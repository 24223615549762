const countries = ["Austria", "France", "Germany", "UK", "USA - TX"];

const commonSubMenu = [
  {
    title: "Infrastructure",
    subMenu: [
      {
        title: "Transmission & Distribution",
        subMenu: [
          {
            title: "Transmission",
            subMenu: [
              { title: "Power Lines" ,
			    subMenu: [
					{ title: "0 to 100 kV", type : 1},
					{ title: "100 to 200 kV", type : 1 },
					{ title: "200 to 300 kV" , type : 1},
					{ title: ">300 kV", type : 1 },
					{ title: "Minor lines", type : 1 },
					{ title: "Enter Voltage", type : 2 }
				]
			  },
              { title: "Substations",
				subMenu: [
					{ title: "Substation Name", type : 2 },
				]
			  },
			  { title: "Charts", type : 3 },
            ],
          },
          {
            title: "Distribution",
            subMenu: [
              { title: "Power Lines" ,
			  subMenu: [
					{ title: "0 to 100 kV", type : 1},
					{ title: "100 to 200 kV", type : 1 },
					{ title: "200 to 300 kV" , type : 1},
					{ title: ">300 kV", type : 1 },
					{ title: "Minor lines", type : 1 },
					{ title: "Enter Voltage", type : 2 }
				]
			  },
              { title: "Substations",
				subMenu: [
					{ title: "Substation name", type : 2 },
				]
			  },
            ],
          },
          {
            title: "Coverage Maps",
            subMenu: [
              { title: "TSO" },
              { title: "DSO" },
            ],
          },
          { title: "Long Term Development Statements" },
        ],
      },
      {
        title: "Generation & Storage",
        subMenu: [
          {
            title: "Existing Generation (Technology)",
            subMenu: [
              { title: "Nuclear" },
              { title: "Coal" },
              {
                title: "Gas",
                subMenu: [
                  { title: "OCGT" },
                  { title: "CCGT" },
                ],
              },
              { title: "Biomass" },
              { title: "Geothermal" },
              { title: "Wind" },
              { title: "Solar" },
              { title: "BESS" },
              {
                title: "Hydro",
                subMenu: [
                  { title: "Pumped" },
                  { title: "RoR" },
                ],
              },
            ],
          },
          { title: "TEC Register (or equivalent)" },
        ],
      },
    ],
  },
  {
    title: "Regulator",
    subMenu: [
      { title: "National / Federal" },
      { title: "State" },
    ],
  },
  { title: "Tax & Levies" },
  { title: "Connection Application Process" },
  {
    title: "Generation Mix",
    subMenu: [
      { title: "By Source" },
      { title: "Carbon Intensity of Grid" },
      { title: "Renewable Energy Utilization (REU)" },
    ],
  },
];

// Function to generate the menu dynamically
function generateMenu(countries, subMenuData, parentElement) {
  console.log("Parent Container is", parentElement);

  countries.forEach((country) => {
    const countryItem = document.createElement("div");
    countryItem.className = "menu-item has-submenu";
    countryItem.textContent = country;

    const countrySubMenu = document.createElement("div");
    countrySubMenu.className = "submenu";

    subMenuData.forEach((subItem) => {
      generateSubMenu(subItem, countrySubMenu, `${country}`);
    });

    countryItem.appendChild(countrySubMenu);
    parentElement.appendChild(countryItem);
  });
}

function sanitizeId(id) {
  return id
    .replace(/\s+/g, "")       // Replace spaces with underscores
    .replace(/&/g, "and")     // Replace '&' with '_and_'
	.replace(/>/g, "g")
    .replace(/[^a-zA-Z0-9_-]/g, ""); // Remove any other invalid characters
}

function generateSubMenu(item, parentElement, idPrefix) {
  const menuItem = document.createElement("div");
  menuItem.className = "submenu-item";
  

  if (item.subMenu) {
	menuItem.textContent = item.title;
    menuItem.classList.add("has-submenu");

    const subMenu = document.createElement("div");
    subMenu.className = "submenu";

    item.subMenu.forEach((subItem) => {
      generateSubMenu(subItem, subMenu, `${idPrefix}_${sanitizeId(item.title)}`);
    });

    menuItem.appendChild(subMenu);
  } else {
    // Assign a sanitized unique ID for the leaf menu item
    const leafId = `${idPrefix}_${sanitizeId(item.title)}`;
    menuItem.id = leafId;
    menuItem.setAttribute("data-id", leafId);
	
   // console.log(`Leaf menu created with ID: ${leafId}`);
   if(item.type){
	   
	   if (item.type === 1) {
		  let label = document.createElement("label");
		  let checkbox = document.createElement("input");
		  checkbox.type = "checkbox";
		  checkbox.id = `checkbox_${leafId}`;

		  // Append checkbox and text to the label
		  label.appendChild(checkbox);
		  label.appendChild(document.createTextNode(` ${item.title}`)); // Label text
		  menuItem.appendChild(label);
		} else if (item.type === 2) {
		  let label = document.createElement("label");
		  let textbox = document.createElement("input");
		  textbox.type = "text";
		  textbox.id = `textbox_${leafId}`;
		  textbox.placeholder = item.title; // Use title as placeholder
      textbox.setAttribute("autocomplete", "off"); 

      let clearButton = document.createElement("button");
      clearButton.textContent = "Clear";
      clearButton.style.display = "inline"; // Initially hide the clear button
      clearButton.style.padding = "5px 10px";
      clearButton.style.cursor = "pointer";
      clearButton.style.marginLeft = "10px"; // Add margin to the left to separate it from the text box
      clearButton.id = `clearbutton_${leafId}`;


      // Create a <ul> for suggestions
      let suggestionBox = document.createElement("ul");
      suggestionBox.id = `suggestions_${leafId}`;
      suggestionBox.className = "suggestion-list";

		  label.appendChild(textbox);
      label.appendChild(clearButton)
      label.appendChild(suggestionBox);

      menuItem.appendChild(label);

		}else if (item.type === 3) {
			menuItem.textContent = item.title; 
		}
		
   }else{
	  menuItem.textContent = item.title; 
   }
  }

  parentElement.appendChild(menuItem);


}




// Render the menu and bind events
const menuContainer = document.getElementById("menu-container");
generateMenu(countries, commonSubMenu, menuContainer);
