import CesiumMVTImageryProvider from "cesium-mvt-imagery-provider";

const allowedProperties = ["circuits", "operator", "start_date", "power", "substation", "ref"];

export default class CustomCesiumMVTImageryProvider extends CesiumMVTImageryProvider {
	constructor(options) {
        
		options.onSelectFeature = (feature) =>  {
			
			showCustomInfoBox(feature);
		}
		  
		super(options);
		this.customRectangle = options.rectangle;
		
    }
	
	
    requestImage(x, y, level) {
		if(this.customRectangle){
			const tileRectangle = this.tilingScheme.tileXYToRectangle(x, y, level);
			
			const intersection = Cesium.Rectangle.intersection(tileRectangle, this.customRectangle);
			
			if (!intersection 
				|| (intersection.east - intersection.west <= 0) || (intersection.north - intersection.south <= 0)) {
			//	console.log(`Skipping tile: x=${x}, y=${y}, level=${level}`);
				return undefined; // Skip request if no intersection
			}
		}
        
        return super.requestImage(x, y, level);
    }
	
}

function showCustomInfoBox(feature) {
  let infoBox = document.getElementById('customInfoBox');
  let header = document.getElementById('infoBoxHeader');
  let title = document.getElementById('infoBoxTitle');
  let table = document.getElementById('infoBoxTable');
  let properties = feature.properties;
  
  // Populate the header
  header.textContent = properties['name'] || properties['Name'] || properties['@id'] || "Not found";

  // Populate the title
  title.textContent = properties.voltage ? `${properties.voltage/1000} kV` : "";

  let propertyInfo = "";
  for (let [key, value] of Object.entries(properties)) {
    if (allowedProperties.includes(key)) {
      propertyInfo += `<tr><td>${key}</td><td>${value}</td></tr>`;
    }
  }

  // Add the rows to the table
  table.innerHTML = propertyInfo;
  // Display the info box
  infoBox.classList.add('show');
}


