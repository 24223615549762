 const voltageStyles = {

    "525000": { strokeStyle: "red", fillStyle: "red" },
    "515000": { strokeStyle: "red", fillStyle: "red" },
    "600000": { strokeStyle: "red", fillStyle: "red" },
    "500000": { strokeStyle: "red", fillStyle: "red" },
    "450000": { strokeStyle: "red", fillStyle: "red" },
    "400000": { strokeStyle: "red", fillStyle: "red" },
    "380000": { strokeStyle: "red", fillStyle: "red" }, 
    "345000": { strokeStyle: "red", fillStyle: "red" },
    "320000": { strokeStyle: "red", fillStyle: "green" },
    "275000": { strokeStyle: "red ", fillStyle: "red" },
    "270000": { strokeStyle: "green", fillStyle: "green" },
    "250000": { strokeStyle: "green", fillStyle: "green" },
    "225000": { strokeStyle: "green", fillStyle: "green" },
    "230000": { strokeStyle: "green", fillStyle: "green" },
    "220000": { strokeStyle: "green", fillStyle: "green" },
    "138000": { strokeStyle: "brown", fillStyle: "brown" },
    "132000": { strokeStyle: "blue", fillStyle: "blue" },
    "115000": { strokeStyle: "blue", fillStyle: "blue" },
    "110000": { strokeStyle: "blue", fillStyle: "blue" },
    "90000": { strokeStyle: "blue", fillStyle: "blue" },
    "69000": { strokeStyle: "magenta", fillStyle: "magenta" },
    "66000": { strokeStyle: "gray", fillStyle: "gray" },
    "65000": { strokeStyle: "orange", fillStyle: "orange" },
    "63000": { strokeStyle: "orange", fillStyle: "orange" },
    "38000": { strokeStyle: "black", fillStyle: "black" },
    "33000": { strokeStyle: "gray", fillStyle: "gray" },
    "30000": { strokeStyle: "orange", fillStyle: "orange" },
    "25000": { strokeStyle: "black", fillStyle: "black" },
    "20000": { strokeStyle: "black", fillStyle: "black" },
    "27000": { strokeStyle: "black", fillStyle: "black" },
    "15000": { strokeStyle: "black", fillStyle: "black" },
    "11000": { strokeStyle: "black", fillStyle: "black" },
    "10500": { strokeStyle: "black", fillStyle: "black" },
    "10000": { strokeStyle: "orange", fillStyle: "orange" },
};

export function stylePowerLines( power, voltage, lw, defaultStyle = true) {

	
	voltage = voltage.split(";")[0]
	// let volt = voltage.split (';');
	// const v = volt[0];
	// console.log ("voltage = ", voltage, " volt = ", v);
	// //power is "line" or "minor_line" or "cable". Ranges must be applied to all 3
	
		// Extract the first 5 or 6 characters depending on voltage length
		const key = voltage.length === 5 ? voltage.substring(0, 5) : voltage.substring(0, 6);
	//console.log('Voltage:', key, 'Active Ranges:', activeRanges);
		const style = voltageStyles[key];

		if (power === "minor_line") {
			return { strokeStyle: "orange", fillStyle: "orange", lineWidth: 0.5 };
		}	
		if (style) {
			return { ...style, lineWidth: lw };
		}
		// If defaultStyle flag is true, return default; otherwise, return nothing
		if (defaultStyle) {
			return { strokeStyle: "red", fillStyle: "red", lineWidth: lw };
		}
	
    return null; // Explicitly return nothing
}

function _styleAllPowerEntity(power, voltage, lw, defaultStyle = true) {
	if(power === 'pole') {
		return styleEntity("blue", "blue", 4);
	}else if(power === 'substation') {
		return styleEntity("red", "red", 2);
	}else if(power === 'tower') {
		return styleEntity("green", "green", 4);
	}else if(power === 'switch'){
		return styleEntity("black", "black", 4);
	}else if(power === 'compensator'){
		return styleEntity("orange", "black", 4);
	}else if(power === 'portal'){
		return styleEntity("violet", "black", 4);
	}else if(power === 'transformer'){
		return styleEntity("red", "black", 4);
	}else if(power === 'cable'){
		if (voltage) {
			return stylePowerLines(power, voltage, 1, false);
		} 
	}
	
	return null;
}


export function stylePowerSubstations(voltage) {
	let volt = voltage.split(";")[0];
	if ((volt === '380000') ||(volt === '400000') )
		{
			return {
				strokeStyle: "black",
				fillStyle: 'rgba(255, 0, 0, 0.2)',
				lineWidth: 2,
			};
		} else if ((volt === '220000') || (volt === '225000')) {
			return {
				strokeStyle: "black",
				fillStyle: 'rgba(0, 255, 0, 0.1)',
				lineWidth: 2,
			};
		} else if ((volt === '110000') || (volt === '150000') || (volt === '155000') || (volt === '132000') || (volt === '90000')) {
			return {
				strokeStyle: "black",
				fillStyle: 'rgba(0, 0, 255, 0.2)',
				lineWidth: 2,
			};
		} else if ((volt === '20000') || (volt === '10000') || (volt === '15000')) {
			return {
				strokeStyle: "black",
				fillStyle: 'rgba(100, 0, 100, 0.2)',
				lineWidth: 2,
			};
		}else {
			return {
				strokeStyle: "black",
				fillStyle: 'rgba(128, 128, 128, 0.5)',
				lineWidth: 2,
			};
		}	
}


function _styleEntity(fillStyle, strokeStyleStyle, lineWidth){
	return {
			fillStyle: fillStyle,
			strokeStyleStyle: strokeStyleStyle,
			lineWidth: lineWidth
	};
}


export function stylePowerLinesDynamic(feature, params) {
  const { opacity, defaultStyle } = params;
  if(feature.properties.voltage && feature.properties.power){
	return stylePowerLines(feature.properties.power, feature.properties.voltage, opacity, defaultStyle);  
  }
  return null;
}


export function styleSubstations(feature, params) {
	if(feature.properties.voltage && feature.properties.power){
		return stylePowerSubstations(feature.properties.voltage);
	}
	return null;
}


export function styleEntity(feature, params) {
	const { fillStyle, strokeStyleStyle, lineWidth } = params;
	return _styleEntity(fillStyle, strokeStyleStyle, lineWidth);
}
