export const Austria_Substations = [
    [ "Umspannwerk Favoriten",[16.37741036411402, 48.1823905867698]],
    [ "Umspannwerk Kaunitzgasse",[16.353570916882674, 48.19658013292616]],
    [ "4731363",[13.989009033775602, 46.57814739311813]],
    [ "Umspannwerk Japons",[15.542555166610674, 48.77754712845976]],
    [ "Umspannwerk 20 kV",[14.866884603028312, 48.112919825986104]],
    [ "Wiener Netze GmbH",[16.25023701854072, 47.963324081771795]],
    [ "Austrian Power Grid AG",[12.741689159023, 47.25873715660336]],
    [ "Umspannwerk Bruck an der Leitha",[16.767549234833513, 48.03382616997992]],
    [ "Umspannwerk Kirchbichl",[12.076057303362832, 47.52231765397104]],
    [ "Umspannwerk Bisamberg",[16.37828011232521, 48.35775529267458]],
    [ "Umspannwerk Thaur",[11.471364700230426, 47.27284131958113]],
    [ "Umspannwerk Linz-Nord",[14.300850090429302, 48.32474153973333]],
    [ "Umrichterwerk Kledering",[16.43294615827528, 48.13914612610641]],
    [ "Umspannwerk Moosbrunn",[16.462739930140373, 48.027553639516455]],
    [ "Umspannwerk Wr. Neudorf",[16.30821515755316, 48.081776826491854]],
    [ "Umspannwerk Wien West",[16.23679893664231, 48.20073320817707]],
    [ "Schaltanlage Kritzendorf",[16.3239067, 48.32325359999999]],
    [ "Umspannwerk Zedlitzhalle",[16.37799487440255, 48.20627592707003]],
    [ "Umspannwerk Brederis",[9.615469295521116, 47.27991085046323]],
    [ "Umspannwerk Wien Nord",[16.389919763786526, 48.25758900704008]],
    [ "Umspannwerk Reutte",[10.748683357935365, 47.49363218088834]],
    [ "Umspannwerk Kendlerstraße",[16.311235528730542, 48.20514484641551]],
    [ "Umspannwerk Baumkirchen",[14.748218016325357, 47.15515634959034]],
    [ "Umspannwerk Ernsthofen",[14.473735295233892, 48.124926903190314]],
    [ "Umspannwerk Wien Südost",[16.42006927049899, 48.12390360801026]],
    [ "Umspannwerk Pottenbrunn",[15.689352234873265, 48.22490768801121]],
    [ "Unterwerk Asten",[14.40563871570574, 48.23391374901161]],
    [ "Unterwerk Simmering",[16.427021186330318, 48.15134436746636]],
    [ "Unterwerk Götzendorf",[16.583291263713484, 48.02615999056099]],
    [ "Umspannwerk Eibesbrunn",[16.504552999249018, 48.360859353443104]],
    [ "Schaltanlage Mayrhofen",[11.850783184805469, 47.16249227836748]],
    [ "Umspannwerk Kaiserebersdorf",[16.47577083062494, 48.15614171624655]],
    [ "Umspannwerk Enzersdorf",[16.615051383639326, 48.0650615710152]],
    [ "Umspannwerk Hütte Süd",[14.342235720671509, 48.26691558325425]],
    [ "Schaltwerk Etzersdorf",[15.737396908920351, 48.268774998415275]],
    [ "Umspannwerk Ybbsfeld",[15.047811571324823, 48.146700214408696]],
    [ "Umspannwerk Ulmerfeld-Hausmening",[14.811852426779733, 48.062777422062915]],
    [ "Umspannwerk Bergern",[15.269627662792677, 48.218052752630676]],
    [ "Umspannwerk Innsbruck-Ost",[11.4287093380941, 47.271467744243374]],
    [ "Umspannwerk Ohlsdorf",[13.797445206915725, 47.970216144153106]],
    [ "Umspannwerk Rietz",[11.057001158510339, 47.2927201227241]],
    [ "EBNER STROM GmbH",[14.664452199598706, 48.43384813805032]],
    [ "Netz Oberösterreich",[13.984535612812872, 48.56874572132577]],
    [ "Umspannwerk Handelskai",[16.408297964283136, 48.222890635902054]],
    [ "Umspannwerk Frastanz",[9.642669552965167, 47.22205620250612]],
    [ "Unterwerk Matrei",[11.453340753015524, 47.127179770116335]],
    [ "Unterwerk Unterberg",[11.392445196288715, 47.21415371831689]],
    [ "Unterwerk Steindorf",[13.240179538447919, 47.96674238809696]],
    [ "Unterwerk Elsbethen",[13.084420392550289, 47.75219131527944]],
    [ "Umspannwerk Wasserkraftwerk Abwinden-Asten",[14.427944011675851, 48.24731306643845]],
    [ "Umspannwerk FHKW Linz-Süd",[14.347485966176917, 48.26722964855725]],
    [ "Umspannwerk Wilten",[11.39353984747033, 47.252299487492]],
    [ "Umspannwerk Steyrermühl",[13.799890049716488, 47.99687563686043]],
    [ "Unterwerk Feldkirch",[9.617849927031674, 47.25231992018801]],
    [ "Umspannwerk Seekirchen",[13.1191609901998, 47.89101719335065]],
    [ "Umspannwerk Hagenau",[13.027753744661096, 47.83325259932306]],
    [ "Umspannwerk Taxach",[13.060207747100858, 47.71469850244283]],
    [ "Umspannwerk Oberalm",[13.082388343097605, 47.70660481157388]],
    [ "Umspannwerk Salzach Stich",[13.029645272419058, 47.831060680260165]],
    [ "Energie AG Oberösterreich",[14.223879884007648, 48.20476543146953]],
    [ "Umspannwerk Tillysburg",[14.420009844175633, 48.21245716399277]],
    [ "Umspannwerk Steyr-Nord",[14.422868863179653, 48.068320330685836]],
    [ "Umspannwerk Steyr-Fischhub",[14.438986614324167, 48.043036916571715]],
    [ "Umspannwerk Katzbach",[14.324887574326926, 48.324133282440016]],
    [ "Kraftwerk Rosenau",[14.37800063349876, 47.99377297593934]],
    [ "Umspannwerk Linz FHKW Mitte",[14.32256099342795, 48.30064537877519]],
    [ "Umspannwerk Traiskirchen",[16.28470903472906, 48.01005238978144]],
    [ "Umspannwerk Greifenstein",[16.24079042004093, 48.35670204799183]],
    [ "Umspannwerk Stockerau",[16.205957574478276, 48.393447334665574]],
    [ "Umspannwerk Türnitz",[15.477394372214272, 47.930419526580444]],
    [ "Unterwerk Attnang-Puchheim",[13.727421138395297, 48.019200808894304]],
    [ "33750614",[16.46885636363393, 48.2154720225111]],
    [ "Umspannwerk Timelkam",[13.588175399136958, 48.01077000223107]],
    [ "Umspannwerk Traunfall",[13.802124701109433, 48.01787203028779]],
    [ "Umspannwerk Freistadt",[14.496992715951157, 48.49648308077479]],
    [ "Umspannwerk Gerasdorf",[16.430275654221873, 48.32758170449355]],
    [ "KNG-Kärnten Netz GmbH",[13.641843682552876, 46.535852652267344]],
    [ "Umspannwerk Gaumberg",[14.275758228560848, 48.2765780066603]],
    [ "Umspannwerk Puchberg",[14.004959963395955, 48.184008462876584]],
    [ "Umspannwerk Schmelz",[16.333088517507896, 48.200545816538636]],
    [ "Energie AG",[14.337364518304076, 48.16378925303936]],
    [ "eww ag",[14.026480142759567, 48.15287673459957]],
    [ "Umspannwerk Kaprun",[12.74224749320021, 47.25923590818875]],
    [ "Umspannwerk Tauern",[12.73973337869625, 47.277595484532064]],
    [ "Marchtrenk",[14.10293344029251, 48.2033409896527]],
    [ "Umspannwerk Vorderwald",[9.852832008570008, 47.471992500239786]],
    [ "Umspannwerk Wels-Ost",[14.059647416825316, 48.16530494821863]],
    [ "Umspannwerk Arnstein",[15.158944131776781, 47.01855861593435]],
    [ "Umspannwerk Großraming",[14.517352267112264, 47.8921132829435]],
    [ "Umspannwerk Neulengbach",[15.906129087089726, 48.2059645095134]],
    [ "Umspannwerk Steinach",[11.473916178305911, 47.08313100256651]],
    [ "Netz Oberösterreich",[13.714172244307642, 47.535313885616475]],
    [ "Umspannwerk West Maxglan",[13.018645199999998, 47.79189469999999]],
    [ "Austrian Power Grid AG",[16.339751415326603, 48.33076629770176]],
    [ "Umspannwerk Mitte",[11.397125400756524, 47.262508998993084]],
    [ "Umspannwerk Theiß",[15.705590039073314, 48.393951770959966]],
    [ "Umspannwerk Stratzdorf",[15.68924551808161, 48.409249490499434]],
    [ "Salzburg AG",[13.056813678708552, 47.80975983263526]],
    [ "49800455",[14.318400755088303, 48.33167416875022]],
    [ "Umspannwerk Sattledt",[14.052111502980678, 48.08325524594763]],
    [ "Umspannwerk Kremsmünster",[14.123506877047925, 48.03988005252813]],
    [ "Umspannwerk Linz-Nordwest",[14.276267280661566, 48.31109220880427]],
    [ "UW Sillian",[12.433825926427275, 46.747841762129454]],
    [ "Umspannwerk Salzburg Mitte",[13.038191938330714, 47.80987384792242]],
    [ "Umspannwerk Dürnrohr",[15.885238377926246, 48.32893727438375]],
    [ "Umspannwerk Frauenkirchen",[16.900671894046827, 47.84472607163928]],
    [ "60354029",[13.495179277677682, 47.386076270579736]],
    [ "KNG-Kärnten Netz GmbH",[13.66600539323719, 46.705067517214665]],
    [ "Umspannwerk Meiningen",[9.591857747506236, 47.312487253165]],
    [ "KNG-Kärnten Netz GmbH",[14.034747861177483, 46.564489960287986]],
    [ "SAFE",[13.182835797164422, 47.82867303713549]],
    [ "65071211",[13.508204483947527, 47.303995067373066]],
    [ "65347650",[13.481103536762436, 47.36857991792276]],
    [ "Umspannwerk Linz-Mitte",[14.303640999999999, 48.311101400000005]],
    [ "Umspannwerk Enzesfeld",[16.185632705609468, 47.924340187775314]],
    [ "69866540",[13.392270374431398, 47.34802573896424]],
    [ "Salzburg AG",[13.402808229578358, 47.28970671598546]],
    [ "70085078",[13.428007320250613, 47.247048863880714]],
    [ "Umspannwerk Ybbs Persenbeug",[15.065597356128652, 48.189484873929885]],
    [ "70696602",[13.394532046758318, 47.382086693702256]],
    [ "71118465",[13.424637524294774, 47.37392045742414]],
    [ "Umspannwerk Langenlois",[15.693129947769807, 48.48080649995773]],
    [ "71901246",[13.465528115976046, 47.37271933244058]],
    [ "72660205",[13.518851605927873, 47.37737369830617]],
    [ "73331674",[13.397313736202301, 47.39829238375297]],
    [ "Sbg. AG",[13.561600537167198, 47.24864332143082]],
    [ "Ebensee Kirchengasse",[13.7725236, 47.813132899999985]],
    [ "Peierlhang",[15.485930256054923, 47.06068587715197]],
    [ "Umspannwerk Weiz",[15.631160458635403, 47.20688597567884]],
    [ "Umspannwerk Poysdorf",[16.629990365979886, 48.65542448907396]],
    [ "Umspannwerk Ried",[13.514989116318278, 48.21066650128425]],
    [ "Umspannwerk St. Peter",[13.081870668798638, 48.25651953629614]],
    [ "Umspannwerk Bruck an der Mur",[15.273971310501333, 47.42332735103297]],
    [ "Unterwerk Bruck/Mur",[15.274075933044802, 47.42864138829737]],
    [ "81639361",[13.7672836, 47.911916149999996]],
    [ "Umspannwerk Südburgenland/Rotenturm",[16.27630146801807, 47.25075082813865]],
    [ "Umspannwerk Neusiedl",[16.838042550684637, 47.960467808943676]],
    [ "82225798",[13.763233834003149, 47.906310432611384]],
    [ "82227734",[13.75687645, 47.90615019999999]],
    [ "Umspannwerk Ternitz",[16.06490396258756, 47.70706482438218]],
    [ "Umspannwerk Keplerbrücke",[15.434094309077386, 47.077016316735076]],
    [ "Umspannwerk Mattersburg",[16.383061623491248, 47.72251197932615]],
    [ "Umspannwerk Werben - Dornbirn",[9.716747434673355, 47.43266822608249]],
    [ "Umspannwerk Deutsch-Altenburg",[16.91914340385856, 48.122025037990916]],
    [ "Umspannwerk Hütte Krems",[15.65094743025726, 48.40813880895087]],
    [ "Umspannwerk Leibnitz",[15.559440009319754, 46.800057742849134]],
    [ "Umspannwerk Kainachtal/Zwaring",[15.423167733646435, 46.90363799102185]],
    [ "Umspannwerk Neudorf/Werndorf",[15.484073722585197, 46.909098092292005]],
    [ "Umspannwerk Oststeiermark/Wünschendorf",[15.725629800308592, 47.09103556760317]],
    [ "83803297",[13.137987340338192, 47.31536868364601]],
    [ "Malta Hauptstufe",[13.327769701179395, 46.870956773174925]],
    [ "Reißeck-Kreuzeck",[13.312496433656232, 46.87245890126082]],
    [ "83823298",[13.11000498049443, 46.933986169546536]],
    [ "Unterwerk Mallnitz",[13.179026711364006, 46.9780079348965]],
    [ "Umspannwerk Obersielach",[14.681460598337997, 46.66845419378626]],
    [ "Pusarnitz",[13.404950383696551, 46.83467309790322]],
    [ "Malta Unterstufe",[13.359562732090783, 46.83318251521562]],
    [ "Lieserhofen",[13.477055326226592, 46.82997449364618]],
    [ "Umspannwerk Tröpolach",[13.278955115988417, 46.617739601046104]],
    [ "Umspannwerk Hollabrunn",[16.07103939657266, 48.5593617493327]],
    [ "Kraftwerk Kamering",[13.592968119389077, 46.73978248566137]],
    [ "Unterwerk Villach",[13.832026497723918, 46.59503241200184]],
    [ "Umspannwerk Landskron",[13.893413871101266, 46.631353501788304]],
    [ "Schaltwerk Strass",[11.838714775570887, 47.39242894149838]],
    [ "Unterwerk St. Veit an der Glan",[14.37253563203552, 46.76148957028398]],
    [ "Umspannwerk St. Veit an der Glan",[14.374592640458141, 46.76118521107952]],
    [ "Unterwerk Andreas-Hofer-Platz",[15.435599641091036, 47.0692606699617]],
    [ "Umspannwerk Amlach",[12.749779624058878, 46.80188066503215]],
    [ "Umspannwerk Seebach",[13.876860349999998, 46.617061650000004]],
    [ "Umspannwerk Gailitz",[13.694409774408115, 46.561398233684756]],
    [ "Umspannwerk Fürnitz",[13.817172672850331, 46.55762319760959]],
    [ "Umspannwerk Forstsee",[14.07286460280286, 46.62255377193995]],
    [ "Umspannwerk Windischbach",[14.210223667544936, 46.69018548025456]],
    [ "Unterwerk Reiterkaserne",[15.46072506457986, 47.07640771582041]],
    [ "Umspannwerk Feldkirchen",[14.090481667707127, 46.7196931676146]],
    [ "Umspannwerk Oberdrauburg",[12.967293725065046, 46.74203362152443]],
    [ "Umspannwerk Steinfeld",[13.250269230834974, 46.75375491190401]],
    [ "85511135",[15.468303186606658, 47.08195716659582]],
    [ "Umspannwerk Würmlach",[13.070917170567585, 46.64913359095985]],
    [ "Umspannwerk Vorderberg",[13.532657705389006, 46.586782591320855]],
    [ "Umspannwerk St. Pölten",[15.606966377044632, 48.199435354466175]],
    [ "Umspannwerk Hermagor",[13.379886358808758, 46.62853767403916]],
    [ "UW Gruben",[12.516521714588418, 47.035765678466056]],
    [ "UW Kienburg",[12.591530275480936, 46.92357176034534]],
    [ "KW Kalserbach",[12.601087508481708, 46.922950008200985]],
    [ "85816225",[14.073954505814454, 48.02590654724691]],
    [ "Kraftwerk Rosegg",[14.027972807478974, 46.56831819131433]],
    [ "Freiluftschaltanlage Feistritz",[14.172961241105924, 46.53584400041256]],
    [ "Klagenfurt",[14.320024353009808, 46.605736711901905]],
    [ "Umspannwerk Rosegg",[14.02700853722719, 46.56774985289981]],
    [ "Unterwerk Wartberg an der Krems",[14.121723464852952, 47.98957378127701]],
    [ "Umspannwerk Völkermarkt",[14.619155168221424, 46.660902824090414]],
    [ "Kraftwerk Ferlach-Maria Rain",[14.298876633010162, 46.545461262183224]],
    [ "Umspannwerk Ferlach",[14.298152293220696, 46.5291857670175]],
    [ "Umspannwerk Hörbranz",[9.748993322147376, 47.54266306646347]],
    [ "Spittal an der Drau",[13.519406673640812, 46.78990786622555]],
    [ "KNG-Kärnten Netz GmbH",[13.51983167425974, 46.789825684110255]],
    [ "Rennweg",[13.603867241572566, 47.02923299687584]],
    [ "Uttendorf Schule",[13.120531517049107, 48.158914794773544]],
    [ "Umspannwerk Lungau",[13.621528819244364, 47.09427744472757]],
    [ "Umspannwerk Zwettl",[15.179534995555343, 48.59916835943529]],
    [ "86869360",[14.016131316116121, 48.17837842095441]],
    [ "Umspannwerk Gmunden",[13.785620516818678, 47.923441694772976]],
    [ "Kraftwerk Freibach",[14.483112810249827, 46.55956210802469]],
    [ "Bleiburg",[14.784818726799886, 46.628440471246975]],
    [ "Umspannwerk Spannberg",[16.76028878674331, 48.468084878801]],
    [ "Linz AG",[14.163154588277013, 48.27837162296912]],
    [ "Kraftwerk Edling",[14.702450658958183, 46.629789498657665]],
    [ "Kraftwerk Schwabeck",[14.874255607594405, 46.65819524451186]],
    [ "Umspannwerk Marchtrenk",[14.099610988761107, 48.173129154967135]],
    [ "UW Deutschlandsberg",[15.226581254029728, 46.81818954183232]],
    [ "Umspannwerk Horn",[15.632727041365575, 48.64769810666873]],
    [ "Umspannwerk Waidhofen an der Thaya",[15.275988705826515, 48.807007650488295]],
    [ "Umspannwerk Sankt Andrä",[14.837949563676396, 46.772083009067615]],
    [ "UW Wolfsberg",[14.840465854193942, 46.84752810780342]],
    [ "Unterwerk Mariagrün",[15.460026118434735, 47.0895567008047]],
    [ "Umspannwerk Hessenberg",[15.02094814749136, 47.39715475254587]],
    [ "Umspannwerk Zeltweg",[14.726172707435433, 47.180966016944424]],
    [ "Umspannwerk Judenburg West",[14.63695579803325, 47.17408529972325]],
    [ "UW Angertal",[13.106596557207554, 47.15100414543591]],
    [ "Unterwerk Mariahof",[14.374216899586507, 47.10511330345351]],
    [ "Umspannwerk Fügen",[11.860076635384036, 47.35649473250751]],
    [ "Umspannwerk Weißenbach",[14.20781043437853, 47.57453792279139]],
    [ "Umspannwerk Bergla",[15.280928543915243, 46.75152636078344]],
    [ "Energie AG",[14.3462851738442, 47.69796585405929]],
    [ "89663195",[14.017731455449898, 48.179713082998724]],
    [ "Umspannwerk Pyhrn",[14.330479603541383, 47.67845501526692]],
    [ "Unterwerk Küpfern",[14.617542540248774, 47.85264430408433]],
    [ "Umspannwerk Amstetten Süd",[14.866826183916626, 48.11200080968576]],
    [ "Unterwerk Wagram",[15.686762909462113, 48.21977927633027]],
    [ "Umspannwerk Traisen",[15.615647516483433, 48.044966351053]],
    [ "Unterwerk Steyrergasse",[15.4433943790543, 47.0605876991302]],
    [ "Umspannwerk Haus",[13.772554640300841, 47.41358922080837]],
    [ "UW St. Johann im Pongau",[13.190023656210634, 47.336529280166914]],
    [ "Umspannwerk Zagging",[15.653020703061324, 48.27231237321921]],
    [ "Unterwerk Zellerndorf",[15.96932977119373, 48.69205290552029]],
    [ "Umspannwerk Donawitz-Nord",[15.051907988432198, 47.38784167882425]],
    [ "Umspannwerk Stadtmitte",[12.0634454, 47.485809749999994]],
    [ "Umspannwerk Sallingberg",[15.241532253851682, 48.47378289183362]],
    [ "Umspannwerk Am Schuss",[15.256727872399903, 48.30196881354556]],
    [ "Umspannwerk Irdning",[14.107288655924572, 47.49080124512696]],
    [ "Umspannwerk Kilb",[15.390410221711448, 48.08930844972901]],
    [ "Umspannwerk Gresten",[15.023558709412795, 47.9910051772554]],
    [ "Umspannwerk Liezen",[14.254216183391742, 47.56640192055742]],
    [ "Unterwerk Selzthal",[14.306453690785665, 47.547031336863085]],
    [ "Rheinbauleitung",[9.659841400666414, 47.447377449286556]],
    [ "Umspannwerk Hainfeld",[15.768319095263761, 48.03331183484653]],
    [ "Unterwerk Wald am Schoberpass",[14.6689161810476, 47.45152610387364]],
    [ "ÖBB Unterwerk Selzthal",[14.306593613180123, 47.54731119049988]],
    [ "Umspannwerk Schwarzenbach",[14.464497777064524, 47.49457592049103]],
    [ "Unterwerk Dorfgastein",[13.104733792611698, 47.234932077484736]],
    [ "Krankenhaus",[12.061303489789816, 47.487789217100556]],
    [ "Umspannwerk Ottensheim",[14.151168209513838, 48.31506223683798]],
    [ "Umspannwerk Sankt Michael",[15.001768372819724, 47.35879454404661]],
    [ "Unterwerk St. Michael",[15.003192132108373, 47.356973669054376]],
    [ "Umformerwerk Sankt Michael",[15.002590618962477, 47.35776827352552]],
    [ "Umspannwerk Knittelfeld-Ost",[14.842199605792635, 47.22072990829917]],
    [ "Aubach",[12.05329785, 47.481324300000004]],
    [ "Umspannwerk Bodendorf",[14.065258419881246, 47.108317279285544]],
    [ "Umspannwerk Eferding",[14.007721026562567, 48.30271179028237]],
    [ "Umspannwerk Aschach",[14.019605071709073, 48.385208429096316]],
    [ "92923091",[13.109045685899499, 47.16403249980462]],
    [ "Unterwerk Semmering",[15.81530171376349, 47.626793657669836]],
    [ "Umspannwerk Höchst",[9.657544705349112, 47.463467892799194]],
    [ "Umspannwerk Hausruck",[13.89975908846384, 48.10437543439557]],
    [ "Umspannwerk Angatherweg",[12.067580898804637, 47.49405770450302]],
    [ "Umspannwerk Vöcklabruck",[13.658826954870532, 47.991645877619014]],
    [ "Vöcklabruck",[13.65870988509404, 47.99127041195704]],
    [ "Umspannwerk Golling",[13.180117733495482, 47.5910699471079]],
    [ "Unterwerk Golling",[13.164908306494034, 47.59818200552628]],
    [ "Umspannwerk Eichet",[13.0361387865981, 47.75095347225389]],
    [ "Umspannwerk Kleinmünchen",[14.320138268118617, 48.24990928637895]],
    [ "Umspannwerk Kapfenberg",[15.295926597888995, 47.44178901301866]],
    [ "Umspannwerk Pichling",[14.376535763646292, 48.25656833785062]],
    [ "Umspannwerk West",[12.047870970855728, 47.484311278937646]],
    [ "Umspannwerk Bruck",[12.812025800964792, 47.285500742313644]],
    [ "93982415",[13.008406976470075, 48.24303045920537]],
    [ "Unterwerk Bruck/Fusch",[12.8302544654524, 47.28425377395648]],
    [ "Kraftwerk Uttendorf",[12.567504712019826, 47.262268267111736]],
    [ "KW Enzingerboden",[12.627222750854585, 47.169816260866085]],
    [ "Umspannwerk Steyr-Ost",[14.45405141223143, 48.0470946707471]],
    [ "Umspannwerk Ebbs",[12.258089675201813, 47.64511864729914]],
    [ "Umspannwerk Krems",[15.606516674912333, 48.40664395441559]],
    [ "Umspannwerk Markt Sankt Martin",[16.41998707683351, 47.55684696997434]],
    [ "Umspannwerk Kufstein",[12.147811128866303, 47.57890702082561]],
    [ "Umspannwerk Hopfgarten",[12.14663376823272, 47.45723224410688]],
    [ "Umspannwerk St. Johann",[12.418754309593561, 47.516048938779754]],
    [ "Umspannwerk Kundl",[11.974744209861534, 47.4673827996209]],
    [ "Umspannwerk Ellmau",[12.268438468403764, 47.52520049879935]],
    [ "Umspannwerk Hochfilzen",[12.630997036495224, 47.47046824223824]],
    [ "Umspannwerk Mattighofen",[13.13401204138902, 48.120400853491105]],
    [ "Umspannwerk Grieskirchen",[13.818808108168911, 48.236375266517605]],
    [ "Umspannwerk Kirchdorf",[14.113445215270701, 47.90774467995356]],
    [ "Umspannwerk Bad Hall",[14.207281427302755, 48.04891853746644]],
    [ "Umspannwerk Ebenfurth",[16.354948225615587, 47.872010501612735]],
    [ "UW Riedau",[13.627193118082705, 48.308974473955566]],
    [ "Unterwerk Haag",[14.593665753081762, 48.09290157252709]],
    [ "Unterwerk Amstetten",[14.885246240216691, 48.119301109361174]],
    [ "Umspannwerk Erlauf",[15.181089233655564, 48.185365953734006]],
    [ "Umspannwerk Eggenburg",[15.832221827607684, 48.66289877941537]],
    [ "Unterwerk Göpfritz",[15.392061827406131, 48.73017070416777]],
    [ "Umspannwerk Frankenmarkt",[13.38533647730417, 47.97849793883342]],
    [ "Umspannwerk Unterach",[13.455815222727676, 47.804641069246905]],
    [ "Umspannwerk Laakirchen",[13.814016899999999, 47.98134495]],
    [ "Umspannwerk Strobl",[13.506404375939779, 47.72247005328663]],
    [ "Umspannwerk Steeg",[13.632816150790218, 47.607813785673265]],
    [ "Umspannwerk Bad Aussee",[13.79159470562732, 47.61297503406755]],
    [ "Umspannwerk Pfandl",[13.596713169902158, 47.717369872547515]],
    [ "Umspannwerk Graz Süd",[15.440919303953724, 47.049564153087395]],
    [ "Umspannwerk Grambach",[15.491955042637665, 47.01642099714811]],
    [ "Umspannwerk Silz",[10.966258459203704, 47.26953050486748]],
    [ "Umspannwerk Mauthausen",[14.53893613106597, 48.24075398019912]],
    [ "Schaltwerk St. Pantaleon",[14.531886611177237, 48.22440697875484]],
    [ "Unterwerk Gaisbach/Wartberg",[14.49790491380114, 48.33106863488606]],
    [ "Umspannwerk Wallsee",[14.6951771552777, 48.164206977159154]],
    [ "Umspannwerk Friensdorf",[14.50014679778417, 48.35972954522142]],
    [ "Umspannwerk Lassendorf",[14.421124596121398, 46.66731362524088]],
    [ "Umspannwerk Zwentendorf",[15.885025666462532, 48.35395596956092]],
    [ "Umspannwerk Haag",[14.571112800447384, 48.10189612307275]],
    [ "Umspannwerk Aigerding",[13.449770030290885, 48.44804115233747]],
    [ "Umspannwerk Antiesenhofen",[13.39543035, 48.3499836]],
    [ "Steinergruende",[15.517107077334794, 47.07444409140712]],
    [ "Schaltstation Friesach",[15.330185516622477, 47.14442538005848]],
    [ "Umspannwerk Deutschfeistritz",[15.32278979136667, 47.20558245764336]],
    [ "Umspannwerk Bärnbach",[15.13475495053909, 47.055713319673664]],
    [ "Umspannwerk Pernhofen",[16.281492850392638, 48.72030866677924]],
    [ "Umspannwerk Laa an der Thaya",[16.383295268632907, 48.71484807456354]],
    [ "Umspannwerk Gleisdorf",[15.705317722865418, 47.11266293660826]],
    [ "Umspannwerk Josefstadt",[16.349753347243862, 48.20783802490636]],
    [ "Umspannwerk Partenstein",[13.986685055220605, 48.43376984969146]],
    [ "Umspannwerk Ranshofen",[13.035341947002129, 48.22122428653503]],
    [ "Umspannwerk Gänserndorf",[16.713445764740168, 48.343984329480335]],
    [ "Umspannwerk Rohrbach",[15.999081621564121, 47.3912212703984]],
    [ "Umspannwerk Wiener Neustadt",[16.269063310876007, 47.81593904700636]],
    [ "KNG-Kärnten Netz GmbH",[13.614409425591168, 47.015943088979604]],
    [ "Umspannwerk Wasenbruck",[16.568629252918914, 47.97883238759813]],
    [ "ÖBB-Infrastruktur AG",[16.822118308292563, 48.38255833321589]],
    [ "Umspannwerk Neusiedl an der Zaya",[16.800268358734087, 48.605823249359375]],
    [ "Umspannwerk Kettlasbrunn",[16.622704079000897, 48.543282653342885]],
    [ "Unterwerk Parndorf",[16.84274238833836, 47.9960190747428]],
    [ "Umspannwerk Eisenstadt",[16.546386673176702, 47.8436680327612]],
    [ "Umspannwerk Parndorf-Walzwerk",[16.889459520234148, 47.988113256081405]],
    [ "Umspannwerk Trofeng",[14.905993175275324, 47.54182093731322]],
    [ "Umspannwerk Heidenreichstein",[15.103637440090585, 48.85490361917443]],
    [ "Mattighofen",[13.148206600000002, 48.104014]],
    [ "Umspannwerk Hartberg",[15.970679953716683, 47.264554507204586]],
    [ "Umspannwerk Hieflau",[14.742589068818441, 47.605632072772224]],
    [ "96806326",[14.160333041644115, 47.703896048443355]],
    [ "Umspannwerk Essling",[14.6835404051827, 47.7134609269357]],
    [ "Umspannwerk Gerstl",[14.74163428889828, 47.98831330039556]],
    [ "Linz Netz",[14.500732095095787, 48.35984421632433]],
    [ "Umspannwerk Hall",[14.46917684189085, 47.59281680832956]],
    [ "Umspannwerk Brückl",[14.525443631058819, 46.73272514567887]],
    [ "Umspannwerk Hart",[15.933060381570716, 47.66383131672891]],
    [ "Umspannwerk Edlitz",[16.126401950817243, 47.61067112803959]],
    [ "Umspannwerk Mürzzuschlag",[15.663603990649394, 47.58837315525597]],
    [ "Umspannwerk Hadersdorf",[15.413396890428283, 47.49619607048331]],
    [ "Umspannwerk Mitterdorf",[15.515988657524614, 47.53096014349072]],
    [ "Unterwerk Wiener Neustadt",[16.220203900726506, 47.798500758895166]],
    [ "Bahnstrom Unterwerk Graz",[15.413284492935944, 47.078065943049715]],
    [ "Umspannwerk Oberpullendorf",[16.4895058519335, 47.49441709182986]],
    [ "Umspannwerk Güssing",[16.317800478903475, 47.07381220196374]],
    [ "Umspannwerk Eltendorf",[16.19160227008426, 47.01027031741626]],
    [ "Umspannwerk Stegersbach",[16.182793116120706, 47.17346410170361]],
    [ "Umspannwerk Pinkafeld",[16.1233884, 47.361430049999996]],
    [ "Umspannwerk Jennersdorf",[16.12825285386466, 46.92888884239553]],
    [ "Umspannwerk Deutschkreutz",[16.59772320691351, 47.59634036092132]],
    [ "Unterwerk Bad Vöslau",[16.224626597677673, 47.96824211674142]],
    [ "Energie Ried",[13.503841781213378, 48.21551916116883]],
    [ "Umspannwerk Groß-Gerungs",[14.991067298154812, 48.572085054092895]],
    [ "Umformerwerk/Unterwerk Bergern",[15.271888924031558, 48.21808889101]],
    [ "Umspannwerk Amstetten-Ost",[14.909250038764073, 48.11216437665243]],
    [ "Unterwerk Mistelbach",[16.559158325965928, 48.56444209870403]],
    [ "Wiener Netze",[16.310461948441727, 48.09076551725849]],
    [ "Umspannwerk Neudörfl",[16.273523774019402, 47.80140578034998]],
    [ "Linz AG Strom",[14.277169213756391, 48.303720771384654]],
    [ "Umspannwerk Riedersbach",[12.842161270670738, 48.02922211480515]],
    [ "Umspannwerk Göming",[12.95487297919276, 47.953400069867705]],
    [ "Unterwerk Hilmteich",[15.459709235828996, 47.08331732381147]],
    [ "98046004",[15.456920950000002, 47.063021500000005]],
    [ "Umspannwerk Warmbad",[13.833361934946918, 46.595246419618306]],
    [ "Umspannwerk Mittersill",[12.471920783666144, 47.2783564800001]],
    [ "Kraftwerk Annabrücke",[14.479834624333957, 46.56203954999026]],
    [ "Umspannwerk Rohrbach",[13.993997256489381, 48.55622881208519]],
    [ "Umspannwerk Nettingsdorf",[14.252581544653664, 48.184239975613856]],
    [ "Umspannwerk Wimpassing",[16.452044305258486, 47.90510103143612]],
    [ "Umspannwerk Grubberg",[15.067933552850352, 47.88693778780801]],
    [ "Salzburg AG",[13.0318181, 47.78983184999999]],
    [ "Bad Hall - Lederhilger",[14.208080314157245, 48.0467166995241]],
    [ "Umspannwerk Hohenbrugg",[15.989236299999996, 47.142398650000004]],
    [ "Umspannwerk Fürstenfeld",[16.061767910506635, 47.051823811474094]],
    [ "Umspannwerk St. Johann in der Haide",[16.01160329879612, 47.28184594998091]],
    [ "Umspannwerk Birkfeld",[15.690634134622757, 47.34473562925489]],
    [ "Umspannwerk Feldbach",[15.899593720860674, 46.95095676747722]],
    [ "Umspannwerk Hohe Wand",[16.103829906579264, 47.725859131803645]],
    [ "Umspannwerk Pitten",[16.167763363238063, 47.70461329258227]],
    [ "Umspannwerk Merkendorf",[15.901249541022645, 46.86205605109931]],
    [ "Umspannwerk Kitzbühel",[12.389700276216068, 47.456344609630946]],
    [ "Unterwerk Gries am Brenner",[11.485949263386988, 47.0432507702089]],
    [ "Energie AG",[14.188075779377192, 48.324595319955165]],
    [ "Umspannwerk Graz-Ost",[15.469209291472563, 47.08268607296376]],
    [ "Tiwag Umspannwerk Ötztal",[10.868763763305843, 47.242954314825006]],
    [ "Umspannwerk Sölden",[11.01264303672966, 46.98239854414912]],
    [ "Umspannwerk Weiler - Klaus",[9.622028714960381, 47.30398933202946]],
    [ "Linz AG",[14.367951514961952, 48.28508526273795]],
    [ "Schörgern",[13.492891430272307, 48.073223155646474]],
    [ "Umspannwerk Prutz",[10.665421871061527, 47.06940845616328]],
    [ "Umspannwerk Sankt Jakob",[10.312649799999999, 47.1449767]],
    [ "Linz AG",[14.34166299816247, 48.34851143092032]],
    [ "Linz AG",[14.351588422498928, 48.350274407774705]],
    [ "Linz AG",[14.355052699107317, 48.36264034961116]],
    [ "Linz AG",[14.32506159969723, 48.347693246853915]],
    [ "Linz AG",[14.355526383348906, 48.34163286639531]],
    [ "ÖBB Umformer-/Unterwerk/Schaltanlage Ötztal",[10.874117327959175, 47.24660741561937]],
    [ "110kV Freischaltanlage",[10.719951242610309, 47.21179293588134]],
    [ "Linz Netz",[14.357632807749598, 48.32354491173956]],
    [ "101502246",[13.108657666878434, 47.772136300447244]],
    [ "Unterwerk Bludenz",[9.828976444990358, 47.14486165370482]],
    [ "Umspannwerk Bürs",[9.810746665688944, 47.14278088986916]],
    [ "Umspannwerk Reitdorf",[13.384864058348835, 47.3646608374626]],
    [ "Umspannwerk Linz-Zentrum",[14.286058100007226, 48.29168649341473]],
    [ "Umspannwerk Wegscheid",[14.268859459851623, 48.24726191593349]],
    [ "Umspannwerk Landeck",[10.564109910833292, 47.14288861304084]],
    [ "Linz Netz",[14.266154126879195, 48.245783411836456]],
    [ "Umspannwerk Bürs",[9.816260966943911, 47.15160281824444]],
    [ "Umspannwerk Unterrohr",[16.049106994906065, 47.2406814746704]],
    [ "Umspannwerk Ardning",[14.348591638847921, 47.58911908740315]],
    [ "Kraftwerk Laufnitzdorf",[15.322659163984383, 47.292687619893435]],
    [ "Umspannwerk Gosdorf",[15.782699926891208, 46.739134178227964]],
    [ "Schulgasse",[15.465551668304292, 47.06790000274125]],
    [ "Schaltstelle Franzenshoehe",[15.47087555, 47.07375654999999]],
    [ "Umspannwerk Lieboch",[15.346297744925517, 46.95583744772143]],
    [ "Umspannwerk Brodingberg",[15.616296739039226, 47.12875599268719]],
    [ "TINETZ",[12.288116099051928, 47.63945552797832]],
    [ "Umspannwerk Söcking",[12.059103450000002, 47.49324595000001]],
    [ "Umspannwerk St. Martin",[15.091421407893282, 46.98736250648287]],
    [ "104970552",[12.981189483167652, 47.811918600466754]],
    [ "Salzburg-AG",[13.066573533717772, 47.7894809578779]],
    [ "105015798",[13.040187411747793, 47.782091976945985]],
    [ "105015826",[13.042902200000002, 47.77369535]],
    [ "Umspannwerk Salzburg",[13.066843061018693, 47.888049709131955]],
    [ "105080874",[12.087516126736075, 47.51525313658413]],
    [ "TINETZ-Stromnetz Tirol AG",[12.040889841970627, 47.50570897651035]],
    [ "Umspannwerk Jahrsdorf",[13.080110013849195, 48.25417634716278]],
    [ "105431455",[13.0863398, 48.25323455000001]],
    [ "Umspannwerk Völs",[11.312787406060533, 47.258711169040076]],
    [ "106104895",[12.982814641101468, 47.79622182604356]],
    [ "Umspannwerk Hinterglemm",[12.592579122222142, 47.37363937140804]],
    [ "Umspannwerk Waldegg",[16.055785532367366, 47.867712298493714]],
    [ "Gluckgasse",[15.465803732981598, 47.05760154923571]],
    [ "Schaltanlage Flurgasse 15",[15.45262787419436, 47.05221926151721]],
    [ "Umspannwerk Kirchberg",[15.880440954533917, 48.427860301506776]],
    [ "Umspannwerk Graz-West",[15.41952121189834, 47.06573366344315]],
    [ "Unterwerk Stadion Liebenau",[15.453744646305726, 47.04761726738042]],
    [ "NÖ Netz EVN Gruppe",[15.85521105, 48.32954975]],
    [ "Umspannwerk Steinkogl",[13.739224403054788, 47.78417543792788]],
    [ "Energie AG",[13.574539535945668, 47.58999178195997]],
    [ "Energie AG",[13.5353224, 47.58752044999999]],
    [ "Umspannwerk Sarasdorf",[16.695964727999105, 48.03194296689241]],
    [ "Unterwerk Wörgl",[12.0719971, 47.499485449999995]],
    [ "Unterwerk Absdorf",[15.993509324099845, 48.39843724656072]],
    [ "Linz AG",[14.273035040811354, 48.28330722932476]],
    [ "107542187",[14.253962173934594, 48.29316164977891]],
    [ "Linz AG",[14.234466915878334, 48.27727860405681]],
    [ "Umspannwerk Neubad",[16.36770717186792, 48.20967100089993]],
    [ "Nussbaumerstrasse",[15.4719687, 47.0558301]],
    [ "Umspannwerk Maishofen",[12.816847198250061, 47.34799823059826]],
    [ "TST Rohrbach Steinbruch",[16.05180405, 47.7274736]],
    [ "108778640",[13.148895759035636, 47.317729977329506]],
    [ "Energie AG",[13.544048277766198, 47.5850198386117]],
    [ "Linz AG",[14.253466436397503, 48.2472896261738]],
    [ "ÖBB-Infrastruktur AG",[13.026267543408485, 47.807687250336194]],
    [ "Umspannwerk Traunfall",[13.803722797092986, 48.019085976897934]],
    [ "109778121",[14.36760185, 48.252374149999994]],
    [ "109830154",[14.00785685, 48.19520659999999]],
    [ "Buchkirchen Hörling",[14.015767156125143, 48.21860756567313]],
    [ "109847018",[14.015926011424318, 48.1911738782332]],
    [ "TST Schrattenbach Schlossteich",[16.00502230961687, 47.78384100687728]],
    [ "110166361",[14.01345845, 48.17919405000001]],
    [ "Umspannwerk Graz-Webling",[15.418233383262802, 47.03432248821909]],
    [ "TINETZ-Tiroler Netze GmbH",[12.072255832494758, 47.50601754720682]],
    [ "112481895",[12.87818721798809, 47.421837473475975]],
    [ "Salzburg Netz",[13.024862397090596, 47.84131670513641]],
    [ "Umspannwerk Krumau",[15.442981816575257, 48.58985343634367]],
    [ "Hilkering",[13.97438500129302, 48.36694263814653]],
    [ "114410922",[13.081743864454843, 47.294776120409196]],
    [ "114410951",[13.076474919879661, 47.29430994573616]],
    [ "114437795",[13.133636321714775, 47.3092409678677]],
    [ "Stifting Bruecke",[15.506640083316183, 47.0983445911617]],
    [ "Umspannwerk Gisingen",[9.584535473587884, 47.252095944536485]],
    [ "Umspannwerk Rieden",[9.737158634167484, 47.48702188059157]],
    [ "Energie AG",[14.259222076089644, 47.997411090782634]],
    [ "Salzburg AG",[13.015608049999999, 47.847756749999995]],
    [ "TINETZ-Tiroler Netze GmbH",[12.25022012169557, 47.522755497021315]],
    [ "115687177",[13.026206079981867, 47.84915631566815]],
    [ "Umspannwerk Andelsbuch",[9.877509600093772, 47.4013659869672]],
    [ "Linz AG",[14.346316597849011, 48.32910399639315]],
    [ "116657224",[13.017902031727424, 47.85197385083617]],
    [ "Salzburg Netz",[13.04705989077324, 47.846294127659526]],
    [ "EnergieAG",[13.824447874946328, 47.96206905467769]],
    [ "Klagenfurt Ost",[14.33560645, 46.6306524]],
    [ "Energie AG",[14.248672987722296, 47.89354358664785]],
    [ "TST Winzendorf Prosseth",[16.109872316584575, 47.81307656870337]],
    [ "Umspannwerk Süd",[16.330837003337923, 48.16515915619317]],
    [ "Austrian Power Grid AG",[15.855122559218634, 48.373180982980784]],
    [ "Umspannwerk Lenzing",[13.619932514297602, 47.98346175552273]],
    [ "Umrichterwerk Timelkam",[13.599542342126412, 47.99355348424553]],
    [ "Umspannwerk Lambach",[13.899871674608526, 48.10347392950119]],
    [ "Linz AG",[14.317643862991247, 48.237457037500185]],
    [ "118248006",[10.196989136375418, 47.3640734804554]],
    [ "Energie AG",[13.467716764557908, 47.92540360327973]],
    [ "Umspannwerk Pama",[17.026461341567735, 48.03916140301872]],
    [ "Umspannwerk Gmünd",[14.989110538702358, 48.76166245933396]],
    [ "119058902",[13.206038690724021, 47.33027067786949]],
    [ "Unterwerk Mutters",[11.378578232158105, 47.2286075009806]],
    [ "119315425",[13.182907418243344, 47.898966953251545]],
    [ "Umspannwerk Herzogenburg",[15.711773422336924, 48.28453539696154]],
    [ "Kabelüberführungsstation 400kV-1GW-Nordeinspeisung",[16.39588476839894, 48.29705727997018]],
    [ "Energie Ried",[13.54368701208496, 48.28830190778113]],
    [ "Umspannwerk Nenzing",[9.713283009092454, 47.191231326688815]],
    [ "119881910",[14.2975306, 48.22990119999999]],
    [ "119895165",[14.30160845, 48.22770105]],
    [ "Linz AG STROM",[14.305354687482012, 48.22271993506988]],
    [ "Trafo Haid Maderspergerstraße",[14.256170002635221, 48.203395182776326]],
    [ "Enzianstraße",[14.26204447434637, 48.202386728378784]],
    [ "Trafo Haid Mondweg",[14.267207454567657, 48.208904981251116]],
    [ "120085265",[14.413366057742977, 46.65171335035101]],
    [ "Energie AG",[14.126011336799335, 48.161942892406344]],
    [ "Transformator Zeughausstraße",[14.291713561832141, 48.225792170200364]],
    [ "Trafo Im Talland",[14.287068849999999, 48.22207220000001]],
    [ "Trafo Gutenbergstraße",[14.294108549999999, 48.223314650000006]],
    [ "Trafo Tannenweg",[14.296186016883112, 48.22471047229669]],
    [ "Trafo Nelkenweg",[14.282719349999999, 48.22165834999999]],
    [ "Tarsdorf Säge",[12.826005127467585, 48.08355744546101]],
    [ "Salzburg AG",[13.063938799999999, 47.780670650000005]],
    [ "Netz NÖ",[16.11412461717945, 47.942764546376225]],
    [ "120656641",[14.34376608867964, 47.719208769350914]],
    [ "Energie AG",[13.52863426710769, 48.45539346158173]],
    [ "Umspannwerk Hall",[11.498219586049354, 47.27479698766386]],
    [ "Energie AG",[13.543533589815766, 48.42319446488228]],
    [ "Unterwerk Pettneu",[10.362652454355036, 47.14872636674105]],
    [ "Energie Ried",[13.551599812909679, 48.253839835538365]],
    [ "121818456",[13.58845195219969, 48.2641397500624]],
    [ "KNG-Kärnten Netz GmbH",[13.871362049717048, 46.64463513336064]],
    [ "121991215",[12.415721300000003, 47.5185987]],
    [ "Linz AG",[14.346612400000001, 48.252973399999995]],
    [ "Robert Fuchsstrasse",[15.413120599999997, 47.0441669]],
    [ "Umspannwerk Roßbach",[13.27180724587954, 48.19739245161709]],
    [ "Wiener Netze",[16.312918212936722, 48.16408486691962]],
    [ "Umspannwerk Lengau",[13.231856921045019, 47.98781540326648]],
    [ "Umspannwerk Gundertshausen",[12.991498694786562, 48.09655617319491]],
    [ "Schneegattern Riedel",[13.293256548112675, 48.02675059327403]],
    [ "Umspannwerk Dürnrohr",[15.879927208955777, 48.32960365102126]],
    [ "KNG-Kärnten Netz GmbH",[13.861641743317435, 46.81717773860562]],
    [ "122630187",[14.364537015291031, 48.256323280348646]],
    [ "Umspannwerk Ebreichsdorf",[16.374756913421983, 47.972409336561526]],
    [ "Umspannwerk Erlaufboden",[15.266402000120863, 47.88024923327839]],
    [ "TST Pitten Feldstraße",[16.17703385, 47.7124924]],
    [ "122819468",[13.107147346228041, 47.17224982862674]],
    [ "Umspannwerk Kindberg/VOEST Alpine",[15.431167962706427, 47.49941159023089]],
    [ "Umspannwerk Pressbaum",[16.06934245373887, 48.17775660038008]],
    [ "Umspannwerk Petronell",[16.864905833061442, 48.09571368937142]],
    [ "122901147",[13.24235483815217, 47.33220627255348]],
    [ "Umspannwerk Schachen",[15.8706846674, 47.39685849871501]],
    [ "Sparchner Steg",[12.18320955, 47.5952199]],
    [ "122960621",[16.626062071467867, 47.81377249009851]],
    [ "Schaltstelle Kroisbach",[15.45904578886768, 47.09041094021033]],
    [ "Umspannwerk Innerfragant",[13.051915599136397, 46.977459299107316]],
    [ "Wiener Netze",[16.438147940136776, 48.18068861344157]],
    [ "Umspannwerk Wöllersdorf",[16.18672719629624, 47.848953684110775]],
    [ "Stadtwerke Kufstein",[12.178900354774306, 47.59139826932738]],
    [ "Energie Steiermark",[15.455123350000003, 47.09098745]],
    [ "Umspannwerk Lustenau",[9.674868981623542, 47.435014644924]],
    [ "Umspannwerk Rodundwerk I",[9.881058272693066, 47.084444703245424]],
    [ "Umspannwerk Vermuntwerk",[10.055546010841544, 46.96903837705607]],
    [ "Umspannwerk Lünerseewerk",[9.87375832535737, 47.0732148184405]],
    [ "KNG-Kärnten Netz GmbH",[13.816654565733067, 46.62998403532351]],
    [ "Umspannwerk Haselstauden",[9.748621587366541, 47.432890898503395]],
    [ "Umspannwerk Bockfließ",[16.625516987277756, 48.33836472033577]],
    [ "Umspannwerk Hohenems",[9.668884895276847, 47.36647574839189]],
    [ "Energie AG",[14.367764673916625, 48.22178465206747]],
    [ "Energie AG",[14.388288397839952, 48.223167950781]],
    [ "Energie AG",[14.37950615481838, 48.227065912057974]],
    [ "Energie AG",[14.397722637511054, 48.21468530054122]],
    [ "124014612",[14.37126365, 48.20544245000001]],
    [ "124014617",[14.369020699999998, 48.204002749999994]],
    [ "KNG-Kärnten Netz GmbH",[14.17576309887588, 46.75286029177617]],
    [ "124051637",[14.363568822988526, 48.205496531558296]],
    [ "Wiener Netze",[16.31163758186308, 48.08799454702208]],
    [ "124190711",[14.372234063177471, 48.203110529564256]],
    [ "Energie AG",[12.811539434973202, 48.09107171400375]],
    [ "Netz Niederösterreich GmbH",[16.0731444391895, 47.93903841704524]],
    [ "Umspannwerk Vorchdorf",[13.906568853575303, 48.0046400092599]],
    [ "Netz Niederösterreich GmbH",[16.05572603969267, 48.6422471855965]],
    [ "Umspannwerk Hütte Ost",[14.345169516195725, 48.276922307029615]],
    [ "Umspannwerk Hütte Nord",[14.342160033927104, 48.28355141729325]],
    [ "KNG-Kärnten Netz GmbH",[14.162302409260136, 46.61745691750148]],
    [ "Unterwerk Telfes",[11.360098100637883, 47.16520823348554]],
    [ "125156380",[12.52740555154985, 47.18176711690003]],
    [ "Unterwerk Goldschlagstraße",[16.33823405292696, 48.19943961321598]],
    [ "Energie AG",[14.053801832151665, 48.28453035073792]],
    [ "Alkoven Schule",[14.105801983751531, 48.28542602300515]],
    [ "Energie AG",[14.116984063135288, 48.28760040816673]],
    [ "Energie AG",[14.10857172557972, 48.28412850498797]],
    [ "Straß bei Emling",[14.076397828141154, 48.28240512054301]],
    [ "125735661",[12.483517392068144, 47.29208265551747]],
    [ "Energie AG",[14.287803555496865, 48.52381360687283]],
    [ "Umspannwerk Linz Ost Bau 102",[14.33062696043712, 48.289267357586276]],
    [ "EWW",[14.013010208141917, 48.18132516331011]],
    [ "126047694",[14.006934748801129, 48.17769528349297]],
    [ "Linz AG",[14.13396295258723, 48.27954703669174]],
    [ "Energie AG",[14.145573018676492, 48.28707490361186]],
    [ "Energie AG",[14.027266669724543, 48.28186264083923]],
    [ "126682143",[15.781988857786795, 46.739028652604716]],
    [ "126826624",[15.88481671908406, 46.736105105668116]],
    [ "Umspannwerk Annaberg",[13.4229725, 47.53748435000001]],
    [ "127200692",[15.774369151387669, 46.71319825004455]],
    [ "127200694",[15.798579050171972, 46.72713551798293]],
    [ "Linz AG",[14.956738950648814, 48.28107411162783]],
    [ "Umspannwerk Galgenul",[9.965940123142282, 47.02085391973669]],
    [ "Umspannwerk Rifawerk",[10.0430278, 46.9745038]],
    [ "Energie Steiermark",[15.4285179, 47.106130400000005]],
    [ "E-Werk Bad Radkersburg",[15.995536524913096, 46.68807674245518]],
    [ "127991948",[14.718301006119448, 48.27268630175727]],
    [ "Linz AG",[14.766155340413292, 48.26349570224765]],
    [ "Linz AG",[14.753708522154446, 48.2669171197502]],
    [ "128057502",[14.001118250000001, 48.15520645]],
    [ "Umspannwerk Wattstraße",[14.266895016919598, 48.246102060960965]],
    [ "Energie AG",[13.777215623807516, 47.80106396631672]],
    [ "128150816",[13.683351782473519, 47.858875248774915]],
    [ "128153329",[13.602843969487731, 47.72284285626025]],
    [ "Unterwerk Rohr",[15.430067420991055, 48.18764081818577]],
    [ "Umspannwerk Schöller",[16.03121228703747, 47.71042280900193]],
    [ "Unterwerk Schlöglmühl",[15.914073752661414, 47.682402116822544]],
    [ "Umspannwerk Korneuburg",[16.32338245, 48.357670000000006]],
    [ "Umspannwerk Wieselburg",[15.134238656957054, 48.11092738893019]],
    [ "Linz AG",[14.705642083964861, 48.232154800312685]],
    [ "Umformerwerk Auhof",[16.236466671663404, 48.199761960588056]],
    [ "Linz AG",[14.944023122132505, 48.27652287965654]],
    [ "KNG-Kärnten Netz GmbH",[14.353707374441779, 46.96799887039416]],
    [ "UPM Steyrermühl",[13.79866790057354, 47.99161478292703]],
    [ "Reininghaus Impulszentrum",[15.408070918884151, 47.06504476617493]],
    [ "Wiener Netze",[16.396341550000002, 47.97561975]],
    [ "Linz AG",[14.707259955625071, 48.22721054555949]],
    [ "Umspannwerk Mistelbach",[16.57578416782903, 48.56080551292396]],
    [ "Trafo Pergkirchen",[14.678042510248945, 48.247230895906846]],
    [ "128890334",[13.407285916366483, 47.49032057821507]],
    [ "Unterwerk Schladming",[13.678419371410055, 47.39426059808065]],
    [ "Energie AG",[14.00801887847136, 48.30851235979842]],
    [ "Unterwerk Alte Poststraße/Eggenberger Straße",[15.411187363855207, 47.0704753943188]],
    [ "Klaus Trafostation 9 Hauptschule",[9.6461061, 47.30508494999999]],
    [ "Wels Strom",[14.02433877105466, 48.17043510419273]],
    [ "Umspannwerk Tschagguns",[9.916490203858029, 47.06879062342196]],
    [ "TST Stuppach Stuppachgraben",[15.95796684519209, 47.68108156534819]],
    [ "129793786",[14.007522999999997, 48.17079905]],
    [ "129872174",[13.123795340381628, 47.71471055029973]],
    [ "129972551",[13.128579027951051, 47.70956534390397]],
    [ "Linz AG",[14.70253289183519, 48.20017700110376]],
    [ "130000275",[14.403903322403401, 48.236685255509215]],
    [ "KNG-Kärnten Netz GmbH",[13.586782916621596, 46.6094955827706]],
    [ "KNG-Kärnten Netz GmbH",[13.604443152992275, 46.60123215470182]],
    [ "Umspannwerk Büchsengut",[15.208347190073047, 47.520943088891954]],
    [ "KNG-Kärnten Netz GmbH",[13.582148296274196, 46.597686184833485]],
    [ "130446553",[14.002975000000001, 48.19349135]],
    [ "130459410",[13.99913005, 48.187245]],
    [ "KNG-Kärnten Netz GmbH",[14.375307187533187, 46.85732285247518]],
    [ "Salzburg AG",[12.991057944153836, 47.77762147085752]],
    [ "KNG-Kärnten Netz GmbH",[13.617802162599403, 46.62674593251108]],
    [ "Stadtwerke Kufstein",[12.154666779713724, 47.58963250613289]],
    [ "KNG-Kärnten Netz GmbH",[13.60913151645623, 46.58081942225554]],
    [ "Unterwerk Landeck",[10.586441605988462, 47.15229717009091]],
    [ "130895622",[14.407162162640832, 48.20859842483776]],
    [ "Energie AG",[14.414000339445835, 48.22108803874324]],
    [ "Energie AG",[14.424861456049936, 48.198493923182724]],
    [ "Stadtwerke Kufstein",[12.180382369746988, 47.59074274337062]],
    [ "Umspannwerk Süd",[12.153621949999998, 47.574282049999994]],
    [ "Netz Oberösterreich",[13.690347154842609, 47.522874805476654]],
    [ "131513862",[14.306374720581907, 48.04553386661862]],
    [ "Rüb 05",[12.167568817915209, 47.586963365939255]],
    [ "131613029",[13.434449411941104, 47.54667406947378]],
    [ "Unterwerk Karl-Huber-Gasse",[15.463899799280702, 47.04321486766726]],
    [ "EBNER STROM GmbH",[14.479627841783005, 48.31762505918649]],
    [ "Energie AG",[14.383412585531897, 48.198334787386685]],
    [ "Engelsdorf Muehle",[15.457025737380485, 47.03128063519815]],
    [ "132377763",[12.707021896235204, 47.56644579829706]],
    [ "Umspannwerk Teufenbach",[14.363302558388101, 47.134296472123005]],
    [ "132467388",[14.011392249999998, 48.2009351]],
    [ "KNG-Kärnten Netz GmbH",[13.766133546999335, 46.6312126040364]],
    [ "132550284",[11.870442192315661, 47.1564926524449]],
    [ "KNG-Kärnten Netz GmbH",[14.15221381505309, 46.59049157997644]],
    [ "Energie AG",[14.380351860431137, 48.17851662147266]],
    [ "Energie AG",[14.37942177580483, 48.14348590140227]],
    [ "Energie AG",[14.37499171745427, 48.149638411544224]],
    [ "Energie AG",[14.423692550993252, 48.1650825935891]],
    [ "Netzgebäude Linz AG / Umspannwerk Linz Wienerstraße",[14.308390449999997, 48.28219565]],
    [ "133177962",[13.43086305408853, 47.428475574212186]],
    [ "Umspannwerk Weißgerber",[16.39110022600726, 48.205446572133035]],
    [ "TST Neunkirchen Mühlfeld",[16.083306910524975, 47.71610216593236]],
    [ "Energie AG",[14.123395856418863, 48.29296359311667]],
    [ "133488344",[13.412035177834431, 47.54758304190181]],
    [ "Salzburg AG",[13.076336714400327, 47.38292283871538]],
    [ "Salzburg AG",[13.082981352781383, 47.38871081078406]],
    [ "Netz NÖ",[16.602898933400688, 47.969568551080556]],
    [ "KNG-Kärnten Netz GmbH",[13.831574293272167, 46.616860866360845]],
    [ "Salzburg AG",[13.0704482, 47.80800399999999]],
    [ "134158103",[13.06508205, 47.81653775]],
    [ "TST Neunkirchen Eltzgasse",[16.0775648, 47.722786]],
    [ "Kloiberweg",[15.46441295, 47.023691549999995]],
    [ "Pumpwerk J.M. Fussenengger",[9.676674754785406, 47.40693727116104]],
    [ "Trafostation Hallwang",[13.077470745645325, 47.82182179906024]],
    [ "Energie Steiermark",[15.464338914524154, 47.02148352376885]],
    [ "Kabelüberführungsstation Pfarrgasse",[16.34062265943335, 48.136314996639236]],
    [ "Energie AG",[13.58793682844382, 48.008034880250996]],
    [ "134792573",[13.195893489275127, 47.351904074993264]],
    [ "134940879",[13.40241705637552, 47.55892823701022]],
    [ "134940901",[13.397660313074436, 47.55993716914122]],
    [ "Umspannwerk Kraftwerk Donaustadt",[16.46444643545261, 48.19982474778196]],
    [ "TST Payerbach Mitte",[15.863069827388802, 47.69247849559606]],
    [ "Wiener Netze",[16.2901976, 48.11427975]],
    [ "Salzburg AG",[13.77583328930025, 46.978954327045315]],
    [ "Salzburg AG",[13.776147500749282, 46.982971347723016]],
    [ "135694042",[13.934360350151758, 47.997221150128816]],
    [ "Eichbachgasse Siedlung",[15.463739604174313, 47.01560819919967]],
    [ "Energie Steiermark",[15.466316202282709, 47.01905434756201]],
    [ "Umspannwerk Franzosenhausweg",[14.299216628105432, 48.242420137651926]],
    [ "135964452",[13.941205434103532, 47.99848114435715]],
    [ "136112666",[13.184655340158896, 47.32088249629137]],
    [ "Umspannwerk Scharndorf",[16.83787827808156, 48.0810819325818]],
    [ "Umspannstation Rohrbach",[13.984093104517777, 48.5685137604554]],
    [ "SalzburgNetz",[13.372666104707223, 47.56118958253338]],
    [ "Pambergergasse",[15.473031624648174, 47.02594211492286]],
    [ "Jaegerweg",[15.471654052474829, 47.02851614665638]],
    [ "TINETZ",[12.476985891522082, 46.73084324374428]],
    [ "TINETZ",[12.47986030669475, 46.736955631136084]],
    [ "TINETZ",[12.495574793361394, 46.72592160222082]],
    [ "Energie AG",[14.162324699831972, 48.29673812826537]],
    [ "Energie AG",[14.1524233531248, 48.29217015082137]],
    [ "Esserweg",[15.469285183884478, 47.03328952827347]],
    [ "136907794",[13.08497129798133, 47.7803285483643]],
    [ "136912304",[13.079881517114005, 47.329008103521915]],
    [ "136921637",[13.080946262433653, 47.793911577184055]],
    [ "Salzburg AG",[13.15924889219317, 47.80307514667426]],
    [ "137032487",[14.297039548113474, 48.22343037204922]],
    [ "Linz AG STROM",[14.302701100000002, 48.1942765]],
    [ "Trafo Ansfelden Bahnhof",[14.26284195, 48.210075950000004]],
    [ "Trafo Haid Schleusenweg",[14.249298832934329, 48.20899264692102]],
    [ "Trafo Haid Wohnpark",[14.252998531842193, 48.208046206454206]],
    [ "Trafo Haid Chem. Ind.",[14.2598234, 48.206599000000004]],
    [ "Trafo Haid Schule",[14.254323399234945, 48.20410131680808]],
    [ "Haibach",[13.914404767051211, 48.410044699950916]],
    [ "Trafo Nettingsdorf Schellingstraße",[14.260258600000002, 48.17654735]],
    [ "137699319",[14.270075570307135, 48.19065565934986]],
    [ "Ansfelden Moosleite",[14.264558099783333, 48.187546046823364]],
    [ "Trafo Nettingsdorf Moos",[14.263309253547888, 48.180256099517194]],
    [ "137699905",[14.278784095066294, 48.18828420052299]],
    [ "137719627",[14.285667671856748, 48.18973415424928]],
    [ "Trafo Haid Schärfstraße",[14.259183850000003, 48.20580035]],
    [ "Trafo Haid Heimstätte",[14.252439533137082, 48.20220704954982]],
    [ "Umspannwerk Ettendorf",[14.956087128191427, 46.672918030678694]],
    [ "Karling Siedlung",[13.989624024101941, 48.353771749850914]],
    [ "Hainbach",[13.992244790163642, 48.37747699389132]],
    [ "Würting",[13.979412359029322, 48.39826805935595]],
    [ "138156990",[14.25678094187203, 48.17786919347573]],
    [ "Umspannwerk Linz-Industrie",[14.324008872053941, 48.29200088146462]],
    [ "Energie AG",[13.90090557824023, 48.39034480207506]],
    [ "138359161",[13.877467750000003, 48.38713584999999]],
    [ "Energie AG",[13.874996741124148, 48.38516789497144]],
    [ "Energie AG",[13.883166834909977, 48.387602046233326]],
    [ "Salzburg AG",[13.137026713568705, 47.324815624433306]],
    [ "Umspannwerk Simmering",[16.430302149091585, 48.182716065626174]],
    [ "Energie AG",[13.867564462928778, 48.34836622363786]],
    [ "Energie Steiermark",[15.48612299584959, 47.05179527919145]],
    [ "Petersbergen II",[15.495211399999997, 47.05450185000001]],
    [ "138459580",[13.854379203378546, 48.333349310199395]],
    [ "Nettingsdorf Ort",[14.254439625252555, 48.18850913146701]],
    [ "Planersdlg",[14.246586566197493, 48.19321936568032]],
    [ "Trafo Nettingsdorf Lagerhaus",[14.2586027800474, 48.186693735744704]],
    [ "Ansfelden Ritzlhofstraße",[14.272623353477757, 48.20365267449555]],
    [ "Laah",[14.271564151555944, 48.206024749990235]],
    [ "Kremsdorf Sdlg Süd",[14.255031492839446, 48.19484025047565]],
    [ "TINETZ",[12.666687310318446, 46.70248359771755]],
    [ "140232949",[13.988050525569209, 47.94558120618618]],
    [ "Energie AG",[13.484781699999997, 47.566460299999996]],
    [ "ÖBB-Infrastruktur AG",[13.404054876212827, 46.8350413755895]],
    [ "SF6-Halle 380 kV",[11.897824772897781, 47.234132790259466]],
    [ "Scheigergasse",[15.464109694675205, 47.04780883409088]],
    [ "Energie AG",[14.028998167464746, 48.28010880538024]],
    [ "Energie AG",[14.037332488711439, 48.2934577538655]],
    [ "Energie AG",[14.0071523362564, 48.281220735917465]],
    [ "Energie AG",[14.032936161299803, 48.28649501851239]],
    [ "140654541",[14.06667401173362, 48.02505192137171]],
    [ "Trafo Wambach",[14.31326329554867, 48.21203430134522]],
    [ "141606594",[12.183484046455353, 47.22736031652413]],
    [ "EWW",[13.999518250000001, 48.1650738]],
    [ "Umspannwerk Wels FHKW",[14.006807431647493, 48.152066088411]],
    [ "Unterwerk Dölsach",[12.832199952414058, 46.81495901477754]],
    [ "Nettingsdorf Bahnhof",[14.256253592136868, 48.19107849723239]],
    [ "Neukirchen a.V. Wimm",[13.540581332581327, 48.04526481711688]],
    [ "Trafo Wasserwerk Haid",[14.24335025184108, 48.20131599648625]],
    [ "Trafo Hasenufer",[14.230223835622738, 48.19820547029897]],
    [ "Umspannstation Haid",[14.250151166228406, 48.202088551355246]],
    [ "Umspannwerk Wiener Neustadt Flugfeld",[16.24605446017921, 47.8363939341909]],
    [ "Linz AG",[14.946276999198844, 48.24546312442823]],
    [ "Energie AG",[14.365023678968578, 48.19804230887697]],
    [ "Stewag Steg",[15.978367390494927, 46.70770697061968]],
    [ "Linz AG",[14.277657160774172, 48.412905458978834]],
    [ "Trafo Haid Wasserwerkstraße",[14.244919317654663, 48.1980256024954]],
    [ "143819640",[14.447339296764026, 48.18491853957671]],
    [ "143828044",[13.20025325, 47.3500354]],
    [ "143835695",[13.206482602847144, 47.34209715283169]],
    [ "143970704",[13.822174579850483, 47.98586644572639]],
    [ "Energie AG",[14.026462315437135, 48.32622939106579]],
    [ "Netz OÖ",[13.82228597628165, 47.98212024533022]],
    [ "Vöcklabruck Fadingerstr.",[13.653531771503323, 48.00389490019246]],
    [ "Rufling Burgwallstraße",[14.21650997615634, 48.28093579434816]],
    [ "144132189",[16.02920423810686, 46.685653653031025]],
    [ "Kraftwerk Ternberg",[14.352945251357601, 47.942030230458435]],
    [ "Energie AG",[14.00567935832765, 48.297274028053764]],
    [ "Energie AG",[14.022484839441553, 48.29034800083703]],
    [ "Salzburg Netz",[12.983975219235921, 47.93166808454948]],
    [ "Salzburg AG",[13.286264550661372, 47.71475524929043]],
    [ "KNG-Kärnten Netz GmbH",[13.941375543594347, 46.594256208835525]],
    [ "KNG-Kärnten Netz GmbH",[13.915133774846991, 46.59700563016968]],
    [ "Kärnten Netz",[13.847310545576219, 46.6154536332411]],
    [ "144460636",[13.072357830495783, 47.78491203431073]],
    [ "Mondsee Hilfberg",[13.353580584288553, 47.85569125187261]],
    [ "144723082",[13.023420940600143, 47.80013948048526]],
    [ "Salzburg AG",[13.039039830875064, 47.79777351881044]],
    [ "Umformerwerk Haiming/Ötztal",[10.8743900889315, 47.246503102969044]],
    [ "Umspannwerk Vill",[11.392455147609397, 47.236318689021445]],
    [ "Linz AG",[14.2610523612836, 48.31382786259534]],
    [ "Salzburg AG",[12.8441974, 47.4318613]],
    [ "Schaltwerk Schönberg",[11.391582457937668, 47.201248724926735]],
    [ "146411796",[13.73052570896934, 47.87162286952055]],
    [ "146442368",[14.138820803777008, 47.884165267152284]],
    [ "146608840",[14.2733551, 48.323723799999996]],
    [ "146623047",[13.003311390707532, 47.764486678758374]],
    [ "Josef-Pock-Straße",[15.406147700497236, 47.09113368330145]],
    [ "Messendorf Autalerstrasse",[15.496956295557743, 47.03723122607498]],
    [ "Umspannwerk Graz Gösting/Nord",[15.416041080261506, 47.094487638353286]],
    [ "Energie AG",[14.277034412220157, 48.33253129024426]],
    [ "147252166",[13.767528650000001, 47.862204199999994]],
    [ "147436851",[14.305749162261089, 46.63346531735473]],
    [ "Umspannwerk Zirl",[11.21462154616306, 47.27923457911699]],
    [ "Unterwerk Zirl",[11.221323015622737, 47.26547719098537]],
    [ "Salzburg AG",[13.199406829388922, 47.85713010350867]],
    [ "Salzburg AG",[13.1747476358455, 47.866125449099144]],
    [ "Energie AG",[14.253574678724195, 48.13750026008748]],
    [ "Energie AG",[13.348454280144487, 47.854389410437555]],
    [ "Schule St. Leonhard",[14.681732781285842, 48.4444582401912]],
    [ "Linz AG Strom",[14.298629793661927, 48.33393506945158]],
    [ "148269800",[14.020138864685109, 48.35988518155512]],
    [ "Unterlandshaag",[14.03084882700342, 48.358413423265205]],
    [ "UST Lienz Alleestraße",[12.75852708122512, 46.82997720295314]],
    [ "148577864",[14.30581479869856, 48.332952355840256]],
    [ "Energie AG",[14.131351756674755, 48.23463971794354]],
    [ "Energie AG",[14.148221275349906, 48.2230642477075]],
    [ "Energie AG",[14.137480371192614, 48.25539866336007]],
    [ "Energie AG",[14.143486697093984, 48.24559493637913]],
    [ "Energie AG",[14.117027551896086, 48.227695934120476]],
    [ "Salzburg AG",[13.036447015795726, 47.79744807431593]],
    [ "Linz AG Strom",[14.31421498356495, 48.330479919501116]],
    [ "Linz AG Strom",[14.310278572750638, 48.32834483333333]],
    [ "149003438",[14.095321496083814, 47.92066288389735]],
    [ "Energie AG",[14.271357416627094, 48.11995394207506]],
    [ "149230686",[14.048508955222816, 48.166184382419814]],
    [ "Energie AG",[13.347957627560442, 47.85618811626301]],
    [ "Mondsee Prielhofstr.",[13.3444237040972, 47.857368506896286]],
    [ "149539442",[14.02930111201846, 48.08283636972385]],
    [ "149606289",[14.049883419828005, 48.09725163259765]],
    [ "Linz AG",[14.33254784072861, 48.343045992799766]],
    [ "149867719",[12.966404374905588, 47.78614511502618]],
    [ "Linz AG",[14.27698140743488, 48.3404992043981]],
    [ "150011419",[12.9679079382228, 47.78916884185937]],
    [ "KNG-Kärnten Netz GmbH",[13.801002941818167, 46.61750308480308]],
    [ "KNG-Kärnten Netz GmbH",[13.803817552029484, 46.62255179441369]],
    [ "Mondsee Hierzenbergerstr.",[13.345101159783724, 47.85357449404011]],
    [ "150428791",[14.291432323915801, 48.377685700522505]],
    [ "Vöcklabruck Würzburgerweg",[13.661535137641739, 48.00781886662492]],
    [ "Salzburg AG",[12.97860915, 47.78770205000001]],
    [ "KNG-Kärnten Netz GmbH",[13.704743739437221, 46.53972767046373]],
    [ "Salzburg AG",[13.081154634130147, 47.8501393676014]],
    [ "Umspannwerk Schwaz",[11.700698079085209, 47.352393212090604]],
    [ "TST Pitten Altagasse",[16.186455490534268, 47.72449511450011]],
    [ "151121856",[14.223252611405968, 48.13666543011468]],
    [ "151215158",[14.30067533964207, 48.33527732021763]],
    [ "151278227",[13.663681993423129, 47.14258645992902]],
    [ "Salzburg AG",[12.898660262754039, 47.97335191796342]],
    [ "KWG",[13.772638893084446, 48.09680965950194]],
    [ "KNG-Kärnten Netz GmbH",[13.686192836320682, 46.53865110556386]],
    [ "KNG-Kärnten Netz GmbH",[13.699768408429199, 46.54454219583358]],
    [ "Energie AG",[13.728615932741699, 48.50929054712194]],
    [ "KNG-Kärnten Netz GmbH",[13.807768650591262, 46.60915859541561]],
    [ "Salzburg Netz",[12.991779749420829, 47.95207839998846]],
    [ "KNG-Kärnten Netz GmbH",[13.823536975341456, 46.60334773971479]],
    [ "KNG-Kärnten Netz GmbH",[13.818855916923416, 46.60422369340889]],
    [ "Umspannwerk Gurk",[14.30318471402913, 46.8783424051713]],
    [ "Umspannwerk Wietersdorf",[14.538375511817021, 46.84138685300547]],
    [ "KNG-Kärnten Netz GmbH",[14.538282263755248, 46.841609186599506]],
    [ "KNG-Kärnten Netz GmbH",[13.725542497171297, 46.80050782502822]],
    [ "KNG-Kärnten Netz GmbH",[13.736494372576615, 46.797071822077825]],
    [ "Salzburg AG",[13.133568015622913, 47.376250011471775]],
    [ "Energie AG",[14.149870702598966, 48.199242595601]],
    [ "Energie AG",[14.155283829840037, 48.19834035203571]],
    [ "Energie AG",[14.128972574820537, 48.191522580193976]],
    [ "Energie AG",[14.11094099366176, 48.18948015200282]],
    [ "Energie AG",[14.14255572234347, 48.194745803773564]],
    [ "KNG-Kärnten Netz GmbH",[14.460763011665986, 46.57003818146546]],
    [ "Umspannwerk Klaus",[14.163206843506996, 47.82139138630443]],
    [ "Umspannwerk St. Stephan",[15.728193005698186, 46.88312310016779]],
    [ "Umspannwerk Unterrettenbach",[15.844375620932814, 47.1105794053884]],
    [ "TINETZ-Tiroler Netze GmbH",[12.819549431639869, 46.82903632886344]],
    [ "152959766",[14.226484750000001, 48.025344000000004]],
    [ "Salzburg AG",[12.882405147201244, 47.99346324516061]],
    [ "Salzburg AG",[12.883443197386041, 47.990719733942875]],
    [ "Umspannwerk Sankt Margareten",[14.395749496801367, 46.545088800084606]],
    [ "Buchleiten",[13.664901115036981, 48.01388962647553]],
    [ "Salzburg AG",[13.024606187074829, 47.813339721768706]],
    [ "Linz AG Strom",[14.309578640013365, 48.334035071353135]],
    [ "Netz OÖ",[13.918994010921384, 48.22892163181508]],
    [ "Linz Netz",[14.522550599999999, 48.371481200000005]],
    [ "155272881",[14.321583950000003, 48.329513250000005]],
    [ "155272897",[14.321386300000002, 48.33234709999999]],
    [ "Umspannwerk Fisching",[14.723267738485877, 47.17421128895831]],
    [ "155422632",[13.158100667029315, 47.48974354903943]],
    [ "Linz AG",[14.190583393247552, 48.26893095175678]],
    [ "Linz AG",[14.201792577256265, 48.26918205395366]],
    [ "Linz AG",[14.145549925935855, 48.26959579676043]],
    [ "Linz AG",[14.160826190689649, 48.26364983106475]],
    [ "Linz AG",[14.321919804446832, 48.35928094128958]],
    [ "Linz AG",[14.321432076505598, 48.36876832503297]],
    [ "Linz AG",[14.312682608837353, 48.36687900152236]],
    [ "Salzburg AG",[13.096414050000002, 47.696938749999994]],
    [ "155763689",[13.09904496286075, 47.703774934366216]],
    [ "Linz AG",[14.315660450000001, 48.372785949999994]],
    [ "Salzburg AG",[13.095480104260837, 47.70485891831295]],
    [ "Energie AG",[14.060450429626638, 48.21237945463909]],
    [ "Jebenstein",[14.109264427195786, 48.218644693810674]],
    [ "Energie AG",[14.087339741354974, 48.21592483838889]],
    [ "KNG-Kärnten Netz GmbH",[13.811246922158716, 46.62156903631385]],
    [ "Linz AG",[14.340600185083662, 48.367538325334806]],
    [ "Linz AG",[14.3164453425966, 48.38317536491569]],
    [ "Energie AG",[14.016957425061433, 48.23206526581306]],
    [ "Energie AG",[13.994831057830867, 48.24486927795241]],
    [ "Schnadt",[14.005691385483415, 48.230834771819]],
    [ "Herrnholz",[14.010342147037392, 48.25858659806271]],
    [ "Energie AG",[13.99291033669952, 48.26313313669296]],
    [ "Energie AG",[14.00331858411161, 48.20976657051488]],
    [ "Energie AG",[14.117522081298528, 48.283568396484554]],
    [ "156311593",[13.98121655, 48.17924320000001]],
    [ "Netz OÖ",[14.151510101209789, 48.31442565053389]],
    [ "Energie AG",[14.126910885790542, 48.29912090250691]],
    [ "Energie AG",[13.94780757599722, 48.57021435591808]],
    [ "Energie AG",[13.980329927349421, 48.57227247122958]],
    [ "Kraftwerk Plankenau",[13.184819922173991, 47.32096654501693]],
    [ "Netz Niederösterreich GmbH",[16.2491667, 47.84277445000001]],
    [ "156698533",[14.091745041873951, 48.206756803335374]],
    [ "156698534",[14.09135745003337, 48.19952099995992]],
    [ "Salzburg AG",[13.08877988520029, 47.70508174381486]],
    [ "156840654",[13.100051140032456, 47.72689352600049]],
    [ "Umspannwerk Zell am Ziller",[11.897844427519983, 47.23344415562435]],
    [ "Umspannwerk Brixen",[12.275691887794036, 47.458207627275435]],
    [ "Salzburg AG",[13.110646398268035, 47.80234955808642]],
    [ "Wiener Netze",[16.404397873137, 47.96850726379403]],
    [ "Wiener Netze",[16.41002661314324, 47.97366772890485]],
    [ "Hohenrainstrasse - OEWG Siedlung",[15.496021419015875, 47.06164737252136]],
    [ "Salzburg AG",[13.098453450000001, 47.6879769]],
    [ "Traun Schusterfeldstraße",[14.248044250000001, 48.2271061]],
    [ "Linz AG",[14.272145868420672, 48.38120267911083]],
    [ "Linz AG",[14.26604471264242, 48.37617469693624]],
    [ "Linz AG",[14.26820253684924, 48.36881619996349]],
    [ "List",[12.182922618109314, 47.58717005212542]],
    [ "158250432",[14.1066978431906, 48.19006641723154]],
    [ "Energie AG",[14.276542496682184, 47.98462972738119]],
    [ "Energie AG",[14.11357008504221, 48.19189462351122]],
    [ "158558763",[13.209210121786544, 47.36462714407653]],
    [ "158671504",[14.1242049517576, 48.19322654842104]],
    [ "Salzburg Netz",[13.03995024094559, 47.83198986113913]],
    [ "Linz AG",[14.346625527451982, 48.37044094754298]],
    [ "159531835",[13.210471602122327, 47.35121174971907]],
    [ "159538653",[14.1161968, 48.1958894]],
    [ "Mondsee",[13.34775325054723, 47.85930966292769]],
    [ "159555241",[13.219428846444464, 47.377851887584114]],
    [ "Linz AG Strom",[14.3664347, 48.373912250000004]],
    [ "Linz AG",[14.352173464736877, 48.372981999038586]],
    [ "Linz AG",[14.355426716205178, 48.37461979555913]],
    [ "Linz AG",[14.375406032893121, 48.3733662977839]],
    [ "Linz AG",[14.3476524, 48.376562549999996]],
    [ "TST Günselsdorf",[16.260786017247725, 47.9435597479522]],
    [ "Linz AG",[14.342456549272335, 48.380942455263195]],
    [ "Linz AG",[14.333130353442792, 48.37636658079408]],
    [ "Energie AG",[14.198353765846425, 47.86427175034618]],
    [ "Salzburg Netz",[13.057934418213634, 47.86196134814286]],
    [ "Sierning - Untermühlen",[14.31371609978485, 48.05011075017073]],
    [ "Linz AG",[14.31816384405962, 48.39211940189885]],
    [ "Energie AG",[13.913405290918536, 48.57181274023431]],
    [ "Energie AG",[14.016620184179857, 48.52841771307756]],
    [ "Kappern Siedlung",[14.127824287957965, 48.18830157649468]],
    [ "KNG-Kärnten Netz GmbH",[13.646300342948997, 46.542681799040125]],
    [ "160489309",[13.129649742258092, 47.69597374977592]],
    [ "160523793",[13.132195837537717, 47.695674129367845]],
    [ "160526057",[13.144637400184244, 47.68848655054723]],
    [ "160527033",[13.13415538695101, 47.68944948875303]],
    [ "160567379",[13.133926547570974, 47.70154354885721]],
    [ "160567389",[13.141019756567424, 47.70602306650099]],
    [ "160567390",[13.162586431569599, 47.68920985048422]],
    [ "160568267",[13.145955453238098, 47.7075585697933]],
    [ "160576531",[13.17395673903622, 47.32586906739268]],
    [ "Linz AG",[14.24998433329667, 48.30211839954986]],
    [ "Linz AG",[14.348000441066445, 48.39662449433399]],
    [ "Umspannwerk Feiting",[15.601873186173114, 46.911139462120815]],
    [ "Trafo Ansfelden Birkensiedlung",[14.262683500000001, 48.212692049999994]],
    [ "Trafo Ansfelden Gärtnerstraße",[14.262975401072454, 48.21475233352431]],
    [ "Trafo Haid Traunuferstraße",[14.245630295980268, 48.20592632075506]],
    [ "Energie AG",[14.385396552709029, 48.380027125561725]],
    [ "Energie AG",[14.230982499524647, 48.20352238570143]],
    [ "161572496",[13.17647917386019, 47.71553442201883]],
    [ "Linz AG",[14.345511543238699, 48.338214752944765]],
    [ "161643062",[13.17250497741079, 47.70860510548834]],
    [ "Wiener Netze",[14.771504401019893, 47.8508166469437]],
    [ "Linz AG",[14.367387499999998, 48.33722900000001]],
    [ "Linz AG",[14.267976478100067, 48.35141755927406]],
    [ "Linz AG",[14.376205523231569, 48.341186419380044]],
    [ "Linz AG",[14.368894627524131, 48.344656431354814]],
    [ "KNG-Kärnten Netz GmbH",[13.657908461918966, 46.55341896860955]],
    [ "St. Georgen i. A. Ort UST",[13.48854298144592, 47.93627130254037]],
    [ "Linz AG",[14.260032345543113, 48.345600420975025]],
    [ "162600956",[14.116069983914372, 48.183328711709706]],
    [ "162601662",[13.008939849999999, 47.877191999999994]],
    [ "Linz AG",[14.398840244806872, 48.33966254220552]],
    [ "Linz AG",[14.392399885923291, 48.343376679237835]],
    [ "Linz AG",[14.386662036144463, 48.348272946902426]],
    [ "Lienz",[12.805760952375598, 46.82504402201394]],
    [ "St. Georgen Süd",[13.487731306452833, 47.93119593505963]],
    [ "Energie AG",[13.499244288355433, 47.92607431721903]],
    [ "Hartkirchen (Deinham)",[14.010400801651297, 48.35067698540158]],
    [ "Linz AG",[14.341622989732505, 48.3344657302711]],
    [ "Energie AG",[13.34347365305819, 47.86106262621509]],
    [ "Trafo Haid Garagenstraße",[14.252951171471386, 48.20603951465309]],
    [ "Trafo Haid Schulstraße",[14.25319715, 48.20505215000001]],
    [ "Salzburg AG",[13.12598815, 47.48005135]],
    [ "KNG-Kärnten Netz GmbH",[13.626576294157497, 46.561193264047546]],
    [ "KNG-Kärnten Netz GmbH",[13.634584395192237, 46.56052480073631]],
    [ "Linz AG",[14.377862080892319, 48.32610424187424]],
    [ "LKH Portierhaus",[14.309983980852634, 46.63277061890465]],
    [ "163691549",[13.080967202390099, 47.75222895050159]],
    [ "Linz AG",[14.25860580801054, 48.353984072109554]],
    [ "163844185",[14.267663149999997, 47.97044294999999]],
    [ "Trafo Haid Lawog",[14.251381220088769, 48.20337323542191]],
    [ "Energie AG",[13.238418786709214, 48.2488875374729]],
    [ "Hofwimmerstraße",[14.132426059749967, 48.19380998022238]],
    [ "Salzburg Netz",[13.0994811, 47.9687963]],
    [ "Salzburg AG",[13.104956012044264, 47.972042674405856]],
    [ "Trafo Haid Amtsgebäude",[14.249499600000002, 48.2053951]],
    [ "Stadtwerke Judenburg AG",[14.636970370102405, 47.17441339899508]],
    [ "Energie AG",[14.032279159821307, 47.89242647517406]],
    [ "Bahnhofstraße",[9.66008215, 47.44485165]],
    [ "164694928",[13.089279230927136, 47.74266540356065]],
    [ "Salzburg AG",[13.30635000062869, 47.79065779873837]],
    [ "KNG-Kärnten Netz GmbH",[13.809107364447778, 46.6150784193523]],
    [ "EAG",[13.564775887922215, 47.84154481623031]],
    [ "Energie AG",[14.114795863843794, 48.253761182726485]],
    [ "Kranzing",[14.088629659027639, 48.249450157196634]],
    [ "Energie AG",[14.069162203519317, 48.23516432835855]],
    [ "Energie AG",[13.477997549812791, 48.41494789993011]],
    [ "Linz AG",[14.258826766526461, 48.362199585810195]],
    [ "TINETZ-Stromnetz Tirol AG",[11.89454970687001, 47.44174297624051]],
    [ "165813810",[14.302220756179219, 48.52039558612086]],
    [ "Linz AG STROM",[14.286647649999999, 48.203710550000004]],
    [ "165953598",[15.447120771944668, 47.096290645793054]],
    [ "Mariagruen",[15.4503495, 47.09897525]],
    [ "Blümigen",[13.553859967502161, 47.84186012852173]],
    [ "Netz Niederösterreich GmbH",[16.245879004354908, 47.83915293366909]],
    [ "Linz AG",[14.248963949634389, 48.371899442610896]],
    [ "TST Großarl Thalgau",[13.216419899999998, 47.233694650000004]],
    [ "Umspannwerk Wels Nord",[14.00820399651047, 48.16896111723002]],
    [ "Energie Steiermark",[15.436365346497782, 47.10163367395427]],
    [ "Wiener Netze",[16.321961012584822, 48.090782233036215]],
    [ "Alexenau",[13.571425750611397, 47.87539235590034]],
    [ "EAG",[13.574294021676996, 47.90148335628951]],
    [ "Managettaweg II",[15.468721408701843, 47.06872668401754]],
    [ "Oberlaab - Kumelgut",[14.02336248327286, 48.199096098821734]],
    [ "Linz AG",[14.238974577184326, 48.34759351449593]],
    [ "Linz AG",[14.244510392609266, 48.345415630388985]],
    [ "Energie Klagenfurt GmbH",[14.319279677922813, 46.619809234122265]],
    [ "KNG-Kärnten Netz GmbH",[13.505545669404633, 46.952114366538915]],
    [ "167258070",[14.303997700996513, 46.618224236708684]],
    [ "Scharfling",[13.395170881981956, 47.79783832889437]],
    [ "Kroisbach Studentenheim",[15.466288107534401, 47.09392878353796]],
    [ "Wiesingergasse",[15.4630293, 47.09391959999999]],
    [ "Gmunden Klosterplatz",[13.804297990125283, 47.91734858816238]],
    [ "Salzburg AG",[13.089154737939598, 47.7089840276281]],
    [ "Salzburg AG",[13.087159149224194, 47.71357547246626]],
    [ "168258903",[13.092175923849478, 47.712779370545626]],
    [ "Energie AG",[14.069475722260965, 47.887575032401095]],
    [ "168555559",[13.094985434209269, 47.71414808316005]],
    [ "Wiener Netze",[16.43081923006731, 48.08538513917888]],
    [ "168856494",[14.241525249999999, 48.312985950000005]],
    [ "KNG-Kärnten Netz GmbH",[13.825269111899953, 46.63169697388115]],
    [ "KNG-Kärnten Netz GmbH",[13.813457694879869, 46.636500983693146]],
    [ "KNG-Kärnten Netz GmbH",[13.814145195218229, 46.6272707938325]],
    [ "Salzburg AG",[13.052568605479921, 47.79244808236339]],
    [ "Umspannwerk Feldkirch",[9.589550804334943, 47.232697506448716]],
    [ "Umspannwerk Götzis",[9.629616066348028, 47.33442139024408]],
    [ "Salzburg AG",[13.032746867895042, 47.793540663871255]],
    [ "169485310",[14.354132562929054, 47.656202474115666]],
    [ "Wiener Netze",[16.322659563156442, 48.08958902659877]],
    [ "Salzburg AG",[13.07538066406792, 47.9383247766052]],
    [ "Mondsee Prielhofsdlg.",[13.337111685202997, 47.85830454654985]],
    [ "KNG-Kärnten Netz GmbH",[13.886401414782844, 46.86666071137152]],
    [ "KNG-Kärnten Netz GmbH",[13.888876105552553, 46.879699084647505]],
    [ "Salzburg Netz",[13.124081482737237, 47.955362286434294]],
    [ "Energie AG",[13.527640600978641, 47.577181316696446]],
    [ "Energie AG",[13.534975396022766, 47.5853096668514]],
    [ "Umspannwerk Zurndorf",[16.994533021838112, 47.93769817446617]],
    [ "Umspannwerk Stadlau",[16.458726418564122, 48.226919423319046]],
    [ "171196540",[13.112294678437733, 47.666220734215074]],
    [ "TINETZ",[11.875606699999999, 47.4421975]],
    [ "Energie AG",[14.039505250805199, 48.22903534128047]],
    [ "Energie AG",[14.041004238693386, 48.21966246094295]],
    [ "Unterwerk Tullnerfeld",[16.02825401462092, 48.29454025636436]],
    [ "KNG-Kärnten Netz GmbH",[14.674664199049253, 46.93562590100053]],
    [ "Energie AG",[13.339584502724078, 47.856660257306935]],
    [ "Klagenfurt Nord",[14.3155644632959, 46.62992681216982]],
    [ "Salzburg AG",[13.001348494379384, 47.81095629607263]],
    [ "TST Joching",[15.4601592, 48.3850078]],
    [ "KNG-Kärnten Netz GmbH",[13.607125108768715, 46.570517711474395]],
    [ "KNG-Kärnten Netz GmbH",[13.177583786998532, 46.97814378286082]],
    [ "Mallnitz Rabbisch",[13.179442945261357, 46.97459296746117]],
    [ "172962356",[15.423529962149564, 47.08240114100821]],
    [ "Energie AG",[14.189513644953816, 48.32267676960899]],
    [ "Salzburg AG",[12.766645999152376, 47.44101468393307]],
    [ "Klagenfurt Süd",[14.321083476960338, 46.60701049090879]],
    [ "Umspannwerk Leopoldstadt",[16.371944349215457, 48.2236281452775]],
    [ "173654067",[13.697060364615226, 47.11001661740293]],
    [ "Salzburg AG",[13.683908150985724, 47.12379246052869]],
    [ "Salzburg AG",[13.008567981427934, 47.79672784683066]],
    [ "174092625",[13.094736260050935, 47.66496905334574]],
    [ "Salzburg AG",[13.093981699999999, 47.984111350000006]],
    [ "Umspannwerk Schönkirchen",[16.693326524706926, 48.351501827890225]],
    [ "Umspannwerk Andau",[17.042393113135496, 47.81607449647425]],
    [ "Umspannwerk Baumgartenberg",[14.760134040576638, 48.20566401343585]],
    [ "Schaltstelle Ries / Tannhof",[15.493800904262326, 47.09280129577435]],
    [ "Grossgrabenweg",[15.479895949999998, 47.0887023]],
    [ "Wiener Netze",[16.318363438701137, 48.1029790465708]],
    [ "Energie AG",[13.956651281258885, 48.20537465314725]],
    [ "Sauldorf",[13.094329413273892, 48.038046172237074]],
    [ "Salzburg AG",[13.029080742872729, 47.34322698699981]],
    [ "Salzburg AG",[13.011869403198661, 47.34377264843242]],
    [ "Wiener Netze",[16.3840945, 47.87388775]],
    [ "Salzburg AG",[13.05661151360306, 47.39095794984391]],
    [ "Wiener Netze GmbH",[16.393405169529338, 47.879436008614285]],
    [ "Gärtnerstraße",[9.65647443100316, 47.428125854340124]],
    [ "175696392",[13.017252435784401, 48.01627681566738]],
    [ "Kirchstraße",[9.6526106, 47.4268541]],
    [ "Salzburg AG",[13.0320218, 47.79439985]],
    [ "Wiener Netze",[16.3279337, 48.0799542]],
    [ "KNG-Kärnten Netz GmbH",[14.037558414456283, 46.5732854236907]],
    [ "KNG-Kärnten Netz GmbH",[13.173905742172314, 46.98215683017461]],
    [ "KNG-Kärnten Netz GmbH",[14.226998510260767, 46.47981950502005]],
    [ "Salzburg AG",[13.03699799555119, 47.79867136603909]],
    [ "KNG-Kärnten Netz GmbH",[14.857566964643334, 46.81309445555307]],
    [ "177789829",[12.93532828952482, 48.089097867399566]],
    [ "Energie AG",[13.083791316796711, 48.00853469847455]],
    [ "Energie AG",[13.120446569613494, 48.15439472969306]],
    [ "KNG-Kärnten Netz GmbH",[13.49613961803866, 46.82623450115506]],
    [ "Energie Steiermark",[15.525237182949006, 47.10514559875776]],
    [ "178200817",[15.49698929885961, 47.087876357093705]],
    [ "Schaltstelle Ragnitz",[15.498969801652997, 47.07646302550375]],
    [ "EWW",[13.996582397450611, 48.173218466906604]],
    [ "Lieseregg Siedlung",[13.49933111715792, 46.829351224253585]],
    [ "Umspannwerk Währing",[16.334234449916934, 48.227201440041156]],
    [ "KNG-Kärnten Netz GmbH",[13.590770332428658, 46.575390675568435]],
    [ "KNG-Kärnten Netz GmbH",[13.60221667097258, 46.5754206275792]],
    [ "179160605",[14.00321866078748, 48.16608196930687]],
    [ "KNG-Kärnten Netz GmbH",[13.168534989408558, 46.98933218310974]],
    [ "Freihofanger",[15.467726299999997, 47.097154950000004]],
    [ "Schaltstelle Tannhof",[15.4882124, 47.103241649999994]],
    [ "Salzburg Netz",[12.932135410568756, 47.943151705145034]],
    [ "180186742",[12.945737999999999, 47.21626685]],
    [ "180186747",[12.971758140069191, 47.153951971282474]],
    [ "Umspannwerk Greuth",[13.679812286068142, 46.54319159528694]],
    [ "KNG-Kärnten Netz GmbH",[13.82975405, 46.61139085]],
    [ "Salzburg AG",[13.108185650000001, 47.96773285]],
    [ "Salzburg Netz",[12.93630015, 47.94646559999999]],
    [ "Salzburg Netz",[12.94279599838695, 47.94855876560258]],
    [ "182319687",[13.048057858967718, 48.08507798107581]],
    [ "KNG-Kärnten Netz GmbH",[13.21359152167084, 46.928885185364265]],
    [ "Netz OÖ",[14.470436248202576, 48.13347811699311]],
    [ "Obervellach-West",[13.198249362081153, 46.935682455698085]],
    [ "KNG-Kärnten Netz GmbH",[13.2329324745659, 46.922382606010466]],
    [ "Kärnten Netz",[13.260199153452321, 46.89949450980443]],
    [ "Salzburg Netz",[12.943224350000001, 47.941332200000005]],
    [ "Kärnten Netz",[13.268793654689011, 46.88605894406101]],
    [ "KNG-Kärnten Netz GmbH",[13.3039219607182, 46.87679572044392]],
    [ "Salzburg Netz",[12.947295700000002, 47.9374361]],
    [ "Salzburg Netz",[12.942232683408742, 47.93596075029649]],
    [ "SST Eibiswald",[15.249679177158995, 46.69145807156379]],
    [ "KNG-Kärnten Netz GmbH",[14.812792651422363, 46.94451164623198]],
    [ "Energie AG",[14.01054333978455, 48.36697122418446]],
    [ "Unterwerk Wetzelsdorf",[15.392810101615646, 47.06068712542517]],
    [ "Salzburg Netz",[12.949796950000001, 47.942171800000004]],
    [ "Salzburg AG",[13.168504509549747, 47.82245050637073]],
    [ "Salzburg AG",[13.16965404486467, 47.8185545399266]],
    [ "Energie AG",[14.237516638366014, 48.464992108547676]],
    [ "185836528",[14.22841875, 48.4639613]],
    [ "Energie AG",[14.22743007511049, 48.460353557761806]],
    [ "Umspannwerk Wagenham",[13.103596143292936, 48.09898817221889]],
    [ "Energie AG",[14.224497442811268, 48.464470903568234]],
    [ "Umspannwerk Enns-Industrie",[14.494959515842023, 48.23465411505016]],
    [ "Linz AG",[14.408382349977565, 48.35894524922656]],
    [ "Netz NÖ",[15.825940663690714, 47.639544770848836]],
    [ "Energie AG",[14.267692905845186, 48.46574861713857]],
    [ "187164791",[14.271536758158222, 48.46198059333735]],
    [ "SST Wegkreuzung Allerheiligen",[15.536162199191278, 46.91148099976008]],
    [ "187767515",[13.15123933110079, 47.81627501638384]],
    [ "187767516",[13.157613068054868, 47.808917105964476]],
    [ "Salzburg AG",[13.154886669249386, 47.8108310987348]],
    [ "Energie AG",[14.110484874315622, 48.27901769494223]],
    [ "188145014",[14.259083, 47.88690555]],
    [ "KNG-Kärnten Netz GmbH",[14.812065900166528, 46.94864746584078]],
    [ "Salzburg AG",[12.933876448584845, 47.94788760001581]],
    [ "KNG-Kärnten Netz GmbH",[14.704138435189446, 46.55907538649213]],
    [ "189139935",[13.1333456, 47.66887864999999]],
    [ "189139936",[13.13900854949964, 47.664776916965955]],
    [ "189139938",[13.13152644576172, 47.66423988204139]],
    [ "189142545",[13.128046329522855, 47.674957303238905]],
    [ "KNG-Kärnten Netz GmbH",[14.552140457981677, 46.79117981337888]],
    [ "189256628",[13.1344031, 47.70681705]],
    [ "189266226",[13.135313799999997, 47.71123699999999]],
    [ "189470501",[15.934174651669833, 46.775918722192436]],
    [ "KNG-Kärnten Netz GmbH",[13.757630017823846, 46.633647651990685]],
    [ "KNG-Kärnten Netz GmbH",[13.73954667458571, 46.6172051304965]],
    [ "KNG-Kärnten Netz GmbH",[13.765422220534834, 46.650590139273255]],
    [ "KNG-Kärnten Netz GmbH",[14.950199542700759, 46.662563555375584]],
    [ "E-Werk Perg",[14.674519028679054, 48.280760348039195]],
    [ "Trafo Pragtal",[14.659998068609099, 48.263653893412915]],
    [ "E-Werk Perg",[14.65235349343035, 48.27389986804994]],
    [ "Linz AG",[14.686349899395116, 48.268782341347084]],
    [ "Linz AG",[14.69481672978826, 48.268776976917486]],
    [ "KNG-Kärnten Netz GmbH",[13.645104117297674, 46.62934183154923]],
    [ "Linz AG",[14.287156934208083, 48.45390845434429]],
    [ "E-Werk Perg",[14.630510862561955, 48.27615565527997]],
    [ "Umspannwerk Dornbirn Süd",[9.73198143997647, 47.40982515693262]],
    [ "Strass i. A.",[13.459826132490532, 47.9111388792045]],
    [ "Energie AG",[13.975182987832575, 48.194684449537796]],
    [ "Wiener Netze",[16.28666300803566, 48.109267774090746]],
    [ "191189333",[13.143268086212474, 47.66097133000447]],
    [ "Linz AG",[14.271077623487082, 48.452125035682144]],
    [ "Linz AG",[14.299155531347695, 48.45859017005496]],
    [ "191361639",[13.121791570429234, 47.65396316373467]],
    [ "191536354",[14.67875512493178, 48.29550229871141]],
    [ "Linz AG",[14.70775879430662, 48.299396955652405]],
    [ "191566994",[13.06419762546953, 47.71686782526945]],
    [ "KNG-Kärnten Netz GmbH",[14.892663127129286, 46.79243454886683]],
    [ "191676510",[14.733457135101885, 48.31478280828448]],
    [ "Wiener Netze",[16.18821631565099, 47.918235848142245]],
    [ "191912858",[14.808437669315603, 48.25851923139273]],
    [ "191927561",[13.064676949999999, 47.722035899999995]],
    [ "Salzburg AG",[13.010574899999998, 47.809750599999994]],
    [ "192062619",[14.804379221569281, 48.268883999773465]],
    [ "192075632",[14.806882486902541, 48.26321685904535]],
    [ "192146312",[13.759446465829791, 47.878358907210625]],
    [ "192523103",[13.074819127416175, 47.72835505437212]],
    [ "192645959",[13.77055005, 47.85854234999999]],
    [ "192733904",[13.065405800165532, 47.84323635025128]],
    [ "193580585",[13.772306734977395, 47.92431974222094]],
    [ "Umspannwerk Rauz",[10.179462864462085, 47.13800504875978]],
    [ "193629955",[14.805171899999998, 48.23785529999999]],
    [ "Linz AG",[14.778343004082648, 48.25527657819024]],
    [ "Energie AG",[13.854116631965752, 48.19925424527829]],
    [ "Energie AG",[13.888974426954926, 48.2043034832344]],
    [ "Salzburg AG",[13.060591679170951, 47.75054858511561]],
    [ "194435086",[14.810632350924612, 48.248534250270936]],
    [ "194808960",[14.815054332741148, 48.26142312690431]],
    [ "Weiberfelderweg",[15.395058350000001, 47.02810235]],
    [ "Umspannwerk Schwechat",[16.507042743279893, 48.135823061967415]],
    [ "Unterwerk Reith",[11.198373901861492, 47.31974340777395]],
    [ "Unterwerk Werndorf",[15.476503888088546, 46.91636095043615]],
    [ "Umspannwerk Pinggau",[16.08086826610193, 47.426229616383814]],
    [ "Linz AG",[14.9088288963152, 48.30210868118984]],
    [ "195192858",[14.871689200494925, 48.25475284843879]],
    [ "Wiener Netze",[16.32384095, 48.119706]],
    [ "195261154",[13.01997205, 47.789142000000005]],
    [ "Gurtenbach",[13.345369766022115, 48.32755930263948]],
    [ "Umspannwerk Essling",[16.5248187, 48.2137665]],
    [ "Umspannwerk Landstraße",[16.400354374443957, 48.18573360912744]],
    [ "Umspannwerk Eisenstadtplatz",[16.382617669238215, 48.16519062192489]],
    [ "EWW",[14.02443605, 48.179412850000006]],
    [ "Salzburg Netz",[13.088653594902988, 47.88136750101413]],
    [ "Wiener Netze",[16.330431369461664, 48.1214007056245]],
    [ "198415725",[14.90784926828656, 48.38826345793382]],
    [ "SST Ödenhof",[15.95856116702131, 47.76276359785408]],
    [ "Energie AG",[13.249565685214383, 47.9872793995599]],
    [ "Energie Ried",[13.528746645875774, 48.2411023966828]],
    [ "Schöneben",[13.946906717818022, 48.70513934733952]],
    [ "198832587",[14.871264967306267, 48.36670354416828]],
    [ "198965454",[13.001198684395373, 47.38171959706794]],
    [ "199149333",[13.895246149999997, 48.18754145]],
    [ "199151904",[13.899902407858276, 48.18553214445806]],
    [ "KNG-Kärnten Netz GmbH",[14.79390106735508, 46.58031979879997]],
    [ "199402843",[14.90496092925932, 48.35345408303785]],
    [ "EAG",[13.546735640752319, 47.814150447075804]],
    [ "199700878",[15.389021800000002, 47.0536693]],
    [ "Wiener Netze",[16.615204659239513, 48.11814402210446]],
    [ "Thal",[13.936802368208438, 48.170569965513195]],
    [ "TST Donaudorf 2",[15.061365442530004, 48.19020333320473]],
    [ "Neue Welthöhe",[15.492713354228432, 47.06405671933484]],
    [ "EVN AG",[14.91929711572254, 48.501151004724576]],
    [ "201278842",[13.477007043732673, 47.554354671823354]],
    [ "201320669",[13.14920202981097, 47.82097513776314]],
    [ "201378642",[14.82990476234658, 48.5080188500204]],
    [ "Wenisbuch",[15.474841808444992, 47.12048070320516]],
    [ "Salzburg Netz",[12.98009032407353, 47.80881195446688]],
    [ "Salzburg AG",[13.123397612024803, 47.86618950668358]],
    [ "Braunberg",[13.844544868070345, 48.15059169881391]],
    [ "202079279",[13.041236915512158, 47.72752663276544]],
    [ "Energie AG",[13.921747360683433, 48.158589678817]],
    [ "Energie AG",[13.891217413499152, 48.1576778971427]],
    [ "Umspannwerk Prinzendorf",[16.746856816267695, 48.587393667022475]],
    [ "202457631",[13.91696990120857, 48.102232565952775]],
    [ "202457634",[13.926482298163723, 48.11321184819613]],
    [ "202546540",[13.013338869278558, 47.7501040654588]],
    [ "202577634",[14.147959340848617, 48.55662890581185]],
    [ "202585677",[13.91268972751591, 48.088866249304886]],
    [ "202589293",[13.92252145, 48.09509100000001]],
    [ "202589301",[13.915108595677557, 48.09246610693182]],
    [ "202670541",[15.472392746045363, 47.038445066886474]],
    [ "Wiener Netze GmbH",[16.235469276612122, 47.8747595104168]],
    [ "203144009",[13.274766303375733, 47.45888629943601]],
    [ "203158365",[13.139590432239077, 47.64700798164737]],
    [ "203158367",[13.147917210969927, 47.65430119288521]],
    [ "Salzburg AG",[13.309947417613351, 47.47779254721819]],
    [ "203215313",[13.301557136781227, 47.47489382295466]],
    [ "203232704",[13.156863387222938, 47.63580915296137]],
    [ "E-Werk Perg",[14.618507950000001, 48.2516004]],
    [ "Salzburg AG",[13.021013611277015, 47.81099947070073]],
    [ "203420351",[15.43746045638693, 47.0940626286093]],
    [ "Salzburg AG",[13.00519775, 47.81341285]],
    [ "Klaus",[13.747570306179062, 47.62773450234524]],
    [ "Netz OÖ",[13.768302159440648, 47.62613968514384]],
    [ "E-Werk Perg",[14.651640431180587, 48.24864567689166]],
    [ "204580024",[13.792941461787693, 47.95962447358632]],
    [ "Energie AG",[13.786290779011908, 47.95333344521844]],
    [ "Kerschhoferweg 2",[15.468326600000003, 47.07713305]],
    [ "Linz AG",[14.793485277774439, 48.23047833524384]],
    [ "Salzburg AG",[13.015610366891158, 47.847615750941614]],
    [ "Energie AG",[13.746276889348081, 47.960580038532015]],
    [ "Energie AG",[13.758371235878718, 47.96048551299715]],
    [ "204882157",[13.79548215, 47.9870118]],
    [ "Energie AG",[14.255214389216578, 48.01790442631529]],
    [ "Energie AG",[14.247207454006155, 48.0067495383045]],
    [ "Energie AG",[14.188525568369998, 48.004881593065775]],
    [ "St. Blasien - Schst.",[14.236259121718724, 48.01812814806544]],
    [ "205516761",[14.269455800000001, 48.081034599999995]],
    [ "Energie AG",[14.00377712717832, 48.450218196524425]],
    [ "Energie AG",[13.914390679043912, 48.67495113120893]],
    [ "Otterbach Ind.",[13.450130337704662, 48.45953176998487]],
    [ "Umspannwerk Liesing",[16.297197241054167, 48.13783440919996]],
    [ "206312774",[13.922058742516954, 47.98264414802887]],
    [ "Energie AG",[13.908476660630152, 48.68091157536005]],
    [ "206394519",[14.475707684877449, 48.13012871524095]],
    [ "Waltendorfer Hauptstrasse 206",[15.504862882391699, 47.06879954596144]],
    [ "Ebensee Gartenstr.",[13.772694564161416, 47.8046004440053]],
    [ "Energie AG",[13.736998977768167, 48.22683871414869]],
    [ "207187804",[13.67009081015558, 48.20354438067783]],
    [ "Energie AG",[13.736697971362513, 48.21964731405686]],
    [ "EVN",[14.641712966497655, 48.56214951965259]],
    [ "207430085",[13.105894720292271, 47.19687129749674]],
    [ "207599561",[13.0013182305189, 47.833212654424614]],
    [ "207709871",[13.202411937187758, 47.64373212065852]],
    [ "207710742",[13.207511023527156, 47.650550557579464]],
    [ "207880458",[13.191822239081747, 47.62099255951072]],
    [ "208004899",[13.840125186482231, 48.19398067323153]],
    [ "Krenglbach Forst",[13.968331087240728, 48.19301782459702]],
    [ "Wiener Netze",[16.24391, 48.002564849999985]],
    [ "Umspannwerk Baden",[16.247534750199417, 48.000899116318344]],
    [ "Energie AG",[13.977491317371003, 48.257899514652976]],
    [ "Umspannwerk Schärding",[13.442854236030692, 48.43454641266204]],
    [ "Umspannwerk Messendorf",[15.479089197391419, 47.04358144682744]],
    [ "Energie AG",[14.164866685295204, 48.41252330602173]],
    [ "KNG-Kärnten Netz GmbH",[13.687604289143954, 46.71380725142068]],
    [ "Hörgattern",[13.545402486121198, 48.00023259826266]],
    [ "KNG-Kärnten Netz GmbH",[13.695320318659348, 46.733869478259706]],
    [ "KNG-Kärnten Netz GmbH",[13.731044611362513, 46.69790275037315]],
    [ "Energie AG",[13.797261789901345, 48.13289428321429]],
    [ "211030095",[13.83320320242154, 48.150709417811676]],
    [ "Umspannwerk Auen",[13.859681427910804, 46.59521215476118]],
    [ "KNG-Kärnten Netz GmbH",[13.685319167942662, 46.717591266187625]],
    [ "211425662",[13.656662026936774, 48.09698296403789]],
    [ "KNG-Kärnten Netz GmbH",[14.799546647732955, 46.95976755218749]],
    [ "Pöndorf",[13.369280128629082, 47.99268877166296]],
    [ "Landgraben",[13.360013503195605, 48.000566581613285]],
    [ "Schardenberg",[13.49969218840559, 48.51949637569823]],
    [ "Auleiten",[13.402274158021118, 47.99008791409709]],
    [ "Buchkirchen Schule",[14.01913366067894, 48.222013480021864]],
    [ "Energie AG",[13.953336895241899, 48.3417003493642]],
    [ "Schärding Mitte",[13.435467642500054, 48.45742471243162]],
    [ "Energie AG",[13.825193671870512, 48.227257893164264]],
    [ "Energie AG",[13.821744215962287, 48.22884936733541]],
    [ "Energie AG",[13.81842755714254, 48.211196142593664]],
    [ "KNG-Kärnten Netz GmbH",[13.828366219384865, 46.62676473726191]],
    [ "214745000",[14.296140800000002, 48.43155765]],
    [ "Linz AG",[14.324116646235908, 48.446148124528385]],
    [ "214745012",[14.306888609799609, 48.435046543693005]],
    [ "Linz AG",[14.296064245326288, 48.403778552669344]],
    [ "Linz AG",[14.28798848123981, 48.4056918570109]],
    [ "Linz AG",[14.34881990639907, 48.463441035150495]],
    [ "Linz AG",[14.335771877563237, 48.464698807993116]],
    [ "Energie AG",[14.326142067748416, 48.48574039961834]],
    [ "Linz AG",[14.341529225249557, 48.47274116156158]],
    [ "Linz AG",[14.354294460644333, 48.45457459278701]],
    [ "216067172",[12.980528434636547, 47.78839588100199]],
    [ "Wiener Netze",[16.385297349537378, 47.8625080837255]],
    [ "Salzburg AG",[13.131679003453568, 47.140542907958306]],
    [ "KNG-Kärnten Netz GmbH",[13.604037074167879, 47.029047944048266]],
    [ "KNG-Kärnten Netz GmbH",[13.853863132304575, 46.63306191285969]],
    [ "KNG-Kärnten Netz GmbH",[13.855451300584033, 46.635077082239626]],
    [ "Weinbach",[13.379185066622515, 47.988430597079756]],
    [ "Linz Netz",[14.445469128189183, 48.34588042511292]],
    [ "217630151",[13.1097263, 47.16205985]],
    [ "217721811",[14.403156599999999, 48.022231999999995]],
    [ "Linz AG",[14.4215718, 48.34059115000001]],
    [ "218427102",[13.95061370373532, 48.23249153656927]],
    [ "Salzburg AG",[13.008367075748597, 47.794837944684716]],
    [ "Linz AG",[14.390493952942109, 48.33131302359824]],
    [ "Umspannwerk Wienerberg",[16.353879961411074, 48.16838919392481]],
    [ "218766284",[13.944847650000002, 48.23149565000001]],
    [ "Energie AG",[13.725609425368523, 48.01576827760882]],
    [ "218904213",[12.708501458317484, 47.58895096688981]],
    [ "Energie AG",[13.443711993022815, 48.4301133259911]],
    [ "Seewinkel",[13.778782699999999, 47.8114322]],
    [ "Energie AG",[13.927627399999997, 48.22571455000001]],
    [ "Linz AG",[14.397360806052877, 48.32672533517135]],
    [ "Attersee",[13.534871359771962, 47.914532346591216]],
    [ "Peretseck",[13.306810037838344, 48.11342836952228]],
    [ "Energie AG",[13.92159366976648, 48.23182073164724]],
    [ "Umspannstation Braunau",[13.039430386939571, 48.24916209384126]],
    [ "Energie AG",[13.94621861023806, 48.21999924266647]],
    [ "219882628",[13.41229520185412, 47.95552395333865]],
    [ "Schaltanlage Kopswerk I",[10.054120942036244, 46.968959650404045]],
    [ "Umspannwerk Rodundwerk II",[9.87444994247068, 47.084845982469695]],
    [ "Pößing",[13.445992881262207, 47.94947529340718]],
    [ "Hipping",[13.464976312496729, 47.9514057]],
    [ "219923862",[13.62560691321344, 47.09867938098015]],
    [ "Schüblingen",[13.383770878689006, 47.96137209142377]],
    [ "Wiener Netze",[16.40054533016241, 47.89275684497629]],
    [ "Wiener Netze",[16.404884514910552, 47.89256069394378]],
    [ "Energie AG",[13.966847193091992, 48.63918798361645]],
    [ "Energie AG",[13.956265502977898, 48.63386879367611]],
    [ "Linz AG",[14.42856349614366, 48.32686577238809]],
    [ "220355111",[14.022120546192323, 48.10414580499423]],
    [ "220360601",[13.997498046964235, 48.11270974950247]],
    [ "220360603",[14.018952702849468, 48.11635020101051]],
    [ "Gampern",[13.554841356524713, 47.98824972872453]],
    [ "220456274",[13.056957696780598, 47.296347412277626]],
    [ "KNG-Kärnten Netz GmbH",[14.388373052335206, 46.73863482984152]],
    [ "KNG-Kärnten Netz GmbH",[14.388511466216949, 46.738624223157146]],
    [ "Salzburg AG",[13.059385972722877, 47.807223272485786]],
    [ "Linz AG",[14.436319019832444, 48.35594042019833]],
    [ "Energie AG",[13.590858663221601, 47.71986792752343]],
    [ "Obernberg Hub",[13.337794493989293, 48.311579965208665]],
    [ "Energie AG",[14.123714223679452, 48.061405839352396]],
    [ "Energie AG",[13.898933205079736, 48.14285769414213]],
    [ "Energie AG",[13.564425934936647, 48.00378902692844]],
    [ "221686378",[13.931406933070923, 48.13509428077671]],
    [ "221698919",[13.94576438886303, 48.132897895848885]],
    [ "Oberglanz",[15.518558740558348, 46.67709872929858]],
    [ "KNG-Kärnten Netz GmbH",[13.872422, 46.62014225000001]],
    [ "Salzburg Netz",[13.103495909532578, 47.984204021665455]],
    [ "KNG-Kärnten Netz GmbH",[14.256517587242062, 46.665699952269826]],
    [ "Wiener Netze",[16.295468195014383, 48.039735223329046]],
    [ "Energie AG",[13.765070703838687, 48.25131375248282]],
    [ "222258368",[13.760683267073393, 48.24833370206711]],
    [ "Wiener Netze",[16.295108589326162, 48.007232779724674]],
    [ "Trafo Unterschnadt",[14.237067233325543, 48.1779745500826]],
    [ "Energie AG",[13.833956808617057, 48.23409126933061]],
    [ "Netz OÖ",[13.941809148930894, 48.13228836670797]],
    [ "Wiener Netze",[16.534443905320515, 48.07657468459358]],
    [ "KNG-Kärnten Netz GmbH",[13.909943099060962, 46.55600348625876]],
    [ "Wiener Netze",[16.135252629872635, 48.19399304724408]],
    [ "222934941",[15.754002877910416, 48.665608161321444]],
    [ "Energie Ried",[13.53644176525582, 48.21383495836056]],
    [ "Energie Ried",[13.549477346822695, 48.237089801025085]],
    [ "Edenhub",[13.560373049061823, 48.2339825274461]],
    [ "Energie Ried",[13.527653146535068, 48.22562239660773]],
    [ "Energie AG",[13.97328193876538, 48.216911197528574]],
    [ "Kitzing",[13.972323018525794, 48.22580081828029]],
    [ "Energie AG",[13.9676562, 48.232163299999996]],
    [ "223705799",[13.948575505615882, 48.20325663244784]],
    [ "Netz OÖ",[13.723705850000002, 48.013285999999994]],
    [ "Hüttenedt",[13.35246504118149, 47.95629321423454]],
    [ "Schrankbaum",[13.375956505229684, 47.963610199750406]],
    [ "Frankenmarkt Wimm",[13.41566341570153, 47.97499571520778]],
    [ "Energie AG",[13.90643370029077, 48.2302473552897]],
    [ "Energie AG",[13.80292533297209, 48.24577076605455]],
    [ "Energie AG",[13.844970721848927, 48.255318838787595]],
    [ "Energie AG",[13.880603794214826, 48.253103380316695]],
    [ "Ebensee Wasserwerk",[13.781449215364418, 47.80025008992104]],
    [ "Energie AG",[13.775949299999999, 47.8093983]],
    [ "Ebensee Steinbruchgasse",[13.768996413075447, 47.80154462111403]],
    [ "Energie AG",[13.768583207219285, 47.798302052174066]],
    [ "Umspannwerk Mariahilf",[16.361011089366464, 48.20115468031795]],
    [ "225009947",[13.394650583002102, 47.47584920106535]],
    [ "225009948",[13.387422353920872, 47.467131859393035]],
    [ "225029391",[14.102609097912156, 48.0619417973918]],
    [ "Salzburg AG",[12.9837662726771, 47.78904078489185]],
    [ "KNG-Kärnten Netz GmbH",[13.994062596752594, 46.65177616991781]],
    [ "KNG-Kärnten Netz GmbH",[13.945532947135442, 46.64012431933225]],
    [ "KNG-Kärnten Netz GmbH",[13.960478486927265, 46.659083305432674]],
    [ "KNG-Kärnten Netz GmbH",[13.933119600239879, 46.65265085010604]],
    [ "KNG-Kärnten Netz GmbH",[13.944292985073478, 46.65701706166477]],
    [ "KNG-Kärnten Netz GmbH",[13.969317161549869, 46.65964412874111]],
    [ "Ebensee Solvaystr.",[13.772672489487613, 47.80747619348765]],
    [ "KNG-Kärnten Netz GmbH",[12.885263353369128, 46.68587635306482]],
    [ "TST Reichenau Hochfeld",[15.828924740178838, 47.70249425393679]],
    [ "KNG-Kärnten Netz GmbH",[12.975153630085515, 46.679707100534216]],
    [ "225365801",[14.082207686643335, 48.06952909098781]],
    [ "KNG-Kärnten Netz GmbH",[12.841658240045456, 46.68817422198162]],
    [ "KNG-Kärnten Netz GmbH",[12.771325593752511, 46.70069451488928]],
    [ "225586596",[12.992793773018676, 47.22979858747978]],
    [ "225693066",[13.150813961063454, 47.635660138264065]],
    [ "Energie AG",[13.879570942182275, 48.22060449937543]],
    [ "Trafo Mitterberg",[14.665235900496807, 48.256271670508006]],
    [ "Netz OÖ",[13.738385981756462, 48.0238450404417]],
    [ "Netz OÖ",[13.729479845970681, 48.019807202216114]],
    [ "Netz OÖ",[14.132324236257137, 48.04463924826284]],
    [ "225889087",[13.140839200000002, 47.631181350000006]],
    [ "Transformatorenturmstation Moos",[13.142998032277207, 47.63218574556071]],
    [ "KNG-Kärnten Netz GmbH",[13.278443396013024, 46.60198644291739]],
    [ "KNG-Kärnten Netz GmbH",[12.968028899999998, 46.74217795]],
    [ "Filzmoos Süd",[13.52242221932262, 47.43247725361423]],
    [ "Energie AG",[14.178723900323089, 48.04901017908841]],
    [ "Energie AG",[14.156271281039547, 48.04652025579091]],
    [ "226279390",[13.709827339885871, 48.02152297349689]],
    [ "Energie AG",[13.695776465197044, 47.992486891327886]],
    [ "226279392",[13.717235831837616, 48.01430697555681]],
    [ "Preising Wankhamerstr.",[13.70840644992247, 47.99360805011275]],
    [ "226280945",[13.708808999981352, 48.0079963982471]],
    [ "226280946",[13.712790019071452, 48.00809339257633]],
    [ "Linz AG",[14.472984300684622, 48.534665960675326]],
    [ "Attnang Berufsschule",[13.7258142345151, 48.020631560933744]],
    [ "Salzburg AG",[13.813293834565364, 47.12555523285422]],
    [ "Salzburg Netz",[13.084485687809035, 47.83710704715437]],
    [ "226556833",[13.715809628253599, 48.00384775135065]],
    [ "Energie AG",[14.157531214833035, 48.05700399414395]],
    [ "Salzburg Netz",[13.08794325, 47.83955495000001]],
    [ "Salzburg Netz",[13.06023028747468, 47.8723658132104]],
    [ "Salzburg Netz",[13.062816647479673, 47.86749896207695]],
    [ "Linz AG",[14.362951306993061, 48.41902058308183]],
    [ "Linz AG",[14.358282589445793, 48.426584904913504]],
    [ "Energie AG",[13.714314228339472, 48.005967251565615]],
    [ "Linz AG",[14.349205109322277, 48.41945919611404]],
    [ "KNG-Kärnten Netz GmbH",[12.898530299999997, 46.96459814999999]],
    [ "KNG-Kärnten Netz GmbH",[12.86707429373932, 47.01738550730173]],
    [ "Wiener Netze",[16.51312235, 48.05191404999999]],
    [ "Lohnsburg",[13.417638199169405, 48.14856409287975]],
    [ "Salzburg AG",[12.826813793394662, 47.2305358574705]],
    [ "Linz AG",[14.380040757492257, 48.4111178938799]],
    [ "Umspannwerk Leoben-West",[15.082318721103332, 47.37271432591021]],
    [ "Bramberg Ort",[12.348916599999999, 47.272830250000005]],
    [ "228358324",[12.818413818941842, 47.287760002039136]],
    [ "KNG-Kärnten Netz GmbH",[13.90441566178748, 46.611792948230566]],
    [ "Netz OÖ",[13.74549834419332, 48.01616244066774]],
    [ "Energie AG",[13.343503546787794, 48.15155589185309]],
    [ "228728823",[13.739332449999997, 47.8565475]],
    [ "228730209",[13.76115253747785, 47.86652592682832]],
    [ "Umspannwerk Aspern",[16.49574320760271, 48.21813479258214]],
    [ "228736168",[13.718106419543988, 47.87467560555204]],
    [ "KNG-Kärnten Netz GmbH",[14.409565999999998, 46.768633799999996]],
    [ "228884725",[12.298140515791319, 47.25295355388414]],
    [ "Energie AG",[14.097542134111098, 48.124209354418994]],
    [ "229019889",[14.074359235201092, 48.13976532056637]],
    [ "SalzburgAG",[13.0823236, 47.8034436]],
    [ "Bramberg Senningerfeld",[12.338751253432616, 47.27180666691111]],
    [ "KNG-Kärnten Netz GmbH",[14.33244960740994, 46.787387421361444]],
    [ "Energie AG",[14.106130173511408, 48.14619819550764]],
    [ "Weyerbach Golfplatz",[14.103710191199104, 48.13832826621341]],
    [ "KNG-Kärnten Netz GmbH",[14.247591900318547, 46.6557654494563]],
    [ "Energie AG",[14.144850449999998, 48.383734049999994]],
    [ "KNG-Kärnten Netz GmbH",[14.456705277016505, 46.77133180198934]],
    [ "KNG-Kärnten Netz GmbH",[14.456487734813187, 46.76767306643545]],
    [ "KNG-Kärnten Netz GmbH",[14.301633607375031, 46.66035339623724]],
    [ "229587687",[15.46445582995305, 47.079263185164315]],
    [ "Schmiding",[13.953823313207087, 48.19304468450719]],
    [ "TST Flatz",[16.02526963995084, 47.74242412042809]],
    [ "KNG-Kärnten Netz GmbH",[14.363584953466875, 46.72659011289877]],
    [ "KNG-Kärnten Netz GmbH",[14.355424871777583, 46.70081269325842]],
    [ "Energie AG",[13.701757596869275, 48.180697545705314]],
    [ "Energie AG",[13.733420338232378, 48.204632057079664]],
    [ "Energie AG",[13.705036154964718, 48.191276621639695]],
    [ "Energie AG",[13.703904449520195, 48.18493385022209]],
    [ "Energie AG",[13.520023339670377, 47.55821557886478]],
    [ "Energie AG",[13.692903311107795, 48.21481006807477]],
    [ "Energie AG",[13.714266417469586, 48.21986226945569]],
    [ "Energie AG",[13.741128255334054, 48.216306158900856]],
    [ "230025301",[14.227583921874878, 48.22527691118068]],
    [ "KNG-Kärnten Netz GmbH",[14.377608017092031, 46.755017951030794]],
    [ "KNG-Kärnten Netz GmbH",[14.377530692101091, 46.75638293047234]],
    [ "KNG-Kärnten Netz GmbH",[14.3740457, 46.755906499999995]],
    [ "KNG-Kärnten Netz GmbH",[14.382677534572718, 46.754936070506815]],
    [ "KNG-Kärnten Netz GmbH",[14.37866398170132, 46.7604956092794]],
    [ "230253208",[12.407365402784828, 47.309696250783844]],
    [ "230253209",[12.410652976189644, 47.28906899530792]],
    [ "230253210",[12.415652895111917, 47.30037100670297]],
    [ "KNG-Kärnten Netz GmbH",[14.373716409512273, 46.77590093434057]],
    [ "KNG-Kärnten Netz GmbH",[14.377936850000001, 46.78445065]],
    [ "Energie AG",[13.336413416413642, 47.854665621940526]],
    [ "KNG-Kärnten Netz GmbH",[14.440300383446738, 46.796548734070505]],
    [ "230438456",[12.991571738709242, 47.28682156325899]],
    [ "230438462",[12.99993968686793, 47.288293727097894]],
    [ "Energie AG",[13.335648977333948, 47.845900144041096]],
    [ "Energie AG",[13.34619510140492, 47.84070977140452]],
    [ "St. Lorenz Auholz",[13.352712314167654, 47.83655360580104]],
    [ "Wiener Netze",[16.333600917744935, 48.16466774887689]],
    [ "Energie AG",[13.357836734846613, 47.827948806461514]],
    [ "Salzburg Netz",[12.918803750000004, 47.7306865]],
    [ "Energie AG",[13.365392600305203, 47.81585050063076]],
    [ "230825025",[13.340111346452161, 47.84140132393368]],
    [ "Salzburg Netz",[13.074407064464092, 47.934423477665185]],
    [ "Attnang Brunnengasse",[13.734851265368881, 48.016521248843475]],
    [ "Attnang",[13.72538607824556, 48.008776132253736]],
    [ "Lenzing Ginzkeystr.",[13.605444286628682, 47.972186225401046]],
    [ "KNG-Kärnten Netz GmbH",[13.852141149975244, 46.569575566670515]],
    [ "Teufelsmühle",[13.307282498146977, 47.83584620307652]],
    [ "KNG-Kärnten Netz GmbH",[13.816930355008617, 46.55881514488869]],
    [ "Salzburg AG",[12.968248548429507, 47.283212185276106]],
    [ "Salzburg AG",[13.00666185, 47.7956019]],
    [ "Energie AG",[14.145912536522797, 48.0072909830626]],
    [ "Salzburg AG",[13.06621475, 47.82606445]],
    [ "KNG-Kärnten Netz GmbH",[14.405382378398997, 46.94990334420825]],
    [ "KNG-Kärnten Netz GmbH",[14.405320216343485, 46.9541173925039]],
    [ "Wiener Netze",[16.184877032569073, 47.91661498361416]],
    [ "KNG-Kärnten Netz GmbH",[13.92150979955617, 46.603839940561215]],
    [ "Energie AG",[14.038219816092367, 48.21276462629935]],
    [ "Netz OÖ",[13.647083099999998, 47.5564295]],
    [ "Netz Oberösterreich",[13.670064519570467, 47.512093703239685]],
    [ "Netz Oberösterreich",[13.674223618282767, 47.51581571437359]],
    [ "Energie AG",[13.724481583084195, 47.998129369823204]],
    [ "Wankham Sdlg.",[13.731488346323227, 47.99989261143813]],
    [ "Attnang Agergasse",[13.72359602728623, 48.00115870786701]],
    [ "232435280",[14.070590404848737, 48.06736771365861]],
    [ "Wildenhag",[13.489681939798272, 47.90805582560386]],
    [ "Energie AG",[13.490473755416282, 47.91308192055231]],
    [ "Vöcklamarkt Graben",[13.485830353454398, 48.011429129559694]],
    [ "Vöcklamarkt Schulweg",[13.479260514292436, 48.00381680471657]],
    [ "Energie AG",[13.472035009162447, 48.01754337174755]],
    [ "Sonnleiten b. Vöcklamarkt",[13.465480722526992, 48.00182818358075]],
    [ "Salzburg AG",[13.43748053732437, 47.681155906285966]],
    [ "Salzburg AG",[13.42819490833729, 47.65769836343826]],
    [ "Salzburg Netz",[13.43810094973985, 47.673373399249726]],
    [ "Salzburg AG",[13.4317715398198, 47.6529854940802]],
    [ "Energie AG",[13.42648145, 48.0230472]],
    [ "Energie AG",[13.415653961001649, 48.02452269353505]],
    [ "Umspannwerk Zwentendorf",[15.885580093652168, 48.35385087394745]],
    [ "Salzburg Netz",[12.92437910074925, 47.73778915235363]],
    [ "233104800",[12.949348174152103, 47.77386729464075]],
    [ "Wiener Netze",[16.315815550000003, 48.105433]],
    [ "233307612",[13.757620793646483, 47.63601459304547]],
    [ "Abtsdorf",[13.514416658067313, 47.91028744509228]],
    [ "Ostermiething Molkerei",[12.83881747943942, 48.045134202207436]],
    [ "Oberbach",[13.524332236958553, 47.91118419117067]],
    [ "Netz NÖ",[16.156093790348525, 47.93911039720258]],
    [ "SalzburgAG",[13.623693391026247, 47.1782518034114]],
    [ "Netz NÖ",[16.150414516563973, 47.937565403819896]],
    [ "Netz NÖ",[16.143596849999998, 47.93897714999999]],
    [ "Bludenz Trafostation 40 Schlossstiege",[9.821114016994644, 47.15700795356543]],
    [ "KNG-Kärnten Netz GmbH",[13.27750130786635, 46.72239675055496]],
    [ "Wiener Netze",[16.20298788752078, 48.05405197389844]],
    [ "Energie AG",[14.349331142123823, 48.04580912125135]],
    [ "Schwaming",[14.345039008420912, 48.037573796976766]],
    [ "234416263",[12.21388735, 47.244542249999995]],
    [ "KNG-Kärnten Netz GmbH",[14.031407280635806, 46.942645295595064]],
    [ "234714457",[14.401383324661321, 48.038196394427494]],
    [ "KNG-Kärnten Netz GmbH",[13.949332448477449, 46.58245371909484]],
    [ "Energie AG",[13.716326790270152, 48.00723834126894]],
    [ "Salzburg Netz",[12.907466239634537, 47.72520337752711]],
    [ "234939596",[12.922031388269215, 47.72220933341057]],
    [ "Energie AG",[13.778783303550075, 48.2094298344325]],
    [ "Energie AG",[14.375689020953148, 48.03358250256501]],
    [ "Nindorf",[13.485263828982971, 48.01943369925092]],
    [ "Vöcklamarkt Pfaffingerstr.",[13.485902922802449, 48.005901157281315]],
    [ "235258606",[12.986245984376822, 47.79466921261322]],
    [ "Seppenröth",[13.407848101402154, 48.038967651510205]],
    [ "Geinberg Ort",[13.293672865312233, 48.2620606063196]],
    [ "Energie Ried",[13.524740199999998, 48.2621363]],
    [ "Schaltwerk Kronstorf",[14.469427174416168, 48.116435310292175]],
    [ "Salzburg AG",[12.753757471499238, 47.52197113003168]],
    [ "235746848",[14.441400249303571, 48.09854865577276]],
    [ "235823504",[14.53020497756136, 47.85821501089913]],
    [ "Energie Ried",[13.510902111997122, 48.24302546782278]],
    [ "Energie Ried",[13.500255026228185, 48.250260500393566]],
    [ "Energie Ried",[13.512461173786999, 48.2391047930571]],
    [ "Brunnenthal",[13.460954057392033, 48.46703267752042]],
    [ "Salzburg AG",[13.059217752352957, 47.29056443286029]],
    [ "236205502",[13.112266165265023, 47.301949201256534]],
    [ "Hinterleiten",[13.778877613507849, 48.16035644582546]],
    [ "Netz Niederösterreich GmbH",[16.107579631085116, 48.492068412371424]],
    [ "236406019",[12.79860371641227, 47.307720751673656]],
    [ "236826485",[15.471063250000002, 46.74252345]],
    [ "Umspannwerk Kagran",[16.454815032713327, 48.249025513506254]],
    [ "Ebensee Soleweg",[13.770594002513658, 47.811078487424766]],
    [ "237179809",[15.462109108435175, 46.73887124721459]],
    [ "Gmünd",[13.537100949460072, 46.90802075137986]],
    [ "Karnerau",[13.528987567732269, 46.919829196357576]],
    [ "Salzburg AG",[13.099459071715193, 47.96480398794872]],
    [ "237481688",[12.719228222113408, 47.55912908367834]],
    [ "Gmünd Tivansäge",[13.538993825745065, 46.90627470115044]],
    [ "Gmünd Vorstadt",[13.530229595445126, 46.904095529516376]],
    [ "Salzburg AG",[13.136179748892348, 47.13447719680359]],
    [ "Energie AG",[13.909204730045765, 48.195892924063]],
    [ "238027451",[14.145270799999999, 48.0536403]],
    [ "Energie AG",[14.319414410899208, 48.07909418395286]],
    [ "238129376",[14.31992251353569, 48.07192626294721]],
    [ "TST Wiesmath Stadtweg",[16.274791200000003, 47.64015715]],
    [ "238651316",[14.264461567347615, 48.0913515593142]],
    [ "Energie AG",[13.613176645788892, 48.22030000140047]],
    [ "Ried b. St. Wolfgang",[13.416662382237607, 47.75103265884793]],
    [ "Umspannstation St. Wolfgang",[13.43867716495023, 47.74291698144868]],
    [ "KNG-Kärnten Netz GmbH",[14.013965413760797, 46.552707965002334]],
    [ "Salzburg Netz",[13.287964624021546, 47.58899764384039]],
    [ "Enzendorf",[13.60652291622162, 48.52650768334328]],
    [ "238919797",[14.23624147226164, 48.07562159491763]],
    [ "238925124",[14.363030945768434, 48.082404998979726]],
    [ "SalzburgNetz",[13.373726440884997, 47.558657730269665]],
    [ "Salzburg Netz",[13.351921185031541, 47.56035905235418]],
    [ "KNG-Kärnten Netz GmbH",[14.256547044221602, 46.78097595063572]],
    [ "KNG-Kärnten Netz GmbH",[14.286454308833664, 46.750305375342826]],
    [ "KNG-Kärnten Netz GmbH",[14.319430602322742, 46.75977518723669]],
    [ "KNG-Kärnten Netz GmbH",[14.298706855877297, 46.75545500396403]],
    [ "KNG-Kärnten Netz GmbH",[14.27436459470672, 46.76346429161412]],
    [ "KNG-Kärnten Netz GmbH",[14.268926916722585, 46.750737272679075]],
    [ "Redleiten",[13.45706002623251, 48.086124070713986]],
    [ "Triesterstrasse Lagerplatz",[15.435185819268028, 47.02467940578894]],
    [ "Salzburg AG",[13.346674422650796, 47.567645868000795]],
    [ "Kager",[13.545785498233782, 48.20640116381001]],
    [ "Breiningsham",[13.568254140689737, 48.21379821184467]],
    [ "Münsteuer",[13.397471326575294, 48.34074882938495]],
    [ "Energie AG",[13.486051685035536, 48.07631648810884]],
    [ "KNG-Kärnten Netz GmbH",[13.25139365820072, 46.75402392720523]],
    [ "Linz AG",[14.155443549623998, 48.27611350047301]],
    [ "Energie AG",[14.248727969867126, 47.93453055639075]],
    [ "Energie AG",[13.44156858353693, 48.46307312037007]],
    [ "Schärding Prambrücke",[13.437845552461976, 48.45897793863503]],
    [ "Energie AG",[13.428780818815605, 48.457459446817694]],
    [ "Schärding Pflegfeld",[13.44127365977297, 48.45131911321166]],
    [ "Gollweg",[15.410797550000002, 47.0537295]],
    [ "Kleinalpe Krautwaschl",[15.14612453301543, 47.201290418522845]],
    [ "Energie AG",[13.865734461644674, 48.22188804046545]],
    [ "Reichersberg a. I.",[13.361347291095743, 48.33223900334792]],
    [ "KNG-Kärnten Netz GmbH",[13.37278272102063, 46.62695115550764]],
    [ "Linz AG",[14.379125242235261, 48.47995262777762]],
    [ "241984039",[14.38717425116199, 48.4664589000446]],
    [ "KNG-Kärnten Netz GmbH",[13.755926821550037, 46.56649457454364]],
    [ "Energie AG",[13.85418730418741, 48.22914402039742]],
    [ "Energie AG",[13.862688018640368, 48.22620775786265]],
    [ "Energie AG",[13.834600992802994, 48.23841971369139]],
    [ "Klagenfurt West",[14.280732851186912, 46.611698098256554]],
    [ "Salzburg Netz",[13.082365045811375, 47.889701842594945]],
    [ "Salzburg Netz",[13.094296868481047, 47.903038173318045]],
    [ "242260220",[15.4014436, 47.027428300000004]],
    [ "Hafnerstraße II",[15.411156410451227, 47.02501627419277]],
    [ "E-Werk Gösting",[15.3798363873823, 47.09964472293552]],
    [ "Gradnerstrasse 45",[15.424065850000002, 47.022113649999994]],
    [ "242492668",[15.44051855, 47.03497015]],
    [ "242495457",[15.43189292488028, 47.0400918739037]],
    [ "Salzburg Netz",[13.091217212122507, 47.91186158612586]],
    [ "KNG-Kärnten Netz GmbH",[13.793371698434024, 46.55291187369891]],
    [ "Bramberg Leiten",[12.336404231606481, 47.26855169647777]],
    [ "Energie AG",[13.820207438065406, 48.23545817224434]],
    [ "Salzburg AG",[13.048809675653589, 47.79348754136195]],
    [ "KNG-Kärnten Netz GmbH",[14.394457321489702, 46.83597578416481]],
    [ "Redlthal",[13.412237947487471, 48.060028689005925]],
    [ "Frankenburg Ottokönigerstr.",[13.475042199558596, 48.07151395775217]],
    [ "Kreuschlach",[13.550858696611984, 46.915342397905604]],
    [ "Ranshofen Mitterweg",[13.019089693982169, 48.237273808426814]],
    [ "Energie AG",[14.33714083480301, 48.02974231315507]],
    [ "Energie AG",[14.342259494882882, 48.10367458200085]],
    [ "244212598",[12.492700545894914, 47.226233688173416]],
    [ "244224048",[12.44340155, 47.2759546]],
    [ "244224051",[12.445562786525699, 47.276612284626054]],
    [ "244405331",[12.485562549946726, 47.27330131667576]],
    [ "Traschwandt",[13.430330382732496, 47.88245395111406]],
    [ "Salzburg Netz",[13.132626646281963, 47.90314957545211]],
    [ "Salzburg Netz",[13.112328549999999, 47.91284334999999]],
    [ "Salzburg Netz",[13.125182133657528, 47.91537721852956]],
    [ "Wiener Netze",[16.287614972229974, 48.111747204200285]],
    [ "Salzburg Netz",[13.070194025491116, 47.86804729583023]],
    [ "Salzburg Netz",[13.098916576022438, 47.875657266571125]],
    [ "Salzburg Netz",[13.1133548912782, 47.89555454795172]],
    [ "Energie AG",[13.662081989592998, 48.01102710472294]],
    [ "244921796",[13.013507844213878, 47.87864099874596]],
    [ "Salzburg Netz",[13.018803481217347, 47.87254399485234]],
    [ "244921798",[13.004213612653805, 47.88010127831259]],
    [ "Kärnten Netz",[13.256128694181626, 46.722810707299566]],
    [ "Salzburg AG",[13.086550558607751, 47.93786963107633]],
    [ "Salzburg AG",[13.030944450000002, 47.81565870000001]],
    [ "Rudolf Hans Bartsch Strasse",[15.4690053, 47.05627135]],
    [ "Kärnten Netz",[13.21315897163445, 46.73968696765295]],
    [ "Wiener Netze",[16.1741223, 48.1124236]],
    [ "246359121",[13.843987444869676, 48.12801059893488]],
    [ "Energie AG",[13.854449662582391, 48.09943120554789]],
    [ "Energie AG",[13.825356952832434, 48.1100614326561]],
    [ "Kärnten Netz",[13.173213106507333, 46.750486835711584]],
    [ "246705739",[15.737200363983922, 46.71262025497087]],
    [ "KNG-Kärnten Netz GmbH",[13.848761544513192, 46.73122918240761]],
    [ "Kärnten Netz",[13.166873294722384, 46.74651355046742]],
    [ "247041848",[13.25407349287211, 47.4491128500809]],
    [ "Energie AG",[13.401945572757942, 47.8150687162103]],
    [ "Gmünd Riesertratte Ost",[13.532815513336907, 46.91052010312337]],
    [ "Ansfelden",[13.04175225, 47.963333649999996]],
    [ "Pichl Auhof",[13.4036932, 47.8161175]],
    [ "KNG-Kärnten Netz GmbH",[13.1213556, 46.734382999999994]],
    [ "247417318",[15.726025522630392, 46.705487706525425]],
    [ "Brunnern",[14.139715377634005, 48.1470842510947]],
    [ "St. Radegund",[12.776585858532627, 48.09324774032873]],
    [ "247894960",[13.7726805919328, 47.7936329832312]],
    [ "247952572",[14.42558145, 48.0198282]],
    [ "248171489",[12.74187370770152, 47.662122683429025]],
    [ "Thean",[13.499917463885415, 47.93085855639534]],
    [ "Dobl Neue Welt",[15.384646452314739, 46.95178327006214]],
    [ "Energie AG",[13.822691360502104, 48.35394692049619]],
    [ "KNG-Kärnten Netz GmbH",[14.163075951870141, 46.66831539185692]],
    [ "Energie AG",[14.13115587429697, 48.16499948332126]],
    [ "Attnang Salzburgerstraße",[13.72076340475512, 48.01787254822897]],
    [ "St. Georgen Plainfeld",[13.479203371377576, 47.93665325256407]],
    [ "St. Georgen Wildenhagerstr.",[13.492974485291304, 47.925021742905145]],
    [ "Lobichl",[13.401151437252489, 47.835573093294556]],
    [ "Salzburg AG",[13.444913929639014, 47.71713771284725]],
    [ "249213891",[12.972406678239434, 47.40630487895844]],
    [ "Energie AG",[13.473236423430455, 47.92844710215599]],
    [ "Vöcklamarkt Gries",[13.50191682029042, 48.01568453324145]],
    [ "Energie AG",[13.538546351205778, 47.9155418053921]],
    [ "Attersee Sportstrasse",[13.53970832891318, 47.91789807520389]],
    [ "Badhöring",[13.445385348750223, 48.434818911387445]],
    [ "Haag a. Hausruck",[13.64228500726376, 48.182763354632066]],
    [ "250853335",[13.76707559497954, 47.79947327276139]],
    [ "Haag a. Hausruck Oberer Markt",[13.63498365, 48.18472185000001]],
    [ "Netz Oberösterreich",[13.766688366732364, 47.85486037293346]],
    [ "Dobl Mühlwegäcker",[15.358984193923995, 46.945955113817455]],
    [ "Energie AG",[13.462964573548623, 48.508764852837665]],
    [ "Trafo I Maria Saal",[14.338443250000001, 46.68476370000001]],
    [ "Wiener Netze",[16.398091024322714, 48.103779494273134]],
    [ "Salzburg AG",[13.202022306002101, 47.59186012045537]],
    [ "Energie AG",[13.524530407740203, 47.90481384993698]],
    [ "Treffenboden",[13.539230792696433, 46.920915155471796]],
    [ "Feistritz bei Malta",[13.487903658813932, 46.96365739563141]],
    [ "Dornbach",[13.516474802997633, 46.93202619975912]],
    [ "Feichtet Ort",[13.528758832658637, 48.15016456782658]],
    [ "Anetsham",[13.52462996152267, 48.165007470206525]],
    [ "253215621",[14.140466652594341, 48.17453560078092]],
    [ "Energie AG",[13.522037212082221, 47.882272477009714]],
    [ "253256690",[13.51849876266104, 47.88373104214599]],
    [ "Energie AG",[13.76560598712121, 47.814662128390296]],
    [ "Nußdorf Süd",[13.523210864634464, 47.87909117329623]],
    [ "Gmünd Schloßbichl",[13.530885924846043, 46.91572969563492]],
    [ "Malta Fischertratten Gemeindewohnblöcke",[13.521705749522413, 46.93565036684519]],
    [ "KNG-Kärnten Netz GmbH",[13.52080462960096, 46.90455775613847]],
    [ "KNG-Kärnten Netz GmbH",[13.50867230362182, 46.885624986969844]],
    [ "KNG-Kärnten Netz GmbH",[13.48723884291722, 46.85869469594381]],
    [ "KNG-Kärnten Netz GmbH",[13.484927636962823, 46.83602708107382]],
    [ "KNG-Kärnten Netz GmbH",[13.51425989956695, 46.883145677512104]],
    [ "KNG-Kärnten Netz GmbH",[13.578170669756503, 46.929414449022126]],
    [ "KNG-Kärnten Netz GmbH",[13.593601192100309, 46.93224105667245]],
    [ "KNG-Kärnten Netz GmbH",[13.59980905776124, 46.94195152047529]],
    [ "TST Hüttschlag",[13.234554504504915, 47.17593636357736]],
    [ "Hüttschlag Freizeitzentrum",[13.229574678965358, 47.17778674284134]],
    [ "EAG",[13.213988550000002, 47.19005615000001]],
    [ "KNG-Kärnten Netz GmbH",[13.108981330930224, 46.744255980429166]],
    [ "EAG",[13.274472743675778, 47.16250944596094]],
    [ "KNG-Kärnten Netz GmbH",[13.0314101, 46.743757599999995]],
    [ "Tentschach",[14.255519294699415, 46.68322453441123]],
    [ "KNG-Kärnten Netz GmbH",[14.260601649657868, 46.69546530073777]],
    [ "KNG-Kärnten Netz GmbH",[14.271308151206474, 46.7153542634516]],
    [ "Energie AG",[14.423238326976607, 48.148490455350725]],
    [ "Energie AG",[14.060953204451504, 48.347275160798645]],
    [ "255096199",[15.976556314684128, 46.775480671798455]],
    [ "KNG-Kärnten Netz GmbH",[13.076535683630167, 46.74498763253679]],
    [ "KNG-Kärnten Netz GmbH",[14.29813976056346, 46.677985945170754]],
    [ "KNG-Kärnten Netz GmbH",[14.324087664904132, 46.70254776231897]],
    [ "Energie AG",[13.715111954582769, 47.8957157814461]],
    [ "EAG",[13.199298209593291, 47.20752439715345]],
    [ "Großarl Süd",[13.20020310033442, 47.23545930009588]],
    [ "TST Hof Am Gipfelberg",[16.582734100000003, 47.94169454999999]],
    [ "Großarl Ost",[13.20429827791264, 47.237015206517555]],
    [ "Ebensee Offenseestr.",[13.76181903856485, 47.80044572166962]],
    [ "Salzburg AG",[13.057466844431616, 47.80495286066243]],
    [ "Energie AG",[13.51989577116618, 47.871035738025526]],
    [ "EAG",[13.51963456650673, 47.87607347200061]],
    [ "Energie AG",[14.344265990647074, 48.18781334845121]],
    [ "Energie AG",[14.325850345961555, 48.14353946007558]],
    [ "256245986",[14.335573329941473, 48.1600729053748]],
    [ "Energie AG",[14.331239685066421, 48.13218730852276]],
    [ "Salzburg AG",[13.20781988190505, 47.41338807182452]],
    [ "Zell a. A.",[13.519906533762901, 47.863349892833924]],
    [ "256353644",[13.830998426204783, 47.93191539462232]],
    [ "256353645",[13.870118447231421, 47.92173345250049]],
    [ "256353676",[13.846398763567448, 47.93206266246209]],
    [ "Energie AG",[14.317617846629723, 48.10137710557713]],
    [ "KNG-Kärnten Netz GmbH",[13.4459118157045, 46.70165199808294]],
    [ "KNG-Kärnten Netz GmbH",[14.045552633761808, 46.87772946937669]],
    [ "EAG",[13.524053648625372, 47.859871649896064]],
    [ "256477817",[13.224813295237713, 47.420388692495415]],
    [ "256481346",[13.221610389852481, 47.41332175327315]],
    [ "256481807",[13.223839318861959, 47.40780145293307]],
    [ "Mondsee Gaisberg",[13.334072351223034, 47.849659807692994]],
    [ "256594602",[14.334735608921896, 48.081116951061226]],
    [ "Energie AG",[14.311505946862685, 48.084948066792464]],
    [ "256678213",[13.320810179811112, 47.341011394984356]],
    [ "Aich a. Attersee",[13.522311442127672, 47.848183169393536]],
    [ "Energie AG",[13.523323600416399, 47.85347852418216]],
    [ "256967739",[13.699268672938102, 48.08482011961639]],
    [ "257017666",[14.283809050000004, 48.06573445]],
    [ "257033957",[14.305179508701288, 48.04992083122729]],
    [ "Energie AG",[14.3576569749324, 48.11493989156934]],
    [ "Energie AG",[14.333898253241198, 48.04449253948824]],
    [ "257364747",[14.260104444023252, 48.213236532153864]],
    [ "Umspannwerk Neuhaus",[16.03987670059844, 48.00397600678647]],
    [ "Energie AG",[13.372798735463777, 48.18667794957722]],
    [ "Energie AG",[13.518852376959236, 47.84152245228441]],
    [ "EAG",[13.200592827308922, 47.2646043799551]],
    [ "Energie Ried",[13.403052441906107, 48.1730245182202]],
    [ "Energie AG",[13.896040626919856, 48.43203911565824]],
    [ "258114584",[13.841694280034371, 48.39530804517844]],
    [ "258127676",[14.176215101510637, 48.15124101044673]],
    [ "Allhaming - Taborstraße",[14.181278544288068, 48.1567487411879]],
    [ "258516669",[13.215375200000002, 47.283277000000005]],
    [ "258540847",[14.467366733523425, 48.14196899904953]],
    [ "258553277",[14.476640949999998, 48.183022300000005]],
    [ "Salzburg AG",[13.087084995253345, 47.86044644935916]],
    [ "Salzburg Netz",[13.076365877576674, 47.863301029831014]],
    [ "Misling",[13.51691359529501, 47.831042482352345]],
    [ "EAG",[13.506336231613572, 47.81498959241604]],
    [ "EAG",[13.22042860926815, 47.280313730058886]],
    [ "Karlsdorf",[13.475239302198867, 46.83119285007107]],
    [ "KNG-Kärnten Netz GmbH",[13.46885823025435, 46.83029091961335]],
    [ "KNG-Kärnten Netz GmbH",[13.623824443297368, 46.93305525479873]],
    [ "KNG-Kärnten Netz GmbH",[13.654770427634663, 46.91709480425493]],
    [ "KNG-Kärnten Netz GmbH",[13.57707659369138, 46.91881249894703]],
    [ "KNG-Kärnten Netz GmbH",[13.59464335576399, 46.895032387044076]],
    [ "Braunau Frauenhuberberg",[13.043105959963924, 48.244093254514425]],
    [ "Braunau Ringstraße",[13.039146833311143, 48.2584228507223]],
    [ "259327225",[14.411920968715345, 48.01759309394225]],
    [ "Netz OÖ",[13.789523048309386, 47.61309352175327]],
    [ "Energie AG",[13.501618401636279, 47.81116780136772]],
    [ "Energie AG",[13.481544811114013, 47.80213875145047]],
    [ "Energie AG",[13.487610718371679, 47.80505985429988]],
    [ "Energie AG",[13.490103640681976, 47.8063325484484]],
    [ "Energie AG",[13.013972132764817, 48.23656208018674]],
    [ "Ranshofen Hochstr.",[13.018715630574729, 48.22823959882269]],
    [ "Salzburg Netz",[13.3830766, 47.57506]],
    [ "259911703",[13.512650050000001, 47.794690349999996]],
    [ "Energie AG",[12.91575713879041, 48.05293708844432]],
    [ "Netz AG",[13.94405529314673, 48.19174913032871]],
    [ "260291458",[13.686241075393628, 47.127295794145994]],
    [ "260516529",[14.34451335, 48.05761685000001]],
    [ "260516530",[14.335066475914143, 48.04898751557818]],
    [ "260516531",[14.327491800000002, 48.05030985]],
    [ "Wiener Netze",[16.350499287706292, 48.08420402729023]],
    [ "Villach Dollhopfgasse",[13.8549304, 46.617132299999994]],
    [ "Energie AG",[14.29744595033389, 48.08135352091522]],
    [ "Köblern",[9.704739235469718, 47.41341159269893]],
    [ "Weißenbach",[13.53983055, 47.79822125]],
    [ "261932752",[14.0229598, 48.17406189999999]],
    [ "Energie AG",[14.013639614059054, 48.322772239090355]],
    [ "Pupping",[14.001483803172903, 48.339973695327224]],
    [ "Energie AG",[14.003941042338601, 48.311227032625176]],
    [ "Energie AG",[14.020160499232198, 48.32159270026484]],
    [ "Energie AG",[14.013361637722268, 48.3249509042782]],
    [ "Energie AG",[13.972245933378554, 48.3252359860909]],
    [ "Energie AG",[13.995246876648336, 48.349418732111886]],
    [ "Energie AG",[14.054018612741872, 48.34407070006671]],
    [ "KNG-Kärnten Netz GmbH",[13.741085849999997, 46.55049499999999]],
    [ "Ginzldorf",[13.639031911767548, 48.507302127201555]],
    [ "Energie AG",[12.925232662391629, 48.06857961686549]],
    [ "Salzburg AG",[12.997232423052884, 47.36779277718944]],
    [ "Energie AG",[13.925073411501959, 48.34114427131607]],
    [ "Energie AG",[13.917660949999997, 48.332849599999996]],
    [ "Energie AG",[13.914856915327809, 48.318318036429716]],
    [ "Energie AG",[13.928600451998491, 48.31542390029035]],
    [ "263999203",[13.850612410605574, 48.336754249717714]],
    [ "Tross",[13.923346700123876, 48.35770481367702]],
    [ "Energie AG",[13.912645011018416, 48.35840600010395]],
    [ "KNG-Kärnten Netz GmbH",[13.845847538153253, 46.589957289363326]],
    [ "Linz AG",[14.466033023609024, 48.21551695448843]],
    [ "264136544",[14.361562612066429, 48.02200017867306]],
    [ "Holzöster",[12.906337424825027, 48.05934112206783]],
    [ "Energie AG",[13.958699078391446, 48.336885051523424]],
    [ "264413620",[14.157031511853077, 48.13393665748315]],
    [ "Energie AG",[13.728942154418098, 48.15916168430643]],
    [ "Wiener Netze",[16.3833214, 47.903059549999995]],
    [ "Oberfranking",[12.901934448299153, 48.06583012104042]],
    [ "Wiener Netze",[16.393328182988864, 47.90902026687819]],
    [ "Wiener Netze",[16.39246995, 47.90963295]],
    [ "Energie AG",[13.894455125614373, 48.32449074934872]],
    [ "Energie AG",[13.877024718251004, 48.32281284738555]],
    [ "Prambachkirchen West",[13.900541948004795, 48.31818879956103]],
    [ "Energie AG",[13.903317708142986, 48.31199368297857]],
    [ "Energie AG",[13.973379525518965, 48.302997627491955]],
    [ "Energie AG",[13.97086757454005, 48.282104542040116]],
    [ "Energie AG",[13.94085034384444, 48.25931265519492]],
    [ "Energie AG",[13.930455467180384, 48.26255245455447]],
    [ "Energie AG",[13.922052212586086, 48.26307330863631]],
    [ "Energie AG",[13.9036095260552, 48.272269762920736]],
    [ "Energie AG",[13.901716944923155, 48.26295713804231]],
    [ "EAG",[13.549577232916397, 47.830157798341915]],
    [ "EAG",[13.557546548364618, 47.82945899990133]],
    [ "265193806",[13.55335734279251, 47.829350251379026]],
    [ "Linz AG",[14.5596010469378, 48.365566487034215]],
    [ "Wiener Netze",[16.38755255, 47.9118618]],
    [ "Wiener Netze",[16.399232687778415, 47.91386353538985]],
    [ "Wiener Netze",[16.392798466789973, 47.91626124933826]],
    [ "EAG",[13.558812950000002, 47.858606449999996]],
    [ "EAG",[13.570717700000001, 47.88962295000001]],
    [ "Energie AG",[13.798993456973719, 48.42598826647197]],
    [ "Energie AG",[13.82140280747588, 48.43971523223834]],
    [ "Energie AG",[13.76428721711286, 48.39497778328001]],
    [ "Energie AG",[13.751454289775587, 48.39665654248034]],
    [ "Energie AG",[13.772196103919658, 48.407129621691475]],
    [ "Energie AG",[13.75979649854691, 48.431753899100904]],
    [ "Energie AG",[13.748090570258386, 48.45552294842959]],
    [ "Wiener Netze",[16.392791900000002, 47.90831850000001]],
    [ "Energie AG",[13.852995736345004, 48.325715441128175]],
    [ "Energie AG",[13.84461620838838, 48.32931734435501]],
    [ "Energie AG",[13.842240132385385, 48.33481029892426]],
    [ "Energie AG",[13.863067606867531, 48.34265181937231]],
    [ "Unterwegbach",[13.851069518255303, 48.32240760698278]],
    [ "Energie AG",[13.85582204134788, 48.32387572814994]],
    [ "Energie AG",[13.859739996507225, 48.33346635210586]],
    [ "KNG-Kärnten Netz GmbH",[13.6168903, 47.005340249999996]],
    [ "Mitternberg Paschen",[12.987381422742107, 48.19658344981029]],
    [ "Berndorf",[12.95766618270137, 48.162595128305405]],
    [ "Schwand",[12.965576820921223, 48.18013050769517]],
    [ "268662973",[13.818641148354903, 48.39426872671936]],
    [ "Energie AG",[13.821462164856616, 48.36435954273993]],
    [ "Energie AG",[13.43344536861191, 48.38266710219119]],
    [ "Energie AG",[13.835999587658375, 48.42177839451285]],
    [ "Energie AG",[13.803783969085936, 48.4468612523285]],
    [ "Energie AG",[13.807679864483353, 48.45767664861184]],
    [ "Lohnsburg Sdlg.",[13.400407299378939, 48.14810910410053]],
    [ "270165658",[14.240511600000001, 48.3111819]],
    [ "Energie AG",[13.74652868426868, 48.245197594804466]],
    [ "Energie AG",[13.792462495560194, 48.26476213029011]],
    [ "Energie AG",[13.810331310894249, 48.28772620758287]],
    [ "Energie AG",[13.82835282006657, 48.28543746404838]],
    [ "Energie AG",[13.812923705851588, 48.305113780657926]],
    [ "270614086",[15.51691195, 47.03535745]],
    [ "Energie AG",[13.787604929229976, 48.328768380355484]],
    [ "Energie AG",[13.771620289537607, 48.33510161445267]],
    [ "Radlern",[13.54884745, 48.370553199999996]],
    [ "Andorf Messenbach",[13.570318650000003, 48.374573149999996]],
    [ "Andorf Pfarrhof",[13.587891861673935, 48.371570517216426]],
    [ "Netz Niederösterreich GmbH",[16.058894961304826, 47.83947126864462]],
    [ "Salzburg Netz",[13.029651328736488, 47.88081937780529]],
    [ "Umspannwerk Penzing",[16.310402906638124, 48.19492884905041]],
    [ "Großschörgern",[13.578692155095052, 48.355040586095065]],
    [ "Energie AG",[13.589303221306727, 48.32028134309185]],
    [ "Linz AG",[14.762596543891236, 48.31185870191893]],
    [ "272806818",[14.763858299543967, 48.31758802514608]],
    [ "KNG-Kärnten Netz GmbH",[13.654121175237824, 46.964413438095136]],
    [ "Eferding - Wörtherstraße",[14.028378288816022, 48.31562672972691]],
    [ "Energie Ried",[13.464058125871325, 48.24237860696829]],
    [ "Energie Ried",[13.483185591927676, 48.234547251516815]],
    [ "Linz AG",[14.773892504334322, 48.30420719765418]],
    [ "274054015",[14.771013787656504, 48.33244854191218]],
    [ "Salzburg AG",[13.439143801145846, 47.18323864660675]],
    [ "Reintal",[15.51104968507214, 47.05184772858195]],
    [ "Salzburg AG",[13.189749822177722, 47.79912643789746]],
    [ "Leimhof bei Adenberg",[12.985101693305104, 48.15821810073517]],
    [ "Energie AG",[13.001587688983266, 48.15400371674455]],
    [ "KNG-Kärnten Netz GmbH",[14.627748914806752, 46.59102670194455]],
    [ "Walchen",[13.487086602007322, 47.98365839849786]],
    [ "KNG-Kärnten Netz GmbH",[13.65435628818238, 46.76484879893262]],
    [ "Netz Niederösterreich GmbH",[16.20945725, 47.721773]],
    [ "276145081",[14.409146317090103, 48.025580701707526]],
    [ "Enzenkirchen Steinbruch",[13.654538188126004, 48.38823629485408]],
    [ "Energie AG",[14.220856117320588, 47.998640990800716]],
    [ "Energie AG",[14.219113349999999, 47.991904600000005]],
    [ "Sandtal",[13.023004549455186, 48.1464285264362]],
    [ "Energie AG",[14.088072754149636, 48.38292565242687]],
    [ "Energie AG",[14.095210168334551, 48.37583061841775]],
    [ "Jagern",[13.64241779322531, 48.38012343114759]],
    [ "Wiener Netze",[16.10793744939098, 48.0825855006503]],
    [ "Kopfing",[13.659828197367041, 48.43865651001744]],
    [ "Götzendorf",[13.656954540544898, 48.44576200373951]],
    [ "280715576",[12.4514687162, 47.28951837075464]],
    [ "280715577",[12.467632115449408, 47.28889026160406]],
    [ "Gmünd Holztratte",[13.531160541486345, 46.907723582924916]],
    [ "Energie AG",[13.608903454572049, 48.41041224975225]],
    [ "Mitterndorf Raad",[13.620878555129591, 48.42409216726227]],
    [ "Unterwerk Kreith",[11.38078890314014, 47.20252268227363]],
    [ "Energie AG",[13.57393166130861, 48.40378831296255]],
    [ "Inding",[13.559698811407976, 48.39713289907108]],
    [ "Energie AG",[13.538590540773841, 48.40776621071463]],
    [ "Energie AG",[13.522876200040416, 48.40932169934684]],
    [ "KNG-Kärnten Netz GmbH",[14.199162438189598, 46.724315745881086]],
    [ "KNG-Kärnten Netz GmbH",[14.100514782318198, 46.74258842587576]],
    [ "KNG-Kärnten Netz GmbH",[14.287232349590015, 46.73858815068744]],
    [ "KNG-Kärnten Netz GmbH",[14.168460450042721, 46.775079131463734]],
    [ "KNG-Kärnten Netz GmbH",[14.298936108030352, 46.73963312772448]],
    [ "KNG-Kärnten Netz GmbH",[14.18747598197419, 46.77019211940395]],
    [ "KNG-Kärnten Netz GmbH",[14.17039671838094, 46.748408142939915]],
    [ "KNG-Kärnten Netz GmbH",[14.208208780134212, 46.773187584021166]],
    [ "KNG-Kärnten Netz GmbH",[14.106130043775226, 46.75166555601286]],
    [ "KNG-Kärnten Netz GmbH",[14.196929111827492, 46.74260592046304]],
    [ "KNG-Kärnten Netz GmbH",[14.142517437124484, 46.77497622238406]],
    [ "KNG-Kärnten Netz GmbH",[14.331331967172101, 46.75110515782158]],
    [ "KNG-Kärnten Netz GmbH",[14.310321210244489, 46.74338890309504]],
    [ "KNG-Kärnten Netz GmbH",[14.230639062766835, 46.74843239199406]],
    [ "KNG-Kärnten Netz GmbH",[14.245032947012856, 46.75877675593063]],
    [ "KNG-Kärnten Netz GmbH",[14.227503877482146, 46.7313440461807]],
    [ "KNG-Kärnten Netz GmbH",[14.179130080703139, 46.7458304088599]],
    [ "KNG-Kärnten Netz GmbH",[14.130787705379952, 46.75185352928492]],
    [ "KNG-Kärnten Netz GmbH",[14.094961857285181, 46.74507674815407]],
    [ "KNG-Kärnten Netz GmbH",[13.514590700000001, 46.89315849999999]],
    [ "Radl bei Trebesing",[13.515333375845714, 46.89010087198393]],
    [ "Reikersberg",[13.47825071210886, 48.47551437471929]],
    [ "Energie AG",[13.608141023298696, 48.48039339986791]],
    [ "E-Werk Perg",[14.650093107696435, 48.30021119415944]],
    [ "Neukirchen a.E.",[13.045303740498909, 48.17837539206844]],
    [ "Friedrichsdorf",[13.051437512682595, 48.17567576009583]],
    [ "Dorfgastein Gruberalm",[13.13783153258675, 47.23823890185689]],
    [ "285197164",[13.8574208, 47.94079189999999]],
    [ "Umspannwerk Messe",[16.410713560432946, 48.21023681564248]],
    [ "Salzburg AG",[13.106728729822652, 47.93801773461484]],
    [ "SalzburgAG",[13.084482169573164, 47.812208156864685]],
    [ "Lohnsburg Ort",[13.402529091106528, 48.14276090162933]],
    [ "Energie AG",[13.405963262596925, 48.143051006896215]],
    [ "Handenberg",[13.001697213726837, 48.133654513836]],
    [ "Umspannwerk Tulln-West",[16.041596793871847, 48.31317968312928]],
    [ "Stadtwerke Kufstein",[12.17897596933149, 47.58961684790838]],
    [ "Salzburg AG",[13.10337363342817, 47.966201900523245]],
    [ "Salzburg AG",[13.260761471342429, 47.73742085392412]],
    [ "Netz NÖ",[16.04165476517707, 47.716720145421036]],
    [ "289940729",[13.814547053464391, 48.08714124210419]],
    [ "290568577",[14.866826534421927, 48.113093550524006]],
    [ "Energie AG",[12.997855200177096, 48.10978132502941]],
    [ "Energie AG",[13.012024954654004, 48.127549239997066]],
    [ "Energie Steiermark",[15.410409871146335, 47.04850209723633]],
    [ "Zellerkirche",[12.16401807496578, 47.585074590939236]],
    [ "Energie AG",[13.901706248908546, 48.22475142665221]],
    [ "Wiener Netze",[16.283498809913915, 47.94863208826191]],
    [ "292387503",[12.90865906674199, 47.72244724780624]],
    [ "292389602",[14.011611149514996, 48.17300721669005]],
    [ "TST Stollhof Maut",[16.0660375, 47.829943400000005]],
    [ "E-Werk Perg",[14.643251228631282, 48.249015296419294]],
    [ "Neukirchen Bahnhof",[12.283172571428516, 47.253487964357205]],
    [ "Kerschhacklhütte Maltatal",[13.442115837039578, 47.00604284474192]],
    [ "KNG-Kärnten Netz GmbH",[13.599831440726522, 47.02797635718753]],
    [ "KNG-Kärnten Netz GmbH",[13.581415400000001, 47.03554505]],
    [ "KNG-Kärnten Netz GmbH",[13.724615144579726, 46.969985751010405]],
    [ "KNG-Kärnten Netz GmbH",[13.728819962374166, 46.95371476474922]],
    [ "Linz AG",[14.477499547759075, 48.27990905087655]],
    [ "Webersdorf",[12.956377049999999, 48.10675784999998]],
    [ "Salzburg AG",[13.24189162778747, 47.43430577285672]],
    [ "294300392",[12.945429822103339, 48.1397571748582]],
    [ "Gilgenberg",[12.945961856774447, 48.12611776425965]],
    [ "Puttenhausen",[12.991765907759241, 48.05150395409766]],
    [ "Netz Niederösterreich GmbH",[15.993495962083914, 47.78887841574835]],
    [ "Röhrn",[12.948541251740773, 48.15041829976751]],
    [ "TST Dunkelstein Süd",[16.05187665, 47.71007215]],
    [ "Moosdorf",[12.991382883664132, 48.04425443809601]],
    [ "Salzburg Netz",[13.13285258214187, 47.85774664847662]],
    [ "Salzburg Netz",[13.128961771904047, 47.868030856256105]],
    [ "Salzburg Netz",[13.125763451881728, 47.869346795633575]],
    [ "Salzburg Netz",[13.128625945644805, 47.85375920476551]],
    [ "Energie AG",[13.997913866666668, 48.36255405036662]],
    [ "Energie AG",[14.021538598583868, 48.36698519979007]],
    [ "Energie AG",[14.018839598029622, 48.37131789786342]],
    [ "Energie AG",[13.988469014459724, 48.272248160296655]],
    [ "Energie AG",[13.96601904943321, 48.242497398809085]],
    [ "KNG-Kärnten Netz GmbH",[14.236972631169065, 46.49085855097601]],
    [ "296005712",[14.786390684657372, 48.276793078604754]],
    [ "296014263",[14.834376902930952, 48.33207290125131]],
    [ "Salzburg AG",[13.341899914376345, 47.77548601327719]],
    [ "Salzburg AG",[12.725408032531439, 47.55225360664194]],
    [ "Netz Niederösterreich GmbH",[15.7052481, 47.8086359]],
    [ "Salzburg AG",[13.807242954823305, 47.18794940624635]],
    [ "Sulzau Ehrensberger",[13.16487436103389, 47.52115943158379]],
    [ "297524638",[14.799870975889455, 48.29944165903732]],
    [ "297618503",[13.833024903410513, 48.28814600401352]],
    [ "297723758",[13.240718145897254, 47.463724002753]],
    [ "Salzburg AG",[13.341615498498292, 47.56523709897585]],
    [ "Salzburg AG",[13.342128847298035, 47.57055668530704]],
    [ "Salzburg AG",[13.341067382532048, 47.568921472299635]],
    [ "Salzburg AG",[13.335211196024845, 47.57006047795642]],
    [ "Salzburg Netz",[13.333942878428815, 47.55174729558739]],
    [ "Salzburg Netz",[13.346258560581836, 47.55961916994073]],
    [ "Salzburg AG",[13.350869412891203, 47.55682369574608]],
    [ "297975694",[13.346595453322822, 47.586478900144954]],
    [ "297975700",[13.331609198927058, 47.59160949229654]],
    [ "Salzburg Netz",[13.347781149250212, 47.60044118784397]],
    [ "Salzburg AG",[13.267671930368548, 47.57429681322554]],
    [ "Salzburg AG",[13.265954784722126, 47.57846408538703]],
    [ "Salzburg AG",[13.801606503748845, 47.133940393456]],
    [ "Salzburg AG",[13.802454589126096, 47.129747852871525]],
    [ "298269023",[14.234619053273269, 48.169779992186236]],
    [ "298383061",[14.222260986557941, 48.16907573749433]],
    [ "298389210",[14.197786299999999, 48.17790085]],
    [ "298460067",[12.7218392511435, 47.54126309859039]],
    [ "Energie AG",[13.437838363426676, 47.74423663796029]],
    [ "KNG-Kärnten Netz GmbH",[14.01056599968404, 46.69822053752415]],
    [ "299396046",[12.704702911480158, 47.584931323507554]],
    [ "Meggenhofen",[13.79414021540014, 48.17997359118873]],
    [ "KNG-Kärnten Netz GmbH",[13.92616933075765, 46.56651001304982]],
    [ "Salzburg AG",[13.010476658615737, 47.80674351934401]],
    [ "300510980",[14.818101204093386, 48.313235216603246]],
    [ "300513305",[14.819460514315503, 48.31640734601511]],
    [ "Linz AG",[14.488725984892831, 48.397559268073856]],
    [ "Netz Niederösterreich GmbH",[14.939982853874081, 48.75447581376282]],
    [ "Netz Niederösterreich GmbH",[15.997277799999999, 48.76827874999999]],
    [ "301182168",[12.711594958894688, 47.60439824842766]],
    [ "Energie AG",[13.940721204317187, 48.4237584356652]],
    [ "Energie AG",[13.940230952105486, 48.444155986106146]],
    [ "301775195",[13.464454743861264, 47.53082619329892]],
    [ "301856879",[13.891285761616762, 48.18169942776503]],
    [ "302599962",[14.870213400259457, 46.94427671002718]],
    [ "KWG",[13.766553149853973, 48.06293049622095]],
    [ "KWG",[13.758047245112856, 48.069131623073616]],
    [ "Linz AG",[14.50709809868851, 48.42103452551624]],
    [ "Linz AG",[14.512385024953678, 48.43693609993929]],
    [ "Linz AG",[14.491932300075268, 48.38837299925984]],
    [ "Linz AG",[14.491042622076574, 48.40570700700881]],
    [ "Eisenhub",[13.011537414157454, 48.18673220091156]],
    [ "303736702",[14.814063764559668, 48.317911703882864]],
    [ "303742604",[14.813492803941031, 48.31988805587632]],
    [ "Schützenstraße",[12.175686200000001, 47.58168265]],
    [ "304576431",[14.288024830292237, 48.431665227569766]],
    [ "E-Werk Perg",[14.680759931073663, 48.2854099709697]],
    [ "304799384",[14.818121124620525, 48.323997282185466]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.93287664898559, 47.620488401600305]],
    [ "Stadtbetriebe Mariazell",[15.318284646539066, 47.76775298526193]],
    [ "305217693",[13.8707236, 48.093445]],
    [ "Enknach",[13.055202503419292, 48.18301947071619]],
    [ "305517537",[13.2108768854886, 47.702922307609555]],
    [ "Salzburg AG",[13.196166679003463, 47.71617809266638]],
    [ "305540164",[13.194249103503529, 47.62911629321691]],
    [ "Salzburg AG",[13.349358505072733, 47.57930990588203]],
    [ "Salzburg AG",[13.346394065909253, 47.573730345599465]],
    [ "305577647",[13.376935054096592, 47.58387166319321]],
    [ "Salzburg Netz",[13.3883262202112, 47.58626113970821]],
    [ "305580622",[13.420040460844518, 47.58030634287555]],
    [ "305683576",[13.264313599902612, 47.60909335008781]],
    [ "Umformerwerk Bergern",[15.271428073031444, 48.21810797580372]],
    [ "305838395",[13.178553356493236, 47.67355844322056]],
    [ "Wiener Netze",[16.295824322828516, 48.1028472969147]],
    [ "306167878",[14.913707061521002, 48.328458257456475]],
    [ "Energie Ried",[13.433015633611085, 48.26483455382368]],
    [ "306526499",[14.904196266325682, 48.36291809343668]],
    [ "Gmünd Neue Heimat",[13.53302620043798, 46.90843724948362]],
    [ "Ebner Strom GmbH",[14.823728764593941, 48.32804373310795]],
    [ "306636204",[13.855996060930012, 48.09086637739265]],
    [ "306732533",[13.448999765198211, 47.58134825044856]],
    [ "Energie AG",[13.053703191806862, 48.16014505916098]],
    [ "TINETZ",[11.98377186217645, 47.47078670821852]],
    [ "307277838",[13.448572087094757, 47.51996863254269]],
    [ "Netz. OÖ",[13.659211300356287, 47.99415928310683]],
    [ "KNG-Kärnten Netz GmbH",[13.98858825395926, 46.5822683070164]],
    [ "307536391",[15.927751544976692, 46.75896767936034]],
    [ "KNG-Kärnten Netz GmbH",[14.643692, 46.59217885]],
    [ "308049598",[14.833101886619003, 48.27855474480414]],
    [ "Hagleitnerstr.",[12.071103785966509, 47.492844609000166]],
    [ "Salzburg AG",[13.07056295, 47.93874795]],
    [ "Kraftwerk Blasnitzen GmbH",[14.577235549343262, 46.52098844524125]],
    [ "Linz AG",[14.488247356267589, 48.223453717193266]],
    [ "Energie AG",[13.16443755594802, 48.20591300862839]],
    [ "Netz Niederösterreich",[15.40573729586681, 48.30140828896946]],
    [ "Reisach",[13.178066081811009, 48.177996802691496]],
    [ "309477390",[14.576721599999999, 48.5976693]],
    [ "Netz Niederösterreich GmbH",[16.22897618392557, 47.86460396699009]],
    [ "KNG-Kärnten Netz GmbH",[14.39691208606223, 46.68102110041089]],
    [ "KNG-Kärnten Netz GmbH",[14.42111403585583, 46.68420032573117]],
    [ "Kärnten Netz",[14.433318411085748, 46.71787213260378]],
    [ "KNG-Kärnten Netz GmbH",[14.365231693980855, 46.69261692102661]],
    [ "KNG-Kärnten Netz GmbH",[14.377435264963712, 46.71540903554167]],
    [ "KNG-Kärnten Netz GmbH",[14.419477334563934, 46.70322687612184]],
    [ "Kärnten Netz",[14.419015086807926, 46.71575072850818]],
    [ "Linz AG",[14.551533236770979, 48.589681943354826]],
    [ "TST Alberndorf 1",[16.09769684636116, 48.70644841167564]],
    [ "309998811",[13.8626765707778, 48.07926889376947]],
    [ "310614574",[13.97851008308755, 48.152524550780996]],
    [ "Arnfels/Zollhaus",[15.398048646208093, 46.671416150869504]],
    [ "Netz Burgenland",[16.364585408277367, 47.809731393603144]],
    [ "311018271",[14.773613956123809, 48.53017373639278]],
    [ "Mauerkirchen Markt",[13.132127751014941, 48.196897938388595]],
    [ "Netz Niederösterreich GmbH",[16.275334872732437, 47.83216033348257]],
    [ "KNG-Kärnten Netz GmbH",[13.526376590923775, 46.8421932098217]],
    [ "311693049",[13.867356693861181, 48.082675297717955]],
    [ "Mauerkirchen Schloßwiese",[13.126521080785462, 48.19338359761224]],
    [ "Energie AG",[13.122540575200514, 48.1853976228292]],
    [ "Energie AG",[13.128928450000002, 48.189879149999996]],
    [ "Mauerkirchen Schmitzb. Str.",[13.133225599336178, 48.186273034437036]],
    [ "Wiener Netze",[16.296834184547407, 48.10967109258262]],
    [ "Wiener Netze",[16.21589501954012, 47.977178091035405]],
    [ "Kraftwerk Haim",[11.59518024973854, 47.29890000732111]],
    [ "Mauerkirchen Mitte",[13.138479580850452, 48.19002865703787]],
    [ "Salzburg AG",[13.093008834417038, 47.68543506688126]],
    [ "312302451",[14.16153435629438, 47.80216473193458]],
    [ "SalzburgAG",[12.846638549999998, 47.42399919999999]],
    [ "Energie AG",[13.834488119762707, 48.60763044281774]],
    [ "Energie AG",[13.469961201767617, 48.47033297056906]],
    [ "KNG-Kärnten Netz GmbH",[14.452602513074233, 46.64910743191495]],
    [ "KNG-Kärnten Netz GmbH",[14.474545348014825, 46.64885401917338]],
    [ "313064961",[13.581290771378896, 47.07932890922683]],
    [ "313074939",[13.471614746474101, 47.10782199836904]],
    [ "Wiener Netze",[16.3035721, 48.10936885]],
    [ "313200197",[13.955586358977676, 48.07646335004816]],
    [ "313354390",[13.133061120626122, 48.199318524264015]],
    [ "Linz AG",[14.492617332545416, 48.379808273302956]],
    [ "313539656",[13.864754480918128, 47.074100255560516]],
    [ "313541478",[13.836789033589032, 47.07314410110793]],
    [ "313543530",[13.81714692378844, 47.077162568684955]],
    [ "313548294",[13.218019282756783, 47.39671185778432]],
    [ "313568890",[13.690380005747757, 47.08151554625291]],
    [ "313568891",[13.614474656793833, 47.094672018939896]],
    [ "313568892",[13.615728082109186, 47.0963584115775]],
    [ "Salzburg AG",[13.667750409784158, 47.095702272414734]],
    [ "313571242",[13.74729225536449, 47.07260715417239]],
    [ "313571245",[13.737324843065156, 47.07688983969054]],
    [ "Salzburg AG",[13.762499286824484, 47.06605049981923]],
    [ "Salzburg AG",[13.717344103924884, 47.08756179499796]],
    [ "313572045",[13.776739141336781, 47.06261257411286]],
    [ "313572685",[13.730439788343219, 47.07789938115008]],
    [ "313573733",[13.788221985358495, 47.12752411000516]],
    [ "Salzburg AG",[13.81297137634906, 47.12239836767497]],
    [ "313678174",[13.380338471341723, 47.449275010719944]],
    [ "313682495",[13.386310807099475, 47.41548694675603]],
    [ "313682496",[13.366754415352913, 47.422005297513444]],
    [ "313684020",[13.315570908973134, 47.417282286556386]],
    [ "313684022",[13.353028857448205, 47.422827254892155]],
    [ "313684761",[13.30694598057193, 47.420055996796194]],
    [ "313686866",[13.267498766617884, 47.4750834679168]],
    [ "313686869",[13.26181875961787, 47.467886019617275]],
    [ "313690363",[13.226408056008466, 47.429340091470536]],
    [ "313690367",[13.214225229340151, 47.43795150443146]],
    [ "313695184",[13.389731900577631, 47.37080620213524]],
    [ "313695185",[13.385998792069785, 47.3719513311991]],
    [ "313695189",[13.403951796751006, 47.38839275285232]],
    [ "313696767",[13.455814641371013, 47.37428970975359]],
    [ "313696787",[13.424278200172512, 47.37196339901482]],
    [ "313696788",[13.421889462535047, 47.368188347298236]],
    [ "313697651",[13.484203179524657, 47.396288195058204]],
    [ "313697655",[13.486140002459807, 47.38827040618311]],
    [ "313702140",[13.559167831404446, 47.42779073670201]],
    [ "313702141",[13.504626300593301, 47.3905057696665]],
    [ "313702145",[13.556267806450105, 47.40369846908267]],
    [ "313702156",[13.524655802911514, 47.39741246240898]],
    [ "313703569",[13.50080432661976, 47.43407030922622]],
    [ "313703570",[13.493495661391655, 47.43271908336822]],
    [ "313721452",[13.497273736332826, 47.268892829397274]],
    [ "313721817",[13.513515999596883, 47.25157240026944]],
    [ "313727389",[13.591157959500359, 47.105538270447695]],
    [ "313729437",[13.496385681178614, 47.158083757511044]],
    [ "313729907",[13.484728617050154, 47.16058444729136]],
    [ "313758294",[13.215952000976566, 47.36865055194015]],
    [ "Salzburg Netz",[13.106217553052675, 47.93102496304565]],
    [ "Salzburg AG",[13.149171726261217, 47.93064193270761]],
    [ "Salzburg AG",[13.142246808258948, 47.92306284822229]],
    [ "Salzburg Netz",[13.174298066426216, 47.946223146539666]],
    [ "313764679",[13.168892560575301, 47.89954376074067]],
    [ "Salzburg AG",[13.17590178409144, 47.905009320842886]],
    [ "Salzburg AG",[13.179658165874383, 47.90892998145659]],
    [ "Salzburg Netz",[13.143815133818444, 47.87234509419506]],
    [ "Salzburg Netz",[13.1402340246741, 47.87175204971865]],
    [ "Salzburg Netz",[13.121690271302507, 47.88421586867157]],
    [ "Salzburg Netz",[13.122930819061096, 47.879366409668776]],
    [ "Plankenau BGV",[13.18538608794842, 47.32058552855032]],
    [ "EAG",[13.196867797459717, 47.22376355005362]],
    [ "313794535",[12.247622936097818, 47.24511509970745]],
    [ "Salzburg Netz",[13.05285432237681, 47.88030340505322]],
    [ "Salzburg Netz",[12.96872896041193, 47.92470629498198]],
    [ "Salzburg Netz",[12.952768622448572, 47.93469033237148]],
    [ "Salzburg AG",[12.896438462485065, 47.99426729440211]],
    [ "Salzburg AG",[12.91466715706503, 47.96217142761844]],
    [ "Energie AG",[12.895523056402437, 48.007018545186064]],
    [ "Energie AG",[12.889639402294742, 48.00947334971904]],
    [ "Neuhausen",[12.90313671019214, 48.04334720607963]],
    [ "St. Pantaleon Seeleiten",[12.890034250259442, 48.01671414837313]],
    [ "Wildshut Sdlg.",[12.870797800601665, 48.00831329954095]],
    [ "Wildshut Ort",[12.871569846174426, 48.00474454404506]],
    [ "Salzburg AG",[12.981243307196735, 48.01761999993575]],
    [ "Salzburg AG",[13.0602664, 47.99284530000001]],
    [ "Berndorf Grub",[13.04413152527988, 47.979089564577265]],
    [ "Salzburg AG",[13.053825449999998, 47.94331855]],
    [ "Salzburg AG",[13.04024355, 47.944213950000005]],
    [ "Salzburg Netz",[12.998481103228107, 47.954909229252706]],
    [ "Salzburg AG",[13.005620006298797, 47.9503937595136]],
    [ "Salzburg AG",[12.98553131386903, 47.960223255043175]],
    [ "Salzburg AG",[12.96704461720832, 47.976430440668224]],
    [ "Salzburg Netz",[13.010050014526373, 47.973500127529185]],
    [ "Salzburg Netz",[13.011114775926908, 47.96715340627121]],
    [ "Salzburg Netz",[13.015085660672806, 47.9839377267452]],
    [ "Salzburg AG",[13.016078672262143, 47.935055144534985]],
    [ "Asperting",[13.072126500000001, 47.98518675]],
    [ "Hochfeld",[13.328308295700184, 47.96857505099879]],
    [ "Riemerhof",[13.270845244464173, 47.98296398944521]],
    [ "Energie AG",[13.29799456236287, 47.97223785462393]],
    [ "Irrsdorf",[13.281663703136646, 47.96807815675959]],
    [ "Salzburg AG",[13.176548329791064, 47.95534656259658]],
    [ "Salzburg AG",[13.124985574957282, 47.97215804351442]],
    [ "Salzburg Netz",[13.15285712150329, 47.95480922661066]],
    [ "Salzburg AG",[13.219219605049254, 47.90518627990098]],
    [ "Salzburg AG",[13.206273626196648, 47.90838127440815]],
    [ "Salzburg AG",[13.210693318126799, 47.91271141496063]],
    [ "Salzburg AG",[13.22205752106112, 47.92314535892452]],
    [ "Salzburg AG",[13.226046644748198, 47.93496108552919]],
    [ "Salzburg Netz",[13.222855902894693, 47.94895850051873]],
    [ "Salzburg AG",[13.238685766327434, 47.934158145300636]],
    [ "Salzburg AG",[13.227811050142984, 47.9451505680852]],
    [ "Salzburg AG",[13.248488346253353, 47.94828123798325]],
    [ "Salzburg AG",[13.242566834427441, 47.95971931274344]],
    [ "Energie AG",[13.8967046, 48.098034850000005]],
    [ "Salzburg AG",[12.688339350733488, 47.448069101973836]],
    [ "Wiener Netze",[16.290722126488347, 48.07390139589599]],
    [ "314076127",[14.24311938709815, 48.35868517424713]],
    [ "314076131",[14.241016717463726, 48.362585345568014]],
    [ "Salzburg AG",[13.278794806973698, 47.82210239041408]],
    [ "Salzburg AG",[13.21664575906745, 47.82554061946568]],
    [ "Salzburg AG",[13.249865083664968, 47.82477511849596]],
    [ "Salzburg AG",[13.279129931404528, 47.83941670763415]],
    [ "Salzburg AG",[13.261115267239246, 47.86147196079075]],
    [ "Salzburg AG",[13.26780150176143, 47.85553105521697]],
    [ "Salzburg AG",[13.243404622729026, 47.8610340924647]],
    [ "Salzburg AG",[13.227834564304878, 47.850623826401254]],
    [ "Salzburg AG",[13.253769458968373, 47.807719120970575]],
    [ "Salzburg AG",[13.337598469435047, 47.78722514562232]],
    [ "Salzburg AG",[13.34982966673695, 47.77860308547275]],
    [ "Salzburg AG",[13.360881799191967, 47.774038915424114]],
    [ "Salzburg AG",[13.224681316477927, 47.85494503525954]],
    [ "Salzburg AG",[13.1768385021514, 47.85496346488129]],
    [ "Salzburg AG",[13.163795775503829, 47.855872554574326]],
    [ "Salzburg AG",[13.137705586464346, 47.842620105453754]],
    [ "Salzburg AG",[13.192315998080261, 47.81876409598796]],
    [ "Salzburg AG",[13.211938554235234, 47.81834965150762]],
    [ "Salzburg AG",[13.17940464929775, 47.7852276002726]],
    [ "Salzburg AG",[13.166409040207078, 47.79359110795344]],
    [ "Salzburg AG",[13.25377902021464, 47.76750731071968]],
    [ "Salzburg AG",[13.302195591553707, 47.77411916223683]],
    [ "Salzburg AG",[13.273694504872747, 47.76823192808874]],
    [ "Salzburg AG",[13.335993447106482, 47.76362621347334]],
    [ "Salzburg AG",[13.3551791833067, 47.751161891975876]],
    [ "Salzburg AG",[13.425764402738745, 47.730660047566786]],
    [ "Salzburg AG",[13.462327837777458, 47.71246510794879]],
    [ "Netz Oberösterreich",[13.495620295823853, 47.72539700048994]],
    [ "Netz Oberösterreich",[13.495468075121092, 47.732530450731105]],
    [ "Netz Oberösterreich",[13.48916743330374, 47.73276577347287]],
    [ "Netz Oberösterreich",[13.519775633850584, 47.73006412902942]],
    [ "Salzburg AG",[13.428837440468493, 47.69731696325825]],
    [ "Salzburg AG",[13.437838525012227, 47.68822198412135]],
    [ "Salzburg AG",[13.19985100256036, 47.78673302239436]],
    [ "Salzburg AG",[13.194334331796195, 47.787767081852024]],
    [ "TST Kleinhöflein 1",[16.000486361033033, 48.748190797548226]],
    [ "Alpstraße",[9.654536833202254, 47.42187504942379]],
    [ "Salzburg AG",[13.190732698323332, 47.46964696405645]],
    [ "Salzburg AG",[13.182476539409418, 47.47142794845714]],
    [ "Salzburg AG",[13.207939732016259, 47.590767414106296]],
    [ "Salzburg AG",[13.022270802334203, 47.291214608073595]],
    [ "Salzburg AG",[13.033077943172904, 47.300232352385656]],
    [ "Salzburg AG",[13.014097153705984, 47.38850323753103]],
    [ "Salzburg AG",[13.10410565081539, 47.385011347976615]],
    [ "Salzburg AG",[13.119631081780453, 47.3785813048517]],
    [ "Salzburg AG",[13.092888190882347, 47.39236489988983]],
    [ "Salzburg AG",[13.113214947452716, 47.383009878465124]],
    [ "Salzburg AG",[13.126650439545514, 47.38047449768716]],
    [ "Salzburg AG",[13.153207518213707, 47.38259075520605]],
    [ "314833158",[13.713401642701914, 48.41635069462169]],
    [ "Salzburg AG",[12.934087782938233, 47.2931577423593]],
    [ "Salzburg AG",[12.873199536845824, 47.2889299950941]],
    [ "Salzburg AG",[12.891212918360635, 47.29004625641614]],
    [ "Salzburg AG",[12.915707474366226, 47.290094198584605]],
    [ "Salzburg AG",[12.77499951608957, 47.283803665224454]],
    [ "Salzburg AG",[12.693418998086122, 47.289626852090734]],
    [ "Salzburg AG",[12.700208652189922, 47.280555058321994]],
    [ "Energie AG",[13.814987552734655, 48.10105785080432]],
    [ "Salzburg AG",[12.622563397564516, 47.281731817391695]],
    [ "Salzburg AG",[12.644626406747774, 47.279984449688364]],
    [ "Salzburg AG",[12.627958047909651, 47.28053984900963]],
    [ "Salzburg AG",[12.570089665118903, 47.279374521344394]],
    [ "Salzburg AG",[12.561869847989986, 47.28042872238444]],
    [ "Salzburg AG",[12.494329549418833, 47.28548628778454]],
    [ "Salzburg AG",[12.494891614094795, 47.28783308090332]],
    [ "Salzburg AG",[12.148717694158487, 47.249329801403164]],
    [ "Salzburg AG",[12.209107269974824, 47.241274425370094]],
    [ "Salzburg AG",[12.681616199432641, 47.37780585093111]],
    [ "Salzburg AG",[12.725679056164726, 47.36587407993358]],
    [ "Salzburg AG",[12.788933928434354, 47.362087033272864]],
    [ "Salzburg AG",[12.797719337253179, 47.35684622377889]],
    [ "Salzburg AG",[12.846810220588587, 47.334039432544174]],
    [ "Salzburg AG",[12.82287306644839, 47.331082050425785]],
    [ "Salzburg AG",[12.81571181685228, 47.31388912276178]],
    [ "Oberspraidt",[13.11021970607447, 48.22808436231495]],
    [ "Salzburg AG",[12.754348266461696, 47.44586351733648]],
    [ "Salzburg AG",[12.78518235011724, 47.439678997325686]],
    [ "Salzburg AG",[12.76378928620493, 47.43263049510947]],
    [ "Salzburg AG",[12.757606831077926, 47.439290882830804]],
    [ "Salzburg AG",[12.70159715171665, 47.44000206101336]],
    [ "Salzburg AG",[12.7242879, 47.44069305000001]],
    [ "Salzburg AG",[12.620728938126614, 47.4161992272086]],
    [ "Salzburg AG",[12.635697254378265, 47.396426982371885]],
    [ "Salzburg AG",[12.668449330122026, 47.42730369187309]],
    [ "Salzburg AG",[12.756167415524265, 47.51992550566959]],
    [ "Salzburg AG",[13.190392818344217, 47.89073396533527]],
    [ "Salzburg AG",[13.188027173731255, 47.89184344429335]],
    [ "Salzburg AG",[13.183400038734012, 47.893927424878235]],
    [ "Salzburg AG",[13.190665982863182, 47.896195407620354]],
    [ "Salzburg AG",[13.187419028061273, 47.89771395379686]],
    [ "Salzburg AG",[13.201893974428957, 47.908226388042415]],
    [ "Salzburg AG",[13.189413630845712, 47.882247355635]],
    [ "Salzburg AG",[13.193124921567955, 47.89858396605458]],
    [ "Salzburg AG",[13.1922238, 47.900886500000006]],
    [ "Salzburg AG",[13.185906235204472, 47.9008850759057]],
    [ "Energie AG",[13.993721348451334, 48.50455929860254]],
    [ "Buch b. St. Peter",[13.136950301929728, 48.24283267378217]],
    [ "Salzburg AG",[13.253540866381732, 47.71673827078908]],
    [ "Salzburg AG",[13.274726834525653, 47.72530691901902]],
    [ "Salzburg AG",[13.392370706412725, 47.30776299156857]],
    [ "EAG",[13.214746105067713, 47.23775425503026]],
    [ "315737842",[14.856256358859795, 48.34130975287025]],
    [ "Unterwerk Reith",[12.889615249999999, 47.9950698]],
    [ "Burgkirchen",[13.10130194609429, 48.20508581486507]],
    [ "Energie AG",[14.052071732070141, 48.576848405139394]],
    [ "Salzburg AG",[13.06191471006229, 47.859873889269885]],
    [ "Salzburg AG",[12.828803826635522, 47.21635520763838]],
    [ "Salzburg AG",[12.832820284087601, 47.20226501937567]],
    [ "Burgkirchen Landw. Schule",[13.083906158589716, 48.2100619402024]],
    [ "Burgkirchen Siedlung",[13.098296100576336, 48.20445803674821]],
    [ "Kuglberg",[13.154222152889202, 48.08656055463521]],
    [ "317265481",[14.248650577996937, 48.29155399331014]],
    [ "Energie Ried",[13.464803569948579, 48.212495030380374]],
    [ "Fraham Siedlung",[13.080446542242651, 47.979427331664205]],
    [ "Salzburg AG",[13.07038675, 47.963960099999994]],
    [ "Wiener Netze",[16.294042882989903, 48.09505084901597]],
    [ "Energie AG",[13.908772460002902, 48.546146787085036]],
    [ "Wiener Netze",[16.293076150000005, 48.114339050000005]],
    [ "Wiener Netze",[16.296837427795964, 48.114546947890716]],
    [ "Umspannwerk Schwarzenbach",[12.583373796106931, 47.27851048556754]],
    [ "318701597",[16.22126145, 48.0805312]],
    [ "Wiener Netze",[16.228283173880165, 48.07790444654672]],
    [ "Wiener Netze",[16.235551823904057, 48.080794183383766]],
    [ "Wiener Netze",[16.24155835, 48.0847739]],
    [ "Wiener Netze",[16.232075500000004, 48.07253645000001]],
    [ "Wiener Netze",[16.25263198323206, 48.08825667488092]],
    [ "Netz Oberösterreich GmbH",[13.117642075586872, 48.15472093457708]],
    [ "Linz AG",[14.41129374182931, 48.27381660007889]],
    [ "Sulzau Siggen",[12.250523993629482, 47.233489016937895]],
    [ "319178647",[13.781482603165903, 48.04203219899309]],
    [ "Netz OÖ",[13.789928175668399, 48.04105425030228]],
    [ "Energie AG",[13.788041507241578, 48.05664823554483]],
    [ "Sattledt Siedlung",[14.064257930340586, 48.077427005351346]],
    [ "Energie AG",[13.961727310200365, 48.44731171103046]],
    [ "Stadtwerke Schwaz",[11.70036201720498, 47.34695924876821]],
    [ "Wiener Netze",[16.28332454950731, 48.11094704111131]],
    [ "Aching",[13.072399149781042, 48.23383875364415]],
    [ "Wiener Netze",[16.19008405, 48.07162844999999]],
    [ "Wiener Netze",[16.184640920375333, 48.07529897059876]],
    [ "Energie AG",[13.811771725076296, 48.32354148708189]],
    [ "Energie AG",[13.774444063358654, 48.28634119172704]],
    [ "Kärnten Netz",[14.408705860060595, 46.66592915891476]],
    [ "Wiener Netze",[16.239593470494878, 48.0880715539654]],
    [ "320416620",[13.753081330300951, 47.145778346202555]],
    [ "320419147",[13.656294966689384, 47.06237120296491]],
    [ "Umspannwerk Leonding",[14.22624183086191, 48.26888449951086]],
    [ "Hörsching Sportplatzstraße",[14.1815363, 48.22336385]],
    [ "Wiener Netze",[16.25052588155703, 48.092492766367535]],
    [ "Wiener Netze",[16.24418165, 48.0895768]],
    [ "Wiener Netze",[16.2455395, 48.08628249999999]],
    [ "KNG-Kärnten Netz GmbH",[14.615333196471624, 46.871477441802554]],
    [ "Mühlheim",[13.217958883880076, 48.281219070757736]],
    [ "Energie AG",[13.246648038120107, 48.281445550069606]],
    [ "Wiener Netze",[16.307130627482152, 48.101211771393814]],
    [ "Wiener Netze",[16.2897842, 48.02786295000001]],
    [ "Salzburg AG",[13.773471526679062, 46.98479150061456]],
    [ "320928666",[13.754619101710123, 48.275856750568316]],
    [ "Energie AG",[13.787982715683583, 48.3002254471861]],
    [ "Energie AG",[13.713326607365644, 48.2809605535905]],
    [ "Netz Niederösterreich GmbH",[15.827840250000003, 47.6351779]],
    [ "Energie AG",[13.73707290105269, 48.274768241219235]],
    [ "321177839",[13.832987347422034, 48.03498334670409]],
    [ "Hausruck Roith",[13.569967711683953, 48.13054207543469]],
    [ "Energie AG",[13.564426757878273, 48.13062038373517]],
    [ "Energie AG",[13.549913807596045, 48.12618233111245]],
    [ "Umspannwerk Halbenrain",[15.957239404495292, 46.718504563638135]],
    [ "321321458",[15.956978952783162, 46.71852908951057]],
    [ "KNG-Kärnten Netz GmbH",[14.14308464063641, 46.82988424597291]],
    [ "St. Peter Eichenweg",[15.4730083430484, 47.04990323205826]],
    [ "Novalisgasse",[15.471790912151322, 47.0474761202495]],
    [ "Umspannwerk Wilhelmsburg",[15.613292334720441, 48.11088858082103]],
    [ "Salzburg Netz",[13.036173267348788, 47.89902187994104]],
    [ "Salzburg Netz",[13.050413965701232, 47.89025230907581]],
    [ "322611894",[13.953047589507856, 48.13591229540257]],
    [ "Salzburg AG",[13.274487070798495, 47.83751739469447]],
    [ "Thalgau Freibad",[13.251794874766908, 47.8382223688348]],
    [ "Energie AG",[13.267579605528512, 47.83738700953783]],
    [ "Matzing Edt",[13.060965806577064, 47.96574107880033]],
    [ "Salzburg Netz",[13.070951281146469, 47.97902779499078]],
    [ "323446564",[13.991444016976912, 48.1681906481533]],
    [ "Wiener Netze",[16.3003822692039, 48.021907351795655]],
    [ "Mining",[13.164467638607242, 48.275225747940404]],
    [ "323781443",[13.07215580016967, 47.958885900251666]],
    [ "KNG-Kärnten Netz GmbH",[13.62316255755057, 46.79130321715602]],
    [ "KNG-Kärnten Netz GmbH",[13.634997830580865, 46.78282718925674]],
    [ "324045685",[13.648019755822203, 47.621708908008536]],
    [ "Umspannwerk Peigarten",[16.05006173395132, 48.69744603842469]],
    [ "Gundholling",[13.194203924879238, 48.27039718744187]],
    [ "Amberg",[13.171050169451197, 48.270060624750315]],
    [ "Salzburg Netz",[13.079323800000001, 47.99902719999999]],
    [ "Haiding Steinitwerk",[13.977048491334807, 48.208432559613605]],
    [ "Netz OÖ",[13.618383700439557, 47.64275206660576]],
    [ "Wiener Netze",[16.32188231851381, 48.13288794861555]],
    [ "Wiener Netze",[16.31650545, 48.118234199999996]],
    [ "Wiener Netze",[16.315396824151783, 48.11967589512315]],
    [ "Wiener Netze",[16.28516970807512, 48.11925612657006]],
    [ "Frauenstein",[13.170251199470934, 48.28516829063647]],
    [ "Wiener Netze",[16.304045779783767, 48.12208528355948]],
    [ "Schloss Waasen",[15.536709212686093, 46.93224267770747]],
    [ "WHB PV-Park GmbH",[13.063250951498956, 47.98788458340973]],
    [ "Energie AG",[13.426587108493242, 48.31854055241071]],
    [ "Umspannwerk Stollhofen",[15.757263522245387, 48.356960531348854]],
    [ "KNG-Kärnten Netz GmbH",[14.230541354313937, 46.673688790122995]],
    [ "TST Seebenstein Friedhof",[16.156766349999998, 47.700446649999996]],
    [ "TST Seebenstein Kläranlage",[16.1303624, 47.68709915]],
    [ "Gstocket Bahn",[13.95578882302423, 48.30636684740055]],
    [ "326487375",[14.453779703163569, 48.500417663814424]],
    [ "Salzburg Netz",[13.06299925, 47.99627785]],
    [ "Salzburg Netz",[13.063597230013155, 47.99660611475029]],
    [ "Salzburg Netz",[13.067760140799633, 47.999125367382604]],
    [ "Wiener Netze",[16.25725015, 48.116355000000006]],
    [ "KNG-Kärnten Netz GmbH",[12.804491825466256, 47.055353695791105]],
    [ "Energie AG",[13.9223910723809, 48.46406449668665]],
    [ "Salzburg Netz",[13.061806, 47.999445650000006]],
    [ "Energie AG",[13.521211351728839, 47.56768801697943]],
    [ "Energie AG",[13.897090087835377, 48.61670537690078]],
    [ "Energie AG",[13.902840797923453, 48.61570712670531]],
    [ "Energie AG",[13.998723286593442, 48.570839223328434]],
    [ "Energie AG",[14.033121101411192, 48.58695150433079]],
    [ "Salzburg AG",[13.207885655255163, 47.852964322799636]],
    [ "327638717",[12.254084617886347, 47.23721597606528]],
    [ "Bramberg Eder",[12.334235966775774, 47.266767511898394]],
    [ "Gurten Bahnhof",[13.33483549218841, 48.2449573960617]],
    [ "328043006",[12.27108545, 47.25128309999999]],
    [ "Netz Oberösterreich",[13.986374728485409, 48.43391515607606]],
    [ "Haberbach II",[15.51962806913835, 47.053530875108365]],
    [ "Hart Holzerhofstrasse",[15.521523916495182, 47.05955675217115]],
    [ "Energie AG",[14.110965394345527, 48.5104111239245]],
    [ "Wiener Netze",[16.303609299999998, 48.11417945]],
    [ "Linz AG",[14.566706505761346, 48.591464706604334]],
    [ "Rosental Bergweg",[15.12554433269557, 47.05113605124878]],
    [ "Bad Schallerbach Kurpark",[13.914418549999997, 48.2268147]],
    [ "Salzburg AG",[13.07303705, 47.93907205000001]],
    [ "Ebner Strom GmbH",[14.840583840701411, 48.31548052130228]],
    [ "Salzburg AG",[13.07093556226999, 47.93661162820388]],
    [ "Salzburg AG",[13.074620980180361, 47.93707574677121]],
    [ "Wiener Netze",[16.29169412440911, 48.10567054129439]],
    [ "Linz AG",[14.517521484058216, 48.55593770232526]],
    [ "Umspannwerk Pöls",[14.5905722, 47.2140906]],
    [ "Linz AG",[14.563632257715637, 48.584830262406044]],
    [ "329004661",[14.560254431688325, 48.58759838402139]],
    [ "Energie AG",[14.347178343966064, 47.96570391556697]],
    [ "Grünwald",[13.98818389977046, 48.66835540068243]],
    [ "Energie AG",[13.964249363857737, 48.62792139525826]],
    [ "Energie AG",[13.849040268488737, 48.6285116937349]],
    [ "329182218",[14.5528112, 48.54347255]],
    [ "Energie AG",[14.237425898572502, 48.56460410309168]],
    [ "Salzburg AG",[13.078307456743078, 47.93594528223563]],
    [ "Bad Hall - Neumühle",[14.198031599999998, 48.038052449999995]],
    [ "329466059",[14.613839451053453, 48.5470723666732]],
    [ "Linz AG",[14.522613832804378, 48.54545664430007]],
    [ "Linz AG",[14.53269344910775, 48.53678974035555]],
    [ "Linz AG",[14.570986644065751, 48.526632014047024]],
    [ "Linz AG",[14.440575893338664, 48.28345627398315]],
    [ "329535948",[14.469010648120435, 48.63028135179325]],
    [ "329540673",[14.638396695926692, 48.580954830338214]],
    [ "Energie AG",[13.880792146373299, 48.463242540750535]],
    [ "Energie AG",[13.93679519171398, 48.49957394442764]],
    [ "Salzburg Netz",[13.068330412770592, 47.94119021882551]],
    [ "329751729",[13.974418257018371, 48.20944973758664]],
    [ "Energie AG",[14.045025248508203, 48.58099900161481]],
    [ "Energie AG",[14.035162951215653, 48.59188275469182]],
    [ "Umspannwerk Lassee",[16.799480175426776, 48.222881863138184]],
    [ "330049219",[15.96588294514466, 46.76098672418186]],
    [ "Salzburg AG",[13.190832537727212, 47.47981594513822]],
    [ "Energie AG",[13.81058653760348, 48.55710959299487]],
    [ "Energie AG",[13.828021989519236, 48.50333900033475]],
    [ "Energie AG",[13.817259151435257, 48.50304959661538]],
    [ "Energie AG",[13.815911394197744, 48.483576852014814]],
    [ "Energie AG",[13.809628647089616, 48.48177670004801]],
    [ "Energie AG",[13.782593984235772, 48.50394020604814]],
    [ "Energie AG",[13.78320616084299, 48.50698591381044]],
    [ "Energie AG",[13.771905238666097, 48.55076039139891]],
    [ "Energie AG",[13.801929248632097, 48.54184526195049]],
    [ "Energie AG",[13.830909690681718, 48.57277149931659]],
    [ "Salzburg Netz",[13.062727400000002, 47.93815834999999]],
    [ "Schalchham",[13.674788797239033, 47.99857764836172]],
    [ "Energie AG",[14.053239911938675, 48.44220187517247]],
    [ "Energie AG",[14.076100485817742, 48.50184578039359]],
    [ "Energie AG",[14.071197785753041, 48.49931066748736]],
    [ "Salzburg Netz",[13.026830059898659, 47.94389925664133]],
    [ "Energie AG",[13.875432703518705, 48.517595942060865]],
    [ "330590600",[14.24953845, 48.2439827]],
    [ "Linz AG",[14.493676200477424, 48.5358226967537]],
    [ "Energie AG",[13.867503402622383, 48.65719819586936]],
    [ "Energie AG",[13.876753483884364, 48.588290743776426]],
    [ "Energie AG",[13.835521362120875, 48.46447293896809]],
    [ "Energie AG",[14.160752964193765, 48.46724382524697]],
    [ "Energie Steiermark",[15.531190176107305, 47.05229456790976]],
    [ "Energie AG",[13.840259223108944, 48.603369019034346]],
    [ "Energie AG",[13.905568475335889, 48.54295215070416]],
    [ "Salzburg AG",[13.08292356324758, 47.93362492579763]],
    [ "Linz AG",[14.456878710623966, 48.55104190082254]],
    [ "Linz AG",[14.46133891270611, 48.5553641837719]],
    [ "Energie AG",[13.904444635892252, 48.47449897284562]],
    [ "Energie AG",[13.989533600728825, 48.45606429372961]],
    [ "Energie AG",[13.9868062389908, 48.484838518216634]],
    [ "Energie AG",[13.997642953621028, 48.484227083019704]],
    [ "331331317",[13.914993, 48.12757800000001]],
    [ "Perwang Gewerbe",[13.087068749681016, 48.01014873975562]],
    [ "Linz AG",[14.4481449128128, 48.55640851246238]],
    [ "Energie AG",[14.014829547445125, 48.50248775320008]],
    [ "Energie AG",[14.053549758259788, 48.57231033777724]],
    [ "Energie AG",[13.984249263775197, 48.57255386672473]],
    [ "Energie AG",[13.964422033384594, 48.48182545621766]],
    [ "Energie AG",[13.974122846834128, 48.48149579654579]],
    [ "331586842",[13.832748012756287, 48.15501492031959]],
    [ "Energie Ried",[13.435430808939438, 48.283932252499035]],
    [ "Energie AG",[13.91477322841999, 48.238128608331394]],
    [ "Energie Ried",[13.441708295155154, 48.286433962161894]],
    [ "Neufelden Wählamt",[13.992503999999997, 48.4837812]],
    [ "Energie AG",[14.068364724811625, 48.40088830382367]],
    [ "Energie AG",[13.761034127259137, 48.52808158299536]],
    [ "Energie AG",[14.146448488247348, 48.54590439290939]],
    [ "Traun Prandtauerweg",[14.239580955934487, 48.22820838155206]],
    [ "KNG-Kärnten Netz GmbH",[14.370392399999998, 46.777245900000004]],
    [ "KNG-Kärnten Netz GmbH",[14.358368983489832, 46.77500608324602]],
    [ "Anzberg Weg",[13.507955196775074, 48.55065842038609]],
    [ "TST Bad Schönau Am Kurpark",[16.23187139646161, 47.493708117204854]],
    [ "Linz AG",[14.447084104963855, 48.54963167544664]],
    [ "Salzburg AG",[13.078031536786002, 47.9290148288616]],
    [ "Energie AG",[13.994177205391471, 48.63290115987733]],
    [ "Energie AG",[14.164358092408905, 48.42764344753434]],
    [ "Energie AG",[13.69580189923827, 47.995367643129995]],
    [ "Obertrum Lindenhof",[13.07475355, 47.923773849999996]],
    [ "Linz AG",[14.577008611791953, 48.50131677225648]],
    [ "Linz AG",[14.582344802723881, 48.50812493423629]],
    [ "332681374",[14.615349398062667, 48.48288954397354]],
    [ "Energie AG",[14.141961216708086, 48.54338116832603]],
    [ "Energie AG",[14.11768463596901, 48.52011301279268]],
    [ "Sattledt Ind.",[14.059016815922586, 48.07837709548153]],
    [ "Wiener Netze",[16.353166549999997, 48.080192999999994]],
    [ "Wiener Netze GmbH",[16.3615961, 48.08267615]],
    [ "Energie AG",[13.862111417082062, 48.53330054010923]],
    [ "Energie Ried",[13.438629995245071, 48.293833226380855]],
    [ "Energie AG",[13.82457650093126, 48.241039523644545]],
    [ "333470700",[13.697399525461899, 47.983694701247096]],
    [ "Energie AG",[13.714523816601181, 47.96096375542324]],
    [ "Unterkrieh",[13.71024549585427, 47.96341172499999]],
    [ "EWW",[13.998598954580178, 48.17130408405739]],
    [ "Frankenmarkt",[13.41744756316269, 47.99017107127971]],
    [ "Salzburg AG",[13.062165532389283, 47.924968566257384]],
    [ "Salzburg Netz",[13.053960368466385, 47.92582165290031]],
    [ "Wiener Netze",[16.255238207987293, 48.086470730215005]],
    [ "Energie AG",[13.897608563041324, 48.548039763430054]],
    [ "Netz Oberösterreich",[13.127215733147883, 48.00866994866018]],
    [ "Krenglbach Ort",[13.954752732198852, 48.2046041749012]],
    [ "Wiener Netze",[16.258443950000004, 48.09068455]],
    [ "EWW",[14.01879993189698, 48.17315246159471]],
    [ "Wiener Netze",[16.404099799999994, 47.96461329999999]],
    [ "Wiener Netze GmbH",[16.4074217, 47.95589305]],
    [ "335583773",[13.102944380671047, 47.97339769478876]],
    [ "335728197",[14.414899122135123, 48.52685144253632]],
    [ "335812357",[13.4910776, 48.215217100000004]],
    [ "Wiener Netze",[16.407392716594455, 47.951677448364]],
    [ "Energie AG",[14.065051017623407, 48.41992516272801]],
    [ "KNG-Kärnten Netz GmbH",[14.666190943836956, 46.672498469461566]],
    [ "Energie AG",[14.049397298177743, 48.48894059464568]],
    [ "Vöcklabruck Poschenhof 1",[13.643945736814619, 48.02110186341101]],
    [ "Vöcklabruck Poschenhof 2",[13.641128239299151, 48.02151043833861]],
    [ "Energie Ried",[13.455101579170076, 48.26451360003673]],
    [ "Energie AG",[14.00379617115527, 48.566961794655505]],
    [ "Stölln",[13.712460267735853, 47.959624105072585]],
    [ "Energie AG",[14.014685320933417, 48.6609971291905]],
    [ "KNG-Kärnten Netz GmbH",[14.861066357951042, 46.85958702357234]],
    [ "Energie Ried",[13.482693401264015, 48.26485173467275]],
    [ "Energie Ried",[13.510427144083257, 48.290078976395336]],
    [ "Energie Ried",[13.525560646697237, 48.303172655640466]],
    [ "Energie Ried",[13.495543480021993, 48.27847655578494]],
    [ "Götzau",[15.56440354489225, 46.931787131012776]],
    [ "Energie AG",[13.439312092656778, 48.4709792117291]],
    [ "336924398",[12.72344456937301, 47.36780750184531]],
    [ "Umspannwerk Regau",[13.715786640920465, 47.98686447451349]],
    [ "KNG-Kärnten Netz GmbH",[14.890940248432207, 46.81820014123364]],
    [ "337239506",[13.85472096920861, 48.175380257842036]],
    [ "337305920",[14.497754299999999, 48.49899704999999]],
    [ "Linz AG",[14.497099097995477, 48.49271577489378]],
    [ "KNG-Kärnten Netz GmbH",[14.8587054676628, 46.847001463416646]],
    [ "Linz AG",[14.49830545, 48.375507150000004]],
    [ "Linz AG",[14.495660537540997, 48.36751713367882]],
    [ "Linz AG",[14.499905703924338, 48.38107197102212]],
    [ "Energie AG",[13.843738647662779, 48.21320910524099]],
    [ "Linz Netz",[14.510551087550159, 48.507812450047254]],
    [ "Linz AG",[14.497101597709802, 48.615088832950576]],
    [ "Regau Mitte",[13.688169299479046, 47.990252050069216]],
    [ "Großendorf",[14.064412943083259, 48.04806961744361]],
    [ "Energie AG",[13.659006356896555, 47.9960811976833]],
    [ "Sattledt - Kirchdorfer Straße",[14.0525066, 48.070124750000005]],
    [ "Wiener Netze",[16.278084300000003, 48.09776705]],
    [ "338301074",[13.150289517083577, 47.87344298424667]],
    [ "338341431",[13.750248850528987, 47.94657750659972]],
    [ "Energie AG",[13.72343052355734, 48.336804105281296]],
    [ "338641193",[13.771193328864523, 48.063208177113964]],
    [ "EWW",[13.97506775, 48.18223495]],
    [ "338728420",[13.803726466567657, 48.096915356398426]],
    [ "TST Gloggnitz Prägasse",[15.93464046993261, 47.67127910962224]],
    [ "Salzburg AG",[13.090304719716054, 47.956727246228176]],
    [ "Salzburg AG",[13.094387763247529, 47.949453644299865]],
    [ "338843370",[16.631078900000002, 47.89311125]],
    [ "Traunstadlweg",[14.236837573867161, 48.21788680262899]],
    [ "338932516",[14.560790140088113, 48.48528369132897]],
    [ "Wiener Netze",[16.321399822519208, 48.079098993705934]],
    [ "Wiener Netze",[16.26809973311613, 48.09798285073824]],
    [ "Preising",[13.707868644282934, 47.99004441182557]],
    [ "Linz AG",[14.47912559886517, 48.4540319834868]],
    [ "339256321",[14.222738609415991, 48.22324895326884]],
    [ "Salzburg AG",[13.078906775252307, 47.96755605534318]],
    [ "Energie AG",[13.682455656264112, 47.99566484928482]],
    [ "Energie AG",[14.32229907784382, 48.02931929332065]],
    [ "Energie AG",[14.325959647253944, 48.03907509318221]],
    [ "Salzburg AG",[13.097848900000002, 47.97096665]],
    [ "339767739",[13.671460617220216, 48.10253364832299]],
    [ "Pumpwerk Dragositschach",[14.088868854356564, 46.54029261392587]],
    [ "Linz AG",[14.508947608199636, 48.40900850635511]],
    [ "Salzburg AG",[13.091445153150993, 47.939904762738244]],
    [ "Energie AG",[14.222717699999999, 48.21538145]],
    [ "Energie AG",[13.642714505233226, 48.005371747917806]],
    [ "Energie AG",[13.706379725226258, 47.9702298853117]],
    [ "340369618",[14.21506360048928, 48.21066946720542]],
    [ "340587709",[13.762456234698918, 48.04644311863321]],
    [ "KWG",[13.769565507168032, 48.05807692196147]],
    [ "Netz NÖ",[16.266023017057673, 47.94527166305689]],
    [ "Wiener Netze",[16.28108775528184, 47.947600376447426]],
    [ "Linz Netz",[14.474478415278138, 48.35508667644558]],
    [ "Linz Netz",[14.477323398445712, 48.34677970430389]],
    [ "Linz Netz",[14.491085548585332, 48.35221754606945]],
    [ "Linz Netz",[14.502456349129984, 48.34806809728868]],
    [ "Linz Netz",[14.507749950000001, 48.35036494999999]],
    [ "Linz Netz",[14.515512115542169, 48.353414025849816]],
    [ "Linz Netz",[14.500265600144395, 48.36015426250228]],
    [ "Linz AG",[14.51055095, 48.33084735]],
    [ "Linz Netz",[14.536014159152035, 48.329059176413224]],
    [ "Linz Netz",[14.524265139895522, 48.328145560838216]],
    [ "Linz Netz",[14.520175574577415, 48.32329761907201]],
    [ "Linz Netz",[14.504802312245795, 48.32730705545715]],
    [ "Linz Netz",[14.49939957587403, 48.330558892862264]],
    [ "Salzburg AG",[13.100101750000002, 47.9589114]],
    [ "Energie AG",[13.727655699186446, 48.31885665267387]],
    [ "Attnang Vöcklabruckerstr.",[13.711754779639554, 48.00411513983851]],
    [ "341626442",[16.797617048872308, 47.82300288925166]],
    [ "Energie AG",[13.558470906733104, 48.15225179242783]],
    [ "Eberschwang Straß",[13.542313092350524, 48.137141847244315]],
    [ "Energie AG",[13.520483756288323, 48.14337762240993]],
    [ "Energie AG",[14.20377234537226, 48.21167592948531]],
    [ "Energie AG",[14.20456150395799, 48.20882291749272]],
    [ "Energie AG",[13.544551344894034, 48.110311545725665]],
    [ "Schärding Stifterstr.",[13.44315105044491, 48.44697409845877]],
    [ "342081749",[14.841059317087584, 48.405305989947955]],
    [ "342158235",[14.750487156317954, 47.6506748084517]],
    [ "Wiener Netze",[16.2835765, 48.08195634999999]],
    [ "Wiener Netze",[16.33970924999999, 47.997559749999986]],
    [ "Wiener Netze",[16.389041623251245, 48.08084874632812]],
    [ "342479488",[14.863908139459856, 48.3962195507615]],
    [ "Energie AG",[13.696466825463784, 47.98443919223291]],
    [ "342544791",[14.882052256747654, 48.39993009925728]],
    [ "342546070",[14.825536673016622, 48.42124452646506]],
    [ "Linz Netz",[14.524332197126618, 48.35973758986948]],
    [ "Unterrohr",[14.193314029699044, 48.06777279696065]],
    [ "Linz Netz",[14.529627118031964, 48.342343402657114]],
    [ "Linz Netz",[14.544106549704457, 48.33756830763037]],
    [ "Linz AG",[14.56578261589728, 48.32164355087465]],
    [ "Linz AG",[14.602698872441694, 48.331473699391616]],
    [ "Linz Netz",[14.537709982303035, 48.35079339337789]],
    [ "Linz Netz",[14.540485683291678, 48.34910489988804]],
    [ "Linz Netz",[14.537213644545275, 48.34835359020605]],
    [ "Linz Netz",[14.542774626049011, 48.34717371594495]],
    [ "Linz Netz",[14.57025761420783, 48.33709104715284]],
    [ "Linz Netz",[14.533719325468443, 48.36268169851047]],
    [ "Linz Netz",[14.537007556413576, 48.368156957855206]],
    [ "Linz Netz",[14.526005399999999, 48.352696449999996]],
    [ "Linz Netz",[14.529555049999999, 48.350336549999994]],
    [ "Linz AG",[14.756021424870996, 48.36281018059235]],
    [ "342885359",[14.746104403078087, 48.36829975781008]],
    [ "Linz AG",[14.624955448603627, 48.327843689524435]],
    [ "342914830",[14.620534932898583, 48.329079207512464]],
    [ "Linz AG",[14.621969707860046, 48.324922869152225]],
    [ "343081761",[14.412963607907697, 48.40508951835769]],
    [ "Linz Netz",[14.444430563328567, 48.36207097231953]],
    [ "Aug",[13.115280717179232, 47.98932565556237]],
    [ "Linz AG",[14.618106029170184, 48.308229449373464]],
    [ "Linz AG",[14.59825551161384, 48.3045716043574]],
    [ "Linz AG",[14.601245817793398, 48.31918729839549]],
    [ "Linz AG",[14.620837319346826, 48.31553374406727]],
    [ "Linz AG",[14.62429973414711, 48.331493211087015]],
    [ "Energie AG",[14.218026245226257, 48.240111857356325]],
    [ "Wiener Netze",[16.299497922193815, 48.08281174204335]],
    [ "KNG-Kärnten Netz GmbH",[14.893936055552224, 46.86455509504022]],
    [ "Energie AG",[14.195842646980331, 48.18906837421306]],
    [ "Energie AG",[14.186620411131404, 48.187456484348715]],
    [ "Netz OÖ",[14.223608442161183, 48.23146301541969]],
    [ "Linz AG",[14.632578565216582, 48.35071300131987]],
    [ "Linz AG",[14.633631146738002, 48.338198716064355]],
    [ "Linz AG",[14.62695001225336, 48.33416419558608]],
    [ "Linz AG",[14.613903072132397, 48.36350865811369]],
    [ "Rackersing",[13.137683691235052, 47.99835173267925]],
    [ "Linz AG",[14.556250073186009, 48.39447965134216]],
    [ "Salzburg AG",[13.105440600000001, 47.9700734]],
    [ "Energie AG",[14.232321699999998, 48.23839374999999]],
    [ "Energie AG",[14.226054400000002, 48.240715]],
    [ "Anita",[12.150633099999999, 47.56663954999999]],
    [ "Linz AG",[14.513874903932354, 48.45049492342373]],
    [ "EBNER STROM GmbH",[14.650424289877522, 48.42487694948011]],
    [ "EBNER STROM GmbH",[14.678952044006898, 48.44228919662037]],
    [ "Linz Netz",[14.533828676781766, 48.35245912939374]],
    [ "Linz Netz",[14.531729950278386, 48.34618439667308]],
    [ "Linz AG",[14.52877674307106, 48.434176895550955]],
    [ "344865309",[14.094368553591769, 48.16526605569562]],
    [ "344897331",[14.190990249999997, 48.24691034999999]],
    [ "Energie AG",[14.1955969042814, 48.247077901105065]],
    [ "Salzburg AG",[13.126268799999998, 47.979148249999994]],
    [ "345259828",[14.540503740330196, 48.44657292815726]],
    [ "Gutferding",[13.18625058198806, 47.98887011453356]],
    [ "Reitsham",[13.15353047471477, 47.9804601053154]],
    [ "Wiener Netze",[16.326756850000002, 48.12886020000001]],
    [ "Linz AG",[14.468443445436765, 48.58864035328506]],
    [ "Linz AG",[14.507976629518138, 48.46926283217577]],
    [ "KNG-Kärnten Netz GmbH",[14.017655286423562, 46.58746106812755]],
    [ "Wiener Netze",[16.4074635351338, 47.9680162842762]],
    [ "Wiener Netze",[16.352620960738218, 48.058633683519524]],
    [ "Linz AG",[14.2376081600962, 48.42783485543609]],
    [ "Linz AG",[14.228592303688274, 48.39790231000691]],
    [ "Linz AG",[14.21184154901021, 48.37803297641945]],
    [ "346290438",[14.193727099999997, 48.2189969]],
    [ "Josefinum",[14.28311235, 46.624641350000005]],
    [ "Energie Ried",[13.49580445831305, 48.30189263803031]],
    [ "Energie Ried",[13.475175646239745, 48.29298165108368]],
    [ "Hönelg. 10 Billa",[14.284485599999998, 46.6228818]],
    [ "Energie AG",[13.741151787326014, 48.35230601358031]],
    [ "346601527",[13.74002665827543, 48.04552340351227]],
    [ "346601528",[13.743896610509761, 48.03838632118604]],
    [ "SSTA Wörgl Tirolmilch",[12.084307949999998, 47.490157350000004]],
    [ "Ort i. I. Säge",[13.43604976286282, 48.31333468971228]],
    [ "Lambrechten Reinthal",[13.471680847149596, 48.3061540541104]],
    [ "Energie AG",[13.451483995213415, 48.314731740086565]],
    [ "Hörsching Niederdorfstraße",[14.178526731393298, 48.227416696296295]],
    [ "347130663",[14.177538802452172, 48.22849904997387]],
    [ "Hörsching - Schumergruber",[14.174924248466057, 48.22585790183621]],
    [ "347620203",[14.17850735, 48.22400515]],
    [ "Unterwerk Fritzens-Wattens",[11.59679171396057, 47.301913467168504]],
    [ "Energie AG",[13.66978419590854, 47.96352603886393]],
    [ "KNG-Kärnten Netz GmbH",[14.038629227686185, 46.60213390504852]],
    [ "Energie AG",[13.691845078491736, 47.975092353813565]],
    [ "Energie AG",[13.703959719188559, 47.97218491925592]],
    [ "Pürstling",[13.697681836671553, 47.9796517746095]],
    [ "Energie AG",[13.685607717117465, 47.992428300423164]],
    [ "Umspannwerk Rosshag",[11.778627549999998, 47.085516699999985]],
    [ "Wiener Netze",[16.292714820887795, 48.0791823058055]],
    [ "Wiener Netze",[16.2923574, 48.07739745000001]],
    [ "Pichlwang Ort",[13.619648189206695, 47.99398837703688]],
    [ "Timelkam Obereck",[13.632474897501359, 47.98520793474879]],
    [ "Hörsching Schule",[14.183288845160964, 48.22228211724997]],
    [ "Wiener Netze",[16.312710466899727, 48.104718877418094]],
    [ "Wiener Netze",[16.23614608281888, 47.99870578713652]],
    [ "Energie AG",[13.646685414746344, 47.953556134375425]],
    [ "Aurach",[13.669043270138287, 47.94818025017286]],
    [ "Weikertsham",[13.096046059042342, 48.021810246303176]],
    [ "KWG",[13.76114749582716, 48.0549087991812]],
    [ "Energie AG",[13.147311054498104, 48.018426144226446]],
    [ "Ampflwang Eitzingerstr.",[13.557889105924474, 48.09444677186946]],
    [ "Energie AG",[13.552317743106618, 48.09963269798297]],
    [ "Energie AG",[14.114087950070777, 48.431575849964965]],
    [ "Salzburg AG",[13.797083502256548, 47.14591345759081]],
    [ "Salzburg AG",[13.793332429019696, 47.14977582848734]],
    [ "Linz AG",[14.438268178259968, 48.2702000790924]],
    [ "349637455",[13.76708860963671, 47.145033168003316]],
    [ "Energie AG",[13.810428503146253, 48.210712821135644]],
    [ "Energie AG",[14.200336664081155, 48.451453486523185]],
    [ "Salzburg AG",[13.800128895703939, 47.13104579725736]],
    [ "Salzburg AG",[13.735230478421784, 47.11114800035483]],
    [ "Netz Burgenland",[16.99383270014338, 47.905260970420436]],
    [ "Energie AG",[14.001997951927963, 48.3619705831569]],
    [ "Wiener Netze",[16.30273666723223, 48.08183121577847]],
    [ "Linz AG",[14.30126944978764, 48.42890749860545]],
    [ "Energie AG",[14.196285548045804, 48.38131760362735]],
    [ "Landskron Resselstraße",[13.88319275266334, 46.63346361815513]],
    [ "Linz AG",[14.267379993345015, 48.40753679089911]],
    [ "Wiener Netze",[16.252512810034688, 48.003755375116924]],
    [ "Linz AG",[14.228742129868584, 48.3766384146286]],
    [ "Linz AG",[14.178860409228966, 48.36597300449343]],
    [ "Linz AG",[14.28341761292843, 48.420088864424024]],
    [ "351311360",[13.96175654913657, 48.13961344904473]],
    [ "Wiener Netze",[16.258074599999997, 48.008361900000004]],
    [ "Energie AG",[13.953179295100565, 48.13344545406506]],
    [ "Wiener Netze",[16.210406574943267, 47.99636371160725]],
    [ "KNG-Kärnten Netz GmbH",[13.580336730370437, 46.90613181865845]],
    [ "Wiener Netze",[16.23157675817658, 48.00351347620857]],
    [ "352749783",[14.2271886, 48.31058505]],
    [ "Linz AG",[14.23295881349102, 48.32329610563049]],
    [ "Linz AG",[14.229197884352216, 48.33427816946874]],
    [ "Linz AG",[14.243158610014683, 48.33768931334064]],
    [ "KNG-Kärnten Netz GmbH",[14.111514166642147, 46.59850842659442]],
    [ "KNG-Kärnten Netz GmbH",[14.094776744561809, 46.60193692777407]],
    [ "KNG-Kärnten Netz GmbH",[14.11543415539845, 46.60403635600673]],
    [ "Wiener Netze",[16.222063542201838, 47.95426523005679]],
    [ "KNG-Kärnten Netz GmbH",[14.092022350486094, 46.595610297915634]],
    [ "353416601",[14.020073120992022, 48.31013997436558]],
    [ "KNG-Kärnten Netz GmbH",[14.073851391429319, 46.57596208425735]],
    [ "Energie AG",[12.850119127539708, 48.02331200720084]],
    [ "Wiener Netze",[16.309639842939788, 48.10999177983248]],
    [ "Wiener Netze",[16.310726112170414, 48.11009906992781]],
    [ "Energie AG",[12.84790458101451, 48.02538958270614]],
    [ "KNG-Kärnten Netz GmbH",[14.065389247118112, 46.5846531811548]],
    [ "KNG-Kärnten Netz GmbH",[14.083418321217696, 46.59015763681555]],
    [ "KNG-Kärnten Netz GmbH",[13.857522957786156, 46.59025339031562]],
    [ "Energie AG",[12.851021462562379, 48.020550654659445]],
    [ "Energie AG",[14.065807991516287, 48.36568144838697]],
    [ "353822686",[14.239286643534863, 48.258715739128]],
    [ "Rahaberg",[13.48839889665417, 48.42420944982203]],
    [ "Wiener Netze",[16.186071591719838, 47.92228578026619]],
    [ "Energie AG",[14.080965520152548, 48.34025219378433]],
    [ "Schärding Linzerstr. Süd",[13.438476613174483, 48.44772795436706]],
    [ "Salzburg Netz",[13.005786050000001, 47.95872395000001]],
    [ "KNG-Kärnten Netz GmbH",[13.879739096517714, 46.59651297791835]],
    [ "KNG-Kärnten Netz GmbH",[14.044275014065427, 46.59384206721808]],
    [ "KNG-Kärnten Netz GmbH",[14.064475911036103, 46.60971694010439]],
    [ "Energie AG",[14.040373162983387, 48.41311720695423]],
    [ "Brauerei Schlägl",[13.969087799999997, 48.637060850000005]],
    [ "355599810",[13.721290940238577, 47.76944374669326]],
    [ "KNG-Kärnten Netz GmbH",[13.876090477759076, 46.9155325060339]],
    [ "356211593",[13.949876556373026, 47.81663880621759]],
    [ "Energie AG",[13.663463441640733, 48.23266604282221]],
    [ "356260420",[13.975254445740056, 48.1484087339093]],
    [ "356527764",[13.958742152725456, 47.85438348311179]],
    [ "356573919",[13.99315588853302, 47.857228327474296]],
    [ "Soboth/Roschitz",[15.097420732199113, 46.6853343962384]],
    [ "356800270",[13.666085447426912, 48.07053835386184]],
    [ "KNG-Kärnten Netz GmbH",[13.889120197471918, 46.60266134506958]],
    [ "KNG-Kärnten Netz GmbH",[14.03673580454844, 46.62307732172433]],
    [ "357043096",[14.803872049999997, 48.52124365]],
    [ "Kobernaußen",[13.369352352364757, 48.12219874561656]],
    [ "357652999",[15.488191043920045, 46.760036946840074]],
    [ "Linet",[13.815647310309018, 48.16873769879039]],
    [ "Burghartsberg",[13.824966137841027, 48.18124554931589]],
    [ "Aspach Brauerei",[13.306949997487429, 48.18540744979432]],
    [ "Energie AG",[13.300749551761236, 48.182262804476515]],
    [ "KNG-Kärnten Netz GmbH",[12.976495331473878, 46.65569605938194]],
    [ "Energie AG",[13.816374960938798, 48.12913151798708]],
    [ "Linz AG",[14.396459703356252, 48.443573811057234]],
    [ "Linz AG",[14.375335305963938, 48.54165553625922]],
    [ "Linz AG",[14.283463590516783, 48.399545866616684]],
    [ "Linz AG",[14.288991942389153, 48.394112439113805]],
    [ "Linz AG",[14.279775569815657, 48.407746804108776]],
    [ "Linz AG",[14.343084699999999, 48.45776555]],
    [ "Linz AG",[14.348801200388507, 48.45718300004433]],
    [ "Netz Niederösterreich GmbH",[14.940178465351893, 48.69417110409899]],
    [ "Energie AG",[14.118330347719438, 48.39442253263627]],
    [ "359507120",[15.440732707940876, 46.75142921447412]],
    [ "Alt Lenzing",[13.625008595758914, 47.969773553796365]],
    [ "Energie AG",[13.694445464700824, 47.98470602436863]],
    [ "Wiener Netze",[16.3025032, 48.10178665]],
    [ "360715744",[13.741185400204472, 48.049301051769646]],
    [ "Energie AG",[13.615864045856616, 47.952137298490406]],
    [ "Siebenmühlen",[13.605792900393535, 47.960291739122994]],
    [ "360782409",[14.035157812253367, 48.06702341656557]],
    [ "Linz AG",[14.428309078604633, 48.34708847763308]],
    [ "Energie AG",[13.75447303303137, 48.26260750364726]],
    [ "361223370",[13.62279288752928, 47.69337110039263]],
    [ "Energie AG",[14.183969352280693, 48.37645881233895]],
    [ "Salzburg Netz",[13.106182183875353, 47.84901569916055]],
    [ "TST Hirschwanger Forst Weichtal",[15.766917499999998, 47.74775820000001]],
    [ "Salzburg Netz",[13.1077506, 47.85237694999999]],
    [ "361645087",[13.70399356245594, 47.75805749648744]],
    [ "Linz AG",[14.260034136379208, 48.359187583538784]],
    [ "361686326",[14.425126599999999, 48.38153265]],
    [ "361846024",[14.418313409412614, 48.406434123958995]],
    [ "Energie AG",[13.765505357054364, 47.79266869783808]],
    [ "362003434",[13.762544712163766, 47.79020082698313]],
    [ "Energie AG",[13.771773699999999, 47.79640615]],
    [ "Wiener Netze",[16.372105141340832, 47.916757572250475]],
    [ "Flurstraße",[9.660483558598797, 47.416299170611836]],
    [ "Reichenaustraße",[9.655749825087272, 47.415124653143174]],
    [ "Umspannwerk Hard",[9.693090019157939, 47.48041099985027]],
    [ "Salzburg AG",[13.808877891000943, 47.13638850026907]],
    [ "Salzburg AG",[13.805138820828313, 47.13473033477228]],
    [ "Salzburg AG",[13.83166841217534, 47.133704891147744]],
    [ "Salzburg AG",[13.857844546578892, 47.13860764900933]],
    [ "Regau Gewerbe",[13.695473582918593, 47.98756501729521]],
    [ "Salzburg AG",[13.761727274194504, 47.11724640558162]],
    [ "Energie AG",[13.685893494130248, 47.996078403985244]],
    [ "Energie AG",[14.347527545955924, 47.685918252518704]],
    [ "364937712",[14.183071958567822, 48.190388530537035]],
    [ "365244165",[14.179357549316887, 47.72490837195286]],
    [ "365264855",[14.174262790446129, 47.679335184233466]],
    [ "Energie AG",[13.55971320433879, 48.48203134057887]],
    [ "365323671",[13.565346510312212, 48.487334313227215]],
    [ "Energie AG",[13.682542876501717, 47.99083531279018]],
    [ "Energie AG",[13.730608409395622, 48.50576695994707]],
    [ "Engelhartszell",[13.733248239390491, 48.50284994703051]],
    [ "Energie AG",[13.741834696258902, 48.491137266645104]],
    [ "365460138",[13.925619081268335, 47.90770430539612]],
    [ "Energie AG",[13.70697752262768, 48.51529555465614]],
    [ "365493403",[13.4403999, 48.55364674999999]],
    [ "Linz AG",[14.398646970568588, 48.534126143663876]],
    [ "365643745",[13.630806, 48.30652585000001]],
    [ "Energie AG",[14.005875583798, 47.90505621617754]],
    [ "365677778",[14.2089765, 48.211681500000005]],
    [ "Salzburg AG",[13.46658499942119, 47.16818890135817]],
    [ "Salzburg AG",[13.457322315209087, 47.17218664864979]],
    [ "Salzburg AG",[13.476651449002297, 47.163915723281626]],
    [ "Soboth St. Jakob",[15.076290095675677, 46.681370167350295]],
    [ "365894782",[14.136861967598534, 46.590909927565015]],
    [ "KNG-Kärnten Netz GmbH",[14.18159372579224, 46.605849316979686]],
    [ "Energie AG",[14.030830124254754, 48.36240799716231]],
    [ "Energie AG",[13.773950185417618, 48.347797405268416]],
    [ "Energie AG",[13.76671164894852, 48.348143758347014]],
    [ "Energie AG",[13.777863184589638, 48.346125672364735]],
    [ "Stadtbetriebe Mariazell",[15.302071549999999, 47.78514535]],
    [ "Energie AG",[13.73061063158621, 48.373823994582224]],
    [ "Energie AG",[14.064556635283681, 48.35925096028138]],
    [ "Energie AG",[14.094201949860228, 48.35160150150347]],
    [ "Linz AG",[14.412284412055044, 48.408341937365584]],
    [ "Linz AG",[14.420320963633863, 48.41034869174659]],
    [ "Linz AG",[14.41037383726074, 48.41370492833459]],
    [ "KNG-Kärnten Netz GmbH",[13.977207109662427, 46.55971473761576]],
    [ "Linz AG",[14.386685978479472, 48.33539873183481]],
    [ "KNG-Kärnten Netz GmbH",[13.964220146789572, 46.56746815124302]],
    [ "Linz AG",[14.430072498252363, 48.358771705280105]],
    [ "Netz Niederösterreich GmbH",[14.902563127166927, 48.723646899878894]],
    [ "Netz Niederösterreich GmbH",[14.963991525758487, 48.759418645519375]],
    [ "367000236",[13.150820750000001, 47.1118791]],
    [ "Rutzenmoos Waldweg",[13.713249300407616, 47.98230689904082]],
    [ "367148025",[13.965593937456285, 47.90157387413509]],
    [ "Wiener Netze",[16.25234155, 48.01171614999999]],
    [ "Wiener Netze",[16.253895, 48.0129015]],
    [ "367192789",[13.8390661, 48.23252495]],
    [ "367495862",[13.80837285, 47.93014245000001]],
    [ "Linz AG",[14.465350384007746, 48.32108185255516]],
    [ "Linz AG",[14.468761750626754, 48.33478620003571]],
    [ "Linz AG",[14.450354312192749, 48.34090898059202]],
    [ "Energie AG",[13.482770186084986, 48.05322258398907]],
    [ "UW Pötting",[13.774399000991352, 48.286432366000184]],
    [ "368112938",[14.159935275257114, 48.25914869332552]],
    [ "Linz AG",[14.157979623560514, 48.28158054545401]],
    [ "Linz AG",[14.201971573728082, 48.28314747410423]],
    [ "Linz AG",[14.175215592630844, 48.290895559917004]],
    [ "Linz AG",[14.179237609057225, 48.280165200171545]],
    [ "Linz AG",[14.172567362924392, 48.28820842416295]],
    [ "Linz AG",[14.147781596460941, 48.28288009495526]],
    [ "Energie AG",[14.16722195675507, 48.29837900485443]],
    [ "Energie AG",[14.17296811049034, 48.303560013512985]],
    [ "368127517",[14.206324500000001, 48.2767025]],
    [ "368127522",[14.221597630879614, 48.27572624975671]],
    [ "368127523",[14.2167114, 48.274747500000004]],
    [ "368155118",[14.03022875086205, 48.2350277009256]],
    [ "Energie AG",[14.020786738279082, 48.222949490478015]],
    [ "Wiener Netze",[16.292637064572652, 48.07402773082724]],
    [ "KNG-Kärnten Netz GmbH",[12.994794543134162, 46.87374147001223]],
    [ "KNG-Kärnten Netz GmbH",[12.964118598139187, 46.860990321668474]],
    [ "Energie AG",[14.172110129759782, 48.20247882552972]],
    [ "Rudelsdorf Logistikzentrum",[14.165996199999997, 48.20536779999999]],
    [ "369663417",[14.17766340343463, 48.21266783273311]],
    [ "KNG-Kärnten Netz GmbH",[12.9158546, 46.85611055]],
    [ "KNG-Kärnten Netz GmbH",[13.462089326669988, 46.76956495528774]],
    [ "Oberhaus Süd",[13.679160668055061, 48.001073734001544]],
    [ "KNG-Kärnten Netz GmbH",[13.436415574562117, 46.80869753410316]],
    [ "Baldramsdorf Unterhaus Neue Heimat",[13.46164136658289, 46.796818699193906]],
    [ "KNG-Kärnten Netz GmbH",[13.447887178244722, 46.802902704058646]],
    [ "KNG-Kärnten Netz GmbH",[13.478432339681053, 46.787127824318354]],
    [ "KNG-Kärnten Netz GmbH",[13.398026144984186, 46.847391327721226]],
    [ "370135095",[14.181642283220228, 48.3393548823084]],
    [ "Linz AG",[14.165345853140533, 48.35755105117385]],
    [ "Linz AG",[14.15914919378402, 48.364363920035714]],
    [ "KNG-Kärnten Netz GmbH",[13.381916074011961, 46.853879550614856]],
    [ "KNG-Kärnten Netz GmbH",[13.37750805988341, 46.84275884740846]],
    [ "Dornbirnerstraße",[9.661309154764455, 47.41903797555417]],
    [ "Ruttelmahd",[9.6636854, 47.414587350000005]],
    [ "370358889",[14.35838729528827, 48.2869724487657]],
    [ "370397379",[16.673243283527295, 47.807316550185845]],
    [ "KNG-Kärnten Netz GmbH",[13.375347326873772, 46.83816991875066]],
    [ "370547436",[15.001670399421263, 48.53957280025179]],
    [ "KNG-Kärnten Netz GmbH",[13.371942408753577, 46.78328895825928]],
    [ "KNG-Kärnten Netz GmbH",[13.35374429705625, 46.769150355685554]],
    [ "Hühnersberg Kolmwirt",[13.442738222136873, 46.84438435917712]],
    [ "Energie AG",[14.22660461178237, 48.142482256280026]],
    [ "Wiener Netze",[16.3016768, 48.11252840000001]],
    [ "370820433",[13.45502042807735, 46.820180787082656]],
    [ "KNG-Kärnten Netz GmbH",[13.423881367024645, 46.83236004627472]],
    [ "371088596",[14.186464274470595, 48.092009147523314]],
    [ "Wiener Netze",[16.308564699083334, 48.06812116702211]],
    [ "Energie AG",[14.151340084197628, 48.230528941573134]],
    [ "TST Enzenreith Nord",[15.949266792973596, 47.66947826817232]],
    [ "Gerersdorf",[14.184755103558482, 48.130279061156045]],
    [ "Energie AG",[13.87111452772187, 48.42366510610808]],
    [ "Wiener Netze",[16.31057410845396, 48.10570412677467]],
    [ "Energie AG",[13.30983145056545, 47.9331871757607]],
    [ "Energie AG",[13.304407688812159, 47.94946036667202]],
    [ "Wegdorf",[13.30851934353574, 47.94184722449691]],
    [ "Kärnten Netz",[13.225216566150369, 46.8661899019319]],
    [ "Energie AG",[14.150948184222159, 48.34718438519727]],
    [ "Energie AG",[14.175950102782474, 48.49399341241351]],
    [ "Energie AG",[14.191569461745743, 48.47880695548187]],
    [ "Energie AG",[13.382374344242171, 48.28647589351863]],
    [ "KNG-Kärnten Netz GmbH",[13.16325879910734, 46.93924559977127]],
    [ "372178973",[13.66461713104709, 48.08781885781665]],
    [ "Hartkirchen",[14.005019450351833, 48.36426684575321]],
    [ "Lengau Monta",[13.223953213290384, 48.0025340257786]],
    [ "Haidach Schwöll",[13.236649793008082, 47.990683818570055]],
    [ "Netz OÖ",[14.162280103765033, 48.20631403272343]],
    [ "Brändle Seewald",[9.66653358881656, 47.425537883072124]],
    [ "372809541",[14.692504583489457, 48.18805709837538]],
    [ "Energie AG",[13.280369737537802, 48.00055184594523]],
    [ "KNG-Kärnten Netz GmbH",[13.203429561874458, 46.930454335747065]],
    [ "KNG-Kärnten Netz GmbH",[13.240791272474782, 46.91822158951658]],
    [ "KNG-Kärnten Netz GmbH",[13.237071536883274, 46.92798603172977]],
    [ "Teilenstraße",[9.66634596937718, 47.42939700463514]],
    [ "KNG-Kärnten Netz GmbH",[13.032826086568368, 46.88820327622893]],
    [ "KNG-Kärnten Netz GmbH",[13.043821473749293, 46.887801930621514]],
    [ "Ampflwang Buchleitenfeld",[13.55839029349862, 48.09683450020153]],
    [ "Ampflwang",[13.56149161178117, 48.09234505832305]],
    [ "Trafostation-Pram (20kV)",[13.607344632152786, 48.23227728911435]],
    [ "Pfarrweg",[9.6611598, 47.4278175]],
    [ "373827871",[14.181889905494954, 48.20333440115607]],
    [ "373854763",[13.595366647967493, 47.70992585918522]],
    [ "374052295",[13.628331361641592, 47.707761022071864]],
    [ "KNG-Kärnten Netz GmbH",[13.822474638396105, 46.7901453689445]],
    [ "Quellenstraße",[9.6498382, 47.43336755]],
    [ "Linz AG",[14.4476259, 48.270431349999996]],
    [ "Energie AG",[13.673825763246, 48.0046209852401]],
    [ "Oberhehenfeld",[13.628115939948774, 47.93758385463093]],
    [ "KNG-Kärnten Netz GmbH",[13.625998498150022, 46.717688898405]],
    [ "KNG-Kärnten Netz GmbH",[13.476539058995947, 46.830090363193]],
    [ "KNG-Kärnten Netz GmbH",[13.486501050000003, 46.821583950000004]],
    [ "KNG-Kärnten Netz GmbH",[13.49363215, 46.824013750000006]],
    [ "KNG-Kärnten Netz GmbH",[13.513930233278424, 46.81517331991572]],
    [ "KNG-Kärnten Netz GmbH",[13.510776700000001, 46.8156407]],
    [ "KNG-Kärnten Netz GmbH",[13.510030310822042, 46.817167438410166]],
    [ "KNG-Kärnten Netz GmbH",[13.504039699999998, 46.81570320000001]],
    [ "KNG-Kärnten Netz GmbH",[13.523123374003639, 46.82212068165335]],
    [ "KNG-Kärnten Netz GmbH",[13.534025491349317, 46.82096244861636]],
    [ "375503548",[13.211828702874538, 47.93529213685523]],
    [ "Gurten Edt",[13.33753540030927, 48.24134533362354]],
    [ "Sand",[9.651910249999998, 47.43736440000001]],
    [ "Salzburg AG",[12.985607522530001, 48.009212040303964]],
    [ "Salzburg AG",[12.838353549999999, 47.42089409999999]],
    [ "376137642",[13.827825938914943, 47.928696226199605]],
    [ "Ried im Traunkreis - Siedlung",[14.072812774789915, 48.0197449056956]],
    [ "376143454",[13.27438054766312, 47.932778196748686]],
    [ "376222275",[15.05211029021993, 48.5385116118739]],
    [ "TST Steinbrückl Kruft",[16.203623070588023, 47.86234189256698]],
    [ "376315134",[15.213822940907706, 47.41007024463653]],
    [ "Umspannwerk Leopoldau",[16.420542006106558, 48.272087063163234]],
    [ "Energie AG",[14.298296398699629, 48.521998750234566]],
    [ "Energie AG",[13.349321212475713, 48.24025229398991]],
    [ "Energie AG",[14.284952073730103, 48.51946464970337]],
    [ "376524451",[14.26840165709053, 48.46446899588817]],
    [ "376935460",[13.219959884254681, 47.93983601780808]],
    [ "KNG-Kärnten Netz GmbH",[13.36081998252734, 46.83124407639869]],
    [ "Maria-Theresien-Straße",[9.655924747777245, 47.43662818260007]],
    [ "Hofsteigstraße",[9.659706519360633, 47.436309247325305]],
    [ "377031566",[13.615280107570914, 47.98563745999073]],
    [ "Seewalchen",[13.585186539017077, 47.954758915603996]],
    [ "377065016",[13.622589074600494, 47.99855675086709]],
    [ "Timelkam Pichlwangerstr.",[13.612619175272695, 47.999597643163675]],
    [ "Obergottesfeld Ort",[13.34985888152092, 46.80559000099813]],
    [ "377196829",[13.219281656958257, 47.942594689071775]],
    [ "377196830",[13.219150445217375, 47.94358775954131]],
    [ "KNG-Kärnten Netz GmbH",[13.352843730852467, 46.85693324734451]],
    [ "KNG-Kärnten Netz GmbH",[13.347624967155722, 46.860579394146384]],
    [ "377298255",[14.201594943730385, 48.51477542907858]],
    [ "Steinackerstraße",[9.659072710305932, 47.433426219838815]],
    [ "Salzburg Netz",[13.040961829550115, 47.974442503066385]],
    [ "Linz AG",[14.194617955208532, 48.36120848976649]],
    [ "Radetzkystraße",[9.659639550000001, 47.43153515]],
    [ "377757742",[14.28829725138583, 48.21237211627697]],
    [ "KNG-Kärnten Netz GmbH",[13.23027005, 46.7667861]],
    [ "Linz AG",[14.49207559902684, 48.248515199315655]],
    [ "KNG-Kärnten Netz GmbH",[13.249327488796258, 46.75902443052946]],
    [ "KNG-Kärnten Netz GmbH",[13.344388825531256, 46.76191391431577]],
    [ "378111897",[13.210446971712688, 47.962359962568804]],
    [ "KNG-Kärnten Netz GmbH",[13.65060639379031, 46.76889065445714]],
    [ "Energie AG",[13.060670975948758, 48.25843280442516]],
    [ "Linz AG",[14.202987450611754, 48.32423813694649]],
    [ "KNG-Kärnten Netz GmbH",[13.677555635655624, 46.79325821643239]],
    [ "378262605",[14.348075949288976, 48.46053316691207]],
    [ "KNG-Kärnten Netz GmbH",[13.727243236143428, 46.79155587675515]],
    [ "KNG-Kärnten Netz GmbH",[13.729544068202681, 46.791412206336055]],
    [ "KNG-Kärnten Netz GmbH",[13.739949811358013, 46.7920308337211]],
    [ "Linz AG",[14.291915853949126, 48.43080006123477]],
    [ "Bündten",[9.657511525791776, 47.43830669155673]],
    [ "KNG-Kärnten Netz GmbH",[13.69225858151191, 46.83258753993981]],
    [ "KNG-Kärnten Netz GmbH",[13.706244035869407, 46.80428571853842]],
    [ "KNG-Kärnten Netz GmbH",[13.70124504949808, 46.80409532276069]],
    [ "Linz AG",[14.39356487258158, 48.312236452479695]],
    [ "Linz AG",[14.399139571575148, 48.28088149536983]],
    [ "Malta Hilpersdorf",[13.513175949999999, 46.95113085]],
    [ "Obermalta",[13.51043090224119, 46.95553010251609]],
    [ "KNG-Kärnten Netz GmbH",[13.585226678616007, 46.757165386440185]],
    [ "KNG-Kärnten Netz GmbH",[13.572215532450745, 46.76845767021521]],
    [ "KNG-Kärnten Netz GmbH",[13.583121140856338, 46.77694880504023]],
    [ "KNG-Kärnten Netz GmbH",[13.548201648918264, 46.77797194873205]],
    [ "KNG-Kärnten Netz GmbH",[13.48713961659041, 46.789865766687]],
    [ "KNG-Kärnten Netz GmbH",[14.26573506076586, 46.591263525981816]],
    [ "Wiener Netze",[16.285248550000002, 48.0950296]],
    [ "Wiener Netze",[16.29943196623315, 48.119432701006986]],
    [ "KNG-Kärnten Netz GmbH",[14.25770795, 46.60464339999999]],
    [ "Salzburg AG",[13.012637656528689, 48.024970638130725]],
    [ "Salzburg AG",[13.036087679068153, 47.82817025359361]],
    [ "Salzburg AG",[13.032836812642145, 47.83161693110759]],
    [ "KNG-Kärnten Netz GmbH",[13.497820284936171, 46.79516446563951]],
    [ "KNG-Kärnten Netz GmbH",[13.49299191759553, 46.80484480522569]],
    [ "Wiener Netze",[16.31841478207354, 48.07624290089034]],
    [ "Stadl Paura Ufer",[13.8790407, 48.08449555]],
    [ "382860946",[13.87116633251208, 48.01951725088296]],
    [ "382948365",[13.775209701664327, 48.0482499485562]],
    [ "Netz Niederösterreich GmbH",[15.038694744384317, 48.452532071713996]],
    [ "Obertrum Hohengarten",[13.0441558, 47.919866250000005]],
    [ "KNG-Kärnten Netz GmbH",[13.05715758462199, 46.96478332840162]],
    [ "KNG-Kärnten Netz GmbH",[13.095730900819115, 46.74893157180094]],
    [ "KNG-Kärnten Netz GmbH",[13.09082488490043, 46.74693919457828]],
    [ "KNG-Kärnten Netz GmbH",[13.091479933676384, 46.749248603144146]],
    [ "Kärnten Netz",[13.196874584873607, 46.758533888036176]],
    [ "Kärnten Netz",[13.167913000610934, 46.75644754868649]],
    [ "KNG-Kärnten Netz GmbH",[13.315274341704486, 46.71639472212016]],
    [ "KNG-Kärnten Netz GmbH",[13.307023335110689, 46.717642504326385]],
    [ "Salzburg Netz",[13.135814483925826, 47.97492100183408]],
    [ "383957580",[14.362443584697582, 46.643787598178925]],
    [ "Energie AG",[13.317696454544699, 48.18003370127919]],
    [ "Aspach Markt",[13.304039290981839, 48.182916174529986]],
    [ "Energie AG",[13.325227367833973, 48.17682410315396]],
    [ "Rotkreuzstraße",[9.663307776640195, 47.44260619584793]],
    [ "Ebensee Ischlerstr.",[13.764152966354404, 47.805354700999985]],
    [ "Energie AG",[14.407522152708399, 47.93271349993711]],
    [ "384531077",[14.406832081189615, 47.931151802392975]],
    [ "Wiener Netze",[16.3460985, 48.118115450000005]],
    [ "Wiener Netze",[16.33697927741521, 48.11916243883753]],
    [ "Aspach Niederham",[13.293370780685532, 48.17926881909782]],
    [ "Maderspergerstrasse",[12.170674767101506, 47.580718242021604]],
    [ "Energie AG",[14.438099136566365, 47.918838504767706]],
    [ "384829568",[15.07829545, 48.438176450000014]],
    [ "Kärnten Netz",[13.007749907409517, 46.67018963262066]],
    [ "Kärnten Netz",[13.002463805303176, 46.675604125459685]],
    [ "AAE Wasserkraft GmbH",[13.006082747882694, 46.679087252083015]],
    [ "KNG-Kärnten Netz GmbH",[12.988789449026344, 46.69451754888092]],
    [ "Energie AG",[14.387339201028373, 47.93153872427421]],
    [ "Energie AG",[14.35215655, 47.945954199999996]],
    [ "Energie Ried",[13.40594714837203, 48.22320940915046]],
    [ "Energie Ried",[13.432286048958899, 48.206425797742774]],
    [ "Energie Ried",[13.428354350003916, 48.20194915072614]],
    [ "Energie Ried",[13.435449100000001, 48.20749630000001]],
    [ "Energie Ried",[13.444806909106516, 48.20064664665249]],
    [ "Energie AG",[14.320420445421712, 47.94712305041009]],
    [ "Energie Ried",[13.444469096641377, 48.21686128095556]],
    [ "Energie AG",[14.354556855837535, 47.929162997616224]],
    [ "Energie AG",[14.34241892672076, 47.919909905712686]],
    [ "385326659",[13.71339465833933, 48.01774300586479]],
    [ "Schwanenstadt Alpina",[13.783518774934034, 48.05935490548258]],
    [ "Netz Niederösterreich GmbH",[16.5688761, 48.56870595]],
    [ "Salzburg Netz",[13.15875633607388, 47.919281210412585]],
    [ "KNG-Kärnten Netz GmbH",[14.511211577404133, 46.65820721022768]],
    [ "Netz Niederösterreich GmbH",[15.92052171088968, 47.77026629311572]],
    [ "TST Sollenau Alleegasse",[16.25028734494147, 47.899151167244455]],
    [ "Energie Ried",[13.482574735355314, 48.15692159330417]],
    [ "Schildorn",[13.462073951801719, 48.14440383421118]],
    [ "386068774",[13.674881867110237, 48.12326535149705]],
    [ "Klingersberg",[13.355090152440777, 48.267739700057994]],
    [ "Energie AG",[13.585473765288027, 48.13844423905183]],
    [ "Bad Ischl Jainzendorf",[13.616270544725756, 47.71549322018594]],
    [ "Bad Ischl Doppelblick",[13.615306699999998, 47.71862365]],
    [ "Energie AG",[13.569261599999997, 48.154037]],
    [ "Eberschwang Schule",[13.56109478458781, 48.15764286616731]],
    [ "Eberschwang",[13.565412627156235, 48.15784437469539]],
    [ "Energie AG",[13.573403245644739, 48.16795218811968]],
    [ "Energie AG",[13.557647688484588, 48.174957108444985]],
    [ "Energie AG",[13.633758865229362, 48.06473115522336]],
    [ "386932270",[13.114065099489284, 47.39874522167087]],
    [ "386936486",[13.640980105851298, 47.69159145600527]],
    [ "Energie AG",[13.488733043961691, 48.14279196244474]],
    [ "Netz Niederösterreich GmbH",[16.1661079, 47.732322950000004]],
    [ "Netz Niederösterreich GmbH",[16.196452299999997, 47.7380364]],
    [ "Bahngasse",[9.665190940585376, 47.446882583343985]],
    [ "KNG-Kärnten Netz GmbH",[14.450159232271009, 46.66803078953788]],
    [ "388449286",[13.91380801414182, 48.01339413273625]],
    [ "Martin Kink Straße",[9.668294156219792, 47.439748296265066]],
    [ "Am Böhler",[9.664175971362402, 47.43587354683234]],
    [ "388911207",[13.899025638993148, 48.01353529275126]],
    [ "Salzburg AG",[13.070739787815656, 47.969798073092875]],
    [ "389192505",[13.049073245493947, 47.80551856833558]],
    [ "Strasswalchen Kriechbaumsiedlung",[13.250920999999998, 47.97632480000001]],
    [ "Unterer Breitenweg",[15.483350231486527, 47.06346929583836]],
    [ "Umspannwerk Michelbeuern",[16.349004784736, 48.22230512822321]],
    [ "389742530",[12.136061450000001, 47.22662285]],
    [ "Energie Ried",[13.407496043264757, 48.20700693151831]],
    [ "Unterwerk Keplerbrücke",[15.43426774979516, 47.077311012086064]],
    [ "Staldenstraße",[9.668093261299019, 47.433432023390196]],
    [ "390003304",[13.637671415124485, 47.715887468159714]],
    [ "Kremsmünster Stiftstöckl",[14.127424332976567, 48.05378489995944]],
    [ "Helmberg",[14.115368557550378, 48.041400149697914]],
    [ "390368249",[14.123014657188241, 48.044250718146905]],
    [ "Energie AG",[13.318718293633365, 48.20455180066161]],
    [ "Eisecking",[13.307827905234443, 48.20118950668647]],
    [ "Kärnten Netz",[14.453454977981622, 46.686424739077324]],
    [ "Energie AG",[13.416968841726273, 48.13917806896257]],
    [ "Waldzell",[13.42337503794967, 48.13612407479417]],
    [ "Waldzell Kirche",[13.42819090599342, 48.134067031079326]],
    [ "Salzburg Netz",[13.09946429316878, 47.920385783834604]],
    [ "Maireck",[13.446840695875746, 48.117520651416896]],
    [ "Nußbaum",[13.406310863644606, 48.10961112567342]],
    [ "391040522",[13.971896150302994, 47.91396083819267]],
    [ "Salzburg AG",[13.121358319233922, 47.900993610821814]],
    [ "391522533",[13.504260707508196, 48.19836386751745]],
    [ "Salzburg Netz",[13.119483115971432, 47.90846666766816]],
    [ "391606104",[13.253529802030869, 47.97366926633978]],
    [ "Netz Niederösterreich GmbH",[16.19662770298893, 47.63059289877655]],
    [ "Obernberg Altheim",[13.261840727266678, 48.270663045214995]],
    [ "Salzburg Netz",[13.132669049551348, 47.89762844985069]],
    [ "Energie AG",[13.256942702922817, 48.25402554415139]],
    [ "Energie AG",[13.287270110368155, 48.26406455195046]],
    [ "392401339",[13.277511899718238, 48.26255463569027]],
    [ "392418228",[13.31367779367747, 48.25443926227127]],
    [ "Salzburg Netz",[13.134483390238842, 47.89868541404792]],
    [ "Salzburg Netz",[13.130694852149922, 47.896481637431165]],
    [ "Salzburg Netz",[13.136398102451281, 47.8998283010718]],
    [ "Energie Ried",[13.45648831265138, 48.189836548464996]],
    [ "Energie Ried",[13.471355340665696, 48.191622100758096]],
    [ "Energie Ried",[13.446188631853637, 48.175459400617726]],
    [ "Energie Ried",[13.497245041439609, 48.18901159661224]],
    [ "Salzburg Netz",[13.131644349551348, 47.89704284985069]],
    [ "Salzburg AG",[13.248232782633437, 47.842231816142444]],
    [ "393161377",[13.110770760476523, 47.824383281194784]],
    [ "SST Sollenau",[16.254529650000002, 47.89438834999999]],
    [ "Geiersberg",[13.584476959437717, 48.2007559964095]],
    [ "Energie AG",[13.591599609940708, 48.21027894194604]],
    [ "Salzburg Netz",[13.11597854937607, 47.89926204846562]],
    [ "393724648",[13.262547588129785, 47.94406046082594]],
    [ "Salzburg Netz",[13.124822161498868, 47.89010612185723]],
    [ "393892232",[13.119616270823425, 47.891379822735466]],
    [ "394016246",[13.244917292847646, 47.963045028943206]],
    [ "Salzburg Netz",[13.119219809328587, 47.89664857962265]],
    [ "Netz Burgenland GmbH",[16.518148069370547, 47.907844050294734]],
    [ "Netz Niederösterreich GmbH",[15.83175567843578, 47.791740204483474]],
    [ "394380004",[14.119084175654374, 47.987148786649094]],
    [ "Nagsdorf",[13.339545250377958, 48.178890621393755]],
    [ "Wagham",[13.269306413136755, 48.22848020630556]],
    [ "KNG-Kärnten Netz GmbH",[14.67451671288851, 46.63808321434381]],
    [ "Karnburg/Unterort",[14.315131740064182, 46.68215503835359]],
    [ "Wiener Netze",[16.212007910537018, 47.983233284087866]],
    [ "Wiener Netze GmbH",[16.353983749999994, 48.1133788]],
    [ "Energie Ried",[13.482021857422264, 48.19905600370719]],
    [ "UST Mitte",[13.491004581111229, 48.20922165388339]],
    [ "Salzburg AG",[13.09697451673917, 47.84958968118231]],
    [ "Salzburg Netz",[13.20151005, 47.957615300000015]],
    [ "Salzburg Netz",[13.146284378285445, 47.87272339820806]],
    [ "KNG-Kärnten Netz GmbH",[14.771337358873959, 46.65595907846875]],
    [ "KNG-Kärnten Netz GmbH",[14.82775554342249, 46.68865994828931]],
    [ "Energie Ried",[13.4963018, 48.222869700000004]],
    [ "395830314",[13.230471450000001, 47.94984975]],
    [ "Wiener Netze",[16.32703765770294, 47.85303574582463]],
    [ "Energie AG",[14.12291597198988, 47.98812624724137]],
    [ "Pichling 2",[15.27729275, 46.9045335]],
    [ "396304337",[15.884029228022952, 46.875355176197026]],
    [ "396371004",[13.254481256997694, 48.21278104952157]],
    [ "396534165",[14.041697889807073, 48.152329886783235]],
    [ "Hübing KW EB ON",[13.42019575471186, 48.32932242345501]],
    [ "Hart",[13.409121421802931, 48.3155387445119]],
    [ "396872073",[13.14148883497271, 47.83467501706946]],
    [ "KNG-Kärnten Netz GmbH",[14.863151514476558, 46.694848179143584]],
    [ "397027369",[15.942861094935951, 46.88439934596165]],
    [ "Salzburg Netz",[13.222517492074243, 47.96935352419602]],
    [ "DL Mühlgang",[15.21716220672402, 46.81477169710436]],
    [ "397345462",[15.91277243828485, 46.86306089022766]],
    [ "397358993",[13.847042442328988, 48.0761822807014]],
    [ "Salzburg Netz",[13.06806709047888, 47.870947282845414]],
    [ "Schwanenstadt Jennystraße",[13.775365031808777, 48.05739277633795]],
    [ "Salzburg AG",[13.066875760434913, 47.90085207615218]],
    [ "Wiener Netze",[16.29210203887745, 48.11916042730992]],
    [ "397748983",[13.202771545140452, 47.85084135528811]],
    [ "397752741",[13.201920475099625, 47.85278360454798]],
    [ "Netz NÖ",[15.9755038, 47.97151114999999]],
    [ "Linz Netz",[14.52423003725606, 48.36360371560174]],
    [ "TST Wr.Neustadt Hauergass",[16.26346217718069, 47.81767344485827]],
    [ "Linz Netz",[14.521744508838, 48.357328464609274]],
    [ "398645772",[14.84673932503322, 48.23008539673676]],
    [ "398648084",[14.838569023173385, 48.2324908146727]],
    [ "398655368",[14.854273179593449, 48.230017644934016]],
    [ "398707435",[14.87037563815192, 48.231758811852245]],
    [ "Kreh Stockham",[13.287776483590902, 47.147121300971065]],
    [ "Linz Netz",[14.759365273630056, 48.207881195045715]],
    [ "Linz AG",[14.733848497526937, 48.21947635169724]],
    [ "Netz NÖ",[16.12387795, 48.17294825000001]],
    [ "Thon",[14.479180300028387, 46.63144981244906]],
    [ "TST Payerbach Hauptschule",[15.86705342170663, 47.69412345369148]],
    [ "Linz Netz",[14.630657396604754, 48.210112749538226]],
    [ "Linz AG",[14.581503243364489, 48.22952035088514]],
    [ "Linz AG",[14.569454418274596, 48.236509796886]],
    [ "Linz AG",[14.588236071262772, 48.249453878865914]],
    [ "Linz AG",[14.607879103132593, 48.226737698031684]],
    [ "Umspannwerk Breitenfeld",[15.498820621562487, 47.534336612322306]],
    [ "399659618",[14.614732650000002, 48.228522100000006]],
    [ "Linz AG",[14.738824551510298, 48.224676490910674]],
    [ "E-Werk Clam",[14.788819711050555, 48.20611912085447]],
    [ "Wiener Netze GmbH",[16.3729108, 48.0224185]],
    [ "400103810",[14.78796952877845, 48.21093430425782]],
    [ "E-Werk Clam",[14.784749918721914, 48.21130297281298]],
    [ "Linz AG",[14.536513135120355, 48.24546609549667]],
    [ "TST Wagrain Kranabet",[13.304615049999999, 47.3296853]],
    [ "400639947",[14.02645578275102, 48.18333203736983]],
    [ "Linz AG",[14.514213403329123, 48.26021295386011]],
    [ "Linz AG",[14.506603503818116, 48.24969913429945]],
    [ "Linz AG",[14.501761654233793, 48.25144760446931]],
    [ "400754186",[14.52194056298312, 48.242618616074424]],
    [ "Wiener Netze",[16.523254650000002, 48.08049375000002]],
    [ "Linz AG",[14.533637432608204, 48.24068196985657]],
    [ "Altheim Lehen",[13.226678722489543, 48.23540444518562]],
    [ "401148638",[14.519021386701715, 48.245250925873194]],
    [ "Energie AG",[13.251500718017367, 48.23817138478357]],
    [ "Linz AG",[14.497457862594578, 48.24659998485968]],
    [ "Linz AG",[14.53843515, 48.25846425000001]],
    [ "KNG-Kärnten Netz GmbH",[14.486888533132332, 46.61304664860165]],
    [ "402322220",[14.571738316669999, 48.273581849972594]],
    [ "KNG-Kärnten Netz GmbH",[14.312219103912646, 46.55573727259388]],
    [ "403352970",[13.964410300000003, 48.123421750000006]],
    [ "Linz AG",[14.593129036201425, 48.28615621921067]],
    [ "Linz AG",[14.594611710052444, 48.27519898805535]],
    [ "Linz AG",[14.58844489010547, 48.27631510184031]],
    [ "403960561",[14.28049865, 48.15103315]],
    [ "Linz AG",[14.581989270473873, 48.2770448970529]],
    [ "Leibenfeld Keltenweg",[15.220237099999999, 46.8104118]],
    [ "KNG-Kärnten Netz GmbH",[14.21102765, 46.6335469]],
    [ "Energie AG",[13.974449899214836, 48.20628510995787]],
    [ "KNG-Kärnten Netz GmbH",[14.206221859076342, 46.635076769550736]],
    [ "KNG-Kärnten Netz GmbH",[14.205191750627572, 46.63704200024177]],
    [ "KNG-Kärnten Netz GmbH",[14.418368213652142, 46.582575297356314]],
    [ "405124124",[14.910172773837454, 48.30649304796568]],
    [ "Linz AG",[14.905111005079679, 48.306636639296514]],
    [ "Energie AG",[13.625923578684919, 48.14053374224472]],
    [ "Wiener Netze",[14.768978727020244, 47.80321329371547]],
    [ "Geboltskirchen Aspett",[13.633426654079207, 48.15418623899537]],
    [ "KNG-Kärnten Netz GmbH",[14.43450469477821, 46.57317110126276]],
    [ "KNG-Kärnten Netz GmbH",[14.412113049811287, 46.574750400661415]],
    [ "405453790",[14.527508859578694, 48.273019130976536]],
    [ "Linz AG",[14.521426689863624, 48.274583614910874]],
    [ "Filzmoos Rettenegg",[13.522933536787425, 47.43878532529098]],
    [ "405998958",[14.9268464, 48.239986099999996]],
    [ "EWW",[14.078678123087666, 48.187601834854526]],
    [ "Energie AG",[14.074397284953111, 48.19396257714355]],
    [ "406077445",[14.079301322278917, 48.18842011658276]],
    [ "406129326",[14.142066100555464, 48.203267296791076]],
    [ "Niglbaueralm",[14.59624115, 47.785402500000004]],
    [ "Energie AG",[13.704126687791236, 48.243185741780465]],
    [ "Braunau Osternbergerstraße",[13.028833144332765, 48.247145580408066]],
    [ "Energie AG",[13.727383510592313, 48.234920513404916]],
    [ "406946441",[14.239532213888202, 48.13793896799434]],
    [ "Netz OÖ",[14.206311490847966, 48.05393511863076]],
    [ "Weiherstraße",[9.672936609460654, 47.43216282387268]],
    [ "Energie AG",[14.251500312323206, 48.15129624187481]],
    [ "Energie AG",[13.75003864029319, 48.24981529270995]],
    [ "407935344",[14.720686244693395, 47.84108231737972]],
    [ "408009938",[14.478572049999999, 48.490037650000005]],
    [ "EWW",[13.988332148773129, 48.14281108337299]],
    [ "408509681",[14.662535721226106, 47.85589560506302]],
    [ "408532980",[14.64516941151294, 47.74829899508664]],
    [ "408540091",[14.645531825571062, 47.825441205938986]],
    [ "408540163",[14.639381542382221, 47.82201712584563]],
    [ "Salzburg Netz",[13.048753640124987, 48.00994847634524]],
    [ "KNG-Kärnten Netz GmbH",[14.17743235, 46.603722250000004]],
    [ "409127069",[14.096165247390486, 48.19269389948169]],
    [ "Energie AG",[14.174377148568007, 48.5655270081589]],
    [ "Energie AG",[13.731888751852857, 48.17338388333603]],
    [ "Energie AG",[13.731223783298253, 48.1712476987248]],
    [ "409420625",[14.633560849924462, 47.728100800211784]],
    [ "Energie AG",[13.021708023606164, 48.05090937758134]],
    [ "Salzburg Netz",[13.038235603450174, 48.0010789975286]],
    [ "410447529",[13.792240638174569, 48.025159759729476]],
    [ "KWG",[13.763276600000003, 48.035443199999996]],
    [ "Meggenbach",[13.664562608958361, 48.178248190533736]],
    [ "Babenham",[13.157016025026278, 48.025995681133935]],
    [ "Haselbach",[13.05380669709329, 48.24892004845302]],
    [ "Energie AG",[13.059197326974756, 48.060803582429436]],
    [ "Attnang Römerstr.",[13.723214117224547, 48.01074925168208]],
    [ "Attnang Niederstraß",[13.731455752975231, 48.01349274771401]],
    [ "Netz OÖ",[13.726483200000002, 48.0133192]],
    [ "Netz OÖ",[13.720338610668884, 48.010037883911366]],
    [ "Energie AG",[13.316507406980506, 47.902643510402314]],
    [ "Zell a. Moos",[13.322063561106567, 47.904387441957496]],
    [ "Haslau",[13.350111087530811, 47.925053850869055]],
    [ "412389997",[13.650566199081203, 48.24583105033359]],
    [ "412614181",[13.586584480991675, 48.01182374436988]],
    [ "412643396",[13.601889474717074, 48.018138893985316]],
    [ "Ottenhausen",[13.048684008016759, 48.08445064505635]],
    [ "Feldkirchen b. M.",[13.042946747733614, 48.06835613210536]],
    [ "Energie AG",[13.032981497934108, 48.080607264766705]],
    [ "Pram Molkerei",[13.618606810405577, 48.23062015921149]],
    [ "Schüttdorf Wiesenweg",[12.795607987862546, 47.295556783757]],
    [ "Salzburg Netz",[12.969337931994078, 47.92171564133668]],
    [ "Maisling",[13.100798025440719, 48.04999129489482]],
    [ "414276852",[13.781234231147868, 48.05988836865803]],
    [ "Wienern St. Florian",[13.151241383394014, 48.16323721762093]],
    [ "Energie AG",[13.130963332538837, 48.15844887507447]],
    [ "Energie AG",[14.022347679512782, 48.3021270933109]],
    [ "Energie AG",[14.02955905, 48.301733049999996]],
    [ "KNG-Kärnten Netz GmbH",[13.9577146245788, 46.61770093649402]],
    [ "KNG-Kärnten Netz GmbH",[13.980267193917099, 46.59641168909775]],
    [ "KNG-Kärnten Netz GmbH",[13.962588311240902, 46.60143909365142]],
    [ "Energie Ried",[13.530764572292068, 48.31946627418848]],
    [ "Energie AG",[13.696959364799671, 48.49190320809228]],
    [ "Hackendorf",[13.700448422264476, 48.466070851026736]],
    [ "Energie Ried",[13.438540448329288, 48.22451868231919]],
    [ "Energie AG",[13.730036949702042, 48.143608262698834]],
    [ "Wiener Netze",[16.300308230057464, 48.07148795580985]],
    [ "Wiener Netze",[16.30403513692339, 48.070299566979706]],
    [ "Wiener Netze",[16.30214153008178, 48.07153975185097]],
    [ "Energie AG",[13.76180170424794, 48.147371194723576]],
    [ "Salzburg Netz",[13.02580305, 47.99213345]],
    [ "Energie AG",[13.595107610020015, 47.94536302755452]],
    [ "415297411",[15.094785454364283, 48.55328172926283]],
    [ "Schörfling",[13.607256202878792, 47.95008259767061]],
    [ "Energie AG",[13.58235647045458, 47.923049798640584]],
    [ "Energie AG",[13.59536917257669, 47.93642145406082]],
    [ "Energie AG",[13.604840377072613, 47.93902042549922]],
    [ "Linz AG",[14.66456452719892, 48.23810551882396]],
    [ "Energie AG",[14.158234959793925, 48.42894226384541]],
    [ "Schörfling Sulzberg",[13.613915800000001, 47.9411205]],
    [ "Netz NÖ",[16.177473479747377, 48.03826079749519]],
    [ "Energie AG",[14.252908788903872, 48.03259353982341]],
    [ "Rasdorf Ind. 1",[13.673899339560416, 48.43156186511695]],
    [ "Energie AG",[13.569030964094912, 48.464013069240316]],
    [ "Linz AG",[14.388432996725784, 48.54051260269136]],
    [ "Esternberg",[13.570291014418304, 48.544368093420516]],
    [ "Vichtenstein",[13.646899564702702, 48.53187559252819]],
    [ "Energie AG",[13.610422718307479, 48.54128199656294]],
    [ "Wiener Netze",[16.33637472828711, 48.08320064596624]],
    [ "Wiener Netze",[16.352216527293482, 48.077652007609224]],
    [ "Energie AG",[13.869593553666967, 48.480839378895276]],
    [ "Energie AG",[13.968857282867232, 48.49031377823992]],
    [ "Energie AG",[13.840154056353153, 48.22895655375725]],
    [ "KNG-Kärnten Netz GmbH",[13.707317661792171, 46.594515317790346]],
    [ "419146054",[14.896340312126876, 48.24351399549001]],
    [ "419157957",[14.895455918648116, 48.23044653627037]],
    [ "Energie AG",[13.927860202113774, 48.68619389379913]],
    [ "Energie AG",[13.91923517042006, 48.67511734612618]],
    [ "Energie AG",[13.95280379992488, 48.600019048177806]],
    [ "Energie AG",[13.917004709160553, 48.658145781068676]],
    [ "Energie AG",[13.940066784482992, 48.602643591237644]],
    [ "419240722",[13.981759811726878, 48.584628748215586]],
    [ "Bramberg Taxerhof",[12.33059975, 47.26554744999999]],
    [ "Linz AG",[14.496412339003921, 48.31020261994789]],
    [ "Linz AG",[14.509851261677095, 48.30571806585966]],
    [ "419624712",[14.472518917633808, 48.32027609654741]],
    [ "Energie AG",[14.071207499999996, 48.4992585]],
    [ "Energie AG",[14.084673953123705, 48.501803409080736]],
    [ "Energie AG",[14.046668205857966, 48.468279112462895]],
    [ "Netz OÖ",[14.223881194572783, 48.23005308664592]],
    [ "KNG-Kärnten Netz GmbH",[14.182162395144351, 46.68704419995068]],
    [ "Energie AG",[14.181698445716124, 48.18426209858961]],
    [ "Energie AG",[13.752281525846891, 48.36608671766524]],
    [ "Energie AG",[13.719388091329783, 48.3919753142929]],
    [ "Thomasroith",[13.619660111625288, 48.07856912230071]],
    [ "420529963",[13.230426150779152, 47.40516974936709]],
    [ "420531973",[13.234184016097275, 47.42394841356193]],
    [ "420533501",[13.249733015028532, 47.42100502145983]],
    [ "420552664",[15.949007018099802, 46.76144717010227]],
    [ "420761878",[13.61866053658294, 48.04900314382417]],
    [ "420793703",[13.64320766076583, 48.05166134793103]],
    [ "420897895",[13.253083303782729, 47.43075285335387]],
    [ "420905149",[13.017176881154455, 47.31120213876113]],
    [ "421050114",[12.688965594278093, 47.29159619847363]],
    [ "Salzburg Netz",[12.969187291731428, 47.92134056875998]],
    [ "Kolopfern",[13.564153299060361, 48.060615639109706]],
    [ "Brunn",[13.300290499626247, 47.99121176128451]],
    [ "Puchkirchen",[13.57500990858396, 48.043899741822635]],
    [ "Kerschham Säge",[13.139458455488931, 48.0314894504904]],
    [ "Linz AG",[14.688237557364841, 48.231310491344686]],
    [ "E-Werk Perg",[14.660049250324525, 48.24453362484548]],
    [ "Energie AG",[14.280649600000002, 48.143810900000005]],
    [ "Salzburg Netz",[13.2041573, 47.956115499999996]],
    [ "KNG-Kärnten Netz GmbH",[14.210296216514005, 46.69000048169136]],
    [ "422041875",[14.192763434049285, 48.107816750693644]],
    [ "422175430",[13.711490904369873, 48.03610289832512]],
    [ "Netz OÖ",[13.10593619953743, 48.02268251686594]],
    [ "422578579",[14.608965406473853, 47.9386781738815]],
    [ "422579670",[14.629224350000001, 47.92499215000001]],
    [ "Wiener Netze",[16.618719156176013, 48.16745945614541]],
    [ "Salzburg AG",[13.168904353948934, 47.840106803906515]],
    [ "422943934",[15.86718792506775, 46.82151505078078]],
    [ "422957020",[14.25877359731426, 48.050986150022645]],
    [ "Sierning Forsthof",[14.306314450000002, 48.0403152]],
    [ "Salzburg AG",[12.798688, 47.34764425]],
    [ "423630397",[14.621593134750414, 48.334379299853225]],
    [ "Linz AG",[14.617900350559049, 48.332400444201355]],
    [ "423630404",[14.616468479643828, 48.33679369091331]],
    [ "Energie AG",[14.325866634149664, 48.019582217717584]],
    [ "423840592",[12.809079059840046, 47.351019726458524]],
    [ "423840629",[12.809972051207245, 47.348114106509115]],
    [ "423854182",[14.345814612315646, 48.05514523001458]],
    [ "423855438",[14.350331073459774, 48.057921156068716]],
    [ "Kerschham Gewerbe",[13.14190234343979, 48.02698345050463]],
    [ "Salzburg AG",[13.561968908253288, 47.25569536766163]],
    [ "Linz AG",[14.552798016176855, 48.24492461131602]],
    [ "Linz AG",[14.701452699711954, 48.227153949064395]],
    [ "Linz AG",[14.708812536673442, 48.26787116948401]],
    [ "Linz AG",[14.66208363915549, 48.31593924380475]],
    [ "424408464",[14.032880287876873, 48.153570871548936]],
    [ "424416620",[14.035802599999998, 48.15292729999999]],
    [ "424417968",[14.044076330829986, 48.1588740442284]],
    [ "424437631",[12.447804050027344, 47.29869684523877]],
    [ "SalzburgAG",[13.040983733275363, 47.84536007296275]],
    [ "Schörgeneck",[13.585082589383926, 48.5429447743822]],
    [ "Energie AG",[13.487449550713126, 48.555625248696344]],
    [ "Energie AG",[13.485018507466512, 48.55332365411345]],
    [ "Energie AG",[13.512322301400062, 48.56755830100215]],
    [ "Energie AG",[13.471949077159275, 48.52872708784706]],
    [ "425622641",[13.996584143283771, 47.94274077490917]],
    [ "Energie AG",[13.779515076791867, 48.403683450134714]],
    [ "Energie AG",[13.785233282487235, 48.40410395150004]],
    [ "TST Pottschach Buchbacherstraße",[15.996036633687945, 47.7049210673759]],
    [ "Energie AG",[14.39805538244245, 48.1256217521505]],
    [ "Maierau SCHST.",[13.556662446243985, 48.485416083588355]],
    [ "St. Florian a. I.",[13.445216249987068, 48.44231183296641]],
    [ "Eggersham",[13.48651593922567, 48.4872561187994]],
    [ "Münzkirchen",[13.571577540858959, 48.48277587522025]],
    [ "Schiessdorf",[13.587640699785677, 48.48071449264797]],
    [ "426086945",[14.542599400000002, 47.88396114999999]],
    [ "426098076",[14.545158242741214, 47.88602021828922]],
    [ "426098100",[14.54737643726505, 47.884420230446274]],
    [ "426311484",[14.536568077459565, 47.92238740514121]],
    [ "426500465",[13.683098165154883, 47.17205709945539]],
    [ "Pfarrkirchen Ort",[14.19894271178772, 48.03085843451908]],
    [ "426517173",[14.193410244849218, 48.02773578296914]],
    [ "Pfarrkirchen - Mühlgrub",[14.20251233296924, 48.027007401484376]],
    [ "Feyregg - Styria",[14.192201, 48.03097995000001]],
    [ "Energie AG",[13.688150020312216, 48.46367255397386]],
    [ "Neukirchendorf",[13.644062648222958, 48.45693958134022]],
    [ "426754843",[14.4297733, 48.02932665]],
    [ "426971380",[14.53825775, 47.8867769]],
    [ "Energie AG",[13.827781219479968, 48.34931165291649]],
    [ "Jeging Pfaffing",[13.148480291923603, 48.06238573712628]],
    [ "Jeging",[13.150068667054695, 48.05335459766746]],
    [ "Dietach Schule",[14.41439405828517, 48.08935548109025]],
    [ "427593560",[14.397570400000001, 48.107445]],
    [ "427598497",[14.462574427859332, 48.09823320279325]],
    [ "427598510",[14.431692599999998, 48.085253599999994]],
    [ "427599532",[14.422846782446905, 48.07446039730581]],
    [ "Energie AG",[13.094236749999999, 48.03802185000001]],
    [ "427742865",[14.464371758904356, 47.892889966952474]],
    [ "427748059",[14.46215715193178, 47.88956732755796]],
    [ "427755623",[14.451054381336876, 47.88377451773835]],
    [ "Energie AG",[13.937282399999999, 48.39658119999999]],
    [ "427790653",[14.44947355181466, 47.90166694969678]],
    [ "KNG-Kärnten Netz GmbH",[14.373646301550364, 46.58977399879015]],
    [ "Netz NÖ",[15.600171450000001, 48.10512215]],
    [ "428137699",[14.4848198, 47.89564055]],
    [ "428385400",[13.565823133320414, 48.090927999941876]],
    [ "Ampfelwang Gemeinde",[13.568095508857558, 48.09350295263234]],
    [ "Ampflwang Bahnhofstraße",[13.560293699512338, 48.08901423601804]],
    [ "428780356",[13.810618670417652, 46.81358899864274]],
    [ "Energie AG",[13.566691383047026, 48.07982190632401]],
    [ "429233917",[13.682835741553982, 48.05671096710674]],
    [ "429249555",[13.793353940401035, 48.07642448945865]],
    [ "Energie AG",[13.501684544185776, 48.064322702789084]],
    [ "Frankenburg Würfelsp.-Str.",[13.494929736686759, 48.06483834453552]],
    [ "Wiener Netze",[16.293477444059846, 48.0963259704901]],
    [ "Haslach b. Zipf",[13.492866459639771, 48.03734487349395]],
    [ "Energie AG",[13.487571731800115, 48.06897696863593]],
    [ "Frankenburg Fürth",[13.480145997604206, 48.07177910212559]],
    [ "Energie AG",[13.48116247339642, 48.068726111412495]],
    [ "Regau Autobahnauffahrt",[13.726096531618802, 47.95463030134474]],
    [ "Salzburg Netz",[13.006473259262403, 47.9618181252507]],
    [ "Energie AG",[13.48520225007314, 48.06301930064401]],
    [ "Frankenburg Ort",[13.488801703387802, 48.06561463117721]],
    [ "Energie AG",[13.488157299606481, 48.06373561106856]],
    [ "Netz Niederösterreich GmbH",[15.72940428316756, 47.78850619977702]],
    [ "Seewalchen Siebenmühlenweg",[13.601773573254155, 47.96197469702293]],
    [ "Kirchberg",[13.631704708944058, 48.000351372313986]],
    [ "Wiener Netze",[16.357872884595132, 48.1792787020319]],
    [ "Wiener Netze",[16.31647299150973, 48.066593980251085]],
    [ "432850659",[13.52469614481313, 47.43574710581477]],
    [ "432931698",[14.234040433081145, 48.24831322711152]],
    [ "Netz Niederösterreich GmbH",[15.805964050000002, 47.71079025]],
    [ "Salzburg AG",[12.344731393748733, 47.272518519677284]],
    [ "TST Stockerau Berggartenstraße",[16.217848259511218, 48.38488537764513]],
    [ "Treubach",[13.212185461512057, 48.194194313683674]],
    [ "433780109",[13.734917349999998, 48.08095025]],
    [ "Sommereben Schule",[15.18174838336993, 46.938507950931786]],
    [ "Sommereben",[15.19083825, 46.93681000000001]],
    [ "KNG-Kärnten Netz GmbH",[12.929487652002969, 46.952097129454685]],
    [ "Wiener Netze",[16.339225099999997, 48.12329955]],
    [ "Pettighofen",[13.611262947092088, 47.98207938986233]],
    [ "Energie AG",[13.614077752613367, 48.00082802924927]],
    [ "KNG-Kärnten Netz GmbH",[14.199436862502129, 46.981034314002784]],
    [ "Lochen",[13.179197845063726, 48.01033717520359]],
    [ "Energie AG",[13.477646560356757, 47.97344244495678]],
    [ "Exlwöhr",[13.500495104095261, 48.03244719688589]],
    [ "Energie AG",[13.495373899999997, 47.97936470000001]],
    [ "Vöcklamarkt Wallern",[13.493975395140197, 47.99998614329966]],
    [ "Vöcklamarkt Ort",[13.482925502234439, 48.00306224730791]],
    [ "Unterach",[13.4560770054484, 47.80453905079361]],
    [ "Obermühlham",[13.348914384637482, 47.9949756571964]],
    [ "Energie AG",[13.607214145239256, 47.99930671827083]],
    [ "Timelkam OKA Sdlg.",[13.604764125412766, 48.00306786538791]],
    [ "Timelkam Ort",[13.605469883632, 48.00195660783192]],
    [ "KNG-Kärnten Netz GmbH",[14.120189229889583, 46.63533197130557]],
    [ "438120529",[14.46712823483134, 48.48709473849144]],
    [ "438120537",[14.478986995512344, 48.4942962720877]],
    [ "Winkeln Gewerbe",[13.9279739, 48.21686365000001]],
    [ "438496543",[13.645177158118496, 48.08223348448899]],
    [ "438507982",[13.63138569007571, 48.100003542312905]],
    [ "Energie AG",[13.55388686457967, 47.93849127707379]],
    [ "Energie AG",[13.576706888580944, 47.95251647952193]],
    [ "438951471",[15.99967295103827, 47.655417803822964]],
    [ "KNG-Kärnten Netz GmbH",[14.787703150088355, 46.578204249384264]],
    [ "439028631",[13.036989038980995, 47.77582627710782]],
    [ "KNG-Kärnten Netz GmbH",[14.886728667713749, 46.92878495303529]],
    [ "KNG-Kärnten Netz GmbH",[14.894498154982402, 46.930910382368786]],
    [ "Salzburg Netz",[13.05868805, 47.8576667]],
    [ "Oberachmann Sdlg.",[13.612235056313157, 47.9568807016503]],
    [ "Energie AG",[13.602305497638813, 47.97351965032042]],
    [ "440013888",[13.618953911198837, 47.96587820824622]],
    [ "Linz AG",[14.682508900000002, 48.1961513]],
    [ "Linz AG",[14.715702590879125, 48.190544340491094]],
    [ "Linz Netz",[14.546032276224452, 48.272103716324]],
    [ "Linz AG",[14.540624647910542, 48.31332135466663]],
    [ "Linz Netz",[14.647412293494591, 48.209298957603565]],
    [ "Linz Netz",[14.665481804038679, 48.204911401502464]],
    [ "440196858",[14.625997339287656, 48.21763768441297]],
    [ "Linz AG",[14.538810294198832, 48.29021733283297]],
    [ "Linz AG",[14.471257453027755, 48.25312555793453]],
    [ "Linz AG",[14.463381746947041, 48.25847559560822]],
    [ "Linz AG",[14.47641647537274, 48.293162547210905]],
    [ "Wiener Netze",[16.488230950000002, 48.026943599999996]],
    [ "Linz AG",[14.38689090532532, 48.56127486150049]],
    [ "Linz AG",[14.358219163722524, 48.518798891848576]],
    [ "Salzburg Netz",[13.064364783249191, 47.858183848197555]],
    [ "Uttendorf Steinbruchsdlg.",[13.121066373827997, 48.16210614507563]],
    [ "Kronleithen",[13.120547354200147, 48.16771024095439]],
    [ "Energie Ried",[13.474502447827687, 48.17838759956711]],
    [ "Reichsdorf",[13.123511621801121, 48.176099970363865]],
    [ "Energie AG",[13.074918200000003, 48.095976525000424]],
    [ "Auerbach",[13.106357530282304, 48.065991618852465]],
    [ "Energie Ried",[13.483242514256444, 48.172634768825816]],
    [ "Humertsham",[13.089809112579966, 48.1138504117278]],
    [ "Energie AG",[13.113249429761378, 48.094781055510396]],
    [ "Grillham",[13.077618349890233, 48.156986648426475]],
    [ "Pischelsdorf",[13.081312739895992, 48.12874940805774]],
    [ "Salzburg AG",[12.731703657755261, 47.65014271856435]],
    [ "Linz AG",[14.236214226418072, 48.35130170431549]],
    [ "441565756",[14.41656659607028, 48.381811916741185]],
    [ "Linz AG",[14.2769893, 48.2707526]],
    [ "Linz AG",[14.27074316853065, 48.269371836413136]],
    [ "KNG-Kärnten Netz GmbH",[14.282723007231906, 46.57553192019642]],
    [ "442147976",[14.421890878583241, 48.35337415582796]],
    [ "KNG-Kärnten Netz GmbH",[14.0986634, 46.7263495]],
    [ "Salzburg Netz",[13.065152279352569, 47.88105603702092]],
    [ "Energie AG",[14.102296843150548, 48.331028854638596]],
    [ "Energie AG",[14.11051718711015, 48.333324254968254]],
    [ "442533043",[14.097978781424464, 48.32654505395216]],
    [ "Energie AG",[14.0499736697455, 48.3466898878661]],
    [ "Friedhof Feldkirchen",[14.054372526821892, 48.34779929654972]],
    [ "Salzburg Netz",[13.05622764180089, 47.853305246009526]],
    [ "442798236",[14.098535634207762, 46.71530784280724]],
    [ "Salzburg Netz",[13.075158022262036, 47.96957185517599]],
    [ "Energie AG",[14.398247715359318, 48.2098217264513]],
    [ "Wiener Netze",[16.321647380607097, 48.05959870613617]],
    [ "Energie AG",[14.233069449824688, 48.39539800190972]],
    [ "Energie AG",[14.239586203185716, 48.39848269672122]],
    [ "443767630",[14.871395053756043, 48.75147290706771]],
    [ "Valentinshaft",[13.193956048767452, 48.039898903286726]],
    [ "Munderfing Römerstrasse",[13.18272761720245, 48.06494680458453]],
    [ "Umspannwerk Klosterneuburg",[16.333728034262176, 48.30008637940668]],
    [ "Teichstätt Ort",[13.2130386, 48.02666879999999]],
    [ "Friedburg Siedlung",[13.235600644726368, 48.02081046612745]],
    [ "Friedburg",[13.240015560271125, 48.01933839821467]],
    [ "KNG-Kärnten Netz GmbH",[14.086618655328962, 46.71630676850049]],
    [ "Friedburg Gassl",[13.2456340018609, 48.01659534805981]],
    [ "TST Haugsdorf 1",[16.074797820265847, 48.70600243159801]],
    [ "Salzburg Netz",[13.314430956958976, 47.59513303502232]],
    [ "TST Haugsdorf Winzergenossenschaft",[16.070308852668195, 48.70541761056113]],
    [ "Friedburg Schule",[13.24943089998503, 48.0153938238523]],
    [ "Linz AG",[14.451769804161817, 48.32323313996668]],
    [ "Wiener Netze",[16.312154566692268, 48.072030833355086]],
    [ "EBNER STROM GmbH",[14.657787906189705, 48.36046300827507]],
    [ "Energie AG",[13.613225876720561, 48.332714287860234]],
    [ "Wiener Netze",[16.299849450000004, 48.11478675]],
    [ "Kälbermoos Ort",[12.832933073462332, 48.13646640224143]],
    [ "446417187",[14.190396482920038, 48.07221884504987]],
    [ "Lengau",[13.210917098316347, 48.01405667258789]],
    [ "Scherschham Gewerbe",[13.176684856694875, 48.01869070031851]],
    [ "Salzburg Netz",[13.080563161285571, 47.93870197893883]],
    [ "446680295",[14.193231817325021, 48.11070449413969]],
    [ "446761133",[14.189299952795295, 48.104522561466155]],
    [ "Energie AG",[13.889684370688457, 48.63597426931885]],
    [ "Kärnten Netz",[14.470303845368885, 46.69158680737651]],
    [ "Lochen Siedlugn",[13.17188706782717, 48.006798433840515]],
    [ "Lochen Hauptschule",[13.176702256095803, 48.011197503787784]],
    [ "Lochen Schule",[13.178891545151924, 48.00707604807119]],
    [ "Lengau West",[13.213326262467325, 48.00661668747963]],
    [ "Lengau Ort",[13.218948488335753, 48.00546163204926]],
    [ "Energie AG",[14.127549591080298, 48.35597896818415]],
    [ "Bogenhofen",[13.115801818897555, 48.26148131860975]],
    [ "Energie AG",[13.326312720654917, 48.315865608082646]],
    [ "Energie AG",[13.368317037796313, 48.33532076211384]],
    [ "Energie AG",[13.375587059135436, 48.33998045560548]],
    [ "449022441",[13.465907237897648, 48.308042726403144]],
    [ "Greifing",[13.394908608027567, 48.29759480109603]],
    [ "Energie AG",[13.9901477, 48.3079784]],
    [ "449519892",[14.0240242, 48.16666000000001]],
    [ "Kärnten Netz",[13.998155019441613, 46.6803490587901]],
    [ "449792974",[13.998435868250718, 48.15190225899365]],
    [ "449800797",[14.153855337853694, 48.34970027000536]],
    [ "Energie AG",[13.857612118672975, 48.372239445783066]],
    [ "Wiener Netze",[16.28573125227441, 47.95090020450404]],
    [ "450205118",[13.996940703976087, 48.158014481469216]],
    [ "Wurzeralm Bergstation",[14.288486800000001, 47.645534749999996]],
    [ "451675028",[13.850402999999998, 48.22616444999999]],
    [ "Teufenbach",[13.493308907401229, 48.40580694864702]],
    [ "St. Florian Pramerdorf",[13.454235682181956, 48.43146663456042]],
    [ "Jeging Gewerbe",[13.16053588813967, 48.058679648236506]],
    [ "KNG-Kärnten Netz GmbH",[14.288948852062793, 46.669787466066126]],
    [ "453497492",[14.582998470398092, 47.91848135288851]],
    [ "Netz OÖ",[14.544688618649776, 47.89507263404867]],
    [ "Energie AG",[14.360531894973144, 47.94697267393663]],
    [ "Energie AG",[14.334872831674767, 47.91974279744543]],
    [ "KNG-Kärnten Netz GmbH",[14.581061199693263, 46.613046655137886]],
    [ "454514482",[14.169955839817236, 48.14932368498618]],
    [ "454514486",[14.217735662502106, 48.12705594257232]],
    [ "454514489",[14.16685617428263, 48.095446875339505]],
    [ "454514490",[14.19746707981108, 48.112620634498285]],
    [ "454514492",[14.201586870842098, 48.11227671963525]],
    [ "Energie AG",[14.187855911434635, 48.06693593745829]],
    [ "Kematen an der Krems - Schule",[14.196158856424324, 48.10935456296148]],
    [ "Wiener Netze",[16.444743712988874, 48.08673903295878]],
    [ "KNG-Kärnten Netz GmbH",[14.110229720980751, 46.69872719921558]],
    [ "Energie AG",[14.396121704963736, 48.011235176468034]],
    [ "TST Bad Schönau Hauptstraße",[16.23341856408121, 47.49839432573855]],
    [ "Untererb",[13.25994071042556, 48.017025608711954]],
    [ "Gunzing",[13.192136170843105, 47.99544606940146]],
    [ "Braunau Krieger Friedhof",[13.06144759661024, 48.25358944912371]],
    [ "Munderfing",[13.178738163255394, 48.067784874604534]],
    [ "KNG-Kärnten Netz GmbH",[14.118715931407403, 46.691924271942774]],
    [ "Unterminathal",[13.212395118387201, 48.137189466426435]],
    [ "Wiener Netze",[16.32388725853393, 48.08360358035242]],
    [ "457975974",[14.3760067, 48.20639829999999]],
    [ "458485027",[14.027281233090973, 48.29281654757002]],
    [ "Munderfing Lindenstrasse",[13.17553461997328, 48.07240272659026]],
    [ "Linz Strom",[14.244533212006159, 48.31889766604822]],
    [ "Höllersberg",[13.160206207620444, 48.08281450146287]],
    [ "Munderfing Gewerbegebiet",[13.171718892071587, 48.07627729544226]],
    [ "Energie AG",[14.426665213685695, 47.92329476576939]],
    [ "KNG-Kärnten Netz GmbH",[14.247311168103552, 46.53587214694294]],
    [ "Munderfing Pfaffstätterstraße",[13.16688169603413, 48.072747552509625]],
    [ "Linz AG",[14.551025613412477, 48.29515711579843]],
    [ "Höhnhart",[13.270072690681198, 48.1648775097335]],
    [ "Energie AG",[13.235337959395652, 48.15424393453437]],
    [ "Feichta",[13.272566910349147, 48.14319689843852]],
    [ "Netz Niederösterreich",[16.297230650000003, 47.836996299999996]],
    [ "Ach Wenghartsdlg.",[12.855224678026131, 48.16643763687317]],
    [ "Energie AG",[14.00795041758622, 48.59173805610676]],
    [ "Energie AG",[14.032568909464825, 48.57683838721671]],
    [ "Energie AG",[13.980655830843872, 48.6200120994707]],
    [ "Dobl",[13.248782520275597, 48.121488202284226]],
    [ "Salzburg AG",[13.34848195, 47.5639064]],
    [ "Wiener Netze GmbH",[16.259822568727266, 47.95869439875126]],
    [ "Wiener Netze GmbH",[16.25060231990141, 47.96065219750187]],
    [ "Pfaffstätt Geflügelhof",[13.139524790470112, 48.072806920388004]],
    [ "KNG-Kärnten Netz GmbH",[13.677515278939355, 46.655528844860136]],
    [ "KNG-Kärnten Netz GmbH",[14.101620197245092, 46.64446145704742]],
    [ "KNG-Kärnten Netz GmbH",[13.652904558755989, 46.66552447731024]],
    [ "KNG-Kärnten Netz GmbH",[14.091791278181494, 46.6472586418764]],
    [ "Netz OÖ",[13.111972832285655, 48.08340430480188]],
    [ "Energie AG",[14.179118791746284, 48.31259259118747]],
    [ "Linz AG",[14.19667296458668, 48.29150609492927]],
    [ "463265821",[14.642780182680296, 48.457518997387844]],
    [ "463433989",[14.60993676270544, 48.421003724782054]],
    [ "Webersdorf Gusswerk Sch.St.",[12.953704344920977, 48.10440161680707]],
    [ "Unterwerk Breitenweg",[15.474983413021478, 47.059500751045896]],
    [ "Netz NÖ",[15.9784615, 48.1656865]],
    [ "Energienetze Steiermark",[15.900695509505265, 46.73129855912133]],
    [ "464086540",[15.919973795530742, 46.73756664327172]],
    [ "Energie AG",[14.154642698310667, 48.3326187002572]],
    [ "Energie AG",[14.130471700302389, 48.33145907634151]],
    [ "KNG-Kärnten Netz GmbH",[14.547934535069187, 46.938511098418175]],
    [ "464480070",[15.966155585044318, 46.76635747920264]],
    [ "Mösenberg",[13.479936456255887, 47.99504572833614]],
    [ "Berlinerring D",[15.49389132629814, 47.075948272068054]],
    [ "464662913",[14.353292332155865, 48.018675947639]],
    [ "464687690",[14.304987970261505, 47.99275219611172]],
    [ "464933030",[14.77813082662386, 48.222047193249374]],
    [ "465941741",[15.94896120097967, 46.71325339953638]],
    [ "466381009",[13.76727955, 47.9387293]],
    [ "Energie AG",[13.73477447978097, 47.92118375319222]],
    [ "Wiener Netze",[16.38086805, 47.869787499999994]],
    [ "466669506",[13.760292763973368, 47.928686626706785]],
    [ "EWW",[14.063758565975105, 48.18047735360264]],
    [ "467003899",[16.003766706574734, 46.68981382682029]],
    [ "Munderfing Betonwerk",[13.189517769071324, 48.06164201582958]],
    [ "KNG-Kärnten Netz GmbH",[14.087474314645265, 46.68798075258164]],
    [ "Linz AG",[14.285321506705435, 48.257586079460935]],
    [ "Linz AG",[14.284835421955268, 48.25902570782618]],
    [ "Altheim St. Laurenz",[13.2280935013179, 48.246441058104445]],
    [ "KNG-Kärnten Netz GmbH",[14.15855336646018, 46.8141488342638]],
    [ "470496285",[14.6721968, 47.862795549999994]],
    [ "470920898",[14.4097364, 48.092954500000005]],
    [ "Höcken",[13.321506190486962, 48.02803660390342]],
    [ "471703379",[13.905659705419103, 47.91059229001783]],
    [ "471782922",[13.947187850882264, 47.901900344788004]],
    [ "471802605",[13.938794362211969, 47.892374693210115]],
    [ "471806957",[13.933259349999997, 47.88999644999999]],
    [ "Linz AG",[14.743524386570003, 48.21352244221884]],
    [ "471831632",[13.863925133181022, 46.84499232018348]],
    [ "Altheim Trabrennplatz",[13.234156196089572, 48.258790474273354]],
    [ "KNG-Kärnten Netz GmbH",[13.956813003550785, 46.624504019155694]],
    [ "Munderfing Bradirn",[13.186231324563662, 48.06947286957448]],
    [ "KNG-Kärnten Netz GmbH",[13.419671499093178, 46.62673007349622]],
    [ "472600321",[13.93982982377223, 47.87744879952886]],
    [ "Linz AG",[14.621760618874955, 48.22113444368836]],
    [ "473591971",[14.623235800000002, 48.212213950000006]],
    [ "473952572",[14.750787500000001, 48.21011079999999]],
    [ "Energie AG",[13.833445123622443, 48.730229465951055]],
    [ "Energie AG",[13.871253166624658, 48.68617189143691]],
    [ "Energie AG",[13.853282693294057, 48.70234678011665]],
    [ "Munderfing Mitte",[13.18375595869347, 48.06720005534427]],
    [ "474599589",[14.6813609, 47.920422200000004]],
    [ "St. Georgen b. Obernberg a. I.",[13.332724878237526, 48.293857008125975]],
    [ "Energie AG",[13.663152843608197, 48.0094591721043]],
    [ "Gunderding",[13.186987348926841, 48.25036285121293]],
    [ "KNG-Kärnten Netz GmbH",[13.415049025517156, 46.69922735176241]],
    [ "KNG-Kärnten Netz GmbH",[13.476509263001468, 46.713047801355664]],
    [ "Arndorf b. Hörzendorf",[14.35395542636075, 46.73874024281133]],
    [ "Aselkam",[13.073819353674548, 48.24829584298085]],
    [ "Energie AG",[13.652667257160848, 48.00763462158128]],
    [ "Salzburg Netz",[13.024515249999999, 47.83885024999999]],
    [ "477879014",[13.92974725, 48.01047485]],
    [ "Lenzing Böhmstr.",[13.609094230162212, 47.97542460188011]],
    [ "478771464",[13.922506899999998, 47.9971119]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.905869525846672, 47.610368708203644]],
    [ "Umspannwerk Gaweinstal",[16.568984426604597, 48.47429826581815]],
    [ "Umspannwerk Hauskirchen",[16.74912152065898, 48.625841805902084]],
    [ "478984852",[14.573153375083542, 47.9716088132853]],
    [ "Anton Mellweg",[15.407845400000001, 47.04493644999999]],
    [ "Energie AG",[13.610170451541407, 47.95976175127458]],
    [ "Energie AG",[13.990843259806093, 48.57905988966773]],
    [ "TST Wr. Neustadt Pottendorferstraße",[16.2510996, 47.825763450000004]],
    [ "Wiener Netze",[16.22702856846278, 47.996784697804756]],
    [ "Energie AG",[13.987747069209732, 47.91420310644832]],
    [ "482554588",[16.61087235, 47.800644399999996]],
    [ "Neupirath",[13.216063437474524, 48.25739107913455]],
    [ "Altheim Wengerweg",[13.23051158878099, 48.24346870464775]],
    [ "Stern",[13.218389772212655, 48.231273604766756]],
    [ "482988424",[13.242108628600361, 48.25011876408549]],
    [ "Gallenberg",[13.229538691540784, 48.266194203335345]],
    [ "483017880",[13.235727191211561, 48.25193879870394]],
    [ "Hart b. St. Peter Dominikanergasse",[15.516663432414264, 47.07180744485133]],
    [ "KNG-Kärnten Netz GmbH",[13.644412163007642, 46.78054826813645]],
    [ "KNG-Kärnten Netz GmbH",[13.64842660749968, 46.773829789530794]],
    [ "Energie AG",[12.98897325253979, 48.07709495373558]],
    [ "Umspannwerk Kramsach",[11.865389136544906, 47.43382288103195]],
    [ "Linz AG",[14.50024593103179, 48.24715284506858]],
    [ "Linz AG",[14.497228350000002, 48.250275249999994]],
    [ "485489555",[14.515920696372863, 48.243681503786895]],
    [ "486697579",[14.4167795, 48.014220949999995]],
    [ "Linz AG",[14.609288320378491, 48.22057252074537]],
    [ "Linz Netz",[14.676348974625798, 48.20168956719638]],
    [ "Linz AG",[14.588551937664421, 48.22960790746241]],
    [ "Linz AG",[14.601002612596602, 48.229314897388456]],
    [ "Niedersebern",[14.583966741059006, 48.24474740189116]],
    [ "Niedersebern",[14.600095725048766, 48.236066900463335]],
    [ "Niedersebern",[14.598515156945986, 48.24052455704775]],
    [ "KNG-Kärnten Netz GmbH",[14.011097247376108, 46.766005529443724]],
    [ "Energie AG",[13.760945412382881, 48.507699303110456]],
    [ "Linz AG",[14.759454600528665, 48.21444230005796]],
    [ "Linz AG",[14.78576229875001, 48.2441832771925]],
    [ "Linz AG",[14.797132690791724, 48.223567341755334]],
    [ "Sierning Waltherweg",[14.303879549999998, 48.042118949999995]],
    [ "487965421",[14.181368058713721, 47.77368069585951]],
    [ "Netz Niederösterreich GmbH",[16.334891117765483, 48.72290758674371]],
    [ "488379041",[14.088703800000001, 48.207548]],
    [ "Micheldorf Warschenbichl",[14.175154597625198, 47.86627890431769]],
    [ "488395735",[14.151404416314525, 47.86320616628564]],
    [ "KNG-Kärnten Netz GmbH",[14.005151857343192, 46.742657755986215]],
    [ "KNG-Kärnten Netz GmbH",[14.083637682312292, 46.79220730046996]],
    [ "Energie AG",[14.284644750000002, 47.958383999999995]],
    [ "Energienetze Steiermark",[15.323062694425847, 47.20586416148321]],
    [ "KNG-Kärnten Netz GmbH",[14.085440348547337, 46.7237390173266]],
    [ "KNG-Kärnten Netz GmbH",[14.097653850000002, 46.72396975]],
    [ "Rossbach Ort",[13.249164388321525, 48.20058787167061]],
    [ "Energie AG",[13.266759662231644, 48.19918789931799]],
    [ "Jaiding SCHST",[13.266943362499836, 48.19628426171541]],
    [ "Energie AG",[14.272255000756374, 47.97460977930741]],
    [ "Energie AG",[14.273681255410164, 47.9845677487743]],
    [ "Energie AG",[14.353368400019717, 47.95098294927542]],
    [ "Energie AG",[14.3062367732148, 48.00743636886676]],
    [ "Energie AG",[14.407886953541519, 47.99358742991578]],
    [ "Energie AG",[14.416580554482055, 47.90676916973612]],
    [ "Energie AG",[14.340443534215972, 47.979676893660354]],
    [ "Energie AG",[14.397981844230355, 47.99706087109445]],
    [ "Energie AG",[14.333137790290056, 48.01174949053411]],
    [ "Dauersdorf",[14.14014355522566, 47.9638595285225]],
    [ "Energie AG",[14.170032210314638, 47.98370799882487]],
    [ "Nußbach",[14.16839455066898, 47.972894397373494]],
    [ "Netz Oberösterreich GmbH",[14.395751040791783, 47.71282544920906]],
    [ "Energie AG",[14.291049493235265, 47.71448053138493]],
    [ "Energie AG",[14.32693426153058, 48.12560135858565]],
    [ "Energie AG",[14.228237951691687, 48.129233630474616]],
    [ "Energie AG",[14.38071795370155, 48.13987480781367]],
    [ "Energie AG",[14.343446467949162, 48.15943571117274]],
    [ "Energie AG",[14.427803658241887, 48.15411612061179]],
    [ "KNG-Kärnten Netz GmbH",[14.138647421373538, 46.72483755092947]],
    [ "Energienetze Steiermark",[15.497939781673114, 47.017867298638784]],
    [ "Gstöcket",[13.604895395276113, 48.20579120031584]],
    [ "491954704",[13.88341654635277, 47.95877378195866]],
    [ "Energie AG",[14.29931451261418, 48.52130381651444]],
    [ "Energie AG",[14.222875245377733, 48.550514706358086]],
    [ "Energie AG",[14.298467348116164, 48.5369418805924]],
    [ "Energie AG",[14.310345210671993, 48.55799295244211]],
    [ "Energie AG",[14.111041755681113, 48.5717668456142]],
    [ "Energie AG",[14.101668752889651, 48.569132650323546]],
    [ "Energie AG",[14.064837714329801, 48.586017342388544]],
    [ "Lehen Mettmach",[13.357371496660123, 48.1632308426294]],
    [ "Energie AG",[14.029982451938059, 48.61829960466896]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.963132067265379, 47.62905505395367]],
    [ "Elektrizitätswerke Eisenhuber",[16.00541960035479, 47.57997385196011]],
    [ "Netz Niederösterreich GmbH",[15.931617090915116, 47.64310047373343]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[16.006175227858606, 47.59756944298192]],
    [ "Energie AG",[14.27863102767837, 48.49576863455821]],
    [ "Netz Niederösterreich GmbH",[16.19749864216105, 47.597997455511084]],
    [ "TST Kleinau Großau Großau",[15.773972127148092, 47.69224494459747]],
    [ "Linz AG",[14.23002002731061, 48.42037900923746]],
    [ "Linz AG",[14.250120602379539, 48.44113473944367]],
    [ "Linz AG",[14.156506460001133, 48.352554208511606]],
    [ "Linz AG",[14.159881063111985, 48.35527825634677]],
    [ "Energie AG",[14.186702964432902, 48.49230555490102]],
    [ "Energie AG",[14.171686069934353, 48.48046541966529]],
    [ "Energie AG",[14.165121795904223, 48.46987495874996]],
    [ "Energie AG",[14.17889233244805, 48.39766743721105]],
    [ "Energie AG",[14.192200000840305, 48.38493618456124]],
    [ "Energie AG",[14.05138805124721, 48.439288902003796]],
    [ "Energie AG",[14.05793815501215, 48.425823799790734]],
    [ "Energie AG",[14.074560565660336, 48.40186740347626]],
    [ "Energie AG",[14.084287511548053, 48.392347573214906]],
    [ "Energie AG",[14.135273592043768, 48.548856497695525]],
    [ "Energie AG",[14.109298906274041, 48.42728889752065]],
    [ "Energie AG",[14.087772297032487, 48.449217319960354]],
    [ "Energie AG",[14.084219108475928, 48.45404454648324]],
    [ "Kärnten Netz",[14.004239314573681, 46.697120524942044]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.944952663217546, 47.62267749118228]],
    [ "Netz Niederösterreich GmbH",[15.932662790486306, 47.66409427874063]],
    [ "Kärnten Netz",[13.990768834811192, 46.69590559977249]],
    [ "EWW",[14.058144057198852, 48.19284568126788]],
    [ "Linz AG",[14.438169896329013, 48.263512833577835]],
    [ "Linz AG",[14.423124966580543, 48.25850676649544]],
    [ "Wiener Netze",[16.38063481816523, 47.8552753475831]],
    [ "493756569",[14.086468600000002, 48.1963986]],
    [ "Netz Niederösterreich GmbH",[16.19462732826389, 47.61340554487811]],
    [ "Energie AG",[14.079201491777633, 48.352037881225804]],
    [ "Energie AG",[14.061710174321346, 48.35092434652679]],
    [ "Kärnten Netz",[13.962223485826561, 46.67815073224602]],
    [ "Energie AG",[13.997168154572657, 48.45784905623948]],
    [ "Energie AG",[14.00130915061697, 48.48156070040705]],
    [ "Energie AG",[13.898219294274222, 48.493950805646655]],
    [ "KNG-Kärnten Netz GmbH",[13.940630181057392, 46.67488520463961]],
    [ "KNG-Kärnten Netz GmbH",[13.921751830269322, 46.664283944334215]],
    [ "Linz AG",[14.34632401125016, 48.451265574644104]],
    [ "Linz AG",[14.380713043498432, 48.54202145942206]],
    [ "Linz AG",[14.352722925000004, 48.529422200000006]],
    [ "Linz AG",[14.311746217754388, 48.361717381573655]],
    [ "Linz AG",[14.310765767083666, 48.43719575137609]],
    [ "Linz AG",[14.217949590680403, 48.37131703896733]],
    [ "Energie AG",[13.897256289772477, 48.67466247839741]],
    [ "Energie AG",[13.901215171905712, 48.677302269539325]],
    [ "Energie AG",[13.908830950071225, 48.681444400892964]],
    [ "Energie AG",[13.901264935912343, 48.704987028410194]],
    [ "Energie AG",[13.892376735471121, 48.619289909493865]],
    [ "Energie AG",[13.82880339273251, 48.67610578272886]],
    [ "Netz OÖ",[13.757083948921315, 47.79254045435986]],
    [ "E-Werk Perg",[14.645046036078876, 48.29326055702403]],
    [ "E-Werk Perg",[14.636051738501191, 48.28576806927075]],
    [ "E-Werk Perg",[14.626106511080698, 48.25625313041366]],
    [ "E-Werk Perg",[14.62554258946234, 48.26501679186419]],
    [ "E-Werk Perg",[14.665282750000001, 48.34602794999999]],
    [ "E-Werk Perg",[14.6743184, 48.3358425]],
    [ "Linz AG",[14.560559225787802, 48.2750095945055]],
    [ "Linz AG",[14.575652983805833, 48.29101650084231]],
    [ "Linz AG",[14.579581661755904, 48.2984405793448]],
    [ "Linz AG",[14.645510950704628, 48.316050149753835]],
    [ "Linz AG",[14.566383935737976, 48.269503437525444]],
    [ "EBNER STROM GmbH",[14.561444738368786, 48.42341024884833]],
    [ "Linz AG",[14.61156032817625, 48.41747145960353]],
    [ "Linz AG",[14.61070315, 48.415531699999995]],
    [ "Linz Netz",[14.515187297017334, 48.369081028368484]],
    [ "Linz AG",[14.5125481, 48.36987655]],
    [ "Netz Niederösterreich GmbH",[15.873599929560966, 47.65933454493031]],
    [ "Linz AG",[14.487200403698315, 48.3223095316466]],
    [ "Linz Netz",[14.477687797927526, 48.32754160167697]],
    [ "Linz Netz",[14.44816103788517, 48.353397660165776]],
    [ "Linz Netz",[14.462960036221622, 48.354225375502516]],
    [ "Linz AG",[14.511337439267043, 48.39893267978763]],
    [ "Linz AG",[14.5098117, 48.3484342]],
    [ "Linz Netz",[14.50866259941243, 48.34391959852742]],
    [ "Linz Netz",[14.562573558622054, 48.34307413579608]],
    [ "EBNER STROM GmbH",[14.613685410415862, 48.41814189690106]],
    [ "Linz AG",[14.563799211930787, 48.38421685768732]],
    [ "EBNER STROM GmbH",[14.646765798835936, 48.369082596135975]],
    [ "Linz AG",[14.706939697027531, 48.377016841889336]],
    [ "Linz AG",[14.534225598785936, 48.47345196046907]],
    [ "496635618",[14.496778492099452, 48.4800672628321]],
    [ "Linz AG",[14.497963310236953, 48.260753698249374]],
    [ "Linz AG",[14.45339665, 48.2661042]],
    [ "Linz AG",[14.475198290170887, 48.252041505578404]],
    [ "Linz AG",[14.463556778745998, 48.255392524819875]],
    [ "Linz AG",[14.483393599581278, 48.25313134788068]],
    [ "Linz AG",[14.437060524345942, 48.26706130676233]],
    [ "Linz AG",[14.440457461102033, 48.27777420866118]],
    [ "497064713",[13.792939845379621, 47.94583311709958]],
    [ "497111121",[13.805079450000001, 47.94964714999999]],
    [ "Linz AG",[14.470532561271671, 48.316953482094156]],
    [ "Linz AG",[14.481717804071353, 48.30713317360916]],
    [ "Linz AG",[14.500337025670849, 48.28385443569835]],
    [ "Linz AG",[14.507287087337605, 48.29817941165416]],
    [ "Umspannwerk Rainbach im Mühlkreis",[14.48886524880473, 48.53357494502803]],
    [ "Linz AG",[14.487354345983169, 48.451324550310694]],
    [ "Linz AG",[14.495438662296683, 48.453307075455896]],
    [ "Linz AG",[14.458712906117682, 48.263384510549606]],
    [ "Linz AG",[14.467888099597854, 48.26899141761847]],
    [ "Linz AG",[14.472814254699875, 48.28378804548315]],
    [ "Linz AG",[14.481908251026903, 48.28881230277461]],
    [ "Linz AG",[14.457502012674485, 48.273724125013956]],
    [ "Linz AG",[14.53205007904811, 48.27021440836314]],
    [ "Linz AG",[14.572171068467734, 48.309655289942455]],
    [ "Linz AG",[14.56685912255153, 48.30250689920859]],
    [ "Linz AG",[14.448115755435312, 48.28232285688219]],
    [ "Linz AG",[14.414879200000001, 48.28342925]],
    [ "Linz AG",[14.420171282880156, 48.28477891202448]],
    [ "498014108",[14.282574599999998, 48.29887144999999]],
    [ "KNG-Kärnten Netz GmbH",[13.827527250000001, 46.69145055000001]],
    [ "Wiener Netze",[16.30527943687002, 48.05249768206045]],
    [ "Wiener Netze",[16.350238704243804, 48.079985979115456]],
    [ "Uttendorf Lohnau",[13.119001492927532, 48.15080569947871]],
    [ "Linz AG",[14.227424816850101, 48.23315615099468]],
    [ "KNG-Kärnten Netz GmbH",[13.804150649490703, 46.71131085178154]],
    [ "KNG-Kärnten Netz GmbH",[13.82104605482483, 46.71844186778896]],
    [ "499008312",[13.8078778520536, 47.96620549847863]],
    [ "Energie AG",[13.756501049999997, 48.52769045000001]],
    [ "Energie AG",[13.778007498617475, 48.51892470915939]],
    [ "Energie AG",[13.766031167393994, 48.490687409094086]],
    [ "499220698",[13.84918390345738, 47.97554139253813]],
    [ "Energie AG",[13.891476804843496, 48.49748515270982]],
    [ "Energie AG",[14.043940708693134, 48.515177006297236]],
    [ "Energie AG",[14.00116129635844, 48.43992625034853]],
    [ "Energie AG",[14.00133003967483, 48.428234802047434]],
    [ "Energie AG",[13.954606313096287, 48.25480333750754]],
    [ "Energie AG",[14.116609132831456, 48.273792039541284]],
    [ "Raab Hirschdobl",[13.64488010305705, 48.3482578766194]],
    [ "Energie AG",[13.710652711194495, 48.27096805782363]],
    [ "Energie AG",[13.57670289214659, 48.184570412958784]],
    [ "KNG-Kärnten Netz GmbH",[13.789481454128937, 46.726289317490085]],
    [ "KNG-Kärnten Netz GmbH",[13.668256191756168, 46.751076571088845]],
    [ "Linz AG",[14.732617300000003, 48.2449549]],
    [ "Übergabestation Mollnegg",[14.721426399999999, 48.25601705]],
    [ "E-Werk Perg",[14.633030438988497, 48.243323800405555]],
    [ "E-Werk Perg",[14.635785324692145, 48.2436838481953]],
    [ "501399155",[14.583197780776143, 48.2606590893781]],
    [ "501399157",[14.579600549929298, 48.259965742825095]],
    [ "Energie AG",[13.346986599704492, 47.85093000642338]],
    [ "501980352",[13.817907550000001, 47.97658139999999]],
    [ "KNG-Kärnten Netz GmbH",[14.331392638738459, 46.55681019249142]],
    [ "Energie AG",[14.437314195736947, 47.95209884943968]],
    [ "Mettmach",[13.345812543194882, 48.16931582216658]],
    [ "KNG-Kärnten Netz GmbH",[13.766100962400904, 46.75306990002435]],
    [ "502876745",[14.176145560173751, 47.84643613029952]],
    [ "KNG-Kärnten Netz GmbH",[13.744505597509637, 46.780223013154796]],
    [ "EVN",[15.265162522072874, 48.217166676962144]],
    [ "KNG-Kärnten Netz GmbH",[13.762535130892324, 46.7589098031776]],
    [ "KNG-Kärnten Netz GmbH",[13.759879103412686, 46.76166513415893]],
    [ "Energie AG",[14.233660247718216, 47.89806469819149]],
    [ "Ebersau",[13.463363884036893, 48.130454655622685]],
    [ "St. Andrä Heimat",[13.884862033314047, 46.636820600029395]],
    [ "Energie AG",[13.60611440380817, 48.156333901648104]],
    [ "503578057",[16.241970078195198, 47.642480717257314]],
    [ "Netz Niederösterreich GmbH",[16.211050097328894, 47.64264698103734]],
    [ "Wiener Netze",[16.29921545, 48.0764448]],
    [ "KNG-Kärnten Netz GmbH",[14.459195018149108, 46.537040150168984]],
    [ "KNG-Kärnten Netz GmbH",[13.92392459929602, 46.690130366316964]],
    [ "Wiener Netze",[16.310412301467842, 48.072550667333864]],
    [ "SST Kalladorf",[16.08341067105962, 48.646896546306884]],
    [ "505516404",[14.844604433622616, 48.228043548169715]],
    [ "Linz AG",[14.833608099505566, 48.23005112398947]],
    [ "505821421",[13.254210036909363, 47.84278111892669]],
    [ "KNG-Kärnten Netz GmbH",[14.82316344126464, 46.93845729952431]],
    [ "KNG-Kärnten Netz GmbH",[13.82309105763028, 46.72968371764476]],
    [ "KNG-Kärnten Netz GmbH",[13.931939857018317, 46.7319889985348]],
    [ "KNG-Kärnten Netz GmbH",[13.861699788243985, 46.8294468793762]],
    [ "Kruckenberg Trahütten",[15.153263027355797, 46.821773815144546]],
    [ "Salzburg AG",[13.258849976926658, 47.83933770347073]],
    [ "Energie AG",[14.185267288005504, 48.32040537164551]],
    [ "Energie AG",[14.180605411364692, 48.327323691884715]],
    [ "Energie AG",[14.174048410505407, 48.32337239804764]],
    [ "Energie AG",[14.16468271680301, 48.255847599043264]],
    [ "Energie AG",[14.1751141, 48.244584149999994]],
    [ "Linz AG",[14.1989346, 48.2735418]],
    [ "Linz AG",[14.2030633, 48.28065530000001]],
    [ "KNG-Kärnten Netz GmbH",[13.914308301339755, 46.796531982371384]],
    [ "KNG-Kärnten Netz GmbH",[14.239116894672897, 46.564347711231534]],
    [ "TST Edlitz Grub",[16.179839326103455, 47.58149122313742]],
    [ "TST Thomasberg Olbersdorf Industriegebiet",[16.117412350000002, 47.5987944]],
    [ "Netz Niederösterreich GmbH",[16.129230119079143, 47.635960742949955]],
    [ "Energie AG",[14.005620257387557, 48.359337070348985]],
    [ "KNG-Kärnten Netz GmbH",[13.672241447542262, 46.73232569888678]],
    [ "KNG-Kärnten Netz GmbH",[13.965756399221315, 46.77594900020817]],
    [ "510825509",[14.34417554670166, 47.71486008174576]],
    [ "KNG-Kärnten Netz GmbH",[13.663641767567698, 46.69809236428875]],
    [ "Wiener Netze",[16.4602349, 48.0182661]],
    [ "Lake Side Park 2.0",[14.267493963554932, 46.61418469498576]],
    [ "KNG-Kärnten Netz GmbH",[13.65953244994509, 46.68953594941768]],
    [ "TST Dunkelstein Ternitz Tulpengasse",[16.03588945082014, 47.70739073331913]],
    [ "KNG-Kärnten Netz GmbH",[13.635521687316553, 46.712571525304064]],
    [ "513204495",[14.457252231232289, 48.17862089907317]],
    [ "Wiener Netze",[16.38399018334076, 47.86522205016637]],
    [ "KNG-Kärnten Netz GmbH",[13.706077010693841, 46.68518193865949]],
    [ "KNG-Kärnten Netz GmbH",[14.674008631361055, 46.69498370741306]],
    [ "TST Wimpassing Kreuzgasse",[16.032308183419747, 47.704390546111384]],
    [ "KNG-Kärnten Netz GmbH",[13.714658255570749, 46.708039899330174]],
    [ "KNG-Kärnten Netz GmbH",[13.706040907498307, 46.71867059325682]],
    [ "EVN",[16.790512678045047, 48.18964300377909]],
    [ "KNG-Kärnten Netz GmbH",[13.651947853958996, 46.72745605753483]],
    [ "Energie AG",[13.824575696894138, 48.23831070335352]],
    [ "TST Tattendorf Siedlungsstraße",[16.303922316591958, 47.95132455044415]],
    [ "Guggenberg",[13.327469397300515, 47.87591809099236]],
    [ "KNG-Kärnten Netz GmbH",[13.756823302626474, 46.65852791382128]],
    [ "Salzburgerstr.",[12.073582443525758, 47.48866418560139]],
    [ "KNG-Kärnten Netz GmbH",[13.640171383835833, 46.734064312456454]],
    [ "Sollern Wasserwerk",[13.13283725386538, 48.09693650182527]],
    [ "Energie AG",[14.145645705190551, 47.97883840380129]],
    [ "KNG-Kärnten Netz GmbH",[13.885079693570143, 46.61492357800537]],
    [ "KNG-Kärnten Netz GmbH",[13.585403821315818, 46.72653196615837]],
    [ "KNG-Kärnten Netz GmbH",[13.58774266451962, 46.73696255143703]],
    [ "516020599",[14.215761582578134, 48.551095815760526]],
    [ "Pfaffstätt",[13.145654048359493, 48.079972808670696]],
    [ "KNG-Kärnten Netz GmbH",[13.605781012302515, 46.74749610066466]],
    [ "KNG-Kärnten Netz GmbH",[13.598461755792288, 46.74978675652331]],
    [ "TST Tattendorf Rechte Bahnzeile",[16.301103679936624, 47.95190990182461]],
    [ "Nussbach Stretz",[14.170538839624413, 47.953881680074744]],
    [ "Millstatt Süd",[13.570409161371142, 46.802409369200205]],
    [ "517852109",[14.817470612038102, 48.2568845694138]],
    [ "KNG-Kärnten Netz GmbH",[13.559864736984359, 46.818569625303766]],
    [ "518509128",[14.619572025595282, 48.22729069439953]],
    [ "Lungdorf",[13.506399373264298, 48.1269517510516]],
    [ "Wiener Netze",[16.3652176, 48.3067884]],
    [ "518963689",[13.938173084213346, 48.12887103300434]],
    [ "KNG-Kärnten Netz GmbH",[14.365125764995348, 46.81130352438975]],
    [ "Energie AG",[13.52871926948393, 48.1502070552462]],
    [ "520937347",[13.874890649999998, 48.08829825]],
    [ "TST Wimpassing Neubaugasse",[16.01983288080212, 47.694787587891554]],
    [ "TST Pottschach 2",[16.012466196668207, 47.69620559625408]],
    [ "KNG-Kärnten Netz GmbH",[14.459909496256632, 46.6740450411894]],
    [ "Wernberg Schloss",[13.93300980405617, 46.6195904205617]],
    [ "521555537",[13.188095843354406, 47.82365977091848]],
    [ "Salzburg Netz",[13.123166999999999, 47.856042650000006]],
    [ "Wiener Netze",[16.331496571839427, 48.12471019547629]],
    [ "Energie AG",[14.245184813777284, 47.9798320431441]],
    [ "Energie AG",[14.240990421834834, 47.96255217821269]],
    [ "Wiener Netze",[16.584515915509247, 48.07394282301174]],
    [ "Energie Ried",[13.503092020126942, 48.16997504815776]],
    [ "523987784",[14.948789949999998, 48.279220800000004]],
    [ "KNG-Kärnten Netz GmbH",[14.538869345570125, 46.72109790245561]],
    [ "Kärnten Netz",[14.058222717591027, 46.6911402537005]],
    [ "Salzburg Netz",[12.968687700000002, 47.79611385]],
    [ "Salzburg Netz",[13.127336057218844, 47.88788286662231]],
    [ "TST Göttschach",[16.002923773621138, 47.67423333563419]],
    [ "Salzburg Netz",[13.120619450575063, 47.88566168938707]],
    [ "Energie AG",[13.451297967240967, 48.157774801921285]],
    [ "Salzburg Netz",[12.984893900000001, 47.81666545]],
    [ "Salzburg Netz",[13.129187672801894, 47.89125300230348]],
    [ "Wiener Netze",[16.459443403562968, 48.33565357069863]],
    [ "527195272",[16.191097753768783, 47.01003913274764]],
    [ "Linz AG",[14.18220203637101, 48.29000689925657]],
    [ "Energie AG",[13.54059757715091, 47.93670527041873]],
    [ "Energie AG",[14.118399550000001, 48.290285649999994]],
    [ "528328490",[14.466602133990378, 48.133088914115014]],
    [ "Wiener Netze",[16.2152192, 47.926120049999994]],
    [ "528526196",[14.130891960975367, 48.201050787479076]],
    [ "528649331",[14.744217710100367, 48.341473217430526]],
    [ "KNG-Kärnten Netz GmbH",[13.973916124946244, 46.55531959401489]],
    [ "KNG-Kärnten Netz GmbH",[13.94391921067392, 46.55803764554466]],
    [ "KNG-Kärnten Netz GmbH",[14.210663206717, 46.52707291157137]],
    [ "Linz AG",[14.579330499999998, 48.27726565000001]],
    [ "Roitham b. Seewalchen",[13.587301571131716, 47.9596335435401]],
    [ "529201237",[14.164511532456421, 48.20350896335015]],
    [ "Sollern",[13.135674428133713, 48.08484905639908]],
    [ "529673080",[14.526347500000002, 48.24072680000001]],
    [ "Mattighofen Moosstraße",[13.136862187138684, 48.101343971901265]],
    [ "Diersbach",[13.56501878832519, 48.412771941979045]],
    [ "Mattighofen Bad",[13.149373218404483, 48.091429176351724]],
    [ "Kärnten Netz",[12.993735600000003, 46.66889449999999]],
    [ "Pumpwerk Selkach",[14.097816584341253, 46.54039872270988]],
    [ "Netz Niederösterreich",[16.19072496735927, 47.781192600929884]],
    [ "533099390",[15.978956652059827, 46.78204626515408]],
    [ "Energie AG",[13.858552043251196, 48.22151147989831]],
    [ "Wiener Netze",[16.29957075, 48.1136128]],
    [ "534217602",[14.492176774798926, 48.23345963945751]],
    [ "KNG-Kärnten Netz GmbH",[14.176760449019408, 46.5249246198278]],
    [ "Wiener Netze",[16.45735763124503, 48.07176235444144]],
    [ "Elektrozentrale West",[14.664067646261062, 47.17348428021855]],
    [ "Höschmühl",[13.412772941932976, 48.12200583458788]],
    [ "Energie AG",[13.44190646608829, 48.14854024031927]],
    [ "536619513",[13.198607972734058, 47.24361164489329]],
    [ "Engertsberg",[13.662961303420849, 48.416678432108895]],
    [ "Wiener Netze",[16.380614921980637, 48.08223650299763]],
    [ "KNG-Kärnten Netz GmbH",[14.205097976391501, 46.5302223946985]],
    [ "540510494",[14.191851581245848, 48.224429249869836]],
    [ "540622988",[15.943914605582854, 46.77515709674962]],
    [ "Netz Niederösterreich",[16.08859445, 47.55889115]],
    [ "Netz Oberösterreich",[13.514190582616084, 47.71553823503166]],
    [ "Wiener Netze",[16.288550263429453, 47.95094717113715]],
    [ "KNG-Kärnten Netz GmbH",[14.445369997927775, 46.53949230048581]],
    [ "KNG-Kärnten Netz GmbH",[14.45337854251224, 46.53679980986318]],
    [ "542059534",[15.905090963745003, 46.73509852011385]],
    [ "Obernberg a. I. Hauptschule",[13.337146365361496, 48.32364334673527]],
    [ "KNG-Kärnten Netz GmbH",[14.826820702459807, 46.87427410495836]],
    [ "Energie AG",[14.036838399577915, 48.57565480250316]],
    [ "Wiener Netze",[16.167842099999998, 48.15592695000001]],
    [ "KNG-Kärnten Netz GmbH",[14.314000555253006, 46.52429872016581]],
    [ "TST Sollenau Heilsamer Brunnen 2",[16.23790301759951, 47.90270564885455]],
    [ "Energie AG",[13.576090254637096, 48.54384700088253]],
    [ "Mattighofen Galvanowerk",[13.146886920152996, 48.10550632288463]],
    [ "Salzburg Netz",[13.1376589, 47.8578138]],
    [ "Wiener Netze GmbH",[16.2258741, 47.88857405000001]],
    [ "Wiener Netze",[16.22884188259112, 47.89246680071437]],
    [ "Wiener Netze",[16.292749399999995, 48.1082949]],
    [ "Wiener Netze",[16.28851725277556, 48.03213781720134]],
    [ "KNG-Kärnten Netz GmbH",[14.258963819439904, 46.53821141990589]],
    [ "Energie AG",[13.832322399999999, 48.22940545000001]],
    [ "Wiener Netze",[16.315447300000002, 48.07235970000001]],
    [ "Wiener Netze",[16.315749342592017, 48.07862527820171]],
    [ "Wiener Netze",[16.32692965, 48.07091425]],
    [ "Energie AG",[13.142777775606456, 48.09540021102864]],
    [ "TST Blumau Bahnhofstraße",[16.292725755418026, 47.91522919662979]],
    [ "KNG-Kärnten Netz GmbH",[14.793837506304818, 46.58969514757796]],
    [ "Linz AG",[14.30773568594207, 48.429334943030916]],
    [ "Netz NÖ",[16.13598416749853, 47.942406048007555]],
    [ "Mattighofen Schwarzgraben",[13.146729775927165, 48.093078505275365]],
    [ "Emprechting",[13.52400618023427, 48.210856675761974]],
    [ "Mattighofen Glechnermühle",[13.148742009754063, 48.09813622090298]],
    [ "Mattighofen Lohberger",[13.147671098637524, 48.10785639775967]],
    [ "KNG-Kärnten Netz GmbH",[14.3991924638473, 46.95959180453441]],
    [ "Mattighofen Voglstr.",[13.143481950187558, 48.100163658782385]],
    [ "Umspannwerk Ottakring",[16.32147496450251, 48.21505163146116]],
    [ "Mattighofen Brauereistr.",[13.14683448293852, 48.104569391127534]],
    [ "Mattighofen Ahamerweg",[13.152911277815912, 48.100323975518585]],
    [ "Ranshofen Schloßstr.",[13.028244407198894, 48.23395579343597]],
    [ "Wiener Netze",[16.27835935834834, 47.94931148291053]],
    [ "Wiener Netze",[16.28068294350129, 47.95088293166205]],
    [ "Mattighofen Gärtnergasse",[13.138279890126917, 48.10626777492523]],
    [ "Mattighofen Lederergasse",[13.14232968469676, 48.103386109786726]],
    [ "Energie AG",[13.439602366716226, 47.845550541045476]],
    [ "Oberlindach",[13.131372303786332, 48.107532600541894]],
    [ "Mattighofen Berghammersdlg.",[13.145564534990243, 48.11033291697191]],
    [ "Mattighofen Kühbachweg",[13.146371107314666, 48.10594784680276]],
    [ "Energie AG",[13.144133880324294, 48.11321446921063]],
    [ "St. Peter am Hart",[13.094914987968563, 48.25586767066214]],
    [ "Aselkam WK",[13.073830897831305, 48.246876905963916]],
    [ "Mattighofen Bauhof",[13.155566709873069, 48.09769730541645]],
    [ "Mattighofen Bauernberg",[13.151842000000006, 48.104700599999994]],
    [ "Riedersbach",[12.841881601652451, 48.02966130024725]],
    [ "KNG-Kärnten Netz GmbH",[14.793475876657883, 46.916987601972096]],
    [ "KNG-Kärnten Netz GmbH",[14.820822617174297, 46.927311101286755]],
    [ "Energie AG",[12.779842046488634, 48.07363460367657]],
    [ "KNG-Kärnten Netz GmbH",[14.931593380373311, 46.91513714911715]],
    [ "Umspannwerk Heiligenstadt",[16.362449346603867, 48.24410768904308]],
    [ "Netz NÖ",[16.089839984227865, 47.964178238157736]],
    [ "KNG-Kärnten Netz GmbH",[14.728774083928599, 46.85959822665054]],
    [ "KNG-Kärnten Netz GmbH",[13.47252498656241, 46.6276627244438]],
    [ "KNG-Kärnten Netz GmbH",[13.406638889578652, 46.62962050984472]],
    [ "Netz Niederösterreich GmbH",[14.751509240591108, 48.68304250292856]],
    [ "Mattighofen Wasseracker",[13.152441592082752, 48.103207283914436]],
    [ "Mattighofen Gartenstr.",[13.151111825691558, 48.106843002799316]],
    [ "KNG-Kärnten Netz GmbH",[14.817172594911264, 46.81052480000355]],
    [ "557299885",[13.816474800000002, 47.993079200000004]],
    [ "Öppling",[13.144943853087627, 48.26615017854261]],
    [ "KNG-Kärnten Netz GmbH",[14.76318953468043, 46.898924154061305]],
    [ "557742425",[13.797725226533675, 47.982735093505255]],
    [ "Linz AG",[14.512162900015898, 48.580709825345885]],
    [ "Linz AG",[14.56889658239492, 48.586060661075116]],
    [ "KNG-Kärnten Netz GmbH",[14.844266351904585, 46.92939005235062]],
    [ "558918833",[14.093664104402581, 48.18819128850978]],
    [ "559225359",[16.659054269787337, 47.83174975548118]],
    [ "Hochburg",[12.874996928209164, 48.13251761545321]],
    [ "KNG-Kärnten Netz GmbH",[14.916361314636843, 46.86263748691985]],
    [ "KNG-Kärnten Netz GmbH",[14.743154036403578, 47.00427313559197]],
    [ "KNG-Kärnten Netz GmbH",[14.784693685952933, 46.72482374069973]],
    [ "KNG-Kärnten Netz GmbH",[14.82196255, 46.766198100000004]],
    [ "Mattighofen Trattmannsbg.",[13.153544794891749, 48.09654636149651]],
    [ "KNG-Kärnten Netz GmbH",[14.842834349576345, 46.78396770069965]],
    [ "KNG-Kärnten Netz GmbH",[14.801991965258154, 46.7801261513028]],
    [ "KNG-Kärnten Netz GmbH",[14.772720411392388, 46.95586766326855]],
    [ "KNG-Kärnten Netz GmbH",[14.804183416997763, 46.71712044405395]],
    [ "TST Breitenau Peischingerstraße",[16.12993597856604, 47.731582946684156]],
    [ "KNG-Kärnten Netz GmbH",[14.90585404382154, 46.76975530118213]],
    [ "KNG-Kärnten Netz GmbH",[14.960630747951495, 46.9620269367091]],
    [ "Pfaffstätt Kirche",[13.148566094436024, 48.07390739644945]],
    [ "Pfaffstätt Gemeinde",[13.149511879741947, 48.07608735240512]],
    [ "Wiener Netze",[16.20299116629689, 47.89405003226073]],
    [ "KNG-Kärnten Netz GmbH",[14.933488860003587, 46.78154295487793]],
    [ "KNG-Kärnten Netz GmbH",[14.915865168546434, 46.768853593364504]],
    [ "KNG-Kärnten Netz GmbH",[14.900058345785569, 46.75614608077923]],
    [ "562271930",[14.19793328061462, 48.20846200272436]],
    [ "KNG-Kärnten Netz GmbH",[14.759626805006413, 46.79103904767531]],
    [ "KNG-Kärnten Netz GmbH",[14.788644608495867, 46.78446170417552]],
    [ "KNG-Kärnten Netz GmbH",[14.865484070126065, 46.730861969795306]],
    [ "KNG-Kärnten Netz GmbH",[14.86348554251835, 46.735602642929294]],
    [ "Netz Niederösterreich GmbH",[15.951386238408645, 48.76666375398658]],
    [ "KNG-Kärnten Netz GmbH",[14.82961355702827, 46.79186428522534]],
    [ "KNG-Kärnten Netz GmbH",[14.788589762219939, 46.74333650482138]],
    [ "KNG-Kärnten Netz GmbH",[14.948723240477412, 46.65423668096437]],
    [ "KNG-Kärnten Netz GmbH",[14.864522445482626, 46.81078754987715]],
    [ "KNG-Kärnten Netz GmbH",[14.98954663782187, 46.62758599303968]],
    [ "KNG-Kärnten Netz GmbH",[14.981931865966791, 46.616024946777074]],
    [ "Netz Niederösterreich GmbH",[14.825343610808225, 48.184482846899485]],
    [ "KNG-Kärnten Netz GmbH",[14.844943761634813, 46.76295130502477]],
    [ "KNG-Kärnten Netz GmbH",[14.851964703815485, 46.80691094176292]],
    [ "Wiener Netze",[14.748186273570528, 47.83755415162058]],
    [ "Wiener Netze",[14.7510008, 47.828562049999995]],
    [ "KNG-Kärnten Netz GmbH",[14.717168467929406, 47.01318693717491]],
    [ "KNG-Kärnten Netz GmbH",[14.792832155698605, 46.81215375048321]],
    [ "567602169",[14.388128397047995, 46.98094706735258]],
    [ "Energie AG",[14.219353250157658, 48.231064683243325]],
    [ "KNG-Kärnten Netz GmbH",[14.872485400850294, 46.770319712468165]],
    [ "Mattighofen Lawog",[13.142784199373594, 48.116098647387986]],
    [ "Energie AG",[13.139315851683365, 48.12236780373857]],
    [ "Umspannwerk Mattighofen",[13.13373470377298, 48.12002503623766]],
    [ "569295200",[16.84312335480118, 48.30540332472295]],
    [ "Schalchen Unterlochnerstr.",[13.145292998195446, 48.11954867103485]],
    [ "KNG-Kärnten Netz GmbH",[14.534161033448314, 46.847258773552575]],
    [ "KNG-Kärnten Netz GmbH",[14.030424807197102, 46.53639045282454]],
    [ "KNG-Kärnten Netz GmbH",[14.328565006421961, 46.76014815012801]],
    [ "KNG-Kärnten Netz GmbH",[14.230112650537777, 46.810054708703085]],
    [ "Wiener Netze",[16.31215884003694, 48.08955417574951]],
    [ "KNG-Kärnten Netz GmbH",[14.266311372150781, 46.48880024782291]],
    [ "Feschnigsiedlung",[14.301226682404275, 46.63975560228508]],
    [ "KNG-Kärnten Netz GmbH",[14.52938133940978, 46.91295137292849]],
    [ "KNG-Kärnten Netz GmbH",[14.290791868775827, 46.8752259572451]],
    [ "KNG-Kärnten Netz GmbH",[14.303012160343336, 46.878366004766946]],
    [ "Linz AG",[14.24495335, 48.28083035]],
    [ "KNG-Kärnten Netz GmbH",[14.424903010106174, 46.91439677715421]],
    [ "KNG-Kärnten Netz GmbH",[14.159545687031933, 46.854639505934834]],
    [ "Kreuzberg",[13.450913982374344, 48.474399024196906]],
    [ "KNG-Kärnten Netz GmbH",[14.605095353063586, 46.59176250006273]],
    [ "KNG-Kärnten Netz GmbH",[14.043203584535082, 46.9378058044232]],
    [ "EVN",[14.634291601045684, 48.56284800975312]],
    [ "KNG-Kärnten Netz GmbH",[14.589593800014606, 46.56011921710365]],
    [ "KNG-Kärnten Netz GmbH",[14.071823805866545, 46.85737151140333]],
    [ "Energie AG",[13.132880303186463, 48.124767541256425]],
    [ "KNG-Kärnten Netz GmbH",[14.32580493309025, 46.96710444974599]],
    [ "KNG-Kärnten Netz GmbH",[14.359567335896827, 46.97287742426745]],
    [ "Linz AG",[14.535080150000002, 48.24542244999999]],
    [ "KNG-Kärnten Netz GmbH",[14.44493325226024, 46.588980748472615]],
    [ "KNG-Kärnten Netz GmbH",[14.024241937135423, 46.95427656496184]],
    [ "Karnerau Genser",[13.529256405830875, 46.92645230084429]],
    [ "Eggersäge",[13.529660049999999, 46.92408104999999]],
    [ "KNG-Kärnten Netz GmbH",[13.529376738385421, 46.92163605252406]],
    [ "Gmünd Waschanger",[13.539457950977662, 46.90594619966786]],
    [ "Gmünd Ruine",[13.536992864575184, 46.90931214046064]],
    [ "Malta Fischertratten Grünleiten",[13.527742644028939, 46.93110161056172]],
    [ "Gmünd Riesertratte",[13.530642013067423, 46.90998671995513]],
    [ "UW Tobadill",[10.498568696353393, 47.11895302417579]],
    [ "KNG-Kärnten Netz GmbH",[14.328085008916622, 46.89279755404874]],
    [ "KNG-Kärnten Netz GmbH",[14.819457177824393, 46.758582821128556]],
    [ "KNG-Kärnten Netz GmbH",[14.833866394917735, 46.765354148348386]],
    [ "583308089",[13.106836883028299, 47.16639450427048]],
    [ "KNG-Kärnten Netz GmbH",[14.537842300454425, 46.83489936113997]],
    [ "Wiener Netze",[16.511986999999998, 47.98850255]],
    [ "KNG-Kärnten Netz GmbH",[14.289958002538944, 46.87311272282939]],
    [ "KNG-Kärnten Netz GmbH",[14.230863040953187, 46.863684528279514]],
    [ "Umspannwerk Pottenstein",[16.097914477257955, 47.9537226807117]],
    [ "Wiener Netze",[16.39170565, 48.106688000000005]],
    [ "Seewalchen Agerbrücke",[13.594545779030517, 47.95067314323278]],
    [ "586183123",[14.01781775, 48.15985835]],
    [ "Netz Niederösterreich",[16.20487088885988, 47.8062094786292]],
    [ "TST Würflach Willendorferstraße",[16.057398699999993, 47.7811308]],
    [ "TST Würflach Ost",[16.057162407528484, 47.78080191807826]],
    [ "TST Hettmannsdorf Hauptstraße",[16.052848430758846, 47.768202254286244]],
    [ "TST Urschendorf West",[16.088496325108405, 47.78451179509648]],
    [ "TST Urschendorf Ost",[16.101202322673252, 47.78196619851751]],
    [ "Kärnten Netz",[14.506916576260824, 46.67917116682909]],
    [ "KNG-Kärnten Netz GmbH",[14.5286260179802, 46.67073663314134]],
    [ "KNG-Kärnten Netz GmbH",[14.545367100000004, 46.65785145]],
    [ "KNG-Kärnten Netz GmbH",[14.536163740964794, 46.76300007275906]],
    [ "Wiener Netze",[16.308529524396377, 48.109130743677476]],
    [ "KNG-Kärnten Netz GmbH",[14.54202224037601, 46.86147890009143]],
    [ "Umspannwerk Feldkirchen",[14.079683344131277, 48.34964095064613]],
    [ "Netz NÖ",[16.00596261791971, 47.99185296654349]],
    [ "TST Pottschach Lissenwiese",[16.015543878926767, 47.69916628469814]],
    [ "Wiener Netze",[16.1123881, 48.130430950000004]],
    [ "KNG-Kärnten Netz GmbH",[14.619553350000002, 46.828843600000006]],
    [ "KNG-Kärnten Netz GmbH",[14.228737693660793, 46.866545915698126]],
    [ "KNG-Kärnten Netz GmbH",[14.401247282689914, 46.953368154214104]],
    [ "KNG-Kärnten Netz GmbH",[13.39951256510021, 46.838312124516634]],
    [ "Linz AG",[14.482366103188829, 48.27600214545338]],
    [ "KNG-Kärnten Netz GmbH",[13.841201384294875, 46.597763216779235]],
    [ "KNG-Kärnten Netz GmbH",[13.991776041872997, 46.857735958905934]],
    [ "Replach",[14.430534138591566, 46.63576357649794]],
    [ "Trst Möderndorf- Messner",[14.333803132434625, 46.70066919054534]],
    [ "Meiselding/Papst",[14.398136144592764, 46.839217490442564]],
    [ "KNG-Kärnten Netz GmbH",[13.443854944599876, 46.83213788518025]],
    [ "KNG-Kärnten Netz GmbH",[13.456263696668307, 46.83178723266749]],
    [ "Lieserbrücke",[13.496839364395981, 46.81764167962934]],
    [ "KNG-Kärnten Netz GmbH",[13.458840954271903, 46.800755609149554]],
    [ "KNG-Kärnten Netz GmbH",[13.454703614324742, 46.80164207174935]],
    [ "KNG-Kärnten Netz GmbH",[13.392465254065733, 46.82463119896545]],
    [ "Bleiburg Graben",[14.799704575333793, 46.59086414491165]],
    [ "KNG-Kärnten Netz GmbH",[13.632129982065866, 46.7781136766401]],
    [ "KNG-Kärnten Netz GmbH",[13.602337099896909, 46.802982289608806]],
    [ "KNG-Kärnten Netz GmbH",[13.592777597042666, 46.80908208207189]],
    [ "KNG-Kärnten Netz GmbH",[13.571381461519548, 46.812286949670565]],
    [ "KNG-Kärnten Netz GmbH",[13.554590452334661, 46.82141991152404]],
    [ "Energie Steiermark",[15.386777284572993, 47.04084440918686]],
    [ "KNG-Kärnten Netz GmbH",[13.53658881477871, 46.76536489755085]],
    [ "KNG-Kärnten Netz GmbH",[13.545413543345544, 46.77493101578645]],
    [ "KNG-Kärnten Netz GmbH",[13.498119131972175, 46.77909900915049]],
    [ "KNG-Kärnten Netz GmbH",[13.498883937777718, 46.78424942231465]],
    [ "KNG-Kärnten Netz GmbH",[13.52348505468254, 46.59722090750803]],
    [ "KNG-Kärnten Netz GmbH",[13.110471793796458, 46.65690400244193]],
    [ "KNG-Kärnten Netz GmbH",[13.57787327840989, 46.76884178443602]],
    [ "KNG-Kärnten Netz GmbH",[13.571771733812684, 46.76438735588409]],
    [ "KNG-Kärnten Netz GmbH",[13.635701597271629, 46.74628596179719]],
    [ "KNG-Kärnten Netz GmbH",[13.658045102349508, 46.761935788008444]],
    [ "KNG-Kärnten Netz GmbH",[13.319278946880615, 46.877578295856786]],
    [ "KNG-Kärnten Netz GmbH",[13.119524110688959, 46.64059356186825]],
    [ "Wiener Netze",[16.330253900000002, 47.857087]],
    [ "KNG-Kärnten Netz GmbH",[13.07794069889274, 46.659782509016026]],
    [ "KNG-Kärnten Netz GmbH",[13.121884904307443, 46.64439973449022]],
    [ "KW Zirknitz",[12.956974323694244, 46.99539575539007]],
    [ "593004743",[14.945124857330073, 48.234912000144064]],
    [ "ÖBB-Infrastruktur AG",[13.188383588192826, 46.966070878954355]],
    [ "Kraftwerk Koralpe",[14.955419671352155, 46.63389133026938]],
    [ "KNG-Kärnten Netz GmbH",[14.786779646948482, 46.558880782401154]],
    [ "Umspannwerk Gaudenzdorf",[16.33926794446962, 48.18670809875945]],
    [ "KNG-Kärnten Netz GmbH",[14.484835048670798, 46.77290996872726]],
    [ "KNG-Kärnten Netz GmbH",[14.795523033032472, 46.58291757310991]],
    [ "KNG-Kärnten Netz GmbH",[14.7952915, 46.601870000000005]],
    [ "KNG-Kärnten Netz GmbH",[14.569799387043501, 46.5404694939965]],
    [ "KNG-Kärnten Netz GmbH",[14.093777351994095, 46.71665370226489]],
    [ "AAE",[13.0038965, 46.6720743]],
    [ "KNG-Kärnten Netz GmbH",[13.085902074776051, 46.66340364274762]],
    [ "KNG-Kärnten Netz GmbH",[14.590114345519739, 46.703999174851305]],
    [ "Umspannwerk Radenthein",[13.688142032626743, 46.79893492305126]],
    [ "Energie Steiermark",[15.498538917558449, 47.00792192382144]],
    [ "KNG-Kärnten Netz GmbH",[13.682291131006442, 46.62611351165967]],
    [ "KNG-Kärnten Netz GmbH",[14.739229328684997, 47.01295830177738]],
    [ "KNG-Kärnten Netz GmbH",[14.750561851014277, 47.00406018623697]],
    [ "KNG-Kärnten Netz GmbH",[13.845584388922564, 46.55697627648184]],
    [ "Linz Netz",[14.481525749935312, 48.500809924682855]],
    [ "KNG-Kärnten Netz GmbH",[13.992146038859998, 46.620003002485205]],
    [ "KNG-Kärnten Netz GmbH",[13.99894865, 46.602199600000006]],
    [ "Schiene Oberösterreich",[14.23426061875624, 48.23331066020392]],
    [ "KNG-Kärnten Netz GmbH",[14.544391478677111, 46.7832328982263]],
    [ "KNG-Kärnten Netz GmbH",[14.5621164525174, 46.80827129888132]],
    [ "KNG-Kärnten Netz GmbH",[14.54074105, 46.8361084]],
    [ "Villach Süd",[13.828019208467923, 46.553775740372444]],
    [ "603300207",[14.200601857208941, 48.2572382779883]],
    [ "603360441",[14.20442775, 48.254344800000005]],
    [ "KNG-Kärnten Netz GmbH",[14.647948956417872, 46.731558498096554]],
    [ "KNG-Kärnten Netz GmbH",[13.706879561022733, 46.55597490657513]],
    [ "KNG-Kärnten Netz GmbH",[13.689375060006267, 46.693769002979884]],
    [ "KNG-Kärnten Netz GmbH",[13.680083242588658, 46.69781790606398]],
    [ "KNG-Kärnten Netz GmbH",[14.736135089042941, 46.82762470334124]],
    [ "KNG-Kärnten Netz GmbH",[13.324220404650159, 46.70601522706157]],
    [ "KNG-Kärnten Netz GmbH",[13.53503393558325, 46.6248560379602]],
    [ "KNG-Kärnten Netz GmbH",[13.597697276773886, 46.60371160163508]],
    [ "KNG-Kärnten Netz GmbH",[13.605006755239748, 46.59789364261321]],
    [ "KNG-Kärnten Netz GmbH",[13.601651650570735, 46.57322735087078]],
    [ "KNG-Kärnten Netz GmbH",[13.667333464251804, 46.739934605353305]],
    [ "KNG-Kärnten Netz GmbH",[13.753706167966282, 46.618065824025095]],
    [ "KNG-Kärnten Netz GmbH",[13.68774439102407, 46.625119100325634]],
    [ "KNG-Kärnten Netz GmbH",[13.68003348036859, 46.62604160184296]],
    [ "KNG-Kärnten Netz GmbH",[13.826846549231304, 46.56067566962567]],
    [ "KNG-Kärnten Netz GmbH",[13.78024682081113, 46.55484832293242]],
    [ "KNG-Kärnten Netz GmbH",[13.738040154775401, 46.54729777471443]],
    [ "KNG-Kärnten Netz GmbH",[14.04063701630571, 46.85875313818979]],
    [ "KNG-Kärnten Netz GmbH",[13.918636503097966, 46.63806948970026]],
    [ "KNG-Kärnten Netz GmbH",[14.0414831608526, 46.553661160669414]],
    [ "KNG-Kärnten Netz GmbH",[14.031283206131391, 46.56404699582167]],
    [ "KNG-Kärnten Netz GmbH",[14.063681696017287, 46.59919249928972]],
    [ "KNG-Kärnten Netz GmbH",[14.06970592976728, 46.57032229335019]],
    [ "KNG-Kärnten Netz GmbH",[14.064100372391561, 46.57498793363956]],
    [ "KNG-Kärnten Netz GmbH",[14.474041606127926, 46.859728972134306]],
    [ "KNG-Kärnten Netz GmbH",[14.149784188758112, 46.577994804804106]],
    [ "KNG-Kärnten Netz GmbH",[14.167743678483765, 46.593616256446396]],
    [ "KNG-Kärnten Netz GmbH",[14.09237348965187, 46.635958294017655]],
    [ "KNG-Kärnten Netz GmbH",[14.170976497373074, 46.73662905976628]],
    [ "KNG-Kärnten Netz GmbH",[13.664558955018986, 46.62670089940826]],
    [ "KNG-Kärnten Netz GmbH",[13.671477222616755, 46.625429927797384]],
    [ "KNG-Kärnten Netz GmbH",[13.628653058990245, 46.62903162987479]],
    [ "KNG-Kärnten Netz GmbH",[13.635765716625123, 46.628179991866844]],
    [ "KNG-Kärnten Netz GmbH",[13.69910391433193, 46.62381315676706]],
    [ "KNG-Kärnten Netz GmbH",[13.089320204433282, 46.64500149869783]],
    [ "605160441",[13.949262476300783, 48.229817541686685]],
    [ "KNG-Kärnten Netz GmbH",[14.58561524019276, 46.490022522857224]],
    [ "KNG-Kärnten Netz GmbH",[14.32828045, 46.86106859999999]],
    [ "KNG-Kärnten Netz GmbH",[14.396801236310035, 46.85057865416242]],
    [ "KNG-Kärnten Netz GmbH",[14.465467982938613, 46.83524128678418]],
    [ "KNG-Kärnten Netz GmbH",[14.483160473346246, 46.56865732636008]],
    [ "KNG-Kärnten Netz GmbH",[14.277911569231124, 46.755854576068]],
    [ "KNG-Kärnten Netz GmbH",[14.292171833682337, 46.76360237629733]],
    [ "KNG-Kärnten Netz GmbH",[14.35118370465651, 46.78172929519565]],
    [ "KNG-Kärnten Netz GmbH",[14.218406085609677, 46.70498185690037]],
    [ "KNG-Kärnten Netz GmbH",[14.212609660032102, 46.66602810172343]],
    [ "KNG-Kärnten Netz GmbH",[14.189074684903753, 46.66029184517776]],
    [ "KNG-Kärnten Netz GmbH",[13.947581414473097, 46.671560320786746]],
    [ "KNG-Kärnten Netz GmbH",[14.381180285927233, 46.7435535559324]],
    [ "KNG-Kärnten Netz GmbH",[14.379313652595714, 46.74986394814494]],
    [ "KNG-Kärnten Netz GmbH",[14.41190064325801, 46.756146076753005]],
    [ "KNG-Kärnten Netz GmbH",[14.292102490125774, 46.71133195481376]],
    [ "KNG-Kärnten Netz GmbH",[14.342569944018221, 46.71285390100606]],
    [ "KNG-Kärnten Netz GmbH",[14.327023668384731, 46.689810752838866]],
    [ "KNG-Kärnten Netz GmbH",[14.79474725, 46.599594800000006]],
    [ "KNG-Kärnten Netz GmbH",[14.146795572011051, 46.66133836894692]],
    [ "KNG-Kärnten Netz GmbH",[14.450129384469502, 46.66788289413384]],
    [ "KNG-Kärnten Netz GmbH",[14.475430279781868, 46.65433721396002]],
    [ "KNG-Kärnten Netz GmbH",[14.312468451203804, 46.5605819404467]],
    [ "KNG-Kärnten Netz GmbH",[14.533343087967793, 46.75319623301818]],
    [ "KNG-Kärnten Netz GmbH",[14.470619966441072, 46.77065163533328]],
    [ "KNG-Kärnten Netz GmbH",[13.656344120248365, 46.70315582442053]],
    [ "KNG-Kärnten Netz GmbH",[13.6351017367135, 46.70922700775321]],
    [ "KNG-Kärnten Netz GmbH",[13.57763072614496, 46.72855694523112]],
    [ "KNG-Kärnten Netz GmbH",[13.55358971090897, 46.738514347244354]],
    [ "KNG-Kärnten Netz GmbH",[13.531073450000001, 46.72339425]],
    [ "KNG-Kärnten Netz GmbH",[13.495658181909848, 46.723173538133814]],
    [ "KNG-Kärnten Netz GmbH",[13.49522594448098, 46.62855507468404]],
    [ "KNG-Kärnten Netz GmbH",[13.51972477840498, 46.624315401485376]],
    [ "KNG-Kärnten Netz GmbH",[13.464594874020769, 46.60792251949352]],
    [ "KNG-Kärnten Netz GmbH",[13.475875688071024, 46.617504779961855]],
    [ "KNG-Kärnten Netz GmbH",[13.475012013263276, 46.623932082601634]],
    [ "KNG-Kärnten Netz GmbH",[13.56388746802722, 46.61485313648876]],
    [ "KNG-Kärnten Netz GmbH",[13.54111144347776, 46.61857804410875]],
    [ "KNG-Kärnten Netz GmbH",[13.604439944403632, 46.57919580011104]],
    [ "KNG-Kärnten Netz GmbH",[13.695700236032636, 46.548582927849914]],
    [ "KNG-Kärnten Netz GmbH",[13.665063832716028, 46.54555378942642]],
    [ "KNG-Kärnten Netz GmbH",[13.358770694043711, 46.6218808495014]],
    [ "KNG-Kärnten Netz GmbH",[13.347458567495714, 46.615518483925975]],
    [ "KNG-Kärnten Netz GmbH",[13.338969289459468, 46.61849853048345]],
    [ "KNG-Kärnten Netz GmbH",[13.25441180475129, 46.62284643694714]],
    [ "KNG-Kärnten Netz GmbH",[13.308967802219975, 46.61940239963955]],
    [ "606130317",[13.639425597851579, 46.675567698447594]],
    [ "606130319",[13.663549836656466, 46.68500967044833]],
    [ "KNG-Kärnten Netz GmbH",[13.849546302604955, 46.636119936438895]],
    [ "KNG-Kärnten Netz GmbH",[14.2252239, 46.6748854]],
    [ "KNG-Kärnten Netz GmbH",[14.22613403894005, 46.66949444700429]],
    [ "KNG-Kärnten Netz GmbH",[14.2151225, 46.71695785]],
    [ "KNG-Kärnten Netz GmbH",[14.233156349266165, 46.70994660009044]],
    [ "Energie AG",[14.119394907888234, 47.88278748368931]],
    [ "Energie AG",[14.123400756426356, 47.88260893218762]],
    [ "Hauptstraße",[16.54734564606377, 47.909983443113575]],
    [ "KNG-Kärnten Netz GmbH",[13.603997820650388, 46.733392548306675]],
    [ "KNG-Kärnten Netz GmbH",[13.6867902013412, 46.70067915488699]],
    [ "KNG-Kärnten Netz GmbH",[13.721483084026138, 46.6824305942195]],
    [ "KNG-Kärnten Netz GmbH",[13.703426594692392, 46.69540154821416]],
    [ "KNG-Kärnten Netz GmbH",[13.710631590980155, 46.67486119464822]],
    [ "KNG-Kärnten Netz GmbH",[13.740448406334773, 46.67272185748552]],
    [ "KNG-Kärnten Netz GmbH",[13.719843238990073, 46.66975647143754]],
    [ "Netz Niederösterreich GmbH",[16.214801248393094, 47.81117877488595]],
    [ "KNG-Kärnten Netz GmbH",[13.272049883838656, 46.63714921297196]],
    [ "KNG-Kärnten Netz GmbH",[13.444036651068465, 46.620940595063345]],
    [ "KNG-Kärnten Netz GmbH",[13.444907096429032, 46.609010090963366]],
    [ "KNG-Kärnten Netz GmbH",[13.416769475254318, 46.60775342137971]],
    [ "KNG-Kärnten Netz GmbH",[13.40711440772239, 46.61412249645365]],
    [ "KNG-Kärnten Netz GmbH",[13.365876637218033, 46.61170420850757]],
    [ "KNG-Kärnten Netz GmbH",[13.367042096210556, 46.61750971068251]],
    [ "Kärnten Netz",[13.376848303339811, 46.615940399711484]],
    [ "KNG-Kärnten Netz GmbH",[13.392485893562993, 46.61817115375116]],
    [ "Enns Donaustraße 1 Fa. Primagaz",[14.512956420567695, 48.23484052484978]],
    [ "KNG-Kärnten Netz GmbH",[13.314143853183781, 46.66191583248806]],
    [ "KNG-Kärnten Netz GmbH",[13.345343355763273, 46.638620742859864]],
    [ "KNG-Kärnten Netz GmbH",[13.362797758957306, 46.62586766626111]],
    [ "Kärnten Netz",[13.370712089167064, 46.62354977414117]],
    [ "KNG-Kärnten Netz GmbH",[13.347117600035363, 46.63900771066539]],
    [ "KNG-Kärnten Netz GmbH",[13.357380198448421, 46.63281255073986]],
    [ "KNG-Kärnten Netz GmbH",[13.361311543855138, 46.628825933072775]],
    [ "KNG-Kärnten Netz GmbH",[13.254777694045101, 46.68366439957332]],
    [ "KNG-Kärnten Netz GmbH",[13.302684960575354, 46.66793984363163]],
    [ "KNG-Kärnten Netz GmbH",[13.250347619826545, 46.68977572925547]],
    [ "KNG-Kärnten Netz GmbH",[13.25638846499294, 46.68670598711236]],
    [ "KNG-Kärnten Netz GmbH",[13.32801669851882, 46.754876555891165]],
    [ "KNG-Kärnten Netz GmbH",[13.324134761762227, 46.71646325846364]],
    [ "KNG-Kärnten Netz GmbH",[13.294446891984869, 46.71792861680796]],
    [ "KNG-Kärnten Netz GmbH",[13.281913025139648, 46.720448856409185]],
    [ "Kärnten Netz",[13.271195414423202, 46.72369762628147]],
    [ "KNG-Kärnten Netz GmbH",[13.2949986520231, 46.71355436388177]],
    [ "KNG-Kärnten Netz GmbH",[13.23954243455583, 46.725161452624576]],
    [ "Gmünd Gries",[13.545840435912753, 46.908840600355596]],
    [ "KNG-Kärnten Netz GmbH",[13.271220865757218, 46.76402210549319]],
    [ "KNG-Kärnten Netz GmbH",[13.35074808170896, 46.81904054710161]],
    [ "Trst. Lessing",[13.347756809883164, 46.786387322604256]],
    [ "KNG-Kärnten Netz GmbH",[13.333486630623486, 46.76949711898201]],
    [ "Wiener Netze",[16.20398605, 47.97769605]],
    [ "KNG-Kärnten Netz GmbH",[13.572489748975183, 46.596519828774255]],
    [ "KNG-Kärnten Netz GmbH",[13.551499249472428, 46.60685511977472]],
    [ "KNG-Kärnten Netz GmbH",[13.520280253804177, 46.618780664822054]],
    [ "KNG-Kärnten Netz GmbH",[13.522116069966133, 46.60863157838998]],
    [ "KNG-Kärnten Netz GmbH",[13.70947435, 46.553618050000004]],
    [ "KNG-Kärnten Netz GmbH",[13.702680888558007, 46.56111292654572]],
    [ "Treffenboden Siedlung",[13.54136385159701, 46.91607629107003]],
    [ "KNG-Kärnten Netz GmbH",[13.695929691501961, 46.8123576899476]],
    [ "KNG-Kärnten Netz GmbH",[13.69234901694256, 46.827306818445834]],
    [ "KNG-Kärnten Netz GmbH",[13.703305315901023, 46.8250076578526]],
    [ "KNG-Kärnten Netz GmbH",[14.550105443388894, 46.93565567811559]],
    [ "Wiener Netze",[16.34877757681178, 48.088060605144406]],
    [ "KNG-Kärnten Netz GmbH",[12.935382433950755, 46.68267414055447]],
    [ "KNG-Kärnten Netz GmbH",[13.052192081480394, 46.64847454558355]],
    [ "KNG-Kärnten Netz GmbH",[13.001009127308734, 46.66321159087207]],
    [ "KNG-Kärnten Netz GmbH",[13.460986813590262, 46.63192308140635]],
    [ "KNG-Kärnten Netz GmbH",[14.85801653873951, 46.71914894678763]],
    [ "KNG-Kärnten Netz GmbH",[13.268074746194163, 46.90700669774555]],
    [ "Wiener Netze",[16.313513682506922, 48.07699713138473]],
    [ "KNG-Kärnten Netz GmbH",[13.19277068810177, 46.93412196585971]],
    [ "KNG-Kärnten Netz GmbH",[14.593240622239534, 46.91990385278373]],
    [ "KNG-Kärnten Netz GmbH",[13.10618115473808, 46.935834575421936]],
    [ "KNG-Kärnten Netz GmbH",[13.115743851813507, 46.935248548606566]],
    [ "KNG-Kärnten Netz GmbH",[13.13653820322657, 46.93369570070111]],
    [ "KNG-Kärnten Netz GmbH",[13.064418599993559, 46.89753245151207]],
    [ "KNG-Kärnten Netz GmbH",[13.080967102316944, 46.92577205244154]],
    [ "KNG-Kärnten Netz GmbH",[12.932792714356806, 46.85593764904573]],
    [ "KNG-Kärnten Netz GmbH",[12.95130371775016, 46.858585104802735]],
    [ "KNG-Kärnten Netz GmbH",[12.888879129780973, 46.86606165474378]],
    [ "KNG-Kärnten Netz GmbH",[12.908716799231016, 46.912021840003234]],
    [ "KNG-Kärnten Netz GmbH",[12.902686952864459, 46.957091754636814]],
    [ "KNG-Kärnten Netz GmbH",[12.937014903417325, 46.948306302636546]],
    [ "KNG-Kärnten Netz GmbH",[12.910796250082743, 46.919782199162974]],
    [ "KNG-Kärnten Netz GmbH",[12.882613364939155, 47.00887245466027]],
    [ "KNG-Kärnten Netz GmbH",[12.876831899426609, 46.988958950723145]],
    [ "KNG-Kärnten Netz GmbH",[12.821013575260686, 47.044289898328024]],
    [ "Kärnten Netz",[13.181160090694567, 46.74528825232572]],
    [ "KNG-Kärnten Netz GmbH",[13.2542585, 46.75946515]],
    [ "KNG-Kärnten Netz GmbH",[13.248754774464917, 46.75661370434857]],
    [ "KNG-Kärnten Netz GmbH",[13.25117519446948, 46.754672570765585]],
    [ "KNG-Kärnten Netz GmbH",[13.247012473453534, 46.75901409513773]],
    [ "Kärnten Netz",[13.222630771925118, 46.75356665989872]],
    [ "KNG-Kärnten Netz GmbH",[13.147772169558456, 46.731796855887836]],
    [ "Kärnten Netz",[13.145290194857077, 46.7529361829396]],
    [ "Kärnten Netz",[13.146664550842086, 46.74972170021741]],
    [ "KNG-Kärnten Netz GmbH",[13.142254238978621, 46.74417665130866]],
    [ "Kärnten Netz",[13.1775394, 46.74581775]],
    [ "Kärnten Netz",[13.179047, 46.75184835000001]],
    [ "Kärnten Netz",[13.187442518091943, 46.75038997606944]],
    [ "KNG-Kärnten Netz GmbH",[13.084928046066407, 46.74669392657545]],
    [ "KNG-Kärnten Netz GmbH",[13.079686443754941, 46.736317854109075]],
    [ "Kärnten Netz",[13.124733899797903, 46.738804841887074]],
    [ "Kärnten Netz",[13.137850949999999, 46.74369374999999]],
    [ "KNG-Kärnten Netz GmbH",[12.97995376558885, 46.763994820704255]],
    [ "KNG-Kärnten Netz GmbH",[12.994421609902176, 46.75176790073048]],
    [ "KNG-Kärnten Netz GmbH",[13.02074168748914, 46.754689997954955]],
    [ "KNG-Kärnten Netz GmbH",[13.026499361711489, 46.75389355939973]],
    [ "KNG-Kärnten Netz GmbH",[12.938645530666841, 46.74992876289543]],
    [ "KNG-Kärnten Netz GmbH",[13.047191586058903, 46.731949325833995]],
    [ "KNG-Kärnten Netz GmbH",[12.996887973674474, 46.747785999699694]],
    [ "KNG-Kärnten Netz GmbH",[12.971934011886402, 46.685888629607376]],
    [ "KNG-Kärnten Netz GmbH",[12.964439599999997, 46.67596265]],
    [ "KNG-Kärnten Netz GmbH",[12.946113635871326, 46.67574770127944]],
    [ "KNG-Kärnten Netz GmbH",[12.915792647697552, 46.67984950119693]],
    [ "KNG-Kärnten Netz GmbH",[12.816288991788877, 46.69181620246265]],
    [ "KNG-Kärnten Netz GmbH",[12.809283434408023, 46.69381119794432]],
    [ "KNG-Kärnten Netz GmbH",[14.799912899679622, 46.973345399814285]],
    [ "KNG-Kärnten Netz GmbH",[13.115539049999999, 46.6435554]],
    [ "KNG-Kärnten Netz GmbH",[13.057666762216186, 46.65990514975163]],
    [ "KNG-Kärnten Netz GmbH",[13.154318547434379, 46.648338749511176]],
    [ "KNG-Kärnten Netz GmbH",[13.114733408965522, 46.65022779182024]],
    [ "KNG-Kärnten Netz GmbH",[13.230316801872448, 46.63349202335288]],
    [ "KNG-Kärnten Netz GmbH",[13.230349243858782, 46.63638533786528]],
    [ "KNG-Kärnten Netz GmbH",[13.18216395, 46.63922265]],
    [ "KNG-Kärnten Netz GmbH",[13.178675060664025, 46.64130657040355]],
    [ "Energie AG",[13.748302479433, 48.184744962067015]],
    [ "KNG-Kärnten Netz GmbH",[13.748591, 46.77718465000001]],
    [ "KNG-Kärnten Netz GmbH",[13.1799737746047, 46.640468708616964]],
    [ "Energie AG",[13.635387726721522, 48.151270701893566]],
    [ "Reitting",[13.63750448585647, 48.165643425120685]],
    [ "KNG-Kärnten Netz GmbH",[13.774286134927513, 46.73702462642676]],
    [ "KNG-Kärnten Netz GmbH",[13.799904256227293, 46.72618637545228]],
    [ "Linz Netz",[14.481225749630926, 48.495879791152056]],
    [ "Linz Netz",[14.469078399999999, 48.505440650000004]],
    [ "612040308",[14.44120170630775, 48.51782235873811]],
    [ "KNG-Kärnten Netz GmbH",[13.82498928454919, 46.70906418174788]],
    [ "Wiener Netze",[16.259708955382898, 48.098624949314306]],
    [ "KNG-Kärnten Netz GmbH",[13.891754600694728, 46.72931639804957]],
    [ "KNG-Kärnten Netz GmbH",[13.86267891768597, 46.72619344591715]],
    [ "KNG-Kärnten Netz GmbH",[13.85138866084528, 46.72586409130625]],
    [ "Linz AG",[14.213669348838566, 48.33175304248999]],
    [ "Wiener Netze",[16.45305738934724, 48.01378677999689]],
    [ "613200677",[13.729633956214723, 48.13878503072599]],
    [ "KNG-Kärnten Netz GmbH",[13.894978349999999, 46.746129350000004]],
    [ "IZ Trafo 1",[16.3341883, 48.06113814999999]],
    [ "Wiener Netze",[16.333715702760426, 48.061673115481405]],
    [ "KNG-Kärnten Netz GmbH",[13.97999559171643, 46.74474760150514]],
    [ "KNG-Kärnten Netz GmbH",[13.965560435050847, 46.742048294937845]],
    [ "Wiener Netze",[16.32583028412162, 48.073666796758665]],
    [ "Wiener Netze",[16.325669381079383, 48.07402441612633]],
    [ "Wiener Netze",[16.3230835, 48.074363049999995]],
    [ "KNG-Kärnten Netz GmbH",[14.061683624030593, 46.735904269425546]],
    [ "KNG-Kärnten Netz GmbH",[14.0312238870686, 46.734423386006036]],
    [ "KNG-Kärnten Netz GmbH",[14.039345577094746, 46.75027409290183]],
    [ "KNG-Kärnten Netz GmbH",[14.070890210011887, 46.734614956523664]],
    [ "KNG-Kärnten Netz GmbH",[14.038474622570108, 46.77717245238715]],
    [ "KNG-Kärnten Netz GmbH",[13.925292391567476, 46.79213603708822]],
    [ "KNG-Kärnten Netz GmbH",[13.938044258816614, 46.78328943030882]],
    [ "KNG-Kärnten Netz GmbH",[13.880029761374855, 46.81017179851242]],
    [ "KNG-Kärnten Netz GmbH",[13.943371000549433, 46.80822115019059]],
    [ "KNG-Kärnten Netz GmbH",[13.843339949320336, 46.85982275021606]],
    [ "KNG-Kärnten Netz GmbH",[13.880203725782478, 46.843974495109876]],
    [ "KNG-Kärnten Netz GmbH",[13.891610645233149, 46.85466019782051]],
    [ "KNG-Kärnten Netz GmbH",[13.923339581767177, 46.855050495490865]],
    [ "KNG-Kärnten Netz GmbH",[14.059021617370675, 46.82131022590584]],
    [ "KNG-Kärnten Netz GmbH",[14.095764031152969, 46.829371955178196]],
    [ "KNG-Kärnten Netz GmbH",[14.110579541086091, 46.835478044708644]],
    [ "Wiener Netze",[16.30787223404101, 48.04373929900983]],
    [ "Linz AG",[14.406018482965449, 48.36645511097403]],
    [ "Linz AG",[14.415000523610564, 48.36271020691563]],
    [ "KNG-Kärnten Netz GmbH",[14.11969975803996, 46.77068098630866]],
    [ "KNG-Kärnten Netz GmbH",[14.1182413, 46.789243299999995]],
    [ "KNG-Kärnten Netz GmbH",[14.09934897940939, 46.75242690979256]],
    [ "KNG-Kärnten Netz GmbH",[14.090757761711114, 46.76099527678041]],
    [ "KNG-Kärnten Netz GmbH",[14.07854660167531, 46.75850250110764]],
    [ "Netz OÖ",[13.764261416483404, 48.15600090069884]],
    [ "Linz AG",[14.225823875775564, 48.27748097991192]],
    [ "616892124",[14.576089359584431, 48.56828975805087]],
    [ "Umspannwerk Loosdorf",[15.389781738629484, 48.19745140559017]],
    [ "Linz AG",[14.314957468523563, 48.314923501084]],
    [ "KNG-Kärnten Netz GmbH",[14.76168871384705, 46.81584331144365]],
    [ "KNG-Kärnten Netz GmbH",[14.229980350000002, 46.62809715000001]],
    [ "Wiener Netze",[16.521373853339252, 47.99825286598696]],
    [ "Beethovenstraße 10",[14.293403656597594, 46.62720114375445]],
    [ "Ogris Universitätsstraße",[14.271398706281076, 46.61756567401941]],
    [ "Staudach",[12.868833906610154, 48.146206323722424]],
    [ "Dorfen",[12.861595189870764, 48.1522634478433]],
    [ "Endt",[12.865309984128823, 48.16705058629896]],
    [ "Kohldorf",[14.270157588506837, 46.6242975784758]],
    [ "Pädagogische Akademie",[14.287707499999998, 46.608780499999995]],
    [ "Karawankenblick 275",[14.290101727117058, 46.604979803357544]],
    [ "Raiffeisenstraße",[14.307559090264336, 46.60198114072018]],
    [ "Papiermühlgasse",[14.30869065, 46.6022337]],
    [ "Am Wasserturm Gewerbehof",[14.317693541887483, 46.60281253120413]],
    [ "Sorgo Hirschstraße",[14.322219574542164, 46.60350304207972]],
    [ "TST Kapellen Waxriegelhaus",[15.714832950000002, 47.6865085]],
    [ "KNG-Kärnten Netz GmbH",[14.332802952247626, 46.55820791768395]],
    [ "KNG-Kärnten Netz GmbH",[14.297765552327398, 46.55872339949638]],
    [ "Energie AG",[12.840446712226685, 48.15928623595465]],
    [ "620077832",[15.410985204138628, 47.06834928137409]],
    [ "Pasching Brückenstraße gegenüber 1",[14.206126594916004, 48.25948416748556]],
    [ "Linz AG",[14.187313172192981, 48.266899192631584]],
    [ "KNG-Kärnten Netz GmbH",[14.285564510485209, 46.56095149887012]],
    [ "KNG-Kärnten Netz GmbH",[14.296629143620018, 46.573516596378084]],
    [ "KNG-Kärnten Netz GmbH",[14.260469449888458, 46.54970489655457]],
    [ "KNG-Kärnten Netz GmbH",[14.244135972816526, 46.54029007194033]],
    [ "KNG-Kärnten Netz GmbH",[14.262680442128378, 46.55494411723467]],
    [ "KNG-Kärnten Netz GmbH",[14.26668024705545, 46.571474446938446]],
    [ "KNG-Kärnten Netz GmbH",[14.276012549886136, 46.56791864100197]],
    [ "KNG-Kärnten Netz GmbH",[14.307781093209227, 46.56301304972975]],
    [ "KNG-Kärnten Netz GmbH",[14.312980049514401, 46.56611910521872]],
    [ "KNG-Kärnten Netz GmbH",[14.28589891783831, 46.57382097836721]],
    [ "KNG-Kärnten Netz GmbH",[14.289108473685483, 46.57180934450556]],
    [ "KNG-Kärnten Netz GmbH",[14.301948316442001, 46.56244874956973]],
    [ "KNG-Kärnten Netz GmbH",[14.299417768582654, 46.545416302281794]],
    [ "KNG-Kärnten Netz GmbH",[14.295914978762605, 46.554724268320506]],
    [ "KNG-Kärnten Netz GmbH",[14.294059956086459, 46.53924958877093]],
    [ "KNG-Kärnten Netz GmbH",[14.293056544148518, 46.554885486211234]],
    [ "KNG-Kärnten Netz GmbH",[14.332256849999998, 46.55393985]],
    [ "KNG-Kärnten Netz GmbH",[14.179041415796815, 46.59182948181656]],
    [ "KNG-Kärnten Netz GmbH",[14.191588908288871, 46.59955280253028]],
    [ "KNG-Kärnten Netz GmbH",[14.201424998542187, 46.592900650712025]],
    [ "KNG-Kärnten Netz GmbH",[14.222182332166497, 46.5916687812767]],
    [ "KNG-Kärnten Netz GmbH",[14.237752915628231, 46.59172079935589]],
    [ "KNG-Kärnten Netz GmbH",[14.251541377439214, 46.59066685084831]],
    [ "KNG-Kärnten Netz GmbH",[14.140378548971242, 46.60148490923976]],
    [ "KNG-Kärnten Netz GmbH",[14.11849711184762, 46.6121814525793]],
    [ "KNG-Kärnten Netz GmbH",[14.103557475089026, 46.5849831764455]],
    [ "KNG-Kärnten Netz GmbH",[14.113615548182517, 46.59003859497969]],
    [ "KNG-Kärnten Netz GmbH",[14.0856835, 46.6030138]],
    [ "KNG-Kärnten Netz GmbH",[14.025955708582948, 46.59173952058232]],
    [ "KNG-Kärnten Netz GmbH",[14.904567924548354, 46.80476004524937]],
    [ "KNG-Kärnten Netz GmbH",[14.058908220830514, 46.56940459184828]],
    [ "KNG-Kärnten Netz GmbH",[14.055104324753232, 46.55121130592247]],
    [ "KNG-Kärnten Netz GmbH",[14.014220691321086, 46.54496486708537]],
    [ "KNG-Kärnten Netz GmbH",[14.011977466506345, 46.54945812288647]],
    [ "KNG-Kärnten Netz GmbH",[14.102190166128558, 46.52760050319299]],
    [ "KNG-Kärnten Netz GmbH",[14.084310482246694, 46.53243424604982]],
    [ "KNG-Kärnten Netz GmbH",[14.065466304976884, 46.53577699334037]],
    [ "KNG-Kärnten Netz GmbH",[14.062676727154393, 46.54607336710494]],
    [ "KNG-Kärnten Netz GmbH",[14.052220412563043, 46.539280787816836]],
    [ "KNG-Kärnten Netz GmbH",[14.0374989, 46.5364011]],
    [ "KNG-Kärnten Netz GmbH",[14.036369910360001, 46.53942279543289]],
    [ "KNG-Kärnten Netz GmbH",[14.007430457718154, 46.57223335036127]],
    [ "KNG-Kärnten Netz GmbH",[14.00408051342058, 46.5942498583516]],
    [ "KNG-Kärnten Netz GmbH",[13.997541664887528, 46.58938863985235]],
    [ "KNG-Kärnten Netz GmbH",[13.984771732423827, 46.554514490510115]],
    [ "KNG-Kärnten Netz GmbH",[13.986765839418975, 46.54774117540045]],
    [ "KNG-Kärnten Netz GmbH",[13.992425864806853, 46.55238355076395]],
    [ "KNG-Kärnten Netz GmbH",[14.7038655, 46.7917954]],
    [ "KNG-Kärnten Netz GmbH",[14.103079786893083, 46.555258276956]],
    [ "KNG-Kärnten Netz GmbH",[14.130714935690396, 46.54127794672176]],
    [ "KNG-Kärnten Netz GmbH",[14.152453032978254, 46.54622483188266]],
    [ "KNG-Kärnten Netz GmbH",[14.149645248942864, 46.543785451811964]],
    [ "KNG-Kärnten Netz GmbH",[14.14730892074873, 46.53911582963808]],
    [ "Wiener Netze",[16.382027046412627, 48.31242212778855]],
    [ "KNG-Kärnten Netz GmbH",[14.189689156552808, 46.54893105042404]],
    [ "KNG-Kärnten Netz GmbH",[14.18107717300324, 46.542155630601606]],
    [ "KNG-Kärnten Netz GmbH",[14.231910158775335, 46.55942776358662]],
    [ "KNG-Kärnten Netz GmbH",[14.227730339777294, 46.553391014441296]],
    [ "KNG-Kärnten Netz GmbH",[14.214911144393689, 46.55419029770096]],
    [ "KNG-Kärnten Netz GmbH",[14.20113335, 46.568408500000004]],
    [ "KNG-Kärnten Netz GmbH",[14.270480596901876, 46.56582595008727]],
    [ "KNG-Kärnten Netz GmbH",[14.251669507880182, 46.566748902725]],
    [ "KNG-Kärnten Netz GmbH",[14.230256593919531, 46.57034841013738]],
    [ "KNG-Kärnten Netz GmbH",[14.229797459376531, 46.56072931388045]],
    [ "KNG-Kärnten Netz GmbH",[14.343116108426235, 46.57257803321787]],
    [ "KNG-Kärnten Netz GmbH",[14.331908294674948, 46.545649289169404]],
    [ "KNG-Kärnten Netz GmbH",[14.344231405641736, 46.549546104413466]],
    [ "KNG-Kärnten Netz GmbH",[14.378780522551573, 46.58482875474403]],
    [ "KNG-Kärnten Netz GmbH",[14.392577338823259, 46.573769863117086]],
    [ "Energie AG",[13.640159983097746, 48.0210280774467]],
    [ "622073777",[16.66437575241981, 47.805074767843664]],
    [ "KNG-Kärnten Netz GmbH",[14.258013290613913, 46.46087678361146]],
    [ "KNG-Kärnten Netz GmbH",[14.286879374528283, 46.506573656911726]],
    [ "KNG-Kärnten Netz GmbH",[14.27741866829069, 46.532834667571024]],
    [ "KNG-Kärnten Netz GmbH",[14.280865925181566, 46.53242175545919]],
    [ "KNG-Kärnten Netz GmbH",[14.27046509871631, 46.53076325941554]],
    [ "KNG-Kärnten Netz GmbH",[14.278632696798994, 46.52623136515575]],
    [ "KNG-Kärnten Netz GmbH",[14.282468030895698, 46.51795603081548]],
    [ "KNG-Kärnten Netz GmbH",[14.291888115475139, 46.5239912647465]],
    [ "KNG-Kärnten Netz GmbH",[14.26336444884683, 46.534558811203475]],
    [ "KNG-Kärnten Netz GmbH",[14.228370986554532, 46.53318912298393]],
    [ "KNG-Kärnten Netz GmbH",[14.242438622953651, 46.5403833394756]],
    [ "KNG-Kärnten Netz GmbH",[14.153448646295743, 46.52708205112761]],
    [ "KNG-Kärnten Netz GmbH",[13.957496849508871, 46.55651140001308]],
    [ "KNG-Kärnten Netz GmbH",[13.937144450000002, 46.57641859999999]],
    [ "KNG-Kärnten Netz GmbH",[13.952371936852003, 46.574324565368215]],
    [ "KNG-Kärnten Netz GmbH",[13.92645702002658, 46.561670643667824]],
    [ "KNG-Kärnten Netz GmbH",[13.916126767143817, 46.56097085874155]],
    [ "KNG-Kärnten Netz GmbH",[13.93171317479353, 46.569406305200644]],
    [ "KNG-Kärnten Netz GmbH",[13.92020766514005, 46.57501508251603]],
    [ "KNG-Kärnten Netz GmbH",[13.868778573103597, 46.56755981562481]],
    [ "KNG-Kärnten Netz GmbH",[13.84734387914883, 46.56144401314167]],
    [ "KNG-Kärnten Netz GmbH",[13.867530450224296, 46.561336147850646]],
    [ "KNG-Kärnten Netz GmbH",[13.872085200000003, 46.56250345000001]],
    [ "KNG-Kärnten Netz GmbH",[13.901149372712075, 46.571931846774916]],
    [ "KNG-Kärnten Netz GmbH",[13.910159373437377, 46.576142184205054]],
    [ "KNG-Kärnten Netz GmbH",[13.902585308669192, 46.570990204659296]],
    [ "KNG-Kärnten Netz GmbH",[13.87770895, 46.5702666]],
    [ "KNG-Kärnten Netz GmbH",[13.892071305103286, 46.59414878542965]],
    [ "KNG-Kärnten Netz GmbH",[13.933056549335788, 46.58686675120441]],
    [ "KNG-Kärnten Netz GmbH",[13.927784358091627, 46.58748909882928]],
    [ "KNG-Kärnten Netz GmbH",[13.798108303826133, 46.54734283279765]],
    [ "KNG-Kärnten Netz GmbH",[13.816993514077788, 46.55422980254834]],
    [ "KNG-Kärnten Netz GmbH",[13.778945392203678, 46.54811885261354]],
    [ "KNG-Kärnten Netz GmbH",[13.780371754877812, 46.558282707287354]],
    [ "KNG-Kärnten Netz GmbH",[13.838066695634852, 46.56199360765965]],
    [ "KNG-Kärnten Netz GmbH",[13.834880454691206, 46.56197757312354]],
    [ "KNG-Kärnten Netz GmbH",[13.844747149558373, 46.56761363976404]],
    [ "KNG-Kärnten Netz GmbH",[13.96432015, 46.61027730000001]],
    [ "KNG-Kärnten Netz GmbH",[13.959497612865153, 46.61007764419798]],
    [ "KNG-Kärnten Netz GmbH",[13.945783361868571, 46.620264900179606]],
    [ "KNG-Kärnten Netz GmbH",[13.948873211918732, 46.610081898818464]],
    [ "KNG-Kärnten Netz GmbH",[13.9412903, 46.60726025]],
    [ "KNG-Kärnten Netz GmbH",[13.934940366735491, 46.62474137363735]],
    [ "624147090",[12.835253293932162, 48.04864611476257]],
    [ "Energie AG",[13.00295540749184, 48.22546302974494]],
    [ "Roith",[12.992830875761252, 48.211192453870865]],
    [ "Ranshofen Siedlerstr.",[13.004788650123734, 48.23107299974287]],
    [ "KNG-Kärnten Netz GmbH",[13.86963645893558, 46.64173881446253]],
    [ "Kärnten Netz",[13.958959179283793, 46.67596953103664]],
    [ "KNG-Kärnten Netz GmbH",[13.965607996267464, 46.69501661406043]],
    [ "KNG-Kärnten Netz GmbH",[14.013706947585055, 46.673473249591375]],
    [ "KNG-Kärnten Netz GmbH",[14.00955136332499, 46.6853125639119]],
    [ "KNG-Kärnten Netz GmbH",[13.965763558803026, 46.65973039123524]],
    [ "Kärnten Netz",[13.985281897198272, 46.67671459850608]],
    [ "Dietachdorf Wasserwerk",[14.448392371503378, 48.08647625308053]],
    [ "Rankengasse",[14.27852352909946, 46.624568252162824]],
    [ "KNG-Kärnten Netz GmbH",[14.271325685014897, 46.59027321895741]],
    [ "KNG-Kärnten Netz GmbH",[13.959004938118051, 46.63393769150052]],
    [ "KNG-Kärnten Netz GmbH",[13.949040751921226, 46.62979201091325]],
    [ "KNG-Kärnten Netz GmbH",[13.976756553598946, 46.61869153744035]],
    [ "KNG-Kärnten Netz GmbH",[13.979097340486277, 46.6225305635368]],
    [ "Anzengruberstraße 28",[14.274587549999998, 46.62155095]],
    [ "Universitätsstraße",[14.275980705483196, 46.61769017111338]],
    [ "Perkoniggasse",[14.279091058785713, 46.61558749587841]],
    [ "Obirstraße Compakt",[14.28125, 46.61609365]],
    [ "Haraldweg",[14.272066020900118, 46.6141780429522]],
    [ "Generationwohnpark Kranzmayerstraße",[14.269094549999998, 46.6139156]],
    [ "KNG-Kärnten Netz GmbH",[14.002202430341372, 46.61159559737122]],
    [ "KNG-Kärnten Netz GmbH",[14.00484342475554, 46.60021454218911]],
    [ "KNG-Kärnten Netz GmbH",[14.004904950105267, 46.60287977476566]],
    [ "KNG-Kärnten Netz GmbH",[13.999639423591356, 46.605923341239105]],
    [ "KNG-Kärnten Netz GmbH",[13.999160184829183, 46.60931433002514]],
    [ "KNG-Kärnten Netz GmbH",[14.373621709179087, 46.80211265093567]],
    [ "KNG-Kärnten Netz GmbH",[14.026614355916314, 46.631064631907435]],
    [ "KNG-Kärnten Netz GmbH",[14.01288787914996, 46.62218944286638]],
    [ "KNG-Kärnten Netz GmbH",[14.000890125093754, 46.61983650271482]],
    [ "KNG-Kärnten Netz GmbH",[14.025344362005484, 46.60223935175763]],
    [ "KNG-Kärnten Netz GmbH",[14.057725180708124, 46.60529895577603]],
    [ "KNG-Kärnten Netz GmbH",[14.0392931, 46.6241994]],
    [ "KNG-Kärnten Netz GmbH",[14.02694934664257, 46.62239935022012]],
    [ "Schiene Oberösterreich",[14.239434214379703, 48.217100640057865]],
    [ "Hauptmann-Hermann-Platz",[14.288336425481964, 46.61364955328268]],
    [ "Josef Gruberstraße",[14.289806842662864, 46.617069775830075]],
    [ "Pilotenweg",[14.318164450000001, 46.64987500000001]],
    [ "Seeboden Maschinenfabrik Brose",[13.498909545466919, 46.82329112392209]],
    [ "TST St. Johann Weiglgasse",[16.025313150000002, 47.71229725]],
    [ "Linz AG",[14.488420317499184, 48.52965126854627]],
    [ "Wiener Netze",[16.2207626, 48.00965299999999]],
    [ "Wiener Netze",[16.335459117935205, 47.8565471458755]],
    [ "KNG-Kärnten Netz GmbH",[13.77189480846121, 46.65293396826878]],
    [ "KNG-Kärnten Netz GmbH",[14.812275545061404, 46.70269160374932]],
    [ "KNG-Kärnten Netz GmbH",[14.454500264112353, 46.75431446620872]],
    [ "KNG-Kärnten Netz GmbH",[14.410860808248422, 46.749147967072496]],
    [ "KNG-Kärnten Netz GmbH",[14.609774041005792, 46.60549505203702]],
    [ "TST Gloggnitz Ruppgasse",[15.943109649999997, 47.67422335]],
    [ "Netz Niederösterreich GmbH",[15.984476074583288, 47.79738785910627]],
    [ "TST Puchberg Sierningstraße",[15.912091763460204, 47.79541412180678]],
    [ "Kabelüberführungsstation Schemmerlstraße",[16.424735346990317, 48.154219567527946]],
    [ "637441143",[14.935459046814888, 48.27738895016981]],
    [ "637590642",[14.46854874493366, 48.52258410971586]],
    [ "Nonsbach",[13.318506051395408, 48.23979107072462]],
    [ "TST Rohrbach Spiegelgasse",[16.0359079, 47.719545299999986]],
    [ "TST Reichenau Badgasse",[15.838531257175829, 47.693765179178925]],
    [ "Energie AG",[13.870065513209783, 48.21893536250956]],
    [ "Gmünd Kirchgasse",[13.534288255954253, 46.90691754417203]],
    [ "642502746",[13.755992221629992, 48.16957711459383]],
    [ "Gmünd",[13.53554008584938, 46.912955241325164]],
    [ "KNG-Kärnten Netz GmbH",[14.922116308208523, 46.87156995258073]],
    [ "Netz NÖ",[15.88077702656212, 48.10809257426925]],
    [ "Kirche Mettmach",[13.342144253496635, 48.172734823340356]],
    [ "KNG-Kärnten Netz GmbH",[14.041010532723337, 46.64578601727877]],
    [ "KNG-Kärnten Netz GmbH",[14.01773440527083, 46.650794859782785]],
    [ "648304227",[14.27217765, 48.2317192]],
    [ "Viktring",[14.2733165, 46.59029725]],
    [ "SSE St. Valentin Schwarzadamm",[16.015395995799796, 47.6950563447278]],
    [ "649434040",[14.936712551983357, 48.37570460002245]],
    [ "Netz Niederösterreich",[16.121184386131276, 47.6522861813252]],
    [ "Energie AG",[13.30267075609952, 48.18710160015601]],
    [ "652080665",[14.259552249999999, 48.23666465]],
    [ "Altheim Spindlerwehr",[13.224257842426352, 48.253856781591736]],
    [ "Wiener Netze",[16.217364800113863, 48.00487808155041]],
    [ "TST Payerbach West",[15.854721349999998, 47.69243545]],
    [ "TST Puchberg Edenhof",[15.944229749999998, 47.76600045]],
    [ "654227513",[12.725276311719034, 47.64182321097028]],
    [ "654232451",[14.92160009479689, 48.27679128408504]],
    [ "TST Unterhöflein",[16.0293668827318, 47.79325394793528]],
    [ "TST Neunkirchen Loipersbacherstraße",[16.09200905, 47.71747475000001]],
    [ "Netz NÖ",[15.706961357345875, 47.76648173592791]],
    [ "656501209",[13.618035716584265, 48.26531781434029]],
    [ "656501212",[13.624386305135598, 48.27184308837954]],
    [ "656501214",[13.639524210220435, 48.26245265754582]],
    [ "Umspannwerk Prottes",[16.762541147776556, 48.38621182529716]],
    [ "Wiener Netze",[16.31350955, 48.106164400000004]],
    [ "Netz NÖ",[16.60558002195598, 48.00482141320936]],
    [ "TST Seebenstein Bahnstraße",[16.144339384191184, 47.70035124769415]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.958773925746897, 47.61903233004399]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.92459434096803, 47.61428739013312]],
    [ "660188000",[13.09932414955393, 47.08169598855735]],
    [ "Linz AG",[14.76370391103282, 48.38299374742748]],
    [ "Spittal Bahnhof",[13.48819553133313, 46.796432996585374]],
    [ "KNG-Kärnten Netz GmbH",[14.818593996180791, 46.79670548270821]],
    [ "KNG-Kärnten Netz GmbH",[14.863341037633592, 46.93437556434539]],
    [ "Wiener Netze",[16.375657533419083, 47.860522577725355]],
    [ "662337650",[13.499288868623022, 47.71229944808081]],
    [ "663135040",[14.726283459533969, 48.37779259012638]],
    [ "Unterwerk St. Johann",[15.469570554056098, 47.0967799599089]],
    [ "664905928",[14.754356034766646, 48.349508762451585]],
    [ "665331963",[15.083968651163033, 48.549540790639654]],
    [ "Pramet Gewerbegebiet",[13.483316430819084, 48.144203385148]],
    [ "Energie AG",[13.8628451330201, 48.619401252102776]],
    [ "666889140",[13.986722198013714, 48.086292950054165]],
    [ "Salzburg Netz",[13.07929991833816, 47.786676975250494]],
    [ "667683918",[14.615664970131231, 48.41726920087723]],
    [ "Linz AG",[14.553285382679809, 48.26803216865801]],
    [ "Linz AG",[14.565352161809509, 48.26939386917231]],
    [ "Energie Ried",[13.353349577851262, 48.223532296378046]],
    [ "Linz AG",[14.586376149999998, 48.26845265000001]],
    [ "668643425",[14.04713145, 48.07600014999999]],
    [ "Energie Ried",[13.364817867923055, 48.20792876813877]],
    [ "669134967",[14.222861270259758, 47.7597746242926]],
    [ "Energie Ried",[13.391366588598638, 48.1981632574842]],
    [ "Linz AG",[14.578245750000002, 48.270749]],
    [ "Magetsham",[13.42005579404322, 48.16572839821382]],
    [ "Umspannwerk Untersiebenbrunn",[16.74830065334453, 48.26530911818088]],
    [ "SST Günselsdorf",[16.279554948716793, 47.93528495091291]],
    [ "Braunau Bahnhof",[13.045998742551392, 48.25906248414064]],
    [ "Umspannwerk Pottendorf",[16.35756202376576, 47.92926506054164]],
    [ "Kogl",[13.46770404539533, 47.940645992558856]],
    [ "KNG-Kärnten Netz GmbH",[14.3309691068693, 46.824488517523335]],
    [ "Mühlbach a. A.",[13.530426956244602, 47.91267888890579]],
    [ "Energie AG",[13.453014309553698, 47.803891196879015]],
    [ "KNG-Kärnten Netz GmbH",[13.82554264908933, 46.636474778685084]],
    [ "KNG-Kärnten Netz GmbH",[13.81930650112412, 46.6381472495536]],
    [ "Netz Niederösterreich GmbH",[16.101406293290115, 47.70644377067736]],
    [ "681118756",[14.41785765, 48.1482627]],
    [ "681686001",[14.3088541, 48.27870335]],
    [ "682084455",[14.1277185, 48.058737249999986]],
    [ "Schleißheim Mühle",[14.07730831428766, 48.158137057042374]],
    [ "Blumengasse",[14.29831865, 46.63473425000001]],
    [ "Aichelburg Labia Strasse",[14.296969999999998, 46.631564600000004]],
    [ "Unterwerk St. Paul im Lavanttal",[14.85798098855146, 46.72368537954071]],
    [ "Unterwerk Grafenstein",[14.426452856827796, 46.62112939703297]],
    [ "Unterwerk Weststeiermark",[15.305296454994163, 46.814309507230284]],
    [ "Energie AG",[14.25745282928055, 47.84355681007248]],
    [ "Wiener Netze",[16.31396265170391, 47.85376272805609]],
    [ "KNG-Kärnten Netz GmbH",[14.344979548709718, 46.593617147508624]],
    [ "KNG-Kärnten Netz GmbH",[14.348936762603413, 46.590868306470796]],
    [ "KNG-Kärnten Netz GmbH",[13.82263115, 46.703208249999996]],
    [ "KNG-Kärnten Netz GmbH",[13.82521225, 46.69630760000001]],
    [ "685093525",[13.776870993051716, 48.057412111247324]],
    [ "KNG-Kärnten Netz GmbH",[13.836183072735802, 46.58915991244151]],
    [ "KNG-Kärnten Netz GmbH",[13.845746165980295, 46.59559080238016]],
    [ "KNG-Kärnten Netz GmbH",[13.84331793333626, 46.593944449975154]],
    [ "Villach Maria Gailerstraße",[13.868541106814359, 46.60324447061639]],
    [ "Villach Bruno Kreisky Straße",[13.862270350000001, 46.603634400000004]],
    [ "Ma. Gailerstr. Hofer",[13.865212522101546, 46.60397699622537]],
    [ "KNG-Kärnten Netz GmbH",[13.923491200000003, 46.6247151]],
    [ "Netz Niederösterreich GmbH",[16.215277300000004, 48.34121880000001]],
    [ "Netz OÖ",[13.747208592431825, 48.01739703501421]],
    [ "688730183",[13.77330516367181, 48.01901285332864]],
    [ "Landskron Neherweg",[13.881327999999998, 46.63208359999999]],
    [ "Energie Ried",[13.37257051142012, 48.224943106682424]],
    [ "Linz AG",[14.415896403859199, 48.26181187107292]],
    [ "Stallhofen",[13.1767474, 48.09615195]],
    [ "TST Hörsching Seniorenzentrum",[14.175341591373153, 48.22278685803982]],
    [ "Energie AG",[14.239933, 48.14189165]],
    [ "Ach Oberkriebach",[12.82881788912367, 48.146590636294604]],
    [ "Linz AG",[14.572761103121008, 48.58089819281836]],
    [ "Linz AG",[14.569896395694203, 48.59860325127841]],
    [ "Linz AG",[14.52847196008854, 48.60496001582687]],
    [ "Linz AG",[14.378229659592689, 48.24407344055738]],
    [ "Energie AG",[14.411850730351627, 48.22444340741397]],
    [ "Energie AG",[14.41188845, 48.22449855]],
    [ "Umspannwerk Mellach",[15.491168449670244, 46.91008698214186]],
    [ "EWW",[14.011575869440877, 48.15408635567325]],
    [ "Netz NÖ",[16.55725435, 47.987418749999996]],
    [ "TINETZ-Stromnetz Tirol AG",[11.98775697526392, 47.47138609378698]],
    [ "KNG-Kärnten Netz GmbH",[14.13478666408068, 46.68739294731223]],
    [ "Salzburg AG",[13.070479668802994, 48.00168519472202]],
    [ "KNG-Kärnten Netz GmbH",[14.097242258004444, 46.72944374925803]],
    [ "KNG-Kärnten Netz GmbH",[14.118954770451651, 46.72786632689381]],
    [ "KNG-Kärnten Netz GmbH",[14.119686328831968, 46.72401139763014]],
    [ "KNG-Kärnten Netz GmbH",[14.102097876953108, 46.72692179684077]],
    [ "KNG-Kärnten Netz GmbH",[14.097805928933246, 46.719724555268215]],
    [ "Energie AG",[14.111120249999999, 48.571805399999995]],
    [ "Riesstrasse Tischlerei",[15.478848450000003, 47.08243635000001]],
    [ "Linz AG",[14.143081149999999, 48.28474355]],
    [ "Linz AG",[14.142745750000001, 48.28722615000001]],
    [ "Netz OÖ",[13.10744985, 48.06142335]],
    [ "Hainingerbach",[13.568416114377145, 47.94983033633574]],
    [ "Energie AG",[13.862123398823014, 48.09269696424022]],
    [ "Netz Oberösterreich",[13.763672752196394, 48.13339542907251]],
    [ "Energie AG",[13.988357274250387, 47.917642005043895]],
    [ "Schalchen Auffang",[13.167813000000004, 48.12003745]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.872481920105963, 47.59323553994195]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.889790649999997, 47.60146825000001]],
    [ "Energie AG",[14.212984599999999, 47.9167698]],
    [ "Energie AG",[14.2215508, 48.001719050000005]],
    [ "KNG-Kärnten Netz GmbH",[13.535627968471347, 46.912781932100756]],
    [ "TST Grafenbach St. Valentin-Landschach",[16.015414921951294, 47.68650774496068]],
    [ "Netz Niederösterreich GmbH",[15.9482291, 48.76520795000002]],
    [ "Linz AG",[14.67331575, 48.1922735]],
    [ "704735582",[14.07386185, 48.15017415]],
    [ "E-Werk Wels",[14.081638332125237, 48.18155524427627]],
    [ "705361846",[14.36058893782675, 48.497368476905294]],
    [ "TST Wr. Neustadt Lutz Feichtingergasse",[16.212469890731082, 47.82251447978477]],
    [ "705605569",[13.993054899999999, 48.04618595000001]],
    [ "Netz Niederösterreich GmbH",[16.208648035469157, 47.82311397844558]],
    [ "Pichlwang Gallabergstr.",[13.61547165, 47.99162024999999]],
    [ "Timelkam Lerchenfeldstr.",[13.60982506347403, 48.00083061463907]],
    [ "Rudolfstrasse 29",[15.467252120592132, 47.07313494151292]],
    [ "Weißenbach Siedlung",[13.53803542681529, 47.79459404233942]],
    [ "Netz Oberösterreich GmbH",[13.130217554755637, 48.14494432210316]],
    [ "Uttendorf Höfen",[13.122329710358631, 48.14536973002948]],
    [ "Reith",[13.105647663892393, 48.16016225981952]],
    [ "Polling Gemeinde",[13.282323748830867, 48.229137700522664]],
    [ "711667924",[13.43713690874428, 48.16273085385892]],
    [ "Siebenhügelstr. 150",[14.272591, 46.60888835000001]],
    [ "Dachschwendau",[13.550580608735366, 48.025836416207156]],
    [ "Neudorf b. Zipf",[13.511625691472439, 48.02851864376998]],
    [ "Mauerkirchen Romaniberg",[13.140938231943666, 48.19290957066259]],
    [ "Energie AG",[13.329294049266023, 48.12780362336779]],
    [ "Energie AG",[13.236485081248507, 48.255748246879016]],
    [ "Energie AG",[13.34803170194246, 48.17446865292039]],
    [ "712322092",[15.454741798623386, 47.06972521566109]],
    [ "Mörschwang",[13.364505697605702, 48.30551366241446]],
    [ "Pumpstation",[13.34465697985016, 48.32848992604333]],
    [ "Energie AG",[13.336367920848865, 48.31974963468954]],
    [ "ÖDK-Tenniscenter",[14.26283477293769, 46.61749607816819]],
    [ "Linz AG",[14.428630916723366, 48.413018145432595]],
    [ "Linz AG",[14.424602263611744, 48.40883760227456]],
    [ "Energie Ried",[13.463156229829082, 48.25366336062687]],
    [ "Aspach Sdlg.",[13.298583736577532, 48.18595834528781]],
    [ "Wiener Netze",[16.605787246871518, 48.11493485152006]],
    [ "713706905",[13.61974296626928, 48.08451004425022]],
    [ "713710175",[13.612223736990584, 48.04871259826336]],
    [ "Nößlthal",[13.322969798063761, 47.99910255997143]],
    [ "Vöcklabruck Eternitstr.",[13.659072645491145, 48.00000597622543]],
    [ "Umspannwerk Pischelsdorf",[15.941524636092824, 48.32989439085401]],
    [ "Energie AG",[13.368250436417732, 48.280012347139206]],
    [ "Energie AG",[13.373838281986895, 48.261965878499396]],
    [ "Wiener Netze GmbH",[16.2098095090023, 47.89225722007452]],
    [ "Energie AG",[13.396964579511463, 48.30937135361833]],
    [ "Schneegattern",[13.29435205164108, 48.021135049033624]],
    [ "718353355",[13.163947031350206, 48.123404612312946]],
    [ "718355174",[13.155634185515845, 48.120808413452316]],
    [ "Altheim Dornergries",[13.238404211739782, 48.23857108184811]],
    [ "Netz Oberösterreich",[13.686199903142727, 47.531600367951]],
    [ "719122119",[16.031607237948762, 46.68060932195499]],
    [ "Energie AG",[13.178864015921029, 48.23654195409075]],
    [ "719460024",[13.599939585185522, 48.080279556480015]],
    [ "Waltendorferhauptstraße",[15.475828199999997, 47.06647449999999]],
    [ "Energie AG",[13.581131950460277, 48.075926467432225]],
    [ "Energie AG",[13.565527648237842, 48.080529452215444]],
    [ "Energie AG",[13.510421627920513, 48.078019104037864]],
    [ "Energie Ried",[13.413377079529976, 48.262598960322556]],
    [ "721008644",[13.620588263047953, 48.10639130656657]],
    [ "721008646",[13.603876590073273, 48.1142033988589]],
    [ "Salzburg AG",[13.02283443879772, 47.81149168450205]],
    [ "KNG-Kärnten Netz GmbH",[14.112415600985635, 46.64172350133609]],
    [ "Kärnten Netz",[14.389715767322544, 46.65939250704976]],
    [ "Sauggas",[12.058933083462989, 47.48967629805126]],
    [ "Döstling",[12.833372208033355, 48.059903365005525]],
    [ "KNG-Kärnten Netz GmbH",[14.061066990425518, 46.68312584774009]],
    [ "Gmünd Glanzersäge",[13.544871403562151, 46.90989154966076]],
    [ "APG Umspannwerk Westtirol",[10.873845198722055, 47.24424668126891]],
    [ "727580810",[13.96007803420882, 47.977603572725414]],
    [ "Gmünd Volksschule",[13.539357249556407, 46.90852140370739]],
    [ "Energie AG",[14.163362159201641, 47.83242456636091]],
    [ "Energie AG",[14.260910899999999, 47.88239694999999]],
    [ "Energie AG",[14.244906332432485, 47.88425041044925]],
    [ "731912524",[14.268769929705593, 47.88865455952363]],
    [ "TST Lichtenwörth Heutalhof",[16.321754983403384, 47.82225864530645]],
    [ "732727028",[13.962630938766544, 47.89792559983495]],
    [ "732727029",[13.942156364099091, 47.88460623199448]],
    [ "Energie AG",[14.205177268617584, 48.19168179373782]],
    [ "Braunau Innbrücke",[13.017060498732764, 48.247078417671155]],
    [ "Buchengasse",[14.307743147139904, 46.62052114253289]],
    [ "Wiener Netze",[16.4058755, 48.102916099999995]],
    [ "Netz Niederösterreich GmbH",[16.04353243164563, 47.79155977524928]],
    [ "741084597",[14.2676203, 47.9754341]],
    [ "UW Sankt Leonhard",[14.785933351712037, 46.97088366593514]],
    [ "744193070",[14.3606131, 47.945999799999996]],
    [ "Madersbacherweg",[12.053593841440588, 47.48461476714061]],
    [ "KNG-Kärnten Netz GmbH",[14.698114972763696, 46.94896307110087]],
    [ "TST Brunn Wasserwerk",[16.16970136747472, 47.8171085423235]],
    [ "KNG-Kärnten Netz GmbH",[13.76854414884206, 46.650543298805744]],
    [ "751660356",[13.069952759793516, 47.77921022737207]],
    [ "EVN",[15.0623928, 48.07392905]],
    [ "Rudolfstrasse Wippel",[15.480680311130238, 47.07320745483026]],
    [ "Polzergasse Altenheim",[15.465069999999997, 47.07180325000001]],
    [ "KNG-Kärnten Netz GmbH",[14.579519828578203, 46.50801200990699]],
    [ "KNG-Kärnten Netz GmbH",[14.578761356382241, 46.52798915423324]],
    [ "KNG-Kärnten Netz GmbH",[14.593685788643423, 46.53785798784915]],
    [ "KNG-Kärnten Netz GmbH",[14.676013644329837, 46.49094821521523]],
    [ "KNG-Kärnten Netz GmbH",[14.638459949353189, 46.47933113286714]],
    [ "KNG-Kärnten Netz GmbH",[14.528028653228759, 46.419337878055586]],
    [ "KNG-Kärnten Netz GmbH",[14.621854466894039, 46.465809306111694]],
    [ "KNG-Kärnten Netz GmbH",[14.335835141462907, 46.47280155184861]],
    [ "KNG-Kärnten Netz GmbH",[14.35014852059275, 46.48572955895271]],
    [ "KNG-Kärnten Netz GmbH",[14.385219382260194, 46.472446790773425]],
    [ "KNG-Kärnten Netz GmbH",[14.425810115689517, 46.474700543377494]],
    [ "KNG-Kärnten Netz GmbH",[14.443681583788988, 46.480216045737286]],
    [ "KNG-Kärnten Netz GmbH",[14.48291179138506, 46.4377925460041]],
    [ "KNG-Kärnten Netz GmbH",[14.341841740243519, 46.50117262763846]],
    [ "KNG-Kärnten Netz GmbH",[14.400600364341923, 46.53902528767052]],
    [ "KNG-Kärnten Netz GmbH",[14.39317094305178, 46.54801303971562]],
    [ "KNG-Kärnten Netz GmbH",[14.331198332089723, 46.542215574610836]],
    [ "KNG-Kärnten Netz GmbH",[14.340460804405197, 46.54061415918989]],
    [ "KNG-Kärnten Netz GmbH",[14.358672919275104, 46.54253397996012]],
    [ "KNG-Kärnten Netz GmbH",[14.37210869943433, 46.543133654695055]],
    [ "KNG-Kärnten Netz GmbH",[14.313623575830155, 46.52890959760237]],
    [ "KNG-Kärnten Netz GmbH",[14.30727067916112, 46.53575258161755]],
    [ "KNG-Kärnten Netz GmbH",[14.313857813008598, 46.54063484518811]],
    [ "KNG-Kärnten Netz GmbH",[14.421188301936049, 46.54113974839316]],
    [ "KNG-Kärnten Netz GmbH",[14.417305436765082, 46.54150072095627]],
    [ "KNG-Kärnten Netz GmbH",[14.412225026671766, 46.5370536510918]],
    [ "KNG-Kärnten Netz GmbH",[14.3997062768173, 46.53337643433545]],
    [ "755479472",[14.437992410234152, 46.54306959765524]],
    [ "KNG-Kärnten Netz GmbH",[14.432348957515325, 46.551642790383724]],
    [ "KNG-Kärnten Netz GmbH",[14.470127779836389, 46.54748145062393]],
    [ "KNG-Kärnten Netz GmbH",[14.457620069885861, 46.525337144859435]],
    [ "KNG-Kärnten Netz GmbH",[14.52108467091597, 46.47488970014724]],
    [ "Oberlerchergasse",[14.297697876733316, 46.630361428702315]],
    [ "Kühtai",[11.005711001419092, 47.207728403140656]],
    [ "EVN",[15.943072, 48.507438]],
    [ "Energie AG",[14.646352317161824, 48.549911689030985]],
    [ "Wiener Netze",[16.282238399999997, 48.093999149999995]],
    [ "Umspannwerk Kledering",[16.431536531750776, 48.13899339825063]],
    [ "Linz AG",[14.504958207436381, 48.378070417798384]],
    [ "Wiener Netze",[16.322577729832815, 47.85201854188389]],
    [ "Linz AG",[14.653626655160023, 48.195096719977975]],
    [ "Am Ragnitzbach",[15.471256779044852, 47.07721455370202]],
    [ "Umspannwerk Pyburg",[14.516763175516402, 48.232445969907744]],
    [ "Maschinenbau 1",[13.338492565490125, 48.24028250291849]],
    [ "Salzburg AG",[13.074457750000002, 47.9654902]],
    [ "777467273",[14.600170762924362, 48.55519350499301]],
    [ "Energie AG",[13.437181630763492, 47.74175445771217]],
    [ "Linz AG",[14.545151611467373, 48.380217918105025]],
    [ "Lengau Gewerbegebiet",[13.22758106802683, 48.005490832982275]],
    [ "Linz AG",[14.4246845, 48.26127435]],
    [ "Energie AG",[13.42695245, 47.74663474999999]],
    [ "Ried Leopoldhof",[13.421076715452514, 47.74777340552428]],
    [ "Netz Niederösterreich GmbH",[16.56731726212602, 48.58007892672303]],
    [ "TST Schwarzau Nord",[15.692519129137537, 47.82253575121543]],
    [ "St. Gilgen Winkl",[13.385361850000002, 47.7765371]],
    [ "Wiener Netze",[16.2531154, 47.93509525]],
    [ "Netz NÖ",[16.248508568155376, 47.94179955213302]],
    [ "Aich SCHST.",[13.382721120657706, 47.77587744648125]],
    [ "Netz OÖ",[13.918169650000001, 48.23220395]],
    [ "Netz Niederösterreich GmbH",[15.421483945669783, 48.3668794836745]],
    [ "Schaltwerk Pernhofen",[16.28205385321283, 48.715882223090176]],
    [ "Energie AG",[13.19971975566755, 48.13301260057097]],
    [ "Netz Niederösterreich GmbH",[15.618002200000001, 48.20096104999999]],
    [ "Energie AG",[14.116465283205532, 48.179054072298804]],
    [ "Energie Klagenfurt GmbH",[14.30252990686239, 46.62658462981129]],
    [ "Energie AG",[13.801868940351985, 48.204130013848705]],
    [ "Energie AG",[13.804880230235717, 48.208278252322465]],
    [ "Netz Niederösterreich",[15.400475777807758, 48.34089180320691]],
    [ "Andorf Bruck",[13.5682963, 48.365939000000004]],
    [ "798515369",[15.8097214718461, 46.72675051673523]],
    [ "Mattighofen Berufsschule",[13.152702395475364, 48.11175626937539]],
    [ "Energie AG",[13.71275893310249, 47.909322036564305]],
    [ "Wiener Netze",[16.309663966804152, 47.9508110501712]],
    [ "Wiener Netze",[16.2962266453245, 47.95283080769012]],
    [ "Netz Niederösterreich",[15.407615313255418, 48.31594680498978]],
    [ "Netz Niederösterreich",[15.407843937628261, 48.32319343106013]],
    [ "TST Tattendorf Schulstraße",[16.301787872727274, 47.958018167099574]],
    [ "Wiener Netze",[16.276124594954016, 47.950556503107464]],
    [ "Netz NÖ",[16.089778124971016, 47.95769993188278]],
    [ "Netz Oberösterreich",[13.703713687843887, 47.54670358163646]],
    [ "TST Langenschönbichl Siedlung",[15.985739050000003, 48.32768385]],
    [ "Wiener Netze",[16.30511288120096, 48.07499630484476]],
    [ "Salzburg Netz",[12.939835550000002, 47.93913895000001]],
    [ "TINETZ-Stromnetz Tirol AG",[12.089568799999999, 47.53558534999999]],
    [ "TST Mollram Ortstraße",[16.092828771439773, 47.757598546559265]],
    [ "808302742",[13.069579122569662, 47.781255656848344]],
    [ "810699678",[13.417199949999999, 47.7253165]],
    [ "812614012",[13.793403532732903, 48.04743273411982]],
    [ "812614023",[13.793838174722387, 48.0127485564504]],
    [ "Salzburg Netz",[12.940852293684207, 47.942651832898605]],
    [ "TST Günselsdorf Wohnhausanlage",[16.258369884273385, 47.944407483216985]],
    [ "Salzburg Netz",[12.940030793416495, 47.94818683082121]],
    [ "Wiener Netze",[16.297705249999996, 48.02202570000001]],
    [ "TST Teesdorf Spinnerei",[16.28854454943921, 47.95066619096703]],
    [ "Salzburg Netz",[12.9398849, 47.94613395]],
    [ "813943770",[15.789338400517856, 46.72574165306144]],
    [ "Netz NÖ",[16.261561436228945, 47.939749099385594]],
    [ "Netz Niederösterreich GmbH",[16.250417926244626, 47.84615872617606]],
    [ "Netz Niederösterreich GmbH",[16.251061313921298, 47.83685788182058]],
    [ "Netz Niederösterreich GmbH",[16.2509162, 47.8358618]],
    [ "815063706",[12.108644949999999, 47.514471349999994]],
    [ "Wiener Netze",[16.278003742032958, 47.94737325030534]],
    [ "Wiener Netze",[16.252099448548943, 47.93235597290561]],
    [ "Wiener Netze",[16.277627157976525, 47.969281300923825]],
    [ "Wiener Netze",[16.276474432621754, 47.96467138977697]],
    [ "Wiener Netze",[16.273558501407773, 47.96537339083345]],
    [ "Wiener Netze GmbH",[16.26479671884659, 47.95669684023346]],
    [ "Salzburg Netz",[12.938443000000001, 47.95105145]],
    [ "Einoedhofweg",[15.497710823209836, 47.06119350672404]],
    [ "Salzburg Netz",[12.93336025, 47.94156520000001]],
    [ "KNG-Kärnten Netz GmbH",[14.948919841284699, 46.96296819915548]],
    [ "KNG-Kärnten Netz GmbH",[14.867079897319954, 46.95201509962179]],
    [ "KNG-Kärnten Netz GmbH",[14.93745866061515, 46.963406402820986]],
    [ "KNG-Kärnten Netz GmbH",[14.117176993727552, 46.70898844616169]],
    [ "KNG-Kärnten Netz GmbH",[13.874492707203203, 46.83901572753478]],
    [ "Am Blumenhang",[15.48357023040931, 47.06974354480582]],
    [ "Endach",[12.154287231828821, 47.57055844674786]],
    [ "Hörsching WV 2",[14.189843930867573, 48.24842644551135]],
    [ "Hörsching WV 1",[14.189821342213554, 48.24846121912782]],
    [ "Kaiserlift Tal",[12.177479555622277, 47.579106584983265]],
    [ "TST Pitten Fabrikstraße",[16.19355367878648, 47.72122196979653]],
    [ "TST Pitten Haidwiesensiedlung",[16.189725665755745, 47.720322483820375]],
    [ "TST Pitten Werksgasse",[16.18584698441102, 47.71842059737359]],
    [ "TST Pitten Bahnhofstraße",[16.18512765, 47.715678499999996]],
    [ "Netz Niederösterreich GmbH",[16.18361953272415, 47.71373306686639]],
    [ "TST Pitten Idastraße",[16.182663149999996, 47.72126605]],
    [ "820344467",[14.200136807835248, 48.253973216056146]],
    [ "Pirlo",[12.181249049999998, 47.594714350000004]],
    [ "Innsiedlung",[12.182400450000003, 47.602625100000004]],
    [ "822919374",[12.744821925470061, 47.25776674644253]],
    [ "823258503",[16.02186840109449, 46.707085599513995]],
    [ "Lake Side Park 4.0",[14.263204474102292, 46.613160742192186]],
    [ "Tennis Center",[14.2799262, 46.60853480000001]],
    [ "Salzburg Netz",[13.321694001699747, 47.59249018336417]],
    [ "Toni-Strugger-Weg",[14.284223450000004, 46.62373310000001]],
    [ "KNG-Kärnten Netz GmbH",[14.357793485375135, 46.65760553614714]],
    [ "827130074",[14.339486499141726, 46.646327906770594]],
    [ "KNG-Kärnten Netz GmbH",[14.352756323504261, 46.64417556839594]],
    [ "827631225",[15.250844823272729, 48.22332159531375]],
    [ "Kraßniggstr.",[14.308963832321808, 46.63118025094463]],
    [ "Feldgasse",[14.318368000000003, 46.63611385000001]],
    [ "Walnussweg",[14.301696599999998, 46.63743709999999]],
    [ "Austrian Power Grid AG",[16.419733551016826, 48.125972964273174]],
    [ "KNG-Kärnten Netz GmbH",[14.280076600632954, 46.79507625172176]],
    [ "KNG-Kärnten Netz GmbH",[13.497075077011488, 46.81765858881059]],
    [ "KNG-Kärnten Netz GmbH",[14.89903696669441, 46.90233373858768]],
    [ "KNG-Kärnten Netz GmbH",[14.878833071843882, 46.91094472392305]],
    [ "TINETZ",[11.890400399999999, 47.44635115]],
    [ "Energie AG",[14.328118819103954, 48.01696603205083]],
    [ "Braunau Sdlg. Haiden",[13.03803585416487, 48.24972842880272]],
    [ "Energie AG",[12.85538168568765, 48.047268163392054]],
    [ "KNG-Kärnten Netz GmbH",[14.935601936760165, 46.87445461085186]],
    [ "Wiener Netze",[16.272029608229516, 47.9614366672799]],
    [ "Netz NÖ",[16.306533549999997, 47.9573956]],
    [ "Salzburg Netz",[13.057996299999997, 47.840568]],
    [ "839821492",[14.250001600000003, 48.52083170000001]],
    [ "KNG-Kärnten Netz GmbH",[14.452511253751364, 46.61999028969757]],
    [ "KNG-Kärnten Netz GmbH",[14.452945128650592, 46.62633052174335]],
    [ "Kärnten Netz",[14.437589153649126, 46.66673062559631]],
    [ "KNG-Kärnten Netz GmbH",[14.442181955030048, 46.61355732335107]],
    [ "KNG-Kärnten Netz GmbH",[14.500022805234764, 46.61159538447249]],
    [ "KNG-Kärnten Netz GmbH",[14.484398645675665, 46.6043940646135]],
    [ "KNG-Kärnten Netz GmbH",[14.468478400000002, 46.611553400000005]],
    [ "840653603",[14.466329531791986, 46.61590677724964]],
    [ "KNG-Kärnten Netz GmbH",[14.184340244022222, 46.64728831770235]],
    [ "KNG-Kärnten Netz GmbH",[14.199330144744204, 46.64227449512751]],
    [ "KNG-Kärnten Netz GmbH",[14.214679301095131, 46.69578751228946]],
    [ "KNG-Kärnten Netz GmbH",[14.217925470429346, 46.689860448438466]],
    [ "KNG-Kärnten Netz GmbH",[14.051277869635173, 46.64170574701924]],
    [ "KNG-Kärnten Netz GmbH",[14.066495232895361, 46.65787865151238]],
    [ "KNG-Kärnten Netz GmbH",[14.073319080947467, 46.638919115348784]],
    [ "KNG-Kärnten Netz GmbH",[14.073724263602747, 46.642606150023816]],
    [ "KNG-Kärnten Netz GmbH",[14.06419066017262, 46.64830259153981]],
    [ "KNG-Kärnten Netz GmbH",[14.0827517213591, 46.65296010303267]],
    [ "KNG-Kärnten Netz GmbH",[14.100294132662526, 46.71787995204251]],
    [ "KNG-Kärnten Netz GmbH",[14.10327119149423, 46.71615001620838]],
    [ "KNG-Kärnten Netz GmbH",[14.107749250000001, 46.71775895]],
    [ "KNG-Kärnten Netz GmbH",[14.12155069595305, 46.69831629651231]],
    [ "KNG-Kärnten Netz GmbH",[14.142328498110286, 46.70067876058358]],
    [ "KNG-Kärnten Netz GmbH",[14.139885839013884, 46.696276353710225]],
    [ "KNG-Kärnten Netz GmbH",[14.153378366051138, 46.695243292613995]],
    [ "KNG-Kärnten Netz GmbH",[14.164746850000002, 46.695565550000005]],
    [ "KNG-Kärnten Netz GmbH",[14.184614726670697, 46.69264567394088]],
    [ "KNG-Kärnten Netz GmbH",[14.089008734983913, 46.69594428816371]],
    [ "KNG-Kärnten Netz GmbH",[14.080769283121883, 46.706952448452725]],
    [ "KNG-Kärnten Netz GmbH",[14.07314534764901, 46.70039346662086]],
    [ "KNG-Kärnten Netz GmbH",[14.2493124, 46.67088550000001]],
    [ "KNG-Kärnten Netz GmbH",[14.245931351842573, 46.671578716381646]],
    [ "KNG-Kärnten Netz GmbH",[14.148297543898565, 46.72864060568492]],
    [ "KNG-Kärnten Netz GmbH",[14.156781932773214, 46.723682204936196]],
    [ "KNG-Kärnten Netz GmbH",[14.151227318469251, 46.673263465095594]],
    [ "KNG-Kärnten Netz GmbH",[14.14870104903517, 46.68033665059486]],
    [ "KNG-Kärnten Netz GmbH",[14.107739570191377, 46.65749979805574]],
    [ "KNG-Kärnten Netz GmbH",[14.308928556980652, 46.72622024978093]],
    [ "KNG-Kärnten Netz GmbH",[14.3257750988766, 46.72204483509372]],
    [ "KNG-Kärnten Netz GmbH",[14.340934402001537, 46.716941934814834]],
    [ "KNG-Kärnten Netz GmbH",[14.341603013976, 46.73282951466786]],
    [ "KNG-Kärnten Netz GmbH",[14.517808693183193, 46.588057279429684]],
    [ "KNG-Kärnten Netz GmbH",[14.49703250201653, 46.598088398575754]],
    [ "KNG-Kärnten Netz GmbH",[14.1016607, 46.631088749999996]],
    [ "KNG-Kärnten Netz GmbH",[14.109248099999999, 46.633538099999996]],
    [ "KNG-Kärnten Netz GmbH",[14.11646315, 46.6351819]],
    [ "KNG-Kärnten Netz GmbH",[14.11209511647443, 46.63512630613949]],
    [ "KNG-Kärnten Netz GmbH",[14.257447625237194, 46.820645813597956]],
    [ "KNG-Kärnten Netz GmbH",[14.309183284107963, 46.78761084796521]],
    [ "KNG-Kärnten Netz GmbH",[14.213029600353785, 46.78848605058003]],
    [ "Energie Klagenfurt GmbH",[14.284088749999999, 46.66591475]],
    [ "Energie Klagenfurt GmbH",[14.281844891587232, 46.66275950844826]],
    [ "KNG-Kärnten Netz GmbH",[14.254427374936137, 46.83693819354573]],
    [ "KNG-Kärnten Netz GmbH",[14.286745391587418, 46.846368065136204]],
    [ "KNG-Kärnten Netz GmbH",[14.295569257190943, 46.81878790019567]],
    [ "KNG-Kärnten Netz GmbH",[14.134147031000342, 46.85210182515085]],
    [ "KNG-Kärnten Netz GmbH",[14.1539577017338, 46.85112473577823]],
    [ "KNG-Kärnten Netz GmbH",[14.168445478071904, 46.8499333579866]],
    [ "KNG-Kärnten Netz GmbH",[14.02505031202488, 46.94069712867674]],
    [ "KNG-Kärnten Netz GmbH",[14.094880157779516, 46.90442346263645]],
    [ "KNG-Kärnten Netz GmbH",[14.11932669873877, 46.87449296994215]],
    [ "KNG-Kärnten Netz GmbH",[14.19249665030098, 46.847528224267485]],
    [ "KNG-Kärnten Netz GmbH",[14.23284197544192, 46.86100125448632]],
    [ "KNG-Kärnten Netz GmbH",[14.200441199242684, 46.845102631506165]],
    [ "KNG-Kärnten Netz GmbH",[14.196179843431569, 46.84527952642843]],
    [ "KNG-Kärnten Netz GmbH",[14.158139297230813, 46.86268141552686]],
    [ "KNG-Kärnten Netz GmbH",[14.151565516995376, 46.894094626703705]],
    [ "KNG-Kärnten Netz GmbH",[14.286217878978777, 46.8756211954599]],
    [ "KNG-Kärnten Netz GmbH",[14.287335481444021, 46.873868547516665]],
    [ "KNG-Kärnten Netz GmbH",[14.26823433156222, 46.86752702194915]],
    [ "KNG-Kärnten Netz GmbH",[14.305300899999997, 46.91373245]],
    [ "KNG-Kärnten Netz GmbH",[14.303428500000003, 46.90073655]],
    [ "KNG-Kärnten Netz GmbH",[14.336884193153105, 46.89391889194238]],
    [ "KNG-Kärnten Netz GmbH",[14.326462078820494, 46.892530094875916]],
    [ "KNG-Kärnten Netz GmbH",[14.348273447065896, 46.93643925099023]],
    [ "KNG-Kärnten Netz GmbH",[14.32514280324543, 46.932336540405586]],
    [ "KNG-Kärnten Netz GmbH",[14.27477828584183, 46.9265144499136]],
    [ "KNG-Kärnten Netz GmbH",[14.305000120687858, 46.91788025649222]],
    [ "KNG-Kärnten Netz GmbH",[14.36002270512766, 46.89548023089871]],
    [ "KNG-Kärnten Netz GmbH",[14.582571520935433, 46.96789825674238]],
    [ "Linz AG Strom",[14.296176049999998, 48.32386845]],
    [ "KNG-Kärnten Netz GmbH",[14.405924689173556, 46.90067010110453]],
    [ "KNG-Kärnten Netz GmbH",[14.395768548827085, 46.84068309862804]],
    [ "843240118",[14.421694450714408, 48.37456560357545]],
    [ "KNG-Kärnten Netz GmbH",[14.219608999575028, 46.98249291421021]],
    [ "KNG-Kärnten Netz GmbH",[14.20840725, 46.981942450000005]],
    [ "KNG-Kärnten Netz GmbH",[14.175362177884319, 46.9802513962792]],
    [ "KNG-Kärnten Netz GmbH",[14.148775909815617, 46.96740584863299]],
    [ "KNG-Kärnten Netz GmbH",[14.253858446947493, 46.978282602886026]],
    [ "KNG-Kärnten Netz GmbH",[14.209918458721074, 46.963277858571374]],
    [ "KNG-Kärnten Netz GmbH",[14.257597602284095, 46.98084174232415]],
    [ "KNG-Kärnten Netz GmbH",[14.272996909133212, 46.9757164161594]],
    [ "KNG-Kärnten Netz GmbH",[14.378990710843697, 46.96732657871251]],
    [ "KNG-Kärnten Netz GmbH",[14.360586301442567, 46.9661188996732]],
    [ "Gruener Baum",[15.48548993512436, 47.05721193126444]],
    [ "Sterneckstrasse 25",[14.297846821800066, 46.62546355742575]],
    [ "Energie Klagenfurt GmbH",[14.329560130134388, 46.63145914796239]],
    [ "Energie Klagenfurt GmbH",[14.3327191, 46.6303517]],
    [ "Energie Klagenfurt GmbH",[14.3408262, 46.63601625]],
    [ "Energie Klagenfurt GmbH",[14.340274499999998, 46.63237399999999]],
    [ "KNG-Kärnten Netz GmbH",[14.371523465438237, 46.644394781971585]],
    [ "St. Veiterring 27",[14.307950484092148, 46.62886151524633]],
    [ "Schloss Zigguln",[14.290981634018767, 46.635085654009785]],
    [ "Billa Feldkirchnerstr.",[14.293907392653741, 46.639459434309885]],
    [ "Mantschehofg. 7",[14.293376413369012, 46.64205178258665]],
    [ "N. H. Uteweg",[14.296193800000001, 46.64016169999999]],
    [ "Wohnen-Morgen II",[14.29648303323794, 46.641914696100876]],
    [ "Wohnen-Morgen 1",[14.297264699999996, 46.64108055]],
    [ "Energie Klagenfurt GmbH",[14.29976454069259, 46.641653965220115]],
    [ "Turmgasse Neue Heimat",[14.298782773215535, 46.63886581277898]],
    [ "Energie AG",[14.112240884262052, 48.183001353056035]],
    [ "Wiener Netze GmbH",[16.22934455, 47.88796955]],
    [ "TST Sollenau Wasserwerk",[16.23707507503908, 47.8953826964237]],
    [ "Wiener Netze",[16.185157551900765, 47.91965020017]],
    [ "KNG-Kärnten Netz GmbH",[14.422374427185577, 46.97524893294113]],
    [ "KNG-Kärnten Netz GmbH",[14.41399283259474, 46.94657573185916]],
    [ "KNG-Kärnten Netz GmbH",[14.411859749999998, 46.945972499999996]],
    [ "KNG-Kärnten Netz GmbH",[14.40758554211282, 46.949737017311534]],
    [ "KNG-Kärnten Netz GmbH",[14.410168673547403, 46.94762720026219]],
    [ "KNG-Kärnten Netz GmbH",[14.415044149999998, 46.950626050000004]],
    [ "KNG-Kärnten Netz GmbH",[14.420146735325698, 46.95273379312813]],
    [ "KNG-Kärnten Netz GmbH",[14.4101196, 46.95339135]],
    [ "KNG-Kärnten Netz GmbH",[14.402059360264612, 46.95525458614526]],
    [ "KNG-Kärnten Netz GmbH",[14.406286483188223, 46.9554150990442]],
    [ "KNG-Kärnten Netz GmbH",[14.396284134442293, 46.962715754050414]],
    [ "KNG-Kärnten Netz GmbH",[14.39422145, 46.9597367]],
    [ "KNG-Kärnten Netz GmbH",[14.397393950000001, 46.958557]],
    [ "KNG-Kärnten Netz GmbH",[14.405831534028062, 46.95892320105777]],
    [ "KNG-Kärnten Netz GmbH",[14.401747750000004, 46.96150775]],
    [ "KNG-Kärnten Netz GmbH",[14.387163854515583, 46.96981219511856]],
    [ "Energie Ried",[13.51873310151722, 48.21352115144693]],
    [ "Premstätten Ziegelstrasse",[15.393766666994791, 46.96876635035666]],
    [ "Umspannwerk Walgauwerk",[9.67005603768491, 47.19890398743703]],
    [ "KNG-Kärnten Netz GmbH",[13.454976299670514, 46.682127875013734]],
    [ "Energie Ried",[13.450944655817297, 48.20492514849233]],
    [ "Petersbergenstrasse Rast",[15.501884736258772, 47.059216471267874]],
    [ "Petersbergenstrasse 36",[15.481978623365226, 47.05504616248398]],
    [ "Energie Ried",[13.418373999442208, 48.20979757123206]],
    [ "Emil Ertl Gasse 69",[15.460357370271867, 47.04732936350473]],
    [ "856604038",[13.770624213723934, 47.8786835516409]],
    [ "Unterwerk Reininghaus",[15.41029516717152, 47.05660283746858]],
    [ "Netz Niederösterreich GmbH",[15.614738349999996, 48.17951645]],
    [ "858785778",[13.754217675666794, 47.90287195643229]],
    [ "Linz Netz",[14.51493075, 48.37010809999999]],
    [ "Wiener Netze",[16.20019332595126, 47.93225033822509]],
    [ "KNG-Kärnten Netz GmbH",[14.436054820619004, 46.9468554568]],
    [ "KNG-Kärnten Netz GmbH",[14.446518205889259, 46.95853543640324]],
    [ "KNG-Kärnten Netz GmbH",[14.474136299999998, 46.96337335]],
    [ "KNG-Kärnten Netz GmbH",[14.444541276125596, 46.97190671903271]],
    [ "KNG-Kärnten Netz GmbH",[14.467341509098137, 46.94902329412863]],
    [ "KNG-Kärnten Netz GmbH",[14.513611924375278, 46.90259895477974]],
    [ "KNG-Kärnten Netz GmbH",[14.478092735902155, 46.91628527609793]],
    [ "KNG-Kärnten Netz GmbH",[14.425331624758476, 46.91856374526295]],
    [ "861254435",[13.708796900000001, 47.87365539999999]],
    [ "863266995",[15.650878973572294, 46.72180565671166]],
    [ "Netz NÖ",[16.096058349999996, 47.95328969999999]],
    [ "Petri Au",[15.497668420461844, 47.06322800144012]],
    [ "Wels Strom",[13.986962301398545, 48.13729691596925]],
    [ "865562521",[13.514628639340954, 47.71600112777349]],
    [ "Linz Netz",[14.518591347716379, 48.36680943416801]],
    [ "Linz AG",[14.64826947524951, 48.201785384700486]],
    [ "Linz AG",[14.516650807383265, 48.36427777129842]],
    [ "Linz Netz",[14.530902234598667, 48.241426928397324]],
    [ "870887220",[13.119422120387277, 47.4026842540634]],
    [ "KNG-Kärnten Netz GmbH",[13.87833337390449, 46.63836201276718]],
    [ "Eggelsberg",[12.992678437873382, 48.076722323893904]],
    [ "Linz Netz",[14.518947306382978, 48.37142149999999]],
    [ "Linz Netz",[14.516004377445785, 48.36660536752689]],
    [ "Linz Netz",[14.512595705628083, 48.368202122185586]],
    [ "Netz Niederösterreich GmbH",[16.242964259978685, 47.833193730364464]],
    [ "Netz Niederösterreich GmbH",[16.244017391482746, 47.82970452618798]],
    [ "877435971",[14.263798018334743, 47.97603439489751]],
    [ "Gewerbep. Reigersd.",[14.396349649999996, 46.645538599999995]],
    [ "Linz Netz",[14.515952648530346, 48.37272000487623]],
    [ "Linz Netz",[14.516150152023732, 48.37476494475524]],
    [ "Energie AG",[13.254770049403858, 47.97609106770933]],
    [ "Linz AG Strom",[14.307379601119724, 48.33109638280088]],
    [ "881893923",[14.6109863, 47.89712425]],
    [ "883142995",[13.068366900000003, 47.824396750000005]],
    [ "Umspannwerk Pöchlarn",[15.218504806013922, 48.20482243836988]],
    [ "Netz Niederösterreich",[15.45655926587838, 48.38156590120676]],
    [ "886113699",[13.967827399999997, 47.914550199999994]],
    [ "886639490",[14.582916950000001, 48.25202395]],
    [ "Netz Niederösterreich GmbH",[16.18838095, 47.6128327]],
    [ "Wiener Netze",[16.303545442053814, 48.10957012505366]],
    [ "Linz AG",[14.6987779434653, 48.22556202752471]],
    [ "888593883",[13.75183519742681, 48.48964652701453]],
    [ "Umspannwerk Raab",[13.623741259871641, 48.354031097863505]],
    [ "889624614",[13.60118747447427, 48.066391006027466]],
    [ "Wiener Netze GmbH",[16.26490266923106, 47.95768314193838]],
    [ "Wiener Netze",[16.28283064618166, 47.97662311583316]],
    [ "Austrian Power Grid AG",[14.163032199999998, 47.82125954999999]],
    [ "893792313",[13.996463887498164, 48.07078403702049]],
    [ "893792316",[13.967824188056266, 48.07557742208871]],
    [ "Linz AG",[14.426806850000002, 48.568854449999996]],
    [ "Oberwang",[13.428341169964057, 47.86965859966562]],
    [ "Netz NÖ",[16.258649512102533, 47.94531500436493]],
    [ "Netz NÖ",[16.26084848220465, 47.94191784786525]],
    [ "Berlinerring Hauptstation",[15.495944622885665, 47.07632241735918]],
    [ "Berlinerring A",[15.498473810599018, 47.07587807510601]],
    [ "Energie AG",[13.772278815825379, 47.79973791539357]],
    [ "Reintalstrasse 90",[15.514878535880717, 47.06040352786686]],
    [ "Netz NÖ",[16.25317071341362, 47.943138810875034]],
    [ "Umspannwerk Hörsching",[14.16854121839108, 48.20751456822298]],
    [ "Linz AG",[14.411716742013786, 48.283035045460196]],
    [ "903761769",[15.454916078745686, 47.05404950372058]],
    [ "905628525",[13.941751464728389, 48.04759419333435]],
    [ "905628527",[13.923785993073693, 48.06518676609188]],
    [ "906270279",[13.950289045124588, 48.08667349804423]],
    [ "906946130",[15.477676463901512, 47.04827403912512]],
    [ "906946169",[15.447500873966618, 47.025613266905104]],
    [ "Linz Netz",[14.524599298042602, 48.348856907850816]],
    [ "Laßnitzhöhe Liebmannweg",[15.576862707776607, 47.074165878602095]],
    [ "TST Blumau Labor",[16.289120031706208, 47.91587255020657]],
    [ "TST Blumau Kasinostraße",[16.286879363022702, 47.91639114445326]],
    [ "Umspannwerk Opponitz",[14.830181580409175, 47.890416985973594]],
    [ "Wiener Netze",[16.346163226121366, 48.086055555457996]],
    [ "Wiener Netze",[16.391985807853107, 47.91240853479069]],
    [ "910595426",[15.061900801323606, 48.56524405313282]],
    [ "Wiener Netze",[16.331153899999997, 47.97214579999999]],
    [ "Linz AG Strom",[14.421329450000004, 48.37003915]],
    [ "TINETZ",[12.50739282215866, 46.722498507772514]],
    [ "912671442",[16.6529715, 47.89374029999999]],
    [ "Wiener Netze",[16.390037272710565, 47.91127829225803]],
    [ "913483635",[15.019076550694443, 48.55819440575601]],
    [ "Linz AG Strom",[14.311057836122796, 48.33233750788929]],
    [ "Linz AG Strom",[14.3131506, 48.329788699999995]],
    [ "Hermagor Heilstätte",[13.371416696299676, 46.6299445757633]],
    [ "Hermagor Nord",[13.369485546010536, 46.62939799183904]],
    [ "TST Grünsting Kurparksiedlung",[15.809609149999996, 47.69204584999999]],
    [ "Linz Netz",[14.529530860650018, 48.354053293732775]],
    [ "Umspannwerk Baumgarten",[16.87038865644527, 48.317191512098795]],
    [ "Linz AG",[14.440107609132646, 48.3440892067583]],
    [ "Riedergasse",[13.36984835, 46.6262984]],
    [ "KNG-Kärnten Netz GmbH",[13.3671058, 46.6268264]],
    [ "Wiener Netze",[16.31238593528052, 47.94826449525375]],
    [ "Wiener Netze",[16.31183548558294, 47.949984444821126]],
    [ "Wiener Netze",[16.28563429910292, 48.019958157176134]],
    [ "Stiftingtalstrasse 199",[15.488102081703778, 47.09169133442217]],
    [ "Stiftingtalstrasse 295",[15.500169636476366, 47.096045655674814]],
    [ "Unterwerk Ahrental",[11.396486279761213, 47.21535229542619]],
    [ "923616813",[13.630506304067953, 47.63181294619056]],
    [ "Linz Netz",[14.574480331002201, 48.33298270193335]],
    [ "KNG-Kärnten Netz GmbH",[12.785850755018195, 46.70118487761859]],
    [ "926085664",[13.61821248076995, 47.687365754991546]],
    [ "Hohenreinstrasse",[15.499480600000002, 47.06718775000001]],
    [ "928560771",[15.44461479399375, 47.09307656953795]],
    [ "EW Gösting",[15.408624262208349, 47.09038709439822]],
    [ "Kärnten Netz",[13.845603999999998, 46.616091499999996]],
    [ "Netz Niederösterreich GmbH",[16.308120306605282, 48.717190942034804]],
    [ "Netz Niederösterreich GmbH",[16.299259356381306, 48.71561269964576]],
    [ "EKG-Energie Klagenfurt GmbH",[14.316613208391937, 46.63721847495442]],
    [ "TST Tattendorf Dir. Franz Danzinger Straße",[16.301699467513973, 47.9610806665078]],
    [ "Netz NÖ",[16.25308887859385, 47.942013944751146]],
    [ "TST Neurißhof Beamterhaus",[16.30245655962212, 47.92564220017843]],
    [ "TST Neurißhof Böhmer",[16.309853061864853, 47.92728842916479]],
    [ "TST Neurißhof Koloniestr.",[16.303156752449304, 47.928366123206004]],
    [ "TST Neurißhof Sportplatzsiedlung",[16.29810809908457, 47.92829220169323]],
    [ "TST Blumau Heidegasse",[16.300474471011363, 47.92130111154191]],
    [ "Netz Niederösterreich GmbH",[16.25403215, 47.87149825]],
    [ "TST Theresienfeld 2",[16.25080129323299, 47.85937406991393]],
    [ "Netz Niederösterreich GmbH",[16.231137300000004, 47.85206924999999]],
    [ "Netz Niederösterreich GmbH",[16.246111341269838, 47.85463295637187]],
    [ "Netz Niederösterreich GmbH",[16.253306787183607, 47.862184125774945]],
    [ "TST Theresienfeld Eigenheimsiedlung",[16.23525235, 47.85621589999999]],
    [ "Netz Niederösterreich GmbH",[16.248523765137325, 47.86069609347886]],
    [ "TST Theresienfeld Getreidegasse",[16.24595985, 47.863695799999995]],
    [ "Netz Niederösterreich GmbH",[16.2362143, 47.86172655]],
    [ "TST Theresienfeld Industrie Gelände 2",[16.232110572180314, 47.864099744555766]],
    [ "Netz Niederösterreich GmbH",[16.250911254182355, 47.86526222299973]],
    [ "Netz Niederösterreich GmbH",[16.248939676470396, 47.871795359191005]],
    [ "Netz Niederösterreich GmbH",[16.239481100000003, 47.86091625000001]],
    [ "TST Theresienfeld Tritolgasse",[16.25771892319748, 47.860399940904585]],
    [ "Netz Niederösterreich GmbH",[16.247834116937444, 47.86724367875068]],
    [ "TST Theresienfeld Gewerbepark",[16.229797277563538, 47.864353644686155]],
    [ "Hermann-Gmeiner-Weg",[15.406248999999999, 47.02990254999999]],
    [ "KNG-Kärnten Netz GmbH",[13.486143831101272, 46.804775584739176]],
    [ "Spittal Edling",[13.513996665210188, 46.79207183748629]],
    [ "KNG-Kärnten Netz GmbH",[13.505412666576117, 46.79585320015426]],
    [ "KNG-Kärnten Netz GmbH",[14.40196925198134, 46.80288843173567]],
    [ "KNG-Kärnten Netz GmbH",[14.374587564853126, 46.80002555972881]],
    [ "KNG-Kärnten Netz GmbH",[14.38141424475638, 46.78826011275466]],
    [ "KNG-Kärnten Netz GmbH",[14.392170917388011, 46.801448853827914]],
    [ "KNG-Kärnten Netz GmbH",[14.374864118472516, 46.796262049605524]],
    [ "KNG-Kärnten Netz GmbH",[14.298901025743053, 46.820775126228796]],
    [ "KNG-Kärnten Netz GmbH",[14.408308812286103, 46.805874839923504]],
    [ "KNG-Kärnten Netz GmbH",[14.363008907258171, 46.811846915653014]],
    [ "KNG-Kärnten Netz GmbH",[14.368573249582333, 46.81448300215641]],
    [ "KNG-Kärnten Netz GmbH",[14.369036772132864, 46.813253014267175]],
    [ "KNG-Kärnten Netz GmbH",[14.377361350000001, 46.78142325]],
    [ "KNG-Kärnten Netz GmbH",[14.376820859465631, 46.76619072557713]],
    [ "KNG-Kärnten Netz GmbH",[14.367281665952445, 46.774169055573076]],
    [ "KNG-Kärnten Netz GmbH",[14.364356413772349, 46.7719072566888]],
    [ "KNG-Kärnten Netz GmbH",[14.35851642720398, 46.77983839640997]],
    [ "KNG-Kärnten Netz GmbH",[14.420093234588414, 46.76566308140156]],
    [ "KNG-Kärnten Netz GmbH",[14.41495229554683, 46.76947664706258]],
    [ "KNG-Kärnten Netz GmbH",[14.401356356745076, 46.763154031037935]],
    [ "KNG-Kärnten Netz GmbH",[14.380567894845983, 46.76279144993219]],
    [ "KNG-Kärnten Netz GmbH",[14.390593303222994, 46.746585582710026]],
    [ "KNG-Kärnten Netz GmbH",[14.417538868916068, 46.796725670494375]],
    [ "KNG-Kärnten Netz GmbH",[14.419129248553542, 46.78388819834035]],
    [ "KNG-Kärnten Netz GmbH",[14.443698906708919, 46.83018202289474]],
    [ "KNG-Kärnten Netz GmbH",[14.435429505397796, 46.85539585287423]],
    [ "KNG-Kärnten Netz GmbH",[14.430569861159006, 46.85244895258904]],
    [ "KNG-Kärnten Netz GmbH",[14.452716619260768, 46.87788650844354]],
    [ "KNG-Kärnten Netz GmbH",[14.49880815582259, 46.855992267513756]],
    [ "KNG-Kärnten Netz GmbH",[14.447327222427573, 46.86043894471025]],
    [ "KNG-Kärnten Netz GmbH",[14.475682031693758, 46.777350556293044]],
    [ "KNG-Kärnten Netz GmbH",[14.47417725, 46.78568855]],
    [ "KNG-Kärnten Netz GmbH",[14.490599280825082, 46.81179612941297]],
    [ "KNG-Kärnten Netz GmbH",[14.48886300100143, 46.82916356904994]],
    [ "KNG-Kärnten Netz GmbH",[14.483720150000002, 46.840213850000005]],
    [ "Unterwerk Angern",[16.813205545926884, 48.3782616961928]],
    [ "KNG-Kärnten Netz GmbH",[14.460616927464647, 46.76346383099589]],
    [ "KNG-Kärnten Netz GmbH",[14.434959717106851, 46.764496759243904]],
    [ "KNG-Kärnten Netz GmbH",[14.433844448565806, 46.76224543530372]],
    [ "KNG-Kärnten Netz GmbH",[14.45106735, 46.7692585]],
    [ "KNG-Kärnten Netz GmbH",[14.43355115862152, 46.78332463747789]],
    [ "KNG-Kärnten Netz GmbH",[14.502084357853082, 46.750681296824126]],
    [ "Netz Niederösterreich GmbH",[15.73881035, 47.981295300000006]],
    [ "Linz Netz",[14.517475166251213, 48.355890959722295]],
    [ "Virchowgasse",[15.464263381928921, 47.038383247433316]],
    [ "Stanglmuehlstrasse",[15.463628961192743, 47.03187537216481]],
    [ "Netz Noe",[15.213802466788188, 48.06074609605733]],
    [ "Wiener Netze",[16.250099901652632, 47.93033615238056]],
    [ "Wiener Netze",[16.250255676295428, 47.92949356610502]],
    [ "Wiener Netze",[16.25641346786384, 47.93289184038248]],
    [ "Altmünster Nachdemsee",[13.76580303127226, 47.89558263095968]],
    [ "Umspannwerk Linz-City",[14.300739365687992, 48.296967508860405]],
    [ "Moosbrunnweg",[15.492188599999999, 47.04364869999999]],
    [ "TST Erlach Erholungszentrum",[16.21851495, 47.72944095]],
    [ "KNG-Kärnten Netz GmbH",[14.564584036613887, 46.938176149615416]],
    [ "KNG-Kärnten Netz GmbH",[14.549603111154111, 46.89934555509671]],
    [ "KNG-Kärnten Netz GmbH",[14.549703863375013, 46.90381288692752]],
    [ "KNG-Kärnten Netz GmbH",[14.50972317260658, 46.881522425944766]],
    [ "KNG-Kärnten Netz GmbH",[14.502070357944392, 46.869960958860815]],
    [ "KNG-Kärnten Netz GmbH",[14.505736649074901, 46.88769023323996]],
    [ "KNG-Kärnten Netz GmbH",[14.528288301235433, 46.895061852248766]],
    [ "KNG-Kärnten Netz GmbH",[14.54119580486835, 46.86905768697715]],
    [ "KNG-Kärnten Netz GmbH",[14.542025829102773, 46.867243826830276]],
    [ "KNG-Kärnten Netz GmbH",[14.564034423405836, 46.88457104974883]],
    [ "KNG-Kärnten Netz GmbH",[14.5438054574573, 46.8743886561729]],
    [ "KNG-Kärnten Netz GmbH",[14.553733502137447, 46.891567229055454]],
    [ "KNG-Kärnten Netz GmbH",[14.548732421613268, 46.820255298061696]],
    [ "KNG-Kärnten Netz GmbH",[14.540906611622471, 46.83202947531647]],
    [ "KNG-Kärnten Netz GmbH",[14.534502059089101, 46.82978435799097]],
    [ "KNG-Kärnten Netz GmbH",[14.536207657356787, 46.8489651760408]],
    [ "KNG-Kärnten Netz GmbH",[14.588289074530387, 46.819141478034716]],
    [ "KNG-Kärnten Netz GmbH",[14.5552609703399, 46.8018576752898]],
    [ "KNG-Kärnten Netz GmbH",[14.557679578969985, 46.81076698553497]],
    [ "KNG-Kärnten Netz GmbH",[14.562836747804035, 46.81087888836976]],
    [ "Kärnten Netz",[14.452198902464964, 46.69697395040627]],
    [ "KNG-Kärnten Netz GmbH",[14.319185971165536, 46.750937169093454]],
    [ "KNG-Kärnten Netz GmbH",[14.91656178312078, 46.95964450119148]],
    [ "KNG-Kärnten Netz GmbH",[14.78572364353326, 46.978439270610345]],
    [ "KNG-Kärnten Netz GmbH",[14.888919022787425, 46.88458816954374]],
    [ "KNG-Kärnten Netz GmbH",[14.96797339982927, 46.933976649697584]],
    [ "Austrian Power Grid AG",[14.683844690535064, 46.66821396323193]],
    [ "Austrian Power Grid AG",[14.475579698167492, 48.125854708188626]],
    [ "945754901",[13.147507703901928, 47.601967094036404]],
    [ "Energie AG",[13.195621699999998, 47.24394985000001]],
    [ "Netze OÖ",[14.651303028017388, 47.85316606211969]],
    [ "Janischhofweg 61",[15.470774071310384, 47.105977756846066]],
    [ "947882213",[13.549541765362125, 47.37514578271114]],
    [ "Netz Oberösterreich GmbH",[13.20793385, 47.26926815]],
    [ "947882215",[13.737441087785282, 47.13977152003357]],
    [ "947882216",[12.6891761, 47.6022278]],
    [ "947882217",[12.537982575992935, 47.372884194142735]],
    [ "Netz NÖ",[16.104860790483546, 47.9492773866847]],
    [ "Wiener Netze",[16.61341845, 48.117311550000004]],
    [ "951596933",[14.030891104903127, 48.192447923906734]],
    [ "Netz Oberösterreich",[13.460954821744686, 47.73710358975805]],
    [ "951860784",[14.229504649999997, 48.14598265]],
    [ "Zwickledt",[13.47889019685821, 48.50574150908315]],
    [ "TST Sollenau Almera",[16.27976551106006, 47.906299589793576]],
    [ "TST Sollenau Am Damm",[16.255136576782945, 47.91127568786343]],
    [ "TST Sollenau Bahngasse",[16.24694618132956, 47.89978200728089]],
    [ "TST Sollenau Benzolstraße",[16.256864034027398, 47.89657147764556]],
    [ "TST Sollenau Billa",[16.25147977581705, 47.88047931265368]],
    [ "TST Sollenau Blumauerstraße",[16.263655850000003, 47.898881]],
    [ "TST Sollenau Böhlersiedlung",[16.27248338374585, 47.90199960073387]],
    [ "TST Sollenau Brunnenfeldstraße 1",[16.24320415, 47.895424000000006]],
    [ "Netz Niederösterreich GmbH",[16.239103315918012, 47.89485741760882]],
    [ "TST Sollenau Feldgasse",[16.254520026017307, 47.89490960612572]],
    [ "TST Sollenau Grossmittlerstraße",[16.254271171752304, 47.8886144040781]],
    [ "TST Sollenau Heilsamer Brunnen 1",[16.2414026, 47.90162030000001]],
    [ "TST Sollenau Kindergarten",[16.25210625, 47.89776705]],
    [ "TST Sollenau Mitte",[16.249510600000004, 47.89688385]],
    [ "TST Sollenau Mühlstraße",[16.250343915631532, 47.89327174821429]],
    [ "TST Sollenau Nord",[16.252506250000003, 47.90353895000001]],
    [ "TST Sollenau Oase",[16.252116249999997, 47.88604805]],
    [ "TST Sollenau Petrifeld",[16.252696016097765, 47.8910914662874]],
    [ "TST Sollenau Piestingbrücke",[16.25099575, 47.89555165]],
    [ "Netz Niederösterreich GmbH",[16.2358388, 47.891357899999996]],
    [ "TST Sollenau Schönauerstraße",[16.2507330352576, 47.90621825749117]],
    [ "Netz Niederösterreich GmbH",[16.242627249999998, 47.89752535000001]],
    [ "TST Steinabrückl Heidemühle",[16.218628400577025, 47.87510006684003]],
    [ "TST Steinabrückl Lehnerstraße",[16.21498734236254, 47.87022588413292]],
    [ "TST Steinabrückl Schule",[16.202840978710356, 47.86844549662998]],
    [ "UST Kramsach Hagau",[11.872381649999998, 47.435646899999995]],
    [ "Zellerberg",[12.15211935, 47.578488050000004]],
    [ "TST Sollenau Radweg",[16.2410475, 47.88791274999999]],
    [ "TST Sollenau Maria Theresien Str.",[16.252925650000005, 47.892497000000006]],
    [ "Wiener Netze",[16.276024239239753, 47.99183909648164]],
    [ "Wiener Netze",[16.32348345, 48.07960235]],
    [ "Linz AG",[14.633635450000003, 48.193252900000005]],
    [ "Wiener Netze",[16.33066819078767, 48.06469849209802]],
    [ "Netz NÖ",[16.26873727274385, 47.844539597004214]],
    [ "967839725",[14.557127593391279, 48.45286222156926]],
    [ "967839731",[14.592470685058311, 48.459067993813825]],
    [ "Wiener Netze",[16.335372520250438, 48.07006141378975]],
    [ "Einfangstr",[12.15304435, 47.57398435]],
    [ "Rosenheimerstr",[12.17506205, 47.593720749999996]],
    [ "Schule Sparchen",[12.17698165, 47.59123060000001]],
    [ "Maximilianstr",[12.173185749999998, 47.58060385000001]],
    [ "Umspannwerk Gaming",[15.097146723185123, 47.941776024532906]],
    [ "Wiener Netze",[16.376155150000002, 48.310900000000004]],
    [ "TINETZ-Stromnetz Tirol AG",[12.113226121761507, 47.5547635805747]],
    [ "Ragnitztalweg",[15.50378395, 47.07419925000001]],
    [ "Lustbuehel",[15.504458249999997, 47.06928084999999]],
    [ "Netz Niederösterreich",[16.173346149999997, 48.015557199999996]],
    [ "Netz NÖ",[16.119493972160047, 48.05144544271723]],
    [ "Netz NÖ",[16.13338602732625, 48.044287841917935]],
    [ "Netz Oberösterreich",[13.463027853054292, 47.73384580270952]],
    [ "TST Rohr im Gebirge Nöst",[15.702371, 47.8678338]],
    [ "Münchnerstrasse",[12.166156244486512, 47.58432942429775]],
    [ "Weckaufstr",[12.16062137743236, 47.58319158733505]],
    [ "BFST Langkampfen Energiepark",[12.092555516894105, 47.53457761389171]],
    [ "Netz Niederösterreich",[16.182233085231505, 48.018992521651064]],
    [ "Netz OÖ",[13.461830999999998, 47.7331761]],
    [ "Netz NÖ",[16.309752699999997, 48.095676499999996]],
    [ "981264931",[13.496559116143668, 47.533725344928136]],
    [ "TST Wöllersdorf Bahnhof",[16.17582375, 47.861857050000005]],
    [ "Netz Niederösterreich GmbH",[16.19093942827121, 47.85037391419562]],
    [ "TST Steinabrückl Febau Röhrs",[16.199869718485534, 47.85915269666814]],
    [ "Netz Niederösterreich GmbH",[16.19957141753775, 47.85826089407666]],
    [ "Netz Niederösterreich GmbH",[16.199435417473072, 47.85488909792177]],
    [ "Netz Niederösterreich GmbH",[16.18376672295954, 47.85022930869658]],
    [ "TST Wöllersdorf Stadtweg",[16.19414021586715, 47.85557525193747]],
    [ "Netz Niederösterreich GmbH",[16.19031035105892, 47.84945872785536]],
    [ "Netz Niederösterreich GmbH",[16.186233710939536, 47.85136612573644]],
    [ "Netz Niederösterreich GmbH",[16.200046188482112, 47.852060888296315]],
    [ "Spittal Lutherstraße",[13.493937462496387, 46.79965925835421]],
    [ "981547182",[13.61402725, 47.712509700000005]],
    [ "Netz OÖ",[13.60024435, 47.71245000000001]],
    [ "Netz OÖ",[13.585718968733783, 47.71920724823318]],
    [ "Wagingerstr",[12.155682965783857, 47.57346528952779]],
    [ "Messendorfberg 31",[15.491445050000001, 47.05267700000001]],
    [ "Netz NÖ",[16.596108073361073, 47.96956217114728]],
    [ "Umspannwerk Mannersdorf",[16.609317491591828, 47.98520134496219]],
    [ "Linz AG",[14.3049235, 48.314933749999994]],
    [ "Berlinerring B",[15.496680460204267, 47.07552160415398]],
    [ "Berlinerring C",[15.494758450141843, 47.075428883475176]],
    [ "TST Wimpassing Kirche",[16.02018815, 47.69996375000001]],
    [ "Wiener Netze GmbH",[16.243226030895464, 47.87668674637548]],
    [ "Wiener Netze GmbH",[16.237333908098524, 47.87618180004935]],
    [ "Wiener Netze",[16.23200425, 47.875422349999994]],
    [ "Wiener Netze GmbH",[16.230200035099283, 47.87737270410109]],
    [ "Wiener Netze GmbH",[16.2337367, 47.88030799999999]],
    [ "Wiener Netze GmbH",[16.23172565, 47.88122189999999]],
    [ "Wiener Netze",[16.23892053130935, 47.88365856673124]],
    [ "Wiener Netze",[16.23655379149907, 47.88433269571734]],
    [ "Wiener Netze",[16.242337715547787, 47.88547301288482]],
    [ "Wiener Netze GmbH",[16.247060511158878, 47.886202967649936]],
    [ "Wiener Netze GmbH",[16.24721383106258, 47.882462771961414]],
    [ "Wiener Netze",[16.248136991181305, 47.874574318427406]],
    [ "Wiener Netze",[16.24118896459638, 47.88232935387755]],
    [ "Wiener Netze",[16.3255301, 48.06677355]],
    [ "Wiener Netze",[16.31988248590647, 48.068247330537005]],
    [ "Wiener Netze",[16.319949818482765, 48.06824971031621]],
    [ "Wiener Netze",[16.314762139459493, 48.069178186532355]],
    [ "TST Heiligenkreuz Friedhof",[16.13899918389804, 48.05670111917479]],
    [ "Netz NÖ",[16.17837547531487, 48.03109771321024]],
    [ "Wiener Netze",[16.096030128962457, 48.1246146246974]],
    [ "Wiener Netze",[16.298256403538343, 47.84807219517489]],
    [ "Linz Netz",[14.51203172773843, 48.374560227536314]],
    [ "Umspannwerk Steinbrunn",[16.43662240335838, 47.81911613754462]],
    [ "Netz NÖ",[16.258277657901797, 47.824483222331935]],
    [ "TST Miesenbach Ascherstraße",[15.959019232693219, 47.82309385337202]],
    [ "Linz AG",[14.177928799999998, 48.2372103]],
    [ "SSE Wampersdorf",[16.39919349775307, 47.92323578234409]],
    [ "Wiener Netze",[16.295897863396725, 47.892640745425005]],
    [ "Wiener Netze",[16.30497767943374, 47.89195043587246]],
    [ "Wiener Netze",[16.33042970353022, 47.890492218094565]],
    [ "Wiener Netze",[16.338410363382195, 47.88801346453962]],
    [ "Wiener Netze",[16.35139506950491, 47.8736423046191]],
    [ "Wiener Netze",[16.355185348709963, 47.87401702372388]],
    [ "Bildgasse",[9.673568777342144, 47.438260234243025]],
    [ "Grütt",[9.657905646763542, 47.442195602979986]],
    [ "Rheinstraße",[9.649446629076126, 47.43806548185695]],
    [ "Badlochstraße",[9.65329385, 47.44112404999999]],
    [ "993401092",[14.535344577750188, 48.52327500556575]],
    [ "Netz Niederösterreich",[15.416963699999998, 48.364987099999986]],
    [ "Netz Niederösterreich GmbH",[15.417553550000001, 48.36287110000001]],
    [ "Trautweinstr",[12.180819142869492, 47.59540056685773]],
    [ "Bettleweg",[9.670798867598352, 47.44272760564188]],
    [ "Rasisbündt",[9.669194199999998, 47.44526694999999]],
    [ "ÖMV",[9.666142749999999, 47.4503208]],
    [ "Sender",[9.70208685260757, 47.44874070900886]],
    [ "Linz AG",[14.424355649999999, 48.3599871]],
    [ "Wiener Netze",[16.345740838466853, 47.88956088395461]],
    [ "Wiener Netze",[16.28840306070873, 47.94610394196551]],
    [ "Umspannwerk Höflein",[16.785533100252355, 48.04776485281224]],
    [ "Haag a. Hausruck Kreuzerfeldsdlg.",[13.63641984838245, 48.1819955039019]],
    [ "996551197",[14.02224342125218, 48.379118155352124]],
    [ "Netz NÖ",[15.981851, 47.83782929999999]],
    [ "Energie AG",[13.647799376284489, 48.17944755406736]],
    [ "Powang",[13.430674187400598, 47.89808844417816]],
    [ "Plaika",[13.431068817729797, 47.86517933473383]],
    [ "Energie AG",[13.396650492843026, 47.83659868922416]],
    [ "St. Georgen Gewerbe",[13.4996029, 47.9345239]],
    [ "Energie AG",[13.509616852593496, 47.92256130866068]],
    [ "Nußdorf Nord",[13.522346228753426, 47.88547659373456]],
    [ "Netz Niederösterreich GmbH",[15.344457456806431, 48.146741597458934]],
    [ "998405983",[13.823597856152489, 47.99781813874082]],
    [ "Netz NÖ",[15.98417575, 47.847638200000006]],
    [ "BST Jenbach Rotholzerbrücke",[11.792502750000002, 47.39058250000001]],
    [ "TST Grünsting Stiftung Rothschild",[15.82724895, 47.694650849999995]],
    [ "TST Neunkirchen Bahnstraße",[16.08289190981075, 47.73058701907595]],
    [ "Wiener Netze",[16.26366562358784, 47.985328832431385]],
    [ "Wiener Netze",[16.254655273465882, 47.99709099941282]],
    [ "Wiener Netze",[16.354875763127236, 47.87468673169365]],
    [ "Wiener Netze",[16.310017213237092, 47.98530824668646]],
    [ "Netz NÖ",[15.925947717154322, 48.327300095734444]],
    [ "Austrian Power Grid",[15.925061796292649, 48.32749776668374]],
    [ "Energie AG",[13.562908664572422, 48.15282842845388]],
    [ "Energie Steiermark",[15.471308499999997, 47.07129164999999]],
    [ "Energie Steiermark",[15.471331588976714, 47.07120789886719]],
    [ "KNG-Kärnten Netz GmbH",[13.936931128667673, 46.62455700833388]],
    [ "Wiener Netze",[16.380013417927575, 47.91032225713649]],
    [ "Wiener Netze",[16.298121050000002, 48.131231750000005]],
    [ "Wiener Netze",[16.388284525466304, 47.90730498607456]],
    [ "1008978106",[14.2769562, 48.23631645]],
    [ "Netz NÖ",[15.177154049999999, 47.975389850000006]],
    [ "1009718425",[14.275297250000001, 48.236084]],
    [ "Wiener Netze",[16.385270853450905, 47.86539373492177]],
    [ "Wiener Netze GmbH",[16.215617350000002, 47.89504215000001]],
    [ "Wiener Netze GmbH",[16.218317986711238, 47.88868916747161]],
    [ "Wiener Netze GmbH",[16.216166649999998, 47.89045240000001]],
    [ "Wiener Netze GmbH",[16.204579312899188, 47.89777803277087]],
    [ "Wiener Netze",[16.324643350000002, 47.8571399]],
    [ "Josefweg",[15.46459287463223, 47.10113339138706]],
    [ "Wiener Netze",[16.41179546641915, 47.95524589435866]],
    [ "Wiener Netze",[16.41059025, 47.95801629999999]],
    [ "Wiener Netze",[16.377289086751823, 47.878935000848315]],
    [ "1015399020",[14.119218176053723, 48.163267901300976]],
    [ "Unterwerk Alt-Eggenberg",[15.392836840869004, 47.076779514677845]],
    [ "Netz OÖ",[13.996205068494556, 48.321472915980344]],
    [ "SSE Steinabrückl",[16.218934449999995, 47.8754176]],
    [ "Wiener Netze",[16.288255001080667, 48.12734098692393]],
    [ "Traun - Untere Dorfstraße",[14.25314705846028, 48.222957930842334]],
    [ "Linz AG",[14.45809851904365, 48.21977358764123]],
    [ "Energie AG",[13.956269587213919, 48.63382027189392]],
    [ "Wiener Netze",[16.298389699999998, 48.139953049999995]],
    [ "Wiener Netze",[16.364066399940796, 48.11590729991011]],
    [ "TST Hirschwang Siedlung Trautenberg",[15.81785276658748, 47.70346450049988]],
    [ "1022835369",[11.757262748779734, 47.38930467192868]],
    [ "Linz AG",[14.5556476, 48.245995549999996]],
    [ "KNG-Kärnten Netz GmbH",[14.282026167687777, 46.58044331569848]],
    [ "TINETZ",[11.811220500000001, 47.40407609999999]],
    [ "Wiener Netze",[16.26359396587608, 48.01453299222283]],
    [ "Energie AG",[13.994913293358897, 48.574784902546675]],
    [ "Weißbacher-Straße",[12.068187958445952, 47.488445983079]],
    [ "Energie AG",[13.006909436791556, 48.12496785395245]],
    [ "Umspannwerk Wels Mitte",[14.0295676, 48.161343349999996]],
    [ "Umspannwerk WAV",[14.074691635112872, 48.1700225533884]],
    [ "Energie Ried",[13.422436572185212, 48.189410386696736]],
    [ "Wiener Netze",[16.308627374157147, 48.030192316994345]],
    [ "Umspannwerk Linz Ost",[14.331228446269689, 48.28799743269726]],
    [ "Linz Netz",[14.22672315, 48.268572600000006]],
    [ "Wiener Netze",[16.28600783386135, 48.03278450668821]],
    [ "Wiener Netze",[16.285210096504187, 48.032323215411125]],
    [ "Netz NÖ",[16.056497180398644, 47.998827525289386]],
    [ "Energie AG",[12.946976959787632, 48.093807787055596]],
    [ "TST Enzenteith Siedlung",[15.9461136591264, 47.66492191265472]],
    [ "Netz Niederösterreich",[15.965861200541708, 47.65227746648608]],
    [ "Wiener Netze",[16.3954742, 47.915980450000006]],
    [ "Wiener Netze",[16.35480525, 48.072608349999996]],
    [ "Wiener Netze",[16.378549263566384, 48.0335829757953]],
    [ "Wiener Netze",[16.37847926698069, 48.02574870065131]],
    [ "Wiener Netze",[16.412984677625246, 48.0322549469155]],
    [ "Wiener Netze",[16.42315409497615, 48.03491865408753]],
    [ "Wiener Netze",[16.430676135545216, 48.03648766110384]],
    [ "Wiener Netze GmbH",[16.41092218378059, 47.951033500666234]],
    [ "Wiener Netze",[16.352270100000002, 48.117437100000004]],
    [ "Wiener Netze",[16.394557087177823, 47.89646595127429]],
    [ "Wiener Netze",[16.392224083322454, 47.90371024886242]],
    [ "Ostermiething Krankenhaus",[12.8312823027639, 48.043873723589655]],
    [ "Ostermiething Unterfeld",[12.834859547403696, 48.039612347860505]],
    [ "Umspannwerk Ottenstein",[15.332079795128783, 48.59241168286135]],
    [ "Wiener Netze",[16.4114750827262, 48.03724015927543]],
    [ "Wiener Netze",[16.385215146574552, 48.029956673632114]],
    [ "Wiener Netze",[16.40591326771617, 48.02488499344701]],
    [ "Wiener Netze",[16.396101299999994, 48.08323015]],
    [ "Wiener Netze",[16.3687825, 48.08148955000001]],
    [ "TST Putzmannsdorf 2",[16.0022289, 47.692154900000006]],
    [ "TST Putzmannsdorf Schöpfwerkstraße",[15.99611525, 47.689498300000004]],
    [ "Umspannwerk Bruckneudorf",[16.860399665316628, 48.020813924380164]],
    [ "Wiener Netze",[16.54268930194095, 48.07937909507256]],
    [ "Netz NÖ",[16.51012125372157, 47.97775948281522]],
    [ "TST St. Valentin Höller",[16.017548959928096, 47.69420235477636]],
    [ "Wiener Netze",[16.410399784577006, 47.93894223283443]],
    [ "Netz NÖ",[16.01715945456781, 47.691587683171356]],
    [ "Netz NÖ",[16.068432756772456, 47.64357907489329]],
    [ "Netz Niederösterreich GmbH",[16.076127255052086, 47.59330899164076]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[16.031604288698315, 47.60439070581404]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[16.01776536858967, 47.60848711839275]],
    [ "Feistritzwerke Dechantskirchen",[16.01548034466479, 47.41637390756528]],
    [ "Wiener Netze",[16.385858250000002, 48.0735965]],
    [ "Wiener Netze",[16.382355099999998, 48.05060340000001]],
    [ "Nauders",[10.506593089675452, 46.85874433942254]],
    [ "Umspannwerk Steinfelden",[13.989957000198125, 47.92360972357603]],
    [ "Netz NÖ",[16.054963727978308, 47.65297560477887]],
    [ "TST Wimpassing Dammstraße",[16.023234636790267, 47.70239420712614]],
    [ "Oberregau Gewerbegebiet",[13.658955033003117, 47.99033885754336]],
    [ "Wiener Netze",[16.285447696783017, 47.988189146255884]],
    [ "TST Pottschach Am Gratzer",[16.003834650000005, 47.70564935000001]],
    [ "TST Pottschach Altstraße",[16.006230250000005, 47.70200890000001]],
    [ "TST Dunkelstein Ternitz Pfandwiesengasse",[16.033520893876943, 47.70658123438627]],
    [ "Umspannwerk Pongau",[13.21075267005837, 47.378945112816716]],
    [ "1051045930",[14.625334328965236, 48.54255094802756]],
    [ "Netz Burgenland",[16.377151190514034, 47.81475308716512]],
    [ "TST St. Johann Ternitz Gfiederstraße",[16.024190623158397, 47.71459347990004]],
    [ "Engergie AG",[13.844872744187384, 48.607510009406276]],
    [ "Energie AG",[13.437158218205912, 48.338495288033855]],
    [ "Wiener Netze",[16.35865235, 48.111757]],
    [ "TST Grünsting Edlach Bad",[15.806826349999996, 47.69040615]],
    [ "1052573672",[14.01994305, 47.97727435]],
    [ "Umspannwerk Gratkorn",[15.328250227033667, 47.1316725250831]],
    [ "Weinzödl Schottergrube",[15.389888389025446, 47.11034415079362]],
    [ "BKST Brixlegg Innbrücke",[11.883249099999999, 47.43804145]],
    [ "BST Brixlegg Niederfeldweg",[11.878878261522782, 47.4351087734869]],
    [ "Wiener Netze",[16.304720784738947, 47.8493523966941]],
    [ "Wiener Netze",[16.375715941164795, 47.873076368190254]],
    [ "Wiener Netze",[16.366079016728943, 47.8765493928509]],
    [ "Wiener Netze",[16.363117449999997, 47.880836849999994]],
    [ "Netz Burgenland",[16.431409649999996, 47.91438159999999]],
    [ "TST Gloggnitz Zeile",[15.942590606136784, 47.676877817644]],
    [ "TST Gloggnitz Bahnhof",[15.9457713, 47.67695679999999]],
    [ "TST Gloggnitz Bahnhofstraße",[15.94016545, 47.67576574999999]],
    [ "Lorettostraße",[16.433873196433478, 47.91738668409553]],
    [ "ALFA",[16.433968893210068, 47.921187945524814]],
    [ "Netz Burgenland",[16.436702649999997, 47.9258719]],
    [ "KNG-Kärnten Netz GmbH",[14.545132426456224, 46.77162375497445]],
    [ "KNG-Kärnten Netz GmbH",[14.52032430927346, 46.721274772450556]],
    [ "Kärnten Netz",[14.488721837949113, 46.687367147725496]],
    [ "KNG-Kärnten Netz GmbH",[14.497894010103753, 46.69845070843073]],
    [ "KNG-Kärnten Netz GmbH",[14.4894322811703, 46.71367994659367]],
    [ "KNG-Kärnten Netz GmbH",[14.50480270505332, 46.70479202684477]],
    [ "KNG-Kärnten Netz GmbH",[14.516701093791076, 46.642944203381106]],
    [ "Hum",[14.525042325699326, 46.63152426351358]],
    [ "KNG-Kärnten Netz GmbH",[14.537857200000003, 46.63891435000001]],
    [ "KNG-Kärnten Netz GmbH",[14.533627296375641, 46.61979769608448]],
    [ "KNG-Kärnten Netz GmbH",[14.51666150018076, 46.61878260526791]],
    [ "KNG-Kärnten Netz GmbH",[14.50551915, 46.617777999999994]],
    [ "KNG-Kärnten Netz GmbH",[14.571477146841582, 46.638458098433276]],
    [ "KNG-Kärnten Netz GmbH",[14.538980467798169, 46.63291009697638]],
    [ "Energie AG",[13.88631591065135, 48.7380051676466]],
    [ "KNG-Kärnten Netz GmbH",[14.591179249999998, 46.67772660000001]],
    [ "KNG-Kärnten Netz GmbH",[14.581748850390927, 46.69956950017379]],
    [ "KNG-Kärnten Netz GmbH",[14.575630229799335, 46.67737783581106]],
    [ "KNG-Kärnten Netz GmbH",[14.5569079, 46.690615949999994]],
    [ "KNG-Kärnten Netz GmbH",[14.561329912401963, 46.69441179750563]],
    [ "KNG-Kärnten Netz GmbH",[14.544437451257854, 46.695903270437974]],
    [ "KNG-Kärnten Netz GmbH",[14.547061594163011, 46.710264451155325]],
    [ "KNG-Kärnten Netz GmbH",[14.590767020796765, 46.64868345000629]],
    [ "KNG-Kärnten Netz GmbH",[14.57660015, 46.6521876]],
    [ "KNG-Kärnten Netz GmbH",[14.579105186450992, 46.66419961967629]],
    [ "Umspannwerk Retznei",[15.574319813119544, 46.73632508020081]],
    [ "Umspannwerk Pirka",[15.408767902680184, 47.00518523365535]],
    [ "Obere Teichstraße Lindensiedlung",[15.474793801921013, 47.069482223579534]],
    [ "Umspannwerk Vorkloster",[9.720635718896448, 47.49477599784419]],
    [ "Netz Niederösterreich GmbH",[15.99121115, 47.792383799999996]],
    [ "Netz NÖ",[16.5788161, 48.0099722]],
    [ "Netz NÖ",[16.5787175, 48.01401765]],
    [ "Netz Niederösterreich",[16.136389521774007, 47.53763314379166]],
    [ "Netz Niederösterreich GmbH",[16.24257486132021, 47.62754385492389]],
    [ "Netz Niederösterreich GmbH",[16.25836846227667, 47.620246924465626]],
    [ "Netz Niederösterreich GmbH",[16.302445674071738, 47.60550899433245]],
    [ "Ennskraftwerke AG",[14.433905042913853, 47.92319451283167]],
    [ "Wiener Netze",[16.2540186, 47.9580149]],
    [ "Raab",[13.6241304897158, 48.3541033104519]],
    [ "Falzgraben",[15.362784150347503, 46.99500593481979]],
    [ "Roseggerstraße",[9.652990959981569, 47.43163422028699]],
    [ "Montfortstraße",[9.654636601196898, 47.43403428734218]],
    [ "Schaltanlage Kopswerk II",[10.043312239792426, 46.97431035259217]],
    [ "Wiener Netze",[16.305451400000006, 48.02084275]],
    [ "Bahnhof Ost",[9.668094344835765, 47.45142038103671]],
    [ "Wiener Netze",[16.3200275, 48.04892045]],
    [ "Hornstein Sonnenberg",[16.451190954041515, 47.8802624631695]],
    [ "Oberlatein Kleinradl",[15.278689802358084, 46.674137850210265]],
    [ "1068247294",[15.439599084725666, 46.740411000515174]],
    [ "1068311623",[15.406648439185766, 46.75614275052915]],
    [ "Priesergraben",[15.390122488093285, 46.73172854544197]],
    [ "Energie AG",[13.90645072768778, 48.675813254837145]],
    [ "Energie AG",[13.9164122, 48.6726096]],
    [ "Unterer Breitenweg 43",[15.48261316954198, 47.06092350730765]],
    [ "TST Pottschach Ternitzerstraße",[16.023592133068142, 47.708247350524665]],
    [ "Umspannwerk Graz Thondorf",[15.477319844186722, 47.01750049238565]],
    [ "Kollonitschstrasse",[15.481153994223076, 47.08671251600256]],
    [ "Wiener Netze GmbH",[16.346482420916598, 48.06466699426649]],
    [ "Wiener Netze GmbH",[16.383437332912298, 48.08293039844775]],
    [ "Wiener Netze GmbH",[16.380705599999995, 48.0804021]],
    [ "1072327657",[15.91027725210076, 46.71929117091731]],
    [ "Wiener Netze",[16.398514997334868, 47.91280198323682]],
    [ "1072564431",[15.848927510719149, 46.73066414742251]],
    [ "1072564433",[15.838860180117399, 46.731188339529645]],
    [ "Wiener Netze GmbH",[16.38016603049961, 47.87027276028848]],
    [ "Wiener Netze GmbH",[16.384014750000002, 47.87532825]],
    [ "Wiener Netze GmbH",[16.355453189409644, 48.07538232888117]],
    [ "Wiener Netze",[16.38392219037386, 48.04114921871293]],
    [ "Wiener Netze",[16.382984733566023, 48.0396245778319]],
    [ "Wiener Netze GmbH",[16.37887615, 48.0358648]],
    [ "Wiener Netze GmbH",[16.3514333, 48.109315200000005]],
    [ "Wiener Netze",[16.365358242492103, 48.08215726987866]],
    [ "Wiener Netze",[16.39877142010984, 47.962422683787615]],
    [ "Wiener Netze",[16.411137816278263, 47.94409114966602]],
    [ "Wiener Netze",[16.176056699999997, 47.93004335]],
    [ "1075082310",[16.610560397427115, 47.80422948148457]],
    [ "1075082311",[16.64328564358624, 47.800584428646204]],
    [ "1075082312",[16.61628737784229, 47.79962560889986]],
    [ "1075087327",[16.678783217185913, 47.839925009748434]],
    [ "1075196089",[16.79368247307284, 47.763410670033515]],
    [ "Energie Burgenland",[16.802573950000003, 47.7668047]],
    [ "1075196092",[16.82706539661338, 47.852539015295896]],
    [ "1075532837",[15.937615792388074, 46.76376473981217]],
    [ "1075590082",[15.905481061632543, 46.864777324471106]],
    [ "Wiener Netze",[16.45202041172965, 48.016958374273344]],
    [ "Wiener Netze",[16.323769199999997, 48.3071357]],
    [ "Wiener Netze",[16.336851366470125, 48.034031742003236]],
    [ "Wiener Netze",[16.251238106802013, 47.936060713138]],
    [ "TST Rohrbach Unterternitz",[16.03907493185152, 47.71988655612129]],
    [ "TST Reichenau Gahnsstraße",[15.833497728019948, 47.69926595910438]],
    [ "Wiener Netze",[16.383370420563427, 48.07965072931629]],
    [ "Wiener Netze",[16.35512798415316, 48.068409496336635]],
    [ "Wiener Netze",[16.321930818902647, 48.04486851776039]],
    [ "Wiener Netze",[16.42699995614545, 48.363529600600295]],
    [ "Wiener Netze",[16.22517905, 47.98978925]],
    [ "Wiener Netze",[16.225680533544192, 47.9927918118343]],
    [ "Wiener Netze",[16.225409984087605, 47.99165758249454]],
    [ "Wiener Netze",[16.30855553413471, 48.076912931244024]],
    [ "Wiener Netze",[16.337541973625243, 48.06133350616614]],
    [ "Wiener Netze",[16.31582315, 48.07394834999999]],
    [ "Wiener Netze",[16.329288029670224, 48.06576855521288]],
    [ "Wiener Netze",[16.31252615, 48.08121145]],
    [ "Wiener Netze",[16.3310442, 48.062261449999994]],
    [ "Wiener Netze",[16.33799752772844, 48.06190638638812]],
    [ "Wiener Netze",[16.325040877599218, 48.07731468987401]],
    [ "Wiener Netze",[16.325944981786677, 48.0739210011243]],
    [ "Wiener Netze",[16.312607773491454, 48.07348074044973]],
    [ "Wiener Netze",[16.31961455, 48.07762775]],
    [ "Wiener Netze",[16.328318300000003, 48.063558699999994]],
    [ "Wiener Netze",[16.3316669695921, 48.07548954572308]],
    [ "Wiener Netze",[16.322009238647965, 48.074898959524724]],
    [ "Wiener Netze",[16.317881769559417, 48.079927292228554]],
    [ "Wiener Netze",[16.426495646454462, 48.101540653444076]],
    [ "Wiener Netze",[16.3109004, 48.10573174999999]],
    [ "Wiener Netze",[16.29060201863619, 48.0756551809358]],
    [ "Wiener Netze",[16.4182923, 48.025502249999995]],
    [ "Wiener Netze GmbH",[16.415894429060184, 48.02290065872074]],
    [ "Wiener Netze",[16.304603335303106, 48.04234043506672]],
    [ "Wiener Netze",[16.310656736483615, 48.05157074230447]],
    [ "Wiener Netze",[16.31977208260766, 48.0509084526007]],
    [ "Wiener Netze",[16.317050322685947, 48.047422239300765]],
    [ "Wiener Netze",[16.319620445903684, 48.06743083071653]],
    [ "Wiener Netze",[16.30535011024686, 48.04720866993493]],
    [ "Wiener Netze",[16.292357384488394, 48.036297502659956]],
    [ "Wiener Netze",[16.314487426402863, 48.04349269735877]],
    [ "Wiener Netze",[16.331129482327636, 48.05649704912996]],
    [ "Wiener Netze",[16.306669616843642, 48.07471064830181]],
    [ "Wiener Netze",[16.32768605, 48.06156325]],
    [ "Wiener Netze",[16.279212282189615, 48.040148382065]],
    [ "Wiener Netze",[16.443251619561845, 48.08268172929733]],
    [ "Netz Niederösterreich",[16.09407365, 47.55847700000001]],
    [ "TST Aspang Hauptplatz",[16.090738134103702, 47.55382459723951]],
    [ "Netz Niederösterreich",[16.09908798577576, 47.57423477712259]],
    [ "Netz Niederösterreich",[16.100205927092993, 47.5845949587723]],
    [ "Netz Niederösterreich",[16.10783946691701, 47.59356995046698]],
    [ "TST Aspang Schulgasse",[16.08982251520039, 47.554899804683345]],
    [ "TST Krichberg Friedhof",[15.983533700000002, 47.61194515]],
    [ "TST Feistritz OBM",[16.040906800000002, 47.60163455]],
    [ "1080794647",[15.987664200000001, 47.64136834999999]],
    [ "Wiener Netze",[16.270479515469493, 48.00535385825919]],
    [ "Netz Niederösterreich GmbH",[15.897038977317212, 47.65931005299897]],
    [ "Netz Niederösterreich GmbH",[15.878568021309402, 47.64248230253715]],
    [ "Netz NÖ",[15.830876632849053, 47.63763100099583]],
    [ "Wiener Netze",[16.275245412830646, 48.015334835838125]],
    [ "Wiener Netze",[16.28513208382311, 48.02553550492441]],
    [ "Netz Niederösterreich",[16.20602232375961, 47.78557901695722]],
    [ "Netz Niederösterreich",[16.237200267640738, 47.79974606696631]],
    [ "Netz Niederösterreich",[16.23244528320037, 47.80327704962268]],
    [ "Netz Niederösterreich",[16.235543078807606, 47.807419303514486]],
    [ "Netz Niederösterreich",[16.22204995, 47.79483015]],
    [ "Netz Niederösterreich",[16.224757566259612, 47.79343479590231]],
    [ "Netz Niederösterreich",[16.23456231607906, 47.80520582365513]],
    [ "Netz Niederösterreich",[16.227244566060694, 47.79953924895948]],
    [ "Netz Niederösterreich",[16.2258071, 47.79469050000001]],
    [ "Wiener Netze",[16.30144207481144, 48.0267364655954]],
    [ "Wiener Netze",[16.30679329966118, 48.02463987809766]],
    [ "KNG-Kärnten Netz GmbH",[14.888754823139108, 46.64330719760228]],
    [ "Messendorf Theaterwerkstätte",[15.48143974169088, 47.04792974709576]],
    [ "Wiener Netze",[16.328917010098387, 48.073839669647356]],
    [ "Wiener Netze",[16.34495651869509, 48.08355082194761]],
    [ "Wiener Netze",[16.351282050000002, 48.069967749999996]],
    [ "Wiener Netze",[16.302008899999997, 48.111140250000005]],
    [ "Wiener Netze",[16.29328802585829, 48.11254559416809]],
    [ "Wiener Netze",[16.2998843, 48.1089077]],
    [ "Wiener Netze",[16.32651676944638, 48.06454394676404]],
    [ "Wiener Netze",[16.334254616299116, 48.06682988544494]],
    [ "BST Kramsach Bundesforste",[11.8673653, 47.43514269999999]],
    [ "Wiener Netze",[16.208872623581446, 48.03828405950996]],
    [ "Wiener Netze",[16.30872975, 48.04757954999999]],
    [ "KNG-Kärnten Netz GmbH",[14.03552937546406, 46.5822806577373]],
    [ "BST Schlitters Gewerbegebiet Nord",[11.832536805944756, 47.38148028555003]],
    [ "Wiener Netze",[16.333272049999998, 48.07207235]],
    [ "Wiener Netze",[16.33590450303174, 48.06937762423811]],
    [ "TST Katzelsdorf Nord",[16.267821373347484, 47.78440359512259]],
    [ "Wiener Netze",[16.304471684646277, 48.0437143799824]],
    [ "Wiener Netze",[16.315844622296062, 48.05237434664601]],
    [ "Kaiserwald II",[15.406893983208382, 46.937894174600565]],
    [ "TST Neunkirchen Flatzerstraße",[16.06951017345743, 47.727472353864485]],
    [ "Wiener Netze",[16.386582100000002, 48.083204450000004]],
    [ "Oskar Mulley Straße",[12.177501566904422, 47.58484520892381]],
    [ "Unterwerk Untersiebenbrunn",[16.75540073134609, 48.248687031584915]],
    [ "Wiener Netze",[16.286404913016764, 47.96582393557392]],
    [ "Energie AG",[13.942995892426604, 48.66619903022064]],
    [ "Wiener Netze GmbH",[16.25604925, 47.959604]],
    [ "KNG-Kärnten Netz GmbH",[14.511566346312799, 46.57427967542344]],
    [ "Wiener Netze",[16.39682314853571, 47.90638856571679]],
    [ "Wiener Netze",[16.30096057746612, 48.111080228260846]],
    [ "Wiener Netze",[16.354600849999997, 48.085897700000004]],
    [ "Wiener Netze",[16.396025458392533, 47.90042041447996]],
    [ "KNG-Kärnten Netz GmbH",[14.544916109090572, 46.550370948139026]],
    [ "KNG-Kärnten Netz GmbH",[14.527751971876409, 46.54878752849122]],
    [ "KNG-Kärnten Netz GmbH",[14.514282434798359, 46.547818793152324]],
    [ "KNG-Kärnten Netz GmbH",[14.50531371526451, 46.5590134863403]],
    [ "KNG-Kärnten Netz GmbH",[14.50297545012567, 46.562918222686264]],
    [ "KNG-Kärnten Netz GmbH",[14.569488301972717, 46.568116221672284]],
    [ "KNG-Kärnten Netz GmbH",[14.546986943399451, 46.56556648536922]],
    [ "Energienetze Steiermark",[15.022237760189306, 47.39727509597097]],
    [ "Wiener Netze",[16.319269699999996, 48.11450999999999]],
    [ "Wiener Netze",[16.29371835, 48.118981049999995]],
    [ "Wiener Netze",[16.293640500000002, 48.11140310000001]],
    [ "1088173888",[14.269346320984775, 48.332441846506185]],
    [ "Wiener Netze",[16.37886327166069, 48.04189729147636]],
    [ "KNG-Kärnten Netz GmbH",[14.565451440117277, 46.59168580804325]],
    [ "KNG-Kärnten Netz GmbH",[14.564312618558091, 46.585491494135574]],
    [ "KNG-Kärnten Netz GmbH",[14.544188054494755, 46.60908485220108]],
    [ "KNG-Kärnten Netz GmbH",[14.543942787808994, 46.58158683136754]],
    [ "KNG-Kärnten Netz GmbH",[14.563744171215632, 46.577038239271914]],
    [ "Wiener Netze",[16.31875742681759, 48.08324279622088]],
    [ "Wiener Netze",[16.3185153, 48.08830274999999]],
    [ "Wiener Netze",[16.315906825308975, 48.087979818503996]],
    [ "Wiener Netze",[16.319241370217085, 48.086102874602346]],
    [ "KNG-Kärnten Netz GmbH",[14.601299096692982, 46.58216183554407]],
    [ "KNG-Kärnten Netz GmbH",[14.583227234464015, 46.59697015404256]],
    [ "KNG-Kärnten Netz GmbH",[14.58948237112254, 46.59397110019963]],
    [ "KNG-Kärnten Netz GmbH",[14.586056627430006, 46.592505191809806]],
    [ "KNG-Kärnten Netz GmbH",[14.582676938832725, 46.590067006487175]],
    [ "KNG-Kärnten Netz GmbH",[14.830105137691302, 46.56933397684813]],
    [ "KNG-Kärnten Netz GmbH",[14.816973713768245, 46.54370171237695]],
    [ "KNG-Kärnten Netz GmbH",[14.805654951482696, 46.55838946225932]],
    [ "KNG-Kärnten Netz GmbH",[14.775922234027943, 46.57975190428262]],
    [ "KNG-Kärnten Netz GmbH",[14.780840202843537, 46.576525497345244]],
    [ "KNG-Kärnten Netz GmbH",[14.771110334350405, 46.5761602241848]],
    [ "KNG-Kärnten Netz GmbH",[14.7518389, 46.558425549999995]],
    [ "KNG-Kärnten Netz GmbH",[14.758479685832, 46.5621618292055]],
    [ "KNG-Kärnten Netz GmbH",[14.727903250352332, 46.58224055224234]],
    [ "KNG-Kärnten Netz GmbH",[14.724351719761614, 46.57857527452757]],
    [ "KNG-Kärnten Netz GmbH",[14.73044285, 46.55706285]],
    [ "KNG-Kärnten Netz GmbH",[14.606104388773105, 46.54324074198941]],
    [ "KNG-Kärnten Netz GmbH",[14.702962638382196, 46.55241345128578]],
    [ "KNG-Kärnten Netz GmbH",[14.645260374436795, 46.549294153826345]],
    [ "KNG-Kärnten Netz GmbH",[14.633780329730634, 46.55385837363775]],
    [ "KNG-Kärnten Netz GmbH",[14.669478194117758, 46.56293440426446]],
    [ "Wiener Netze",[16.42736261571591, 48.0896612512722]],
    [ "Wiener Netze",[16.426984102450547, 48.08945393298155]],
    [ "Wiener Netze",[16.42643653884152, 48.087698269587904]],
    [ "Wiener Netze",[16.428413100000004, 48.08514325]],
    [ "Wiener Netze",[16.43060172168385, 48.08189660960488]],
    [ "Wiener Netze",[16.43705365, 48.088615100000005]],
    [ "Wiener Netze",[16.441747149999998, 48.0875963]],
    [ "Wiener Netze",[16.450499899999997, 48.08392105]],
    [ "Wiener Netze",[16.447501799999998, 48.08544379999999]],
    [ "Wiener Netze",[16.44952677591085, 48.079649293837214]],
    [ "Wiener Netze",[16.44927632470764, 48.0795355591131]],
    [ "Wiener Netze",[16.44929095, 48.07945765]],
    [ "Wiener Netze",[16.45057251951383, 48.07743639613763]],
    [ "Wiener Netze",[16.454942635611218, 48.072578282676375]],
    [ "TST Stuppach Neusiedlerstraße",[15.971843668783618, 47.67911565218431]],
    [ "TST Stuppach Nord",[15.963111132078211, 47.683764551587]],
    [ "TST Gloggnitz Dittelbachstraße",[15.945552134940263, 47.67611894709748]],
    [ "TST Stuppach Sandgasse",[15.96427875, 47.68079705000002]],
    [ "Netz Niederösterreich",[15.956094499999999, 47.67283995]],
    [ "TST Wörth Voith",[15.968753469542321, 47.67322790289982]],
    [ "TST Stuppach Siedlau",[15.976527142565738, 47.679536634232214]],
    [ "KNG-Kärnten Netz GmbH",[14.598771224040021, 46.54203060349776]],
    [ "KNG-Kärnten Netz GmbH",[14.6014207, 46.546804599999994]],
    [ "KNG-Kärnten Netz GmbH",[14.597516824607462, 46.54892000820619]],
    [ "TST Bad Fischau Fridehof",[16.161661282764104, 47.82983723051781]],
    [ "Netz Niederösterreich",[16.16511227670853, 47.834188460182524]],
    [ "KNG-Kärnten Netz GmbH",[14.662728154805793, 46.592208201798336]],
    [ "KNG-Kärnten Netz GmbH",[14.62274297383174, 46.603061685851785]],
    [ "KNG-Kärnten Netz GmbH",[14.656071961616082, 46.58784594111494]],
    [ "KNG-Kärnten Netz GmbH",[14.649823914280809, 46.58534525584007]],
    [ "KNG-Kärnten Netz GmbH",[14.648365379927453, 46.58529611411493]],
    [ "KNG-Kärnten Netz GmbH",[14.62312400407259, 46.57561626191419]],
    [ "KNG-Kärnten Netz GmbH",[14.634237385890742, 46.571708675941345]],
    [ "KNG-Kärnten Netz GmbH",[14.698077965471118, 46.56880285184438]],
    [ "KNG-Kärnten Netz GmbH",[14.707080703150517, 46.566972003072436]],
    [ "KNG-Kärnten Netz GmbH",[14.61293416378211, 46.60191031954452]],
    [ "KNG-Kärnten Netz GmbH",[14.590981599388519, 46.620675669386216]],
    [ "KNG-Kärnten Netz GmbH",[14.565000090951273, 46.614858983211775]],
    [ "KNG-Kärnten Netz GmbH",[14.559660136560662, 46.61696203595339]],
    [ "KNG-Kärnten Netz GmbH",[14.565056238218498, 46.620764020379475]],
    [ "KNG-Kärnten Netz GmbH",[14.768728336033277, 46.598219925154666]],
    [ "KNG-Kärnten Netz GmbH",[14.737139500000001, 46.57997550000001]],
    [ "KNG-Kärnten Netz GmbH",[14.750484924305962, 46.580629704357676]],
    [ "KNG-Kärnten Netz GmbH",[14.611314783557274, 46.61669509134089]],
    [ "KNG-Kärnten Netz GmbH",[14.613469816817831, 46.61918992119827]],
    [ "KNG-Kärnten Netz GmbH",[14.60800654097301, 46.62027029564112]],
    [ "KNG-Kärnten Netz GmbH",[14.607401898579814, 46.62609770013409]],
    [ "KNG-Kärnten Netz GmbH",[14.599229919353473, 46.623305262852035]],
    [ "Wiener Netze",[16.33777665, 48.03015824999999]],
    [ "TST Lichtenwörth Siedlung",[16.299430475822128, 47.83023908995985]],
    [ "Wiener Netze",[16.352225482049604, 47.8978430972031]],
    [ "Feuerwehr",[16.40867243391727, 47.81496671808017]],
    [ "Netz Niederösterreich",[16.318443994880802, 47.82471208377557]],
    [ "TST Wr. Neustadt Heideansiedlung",[16.209794483218474, 47.867266351860685]],
    [ "Wiener Netze",[16.548824106347247, 48.20554363847491]],
    [ "Netz Niederösterreich",[16.2461929, 47.84260475]],
    [ "Netz Niederösterreich",[16.24673495, 47.84200195000001]],
    [ "Wiener Netze",[16.32276065, 47.86023519999999]],
    [ "KNG-Kärnten Netz GmbH",[14.634589981572507, 46.62580974047459]],
    [ "KNG-Kärnten Netz GmbH",[14.625543712206325, 46.631297562996565]],
    [ "KNG-Kärnten Netz GmbH",[14.635562139239353, 46.63961029750415]],
    [ "KNG-Kärnten Netz GmbH",[14.740841420568113, 46.61335244842974]],
    [ "KNG-Kärnten Netz GmbH",[14.713730163786591, 46.60547346674288]],
    [ "KNG-Kärnten Netz GmbH",[14.717811498354713, 46.61668410975739]],
    [ "KNG-Kärnten Netz GmbH",[14.702098551798969, 46.61832504980642]],
    [ "KNG-Kärnten Netz GmbH",[14.689588195846873, 46.61962117441122]],
    [ "KNG-Kärnten Netz GmbH",[14.771533159455382, 46.61790171953691]],
    [ "KNG-Kärnten Netz GmbH",[14.770478974674663, 46.62233782119621]],
    [ "KNG-Kärnten Netz GmbH",[14.765603691853554, 46.62341668154527]],
    [ "KNG-Kärnten Netz GmbH",[14.767102995417895, 46.611544211564684]],
    [ "KNG-Kärnten Netz GmbH",[14.742338571869468, 46.622297239670566]],
    [ "KNG-Kärnten Netz GmbH",[14.803165455271785, 46.620027639792994]],
    [ "KNG-Kärnten Netz GmbH",[14.810245193886187, 46.628818993995786]],
    [ "KNG-Kärnten Netz GmbH",[14.818016704794474, 46.63487609019463]],
    [ "KNG-Kärnten Netz GmbH",[14.829091814927065, 46.60882893052217]],
    [ "KNG-Kärnten Netz GmbH",[14.801753822349587, 46.609753660528945]],
    [ "KNG-Kärnten Netz GmbH",[14.80039258553327, 46.60211412911976]],
    [ "KNG-Kärnten Netz GmbH",[14.793890350000002, 46.5911662]],
    [ "KNG-Kärnten Netz GmbH",[14.796593799999998, 46.58721089999999]],
    [ "KNG-Kärnten Netz GmbH",[14.79867898402864, 46.58394239035446]],
    [ "KNG-Kärnten Netz GmbH",[14.794629550000002, 46.58490650000001]],
    [ "Landfraß KW Gmünd",[13.527095194574537, 46.894832728987566]],
    [ "KNG-Kärnten Netz GmbH",[14.877062400000002, 46.658054]],
    [ "KNG-Kärnten Netz GmbH",[14.852085846982265, 46.64983118213047]],
    [ "KNG-Kärnten Netz GmbH",[14.846559649085105, 46.64345730272782]],
    [ "KNG-Kärnten Netz GmbH",[14.864324949148154, 46.64708810283332]],
    [ "KNG-Kärnten Netz GmbH",[14.941671286788786, 46.62536792481976]],
    [ "KNG-Kärnten Netz GmbH",[14.93024544152388, 46.64340435343647]],
    [ "KNG-Kärnten Netz GmbH",[14.919122405660726, 46.647808419086445]],
    [ "KNG-Kärnten Netz GmbH",[14.908287609899098, 46.62316649383222]],
    [ "KNG-Kärnten Netz GmbH",[14.644848549999999, 46.66002745000001]],
    [ "KNG-Kärnten Netz GmbH",[14.675014310296453, 46.67075195247712]],
    [ "KNG-Kärnten Netz GmbH",[14.648078600012287, 46.68061304922296]],
    [ "KNG-Kärnten Netz GmbH",[14.61098548362455, 46.65736879368558]],
    [ "KNG-Kärnten Netz GmbH",[14.627831350000001, 46.65535565]],
    [ "1092112942",[14.315354497078587, 48.308799652917614]],
    [ "KNG-Kärnten Netz GmbH",[14.59671253162386, 46.692975976730686]],
    [ "KNG-Kärnten Netz GmbH",[14.596017970664633, 46.727747513368165]],
    [ "KNG-Kärnten Netz GmbH",[14.612512966241026, 46.72023537730547]],
    [ "KNG-Kärnten Netz GmbH",[14.637483509636418, 46.705462056279565]],
    [ "KNG-Kärnten Netz GmbH",[14.63636966042256, 46.69111313686372]],
    [ "KNG-Kärnten Netz GmbH",[14.617218450621106, 46.67395900016818]],
    [ "KNG-Kärnten Netz GmbH",[14.665097574135178, 46.69579270883632]],
    [ "KNG-Kärnten Netz GmbH",[14.672608253008965, 46.717695028004286]],
    [ "KNG-Kärnten Netz GmbH",[14.617255508088512, 46.745857378609124]],
    [ "KNG-Kärnten Netz GmbH",[14.65914515493383, 46.747042716940165]],
    [ "KNG-Kärnten Netz GmbH",[14.73347758166822, 46.715145460573886]],
    [ "KNG-Kärnten Netz GmbH",[14.725797099999998, 46.69918719999999]],
    [ "KNG-Kärnten Netz GmbH",[14.688998500000002, 46.6776076]],
    [ "KNG-Kärnten Netz GmbH",[14.701253447973242, 46.68886251458847]],
    [ "KNG-Kärnten Netz GmbH",[14.699925357192853, 46.67054352210243]],
    [ "KNG-Kärnten Netz GmbH",[14.692075596110158, 46.65439862176211]],
    [ "KNG-Kärnten Netz GmbH",[14.682289053119879, 46.66107145617681]],
    [ "KNG-Kärnten Netz GmbH",[14.716755785878526, 46.650591884047586]],
    [ "KNG-Kärnten Netz GmbH",[14.661823847568394, 46.64510970254765]],
    [ "KNG-Kärnten Netz GmbH",[14.732633238574957, 46.642099249666096]],
    [ "KNG-Kärnten Netz GmbH",[14.727283588584836, 46.65731989937045]],
    [ "KNG-Kärnten Netz GmbH",[14.710707923173677, 46.65393030803823]],
    [ "KNG-Kärnten Netz GmbH",[14.750827128641134, 46.68739280977301]],
    [ "KNG-Kärnten Netz GmbH",[14.749627647848953, 46.696926507584415]],
    [ "KNG-Kärnten Netz GmbH",[14.76651326969707, 46.68131219175229]],
    [ "KNG-Kärnten Netz GmbH",[14.752510706699715, 46.67040963736285]],
    [ "KNG-Kärnten Netz GmbH",[14.768405522093936, 46.65742489329257]],
    [ "KNG-Kärnten Netz GmbH",[14.781481698056913, 46.687740546868376]],
    [ "KNG-Kärnten Netz GmbH",[13.79985395290549, 46.646792044296625]],
    [ "Wiener Netze",[16.28561015, 48.0270076]],
    [ "Wiener Netze",[16.269228320573976, 48.11816890402197]],
    [ "Wiener Netze",[16.486461947928618, 47.96984943021292]],
    [ "Debant",[12.819206160549015, 46.82862956342997]],
    [ "Niederdorf Süd",[14.40706705, 46.62208165]],
    [ "Einsteinstraße",[14.395074300000001, 46.618028699999996]],
    [ "Energie Klagenfurt GmbH",[14.313146406949311, 46.600657820470204]],
    [ "KNG-Kärnten Netz GmbH",[14.038578423296832, 46.585536843938215]],
    [ "Mallnitz Ost",[13.1756144988897, 46.98348982233501]],
    [ "KNG-Kärnten Netz GmbH",[13.888805208738725, 46.61392896972943]],
    [ "Wiener Netze",[16.220455724514547, 47.97818653999631]],
    [ "Wiener Netze",[16.21240695184577, 48.007462140258156]],
    [ "Wiener Netze",[16.209470850000002, 48.00915445]],
    [ "Rosenau Karlhütte",[14.469558198499584, 47.689681717302584]],
    [ "Netz Oberösterreich GmbH",[13.769024890344621, 47.808213732041914]],
    [ "Wiener Netze",[16.301771556283846, 48.02682862373637]],
    [ "Wiener Netze",[16.30112621645736, 48.02350875024354]],
    [ "Wiener Netze",[16.29675915, 48.025982500000005]],
    [ "Wiener Netze",[16.291016780375752, 48.025844846539286]],
    [ "Wiener Netze",[16.39458333429201, 47.907008202276856]],
    [ "Wiener Netze",[16.392896486230395, 47.90644561262684]],
    [ "TST Payerbach Mühlhofstraße",[15.866276400000002, 47.691780449999996]],
    [ "Netz Niederösterreich",[15.839809372266593, 47.69944810445854]],
    [ "Netz Niederösterreich",[16.25876725, 47.82952684999999]],
    [ "TST Wr. Neustadt Döttelbachsiedlung",[16.265542462895873, 47.83228362140045]],
    [ "Netz Niederösterreich",[16.196042683732745, 47.783396550551565]],
    [ "Netz Niederösterreich GmbH",[16.223495676708488, 47.808186807498764]],
    [ "Netz Niederösterreich",[16.194941316916648, 47.78285675062103]],
    [ "1097993252",[15.865307061647359, 47.92645812330901]],
    [ "Netz OÖ",[13.720284558552752, 48.008664419523285]],
    [ "Netz OÖ",[13.717792557829714, 48.00316343893399]],
    [ "Netz OÖ",[13.728126249999997, 48.00988764999999]],
    [ "Energienetze Steiermark",[15.548498442101891, 46.949105340137336]],
    [ "TST Wr. Neustadt Richtergasse",[16.23837300211826, 47.800163674771355]],
    [ "TST Lichtenwörth Hainisch Straße",[16.2947466, 47.84424055000001]],
    [ "Netz NÖ",[16.2480144, 47.82147069999999]],
    [ "TST Wr.Neustadt Südtiroler Platz",[16.24296705, 47.8075829]],
    [ "TST Wr. Neustadt Marktstraße",[16.238286900000002, 47.8038839]],
    [ "TST Wr. Neustadt Altabachgasse",[16.252691499999997, 47.821377549999994]],
    [ "TST Wr. Neustadt Am Kleinen Lazarett",[16.264981075342963, 47.808937008975484]],
    [ "Energienetze Steiermark",[15.541240668936183, 46.93302081654949]],
    [ "Netz Niederösterreich",[16.272519774718614, 47.77002044138528]],
    [ "Netz Niederösterreich",[16.2688165, 47.788165150000005]],
    [ "TST Katzelsdorf Süd",[16.26700338921572, 47.774399971532034]],
    [ "Netz Niederösterreich",[16.276913049999997, 47.778168699999995]],
    [ "Netz Niederösterreich",[16.26988553244105, 47.80002210561395]],
    [ "1098520750",[14.639293826458395, 47.848165591859264]],
    [ "St. Corona Unternberg Erlebnisarena",[16.02057628195387, 47.57827425207353]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.96583063202651, 47.61655605164479]],
    [ "1099821419",[13.69804396271876, 48.06663724249685]],
    [ "Netz Niederösterreich",[16.050092830082946, 47.56253514377857]],
    [ "Umspannwerk Kronstorf",[14.452827924564843, 48.127760905435046]],
    [ "TST Neunkirchen Brabetzgasse",[16.076690280924097, 47.726872747950104]],
    [ "Wiener Netze",[16.460501949999998, 48.34698550000001]],
    [ "Wiener Netze",[16.45985375, 48.344360449999996]],
    [ "Wiener Netze",[16.459694937874083, 48.344412361249745]],
    [ "Wiener Netze",[16.45827743442107, 48.34312960327957]],
    [ "Altes Zollamt",[9.649912500000001, 47.42745904999999]],
    [ "Reichsstraße",[9.651182800000003, 47.4285766]],
    [ "Oberscheider",[9.64928375, 47.42952660000001]],
    [ "Energie AG",[13.64308599841462, 48.1877945495841]],
    [ "Energie AG",[13.637373086151172, 48.17463595642738]],
    [ "Netz Niederösterreich",[16.212086400000004, 47.80552715]],
    [ "TST Wr.Neustadt Weikersdorfer Wald",[16.191023380496663, 47.80675129717387]],
    [ "TST Gloggnitz Hoffeld",[15.931988124033373, 47.67217013890814]],
    [ "TST St. Johann Dinhoblstraße",[16.02989158280929, 47.71212374906713]],
    [ "TST Pottschach Samwaldstraße",[16.01780088488889, 47.70495705342222]],
    [ "TST Wimpassing Bauerstraße",[16.02126415, 47.697908649999995]],
    [ "TST Rohrbach Familienhilfe",[16.03317655, 47.71803979999999]],
    [ "TST St. Johann Rennerschule",[16.031861099999997, 47.71926055]],
    [ "TST Kleinau Großau Kleinau",[15.786850439627079, 47.699048632711786]],
    [ "Netz Niederösterreich GmbH",[15.767776025597792, 47.676518705142854]],
    [ "Energie Steiermark",[15.42684904501529, 47.01446167866235]],
    [ "Wiener Netze",[16.610406137516954, 48.11427787075258]],
    [ "Wiener Netze",[16.6120364, 48.11359065]],
    [ "Wiener Netze",[16.61656755, 48.111449300000004]],
    [ "Wiener Netze",[16.616245900000003, 48.118036800000006]],
    [ "Wiener Netze",[16.610087825104035, 48.116750496641956]],
    [ "Wiener Netze",[16.615271149999998, 48.10818164999999]],
    [ "Wiener Netze",[16.614718749999998, 48.109729599999994]],
    [ "Wiener Netze",[16.621073799999998, 48.1100504]],
    [ "Wiener Netze",[16.62576415169354, 48.11118752012612]],
    [ "Wiener Netze",[16.62633467530972, 48.11498975564914]],
    [ "Netz NÖ",[16.075768099999998, 47.615289600000004]],
    [ "TST Thomasberg Olbersdorf Lagerhaus",[16.119630336854083, 47.602999782158136]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.985826900000001, 47.60759329999999]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.995333239651327, 47.593016810316364]],
    [ "Wiener Netze",[16.38209615, 48.07926035]],
    [ "1108163581",[13.511311778388178, 46.88003912664907]],
    [ "KNG-Kärnten Netz GmbH",[13.516200514172144, 46.79149089382339]],
    [ "Wiener Netze",[16.245680613728123, 48.264706220884534]],
    [ "KNG-Kärnten Netz GmbH",[14.745686793594862, 47.00612305456264]],
    [ "KNG-Kärnten Netz GmbH",[14.731901398915479, 47.00625418776038]],
    [ "KNG-Kärnten Netz GmbH",[14.745866053850609, 47.009129636033876]],
    [ "KNG-Kärnten Netz GmbH",[14.730336856726419, 47.0233666451339]],
    [ "KNG-Kärnten Netz GmbH",[14.759561808175967, 46.99614475681185]],
    [ "KNG-Kärnten Netz GmbH",[14.945232424496059, 46.932908344942895]],
    [ "Gmünd Feuerwehrhaus ESG",[13.529656199931834, 46.903914849999815]],
    [ "KNG-Kärnten Netz GmbH",[14.799652828434413, 46.95704295174864]],
    [ "KNG-Kärnten Netz GmbH",[14.741912427841262, 46.952276943588664]],
    [ "KNG-Kärnten Netz GmbH",[14.793275874997933, 46.87914089213709]],
    [ "KNG-Kärnten Netz GmbH",[14.690792742617969, 46.94906459495862]],
    [ "KNG-Kärnten Netz GmbH",[14.793025799999999, 46.9589481]],
    [ "KNG-Kärnten Netz GmbH",[14.796298840565225, 46.96273507349089]],
    [ "KNG-Kärnten Netz GmbH",[14.793792739039473, 46.96545574385981]],
    [ "KNG-Kärnten Netz GmbH",[14.79064879668278, 46.96446817831183]],
    [ "TST Pottschach Ort",[16.001972193867058, 47.70277462384748]],
    [ "TST Pottschach Edisongasse",[16.010828334035885, 47.70648333361575]],
    [ "TST Pottschach Auäcker",[16.0221225830733, 47.704220997742716]],
    [ "KNG-Kärnten Netz GmbH",[14.82630568676554, 46.99134485259337]],
    [ "KNG-Kärnten Netz GmbH",[14.816957099925993, 46.9816963479486]],
    [ "KNG-Kärnten Netz GmbH",[14.80749433637336, 46.95122596974811]],
    [ "KNG-Kärnten Netz GmbH",[14.790194505481855, 46.94560309904098]],
    [ "KNG-Kärnten Netz GmbH",[14.837882829294754, 46.956151104427065]],
    [ "KNG-Kärnten Netz GmbH",[14.841967985833337, 46.94073212671691]],
    [ "Netz NÖ",[15.973785200000002, 47.633389]],
    [ "Netz NÖ",[15.962954617794956, 47.72831115111957]],
    [ "TST Rohrbach Betriebsstraße",[16.05371825, 47.72476820000001]],
    [ "TST St. Lorenzen",[16.03756366289624, 47.73971936500101]],
    [ "TST Raglitz",[16.051981082444556, 47.75077325225053]],
    [ "Netz NÖ",[16.04288040268125, 47.75594591617337]],
    [ "TST St.Johann Dölzlg",[16.01919211884237, 47.71852474789471]],
    [ "Wiener Netze",[16.358569699999997, 48.070651399999996]],
    [ "KNG-Kärnten Netz GmbH",[14.921273300000003, 46.93898430000001]],
    [ "KNG-Kärnten Netz GmbH",[14.90630935, 46.939828000000006]],
    [ "KNG-Kärnten Netz GmbH",[14.867953403661732, 46.93269339168665]],
    [ "KNG-Kärnten Netz GmbH",[14.87429265, 46.9282941]],
    [ "KNG-Kärnten Netz GmbH",[14.810458934698415, 46.927172897500405]],
    [ "KNG-Kärnten Netz GmbH",[14.821391593943956, 46.90871426901522]],
    [ "1110595957",[15.411187174199723, 47.075822878878995]],
    [ "Energie Steiermark",[15.449860232261418, 47.03332904596583]],
    [ "Hagenmühle",[9.656678637157453, 47.426069249771544]],
    [ "Jahnstraße",[9.65498978011734, 47.4245039623843]],
    [ "Reichshofstadion",[9.652156679295313, 47.42158034588306]],
    [ "Siedlung Alpstraße",[9.657207066886064, 47.41871036643129]],
    [ "KNG-Kärnten Netz GmbH",[14.971539443753944, 46.85152989872163]],
    [ "KNG-Kärnten Netz GmbH",[14.955385255717363, 46.853716299322286]],
    [ "KNG-Kärnten Netz GmbH",[14.941654453280188, 46.86124888862366]],
    [ "KNG-Kärnten Netz GmbH",[14.841588724100884, 46.88294945506587]],
    [ "KNG-Kärnten Netz GmbH",[14.851408062014935, 46.879415448133926]],
    [ "KNG-Kärnten Netz GmbH",[14.81927667130746, 46.89858949048361]],
    [ "KNG-Kärnten Netz GmbH",[14.762559585355971, 46.86759054528539]],
    [ "KNG-Kärnten Netz GmbH",[14.779087069807888, 46.870555760056]],
    [ "KNG-Kärnten Netz GmbH",[14.822309199686364, 46.88475714679386]],
    [ "KNG-Kärnten Netz GmbH",[14.795496057830151, 46.905651603516866]],
    [ "KNG-Kärnten Netz GmbH",[14.852178200979923, 46.85914303328769]],
    [ "KNG-Kärnten Netz GmbH",[14.79676695274815, 46.858368148901995]],
    [ "KNG-Kärnten Netz GmbH",[14.823265692168086, 46.84789535611182]],
    [ "KNG-Kärnten Netz GmbH",[14.84162604230586, 46.85566903826809]],
    [ "KNG-Kärnten Netz GmbH",[14.836237513408419, 46.8601072026595]],
    [ "KNG-Kärnten Netz GmbH",[14.829666187289897, 46.86922902464302]],
    [ "KNG-Kärnten Netz GmbH",[14.855728239221405, 46.820027461522834]],
    [ "KNG-Kärnten Netz GmbH",[14.785866355776923, 46.836851920221164]],
    [ "KNG-Kärnten Netz GmbH",[14.758804659119509, 46.83181949918342]],
    [ "KNG-Kärnten Netz GmbH",[14.750760674710463, 46.84114374357848]],
    [ "TST St.Johann Ufergasse",[16.02540877343536, 47.71652938956493]],
    [ "TST St. Johann Am Sierningbach",[16.02948735, 47.71677099999999]],
    [ "TST Dunkelstein Blindendorf",[16.04111170114652, 47.70979981670888]],
    [ "TST Putzmannsdorf 1",[15.994183549999999, 47.69254865]],
    [ "Energie Steiermark",[15.410163983557274, 47.03079348860892]],
    [ "Energie AG",[13.54633478197475, 48.351601839364115]],
    [ "Wiener Netze",[16.317894499999998, 48.080180250000005]],
    [ "Wiener Netze",[16.325418146469396, 48.06821763145844]],
    [ "Tinetz",[12.757921300000003, 46.821108300000006]],
    [ "Marburgerstrasse",[15.466747438355165, 47.05744917705661]],
    [ "Spittal XXXLutz",[13.523226107757369, 46.784982167310666]],
    [ "KNG-Kärnten Netz GmbH",[13.532369058856517, 46.783740254701414]],
    [ "KNG-Kärnten Netz GmbH",[13.53182922027535, 46.7810165820019]],
    [ "KNG-Kärnten Netz GmbH",[13.523315025018224, 46.78728314594563]],
    [ "KNG-Kärnten Netz GmbH",[13.52046495, 46.78567639999999]],
    [ "KNG-Kärnten Netz GmbH",[13.488703502301028, 46.795421282273644]],
    [ "KNG-Kärnten Netz GmbH",[13.488169681614172, 46.79045683017503]],
    [ "KNG-Kärnten Netz GmbH",[14.78238988087047, 46.81910865366114]],
    [ "KNG-Kärnten Netz GmbH",[14.818866350000004, 46.8407902]],
    [ "KNG-Kärnten Netz GmbH",[14.797415749999999, 46.83757065]],
    [ "KNG-Kärnten Netz GmbH",[14.811516513075267, 46.83935514794146]],
    [ "Trafo Feriensiedlung Waldrast/Koralpe",[14.927747206282227, 46.822391525086616]],
    [ "KNG-Kärnten Netz GmbH",[14.870525578071362, 46.8052019324043]],
    [ "KNG-Kärnten Netz GmbH",[14.912693646211403, 46.82035519706761]],
    [ "Energie Ried",[13.538652274139702, 48.27785837932532]],
    [ "KNG-Kärnten Netz GmbH",[14.816789788256786, 46.78811181089761]],
    [ "KNG-Kärnten Netz GmbH",[14.82778048431671, 46.79918318329899]],
    [ "KNG-Kärnten Netz GmbH",[14.875022484118288, 46.80220341190522]],
    [ "KNG-Kärnten Netz GmbH",[14.884408641563896, 46.795229101267175]],
    [ "Energie Steiermark",[15.385255448731368, 47.06406056763747]],
    [ "Wiener Netze",[16.284084523157272, 48.126074256725566]],
    [ "KNG-Kärnten Netz GmbH",[14.905915349999999, 46.76277145000001]],
    [ "KNG-Kärnten Netz GmbH",[14.850709656778392, 46.76603114363647]],
    [ "KNG-Kärnten Netz GmbH",[14.845697288096265, 46.77871744573904]],
    [ "KNG-Kärnten Netz GmbH",[14.889585548873649, 46.773627499610136]],
    [ "Netz NÖ",[16.03708158285175, 47.79490375708097]],
    [ "Straßwalchen Thalham",[13.26931940646176, 47.97233430618425]],
    [ "Wiener Netze",[16.3637099, 48.08355149999999]],
    [ "TST Breitenstein Untere Bahnstraße",[15.824520658773945, 47.661122324707094]],
    [ "TST Willendorf Neunkirchnerstraße",[16.0569269, 47.786862500000005]],
    [ "St. Marienkirchen Pfarrhof",[13.45652828913619, 48.38823030093425]],
    [ "St. Marienkirchen b. Schärding",[13.448979197844281, 48.385991150583884]],
    [ "Wiener Netze",[16.315914415983276, 47.858961097611726]],
    [ "Energie Ried",[13.408045939697068, 48.2467713083109]],
    [ "KNG-Kärnten Netz GmbH",[14.720180204454952, 46.755008530866235]],
    [ "KNG-Kärnten Netz GmbH",[14.7524533, 46.775065850000004]],
    [ "Netztransformatorstation Schulgasse",[16.2051379, 48.05399855]],
    [ "KNG-Kärnten Netz GmbH",[14.74455230232537, 46.74847194226711]],
    [ "KNG-Kärnten Netz GmbH",[14.726933521180017, 46.73667463819462]],
    [ "KNG-Kärnten Netz GmbH",[14.696881279651112, 46.75141215968278]],
    [ "KNG-Kärnten Netz GmbH",[14.76430910699323, 46.716116621593144]],
    [ "TST Reichenau Thalhof",[15.846320128644864, 47.7011347583884]],
    [ "KNG-Kärnten Netz GmbH",[14.986082109790823, 46.65159685050429]],
    [ "KNG-Kärnten Netz GmbH",[14.96683722920343, 46.64613981041234]],
    [ "KNG-Kärnten Netz GmbH",[14.989920353748227, 46.67242370216735]],
    [ "KNG-Kärnten Netz GmbH",[14.962125461363778, 46.66630919087703]],
    [ "KNG-Kärnten Netz GmbH",[14.946251956260227, 46.672360436003494]],
    [ "KNG-Kärnten Netz GmbH",[14.928407350811428, 46.67858126378876]],
    [ "KNG-Kärnten Netz GmbH",[14.879308975048275, 46.66167552963508]],
    [ "KNG-Kärnten Netz GmbH",[14.92284796103687, 46.66350523369874]],
    [ "Seeboden Ortszentrum",[13.517965049999999, 46.81892625]],
    [ "Polsterer",[16.602337647425937, 48.08902678409591]],
    [ "Wiener Netze",[16.186735445369568, 47.92915333227293]],
    [ "Wiener Netze",[16.40800635, 47.94234775]],
    [ "Wiener Netze",[16.383073781483887, 48.039614427349036]],
    [ "Wiener Netze",[16.313340848353068, 47.93331700004774]],
    [ "TST Kleinwolkersdorf",[16.247276431943593, 47.747355155840204]],
    [ "Wiener Netze",[16.337376999999996, 47.997055749999994]],
    [ "EVN",[14.844736887113191, 48.53600831274338]],
    [ "KNG-Kärnten Netz GmbH",[14.818152694856543, 46.702525358155626]],
    [ "Rosenau - Ort",[14.3904242, 47.7125759]],
    [ "KNG-Kärnten Netz GmbH",[14.834578251317513, 46.69181719865596]],
    [ "KNG-Kärnten Netz GmbH",[14.899074889436948, 46.701635141430415]],
    [ "KNG-Kärnten Netz GmbH",[14.962767350977721, 46.684473890545675]],
    [ "KNG-Kärnten Netz GmbH",[14.953713552233012, 46.69513508185391]],
    [ "KNG-Kärnten Netz GmbH",[14.907928445066002, 46.678817601753906]],
    [ "KNG-Kärnten Netz GmbH",[14.856185369371191, 46.71298235440542]],
    [ "KNG-Kärnten Netz GmbH",[14.928424840583677, 46.730998057468696]],
    [ "KNG-Kärnten Netz GmbH",[14.874875252442967, 46.71122029702049]],
    [ "KNG-Kärnten Netz GmbH",[14.879533208013127, 46.70228929781941]],
    [ "KNG-Kärnten Netz GmbH",[14.90398902668804, 46.74932539573387]],
    [ "KNG-Kärnten Netz GmbH",[14.944902713909675, 46.736342162008306]],
    [ "KNG-Kärnten Netz GmbH",[14.917028755483033, 46.72885999813179]],
    [ "KNG-Kärnten Netz GmbH",[14.850150051775332, 46.744271810626174]],
    [ "KNG-Kärnten Netz GmbH",[14.881152949999999, 46.735222150000006]],
    [ "KNG-Kärnten Netz GmbH",[14.905158285476503, 46.75660489312961]],
    [ "KNG-Kärnten Netz GmbH",[14.82838843714871, 46.76405889025642]],
    [ "KNG-Kärnten Netz GmbH",[14.840894752698508, 46.76086638927877]],
    [ "KNG-Kärnten Netz GmbH",[14.937678645702206, 46.75490703504761]],
    [ "KNG-Kärnten Netz GmbH",[14.813968527143405, 46.76709288697741]],
    [ "KNG-Kärnten Netz GmbH",[14.8193146, 46.766137400000005]],
    [ "KNG-Kärnten Netz GmbH",[14.836415952965346, 46.72838174782416]],
    [ "KNG-Kärnten Netz GmbH",[14.863497358480451, 46.72155925365451]],
    [ "KNG-Kärnten Netz GmbH",[14.78197076737094, 46.732984072332066]],
    [ "KNG-Kärnten Netz GmbH",[14.782024013965243, 46.74046256695459]],
    [ "KNG-Kärnten Netz GmbH",[14.796103739220326, 46.74545201176173]],
    [ "Wiener Netze",[16.320042239664744, 47.85633557538438]],
    [ "Wiener Netze",[16.308066244419788, 47.84968107929933]],
    [ "Zur Feldrast",[9.671142016429307, 47.41790562278801]],
    [ "Wiener Netze",[16.186649266456502, 47.921131450246016]],
    [ "Wiener Netze",[16.2282876, 47.9999256]],
    [ "Netz NÖ",[16.820668350000002, 48.03349195000001]],
    [ "Netz Niederösterreich GmbH",[16.25995355, 47.841720200000005]],
    [ "Netz Niederösterreich GmbH",[16.256738335488638, 47.84189762661857]],
    [ "Netz Niederösterreich GmbH",[16.245848674262135, 47.83548219643242]],
    [ "Netz Niederösterreich GmbH",[16.2623306, 47.84150295]],
    [ "Netz Niederösterreich",[16.2514586356008, 47.84010143130646]],
    [ "Wiener Netze",[16.247923626672574, 48.00789404274602]],
    [ "Wiener Netze",[16.25596202189927, 48.0080792559425]],
    [ "Wiener Netze",[16.259445279145282, 48.0086378940834]],
    [ "Wiener Netze",[16.244668, 48.008422100000004]],
    [ "Wiener Netze",[16.256381849999997, 48.01433415000001]],
    [ "Wiener Netze",[16.256059999999994, 47.996845449999995]],
    [ "1127867857",[15.891784390015374, 46.86482267892294]],
    [ "Bundesstraße",[16.43983501664928, 47.87840640025088]],
    [ "Hornstein Autobahnzubringer",[16.433595941142155, 47.875466932772355]],
    [ "Netz Burgenland",[16.439362666628856, 47.88399834544423]],
    [ "Eisenstädterstraße",[16.440807125907487, 47.87289835484808]],
    [ "Siget",[16.442399849999997, 47.875613050000005]],
    [ "Hornstein TS Gartengasse",[16.443847650000002, 47.88336995]],
    [ "Rathaus",[16.444113350000002, 47.88033095]],
    [ "Netz Burgenland",[16.450016400000003, 47.886341800000004]],
    [ "Netz Burgenland",[16.43986457678959, 47.88793572234273]],
    [ "Wiener Netze",[16.397542275165673, 47.90357161834655]],
    [ "TST Reichenau Eichengasse",[15.833389582813549, 47.69405515042142]],
    [ "TST Hirschwang Edlach 2",[15.818415112302898, 47.69960297245627]],
    [ "Netz NÖ",[16.16296182258674, 47.93375039290417]],
    [ "Netz NÖ",[16.158672779759762, 47.93394045483484]],
    [ "Netz NÖ",[16.149445964902657, 47.93454716933661]],
    [ "Netz NÖ",[16.161055699999995, 47.93770494999999]],
    [ "Netz NÖ",[16.15569388394051, 47.94416498006541]],
    [ "Netz NÖ",[16.15012739796021, 47.942765782560045]],
    [ "Netz NÖ",[16.14805306313016, 47.94115397377614]],
    [ "Netz NÖ",[16.146487262677056, 47.93779406711475]],
    [ "VLB",[9.674394699999999, 47.39776225]],
    [ "Netz NÖ",[16.139180882340092, 47.939908081959786]],
    [ "Netz NÖ",[16.131925250000002, 47.94399135000001]],
    [ "Netz NÖ",[16.12252483449506, 47.944851402594146]],
    [ "Netz NÖ",[16.1173378187887, 47.94282882554944]],
    [ "Eggerding",[13.47156923053007, 48.34992645027181]],
    [ "Energie AG",[13.47981945204814, 48.361130914976236]],
    [ "Netz NÖ",[16.117784858578204, 47.95132446736292]],
    [ "Netz NÖ",[16.115097259355352, 47.94858836492678]],
    [ "Netz NÖ",[16.10529825, 47.9527547]],
    [ "Netz NÖ",[16.106388137369574, 47.95019700572727]],
    [ "Netz NÖ",[16.105308450000003, 47.947501450000004]],
    [ "Netz NÖ",[16.10762765, 47.94800685]],
    [ "Netz NÖ",[16.106722449999996, 47.94477070000001]],
    [ "Netz NÖ",[16.103535649999998, 47.9448225]],
    [ "Netz NÖ",[16.1087632, 47.9434894]],
    [ "Netz NÖ",[16.106882705365074, 47.942287815376766]],
    [ "Netz NÖ",[16.103682649999996, 47.9405987]],
    [ "Netz NÖ",[16.103331515910607, 47.93876045174942]],
    [ "Netz NÖ",[16.08130906647613, 47.968684800436236]],
    [ "Untere Aue",[9.65706065790554, 47.411575584683945]],
    [ "Wiesenrainstraße",[9.660530428399909, 47.41064539184869]],
    [ "Widnauerstraße",[9.658733049999997, 47.4055844]],
    [ "Am Rohr",[9.65991363543808, 47.40067490807634]],
    [ "Netz NÖ",[16.09846695, 47.9439001]],
    [ "Netz NÖ",[16.09676917188958, 47.94208480377526]],
    [ "Netz NÖ",[16.0942969, 47.940968850000004]],
    [ "Netz NÖ",[16.098825624720988, 47.94560984346149]],
    [ "Netz NÖ",[16.0943281, 47.9462121]],
    [ "Netz NÖ",[16.082683472536136, 47.94141602865199]],
    [ "Netz NÖ",[16.078681594015023, 47.93785352029814]],
    [ "Netz NÖ",[16.094546721076966, 47.95656543585555]],
    [ "Netz NÖ",[16.0954613, 47.957619199999996]],
    [ "Netz NÖ",[16.09869088242306, 47.958290601888926]],
    [ "Netz NÖ",[16.094479766456725, 47.959936799777644]],
    [ "Netz NÖ",[16.091970950000004, 47.9626332]],
    [ "Netz NÖ",[16.0880613, 47.9669798]],
    [ "Netz NÖ",[16.083123480000378, 47.95963240438368]],
    [ "Netz NÖ",[16.056751219302527, 47.96365749861725]],
    [ "Netz NÖ",[16.038779100000003, 47.95570790000001]],
    [ "Netz NÖ",[16.01889896748561, 47.94597645096518]],
    [ "Netz NÖ",[16.070627018154607, 47.974697739342865]],
    [ "Netz NÖ",[16.086302236292347, 47.963366774912004]],
    [ "Netz NÖ",[16.050032500000004, 47.984352]],
    [ "Netz NÖ",[16.04832147759303, 47.98513593094207]],
    [ "Netz NÖ",[15.6959336, 47.7625978]],
    [ "Stadtbetriebe Mariazell",[15.333430831495443, 47.72848317296953]],
    [ "Wiener Netze",[16.35697815788515, 48.08675923010367]],
    [ "Wiener Netze",[16.367885976364864, 48.11512239955193]],
    [ "Wiener Netze",[16.381400530887877, 48.03480415160193]],
    [ "Wiener Netze",[16.371145200000004, 48.104564700000005]],
    [ "Wiener Netze",[16.3686374, 48.111749700000004]],
    [ "1132005169",[16.579609750000003, 48.29031120000001]],
    [ "1132005170",[16.5812152713886, 48.2921472270072]],
    [ "1132005171",[16.578092277777777, 48.28834088080808]],
    [ "Rudolfstrasse Krankenhaus",[15.461935090332128, 47.07283796840421]],
    [ "Wiener Netze",[16.230493933736966, 48.00797491718707]],
    [ "Wiener Netze",[16.221695150000002, 48.00137535]],
    [ "Wiener Netze",[16.21688885, 48.00185034999999]],
    [ "Netz Niederösterreich GmbH",[16.093084983607806, 48.47471705823332]],
    [ "Netz NÖ",[16.025273300000002, 48.016332500000004]],
    [ "Netz NÖ",[16.01338955, 48.017770950000006]],
    [ "Netz NÖ",[16.02672775, 47.990950399999996]],
    [ "Netz NÖ",[16.032666198083138, 47.98790642236241]],
    [ "Gerhart Hauptmanngasse",[15.465805188801356, 47.05144067778626]],
    [ "Wiener Netze",[16.2243386, 48.0027869]],
    [ "Wiener Netze",[16.2124307, 48.004077900000006]],
    [ "Wiener Netze",[16.230113537298248, 48.00257152682636]],
    [ "Wiener Netze",[16.245139574853802, 47.99971825619361]],
    [ "Wiener Netze",[16.38168375, 48.06001225]],
    [ "Wiener Netze",[16.23630404361955, 48.010211020280764]],
    [ "Netz OÖ",[13.993461573963028, 48.038889573676286]],
    [ "Netz OÖ",[13.992293114965737, 48.03929668117236]],
    [ "Netz OÖ",[13.994778441902257, 48.03786647304544]],
    [ "Netz NÖ",[16.005969980198483, 47.929408626965454]],
    [ "Netz NÖ",[15.988499329477767, 47.93576669506154]],
    [ "Netz NÖ",[15.974708103203945, 47.91805331851509]],
    [ "Wiener Netze",[16.438448337371536, 47.96608658083651]],
    [ "Wiener Netze",[16.437817022499008, 47.96535071675798]],
    [ "Wiener Netze",[16.43665174176639, 47.96241786809298]],
    [ "Wiener Netze",[16.431553349999998, 47.96175805]],
    [ "Wiener Netze",[16.430811187763336, 47.965490934532184]],
    [ "Netz NÖ",[16.036155550000004, 47.982934750000005]],
    [ "Netz NÖ",[16.034211699999997, 47.98564995]],
    [ "Netz NÖ",[16.023487666288904, 47.97815860070885]],
    [ "Netz NÖ",[16.03777544754291, 47.9758660162151]],
    [ "Netz NÖ",[16.03444314640005, 47.974191231630314]],
    [ "Netz NÖ",[16.02773021695848, 47.972193102145994]],
    [ "Netz NÖ",[16.008757665491533, 47.97214550153172]],
    [ "Netz NÖ",[15.997617850000003, 47.970891050000006]],
    [ "Netz NÖ",[15.971145000000002, 47.9735047]],
    [ "Netz NÖ",[15.951493449999997, 47.97574595]],
    [ "Netz NÖ",[15.93474735, 47.975748700000004]],
    [ "Netz NÖ",[15.91905905, 47.9795298]],
    [ "Netz NÖ",[15.963747450001257, 47.96176325030303]],
    [ "Netz NÖ",[15.98104476645663, 47.960373359126635]],
    [ "Netz NÖ",[15.96546060109335, 47.94063806638644]],
    [ "Netz NÖ",[15.9531789, 47.93175374999999]],
    [ "Netz NÖ",[15.947129309804735, 47.92705933456857]],
    [ "Netz NÖ",[15.928948950000004, 47.91940054999999]],
    [ "Netz NÖ",[16.14126005, 48.0314228]],
    [ "Netz NÖ",[16.15024105, 48.065308800000004]],
    [ "Wiener Netze",[16.312461286457903, 48.07050817042702]],
    [ "Wiener Netze",[16.4410989, 48.317390599999996]],
    [ "Wiener Netze",[14.930880455140814, 47.80698163432043]],
    [ "Wiener Netze",[16.402139449725272, 48.105100698642126]],
    [ "Wiener Netze GmbH",[16.429888402993807, 47.960308333288545]],
    [ "TST Wampersdorf Wohnanlage",[16.431579354821263, 47.92689897018144]],
    [ "TST Wampersdorf 1",[16.428660856449543, 47.92230481558258]],
    [ "TST Wampersdorf 2",[16.424851305474558, 47.91575934708525]],
    [ "TST D. Brodersdorf Triftgasse",[16.4694451, 47.94338140000001]],
    [ "TST Deutsch Brodersdorf West",[16.4706659, 47.940257599999995]],
    [ "TST Deutsch Brodersdorf Ost",[16.480824400000003, 47.94013235]],
    [ "TST Deutsch Brodersdorf Siedlung",[16.4820861313437, 47.943630418362694]],
    [ "Neue Siedlung",[16.4814913, 47.930888350000004]],
    [ "Rosenweg",[16.481543251985627, 47.93306773229997]],
    [ "Netz Burgenland",[16.476562649999998, 47.93616765]],
    [ "Holzstraße",[9.661511482415959, 47.424793683634135]],
    [ "1144226389",[15.4241903, 47.07935045]],
    [ "Wiener Netze",[16.32119755, 48.11748360000001]],
    [ "Wiener Netze",[16.321046000000003, 48.123065849999996]],
    [ "Wiener Netze",[16.341900531346848, 48.12291010562528]],
    [ "Feldkreuz Siedlung",[9.67722885, 47.42724175]],
    [ "Eishalle",[9.67279125, 47.42819254999999]],
    [ "Sägerstraße",[9.673541171833854, 47.42312383987067]],
    [ "Smart Textiles",[9.67222485, 47.4221071]],
    [ "Millenniumspark",[9.671130600000001, 47.421948]],
    [ "BULU",[9.670113031108203, 47.42180947734325]],
    [ "Millenniumspark 2",[9.671863956773631, 47.421191525296955]],
    [ "Ochsenvorach",[9.669389266431864, 47.41815687102303]],
    [ "Binsenfeldstraße",[9.665878809182807, 47.42173902332818]],
    [ "Kaiser Franz Josef Straße",[9.659857781395019, 47.42273414822615]],
    [ "Lustenauerstr.",[9.70052635, 47.4157261]],
    [ "Oberes Heuried",[9.69051381834718, 47.41763851449938]],
    [ "Energie AG",[13.406283424210619, 47.79805460294027]],
    [ "TST Lichtenwörth Bründlweg",[16.299456334284862, 47.826008660238045]],
    [ "Netz Niederösterreich",[16.29497155, 47.83233425000001]],
    [ "Netz Niederösterreich GmbH",[16.251013773593073, 47.840542996536804]],
    [ "Netz Niederösterreich GmbH",[16.248724406895818, 47.83903922980856]],
    [ "KNG-Kärnten Netz GmbH",[13.534647050841414, 46.782095200693135]],
    [ "Netztransformatorstation Hauptstraße 40",[16.210859872581132, 48.05683260893599]],
    [ "TST Neunkirchen Peischingerstraße",[16.089650473256988, 47.72577559446883]],
    [ "TST Neunkirchen B17",[16.08694685, 47.7277357]],
    [ "TST Neunkirchen Waldrandgasse",[16.093756966571515, 47.73751904974982]],
    [ "Netz Niederösterreich GmbH",[16.086946984258695, 47.73255464403439]],
    [ "TST Neunkirchen Straußgasse",[16.090599830081537, 47.73178189548997]],
    [ "Netz Niederösterreich GmbH",[16.098744500000002, 47.72647010000001]],
    [ "Netz Niederösterreich GmbH",[16.09863144222505, 47.73205823583192]],
    [ "Netz Niederösterreich GmbH",[16.067520678689814, 47.717882919313475]],
    [ "TST Neunkirchen Wahlamt",[16.08181755, 47.71747515]],
    [ "TST Neunkirchen WIFI",[16.074467084173236, 47.717175552303495]],
    [ "Netz Niederösterreich GmbH",[16.095453479394426, 47.72241560855305]],
    [ "TST Neunkirchen Augasse",[16.09942205, 47.720771000000006]],
    [ "Netz Niederösterreich GmbH",[16.113102169875425, 47.72706669579652]],
    [ "Netz Niederösterreich GmbH",[16.121068969698793, 47.72466695382384]],
    [ "TST Loipersbach Siedlung",[16.122254312563918, 47.72001887504261]],
    [ "TST Loipersbach Guntramser Straße",[16.133724500000003, 47.71936145]],
    [ "Eduard Keil Gasse",[15.45213437674593, 47.04590296522643]],
    [ "TST Wampersdorf Siedlung",[16.43142532636277, 47.925618155853]],
    [ "Umspannwerk Albern",[16.490416710583858, 48.158918953350486]],
    [ "Haaräckerstraße",[15.415073924105219, 47.04850690512673]],
    [ "March",[12.169416649999999, 47.60181299999999]],
    [ "Energie AG",[13.594601964174995, 47.96981039751433]],
    [ "TST Au Untere Hauptstraße",[16.55368821514258, 47.92621059514915]],
    [ "Netz Burgenland GmbH",[16.473427975614126, 47.931606168484755]],
    [ "TST Seibersdorf Brodersdorfer Straße",[16.515107200000003, 47.95761380000001]],
    [ "Netz Niederösterreich GmbH",[16.51804895, 47.96260834999999]],
    [ "Netz Niederösterreich GmbH",[16.55884321741487, 47.91739029900923]],
    [ "Wiener Netze GmbH",[16.431634499999998, 47.95893670000001]],
    [ "Netz Burgenland GmbH",[16.542383438677394, 47.905039434678315]],
    [ "Hauptstraße",[16.51355469848889, 47.916094691398264]],
    [ "TST Au Feldgasse",[16.560226099999998, 47.9240166]],
    [ "Netz Niederösterreich GmbH",[16.521294206998512, 47.95850868600298]],
    [ "Netz Niederösterreich GmbH",[16.55927785, 47.9187369]],
    [ "Netz Burgenland GmbH",[16.47844087278404, 47.934635451503325]],
    [ "Netz Niederösterreich GmbH",[16.18719513901928, 47.83096370997275]],
    [ "TST Wr. Neustadt CENTRO",[16.217329414163608, 47.82152563282018]],
    [ "Netz Niederösterreich GmbH",[16.212714335030388, 47.823676429046046]],
    [ "TST Wiener Neustadt McDonald's",[16.219118619268837, 47.821226097810715]],
    [ "Netz Niederösterreich GmbH",[16.224390340355118, 47.82175302823826]],
    [ "Netz Niederösterreich GmbH",[15.6798859, 47.92165785]],
    [ "TST Schwarzau Süd",[15.7088949, 47.8035317]],
    [ "Netz Niederösterreich GmbH",[15.730515399999996, 47.77281745]],
    [ "Untere Teichstraße",[15.4791, 47.069718699999996]],
    [ "Netz Niederösterreich GmbH",[15.347226299999999, 48.215557200000006]],
    [ "Wiener Netze",[16.224729500000002, 47.9749473]],
    [ "Eichele",[9.683762649999998, 47.42713355]],
    [ "Stanztech",[9.669973500000001, 47.4161777]],
    [ "Wiener Netze GmbH",[16.404065699999997, 47.945580150000005]],
    [ "Wiener Netze GmbH",[16.40250274844567, 47.944203966687844]],
    [ "Wiener Netze",[16.400817076660488, 47.91022971860522]],
    [ "TST Wimpassing Triesterstraße",[16.03127833419356, 47.701712244988684]],
    [ "KNG-Kärnten Netz GmbH",[13.814380099999997, 46.565772200000005]],
    [ "Wiener Netze",[16.376938379025745, 48.0301498032766]],
    [ "Wiener Netze",[16.615070300000003, 48.11864115000001]],
    [ "Netz Burgenland GmbH",[16.42173723333333, 47.83324189912396]],
    [ "Netz Burgenland",[16.453414650000003, 47.83473230000002]],
    [ "Netz Burgenland GmbH",[16.4504516, 47.835380699999995]],
    [ "Akaziensiedlung",[16.38254151619207, 47.832212700850256]],
    [ "Wiener Netze",[16.2708045727919, 48.008024393677886]],
    [ "Wiener Netze",[16.562785414335348, 48.10733002197205]],
    [ "Wiener Netze",[16.436712424883765, 48.08296125585247]],
    [ "Wiener Netze",[16.434063787199555, 48.081363527539075]],
    [ "Wiener Netze",[16.44220660163489, 48.083762196218196]],
    [ "Wiener Netze",[16.456996349999997, 48.08468899999999]],
    [ "Wiener Netze",[16.45297855, 48.073259400000005]],
    [ "Wiener Netze",[16.4447161, 48.077374750000004]],
    [ "Wiener Netze",[16.443505755341274, 48.07826163436714]],
    [ "Wiener Netze",[16.444433476707662, 48.080332258447406]],
    [ "Wiener Netze",[16.439457433311066, 48.077231199443325]],
    [ "Wiener Netze",[16.406311083032993, 48.103048361473014]],
    [ "Wiener Netze",[16.417936167761702, 48.097205547806055]],
    [ "Wiener Netze",[16.418251050000002, 48.106939700000005]],
    [ "Wiener Netze",[16.41767946557763, 48.10700967602591]],
    [ "Wiener Netze",[16.417638850000003, 48.1070923]],
    [ "Wiener Netze",[16.419942623230536, 48.09510946137609]],
    [ "Wiener Netze",[16.4171515, 48.102117099999994]],
    [ "Wiener Netze",[16.42229476669695, 48.10077429975683]],
    [ "Wiener Netze",[16.417926999999995, 48.107057999999995]],
    [ "Wiener Netze",[16.41763806793549, 48.106815801025206]],
    [ "Wiener Netze",[16.410542915659523, 48.10738397170109]],
    [ "Wiener Netze",[16.40933731908866, 48.107701424164446]],
    [ "Wiener Netze",[16.430923435586703, 48.10263070375077]],
    [ "Eisnitzgraben",[15.517514583510373, 47.12557944845889]],
    [ "Energie AG",[13.598474235769903, 47.95958265943327]],
    [ "Lenzing Sommerfeldstr.",[13.596947525632045, 47.968914169307155]],
    [ "Kraims Sdlg.",[13.58916685554523, 47.967347430307434]],
    [ "Redl b. Vöcklamarkt",[13.499392502203884, 48.02611365428399]],
    [ "Netz Niederösterreich GmbH",[16.01585801717735, 47.66041870633249]],
    [ "Energie AG",[13.532680881748034, 48.02144880512455]],
    [ "Energie AG",[13.52674671169357, 48.01848051912786]],
    [ "Rohrbachfeld",[15.50188177643276, 47.101817243260264]],
    [ "Energie AG",[13.510160804061943, 48.02592130864764]],
    [ "Am Blumenhang Box Nord",[15.482734512061848, 47.070977177872855]],
    [ "Wiener Netze",[16.190772816878162, 47.92087692514869]],
    [ "Wiener Netze",[16.280678283151065, 48.046233000194846]],
    [ "Wiener Netze",[16.284252957537014, 48.04425501665379]],
    [ "Wiener Netze",[16.289277449999997, 48.041771299999986]],
    [ "Waschprechting",[13.508673705443638, 47.99792171598454]],
    [ "Wiener Netze",[16.3384369371029, 48.0337891575459]],
    [ "Wiener Netze",[16.13390397867171, 48.10548800759502]],
    [ "Wiener Netze",[16.250292314128338, 48.012717840616354]],
    [ "Wiener Netze",[16.147596124552404, 48.10498346365909]],
    [ "Wiener Netze",[16.3247717, 47.9798108]],
    [ "Steindorf b. Seewalchen",[13.571264894839091, 47.96307149076953]],
    [ "Energie AG",[13.61747154100656, 48.000826035477665]],
    [ "Thalberg",[13.502455363999603, 48.00582685909105]],
    [ "Vorarlberger Energienetze GmbH",[9.684832850000001, 47.393141299999996]],
    [ "EKZ",[12.047025916395725, 47.47654388095216]],
    [ "Döbriach See Isola",[13.645179465481844, 46.77232174799823]],
    [ "TST Loipersbach Ort",[16.116558910064857, 47.71670547569034]],
    [ "Wiener Netze",[16.374036873259445, 47.96427920318651]],
    [ "Wiener Netze",[16.376864750000006, 47.969984700000005]],
    [ "Wiener Netze",[16.37461825, 47.971989400000005]],
    [ "Wiener Netze",[16.416428832194004, 48.10361764049277]],
    [ "Wiener Netze",[16.41576196917208, 48.10530350619661]],
    [ "Wiener Netze",[16.223597400000003, 47.97458460000001]],
    [ "Wiener Netze",[16.535323029461374, 48.13286325914652]],
    [ "Wiener Netze",[16.50003695, 48.129823050000006]],
    [ "Wiener Netze",[16.4916936, 48.13572655]],
    [ "Wiener Netze",[16.39696575, 47.981751]],
    [ "Wiener Netze",[16.490844328160197, 48.13808430488142]],
    [ "Wiener Netze",[16.4920186298809, 48.13768505179585]],
    [ "Wiener Netze",[16.494156970262512, 48.13759670179793]],
    [ "Wiener Netze",[16.439917857258756, 48.09195621762755]],
    [ "Wiener Netze",[16.1298735, 48.144392999999994]],
    [ "Wiener Netze",[16.15476465, 48.15916014999999]],
    [ "Energie AG",[13.510685388542758, 47.976295870646126]],
    [ "Energie AG",[13.505208558917055, 47.96180414344229]],
    [ "Netz OÖ",[14.24648835197312, 48.20263950667962]],
    [ "Wiener Netze",[16.283497850000003, 48.09570615]],
    [ "Gampern Schule",[13.550915666513074, 47.98914614324032]],
    [ "Netz Niederösterreich GmbH",[15.968381022451368, 47.893673695478626]],
    [ "TST Bad Fischau Math. Bliemgasse",[16.174709450000005, 47.82921109999999]],
    [ "TST Bad Fischau 3",[16.17397898097627, 47.83570870231389]],
    [ "Pöllatal",[13.544608538343718, 47.04110508412059]],
    [ "1182058883",[15.720736604836972, 46.71313283057331]],
    [ "1182059952",[15.706015696382286, 46.719486016867116]],
    [ "1182060089",[15.71959817468976, 46.70954591667069]],
    [ "Wiener Netze",[16.366035597646476, 48.08168041990152]],
    [ "Hinternberg Ort",[13.407691713681848, 48.344816595701154]],
    [ "Fischhamering Rag",[13.57214161605217, 48.01614040535365]],
    [ "KNG-Kärnten Netz GmbH",[13.725209950000002, 46.793847400000004]],
    [ "Netz Niederösterreich GmbH",[16.084203918383466, 47.720735644923444]],
    [ "TST Neunkirchen Urtelgasse",[16.08595049641989, 47.71906462358885]],
    [ "Glaserweg",[15.417942345565878, 47.05089617500092]],
    [ "TST Retz Windmühle",[15.940857750000001, 48.7606109]],
    [ "Wiener Netze",[16.495613853047264, 48.14795730374772]],
    [ "Linz Netz",[14.624132216356042, 48.211669901051344]],
    [ "Netz NÖ",[16.333768450300415, 47.81218091664899]],
    [ "Netz Niederösterreich GmbH",[16.206826200000002, 47.4798975]],
    [ "Netz Niederösterreich GmbH",[16.210550369090743, 47.49112590786589]],
    [ "Netz Niederösterreich GmbH",[16.207280882496693, 47.46933070087943]],
    [ "Wiener Netze",[16.297982784764052, 47.996420707823916]],
    [ "Netz NÖ",[15.994194343000563, 48.13783637851756]],
    [ "Netz NÖ",[15.978645899999998, 48.13625249999999]],
    [ "Netz NÖ",[15.986408644890604, 48.13786552968986]],
    [ "Netz NÖ",[15.984670436726894, 48.13917972552562]],
    [ "Netz NÖ",[16.016363400376928, 48.127202466795524]],
    [ "TST Neunkirchen Erholungszentrum",[16.07256865, 47.7209299]],
    [ "TST Neunkirchen Bürocenter",[16.070020099999997, 47.717718600000005]],
    [ "TST Neunkirchen Steinwendergasse",[16.089117688068306, 47.737129919573576]],
    [ "TST Neunkirchen Urbanbrücke II",[16.075339038936, 47.725211675232345]],
    [ "TST Neunkirchen Gymnasium",[16.080616315127674, 47.73248091314174]],
    [ "TST Neunkirchen Hochhauser",[16.07206628328941, 47.72591073331304]],
    [ "TST Neunkirchen Schillergasse",[16.076007049999998, 47.73146710000001]],
    [ "TST Neunkirchen RHA Straußgasse",[16.086391918809262, 47.73091255228343]],
    [ "TST Neunkirchen Lagerhaus",[16.0817569, 47.72429460000001]],
    [ "TST Rohrbach Dr. Bolza-Schünemann-Straße",[16.060189227004436, 47.72561049222297]],
    [ "TST Neunkirchen Urbanbrücke",[16.075289438936, 47.725212475232354]],
    [ "TST Neunkirchen Schraubenwerkstraße",[16.078580816097666, 47.72420837463734]],
    [ "TST Neunkirchen BU",[16.0805742, 47.72405925]],
    [ "Ruhsam",[13.797423699999998, 47.972021350000006]],
    [ "Josef-Gauby-Weg",[15.463457233156756, 47.06664729019992]],
    [ "Wiener Netze",[16.131942149803848, 48.08569475106238]],
    [ "Wiener Netze",[16.100709080748093, 48.115378294218225]],
    [ "Wiener Netze",[16.1231656, 48.1354316]],
    [ "Netz NÖ",[16.018818301203275, 48.08332164893058]],
    [ "Netz NÖ",[16.001133499999998, 48.074796049999996]],
    [ "Netz NÖ",[15.983529609294692, 48.09471427436799]],
    [ "Netz NÖ",[16.01058095, 48.09144675]],
    [ "Netz NÖ",[16.00955831601171, 48.089534402514424]],
    [ "Netz NÖ",[15.998665750000002, 48.1014028]],
    [ "Netz NÖ",[16.00683115, 48.097788099999995]],
    [ "Netz NÖ",[16.01133705534196, 48.10370471879739]],
    [ "Netz NÖ",[16.012474615307603, 48.10672900576079]],
    [ "Netz NÖ",[15.999372779984329, 48.14531894709263]],
    [ "Netz NÖ",[15.958678408054707, 48.12347971979177]],
    [ "Netz NÖ",[15.946971156414135, 48.098657720099965]],
    [ "Netz NÖ",[15.918218480237966, 48.10424722667197]],
    [ "Netz NÖ",[15.918374049999999, 48.0983485]],
    [ "Wiener Netze",[16.55722075713948, 48.06190621562743]],
    [ "Netz NÖ",[16.605405349999998, 48.08344045]],
    [ "Netz NÖ",[16.610161570823326, 48.089025944874244]],
    [ "Netz NÖ",[16.619592772205056, 48.07871578726794]],
    [ "Netz NÖ",[15.97362883214632, 47.82891801987077]],
    [ "Netz Niederösterreich GmbH",[15.616286650000001, 48.18463465]],
    [ "Netz Niederösterreich GmbH",[15.618950076908, 48.17929913900868]],
    [ "Netz Niederösterreich GmbH",[15.615109749999997, 48.19124525]],
    [ "Netz Niederösterreich GmbH",[15.61229845, 48.197244399999995]],
    [ "Netz NÖ",[15.619883064983298, 48.20078778298783]],
    [ "Netz Niederösterreich GmbH",[15.621112349999999, 48.180996949999994]],
    [ "Netz Niederösterreich GmbH",[15.622666754240747, 48.181191821870414]],
    [ "Netz Niederösterreich GmbH",[15.613838573978406, 48.19560759449417]],
    [ "Netz Oberösterreich",[13.743951182042595, 48.000813108375525]],
    [ "KWG",[13.769654633665487, 48.05818979563454]],
    [ "Wiener Netze",[16.517881484548123, 47.99452793814519]],
    [ "Wiener Netze",[16.517490563475935, 47.992212467193916]],
    [ "Wiener Netze",[16.5148597, 47.990703399999994]],
    [ "Wiener Netze",[16.50922925, 47.9857995]],
    [ "Wiener Netze",[16.52114924058948, 47.99523218052524]],
    [ "Wiener Netze",[16.524784307962236, 47.99458693177513]],
    [ "Wiener Netze",[16.52939553259044, 47.99635069821351]],
    [ "Wiener Netze",[16.532186799999998, 47.99887665000001]],
    [ "Wiener Netze",[16.51034130621967, 48.01501914529374]],
    [ "Wiener Netze",[16.48651365, 47.99366675000001]],
    [ "Wiener Netze",[16.495721850000002, 48.021992749999995]],
    [ "Umspannwerk Treibach",[14.461833276218291, 46.85630313969994]],
    [ "Wiener Netze",[16.4726001, 48.0021403]],
    [ "Netz NÖ",[15.69039395, 48.40873165]],
    [ "Wiener Netze",[16.454910243390614, 48.018026817751796]],
    [ "Wiener Netze",[16.461900456888664, 48.022481999697746]],
    [ "Wiener Netze",[16.464690157058055, 48.01659313113218]],
    [ "Wiener Netze",[16.462032632508063, 48.01613239232499]],
    [ "Wiener Netze",[16.456179900000002, 48.01453890000001]],
    [ "KNG-Kärnten Netz GmbH",[13.536443095237756, 46.90659065460961]],
    [ "Netz NÖ",[16.118132937875384, 48.152418476874026]],
    [ "Wiener Netze",[16.17666105, 48.18353215]],
    [ "Netz NÖ",[16.116026723370837, 48.15461140445691]],
    [ "Netz NÖ",[16.120055209516853, 48.165387970735665]],
    [ "Netz NÖ",[16.124734450000002, 48.1683164]],
    [ "Wiener Netze",[16.1519543, 48.136275049999995]],
    [ "Energie Steiermark",[15.48237635, 46.90000395]],
    [ "Rohrerberg",[15.411375, 47.127931600000004]],
    [ "Wiener Netze",[16.256000973303493, 48.09671158927528]],
    [ "Wiener Netze",[16.297979331923827, 48.09978454651081]],
    [ "Wiener Netze",[16.61144865, 48.12077215]],
    [ "Wiener Netze",[16.59909475, 48.12197199999999]],
    [ "Netz NÖ",[16.10396642446845, 48.163803433899176]],
    [ "Netz NÖ",[16.10164962821511, 48.17015411732439]],
    [ "Netz NÖ",[16.128170841869505, 48.168613125142606]],
    [ "Netz NÖ",[16.14262694752473, 48.17023947629421]],
    [ "Netz NÖ",[16.151923769587814, 48.17396580224892]],
    [ "Wiener Netze",[16.45983561338313, 48.332007583618626]],
    [ "Schaltwerk Weibern",[13.702932763321215, 48.17372223089142]],
    [ "Wiener Netze",[16.362455599999997, 48.30867964999999]],
    [ "Netz Niederösterreich GmbH",[15.807239833437249, 47.70795925051002]],
    [ "TST Kleinau Großau Hirschwang Rax Talstation",[15.804957052669856, 47.714266183084256]],
    [ "Netz Niederösterreich GmbH",[15.807127235655905, 47.70886242830422]],
    [ "Messendorfgrund",[15.479295700000003, 47.04337985]],
    [ "Netz NÖ",[16.494115279242166, 47.94327870373867]],
    [ "Netz NÖ",[16.452620899999996, 47.949978550000004]],
    [ "Netz NÖ",[16.451689315849155, 47.944730546165275]],
    [ "Netz NÖ",[16.447105454797484, 47.94474853468555]],
    [ "Netz NÖ",[16.425106732106755, 47.91941700313137]],
    [ "Netz NÖ",[16.511323750000003, 47.96211575]],
    [ "Netz NÖ",[16.509684649999997, 47.9610256]],
    [ "Netz NÖ",[16.495956213054836, 47.96998351713526]],
    [ "Netz NÖ",[16.49533415, 47.971354399999996]],
    [ "Netz NÖ",[16.502852909969892, 47.97558706805813]],
    [ "Netz NÖ",[16.50965115, 47.97735665]],
    [ "Netz NÖ",[16.51031635, 47.97541705]],
    [ "Netz NÖ",[16.561341449999997, 47.98993244999999]],
    [ "Netz NÖ",[16.56056323477471, 47.98374788618849]],
    [ "Netz NÖ",[16.55726502206887, 47.98406658459739]],
    [ "Netz NÖ",[16.5696532, 48.0060617]],
    [ "Netz NÖ",[16.5807914, 48.015802900000004]],
    [ "Netz NÖ",[16.583027599999998, 48.0153186]],
    [ "Netz NÖ",[16.59099755442343, 48.01498483017535]],
    [ "Netz NÖ",[16.584185498258087, 48.01783223328453]],
    [ "Netz NÖ",[16.571274489898542, 48.02201401689159]],
    [ "Netz NÖ",[16.588450549999997, 48.0244079]],
    [ "Netz NÖ",[16.58357104356381, 48.0111148210299]],
    [ "Netz NÖ",[16.58517508444601, 48.009108317755796]],
    [ "Netz NÖ",[16.53855315533977, 47.96664058158042]],
    [ "Netz NÖ",[16.580419025753486, 47.93720650793077]],
    [ "Netz NÖ",[16.577646171794473, 47.94081644662644]],
    [ "Netz NÖ",[16.579111529985425, 47.945226097953075]],
    [ "Netz NÖ",[16.572967031453686, 47.94734185782402]],
    [ "Netz NÖ",[16.57057775034357, 47.945365483452406]],
    [ "Netz NÖ",[16.5649524427037, 47.94518257022502]],
    [ "Netz NÖ",[16.562056697827785, 47.94730253379754]],
    [ "Netz NÖ",[16.583973467448065, 47.94804544358136]],
    [ "Netz NÖ",[16.5817194, 47.948908749999994]],
    [ "Netz NÖ",[16.587533999999998, 47.959042649999994]],
    [ "Energie AG",[13.53540620022475, 48.15138043884112]],
    [ "Umrichterwerk Uttendorf",[12.56731458648846, 47.261555428510654]],
    [ "Wiener Netze",[16.214146207459063, 48.00295533320738]],
    [ "Wiener Netze",[16.315313256840767, 48.06301987387788]],
    [ "Wiener Netze",[16.318289476941448, 48.06018415517631]],
    [ "Wiener Netze",[16.2251559592326, 48.01254517938503]],
    [ "Wiener Netze",[16.2279432091535, 48.009683924076384]],
    [ "Netz NÖ",[16.594485549999995, 47.96557915]],
    [ "Netz NÖ",[16.599439732918807, 47.96808499903928]],
    [ "Netz NÖ",[16.6019938, 47.97148075]],
    [ "Netz NÖ",[16.59861185080115, 47.97266683363015]],
    [ "Netz NÖ",[16.60101632752403, 47.97480216835935]],
    [ "Netz NÖ",[16.5959041, 47.974203700000004]],
    [ "Netz NÖ",[16.59522643053602, 47.97709362968195]],
    [ "Netz NÖ",[16.6043931, 47.97610935]],
    [ "Netz NÖ",[16.605670683857614, 47.97635864556489]],
    [ "Netz NÖ",[16.60084005, 47.97921285]],
    [ "Netz NÖ",[16.604751583441292, 47.97978779556157]],
    [ "Netz NÖ",[16.60698875278247, 47.97375306901222]],
    [ "Netz NÖ",[16.61005025, 47.9753563]],
    [ "Netz NÖ",[16.6190523, 47.975949]],
    [ "Netz NÖ",[16.62744350907816, 47.98163161807686]],
    [ "Netz NÖ",[16.57395609424769, 47.97735548392358]],
    [ "Netz NÖ",[16.5711053, 47.97785899999999]],
    [ "Netz NÖ",[16.59637075, 48.00503619999999]],
    [ "Netz NÖ",[16.65068222052966, 48.00414107556892]],
    [ "Neufeldweg Siedlung",[15.465972600000002, 47.045548100000005]],
    [ "Wiener Netze",[16.29288791704254, 47.846538500415576]],
    [ "TST Neunkirchen Triesterstraße",[16.06960235, 47.718382150000004]],
    [ "TST Neunkirchen Spinnereiweg",[16.069702550000002, 47.71956624999999]],
    [ "Wiener Netze",[16.203072199999998, 47.96053224999999]],
    [ "Wiener Netze",[16.205838841927754, 47.96135337640363]],
    [ "Wiener Netze",[16.209470699999997, 47.96434765000001]],
    [ "Wiener Netze",[16.213451250000002, 47.965997949999995]],
    [ "Wiener Netze",[16.181846399999994, 47.91503705]],
    [ "TST Puchberg Neunkirchner Straße",[15.925626483025692, 47.77408005089464]],
    [ "Netz Niederösterreich GmbH",[15.999952449999997, 47.722988400000006]],
    [ "Wiener Netze",[16.181595738426115, 47.91936862368537]],
    [ "TST Rohrbachgraben 1",[15.9091009, 47.75907205]],
    [ "TST Rohrbachgraben 2",[15.909023781143276, 47.759095252524276]],
    [ "Netz Niederösterreich GmbH",[15.959495099999996, 47.795733549999994]],
    [ "Netz Niederösterreich GmbH",[15.974579049999996, 47.79957535]],
    [ "TST Puchberg Schneebergdörfl",[15.866767076041661, 47.778072919025696]],
    [ "Netz NÖ",[15.986451417132047, 47.83664349448993]],
    [ "Netz Niederösterreich GmbH",[16.079754122497082, 47.83732911674371]],
    [ "Netz Niederösterreich GmbH",[16.058112087265602, 47.81545813478869]],
    [ "TST Muthmannsdorf",[16.110340307386533, 47.838503245562826]],
    [ "Netz Niederösterreich GmbH",[16.0730763, 47.83191905000001]],
    [ "TST Grafenbach Austraße",[16.007256930288534, 47.68985269466118]],
    [ "TST Gloggnitz Schulgasse",[15.93351443397934, 47.674336806783096]],
    [ "Wiener Netze",[16.296108049999997, 48.09004360000001]],
    [ "SSE Stephanshart",[14.831636549999999, 48.157202500000004]],
    [ "Wiener Netze",[16.257033103302003, 47.99047774907981]],
    [ "TST Natschbach Wohnhausanlage Frieden",[16.104696122582787, 47.712247141492234]],
    [ "TST Ramplach Mühlgasse",[16.091930366644405, 47.69929324995939]],
    [ "TST Neusiedl Wassergasse",[16.11064965496876, 47.7773232789969]],
    [ "TST Natschbach",[16.09739325, 47.708825850000004]],
    [ "TST Gerasdorf Strafanstalt",[16.075878984304886, 47.78183813850405]],
    [ "TST Neusiedl am Steinfeld Siedlung",[16.135271433713104, 47.765508599149314]],
    [ "TST Gloggnitz Post",[16.104777795118004, 47.712072269796884]],
    [ "TST Mollram",[16.0862657233458, 47.758935055503606]],
    [ "TST Loipersbach Augasse",[16.10843132967713, 47.71765354630819]],
    [ "Netz Oberösterreich",[13.511586758924174, 47.71855179988125]],
    [ "Netz Oberösterreich",[13.185468302996684, 47.32157808614042]],
    [ "Netz OÖ",[13.19927710429204, 47.31734521802776]],
    [ "TST Dunkelstein Schoellergasse",[16.03848186757322, 47.71436274559531]],
    [ "Netz Niederösterreich GmbH",[16.045761232325585, 47.718726084828155]],
    [ "Netz Niederösterreich GmbH",[16.111504616063925, 47.80754159705543]],
    [ "Netz Niederösterreich GmbH",[16.091270834369848, 47.795370486726725]],
    [ "TST Dunkelstein Wohnanlage",[16.046154457151353, 47.71418821493907]],
    [ "TST Willendorf Dörfles",[16.0828028, 47.797126049999996]],
    [ "Umspannwerk Modriach",[15.058687850399695, 46.9641367820424]],
    [ "Netz Niederösterreich GmbH",[16.2123086, 47.73300635]],
    [ "TST Breitenau Schwarzer Weg",[16.1474562340027, 47.73172449097361]],
    [ "Netz Niederösterreich GmbH",[16.1928894829727, 47.72525294944559]],
    [ "Netz Niederösterreich GmbH",[16.254836306829553, 47.681812819267705]],
    [ "Netz Niederösterreich GmbH",[16.152837770286208, 47.7200982065372]],
    [ "TST Bad Erlach Thermenstraße",[16.208040250000003, 47.734415999999996]],
    [ "TST Grafenbach Philharmonikergasse",[16.0028727, 47.688950150000004]],
    [ "TST Bad Erlach Linsberg",[16.20214412873751, 47.72787609635597]],
    [ "Netz Niederösterreich GmbH",[16.138616, 47.7328812]],
    [ "TST Breitenau Gartengasse",[16.145601411956967, 47.7375688884578]],
    [ "TST Bad Erlach Bahnhofsiedlung",[16.211485949753282, 47.72694398771405]],
    [ "Netz Niederösterreich GmbH",[16.21450136814924, 47.73108980582685]],
    [ "TST Putzmannsdorf Hainbuchengasse",[15.995350175937538, 47.6855173170078]],
    [ "Netz Niederösterreich GmbH",[16.089403428880406, 47.70090116152708]],
    [ "Netz Niederösterreich GmbH",[16.130161420410364, 47.654079942329346]],
    [ "Netz NÖ",[16.100660025278323, 47.57132014138834]],
    [ "TST Grafenbach Fleischmarkt",[16.01585151566537, 47.694425498518534]],
    [ "Netz Niederösterreich",[16.05163493042807, 47.599627457913606]],
    [ "Netz Niederösterreich",[16.099951082771337, 47.58650801605547]],
    [ "Netz Niederösterreich",[16.091625665977947, 47.65563748560649]],
    [ "Netz Niederösterreich",[16.109379457146588, 47.65359677842677]],
    [ "Netz Niederösterreich",[16.099750610612627, 47.5799095886633]],
    [ "TST Seebenstein Sauternerstraße",[16.146927378718388, 47.70557137798798]],
    [ "Netz Niederösterreich GmbH",[16.23079473053471, 47.72530289213218]],
    [ "Netz Niederösterreich GmbH",[16.23385748327264, 47.67057489963584]],
    [ "Netz Niederösterreich GmbH",[16.25042295, 47.66497629999999]],
    [ "Netz Niederösterreich GmbH",[16.269728532293485, 47.667706501099026]],
    [ "Netz Niederösterreich GmbH",[16.276741770140777, 47.66007262475388]],
    [ "Netz Niederösterreich GmbH",[16.20692657260996, 47.67081038387631]],
    [ "Netz NÖ",[16.06542927526259, 48.14959390815226]],
    [ "Netz NÖ",[16.081656726800833, 48.15448370358965]],
    [ "Netz NÖ",[16.07345055, 48.158452499999996]],
    [ "TST Grafenbach Ernst Gruberstraße",[16.005843418381005, 47.68416960751492]],
    [ "TST Reichenau Lokalbahnhof",[15.838749749999998, 47.698666149999994]],
    [ "TST Putzmannsdorf Köttlachergasse",[15.993676066462266, 47.691179749623224]],
    [ "Wiener Netze",[16.167079800000003, 48.19883075]],
    [ "KW Dießbach",[12.818253894580296, 47.49074490561087]],
    [ "Umspannwerk Lend",[13.049971435308304, 47.293900554142475]],
    [ "TST St. Johann Wegscheiderstraße",[16.03033365, 47.71540460000001]],
    [ "Wiener Netze",[16.329578515614326, 48.07769447112076]],
    [ "TST Asparn Lutz",[16.0164222, 48.316500999999995]],
    [ "KNG-Kärnten Netz GmbH",[13.706595670581695, 46.79818747473592]],
    [ "KNG-Kärnten Netz GmbH",[13.719596595635144, 46.795664206981215]],
    [ "KNG-Kärnten Netz GmbH",[13.5104805, 46.816744850000006]],
    [ "Netz Niederösterreich GmbH",[16.22915917741544, 47.80856159506013]],
    [ "TST Wr. Neustadt Willendorfergasse",[16.21724157279939, 47.80651881353883]],
    [ "TST Köttlach Industriestraße",[15.982557577932702, 47.68028851641571]],
    [ "Netz Niederösterreich GmbH",[16.0039838, 48.7667942]],
    [ "TST Haugsdorf Billa",[16.083583150000003, 48.70288735]],
    [ "TST Kleinhaugsdorf",[16.064155443420308, 48.75359385101281]],
    [ "TST Alberndorf OST",[16.107570121219357, 48.70830745339087]],
    [ "Netz Niederösterreich GmbH",[15.977141615351549, 48.78608284672097]],
    [ "Netz Niederösterreich GmbH",[16.100204700000003, 48.62973675]],
    [ "Netz Niederösterreich GmbH",[16.09782285, 48.62774079999999]],
    [ "TST Untermarkersdorf Ost",[16.1236535, 48.70643140000001]],
    [ "TST Hadres Kellergasse",[16.128362741507193, 48.71825559933103]],
    [ "TST Untermarkersdorf Kellergasse",[16.113368824937048, 48.71356840506186]],
    [ "Netz Niederösterreich GmbH",[16.00903361451953, 48.76295454355858]],
    [ "TST Neunkirchen Raimundweg",[16.076369075038187, 47.71937030607868]],
    [ "TST St. Johann",[16.01466511185328, 47.72164057497295]],
    [ "Wiener Netze",[16.220339149450762, 47.96022582470525]],
    [ "TST Alberndorf Hainburgersiedlung",[16.104260973365996, 48.70450945905777]],
    [ "TST Haugsdorf Berggasse",[16.07889231768025, 48.70316323402836]],
    [ "TST Hadres Mitte",[16.13057305, 48.70931310000001]],
    [ "TST Haugsdorf 2",[16.079384693654482, 48.71106181088405]],
    [ "TST Jetzelsdorf 2",[16.059333420646237, 48.709738032756526]],
    [ "TST Alberndorf Bundesstraße",[16.098522267272273, 48.702877570426345]],
    [ "TST Hadres Feuerwehrhaus",[16.131029438115174, 48.71231467209973]],
    [ "Netz Niederösterreich GmbH",[16.124710315601277, 48.707826232756865]],
    [ "TST Guntersdorf 1",[16.053906700000002, 48.64799925]],
    [ "TST Guntersdorf Sportplatzgasse",[16.0428115, 48.651759250000005]],
    [ "TST Kalladorf",[16.085442915875458, 48.64185625203914]],
    [ "Netz Niederösterreich GmbH",[16.07338832000844, 48.61727440373097]],
    [ "Netz Niederösterreich GmbH",[16.048893101690982, 48.64445414956577]],
    [ "Netz Niederösterreich GmbH",[16.069917649241066, 48.601210547162154]],
    [ "Netz Niederösterreich GmbH",[15.995560729588567, 48.7690653049226]],
    [ "Höbmannsbach",[13.516744702531005, 48.43273604610854]],
    [ "Unterer Plattenweg",[15.460462000000001, 47.100392150000005]],
    [ "Netz Niederösterreich GmbH",[16.099226299999998, 48.50572304999999]],
    [ "Netz Niederösterreich GmbH",[16.10597688080041, 48.50445445821908]],
    [ "TST Göllersdorf Hollabrunnerstraße",[16.11524659901466, 48.49526591707527]],
    [ "Netz Niederösterreich GmbH",[16.024981475012645, 48.703646855943354]],
    [ "Netz Niederösterreich GmbH",[16.00623385, 48.699645950000004]],
    [ "Netz Niederösterreich GmbH",[16.0203628192897, 48.700648533460516]],
    [ "Netz Niederösterreich GmbH",[16.01436852366112, 48.69932864719908]],
    [ "Netz Niederösterreich GmbH",[16.029886965275516, 47.705824647223906]],
    [ "TST Grafenbach Auriegelgasse",[16.008104449999998, 47.686789000000005]],
    [ "Umspannwerk Saalfelden",[12.865106349923085, 47.42281717966914]],
    [ "Messendorfberg 152",[15.49405138032927, 47.03951047220025]],
    [ "Umspannwerk Fulpmes",[11.342125928031415, 47.1374021074094]],
    [ "KNG-Kärnten Netz GmbH",[13.606133908735918, 46.94139957965354]],
    [ "KNG-Kärnten Netz GmbH",[13.51163968115335, 46.88043227420318]],
    [ "KNG-Kärnten Netz GmbH",[13.541359499999999, 46.897158899999994]],
    [ "Landfrass Siedlung",[13.530388962286676, 46.89709981621965]],
    [ "KNG-Kärnten Netz GmbH",[13.523956663917838, 46.89255824222792]],
    [ "KNG-Kärnten Netz GmbH",[13.510425988829114, 46.883755852739036]],
    [ "KNG-Kärnten Netz GmbH",[13.511651897984017, 46.88680295110329]],
    [ "TST Alberndorf Seetraße",[16.101009817167462, 48.699973750798705]],
    [ "TST Untermarkersdorf Mitte",[16.11621472424307, 48.7101267539292]],
    [ "Reintalweg",[15.493798399999998, 47.05866425]],
    [ "Netz Niederösterreich GmbH",[16.390926349999997, 48.71826985]],
    [ "Netz Niederösterreich GmbH",[16.21606963140785, 48.702558974835306]],
    [ "TST Zwingendorf 1",[16.2322045, 48.70342185]],
    [ "Netz Niederösterreich GmbH",[16.241880692557928, 48.70725749770193]],
    [ "Netz Niederösterreich GmbH",[16.37569585, 48.712730699999994]],
    [ "Netz Niederösterreich GmbH",[15.94998225, 48.7494265]],
    [ "Netz Niederösterreich GmbH",[15.924869150000001, 48.6939696]],
    [ "Netz Niederösterreich GmbH",[15.885416722298032, 48.69852364561215]],
    [ "Netz Niederösterreich GmbH",[16.026721271094804, 48.727316553200254]],
    [ "Netz Niederösterreich GmbH",[15.94126035, 48.7452424]],
    [ "Netz Niederösterreich GmbH",[15.850099181335262, 48.6972235562839]],
    [ "TST Schrattenthal Siedlung",[15.907509832994664, 48.719571895519145]],
    [ "TST Obernalb 2",[15.945070442894602, 48.748387128841756]],
    [ "TST Schrattenthal 1",[15.909886777237537, 48.713262544583735]],
    [ "Netz Niederösterreich GmbH",[15.831679150000001, 48.6974405]],
    [ "1257255114",[15.406061039822474, 47.07146127835499]],
    [ "Netz Niederösterreich GmbH",[16.205081550000003, 48.33631455000001]],
    [ "Unterwerk Absengerstraße",[15.398166939037194, 47.06871161871675]],
    [ "KNG-Kärnten Netz GmbH",[13.502239100000002, 46.793422899999996]],
    [ "KNG-Kärnten Netz GmbH",[13.489687631543559, 46.79260265447266]],
    [ "KNG-Kärnten Netz GmbH",[13.4885992, 46.79313809999999]],
    [ "TST Sautern",[16.157986449999996, 47.70500635]],
    [ "TST Haderswörth Föhrenau",[16.204022923781093, 47.73951593651741]],
    [ "TST Seebenstein Aspangerstraße",[16.1351549, 47.69665085]],
    [ "TST Haderswörth Föhrenau 3. Quergasse",[16.202567000000002, 47.745286549999996]],
    [ "SST Breitenau Ost",[16.15573335270663, 47.73289283804698]],
    [ "TST Haderswörth Föhrenau Golfstraße",[16.205312618913627, 47.74090375516658]],
    [ "TST Schwarzau Nord",[16.178081965419146, 47.73401799732066]],
    [ "TST Haderswörth Kreisverkehr",[16.210989283515474, 47.73741529968507]],
    [ "TST Schwarzau Föhrenau Siedlung",[16.199882166045903, 47.744206424477056]],
    [ "TST Pitten Niebelungenstraße",[16.17683043184932, 47.71370785606589]],
    [ "Netz Niederösterreich GmbH",[16.13391584552978, 47.68915602816851]],
    [ "TST Neunkirchen Wartmannstetterstraße",[16.07753111088163, 47.71719518106217]],
    [ "KNG-Kärnten Netz GmbH",[13.186065566723121, 46.939070156710294]],
    [ "KNG-Kärnten Netz GmbH",[13.164411599463136, 46.93605910419355]],
    [ "Umspannwerk Zettling",[15.444648105563715, 46.957322701279125]],
    [ "TST Pottsch Kreuzäckergasse",[16.02148108440323, 47.710246074254506]],
    [ "Netz NÖ",[15.897509502890241, 48.10965977705036]],
    [ "Netz NÖ",[15.89686275, 48.10385409999999]],
    [ "Netz NÖ",[15.884865526901331, 48.116109060578594]],
    [ "Netz NÖ",[15.882251700000001, 48.11072215]],
    [ "Netz NÖ",[15.881114700000001, 48.103497399999995]],
    [ "Netz NÖ",[15.920323869113437, 48.08207497429286]],
    [ "Netz NÖ",[15.886463671023906, 48.095173274511716]],
    [ "Netz NÖ",[15.877451831915605, 48.09503183493306]],
    [ "Netz NÖ",[15.867710633863684, 48.08753670166962]],
    [ "Netz NÖ",[16.75667841806869, 48.35273574298987]],
    [ "Netz Niederösterreich GmbH",[14.820573863048306, 48.158478064180976]],
    [ "Netz Niederösterreich GmbH",[15.404353125542896, 48.29728365827332]],
    [ "Netz Niederösterreich GmbH",[15.587781599999998, 48.4036428]],
    [ "TST Donaudorf",[15.05326115, 48.193544450000005]],
    [ "Netz Niederösterreich GmbH",[14.872798626353608, 48.15508355327175]],
    [ "TST Viehdoef Berger",[14.887839314628843, 48.15066712837167]],
    [ "Netz Niederösterreich GmbH",[15.03659215, 48.1939978]],
    [ "1267903290",[14.936910225614414, 48.25912954690157]],
    [ "1267903295",[14.905229500984873, 48.24101674763648]],
    [ "1267903296",[14.953585144188624, 48.26598795986679]],
    [ "1267934340",[14.952546448274198, 48.34119200054633]],
    [ "1267934350",[14.946861409456409, 48.29542800665016]],
    [ "1267934351",[14.962284915292326, 48.2866914087673]],
    [ "1267934356",[14.920734679951597, 48.30884386926986]],
    [ "1268410084",[14.674522849584712, 48.352186400189844]],
    [ "Wiener Netze",[16.235296799999997, 48.01295024999999]],
    [ "Wiener Netze",[16.299789032044785, 48.01426888965713]],
    [ "Wiener Netze",[16.297160799999997, 48.012475450000004]],
    [ "Millstatt Kleindombra",[13.581486116191154, 46.80764944938322]],
    [ "Wiener Netze",[16.35152365310302, 48.08504313135976]],
    [ "Wiener Netze",[16.349447722682868, 48.08543184694183]],
    [ "Wiener Netze",[16.34870681939191, 48.08617827328639]],
    [ "Wiener Netze",[16.346829960660013, 48.08939072151691]],
    [ "Wiener Netze",[16.35291617297447, 48.08835579581823]],
    [ "Wiener Netze",[16.355072200000002, 48.0837783]],
    [ "Wiener Netze",[16.35409865692827, 48.08268997566978]],
    [ "Wiener Netze",[16.33801522442562, 48.08421864420998]],
    [ "Netz Niederösterreich GmbH",[16.217213100000002, 47.81630985]],
    [ "Netz Niederösterreich GmbH",[16.222357179951427, 47.813525557627784]],
    [ "Netz Niederösterreich GmbH",[16.22018266679889, 47.811220199351595]],
    [ "Netz Niederösterreich GmbH",[16.234424150000002, 47.80863415000001]],
    [ "Netz NÖ",[16.055284250000003, 48.042089499999996]],
    [ "Netz NÖ",[16.053222147695504, 48.04564927333019]],
    [ "Netz NÖ",[16.05247047422066, 48.050807505642744]],
    [ "TST Payerbach Ost",[15.873668150000002, 47.6917931]],
    [ "Netz NÖ",[16.261100117765963, 48.338415719033904]],
    [ "Wiener Netze",[16.175808271778433, 48.153975647638646]],
    [ "Wiener Netze",[16.1729008, 48.155762949999996]],
    [ "Wiener Netze",[16.184042650000002, 48.15445835]],
    [ "Wiener Netze",[16.18125445, 48.15234505000001]],
    [ "Wiener Netze",[16.289645415548506, 48.02419339482257]],
    [ "Wiener Netze",[16.29071453092977, 48.02045453486084]],
    [ "Wiener Netze",[16.29433236810282, 48.022916848044964]],
    [ "Wiener Netze",[16.291020076539635, 48.01739468128101]],
    [ "Mösendorf",[13.467655625447572, 47.98661737318707]],
    [ "Wiener Netze",[16.251050207336316, 48.00788378594031]],
    [ "Am Schlatt",[9.66504048075534, 47.4414669559816]],
    [ "TST Sautern Nord",[16.16281020266174, 47.70971608194274]],
    [ "TST Pitten An Der Pitten",[16.183185217974085, 47.71636899352145]],
    [ "Wiener Netze",[16.2521872, 47.876422749999996]],
    [ "TST Winzendorf Ost",[16.117153300000005, 47.8115439]],
    [ "TST Gloggnitz Rosengasse",[15.934952199999998, 47.67726544999999]],
    [ "TST Willendorf Strelzhof",[16.07311075, 47.79772594999999]],
    [ "TST Weikersdorf IAG",[16.16165585, 47.80662614999999]],
    [ "TST Weikersdorf Regina Küchen",[16.169492125844517, 47.80671120587163]],
    [ "TST Brunn Deponie Weikersdorf",[16.17946052646442, 47.80683434522259]],
    [ "Netz Niederösterreich GmbH",[16.196645579329957, 47.8072004575343]],
    [ "TST Willendorf Klammweg",[16.056904499999998, 47.784914099999995]],
    [ "TST Weikersdorf Indistriegebiet",[16.17618582481016, 47.806606405910806]],
    [ "Netz Niederösterreich GmbH",[16.169377525844514, 47.80670520587162]],
    [ "TST Brunn Steinfeld",[16.171824100000002, 47.806787900000025]],
    [ "TST Weikersdorf Industriestraße II",[16.16699197556715, 47.80689250367745]],
    [ "TST Hettmannsdorf Nord",[16.05074593237905, 47.771501299079794]],
    [ "TST Winzendorf Römerweg",[16.110725900000002, 47.8102465]],
    [ "Energie AG",[13.533004609317233, 48.533480562601795]],
    [ "Wiener Netze",[16.473842856762925, 47.99641982761101]],
    [ "1283598277",[15.446631256335468, 47.05179263126579]],
    [ "Wiener Netze",[16.524601534118677, 47.998204416261714]],
    [ "TST Ternitz Morriegelgasse",[16.038158517102577, 47.715389196753854]],
    [ "TST Neunkirchen Stadtamt",[16.0821411899492, 47.72019587455422]],
    [ "Netz Niederösterreich GmbH",[16.576520080560503, 48.562446925425725]],
    [ "Netz Niederösterreich GmbH",[16.56680426780778, 48.56656340443588]],
    [ "Netz Niederösterreich GmbH",[16.58285538744326, 48.56780692982674]],
    [ "Netz Niederösterreich GmbH",[16.572390926462035, 48.570971096925]],
    [ "Netz Niederösterreich GmbH",[16.5773405, 48.56286645]],
    [ "TST Wiener Neustadt BHF-City",[16.233491016932316, 47.81237233345393]],
    [ "TST Theresienfeld Grazerstraße 57",[16.249267174098293, 47.85171803959919]],
    [ "Netz NÖ",[16.30405895, 47.95955409999999]],
    [ "Netz NÖ",[15.97478841778636, 48.165292734426096]],
    [ "Netz NÖ",[15.968981100000002, 48.16815315]],
    [ "Netz NÖ",[15.9610052, 48.16743295]],
    [ "TST Bad Erlach Oberfeldgasse",[16.217571355488868, 47.72480477883622]],
    [ "TST Gloggnitz Wienerstraße",[15.94583595, 47.67312764999999]],
    [ "TST Enzenreith Eder",[15.954786990809867, 47.670808531305205]],
    [ "KW Gratkorn",[15.36967459683103, 47.11815589771687]],
    [ "Wiener Netze",[16.493769249999996, 48.01711224999999]],
    [ "Wiener Netze GmbH",[16.222860817519607, 47.89186909946895]],
    [ "TST Rohr im Gebirge Furtner",[15.77129855, 47.9074859]],
    [ "Netz Niederösterreich GmbH",[15.982232450000001, 47.8684835]],
    [ "TST Rohr im Gebirge Gegend",[15.7188283, 47.88967764999999]],
    [ "TST Gutenstein Dorf",[15.894865266087816, 47.87674998331114]],
    [ "TST Neusiedl/Pernitz Hauptstraße",[15.958345466268437, 47.88394665343141]],
    [ "TST Rohr Brunntal",[15.78503116678942, 47.90625735499608]],
    [ "TST Gutenstein Straßenmeisterei",[15.915270622232853, 47.88068310277512]],
    [ "Pernitz Siedlung",[15.9433644, 47.89182819999999]],
    [ "TST Steinapiesting Tiefenbacher",[15.850464949999997, 47.8929618]],
    [ "Netz Niederösterreich GmbH",[15.946818681948121, 47.892053998708604]],
    [ "TST Rohr im Gebirge Klausbachgraben",[15.754635299999995, 47.91015245]],
    [ "Netz Niederösterreich GmbH",[15.954912650000002, 47.8953516]],
    [ "TST Schwarzau/Geb. Pirkner",[15.732314033272123, 47.77954725439188]],
    [ "Netz Niederösterreich GmbH",[15.994387349999997, 47.86656374999999]],
    [ "Wiener Netze",[16.159946699999995, 48.07101234999999]],
    [ "Netz NÖ",[15.9679886, 48.146945599999995]],
    [ "Netz NÖ",[15.955609299999997, 48.155709349999995]],
    [ "Netz NÖ",[15.95002095, 48.156103]],
    [ "Netz NÖ",[15.950680044799515, 48.15732494170493]],
    [ "Netz NÖ",[16.034993024390943, 48.060454806018925]],
    [ "TST Theresienfeld Lindenweg",[16.23122055, 47.8553667]],
    [ "Netz Niederösterreich GmbH",[16.243910500000002, 47.84678560000001]],
    [ "Netz NÖ",[16.060668468675573, 48.16018388002891]],
    [ "Netz NÖ",[16.048233250000003, 48.154274699999995]],
    [ "Netz NÖ",[16.073040174515594, 48.16481594077751]],
    [ "Netz NÖ",[16.07500965, 48.168967599999995]],
    [ "Am Blumenhang Box Süd",[15.48399241188048, 47.06856762500127]],
    [ "Hubertusstrasse",[15.476028450000003, 47.04936085000001]],
    [ "Wiener Netze",[16.289535787877885, 48.0119383755087]],
    [ "Wiener Netze",[16.2923965252639, 48.01064403284987]],
    [ "Energienetze Steiermark",[15.502787734302215, 47.015529564877966]],
    [ "Energienetze Steiermark",[15.49222879577159, 47.00354660867497]],
    [ "Energienetze Steiermark",[15.500028157919186, 47.01822452501392]],
    [ "Energienetze Steiermark",[15.499947760235472, 47.019599125711494]],
    [ "Energienetze Steiermark",[15.514220216150493, 47.029916911723674]],
    [ "TST Schmidsdorf Küb",[15.889856582047347, 47.684488122467265]],
    [ "TST Reichenau Schulgasse",[15.840939679813413, 47.69602805255819]],
    [ "1301961012",[15.499182900000001, 47.1112414]],
    [ "1302497675",[15.42085636488206, 47.08580769638971]],
    [ "Martinhofstraße",[15.392639999999998, 47.03498464999999]],
    [ "Ankerstrasse Siedlung",[15.39541243300881, 47.036234848642344]],
    [ "TST Wr.Neustadt Volksbadgasse",[16.25671015, 47.828623900000004]],
    [ "TST Wr.Neustadt Gartenstadt",[16.2604991, 47.81939585000001]],
    [ "TST Wr.Neustadt Stadiongasse Porschesiedlung",[16.254337519104897, 47.825268201852445]],
    [ "TST Wr.Neustadt Ganglbergergasse",[16.263304971871104, 47.81829735483429]],
    [ "TST WRN Wohnanlage",[16.2550119, 47.8276038]],
    [ "TST Wr.Neustadt Neue Bezirkshauptmannschaft",[16.250944184279263, 47.81218258098376]],
    [ "Netz Niederösterreich GmbH",[16.248159782263286, 47.816477757895164]],
    [ "Strassgang Camping",[15.397068558669977, 47.02493453016759]],
    [ "Wiener Netze",[16.28938068369677, 47.942823478968265]],
    [ "Umspannwerk Speising",[16.288934512470416, 48.17097097948799]],
    [ "Oberlangkampfen Schule",[12.087754272082849, 47.52382227921487]],
    [ "TST Trübensee Tankstelle",[16.056086531301734, 48.3631720571106]],
    [ "Netz Niederösterreich GmbH",[16.019557, 48.31714355]],
    [ "TST Stockerau Neubau",[16.21341876616569, 48.38397102918432]],
    [ "Netz Niederösterreich GmbH",[16.218241489146628, 48.386519873515326]],
    [ "Netz Niederösterreich GmbH",[16.0266777, 48.322647950000004]],
    [ "Netz Niederösterreich GmbH",[16.01929655, 48.31875195000001]],
    [ "Netz Niederösterreich GmbH",[16.015649500000002, 48.316563650000006]],
    [ "Netz Niederösterreich GmbH",[16.058624700000003, 48.33755445]],
    [ "Netz Niederösterreich GmbH",[16.01796925, 48.31874045]],
    [ "TST Stockerau Wolfikstraße",[16.205927024492297, 48.3843501089135]],
    [ "Netz Niederösterreich GmbH",[16.020138016874796, 48.32039170113921]],
    [ "TST Asparn Ost",[16.026213006067955, 48.32198207969493]],
    [ "TST Oberolberndorf WAG",[16.1825255, 48.40430190000001]],
    [ "TST Aderklaa Raasdorferstrasse",[16.55728899711322, 48.276350473557855]],
    [ "Netz Niederösterreich GmbH",[16.480187610328667, 48.7493644262672]],
    [ "TST Wartmannstetten",[16.07444095, 47.693537199999994]],
    [ "TST Ramplach",[16.09177185, 47.6959083]],
    [ "TST Lanzenkirchen Mitte",[16.232086250000002, 47.739261299999995]],
    [ "TST Kleinwolkersdorf GEBÖS",[16.25674014580171, 47.75405775543933]],
    [ "TST Lanzenkirchen Marktplatz",[16.2387309, 47.74371245000001]],
    [ "TST Kleinwolkersdorf Leithabrücke",[16.250722250000003, 47.74896875000001]],
    [ "Dr.-Karl-Psenner-Straße 56",[11.7148714, 47.3563206]],
    [ "Fiecht Au 48",[11.699699631628777, 47.35463133469705]],
    [ "TST Mollram Jubiläumsstraße",[16.09139377376679, 47.750456489550764]],
    [ "Netz Niederösterreich GmbH",[16.192297600000003, 47.82879570000001]],
    [ "Netz Niederösterreich GmbH",[16.222560782116958, 47.87402991664992]],
    [ "Netz Niederösterreich GmbH",[16.1698968, 47.82952805]],
    [ "Netz Niederösterreich GmbH",[16.182989576881603, 47.834849703270784]],
    [ "Netz Niederösterreich GmbH",[16.18942805, 47.84540065]],
    [ "TST Steinabrückl Römerstraße 41",[16.198630516563515, 47.85750386536972]],
    [ "TST Neunkirchen Raglitzerstraße",[16.075501381132245, 47.73299128539443]],
    [ "Netz Niederösterreich GmbH",[16.186678610979776, 47.83426622035724]],
    [ "Netz Niederösterreich GmbH",[16.14282671685888, 47.804444083362256]],
    [ "Wiener Netze",[16.310249877572115, 48.10626742290634]],
    [ "Salzburg Netz",[13.10208242928706, 47.9734282812098]],
    [ "Wiener Netze",[16.3579389, 48.112411099999996]],
    [ "Energie Steiermark",[15.48750295, 47.0413017]],
    [ "Wiener Netze",[16.58500088577754, 48.17679951675075]],
    [ "Wiener Netze",[16.40884573051197, 47.97579893883855]],
    [ "Wiener Netze",[16.522768284278005, 48.0433678806327]],
    [ "Wiener Netze",[16.514549456319205, 48.040708035257055]],
    [ "Wiener Netze",[16.51797104241664, 48.043526368589475]],
    [ "Netz Niederösterreich GmbH",[16.284636770839523, 47.65815095284514]],
    [ "Netz Niederösterreich GmbH",[16.205768186758988, 47.68083705324711]],
    [ "Netz Niederösterreich GmbH",[16.24472800286677, 47.777161403997695]],
    [ "Netz Niederösterreich GmbH",[16.24977945, 47.737983]],
    [ "TST Wiesmath",[16.289544455467023, 47.61694407852994]],
    [ "Netz Niederösterreich GmbH",[16.1932814, 47.60290240000001]],
    [ "Netz Niederösterreich GmbH",[16.15670496622161, 47.5405148526855]],
    [ "Netz Niederösterreich GmbH",[16.196175999999998, 47.590654699999995]],
    [ "Netz Niederösterreich GmbH",[16.2050778, 47.662645100000006]],
    [ "Netz Niederösterreich GmbH",[16.18107185, 47.61484850000001]],
    [ "TST Thomasberg Kienegg",[16.162882331228094, 47.62208874065799]],
    [ "Netz Niederösterreich GmbH",[16.16302685, 47.55134495]],
    [ "Netz Niederösterreich GmbH",[16.16953541708213, 47.72518050803221]],
    [ "Wiener Netze",[16.308869299999998, 48.08232005]],
    [ "Gurten Parkhaus",[13.339765702339577, 48.239664102965534]],
    [ "Gurten Siedlung",[13.340767849375048, 48.244902928929555]],
    [ "Gurten Aichberg",[13.345089739292602, 48.241945278710915]],
    [ "St. Peter Ind.",[13.076451554740265, 48.25519945708863]],
    [ "Braunau Rainerstraße",[13.053196072547985, 48.25676059177876]],
    [ "Energie AG",[13.049039126006273, 48.257642848920945]],
    [ "Braunau Verbundamt",[13.04723984031489, 48.258100874476966]],
    [ "Braunau Laaberholzweg",[13.04610020034358, 48.257202930994275]],
    [ "Braunau Zentralschule",[13.04336843344418, 48.25706420573101]],
    [ "Haselbach Mühle",[13.04837149480541, 48.25478982596421]],
    [ "Haselbach Kirche",[13.055006982648745, 48.253213252037725]],
    [ "Braunau Wagnerbauerstraße",[13.064208807786702, 48.25575302167048]],
    [ "Braunau Flurstraße",[13.065037872462604, 48.25287965479673]],
    [ "Braunau Ambergerstraße",[13.057355922094848, 48.26069900616443]],
    [ "Braunau Gartenstadt",[13.054586045247058, 48.26089938651508]],
    [ "Energie AG",[13.05160339361455, 48.261594413177114]],
    [ "Braunau Südtirolerstraße",[13.048873217442642, 48.26152133978129]],
    [ "Braunau Sachsstraße",[13.048814700883943, 48.26001741857053]],
    [ "Braunau Stelzhamerstr.",[13.053482559485719, 48.26318896742527]],
    [ "Braunau Kläranlage",[13.05830200848943, 48.26499438956207]],
    [ "Braunau Höft",[13.060699955821661, 48.26116473308268]],
    [ "Braunau BH",[13.045068944172618, 48.26135945278262]],
    [ "Energie AG",[13.045450756606948, 48.25966579991231]],
    [ "Braunau Rayonsleitung",[13.043616149094731, 48.26013017757868]],
    [ "Energie AG",[13.036737447478231, 48.25911398035085]],
    [ "Braunau Musikschule",[13.035772846405896, 48.258961888761945]],
    [ "Energie AG",[13.035383803053174, 48.25993643719125]],
    [ "Braunau Lerchenfeldgasse",[13.038509570820906, 48.25921249599917]],
    [ "Energie AG",[13.035377841877533, 48.258330249469935]],
    [ "Braunau Rathaus",[13.035856956064098, 48.25784628349984]],
    [ "Braunau Am Berg",[13.036745351334874, 48.256904102499504]],
    [ "Energie AG",[13.037673931336977, 48.25566457989237]],
    [ "Braunau Talstraße",[13.03347806858807, 48.2553338262994]],
    [ "Energie AG",[13.032923685660352, 48.25609812391823]],
    [ "Braunau Schleifmühlgasse",[13.033700331468163, 48.25653641855709]],
    [ "Energie AG",[13.034623749202249, 48.25733247503474]],
    [ "Braunau Poststallgasse",[13.03408265801245, 48.258480100846064]],
    [ "Braunau Hochhaus",[13.035549599669123, 48.25379415266938]],
    [ "Braunau Friedhofstraße",[13.033431828385622, 48.25312335327681]],
    [ "Braunau Zürnstraße",[13.041031682124599, 48.254802987752015]],
    [ "Braunau Handelsakademie",[13.042810837699573, 48.25348983180853]],
    [ "Braunau Arbeiterkammer",[13.035926888497675, 48.25263316811501]],
    [ "Energie AG",[13.035126861625791, 48.250666841104085]],
    [ "Braunau Süd",[13.03240375572161, 48.25051668424839]],
    [ "Braunau Sebastianistr.",[13.035493627761944, 48.246997697837635]],
    [ "Energie AG",[13.033570907463508, 48.24660006283934]],
    [ "Energie AG",[13.035230414646964, 48.24542447179663]],
    [ "Energie AG",[13.034821043556848, 48.24458669567112]],
    [ "Braunau Michaelistrasse",[13.031839093733868, 48.243448251360206]],
    [ "Braunau HTL",[13.029241059887534, 48.24416646452306]],
    [ "Braunau Salzburgerstr.",[13.041167247428021, 48.246255854190814]],
    [ "Braunau Bauhofstrasse",[13.045906911004145, 48.24299336196165]],
    [ "Achinger Totenweg",[13.047428508695408, 48.234911804627785]],
    [ "Braunau Bauhof",[13.051747959014644, 48.24504798056086]],
    [ "Braunau Quellenweg",[13.048373959792464, 48.245890301795356]],
    [ "Braunau Sportplatz",[13.045636492739744, 48.24799070265069]],
    [ "Energie AG",[13.054673902783948, 48.24477888187973]],
    [ "Energie AG",[13.033112538813443, 48.25739764494214]],
    [ "Energie AG",[13.032182353568903, 48.25518469985613]],
    [ "Braunau Lach",[13.055910444012113, 48.24608054838089]],
    [ "Energie AG",[13.056478100831292, 48.2455779491742]],
    [ "Braunau Gasteigerstraße",[13.060944408457946, 48.24739368565987]],
    [ "Braunau Netzservice",[13.062900000323703, 48.24859479196437]],
    [ "Braunau Industriezeile",[13.064125558411805, 48.24902494139268]],
    [ "Braunau Lindachwiese",[13.066957538993682, 48.24995052668096]],
    [ "Braunau EKB 2",[13.06911546361987, 48.250568801656044]],
    [ "Braunau EKB Schaltstation",[13.069223509967031, 48.25059083155186]],
    [ "Braunau Mattigsenke",[13.072086094093248, 48.25095156686577]],
    [ "Braunau Citycenter",[13.045724488683074, 48.24977455337328]],
    [ "Braunau Erlachweg",[13.045003067942774, 48.25237548800295]],
    [ "Braunau SP Markt",[13.045702160029228, 48.251901459969375]],
    [ "Braunau Simbachstrasse",[13.047833926503118, 48.25035529524409]],
    [ "Haselbach Heinrichgründe",[13.05593925915736, 48.25040560091951]],
    [ "Braunau Guggstr.",[13.061372300352547, 48.25135387110092]],
    [ "Energie AG",[13.060403303113649, 48.22938055111035]],
    [ "Energie AG",[13.02988708655428, 48.23347899353855]],
    [ "Ranshofen Sdlg.",[13.023020112878989, 48.23199073590138]],
    [ "Energie AG",[13.01874944237652, 48.23191522347688]],
    [ "Energie AG",[13.016409246098226, 48.23303950651504]],
    [ "Energie AG",[13.025977446453133, 48.236017495474634]],
    [ "TST St. Johann Hauptplatz",[16.0343556, 47.716124549999996]],
    [ "Netz Niederösterreich GmbH",[15.870154150000001, 47.69301715]],
    [ "Wiener Netze",[16.377714815084072, 48.02537554642451]],
    [ "Umspannstation Dietfurth",[13.06690976080606, 48.256760080335184]],
    [ "Energie AG",[13.10460260536244, 48.196066895588295]],
    [ "Energie AG",[13.102510853897297, 48.20115333399759]],
    [ "Energie AG",[13.09712403015728, 48.20242233444686]],
    [ "Geretsdorf",[13.111337646491696, 48.20244229975197]],
    [ "St. Peter a. Hart Schule",[13.09624885210964, 48.25320338097975]],
    [ "St. Peter a. Hart Sdlg.",[13.103375153588187, 48.25650722989293]],
    [ "Energie AG",[13.158408892582154, 48.27548348628177]],
    [ "Mundenham Landesstrasse",[13.126407159979353, 48.01047670132197]],
    [ "Netz NÖ",[16.104450220949545, 47.857340124658194]],
    [ "Netz NÖ",[16.107336754802454, 47.855160969979416]],
    [ "Netz NÖ",[16.111589100000003, 47.852867950000004]],
    [ "Energie AG",[13.259488188663163, 48.26886907956039]],
    [ "Energie Steiermark",[15.468241547009109, 47.08947469900828]],
    [ "Energie AG",[13.194197719592546, 48.24853160959235]],
    [ "Mauerkirchen Kinogasse",[13.133209812900096, 48.18851674702457]],
    [ "Wiener Netze",[16.309407149999995, 48.10667784999999]],
    [ "Wiener Netze",[16.310244599999997, 48.10860265]],
    [ "Energie AG",[13.231510540291008, 48.2510372081926]],
    [ "Energie AG",[13.229651209522835, 48.248605154190344]],
    [ "Altheim Wiehag",[13.23787111152578, 48.24901790451457]],
    [ "Energie AG",[13.234040267203763, 48.24853826481473]],
    [ "Energie AG",[13.238954467043097, 48.24361820268354]],
    [ "Energie AG",[13.257515124658104, 48.254127695585034]],
    [ "Energie AG",[13.238211789732436, 48.25313125312408]],
    [ "Energie AG",[13.295671332385018, 48.26395238193533]],
    [ "Energie AG",[13.2957288092428, 48.26304350663856]],
    [ "Energie AG",[13.298027388474752, 48.26351841489181]],
    [ "Industrietrafo 2",[13.342145300000004, 48.239190349999994]],
    [ "Maschinenbau 2",[13.342117945512982, 48.23921659826837]],
    [ "Gurten Park 21",[13.342090685433925, 48.23924289330496]],
    [ "Umspannwerk St. Peter",[13.08247765859151, 48.258965163031625]],
    [ "Wiener Netze",[16.16620849471412, 47.93351047951005]],
    [ "Braunau HAK Ladestation",[13.0437250290849, 48.253796148040664]],
    [ "Mauerkirchen Bahnhofstrasse",[13.12948899225185, 48.19676262676347]],
    [ "Energie AG",[13.196995900000001, 48.24906674999999]],
    [ "Moosham Lagerhaus",[13.256665818417334, 48.27206853016927]],
    [ "Franz-Schmidt-Weg",[15.412107784895822, 47.111111475085394]],
    [ "Netz NÖ",[16.26872995, 47.84450155]],
    [ "Ranshofen Am Hang",[13.025823296502235, 48.228348132618684]],
    [ "GKW",[13.008900484120483, 48.24408390648361]],
    [ "Energie AG",[13.04837275485449, 48.180335687894356]],
    [ "Energie AG",[13.089731547945409, 48.145217822403175]],
    [ "Uttendorf Goldberger",[13.116315501232044, 48.15262372218954]],
    [ "Energie AG",[13.112860548072751, 48.15285587734939]],
    [ "Energie AG",[13.13620341726071, 48.11828015197159]],
    [ "Energie AG",[13.137902384947775, 48.117471247291824]],
    [ "Energie AG",[13.156268948461728, 48.10719101461326]],
    [ "Energie AG",[13.144519194068774, 48.11631731557599]],
    [ "Mattighofen Römerfeld",[13.147770912097275, 48.11342168755576]],
    [ "Mattighofen Baierstr.",[13.150435813121947, 48.11724466476835]],
    [ "Schalchen Lengauerstrasse",[13.162369280325017, 48.11933270738762]],
    [ "Schalchen",[13.158428635726136, 48.1161963699672]],
    [ "Energie AG",[13.155295069286963, 48.11260815976503]],
    [ "Mattighofen Braunauerstr.",[13.151983454494845, 48.11348189451087]],
    [ "Mattighofen Scheiterb. Str.",[13.155278399348328, 48.10555214551641]],
    [ "Mattighofen Bez. Altenheim",[13.151266246155183, 48.09494304348362]],
    [ "Mattighofen Spitzleithenweg",[13.142419461732093, 48.10383784698096]],
    [ "Energie AG",[13.145170593199781, 48.10432929016929]],
    [ "Mattighofen Mühlweg",[13.149152433161145, 48.10194216282884]],
    [ "Energie AG",[13.157901298469083, 48.096659698361506]],
    [ "Mattighofen Stallhofnerstr.",[13.157167041098031, 48.09530853182458]],
    [ "Energie AG",[13.157595520433103, 48.09473009877298]],
    [ "Energie AG",[13.158356593252782, 48.09478928096432]],
    [ "Energie AG",[13.159474166090137, 48.09471744632659]],
    [ "Energie AG",[13.158976332386407, 48.09241399567825]],
    [ "Energie AG",[13.146267018078102, 48.103104900633014]],
    [ "Pischelsdorf Sdlg.",[13.082025313811783, 48.132050502945155]],
    [ "Maierhof",[13.00983514429937, 48.170816802325696]],
    [ "Handenberg Schule",[13.006216148700753, 48.133384653567354]],
    [ "Oberhaunsberg",[12.996128275796059, 48.10300605296194]],
    [ "Energie AG",[12.991856268110277, 48.08988812259511]],
    [ "Gilgenberg Panoramaweg",[12.93586230675, 48.14380802885845]],
    [ "Energie AG",[12.964120855194773, 48.177150034109005]],
    [ "Schwand Biogasanlage",[12.970357629754202, 48.19886359243211]],
    [ "Schwand Sportplatz",[12.971373965946837, 48.17934101073081]],
    [ "Sengstatt",[12.860647208923998, 48.1293746258263]],
    [ "Energie AG",[12.85747599891723, 48.17733449696988]],
    [ "Überackern",[12.877105941159703, 48.19170889798937]],
    [ "Energie AG",[12.838778141987637, 48.14886495027424]],
    [ "Ach",[12.832189620014272, 48.15469573356528]],
    [ "1323731622",[15.406833463785777, 47.09624233497341]],
    [ "Feldkirchen Molkerei",[13.05341311252574, 48.08521308028048]],
    [ "Feldkirchen Sportplatz",[13.047351433083612, 48.066967749868994]],
    [ "Gundertshausen Gewerbe",[12.994155883457102, 48.08817959921124]],
    [ "Eggelsberg Siedlung",[12.991676348382745, 48.08140675777534]],
    [ "Energie AG",[12.992423971985135, 48.07834107128423]],
    [ "Energie AG",[12.988841847490706, 48.07415422964278]],
    [ "Energie AG",[12.98975679325453, 48.0747171423381]],
    [ "Energie AG",[12.991510568884184, 48.072491153871105]],
    [ "Ibm",[12.960927577967576, 48.06468850733881]],
    [ "Energie AG",[12.980179207690913, 48.07064388533721]],
    [ "Netz Niederösterreich GmbH",[16.253582179499404, 47.83627645555318]],
    [ "Eggenham",[12.921547330000687, 48.04681560803416]],
    [ "Trimmelkam Bahnhof",[12.86084750422415, 48.02790929368498]],
    [ "Trimmelkam Gewerbe",[12.859614918378819, 48.027946298890775]],
    [ "Energie AG",[12.855794085525579, 48.02553562153327]],
    [ "Energie AG",[12.840065424769392, 48.03223779469285]],
    [ "Energie AG",[12.832875227612677, 48.04099849120641]],
    [ "Ostermiething Markt",[12.829881812647384, 48.04694855426897]],
    [ "Energie AG",[12.824260193078668, 48.07448798957987]],
    [ "Tarsdorf",[12.822112138405357, 48.07607688975786]],
    [ "Energie AG",[12.769537999771698, 48.09339679277823]],
    [ "Ebersdorf",[12.814664791716183, 48.08465479244669]],
    [ "Energie AG",[12.862228401965696, 48.06741631847755]],
    [ "Netz Niederösterreich GmbH",[16.256642778665498, 47.837620102926536]],
    [ "TST Rohrbach Industriestraße",[16.06322165, 47.72634284999999]],
    [ "TST St. Johann Forstnerweg",[16.031111130277484, 47.717901019213194]],
    [ "Energie AG",[12.80379039704316, 48.070798681926604]],
    [ "Marktl",[12.876083650000002, 48.0574902]],
    [ "Ostermiething Gewerbe",[12.839883067809188, 48.042503553329674]],
    [ "Energie AG",[12.870971278181, 48.00399765264127]],
    [ "St. Pantaleon Reith",[12.887892986750026, 48.00201336783107]],
    [ "St. Pantaleon Ind.",[12.88609359465293, 48.00150736805147]],
    [ "Energie AG",[12.974173045053272, 48.029996999736184]],
    [ "Moosdorf Elektroindustrie",[12.98450289704658, 48.03790350008229]],
    [ "Moosdorf Gewerbepark",[12.988354531533867, 48.03951050080451]],
    [ "Energie AG",[13.08939955945667, 48.007141452082244]],
    [ "Lengau Palfinger Übergabe",[13.221963649999998, 48.006853650000004]],
    [ "Energie AG",[13.180732347095642, 48.0705898174151]],
    [ "Schneegattern Mitte",[13.293908347848541, 48.02444885969778]],
    [ "Hocheck",[13.338117341584612, 48.038477495447495]],
    [ "Mattighofen Stallhofnerstr.",[13.157890908494775, 48.09621327082475]],
    [ "Mining Stockerbachsiedlung",[13.175936807391466, 48.27165101740358]],
    [ "Energie AG",[13.3321673047237, 48.32130384393687]],
    [ "Energie AG",[13.340905299511217, 48.31924234570721]],
    [ "Energie AG",[13.28737515586807, 48.26408995124714]],
    [ "Energie AG",[13.333469706889765, 48.26510296476387]],
    [ "Freiling Gewerbegebiet",[13.335312999787496, 48.26024080225352]],
    [ "Energie AG",[13.326415546380526, 48.20500790249423]],
    [ "Aspach Molkerei",[13.305578337404523, 48.18731556952549]],
    [ "Energie AG",[13.2293746485591, 48.25851494928264]],
    [ "Altheim Muckenau",[13.221739033260198, 48.250432884703706]],
    [ "Polling",[13.27788348752122, 48.233040695041915]],
    [ "Energie AG",[13.277259318751948, 48.2307605700291]],
    [ "Fraham Rossbach",[13.271774850619083, 48.19426473368521]],
    [ "Dötting Mühle",[13.277283000404848, 48.181040049471314]],
    [ "Energie AG",[13.214189726499123, 48.195030042057546]],
    [ "Energie AG",[13.217745060223642, 48.13928415629179]],
    [ "St. Johann a. Walde",[13.279384904900452, 48.1219635035403]],
    [ "Energie AG",[13.310392897190583, 48.115913966475894]],
    [ "Energie AG",[13.266400951576802, 48.16512102737406]],
    [ "Energie AG",[13.271391143658574, 48.169627310843715]],
    [ "Energie AG",[13.279223411346441, 48.167140625321]],
    [ "Wolfersberg",[13.451410666657038, 48.114586655587885]],
    [ "Energie AG",[13.423851306525274, 48.1462781732568]],
    [ "Energie Ried",[13.430567173416792, 48.208678287672036]],
    [ "Energie Ried",[13.423693652924179, 48.20890155585665]],
    [ "Moosham Gewächshaus",[13.27281761804445, 48.26740671836164]],
    [ "Netz Niederösterreich GmbH",[15.90402515, 47.683150000000005]],
    [ "Netz Niederösterreich GmbH",[16.269774849999997, 47.831339050000004]],
    [ "Netz Niederösterreich GmbH",[16.271937649999998, 47.82795675]],
    [ "Netz Niederösterreich GmbH",[16.29166081726098, 47.83799539896083]],
    [ "Wiener Netze",[16.210961999297396, 47.968878350743736]],
    [ "Wiener Netze",[16.21097492218041, 47.96903031768968]],
    [ "Mariatrosterstrasse 103",[15.46448820212605, 47.09522730911123]],
    [ "Elektrizitätswerke Eisenhuber GmbH & Co KG",[15.954406999999998, 47.62121985000002]],
    [ "Aselkam WK",[13.06911784709385, 48.254158351071055]],
    [ "Energie AG",[13.357779037717842, 48.3342724719646]],
    [ "Energie AG",[13.402911061786206, 48.34228660382844]],
    [ "Energie AG",[13.401762723205133, 48.34636802025869]],
    [ "Lustbuehel Schloss",[15.496295766042596, 47.069996699025324]],
    [ "Energie AG",[13.378737458180447, 48.27364137303839]],
    [ "Energie AG",[13.436279828339496, 48.32619617785335]],
    [ "Energie AG",[13.427915631630563, 48.320160426968975]],
    [ "Energie AG",[13.426478488198317, 48.31731752146676]],
    [ "Kammer Ind.",[13.421184962858488, 48.311201150160514]],
    [ "Kammer OTN Übergabe",[13.42111169095607, 48.311163245452406]],
    [ "Energie AG",[13.425708499999999, 48.305469900000006]],
    [ "Ort i. I. Mitte",[13.432648640470624, 48.315546428018216]],
    [ "Ort i. I. Zentrum",[13.432707994572871, 48.317716236282806]],
    [ "St. Martin Postweg",[13.439704546125885, 48.29061550165293]],
    [ "St. M. Hauptschule",[13.44066661507915, 48.29470927988512]],
    [ "Energie Ried",[13.444817291653587, 48.29186477562401]],
    [ "Energie Ried",[13.43881016794752, 48.297787001051056]],
    [ "Energie Ried",[13.438895765233786, 48.29781767108028]],
    [ "Energie Ried",[13.429416279431724, 48.30260080030206]],
    [ "Energie Ried",[13.431917446279027, 48.29751070886992]],
    [ "Energie Ried",[13.428955438577207, 48.29122714819815]],
    [ "Energie Ried",[13.433947702527918, 48.29398031704733]],
    [ "Energie Ried",[13.434722328181525, 48.288236882523385]],
    [ "Energie Ried",[13.43459280735184, 48.28615661042338]],
    [ "Energie Ried",[13.434989740311075, 48.28454600156701]],
    [ "Energie Ried",[13.428270332471019, 48.3027561438034]],
    [ "Energie Ried",[13.423380717782802, 48.302399926359925]],
    [ "Energie AG",[13.468137660313273, 48.30233649985518]],
    [ "Wiener Netze",[16.293497549999994, 48.01450560000001]],
    [ "St. Marienkirchen b. Sch. Gewerbe 1",[13.444445874769585, 48.38724184111821]],
    [ "St. Marienkirchen b. Sch. Gewerbe 2",[13.44465896221356, 48.388979748738876]],
    [ "Energie AG",[13.516013821172802, 48.389709682542005]],
    [ "Energie AG",[13.526479600721085, 48.400454740976286]],
    [ "Energie AG",[13.436678137986506, 48.39587415021235]],
    [ "Energie AG",[13.433854123013647, 48.40801802204853]],
    [ "Suben Justizanstalt",[13.429363270601534, 48.411666748588374]],
    [ "Energie AG",[13.429615449985805, 48.40605024884256]],
    [ "Andiesen Ind.",[13.426693050004031, 48.39511980015086]],
    [ "Energie AG",[13.438592163683987, 48.35504517627528]],
    [ "Energie AG",[13.472852621131153, 48.337083296010285]],
    [ "TST Kirchschlag Pflegeheim",[16.286631915715898, 47.49478315344499]],
    [ "Netz Niederösterreich GmbH",[16.16789735, 47.51677095000001]],
    [ "Netz Niederösterreich GmbH",[16.264015099999998, 47.4986478]],
    [ "Energie AG",[13.512839109984474, 48.337248996667036]],
    [ "Energie AG",[13.501022876705516, 48.34123255381303]],
    [ "Kronberg Hof",[13.51579429494262, 48.36253063848455]],
    [ "Energie AG",[13.558589687874319, 48.328652755848005]],
    [ "Energie AG",[13.569072544965394, 48.34669095096444]],
    [ "TST Maiersdorf",[16.048521386477773, 47.816954137809894]],
    [ "Netz Niederösterreich GmbH",[16.0343364881932, 47.803295950033664]],
    [ "Energie AG",[13.275637080856763, 47.96747405591255]],
    [ "Energie AG",[13.3057644, 47.95963381250102]],
    [ "Wieneröth",[13.400479331841655, 47.973107550790616]],
    [ "Energie AG",[13.274321840230055, 47.98497746736377]],
    [ "Energie AG",[13.287373149750469, 47.98844990074951]],
    [ "Energie AG",[13.325868920873686, 47.992227804191494]],
    [ "Pöndorf Kirche",[13.368959305599619, 47.99639095680906]],
    [ "Energie AG",[13.395001850141321, 47.989495588472494]],
    [ "Energie AG",[13.396970261298637, 47.98922065661638]],
    [ "Energie AG",[13.400645194934599, 47.98632191605536]],
    [ "Energie AG",[13.391301582990824, 47.985726495932894]],
    [ "Energie AG",[13.386771500481288, 47.98176800302928]],
    [ "Energie AG",[13.41436801351984, 47.99463325062529]],
    [ "Energie AG",[13.53933099239363, 48.37209465994084]],
    [ "Andorf Harterstr.",[13.55899738741217, 48.3722328739755]],
    [ "Schärdingerau",[13.558471666280477, 48.38032494722647]],
    [ "Energie AG",[13.5741174120192, 48.373023649608974]],
    [ "Energie AG",[13.57252419674494, 48.37373703788586]],
    [ "Andorf Hanak",[13.566486426467115, 48.372531805073955]],
    [ "Andorf Bahnhof",[13.566757456946311, 48.368812489540055]],
    [ "Energie AG",[13.564473661034286, 48.36916220267095]],
    [ "Andorf Ertl",[13.563084854819415, 48.366420775725544]],
    [ "Energie AG",[13.573913602826412, 48.36999315348109]],
    [ "Energie AG",[13.573464602769393, 48.36839876694778]],
    [ "Energie AG",[13.57605466995125, 48.37455320751287]],
    [ "Andorf Ort",[13.581216768999994, 48.37019809490257]],
    [ "Energie AG",[13.57042394104245, 48.38570300618897]],
    [ "Lauterbrunn",[13.598230327160135, 48.37758370269208]],
    [ "Niederham bei Raab",[13.621569012735147, 48.3565523756564]],
    [ "Raab Biogas",[13.635327013575703, 48.354021830673574]],
    [ "Energie AG",[13.638198700107303, 48.353293112597555]],
    [ "Energie AG",[13.643589717889673, 48.35211845396344]],
    [ "Hausmaning",[13.64774759988722, 48.32904255096871]],
    [ "Energie AG",[13.65366915362738, 48.35122110984408]],
    [ "Raab Lachmayr",[13.648641730379959, 48.3526849060222]],
    [ "Energie AG",[13.627279135491749, 48.319250071105884]],
    [ "Frankenmarkt Sparkasse",[13.41948344890836, 47.98614123891765]],
    [ "Frankenmarkt B-Schartenstr.",[13.413579057888187, 47.98716030487001]],
    [ "Energie AG",[13.414999597397962, 47.9846016604849]],
    [ "Energie AG",[13.409911152913232, 47.9853289621835]],
    [ "Frankenmarkt Moosmühle",[13.417101062302057, 47.98204508323748]],
    [ "Moosmühle Schaltstation",[13.41707492204873, 47.98193006059051]],
    [ "Energie AG",[13.41180441704979, 47.98216275905466]],
    [ "Energie AG",[13.418582642669012, 47.98225739270325]],
    [ "Energie AG",[13.420358097008625, 47.98231583712815]],
    [ "Frankenmarkt Brauhausgasse",[13.424451091785594, 47.98522760268307]],
    [ "Energie AG",[13.429350238763037, 47.983446857465516]],
    [ "Energie AG",[13.433853464955444, 47.984742153085776]],
    [ "Energie AG",[13.447060781065812, 47.98706789502228]],
    [ "Untermühlberg",[13.448032484974439, 47.9884892497778]],
    [ "Pointen",[13.423550643714753, 47.990743577915396]],
    [ "Energie AG",[13.449441815038082, 47.99321846307135]],
    [ "Energie AG",[13.650888450000002, 48.385390550000004]],
    [ "Enzenkirchen Ort",[13.645601761153904, 48.38851208015733]],
    [ "Enzenkirchen Hacking",[13.639045706265364, 48.39073675247834]],
    [ "Energie AG",[13.685538737482878, 48.47096110671183]],
    [ "Energie AG",[13.737916189747454, 48.48005200741535]],
    [ "Energie AG",[13.755889653397533, 48.48193398346764]],
    [ "Spital Hallenbad",[14.346903365429366, 47.668411873491785]],
    [ "Spital Eig",[14.34651421149364, 47.665188620825546]],
    [ "Ader",[13.587776037422818, 47.9960198980454]],
    [ "Wurzeralm Pumpenhaus",[14.31529131632434, 47.62781271251227]],
    [ "Wurzeralm Talstation",[14.315033234737513, 47.62741489349768]],
    [ "Wurzeralm Parkplatz",[14.3152118, 47.627110449999996]],
    [ "Schildorn Rampfen",[13.473453169918885, 48.15007072751433]],
    [ "Taufkirchen Kläranlage",[13.52020490206272, 48.411832901850154]],
    [ "Resthofstrasse 92",[14.426606528088179, 48.06250493207905]],
    [ "Wurzeralm Hahnlgraben",[14.280593566433295, 47.646592345128035]],
    [ "Wurzeralm Pumpwerk 4",[14.280923726437415, 47.64677029537568]],
    [ "Wurzeralm Pumpwerk 3",[14.287341999999999, 47.64863805]],
    [ "Wurzeralm Frauenkar",[14.275267906327286, 47.64683478758245]],
    [ "Wurzeralm Beschneiung",[14.300216599999997, 47.6369208]],
    [ "Energie AG",[13.317591401818852, 47.850727463881626]],
    [ "Wiener Netze",[16.308054, 48.10838855000001]],
    [ "Wendling Hub",[13.6517157, 48.23447114999999]],
    [ "Sattledt Voralpenkreuz Ost",[14.038635869944555, 48.06504430760794]],
    [ "Marchtrenk Freilingerstr.",[14.0934563, 48.19672085]],
    [ "Marchtrenk am Bahndamm",[14.095867583781827, 48.198707500874896]],
    [ "Energie AG",[13.489776349620287, 48.55444940141997]],
    [ "Energie AG",[13.48700001196195, 48.551964001675984]],
    [ "Energie AG",[13.459450108167047, 48.5069758776039]],
    [ "Energie AG",[13.461555437677426, 48.4874386374157]],
    [ "Riedau Ziegelei",[13.623880586409545, 48.30748902780191]],
    [ "Wendling Kläranlage",[13.672952584130895, 48.23301298247747]],
    [ "Ebensee Reitsportzentrum",[13.76195521855621, 47.796743113318776]],
    [ "Energie AG",[13.54585254325607, 48.48384824586945]],
    [ "Energie AG",[13.565718399655548, 48.490601512337555]],
    [ "Energie AG",[13.55577204598819, 48.48074826781289]],
    [ "Energie AG",[13.599525025561432, 48.396241042809905]],
    [ "Energie AG",[13.596165041250638, 48.391664648937]],
    [ "Energie AG",[13.534158784043125, 48.40883682732635]],
    [ "Energie AG",[13.530797112463432, 48.4058921836261]],
    [ "Energie AG",[13.52583935, 48.4080351]],
    [ "Energie AG",[13.521328391333972, 48.40929365254392]],
    [ "Energie AG",[13.521767409423143, 48.40794297982111]],
    [ "Energie AG",[13.510628066918253, 48.41321519551562]],
    [ "Energie AG",[13.459325940400566, 48.432735455634194]],
    [ "Energie AG",[13.459360698194589, 48.43271606321631]],
    [ "Energie AG",[13.449525094203683, 48.42821813838625]],
    [ "Energie AG",[13.447705299454109, 48.4267766897077]],
    [ "Haid b. Schärding",[13.451963078212655, 48.426355276849726]],
    [ "Rossbach",[13.448658975779248, 48.4187653587637]],
    [ "Schnelldorf",[13.438586162224148, 48.42020770122349]],
    [ "Suben Sdlg.",[13.43630177203281, 48.414587482540995]],
    [ "Energie AG",[13.443714300827324, 48.43091615359811]],
    [ "Bubing",[13.456811996435318, 48.45465281707839]],
    [ "Gopperding Steinbach",[13.461413060497321, 48.445482821424264]],
    [ "Energie AG",[13.445277320735123, 48.468868564799884]],
    [ "TST Sollenau Heidegasse",[16.285891084384115, 47.91033382416909]],
    [ "1334843422",[15.01726175, 48.48958365]],
    [ "1334843425",[15.042527125100062, 48.473231345038336]],
    [ "Energie AG",[13.265562348821295, 48.26948889756769]],
    [ "Kremsmünster Sportanlagen",[14.128930803806835, 48.05010471775172]],
    [ "Netz Niederösterreich GmbH",[14.885360119469768, 48.70322940259942]],
    [ "Netz Niederösterreich GmbH",[14.798279929938447, 48.70069212348627]],
    [ "Netz Niederösterreich GmbH",[14.79066045, 48.70402800000001]],
    [ "Netz Niederösterreich GmbH",[14.942074458829158, 48.6558113744558]],
    [ "Netz Niederösterreich GmbH",[14.960450299999998, 48.73775224999999]],
    [ "Netz Niederösterreich GmbH",[14.861665585387016, 48.704383778483894]],
    [ "Schneegattern Lawog",[13.297266749999999, 48.0241271]],
    [ "Schneegattern Weissenbach",[13.290431647976382, 48.03451409985296]],
    [ "Schneegattern Holzind.",[13.290858979872713, 48.020026671735735]],
    [ "Schneegattern Moosstraße",[13.286753666490052, 48.02229157423988]],
    [ "Friedburg Mühlbacherstrasse",[13.255466348240779, 48.016124433138]],
    [ "Friedburg Mozartweg",[13.246686177802216, 48.01497240606051]],
    [ "Lengau Wimpassing",[13.245296862628784, 47.994638121219204]],
    [ "Haidach RAG 1",[13.24471700162747, 47.99773133280573]],
    [ "Schwöll Römerstraße",[13.232742624096478, 47.998911792990675]],
    [ "Lengau Kabinenbau 2",[13.228228301487373, 48.004338537148556]],
    [ "Lengau Kabinenbau",[13.229771925857174, 48.0048135254714]],
    [ "Lengau Palfinger 1",[13.22120432970301, 48.008611638421364]],
    [ "Lengau Palfinger 2",[13.223194036256723, 48.00801279281396]],
    [ "Lengau Palfinger 3",[13.224249961214424, 48.00965942846773]],
    [ "Pießling Bernegger KW",[14.273243221642293, 47.73033864831304]],
    [ "Energie Ried",[13.395944187496974, 48.230855500000004]],
    [ "Energie Ried",[13.420221953802203, 48.27018559922394]],
    [ "Energie Ried",[13.450507002137691, 48.268105303639146]],
    [ "Energie Ried",[13.45221955050006, 48.26165889987173]],
    [ "Energie Ried",[13.457555552698576, 48.25781849988969]],
    [ "Gunskirchen Dieselstrasse",[13.95831266753468, 48.13495708978242]],
    [ "Energie Ried",[13.427425623504769, 48.28720600564856]],
    [ "Energie Ried",[13.454400703791695, 48.28046740479867]],
    [ "Utzenaich Ort",[13.461917900778321, 48.27582855023198]],
    [ "Energie Ried",[13.48825307995338, 48.28900037334888]],
    [ "Energie Ried",[13.512785450513455, 48.31691817427942]],
    [ "Energie Ried",[13.512960382891647, 48.31770847263037]],
    [ "Energie Ried",[13.51760054172456, 48.318415937247806]],
    [ "Energie Ried",[13.522715717570762, 48.32074989359709]],
    [ "Energie Ried",[13.532337768569894, 48.306623449889024]],
    [ "Energie Ried",[13.510833386725423, 48.26064231944322]],
    [ "Energie Ried",[13.499847765272323, 48.22976597476938]],
    [ "Energie Ried",[13.492616200000004, 48.23156320000001]],
    [ "Energie Ried",[13.48953248407144, 48.22847832829069]],
    [ "Ansfelden Gewerbepark",[14.273276676627608, 48.21130523019442]],
    [ "Ebensee Gartenstr. Wohnanlage",[13.77171735, 47.8027286]],
    [ "Ebensee Almhausstr.",[13.774495223474885, 47.80642998343881]],
    [ "Ebensee Solvay UST",[13.769456279207253, 47.80550458556459]],
    [ "Ebensee Gewerbepark 1",[13.769773360534419, 47.80544993195411]],
    [ "Ebensee UST",[13.769045675919225, 47.80832470919484]],
    [ "Ebensee Einkaufszentrum",[13.77056838825625, 47.808479267047545]],
    [ "Ebensee Bahnhofstr.",[13.772103549999999, 47.80919560000001]],
    [ "Ebensee Sozialzentrum",[13.77350031594169, 47.810389677991765]],
    [ "Ebensee Berggasse",[13.770659829330146, 47.81240252064223]],
    [ "Ebensee Amtsgebäude",[13.77406647295448, 47.8120802138073]],
    [ "Ebensee Trauneck",[13.775893849999997, 47.812031499999996]],
    [ "TST Natschbach Am Klosteracker",[16.08004010997332, 47.7169280735073]],
    [ "Energie AG",[13.631609130647059, 48.004753761510614]],
    [ "Energie AG",[13.319182882327462, 47.88621531765529]],
    [ "Energie Ried",[13.425460099895217, 48.20778435595532]],
    [ "Ponneredt",[13.467124565247348, 48.19064453354721]],
    [ "Energie Ried",[13.485276046333, 48.186527579417195]],
    [ "Energie Ried",[13.493839698835531, 48.210041820971504]],
    [ "Energie Ried",[13.503528945900948, 48.215171761876746]],
    [ "Energie Ried",[13.499061300000001, 48.21667875]],
    [ "Energie Ried",[13.498980843253252, 48.21985375325186]],
    [ "Energie Ried",[13.498203728349457, 48.22222154144434]],
    [ "Energie Ried",[13.494475492077788, 48.21816575078054]],
    [ "Energie Ried",[13.478640040390744, 48.225187099818775]],
    [ "Energie Ried",[13.474454507475249, 48.227419152901405]],
    [ "Energie Ried",[13.46978663204026, 48.22888200211272]],
    [ "Energie Ried",[13.468064142138052, 48.22997909945961]],
    [ "Energie Ried",[13.464953623064407, 48.23408545625807]],
    [ "Energie Ried",[13.466966053097545, 48.234465338455074]],
    [ "Energie Ried",[13.458859750411232, 48.2439833109774]],
    [ "Energie Ried",[13.45546245, 48.24705715000001]],
    [ "Energie Ried",[13.456212900926364, 48.248346891119574]],
    [ "Energie Ried",[13.457320249999999, 48.248846650000004]],
    [ "Energie Ried",[13.45479845989492, 48.24933716184117]],
    [ "Energie Ried",[13.453159201241585, 48.25336835574939]],
    [ "Energie Ried",[13.450521510059879, 48.248934763349254]],
    [ "Energie Ried",[13.430560795213523, 48.240860302752864]],
    [ "Energie Ried",[13.433050264240167, 48.230663549436336]],
    [ "Energie Ried",[13.43053712642743, 48.23613739732844]],
    [ "Energie Ried",[13.450804111886018, 48.231831888104566]],
    [ "Energie Ried",[13.458712705207308, 48.25151845836342]],
    [ "Energie Ried",[13.460937714521867, 48.25127460528539]],
    [ "Energie Ried",[13.46185510230925, 48.24715574385424]],
    [ "Schlag b. Thomasroith",[13.624194450000001, 48.08054215000001]],
    [ "Pucking Hobelweg",[14.213314848869961, 48.19499881742055]],
    [ "Schärding Wohnungsfr.",[13.441259561781553, 48.44900119233988]],
    [ "St. Florian a. I. Linzerstr.",[13.440725724754799, 48.443717213584826]],
    [ "Schärding Linzerstr.",[13.43603840750991, 48.4520432480378]],
    [ "Schärding Krankenhaus",[13.435326546869351, 48.45557042448482]],
    [ "Energie AG",[13.439339752153032, 48.46877772820189]],
    [ "Allerheiligen",[13.438818, 48.46552330000001]],
    [ "Energie AG",[13.43351346105501, 48.46383110900497]],
    [ "Schärding Brunnerstr.",[13.433692494916249, 48.45894602409423]],
    [ "Energie AG",[13.438287104153549, 48.45485268519048]],
    [ "Schärding Berufsschule",[13.446037133234434, 48.44977029016388]],
    [ "Schärding Steingasse",[13.430495214551085, 48.455322106835034]],
    [ "Schärding Passauertor",[13.431354903333276, 48.459048138757815]],
    [ "Energie AG",[13.429930538112018, 48.4539762017894]],
    [ "Oberhofen Ort",[13.305204625749296, 47.95271375544129]],
    [ "Energie AG",[13.319944300142545, 47.903701498415906]],
    [ "Weißer Stein",[13.323968394586679, 47.87194550436499]],
    [ "Energie AG",[13.32418885282824, 47.87172799798454]],
    [ "Energie AG",[13.329438803202976, 47.865749699747745]],
    [ "Energie AG",[13.332629596918453, 47.86485728502847]],
    [ "Energie AG",[13.331949813276143, 47.86369307478659]],
    [ "Energie AG",[13.331997089105291, 47.862816661470596]],
    [ "Energie AG",[13.332571154292209, 47.86036673148585]],
    [ "Energie AG",[13.341841484794013, 47.85704496711418]],
    [ "Energie AG",[13.343469361886594, 47.85908741297364]],
    [ "Mondsee Brandlberg",[13.350922118532257, 47.858970636425035]],
    [ "Mondsee Markt",[13.350852572040376, 47.855310752888855]],
    [ "Energie AG",[13.349489789081762, 47.854711232026524]],
    [ "Energie AG",[13.347483600330316, 47.855265698414165]],
    [ "Mondsee Hochkreuz",[13.341043129785291, 47.86190168774993]],
    [ "Energie AG",[13.350708148477326, 47.85691228881504]],
    [ "Energie AG",[13.350860793602207, 47.85742701063161]],
    [ "Energie AG",[13.349617035600245, 47.85733607978574]],
    [ "Energie AG",[13.360428840713857, 47.853233931557085]],
    [ "Energie AG",[13.349666700089761, 47.92940715027455]],
    [ "Haslau Leistenwerk",[13.356193563805212, 47.9322552579429]],
    [ "Haslau Vöcklatalstr.",[13.356310771235547, 47.932328718551936]],
    [ "Energie AG",[13.356310324285447, 47.934150140736385]],
    [ "Mondsee Schlößl",[13.337737209729426, 47.86647355348776]],
    [ "Energie AG",[13.355803374556464, 47.86492413146307]],
    [ "Energie AG",[13.37295207103167, 47.84691170092351]],
    [ "EVN",[16.792949878045047, 48.204115403779106]],
    [ "TST Pottschach Semperitsiedlung",[16.011007170607204, 47.69459755987324]],
    [ "Energie AG",[13.32633346240831, 47.8427021695682]],
    [ "Energie AG",[13.335295588398635, 47.842854341208174]],
    [ "TST Pottschach Platanenstraße",[16.006617049999996, 47.6955594]],
    [ "St. Lorenz Fink-Siedlung",[13.346141500490338, 47.83810138017599]],
    [ "Aich SST",[13.38268532117879, 47.77588704760371]],
    [ "Energie AG",[13.435208505416831, 47.80609354654989]],
    [ "Energie AG",[13.39665345, 47.83668675]],
    [ "Au b. Unterach",[13.464317300000001, 47.80512645]],
    [ "Energie AG",[13.47139985143221, 47.803055527105485]],
    [ "Energie AG",[13.47741496688953, 47.80088433690109]],
    [ "Energie AG",[13.478568385458013, 47.80125906117943]],
    [ "Unterach am Kogel",[13.479161544495021, 47.804205967442556]],
    [ "Unterach Kastanienwald",[13.493139156758005, 47.809466001860336]],
    [ "Energie AG",[13.51424949140821, 47.83286937171584]],
    [ "Schweiber b. Oberaschau",[13.484687066364117, 47.84523665951096]],
    [ "Oberaschau",[13.470712082516442, 47.84193053322049]],
    [ "Energie AG",[13.443680947338343, 47.85075513178052]],
    [ "Oberwang Gewerbe",[13.443169648775275, 47.851622662784095]],
    [ "Energie AG",[13.438088874809475, 47.85449541181324]],
    [ "Energie AG",[13.438882541342556, 47.85087437423892]],
    [ "Energie AG",[13.433532625032758, 47.86839209441099]],
    [ "Energie AG",[13.418556425376126, 47.87237380799049]],
    [ "Energie AG",[13.430023695162161, 47.87742064908618]],
    [ "Energie AG",[13.432489241149845, 47.89252957561551]],
    [ "Pasching Gewerbepark",[14.229581049999998, 48.2344959]],
    [ "Freising Betriebspark",[14.416304700000001, 48.0126902]],
    [ "Altmünster Industrie 2",[13.761626909757258, 47.89117033154118]],
    [ "Netz Niederösterreich GmbH",[16.257099800000002, 47.859004199999994]],
    [ "TST Gloggnitz Hauptplatz",[15.9364365, 47.67593865]],
    [ "TST Wörth",[15.964353229534842, 47.67232035464437]],
    [ "Netz Niederösterreich GmbH",[15.933485327859401, 47.6667424035107]],
    [ "Br. Bauhofstr. Ladestation",[13.047568961198825, 48.243453889981176]],
    [ "Laakirchen Gewerbegebiet Süd",[13.824675271697256, 47.97115824609404]],
    [ "Flughafen Linz Objekt 4",[14.20638542412913, 48.23667080360805]],
    [ "St. Georgen Lederergasse",[13.483559310611318, 47.939261554629574]],
    [ "St. Georgen Weinbergsdlg.",[13.475063348410835, 47.933061248878026]],
    [ "St. Georgen Doblergasse",[13.479390425486757, 47.931718047282]],
    [ "St. Georgen Stelzhamerstr.",[13.484012595311432, 47.93453304594685]],
    [ "Energie AG",[13.513103593061297, 47.92325610215683]],
    [ "Reinthalmühle",[13.459058902149454, 47.91501962006349]],
    [ "Energie AG",[13.459369164648232, 47.9173952805902]],
    [ "Straß i. A. Holzindustrie",[13.460378883910215, 47.91791151663283]],
    [ "Abtsdorf Erlach",[13.514039851482561, 47.90577419775563]],
    [ "Altenberg Ort",[13.523602935452118, 47.896170386785094]],
    [ "Energie AG",[13.52512112116862, 47.88207359816871]],
    [ "Energie AG",[13.52910014611616, 47.88636681282838]],
    [ "Energie AG",[13.524460081502003, 47.89196520361982]],
    [ "Energie AG",[13.526206206285254, 47.907736048729475]],
    [ "Energie AG",[13.533397055030717, 47.91738214764903]],
    [ "Energie AG",[13.543054356104822, 47.920028916681275]],
    [ "Energie AG",[13.541641699999996, 47.930524187503195]],
    [ "Energie AG",[13.526707716707328, 47.940137706083505]],
    [ "Energie AG",[13.551979887549729, 47.93823250988271]],
    [ "Energie AG",[13.556926924526806, 47.93606819361951]],
    [ "Energie AG",[13.571963879681737, 47.945732004642885]],
    [ "Kemating Steindorf Sch. St.",[13.551910031234343, 47.961281878469954]],
    [ "Witzling",[13.546238549999998, 47.99586904999999]],
    [ "Energie AG",[13.506180720195374, 47.99559221334236]],
    [ "Energie AG",[13.475271868268772, 47.99857692923492]],
    [ "Energie AG",[13.478953392675864, 47.998828151000374]],
    [ "Vöcklamarkt Bahnhofstr.",[13.484372536033982, 47.99944587710231]],
    [ "Energie AG",[13.48996120404805, 47.9993908345386]],
    [ "Energie AG",[13.489582902213286, 47.99668648263029]],
    [ "Energie AG",[13.482113234740348, 47.98964368950191]],
    [ "Energie AG",[13.47851260844727, 47.98868715358066]],
    [ "Vöcklamarkt Raika",[13.485589729951732, 48.001764940271734]],
    [ "Vöcklamarkt Lehrstr.",[13.488898630846142, 48.003134220062826]],
    [ "Energie AG",[13.493568575432178, 48.00375249583604]],
    [ "Energie AG",[13.503509884532264, 48.038757945089586]],
    [ "Energie AG",[13.432170083619356, 48.01775062020398]],
    [ "Energie AG",[13.42343321981647, 48.015472412008705]],
    [ "St. Georgen Pausingergasse",[13.495332167394663, 47.93599714815166]],
    [ "Energie AG",[13.500773202849402, 48.065445864454354]],
    [ "Energie AG",[13.49974385331162, 48.06677861518828]],
    [ "Energie AG",[13.492760544041014, 48.06757539559994]],
    [ "Frankenburg Kellerweg",[13.494225350000002, 48.068574700000006]],
    [ "Energie AG",[13.498408771995532, 48.06819414358945]],
    [ "Frankenburg Erlat",[13.488337160519482, 48.07105421392024]],
    [ "Mining Raiffeisenstrasse",[13.168437062310463, 48.273518762121995]],
    [ "Hoblschlag",[13.517419246493251, 48.10094019624756]],
    [ "Energie AG",[13.565550899999998, 48.0793879]],
    [ "Energie AG",[13.568471356147846, 48.08498130409539]],
    [ "Ampflwang Neue Siedlung",[13.561611289115058, 48.082775302798986]],
    [ "Energie AG",[13.563075207895947, 48.08984478791327]],
    [ "Energie AG",[13.563730113144244, 48.09719489376411]],
    [ "Ampflwang Schachen",[13.57337837158602, 48.089931145359316]],
    [ "Energie AG",[13.541541671823197, 48.09852697774656]],
    [ "Energie AG",[13.57010130003604, 48.1149911495617]],
    [ "Eberschwang Ziegelwerk",[13.542160945064092, 48.136996397265726]],
    [ "Mühring Schwimmbad",[13.558310824271938, 48.15132189410032]],
    [ "Eberschwand Sdlg.",[13.568583396725574, 48.15581150476683]],
    [ "Energie AG",[13.56154236037617, 48.158420362631915]],
    [ "Energie AG",[13.552766347757363, 48.16892581326738]],
    [ "Energie AG",[13.546035878694136, 48.19355159402758]],
    [ "Energie AG",[13.541724273380334, 48.19043668231735]],
    [ "Energie AG",[13.540725266319248, 48.199865060680054]],
    [ "Energie AG",[13.607385917516767, 48.17856414390097]],
    [ "Energie AG",[13.568137524961859, 48.16099976668697]],
    [ "Anhang",[13.587878138932377, 48.154799468019846]],
    [ "Energie AG",[13.605767369658048, 48.14881589535558]],
    [ "Reitting Ayurveda",[13.628744399813105, 48.1640208125013]],
    [ "Energie AG",[13.63692255, 48.1751929]],
    [ "Haag a. Hausruck Hochfeld",[13.643234769056416, 48.17990199289934]],
    [ "Haag a. Hausruck Gemeinde",[13.63977814895026, 48.18403038753642]],
    [ "Energie AG",[13.629747447453278, 48.18590084976265]],
    [ "Energie AG",[13.640532340277105, 48.18612489183377]],
    [ "Haag a. Hausruck Bahnhof",[13.647822528512615, 48.185638052469145]],
    [ "Energie AG",[13.65619738712813, 48.19119087507854]],
    [ "Energie AG",[13.65624868712813, 48.19119057507855]],
    [ "Energie AG",[13.637967703691395, 48.20100363243895]],
    [ "Energie AG",[13.650726649866044, 48.192940156040045]],
    [ "Energie AG",[13.64718071485827, 48.200740088717914]],
    [ "Energie AG",[13.64324911027209, 48.19963847065779]],
    [ "Energie AG",[13.550954725446104, 48.00026277471433]],
    [ "Energie AG",[13.548983123363378, 48.00068821291242]],
    [ "Timelkam Industriestraße",[13.596047848369066, 47.9984667368795]],
    [ "Energie AG",[13.596811102839517, 47.99413589685111]],
    [ "Energie AG",[13.581972573192989, 48.00120620576912]],
    [ "Energie AG",[13.591360698882097, 48.008142943284454]],
    [ "Timelkam Nestroystr.",[13.607214499970638, 48.003844600406374]],
    [ "Timelkam Roseggerstrasse",[13.609149211886363, 47.99688150854214]],
    [ "Energie AG",[13.612720049421883, 48.00233862859142]],
    [ "Timelkam Sternhaus",[13.611536749611371, 48.003390448544856]],
    [ "Strass b. Timelkam",[13.62400167168552, 48.00028445252081]],
    [ "Energie AG",[13.621651551096402, 47.99676194313737]],
    [ "Energie AG",[13.637692987080952, 48.00299826924482]],
    [ "Energie AG",[13.640119081954705, 48.002523972967964]],
    [ "Energie AG",[13.642295735735964, 48.0037852794739]],
    [ "Vöcklabruck Bockstrasse",[13.64184210519117, 48.002719948228695]],
    [ "Energie AG",[13.643118040974317, 47.9988786396477]],
    [ "Vöcklabruck Laudonstr.",[13.645544531924545, 47.99807284324606]],
    [ "Energie AG",[13.64402952225647, 47.99709101148824]],
    [ "Energie AG",[13.63696426689956, 47.995885658711885]],
    [ "Vöcklabruck Spiegelfeldstr.",[13.639756499506985, 47.99549465370056]],
    [ "Vöcklabruck Edisonstr.",[13.643367014934544, 47.99506075488547]],
    [ "Energie AG",[13.656334606375216, 47.99902890403891]],
    [ "Energie AG",[13.659425447505729, 48.00066539620339]],
    [ "Energie AG",[13.660003676651014, 48.00089445056718]],
    [ "Vöcklabruck Friedhofstr.",[13.658743172899966, 48.00238898563446]],
    [ "Vöcklabruck Wagrainerstr.",[13.662694171855396, 48.00207023529526]],
    [ "Energie AG",[13.663100621411056, 48.00229640249682]],
    [ "Energie AG",[13.666826052735363, 48.00254375027539]],
    [ "Energie AG",[13.666814890638152, 48.00244790379628]],
    [ "Energie AG",[13.666974024301357, 48.00367041932096]],
    [ "Energie AG",[13.659644848630967, 48.00437564868481]],
    [ "Energie AG",[13.663274812477727, 48.007589254391036]],
    [ "Energie AG",[13.664141813073016, 48.00519930413273]],
    [ "Vöcklabruck Industriestr.",[13.664543931036484, 48.00540360492243]],
    [ "Energie AG",[13.665056113967198, 48.00639462042714]],
    [ "Vöcklabruck Engelweg",[13.671462936465344, 48.0055638857763]],
    [ "Energie AG",[13.672456443466288, 48.006193486432]],
    [ "Energie AG",[13.66889452057521, 48.00230395413077]],
    [ "Vöcklabruck Hausruckstrasse",[13.674322846429572, 48.006366468098605]],
    [ "Energie AG",[13.676664478046161, 48.005796993473034]],
    [ "Energie AG",[13.681901613246673, 48.005320574806554]],
    [ "Energie AG",[13.672518375381316, 48.004191346007566]],
    [ "Vöcklabruck Telefunkenstrasse",[13.676394334173327, 48.002446993358035]],
    [ "Oberhaus Buchenwald",[13.678209447954938, 48.00373859790442]],
    [ "Regau Hatschekstr.",[13.670234353019042, 47.99860806671169]],
    [ "Regau Pucheggerstr.",[13.666141450000001, 47.998992099999995]],
    [ "Energie AG",[13.6622818, 47.994283]],
    [ "Energie AG",[13.6477514031973, 48.00165730629471]],
    [ "Vöcklabruck Eishalle",[13.64822294208471, 48.00314344840947]],
    [ "Energie AG",[13.651633049258432, 48.00555947474529]],
    [ "Energie AG",[13.650615854607025, 48.006097150964635]],
    [ "Vöcklabruck Krankenhaus",[13.648172014949653, 48.006423291450716]],
    [ "Energie AG",[13.64523797677708, 48.00458145932354]],
    [ "Energie AG",[13.644416778135852, 48.005850902353]],
    [ "Vöcklabruck Postberg West",[13.640157748055556, 48.004613356051195]],
    [ "Vöcklabruck Hochhaus",[13.650981824415963, 48.00883017093095]],
    [ "Energie AG",[13.653705850791217, 48.00701376640273]],
    [ "Vöcklabruck Graben",[13.656704344979335, 48.00742020534122]],
    [ "Energie AG",[13.652129130273503, 48.00960549399396]],
    [ "Energie AG",[13.653619270384597, 48.00921666640431]],
    [ "Energie AG",[13.655127515218915, 48.00968960674616]],
    [ "Energie AG",[13.65598359636591, 48.00818927557747]],
    [ "Vöcklabruck Gmundnerstrasse",[13.658322913528457, 48.00771645648685]],
    [ "Energie AG",[13.6568068105257, 48.00988131184004]],
    [ "Energie AG",[13.658818451665212, 48.01042409031283]],
    [ "Energie AG",[13.666935840998232, 48.00782000872529]],
    [ "Energie AG",[13.668748744834602, 48.006019601992804]],
    [ "Vöcklabruck Marienkeller",[13.66972775, 48.0086064]],
    [ "Vöcklabruck Altenheim",[13.659097798951887, 48.01260090016787]],
    [ "Energie AG",[13.652587334004867, 48.01336098528591]],
    [ "Vöcklabruck Wegscheid",[13.628166894602828, 48.03159264999606]],
    [ "Energie AG",[13.626997350000002, 48.04465575]],
    [ "Altmannsberg",[13.642342846171468, 48.01452477283919]],
    [ "Altmannsberg Sdlg.",[13.632856483349181, 48.017052690889166]],
    [ "Vöcklabruck Dörfl",[13.646747544949786, 48.01234078361418]],
    [ "Vöcklabruck Heschgasse",[13.649246266303356, 48.01021344768518]],
    [ "Energie AG",[13.651573286504096, 48.01123272988283]],
    [ "Energie AG",[13.667996606743522, 48.012256254169465]],
    [ "Vöcklabruck Sonderschule",[13.64868367806926, 47.99960209450765]],
    [ "Vöcklabruck Pestalozzistr.",[13.651760119708902, 48.00006171525087]],
    [ "Energie AG",[13.652442049221984, 48.00271015588719]],
    [ "Energie AG",[13.654762830325243, 48.006046796401606]],
    [ "Energie AG",[13.647046439220016, 47.993917782677045]],
    [ "Energie AG",[13.649171712561497, 47.99471191019303]],
    [ "Energie AG",[13.646993284457189, 47.994097444666835]],
    [ "Oberlixlau",[13.659385425351491, 47.993828939876266]],
    [ "Pichlwang Kaplanweg",[13.616141184076408, 47.98880333377766]],
    [ "Energie AG",[13.613061300000004, 47.979493999999995]],
    [ "Energie AG",[13.60009380698118, 47.97575508970472]],
    [ "Energie AG",[13.603948251547, 47.973360817067736]],
    [ "Lenzing Waldstr.",[13.602121697695225, 47.97070689262362]],
    [ "Energie AG",[13.609069251954207, 47.9727222682699]],
    [ "Energie AG",[13.606284328469357, 47.96840294421937]],
    [ "Lenzing Agerstr.",[13.60795817626968, 47.96881638143227]],
    [ "Energie AG",[13.607246805974858, 47.965103631641156]],
    [ "Energie AG",[13.608218572168203, 47.96320093665815]],
    [ "Unterachmann",[13.6123369, 47.9617409]],
    [ "Energie AG",[13.623561285432409, 47.97931822208956]],
    [ "Lenzing AG UST",[13.619895005197746, 47.97967244174489]],
    [ "Energie AG",[13.618650722619849, 47.98341344448709]],
    [ "Haidach b. Seewalchen",[13.603394200000002, 47.96461060000001]],
    [ "Seewalchen Gewerbe",[13.606186470390197, 47.95137687963702]],
    [ "Energie AG",[13.603442374409447, 47.95410165567831]],
    [ "Energie AG",[13.602981253944877, 47.95578194767235]],
    [ "Schörfling Schießstatt",[13.601764441640446, 47.94617077133986]],
    [ "Energie AG",[13.596937101398915, 47.94808996804101]],
    [ "Kammer Weyreggerstrasse",[13.596666403064852, 47.94274709709581]],
    [ "Energie AG",[13.600465402586018, 47.943323322879564]],
    [ "Schörfling Gahbergweg",[13.607989029869099, 47.942234905780374]],
    [ "Schörfling Gmundnerstr.",[13.608938103343178, 47.94802130402236]],
    [ "Energie AG",[13.60553801690385, 47.94589095384307]],
    [ "Energie AG",[13.612213460415711, 47.95139774407663]],
    [ "Energie AG",[13.614134171728958, 47.95301013581875]],
    [ "Schörfling Gewerbegebiet",[13.616575796070673, 47.95251616631349]],
    [ "Energie AG",[13.61863248516098, 47.952875579160626]],
    [ "Energie AG",[13.589794714422965, 47.935127584297256]],
    [ "Energie AG",[13.579011421459231, 47.9168937931334]],
    [ "Weyregg Landeroith",[13.57336609511714, 47.90934921178539]],
    [ "Energie AG",[13.574230966013916, 47.90433042795303]],
    [ "Energie AG",[13.52774547089653, 48.01798867290609]],
    [ "Energie AG",[13.539292221000757, 48.03949709295322]],
    [ "Energie AG",[13.531860208049855, 48.04136205844236]],
    [ "Energie AG",[13.53604255920988, 48.04176770384322]],
    [ "Energie AG",[13.565099497638933, 47.98496769560009]],
    [ "Gampern Sportplatz",[13.556614091235792, 47.988097848955775]],
    [ "Energie AG",[13.58832974589442, 47.953665798342385]],
    [ "Energie AG",[13.58131382754188, 47.950910071684454]],
    [ "Seewalchen Schuhfabrik",[13.583178329543438, 47.9547743988526]],
    [ "Seewalchen Häuplweg",[13.580412081251273, 47.95554934114392]],
    [ "Pranzing Autobahn",[13.664158877538641, 47.95312482575983]],
    [ "Aurach Ort",[13.67461982555302, 47.95182530597523]],
    [ "Energie AG",[13.712643996424156, 47.96214888340045]],
    [ "Energie AG",[13.68468933915805, 47.98360912691657]],
    [ "Energie AG",[13.669736302321692, 47.989363398166326]],
    [ "Energie AG",[13.672785010718167, 47.98885454782851]],
    [ "Energie AG",[13.677136523043794, 47.98770507576268]],
    [ "Dietlsiedlung",[13.700593799999998, 47.9844482]],
    [ "Energie AG",[13.716033402391984, 47.98688670118889]],
    [ "Preising Ort",[13.710870815068919, 47.988268701004706]],
    [ "Energie AG",[13.713556466522553, 47.9972878686649]],
    [ "Energie AG",[13.717399012938234, 47.995640489651954]],
    [ "Energie AG",[13.230316084255756, 48.002404768104896]],
    [ "Energie AG",[13.165295249565707, 48.07877844495653]],
    [ "Munderfing Motorenwerk",[13.170398271832761, 48.07828098008562]],
    [ "Energie AG",[13.60541130294689, 47.98981090209336]],
    [ "Umspannwerk Kleinkirchheim",13.7721488],
    [ "Buchenweg",[14.2203187, 48.0275067]],
    [ "312591663",[13.8646677, 48.176406]],
    [ "317410377",[14.2312654, 48.0268085]],
    [ "Kremsmünster Kirchberg",[14.1319458, 48.0602101]],
    [ "332274190",[14.0787598, 48.1611127]],
    [ "Ohlsdorf Ortsplatz",[13.7914309, 47.9605074]],
    [ "344940235",[14.1039843, 48.0912077]],
    [ "353391291",[14.5025637, 48.493615]],
    [ "354494219",[14.1822628, 48.0619203]],
    [ "Sattledt - Nico",[14.0464804, 48.0856279]],
    [ "371585862",[14.0144886, 48.1709423]],
    [ "Energie AG",[13.7662742, 48.2434123]],
    [ "385536483",[13.808022, 48.2326371]],
    [ "Adlwanger Straße",[14.2195617, 48.024579]],
    [ "Traunfeld Siedlung",[13.8140055, 47.9689682]],
    [ "391544956",[14.1244612, 47.9875417]],
    [ "Energie AG",[14.1299629, 47.9701242]],
    [ "Dorfham",[13.8923759, 48.0358131]],
    [ "Micheldorf - Hessendorf",[14.1249245, 47.8822418]],
    [ "Marchtrenk VLW2",[14.112024, 48.1972509]],
    [ "418106776",[14.130546, 47.8902152]],
    [ "423141841",[14.1923076, 48.0319033]],
    [ "459052324",[14.2131524, 48.0000835]],
    [ "471092120",[14.0706779, 48.1835994]],
    [ "471101380",[14.0890437, 48.1902446]],
    [ "Hörsching - Majorweg",[14.1838971, 48.2074677]],
    [ "494792953",[14.0335085, 48.1437867]],
    [ "576466946",[14.3355455, 47.7148718]],
    [ "576531017",[14.3992893, 47.7135615]],
    [ "Hörsching - Haidstraße",[14.167532, 48.2101712]],
    [ "Oberlaussa",[14.5220706, 47.6863724]],
    [ "Energie AG",[13.3293226, 47.8658601]],
    [ "Wartberg - Gartenstraße",[14.1145563, 47.9847483]],
    [ "Wartberg - Gebeshubersiedlung 2",[14.1088643, 47.9832205]],
    [ "Achleiten - Säge",[14.1786252, 48.0725143]],
    [ "Rohr - Stadlbauer",[14.1841082, 48.0632321]],
    [ "660334142",[14.0425784, 48.1659469]],
    [ "Kematen an der Krems - Ort",[14.1884161, 48.1099408]],
    [ "704712410",[14.1234324, 48.1580262]],
    [ "727684733",[16.0566292, 47.5991701]],
    [ "Sierning - Hofer",[14.3136452, 48.0357366]],
    [ "St.Lorenzen Radlitz",[15.2020124, 46.6651415]],
    [ "Sankt Lorenzen (Ort)",[15.1629889, 46.6617602]],
    [ "792276828",[14.2702721, 48.229652]],
    [ "Traun - Obereggerstraße",[14.2552115, 48.2262871]],
    [ "864026757",[14.0127853, 48.1762839]],
    [ "919809443",[13.9829459, 48.1536069]],
    [ "Irnharting Lagerhaus",[13.9133181, 48.1339242]],
    [ "Linz AG",[14.2569154, 48.3027617]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9735487, 47.4636924]],
    [ "Mondsee Markt",[13.3508248, 47.8552425]],
    [ "KELAG",[12.9974464, 46.7552114]],
    [ "Energie AG",[13.7468712, 47.7887071]],
    [ "1434865660",[14.0295672, 48.1688662]],
    [ "St.Bartlmä Petzhütte",[15.1558697, 46.6646742]],
    [ "1516249771",[13.5289006, 47.5745358]],
    [ "1590701022",[14.0188252, 48.1824497]],
    [ "1590713950",[14.0214801, 48.1830662]],
    [ "1603229589",[13.5614847, 47.588133]],
    [ "Hörmsdorf Kolonie",[15.2728071, 46.6994526]],
    [ "Stammeregg",[15.2416837, 46.6621055]],
    [ "Trafo Haid Hopfenweg",[14.2534668, 48.2074182]],
    [ "1797973175",[14.3292623, 47.726366]],
    [ "KNG-Kärnten Netz GmbH",[13.7889263, 46.6496297]],
    [ "Oberlatein Vouch",[15.2623543, 46.666218]],
    [ "1937323227",[12.9603351, 47.9927166]],
    [ "1993443806",[14.087462, 48.0197043]],
    [ "2122656685",[12.4171204, 47.5235786]],
    [ "2122656687",[12.4252149, 47.5227612]],
    [ "2122656689",[12.4099342, 47.5231734]],
    [ "Greim Lenzbauer",[15.1942037, 46.8637163]],
    [ "HAIM",[11.6063131, 47.2722101]],
    [ "HAIM",[11.6155322, 47.26795]],
    [ "Kornriegel",[15.2726938, 46.6634957]],
    [ "Ebensee Steinbründel",[13.7644437, 47.7881729]],
    [ "2346928545",[14.1485771, 48.0514156]],
    [ "Riedauer Straße",[13.4941386, 48.2161703]],
    [ "2412313866",[13.2075416, 47.4658072]],
    [ "Warnblick Schwemmhoisl",[15.1938644, 46.8063495]],
    [ "Warnblick Halmbauer",[15.1995593, 46.8036636]],
    [ "2539487693",[13.1318984, 47.655969]],
    [ "St. Lorenzen Roda",[15.1714745, 46.6598946]],
    [ "Aibl Knass",[15.2294197, 46.682522]],
    [ "Feeisternitz Lichtenegg",[15.258918, 46.6748428]],
    [ "Bachholz/Maritsch",[15.2273786, 46.6539454]],
    [ "3069021737",[14.1029493, 48.1655801]],
    [ "Warnblick Herk",[15.1755333, 46.8051669]],
    [ "Linz AG",[14.2446538, 48.2992463]],
    [ "Kleinalpe Koch",[15.1349365, 47.1947773]],
    [ "Garanas Amtmann",[15.1494394, 46.7590491]],
    [ "Garanas Kochgregor",[15.1325114, 46.7735387]],
    [ "Garanas Maxlippi",[15.1339736, 46.7647684]],
    [ "Garanas Mentl",[15.1328005, 46.7682403]],
    [ "Garanas Pauritsch",[15.1618144, 46.7593797]],
    [ "Garanas Schreitmattl",[15.1402268, 46.7662261]],
    [ "Garanas Veitlhoma",[15.1472363, 46.762544]],
    [ "Garanas Veitlmichl",[15.1349804, 46.7582025]],
    [ "Mainsdorf Weiherbach",[15.191193, 46.7587717]],
    [ "Schwanberg Mörth",[15.1779864, 46.7574541]],
    [ "Kleinalpe Sattelwirt",[15.167227, 47.1913458]],
    [ "Feldbaum Mathi",[15.1795407, 46.8636495]],
    [ "Sallegg Erntoni",[15.1858074, 46.8674501]],
    [ "Sallegg Grandlwirt",[15.1702215, 46.8718311]],
    [ "Sallegg Klugbauer",[15.1535321, 46.8703882]],
    [ "Sallegg/Jagerhansl",[15.1755741, 46.8710324]],
    [ "Bachholz Ort",[15.2018486, 46.6591507]],
    [ "St.Loenzen Polsny",[15.1938258, 46.65504]],
    [ "St.Lorenzen Auenberg",[15.212061, 46.6652846]],
    [ "St.Lorenzen Prassnegg",[15.1925823, 46.6586008]],
    [ "Rosental Siedlung",[15.1285554, 47.0512497]],
    [ "3381604629",[11.604089, 47.2771479]],
    [ "HAIM",[11.6125515, 47.2748718]],
    [ "3505771386",[12.4252746, 47.5215362]],
    [ "3505771387",[12.4236299, 47.5246488]],
    [ "Mitteregg/Gamseggberg",[15.2210937, 46.8791124]],
    [ "Mitteregg/Schifferl",[15.2199086, 46.8759995]],
    [ "Oberlatein Malliseppl",[15.2739945, 46.6754237]],
    [ "Steinbachberg",[15.5227331, 46.6932756]],
    [ "Gundersdorf",[15.2332217, 46.9474485]],
    [ "Hochneuberg",[15.2388373, 46.9406765]],
    [ "Lestein",[15.2324307, 46.9336045]],
    [ "SST Grubberg",[15.2388695, 46.9359482]],
    [ "Grubberg Ort",[15.2301861, 46.940535]],
    [ "Oberlestein",[15.2256685, 46.9375438]],
    [ "Laaken Strutz",[15.0912415, 46.6549388]],
    [ "3798391961",[15.3530528, 46.9644082]],
    [ "SST Stammeregg Schönegger",[15.2209703, 46.6729428]],
    [ "Deutschlandsberg Bad",[15.2058076, 46.8112719]],
    [ "Aibl Maritschsiedlung",[15.2188964, 46.6775312]],
    [ "Hörmsdorf Ort",[15.2622768, 46.6930487]],
    [ "Deutschlandsberg Brücke",[15.2147265, 46.8132872]],
    [ "Remschnigg Hasenkogel",[15.4059276, 46.6649795]],
    [ "Reininghaus Alte Poststrasse 182",[15.4116117, 47.0649045]],
    [ "Reininghaus Mälzerei",[15.4078131, 47.0635917]],
    [ "4018721508",[13.2526666, 47.168347]],
    [ "Kruckenberg Feriensiedlung",[15.1512419, 46.823157]],
    [ "Kruckenberg Strassenresch",[15.1367361, 46.8245516]],
    [ "Kruckenberg Ully",[15.158834, 46.81336]],
    [ "Leibenfeld Edenpater",[15.2077067, 46.8017802]],
    [ "Leibenfeld",[15.2133466, 46.8063105]],
    [ "Trahütten Hazi",[15.1823104, 46.8094629]],
    [ "Trahütten Mody",[15.1652097, 46.8121855]],
    [ "Trahütten Parfusswirt",[15.1247737, 46.8286112]],
    [ "Trahütten Schiermeister",[15.1554169, 46.8249044]],
    [ "Warnblick Fobisch",[15.1942834, 46.8031079]],
    [ "Gressenberg Aldrian",[15.1696579, 46.7687556]],
    [ "Gressenberg Amtmann",[15.1787769, 46.7600298]],
    [ "Gressenberg Fuchs",[15.0747529, 46.8083315]],
    [ "Gressenberg Fürpass",[15.1664366, 46.7700462]],
    [ "Gressenberg Glashütten",[15.0602111, 46.8232757]],
    [ "Gressenberg Grössbauer",[15.0886649, 46.8111092]],
    [ "Gressenberg Koglgasperl",[15.1281108, 46.7895561]],
    [ "Gressenberg Mojer",[15.0576601, 46.8158433]],
    [ "Gressenberg Oberländer",[15.0885931, 46.8202445]],
    [ "Gressenberg Schmuck",[15.0725017, 46.8210609]],
    [ "Gressenberg Schule",[15.1416077, 46.7881319]],
    [ "Gressenberg Strassenhohl",[15.1513823, 46.7835299]],
    [ "Gressenberg Thorwartl",[15.1134183, 46.7986903]],
    [ "Gressenberg Wetl",[15.163509, 46.7732611]],
    [ "Rostock Berger",[15.09189, 46.8296777]],
    [ "Rostock Grosskeuschen",[15.0997478, 46.8297388]],
    [ "Rostock KWK Smolana",[15.1093952, 46.8252841]],
    [ "Trahütten Schwagbauer",[15.117839, 46.8306704]],
    [ "Gressenberg Ofner",[15.0986266, 46.8064231]],
    [ "Stainz Bauhof",[15.2674496, 46.8946669]],
    [ "Schwanenstadt Stadtplatz",[13.7730608, 48.0551537]],
    [ "4265975174",[13.9362572, 48.0144264]],
    [ "Sierling Rainbach",[15.221188, 46.9086225]],
    [ "Teufenbach Kühlhaus",[15.2230458, 46.9111027]],
    [ "Allhaming Gewerbe",[14.1585307, 48.1528533]],
    [ "Mitterspiel Steinerlenz",[15.1778524, 46.8420362]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0577127, 47.5300446]],
    [ "Laakirchen Traunsteinbauer",[13.8169106, 47.9722223]],
    [ "KNG-Kärnten Netz GmbH",[13.4360082, 47.0099052]],
    [ "Verbund Hydro Power GmbH",[13.3527727, 47.065707]],
    [ "KELAG",[13.4548526, 46.9828458]],
    [ "Verbund Hydro Power GmbH",[13.3432254, 46.8954518]],
    [ "KELAG",[13.0469634, 46.9922618]],
    [ "KELAG",[12.8581613, 47.0294669]],
    [ "Verbund Hydro Power GmbH",[13.645446, 46.7214603]],
    [ "Verbund Hydro Power GmbH",[13.7251827, 46.6692828]],
    [ "Verbund Hydro Power GmbH",[13.8287468, 46.63259]],
    [ "KELAG",[13.758418, 46.5600895]],
    [ "VVT Vermögensverwaltung GmbH",[13.4611608, 46.6292009]],
    [ "Großglockner Seilbahn GmbH",[12.8544652, 47.0288791]],
    [ "KELAG",[12.8734352, 47.0372885]],
    [ "KNG-Kärnten Netz GmbH",[13.8284866, 46.7155778]],
    [ "KNG-Kärnten Netz GmbH",[14.0228058, 46.7595746]],
    [ "BST Kirchbichl Kufsteinerstraße",[12.0978698, 47.5188146]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9768399, 47.4854099]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9748093, 47.4813243]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1023216, 47.5460533]],
    [ "Karawankenblick Kinkstraße",[14.2887484, 46.6273258]],
    [ "Steinerne Brücke",[14.2825987, 46.6206759]],
    [ "Luegerstraße 2",[14.2835676, 46.6200935]],
    [ "Brodnig Sonnwendgasse",[14.3074523, 46.6120383]],
    [ "Paternionerbrücke",[14.2699943, 46.6198086]],
    [ "Real-Wohnbau Uni 64",[14.2692101, 46.6176261]],
    [ "Hönigtal/ Schultraße",[15.5615297, 47.0868062]],
    [ "Fortschritt-Zigguln",[14.2931432, 46.6351399]],
    [ "Energie Klagenfurt GmbH",[14.2674256, 46.6157712]],
    [ "KNG-Kärnten Netz GmbH",[13.8213935, 46.6192062]],
    [ "Waxenberg Schule",[14.1922814, 48.4767763]],
    [ "Linz AG",[14.2611719, 48.3018619]],
    [ "Kranzmeierstraße 61A",[14.2730682, 46.6125631]],
    [ "Sanatorium",[14.2961472, 46.627813]],
    [ "6634039225",[12.1286858, 47.4825796]],
    [ "TINETZ-Tiroler Netze GmbH",[11.9883122, 47.4767928]],
    [ "BST Kundl Höck",[11.9861624, 47.4724979]],
    [ "6640734497",[11.8810203, 47.4147757]],
    [ "Managettaweg Siedlung",[15.467486, 47.0695286]],
    [ "Transped",[12.0405214, 47.4849591]],
    [ "Stangl",[12.031308, 47.4780273]],
    [ "BST Kirchbichl Kastengstatterstraße",[12.0787247, 47.5087314]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0642811, 47.5059389]],
    [ "TINETZ-Tiroler Netze GmbH",[12.0207126, 47.4907174]],
    [ "Angerberg Pfaring",[12.0329113, 47.5026108]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9909696, 47.4733958]],
    [ "TINETZ-Stromnetz Tirol AG",[11.981937, 47.4666673]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0670632, 47.5069666]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0668594, 47.5017216]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0545903, 47.5238715]],
    [ "TINETZ",[12.0782796, 47.5173909]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1105519, 47.4868709]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1355769, 47.4756375]],
    [ "TINETZ-Tiroler Netze GmbH",[11.9960122, 47.4894279]],
    [ "BST Kirchbichl Höger",[12.081405, 47.5067216]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9776303, 47.4754784]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9706438, 47.4772333]],
    [ "TINETZ-Tiroler Netze GmbH",[11.9905163, 47.4852949]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0551395, 47.5279856]],
    [ "Kasernstraße West",[13.4698387, 48.215443]],
    [ "Trafostation Kasernstraße Mitte",[13.4766879, 48.2147662]],
    [ "Kabelturm",[12.0658918, 47.4949342]],
    [ "Friedhof",[12.068627, 47.4840175]],
    [ "Bodensiedlung",[12.0858685, 47.4870662]],
    [ "Kreuzbergl",[14.2932471, 46.6288031]],
    [ "Brucknerstr.",[12.0672745, 47.4902974]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9909341, 47.468544]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9835201, 47.4668165]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9614318, 47.4582166]],
    [ "TINETZ-Stromnetz Tirol AG",[11.966184, 47.4928909]],
    [ "TINETZ-Stromnetz Tirol AG",[11.976311, 47.5027019]],
    [ "Lagerhaus",[12.0501754, 47.4800049]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0495531, 47.5090634]],
    [ "Stumpfstr.",[12.0677733, 47.4872058]],
    [ "Rainerstr.",[12.0652199, 47.4882924]],
    [ "Kirche",[12.0661285, 47.485695]],
    [ "Höck",[12.0216809, 47.482193]],
    [ "Lilienthalstr.",[14.2729734, 46.6174781]],
    [ "7160876435",[13.7820261, 47.8574285]],
    [ "Viktring Schloss",[14.2703562, 46.5891501]],
    [ "Angerberg Säge Vögele",[12.0383395, 47.5024396]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0388068, 47.5089922]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0384045, 47.5124716]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0438864, 47.5258122]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0553205, 47.520158]],
    [ "7468505574",[12.1116697, 47.508172]],
    [ "TINETZ-Tiroler Netze GmbH",[12.1183538, 47.5103827]],
    [ "Wasserwelt",[12.0397553, 47.4778988]],
    [ "7499306267",[12.1018097, 47.5032238]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0826361, 47.5347383]],
    [ "7618638090",[12.0756604, 47.4990905]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1156451, 47.5567275]],
    [ "7685894424",[12.1408649, 47.5434787]],
    [ "TINETZ-Tiroler Netze GmbH",[12.1430009, 47.5392427]],
    [ "Wörgler Boden",[12.0970912, 47.4862596]],
    [ "Salzburg Netz",[13.3149549, 47.5929311]],
    [ "Salzburg Netz",[13.319225, 47.5844929]],
    [ "Winkl",[12.08261, 47.485507]],
    [ "Flucking",[12.0884706, 47.4859592]],
    [ "Lakeside Park Süd",[14.2618648, 46.6143034]],
    [ "Kärntnerland Waidmannsdorferstr. 130",[14.2915392, 46.6094303]],
    [ "7774485013",[12.1096728, 47.5016722]],
    [ "TINETZ",[11.874653, 47.4491439]],
    [ "7802173167",[11.9229569, 47.4638844]],
    [ "CST Brixlegg Bahnunterführung",[11.885648, 47.4367855]],
    [ "BST Breitenbach Hachl",[11.9316526, 47.4627134]],
    [ "BST Kramsach Schwaigen",[11.8847387, 47.4422688]],
    [ "Rastbuehel Äussere Ragnitz 135",[15.5412334, 47.0790231]],
    [ "BST Brixlegg FMZ",[11.883182, 47.4369837]],
    [ "7844199092",[14.3085408, 46.6362651]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0441665, 47.5099733]],
    [ "Gmünd Moostratten",[13.5353716, 46.9031079]],
    [ "Magazingasse",[14.3044326, 46.6295067]],
    [ "7905934279",[14.3071562, 46.631489]],
    [ "7905934280",[14.3066615, 46.6271232]],
    [ "Paracelsusgasse",[14.2987307, 46.6396941]],
    [ "Johann Koller-Weg",[15.4599187, 47.0361443]],
    [ "Lahntal Süd",[12.0438252, 47.474218]],
    [ "BST Kirchbichl Schroll",[12.0879791, 47.5144929]],
    [ "Kainbach Greimelweg",[15.5323925, 47.0793721]],
    [ "TINETZ-Stromnetz Tirol AG",[11.9861531, 47.4680549]],
    [ "8155685441",[14.041183, 48.1894509]],
    [ "Ziehrerstraße 70",[15.4525251, 47.0396135]],
    [ "Laßnitzhöhe Tesla",[15.5782332, 47.0711059]],
    [ "Liebenau Herbert-Boeckl-Gasse 15",[15.469528, 47.0346171]],
    [ "Stifting Edelweisssiedlung II",[15.4837384, 47.0893974]],
    [ "TINETZ",[12.0806305, 47.5139598]],
    [ "8657132862",[12.0899177, 47.4966164]],
    [ "Gradnerstraße 180",[15.404185, 47.0219053]],
    [ "KNG-Kärnten Netz GmbH",[14.4520374, 46.8070395]],
    [ "KNG-Kärnten Netz GmbH",[14.4565313, 46.8609357]],
    [ "Platte",[15.4636652, 47.1095741]],
    [ "CST Brixlegg Postamt",[11.8794373, 47.4314613]],
    [ "BST Kirchbichl Alpenländische",[12.0860536, 47.5040384]],
    [ "BST Wörgl Fuchs",[12.1132965, 47.4849306]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1242318, 47.4756015]],
    [ "8895724366",[12.1489993, 47.5524854]],
    [ "TINETZ-Tiroler Netze GmbH",[12.0943299, 47.5162465]],
    [ "Ries Kriegerdenkmal II",[15.4968092, 47.0856319]],
    [ "Bahnhofstr.",[12.0616411, 47.4896646]],
    [ "Endach 2",[12.1500599, 47.5683093]],
    [ "Stadtwerke Kufstein",[12.1699715, 47.5827208]],
    [ "EW Weissach",[12.142951, 47.563265]],
    [ "Viking",[12.1634652, 47.5888849]],
    [ "Innpark",[12.1581902, 47.5763782]],
    [ "Wechselberger",[12.1669625, 47.579383]],
    [ "UW Frischergries",[12.1683575, 47.5850759]],
    [ "Innotech",[12.1623581, 47.5776832]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1019122, 47.5513912]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1073544, 47.5546171]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1002251, 47.5492206]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1597523, 47.5537083]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1067197, 47.5269315]],
    [ "TINETZ-Stromnetz Tirol AG",[12.0967956, 47.5244337]],
    [ "Villach Neukauf Promenade Süd",[13.8586673, 46.6033377]],
    [ "Villach Neukauf Promenade Nord",[13.8608039, 46.6055296]],
    [ "9054363151",[12.1716808, 47.5918986]],
    [ "9057129258",[13.6051179, 47.093283]],
    [ "Bärental",[12.1624915, 47.591597]],
    [ "9072272712",[12.1687149, 47.5891585]],
    [ "Stadtwerke Kufstein",[12.1551691, 47.5741381]],
    [ "St. Margarethen Silverjet 1",[13.6885573, 47.0802762]],
    [ "Friedensiedlung",[12.1790934, 47.5971966]],
    [ "Grabher",[9.6696395, 47.4393148]],
    [ "Grabher - Meyer",[9.6704728, 47.4393337]],
    [ "Heimkehrer Siedlung",[9.6608277, 47.4397866]],
    [ "Wasserwerk",[9.6478337, 47.4386219]],
    [ "Höchster Straße",[9.6616936, 47.4477133]],
    [ "Fulterer",[9.662784, 47.4493501]],
    [ "Carini",[9.6729533, 47.4404448]],
    [ "Kral",[9.6723027, 47.4400963]],
    [ "Heizbösch",[9.6706534, 47.4417133]],
    [ "Gummibandweberei Alge",[9.6704766, 47.4432719]],
    [ "Perzi",[9.6687238, 47.4452131]],
    [ "Bruggerwiesen",[9.6680139, 47.4472907]],
    [ "Ladestraße",[12.0716515, 47.4902693]],
    [ "Unterwerk Tannhof",[15.4881579, 47.1032694]],
    [ "Unterwerk FH Joanneum",[15.4059948, 47.0697957]],
    [ "Unterwerk Roseggerhaus",[15.4270468, 47.0708686]],
    [ "Unterwerk Jakominiplatz",[15.4416929, 47.0674118]],
    [ "Unterwerk Kaiser-Josef-Platz",[15.4453849, 47.0683549]],
    [ "Unterwerk Lichtenfelsgasse",[15.4504798, 47.072237]],
    [ "Unterwerk LKH Med Uni",[15.4681698, 47.0822363]],
    [ "Liebenauer Hauptstrasse 20",[15.4563213, 47.0459382]],
    [ "Liebenauer Hauptstrasse 28",[15.4568772, 47.0447104]],
    [ "Energie Steiermark",[15.5813368, 47.104313]],
    [ "Parkdeck",[11.7748949, 47.3875786]],
    [ "TINETZ",[11.8000453, 47.4041256]],
    [ "Energie Steiermark",[15.4753553, 47.0346715]],
    [ "M4",[12.0704457, 47.489275]],
    [ "9462141440",[11.8477826, 47.3391359]],
    [ "9462144385",[11.8427012, 47.3391464]],
    [ "9462148817",[11.8489981, 47.3393087]],
    [ "Reininghaus Gewerbepark Süd",[15.4113854, 47.0629951]],
    [ "Netz Niederösterreich",[16.0854184, 47.5533975]],
    [ "TINETZ",[11.8163156, 47.4043589]],
    [ "Roseggerweg II",[15.4868314, 47.097554]],
    [ "Hafnerstraße 20 II",[15.4311748, 47.0269986]],
    [ "Schaltstelle Puntigam",[15.4234258, 47.0245926]],
    [ "TST Wimpassing Rathaus",[16.0271876, 47.700195]],
    [ "TST Hirschwang Edlach Mitte",[15.815865, 47.6954525]],
    [ "SSTA Brixlegg Gießwein",[11.8797813, 47.4364737]],
    [ "TINETZ",[11.8820481, 47.4371617]],
    [ "BST Kramsach Amerling",[11.8789706, 47.4407568]],
    [ "9686703203",[11.8452258, 47.3926151]],
    [ "TINETZ-Tiroler Netze GmbH",[11.8227415, 47.3972673]],
    [ "CST Jenbach ZLJ",[11.7573402, 47.3903001]],
    [ "Ruckerlberggasse 56 II",[15.4694138, 47.0727456]],
    [ "Wagramer Weg 22 PV Anlage",[15.4337324, 47.0325416]],
    [ "Unterwerk Bergern",[15.2722838, 48.2180865]],
    [ "Unterwerk Auhof",[16.2368652, 48.1995323]],
    [ "Unterwerk Ötztal",[10.8740674, 47.2466932]],
    [ "TINETZ",[11.883979, 47.4485528]],
    [ "TST Neunkirchen Blätterstraße",[16.0864825, 47.7377627]],
    [ "Metallverarbeitung Egger",[9.6545195, 47.4339605]],
    [ "Thondorf Fernwaerme II",[15.4738769, 47.0154639]],
    [ "TINETZ",[11.9737378, 47.5213635]],
    [ "TINETZ",[11.9823614, 47.5060292]],
    [ "9842351039",[16.1320006, 47.6332322]],
    [ "9842351040",[16.1260823, 47.6486764]],
    [ "9842351041",[16.1293018, 47.6381054]],
    [ "9842351043",[16.1029421, 47.6135458]],
    [ "Wiener Netze GmbH",[16.4055612, 48.0865117]],
    [ "9855887523",[15.3052641, 46.8144134]],
    [ "9855887524",[14.8580986, 46.724284]],
    [ "Netz Burgenland",[16.6201672, 47.8021916]],
    [ "9861181421",[16.8255865, 47.8458055]],
    [ "Steinbacherstr.",[12.0651019, 47.4890178]],
    [ "Spar",[12.0557222, 47.4860417]],
    [ "Giessen",[12.0569503, 47.4910586]],
    [ "TST Gloggnitz Schlaglstraße",[15.9284859, 47.6658748]],
    [ "Energie Steiermark",[15.4483262, 47.1008532]],
    [ "Wiener Netze",[16.357522, 48.0675784]],
    [ "Wiener Netze",[16.3753142, 48.0931348]],
    [ "Wiener Netze",[16.3940306, 48.0830163]],
    [ "TINETZ",[11.8380321, 47.3920519]],
    [ "BST Münster REHA Wohnbau",[11.8392462, 47.4183711]],
    [ "TINETZ",[11.9734699, 47.4737278]],
    [ "Innpromenade",[12.1609519, 47.5770301]],
    [ "Wiener Netze",[16.2865002, 48.1049958]],
    [ "SST Schwoich Hafen",[12.1337602, 47.5567625]],
    [ "BKSTf Schwoich Saugbagger",[12.1338169, 47.5568097]],
    [ "TINETZ",[12.0932067, 47.5306626]],
    [ "BST Niederbr.bach Anco",[12.0903642, 47.5312582]],
    [ "KNG-Kärnten Netz GmbH",[14.7741327, 46.5405328]],
    [ "Wiener Netze",[16.4140617, 48.1875849]],
    [ "TINETZ",[11.7179489, 47.3627019]],
    [ "BST Stans Unterdorf",[11.7231031, 47.364101]],
    [ "BFST Jenbach Schießstandstraße",[11.7703918, 47.3884949]],
    [ "ÖBB-Infrastruktur AG",[12.5672762, 47.2616077]],
    [ "TST Kapellen Carl-Ludwig-Haus",[15.7039287, 47.6887833]],
    [ "Stickereizentrum",[9.6557716, 47.4301938]],
    [ "BST Breitenbach Reichen",[11.9422396, 47.4796933]],
    [ "Angath Siedlung",[12.0669891, 47.5095267]],
    [ "Angerberg Vordergrub",[12.050925, 47.5126458]],
    [ "Stimmerfeldstraße",[12.1612443, 47.5844529]],
    [ "KNG-Kärnten Netz GmbH",[13.4993568, 46.824897]],
    [ "Ries Kino",[15.473803, 47.0795141]],
    [ "Energie Steiermark",[15.44526, 47.035489]],
    [ "Schützengartenstraße",[9.654307, 47.4178864]],
    [ "Lakeside Park",[14.2624144, 46.6149082]],
    [ "KNG-Kärnten Netz GmbH",[14.3142168, 46.6036597]],
    [ "TINETZ",[11.8806423, 47.4338307]],
    [ "Haignitzhofweg",[15.4569066, 47.0947448]],
    [ "Netz Niederösterreich GmbH",[16.2351338, 47.8219466]],
    [ "Netz NÖ",[16.1434373, 47.9360097]],
    [ "First Chemie",[9.6707248, 47.3969344]],
    [ "Netz NÖ",[16.1027198, 47.9430389]],
    [ "Netz NÖ",[16.1012509, 47.9438966]],
    [ "Netz NÖ",[16.0612136, 47.9755029]],
    [ "Netz NÖ",[15.6775833, 47.7636462]],
    [ "Linz AG",[14.3002584, 48.2870917]],
    [ "Netz NÖ",[15.9892976, 47.9267574]],
    [ "10587663898",[12.0805614, 47.4982721]],
    [ "KST Brixlegg Montanwerk",[11.8724589, 47.4295688]],
    [ "CST Brixlegg Sigwartbrücke",[11.8769791, 47.4281957]],
    [ "Schwimmbad",[9.669681, 47.4286853]],
    [ "Vorachstraße",[9.6746168, 47.4250177]],
    [ "BST Lienz Altersheim",[12.7642562, 46.8334991]],
    [ "UST Lienz Michaelsplatz",[12.7671567, 46.8338885]],
    [ "UST Lienz Patriasdorf",[12.7620933, 46.8353643]],
    [ "UST Lienz Linker Iselweg",[12.769517, 46.8315778]],
    [ "Tiefgarage",[15.4683375, 47.0818647]],
    [ "UST Lienz Hallenbad",[12.7653891, 46.824239]],
    [ "CST Lienz Amlacherstraße",[12.7661696, 46.8259844]],
    [ "Lahnbachgasse 1",[11.7071708, 47.3481022]],
    [ "Josef-Heiß-Straße 2",[11.6984345, 47.347602]],
    [ "Josef-Heiß-Straße 1",[11.6964755, 47.3476311]],
    [ "Netz Niederösterreich GmbH",[15.7248251, 48.0338834]],
    [ "Biogasanlage König",[9.6861692, 47.4258189]],
    [ "Seepark Hotel",[14.26039, 46.617299]],
    [ "Universität",[14.2658006, 46.6161964]],
    [ "KNG-Kärnten Netz GmbH",[13.818344, 46.5648084]],
    [ "TINETZ",[12.1245376, 47.4616379]],
    [ "Stadtwerke Wörgl",[12.0593183, 47.4813936]],
    [ "Wiener Netze",[16.6080645, 48.1168439]],
    [ "Wiener Netze",[16.6153843, 48.1133331]],
    [ "Wiener Netze",[16.4348534, 48.0812624]],
    [ "Bad Eisstein",[12.0612086, 47.4788136]],
    [ "BFST Jenbach/Kirchlergründe",[11.7678192, 47.3935055]],
    [ "BST Jenbach/Fischl",[11.7746188, 47.4014695]],
    [ "TINETZ-Stromnetz Tirol AG",[12.1173996, 47.4802768]],
    [ "BST Wörgl Morandell",[12.1071408, 47.4867878]],
    [ "TST Wr. Neustadt Gymelsdorfergasse",[16.2388782, 47.8024733]],
    [ "Kirchbichl Waldruhe",[12.0830512, 47.5026575]],
    [ "TINETZ",[12.1347263, 47.4485494]],
    [ "BST Jenbach Hagebau",[11.7663701, 47.3895354]],
    [ "Schebesta",[12.1709464, 47.5940661]],
    [ "Gewerbehof",[12.1701797, 47.5848022]],
    [ "TINETZ",[12.2730851, 47.6465247]],
    [ "TST Rohrbach-Ternitz König",[16.058599, 47.7255194]],
    [ "TINETZ",[11.8391636, 47.4029377]],
    [ "Reith i.A. St.Gertraudi Leitner",[11.8467618, 47.4096672]],
    [ "Münster Lichtwehr 2",[11.8547369, 47.4158376]],
    [ "Hauptbahnhof Lastenstrasse",[15.4151313, 47.0809458]],
    [ "Tunnelwarte Suedguertel",[15.459725, 47.0327186]],
    [ "Am Lineck II",[15.4783921, 47.1262197]],
    [ "Wenisbucher Straße II",[15.4806164, 47.1167126]],
    [ "Pfangberg Süd",[15.4447349, 47.1156601]],
    [ "Hauptbahnhof Plabutscherstrasse 16",[15.4087757, 47.0839688]],
    [ "Messendorf Prinzhoferweg",[15.4852455, 47.0336948]],
    [ "Messendorfberg",[15.4978938, 47.038366]],
    [ "TST Neunkirchen Triester Spitz",[16.0704687, 47.7162656]],
    [ "Plabutscherstrasse",[15.4109135, 47.0834363]],
    [ "Energie Steiermark",[15.4417801, 47.0555229]],
    [ "Umspannanlage Wiener Straße 125",[15.4166604, 47.0828663]],
    [ "St.Peter / Nussbaumerstraße 37",[15.4681628, 47.0540157]],
    [ "TST Jetzelsdorf West",[16.0563884, 48.706542]],
    [ "Stadtwerke Wörgl",[12.0768694, 47.4925826]],
    [ "KNG-Kärnten Netz GmbH",[13.486674, 46.7941763]],
    [ "Münster Harland",[11.8309822, 47.4121419]],
    [ "TST Marbach Pumpwerk",[15.1544056, 48.214176]],
    [ "TST Kummnussbaum Pumpwerk",[15.1764894, 48.2173505]],
    [ "Netz Niederösterreich GmbH",[15.6677506, 48.398107]],
    [ "Münster Gemeinde",[11.8336088, 47.4201142]],
    [ "Kundl Pfeifer",[12.0166337, 47.4739456]],
    [ "Kundl Pfeifer",[12.0165639, 47.4739324]],
    [ "Kundl Gewerbegebiet",[12.015195, 47.4736634]],
    [ "Kundl Nägele",[12.0148557, 47.4736]],
    [ "Energie Graz",[15.4119731, 47.077476]],
    [ "Unterwerk Dreierschützengasse",[15.4119933, 47.0774431]],
    [ "11863140205",[15.4085286, 47.0564652]],
    [ "Kundl Luna",[12.0108542, 47.472337]],
    [ "Linz AG",[14.2519746, 48.3045204]],
    [ "TST Erlach Ortszentrum",[16.2153153, 47.727979]],
    [ "Unterer Plattenweg 68",[15.4590823, 47.1044799]],
    [ "Unterwerk Herz-Jesu-Kirche",[15.4546902, 47.0697028]],
    [ "Krankenhausgasse",[12.1738231, 47.5829203]],
    [ "Meraner Straße",[12.1798984, 47.589088]],
    [ "Messendorf St. Peter Gürtel 9",[15.4846207, 47.0369126]],
    [ "Koeglerweg",[15.4882118, 47.0399625]],
    [ "Straßgang Mela Spira Straße 20",[15.3975805, 47.0334132]],
    [ "Schaftal II",[15.5125041, 47.1049462]],
    [ "Kirchbichl/Innbrücke",[12.0936406, 47.5220009]],
    [ "TST Stockerau Hauptstraße 8",[16.2092909, 48.3841921]],
    [ "Kundl Lindenweg",[11.9862288, 47.4733831]],
    [ "Industriestraße 4",[11.7002289, 47.350834]],
    [ "12133862128",[15.4522595, 47.0297222]],
    [ "Wiener Netze",[16.2982414, 48.1341446]],
    [ "Wiener Netze",[16.2875477, 48.1334747]],
    [ "Messendorf Styriastrasse 18",[15.4831664, 47.0419752]],
    [ "Messendorf Styriastrasse 15",[15.4842469, 47.0422358]],
    [ "Trst. Messendorf Styriastrasse 18 Station 2",[15.4843312, 47.0422755]],
    [ "Weblingergürtel 25 Station 2",[15.4153378, 47.0344987]],
    [ "Messendorf Styriastrasse",[15.4845765, 47.042583]],
    [ "Styriastraße 28",[15.4792729, 47.0402272]],
    [ "EW Gösting",[15.4110903, 47.0929685]],
    [ "12250719821",[15.5120052, 47.1018254]],
    [ "Wiener Netze GmbH",[16.2397556, 47.8789514]],
    [ "Sankt Peter Hauptstrasse 29",[15.4709213, 47.0588306]],
    [ "Puntigam Alte Poststrasse 470",[15.430778, 47.0305328]],
    [ "Petrifelderstrasse 25",[15.4703319, 47.0518616]]
];