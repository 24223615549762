export const UK_Substations = [
    [ "1597279",[1.2460788581393978, 52.630024555094785]],
    [ "Boundary Park Primary Substation",[1.2716064074572668, 52.655812089548014]],
    [ "Keadby Substation",[-0.7561390091035263, 53.598066594651854]],
    [ "Leicester Square Substation",[-0.13011169688320973, 51.51036716855512]],
    [ "Skelton Grange",[-1.4962221655157668, 53.777162247367635]],
    [ "Newlands Avenue 5202",[-0.6701666752040759, 53.58951397051445]],
    [ "13578433",[-2.340169221111971, 53.55150361820103]],
    [ "Alverthorpe Road",[-1.5197289347097764, 53.68240201934314]],
    [ "Littlebrook/W Thurrock Cable Compound",[0.2491757073535135, 51.460547286712654]],
    [ "Frimley Substation",[-0.7426247627451276, 51.313875009788255]],
    [ "18312129",[-5.268989683780039, 50.11102037240152]],
    [ "Silverstone Substation",[-1.0311292198626245, 52.07456838518819]],
    [ "Birstall Substation",[-1.129689772083351, 52.669397865357936]],
    [ "4003245",[-0.2004838202822287, 51.98093624744984]],
    [ "Botley Wood",[-1.2326261878744231, 50.88474915597864]],
    [ "Elstree Substation",[-0.34302680778216893, 51.66057914576138]],
    [ "Mill Hill Substation",[-0.20911928219388487, 51.61785412831556]],
    [ "4989349",[-0.38350477421486884, 51.475659974610345]],
    [ "Barnet Substation",[-0.2107303389409593, 51.66116215396222]],
    [ "Northfleet East Substation",[0.32999832639298843, 51.428305690130365]],
    [ "Network Rail Borehamwood Substation",[-0.294214536188879, 51.66453435929644]],
    [ "Lovedean Substation",[-1.0398809024574072, 50.91705623245437]],
    [ "Welwyn Substation",[-0.20959915567302898, 51.78787478665126]],
    [ "Netley Common Substation",[-1.3249396235278592, 50.90554063702844]],
    [ "22973936",[-2.95999868594976, 53.2659228523923]],
    [ "Waltham Cross Substation",[-0.011485706385154832, 51.71413901769652]],
    [ "Marchwood Substation",[-1.4435066601250188, 50.897356753565774]],
    [ "Millbrook Substation",[-1.4657024444194333, 50.91821056751656]],
    [ "Cowley Substation",[-1.1904151596299273, 51.71118075604162]],
    [ "Sheffield City Substation",[-1.4785427394248591, 53.37473900902734]],
    [ "Tottenham Substation",[-0.048025906696519925, 51.60401883192838]],
    [ "Capenhurst Substation",[-2.9473107992306815, 53.2674905444578]],
    [ "Frodsham Substation",[-2.7163770781105145, 53.309344529665154]],
    [ "West Quay Substation",[-1.4122986843829146, 50.90592983197841]],
    [ "West Ham Substation",[0.0012200494275910463, 51.51941544333011]],
    [ "Brimsdown Substation",[-0.02445016930685435, 51.663612655621115]],
    [ "Warley Substation",[0.3007464293246025, 51.552778766684824]],
    [ "Longford Substation",[-0.5050477471903589, 51.480191047047796]],
    [ "Iver Substation",[-0.4980280800748809, 51.543374961043284]],
    [ "Iver Substation",[-0.4972954080936551, 51.54168743837229]],
    [ "23233560",[-0.6236921169439142, 51.52667400322118]],
    [ "23233614",[-0.6142802922296617, 51.50744747258668]],
    [ "23233802",[-0.6071689511620347, 51.5044472343859]],
    [ "Watford Substation",[-0.40449586105428054, 51.64269046194031]],
    [ "23234982",[-1.1719127667034515, 51.36028929903157]],
    [ "23235147",[-0.4077367120859233, 51.64622155608502]],
    [ "Hatch End Grid Substation",[-0.3825604393374219, 51.61627627230911]],
    [ "Harrow North Grid Substation",[-0.36696152164238655, 51.580230465760216]],
    [ "Laleham Substation",[-0.47027441475282156, 51.423972858703166]],
    [ "Staines Substation",[-0.49654672970834457, 51.4385603872078]],
    [ "23249099",[-0.48087835767201376, 51.39104068705073]],
    [ "Byfleet Substation",[-0.5074230456739849, 51.33935483881059]],
    [ "Sellindge Converter Station",[0.9751194318026336, 51.10557811166607]],
    [ "23298780",[-0.5466731, 51.3022805]],
    [ "23298839",[-0.4849973137018295, 51.33246484916949]],
    [ "Leatherhead Substation",[-0.33266585018043493, 51.30281845340494]],
    [ "Beddington Substation",[-0.1275337453838069, 51.37324543319628]],
    [ "Wimbledon Substation",[-0.18828548766783482, 51.43080748902918]],
    [ "Croydon A Substation",[-0.11282888492734296, 51.37682547253315]],
    [ "Croydon B Substation",[-0.12307124240313254, 51.378912704721365]],
    [ "Rowdown Substation",[-0.0038196271532278856, 51.351845088764385]],
    [ "Chilling Headhouse",[-1.2909705659693456, 50.83487112672162]],
    [ "Fort Widley",[-1.0662278449302403, 50.86492454293024]],
    [ "Chessington Substation",[-0.322974331930332, 51.343733915921625]],
    [ "23486534",[-1.1890869786341474, 51.72369529860471]],
    [ "23487707",[-1.1985589582391998, 50.8330659137609]],
    [ "Nottingham Substation",[-1.162599424357377, 52.931871355725825]],
    [ "23751377",[-2.9126537773946755, 53.21567659780035]],
    [ "23803669",[-1.0346745449192656, 50.878828368536205]],
    [ "Nursling Substation",[-1.4872019082696086, 50.94175904273634]],
    [ "23989200",[-1.4718328987663423, 50.949679014841315]],
    [ "Wymondley Substation",[-0.24910083907837546, 51.92775388780629]],
    [ "Sub Station",[-1.3566227354728122, 50.836682126371386]],
    [ "Sub Station",[-1.37431981563324, 50.83856265644214]],
    [ "24403543",[-1.3791255662935664, 50.846673298826275]],
    [ "Eastbourne Grid132 KV",[0.29761523234090537, 50.78330339634001]],
    [ "Templars Way",[-1.3935689361261032, 50.97023310414836]],
    [ "Ferrybridge B Substation",[-1.2781716772508305, 53.72207640904956]],
    [ "Wakefield B",[-1.4699847976427511, 53.67434997564511]],
    [ "Bramford Substation",[1.061733116197597, 52.07129196595509]],
    [ "Cox Street Primary",[-1.502152813130404, 52.40955612208583]],
    [ "Spon Street Substation",[-1.5176662231810178, 52.40857108962098]],
    [ "25455537",[-1.21213729156886, 51.76962981500991]],
    [ "Rodley",[-1.664289049117288, 53.821212815439054]],
    [ "Kirkstall 275",[-1.5980453058239759, 53.806290365618686]],
    [ "Thornton Substation",[-0.8220709100483058, 53.9004002798434]],
    [ "Ocker Hill Substation A",[-2.0295120224488565, 52.54208261067631]],
    [ "Ocker Hill Transmission Station",[-2.032751872243425, 52.54110459479934]],
    [ "Shenfield Substation",[0.3598038126125761, 51.64062455799622]],
    [ "Burwell Substation",[0.31265079444174154, 52.28071374100581]],
    [ "26520113",[-0.7767506567099268, 51.622339156432275]],
    [ "Sowton Substation",[-3.4662061800554778, 50.72647045522467]],
    [ "Exeter Substation",[-3.4053158528075436, 50.767558697543826]],
    [ "Milton Substation",[0.16711581524682131, 52.23276540567695]],
    [ "Smallfield Substation",[-0.13140804457706218, 51.17767965292979]],
    [ "Leigh Substation",[-0.2177770899584698, 51.201933549822364]],
    [ "26711808",[-0.16308530725675216, 51.11479919551251]],
    [ "26711894",[-0.16273112991527675, 51.11730996175947]],
    [ "Histon Substation",[0.10236304235998564, 52.24598991786131]],
    [ "Longton Bulk Supply Point",[-2.133580434678219, 52.97446680905535]],
    [ "Abham Substation",[-3.7303871109101827, 50.471639902906766]],
    [ "Bournville Substation",[-1.9260861573695096, 52.42757721773017]],
    [ "27287163",[-1.1101657219304173, 51.271497106880076]],
    [ "Salisbury Grid Substation",[-1.8435326048367902, 51.08203390762118]],
    [ "28035849",[-2.193343192594273, 53.48663182753759]],
    [ "Kitwell Substation",[-2.013462232831166, 52.43108517644931]],
    [ "Exeter City Substation",[-3.528359638983385, 50.71204411304287]],
    [ "Cliff Quay Substation",[1.1636266540177347, 52.03554907591158]],
    [ "Bromley Substation",[0.06793435883366206, 51.391956864175235]],
    [ "Dutton Substation",[-2.66484814932972, 53.31159547675835]],
    [ "Ninfield Substation",[0.4483953877960911, 50.88121601199495]],
    [ "Ipswich Grid (West End Road)",[1.1361392404027353, 52.05597466103792]],
    [ "29083085",[-2.7444513786244062, 53.3193560272378]],
    [ "Percival Lane Substation",[-2.755509421167811, 53.332302905737656]],
    [ "Rayleigh Main Substation",[0.5665020055378905, 51.593759127874876]],
    [ "Rayleigh Local Substation",[0.5949849023476304, 51.587089650238084]],
    [ "Fulbourn Grid Substation",[0.18705314264118528, 52.188624535456086]],
    [ "Crediton Substation",[-3.66903314730084, 50.7906234817667]],
    [ "Thanet Substation",[1.3897623150546268, 51.36804308808976]],
    [ "Toton Substation",[-1.265049844942849, 52.91634443812371]],
    [ "Kirkby Substation",[-2.848220633043583, 53.46912677895013]],
    [ "Solihull Local No 1",[-1.7800562404950064, 52.41472285649394]],
    [ "St John's Wood substation",[-0.17031527420316195, 51.52745352087213]],
    [ "Bold Substation",[-2.6943220079334957, 53.43397240395755]],
    [ "Sankey Bridges Substation",[-2.6241453475446574, 53.38252545977072]],
    [ "Bustleholm Substation",[-1.9615766050519712, 52.55308416562766]],
    [ "31557531",[-1.0905924824063005, 51.278464919690805]],
    [ "Kitwell Oldbury cct 275kV Sealing End",[-2.028229097583304, 52.496879170298755]],
    [ "Priorswood Substation",[-3.091677466702655, 51.02559415479727]],
    [ "Trowse Grid Substation",[1.3080970516673478, 52.60772718841079]],
    [ "Earlham Grid",[1.2279760218263411, 52.62964830822004]],
    [ "32214252",[-1.067821737015409, 51.269821836137204]],
    [ "Norwich Main Substation",[1.2722952678925619, 52.57403635357926]],
    [ "Cringleford Primary Substation TG1972 0581",[1.2437416782453254, 52.60563371840791]],
    [ "Berechurch Primary TL99942220",[0.9023537024224471, 51.862576212235815]],
    [ "Hurst Substation",[0.1549210161591661, 51.432766563192544]],
    [ "Elmdon Substation",[-1.7123761032409468, 52.45185579238378]],
    [ "Havant BSP",[-0.9979900691533844, 50.84674032766657]],
    [ "Copt Heath Substation",[-1.7490588731856633, 52.406502341648405]],
    [ "Selly Oak Switching Station",[-1.9381756339841152, 52.44439117185059]],
    [ "Bramley Substation",[-1.077280258993671, 51.336292010555105]],
    [ "Abberton Grid",[0.8866452689254956, 51.84456218671056]],
    [ "Sparkbrook Substation",[-1.8595664755095962, 52.46131885871107]],
    [ "Creyke Beck Substation",[-0.415278374979197, 53.8005338080992]],
    [ "Holloway Grid Substation",[-0.11541932355738783, 51.55275844214367]],
    [ "Halesowen Substation",[-2.0466248931282074, 52.45339321712175]],
    [ "Axminster Substation",[-2.9243997987945987, 50.78678954911017]],
    [ "Landulph Substation",[-4.242535217571593, 50.44487758824958]],
    [ "Longbridge Substation",[-1.974234686973076, 52.39626974807319]],
    [ "Barnstaple Substation",[-4.054323433058434, 51.06753944921764]],
    [ "34961402",[0.3677794379794537, 52.60826877419278]],
    [ "35398532",[-2.107286830852761, 51.467468668219155]],
    [ "Bradford",[-1.752639018322785, 53.802543070708595]],
    [ "Warrington Substation",[-2.5684704125152105, 53.388934904316045]],
    [ "36989476",[0.3996828640742041, 52.75781096555199]],
    [ "Newton Poppleford Substation",[-3.2912374567030973, 50.700078857820095]],
    [ "Burlington Street Substation",[-2.994003150517225, 53.418444423979665]],
    [ "Alpington Substation",[1.386502541486077, 52.55702179144512]],
    [ "Hallcroft Road Substation",[-0.9561101, 53.33527495]],
    [ "West Wick Substation",[-2.8964215320219915, 51.34982510390629]],
    [ "UK Power Networks",[1.711506815202057, 52.55520980510087]],
    [ "Walpole Substation",[0.19893356752897223, 52.72678071763894]],
    [ "Eltham Substation",[0.07977900312286512, 51.45975282681003]],
    [ "Sharlston",[-1.4231183715334128, 53.673967445326916]],
    [ "Thorpe Grid",[1.3210826911869344, 52.621983398897335]],
    [ "Parkgate Primary Substation",[-1.270648746669683, 50.87124768558599]],
    [ "EDF Energy Networks Ltd.",[1.514608688042782, 52.622206646957096]],
    [ "East Budleigh Substation",[-3.328397822480399, 50.65637987822804]],
    [ "Ealing Substation",[-0.2959389128682983, 51.5018575516387]],
    [ "Yarnton",[-1.3008226711279363, 51.805305155362525]],
    [ "Dolemeads Substation",[-2.3542121137931034, 51.37824284797855]],
    [ "Higher Woodway Substation",[-3.4963994070940894, 50.56108011341555]],
    [ "Amersham Main",[-0.6560364321562867, 51.664868542994114]],
    [ "41750231",[-0.5896855221261647, 51.72198813628306]],
    [ "41756324",[-0.48333355676228407, 51.77360458579193]],
    [ "Ilmer Grid Substation",[-0.8825075278419212, 51.751770554959755]],
    [ "Bushey Mill Substation",[-0.381445830568592, 51.67134103099293]],
    [ "Bletchley Substation",[-0.7435990976204954, 52.010939974553686]],
    [ "Aylesbury East Substation",[-0.775006388589479, 51.82508652818317]],
    [ "Arbury Substation",[0.1310110761529987, 52.235072004318475]],
    [ "Warwick Substation 132kV",[-1.5668551914284592, 52.286072767273815]],
    [ "Saltholme Substation",[-1.2379942619751336, 54.60557598370728]],
    [ "Norton Substation",[-1.3647025921556661, 54.59221765192567]],
    [ "Charing Cross Substation",[-0.1221954248494969, 51.50747784752465]],
    [ "Iron Acton Substation",[-2.480825239293219, 51.56948210563965]],
    [ "Rushall Substation",[-1.970351051596831, 52.59812549003816]],
    [ "Scottish and Southern Electricity Networks",[-0.48759467892342273, 51.54051404811352]],
    [ "Okehampton Substation",[-4.000018103146787, 50.73772812061566]],
    [ "Sleaford Street Primary Substation",[0.14281305496560787, 52.203682819635226]],
    [ "Bridgwater Substation (BSP)",[-2.9656471843283176, 51.118124989694465]],
    [ "Bridgwater Substation",[-2.9691164732042146, 51.11733977110499]],
    [ "Beeston Substation",[-1.215278230780626, 52.93018296500983]],
    [ "North Wheatley Substation",[-0.8825483842571247, 53.37185009378715]],
    [ "Strood Substation",[0.49969567988270114, 51.40009058849088]],
    [ "Hams Hall Substation",[-1.7142505945278206, 52.53106219761112]],
    [ "Willesden Substation",[-0.25749942072156967, 51.53398301257795]],
    [ "Willesden Feeder Station",[-0.25508539744892295, 51.53365161035956]],
    [ "Shirley Substation",[-1.8061301693211718, 52.3946156407492]],
    [ "Chickerell Substation",[-2.4877531702614366, 50.62417882092422]],
    [ "Norrington Substation",[-2.1699306000000003, 51.38377705000001]],
    [ "Potter House Substation",[-1.5907579607773241, 54.799539189012975]],
    [ "Timberdine Substation",[-2.205473161143144, 52.167712260201775]],
    [ "54754120",[-0.10429796438783734, 52.43816891293372]],
    [ "Coventry Substation",[-1.463548773460175, 52.455982410505236]],
    [ "55386403",[-1.5644346440185741, 52.38368474535453]],
    [ "Coventry North Substation",[-1.4678182986549226, 52.45311090910189]],
    [ "Wribbenhall Substation",[-2.3079135463774336, 52.385138618121104]],
    [ "Craven Arms Substation",[-2.831651345475773, 52.440968158879095]],
    [ "Malvern Substation",[-2.305165573632377, 52.12646292920943]],
    [ "Kenmore Road",[-1.5204692626937732, 53.71726750442073]],
    [ "57953488",[-0.17773198826968978, 51.9191374286301]],
    [ "Bradley Stoke Substation",[-2.5600283404594344, 51.54739903284021]],
    [ "Rover Lode La. No. 5 11kV",[-1.7682724, 52.43768590000001]],
    [ "UK Power Networks",[-0.3206100840407897, 51.23158683089404]],
    [ "59960720",[-0.6165000127156832, 51.190233261816125]],
    [ "Morrell Library Substation",[-1.0522860180785434, 53.949551951402846]],
    [ "Chelmsley Wood Substation",[-1.7365494454605068, 52.47972192188431]],
    [ "Warwick Substation 11kV",[-1.5619199733132274, 52.28621592314846]],
    [ "Leeman Road York",[-1.0945321215914383, 53.959901933236566]],
    [ "Boughton Road Substation",[-1.8207125727931397, 52.46427598107738]],
    [ "Guildford Substation",[-0.57844775, 51.24570005000001]],
    [ "Westwood Substation",[-1.3150395213706851, 53.05642279502846]],
    [ "Wix Primary",[1.144887430348963, 51.91911780516356]],
    [ "Warndon Substation",[-2.193972649077665, 52.21744369183208]],
    [ "Worcester Substation",[-2.2363737225675795, 52.191916911215756]],
    [ "Willington Substation",[-1.5501727686236428, 52.8556768659518]],
    [ "Derby South Substation",[-1.4612145419125542, 52.88569854531642]],
    [ "Highters Heath Substation",[-1.8699012579741185, 52.40426011903457]],
    [ "74202653",[-1.1068698801688532, 51.37236539353466]],
    [ "Shipston Substation",[-1.642793020060325, 52.07958646474496]],
    [ "Summer Lane Substation",[-1.8990611621427496, 52.48736729128672]],
    [ "Bordesley Substation",[-1.87954327423125, 52.47453301442969]],
    [ "75490098",[-1.534741492434084, 54.714229859369716]],
    [ "National Grid Electricity Distribution Plc",[-1.7101877584477376, 52.6380642529498]],
    [ "Outwood",[-1.5106578373179975, 53.71802183244184]],
    [ "Ladywood Substation",[-1.9158657425249621, 52.476965134946255]],
    [ "Stocking Pelham Substation",[0.11846011077088545, 51.935052834474966]],
    [ "Sutton Coldfield Substation",[-1.8140710120923638, 52.56214363735048]],
    [ "78861453",[-1.39088958899291, 50.97077332749242]],
    [ "Munnings Drive Substation",[1.1438998880503883, 51.80757336370869]],
    [ "Newton Aycliffe South",[-1.584841285038518, 54.61010133429268]],
    [ "Spa Road Substation",[-2.716346667153042, 53.75948332199255]],
    [ "Litherland Substation",[-3.009838638157677, 53.47532291331968]],
    [ "Aintree Substation",[-2.9488487695018564, 53.49099022521668]],
    [ "Southport Substation",[-2.9610375435869423, 53.64350123173283]],
    [ "Washway Farm Substation",[-2.819483007901917, 53.562597002173646]],
    [ "Gaskell Road Substation",[-2.7128348050524145, 53.747043175954786]],
    [ "Stocks Mill Substation",[-2.72157495, 53.77044309999999]],
    [ "Avenham Lane Substation",[-2.6921989500000003, 53.757085100000005]],
    [ "Gnosall",[-2.2577606, 52.78213615]],
    [ "Chester Street Substation",[-1.8811252339295397, 52.496249172168824]],
    [ "Heybarnes Road Substation",[-1.8357549953821153, 52.46693423266473]],
    [ "Stanton Substation",[-1.312563626821495, 52.94791726657076]],
    [ "Oakham Substation",[-0.7101294271541428, 52.66814122470831]],
    [ "Drakelow Substation",[-1.6525627534263108, 52.77323470357459]],
    [ "Cellarhead Substation",[-2.083385683324329, 53.04085817093262]],
    [ "Forsbrook Substation",[-2.0438516755470655, 52.980057517009946]],
    [ "Barlaston Substation",[-2.167621939140826, 52.92394512593428]],
    [ "Tamworth Substation",[-1.6564850923274228, 52.60836796246761]],
    [ "York Street",[-1.4989479611469803, 53.68615971438106]],
    [ "Rye House Substation",[0.008567060619666134, 51.75904501762908]],
    [ "Bretton Substation",[-0.2679439641709878, 52.598591713851796]],
    [ "Hammerley Down Substation",[-2.420269977400154, 51.62547874257383]],
    [ "Canterbury North Substation",[1.096375604736321, 51.293720712937144]],
    [ "Canterbury North Substation",[1.0941676497609842, 51.29233845858237]],
    [ "Bath University Substation",[-2.32851815, 51.37665975]],
    [ "Austin Cannon Substation",[-0.4833456566979957, 52.1269484541907]],
    [ "88129066",[-0.47468189281901446, 52.15459870644019]],
    [ "Grendon Substation",[-0.7278762928698115, 52.24314497906388]],
    [ "Kibworth Substation",[-1.0243987344602308, 52.53719664220859]],
    [ "Fleet Substation",[-0.8818121269317508, 51.250867529687106]],
    [ "88489642",[-1.6442562940043384, 53.90232021021217]],
    [ "Chevin End",[-1.7195683135779176, 53.891467059864965]],
    [ "rockcliffe substation sw",[-3.0007236948313896, 54.95135528089286]],
    [ "Alton Substation",[-0.9614436951169243, 51.15258099497399]],
    [ "Cramlington Substation",[-1.5965593500000002, 55.1063634]],
    [ "Eaton Socon substation",[-0.30168238186023594, 52.21479599229296]],
    [ "Penistone Road",[-1.4880523505520158, 53.397034666577326]],
    [ "Checkerhouse Substation",[-0.9969456643804636, 53.306809262300085]],
    [ "Leighton Buzzard Substation",[-0.6775743331164963, 51.904108062452714]],
    [ "Warkworth 66/20",[-1.657201219919811, 55.34613202406974]],
    [ "Mansfield Street Substation",[-1.1334852482133841, 52.638411878209865]],
    [ "East Wootton Substation",[-1.2537527432346436, 50.710017201608586]],
    [ "89943460",[-1.5516172321486703, 51.03450073255]],
    [ "Mannington Substation",[-1.8951223406210291, 50.84686404434037]],
    [ "Husthwaite Substation",[-1.2276637380946902, 54.157488608684886]],
    [ "Christchurch Substation",[-1.7795254241217147, 50.73762379345291]],
    [ "Osbaldwick Grid Substation",[-1.0230615104336285, 53.95675251812129]],
    [ "Melrosegate",[-1.0587474635486405, 53.95926368666585]],
    [ "90145971",[1.5093681716495249, 52.43792373712631]],
    [ "UK Power Networks",[1.468758829572101, 52.39568848457598]],
    [ "Halesworth Substation",[1.5102031195989378, 52.35617958851009]],
    [ "Irthlingborough Substation",[-0.6140187115153586, 52.31928953399592]],
    [ "Enderby Substation",[-1.2166467402341483, 52.59765066667309]],
    [ "Loughborough Substation",[-1.2097049089872745, 52.786678097899845]],
    [ "Hunningley",[-1.4493475269127518, 53.5484165454664]],
    [ "90539500",[-1.604483596778288, 53.48789696568872]],
    [ "Barugh",[-1.5186303, 53.5728901]],
    [ "Barugh",[-1.5191698637688598, 53.57358832198477]],
    [ "90539508",[-1.6019111194287108, 53.487711482553]],
    [ "Ledston 1621",[-1.3556222230645414, 53.74947345415678]],
    [ "Monk Fryston substation",[-1.2665183397548825, 53.756357208120434]],
    [ "Thurcroft Substation",[-1.2665404035363466, 53.404859208175644]],
    [ "Worksop Substation",[-1.1003819806142985, 53.30606807251931]],
    [ "Thorpe Marsh Substation",[-1.0893588954572815, 53.57552995073674]],
    [ "Thurcroft Substation",[-1.2685952888763938, 53.40487399498011]],
    [ "Wakefield North",[-1.4988997012544227, 53.70603727409119]],
    [ "Guisborough Substation",[-1.0569358896996082, 54.542497682626106]],
    [ "Staythorpe Substation",[-0.8688805466081853, 53.07711914842442]],
    [ "91003260",[-0.87552129940663, 52.999414441168256]],
    [ "Staythorpe B Substation",[-0.8640948359856742, 53.07780503495865]],
    [ "Staythorpe C Substation",[-0.8654920654505699, 53.07688147850653]],
    [ "Kemsley Substation",[0.7415098995742254, 51.368250532802335]],
    [ "Singlewell Substation",[0.3924175493819639, 51.40501629507757]],
    [ "Pembury Grid Substation",[0.3433772726380525, 51.168085838955925]],
    [ "91034594",[-0.6457620402455694, 52.93808629187175]],
    [ "Grantham North Substation",[-0.6465452500000001, 52.9377208]],
    [ "Belmont",[-1.5375139417933696, 54.78339881332733]],
    [ "Ridham Dock Substation",[0.7586828472914601, 51.37944848713475]],
    [ "Sittingbourne Substation",[0.6971905507359912, 51.34448117230354]],
    [ "Bolney Substation",[-0.23528147622199067, 50.97515700828163]],
    [ "Betteshanger Substation",[1.3433605351129485, 51.22809029151077]],
    [ "Appledore Switching Station",[0.7748678779555974, 51.032662348210145]],
    [ "Ashford Substation",[0.8668355131958351, 51.144953152419944]],
    [ "Eurotunnel Main Intake Substation",[1.1474467562534898, 51.0979625977131]],
    [ "Burham Grid",[0.462373777541992, 51.31437079990696]],
    [ "91236807",[-0.3088973603911109, 51.51391641805535]],
    [ "Medway Grid",[0.46139014068260004, 51.35262734099678]],
    [ "Maidstone Grid",[0.5336490169259583, 51.27472240146848]],
    [ "Boyton End Substation",[0.36048062445107915, 51.96560536929577]],
    [ "Fletchwood",[-1.5205837347697468, 50.90752200364234]],
    [ "91582773",[-0.4208021789735764, 52.14995418101483]],
    [ "Hampton Substation",[-0.3626146187304055, 51.42212213020721]],
    [ "Water Lane Primary Substation",[0.7437159013852023, 52.41545911325844]],
    [ "Stella West Sub Station",[-1.743123103857208, 54.97774329291751]],
    [ "Menston Substation",[-1.725959145379869, 53.88529979499443]],
    [ "Corby North Substation",[-0.6807279722593292, 52.50950696941721]],
    [ "Corby Substation",[-0.669445840446122, 52.491213270551114]],
    [ "Tamworth BR Substation",[-1.6871102572675194, 52.636174028447364]],
    [ "Tamworth Town Substation",[-1.6875671221395205, 52.63226827702946]],
    [ "Chatham Grid;Chatham Hill",[0.5396981629397257, 51.377893680466194]],
    [ "Bushbury 'D' Substation",[-2.1145511136391475, 52.63634339972203]],
    [ "Bushbury B-C 132/33/11 kV Substation",[-2.11316181881545, 52.63359873960445]],
    [ "Lichfield Road Cable Sealing End Compound Substation",[-2.0668019085390736, 52.60522116634596]],
    [ "Berkswell Substation",[-1.6135329999679446, 52.386394768899294]],
    [ "National Grid",[-1.615529924210609, 52.38585914038903]],
    [ "Annesley Substation",[-1.244811453383059, 53.08790439389096]],
    [ "Chesterfield Substation",[-1.3879220946585806, 53.2245478249846]],
    [ "East Claydon Substation",[-0.9072399794268032, 51.926405089439534]],
    [ "Brackley Substation",[-1.1460760136955488, 52.04917638449005]],
    [ "92418219",[-0.9989594578458925, 52.69631613721041]],
    [ "Macclesfield Substation",[-2.1209210444129485, 53.268639737849455]],
    [ "Alfreton Substation",[-1.3850781177353577, 53.108884185583705]],
    [ "Hinksford Substatio",[-2.19429356013851, 52.50556694057689]],
    [ "Coseley Substation",[-2.092120362627596, 52.55173137399515]],
    [ "Penn Substation",[-2.209028519894717, 52.55631742892363]],
    [ "Walham Substation",[-2.2535023641035474, 51.87945314555024]],
    [ "92474025",[0.9692736746482784, 51.10579842924683]],
    [ "92474849",[-1.4306599671157891, 51.603713432147586]],
    [ "Walford Substation",[-2.6045758480951813, 51.888228850277414]],
    [ "Hereford",[-2.61963429389788, 52.05400510316835]],
    [ "Daines Substation",[-2.3793128965188997, 53.42600879152348]],
    [ "Sale BSP",[-2.3218356951869557, 53.43247472660543]],
    [ "South Manchester Substation",[-2.275938819276197, 53.42103064608474]],
    [ "Radway Green Substation",[-2.336227078038125, 53.08437283750094]],
    [ "Darlington North Substation",[-1.5607629086695665, 54.55546948775958]],
    [ "92837026",[-1.9865690993383303, 52.440828634759654]],
    [ "Meaford C Substation",[-2.1674230881465615, 52.93160624865427]],
    [ "Longbridge 11kV",[-1.9739615378068203, 52.39610862461153]],
    [ "Congleton Substation",[-2.203866097482043, 53.16334325434421]],
    [ "Leeds East Substation",[-1.5093379703464793, 53.8016181056074]],
    [ "93263973",[-0.6902740576505559, 50.85893774630537]],
    [ "93263986",[-0.8387652760544596, 50.78853204354622]],
    [ "93264071",[-0.767026772384135, 50.81670317769593]],
    [ "Kensal Green Substation",[-0.22400613653592263, 51.52620529096558]],
    [ "Marsh Barton Substation",[-3.529026646923765, 50.70599223133119]],
    [ "Bredbury Substation",[-2.129669417217164, 53.41474268796568]],
    [ "Colchester Grid Substation",[0.9306886487851905, 51.87809774362588]],
    [ "Heysham Substation",[-2.8907847485898355, 54.03243042008159]],
    [ "Burghfield Substation",[-1.002428590524706, 51.42932499352936]],
    [ "94196968",[0.5619128925761068, 52.33605926495363]],
    [ "94197043",[0.5941731802548542, 52.24690115274937]],
    [ "94197054",[0.49620684361021594, 52.28802358783559]],
    [ "Ryde Substation",[-1.158004128469812, 50.72429833747255]],
    [ "Binstead Substation",[-1.202039475772121, 50.71928116946606]],
    [ "Ribble GSP Substation",[-2.732416970664945, 53.757126421165054]],
    [ "Kidsgrove BR Substation",[-2.208179434799706, 53.08093149985904]],
    [ "Knutsford Substation",[-2.3735067379747363, 53.29764211353499]],
    [ "Bentley Substation",[-2.017782953790651, 52.580578149154064]],
    [ "Tilbury Substation",[0.39126393883866734, 51.46015641973413]],
    [ "Perry Barr Substation",[-1.8960547264372671, 52.52704392216543]],
    [ "Lostock Substation",[-2.4626818028592, 53.265986965854054]],
    [ "ICI Wade Substation",[-2.4807898644853323, 53.263487128456426]],
    [ "Stowmarket Substation",[1.0183247444902077, 52.17125651896853]],
    [ "Shalfleet Substation",[-1.4230062730767101, 50.69580845071445]],
    [ "95096763",[0.8673033731979471, 52.213375092222414]],
    [ "North Tees Sub Station",[-1.267022268441587, 54.58799622760633]],
    [ "Stafford Substation",[-2.1222981690170877, 52.81018098353678]],
    [ "Hyde Grid Substation",[-2.0691463030399637, 53.45759387497158]],
    [ "New Mills Substation",[-1.9842935910735784, 53.35233598637818]],
    [ "Buxton Substation",[-1.885066150014755, 53.27598445268012]],
    [ "95236133",[0.8661827896613182, 52.32276651928414]],
    [ "Minety Substation",[-2.002022274215361, 51.60760665039233]],
    [ "Cirencester Grid Substation (CIRE)",[-1.9623404763043273, 51.70202091409775]],
    [ "Electricity North West",[-2.748014586851697, 53.8863956055419]],
    [ "Oldfield Park 33 kV Substation",[-2.37937059136957, 51.3739377196603]],
    [ "Peterborough Power Station Substation",[-0.2015224227463419, 52.575974220991135]],
    [ "95597604",[-1.2350872973995184, 51.6622462715251]],
    [ "Erdington Substation",[-1.8283818581851012, 52.51247650009732]],
    [ "Upton Warren Substation",[-2.0960167141311103, 52.309368069102675]],
    [ "Brelston Green Substation",[-2.6357344957910627, 51.87684294511618]],
    [ "95917248",[0.9116458869735926, 52.24217786977]],
    [ "Chisbon Heath Substation",[1.0927658476875628, 51.82523375576103]],
    [ "Stourport Substation",[-2.2693770397059936, 52.33760857895663]],
    [ "Bishops Wood Substation",[-2.242285720590836, 52.31238718682146]],
    [ "Feckenham Substation",[-1.9728000306706588, 52.25116317610888]],
    [ "Olney 33 11kv S Stn",[-0.7113510957634337, 52.15373801402885]],
    [ "The Mall Substation",[-0.9747808265489823, 51.45407170754165]],
    [ "Burton Substation",[-1.6236657109132688, 52.81601784981629]],
    [ "96338184",[0.7383385116306778, 52.386166896445516]],
    [ "Ellin Street Local",[-1.475075411296664, 53.373295400893326]],
    [ "Folkestone Grid 132kV 009920",[1.1491459184290198, 51.0846918330485]],
    [ "Tynemouth Substation",[-1.5000575634379432, 55.023675313123874]],
    [ "Fox Ash Substation",[1.0233760328831745, 51.93731968102133]],
    [ "96696111",[0.9035356884866798, 52.12242893855287]],
    [ "Market St",[-2.0459758931326446, 53.517343170873694]],
    [ "97174482",[-2.262346809335819, 52.071422721823204]],
    [ "Kearsley Substation",[-2.3577132268745102, 53.53769988564974]],
    [ "Featherstone Substation",[-1.384839865323298, 53.68641094787716]],
    [ "97407187",[-1.4286205651794077, 53.695069013213356]],
    [ "Tod Point Substation",[-1.1167231038998224, 54.60650023840444]],
    [ "Gibson Lane Substation",[-0.5320965631854049, 53.721050565534966]],
    [ "98323879",[-0.49036339999999995, 52.1961888]],
    [ "Aldershot Grid Substation",[-0.7706782017783976, 51.23173021290239]],
    [ "98524677",[-2.356589410569813, 53.397727339139344]],
    [ "Manningtree BR Substation",[1.0495890259854577, 51.94919656588889]],
    [ "Durkar Low Lane Substation",[-1.5119820048288906, 53.6483424663341]],
    [ "Denby Grange Substation",[-1.5937162178962272, 53.63319425071425]],
    [ "98616603",[-1.03350183784862, 51.32154093028386]],
    [ "98665566",[-1.584721300085104, 51.6521774297331]],
    [ "Tunbridge Wells Grid",[0.2743180047081579, 51.145415881131285]],
    [ "Alresford Substation",[0.9945775670304288, 51.86106982025213]],
    [ "Hayton Substation",[-0.764309732029651, 53.90834017447535]],
    [ "Westoning Substation",[-0.4906717226295139, 51.98577154752096]],
    [ "Fraddon Substation",[-4.937098624915483, 50.37536425450554]],
    [ "Wellingborough Substation",[-0.6854159385123265, 52.29193650149799]],
    [ "St Austell BSP Substation",[-4.791213456105279, 50.35505542326156]],
    [ "Lewes Grid",[0.04125635920772049, 50.88720488633311]],
    [ "Pyworthy Substation",[-4.4081401650039345, 50.79407315229802]],
    [ "Alverdiscott Substation",[-4.136944784550412, 51.00583239470789]],
    [ "Wilton Substation",[-1.1317653247946007, 54.57576678686826]],
    [ "Whitby",[-0.612336796725866, 54.477865597667034]],
    [ "Hastings Main",[0.6008065127942099, 50.87733112140869]],
    [ "London Gateway Substation",[0.45520266805022935, 51.50783753018254]],
    [ "Coryton South Substation",[0.5067235237113491, 51.51286097761478]],
    [ "Fleethall Substation",[0.7336559907651085, 51.570727660973205]],
    [ "Maldon Substation",[0.6587663816129853, 51.725243168454945]],
    [ "Chelmsford East Substation",[0.5185300924590127, 51.71599702202212]],
    [ "Springfield BR Substation",[0.5320747022022749, 51.76424857730327]],
    [ "Scunthorpe North Substation",[-0.6511012643733616, 53.60260150748243]],
    [ "100865710",[-0.5946125668445972, 53.5962477337562]],
    [ "100865730",[-0.5939607495287211, 53.59660527918913]],
    [ "Broughton GSP",[-0.5904398803099704, 53.56753240787264]],
    [ "Grovehurst Substation",[0.7538433448552871, 51.36602321602113]],
    [ "Sheerness Grid Substation",[0.7495718802643085, 51.43671654729571]],
    [ "Sheerness Steelworks",[0.7522059361200638, 51.437409787922995]],
    [ "Coventry West 33kV",[-1.6050534594984094, 52.40167509703987]],
    [ "Coventry West 11kV",[-1.6054233259534416, 52.401517796293135]],
    [ "Nevendon Substation",[0.485015948680177, 51.59456704060993]],
    [ "UK Power Networks",[0.42869294579932277, 52.765400411826135]],
    [ "Neepsend Substation",[-1.4881385475580533, 53.40622503182682]],
    [ "Attercliffe Substation",[-1.4273681958384279, 53.39085501570104]],
    [ "Bourne Substation",[-0.38428029178288003, 52.76534182590273]],
    [ "South Holland Substation",[0.03183391407502826, 52.756711293884926]],
    [ "Bourne West Substation",[-0.41972005905064425, 52.76027533697735]],
    [ "Grantham Substation",[-0.5883646823026212, 52.92469520954193]],
    [ "Boston Substation",[-0.014753980855537063, 52.96633257749378]],
    [ "101081032",[-0.3644826500000001, 52.75838925000001]],
    [ "West Melton Substation",[-1.378670734118624, 53.501876467197434]],
    [ "Stamford Substation",[-0.4709784457595402, 52.65155927831138]],
    [ "Melton Mowbray Substation",[-0.8694161035595, 52.7646941471009]],
    [ "Market Deeping Substation",[-0.3011962756595106, 52.67816291291163]],
    [ "Wincobank Substation",[-1.414098498110735, 53.41576403956853]],
    [ "Stevenson Road",[-1.4370105028404623, 53.395254141350776]],
    [ "Harlow West Grid Substation",[0.06642624335460776, 51.7638326947739]],
    [ "Dormansland Grid",[0.02101906987867985, 51.14885128148431]],
    [ "Epping Grid Substation",[0.11911014033161062, 51.707434322633254]],
    [ "102154321",[-0.24653617688077384, 51.98804817342328]],
    [ "Stevenage Substation",[-0.22328444811067533, 51.905232529554354]],
    [ "Barking Substation",[0.11447897168908329, 51.52107567981559]],
    [ "YEDL",[-0.46331890290413513, 53.723274128662]],
    [ "Skerneside Substation",[-1.5321168782624461, 54.60967752084143]],
    [ "102968360",[0.054371829231682114, 52.552674814261735]],
    [ "103009999",[-1.4749485259116408, 53.373386997110465]],
    [ "Combe Park Substation",[-2.3888459617444817, 51.39300292399296]],
    [ "W Thurrock/Littlebrook Cable Compund",[0.2722298296396838, 51.47372932818932]],
    [ "West Thurrock Substation",[0.288279636739799, 51.471099025790515]],
    [ "Bath Easton Substation",[-2.338756967700193, 51.39711365705149]],
    [ "Coryton Substation",[0.5044661239549328, 51.51790749416606]],
    [ "Tilbury Local Substation",[0.3802814175731996, 51.46159121945992]],
    [ "103581626",[0.3636141599621921, 51.478129247389816]],
    [ "UK Power Networks",[0.4146010989643846, 51.48286571701281]],
    [ "Ottery St. Mary Substation",[-3.286619805649292, 50.74566176793656]],
    [ "Woodbury Substation",[-3.415475037817811, 50.666968282184634]],
    [ "103735256",[-1.5914720973430347, 51.76821241268476]],
    [ "National Grid",[0.40752524614680796, 51.44233595441938]],
    [ "South Benfleet Substation",[0.5575532198697428, 51.54649997488623]],
    [ "Melksham Substation",[-2.150669706841549, 51.39357240406239]],
    [ "Chipping Sodbury Substation",[-2.412358900706441, 51.552733834163206]],
    [ "Wapley Substation",[-2.4132767842417366, 51.512118865288734]],
    [ "Lawford Substation",[1.0187942788736057, 51.916606138467664]],
    [ "Langham Substation",[0.9443008684407684, 51.95334662451668]],
    [ "104372210",[0.7026644982452587, 52.64260319215403]],
    [ "Arundel Street",[-1.469901292005014, 53.3755981886727]],
    [ "Alveston Substation",[-2.520905797754885, 51.59411763142627]],
    [ "Darnall",[-1.4107169712350123, 53.38522486466021]],
    [ "104661404",[1.1174020356460477, 51.998187431719046]],
    [ "Broad Street",[-1.4582121654909292, 53.38334297070779]],
    [ "104859150",[-0.31850827535483756, 50.89324608408958]],
    [ "West Grafton Substation",[-1.6526687896524328, 51.331467641510045]],
    [ "105013415",[1.2559503866394488, 51.97174957541646]],
    [ "Ruckinge Grid",[0.8740953376146109, 51.06212175855274]],
    [ "105505658",[-2.181137855282996, 51.718172553925164]],
    [ "105620459",[-0.7502048822888002, 51.43458238479344]],
    [ "105665630",[0.402887049432051, 52.73153580003995]],
    [ "105801012",[0.41251911259674345, 52.6667790712116]],
    [ "106079561",[-1.409503454485536, 50.90635536200541]],
    [ "Coggeshall Substation",[0.684092801630164, 51.86287677053194]],
    [ "106127011",[-1.4268026308176534, 53.391224243924775]],
    [ "106292141",[0.7410674063969194, 52.8298351274108]],
    [ "Linton Substation",[-1.6051825623484952, 55.228239915793594]],
    [ "University Of Birmingham Substation",[-1.9346917, 52.44792645]],
    [ "Duke Street Substation",[-0.15152729272155166, 51.51313765535037]],
    [ "Singleton Primary",[0.8470214157281473, 51.1464456259305]],
    [ "Tiverton Junction Substation",[-3.3812472598793932, 50.895937652043905]],
    [ "Winson Green Substation",[-1.928244281003868, 52.486972865635266]],
    [ "107569787",[-1.2889602938502571, 51.68087001215629]],
    [ "Burwell Primary Substation",[0.3178372640132149, 52.27738610910695]],
    [ "107904397",[0.37897353642436593, 52.26299655490092]],
    [ "Mill Lane Substation",[0.6785991616748013, 51.73530802277132]],
    [ "108019292",[0.67879703729692, 52.085510804101375]],
    [ "Belchamp Substation",[0.6057595941575417, 52.068734638230694]],
    [ "Moorgreen Substation",[-1.2955600057738057, 53.02299559512536]],
    [ "Folkstone 33 kV 00912",[1.1482744052633378, 51.08467485993885]],
    [ "108375327",[1.1853536182508408, 52.218435656110444]],
    [ "Cotton Substation",[1.026339422729429, 52.2602005289115]],
    [ "Rye Grid",[0.7111874697390883, 50.97446123720234]],
    [ "108522366",[1.3250197266132713, 52.230193739480285]],
    [ "Wickham Market Substation",[1.367551455266906, 52.18750394130956]],
    [ "Churchill Substation",[-2.789092350492391, 51.35650187499519]],
    [ "108575028",[1.3475177138071215, 52.314490595146964]],
    [ "108587767",[1.380703321742165, 52.15790658615685]],
    [ "108603274",[1.2749638786048718, 52.57484356200559]],
    [ "Shirland Lane",[-1.423284179213858, 53.390581084337555]],
    [ "108935203",[0.8079742704359352, 52.34168879423416]],
    [ "National Grid",[0.699940287584098, 51.42062317555809]],
    [ "National Grid",[0.7054134474735437, 51.435829330091835]],
    [ "Grain Static Inverter Plant",[0.7161575426832575, 51.44049830101791]],
    [ "Poppleton Substation",[-1.1315449291063868, 53.97496120798917]],
    [ "109598747",[-0.7748269086134268, 51.82597129990534]],
    [ "Lackenby Substation",[-1.1314223067406188, 54.56673535539775]],
    [ "Scottish and Southern Electricity Networks",[-2.2416953991363995, 51.324997617122136]],
    [ "Harrietsham Primary",[0.7073217312403018, 51.239330013416435]],
    [ "Moira Substation",[-1.5531545191821898, 52.740727977896]],
    [ "Luton South Substation",[-0.401079026827075, 51.874514794679904]],
    [ "110310867",[-0.5135652583045129, 51.896406965558135]],
    [ "Newhall Road",[-1.4371157302860649, 53.400143208216505]],
    [ "111204888",[0.7087567177159895, 52.26607980910954]],
    [ "111248318",[-0.2402812022095221, 53.1420650860245]],
    [ "Epwell Substation",[-1.4746759999999997, 52.070084499999986]],
    [ "111507170",[0.47912445466752734, 52.10101608462286]],
    [ "111627660",[-3.495476611378871, 54.42487655813228]],
    [ "111627684",[-3.491137949461517, 54.41891511725599]],
    [ "Ebury Bridge Substation",[-0.14875322944970387, 51.490600276772945]],
    [ "Hawton Substation",[-0.805149629581294, 53.05031240608159]],
    [ "Moreton Street Substation",[-0.13486843822439695, 51.4900835321634]],
    [ "112173692",[-0.27269748161178536, 51.48852210453951]],
    [ "Cut Throat Lane Switching Station",[-0.48770117566118, 52.144326228510344]],
    [ "112882829",[1.0967350960265476, 52.10974355119477]],
    [ "Elmers Marsh substation",[-0.7671164100905207, 51.04793583683097]],
    [ "Hartley Grid",[0.506326770059323, 51.093429441634726]],
    [ "Snaithing Park Road",[-1.533227587580934, 53.372371537550045]],
    [ "Doncaster Central 132/33/25kV",[-1.1384337883670799, 53.52681338775005]],
    [ "Droitwich Substation",[-2.170668744646858, 52.27388988807499]],
    [ "St Ann's Substation",[-1.1431244817678372, 52.956810746247626]],
    [ "Culham JET Substation",[-1.228266382506022, 51.66108028397524]],
    [ "Steventon Substation",[-1.326950931796362, 51.63123282644413]],
    [ "115554230",[-1.317368239754912, 51.57958658813633]],
    [ "Alcuin College Substation",[-1.0488430396128074, 53.94980202082403]],
    [ "115678830",[-2.2738140720800977, 50.678196850145085]],
    [ "West Oxford Substation",[-1.2762044551029887, 51.75033478733645]],
    [ "115762970",[-1.2762017140536737, 51.749704367509615]],
    [ "115770505",[-1.4853631942687369, 51.772040862400644]],
    [ "115790999",[-1.5174719198343025, 51.78540499670982]],
    [ "115794810",[-1.235859164543883, 51.70594753630926]],
    [ "Law And Management Substation",[-1.03228556807939, 53.94775019512818]],
    [ "Tinsley Wire Industries",[-1.4073840523244536, 53.40795900838369]],
    [ "Tinsley Park Substation",[-1.3946728007908147, 53.40117102913102]],
    [ "Grangetown Substation",[-1.1492272367688374, 54.581702894946574]],
    [ "Herne Bay Substation",[1.0956878962314553, 51.353580261088304]],
    [ "116539366",[-1.4427254281589754, 53.40126509759584]],
    [ "116539368",[-1.4445252805629876, 53.40104798622432]],
    [ "Pitsmoor Substation",[-1.4437159545097353, 53.40082263158929]],
    [ "Halifax substation",[-1.8582944164057444, 53.72627534729387]],
    [ "116863176",[-1.5547392000000018, 54.0010673]],
    [ "Longford Street Substation",[-0.14173146533687148, 51.5252100329161]],
    [ "Ross",[-2.589847626996621, 51.92177647337832]],
    [ "Leicester Substation",[-1.141250476764432, 52.62251504404806]],
    [ "Raynesway Industrial Units",[-1.4268887499999998, 52.90223764999999]],
    [ "Mansfield Road",[-1.4089926473864156, 53.35473582957079]],
    [ "East Yelland Substation",[-4.171609438370895, 51.06822575426486]],
    [ "Flagg Substation",[-1.7820458183084038, 53.215079241734465]],
    [ "Moreton Substation",[-1.7019401596067567, 51.99408088299432]],
    [ "Hebden Bridge",[-2.0299846512491255, 53.739754385434026]],
    [ "Rye Primary",[0.7314721482195814, 50.96112379232332]],
    [ "Taunton Substation",[-3.1542237740579826, 51.017007129035264]],
    [ "Northiam Primary",[0.6092974712899537, 50.98717723960786]],
    [ "Peterborough Central Substation",[-0.24312111401528574, 52.56750528840412]],
    [ "Neepsend",[-1.486288695303753, 53.403710133741946]],
    [ "Mereworth primary",[0.3704118562501616, 51.25538549197594]],
    [ "121219576",[0.5499370662551544, 51.25189879456342]],
    [ "Canterbury South Substation",[1.0868921208302937, 51.29084976966462]],
    [ "Stella North Substation",[-1.7245901075977859, 54.97803202339338]],
    [ "Campion Hills Substation",[-1.5160406002515074, 52.29650894546068]],
    [ "122587388",[0.5322913414895555, 52.39167813897421]],
    [ "Gidea Park Primary",[0.2199742714045047, 51.587267105638574]],
    [ "Bowesfield",[-1.3183025352447963, 54.55174604478446]],
    [ "Bowesfield",[-1.3187712430428837, 54.552635184905924]],
    [ "Street Substation",[-2.7528910947585437, 51.1311611245324]],
    [ "Bolsover Substation",[-1.325600185873444, 53.23527259440398]],
    [ "123837535",[-3.121886381871414, 51.00136683170222]],
    [ "Jordanthorpe Substation",[-1.458393669252525, 53.329644587601166]],
    [ "Portishead Substation",[-2.7449500599237022, 51.48893700427345]],
    [ "Brent Knoll Switching Site",[-2.9217865527376605, 51.26085588164842]],
    [ "Watchfield Substation",[-2.9323173558625286, 51.219023967799394]],
    [ "Blackditch Substation",[-2.9669041838716352, 51.18142480484435]],
    [ "Bath Road Substation",[-2.981840659506686, 51.14158468416937]],
    [ "Electricity North West",[-2.6895572143281954, 53.6168811100879]],
    [ "SP Energy Networks",[-2.6039309472798373, 53.44629011021034]],
    [ "Bedlington Switching Station",[-1.5908601430220888, 55.1327944821843]],
    [ "125237754",[-2.319198272672294, 50.9975546563285]],
    [ "Peasedown Substation",[-2.430702056688344, 51.31708286417454]],
    [ "125925586",[-1.6312598234323803, 50.77472045465597]],
    [ "SSE",[-1.9132543590073463, 50.73101592957583]],
    [ "SSE",[-1.912555881699135, 50.73153966287964]],
    [ "126271826",[-1.5506682145890733, 50.77056144369119]],
    [ "Lypstone Farm Substation",[-2.916810610771044, 51.35089615265947]],
    [ "Shrewsbury Substation",[-2.6930951028697665, 52.70930034036254]],
    [ "Kings Yard Substation",[-0.019220736424531, 51.5434160311104]],
    [ "127417081",[-1.674253620598009, 50.75347856346136]],
    [ "Banbury Road Substation",[-1.5649156352870863, 52.257544773354226]],
    [ "Blagdon Primary Substation",[-2.7155006985337558, 51.336964210796886]],
    [ "Hewlett Packard Substation",[-2.5564824396302175, 51.50128045380139]],
    [ "128108444",[-2.3048904534929413, 51.23106188894842]],
    [ "Bishop's Stortford Substation",[0.14174895012542038, 51.87830403416398]],
    [ "Castle Bromwich Substation",[-1.7817249797072754, 52.526191406209584]],
    [ "North Street Langport Substation",[-2.82758877504076, 51.039198358348024]],
    [ "High Littleton Substation",[-2.500289152968052, 51.330108269584485]],
    [ "Radstock Substation",[-2.5085789748072, 51.290798263049]],
    [ "129880032",[-1.8233473355271979, 51.06094428307915]],
    [ "Market Place",[-0.17428305, 53.91031625]],
    [ "Ryeford",[-2.2730489355746686, 51.73876312281364]],
    [ "130705252",[0.2944002408964202, 52.11938480914251]],
    [ "130886290",[-1.8111289410143767, 51.55426502945844]],
    [ "130886291",[-1.8216398889700378, 51.582303717684596]],
    [ "Chilwell Substation",[-1.2295029306364047, 52.91554713594373]],
    [ "Millfield Substation",[-2.7209806438519353, 51.12020879326972]],
    [ "Penn Cross Substation",[-2.9271421663728217, 50.745170654840244]],
    [ "Skegness Substation",[0.26080123289052537, 53.140577859430806]],
    [ "Aldreth Primary Substation",[0.12446127456354436, 52.33929458885419]],
    [ "132828862",[0.07488767305633386, 52.54826959618926]],
    [ "Edgarley Substation",[-2.674716305535458, 51.13916420345653]],
    [ "132920694",[0.3417440585912247, 52.325971775510865]],
    [ "132933408",[0.5347071375824587, 52.373579939925385]],
    [ "Kintbury 33/11kV Substation",[-1.4598067654764046, 51.40235666327074]],
    [ "Scot Hay Substation",[-2.291583284820563, 53.02130723877422]],
    [ "Sawston Primary",[0.15386381345922767, 52.12291125012713]],
    [ "134772452",[-0.3880122031660965, 52.3032066284031]],
    [ "134776185",[-0.22641308812563568, 52.280949387420755]],
    [ "135080551",[-0.3061296491399626, 52.28268574288984]],
    [ "Doxey Road",[-2.1356842499999997, 52.8089372]],
    [ "Bourn Primary Substation",[-0.07361733907196058, 52.20112128615509]],
    [ "Little Barford 33kV",[-0.2636637522981933, 52.20799482178831]],
    [ "Northern Powergrid",[-1.0630470680366062, 54.53054589551127]],
    [ "Whitegate Substation",[-2.16723274500072, 53.528744422915715]],
    [ "136105683",[-2.665134682804715, 50.91390772102749]],
    [ "136633139",[0.076817593188504, 52.61830591305307]],
    [ "UK Power Networks",[1.3915744321087993, 52.80497030148588]],
    [ "136822109",[-2.8071816941034777, 51.22942143158538]],
    [ "136895420",[-0.027483549037502334, 51.92141638567387]],
    [ "Chatteris Primary Substation",[0.03960166661570518, 52.45578635589952]],
    [ "Beche Road",[0.13854257841092077, 52.20943259699335]],
    [ "Hilton Primary Substation",[-0.11716537922305632, 52.264133754180364]],
    [ "138018809",[1.416667566736274, 51.37177484959117]],
    [ "Spalding Substation",[-0.1420316150775132, 52.77294484707023]],
    [ "Banbury Substation",[-1.3354225689947525, 52.070122254531]],
    [ "Bowhays Cross Substation",[-3.3398865995663902, 51.158586573497544]],
    [ "Truro Substation",[-5.085563285016802, 50.284270298501035]],
    [ "Hackenthorpe",[-1.3844273690235465, 53.35043744645363]],
    [ "St Ives Substation",[-5.4912789020007144, 50.19185379906079]],
    [ "Basildon Substation",[0.39139244687034286, 51.5656029621913]],
    [ "Goddards Green Grid",[-0.15762502227072245, 50.965291459423526]],
    [ "Littleport Primary Substation",[0.308107611734753, 52.46120102526885]],
    [ "Nunthorpe 400KV Cable Compound",[-1.160724327579262, 54.52456441948779]],
    [ "Stanah Substation",[-2.989878239756803, 53.87961055245663]],
    [ "Rugeley Substation",[-1.9149745787991976, 52.75668149628815]],
    [ "Lichfield Substation",[-1.8174307293864642, 52.69802713039872]],
    [ "Ingatestone Substation",[0.39488845000000006, 51.669445849999995]],
    [ "Cambridge Arms Switching Station",[-2.3811496291406837, 51.74155952537189]],
    [ "Bradford West Substation",[-1.8659388858461496, 53.81039344104795]],
    [ "142903555",[-0.4730845315814597, 52.4952740995167]],
    [ "Steel Cross Primary",[0.18471455257837746, 51.06666299894538]],
    [ "Langley Substation",[-1.360051337663024, 50.810144161729156]],
    [ "Southern Electric Power Distribution",[-1.3543454300567885, 50.78581259908748]],
    [ "143087874",[-2.891369730378443, 53.749922930196774]],
    [ "Fourstones Substation",[-2.1639425298658974, 55.01179065963984]],
    [ "Penrith Newtongate Substation",[-2.777539236200135, 54.66632882107723]],
    [ "Camborne Substation",[-5.279807124370437, 50.22888142031176]],
    [ "Tadcaster",[-1.2649640399229718, 53.87760199809753]],
    [ "Medway Substation",[0.7156069837565275, 51.44486639069191]],
    [ "Denwick 66/20",[-1.6703922784977552, 55.440440816378604]],
    [ "Kings Lynn",[0.3786881638449347, 52.72649601517497]],
    [ "High Marnham Substation",[-0.7935816268691859, 53.228039104571096]],
    [ "143955173",[-0.21348483243521904, 52.41340401511938]],
    [ "Bispham Substation",[-3.029636229349299, 53.849366804823774]],
    [ "144189387",[-0.5319305192539393, 52.07756355979785]],
    [ "144325554",[-1.7554714334762291, 51.59742274679607]],
    [ "144405288",[-0.40884579841808305, 52.3683982366864]],
    [ "144840847",[-2.1210568156109346, 50.695623992036865]],
    [ "145115364",[0.37210100368199023, 52.53498492570498]],
    [ "Evesham Substation",[-1.9332966471284005, 52.109671412458475]],
    [ "Long Eaton Substation",[-1.2748536993864914, 52.898186088323364]],
    [ "Pinhoe Substation",[-3.4781133109787175, 50.738247657792506]],
    [ "145699588",[-0.00967897578746472, 51.77322545529718]],
    [ "Redditch North Substation",[-1.950861448697523, 52.31306282667433]],
    [ "Rame Substation",[-5.184440739873473, 50.15710900600769]],
    [ "Gateshead East",[-1.5844139499999996, 54.96572785000001]],
    [ "Stella South Substation",[-1.7325174081796062, 54.97434488945528]],
    [ "146962822",[-1.7932795499999992, 51.53206545]],
    [ "Harden",[-1.8675577661408997, 53.833867106893145]],
    [ "Staplegrove Substation",[-3.12061822977254, 51.0251884064668]],
    [ "Templeborough Substation",[-1.3863353999068757, 53.412177863951214]],
    [ "Barwick Substation",[-1.4344569286133453, 53.8174809456241]],
    [ "Malleable",[-1.2890836865616118, 54.57170595182832]],
    [ "148188395",[-2.5304895804709036, 50.713222427688976]],
    [ "Monkton Substation",[1.2833642178270221, 51.3573934585397]],
    [ "Loxley Road",[-1.5650987885099525, 53.41055293566341]],
    [ "Tiverton Moorhayes Substation",[-3.48002871052436, 50.91691718143701]],
    [ "Melbourn Grid Substation",[-0.00395694512642552, 52.07497861783955]],
    [ "Arley Substation",[-1.5756111448964096, 52.501878415976726]],
    [ "Wellington Substation",[-3.247755175385916, 50.9913416777863]],
    [ "Canvey Substation",[0.5756689500000001, 51.52360105000001]],
    [ "Burlescombe Substation",[-3.327284166342715, 50.94676835069611]],
    [ "Ledbury Substation",[-2.427233080742275, 52.04605095759954]],
    [ "Almondsbury Substation",[-2.5693380283132727, 51.550033788095455]],
    [ "Westbury Substation",[-2.1784853681132064, 51.262532485687125]],
    [ "Roman Avenue",[-1.5087132033370518, 53.847044658594726]],
    [ "Northern Powergrid",[-1.5077211999999989, 53.84631834999999]],
    [ "Longstanton Primary Substation",[0.03185042437326025, 52.26578622631775]],
    [ "Bow Substation",[-0.012299994674809968, 51.5327372414604]],
    [ "Station Lane",[-1.5375423277416382, 53.73274009120278]],
    [ "Wollaton Road",[-1.1838684828766763, 52.95521776088871]],
    [ "Commonside Lane Substation",[-1.3769648975347966, 53.67848100835417]],
    [ "Cheapside Substation",[-1.8877837833976638, 52.472730195082306]],
    [ "Chudleigh Knighton Substation",[-3.625644204052314, 50.58775804875096]],
    [ "Keighley",[-1.9059217214156208, 53.86479595669873]],
    [ "Airedale Road",[-1.8886515955107506, 53.87033479023206]],
    [ "Metheringham Substation",[-0.4091847299088021, 53.135059192320135]],
    [ "Torquay Substation",[-3.545077348223501, 50.48761297286795]],
    [ "Blast Furnaces",[-0.6213703405721037, 53.579316477749224]],
    [ "CMB 6.6kV",[-0.6226252486829821, 53.58170331751241]],
    [ "152001359",[-0.6155907325872142, 53.57999877893997]],
    [ "UK Power Networks",[-0.1490247382156464, 51.365170927449014]],
    [ "152271724",[0.10456943453161756, 53.16851394872189]],
    [ "Warth Lane Substation",[0.3070007231947548, 53.14287337590498]],
    [ "Atherstone Substation",[-1.5492817227979732, 52.58484407480765]],
    [ "Hams Hall South Substation",[-1.7181327066696934, 52.52464730483076]],
    [ "152504281",[-0.1518830313910128, 51.3595721062458]],
    [ "Thatcham Grid Substation",[-1.2420312944550391, 51.39452394082759]],
    [ "Hull South Substation",[-0.3798478085947459, 53.732781018839205]],
    [ "Saltend South Substation",[-0.24275499635579806, 53.73686692683162]],
    [ "Saltend North Substation",[-0.2384793061540373, 53.74198704161621]],
    [ "Bransholme Substation",[-0.30475003911218745, 53.78952986878506]],
    [ "Lincoln Local Substation",[-0.5218694506568827, 53.22849510030806]],
    [ "Lincoln Main Substation",[-0.5183388261507963, 53.228662794474545]],
    [ "Dudley Substation",[-2.09957392699598, 52.506839832475606]],
    [ "Stickney Substation",[0.0196886392757987, 53.08850973791682]],
    [ "Lye Substation",[-2.122584101965259, 52.45970361152793]],
    [ "Sleaford Substation",[-0.40581357581351646, 52.991473965396345]],
    [ "Immingham Substation",[-0.19221368535407107, 53.61521440713785]],
    [ "153861217",[1.5362742615720169, 52.10360530996967]],
    [ "Birkenhead Substation",[-3.0617878566642847, 53.36583655707993]],
    [ "Oxbridge",[-2.459322496571113, 51.53187431821481]],
    [ "Redland Road Master Substation",[-2.5966937, 51.46846655]],
    [ "154879807",[-0.23461867712559223, 52.53659762556232]],
    [ "Newport Substation",[-0.69679096454654, 53.765837517887064]],
    [ "Spring Gardens Substation",[-2.739236745252413, 52.720126145142785]],
    [ "Lytchett Substation",[-2.032583445701646, 50.74751872832951]],
    [ "Hamworthy Substation",[-1.9986947313151824, 50.716757954944065]],
    [ "155618319",[-2.229154419492683, 50.69015106381613]],
    [ "Organford Substation",[-2.0757915966633496, 50.73186805369056]],
    [ "155738019",[-2.5713048012718787, 50.91329455606462]],
    [ "155738495",[-2.0188828210066365, 50.71886896868283]],
    [ "156127415",[-2.208792475566612, 51.01335564266064]],
    [ "Cheltenham substation",[-2.120523053267874, 51.908542692596775]],
    [ "Marle Hill Substation",[-2.0825075339809977, 51.91049473670189]],
    [ "Ring Rd Horsforth",[-1.6154038996980686, 53.83628806060884]],
    [ "Spadeadam Substation",[-2.606782458122454, 55.02620338064941]],
    [ "CEGB Kitwell Lane 11kV",[-2.013450601922854, 52.431147739004565]],
    [ "Camp Substation",[-2.1304070191488083, 51.78639293969111]],
    [ "Mill Lane Substation",[-1.792716506750128, 50.849840329998294]],
    [ "Leyland Substation",[-2.6966370803284625, 53.70478588715224]],
    [ "Carr Lane Substation",[-2.693151573959697, 53.70392363304186]],
    [ "Star Aluminium",[-2.4040653598756525, 52.52518162928623]],
    [ "Morehall Grid",[1.150537810998265, 51.08550386782003]],
    [ "Marchington Substation",[-1.8029333089839514, 52.8787757604998]],
    [ "National Grid",[-1.7669547656315145, 53.516708523909514]],
    [ "Brinsworth Substation",[-1.351293162347844, 53.40137384864177]],
    [ "Rednal Substation",[-1.9815453463866306, 52.39695444267468]],
    [ "Kirbymoorside Substation",[-0.9347995230453583, 54.252350131825914]],
    [ "Grimsby West Substation",[-0.15143181601616337, 53.565740090133254]],
    [ "Drax",[-0.9924425313204908, 53.73621729755735]],
    [ "Doncaster B 132/66 kV",[-1.1469040096481118, 53.52599216828074]],
    [ "160580130",[1.6108775906488788, 52.20854575031716]],
    [ "Aldwarke Substation",[-1.32061173047533, 53.4454931310063]],
    [ "Bottesford",[-0.6376105438427512, 53.56033063796955]],
    [ "Wolverhampton West Substation",[-2.1859466417388393, 52.57317524492691]],
    [ "Polesworth Substation",[-1.6128057605761439, 52.61599076153163]],
    [ "Smethwick Substation",[-1.9694415321897423, 52.50744834104285]],
    [ "Longfield Primary",[0.30329144123190943, 51.3986782751097]],
    [ "Burnham Substation",[0.8252414482424668, 51.627406585423316]],
    [ "National Grid Electricity Distribution Plc",[-2.396834840147271, 51.38120849097446]],
    [ "Substation",[-2.7335329285258942, 53.6903515895377]],
    [ "Bitterne Substation",[-1.3599808180365445, 50.91543991691636]],
    [ "162969125",[-0.46105968582676443, 51.45909296665493]],
    [ "Rochdale Substation",[-2.2160837098253587, 53.621941010133476]],
    [ "Dymock",[-2.449493042630948, 51.97028171903765]],
    [ "Chelsfield Grid",[0.14042207949017463, 51.34520042594197]],
    [ "Dunkeswell Substation",[-3.2164859561753807, 50.856079628057955]],
    [ "Wem Substation",[-2.7386019964238084, 52.85739571755856]],
    [ "Elland Substation",[-1.8230108085186676, 53.69446090441378]],
    [ "Silsden",[-1.9236111710226107, 53.908982948782594]],
    [ "Holmfield",[-1.8749772777668512, 53.750672033938436]],
    [ "164492375",[-0.33766858380634407, 51.754660080770435]],
    [ "Bolton Rd",[-1.9332426946044505, 53.91681918354156]],
    [ "Gravesend South",[0.3587978670340174, 51.418368712832525]],
    [ "165333715",[-0.18709658009917102, 51.35975020239337]],
    [ "Brodsworth",[-1.20996114301266, 53.564901193438835]],
    [ "West Hanningfield Substation",[0.5199915899081882, 51.66419461369009]],
    [ "Chemistry Laboratory Substation",[-1.048642896220984, 53.94847431559782]],
    [ "UK Power Networks",[1.1775765267365998, 52.9156507226784]],
    [ "Birchfield Lane Substation",[-2.0226465738629753, 52.49309834861546]],
    [ "Langley substation",[-0.847434700000001, 51.05501060000001]],
    [ "Gillingham Street Sub-Station",[-0.14336610446110926, 51.493447718322685]],
    [ "Martins Close 2",[-2.006608185771294, 50.74241155297598]],
    [ "Woodpecker Drive",[-2.0110922749601934, 50.74242019486106]],
    [ "SSE",[-1.9779886500000001, 50.73700624999999]],
    [ "Oldbury Substation",[-2.022984064974972, 52.498636762291575]],
    [ "Stour Drive",[-2.1133488247024617, 50.69815231042715]],
    [ "Greatham Substation",[-1.2085138329789955, 54.627941577025126]],
    [ "Cobourg Street Electricity Substation",[-0.1361133611814488, 51.527224333774505]],
    [ "168430984",[-0.6928456135445742, 51.61258995491971]],
    [ "Frinton Substation",[1.2505602202751591, 51.83774174121715]],
    [ "Union Road Substation",[-2.42077456600774, 53.59127730033859]],
    [ "Cromwell Mount",[-1.5270895999999998, 53.802148800000005]],
    [ "Burmantofts",[-1.528720490518877, 53.80268558689782]],
    [ "Moreton Village",[-2.2830425872381213, 50.702941126809]],
    [ "St Andrews Road",[-1.7727327208922659, 53.65108336210028]],
    [ "169770502",[-1.5309498188783337, 53.59678130562407]],
    [ "Honley Substation",[-1.8016651196122544, 53.603763456288235]],
    [ "Scissett Substation",[-1.6220802857034275, 53.589189363549934]],
    [ "Kenswick Substation",[-2.3109257982265405, 52.227850979214864]],
    [ "Broseley Substation",[-2.484436210291584, 52.61047200261482]],
    [ "Beeston Royds",[-1.5840187503745438, 53.77607341640034]],
    [ "Beeston Royds",[-1.5837628403738782, 53.77585842323596]],
    [ "170653636",[-0.05222888073255044, 52.077078938425736]],
    [ "Shepton Mallet Substation",[-2.545523194772217, 51.19233499992556]],
    [ "Strensham Substation",[-2.1402973699431933, 52.06779915679927]],
    [ "BLOOM ST GRID",[-2.239613461597911, 53.475952143339185]],
    [ "172415295",[-2.5121846235502288, 53.51440622503222]],
    [ "Harlton Village",[0.02550214723210903, 52.15244634063608]],
    [ "172524916",[-2.1771652714478913, 53.59763173440026]],
    [ "Alderton Substation",[-1.998281664564623, 51.99131905422021]],
    [ "173079436",[-0.8864146284162528, 51.642795309248825]],
    [ "Endon Substation",[-2.1041544000000005, 53.07030075]],
    [ "Out Newton WF",[0.09597278840494569, 53.668488291453045]],
    [ "Thornton Dale",[-0.7420109384996761, 54.23104788575857]],
    [ "174764278",[-0.5957861112842753, 51.71550608600807]],
    [ "175473608",[-2.1149121201297785, 53.56489350901667]],
    [ "175951009",[-1.9735375241642021, 50.61338695388933]],
    [ "177065871",[-3.190481709103838, 54.10641030540522]],
    [ "177180645",[-3.1125155176753316, 54.19073357555086]],
    [ "177183492",[-2.7377347705081103, 54.29144955825641]],
    [ "Andover Grid Substation",[-1.4954108007412132, 51.217481596698356]],
    [ "Nailsea Primary Substation",[-2.755432984901273, 51.42631619787076]],
    [ "177849045",[-2.3513317735757395, 53.471001927662854]],
    [ "Paignton Substation",[-3.5899880813067986, 50.42128819774586]],
    [ "Grillington",[-1.7904319488994347, 53.7955143982481]],
    [ "Park North Substation",[-1.7405800829574938, 51.55925474392451]],
    [ "Bagshot Substation",[-0.6924437901007802, 51.36334559456637]],
    [ "Reading Main Substation",[-0.9270375527335694, 51.458902150944226]],
    [ "178797328",[-1.3188270667572926, 51.57438155354694]],
    [ "179646586",[-2.8892776646000753, 54.02347754004017]],
    [ "Totnes BSP Substation",[-3.727531084864419, 50.44393191762139]],
    [ "Leeds North Substation",[-1.5420289136639718, 53.813844115274804]],
    [ "Woodford Halse Primary Substation",[-1.2079699927715741, 52.173480273894405]],
    [ "Thenford Substation",[-1.2410837537741597, 52.07741926557462]],
    [ "180741589",[-1.7473208824900859, 51.61712999429719]],
    [ "Leeds Road",[-1.4240477973754266, 53.90263990420124]],
    [ "Derby Substation",[-1.476355538711114, 52.92578080198109]],
    [ "Ernesettle Substation",[-4.188182519134911, 50.42279832538919]],
    [ "London Way Substation",[-2.6762095500000007, 53.74844585]],
    [ "Longton Road Substation",[-2.179288403209663, 52.9660196201945]],
    [ "Weir Hill Substation",[-2.7191350768197866, 52.704256215677205]],
    [ "Bleachfield Substation",[-1.0586350225203716, 53.94863852551128]],
    [ "Westferry Circus Substation",[-0.026342105710026938, 51.50699985017555]],
    [ "Hindhead",[-0.7343294359111495, 51.12058437967632]],
    [ "Pattingham",[-2.259016741509402, 52.58985547940424]],
    [ "Victoria Street",[-1.592103439452544, 52.28493295385626]],
    [ "Marldon Substation",[-3.595722600000001, 50.45290375]],
    [ "183865652",[-1.5749261406360373, 52.79310277232165]],
    [ "West Boldon Substation",[-1.4709330610097702, 54.94008872365483]],
    [ "St Tudy Substation",[-4.722483265042651, 50.53536927556112]],
    [ "Treningle Switching Station",[-4.756582209942452, 50.45649168106102]],
    [ "Patrington Road",[-0.0692666, 53.69894489999999]],
    [ "184231377",[-0.013696409072238423, 51.5446049985941]],
    [ "184584639",[-1.4496097682133238, 54.9733502285768]],
    [ "185028049",[-2.0948379977961906, 51.061545378899865]],
    [ "185028070",[-2.1602792064315524, 50.84910022282699]],
    [ "Winterborne Kingston 33/11kV",[-2.2193197134929346, 50.78237570457909]],
    [ "185028088",[-2.209402057541284, 50.922131177104816]],
    [ "Bushey 33-11 KV S/S",[-2.034173578972693, 50.65152077503405]],
    [ "Sheriff Hutton 1064",[-1.0357835009897598, 54.09448561366118]],
    [ "Billericay East Substation",[0.4468792992828929, 51.621942512862574]],
    [ "Cowhorn Substation",[-2.4773706590307505, 51.44608110428093]],
    [ "185900192",[0.28618920000000003, 51.483557649999995]],
    [ "186009248",[0.23671096066525846, 52.541942284275336]],
    [ "186176839",[-0.6416661992669974, 54.18166565430409]],
    [ "St Anthony Street Primary Substation",[0.12453441623003432, 52.196052688786516]],
    [ "Knaresborough",[-1.488117951028905, 54.03125235962372]],
    [ "Wormald Green Substation",[-1.5217824373736888, 54.07488408234859]],
    [ "Belthorpe Lane",[-0.7918524803601289, 53.98145105304732]],
    [ "Driffield Substation",[-0.41675381211889084, 53.99939690527185]],
    [ "Kirkburn",[-0.5147949591101657, 53.97816607598299]],
    [ "Moss Nook",[-2.2410899522264667, 53.36180123177183]],
    [ "187197984",[-1.8009286499999997, 50.93891195]],
    [ "South Kirkby",[-1.3324337157975001, 53.60110062829956]],
    [ "TFTV substation",[-1.0329083499999998, 53.947223900000004]],
    [ "Malton Substation",[-0.7812554595831175, 54.15070379894413]],
    [ "188121499",[-1.1305389871282614, 51.166494404017314]],
    [ "Selby",[-1.064455138417777, 53.7689339226431]],
    [ "Olympia Mills",[-1.0516797485657612, 53.78570272027556]],
    [ "King Henry's Walk Substation",[-0.0827949487909921, 51.54710909757193]],
    [ "St Germans Substation",[-4.373157318655894, 50.41794982825632]],
    [ "Berkswell TSC",[-1.637411819347058, 52.39473117806011]],
    [ "South Elmsall",[-1.281965813846704, 53.58445048722919]],
    [ "Southmead Master Substation",[-2.5997919364712834, 51.49598760550182]],
    [ "Bicker Fen Substation",[-0.2208169142516352, 52.93136752983186]],
    [ "Sleaford Road Boston Substation",[-0.0328731717628361, 52.97763433391086]],
    [ "Marsh Lane Substation",[-0.013459528635174283, 52.961610674825096]],
    [ "Lawes Bridge Substation",[-3.5519499697670947, 50.48426934254215]],
    [ "Easington",[0.11565606560513457, 53.66039091970558]],
    [ "Newtown Substation",[0.1513540518394128, 51.86701619732966]],
    [ "193235419",[-1.1194630669831074, 51.248018979679955]],
    [ "Leeming Bar",[-1.5593692116746238, 54.32778158213716]],
    [ "193437135",[-0.8570589802688874, 51.40251508292817]],
    [ "193460868",[-1.2851108999999998, 53.715590500000005]],
    [ "193460871",[-1.2905632002078005, 53.71573438336628]],
    [ "193461512",[-1.28201525, 53.723631950000005]],
    [ "Coalville Substation",[-1.3270945971041859, 52.68827254827095]],
    [ "Ferrybridge C Substation",[-1.2828399433842004, 53.71788534448197]],
    [ "193865796",[-0.9623102845502435, 51.43414522563935]],
    [ "Rose Valley Substation",[0.301504546490582, 51.61401631863053]],
    [ "Madley Substation",[-2.8485669999999987, 52.03622325]],
    [ "195217951",[-1.0486963638762088, 51.41423623687047]],
    [ "Kenninghall Primary Substation",[0.992964189443335, 52.43101824192505]],
    [ "Pontefract Lane",[-1.5101830972727586, 53.7874512222926]],
    [ "195952314",[-1.8276615108288492, 52.51290847537123]],
    [ "Lytham Substation",[-2.9819415499999993, 53.7564229]],
    [ "Electricity North West",[-2.979882606670253, 53.781049624499175]],
    [ "198179053",[0.8542189885230451, 52.037259940797696]],
    [ "Romford Substation",[0.18194700495839503, 51.57493952067069]],
    [ "Crowlands Substation",[0.15924621782392706, 51.56903487363959]],
    [ "Harold Wood Primary",[0.2381016951336257, 51.59982520940247]],
    [ "Park Hill",[-1.4589797377782106, 53.381908227354195]],
    [ "200073737",[1.4471979883725608, 52.27450813060028]],
    [ "200082717",[-2.40484195, 50.883891399999996]],
    [ "Wold Newton",[-0.1696498032637085, 53.478737745022684]],
    [ "200470496",[-0.5357482645113828, 53.17501397597272]],
    [ "Hawthorn Pit Substation",[-1.4046794258149329, 54.805018907148416]],
    [ "Worthing Substation",[-0.3537949000680154, 50.822221455996804]],
    [ "Offerton 275kv Substation",[-1.457754335466204, 54.88090288197847]],
    [ "School House",[0.09447040000000004, 52.18030760000001]],
    [ "201039923",[-0.9925296178698163, 53.732485935242366]],
    [ "201078322",[-1.523267810810685, 53.780085236610866]],
    [ "Staygate",[-1.7492378818553982, 53.766669781041664]],
    [ "Dunkeswick",[-1.533805031134899, 53.91856372188728]],
    [ "Four Lane Ends",[-1.7955606887360729, 53.80017524009919]],
    [ "Sculcoates B",[-0.3437853466324897, 53.76591685079604]],
    [ "Brookfield Street",[-1.5325347048039955, 53.78705638361913]],
    [ "Darne Road",[-1.3302421195525902, 53.51118546470911]],
    [ "Ash Green Substation",[-2.196140391245274, 52.96528753445127]],
    [ "Mytholmroyd",[-1.9976345293143412, 53.73483475793198]],
    [ "Todmorden Substation",[-2.1032524774629326, 53.70879049706874]],
    [ "202165416",[-1.552220940341504, 54.9376664724917]],
    [ "Kenilworth",[-1.584848890295972, 52.33704784032436]],
    [ "Glenarm Crescent Lincoln",[-0.5516592142663564, 53.19386771758498]],
    [ "Pinewood Road",[-2.0353796420477916, 50.73835899221487]],
    [ "School Lane",[-2.2418443860442667, 50.62554258352844]],
    [ "Bindon Road",[-2.250206161473762, 50.6237148751599]],
    [ "202831533",[-2.166055798924498, 53.616565429512534]],
    [ "SSE Power Distribution",[-2.253406599999999, 50.62063845]],
    [ "203033343",[-2.5661349, 50.88977825]],
    [ "203033346",[-2.48176997147219, 50.807867696719526]],
    [ "203033356",[-2.46461510029311, 50.737185718033615]],
    [ "Bridport Substation",[-2.7614206192188613, 50.73690680527704]],
    [ "97 Northport Drive",[-2.1068252661875313, 50.697200988533496]],
    [ "Hartford Substation",[-2.549745248809721, 53.238100388685574]],
    [ "203603800",[-1.9030579287352185, 53.61430011144861]],
    [ "No. 90 Grantham Road Bracebridge Heath",[-0.5362497617488432, 53.187938370835845]],
    [ "Red Hall Drive Bracebridge Heath",[-0.5374883373245508, 53.19654056833368]],
    [ "Willenhall Substation",[-2.060057587657599, 52.57648360931034]],
    [ "Willenhall Substation",[-2.0492731756859577, 52.57785467747941]],
    [ "Hunshelf Substation",[-1.6046206328813692, 53.49554720653414]],
    [ "204052770",[0.8157202540341751, 52.575940334618124]],
    [ "Kempstone Substation",[0.7774069494917113, 52.70906130916565]],
    [ "Emersons Green Substation",[-2.4733569719956927, 51.49797563127014]],
    [ "Chester Road Network",[-2.286868619984169, 53.461979991411056]],
    [ "Humberston Substation",[-0.04883970236327921, 53.535682087912726]],
    [ "Yarborough Road Substation",[-0.10898871348230976, 53.565810727299784]],
    [ "Killingholme Substation",[-0.25651327304545257, 53.65581852001621]],
    [ "Blyton Substation",[-0.7137609569165672, 53.43574553562064]],
    [ "Haxey",[-0.8131248757640291, 53.485471379382915]],
    [ "Thorne",[-0.9339327332475881, 53.63328011663897]],
    [ "Stony Stratford Substation",[-0.8673550375657448, 52.018508304256876]],
    [ "205697345",[-1.43668720889597, 51.26984403069466]],
    [ "Wicken Substation",[-0.9047621585184795, 52.04780788066806]],
    [ "Scartho 18",[-0.09990968757013373, 53.534744224931465]],
    [ "Grainthorpe Substation",[0.09520027636070773, 53.44262704568335]],
    [ "Caistor",[-0.32110317456376086, 53.499658992679244]],
    [ "205827485",[-1.5346769028861875, 55.205654450231926]],
    [ "Conyard Road",[-0.03668227210648884, 53.56278582528958]],
    [ "Moss Road Substation",[-0.08086227146316582, 53.56313413401945]],
    [ "Doughty Road Substation",[-0.08253091220127594, 53.56252638026133]],
    [ "Convamore Road Substation",[-0.06939007072946064, 53.557733724102434]],
    [ "Marsden Road Substation",[-0.05658821333483666, 53.574424447812575]],
    [ "Harpswell",[-0.608504855725018, 53.401075619641674]],
    [ "Normanby",[-0.4577096528525523, 53.38117996745252]],
    [ "Woodbeck Substation",[-0.8340467187911901, 53.29747625274935]],
    [ "Newton Abbott Substation",[-3.59594443930247, 50.53082980725391]],
    [ "Stow 84",[-0.6945839098485951, 53.322556732793274]],
    [ "Corringham Rd 33kV",[-0.757705819719366, 53.40531042516844]],
    [ "Manton Substation",[-1.0958322206439484, 53.294464639847625]],
    [ "Ordsal Road Substation",[-0.9497146191278307, 53.31278728135698]],
    [ "Retford Substation",[-0.9614144775133622, 53.32501035914315]],
    [ "Wragby Substation",[-0.3075560558846587, 53.28658813069997]],
    [ "206267387",[-0.3064664, 53.2862042]],
    [ "206324970",[-0.9691846203154199, 53.44810678786402]],
    [ "Kilton Road Substation",[-1.1112401983343108, 53.30838434817813]],
    [ "Whitwell Substation",[-1.2271616026472927, 53.276817000071844]],
    [ "Montpellier Substation",[-2.084437811381473, 51.892819347933475]],
    [ "206454735",[-0.9599347333602699, 53.26174100118079]],
    [ "Hornchurch Substation",[0.22497139513584524, 51.559614870978585]],
    [ "Delta",[0.12211905944268242, 52.235431527255]],
    [ "206979522",[-0.4153789865465441, 53.23812179086629]],
    [ "North Greetwell Switching Station",[-0.4899406782291757, 53.25452306356593]],
    [ "Anderson Lane Lincoln Substation",[-0.5392883089586593, 53.24253392073115]],
    [ "South Carlton Substation",[-0.5759712667047866, 53.27259602490143]],
    [ "207455612",[-0.8439218220137077, 53.434117716714795]],
    [ "Hibaldstow",[-0.5838337746919104, 53.48765495775091]],
    [ "208277681",[-0.13021661985313568, 53.57590347971019]],
    [ "Queens Road",[-0.17675301336771068, 53.62183708278541]],
    [ "208286622",[-1.8918030605549625, 51.52892008216957]],
    [ "208441304",[-1.8055402385846202, 52.39458580473689]],
    [ "South Ferriby",[-0.5316185646166927, 53.67138246286173]],
    [ "Pasture Rd South",[-0.42271485000000003, 53.688225349999996]],
    [ "Billet Lane",[-0.6651379759503858, 53.61631017680929]],
    [ "208673817",[-4.132860206483256, 50.95665500465415]],
    [ "Jaguar Cars Browns Lane Substation",[-1.55584185, 52.4378189]],
    [ "Barrow",[-0.3616439988441691, 53.67949586875834]],
    [ "Eastfield Road",[-0.25848838101785854, 53.64107012710778]],
    [ "Whitley Substation",[-1.4826794182215706, 52.39355825388813]],
    [ "Ryton Substation",[-1.448406073764302, 52.36739638479838]],
    [ "208850838",[-1.484182365749073, 52.43017662870346]],
    [ "Scunthorpe South",[-0.6226255597755088, 53.57532155837634]],
    [ "Ashby Main Road",[-0.11035044999999999, 53.48805645]],
    [ "North Thoresby",[-0.07285150000000001, 53.454284900000005]],
    [ "209218266",[-1.4308512763112748, 52.22700206199957]],
    [ "Harbury Grid Substation",[-1.4339087267288144, 52.22780014484962]],
    [ "Substation No 14",[-0.034225247496575434, 53.552476576121315]],
    [ "Newby 400KV Cable Compound",[-1.22952515, 54.50409955]],
    [ "Chelmsford South Substation",[0.4887024749581175, 51.710144839535886]],
    [ "Ocker Hill Substation B",[-2.0373756610833986, 52.54141734291088]],
    [ "Bracknell Substation",[-0.7643454842381531, 51.417259304068054]],
    [ "Kirkhaw Lane",[-1.2770614566741643, 53.71980362733212]],
    [ "Beverley",[-0.415628069017418, 53.821265062658476]],
    [ "Hawkesmill Lane Substation",[-1.5606260354968993, 52.44265143080047]],
    [ "Seaton",[-0.22095727972473733, 53.90515420628258]],
    [ "Edlington",[-1.1829606850739602, 53.484875242839024]],
    [ "Balby",[-1.1335532202781224, 53.504930970202196]],
    [ "West End Lane",[-1.088508021347662, 53.48001119152191]],
    [ "Crowle 45047",[-0.8257853178570583, 53.60611665099868]],
    [ "Rawcliffe",[-0.9447609955329009, 53.68282128827033]],
    [ "Bentley Substation",[-1.14134445, 53.55871775]],
    [ "Doncaster Central Substation",[-1.138315488331452, 53.52610596309131]],
    [ "Blackburn Meadows B",[-1.4025134210873598, 53.418848886394166]],
    [ "West Melton Substation",[-1.3769901683689776, 53.501530538932165]],
    [ "Park Street Substation",[-1.3737386905358613, 53.432675534891736]],
    [ "Rawmarsh Road Substation",[-1.3561342586282974, 53.43644977682082]],
    [ "Blackburn Meadows Substation",[-1.4065042490269106, 53.418774466426335]],
    [ "Kilnhurst",[-1.3167585140022888, 53.467907133218695]],
    [ "211685754",[-0.961224219804952, 53.325264692708664]],
    [ "Armthorpe",[-1.043994697930054, 53.53967241591773]],
    [ "Kirk Sandall",[-1.0758338725932302, 53.558550587745756]],
    [ "Markham Gates",[-1.0731245672523417, 53.536626730381336]],
    [ "Barnburgh",[-1.2891866808221952, 53.52968561808117]],
    [ "212440905",[-1.240575537708608, 53.27893928506508]],
    [ "Wath Upon Darne",[-1.3300782536850182, 53.50302916806424]],
    [ "Mexborough",[-1.2640271641975571, 53.49326679413891]],
    [ "212813683",[-1.1997740499999996, 53.27406259999999]],
    [ "212813693",[-1.360464033181679, 53.26952099373005]],
    [ "Staveley Substation",[-1.3856498808761768, 53.26704314524097]],
    [ "212864760",[-1.170069622565178, 53.42361288379061]],
    [ "Tickhill Road",[-1.1621695525159503, 53.42306325954288]],
    [ "New Orchard Lane",[-1.2540340682466062, 53.40223040872031]],
    [ "Kiveton Park",[-1.259860438184062, 53.334489249605184]],
    [ "Dinnington",[-1.2219765463754313, 53.37527839011915]],
    [ "Costhorpe",[-1.126768512592459, 53.36970171678104]],
    [ "Harworth",[-1.0637752847172555, 53.41636953122806]],
    [ "213577047",[-0.014420006144302866, 52.969064141939846]],
    [ "214293347",[1.3894506617902502, 51.22626394164282]],
    [ "215491455",[-0.41960670973206504, 53.60404854562231]],
    [ "Flixborough",[-0.6952805050037042, 53.6187403619028]],
    [ "Great Hale Substation",[-0.2946253823027815, 52.968454895113126]],
    [ "Burton Pidsea 5620",[-0.09582269979790271, 53.77402607269194]],
    [ "Hunmanby",[-0.32693208479130453, 54.18859801131308]],
    [ "216257436",[-3.1057618500000004, 52.79897225]],
    [ "Wheatley Park Substation",[-1.1126212374214532, 53.54047632746082]],
    [ "Rockware",[-1.0864042042709035, 53.55431172807249]],
    [ "216890702",[-1.000606508581465, 51.4246774101497]],
    [ "Yarrells Lane",[-2.0279953729631623, 50.73408176583351]],
    [ "Moore Substation",[-2.6655419535258846, 53.344084652921936]],
    [ "Northern Powergrid",[-1.0502362, 54.5286233]],
    [ "Alford Substation",[0.18838908165910043, 53.2544491864336]],
    [ "Trusthorpe Substation",[0.26421469545362725, 53.325666638191024]],
    [ "Stainforth",[-1.0197479240951564, 53.594699088989806]],
    [ "Askern",[-1.159641412343969, 53.618802453634]],
    [ "Saltfleetby St Peter Substation",[0.15932907987672917, 53.38408445955041]],
    [ "Fir Tree Lane",[-1.536263236345281, 53.84932850655528]],
    [ "217810140",[-0.9098816591701829, 54.562290512543704]],
    [ "Redcar Substation",[-1.0620497277303496, 54.600284858926244]],
    [ "218284998",[1.0951429427704564, 51.354159957406985]],
    [ "Grimsby Docks Substation",[-0.07589626913167213, 53.57598363570963]],
    [ "218337900",[-0.07556519022445701, 53.57612261473543]],
    [ "Grimsby Docks Substation",[-0.07568418236386282, 53.575978544711674]],
    [ "218636774",[-1.5615523595139718, 51.83531466376093]],
    [ "Camblesforth Substation",[-1.0009552244750952, 53.72948860286533]],
    [ "135 Wareham Road",[-2.0775533102696158, 50.75652791514267]],
    [ "218727797",[-0.0840550607847901, 52.12761378689907]],
    [ "Claverdon Substation 33kV",[-1.695612281110935, 52.276662836829686]],
    [ "Withernsea Substation",[0.029276472251926523, 53.726015612171715]],
    [ "Guardian Glass",[-0.9073192781676389, 53.69357804798331]],
    [ "218939397",[-0.16582446697209674, 53.57537779905401]],
    [ "218939427",[-0.16583802653479726, 53.57535531566864]],
    [ "Nottingham North Substation",[-1.1733978396029885, 52.97851186817302]],
    [ "Goole 3728",[-0.8864158345411162, 53.700938680491426]],
    [ "Walesby",[-0.32004131107129413, 53.3956044825292]],
    [ "High Street",[0.24337177216760023, 52.24218469486724]],
    [ "Bytham B.R. Substation",[-0.49391959837285393, 52.744697479612945]],
    [ "Grantham South Substation",[-0.6381216585276013, 52.90401850946136]],
    [ "Ongar Substation",[0.25545625000000094, 51.696947949999995]],
    [ "Bilborough Substation",[-1.2134177000000002, 52.961804599999994]],
    [ "219446485",[1.7149583761442608, 52.57429659742393]],
    [ "Great Coates",[-0.1286474672973182, 53.57509265775806]],
    [ "Carlton Way",[0.12071651525216834, 52.223721232098]],
    [ "New Beacon Road Substation",[-0.6291714648370121, 52.91318845525104]],
    [ "Central Networks",[-0.05750896152789894, 52.921454633638305]],
    [ "219770365",[0.43983041040338433, 52.07493030667483]],
    [ "Northern Powergrid",[-0.7963281232654147, 54.14204743454017]],
    [ "Black Lake Substation",[-2.0061168715900397, 52.530736059147564]],
    [ "Bulwell Substation",[-1.1988472216239765, 53.00217371457519]],
    [ "Thrapston Substation",[-0.5511224598444688, 52.39195966203664]],
    [ "220715449",[-2.3963821402532957, 53.07335237062504]],
    [ "220786427",[-1.865185087304724, 51.637875012979656]],
    [ "Hawthorn Pit Grid",[-1.40163360722289, 54.80625019862113]],
    [ "NEDL",[-1.4352223011322158, 54.69475096347729]],
    [ "Hawthorn Pit",[-1.3979037700614991, 54.80618637537053]],
    [ "187 West Way",[-2.009583616950781, 50.75430704916966]],
    [ "Wood End Substation",[-1.6300024256621353, 52.582102071971356]],
    [ "Hookgate Substation",[-2.3799100130691393, 52.91289446524878]],
    [ "221490974",[-1.7119841000000007, 52.534171150000006]],
    [ "Toronto Substation",[-1.702459384017556, 54.67304542837831]],
    [ "Northen Power Grid",[-1.9162469081734708, 54.553704630962045]],
    [ "Eastgate Substation",[-2.0800240717512035, 54.74191914692999]],
    [ "221531006",[-1.8724028700283504, 54.72731467162744]],
    [ "Wynyard Substation",[-1.3169193090510438, 54.639677925636136]],
    [ "Heighington",[-1.5799882705057466, 54.592592235857296]],
    [ "Skeeby",[-1.7018973096964545, 54.41309912385116]],
    [ "Coaley Substation",[-2.3613272942804966, 51.71294083028192]],
    [ "222327036",[-1.2327171946330358, 50.84815168953513]],
    [ "NEDL",[-0.4145477614540178, 54.264894781272524]],
    [ "222798522",[-0.8425651113260223, 51.595379667924774]],
    [ "Carlisle Grid",[-2.9591258670595946, 54.899272924186306]],
    [ "223417445",[-0.39294947269003233, 51.53703013379676]],
    [ "223450957",[-0.34764187495465987, 51.541390494506814]],
    [ "Selhurst Substation",[-0.08627737667777637, 51.39288904888104]],
    [ "Bromyard Substation",[-2.500478642999765, 52.18608312513194]],
    [ "223820218",[-0.7193517697517644, 51.51827103299835]],
    [ "York University",[-1.04419624057521, 53.94919793668296]],
    [ "Tang Hall Lane",[-1.0484025282772316, 53.95745702968142]],
    [ "York University",[-1.044359009735336, 53.949131693805995]],
    [ "Thorpe Road Substation",[-0.8446119294253988, 53.75376051272779]],
    [ "Northern Powergrid",[-1.6051801663844174, 55.00975650926729]],
    [ "Drax Primary 66kV",[-1.002106440589887, 53.72972015950276]],
    [ "225733099",[-1.0390129886342236, 53.75099204484605]],
    [ "Fenton Lane",[-1.2286962204532397, 53.80367136816771]],
    [ "North Hyde Substation",[-0.4111179591456343, 51.4998176167244]],
    [ "Derwent College Substation",[-1.0476820499999997, 53.947000300000006]],
    [ "Bulwer Street Substation",[-0.22341543036649755, 51.50559969747135]],
    [ "University Business",[-1.0468169036057056, 53.95026947678839]],
    [ "Beal",[-1.2098899635037235, 53.70701563615896]],
    [ "Eggborough 6073",[-1.1226436013325674, 53.70474136156946]],
    [ "227009160",[0.4883428767298178, 51.96227304542906]],
    [ "Heckmondwike Substation",[-1.6799871661444683, 53.71142313808933]],
    [ "Smithy Green Substation",[-1.4742499198254082, 53.573406199874036]],
    [ "Network Rail",[-2.7204957000000003, 54.30504895]],
    [ "Nabwood",[-1.8117049868012425, 53.833706263169034]],
    [ "Brancepeth",[-1.707962674865684, 54.719903837694574]],
    [ "Bioscience Substation",[-1.0581278596931634, 53.9482874231737]],
    [ "Seal Sands Substation",[-1.202451062559682, 54.60713219606474]],
    [ "Bevington Substation",[-1.9437117142380949, 52.17323640248905]],
    [ "Grangetown Substation",[-1.1488086244691065, 54.58058953315101]],
    [ "227500185",[-1.3556578690635273, 53.437060710255224]],
    [ "227500209",[-1.356131213530131, 53.43614289328885]],
    [ "Benton Square Substation",[-1.5375962710218873, 55.022409056820784]],
    [ "227578591",[-1.5577048148552628, 55.01165051830041]],
    [ "Highbury Substation",[-0.11350683189255782, 51.56149485876198]],
    [ "228012090",[1.1528715483505132, 52.77147893157692]],
    [ "Goodricke College South Substation",[-1.0306780628440304, 53.94743490896985]],
    [ "Goodricke College North",[-1.032631050000001, 53.94865215]],
    [ "Langwith South Substation",[-1.0267503654433752, 53.94810263683631]],
    [ "Sports Village Substation",[-1.019374089919131, 53.95066818099007]],
    [ "Shotton",[-1.3554965582774277, 54.765464964632855]],
    [ "Stoney Cut Substation",[-1.35858387157987, 54.815557334936116]],
    [ "Cranbrook Primary 33kV Substation",[0.53306668655501, 51.09507203453688]],
    [ "Brett Street",[-0.20463682497393476, 54.089090099313125]],
    [ "Reservoir 66/22",[-1.5911227529693555, 55.13437997046593]],
    [ "228452915",[-1.569538287459232, 55.17181157259871]],
    [ "228459897",[-1.7243398792301041, 54.98860859082281]],
    [ "228459903",[-1.7174404959584568, 54.972402364024404]],
    [ "228484727",[-1.5457647732985955, 54.528913211173865]],
    [ "Maddison Street Substation",[-1.5152397783514169, 55.12843268677133]],
    [ "Coalburns Substation",[-1.8107765230432988, 54.93914650606469]],
    [ "Ravensworth",[-1.594900720711446, 54.90883509496108]],
    [ "Harraton",[-1.5404091500000003, 54.88397884999999]],
    [ "Birtley Grove",[-1.584775645919153, 54.900913465957]],
    [ "Barrow",[-3.229953759093766, 54.12349843843119]],
    [ "Belmont Covert Substation",[-0.16066429999999998, 53.3380936]],
    [ "Louth Substation",[-0.01235631420201082, 53.38205413786437]],
    [ "Tinwell Road Substation",[-0.5430160898095538, 52.63767066728275]],
    [ "229180967",[-0.3270451453814095, 51.562357481291826]],
    [ "SOUTHFIELD PARK FLATS 1661",[-1.2231467308104007, 51.746109616963615]],
    [ "Fakenham Substation",[0.8458867016615347, 52.83000488183855]],
    [ "Great Alne Substation",[-1.8113754961438062, 52.234046122501816]],
    [ "Cloisters Substation",[-1.3837317739540287, 54.89693849307596]],
    [ "229778600",[-1.4967675351148972, 54.91640520501204]],
    [ "229778601",[-1.4522199176938035, 54.9379840551428]],
    [ "229778606",[-1.4860850832294028, 54.86807889932536]],
    [ "Deptford Substation",[-0.02318472129218162, 51.48458984233203]],
    [ "Hare & Hounds",[-2.1854775496002374, 51.888197429820075]],
    [ "230391222",[-0.10998327603965344, 53.582871475603966]],
    [ "Scottish and Southern Electricity Networks",[-0.8261400948104585, 51.19208453994953]],
    [ "Tanfield Substation",[-1.701930518885601, 54.885965334511276]],
    [ "Annfield Substation",[-1.7318857414743651, 54.849889384459104]],
    [ "Tunstall Substation",[-1.3935018038541753, 54.87305173221542]],
    [ "Boothen Substation",[-2.1811845540372503, 52.996319799018465]],
    [ "231401282",[-1.4157151341968544, 54.92134121486386]],
    [ "Sunderland",[-1.394022498391603, 54.91139620755768]],
    [ "231448933",[-0.3135631886751291, 50.893107875888]],
    [ "Durham East",[-1.5765749258135102, 54.78107805264254]],
    [ "Denton Substation",[-0.7763784020807112, 52.21742062083082]],
    [ "High Flatts",[-1.5902657958226962, 54.86436319544861]],
    [ "Consett Substation",[-1.8195213150565879, 54.85444445169812]],
    [ "231869908",[-0.10557705421867643, 53.553171696754106]],
    [ "Chelmsford Avenue College 52598",[-0.10548807752694551, 53.553188036824444]],
    [ "Newdigate Substation",[-1.4993507200155323, 52.48589565274567]],
    [ "Coniston Place Substation",[-2.1975922032526882, 52.967187683574195]],
    [ "232178149",[-1.5927804094047882, 53.80521288446021]],
    [ "Stuart Avenue Substation",[-2.188674450000001, 52.96643649999999]],
    [ "Silverwood",[-1.2792498624822624, 53.44048361598578]],
    [ "The Garrison Substation",[-6.3206048272534225, 49.91235634302709]],
    [ "Clipstone Substation",[-1.1030346423649546, 53.16599856175618]],
    [ "Willoughby Substation",[-1.0709893363014558, 52.826189047224695]],
    [ "Willoughby Substation",[-1.0705388590884637, 52.826030561170036]],
    [ "Bar Point Switching Station",[-6.2996053, 49.93406985]],
    [ "Clevedon Primary Substation",[-2.841489018565556, 51.43221153115007]],
    [ "National Grid Electricity Distribution",[-4.355996089774587, 50.62894715979385]],
    [ "Exeter Science Park Substation",[-3.461034166911362, 50.73494645027999]],
    [ "Pailton Substation",[-1.2762535297492579, 52.43879757227228]],
    [ "Lower Town St.Agnes Substation",[-6.348835227367005, 49.89387240785911]],
    [ "234398656",[-2.8998150469471335, 54.37767124456754]],
    [ "Burley Street",[-1.5626167, 53.800904900000006]],
    [ "Kettering Substation",[-0.7382800600600742, 52.401063747373605]],
    [ "Northampton East Substation",[-0.8367284201301607, 52.242903364832095]],
    [ "Cedar Close Substation",[-1.3027672623998865, 50.91436423202973]],
    [ "234565473",[-1.0798773453214656, 52.86893872198342]],
    [ "Hedley Chase",[-1.5714430879693868, 53.795619618687525]],
    [ "Halfway Substation",[-1.34197265, 53.3281612]],
    [ "Higher Walton Rd Substation",[-2.661128923835336, 53.74549855666889]],
    [ "Middle Town St.Agnes Substation",[-6.344268221656812, 49.89206692296482]],
    [ "Higher Town St Agnes Substation",[-6.341024280439475, 49.893274495725684]],
    [ "Grain Primary",[0.69082715, 51.444988300000006]],
    [ "235292887",[-0.8909378027223457, 52.15241708564993]],
    [ "Southern Cross Substation",[-0.22456470457474573, 50.837707725643305]],
    [ "Egremont Substation",[-3.5292431106317825, 54.5034186352409]],
    [ "Butterwick",[-0.48926021624235766, 54.13150172406575]],
    [ "236072368",[-0.41782814846009403, 54.27155330949201]],
    [ "Grimethorpe Substation",[-1.3817432706911905, 53.570517654158984]],
    [ "Scottish and Southern Electricity Networks",[-1.3600303622625607, 51.03763222580771]],
    [ "Stratford Substation",[-1.7413886964901493, 52.194393276472574]],
    [ "Netherhills Substation",[-2.34074177777384, 51.76208071142724]],
    [ "Burntwood Substation",[-1.9480105280981679, 52.6801027033045]],
    [ "Brenda Trading",[-1.2127706181160123, 54.665178767542656]],
    [ "Greystones A",[-1.1247622459130648, 54.575289156649184]],
    [ "Greystones B",[-1.1292693649966148, 54.57413745283161]],
    [ "Ludlow Substation",[-2.689257262487758, 52.36673676045599]],
    [ "Bloxham Substation",[-1.3864940844942266, 52.03134546747014]],
    [ "Nuneaton Substation",[-1.4883217621008222, 52.51156206803342]],
    [ "Hinckley Substation",[-1.3957183497742283, 52.52877520235933]],
    [ "Daventry Substation",[-1.154471763201788, 52.2593557282133]],
    [ "Pershore Substation",[-2.0978641543162198, 52.13433373646334]],
    [ "237420905",[0.1011887277422513, 51.75640803703949]],
    [ "Newent 11kV",[-2.3800466007453553, 51.928678540727816]],
    [ "Broadway Substation",[-1.855721656236674, 52.054504711351164]],
    [ "Hortonwood Substation",[-2.489058816522711, 52.7188437989022]],
    [ "Stafford South",[-2.106383709301109, 52.7939725448802]],
    [ "Huntingdon Substation",[-0.18338825879792325, 52.34124565482416]],
    [ "238179555",[-1.4585052438874926, 50.982978277411526]],
    [ "Bodenham Substation",[-2.676588917204442, 52.14874466543861]],
    [ "Leominster Substation",[-2.7612780850589007, 52.228611982533614]],
    [ "Woofferton Substation",[-2.727136555484008, 52.307178165125094]],
    [ "Woodside Substation",[-2.116976062919441, 52.489721732335155]],
    [ "Fishersgate Substation",[-0.2212981182380937, 50.83462129951409]],
    [ "Coventry Colliery Substation",[-1.5265022706353402, 52.45536933782872]],
    [ "Coventry South Substation",[-1.5393606841912875, 52.40518426480429]],
    [ "239578061",[-1.2484573633693632, 51.92730629919538]],
    [ "239866155",[-2.9970898255622878, 53.88254302228058]],
    [ "Arley Substation",[-2.3220758090840787, 52.42612521478343]],
    [ "Southam Substation",[-1.3904912326524037, 52.27301854954922]],
    [ "Peterborough South Substation",[-0.26196978108902735, 52.5561911060065]],
    [ "National Science Learning Centre Substation",[-1.050565249999999, 53.950899449999994]],
    [ "Freshwater Substation",[-1.5152695870854445, 50.681130150415584]],
    [ "Kington Substation",[-3.00763670865453, 52.21203782866185]],
    [ "Lindley",[-1.8283394413547576, 53.655478709752515]],
    [ "Thornhill 132",[-1.653414644390926, 53.677746669538834]],
    [ "241937608",[-0.30318602654369403, 51.7372502343908]],
    [ "Welwyn Feeder Station",[-0.21048986877717007, 51.787693509040835]],
    [ "Cell Barnes Substation",[-0.3133324335558109, 51.74493682037528]],
    [ "National Grid",[-1.171075824833904, 52.94696740814004]],
    [ "National Grid Electricity Distribution",[-1.1671214132537044, 52.94749115675604]],
    [ "Northern Powergrid",[-1.0631952000000013, 54.53250065]],
    [ "Malham Drive Lincoln",[-0.6031170000000008, 53.22332955]],
    [ "Park Rock",[-1.1620750191980385, 52.94766339618347]],
    [ "244771027",[-1.9405789683448043, 50.755551064430364]],
    [ "Castle Road Substation",[-1.1519050775494741, 52.94903393463123]],
    [ "Spark Mill Lane",[-0.41718580165654867, 53.83700687284352]],
    [ "Crowhurst Primary",[0.008719299999999998, 51.21381439999999]],
    [ "UK Power Networks",[-0.07806667311901984, 51.5472037060762]],
    [ "Hazelwood Substation",[-0.7130662000000002, 52.4853031]],
    [ "Plympton Substation",[-4.012248929033243, 50.37182579345276]],
    [ "College Road 11KV;Milton Mount School 11LV",[-1.823781636101745, 50.72435172978081]],
    [ "245715468",[-1.2130763263289954, 51.3638600830778]],
    [ "Southbourne 33/11KV S/S",[-1.808849049305442, 50.72815950948781]],
    [ "247395809",[-1.1369795761331674, 51.60073704485025]],
    [ "SP Energy Networks",[-2.799025430243903, 53.56090025964656]],
    [ "Market Square Substation",[-1.0546944133150935, 53.94882567914957]],
    [ "248001847",[-0.0702208, 50.863738999999995]],
    [ "Newhaven Town Primary",[0.05620483342379918, 50.79652160471096]],
    [ "Newhaven Grid",[0.056586556619158145, 50.797226208598964]],
    [ "North Wilford Substation",[-1.1636159772347585, 52.93481194135457]],
    [ "Burslem Substation",[-2.202146165639163, 53.05069761693702]],
    [ "nG2 Enterprise Way",[-1.1650625000000017, 52.941311799999994]],
    [ "UK Power Networks",[-0.2326151138083322, 51.75348572153057]],
    [ "Castle Quay Close",[-1.165903917921363, 52.946066365079524]],
    [ "Danesmoor Substation",[-1.3944717366072774, 53.16346126428112]],
    [ "250962681",[-0.25645835232101294, 51.54519366651784]],
    [ "Leicester Road Grid Substation",[-0.25808789742089494, 51.54505795845337]],
    [ "BOS Main",[-0.5933563763699878, 53.56651993615303]],
    [ "Seraphim",[-0.6116656731906414, 53.57827746840578]],
    [ "HPM 33",[-0.6220091925975342, 53.591127249402106]],
    [ "DLCO",[-0.6120934543527474, 53.593629304275424]],
    [ "SRM",[-0.6253417520481213, 53.582495114063704]],
    [ "Weymouth Crescent Substation",[-0.6818844670752983, 53.58000160065975]],
    [ "Dursley Substation",[-2.3478783249143267, 51.69062567664294]],
    [ "Wrawby",[-0.453492004491757, 53.56000853824208]],
    [ "Berrick Saul Substation",[-1.0497535710393369, 53.947879373664826]],
    [ "Wilcon H.E.",[0.02435523985636643, 52.25033412167308]],
    [ "Northampton Substation",[-0.88122547453891, 52.22943478000761]],
    [ "Longcliffe Substation",[-1.6592530064475197, 53.09480610626325]],
    [ "Little Irchester Substation",[-0.6796023797689223, 52.287340654116015]],
    [ "Humber Road Substation",[-0.2147460896233171, 53.633323920602045]],
    [ "Desborough Substation",[-0.815029897532936, 52.44523822554797]],
    [ "255617996",[-0.5341992168127125, 52.07864483012112]],
    [ "Rock Ferry Substation",[-3.0053853670715327, 53.36421443529232]],
    [ "Arras Boulevard",[-1.61746245, 52.2841654]],
    [ "Cherry Lane",[-1.6237004000000002, 52.282716549999996]],
    [ "Daly Avenue",[-1.6228846620501343, 52.2805697326569]],
    [ "Castle Lane",[-1.5862525341585463, 52.280478786085865]],
    [ "Northport Drive No1",[-2.11024695, 50.69640045]],
    [ "Bromborough Substation",[-2.96348608811297, 53.333958350384464]],
    [ "Station Road",[-0.6422943112023379, 53.589017067596956]],
    [ "South Brent Substation",[-3.825950414996787, 50.42760521519433]],
    [ "Monckton",[-1.4325659299063138, 53.608490574361475]],
    [ "Hemsworth",[-1.3705778333392469, 53.63548521497485]],
    [ "Kexby Avenue",[-1.062196845929663, 53.952268749449466]],
    [ "257203552",[-2.9014393731389534, 51.32879602577178]],
    [ "257311344",[-0.23841687407321077, 53.63835871456388]],
    [ "Simplex Substation",[-2.078146744611764, 52.9659775912835]],
    [ "Marmion Road",[-1.5074381501074328, 53.36216947424993]],
    [ "Sutton Junction Substation",[-1.2317965642398188, 53.12348706627121]],
    [ "Avon Drive",[-2.1150027173554267, 50.69613714510355]],
    [ "Trent Drive",[-2.11877815, 50.69660489999998]],
    [ "John Road S/S",[-2.1151936821667032, 50.693554297775464]],
    [ "Tarrant Drive R/O 19",[-2.1155759102906297, 50.69833426668673]],
    [ "258509757",[-1.4830201228788433, 54.40274066883465]],
    [ "258735932",[-2.9195170251810962, 54.39768335371916]],
    [ "Howard Road",[-2.519923767975177, 51.61496964908865]],
    [ "Holton Heath Housing Site",[-2.082751716604343, 50.720431797769464]],
    [ "Filleul Road",[-2.100168182350666, 50.70812610348107]],
    [ "Gore Hill",[-2.102671072024147, 50.70678075177432]],
    [ "Married Quarters",[-2.2370384000000003, 50.62995694999999]],
    [ "Pound Lane 84",[-1.966118737044337, 50.73104158985679]],
    [ "Saint Helens Road",[-2.096529393761724, 50.70545084396279]],
    [ "Stoborough Meadows",[-2.1051875500000006, 50.6722418]],
    [ "Studland Switching Station",[-1.9695233499999998, 50.66009445]],
    [ "Tyneham Close",[-2.094521848876743, 50.70725976193453]],
    [ "Wareham Road Married Quarters",[-2.2316727500000004, 50.634007950000004]],
    [ "Wessex Oval",[-2.120644120525482, 50.69227470117655]],
    [ "West Lulworth Bore Hole",[-2.2524098999999995, 50.62645955]],
    [ "Woodlands Drive",[-2.0938084438101394, 50.70984546635444]],
    [ "258846582",[-2.2219520999999998, 50.6360643]],
    [ "13 Pound Lane",[-1.9642137526268717, 50.73199504415381]],
    [ "20 Forest Edge Road",[-2.1052951000000006, 50.70373175000001]],
    [ "258877830",[-0.8114667621629666, 51.28391118808541]],
    [ "Cullompton Primary",[-3.403429196508382, 50.85928248444797]],
    [ "Ninfield Local Primary",[0.43141354999999953, 50.886336149999984]],
    [ "Kirkstall C",[-1.5898603515900123, 53.8067564372613]],
    [ "Network Rail",[0.9345036499999997, 51.879400100000005]],
    [ "259639606",[-0.8072001232492082, 51.50468464825347]],
    [ "259639617",[-0.754342758637606, 51.51144123276001]],
    [ "259857677",[-2.868813201437233, 51.329467817309165]],
    [ "Yorkshire Water Hull Road Substation",[-0.23771104819245012, 53.74426060580789]],
    [ "260045493",[-0.12413065047391038, 51.54088859712702]],
    [ "Meadow Close",[-2.21808615, 52.13670335]],
    [ "261275624",[-1.1767591674310869, 50.80359355716949]],
    [ "Castle Meads Substation",[-2.2572842802620157, 51.86764244471014]],
    [ "Gunnislake Substation",[-4.226491957317399, 50.51973307354249]],
    [ "Kilo",[0.1154969420989393, 52.23489833224487]],
    [ "Charlie",[0.12292377069978733, 52.234003997125384]],
    [ "Loup Farm South",[-1.7211655396343066, 54.962355209646034]],
    [ "263812527",[-0.6510217456765672, 53.5850063096284]],
    [ "263812628",[-0.6803528798791972, 53.599375455897096]],
    [ "263812690",[-0.6556051861070475, 53.5784854568892]],
    [ "Didcot Substation",[-1.259285897241922, 51.625597792049234]],
    [ "Dungeness Substation",[0.9475038053995356, 50.9147078632136]],
    [ "Cleve Hill Substation",[0.9419644626214956, 51.33798590035405]],
    [ "264214748",[0.39265177547242597, 51.453958773399904]],
    [ "Ripe Primary",[0.1410951566462916, 50.866208782044936]],
    [ "Newick",[0.0024009271215886642, 50.98231569402797]],
    [ "Lewes / Polegate Grid O/H - U/G termination",[0.24332966310739715, 50.85844688331626]],
    [ "Uckfield Primary",[0.09517605547237418, 50.97028859045818]],
    [ "Redbridge Substation",[0.04314238055466436, 51.588584395272065]],
    [ "West Common Lane nursery",[-0.6657519355872289, 53.57362881044021]],
    [ "JLC substation",[-0.6666287117055513, 53.57418547892858]],
    [ "Berkeley Circus",[-0.6838998446099844, 53.59106706553157]],
    [ "Sizewell B Substation",[1.6165820745377637, 52.21502920764734]],
    [ "264650882",[1.2759671188897297, 52.57531512205292]],
    [ "264709991",[-2.4242712432686386, 50.7970188814401]],
    [ "Puddletown",[-2.3669970523009454, 50.754565837868185]],
    [ "Rowland Road",[-0.6491307816143038, 53.585422010989774]],
    [ "Liskeard Substation",[-4.451669613945476, 50.448566401612936]],
    [ "Loup Farm Substation",[-1.7171329812786886, 54.96266200154482]],
    [ "Shirehampton Master Substation",[-2.6783675887221583, 51.489279117060114]],
    [ "266538696",[-0.6147136955305786, 51.30944383295513]],
    [ "Berwick",[-2.078630910440825, 55.77743210694337]],
    [ "Horse Crofts",[-1.7192575311344258, 54.96551980611869]],
    [ "Osborn Street Substation",[-0.07081415351416255, 51.5168706197187]],
    [ "Goldenhill Bank Substation",[-2.2268331746429832, 53.082807302708254]],
    [ "Wallsall Substation",[-2.0007979117709214, 52.59774910631529]],
    [ "272169939",[-1.0971510834276736, 53.22551528972914]],
    [ "Warsop Substation",[-1.140444556711706, 53.21635182189367]],
    [ "Wymering Grid Substation",[-1.0788004075086692, 50.84492929468541]],
    [ "274859500",[-0.6624228017878436, 53.58433122648275]],
    [ "Axholme Road",[-0.6729635000000002, 53.5858434]],
    [ "Ince Substation",[-2.8114042579716636, 53.2778360080515]],
    [ "Scottish Power",[-3.00702621656944, 53.34124818500629]],
    [ "Keswick Substation",[-3.1313558046850978, 54.603765980682745]],
    [ "Winery Ln Unit 7 Substation",[-2.67769785, 53.750314949999996]],
    [ "Winery Lane Substation",[-2.677459993168054, 53.750841411845144]],
    [ "Kinver",[-2.2146315325902353, 52.440654951037274]],
    [ "Clarence Road",[-1.5348195221786007, 53.7930105399136]],
    [ "Lostwithiel Substation",[-4.659424859724016, 50.41202673530209]],
    [ "283207199",[-2.681146210907573, 54.73059143008556]],
    [ "Elmhirst Lane Substation",[-1.5380359486779163, 53.547120254054946]],
    [ "Hunshelf Bank Substation",[-1.5925037738591559, 53.48499110138732]],
    [ "284395915",[0.5339121, 52.48626575]],
    [ "286056113",[-0.9094774379584555, 51.385812432515614]],
    [ "286073405",[-0.9345355234041731, 51.36977729559464]],
    [ "Redditch South Substation",[-1.9480004421079764, 52.2878809477101]],
    [ "286505076",[-0.6631602040684865, 53.5981540924553]],
    [ "286505453",[-0.6619313000000009, 53.598528300000005]],
    [ "286505460",[-0.6638246000000001, 53.59059105]],
    [ "286505665",[-0.6431521288977493, 53.59271525374528]],
    [ "Dormer Place",[-1.5383431283136746, 52.28831643398216]],
    [ "Blackwell Substation",[-1.3441950699361642, 53.11531251472451]],
    [ "Hermes Close",[-1.5422944234664462, 52.27652045383414]],
    [ "Queensway South",[-1.5390628870405283, 52.27676027159127]],
    [ "Whinmoor",[-1.4592057499999989, 53.8338393]],
    [ "Devonshire Square Substation",[-0.07970294779482082, 51.51636640607893]],
    [ "287967879",[-0.5508230288469171, 51.324512495497856]],
    [ "Scottish Power",[-2.9915862338654136, 53.32083497880113]],
    [ "Scottish Power",[-2.968529760509965, 53.31719689799116]],
    [ "St Blazey Switching Station",[-4.7224498240034665, 50.35404415724534]],
    [ "Scottish Power",[-2.9858626425714867, 53.328039128894254]],
    [ "289420367",[-1.256431165159465, 52.891100002123196]],
    [ "Scottish Power",[-2.9877197000000013, 53.33318610000001]],
    [ "SP Energy Networks",[-2.9843211, 53.331773600000005]],
    [ "Sydenham Park Substation",[-0.05346085822228214, 51.43315855160158]],
    [ "Lots Road Substation",[-0.18244699999999694, 51.477425950000004]],
    [ "290254102",[-0.7459282265519899, 50.968751764893646]],
    [ "Lewes Central",[0.017060553010972087, 50.87340711874233]],
    [ "Slater Crescent",[-1.5743897711838846, 53.07519679643937]],
    [ "Wingham Primary",[1.2138835000000001, 51.25198034999999]],
    [ "291637296",[-2.3357533643295487, 53.77560359571158]],
    [ "Wroxham Primary Substation",[1.407647383722962, 52.71598982653745]],
    [ "Little Barford Substation",[-0.27032473351512915, 52.20693224054864]],
    [ "National Grid Electricity Distribution Plc",[-2.994434464363805, 52.49151142375937]],
    [ "292371992",[-3.1325621470867397, 51.20617406895994]],
    [ "292372019",[-3.1276508146550843, 51.2067804305173]],
    [ "292470577",[-1.2678249444805627, 51.625690184472695]],
    [ "Seabank Substation",[-2.6702303893594532, 51.536749611215946]],
    [ "Blackpool Substation",[-4.8312750940893885, 50.3449069776155]],
    [ "293353043",[-0.03313335091344538, 51.35745953050075]],
    [ "Cherington Substation",[-2.146597254672308, 51.67482811012225]],
    [ "Wallasey Substation",[-3.0558286630699203, 53.41304153743138]],
    [ "Worsbrough Park",[-1.4616310000000001, 53.5174964]],
    [ "Hayle Substation",[-5.429574668628167, 50.19273375319735]],
    [ "Newton St Cyres Substation",[-3.569221982530251, 50.77514176065885]],
    [ "Winscombe Substation",[-2.8363949725107727, 51.31828985600203]],
    [ "Houghton Main Substation",[-1.3638685110949365, 53.547091898042346]],
    [ "295405824",[-0.8840106952172436, 51.95160728934351]],
    [ "Hatherleigh Substation",[-4.076045697565969, 50.81730174537667]],
    [ "296681748",[-2.376927307716947, 53.19766807223977]],
    [ "296904396",[-1.4821496658033022, 55.00367330853885]],
    [ "Carrallack Terrace",[-5.68030944994156, 50.120993963040334]],
    [ "Malehurst Substation",[-2.91057810789939, 52.65017773020542]],
    [ "BBM Soaker Bay West",[-0.6063290289706774, 53.5705163067227]],
    [ "Emmanuel Rd",[-0.6157936161995357, 53.56829554747351]],
    [ "CMB 33kV",[-0.6225953149412733, 53.58208658431459]],
    [ "Finishing End 6.6kV",[-0.6276942626437149, 53.59023634366587]],
    [ "DLCHP 11kV B",[-0.6165584421192167, 53.600006831358286]],
    [ "OBP Main 11kV",[-0.6084084609127184, 53.57639326574659]],
    [ "MSM 33kV",[-0.599752956559678, 53.57383717449662]],
    [ "Hickelton",[-1.2988253490221873, 53.543718363110344]],
    [ "Elsecar",[-1.4128493, 53.496302899999996]],
    [ "Talke Substation",[-2.263442324223442, 53.082402603295876]],
    [ "Endike Lane Substation",[-0.38965423612343675, 53.77979989233862]],
    [ "First Avenue Substation",[-0.35934071913810156, 53.78134834739832]],
    [ "Spinney Hill",[-1.5733073000000002, 52.2934119]],
    [ "Budleigh Salterton Substation",[-3.3327951655856722, 50.63035329715661]],
    [ "Exmouth Substation",[-3.400877310515375, 50.63090300484832]],
    [ "Core Hill Substation",[-3.2468955881935786, 50.701877253391515]],
    [ "Offwell Substation",[-3.15272132468194, 50.79481851343897]],
    [ "County Road North Substation",[-0.39278664955965953, 53.7573229087253]],
    [ "Whiston Road Substation",[-0.06825085514642952, 51.53522634863617]],
    [ "305192365",[-0.6630127000000001, 52.25452095000001]],
    [ "Davidstow Substation",[-4.61812863410082, 50.654843876881195]],
    [ "Trumfleet Generation",[-1.0791572481964817, 53.585501921929094]],
    [ "305547821",[-1.158192138217668, 50.815486075391526]],
    [ "Rugby Substation",[-1.262319260648599, 52.38823428112803]],
    [ "Brownsover Substation",[-1.2553999999999998, 52.38652820000001]],
    [ "305583193",[-4.3570513319541115, 50.80489141437159]],
    [ "Tiverton Road Substation",[-0.3299370191458077, 53.78943464488511]],
    [ "Wawne Road Substation",[-0.33249716665427326, 53.802924622990076]],
    [ "306996350",[-1.4425069598446452, 50.915600454356245]],
    [ "Pollington",[-1.059950135783139, 53.680709161377465]],
    [ "Wadebridge Substation",[-4.813744186321556, 50.52202122737256]],
    [ "307146838",[-1.1290327, 51.3742668]],
    [ "Delabole Substation",[-4.713294963798689, 50.626809027417345]],
    [ "307226088",[-4.530055999016548, 50.64424590924415]],
    [ "Callywith Substation",[-4.6997578759475624, 50.47968892521589]],
    [ "Teversal Substation",[-1.2918892929934724, 53.148424714977416]],
    [ "308663252",[-1.3543889559959241, 50.9680021753815]],
    [ "Ramsey Road",[-1.5203322999999986, 52.282289350000006]],
    [ "310110727",[-1.3899511602215282, 51.693192678919985]],
    [ "Ormonde Wind Farm Substation",[-2.8877020058562395, 54.03281126602959]],
    [ "310495252",[-2.7868428583392535, 54.06610261738921]],
    [ "Stanah Substation",[-2.988324101069588, 53.87893093932475]],
    [ "Blyth Substation",[-1.531315495656584, 55.141189999062426]],
    [ "310504462",[-1.5327509965433883, 55.19963259431178]],
    [ "310504463",[-1.5330126202619392, 55.200344989026945]],
    [ "Ashbourne Substation",[-1.7116282999999997, 53.013985399999996]],
    [ "Hockley Substation",[-1.900327363700533, 52.48908508955403]],
    [ "311840838",[-1.1832756013423347, 54.6356625139779]],
    [ "Waterworks Mildmay Road Substation",[0.4744314999999994, 51.7297117]],
    [ "313180501",[-0.14614468443740689, 53.60139848050947]],
    [ "313294834",[-0.9808864487175248, 51.420677783757334]],
    [ "Eggborough Substation",[-1.1298003933525504, 53.713514614351936]],
    [ "Finsbury Market Substation",[-0.08252889374002703, 51.521106393608406]],
    [ "Bingley",[-1.8408326003510218, 53.85294487292973]],
    [ "313890841",[-2.16333178974645, 53.53015075898864]],
    [ "314338790",[-0.7813506065523582, 53.300732771811695]],
    [ "314338853",[-0.7989419392705616, 53.36251996493928]],
    [ "Austerfield",[-1.0039429690428023, 53.45923879850366]],
    [ "Fiddlers Ferry Substation",[-2.68880255, 53.37016319999999]],
    [ "Simonswood Substation",[-2.8607118499999995, 53.49093745]],
    [ "Fiddlers Ferry Substation",[-2.68896586277854, 53.37141739271447]],
    [ "Buslingthorpe Green",[-1.5430821810467803, 53.81392029273656]],
    [ "Burnaston Substation",[-1.5736307333767408, 52.87420955011911]],
    [ "316238278",[-0.26210077619324607, 52.08092006750518]],
    [ "Asfordby Substation",[-0.9290205207592411, 52.78134165174035]],
    [ "316242101",[-1.0613737401897545, 52.700613157660264]],
    [ "Brogborough Substation",[-0.5891767569263879, 52.041271152065136]],
    [ "Hamilton Substation",[-1.0785574379628973, 52.658688293081916]],
    [ "Wolverton BR Substation",[-0.7947264999999999, 52.050012450000004]],
    [ "Bradwell Abbey Substation",[-0.7963053488680099, 52.04951940053369]],
    [ "Ollerton Substation",[-1.0232203938329842, 53.19486101924476]],
    [ "Thoresby Substation",[-1.040833702663052, 53.19836230286696]],
    [ "Billingborough Substation",[-0.3650475360139592, 52.89489061074345]],
    [ "Hampole",[-1.2214669, 53.57105040000001]],
    [ "Leisure Way Substation",[1.7346651862872449, 52.50203556848165]],
    [ "Dowsby Fen Substation",[-0.2762732229052855, 52.84878028069254]],
    [ "318848679",[-0.3957094031425917, 51.90132822067995]],
    [ "318850296",[-0.47330247714858026, 51.74240737811397]],
    [ "Oldbury Substation",[-2.0260447563710584, 52.498355751066555]],
    [ "318886989",[-1.714574749999999, 52.53457509999999]],
    [ "318886991",[-1.716212318783875, 52.531479566599046]],
    [ "Oswestry Substation",[-3.0358522849373824, 52.868070769595015]],
    [ "Northen Power Grid",[-0.1711888778660618, 53.57810043374363]],
    [ "Wheatacre Road Substation",[-1.5898532716806943, 53.47946690505606]],
    [ "Tividale Substation 132kV",[-2.053827456449144, 52.519851797931494]],
    [ "321048215",[-2.8669267343216083, 53.230407865502244]],
    [ "Horsham Grid Substation",[-0.3212705042475004, 51.063157057432726]],
    [ "Nottingham East Substation",[-1.1023908207943482, 52.96509681154616]],
    [ "Cinderhill Substation",[-1.2019176853846474, 52.98801073526298]],
    [ "Cribbs Causeway Substation",[-2.595004888183726, 51.52869037005479]],
    [ "Robin Rigg East Substation",[-3.511489548880168, 54.64939091759568]],
    [ "Malvern Avenue Substation",[-2.684008511388408, 53.75191097674948]],
    [ "Kittlingborne Substation",[-2.6438627499999985, 53.74252430000001]],
    [ "330291759",[0.5616105932521998, 52.551105106437795]],
    [ "331094170",[-3.4721379606069234, 54.40453339597395]],
    [ "Walton Le Dale Substation",[-2.6723214395024772, 53.74685373381801]],
    [ "331556552",[-2.1871427, 53.00268729999999]],
    [ "Yeaman Street District Substation",[-2.186101701904836, 52.99978543210728]],
    [ "331771775",[-0.6023557685279983, 50.97355175893757]],
    [ "Wandsworth Grid Substation",[-0.19330604136162027, 51.4610709365394]],
    [ "332136984",[-0.5769442582685085, 53.04593058896152]],
    [ "332319948",[-2.0242350878668396, 55.749773564854166]],
    [ "Cotes Heath Substation",[-2.2481642450461568, 52.91147806105431]],
    [ "Wareham Town",[-2.1142989763623565, 50.686592262917515]],
    [ "Penistone Substation",[-1.6164762864315811, 53.52149446682594]],
    [ "NPG",[-1.566296318046234, 53.57070309763857]],
    [ "Hazelhead Substation",[-1.7342299291583678, 53.540258395973794]],
    [ "Scholes Substation",[-1.757893350000001, 53.5630879]],
    [ "Barnsley Substation",[-1.476186206344144, 53.55434627645151]],
    [ "Stairfoot Substation",[-1.4401055934177585, 53.55012136512198]],
    [ "Burton Road Substation",[-1.438808390737016, 53.57089503551742]],
    [ "Fish Dam Lane Substation",[-1.4405380593764685, 53.58196783518821]],
    [ "Whieldon Road",[-2.1757615, 52.99835045]],
    [ "Bilsthorpe Substation",[-1.0129301153128263, 53.151406435372294]],
    [ "Dormer School",[-1.5580208140583427, 52.281267023600634]],
    [ "Eckington Substation",[-1.3662581999999994, 53.30320435]],
    [ "335066159",[-2.274575132707041, 53.09762539514432]],
    [ "British Gas Hallen Substation",[-2.654377020323878, 51.52909725251287]],
    [ "Bishopstone Switching Station",[-0.8284434454830524, 51.7822301664685]],
    [ "Merrick St",[-2.1694331567362104, 53.03263464335925]],
    [ "Home Carr Substation",[-1.1651604782955036, 53.313268497774644]],
    [ "Newsholme Close",[-1.5838330138351604, 52.29394202187287]],
    [ "Blackburn Substation",[-2.4478403686639654, 53.75896129811113]],
    [ "Roach Road",[-2.6132685, 53.75392945]],
    [ "Comberton Village Substation",[0.020460180020938457, 52.185580196807734]],
    [ "Henbury Hill Master Substation",[-2.620793258311915, 51.49701343520312]],
    [ "North Tawton Substation",[-3.9138760389836396, 50.79603850896794]],
    [ "Palm Paper 132kV Intake",[0.3769513628506854, 52.72659490140563]],
    [ "Clyst Honiton Substation",[-3.434150073708721, 50.729127342449964]],
    [ "Marsh Green Substation",[-3.3515674987474995, 50.73897563345666]],
    [ "Old Milverton Road",[-1.5574920272982509, 52.29603965504448]],
    [ "340112796",[-1.1098215445735164, 53.13755865405161]],
    [ "Rolvenden Layne Switching Station",[0.6523939196154801, 51.03428051874918]],
    [ "341209725",[-1.1495982075687494, 51.08888872939832]],
    [ "341333705",[-2.313126145206128, 53.695199526315335]],
    [ "Electricity North West",[-2.3008395489180224, 53.694176148479734]],
    [ "Guys Cross Estate",[-1.5797378333213623, 52.290449100119716]],
    [ "West Lulworth",[-2.2486199342973827, 50.62596303095669]],
    [ "Hucknall Substation",[-1.1986519006094853, 53.041150382586096]],
    [ "Darley Abbey Substation",[-1.485732002687082, 52.94392692986355]],
    [ "Mapperley Substation",[-1.131082728164199, 52.97016590206514]],
    [ "Croydon Central Substation",[-0.10702190459363785, 51.37493714046639]],
    [ "Saint Mary's Road Substation",[-2.663077585354957, 53.73010411423861]],
    [ "352172760",[-0.6788705549440083, 53.55591456224684]],
    [ "352172803",[-0.677822258392528, 53.5547097953784]],
    [ "Knot House Substation",[-2.661769852534624, 53.748638217653614]],
    [ "353071430",[-2.2364883107630074, 53.48619167686225]],
    [ "353719231",[-3.172098509287316, 54.158282554792216]],
    [ "Hemblington Primary Substation",[1.4504347808410405, 52.65490467243731]],
    [ "North Walsham Primary Substation",[1.3834533357476053, 52.81705357725521]],
    [ "Donnington Primary Substation",[-2.4338546616689074, 52.7264069483277]],
    [ "Stanlow South Substation",[-2.8350475999999993, 53.26847645]],
    [ "Campbell Park",[-0.7441772431811879, 52.05112614104691]],
    [ "Brugley Substation",[0.2223849, 51.9417941]],
    [ "Wood Green Feeder Station",[-0.12370470446925294, 51.60047833202123]],
    [ "Kirkby Lonsdale Substation",[-2.6170743784909916, 54.20367186586611]],
    [ "Storeys Way Primary Substation",[0.10538414215082599, 52.21301495202404]],
    [ "Kitts Green Substation",[-1.779416149491915, 52.48398264926871]],
    [ "Histon Primary Substation",[0.10705260044301594, 52.24360617523163]],
    [ "Milton Road Primary",[0.1471487962558985, 52.22980158413581]],
    [ "Thompsons Lane Primary Substation",[0.11771553413410904, 52.21038468531031]],
    [ "Swaffham Road Bottisham",[0.2585338101780249, 52.22411138921245]],
    [ "Barnwell Primary",[0.14786330541297107, 52.21298176583977]],
    [ "Madingley Road Primary Substation",[0.08779376775713013, 52.21297742976694]],
    [ "366850345",[-2.755981004864631, 53.33140551181604]],
    [ "The Green, Bilton, Rugby",[-1.2906885320887291, 52.359946066212615]],
    [ "Rainhill Substation",[-2.7383495964972626, 53.421194827307716]],
    [ "SP Energy Networks",[-2.6297330182775376, 53.53643689133905]],
    [ "Electricity North West",[-2.977513814328924, 53.781479468971966]],
    [ "367133999",[-0.025982907357362986, 52.00332979859948]],
    [ "367157028",[-1.7330716500000012, 54.97593225000001]],
    [ "367157045",[-1.7325302500000004, 54.97509440000002]],
    [ "367157046",[-1.732041646925504, 54.9758650322614]],
    [ "Brimley Road",[0.12475934464885309, 52.22365461261607]],
    [ "Leys Avenue",[0.12844853154545124, 52.223964950753654]],
    [ "Mulberry Close",[0.13110111149661613, 52.22137516866638]],
    [ "Tankersley 66KV",[-1.4705046971206117, 53.478281484451344]],
    [ "Wilberforce Road",[0.09911514200087984, 52.20750778814889]],
    [ "Shepreth Primary",[0.03022811799689437, 52.113520149751395]],
    [ "369163256",[-1.9775535404365814, 51.291718631616796]],
    [ "369231294",[-0.021576403456803922, 52.0520364567716]],
    [ "Barrington Road",[0.049871169439492644, 52.14866625488359]],
    [ "Cantelupe Road",[0.060397848007908754, 52.15225151864455]],
    [ "Light Source Grid Site",[-0.002897204307589172, 52.07529275185478]],
    [ "Melbourn Primary Substation",[-0.0037387900330624157, 52.075177985061025]],
    [ "Melbourn Solar",[-0.003010697266309483, 52.07510915418151]],
    [ "Eboracum Way",[-1.07097725, 53.964020700000006]],
    [ "Meadow Lane Substation",[-1.3758105593850205, 53.10196438050349]],
    [ "Naishcombe Hill",[-2.4273820637611943, 51.463131243965755]],
    [ "Lowestoft Grid Substation",[1.7398011323410632, 52.47899207845956]],
    [ "Carlton on Trent Substation",[-0.813683814040567, 53.168498089906706]],
    [ "Northwick Generation Substation",[-1.8020261560709807, 52.01956637242587]],
    [ "East Stevenage Primary",[-0.1778386001465521, 51.89780930018256]],
    [ "Folly Bridge Substation",[-3.6797958842419565, 50.775955928495904]],
    [ "Langwathby Low Mill",[-2.6716976824177383, 54.6971639170619]],
    [ "Lazonby Railway Station",[-2.7032342556837734, 54.750324548254895]],
    [ "Scaur Close",[-2.7035132079026423, 54.74796826975693]],
    [ "West View (Horton)",[-2.3001219499999994, 54.1489594]],
    [ "Hackney Substation",[-0.0427888433057338, 51.55913643242315]],
    [ "371817305",[-1.4136969880887331, 52.9685461075274]],
    [ "Newton Ferrers Substation",[-4.026843611032001, 50.32708498163982]],
    [ "Housing Site Roydon",[1.0843328687784535, 52.38074069728072]],
    [ "Lowbury Crescent",[0.06298454999999954, 52.261770549999994]],
    [ "Oakington Village",[0.06446530000000207, 52.25953945]],
    [ "374461306",[-1.7690932, 51.18163810000001]],
    [ "374462930",[-1.7746307554216127, 51.18351069275665]],
    [ "Crane Bank",[-2.902091261820036, 53.19047214038429]],
    [ "Bears Bridge Master Substation",[-2.5636489966181015, 51.425662679606944]],
    [ "Spalding North Substation",[-0.1332731142289259, 52.805785983249514]],
    [ "Shenley Wood Substation",[-0.794744517158515, 52.01884614024181]],
    [ "Hull East Substation",[-0.25469459905223596, 53.74885245898015]],
    [ "Saltend North Substation",[-0.23813945, 53.7427937]],
    [ "Sculcoates A",[-0.3446949231918198, 53.762578936534]],
    [ "Winster Substation",[-1.6398537257432244, 53.12616664801129]],
    [ "Hopton Substation",[-1.6099774328206515, 53.08880639138607]],
    [ "Millclose Substation",[-1.6162020409440607, 53.15773303750228]],
    [ "376930718",[1.173629213642492, 52.445460025922834]],
    [ "Clarendon Street Substation",[-0.3531895254144172, 53.74596007027889]],
    [ "Holderness Substation",[-0.2661037159943519, 53.77393152977376]],
    [ "Saltshouse Road Substation",[-0.29441610529787166, 53.778517090148384]],
    [ "Southwood Road Substation",[-0.42583729651465585, 53.77656403016537]],
    [ "Northern Powergrid",[-0.10756222717653068, 53.58198611518645]],
    [ "Winterbourne Substation",[-2.512887483052562, 51.5243383909329]],
    [ "Gunfleet Sands Substation",[1.1454466775788759, 51.79087621185265]],
    [ "Coach Park Substation",[1.147919767573668, 51.790193872250036]],
    [ "Scotish and Southern Electric PLC",[-1.2358812068249538, 51.749554664809246]],
    [ "Axminster Substation",[-2.995187001669927, 50.77995925168211]],
    [ "Tewkesbury",[-2.1628859598549863, 51.98595353402]],
    [ "381498049",[-3.9293926623469866, 51.036646500775745]],
    [ "381699899",[-1.6903410340804175, 53.83958016595255]],
    [ "Chapel St. Leonards Substation",[0.31599478066876535, 53.225307821448446]],
    [ "382190990",[-1.2040182999999953, 52.359047649999994]],
    [ "382900669",[-1.153841076719293, 50.66203923792097]],
    [ "Darlington West",[-1.588548120175398, 54.52975606445976]],
    [ "Middlemarsh Substation",[0.29269833706812237, 53.16075269913796]],
    [ "384813838",[-1.5181425379533964, 54.519352100536345]],
    [ "Darlington Central",[-1.5405322293706292, 54.52615231980139]],
    [ "Rodley Lane",[-1.6663712486435143, 53.824232033062444]],
    [ "Hatton Gas Compressor Substation",[-0.2349545848531715, 53.27003193278173]],
    [ "Holbeach Substation",[0.029600380575361206, 52.816737754329935]],
    [ "Long Sutton Substation",[0.11376858990837961, 52.778364601962636]],
    [ "386214719",[-0.18896483807813905, 52.91547026861382]],
    [ "Pinner Green Substation",[-0.39702769766506324, 51.59717833186297]],
    [ "Fox Milne",[-0.7067616270576379, 52.04770255427402]],
    [ "Portway Substation",[-0.7616906821865134, 52.044820582086366]],
    [ "Elder Gate Substation",[-0.771532347957702, 52.04068515003747]],
    [ "Childs Way Substation",[-0.7646645514981518, 52.033246475724454]],
    [ "386725343",[0.12022251524157103, 53.04088762305128]],
    [ "Herbert Street",[0.1274489237106108, 52.21591839822768]],
    [ "Electricity North West",[-2.7923690632521105, 54.05364715801498]],
    [ "British Gypsum Substation",[-1.1198605021680688, 52.74137375081634]],
    [ "Union Road Generation",[-2.0261779, 52.51464179999999]],
    [ "Carlton Hill",[-1.5445312944987017, 53.80551798984166]],
    [ "Northern Powergrid",[-1.6670552035739734, 55.0148137601008]],
    [ "Seaton Burn Substation",[-1.632842289572538, 55.06305345446124]],
    [ "Ravensdale Park Substation",[-1.5997558166527994, 53.000471500043325]],
    [ "B.T.T.T.C Yarnfield",[-2.1969676059569982, 52.89689127959384]],
    [ "Ingoldmells Substation",[0.3314650347387925, 53.180096895615726]],
    [ "Little Barford feeder subtation",[-0.2635195586711975, 52.20715672127349]],
    [ "Aldham",[-1.4168573508420559, 53.53339319356698]],
    [ "Wakefield Monckton Road",[-1.5098665659217212, 53.66611030063025]],
    [ "Rocester Substation",[-1.8506836613128728, 52.9500080105758]],
    [ "393069282",[-0.6465011791994615, 51.849283368762386]],
    [ "Sherborne Substation",[-2.5140518411989254, 50.9616792127174]],
    [ "394539806",[0.5944711848744538, 52.447241082401774]],
    [ "Peterborough East Substation",[-0.21708730662219453, 52.58725554108312]],
    [ "Funthams Lane Substation",[-0.1729294973061451, 52.556779303686696]],
    [ "Peterborough North Substation",[-0.26683473735090396, 52.59370219217294]],
    [ "Whittlesey Substation",[-0.13469470184734106, 52.55070084895207]],
    [ "Marjoram Way Substation",[-1.25053405, 50.88119345]],
    [ "Creech St. Michael Substation",[-3.026203775349283, 51.01561926982065]],
    [ "Oakington Road",[0.02941865847055212, 52.24256180691623]],
    [ "396615804",[-2.741432979716966, 51.05797119370596]],
    [ "Embleton ESS",[-3.307411186729386, 54.652407747969875]],
    [ "396980277",[-2.507452782747242, 51.077664956918596]],
    [ "397784722",[-1.8356813751490721, 53.92696187220805]],
    [ "West Moor Park",[-1.041202950000001, 53.53777300000001]],
    [ "National Grid Electricity Distribution",[-5.290457397267457, 50.21346653186064]],
    [ "Cleeve Hill Master Substation",[-2.504122781571254, 51.4899323414607]],
    [ "Heanor Substation",[-1.3626929234140068, 53.02128907402688]],
    [ "401030591",[0.06756381336271923, 51.858263243074774]],
    [ "Varley Street",[-1.6675070469838411, 53.80386014466551]],
    [ "Wedgwood Houses",[-2.16630102514536, 52.95588847281542]],
    [ "402055994",[-2.844807704968279, 53.205181833268625]],
    [ "Clarendon Road",[-1.5600039665319916, 53.80615188784978]],
    [ "Barwell Substation",[-1.3384566221488106, 52.58259944770804]],
    [ "Barking West Substation",[0.10894161865200798, 51.517653338828254]],
    [ "Yockingsgate Substation",[-2.6753273644887785, 52.973305568018134]],
    [ "403155164",[-2.6008281691778463, 50.992327872408104]],
    [ "Cromford Substation",[-1.5648911461782187, 53.102168969534176]],
    [ "Wessington Substation",[-1.432382652781616, 53.117966689412626]],
    [ "Electricity North West",[-2.299182928268261, 53.51239699032836]],
    [ "404549502",[-0.4225326021868924, 51.050124310829176]],
    [ "Cranleigh Substation",[-0.5040560984758333, 51.15109757758613]],
    [ "National Grid Electricity Distribution",[-2.6971780454545398, 50.95469487288823]],
    [ "405505504",[-1.8356221475625905, 51.080036080850455]],
    [ "405749831",[-2.773367543008049, 50.96786162327778]],
    [ "Cotgrave Substation",[-1.030828724251159, 52.92362173293282]],
    [ "Heacham Lodgefields North",[0.4840371370755378, 52.90718348118243]],
    [ "409123869",[-2.6497145060727987, 51.20675764086612]],
    [ "Rookery Avenue Substation",[-1.2513623020056266, 50.87969916929105]],
    [ "411026463",[-2.1521750500000003, 52.97063650000001]],
    [ "Beighton Substation",[-1.3271739132758673, 53.35697078188883]],
    [ "Blackhorse Lane Substation",[-0.0402294304790431, 51.58977600491965]],
    [ "414018918",[0.14280908078690724, 52.67609499617979]],
    [ "415075864",[-1.0151864932075405, 51.575633424987586]],
    [ "UK Power Networks",[0.9453983125841388, 52.684662786978706]],
    [ "419029781",[-2.07911742833075, 50.86506199472374]],
    [ "419029794",[-2.0558313005150457, 50.93011341152006]],
    [ "419089670",[-1.9583143999999997, 50.91373844999999]],
    [ "419105185",[-1.8914789153010476, 50.89129299221882]],
    [ "Percy Road",[-1.587069, 52.28910545]],
    [ "Hallam Street",[-1.7132730847685451, 53.873209038221134]],
    [ "Empingham Substation",[-0.5958143664056574, 52.66211386632375]],
    [ "422875132",[-1.6696600284056518, 51.19276849554682]],
    [ "422986596",[-1.7293125211776148, 50.98385440818989]],
    [ "Montford Place Substation",[-0.1135471934921904, 51.48554173037612]],
    [ "424288992",[-3.4052194619357383, 54.371961962915705]],
    [ "425639395",[-2.41070547661036, 51.04873115425669]],
    [ "425645268",[-2.324590713194874, 51.071384739282905]],
    [ "426022329",[-0.1617107997091774, 51.160062613379445]],
    [ "High Street",[0.06361708738821667, 52.21057443022612]],
    [ "Girton Village",[0.08384554828234858, 52.24008646629085]],
    [ "Brandon Road Substation",[-1.0835484461906626, 50.78410730437225]],
    [ "429340465",[-1.5191754077415804, 51.08414989070006]],
    [ "429674919",[-2.460200457057049, 53.72027485382517]],
    [ "Iveson House",[-1.6103610160234876, 53.83976635631513]],
    [ "West Park",[-1.5933098890691628, 53.838608345433904]],
    [ "Farringford Close",[0.11093285, 52.22440885]],
    [ "Woodcock Street",[-1.4860505454101716, 53.66721683727882]],
    [ "Gough Way No 54",[0.09577674028830878, 52.200140698485015]],
    [ "Eynsham",[-1.3886263900409934, 51.7893517248089]],
    [ "434658242",[-1.343299033924275, 51.23360973587393]],
    [ "434668148",[-1.3989746962045249, 51.182085964427]],
    [ "434747670",[-1.5579868477123857, 51.1595417274158]],
    [ "436398493",[-1.4051135240065615, 51.59200588504857]],
    [ "436460130",[-1.1610081391565676, 51.558006633933616]],
    [ "436460131",[-1.2154137479372895, 51.601766999586616]],
    [ "436882788",[-1.3677260311558783, 51.4957914656184]],
    [ "437354599",[-1.157340720944128, 51.65466552196024]],
    [ "HEDWORTH",[-1.487093906210804, 54.958963659165725]],
    [ "Arncott",[-1.1195781328500227, 51.85798308716577]],
    [ "437743715",[-1.5250941361153396, 54.971936697238775]],
    [ "438513702",[-1.5907423264233225, 51.73227913020228]],
    [ "Harlow Green",[-1.592511701519436, 54.93033708714398]],
    [ "London Road Substation",[-1.5015833096451319, 52.4029550886249]],
    [ "Juliet",[0.11575221971093785, 52.23296999679771]],
    [ "Kiddington",[-1.3708172689546592, 51.9155182043371]],
    [ "439896132",[-1.3208303726812478, 51.96933722670278]],
    [ "UK Power Networks",[1.106287636031774, 52.567714971840196]],
    [ "Attleborough Primary Substation",[1.023839435899832, 52.512680883112154]],
    [ "Carr Hill",[-1.5812132973110844, 54.9438651675929]],
    [ "Wednesfield Substation",[-2.0707267370577322, 52.598837291031714]],
    [ "Constable Street Electricity Substation",[-2.166171545678013, 53.46891321724929]],
    [ "Hereford South Substation",[-2.7065591062051046, 52.029198268507535]],
    [ "Hereford North Substation",[-2.7146152883812475, 52.0743736786398]],
    [ "442550220",[-1.2556306064483382, 51.254765137981714]],
    [ "Hearn Street Substation",[-0.07968185944322072, 51.52241358530497]],
    [ "Wellclose Square Substation",[-0.06625034153163367, 51.50960211300931]],
    [ "Beech Street Substation",[-0.09323468725413919, 51.52062298994943]],
    [ "Boundary Street Electricity Substation",[-2.622468357126214, 53.541704218363805]],
    [ "Team Valley",[-1.6181146971273468, 54.926997958033]],
    [ "Uni Intake Osborne Villas",[-2.6018990140721447, 51.46027227672574]],
    [ "York Street",[-1.4988965659646925, 53.686116455658905]],
    [ "Clarkson Road",[0.10565948832748547, 52.20895308473704]],
    [ "Manor Farm",[0.09582377209352423, 52.1775239511441]],
    [ "Scawby Brook",[-0.5046806287093071, 53.54180550172696]],
    [ "Risley Substation",[-2.5258601534178102, 53.432535405544414]],
    [ "UK Power Networks",[1.2553848290970835, 52.564050342771736]],
    [ "UK Power Networks",[1.211603158323517, 52.52108802827252]],
    [ "Windle Substation",[-2.7400217636636652, 53.46783724558608]],
    [ "445707886",[-2.3902424962939715, 50.97205103263141]],
    [ "Prescot Substation",[-2.8020765828146055, 53.42237176799699]],
    [ "445999769",[-0.6351871846712478, 54.480126964775145]],
    [ "Golborne Substation",[-2.5949758338086384, 53.47471032819433]],
    [ "446412467",[-2.457846878864952, 50.97619443142561]],
    [ "National Grid Electricity Distribution",[-4.482444679934233, 50.34645475836525]],
    [ "New Cross Substation",[-0.059013910157990325, 51.48387903611519]],
    [ "Back Hill Substation",[-0.10994086040701935, 51.522861193702354]],
    [ "City Road Substation",[-0.09588640440107028, 51.530398358280586]],
    [ "Limeburner Lane Substation",[-0.10307334138679453, 51.51483104504215]],
    [ "Paternoster Substation",[-0.1004508293748983, 51.51433764714623]],
    [ "Gateacre Substation",[-2.8405330150031336, 53.39245943617066]],
    [ "Bankside Substation",[-0.09833609149538772, 51.5072276403014]],
    [ "Mansell Street Substation",[-0.07399177034405764, 51.51392181193785]],
    [ "Western Power Distribution",[-2.5607136894190115, 51.02732208649395]],
    [ "Lombard Road Substation",[-0.17702583610885608, 51.470594046543766]],
    [ "Wandsworth Central Substation",[-0.192889148679729, 51.45354224868944]],
    [ "Bengeworth Road Substation",[-0.09681570476230364, 51.46717314563363]],
    [ "Chadwick Road Substation",[-0.07623706246353082, 51.46813731419716]],
    [ "North Cross Road Substation",[-0.07452728280761102, 51.45767314238941]],
    [ "Fisher Street Substation",[-0.11987786108751612, 51.5183743131898]],
    [ "Kingsway Substation",[-0.11864840124527608, 51.515002143420205]],
    [ "South Bank Substation",[-0.1144895273187204, 51.50583946160112]],
    [ "Verney Road Substation",[-0.06479446277973237, 51.48482754311637]],
    [ "Newington House Substation",[-0.09985592189540277, 51.498045454453234]],
    [ "Deptford West Substation",[-0.022718278732042657, 51.48366654085528]],
    [ "Stowage",[-0.020056524952788292, 51.48144799789369]],
    [ "Neckinger Substation",[-0.07370781760901567, 51.49640434235509]],
    [ "Chapel Street Substation",[-0.16661679009697655, 51.519982377608244]],
    [ "Carnaby Street Substation",[-0.1381046229287508, 51.5132655941547]],
    [ "Old Brompton Road Substation",[-0.1946449553885039, 51.48694293113469]],
    [ "Bloomfield Place Substation",[-0.14446812693959746, 51.51158062241744]],
    [ "St Pancras Substation",[-0.13688409860662604, 51.53934530703606]],
    [ "Hatchard Road Substation",[-0.1262539000000015, 51.565802050000016]],
    [ "Islington Substation",[-0.11574327582049991, 51.54996682521299]],
    [ "[Rounton Road TSS]",[-0.01998798053396563, 51.52484875567705]],
    [ "Edward's Lane Substation",[-0.08303329770380065, 51.56194246958022]],
    [ "Walthamstow Tee Point",[-0.02274550375874616, 51.581770768413094]],
    [ "Waterloo Road Substation",[-0.023787761644440367, 51.57030983416203]],
    [ "Lithos Road Substation",[-0.1859453615570758, 51.54880951894579]],
    [ "Moscow Road Substation",[-0.1904803834951705, 51.512635119904644]],
    [ "Brunswick Wharf Substation",[0.0002334483842497767, 51.510083998237754]],
    [ "Stephenson Street Substation",[0.003926090740200799, 51.521143971639]],
    [ "East Ham Tee Point",[0.06400480985428503, 51.5208421583882]],
    [ "Glaucus Street Substation",[-0.017869457361051846, 51.519611733561]],
    [ "Nelson Street Substation",[0.05752109940983469, 51.53257149497213]],
    [ "Axe Street Substation",[0.07980174357485215, 51.53576327382125]],
    [ "Sewell Road Substation",[0.11235419776529339, 51.49758495219367]],
    [ "Hornsey Electricity Substation",[-0.11390952215589711, 51.58489819536726]],
    [ "Fulham Palace Road Substation",[-0.2217428074748378, 51.49059291453731]],
    [ "Gibbons Road Substation",[-0.2566006039200929, 51.546050281547004]],
    [ "Neasden Feeder Station",[-0.25216985000000003, 51.5550239]],
    [ "North Pole Feeder Station",[-0.23279907624467627, 51.5251535550082]],
    [ "Kimberley Road Substation",[-0.2058667000000011, 51.54098975]],
    [ "Brentham New Feeder Station",[-0.29567985509271905, 51.530502565995015]],
    [ "Greenford New Feeder Station",[-0.34006974146050684, 51.540596192290245]],
    [ "Park Royal Feeder Station",[-0.2731481, 51.52620435000001]],
    [ "Holloway Substation",[-0.11314369393478088, 51.55262435740094]],
    [ "448005308",[-0.04807854074060384, 51.485996119512585]],
    [ "Buckfastleigh Substation",[-3.768834214925053, 50.48045594645615]],
    [ "Bramhope",[-1.625338742925208, 53.882524577416426]],
    [ "Orgreave",[-1.372417038528418, 53.37745109127905]],
    [ "Southwater Primary Substation",[-0.34128347705960177, 51.0186220457705]],
    [ "Green Lane Electricity Substation",[-1.3507589864751877, 51.854122530070576]],
    [ "Colindale Grid Substation",[-0.2553704794808977, 51.592341140059666]],
    [ "451736065",[-1.7926436528414584, 51.70379123460571]],
    [ "Portland Place Substation",[-2.1558400658117214, 52.956787146471754]],
    [ "452091277",[-1.6283984345520541, 51.90107897732286]],
    [ "Charlbury",[-1.478149997467674, 51.86254338255686]],
    [ "Prudhoe West",[-1.855792366818571, 54.96657159971654]],
    [ "UK Power Networks",[-0.05024706595425206, 51.60397386313978]],
    [ "453988283",[-1.6814146765030693, 51.70247802229139]],
    [ "454632041",[-2.2208824477909164, 53.80640361938701]],
    [ "Shelford Road Gedling",[-1.0948442570797319, 52.981961731654074]],
    [ "York Road",[-1.5001486079190356, 53.80146571837104]],
    [ "Easthope Substation",[-2.6369302042406417, 52.55202422750131]],
    [ "St Buryan Substation",[-5.62990092648801, 50.08273460318736]],
    [ "458922123",[-2.143999777479128, 51.374799026387365]],
    [ "Foxhills Substation",[-2.4482297546789136, 51.28880120504324]],
    [ "UK Power Networks",[-0.5865849659718289, 51.32284737869448]],
    [ "460017050",[-0.7969813012215733, 51.28074204254485]],
    [ "Bushbury B 11 kV",[-2.1133026333498655, 52.633165027978336]],
    [ "Hall Green Substation",[-1.8427074736234796, 52.44040140602523]],
    [ "Benwell",[-1.6615528854004866, 54.97244690963737]],
    [ "Gardiners Lane Substation",[0.4829054395391692, 51.58832429323124]],
    [ "Swinnow Moor",[-1.6452157500000002, 53.8016614]],
    [ "Clifton Substation",[-2.6125659338961524, 51.455475977607094]],
    [ "Dorchester Street Substation",[-2.35852930585356, 51.377527466366544]],
    [ "Thorpeside Scalford",[-0.8675216919420102, 52.809222510451]],
    [ "Castle Donington Substation",[-1.3617583633395087, 52.84101535790034]],
    [ "Haskayne Substation",[-2.9682696192836047, 53.560193513863155]],
    [ "467624311",[-0.9850418676544683, 52.798043633995405]],
    [ "UK Power Networks",[1.087177157035955, 52.699455873944146]],
    [ "Fossway Substation",[-1.5647120038590456, 54.97974029284182]],
    [ "468331420",[-1.019858246630197, 51.43663711005768]],
    [ "468562717",[-1.657901644753716, 51.58960875192685]],
    [ "Preston East Substation",[-2.658307747144948, 53.790951966084855]],
    [ "Gas Lane Substation",[-2.7666766152544, 51.48472519424693]],
    [ "470380202",[-0.8146953228768947, 52.930532643074066]],
    [ "Ludgate Cellars Substation",[-0.10366822483541584, 51.512604837917344]],
    [ "471291249",[-0.6670667135468207, 50.79806659937277]],
    [ "Mansfield Substation",[-1.1990157542341087, 53.1553136433353]],
    [ "Stoke Bardolph Substation",[-1.0503022517595426, 52.959948741035376]],
    [ "Northern Powergrid",[-1.3122811500000002, 54.6330593]],
    [ "Hartlepool Steel Substation",[-1.2105040000000011, 54.652899700000006]],
    [ "Horningsea Road",[0.17696118091246713, 52.22555943940535]],
    [ "SSE Power Distribution",[-2.4386708374570625, 50.71248169719223]],
    [ "Longbarrow Junction Substation",[-1.7986413794090337, 51.07316065430155]],
    [ "Park Street Substation",[-2.367596758134784, 51.390335479644975]],
    [ "Badgers Copse Substation",[-1.2621715, 50.8711561]],
    [ "Lindsey Avenue",[-1.117387625739323, 53.96271551340777]],
    [ "475297282",[-2.0289373542709885, 54.04688503905644]],
    [ "Senior Training Centre",[-1.5736317302776517, 52.28844068611622]],
    [ "Murley Engineering",[-1.5748393876553595, 52.28974376914635]],
    [ "Oakwood Grove",[-1.5705821976603793, 52.29381868339062]],
    [ "476649812",[-1.4375802499999983, 51.71830055]],
    [ "Uttoxeter Substation",[-1.8573849588653926, 52.908088937382104]],
    [ "Cannock Substation",[-2.018756004460668, 52.68254729866628]],
    [ "Ware Primary",[-0.02664939421097011, 51.81470451883182]],
    [ "Hipswell",[-1.7188437244157064, 54.384196763483104]],
    [ "478512342",[-0.68118929913582, 50.80778583949944]],
    [ "Widnes Substation",[-2.7394681661116187, 53.36065146218356]],
    [ "Hanger Lea Substation",[0.17003479951197215, 51.88265380294088]],
    [ "UK Power Networks",[1.7289240224411282, 52.63621273367233]],
    [ "Midsomer Norton Substation",[-2.4844045997996838, 51.29046033864702]],
    [ "Paulton Substation",[-2.5095701074752763, 51.31036576990571]],
    [ "Animal Research",[0.07860585, 52.230340749999996]],
    [ "Haxby Road",[-1.0752174161887473, 53.97676462392696]],
    [ "Wrightington Primary",[-2.704396399647371, 53.61412844968437]],
    [ "485669396",[-0.2687972333333333, 53.606362]],
    [ "Portsmouth Grid Substation",[-1.1017240839791587, 50.793250842355825]],
    [ "Cairns Road Substation",[-2.603682892160216, 51.47829988375174]],
    [ "Hawley Substation",[-0.7680346102664473, 51.31167650979331]],
    [ "Queen's Park Substation",[-1.4313021014247587, 53.23337253974373]],
    [ "Salutation Substation",[-1.0990138363946147, 52.64647860649767]],
    [ "489141822",[1.2959669521662662, 52.100463475748775]],
    [ "Rugeley Town Substation",[-1.9346974445589826, 52.753913931764465]],
    [ "Shapwick Substation",[-2.83687365, 51.134036449999996]],
    [ "491909055",[-2.1551052999999993, 55.71765809999999]],
    [ "492174855",[-0.8622418071433414, 51.286541284745596]],
    [ "492174861",[-0.9280413708208322, 51.26548227441235]],
    [ "Merrivale Substation",[-4.155760582710785, 50.41350118137397]],
    [ "Constantine College Substation",[-1.0241810538249543, 53.949628533544434]],
    [ "Gulson Road Substation",[-1.4910851814592505, 52.40733531381886]],
    [ "494654144",[-2.9758737467424266, 54.62931800299327]],
    [ "Adam and Eve Street",[0.1318997, 52.20401835]],
    [ "Chafford West 400kV Sealing End Compound",[0.2844212535317909, 51.49169829817805]],
    [ "Chafford East 400kV Sealing End Compound",[0.28647697679082396, 51.491958642001165]],
    [ "Railway Station Substation",[0.15657395000000107, 52.2244413]],
    [ "Water Street",[0.145692, 52.21775750000001]],
    [ "Little Common Primary",[0.43475106630017185, 50.843509394279494]],
    [ "496532244",[-3.453830232469012, 50.80110630293212]],
    [ "Victoria Road",[0.12328075033872024, 52.21440798464902]],
    [ "Walsgrave Substation",[-1.446359682586106, 52.42518196029537]],
    [ "Nagshead Hill Master Substation",[-2.5251688742800327, 51.454035218601994]],
    [ "Blackwall Way Substation",[-0.003927828236159972, 51.507752735363034]],
    [ "Beaumont Leys Substation",[-1.1544882642415486, 52.66919197864578]],
    [ "Grange Road",[0.10519077757913996, 52.201183106568344]],
    [ "SSE",[-1.8796645477682556, 50.76500766636757]],
    [ "Quorn Substation",[-1.17390436014199, 52.74877883514771]],
    [ "508283102",[-2.6606512707001175, 50.949895330062425]],
    [ "Weston Mill Substation",[-4.171563210449634, 50.40219257612243]],
    [ "Bramley",[-1.624151561165411, 53.8066727335373]],
    [ "Paradise Street Substation",[-2.9855915534710147, 53.40197975241722]],
    [ "349 St Neots Road",[-0.0054542, 52.21902275]],
    [ "Carr Lane",[-1.3410408204390953, 53.71380295945032]],
    [ "Seabank Power",[-2.6711774, 51.542383349999994]],
    [ "Shap Substation",[-2.6674249866488196, 54.513907078271835]],
    [ "West Hoathly Primary",[-0.07483535476462345, 51.09163679598291]],
    [ "512870332",[0.6420394272468655, 51.80886619539043]],
    [ "Berkhamsted Primary",[-0.5796713530999926, 51.753120724417414]],
    [ "Whingate",[-1.6029312500000001, 53.79646880000001]],
    [ "Pontrilas Substation",[-2.872860045845946, 51.94223801215663]],
    [ "Buckingham Road Substation",[-0.8182812630080561, 51.8332112614362]],
    [ "Waddesdon Substation",[-0.9396921695410985, 51.85186180850239]],
    [ "Ambergate Substation",[-1.4824531391261333, 53.06287061095605]],
    [ "armly power sub",[-1.6167922098492586, 53.79623772079271]],
    [ "North Drive Substation",[-0.8043781358810683, 51.803123750009156]],
    [ "Exchange Street Substation",[-0.8070598902466319, 51.81518700498469]],
    [ "Newport Pagnell",[-0.725815771348674, 52.075585944428354]],
    [ "Sweet Street",[-1.5560751648447004, 53.78889370229017]],
    [ "Princethorpe Substation",[-1.4146946896883288, 52.3300851192965]],
    [ "519444461",[-2.112906851008555, 51.58636546979238]],
    [ "Hinton Matell 33KV SS",[-1.9911227004481837, 50.853748239208954]],
    [ "520520239",[-3.332074324243628, 54.77344475783928]],
    [ "TBS Lot 6 Huntingdon Road",[0.09138436768969256, 52.22045419774691]],
    [ "521841254",[-3.1561407403506654, 54.838641693342744]],
    [ "Glennamaddy 38kV Substation",[-8.57393223868388, 53.60563999729175]],
    [ "Arnside Substation",[-2.8212371662898703, 54.19638260268611]],
    [ "523430250",[-2.758927966772263, 54.22648542460365]],
    [ "523433701",[-2.7410819972699754, 54.319853401640195]],
    [ "Sedbergh Substation",[-2.5370133164501474, 54.320037811887445]],
    [ "Grassmoor Substation",[-1.3841465333447514, 53.19988550010653]],
    [ "Golders Green Primary Substation",[-0.19476690677780079, 51.56736481997173]],
    [ "Stanmore Grid Substation",[-0.3125905524541911, 51.60967060095208]],
    [ "Palmers Green Grid Substation",[-0.11355725778550077, 51.61560515806031]],
    [ "Finchley Grid Substation",[-0.18422336399355863, 51.600621027895706]],
    [ "Long Preston Substation",[-2.257461824374198, 54.008621463630966]],
    [ "525905404",[-3.007086713343708, 54.774516246020475]],
    [ "Dorrington Road",[-2.7981014033104405, 54.036415988713536]],
    [ "Four Ashes 11kV",[-2.1169006042130682, 52.67611654515689]],
    [ "527508091",[-2.8427574767536616, 54.74148182316175]],
    [ "Erith Substation",[0.17807061003240987, 51.48200234322775]],
    [ "UK Power Networks",[1.0226840533049133, 52.60158109003197]],
    [ "Exeter Substation",[-3.4060335377911937, 50.76652827824851]],
    [ "Elstree Substation",[-0.3421271842790191, 51.65917070993058]],
    [ "Hornsea Offshore Wind Farm Substation",[-0.26071829731119367, 53.658220503543205]],
    [ "Maynard Street Substation",[-2.718699868365599, 53.76904039934114]],
    [ "Pudding Mill Lane Substation",[-0.016512997183252578, 51.53160831030135]],
    [ "533734939",[-2.0090605061588427, 55.77781324444962]],
    [ "Campleshon Road",[-1.0885562500000003, 53.94342185]],
    [ "Hoxton Substation",[-0.07538244460629935, 51.53152221925625]],
    [ "Hackney C",[-0.0407484479529882, 51.558459182205695]],
    [ "Winsford Substation",[-2.4944757440046286, 53.198817834557524]],
    [ "Alexandra Road Substation",[-4.135776557191778, 50.40816026748362]],
    [ "Sherburn",[-1.2202280519223139, 53.79277607364715]],
    [ "Infinity Park Substation",[-1.4636623678837313, 52.879094694242596]],
    [ "Tiverton South Substation",[-3.48640608230087, 50.898750038631206]],
    [ "Slap Up",[0.17865119999999857, 52.263707149999995]],
    [ "Mirfield",[-1.6920464371311292, 53.670470126224274]],
    [ "Bugbrooke Substation",[-0.9988618918520955, 52.21439927960839]],
    [ "UK Power Networks",[0.7598049140062331, 52.94514617729513]],
    [ "546601976",[-0.33312770066073183, 51.292977401516275]],
    [ "Thirkleby Way",[-1.0341493644285351, 53.958563870314904]],
    [ "Hereford Central Substation",[-2.7163474226982607, 52.0592262709752]],
    [ "Huntington New Lane",[-1.0556595266385893, 53.9792109025653]],
    [ "Highworth Avenue",[0.13254122054448095, 52.21980278824376]],
    [ "548450351",[-1.2490009058099203, 50.87651164987526]],
    [ "Bath Road East Substation",[-0.43000687106761953, 51.48025407445649]],
    [ "Lydeard St Lawrence Substation",[-3.238611258418044, 51.079784111614934]],
    [ "550018902",[-0.18537854999999998, 51.240354]],
    [ "Substation",[-3.2364635732218985, 54.66570252459566]],
    [ "St Mawgan Substation",[-5.005022473361276, 50.426934731736225]],
    [ "Lapins Close Embedded",[-1.20034015, 52.96548625]],
    [ "Honiton Heathfield Substation",[-3.2061142555674644, 50.791149339879055]],
    [ "Troughton Farm",[-2.125153774938504, 51.95665246167577]],
    [ "Cape Court",[-1.5956774500000002, 52.2911649]],
    [ "Capel Substation",[-0.31938904193656764, 51.15949281077071]],
    [ "Nether Stowey Substation",[-3.1256951439898333, 51.14796344418474]],
    [ "Holford Switching Station",[-3.196816540096725, 51.168866854796164]],
    [ "Northmoor Solar Farm Substation",[-4.454182298240433, 50.75064588096805]],
    [ "Canworthy Solar Farm Substation",[-4.5150857783201, 50.71182754572902]],
    [ "Fullabrook Wind Farm Substation",[-4.114839975924166, 51.10651957943012]],
    [ "Rawdon",[-1.6914709996047859, 53.85857568328647]],
    [ "Chewton Mendip Substation",[-2.565416185512487, 51.27169164387385]],
    [ "Farndon Road Substation",[-0.9333185395117328, 52.47093095376739]],
    [ "Bruntingthorpe Substation",[-1.1161940034299966, 52.48868884903123]],
    [ "Burton Latimer Substation",[-0.6953054110714239, 52.36382402727973]],
    [ "UK Power Networks",[0.2498912564167467, 51.45126675063672]],
    [ "Lower Cape",[-1.5952883500000001, 52.29064415]],
    [ "Sherford Substation",[-4.052876972993619, 50.36975191915567]],
    [ "551743746",[1.3529781772532377, 52.7584920458616]],
    [ "551744342",[-3.0622115330955086, 53.534240812073996]],
    [ "551863959",[0.13568835552731487, 51.50737953503587]],
    [ "551954092",[-0.2808446989770601, 52.126582967318356]],
    [ "Dallam Substation",[-2.6009828736022, 53.4118843663833]],
    [ "Westhoughton Substation",[-2.5175099359988935, 53.558474282772416]],
    [ "Worfield Substation",[-2.336184892452845, 52.57259279457582]],
    [ "Barton Substation",[-0.43046067542664246, 51.96623609554124]],
    [ "Ampthill Substation",[-0.4790120286975339, 52.01614020433893]],
    [ "Reading Town Substation",[-0.9712399326447069, 51.4615782232535]],
    [ "552904063",[-2.4922812636315115, 51.41166480737676]],
    [ "Keynsham West Substation",[-2.5064294176655477, 51.41388489926512]],
    [ "Milford-on-sea substation",[-1.600747405754326, 50.73884718298237]],
    [ "Petersfield Substation",[-0.9444523854613642, 51.00535567047426]],
    [ "553138643",[-1.6479374484803513, 51.340871554171585]],
    [ "553258760",[-1.8336647617235022, 51.033409934160346]],
    [ "553258768",[-1.8358712846857836, 50.95962398756171]],
    [ "553270747",[-1.8047931794817842, 51.26405176879082]],
    [ "Waterlake Substation",[-2.9508099344653327, 50.847131005594846]],
    [ "Cheddar Substation",[-2.782952729181484, 51.27464515322536]],
    [ "Axbridge Substation",[-2.8160578883308522, 51.28544438912946]],
    [ "553706585",[-2.725864875151688, 50.91549961311515]],
    [ "National Grid",[-2.76112202954519, 50.81160360573523]],
    [ "553712473",[-2.7852299016848723, 50.88159111411978]],
    [ "Blidworth Substation",[-1.1444764890753594, 53.08833146104438]],
    [ "Cotham Substation",[-2.6002856662128866, 51.460206444869875]],
    [ "Harwood",[-2.3934880527605755, 53.59438984621909]],
    [ "Willesley Substation",[-1.4999162000000001, 52.73282405]],
    [ "556435207",[0.20151569729288554, 51.545319182302165]],
    [ "Eastern Garages Substation",[0.4464104478336846, 51.72411161564364]],
    [ "Russell Way Substation",[0.44847522326006073, 51.72207944795071]],
    [ "UK Power Networks",[1.4337871381761143, 52.85618482764208]],
    [ "Scottish and Southern Electricity Networks",[-0.6796910594883029, 51.52275545135879]],
    [ "Gedling Substation",[-1.0907285166722378, 52.98488667259318]],
    [ "Control Tower Substation",[-5.226388099999999, 50.002548]],
    [ "UK Power Networks",[1.2672534379196532, 52.801980629706435]],
    [ "ENWL",[-2.7830069012304723, 54.125080649818756]],
    [ "UK Power Networks",[1.3508087601478382, 52.76421815897906]],
    [ "566812579",[-2.9338957226523736, 54.89111999116338]],
    [ "Farnsfield Substation",[-1.023263764029123, 53.10463772157365]],
    [ "Spring Garden Street",[-2.800510055280636, 54.046692612201596]],
    [ "Purfleet Substation",[0.2576472131402027, 51.47798035395565]],
    [ "568731952",[-1.0440193173119252, 51.30224528188742]],
    [ "Gaydon 11kV",[-1.4854888040840535, 52.20175716923625]],
    [ "Lawford Substation",[-1.288215503930444, 52.37540442355266]],
    [ "Nailstone Substation",[-1.3616652660244113, 52.67634550761344]],
    [ "Osbaston Substation",[-1.3777774964731495, 52.62879531360062]],
    [ "Gresley Substation",[-1.5900455436184564, 52.75831413127083]],
    [ "Hindlow Substation",[-1.85462735258004, 53.21158490791248]],
    [ "Eyam Substation",[-1.6731370000000003, 53.28004515000001]],
    [ "Electricity North West",[-2.388662069150868, 53.77220234371656]],
    [ "573099602",[-0.7259193203169546, 51.078154281738996]],
    [ "Normandy Substation",[-0.6652136595337808, 51.267456246632115]],
    [ "Crookham",[-0.8300489798785139, 51.27351458808714]],
    [ "573503653",[-2.058117779560667, 51.66263847959518]],
    [ "573503699",[-1.9769866386387447, 51.626409411015025]],
    [ "St Cross Substation",[-1.3263848334766255, 51.050906839652036]],
    [ "Maybush",[-1.7240152333234067, 51.413281948402194]],
    [ "574022051",[-1.7898730730973595, 51.33201369911985]],
    [ "Rawcliffe Lane",[-1.106088627240504, 53.97981925807231]],
    [ "National Grid Electricity Distribution",[-5.230770199724027, 50.24673938285254]],
    [ "574119059",[-0.38993182069283994, 50.92755262871825]],
    [ "574119074",[-1.345537553139311, 51.079099233694485]],
    [ "Spring Quarry Primary Substation",[-2.225580555795074, 51.4172899258594]],
    [ "574129664",[-1.0438539380202547, 50.83893759334508]],
    [ "Brockhampton Substation",[-0.9943676826903652, 50.84748744433523]],
    [ "Scottish and Southern Electricity Networks",[-2.2702389887853673, 51.34401458958298]],
    [ "574142981",[-2.2016619586828954, 51.34798278615524]],
    [ "574201161",[-1.9712929036176412, 51.72591971018476]],
    [ "574201176",[-1.9447777604150527, 51.87047661222002]],
    [ "574201184",[-1.8485381847241356, 51.826937838599775]],
    [ "Market Drayton Substation",[-2.481899983847308, 52.90185903622161]],
    [ "Berrington Substation",[-2.6852329260608703, 52.66574381135353]],
    [ "574580839",[-0.9934247339509925, 51.498383773132325]],
    [ "574593984",[-2.385890314613018, 53.88776965149084]],
    [ "574593990",[-2.214069733036886, 53.843457636974975]],
    [ "574607713",[-2.2918299385132777, 54.120194862812994]],
    [ "574607718",[-2.611891747445528, 54.139142373831916]],
    [ "574607732",[-2.4772020891768043, 54.145829681087086]],
    [ "574607735",[-2.5084578788998395, 54.12585235461576]],
    [ "575028406",[-2.282575232595605, 54.06910244915565]],
    [ "Morley",[-1.604845829562069, 53.74349253490518]],
    [ "Stockton",[-2.42177376917759, 52.300656577627336]],
    [ "Leebotwood Substation",[-2.7664019788272167, 52.57838585653075]],
    [ "576003936",[-3.0626222121802558, 52.80861565421246]],
    [ "Ellesmere Substation",[-2.8975779282044427, 52.91165796201332]],
    [ "Tewkesbury Grid Substation",[-2.1298048042026165, 52.00136145790599]],
    [ "Formby Substation",[-3.043105144663241, 53.55490788731668]],
    [ "576068927",[-2.917853026499613, 53.298227999529445]],
    [ "Barnoldswick",[-2.1833308801499753, 53.924292674935955]],
    [ "Leiston B Substation",[1.607895399396468, 52.207556560043614]],
    [ "Kirkby Thore Substation",[-2.552465856520862, 54.633716900841954]],
    [ "Newby Substation",[-2.61222215, 54.58399275]],
    [ "Roosecote",[-3.202743941910012, 54.109805510886304]],
    [ "Robin Rigg West Substation",[-3.5473489299174013, 54.66233819746871]],
    [ "Clare Engineering Substation",[-2.1675090500000005, 52.96457959999999]],
    [ "Ticehurst Primary",[0.4055402679056387, 51.046804145864094]],
    [ "Walney Wind Farm Extension Substation",[-2.880693708388551, 54.03507762395861]],
    [ "Botany Bay Substation",[-2.6155903339442594, 53.66452830202434]],
    [ "Kings Road",[0.10443074572472366, 52.19630282316889]],
    [ "Gloucester Avenue Substation",[0.4659311205062524, 51.718595898411685]],
    [ "Skeldergate",[-1.0841088222324053, 53.9555769637752]],
    [ "586510341",[-0.6192784038923544, 50.80546694082379]],
    [ "Chorley South",[-2.6265350024704497, 53.64809215731035]],
    [ "Stannington Road",[-1.5124868399050415, 53.39852939645108]],
    [ "Ellesmere Port Substation",[-2.8769857531803966, 53.28012592113232]],
    [ "Long Marston Substation",[-1.7824852428232014, 52.1023874769145]],
    [ "Vanbrugh College Substation",[-1.054475146583774, 53.948083465345995]],
    [ "Foss Islands",[-1.0717534404051532, 53.96136701650581]],
    [ "Redeness Street",[-1.0706081807559527, 53.96182390362589]],
    [ "Lindhurst Wind Farm Substation",[-1.1354140969056845, 53.11676427446956]],
    [ "Petsoe Manor Wind Farm Substation",[-0.6690823477199422, 52.13471612781106]],
    [ "Binbrook",[-0.17665830415340658, 53.41977708435191]],
    [ "Thief Lane",[-1.0504409607895628, 53.953489268805136]],
    [ "Fossgate",[-1.0792818499999999, 53.95829445]],
    [ "Nelsons Lane",[-1.105424883051083, 53.9437128749619]],
    [ "Naburn",[-1.0903986488769417, 53.90222418338776]],
    [ "Wentworth College Substation",[-1.0581727240079204, 53.94627028534217]],
    [ "Biology North Substation",[-1.0585524225390204, 53.9482722988654]],
    [ "Darracott Moor Windfarm",[-4.11867605, 50.972118149999986]],
    [ "Old Dalby Lodge Wind Farm",[-1.0313723667219683, 52.81043712114106]],
    [ "Ruskington Substation",[-0.40031528094980084, 53.0445269357035]],
    [ "601367849",[-2.1839608146436498, 51.480478567863265]],
    [ "Calcott Road Master Substation",[-2.5716897847610976, 51.43744205769916]],
    [ "Lincoln Energy From Waste Substation",[-0.6083259503869798, 53.198868363427394]],
    [ "North Hykeham Substation",[-0.5995310063884322, 53.19250977252737]],
    [ "Rookery Lane Lincoln Substation",[-0.561297619175108, 53.21030471284855]],
    [ "602719061",[-0.552601585147436, 53.225267435734594]],
    [ "Doddington park Substation",[-0.615544222143455, 53.20605424242483]],
    [ "Eye Substation",[1.160709910748339, 52.3213259129647]],
    [ "607148609",[-0.45694662851822954, 53.1780128537041]],
    [ "607905351",[-0.9799546771950318, 52.12426369576913]],
    [ "Roade Wind Farm Substation",[-0.8618307000000001, 52.163138800000006]],
    [ "Hoghton Arms",[-2.5626172, 53.714402449999994]],
    [ "Whittleford Substation",[-1.5147702990225294, 52.52645940609724]],
    [ "Diss Substation",[1.1059086542933148, 52.37244286823792]],
    [ "Kemptown Primary",[-0.10484915572914853, 50.816918834679356]],
    [ "Moulsecoomb Grid",[-0.11893770860140317, 50.847423324727934]],
    [ "Ninelands Lane",[-1.3760958071479235, 53.79264419701452]],
    [ "Studholme Avenue Substation",[-2.7052579848061695, 53.73723024007133]],
    [ "Middleton town street",[-1.5454511999999996, 53.75132975]],
    [ "612772120",[-1.045313176625573, 51.199863764315346]],
    [ "Electricity North West",[-2.809029966472318, 54.04515602384489]],
    [ "Allerton Hill",[-1.5388797, 53.83052905]],
    [ "617892980",[-0.9915430502133044, 53.006792726374165]],
    [ "Lordswood Primary",[0.544190681038092, 51.347247358165376]],
    [ "619762644",[-0.73848034643057, 52.99535939327202]],
    [ "619786142",[-0.7986321770638739, 53.07825325175329]],
    [ "Fernwood Substation",[-0.7772179692117965, 53.042236769199214]],
    [ "Kneesworth Road",[-0.0019679833462324194, 52.09765606605595]],
    [ "The Hollies Wind Farm Substation",[0.23742684999999997, 53.1421314]],
    [ "623180694",[-2.9887042249441014, 51.11917869305788]],
    [ "623972317",[-3.0517317234597945, 53.809428956639955]],
    [ "Montys Farm 33kV Solar Pk",[-3.1683513, 51.032698599999996]],
    [ "Halse 33kv Solar Farm",[-3.20300385, 51.04765515]],
    [ "Ashburton Substation",[-3.7439120056473927, 50.51938033296563]],
    [ "Conduit Place Substation",[-2.576936937146365, 51.46730085948107]],
    [ "Spalding Substation",[-0.1541746662301143, 52.795807349961464]],
    [ "627850993",[-1.5334434363520062, 55.027058799360255]],
    [ "Highridge Common Master Substation",[-2.6246650463985968, 51.4119339678641]],
    [ "Carlisle 33kV",[-2.95851505, 54.899715650000005]],
    [ "Burgess Hill Primary",[-0.14066703989550505, 50.95295954685097]],
    [ "Till House Solar Park Substation",[-3.4006997, 50.76288499999999]],
    [ "631531650",[-2.1105101095287973, 55.74122920607848]],
    [ "632304744",[-0.7068378197492706, 51.70564469726796]],
    [ "Wendove Substation",[-0.7436181108312794, 51.75917028086734]],
    [ "Black Fan Substation",[-0.185068003214243, 51.79926775022109]],
    [ "West Potters Bar Substation",[-0.20236102601123912, 51.70729564356465]],
    [ "Southend Substation",[0.70424413125021, 51.541910660509615]],
    [ "Southend West Substation",[0.6859660073982663, 51.560973129885056]],
    [ "Prittlewell Substation",[0.7104518000000001, 51.550234]],
    [ "Thorpe Bay Substation",[0.7643622732331296, 51.53718207812121]],
    [ "Burnham Switching Station",[0.8320779, 51.62313314999999]],
    [ "Holwell Substation",[-0.9198004049401144, 52.77522189208481]],
    [ "New Rendy Farm 33kV Solar",[-3.1992022999999996, 51.00764804999999]],
    [ "Grange Farm 33kV Solar Pk",[-3.2217426999999996, 51.009685099999984]],
    [ "Elvington",[-0.9491274354770494, 53.92447925820834]],
    [ "Ilkeston Substation",[-1.32478392704629, 52.97588356644387]],
    [ "Leeds East 33KV Substation",[-1.5090632855947894, 53.80161857979278]],
    [ "ICI Fibres",[-1.1219870987184553, 53.538329946891146]],
    [ "Wormington Substation",[-1.929726746564547, 52.02820119918626]],
    [ "Patford Bridge Substation",[-1.0552342401930823, 52.29158790689835]],
    [ "Rocksavage Substation",[-2.724965444021476, 53.31497249834766]],
    [ "Sutton Bridge Substation",[0.19101444422428862, 52.75597929565637]],
    [ "Luton North Substation",[-0.4251152898881901, 51.9078514405617]],
    [ "Truro Treyew Road Substation",[-5.065564380570259, 50.25732061528245]],
    [ "Burbo Bank Offshore Substation",[-3.2749565128094975, 53.48615793052228]],
    [ "Middleton Substation",[-2.8854390135538743, 54.0348879877918]],
    [ "West of Duddon Sands Wind Farm Substation",[-2.8901969171994546, 54.02978817299983]],
    [ "Rampion Wind Farm Onshore Substation",[-0.2278112906938329, 50.97489329024562]],
    [ "Race Bank Onshore Connection Substation",[0.20284595164041627, 52.727068543132795]],
    [ "Lincs Wind Farm Substation",[0.20166725633729907, 52.726782424869064]],
    [ "Hedon",[-0.2316683786233692, 53.74714295425919]],
    [ "635932210",[1.1518563652693705, 52.77263313566728]],
    [ "Weston-In-Gordano Substation",[-2.796248863301248, 51.463739249636]],
    [ "Daedalus Converter Station",[-1.1944394845965616, 50.81803564355848]],
    [ "Chilling Lane Substation",[-1.2850681834493072, 50.835624294410714]],
    [ "Kingsteignton Main Substation",[-3.5960111499999994, 50.54587615]],
    [ "Flax Bourton Substation",[-2.6984790246169266, 51.42129765388509]],
    [ "Marsh Barton Power Station Substation",[-3.524098840547773, 50.70644910627555]],
    [ "639516541",[-2.5803411182869866, 50.777992344652745]],
    [ "Wittering Substation",[-0.43930042758090554, 52.611527294000695]],
    [ "640667771",[-1.031725923846658, 53.69333306427418]],
    [ "Sandhill Lane",[-0.8989000569775505, 53.91783159468218]],
    [ "641026120",[-2.2169952099049857, 51.200130003999625]],
    [ "641026132",[-2.176987092299281, 51.189237741896214]],
    [ "Hedon Road Substation",[-0.2450974945706513, 53.74413697243489]],
    [ "King George Dock",[-0.2851457709872818, 53.747141240818436]],
    [ "Westcott Street Substation",[-0.3057570406909533, 53.75966226307612]],
    [ "Alfred Gelder Street Substation",[-0.331331399765041, 53.74506834617355]],
    [ "West Docks Substation",[-0.3746869498891002, 53.73063874997331]],
    [ "Hessle Road Substation",[-0.39618921617740105, 53.72889337122968]],
    [ "PLANGEO",[-0.3757895760577312, 53.740091815732846]],
    [ "National Avenue Substation",[-0.3830727253400305, 53.755163053910486]],
    [ "Filton DC Substation",[-2.5729968166753885, 51.509364688128045]],
    [ "Cambois Converter Station",[-1.540426920683147, 55.146475963332776]],
    [ "643044062",[-1.614050684146569, 55.22818488203376]],
    [ "Skillings Lane Substation",[-0.5743260252364906, 53.72509263013045]],
    [ "Addington Substation",[-0.03798918696034267, 51.34355474728217]],
    [ "University of Sussex",[-0.08566947082202139, 50.86363638942668]],
    [ "Dinder Substation",[-2.5860431022573294, 51.20752460017378]],
    [ "Evercreech Substation",[-2.5102508887239585, 51.14691692786998]],
    [ "Scottish and Southern Electricity Networks",[-2.0002887857099507, 51.34818794210883]],
    [ "Kingsbridge Substation",[-3.7798275606357525, 50.284896723892864]],
    [ "National Grid Electricity Transmission",[-2.6686893000000005, 51.53859685]],
    [ "649100906",[-1.63014975, 52.5821768]],
    [ "Shanklin Substation",[-1.1839304186769886, 50.63419749723431]],
    [ "Scout Moor Substation",[-2.2692099189689685, 53.64402597451823]],
    [ "Hatchlands Farm 33kV Solar",[-3.779916979508692, 50.42090001511245]],
    [ "Lordshill",[-1.4499025391693159, 50.94246755790955]],
    [ "Brackley Town",[-1.1379276179819322, 52.027005714256426]],
    [ "Cullompton Solar Park Substation",[-3.3957537937130833, 50.824811668157494]],
    [ "Trafalgar Road",[0.12739735, 52.21336845000001]],
    [ "Downhams Lane",[0.13553217162931844, 52.22583418104564]],
    [ "COOMBE FARM 33KV SOLAR PARK",[-3.3949147256388685, 50.656811011689676]],
    [ "Liverton Farm Solar Park Substation",[-3.3651483500000006, 50.63763095]],
    [ "655444926",[-0.8264646744761047, 51.11053234469898]],
    [ "Radmarsh Road Lenton Substation",[-1.1807582592480366, 52.949871468539065]],
    [ "Churchill College Flats",[0.09739537763932256, 52.215134415398325]],
    [ "Totton",[-1.4911690744286086, 50.919337545729114]],
    [ "Hillcrest",[-1.5144331500000003, 53.81745980000001]],
    [ "Stannington track sectioning cabin",[-1.661531851933836, 55.12898198511794]],
    [ "Cliffe Way",[-1.5771485210550429, 52.28979526506656]],
    [ "Medway Local",[0.46133099793482374, 51.35224156097263]],
    [ "Durham Rail",[-1.6071238464549875, 54.769680696392584]],
    [ "659804915",[-0.26003555732015154, 51.333822752766686]],
    [ "Leasowe Road",[-1.524146822732218, 53.77242391729896]],
    [ "Sledmere Garth",[-1.4474230678321542, 53.82563732210151]],
    [ "Falmouth Bickland Hill Substation",[-5.10075694801154, 50.15743281404048]],
    [ "Hill Chorlton Substation",[-2.2975466087969454, 52.95200649616998]],
    [ "Culmhead 33kV Solar Park",[-3.1245451641772677, 50.92714982565406]],
    [ "The Ridge Solar Park",[-0.6975622499999999, 52.28183205]],
    [ "Beeley Wood",[-1.5235628846589215, 53.42575229176109]],
    [ "Damhead Creek Substation",[0.6015803818138342, 51.42443846024551]],
    [ "Market Harborough Substation",[-0.90242721171728, 52.479154235063476]],
    [ "Shebbear Substation",[-4.199032795154349, 50.870900988879946]],
    [ "663580546",[-2.9581997217036298, 50.87252023024858]],
    [ "Culmhead Substation",[-3.1119423440204947, 50.938395914514686]],
    [ "Newbury Substation",[-2.4324042918627087, 51.24875139037579]],
    [ "663646797",[-2.399534485763293, 51.20002089417374]],
    [ "663650835",[-2.0211075631440836, 50.76418623470159]],
    [ "663654569",[-1.7289704491500142, 51.517933239395404]],
    [ "663771472",[-2.195666354840471, 51.276141468039285]],
    [ "663771476",[-2.171943568926214, 51.27191212168065]],
    [ "663799249",[-2.914860083026408, 50.91495525072714]],
    [ "663799255",[-2.64521465, 50.80975639999999]],
    [ "663799256",[-2.639919983485989, 50.80780513334052]],
    [ "South Molton Substation",[-3.828169873508306, 51.02631216306476]],
    [ "663820218",[-3.528941965253296, 51.014454242813315]],
    [ "Wiveliscombe Substation",[-3.3090577977098277, 51.035431983614025]],
    [ "Quartley Switching Station",[-3.4488956500000003, 51.01791449999999]],
    [ "Rotol Substationn",[-2.1788645000000004, 51.897677900000005]],
    [ "Whiddon Down Substation",[-3.8678628666082235, 50.717751606680494]],
    [ "Stratton Substation",[-4.506667472199672, 50.82669257683312]],
    [ "Clovelly Substation",[-4.404794645010754, 50.98336010209032]],
    [ "Bideford Main Substation",[-4.199610386100394, 51.011390350829316]],
    [ "Burnham Substation",[-2.9881943408821527, 51.23813558236906]],
    [ "Chew Stoke Primary Substation",[-2.6380743564976536, 51.37216032119986]],
    [ "Congresbury Substation",[-2.8029425115716275, 51.375452421531904]],
    [ "Churchill Gate Primary Substation",[-2.7936568161260644, 51.33790752640167]],
    [ "Quatt Substation",[-2.3730614232879557, 52.480746709752744]],
    [ "Langage Substation",[-4.017795204183333, 50.385328368471086]],
    [ "665194060",[-4.242085981977932, 50.442803767155986]],
    [ "Lifton Substation",[-4.270609136281356, 50.643031496158656]],
    [ "665201047",[-4.161352422959448, 50.55293966942818]],
    [ "Hemyock Substation",[-3.2516762157260817, 50.91866093227849]],
    [ "Bovey Tracey Substation",[-3.679835475920836, 50.59199131976561]],
    [ "665520626",[-2.0033056334981696, 51.078076698943974]],
    [ "665572153",[-0.6255011499999998, 51.523458250000004]],
    [ "665797448",[-1.903130437066767, 51.13307166866795]],
    [ "Ramsbury S/S",[-1.6069178399144555, 51.447002321537894]],
    [ "665999581",[-2.096506233850272, 51.51967680833238]],
    [ "Scottish and Southern Electricity Networks",[-2.010406936808064, 51.5231908648624]],
    [ "Berkeley Substation",[-2.468144873799285, 51.70176756639572]],
    [ "Trent Lane Substation",[-1.3453754287519755, 52.850271581644236]],
    [ "Cowfold Primary",[-0.24049966533392478, 50.98967522138543]],
    [ "Easton Substation",[-0.6075488877942372, 52.82769819229087]],
    [ "Market Overton Substation",[-0.6665294830828509, 52.75872155404471]],
    [ "Exton Substation",[-0.6524770137746994, 52.68595714179303]],
    [ "Uppingahm Substation",[-0.7189938884141055, 52.595416781287845]],
    [ "J McKintyre Leen Gate Lenton",[-1.181569679500478, 52.94458182602207]],
    [ "Bravo",[0.12320358602074206, 52.23553337300453]],
    [ "Francis Court Farm Substation",[-3.4675913517514587, 50.7720224831001]],
    [ "West Deeping Substation",[-0.3559667688523754, 52.671597925452225]],
    [ "Goudhurst Primary",[0.4705737860235706, 51.11345395748025]],
    [ "669088818",[0.47245904999999994, 50.98650205]],
    [ "669088825",[0.5074256999999999, 51.0518273]],
    [ "Wittersham Primary",[0.7272112499999999, 51.017738599999994]],
    [ "Four Elms Primary",[0.11182195570414377, 51.22472737094501]],
    [ "Edenbridge Primary",[0.05366364999999923, 51.21531459999999]],
    [ "Forest Row Substation",[0.03579219809539496, 51.097765430156244]],
    [ "Broadoak Primary & Switching Station",[0.29639682106569937, 50.98324195281911]],
    [ "Crowborough Switching Station",[0.18044090524681697, 51.03933171656132]],
    [ "Hastings Local",[0.5904359577847783, 50.86787297619236]],
    [ "669394014",[0.29383194999999995, 51.28414479999999]],
    [ "Polegate Grid",[0.2795357851041136, 50.81731708015358]],
    [ "Little Cheyne Court Substation",[0.8224558136426334, 50.9630172246131]],
    [ "Mountfield Primary",[0.45385729534651387, 50.94891595986665]],
    [ "Buxted Primary",[0.12736301952639395, 50.98856590757879]],
    [ "669406050",[0.3230114048440585, 51.066678267061356]],
    [ "Jarvis Brook Primary",[0.19317469221929795, 51.04240280157769]],
    [ "Horsebridge Primary",[0.24093199348983213, 50.87978456921693]],
    [ "Horam Primary",[0.24487230000000004, 50.93140090000001]],
    [ "Garlenick Wind Farm Substation",[-4.884232974264491, 50.30476879300903]],
    [ "St Breock Windfarm",[-4.856995112808176, 50.4852519208035]],
    [ "Lightweight Aggregates Primary",[1.219821738643737, 51.209114752233525]],
    [ "Tenterden Primary (substation)",[0.6865465082992038, 51.0663863390244]],
    [ "Little Chart Primary",[0.7785994968844304, 51.1794844992674]],
    [ "Hurstpierpoint Primary",[-0.17352050000000002, 50.93254365]],
    [ "Warwick Rd",[0.10989124999999998, 52.221705150000005]],
    [ "Blackthorn Close",[0.1345242970414336, 52.223861166857176]],
    [ "670929566",[0.2176564119153243, 51.45233908997461]],
    [ "670929573",[-0.18904035169276417, 51.359285227748785]],
    [ "Dunmow Substation",[0.3704618578584749, 51.867879166154125]],
    [ "White Roding Substation",[0.274022414442904, 51.79983202919526]],
    [ "Takeley Substation",[0.2610448922853398, 51.87211740996836]],
    [ "Skillington Substation",[-0.6765790339137588, 52.81193123490424]],
    [ "670991698",[-0.7128924157513976, 53.167386929682216]],
    [ "Tuxford Substation",[-0.9022871158983043, 53.234260686290305]],
    [ "Kingston Grid Substation",[-0.30609202819079007, 51.41457696724258]],
    [ "UK Power Networks",[-0.33833722677174266, 51.23362084591208]],
    [ "Colley Lane SW",[-0.2226606537073548, 51.24561632903948]],
    [ "671162108",[-0.7825149070575902, 51.41104949431676]],
    [ "Eggborough Glass",[-1.1359252601955045, 53.709476836770584]],
    [ "671335097",[-1.9112455709932041, 52.751666498522184]],
    [ "Rugeley Substation",[-1.9123624494740126, 52.753719159668265]],
    [ "Halesfield Substation",[-2.4331464499999997, 52.6410038]],
    [ "Thorpe Marsh 275",[-1.096571742459582, 53.575466252147436]],
    [ "Ferrybridge A Substation",[-1.2717479468362525, 53.719444173928245]],
    [ "671616610",[-1.272721162913871, 53.71976451384139]],
    [ "Fryston Lane",[-1.287020872029515, 53.71697738711608]],
    [ "671616620",[-1.2843341999999998, 53.72297815]],
    [ "Withdean Primary",[-0.15605810409061582, 50.853899776843065]],
    [ "Elton Substation",[-2.442884600794893, 51.82107170085949]],
    [ "Bratton Fleming Substation",[-3.936923787604512, 51.12417094907253]],
    [ "Milehouse Substation",[-4.158192259827602, 50.38352157755114]],
    [ "674276696",[-1.0026928901229688, 51.6429555287723]],
    [ "674293982",[-0.7175893936250185, 51.43923029369404]],
    [ "674416889",[-0.9097590273995557, 51.695664239627334]],
    [ "674424129",[-0.5275174331488749, 51.86634009579177]],
    [ "Tunbridge Wells Town",[0.26988849349137745, 51.13975545836381]],
    [ "Rusthall Primary",[0.22890439087936829, 51.1409085021325]],
    [ "Penshurst Primary",[0.17661590000000002, 51.15279205]],
    [ "UK Power Networks",[1.40107465, 52.77315425]],
    [ "Stoneshill Solar Park Substation",[-3.38463035, 50.87892455]],
    [ "DWS Primary",[0.1056633, 51.04284165000001]],
    [ "Crowborough Town Primary",[0.1680853, 51.04810444999999]],
    [ "Tonbridge Town Primary",[0.2718312297649395, 51.197373881633176]],
    [ "675162940",[0.10924677614017624, 52.83811544640568]],
    [ "South Reston",[0.11304974595133332, 53.323105541254975]],
    [ "675162947",[-0.12457917489527531, 53.01196967412651]],
    [ "Tattershall Substation",[-0.22393747173589848, 53.08643614961869]],
    [ "UK Power Networks",[-0.20239896941352664, 51.881443838016445]],
    [ "Tonbridge East Primary",[0.2941372739396426, 51.205908985337715]],
    [ "Paddock Wood Primary",[0.39257817504126685, 51.17739916814069]],
    [ "Whitchurch Substation",[-2.7071555137938597, 52.98047871969543]],
    [ "Marden Primary",[0.4908593378405281, 51.17581891785913]],
    [ "Shawbury Substation",[-2.679007799999999, 52.79959765]],
    [ "Wem East Substation",[-2.7089192660030754, 52.861280190941265]],
    [ "675190961",[-2.5527078346079546, 53.07699678758167]],
    [ "675190969",[-2.635908767971309, 53.033211512534166]],
    [ "675210100",[-2.7535851819906845, 53.06073192315442]],
    [ "675214373",[-2.7887334028376225, 53.10054234057184]],
    [ "Prees Substation",[-2.674117625402654, 52.89746046920649]],
    [ "675243132",[-3.0546116195963764, 52.84787094812064]],
    [ "East Grinstead Primary",[-0.014649391977266163, 51.12963116159915]],
    [ "675373874",[-2.5899980000000005, 53.00047754999999]],
    [ "675375722",[-2.4891489829341165, 52.9959604557879]],
    [ "675380378",[-2.4856856075240183, 53.05653987383844]],
    [ "Sundridge Primary",[0.13387752101438224, 51.279892637929535]],
    [ "Chelmsford North Substation",[0.47236682484457615, 51.74476108936071]],
    [ "St Paul's Substation",[-2.5833380446703575, 51.45918000312641]],
    [ "675638349",[-2.7235230991772186, 53.180652683334856]],
    [ "North Sevenoaks Primary",[0.18656875750699836, 51.287078863807395]],
    [ "Kingsley Holt Substation",[-1.9693827926754208, 53.009053455024535]],
    [ "Cauldon Cement Substation",[-1.8766336759884168, 53.04368999564715]],
    [ "Cauldon Substation",[-1.8862836133852574, 53.04105502094004]],
    [ "Moneystone Quarry Substation",[-1.9336339762691594, 53.008235577769284]],
    [ "National Grid Electricity Distribution",[-1.6229622022196943, 52.81703593566959]],
    [ "675772323",[-2.670471, 53.15295735]],
    [ "Renwick Road Substation",[0.11666167532933173, 51.52761595647827]],
    [ "East End Thames View IDNO Substation",[0.11667072486884454, 51.52723405594691]],
    [ "Oxted Primary",[0.0038765250304910602, 51.25529727053507]],
    [ "UK Power Networks",[-0.23856319749917193, 51.764770858494714]],
    [ "Bicester Substation",[-1.1528065322619652, 51.8930271282158]],
    [ "Bicester North Substation",[-1.1334393286059372, 51.90907044531582]],
    [ "Nutfield Primary",[-0.15012041592950875, 51.23506095576836]],
    [ "Langage Substation",[-4.012721053465828, 50.3890538259677]],
    [ "Marshall Meadows",[-2.0277264071977954, 55.80111160582631]],
    [ "Broxell Close",[-1.6000314376845972, 52.29186408009076]],
    [ "Bixhead Substation",[-2.5920704325285717, 51.79563229404827]],
    [ "679299446",[0.6127107000000006, 51.59256735]],
    [ "Temple Park",[-1.4196293590723612, 54.96245425025892]],
    [ "Clarence Street",[-1.5300194802538543, 52.280751458070675]],
    [ "Boxted Substation",[0.6658780552678887, 52.130382970048]],
    [ "680896807",[-0.009579644942497023, 51.61936686323619]],
    [ "Uttoxeter Church Street Substation",[-1.8609725278137914, 52.899435847201254]],
    [ "Stourton",[-1.4946973207649346, 53.764902365195354]],
    [ "681861445",[-1.1794356514783049, 50.83626663574088]],
    [ "681875115",[-1.4402052328757482, 50.8983421600225]],
    [ "Central Welwyn Primary",[-0.1953512519696941, 51.80216533515846]],
    [ "682275240",[1.5407170794498752, 52.45341922886602]],
    [ "682301564",[0.8312524047396626, 52.907110456032605]],
    [ "Brighouse",[-1.7671413247828234, 53.69926496726804]],
    [ "684373101",[0.7420373712569558, 52.04524549569108]],
    [ "684708626",[-0.10205492102191022, 51.58942752720246]],
    [ "Oakwood Lane",[-1.4903666951846348, 53.81583984150773]],
    [ "687539300",[-2.093952355763167, 51.37513208867913]],
    [ "Nevis Way Subsation",[0.4762435209883057, 51.70040608771061]],
    [ "Pepys Way",[0.08643791303457679, 52.23407862336923]],
    [ "Newport Substation",[-1.2920867681256605, 50.703449900434414]],
    [ "Ventnor Substation",[-1.2080670808614051, 50.5975560304994]],
    [ "688028184",[-1.145947536453081, 50.69207635930267]],
    [ "688030955",[-1.1573923952002547, 50.72379793657646]],
    [ "Newlyn Substation",[-5.561800450429865, 50.10944198324719]],
    [ "Penzance Heamoor Substation",[-5.553355775942231, 50.13196774003234]],
    [ "688047343",[-5.372154568671224, 50.12606331515427]],
    [ "St Keverne Substation",[-5.127832802178472, 50.05306778382923]],
    [ "Marazion Substation",[-5.464153570331889, 50.136646682234264]],
    [ "Constantine Substation",[-5.185110299657419, 50.12420852818525]],
    [ "Penryn Substation",[-5.111109747250828, 50.17789460127323]],
    [ "Falmouth Docks Substation",[-5.056999347979536, 50.150871136550414]],
    [ "Roseland Substation",[-4.978472508011704, 50.19821777902899]],
    [ "Probus Substation",[-4.951998052736724, 50.2981001686009]],
    [ "Drinnick Substation",[-4.875026544107997, 50.362296918960446]],
    [ "Lanner Substation",[-5.197231010460003, 50.20944202157846]],
    [ "Twelveheads Substation",[-5.134564847107088, 50.23693043384834]],
    [ "Devoran Substation",[-5.100402809159952, 50.21824359422976]],
    [ "Truro Shortlanesend Substation",[-5.070046612809945, 50.278067488937054]],
    [ "Broadweir Primary Substation",[-2.5829058454287854, 51.45856567192245]],
    [ "St Weonards Substation",[-2.728823793161474, 51.90956501948937]],
    [ "Lanreath Substation",[-4.584989175422786, 50.37823079191651]],
    [ "Mevagissey Substation",[-4.799760094545845, 50.28551830408656]],
    [ "Sawles Road Substation",[-4.790909597195024, 50.3318574488952]],
    [ "Callington Substation",[-4.3200950665420645, 50.50422518578373]],
    [ "Rawlinson Road",[-1.51924745, 52.29764635000001]],
    [ "Bugle Substation",[-4.794699789193996, 50.39980187397838]],
    [ "National Grid Electricity Distribution",[-4.734260529807254, 50.47382271568771]],
    [ "Dairy Crest Davidstow Substation",[-4.635290450873458, 50.65004775221252]],
    [ "Polzeath Substation",[-4.867335858812262, 50.56980283164139]],
    [ "National Grid Electricity Distribution",[-4.952164395536988, 50.51357865809117]],
    [ "Laneast Substation",[-4.482639604517329, 50.61775552429514]],
    [ "Saltash Whity Cross Substation",[-4.261349309531469, 50.4220011354084]],
    [ "Torpoint Town Substation",[-4.214252522350899, 50.37697125872127]],
    [ "Southway Substation",[-4.120900349999999, 50.43247525]],
    [ "Torpoint Antony Substation",[-4.258563489297944, 50.365672443795766]],
    [ "Saltash Dunheved Road Substation",[-4.217586963039269, 50.40813476378683]],
    [ "688864045",[-4.40829332542667, 50.70375105241623]],
    [ "National Grid Electricity Distribution Plc",[-4.42202855, 50.712078950000006]],
    [ "Roadford Substation",[-4.2382640752773, 50.68759538594586]],
    [ "Ashwater Substation",[-4.290112425915473, 50.74222134860005]],
    [ "Tamar Pumping Station Substation",[-4.208225194208046, 50.52962528693976]],
    [ "Yelverton Substation",[-4.081506849417368, 50.494522192548885]],
    [ "Morwenstow Substation",[-4.504234164304812, 50.90273822398759]],
    [ "Tinkers Cross Substation",[-3.926583589728798, 50.88096294593705]],
    [ "National Grid Electricity Distribution",[-4.0570473452545, 50.92906406189472]],
    [ "Lapford Substation",[-3.8097296986559024, 50.85256668939429]],
    [ "Winslake Foot Substation",[-3.674831991361185, 50.73049068705704]],
    [ "Witheridge Substation",[-3.7054927308211214, 50.90360705982381]],
    [ "Mortonhampstead Substation",[-3.7663705812028065, 50.657227093943526]],
    [ "Ivybridge Substation",[-3.9259058647388105, 50.38651716297951]],
    [ "Sharpington Close Substation",[0.4774046442446757, 51.7015496940895]],
    [ "East Anglia ONE Substation",[1.0566830196897692, 52.07393150526929]],
    [ "Chalton ATFS",[-0.49220327492978844, 51.925982251327994]],
    [ "Northern Powergrid",[-1.4400032336531738, 53.8221063489414]],
    [ "Hopkins Close",[0.13770824999999998, 52.22715435]],
    [ "Grange (The)",[-1.494175377903963, 52.310390004790094]],
    [ "Royds Lane",[-1.4754259768176319, 53.746410128442]],
    [ "Ellifoot Lane Substation",[-0.13932318553884734, 53.738492346765355]],
    [ "Cloverfield",[-2.637638050190196, 53.69903167470864]],
    [ "Hinstock Substation",[-2.450992445554328, 52.836711364434265]],
    [ "High Offley Substation",[-2.320545085889091, 52.831943461151745]],
    [ "Hornsea Two Offshore Wind Farm Substation",[-0.26043622396487554, 53.656813352790294]],
    [ "Severus Hill",[-1.118184961947921, 53.95944869322061]],
    [ "Bevington 11kV",[-1.9434736499999998, 52.17346164999999]],
    [ "Sandhurst Substation",[-0.8009856830483526, 51.34118596179377]],
    [ "Singlewell Feeder Station",[0.3935133481945636, 51.40465566253148]],
    [ "705313188",[-0.7591667353049301, 51.582360443906964]],
    [ "Loscoe Switching Station",[-1.3693954662819727, 53.030313767669924]],
    [ "Witches Solar Park",[-2.6754721146579445, 51.97082097202958]],
    [ "Maybush",[-1.4548403838707562, 50.932617443790306]],
    [ "708237176",[-1.1065816137995614, 51.389856739439026]],
    [ "SELBY Rd",[-1.4668633204934591, 53.79654568918827]],
    [ "709903481",[-1.11395225, 53.50086175]],
    [ "710028722",[-1.521788643155848, 51.50176283556497]],
    [ "Sevington Primary",[0.8907614859996754, 51.128348590632456]],
    [ "713301519",[-0.9755793734675946, 51.99591180945596]],
    [ "JLR Module 4",[-2.1429066132642443, 52.63042278921206]],
    [ "i54 Business Park Substation",[-2.142044391851502, 52.630428417196136]],
    [ "Scottish Power",[-2.971346774075572, 53.3308265913329]],
    [ "Scottish Power",[-2.985617515472908, 53.3251095209974]],
    [ "Scottish Power",[-2.986784727723934, 53.33068945304725]],
    [ "Scottish Power",[-2.9757511214888326, 53.33573551214077]],
    [ "Littlewood Farm PV Substation",[-1.2034790003273443, 53.18063754938737]],
    [ "Harrowgate Hill",[-1.5464636337248954, 54.5501843080781]],
    [ "725967773",[-2.68757285, 52.03683895]],
    [ "Garlic Row",[0.14848240000000001, 52.214394899999995]],
    [ "Garston Substation",[-2.8954017948269426, 53.353939252042096]],
    [ "Mitcheldean Substation",[-2.498964790929734, 51.85799128745811]],
    [ "Kimberlow Lane",[-1.0300713180009842, 53.94988235094043]],
    [ "Tranby Avenue",[-1.0299565916055262, 53.954061393983864]],
    [ "Heslington South",[-1.0424362328519567, 53.94323757142126]],
    [ "Sittingbourne Town Primary",[0.7537369312983876, 51.34151659284828]],
    [ "Stoke Bishop Substation",[-2.6322559992678087, 51.48308347062369]],
    [ "Urlay Nook",[-1.378550279484643, 54.5243006417508]],
    [ "Aycliffe Forrest",[-1.5659640141177178, 54.586789174229736]],
    [ "Wellington Street Substation",[-1.6527545981571685, 52.802409666358365]],
    [ "Peterchurch Substation",[-2.9544560391213204, 52.0420779397342]],
    [ "737630126",[-1.3320000500000002, 54.1912785]],
    [ "Tring Primary",[-0.6663621993458786, 51.800464137024505]],
    [ "Hillcrest",[0.025225526254850507, 52.24930013362595]],
    [ "Kingsnorth Grid",[0.5952599108087301, 51.421748578732455]],
    [ "Abbey Rd",[-1.6284059837719385, 53.82797170296902]],
    [ "East Leake Substation",[-1.1815356552673382, 52.843415449703734]],
    [ "National Grid Electricity Distribution",[-5.323215578064066, 50.22330700639849]],
    [ "Ditton Walk",[0.15494946624801859, 52.213788196473395]],
    [ "Bilson",[-2.510126553636717, 51.82235439224332]],
    [ "Bristol Airport Primary Substation",[-2.706603700478826, 51.38021824510074]],
    [ "Fallowfield",[0.15053966444405467, 52.21955796709178]],
    [ "Whipton Substation",[-3.4990515193837317, 50.73411153984765]],
    [ "Stewarts Road Substation",[-0.1418496324918213, 51.47594025870499]],
    [ "SPEN",[-3.062253264270342, 53.53431644669874]],
    [ "NPG",[-1.1151517214787765, 53.50165605346969]],
    [ "NPG",[-1.010686469160638, 53.4900387714948]],
    [ "BS",[-0.6206416678300234, 53.581657243609925]],
    [ "Northern Powergrid",[-0.06922430000000121, 53.56148515000001]],
    [ "754720544",[1.354089520480055, 52.76076933095511]],
    [ "754720545",[1.3678108300351237, 52.75331808369385]],
    [ "Private Network",[-0.9793699935422365, 51.98694173462146]],
    [ "Northern Powergrid",[-0.43038105757420797, 53.99462661600187]],
    [ "754723333",[-0.4303761336304084, 53.99466880943501]],
    [ "NPG and Natural Power",[-0.7978185377609264, 53.659337827531544]],
    [ "NP",[-0.798069641791062, 53.65937675758662]],
    [ "Avonmouth Windfarm Substation",[-2.6751682352276753, 51.534757347687446]],
    [ "Cromwell Curve",[-0.18720114972683494, 51.495986772976785]],
    [ "Athelstan Road Substation",[-3.522091702980949, 50.72371447816231]],
    [ "White City Substation",[-0.2249941205767017, 51.515233634476154]],
    [ "Staple Hill Switching Station",[-2.492938631205533, 51.48436465784877]],
    [ "Mount Road",[-1.402598379458007, 54.896153743711494]],
    [ "Bexhill Town Primary",[0.4885996815355775, 50.842205259663615]],
    [ "Murcott Road",[-1.5239907492178582, 52.267126149916585]],
    [ "Avonmouth Substation",[-2.680545603303265, 51.50218821095536]],
    [ "New Droppingwell Road",[-1.416699714473781, 53.4292701793699]],
    [ "Arnold Substation",[-1.1241444611811822, 53.007613250738515]],
    [ "Hellifield Substation",[-2.2241936577102903, 54.00557779259324]],
    [ "Knostrop",[-1.4952207098886319, 53.78175129175515]],
    [ "Calshot Street Substation",[-0.11682385549426501, 51.531758264293416]],
    [ "East Hertford Primary",[-0.07250162822689052, 51.80078234143665]],
    [ "Mead Lane Substation",[-2.537771062400714, 51.714225376905326]],
    [ "SSE",[-0.2522124136726118, 51.517335075281025]],
    [ "Pitstone Substation",[-0.6481071052798291, 51.83032559959822]],
    [ "Garrod Street Substation",[0.0901422, 52.21714285]],
    [ "Offerton Substation",[-1.4568014047235065, 54.88246351911781]],
    [ "Crowland Substation",[-0.16397565097692976, 52.6833650047003]],
    [ "BALCARRES AVE",[-2.61763655, 53.5567559]],
    [ "772963799",[-2.621338874448235, 53.55383315311647]],
    [ "Burton Service Area",[-2.7346653326530137, 54.17944797240762]],
    [ "773892510",[-0.6683155611803469, 53.571487848497085]],
    [ "773894375",[-1.2589906502198784, 53.48519917571309]],
    [ "Mallard Way Substation",[-1.114203688196095, 53.500602588006764]],
    [ "774629952",[-1.3734878223507467, 50.898819496568755]],
    [ "Tansterne Biomass",[-0.1401152812112864, 53.81977956518045]],
    [ "TWIN BRIDGE WF",[-0.9193510384014862, 53.62114343711042]],
    [ "Folkestone Converter Station",[1.1448358115066304, 51.09844688386249]],
    [ "Wymeswold Solar Farm",[-1.0720373266404293, 52.802360529330116]],
    [ "Goole Fields 1 WF",[-0.8721080814358722, 53.65759354860895]],
    [ "Yockerton Substation",[-2.1803588, 52.956313300000005]],
    [ "Thorpe Marsh",[-1.0870581171637976, 53.57920839428199]],
    [ "Belmont Avenue",[-1.1396584173736268, 53.51144704840177]],
    [ "Jarrat Street",[-1.1303182552001385, 53.517601402909314]],
    [ "West Melton Substation",[-1.3791909913001128, 53.50029916794274]],
    [ "Ecclesfield",[-1.4521255662926016, 53.44436823094003]],
    [ "781416468",[-0.5232855986780531, 51.43468592362364]],
    [ "Skelton Grange",[-1.4938931121751546, 53.77852584063916]],
    [ "785728733",[-0.5909173504788967, 53.567255826279805]],
    [ "785730494",[-1.085656617191061, 53.57876060081146]],
    [ "785730495",[-1.0889194184956563, 53.57861789229864]],
    [ "Epworth",[-0.8289458000000001, 53.52860417845922]],
    [ "786167549",[-0.7871061029652859, 50.74160701248676]],
    [ "786252801",[-0.7965656909763028, 53.740605642008354]],
    [ "Riding Mill Pumps",[-1.9587075377483616, 54.951299409041305]],
    [ "788566576",[-1.28947, 52.3614117]],
    [ "Saxon Mill",[-1.5751853356745338, 52.2999280748386]],
    [ "West Wylam",[-1.83848761823723, 54.964909445194756]],
    [ "Market Street Warwick",[-1.591443000000001, 52.2809824]],
    [ "Woodloes Avenue No. 2",[-1.5858230499999983, 52.295660350000006]],
    [ "792855602",[-1.2914648803168387, 52.362069753769255]],
    [ "Michelin Newstead Substation",[-2.1679441499999985, 52.96466335]],
    [ "policemans Lane Sewage S/S",[-2.0459268893865326, 50.73791656654691]],
    [ "Norton Lees Substation",[-1.4715696914905554, 53.34858558512874]],
    [ "796662221",[-1.139429175097768, 53.52664520988373]],
    [ "796924800",[0.10171043699853363, 51.49068711436826]],
    [ "Limmo Peninsula Substation",[0.007475583471286044, 51.51036152636626]],
    [ "Greenwich Peninsula Substation",[0.007154189176711776, 51.49500685974563]],
    [ "Waterworks Lane",[-1.5620287000000002, 52.2918516]],
    [ "PORTSLADE 33KV",[-0.21832418156302486, 50.83064357423504]],
    [ "Hangleton Substation",[-0.19790858128535227, 50.84429641952929]],
    [ "Brighton Local Substation",[-0.14106195344703398, 50.82524872517007]],
    [ "Brighton Town Substation",[-0.1411033070726648, 50.825404504661606]],
    [ "Queens Park primary substation",[-0.12460674432128102, 50.8292993142715]],
    [ "SSE",[-1.6066206, 50.8028088]],
    [ "Rottingdean Primary",[-0.0627152839116477, 50.81510990881944]],
    [ "Southwick Substation",[-0.2248515749501377, 50.838604074718425]],
    [ "North Shoreham Substation",[-0.28306984451618356, 50.83967798621007]],
    [ "Sompting Substation",[-0.3374468023188116, 50.833836694498046]],
    [ "Eastbourne Grid",[0.2971255046951954, 50.78307332308584]],
    [ "Beechwood Rd Sw Ho Master",[-2.52795179826256, 51.480479934024366]],
    [ "Regents Court Warwick",[-1.5971220999999998, 52.28635834999999]],
    [ "Lower Chadnor Substation",[-2.8425247974180414, 52.169206932465386]],
    [ "Creekmoor Local S/S",[-1.9934048789962737, 50.74671023938011]],
    [ "Creekmoor",[-1.9933717397346042, 50.74655712924873]],
    [ "Pine Springs Road No2 S/S",[-2.0100210499999998, 50.7534569]],
    [ "14 Bullfinch Close",[-2.0049206750231194, 50.750426397463045]],
    [ "Thamesmere Substation",[0.11158307878179351, 51.507765214806035]],
    [ "Easton-in-Gordano Substation",[-2.6920355395639968, 51.471180311177235]],
    [ "Norfolk Park",[-1.448245252923935, 53.366254789378395]],
    [ "804211174",[-1.4272954865608198, 53.39092812665612]],
    [ "Redrow Archery Road Substation",[-1.3731483183138433, 50.888072753271786]],
    [ "Waldergrave Close Substation",[-1.378319807761274, 50.888507680054865]],
    [ "Ellin Street",[-1.4749486528192375, 53.37338730978731]],
    [ "Stoke Street",[-1.4395412385897723, 53.390974934476944]],
    [ "Bernard Road",[-1.4498713528054858, 53.38645942734097]],
    [ "Bellhouse Road",[-1.4439537781988607, 53.42609744486182]],
    [ "Barnsley Road",[-1.4537687740585779, 53.4110933846583]],
    [ "Saxon Road",[-1.4761529089701784, 53.35952921457222]],
    [ "Gleadless Valley",[-1.4432812563196928, 53.3466648851542]],
    [ "Dronfield",[-1.4646484123297647, 53.30043036196062]],
    [ "Callywhite Lane",[-1.4646013557778181, 53.30072178048897]],
    [ "Waverley Business Park",[-1.3782455071874151, 53.38474553991238]],
    [ "Tinsley Park Road",[-1.4172613877494924, 53.39874580068646]],
    [ "Foxhills 33",[-0.6638506966025942, 53.612618284660826]],
    [ "Templeborough Biomass",[-1.3714575201974426, 53.42182144235534]],
    [ "Substation",[-2.6393069810428207, 53.69763400877588]],
    [ "Leaton Substation",[-2.5752527131693848, 52.70255776562521]],
    [ "Sinfin Lane Substation",[-1.4744847121464466, 52.88746830738894]],
    [ "New Albion Wind Farm Substation",[-0.7539297626958306, 52.44707637518795]],
    [ "808065044",[-1.302044105013359, 52.35276697357765]],
    [ "808169347",[-1.159836570725956, 53.61895070155399]],
    [ "Hillmorton Substation",[-1.2204387408074453, 52.36670503929033]],
    [ "812405470",[-0.9174847786946179, 51.44205333084278]],
    [ "Kirby Avenue",[-1.5805789779917179, 52.29587520405898]],
    [ "Northern Powergrid",[-1.5266711604249108, 53.81070356829962]],
    [ "Kingsnorth Substation",[0.598549966590544, 51.418553260565865]],
    [ "Humber Road",[-0.2147670497457537, 53.63338672785811]],
    [ "Goole Fields 2 WF",[-0.8762603661968372, 53.681879556928585]],
    [ "Kirkstall 132",[-1.595561295265126, 53.8051946736394]],
    [ "818177812",[-0.13432438711605804, 51.58123520535808]],
    [ "Upper Cape Warwick",[-1.5975485061059753, 52.29128013251313]],
    [ "Osgodby",[-1.0304537120557, 53.7879398713035]],
    [ "Ilfracombe Substation",[-4.111190141534733, 51.199515873476166]],
    [ "822172558",[-1.6601991971632681, 54.95933375161019]],
    [ "822219595",[-1.559223, 55.0126767]],
    [ "Barrack Road",[-1.6241519374585527, 54.974852130736075]],
    [ "823714093",[-2.241239034476332, 55.65345035909352]],
    [ "Ottringham Rd",[-0.09982040472068311, 53.710024384513794]],
    [ "Martongate",[-0.18228102680901356, 54.103561087251045]],
    [ "Hall Farm WF Routh",[-0.3538052605753183, 53.86538811901769]],
    [ "Norwood",[-0.4244016370660362, 53.85066429085747]],
    [ "Keddington Road",[0.015031739817963576, 53.37724511338145]],
    [ "The Breck Solar Farm Substation",[-1.3642093840878002, 53.281026629171755]],
    [ "Hutton Bonville TSC",[-1.4829698, 54.402574099999995]],
    [ "826450087",[-0.4766649410108284, 51.6407937151889]],
    [ "826450090",[0.08251136246535588, 51.569306559727785]],
    [ "Prologis Substation",[-0.13723428722280415, 51.383609507657496]],
    [ "826450097",[-0.3407904248256867, 51.44762321115504]],
    [ "827466341",[0.12545799536539423, 51.41274236796898]],
    [ "827466349",[0.10771450096237085, 51.3863812635863]],
    [ "827466351",[-0.34233220488657046, 51.30562246440999]],
    [ "Ashtead",[-0.2901979396541711, 51.320443168173135]],
    [ "Redhill Primary",[-0.15964963105507055, 51.24847730784652]],
    [ "Shalford Substation",[-0.5677380780255623, 51.21548361453989]],
    [ "827815478",[-2.668849198601601, 54.515016074920624]],
    [ "827818044",[-2.6028134850079043, 54.46126868902866]],
    [ "827818985",[-2.467344257037597, 54.44665798535303]],
    [ "827879808",[-2.3631344008711728, 54.477176156053105]],
    [ "827899626",[-2.9629695104424543, 54.422985341922406]],
    [ "Northallerton",[-1.4791949836560243, 54.346793877412296]],
    [ "Bedale",[-1.5905314372482968, 54.290099300410986]],
    [ "Calverton Substation",[-1.1028858512660882, 53.047108956704605]],
    [ "828552930",[-3.55527655435723, 54.544025180488056]],
    [ "828575386",[-3.3831057765704373, 54.86801485507129]],
    [ "828746891",[-3.2039135104359637, 54.190016624173566]],
    [ "828749693",[-3.0809386320305285, 54.36932569956155]],
    [ "828789831",[-3.1352046995474865, 54.237286519788114]],
    [ "828789835",[-2.917565977331689, 54.196871546077865]],
    [ "828789838",[-3.0797690500000003, 54.19684759999999]],
    [ "828938624",[-3.5198886276265307, 54.619332585612874]],
    [ "828938625",[-3.5227684, 54.606397400000006]],
    [ "828938629",[-3.5669801864839004, 54.64662551011055]],
    [ "829235788",[-2.876678483764179, 53.76919130025309]],
    [ "Bensham",[-1.6222945517009282, 54.95229813446706]],
    [ "829978176",[-2.7441749503508377, 54.17673893547997]],
    [ "829983306",[-1.867129300000001, 53.29410895000001]],
    [ "Matlock Substation",[-1.5509381773219255, 53.13883143837188]],
    [ "Knypersley Substation",[-2.1786556683937217, 53.098663478005484]],
    [ "Cheddleton Substation",[-2.0361690484611064, 53.07116821404484]],
    [ "Hatton Substation",[-1.6816605805505993, 52.86373120414744]],
    [ "Pinxton Substation",[-1.3029749267867232, 53.105673493832406]],
    [ "Crown Farm Substation",[-1.1571511041804214, 53.147485281806794]],
    [ "Ayshford Court Farm Solar Park Substation",[-3.3506235073148978, 50.92822227307675]],
    [ "Woodville Substation",[-1.531831613455357, 52.773695603801876]],
    [ "Marlborough Road Substation",[-1.1342143013132822, 52.990032175401616]],
    [ "Watnall Substation",[-1.2502615081708583, 53.00753476185627]],
    [ "Leicester North Substation",[-1.1431726582179054, 52.64684817605194]],
    [ "Wigston Substation",[-1.1440233677089608, 52.57732695403069]],
    [ "Chapel Brampton Substation",[-0.9273844037902288, 52.290040038151]],
    [ "Kelmarsh Wind Farm Substation",[-0.943138450000003, 52.4050226]],
    [ "Mountsorrel Substation",[-1.132555168247958, 52.723599316174294]],
    [ "830333468",[-0.9680286950331883, 52.69526243521497]],
    [ "Leicester East Substation",[-1.1014764106702202, 52.62973365992748]],
    [ "Wigston Magna Substation",[-1.1018142054844493, 52.584775159965325]],
    [ "Carlton Park Substation",[-1.2094413099316372, 52.5840092372168]],
    [ "830382018",[-0.19355848544311285, 51.883097112340316]],
    [ "Wilmot Lane",[-1.2196033830921336, 52.92156255043225]],
    [ "Vincent Avenue",[-1.2195707669465945, 52.92153389948523]],
    [ "Dartford Grid Substation",[0.2125340765665878, 51.45018022793504]],
    [ "EX North Selby S/S",[-1.0163139384346058, 53.89095051038817]],
    [ "DNO Substation",[-2.996028460336549, 51.020575429835105]],
    [ "Staplehurst Primary 33kV",[0.5448979124103431, 51.166911855177766]],
    [ "Echo",[0.11897007325437979, 52.2342376242783]],
    [ "Starr End",[0.12815489999999993, 52.235317800000004]],
    [ "Froghall",[-1.9636968520782145, 53.02231143277944]],
    [ "Tean Substation",[-1.9904787934452424, 52.95838646365751]],
    [ "Cheadle Substation",[-2.004769745712429, 52.981280265057535]],
    [ "837859812",[-1.615702656804234, 54.96029401630972]],
    [ "QUEENS PK",[-2.2140747677607133, 53.50223437156585]],
    [ "Sparling Street Substation",[-2.984698328553775, 53.39852809549285]],
    [ "Kingston",[-0.6873405359438646, 52.04105467572292]],
    [ "Wavendon Gate",[-0.6880851708979784, 52.02381709217078]],
    [ "Fen Farm",[-0.6771946333333333, 52.034255599999995]],
    [ "Secklow Gate Substation",[-0.7463174026690722, 52.04113458580519]],
    [ "Marlborough Street",[-0.7588797700670512, 52.06197858848975]],
    [ "838655604",[-0.7985784480528181, 51.281370600706225]],
    [ "Maxwell House Data Centre",[-0.7373810703995297, 52.00236557494368]],
    [ "Victoria Road",[-0.7186618966915523, 51.99716803372384]],
    [ "Newton Road",[-0.752925226436813, 51.990262915942694]],
    [ "Wolverton",[-0.8221912886513265, 52.063211193906106]],
    [ "Kiln Farm",[-0.8255034025644747, 52.049228791769885]],
    [ "Bearstone P.S.",[-2.411968022918319, 52.94729194124931]],
    [ "St Chads Road",[-2.258005883186493, 52.85794694989687]],
    [ "Staffford BR",[-2.1322617999999984, 52.809438150000005]],
    [ "Kidderminster",[-2.250928095455746, 52.38274002666949]],
    [ "Cleobury Mortimer Substation",[-2.4856826305691153, 52.37624571634627]],
    [ "Tenbury Substation",[-2.5939430202597276, 52.316967897416745]],
    [ "839729281",[-2.7003962865742155, 52.0504214829359]],
    [ "Brotheridge Green",[-2.262346809335819, 52.07142272182319]],
    [ "Bishops Cleeve Substation",[-2.078958431828191, 51.9471476701234]],
    [ "Eastern Avenue",[-2.2255748210424526, 51.85501399265891]],
    [ "Commercial Road",[-2.2499896517649915, 51.864464800257885]],
    [ "Tuffley Substation",[-2.262341686329196, 51.83708148595841]],
    [ "840108235",[-1.6803881910872258, 52.82737277448554]],
    [ "Princess Royal Substation",[-2.5618152733148443, 51.75437737736888]],
    [ "Wyelands Substation",[-2.578015839543731, 51.85553817685657]],
    [ "Stowfield Substation",[-2.5988285651914658, 51.854484241964585]],
    [ "Whitchurch Substation",[-2.5599353637204176, 51.41151734621478]],
    [ "Fords Car Park Substation",[-1.5475516538457708, 52.284625572564124]],
    [ "Wise Street Substation",[-1.5329449685442456, 52.283134861709264]],
    [ "Lockheed Substation",[-1.54032925, 52.271881449999995]],
    [ "Tournament Fields 33/11KV",[-1.611181220869428, 52.26516432684683]],
    [ "Dunlop Substation",[-1.5123547499999999, 52.4371977]],
    [ "Holbrook Lane",[-1.5071730868898088, 52.43540274830523]],
    [ "Coventry Arena Substation",[-1.4933174478784006, 52.44970532001723]],
    [ "Torrington Avenue Substation",[-1.571718949442311, 52.399163233941835]],
    [ "Dillotford Avenue Substation",[-1.506916409867038, 52.38816715144887]],
    [ "Ansty Substation",[-1.4004973445377946, 52.42985812567706]],
    [ "Sandy Lane Substation",[-1.5118952105848995, 52.417544264851685]],
    [ "Courtaulds Substation",[-1.5038045419958659, 52.42451236193661]],
    [ "Gipsy Lane Substation",[-1.443671036024053, 52.50113485684632]],
    [ "Langdale Drive Substation",[-1.4364819331871583, 52.52987110569371]],
    [ "Knebworth Substation",[-0.17609551302666873, 51.86855292528702]],
    [ "840615496",[-0.20689923839684687, 51.89748419070313]],
    [ "840615498",[-0.2623148423847117, 51.955030697055406]],
    [ "Corby Central",[-0.6754139262406934, 52.4984136887569]],
    [ "Banbury Lane Substation",[-0.9456954314306583, 52.2150838243135]],
    [ "Pineham Substation",[-0.95775311944133, 52.21751605268434]],
    [ "Ratcliffe on Soar",[-1.2614838, 52.86121310000001]],
    [ "Farnborough",[-0.7337753131613822, 51.27728948199092]],
    [ "Queensmead Substation",[-0.7529111621533681, 51.293425714733665]],
    [ "Camberley Substation",[-0.7592413675503021, 51.3280917626486]],
    [ "Camberley Substation",[-0.7594606142625431, 51.32754986059687]],
    [ "Kings Ride Substation",[-0.7439490320762637, 51.346005144960394]],
    [ "Crowthorne Substation",[-0.7876912986943809, 51.3722718192751]],
    [ "Easthampstead Substation",[-0.7626786887903896, 51.39714097909082]],
    [ "Bath Road West",[-0.4774945873582566, 51.4803484002475]],
    [ "Poyle Substation",[-0.5122174850133002, 51.46928399754372]],
    [ "Stanwell Moor Substation",[-0.47586114619753167, 51.443887740597425]],
    [ "East Bedfont Substation",[-0.44138011137602073, 51.45307409979285]],
    [ "North Feltham Substation",[-0.40355948731382596, 51.462128272994775]],
    [ "840684232",[-0.3987136098765497, 51.46863698788329]],
    [ "Palmer Lane Substation",[-1.510044236846363, 52.40995563780942]],
    [ "Hayward Road Master Substation",[-2.509956255381089, 51.47798123877932]],
    [ "Earls Barton Substation",[-0.7605101611900869, 52.26251017372957]],
    [ "Harrold Substation",[-0.6046399021799582, 52.20626511334719]],
    [ "Irchester Road Substation",[-0.6651585691624268, 52.25983992624165]],
    [ "Sharnbrook Substation",[-0.5653320684626756, 52.22814608323234]],
    [ "Campbell Street Substation",[-0.896709806967802, 52.242812866142614]],
    [ "Wellingborough Road Substation",[-0.851416526465815, 52.251675016492705]],
    [ "Ellesmere Avenue Substation",[-0.9315181942843882, 52.24167788154471]],
    [ "Northampton West Substation",[-0.9136707025549915, 52.245425790721846]],
    [ "Boothville Substation",[-0.8580783545769813, 52.27134652674141]],
    [ "Abington Substation",[-0.8683433373767481, 52.24960667927449]],
    [ "Littlewood Farm Solar",[-0.7992568712794411, 52.12019825616554]],
    [ "Hanslope Park",[-0.8057058621634028, 52.106313394384514]],
    [ "Bletchley Landfill Substation",[-0.7567129, 51.98029875]],
    [ "Tattenhoe Primary Substation",[-0.7954898428936085, 51.99994637051759]],
    [ "Hexham",[-2.0920809957571396, 54.97711443687884]],
    [ "Saxon Place",[-0.001342663588092653, 52.218384175206495]],
    [ "Mullard Drive",[-1.5187752912944374, 52.26279187794108]],
    [ "Beverley Lane",[-0.6038209630048174, 53.84588847188931]],
    [ "Sober Hill",[-0.599143936893564, 53.84061664042779]],
    [ "Southgate",[-0.6613314568653565, 53.86188142853812]],
    [ "Holme Upon Spalding Moor",[-0.7962477512826972, 53.80154915394635]],
    [ "Clayton Brook Substation",[-2.6462857823354686, 53.71396175369079]],
    [ "Southern Electric",[-0.6236678895284224, 51.48063369991514]],
    [ "Rainham Substation",[0.2041893969200017, 51.52072006953571]],
    [ "Headcorn Primary",[0.6139232887689512, 51.16961026020496]],
    [ "Kenardington 33kV",[0.8114451550280996, 51.05984600993047]],
    [ "Warehorne 33kV",[0.8361028832202959, 51.062664683087114]],
    [ "Romney Warren 33kV",[0.9585849684726822, 50.99735547703641]],
    [ "Dymchurch Primary 33kV",[1.0040339799614166, 51.0357112531689]],
    [ "Barcicroft Road Substation",[-2.2084835297156533, 53.42308174479516]],
    [ "Lewes Town Primary",[0.02170806666666667, 50.883478162804806]],
    [ "Berwick inverter feed",[0.17263565, 50.85560925000001]],
    [ "Victoria Quays",[-1.4582774229340774, 53.38452894570394]],
    [ "847505452",[-0.9413036420543348, 53.07733060193181]],
    [ "Hebb St",[-2.2255642892738265, 52.20109462888885]],
    [ "Sowerby Bridge",[-1.9239324608622013, 53.70880577601917]],
    [ "Hillview Road Substation",[0.4814659905191365, 51.74168725438001]],
    [ "Derrick Road Master Substation",[-2.5092416344552984, 51.46034945825111]],
    [ "Hampden Park Primary",[0.28719635532763205, 50.798352668477484]],
    [ "Ocklynge Primary",[0.2647466215049861, 50.78082117069592]],
    [ "Polegate Town Primary",[0.24516279984124542, 50.816942440550235]],
    [ "Pevensey Bay Primary",[0.3406311610728411, 50.81764986321734]],
    [ "850202300",[1.4274069618769232, 52.1299742681599]],
    [ "High Point Solar PV Substation",[-2.4914922176653804, 52.33537664931485]],
    [ "Rugby Road Flats",[-1.5419377193242234, 52.294942027644026]],
    [ "St. Margarets Road",[-1.5243570177522567, 52.27607099660242]],
    [ "Meads Primary",[0.269038464340105, 50.75835181514738]],
    [ "Seaford Primary",[0.10143110518914336, 50.774491062512105]],
    [ "Grid feed?",[0.04943182236493658, 50.80253670302531]],
    [ "Peacehaven Primary",[-0.009153066454834505, 50.79650368229257]],
    [ "Thingley Feeder",[-2.1419387934286562, 51.43706398731534]],
    [ "Ryhall Substation",[-0.45247282900619445, 52.68829744011021]],
    [ "Essendine feeder station",[-0.44859256615667764, 52.69665607489484]],
    [ "SSE Distribution",[-2.372593995176624, 50.95909985481951]],
    [ "Wooton Bassett Feeder",[-1.9042748456537633, 51.53515007346306]],
    [ "Farningham Primary",[0.2344289366191812, 51.38001008300084]],
    [ "853814172",[-0.42065032594241747, 51.48080650739088]],
    [ "Long Buckby Wharf feeder",[-1.1025312934494587, 52.28664097041477]],
    [ "854288163",[-2.375109622690073, 50.96064981202539]],
    [ "Baldslow Primary",[0.550001531526284, 50.884225542162056]],
    [ "Selly Oak Transformer Station",[-1.9368935944892098, 52.44431185963708]],
    [ "Dunlop Substation",[-1.8097557368515589, 52.5097877147531]],
    [ "856533119",[-0.07699670500578162, 51.583507117397524]],
    [ "Birch Coppice Substation",[-1.6242978422278436, 52.59181217640988]],
    [ "857230659",[1.2922533465140054, 52.39510934527672]],
    [ "857241922",[1.4693747767290963, 52.20982206376732]],
    [ "857246794",[1.4372097641685317, 52.453027432137695]],
    [ "857285467",[1.6610829297612373, 52.422317528740905]],
    [ "857285471",[1.6467344216161433, 52.34023311498062]],
    [ "EPR Substation",[1.1287223849593506, 52.33469360293388]],
    [ "857524272",[0.5116373506467355, 52.35391291305302]],
    [ "Dovercourt Substation",[1.2720532158915658, 51.934914184011916]],
    [ "857550702",[0.9613226103360638, 52.045152284582414]],
    [ "857554135",[1.3146159348590043, 51.96551555700052]],
    [ "857556874",[1.5549994222050494, 52.1951697971408]],
    [ "Walsoken Substation",[0.19022722698634734, 52.66103713928199]],
    [ "857619526",[0.24457550802421804, 52.628286677646464]],
    [ "857619530",[0.4453141896628363, 52.55054085809463]],
    [ "857624949",[0.21760955833771312, 52.73092186195375]],
    [ "857635560",[-0.06735124571134038, 52.33484495069315]],
    [ "857761260",[0.5281935828929337, 52.8747173320767]],
    [ "857763779",[0.65860535, 52.787792499999995]],
    [ "Townsend Hook Primary",[0.4499433457228683, 51.32645230609823]],
    [ "Reeds Paper Mill Primary (Disused?)",[0.460810201480667, 51.30681482176017]],
    [ "Aylesford Primary",[0.4671093723612518, 51.30082323142633]],
    [ "Wrotham Primary",[0.3363335080012999, 51.30292162555851]],
    [ "857867870",[1.3765115170873614, 52.633992371860444]],
    [ "UK Power Networks",[1.3050154169280195, 52.91648849208218]],
    [ "UK Power Networks",[1.5116747014375054, 52.77365191969398]],
    [ "857908678",[1.6360853001622906, 52.70279055757221]],
    [ "857908679",[1.6892369393467703, 52.68090727276434]],
    [ "Lea Road",[-0.7741885004046669, 53.390713582487024]],
    [ "858053033",[0.2611216674977382, 52.0291927711539]],
    [ "858053034",[0.25219508045981187, 52.01844348167302]],
    [ "858056045",[0.39550545000000004, 52.24224155]],
    [ "858437193",[0.4990523439676006, 52.936746244164596]],
    [ "859278548",[0.25546497890995035, 52.39789763142402]],
    [ "SB Gen 3",[-0.5077376772298543, 53.54141980370385]],
    [ "Chelwood 33kV Solar Park",[-2.5131571999999998, 51.347948099999996]],
    [ "Izaak Walton Way",[0.15158920370250725, 52.22079769947555]],
    [ "Stentaway Substation",[-4.085309297938647, 50.3641127978191]],
    [ "Linketty Lane Substation",[-4.064968260725931, 50.38882262167635]],
    [ "University Of Nottingham Triumph Road Substation",[-1.1847351510005515, 52.953164527933936]],
    [ "Clouds Hill Master Substation",[-2.5431840588751626, 51.46157729315742]],
    [ "Etty Avenue",[-1.0533458027510636, 53.95807613175544]],
    [ "Kirkmond ISOL",[-0.1897073661334905, 53.427658785564766]],
    [ "STAINTON",[-0.23761884449055468, 53.42459987066088]],
    [ "Kelstern Grange",[-0.12489036683656127, 53.39033455284857]],
    [ "Kelstern LSI",[-0.12269184104961602, 53.390445848955814]],
    [ "Calcethorpe",[-0.12252546649212523, 53.38492500247713]],
    [ "Foxby Lane",[-0.7558723830104168, 53.38680479490076]],
    [ "Knaith Upton Road",[-0.7275564483870967, 53.36384437849463]],
    [ "Clough Lane",[-0.24740558746149202, 53.65482372025662]],
    [ "Woodland Way Substation",[-2.516773952109587, 51.47050671715139]],
    [ "865201537",[0.18098978328091322, 51.52220498934815]],
    [ "Close",[-1.6144581711488486, 54.96559473572551]],
    [ "Priestweston Substation",[-3.047112295281333, 52.57592377201928]],
    [ "Richmond",[-1.7210004059949058, 54.411369922086166]],
    [ "868376955",[-0.6117513, 54.471333200000004]],
    [ "Ketley Substation",[-2.4838718904941244, 52.69400869083455]],
    [ "868706635",[0.4453458082112388, 51.35997206339452]],
    [ "Cobhambury Switching station",[0.41099507698938165, 51.383646959852534]],
    [ "Cobham Primary",[0.40439113049802666, 51.39055432196787]],
    [ "Shornewood Switching Station",[0.4022794040785217, 51.40388598547642]],
    [ "869058061",[-4.891556301592896, 50.50662790405145]],
    [ "Sharnal Street Primary",[0.5711819911480278, 51.439281966807826]],
    [ "Geevor Substation",[-5.674079928028906, 50.148201855864876]],
    [ "Mousehole Substation",[-5.549152288845482, 50.083935005132176]],
    [ "Mullion Substation",[-5.208953443697163, 50.021250597316914]],
    [ "870459187",[-4.919653921804875, 50.43755611821835]],
    [ "Aaronsons Substation",[-3.875342614773278, 51.02239533912045]],
    [ "Bagmoor WF",[-0.6584144687238094, 53.624609778126384]],
    [ "Galsworthy Wind Farm Substation",[-4.264831590738525, 50.907997934748735]],
    [ "Rowton Substation",[-2.94138015, 52.70101390000001]],
    [ "West Chatham Primary",[0.5146538359023619, 51.37737860810742]],
    [ "Perranporth Substation",[-5.155401482122644, 50.32144439418697]],
    [ "St Agnes Substation",[-5.199947011381353, 50.29674910366882]],
    [ "Newquay Trencreek Lane Substation",[-5.05717091646428, 50.41262242193864]],
    [ "870837108",[-5.03600000921354, 50.352330158471304]],
    [ "Newquay Trevemper Substation",[-5.070807554687768, 50.39710715798382]],
    [ "Rainham Mark Primary",[0.5781555819063466, 51.36293350202576]],
    [ "870981859",[-4.15011618262729, 51.114770950133334]],
    [ "Luckwell Bridge Substation",[-3.563561429562801, 51.13736165411831]],
    [ "Periton Substation",[-3.49730769150405, 51.20009217245993]],
    [ "Alcombe Substation",[-3.465117900533011, 51.19944329639871]],
    [ "Barnstaple Quay Substation",[-4.062877437586228, 51.07954548245913]],
    [ "Lynton Substation",[-3.8485396965695062, 51.22129062219399]],
    [ "Rainham Primary",[0.5974657646765718, 51.360104998440434]],
    [ "871403670",[-3.6822039185074997, 50.34737294666045]],
    [ "Stokenham Substation",[-3.6823492009523737, 50.274748136381554]],
    [ "Dartmouth Substation",[-3.6025490757815555, 50.34784834943771]],
    [ "Churston Substation",[-3.5659586733996336, 50.40241302045843]],
    [ "Faversham Primary",[0.9114817375471164, 51.31257339638175]],
    [ "St Neot Substation",[-4.550895832033078, 50.478138687570755]],
    [ "Pensilva Substation",[-4.412161294317269, 50.50740369097326]],
    [ "Dawlish Substation",[-3.480459421013992, 50.57880214244657]],
    [ "Waterside Primary",[0.5186143353659891, 51.276937155783706]],
    [ "Fairmeadow 11kv",[0.5179807976792888, 51.272740718186434]],
    [ "Barming Primary",[0.48809493676959353, 51.26511316081116]],
    [ "Hunt Road",[-1.96383375, 50.728586050000004]],
    [ "Llewellin Close",[-2.02692639330888, 50.74135290697403]],
    [ "Carey Road No1",[-2.1189627617637394, 50.694272073079986]],
    [ "High Street",[-2.002417807449311, 50.610091598526125]],
    [ "The Old Malt House",[-2.0076317935560857, 50.60837960692124]],
    [ "879044024",[-2.9448642912788623, 50.986736343728104]],
    [ "Shifnal Substation",[-2.3783974649900306, 52.665526088764956]],
    [ "Holbeck",[-1.5492623780039845, 53.787058588323404]],
    [ "Colyford Substation",[-3.056569649433734, 50.72890320652084]],
    [ "881174017",[-1.7788477793112565, 51.06315580392416]],
    [ "881461409",[-0.6892668672863566, 53.587041527218084]],
    [ "881461410",[-0.6861088600492259, 53.589059502044165]],
    [ "881461411",[-0.6821253238365401, 53.58994703811614]],
    [ "881461412",[-0.6825378579181383, 53.577407636136684]],
    [ "881461413",[-0.6807516493059054, 53.57574250804024]],
    [ "881461414",[-0.6750392964228409, 53.57565198066818]],
    [ "Crematorium",[-0.6764107577581189, 53.58173522631081]],
    [ "881461416",[-0.6548434197084085, 53.58152813142202]],
    [ "881461417",[-0.653410905790588, 53.56929080535375]],
    [ "881461418",[-0.6441338048233689, 53.57074810914162]],
    [ "Cliff Closes",[-0.6739024135609771, 53.58962113035687]],
    [ "Firth brown",[-0.6162923187341248, 53.59360679826919]],
    [ "881463243",[-0.6522639848790673, 53.59983106950293]],
    [ "881463244",[-0.6508947000000007, 53.602754749999995]],
    [ "881463245",[-0.6422177361563823, 53.589099248349115]],
    [ "881463246",[-0.6220608112359673, 53.575306864576916]],
    [ "881463247",[-0.869617158034721, 53.579635595052956]],
    [ "881464036",[-2.19142068079748, 51.4249254949552]],
    [ "881465540",[-0.6805637433036786, 53.573898538700746]],
    [ "881465541",[-0.6767138415841585, 53.57157569863862]],
    [ "881465542",[-0.6694251891535925, 53.57120219471879]],
    [ "881465543",[-0.6757939951534097, 53.61114673108747]],
    [ "881465544",[-0.7003409148159446, 53.62248270386667]],
    [ "Roxby",[-0.6275260584034124, 53.63584352287933]],
    [ "881465546",[-0.19215347842376437, 53.615673893437666]],
    [ "881465547",[-0.17767769798996483, 53.616474173011134]],
    [ "881465548",[-0.1701082528164753, 53.47890408512762]],
    [ "Westhorpe Substation",[-1.3165792479965532, 53.30887332346474]],
    [ "Bayston Hill Substation",[-2.761606606118258, 52.67629442664284]],
    [ "Harlescott Substation",[-2.72876581067335, 52.73860562787319]],
    [ "Drayton Farm North Substation",[-1.772803509567718, 52.196165218829535]],
    [ "Patchway Substation",[-2.5617460010856017, 51.52175790360859]],
    [ "Avonmouth Substation",[-2.6967643716217813, 51.50481400310573]],
    [ "Western Approach Substation",[-2.6502316969039263, 51.54868126778948]],
    [ "884174275",[-2.6666511679544525, 51.5406646314194]],
    [ "Kingsweston Substation",[-2.68401615, 51.5111935]],
    [ "Hungerford",[-1.51151499589926, 51.41880243216292]],
    [ "885939413",[-0.3002251918902996, 51.586290232275765]],
    [ "Wood Lane Substation",[-1.4579869656956792, 52.55226073107096]],
    [ "Irongate Farm Substation",[-2.674379046877057, 53.727825950146666]],
    [ "Todd Lane Substation",[-2.6815100438822372, 53.7277446989427]],
    [ "Todd Lane North Substation",[-2.6810529448495224, 53.73110724310924]],
    [ "Myrtle Grange Substation",[-2.6813509036357788, 53.734978990954076]],
    [ "Walton Park Substation",[-2.681564455611775, 53.73871780062185]],
    [ "Hennel Lane Substation",[-2.6818112786002493, 53.74074145494531]],
    [ "Gateways Substation",[-2.6602197464338637, 53.72993040032192]],
    [ "Braithwaite Substation",[-3.1911902957465617, 54.6022010512503]],
    [ "B&Q Bamber Bridge Substation",[-2.6672597185810547, 53.71927816584755]],
    [ "South Rings Hotel Substation",[-2.6708902, 53.72101254999999]],
    [ "Tennis Courts Substation",[-2.668579601656729, 53.72278760008107]],
    [ "Bow Lane Substation",[-2.6850618883833293, 53.69609864651777]],
    [ "Leyland Goods, Leyland Substation",[-2.688308994600152, 53.698653155886795]],
    [ "Northgate Substation",[-2.689378594321149, 53.6999171249977]],
    [ "Threlfalls Leyland Substation",[-2.685300578466971, 53.70045806009419]],
    [ "Catterick Camp",[-1.728976999656415, 54.37486495982654]],
    [ "Limepots Road Substation",[-3.1444957451910702, 54.60635068165336]],
    [ "Stanifield Lane Substation",[-2.682488907741589, 53.70978928469192]],
    [ "Cuerden Green Switching Station Substation",[-2.6828690232528376, 53.71821715331428]],
    [ "Coniston Drive North Substation",[-2.667088056139824, 53.73405748944686]],
    [ "Renshaw Drive Substation",[-2.665137398559017, 53.73553724210239]],
    [ "Horsley Smiths Substation",[-2.6642528500000004, 53.7326305]],
    [ "St Mary's Roman Catholic School Substation",[-2.661907600000001, 53.732205699999994]],
    [ "Bamber Bridge Substation",[-2.66190286832896, 53.73465268559583]],
    [ "Old Mill Substation",[-2.6589233290086076, 53.735972381643556]],
    [ "Drum Developments Substation",[-2.6587511523312855, 53.73805689787377]],
    [ "Stone Mill Switching Station Substation",[-2.6585909962597865, 53.73724005088642]],
    [ "Brindle Road North Substation",[-2.656311554205928, 53.73581439912947]],
    [ "Cottage Lane Hostel Substation",[-2.655056386052253, 53.738162747451796]],
    [ "Opp 286 Hr Walton Rd Substation",[-2.6492764, 53.744585750000006]],
    [ "Cooper Hill Drive Substation",[-2.6689152594450833, 53.74702850178741]],
    [ "St Leonards Substation",[-2.6761637604190085, 53.74587576571535]],
    [ "Heaton Close Substation",[-2.6739774630422013, 53.744205636991545]],
    [ "Allerton Road Substation",[-2.672088622401491, 53.74235244848675]],
    [ "Chorley Road Substation",[-2.670322, 53.740969]],
    [ "Substation",[-2.6925076, 53.74154369999999]],
    [ "Meadow Lane Substation",[-2.643235962118417, 53.709512903258634]],
    [ "Clayton Green Road Substation",[-2.644112783030901, 53.706373427191295]],
    [ "Library Road Offices Substation",[-2.6410301501904305, 53.70803369599773]],
    [ "Substation",[-2.6714167, 53.7242708]],
    [ "Club Street Substation",[-2.6621439077960423, 53.723232895079065]],
    [ "Church Road Substation",[-2.6586992364630766, 53.71968731605348]],
    [ "British Beef Substation (Private)",[-2.650764627366912, 53.71618943639832]],
    [ "British Beef Substation",[-2.650694387449956, 53.71615611177024]],
    [ "Co-Op Street Training Centre Substation",[-2.6627853798789403, 53.72455449553921]],
    [ "Adams Hall Substation",[-2.6549752441225762, 53.72495571233548]],
    [ "Severn Drive Substation",[-2.675486123698735, 53.73522889685984]],
    [ "Grenville Avenue Substation",[-2.6709633000000017, 53.734875900000006]],
    [ "Tardy Gate Substation",[-2.6995762506499923, 53.72891767133076]],
    [ "Holme Road Substation",[-2.724573811341868, 53.75663022267442]],
    [ "Buckshaw Substation",[-2.6565168234189978, 53.68662080282147]],
    [ "Higher Walton Substation",[-2.638155587938744, 53.740011660433694]],
    [ "Moss Side Leyland Substation",[-2.7260319463612626, 53.7010823040335]],
    [ "Seven Stars Substation",[-2.718546413877795, 53.68919343531]],
    [ "Barn Meadow Substation",[-2.6428375017397245, 53.71570080064782]],
    [ "Gough Lane Substation",[-2.6416862761602196, 53.71671683010734]],
    [ "Great Green Lane Substation",[-2.64071719078034, 53.71394821563474]],
    [ "Homestead Substation",[-2.639102477252711, 53.711363990229394]],
    [ "Seven Acres Substation",[-2.6332363110021504, 53.71159199300111]],
    [ "The Paddock Substation",[-2.6338812886341203, 53.71602908566886]],
    [ "Woodfield Substation",[-2.636618159188512, 53.71591917110028]],
    [ "Havelock Terrace Substation",[-2.6653515000000003, 53.72266605]],
    [ "Old Hall Drive Substation",[-2.6661170487833052, 53.72514128185221]],
    [ "Grove Street Substation",[-2.659858195900004, 53.72753016956147]],
    [ "Withy Grove Substation",[-2.6574987140366604, 53.72946189937505]],
    [ "Ash Grove Substation",[-2.654296516259444, 53.732627593995076]],
    [ "Brown Lane Substation",[-2.652103452577897, 53.73411169848025]],
    [ "Whitefield Meadow Substation",[-2.6587353694073017, 53.73380849401337]],
    [ "Duddle Lane End Substation",[-2.666412426442167, 53.73777864464223]],
    [ "Holland House Farm Substation",[-2.66192456967973, 53.739686142331095]],
    [ "Balmoral Road Substation",[-2.6741523172781587, 53.73965330429084]],
    [ "Bluebell Way Substation",[-2.647474061040455, 53.72812317010755]],
    [ "Margaret Road Substation",[-2.709994773500539, 53.745379057267904]],
    [ "Stricklands Lane Substation",[-2.7132816775117283, 53.74474989578402]],
    [ "Malthouse Way Substation",[-2.718858236687408, 53.74104205734243]],
    [ "Substation",[-2.7218135675022492, 53.7393976824497]],
    [ "Millbrook Way Substation",[-2.725602302211349, 53.73920034740017]],
    [ "Woodville Road Substation",[-2.720372008263992, 53.736837110051404]],
    [ "Pope Lane End Substation",[-2.7143506599329843, 53.73845224460707]],
    [ "Adj 560 Preston Road Substation",[-2.638801816092875, 53.706133429203305]],
    [ "The Hawthorns Substation",[-2.633593124385339, 53.70740364741813]],
    [ "Eric Wright Substation",[-2.640099045295793, 53.71869639726016]],
    [ "Albrighton Road Substation",[-2.6829699616807687, 53.72435445314884]],
    [ "Bridgeway Substation",[-2.678894326166407, 53.72445803717869]],
    [ "Sherdley Road Substation",[-2.687275464161376, 53.72074944455483]],
    [ "Beaumont Park Substation",[-2.69456476020706, 53.721300350793406]],
    [ "Kellett Acre Substation",[-2.698421425205389, 53.72100678579795]],
    [ "Fowler Lane Substation",[-2.6997908217916917, 53.71878129771378]],
    [ "Moss Walk Substation",[0.4733515279204411, 51.71640197831372]],
    [ "Bootle Substation",[-2.9855025810995337, 53.456526535110505]],
    [ "Edge Lane Substation",[-2.9268322400370734, 53.411341437815544]],
    [ "Huyton Substation",[-2.8606768880404276, 53.42321262781749]],
    [ "891890879",[-3.0681476380514967, 53.37273750367284]],
    [ "Southworths Farm Substation",[-2.671203693304786, 53.705633577833794]],
    [ "Spring Mill Street",[-1.7531280434499572, 53.78280552339614]],
    [ "892244586",[0.15427787486427982, 51.50478826538467]],
    [ "Withernwick WF",[-0.1671270783641247, 53.8484231231512]],
    [ "Ribble House Tx (Cons) Substation",[-2.66635474420912, 53.72971039986904]],
    [ "Wheelton Lane Substation",[-2.694720927753132, 53.702835774249365]],
    [ "Grasmere Avenue Farington Substation",[-2.696538676659646, 53.70311640440285]],
    [ "Carr Lane Local Substation",[-2.692506933026461, 53.7041747246859]],
    [ "Carr Lane Leyland Substation",[-2.690975965733282, 53.70387277757128]],
    [ "Garstang Road Substation",[-2.7127660271665373, 53.78681436003998]],
    [ "Triton Knoll Wind Farm Substation",[-0.2264736679937506, 52.944850354627306]],
    [ "Charlton Road Master Substation",[-2.52195955, 51.46654425]],
    [ "895877015",[-2.217653711900615, 52.13311581990969]],
    [ "896253162",[-0.25027546852041976, 51.349500912287134]],
    [ "Northern Powergrid",[-1.241975726651206, 53.706367198932206]],
    [ "896963313",[-1.6822486401760663, 51.22751367168055]],
    [ "896985688",[-1.5192287014253154, 51.09798089500363]],
    [ "897153777",[-1.0946858695683848, 51.48102028974367]],
    [ "897301260",[-1.755256708726174, 51.168436858976044]],
    [ "HS1 Ltd",[0.5150522575963351, 51.31269970214596]],
    [ "Sleaford REP Substation",[-0.38179309489810437, 53.00016606112821]],
    [ "Hospital Crossing Substation",[-2.638822325193355, 53.72573962426373]],
    [ "Doodstone Nook Substation",[-2.688173875134889, 53.73095635188524]],
    [ "Gas Works Substation",[-2.690241952066296, 53.733150699677275]],
    [ "Green Lane Substation",[-2.706540956762542, 53.72067811231908]],
    [ "North Union Road Substation",[-2.700226893906508, 53.723901166634086]],
    [ "Coote Lane Switching House Substation",[-2.6980198385690994, 53.72450697186673]],
    [ "Coppenhall Substation",[-2.425785389355988, 53.106232730235945]],
    [ "Tardy Gate Mill Substation",[-2.6956645155008285, 53.72706885303689]],
    [ "Elworth Substation",[-2.409218311250043, 53.1672753518635]],
    [ "Greenfield Drive Substation",[-2.6962759876859757, 53.7246857447988]],
    [ "900802309",[-2.4237130896834675, 53.53023821447815]],
    [ "Fullerton Road",[-1.3670007380267801, 53.41634611538172]],
    [ "BOC Brinsworth",[-1.3866332700261366, 53.41112200689044]],
    [ "Templeborough",[-1.381588531274228, 53.41771706876392]],
    [ "Revill Lane",[-1.3683632641817134, 53.359227104847434]],
    [ "Greenhill",[-1.4848844533947194, 53.32523508151673]],
    [ "Baslow Road",[-1.5243007584859272, 53.32028647853585]],
    [ "Fox Ford Lane",[-1.5874365218311668, 53.48378923687514]],
    [ "Wheatacre Road",[-1.5897927569260883, 53.479477636176895]],
    [ "Hope Cement",[-1.7540181355459328, 53.339082847825125]],
    [ "Crookesmoore Road",[-1.4901403125422248, 53.387138031829025]],
    [ "Rawson Spring Road",[-1.4955683992045263, 53.41127384166511]],
    [ "Clay wheels Lane",[-1.5030774340350879, 53.4173744904006]],
    [ "Shepcote Tinsley",[-1.406698006047853, 53.40293168242728]],
    [ "Silverwood",[-1.2791831558702944, 53.44024611138099]],
    [ "Mansfield Road",[-1.4090215999999998, 53.3548887]],
    [ "Balby Generation",[-1.1237659002351315, 53.50210828253217]],
    [ "Woodseats",[-1.479380977506007, 53.33955457043279]],
    [ "Millhouses",[-1.49512491829702, 53.34569943176749]],
    [ "Salterforth",[-2.1647846830341133, 53.90906191175855]],
    [ "Linden Close Substation",[-2.6870587680301186, 53.72862972505669]],
    [ "902367686",[-0.19053622526531064, 53.570384494584424]],
    [ "Lostock Hall Labour Club Substation",[-2.688893744945077, 53.72479921598201]],
    [ "Greenbank Road Subststation",[-2.7099980747127814, 53.743190445598636]],
    [ "Factory Lane Substation",[-2.707087285853432, 53.741929295406806]],
    [ "Factory Lane Pump Station Substation",[-2.6992693339625164, 53.741131326280595]],
    [ "Old Tram Road Substation",[-2.692721921492485, 53.738663260794006]],
    [ "The Cawsey Substation",[-2.69657655, 53.733513599999995]],
    [ "Lostock Hall Library Substation",[-2.6912045085250806, 53.72634665197545]],
    [ "Carrwood Way Substation",[-2.688272645618129, 53.73644827888428]],
    [ "Hennel Lane Switching House Substation",[-2.6870241904588705, 53.737711026342375]],
    [ "Albrighton Estate Substation",[-2.6845805999999994, 53.7216302]],
    [ "Mercer Road Substation",[-2.6895960941434516, 53.729694551725295]],
    [ "Lourdes Avenue Substation",[-2.6950277035398575, 53.73025194869935]],
    [ "Denby Dale Road",[-1.508078160183998, 53.66446793713221]],
    [ "Bee Lane Substation",[-2.7092491127817016, 53.731118428145784]],
    [ "Harrington Street Substation",[-2.7055800000000003, 53.764250950000005]],
    [ "Leyland Road Substation",[-2.7021992553506684, 53.73432978127472]],
    [ "Mortal Ash Raventhorpe",[-0.5937344270288978, 53.55934203333898]],
    [ "Atkinsons Warren",[-0.672400699471954, 53.611829434472774]],
    [ "ST MARYS VIEW",[-1.371232506391582, 53.45126979011335]],
    [ "Ridgeway",[-1.3152946458522186, 53.43290933177253]],
    [ "Woodlaithes Villeage Beta",[-1.2812412164792997, 53.43602508311466]],
    [ "Townend Avenue",[-1.3057690723461715, 53.44504601559041]],
    [ "Todwick road Industrial Estate 5551",[-1.2370914136530673, 53.37080024779857]],
    [ "Blyth Road Harworth",[-1.0693790509411025, 53.408378478419]],
    [ "Goose Carr Lane",[-1.259057840421412, 53.35395162655791]],
    [ "Hangsman Lane",[-1.2311794597733083, 53.37916598009802]],
    [ "All Saints Schoolo'ranger marbles set",[-1.0672900014920221, 53.46899833094191]],
    [ "Bond Street 44881",[-1.0721750222016804, 53.4696175275002]],
    [ "THE AVENUE",[-1.0923350285810183, 53.51385866092323]],
    [ "Bridge Street 44146",[-1.1432243429584792, 53.51897073442666]],
    [ "St  JAmes Bridge 4113",[-1.1381757661218554, 53.51949680510031]],
    [ "Nutfield Grove 4406",[-1.0222574708033738, 53.59856854066411]],
    [ "Coulman Road Industrial Estate 45369",[-0.9492609347895151, 53.61737136521049]],
    [ "Instone Terrace 3361",[-1.1568530815251605, 53.61022249672141]],
    [ "Snaithing Lane 5367",[-1.5320663621670667, 53.373103643549165]],
    [ "St Albans Road 2856",[-1.551172898811926, 53.371042791925476]],
    [ "Baths 5104",[-0.6581686241293334, 53.58996559545002]],
    [ "Doncaster Road Supermarket 5535",[-0.6592144218033912, 53.591186250375465]],
    [ "Dunstall Street 49414",[-0.65257896645125, 53.58962402762587]],
    [ "Bursledon North Substation",[-1.30844643940051, 50.89150095265834]],
    [ "KIRK BALK SHOOL 50545",[-1.4525026876890783, 53.50042313925105]],
    [ "Hoyland Lane 22883",[-1.4568316047424228, 53.50048145259454]],
    [ "Leverton Drive 3457",[-1.4780348066294784, 53.37150465312473]],
    [ "Callow Mount",[-1.4531300231182374, 53.35496947222799]],
    [ "Albrighton Substation",[-2.2497520782407467, 52.63721126383003]],
    [ "Hartlebury EFW",[-2.207878989678554, 52.32656427381119]],
    [ "Attleborough Buckenham Road",[1.0270468478696393, 52.50996769288803]],
    [ "Meadowfield",[-1.6115324977742072, 54.74545175963865]],
    [ "904589616",[-1.5845245489254856, 51.20937904072813]],
    [ "Substation",[-2.711612857971475, 53.73670116881639]],
    [ "Kingsfold Drive East Substation",[-2.718423836951936, 53.73368890072022]],
    [ "Kingshaven Substation",[-2.711821254148738, 53.73405240768647]],
    [ "Studholme Crescent Substation",[-2.7075796288536638, 53.73482156028066]],
    [ "Spring Gardens Substation",[-2.7045134849237833, 53.73398050182816]],
    [ "Martinfield Road Substation",[-2.7162393463983627, 53.735106561970845]],
    [ "Kingsfold Drive Substation",[-2.7217982700751153, 53.73343287248477]],
    [ "Fryers Close Substation",[-2.721050377508122, 53.732402984591204]],
    [ "Marsh Way Substation",[-2.7284616232047703, 53.73677190568912]],
    [ "Booths Penwortham Substation",[-2.7301665877790704, 53.736180730864355]],
    [ "Cyprus Road Substation",[-1.2610953062246457, 50.855239469057]],
    [ "Locksheath Park Road Substation",[-1.2695486578504627, 50.85454498867562]],
    [ "Greenway Substation",[-1.2886470833333337, 50.8537894]],
    [ "Brookfield Nurseries Substation",[-1.2853088565969835, 50.85523060235777]],
    [ "Primrose Way Substation",[-1.2803782732239202, 50.8561713545423]],
    [ "Fleet End Substation",[-1.2769613479442616, 50.85517499352249]],
    [ "Substation",[-1.28335525, 50.8511336]],
    [ "Oaklands Way Substation",[-1.2604643808667053, 50.85076868785559]],
    [ "Heath Road Substation",[-1.2786823576254363, 50.86182815553617]],
    [ "Crescent Road Substation",[-1.2764850315591587, 50.85772732031335]],
    [ "Laing Homes Substation",[-1.282352294150657, 50.857334793349956]],
    [ "Upper Brook Drive North Substation",[-1.2809547993902004, 50.85999704557]],
    [ "St Georges Road Substation",[-1.2823896892257107, 50.86074510149376]],
    [ "Clover Close Substation",[-1.2842093531883652, 50.85946649775736]],
    [ "Upper Brook Drive Substation",[-1.28737584969171, 50.858449102062174]],
    [ "Yarrow Way Substation",[-1.2866057500296946, 50.85697935797735]],
    [ "Greenaway Lane Substation",[-1.2897852514765185, 50.85676051373947]],
    [ "Albacore Avenue Substation",[-1.2840145723804905, 50.85249252658032]],
    [ "Schooner Way Substation",[-1.281303350000001, 50.8537339]],
    [ "Sir Joseph Paxton PH Substation",[-1.2560425512252598, 50.85490341639116]],
    [ "Churchill Close Substation",[-1.259954206685793, 50.85445454994739]],
    [ "Titchfield Common Substation",[-1.2571559757490554, 50.849828732549426]],
    [ "134 Huntspond Road Substation",[-1.260514839592906, 50.86242873007651]],
    [ "Lodge Road Substation",[-1.2640633922029927, 50.86230450111542]],
    [ "Church Road Substation",[-1.269666318316185, 50.862924664207206]],
    [ "Locksheath Supermarket Substation",[-1.273833715521735, 50.86419457379891]],
    [ "Centre Way Substation",[-1.271311617040089, 50.863090397545946]],
    [ "Substation",[-1.270455732226843, 50.86101567459542]],
    [ "Orchard Park Substation",[-1.271322129931578, 50.85904042279649]],
    [ "Substation",[-1.2468476608491277, 50.85501531914927]],
    [ "Substation",[-1.2464493500000005, 50.85496495]],
    [ "Substation",[-1.2469997782488798, 50.85581211570378]],
    [ "Estee Lauder Substation",[-1.248143075517858, 50.85658520081122]],
    [ "Substation",[-1.2492167668916234, 50.85750724943761]],
    [ "The Gallops Substation",[-1.254051885274667, 50.85736117063164]],
    [ "Meon Gardens Substation",[-1.255021086503134, 50.858532038776346]],
    [ "Locksheath Park Road North Substation",[-1.2633262076406735, 50.85827774101903]],
    [ "St Johns Road Substation",[-1.265577191073913, 50.85892674257203]],
    [ "Stanley Road Outddor Substation",[-2.680220189628441, 53.70708540243651]],
    [ "Cuerden County Primary School Substation",[-2.6785886755557984, 53.70499130327944]],
    [ "Leverhouse Lane Substation",[-2.680760030637891, 53.701811148214155]],
    [ "Vicarage Substation",[-2.682622413903969, 53.69887838486417]],
    [ "St Catherines Substation",[-2.683368420416208, 53.70469060210649]],
    [ "Stanifield Close Substation",[-2.684797754522523, 53.7028523692903]],
    [ "906679074",[-0.0651544904675749, 53.69791916823689]],
    [ "906679075",[-0.06940747919364951, 53.698848558691516]],
    [ "9 Locks Road Substation",[-1.2720879173617545, 50.856505476451645]],
    [ "Beverley Close Substation",[-1.260283029690812, 50.86788891987876]],
    [ "Longacres Substation",[-1.2584432746622751, 50.8662150527574]],
    [ "Halfords Substation",[-1.2567525265130395, 50.866324794506866]],
    [ "Brown Bear Substation",[-1.2562656587462495, 50.86444411664139]],
    [ "Allied Carpets Substation",[-1.2550689192379323, 50.86287004638774]],
    [ "Queensway Substation",[-1.2539085603274531, 50.86085832553683]],
    [ "Caigers Green Substation",[-1.2642853987752722, 50.89136371713099]],
    [ "250 Botley Road Substation",[-1.2658174988547573, 50.88808144840046]],
    [ "SSE Power Distribution",[-1.2988281784583051, 50.91269342038464]],
    [ "Electricity North West",[-1.300653798318032, 50.91375192602684]],
    [ "Richlans Road Substation",[-1.30002600136948, 50.910150498769084]],
    [ "Smith Grove Substation",[-1.3032859714488896, 50.90879038287557]],
    [ "SSE Power Distribution",[-1.3034436356016357, 50.90989130621159]],
    [ "SSE Power Distribution",[-1.2965445830266862, 50.91211900936901]],
    [ "Elm Tree Corner Substation",[-1.2704576301979125, 50.88324164967091]],
    [ "Kingsway Substation",[-2.6638278177783454, 53.72811883802262]],
    [ "Sweethills Flats Substation",[-1.26109712811328, 50.88653365363386]],
    [ "Wells Close Substation",[-1.2581446919053856, 50.88730393642051]],
    [ "Dumas Drive Substation",[-1.2612799888645074, 50.88974822322111]],
    [ "SSE Power Distribution",[-1.270337399936799, 50.91599445001754]],
    [ "Hills Industrial Estate Substation",[-1.2628984524167737, 50.916359544209485]],
    [ "SSE Power Distribution",[-1.2720898764598507, 50.91330289577169]],
    [ "SSE Power Distribution",[-1.2698412473773344, 50.91247533780289]],
    [ "SSE Power Distribution",[-1.2746608000000001, 50.91792066666666]],
    [ "SSE Power Distribution",[-1.277295695726448, 50.91813789922892]],
    [ "SSE Power Distribution",[-1.2725352499999993, 50.91502909999999]],
    [ "SSE Power Distribution",[-1.2904092830511846, 50.91964815269425]],
    [ "SSE Power Distribution",[-1.290650031738884, 50.91906075100437]],
    [ "SSE Power Distribution",[-1.2784518696404596, 50.92404118180878]],
    [ "21 Holmesland Drive Substation",[-1.2763526019249674, 50.91909798835928]],
    [ "SSE Power Distribution",[-1.2929, 50.91982865000001]],
    [ "SSE Power Distribution",[-1.300152029998842, 50.92365109564664]],
    [ "Locke Road Substation",[-1.2985893720786783, 50.92239623667037]],
    [ "SSE Power Distribution",[-1.2919459444457957, 50.92249308149613]],
    [ "Drummond Road Substation",[-1.2992367000000014, 50.92582945]],
    [ "Mobile Homes Substation",[-1.299969658966056, 50.92484742948157]],
    [ "SSE Power Distribution",[-1.3066008381027365, 50.92637056946001]],
    [ "SSE Power Distribution",[-1.3050877500000007, 50.92589445]],
    [ "Barfoot Road Substation",[-1.3041159188810796, 50.9273324942781]],
    [ "Radley Close Substation",[-1.3025510500000002, 50.926578150000005]],
    [ "Ancaster Gardens Wollaton",[-1.2060600709574172, 52.95494827409617]],
    [ "908910188",[-2.1752109500000003, 52.9398637]],
    [ "Robinsons Wedgwood Substation",[-2.170688841996615, 52.95287137003344]],
    [ "Olddacres Road Substation",[-2.1800010879337255, 52.95849811440883]],
    [ "Meriden Substation",[-2.180479212625801, 52.960999824889726]],
    [ "Carisbrooke Way Substation",[-2.174804802050084, 52.958898591358505]],
    [ "Burrington Drive",[-2.1775801136474078, 52.96390911925176]],
    [ "Trentham Garden Centre Substation",[-2.197140649999999, 52.96508655]],
    [ "Marshalls H2 Substation",[-2.647816065878465, 53.71941519135519]],
    [ "South Walton Summit Road Substation",[-2.6525884, 53.7181226]],
    [ "Holly Place Substation",[-2.6447358, 53.71916865]],
    [ "Reedfield Place Substation",[-2.6534434151226183, 53.719272141471066]],
    [ "Substation",[-2.650214656376941, 53.72021551423774]],
    [ "Sovereign Crescent Substation",[-1.2694341500000001, 50.852918300000006]],
    [ "Bosals No 5 Substation",[-2.642763955423142, 53.721274406103554]],
    [ "Cocker Road Substation",[-2.644944058247631, 53.721841149631516]],
    [ "Walton Summit Road Substation",[-2.639675650631813, 53.723977799887116]],
    [ "Carnfield Place Substation",[-2.644700699039318, 53.72431422586947]],
    [ "Substation",[-2.6489992500000006, 53.7227619]],
    [ "Substation",[-2.648639070497436, 53.72313363445064]],
    [ "Brierley Road Substation",[-2.6494239170914757, 53.72522712433779]],
    [ "Unit 171 Walton Summit Substation",[-2.650102500000001, 53.72224835000001]],
    [ "Dawson Place Substation",[-2.64540388994696, 53.720346427947774]],
    [ "Littleworth",[-2.1715626500000003, 52.14959255]],
    [ "909178894",[-2.2060438, 52.96209375000001]],
    [ "Substation",[-2.6479673, 53.71722075]],
    [ "Carr Place Substation",[-2.6418424547398467, 53.72103794854233]],
    [ "Longton Machinery Supplies Substation",[-2.6425113619661413, 53.72011519611247]],
    [ "Site J Substation",[-2.639364873958823, 53.720655673177326]],
    [ "Phase 8 Substation",[-2.6369030910299545, 53.72247897731088]],
    [ "Gilwilly Back Lane Substation",[-2.758566683669127, 54.66523440048405]],
    [ "Firs Estate Substation",[-2.4393968500000005, 54.80984399999999]],
    [ "Clumber Avenue",[-2.2193450500000003, 52.99697509999999]],
    [ "Pear Tree Close Substation",[-1.2131973991323606, 50.82659183477299]],
    [ "Ditton Close Substation",[-1.2140818000531666, 50.82804935124286]],
    [ "Green Road Substation",[-1.2135909340646331, 50.8299039094355]],
    [ "Stubbington Substation",[-1.214714926234741, 50.82697141149645]],
    [ "Substation",[-1.2120915, 50.82739965]],
    [ "Substation",[-1.2117305927052513, 50.82879461120045]],
    [ "Substation",[-1.2157637125584628, 50.831668880961494]],
    [ "Substation",[-1.217027123682065, 50.832516797843496]],
    [ "Crofton Substation",[-1.2228226861824274, 50.83691768379459]],
    [ "Crofton P/S Substation",[-1.2221499932102367, 50.83553688248185]],
    [ "Alderflat Drive Substation",[-2.165967084767571, 52.965090100766325]],
    [ "Remploy Substation",[-2.1625247891948525, 52.96660384347295]],
    [ "Beaconsfield Drive",[-2.162195849999999, 52.9712949]],
    [ "Mapleton Road Substation",[-1.2960806275608223, 50.91014881998135]],
    [ "SSE Power Distribution",[-1.2985967245802856, 50.90804060076284]],
    [ "Coniston Gardens Substation",[-1.3036559657799172, 50.907317229201205]],
    [ "183 Cranbourne Park Substation",[-1.2988384175822643, 50.90424874701109]],
    [ "Stockholm Drive Substation",[-1.3045977000449396, 50.9053434156703]],
    [ "224 Cranbourne Park Substation",[-1.3022296000000002, 50.904139050000005]],
    [ "Sherwood Avenue Substation",[-1.3042999651753981, 50.904008197169304]],
    [ "Heathhouse Gardens",[-1.3055125023236034, 50.9042340453116]],
    [ "Scottish and Southern Electricity Networks",[-1.3126453816298662, 50.90527860285181]],
    [ "SSE Power Distribution",[-1.3125368923751524, 50.908256963826524]],
    [ "SSE Power Distribution",[-1.3102728500000003, 50.90805590000001]],
    [ "SSE Power Distribution",[-1.3095497280053614, 50.90718085795341]],
    [ "SSE Power Distribution",[-1.3065870338949042, 50.907708096456]],
    [ "Scottish and Southern Electricity Networks",[-1.3103270185390576, 50.90525914222432]],
    [ "Pylands Housing Substation",[-1.3011972459868166, 50.90131436694304]],
    [ "Pylands Lane Substation",[-1.3070785000000003, 50.90119645]],
    [ "Dodwell Farm Substation",[-1.3093386, 50.89951675]],
    [ "Thorley Substation",[0.15652085273143748, 51.84785949104127]],
    [ "Lemon Hs Fm Substation",[-2.6480597399234833, 53.72035353645706]],
    [ "Wolverhampton Substation",[-2.114122844554496, 52.58115389395551]],
    [ "Virgin Active Substation",[-2.6814606827490297, 53.748463552873325]],
    [ "Capitol Centre Substation",[-2.6802311846629814, 53.74879595674061]],
    [ "Waitrose Winery Lane Substation",[-2.679709232724378, 53.7490694524651]],
    [ "Whiteley School Substation",[-1.2573712834180433, 50.88122985025553]],
    [ "Java Drive Substation",[-1.2607380392781766, 50.88132154310813]],
    [ "150 Botley Road Substation",[-1.2667954399259787, 50.87948337625565]],
    [ "Caspian Close Substation",[-1.265672306214197, 50.87812308928009]],
    [ "Southampton Road West Substation",[-1.260445685642851, 50.869501341676255]],
    [ "Hunts Pond Road North Substation",[-1.2644783506818538, 50.86982487348733]],
    [ "Substation",[-1.2662225999999999, 50.87047499999999]],
    [ "Station Road Substation",[-1.2706090408855886, 50.87252711723483]],
    [ "Middle Road Substation",[-1.268402831689483, 50.87229833014811]],
    [ "Bastins Close Substation",[-1.26715785, 50.874020449999996]],
    [ "Substation",[-1.2668040499999997, 50.875330399999996]],
    [ "Substation",[-1.2654940173775615, 50.876280066609056]],
    [ "Botley Road Substation",[-1.2678446683276932, 50.87598010193012]],
    [ "Substation",[-1.2562267, 50.872864549999996]],
    [ "Crompton Way Substation",[-1.258614552421621, 50.872476575680686]],
    [ "Adams Way Substation",[-1.2578057637337265, 50.871698574701924]],
    [ "PH McLanes Substation",[-1.2590182111592445, 50.87106427470398]],
    [ "Makro Substation",[-1.256968987984845, 50.87087572481512]],
    [ "Telstar Substation",[-1.2573839906644324, 50.87042897704836]],
    [ "Eagle Point Substation",[-1.2559464000000002, 50.8696274]],
    [ "Eccleshall Primary",[-2.2551628053285215, 52.85265704009565]],
    [ "Leek Substation",[-2.0328654879928454, 53.10192806910556]],
    [ "Battlefield Energy Recovery Facility Substation",[-2.725895651702873, 52.742650315731005]],
    [ "Sundorne Solar Farm Substation",[-2.7093886567765955, 52.732858700419555]],
    [ "Marlborough Gardens Substation",[-1.301208445464983, 50.93333576743787]],
    [ "Cheltenham Gardens Substation",[-1.2985093566234123, 50.93231933200032]],
    [ "Wrockwardine Solar Farm Substation",[-2.5785118111528136, 52.70330557922183]],
    [ "Maunsell Way Substation",[-1.2964551133872873, 50.924881222378424]],
    [ "Britannia Gardens Substation",[-1.3061311, 50.9337703]],
    [ "Stephenson Way Substation",[-1.3033835999999999, 50.9335287]],
    [ "Stanier Way Substation",[-1.2980985663811686, 50.930794597673724]],
    [ "SSE Power Distribution",[-1.2966334008301914, 50.930051299479786]],
    [ "SSE Power Distribution",[-1.29317965, 50.929787950000005]],
    [ "Sovereign Lodge Substation",[-1.3035030500000002, 50.9350265]],
    [ "Dothill Substation",[-2.5343653959766352, 52.71667008421745]],
    [ "Snedshill Substation",[-2.4422183388058136, 52.68865414999867]],
    [ "Sheriffhales Solar Farm",[-2.374235418672923, 52.709593224872165]],
    [ "Newport Substation",[-2.3948511398971046, 52.76475195749522]],
    [ "Madeley Substation",[-2.453561223163054, 52.650753488187235]],
    [ "Vodafone MTX Substation",[-2.6907088876926526, 53.704016021209]],
    [ "Chapel Brow Substation",[-2.6901462, 53.6971153]],
    [ "Pearfield Substation",[-2.6933125443106647, 53.69736400429796]],
    [ "Substation",[-2.6961581323386565, 53.69858520235407]],
    [ "Wheelton lane off Parish Garden Substation",[-2.695491013166555, 53.7007531177581]],
    [ "Centurion Way",[-2.692159587029831, 53.706209174773015]],
    [ "SSE Power Distribution",[-2.6963153999999996, 53.70647805000001]],
    [ "Substation",[-2.6963206227138774, 53.706478370368615]],
    [ "Moss Lane Outdoor Substation",[-2.6772094114095175, 53.69869941709]],
    [ "National Grid Electricity Distribution Plc",[-2.8100217286685547, 51.37954929247923]],
    [ "Compton Martin Primary Substation",[-2.6555702231469978, 51.32492910411503]],
    [ "Stancombe Quarry Primary Substation",[-2.7167431226606684, 51.41646490957961]],
    [ "Warsash Substation",[-1.2996272654749066, 50.854361854709374]],
    [ "Parkhurst Avenue Substation",[-2.6699465087125103, 53.70241536949763]],
    [ "Oak Tree Substation",[-2.6732571500000004, 53.69999280000001]],
    [ "Wychwood Grove Substation",[-2.668798630826049, 53.70064435281929]],
    [ "Kings Copse Road Substation",[-1.2963118709833685, 50.90665160173555]],
    [ "Old Bursledon Substation",[-1.3050731806009692, 50.884806598309005]],
    [ "SSE Power Distribution",[-1.2913256, 50.91102124999999]],
    [ "SSE Power Distribution",[-1.2929346413761857, 50.90981356964824]],
    [ "SSE Power Distribution",[-1.2928479742089471, 50.90723350435655]],
    [ "Tanhouse Lane Substation",[-1.2955935178679086, 50.90843727945006]],
    [ "Sneinton Substation",[-1.1325504534030717, 52.94876796117196]],
    [ "914937755",[-1.1293348104627674, 52.30498773773865]],
    [ "Denby Substation",[-1.4299587187479392, 53.0155997515062]],
    [ "Belper Substation",[-1.4769776689047873, 53.01551264937658]],
    [ "Ripley Primary Substation",[-1.406554179291829, 53.05749850985032]],
    [ "Substation",[-1.2825012545360412, 50.874941835655946]],
    [ "Allotment Road Substation",[-1.2867696447689092, 50.87287729848813]],
    [ "Bursledon Heights Substation",[-1.309500371149249, 50.88890170646161]],
    [ "Old Bridge House Road Substation",[-1.3058509532068763, 50.88685822785182]],
    [ "Hill Place Substation",[-1.3083603221907183, 50.88657906965822]],
    [ "SSE Power Distribution",[-1.303859433333333, 50.8867942]],
    [ "Substation",[-1.3025599226853335, 50.885795540735764]],
    [ "Substation",[-1.2995695, 50.883409949999994]],
    [ "Substation",[-1.3009129499999996, 50.8836223]],
    [ "Moodys Boatyard Substation",[-1.296654310111905, 50.881597747747065]],
    [ "Bentham Way Substation",[-1.2949165350908969, 50.885476000492446]],
    [ "Substation",[-1.2957786990844584, 50.883752958464555]],
    [ "Glen Road Substation",[-1.2816839386414536, 50.8831760183975]],
    [ "Swanwick Lane Substation",[-1.29214359552408, 50.8845092977149]],
    [ "Chad Valley Substation",[-1.9533633005717728, 52.47340879917961]],
    [ "Substation",[-2.6412808, 53.72761105000001]],
    [ "Express Foods Substation",[-2.9063436261291775, 52.79552878406819]],
    [ "Baschurch Substation",[-2.857888301632814, 52.80524883495393]],
    [ "West Felton Substation",[-2.974152558636091, 52.83064049464402]],
    [ "915133080",[-3.008871351612571, 52.92843218393138]],
    [ "Main Road",[-1.4113633948657685, 53.3882771742222]],
    [ "Helen Road",[-1.4116050579211827, 53.3907950233379]],
    [ "Express Dairy Coleford Road",[-1.4088433674748253, 53.39206771565951]],
    [ "Coleridge Road",[-1.4133703061476908, 53.393655027719554]],
    [ "Kettering North Substation",[-0.7351351, 52.42493195000001]],
    [ "Rockingham Data Centre Substation",[-0.6501148290366445, 52.51663075640476]],
    [ "Earlstrees Substation",[-0.6969040315071761, 52.51084760268871]],
    [ "Smeeth Primary",[0.9496729170777404, 51.10606840115659]],
    [ "Ashford West Primary",[0.7444093923796303, 51.14747695490701]],
    [ "East Lenham Solar Farm",[0.7129763630589947, 51.22804616214653]],
    [ "Prince of Wales",[-1.3194050195491651, 53.69864678483231]],
    [ "Hawthorn Close Substation",[-2.6421877555679734, 53.70195347807537]],
    [ "Carr Brook Substation",[-2.645574338897807, 53.701746733098666]],
    [ "Blackcroft Substation",[-2.64346178216193, 53.70426815129878]],
    [ "Sheephill Lane Substation",[-2.6414528031275295, 53.70430612039144]],
    [ "Meadway Substation",[-2.640212196889078, 53.70341004774288]],
    [ "Hazel Close Substation",[-2.63631177895058, 53.70426650506492]],
    [ "Preston Dairies Substation",[-2.7101621899479604, 53.75834478615645]],
    [ "West Cliff Terrace Substation",[-2.708125478084821, 53.75321450241426]],
    [ "South Meadow Lane Substation",[-2.707309530040705, 53.75050938647177]],
    [ "South Meadow Lane Flats Substation",[-2.7115953473493297, 53.75189071658417]],
    [ "Ardee Road Substation",[-2.7146536515996162, 53.751544705017515]],
    [ "917880740",[1.023948945730847, 51.25653116468]],
    [ "918021133",[1.3464779322862168, 51.291767677573404]],
    [ "918039041",[-1.8563076283645676, 53.696535893025064]],
    [ "Coniston Drive South Substation",[-2.6706284039720023, 53.73237599126058]],
    [ "Great Universal Stores Substation",[-2.6842165999999996, 53.759858050000005]],
    [ "George Street (Preston) Substation",[-2.6853464621959278, 53.75716643121451]],
    [ "Frenchwood Avenue Substation",[-2.6879101136962964, 53.75456589514519]],
    [ "Southern Parade Substation",[-2.6861297229234586, 53.753534221630275]],
    [ "Crosshills",[-1.9786933042034176, 53.8959324116567]],
    [ "918204377",[-2.0032751767190544, 53.95744915139064]],
    [ "918222029",[-1.8380159080915588, 53.59583772779828]],
    [ "Wensleydale",[-1.8505921, 54.30799045]],
    [ "Brownedge Close Substation",[-2.672007497147117, 53.72885131380482]],
    [ "919104833",[-1.3917251976907352, 51.36604751771554]],
    [ "919112442",[-1.2553524268293559, 51.33120919182197]],
    [ "919667139",[-0.95850045, 51.737804899999986]],
    [ "919788806",[0.1347910261160726, 52.23438621905067]],
    [ "Five Acres",[0.09010892135072446, 52.22115084857814]],
    [ "Substation",[-1.2460644693037624, 50.867635883719885]],
    [ "Astro Substation",[-1.2468583667688622, 50.867978650745556]],
    [ "Substation",[-1.2486150140471313, 50.869165138054015]],
    [ "Substation",[-1.2503281331658453, 50.87026404883038]],
    [ "Substation",[-1.2501090671370418, 50.869078523683825]],
    [ "Mitchell Close Substation",[-1.2521227388811753, 50.86929732379864]],
    [ "Substation",[-1.252542075339887, 50.868140305484935]],
    [ "Barnes Wallis Road Substation",[-1.2510707748184515, 50.86817677294319]],
    [ "Substation",[-1.2510486690711267, 50.86777468397878]],
    [ "Substation",[-1.24728100484782, 50.86653918286698]],
    [ "Substation",[-1.2476161332289895, 50.86602514925046]],
    [ "Substation",[-1.24608725, 50.8652928]],
    [ "Substation",[-1.2447320332289895, 50.86605934925046]],
    [ "Substation",[-1.2393582425396017, 50.86477276719514]],
    [ "Knights Bank Road Substation",[-1.2359679499999994, 50.8200233]],
    [ "Substation",[-1.2365577499999991, 50.820217150000005]],
    [ "Barnes Wallis Road East Substation",[-1.2429095512873494, 50.865049143731525]],
    [ "Plessey Titchfield Substation",[-1.2426962132440167, 50.859166803260216]],
    [ "Substation",[-1.2567086499999998, 50.8926041]],
    [ "Wellington Town Substation",[-3.2255558882190476, 50.97854690282091]],
    [ "Exminster Substation",[-3.494079037036983, 50.67577304226238]],
    [ "920265209",[-3.4112872463299087, 50.71223163021224]],
    [ "920307468",[0.9106780167796064, 51.81445279415679]],
    [ "Shrub End Primary Substation",[0.8649541425311509, 51.86973591126471]],
    [ "Fir Trees Substation",[-2.697764703053162, 53.72861110268381]],
    [ "Emily Street Substation",[-2.693543978682187, 53.72752363768496]],
    [ "SSE Power Distribution",[-1.2205252, 50.8225239]],
    [ "Wesley Street Substation",[-2.659168507434291, 53.72533837733847]],
    [ "Dudbridge Substation",[-2.2384208144772813, 51.740155191012605]],
    [ "920883348",[-2.149767400899382, 51.63989219992762]],
    [ "Scottish and Southern Electricity Networks",[-1.9936008471121658, 51.4511677011376]],
    [ "920938442",[-2.222804153103011, 51.55522070209891]],
    [ "920966100",[-1.5450723651786145, 51.93862306965787]],
    [ "Cottisford",[-1.1418110374918837, 51.97535747518251]],
    [ "921248268",[-1.7224248197482799, 51.874509733239414]],
    [ "921248274",[-1.6289016868638717, 51.81062478509448]],
    [ "Yattendon",[-1.22860195, 51.46543305000001]],
    [ "Substation",[-1.2805816999999997, 50.86609514999999]],
    [ "Brookfield Gardens SW STN Substation",[-1.281116332534382, 50.866893948681955]],
    [ "Admirals Wood Substation",[-1.280871917034248, 50.86819990138357]],
    [ "Substation",[-1.2825038, 50.87048505]],
    [ "Hawthorne Court Substation",[-1.276104231781329, 50.869847046977064]],
    [ "Substation",[-1.275355232508372, 50.87042857299087]],
    [ "Substation",[-1.2783766999999997, 50.87071535]],
    [ "Cold East Way Substation",[-1.27810345, 50.872350850000004]],
    [ "Pine Walk Substation",[-1.2764232870855257, 50.87343844397214]],
    [ "Chichester Close Substation",[-1.2891413391591828, 50.862553363209926]],
    [ "Substation",[-1.2848587855291038, 50.862405308507526]],
    [ "Strawberry Hill Road Substation",[-1.278739958750131, 50.86486079477074]],
    [ "Brook Lane North Substation",[-1.275115998510299, 50.8681851990155]],
    [ "Coach Hill Substation",[-1.2363863383079103, 50.84686384927312]],
    [ "2 Puffin Crescent Substation",[-1.2221295371057515, 50.8298761923475]],
    [ "Moody Road Switching Station Substaton",[-1.214219779039497, 50.81693373855252]],
    [ "Crofton Lane North Substation",[-1.2230946779852367, 50.82040539533491]],
    [ "SSE Power Distribution",[-1.2174399209951394, 50.82208739750232]],
    [ "SSE Power Distribution",[-1.2109521723382064, 50.82164806404695]],
    [ "SSE Power Distribution",[-1.2139590552360104, 50.82092223103622]],
    [ "SSE Power Distribution",[-1.2094382424068266, 50.82191668025445]],
    [ "SSE Power Distribution",[-1.2084634, 50.82320060000001]],
    [ "Substation",[-1.2107082499999997, 50.8253891]],
    [ "Substation",[-1.21602505, 50.82519890000001]],
    [ "Substation",[-1.218480641279058, 50.82556317515866]],
    [ "Substation",[-1.2222911, 50.82583939999999]],
    [ "1 Vicarage Lane Substation",[-1.2201382531220675, 50.8283536116124]],
    [ "Anzac Close Substation",[-1.2173070309584415, 50.829466796139904]],
    [ "Substation",[-1.2114292782316962, 50.831352048038774]],
    [ "Substation",[-1.2127353339126345, 50.83379423311744]],
    [ "Substation",[-1.2182157938516012, 50.83130621963115]],
    [ "Substation",[-1.2210012500000011, 50.8320807]],
    [ "Scottish and Southern Electricity Networks",[-1.2065722779044519, 50.80600929534252]],
    [ "Scottish and Southern Electricity Networks",[-1.210819494708723, 50.80875838359876]],
    [ "Scottish and Southern Electricity Networks",[-1.2127052999999999, 50.80975886666667]],
    [ "Scottish and Southern Electricity Networks",[-1.2170121894731785, 50.8119071858798]],
    [ "SSE Power Distribution",[-1.2134186500000002, 50.815336099999996]],
    [ "Substation",[-1.2240837500000001, 50.82343505]],
    [ "Substation",[-1.22643875, 50.825353050000004]],
    [ "Carisbrooke Avenue Substation",[-1.2267113343733294, 50.821157924805036]],
    [ "Old Street Substation",[-1.2317901836853606, 50.821414100162244]],
    [ "Solent Road Substation",[-1.2323074954423818, 50.81729209533969]],
    [ "Cottes Way Substation",[-1.2286233374428304, 50.81816266687157]],
    [ "Substation",[-1.2275889478637263, 50.81526634942789]],
    [ "SSE Power Distribution",[-1.2155382638929368, 50.81940565008075]],
    [ "SSE Power Distribution",[-1.2168391134524643, 50.81715187287842]],
    [ "SSE Power Distribution",[-1.2217006168916864, 50.81829825097178]],
    [ "SSE Power Distribution",[-1.2225168999999998, 50.8170378]],
    [ "Crofton Lane Substation",[-1.2253537919332393, 50.81778649549396]],
    [ "SSE Power Distribution",[-1.2209921000000001, 50.81982915]],
    [ "922579813",[-0.4370804164267507, 51.764167781342046]],
    [ "922579816",[-0.42615000966695543, 51.753134643100665]],
    [ "922598362",[-0.6054561681063889, 51.35956471825203]],
    [ "Egham Substation",[-0.5481389636132108, 51.43243670998536]],
    [ "922619789",[-0.5909947745787429, 51.38425638452039]],
    [ "922657416",[-0.6530182569203864, 51.399415681558715]],
    [ "922664964",[-0.5038983693252521, 51.43512691185921]],
    [ "922667030",[-0.46440921644893, 51.434328656178806]],
    [ "922696312",[-0.970845300000001, 51.46144025]],
    [ "Theale Substation",[-1.0697625, 51.4344325]],
    [ "Manchester Road Substation",[-2.692918859039726, 53.75849178313618]],
    [ "Guild Row Substation",[-2.696147557683139, 53.7592546975565]],
    [ "Nile Street Substation",[-2.6951604241059592, 53.75958203614665]],
    [ "Queen Street Retail Park Substation",[-2.6900048525494937, 53.75907939882116]],
    [ "923132714",[-4.648321040755045, 50.340809886729595]],
    [ "923137148",[-4.761773150840997, 50.37457051788751]],
    [ "St Austell Substation",[-4.789399063596017, 50.349786332287046]],
    [ "923167365",[-5.385014299999998, 50.178030449999994]],
    [ "Wellington Street",[-1.3647549675869552, 53.72541639314857]],
    [ "923340037",[-2.6182973072087683, 54.35879024458401]],
    [ "Brampton Drive Substation",[-2.669439675188965, 53.72845736410639]],
    [ "923726260",[-0.08029920233331922, 51.54181985469965]],
    [ "Cornwall Energy Recovery Centre Substation",[-4.890856724489246, 50.377358794540264]],
    [ "Substation",[-1.2491999669673124, 50.877461398092784]],
    [ "Coopers Wood Substation",[-1.254224419982198, 50.87735013366402]],
    [ "Harrod Way Substation",[-1.2535793000000002, 50.87716565]],
    [ "Solent 2 Street Lighting Substation",[-1.2532896832581855, 50.87747284961476]],
    [ "Solent 2 Op 2 Substation",[-1.2529731499999999, 50.877668750000005]],
    [ "Clare Acre Substation",[-1.25340431858097, 50.87808335294569]],
    [ "Yates Way Substation",[-1.2517307031946379, 50.87855513325422]],
    [ "SSE Power Distribution",[-1.2505114167418143, 50.879626750385256]],
    [ "Golden Grove Substation",[-1.2585738087049638, 50.87811310155056]],
    [ "Sweethills Meadows Substation",[-1.2595817032969632, 50.88401866716759]],
    [ "Sweethills Crescent Substation",[-1.2646125417232126, 50.88404310087973]],
    [ "Substation",[-1.3033286836710367, 50.847508229166905]],
    [ "Osborne Road Substation",[-1.2986165892189219, 50.849888299764125]],
    [ "Havelock Road Substation",[-1.3010550624437824, 50.85200586696615]],
    [ "Shore Road Substation",[-1.3012837785542957, 50.85287910225705]],
    [ "Crofton Way Substation",[-1.302590748924081, 50.8544707409293]],
    [ "Polycast Substation",[-1.3007520051553756, 50.85377802533386]],
    [ "Greenaway Lane West Substation",[-1.2966206876010924, 50.858406993776704]],
    [ "Brook Lane Substation",[-1.2920571277787434, 50.86248822044822]],
    [ "Barnbrook Road Substation",[-1.2865179289828312, 50.86466467516765]],
    [ "2 Barnes Close Substation",[-1.291085045031475, 50.86545634802297]],
    [ "17 Barnes Close Substation",[-1.2930709663865645, 50.865736033613445]],
    [ "Riccal",[-1.0324583387874482, 53.82592546101272]],
    [ "STILLINGFLEET",[-1.0832259650069973, 53.8568477283036]],
    [ "WHITEMOOR",[-0.9924606179600259, 53.81399895802314]],
    [ "Hambleton Crossing",[-1.157487229001749, 53.77752683852547]],
    [ "924786852",[1.0634301499999999, 51.95668834999999]],
    [ "Maldon Causeway Substation",[0.6841779214569593, 51.73836459846866]],
    [ "Tillingham Substation",[0.8688819259720423, 51.6895914272085]],
    [ "South Woodham Substation",[0.5948929057635582, 51.651074186025575]],
    [ "Haydons Road Track Paralleling Hut",[-0.18227355, 51.4247255]],
    [ "Bull's Lodge Substation",[0.5371121610281675, 51.765558098867835]],
    [ "BR Trackside Supply Substation",[0.5305171321302596, 51.761682242794734]],
    [ "925188650",[-0.323124952991982, 52.032972117846406]],
    [ "925188672",[-0.20636291773566684, 51.98385241588171]],
    [ "Newark 25KV",[-0.8115480936777613, 53.10206883013305]],
    [ "Yew Tree Substation",[-1.2667475501467134, 50.8821745000617]],
    [ "Lero Substation",[-1.1276310473813898, 52.644399086172164]],
    [ "925546247",[-0.22516809999999998, 51.82690135]],
    [ "Wildflower Drive Substation",[-2.662976490593997, 53.70124387699114]],
    [ "Lancaster Lane East Substation",[-2.6619962255632914, 53.69810716261172]],
    [ "Lancaster Lane West Substation",[-2.6721615744087335, 53.69668724148403]],
    [ "Substation",[-2.671121709437982, 53.69425622010804]],
    [ "Substation",[-2.662673532279544, 53.69616716873159]],
    [ "Addison Road Substation",[-1.2789310781961376, 50.87545364731157]],
    [ "Stone Pier Shipyard Substation",[-1.3063449762616675, 50.853520938137066]],
    [ "Thornton Avenue Substation",[-1.3051067847613718, 50.8535226380283]],
    [ "SSE Power Distribution",[-1.281108745166853, 50.87539291848025]],
    [ "Substation",[-1.2869804497726565, 50.87620093162804]],
    [ "Sopwith Park Substation",[-1.2505484359405807, 50.87234103135159]],
    [ "Clydesdale Road Substation",[-1.2624391401864479, 50.879825180130304]],
    [ "925912202",[-0.8743035790095329, 52.20534384727166]],
    [ "925918440",[-1.04558432415936, 52.354453044065956]],
    [ "Suffolk Drive Substation",[-1.265056551660165, 50.88153321965878]],
    [ "The Grange Substation",[-1.2656523808763067, 50.88866534545391]],
    [ "Hillsons Road Substation",[-1.2610655337363588, 50.91618334567719]],
    [ "School Lane (Bamber Bridge) Substation",[-2.66086101091391, 53.73636936911026]],
    [ "HS1 Ltd",[0.8515921, 51.15564349999999]],
    [ "HS1 Ltd",[0.8545654623353627, 51.15371406312741]],
    [ "HS1 Ltd",[0.854369735265938, 51.153845746009324]],
    [ "HS1 Ltd",[0.8645077640690522, 51.14770521776022]],
    [ "HS1 Ltd",[0.81173485, 51.19109029999999]],
    [ "Goxhill Road",[-0.35125597628311594, 53.67096701483666]],
    [ "West Middlemere Rd",[-0.2895399618769671, 53.629984231094475]],
    [ "HS1 Ltd",[0.6340240624776492, 51.255288464143085]],
    [ "HS1 Ltd",[0.5768925761589916, 51.28548465449081]],
    [ "HS1 Ltd",[0.4921615474447427, 51.35724253445406]],
    [ "HS1 Ltd",[0.45230996681407154, 51.39351646510782]],
    [ "HS1 Ltd",[0.31923119815711926, 51.44643631982184]],
    [ "HS1 Ltd",[0.31602742016705615, 51.44980961488188]],
    [ "HS1 Ltd",[0.272528, 51.46980945000001]],
    [ "HS1 Ltd",[0.23562920249192695, 51.491013389934864]],
    [ "HS1 Ltd",[0.19193235, 51.515358]],
    [ "Elgar Road",[-0.4173578380658648, 53.73531401706486]],
    [ "St Cuthberts Lane Substation",[-1.2648150811329908, 50.865568629076996]],
    [ "Laurel Road Substation",[-1.2675132789082804, 50.86577623664597]],
    [ "Hardy Close Substation",[-1.266458551835915, 50.86846788015368]],
    [ "Substation",[-1.2677870151892172, 50.87009352552936]],
    [ "Substation",[-1.2688525, 50.86878695000001]],
    [ "Hollybrook Gardens Substation",[-1.2719543404097666, 50.86938319868226]],
    [ "Substation",[-1.2759556499999993, 50.863595450000005]],
    [ "Old Common Substation",[-1.2719679103208705, 50.86545971841567]],
    [ "Downland Close Substation",[-1.2724707890160363, 50.86638276226291]],
    [ "Brook Lane School Substation",[-1.282483815377086, 50.86517890325789]],
    [ "Barnes Lane Substation",[-1.2893780935682413, 50.86707300445079]],
    [ "Sarisbury Court Substation",[-1.2972691791360846, 50.86754530044995]],
    [ "Holly Hill Leisure Centre Substation",[-1.2878679999999998, 50.86920675]],
    [ "Crableck Lane Substation",[-1.2945494134261755, 50.87324793850671]],
    [ "Bridge Road Substation",[-1.2931783473579395, 50.87899399172756]],
    [ "927654342",[-0.10921684239330981, 51.70786946899701]],
    [ "Hammerton Farm Substation",[-1.2906803333333334, 50.91559496666667]],
    [ "Haileybury Gardens Substation",[-1.3030949272671275, 50.92656743488215]],
    [ "Hazel Close Substation",[-2.654008079120792, 53.730503373281984]],
    [ "Hollyhill Lane - 37 Substation",[-1.2917497792817916, 50.874359412856975]],
    [ "Strawberry Fields Substation",[-1.2895908503326354, 50.86099825944954]],
    [ "Rock Farm ESS Substation",[-2.6889889931328783, 52.368379517892315]],
    [ "Places for People T1 Substation",[-2.6739345999999973, 53.71960415]],
    [ "Places for People T2 Substation",[-2.673920100000003, 53.71964325]],
    [ "Craven Drive Substation",[-2.6739056000000003, 53.71968235]],
    [ "Common Lane Substation",[-1.2463239500000003, 50.848458650000005]],
    [ "Brook Street",[-1.5418678499999998, 52.29101674999999]],
    [ "Brading Avenue Substation",[-1.174596617953319, 50.82061628664115]],
    [ "Morganite Substation",[-1.2469489810959433, 50.86800544677089]],
    [ "Dewhurst Clough WWTW Substation",[-2.6896342503629684, 53.74459708336318]],
    [ "RAF Waddington Substation",[-0.5331562851444495, 53.175627125672335]],
    [ "Lyme Road Substation",[-2.694655314672873, 53.734036323206176]],
    [ "Substation",[-1.25274721858097, 50.87869265294569]],
    [ "935723326",[-0.48808669015860096, 50.952336397503124]],
    [ "Sherwood Park",[-1.2610675295733245, 53.07064035224926]],
    [ "Superbowl",[-1.545320791320363, 52.279323630510675]],
    [ "Littlebrook 132kV Substation",[0.24501515133402652, 51.45986430504442]],
    [ "Tathwell New Lane",[-0.01905209192778499, 53.32838511330152]],
    [ "938204113",[-1.259887894303884, 53.33432697256184]],
    [ "Hook Recreation Ground Substation",[-1.2728881093489985, 50.84637687963085]],
    [ "Southampton Road Substation",[-1.2435233927971647, 50.85458203935405]],
    [ "Oldfield Park 11 kV Substation",[-2.378220800000001, 51.37338165]],
    [ "Substation",[-1.21312155, 50.8474286]],
    [ "Substation",[-1.2164503999999996, 50.84700955000001]],
    [ "Substation",[-1.216186014483306, 50.847817676299435]],
    [ "Substation",[-1.2202065331989247, 50.847158299462365]],
    [ "Substation",[-1.2226748943734411, 50.848559586253266]],
    [ "Substation",[-1.2222638437696003, 50.84603183076206]],
    [ "Substation",[-1.2256139500000003, 50.84618005000001]],
    [ "Substation",[-1.2234614705646292, 50.84727319356569]],
    [ "Ranvilles Lane Substation",[-1.2251374278065816, 50.84931944224534]],
    [ "Robin Wood Substation",[-2.123361073701814, 53.72716468557928]],
    [ "Portsmouth Mill Substation",[-2.1556302636098037, 53.73309834282037]],
    [ "Bradon Farm Gen",[-2.910732822061987, 50.97455741907492]],
    [ "939966279",[-1.8971690761016464, 52.74994246141897]],
    [ "939966280",[-1.897317925315686, 52.74984280429056]],
    [ "Washwood Heath Substation",[-1.8580294460755142, 52.498714390205414]],
    [ "Entry Hill Substation",[-2.3645117366956176, 51.36061847961936]],
    [ "Eastville Substation",[-2.5507574317492585, 51.46824413434023]],
    [ "Galton Junction Substation",[-1.9789862999999999, 52.50138989999999]],
    [ "BR Trackside",[-1.933654927155163, 52.48706601202489]],
    [ "Willenhall BR Substation",[-2.067455037661178, 52.58318515212371]],
    [ "Barton Abrasives Substation",[-2.0278464698474514, 52.534480599451264]],
    [ "Kingstanding Substation",[-1.8801438984064094, 52.55536408405836]],
    [ "Ipsley Substation",[-1.9146555700578505, 52.295919112720654]],
    [ "Stow Substation",[-1.770073248877843, 51.924117443316526]],
    [ "Port Ham Substation",[-2.266206492640145, 51.871652027885446]],
    [ "940760143",[-2.621515331616484, 52.054794590917595]],
    [ "Henley Solar Farm Substation",[-2.800615391921761, 52.49185387082784]],
    [ "Rolls Royce Substation",[-2.564706909501681, 51.52685433189422]],
    [ "Rockingham Generation Station",[-2.6834218088331774, 51.52029968291423]],
    [ "Avonmouth Docks Substation",[-2.700917174738844, 51.50394640076455]],
    [ "Avonmouth Biogas Substation",[-2.6880464999999987, 51.51125899999999]],
    [ "West Dock Portbury Substation",[-2.7232070587758637, 51.4904802622451]],
    [ "Lawrence Weston Solar Park Substation",[-2.66645055, 51.50648920000001]],
    [ "Mangotsfield Substation",[-2.494385316104565, 51.486181920701675]],
    [ "Abbeywood Substation",[-2.5615344423937145, 51.50460557627429]],
    [ "Astra Zeneca Substation",[-2.659689694033359, 51.54699440326189]],
    [ "Bedminster Substation",[-2.5965891682987174, 51.441662542159605]],
    [ "Bishopsworth Substation",[-2.6077406540317267, 51.414897280877895]],
    [ "Baddesley Energy Park Substation",[-1.5846927000000004, 52.5729868]],
    [ "940961825",[-1.4643295494508153, 52.88410951657782]],
    [ "Goitside Substation",[-1.4450224207818985, 53.23303264875907]],
    [ "Robin Hood Substation",[-1.5801851998390526, 53.24357091289889]],
    [ "Wormsey Wood Substation",[1.0234427371290162, 51.92570242942125]],
    [ "Isles Of Scilly Primary Substation",[-6.3096309548287, 49.91382122715968]],
    [ "Pentle Bay Switching Station",[-6.323885091977155, 49.94898870737937]],
    [ "Higher Town St. Martins Substation",[-6.282752400000001, 49.960697399999994]],
    [ "Porthcressa Flats Substation",[-6.313358173948229, 49.913978996628536]],
    [ "Lower Town Switching Station",[-6.301443837071713, 49.96516907824879]],
    [ "Rushy Point Switching Station",[-6.323400524524573, 49.95501935767137]],
    [ "The Quay Bryher Substation",[-6.351662225648086, 49.952561113107265]],
    [ "Taunton Local Substation",[-3.1009896711395015, 51.01715041858111]],
    [ "Luxulyan Solar Park Substation",[-4.753870619452207, 50.40108127435049]],
    [ "Par Harbour Substation",[-4.710236192722987, 50.34341924751803]],
    [ "Four Burrows Blackdown Substation",[-5.149456784835574, 50.28861413613176]],
    [ "Penzance Causewayhead Substation",[-5.539261700002974, 50.12009784310626]],
    [ "Coventry Central Substation",[-1.5074087060193069, 52.42016195151576]],
    [ "Coton Road Substation",[-1.4668884408817378, 52.52125721911983]],
    [ "Haywards Heath Primary",[-0.10493756524455101, 51.0000737945903]],
    [ "Ashby de la Zouch Substation",[-1.4809085745151425, 52.758366194558306]],
    [ "Prestop Fm Burton Rd Ashby Substation",[-1.49893665, 52.75261274999999]],
    [ "High St",[-2.004866187306408, 53.55045992217837]],
    [ "Stanford Primary",[1.0421506333152835, 51.10837965553994]],
    [ "Locksheath School Substation",[-1.257991166850311, 50.858589407185086]],
    [ "Stoke CHP",[-2.194682035671374, 52.98636413192252]],
    [ "McCARTHY & STONE UPPERMILL",[-2.0046279507545077, 53.54964853528409]],
    [ "Waterhead",[-2.0771833268928543, 53.54993746369867]],
    [ "Church Road Substation",[-6.3095192, 49.913839349999996]],
    [ "Jerusalem Terrace Substation",[-6.317990216486823, 49.91551961684783]],
    [ "Hughtown Substation",[-6.31691125, 49.91433195]],
    [ "Bbc Station Substation",[-6.305164430554998, 49.932361855573404]],
    [ "Cover Hill",[-2.0482610112033854, 53.535992128347345]],
    [ "Den Brook Wind Farm Substation",[-3.8607601649353662, 50.78472810344096]],
    [ "Longfellow Crescent",[-2.0763518999999997, 53.568470350000005]],
    [ "Water Lane STOR Generation Substation",[-3.5229581999999993, 50.707702950000005]],
    [ "Heavitree Substation",[-3.490733880059343, 50.72051492648207]],
    [ "Countess Wear Substation",[-3.493849599161482, 50.70144261054807]],
    [ "Makro Exeter STOR Generator Substation",[-3.514222880058652, 50.69561756551946]],
    [ "St Thomas Substation",[-3.546176078107471, 50.719510305422]],
    [ "Cowley Road Substation",[-3.541625610206124, 50.73496460065002]],
    [ "Topsham Substation",[-3.464401232859054, 50.68444621737079]],
    [ "Hedge End Substation",[-1.3032019991320787, 50.912604500221434]],
    [ "Roundswell Substation",[-4.0814377190981865, 51.0631934004647]],
    [ "Rock Park Substation",[-4.053403669668488, 51.06757250587815]],
    [ "Horsacott Solar Park Substation",[-4.11218215, 51.06377029999999]],
    [ "Fremington Substation",[-4.14580725, 51.066719400000004]],
    [ "Ernesettle B&S Substation",[-4.189620030099845, 50.423318071814236]],
    [ "Ernesettle Lane STOR Substation",[-4.187981240074995, 50.421240221998836]],
    [ "Tamerton Bridge STOR Substation",[-4.18714418091427, 50.42165831906893]],
    [ "Hythe Main Primary",[1.0928711787033905, 51.07288667950301]],
    [ "Elim Terrace Substation",[-4.136782508534143, 50.38761089249847]],
    [ "Longbridge Substation",[-4.084268989030695, 50.392720541559804]],
    [ "Eggbuckland Substation",[-4.111840621644332, 50.400813810185156]],
    [ "St Levan Road Substation",[-4.1719453870415935, 50.38460059661711]],
    [ "North Intake Substation",[-4.181696449978887, 50.386263188015576]],
    [ "Central Intake Substation",[-4.1806470477048645, 50.377583639709044]],
    [ "Newport Street Substation",[-4.164224918644701, 50.367913897694834]],
    [ "Old Laira Road Substation",[-4.119809836653847, 50.38177152759009]],
    [ "Armada Street Substation",[-4.135270448483283, 50.37592152632649]],
    [ "Adelaide Road Substation",[-4.148728342388704, 50.3691490093699]],
    [ "Buckwell Street Substation",[-4.138029920156787, 50.36950136980528]],
    [ "Warleigh Barton Solar Park Substation",[-4.17618565, 50.42715815]],
    [ "Plymstock South Substation",[-4.101370721853041, 50.35238346506324]],
    [ "944225467",[-4.100712351060665, 50.37369208120533]],
    [ "Newton Downs Solar Park Substation",[-4.030458950000001, 50.3287616]],
    [ "Torycombe Substation",[-4.025111628669967, 50.43290326585083]],
    [ "Hemerdon Mine Substation",[-4.014430049999999, 50.41338329999999]],
    [ "Newnham Farm Solar Park Substation",[-4.028905599999999, 50.40851204999999]],
    [ "Alma Road Substation",[-4.154576811518694, 50.3833083544014]],
    [ "Dover Primary",[1.31076753996253, 51.128954216635925]],
    [ "Laywell Brixham Substation",[-3.5243156023533087, 50.384218101969005]],
    [ "Electricity North West",[-2.2203498, 54.00587650000001]],
    [ "Teignmouth Gasworks Substation",[-3.516011512595256, 50.54692622827464]],
    [ "Barton Substation",[-3.5365029576014346, 50.4883814259777]],
    [ "Torwood Substation",[-3.5206512500000002, 50.46119504999999]],
    [ "Hollicombe Substation",[-3.557513823579946, 50.45320513005052]],
    [ "Colley End Substation",[-3.5708260182777565, 50.43846760006954]],
    [ "Totnes Substation",[-3.688454145833368, 50.43343137580628]],
    [ "Salcombe Substation",[-3.7780857179273517, 50.234468897178076]],
    [ "Modbury Substation",[-3.894289156958039, 50.351229929259205]],
    [ "Axe View Solar Farm Substation",[-2.9525515499999995, 50.81319585]],
    [ "Northam Substation",[-4.210263374651126, 51.041699476205515]],
    [ "Park Lane Substation",[-4.207098773114489, 51.023662546782035]],
    [ "West Hill Solar Farm Substation",[-4.141490475771371, 51.14509315204855]],
    [ "Georgeham Substation",[-4.1775399, 51.15109904999999]],
    [ "Moor Monkton Pumps",[-1.1987908992533118, 54.01054092256441]],
    [ "Wansbrough Paper Mills Substation",[-3.34130755, 51.17780825]],
    [ "R.O.F. Power House Substation",[-2.959222882762597, 51.17248453409786]],
    [ "Bridgwater Local Substation",[-3.0081116552259344, 51.12919649139752]],
    [ "Dunsland Cross Wind Farm Substation",[-4.260590777230384, 50.8063517036825]],
    [ "Batsworthy Cross Wind Farm Substation",[-3.6913031906188114, 50.980371268252114]],
    [ "Kingsland Barton Solar Park Substation",[-3.8657101240435976, 51.01864677456382]],
    [ "Ashcombe Solar Park Substation",[-3.531512749999999, 50.589968649999996]],
    [ "Goonhilly Solar Park Substation",[-5.206894750000001, 50.04005015]],
    [ "Goonhilly Wind Farm Substation",[-5.202849856853574, 50.04151707395318]],
    [ "Rew Farm Solar Park Substation",[-4.691671405112402, 50.42391263271665]],
    [ "Haye Lane Solar Park Substation",[-4.325142175065596, 50.50913844533496]],
    [ "Trequite Solar Farm Substation",[-4.369891149999999, 50.42531085]],
    [ "Trehawke Farm Substation",[-4.380259416951097, 50.43432857323954]],
    [ "Freathy Solar Substation",[-4.251752536176077, 50.3537610745413]],
    [ "Exmouth Works Substation",[-3.4058311971993023, 50.623505300277195]],
    [ "Withycombe Raleigh Substation",[-3.393864179548684, 50.63240609284569]],
    [ "Brookend Lane",[-2.2127331758438946, 52.145775731452545]],
    [ "Napleton Lane",[-2.2131014591364884, 52.13596166817254]],
    [ "Main Road Kempsey",[-2.216450440185616, 52.135220028130526]],
    [ "Foxes Hill IDNO",[-2.2105055407970813, 52.13805802428036]],
    [ "Post Office Lane",[-2.215305331707577, 52.13953735980801]],
    [ "King Alfred Road Kempsey",[-2.2152057500000004, 52.1388733]],
    [ "945819334",[-2.2179495919002044, 52.141363578232166]],
    [ "945819336",[-2.2209117333333337, 52.141627699999994]],
    [ "Ketch",[-2.2170287110497346, 52.16463832979703]],
    [ "Princes Close",[-0.5747317728381394, 51.76758378794926]],
    [ "Darenth Road Old Foundary",[0.2293242, 51.433000400000005]],
    [ "Four Ashes Substation",[-2.1170138923531048, 52.67622581922011]],
    [ "St Margarets Road East Substation",[1.7518847703635834, 52.486480214922764]],
    [ "Tesco",[-1.5622291830858468, 52.28657895060889]],
    [ "Warwick Substation 33kV",[-1.5658146803402373, 52.285769012814164]],
    [ "Substation",[-1.1623603426352946, 50.81780491318011]],
    [ "Substation",[-1.1622028445738655, 50.82027408200953]],
    [ "SSE",[-1.1635664000000001, 50.82498644999999]],
    [ "SSE",[-1.16357865, 50.82502425]],
    [ "Substation",[-1.1645728434179026, 50.825512727267736]],
    [ "Substation",[-1.1649707500000002, 50.821041550000004]],
    [ "Braemar Road Substation",[-1.1670708711257811, 50.82389872241297]],
    [ "Wickham Substation",[-1.1831555999999999, 50.89570849999999]],
    [ "Texas Retail Substation",[-1.3137406500000002, 50.917313750000005]],
    [ "Claverdon Substation 11kV",[-1.6953153085558688, 52.276844101542295]],
    [ "Navigators Way Substation",[-1.3022218079500387, 50.92334915499084]],
    [ "Wick Road",[-0.6438813377093295, 51.78068929637554]],
    [ "Dudswell",[-0.601271808522735, 51.77802875221677]],
    [ "Lower Town St. Martins Substation",[-6.3019065596963415, 49.96594272222739]],
    [ "Middle Town St. Martins Substation",[-6.29680068554885, 49.96511726834061]],
    [ "Reading Room St Martins Substation",[-6.290939607429437, 49.96424377822904]],
    [ "School St. Martins Substation",[-6.2877619233007325, 49.963529314915284]],
    [ "Tamarisk Farm Substation",[-6.3477819107477895, 49.891542224027894]],
    [ "Troy Town Substation",[-6.35101245, 49.890762450000004]],
    [ "Newent 66kV",[-2.38039655, 51.92867484999999]],
    [ "Borough Farm Tresco Substation",[-6.3251187612254745, 49.9537279240042]],
    [ "Abbey Farm Tresco-New Substation",[-6.337300289261536, 49.953754770145494]],
    [ "Tresco Island Substation",[-6.334774636540242, 49.96011380717079]],
    [ "The Town Bryher Substation",[-6.3532109000000005, 49.954365800000005]],
    [ "Northward Bryher Substation",[-6.350723218073611, 49.95723684711207]],
    [ "Hell Bay Hotel Substation",[-6.3570548, 49.95137545000001]],
    [ "Boathouse Bryher Substation",[-6.3522567500000005, 49.94924570000001]],
    [ "Southward Bryher Substation",[-6.353629078423435, 49.950954005084014]],
    [ "953378603",[-1.5201804736169258, 54.975802621176946]],
    [ "Desford Substation",[-1.271542547557291, 52.63943497258292]],
    [ "Lindridge Farm Solar Park Substation",[-1.3214030000000014, 52.642104200000006]],
    [ "Caterpillar Desford Substation",[-1.2952649062767707, 52.61031498045269]],
    [ "Melbourne Substation",[-1.4337167888928515, 52.80905780225477]],
    [ "Worthington Substation",[-1.3793943984037988, 52.78254641569945]],
    [ "Mantle Lane Substation",[-1.3751672101310086, 52.72652194886357]],
    [ "Bardon Substation",[-1.347870177848783, 52.71348164585678]],
    [ "Interlink Park Substation",[-1.3465141087188865, 52.69877564992201]],
    [ "Babelake Street Packington Substation",[-1.474533326156723, 52.710448458354236]],
    [ "Higher Tregarne Solar Farm Substation",[-5.127318830163706, 50.131961024207605]],
    [ "Woodgrange Park Substation",[0.04908247033018188, 51.54781271587418]],
    [ "Ashyns Road",[-0.5708986404296954, 51.75517175987821]],
    [ "Kerhaw Lane N",[-2.1359116243108547, 53.47839870393598]],
    [ "955899490",[0.7580880467104028, 51.365244761241094]],
    [ "955899669",[-2.0144768178012353, 53.504848397214715]],
    [ "955901367",[-2.0272530999999976, 53.5248851]],
    [ "Ladysmith Barracks",[-2.064905669403309, 53.49867595230855]],
    [ "955904783",[-2.0741699999999996, 53.56761004999999]],
    [ "SSE plc",[-1.8130501500000002, 50.7306105]],
    [ "Players Triumph Road",[-1.1860304226079124, 52.954625375365175]],
    [ "National Grid Electricity Distribution",[-1.1982315000000001, 52.944403900000005]],
    [ "Unite Student Accommodation",[-1.1811614964027541, 52.948633343399344]],
    [ "Unit 2 Castle Meadow Retail Park",[-1.1613226500000005, 52.9463508]],
    [ "Sainsburys Superstore",[-1.1613272559722179, 52.94687191775363]],
    [ "Heaton Moor Electricity Substation",[-2.188068354825425, 53.41873956259634]],
    [ "Bakewell Substation",[-1.6893203142743083, 53.21641976517632]],
    [ "Whaplode Drove Substation",[-0.05191140348824276, 52.69781170141749]],
    [ "Ketton Cement Substation",[-0.5447667104502514, 52.64206027057296]],
    [ "The Grange Wind Farm Substation",[0.1626204661629092, 52.75095520129738]],
    [ "Cranwell Substation",[-0.4983609902123652, 53.03678819236653]],
    [ "Biwater Substation",[-1.4055371440174123, 53.173932219956654]],
    [ "Wingerworth Substation",[-1.4197888, 53.20707595]],
    [ "Sheepbridge Substation",[-1.4425266763310132, 53.27013555657754]],
    [ "Sheffield Road Substation",[-1.4256839885016412, 53.248218306157966]],
    [ "Robert Hyde Substation",[-1.4194818579422201, 53.23074531326178]],
    [ "Walton Substation",[-1.4660488009058652, 53.21647529219683]],
    [ "Erin Road Substation",[-1.3331613299681522, 53.25727206042179]],
    [ "Worksop West Substation",[-1.138371687108328, 53.31428171319035]],
    [ "Acreage Lane Substation",[-1.2084200880727782, 53.19515845181089]],
    [ "Stonish Hill Wind Farm Substation",[-1.011934112573861, 53.151457026210736]],
    [ "Hermitage Lane Generation Substation",[-1.2175242499999999, 53.13273725]],
    [ "Lime Tree Place Substation",[-1.191267742543298, 53.14135695655158]],
    [ "Skegby Lane Substation",[-1.2177510358207595, 53.137725683655304]],
    [ "Huthwaite Substation",[-1.2912589822837715, 53.126930335998]],
    [ "Sapcote Substation",[-1.2548311051993886, 52.536266949852674]],
    [ "Lutterworth Substation",[-1.2167575328857023, 52.455257369352424]],
    [ "Magna Park Substation",[-1.2506752081583234, 52.449107507984365]],
    [ "Churchover Substation",[-1.2729572359062944, 52.41519926129846]],
    [ "Stoneygate Substation",[-1.1015172673612692, 52.61592036575582]],
    [ "Braunstone Substation",[-1.1627318568732543, 52.609836263315195]],
    [ "Hockley Farm Road Substation",[-1.187586671069549, 52.63037470990697]],
    [ "Groby Road Substation",[-1.1729985659546467, 52.650556447948404]],
    [ "Thurmaston Substation",[-1.0942941010557115, 52.66412675886082]],
    [ "Thurnby Substation",[-1.0526340730769792, 52.634740092112]],
    [ "Pebble Hall Generation Substation",[-1.027867, 52.454345]],
    [ "Alliance and Leicester Substation",[-1.2073452497619013, 52.58307618835599]],
    [ "Whetstone Substation",[-1.1847792604617804, 52.56767243397439]],
    [ "Normanton Substation",[-1.4925421819837494, 52.899056846992536]],
    [ "Chaddesden Substation",[-1.4409454964642028, 52.92481592247428]],
    [ "Trafalgar Park Gas Peaking Substation",[-1.4743110231196188, 52.89165629344878]],
    [ "Derby Waste Sinfin Lane Substation",[-1.479471568192827, 52.89311059240475]],
    [ "Allenton Substation",[-1.4531389410695033, 52.8941865576694]],
    [ "Bombardier Substation",[-1.461753937480092, 52.904698557534154]],
    [ "Eagle Centre Substation",[-1.4715088166842376, 52.919577899886384]],
    [ "Mackworth Substation",[-1.5201596680999154, 52.92068442670897]],
    [ "Nottingham Road STOR Substation",[-1.2679982046031764, 52.90087550980524]],
    [ "London Road Heat Station Substation",[-1.140629499999999, 52.948972999999995]],
    [ "Colwick Substation",[-1.0804030957260775, 52.96038442903443]],
    [ "National Grid Electricity Distribution Plc",[-1.0706656410056907, 52.95707217241604]],
    [ "Electricity Substation West Of Multi Storey Car Park",[-1.154490642504195, 52.95557611019128]],
    [ "West Bridgford Substation",[-1.1398663603328147, 52.92495935044785]],
    [ "Lenton Substation",[-1.1890091636891005, 52.94382266156795]],
    [ "Boots Substation",[-1.187997908370832, 52.92394710483383]],
    [ "Redfield Road 2 STOR Substation",[-1.1784510652449816, 52.93407847863528]],
    [ "Redfield Road 1 STOR Substation",[-1.1783611500000002, 52.93411275000001]],
    [ "Sandiacre Substation",[-1.2968044332249367, 52.92245287545429]],
    [ "Little Hallam Substation",[-1.309216447546218, 52.95956527168676]],
    [ "Clifton Substation",[-1.1784702846644415, 52.90392525254768]],
    [ "Red Bank",[-2.234188569501748, 53.49002541958787]],
    [ "Electricity North West",[-2.2762436608359047, 53.489525275718464]],
    [ "CLARKSFIELD RD",[-2.0836938826175713, 53.541965646999486]],
    [ "Cury Estate Substation",[-5.242353739309397, 50.046088911636566]],
    [ "chester road",[-2.2866387060048865, 53.46188886762544]],
    [ "Green Street",[-2.631555176949165, 53.53982030785842]],
    [ "Queen's Medical Centre",[-1.1826534139189349, 52.94201244484526]],
    [ "New Harlow Primary",[0.11023629919494252, 51.7807993683676]],
    [ "961740418",[-1.830514902191302, 53.68502845724309]],
    [ "CIS Tower",[-2.2375751, 53.4867622]],
    [ "961953609",[-2.3682634067877233, 53.467580027621345]],
    [ "Orchard Grove",[-2.1678590797336765, 52.14873410534074]],
    [ "Winwick Wind Farm Substation",[-1.0613006166181551, 52.3667577140317]],
    [ "962710774",[-2.2464745666666666, 52.163058033333336]],
    [ "Grange WF",[-0.6911327311072302, 53.63196122116155]],
    [ "964270432",[-0.27251375800906796, 51.96732131662004]],
    [ "964719585",[-0.160608051844654, 51.65552635893907]],
    [ "Tengore Lane Solar Park Substation",[-2.790244169951402, 51.04336573843852]],
    [ "966582430",[-2.273273254514926, 52.105196064922076]],
    [ "967274970",[-0.5325480551765166, 51.578231497113165]],
    [ "967665644",[-0.4812307589787085, 53.76721267014715]],
    [ "967665645",[-0.47743283956457955, 53.770708567048686]],
    [ "967665646",[-0.39512578154108435, 53.81812538187975]],
    [ "967665647",[-0.3955850588544152, 53.802842220254576]],
    [ "967676697",[-0.6752598018498228, 53.61170189744762]],
    [ "967781844",[-0.48613744961504485, 52.086722374319216]],
    [ "Poplar Street",[-1.1385905164869925, 52.950453621859715]],
    [ "Holme Place Health Club Great Northern Close",[-1.139682464917896, 52.947967418832455]],
    [ "Mina Road Substation",[-2.5743907023624084, 51.46698822143335]],
    [ "Cherry Tree Substation",[0.135577002625141, 51.5530403934895]],
    [ "970708078",[0.08757796388769819, 51.562988063539756]],
    [ "970708111",[0.08710736442688023, 51.5636414818848]],
    [ "Dry Drayton Village",[0.018327749999999997, 52.24008485]],
    [ "Marksbury 33kV Solar Park",[-2.503181902463357, 51.35084376733236]],
    [ "Amersham Substation",[-0.5926187989703374, 51.67203579197772]],
    [ "Harefield Substation",[-0.49917653333333334, 51.6102724]],
    [ "Four Burrows Windfarm Substation",[-5.134946312295608, 50.29343689141778]],
    [ "Otterham Wind Farm Substation",[-4.608250954541546, 50.68225924124182]],
    [ "Bradley Lane Substation",[-3.6182422383393, 50.52912248755876]],
    [ "Newton Abbot Substation",[-3.5998009612417783, 50.532806159067064]],
    [ "Tricky Warren Switching Station",[-3.1208682124412483, 50.93472398234068]],
    [ "Whitley Farm 33kV Solar Fm",[-2.793306200000001, 51.12964664999999]],
    [ "Shooters Bottom Solar Park Substation",[-2.5875026500000002, 51.24797605]],
    [ "Ashlawn Farm Solar Park Substation",[-2.903994999999999, 51.257411950000005]],
    [ "Tonedale Farm Solar Park Substation",[-3.2522352500000005, 50.98078555]],
    [ "Whitchurch Master Substation",[-2.5559256653365257, 51.404318228065975]],
    [ "Aller Court Solar Farm Substation",[-2.8722173749048197, 51.05825905755717]],
    [ "Capelands Farm Solar Substation",[-3.9134393469082807, 51.1338947745969]],
    [ "Pylle Solar Park Substation",[-2.5308943499999996, 51.147484150000004]],
    [ "East Brent Substation",[-2.9295891800170684, 51.277115131564656]],
    [ "Park Wall Solar Park Substation",[-2.971989855894903, 51.118605220201154]],
    [ "St Marychurch Substation",[-3.5221483805318616, 50.48276463944335]],
    [ "Weston Central Substation",[-2.97970425, 51.34565645]],
    [ "Whatley Quarry Substation",[-2.388414400000001, 51.228866999999994]],
    [ "Upton Valley Substation",[-3.5325524015555008, 50.469115886502244]],
    [ "Towerhead Farm Solar Park Substation",[-2.8524885152587123, 51.332663841566784]],
    [ "Tricky Warren 33kV Solar",[-3.122642421646184, 50.93489052739745]],
    [ "Stockwood Road Master",[-2.548050442139979, 51.41075887545701]],
    [ "Sidmouth Town Substation",[-3.238616024811532, 50.688650136994156]],
    [ "Hurcott Regulator",[-2.878050360931689, 50.921807109766355]],
    [ "Bournville Substation",[-2.965726850185084, 51.327022862897486]],
    [ "Viridor Waste Substation",[-2.67000465, 51.532892800000006]],
    [ "Cambridge Solar Panel Substation",[-2.360037, 51.738212850000004]],
    [ "Howard Road STOR Substation",[-1.9107589726695486, 52.29027343960805]],
    [ "Old Green Substation",[-2.5069702327125016, 51.55974505709475]],
    [ "Tower Hill Farm Substation",[-2.4751952, 51.598826849999995]],
    [ "Glenfrome Road Master Substation",[-2.5605567550912847, 51.47848078058595]],
    [ "Sudmeadow Road STOR Substation",[-2.264437351256857, 51.86500771683984]],
    [ "Rotherdale Farm PV Substation",[-2.0515513826810667, 52.15404629401804]],
    [ "Hewlett Packard Switching Station",[-2.5660228290029528, 51.500797214335584]],
    [ "West Town Lane Master Substation",[-2.5450676379027284, 51.432345309210206]],
    [ "Wickhamford Substation",[-1.9288236128387735, 52.05733393055212]],
    [ "Says Farm PV Substation",[-2.4465929500000017, 51.52416184999999]],
    [ "Southmead Hospital Intake",[-2.5952113708026494, 51.49756723775981]],
    [ "Northwood Solar Park Substation",[-2.529637880908627, 51.540111177443144]],
    [ "Brockworth Substation",[-2.1668749229377187, 51.84491974371072]],
    [ "Wedmore Vale Master Substation",[-2.5844593999999996, 51.42965615000001]],
    [ "Ring 'o' Bells Solar Farm Substation",[-2.39784952727851, 51.491279098205446]],
    [ "Halls Road Master Substation",[-2.5088568189067293, 51.462267001131735]],
    [ "Abson Substation",[-2.4177951815911336, 51.47920553136836]],
    [ "St Brelades Grove Master Substation",[-2.5413791777461294, 51.44792766538917]],
    [ "Bower Ashton Substation",[-2.626833128907406, 51.44035128257972]],
    [ "Oakham Farm Solar Park Substation",[-2.5786713, 51.54743980000001]],
    [ "Whittlebury Road PV Substation",[-1.0089153617293913, 52.10214723198182]],
    [ "Gawcott Fields Solar Park Substation",[-1.0016026707170407, 51.98643431570168]],
    [ "Emberton PV Substation",[-0.6880154499999993, 52.13494555]],
    [ "Brackmills Substation",[-0.8665857923688743, 52.223657063972354]],
    [ "Eakley Lanes South PV",[-0.8081232254779188, 52.14752654769355]],
    [ "Handley Park Solar Park",[-1.0183232999999998, 52.10834924999999]],
    [ "Littlewood Farm Solar Substation",[-0.7989162000000007, 52.11624435]],
    [ "Brafield on the Green PV Substation",[-0.7954923500000005, 52.20594125000001]],
    [ "Homestead Solar Park",[-0.9207842738539851, 52.10521805469698]],
    [ "Manor Farm PV Horton",[-0.77935235, 52.17288485]],
    [ "Thornborough Grounds PV",[-0.9278339999999999, 52.006452249999995]],
    [ "Elms Fm Southam Solar Fm 33kV",[-1.4436125499999999, 52.218682799999996]],
    [ "Turweston Airfield Solar Farm Substation",[-1.1113894, 52.0436531]],
    [ "Greatmoor EFW Calvert Substation",[-0.9787489845846399, 51.895720354378355]],
    [ "Jaguar Land Rover Substation 33kV",[-1.477838113443325, 52.19366665154305]],
    [ "Bletchley Landfill Substation",[-0.7646878999999999, 51.97995869999999]],
    [ "Hartwell Road",[-0.8446199, 52.133782000000004]],
    [ "Olney Hyde Farm Solar",[-0.7083283752884496, 52.17638561017995]],
    [ "Rolleston Park Solar Farm",[-1.6847067500000001, 52.84236519999999]],
    [ "Barton Under Needwood Substation",[-1.7044894469619924, 52.758236195787205]],
    [ "Aston House Solar Farm",[-1.7429734000000001, 52.8797505]],
    [ "Drakelow Solar Farm",[-1.66678615, 52.777644300000006]],
    [ "Astley Solar Farm Substation",[-2.33494785800899, 52.467469268982754]],
    [ "Upper Meadowley PV Farm",[-2.49482135, 52.529951499999996]],
    [ "Hayford Solar Farm",[-2.942887604680068, 52.68706358288473]],
    [ "Moat Farm Draycott Solar",[-1.768658663591223, 52.87383182240399]],
    [ "Darwall St Substation",[-1.981144095826291, 52.58659450720153]],
    [ "Roushill Substation",[-2.75357254017986, 52.7095214360429]],
    [ "Battlefield ERF Gen",[-2.7275557999999975, 52.74252609999999]],
    [ "Swancote Ad Generation",[-2.37729105, 52.54004060000001]],
    [ "Green Lane Marchington PV",[-1.7842290000000003, 52.876005600000006]],
    [ "Breach Farm",[-1.61016505, 52.7676838]],
    [ "Aldridge Substation",[-1.9344652943075669, 52.60247766334441]],
    [ "Bloxwich ESS Substation",[-2.0093164249411988, 52.58933380276594]],
    [ "Pitchford Solar Farm",[-2.71554485, 52.62106339999999]],
    [ "Condover Solar Farm",[-2.7267293000000006, 52.659351150000006]],
    [ "Station Street Substation",[-1.632165524346447, 52.8041258619368]],
    [ "Copsewood Substation",[-1.4642247617940622, 52.40287665489496]],
    [ "Rugby Gateway Substation",[-1.2520440443456013, 52.40400543474631]],
    [ "Fields Farm Southam Solar",[-1.4096708, 52.24549055]],
    [ "Lilbourne Wind Farm Substation",[-1.1691185999999998, 52.3903937]],
    [ "Rushden Substation",[-0.5968979483150811, 52.29521810066994]],
    [ "Rugby BR Substation",[-1.2794452893383488, 52.38130350072727]],
    [ "Irchester Road Switching Station",[-0.6525793932527391, 52.26591260292881]],
    [ "University Of Warwick Substation",[-1.564774381079622, 52.38369205781254]],
    [ "Raunds Substation",[-0.5482731588620857, 52.344997459557774]],
    [ "Holyhead Road Substation",[-1.5432628064083833, 52.41692316052572]],
    [ "Kingsthorpe Substation",[-0.9005671524969432, 52.26429207100596]],
    [ "Field Street Substation",[-0.7286407651614953, 52.40264199140508]],
    [ "Park Farm Substation",[-0.7388583367275874, 52.299203940324375]],
    [ "Chelveston Solar Park Substation",[-0.5250226737289934, 52.30846041786113]],
    [ "Rugby Portland Cement Substation",[-1.2869787098827208, 52.377590880087396]],
    [ "English Electric Substation",[-1.2703889004014066, 52.38016100926303]],
    [ "Union Street Substation",[-1.2656941074043004, 52.36872516832466]],
    [ "Fosse Way Radford Solar FM",[-1.4830822038641496, 52.25529140993517]],
    [ "Weedon Substation",[-1.0811609989132567, 52.23467806576964]],
    [ "Abington Substation",[-0.8764935443540659, 52.247547394181225]],
    [ "Yelvertoft Wind Farm Substation",[-1.1533118480913775, 52.36752279976478]],
    [ "Pipewell Road PV",[-0.8006858774716734, 52.44562139726593]],
    [ "Sywell PV",[-0.7711323999999999, 52.307772199999995]],
    [ "Braunston Road Substation",[-1.1752092530601843, 52.26096794859015]],
    [ "Pytchley Road Substation",[-0.7207788546201361, 52.37446992093479]],
    [ "Watford Lodge Windfarm Substation",[-1.1148074929758311, 52.329201922171016]],
    [ "Low Spinney Wind Farm Substation",[-1.1767989000000003, 52.49884425000001]],
    [ "Higham Ferrers Switching Station",[-0.5850014998542975, 52.30764863333138]],
    [ "Burton Wold North Substation",[-0.6391030666359137, 52.3739351999595]],
    [ "Corby Central Substation",[-0.6730071941745662, 52.49824996898755]],
    [ "Cannon Street Substation",[-0.6891845280970185, 52.30488498108505]],
    [ "G E C Test Bed Whetstone",[-1.1818257189987673, 52.5630483562925]],
    [ "Oakley Substation",[-0.7307001600487477, 52.463998339181764]],
    [ "Middlefield Substation",[-1.3744013825273593, 52.555947381574]],
    [ "Swinford Wind Farm Substation",[-1.1730768184354101, 52.430315348858905]],
    [ "Meadow Farm Solar Substation",[-0.9092288, 52.50482655]],
    [ "Hill Farm Battery Generation",[-1.4476881499999996, 52.724821299999995]],
    [ "Astrazeneca Substation",[-1.2173362395347054, 52.78828633812782]],
    [ "Wardentree Park Substation",[-0.13418148866659071, 52.81343184159197]],
    [ "Jupiter Substation",[-1.1645770606963155, 52.6263497873537]],
    [ "Atherstone Solar Farm Substation",[-1.5629557754847987, 52.610533144662064]],
    [ "Trent Alloys Substation",[-1.564417174529762, 52.85025223665079]],
    [ "French Farm Windfarm Substation",[-0.10019240000000104, 52.66492879999999]],
    [ "Redcross Street Substation",[-1.1385455403195144, 52.63378378571417]],
    [ "Victoria Road PV Ellistown",[-1.3438925823932304, 52.689833677729325]],
    [ "Gypsum Solar Farm",[-1.1162849500000003, 52.73969855]],
    [ "Regent Street Substation",[-0.8795493448588827, 52.763595025420976]],
    [ "Shepshed Substation",[-1.2851739011687335, 52.769614800013464]],
    [ "Hall Farm Newbold Verdon",[-1.3635811747308715, 52.62520490606539]],
    [ "Wymeswold Solar Park Substation",[-1.137036514811992, 52.797558634039156]],
    [ "Mill Farm Solar Boothby",[-0.59305015, 52.86818645]],
    [ "Decoy Farm Generation",[-0.13479655, 52.69793215]],
    [ "Brush Substation",[-1.1938861751203833, 52.78130427531444]],
    [ "Ragdale PV Solar Park",[-1.0269674972227454, 52.777225916160646]],
    [ "Deeping St Nicholas Wind",[-0.21733480000000002, 52.72266985000001]],
    [ "Langar Solar Switch Room",[-0.95125445, 52.90012960000001]],
    [ "Horsemoor Drove Solar Park",[0.058800299999999986, 52.751075699999994]],
    [ "Highfields Substation",[-1.121514578438646, 52.62919470639859]],
    [ "Lincoln Farm Solar Park Substation",[0.20948289999999997, 53.14004129999999]],
    [ "Croft End Solar Farm Substation",[0.2372061815550903, 53.145079148465406]],
    [ "Nowhere Farm PV Substation",[-0.10312430000000002, 52.923795]],
    [ "Boston Biomass Generation Substation",[-0.00616382703136126, 52.96045016475055]],
    [ "Leverton Battery Storage Substation",[-0.553832818163932, 53.266136833973846]],
    [ "Leverton Solar Park Substation",[0.06359694999999879, 53.02341079999999]],
    [ "Whitecross Lane Solar Park Burton Substation",[-0.3561413, 52.97706130000001]],
    [ "Bambers Farm Wind Generation Substation",[0.23784399999999983, 53.31987725000001]],
    [ "Mill Farm Battery Storage Substation",[-0.5844310000000017, 53.025026399999994]],
    [ "Grange Farm Solar Kirkby on Bain Substation",[-0.16098147514124292, 53.14171340847457]],
    [ "Bicker Fen Wind Generation Substation",[-0.22317340000000002, 52.937003350000005]],
    [ "Fiskerton Solar Farm Substation",[-0.42841305, 53.24333585000001]],
    [ "Deepdale Solar Farm",[-0.3955091576205294, 53.029819083071594]],
    [ "Ermine Farm Solar Farm",[-0.5000565545543538, 53.008610117332836]],
    [ "Canopus Solar Farm",[-0.0552526, 53.01000415000001]],
    [ "Copley Farm Solar Gen",[-0.7351099499999998, 53.01917315]],
    [ "Welbeck Collery Solar Gen",[-1.147830600000001, 53.221712350000004]],
    [ "Cobb Farm Solar Farm",[-0.8803467889653879, 53.21116502093755]],
    [ "Lodge Farm Solar Park",[-0.8402775343342871, 52.9650090686071]],
    [ "Retfor Road Solar Gen",[-1.0353207824506632, 53.365875248140206]],
    [ "Eakring Solar Farm",[-1.015984400000003, 53.1522883]],
    [ "Bingham Substation",[-0.9455711391337511, 52.94883607987397]],
    [ "Walkers Wood Solar Farm",[-0.9977631116750256, 53.30822692565403]],
    [ "Bilsthorpe PV Generation",[-1.043022456779902, 53.128356976860125]],
    [ "Bilsthorpe Solar Substation",[-1.0174884145547631, 53.14355252189136]],
    [ "Stragglethorpe Road PV Solar",[-1.0522606792822478, 52.929092401071316]],
    [ "Low Farm AD Substation",[-0.9524182, 53.3629587]],
    [ "Thoresby Solar Farm",[-1.0875813300361128, 53.23135296196741]],
    [ "Tiln Solar Generation",[-0.9272939663094661, 53.34535611567742]],
    [ "Moat Farm Solar Farm",[-0.9969244, 53.35669355]],
    [ "West End Farm Solar Gen",[-0.8453732, 53.30023450000001]],
    [ "Hawton Wind Farm Substation",[-0.8125186499999999, 53.03638044999999]],
    [ "Shelton Lodge Solar Gen",[-0.8577351645417101, 52.947702278139026]],
    [ "Bowbridge Generation STOR Substation",[-0.8089940014675677, 53.05100791617797]],
    [ "Welbeck Solar Farm Substation",[-1.1044060499999993, 53.23618650000001]],
    [ "Smith Hall Farm Solar Plant Substation",[-1.6055383244041173, 53.012605354727874]],
    [ "Dayfields Farm PV Substation",[-1.66122257736476, 53.02431449789483]],
    [ "Twin Yards Solar Farm Substation",[-1.3182355499999985, 53.11592425]],
    [ "Lower Newton Solar Farm Substation",[-2.018797484803841, 52.944896775523176]],
    [ "Asher Lane STOR Substation",[-1.4139782500000002, 53.06194200000001]],
    [ "Somercotes Substation",[-1.3729192462139714, 53.08503463238872]],
    [ "Averill Solar Farm Substation",[-1.3961335741584713, 53.14841887343055]],
    [ "Taylor Lane STOR Substation",[-1.3656540349989008, 53.023213477411474]],
    [ "Shirebrook Substation",[-1.244268800207316, 53.19605795888664]],
    [ "Lodge Farm Solar Substation",[-1.3746487295524963, 53.22861016532866]],
    [ "Holtwood Farm Solar Park Substation",[-1.8051167, 52.89190200000001]],
    [ "Bentinck Generating Station Substation",[-1.269816082806528, 53.0911103254905]],
    [ "Bolsover Moor Quary Solar",[-1.2558121794011057, 53.24129320959005]],
    [ "Garnham Close STOR Substation",[-1.3660886818225408, 53.09038173384512]],
    [ "Oxcroft Solar Farm Substation",[-1.2987271450822346, 53.26169992464667]],
    [ "Twin Oaks",[-1.8113292128534972, 52.89570537785196]],
    [ "Arkwright Solar",[-1.3457784000000002, 53.2458876]],
    [ "Shanks Generation Substation",[-1.0001439505656293, 51.91442613341422]],
    [ "Drayton Farm Local Substation",[-1.7733067548283226, 52.19605155624209]],
    [ "The Rushes Drayton Substation",[-1.7753387000000003, 52.198689300000005]],
    [ "Brookside Drayton Substation",[-1.7745745528572185, 52.19093735039442]],
    [ "Willows Drayton Substation",[-1.7703625710540989, 52.190130279833944]],
    [ "Poplars Drayton Substation",[-1.7692667247837086, 52.190478449191616]],
    [ "Eller Brook Close",[-2.6074873500000013, 53.62183234999999]],
    [ "Northern Powergrid",[-1.3410874727493236, 53.71380863999498]],
    [ "978077883",[-1.3824053108153738, 53.75237986779696]],
    [ "Speke Substation",[-2.8488589502380517, 53.352969750653585]],
    [ "979695794",[0.6504680664472462, 51.56278969324776]],
    [ "Cokes Lane Substation",[-0.5660488152496371, 51.66757319674474]],
    [ "980113292",[-0.30652533305889607, 51.4911787776104]],
    [ "980151106",[-0.4024160989789551, 51.272585504188704]],
    [ "982779760",[-0.8070842524880667, 50.85416227815739]],
    [ "Scottish and Southern Electricity Networks",[-0.9770026988822396, 50.794216478227575]],
    [ "Scottish and Southern Energy Power Distribution",[-1.1941202319725042, 50.94663640611147]],
    [ "982807335",[-1.3923001446047654, 50.904779049968184]],
    [ "983020527",[-1.3789648381151076, 50.93685359220064]],
    [ "983020935",[-1.4003084186603045, 50.94135514930381]],
    [ "West Hertford Primary",[-0.08702146773162713, 51.791902796396975]],
    [ "Greylands Road Substation",[-2.6268168910884, 51.420395521775625]],
    [ "Somerton Door Solar Park Substation",[-2.7557135816979774, 51.06922141615166]],
    [ "Sydner Close Substation",[0.5038284804107237, 51.70995055335031]],
    [ "Bristol Road Substation",[-2.262877570848185, 51.84114801604229]],
    [ "Porthmellon Industrial Substation",[-6.307870046098312, 49.91575953335582]],
    [ "Angmering 33kV",[-0.4880680000000003, 50.815859100000004]],
    [ "Jephson Gardens",[-1.5306424906150675, 52.286737876051596]],
    [ "996265629",[0.18951355462768205, 52.66069929966914]],
    [ "UK Power Networks",[-0.3822802001358247, 50.82381442758858]],
    [ "UK Power Networks",[-0.38393021369006686, 50.82005605470532]],
    [ "999009809",[-1.1554891125846927, 53.7777530596838]],
    [ "Acacia Crescent",[-0.53875965, 52.33771110000001]],
    [ "1000502195",[0.13416759585695667, 51.52366359431808]],
    [ "Purfleet Substation",[0.2618844226409212, 51.478530689808345]],
    [ "1000600880",[0.31825492341691586, 51.476970615446284]],
    [ "1000638724",[0.4460449942302427, 51.51366981561689]],
    [ "1000640495",[0.6921507718536495, 51.591808517573725]],
    [ "Shipston Local 11kV",[-1.6427149, 52.07918045]],
    [ "1000803962",[0.03001312835938513, 51.62712578196874]],
    [ "1000804402",[0.032220218626236176, 51.62499822966346]],
    [ "1000804522",[0.0031397, 51.6316509]],
    [ "1001099425",[-0.819946283508728, 53.12836781022809]],
    [ "Welby Lane PV Substation",[-0.9314428017443055, 52.78122697352913]],
    [ "Willoughby STOR Substation",[-1.0716009999999998, 52.82590909999999]],
    [ "New Banbury Rd Primary Distribution 11kV",[-1.5652102740412028, 52.257337278954545]],
    [ "Hams Hall South 11kV",[-1.7172226784194382, 52.52508436200656]],
    [ "Rover Lode La. No. 6 11kV",[-1.7654380114528818, 52.434052725794835]],
    [ "1005678502",[-1.7853735872524812, 52.70982419406312]],
    [ "Harbury 33kV",[-1.4329667590584907, 52.22782059125581]],
    [ "1006850137",[-0.05963279593222217, 51.562366788231735]],
    [ "Feckenham 11kV",[-1.9723013942896546, 52.25185406735889]],
    [ "Bow Junction Feeder Station",[-0.01720470489111562, 51.53317311774885]],
    [ "Aycliffe Industrial",[-1.5649500228833002, 54.611292798074494]],
    [ "UK Power Networks",[-0.5679182, 51.76124095]],
    [ "Rosecroft Close Substation",[1.15171205, 51.8082209]],
    [ "Hinksford Local Transformer 11kV",[-2.195431352523787, 52.50519574641096]],
    [ "Hinksford 11kV",[-2.19405355, 52.50513915]],
    [ "Bury Grid",[-2.2995081520055938, 53.597024071517055]],
    [ "Upton Warren Local 11kV",[-2.0948757, 52.30934669999999]],
    [ "Maynard Avenue",[-1.5708931499999998, 52.28476779999999]],
    [ "Low Street, Elston",[-0.8665926117537588, 53.024833100043914]],
    [ "Bournville Local 11kV",[-1.9258513173397644, 52.42690841516128]],
    [ "Bournville Substation",[-1.926022489085352, 52.4270078367725]],
    [ "1017148498",[-0.7360577520405437, 51.237697331715175]],
    [ "1017148504",[-0.6464008208240506, 51.178598365046966]],
    [ "1017574488",[-0.5445668297530395, 51.056991895578]],
    [ "1017847958",[-2.985793061436792, 54.94268286078162]],
    [ "1017850128",[-2.767475679731458, 54.13159440950852]],
    [ "Dovecote Cl E Bridgford",[-0.9699722692247954, 52.98341214828798]],
    [ "UK Power Networks",[0.4675387969085008, 51.774724401719226]],
    [ "Brunswick Street",[-1.5238229848193483, 52.273327823770245]],
    [ "1020641937",[-2.432465286154222, 51.52124029201656]],
    [ "Scampston Drive",[-1.5379611876876724, 53.733001975929184]],
    [ "1020861481",[-1.5376129324629115, 53.73254985376383]],
    [ "Barking Riverside 132kV substation",[0.11428651670358116, 51.51922785002853]],
    [ "Bishopgate",[-2.6336922886815213, 53.54622726853222]],
    [ "Cranham Substation",[0.2677699671635212, 51.55685461081968]],
    [ "Cranham Golf Club Solar Farm",[0.2876784, 51.559324200000006]],
    [ "West Horndon Substation",[0.33710822759452097, 51.567824647791674]],
    [ "Ford Tractor Substation",[0.4746079499999992, 51.5880025]],
    [ "The Limes Substation",[0.45121419999999995, 51.581821749999996]],
    [ "Kingswood Substation",[0.4530025035793481, 51.56745085410878]],
    [ "Durham Road Substation",[0.417504373320628, 51.56952270836313]],
    [ "Stansted Airport Main Substation",[0.23006178360841656, 51.88753527392994]],
    [ "Maldon Wick Substation",[0.6664802019494337, 51.724830716636475]],
    [ "1026414471",[0.53689405, 51.62062925]],
    [ "1026414472",[0.5556160398692926, 51.60716564127308]],
    [ "Clacton Substation",[1.1794391115983005, 51.82591368992906]],
    [ "Valleybridge Road Substation",[1.164573945698537, 51.803777171013365]],
    [ "Lake and Elliot Substation",[0.5658314, 51.87393325000001]],
    [ "Braintree Depot Substation",[0.5723586282328363, 51.88242552411787]],
    [ "Blickling Close Substation",[0.5325348075252172, 51.864464676018535]],
    [ "Fairburn Close  Edwalton",[-1.12305795, 52.911255849999996]],
    [ "Points Heating",[0.13583365, 51.52649200000001]],
    [ "Birch Grove No. 1",[-1.67615675, 52.87338650000001]],
    [ "Tividale 11kV",[-2.054396679557269, 52.52035229719862]],
    [ "Electricity North West",[-2.046390557976411, 53.49171681464438]],
    [ "Heyrod",[-2.0412397638132105, 53.49656674859723]],
    [ "1030697595",[-0.11261467498622003, 51.598059996735465]],
    [ "1033518981",[-0.08531015228654781, 51.60216094612222]],
    [ "1033518982",[-0.09270919999999999, 51.603653449999996]],
    [ "1033518990",[-0.0897142, 51.6030552]],
    [ "1033532966",[-0.07606003382202432, 51.598722071139186]],
    [ "1033532968",[-0.07934602356064295, 51.59948496985934]],
    [ "1033804666",[-0.15061637028086633, 51.58600978849638]],
    [ "1034467458",[-0.08149853017369252, 51.60150456872009]],
    [ "Willowbath Mill",[-1.571068187627976, 53.07627382504]],
    [ "Derby Rd Wirksworth",[-1.5758394017809625, 53.074009765497244]],
    [ "Millers Green Wirksworth",[-1.579304311143171, 53.07228403318835]],
    [ "1039091445",[0.3136501661486911, 51.431303667162126]],
    [ "Back Westgate",[-0.17776830755247425, 53.91076468195277]],
    [ "Yarrows Quarry",[-0.2747398, 53.897082833333336]],
    [ "Catwick Lane Intake",[-0.2748005692169286, 53.89703416568881]],
    [ "1039905176",[-0.4676968763156734, 51.37217555302494]],
    [ "Osbaldwick Cyclops",[-1.0265550049551837, 53.958007293323206]],
    [ "Osbaldwick Grid",[-1.0229876999999996, 53.95577255000001]],
    [ "Wentworth Road",[-1.0934175634028866, 53.949665320464]],
    [ "1040062722",[-1.1513377197115482, 53.97348347732091]],
    [ "Northern Powergrid",[-1.1489484920839552, 53.96919087545423]],
    [ "Sherwood Grove",[-1.1376647025305693, 53.9668725845523]],
    [ "1040066293",[-1.7151032400151782, 54.41231754443571]],
    [ "Addenbrooke's Hospital",[0.1455872, 52.1760007]],
    [ "Physics Substation",[-1.052780495336075, 53.94522336556072]],
    [ "BadgerHill North",[-1.0371516059030081, 53.95277771176153]],
    [ "Heslington Smith And Nephew",[-1.0438276818277477, 53.94848331682187]],
    [ "University Park",[-1.0457485335824555, 53.94931429127612]],
    [ "University Bio Incubator",[-1.0478964821185317, 53.94949056843347]],
    [ "Badger Hill South",[-1.0360324078080358, 53.950658926659735]],
    [ "Badger Hill West",[-1.0406795838557348, 53.949785437899806]],
    [ "Osbaldwick Link Sw",[-1.0290758831223423, 53.954243256514545]],
    [ "1041213415",[-0.16212344999999997, 53.580920400000004]],
    [ "Albert Hill",[-1.5511416939753118, 54.53583410906394]],
    [ "Northern Powergrid",[-1.5581439221855462, 54.54243105144878]],
    [ "Hull Road",[-1.0405754616481369, 53.953999463968294]],
    [ "Hewley Avenue",[-1.0566100456502678, 53.958395225029975]],
    [ "Knapton",[-1.1462519440586316, 53.96023623972615]],
    [ "Arkwright Street",[-1.5772140695371908, 53.07760787632561]],
    [ "Lighthurst Lane",[-2.6282123230601098, 53.642184795472986]],
    [ "Darlington Denmark Street",[-1.5531018582350253, 54.53920963155693]],
    [ "Darlington North Road",[-1.5522116467528222, 54.538538021516814]],
    [ "Darlington Westmoreland",[-1.5556837671903507, 54.54029746751132]],
    [ "Darlington Fitzwilliam",[-1.5452395256856126, 54.541780749018045]],
    [ "Darlington Thompson",[-1.544229, 54.54564285]],
    [ "Leydene Housing S/S",[-1.0366892000000003, 50.968231]],
    [ "1049638569",[-2.899964073569487, 51.28654274670776]],
    [ "York University",[-1.044157797542323, 53.949125666586106]],
    [ "Stourton Warehouse",[-1.49827835, 53.766016349999994]],
    [ "Warren Lane",[-1.3247080168421115, 53.871308250150925]],
    [ "Bramham",[-1.3279528570897434, 53.871938181155365]],
    [ "Audby Lane",[-1.3789949204475234, 53.933094021488905]],
    [ "Electricity Street Substation",[-2.4422251418685352, 53.09429878113506]],
    [ "1052243922",[-2.3339611917250105, 53.09180865318214]],
    [ "Winnington Substation",[-2.5296485500000014, 53.267603349999995]],
    [ "Milton Place",[-1.5792737380287978, 53.68501690089486]],
    [ "Liverpool Road Substation",[-2.6919690557148237, 52.96408536941518]],
    [ "Woodside Substation",[-3.0128491025861415, 53.39559921493335]],
    [ "Hoylake Substation",[-3.176853823181915, 53.389681701990504]],
    [ "Hewswall Substation",[-3.0726854665842516, 53.328227213730635]],
    [ "Power Station 33kV Aux",[0.38140438630324364, 52.72615527334056]],
    [ "Porter Street",[0.36855629999999995, 52.60414435]],
    [ "1052501998",[0.3670106672643244, 52.520431401001076]],
    [ "Morley Engineering Works",[0.22620509738131597, 52.22340728314897]],
    [ "Darley",[-1.6785224377033272, 54.032239850169994]],
    [ "Gillmoss Substation",[-2.8977112176364725, 53.46258373259488]],
    [ "Pinfold Lane Substation",[-3.0399194501375804, 53.59224254204609]],
    [ "Grantham Close Substation",[-3.0120129545759085, 53.61949684848824]],
    [ "Dover Road Substation",[-3.0214256905910792, 53.62441265674012]],
    [ "Network Rail Hillside Substation",[-3.0266921552074564, 53.619772941948625]],
    [ "Market Street Substation",[-3.0061567553231026, 53.64575655081707]],
    [ "Mullards Balmoral Drive Substation",[-2.953946978124971, 53.66665198096367]],
    [ "Ivy Street Substation",[-2.986766278236549, 53.642745785291794]],
    [ "Banastre Road Substation",[-3.0069977002241592, 53.63663764361279]],
    [ "Lord Street Substation",[-2.998515599228511, 53.650840967863545]],
    [ "1053027785",[-3.0175401558546517, 53.63457469920639]],
    [ "Ocean Plaza Substation",[-3.0122781877879934, 53.65368646778286]],
    [ "Neville Street Substation",[-3.0085865251648958, 53.65002080289432]],
    [ "1053032956",[-3.0354762456450337, 53.60301579050515]],
    [ "1053033356",[-3.0462264163955193, 53.60421829977792]],
    [ "Cambridge Road Substation",[-2.9823740592433223, 53.65675960713661]],
    [ "Marshside Substation",[-2.9742164548384435, 53.66497891136697]],
    [ "Fazakerley Substation",[-2.9424179177671554, 53.46096145973646]],
    [ "Ravenhead Substation",[-2.7419648926773075, 53.445478034385516]],
    [ "St Helen's Substation",[-2.7569251462585975, 53.45210726808443]],
    [ "Crossrail Pudding Mill substation",[-0.01652872040751951, 51.53243136405515]],
    [ "UK Power Networks",[-0.004344068357176529, 51.45690356677423]],
    [ "Coneythorpe",[-1.4066938882155, 54.03041979194543]],
    [ "Boroughbridge",[-1.4140000334463252, 54.0870631335305]],
    [ "Dogger Bank Wind Farm Substation",[-0.4217009614363485, 53.81106715354767]],
    [ "Ripon",[-1.521716917543125, 54.13351085672555]],
    [ "Network Rail",[-1.2550847731202934, 51.614839950776535]],
    [ "National Grid",[-1.2542062314687121, 51.61464044862572]],
    [ "Thingley Feeder",[-2.1425388499999998, 51.436559749999994]],
    [ "National Grid",[-0.49267679999999997, 51.9263144]],
    [ "Halewood Substation",[-2.8295037694600214, 53.35375706658441]],
    [ "1062125981",[-2.830422324656906, 53.353824496247235]],
    [ "Kenton Road",[-2.8282011486820204, 53.36118175155541]],
    [ "Selwyn Road Primary",[0.35538588187946435, 51.46298523841658]],
    [ "Fords Dunton Primary",[0.40769754999999747, 51.57949515]],
    [ "Silvertown Substation",[0.043661495620833414, 51.503601750436445]],
    [ "Gaydon 33kV",[-1.4852038312601434, 52.20149107289932]],
    [ "Hailsham Primary",[0.24528748174145573, 50.858736283703685]],
    [ "1064458864",[-0.17716966898355113, 51.63434824956202]],
    [ "Bachelor's Bump Substation",[0.61915699560755, 50.88256527460541]],
    [ "Southam Substation",[-1.3908634106167448, 52.27306532841041]],
    [ "Crick Local 11kV",[-1.1645289736674986, 52.35184701385666]],
    [ "Red Lake School Substation",[0.6146810930437653, 50.87900073287506]],
    [ "1066861671",[-0.10719635984863447, 53.548456370830564]],
    [ "Wheatley",[-1.1209437929010042, 51.743639517897144]],
    [ "Churchill Avenue Substation",[0.6092818198794245, 50.878542228477166]],
    [ "Curlieu Lane Norton Lindsey",[-1.6761873879855027, 52.26812993033113]],
    [ "Coventry West Substation",[-1.6050784555724142, 52.40098369065545]],
    [ "University Of Warwick Substation",[-1.5644346440185741, 52.38368474535453]],
    [ "Fairlight Road Substation",[0.61643125, 50.87435165]],
    [ "James College Substation",[-1.0563586992211813, 53.94531054909837]],
    [ "Lindsey Street Substation",[0.11555172794959338, 51.70435495750431]],
    [ "Westbrooke Centre",[0.12500543686312923, 52.21607107573446]],
    [ "Flaxman Close Substation",[-2.160194400000001, 52.95610105]],
    [ "Ufton Close Substation",[-2.1559218700618192, 52.96676874362099]],
    [ "Wyndham Road Substation",[-2.1514546295221026, 52.96720490697373]],
    [ "1076500557",[-2.024193348097111, 55.75004759373788]],
    [ "Viking Link Converter Station",[-0.23650053092806733, 52.920992537261974]],
    [ "Winton 33/11KV",[-1.8686062999999997, 50.744030699999996]],
    [ "Wimborne",[-1.9721792819121404, 50.79933119741896]],
    [ "UK Power Networks",[-0.37924492467638815, 50.819078393543364]],
    [ "UK Power Networks",[-0.3798671334691776, 50.82222537490686]],
    [ "Telehouse Substation",[-0.0016479994682675777, 51.511144620942005]],
    [ "UK Power Networks",[-0.38663488296772053, 50.82162467484033]],
    [ "UK Power Networks",[-0.38865440000000034, 50.82195185]],
    [ "UK Power Networks",[-0.386565, 50.819261100000006]],
    [ "UK Power Networks",[-0.3811608248241894, 50.820335396675496]],
    [ "1082060304",[0.1367006226841291, 51.45696884176396]],
    [ "1082061031",[0.18055877786044539, 51.45236823121301]],
    [ "1082063002",[0.14661067297040045, 51.52963353367775]],
    [ "1082063728",[0.14725896933270416, 51.54209337460853]],
    [ "Stone Marshes",[0.25467053360191205, 51.44413908902977]],
    [ "1082083220",[0.24436242435992567, 51.44256013793405]],
    [ "1082122910",[0.09023267516834364, 51.56160780262923]],
    [ "1082123247",[0.0843740762348303, 51.56869754958431]],
    [ "1082123519",[0.02440634553347784, 51.59537907369047]],
    [ "1082124309",[-0.18105113306083248, 51.615841000209436]],
    [ "1082124310",[-0.041262099999999996, 51.615266500000004]],
    [ "1082124651",[-0.25948495856443393, 51.61494487163894]],
    [ "1082213048",[-0.26466100974802975, 51.503690110955496]],
    [ "Local 11kV",[-1.782688299999998, 52.102219250000005]],
    [ "1083006694",[-1.4833582911336638, 50.94087427211931]],
    [ "UK Power Networks",[-0.3792324385953703, 50.82561511919291]],
    [ "UK Power Networks",[-0.38782815, 50.82505200000001]],
    [ "Northern Power Grid",[-0.15890980797850382, 53.57600931717108]],
    [ "1084678521",[-0.3993663600748209, 54.237034998561995]],
    [ "Sharland Farm Solar Park Substation",[-3.7759647519000397, 50.834195933774]],
    [ "Ellicombe Solar Farm Substation",[-3.787451800000001, 50.83302834999999]],
    [ "Carn Brea Substation",[-5.2806776697282185, 50.22825621733236]],
    [ "St Day Landfill",[-5.161375831677519, 50.229373502142415]],
    [ "Hewas Solar Farm Substation",[-4.924820246281156, 50.34411587251528]],
    [ "Carnemough Farm Solar Substation",[-4.923426554473738, 50.33150272863459]],
    [ "Manor Farm 33kv Solar Pk",[-4.829321432733732, 50.33491889897905]],
    [ "Dinder Farm Solar Park Substation",[-2.5860762519143106, 51.207947382685255]],
    [ "Shooters Bottom Wind Farm",[-2.5823877693355093, 51.25472169850331]],
    [ "Sandford Substation",[-2.839449262357792, 51.340573112023485]],
    [ "St Helen's Hospital Substation",[0.6021734122090263, 50.872514768616014]],
    [ "Hornsea Ashcourt",[-0.1730319863225914, 53.919858529482724]],
    [ "Frederick Road Depot Substation",[0.6015528499999991, 50.87569705000001]],
    [ "Clifton Road Substation",[0.6060517177546217, 50.874509448151414]],
    [ "1090871448",[-0.11982437855075248, 53.562291727698145]],
    [ "Waterside Close Substation",[0.59956305, 50.870862450000004]],
    [ "Bates Road Substation",[-1.5397074865535292, 52.39382503489807]],
    [ "Malvern Way North (Risk Site C) Substation",[0.6003678660782008, 50.876441306563656]],
    [ "Stewarts The Ridge Substation",[0.5989017359875464, 50.877248132758126]],
    [ "Haywood Way Substation",[0.6035143826639018, 50.87667264054065]],
    [ "Sandown School The Ridge Substation",[0.6042191529186683, 50.8757962690859]],
    [ "Leeds Close Substation",[0.6086244, 50.87366200000001]],
    [ "Rye Road South Substation",[0.6113287299845529, 50.876314316680066]],
    [ "Crowborough Road Substation",[0.61547525, 50.876083799999996]],
    [ "School Road Substation",[0.6049922145429669, 50.87053961957657]],
    [ "Union Street Primary Distribution",[-1.2656834817692384, 52.36886728393536]],
    [ "Wellesbourne Local 11KV",[-1.6033983588817808, 52.19724601669144]],
    [ "Courtaulds 33KV/6.6KV Substation",[-1.505176897984506, 52.42471351713186]],
    [ "1094326837",[0.2213828739463303, 51.587774302732484]],
    [ "1094351843",[-2.569630784331799, 51.528448703993845]],
    [ "1094354048",[-2.2945153, 51.821400000000004]],
    [ "Horsepools Church",[-2.221692169147096, 51.78918164889645]],
    [ "Ash Path",[-2.20036915, 51.83467515]],
    [ "Maple Close",[-2.2916161500000007, 51.8204301]],
    [ "Church Road",[-2.479039460655994, 51.81880468595528]],
    [ "Brockworth Urban",[-2.1845409298811034, 51.84542026232847]],
    [ "Six Acres",[-2.196801366666667, 51.83519546666666]],
    [ "Bondend North",[-2.193030308181162, 51.83299154615394]],
    [ "Ashley Drive Bussage",[-2.1677888611291047, 51.72814547826949]],
    [ "Pullens Road",[-2.192947241950305, 51.788355386617305]],
    [ "1094354058",[-2.1666505458205023, 51.73214743239677]],
    [ "Kings Head",[-2.2051352503748314, 51.829641783435456]],
    [ "Yarnfield Village",[-2.202308196923473, 52.89039863322562]],
    [ "Cold Meece",[-2.2161914379322183, 52.8925203960263]],
    [ "GRS Cold Meece",[-2.2243750500000004, 52.88897574999999]],
    [ "Lawrence Drive",[-2.226597000000001, 52.9169343]],
    [ "Kingfisher Crescent",[-2.0729509093326057, 52.93765952095837]],
    [ "Fitzherbert Arms",[-2.2233326506242324, 52.91791563428213]],
    [ "Dehaviland Drive",[-2.1969378, 52.89043534999999]],
    [ "Hollybush Road",[-2.1643935553598537, 52.986249075774985]],
    [ "1094485696",[-0.11224541329270157, 53.218095526523626]],
    [ "Horbury",[-1.567574520278523, 53.66452273866054]],
    [ "SP Energy Networks",[-2.7643306790654414, 53.126348744602694]],
    [ "SP Energy Networks",[-2.76557758031967, 53.12478769722474]],
    [ "1095712996",[-0.1659054508508334, 53.57809283525445]],
    [ "Shurton Substation",[-3.138205510620323, 51.20320113283908]],
    [ "Wogden Foot Cable Sealing End Compound",[-1.7397251489377163, 53.52017730026446]],
    [ "Shepshed Substation",[-1.2856330001384222, 52.75935954484308]],
    [ "Longlands TSC",[-1.431876715837062, 54.31511933190108]],
    [ "Brighton Old Shoreham Road Substation",[-0.14341887133494113, 50.83297507420136]],
    [ "1101353424",[-0.11425094999999999, 53.55933195000001]],
    [ "1101581996",[-0.09734519999999824, 53.55363605000001]],
    [ "Northen Power Grid",[-0.10692490799998274, 53.55727737514426]],
    [ "Northen Power Grid",[-0.0768745172865803, 53.54994748359269]],
    [ "1105215398",[0.33512961249436607, 51.470742280696506]],
    [ "1105215425",[0.3343847907899149, 51.466486703530514]],
    [ "1105221800",[0.34989792061913355, 51.46224727004664]],
    [ "1105243651",[-1.2129841054110058, 54.073144079145514]],
    [ "UK Power Networks",[0.037445138897241256, 51.47271350556379]],
    [ "1105662010",[-0.12048231050231586, 53.5595055756378]],
    [ "SP Energy Networks",[-2.7663931087217484, 53.12183986889828]],
    [ "Braytown S/S",[-2.2297604958769868, 50.67775630095225]],
    [ "Fylands Bridge",[-1.680736955635085, 54.64171334438021]],
    [ "Sweeting Thorns",[-0.6007960239804538, 53.56121003807854]],
    [ "1116235822",[-0.7540783666666666, 51.51132393333334]],
    [ "1116870865",[-2.0030306871793253, 51.60623707785789]],
    [ "1116929124",[-1.267172714738392, 54.587440124743324]],
    [ "Northern Powergrid",[-1.262345434658408, 54.58984587064647]],
    [ "1117585314",[-0.08834448155117554, 53.81288260234438]],
    [ "Hyde Park Estate A",[-0.16740497143485872, 51.51615895958955]],
    [ "UK Power Networks",[-0.37141422617623493, 50.81179847449243]],
    [ "Shorts Gardens Substation",[-0.12359944290371083, 51.51531354789853]],
    [ "Lackenby",[-1.1333028697079397, 54.56761579038652]],
    [ "Prissick Substation",[-1.2110455122403463, 54.542265718709125]],
    [ "Wimbledon Substation",[-0.18910666161967465, 51.43224256650811]],
    [ "New Cross Substation",[-0.0583806407919113, 51.48460067679449]],
    [ "New Cross Substation",[-0.055897251969883356, 51.48423672121049]],
    [ "1120532464",[-1.43458065, 53.65229354999999]],
    [ "1121670725",[-2.7745363176977467, 53.96946998845668]],
    [ "Hyde Park Estate B",[-0.16705253572237103, 51.51592030480049]],
    [ "Colliers Lane S/S",[-2.2235147251704412, 50.67830838073183]],
    [ "Amberley Road A Substation",[-0.1930012481268733, 51.52258310217645]],
    [ "Amberley Road B Substation",[-0.19255333100450828, 51.52272361567788]],
    [ "Barking (HS1) Feeder Station",[0.13439904428162114, 51.526706669560006]],
    [ "Clover Lane Healing",[-0.17431549999999996, 53.58108529999999]],
    [ "West Croydon Substation",[-0.12103283610119231, 51.35903875931141]],
    [ "Shirley Substation",[-0.05048797454829456, 51.37508317386536]],
    [ "Bridges Lane Substation",[-0.1312061859743967, 51.3683619513015]],
    [ "Ashburton Grid",[-0.07390685335367744, 51.392031834045895]],
    [ "UK Power Networks",[-0.07407000812147821, 51.39223074498999]],
    [ "Norbury Substation",[-0.11638572535512799, 51.40281661844577]],
    [ "UK Power Networks",[-0.13696211905225814, 51.38339177196167]],
    [ "Suffolk Road Substation",[-0.07650241582318087, 51.39938821615455]],
    [ "Biggin Hill Substation",[0.032527417064177146, 51.31789830038166]],
    [ "1128023211",[-0.41153244677842016, 53.800034404624085]],
    [ "1128023212",[-0.418813049542757, 53.79782070051901]],
    [ "Spurgeons Bridge Substation",[-0.09805127678826814, 51.383181192958084]],
    [ "UK Power Networks",[-0.11238273175378613, 51.34012758070384]],
    [ "UK Power Networks",[-0.11301010728962015, 51.33993790954314]],
    [ "UK Power Networks",[-0.11286525600216465, 51.33969033805526]],
    [ "Barking Substation",[0.11319282472885879, 51.51816200135394]],
    [ "Daily Mail Switching Station",[0.28043196472258114, 51.470945966965246]],
    [ "Barclay Way Substation",[0.27148946965277304, 51.481065260642396]],
    [ "1131551273",[-1.2337901957632946, 52.397946618269486]],
    [ "1131551278",[-1.2522352326683293, 52.38711073359934]],
    [ "1131552374",[-1.3073250170167774, 52.355584398626654]],
    [ "1131552377",[-1.3053924021491756, 52.35253048376238]],
    [ "1131552378",[-1.303746911987691, 52.35473967870309]],
    [ "1131552379",[-1.2949276603538362, 52.35652720371018]],
    [ "1131552380",[-1.294135205442646, 52.360556440336545]],
    [ "1131553809",[-1.3105336499999998, 52.361882699999995]],
    [ "1131553810",[-1.305879773213327, 52.358627305601786]],
    [ "1131553811",[-1.3117263080302402, 52.357137732097904]],
    [ "1131553812",[-1.3116155166472776, 52.35808415003991]],
    [ "1131553814",[-1.31582710088086, 52.35418446684473]],
    [ "1131556522",[-1.2885524940219606, 52.35464027052053]],
    [ "1131556523",[-1.2869216320612655, 52.357638374318505]],
    [ "1131556524",[-1.2862344907311318, 52.35343246267067]],
    [ "1131556525",[-1.2856922680467249, 52.35067460107516]],
    [ "West Norwood Substation",[-0.10397215904323355, 51.43024018188489]],
    [ "Trinity Crescent Substation",[-0.15763860154767062, 51.4375673645199]],
    [ "Forest Hill Substation",[-0.060948028220652394, 51.44718494439059]],
    [ "Clapham Park Road Substation",[-0.13646706577174558, 51.46155573230094]],
    [ "UK Power Networks",[-0.12127235000000001, 51.35913000000001]],
    [ "Exeter Road Substation",[-0.021657043454554933, 51.58200029092469]],
    [ "Market Harborough Substation",[-0.8855622869765496, 52.464397932648325]],
    [ "1133157407",[-0.8867098014346485, 52.46375099708771]],
    [ "1134362793",[-2.1650091651136134, 53.53142712764476]],
    [ "1134372904",[-2.1937779293799777, 53.55012562586593]],
    [ "1134656402",[-0.044667030767421546, 51.40377451078563]],
    [ "Enterprise Way No. 2",[-1.1680429798440708, 52.940959646014235]],
    [ "Enterprise Way No 1",[-1.1664380852398528, 52.9412876293358]],
    [ "1135148033",[-1.3036968, 53.59937860000001]],
    [ "Harworth 1690",[-1.9389484023616592, 53.83826448406885]],
    [ "National Grid Electricity Distribution Plc",[-2.403348314687247, 51.74154278724916]],
    [ "Kings Avenue S/S",[-1.926631, 50.721964400000005]],
    [ "North Burton",[-0.374223487457734, 54.14208285690822]],
    [ "West Burton",[-0.37379927377491035, 54.14003484810661]],
    [ "Fraisthorpe Fields",[-0.27181759643863085, 54.073621000587785]],
    [ "Brough Cave Road",[-0.5890906145878829, 53.74493275764457]],
    [ "Brough Brantingham Grange",[-0.5884368636140396, 53.738034223210725]],
    [ "Burnby Hayton",[-0.7390609693538712, 53.908452977725226]],
    [ "Burnby Driffield",[-0.736438111920112, 53.908849091511684]],
    [ "Burnby Belthorpe Lane",[-0.7379908800795298, 53.911372013546384]],
    [ "Figham Hull Road",[-0.4031954907167491, 53.834904694516595]],
    [ "Thirsk",[-1.3885664485454003, 54.22999923664922]],
    [ "Sowerby",[-1.3479157556864536, 54.221314075543674]],
    [ "1148902422",[-1.8409694000000005, 53.85417075000001]],
    [ "UK Power Networks",[0.7565204011027142, 51.413457482980355]],
    [ "1149198456",[0.7680465, 51.40828885]],
    [ "Fairlands Way Pump Substation",[-0.20769370000000031, 51.904842249999994]],
    [ "New Works Depot Substation",[-0.20140671555770673, 51.99017371427565]],
    [ "Aberdeen Place B Substation",[-0.17457257399147827, 51.525443441782684]],
    [ "Aberdeen Place A",[-0.17430307991865085, 51.52564896251445]],
    [ "St Marylebone Substation",[-0.17368912184284227, 51.52549961921121]],
    [ "Network Rail",[-0.3220456606894601, 51.769008133914944]],
    [ "Network Rail",[-0.31586398214291445, 51.68354445250415]],
    [ "Network Rail",[-0.3869618030203259, 51.86662922423617]],
    [ "Network Rail",[-0.511897912232463, 52.02388663372439]],
    [ "Network Rail",[-0.4780387, 52.1322523]],
    [ "1152768738",[-2.520195340982176, 51.48104032035047]],
    [ "Creyke Beck",[-0.4134801830465029, 53.801367394815585]],
    [ "Keadby 1",[-0.7498951379060007, 53.597265689661974]],
    [ "1153099347",[-0.12208355129891267, 50.829054299829224]],
    [ "Lister Drive Greener Grid Park",[-2.9264041669497196, 53.41941061785834]],
    [ "1154010475",[-0.21268062410964986, 51.574165784660224]],
    [ "Townmead Road Substation",[-0.18618906692882448, 51.46849354646976]],
    [ "Ardmore Road",[-1.946516084356212, 50.723426231847874]],
    [ "Amberton Road",[-1.2143546523357458, 54.69656143134799]],
    [ "Hopton Hall Substation",[-1.6232091682857108, 53.07681063876214]],
    [ "1161187669",[-0.8217216856188646, 53.02875564684783]],
    [ "Fairlop Road Primary Substation",[0.08404584094858292, 51.593161603530106]],
    [ "Rhodesia CO2 Capture",[-1.1506930195038392, 53.308965337444484]],
    [ "Fenstanton - DO",[-0.07336598460015462, 52.2991186277605]],
    [ "UK Power Networks",[-0.3612353547857516, 51.79477265786581]],
    [ "London Power Network Public Limited Company",[0.06904764351487357, 51.59074678350673]],
    [ "UK Power Networks",[-0.3547963499999992, 51.81662525]],
    [ "1170862745",[-1.7347898042406906, 54.9740940939881]],
    [ "1170862746",[-1.7346584340337645, 54.973837399318285]],
    [ "Hornsea Bridge",[-0.16990725000000004, 53.90424805000001]],
    [ "Rolston Road North",[-0.16783853268729396, 53.9009914167653]],
    [ "London Power Network Public Limited Company",[0.0854673, 51.59290495]],
    [ "London Power Network Public Limited Company",[0.083023, 51.59227980000001]],
    [ "London Power Network Public Limited Company",[0.08221905000000002, 51.59110520000001]],
    [ "London Power Network Public Limited Company",[0.08349925, 51.59121575]],
    [ "London Power Network Public Limited Company",[0.08556385528898719, 51.5896430510093]],
    [ "London Power Network Public Limited Company",[0.08749279999999998, 51.587205049999994]],
    [ "London Power Network Public Limited Company",[0.055680199999999555, 51.58729054999999]],
    [ "Sennen Cove",[-5.697655845919495, 50.076815928565004]],
    [ "Sennen Beach",[-5.7018036, 50.07738065]],
    [ "Sennen Harbourside",[-5.705840458571776, 50.07743862493855]],
    [ "Mayon Cliff",[-5.703365781503545, 50.075602497809314]],
    [ "Trevorian Level 3",[-5.677551900000001, 50.0803946]],
    [ "Mayon Estate",[-5.6919738, 50.07692154999999]],
    [ "Lands End A",[-5.671874850000001, 50.06683960000001]],
    [ "Lands End B",[-5.67182027988235, 50.06685010292561]],
    [ "Kew Pendra",[-5.62246225, 50.07206295]],
    [ "St Buryan",[-5.6212785, 50.0749541]],
    [ "Mounts Bay Park",[-5.553834592070156, 50.09974993323342]],
    [ "Newlyn",[-5.5496204033340994, 50.10584626550666]],
    [ "Newlyn Ice Plant",[-5.548638400000001, 50.104599650000004]],
    [ "The Strand",[-5.549572625352744, 50.10456829495962]],
    [ "Boase Street",[-5.548839966197386, 50.102240944531076]],
    [ "Bay View",[-5.5525151, 50.102457]],
    [ "Faughan Lane",[-5.5532678, 50.10082620000001]],
    [ "Bowjey",[-5.54571776303913, 50.10055063490907]],
    [ "Gwavas",[-5.54926579765831, 50.09819103338049]],
    [ "Parade Housing Estate",[-5.537579416439726, 50.085056849733775]],
    [ "Mousehole",[-5.5400755344039965, 50.08479717815845]],
    [ "Portland Place",[-5.53940198115809, 50.08196197635938]],
    [ "Tredarvah Estate",[-5.549988, 50.11871340000001]],
    [ "Penhalverne Crescent",[-5.544071381915432, 50.11930277418542]],
    [ "St Johns Hall",[-5.540602399999999, 50.11885555]],
    [ "Alverton Terrace",[-5.541989181523286, 50.11796756541799]],
    [ "Buriton Row",[-5.540089121800069, 50.11803649489875]],
    [ "Greenmarket",[-5.537580767463134, 50.11833149767495]],
    [ "Jennings Street",[-5.5356974, 50.118673699999995]],
    [ "Laundry & CWLL Frms",[-5.534352703799375, 50.11846484506729]],
    [ "Dock Lane",[-5.532285254225328, 50.11665593360178]],
    [ "Jubilee Pool",[-5.532242800000001, 50.11559395]],
    [ "Telephone Exchange",[-5.536378804841724, 50.11810021633101]],
    [ "Queens Street",[-5.535568760866351, 50.11622342266742]],
    [ "Redinnick Place",[-5.539481386429979, 50.1141324669654]],
    [ "Alexandra Gardens",[-5.547529849305495, 50.11599050810518]],
    [ "Cardinnis Road",[-5.552132467020706, 50.11689259984394]],
    [ "Trenoweth Road",[-5.554480060405086, 50.115682115093634]],
    [ "Toltuff Road",[-5.550404112214604, 50.114588482385415]],
    [ "Croft Road",[-5.5524035000000005, 50.11324995]],
    [ "Alexandra Road",[-5.541762950000001, 50.11318200000001]],
    [ "Lidl Penzance Promenade Road",[-5.542634492261121, 50.11312763362418]],
    [ "Lower Mennaye",[-5.545781127124051, 50.11334275626456]],
    [ "The Ropewalk",[-5.551599641634088, 50.11159312467658]],
    [ "Laregan Hill",[-5.5468049000000015, 50.1110071]],
    [ "Trewidden Estate",[-5.550034008653372, 50.110074529749504]],
    [ "Tolcarne",[-5.550812858547214, 50.10746795774425]],
    [ "Lower Gurnick Road",[-5.555403200000001, 50.10710875]],
    [ "Newlyn Coombe",[-5.556211731287424, 50.10829539176181]],
    [ "Polventon Close",[-5.554935991078927, 50.12681431866996]],
    [ "Penwith Housing",[-5.551383749999999, 50.12511304999999]],
    [ "Heamoor Kiosk",[-5.551236396911731, 50.12652383224037]],
    [ "Manor Way",[-5.548459616937854, 50.125356546913075]],
    [ "Polmeere Road",[-5.54306135, 50.12900299999999]],
    [ "Barton Close",[-5.547153067156486, 50.12822419854044]],
    [ "Treneere Manor",[-5.545685790934844, 50.12730757678942]],
    [ "Treneere",[-5.5409675499999995, 50.12810275]],
    [ "Lescudjack School",[-5.538832349999999, 50.126784549999996]],
    [ "Lescudjack Hill",[-5.5354068000000005, 50.12632505]],
    [ "Chyandour",[-5.529528610952918, 50.12531218166103]],
    [ "Thornberry Terrace",[-5.533456899999999, 50.1243949]],
    [ "Newtown Lane",[-5.534152998281014, 50.12019086752388]],
    [ "Britons Hill",[-5.532212612445604, 50.12337893115752]],
    [ "St Michaels Garage",[-5.533381899999999, 50.12256239999999]],
    [ "Penwith College",[-5.543450552702126, 50.12521678093544]],
    [ "Trewartha Terrace",[-5.535889972994577, 50.12388781574341]],
    [ "Barwis Hill",[-5.5385482, 50.123779]],
    [ "County School",[-5.5408222, 50.124110099999996]],
    [ "Kings Ransom",[-5.546746465364468, 50.1222693308315]],
    [ "St Clare Street",[-5.541464679414237, 50.122047347349216]],
    [ "Prospect Place",[-5.539668258264882, 50.12225702014774]],
    [ "St Clare Flats",[-5.540036019214576, 50.12148129738676]],
    [ "Penzance Leisure Centre",[-5.544690025312651, 50.12112984610018]],
    [ "Taroveor",[-5.536194319603156, 50.121547582176476]],
    [ "Poltair Terrace",[-5.555987550000001, 50.128881650000004]],
    [ "Bolitho Road",[-5.552184896730247, 50.1287852720997]],
    [ "Boscathnoe Lane",[-5.558277031608392, 50.12558070219114]],
    [ "Penarwyn Crescent",[-5.556171514895152, 50.125890482230574]],
    [ "Tregeseal",[-5.677264, 50.12835330000001]],
    [ "St Just Bakery",[-5.684074957076665, 50.12576990503126]],
    [ "St Just School Canteen",[-5.68473917520267, 50.12489245507659]],
    [ "Regent Terrace",[-5.685169, 50.1230718]],
    [ "Lafrowda Fields",[-5.681775144632032, 50.1229233804349]],
    [ "Carrallack Park",[-5.681801149999999, 50.121820400000004]],
    [ "Fore Street St Just",[-5.679378450000001, 50.122539749999994]],
    [ "St Just Bank Square",[-5.6798122499999995, 50.1246953]],
    [ "Higher Gews Close",[-5.677192516816811, 50.12224280024111]],
    [ "Westward Airways",[-5.667640082946319, 50.102174552807924]],
    [ "Marazion Snack Bar",[-5.490871191482589, 50.12909322307646]],
    [ "Cuxhaven Way",[-5.50410575, 50.1309488]],
    [ "Poniou Way",[-5.503786264883819, 50.12986382233763]],
    [ "1175376649",[-5.503368016745755, 50.12988195114982]],
    [ "Longrock",[-5.499534199999999, 50.13093370000001]],
    [ "Beachfield Longrock",[-5.499605481080609, 50.12917528002954]],
    [ "Shippams Longrock",[-5.50751455, 50.12941609999999]],
    [ "Ponsandane Depot",[-5.50886561173784, 50.12843012951741]],
    [ "Safeway Superstore",[-5.51469001110758, 50.127815272125865]],
    [ "Penzance S/Stores",[-5.514694326611228, 50.128649857229895]],
    [ "Gulval",[-5.5274193452031115, 50.13096743126819]],
    [ "Sainsbury Eastern Green",[-5.519801418776412, 50.12836714929286]],
    [ "Gulval Mead Makers",[-5.530005123969336, 50.1323421652759]],
    [ "Pendrea Gulval",[-5.5296844114185, 50.12991247765809]],
    [ "Eastern Green Area 4",[-5.526099050000001, 50.1297135]],
    [ "Tesco Branwell Lane",[-5.525815501725817, 50.128389934073624]],
    [ "Finns Shoe Factory",[-5.52502317524253, 50.12757281594503]],
    [ "Boscaswell Housing",[-5.669259450000001, 50.15454465]],
    [ "Geevor Mine",[-5.675388624844361, 50.151875487760826]],
    [ "Geevor Mill",[-5.675855599999999, 50.15309925000001]],
    [ "Kenidjack",[-5.689211016290983, 50.132707948523]],
    [ "Botallack House",[-5.6891831999999996, 50.1406207]],
    [ "Levant Engine House",[-5.68526785, 50.15226605000001]],
    [ "Trewellard Road",[-5.67253355, 50.14499715]],
    [ "St Michaels Reach",[-5.469855145173029, 50.12373448110632]],
    [ "Fore Street",[-5.471353687520588, 50.123587175348]],
    [ "Godolphin Hotel",[-5.473881998201841, 50.12335511688675]],
    [ "The Gew",[-5.476231617740356, 50.12660908966908]],
    [ "Boscaswell Terrace",[-5.661590766278115, 50.150905583198]],
    [ "Madron",[-5.565124335527673, 50.13313693112511]],
    [ "Aldreath Road",[-5.564724999999999, 50.13428725000001]],
    [ "Trelawney Estate",[-5.566571822071483, 50.13206055559351]],
    [ "1175555882",[-1.5597034999999997, 55.01314455]],
    [ "Lelant Manor",[-5.439188799999999, 50.18860874999999]],
    [ "Larrigan Estate",[-5.4403889, 50.18633075]],
    [ "Lelant Hotel",[-5.439056191699589, 50.18380186996508]],
    [ "Green Lane Lelant",[-5.4367873499999995, 50.186360699999994]],
    [ "Lelant Wharf",[-5.433475992517564, 50.18892368153436]],
    [ "Trevethoe",[-5.453522166657189, 50.18184624997944]],
    [ "Porthia Close",[-5.489461649999999, 50.203718]],
    [ "Breamish Street",[-1.5957126008036475, 54.97256049419451]],
    [ "Castle an Dinas",[-5.5224883, 50.15622195]],
    [ "Spring Lane Solar Park",[-1.4478224254539576, 52.727356096504856]],
    [ "Cliff Road North",[-0.16778622900108767, 53.920520601856815]],
    [ "Cliff Road South",[-0.16470455, 53.91590025000001]],
    [ "New Lane",[-0.16173019999999924, 53.913238449999994]],
    [ "Hornsea Sands Lane",[-0.1603962577966971, 53.91059543600307]],
    [ "Hornsea Northgate",[-0.1738360810363692, 53.918447406780345]],
    [ "Hornsea Draycott",[-0.17109505, 53.91707065000001]],
    [ "Wilton Road",[-0.16480374736488088, 53.91038998225505]],
    [ "King Street",[-0.17155934381308074, 53.909399684765894]],
    [ "Newbegin Exchange",[-0.17094875, 53.9116121]],
    [ "Newbegin",[-0.16780293335002827, 53.91241739969635]],
    [ "Hornsea Mere Lodge",[-0.17223665000000002, 53.90562685]],
    [ "Hornsea Hull Road",[-0.17653022292277576, 53.90194523494893]],
    [ "Tesco Hornsea",[-0.1705331169626182, 53.906083404388745]],
    [ "1176385645",[-1.5443378289157739, 54.52866218477495]],
    [ "Whimbrel Avenue",[-0.16521407229242968, 53.90294499252302]],
    [ "Marlborough",[-0.17401085, 53.901093800000005]],
    [ "Atwick Road",[-0.17691525, 53.9148258]],
    [ "Trenwith",[-5.491426233387649, 50.20686700095354]],
    [ "Parc An Creet",[-5.493909399999999, 50.20834145]],
    [ "Trewidden Road St Ives",[-5.4824404333311945, 50.208313699932404]],
    [ "St Ives Leisure Pool",[-5.48582965, 50.2087007]],
    [ "Infants School St Ives",[-5.4876847, 50.208507850000004]],
    [ "Trerice Place",[-5.488454230856927, 50.21047396372845]],
    [ "Albert Road",[-5.47897585, 50.20909530000001]],
    [ "Guildhall St Ives",[-5.4807615, 50.21187724999999]],
    [ "Royal Cinema",[-5.482305023995212, 50.21092504478449]],
    [ "Bullans Lane",[-5.486157031656135, 50.21151518082967]],
    [ "Alexandra Road St Ives",[-5.489383686434086, 50.21237372827]],
    [ "Beach Road St Ives",[-5.489344600000001, 50.21477695]],
    [ "Belmont Place",[-5.484769766068863, 50.212545244265264]],
    [ "Back Street",[-5.480646708853653, 50.213126774602166]],
    [ "Meadow Flats",[-5.48315425, 50.21414869999999]],
    [ "Barnoon Terrace",[-5.4811309, 50.213888450000006]],
    [ "Porthmeor",[-5.481363433489025, 50.214686100298096]],
    [ "The Island",[-5.47836549645267, 50.21669978299916]],
    [ "Chy an Gweal",[-5.472078244086083, 50.19794168729638]],
    [ "Somerfield St. Ives",[-5.472948522465666, 50.19817111323014]],
    [ "Hain Walk",[-5.471580174333176, 50.205767845355474]],
    [ "Tregenna Lodges",[-5.473411458338676, 50.205100781102146]],
    [ "Belyars Estate",[-5.4824868189642055, 50.20402931397887]],
    [ "Carbis Bay",[-5.4689315, 50.19392990000001]],
    [ "Carrack Gladden",[-5.4649994202989705, 50.19735275289938]],
    [ "Hendra Hotel",[-5.463000823447288, 50.196193455193296]],
    [ "Carninney Rise",[-5.47307733276479, 50.193605352152346]],
    [ "Carbis Estate",[-5.47191625, 50.19523365]],
    [ "Porthrepta",[-5.461231755553613, 50.193102355478565]],
    [ "Barrepta Cove",[-5.459617742101464, 50.19580346128857]],
    [ "Boskerris Estate",[-5.465630513465136, 50.19338044685563]],
    [ "Polmennor Drive",[-5.466989832556119, 50.190658401068674]],
    [ "Carbis Bay Holiday Centre",[-5.467844082590402, 50.186300350821675]],
    [ "Laity Lane Hol Centre",[-5.466957926606803, 50.18826124491253]],
    [ "Trencrom Lane",[-5.462940323848215, 50.190913307817944]],
    [ "Vicarage Gate",[-5.42785565, 50.1651705]],
    [ "School Lane",[-5.428638033477829, 50.166140400659266]],
    [ "Trehayes Meadows",[-5.4358955, 50.165418100000004]],
    [ "London Power Network Public Limited Company",[0.05357445428189448, 51.59055719441982]],
    [ "El Sub St",[-2.594827202511415, 53.29284511634616]],
    [ "London Power Network Public Limited Company",[0.0505755099254619, 51.590134020321564]],
    [ "London Power Network Public Limited Company",[0.04941835233511763, 51.59067878360268]],
    [ "1187840777",[-0.3261895940628548, 52.24522467468432]],
    [ "London Power Network Public Limited Company",[0.07542323992160502, 51.589489626076315]],
    [ "Haxey",[-0.8130432767002262, 53.485649156537654]],
    [ "Ashby",[-0.4156937657567534, 53.73346807265973]],
    [ "John Leggott Car Park",[-0.6701365113267117, 53.57312624715683]],
    [ "Victoria Street Substation",[-1.481844232424132, 53.380877488152365]],
    [ "London Power Network Public Limited Company",[0.08038696174311889, 51.589027518243334]],
    [ "London Power Network Public Limited Company",[0.08059166498057997, 51.58807797529655]],
    [ "1192389041",[0.14017748107577668, 52.17604939708517]],
    [ "Rawson Spring Road",[-1.49586715, 53.411302500000005]],
    [ "London Power Network Public Limited Company",[0.06515146237979982, 51.57241814968395]],
    [ "Blue Boy Street Substation",[-1.4756020865301003, 53.386953095976736]],
    [ "SSE Power Distribution",[-1.922413685436692, 50.71686012463882]],
    [ "1210608541",[-0.8391997891119937, 53.60482452894656]],
    [ "Unite Student Accommodation",[-1.1811268330065094, 52.9486084011361]],
    [ "Northern Powegrid- Stanley Street",[-1.465293585273907, 53.38847438391897]],
    [ "Gibson Lane WF",[-0.5314263733540064, 53.71621873750457]],
    [ "Foss Beckwith",[-1.0719139500000001, 53.96111315]],
    [ "Farnley Crescent",[-1.6155719789803487, 53.79054449202885]],
    [ "Whitehall Road",[-1.5530992028894506, 53.795006471220894]],
    [ "Sulzers",[-1.5745804333333335, 53.76930379298245]],
    [ "New Market Approach Generation",[-1.5030889837464736, 53.78737452730286]],
    [ "Barden Friar",[-1.7945281599674148, 54.337777004800905]],
    [ "Premier Way North 2",[-1.3751565614385828, 53.70412396656988]],
    [ "Premier Way North 1",[-1.3785483947815613, 53.70398066613961]],
    [ "Duke Street",[-1.3425155002167186, 53.7321041383134]],
    [ "Smith Street",[-1.3387281877667285, 53.731856739045504]],
    [ "Alverthorpe Rd",[-1.5197025474953485, 53.682402538445764]],
    [ "North Avenue",[-1.6891730523046167, 53.90910561442499]],
    [ "Gildersome Bradford Rd",[-1.6351617047742562, 53.750521033908505]],
    [ "Nab Lane",[-1.6433380405780427, 53.7381479137831]],
    [ "Batley",[-1.6272977703026354, 53.70713813240173]],
    [ "Calder Wharf",[-1.6544269589927312, 53.679447540208386]],
    [ "Gascoigne Wood",[-1.2030140907529847, 53.78090327729703]],
    [ "Upper Basinghall Street",[-1.5461836187405786, 53.79905897990266]],
    [ "Armouries Drive",[-1.5299316126116804, 53.790691035978625]],
    [ "Leylands Rd",[-1.534443024157647, 53.802813435264824]],
    [ "Burnleyville",[-1.6886457011965048, 53.73219469849856]],
    [ "Spenborough",[-1.705864525391391, 53.72196507655375]],
    [ "Snelsins Lane",[-1.7216817699837559, 53.73184573061932]],
    [ "South Street",[-1.9131647816593718, 53.85821898635263]],
    [ "Bishopthorpe WF",[-0.0029332499999999997, 53.515326099999996]],
    [ "High Wycombe Town Substation",[-0.7541388727533479, 51.628920853472934]],
    [ "Elvington Park",[-0.9524727864516127, 53.92490017118279]],
    [ "Roos WF",[-0.06727473513754542, 53.745235122643415]],
    [ "Bilton Generation",[-0.23798005934395974, 53.77562316304148]],
    [ "Scurf Dyke Gen",[-0.40196615715085143, 53.95205877856558]],
    [ "Ashleworth Sawmills",[-2.274125332258251, 51.93065344789548]],
    [ "Ashleworth Green",[-2.2743796422140563, 51.92752471949165]],
    [ "Wilson Road Substation",[-1.0048212019833183, 51.454291399207264]],
    [ "Manton Road Edith Weston",[-0.6387487999999987, 52.63612414999999]],
    [ "Ullswater Avenue Edith Weston",[-0.6426963500000001, 52.636496150000006]],
    [ "New Street Substation",[-1.7849264998237846, 50.841660892484]],
    [ "Oak Street Carrington",[-1.1528208251077443, 52.973900844510815]],
    [ "1221017115",[-2.5070429217190164, 52.99701774690539]],
    [ "Tong Street",[-1.7198445230063721, 53.77053217465097]],
    [ "1222740582",[0.3838702481508099, 51.44141549144334]],
    [ "Eastney Substation",[-1.0596772295908197, 50.787197970043756]],
    [ "1227631887",[-1.9990919500000008, 51.6079038]],
    [ "1228759633",[-1.9670474598949108, 50.60848102931495]],
    [ "Codmore Hill Substation",[-0.4942636510029843, 50.968957106499445]],
    [ "1230163143",[-0.28540138307970203, 53.587798583580835]],
    [ "1230166682",[-0.2858392038260152, 53.58808079751707]],
    [ "1230166683",[-0.2848261777241104, 53.58706083391828]],
    [ "1230166693",[-0.2560701236680306, 53.612504392528834]],
    [ "1230641461",[-1.122558545371669, 53.97276846232398]],
    [ "Pumping Station No 1. Lambley",[-1.0674815210612176, 52.99889372975072]],
    [ "Corbin Hall",[-1.9323483735243874, 50.7209094555909]],
    [ "Netherfield Lane Colwick",[-1.068172, 52.96157265]],
    [ "Victoria Parkway",[-1.0649285919681382, 52.95952587793062]],
    [ "Romanby",[-1.4528981830499341, 54.32679392781866]],
    [ "Grain Synchronous Condenser",[0.7127965187143056, 51.4451076368544]],
    [ "1238104263",[1.0978823298113372, 51.29477935027875]],
    [ "The Orchards Elston",[-0.8668467800741102, 53.02195916330123]],
    [ "Woodbank Drive Wollaton",[-1.2321892050673293, 52.945801297715484]],
    [ "Sibthorpe Village",[-0.8698892285555361, 53.00218771505609]],
    [ "1246131753",[-0.29381536537278546, 53.63267980938931]],
    [ "Electricity North West",[-2.0459384222828434, 53.492049449761716]],
    [ "1248881254",[-1.705940457377846, 52.516161706711195]],
    [ "National Grid Electricity Distribution Plc",[-1.7130871637312672, 52.532825355246366]],
    [ "Portable Floors",[-1.2677516411189895, 52.86569117535164]],
    [ "Low Farm Solar 33kV",[-0.13581799642375744, 53.53430220890783]],
    [ "SSE",[-0.4519570438427278, 51.53416044370757]],
    [ "1250852233",[-0.4234300704420757, 51.50420557890058]],
    [ "1253301933",[0.43953927998268066, 52.07472888699415]],
    [ "1253946422",[-2.7284083837405797, 52.07727012080003]],
    [ "Westion Power",[-2.725762402085088, 52.07951643796721]],
    [ "1255408450",[-1.0304379226840672, 53.78792647255006]],
    [ "Spaldington WF",[-0.8700898825041814, 53.785342749346626]],
    [ "Selby STOR",[-1.0431888113347174, 53.77433611102128]],
    [ "UK Power Networks",[-0.02520195730651508, 51.68600404616945]],
    [ "1261999555",[-1.5498659, 52.3892199]],
    [ "1262295975",[-2.1330973500000003, 52.9761584]],
    [ "1263577276",[-0.13946734955978052, 53.570724687891]],
    [ "Great Field Lane Generation",[-0.25375948232504797, 53.74890702058542]],
    [ "Hull Reserve Power 57923",[-0.23195011552667627, 53.74474443333334]],
    [ "Nissan",[-1.4690599727553444, 54.91779272631176]],
    [ "1264643797",[-1.7021115286389088, 54.412995917312365]],
    [ "Tingley",[-1.5738289333946245, 53.73177614625806]],
    [ "Park Road",[-1.6323018641165061, 53.684778039992736]],
    [ "Liversedge",[-1.6938525040359393, 53.70804878837185]],
    [ "Low Moor",[-1.76226290607346, 53.75473991882802]],
    [ "Constitutional Hill Substation",[-3.5545343629388793, 54.57179523455999]],
    [ "1271279102",[-1.2622354868665318, 51.62483205028438]],
    [ "1271282305",[-2.000580930635553, 51.607264141392164]],
    [ "National Grid plc",[0.97467116867853, 51.10770974724382]],
    [ "Sellindge Converter Station",[0.9760924475139008, 51.10660495403463]],
    [ "Sellindge Substation",[0.9722296701654706, 51.105730168400534]],
    [ "1272605994",[-0.6499228446751766, 53.85707663056836]],
    [ "1272605995",[-0.6517457632559054, 53.856618746310374]],
    [ "1273115725",[-1.5576504, 54.59823625]],
    [ "Saltholme Substation",[-1.2371582973127435, 54.60541243015454]],
    [ "Littlebrook Substation",[0.23761348944282137, 51.46361142877837]],
    [ "1278243737",[-0.13735745010938485, 53.55393933332962]],
    [ "Abbey Hey Lane (No 83)",[-2.1593669499999995, 53.47079845]],
    [ "UK Power Networks",[-0.025444386419349645, 51.49891411860754]],
    [ "1280027997",[-0.1644545499999992, 53.574474900000006]],
    [ "1280551754",[-2.1141481193241014, 52.79694511651558]],
    [ "1280551755",[-1.9885457500000048, 52.78858815]],
    [ "Mauxhall Farm",[-0.19906839566080695, 53.6034263215299]],
    [ "Debdale Park",[-2.1562007500000004, 53.458012350000004]],
    [ "1284807674",[-0.12107312451661927, 53.581949194134474]],
    [ "1288748607",[-0.09271372775632619, 53.5419379746121]],
    [ "Northern Powergrid",[-0.53594385, 54.4361805]],
    [ "Greystones 66KV Substation",[-1.1310780704347194, 54.575850515590396]],
    [ "Forkers Yard Battery Storage Facility",[-2.0228153870739116, 52.53947845361954]],
    [ "Three Mile House S/S / Mautby",[1.699119327234716, 52.62576889547742]],
    [ "RWE",[1.7340174357020381, 52.584605732361595]],
    [ "1296355310",[-1.7645464806678528, 53.8372387895974]],
    [ "Buslingthorpe Lane",[-1.5443078581309087, 53.81563860835936]],
    [ "Northern Powergrid",[-1.4463220822268708, 53.825095671572555]],
    [ "Northern Powergrid",[-1.4436931969427398, 53.824755816518405]],
    [ "Sledmere Place",[-1.4496568332864601, 53.82525715051092]],
    [ "Seacroft",[-1.462768361692911, 53.826783592075735]],
    [ "Pallion Trading",[-1.4236565939050736, 54.90945376756185]],
    [ "Sunderland Ormonde",[-1.4111344809273423, 54.897255403239846]],
    [ "Hartmoor",[-1.2907037726487924, 54.701389161554864]],
    [ "Hartmoor",[-1.29203841580996, 54.70063198824823]],
    [ "Binswood Avenue",[-1.53957050262734, 52.296742528671224]],
    [ "Bushey Trackside Substation",[-0.38484926675872566, 51.646580318835504]],
    [ "1300074139",[-0.11427455000000002, 53.553614149999994]],
    [ "National Grid Electricity Distribution",[-1.6497407340699215, 52.77201424226055]],
    [ "Northern Powergrid",[-1.0709367563085082, 53.96394349005345]],
    [ "Fratton Park Substation",[-1.0627686535533762, 50.79722212473129]],
    [ "Greetham Street Substation",[-1.0874282753204603, 50.79765233138853]],
    [ "The Lane Carsington Substation",[-1.6279582, 53.078142899999996]],
    [ "1305125626",[-0.136203, 53.567785949999994]],
    [ "1305624724",[-0.11786705174822125, 53.55238208531876]],
    [ "1305624725",[-0.11958787395932416, 53.55375534917255]],
    [ "Barking (BR) Feeder Station",[0.08540169999999998, 51.53746399999999]],
    [ "1305734698",[0.1441921146944378, 51.5683631056173]],
    [ "1305738792",[0.17323674884960308, 51.547479122878784]],
    [ "1305738806",[0.16883288442057962, 51.54713934295523]],
    [ "1305739451",[0.2903559018327912, 51.51380717252773]],
    [ "1305758726",[0.12933871615121514, 51.76872764556616]],
    [ "1305765440",[-0.6221031802492011, 51.521064047917896]],
    [ "1305765444",[-0.6251641500000005, 51.52328255]],
    [ "1305765445",[-0.625428094600194, 51.52286369115439]],
    [ "1305765455",[-0.6172307781420358, 51.518274981517784]],
    [ "1305765460",[-0.620270871725539, 51.52229892894544]],
    [ "1305776003",[-0.6226740728171655, 51.53657529940425]],
    [ "1305776806",[-0.5877646815390899, 51.51322244676875]],
    [ "1305776807",[-0.5843745845475519, 51.51083101838366]],
    [ "1305777442",[-0.5484654731539782, 51.50491772637905]],
    [ "1305789869",[-0.428057222310362, 51.50327465967011]],
    [ "1306342648",[-0.06006144118319003, 51.626116073996755]],
    [ "1306357950",[-0.07290913917658966, 51.63308123839236]],
    [ "1306358139",[-0.04228112612587041, 51.642363353919535]],
    [ "1306358480",[-0.02112724215636704, 51.6583587472899]],
    [ "1306359055",[-0.05689499856756225, 51.65187041062056]],
    [ "1306359542",[-0.0727285585672088, 51.653089876420545]],
    [ "1306359685",[-0.09604015140525075, 51.66527554552723]],
    [ "1306360129",[-0.11271054998610408, 51.648845320576186]],
    [ "1306566359",[-0.1772794802052851, 51.59022262702398]],
    [ "1306568777",[-0.1991912439271918, 51.65446812191996]],
    [ "1306571911",[-0.3375211047048467, 51.59424792964125]],
    [ "1306572439",[-0.3297311367055717, 51.5901707883482]],
    [ "1306572907",[-0.27872337474696224, 51.612316276046386]],
    [ "1306607710",[-0.040465351363668633, 51.620134314798804]],
    [ "1306848099",[-0.12402789999999998, 53.56393150000001]],
    [ "1306848106",[-0.12084328518811675, 53.56367512311314]],
    [ "1306854725",[-0.13139971449717955, 53.55148477323445]],
    [ "1306854737",[-0.13721518633221913, 53.5513762718187]],
    [ "1306863264",[-0.4302222167769541, 51.75687711758042]],
    [ "1306867359",[-0.4150931331008417, 51.628192664160636]],
    [ "The Showfields Idno",[-0.1229109664062231, 52.56440138886885]],
    [ "Bassenhally Road",[-0.12057373517701685, 52.56110301357419]],
    [ "Harrogate West",[-1.5634623066747735, 53.988153495229206]],
    [ "Oatlands",[-1.5312611648008088, 53.976794302983244]],
    [ "Oatlands",[-1.5311514552620924, 53.97659521942689]],
    [ "1307300448",[-0.13295269999999995, 53.54946409999999]],
    [ "Sessay Bridge",[-1.319303244956543, 54.174179897596254]],
    [ "Rise Carr",[-1.5591006317746776, 54.54628016794902]],
    [ "Rise Carr",[-1.5590100009254741, 54.54629376482072]],
    [ "Peterlee Industrial",[-1.3588447838596578, 54.77275575973994]],
    [ "Peterlee West",[-1.3716021819928812, 54.7570857600398]],
    [ "Tees Industrial",[-1.1726483657056859, 54.60898642083203]],
    [ "Billingham Marsh House",[-1.283958990070505, 54.616044120817186]],
    [ "Faraday Street",[-1.2484825930085022, 54.569440075175386]],
    [ "Spencerbeck",[-1.188903046532029, 54.57189754018854]],
    [ "Acklam",[-1.2464102666181844, 54.54774460079582]],
    [ "Millbank Lane",[-1.298263766737703, 54.53983495516184]],
    [ "Newcastle Airport",[-1.7100912057303963, 55.034987275966344]],
    [ "1307553987",[-0.14452799026103275, 53.56532688013128]],
    [ "Gateshead Central",[-1.600755495894124, 54.96038302360339]],
    [ "Corporation Street",[-1.6221115815165945, 54.973444352735996]],
    [ "Educational Precinct",[-1.6081500633401686, 54.97937252915332]],
    [ "University",[-1.6182200626883885, 54.98206791368762]],
    [ "1307919952",[-0.09720033544007194, 53.55059287609869]],
    [ "1308472355",[-0.12788825000000054, 53.551048]],
    [ "Biology Laboratory Switchroom",[-1.0573897111190569, 53.947610930827494]],
    [ "Biology Laboratory Substation",[-1.0574771479831615, 53.94760889028961]],
    [ "1308664419",[-0.11958167584028746, 53.57875644374823]],
    [ "1311885646",[-4.122382229383787, 51.04564961713559]],
    [ "1311887649",[0.3163466692901479, 52.278875298370316]],
    [ "Leiston Substation",[1.6112786381287072, 52.208069136245896]],
    [ "1313029459",[1.6122431857929993, 52.20838238079065]],
    [ "Arnolds Drive",[-0.12566425070422527, 52.56005016431925]],
    [ "Redlands Substation",[-2.4639231500000003, 50.63898485]],
    [ "1317378057",[-0.10781222297928937, 51.85607893427543]],
    [ "1317705013",[-0.027755758939669944, 51.71904807953629]],
    [ "1319321492",[-0.511065647047139, 53.541332354196136]],
    [ "1319323885",[-0.05709137740466991, 53.5053988269874]],
    [ "1319323886",[-0.052674584111068096, 53.50212441586331]],
    [ "1319345521",[-0.7429499600782913, 53.63995687544736]],
    [ "1319347455",[-0.7496120530022697, 53.597460748145046]],
    [ "1319347456",[-0.7441033524909342, 53.59702319371915]],
    [ "1319561119",[-0.7810588506843253, 53.63860400584641]],
    [ "Carloggas Farm 33kv Solar",[-4.874887723456791, 50.352553874537044]],
    [ "1323427356",[-1.071536128637682, 53.426061818212176]],
    [ "1323522522",[-0.2263893735031217, 51.52627321263355]],
    [ "Idle",[-1.734331398380159, 53.82588756887754]],
    [ "Moorside Rd",[-1.7224131346331129, 53.81434471568043]],
    [ "Thornbury",[-1.7101476982328874, 53.79859022311569]],
    [ "Killinghall Rd",[-1.7190430126158924, 53.796649660208395]],
    [ "Mount Street",[-1.729201769415319, 53.78837924791984]],
    [ "Dudley Hill",[-1.727332556286556, 53.774907763892756]],
    [ "Odsal",[-1.762317639121547, 53.76380219623569]],
    [ "Wibsey",[-1.7843860098974642, 53.76915535466557]],
    [ "Saint Street",[-1.786980429524807, 53.78046870318083]],
    [ "Legrams Mill Lane",[-1.7873383464342574, 53.78910624611097]],
    [ "Toller Lane",[-1.7914282725812234, 53.81042608690668]],
    [ "Gaisby Lane",[-1.763690989821321, 53.819910185620486]],
    [ "Rawson Rd",[-1.758757361867693, 53.796497056742844]],
    [ "Crown Street",[-1.7683166659053553, 53.79732103433071]],
    [ "Balme Street",[-1.7484535653092326, 53.79737915997923]],
    [ "Manchester Rd",[-1.755098227828632, 53.78919431982439]],
    [ "Townhead",[-1.9315456677424037, 53.9174580827504]],
    [ "Thornton",[-1.8323841843435844, 53.79261365063936]],
    [ "Denholme",[-1.8977510398406012, 53.80489460550099]],
    [ "Oxford Street Substation",[-0.060204732156838865, 53.570032269485075]],
    [ "Electronic Valve Co East Bridgford",[-0.9712693162624136, 52.98040490331228]],
    [ "Cross Lane E Bridgford",[-0.9612447201002857, 52.98053270265431]],
    [ "College Street E Bridgford",[-0.9670790497885199, 52.981039487552884]],
    [ "Grantham Road",[-0.13683019999999999, 50.83954765000001]],
    [ "Ashorne Solar Farm Substation (Under Construction)",[-1.5195148840021728, 52.25326594424311]],
    [ "1329873929",[-0.8591253000000001, 53.363061349999995]],
    [ "Lovelace Road",[-1.270469911284943, 51.78975909033201]],
    [ "1330185732",[-1.2390621397395765, 54.60555978584667]],
    [ "Seal sands",[-1.2011321335956178, 54.607203598896724]],
    [ "1331105895",[-1.1316982804701181, 51.54172188554356]],
    [ "Beenham",[-1.1650977677051, 51.41731498723842]],
    [ "Love Lane",[-1.3209842528389653, 51.413424046051844]],
    [ "1331446750",[-1.2839959446719642, 51.37613668154845]],
    [ "1332426108",[-1.0731759153147378, 53.42293731814693]],
    [ "1332704885",[-0.09042757680372779, 53.5578962086188]],
    [ "1332709678",[-0.05797988432071236, 53.552175653584165]],
    [ "Necton Substation",[0.7923729300801109, 52.661165795572806]],
    [ "1335138398",[-0.20729265, 53.61525874999999]],
    [ "1335164661",[-0.13893193647642685, 53.56526026666667]],
    [ "1336279118",[-0.08735556686889101, 53.56071124979052]],
    [ "1337532981",[-0.05196118303710614, 53.55993193283443]],
    [ "1337615747",[-0.15437755, 53.57792405]],
    [ "Calow Lane Hasland",[-1.4042235499999995, 53.22105270000001]],
    [ "1339612049",[-0.0242522, 53.5475882]],
    [ "1340993910",[-0.025505417865118035, 53.52350638408718]],
    [ "1341343952",[-0.12692212488463916, 53.552894343729825]],
    [ "Richdale Ave",[-0.5974143282789246, 53.47990672499215]],
    [ "1342711885",[-0.023997929671109093, 53.53671486543194]],
    [ "1342714949",[-0.020586002196005163, 53.537912118732244]],
    [ "1344880140",[-0.0342777, 53.55479970000002]],
    [ "1344895973",[-0.0301666500000004, 53.55286194999999]],
    [ "1346661743",[-0.2200701, 53.54776749999999]],
    [ "1346915186",[-0.9340204328105138, 53.32709960017961]],
    [ "1347152296",[-0.13669959999999998, 53.562719200000004]],
    [ "1347152297",[-0.1399660954260391, 53.56114908296141]],
    [ "1347404291",[-0.07675288316265917, 53.56225692230636]],
    [ "1347841153",[-0.1218981812206584, 53.57045667042254]],
    [ "1347841775",[-0.12109571604289396, 53.569014647592105]],
    [ "1347842473",[-0.09237408897213906, 53.54877867727684]],
    [ "1348056574",[-0.12459315000000111, 53.5710306]],
    [ "1348057392",[-0.12863830681405097, 53.56886256967798]],
    [ "1348059186",[-0.11286619513822524, 53.54841930905523]],
    [ "1348071510",[-0.10340127418916259, 53.56578424641743]],
    [ "1348341576",[-0.057142249999999346, 53.571462950000004]],
    [ "1348499014",[-0.21219492259699566, 53.608152193558205]],
    [ "1349081130",[-0.12213426896379688, 53.55562385292912]],
    [ "1349081131",[-0.12842179999999914, 53.55512425000001]],
    [ "1350081468",[-0.05538165031726349, 53.52942650252579]],
    [ "St Stephens Primary",[1.2901094181103874, 52.62492210764258]],
    [ "Earlham (Grid) Local",[1.228104821598313, 52.62999549787143]],
    [ "1351637196",[-0.04182353410639698, 53.54767778032485]],
    [ "1351645077",[-0.04549740000000027, 53.56329990000001]],
    [ "347330011",[-2.4745503, 51.4491064]],
    [ "426753591",[0.1080795, 51.3865612]],
    [ "Watery Lane",[-2.1925123, 51.825759]],
    [ "Lakin Court",[-1.5815696, 52.2882691]],
    [ "Scar Bank",[-1.5859816, 52.2911746]],
    [ "Low Road",[-1.5233172, 53.7803304]],
    [ "Ufton Fields SW",[-1.4490068, 52.251397]],
    [ "725158656",[-1.392406, 53.0001917]],
    [ "Kynance Cove Substation",[-5.2312141, 49.9758753]],
    [ "Kyance Carn Goon Substation",[-5.2174145, 49.9737346]],
    [ "Central Networks",[-1.7127413, 52.6409365]],
    [ "Central Networks",[-1.7109598, 52.6400942]],
    [ "Central Networks",[-1.7069881, 52.6377325]],
    [ "Central Networks",[-1.7117447, 52.6387596]],
    [ "917415075",[-2.0941397, 52.7957264]],
    [ "Queensville Retail Park 2",[-2.1003598, 52.7956218]],
    [ "Richmond Park",[-2.249621, 52.7867415]],
    [ "Doley Close",[-2.2567819, 52.786652]],
    [ "Eccleshall Primary",[-2.2531455, 52.8570323]],
    [ "Criddles Burgess",[-2.2220488, 52.8898368]],
    [ "Eton Grove",[-1.1994388, 52.9546523]],
    [ "1719246743",[-1.9030641, 53.0060484]],
    [ "Stoborough Pumping Stn",[-2.1029212, 50.669233]],
    [ "Yew Tree Farm",[-0.3801637, 51.2528215]],
    [ "Cherry Hill Grove",[-2.0294548, 50.7359024]],
    [ "2275396323",[1.6221121, 51.4374246]],
    [ "2275547056",[1.2383998, 51.7298011]],
    [ "Cecil Place, Lytchett Matravers, BH16 6FG",[-2.0787509, 50.7541464]],
    [ "Commodore",[-1.1905638, 52.9781493]],
    [ "2315240631",[-2.566741, 51.540306]],
    [ "Substation for Barrow Windfarm",[-3.2701138, 53.9874608]],
    [ "2363140323",[-3.5023713, 54.0446444]],
    [ "2363140352",[-3.4111891, 54.0785193]],
    [ "2363140353",[-3.5759736, 54.0794735]],
    [ "LILLESHALL AVENUE MONKSTON",[-0.7049868, 52.0369191]],
    [ "TEWKESBURY LANE, MONKSTON",[-0.706985, 52.0337684]],
    [ "Unit 5 Castle Meadow Retail Park",[-1.1610004, 52.9469567]],
    [ "2626386018",[-1.8666486, 52.7396144]],
    [ "2718252197",[-1.7202433, 54.9622599]],
    [ "Wollaton Hall Nurseries",[-1.2100784, 52.9544871]],
    [ "Bonners Lane Awsworth",[-1.2838017, 52.9894642]],
    [ "Welton Road Warwick",[-1.5966554, 52.2923376]],
    [ "Myton Road",[-1.5555837, 52.2824457]],
    [ "Haddenham Village",[0.1493018, 52.3575595]],
    [ "AWA Pumping Station Middleton",[-0.7194215, 52.0441846]],
    [ "Noon Layer Drive West",[-0.7151058, 52.043919]],
    [ "Market Place (B142)",[-1.2056837, 52.7724556]],
    [ "Stanners West",[-1.7281206, 54.9780993]],
    [ "Huntingdon Road Substation",[0.1103814, 52.214312]],
    [ "Recreation Centre",[0.0854283, 52.2085599]],
    [ "Institute for Manufacturing",[0.0869282, 52.2095947]],
    [ "Central Networks",[-1.7094224, 52.6382416]],
    [ "Boots Sidney Street",[0.1204321, 52.2057111]],
    [ "Banham Moor Farm Abattoir",[1.0091658, 52.4585286]],
    [ "Fen Causeway Engineering Laboratories",[0.1201218, 52.1975234]],
    [ "Dean Road Gorton",[-2.1646555, 53.4572519]],
    [ "Belmont Road Electricity Substation",[-2.2301572, 53.394437]],
    [ "Entwistle Electricity Substation",[-2.413203, 53.6546616]],
    [ "Hauxton Village",[0.0963957, 52.1489206]],
    [ "Rectory Farm",[0.0307673, 52.152041]],
    [ "Royston Road P.S.",[0.0554576, 52.11883]],
    [ "Station Road Hotel",[0.1373989, 52.1950973]],
    [ "Tenison Road Flats Substation",[0.1355451, 52.1956264]],
    [ "Whitehouse",[0.094854, 52.2223828]],
    [ "Chemical Engineering and Biotechnology",[0.0866052, 52.2089342]],
    [ "High Street, Fenstanton",[-0.0668599, 52.2968934]],
    [ "Park Farm Hardwick",[0.0218035, 52.2164954]],
    [ "Toft Road Hardwick",[0.0136102, 52.2172593]],
    [ "Hunt's Corner Awsworth",[-1.2823716, 52.9937525]],
    [ "The Redwoods off Haywood Road",[-1.6084053, 52.2902523]],
    [ "5273698327",[1.120607, 53.144141]],
    [ "5273698328",[1.175819, 53.126704]],
    [ "Garden House Hotel",[0.116291, 52.2009388]],
    [ "Madingley Village",[0.0425272, 52.2248235]],
    [ "New Hall College Substation",[0.109338, 52.2144865]],
    [ "Boys Home Substation",[-0.0166052, 52.0444269]],
    [ "Grantchester Road Trumpington",[0.1095716, 52.1739122]],
    [ "Russell St Travelodge",[-2.8007444, 54.0471425]],
    [ "Herschel Road",[0.1048234, 52.2041491]],
    [ "Wolfson College",[0.1006747, 52.1980264]],
    [ "Pythagoras School",[0.1144338, 52.2096135]],
    [ "University Library",[0.1083693, 52.2040669]],
    [ "Robinson College Grange Road",[0.1055134, 52.2047265]],
    [ "Botany Bay Local",[-2.6156876, 53.6646701]],
    [ "Lincs Wind Farm Substation",[0.4906076, 53.188448]],
    [ "Humber Gateway Offshore Substation",[0.2528142, 53.6585258]],
    [ "Westermost Rough Offshore Substation",[0.1330602, 53.8044547]],
    [ "Substation JC - 07 - JCC",[-1.1854659, 52.9495683]],
    [ "Offchurch Road",[-1.4990241, 52.3083256]],
    [ "University Main Intake",[-1.046757, 53.9484988]],
    [ "6443942370",[-0.0773764, 51.5397801]],
    [ "East Youlstone 33kv WFarm",[-4.4469899, 50.9162441]],
    [ "CEDAR DR (WHELLEY)",[-2.6206614, 53.5563453]],
    [ "Mesopotamia Island Substation",[0.4760865, 51.7335317]],
    [ "Northern Powergrid",[-1.5295796, 55.0262666]],
    [ "Swanscombe",[0.3028807, 51.4525066]],
    [ "APCM Swanscombe",[0.3029006, 51.4524761]],
    [ "Boxworth End",[-0.0048508, 52.285891]],
    [ "Jonathan Road Substation",[-2.1770497, 52.9609401]],
    [ "Fairways",[0.0302111, 52.2499769]],
    [ "Foxtrot",[0.1194924, 52.2329759]],
    [ "Agricultural Works",[0.0037712, 52.2183229]],
    [ "Noon Layer Drive",[-0.7087723, 52.0456905]],
    [ "Spa Garage",[-1.5421004, 52.2837611]],
    [ "St Andrews Road Electricity Substation",[-2.1892184, 53.4232623]],
    [ "Norris Bank Electricity Substation",[-2.1845005, 53.4120186]],
    [ "Golf Club",[-1.5179018, 52.2590993]],
    [ "Hill Farm Switching Station",[0.0065312, 52.2667474]],
    [ "Box Close",[-1.5189961, 52.2662057]],
    [ "Home Farm Crescent",[-1.5207531, 52.2690948]],
    [ "Tithebarn Road electricity substation",[-2.1985173, 53.4208551]],
    [ "St Helens Road",[-1.5308275, 52.2764769]],
    [ "Greenhill Road",[-1.5206421, 52.2712194]],
    [ "Building Society",[-1.5319658, 52.2920399]],
    [ "Anderson Drive",[-1.5265426, 52.2596552]],
    [ "Coppice Road",[-1.5271768, 52.262135]],
    [ "Uttons Drove Sewage Pumping Station",[0.0023221, 52.2689132]],
    [ "Beauchamp Avenue",[-1.5387033, 52.2950712]],
    [ "Guys Cliffe Avenue",[-1.5519199, 52.2953646]],
    [ "Trinity Technology College",[-1.5524716, 52.2957829]],
    [ "Troutbeck Avenue",[-1.5547854, 52.2969619]],
    [ "John St",[-1.5373048, 52.2907646]],
    [ "Warwick Terrace",[-1.5418482, 52.2921343]],
    [ "Multi Car Park",[-1.5371049, 52.2923886]],
    [ "Winnards Perch 33kv Solar",[-4.9264656, 50.4638876]],
    [ "Long Hall Embedded",[-1.5395308, 52.2850348]],
    [ "Fire Station",[-1.5402347, 52.290967]],
    [ "Oxford Place",[-1.5325819, 52.2932557]],
    [ "Villiers Street",[-1.5271465, 52.2953495]],
    [ "Campion Terrace",[-1.5255949, 52.2941149]],
    [ "Newbold Centre Leicester Street",[-1.5275345, 52.2940741]],
    [ "Ranelagh Terrace",[-1.532318, 52.281888]],
    [ "Gas Street",[-1.5339642, 52.2826063]],
    [ "Woodcote Road",[-1.537573, 52.3006858]],
    [ "Acacia Road",[-1.5531344, 52.2939542]],
    [ "Central Clinic",[-1.5284275, 52.2907459]],
    [ "William Street",[-1.5300785, 52.2904615]],
    [ "Rosefield Street",[-1.5323835, 52.2889282]],
    [ "Rosefields Street No. 2",[-1.5333178, 52.2886117]],
    [ "Moorhill Road",[-1.5309434, 52.2645249]],
    [ "Whitnash",[-1.532967, 52.2677625]],
    [ "Court Street",[-1.5306269, 52.2827544]],
    [ "Warehouse Spa Park",[-1.5392039, 52.2738422]],
    [ "Fountain",[-2.2036082, 52.9634019]],
    [ "Wedgwood Drive",[-2.1751648, 52.9513272]],
    [ "Brookhouse Drive Substation",[-2.1697047, 52.9441007]],
    [ "Plume of Feathers Barlaston Substation",[-2.1699486, 52.9420293]],
    [ "Akron Standard Substation",[-2.168773, 52.9658131]],
    [ "Mosspak Substation",[-2.1634665, 52.9675106]],
    [ "Selby Walk Substation",[-2.159152, 52.96864]],
    [ "Yeldham Place Substation",[-2.1561089, 52.9645144]],
    [ "Kier Close",[-1.5279109, 52.2983539]],
    [ "Heywood Grange Farm",[-2.0549349, 53.0016541]],
    [ "Staunch Standby",[-2.2452706, 53.0328946]],
    [ "New Market Hall",[-2.6319018, 53.548757]],
    [ "Aldi Store Queens way",[-1.5443644, 52.2806413]],
    [ "Mount Todden Substation",[-6.2806356, 49.9231446]],
    [ "Four Ashes EFW",[-2.1152061, 52.6726431]],
    [ "Cloister Way Leamington",[-1.5350905, 52.3053267]],
    [ "Abbey Tresco Substation",[-6.3287439, 49.9479853]],
    [ "Rowes Field Tresco Substation",[-6.3253086, 49.9503048]],
    [ "Cadgwith Substation",[-5.1800403, 49.987796]],
    [ "Lizard Point Substation",[-5.2044933, 49.9604756]],
    [ "Fishpond Drive The Park",[-1.1621959, 52.9477864]],
    [ "Burger King Castle Boulevard",[-1.1661394, 52.9471102]],
    [ "Mullion Laflounder Sewage",[-5.2505435, 50.026319]],
    [ "Predannack Wartha",[-5.2559763, 50.0066134]],
    [ "Mullion Cove Substation",[-5.2548122, 50.0164616]],
    [ "Heathcote Hospital",[-1.5403347, 52.2690334]],
    [ "Pencoose 33kv Solar Park",[-5.183732, 50.19813]],
    [ "Roskrow Barton 33kv Solar",[-5.131236, 50.176811]],
    [ "Little Trevease Fm Solar",[-5.186197, 50.152299]],
    [ "Nancrossa 33kv Solar Park",[-5.181525, 50.157746]],
    [ "Hope 33kV Solar Park",[-5.368417, 50.209977]],
    [ "Churchtown Farm 33kv Solar",[-5.365195, 50.216096]],
    [ "Ninnis 33kV Solar Park",[-4.8395265, 50.3249634]],
    [ "Burthy 33kv Solar Park",[-4.9462241, 50.3644596]],
    [ "St Stephen 33kV Solar Park",[-4.9044192, 50.3580951]],
    [ "Garvinack Farm 33kv Solar",[-5.1282458, 50.2934534]],
    [ "Causilgey 33kV Solar Park",[-5.1142471, 50.285467]],
    [ "Tregassow 33kV Solar Farm",[-5.0153567, 50.3044927]],
    [ "Nanteague 33kv Solar Park",[-5.097726, 50.3057956]],
    [ "Fourburrows Pendown",[-5.1382729, 50.3026913]],
    [ "Penare Farm 33kV Solar Fm",[-5.0766418, 50.302377]],
    [ "Gover Park 33kV Solar Park",[-5.2000576, 50.2906348]],
    [ "Willsland 33kV Solar Park",[-4.1193956, 50.8160105]],
    [ "Forestmoor Windfarm",[-4.4190131, 50.8940772]],
    [ "Pitworthy 33kV Solar Park",[-4.4325489, 50.8181119]],
    [ "Walland Farm 33kv Solar Pk",[-4.3474276, 50.974021]],
    [ "East Langford 33kv Solar",[-4.4401637, 50.8677387]],
    [ "Beaford 33kV Solar Farm",[-4.0384586, 50.9214373]],
    [ "Bradford Manor 33kV Solar",[-4.4395964, 50.781119]],
    [ "Foxcombe 33kV Solar Park",[-4.2978892, 50.802175]],
    [ "Cleave Farm 33kV Solar Pk",[-4.1424572, 51.003541]],
    [ "Rexon Cross Farm Solar Pk",[-4.2324521, 50.6859202]],
    [ "Knockworthy Farm Solar Pk",[-4.1208038, 50.9857629]],
    [ "Ashwater Solar Park",[-4.3016651, 50.7556605]],
    [ "Crinacott Farm Solar",[-4.4034342, 50.7895944]],
    [ "Week Farm 33kV Solar Park",[-4.1176103, 50.9532336]],
    [ "Derriton Fields Solar",[-4.3697718, 50.7996365]],
    [ "Eastacombe Fm Solar",[-4.3337497, 50.7732577]],
    [ "Trenouth Farm 33kv Solar",[-4.9489465, 50.4945698]],
    [ "North Wayton 33kV Solar Pk",[-4.2370487, 50.4463105]],
    [ "Trekenning Farm 33kV Solar",[-4.9249946, 50.4237857]],
    [ "Trewidland Farm 33kV Solar",[-4.4482921, 50.4085107]],
    [ "Langage Energy Centre",[-4.01132, 50.3882594]],
    [ "Yonder Park 33kV Solar Pk",[-3.6455472, 50.3583287]],
    [ "Alders Way 33kv Stor",[-3.5894722, 50.4229969]],
    [ "Mary Tavy Gen Stn",[-4.1074343, 50.5867823]],
    [ "Bidwell 33kv Solar Park",[-3.7292589, 50.4407176]],
    [ "Place Barton Farm 33kv",[-3.7471546, 50.3566894]],
    [ "Langunnett Farm Solar Pk",[-4.5954233, 50.3916394]],
    [ "West Carclaze 33kv Solar P",[-4.7863738, 50.3605267]],
    [ "Portworthy Dams 33kv Solar",[-4.0252903, 50.4301698]],
    [ "Tulip",[-4.6987943, 50.4703556]],
    [ "Woodland Barton Solar Pk",[-4.8072253, 50.4229854]],
    [ "Ford Farm 33kV Solar Park",[-4.3797094, 50.4720145]],
    [ "Wilton Farm 33kv Solar Pk",[-4.3746614, 50.4027916]],
    [ "Balls Wood 33kv Solar Park",[-4.0282743, 50.3607141]],
    [ "Heathfield Landfill Substation",[-3.6111103, 50.5725783]],
    [ "Bodiniel 33kV Solar Park",[-4.7353797, 50.4847926]],
    [ "Howton Farm 33kv Solar Pk",[-4.2933731, 50.4465398]],
    [ "Newton Barton 33kv Solar P",[-4.3141481, 50.4760162]],
    [ "Slade Farm 33kV Solar Park",[-3.7667079, 50.296454]],
    [ "Coombeshead Farm 33kv Solar",[-3.7622501, 50.3886408]],
    [ "Kerriers 33kV Solar Park",[-4.8155724, 50.4405681]],
    [ "Trerule 33kv Solar Park",[-4.3582021, 50.4007759]],
    [ "Marley Thatch Farm Solar",[-3.7946733, 50.4271604]],
    [ "Rydon Farm Solar Park",[-3.6323413, 50.5071571]],
    [ "Middle Treworder Farm Solar",[-4.8025261, 50.5239507]],
    [ "Outlands Wood Solar",[-4.7327806, 50.4841803]],
    [ "Wilton Farm Wind Farm Substation",[-4.3788993, 50.4035771]],
    [ "Bommertown Solar Park",[-3.6806358, 51.0172261]],
    [ "Old Stone Farm Solar",[-3.6611878, 50.3518792]],
    [ "Connon Bridge Landfill",[-4.5666459, 50.4331922]],
    [ "Denzell Downs Wind Farm Substation",[-4.9640876, 50.4667465]],
    [ "Trefinnick Farm Solar Farm",[-4.3479936, 50.5425298]],
    [ "Parkview 33kv Solar Park",[-3.7457907, 50.5048661]],
    [ "Bears Down Wind Farm",[-4.9498786, 50.4708993]],
    [ "Victoria 33kV Wind Farm",[-4.8183109, 50.4235322]],
    [ "Higher Trevartha Solar Fm",[-4.4255336, 50.4527386]],
    [ "Hazard Farm 33kV Solar Pk",[-3.7621777, 50.4211859]],
    [ "Huntworth Generator",[-2.9891603, 51.1126301]],
    [ "Wyld Meadow 33kv Solar Pk",[-2.9301209, 50.7820818]],
    [ "Hurcott 33kv Solar Park",[-2.8691, 50.9387942]],
    [ "Bystock Farm 33kv Solar Pk",[-3.3830535, 50.6501816]],
    [ "Pitts Fm. Solar Park",[-2.5854792, 51.2092334]],
    [ "Redlands Fm 33kV Solar Pk",[-2.8168331, 51.1150038]],
    [ "Rookery Farm Master Substation",[-2.5764572, 51.4069885]],
    [ "Beechgrove Farm Solar Park Substation",[-2.9251876, 50.7887046]],
    [ "Higher Berechapel Farm ADP",[-2.8588325, 50.8451651]],
    [ "Crewkerne 33kv Solar Park",[-2.7731024, 50.8986813]],
    [ "Frys Hill Radio Station",[-2.8107794, 51.2976061]],
    [ "Rookery Farm 33kV Solar Pk",[-2.8556165, 51.3513606]],
    [ "Withy Drove 33kv Solar Pk",[-2.9660223, 51.1858572]],
    [ "Whitchurch Solar Farm Substation",[-2.5234678, 51.2682219]],
    [ "Wick Farm West 33kv Solar",[-2.9820585, 51.2828824]],
    [ "Woolavington 33kV Solar Pk",[-2.9267111, 51.1809101]],
    [ "Cricket St Thomas Solar Pk",[-2.9090126, 50.8738357]],
    [ "Red Hill 33kv Solar Park",[-3.2989394, 50.9550335]],
    [ "Wick Farm 33kv Solar Park",[-2.9821853, 51.2829289]],
    [ "Newlands Farm Solar Park",[-2.9499357, 50.7760564]],
    [ "Haven Road",[-3.5310386, 50.7168124]],
    [ "Iwood Lane 33kV Solar Park",[-2.7925725, 51.3580427]],
    [ "Watchfield Lawn 33kV Solar",[-2.9437619, 51.2256949]],
    [ "Puriton Landfill 33kV Solar",[-2.9814644, 51.1709611]],
    [ "Stonebarrow Fm 33kV Solar",[-2.9202651, 50.7854875]],
    [ "Hengrove Park Master",[-2.5833233, 51.4110976]],
    [ "Luscott Barton 33kv Solar",[-4.1165122, 51.1112404]],
    [ "Carditch Drove 33kV Solar",[-2.8234085, 51.3486476]],
    [ "Higher Bye Farm 33kV Solar",[-3.3598678, 51.1711747]],
    [ "Parsonage Barn 33kV Solar",[-2.8875683, 50.9424423]],
    [ "Bratton Fleming Solar Park",[-3.9192116, 51.1279507]],
    [ "Great Houndbeare Solar Park",[-3.3501099, 50.7310337]],
    [ "New Row Farm Solar Park",[-2.5377506, 51.1981922]],
    [ "Hawkers Fm 33kv Solar Park",[-2.761992, 51.2249667]],
    [ "Jacobs Well Master Substation",[-2.6079728, 51.4516878]],
    [ "Upper Huntingford Solar Farm Substation",[-2.4049357, 51.641966]],
    [ "EFW Javelin Park Substation",[-2.2926731, 51.792224]],
    [ "Upper Wick Solar Farm Substation",[-2.4030085, 51.6663436]],
    [ "STADCO",[-2.1690025, 51.8445351]],
    [ "Yorkley Solar Farm Substation",[-2.5330745, 51.7419563]],
    [ "Hall Farm PV Substation",[-2.4454789, 51.7724483]],
    [ "Actree Solar Farm Substation",[-2.4185911, 51.6804707]],
    [ "Eakley Lanes North PV",[-0.8108425, 52.1528554]],
    [ "Lyon Road Generation",[-0.7383303, 52.0046365]],
    [ "Mount Mill Solar Park",[-0.8984347, 52.0404631]],
    [ "Wolverhampton Power",[-2.1083189, 52.582321]],
    [ "Weldon Shanks Generation",[-0.6413189, 52.4874267]],
    [ "Burton Wold Wind Farm",[-0.6569403, 52.3627223]],
    [ "Burton Wold Wind Farm South",[-0.657897, 52.3534152]],
    [ "Airfield Farm Wind Farm",[-0.6128626, 52.2317746]],
    [ "Wykes Generation",[-0.5920158, 52.2630354]],
    [ "Coventry Waste Reduction",[-1.4907309, 52.3958595]],
    [ "Glebe Farm Solar Park",[-0.5879536, 52.2532343]],
    [ "A B R Foods",[-0.6529476, 52.5027766]],
    [ "Lime Kilns STOR Generation Substation",[-1.3921081, 52.5291935]],
    [ "Clay Lake Generation",[-0.1422161, 52.7745108]],
    [ "Equitix ESI CHP Notts",[-1.0323085, 52.8437667]],
    [ "Pound Lane",[-1.524384, 52.299639]],
    [ "Baker Avenue",[-1.5376379, 52.2790366]],
    [ "Tachbrook Court",[-1.5355303, 52.2807925]],
    [ "Leam Terrace Flats",[-1.524327, 52.2877029]],
    [ "Redland Road",[-1.5204362, 52.2742937]],
    [ "Rampion Wind Farm Offshore Substation",[-0.259831, 50.6928285]],
    [ "Verdun Close",[-1.5239543, 52.2630876]],
    [ "Whitnash C of E School",[-1.5238633, 52.2653]],
    [ "Willoughby Fields Whitnash",[-1.5350061, 52.2629107]],
    [ "South Farm",[-1.5334316, 52.2721477]],
    [ "Franklin Road",[-1.5293282, 52.2665547]],
    [ "Markham Drive",[-1.5220803, 52.2657222]],
    [ "Dobson Lane",[-1.5305038, 52.270517]],
    [ "Ashford Gardens",[-1.5333591, 52.2639848]],
    [ "Shire Foods Tachbrook Road",[-1.5345373, 52.2710453]],
    [ "Rowley Road",[-1.5270947, 52.2643289]],
    [ "Lammas Croft",[-1.5284378, 52.2683708]],
    [ "Aurora Harrison Way",[-1.5356209, 52.274374]],
    [ "Juno Drive",[-1.5368554, 52.2742852]],
    [ "Spa Park",[-1.5393608, 52.2750994]],
    [ "Wolseley UK",[-1.5355643, 52.2739408]],
    [ "King Street",[-1.5288784, 52.2928583]],
    [ "Clarendon Square",[-1.5397276, 52.2935045]],
    [ "Willes Road",[-1.5282339, 52.2908008]],
    [ "Eastfield Road Flats",[-1.5268106, 52.2907967]],
    [ "Tachbrook Road",[-1.5351814, 52.2770313]],
    [ "Spencer Parade Stanwick",[-0.5638189, 52.3322114]],
    [ "Manningham Road Stanwick",[-0.5595773, 52.3367982]],
    [ "Raunds Andrews Way",[-0.5521511, 52.3380986]],
    [ "Keston Way Raunds",[-0.5499222, 52.339508]],
    [ "Darsdale Drive South",[-0.5440345, 52.3338594]],
    [ "Church Street Barford 11kV",[-1.5977637, 52.2481989]],
    [ "9262033501",[0.1048092, 52.2455567]],
    [ "Huntley Lodge",[-1.5436266, 52.2992231]],
    [ "New College",[-1.537405, 52.3035949]],
    [ "Building Society Milverton Hill",[-1.5442977, 52.2897281]],
    [ "Oaks Hotel Flats",[-1.5471252, 52.2904928]],
    [ "Hyde Place",[-1.5448028, 52.2911939]],
    [ "Bedford Street",[-1.5362408, 52.2888757]],
    [ "Tesco",[-1.536924, 52.2927248]],
    [ "Fairlawn Close",[-1.5512524, 52.2917367]],
    [ "Bereham Court",[-1.5304855, 52.3005494]],
    [ "Lovell Homes Heathcote",[-1.5367294, 52.2663156]],
    [ "9460854755",[-1.1789607, 50.6298762]],
    [ "9482200502",[-1.6507907, 52.8044888]],
    [ "9482206531",[-1.6531927, 52.800913]],
    [ "9482327030",[-1.6261081, 52.8108777]],
    [ "Scalford South",[-0.8715039, 52.808949]],
    [ "Village Hall Scalford",[-0.8697679, 52.8117448]],
    [ "Thurvaston",[-1.6399308, 52.937615]],
    [ "Cross Street",[-1.5302766, 52.2916152]],
    [ "Hazel Close",[-1.5285909, 52.2964119]],
    [ "Student Union",[-1.054898, 53.946011]],
    [ "Flavels No. 1",[-1.5293845, 52.2811338]],
    [ "Cape of Good Hope",[-1.5953626, 52.292101]],
    [ "New Milverton",[-1.5541264, 52.2906898]],
    [ "Rugby Road",[-1.545545, 52.2944067]],
    [ "Guy Street",[-1.5349127, 52.2929065]],
    [ "Boots",[-1.5348679, 52.2925035]],
    [ "Tachbrook Road South",[-1.5352581, 52.2751738]],
    [ "Bridge Street Warwick",[-1.5605471, 52.2883242]],
    [ "Hill Street",[-1.563957, 52.2893955]],
    [ "Portobello",[-1.560316, 52.2904997]],
    [ "Milverton Crescent West",[-1.543471, 52.2935648]],
    [ "Chesford Crescent",[-1.5670759, 52.2911855]],
    [ "Blackdown",[-1.5372003, 52.3149359]],
    [ "Balmoral Way",[-1.5150868, 52.3144347]],
    [ "Lillington Sandpits",[-1.5201368, 52.30469]],
    [ "New Cubbington",[-1.5128646, 52.309153]],
    [ "Myton Crescent",[-1.5618356, 52.2793006]],
    [ "Myton High School",[-1.5659699, 52.2805735]],
    [ "Cowdray Close",[-1.5135097, 52.2836777]],
    [ "STW Station Approach",[-1.5366439, 52.2852129]],
    [ "North Leamington School",[-1.529281, 52.310332]],
    [ "Rackhams",[-1.5365343, 52.2906133]],
    [ "Hopton Crofts",[-1.5552237, 52.2996793]],
    [ "Mason Avenue",[-1.5110875, 52.3006205]],
    [ "Delamere Way",[-1.5116237, 52.3054922]],
    [ "New Inn",[-1.6689712, 52.266132]],
    [ "Windermere Drive",[-1.5500629, 52.2983285]],
    [ "Fairways Leamington Spa",[-1.5468492, 52.2971058]],
    [ "Aragon Drive",[-1.5511191, 52.2817666]],
    [ "Park Road Leamington",[-1.5307956, 52.3053766]],
    [ "Lime Avenue",[-1.5256034, 52.3073485]],
    [ "Leicester Lane",[-1.5273744, 52.3099161]],
    [ "Electricity North West",[-2.2196033, 53.4786111]],
    [ "ENWL",[-2.2181734, 53.4789605]],
    [ "Rugby Road Cubbington",[-1.503588, 52.3133158]],
    [ "Old Cubbington",[-1.4997471, 52.3140753]],
    [ "Coventry Road",[-1.4988154, 52.3158884]],
    [ "Gresham Place",[-1.5230503, 52.2970866]],
    [ "Patten's Road",[-1.5673535, 52.2936029]],
    [ "Milverton Fields",[-1.5571236, 52.2929938]],
    [ "Watsons Bakery Substation",[-2.1650279, 52.9676912]],
    [ "Bell Lane Substation",[-2.1571459, 52.9549605]],
    [ "Copeland Avenue Substation",[-2.1905409, 52.9425013]],
    [ "Ash Green Substation",[-2.1706714, 52.9402534]],
    [ "Trentham Tulip Substation",[-2.1956074, 52.9637802]],
    [ "Trentham Retail Substation",[-2.1970832, 52.9630935]],
    [ "Park Drive Substation",[-2.2025524, 52.9658977]],
    [ "Parkway Substation",[-2.2039833, 52.9687151]],
    [ "S.T.W.A. Pump Swynnerton Substation",[-2.2226281, 52.9218508]],
    [ "Myton Grange",[-1.5596237, 52.2827768]],
    [ "Meadow Road",[-1.5737821, 52.2843803]],
    [ "Hillcrest",[-1.5002361, 52.3100135]],
    [ "National Grid Electricity Distribution",[-1.5453175, 52.2777685]],
    [ "Thorn EMI",[-1.5438431, 52.2765714]],
    [ "Adelaide Road",[-1.5404184, 52.2887082]],
    [ "Victoria Road",[-1.5426705, 52.2868276]],
    [ "Trident Park Poseidon Way",[-1.535881, 52.2701588]],
    [ "Poseidon Embedded",[-1.5363423, 52.2699662]],
    [ "Warwick Gates Health Centre",[-1.5399214, 52.2677429]],
    [ "Hadrians Close",[-1.5228479, 52.3054653]],
    [ "The Shires Trade Centre & Care Home",[-1.5440365, 52.2794054]],
    [ "Crown Way",[-1.5186284, 52.3023435]],
    [ "Valley Road",[-1.5157735, 52.3048514]],
    [ "Avondale Road",[-1.5177031, 52.3080702]],
    [ "Burns Road",[-1.519139, 52.306632]],
    [ "Station Approach",[-1.5372262, 52.2855329]],
    [ "Kingsway",[-1.5409832, 52.2796484]],
    [ "Styles Close",[-1.5267675, 52.2822503]],
    [ "Waterloo Street",[-1.5197636, 52.2842266]],
    [ "Brunswick St Flats",[-1.5302747, 52.2795123]],
    [ "Packington Place",[-1.5305231, 52.2845788]],
    [ "Montague Road",[-1.5730468, 52.2904427]],
    [ "Charles Street",[-1.5688417, 52.2885536]],
    [ "Warwick School",[-1.5710146, 52.2800123]],
    [ "Co-op",[-1.5366284, 52.2916911]],
    [ "Royal Priors Satchwell Court",[-1.5350502, 52.2908444]],
    [ "Bath Place",[-1.5341941, 52.2851151]],
    [ "Newbold Comyn Park",[-1.5171802, 52.2899511]],
    [ "Springwell Road",[-1.5097654, 52.2818604]],
    [ "National Grid Electricity Distribution",[-1.5176307, 52.2825883]],
    [ "Lowes Avenue",[-1.5908082, 52.2951221]],
    [ "Langcliffe Avenue No. 1",[-1.581708, 52.2927915]],
    [ "Langcliffe Avenue No. 2",[-1.5832287, 52.292449]],
    [ "Spartan Close",[-1.5408929, 52.2704774]],
    [ "Mill House Drive",[-1.5577924, 52.2889806]],
    [ "Ryland Close",[-1.5176299, 52.2791135]],
    [ "Leason",[-1.5413916, 52.2766198]],
    [ "Plato Close",[-1.5479663, 52.2743797]],
    [ "Athena Drive",[-1.5469176, 52.2756099]],
    [ "Olympus Court",[-1.5515709, 52.275492]],
    [ "Tata Technologies",[-1.5484737, 52.2768695]],
    [ "Apollo Way",[-1.5484545, 52.2769096]],
    [ "Millward Brown",[-1.5489192, 52.2765605]],
    [ "BT Leamington",[-1.5373256, 52.2908245]],
    [ "Russell Street",[-1.5385151, 52.2925159]],
    [ "Tachbrook Park Drive South",[-1.5444724, 52.2728706]],
    [ "National Grid Electricity Distribution",[-1.5439872, 52.274278]],
    [ "National Grid Electricity Distribution",[-1.5439857, 52.2743148]],
    [ "National Grid Electricity Distribution",[-1.5410545, 52.2721097]],
    [ "National Grid Electricity Distribution",[-1.5402735, 52.2718278]],
    [ "National Grid Electricity Distribution",[-1.540841, 52.2720305]],
    [ "Warwick Gates No. 3",[-1.5445195, 52.2690625]],
    [ "National Grid Electricity Distribution",[-1.6103151, 52.2596679]],
    [ "National Grid Electricity Distribution",[-1.6117863, 52.2579654]],
    [ "National Grid Electricity Distribution",[-1.6096486, 52.2610716]],
    [ "National Grid Electricity Distribution",[-1.607409, 52.2637532]],
    [ "National Grid Electricity Distribution",[-1.6067559, 52.2651272]],
    [ "National Grid Electricity Distribution",[-1.608679, 52.2655121]],
    [ "National Grid Electricity Distribution",[-1.6099463, 52.2636974]],
    [ "Heathcote Lane",[-1.5489283, 52.2695596]],
    [ "Warwick Gates Business Park",[-1.5496174, 52.2687592]],
    [ "Earl Rivers Avenue",[-1.5469508, 52.2666259]],
    [ "Charles Gardner Road",[-1.5319562, 52.2789131]],
    [ "Lewelyn",[-1.5260478, 52.278054]],
    [ "National Grid Electricity Distribution",[-1.5898022, 52.291085]],
    [ "Millers Road",[-1.5923802, 52.2903603]],
    [ "Aliminium Services",[-1.5924301, 52.2903778]],
    [ "Lock Lane",[-1.5937341, 52.2915158]],
    [ "Woodloes Avenue No. 1",[-1.5799109, 52.2934957]],
    [ "Stocksbridge Retail Park Alpha",[-1.588254, 53.48307]],
    [ "Stocksbridge Retail Park Beta",[-1.5882178, 53.4830651]],
    [ "Stocksbridge Retail Park Charlie",[-1.5881854, 53.483062]],
    [ "Aldi Tachbrook Park Drive",[-1.5453252, 52.2801777]],
    [ "Regents Court Number 1",[-1.5347037, 52.289396]],
    [ "Regent Grove",[-1.5341406, 52.2894931]],
    [ "Handley Grove",[-1.5931528, 52.2937082]],
    [ "Regent Parade",[-1.534863, 52.290023]],
    [ "Saw Mill Close",[-1.5779088, 52.2853108]],
    [ "Wharf Street",[-1.576283, 52.2859626]],
    [ "10548639844",[-1.2916808, 52.3587823]],
    [ "10548663461",[-1.2889221, 52.3500256]],
    [ "Substation MC - 21 - Architecture",[-1.1954322, 52.9443291]],
    [ "University Campus Wollaton Road",[-1.188966, 52.9547314]],
    [ "Substation JC - 01 - Business School North",[-1.1889593, 52.9546846]],
    [ "Substation JC - 02 - Exchange",[-1.1878891, 52.9543921]],
    [ "Substation JC - 03 - Computer Science",[-1.1868109, 52.9535341]],
    [ "Substation JC - 06 - AMB",[-1.1840307, 52.9479119]],
    [ "Axiom Sword",[-1.5396874, 52.2743884]],
    [ "Tesco Bulwell",[-1.1943857, 53.0036693]],
    [ "Orwell Village",[-0.0120284, 52.1342758]],
    [ "Newnham",[-0.1876514, 52.0206879]],
    [ "Warren Court",[-1.5314223, 52.3013533]],
    [ "National Grid Electricity Distribution",[-1.5015932, 52.3165801]],
    [ "York House",[-1.5365551, 52.2932188]],
    [ "Cobden Avenue",[-1.5110581, 52.2755353]],
    [ "National Grid Electricity Distribution",[-1.5131314, 52.2735455]],
    [ "National Grid Electricity Distribution",[-1.5128815, 52.2774268]],
    [ "Pen-Llan Biomass Generator",[-2.9618534, 52.036731]],
    [ "St Levan Cable & Wireless",[-5.662958, 50.0561262]],
    [ "10920080217",[-5.6554149, 50.0469363]],
    [ "10920080220",[-5.6582661, 50.0492426]],
    [ "10920080261",[-5.6550998, 50.0423235]],
    [ "Trevorian Tyco SSI",[-5.6749856, 50.0804194]],
    [ "Sennen Village",[-5.6953329, 50.0691743]],
    [ "Lands End",[-5.7135625, 50.0660452]],
    [ "FLAG Telecom",[-5.6743218, 50.06385]],
    [ "Parc-An-Caddy Estate",[-5.6211544, 50.0727784]],
    [ "Gwavas Works",[-5.5493188, 50.100641]],
    [ "Tregona",[-5.5742835, 50.0738784]],
    [ "Lamorna Cove",[-5.5664943, 50.0635616]],
    [ "Tregurno Cliffs",[-5.5681814, 50.0635986]],
    [ "Lamorna",[-5.5712351, 50.0668673]],
    [ "Trethinnick",[-5.5754542, 50.0694713]],
    [ "St Marys Terrace",[-5.5374849, 50.1174368]],
    [ "Penlee Gardens",[-5.5436566, 50.1163383]],
    [ "Morrab Road",[-5.5393671, 50.1163378]],
    [ "10921445598",[-5.5833466, 50.1027589]],
    [ "Trennack Terrace",[-5.5359969, 50.1271085]],
    [ "Wharf Road",[-5.5336922, 50.1199065]],
    [ "Market Jew Street",[-5.535207, 50.1194267]],
    [ "Churchill Flats Penzance",[-5.5335997, 50.1196107]],
    [ "Penwith Council Offices",[-5.5463281, 50.1230545]],
    [ "Pendarves Flats",[-5.5425509, 50.1225485]],
    [ "West Cornwall Hospital",[-5.5433871, 50.1214259]],
    [ "Penzance Station",[-5.5329855, 50.1218314]],
    [ "Albert Pier SW Water",[-5.5311039, 50.1226658]],
    [ "Higher Nancealverne",[-5.5558714, 50.1240811]],
    [ "Ponsandane Industrial",[-5.5077687, 50.1302835]],
    [ "Penzance Airfield",[-5.5136897, 50.1295613]],
    [ "Eastern Green Motel",[-5.5214121, 50.127842]],
    [ "St Michaels Mount",[-5.4780693, 50.1184056]],
    [ "Marazion Bridge",[-5.4800796, 50.1290635]],
    [ "10923048637",[-5.5663569, 50.1275392]],
    [ "Treen Zennor",[-5.5941439, 50.1830696]],
    [ "Rosemergy",[-5.6174503, 50.1706763]],
    [ "10923614536",[-5.6125887, 50.1726049]],
    [ "Brush End",[-5.4424106, 50.1819579]],
    [ "Hornsea Sewage",[-0.1605058, 53.9100178]],
    [ "Boskennal Solar Farm",[-5.4930172, 50.1529395]],
    [ "Draycott Terrace",[-5.4778996, 50.2071216]],
    [ "Carnellis Road",[-5.4918417, 50.2101506]],
    [ "Stennack",[-5.4847875, 50.2101424]],
    [ "St Andrews Street",[-5.4797852, 50.2121398]],
    [ "Fishermans Lodge",[-5.4796068, 50.2146739]],
    [ "St Peters Street",[-5.4793406, 50.2155016]],
    [ "Porthgwidden Sewage",[-5.4764284, 50.2164383]],
    [ "Carbis Hotel",[-5.4659034, 50.19795]],
    [ "Tir Gwel Carbis Bay",[-5.4660255, 50.1963488]],
    [ "Carbis Valley",[-5.468064, 50.1962274]],
    [ "Carbis Water",[-5.474168, 50.1958569]],
    [ "Venns Estate",[-5.4740949, 50.1966983]],
    [ "Trelyon",[-5.4747655, 50.2005584]],
    [ "Venton Road",[-5.4719686, 50.2014924]],
    [ "St Ives Gs School",[-5.4794144, 50.2015237]],
    [ "Tregenna St Ives",[-5.4805724, 50.2052281]],
    [ "Langweath",[-5.4441346, 50.1770501]],
    [ "Old Roaches Garage",[-5.4587553, 50.191601]],
    [ "Castle Close",[-1.5906744, 52.2787653]],
    [ "Monks Way",[-1.5935317, 52.2789606]],
    [ "Avon Court Flats",[-1.579453, 52.2832715]],
    [ "Pottertons (Coventry Road)",[-1.5801676, 52.2863264]],
    [ "Warwick School Banbury Road",[-1.5762795, 52.276899]],
    [ "The Gallows 01 Embedded",[-1.5585095, 52.2708626]],
    [ "New Warwick Racecourse",[-1.596386, 52.2787238]],
    [ "Lynwood Drive",[-1.5154441, 52.2797266]],
    [ "Gainsborough Drive",[-1.5129479, 52.2823934]],
    [ "Sovereign House",[-1.5404146, 52.2781048]],
    [ "Flavels No. 2",[-1.5294651, 52.281135]],
    [ "Camprian Drive",[-1.5720987, 52.2867097]],
    [ "Wesley Crescent 7",[-0.0319827, 53.5468268]],
    [ "Pumping Station No. 2 Lambley",[-1.0683179, 52.9995263]],
    [ "Pinfold Lane Elston",[-0.8727494, 53.0257837]],
    [ "Eden Hall Elston",[-0.8866665, 53.0276094]],
    [ "Cell Site Lodge Lane Elsron Padmount",[-0.8759407, 53.0225136]],
    [ "RAF Syerston",[-0.9105885, 53.0172603]],
    [ "Coney Grey Syerston",[-0.9107897, 53.0130045]],
    [ "Fosse Road Flintham",[-0.9078533, 53.0109998]],
    [ "Inholms Gardens Flintham",[-0.9016722, 53.0111237]],
    [ "Flintham Village",[-0.8975603, 53.0079413]],
    [ "Back Lane Flintham",[-0.8942934, 53.0062386]],
    [ "Valley Lane Fearns",[-0.7711916, 53.0013855]],
    [ "Scottish and Southern Electricity Power Distribution",[-1.4389246, 50.9289502]],
    [ "Scottish and Southern Energy Power Distribution",[-1.4337306, 50.9231058]],
    [ "12044020837",[-2.2276924, 51.7068542]],
    [ "Poorhole Lane IDNO",[1.3966314, 51.3641405]],
    [ "Pilgrim",[-1.6089634, 54.9718544]],
    [ "Carn Gwaval School Substation",[-6.3054858, 49.9131706]],
    [ "Old Town Substation",[-6.2988948, 49.9130361]],
    [ "Blockhouse Tresco Substation",[-6.3289442, 49.9580007]],
    [ "New Inn-New Substation",[-6.338458, 49.9572756]],
    [ "Chelker Reservoir Substation",[-1.908692, 53.9578356]],
    [ "Seagull Owls Road 11kV S/S",[-1.8415297, 50.7229381]],
    [ "Brunts Lane E Bridgford",[-0.9665342, 52.9779561]],
    [ "Fairfield Drive substation",[-0.3273045, 51.2368347]],
    [ "UK Power Networks",[-0.3348445, 51.2346143]]
];