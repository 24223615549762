import { getViewer } from './main.js';
import * as Cesium from 'cesium';
import CustomCesiumMVTImageryProvider from "./customMVTImageryProvider.js";
import { ukPowerSourceList, ukPowerSourceType, TILE_BASE_URL} from './common.js';
import { countryMVTImageryConfigs, voltagePowerLinesByCountry} from './countryMVTImageryConfigs.js';
import { stylePowerLines, stylePowerSubstations, styleEntity, stylePowerLinesDynamic} from './imageryLayersStyles.js';
import {Austria_Substations} from './austria/austria_substations.js';
import { UK_Substations } from "./uk/uk_substations.js";
import { France_Substations } from "./france/france_substations.js";
import { Germany_Substations } from "./germany/germany_substations.js";
import { Texas_Substations } from "./texas/texas_substations.js";

const Substations = {
	Germany_Substations,
	Austria_Substations,
	UK_Substations,
	France_Substations,
	Texas_Substations,
};


let currentCountry;
let currentCountryCheckboxList = [];
const countryObjects = {
  USA: {rectangle: Cesium.Rectangle.fromDegrees(-107.6456, 25.3371, -92.5083, 38.5007),layers: {}},
  UK : {rectangle: Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0),layers: {}},
  France : {rectangle: Cesium.Rectangle.fromDegrees(-5.142, 40.4, 13.7, 51.2),layers: {}},
  Austria : {rectangle: Cesium.Rectangle.fromDegrees(9.5, 41.4, 17.1, 49.0),layers: {}},
  Germany : {rectangle: Cesium.Rectangle.fromDegrees(5.87, 47.27,15.04, 55.09), layers: {}},

  //remove below later
  Texas: {rectangle: Cesium.Rectangle.fromDegrees(-107.6456, 25.3371,-92.5083, 38.5007),layers: {}},
  England : {rectangle: Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0),layers: {}},
  Ireland : {rectangle: Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0),layers: {}},
  Scotland : {rectangle: Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0),layers: {}},
  Wales : {rectangle: Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0),layers: {}},
};

const viewer = getViewer();


const mapGrid = document.getElementById('mapGrid');
const chartGrid = document.getElementById('chartGrid');


let currentLayerUKPower;
  

//Define the bounds of the rectangle using the provided coordinates
//const rectangle1 = Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0);
//const rectangle = Cesium.Rectangle.fromDegrees(-11, 49, 2.9, 62.0);
//Create an entity with a rectangle geometry
//
// Zoom to the rectangle
//viewer.zoomTo(viewer.entities);

const imageryUKPowerProvider = new CustomCesiumMVTImageryProvider({
	urlTemplate: `${TILE_BASE_URL}/uk_power_plants_all/{z}/{x}/{y}.pbf`,
	//urlTemplate: "http://localhost:8080/data/uk_power_plants_all/{z}/{x}/{y}.pbf",
	maximumLevel: 18,
	layerName : "uk_power_plants_all",
	rectangle: countryObjects['UK'].rectangle,
    tilingScheme: new Cesium.WebMercatorTilingScheme(),
	style: feature => {
		let electricityOutput = feature.properties["Capacity (MW)"];
		let mult = 1.0;

		if (electricityOutput > 1000) {
			mult = 0.1;
		}
		if(electricityOutput > 100){
			let type = feature.properties["Type"];
			
			let checkbox = document.getElementById(ukPowerSourceType(type));
			
			if (checkbox.checked) {
				let interpolatedColor = fnPixelColor(type).withAlpha(0.7);
				return {
				   fillStyle: interpolatedColor.toCssColorString(),
				   lineWidth: 0.007*mult*electricityOutput,
				};
			}
		}
		return null;
	},
	viewer : viewer,
});





addAllImageryLayers();





let ukGeoJsonDataSource ;
loadPointsGeoJsonSource(ukGeoJsonDataSource, 'UKPowerPlant', '/UK_Power_Plants.geojson', '/bright_green_circle.png', true);

let UKPowerSubstationsGeoJsonDataSource;
//loadPointsGeoJsonSource(UKPowerSubstationsGeoJsonDataSource, 'UKSubstation', '/england_power_substations.geojson', '/marker-red-icon.png');

let germanyWindGeoJsonDataSource ;
 loadPointsGeoJsonSource(germanyWindGeoJsonDataSource, 'WindPower', '/Germany_Wind_Power_Plants.geojson', '/marker-blue-icon.png');

let germanyNuclearGeoJsonDataSource;
loadPointsGeoJsonSource(germanyNuclearGeoJsonDataSource, 'NuclearPower','/Germany_Nuclear_Power_Plants.geojson', '/marker-green-icon.png');

let germanyThermalGeoJsonDataSource;
loadPointsGeoJsonSource(germanyThermalGeoJsonDataSource, 'ThermalPower', '/Germany_Thermal_Power_Plants.geojson', '/marker-black-icon.png');

let germanyHydroGeoJsonDataSource;
loadPointsGeoJsonSource(germanyHydroGeoJsonDataSource, 'HydroPower', '/Germany_Hydro_Power_Plants.geojson', '/marker-gray-icon.png');


let germanyPhotoVoltaicGeoJsonDataSource;
loadPointsGeoJsonSource(germanyPhotoVoltaicGeoJsonDataSource, 'SolarPower', '/Germany_PhotoVoltaic_Power_Plants.geojson', '/marker-red-icon.png');
//loadPointsGeoJsonSource(germanyPhotoVoltaicGeoJsonDataSource, 'SolarPower', '/england_power_substations.geojson', '/marker-red-icon.png');

//let germanyPowerSubstationsGeoJsonDataSource;
//loadPointsGeoJsonSource(germanyPowerSubstationsGeoJsonDataSource, 'PowerSubstations', '/germany_power_substations.geojson', Cesium.Color.DARKSLATEGREY);

//let texasPowerLineGeoJsonDataSource;
//loadPointsGeoJsonSource(texasPowerLineGeoJsonDataSource, 'TexasPowerLines', '/texas_power_lines.geojson', '/marker-red-icon.png');

let texasPowerSubStationsGeoJsonDataSource;
//loadPointsGeoJsonSource(texasPowerSubStationsGeoJsonDataSource, 'TexasPowerSubStations', '/texas_power_substation_data.geojson', '/marker-red-icon.png', true);

async function loadPointsGeoJsonSource(geoJsonDataSource, name, url, iconUrl, showCircle){

	if(!geoJsonDataSource){
		Cesium.GeoJsonDataSource.load(url, {
			clampToGround: true,
		}).then(dataSource => {
			dataSource.name = name;
			viewer.dataSources.add(dataSource);
			//console.log(dataSource);
			geoJsonDataSource = dataSource;
			//Customize each entity's icon
			dataSource.entities.values.forEach(function(entity) {
			  // Check if entity has a position (point) property
			  if (Cesium.defined(entity.position)) {
				 if(showCircle){
					
					const plantType = entity.properties["Type"];
					const capacityProperty = entity.properties["Capacity (MW)"];
					let capacity = Cesium.defined(capacityProperty.getValue) ? capacityProperty.getValue() : capacityProperty;
					let type = Cesium.defined(plantType.getValue) ? plantType.getValue() : plantType;
					const pixelSize = fnPixelSize(capacity);
					 const pixelColor = fnPixelColor(type);
					 entity.point = { 
						 pixelSize: pixelSize, // Size of the point in pixels 
						 color: pixelColor, // Point color 
						 outlineColor: Cesium.Color.WHITE, // Outline color 
						 outlineWidth: 2, // Outline width in pixels 
					}
					
					// Remove any existing billboard to avoid the pin being shown
					entity.billboard = undefined;
					 
				 }else{
					 
					entity.billboard = new Cesium.BillboardGraphics({
						  image: iconUrl, // Path to your custom icon image
						  width: 32,   // Set the width of the icon
						  height: 32,  // Set the height of the icon
						  scale: 1.0,
						  verticalOrigin: Cesium.VerticalOrigin.BOTTOM // Positioning at the bottom
					  });	 
				 }
			  } else if (Cesium.defined(entity.polyline)) {
				
				const lineVoltage = entity.properties["voltage"];
				let lv = Cesium.defined(lineVoltage) ? lineVoltage.getValue() : "000";
				const [lvColor, lvWidth] = fnLineVoltageColor(lv);

					// For LineString entities, customize the polyline appearance
					entity.polyline.width = 3;
					entity.polyline.material = lvColor; // Set line color and transparency
					entity.polyline.height = 0; // Disable terrain clamping to enable outlines
					
			  }else if (Cesium.defined(entity.polygon)) {
				    
					entity.polygon.material = Cesium.Color.BLUE.withAlpha(0.5);
					entity.polygon.outline = true;
					entity.polygon.outlineColor = Cesium.Color.BLACK;
					entity.polygon.height = 0; // Flat appearance
					entity.polygon.extrudedHeight = 0;
					//Dispay Pin on Polygon
					// Get the center of the polygon for placing the pin
					const polygonCenter = Cesium.BoundingSphere.fromPoints(entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions).center;
					const cartographic = Cesium.Cartographic.fromCartesian(polygonCenter);
					const latitude = Cesium.Math.toDegrees(cartographic.latitude);
					const longitude = Cesium.Math.toDegrees(cartographic.longitude);

					// Create a new entity for the pin at the center of the polygon
					dataSource.entities.add({
						position: Cesium.Cartesian3.fromDegrees(longitude, latitude),
						billboard: {
							image: iconUrl, // Path to your custom icon image
							width: 4,
							height: 4,
							scale: 1.0,
							verticalOrigin: Cesium.VerticalOrigin.BOTTOM
						}
					});
				}
			});
			geoJsonDataSource.show=false;//First time load so set false.
		})
		.catch(error => {
			console.error('Error loading GeoJSON:', error);
		});
	}else{
		geoJsonDataSource.show=true;
	}
}

function fnLineVoltageColor (lv) {

	if (lv.startsWith("69")){
		const width = 2;
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.5, new Cesium.Color());
	 	return [color, width];
	}else if (lv.startsWith("115")){
		const width = 3;
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.WHITE, 0.5, new Cesium.Color());
		return [color, width];
	}else if (lv.startsWith("138")){
		const width = 4;
		const color = Cesium.Color.fromCssColorString("brown");
		// const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.RED, 0.5, new Cesium.Color());
		return [color, width];
	}else if (lv.startsWith("230")){
		const width = 4;
		const color = Cesium.Color.BLUE;
		return [color, width];
	}else if (lv.startsWith("345")){
		const width = 4;
		const color = Cesium.Color.GREEN;
		return [color, width];
	}else if (lv.startsWith("500")){
		const width = 5;
		const color = Cesium.Color.RED;
		return [color, width];
	}else if (lv.startsWith("000")){
		const width = 2;
		const color = Cesium.Color.BLACK;
		return [color, width];
	}else {
		const width = 2;
		const color = Cesium.Color.BLACK;
		return [color, width];
	}
}

function fnPixelColor (str) {

	if (str.startsWith("CCGT")){
		const color = Cesium.Color.RED;
	 	return color;
	}else if (str.startsWith("CHP")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.WHITE, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Coal")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.WHITE, 0.7, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Demand")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.WHITE, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Energy")){
		const color = Cesium.Color.GREEN;
	 	return color;
	}else if (str.startsWith("Gas")){
		const color = Cesium.Color.lerp(Cesium.Color.GREEN, Cesium.Color.WHITE, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Hydro")){
		const color = Cesium.Color.lerp(Cesium.Color.BLUE, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Nuclear")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("OCGT")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.BLACK, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Oil")){
		const color = Cesium.Color.MAGENTA;
	 	return color;
	}else if (str.startsWith("PV")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Pump")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.CYAN, 0.4, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Reactive")){
		const color = Cesium.Color.BLUE;
	 	return color;
	}else if (str.startsWith("Thermal")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.BLUE, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Tidal")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.GREEN, 0.2, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Waste")){
		const color = Cesium.Color.lerp(Cesium.Color.WHITE, Cesium.Color.BLACK, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Wind Offshore")){
		const color = Cesium.Color.lerp(Cesium.Color.WHITE, Cesium.Color.GREEN, 0.5, new Cesium.Color());
	 	return color;
	}else if (str.startsWith("Wind Onshore")){
		const color = Cesium.Color.lerp(Cesium.Color.RED, Cesium.Color.YELLOW, 0.5, new Cesium.Color());
	 	return color;
	}else {
		const color = Cesium.Color.RED;
	 	return color;
	}
}

function fnPixelSize (capacity) {
	if (capacity == 0) {
		const size = 5;
		return size;
	}else if (capacity <= 25) {
		const size = 10;
		return size;
	}else if (capacity <= 100) {
		const size = 15;
		return size;
	}else if (capacity <= 500) {
		const size = 20;
		return size;
	}else if (capacity <= 1000) {
		const size = 25;
		return size;
	}else if (capacity <= 2000) {
		const size = 30;
		return size;
	}else if (capacity <= 3000) {
		const size = 40;
		return size;
	}else {
		const size = 50;
		return size;
	}
}


const powerSourceElements3d = document.querySelectorAll('.sidebar .menu-section #germanySection > input[type="checkbox"], .sidebar .menu-section #ukSection > input[type="checkbox"], .sidebar .menu-section #usSection > input[type="checkbox"]');
for (let powerSourceElement of powerSourceElements3d) {
   powerSourceElement.addEventListener ('change', function (){
    
	
	
	if(this.value === 'UKEnergyChart'){
		if(this.checked){
			mapGrid.style.display = 'none';
			chartGrid.style.display = 'block';
		}else{
			mapGrid.style.display = 'block';
			chartGrid.style.display = 'none';
		}
	}else{
		mapGrid.style.display = 'block';
		chartGrid.style.display = 'none';
		document.getElementsByName('UKEnergyChartCheckbox')[0].checked = false;
		
		let powerSources = viewer.dataSources.getByName(this.value);
		//console.log (powerSources[0]);
		if (powerSources.length > 0) {
			if(this.checked){
				powerSources[0].show = true;
			}else{
				powerSources[0].show = false;
			}
		}
	}
    
  });
}



function getZoomLevel(camera) {
    const ellipsoid = Cesium.Ellipsoid.WGS84;
    const height = ellipsoid.cartesianToCartographic(camera.position).height;
	return Math.floor(19 - Math.log2(height / 1000));
}

//let zoomLevelLabel = document.getElementById("zoomLevelLabel");

let activeDetailLayer = null;

viewer.scene.camera.changed.addEventListener(() => {
    const zoomLevel = getZoomLevel(viewer.camera);
	
	if (zoomLevel >= 16 && zoomLevel <= 19 && activeDetailLayer !== 'Show') {
		//console.log ("current country is [false, true]",currentCountry);
		changeLayerVisibility(currentCountry, false, true);

		activeDetailLayer = "Show";
    } else if (zoomLevel >= 0 && zoomLevel < 16 && activeDetailLayer !== 'NoShow'){
		changeLayerVisibility(currentCountry, false, false);		
		

		activeDetailLayer = "NoShow";
	}

//	zoomLevelLabel.innerHTML = 'zoomLevel : ' + zoomLevel +', activeLayer : '+ activeLayer;

});




function addAllImageryLayers(){

	// currentLayerUKPower = viewer.imageryLayers.addImageryProvider(imageryUKPowerProvider);
	// imageryUKPowerProvider.alpha = 1.0;
	// currentLayerUKPower.show = false;
	
}


const convertToKilowatts = (value) => {
        const regex = /(\d+\.?\d*)\s*(kW|MW|GW)/i; // Matches numbers with kW, MW, or GW
        const match = value.match(regex);

        if (match) {
            const number = parseFloat(match[1]); // Get the number
            const unit = match[2].toUpperCase(); // Get the unit (kW, MW, GW)

            // Convert the value to kW
            if (unit === "MW") {
                return number * 1000; // Convert MW to kW
            } else if (unit === "GW") {
                return number * 1000000; // Convert GW to kW
            } else if (unit === "KW") {
                return number; // Already in kW
            }
        }
        return 0; // If no valid match, return 0
};


function swapCurrentCountryObject(country){
	if(currentCountry && currentCountry !== country){
		changeLayerVisibility(currentCountry, true, false);
		currentCountryCheckboxList.forEach(checkbox => {
			checkbox.checked = false;
		});
		currentCountryCheckboxList.length = 0;
		
	}
	currentCountry = country;
}

function changeLayerVisibility(country, forAllLayers, visibility){
	if(country){
		let countryObject = countryObjects[country];
		//console.log(country, countryObject);
		for (const layerKey in countryObject.layers) {
			//console.log(layerKey);
			
			if(!forAllLayers && layerKey.startsWith('_')){
				continue; // Ignore lines layers 
			}
			countryObject.layers[layerKey].show = visibility;
		}	
	}
	
}

let hideTimeout;

// Function to make the menu sticky
function makeSticky(menu) {
  clearTimeout(hideTimeout);
  menu.classList.add("sticky");
}

// Function to remove the sticky class after a timeout
function removeSticky(menu) {
  hideTimeout = setTimeout(() => {
    menu.classList.remove("sticky");
  }, 700); // Timeout before hiding the menu
}


// Function to add events to leaf menu items dynamically
function addMenuEventListeners() {

	const mainMenu = document.getElementById("menu-container");
	const submenus = document.querySelectorAll(".submenu");

	mainMenu.addEventListener("mouseenter", () => makeSticky(mainMenu));
	mainMenu.addEventListener("mouseleave", () => removeSticky(mainMenu));
	
	// Add event listeners for the submenus
	submenus.forEach((submenu) => {
	  submenu.addEventListener("mouseenter", () => makeSticky(submenu));
	  submenu.addEventListener("mouseleave", () => removeSticky(submenu));
	});


	let leafMenus = document.querySelectorAll(".submenu-item:not(.has-submenu)");
	  
	leafMenus.forEach((menu) => {
    	menu.addEventListener("click", (event) => {
     		//alert(`Menu clicked: ${menu.id}`);
	  		let clickedId = menu.id.split('_');
	  		let menuType = clickedId[clickedId.length - 1];
	  		let country = clickedId[0];
	  		let typeOfMenu = clickedId[clickedId.length - 2];
      		console.log(`Country: ${country}, menuType: ${menuType}, typeOfMenu: ${typeOfMenu}, menuID: ${menu.id}`);
	  
			if(typeOfMenu === 'Substations'){
				if (country === 'USA - TX') {
					country = 'Texas';
		  		}
				if ((country === 'Scotland') || (country === 'Ireland') || (country === 'Wales')) {
					country = 'UK'
				}
				
				// Construct the key dynamically
				if(country === 'USA - TX'){
					country = 'Texas';
	    		}
				const key = `${country}_${typeOfMenu}`;
				// Access the array dynamically
				const substation = Substations[key];
				// console.log ("key = ", key);
				if (substation) {
					let selected_lat = ''; 
					let selected_long = '';
					let selected_name = '';
					//console.log ("entered Substations");
					const suggestionBox = document.getElementById(`suggestions_${menu.id}`);

					const clearButton = document.getElementById(`clearbutton_${menu.id}`);
					let textbox = document.getElementById('textbox_'+menu.id);
					textbox.addEventListener('keydown', function (event) {
						suggestionBox.innerHTML = "";
						suggestionBox.style.display = 'block';

						if (this.value.length > 1) {

							const filteredSubstations = substation
								.filter(name => name[0].toLowerCase().includes(this.value.toLowerCase()))
								.slice(0, 50); // Limit to 100 names
							
							// // Populate the suggestions
							filteredSubstations.forEach(name => {
								const listItem = document.createElement("li");
								listItem.textContent = name;
		
								// Store the original value as an attribute on the list item for later use
								listItem.setAttribute("data-original-value", this.value);

								listItem.addEventListener("mouseover", () => {
									this.value = name; // Update text box with hovered value
								});

								listItem.addEventListener("mouseleave", () => {
									this.value = listItem.getAttribute("data-original-value"); // Revert to original								
								});
					
								if (clearButton) {
									clearButton.addEventListener("click", () => {
									  // Additional logic
									  if (textbox) {
										textbox.value = "";
									  }
									});
								  } else {
									console.log("clearButton not found");
								  }

								listItem.addEventListener("click", function(event){

									const selectedValue = this.textContent;
									const selectedParts = selectedValue.split(','); // Split the selected suggestion into parts
					
									selected_name = selectedParts[0].trim(); // Substation name
									selected_long = parseFloat(selectedParts[1].trim()); // Longitude
									selected_lat = parseFloat(selectedParts[2].trim()); // Latitude

									// Update input field with the full suggestion (e.g., name)
									document.getElementById('textbox_' + menu.id).value = selected_name;
									suggestionBox.style.display = 'none'; // Hide suggestion box
									document.getElementById('textbox_' + menu.id).focus(); // Refocus on the input field

									// Log the selected values (for debugging)
									//console.log("selected_name = ", selected_name);
									//console.log("selected_long_lat = ", selected_long, ", ", selected_lat);
									swapCurrentCountryObject(country);
									processSubstationData (selected_name, selected_long, selected_lat, country );

									suggestionBox.innerHTML = ""; // Clear suggestions
								});
								suggestionBox.appendChild(listItem);
		
							});

						}
					});
				} else {
					alert (`${key}, is not implemented yet`);
				}	
			}else if (typeOfMenu === 'PowerLines') {
				if(country === 'USA - TX'){
					country = 'Texas';
	    		}
				swapCurrentCountryObject(country);
				let countryObject = countryObjects[country];
				let rectangle = countryObject.rectangle;
				//console.log ("menuType - ", menuType);

				if(menuType === 'Minorlines'){
				//	let rectangle = countryObject.rectangle;
					if(country === 'UK'){
						country = 'England';
						countryObject = countryObjects[country];
					}
			  
					let minorLines = countryObject.layers.minor_lines;
					if(minorLines){
						let checkbox = document.getElementById('checkbox_'+menu.id);
						minorLines.show = checkbox.checked;
						currentCountryCheckboxList.push(checkbox);
					}
					// console.log ('fly to triggered at Minor Lines event');

					if (rectangle) {
						flyToInRequired(rectangle);
					}
				} else if (menuType === 'EnterVoltage'){

					// console.log("selected - ", country, menuType, typeOfMenu, menu.id);

					if (country === 'USA - TX') {
						country = 'Texas';
					}

					// Construct the key dynamically
					
					// Access the array dynamically
					const countryVoltages = voltagePowerLinesByCountry[country];
					if (countryVoltages) {
						let selected_volt = ''; 
						// ("entered EnteredVoltages");
						const suggestionBox = document.getElementById(`suggestions_${menu.id}`);

						const clearButton = document.getElementById(`clearbutton_${menu.id}`);
						let textbox = document.getElementById('textbox_'+menu.id);
						textbox.addEventListener('keydown', function (event) {
							suggestionBox.innerHTML = "";
							suggestionBox.style.display = 'block';
	
							if (this.value.length > 0) {
	
								const filteredVoltages = countryVoltages
									.filter(volt => volt.includes(this.value))
									.slice(0, 50); // Limit to 100 names
								
								// // Populate the suggestions
								filteredVoltages.forEach(volt => {
									const listItem = document.createElement("li");
									listItem.textContent = volt;
			
									// Store the original value as an attribute on the list item for later use
									listItem.setAttribute("data-original-value", this.value);
	
									listItem.addEventListener("mouseover", () => {
										this.value = volt; // Update text box with hovered value
									});
	
									listItem.addEventListener("mouseleave", () => {
										this.value = listItem.getAttribute("data-original-value"); // Revert to original
									});
						

									if (clearButton) {
										clearButton.addEventListener("click", () => {
										  // Additional logic
										  if (textbox) {
											textbox.value = "";
										  }
										});
									  } else {
										console.log("clearButton not found");
									  }
			
									listItem.addEventListener("click", function(event){
	
										const selected_volt = this.textContent;
										//console.log ('click event triggereed');
										// Update input field with the full suggestion (e.g., name)
										document.getElementById('textbox_' + menu.id).value = selected_volt;
										suggestionBox.style.display = 'none'; // Hide suggestion box
										document.getElementById('textbox_' + menu.id).focus(); // Refocus on the input field
	
										// Log the selected values (for debugging)
										//console.log("selected_voltage = ", selected_volt, selected_volt+'kv', typeof selected_volt);
										//console.log("selected_long_lat = ", selected_long, ", ", selected_lat);
										swapCurrentCountryObject(country);

										let voltageLayer = countryObject.layers[`_${selected_volt.toLowerCase()}`];
										//console.log ("menuType - ", menuType);
										//console.log ("lines - ", voltageLayer);
										if(voltageLayer){
											voltageLayer.show = true;
										}						
										suggestionBox.innerHTML = ""; // Clear suggestions

										//console.log ('fly to triggered at Entered Voltage event');

										if (rectangle) {
											flyToInRequired(rectangle);
										}
									});
									suggestionBox.appendChild(listItem);
								});	
							}
						});
					} else {
						alert (`${key}, is not implemented yet`);
					}
				} else {
					//console.log ("countryObject - ", countryObject);
					let lines = countryObject.layers[`_${menuType.toLowerCase()}`];
					//console.log ("Clicked on 0 - 100kv lines - ", lines);
					if(lines){
						let checkbox = document.getElementById('checkbox_'+menu.id);
						lines.show = checkbox.checked;
						currentCountryCheckboxList.push(checkbox);
					}
					//console.log ('fly to triggered at check box event');
					if (rectangle) {
						flyToInRequired(rectangle);
					}
				}
	  		}else if(menuType === 'Charts'){
			  	mapGrid.style.display = 'none';
		  		chartGrid.style.display = 'block';
			}
	    });
  	});
}

addMenuEventListeners();
function processVoltageData (volt, country){

	//console.log ("countryObject - ", countryObject);
	let lines = countryObject.layers[`_${menuType.toLowerCase()}`];
	//console.log ("lines - ", lines);
	if(lines){
		let checkbox = document.getElementById('checkbox_'+menu.id);
		lines.show = checkbox.checked;
		currentCountryCheckboxList.push(checkbox);
	}

	viewer.camera.flyTo({
		destination: Cesium.Cartesian3.fromDegrees(sub_long, sub_lat, 2000.0),
		easingFunction: Cesium.EasingFunction.EXPONENTIAL_IN_OUT,
		duration: 7.0,
		maximumHeight: 60000.0,
	});
}

function processSubstationData (sub_name, sub_long, sub_lat, country){
	//console.log ("Name is ", sub_name);
	//console.log ("Long is ", sub_long);
	//console.log ("Lat is ", sub_lat);

	viewer.camera.flyTo({
		destination: Cesium.Cartesian3.fromDegrees(sub_long, sub_lat, 2000.0),
		easingFunction: Cesium.EasingFunction.EXPONENTIAL_IN_OUT,
		duration: 7.0,
		maximumHeight: 60000.0,
	});
}

function flyToInRequired(rectangle){
	let currentViewRectangle = viewer.camera.computeViewRectangle(viewer.scene.globe.ellipsoid);
	
	if( currentViewRectangle.west < rectangle.west || currentViewRectangle.south < rectangle.south ||
			currentViewRectangle.east > rectangle.east || currentViewRectangle.north > rectangle.north){
		
		viewer.camera.flyTo({
					destination: rectangle,
					easingFunction: Cesium.EasingFunction.EXPONENTIAL_OUT,
					duration: 7.0,
				});	
	}
}

async function flyToTown(town, country) {
    
    try {
		let countryObject = countryObjects[country]

		let search = town +','+country;
        let response = await fetch(`https://api.cesium.com/v1/geocode/autocomplete?text=${encodeURIComponent(search)}&access_token=${Cesium.Ion.defaultAccessToken}`);
        let data = await response.json();
		
		//console.log(data);
		if (data.features && data.features.length > 0) {
            let firstBBox = data.features[0].bbox;
            //console.log('First BBox:', firstBBox);

            // Example: Do something with the bbox
            if (firstBBox) {
               viewer.camera.flyTo({
					destination: Cesium.Rectangle.fromDegrees(firstBBox[0], firstBBox[1], firstBBox[2], firstBBox[3]),
					easingFunction: Cesium.EasingFunction.EXPONENTIAL_IN_OUT,
					duration: 7.0,
					complete: function() {
						//countryObject.layers.substations.show = true;
						}

				});
            }
		}

        
    } catch (error) {
        console.error('Error fetching geocode:', error);
       
    }
}

document.getElementById("backToMap").addEventListener("click", () => {
    mapGrid.style.display = 'block';
	chartGrid.style.display = 'none';
});

// Utility Function to Create Imagery Providers
function createImageryProvider({country,layerPath,layerName,maximumLevel,layerStyleFnc,layerStyleParams = {}}) {
  if (typeof layerStyleFnc !== "function") {
    throw new Error("A layerStyleFnc function must be provided.");
  }
  return new CustomCesiumMVTImageryProvider({
    urlTemplate: `${TILE_BASE_URL}/${layerPath}/{z}/{x}/{y}.pbf`,
    layerName: layerName,
    rectangle: countryObjects[country]?.rectangle || null,
    tilingScheme: new Cesium.WebMercatorTilingScheme(),
    maximumLevel: maximumLevel,
    style: feature => {
      return layerStyleFnc(feature, layerStyleParams); // Pass additional parameters
    },
    viewer: viewer
  });
}


countryMVTImageryConfigs.forEach(config => {
	let imageryProvider = createImageryProvider({...config});
	let currentLayer = viewer.imageryLayers.addImageryProvider(imageryProvider); // Add to the viewer
	imageryProvider.alpha = 1.0;
	currentLayer.show = false;
	countryObjects[config.country].layers[config.layerKey] = currentLayer;
});


Object.entries(voltagePowerLinesByCountry).forEach(([country, powerLines]) => {
  powerLines.forEach((line) => {
	
	let config = {
		country: `${country}`,
		layerPath: `${country.toLowerCase()}_lines_${line.toLowerCase()}`,
		layerName: `${country.toLowerCase()}_lines_${line.toLowerCase()}`,
		layerKey: `_${line.toLowerCase()}`,
		maximumLevel: 18,
		layerStyleFnc: stylePowerLinesDynamic,
		layerStyleParams: {
			opacity: 1,
			defaultStyle: true
		}
	}
	let imageryProvider = createImageryProvider({...config});
	let currentLayer = viewer.imageryLayers.addImageryProvider(imageryProvider); 
	imageryProvider.alpha = 1.0;
	currentLayer.show = false;
	countryObjects[config.country].layers[config.layerKey] = currentLayer;
  });
});
