export const Germany_Substations = [
    [ "Lingen",[7.3199387, 52.5050041]],
    [ "Station Godesberg",[7.1422679, 50.6916011]],
    [ "Schweinfurt",[10.1944427, 50.0286798]],
    [ "Station Leithe",[7.1024284, 51.4813071]],
    [ "Station Mandern",[6.7842685, 49.6213317]],
    [ "Eintracht",[8.0066894, 50.8656779]],
    [ "Umspannwerk Tiefstack",[10.0676246, 53.5260937]],
    [ "Umspannwerk Reuter 110/30KV",[13.2467585, 52.5304288]],
    [ "Station Bomig",[7.542819, 50.971153]],
    [ "Station Küppersteg",[6.9891285, 51.0470265]],
    [ "Bevern",[9.4828812, 51.8537153]],
    [ "Station Olsberg",[8.48645, 51.3432997]],
    [ "Umspannwerk Straubing",[12.6171389, 48.8797224]],
    [ "Kraftwerkseinspeisung Staudinger",[8.9566137, 50.0862786]],
    [ "Schaltanlage Lübeck Nord2",[10.6935742, 53.8965089]],
    [ "Umspannwerk Vorwerk",[10.6930617, 53.8966123]],
    [ "GKM Station B",[8.5026611, 49.4446933]],
    [ "Umspannwerk Unterboihingen",[9.3649306, 48.6684301]],
    [ "Umspannwerk Vacher Straße",[10.9749135, 49.4950579]],
    [ "Kraftwerk Bergheim",[11.2737341, 48.7514634]],
    [ "Umspannwerk Frankfurt Nord",[8.7359056, 50.160716]],
    [ "Hauptumspannwerk Föhring",[11.6400854, 48.176889]],
    [ "Umspannwerk Sindelfingen",[8.9879402, 48.7141923]],
    [ "Umspannwerk Bommersheim",[8.6079718, 50.1916472]],
    [ "Hamburg/Süd",[9.9071431, 53.4864439]],
    [ "380 kV-Kabel Terminal Berlin",[13.2808219, 52.5264668]],
    [ "Umspannwerk Frankfurt/West",[8.6028063, 50.145687]],
    [ "Umspannwerk Jakob-Kaiser-Platz",[13.2924936, 52.5338522]],
    [ "Umspannwerk Oberbachern",[11.3674138, 48.2908123]],
    [ "Umspannwerk Falkenberger Straße",[13.4944957, 52.5598401]],
    [ "Umspannwerk Malchow",[13.4932544, 52.5912074]],
    [ "UW Niederwartha",[13.6104714, 51.0921844]],
    [ "Umspannwerk Rommelshausen",[9.310804, 48.8014791]],
    [ "Nord",[7.6157007, 51.9859142]],
    [ "Umspannwerk Bocklemünd",[6.852304, 50.9827249]],
    [ "Hamburg Nord",[9.986011, 53.7416532]],
    [ "UW Fühlingen",[6.9329457, 51.0335911]],
    [ "Station Opladen",[6.9651425, 51.0690086]],
    [ "Wernigerode",[10.7972528, 51.8465719]],
    [ "Station Dhünnaue",[6.9758826, 51.0379197]],
    [ "Station Knapsack",[6.8415505, 50.8640714]],
    [ "Station Sechtem",[6.9742187, 50.7977351]],
    [ "Station Siegburg",[7.189821, 50.8005042]],
    [ "Station Alfter",[7.0302705, 50.7523588]],
    [ "Bonn-Nord (Westnetz GmbH)",[7.0654683, 50.7519375]],
    [ "Station Ranzel",[7.0316336, 50.8213605]],
    [ "Station Wesseling",[7.009341, 50.8138426]],
    [ "Station Stockem",[7.0954506, 50.8278233]],
    [ "Umspannwerk Echterdingen Nord",[9.1711298, 48.7035138]],
    [ "Umspannwerk Echterdingen Süd 110 kV",[9.1684175, 48.6809841]],
    [ "Umspannwerk Möhringen",[9.1341881, 48.7262293]],
    [ "Umspannwerk Kulkwitz",[12.2326203, 51.2928734]],
    [ "Umspannwerk Leipzig I",[12.4358187, 51.3698837]],
    [ "Umspannwerk Seehausen",[12.4048644, 51.4040465]],
    [ "Station Bergmannsglück",[7.0361751, 51.5991238]],
    [ "UW Merkenich",[6.9562536, 51.0204147]],
    [ "Umspannwerk Mühlhausen",[9.2139841, 48.852172]],
    [ "Station Kruckel",[7.4154838, 51.457171]],
    [ "Umspannwerk Wuhlheide",[13.5042706, 52.4730233]],
    [ "Umspannwerk Marzahn",[13.5249484, 52.5357513]],
    [ "Unterwerk Rohr",[9.1101183, 48.7142756]],
    [ "Station Polsum",[7.020273, 51.6235716]],
    [ "Station Rotthausen",[7.08435, 51.4856461]],
    [ "Umspannwerk Hohenbrunn",[11.6817068, 48.0493718]],
    [ "Station Hillerheide",[7.2260173, 51.5994437]],
    [ "Station Hüllen",[7.1211567, 51.5261612]],
    [ "UW Maarweg",[6.8980527, 50.9476414]],
    [ "Station Datteln",[7.3527254, 51.6533141]],
    [ "Station Pfalzdorf",[6.2202246, 51.6941152]],
    [ "Umspannanlage Brauweiler",[6.8104026, 50.9690437]],
    [ "Umspannwerk Herrenberg",[8.8744978, 48.6029461]],
    [ "Umspannwerk Sindelfingen-Mahdental",[9.0366858, 48.704722]],
    [ "Umspannwerk Röhrsdorf",[12.8114495, 50.8578124]],
    [ "Umspannwerk Dresden Räcknitz",[13.7304622, 51.0241334]],
    [ "Umspannwerk Meißen",[8.9586136, 52.2798182]],
    [ "Station Eller",[6.848087, 51.2047884]],
    [ "Päpinghausen",[8.9812781, 52.3039387]],
    [ "Freiluftschaltanlage Lokstedt",[9.9489241, 53.6078938]],
    [ "Heitmannshausen",[9.6695068, 53.4735176]],
    [ "Umspannwerk Taucha",[12.4674033, 51.371626]],
    [ "Umspannanlage Hugo",[7.040466, 51.5675087]],
    [ "Umspannwerk Leonberg",[8.9883555, 48.8045974]],
    [ "Station Reisholz",[6.8272148, 51.163789]],
    [ "Umspannanlage Niederhöchstadt",[8.5334228, 50.166524]],
    [ "Umspannwerk Schönaich",[9.0508753, 48.6458618]],
    [ "Umspannwerk Dresden Süd",[13.8404732, 50.9896955]],
    [ "Umspannwerk Oberems",[8.3970333, 50.2381677]],
    [ "Umspannwerk Urberach",[8.7706863, 49.9764305]],
    [ "Umspannanlage Rheinelbe",[7.1109757, 51.4916528]],
    [ "Königsee",[11.1092575, 50.6593658]],
    [ "Station Hau",[6.1315217, 51.7670677]],
    [ "Station Utfort",[6.6134898, 51.4775667]],
    [ "Coburg-Neuses",[10.9568974, 50.2813037]],
    [ "Umspannwerk Weilimdorf",[9.0954856, 48.8281387]],
    [ "Umspannwerk Grüner Jäger",[10.4173642, 53.4252033]],
    [ "Altenwerder",[9.9126128, 53.5159003]],
    [ "Storkow",[13.936229, 52.2636317]],
    [ "Mittelnkirchen",[9.6340839, 53.5392]],
    [ "Umspannwerk Dambacher Straße",[10.9832461, 49.470704]],
    [ "Umspannwerk Leipzig Ost",[12.4216082, 51.3544277]],
    [ "Neuhof",[9.9605023, 53.5164503]],
    [ "Tempelfelde",[13.7128799, 52.7153026]],
    [ "Umspannwerk Kuhwerder",[9.9702995, 53.5277143]],
    [ "Umspannwerk Sielmingen",[9.244967, 48.6651856]],
    [ "Wedel",[9.7264584, 53.5714718]],
    [ "Hamburg/Ost",[10.1543968, 53.5557453]],
    [ "Umspannwerk Jenfeld",[10.0995544, 53.5740577]],
    [ "Umspannwerk Wörrstadt",[8.1103256, 49.8464733]],
    [ "Schenefeld",[9.8341082, 53.6035197]],
    [ "Neu Wulmstorf",[9.7980267, 53.4517786]],
    [ "Umspannwerk Seevetal-Hittfeld",[9.9809195, 53.3903722]],
    [ "Umspannwerk Marxheim",[8.4334178, 50.0596256]],
    [ "Farbwerke Höchst Süd (FWH-S)",[8.53597, 50.0783121]],
    [ "List",[9.7526456, 52.3992325]],
    [ "Hannover/West",[9.524755, 52.359]],
    [ "Unterwerk Wickrath",[6.3953859, 51.1211799]],
    [ "Umspannwerk Rehhof",[11.1476002, 49.4607229]],
    [ "Schwicheldt",[10.108111, 52.3221425]],
    [ "Umspannanlage Mörfelden",[8.5903672, 49.9701972]],
    [ "Anderten",[9.8900582, 52.3655975]],
    [ "Umspannwerk Maade",[8.1242077, 53.559591]],
    [ "Dortelweil",[8.7551905, 50.2213192]],
    [ "Bentstreek",[7.8469655, 53.3882425]],
    [ "Roffhausen",[8.001357, 53.517333]],
    [ "Umspannwerk Lahe",[9.8208248, 52.4237429]],
    [ "Dörnigheim 220 kV",[8.8717425, 50.1303849]],
    [ "Umspannwerk Westerfeld",[8.5255416, 50.319849]],
    [ "Zeppelinstraße",[13.0316065, 52.3904449]],
    [ "Umspannwerk Reußen",[12.116407, 51.5023447]],
    [ "Algermissen",[9.9181166, 52.2778208]],
    [ "Hauptumspannwerk Menzing",[11.4401405, 48.1699552]],
    [ "Rethen (EON_Netz)",[9.8134936, 52.2918979]],
    [ "Banter Weg",[8.0884474, 53.5205248]],
    [ "Unterwerk Rethen",[9.8350373, 52.2675911]],
    [ "Unterwerk Leipzig-Wahren",[12.3122405, 51.3821224]],
    [ "Bielefeld Nord",[8.5221099, 52.0590596]],
    [ "S-Bahn Gleichrichterunterwerk Treptower Park",[13.4595207, 52.4868983]],
    [ "Umspannwerk Karben",[8.8092043, 50.2044969]],
    [ "Umspannwerk Moabit",[13.3497205, 52.5380757]],
    [ "Umspannwerk Wolfsburg",[10.7586019, 52.4355417]],
    [ "Fallersleben",[10.7343007, 52.4127335]],
    [ "Umspannwerk Leipzig Nord",[12.3456184, 51.3768945]],
    [ "Hanau Nord",[8.9295177, 50.1530711]],
    [ "Station Neuss Memeler Straße",[6.7030053, 51.2035964]],
    [ "Umspannwerk Neufinsing",[11.8127085, 48.2226847]],
    [ "Umspannwerk Herford/Füllenbruch",[8.6554638, 52.1309165]],
    [ "Eickum",[8.5694514, 52.1057202]],
    [ "Herford/Ost",[8.7064612, 52.1254809]],
    [ "Umspannwerk Oberbrunn",[11.2961846, 48.0511888]],
    [ "Umspannwerk",[6.4579323, 51.2127159]],
    [ "Umspannwerk Hattorf",[10.7598305, 52.3673096]],
    [ "Unterwerk Fallersleben",[10.6747811, 52.4210036]],
    [ "Peine/Ost",[10.270356, 52.316902]],
    [ "Wahle 380 kV",[10.3669228, 52.2860656]],
    [ "Bechterdissen 220 kV",[8.6611058, 52.0000406]],
    [ "Umspannanlage Frimmersdorf I",[6.5703581, 51.0504017]],
    [ "Station Frimmersdorf",[6.5679782, 51.0481173]],
    [ "Station Verlautenheide",[6.1656406, 50.802687]],
    [ "Station Wevelinghoven",[6.6089407, 51.0960067]],
    [ "Station Altenrath",[6.1661202, 50.8065004]],
    [ "Ehra",[10.7883602, 52.5905541]],
    [ "Klein Ilsede",[10.237583, 52.2931381]],
    [ "Umspannwerk Braunschweig Nord",[10.505363, 52.3026601]],
    [ "Station Nordlicht",[6.9053084, 51.5658354]],
    [ "Umspannwerk Diebrock",[8.636849, 52.1032862]],
    [ "Station Goch",[6.1553115, 51.6948472]],
    [ "Grohnde",[9.3873929, 52.0269667]],
    [ "Station Wambel",[7.5198716, 51.5307979]],
    [ "Station Elmenhorst",[7.4469768, 51.6185904]],
    [ "Sehnde",[9.983367, 52.3186538]],
    [ "Station Ossenberg",[6.5785775, 51.556256]],
    [ "Spellen",[6.6157107, 51.6186452]],
    [ "Station Niederrhein",[6.6853451, 51.6459543]],
    [ "Umspannwerk Langenzenn",[10.8153912, 49.5028103]],
    [ "Oldenburg Osternburg",[8.22239, 53.1224724]],
    [ "Umspannwerk Rüsternweg",[13.5274095, 52.5580103]],
    [ "Station Walsum",[6.7132629, 51.5267704]],
    [ "Station Möllen",[6.6828415, 51.5759828]],
    [ "Station Emmelsum",[6.6299444, 51.6256333]],
    [ "Station Kirchhellen",[6.9357275, 51.6015934]],
    [ "Uerdingen",[6.6555066, 51.3802325]],
    [ "Umspannwerk Koppenplatz",[13.3985358, 52.5277561]],
    [ "Altwarmbüchen",[9.8394443, 52.4389624]],
    [ "Ganderkesee",[8.5628556, 53.0332008]],
    [ "Unterwerk Elsfleth",[8.4739746, 53.2817682]],
    [ "Delmenhorst Nord",[8.6514482, 53.0714578]],
    [ "Boitwarden",[8.4824805, 53.3437448]],
    [ "Umspannwerk Dollern",[9.504022, 53.543368]],
    [ "Umspannwerk Rothe Erde",[6.1309384, 50.7766521]],
    [ "Station Moers",[6.6204602, 51.4354996]],
    [ "Station Lank",[6.6602911, 51.2951504]],
    [ "Aßlar 110kV",[8.4813966, 50.5908104]],
    [ "Dillenburg 110kV",[8.2846388, 50.7592227]],
    [ "Braunschweig Ost",[10.5694855, 52.2960248]],
    [ "Wolfenbüttel Nord",[10.5254096, 52.1673749]],
    [ "Braunschweig Süd",[10.5042467, 52.241893]],
    [ "Hordorf",[10.6641679, 52.2912404]],
    [ "Gifhorn",[10.5305046, 52.4664372]],
    [ "Rethen/BKB",[10.4937827, 52.3785891]],
    [ "Schaltanlage Ovenstädt",[8.9408129, 52.4040658]],
    [ "Oldenburg Süd",[8.2168271, 53.0990114]],
    [ "Station Meitingen",[10.8663303, 48.5387423]],
    [ "Calau",[13.9667951, 51.7409947]],
    [ "Umspannwerk Erding",[11.9103171, 48.3135328]],
    [ "Garenfeld",[7.5080948, 51.4000638]],
    [ "Harzberg",[9.2216568, 51.9317497]],
    [ "Umspannstation Bischofsheim",[8.3888624, 49.9808847]],
    [ "Umspannwerk Hof Schönau",[8.4029854, 49.9643866]],
    [ "Umspannwerk Laubenheim",[8.3099111, 49.9522093]],
    [ "Umspannwerk Biessenhofen",[10.6333987, 47.8244021]],
    [ "Abspannwerk Bergedorf",[10.2104353, 53.4825169]],
    [ "Station Niederkrüchten",[6.2178674, 51.2063911]],
    [ "Wegberg",[6.2818709, 51.1537524]],
    [ "Umspannwerk Raitersaich",[10.8424109, 49.3816118]],
    [ "Umspannwerk Müncherlbach",[10.8400681, 49.3606122]],
    [ "Umspannwerk Bergrheinfeld",[10.1580924, 49.9894584]],
    [ "Farge",[8.5183023, 53.203735]],
    [ "Umspannwerk Zirndorf",[10.9420962, 49.4280543]],
    [ "Umspannwerk Redwitz",[11.1918131, 50.1599123]],
    [ "Umspannwerk Würgau",[11.1036403, 49.9800323]],
    [ "Umspannwerk Oberhaid",[10.8348164, 49.9290683]],
    [ "Eltmann",[10.6622979, 49.9800207]],
    [ "Umspannwerk Sittling",[11.7915746, 48.839525]],
    [ "Schaltwerk Schwebheim",[10.2196004, 50.0165276]],
    [ "Umspannwerk Iphofen",[10.2552203, 49.694288]],
    [ "Umspannwerk Diespeck",[10.6265152, 49.6028271]],
    [ "Umspannwerk Trennfeld",[9.5927005, 49.7999592]],
    [ "Umspannwerk Petersgmünd",[11.0245158, 49.1764894]],
    [ "Umspannwerk Preith",[11.1980963, 48.9107246]],
    [ "Ingolstadt",[11.5056233, 48.7624524]],
    [ "Station Osterath",[6.6237703, 51.2626102]],
    [ "Station Dülken",[6.3301667, 51.2443654]],
    [ "Umspannwerk Harburg",[10.6855469, 48.7764327]],
    [ "Station Schacht V",[6.6367234, 51.4709004]],
    [ "Umspannwerk Rommerskirchen",[6.6962774, 51.0101535]],
    [ "Umspannanlage Ober-Roden",[8.8429046, 49.9653038]],
    [ "Umspannwerk Dietzenbach",[8.7994161, 50.0027825]],
    [ "Station Eving",[7.4552549, 51.542826]],
    [ "Union",[7.4357479, 51.518779]],
    [ "Station Ratsbusch",[7.4999682, 51.5346148]],
    [ "Velten",[13.2006763, 52.6940752]],
    [ "Umspannwerk Rubi",[10.2897195, 47.4381787]],
    [ "Übernahmestelle Göggingen;Umspannwerk Göggingen",[10.879623, 48.3257677]],
    [ "Station Büscherhof",[6.9236597, 51.4976616]],
    [ "Umspannwerk Reinickendorf",[13.3306326, 52.5630872]],
    [ "Oldenburg Ost",[8.2474395, 53.1388983]],
    [ "Nordhausen-Salza",[10.7703163, 51.5194262]],
    [ "Umspannwerk Pleinting",[13.1005612, 48.6691577]],
    [ "Bäumenheim",[10.8264151, 48.6789906]],
    [ "Wenden",[10.4971927, 52.3239318]],
    [ "Paderborn-West",[8.7199383, 51.7155756]],
    [ "Styrum",[6.8731763, 51.4454612]],
    [ "Station Friedrich-Wilhelms-Hütte",[6.8639369, 51.4400842]],
    [ "Station Saarn",[6.8599356, 51.4038676]],
    [ "Station Wanne",[7.1391839, 51.5574314]],
    [ "Station Gelsenkirchen",[7.0744572, 51.5191361]],
    [ "Schalke",[7.0915224, 51.5255523]],
    [ "Station Selbeck",[6.8664341, 51.374747]],
    [ "Station Rath",[6.8089464, 51.2680757]],
    [ "Station Herten",[7.105132, 51.6008369]],
    [ "Station Gelsenberg",[7.0358531, 51.5415518]],
    [ "Bahrenfeld",[9.9194291, 53.5614482]],
    [ "Station Hösel",[6.9234895, 51.3321255]],
    [ "Station Speldorf",[6.8494291, 51.4268028]],
    [ "Station Ellinghorst",[6.9313369, 51.5653794]],
    [ "Schaltwerk Kirchhellen",[6.9524813, 51.6324622]],
    [ "Umspannwerk Weixdorf",[13.7935513, 51.1578518]],
    [ "Unterwerk Frankfurt-Rödelheim",[8.5966358, 50.1417536]],
    [ "Umspannwerk Böckingen",[9.1766327, 49.1338809]],
    [ "Minden/West",[8.8859408, 52.2863991]],
    [ "Umspannwerk Schmittenberg",[10.1748235, 48.6829636]],
    [ "Umspannwerk Höningen",[6.9329342, 50.8840136]],
    [ "Umspannwerk Parsberg",[11.7355339, 49.15906]],
    [ "Station Eisenwerk",[6.9165508, 50.8431721]],
    [ "RWENet Station Würselen, enwor Unterwerk Neustraße",[6.1275096, 50.8328287]],
    [ "Umspannwerk Herbertingen",[9.4290222, 48.0636198]],
    [ "Kummerfeld",[9.7764893, 53.6897496]],
    [ "Büdingen",[9.0913621, 50.2849078]],
    [ "Umspannanlage Neuenheim",[8.6735537, 49.4224453]],
    [ "Unterwerk Gerresheim",[6.8361147, 51.2218692]],
    [ "Zentrales Bahnstromumformerwerk Dresden-Niedersedlitz",[13.8333422, 50.9943378]],
    [ "Umspannwerk Großholz",[9.0960971, 48.5211589]],
    [ "Umspannanlage Zukunft",[6.2982011, 50.830112]],
    [ "Umspannwerk Nasbach",[11.0276646, 49.3417766]],
    [ "Station Borbeck",[6.9472613, 51.4983411]],
    [ "Station Frintrop",[6.9221296, 51.473615]],
    [ "Umspannwerk Memmingen",[10.1939621, 47.9701057]],
    [ "Station Roxel",[7.5465117, 51.9693893]],
    [ "Umspannwerk Marke",[12.261608, 51.7425763]],
    [ "Umspannwerk West",[9.1533472, 48.4951776]],
    [ "Westerbach",[8.5753935, 50.1359903]],
    [ "Station Rheinbach",[6.9560187, 50.6285189]],
    [ "Umspannwerk Budenheim",[8.1869517, 50.0311534]],
    [ "Unterwerk Donauwörth",[10.7616089, 48.7166714]],
    [ "Wedel/Nord",[9.7121606, 53.5942178]],
    [ "Hamburg/West",[9.8689568, 53.5820315]],
    [ "Umspannwerk Knittlingen",[8.7556982, 49.0190591]],
    [ "Vorsfelde",[10.8258493, 52.4578144]],
    [ "Klosterwalde",[13.5537464, 53.1743815]],
    [ "Neuhof",[10.5761405, 51.5846923]],
    [ "Pumpspeicherwerk Goldisthal",[11.0047265, 50.5076619]],
    [ "Transnet BW Umspannwerk Hoheneck",[9.2000547, 48.9135806]],
    [ "Station Schermbeck",[6.8568833, 51.6797366]],
    [ "Amprion Umspannwerk Hoheneck",[9.1885451, 48.9137803]],
    [ "Umspannwerk Bernau",[13.5642223, 52.6693062]],
    [ "Umspannwerk Rothenburg",[10.1989712, 49.3728782]],
    [ "Umspannwerk Wolgast",[13.7568168, 54.0498966]],
    [ "Elsen",[8.7029458, 51.7360487]],
    [ "Umspannwerk Bad Lippspringe",[8.8359359, 51.7882053]],
    [ "Umspannwerk Talle",[8.7629534, 51.7524889]],
    [ "Siersdorf",[6.2141545, 50.9128829]],
    [ "Magdeburg/Diesdorf",[11.5742284, 52.1282244]],
    [ "Delmenhorst-Süd",[8.6349687, 53.0231389]],
    [ "Umspannwerk Ottenhofen",[11.86658, 48.2077926]],
    [ "S-Bahn Gleichrichterunterwerk Markgrafendamm",[13.4665061, 52.5032337]],
    [ "Umspannwerk Wendlingen",[9.3970958, 48.6672233]],
    [ "Station Rosenblumendelle",[6.948823, 51.4452867]],
    [ "Unterwerk Vaihingen (Enz)",[8.9656489, 48.9434015]],
    [ "Umspannwerk Bamberg-Nord",[10.9049391, 49.9176315]],
    [ "Netze BW Umspannwerk Göppingen Süd",[9.6595225, 48.6948772]],
    [ "Unterwerk Priort",[12.9737266, 52.540317]],
    [ "Station Gütersloh",[8.3618673, 51.9280196]],
    [ "Netze BW Umspannwerk Bünzwangen",[9.5631602, 48.7020848]],
    [ "Umspannwerk Laichingen",[9.7036295, 48.4655024]],
    [ "Bad Salzungen",[10.2218727, 50.8007364]],
    [ "Burgwedel",[9.8868771, 52.4949707]],
    [ "Voslapp",[8.0847425, 53.6050499]],
    [ "Rüstersiel",[8.1272911, 53.5517882]],
    [ "Kabelgarten Schwärzloch",[9.0217159, 48.5158065]],
    [ "Umspannwerk Wolmirstedt",[11.6376408, 52.269953]],
    [ "Umspannwerk Helmstedt",[10.9970552, 52.2005373]],
    [ "Helmstedt",[11.0028264, 52.1953612]],
    [ "Umspannwerk Potsdamer Platz",[13.3787408, 52.5077466]],
    [ "Stelle",[10.0944777, 53.3957467]],
    [ "Umspannwerk Jöllenbeck",[8.5182292, 52.1057549]],
    [ "Zirgesheim",[10.8028506, 48.7251598]],
    [ "Station Tarforst",[6.6936017, 49.7456142]],
    [ "Umspannwerk Holzkirchen",[11.6904951, 47.8662614]],
    [ "Magdeburg",[11.562467, 52.1248729]],
    [ "Unterwerk Weiterstadt",[8.5737669, 49.9113147]],
    [ "Nieder Wöllstadt",[8.7655628, 50.2792184]],
    [ "Station Oberzier",[6.4563162, 50.8698311]],
    [ "Station Hambach",[6.4879885, 50.8821225]],
    [ "Umspannwerk Bielefeld Ost",[8.6271883, 51.9937126]],
    [ "Umspannwerk",[14.9076751, 51.2317598]],
    [ "Breitungen",[10.3205898, 50.7579653]],
    [ "Schmalkalden",[10.4620427, 50.7323558]],
    [ "Station Rietberg",[8.4275333, 51.8201832]],
    [ "Umspannwerk Stukenbrock West",[8.6411028, 51.8968168]],
    [ "Station Witterschlick",[7.0072863, 50.6882378]],
    [ "Station Merl",[7.0448588, 50.6315688]],
    [ "Ellenberg",[9.9614416, 54.666436]],
    [ "Unterwerk Holzkirchen",[11.6988461, 47.8838022]],
    [ "Umspannanlage Annen",[7.3580057, 51.4471158]],
    [ "Großbeeren",[13.263918, 52.3444112]],
    [ "Station Ließem",[7.1372179, 50.6434386]],
    [ "Umspannwerk Oberjettingen",[8.7848262, 48.5824029]],
    [ "Station Mehlem",[7.1839673, 50.6648722]],
    [ "Umspannwerk Schwaig",[11.2009451, 49.4666714]],
    [ "Station Paffendorf",[6.5930926, 50.9521422]],
    [ "Umspannwerk Senne I",[8.5473473, 51.9486747]],
    [ "Umspannwerk Sennestadt",[8.5916553, 51.9604007]],
    [ "Umspannwerk Haunstetten",[10.9013072, 48.3212549]],
    [ "Umspannwerk Karlsfeld/West",[11.4345805, 48.2167729]],
    [ "Umspannwerk Amalienhof",[13.1810092, 52.5238221]],
    [ "Station Lennep",[7.2539589, 51.1883701]],
    [ "Bahnstrom-Unterwerk Plochingen",[9.392163, 48.7193195]],
    [ "Umspannwerk Stendal",[11.8316308, 52.5857091]],
    [ "Station Kalstert",[6.9583425, 51.1652069]],
    [ "Kiel-Süd",[10.1545813, 54.2756342]],
    [ "Umspannwerk Bayreuth Mitte",[11.5654138, 49.9498217]],
    [ "Station Greven",[7.6005869, 52.0851282]],
    [ "Umspannwerk Wustermark",[12.9417381, 52.5645832]],
    [ "Umspannwerk Gesundbrunnen",[13.3832739, 52.548679]],
    [ "UW Zwinger",[8.5278272, 52.0239196]],
    [ "UW Tangerhütte (alt)",[11.7902293, 52.429233]],
    [ "Umspannwerk Aicha v.W.",[13.2894891, 48.6838523]],
    [ "Unterwerk Geisenbrunn",[11.3313813, 48.1083319]],
    [ "Umspannstation Irsingen",[10.6406226, 48.0280743]],
    [ "Braunschweig-West",[10.4624541, 52.2812865]],
    [ "Umspannwerk Lichterfelde",[13.3097935, 52.4240701]],
    [ "Station Heligenhaus",[6.9785766, 51.3241684]],
    [ "Umspannwerk Wittenau",[13.3069431, 52.5816401]],
    [ "Schaltwerk Nenndorf",[9.903609, 53.3768341]],
    [ "Nenndorf",[9.9055172, 53.3768572]],
    [ "Station Herkules",[7.0230312, 51.4564836]],
    [ "Neuenland",[8.7834767, 53.0633726]],
    [ "Umspannwerk Gültstein",[8.8961135, 48.5773375]],
    [ "Umspannwerk Esslingen Ost",[9.3196456, 48.7296803]],
    [ "Umspannwerk Bamberg-Süd",[10.9271477, 49.8812343]],
    [ "Umspannwerk Isarau",[11.6547017, 48.2022739]],
    [ "Umspannwerk Neumarkt",[11.4582698, 49.2665157]],
    [ "Minden/Süd",[8.8668491, 52.2677175]],
    [ "Umspannwerk Steglitz",[13.3281394, 52.4439992]],
    [ "Hohen Neuendorf",[13.2719424, 52.662565]],
    [ "Unterwerk Solpke",[11.2949147, 52.5008439]],
    [ "Unterwerk Frankfurt-Höchst",[8.5567782, 50.1036568]],
    [ "110kV-Umspannwerk Merklingen",[8.830628, 48.7738474]],
    [ "Netze BW Umspannwerk Hattenhofen",[9.5535005, 48.6655055]],
    [ "Umspannwerk Weilheim/Teck",[9.5473302, 48.6251631]],
    [ "Umspannwerk Bleidenstadt",[8.1354698, 50.144348]],
    [ "Umspannwerk Happurg",[11.4752936, 49.487481]],
    [ "Schaltwerk Nitzahn",[12.346026, 52.46005]],
    [ "Kiel West",[10.0593679, 54.3492604]],
    [ "Unterwerk Wunstorf",[9.4821012, 52.4156011]],
    [ "Umspannwerk Höchstadt an der Aisch",[10.8243178, 49.7074549]],
    [ "Unterwerk Genshagener Heide",[13.277707, 52.3353442]],
    [ "VUW IWL 110 kV",[13.270052, 52.3317052]],
    [ "Potsdam Süd",[13.1140389, 52.3646847]],
    [ "Nienburg/Ost",[9.2408981, 52.6359326]],
    [ "Landesbergen",[9.12303, 52.536342]],
    [ "Rüdersdorf",[13.7877005, 52.4901473]],
    [ "Umspannanlage Geislautern",[6.8384266, 49.2350038]],
    [ "Unterwerk Magdeburg",[11.660067, 52.15381]],
    [ "Valdorf",[8.8162357, 52.1356625]],
    [ "Springborn",[7.0218874, 50.9756782]],
    [ "Leese",[9.1132275, 52.5132184]],
    [ "Obererlenbach",[8.6922115, 50.2279817]],
    [ "Burgdorf",[10.0264006, 52.4499354]],
    [ "Geltow",[12.9788554, 52.3638509]],
    [ "Station Dünnwald",[7.0216535, 50.9996853]],
    [ "Hennigsdorf",[13.2143782, 52.6423123]],
    [ "Station Gohrpunkt",[6.7126168, 51.0897005]],
    [ "Rehburg",[9.19892, 52.467813]],
    [ "Umspannwerk Hänigsen",[10.1100522, 52.4741482]],
    [ "Königslutter",[10.8056222, 52.2568412]],
    [ "Hamburg Nord",[9.990223, 53.7669478]],
    [ "Umspannwerk Elsenfeld",[9.1620769, 49.8342176]],
    [ "Blumenau",[9.46072, 52.433269]],
    [ "Garbsen",[9.5846893, 52.4255066]],
    [ "Unterwerk Duisburg",[6.7925526, 51.4134512]],
    [ "Büren",[9.4929332, 52.5988485]],
    [ "Unterwerk Heeren",[11.8674395, 52.5745201]],
    [ "Unterwerk Güsen",[11.9781675, 52.3355185]],
    [ "Neustadt am Rübenberge",[9.4551877, 52.494927]],
    [ "Umspannwerk Genthin",[12.1318243, 52.4311414]],
    [ "Dietfurt",[11.5621888, 49.0346668]],
    [ "Umspannwerk Dießen",[11.1048281, 47.9347255]],
    [ "Umspannwerk Hartenstein",[11.5142463, 49.5994974]],
    [ "VW Salzgitter",[10.4501986, 52.1857958]],
    [ "Wolfenbüttel Ost",[10.5711041, 52.1644673]],
    [ "Deuz Waldstrasse",[8.1310511, 50.8851117]],
    [ "Bostel",[10.1297995, 52.647024]],
    [ "Oldau",[9.9336574, 52.658171]],
    [ "Unterwerk Garßen",[10.1288538, 52.674754]],
    [ "Umspannwerk Friedersdorf",[14.549456, 51.0356842]],
    [ "Wülferode",[9.84544, 52.323895]],
    [ "Hallendorf",[10.368454, 52.168524]],
    [ "Vahlhausen",[8.9304485, 51.9426785]],
    [ "Unterwerk Eystrup",[9.2290278, 52.7926476]],
    [ "Unterwerk Nörten-Hardenberg",[9.937513, 51.6395815]],
    [ "Unterwerk Uelzen",[10.5438046, 52.9635266]],
    [ "Umspannwerk Puchheim",[11.340862, 48.1694265]],
    [ "Bahnstrom Unterwerk Karow",[13.4586819, 52.6078807]],
    [ "Umspannwerk Wöhrder Tor",[11.0883327, 49.4558308]],
    [ "Umspannwerk Lüneburg",[10.3796338, 53.2204364]],
    [ "Schwarzenbach Seeuferstr",[8.3102274, 48.6626122]],
    [ "Stadorf",[10.3865503, 52.9960061]],
    [ "Stadorf",[10.3824368, 52.9917415]],
    [ "Unterwerk Almstedt",[9.940841, 52.031453]],
    [ "Umspannanlage Süd",[8.6353887, 49.847201]],
    [ "Umspannwerk Ebermannstadt",[11.169031, 49.7698396]],
    [ "Umspannwerk Enzer Straße",[9.1780136, 52.3244821]],
    [ "Wulpke",[9.0233959, 52.242511]],
    [ "Veltheim",[8.9321154, 52.1941203]],
    [ "Unterwerk Ritterhude",[8.7629128, 53.1934222]],
    [ "Schwanewede",[8.5837895, 53.2307493]],
    [ "Grambke",[8.7151062, 53.1384264]],
    [ "Mittelsbüren",[8.6791685, 53.1305461]],
    [ "Bahnstrom Unterwerk Boizenburg",[10.7328799, 53.3918496]],
    [ "Umspannwerk Korbach",[8.8672674, 51.2849804]],
    [ "UW4",[7.0220038, 51.3467918]],
    [ "Station Sonsbeck",[6.376841, 51.6020997]],
    [ "Unterwerk Buchholz",[9.8620231, 53.3224821]],
    [ "Rastede",[8.1837179, 53.2590592]],
    [ "Station Frechen",[6.8289465, 50.9146307]],
    [ "Umrichterwerk Düsseldorf",[6.7302336, 51.2197769]],
    [ "Umspannwerk Unterschleißheim",[11.5576324, 48.2829627]],
    [ "Bahnstrom Unterwerk Röhrmoos",[11.4468082, 48.3237608]],
    [ "Station Pöppinghausen",[7.2603852, 51.5745701]],
    [ "Umspannwerk Allendorf/Nord",[9.0254442, 50.8377623]],
    [ "Umspannwerk Allendorf/Süd",[8.9976813, 50.8159784]],
    [ "Kirchhahn",[8.9395412, 50.81905]],
    [ "EnBW Umspannwerk Reuterstraße",[9.1462809, 48.7744246]],
    [ "Verteilungswerk Karoline",[9.9752444, 53.5609381]],
    [ "Unterwerk Pulling",[11.7142525, 48.3702908]],
    [ "Unterwerk Markt Bibart",[10.4229468, 49.6468807]],
    [ "Umspannanlage Markt Bibart",[10.4439654, 49.6557912]],
    [ "Station Wülfrath",[7.0520231, 51.2868449]],
    [ "Umspannwerk Holzhauser Straße",[13.3068687, 52.5821568]],
    [ "Umspannwerk Bierde",[9.0242566, 52.3660364]],
    [ "Umspannwerk Kutenhasuen",[8.9089086, 52.324593]],
    [ "Umspannwerk Erlangen",[10.9866422, 49.5854046]],
    [ "Umspannwerk Kriegenbrunn",[10.9713801, 49.5576212]],
    [ "Station Letmathe",[7.5870168, 51.364267]],
    [ "Umspannwerk Brietlingen",[10.4640859, 53.3349885]],
    [ "Unterwerk Fronhausen",[8.6985556, 50.6981807]],
    [ "Neuland",[10.0025801, 53.4511671]],
    [ "Bahnstrom Umformerwerk Harburg",[10.0026949, 53.4485621]],
    [ "Bonn-Nord (Bonn-Netz GmbH)",[7.062785, 50.7542136]],
    [ "Station Endenich",[7.07608, 50.7356638]],
    [ "Station Beuel",[7.1459521, 50.7369966]],
    [ "Umspannwerk Kastenweiher",[10.9526857, 49.5815349]],
    [ "UW Schönewalde",[13.158214, 51.8271261]],
    [ "Station Schwadorf",[6.9159864, 50.7997344]],
    [ "Station Vochem",[6.8993133, 50.8443319]],
    [ "Umspannwerk Marzahn",[13.5236521, 52.5357178]],
    [ "Bahnstrom Unterwerk Lüneburg",[10.411359, 53.267302]],
    [ "Unterwerk Golm",[12.9687966, 52.4028878]],
    [ "Unterwerk Kirchmöser",[12.4096877, 52.3826185]],
    [ "Unterwerk Osnabrück",[8.1168098, 52.2654885]],
    [ "Station Duisdorf",[7.0454988, 50.7054123]],
    [ "Wechold",[9.1465507, 52.8532799]],
    [ "Westendorf",[9.1445716, 52.205302]],
    [ "Unterwerk Kreiensen",[9.9692097, 51.8486904]],
    [ "Unterwerk Köln-Mülheim",[7.0184901, 50.972906]],
    [ "Schaltstation Stadtmitte",[13.0525571, 52.3997624]],
    [ "Unterwerk Pasing",[11.4436569, 48.1526808]],
    [ "Unterwerk Leer",[7.4644381, 53.2138935]],
    [ "Unterwerk Braunschweig",[10.6396747, 52.2693911]],
    [ "Unterwerk Wächtersbach",[9.2886696, 50.2458983]],
    [ "Umspannwerk",[8.6579379, 52.1958995]],
    [ "Umspannwerk Kraftwerk Robert Frank",[9.1106565, 52.5430968]],
    [ "Afferde 110/30/6 kV",[9.3899479, 52.1001718]],
    [ "Schwandorf",[12.0821318, 49.3000136]],
    [ "Umspannwerk Rosenberg",[11.7765191, 49.4950733]],
    [ "Umspannwek Luitpoldhütte",[11.841229, 49.4611588]],
    [ "Umspannwerk Amberg",[11.8690119, 49.4254179]],
    [ "Umspannwerk Janahof",[12.6713864, 49.1985417]],
    [ "Umspannwerk Pufendorfstraße",[13.4379537, 52.5218742]],
    [ "Unterwerk Burgweinting",[12.1539566, 48.9825855]],
    [ "Umspannwerk Türkenfeld",[11.1009526, 48.1158963]],
    [ "Umspannwerk Neutraubling",[12.2055089, 48.9949594]],
    [ "Umspannwerk Nittenau",[12.2823341, 49.210295]],
    [ "Umspannwerk Hörde",[7.5153702, 51.485401]],
    [ "Umspannwerk Ponholz",[12.0972244, 49.1779776]],
    [ "Umspannwerk Buch",[13.4681159, 52.6325777]],
    [ "Umspannwerk Rehau",[12.0394653, 50.2542599]],
    [ "Umspannwerk Papenburg",[7.3808906, 53.0718176]],
    [ "Lathen",[7.3286935, 52.8672576]],
    [ "Unterwerk Haren",[7.3011446, 52.7844436]],
    [ "Unterwerk Löhne",[8.7106121, 52.1950776]],
    [ "Umspannwerk Bustedt",[8.5908407, 52.1695064]],
    [ "Umspannwerk Kusenhorst",[7.0528564, 51.6955213]],
    [ "Umrichterwerk Adamsdorf",[13.044945, 53.4092604]],
    [ "Umspannwerk Neustrelitz",[13.0425984, 53.4066474]],
    [ "Umspannwerk Etzenricht",[12.1176735, 49.6319129]],
    [ "Halstenbek",[9.8672622, 53.629734]],
    [ "Umspannwerk Siechen",[12.6774394, 49.2256837]],
    [ "Umspannwerk Delhoven",[6.7780209, 51.0850845]],
    [ "Umspannwerk Torgau",[12.9873607, 51.576647]],
    [ "UW Mühlstraße",[9.3339568, 48.6284012]],
    [ "Umspannwerk Pegnitz",[11.5444087, 49.7678062]],
    [ "ST Ellenbügel",[9.0542895, 50.1788743]],
    [ "Herne",[7.180062, 51.5482789]],
    [ "Neuhof",[13.3466534, 53.0087577]],
    [ "Umspannwerk Mecklar 380kV",[9.747392, 50.9175953]],
    [ "Bahnstrom Umrichterwerk Thyrow (alt)",[13.3035144, 52.2339324]],
    [ "Umspannwerk Thyrow",[13.3046153, 52.2303022]],
    [ "Ummeln",[8.4481349, 51.986184]],
    [ "Rötz",[12.5181483, 49.3374468]],
    [ "Uetersen-West",[9.6497475, 53.6907462]],
    [ "Unterwerk Elmshorn",[9.6547995, 53.7630132]],
    [ "Umspannwerk Roding",[12.5327638, 49.2175723]],
    [ "Quickborn-Ost",[9.9379141, 53.7436032]],
    [ "Steinburg",[9.5788115, 53.8449368]],
    [ "Itzehoe-Süd",[9.4997515, 53.9109245]],
    [ "Stade",[9.5273717, 53.6203314]],
    [ "Umspannwerk Waldmünchen",[12.6939156, 49.3702965]],
    [ "Umformerwerk Köln",[7.0476315, 50.9045663]],
    [ "Umspannwerk Gremberghoven",[7.0476969, 50.9035567]],
    [ "Station Gremberghoven",[7.0476693, 50.9046593]],
    [ "Umspannwerk Vilseck/Reisach",[11.7626703, 49.6080359]],
    [ "Umformerwerk Bützow",[11.9824084, 53.8244705]],
    [ "Umformwerk Schwerin",[11.386256, 53.595072]],
    [ "Umspannwerk Lüdershagen",[13.0798982, 54.2851475]],
    [ "Bahnstrom Umformwerk Stralsund",[13.0906933, 54.2866867]],
    [ "Umformerwerk Prenzlau",[13.8735439, 53.3334307]],
    [ "Umspannwerk Furth im Wald",[12.8234453, 49.3104979]],
    [ "Apolda",[11.5095928, 51.0390832]],
    [ "Umspannwerk Rauberweiherhaus",[12.2110197, 49.3269862]],
    [ "Umspannwerk Regensburg",[12.1494436, 49.0135193]],
    [ "Umspannwerk Sünching",[12.355915, 48.8877224]],
    [ "Inhausen",[8.060195, 53.6209567]],
    [ "Station Prangenhaus",[7.0240164, 51.2907785]],
    [ "Unterwerk Marl",[7.1796402, 51.6619966]],
    [ "Station Sinsen",[7.1610835, 51.6745625]],
    [ "Station Blumenthal",[7.207979, 51.6266344]],
    [ "Station Coesfeld",[7.1945286, 51.9596476]],
    [ "Station Dülmen",[7.3077451, 51.8354918]],
    [ "Station Lüdinghausen",[7.4255289, 51.7706325]],
    [ "Station Selm",[7.4891911, 51.6935094]],
    [ "Wulfen",[7.047966, 51.7162675]],
    [ "Station Groß-Reken",[7.0506896, 51.8218757]],
    [ "Station Gronau",[7.0353436, 52.2037846]],
    [ "Hervest-Dorsten",[6.9981481, 51.6775313]],
    [ "Station Lembeck",[6.9566287, 51.7505325]],
    [ "Station Stadtlohn",[6.917182, 51.9873898]],
    [ "Station Borken",[6.8803112, 51.8400087]],
    [ "Station Ibbenbüren",[7.7287727, 52.276548]],
    [ "Bahnstrom Unterwerk Weimar",[11.3432744, 50.9912887]],
    [ "Bahnstrom Unterwerk Gößnitz",[12.4338415, 50.9025725]],
    [ "Bahnstrom Unterwerk Großkorbetha",[12.0198343, 51.2534348]],
    [ "Unterwerk Saalfeld",[11.3764008, 50.6447789]],
    [ "Unterwerk Eisenach",[10.3723333, 50.9639747]],
    [ "Umspannwerk Schönebeck",[11.7100135, 52.0211617]],
    [ "Landau",[8.1237669, 49.2112346]],
    [ "Oldenburg West",[8.1555763, 53.1310385]],
    [ "Leihgestern",[8.6624153, 50.5251852]],
    [ "Umspannwerk Lauchstädt",[11.8917612, 51.4032117]],
    [ "Station Mannstaedt",[7.1735902, 50.805827]],
    [ "Unterwerk Fulda",[9.691646, 50.5441767]],
    [ "Umspannwerk Wahn",[7.0927475, 50.855097]],
    [ "Unterwerk Körle",[9.5296208, 51.1810552]],
    [ "Unterwerk Augsburg",[10.866235, 48.3989273]],
    [ "Unterwerk München Ost",[11.6505509, 48.1376977]],
    [ "Gleidingen 220 kV",[10.4182607, 52.2364303]],
    [ "Wechloy",[8.16953, 53.1535486]],
    [ "Osterhagen",[8.7041801, 53.1899532]],
    [ "Nettlingen",[10.1698004, 52.1575664]],
    [ "Umspannwerk Oberwallenstadt",[11.0890479, 50.1522888]],
    [ "Umspannwerk Bertikow",[13.9548791, 53.2527319]],
    [ "Umspannwerk Görries",[11.3728014, 53.6013875]],
    [ "Umspannwerk Kinding",[11.3796429, 48.9980125]],
    [ "Umspannwerk Großhöbing",[11.2915142, 49.0758055]],
    [ "Umspannwerk Auerbach",[11.6464805, 49.6748326]],
    [ "Schaltwerk",[10.4136882, 53.4141222]],
    [ "Unterwerk Gemünden",[9.6768996, 50.0599187]],
    [ "Umspannwerk Kirchheim Süd",[9.4425012, 48.6400385]],
    [ "UW Bayreuth Süd",[11.5673315, 49.914776]],
    [ "Umspannwerk Mitterteich",[12.2293016, 49.9384114]],
    [ "Kraftwerk Bittenbrunn",[11.1441054, 48.735299]],
    [ "Umspannwerk Wiesau",[12.2043747, 49.8957066]],
    [ "Umspannwerk Windischeschenbach",[12.1535032, 49.8065699]],
    [ "Sarstedt",[9.883039, 52.2405466]],
    [ "Godenau",[9.7978955, 51.998945]],
    [ "Umspannwerk Oberhavel",[13.2123936, 52.5790129]],
    [ "Umspannwerk Teufelsbruch",[13.2122601, 52.5804007]],
    [ "Umspannwerk Mainz-Bretzenheim",[8.2191672, 49.9670866]],
    [ "Blumberg",[13.5968629, 52.6100498]],
    [ "Unterwerk Neumarkt",[11.4630821, 49.2683568]],
    [ "Umspannwerk Etting",[11.4030999, 48.7947503]],
    [ "Kraftwerk Ingolstadt",[11.412137, 48.7514939]],
    [ "Umspannwerk Graustein",[14.4739676, 51.5524308]],
    [ "Umspannwerk Saarstraße",[8.2062419, 49.9878518]],
    [ "Umspannwerk Waldsassen",[12.2778181, 50.0037139]],
    [ "Umspannwerk Rottendorf",[10.0144819, 49.7913104]],
    [ "Umspannwerk Mitte",[13.3671664, 52.5028712]],
    [ "Falkensee",[13.0977437, 52.5882589]],
    [ "Hennigsdorf",[13.206852, 52.6579341]],
    [ "Blomberg",[9.090603, 51.9312956]],
    [ "Eilendorf",[9.7107138, 53.4589108]],
    [ "Umspannwerk Kranzberg",[11.5979441, 48.4142371]],
    [ "Gehrden",[9.595914, 52.283469]],
    [ "Barsinghausen",[9.4914287, 52.3176147]],
    [ "Berne",[8.4920202, 53.1884489]],
    [ "Hude",[8.4767601, 53.1071127]],
    [ "Umspannwerk Burgstall",[10.8785764, 49.5547547]],
    [ "Umspannwerk Oberottmarshausen",[10.8433798, 48.2388605]],
    [ "Umspannwerk Kernkraftwerk Brunsbüttel",[9.1987592, 53.8954297]],
    [ "Umspannwerk Stechendorf",[11.3108727, 49.9032857]],
    [ "Umspannwerk Freising/Nord",[11.7644856, 48.4148571]],
    [ "Edemissen",[10.1542144, 52.3821955]],
    [ "Oelerse",[10.1548468, 52.3825657]],
    [ "Station Sankt Augustin",[7.1964444, 50.7717456]],
    [ "Umspannwerk Neckartenzlingen",[9.2290308, 48.5829638]],
    [ "Umspannwerk Metzingen",[9.2735204, 48.5489092]],
    [ "Netze BW Umspannwerk Reichenbach",[9.474836, 48.7078198]],
    [ "Bergen auf Rügen",[13.439945, 54.4087076]],
    [ "Station Lirich",[6.839673, 51.4859782]],
    [ "Silenz",[13.3007827, 54.4751245]],
    [ "Umspannwerk Konstanz-Wollmatingen",[9.1277993, 47.7010594]],
    [ "Gustebin",[13.6132148, 54.0890891]],
    [ "Umspannwerk Weißenburg",[10.9646883, 49.0337859]],
    [ "Rathenow",[12.3570556, 52.6017387]],
    [ "Anklam",[13.662358, 53.8526404]],
    [ "Umspannstation Böblingen-West",[8.9951944, 48.6833101]],
    [ "Umspannwerk Friedrichshain",[13.4552916, 52.5212724]],
    [ "Hildesheim Süd",[9.9362651, 52.113129]],
    [ "Greifswald",[13.3820265, 54.0748143]],
    [ "Vöhringen LEW",[10.0830233, 48.2679084]],
    [ "Gundremmingen",[10.401392, 48.5088908]],
    [ "110/20kV UW Essershausen",[8.3218686, 50.457802]],
    [ "Konverter Station Fedderwarden",[8.067789, 53.5588818]],
    [ "Knieper",[13.0389537, 54.3144979]],
    [ "110kV-Umspannwerk Biberach/Riß",[9.8029075, 48.1098515]],
    [ "Umspannwerk Grimmen",[13.0419265, 54.1247535]],
    [ "Umspannwerk Jarmen",[13.3425649, 53.9099948]],
    [ "Nielitz",[13.0775882, 54.0300759]],
    [ "Umspannwerk Niederstotzingen",[10.2322208, 48.5316879]],
    [ "110kV-Umspannwerk Riedlingen",[9.5039497, 48.1593647]],
    [ "110kV-Umspannwerk Otterswang",[9.6508505, 47.9811738]],
    [ "110kV-Umspannwerk Saulgau",[9.512068, 48.0031231]],
    [ "Obermooweiler",[9.8009972, 47.6535062]],
    [ "380kV-Umspannwerk Grünkraut",[9.6447939, 47.7455989]],
    [ "110kV-Umspannwerk Biberach-Süd",[9.8042743, 48.0801141]],
    [ "Umspannwerk Heinitz",[7.1157072, 49.3215673]],
    [ "Wiek",[13.2899652, 54.6014958]],
    [ "Unterwerk Hagen",[7.4623605, 51.4095553]],
    [ "Umspannwerk DD-Gorbitz",[13.6488593, 51.0404657]],
    [ "Umspannwerk Leipzig B",[12.4038344, 51.3238211]],
    [ "Umspannwerk Wutzlhofen",[12.1271227, 49.0540713]],
    [ "Umspannwerk Biebesheim",[8.4968121, 49.7843259]],
    [ "Ennigloh",[8.5799786, 52.2110012]],
    [ "Umspannwerk Hammerbacherstraße",[11.0148139, 49.572157]],
    [ "Stockach 220kV",[9.0128887, 47.8452474]],
    [ "Umspannwerk Gurtweil",[8.2506043, 47.6406343]],
    [ "Umspannwerk Tiengen",[8.2568054, 47.6336892]],
    [ "EnBW Umspannwerk Rechberghausen",[9.6570405, 48.7339066]],
    [ "Netze BW Umspannwerk Eislingen Ost",[9.7222352, 48.6946758]],
    [ "Immenreuth",[11.8732983, 49.9002136]],
    [ "Station Urfeld",[7.0045683, 50.8100721]],
    [ "Umspannwerk Weiden",[12.167111, 49.6482641]],
    [ "Station Genna",[7.6066244, 51.3604276]],
    [ "Umspannwerk Stalldorf",[9.945989, 49.5773895]],
    [ "Umspannwerk Kulmbach",[11.4239632, 50.0953278]],
    [ "Varel",[8.1261721, 53.3742045]],
    [ "Umspannwerk Schwann",[8.5544237, 48.8382902]],
    [ "Einfeld",[9.9770978, 54.1178786]],
    [ "Umspannwerk Nord",[9.2310439, 48.5097819]],
    [ "Station Norf",[6.770011, 51.1469973]],
    [ "Umspannwerk Thuisbrunn",[11.2699641, 49.6841474]],
    [ "Hoya",[9.1539265, 52.8181045]],
    [ "Homfeld",[9.004502, 52.8147759]],
    [ "Umspannwerk Nürtingen",[9.3494863, 48.6091418]],
    [ "Düshorn",[9.6427742, 52.8379613]],
    [ "Sulingen",[8.7881383, 52.6729396]],
    [ "Umspannwerk Halle Ost",[12.0199609, 51.4756374]],
    [ "Alfstedt",[9.0671806, 53.5669773]],
    [ "Neuenburg",[7.942498, 53.387891]],
    [ "e-netz Südhessen",[9.0071931, 49.8111613]],
    [ "Rhede",[7.255256, 53.0269886]],
    [ "Rehme",[8.837452, 52.212424]],
    [ "Philippsthal/Industrie",[9.9408757, 50.8519356]],
    [ "Hera",[9.9448528, 50.8454068]],
    [ "Umspannwerk Güstrow",[12.1874944, 53.8140798]],
    [ "Bentwisch HGÜ",[12.2141037, 54.1011713]],
    [ "Unterweser",[8.4740785, 53.4297133]],
    [ "MVV Netze Umspannwerk 5 Holzweg",[8.5611992, 49.4550559]],
    [ "Umspannanlage Wald-Michelbach",[8.8471989, 49.5873068]],
    [ "Station Bollenacker",[6.9645157, 50.8333145]],
    [ "Umspannwerk Shell",[6.9752096, 50.8560552]],
    [ "Umspannwerk Hahnwald",[6.9723798, 50.8654197]],
    [ "220-kV-Umspannwerk Kuppenheim",[8.248027, 48.8474036]],
    [ "Unterwerk Mannheim/Ers",[8.563458, 49.4407478]],
    [ "Umspannwerk Winnenden",[9.3782432, 48.8805488]],
    [ "Elmshorn-Mitte",[9.6387248, 53.7569807]],
    [ "Heede",[9.7697505, 53.7706286]],
    [ "Umspannwerk Heppenheim",[8.6269459, 49.6445181]],
    [ "Abspannwerk Tilsiter Straße",[10.0880169, 53.5913257]],
    [ "Bentwisch",[12.2135569, 54.1106681]],
    [ "Lohhof",[8.8801261, 52.4831693]],
    [ "Station Mülheim",[6.876035, 51.4343601]],
    [ "Netze BW Umspannwerk Uhingen",[9.560384, 48.713778]],
    [ "110kV Umspannwerk Langensteinbach",[8.5006393, 48.9235977]],
    [ "Oberlethe",[8.1640733, 53.078649]],
    [ "Station Hünxe",[6.7746589, 51.6469822]],
    [ "Umspannwerk Birkenau",[8.7111069, 49.5743165]],
    [ "Umspannwerk Grenzallee",[13.4598758, 52.4678838]],
    [ "Station Mengede",[7.3545119, 51.5766058]],
    [ "Umspannwerk Forckenbeck",[13.3071855, 52.4796961]],
    [ "Unterlüß",[10.290107, 52.849775]],
    [ "Windpark Willmersdorf",[13.6703571, 52.6915279]],
    [ "Unterwerk Neumünster",[9.9465851, 54.1094325]],
    [ "Eitze",[9.2672897, 52.9141221]],
    [ "Winsen",[10.2220189, 53.3520334]],
    [ "Umspannanlage Endenich 110kV",[7.0767671, 50.7351113]],
    [ "Oestrich",[7.3535982, 51.5657982]],
    [ "MVV Netze Umspannwerk 3 Käfertal",[8.5233058, 49.5077666]],
    [ "Seelze",[9.5868212, 52.3890659]],
    [ "Umspannwerk Wölsau",[12.1085528, 50.0057492]],
    [ "Umspannwerk Helpup",[8.7080832, 51.9682165]],
    [ "Station Dahlem",[6.5321768, 50.3911158]],
    [ "Umspannwerk Klostermansfeld",[11.515652, 51.5847369]],
    [ "Station Gronauerwald",[7.1295519, 50.97986]],
    [ "Umspannwerk Oberwald",[8.4447403, 48.9871281]],
    [ "Umspannwerk Karlsruhe Ost",[8.450355, 49.0075483]],
    [ "Umspannwerk Berghausen",[8.5192057, 49.0068272]],
    [ "Karlsruhe-West",[8.3484707, 49.0129482]],
    [ "Umspannwerk Neubiberg",[11.6493733, 48.07859]],
    [ "EnBW Umspannwerk Süßen",[9.7693154, 48.682784]],
    [ "Umspannwerk Eula",[12.5155731, 51.1559248]],
    [ "Station Kessenich",[7.1197269, 50.7138356]],
    [ "Butzbach",[8.6822394, 50.4375816]],
    [ "Station Anstel",[6.7084761, 51.0635372]],
    [ "Station Sankt Hülfe",[8.4024957, 52.6077263]],
    [ "Kropp",[9.5265592, 54.4169321]],
    [ "220 kV-Umspannwerk Birkenfeld",[8.6407748, 48.8887561]],
    [ "Unterwerk Neuhof",[13.479801, 52.1342377]],
    [ "KW Niederaußem",[6.6634218, 50.9883554]],
    [ "Klosterfelde",[13.4841719, 52.7856826]],
    [ "Pinneberg-Mitte",[9.7811376, 53.667061]],
    [ "Eickenbeck",[7.6894783, 51.8250115]],
    [ "Bützfleth",[9.4558992, 53.658599]],
    [ "Abbenfleth",[9.4903549, 53.6586875]],
    [ "Umspannwerk und Betriebszentrum Lehrte",[9.9153269, 52.3794068]],
    [ "Oldendorf",[9.2679977, 53.5882022]],
    [ "Umspannwerk Münsingen",[9.4688292, 48.4274249]],
    [ "Station Spich",[7.1452273, 50.8194067]],
    [ "Leimbach",[10.2041426, 50.8131589]],
    [ "Mainsche",[9.0234422, 52.6279771]],
    [ "Umspannwerk Eybacher Tal",[9.8507103, 48.6292291]],
    [ "Umspannstation Windpark Waltersdorf",[12.4122053, 51.0623403]],
    [ "Audorf",[9.727858, 54.291884]],
    [ "Stendal West 380kV",[11.7290964, 52.5697433]],
    [ "Umspannwerk Forchheim",[11.0594246, 49.7103151]],
    [ "Malchin",[12.7824395, 53.735922]],
    [ "Gettorf",[9.9857636, 54.4009194]],
    [ "Umspannwerk Steinheim",[9.0938894, 51.8556915]],
    [ "Umspannwerk Maulhoop",[9.2271723, 52.9288373]],
    [ "Wehrendorf",[8.3066956, 52.3462045]],
    [ "Clausen",[7.1566241, 51.2716949]],
    [ "Umspannwerk Berne",[10.1249294, 53.613668]],
    [ "Gleichrichterwerk Eidelstedt",[9.9031467, 53.5982288]],
    [ "Umspannwerk Eidelstedt",[9.9055525, 53.597613]],
    [ "Umspannwerk Hirschloh",[12.9272407, 47.7735103]],
    [ "Station Aldekerk",[6.4071846, 51.4387933]],
    [ "Umspannwerk Schutow",[12.0620935, 54.1051275]],
    [ "Station Ruhrau",[7.0863704, 51.4436566]],
    [ "Station Monreberg",[6.3002293, 51.7303695]],
    [ "Trent",[10.3633979, 54.2016009]],
    [ "Umspannwerk Lauf-West",[11.2721816, 49.5061208]],
    [ "Umspannwerk Lützow",[13.3532618, 52.5029973]],
    [ "Höhndorf",[10.3734092, 54.3674952]],
    [ "Umspannwerk Lunder Straße",[13.4196686, 52.5560989]],
    [ "Station Halfeshof",[7.1234523, 51.169925]],
    [ "Umspannwerk Moos",[8.5224109, 48.0483543]],
    [ "Schaltposten Frankfurt(Main)Süd",[8.6896658, 50.1005961]],
    [ "Umspannwerk Markt Schwaben",[11.8457083, 48.1878123]],
    [ "Unterwerk Markt Schwaben",[11.8497533, 48.184823]],
    [ "Barkelsby",[9.8343588, 54.4979315]],
    [ "Umspannwerk Rappenberghalde",[9.0302354, 48.5069813]],
    [ "Unterwerk Ehringhausen",[8.4436154, 51.6639715]],
    [ "Unterwerk Neudietendorf",[10.8939772, 50.9081735]],
    [ "Umspannwerk Vaterstetten",[11.7780663, 48.1127554]],
    [ "Umspannwerk Schneeberg Nord",[12.6380778, 50.6090334]],
    [ "Umspannwerk Vieselbach",[11.1201117, 51.0003814]],
    [ "Umspannwerk Potzham",[11.6187065, 48.0319423]],
    [ "Station Xanten",[6.4396745, 51.6616244]],
    [ "Station Oberaußem",[6.692342, 50.9749017]],
    [ "Umspannwerk Roßla",[11.0528817, 51.4623754]],
    [ "Kuppelstelle Roßla",[11.0519536, 51.4607675]],
    [ "Station Bedburg",[6.5550519, 50.9955225]],
    [ "Umspannwerk Kulmbach-Blaich",[11.4631043, 50.1136339]],
    [ "Etzin",[12.8755576, 52.5281332]],
    [ "Schwarzenbach an der Saale",[11.9461943, 50.2249602]],
    [ "Stromübergabepunkt Rasselstein",[7.4604708, 50.4472551]],
    [ "Station Katharina",[7.0580838, 51.4547784]],
    [ "Umspannwerk Rauhenzell",[10.2343557, 47.5579345]],
    [ "Brake",[8.4715486, 53.3266906]],
    [ "Umspannwerk Neuhaus am Rennweg",[11.1429394, 50.5199866]],
    [ "Westerstede",[7.936238, 53.2421117]],
    [ "Umspannwerk Elbingerode",[10.8127425, 51.7676176]],
    [ "Moritzburg",[10.5652105, 52.2395845]],
    [ "Springe",[9.5568673, 52.2016396]],
    [ "Station Neuwied",[7.4810788, 50.4242681]],
    [ "Umspannstation Turnow",[14.4319384, 51.8892115]],
    [ "Emden-Borssum",[7.2263543, 53.3496645]],
    [ "Unterwerk Münster",[7.635763, 51.9237081]],
    [ "Unterwerk Salzbergen",[7.3440305, 52.3285832]],
    [ "Umspannwerk Tunsel",[7.661899, 47.914877]],
    [ "Umspannwerk Dachau",[11.454286, 48.2713077]],
    [ "Wolmirstedt 110 Kv",[11.6123248, 52.2550271]],
    [ "Umspannwerk Wansleben",[11.7427369, 51.4652548]],
    [ "Unterwerk Grafing",[11.9362544, 48.0484283]],
    [ "Unterwerk Flörsheim",[8.4138953, 50.0048405]],
    [ "Barleben",[11.6228986, 52.1898182]],
    [ "Vulkan",[8.5990544, 53.1776468]],
    [ "Station Elsen",[6.5735049, 51.0921031]],
    [ "Magdeburg/Nord-West",[11.594414, 52.150665]],
    [ "Umspannwerk Santersleben",[11.4586421, 52.1877982]],
    [ "Station Wermelskirchen",[7.2013303, 51.1332305]],
    [ "Umspannwerk Osterweddingen West",[11.5627168, 52.0548914]],
    [ "Umspannwerk Osterweddingen",[11.570374, 52.038218]],
    [ "Förderstedt 110 kV",[11.6426537, 51.9092209]],
    [ "Schaltwerk Wolkramshausen Süd",[10.7367093, 51.4387353]],
    [ "Unterwerk Oelde",[8.1235565, 51.8254617]],
    [ "Audorf Süd",[9.72622, 54.2884927]],
    [ "Umspannwerk Nehren",[9.058294, 48.4354269]],
    [ "Schaltposten Tutzing",[11.2697308, 47.9024855]],
    [ "Station Zollverein",[7.0433135, 51.4852269]],
    [ "Hammesberg",[7.1721453, 51.1928829]],
    [ "Schaltanlage Birkach",[9.2020737, 48.7283048]],
    [ "Schaltposten Grünauer Kreuz",[13.5617643, 52.4257956]],
    [ "Umspannwerk Achim",[9.0279641, 53.0177778]],
    [ "Unterwerk Bebra",[9.7892215, 50.9759095]],
    [ "Unterwerk Jübek",[9.408364, 54.5573268]],
    [ "Rotenburg (Wümme)",[9.4145381, 53.1221079]],
    [ "Umspannwerk Dresden-Wilschdorf",[13.7142498, 51.1286086]],
    [ "Umspannwerk Staufenbühl",[9.039067, 48.2703111]],
    [ "Umspannwerk Rhinstraße",[13.5227242, 52.5239497]],
    [ "Umspannwerk Nordgraben",[13.3164841, 52.5866979]],
    [ "Umspannwerk Osterburg",[11.7429562, 52.7957853]],
    [ "UW Remscheid",[7.1857477, 51.1762546]],
    [ "Umspannwerk Nordheim",[10.1634194, 50.4858666]],
    [ "Helmstorf",[10.5879944, 54.2880499]],
    [ "Witten",[7.3124131, 51.4458064]],
    [ "Station Witten",[7.3140216, 51.4459016]],
    [ "Bahnstrom Unterwerk Landshut",[12.1096985, 48.5472793]],
    [ "Gießen/Süd",[8.6803541, 50.5664545]],
    [ "Unterwerk Leonberg",[8.9742914, 48.7931635]],
    [ "Station Ruhrort",[6.7310152, 51.4588375]],
    [ "Station Beeck",[6.7195897, 51.4801304]],
    [ "Hamborn 3&4",[6.7276528, 51.4904869]],
    [ "Umspannwerk Schwelgern",[6.7460622, 51.4982586]],
    [ "Schwelgern",[6.7407609, 51.5137053]],
    [ "Umspannwerk 1",[8.4562734, 49.5025743]],
    [ "Umspannwerk Coswig",[13.5820322, 51.1167578]],
    [ "Umspannwerk Pockau",[13.2130073, 50.7073827]],
    [ "Umspannwerk Wörth",[11.9103411, 48.2431898]],
    [ "Umspannwerk Trossingen",[8.6143392, 48.0664332]],
    [ "Umspannwerk Niederstetten",[9.916878, 49.4060703]],
    [ "Zwischenahn",[7.995904, 53.1745251]],
    [ "Arnum",[9.7165917, 52.3075256]],
    [ "Landsham",[11.777793, 48.1771163]],
    [ "Umspannwerk Weilheim/Ost",[11.162646, 47.8327806]],
    [ "Umspannwerk Zwönitz",[12.7878367, 50.6276625]],
    [ "Station Krupp Bunker",[6.987963, 51.464798]],
    [ "Hohenkirchen",[7.9274221, 53.6636427]],
    [ "Burhafe",[7.7153569, 53.597782]],
    [ "Jever",[7.8750579, 53.5561807]],
    [ "Emden West",[7.1714717, 53.35116]],
    [ "Schoneberg",[8.1908576, 51.656845]],
    [ "Mannesmann",[7.2048443, 51.1659328]],
    [ "Umspannwerk Obereschach",[8.4604997, 48.0959896]],
    [ "Böhlitz-Ehrenberg",[12.3017795, 51.3456387]],
    [ "Stolpe",[10.2086915, 54.1332115]],
    [ "Umspannwerk Paderborn Süd",[8.7408204, 51.6989342]],
    [ "Magdeburg/Rothensee",[11.6694421, 52.1875492]],
    [ "Station Kupferdreh",[7.0973871, 51.3772179]],
    [ "Tönniesberg",[9.6985725, 52.3518948]],
    [ "Misburg",[9.8639062, 52.3767376]],
    [ "Godshorn",[9.721925, 52.4483184]],
    [ "Empelde",[9.6588808, 52.3357128]],
    [ "Unterwerk Wiesbaden Ost",[8.2665137, 50.0313486]],
    [ "Stöcken",[9.6496667, 52.4219285]],
    [ "Umspannwerk Esens",[7.6179879, 53.665329]],
    [ "Netze BW Umspannwerk Bonlanden",[9.2150472, 48.6581698]],
    [ "UW Godehardstraße",[9.9283928, 51.538237]],
    [ "Umspannwerk Wulsdorf",[8.6032317, 53.516274]],
    [ "Badenstedt",[9.6891467, 52.3628526]],
    [ "Umspannwerk Lüneburg Nord",[10.4081343, 53.270655]],
    [ "Damsum",[7.5335595, 53.6549036]],
    [ "Westeraccum",[7.4574982, 53.6619212]],
    [ "Norddeich",[7.1626219, 53.6171734]],
    [ "Manslagt",[7.0620309, 53.4603628]],
    [ "Lütetsburg",[7.2348817, 53.5984871]],
    [ "Marienhafe",[7.2966387, 53.5327884]],
    [ "Eilsum",[7.1608659, 53.4823083]],
    [ "Umspannwerk Aurich",[7.465021, 53.4701429]],
    [ "Ihlow",[7.3849149, 53.359526]],
    [ "Umspannwerk Waldhäuser",[9.0554532, 48.5415913]],
    [ "EnBW Umspannwerk Glems",[9.2862671, 48.5048471]],
    [ "Unterwerk Eggolsheim",[11.0573255, 49.7524577]],
    [ "Untereschbach",[7.2145619, 50.9444682]],
    [ "Umspannwerk Schönefeld Nord",[13.489842, 52.3944861]],
    [ "Umspannwerk Roderbruch (Alt)",[9.7963239, 52.3936584]],
    [ "Kabelgarten",[9.2318992, 48.7058945]],
    [ "Sandstraße",[9.7276353, 52.3874642]],
    [ "Visselhövede",[9.5826451, 52.9924034]],
    [ "Umspannwerk Soltau",[9.842673, 53.0040847]],
    [ "Unterwerk Ebensfeld",[10.9610766, 50.0836948]],
    [ "Unterwerk Müllheim",[7.5956278, 47.8058923]],
    [ "Umspannwerk Unterlenningen",[9.4585638, 48.5638845]],
    [ "Wülfel",[9.7801818, 52.3304621]],
    [ "Weener",[7.3599658, 53.1725817]],
    [ "Leer/Mitte",[7.4641096, 53.2221733]],
    [ "Völlen",[7.3823691, 53.0986143]],
    [ "Umspannwerk Röthenbach",[11.2223987, 49.4696729]],
    [ "Station Hamborn",[6.7856566, 51.5060727]],
    [ "Umspannwerk Grünau",[11.2319099, 48.7442533]],
    [ "Christinendorf",[13.28763, 52.210352]],
    [ "Umspannwerk Hoheluft",[9.9782134, 53.5816232]],
    [ "Delbrück",[8.573384, 51.7595735]],
    [ "Salzkotten",[8.6001478, 51.6647819]],
    [ "Umspannwerk DD-Kaditz",[13.6863599, 51.086647]],
    [ "Unterwerk Ingolstadt",[11.4269995, 48.7801356]],
    [ "Umspannwerk Altbach 2",[9.3761482, 48.7204941]],
    [ "Umspannwerk Altbach 1",[9.3713861, 48.7195396]],
    [ "Bad Münder",[9.4362778, 52.1898922]],
    [ "Spieka",[8.5912206, 53.7552213]],
    [ "Apen",[7.7962297, 53.2104098]],
    [ "Umspannwerk Weserzink",[8.499807, 53.509005]],
    [ "Umspannwerk Paderborn-Südost",[8.7746439, 51.7085197]],
    [ "Lüstringen",[8.1025229, 52.263828]],
    [ "Oersdorf",[9.4203419, 53.4180849]],
    [ "Bargstedt",[9.429417, 53.4624576]],
    [ "Unterwerk Siegburg",[7.2081853, 50.7898698]],
    [ "Alfeld",[9.815152, 51.9846615]],
    [ "Langensalza",[10.6261964, 51.1112245]],
    [ "Mühlhausen",[10.4772062, 51.2255678]],
    [ "Umspannwerk Gispersleben",[10.9987928, 51.0186922]],
    [ "Umspannwerk Lauf-Ost",[11.3031338, 49.5125628]],
    [ "Umspannwerk Roddens",[8.299435, 53.5478179]],
    [ "Blexen",[8.5451388, 53.5289662]],
    [ "Söhlingen",[9.6378693, 53.0664658]],
    [ "Bomlitz",[9.6522509, 52.9154087]],
    [ "Umspannwerk Halberstadt Süd",[11.0638716, 51.879428]],
    [ "Umspannwerk Untersteinach",[11.5394356, 50.1382851]],
    [ "Loga",[7.5082291, 53.2432337]],
    [ "Clenze",[10.9504253, 52.9256659]],
    [ "Wieren",[10.6454875, 52.8972484]],
    [ "Emern",[10.6610069, 52.897682]],
    [ "110kV-Umspannwerk Schmiechen",[9.7266925, 48.3604589]],
    [ "110kV-Umspannwerk Ehingen",[9.7202284, 48.289296]],
    [ "Station Erwitte",[8.3381623, 51.6045965]],
    [ "Langholt",[7.602276, 53.1148332]],
    [ "Umspannwerk Etelsen",[9.1193744, 53.0025955]],
    [ "Ottersberg",[9.1439254, 53.123048]],
    [ "Oyten",[9.0383039, 53.0571517]],
    [ "Station Schloß Holte",[8.5797832, 51.9119588]],
    [ "Umspannwerk Altdorf",[12.1043606, 48.5517932]],
    [ "Umspannwerk Benteler",[8.7247184, 51.7431306]],
    [ "Umspannwerk",[6.8906585, 49.5278961]],
    [ "Umspannwerk Rotensohl",[10.2229802, 48.736252]],
    [ "Umspannwerk Hof-Süd",[11.9245695, 50.2815627]],
    [ "Umspannwerk Schwaig",[11.8262794, 48.3356289]],
    [ "Umspannwerk Amstetten",[9.8702905, 48.5684709]],
    [ "Unterwerk Amstetten",[9.8771722, 48.5696187]],
    [ "Albwerk Umspannwerk Gosbach",[9.6782295, 48.5709318]],
    [ "Umspannwerk Bad Segeberg",[10.2987106, 53.927891]],
    [ "Kabelgarten",[8.8133689, 48.9426415]],
    [ "Station Wallach",[6.5663891, 51.5911504]],
    [ "S-Bahn Gleichrichterunterwerk Schöneberg",[13.3515685, 52.4783872]],
    [ "Station Bellendorf",[7.0307883, 51.6097545]],
    [ "Dahlenburg",[10.7324934, 53.1813417]],
    [ "Herzebrock-Clarholz",[8.2120739, 51.8878086]],
    [ "Umspannwerk Kriftel",[8.4713963, 50.0959864]],
    [ "Unterwerk Nannhofen",[11.1886195, 48.2160609]],
    [ "Magdeburg/Nord",[11.6319924, 52.1647723]],
    [ "Avenwedde",[8.4238618, 51.9107837]],
    [ "Umspannwerk Bühl",[8.1199393, 48.7047648]],
    [ "Uckro",[13.5975616, 51.8549268]],
    [ "Umspannwerk Pfrombach",[11.9901689, 48.4405975]],
    [ "Schaltwerk Hof",[11.9558769, 50.3192214]],
    [ "Umspannwerk Fennpfuhl",[13.4801446, 52.5335004]],
    [ "Unterwerk Geltendorf",[11.0309154, 48.1059933]],
    [ "Bornholte",[8.5386034, 51.8713336]],
    [ "Station Hesseln",[8.318978, 52.0651482]],
    [ "Umspannwerk Biesdorf Nord",[13.5372435, 52.5379624]],
    [ "Umspannwerk Gelnitzstraße",[13.5866317, 52.4567081]],
    [ "Unterwerk Wolfratshausen",[11.4254853, 47.9081497]],
    [ "Ribnitz-Damgarten",[12.4071931, 54.239967]],
    [ "Nienburg/Nord",[9.2089183, 52.6644845]],
    [ "Bahnstrom-Unterwerk Böhla",[13.5457089, 51.2352375]],
    [ "Umspannwerk Vierraden",[14.2694905, 53.096543]],
    [ "Umspannwerk Pasewalk",[14.0320576, 53.5081889]],
    [ "Umspannwerk Marburg Süd",[8.7496599, 50.7918672]],
    [ "Simmershausen",[10.6378473, 50.3741819]],
    [ "Umspannwerk Jägerstraße",[13.3843333, 52.5133498]],
    [ "KKW Rheinsberg",[12.9890996, 53.144995]],
    [ "Rheinsberg",[12.905215, 53.0901884]],
    [ "Riekdahl",[12.17965, 54.1002076]],
    [ "Sellin",[13.656885, 54.3728921]],
    [ "Garz",[13.3478231, 54.3266009]],
    [ "S-Bahn Gleichrichterunterwerk Neukölln",[13.4453765, 52.4699487]],
    [ "Marienehe",[12.0814584, 54.1252569]],
    [ "Erfurt/Melchendorf",[11.0695866, 50.9497809]],
    [ "Steinbach-Hallenberg",[10.5454538, 50.6838469]],
    [ "Suhl Nord",[10.6797259, 50.639717]],
    [ "Erfurt/Hochstedt",[11.1284582, 50.9835912]],
    [ "Sondershausen",[10.833024, 51.3849757]],
    [ "Karlshagen",[13.8139664, 54.111597]],
    [ "Wolgast Wind",[13.7432302, 54.0587232]],
    [ "Umspannstation Windpark Iven",[13.3988509, 53.7945253]],
    [ "Station Nohn",[6.781818, 50.3300768]],
    [ "Umspannwerk Passau",[13.4092713, 48.582081]],
    [ "Kabelendmast Röderweg",[11.0001413, 50.9784187]],
    [ "Harburg",[9.9811972, 53.4644841]],
    [ "Umspannwerk Erfurt West",[11.0159582, 50.9765288]],
    [ "Umspannwerk Kröpelin",[11.7827432, 54.0756858]],
    [ "Bahnstrom Unterwerk Riesa",[13.2674481, 51.311594]],
    [ "Bahnstrom Unterwerk Niemberg",[12.1033144, 51.5594858]],
    [ "Unterwerk Eilenburg",[12.6186432, 51.4467993]],
    [ "Felsberg",[9.4028399, 51.1360564]],
    [ "Station Speckweg",[10.9916459, 49.6342419]],
    [ "Gräfentonna",[10.7271371, 51.0814347]],
    [ "Walschleben",[10.9559347, 51.0702839]],
    [ "Rechtenbach",[8.5582861, 50.5303785]],
    [ "Martinroda",[10.0709464, 50.8106619]],
    [ "Erfurt/Iderhoffstraße",[11.0492831, 50.9778084]],
    [ "Umspannwerk Krakow",[12.2658295, 53.6678812]],
    [ "Bahnstrom Umformerwerk Lalendorf",[12.3975408, 53.7541842]],
    [ "Umspannwerk Lalendorf",[12.3981272, 53.7537807]],
    [ "UW Keitum",[8.3629678, 54.8910124]],
    [ "Elm",[9.5389759, 50.3530175]],
    [ "Umspannwerk Parey",[11.9813727, 52.3721927]],
    [ "Körbelitz",[11.777638, 52.1730073]],
    [ "Umspannwerk Burg",[11.8217549, 52.2775123]],
    [ "Umspannstation Nielebock",[12.0568166, 52.4279275]],
    [ "Münchehof",[10.1694455, 51.8532464]],
    [ "Brandenburg-West",[12.4982681, 52.40479]],
    [ "U-Bahn Gleichrichterwerk Weberwiese",[13.4429997, 52.5177867]],
    [ "Umspannwerk Elektrokohle",[13.4946195, 52.5274588]],
    [ "Umspannwerk Marktsteft",[10.1461995, 49.712597]],
    [ "Unterwerk Mainbernheim",[10.2109026, 49.7003284]],
    [ "Umspannwerk Leutershausen",[10.4444133, 49.3070969]],
    [ "Umspannwerk Ettlingen Spinnerei",[8.4323625, 48.9346318]],
    [ "Unterwerk Baden-Baden",[8.1470944, 48.7344551]],
    [ "Unterwerk Wiesental",[8.4965069, 49.2224001]],
    [ "S-Bahn Gleichrichterunterwerk Tegel",[13.2870706, 52.5909875]],
    [ "Hemfurth",[9.060642, 51.1831954]],
    [ "Waldeck",[9.0499481, 51.1666008]],
    [ "Waldeck",[9.0486699, 51.1660914]],
    [ "Gardelegen",[11.3983494, 52.5121731]],
    [ "Osterode",[10.2334775, 51.7265969]],
    [ "Stapelmoor",[7.3173039, 53.1280764]],
    [ "Unterwerk Murnau",[11.1922032, 47.688991]],
    [ "Umspannwerk Twistetal",[8.9924777, 51.3415466]],
    [ "Unterwerk Stuttgart",[9.1856879, 48.843993]],
    [ "Unterwerk Waiblingen",[9.2982945, 48.8255488]],
    [ "Umformerwerk Neu-Ulm",[10.019193, 48.3978982]],
    [ "Umspannwerk Pöhlde",[10.3262772, 51.6170397]],
    [ "Schaltwerk Neckarwestheim",[9.2023496, 49.0429314]],
    [ "Drehstromschaltwerk Neckarwestheim",[9.2040425, 49.042811]],
    [ "Station Stahe Am Wasberg",[6.0500827, 50.9861793]],
    [ "Rendsburg Mitte",[9.6907314, 54.3087085]],
    [ "110kV-Umspannwerk Achstetten",[9.8781291, 48.2700753]],
    [ "Umspannwerk Ochsenhausen",[9.9542024, 48.0797285]],
    [ "110kV-Umspannwerk Kirchdorf",[10.1278246, 48.0642499]],
    [ "110kV-Umspannwerk Dietenheim",[10.0812035, 48.2064915]],
    [ "Umspannwerk Süd",[9.9536021, 48.3586381]],
    [ "Dellmensingen",[9.8844226, 48.3025185]],
    [ "110kV-Umspannwerk Haisterkirch",[9.7941307, 47.9169464]],
    [ "110kV-Umspannwerk Munderkingen",[9.6374049, 48.2368184]],
    [ "110kV-Umspannwerk",[9.728089, 48.3666826]],
    [ "110kV-Umspannwerk Beiningen",[9.7895739, 48.3936394]],
    [ "Umspannwerk Söflingen",[9.9435743, 48.4040271]],
    [ "110kV-Umspannwerk",[8.373102, 48.9401963]],
    [ "110-kV-Umspannwerk Gaggenau",[8.3323511, 48.8006757]],
    [ "110-kV-Umspannwerk Hilpertsau",[8.3551973, 48.7379911]],
    [ "110kV-Umspannwerk Forchheim",[8.2986192, 48.9743994]],
    [ "110-kV-Umspannwerk Leopoldshafen",[8.4301091, 49.1044713]],
    [ "110-kV-Umspannwerk Hochstetten",[8.4160376, 49.1388774]],
    [ "S-Bahn Gleichrichterunterwerk Sonnenallee",[13.4540065, 52.4718049]],
    [ "110kV-Umspannwerk Bruchsal",[8.5827721, 49.124824]],
    [ "Niedermittlau",[9.1138313, 50.1711845]],
    [ "Umspannwerk Murnau",[11.2070336, 47.6845088]],
    [ "Wildau",[13.6352167, 52.3278023]],
    [ "Umspannwerk Arolsen",[8.9999626, 51.3709476]],
    [ "Leherheide",[8.6209392, 53.6027699]],
    [ "Umspannwerk Villingen",[8.4652657, 48.0442417]],
    [ "Umspannwerk Goldshöfe",[10.1197025, 48.8974281]],
    [ "Station Osburg",[6.8138609, 49.7255462]],
    [ "Umspannwerk Schwenningen",[8.5494786, 48.0603183]],
    [ "Umspannwerk Winterschneidbach",[10.6201941, 49.2484874]],
    [ "Umspannwerk Tittling",[13.3817051, 48.7360332]],
    [ "Umspannwerk Perlesreuth",[13.4868481, 48.7703278]],
    [ "Umspannwerk Freyung",[13.5281224, 48.8107707]],
    [ "Umspannwerk Grafenau",[13.3693106, 48.8786165]],
    [ "Umspannwerk Dettingen",[8.9891669, 50.0592399]],
    [ "Großkrotzenburg",[8.9592437, 50.0846166]],
    [ "Kraftwerkseinspeisung Staudinger",[8.9566137, 50.0862786]],
    [ "Meckenheim",[7.0131296, 50.6162132]],
    [ "Bilshausen",[10.1588405, 51.6406752]],
    [ "Umspannwerk Hörstein",[9.046115, 50.045917]],
    [ "Umspannwerk Rollberg",[13.4279388, 52.4783247]],
    [ "Umspannungsanlage Uentrop",[7.9902098, 51.6847214]],
    [ "Station Lippborg",[7.9769318, 51.6737333]],
    [ "Beckum",[8.07027, 51.7548808]],
    [ "Station Ahlen",[7.9169991, 51.7572366]],
    [ "Sterbfritz",[9.6141678, 50.3175967]],
    [ "Station Gersteinwerk",[7.7097481, 51.6759176]],
    [ "Station Schering",[7.6295694, 51.6276435]],
    [ "Station Enniger",[7.9936476, 51.8237664]],
    [ "Umspannwerk Horn",[8.9615069, 51.8746124]],
    [ "Borgholz",[9.2552466, 51.6283511]],
    [ "Unterwerk Warburg",[9.1502142, 51.4972094]],
    [ "Ossendorf",[9.0993937, 51.5116612]],
    [ "Wrexen",[8.9779678, 51.5054653]],
    [ "Station Büren",[8.5505723, 51.552469]],
    [ "Vörden",[9.2272178, 51.8134653]],
    [ "Alhausen",[9.0596772, 51.7429511]],
    [ "Holzminden Süd",[9.437482, 51.8041927]],
    [ "Höxter",[9.3924525, 51.7791827]],
    [ "Überlandwerk",[7.9467757, 48.6602648]],
    [ "Oelde",[8.1329692, 51.8296991]],
    [ "Umspannwerk Engstlatt",[8.873358, 48.3084564]],
    [ "Station Nehden",[8.6250697, 51.4291939]],
    [ "Station Brilon",[8.5796403, 51.4077509]],
    [ "Station Arpe",[8.2107914, 51.1769942]],
    [ "Niedereimer",[8.0445761, 51.4195485]],
    [ "Station Ostönnen",[7.9985733, 51.5576795]],
    [ "UA Haarstrang",[8.1378189, 51.546931]],
    [ "Umspannwerk DD-Tolkewitz",[13.8171447, 51.0334175]],
    [ "Station Neheim",[7.9663016, 51.4547752]],
    [ "Station Menden",[7.777477, 51.4513372]],
    [ "Station Unna",[7.7164554, 51.53466]],
    [ "Amelsbüren",[7.6084971, 51.897987]],
    [ "Station Ahaus",[7.0253707, 52.0875825]],
    [ "Huntorf",[8.4086611, 53.1899898]],
    [ "Kottigerhook",[7.0108025, 52.1768857]],
    [ "Cottbus/Halde",[14.475664, 51.7929266]],
    [ "Elsfleth",[8.4583002, 53.2229369]],
    [ "Umspannwerk Mozartstraße",[11.0160076, 49.5915159]],
    [ "Umspannwerk Ahrensburg Nord",[10.2572248, 53.6840614]],
    [ "Umspannwerk Allersberg",[11.2292818, 49.2484951]],
    [ "Umspannwerk Rembrandtstraße",[9.1502414, 48.730594]],
    [ "Hardegsen",[9.8513432, 51.647802]],
    [ "Umspannwerk Thale",[11.069101, 51.7519809]],
    [ "Umspannwerk 5",[10.2505808, 52.3132033]],
    [ "Umspannwerk Reuter",[13.2497946, 52.5317257]],
    [ "Umspannwerk Karow",[13.4877381, 52.617883]],
    [ "S-Bahn Gleichrichterwerk Friedrichstraße",[13.3896567, 52.5203386]],
    [ "Kahl",[8.9858476, 50.0569786]],
    [ "Lüchow",[11.1638322, 52.9801304]],
    [ "Station Stoßdorf",[7.2435697, 50.7802932]],
    [ "110kV-Umspannwerk Mühlacker",[8.8502938, 48.9569781]],
    [ "Peine/West",[10.2147026, 52.3252618]],
    [ "Umspannwerk Föhrenwald",[11.4392933, 47.8995622]],
    [ "Umspannwerk Perlach",[11.6498586, 48.0952396]],
    [ "Umspannwerk Ismaning",[11.6857363, 48.2334572]],
    [ "Umspannwerk Ditzingen",[9.0644522, 48.8330805]],
    [ "Umspannwerk Ludwigsburg West",[9.1501716, 48.8913437]],
    [ "Kornwestheim Nord",[9.1850504, 48.8780189]],
    [ "110kV-Umspannwerk Stein",[8.6526612, 48.9560295]],
    [ "Umspannwerk Rinklingen",[8.6785648, 49.0286338]],
    [ "Umspannwerk Pulverdingen",[9.0458763, 48.9025356]],
    [ "UW Lesum",[8.6957407, 53.1695583]],
    [ "110 kV-Umspannwerk Calmbach",[8.5999494, 48.7728123]],
    [ "ehemaliges Gleichstrom-Unterwerk Gesundbrunnen",[13.384482, 52.5481387]],
    [ "UW Nuthe",[13.0764576, 52.391748]],
    [ "Ketzin",[12.8715409, 52.491114]],
    [ "Unterwerk Denkendorf",[11.4545452, 48.9390861]],
    [ "Umrichterwerk Aschaffenburg",[9.0920341, 49.9864238]],
    [ "Hanau/Ost",[8.926394, 50.1189303]],
    [ "Gleichrichterunterwerk Westhafen",[13.3421803, 52.5352644]],
    [ "Umspannwerk Neu-Ulm Ost",[10.023295, 48.3961038]],
    [ "Duderstadt",[10.236353, 51.5103073]],
    [ "Umspannwerk Schwabmünchen",[10.7337054, 48.1924707]],
    [ "Gleichrichterunterwerk Schönhauser Allee",[13.410351, 52.5498528]],
    [ "Edewecht",[7.9862635, 53.1120399]],
    [ "Umspannwerk Siemens",[13.2466959, 52.538923]],
    [ "Station Weddinghofen",[7.6114937, 51.6152369]],
    [ "Warturm",[8.7639717, 53.0689784]],
    [ "Niedervieland",[8.6966347, 53.0919948]],
    [ "Umspannwerk Glückstadt",[9.4289372, 53.7785841]],
    [ "Umspannwerk Hochbrück",[11.6026676, 48.2464132]],
    [ "EnBW Umspannwerk Waldhausen",[9.6331027, 48.7804454]],
    [ "TWL Umspannwerk Mundenheim",[8.4177012, 49.4579456]],
    [ "Bad Lauterberg",[10.5019172, 51.6460717]],
    [ "Wolkramshausen",[10.7328189, 51.4411887]],
    [ "Umformwerk Wolkramshausen",[10.7367093, 51.4387353]],
    [ "Station Ratingen",[6.8322335, 51.2991695]],
    [ "Umspannwerk Pirna-Copitz Steinhügelweg",[13.9440986, 50.981281]],
    [ "Umspannwerk Radebeul",[13.6505394, 51.1043263]],
    [ "Bahnstrom Unterwerk Stetzsch",[13.6593627, 51.0766246]],
    [ "Schaltposten Himmighausen",[8.9927191, 51.8038531]],
    [ "Umspannwerk Schierling",[12.1558775, 48.8248455]],
    [ "S-Bahn Gleichrichterunterwerk Potsdam",[13.0754396, 52.3919541]],
    [ "Umspannwerk Wedding",[13.3579498, 52.5440706]],
    [ "Stargardtstraße",[13.3600209, 52.5662481]],
    [ "Umspannwerk Schäfersee",[13.3604696, 52.566321]],
    [ "Umspannwerk Niederschönhausen",[13.4131241, 52.5883444]],
    [ "Umspannwerk Marzahn",[13.5204277, 52.5317533]],
    [ "Umspannwerk Gürtelstraße",[13.4701464, 52.5094526]],
    [ "Umspannwerk Grünau",[13.572629, 52.4151582]],
    [ "Lauffener Straße",[13.5212574, 52.4598521]],
    [ "Umspannwerk Trautenauer Straße",[13.5136881, 52.4844979]],
    [ "Umspannwerk Rüdersdorfer Straße",[13.4444162, 52.5113732]],
    [ "Umspannwerk Barbarossa",[13.346948, 52.4926322]],
    [ "Umspannwerk Hockenheim",[8.5346025, 49.3327776]],
    [ "Umspannwerk Zille",[13.3125439, 52.5129649]],
    [ "Umspannwerk Sömmering",[13.3066012, 52.5216703]],
    [ "Umspannwerk Hebbel",[13.2996509, 52.5146829]],
    [ "Umspannwerk Lindenstraße",[13.3941305, 52.5007181]],
    [ "Umspannwerk Zossener Straße",[13.3937394, 52.492956]],
    [ "Umspannwerk Kirrlach",[8.5341071, 49.2498013]],
    [ "Netze BW Umspannwerk Brühl",[8.5394836, 49.3861997]],
    [ "110kV-Umspannwerk Münzesheim",[8.7270455, 49.1200523]],
    [ "Umspannwerk Wiesloch",[8.6727536, 49.2912899]],
    [ "Umspannwerk Gropius",[13.4872064, 52.422529]],
    [ "Umspannwerk Weserstraße",[13.434404, 52.4865899]],
    [ "Umspannwerk Wildmeister",[13.4479264, 52.4251493]],
    [ "Umspannwerk Dahlem",[13.2685228, 52.4507789]],
    [ "Umspannwerk Kopernikus",[13.3021611, 52.4337578]],
    [ "Umspannwerk Claszeile",[13.2587181, 52.4196955]],
    [ "Umspannwerk Schlachtensee",[13.2132089, 52.4341825]],
    [ "Umspannwerk Großbeeren",[13.3763337, 52.43015]],
    [ "Umspannwerk Marienfelde",[13.3803251, 52.4103063]],
    [ "Umspannwerk Baldersheim",[13.4021151, 52.4110361]],
    [ "Umspannwerk Eichhorst",[13.3370123, 52.6036724]],
    [ "Umspannwerk Roedernallee",[13.3423754, 52.5865328]],
    [ "Umspannwerk Charlottenburg",[13.3153843, 52.5221807]],
    [ "Umspannwerk Amalienhof",[13.1815927, 52.5241321]],
    [ "Süwag-Umspannwerk Großgartach",[9.1422783, 49.1431183]],
    [ "Umspannwerk Pfullingen",[9.2326282, 48.4537947]],
    [ "Umspannwerk Klosterstraße",[9.2316224, 48.4539114]],
    [ "Umspannwerk Spittelmarkt",[13.4060712, 52.5094792]],
    [ "Unterwerk Adelsheim/Osterburken",[9.4047957, 49.4153178]],
    [ "Umspannwerk Mitte",[9.2024411, 48.4889726]],
    [ "Umspannwerk Gallwitz",[13.3487394, 52.4353011]],
    [ "Umspannwerk Rubens",[13.3458478, 52.4647693]],
    [ "Unterwerk Niedernhausen",[8.3199274, 50.1563805]],
    [ "Unterwerk Schwäbisch Hall-Hessental",[9.7773892, 49.099756]],
    [ "Unterwerk Aalen",[10.0425725, 48.8226708]],
    [ "Umspannwerk Sipplingen",[9.1222804, 47.7929439]],
    [ "Station Buschhausen",[6.823597, 51.4963835]],
    [ "Umspannwerk Neue Grünstraße",[13.4053496, 52.5095209]],
    [ "Unterwerk Remagen",[7.2425038, 50.5687504]],
    [ "Umspannwerk Rastatt Oberreuth",[8.1821394, 48.8578122]],
    [ "Umspannwerk Moissistraße",[13.546282, 52.4355956]],
    [ "Umspannwerk Alt-Kaulsdorf",[13.5854431, 52.5044078]],
    [ "Umspannwerk Germania",[13.4002231, 52.4645916]],
    [ "Umspannwerk Gatow",[13.1802883, 52.5077853]],
    [ "Umspannwerk Hakenfelde",[13.2165866, 52.5670475]],
    [ "Unterwerk Haltingen",[7.6105402, 47.6055061]],
    [ "Unterwerk Freiburg",[7.8275521, 47.9811013]],
    [ "Unterwerk Herbolzheim",[7.7691291, 48.2328501]],
    [ "Netze BW Umspannwerk Schorndorf",[9.5429661, 48.7995096]],
    [ "Unterwerk Eutingen",[8.7795438, 48.4801304]],
    [ "Unterwerk Sommerau",[8.3122, 48.1279599]],
    [ "Unterwerk Rottweil",[8.6552118, 48.1320942]],
    [ "Bahnstrom Unterwerk Singen",[8.8810628, 47.7584497]],
    [ "Schotten",[9.1437916, 50.5108319]],
    [ "UW Kraftwerk",[8.5388843, 52.0347997]],
    [ "Bad Bramstedt",[9.8933621, 53.9156931]],
    [ "S-Bahn Gleichrichterunterwerk Birkenwerder",[13.2859722, 52.6976038]],
    [ "Umspannwerk Roth",[11.1048805, 49.2486388]],
    [ "Station Geisbach",[7.3014409, 50.7660425]],
    [ "Wiehl",[7.5742166, 50.9523845]],
    [ "Umspannwerk Herzbergstraße",[13.4895574, 52.5264553]],
    [ "Umspannwerk Hermsdorf",[13.3016155, 52.6191826]],
    [ "Umspannwerk Askania",[13.1987296, 52.5431253]],
    [ "UW Nossen",[13.3123002, 51.0653084]],
    [ "UW Heynitz",[13.3339373, 51.0669276]],
    [ "Umspannwerk Dettingen / Erms",[9.3666059, 48.5126621]],
    [ "Umspannwerk Eggolsheim",[11.0189918, 49.7839508]],
    [ "Ebensfeld",[10.9507383, 50.0621817]],
    [ "Umspannwerk Uhlandstraße",[13.3248739, 52.5044548]],
    [ "Umspannwerk Prinzregentenstraße",[13.3325833, 52.4852234]],
    [ "Umspannwerk Münstersche Straße",[13.3069893, 52.4938176]],
    [ "Station Schauenstraße",[6.6942432, 51.4189885]],
    [ "Station Asberg",[6.6664664, 51.4407668]],
    [ "Unterwerk Appenweier",[7.9729867, 48.5439475]],
    [ "Philippsburg",[8.4401788, 49.2504889]],
    [ "Umspannwerk Waakirchen",[11.6827444, 47.7785839]],
    [ "Umspannwerk Augsburg-Süd",[10.8861756, 48.3514394]],
    [ "Umspannwerk Bad Wiessee",[11.7106872, 47.7228192]],
    [ "Umspannwerk Ohmstraße",[13.4183027, 52.5107952]],
    [ "Umspannwerk Heiligensee",[13.2370017, 52.6026112]],
    [ "Umspannwerk Kladow",[13.1515809, 52.4576743]],
    [ "Umspannwerk Lepsius",[13.316322, 52.4597621]],
    [ "Umspannwerk Mariendorf",[13.3676215, 52.4450362]],
    [ "Umspannwerk Pankow",[13.4110617, 52.5649938]],
    [ "Umspannwerk Radeland",[13.1712924, 52.5502393]],
    [ "Umspannwerk Rudolfplatz",[13.4553258, 52.5026413]],
    [ "Gleichrichterunterwerk Beusselstraße",[13.3267597, 52.5335871]],
    [ "Unterwerk Rosenheim",[12.1328765, 47.8451141]],
    [ "Unterwerk Rosenheim",[12.132553, 47.8451351]],
    [ "380 kV-Umspannwerk Endersbach (TransnetBW GmbH)",[9.3772905, 48.8131376]],
    [ "Schaltwerk Freiberg",[9.2072044, 48.8408291]],
    [ "Waiblingen 2",[9.2947633, 48.8302574]],
    [ "Waiblingen",[9.3215909, 48.8282775]],
    [ "Umspannwerk Bad Tölz",[11.5710428, 47.7437061]],
    [ "Umspannwerk Neukölln",[13.445311, 52.4672178]],
    [ "UW Oslebshausen",[8.730447, 53.129574]],
    [ "Gröpelingen",[8.7579165, 53.1111194]],
    [ "Umspannanlage Schweinheim",[9.1314582, 49.956387]],
    [ "Kaltenkirchen-Süd",[9.9779856, 53.8158365]],
    [ "Station St. Johann 80a",[10.974851, 49.61432]],
    [ "Schaltposten Nordkreuz",[13.3979478, 52.5487451]],
    [ "TS Membacher Weg 47",[10.9741326, 49.6081369]],
    [ "TS Amselfeld 48",[10.9773045, 49.6077492]],
    [ "Station Schobertweg 12a",[10.9796641, 49.6040984]],
    [ "Umspannwerk Süd",[9.232354, 48.4839023]],
    [ "Unterwerk Emden",[7.2197163, 53.3583586]],
    [ "Umspannwerk Zittau / Nord",[14.7732891, 50.9045511]],
    [ "Transrapid-Versuchsanlage-Emsland Süd",[7.3480101, 52.8717247]],
    [ "Niederlangen",[7.1953753, 52.8702712]],
    [ "Großringe",[6.9302623, 52.5999287]],
    [ "Umspannwerk Erfurt Flughafen",[10.9598439, 50.9699678]],
    [ "Umspannwerk Hof-Ost",[11.9404633, 50.3197082]],
    [ "Station Sankt Peter 220 kV",[6.8012557, 51.1239608]],
    [ "Station Sankt Peter 380 kV",[6.8011447, 51.123888]],
    [ "Abnehmeranlage Nord",[13.3812043, 52.5484137]],
    [ "Umspannwerk Baruth",[13.5206191, 52.0808915]],
    [ "Umspannwerk Maiach",[11.0625979, 49.4150905]],
    [ "Umspannwerk Zollhaus",[11.1191152, 49.4003565]],
    [ "Badeleben",[11.1188612, 52.1535414]],
    [ "Bahnstrom Unterwerk Muldenstein",[12.3502582, 51.659496]],
    [ "Umspannwerk Tonndorf",[10.1248383, 53.5833829]],
    [ "Technisches Rathaus",[9.3087949, 48.7402553]],
    [ "Umspannanlage Rheinböllen",[7.6855681, 50.0210747]],
    [ "Station Neuenahr",[7.1199103, 50.5500834]],
    [ "Kalle",[6.8670452, 52.5901731]],
    [ "Station Bookholt",[7.0651436, 52.4768193]],
    [ "Dörpen",[7.3646703, 52.9807351]],
    [ "Transrapid-Versuchsanlage-Emsland Nord",[7.3782849, 52.9645568]],
    [ "Sögel",[7.5472083, 52.8268363]],
    [ "Haselünne",[7.4929569, 52.6807077]],
    [ "Essen",[7.9435604, 52.732868]],
    [ "Cloppenburg 220 kV",[8.0812759, 52.8579917]],
    [ "Cloppenburg 110 kV",[8.0842101, 52.8584295]],
    [ "Ramsloh",[7.7079408, 53.0978615]],
    [ "Bardenitz",[12.9545441, 52.1072349]],
    [ "Umspannwerk Jochenstein",[13.7095548, 48.5195579]],
    [ "Luckenwalde",[13.1598813, 52.073766]],
    [ "Jüterbog",[13.0876951, 52.0028766]],
    [ "Markendorf Wind",[13.1551374, 51.9850862]],
    [ "Charlottenfelde",[13.3253506, 51.9871983]],
    [ "Laage",[12.309564, 53.930936]],
    [ "Umspannwerk Petersdorf",[11.0572253, 54.4777925]],
    [ "Umspannwerk Bützow",[11.9595408, 53.8461374]],
    [ "Umspannwerk Hof West",[11.8742077, 50.3290082]],
    [ "Station Limburg",[8.0670178, 50.4051195]],
    [ "Umspannwerk Neusäß",[10.842687, 48.402651]],
    [ "Güstow",[13.7640048, 53.3359026]],
    [ "Schwarzenfeld",[12.1371637, 49.3986914]],
    [ "Umspannwerk Stadtbergen",[10.8396614, 48.376989]],
    [ "Unterwerk Rastede",[8.1641605, 53.2952768]],
    [ "Umspannwerk Fürstenfeldbruck",[11.2186686, 48.1773342]],
    [ "Ebern",[10.7902761, 50.1023159]],
    [ "Drensteinfurt",[7.7504565, 51.7993022]],
    [ "Schaltposten Breckenheim",[8.3727461, 50.0724171]],
    [ "Umspannwerk Hechtsheim",[8.2650573, 49.968539]],
    [ "Spremberg",[13.3859581, 52.1516835]],
    [ "Umspannwerk Manching",[11.5101696, 48.7182404]],
    [ "ELEG 2",[11.7475692, 48.1150947]],
    [ "Umspannwerk Bixterheide",[7.7226873, 51.4096243]],
    [ "Abnehmeranlage Ost",[13.4652096, 52.5030708]],
    [ "Umspannwerk Hirschau",[11.9520557, 49.5334276]],
    [ "Elmshorn-Ost",[9.6905987, 53.7430118]],
    [ "Zeven",[9.2711819, 53.309483]],
    [ "Umspannwerk Hemmoor",[9.131008, 53.6960197]],
    [ "Steinbach",[9.4639968, 48.953233]],
    [ "Schaltposten Backnang",[9.4157948, 48.938247]],
    [ "Schaltwerk Bremen",[8.6789471, 53.1301816]],
    [ "Station Kirchhörde",[7.4602699, 51.46595]],
    [ "Unterwerk Scharnhorst",[7.5321062, 51.5371884]],
    [ "Unterwerk Dortmund",[7.4016446, 51.5106389]],
    [ "Station Technopark",[7.3916435, 51.4879965]],
    [ "Dorstfeld",[7.4005762, 51.5098903]],
    [ "Umspannwerk Horlecke",[7.8132869, 51.4233426]],
    [ "Station Kalthof",[7.691019, 51.4202336]],
    [ "UA Brebach",[7.043411, 49.2141673]],
    [ "Station Neukirchen",[6.5579297, 51.4370771]],
    [ "Eisenhüttenstadt",[14.6056332, 52.1752582]],
    [ "Waren (Müritz)",[12.6713373, 53.531145]],
    [ "Umspannwerk Bad Steben",[11.6452649, 50.3575063]],
    [ "Schüttorf",[7.2301636, 52.3176134]],
    [ "Dieringhausen",[7.5297227, 50.9810183]],
    [ "Dieringhausen",[7.5297412, 50.9814098]],
    [ "Umspannwerk Freising/West",[11.7273899, 48.4079338]],
    [ "Umspannwerk Kissing",[10.9608225, 48.3017827]],
    [ "Umspannwerk Lechhausen",[10.9364979, 48.4003876]],
    [ "Hafen",[7.4324286, 51.5339015]],
    [ "Umspannwerk Kaltengreuth (Schalthaus Ansbach Ost)",[10.6260922, 49.2922846]],
    [ "Umspannstation Windpark Ihlewitz",[11.6778208, 51.6496958]],
    [ "Umspannstation Windpark Gerbstedt",[11.6380422, 51.655141]],
    [ "Umspannstation Drohndorf",[11.5825672, 51.7214858]],
    [ "Harlingerode",[10.501458, 51.9286065]],
    [ "Umspannwerk Eimsbüttel",[9.9562575, 53.5776909]],
    [ "Zellerfeld",[10.347314, 51.8200603]],
    [ "Gladenbach",[8.5831189, 50.7564422]],
    [ "Dahlewitz",[13.4440467, 52.3119869]],
    [ "Umspannwerk Uchtelfangen",[6.9925668, 49.3758584]],
    [ "Station Mehr",[6.4614552, 51.714425]],
    [ "Drewitz",[13.1120907, 52.3812746]],
    [ "Umspannwerk Potsdam Zentrum",[13.0641341, 52.3994557]],
    [ "Hemer",[7.7692665, 51.3824281]],
    [ "Umspannwerk Leipzig West",[12.3164427, 51.32303]],
    [ "Umspannwerk Oberkochen",[10.1164018, 48.7843717]],
    [ "Umspannwerk Aufhausen",[10.1578294, 48.7349085]],
    [ "Schaltposten Feucht",[11.191654, 49.3861857]],
    [ "Umspannwerk Moosburg",[11.9488731, 48.4727435]],
    [ "Umspannwerk Aschaffenburg",[9.0881424, 49.969219]],
    [ "Umspannwerk Corneliusstraße",[11.5748934, 48.1323829]],
    [ "Liebenwalde",[13.4122438, 52.8717738]],
    [ "SÜWAG",[8.0720939, 48.6241661]],
    [ "Bahnstrom Schaltwerk Schkopau",[11.9460157, 51.4011792]],
    [ "Premnitz",[12.3551605, 52.5266879]],
    [ "Arneburg",[11.9882975, 52.6907246]],
    [ "Ellingen",[11.9753899, 52.7099194]],
    [ "Umspannwerk Kempten-West",[10.2866382, 47.7091414]],
    [ "Umspannwerk Ruhleben",[13.981567, 53.4982803]],
    [ "Löcknitz",[14.2123993, 53.4493436]],
    [ "Lehnin",[12.7331107, 52.3227222]],
    [ "Brandenburg Klingenbergstraße",[12.5311985, 52.406375]],
    [ "Brandenburg Nord",[12.5370048, 52.4274508]],
    [ "Kabeleinführung 380 kV",[8.5194178, 50.0491663]],
    [ "Sohren",[7.3081292, 49.9229253]],
    [ "Sonthofen Mitte",[10.2835537, 47.5165591]],
    [ "Station Bodelschwingh",[7.3829371, 51.5511296]],
    [ "Umspannwerk Milbertshofen",[11.5572051, 48.1865699]],
    [ "Umspannwerk Heidemannstraße",[11.602909, 48.2015943]],
    [ "Lübnitz",[12.5393076, 52.1559504]],
    [ "Umspannwerk Hasenbergl",[11.5502638, 48.2135449]],
    [ "Umspannwerk Pasing",[11.4712209, 48.1463874]],
    [ "Umspannwerk Moosach",[11.5062309, 48.1770408]],
    [ "Umspannwerk Allach",[11.463931, 48.1840564]],
    [ "Umspannwerk Aubing",[11.4292432, 48.149136]],
    [ "Umspannwerk Germering",[11.377252, 48.1289438]],
    [ "Umspannwerk Planegg",[11.4242062, 48.1057522]],
    [ "Vagen",[11.8705977, 47.8721916]],
    [ "Endmastanlage HlO-HlSP-HlD",[11.9935955, 51.477151]],
    [ "Umspannwerk Seidlstraße",[11.5578763, 48.1446325]],
    [ "Umspannwerk",[11.575458, 48.1481728]],
    [ "Umspannwerk Wurzerstraße",[11.5825427, 48.1396536]],
    [ "Umspannwerk Muffatwerk",[11.5893281, 48.1332161]],
    [ "Umspannwerk Grafinger Straße",[11.611426, 48.1246797]],
    [ "Umspannanlage Leupolz",[10.3646495, 47.7378443]],
    [ "Kraftwerk Pleidelsheim",[9.203859, 48.9694636]],
    [ "Cottbus/Nord",[14.47924, 51.7896324]],
    [ "Umspannwerk Ausstellung",[11.540409, 48.1297481]],
    [ "Umspannwerk Stadelheim",[11.5872991, 48.0992844]],
    [ "Umspannwerk MBB-Ottobrunn",[11.6551316, 48.0490847]],
    [ "Umspannwerk Teisnach",[12.9839096, 49.0430049]],
    [ "Gleichrichterunterwerk Wannsee",[13.1735954, 52.4176691]],
    [ "Umspannwerk Freiburg - Brunnenmatte",[7.8021843, 48.0100229]],
    [ "Frankenberg",[8.8093071, 51.065982]],
    [ "Umspannwerk Murrhardt",[9.5581105, 48.9824683]],
    [ "Umspannwerk Großer Dreesch",[11.4509963, 53.5924752]],
    [ "Umspannwerk Hagenwerder",[14.9453987, 51.0632702]],
    [ "Brachenfeld",[10.0227818, 54.086255]],
    [ "Umspannwerk Neumünster/Nord",[9.986872, 54.1175798]],
    [ "Ostermoor-West",[9.193007, 53.9092018]],
    [ "Lebenstedt-Nord",[10.3374559, 52.1688394]],
    [ "Umspannwerk Wiebestraße",[13.317477, 52.5259532]],
    [ "Kenz",[12.7420464, 54.3305676]],
    [ "S-Bahn Gleichrichterunterwerk Hohen Neuendorf",[13.2871572, 52.6749958]],
    [ "Poppendorf",[12.305802, 54.130407]],
    [ "Umspannwerk Plennin",[12.6091965, 54.1740314]],
    [ "Umspannwerk Warnemünde",[12.069988, 54.1596522]],
    [ "Umspannwerk Perleberg",[11.8540216, 53.0876554]],
    [ "Bansin",[14.1271974, 53.9606654]],
    [ "Goosefeld",[9.8125892, 54.4296996]],
    [ "Station Karnap",[6.9924831, 51.5148673]],
    [ "Itzehoe/West",[9.4507005, 53.967391]],
    [ "Schlüsselburg",[9.0842378, 52.4821226]],
    [ "Kraftwerk Ellgau",[10.8782533, 48.5968184]],
    [ "Umspannwerk Marne-West",[8.9947023, 53.9598455]],
    [ "Umspannwerk Quickborn Dithmarschen",[9.2179726, 54.0128843]],
    [ "Umspannwerk Kagers",[12.5544196, 48.8968835]],
    [ "Oldenburg Mitte",[8.2096376, 53.1452773]],
    [ "Witzenhausen",[9.8670252, 51.3341638]],
    [ "Schaltposten Hohen-Neuendorf",[13.2872522, 52.6753083]],
    [ "Umspannwerk Höhenrain",[12.8671001, 48.7963982]],
    [ "Umspannwerk Flintsbach",[12.1358555, 47.717573]],
    [ "Umspannwerk Haltingen",[7.6101955, 47.6181511]],
    [ "Umspannwerk Speichersdorf",[11.7825876, 49.8602242]],
    [ "Umspannwerk Grafenwöhr",[11.9110079, 49.7199878]],
    [ "Lindern",[7.8276783, 52.8569169]],
    [ "Station Bonifacius",[7.0833047, 51.4757435]],
    [ "Umspannwerk Rodewitz",[14.433133, 51.1037779]],
    [ "Holm",[9.6606846, 53.6166025]],
    [ "Cloppenburg West",[8.0079804, 52.8306921]],
    [ "Sage",[8.2235509, 52.9542454]],
    [ "Coldewei",[8.0775082, 53.5611702]],
    [ "Ölhaven",[8.1431418, 53.5562901]],
    [ "Hagermarsch",[7.2971795, 53.6373518]],
    [ "Carolinensiel",[7.8009417, 53.6853043]],
    [ "Umspannwerk Norderstedt",[9.9722029, 53.6879559]],
    [ "Wiesmoor",[7.7320811, 53.4131775]],
    [ "Umspannwerk Löningen",[7.7650809, 52.7406143]],
    [ "Harksheide",[10.0182071, 53.7109851]],
    [ "Umspannwerk",[9.1179493, 49.9852088]],
    [ "Lorup",[7.6035993, 52.9695981]],
    [ "Esterwegen",[7.6043583, 52.9699481]],
    [ "Garrel",[8.0271901, 52.964652]],
    [ "Friesoythe",[7.8756378, 53.0168093]],
    [ "Reinsbüttel",[8.909676, 54.1822647]],
    [ "Umspannwerk Ursulasried",[10.3168217, 47.7558868]],
    [ "Umspannwerk Bayreuth Nord",[11.5971742, 49.9614146]],
    [ "Umspannwerk Gerhardstraße",[10.1356636, 54.3319423]],
    [ "Umspannwerk Leinestraße",[9.9242406, 51.5254826]],
    [ "Umspannwerk Bertoldsheim",[11.0213394, 48.7365281]],
    [ "Ulrichstein",[9.2061192, 50.5794259]],
    [ "Umspannwerk Siemersplatz",[9.9608589, 53.5989787]],
    [ "Umspannwerk Uklei",[13.2316961, 52.539517]],
    [ "Umspannwerk Neubukow",[11.6834821, 54.0256]],
    [ "Herda",[10.0950982, 50.9415023]],
    [ "Farnroda",[10.3876594, 50.9297695]],
    [ "Eisenach/Wehr",[10.3352995, 50.9794102]],
    [ "Einbeck",[9.8540547, 51.8104477]],
    [ "Bebra",[9.7927622, 50.9634319]],
    [ "Gleichrichterwerk Borgfeld",[8.8980181, 53.1277786]],
    [ "Station Kierdorf",[6.7824493, 50.8373619]],
    [ "Hanekenfähr",[7.3096329, 52.4740806]],
    [ "Gleichrichterunterwerk Jungfernheide",[13.2969718, 52.5301607]],
    [ "Umspannwerk Goldsteinstraße",[8.6274669, 50.0849299]],
    [ "Umspannwerk Frankfurt Südwest",[8.6192231, 50.09188]],
    [ "Umspannwerk St. Georg",[10.0181201, 53.5546335]],
    [ "Unterwerk Langenfeld",[6.9375436, 51.1032721]],
    [ "Umspannwerk Großenhain",[13.5327762, 51.2763631]],
    [ "Umspannwerk Lommatzsch",[13.3194474, 51.1921676]],
    [ "Leer/West",[7.4311938, 53.2275133]],
    [ "UW Riesa Nord",[13.2793179, 51.3263395]],
    [ "Station Heinrich",[7.0724179, 51.4203955]],
    [ "Leer/Nord",[7.4239628, 53.2590136]],
    [ "Umspannwerk Linden",[7.1671532, 51.4217156]],
    [ "Station Hattingen",[7.1681933, 51.3902502]],
    [ "Kraftwerk Vohburg",[11.6015301, 48.7792729]],
    [ "Oberröppisch",[12.0614889, 50.8344702]],
    [ "Umspannwerk Angersdorf",[11.8361051, 51.4239538]],
    [ "Umspannwerk Halle Süd",[11.9834674, 51.4291652]],
    [ "Station Oberlar",[7.1368543, 50.8144324]],
    [ "Wattenbek",[10.0542632, 54.1788123]],
    [ "Umspannwerk Neufahrn",[12.1766321, 48.736878]],
    [ "Kirchmöser",[12.3935344, 52.387785]],
    [ "Umspannwerk Prim",[8.643953, 48.1459287]],
    [ "Linden",[9.1818365, 54.2561992]],
    [ "Umspannwerk Wahlstedt",[10.2092657, 53.9431515]],
    [ "Umspannwerk Plantage",[8.8045535, 53.0887155]],
    [ "Hemme/West",[9.0132924, 54.2751185]],
    [ "Heide",[9.107684, 54.1873314]],
    [ "Umspannwerk Güstrow Nord",[12.1875621, 53.8112783]],
    [ "Oldenburg Nord",[8.2194997, 53.1798742]],
    [ "Umspannwerk Weiherhammer",[12.0657381, 49.637193]],
    [ "Umspannwerk Magstadt",[8.9511503, 48.7426697]],
    [ "Engelsdorf",[12.4999894, 51.3359764]],
    [ "Station Laer",[7.277607, 51.4736979]],
    [ "Station Linde Gas",[7.2634127, 51.5661242]],
    [ "Station Wengerohr",[6.9217251, 49.9709429]],
    [ "Station Robert Müser",[7.3003016, 51.4921239]],
    [ "Schaltanlage Heng",[11.3677337, 49.2928241]],
    [ "Umspannwerk Benhausen",[8.8301254, 51.7283232]],
    [ "Umspannwerk Lauterhofen",[11.5990008, 49.3947174]],
    [ "Beetz",[13.017744, 52.820777]],
    [ "Umspannwerk Eppendorf",[7.1802613, 51.452964]],
    [ "Waldkappel",[9.8854661, 51.149464]],
    [ "Sontra",[9.9108958, 51.0532081]],
    [ "Umspannwerk Krün",[11.2775034, 47.4963392]],
    [ "Station Bochum",[7.1779892, 51.473071]],
    [ "Station Bochum",[7.1793306, 51.4755506]],
    [ "Weißenhäuser Strand",[10.7740615, 54.2966579]],
    [ "Felde",[9.9506622, 54.3224506]],
    [ "Umspannanlage Schmelz",[6.8724203, 49.4459]],
    [ "S-Bahn Gleichrichterunterwerk Alexanderplatz",[13.4064138, 52.5234295]],
    [ "Lumda",[8.7946645, 50.6676421]],
    [ "Neumünster/Süd",[9.9865909, 54.0494527]],
    [ "Umspannanlage Hüls",[6.4987966, 51.3648293]],
    [ "Umspannwerk Horneckerweg",[11.0780906, 49.4710936]],
    [ "Umspannwerk Bogenhausen",[11.6216312, 48.1535314]],
    [ "Altenbeken",[8.9556831, 51.7672076]],
    [ "Ilsenburg",[10.7035181, 51.8735138]],
    [ "Trafostation 4",[13.0911553, 51.5077499]],
    [ "Umspannwerk Ziegelsee",[11.4091726, 53.6416186]],
    [ "Umspannwerk Sattlerstraße",[9.1689276, 48.7833334]],
    [ "UW Burg",[14.3814702, 51.4398864]],
    [ "Neumünster/West",[9.9751579, 54.0625227]],
    [ "Altenrheine",[7.4344593, 52.2990512]],
    [ "Station Habinghorst",[7.2968651, 51.5912767]],
    [ "Station Sulterkamp",[6.9769541, 51.4886191]],
    [ "Station Prosper II",[6.9606079, 51.5153126]],
    [ "Station Welheim",[6.9827915, 51.5350529]],
    [ "Umspannwerk Freudenstadt",[8.4177476, 48.4695281]],
    [ "Schinne/West",[11.7151267, 52.6522734]],
    [ "Neermoor",[7.4512809, 53.2991789]],
    [ "UW Nortorf",[9.8524754, 54.1727657]],
    [ "Schaltposten Neufahrn",[11.6696948, 48.3274311]],
    [ "Station Dümpten",[6.8846616, 51.4560471]],
    [ "Umspannwerk Puschwitz-Guhra",[14.2951753, 51.2375566]],
    [ "Umspannwerk Rodenberg",[9.3468864, 52.3217549]],
    [ "UW Sparrenberg",[8.528514, 52.0160693]],
    [ "Umspannwerk Sachsenhagen",[9.2680863, 52.4069157]],
    [ "Umspannwerk Prenzlau",[13.8723459, 53.3415812]],
    [ "Station Jahnstraße",[6.9649382, 51.4747533]],
    [ "Umspannwerk Großmehring",[11.5148831, 48.7883024]],
    [ "110-kV-Umspannwerk Gernsbach",[8.3456215, 48.7736845]],
    [ "110kV-Umspannwerk Allmendingen",[9.7316401, 48.3367033]],
    [ "Umspannwerk Rottenegg",[11.6622212, 48.654602]],
    [ "Station Lintorf",[6.8231048, 51.3429132]],
    [ "Unterwerk Biblis",[8.4445742, 49.6846259]],
    [ "Unterwerk Rohrbach",[9.7031176, 49.982916]],
    [ "Schalthaus 1",[7.3241358, 51.4319285]],
    [ "Umspannwerk Niesky",[14.8412813, 51.3032115]],
    [ "Knotenpunktstation Obergasse",[7.3382508, 51.4392219]],
    [ "Unterwerk Grönhart",[10.9304943, 48.990399]],
    [ "Eggesin",[14.0926705, 53.6760928]],
    [ "Gleichrichterwerk Berliner Tor",[10.0262246, 53.5527593]],
    [ "Umspannwerk Kraftwerk Herrenwyk",[10.8030338, 53.8983415]],
    [ "Großenlüder",[9.5389954, 50.6019088]],
    [ "10 kV-Station Soldnerstr.14 A",[7.2665488, 51.451908]],
    [ "10 kV Sprockhövel",[7.2494796, 51.3681556]],
    [ "Umspannwerk Baruth Nord",[13.5203127, 52.0806304]],
    [ "Schaltanlage Wilstedt",[10.069986, 53.7424722]],
    [ "Umspannwerk Leitershofen",[10.84766, 48.3452957]],
    [ "Gotha Gleichenstraße",[10.7278984, 50.9534016]],
    [ "UW Marienberg",[12.1299959, 47.9144505]],
    [ "Umspannwerk Meitingen",[10.8628845, 48.5455322]],
    [ "Station KFA",[6.4069781, 50.9125158]],
    [ "10kV-Station Robert-Koch-Str. 1",[7.2724577, 51.4538873]],
    [ "Umspannwerk Bischheim",[8.0290908, 49.667117]],
    [ "Umspannwerk Bidingen",[10.7016704, 47.8260179]],
    [ "Station Osterath",[6.6215658, 51.2598046]],
    [ "Umspannwerk Lahr-Stadt",[7.860494, 48.3385543]],
    [ "Umspannwerk Landshut",[12.1638395, 48.5467264]],
    [ "Umspannwerk Altheim",[12.2174023, 48.5860548]],
    [ "Isar",[12.2948145, 48.6070861]],
    [ "Umspannwerk Klingenthal",[12.4688289, 50.3739208]],
    [ "Umspannwerk Erkner",[13.7575267, 52.4059784]],
    [ "US Steinenhaus",[7.2524699, 51.4046559]],
    [ "Brieskow-Finkenheerd",[14.5785773, 52.264412]],
    [ "Umspannwerk Augsburg-Ost",[10.9249207, 48.3697429]],
    [ "Umspannwerk Plattling",[12.8521936, 48.7659566]],
    [ "Unterwerk Plattling",[12.8516455, 48.7806249]],
    [ "Umspannwerk Plattling",[12.8508742, 48.76311]],
    [ "Umspannwerk Landau",[12.6795176, 48.6695742]],
    [ "Dauer",[13.9468508, 53.3777211]],
    [ "Wilsickow",[13.8529466, 53.4952173]],
    [ "Angermünde",[13.9947807, 53.0271346]],
    [ "Mürow Oberdorf",[14.0531372, 53.0566744]],
    [ "ehemaliges Umspannwerk Idastraße",[13.4108766, 52.5839824]],
    [ "Schuby",[9.4750905, 54.5183231]],
    [ "Hage-Nord",[7.2975591, 53.63697]],
    [ "Heinrichsfeld",[12.4046614, 52.8995081]],
    [ "Neustadt/Dosse",[12.4600888, 52.8514379]],
    [ "Ueckermünde",[14.0589818, 53.7265221]],
    [ "Bad Freienwalde",[14.0088204, 52.7859744]],
    [ "Umspannwerk Schönefeld Süd",[13.5343915, 52.3669302]],
    [ "Station Haltern",[7.1699981, 51.732876]],
    [ "Station Jülich Kinofilm",[6.358272, 50.9063716]],
    [ "Station Dörth",[7.5917363, 50.1520061]],
    [ "Umspannwerk Landsberg",[10.8535198, 48.0581675]],
    [ "10 kV-Station Höfestr. 79",[7.2677308, 51.4622689]],
    [ "Greit",[8.0058125, 48.7537707]],
    [ "Schwelm",[7.294774, 51.2978902]],
    [ "Station Holzen Mast 30",[7.5427065, 51.4533221]],
    [ "Diefflen",[6.7546096, 49.3654076]],
    [ "Ochtrup",[7.1802413, 52.1989499]],
    [ "Umspannanlage Ensdorf",[6.7752817, 49.2896771]],
    [ "S-Bahn Gleichrichterunterwerk Poelchaustraße",[13.5348226, 52.5352586]],
    [ "Umspannanlage Sandkaule",[7.1032874, 50.7385375]],
    [ "Umspannwerk Walkertshofen",[10.5987836, 48.2109538]],
    [ "Umspannwerk Eutin",[10.6206588, 54.1229489]],
    [ "Umspannwerk Laim",[11.5018553, 48.1347756]],
    [ "Umspannwerk Hadern",[11.4806611, 48.1170133]],
    [ "Umspannwerk Kistlerhofstraße",[11.5067243, 48.0944236]],
    [ "Umspannwerk Siemens Hofmannstraße",[11.5272298, 48.0950298]],
    [ "Umspannwerk Thalkirchen",[11.537404, 48.1005357]],
    [ "Elverlingsen",[7.7055827, 51.2792324]],
    [ "Melle",[8.3318483, 52.210619]],
    [ "Umspannwerk Weiberhof",[9.2399992, 50.0069317]],
    [ "Oege",[7.59711, 51.3471168]],
    [ "Volmarstein",[7.394543, 51.3710941]],
    [ "Buchholz",[9.841497, 53.32651]],
    [ "Umspannwerk Jessen",[12.9729901, 51.7916152]],
    [ "Umspannwerk Schornsheim",[8.1522101, 49.8414203]],
    [ "Tulpenstraße",[8.0044665, 48.7483219]],
    [ "Vöhringen Amprion",[10.0812707, 48.2643308]],
    [ "Mukran",[13.5504644, 54.5044524]],
    [ "Umspannwerk Reichwalde",[14.6435534, 51.4095949]],
    [ "Schaltposten Frankfurt/M.",[8.6492894, 50.0999679]],
    [ "Station Heidhausen - Umspannwerk",[7.0170203, 51.3709421]],
    [ "Station Bockum-Hövel",[7.7432212, 51.6840465]],
    [ "Umspannwerk Hornstorf",[10.7201633, 53.7832832]],
    [ "S-Bahn Gleichrichterunterwerk Hohenschönhausen",[13.5170784, 52.5635795]],
    [ "Hamm Hafen",[7.7730345, 51.6766629]],
    [ "Station Heinrich Robert",[7.7586642, 51.6529215]],
    [ "Endmastanlage Metallguß",[12.2877521, 51.3445607]],
    [ "Umspannwerk Schönwald",[8.2084211, 48.1148584]],
    [ "Halbemond",[7.2633109, 53.5621362]],
    [ "Umspannwerk Augsburg-Nord",[10.8878243, 48.3915165]],
    [ "Umspannwerk Rielasingen",[8.8361157, 47.7417153]],
    [ "Schaltposten Heilbronn",[9.1986157, 49.1404884]],
    [ "Unterwerk Orscheid",[7.3231631, 50.6555976]],
    [ "Station Möbeck",[7.0944647, 51.2499433]],
    [ "Umspannwerk Friedberg",[10.9743472, 48.3469941]],
    [ "Umspannwerk Gersthofen",[10.887265, 48.4441658]],
    [ "UW Balzhausen",[10.4941382, 48.2478209]],
    [ "Umspannwerk Lagerlechfeld",[10.840813, 48.1851826]],
    [ "Umspannwerk Bobingen",[10.8065544, 48.2643036]],
    [ "Umspannwerk Crailsheim",[10.0465081, 49.1204366]],
    [ "UA Velsen",[6.8333789, 49.2168892]],
    [ "Umspannanlage Berrenrath",[6.8251082, 50.8637458]],
    [ "Umspannwerk Ost",[10.0062744, 54.084836]],
    [ "Station Ittenbach",[7.2937848, 50.6909359]],
    [ "Umspannwerk Station Euskirchen",[6.8189826, 50.6563294]],
    [ "Umspannwerk Station IPAS",[6.838052, 50.6860918]],
    [ "Station Vernich",[6.8345541, 50.742955]],
    [ "Umspannwerk Station Kommerner Straße",[6.7674028, 50.656002]],
    [ "Dölitz",[12.681756, 53.9381087]],
    [ "Lensahn",[10.9041064, 54.2155633]],
    [ "Umspannwerk Göhl",[10.9376606, 54.2839383]],
    [ "Cismar West",[10.9659054, 54.188881]],
    [ "Umspannwerk Rogerfelde",[10.7784054, 54.1104298]],
    [ "Lütjenbrode",[11.0426983, 54.3612848]],
    [ "Heiligenhafen",[10.9770147, 54.3619655]],
    [ "220-kV-Umspannwerk Eichstetten",[7.7511643, 48.096829]],
    [ "UW Ulberndorf",[13.6715812, 50.8709413]],
    [ "Brokdorf",[9.3427932, 53.8516638]],
    [ "Unterwerk Stolberg",[6.2023464, 50.791554]],
    [ "Unterwerk Sindorf",[6.6545711, 50.8928849]],
    [ "Umspannwerk Dotternhausen",[8.7775349, 48.2312602]],
    [ "Schaltposten Troisdorf",[7.1425472, 50.8170704]],
    [ "Station Mettmann",[6.9965868, 51.2507299]],
    [ "Station Mettmann",[6.9992367, 51.2491696]],
    [ "Umspannwerk Nagold",[8.6986932, 48.5613541]],
    [ "Unterwerk Urbach",[7.5734923, 50.5541858]],
    [ "Unterwerk Montabaur",[7.8207673, 50.4451005]],
    [ "Bornhöved",[10.2320045, 54.0632618]],
    [ "Umrichterwerk Limburg",[8.0670178, 50.4051195]],
    [ "Schwerin Süd",[11.4015376, 53.5756114]],
    [ "Tarp",[9.4090132, 54.6705653]],
    [ "Flensburg",[9.315706, 54.7163546]],
    [ "Weding",[9.4057525, 54.7518837]],
    [ "Lindewitt",[9.161355, 54.7133727]],
    [ "Schobüll",[9.2317084, 54.6837767]],
    [ "Rosendahl",[9.095478, 54.4731074]],
    [ "Friedrichstadt",[9.1085371, 54.3731058]],
    [ "Dorheim",[8.8074671, 50.3564792]],
    [ "Station Wallenthal",[6.5920018, 50.5731828]],
    [ "Station Kall",[6.5625049, 50.547914]],
    [ "Station Arzfeld",[6.279072, 50.1029965]],
    [ "Station Gerolstein",[6.6584809, 50.2155751]],
    [ "Station Jünkerath",[6.5926883, 50.3405368]],
    [ "Umspannwerk Blankenburg",[10.9456961, 51.808518]],
    [ "Station Balken",[7.0191741, 51.0928169]],
    [ "Umspannwerk Zollhaus",[8.5496393, 47.8318431]],
    [ "Unterwerk Herchen",[7.5216842, 50.7702337]],
    [ "Umspannwerk",[14.3173902, 53.0804821]],
    [ "Nette",[10.077657, 52.0362773]],
    [ "Kyritz",[12.4023951, 52.949032]],
    [ "Schwedt Heinersdorf",[14.2061013, 53.0641168]],
    [ "Finow",[13.7446849, 52.8416865]],
    [ "Eberswalde",[13.8107847, 52.8364932]],
    [ "Gummersbach",[7.5560244, 51.0200942]],
    [ "Station Daun",[6.8275985, 50.2036609]],
    [ "Letschin",[14.3405803, 52.6377054]],
    [ "VW/Süd",[7.1583361, 53.3431423]],
    [ "Lörrach",[7.6716199, 47.622359]],
    [ "Umspannwerk Regen",[13.1492152, 48.9736819]],
    [ "Hohenwestedt",[9.6474718, 54.0845085]],
    [ "Pfeffenhausen UW",[11.9517515, 48.6604124]],
    [ "Dörpum",[8.9979697, 54.6421328]],
    [ "Umspannwerk Rottenburg",[8.9338894, 48.4927898]],
    [ "Lemmerz-Werke",[7.1874816, 50.6864482]],
    [ "Station Pulheim",[6.7907216, 51.0020215]],
    [ "Unterwerk Bengel",[7.0559369, 50.0094974]],
    [ "Benz",[11.5554475, 53.8950005]],
    [ "Haffeld",[11.470759, 53.9108392]],
    [ "Schönberg",[10.9466749, 53.8410136]],
    [ "Tönning",[8.9423158, 54.3257634]],
    [ "Bederkesa",[8.8241556, 53.6133281]],
    [ "10 kV-Station Liebfrauenstr. 19",[7.2458891, 51.4745811]],
    [ "Unterwerk Oberdachstetten",[10.4253605, 49.4175432]],
    [ "Schwörstadt",[7.8422827, 47.5865281]],
    [ "Netzknoten Kühmoos",[7.9553237, 47.5933359]],
    [ "Schaltanlage Gundelfingen",[10.3903212, 48.5200917]],
    [ "UW Goldhausen",[7.8650458, 50.4622703]],
    [ "Meldorf",[9.0633908, 54.0834696]],
    [ "Hademarschen",[9.4079981, 54.1166334]],
    [ "Breklum",[8.9873855, 54.5995333]],
    [ "Unterwerk Saarbrücken",[6.9772936, 49.2442963]],
    [ "Station Eiserfeld",[7.9840605, 50.8415458]],
    [ "Umspannwerk Rennertshofen",[11.0375431, 48.7659837]],
    [ "Windheim",[11.3478725, 50.4287645]],
    [ "Unterwerk Steinbach am Wald",[11.3816784, 50.4366075]],
    [ "Umspannwerk Wertingen",[10.6934845, 48.561393]],
    [ "Unterwerk Würzburg",[9.8974371, 49.8015173]],
    [ "Gemünden",[8.9676023, 50.9856843]],
    [ "Friedensdorf",[8.552948, 50.8548449]],
    [ "Bahnstrom Unterwerk Waigolshausen",[10.1161053, 49.967126]],
    [ "Unterwerk Emskirchen",[10.6951488, 49.5584287]],
    [ "Umspannwerk Parchim",[11.8133782, 53.4242739]],
    [ "Station Wolfhagen",[6.9209693, 51.1226158]],
    [ "Station Sinspelt",[6.3218129, 49.967147]],
    [ "SA Bauler",[6.2117355, 49.9591348]],
    [ "Vianden",[6.1774398, 49.9537003]],
    [ "Windecken",[8.8693028, 50.2197093]],
    [ "Station Beilingen",[6.6535875, 49.9483906]],
    [ "Station Bitburg",[6.5347268, 49.9649075]],
    [ "Brucköbel",[8.9026135, 50.1928134]],
    [ "Station Welschbillig",[6.5388139, 49.8557853]],
    [ "Station Kyllburg",[6.6028552, 50.0314755]],
    [ "Umspannwerk Heilbronn",[9.2075797, 49.1803121]],
    [ "Umspannwerk Weinsberg",[9.2898223, 49.1597185]],
    [ "Umspannwerk Mölln West",[10.6659877, 53.6227496]],
    [ "Umspannwerk Bargteheide",[10.2688572, 53.7406657]],
    [ "Station Saarwellingen",[6.7645161, 49.3604252]],
    [ "UA Hilschbach",[6.9468481, 49.3226926]],
    [ "Station Holzbüttgen",[6.6297356, 51.216775]],
    [ "Station Neuss Jülicherstraße",[6.6797029, 51.183644]],
    [ "Bleckenstedt",[10.3773742, 52.1821571]],
    [ "10 kV-Station Kemnader Str. 4",[7.2126833, 51.4401041]],
    [ "RWE Umspannanlage",[6.7385267, 51.1689881]],
    [ "Lauingen",[10.4180096, 48.5718834]],
    [ "Ohmtal",[9.0165996, 50.7292045]],
    [ "Lahntal",[8.7551805, 50.8737664]],
    [ "Umspannwerk Harburg West",[10.6986511, 48.7757781]],
    [ "Schaltanlage St. Barbara",[7.2387503, 49.3578166]],
    [ "Niebüll",[8.8604677, 54.7914258]],
    [ "UA Rohrbach",[7.1474602, 49.2854674]],
    [ "Umspannwerk Leinau Nr. 730",[10.6189156, 47.9170815]],
    [ "Umspannwerk",[10.8679902, 48.148724]],
    [ "Umspannwerk Unterbergen",[10.927955, 48.2379984]],
    [ "Umspannwerk Stätzling",[10.9701785, 48.3956908]],
    [ "UA Merzig",[6.6291224, 49.4545482]],
    [ "Umspannwerk Brüchermühle",[7.6454622, 50.9279298]],
    [ "Umspannwerk Weiler",[9.9150358, 47.5896719]],
    [ "Unterwerk Limburg",[8.0999195, 50.3796069]],
    [ "Unterwerk Essen",[7.0274653, 51.4564303]],
    [ "Unterwerk Koblenz",[7.5655651, 50.3867476]],
    [ "Unterwerk Finnentrop",[7.9665818, 51.1684089]],
    [ "Station Unterfeldhaus",[6.9293869, 51.2075342]],
    [ "Station Erkrath",[6.9107205, 51.2265396]],
    [ "Falkenhagen",[12.2321146, 53.1996147]],
    [ "Umspannwerk Wittstock",[12.477622, 53.176427]],
    [ "UA Obergraben",[6.7492208, 49.3651828]],
    [ "UA Dillinger Hütte Mitte",[6.7407371, 49.3575833]],
    [ "Umspannwerk Pritzwalk",[12.1788095, 53.1346775]],
    [ "Umspannwerk Jabel",[12.4545908, 53.1690473]],
    [ "Setzer Wiese",[8.0185134, 50.924238]],
    [ "Daimlerstraße",[10.3245705, 47.7590298]],
    [ "Station Bochum",[7.2211145, 51.4523594]],
    [ "Vattenfall Wasserkraft GmbH",[11.7162279, 50.5244172]],
    [ "Umspannwerk Schwarzheide-Ost",[13.9050322, 51.4725766]],
    [ "Gleichrichterwerk Flottbek",[9.8589072, 53.5578195]],
    [ "Umspannwerk Crivitz",[11.6576167, 53.5713678]],
    [ "Umspannwerk Althegneberg",[11.0745413, 48.244511]],
    [ "Umspannwerk Kempten-Au",[10.318603, 47.6833479]],
    [ "Umspannwerk Krugzell",[10.2718062, 47.7983459]],
    [ "Station Saarburg",[6.5381741, 49.5983964]],
    [ "UW2",[7.0403537, 51.3489486]],
    [ "UW1",[7.0598192, 51.3395205]],
    [ "Umspannwerk Pirach",[12.7454411, 48.1495426]],
    [ "Umspannwerk Simbach",[13.0025783, 48.2609453]],
    [ "Prien",[12.3345264, 47.8560108]],
    [ "Station Kamp",[6.5517129, 51.5092239]],
    [ "Umspannwerk Grabenstätt",[12.5429873, 47.8493135]],
    [ "Umspannwerk Wolkersdorf",[12.6187542, 47.8833259]],
    [ "Station Löhnen",[6.6613936, 51.5984374]],
    [ "Station Eppinghoven",[6.7051363, 51.5591959]],
    [ "Dannenberg",[11.0660335, 53.093091]],
    [ "Station Bekond",[6.7923427, 49.8525096]],
    [ "Moselstahlwerk",[6.692815, 49.7925291]],
    [ "Lankow",[11.3690356, 53.6459687]],
    [ "Station Laufeld",[6.8702173, 50.0852179]],
    [ "Station Pünderich",[7.1202814, 50.0388393]],
    [ "10 kV-Station Alleestr. 80 A",[7.2016833, 51.4815468]],
    [ "Station Mündelheim",[6.7234377, 51.3567687]],
    [ "Krefeld/Mitte",[6.5873192, 51.3282385]],
    [ "10 kV-Station Karl-Lange-Str. 2",[7.239973, 51.4899594]],
    [ "Belecke",[8.3495441, 51.4880221]],
    [ "Station Rheinhausen",[6.7212788, 51.3906784]],
    [ "Umspannwerk Petersaurach",[10.7414547, 49.3006296]],
    [ "Station Bernkastel",[7.0563681, 49.9299091]],
    [ "Station Detzem",[6.8432421, 49.8110499]],
    [ "Station Gusterath",[6.7021055, 49.7111737]],
    [ "Station Luckhausen",[7.2516144, 51.2221559]],
    [ "Station Linde",[7.2344139, 51.2334437]],
    [ "Ueberfeld",[7.2272737, 51.1874102]],
    [ "Rheinshagen",[7.1474897, 51.1623452]],
    [ "Station Bayerwerk Dormagen",[6.8328209, 51.0844118]],
    [ "Hausham",[11.8421373, 47.7510818]],
    [ "Kraftwerk Rosenheim-Rohrdorf",[12.1552664, 47.8366194]],
    [ "Umspannwerk Obing",[12.4001906, 47.9984444]],
    [ "Umspannwerk Traunreut",[12.605879, 47.954695]],
    [ "Station Hüthum",[6.2103451, 51.8476277]],
    [ "Station Geldern",[6.32973, 51.5109951]],
    [ "Station Kevelaer",[6.2587497, 51.5825152]],
    [ "Umspannwerk Hart an der Alz",[12.6222643, 48.147018]],
    [ "Freiburg/Elbe",[9.2829427, 53.8169663]],
    [ "Umspannwerk Neuötting",[12.6892828, 48.2485437]],
    [ "Umspannwerk Töging",[12.5857541, 48.248084]],
    [ "Station Kempen",[6.4360648, 51.3709825]],
    [ "Station Schaphausen",[6.3279037, 51.332719]],
    [ "Station Graft",[6.5747877, 51.524638]],
    [ "Umspannanlage Nottuln",[7.3694792, 51.9294001]],
    [ "Umspannanlage Ketteldorf",[10.7570106, 49.3550793]],
    [ "Umspannwerk Neudorf",[10.6724656, 49.4198313]],
    [ "Umspannwerk Bautzen",[14.3992981, 51.1805555]],
    [ "Umspannwerk Dörrenbach",[8.0017384, 49.0908431]],
    [ "Umspannwerk Mittergars",[12.3386847, 48.1535694]],
    [ "Umspannwerk Siegsdorf",[12.6559804, 47.815435]],
    [ "Grassau",[12.4476967, 47.7801565]],
    [ "Franz-Marc-Straße",[8.6881849, 49.3937653]],
    [ "Umspannwerk Waldkraiburg",[12.4005937, 48.2014502]],
    [ "Umspannwerk Altmühldorf",[12.4854866, 48.2472104]],
    [ "Umspannwerk Stollnkirchen",[12.1818255, 48.2538501]],
    [ "Umspannwerk Altdorf",[12.1813255, 48.152785]],
    [ "Umspannwerk Geretsried",[11.505708, 47.8568065]],
    [ "Umspannwerk Bogen Alt",[12.704724, 48.9166438]],
    [ "Umspannwerk Aichach",[11.142353, 48.4647547]],
    [ "Umspannwerk Odelzhausen",[11.1872869, 48.3120517]],
    [ "Hörne",[8.0354585, 52.2610337]],
    [ "Umspannwerk Au",[11.7306918, 48.5527433]],
    [ "Umspannwerk Münchsmünster",[11.701213, 48.7607935]],
    [ "Umspannwerk Saal an der Donau",[11.9226204, 48.9032633]],
    [ "Umspannwerk Riedenburg",[11.6743825, 48.9706733]],
    [ "Bachhausen",[11.4440705, 49.1474423]],
    [ "Station Ruschberg",[7.279693, 49.6224962]],
    [ "Station Birkenfeld",[7.1589988, 49.6356699]],
    [ "Station Süchteln",[6.3849098, 51.2873167]],
    [ "Schaltanlage Loger Straße",[8.7837358, 53.2244693]],
    [ "Scharmbeck",[8.783079, 53.2243911]],
    [ "Umspannwerk Scharbeutz",[10.73931, 54.0315527]],
    [ "Station Bracht",[6.2008473, 51.268642]],
    [ "Station Schwarzbach",[6.868927, 51.2882001]],
    [ "Umspannwerk Pfarrkirchen",[12.9351819, 48.4195181]],
    [ "Umspannwerk Karlstadt",[9.7772841, 49.9514045]],
    [ "Umspannwerk Fuchsstadt",[9.9237767, 50.106091]],
    [ "Umspannanlage Hafen",[6.6570579, 51.3426105]],
    [ "Station Edelstahl Krefeld",[6.5412576, 51.2990184]],
    [ "Umspannwerk Gefrees",[11.7460887, 50.1041619]],
    [ "Bad Kissingen",[10.0792857, 50.1828996]],
    [ "Bad Brückenau",[9.8082519, 50.3182935]],
    [ "Eltingshausen",[10.1157137, 50.1632821]],
    [ "Brendlorenzen",[10.2154307, 50.3385814]],
    [ "Kleinbardorf",[10.3902208, 50.2799854]],
    [ "Haßfurt",[10.5293458, 50.032609]],
    [ "Umspannwerk Mechlenreuth",[11.8113403, 50.1826432]],
    [ "Überherrn",[6.7261206, 49.2727248]],
    [ "Kolpingstraße",[10.3050713, 47.7382269]],
    [ "Waldershof",[12.0720639, 49.9858993]],
    [ "Selb",[12.1417083, 50.1740764]],
    [ "Arzberg",[12.2037117, 50.0584521]],
    [ "Wunsiedel",[12.0043887, 50.0479594]],
    [ "Umspannwerk Freiburg - Vordermattenstraße (110 kV / 20 kV)",[7.8513944, 48.0237504]],
    [ "Fünfeichen",[13.2713552, 53.5368092]],
    [ "Warliner Straße",[13.2951747, 53.5630532]],
    [ "Umspannwerk Freiburg - Mooswaldallee",[7.857043, 48.0355079]],
    [ "Umspannwerk Burglengenfeld",[12.0230039, 49.2140617]],
    [ "Umspannwerk Denzlingen",[7.9115593, 48.0760203]],
    [ "Altentreptow",[13.2381704, 53.6826656]],
    [ "Umspannwerk Niederndorf",[10.6146255, 49.7287518]],
    [ "Neubruck 01",[10.6807596, 49.3412556]],
    [ "Heisterbusch",[10.8155474, 54.0986249]],
    [ "Am Alten Holzplatz",[10.3213826, 47.7201278]],
    [ "Umspannwerk Bahrenkrug",[10.475186, 54.0431649]],
    [ "Lindenhof",[13.0045828, 53.8587289]],
    [ "Umspannwerk Dinkelsbühl",[10.3418792, 49.0708137]],
    [ "Umspannwerk Waizendorf",[10.5366049, 49.1607736]],
    [ "Umspannwerk Rendsburg Nord",[9.6537693, 54.3170634]],
    [ "Station Ichendorf",[6.704346, 50.9307483]],
    [ "Schaltanlage Tollhausen",[6.5264318, 50.9391872]],
    [ "Umspannwerk",[12.5194568, 52.4185714]],
    [ "10 kV-Station Kronenstr. 1A",[7.2160299, 51.4747052]],
    [ "Umspannwerk Osthafenplatz",[8.7121273, 50.110557]],
    [ "Umspannwerk Denzingen",[10.2760939, 48.4426534]],
    [ "Umspannwerk Wohlfahrtstr.",[7.2240201, 51.4525319]],
    [ "Station 687",[10.1156834, 48.4236763]],
    [ "Kraftwerk Egglfing",[13.3186057, 48.3206052]],
    [ "10 kV-Station Romanusplatz",[7.2152061, 51.468925]],
    [ "UW Ellwangen",[10.1280116, 48.9720299]],
    [ "Umspannwerk",[10.4926787, 48.8574675]],
    [ "Umspannwerk Wechingen",[10.6296974, 48.899876]],
    [ "Neumünster/Mitte",[9.9890847, 54.0818232]],
    [ "10 KV Rheinische Straße",[7.2891929, 51.2951076]],
    [ "UW Buchloe",[10.7162835, 48.0256283]],
    [ "Umspannwerk Singham",[13.193112, 48.4200961]],
    [ "Umspannwerk Burghausen",[12.8335668, 48.1985125]],
    [ "10 kV-Station Max-Greve-Str 19",[7.2325894, 51.4863223]],
    [ "Umspannanlage Kleinostheim",[9.0442231, 50.0207815]],
    [ "Umspannwerk Vilsbiburg",[12.3652658, 48.4575277]],
    [ "Umspannwerk Winhöring",[12.6197357, 48.2604128]],
    [ "Umspannwerk Vohenstrauß",[12.3252773, 49.6401449]],
    [ "Bremervörde",[9.1350042, 53.4745834]],
    [ "Umspannwerk Leipzig K",[12.3900128, 51.3417628]],
    [ "Eschershausen",[9.623314, 51.9265659]],
    [ "Halle",[9.5689946, 51.9973475]],
    [ "Umspannwerk Coppenbrügge",[9.5612451, 52.1200815]],
    [ "Umspannwerk Bad Oldesloe",[10.4022465, 53.8112591]],
    [ "Umspannwerk Sandesneben",[10.4843221, 53.6851903]],
    [ "Umspannwerk Bad Schwartau West",[10.6661476, 53.9205456]],
    [ "Schaltanlage Teutendorf",[10.8322318, 53.9605197]],
    [ "Station Powe",[8.1206936, 52.3149397]],
    [ "Umspannwerk Großensee",[10.3332683, 53.6143212]],
    [ "Trafo",[7.6907794, 51.5346982]],
    [ "Naab",[12.0956341, 49.3329231]],
    [ "Bad Hersfeld",[9.7296189, 50.8636289]],
    [ "Süderbrarup",[9.757453, 54.6350698]],
    [ "UW Struxdorf",[9.6432524, 54.629487]],
    [ "Station Schmallenberg",[8.2948354, 51.1599313]],
    [ "Station Berghausen-Berleburg",[8.3309514, 51.037432]],
    [ "Umspannwerk Marbach",[9.2360426, 48.9277149]],
    [ "Unterwerk Rudersdorf",[8.1493844, 50.8350381]],
    [ "Umspannwerk Leipzig A",[12.3464307, 51.3308845]],
    [ "Umspannwerk Walzwerk (UWWW)",[10.421375, 52.165823]],
    [ "Schöningen",[10.9718061, 52.1286727]],
    [ "Neumorschen",[9.5896522, 51.0625576]],
    [ "Melsungen",[9.5266477, 51.123427]],
    [ "10 kV Wasserstr. 158",[7.2310908, 51.4634406]],
    [ "Station Rees",[6.4198991, 51.766593]],
    [ "Station Millingen",[6.4219317, 51.8139524]],
    [ "Lauenburg/Elbe",[10.5377701, 53.3865646]],
    [ "Station Kamen",[7.6556557, 51.6001119]],
    [ "Wurzen",[12.7568296, 51.381842]],
    [ "Umspannwerk Sinning",[12.1799061, 47.7936289]],
    [ "Fürstenwalde",[14.0504712, 52.3826768]],
    [ "Wöhrden",[9.0373206, 54.1797743]],
    [ "Stephanskirchen",[12.172052, 47.8722866]],
    [ "Station Winterberg",[8.5317329, 51.2042279]],
    [ "Iburg",[8.0428287, 52.1467454]],
    [ "Hilchenbach Mühlenweg",[8.104688, 50.989427]],
    [ "Herzfelde",[13.8261824, 52.4866495]],
    [ "Station Erndtebrück",[8.2622328, 51.0004897]],
    [ "Oranienburg",[13.2186124, 52.7508461]],
    [ "Umspannwerk Waltherstraße",[11.5632279, 48.1280852]],
    [ "Umspannwerk Chemnitz Zentrum",[12.919651, 50.8384602]],
    [ "Umspannwerk Brünnstadt",[10.2844655, 49.8991588]],
    [ "Neuruppin",[12.7921424, 52.9280153]],
    [ "Umspannwerk Burgebrach",[10.7509424, 49.8296897]],
    [ "Röbel (Müritz)",[12.5814537, 53.3711675]],
    [ "Batzlow",[14.1152217, 52.6235067]],
    [ "Schaltwerk Bitterfeld Mitte",[12.3028651, 51.6273741]],
    [ "10 kV-Station Herner Str. 68",[7.2141161, 51.4898256]],
    [ "10 KV Elbscheweg",[7.3449198, 51.4024641]],
    [ "Oberauerbach",[10.4552356, 48.0437539]],
    [ "Rühle",[7.2411517, 52.6566327]],
    [ "Punkthäuser",[12.1342626, 50.5055586]],
    [ "Zella/Rhön",[10.1199631, 50.6753394]],
    [ "Gleichrichterunterwerk Kaulsdorf",[13.589723, 52.5114628]],
    [ "Werne-Bahnhof",[7.623156, 51.6701233]],
    [ "Umspannwerk Demmin",[13.0664798, 53.9100368]],
    [ "Siemens Energy",[6.8495312, 51.4375759]],
    [ "Umspannwerk Überlingen",[9.1904949, 47.7832023]],
    [ "Umspannwerk Buch",[9.5633647, 47.6846568]],
    [ "Umspannwerk Lochbrücke",[9.5271083, 47.6717314]],
    [ "Umspannwerk Langenargen",[9.5654632, 47.6183438]],
    [ "UW EnBW Markdorf",[9.3818544, 47.7164313]],
    [ "Umspannwerk Meersburg",[9.2874028, 47.6977142]],
    [ "Umspannwerk Weildorf",[9.3018872, 47.7926288]],
    [ "Beuren",[8.8628273, 47.8063679]],
    [ "Ebersdorf",[11.0779063, 50.2130037]],
    [ "Umspannwerk Tuttlingen",[8.79585, 47.9890659]],
    [ "EnBW Umspannwerk Rottweil",[8.6548678, 48.1413214]],
    [ "Umspannwerk Winterlingen",[9.1470207, 48.1767316]],
    [ "Umspannwerk Speyer-Süd",[8.4503424, 49.2983936]],
    [ "Umspannwerk Laucherthal",[9.2767113, 48.091537]],
    [ "Umspannwerk 7",[8.5093821, 49.5176748]],
    [ "Rheinhausen Krieger",[8.4962114, 49.2842187]],
    [ "Umspannwerk Vordermoos",[9.8059717, 47.7740808]],
    [ "Station Hünenfeld",[7.5445401, 50.2624751]],
    [ "Umspannwerk Wolfsberg",[8.7620289, 48.533599]],
    [ "Trafostation Arnsdorf 02",[13.9774194, 51.0949592]],
    [ "Station Wehofen",[6.7878809, 51.5337935]],
    [ "Station Flachsdick",[6.8426247, 51.5489015]],
    [ "Neustadt bei Coburg",[11.1159591, 50.3112988]],
    [ "10 kV-Station Hattinger Str. 993",[7.170212, 51.4149947]],
    [ "Friesen",[11.3561689, 50.2669547]],
    [ "Umspannwerk Dürrbachau",[9.8987657, 49.8055513]],
    [ "Unterwerk Friedberg",[8.7735399, 50.3249581]],
    [ "Umspannwerk Königsbrunn",[10.886729, 48.2831481]],
    [ "Dahlhauser Straße",[7.1624515, 51.4130116]],
    [ "Station Velau",[6.2255713, 50.789884]],
    [ "KEW Energieversorgung",[7.1936749, 49.3332892]],
    [ "VW/West",[7.1576784, 53.3521494]],
    [ "VW/Mitte",[7.1555447, 53.3500875]],
    [ "UA Köllerbach",[6.9123101, 49.2946174]],
    [ "Nordenham",[8.4612807, 53.4889678]],
    [ "Umspannwerk Reutin",[8.4560681, 48.3337875]],
    [ "Unterwerk Mehrhoog",[6.4986256, 51.7488096]],
    [ "Gadenstedt",[10.2029221, 52.2438652]],
    [ "Umspannwerk Großheubach",[9.2320747, 49.7156747]],
    [ "Gitter",[10.3524223, 52.0419317]],
    [ "Langelsheim",[10.3491682, 51.9442033]],
    [ "Station Ohligs",[7.0065222, 51.1744708]],
    [ "Auf der Brunke",[7.7254866, 50.1807959]],
    [ "Greene",[9.9339576, 51.8666012]],
    [ "Katzenstein",[10.2126931, 51.7576141]],
    [ "Gießen/Nord",[8.6536329, 50.6032091]],
    [ "Umspannwerk Babenhausen",[8.9466264, 49.9716082]],
    [ "Umspannanlage Berthastraße",[7.1702186, 51.5025269]],
    [ "Umspannwerk Algenrodt",[7.2813639, 49.7216819]],
    [ "Station Resse",[7.1156967, 51.5790212]],
    [ "Kusel",[7.4294853, 49.5430431]],
    [ "Umspannwerk Biestow",[12.1097892, 54.0691346]],
    [ "Krempel",[8.6914302, 53.7092917]],
    [ "Umspannwerk Kalsow",[11.5947285, 53.920453]],
    [ "Umspannwerk Brüel",[11.7327537, 53.7262485]],
    [ "Umspannwerk Allmand",[9.1034355, 48.7438636]],
    [ "Karstädt",[11.7421597, 53.1640829]],
    [ "Umformerwerk Wittenberge",[11.7719901, 52.9952347]],
    [ "Wittenberge",[11.7714209, 52.9934513]],
    [ "Umspannwerk Lübz",[12.0297502, 53.4740393]],
    [ "Techentin",[11.4901226, 53.3121156]],
    [ "Saarstahl",[7.1706896, 49.3560472]],
    [ "Gleichrichterwerk Münchenstraße",[10.4988418, 52.2542125]],
    [ "Umspannwerk Kelkheim Hornau",[8.4510331, 50.1499638]],
    [ "Umspannwerk Freiburg - Ferdinand-Weiss-Straße",[7.8344099, 47.9934354]],
    [ "Umspannwerk Walsum",[6.71042, 51.5262351]],
    [ "Heerwiese",[7.6123612, 51.2375677]],
    [ "Station Meschede",[8.2675439, 51.3509351]],
    [ "Station Zweckel",[6.9744078, 51.5989548]],
    [ "Umspannwerk Ost",[9.9838799, 48.4256696]],
    [ "Umspannwerk Markkleeberg",[12.3808077, 51.2778963]],
    [ "Stuhr",[8.7115821, 53.0294012]],
    [ "Lemwerder",[8.6072674, 53.1527382]],
    [ "Vechta Nord",[8.2725121, 52.7548799]],
    [ "Visbek",[8.3561596, 52.8087105]],
    [ "Wildeshausen",[8.4171687, 52.8875326]],
    [ "Vechta Süd",[8.2779964, 52.7009694]],
    [ "Lohne",[8.2013106, 52.6575552]],
    [ "Holdorf",[8.1302584, 52.5772108]],
    [ "Station Rheine",[7.4426339, 52.2619935]],
    [ "Umspannanlage Grube Warndt",[6.8154943, 49.1835099]],
    [ "Wulfenau",[8.048419, 52.6876541]],
    [ "Cloppenburg/Mitte",[8.0607415, 52.8448529]],
    [ "Dinklage",[8.1296346, 52.6763954]],
    [ "Lissberg",[9.0930625, 50.3754015]],
    [ "Umspannwerk Merching",[10.9388683, 48.2658187]],
    [ "Station Feldmark",[6.6081186, 51.6829644]],
    [ "Magdeburg/Mitte",[11.62837, 52.1271797]],
    [ "10 KV Dieselstr. 28",[7.2664552, 51.2810464]],
    [ "Umspannwerk Max-Eyth-Straße",[10.0233516, 48.3958298]],
    [ "Grafenrheinfeld",[10.19163, 49.9812443]],
    [ "Rheinfelden",[7.8018372, 47.5710944]],
    [ "10 kV-Station Blankensteiner Str. 245",[7.200471, 51.4269032]],
    [ "Beverstedt",[8.8055917, 53.4301266]],
    [ "10 kV-Station Blankensteiner Str. 144",[7.1932687, 51.4300857]],
    [ "Umspannwerk Bad Berneck",[11.6404244, 50.048389]],
    [ "Großenkneten",[8.2402607, 52.9286067]],
    [ "Umspannwerk Pirmasens",[7.5960231, 49.2128124]],
    [ "Kernkraftwerk Unterweser",[8.4784938, 53.4287193]],
    [ "Frankfurt (Oder) Autobahn",[14.4948556, 52.3197805]],
    [ "Umspannwerk Mieste",[11.2072775, 52.490653]],
    [ "Beeskow",[14.2548817, 52.1827298]],
    [ "Umspannwerk Freiberg Nord",[13.2561214, 50.9293535]],
    [ "Umspannwerk St. Peter",[8.6515961, 54.3051646]],
    [ "25KV Station Türnich",[6.7373464, 50.8710044]],
    [ "Unterwerk Rotenburg (Wümme)",[9.3510374, 53.1079776]],
    [ "10 kV-Station Nordring 29",[7.2156217, 51.4878798]],
    [ "Ohrdruf",[10.7327404, 50.8344317]],
    [ "Niederlehme",[13.6581203, 52.3325273]],
    [ "Umspannwerk Schießtal",[9.8313761, 48.8174618]],
    [ "UW Lechstaustufe 14",[10.8747477, 48.0096258]],
    [ "Biebermühle",[7.6099204, 49.2658373]],
    [ "Schwanheim",[7.8784891, 49.1839734]],
    [ "Umspannwerk Kandel",[8.1923334, 49.0876999]],
    [ "Lilienthal",[8.9508464, 53.1659487]],
    [ "Gnarrenburg",[9.0045492, 53.3747958]],
    [ "10 kV-Station Laerheidestr. 8",[7.2472571, 51.4532973]],
    [ "S-Bahn Gleichrichterunterwerk Pankow",[13.3973923, 52.5595278]],
    [ "Umspannwerk Döbern",[14.6097998, 51.6112509]],
    [ "UA St. Ingbert",[7.1026441, 49.2774182]],
    [ "Station Bocholt",[6.6145468, 51.8240623]],
    [ "Station Dingden",[6.6015294, 51.7737342]],
    [ "Station Borghorst",[7.3746443, 52.123327]],
    [ "Umspannwerk Goesharde 2",[8.9874423, 54.6006571]],
    [ "Station Münster",[7.592335, 51.9285604]],
    [ "Stickenbütteler Weg",[8.6636584, 53.878672]],
    [ "Industriestraße",[8.7110617, 53.8479734]],
    [ "Lehe",[8.6017056, 53.5595475]],
    [ "Umspannwerk Hochstadt",[11.2649574, 48.0545778]],
    [ "Station Eyllerberg",[6.5418047, 51.480889]],
    [ "Umspannwerk Seebach",[13.0155838, 48.7964504]],
    [ "Umspannwerk Hauzenberg",[13.6337477, 48.6390269]],
    [ "Umspannwerk Waldkirchen",[13.5954571, 48.7350702]],
    [ "Umspannwerk Grubweg",[13.4990944, 48.5903562]],
    [ "Umspannwerk Viechtach",[12.8722337, 49.0711572]],
    [ "Leinefelde",[10.3077997, 51.3850903]],
    [ "Sittensen",[9.5182045, 53.2766017]],
    [ "Geithe",[7.9306328, 51.6740894]],
    [ "Otterndorf",[8.8915006, 53.799289]],
    [ "Sottrum",[9.2489145, 53.1144747]],
    [ "Umspannwerk Meißen",[13.5068601, 51.1689963]],
    [ "Lauben",[10.2887623, 48.0678307]],
    [ "Station Eiberg",[7.1228078, 51.4487447]],
    [ "Trafostation Industriestraße I",[8.8141949, 53.236669]],
    [ "Trafostation Industriestraße II",[8.8177947, 53.235462]],
    [ "Station Baumstraße",[8.0650354, 52.2823721]],
    [ "Umspannwerk Lilienthalstraße",[12.0576988, 49.0176069]],
    [ "Umspannwerk Lochham",[11.4498967, 48.1220006]],
    [ "Steile Straße",[10.3081888, 47.7326161]],
    [ "Station Westerkappeln",[7.8911168, 52.275882]],
    [ "Station Halen",[7.9494654, 52.3280373]],
    [ "Umspannwerk Eggenfelden",[12.7501099, 48.3922486]],
    [ "Umspannwerk Göhl Wind",[10.9326462, 54.289305]],
    [ "Station Annaberg",[6.5739032, 51.5508663]],
    [ "Station Eversburg",[8.0100266, 52.3038326]],
    [ "Station Fürstenau",[7.6919561, 52.5127009]],
    [ "Station Freren",[7.5396013, 52.4798001]],
    [ "Umspannwerk Eberbach",[8.9950477, 49.459851]],
    [ "Umspannwerk Neuses",[11.3052101, 50.2097096]],
    [ "Trafostation Am Eichhof",[8.7773827, 53.2256303]],
    [ "Umspannwerk Weidach",[13.3306368, 48.368359]],
    [ "Station Dormagen",[6.8328504, 51.0875597]],
    [ "Station Kupferdreh",[7.0961009, 51.396773]],
    [ "Umspannwerk Bad Liebenwerda",[13.3885061, 51.5088027]],
    [ "Bremen/Nord",[8.5558283, 53.1965016]],
    [ "Trafostation Vrampe",[8.7645862, 53.2322325]],
    [ "SA Grasberg",[8.99518, 53.1783896]],
    [ "Holsten-Bexten",[7.370411, 52.3400626]],
    [ "MVV Netze Umspannwerk 4 Blumenau",[8.4681592, 49.548036]],
    [ "Umspannwerk Lindau",[9.7208384, 47.5529988]],
    [ "Umspannwerk Zell",[10.0242087, 47.5736504]],
    [ "Station Heessen",[7.8076188, 51.7070284]],
    [ "Umspannwerk Konstanz-Ergatshausen",[9.1713696, 47.6790774]],
    [ "Station Jussenhoven",[6.8226784, 51.0884414]],
    [ "Umspannwerk Peißenberg",[11.0560567, 47.789013]],
    [ "Schaltposten Hanau",[8.9189632, 50.1258584]],
    [ "Staustufe 3",[10.8264462, 47.715878]],
    [ "Flensburg Süd",[9.4202964, 54.7734251]],
    [ "Umspannwerk Weilheim",[8.9237756, 48.35376]],
    [ "Station Bayer X 36",[6.9775762, 51.0114193]],
    [ "Heiligenstadt",[10.1561053, 51.3828416]],
    [ "Umspannwerk Bautzen-Süd",[14.4295627, 51.1608861]],
    [ "Surheide",[8.6209415, 53.5116286]],
    [ "10 kV-Station In der Grume 5A",[7.2496367, 51.507385]],
    [ "UW Alter Hellweg",[7.6691866, 51.5422633]],
    [ "Schladen",[10.5407472, 52.0160666]],
    [ "Umspannwerk Freital",[13.6519285, 50.9984942]],
    [ "Umspannwerk Garmisch/West",[11.0631103, 47.4809302]],
    [ "Umspannwerk Tiefwerder",[13.2106117, 52.5293664]],
    [ "10 kV-Station Alte Bahnhofstr. 108",[7.3130877, 51.4733571]],
    [ "Wasserkraftwerk Landesbergen",[9.1086275, 52.577653]],
    [ "Station Haan",[6.9984071, 51.192814]],
    [ "Auguste Victoria",[7.1109404, 51.6915242]],
    [ "Benteler Stahlwerk",[7.3172267, 52.4692258]],
    [ "10 kV-Station Scharnhorststr. 8",[7.2238428, 51.4812856]],
    [ "Station Erkenschwick",[7.2683886, 51.6524133]],
    [ "Geiselberg",[7.7070137, 49.3237496]],
    [ "Station Freienohl",[8.1759218, 51.3626632]],
    [ "Umspannwerk Frose",[11.3547505, 51.7899332]],
    [ "10 kV-Station Fahrendellerstr. 7",[7.2110531, 51.4861696]],
    [ "Schaltposten Neuss",[6.6882939, 51.2085062]],
    [ "Umspannwerk Salzwedel",[11.1598868, 52.8375139]],
    [ "Station Wimpfen",[9.1320269, 49.2390803]],
    [ "Umspannwerk Scharnhausen",[9.2451568, 48.7091059]],
    [ "Station Lippe",[7.403138, 51.6457468]],
    [ "Station Sedanstraße",[7.5308838, 51.5958417]],
    [ "Umspannwerk Spandau",[13.1927288, 52.5290076]],
    [ "Harsefeld",[9.4912192, 53.4595462]],
    [ "Schaltposten München-Fasanerie",[11.5195207, 48.192358]],
    [ "Umspannwerk Mettingen",[9.270494, 48.7557844]],
    [ "Schwalbach Mühle",[6.8135839, 49.2865922]],
    [ "Brühl Luftschiffhalle",[8.5442311, 49.410537]],
    [ "Umspannwerk Stöckach",[9.2020908, 48.7917001]],
    [ "Haffeld Ost",[11.4717598, 53.910856]],
    [ "Station Werne",[7.6483063, 51.6610117]],
    [ "Meitze",[9.7744893, 52.5676256]],
    [ "Auf den Ried",[8.5375388, 49.5020521]],
    [ "Wichmannsdorf",[13.6496343, 53.2370582]],
    [ "Umspannwerk Bronnweiler",[9.132948, 48.4529777]],
    [ "Netze BW Umspannwerk Horb",[8.6751502, 48.4382259]],
    [ "Umspannwerk Oberndorf",[8.5757029, 48.3051933]],
    [ "Schaltposten Falkenhagener Kreuz",[13.0199085, 52.5776895]],
    [ "Umspannwerk Holzappel",[7.900342, 50.3497971]],
    [ "Umformerwerk Neustadt (Dosse)",[12.4570888, 52.8471118]],
    [ "Munster",[10.0723403, 52.9949617]],
    [ "Borngasse",[11.0322144, 50.9762317]],
    [ "Umspannwerk Rotthalmünster",[13.2088647, 48.3636831]],
    [ "Umspannwerk Vilshofen",[13.1752671, 48.6153414]],
    [ "Umspannwerk Schönmühl",[11.4069916, 47.7438593]],
    [ "Umspannwerk Eßlingen",[11.0243365, 48.8956873]],
    [ "10KV Breslauer Platz",[7.3728004, 51.3021525]],
    [ "Trafostation",[8.4850815, 49.4690187]],
    [ "Umspannwerk Breisach - Im Galgenwinkel",[7.6018304, 48.0280595]],
    [ "10KV Station Steinklippe",[7.2844413, 51.3290806]],
    [ "Lebenstedt Süd",[10.3065617, 52.1389305]],
    [ "Ohlendorf",[10.4483929, 52.0715904]],
    [ "Station Alstätte",[6.926619, 52.1313745]],
    [ "Station Vreden",[6.8385395, 52.0302947]],
    [ "Umspannwerk Dornbreite",[10.623132, 53.8713318]],
    [ "10 KV Haßlinghauser Straße 3",[7.3051727, 51.3196248]],
    [ "10 kV Cuxhavener Straße",[7.2361386, 51.2894277]],
    [ "Stützpunkt Buntekuh",[10.6537632, 53.8509884]],
    [ "Umspannwerk Bollenbach",[8.0761581, 48.2886394]],
    [ "Umspannwerk Bleibach",[8.0001126, 48.1206008]],
    [ "Trafostation Wibiloweg",[8.6486572, 49.4180048]],
    [ "10 KV Wittener Straße 65",[7.2346945, 51.2905498]],
    [ "Umspannanlage Gutach",[8.192077, 48.28642]],
    [ "10 KV Linderhauser Straße 25",[7.2372125, 51.291105]],
    [ "Gievenbeck",[7.5661159, 51.9544596]],
    [ "10 KV Wuppermannstraße",[7.3194009, 51.3015721]],
    [ "Umspannwerk Remptendorf",[11.6467102, 50.5356682]],
    [ "Umspannanlage Gensingen",[7.9425248, 49.9042925]],
    [ "Umspannwerk Rudow",[13.484721, 52.4384493]],
    [ "Eibelshausen",[8.3525437, 50.8125434]],
    [ "Breitscheid",[8.200676, 50.6790387]],
    [ "Hofgeismar",[9.3925135, 51.5077567]],
    [ "10 KV Drebes",[7.3047048, 51.293645]],
    [ "Umspannwerk Wilmersdorf",[13.307341, 52.4806764]],
    [ "10 KV Märkische Straße",[7.2947278, 51.2905182]],
    [ "Brühlerweg km 10.8 NBS",[8.5472177, 49.4054154]],
    [ "Flöha/Ost",[13.0827335, 50.8514049]],
    [ "Freiheit",[7.398534, 51.3879564]],
    [ "Umspannwerk Berkenthin",[10.6357177, 53.7308527]],
    [ "Umspannwerk Teisbach",[12.4549239, 48.6516296]],
    [ "Umspannwerk Lübeck",[10.6418658, 53.9196402]],
    [ "Bismarckstraße",[7.3891745, 51.386329]],
    [ "10 KV Kölner Straße 3",[7.3418949, 51.2986521]],
    [ "10 KV Schachtstraße",[7.3351679, 51.2975102]],
    [ "Umspannwerk Hallbergmoos",[11.7387436, 48.3262355]],
    [ "10 KV Wilhelmsstraße 2",[7.2966365, 51.2853145]],
    [ "10 kV-Station Sudbeckenpfad 4",[7.2718547, 51.4760002]],
    [ "Neukammer",[12.8564566, 52.593183]],
    [ "Unterwerk Hameln",[9.4453859, 52.1408786]],
    [ "Umrichterwerk Lübeck-Genin",[10.6538852, 53.8384798]],
    [ "UW Groß Berkel",[9.3115311, 52.0611676]],
    [ "10 KV Hedt",[7.3129719, 51.3029212]],
    [ "Schaltwerk Glindenberger Weg",[11.6770505, 52.2145226]],
    [ "Umspannwerk Katharinenberg",[10.2572689, 51.1792472]],
    [ "Umspannwerk Altenkleusheim",[7.9378889, 51.0122689]],
    [ "Schaffhausen",[6.7965497, 49.2574156]],
    [ "Station Finnentrop",[7.9684759, 51.1814807]],
    [ "Umspannwerk Zeuzleben",[10.0610336, 49.980787]],
    [ "Umspannwerk Strullendorf",[10.9602134, 49.8333308]],
    [ "Marktleugast",[11.6266574, 50.1669869]],
    [ "Neunburg vorm Wald",[12.4048226, 49.335672]],
    [ "Umspannwerk Wallmersbach",[10.1855389, 49.5269134]],
    [ "Umspannwerk Feuchtwangen",[10.3296239, 49.1596272]],
    [ "Umspannwerk Neustadt an der Donau",[11.7601936, 48.7896319]],
    [ "Umspannwerk Amorbach",[9.2048797, 49.6509314]],
    [ "Unterwerk Mörlach",[11.2401701, 49.1990836]],
    [ "Umspannwerk West",[9.9490511, 48.4065576]],
    [ "Zolling",[11.8057816, 48.456218]],
    [ "Umspannwerk Feucht",[11.2190799, 49.3832951]],
    [ "Umspannwerk Ludersheim",[11.3245323, 49.3938693]],
    [ "Station Floßhafen",[6.7145763, 51.2124871]],
    [ "Friedrichsgabe",[9.985107, 53.7277758]],
    [ "Umspannwerk Station Horchheim",[7.6068744, 50.3327082]],
    [ "Kaltenkirchen Nord",[9.9640394, 53.8401224]],
    [ "Umspannwerk Domhof",[8.3761675, 51.9021047]],
    [ "Umspannwerk Zeiling",[12.4691757, 48.1447392]],
    [ "Umspannwerk Rudolstadt",[11.3351462, 50.6961497]],
    [ "Umspannwerk Seßlach",[10.8301602, 50.1887363]],
    [ "Umspannwerk Marktoberdorf",[10.6113324, 47.7789303]],
    [ "Umspannwerk Gennach",[10.6989135, 48.0992108]],
    [ "Umspannwerk Neresheim",[10.2962964, 48.7640317]],
    [ "Umspannwerk Starnberg",[11.3478386, 48.0064431]],
    [ "Umspannwerk Prittriching",[10.9183396, 48.2084309]],
    [ "Umspannwerk Langweid a.Lech",[10.8661129, 48.4979252]],
    [ "Umspannwerk Argelsried",[11.3082383, 48.0980605]],
    [ "Sandau",[12.0511744, 52.7807246]],
    [ "Badingen",[13.2653982, 52.9998809]],
    [ "UA Stadionstraße",[6.8640999, 49.25223]],
    [ "Löwenberg",[6.2781715, 51.8338774]],
    [ "10 KV Station Gewerbestraße",[7.3765691, 51.3038441]],
    [ "Umspannwerk Chemnitz West",[12.8598688, 50.8105724]],
    [ "Umspannwerk Büttel",[9.2322518, 53.9161504]],
    [ "Kalksbeck",[7.1878168, 51.9269742]],
    [ "Welte",[7.2760836, 51.8771722]],
    [ "Umspannwerk Bernburg Nord",[11.7522979, 51.8024303]],
    [ "Neues Umspannwerk Waiblinger Straße",[9.2250397, 48.8055527]],
    [ "Völklinger Straße 4",[8.5168231, 49.507114]],
    [ "Umspannwerk Dessau Alten",[12.1943073, 51.8154475]],
    [ "Umspannwerk Neuenhagen",[13.7049709, 52.5390851]],
    [ "Pößneck",[11.6166836, 50.6956734]],
    [ "Friesau",[11.6210608, 50.492686]],
    [ "Umspannwerk Schleiz",[11.7923366, 50.5738436]],
    [ "Umspannwerk Blankenstein",[11.6970533, 50.4054853]],
    [ "Umspannwerk Auma",[11.9208997, 50.702919]],
    [ "Umspannwerk Wurzbach",[11.5319262, 50.4541731]],
    [ "Umspannwerk Herrnhütte",[11.1026098, 49.4731016]],
    [ "Umspannwerk Gauting",[11.3806587, 48.0680998]],
    [ "Station Karthause",[7.5619618, 50.3340962]],
    [ "Umspannwerk Taubenbach",[11.2296921, 50.5301946]],
    [ "Umspannwerk Irsching",[11.5768272, 48.7661]],
    [ "Umspannwerk Tegel",[13.2866108, 52.5802311]],
    [ "Nordhausen",[10.8054516, 51.4815222]],
    [ "Mechow",[12.3378393, 52.9590599]],
    [ "10 KV Roller",[7.2819732, 51.2911026]],
    [ "Schwarza",[11.3205333, 50.681363]],
    [ "Kabelgarten Hoheneck",[9.1853607, 48.9231454]],
    [ "Station Hannover",[7.1701755, 51.5034044]],
    [ "Umspannwerk Lautlingen",[8.9418348, 48.2144791]],
    [ "Umspannwerk Ebingen",[9.0351925, 48.2054137]],
    [ "Umspannwerk Albbruck",[8.126431, 47.5920631]],
    [ "Umspannwerk Sigmundstraße",[11.0156821, 49.4520107]],
    [ "Haynrode Apelstraße",[10.4397734, 51.4455241]],
    [ "Station Hermeskeil",[6.9521515, 49.66515]],
    [ "Umspannwerk Ravensburg",[9.6204185, 47.7961981]],
    [ "Umspannwerk Jettenhausen",[9.4663641, 47.6750256]],
    [ "Umspannwerk Jena-Göschwitz",[11.6004938, 50.8871074]],
    [ "Umspannwerk Grüblhof",[12.4629305, 48.640033]],
    [ "10 KV Netto",[7.2879292, 51.2902035]],
    [ "Umspannwerk Bruck",[12.738582, 48.180243]],
    [ "Umspannwerk Gendorf",[12.7281025, 48.1767217]],
    [ "Umspannwerk Waldmohr",[7.3265183, 49.3919719]],
    [ "Umspannanlage Ost",[8.6948331, 49.4063063]],
    [ "Kraftwerk Hausham",[11.8400917, 47.7520195]],
    [ "Umspannanlage Neckarsteinach",[8.8433496, 49.4018176]],
    [ "Heidenfeld",[10.2349336, 49.9791721]],
    [ "Knetzgau",[10.5554903, 49.9780458]],
    [ "Station Soest",[8.1362064, 51.5553277]],
    [ "Bleichenstraße",[9.7421252, 52.3671263]],
    [ "MVV Umspannwerk 2 Galileistraße",[8.4753043, 49.4791582]],
    [ "Rennfeld",[8.7004946, 48.8885089]],
    [ "Umspannwerk Hof-Nord",[11.9163707, 50.3456762]],
    [ "Grünberg",[8.9706425, 50.5816394]],
    [ "Umspannwerk Ettling",[12.7944308, 48.695816]],
    [ "Langerfeld",[7.2507078, 51.2858541]],
    [ "Umspannwerk Zescha",[14.3184481, 51.2837168]],
    [ "Umspannwerk Peiting",[10.9428247, 47.787145]],
    [ "Hallesche Straße",[8.5526942, 49.5107922]],
    [ "UA Alsweiler",[7.0728607, 49.4783514]],
    [ "Umspannwerk Strenzfeld",[11.7305423, 51.8276004]],
    [ "UW Westeregeln",[11.3789087, 51.9566879]],
    [ "Oschersleben",[11.2674125, 52.0216044]],
    [ "UA Lebach",[6.9009585, 49.409763]],
    [ "Dörpen West",[7.2526091, 52.9820573]],
    [ "Umspannwerk Schongau",[10.8800615, 47.811476]],
    [ "Eisenhüttenstadt Stadt",[14.6416062, 52.1511531]],
    [ "Schaltposten Gundelshausen",[12.0046804, 48.9448357]],
    [ "Umspannwerk Lauterbach",[9.3870206, 50.6425426]],
    [ "10 kV-Station Berthastr. 48A",[7.1740502, 51.5004529]],
    [ "Umspannwerk Oberhochstatt",[11.0579485, 49.0393921]],
    [ "Umspannwerk Güglingen",[8.984769, 49.0614114]],
    [ "Umspannwerk Meimsheim",[9.1018617, 49.0634322]],
    [ "Umspannanlage Poppelsdorf",[7.0914529, 50.7206875]],
    [ "Umspannwerk Burgau",[10.4262871, 48.4265773]],
    [ "Crainfeld",[9.3426615, 50.4824246]],
    [ "Umspannwerk Giesing",[11.5761709, 48.1136647]],
    [ "Umspannwerk Am Plärrer",[11.0622263, 49.4488691]],
    [ "S-Bahn Gleichrichterunterwerk Gesundbrunnen",[13.3905462, 52.5482298]],
    [ "Umspannwerk Freiburg - Schlossbergring",[7.8577017, 47.9955739]],
    [ "S-Bahn Gleichrichterunterwerk Rahnsdorf",[13.6900364, 52.4512713]],
    [ "Umspannwerk Breslauer Straße",[11.1500946, 49.4167874]],
    [ "Umspannwerk Kupferzell",[9.6936289, 49.2314078]],
    [ "Umspannwerk Ehningen",[8.9190231, 48.6607251]],
    [ "Umspannwerk Vieselbach",[11.1233386, 51.0011083]],
    [ "Umspannwerk Hüffenhardt",[9.0930171, 49.2960276]],
    [ "Umspannanlage Müncherlbach-Solarpark",[10.8367291, 49.359802]],
    [ "Hildesheim Nord",[9.9631593, 52.1642875]],
    [ "Umspannwerk Weißwasser",[14.6384231, 51.4910553]],
    [ "Umspannwerk Erlau",[10.1109279, 48.8274713]],
    [ "Umspannwerk Nord",[10.0981186, 48.8517734]],
    [ "Umspannwerk Herbrechtingen",[10.1685226, 48.6278635]],
    [ "Umspannwerk Giengen",[10.2280285, 48.6265537]],
    [ "Schaltposten Neckargemünd",[8.7873704, 49.3933825]],
    [ "US Gevelsberg",[7.3430406, 51.3219905]],
    [ "Bischofsheim",[8.8074308, 50.1552461]],
    [ "Umspannwerk Görlitz",[14.9636037, 51.1663025]],
    [ "Schulkomplex BiLa",[10.9942103, 50.9732604]],
    [ "Umspannwerk Höpfingen",[9.4479747, 49.5913124]],
    [ "Umspannwerk Freiburg - Rankackerweg",[7.8052752, 47.9912126]],
    [ "10 kV-Station Rensingstr. 15A",[7.207431, 51.5162158]],
    [ "Alsfeld",[9.2607368, 50.7633701]],
    [ "Gera/Mitte",[12.0754661, 50.8785587]],
    [ "Umspannwerk Köthen Nord",[11.9884232, 51.7650601]],
    [ "Umspannwerk Helmstadt",[8.967005, 49.3178746]],
    [ "Unterwerk Meckesheim",[8.8050633, 49.3294926]],
    [ "Umspannwerk Heizkraftwerk Klingenberg",[13.49411, 52.489385]],
    [ "SH Aerzen - Reherweg",[9.261464, 52.0444923]],
    [ "Wächtersbach",[9.2950673, 50.2365283]],
    [ "Station Trier",[6.6202202, 49.7496478]],
    [ "10 KV Friedhofsweg",[7.3879812, 51.307369]],
    [ "Umspannwerk Travemünde",[10.8628283, 53.9590718]],
    [ "UA Donk",[6.4723113, 51.2337335]],
    [ "Itzehoe-Mitte",[9.4847266, 53.9320886]],
    [ "Holthausen",[7.8285939, 51.195147]],
    [ "Eglosheim",[9.1773561, 48.9057611]],
    [ "Unterwerk Traunstein",[12.6282075, 47.8688555]],
    [ "Unterwerk Flieden",[9.5720262, 50.4194386]],
    [ "10 KV Sudfeldstraße",[7.3372171, 51.3177756]],
    [ "Umspannwerk Brand-Erbisdorf",[13.3285562, 50.8765993]],
    [ "10 KV Blücherstraße 3",[7.2753279, 51.2894661]],
    [ "Bahnstrom Umformerwerk Borken",[9.2839903, 51.0524128]],
    [ "Lüneburg Hafen",[10.464757, 53.2412466]],
    [ "Umspannwerk Bad Soden",[9.4093261, 50.2998379]],
    [ "Petersberg",[9.7147951, 50.5521648]],
    [ "Welkers",[9.7193134, 50.4869834]],
    [ "Unterwerk Mottgers",[9.656016, 50.2790672]],
    [ "Umspannwerk Weier",[7.9093053, 48.500739]],
    [ "10 kV-Station Wattenscheider Str. 87A",[7.185365, 51.4806557]],
    [ "Umspannwerk Lübeck Zentrum",[10.6836074, 53.8690367]],
    [ "UW Heidenau",[13.8982285, 50.9587065]],
    [ "Umspannwerk Sparrenberg",[8.5437236, 51.992906]],
    [ "Umspannanlage Bad Ems",[7.7019218, 50.3315602]],
    [ "10 kV-Station Schützenstr. 219 A",[7.1806989, 51.4589716]],
    [ "UW Leupoldishain",[14.0244574, 50.9118581]],
    [ "UW Seeligstadt",[13.4452403, 51.0863612]],
    [ "Korschenbroich",[6.5061294, 51.1987394]],
    [ "Umspannanlage Hirschhorn",[8.9042346, 49.4495]],
    [ "Umspannanlage Staffel",[8.0325539, 50.392687]],
    [ "Umspannanlage Pfungstadt",[8.5892719, 49.8168111]],
    [ "Umspannwerk Schutterzell",[7.841061, 48.4087745]],
    [ "Wetzlar",[8.4839981, 50.5609411]],
    [ "Wetzlar/Industrie",[8.4905549, 50.5674425]],
    [ "Burgsolms",[8.4251673, 50.5374782]],
    [ "UW Obertiefenbach",[8.1231342, 50.4665983]],
    [ "Gießen/Ost",[8.733327, 50.5927381]],
    [ "Umrichterwerk Neckarwestheim",[9.1763392, 49.0382706]],
    [ "10-kV-Station Waldstraße",[7.1917635, 51.3924187]],
    [ "Breidenbach",[8.4483828, 50.8874717]],
    [ "Oberscheld",[8.3343647, 50.7322601]],
    [ "Umspannwerk Radeburg",[13.7323734, 51.2142041]],
    [ "Aula",[9.569763, 50.8516071]],
    [ "Umspannanlage Weiterstadt",[8.6010724, 49.9084496]],
    [ "Großschwabhausen",[11.4691605, 50.9298428]],
    [ "Dipperz",[9.7758333, 50.5304912]],
    [ "Umspannwerk",[8.2569198, 50.0588141]],
    [ "EVO Umspannwerk Seligenstadt",[8.9727388, 50.0541552]],
    [ "Unterwerk Offenbach am Main",[8.7878857, 50.103735]],
    [ "Umspannwerk Hermsdorf",[11.8523158, 50.8897102]],
    [ "Umspannwerk Baiertal",[8.7289285, 49.3009193]],
    [ "UW Riesa-Mitte",[13.2803363, 51.3129674]],
    [ "Ohle",[7.8463266, 51.2343054]],
    [ "Werdohl",[7.7594982, 51.2596097]],
    [ "Köppelsdorf",[11.2002373, 50.3499555]],
    [ "Umspannwerk Bauernfeldstraße",[8.1989617, 48.7809755]],
    [ "Umspannwerk Orschweier",[7.7900222, 48.2801714]],
    [ "Umspannwerk Lahr-Industrie",[7.820712, 48.3432869]],
    [ "Umspannwerk Groß-Umstadt",[8.9208327, 49.8743604]],
    [ "Schönau",[7.9085126, 47.7989338]],
    [ "Umspannwerk Altenfeld",[10.9901008, 50.573113]],
    [ "Umspannwerk Sebnitz",[14.2501025, 50.9696141]],
    [ "Umspannwerk Bärenstein",[13.0321427, 50.5143157]],
    [ "Umspannwerk Liebersbronn",[9.3435422, 48.7507704]],
    [ "Umspannwerk Hüttenrode",[10.8993654, 51.766715]],
    [ "Umspannwerk",[11.0255882, 51.7546953]],
    [ "Umspannwerk Hettstedt Kuhgrund",[11.4921915, 51.6327671]],
    [ "Umspannwerk Aschersleben",[11.4817953, 51.7502409]],
    [ "Umspannwerk Nachterstedt",[11.3155699, 51.8130999]],
    [ "110 kV Schaltanlage",[11.6706905, 52.3024555]],
    [ "Weferlingen",[11.0643901, 52.3135806]],
    [ "Flechtingen",[11.2195383, 52.3224119]],
    [ "Umspannwerk Quedlinburg Ost",[11.1645301, 51.7863615]],
    [ "Suhl/West",[10.6337055, 50.6059013]],
    [ "Station Speick",[6.4220154, 51.1836477]],
    [ "Umspannwerk Freiberg West",[13.3171216, 50.9161021]],
    [ "Umspannstation 3.1",[13.5279666, 52.4307664]],
    [ "Umspannwerk Bernburg Süd",[11.7553807, 51.7758871]],
    [ "Umspannwerk Könnern",[11.7784831, 51.6661021]],
    [ "Umspannwerk Oranienbaum",[12.3803097, 51.8027858]],
    [ "Wittenberg Nord",[12.6522672, 51.8814757]],
    [ "Umspannwerk Zahna",[12.7663415, 51.9155731]],
    [ "Treuenbrietzen",[12.8726759, 52.0931174]],
    [ "Unterwerk Klebitz",[12.833823, 51.9413581]],
    [ "Umspannstation Schackstedt",[11.6111197, 51.7049682]],
    [ "Barby",[11.863659, 51.9716838]],
    [ "Möckern",[11.9502571, 52.1460549]],
    [ "Umspannwerk",[12.1858888, 51.1316647]],
    [ "Umrichterwerk Doberlug-Kirchhain",[13.5798878, 51.6460381]],
    [ "Seelow",[14.3649593, 52.5362078]],
    [ "Briesen Dorf",[14.3145118, 52.3527876]],
    [ "Torgelow",[13.9965396, 53.6387113]],
    [ "Umspannwerk Zarrentin",[10.8278353, 53.5090007]],
    [ "Umspannwerk Boizenburg",[10.7497278, 53.3833236]],
    [ "Umspannwerk Hagenow",[11.2092706, 53.422757]],
    [ "Uelzen Hafen",[10.5569456, 53.0007519]],
    [ "Lübberstedt",[10.1089416, 53.1991082]],
    [ "Schaltposten Siegen-Weidenau",[8.0303674, 50.8890848]],
    [ "Friedland",[13.5457188, 53.6776649]],
    [ "Station Waldalgesheim",[7.83757, 49.9484711]],
    [ "Umspannwerk Station Rübenach",[7.5196852, 50.3575582]],
    [ "Umspannwerk Latsch",[12.0846431, 49.6831413]],
    [ "Umspannwerk Bingen-Kempten",[7.947968, 49.9570676]],
    [ "Unterwerk Bingen",[7.9479547, 49.9543606]],
    [ "Station Bürstadt",[8.4208544, 49.6362793]],
    [ "Umspannanlage Rosengarten",[8.417487, 49.6384537]],
    [ "Unterwerk Kaiserslautern",[7.7041163, 49.4375395]],
    [ "Umspannanlage Leonhardstanne",[8.6709145, 49.9304251]],
    [ "Umspannwerk Albig",[8.1126129, 49.7635397]],
    [ "Umspannwerk Gundersheim",[8.2019564, 49.7002429]],
    [ "Umspannwerk Guntersblum",[8.3240823, 49.8007751]],
    [ "Umspannwerk Wöllstein",[7.9740837, 49.8170255]],
    [ "Umspannwerk Holzhof",[8.3576372, 49.650325]],
    [ "Kraftwerkschaltanlage Ibbenbüren",[7.7465939, 52.2888835]],
    [ "Geist",[7.637324, 51.9253718]],
    [ "Lengerich",[7.8827407, 52.1801331]],
    [ "Nordhorn",[7.0733512, 52.4183091]],
    [ "Bentheim",[7.1267689, 52.3031179]],
    [ "LMG",[6.9664867, 51.4909083]],
    [ "Station NWO",[7.1107211, 52.1993853]],
    [ "Station Kabelstraße",[7.1204911, 51.2482837]],
    [ "Umspannwerk Donnstetten",[9.565491, 48.503208]],
    [ "Umspannwerk Zeulenroda",[11.9916472, 50.6609707]],
    [ "Sömmerda",[11.1181355, 51.1730154]],
    [ "Greußen",[10.949952, 51.216439]],
    [ "Jesteburg",[9.9724317, 53.317015]],
    [ "Wietzendorf",[9.9665013, 52.9128682]],
    [ "Flensburg Nord",[9.4088842, 54.8010634]],
    [ "Umspannwerk Amsdorf",[11.7126276, 51.4611172]],
    [ "UW Gröditz",[13.4519654, 51.4055658]],
    [ "Hövelhof",[8.6398419, 51.829489]],
    [ "10 KV Berliner Straße 1",[7.2969499, 51.2946202]],
    [ "Umspannwerk Borsigstraße",[8.5798851, 49.3911147]],
    [ "Haverlahwiese",[10.3228248, 52.1094589]],
    [ "Umspannwerk Erbach",[8.994382, 49.6507354]],
    [ "Langenhorn",[8.962108, 54.6761023]],
    [ "Piesteritz/Nord",[12.5872026, 51.8853968]],
    [ "Umspannwerk Gelnhausen",[9.1783058, 50.1848732]],
    [ "Schaltposten Eddersheim",[8.4619603, 50.0300658]],
    [ "SH Kurfürst",[8.8382462, 53.0819982]],
    [ "Irmenseul",[9.9450672, 51.9793889]],
    [ "Götzdorf",[9.4872483, 53.6370191]],
    [ "Rakow",[13.0677239, 54.061229]],
    [ "Woldegk",[13.5716971, 53.4690989]],
    [ "Station Niederstedem",[6.4750207, 49.9206442]],
    [ "Station Quint",[6.7029695, 49.8288267]],
    [ "Station Tobiashaus",[6.5385999, 49.6418332]],
    [ "Unterwerk Karthaus",[6.5917652, 49.708449]],
    [ "Station Konz",[6.5756899, 49.7032057]],
    [ "Mittelbexbach",[7.2372267, 49.3684742]],
    [ "Umspannwerk Niederhausen",[7.7945794, 49.8105565]],
    [ "10 KV Celler Straße",[7.2397983, 51.2900939]],
    [ "Umspannwerk Kerzenheim",[8.067018, 49.5777757]],
    [ "Münchweiler an der Alsenz",[7.8818239, 49.5474894]],
    [ "Otterbach",[7.7301809, 49.4826181]],
    [ "Umspannwerk Rotenberg",[7.782789, 49.4516969]],
    [ "Stahlwerk Thüringen",[11.434395, 50.6530286]],
    [ "Karlsruhe-Daxlanden",[8.3168598, 49.0076266]],
    [ "Umspannwerk Im Klingert",[8.3525958, 49.2178101]],
    [ "Weimar Nord",[11.3308496, 51.0004035]],
    [ "Station Dauersberg",[7.8626132, 50.7684417]],
    [ "Unterwerk Wörsdorf",[8.247521, 50.2355411]],
    [ "Station Urbar",[7.6407236, 50.3846527]],
    [ "Gleichrichterwerk",[10.4889264, 52.2392382]],
    [ "Wartberg",[9.5014454, 49.7497472]],
    [ "Umspannwerk Ober-Olm",[8.2061149, 49.9281616]],
    [ "Umspannwerk Dexheim",[8.3169647, 49.8519919]],
    [ "Umspannwerk Philippsburg",[8.4653004, 49.2210721]],
    [ "10 kV-Station Weitmarer Str. 41A",[7.2000722, 51.4610146]],
    [ "Brassert",[7.0898132, 51.6646063]],
    [ "UA Mutterstadt",[8.3538105, 49.4212735]],
    [ "Umspannwerk Mutterstadt",[8.3562474, 49.4246132]],
    [ "UW Frankenthal",[8.3472178, 49.5507346]],
    [ "BASF",[8.4101088, 49.5292144]],
    [ "UW Studernheim",[8.3504204, 49.5163808]],
    [ "Umspannwerk Schwegenheim",[8.3383204, 49.2641701]],
    [ "Umspannwerk Katzhütte",[11.0114992, 50.522303]],
    [ "Amprion Station Rheinau",[8.5470562, 49.4421725]],
    [ "Burghaun",[9.7543792, 50.6950749]],
    [ "10 kV-Station Wasserstr. 405",[7.204372, 51.4554824]],
    [ "Rheinau",[8.5443109, 49.4351125]],
    [ "Umspannwerk Müncheroda",[11.7303434, 51.2258382]],
    [ "Gemeinschaftskraftwerk Neckarwestheim 2 (GKN2)",[9.1771849, 49.0395495]],
    [ "Umspannwerk Forst",[12.1818876, 49.6944248]],
    [ "Station Kreuztal",[7.9905649, 50.9573561]],
    [ "Umspannwerk Lambrecht",[8.0520069, 49.3747993]],
    [ "10 KV Tunnel Betrieb",[7.3327806, 51.3168385]],
    [ "Schaltwerk Langenprozelten",[9.5825147, 50.0524678]],
    [ "Bahnstromumformerwerk Karlsruhe",[8.3748909, 48.9795589]],
    [ "Unterwerk Ihringshausen",[9.5373733, 51.3524367]],
    [ "Unterwerk Wurzen",[12.7464231, 51.3630899]],
    [ "Unterwerk Großheringen",[11.6557295, 51.1061586]],
    [ "Bahnstrom Unterwerk Chemnitz",[12.9394465, 50.8629966]],
    [ "Philippsburg 110 kV",[8.4399569, 49.2503428]],
    [ "Umspannwerk Goddelau",[8.4879951, 49.8336724]],
    [ "Umspannwerk Sieblos",[9.9044186, 50.5057308]],
    [ "Umspannwerk Eisenach",[10.3874983, 51.0013025]],
    [ "Tettau",[11.2982264, 50.4807702]],
    [ "Umspannwerk Altlußheim",[8.512873, 49.2937882]],
    [ "Umspannwerk Baienfurt Schachen",[9.6317607, 47.8381604]],
    [ "Umspannwerk Calbe",[11.7993839, 51.9243095]],
    [ "UW Staßfurt",[11.5588924, 51.8669434]],
    [ "Wolfgang",[8.963968, 50.1209492]],
    [ "Hungen",[8.8816424, 50.4706031]],
    [ "Nidda",[9.0010261, 50.4084554]],
    [ "Fulda/Süd",[9.6742183, 50.5442223]],
    [ "Battenfeld",[8.6611721, 51.022004]],
    [ "Friedberg",[8.7629037, 50.3464249]],
    [ "Umspannwerk Puderbach",[7.611839, 50.5914367]],
    [ "Umspannwerk Bandstahl",[7.4329409, 50.4364148]],
    [ "Sulzberg Nord",[10.3489052, 47.6646379]],
    [ "Stützpunkt St. Jrügen",[10.6991967, 53.8554109]],
    [ "Umspannwerk Brenz",[10.3747369, 48.5419382]],
    [ "Umspannwerk Oferdingen",[9.202768, 48.5402068]],
    [ "BAB 6 Wallstadt, Regenwasserpumpwerk",[8.5574086, 49.495804]],
    [ "Ennigerloh",[8.0300286, 51.8506972]],
    [ "Schaltanlage Rengershausen",[9.4415567, 51.2530015]],
    [ "Eisenberg",[11.9023885, 50.9850926]],
    [ "Umspannwerk Zerbst",[12.0892365, 51.9468801]],
    [ "Umspannwerk Roßlau",[12.2248272, 51.8827539]],
    [ "Umspannwerk Münchberg 110kV",[11.7711886, 50.2105386]],
    [ "Lachendorf",[10.2466808, 52.626752]],
    [ "Zossen",[13.4457109, 52.2120226]],
    [ "Groß Köris",[13.65018, 52.1547726]],
    [ "Station Elbestraße",[7.2565673, 51.4897366]],
    [ "Station Ronsdorf",[7.2170996, 51.2208024]],
    [ "Berge",[7.8038197, 51.6413632]],
    [ "Bönen",[7.7660178, 51.6126267]],
    [ "Homburg",[7.319057, 49.3066089]],
    [ "Wasserleben",[10.7484082, 51.9302603]],
    [ "Bad Harzburg",[10.5582002, 51.8923953]],
    [ "Einsiedlerhof",[7.6729341, 49.4344681]],
    [ "Borken 380kV",[9.2577712, 51.0594497]],
    [ "Umspannwerk Weida",[12.0465755, 50.7819788]],
    [ "Heidelberg-Süd",[8.6625365, 49.3595941]],
    [ "Umspannwerk Plauen A",[12.1484558, 50.4984]],
    [ "BSI Remscheid",[7.1936519, 51.173095]],
    [ "Tostedt",[9.731437, 53.2687824]],
    [ "Saatel",[12.752991, 54.3169391]],
    [ "Kuhlrade",[12.4802882, 54.2087315]],
    [ "Umspannwerk Bergshausen 380kV",[9.5141155, 51.2600805]],
    [ "Station Welper",[7.196075, 51.4118381]],
    [ "Übergabe Kutscher Behrens",[8.9365791, 53.1538197]],
    [ "Umspannstation Pobzig",[11.8706036, 51.8251899]],
    [ "10 KV Köttershöhe 8",[7.2407373, 51.2860407]],
    [ "Endmastanlage Bitterfeld",[12.3119681, 51.6287715]],
    [ "Umspannwerk Wolfen",[12.2730317, 51.6702417]],
    [ "Umspannwerk Zschornewitz",[12.4041557, 51.7194114]],
    [ "Wolfen Ost",[12.2965005, 51.6551937]],
    [ "Umspannwerk Großkayna",[11.9470642, 51.2923587]],
    [ "Umspannwerk Wischroda",[11.5701191, 51.1683157]],
    [ "Buttstädt",[11.4205304, 51.1194239]],
    [ "Wolkramshausen",[10.7361692, 51.4406963]],
    [ "Umspannwerk Roitzsch",[12.2341511, 51.5806405]],
    [ "Umspannwerk Schmiedeberg",[12.7294953, 51.6948708]],
    [ "Umspannwerk Aderstedt",[11.6896909, 51.7799977]],
    [ "Umspannwerk Rieder",[11.1517538, 51.7378469]],
    [ "BGH Edelstahl Lippendorf GmbH",[12.37788, 51.1708149]],
    [ "Schaltposten Lietzow (Rügen)",[13.5216466, 54.4805306]],
    [ "Wacker Freiberg",[13.3581494, 50.9000705]],
    [ "Freiberg Hütte",[13.3616213, 50.9092042]],
    [ "Umspannwerk Streumen",[13.3808893, 51.3546928]],
    [ "Station Metelen",[7.2379088, 52.1526487]],
    [ "UW",[14.4419883, 51.800334]],
    [ "Meiningen",[10.4275903, 50.594209]],
    [ "Grimmenthal",[10.4689935, 50.5309935]],
    [ "Haddorf",[9.4218872, 53.5959116]],
    [ "Stützpunkt Moisling",[10.6330873, 53.8413911]],
    [ "Umspannwerk Kraftwerk Wilhelmshaven",[8.1473281, 53.5635595]],
    [ "Bremerhaven-Überseehafen",[8.5378278, 53.5979332]],
    [ "Wellen",[11.4371383, 52.142936]],
    [ "Schützenhof",[7.2837685, 52.7057811]],
    [ "Badbergen",[7.9574512, 52.6296685]],
    [ "Alfhausen",[7.9443647, 52.503321]],
    [ "Station Bramsche",[7.9800025, 52.4147463]],
    [ "Damme",[8.2098835, 52.5254889]],
    [ "Harderberg",[8.0807167, 52.2236489]],
    [ "Bahnstrom Unterwerk Borne",[12.5403235, 52.1109455]],
    [ "Schalksmühle",[7.5353559, 51.2420936]],
    [ "Wipperfürth",[7.4075693, 51.1130001]],
    [ "Hönnetal",[7.8410934, 51.3994561]],
    [ "Bergkamen A",[7.6181951, 51.6376271]],
    [ "Station Fröndenberg",[7.7708316, 51.4686777]],
    [ "Brokstedt",[9.8155999, 53.998044]],
    [ "Schaltwerk Bärwalde",[14.505734, 51.4128978]],
    [ "Umspannwerk Schmölln",[14.2207858, 51.1202685]],
    [ "Umspannwerk Lübke-Koog Ost",[8.6459488, 54.8780609]],
    [ "Umspannwerk Pönitz",[10.6796986, 54.0452068]],
    [ "Umspannwerk Ahrensbök",[10.5863622, 54.0032569]],
    [ "Dörnigheim 110 kV",[8.8723843, 50.1302108]],
    [ "Station Gladbeck",[6.9726368, 51.5744646]],
    [ "Station Rheinbaben",[6.9491116, 51.5485029]],
    [ "Umspannwerk Plauen B",[12.1263969, 50.4880046]],
    [ "Umspannwerk Weinhübel",[14.9693173, 51.1268968]],
    [ "Umspannstation",[11.3736756, 51.7836777]],
    [ "Ludwigsbadstraße 2",[8.4545165, 49.4858225]],
    [ "Georg-Lechleiter-Platz",[8.480564, 49.4770774]],
    [ "Langenselbold",[9.0473619, 50.1639791]],
    [ "Gera/Langenberg",[12.0422456, 50.9167472]],
    [ "Umspannwerk Paderborn Süd",[8.7372398, 51.6987468]],
    [ "Gleichrichterwerk Bostelbek",[9.9205719, 53.471511]],
    [ "Umspannwerk DD-Mitte",[13.7201067, 51.052525]],
    [ "Station Siegen",[8.0239883, 50.8763555]],
    [ "Attendorn",[7.8934984, 51.1167081]],
    [ "Netze BW Umspannwerk Viernheim",[8.5657513, 49.5236572]],
    [ "Am Pütt",[9.6542777, 52.3445447]],
    [ "Umspannwerk Belgern",[13.1133047, 51.470286]],
    [ "Station Betzdorf",[7.8892576, 50.7814707]],
    [ "Wiesmoor/Süd",[7.7895606, 53.4019682]],
    [ "Spetzerfehn",[7.5999985, 53.3820486]],
    [ "Jennelt",[7.1171419, 53.4562327]],
    [ "Schaltposten Magdeburg-Sudenburg",[11.6235704, 52.1227396]],
    [ "Schaltanlage Herrieden",[10.4783964, 49.2376485]],
    [ "Wybelsum/Ost",[7.0798987, 53.3448379]],
    [ "Schalthaus West",[13.7223755, 51.4843638]],
    [ "Station Altenberge",[7.4521778, 52.0399818]],
    [ "Umspannwerk Etzel",[7.904469, 53.4488278]],
    [ "Horstedt",[9.0782889, 54.5150921]],
    [ "Henstedt-Dorf",[10.0051195, 53.7873774]],
    [ "Beerfelden",[8.952903, 49.5666081]],
    [ "Station Koblenz",[7.5930215, 50.3875045]],
    [ "10 KV",[7.3608976, 51.2836605]],
    [ "Umspannwerk Weinheim",[8.62592, 49.5446239]],
    [ "Rotenburg",[9.7461552, 50.9856199]],
    [ "Saalfeld",[11.3784323, 50.6431959]],
    [ "Sachsenstraße",[8.5525233, 49.509695]],
    [ "Umspannwerk Bauhof",[11.0808755, 49.4492324]],
    [ "Friedelsheimer Straße 22",[8.5049844, 49.4459624]],
    [ "Zwickau Eckersbach",[12.505019, 50.7275959]],
    [ "10 kV-Station Wagnerplatz 2A",[7.2161026, 51.4619269]],
    [ "Netze BW Umspannwerk Dossenheim",[8.6643992, 49.4559119]],
    [ "Haiger",[8.227982, 50.7480438]],
    [ "10 kV-Station Königsallee 145 A",[7.2221517, 51.4601563]],
    [ "Gartenschauweg",[8.4987576, 49.4783815]],
    [ "Umspannwerk Hundsbach",[9.8725182, 50.0226302]],
    [ "Umspannwerk Barmbek",[10.0246185, 53.5818393]],
    [ "10 KV Büttenberger Straße 1",[7.326643, 51.3006503]],
    [ "Umspannwerk Neustadt-Glewe",[11.6352192, 53.3886705]],
    [ "Merkenbach",[8.3111839, 50.6599845]],
    [ "Umspannwerk Tirschenreuth",[12.3357263, 49.8700601]],
    [ "Mürschnitz",[11.1371792, 50.3657727]],
    [ "Henstedt Wohld",[10.0468967, 53.7756932]],
    [ "10 kV-Station Wasserstr. 225",[7.2232922, 51.4608451]],
    [ "10-kV-Station Auf dem Nocken",[7.1808998, 51.4006604]],
    [ "Umspannwerk Neuenbeken",[8.8605849, 51.7217824]],
    [ "10 kV-Station Ursulastr.19",[7.2082659, 51.4748898]],
    [ "10-kV-Station Oststraße",[7.1884776, 51.399442]],
    [ "Wybelsum",[7.0797074, 53.3448353]],
    [ "Straßenheimer Weg",[8.5654432, 49.5114351]],
    [ "Schaltposten Hamm (Westf)",[7.8057863, 51.6803782]],
    [ "Arnstadt",[10.9683478, 50.8434023]],
    [ "10 kV-Station Hiltroper Landwehr 7",[7.2743207, 51.5197287]],
    [ "Schönwalde",[13.4640358, 52.6816983]],
    [ "Umspannstation",[14.1276316, 51.6689986]],
    [ "Ortsstraße",[8.574301, 49.5088411]],
    [ "Haspe",[7.40818, 51.3497056]],
    [ "Umspannstation Grötzingen - Werrabronn",[8.5146458, 49.0331237]],
    [ "Umspannstation Schramberg",[8.3813538, 48.2296267]],
    [ "Altsprucke",[14.6922964, 51.9457531]],
    [ "Umspannwerk Droßdorf",[12.1960181, 50.4380979]],
    [ "Wildpoldsried, Salzstraße",[10.3997892, 47.7686594]],
    [ "Hessisch Lichtenau",[9.7216051, 51.1921087]],
    [ "Umspannwerk Gardebusch",[11.1293092, 53.7114024]],
    [ "Umspannwerk Haßloch",[8.2507998, 49.3767253]],
    [ "Trafostation Schwindstraße",[8.6850151, 49.394435]],
    [ "Berga/Elster",[12.1573427, 50.7391444]],
    [ "Markersbach",[12.8798939, 50.5182566]],
    [ "10 kV-Station Hattinger Str. 177",[7.2066789, 51.4659558]],
    [ "Station Prüm",[6.4146637, 50.1997661]],
    [ "Friesack",[12.5952303, 52.7378353]],
    [ "Rochau/West",[11.721288, 52.7080186]],
    [ "10 KV Jahnstraße",[7.341879, 51.322984]],
    [ "Umspannwerk Hettstedt Nord",[11.4907798, 51.6412645]],
    [ "Umspannwerk DD-Johannstadt",[13.775965, 51.0548027]],
    [ "Umspannwerk Herlasgrün",[12.2438235, 50.5690383]],
    [ "Basedow",[13.7921723, 53.3403389]],
    [ "Umspannstation Lüdersdorf",[14.054632, 52.9323888]],
    [ "An der Sternenwarte",[8.4592371, 49.4869162]],
    [ "10 KV Windhukstraße neb. 35",[7.2409962, 51.2831165]],
    [ "Druxberge",[11.3191076, 52.1635647]],
    [ "Umspannwerk Dersekow",[13.3218063, 54.0456099]],
    [ "Umspannwerk Dänischenhagen",[10.1336421, 54.4282475]],
    [ "10 kV Bessemer Str. 80",[7.2082852, 51.4738496]],
    [ "Umspannwerk Fischerdorf",[12.9628264, 48.8172946]],
    [ "Umspannwerk Mönchröden",[11.0539462, 50.2958769]],
    [ "Umspannwerk Auerbach/Vogtland",[12.4005857, 50.514766]],
    [ "Station Sternbusch",[6.6870261, 51.5980597]],
    [ "Umspannwerk Richardstraße",[13.4428147, 52.4765945]],
    [ "Wismar",[11.4533169, 53.8815072]],
    [ "Ahlden",[9.528824, 52.7600545]],
    [ "10 KV Löhrelen neb. 22",[7.2432377, 51.2851697]],
    [ "Endmastanlage Halle Süd",[11.9864912, 51.4220808]],
    [ "Umspannwerk Döllnitz",[12.0445691, 51.4106037]],
    [ "Daseburg",[9.1868343, 51.4947473]],
    [ "Breuna",[9.1634664, 51.4137015]],
    [ "Wolfhagen",[9.1683183, 51.3195128]],
    [ "Umspannstation Holleben",[11.8865991, 51.434697]],
    [ "Station Kelsterbach",[8.529844, 50.0472923]],
    [ "Umspannwerk Dornstadt",[9.9605265, 48.4723272]],
    [ "10 KV Augustastraße 66",[7.4568884, 51.355176]],
    [ "10 kV-Station Herne-Nord",[7.2098604, 51.5547886]],
    [ "Umspannwerk Falkenstein",[12.3358778, 50.480744]],
    [ "Umspannwerk Vierraden",[14.2676442, 53.0999041]],
    [ "Schwerinsburg",[13.6760384, 53.739568]],
    [ "Roseck",[8.981793, 48.5398483]],
    [ "Flensburg Peelwatt",[9.4485054, 54.765607]],
    [ "10 KV Spitzenstraße 28",[7.2425008, 51.2759628]],
    [ "10 KV Spitzenstraße 36",[7.2414772, 51.2768503]],
    [ "Gleichrichterunterwerk Westend",[13.2860204, 52.5157503]],
    [ "Umspannwerk Hoisdorf",[10.3326601, 53.6466298]],
    [ "10 kV-Station Lessingstr. 84",[7.2202924, 51.4963644]],
    [ "Sudetenstraße",[10.2943707, 47.8081392]],
    [ "Umspannwerk Ilmenau",[10.9406948, 50.6915565]],
    [ "Fackelsberg",[10.2894054, 47.8125682]],
    [ "Bad Aibling",[12.0015359, 47.8506844]],
    [ "10 KV Grundstraße 20",[7.2364098, 51.2767281]],
    [ "Flensburg Ost",[9.4522221, 54.7930963]],
    [ "Schloßvippach",[11.1236905, 51.1075111]],
    [ "Kölleda",[11.2041751, 51.1876745]],
    [ "Schilfa",[10.9902468, 51.1874621]],
    [ "Baunatal",[9.4234962, 51.2512946]],
    [ "Netzstation MMi0308 Falkenberg Mühle",[13.3971979, 50.9540668]],
    [ "Schaltposten Leipzig",[12.3876258, 51.3517501]],
    [ "Menteroda",[10.5529522, 51.312074]],
    [ "Schaltwerk Leuna",[11.9847474, 51.3020289]],
    [ "Eiterfeld",[9.7874065, 50.7625707]],
    [ "Umspannwerk Naumburg",[11.8291346, 51.1447104]],
    [ "Jemgum",[7.3885925, 53.2503246]],
    [ "Grebenstein",[9.4189042, 51.4426525]],
    [ "Umspannwerk Sandershausen",[9.5687429, 51.3263513]],
    [ "Niestetal",[9.5595322, 51.3237726]],
    [ "Umspannanlage Bad König",[9.0232666, 49.7480949]],
    [ "Vellmar",[9.479306, 51.3586506]],
    [ "10 KV Dahler Straße 72",[7.2420809, 51.2808638]],
    [ "Umspannwerk Meerane Ost",[12.4778194, 50.8492197]],
    [ "Umspannwerk Crossen",[12.4772901, 50.7493049]],
    [ "Umspannwerk Schlunzig",[12.494038, 50.7863625]],
    [ "Umspannwerk Crossen",[12.4762365, 50.7525399]],
    [ "10-kV-Station Grünstraße",[7.183534, 51.3890023]],
    [ "Umspannwerk Silberstraße",[12.5509695, 50.6650564]],
    [ "JadeWeserPort",[8.1395856, 53.5987131]],
    [ "10 KV Graslake",[7.2746473, 51.2862047]],
    [ "Umspannwerk Lößnitz",[12.7193837, 50.617208]],
    [ "Umspannwerk Plaußig",[12.4287371, 51.4064484]],
    [ "Umspannwerk Rackwitz",[12.3726063, 51.4494981]],
    [ "Volksgut",[11.4357904, 51.303399]],
    [ "Bestenheid",[9.4815997, 49.7804776]],
    [ "Umspannwerk Oberröblingen",[11.2951276, 51.4471225]],
    [ "Bottendorfer Straße",[11.4184596, 51.298575]],
    [ "Roßleben Weinberg",[11.4287491, 51.2998446]],
    [ "Ziegelrodaer Straße",[11.4335181, 51.299369]],
    [ "Glück-Auf-Straße",[11.4405417, 51.2997915]],
    [ "Sängerweg",[11.4390884, 51.2985712]],
    [ "Bottendorf Donndorfer Straße",[11.4022544, 51.3028873]],
    [ "10 KV Hügelstraße Nr. 4",[7.224461, 51.2758253]],
    [ "Am Schlifter",[11.4469778, 51.3012529]],
    [ "Behälterbau",[11.449752, 51.2990597]],
    [ "An der Halde",[11.466933, 51.2999675]],
    [ "Lagerhalle",[11.4566899, 51.3021044]],
    [ "Umspannwerk Reinsdorf",[11.608855, 51.2977491]],
    [ "Umspannwerk Artern",[11.3131554, 51.3592398]],
    [ "Umspannwerk Olbernhau",[13.3252186, 50.6642408]],
    [ "Umspannwerk Zschopau Süd",[13.0716087, 50.7434469]],
    [ "Dögerode",[10.0584165, 51.7992022]],
    [ "Northeim",[9.9800273, 51.6959961]],
    [ "Umspannwerk Halle Reideburg",[12.0846481, 51.4910124]],
    [ "Umspannwerk Harzgerode",[11.1554847, 51.6430052]],
    [ "Umspannwerk Halle Nord",[11.9447875, 51.5256223]],
    [ "Umspannwerk Halle Wörmlitz",[11.9574176, 51.4460818]],
    [ "Umspannwerk Köthensdorf (außer Betrieb)",[12.8740885, 50.9155813]],
    [ "Umspannwerk Bennewitz",[12.7054092, 51.3565276]],
    [ "Eilenburg Ost",[12.653925, 51.4636558]],
    [ "Umspannwerk Bad Düben",[12.6072236, 51.5900228]],
    [ "Umspannwerk Langenreichenbach",[12.8970577, 51.4918215]],
    [ "Trompetter",[12.920656, 50.8531624]],
    [ "Dietmannsried, Fürstenstraße",[10.2867808, 47.8140418]],
    [ "Chemnitz Mitte",[12.9186768, 50.8557963]],
    [ "Umspannwerk Frankenberg",[13.0265059, 50.9100303]],
    [ "Etzdorf",[13.150528, 51.0467778]],
    [ "Waldheim",[13.0172915, 51.0544794]],
    [ "Döbeln",[13.0941359, 51.1228447]],
    [ "Umspannwerk Leisnig",[12.9426167, 51.1690827]],
    [ "Sermuth",[12.7818009, 51.1616325]],
    [ "Drei Linden",[11.4144669, 51.3281905]],
    [ "Günthershof",[11.4052908, 51.3344033]],
    [ "Thörey",[10.925003, 50.8878758]],
    [ "Hildburghausen",[10.7496438, 50.430369]],
    [ "Lübbenau",[13.9612944, 51.8488264]],
    [ "UW Vetschau",[14.1085151, 51.7878622]],
    [ "Peitz",[14.396004, 51.8616305]],
    [ "Weimar/Süd",[11.3554318, 50.9668599]],
    [ "Braunlage",[10.5944971, 51.7246933]],
    [ "Kalbsrieth Pfarrgasse",[11.3365397, 51.344769]],
    [ "Umspannwerk SPB",[14.364985, 51.5585108]],
    [ "Glantzhof",[13.6866678, 53.4885254]],
    [ "Louisfelde",[13.8076306, 53.4946206]],
    [ "Umspannwerk Freienhufen",[13.9761748, 51.5830057]],
    [ "Umspannwerk Lennewitz",[12.0666257, 51.3114169]],
    [ "Umspannwerk Kostebrau",[13.8356554, 51.5359164]],
    [ "Umspannwerk Schwarzheide-Ost / Einspeisung Windpark Biehlen",[13.9060979, 51.469532]],
    [ "10 kV-Station Zillertalstr. 122",[7.2252568, 51.5156532]],
    [ "Oberelsdorf",[12.6951077, 50.9674455]],
    [ "Umspannwerk Geithain",[12.6890771, 51.0703128]],
    [ "Umspannwerk Rochlitz",[12.7859339, 51.0605998]],
    [ "Oevenum-Deich",[8.5707221, 54.7482109]],
    [ "Umspannwerk Altenburg",[12.4537641, 50.9873403]],
    [ "Umspannwerk Susigke",[12.0828875, 51.8326357]],
    [ "Umspannwerk Coswig Nord",[12.4482984, 51.8932643]],
    [ "Sportplatz",[11.4427359, 51.2933207]],
    [ "Wittenberg West",[12.592148, 51.8732384]],
    [ "Umspannwerk Finsterwalde",[13.7298603, 51.6392553]],
    [ "Kahla",[11.5701696, 50.8021582]],
    [ "Bernterode",[10.4469968, 51.4149091]],
    [ "Umspannwerk Mücheln",[11.8022299, 51.2916762]],
    [ "Malzfabrik",[11.4402269, 51.2950449]],
    [ "Schaltwerk Göbitz",[12.2021869, 51.0795858]],
    [ "Station Gremberghoven",[7.0476315, 50.9045663]],
    [ "10 kV-Station 5954",[7.2112091, 51.4786032]],
    [ "Waltershausen",[10.5695543, 50.9104934]],
    [ "Umspannwerk Kappel",[12.8852295, 50.828161]],
    [ "Umspannwerk Wittgensdorf Gießerei",[12.8302778, 50.8856457]],
    [ "Umspannwerk Burgstädt",[12.8084196, 50.9082595]],
    [ "Umspannwerk Annaberg-Buchholz",[13.0040885, 50.5836165]],
    [ "Umspannwerk Aue Süd",[12.6789464, 50.5843664]],
    [ "Schönheide",[12.5241024, 50.5148681]],
    [ "Am Schacht",[11.4609619, 51.3058605]],
    [ "Roßleben Park",[11.4639747, 51.3044509]],
    [ "ZEV Umspanwerk Sachsenring",[12.4701476, 50.7350682]],
    [ "Umspannwerk Werdau Süd",[12.3770717, 50.7170645]],
    [ "Umspannwerk Niederwürschnitz",[12.7550093, 50.7146467]],
    [ "Umspannanlage Lambsheim",[8.2909063, 49.5259411]],
    [ "Umspannwerk Löbau",[14.6677542, 51.1061562]],
    [ "Umspannstation Lübben West",[13.8670261, 51.9423667]],
    [ "Umspannwerk Reichenbach",[12.284382, 50.6195822]],
    [ "Umspannstation Zwickau Zentrum",[12.4802668, 50.7038425]],
    [ "Bassum",[8.715495, 52.8505691]],
    [ "10 kV-Station Suntumer Str. 10",[7.2666822, 51.4716526]],
    [ "Bürgerplatz",[7.5684493, 51.351092]],
    [ "Umspannwerk Elgersweier",[7.9380514, 48.4472335]],
    [ "Unterwerk Kirchheim",[9.5873299, 50.878403]],
    [ "UA Freisen",[7.2377002, 49.5473557]],
    [ "Umspannwerk Schwarzenberg",[12.7682695, 50.5536104]],
    [ "Umspannwerk Markneukirchen",[12.3163932, 50.3137677]],
    [ "Dornburg",[11.6462479, 51.0072063]],
    [ "Umspannwerk Prettin",[12.9187042, 51.6723822]],
    [ "Umspannstation Krauschwitz",[11.98071, 51.136415]],
    [ "Umspannwerk Delmenhorst Mitte",[8.6359683, 53.0531132]],
    [ "Umspannwerk Crimmitschau/Süd",[12.3906943, 50.8042263]],
    [ "10 kV-Station Meesmannstraße 15",[7.2103253, 51.5159694]],
    [ "Kraftwerk Kochel",[11.3376868, 47.6306686]],
    [ "10 KV Langerfelder Straße hint. 127",[7.2401045, 51.274674]],
    [ "10-kV-Station Südring",[7.1763741, 51.3902536]],
    [ "10-kV-Station Eschenholt",[7.1797978, 51.387643]],
    [ "Goslar",[10.4124727, 51.9285794]],
    [ "10 KV Wilhelm-Hedtmann-Straße 15",[7.2447376, 51.2728463]],
    [ "Unterwerk Saubachtal",[11.5419681, 51.2055929]],
    [ "10 KV",[7.1808896, 51.4166527]],
    [ "10 kV-Station Hofsteder Straße 174",[7.2071847, 51.4987839]],
    [ "Liepen",[13.4350247, 53.8894745]],
    [ "Werdum",[7.7178899, 53.6512685]],
    [ "Schwittersum",[7.4752929, 53.6505052]],
    [ "Diele",[7.3104368, 53.1246945]],
    [ "Umrichter 1",[10.7358974, 51.4387748]],
    [ "Umrichter 2",[10.7362697, 51.4387171]],
    [ "Lubmin EWN",[13.665581, 54.1391157]],
    [ "Prenzlau Flugplatz Solar",[13.9194093, 53.3399896]],
    [ "10 KV",[7.2215051, 51.2758247]],
    [ "10 KV Berliner Straße hint.141",[7.2183976, 51.2757142]],
    [ "Umspannwerk Oppeln",[14.6993235, 51.1397568]],
    [ "Station Hamminkeln",[6.6015855, 51.7298523]],
    [ "10 KV Schöneberger Ufer",[7.2207565, 51.2748946]],
    [ "10 KV Stennert hint.9",[7.217226, 51.2744393]],
    [ "Bockhorn",[8.0360115, 53.4210096]],
    [ "Dingen",[9.1121567, 53.9550651]],
    [ "Fürstenwalde Flugplatz",[14.0822465, 52.3801552]],
    [ "Station ECI",[7.6331348, 52.3135903]],
    [ "Rellinghausen",[7.0557304, 51.4316503]],
    [ "Cuxhaven Ostseite",[8.701907, 53.8625989]],
    [ "Schalthaus Klosterweide",[8.9092536, 53.1515136]],
    [ "Übergabe Gewerbe",[8.9230519, 53.1620674]],
    [ "Umspannwerk Mergelstetten",[10.1669549, 48.6497476]],
    [ "Bergneustadt",[7.6528024, 51.0192134]],
    [ "10 KV Sternstraße 68",[7.2160168, 51.2755735]],
    [ "10 KV Langobardenstraße neb.48",[7.2236044, 51.2780438]],
    [ "Bad Gandersheim",[10.0277029, 51.8831433]],
    [ "Trafostation Wendefurth",[10.9074133, 51.7387325]],
    [ "Bertolt-Brecht-Straße",[11.4315476, 51.3035562]],
    [ "Lessingstraße",[11.4303096, 51.3023642]],
    [ "UW3",[7.0489271, 51.3332605]],
    [ "10 KV Spreeler Weg",[7.3242548, 51.258207]],
    [ "UW Förderstedt Wind",[11.6470114, 51.9116813]],
    [ "UW Staßfurt Speicher",[11.55843, 51.8912943]],
    [ "Umspannwerk Hoyerswerda",[14.2634635, 51.4466667]],
    [ "10 KV Berliner Straße 58",[7.2120478, 51.2741064]],
    [ "Netze BW Umspannwerk Ladenburg",[8.5966892, 49.4834625]],
    [ "Neckarvorlandstraße 80",[8.4503175, 49.5018134]],
    [ "Güterhallenstraße 66a",[8.4537396, 49.5000299]],
    [ "Landzungenstraße 8",[8.4531716, 49.497269]],
    [ "Verbindungskanal linkes Ufer 14",[8.4545358, 49.4941576]],
    [ "10 KV Bredenscheider Straße 1",[7.1928408, 51.3948672]],
    [ "10 kV-Station Hattinger Str. 44A",[7.2135124, 51.4721562]],
    [ "10 kV-Station Konrad-Adenauer-Platz 1",[7.2157837, 51.4753068]],
    [ "10 kV-Station Kronenstraße 13",[7.2178994, 51.4748039]],
    [ "10 kV -Station Tana-Schanzara-Platz 1",[7.2171289, 51.4734903]],
    [ "Schachtstraße",[11.4451054, 51.2925336]],
    [ "Ludwigsbadenstraße 9",[8.4539409, 49.4876564]],
    [ "Umspannwerk Klostermansfeld S",[11.4974573, 51.5768995]],
    [ "Kiesäcker",[8.5361415, 49.4989212]],
    [ "S-Bahn Gleichrichterunterwerk Frohnau",[13.2845662, 52.6479382]],
    [ "Umspannwerk Dresden/ Neustadt",[13.739993, 51.0738526]],
    [ "Altes Feld",[7.8338925, 51.3078468]],
    [ "Umspannstation",[11.7809364, 51.6658797]],
    [ "Maumke",[8.0388603, 51.122085]],
    [ "Fahrlach",[8.5011711, 49.471981]],
    [ "Umspannst.",[9.1239826, 48.6352689]],
    [ "Dossenheim BW Sender",[8.721232, 49.4529581]],
    [ "10 kV Verteilerstation",[12.3707573, 51.3646011]],
    [ "10 KV Hammerstraße",[7.3258959, 51.3186339]],
    [ "Treysa",[9.2046305, 50.9109651]],
    [ "10 kV-Station Anemonenweg 6",[7.2881952, 51.4824023]],
    [ "Dahlwitz-Hoppegarten",[13.6648394, 52.4996481]],
    [ "Umspannwerk Sachsenmilch",[13.9763646, 51.1484951]],
    [ "Umspannstation Hummelwies",[8.5191614, 49.2783767]],
    [ "SSW",[8.849452, 53.1102796]],
    [ "Itzehoe/Nord",[9.4881698, 53.9609039]],
    [ "10 kV-Station Liegehafen",[7.2468947, 51.5618704]],
    [ "UW Solarpark Mixdorf",[14.3477928, 52.2195125]],
    [ "10 KV Branscheid",[7.3264924, 51.3175705]],
    [ "Uranit",[7.0770981, 52.2144808]],
    [ "Ottrau",[9.3837001, 50.8020851]],
    [ "10 KV Schmiedestraße 1",[7.2486648, 51.3126213]],
    [ "Karlsplatz",[8.5228367, 49.4385836]],
    [ "Umspannwerk Oening",[11.5013142, 49.0894641]],
    [ "Umspannwerk Höchstädt",[10.5718701, 48.6068075]],
    [ "Trafo-Nr. 04-718",[10.995013, 51.0143555]],
    [ "Emsdetten Wilmersstraße",[7.5663647, 52.1553358]],
    [ "Station Emsdetten",[7.5270686, 52.1822738]],
    [ "10-kV-Station Lessingstraße",[7.1816627, 51.3928889]],
    [ "Eisenach/West",[10.2916868, 50.9788334]],
    [ "Schneverdingen",[9.7736195, 53.1268665]],
    [ "UW Wittenborn",[10.2174119, 53.9160232]],
    [ "Tewel",[9.6892142, 53.0680735]],
    [ "Oberdorf",[10.2727178, 47.6290737]],
    [ "Amerikanerstraße",[8.476844, 49.4777882]],
    [ "Station Lotte",[7.9139708, 52.2573149]],
    [ "Blumenthal",[8.5777627, 53.1816924]],
    [ "Umspannwerk Halle West",[11.9058402, 51.470106]],
    [ "10 KV Lindenstraße 1",[7.3888881, 51.3059594]],
    [ "10 kV Mühlenstraße",[7.4110419, 51.3222655]],
    [ "10 kV Hagener Straße",[7.4118141, 51.3207651]],
    [ "Umspannwerk Schleusingen",[10.7639544, 50.5221528]],
    [ "Neuhaus-Schierschnitz",[11.2495352, 50.2996486]],
    [ "Bürgerwindpark Löningen/West",[7.7154483, 52.7576373]],
    [ "Bisdorf",[11.1188243, 54.475214]],
    [ "Stromübernahmestation Ansbach-Mitte",[10.5795817, 49.2961865]],
    [ "Umspannwerk Gunzenhausen",[10.7434117, 49.1082978]],
    [ "Unterwerk Barnstorf",[8.510382, 52.7070919]],
    [ "10 KV Wall 32-34",[7.145944, 51.256281]],
    [ "Umspannwerk Homberg (Efze)",[9.3829889, 51.0119042]],
    [ "Umspannstation Lübben Nord",[13.869836, 51.948844]],
    [ "Umspannwerk Uettingen",[9.7247045, 49.804957]],
    [ "Umspannwerk Dessau Süd",[12.234094, 51.8134591]],
    [ "Umspannwerk Gosheim",[8.7576698, 48.1479547]],
    [ "Umspannwerk Wassertrüdingen",[10.6078488, 49.0477005]],
    [ "Umspannwerk Kraftwerk Kassel",[9.4844798, 51.2772527]],
    [ "Umspannwerk Brakel",[9.1931671, 51.722076]],
    [ "Greifswald-Nord",[13.3466346, 54.1116742]],
    [ "Umspannwerk Leipzig F",[12.3657302, 51.3426363]],
    [ "Teltow",[13.2510891, 52.4027853]],
    [ "Umspannwerk Attilaplatz",[13.3758466, 52.4562218]],
    [ "Syke",[8.7816044, 52.9169506]],
    [ "Schaltposten Crailsheim",[10.0644325, 49.1336978]],
    [ "Schaltposten Leipzig-Mockau",[12.3965395, 51.3791102]],
    [ "UA Borg",[6.4198686, 49.4917507]],
    [ "Umspannstation Wolfen/Thalheim",[12.2402028, 51.6462976]],
    [ "Tauberbischofsheimer Straße, Endschleife der RNV GmbH",[8.5598873, 49.4958157]],
    [ "Netze BW-Umspannwerk Vaihingen",[9.1187622, 48.7245352]],
    [ "Umspannwerk Musberg",[9.113764, 48.6909134]],
    [ "Umspannwerk Fauerbach",[8.7741996, 50.3224737]],
    [ "Wittingen",[10.7289696, 52.7179707]],
    [ "Gamsen",[10.5350502, 52.5069648]],
    [ "Umspannwerk Schwarzenbek",[10.4879573, 53.5103106]],
    [ "Umspannwerk Zeitz",[12.105076, 51.0498733]],
    [ "Grünberg I",[12.3741616, 50.8566234]],
    [ "Schaltposten Angersdorf",[11.9106226, 51.4610887]],
    [ "Umspannwerk Magdeburg/Sandtorstraße",[11.6507954, 52.1398621]],
    [ "Meinerzhagen",[7.6315361, 51.1106704]],
    [ "Lüdenscheid",[7.6376184, 51.2286102]],
    [ "Radevormwald",[7.3798976, 51.2001341]],
    [ "Umspannwerk Bludenzer Straße",[9.1650554, 48.8145113]],
    [ "Umspannwerk Waldlaubersheim",[7.8155655, 49.9229537]],
    [ "Gotha",[10.7106836, 50.9287182]],
    [ "Umspannwerk TU Süd",[13.3236738, 52.5110496]],
    [ "Hanau/Süd",[8.9163922, 50.1030761]],
    [ "Schaltwerk Kirchmöser",[12.4161124, 52.3943967]],
    [ "Unterwerk Schönefeld",[13.5171293, 52.3942645]],
    [ "Umspannwerk Herbolzheim",[7.7556577, 48.2271697]],
    [ "Umspannwerk Gengenbach",[8.0052594, 48.3997689]],
    [ "Umspannwerk Flughafen Tempelhof",[13.3946909, 52.4837546]],
    [ "Altenbruch",[8.71156, 53.8262406]],
    [ "Hohberg",[7.8924968, 48.3392171]],
    [ "Schaltzentrale EAB",[10.9968342, 52.1527679]],
    [ "Phoenix",[11.0406122, 52.1501232]],
    [ "Umspannwerk Fernwärmekraftwerk Neukölln",[13.5164255, 52.4248023]],
    [ "Breesen",[13.1831632, 53.6421549]],
    [ "Schaltposten Mühlacker",[8.838156, 48.9514378]],
    [ "Umspannwerk Salzwiesen",[9.1538591, 48.8341374]],
    [ "Bergholz-Rehbrücke",[13.1045633, 52.3396129]],
    [ "Schaltposten München Kanal",[11.4885031, 48.1467408]],
    [ "Umspannwerk Wegedornstrasse",[13.5271404, 52.4273763]],
    [ "Umspannwerk Senftenberg West",[13.9700101, 51.5105658]],
    [ "Birkholz",[14.2092051, 52.1823226]],
    [ "Schwarza Papierfabrik",[11.3351938, 50.6834594]],
    [ "Kraftwerkschaltanlage Knapsack 1",[6.8408818, 50.8600463]],
    [ "Umspannwerk Großröhrsdorf",[14.0341396, 51.1452655]],
    [ "Umspannwerk Neueibau",[14.6493323, 50.9709059]],
    [ "Umspannwerk Dittersbach",[14.8480119, 51.0201024]],
    [ "Schalthaus Ansbach Süd",[10.6190627, 49.2465161]],
    [ "Haselberg",[11.58871, 50.6856614]],
    [ "Geislingen",[8.8202798, 48.2910439]],
    [ "Station Schöppingen WP",[7.0895957, 52.0784057]],
    [ "Station Holtwick",[7.1378142, 52.0273647]],
    [ "Grefrath",[6.792072, 50.8991706]],
    [ "Blockwinkel",[8.7540605, 52.756974]],
    [ "Scholen",[8.7511174, 52.7401469]],
    [ "Wedehorn",[8.7301432, 52.7907583]],
    [ "Kuppendorf",[8.8866624, 52.5680677]],
    [ "Kirchdorf",[8.8553759, 52.6012186]],
    [ "KW Heyden",[8.9956355, 52.3796076]],
    [ "Siedenburg",[8.9475783, 52.7038151]],
    [ "Strausberg",[13.8667481, 52.5616697]],
    [ "Beerbaum",[13.7469931, 52.7249525]],
    [ "Eberswalde Flugplatz",[13.7495574, 52.819291]],
    [ "Umspannwerk Lange Wieren",[8.8086073, 53.0737126]],
    [ "Fürstenwalde-Süd",[14.0935171, 52.3455911]],
    [ "Marquardt",[12.959812, 52.4779474]],
    [ "Heidekrug",[13.9495365, 52.4753779]],
    [ "Zinndorf",[13.9461322, 52.4736785]],
    [ "Häsen",[13.2389472, 52.9513013]],
    [ "Umspannstation Klein Mutz",[13.2590108, 52.9572919]],
    [ "Pöglitz",[12.8490251, 54.1402127]],
    [ "Eixen",[12.6861828, 54.170864]],
    [ "Brandenburg Hohenstücken",[12.5440973, 52.4329523]],
    [ "Solar-UW Tieckow",[12.4620041, 52.4707402]],
    [ "Tangermünde",[11.9662765, 52.5688528]],
    [ "Wind-UW Hottendorf",[11.544896, 52.5310344]],
    [ "Kenz Solarpark",[12.7429387, 54.3307107]],
    [ "Umspannstation Genthin Wind",[12.1322233, 52.4308196]],
    [ "Umspannwerk Gießen Nord",[8.6548782, 50.6042279]],
    [ "UW Uchtdorf",[11.7375535, 52.4162438]],
    [ "Bliesdorf",[14.1666066, 52.704576]],
    [ "Wriezen",[14.1460045, 52.7253856]],
    [ "Wriezen Höhe",[14.1428902, 52.7280474]],
    [ "Mittenwalde",[13.5794326, 52.2649117]],
    [ "Umspannwerk Breitenmatt",[8.0389398, 47.5593913]],
    [ "Kabelanfang PSP Wehr",[7.9369649, 47.6493026]],
    [ "Kormoranstraße 29",[8.5488691, 49.5003596]],
    [ "Station Euscheid",[6.2886469, 50.1299267]],
    [ "10 KV Buchholzstraße",[7.3182384, 51.3216386]],
    [ "Engelsstraße",[11.5968566, 50.6919331]],
    [ "Umspannwerk Rockenhausen",[7.795741, 49.6227148]],
    [ "Umspannwerk Klärwerk Ruhleben",[13.2299176, 52.5300332]],
    [ "Umspannwerk Messe Berlin",[13.272621, 52.5008923]],
    [ "Kuppelstelle Roisdorf",[7.0427524, 50.7451398]],
    [ "Schaltposten Köln Süd",[6.9418752, 50.9203218]],
    [ "Schaltposten Vohwinkel",[7.0698068, 51.2333188]],
    [ "Schaltposten Hannover-Waldhausen",[9.7732953, 52.3488696]],
    [ "Schaltposten Hannover Hbf",[9.7348503, 52.3827353]],
    [ "Schaltposten Haste",[9.3853234, 52.3762462]],
    [ "Schaltposten Langenhagen",[9.7365026, 52.4522771]],
    [ "Schaltposten Berlin-Spandau",[13.1874014, 52.535981]],
    [ "Schaltposten Südkreuz",[13.365963, 52.477978]],
    [ "Schaltposten Bergholz",[13.0807527, 52.3403957]],
    [ "Schaltposten Griebnitzsee",[13.1344891, 52.3945729]],
    [ "Umspannwerk Süd",[8.3828676, 48.9933483]],
    [ "Umspannwerk Lübke-Koog West",[8.6067475, 54.886238]],
    [ "Schaltposten Dreieich-Buchschlag",[8.6606991, 50.0210832]],
    [ "Schaltposten Rastatt",[8.2273973, 48.8669383]],
    [ "Station Bestwig",[8.3538365, 51.3545989]],
    [ "Umspannstation Lübben Süd",[13.8756946, 51.9250215]],
    [ "Orleshausen",[9.0555668, 50.2897933]],
    [ "Station Kirchberg 1+2",[7.4081405, 49.9571064]],
    [ "Morbach neu",[7.1150407, 49.8171664]],
    [ "10 KV Eichenhofer Weg 81",[7.2617706, 51.3114957]],
    [ "Gotenstraße 7",[8.5544214, 49.4978586]],
    [ "10 kV-Station Hattingerstr. 266A",[7.1969255, 51.4580908]],
    [ "Station Daubenrath",[6.4075374, 50.8988204]],
    [ "Gleichrichterwerk Bgm.-Hildebrand-Str.",[8.7911871, 53.0873555]],
    [ "Umspannwerk Lützschena",[12.2849462, 51.4139987]],
    [ "Transformatorenstation Steinlachalle 61",[9.0554156, 48.5093527]],
    [ "Prützke NORD",[12.6267698, 52.3361724]],
    [ "Meppen",[7.2747047, 52.7535891]],
    [ "10 KV",[7.1446642, 51.2559212]],
    [ "Klein Bünsdorf",[10.968878, 53.849661]],
    [ "Umspannwerk Rehna",[11.0291936, 53.779723]],
    [ "Fulda/Mitte",[9.6830807, 50.5533126]],
    [ "Neurott",[8.6253825, 49.3566553]],
    [ "Dörenberg",[7.6092254, 51.1411287]],
    [ "UW Bismark",[11.5559033, 52.6694165]],
    [ "Umspannstation Bahnsdorf",[14.1114544, 51.5666944]],
    [ "Wiesbadenerstr. 235a",[8.4486854, 50.1715271]],
    [ "KW Weiher",[7.0324888, 49.3348318]],
    [ "Umspannwerk Borstel",[10.1861813, 53.8240827]],
    [ "UW Ellenberg",[10.9321027, 52.8032782]],
    [ "ST Leipziger Straße",[9.0485955, 50.1806575]],
    [ "Umspannwerk Niendorf",[10.5967431, 53.8240185]],
    [ "Schaltposten Fürth",[11.0091172, 49.4658338]],
    [ "UW Kirchbachstraße",[8.8508401, 53.0841018]],
    [ "Umspannwerk Löwentor",[9.1885257, 48.8083019]],
    [ "10 KV Sparkasse",[7.3517353, 51.2985909]],
    [ "Granzow",[12.2485288, 52.9537254]],
    [ "10 kV-Station 5953",[7.2106112, 51.4806884]],
    [ "10 kV-Station Dorotheenstr. 5",[7.2079294, 51.4791668]],
    [ "Umspannwerk Gößnitz",[12.4326928, 50.875119]],
    [ "Umspannwerk Genin Süd",[10.6611359, 53.8359936]],
    [ "Eberswalde 1",[13.7491274, 52.8224755]],
    [ "Neu Rietz",[12.7810604, 52.0799625]],
    [ "Umspannwerk Oberheimbach",[7.7401784, 50.0096822]],
    [ "Bockenem",[10.1280083, 52.0191428]],
    [ "Umspannwerk DD-Nossener Brücke",[13.7091459, 51.0430926]],
    [ "10 KV Am Timmerbeul",[7.3738582, 51.3061208]],
    [ "Ebeleben",[10.7424403, 51.2802967]],
    [ "Umspannwerk Dittersdorf",[13.001346, 50.7395304]],
    [ "Schaltposten Halbe",[13.6933019, 52.1161889]],
    [ "Netze BW Umspannwerk Vaihingen/Enz",[8.949638, 48.9527341]],
    [ "Henstedt-Loh",[9.9985011, 53.7874258]],
    [ "Moorweg",[8.3058847, 51.8620475]],
    [ "10 kV-Station Akademiestr. 26 A",[7.2307409, 51.477553]],
    [ "Reckahn",[12.5621709, 52.3455362]],
    [ "Umspannwerk Hattersheim",[8.5037861, 50.0742597]],
    [ "Umspannwerk Niedernhausen",[8.3198434, 50.161922]],
    [ "Kehr",[6.4132605, 50.3372182]],
    [ "10 kV-Station Altenbochumer Straße 39",[7.2397873, 51.4761485]],
    [ "Schaltposten Goldshöfe",[10.1281238, 48.893692]],
    [ "Schaltposten Altenbeken",[8.9458836, 51.7661063]],
    [ "Umspannwerk Pentling",[12.062676, 48.9819593]],
    [ "Station Eppenhausen",[7.5048493, 51.3610127]],
    [ "Umspannwerk Wetzen",[10.243834, 53.1667563]],
    [ "Umspannwerk Bad Reichenhall Nord",[12.8904704, 47.7410095]],
    [ "Schaltposten Homburg (Saar)",[7.337743, 49.3293321]],
    [ "Schaltposten Sangerhausen",[11.2878999, 51.4790391]],
    [ "Schaltposten Rheine",[7.4389347, 52.2725584]],
    [ "Schaltposten Neunkirchen (Saar)",[7.1718278, 49.3515207]],
    [ "Schaltposten Elsterwerda",[13.5181491, 51.4639196]],
    [ "Schaltposten Heidelberg",[8.6779833, 49.401803]],
    [ "Schaltposten Schifferstadt",[8.3747875, 49.4045629]],
    [ "Schaltposten Essen-Steele",[7.0953685, 51.4408506]],
    [ "Schaltposten Minden",[8.9645879, 52.2953929]],
    [ "Schaltposten Lalendorf",[12.3994182, 53.7541887]],
    [ "Schaltposten Düren",[6.5008684, 50.8250974]],
    [ "Schaltposten Herford",[8.660599, 52.1169236]],
    [ "Schaltposten Rottendorf",[10.0287969, 49.7958451]],
    [ "Pang",[12.0898486, 47.8393648]],
    [ "Umspannwerk Arnstorf",[12.8078326, 48.5626632]],
    [ "UW Ortrand",[13.7703886, 51.3789833]],
    [ "Beedenbostel An der Bahn",[10.2711752, 52.6439989]],
    [ "Gerlingen",[9.0522481, 48.8020263]],
    [ "10 kV-Station Weitmarer Str.2",[7.2025842, 51.4644473]],
    [ "Gägelow",[11.364407, 53.8851892]],
    [ "10 KV Dahler Berg neb.62",[7.2375032, 51.2802074]],
    [ "Umspannwerk Ostkilver",[8.5017032, 52.2161106]],
    [ "Umspannwerk Kunrau",[11.0393109, 52.5699343]],
    [ "Niedersonthofen, Am Dorfbach",[10.2314251, 47.6285843]],
    [ "10 KV Gartenstraße",[7.2445294, 51.3663567]],
    [ "Umspannwerk Kronach",[11.3256686, 50.2321104]],
    [ "10 KV Friedrichstraße",[7.2467759, 51.3664894]],
    [ "10 kV-Station Glockengarten 5",[7.2323328, 51.4737573]],
    [ "10 kV-Station Ulmenallee 30",[7.2403756, 51.4726004]],
    [ "10 kV Freigrafendamm 5",[7.2406256, 51.4737764]],
    [ "Am Umspannwerk",[8.7035684, 53.1909489]],
    [ "Am Wendehammer",[8.7007745, 53.19266]],
    [ "UW Arkebek",[9.2452912, 54.1499781]],
    [ "10 KV",[7.2470488, 51.298422]],
    [ "Hagen Im Grashof",[8.6487386, 53.3580481]],
    [ "SA Hagen",[8.6437938, 53.3675371]],
    [ "Umspannst. Haydenstr.",[9.3209096, 48.6251441]],
    [ "10 kV-Station 5940",[7.2382622, 51.4696146]],
    [ "Roßleben PVA Bottendorfer Straße",[11.4188251, 51.2984606]],
    [ "10 kV-Station Adalbertstr. 1A",[7.1891311, 51.4951314]],
    [ "Schulstraße",[11.4297599, 51.2959433]],
    [ "Umspannwerk Sieverstedt",[9.4431, 54.6371147]],
    [ "Umspannwerk Garching",[11.6594058, 48.2603555]],
    [ "Pillgram",[14.4116077, 52.3318442]],
    [ "ST Schwimmbadstr.",[9.0436511, 50.1729812]],
    [ "Tamm",[9.1478942, 48.9274131]],
    [ "DOW",[9.4984841, 53.6391809]],
    [ "Blockland",[8.8183, 53.1079458]],
    [ "Ortsnetzstation Triebstraße",[8.607146, 49.5089395]],
    [ "10 KV Rennbahn",[7.2776662, 51.2829991]],
    [ "SAP Arena Süd",[8.5147805, 49.4638698]],
    [ "Gleidingen 110 kV",[10.4208447, 52.2373402]],
    [ "Hüttenbühl",[8.6611802, 49.3852251]],
    [ "Umspannanlage Nord",[8.6275376, 49.4191357]],
    [ "Heidelberg West",[8.6504227, 49.4077]],
    [ "Trafostation Dischingerstraße",[8.6386866, 49.4076259]],
    [ "10 KV Adlerstraße ggü. 5",[7.1896479, 51.2677073]],
    [ "10 KV Unterdörnen ggü. 75",[7.1912934, 51.2681884]],
    [ "651 Umspannwerk Flughafen Südost",[8.5893674, 50.0262079]],
    [ "Umspannwerk Seewiesen",[9.1773885, 48.8198218]],
    [ "10 kV Brenscheder Str. 36A",[7.2333987, 51.4538048]],
    [ "Alter Friedhof",[7.3943477, 51.3885181]],
    [ "Umspannwerk Kemnitz",[12.2310758, 53.134318]],
    [ "Vehlin",[12.1635496, 52.9591793]],
    [ "Umspannwerk Zimmern",[8.56313, 48.1719884]],
    [ "Haindl",[14.3181155, 53.0854606]],
    [ "10 KV Langerfelder Straße 37",[7.2292919, 51.2732255]],
    [ "Station Kopernikus",[6.7891451, 51.5049348]],
    [ "Umspannwerk Rinteln-Süd",[9.074507, 52.1706382]],
    [ "Station GVZ 2",[11.1224442, 50.9854704]],
    [ "Umspannwerk Cösitz",[12.0569328, 51.6510539]],
    [ "Umspannwerk Großräschen",[14.0228849, 51.6071713]],
    [ "Umspannwerk Wessin",[11.6966221, 53.5716941]],
    [ "Station Alko",[8.9161408, 53.1592418]],
    [ "Station Gaußstraße",[8.91291, 53.1384198]],
    [ "Station Goebelstraße Mitte",[8.9175734, 53.1586402]],
    [ "Station Gutenbergstraße Mitte",[8.902414, 53.155609]],
    [ "Station Hilligenwarf",[8.9219141, 53.1636441]],
    [ "Station LDW",[8.9173586, 53.160638]],
    [ "Station Scheeren",[8.9156922, 53.1597978]],
    [ "Schrepkow",[12.0910917, 52.968428]],
    [ "Kletzke",[12.0465679, 52.9896838]],
    [ "Umspannwerk",[10.1809386, 47.8691306]],
    [ "Blatternhaus",[10.3214313, 47.73375]],
    [ "Netzstation",[8.3095331, 51.6567492]],
    [ "UA Lisdorfer Berg",[6.7558886, 49.2823899]],
    [ "10 kV-Station Seippelstr. 4",[7.2689129, 51.4716565]],
    [ "Twieflingen",[10.9204208, 52.1208978]],
    [ "Römerstraße 7",[8.5522728, 49.4967088]],
    [ "Kantow Nord",[12.5878564, 52.9373882]],
    [ "Kantow",[12.587432, 52.9366919]],
    [ "Kränzlin",[12.7177795, 52.9310944]],
    [ "Umspannwerk Soholm",[9.048441, 54.7071423]],
    [ "Umspannwerk Kandrich",[7.7558193, 49.9967168]],
    [ "10 KV",[7.3620869, 51.299129]],
    [ "10 KV Rauental 28",[7.227649, 51.2729525]],
    [ "Seitzenmühle 01",[11.1636831, 49.1873534]],
    [ "Unterwerk Freihalden",[10.5050025, 48.3823122]],
    [ "Breklum-Ost",[8.9926119, 54.5986459]],
    [ "Oldersbek",[9.1642551, 54.4529937]],
    [ "Hakenstedt",[11.2577707, 52.1710674]],
    [ "Umrichterwerk Hof",[11.9157842, 50.3449197]],
    [ "Erxleben",[11.2164592, 52.235909]],
    [ "Nordstemmen",[9.798788, 52.1641384]],
    [ "Umspannwerk Zell am Harmersbach",[8.0630171, 48.339661]],
    [ "UW Flughafen München",[11.7683561, 48.3574009]],
    [ "Braunschweig Broitzemer Straße",[10.507522, 52.2605772]],
    [ "DB Schaltposten Schifferstadt",[8.3634808, 49.3912818]],
    [ "Braunschweig Stadtmitte",[10.528176, 52.2670561]],
    [ "Löwenstedt",[9.1472793, 54.6339777]],
    [ "Braunschweig Leonhardplatz",[10.5412477, 52.2573151]],
    [ "Gleichrichterunterwerk Blankenfelde",[13.4151512, 52.3413019]],
    [ "Stützpunkt Pansdorf",[10.7135146, 53.9831229]],
    [ "Umspannanlage Offenburg Nord Kehlerstraße 2a",[7.9390779, 48.4874147]],
    [ "Lierbachtal",[7.8603339, 48.360022]],
    [ "380-kV-Umspannwerk Eichstetten",[7.7544391, 48.0986896]],
    [ "UW Kümper",[7.4147545, 52.0239161]],
    [ "Station Wilhelm Busch Straße",[11.0480216, 50.9675302]],
    [ "Station Zum Karren",[10.9908356, 51.0258981]],
    [ "Weende",[9.956735, 51.5627321]],
    [ "Gleichrichterwerk",[10.486402, 52.2483526]],
    [ "Pattensen",[10.1366828, 53.3188804]],
    [ "Gleichrichterwerk",[10.5212487, 52.2135019]],
    [ "Angermünde TS Bahnhof",[13.9969923, 53.0164143]],
    [ "Station Ascheberg",[7.6329594, 51.7874138]],
    [ "UW Alikendorf Wind",[11.2831879, 51.9774535]],
    [ "UW Harsleben",[11.0948334, 51.8636517]],
    [ "Metzdorf",[14.1740226, 52.6488986]],
    [ "Grasberg Arp-Schnitger-Straße",[8.991931, 53.1798311]],
    [ "Ginsterweg",[10.0444793, 53.7320714]],
    [ "Umspannwerk Gutleutstraße",[8.6489642, 50.0979924]],
    [ "10 kV Station Borken Jahnstr.",[6.8515281, 51.8479267]],
    [ "TS Birkenalle",[13.9836774, 53.005051]],
    [ "Station Fischertor",[6.6001913, 51.6571155]],
    [ "10-kV-Station Frankenhof",[7.0248962, 51.8051529]],
    [ "10 kV-Station Düppelstraße 43",[7.2286953, 51.4755203]],
    [ "Katensen",[10.1805123, 52.4420652]],
    [ "Umspannwerk Marienstraße",[9.17098, 48.7728791]],
    [ "Umspannwerk Hochstraße",[8.6755724, 50.1161999]],
    [ "Umspannwerk Timmendorf",[10.7804425, 53.9951389]],
    [ "Wikingerstraße 4",[8.5535004, 49.4997868]],
    [ "Bahnstromwerk Schlippenstraße",[11.6042582, 50.9317019]],
    [ "Bohmte",[8.3003095, 52.3846522]],
    [ "20kV Schalthaus",[8.3226422, 50.4572313]],
    [ "10 KV Rollmannstraße",[7.394322, 51.3077484]],
    [ "UP Kandel Höhenweg",[8.1792013, 49.0780697]],
    [ "Station Blumenstraße Neubaugebiet",[11.0105319, 50.9852088]],
    [ "Umspannwerk Heigeleshof",[9.9864833, 48.3996151]],
    [ "Station Im Truper Feld",[8.9017843, 53.1389745]],
    [ "Station J.-C.-Findorff-Straße",[8.9100562, 53.1370703]],
    [ "Station Truper Eichenhof",[8.899599, 53.1380502]],
    [ "Umspannwerk Buckendorf",[11.2442395, 50.0101966]],
    [ "Amorbacher Straße 29",[8.5424423, 49.4974085]],
    [ "10 KV Lindenstraße 2",[7.389562, 51.3043262]],
    [ "Station Butendiek",[8.9074703, 53.1354457]],
    [ "Umspannwerk Stuttgart-Hafen",[9.2621257, 48.7626778]],
    [ "Umspannwerk Neugraben",[9.8585391, 53.4731781]],
    [ "Klinikum",[7.590405, 51.9601352]],
    [ "RAG 110KV",[7.7416187, 52.2855529]],
    [ "Schaltposten Stuttgart-Bad Cannstatt",[9.2338212, 48.7978958]],
    [ "Umspannanlage Hochheim",[8.3692821, 50.0216005]],
    [ "Umspannwerk Bruchsal-Kändelweg",[8.5633063, 49.1331969]],
    [ "10 kV Immanuel-Kant-Str. 18-20",[7.2522835, 51.4736051]],
    [ "10 kV-Station Massenbergstraße 12A",[7.2210365, 51.4804377]],
    [ "SA Langenfelde",[8.7278799, 53.3790784]],
    [ "Natterheide",[11.61635, 52.7450213]],
    [ "Kerkow TrSt VEG",[13.9889905, 53.0369316]],
    [ "Umspannanlage Darmstadt Mitte",[8.6575508, 49.8722868]],
    [ "Umspannwerk Schwerte Nord",[7.5569723, 51.4442457]],
    [ "Station Danziger Straße",[8.9220848, 53.1517736]],
    [ "Station Gewerbe",[8.909196, 53.1514216]],
    [ "Station Glockenturm",[11.5912239, 50.69522]],
    [ "Mücke",[9.1608847, 50.5986989]],
    [ "Schaltposten Göttingen",[9.927256, 51.5436221]],
    [ "Unterwerk SWB",[7.1292668, 50.7511787]],
    [ "10 KV",[7.3847863, 51.3053651]],
    [ "Umrichterwerk Nürnberg",[11.007085, 49.4227666]],
    [ "Prützke Süd",[12.6262855, 52.3357497]],
    [ "Netzen",[12.6946367, 52.3271997]],
    [ "Umspannwerk Universität",[8.4978356, 52.0396538]],
    [ "Wind-UW Wusterwitz",[12.3840593, 52.3907626]],
    [ "Demsin",[12.2510549, 52.4519664]],
    [ "Winningstedt",[10.7765085, 52.080301]],
    [ "Umspannwerk Reinfeld",[10.4948046, 53.8235972]],
    [ "Möthlitz",[12.3900983, 52.4679956]],
    [ "Umspannwerk Burgkunstadt",[11.2393369, 50.1370302]],
    [ "Halver",[7.5069578, 51.1887361]],
    [ "Seefeld",[13.655148, 52.6106677]],
    [ "Sydower Fließ West",[13.6819089, 52.6979995]],
    [ "Barlt",[9.0867019, 54.0040928]],
    [ "Lüchow Nord",[11.1648732, 52.9837336]],
    [ "10 KV",[7.3349927, 51.3294978]],
    [ "10 kV-Station Steilstr. 2",[7.2434926, 51.4280972]],
    [ "Weingarten",[8.307027, 49.2571911]],
    [ "10 kV-Station Brenscheder Str. 2",[7.2374326, 51.4574643]],
    [ "10 kV-Station Brenscheder Str. 57",[7.2310967, 51.4507807]],
    [ "Trafoturm",[8.8800847, 51.9151852]],
    [ "10 kV-Station Markstr. 266A",[7.2342184, 51.4480077]],
    [ "10 kV-Station Stiepeler Str. 22A",[7.2351033, 51.4523424]],
    [ "10 kV-Station Stensstr. 1-3",[7.2025433, 51.4619277]],
    [ "10 kV-Station Melschedeweg 49",[7.2343143, 51.4472978]],
    [ "10 kV-Station 8944",[7.2221185, 51.4450537]],
    [ "10 kV-Station Wohlfahrtstr. 19",[7.2216249, 51.4556617]],
    [ "10 kV-Station Wohlfahrtstr. 124",[7.2230543, 51.4528388]],
    [ "10 kV-Station Bonhoefferstr. 117",[7.2480851, 51.4819371]],
    [ "10 kV-Station Pfarrer-Halbe-Str. 19A",[7.2133297, 51.442254]],
    [ "10 kV-Station Kellermannsweg 2 A",[7.2120146, 51.4484723]],
    [ "10 kV-Station Brantropstr. 88",[7.1917469, 51.4614092]],
    [ "10 kV-Station Schützenstr. 5A",[7.1979381, 51.4627765]],
    [ "10 kV-Station Knoopstr. 39A",[7.1950923, 51.4611161]],
    [ "10 kV-Station Dürerstraße 40",[7.1995123, 51.4579701]],
    [ "10 kV-Station Hattinger Str. 219",[7.2038006, 51.4631836]],
    [ "10 kV-Station Hattinger Str. 425",[7.1947052, 51.4463905]],
    [ "10 kV-Station Am Schamberge 50",[7.1864022, 51.433701]],
    [ "10 kV-Station Am Sonnenberg 55",[7.1838274, 51.4297673]],
    [ "10 kV-Station Gollackerstr. 2",[7.1713908, 51.4313066]],
    [ "10 kV-Station Am Röderschacht 44",[7.1742363, 51.437206]],
    [ "10 kV-Station Ostholzer Hang 70",[7.1854094, 51.4316215]],
    [ "10 KV",[7.1718667, 51.4103544]],
    [ "10 kV Dibergstr. 61 A",[7.208818, 51.4709076]],
    [ "10 kV-Station Christstr. 45",[7.2152256, 51.4703703]],
    [ "10 kV-Station Nevelstr. 65B",[7.1844749, 51.4461002]],
    [ "10 kV-Station Ruhrstr.108",[7.1715259, 51.448954]],
    [ "10 kV-Station Immanuel-Kant-Str. 25",[7.2493997, 51.4747947]],
    [ "10 kV-Station Glockengarten 38",[7.2379298, 51.4718303]],
    [ "Damm",[8.8208277, 49.3200564]],
    [ "10 KV Buntebachstraße",[7.4781914, 51.3495732]],
    [ "Beverstedt-Meyerhofstraße",[8.8227034, 53.4361864]],
    [ "VW/Ost",[7.1666477, 53.3508375]],
    [ "Umspannwerk Ratzeburg West",[10.7420375, 53.6918458]],
    [ "10 KV",[7.4804674, 51.3491606]],
    [ "Neu Kosenow",[13.745236, 53.780314]],
    [ "S-Bahn Gleichrichterunterwerk Lankwitz",[13.3336619, 52.4335728]],
    [ "Umrichterwerk Lohsa/West",[14.3693841, 51.4034237]],
    [ "An den Fischteichen",[11.1469072, 51.7933936]],
    [ "10 KV Brackelsberg",[7.3393728, 51.288341]],
    [ "Schaltanlage Damsdorf",[10.3171934, 54.0617603]],
    [ "10 kV-Station Tippelsberger Straße 2",[7.2128225, 51.508947]],
    [ "10 kV-Station Auf dem Dahlacker 6",[7.2102681, 51.5086946]],
    [ "10 KV Herner Straße 368",[7.2114288, 51.5098279]],
    [ "10 kV-Station Vorm Gruthoff 5",[7.2067268, 51.5093505]],
    [ "Schöne Aussicht",[7.901516, 48.3151905]],
    [ "Rutzendorf",[10.6604032, 49.2812842]],
    [ "Kormoranstraße 43",[8.5511424, 49.5010024]],
    [ "Straßenheimer Weg",[8.5654121, 49.5114413]],
    [ "Mudauer Ring 39",[8.5392897, 49.4966231]],
    [ "10 kV-Station Lahnstr. 2",[7.2528111, 51.4976743]],
    [ "10 kV-Station Sorpestr. 9A",[7.249632, 51.4969095]],
    [ "10 kV-Station 2913",[7.2391884, 51.4958135]],
    [ "Umspannwerk Hohenlockstedt",[9.6082594, 53.9536188]],
    [ "10 kV-Station Schleipweg 20",[7.2707082, 51.5017973]],
    [ "Umspannwerk Höllbachstraße",[12.1178769, 49.0292774]],
    [ "10 kV-Station Castroper Hellweg 176 A",[7.2623954, 51.5063764]],
    [ "10 kV-Station Haydnstr. 3",[7.2683303, 51.5058005]],
    [ "10 kV-Station Zunftwiese 38",[7.2625466, 51.508471]],
    [ "SA Bremervörde-Bürgerpark",[9.1325544, 53.4916037]],
    [ "Hagen Am Wall",[8.6441739, 53.3592937]],
    [ "10 kV-Station Hunsrückstr. 48",[7.2456504, 51.5101523]],
    [ "Farge",[8.5165667, 53.2040944]],
    [ "Station Palme-Bach",[8.3938956, 51.2466476]],
    [ "S-Bahn Gleichrichterunterwerk Heinersdorf",[13.4284816, 52.5766784]],
    [ "Ohlensehlen",[8.8955129, 52.5650595]],
    [ "Trafostation Vossberg",[8.7805735, 53.2404819]],
    [ "10 kV-Station Kesterkamp 8",[7.1609289, 51.4237786]],
    [ "10 kV-Station Am Alten Sägewerk 4",[7.1981758, 51.4477324]],
    [ "10 kV-Station Elsa-Brändström-Str. 112",[7.1797175, 51.4551248]],
    [ "Sandebeck",[8.9767147, 51.8318937]],
    [ "10 kV-Station Im Kattenhagen 6",[7.1737758, 51.456238]],
    [ "10 kV-Station Ruhrstr. 37",[7.17563, 51.4540483]],
    [ "10 kV-Station Essener Str. 193",[7.1724786, 51.4718129]],
    [ "10 kV-Station Harenburg 2A",[7.1557743, 51.4643212]],
    [ "10 kV-Station Keltenweg 12",[7.1527416, 51.4684199]],
    [ "10 kV-Station Sachsenring 65",[7.1510284, 51.4686101]],
    [ "10 kV-Station Lohacker Str. 98",[7.1544435, 51.4681164]],
    [ "10 kV-Station Friesenweg 12",[7.1507676, 51.466392]],
    [ "Hiltrup",[7.633766, 51.8931228]],
    [ "Gestorf",[9.6823628, 52.2230963]],
    [ "Schaltknoten Langewiesen",[10.9908412, 50.6676091]],
    [ "10 kV Postkutschenweg 2",[7.2518407, 51.471879]],
    [ "10 kV-Station Laerstr. 19",[7.2502986, 51.4705687]],
    [ "10 kV Auf der Heide 3 A",[7.2420535, 51.4660925]],
    [ "10 kV-Station Am Leisediek 10",[7.2534976, 51.4685272]],
    [ "10 kV-Station Theoderichstr. 27",[7.2501962, 51.4676294]],
    [ "10 kV-Station Am Dornbusch 23",[7.2350178, 51.468097]],
    [ "Umspannstation Proschim",[14.1669486, 51.5426147]],
    [ "RDK7",[8.3043553, 49.0116928]],
    [ "10 KV",[7.3181596, 51.3041515]],
    [ "Station 04733",[10.9950869, 51.0117197]],
    [ "Am Buchenberg Schule",[11.0851318, 50.9428032]],
    [ "In der Wiese",[11.0864484, 50.9443098]],
    [ "Umspannstation Putlitz",[12.0873319, 53.2513443]],
    [ "Biblis B",[8.4119709, 49.7103226]],
    [ "Umspannwerk Mehrum",[10.0864696, 52.316137]],
    [ "Umspannwerk Mehrum",[10.0896372, 52.3139289]],
    [ "Würgassen 380/220 kV",[9.390005, 51.6398713]],
    [ "Schaltposten München-Karlsfeld",[11.4628541, 48.2037438]],
    [ "Schaltwerk Datteln",[7.3363045, 51.6287296]],
    [ "Schaltanlage Datteln 4",[7.3389475, 51.6329862]],
    [ "HVDC Herrenwyk (Baltic Cable)",[10.8021624, 53.8959074]],
    [ "Nauen",[12.8497082, 52.6147664]],
    [ "Neckarauer Straße 241",[8.484209, 49.4676219]],
    [ "Umspannwerk Maximiliansau",[8.2704723, 49.0283832]],
    [ "Wiener Straße",[11.061231, 49.3859157]],
    [ "Trafostation Modersohnstraße",[8.7884746, 53.2363934]],
    [ "Seckacher Straße 57",[8.5421268, 49.4955796]],
    [ "Neuhof",[9.5951813, 50.4608845]],
    [ "Osterburker Straße 46",[8.5460416, 49.493881]],
    [ "Schaltposten Freilassing",[12.9667902, 47.8370848]],
    [ "Stadtilm",[11.072653, 50.778962]],
    [ "Umspannwerk HafenCity",[10.0070004, 53.5440136]],
    [ "Neckarplatt",[8.5125674, 49.4877818]],
    [ "Umspannwerk Elsterwerda",[13.5407016, 51.4596485]],
    [ "Weikersheimer Ring 16",[8.5492639, 49.4923724]],
    [ "Umspannwerk Leipzig C",[12.3901709, 51.3675476]],
    [ "Umspannwerk Lausen",[12.2794641, 51.3058473]],
    [ "Schaltposten Germersheim",[8.3643896, 49.2253471]],
    [ "Schalthaus Spexard",[8.4209913, 51.8873084]],
    [ "10 KV Elberfelder Straße",[7.3287738, 51.3147945]],
    [ "Groß Schwiesow",[12.1056061, 53.8575336]],
    [ "Schaltposten Wuhlheide",[13.550688, 52.4697793]],
    [ "10kV-Station",[7.4661502, 51.3819456]],
    [ "Trafostation Marktweide",[8.7843988, 53.2286152]],
    [ "Unterwerk Eischleben",[10.9913686, 50.8724767]],
    [ "10 KV",[7.1603706, 51.2678033]],
    [ "10 KV",[7.1682007, 51.2703536]],
    [ "10 KV Gennebrecker Straße 5",[7.2157633, 51.2881967]],
    [ "10 KV Döinghauser Straße",[7.2844462, 51.2893734]],
    [ "Schöppenstedt",[10.7697718, 52.1530052]],
    [ "UW Greifswald Süd",[13.3833774, 54.0760209]],
    [ "Bramstedt Kampstraße",[8.691073, 53.364164]],
    [ "Chemiepark Hüls",[7.0911545, 51.6967891]],
    [ "Dittmern",[9.9194285, 53.0083911]],
    [ "S-Bahn Gleichrichterunterwerk Grünauer Kreuz",[13.5574082, 52.4242474]],
    [ "10 KV",[7.39791, 51.33707]],
    [ "10 kV Neue Straße 38",[7.3940929, 51.3360662]],
    [ "10 KV Eilper Straße 115",[7.4855164, 51.3488538]],
    [ "Karlsruhe Nord",[8.3921323, 49.0153658]],
    [ "Umspannwerk Grünwinkel",[8.3558638, 48.999125]],
    [ "Lage",[8.8124784, 51.9827631]],
    [ "Dessau Hbf 02",[12.2372801, 51.8420175]],
    [ "Seckacher Straße 130",[8.5440419, 49.494765]],
    [ "Eberswalde Hafen",[13.7621141, 52.8523787]],
    [ "Finstergasse",[11.0340308, 48.927273]],
    [ "Unterwerk Bachstedt",[11.191773, 51.055056]],
    [ "SA Hambergen",[8.8289508, 53.3161247]],
    [ "Umspannwerk Süderdonn",[9.1064505, 53.972393]],
    [ "Salomonsborn Am Rosenborn",[10.9507502, 50.9997744]],
    [ "Salomonsborn Kronenburgstraße",[10.9461401, 51.0005907]],
    [ "Blitzenburgweg",[8.7077204, 52.0194438]],
    [ "Station Alperstedt 09",[11.0433484, 51.0938676]],
    [ "St.Klärwerk",[11.0453745, 49.6966627]],
    [ "Zwischenpumpwerk Johannisthal",[13.4888271, 52.4513043]],
    [ "Spessartstraße",[8.527314, 49.4841886]],
    [ "10 kV-Station Rechener Str. 9",[7.2199755, 51.4775709]],
    [ "UW Weesby",[9.1150408, 54.8449707]],
    [ "UW Süderlügum",[9.1151153, 54.8440231]],
    [ "10 KV Bendahler Straße",[7.1641408, 51.2566616]],
    [ "10 kV-Station Eintrachtstraße ggü 48",[7.2045534, 51.2779403]],
    [ "Braunsroda Ort",[11.2497624, 51.3085863]],
    [ "Umspannwerk Heldrungen",[11.2025051, 51.3004708]],
    [ "10 kV-Station Falterweg 28A",[7.2258462, 51.4453898]],
    [ "10 kV-Station Markstr. 75A",[7.2577302, 51.4599598]],
    [ "Weimarer Straße 15",[8.6478428, 49.5305534]],
    [ "Ludwig-Erhard-Allee 25",[8.6224539, 52.0098743]],
    [ "Umspannwerk Bischofswerda",[14.1946286, 51.1248243]],
    [ "Angermünde TS Markt",[14.0045137, 53.0180263]],
    [ "Baumgarten",[13.9331975, 53.3383009]],
    [ "Heldrungen Oldislebener Weg",[11.2135975, 51.3034275]],
    [ "Schaltposten München-Giesing",[11.6035715, 48.096949]],
    [ "Umspannwerk Bannesdorf",[11.2388829, 54.4678131]],
    [ "10 KV",[7.4642113, 51.3621687]],
    [ "10-kV-Station Dieningstraße",[7.6234552, 51.7911127]],
    [ "10-kV-Station Himmelstraße",[7.6186599, 51.7883132]],
    [ "VEW Umspannwerk Recklinghausen, ZEITREISE STROM",[7.2080849, 51.5612297]],
    [ "10 KV",[7.2666334, 51.3261523]],
    [ "Umspannwerk Meuselwitz",[12.3161648, 51.0741649]],
    [ "Bahnstrom Unterwerk Dörstewitz",[11.9043397, 51.392787]],
    [ "MN15093, Weißewarte, Stadtweg",[11.8725147, 52.4679209]],
    [ "10 KV Brüggerfelder Straße",[7.340457, 51.3161467]],
    [ "10 KV Habichtstraße",[7.3295908, 51.3251556]],
    [ "Philosophenplatz",[8.4891284, 49.4812406]],
    [ "Schaltposten Westkreuz",[13.2738244, 52.4971733]],
    [ "10 KV Hahnerberger Straße 287",[7.1480163, 51.2157621]],
    [ "Umspannstation Allstedt",[11.373316, 51.3981016]],
    [ "Oswaldstraße",[8.5493014, 49.4971538]],
    [ "Leopoldstraße",[7.8401913, 48.3399492]],
    [ "Schaltposten Koblenz",[7.586704, 50.3458503]],
    [ "Schaltposten Olching",[11.3474285, 48.2073327]],
    [ "Jardelund",[9.195869, 54.8497024]],
    [ "10 KV Pause",[7.3295177, 51.3007621]],
    [ "10 KV Würth",[7.3306944, 51.3001363]],
    [ "Breitenbich",[10.3848556, 51.2969031]],
    [ "10 KV Schweflinghausen 1",[7.3926751, 51.2578028]],
    [ "10 KV",[7.4028667, 51.2588436]],
    [ "Station 1",[11.1055113, 49.1921253]],
    [ "Magdeburg/Süd-West",[11.6215513, 52.0952425]],
    [ "Löhnhorst Hinter den Fuhren",[8.6429774, 53.20399]],
    [ "SA Brundorf",[8.6843946, 53.2311692]],
    [ "Coppanz",[11.5149562, 50.9007348]],
    [ "Allermöhe",[10.1121062, 53.4885931]],
    [ "10 kV Linnepe",[7.3880122, 51.3355133]],
    [ "10 KV Hangstraße",[7.3878751, 51.3119794]],
    [ "Westerberg",[10.6446842, 52.0670158]],
    [ "UW Großenwiehe",[9.2991681, 54.7012983]],
    [ "Tierhotel",[11.8739582, 52.273772]],
    [ "Unterwerk Wolfsberg",[10.9981766, 50.6882781]],
    [ "U-Bahn Gleichrichterwerk Biesdorf-Süd",[13.5437001, 52.4987296]],
    [ "10KV Auf dem Lölfert",[7.5840601, 51.3645045]],
    [ "10 kV-Station 8941",[7.1964227, 51.4499848]],
    [ "Mittelhafen",[7.6389603, 51.9509099]],
    [ "Kuppelstelle Altena",[7.6722019, 51.2986436]],
    [ "Crainfeld/Wind",[9.3588142, 50.480637]],
    [ "TS Templiner Straße",[13.996665, 53.0231962]],
    [ "Emstek",[8.155752, 52.8098905]],
    [ "Station Lehmen",[7.4603042, 50.2729386]],
    [ "Umspannwerk Gräfendorf",[13.2271481, 51.6432869]],
    [ "Umspannwerk Selsingen",[9.1841902, 53.368988]],
    [ "331",[12.2187077, 51.9063692]],
    [ "329",[12.218859, 51.9051041]],
    [ "W-Reuter-Straße I",[7.3980238, 51.389408]],
    [ "W-Reuter-Straße II",[7.3965059, 51.3905478]],
    [ "Unterwerk Kraichtal",[8.7315142, 49.0900251]],
    [ "10 KV",[7.503166, 51.5041063]],
    [ "10 KV",[8.0974073, 51.3355364]],
    [ "OL 02",[8.1592728, 53.1394997]],
    [ "Umspannwerk Delitzsch",[12.3566738, 51.520363]],
    [ "Baumw.-hofstr.",[9.0440486, 50.2145303]],
    [ "Kreuzstr",[9.041048, 50.2159426]],
    [ "Waldstr.",[9.0375297, 50.2177656]],
    [ "Birkenstr",[9.0457152, 50.2183079]],
    [ "Schmiedeberg",[9.0404725, 50.2178651]],
    [ "Am Fallbach",[9.0499621, 50.2175825]],
    [ "Lindenplatz",[7.870467, 48.342972]],
    [ "Umspannwerk Zeitz Guss",[12.1178505, 51.0590413]],
    [ "10 kV-Station Königsallee 121",[7.220479, 51.4632704]],
    [ "10 kV-Station Hattingerstraße 311a",[7.1959852, 51.4569579]],
    [ "Schwetzinger Straße",[8.4761195, 49.4794612]],
    [ "Hermsheimer Straße 82",[8.5050582, 49.4671047]],
    [ "Schlachthofstraße",[8.4897328, 49.473555]],
    [ "PVA Abakus Solar Roßleben",[11.4460988, 51.3005182]],
    [ "Burgheim",[7.8820116, 48.3470957]],
    [ "10 KV Wulfhorststraße",[7.3813135, 51.2632774]],
    [ "Amorbacher Straße",[8.5442275, 49.4989625]],
    [ "Merowingerstraße 13",[8.5566061, 49.498071]],
    [ "Carl-Ritter-Straße",[11.138001, 51.7877379]],
    [ "MLi 2601 Reichenbach Mitteldorf",[13.2400373, 50.9860833]],
    [ "Umspannstation Streichental",[10.0058376, 49.4219227]],
    [ "Umspannstelle Rinderfeld",[9.9897789, 49.418791]],
    [ "Talstraße",[8.5317553, 49.4880773]],
    [ "St.-Peter-und-Paul-Str.",[8.5314669, 49.4861844]],
    [ "Uhlhorn TonWendbüdel 2",[8.5014433, 52.9649044]],
    [ "Kabelübergabestation Diestegge",[6.8359794, 51.7835611]],
    [ "Kabelübergabestation Löchte",[6.8026396, 51.7606912]],
    [ "Untermünstertal Station Gubor",[7.7621891, 47.8596172]],
    [ "Umspannwerk Bernitt",[11.9104321, 53.8851637]],
    [ "Roßleben Friedensstraße",[11.4354623, 51.2967784]],
    [ "Lange Straße",[8.634617, 53.0491279]],
    [ "Umspannstation Eichhof",[9.8840196, 49.3940218]],
    [ "TS Oderberger Chaussee",[14.0010606, 53.0063677]],
    [ "Roßleben PVA Gärtnereistraße",[11.4612475, 51.3008139]],
    [ "Umspannwerk Gießerei Sabrodt",[14.2648897, 51.5244039]],
    [ "Gänsdorf UW",[12.7689921, 48.8048526]],
    [ "Umspannstation Obhausen",[11.7040199, 51.3910177]],
    [ "Ernst-Barlach-Allee 5",[8.5026472, 49.4697922]],
    [ "Station Thalfang",[7.0084994, 49.7648342]],
    [ "Kuppelstelle Herzogenrath",[6.0904347, 50.8789144]],
    [ "Everswinkel",[7.8261987, 51.9211881]],
    [ "Edinger Riedweg 47",[8.5308175, 49.4216153]],
    [ "Kospa",[12.5943821, 51.4644128]],
    [ "Haas",[7.9041255, 48.3379769]],
    [ "Gleichrichterunterwerk Waßmannsdorf",[13.4631574, 52.3689823]],
    [ "Gleichrichterunterwerk Schönefeld",[13.5180954, 52.3944505]],
    [ "S-Bahn Gleichrichterunterwerk Baumschulenweg",[13.4824434, 52.4716625]],
    [ "S-Bahn Gleichrichterunterwerk Karow",[13.4601565, 52.6079735]],
    [ "S-Bahn Gleichrichterunterwerk Bernau",[13.5875343, 52.6736991]],
    [ "S-Bahn Gleichrichterunterwerk Greifswalder Straße",[13.4392977, 52.5396125]],
    [ "Gleichrichterunterwerk Schönholz",[13.3835361, 52.5709151]],
    [ "Kierspe",[7.597375, 51.1253061]],
    [ "S-Bahn Gleichrichterunterwerk Wittenau",[13.3329705, 52.5982813]],
    [ "Station Lamprecht",[9.8866042, 51.550326]],
    [ "Gleichrichterunterwerk Nikolassee",[13.1914714, 52.4280643]],
    [ "Gleichrichterunterwerk Schichauweg",[13.3886351, 52.4008462]],
    [ "S-Bahn-Gleichrichterunterwerk Südkreuz",[13.3654315, 52.4774172]],
    [ "Gleichrichterunterwerk Mahlow",[13.4056597, 52.3657443]],
    [ "S-Bahn Gleichrichterunterwerk Ostbahnhof",[13.4438002, 52.5079203]],
    [ "Gleichrichterunterwerk Fredersdorf",[13.7639242, 52.5261884]],
    [ "Gleichrichterunterwerk Biesdorfer Kreuz",[13.5438707, 52.5155653]],
    [ "S-Bahn Gleichrichterunterwerk Jannowitzbrücke",[13.4195264, 52.5140174]],
    [ "S-Bahn Gleichrichterunterwerk Lehrter Bahnhof",[13.3740145, 52.5249691]],
    [ "S-Bahn Gleichrichterunterwerk Tiergarten",[13.3366918, 52.5150433]],
    [ "S-Bahn Gleichrichterunterwerk Griebnitzsee",[13.1304401, 52.3948912]],
    [ "S-Bahn Gleichrichterunterwerk Hundekehle",[13.2474592, 52.4771007]],
    [ "S-Bahn Gleichrichterunterwerk Oderstraße",[13.4186437, 52.4660973]],
    [ "S-Bahn Gleichrichterunterwerk Nordbahnhof",[13.390274, 52.5303813]],
    [ "S-Bahn Gleichrichterunterwerk Erkner",[13.750679, 52.4307737]],
    [ "S-Bahn Gleichrichterunterwerk Friedrichshagen",[13.619747, 52.4571314]],
    [ "S-Bahn Gleichrichterunterwerk Köpenick",[13.5669125, 52.4636469]],
    [ "S-Bahn Gleichrichterunterwerk Rummelsburg",[13.5133342, 52.4881488]],
    [ "S-Bahn Gleichrichterunterwerk Schöneweide",[13.5219477, 52.4490611]],
    [ "S-Bahn Gleichrichterunterwerk Mexikoplatz",[13.2366649, 52.4349405]],
    [ "S-Bahn Gleichstromunterwerk Lichterfelde West",[13.2825313, 52.4386648]],
    [ "S-Bahn Gleichrichterunterwerk Heidelberger Platz",[13.3090353, 52.4816733]],
    [ "S-Bahn Gleichrichterunterwerk Marienfelde",[13.3760078, 52.4210393]],
    [ "S-Bahn Gleichrichterunterwerk Röntgental",[13.5084145, 52.6463418]],
    [ "S-Bahn Gleichrichterunterwerk Heiligensee",[13.2285569, 52.624611]],
    [ "S-Bahn Gleichrichterunterwerk Adlershof",[13.5393633, 52.4365183]],
    [ "S-Bahn Gleichrichterunterwerk Olympiastadion",[13.2455104, 52.5127353]],
    [ "S-Bahn Gleichrichterunterwerk Hermsdorf",[13.3062771, 52.6198941]],
    [ "S-Bahn Gleichstromunterwerk Hegermühle",[13.8764926, 52.5592294]],
    [ "S-Bahn Gleichrichterunterwerk Lichtenberg",[13.5099663, 52.5129217]],
    [ "Station Rückerswind Ort 1",[11.0568799, 50.3540522]],
    [ "Umspannwerk Leipzig D",[12.3676255, 51.3280068]],
    [ "Umspannstation Delitzsch Kraftwerk",[12.3238293, 51.5096794]],
    [ "Bethenhausen",[12.1929054, 50.9266618]],
    [ "Wollin 3",[12.4808408, 52.2759588]],
    [ "Station Lippstadt",[8.3646568, 51.6707515]],
    [ "10 KV Sonderschule Lasthausstr.",[7.0549591, 51.5919045]],
    [ "10 KV Veranstaltungsplatz",[7.0503839, 51.5882839]],
    [ "Schaltposten",[13.257651, 51.581129]],
    [ "Schaltposten Ludwigsburg",[9.1778622, 48.9017732]],
    [ "Schaltposten Ludwigshafen (Rhein)",[8.4302015, 49.4750321]],
    [ "Schaltposten Kreuzstraße",[11.7588368, 47.9165144]],
    [ "Gera/Süd",[12.0785329, 50.8547325]],
    [ "Kabelendmastanlage Lützschena",[12.2891801, 51.4011618]],
    [ "10 KV Hoch-/Schulstraße",[7.4772405, 51.3536565]],
    [ "Kuppelstelle Katzenfurt",[8.3394069, 50.6179257]],
    [ "Kuppelstelle Loreley",[7.7411894, 50.1305511]],
    [ "Kuppelstelle Namedy",[7.3637461, 50.455835]],
    [ "Kuppelstelle Bad Salzig",[7.6408699, 50.2005167]],
    [ "Schaltposten Offenburg",[7.9484818, 48.4654666]],
    [ "10 kV-Station Hochstraße 29a",[7.1410213, 51.4801824]],
    [ "10 kV-Station Gertrudisstraße 27",[7.132464, 51.4827229]],
    [ "10 kV-Station Bochumer Str. 160 A",[7.1669453, 51.4809365]],
    [ "10 kV-Station Stoodtstr. 5A",[7.238248, 51.4633795]],
    [ "10 kV Paulinenstr.5",[7.2415534, 51.462442]],
    [ "10 kV Steinkuhlstraße 35 A",[7.249378, 51.4632394]],
    [ "10 kV-Station Am Spik 21C",[7.2358385, 51.464249]],
    [ "Ks 88",[8.552702, 49.3649076]],
    [ "Umspannwerk Trochtelfingen",[9.2351144, 48.3182974]],
    [ "10 KV Esbecke 1",[7.355126, 51.2971282]],
    [ "10 kV-Station Drusenbergstr. 68",[7.2126391, 51.465132]],
    [ "10 kV-Station Drusenbergstr. 98",[7.2124726, 51.4629215]],
    [ "Altenstadt",[8.9646657, 50.2762013]],
    [ "10 kV-Station 5204",[7.2029308, 51.2712225]],
    [ "Eiga (Alsfeld)",[9.3543019, 50.74947]],
    [ "Sandberg",[7.3526587, 51.3808237]],
    [ "Kaiserstraße",[7.3844982, 51.3896964]],
    [ "4714",[13.4328114, 51.0378694]],
    [ "Umspannwerk Freiberg Nord",[13.2578797, 50.9312045]],
    [ "Groß Dölln",[13.5202191, 53.02516]],
    [ "UW Dargun",[12.8651757, 53.9136043]],
    [ "10 kV -Station Castroper Str. 202-212A",[7.2432637, 51.49056]],
    [ "10 kV Harpener Str. 45",[7.2505823, 51.4862607]],
    [ "10 kV-Station Castroper Str. 202-212",[7.2430683, 51.4907857]],
    [ "10 kV-Station Castroper Str. 235",[7.2468285, 51.4926228]],
    [ "10 kV-Station Am alten Stadtpark 40",[7.2217617, 51.4902296]],
    [ "10 kV-Station Wittener Straße 100",[7.2321107, 51.4761588]],
    [ "Trafoturm Chinesentempel Mahlitzsch",[13.3704477, 51.0784352]],
    [ "Umspannwerk Bruchhof",[7.3548074, 49.3407512]],
    [ "10 kV-Station A. d. Kalwes 151",[7.2799653, 51.4454946]],
    [ "10 kV-Station Konrad-Zuse-Str. 15",[7.2774103, 51.4490082]],
    [ "10 kV-Station Luxemburger Str. 8",[7.2284669, 51.4665636]],
    [ "10 kV-Station Kuhlehof 12",[7.239862, 51.4660605]],
    [ "10 kV-Station Steinring 65",[7.2297823, 51.4708244]],
    [ "10 kV Wasserstr. 215",[7.2256005, 51.4617139]],
    [ "10 kV-Station Glockengarten 60",[7.2401119, 51.4714146]],
    [ "10 kV-Station Wittener Str. 129",[7.2385197, 51.4746924]],
    [ "10 kV-Station Rombacher Hütte 24",[7.1904542, 51.4634948]],
    [ "10 kV-Station Rombacher-Hütte 6",[7.1943191, 51.4642829]],
    [ "Amtsgericht",[7.3936715, 51.3834445]],
    [ "Sieben Quellen",[6.0697239, 51.7898052]],
    [ "Wolfsberg",[6.0551568, 51.7774517]],
    [ "Hamm Sandbochum",[7.6982612, 51.6408329]],
    [ "Remestraße",[7.3857057, 51.3806602]],
    [ "10 KV Museum Kalkriese",[8.1301404, 52.4078341]],
    [ "TS Michael-Vogel-Str. 1",[11.0004402, 49.5891957]],
    [ "Umspannwerk Falkenbach",[13.0514936, 50.6258064]],
    [ "Hofheim",[10.5204173, 50.1305119]],
    [ "Umspannwerk Flughafen BER",[13.5337056, 52.3670838]],
    [ "Station Riechheim 03",[11.1213507, 50.8787451]],
    [ "Station Ehrang",[6.6870077, 49.7890965]],
    [ "10 KV Kollegstraße 2",[7.2807823, 51.4436796]],
    [ "10 KV Wilhelmstraße 1",[7.296806, 51.2873951]],
    [ "10 KV Bahnhofstraße 1",[7.3468395, 51.3205307]],
    [ "ST Simpelvelder Straße",[9.058587, 50.1799988]],
    [ "ST Gelnhäuser Straße",[9.0509667, 50.176631]],
    [ "Höver",[9.8856197, 52.3553496]],
    [ "Brunnenweg",[8.9834658, 50.358208]],
    [ "Angermünde TS Poliklinik",[14.0026428, 53.0151799]],
    [ "Kuppelstelle Welschen-Ennest",[8.0051679, 51.0429505]],
    [ "Friedrichstraße",[7.3908103, 51.3836087]],
    [ "Umspannwerk Wachenhofen",[10.8381005, 49.0564991]],
    [ "Wilhelmshaven Gasturbine",[8.1493201, 53.5636579]],
    [ "Dahlenburg Süd",[10.7894379, 53.1689968]],
    [ "Bad Bevensen",[10.5685642, 53.0738063]],
    [ "10 KV Saarlandring",[7.3474621, 51.2957672]],
    [ "Seckenheimer Straße",[8.4741234, 49.4817173]],
    [ "Tattersall",[8.474624, 49.4815895]],
    [ "Simmerhauser Straße",[8.4879415, 52.9306308]],
    [ "Ostermoor/Ost",[9.2135969, 53.910537]],
    [ "Schaltanlage Forbach",[8.3532593, 48.6692766]],
    [ "10 KV Westenfelder Str.202",[7.1502318, 51.4650462]],
    [ "Wind-UW Fröhden",[13.1799245, 51.975169]],
    [ "Trafoturmstation Seehausen",[11.1259776, 51.3323558]],
    [ "Norder Gath",[8.8459583, 54.7926623]],
    [ "Windmühlstraße",[8.4852041, 49.4751042]],
    [ "Geldersheim",[10.1404195, 50.0460762]],
    [ "Gerhard-Marcks-Straße 13a",[8.5053625, 49.4680571]],
    [ "Weyhe",[8.8270257, 53.0034829]],
    [ "Umspannst. Weberstr",[9.3391945, 48.6383329]],
    [ "Umspannwerk Skandinavienkai",[10.8466973, 53.9341464]],
    [ "Theodor-Heuss-Straße",[7.3927546, 51.386179]],
    [ "10 KV Metzer Straße 1",[7.2845962, 51.2934592]],
    [ "10 KV Metzer Straße 2",[7.2805066, 51.2925707]],
    [ "10 KV Talstraße",[7.2860299, 51.2929618]],
    [ "Schaltanlage 30 kV",[8.7854411, 51.6099793]],
    [ "Umspannwerk Zwickau West",[12.4521364, 50.7176878]],
    [ "Kuppelstelle Lemförde",[8.3535671, 52.4525728]],
    [ "Umrichterwerk Mannheim",[8.5037195, 49.445107]],
    [ "Kraftwerk Pfrombach",[11.9915905, 48.441375]],
    [ "Kraftwerk Aufkirchen",[11.8593915, 48.305482]],
    [ "Kraftwerk Eitting",[11.8818604, 48.3587432]],
    [ "Schaltposten Neukirietzsch",[12.4157728, 51.1453789]],
    [ "Schaltposten Erfurt",[11.076844, 50.9796034]],
    [ "Schaltposten Halle-Ammendorf",[11.9883822, 51.4351522]],
    [ "Umspannwerk Pfahlbronn",[9.656042, 48.8430152]],
    [ "Schaltanlage Sterup",[9.7436272, 54.712139]],
    [ "Wasserturm",[9.7323404, 52.4145381]],
    [ "Grone",[9.9053335, 51.5345823]],
    [ "Grundschötteler Straße 1",[7.3696832, 51.3742619]],
    [ "Rönnebeck",[8.5511853, 53.1923228]],
    [ "Umspannwerk Leimen",[8.6781561, 49.3495037]],
    [ "10-kV-Station Obere Lichtenplatzer Straße 301",[7.1926297, 51.2489706]],
    [ "UW Burg",[10.0924223, 52.5875381]],
    [ "10 KV Grundschule Fettweide",[7.3687075, 51.3027651]],
    [ "Schaltstation",[11.9468086, 52.9578956]],
    [ "Bullenmast",[13.481722, 51.0299088]],
    [ "TS Werker 6",[10.9980284, 49.609097]],
    [ "Schrepkow Nord",[12.091141, 52.9685347]],
    [ "Kränzlin Nord",[12.7176331, 52.9316852]],
    [ "Umrichterwerk Frankfurt (Oder)-Rosengarten",[14.479342, 52.3397635]],
    [ "Umspannstation Güstrow Umweltpark",[12.2068691, 53.7936445]],
    [ "Altentreptow Mitte",[13.2375246, 53.6815184]],
    [ "Teetzleben",[13.1906642, 53.6486671]],
    [ "Altentreptow Wind",[13.2377008, 53.6811036]],
    [ "Altentreptow Thalberg",[13.237301, 53.6814092]],
    [ "Zirzow",[13.2073113, 53.5725901]],
    [ "Umspannwerk Neubrandenburg",[13.2801186, 53.5705691]],
    [ "Gramelow",[13.3518947, 53.4366004]],
    [ "Woldegk Nord",[13.5678829, 53.4683625]],
    [ "Fürstenberg",[13.1326444, 53.2110353]],
    [ "Wind-UW Löcknitz Süd",[14.1907638, 53.4463743]],
    [ "Ribnitz Süd",[12.4077622, 54.2378619]],
    [ "Umspannwerk Siedenbrünzow",[13.1407997, 53.9085388]],
    [ "Umspannwerk Falkenhagen",[13.2250593, 54.2077003]],
    [ "Boltenhagen",[13.0541454, 54.0753613]],
    [ "Steuerwald",[9.91924, 52.1714448]],
    [ "Gronau",[9.7929329, 52.0900166]],
    [ "Pennigsehl",[9.022597, 52.6286121]],
    [ "Umspannwerk Gaisburg",[9.2219021, 48.788392]],
    [ "Buchholz",[9.8537967, 52.4116063]],
    [ "10 KV Helkenberger Weg SK",[7.3894977, 51.3026011]],
    [ "Holtriem Nord",[7.4773506, 53.5772961]],
    [ "Holtriem",[7.4775562, 53.576943]],
    [ "Hackfahrel",[8.5953565, 53.5010143]],
    [ "Umspanner",[6.7632668, 49.1920211]],
    [ "Wind-UW Stiftswald",[9.6469288, 51.2522021]],
    [ "10 KV Hauptstraße 16",[6.9846885, 51.3294117]],
    [ "10 KV Höhne neb. 102",[7.2062005, 51.2718774]],
    [ "Umspannwerk Seehafen",[12.1302231, 54.1479125]],
    [ "Bacharach",[7.6910475, 50.0255325]],
    [ "Umspannst. Benz Straße",[9.0928675, 48.6106292]],
    [ "Umspannst.",[9.0925658, 48.6126145]],
    [ "Neuhermsheim Gartenanlage",[8.5089179, 49.4702492]],
    [ "10 kV-Station Breslauer Str. 12",[7.186022, 51.4989242]],
    [ "Autotransformatorstation Anklam",[13.7096779, 53.8512301]],
    [ "Autotransformatorstation Guest",[13.4392324, 54.0482519]],
    [ "Autotransformatorstation Jatznick",[13.9371883, 53.6038713]],
    [ "Autotransformatorstation Prenzlau",[13.8712572, 53.3367194]],
    [ "Autotransformatorstation Stralsund",[13.0938836, 54.2857614]],
    [ "Schaltposten Escherde",[9.8458252, 52.1681687]],
    [ "S-Bahn Gleichrichterunterwerk Bellevue",[13.3494446, 52.5199051]],
    [ "Abnehmeranlage West",[13.2834679, 52.4979824]],
    [ "Kuppelstelle Bensheim",[8.6210133, 49.6696728]],
    [ "Kuppelstelle Obereichstätt",[11.1335888, 48.8866646]],
    [ "Schaltposten Bretten",[8.6836363, 49.0360712]],
    [ "Golm",[12.9731486, 52.4209305]],
    [ "Umformwerk Cottbus",[14.2860191, 51.7510351]],
    [ "Umspannwerk Kostheim",[8.3252922, 50.0249723]],
    [ "Kuppelstelle Angern-Rogätz",[11.7511192, 52.3287495]],
    [ "Kuppelstelle Küps",[11.2774141, 50.2010336]],
    [ "Schaltposten Bitterfeld",[12.3164924, 51.6187005]],
    [ "Schaltposten München Flughafen",[11.7366929, 48.3495442]],
    [ "Schaltposten Mering",[10.9912602, 48.2612494]],
    [ "Schaltposten Zorneding",[11.8302866, 48.0897665]],
    [ "Schaltposten Ludwigslust",[11.4829623, 53.3404195]],
    [ "Schaltposten Neubrandenburg",[13.2697747, 53.5612588]],
    [ "10 KV",[7.5989119, 47.5993274]],
    [ "Umspannwerk Graben",[8.5029503, 49.15471]],
    [ "10 kV-Station Suntumer Straße 53A",[7.2747516, 51.4726679]],
    [ "10 kV-Station Bergstr. 10",[7.221215, 51.4844104]],
    [ "10 kV-Station Zeppelinstr. 2",[7.221554, 51.4858612]],
    [ "Umspannwerk Kuhsdorf / Kuhsdorf Süd",[12.1157467, 53.1143791]],
    [ "UW Lampertswalde",[13.6684985, 51.3007148]],
    [ "Umspannwerk Sauerlach",[11.6686708, 47.973383]],
    [ "Umspannstation Ebertsbronn",[9.9474152, 49.4335241]],
    [ "Weinbergstrasse",[7.8835443, 48.3764457]],
    [ "Diersburgerstrasse",[7.9010955, 48.3982594]],
    [ "Moorrege",[9.6569332, 53.6638675]],
    [ "Envian",[11.6741794, 51.1047712]],
    [ "Jahnstraße",[11.5980781, 50.690732]],
    [ "Station Weißenthurm",[7.4453816, 50.4029899]],
    [ "Trenter Berg",[10.3583209, 54.194109]],
    [ "Schaltstation Gotha-Ost",[10.7177524, 50.9579185]],
    [ "Industriegebiet",[11.0306832, 48.9223042]],
    [ "Saßleben",[13.9766934, 51.7604741]],
    [ "Marktplatz",[7.8720931, 48.3412141]],
    [ "E",[12.0796825, 51.4603862]],
    [ "Jessen Nord (Schweinitz) 380 kV",[13.0583153, 51.7984116]],
    [ "Deiderode",[9.8767007, 51.4332405]],
    [ "Vollstedt",[9.062569, 54.6274413]],
    [ "UW Penkun",[14.2387838, 53.2744377]],
    [ "35kV Übergabestation",[7.0716719, 49.289254]],
    [ "Bremervörde 1 Zevener Straße",[9.1590084, 53.4814168]],
    [ "Weber",[7.9065464, 48.3370266]],
    [ "10 KV Heinz",[7.273257, 51.3180812]],
    [ "10 KV Müller & Sohn 1",[7.2825112, 51.320513]],
    [ "10 kV-Station Herzogstr. 18",[7.2053781, 51.5116181]],
    [ "10 kV-Station Verkehrsstr. 26 A",[7.2069579, 51.5060273]],
    [ "10 kV Dorstener Str. 400",[7.1902612, 51.5065004]],
    [ "10 KV Dorstener Str. 420 A",[7.1901657, 51.5089646]],
    [ "10 KV Dorstener Str. 425",[7.1895503, 51.5087883]],
    [ "10 kV-Station Klinikstr. 47",[7.2279441, 51.489766]],
    [ "10 KV Riemker Str. 77",[7.2006972, 51.5081439]],
    [ "Umspannwerk Winterhude",[10.0028591, 53.5903161]],
    [ "Spreewaldallee",[8.5530772, 49.5198291]],
    [ "HUE_120",[8.5839547, 49.5929157]],
    [ "UW Grevenkop",[9.5420676, 53.849702]],
    [ "Umspannwerk Altlußheim",[8.5112117, 49.294308]],
    [ "Bennewitz Ort",[12.1000718, 51.4389435]],
    [ "Station 1",[10.6100331, 49.0951435]],
    [ "10 KV Windmühlenstraße",[7.4628558, 51.263461]],
    [ "10 KV Westenfelder Straße130",[7.1448254, 51.4724249]],
    [ "Trafo Nischwitz 01",[12.2713276, 50.8223419]],
    [ "Heinersdorf",[14.133453, 52.4439397]],
    [ "Umaspannst. Froschegert",[9.2521783, 48.633146]],
    [ "Ortsstraße",[8.5763402, 49.5126863]],
    [ "Schaltanlage Geckenheim",[10.2383498, 49.5584624]],
    [ "HUE_106",[8.5825353, 49.595348]],
    [ "HUE_122",[8.5652832, 49.599921]],
    [ "HUE_119",[8.5892431, 49.5941293]],
    [ "HUE_114",[8.5853865, 49.5950554]],
    [ "HUE_118",[8.584386, 49.6021541]],
    [ "HUE_101",[8.5823954, 49.5992619]],
    [ "HUE_105",[8.5810606, 49.5982197]],
    [ "HUE_112",[8.5818596, 49.5939585]],
    [ "HUE_117",[8.5838657, 49.5997593]],
    [ "HUE_111",[8.5839114, 49.599758]],
    [ "HUE_116",[8.5652496, 49.5998152]],
    [ "Umspannwerk Uhrigstraße",[13.5120913, 52.5029582]],
    [ "HUE_115",[8.5855986, 49.5977748]],
    [ "HUE_109",[8.5883066, 49.5980607]],
    [ "HUE_107",[8.5819701, 49.6003219]],
    [ "HUE_???",[8.5811618, 49.5918898]],
    [ "HUE_100",[8.5878369, 49.5928506]],
    [ "Wiesloch/Breslauer Straße",[8.6765246, 49.2926459]],
    [ "Zellhäuser Reichenbach",[13.2489523, 50.9939598]],
    [ "10 KV Achtmeyer",[7.373702, 51.3313867]],
    [ "10 kV Am Sinnerhoop",[7.3926256, 51.3356071]],
    [ "10 kV",[7.3891028, 51.3350939]],
    [ "Hörbering",[12.5679038, 48.388755]],
    [ "TrSt BBS",[13.3766607, 54.078742]],
    [ "TrSt Gorzberg 2",[13.3900144, 54.0742372]],
    [ "TrSt Hufen 1",[13.3793648, 54.0730023]],
    [ "TrSt WW Heine Straße",[13.3632433, 54.0788603]],
    [ "TrSt Zur Schranke",[13.3812925, 54.0862741]],
    [ "Eschwege",[9.9995529, 51.2028813]],
    [ "Zell",[7.8472231, 47.6997675]],
    [ "10 KV EKZ Breckerfeld",[7.4643849, 51.264041]],
    [ "Im Schwaderloch",[7.9241254, 48.4403687]],
    [ "10 KV Königsfeld",[7.3243114, 51.2632167]],
    [ "Trafostation Weichenheizung",[9.0760201, 48.8916689]],
    [ "Umspannwerk Feldheim",[12.8400026, 52.0016209]],
    [ "Galgenbergweg",[7.8575118, 48.333621]],
    [ "Bergtheim",[10.0704589, 49.9098747]],
    [ "Bodelschwinghstraße",[7.8803682, 48.344236]],
    [ "Hohbergweg",[7.892462, 48.3390886]],
    [ "Lahrer Strasse",[7.8610205, 48.31126]],
    [ "Magdeburg/Süd",[11.6440443, 52.1006332]],
    [ "TrSt Gorzberg 3",[13.3834489, 54.0760583]],
    [ "TrSt Alt Ungnade",[13.2931083, 54.0718208]],
    [ "TrSt C / 7",[13.4169694, 54.0827042]],
    [ "TrSt D/61",[13.416729, 54.0839229]],
    [ "TrSt KfL",[13.3929237, 54.0990837]],
    [ "Dieksanderkoog",[8.9331469, 53.9751801]],
    [ "Station Oer",[7.2110132, 51.6546788]],
    [ "10 KV",[7.1720482, 51.4001214]],
    [ "10 KV Liese",[7.2985702, 51.2894818]],
    [ "10 KV Markgrafenstraße 2",[7.2985911, 51.2898798]],
    [ "10 KV",[7.2970516, 51.2904044]],
    [ "HUE_123",[8.587581, 49.5978714]],
    [ "Ottenhöfen Hasenwald",[8.1530637, 48.5633873]],
    [ "Umspannwerk Böblingen-Ost",[9.0421025, 48.6866933]],
    [ "10 kV-Station Klosterstr. 30a",[7.2098366, 51.4852566]],
    [ "Umspannstation Beyernaumburg",[11.4011325, 51.4676556]],
    [ "Umspannstation",[11.7161575, 51.5883337]],
    [ "Umspannwerk Elfmorgenbruch",[8.4525559, 49.0069386]],
    [ "Grevesmühlen",[11.1692629, 53.8645859]],
    [ "10 kV-Station Gußstahlstr. 23 A",[7.2114488, 51.4817039]],
    [ "Schöllkrippen 14",[9.2506352, 50.0846527]],
    [ "Schaltposten Maschen",[10.0881113, 53.3920433]],
    [ "Wassertrüdingen 02",[10.5950801, 49.0398925]],
    [ "Station Ringen",[7.0957441, 50.5822305]],
    [ "Kuppelstelle Besseringen",[6.6052978, 49.4747644]],
    [ "Kuppelstelle Treis-Karden",[7.3035143, 50.1849442]],
    [ "Kuppelstelle Kattenvenne",[7.851542, 52.10108]],
    [ "Unterwerk Roth",[11.0375667, 50.3824558]],
    [ "Siesel",[7.9034784, 51.2244712]],
    [ "Umspannwerk Pulgar",[12.334785, 51.19705]],
    [ "Wasserwerk Friedrichshagen, Station F02",[13.6486777, 52.4240237]],
    [ "Moitzfeld",[7.1808908, 50.966118]],
    [ "Umspannstation",[11.4848746, 51.7754826]],
    [ "Umspannwerk Aschersleben Nord",[11.4845798, 51.7747331]],
    [ "10 KV Karl-Schwesig-Straße",[7.0518259, 51.5886479]],
    [ "SWEG",[7.8319412, 48.3452104]],
    [ "Umspannwerk Handewitt",[9.3195444, 54.7281622]],
    [ "Station Schmiedebach PW Rehbach",[11.4514116, 50.4964794]],
    [ "Schaltposten Ehrang",[6.6849327, 49.7984617]],
    [ "10 kV",[7.4294615, 51.3352329]],
    [ "10 KV",[7.4431168, 51.3487883]],
    [ "Konrad-Wolf-Allee",[13.136571, 52.3667174]],
    [ "10 KV Allermann",[7.2726622, 51.2857468]],
    [ "Schaltposten Köln",[6.9520643, 50.9505148]],
    [ "Sandershausen/Ost",[9.5841604, 51.3309518]],
    [ "110kV-Umspannwerk Östringen",[8.6885229, 49.2090392]],
    [ "Umspannwerk Wittstock Solar",[12.4723814, 53.1753002]],
    [ "Umspannwerk Lubmin",[13.6820591, 54.1400361]],
    [ "Dillfeld",[8.4864328, 50.5730295]],
    [ "Großenehrich Wind",[10.8362426, 51.2375463]],
    [ "Umspannwerk Mannheimer Straße",[13.3157115, 52.4868473]],
    [ "Umspannwerk Britz",[13.4331357, 52.440735]],
    [ "25 kV",[6.2185739, 51.3791988]],
    [ "Bad Schwartau Nieland",[10.6678407, 53.9219744]],
    [ "Pflegehaus",[7.3908856, 51.3895782]],
    [ "Pastor's Ufer",[7.3886975, 51.385764]],
    [ "Trafo HKWD 1;MDh 4178;Dorfstraße 31a",[12.2685453, 50.8374752]],
    [ "Trafo HKWD 2;MDh 4204;Dorfstraße 9",[12.2715216, 50.8421323]],
    [ "Trafo 2 - Biogasanlage Heukewalde 2",[12.2624256, 50.8363605]],
    [ "Trafo 1 - Biogasanlage Heukewalde 2",[12.2673219, 50.8349186]],
    [ "Trafo WEA Agrar Thonhausen - Rinderställe Heukewalde",[12.2844454, 50.843607]],
    [ "Trafo Jonaswalde 01",[12.2871492, 50.8393863]],
    [ "10 kV-Station Wittkampstr. 61",[7.3336939, 51.4747632]],
    [ "Wengen",[10.1480334, 47.6755632]],
    [ "Hans-Thoma-Straße 95",[8.5412894, 49.4731415]],
    [ "Umspannwerk Stromstraße",[13.3429831, 52.5287788]],
    [ "Teichstraße",[11.0308214, 51.0042529]],
    [ "10 kV Marmelshagen 59a",[7.1908559, 51.5013352]],
    [ "Clara-Zetkin-Straße",[11.0433445, 50.9726334]],
    [ "10 kV-Station 5988",[7.2560209, 51.4692981]],
    [ "c",[7.2613898, 51.4703359]],
    [ "UW PV-Park Perleberg",[11.8364213, 53.0856273]],
    [ "Utersum",[8.4059492, 54.712162]],
    [ "Huckelheim 2",[9.240408, 50.1286251]],
    [ "Umspannstation",[12.2435296, 51.7126732]],
    [ "Metzdof Nord",[14.1736724, 52.6494749]],
    [ "Station 1",[10.6999359, 49.2028217]],
    [ "KW Lünen (UW)",[7.4778471, 51.6154221]],
    [ "UW Kaldorf",[11.1554301, 48.9891607]],
    [ "Umspannwerk Charlottenburg",[13.3071092, 52.5231866]],
    [ "Umspannwerk Bergrheinfeld/West",[10.1505481, 49.9794458]],
    [ "Kleinschwabhausen",[11.3647238, 48.407338]],
    [ "Fahrlachstraße 14a",[8.4950475, 49.4706897]],
    [ "Unterwerk Neckarelz (neu)",[9.1143657, 49.338934]],
    [ "S-Bahn Gleichrichterunterwerk Marzahn",[13.5565217, 52.5621861]],
    [ "Henstedt Ulzburg Jahnstraße 35A",[9.9882864, 53.7981832]],
    [ "Umspannwerk Senftenberg",[14.0076772, 51.532253]],
    [ "10 kV-Station Lorenz-Rebbert-Allee 2",[7.2267493, 51.4859887]],
    [ "10 KV Heidestr. 2",[7.1662343, 51.5328253]],
    [ "10 KV",[7.1666944, 51.5329567]],
    [ "Umspannwerk Appelhülsen",[7.4306416, 51.8916994]],
    [ "Strandbad",[7.9410913, 48.3036237]],
    [ "Umspannwerk Blöße",[8.4491514, 49.0246362]],
    [ "Umspannwerk Kastorf",[10.5581633, 53.7550533]],
    [ "Umspannwerk Waldweiler",[6.7996255, 49.6192942]],
    [ "Umspannwerk Krüzen",[10.5233379, 53.4096708]],
    [ "QABCH-NN02",[10.6242043, 53.4756317]],
    [ "Umspannwerk Fürstenzell",[13.3502085, 48.5319288]],
    [ "10 kV-Station Alte Wittener Str. 38A",[7.2707262, 51.4686519]],
    [ "Umspannwerk Altentreptow Nord",[13.3189717, 53.7356303]],
    [ "Oberpörlitz 10 Otto-Hahn-Straße",[10.9164517, 50.6958432]],
    [ "Umspannwerk Parchim Süd",[11.8980963, 53.4065058]],
    [ "Umspannwerk Gransee",[13.261832, 52.9950362]],
    [ "Melsungen/Wind",[9.512266, 51.1291852]],
    [ "Umspannwerk Lichtenau",[8.8394113, 51.5805025]],
    [ "Umspannwerk Helmern",[8.8073603, 51.5482213]],
    [ "NordLink HVDC Wilster",[9.3422627, 53.9192651]],
    [ "Umspannwerk Wilster",[9.3423446, 53.9243061]],
    [ "10-kV-Station Boland",[8.252088, 51.885079]],
    [ "Wind-UW Schlalach",[12.822396, 52.1532752]],
    [ "Umspannanlage Zülpich",[6.7805279, 50.7068464]],
    [ "Bertholdbrunnen",[7.8718289, 48.344945]],
    [ "Oberer Garten",[7.8791502, 48.3491107]],
    [ "Vilbeln",[8.7416357, 50.1964317]],
    [ "Krankenhaus alt",[7.8851414, 48.3432347]],
    [ "Umspannstation Radegast",[12.0850202, 51.6590943]],
    [ "Beerwalde",[12.2184117, 50.8754496]],
    [ "Gasregler Lichtenau",[8.9062781, 51.617674]],
    [ "UW Keltenweg",[6.6622832, 49.7656412]],
    [ "UW Husum Nord",[9.0778636, 54.5086722]],
    [ "10 kV-Station Bergstraße 25",[7.2210545, 51.4854082]],
    [ "Unterwerk Eichenberg",[9.9240936, 51.3752735]],
    [ "Kormoranstraße 11",[8.5466481, 49.501204]],
    [ "Schimborn 61",[9.1930348, 50.0516689]],
    [ "Bertholdstraße",[7.8721181, 48.3469603]],
    [ "Merzengasse",[7.8863639, 48.3468973]],
    [ "Trafostation KME",[8.0651146, 52.2835987]],
    [ "Wachenburgstraße 172",[8.5295424, 49.4459374]],
    [ "Umspannwerk Lübben",[13.8695586, 51.948494]],
    [ "Atzenhain",[8.9865893, 50.6499992]],
    [ "Konverter Station Emden Ost",[7.2390965, 53.3546351]],
    [ "Umspannwerk Obersteinach",[9.8666775, 49.2014911]],
    [ "Altfunnixsiel",[7.7735819, 53.6576436]],
    [ "10 KV Präsidentenstraße",[7.284937, 51.2812595]],
    [ "Station Riechheim 04",[11.1180405, 50.8795198]],
    [ "Station Riechheim 06",[11.1252042, 50.8803479]],
    [ "10 KV Döppersberg 37",[7.1481526, 51.2544432]],
    [ "Uckley",[13.7411985, 52.3366932]],
    [ "10 KV Commerzbank",[7.3378533, 51.3204859]],
    [ "10 KV Goethestr.",[7.3366738, 51.3205647]],
    [ "Umspannwerk Hullern",[7.3187657, 51.7342915]],
    [ "10 kV-Station Dorstener Str. 209",[7.1917246, 51.4930136]],
    [ "Spree",[12.768205, 50.8634711]],
    [ "Umspannwerk Schkeuditz",[12.2108496, 51.398346]],
    [ "Schwittersum Süd",[7.4755899, 53.6501763]],
    [ "Alt Langenwinkel",[7.8108334, 48.3502329]],
    [ "10 kV-Station Wattenscheider Hellweg 163-165",[7.1441764, 51.4599893]],
    [ "Station Osthausen 05",[11.1052639, 50.8539859]],
    [ "10 kV-Station Schleiermacherstr. 1",[7.1723079, 51.4514877]],
    [ "Umspannstation Salzfurtkapelle Ost",[12.2150164, 51.6977011]],
    [ "Station Holsterfeld",[7.4202358, 52.3293394]],
    [ "10 kV-Station Kronenstraße 1B",[7.2163004, 51.4748248]],
    [ "Herzebrock-West",[8.2323673, 51.8846811]],
    [ "Ebenheim",[10.4986441, 50.9698059]],
    [ "10 kV-Station Marienplatz 1A",[7.2143918, 51.4777766]],
    [ "Borsbach 01",[10.5262803, 49.3630523]],
    [ "Umspannwerk",[8.7451477, 54.8140382]],
    [ "Krämer",[7.9394451, 48.3125722]],
    [ "Möhlstraße",[8.4848441, 49.4723597]],
    [ "Möhlstraße",[8.4849639, 49.4725009]],
    [ "Relaisstraße 159",[8.5318977, 49.4277831]],
    [ "Umspannanlage Lambsheim",[8.2928709, 49.5239228]],
    [ "Gera/Nord",[12.0618126, 50.9051935]],
    [ "Dynamowerk Berlin",[13.2594701, 52.5364385]],
    [ "Umspannwerk Heide-West",[9.0529275, 54.1645414]],
    [ "Umspannwerk Prettelshofen",[10.7382208, 48.5505707]],
    [ "GETEC/Im Götzmann",[7.848897, 48.3343968]],
    [ "10 kV-Station Deimektal 50",[7.1792458, 51.4260131]],
    [ "10 kV-Station Ginsterweg 25",[7.1479371, 51.4547605]],
    [ "10 kV-Station Horneburg 45",[7.149563, 51.4575034]],
    [ "10 kV-Station Essener Str. 203",[7.1705614, 51.4714979]],
    [ "Umspannwerk Bad Soden",[8.4935996, 50.1317536]],
    [ "Umspannwerk Steinau",[9.4756864, 50.308648]],
    [ "10 KV Am Kuhlenkamp 27",[7.2009753, 51.4462569]],
    [ "10 kV-Station Flottmannstr. 42",[7.2185347, 51.5194741]],
    [ "Umspannwerk Thielenstraße",[8.6551794, 50.1574736]],
    [ "Umspannwerk Hohenstaufenstraße",[8.6575331, 50.1101611]],
    [ "Umspannwerk Marbachweg",[8.7017401, 50.1457176]],
    [ "Umspannwerk Pödelwitz",[12.3507031, 51.1403191]],
    [ "UW Harsleben Süd",[11.0954177, 51.8510297]],
    [ "10 kV-Station Herner Str. 249",[7.21187, 51.5012175]],
    [ "Umspannwerk Salzwerkplatz",[9.2155117, 49.1636653]],
    [ "UW Großengottern",[10.5548134, 51.1527083]],
    [ "Umspannwerk Hanauer Landstraße",[8.7555744, 50.1275164]],
    [ "Umspannwerk Gräfstraße",[8.6523643, 50.1218417]],
    [ "Umspannwerk Heideplatz",[8.6989384, 50.1229439]],
    [ "Umspannwerk Lübecker Straße",[8.672187, 50.1294509]],
    [ "Umspannwerk Darmstädter Landstraße",[8.6912953, 50.0986639]],
    [ "Umspannwerk Froschhäuser Straße",[8.5947311, 50.0989854]],
    [ "Frankfurt/Nordwest",[8.6608881, 50.1940919]],
    [ "Umspannwerk Kleyerstraße",[8.6265504, 50.0954003]],
    [ "Umspannwerk Ackermannstraße",[8.6266415, 50.0990165]],
    [ "Umspannwerk Kruppstraße",[8.7413889, 50.140765]],
    [ "Umspannwerk Am Dammgraben",[8.640688, 50.1112162]],
    [ "UW Cremzow",[14.0304189, 53.3482462]],
    [ "Elisenau",[13.6604816, 52.5975845]],
    [ "Pomßen Kieswerk",[12.6104124, 51.2304854]],
    [ "Station Anrath",[6.4501528, 51.2860632]],
    [ "elektrizitätswerk Rheinhessen",[8.3476278, 49.6959224]],
    [ "Iserlohn",[7.6955904, 51.382055]],
    [ "TST Max-Herm-Straße",[12.5213407, 52.4330715]],
    [ "Station 2",[10.6120676, 49.2725083]],
    [ "10 kV Brenscheder Str. 51A",[7.2319667, 51.4515586]],
    [ "10 KV Grimbergst. 16",[7.114976, 51.5411774]],
    [ "Umspannwerk Klein Toitin",[13.3680978, 53.9054087]],
    [ "Umspannwerk Panzow",[11.6746841, 53.9993144]],
    [ "Umspannwerk Brodersdorf",[12.2256781, 54.1008513]],
    [ "Schaltpunkt Rietz",[12.7900825, 52.0800255]],
    [ "Treuenbrietzen Nord",[12.8446107, 52.1002815]],
    [ "UW Milow",[11.6290282, 53.2033185]],
    [ "10-kV-Station 8620",[7.2809358, 51.2339153]],
    [ "Atteln/Henglarn",[8.7855342, 51.6101004]],
    [ "10 kV-Station Am Koppstück 1A",[7.2850727, 51.4803594]],
    [ "Jacobsdorf",[14.3575715, 52.3455806]],
    [ "Sommersdorf",[11.1309601, 52.176148]],
    [ "Vesahn",[8.2062826, 51.887417]],
    [ "Fedderwarden",[8.0614371, 53.5542595]],
    [ "Kerkow",[14.0328019, 53.0390343]],
    [ "Umspannwerk Heiligengrabe",[12.3724477, 53.1526565]],
    [ "Sadenbeck",[12.2348578, 53.194559]],
    [ "Falkenhagen Nord",[12.2336609, 53.1996515]],
    [ "Falkenhagen Süd",[12.2297089, 53.1997227]],
    [ "Stat.25 Sperbersloherstr.",[11.1698043, 49.3438224]],
    [ "10 kV-Station Coloniastr. 13",[7.2993133, 51.4758886]],
    [ "UW Petkus",[13.3403151, 51.9871016]],
    [ "UW Haby",[9.8271935, 54.4081674]],
    [ "Litten",[14.4979138, 51.1802351]],
    [ "10 kV-Station Am Einkaufszentrum Q",[7.2840896, 51.4949869]],
    [ "10 kV-Station Am Einkaufszentrum A",[7.2835517, 51.494094]],
    [ "10 kV-Station Am Einkaufszentrum D",[7.2835899, 51.494142]],
    [ "Umspannwerk Weißenfels",[11.968305, 51.2209597]],
    [ "Hafenstraße",[9.3602083, 52.0978291]],
    [ "10 kV-Station Gahlensche Str. 76a",[7.194571, 51.4858939]],
    [ "10 kV-Station Lohbergstr. 1",[7.2330457, 51.4784992]],
    [ "UW Langacker",[7.5946661, 49.466217]],
    [ "Umspannwerk Rähnitz",[13.7335248, 51.1259345]],
    [ "Bermersbach Rote Lache",[8.3218494, 48.7062908]],
    [ "Gamshurst Michelbuch 3",[8.0266178, 48.6787499]],
    [ "10 kV-Station Speicherstr. 2",[7.2050311, 51.4989674]],
    [ "Horrackerstraße",[8.6645312, 49.5328575]],
    [ "10 kV-Station Unterstr. 91A",[7.3088792, 51.4706036]],
    [ "10 KV Klophaus",[7.2702631, 51.2855969]],
    [ "Umspannwerk Neumark",[12.3524207, 50.6656375]],
    [ "Station 9",[10.6065737, 49.0407788]],
    [ "Umspannstation Quering",[12.284821, 51.5065352]],
    [ "Sendenhorst",[7.8458376, 51.8412885]],
    [ "Schalthaus Isselhorst",[8.4145759, 51.9593584]],
    [ "Umspannwerk Klettwitz",[13.9108228, 51.5346402]],
    [ "Breisgaustrasse",[7.8442232, 48.3331886]],
    [ "Schalthaus Ansbach West",[10.5572632, 49.3013533]],
    [ "Uthlede",[8.5828804, 53.3234833]],
    [ "Umspannanlage Sandkaule",[7.1030316, 50.7387211]],
    [ "Stockach 110kV",[9.0132507, 47.8451393]],
    [ "Kirschbaumallee",[7.8862377, 48.3483859]],
    [ "Padberg",[7.8883371, 48.3399917]],
    [ "Wasserklamm",[7.8646888, 48.3458954]],
    [ "Stadtpark",[7.858839, 48.3425854]],
    [ "Umspannwerk Schinne Nord",[11.7078107, 52.674229]],
    [ "Umspannwerk Schipkau",[13.907269, 51.5049814]],
    [ "10 kV-Station Große Beckstr. 12A",[7.2200029, 51.482702]],
    [ "10 kV-Station Bleichstraße 15A",[7.2223141, 51.4829334]],
    [ "10-kv-Station Pagenkamp",[8.2482987, 51.883792]],
    [ "110kV-Umspannwerk Oberderdingen",[8.7940175, 49.0717121]],
    [ "UW Woringen",[10.2159456, 47.9380719]],
    [ "Klein Briesen",[12.5133498, 52.2388485]],
    [ "UW Nord",[6.9716108, 50.9781009]],
    [ "10 kV-Station Bessemerstr. 107A",[7.212163, 51.4717439]],
    [ "Taylorring",[8.5518656, 49.5157246]],
    [ "10 kV-Station Blumfeldstr. 179",[7.169026, 51.444158]],
    [ "Twielfeld",[8.8193144, 47.7564379]],
    [ "Schanz",[8.8218369, 47.7579986]],
    [ "Gleichstrom-Umspannwerk Philippsburg",[8.4395278, 49.2533483]],
    [ "TS Michael-Vogel-Str. 5",[10.9999365, 49.5880906]],
    [ "10 kV-Station Schützenstr. 251A",[7.1777359, 51.4585399]],
    [ "Eisenbahnstraße AMB",[8.962847, 48.5332955]],
    [ "Umspannwerk Mitte",[8.4056801, 49.0033859]],
    [ "Umspannwerk Halle Zentrum",[11.9198181, 51.478235]],
    [ "Umspannwerk Blankenloch",[8.482708, 49.0696889]],
    [ "Erzhausen",[9.9237392, 51.8971979]],
    [ "Station 7",[10.5470374, 49.1642226]],
    [ "10-kV-Station Am Homberg",[7.1692995, 51.3850816]],
    [ "10-kV-Station Otto-Hue-Straße",[7.176475, 51.3872109]],
    [ "10 KV Heilenbecker Talsperre",[7.3706467, 51.2579783]],
    [ "10 kV-Station 8954",[7.1972377, 51.4447446]],
    [ "Wind-UW Kletzin",[13.1439527, 53.9145683]],
    [ "Umspannstation",[12.2797678, 53.7641592]],
    [ "Umspannwerk Geschendorf",[10.4698359, 53.922955]],
    [ "Umspannwerk Konstanz-Weiherhof",[9.1608896, 47.6759753]],
    [ "Umspannwerk Tellschütz",[12.2470566, 51.1982894]],
    [ "Umspannstation Lippendorf Block R",[12.373281, 51.1812264]],
    [ "Umspannstation Lippendorf Block S",[12.3742225, 51.1812231]],
    [ "Viehhofstraße 23",[8.4848224, 49.4741197]],
    [ "Nußloch Bortkelterstraße",[8.7042576, 49.3222149]],
    [ "Umspannwerk",[12.2981328, 51.6370126]],
    [ "10 kV-Station Hugo-Schultz-Str. 67A",[7.2135208, 51.4694933]],
    [ "Umspannwerk Halle Stadtpark",[11.9802199, 51.4820893]],
    [ "US Haßlinghausen",[7.271915, 51.3288761]],
    [ "10 kV-Station Heintzmannsh. 32",[7.2214576, 51.4407561]],
    [ "10 kV-Station Vollbruchstr. 1",[7.20466, 51.4898987]],
    [ "10 kV-Station Kemnader Str. 302",[7.2408414, 51.4282878]],
    [ "Hansastrasse",[7.8659089, 48.3392308]],
    [ "Umrichterwerk Leutkirch",[9.997446, 47.8221536]],
    [ "Brandenburg Nord",[12.5371653, 52.4282391]],
    [ "Umspannstation Genthin Ost",[12.1335421, 52.4310574]],
    [ "Treuenbrietzen/Feldheim",[12.8846125, 52.0934532]],
    [ "Nahrstedt Nord",[11.729816, 52.5692148]],
    [ "Nahrstedt Süd",[11.7303282, 52.5693365]],
    [ "Börger",[7.5797712, 52.9151658]],
    [ "Börger Nord",[7.5833792, 52.9269403]],
    [ "Langwedel Wasserkraftwerk",[9.1525313, 52.9626828]],
    [ "Gilmerdingen",[9.7293359, 53.051662]],
    [ "Uelzen",[10.5511565, 52.971466]],
    [ "Abbenfleth-Giesserei",[9.4909388, 53.6592936]],
    [ "Altenbruch Süd",[8.7371105, 53.818867]],
    [ "Buecken",[9.0944972, 52.77375]],
    [ "Solschen",[10.1257171, 52.2659283]],
    [ "Ahrensmoor",[9.5039745, 53.4097789]],
    [ "Kührstedt",[8.7830101, 53.6209729]],
    [ "Neuenwalde",[8.7004387, 53.6553866]],
    [ "Lunestedt",[8.6975032, 53.4429359]],
    [ "Uthlede Süd",[8.5918506, 53.3048681]],
    [ "Köhlen",[8.958613, 53.5392146]],
    [ "Hörne",[9.4898681, 53.6136168]],
    [ "856 Estenfeld 08",[10.0158866, 49.808069]],
    [ "Glienecke Dorf",[12.3783776, 52.2784046]],
    [ "Simonswolde",[7.3837656, 53.3596013]],
    [ "Bagband",[7.569494, 53.3797144]],
    [ "Dötlingen",[8.5208601, 52.9635346]],
    [ "Damsum Nord",[7.5336289, 53.655272]],
    [ "Eisten",[7.53271, 52.8034694]],
    [ "Harrenstätte",[7.6431649, 52.8362493]],
    [ "Stapelmoor Süd",[7.3172755, 53.1284941]],
    [ "Saterland",[7.7098726, 53.098029]],
    [ "Hooksiel",[8.0274089, 53.6165949]],
    [ "Neuschoo",[7.4993657, 53.5747258]],
    [ "Arle",[7.4037071, 53.579951]],
    [ "Bahnstrom Umformwerk Lehrte",[9.9513007, 52.3816887]],
    [ "Umspannwerk Lehrte West",[9.9514125, 52.3811839]],
    [ "10 kV-Station Georgstr. 3",[7.1831196, 51.4911865]],
    [ "Gleichrichterunterwerk OST",[11.663091, 52.1310958]],
    [ "Schloßvippach West",[11.122391, 51.1080433]],
    [ "Klein Schulzendorf",[13.2384656, 52.1854719]],
    [ "Riesdorf",[13.2082808, 51.9845134]],
    [ "Umspannwerk Bernburg West",[11.6997701, 51.7951348]],
    [ "Markendorf Süd",[13.1554442, 51.9848999]],
    [ "Station St. Tönis",[6.5177925, 51.3238996]],
    [ "Anstedt",[8.7500715, 52.7389977]],
    [ "Holzhausen",[9.1456646, 51.7805632]],
    [ "Bahro",[14.5823826, 52.0660553]],
    [ "Umspannwerk Rohne",[14.449147, 51.5407084]],
    [ "Altenzentrum",[7.8698326, 48.34375]],
    [ "10 kV Borken Enning",[6.8487186, 51.848141]],
    [ "Uslar",[9.6377608, 51.6536327]],
    [ "Lüchtringen",[9.4357301, 51.8044186]],
    [ "Umspannstation Helbra",[11.508762, 51.5671441]],
    [ "Buschhausen",[10.9776644, 52.1705839]],
    [ "Sommergasse 136",[8.6725726, 49.5275713]],
    [ "10 kV-Station Alte Wittener Str. 50A",[7.2721391, 51.4679984]],
    [ "UW Reußenköge",[8.9053479, 54.615207]],
    [ "Umspannwerk Strübbel",[8.9387459, 54.2595122]],
    [ "Wasserwerk Friedrichshagen, Station E",[13.6189646, 52.4376064]],
    [ "Harderberg",[8.0812406, 52.2231739]],
    [ "Hagen",[7.4590216, 51.3662307]],
    [ "Hasselburg",[11.2686447, 52.3259635]],
    [ "110 kV Schaltanlage",[11.670321, 52.3024265]],
    [ "Falkensee Süd",[13.0362717, 52.5474132]],
    [ "Umspannstation Kemberg",[12.6678563, 51.7854703]],
    [ "Wiebelsaat",[7.6490885, 51.1291926]],
    [ "Kalve",[7.6690534, 51.2120953]],
    [ "Tiegelstraße",[7.5190122, 51.3871092]],
    [ "Herdecke",[7.4113685, 51.4024863]],
    [ "10 kV-Station An der Holtbrügge 99",[7.2062557, 51.4486622]],
    [ "Kölner Straße",[7.4159899, 51.3451327]],
    [ "Schwerte",[7.5656966, 51.4335808]],
    [ "Donnerkuhle",[7.519589, 51.3572538]],
    [ "10 kV-Station Hustadtring 144",[7.2553868, 51.4488854]],
    [ "Schalthaus Gohfeld",[8.7562779, 52.1999235]],
    [ "10 kV-Station Alte Wittener Str. 62",[7.2739277, 51.4674773]],
    [ "Umspannwerk Heng",[11.3676015, 49.2921492]],
    [ "Stadtwerke Altenau",[10.4373334, 51.814252]],
    [ "Vorderambohl",[8.8237142, 47.7573366]],
    [ "Baustrom Krankenhaus",[8.8230875, 47.7596486]],
    [ "Steißlinger Straße",[8.8496655, 47.7445604]],
    [ "Umspannwerk Gebelkofen",[12.1612644, 48.9486714]],
    [ "Gransee (EON edis)",[13.2620541, 52.9927829]],
    [ "Umspannwerk Altentreptow Süd",[13.1765708, 53.6238095]],
    [ "Umrichterwerk Neustadt Dosse",[12.4550683, 52.8484337]],
    [ "Höver",[9.8946784, 52.3615232]],
    [ "Ruhrzink",[7.3234824, 51.6210007]],
    [ "Seddin",[12.9629359, 52.2694706]],
    [ "Umspannstation",[13.2693247, 52.9786201]],
    [ "Umspannwerk Biesenberg",[9.8723184, 47.6278627]],
    [ "Netze BW Umspannwerk Degerloch",[9.1755733, 48.7552279]],
    [ "Pöglitz Wind",[12.8487195, 54.1394523]],
    [ "Gottesgabe Nord",[14.1751873, 52.6481262]],
    [ "Gottesgabe Süd",[14.1749284, 52.6476955]],
    [ "Gottesgabe Wriezen",[14.1745922, 52.6509191]],
    [ "Umspannwerk Waldkraiburg neu",[12.3774425, 48.1944052]],
    [ "Haug-Düngerwerk",[8.9609353, 48.5342969]],
    [ "Umspannstation",[14.0288442, 51.609061]],
    [ "Öchtel",[7.275867, 52.3199177]],
    [ "Öchtel",[7.2769982, 52.3197789]],
    [ "Niederlangen",[7.1955576, 52.8696003]],
    [ "Niederlangen",[7.1955576, 52.8696003]],
    [ "Kabelübergangsanlage Dankern",[7.1755657, 52.8031485]],
    [ "Kabelübergangsanlage Segberg",[7.1648295, 52.7821645]],
    [ "110 kV-Umspannwerk Merklingen",[8.831915, 48.7739814]],
    [ "Kabelübergabestation Marbeck",[6.871597, 51.828011]],
    [ "Kornweg 38a",[8.4852687, 51.8105429]],
    [ "Westerwieher Straße 179",[8.4657998, 51.8132497]],
    [ "Westerwieher Straße 142",[8.4522844, 51.8131672]],
    [ "Station Dellwig",[7.6971939, 51.4759276]],
    [ "Göttingen Rosdorf",[9.8833261, 51.5141723]],
    [ "Göttingen",[9.8832536, 51.5141662]],
    [ "Umspannwerk Ihlow",[14.0914953, 52.6177386]],
    [ "Lüsseweg",[8.9595359, 48.5337271]],
    [ "10 kV-Station Hermannshöhe 40",[7.2211271, 51.4762846]],
    [ "Umspannwerk Kuhsdorf Nord",[12.1150666, 53.1150959]],
    [ "RDK8",[8.306078, 49.0126719]],
    [ "Lamspringe",[9.9867981, 51.9527645]],
    [ "Bahnstromumrichter Datteln 4",[7.3381259, 51.6323039]],
    [ "Erlenbach",[9.2251378, 50.0593078]],
    [ "Station Wienbecker Mühle",[7.0050448, 51.7268129]],
    [ "Bökenförder Warte",[8.3726331, 51.6454038]],
    [ "Lauffen-West",[9.1316596, 49.0686926]],
    [ "Lauffen-West",[9.1312854, 49.0683818]],
    [ "10 kV-Station 5992",[7.2186958, 51.4780978]],
    [ "Umspannwerk Teningen",[7.8365045, 48.1099915]],
    [ "UW Stavenhagen",[12.8758449, 53.7079709]],
    [ "UW Randowhöhe",[14.0510042, 53.2853569]],
    [ "Umspannstation",[12.1832073, 53.8094301]],
    [ "Gärtringen Waldhöfe 1",[8.8751724, 48.6356437]],
    [ "Brückermark",[12.4896057, 52.2833219]],
    [ "406",[8.2057393, 48.8451579]],
    [ "Umspannstation Niemberg",[12.092892, 51.5370802]],
    [ "Umspannstation Polleben",[11.6185069, 51.5905815]],
    [ "Schaltanlage Elsfleth/West",[8.4020818, 53.2379932]],
    [ "Melchendorf 2",[11.0669941, 50.9547861]],
    [ "Umspannwerk Suhl Friedberg",[10.7151617, 50.5901974]],
    [ "UW Schönewalde (E.DIS)",[13.1644712, 51.8242512]],
    [ "Derne",[7.4995277, 51.5453559]],
    [ "Hafenstraße",[8.4563003, 49.4928342]],
    [ "Umspannstation Schotterey",[11.8347835, 51.42024]],
    [ "Umspannstation Steuden",[11.7673434, 51.4045989]],
    [ "Umspannstation Esperstedt WP",[11.7042983, 51.3922799]],
    [ "Umspannstation Nemsdorf",[11.6599343, 51.3729181]],
    [ "Station Aldenrade",[6.7311174, 51.5215207]],
    [ "10 KV Oertchen",[7.3640785, 51.2870607]],
    [ "Umspannwerk Lauchstädt",[11.8917612, 51.4032117]],
    [ "Rheinstraße",[8.4569801, 49.491857]],
    [ "Akademiestraße 26",[8.4570123, 49.4919801]],
    [ "Umspannstation Luko",[12.3240036, 51.9302677]],
    [ "10 kV-Station Flaßkuhlstr. 14",[7.2298509, 51.4302706]],
    [ "Umspannwerk Bitterfeld Ost",[12.3180433, 51.6239334]],
    [ "Kabelübergabestation Lüningkamp",[6.8880217, 51.8509316]],
    [ "10 kV-Station Engelsburger Str. 77",[7.1795807, 51.4649898]],
    [ "Putlitz Süd (380kV)",[12.1072208, 53.2499068]],
    [ "Umspannwerk Tessin Nord",[12.4398418, 54.0320904]],
    [ "Kleinherbederstraße",[7.2920031, 51.454572]],
    [ "Umspannststation Sindelfingen Freibad 1",[9.0149841, 48.7171976]],
    [ "10 kV-Station Castroper Hellweg 22",[7.2586011, 51.4979118]],
    [ "10 kV-Station Zwischenweg 1A",[7.1793424, 51.4677021]],
    [ "10 kV-Station Querenburger Str.37A",[7.2298388, 51.4681795]],
    [ "10 kV Querenburger Str.45A",[7.2305605, 51.4672966]],
    [ "Umspannanlage 110kV - Tesla GF4",[13.7938387, 52.3937437]],
    [ "Hildegard-Kattermann-Str.",[7.8828254, 48.3404901]],
    [ "Hauptstrasse",[7.8326062, 48.3628824]],
    [ "Gleichrichterunterwerk Schönfließ",[13.3331872, 52.6669869]],
    [ "Umspannwerk Olching",[11.3140914, 48.2178891]],
    [ "Umspannwerk Birkholzaue",[13.6142803, 52.6168559]],
    [ "Umspannwerk Börnicke",[13.652969, 52.6632385]],
    [ "Umspannwerk Willmersdorf",[13.6700578, 52.6900369]],
    [ "ALEGrO-Konverterstation Oberzier",[6.453521, 50.8726582]],
    [ "KÜA Ganderkesee Süd",[8.5532044, 53.0032822]],
    [ "Pollhorn",[9.6774975, 54.2169585]],
    [ "UW Granzow Nord",[12.2484805, 52.9542383]],
    [ "Umspannwerk Harburg Ost",[10.7013377, 48.7761484]],
    [ "Hammerdamm",[12.5671121, 52.2737216]],
    [ "Umspannwerk Sandersdorf",[12.2396409, 51.6410017]],
    [ "Umspannwerk Stade-West",[9.4885856, 53.6308662]],
    [ "10 KV Wiensiepen",[7.3032263, 51.3130207]],
    [ "Gleichrichterunterwerk Fredersdorf",[13.8295318, 52.5325474]],
    [ "20kV-Schalthaus",[10.1972211, 50.0285176]],
    [ "Umspannwerk Freienbrink",[13.8191367, 52.3952966]],
    [ "Prinzhöfte",[8.4970097, 52.9366306]],
    [ "Frohnried",[9.1340808, 47.7046095]],
    [ "Solar-UW Marienfelde",[13.2064937, 53.9001123]],
    [ "Wasserwerk Friedrichshagen, Station F01",[13.6644901, 52.4307402]],
    [ "Schaltwerk Zwenkau",[12.324754, 51.1947497]],
    [ "Schaltanlage Kraftwerk Häusern",[8.1882096, 47.7549346]],
    [ "Kuppelstelle Lindern",[6.2014731, 50.9956112]],
    [ "Kuppelstelle Dachau",[11.438608, 48.2674883]],
    [ "10 kV-Station Nordstr. 1A",[7.2363196, 51.4757032]],
    [ "Umspannwerk Kerken",[6.3855937, 51.4556505]],
    [ "Schuby/West",[9.4366442, 54.5218367]],
    [ "Umspannwerk Körrenz",[6.2677807, 50.9878177]],
    [ "ONI Glashüttenstraße",[8.0657847, 48.630441]],
    [ "Gertrud-Beinling-Weg 2",[8.5106821, 49.4668258]],
    [ "10 kV-Station Wittener Str. 272A",[7.2574535, 51.4718869]],
    [ "Station Kruckel",[7.4154595, 51.4563764]],
    [ "LEAG Zentrale Stromversorgung",[14.3349813, 51.6826627]],
    [ "Möhlstraße",[8.4872664, 49.4743347]],
    [ "Umspannwerk Cottbus",[14.3053356, 51.7239845]],
    [ "Umspannwerk Cottbus, EVC",[14.3060378, 51.724443]],
    [ "Unterwerk Merklingen",[9.7325076, 48.5231953]],
    [ "10 kV-Station Hiltroper Str. 156",[7.230049, 51.5052971]],
    [ "Ostwind 1",[13.6812159, 54.1461221]],
    [ "Anlandepunkt Ostwind 1",[13.6581811, 54.1541053]],
    [ "Ostwind 1",[13.6687289, 54.1505899]],
    [ "TS Wasserwerk West Station Nord",[10.9809323, 49.6293178]],
    [ "TS Werker 1t",[10.9983695, 49.6108718]],
    [ "Erweiterung Umspannwerk Weier",[7.9092143, 48.5005047]],
    [ "Uckerthal",[13.9502098, 53.3776122]],
    [ "Karlshof",[13.9488639, 53.3845468]],
    [ "Raumünzach Granitwerk",[8.3395614, 48.6450744]],
    [ "TH 409144 Hausen OT Kraftwerk",[11.0478853, 49.687765]],
    [ "Umspannwerk Thyrow",[13.3046153, 52.2303022]],
    [ "Umspannwerk Thyrow",[13.3076591, 52.2297713]],
    [ "Umspannwerk Wustermark",[12.9416343, 52.5623548]],
    [ "Umspannwerk Wustermark",[12.9416343, 52.5623548]],
    [ "Umspannwerk Malchow",[13.4932544, 52.5912074]],
    [ "Bentwisch HGÜ",[12.2175562, 54.1014934]],
    [ "Umspannwerk Ragow",[13.8983287, 51.8734822]],
    [ "UW Schönewalde (Energiequelle)",[13.1559368, 51.8289383]],
    [ "Umspannwerk Preilack",[14.431577, 51.8900788]],
    [ "Eisenhüttenstadt Pohlitz",[14.6056332, 52.1752582]],
    [ "Umspannwerk Perleberg",[11.8542314, 53.0875904]],
    [ "Putlitz Süd",[12.1112001, 53.2504471]],
    [ "Umspannwerk Siedenbrünzow",[13.1464126, 53.9085866]],
    [ "Stendal West 110kV",[11.7290964, 52.5697433]],
    [ "Umspannwerk Schmölln",[14.2207858, 51.1202685]],
    [ "Umspannwerk Görries",[11.3728014, 53.6013875]],
    [ "Umspannwerk Neuenhagen",[13.708086, 52.54194]],
    [ "Umspannwerk Neuenhagen",[13.708086, 52.54194]],
    [ "Kuppelstelle Bramstedt",[8.7955021, 52.87635]],
    [ "Umspannwerk Graustein",[14.4728594, 51.5530911]],
    [ "Umspannwerk Dresden Süd",[13.8404732, 50.9896955]],
    [ "Umspannwerk Röhrsdorf",[12.8114372, 50.8576008]],
    [ "Umspannwerk Röhrsdorf",[12.8110081, 50.8571902]],
    [ "Umspannwerk Krümmel",[10.417254, 53.4123912]],
    [ "Umspannwerk Pasewalk",[14.0351567, 53.5081487]],
    [ "Flensburg HKW",[9.4314494, 54.8045089]],
    [ "Förderstedt 380 kV",[11.6459803, 51.9087229]],
    [ "Umspannwerk Streumen",[13.3818652, 51.3579267]],
    [ "Umspannwerk Tantow",[14.3621828, 53.2816895]],
    [ "Umspannwerk Zwönitz",[12.7878367, 50.6276625]],
    [ "Umspannwerk Weida",[12.0465755, 50.7819788]],
    [ "Umspannwerk Weida",[12.0439006, 50.7804639]],
    [ "Umspannwerk Marke",[12.2614082, 51.7430694]],
    [ "Umspannwerk Eula",[12.5205223, 51.1583767]],
    [ "Umspannwerk Eula",[12.5176441, 51.1577975]],
    [ "Umspannwerk Hattorf",[10.7624006, 52.3687163]],
    [ "Umspannwerk Hamburg/Ost",[10.1543968, 53.5557453]],
    [ "Umspannwerk Lüdershagen",[13.080481, 54.2839578]],
    [ "Wahle 220 kV",[10.3669228, 52.2860656]],
    [ "Umspannwerk Klixbüll-Süd",[8.8644101, 54.7944127]],
    [ "Umspannwerk Altentreptow Süd",[13.1735047, 53.6249489]],
    [ "Umspannwerk Lüneburg",[10.3796338, 53.2204364]],
    [ "Umspannwerk Klostermansfeld",[11.5181464, 51.5845067]],
    [ "Umspannwerk Lübeck",[10.6418658, 53.9196402]],
    [ "Umspannwerk Vieselbach",[11.1233386, 51.0011083]],
    [ "Großschwabhausen",[11.4715819, 50.9310189]],
    [ "Umspannwerk Altenfeld",[10.9883814, 50.574899]],
    [ "Grohnde",[9.3873929, 52.0269667]],
    [ "Umspannwerk Bergshausen 110kV",[9.5141155, 51.2600805]],
    [ "Umspannwerk Eisenach",[10.3847464, 51.0016835]],
    [ "KÜA Havekost",[8.5455289, 52.9916133]],
    [ "KÜA Klein Henstedter Heide",[8.5300922, 52.9662648]],
    [ "Hardegsen",[9.854247, 51.6488659]],
    [ "Station Gronau",[7.0337747, 52.2035481]],
    [ "Station Gronau",[7.0359802, 52.2022943]],
    [ "Station Gersteinwerk",[7.7041944, 51.6748816]],
    [ "Station Gersteinwerk",[7.710674, 51.6775495]],
    [ "TS Killingerstr. 71a",[10.9875153, 49.6032815]],
    [ "Station An der Marter",[10.9945445, 49.641045]],
    [ "Möhrendorf Erlangerstr.",[10.99816, 49.6371186]],
    [ "TS Schwabenstr. 6",[10.9884415, 49.5868227]],
    [ "TS Damaschkestr. 129",[10.9789161, 49.5877501]],
    [ "Kummerfeld",[9.7771659, 53.6913256]],
    [ "Hamburg Nord",[9.9847763, 53.7388028]],
    [ "Hamburg Nord",[9.990223, 53.7669478]],
    [ "TS Bayreuther Str. 55a",[11.002708, 49.6073695]],
    [ "Möhrendorf St. Hauptstr.",[10.9996277, 49.6394407]],
    [ "Ohlensehlen",[8.8955129, 52.5650595]],
    [ "TS Membacher Weg 47",[10.9742338, 49.6081652]],
    [ "Philippsthal-Wind",[9.9377301, 50.8505549]],
    [ "Kabelübergangsanlage Nord",[10.2510338, 52.1693586]],
    [ "UW Gollmitz",[13.7163299, 53.3054334]],
    [ "Veltheim",[8.9321154, 52.1941203]],
    [ "Station Westerkappeln",[7.8895483, 52.2772461]],
    [ "Station Westerkappeln",[7.8895483, 52.2772461]],
    [ "10 kV-Station Industriestr. 40B",[7.292509, 51.4747783]],
    [ "Umspannwerk Ludersheim",[11.3247246, 49.3943511]],
    [ "Umspannwerk Sottrum",[9.2489145, 53.1144747]],
    [ "Sottrum",[9.2517639, 53.116008]],
    [ "Havellandstraße",[8.5499646, 49.5192338]],
    [ "Am Wallstadter Bahnhof",[8.5491934, 49.4984131]],
    [ "Wechold",[9.143689, 52.8531355]],
    [ "Bechterdissen 380 kV",[8.6579146, 51.9995937]],
    [ "BUGA-Areal Nord-Ost, Talstraße",[8.5243974, 49.5007539]],
    [ "Anna-Sammet-Straße, Baustromstation 2",[8.5203793, 49.5038888]],
    [ "Biblis A",[8.414945, 49.7108057]],
    [ "Klinkow",[13.8235201, 53.341298]],
    [ "FW Karl-Reimann-Ring",[11.0227846, 51.0163702]],
    [ "Burggrund",[8.6336272, 49.0848362]],
    [ "Umspannwerk Wiedensahl",[9.1179858, 52.3791023]],
    [ "Station Rungstraße",[8.1392788, 48.686275]],
    [ "Schaltstelle Roitzsch PVA",[12.2353902, 51.5793265]],
    [ "Umspannstation Roitzsch PVA",[12.2377391, 51.5760859]],
    [ "Lemsell",[11.2797387, 52.3248929]],
    [ "Bülstringen",[11.3427413, 52.3218303]],
    [ "Wieglitz",[11.3183594, 52.356142]],
    [ "Kottigerhook 380 kV",[7.0107733, 52.1777138]],
    [ "UW Tangerhütte",[11.7851932, 52.431513]],
    [ "Neustadt (Hessen)",[9.087712, 50.8572215]],
    [ "UW Terpe",[14.2520239, 51.5507916]],
    [ "Station Handbach",[6.8123848, 51.5202123]],
    [ "Lumda",[8.7954277, 50.6674314]],
    [ "Umrichterwerk Niederbiegen",[9.6238999, 47.8369204]],
    [ "Umspannwerk Herlasgrün",[12.2416348, 50.5678513]],
    [ "10 kV-Station Wittener Str. 503A",[7.2898285, 51.4646605]],
    [ "Umspannwerk Radis",[12.5146372, 51.7475378]],
    [ "Umspannstationen Schnellin",[12.7004155, 51.7722966]],
    [ "Umspannwerk Rollberg",[13.4282973, 52.4779689]],
    [ "Umspannstation",[13.8698959, 51.9491275]],
    [ "Holtfeld 02",[8.2852158, 52.0601779]],
    [ "10 KV Schwimmin",[7.3192788, 51.3224008]],
    [ "10 KV Ochsenkamp",[7.3243779, 51.3232015]],
    [ "Autotransformatorstation Memmingen",[10.1864908, 47.9823593]],
    [ "Autotransformatorstation Leutkirch Süd",[9.9973085, 47.8217948]],
    [ "Autotransformatorstation Leutkirch Nord",[9.997464, 47.8218309]],
    [ "Autotransformatorstation Geltendorf",[11.0305828, 48.1059841]],
    [ "Autotransformatorstation Buchloe",[10.7154451, 48.0318462]],
    [ "Autotransformatorstation Hergatz",[9.8285768, 47.647363]],
    [ "Schaltposten Dörfles-Esbach",[11.0109357, 50.2929823]],
    [ "Unterwerk Nabern",[9.4730139, 48.6294865]],
    [ "Umspannwerk",[11.7285442, 51.7711366]],
    [ "Umspannwerk",[11.7435209, 51.7500781]],
    [ "Sigrid-Hackbarth-Straße",[8.4866113, 49.4694628]],
    [ "Umspannstation",[11.990374, 51.2531331]],
    [ "Umspannwerk Schelkau",[11.9926175, 51.1019348]],
    [ "Station Laasphe",[8.3995061, 50.9232237]],
    [ "Wind-UW Sputendorf",[13.2495314, 52.3449556]],
    [ "Werneuchen",[13.6664538, 52.5915747]],
    [ "Umspannwerk Rommersheim",[6.4588649, 50.2128517]],
    [ "UW Semlin",[11.7579987, 53.1470405]],
    [ "UW Semlin",[11.7571595, 53.1469265]],
    [ "Altentreptow Bahndamm",[13.2369659, 53.6813069]],
    [ "Umspannwerk Groitzsch",[12.2926754, 51.1407855]],
    [ "Umspannwerk Beuna",[11.967909, 51.3044623]],
    [ "Umspannwerk Tröglitz",[12.1971529, 51.0743026]],
    [ "Umspannstation Windpark Langendorf",[12.2480413, 51.0910609]],
    [ "S-Bahn Gleichrichterwerk Stresow",[13.2090273, 52.5322408]],
    [ "Umspannstation Pöhla",[12.2922785, 50.9995354]],
    [ "Petersdorf",[14.3778522, 52.3401269]],
    [ "Umspannstation Obhausen Süd",[11.6890032, 51.3850668]],
    [ "Umspannwerk Hochstadt-Süd",[11.262695, 48.0490674]],
    [ "Umspannstation Amsdorf S",[11.712995, 51.4613249]],
    [ "Wolletz Fachklinik",[13.9065799, 53.0256899]],
    [ "Trafostastion Staffel",[8.6973228, 49.7429772]],
    [ "Michelsbach",[8.6924456, 50.8431496]],
    [ "UW Germendorf",[13.1640683, 52.7440819]],
    [ "Heilbronn",[9.2091932, 49.1735334]],
    [ "10 KV Sonnenweg/Hochhaus",[7.380904, 51.3106757]],
    [ "Umspannwerk Mosel/Zwickau",[12.4734868, 50.7878962]],
    [ "Wolletz SSt Wolletz",[13.8709505, 53.0245876]],
    [ "Station Gellep",[6.6731765, 51.3256674]],
    [ "Station 6.2",[13.5211009, 52.4415426]],
    [ "Bergstraße",[8.9836974, 50.3531048]],
    [ "Martin-Lang-Str.2",[7.1465605, 51.4927627]],
    [ "Frischborn",[9.3623988, 50.5954183]],
    [ "UW Gottesgabe Ost",[14.1780652, 52.6485286]],
    [ "Schönau/Lindenbachsiedlung",[8.8059731, 49.4190635]],
    [ "Umspannwerk Heide",[8.3706327, 49.039635]],
    [ "Umspannst. Wolfenmühle",[9.0642943, 48.6497116]],
    [ "Umspannst.Schubertstr.",[9.0606205, 48.6425695]],
    [ "Umspannst. Schönbuchstr.",[9.0582401, 48.6422598]],
    [ "Umspannst. Mozartstr",[9.0607668, 48.6415979]],
    [ "Autotransformatorstation Lohsa/West",[14.3708188, 51.4029941]],
    [ "Autotransformatorstation Mücka",[14.7067812, 51.3196306]],
    [ "Autotransformatorstation Horka",[14.8877795, 51.302119]],
    [ "10 kV Station BI Bielefeld Schalthaus 1",[8.5322852, 52.0286322]],
    [ "Borchen",[8.7430067, 51.6686348]],
    [ "Schwanheim (vorh. Höchst Süd Neu)",[8.5288115, 50.0782555]],
    [ "Osterburker Straße 6",[8.5461215, 49.4962534]],
    [ "Umspannwerk Altdöbern",[13.9983261, 51.6412774]],
    [ "10 kV-Staton Weidkamp",[7.209417, 51.5555166]],
    [ "Umspannwerk Handewitt",[9.3136939, 54.7274394]],
    [ "Jardelund",[9.1959416, 54.8520492]],
    [ "UW Husum Nord",[9.0778636, 54.5086722]],
    [ "Itzehoe/West",[9.4491566, 53.9671138]],
    [ "Bardenfleth",[8.4044256, 53.2381189]],
    [ "Jugenddorf",[7.9502496, 48.4585682]],
    [ "Umspannwerk Füssen",[10.6533011, 47.5678159]],
    [ "Umspannst. SWN",[9.3475459, 48.6123842]],
    [ "Emscherbruch",[7.1680416, 51.5555901]],
    [ "Barbarossastr. 125",[8.3424391, 51.6839018]],
    [ "Umspannwerk Poelchaustrasse",[13.536953, 52.5373439]],
    [ "Umspannwerk Niederwiesa",[13.0020567, 50.8664105]],
    [ "Baumgarten Süd",[13.9341122, 53.3391799]],
    [ "Flötz",[11.9264404, 51.9878247]],
    [ "Umspannwerk Niederwiesa",[13.0017308, 50.8667688]],
    [ "Umspannwerk Coesfeld-Lette",[7.1451059, 51.8769646]],
    [ "Ü-PV Flötz",[11.9270069, 51.9872835]],
    [ "Umspannwerk Altes Gaswerk Marienfelde",[13.3730256, 52.4387048]],
    [ "Umspannwerk Ragow",[13.9030154, 51.8745809]],
    [ "Umspannwerk Remptendorf",[11.6444313, 50.5352539]],
    [ "Umspannwerk Remptendorf",[11.6477466, 50.5363188]],
    [ "UW Gereonsweiler",[6.2240102, 50.9769195]],
    [ "Umspannst. Kimmich",[9.1090996, 48.608775]],
    [ "Umspannwerk Mecklar 110kV",[9.7464517, 50.916819]],
    [ "Umspannwerk Redwitz",[11.1954477, 50.1641065]],
    [ "Umspannwerk Würgau",[11.1039358, 49.9807961]],
    [ "Umspannwerk Etzenricht",[12.1176735, 49.6319129]],
    [ "Umspannwerk Etzenricht",[12.1149565, 49.6317639]],
    [ "Umspannwerk Woltersdorf",[14.2633989, 53.1707335]],
    [ "Umspannwerk Brunsbüttel (TenneT)",[9.2043868, 53.8973773]],
    [ "Umspannwerk Brunsbüttel (50Hertz)",[9.2034511, 53.8949496]],
    [ "Umspannwerk Krümmel (TenneT)",[10.4172492, 53.4123848]],
    [ "Schaltwerk Krümmel (50 Hertz)",[10.4160363, 53.4124957]],
    [ "Umspannwerk Heinersdorf",[14.1314999, 52.4429921]],
    [ "Gleichrichter-Unterwerk Friedrichstal Rheinstraße",[8.477033, 49.1098017]],
    [ "Umspannwerk Lubmin",[13.6763351, 54.1405798]],
    [ "Kabelüberführungsstation Leipheim",[10.2504829, 48.4190656]],
    [ "Umspannwerk Güstrow",[12.1867094, 53.8140299]],
    [ "Umspannwerk Güstrow",[12.1865016, 53.8161589]],
    [ "Umspannwerk Parchim Süd",[11.9024728, 53.4079379]],
    [ "6260",[13.2938795, 51.0585724]],
    [ "Umspannwerk Wolmirstedt",[11.6355493, 52.2678746]],
    [ "Jessen Nord (Schweinitz) 110 kV",[13.0564271, 51.7984502]],
    [ "Katzenauer Straße",[8.9795082, 50.3571199]],
    [ "Umspannwerk Wessin",[11.6977109, 53.5708123]],
    [ "10 kV-Station Poststr. 28 a",[7.2091405, 51.5014116]],
    [ "UW Beeskow Ost",[14.2863413, 52.1866969]],
    [ "UW Groß Werzin",[12.0112646, 53.0054731]],
    [ "Umspannwerk Brahlstorf",[10.9379559, 53.3648406]],
    [ "Glienecke Am Bach",[12.3738316, 52.2773512]],
    [ "Steinberg Dorf",[12.3603322, 52.2631163]],
    [ "Boecke ASt PVA Agrar",[12.4045477, 52.2926098]],
    [ "PVA Boecke",[12.4068182, 52.2928027]],
    [ "Boecke LPG",[12.4039735, 52.2971164]],
    [ "Boecke Dorf",[12.405816, 52.2998142]],
    [ "Boecke WiKA",[12.4142023, 52.2993987]],
    [ "Wenzlow",[12.4459295, 52.2986965]],
    [ "Grüningen",[12.4543474, 52.298093]],
    [ "Gewerbepark Wollin",[12.4653796, 52.2887613]],
    [ "Wollin PVA Wenzlower Straße",[12.4658693, 52.2863584]],
    [ "Wollin Schulstraße",[12.4692746, 52.2810656]],
    [ "Wollin Schule",[12.4612838, 52.276954]],
    [ "Wollin Hauptstraße",[12.4727599, 52.2801407]],
    [ "Viesen Mühle",[12.3990171, 52.3184505]],
    [ "Brück Heinrich-Heine-Str.",[12.7575581, 52.18567]],
    [ "PVA Wollin",[12.4809551, 52.2759721]],
    [ "Trafo PVA Ziesar 2",[12.2735852, 52.2681126]],
    [ "Ziesar ASt PVA Paplitzer Chaussee",[12.2744036, 52.2690964]],
    [ "Trafo PVA",[12.2781603, 52.2685375]],
    [ "Umschaltwerk Santow",[11.1794324, 53.8858465]],
    [ "Umspannwerk Siems",[10.7582664, 53.9103514]],
    [ "UW Seehausen",[11.7629334, 52.8849036]],
    [ "UW Goldbeck",[11.867816, 52.7186345]],
    [ "Wind-UW Wernstedt",[11.3605994, 52.6510363]],
    [ "UW Jeeben",[11.1016338, 52.6802088]],
    [ "UW Siedenlangenbek",[11.0818757, 52.7584839]],
    [ "UW Nettgau",[10.9155144, 52.6528746]],
    [ "Heilbronn",[9.206744, 49.1778276]],
    [ "Wind-UW Zerrenthin",[14.0651319, 53.4916254]],
    [ "Wind-UW Zerrenthin-Polzow",[14.0646537, 53.4911922]],
    [ "UW Uchtdorf Nord",[11.7376667, 52.4167242]],
    [ "10 kV-Station Wohlfahrtstr. 110",[7.216821, 51.4518117]],
    [ "Übergabestation Rheda-Wiedenbrück",[8.3274857, 51.8260673]],
    [ "Oppenwehe",[8.5460703, 52.4614316]],
    [ "UW Neuferchau Wind",[11.0533505, 52.5673804]],
    [ "Phasenschieberanlage Krempermarsch",[9.4984874, 53.8467271]],
    [ "Bahnstrom Unterwerk Werdau",[12.3705393, 50.7203243]],
    [ "UA Rehlingen",[6.6880496, 49.3654781]],
    [ "Mitte",[7.2940394, 51.2904669]],
    [ "Heckinghausen",[7.2228843, 51.2718174]],
    [ "HGÜ DolWin3 Umspannwerk Dörpen West",[7.2545452, 52.9818892]],
    [ "Station Ricarda Huch Weg 03184",[10.9934618, 51.0244593]],
    [ "10 kV-Station Schleiermacherstr. 64 A",[7.1731645, 51.4530585]],
    [ "10 kV-Station Feldmark 3a",[7.2504427, 51.475399]],
    [ "Schuby/West",[9.4366442, 54.5218367]],
    [ "Audorf",[9.727858, 54.291884]],
    [ "Station Arenberg",[6.9990998, 51.5151672]],
    [ "Umspannstation Dornbock",[11.8775942, 51.8280974]],
    [ "Biedenkopf",[8.4967417, 50.9289768]],
    [ "10 KV Amtmann-Ibing-Straße 5",[7.2831011, 51.5184484]],
    [ "Gleichrichterwerk Hemmingen",[9.7197432, 52.3312309]],
    [ "Trafo Jonaswalde 02",[12.2834805, 50.8368133]],
    [ "Trafo HKWD 3;MDh 4179;Dorfstraße 49",[12.2624525, 50.833457]],
    [ "Umspannwerk Stockelsdorf",[10.6486325, 53.9010943]],
    [ "Umspannwerk Bad Schwartau Ost",[10.7016664, 53.9160851]],
    [ "Umspannwerk St. Lorenz Nord",[10.6648627, 53.8734783]],
    [ "Umspannwerl St. Lorenz Süd",[10.6672405, 53.8588278]],
    [ "Umspannwerk St. Gertrud",[10.7054081, 53.8874155]],
    [ "Umspannwerk Strecknitz",[10.7135391, 53.8351375]],
    [ "Conneforde/Ost",[8.0510947, 53.3314057]],
    [ "Conneforde 110 kV",[8.0545282, 53.3353967]],
    [ "Conneforde 380/220 kV",[8.0510687, 53.3334728]],
    [ "Am Friedensplatz",[8.4959607, 49.477428]],
    [ "Umspannwerk Gauting",[11.380313, 48.0682234]],
    [ "10 kV-Station Wittener Str. 66",[7.2276449, 51.477716]],
    [ "Kirche Bökenförde",[8.3925089, 51.63634]],
    [ "10 kV-Station Flurstr. 1",[7.2344744, 51.483812]],
    [ "Staudach",[10.2871047, 47.7235558]],
    [ "Heiligkreuz",[10.2845068, 47.74837]],
    [ "Rähnisstraße",[12.1321205, 50.5071216]],
    [ "Postamt",[12.1304356, 50.5066854]],
    [ "Max- Planck- Straße",[12.1291513, 50.509612]],
    [ "Schaltposten Lindau-Aeschach",[9.6846178, 47.5584292]],
    [ "10 kV-Station Querenburger Str. 3",[7.227271, 51.4712705]],
    [ "10 kV-Station Universitätsstraße 43",[7.2256261, 51.476513]],
    [ "10 kV Haderslebener Str. 7",[7.2261661, 51.4748525]],
    [ "LEAG Zentrale Stromversorgung",[14.3349472, 51.6826171]],
    [ "Umspannwerk Herrenwyk",[10.7994123, 53.8987145]],
    [ "10 kV-Station Stadionring 1A",[7.2335017, 51.4886794]],
    [ "LEAG Zentrale Stromversorgung",[14.3349741, 51.6826218]],
    [ "Schaltposten Oldenburg",[8.2291245, 53.1436558]],
    [ "Autotransformatorstation Rastede",[8.1643577, 53.2957711]],
    [ "Autotransformatorstation Wilhelmshaven Ölweiche",[8.0829415, 53.5967095]],
    [ "Stützpunkt Geniner Ufer",[10.6809669, 53.8543357]],
    [ "10 kV-Station Auf der Heide 12",[7.2437907, 51.4657715]],
    [ "10 kV-Station Herner Straße 412-414",[7.2115873, 51.5143546]],
    [ "10 kV Hiltroper Straße 160",[7.2335045, 51.5050132]],
    [ "10 kV-Station Königsallee 145 B",[7.2221271, 51.4602057]],
    [ "Rathaus",[8.9865531, 50.3585457]],
    [ "Hassia-Höhe",[8.9764052, 50.3533461]],
    [ "ST Dauernheimer Straße 5",[8.9823351, 50.3592275]],
    [ "Oskerheide 2",[7.9966274, 51.6871729]],
    [ "An der Gärtnerei",[8.9826132, 50.3608712]],
    [ "Waschbodenstraße",[8.9840471, 50.3616779]],
    [ "Gleichrichterwerk Rennbahnstraße",[8.6510024, 50.0876212]],
    [ "10 kV-Station Hofsteder Str. 97",[7.2076529, 51.495081]],
    [ "10 kV-Station Schmechtingstr. 32",[7.2094555, 51.4939627]],
    [ "10 kV-Station Hofsteder Str. 1",[7.2095371, 51.4887996]],
    [ "10 kV-Station Prinz-Regent-Str. 76 A",[7.2142792, 51.4493751]],
    [ "10 kV-Statiion Am Langen Seil 80A",[7.2445273, 51.4587676]],
    [ "10 kV-Station 5941",[7.2377615, 51.4688164]],
    [ "Umspannwerk Schötmar",[8.7614265, 52.07436]],
    [ "10 kV-Station Kemnader Str. 500",[7.2438792, 51.4145128]],
    [ "10 kV-Station 8985",[7.2417754, 51.4303437]],
    [ "10 kV-Station Ermlandstr. 7 A",[7.2089473, 51.4672274]],
    [ "10 kV-Statiion Kulmer Str. 31",[7.2078477, 51.4674508]],
    [ "10 kV-Station 2941",[7.2554836, 51.5014715]],
    [ "10 kV-Station Liebfrauenstr. 19 A",[7.2459046, 51.4744438]],
    [ "10 kV-Station Liebfrauenstr. 19 B",[7.2459388, 51.4744714]],
    [ "10 kV-Station Lohbergstr. 1A",[7.2328315, 51.4784108]],
    [ "10 kV-Station Biermannsweg 22",[7.2397122, 51.4546131]],
    [ "10 kV-Station Feldmark 10",[7.2534643, 51.4766171]],
    [ "10 kV-Station Am Bergbaumuseum 28",[7.217682, 51.4892101]],
    [ "10 kV-Station Agnesstr. 26",[7.2159249, 51.4956566]],
    [ "10 kV-Station 2923",[7.2557091, 51.4982608]],
    [ "10 kV-Station Westhoffstr. 17 A",[7.2117106, 51.4895347]],
    [ "Sieversdorf",[14.3765837, 52.339519]],
    [ "UW Gasturbinenkraftwerk Ahrensfelde",[13.5585336, 52.5901572]],
    [ "10 kV-Station Möhnestr. 21",[7.2437192, 51.4973224]],
    [ "10 kV-Station 5961",[7.2113472, 51.4665569]],
    [ "Schaltanlage Ovendorfer Hof",[10.8226482, 53.9483466]],
    [ "10 kV-Station Auf dem Backenberg 21A",[7.2719753, 51.4580023]],
    [ "eHighway Schleswig-Holstein",[10.5091907, 53.8199612]],
    [ "10 kV-Station Markstr. 189 B",[7.2442768, 51.4510815]],
    [ "10 kV-Station Herner Str. 221-223",[7.2110806, 51.4994143]],
    [ "Wildungen",[9.1493556, 51.1197952]],
    [ "10 kV-Station Wiescherstr. 75",[7.2504643, 51.5193067]],
    [ "10 kV-Station Wiescherstr. 6A",[7.2570187, 51.5160362]],
    [ "10 kV-Station Hiltroper Str. 453",[7.2567154, 51.512631]],
    [ "10 kV-Station Hiltroper Str. 450A",[7.2562144, 51.5124436]],
    [ "10 kV-Station Hiltroper Str. 450",[7.2566747, 51.5124923]],
    [ "10 kV-Station Hiltroper Str. 378",[7.2511018, 51.5106247]],
    [ "10 kV-Station Hiltroper Str. 260A",[7.2461074, 51.5052133]],
    [ "10 kV-Station 2920",[7.2323446, 51.4994959]],
    [ "10 kV-Station Karl-Lange-Str. 59",[7.2431988, 51.4863769]],
    [ "10 kV-Station Knüwerwg 15",[7.2223969, 51.4728773]],
    [ "10 kV-Station Hattinger Str. 427 A",[7.1947379, 51.4462528]],
    [ "Umspannst.",[9.4972168, 48.5690521]],
    [ "10 kV-Station Auf dem Kalwes 230",[7.2868265, 51.4405288]],
    [ "UA Griethausen",[6.1605368, 51.8241626]],
    [ "Großenhain Cottb. BF 01",[13.523825, 51.2903943]],
    [ "10 kV-Station Cheruskerstr. 4",[7.1878079, 51.4795636]],
    [ "10 kV-Station 5929",[7.2116224, 51.4757051]],
    [ "10 kV-Station Baarestr. 52",[7.1986235, 51.4768284]],
    [ "10 kV-Station Thüringer Str. 20",[7.1910151, 51.4797063]],
    [ "Umspannst Dieselstraße",[9.0944903, 48.6099833]],
    [ "Umspannst. Lehrweg",[9.0984848, 48.6132802]],
    [ "Umspannst. Kirchstraße",[9.098589, 48.6101582]],
    [ "Umspannst. Störrenstraße",[9.0985201, 48.6089204]],
    [ "10 kV-Station An der Maarbrücke 6",[7.193489, 51.4814549]],
    [ "10 kV-Station Seilfahrt 107",[7.197503, 51.486418]],
    [ "10 kV-Station Eickeler Straße 21",[7.205255, 51.4879958]],
    [ "Umspannst. Pfrondorferstr. II",[9.0986899, 48.6008085]],
    [ "Umspannst. Mühlhausstr.",[9.1051527, 48.6051063]],
    [ "10 kV-Station Cramerstr. 14 A",[7.1902818, 51.4780013]],
    [ "10 kV-Station Gustav-Heinemann-Platz 2",[7.2151256, 51.483027]],
    [ "TST Am Weinberg",[13.2298133, 52.3961929]],
    [ "10 kV-Station Goystr. 2A",[7.2472586, 51.4716661]],
    [ "Umspannst. Schönbuchstr. II",[9.1043872, 48.6030738]],
    [ "Umspannst. Roßwiesenstr.",[9.1020769, 48.6099308]],
    [ "Umspannst Lehräckerstr.",[9.0970221, 48.6127794]],
    [ "SG280013 Roter Fuchs",[9.6991506, 51.9431971]],
    [ "Umspannst. Pfrondorfer Straße I",[9.098154, 48.6048352]],
    [ "Umspannst. Bahnhofstraße",[9.0966941, 48.6052141]],
    [ "Umspannst. Bismarckstr.",[9.0944998, 48.6068332]],
    [ "Umspannst.Brückenstr.",[9.0916469, 48.6087277]],
    [ "10 kV-Station Kampmannstr. 23",[7.2306051, 51.4612414]],
    [ "10 kV-Station Universitätsstr. 110 E",[7.2333735, 51.4604046]],
    [ "10 kV-Station Hustadtring 157 A",[7.2548413, 51.4486965]],
    [ "10 kV-Station Laerholzstr. 11",[7.2489102, 51.4505903]],
    [ "Umspannst. Gartenstr.",[9.1276426, 48.6373938]],
    [ "Umspannst. Kath. Kirche",[9.10366, 48.6040115]],
    [ "Umspannst. Ahornstr.",[9.1016007, 48.6033687]],
    [ "10 kV-Station Springorumallee 2",[7.2085923, 51.4545858]],
    [ "10 kV-Station Springorumallee 5",[7.2112488, 51.4538315]],
    [ "Umspannwerk Lindenberg",[9.8670069, 47.5919912]],
    [ "St. Roman Kinzigtal",[8.2910311, 48.3301869]],
    [ "10 KV Lidl Hagener Straße",[7.5945625, 51.3635753]],
    [ "10 kV-Station Hofsteder Straße 194A",[7.2056118, 51.4999983]],
    [ "10 kV-Station Wittener Str. 242",[7.2493715, 51.4715869]],
    [ "Vorderer Einbach",[8.1668639, 48.2944045]],
    [ "Großenhain Cottb. BF 02",[13.5231502, 51.2901346]],
    [ "10 kV-Station Suttner-Nobel-Allee C",[7.2680871, 51.4679874]],
    [ "10 kV-Station Opelring 1 A",[7.2608028, 51.4648551]],
    [ "10 kV-Station Suttner-Nobel-Allee B",[7.2604761, 51.4662596]],
    [ "10 kV-Station Suttner-Nobel-Allee 3 A",[7.2583484, 51.4650946]],
    [ "10 kV-Station Sophie-Opel-Str. 20",[7.2588379, 51.4682811]],
    [ "10 kV-Station Suttner-Nobel-Allee 5",[7.2573081, 51.4654927]],
    [ "10 kV-Station Suttner-Nobel-Allee 7",[7.2571505, 51.4658163]],
    [ "10 kV-Station Staudengarten 2",[7.2909996, 51.4867227]],
    [ "10 kV-Station Arnoldschacht 25",[7.2912647, 51.4863584]],
    [ "10 kV-Station Von-Waldthausen-Str. 42A",[7.2976817, 51.4821314]],
    [ "10 kV-Station Von-Waldthausen-Str. 82A",[7.3019051, 51.4854121]],
    [ "10 kV-Station Oveneystr. 69",[7.2584203, 51.4228144]],
    [ "10 kV-Station Sophie-Opel-Str. 17",[7.2599259, 51.468473]],
    [ "Umspannst. Friedhofstr.",[9.1542397, 48.6319756]],
    [ "Umspannst. Schönbuchstr I",[9.101904, 48.6014205]],
    [ "10 kV-Station Gorch-Fock-Str. 29",[7.2731547, 51.4742439]],
    [ "10 kV-Station Suttner-Nobel-Allee 15",[7.2609545, 51.4670774]],
    [ "10 kV-Station Emscherstr. 24",[7.2121923, 51.4916582]],
    [ "10 kV-Station Oveneystr. 71A",[7.2594988, 51.4231868]],
    [ "10 kV-Station Industriestr. 51",[7.2893129, 51.4740904]],
    [ "10 kV-Station Robert-Bosch-Str. 1B",[7.2695119, 51.4682195]],
    [ "10 kV-Station Laerfeldstr. 63",[7.2764507, 51.4747197]],
    [ "10 kV-Station 6903",[7.2850781, 51.4840696]],
    [ "Kloster Dalheim",[8.842374, 51.5665964]],
    [ "10 kV-Station Ümminger Str. 84",[7.2965935, 51.4692414]],
    [ "10 kV-Station Wasserstr. 20A",[7.2449796, 51.4701772]],
    [ "10 kV-Station Im Haarmannsbusch 17",[7.2264711, 51.4375486]],
    [ "Spinelliplatz",[8.5214339, 49.4963104]],
    [ "10 kV-Station Dinnendahlstr. 9",[7.1860807, 51.4969288]],
    [ "10 kV-Station Braunsberger Str. 94 A",[7.1868869, 51.5015355]],
    [ "10 kV-Station Viktoriastr. 2B",[7.215912, 51.4810322]],
    [ "10 kV-Station Viktoriastr. 2A",[7.2159041, 51.4810588]],
    [ "10 kV-Station Hugo-Schultz-Str. 14 A",[7.2190238, 51.4704755]],
    [ "10 kV-Station Hugo-Schultz-Str. 14B",[7.2194887, 51.4705541]],
    [ "Inselweg",[8.538634, 49.385759]],
    [ "Brühlerweg km 10.8 NBS",[8.5473318, 49.4054067]],
    [ "Rheinau Bahnhof",[8.5357954, 49.4238957]],
    [ "Edinger Riedweg 47",[8.5311981, 49.4216325]],
    [ "Rohrhofer Straße 15",[8.526102, 49.4210034]],
    [ "Rohrhofer Straße 15",[8.5260851, 49.4210604]],
    [ "Kobbeln",[14.5730954, 52.0884632]],
    [ "Völklinger Straße, Baustromstation 1",[8.517145, 49.5044427]],
    [ "Hans-Thoma-Straße 15",[8.5306352, 49.473948]],
    [ "Feudenheim Endschleife, Odenwaldstraße",[8.5379252, 49.4842417]],
    [ "Feldstraße",[8.5365734, 49.4887798]],
    [ "Atzelbuckelstraße 13",[8.5499188, 49.494258]],
    [ "10 kV-Station Klausengrund 1",[7.2453564, 51.4780203]],
    [ "10 kV-Station Vödestr. 172",[7.220288, 51.5194211]],
    [ "Maifischweg",[8.5576679, 49.4848395]],
    [ "Linsenbühlweg",[8.5635355, 49.5036735]],
    [ "10 kV-Station Deimektal 54",[7.1789834, 51.4251807]],
    [ "10 kV-Station Wittener Str. 270",[7.2551806, 51.4720369]],
    [ "10 kV-Station Oberscheid str. 33",[7.2193269, 51.5151061]],
    [ "TS Schmargendorfer Weg",[13.9853677, 53.0026855]],
    [ "TS Sternfelde",[13.969548, 53.0039637]],
    [ "TS Ehm-Welk-Str.",[13.9965879, 53.0113456]],
    [ "TS Puschkinallee",[13.9965928, 53.0136456]],
    [ "TS Sportplatz",[13.9881452, 53.0164192]],
    [ "TS Gustav-Bruhn-Schule",[13.9833735, 53.0185247]],
    [ "10 kV-Station Herner Str. 463",[7.2126907, 51.5185619]],
    [ "10 kV-Station Riemker Straße",[7.210513, 51.5220996]],
    [ "10 kV-Station Kornharpenerstr. 240A",[7.2657942, 51.4883629]],
    [ "TS Pestalozzistraße",[13.9811857, 53.0176018]],
    [ "TS Am Tanger",[13.9790121, 53.0114748]],
    [ "TS Breitscheidstraße",[13.9847379, 53.012615]],
    [ "10 kV-Station Harpener Straße 3a",[7.2463229, 51.4846261]],
    [ "10 kV-Statiion Harpener Hellweg 7-9",[7.259005, 51.493734]],
    [ "10 kV-Station Harpener Hellweg 12",[7.2625483, 51.4941073]],
    [ "10 kV-Station Bergener Str.42A",[7.2384673, 51.5172395]],
    [ "10 kV-Station Bergener Str 58",[7.2410769, 51.5173375]],
    [ "10 kV-Station Eifelstr. 15",[7.2500074, 51.5125065]],
    [ "10 kV-Station Sinterstr. 2-6",[7.1917604, 51.4673913]],
    [ "10 kV-Station Bergmannstr. 41",[7.1962819, 51.5134239]],
    [ "10 kV-Station Herzogstr. 71",[7.1979544, 51.5105872]],
    [ "10 kV-Station Herzogstr. 38",[7.1957604, 51.5111799]],
    [ "10 kV-Station Herzogstr. 131",[7.1922932, 51.51138]],
    [ "Stützpunkt Schlutup",[10.7837522, 53.8862729]],
    [ "10 kV-Station 6970",[7.2754968, 51.4584107]],
    [ "10 kV-Station Waldenburger Str. 18",[7.2185459, 51.4473153]],
    [ "10 kV-Station Drohnenweg 1A",[7.2153393, 51.4401543]],
    [ "TS Aldi",[14.0139358, 53.0164031]],
    [ "10 kV-Station Hordeler Heide 112",[7.1755791, 51.4960723]],
    [ "Unterwerk Pretzier",[11.2807522, 52.8300547]],
    [ "10 kV-Station 2904",[7.2057928, 51.4920127]],
    [ "10 kV-Station Hildegardstr. 37",[7.2030383, 51.4923393]],
    [ "10 kV-Station Stat. Sodinger Str. 502/ggb.",[7.2638096, 51.5543551]],
    [ "TS Kirchgasse",[14.0026662, 53.0186221]],
    [ "10 kV-Station Amtsstraße 32",[7.1940099, 51.4904803]],
    [ "Seniorenheim",[14.0081782, 53.0176709]],
    [ "Angermünde TrSt Klosterplatz",[14.0064043, 53.0160111]],
    [ "TrSt. Mudrowweg",[14.0180199, 53.0117983]],
    [ "10 kV-Station Dorstener Str. 128A",[7.2021146, 51.4907566]],
    [ "10 kV-Station Robertstr. 44-56",[7.2045147, 51.4935966]],
    [ "10 kV-Station Gesundheitscampus-Nord 10A",[7.2455649, 51.4481366]],
    [ "10 kV-Station Gesundheitscampus-Süd 21A",[7.2475647, 51.4445971]],
    [ "10 kV-Station Goerdtstr. 38",[7.2404626, 51.4794918]],
    [ "10 kV-Station Wiescherstr. 3A",[7.2580734, 51.515728]],
    [ "10 kV-Station Dietrich-Benking-Str. 31",[7.257398, 51.5109674]],
    [ "Corneliusstraße 39",[7.6352241, 51.2165107]],
    [ "10 kV-Station Steiger-Stein-Str. 2A",[7.2550215, 51.5098533]],
    [ "10 kV-Station Am Thie 22",[7.1754217, 51.4571453]],
    [ "10 kV-Station Dietrich-Benking-Str. 78",[7.256484, 51.5078248]],
    [ "10 kV-Station Stoodtstr.",[7.2380035, 51.4638]],
    [ "10 KV Am Sinnerhoop 1",[7.3812751, 51.3341473]],
    [ "10 kV-Station Stadionring 26",[7.2334745, 51.493103]],
    [ "10 kV-Station Wilhelm Raabe Str. 15",[7.2383011, 51.4943753]],
    [ "10 kV-Station Harpener Feld 6",[7.2615891, 51.4996237]],
    [ "10 kV-Station Kohlleppelsweg 52A",[7.2843911, 51.4930834]],
    [ "10 kV-Station Am Einkaufszentrum I",[7.2845338, 51.4941663]],
    [ "10 kV-Station Gudrunstr. 2",[7.2234699, 51.4944238]],
    [ "10 kV-Station Freiligrathstr. 30A",[7.2174174, 51.4920302]],
    [ "Klosterdorf",[13.9432098, 52.5757981]],
    [ "10 kV-Station Harpener Heide 11",[7.2511046, 51.5008382]],
    [ "c",[7.2535141, 51.4950144]],
    [ "10 kV-Station Castroper-Hellweg 20",[7.2570453, 51.4970615]],
    [ "10 kV-Station Krockhausstr. 27",[7.2112155, 51.435755]],
    [ "Umspannstation",[11.7074567, 51.5907463]],
    [ "Holzhausen-09",[8.5518193, 52.2794189]],
    [ "Holzhausen-3",[8.5346893, 52.287462]],
    [ "Umspannwerk Lübeck West",[10.6369822, 53.925509]],
    [ "Umspannst. Pfefferburg",[9.0424395, 48.6659239]],
    [ "Umspannst Zeppelinstr.",[9.0548033, 48.6569641]],
    [ "Wilsum 15 Dorfstraße",[6.8537357, 52.5327114]],
    [ "Wilsum 10 Dorfstraße",[6.8417023, 52.536148]],
    [ "Romlandpark",[7.6312105, 51.2184944]],
    [ "Stegemannshof",[13.919052, 53.3400844]],
    [ "Wilsum 5 Eichenallee",[6.8551318, 52.5268845]],
    [ "Wilsum 16 Ratzeler Str.",[6.8290521, 52.5390927]],
    [ "10 kV-Station Mont-Cenis-Str./Bruchstr.",[7.2847818, 51.5427943]],
    [ "10 kV-Station Kray",[7.2838554, 51.5309258]],
    [ "10 kV-Station 3930",[7.2803575, 51.522666]],
    [ "10 kV-Station Holthauser Str. 15",[7.27943, 51.5240562]],
    [ "10 kV-Station Castroper Str. 145A",[7.2365869, 51.4911072]],
    [ "10 kV-Station Südring 23",[7.2166367, 51.4788071]],
    [ "Umspannwerk Hartershofen",[10.2436473, 49.4173398]],
    [ "Umspannwerk Zeithain-Ehrenhain",[13.3534317, 51.3454532]],
    [ "Turmstation",[8.5752497, 49.7192351]],
    [ "Umspannwerk Katenstedt",[9.8404844, 54.2127471]],
    [ "10 kV-Station In der Hönnebecke 79A",[7.1532948, 51.460026]],
    [ "10 kV-Station Einsteinweg 1",[7.145782, 51.4596222]],
    [ "10 kV-Station Elsa-Brändström-Str. 31",[7.1867845, 51.453979]],
    [ "10 kV-Station Brantropstr. 6A",[7.1911418, 51.4557357]],
    [ "10 kV-Station Bärendorfer Str. 2",[7.1892002, 51.4566755]],
    [ "10-KV Station Horstmar Wirloksbach",[7.3173542, 52.0710624]],
    [ "10 kV Anlage",[7.2242057, 51.5320097]],
    [ "Umspannwerk Adelschlag",[11.2295908, 48.8319928]],
    [ "10 KV Nirgena",[7.342592, 51.3203304]],
    [ "10 kV-Station Am Hellweg 16",[7.2725292, 51.5124278]],
    [ "10 kV-Station Handwerksweg 10",[7.2643745, 51.5104637]],
    [ "Gutach Hohweg",[8.2232258, 48.2257482]],
    [ "10 kV-Station Lothringer Allee 2A",[7.2709454, 51.5142907]],
    [ "Möning 5",[11.3287017, 49.2411647]],
    [ "10 kV-Station Glockengarten 1",[7.2317642, 51.4739802]],
    [ "Umspannwerk Hasenwinkel",[10.5143432, 52.2771683]],
    [ "10 kV-Station Dietrich-Benking-Str. 78A",[7.2557212, 51.5088936]],
    [ "10 kV-Station Kemnader Str. 340A",[7.2460977, 51.4287772]],
    [ "HS-Kabel-Schaltpunkt Trebnitz",[14.2452538, 52.5609814]],
    [ "10 kV-Station Präsidentstr. 39",[7.2074624, 51.4876874]],
    [ "Wilhelmshof",[13.75851, 53.3311355]],
    [ "10 kV-Station Geisental 5",[7.2614669, 51.4968687]],
    [ "10 kV-Station Gemeindestr. 43",[7.196699, 51.5024728]],
    [ "10 kV-Station Havelstr. 43A",[7.260669, 51.4906225]],
    [ "Umspannwerk Burladingen",[9.1444789, 48.2889602]],
    [ "Trafostation Rheinkalk Werk Flandersbach",[7.0085604, 51.2967652]],
    [ "10 kV-Station Hevener Str. 312",[7.2786052, 51.4381782]],
    [ "10 kV-Station 5974",[7.2245224, 51.4841276]],
    [ "10 kV-Station Castroper Str. 12A",[7.2245645, 51.4841392]],
    [ "UW Wulkow - Trebnitz",[14.2445586, 52.5610988]],
    [ "Umspannwerk Blankenburg",[10.9460175, 51.8088781]],
    [ "UW Seelow Nord",[14.369251, 52.5403301]],
    [ "UW Neu Werbig",[14.3689283, 52.5427864]],
    [ "Baderhof",[8.2948556, 48.2018936]],
    [ "Umspannwerk Kothau",[11.4543913, 48.7386673]],
    [ "10 kV-Station Hevener Str. 67 A",[7.2565815, 51.4320958]],
    [ "10 kV-Station Porschestr. 3",[7.1925408, 51.4868772]],
    [ "10 kV-Station Friedenstr. 9a",[7.2026707, 51.4779655]],
    [ "10 kV-Station Sodinger/von-Waldthausen-Str.",[7.2598445, 51.5605943]],
    [ "Umspannwerk Heyden",[8.9958723, 52.3792958]],
    [ "Umspannwerk Voltairestrasse",[13.4146837, 52.5179655]],
    [ "Gleichrichterwerk Hemmingen",[9.7296571, 52.3170635]],
    [ "TS Kraftverkehr",[13.9899099, 53.0053168]],
    [ "10 kV-Station Stiepeler Str. 105",[7.2411064, 51.4492233]],
    [ "10 kV-Station Oberscheidstr. 2A",[7.2148178, 51.5179491]],
    [ "Papendorf",[13.892257, 53.4981235]],
    [ "Jahnkeshof",[13.8308111, 53.4941161]],
    [ "10 kV-Station Herderallee 30",[7.2178323, 51.4937851]],
    [ "UW Strasburg-Ludwigsthal",[13.7552389, 53.4926589]],
    [ "Holzen",[7.5370335, 51.4383335]],
    [ "Umspannwerk Pasewalk",[14.03775, 53.5078188]],
    [ "Umspannwerk Pasewalk",[14.0363055, 53.5087515]],
    [ "Biblis Gasturbinenkraftwerk",[8.4152792, 49.7058962]],
    [ "Umspannwerk Rehren",[9.2298708, 52.2341118]],
    [ "Anna-Pfund-Straße",[7.8907969, 48.342634]],
    [ "Umspannwerk Roderbruch (Neu)",[9.7970476, 52.3943932]],
    [ "10 kV-Station Teylestr. 12A",[7.2297749, 51.4886508]],
    [ "Am Hohlberg",[8.4492851, 50.173499]],
    [ "Windeckstraße",[8.4829684, 49.4693899]],
    [ "UW Kamp",[9.9218914, 54.1446568]],
    [ "Umspannanlage Seelbach",[7.9349599, 48.3193334]],
    [ "S-Bahn Gleichrichterunterwerk Treptower Park",[13.4618289, 52.4943595]],
    [ "Pumpwerk",[7.9364172, 48.3331281]],
    [ "Litschental",[7.9097612, 48.2900699]],
    [ "Pillgram Schinderberg",[14.4122259, 52.3320586]],
    [ "Lingelbach",[9.3548628, 50.7495758]],
    [ "Berfa",[9.3733711, 50.7874575]],
    [ "10 kV-Station Castroper Hellweg 49A",[7.2574783, 51.4988445]],
    [ "Hattorf",[9.9782699, 50.8426463]],
    [ "Baumgarten West",[13.9325074, 53.343528]],
    [ "Unterwerk Zapfendorf",[10.9426591, 50.0253953]],
    [ "10 kV-Station Zechenstr. 8",[7.2078605, 51.489897]],
    [ "Biblis C",[8.4141168, 49.7111993]],
    [ "Unterwerk Drohne",[8.3288598, 52.425285]],
    [ "10 kV-Station Brandschacht-Straße 17",[7.3661952, 51.4908934]],
    [ "10 kV-Station Wanner Str. 13",[7.1984946, 51.4919605]],
    [ "Stützpunkt Farchau",[10.765302, 53.6798118]],
    [ "Deezbüll Schützenring 46",[8.8230436, 54.7765385]],
    [ "10 kV-Station Richardstr. 40",[7.2031538, 51.4901441]],
    [ "Ovelgönne",[8.4051527, 53.341411]],
    [ "Aßlar 380kV",[8.4795859, 50.5912887]],
    [ "Dillenburg 380kV",[8.2842513, 50.7591957]],
    [ "Gustav-Seitz-Straße 28",[8.5107143, 49.4651431]],
    [ "Großkrotzenburg",[8.9561146, 50.0871102]],
    [ "Borken 110kV",[9.2606991, 51.0594151]],
    [ "Allendorf/Winter",[9.003942, 50.8214522]],
    [ "Umspannwerk Fulda/Nord",[9.6563779, 50.577392]],
    [ "Philippsthal",[9.9408757, 50.8519356]],
    [ "Motzfeld",[9.9173073, 50.8518109]],
    [ "Luisenstraße",[7.9391689, 48.3085951]],
    [ "Julabo",[7.936715, 48.3151996]],
    [ "Trendelburg",[9.3841724, 51.5698305]],
    [ "Umspannwerk Calau Ost",[13.9742809, 51.7664624]],
    [ "Vogel/Übergabe",[7.7403148, 48.3897424]],
    [ "UA Baersdonk",[6.3191094, 51.5033373]],
    [ "10 kV-Station Gahlensche Str. 100",[7.1939147, 51.4883745]],
    [ "10 kV-Station Gahlensche Str. 204 B",[7.1885706, 51.4942542]],
    [ "10 kV-Station Kohlenstr. 53",[7.195827, 51.4661544]],
    [ "10 kV-Station Von der Recke Str 51A",[7.1882779, 51.4909905]],
    [ "Würgassen 110 kV",[9.3860111, 51.6420571]],
    [ "UW Würgassen",[9.3855991, 51.6421156]],
    [ "10 kV-Station Blumenau 94a",[7.2687106, 51.4318169]],
    [ "Merzen",[7.8444178, 52.4592311]],
    [ "Gleichrichterunterwerk Schlachthofstraße",[12.4886717, 50.7359989]],
    [ "Gleichrichterunterwerk 4a Paulusstraße",[12.4545335, 50.7187781]],
    [ "Gleichrichterunterwerk 4b Virchowplatz",[12.4399658, 50.7195154]],
    [ "Station Oberzier",[6.4563162, 50.8698311]],
    [ "10 KV Müller & Sohn SK",[7.2811907, 51.3197493]],
    [ "10 kV-Station Hofsteder Str. 202A",[7.2055761, 51.5005921]],
    [ "Mürow Oberdorf 2",[14.0532584, 53.0563762]],
    [ "10 kV-Station 1943",[7.1792885, 51.4970949]],
    [ "10 kV-Station Dorstener Str. 321",[7.1884783, 51.5006511]],
    [ "10 kV-Station Hüller-Bach-Str. 30",[7.1667672, 51.4985794]],
    [ "10 kV-Station Dinnendahlstr. 2A",[7.1883101, 51.4967863]],
    [ "Bentwisch HGÜ Kurzkupplung",[12.2145226, 54.1004861]],
    [ "10 kV-Station Wilhelm-Morfeld-Str. 20",[7.1794059, 51.4980723]],
    [ "N6,7",[8.4693827, 49.484663]],
    [ "Schaltposten Hagenow Land",[11.2111773, 53.4114489]],
    [ "10 kV-Station Hordeler Str. 55A",[7.1849117, 51.505944]],
    [ "10 kV-Station Insterburger Str. 1",[7.1852844, 51.5046082]],
    [ "10 kV-Station Magdeburger Str. 86",[7.1710376, 51.5051449]],
    [ "10 kV-Station Magdeburger Str. 1",[7.1815175, 51.5073087]],
    [ "Umspannwerk Kopernikus",[6.7895192, 51.5049129]],
    [ "10 kV-Station Kesterkamp 42B",[7.1567866, 51.4235825]],
    [ "10 kV-Station Auf dem Sporkel 61",[7.2905813, 51.4944705]],
    [ "10 kV-Station Lütkendorpweg 35",[7.2833524, 51.4981676]],
    [ "Gottlieb-Daimler-Straße",[8.4951594, 49.4757513]],
    [ "Josef-Braun-Ufer",[8.4936935, 49.4865984]],
    [ "Galileistraße / Keplerstraße",[8.4747075, 49.4792811]],
    [ "Heinrich-von-Stephan-Straße",[8.4736858, 49.4775904]],
    [ "10 KV Carl-vom-Hagen-Straße 2",[7.2824177, 51.2886297]],
    [ "10 kV-Station Robert-Bosch-Str. 2",[7.2682676, 51.4675205]],
    [ "Kippenheim Bürgerhaus",[7.8251863, 48.2989858]],
    [ "10 kV-Station Kemnader Str. 127A",[7.225918, 51.436084]],
    [ "10 kV-Station Sonnenleite 17A",[7.3011609, 51.4642728]],
    [ "10 kV-Station Hustadtring 28",[7.2750473, 51.4596347]],
    [ "10 kV-Station Gerad-Mortier-Platz 5",[7.2008617, 51.4799523]],
    [ "Graßmannstraße",[8.519181, 49.4356496]],
    [ "Zechenstraße",[8.5164354, 49.433614]],
    [ "Zechenstraße 1-3",[8.5164094, 49.4335497]],
    [ "Schwerpunktstation Graßmannstraße",[8.5179747, 49.434856]],
    [ "Bohnenbergerstraße 17",[8.514075, 49.4543326]],
    [ "Casterfeldstraße 40",[8.5004486, 49.4501064]],
    [ "Altriper Straße 25",[8.5046603, 49.4451728]],
    [ "Altriper Straße 37",[8.5053375, 49.4434441]],
    [ "Mundenheimer Straße 65",[8.5115068, 49.4425873]],
    [ "10 KV Schwelmer Straße 145",[7.2603951, 51.2765756]],
    [ "DolWin 5 HVDC Konverter Station",[7.2419376, 53.3557928]],
    [ "BorWin 3 HVDC Konverter Station",[7.2462901, 53.3560786]],
    [ "DolWin 6 HVDC Konverter Station",[7.2468021, 53.3579452]],
    [ "Conneforde 220/110 kV",[8.0510687, 53.3334728]],
    [ "Fedderwarden",[8.0614371, 53.5542595]],
    [ "HelWin 1 HVDC Umspannwerk Büttel",[9.2363464, 53.9166435]],
    [ "SylWin 1 HVDC Umspannwerk Büttel",[9.2363464, 53.9166435]],
    [ "HelWin 2 HVDC Umspannwerk Büttel",[9.2348055, 53.9164579]],
    [ "Diele",[7.3104368, 53.1246945]],
    [ "HGÜ BorWin1 Umspannwerk Diele",[7.3086923, 53.1247705]],
    [ "HGÜ BorWin2 Umspannwerk Diele",[7.3090118, 53.126045]],
    [ "HGÜ DolWin2 Umspannwerk Dörpen West",[7.2548726, 52.9831349]],
    [ "HGÜ DolWin1 Umspannwerk Dörpen West",[7.2564905, 52.9829808]],
    [ "10 KV",[7.3941356, 51.3400552]],
    [ "10 KV",[7.3958441, 51.3402486]],
    [ "10 KV",[7.3951928, 51.3394055]],
    [ "10 kV-Station An der Maarbrücke 75",[7.1857205, 51.4820114]],
    [ "Umspannwerk Marzahn",[13.5236386, 52.5354348]],
    [ "L‘oog Wasserwerk",[7.5042503, 53.7506567]],
    [ "10 kV-Station Laerheidestr. 4",[7.2471493, 51.4526476]],
    [ "Rheinvorlandstraße 10",[8.4560797, 49.4847642]],
    [ "Hafenstraße",[8.4581153, 49.4972261]],
    [ "Neckarvorlandstraße 64",[8.4560317, 49.4993087]],
    [ "Neckarvorlandstraße 99",[8.4443241, 49.5046441]],
    [ "Werfthallenstraße 39-41",[8.4416305, 49.505831]],
    [ "Fleig",[7.9053683, 48.3372247]],
    [ "Müller Meier",[7.929982, 48.3259841]],
    [ "10 kV-Station Konrad-Zuse-Str. 18A",[7.2757122, 51.4478422]],
    [ "10 KV Beyer & Klophaus",[7.2938291, 51.29621]],
    [ "10 KV Jobcenter Schwelm",[7.2921467, 51.2959821]],
    [ "10 KV Am Kaiser",[7.3955732, 51.2973745]],
    [ "Umspannstation Schotterey/Nord",[11.8341653, 51.4210268]],
    [ "10 KV Rosendahler Straße 2",[7.3202288, 51.3161736]],
    [ "Röbel Wind",[12.5822673, 53.3732952]],
    [ "10 KV Plessen",[7.4049421, 51.3002964]],
    [ "Landzungenstraße 5",[8.4525851, 49.4975197]],
    [ "Verbindungskanal linkes Ufer 38-42",[8.4546288, 49.4978901]],
    [ "Ludwigsbadstraße",[8.4538676, 49.4884287]],
    [ "10 kV-Station 8962",[7.2202651, 51.4334117]],
    [ "10 kV-Station Am Varenholt 88a",[7.2189702, 51.4302303]],
    [ "10 kV-Station Am Varenholt 23",[7.22728, 51.4321438]],
    [ "10 kV-Station Am Varenholt 9",[7.2284306, 51.4321335]],
    [ "10 kV-Station Am Varenholt 125A",[7.2143557, 51.427722]],
    [ "10 kV-Station Am Varenholt 123",[7.2144143, 51.4276698]],
    [ "10 kV-Station Am Varenholt 123A",[7.2144022, 51.4276499]],
    [ "10 kV-Station Kemnader Str. 198A",[7.2309546, 51.4318902]],
    [ "Britz",[13.8242294, 52.8860296]],
    [ "Möhlstraße",[8.4880433, 49.4751985]],
    [ "Gottlieb-Daimler-Straße",[8.4905525, 49.4716532]],
    [ "Gottlieb-Daimler-Straße",[8.4904623, 49.4716364]],
    [ "Fritz-Huber-Straße",[8.4898097, 49.471168]],
    [ "Moltkestraße",[8.4766297, 49.4812832]],
    [ "10-kV-Station Weg zum Stadtwald",[7.1718537, 51.3864566]],
    [ "10-kV-Station Gasstraße",[7.1720442, 51.398291]],
    [ "10-kV-Station Eickener Straße",[7.1744317, 51.3975651]],
    [ "Umspannwerk Echtorf",[9.0860417, 52.2920494]],
    [ "Erdbeerweg 1",[8.6535846, 49.5287661]],
    [ "Gerold 12",[11.2158733, 47.4898088]],
    [ "Lützelgässel",[8.662267, 49.5276155]],
    [ "Umspannwerk Krusemark",[11.9747545, 52.7334254]],
    [ "Ellingen West",[11.9742026, 52.709037]],
    [ "Ranstädter Weg",[8.9725252, 50.3511445]],
    [ "10 KV Bahnhofstraße 2",[7.3471236, 51.3205261]],
    [ "Theodor-Heuss-Anlage",[8.5010007, 49.4782424]],
    [ "Hermsheimer Bösfeld 4",[8.5176485, 49.4607589]],
    [ "Franz-Conrad-Linck-Straße 6",[8.5088565, 49.4640682]],
    [ "Reiterweg 28",[8.5024165, 49.466431]],
    [ "Großmarkt Mannheim",[8.4927707, 49.4728387]],
    [ "Tramplerstraße",[7.8526217, 48.3345756]],
    [ "UW Seelow Deponie",[14.3733468, 52.5518068]],
    [ "10 kV-Station Ostring 15",[7.2247887, 51.4829796]],
    [ "10 kV-Station Bürkle de la Camp-Platz 1A",[7.2121702, 51.467396]],
    [ "10-kV-Station Bruchstraße",[7.180334, 51.3948178]],
    [ "10-kV-Station Meininghausen",[7.3587873, 51.3138062]],
    [ "Bachwiesenstraße",[8.6527071, 49.5267771]],
    [ "10 kV-Station Phillippstr.3A",[7.2384352, 51.4628941]],
    [ "Am Sonnenbrink Nr. 24",[9.1883347, 52.3276463]],
    [ "Dornbachweg",[8.6679298, 49.5322056]],
    [ "Horrackerstraße",[8.6644569, 49.5327847]],
    [ "10-kV-Station Droste Hülshoff Straße",[7.1781258, 51.3923445]],
    [ "Valentin-Streuber-Straße",[8.4852494, 49.4661584]],
    [ "10 kV-Station Feldmark 98",[7.2632578, 51.4776039]],
    [ "TrSt",[13.3739226, 54.088104]],
    [ "10-kV-Station Lärchenweg",[7.1938223, 51.396702]],
    [ "10-kV-Station Reuterstraße",[7.1839146, 51.3944572]],
    [ "10 kV-Station Arndtstr.12",[7.2230823, 51.4806276]],
    [ "10-kV-Station Brinkmann",[7.1886298, 51.3962251]],
    [ "Umspannwerk Fellbach West",[9.2575341, 48.8121536]],
    [ "10 kV-Station Castroper Str. 150A",[7.2367172, 51.488942]],
    [ "10 kV-Station Gersteinring 52A",[7.2360442, 51.486494]],
    [ "Umspannwerk Ramstein-Miesenbach",[7.5313756, 49.4437596]],
    [ "Umspannwerk Schacht",[9.7156868, 54.30226]],
    [ "Trafostation WSG",[9.1543303, 49.3653157]],
    [ "Trafostation Bonschel",[9.154454, 49.3659412]],
    [ "Trafostation JBS",[9.1571066, 49.365699]],
    [ "Trafostation WfbM Sportplatz",[9.1515507, 49.3649499]],
    [ "10 kV-Station Universitätsstr. 110",[7.2340153, 51.4607994]],
    [ "Umspannwerk Geisling",[12.3443767, 48.9737318]],
    [ "10 kV-Station Harpener Feld 24",[7.2589435, 51.5002889]],
    [ "Umspannwerk TransnetBW",[9.1513184, 49.1430324]],
    [ "Schaltanlage Netze BW",[9.1492299, 49.1422143]],
    [ "Umspannwerk Aldingen",[9.2610044, 48.8693445]],
    [ "10 KV",[7.5809058, 51.3635423]],
    [ "Lindenstraße",[9.1458095, 50.1546485]],
    [ "Umspannwerk Ulzburg",[9.9487221, 53.7715996]],
    [ "Umspannwerk Göhl West",[10.9305844, 54.2839738]],
    [ "10 kV-Station Kaltehardstr. 61",[7.3095726, 51.4675177]],
    [ "10kV-Station-Dieselstr. 41-47",[8.2242618, 51.8817322]],
    [ "Umspannwerk Reisgang",[11.5100721, 48.5088421]],
    [ "10 kV-Station Albert-Schweizer-Str.26",[7.2744408, 51.4570917]],
    [ "10 kV-Station Hustadtring 31",[7.2735189, 51.4589832]],
    [ "Joh. Seb. Bach Str.",[7.8608904, 48.3329387]],
    [ "Mörsch Sonnenstr.",[8.2896905, 48.9649556]],
    [ "10 kV-Station Robert-Koch-Straße 17",[7.2708809, 51.4560108]],
    [ "Bremervörde Alte Straße 4 / Parkplatz",[9.1470569, 53.4853709]],
    [ "Hambergen Bremer Straße",[8.8346516, 53.2964082]],
    [ "Trafostation Erntefestplatz",[8.806188, 53.2247855]],
    [ "Vollersode-Wallhöfen Am Funkturm",[8.8693321, 53.3206808]],
    [ "Hambergen Sandstraße",[8.8290266, 53.2824777]],
    [ "Schaltstation Leopoldshafen Viermorgen",[8.4039427, 49.089235]],
    [ "DEL Elbinger Straße",[8.6336115, 53.0358478]],
    [ "DEL Am Tierpark",[8.6315847, 53.0382274]],
    [ "Görlitzer Straße",[8.6311567, 53.0336233]],
    [ "DEL Yorckstraße",[8.6279443, 53.0205558]],
    [ "Gneisenauweg",[8.6289177, 53.0247462]],
    [ "Annenheide Burk",[8.6419922, 53.020864]],
    [ "Delmenhorst Annenheider Straße",[8.6464591, 53.0224864]],
    [ "DEL Annenweg Löschteich",[8.646222, 53.0187565]],
    [ "DEL Brendelweg Störmer",[8.6395559, 53.0233695]],
    [ "Jütlandstraße",[8.6376592, 53.0254153]],
    [ "Düsternortstraße",[8.6400554, 53.03262]],
    [ "Bungerhof Schule",[8.6429728, 53.0745839]],
    [ "Am Sassengraben",[8.6353233, 53.0673418]],
    [ "HANSA NORD",[8.6306105, 53.0617343]],
    [ "DEL Lessingstraße Nr. 73",[8.6282158, 53.0662371]],
    [ "Friedrich-Ebert-Allee",[8.6371078, 53.0462833]],
    [ "Dehmelstraße",[8.6370591, 53.0707422]],
    [ "ISA Cramerstraße",[8.6376396, 53.0439117]],
    [ "25881",[8.6376272, 53.0439339]],
    [ "DEL Am Moorkamp",[8.6271231, 53.0357318]],
    [ "DEL Schule Adelheide",[8.6136332, 53.0237678]],
    [ "DEL Im Delmegrund 19",[8.618325, 53.0332568]],
    [ "Brendelweg",[8.6228642, 53.0324286]],
    [ "DEL Försterstraße",[8.629794, 53.0288067]],
    [ "Rethkampplatz U14",[8.6280871, 53.0279648]],
    [ "DEL Schulzentrum Süd",[8.6276064, 53.0275054]],
    [ "DEL Feldhusstraße",[8.6516016, 53.0179814]],
    [ "DEL Steller Straße Hoch-Tief",[8.6578758, 53.0160451]],
    [ "Del. Steller Straße Müllsortieranlage",[8.6576784, 53.0161432]],
    [ "DEL Steller Straße Kunststoffwerk",[8.6564606, 53.0168162]],
    [ "DEL Steller Straße Kunststoffwerk",[8.6568126, 53.0166409]],
    [ "Hasport ISA",[8.6611374, 53.0276924]],
    [ "DEL Kastanienplatz",[8.6535874, 53.0592253]],
    [ "DEL Eibenstraße",[8.6536389, 53.0657191]],
    [ "Hasbergen Delmestraße",[8.6581707, 53.0735115]],
    [ "Nordenhamer Straße 142",[8.6485608, 53.0625172]],
    [ "Birkenstraße",[8.6414595, 53.060551]],
    [ "Arthur-Fitger-Straße",[8.6340952, 53.0459921]],
    [ "DEL Asternstraße",[8.6505673, 53.0534169]],
    [ "Friedensstraße",[8.6258515, 53.0729049]],
    [ "Dwostraße",[8.6230063, 53.0652761]],
    [ "DEL Wilhelm-Raabe-Straße",[8.6190566, 53.0640807]],
    [ "Dwostraße",[8.6176758, 53.0621322]],
    [ "DEL Schönemoorer Straße",[8.6271086, 53.0623694]],
    [ "DEL Hasporter Damm Bahnhof",[8.6456738, 53.0380635]],
    [ "Trafostation Bahnhof Wustermark",[12.9781083, 52.5533866]],
    [ "Trafostation",[8.7555192, 53.1833677]],
    [ "Leuchtenburg Albrechts Park",[8.6505365, 53.1915694]],
    [ "Haus Flemming",[11.187759, 51.8002457]],
    [ "Uhlhorn Depot",[8.4936034, 52.968897]],
    [ "Gutach Schule",[8.2129743, 48.2470756]],
    [ "Gutach Friedhof",[8.2147229, 48.2490116]],
    [ "Oldermannshof",[8.6212762, 52.0120118]],
    [ "Pfauenweg",[7.9218527, 48.4986623]],
    [ "Westenfeld-Mülldeponie",[7.4234622, 52.0657288]],
    [ "Schrank EGST",[7.426208, 52.0641024]],
    [ "Gutach Pfaffenbach 10",[8.2060828, 48.2493312]],
    [ "Gutach Aberle F 26",[8.2101306, 48.2533604]],
    [ "Gutach Reutter",[8.2110892, 48.2533133]],
    [ "Neu St. Jürgen Jan-Weber-Straße",[8.9573173, 53.2587903]],
    [ "Buchtstraße 54/Mühlenstelle",[8.7648834, 53.1999422]],
    [ "Station C Unterwerk 20/0,4 KV",[9.3585574, 53.1085942]],
    [ "Johanniskirchstraße 63",[7.0001218, 51.4993076]],
    [ "Rhedebrügge Niehaves",[6.7622709, 51.8223857]],
    [ "In der Grub 1",[8.2019334, 48.2462607]],
    [ "Trafostation",[8.7895894, 53.2237933]],
    [ "Langenalb Marxzeller Straße",[8.4957599, 48.843398]],
    [ "Umspannwerk Arendsweg",[13.5113267, 52.5308731]],
    [ "Leimitzer Straße (bei UWO)",[11.939282, 50.3198602]],
    [ "Wildenlohslinie 30",[8.1036541, 53.132368]],
    [ "Alemannenweg",[10.3082891, 47.7159036]],
    [ "Keselstraße",[10.321634, 47.7179072]],
    [ "Gerhardingerweg",[10.3386602, 47.7297305]],
    [ "Hehle",[10.3348351, 47.7083371]],
    [ "Cambodunumweg",[10.3250772, 47.7281961]],
    [ "Keckwiese",[10.3253273, 47.7315096]],
    [ "Bühl Ost",[10.3355971, 47.73621]],
    [ "Dürkheimer Weg",[10.3332024, 47.7360757]],
    [ "Peter-Dörfler-Straße",[10.3226548, 47.7372881]],
    [ "Zellerstraße",[7.9504415, 48.4734427]],
    [ "Wasserwerk",[10.3218239, 47.7266037]],
    [ "Rothkreuz",[10.2753311, 47.7113843]],
    [ "Leutkircher Straße",[10.2873162, 47.7170392]],
    [ "Wiesberg",[10.3155243, 47.718158]],
    [ "St.-Denis-Straße",[7.9126353, 48.4857817]],
    [ "Römerstraße",[7.9186897, 48.4884512]],
    [ "In der Spöck",[7.9223406, 48.4809741]],
    [ "Henstedt Maschloh",[10.0218239, 53.78005]],
    [ "Sev. Cappelner Str. 18a",[8.064337, 52.8102015]],
    [ "Baywa",[10.3198639, 47.7493655]],
    [ "Hafenthal",[10.3122308, 47.7648124]],
    [ "Schraudystraße",[10.2959484, 47.7181038]],
    [ "Bachen",[10.3419455, 47.7434768]],
    [ "Steig",[10.3204376, 47.7645279]],
    [ "Keck",[10.3240832, 47.7334907]],
    [ "Wyk Achtern Diek 005",[8.5730794, 54.6939295]],
    [ "Rudolf-Diesel-Straße",[10.3176191, 47.7583382]],
    [ "Schellenbergstraße",[10.3100895, 47.7135576]],
    [ "Ellharten",[10.302542, 47.7154071]],
    [ "Lessingstraße",[10.3058762, 47.7210186]],
    [ "Weilerstraße Nord",[10.2712103, 47.4653234]],
    [ "Fürstenstraße",[10.3087532, 47.7304299]],
    [ "Schnetzerpark",[10.3116431, 47.7213155]],
    [ "Schwabenweg",[10.313776, 47.717901]],
    [ "Gerhart-Hauptmann-Straße",[10.3130874, 47.7330608]],
    [ "Bachstelzenweg",[10.2930648, 47.7388639]],
    [ "Schwalbenweg",[10.286682, 47.7387457]],
    [ "Floßerhäusle",[10.3073773, 47.7404611]],
    [ "Scharfrichter",[10.3098029, 47.7393065]],
    [ "Schlößleweg",[10.316904, 47.7322493]],
    [ "Johannisbrücke",[10.3087003, 47.7369631]],
    [ "Königsberger Straße",[10.3022767, 47.7371094]],
    [ "Halde - Süd",[10.2989753, 47.7424531]],
    [ "Isnyer Straße",[10.2862506, 47.7147806]],
    [ "Buchenberger Weg",[10.2788026, 47.7120085]],
    [ "Im Stiftallmey",[10.2827815, 47.7140637]],
    [ "Buchauer Weg",[10.2830842, 47.7154024]],
    [ "Georg-Haindl-Straße",[10.3176891, 47.6929916]],
    [ "Krokusweg",[10.2995275, 47.7226834]],
    [ "Röntgenweg",[10.3035176, 47.7304319]],
    [ "Bremervörde Brunnenstraße 4 / VB-Parkplatz",[9.14434, 53.4862487]],
    [ "Neuhausen Süd",[10.2916492, 47.7512156]],
    [ "Zollhaus bei Hirschdorf",[10.2949357, 47.7581531]],
    [ "Ajen",[10.3050999, 47.7634296]],
    [ "Schumacherring",[10.3300402, 47.7267497]],
    [ "Gleichrichterwerk 602 Warschauer Platz",[13.4482587, 52.5037243]],
    [ "U-Bahn Gleichrichter Warschauer Brücke",[13.4485698, 52.5041618]],
    [ "Eichbergweg",[10.321081, 47.7534297]],
    [ "Messerschmittstraße",[10.3190796, 47.7553485]],
    [ "Ursulasried Nord",[10.3177116, 47.7632081]],
    [ "Feldweg",[10.3355864, 47.7629432]],
    [ "Hangenberg",[10.3254549, 47.7549841]],
    [ "Kremserstraße",[10.3241963, 47.7021758]],
    [ "Ermengerst - Mariabergstraße",[10.2576275, 47.7254413]],
    [ "Sulzberger Straße",[10.3233455, 47.6995877]],
    [ "Trafostation",[8.7903385, 53.2329937]],
    [ "Walkmühlenstraße",[9.1703897, 53.484558]],
    [ "Weinsteige",[8.1838218, 48.7353665]],
    [ "Grasberg Heckenweg",[8.9898781, 53.1830077]],
    [ "Rothalde",[8.2121855, 48.2335362]],
    [ "Waakhausen Feuerwehr",[8.8570353, 53.1991724]],
    [ "Waakhausen Viehland",[8.8413285, 53.194217]],
    [ "Worphausen Kleine Wende",[8.9400063, 53.1943729]],
    [ "Worpswede Osterholzer Straße",[8.8987431, 53.2137428]],
    [ "Waakhausen Semkenfahrt",[8.8816176, 53.2073676]],
    [ "Sankt Jürgen Moorhausen",[8.8216304, 53.1906721]],
    [ "Trafo-Station Extra-Markt",[8.7980833, 53.2241272]],
    [ "Lüninghauser Straße Nr. 114a",[8.9195462, 53.1864451]],
    [ "Sankt Jürgen Mittelbauer",[8.8444423, 53.1841549]],
    [ "Waakhausen Waakhauserstraße",[8.8660878, 53.2022251]],
    [ "Frankenburg Hauptstraße",[8.9002399, 53.17287]],
    [ "Trafostation Scharmbeckstoteler Straße 39",[8.7510827, 53.2085497]],
    [ "Sankt Jürgen Niederende",[8.8122425, 53.1860049]],
    [ "Trafostation Scharmbeckstoteler Straße 124a",[8.7509512, 53.2151291]],
    [ "Trafostation Westerbeck",[8.7792785, 53.2459313]],
    [ "OHZ Tietjens Hütte",[8.819948, 53.2039343]],
    [ "Trafostation Hördorfer Weg",[8.753551, 53.2335572]],
    [ "Trafostation Halenbeck",[8.7596337, 53.2258261]],
    [ "Freißenbüttel Kattenhorner Weg",[8.7843694, 53.2535895]],
    [ "OHZ-Westerbeck Lange Reihe",[8.7792355, 53.2458928]],
    [ "Trafostation An der Handloge",[8.7856455, 53.2228428]],
    [ "Trafostation Gymnasium",[8.7862222, 53.2268347]],
    [ "Trafostation Hinter der Wurth",[8.7870832, 53.2290585]],
    [ "Trafostation Am Wasserturm",[8.7887785, 53.2315437]],
    [ "Trafostation Barkhof",[8.7890329, 53.2264557]],
    [ "Trafostation Am Weißen Sande",[8.7905267, 53.2275445]],
    [ "Trafostation Haus am Markt",[8.7871365, 53.2282628]],
    [ "Trafostation Katrepel",[8.7830518, 53.2302949]],
    [ "Trafostation Knorrenkamp",[8.7971679, 53.2366058]],
    [ "Trafostation Pappstraße",[8.7969748, 53.2250203]],
    [ "Trafostation Käthe-Kollwitz-Straße",[8.7917874, 53.2378707]],
    [ "Trafostation Am Osterholze",[8.7988551, 53.2335106]],
    [ "Trafostation Unter den Linden",[8.8168794, 53.2483917]],
    [ "Trafostation Rathaus",[8.7951026, 53.2279908]],
    [ "Trafostation Siemensstraße",[8.8125742, 53.2513627]],
    [ "Trafostation Im Dorfe",[8.8113454, 53.2446157]],
    [ "Trafostation Amtslinde",[8.8125799, 53.2253542]],
    [ "Trafostation Albert-Schweitzer-Straße",[8.8102544, 53.2338286]],
    [ "Trafostation Myhle",[8.8145703, 53.2561985]],
    [ "Trafostation Zum Heuweg",[8.8141671, 53.2598223]],
    [ "Trafostation Mensingstraße",[8.7689033, 53.2301761]],
    [ "Trafostation Dobben",[8.7869056, 53.2328556]],
    [ "Trafostation Sandbeckstraße",[8.7809084, 53.2349987]],
    [ "Trafostation Amselstraße",[8.7773895, 53.2290059]],
    [ "Lützowstraße 3",[7.0187622, 51.465417]],
    [ "Beisingstraße 22",[7.016605, 51.4654589]],
    [ "Beisingstraße 13",[7.0153371, 51.465611]],
    [ "Beisingstraße 10",[7.0144608, 51.4654486]],
    [ "Trafostation Wienbeck",[8.7841053, 53.2156391]],
    [ "Trafostation Dresdener Straße",[8.7824162, 53.217352]],
    [ "Trafostation Bremer Straße 43",[8.7944933, 53.2180457]],
    [ "Trafostation Lange Straße 89",[8.7920289, 53.2184618]],
    [ "Trafostation Mozartstraße I",[8.7793853, 53.2182288]],
    [ "Trafostation Theresienstraße",[8.7896528, 53.2163529]],
    [ "Trafostation Horster Feld",[8.8151066, 53.2400619]],
    [ "Trafostation W.R.P.",[8.8109489, 53.2246253]],
    [ "Trafostation Kantstraße",[8.8120647, 53.2295067]],
    [ "OSS Baltic 1",[12.63, 54.6128083]],
    [ "OHZ-Hülseberg Dorfstraße",[8.7602777, 53.2730663]],
    [ "Hambergen Grüne Grund",[8.8262155, 53.286204]],
    [ "Hambergen Am Jantzen Park",[8.8174179, 53.291245]],
    [ "OHZ-Ohlenstedt Im Pesch",[8.7615988, 53.3073363]],
    [ "Hambergen Papenbüttel",[8.8181176, 53.2942628]],
    [ "OHZ-Ohlenstedt Alt Ohlenstedt",[8.757338, 53.299748]],
    [ "Freißenbüttel An der Wassermühle",[8.7898159, 53.2628181]],
    [ "OHZ-Ohlenstedt Vorwohlde",[8.7872896, 53.2955259]],
    [ "Vollersode-Wallhöfen Denkmal",[8.8797456, 53.3190362]],
    [ "Hambergen Bahn",[8.8040605, 53.2909885]],
    [ "OHZ-Hülseberg Hagenberg",[8.7563585, 53.2803251]],
    [ "Hambergen Ackerkamp",[8.8345626, 53.3090698]],
    [ "Hambergen Im Bruch",[8.8154502, 53.2879847]],
    [ "Trafostation Mozartstraße II",[8.7793742, 53.2222069]],
    [ "Trafostation Am Pumpelberg I",[8.7766602, 53.2313816]],
    [ "Trafo-Station Lidl-Markt",[8.776848, 53.2327318]],
    [ "Trafostation Auf der Heide 10",[8.7693673, 53.2083122]],
    [ "Trafostation Brockenacker 52",[8.759596, 53.2063057]],
    [ "Trafostation Auf der Heide 25-27",[8.7662373, 53.2073371]],
    [ "Trafostation Hauptstraße 52",[8.7564766, 53.2035767]],
    [ "Sandhausen Altendamm",[8.8413218, 53.2547579]],
    [ "Freißenbüttel Am Schafkamp",[8.7886969, 53.2586217]],
    [ "Trafostation Hinterm Mühlenberg",[8.8129633, 53.2628297]],
    [ "Trafostation Hinter dem Gartel",[8.7644507, 53.2266842]],
    [ "Sandhausen Sandhausener Straße",[8.8220528, 53.2616063]],
    [ "Trafostation Am Binnenfeld",[8.8147445, 53.2347228]],
    [ "Trafostation Bargten",[8.7679433, 53.2335173]],
    [ "Hambergen Gartenstraße",[8.819294, 53.2729677]],
    [ "Sandhausen Hinterm Torfwerk",[8.8203108, 53.266314]],
    [ "Trafostation Wiste",[8.7982348, 53.2446031]],
    [ "Trafostation Sandhausener Ring",[8.8136463, 53.2658825]],
    [ "Trafostation Neuendammer Straße / Feuerwehr",[8.8151006, 53.2428888]],
    [ "Hambergen Heidreeg",[8.8241337, 53.2745728]],
    [ "Trafostation Beermann / Bahn",[8.8021917, 53.2384858]],
    [ "Teufelsmoor Hamme",[8.9153462, 53.2644133]],
    [ "Trafostation Stubbenkuhle",[8.8199931, 53.2497194]],
    [ "Teufelsmoor Teufelsmoorstraße",[8.9028513, 53.2650968]],
    [ "Teufelsmoor Teufelsmoorstraße 11",[8.8787737, 53.2535445]],
    [ "Heilshorn Alt Heilshorn",[8.7183115, 53.2374212]],
    [ "Heilshorn Industriepark 1",[8.6988387, 53.2293176]],
    [ "Brundorf Gewerbegebiet",[8.6926835, 53.2303441]],
    [ "Trafostation Vrampe",[8.7645257, 53.2322342]],
    [ "Heilshorn Feldhorst",[8.7315749, 53.2308504]],
    [ "Trafostation Krankenhaus I",[8.7943641, 53.2340994]],
    [ "Trafostation Im Steenesch",[8.790237, 53.2212031]],
    [ "Trafostation Am Acker",[8.7499105, 53.2020934]],
    [ "Trafostation Am Fuhrenkamp 20",[8.749484, 53.200498]],
    [ "Trafostation Am Fuhrenkamp 58",[8.74972, 53.2012371]],
    [ "Trafostation Am Rosenmoor",[8.7661616, 53.2020629]],
    [ "Trafostation Werschenreger Straße 90",[8.7466811, 53.2055555]],
    [ "Trafostation Oberstacker 19",[8.7609044, 53.2055315]],
    [ "Schule",[10.3463898, 47.6978884]],
    [ "Westerbeck Militär Anlage",[8.7580077, 53.2483233]],
    [ "Heilshorn Am Waldberg",[8.7061558, 53.2541539]],
    [ "Heilshorn Heidhofweg",[8.7015643, 53.2481389]],
    [ "Heilshorn Am Sportplatz",[8.7115154, 53.2538529]],
    [ "OHZ-Garlstedt Garlstedter Kirchweg 4",[8.7582062, 53.248207]],
    [ "OHZ-Heilshorn Hasenheide",[8.7184064, 53.2527137]],
    [ "Heilshorn Stundenweg",[8.7091014, 53.2404741]],
    [ "Heilshorn Erikaweg",[8.7150872, 53.2517349]],
    [ "Osterhagen",[8.7156938, 53.1895182]],
    [ "Auf den Äckern",[8.7099027, 53.1919944]],
    [ "Trafostation Krankenhaus II",[8.7943113, 53.2342508]],
    [ "Trafostation Berufsschule",[8.8022892, 53.2212237]],
    [ "Trafostation Post",[8.8002642, 53.2229323]],
    [ "Trafostation Grüne Straße",[8.739573, 53.1838591]],
    [ "Trafostation Schillerstraße",[8.7408274, 53.1843887]],
    [ "Trafostation Heilshorn",[8.7583655, 53.1889084]],
    [ "Trafostation LINPAC Plastics",[8.7589635, 53.1894739]],
    [ "Trafostation Elsterstraße",[8.7462311, 53.1910857]],
    [ "Trafostation Am Sande",[8.7460765, 53.1945607]],
    [ "Trafostation Riesstraße",[8.7484546, 53.1772937]],
    [ "Trafostation Hegelstraße",[8.7493972, 53.1797739]],
    [ "Trafostation Goethestraße",[8.7543018, 53.1808896]],
    [ "Trafostation Rathausstraße",[8.7494256, 53.1797499]],
    [ "Trafostation Haydnstraße",[8.7486156, 53.1834164]],
    [ "Trafostation Lehmbarg",[8.7329582, 53.1785104]],
    [ "Trafostation Kolde Würde",[8.7347338, 53.1772406]],
    [ "Trafostation Schüttenbarg",[8.734833, 53.1788915]],
    [ "Trafostation Erbrichterweg",[8.7359327, 53.1777903]],
    [ "Trafostation Ringstraße",[8.7319604, 53.175752]],
    [ "Trafostation Struckbergstraße",[8.7331654, 53.1804085]],
    [ "Trafostation Bachstraße",[8.7472808, 53.1818157]],
    [ "Trafostation Ulmenstraße",[8.7506353, 53.191883]],
    [ "Trafostation Vor Vierhausen",[8.7484327, 53.1930041]],
    [ "Trafostation Am Denkmal",[8.7095747, 53.1871117]],
    [ "Stat. Querstraße",[14.0700804, 52.3511281]],
    [ "Umspannstation Münstertal Süssenbrunn",[7.7825431, 47.8474772]],
    [ "Beckedorf Auetal",[8.5858407, 53.1920315]],
    [ "Löhnhorst Am Fosshall",[8.6245343, 53.203234]],
    [ "Beckedorfer Feld",[8.5915993, 53.1919978]],
    [ "Leuchtenburger Straße",[8.6593669, 53.1834173]],
    [ "Industriestraße",[8.6155931, 53.1657808]],
    [ "Mellumring",[8.6002233, 53.160772]],
    [ "Eschhofstraße",[8.6169654, 53.1609215]],
    [ "Oldenburger Straße",[8.6071114, 53.1626952]],
    [ "Trafostation Stettiner Straße",[8.7486769, 53.1902711]],
    [ "10 KV Station Peddinghaus A",[7.36747, 51.2968244]],
    [ "10 KV Hecker",[7.3020184, 51.290898]],
    [ "OHZ-Garlstedt Hülseberger Straße",[8.7277125, 53.2747402]],
    [ "OHZ-Garlstedt Flitteresche",[8.7101717, 53.2781483]],
    [ "OHZ-Garlstedt Kirchacker",[8.7234944, 53.2703789]],
    [ "Trafostation Val-de-Reuil-Straße",[8.7445535, 53.1927068]],
    [ "OD61",[8.2343542, 53.1089715]],
    [ "OM08",[8.2283101, 53.1108717]],
    [ "Station Heimke",[8.2108086, 51.1786589]],
    [ "Gleichrichterwerk 030",[13.5185796, 52.5081113]],
    [ "Heiligenhäusel",[8.2241741, 48.8087543]],
    [ "Station 3",[10.4383903, 49.4781811]],
    [ "UP Edenkoben Forsthaus Heldenstein",[8.0138573, 49.298334]],
    [ "Siedlung",[10.3493126, 47.6572452]],
    [ "Henstedt Krummacker",[10.0150898, 53.787471]],
    [ "Umsp. Kläranlage",[9.1082205, 48.6065819]],
    [ "Jodbad Sulzbrunn",[10.3866927, 47.6705454]],
    [ "Henstedt-Ulzburg Bahnhof",[10.0060508, 53.7909167]],
    [ "Station Seisla Kalte Schenke",[11.5223623, 50.6374536]],
    [ "Buchenberg Ost",[10.2480518, 47.6951599]],
    [ "DEL Annenriede",[8.6442539, 53.0268865]],
    [ "Dorfmühlstraße",[10.4063118, 47.7692169]],
    [ "Wildpoldsried Süd",[10.399454, 47.7630288]],
    [ "DEL Dresdener Straße",[8.6217524, 53.0221391]],
    [ "Umspannwerk Marli",[10.7183477, 53.8653983]],
    [ "Altenburgring",[11.594212, 50.6884967]],
    [ "Henstedt-Ulzburg Togenkamp 2",[10.0273581, 53.7589112]],
    [ "Dietmannsried Schule",[10.2894289, 47.8054008]],
    [ "MU Wasserwerk",[11.4479855, 51.3132604]],
    [ "Uhlhorn Landriede SV",[8.4987345, 52.9662573]],
    [ "Engelhirsch",[10.1498905, 47.6485758]],
    [ "Station Am Uhlenbrookgraben",[8.9211847, 53.156429]],
    [ "Station Auf dem Kamp",[8.9231468, 53.1598697]],
    [ "Station Bahnhofstraße",[8.9063397, 53.1411817]],
    [ "Station Brauereiweg",[8.9304397, 53.1512173]],
    [ "Station Doktor-Hünerhoff-Straße",[8.8980099, 53.1367527]],
    [ "Station Falkenweg",[8.9290176, 53.1527221]],
    [ "Station Fr.Wilhelm-Raasch-Straße",[8.9206671, 53.1575473]],
    [ "Station Gertrud-Scheene-Straße",[8.9173794, 53.1556965]],
    [ "Station Heidloge",[8.9190304, 53.1552625]],
    [ "Station Im Ökodorf",[8.9156894, 53.1543829]],
    [ "Station Königsbergerstraße",[8.9271528, 53.1528404]],
    [ "Station Molkerei",[8.9306146, 53.15299]],
    [ "Station Pillauer Straße",[8.9255447, 53.1533727]],
    [ "Worpswede Kantstraße",[8.9397765, 53.2191657]],
    [ "Worpswede Im Schluh",[8.9344783, 53.2243634]],
    [ "Worpswede Kattenpad",[8.928805, 53.2264254]],
    [ "Worpswede Schlußdorfer Straße",[8.9498455, 53.2341998]],
    [ "Worpswede Schule",[8.9190372, 53.2243234]],
    [ "Günther-Wüllner-Str.",[7.1625983, 51.4104709]],
    [ "Riffgat",[6.4846323, 53.6909435]],
    [ "Martinszell, Illerstraße",[10.2796782, 47.6281693]],
    [ "Lanzen Süd",[10.3036325, 47.678943]],
    [ "Waltenhofen, Schule",[10.3088234, 47.6761477]],
    [ "Umspannwerk TU Nord",[13.3263229, 52.5142139]],
    [ "Ludwig-Prandtl-Straße",[10.3087812, 47.6680971]],
    [ "Umspannwerk Klinikum Steglitz",[13.3166321, 52.4404173]],
    [ "Umspannwerk FU-Krahmerstraße",[13.3182393, 52.4371888]],
    [ "Umspannwerk Krankenhaus Neukölln",[13.4602645, 52.4354701]],
    [ "Umspannwerk Bayer",[13.364337, 52.5397249]],
    [ "Götzberg Vogelsang",[10.0290514, 53.7929249]],
    [ "Umspannwerk Siemens Dynamowerk",[13.2637918, 52.5359965]],
    [ "Umspannwerk FU Thielallee",[13.2875238, 52.451314]],
    [ "Wakendorf 2 Am Endern",[10.06638, 53.7896041]],
    [ "OHZ-Ohlenstedt Campingplatz",[8.7586524, 53.2888932]],
    [ "OHZ-Hülseberg Friedhof",[8.7517671, 53.2848535]],
    [ "Kisdorf Gräbenhorst",[10.0304601, 53.8043186]],
    [ "Kisdorf Segeberger Straße",[10.0403968, 53.8145558]],
    [ "Henstedt Timmhagen",[10.0183255, 53.7674554]],
    [ "Götzberg Sandberg 4",[10.0589826, 53.7864978]],
    [ "Henstedt-Ulzburg Buschkoppel 2",[10.0170551, 53.7985028]],
    [ "Station Ulmenstraße",[8.1975642, 48.8133992]],
    [ "Henstedt Götzberger Straße",[10.0082512, 53.7880784]],
    [ "Auf den Äckern",[8.702841, 53.1920552]],
    [ "Trafostation Am Moor",[8.7026396, 53.1852204]],
    [ "Trafostation Landesgrenze",[8.703088, 53.1815396]],
    [ "Bramstedt Harrendorf",[8.7341978, 53.3472369]],
    [ "Sandstedt Offenwardener Straße",[8.5234339, 53.3590282]],
    [ "Hagen Kassebrucher Weg",[8.6455706, 53.3653181]],
    [ "OHZ-Garlstedt Ohlenstedter Weg",[8.7254767, 53.2960153]],
    [ "OHZ-Ohlenstedt Aßberg Nr. 7",[8.735055, 53.298313]],
    [ "OHZ-Ohlenstedt Aßberg",[8.739995, 53.3002899]],
    [ "OHZ-Ohlenstedt Haslah",[8.7532688, 53.3038229]],
    [ "Mozartstraße",[8.5932494, 53.543123]],
    [ "Lilienthal Viehreihe",[8.9378493, 53.1592399]],
    [ "Lilienthal Kastanienweg Anfang",[8.9337864, 53.1627247]],
    [ "Station Am Wolfsberg Mitte",[8.9179435, 53.1628483]],
    [ "Station Dreyers Kamp",[8.9069158, 53.1457039]],
    [ "Station Georg-Droste-Straße",[8.9328371, 53.1546588]],
    [ "Station Goebelstraße Anfang",[8.9160421, 53.1576283]],
    [ "Station Goebelstraße Ende",[8.9194768, 53.159673]],
    [ "Station Grimmstraße",[8.9158482, 53.1526267]],
    [ "Station Gutenbergstraße Anfang",[8.9045613, 53.1540181]],
    [ "Station Hilligenwarf Ende",[8.9197157, 53.1645443]],
    [ "Station In den Theilen Ende",[8.9149554, 53.1625725]],
    [ "Station In den Theilen",[8.9180167, 53.1609034]],
    [ "Station Kaffeepad",[8.9069986, 53.1430541]],
    [ "Station Schorfmoor",[8.920684, 53.1522403]],
    [ "Station Stadskanaal",[8.9091809, 53.1406969]],
    [ "Station Wilhelm-Scharrelmann-Straße",[8.9303319, 53.1559315]],
    [ "Stöckaumühle",[10.6122511, 49.0571558]],
    [ "Trafo Grunewald Straße 01091",[11.0451008, 50.9684092]],
    [ "Trafo Kranichfelder Straße Nr: 01106",[11.0559407, 50.9568511]],
    [ "Rappenscheuchen",[10.2890105, 47.765447]],
    [ "Henstedt-Ulzburg Kiefernweg 17b",[9.9910019, 53.7654022]],
    [ "Wakendorf 2 Henstedter Straße 25",[10.0772719, 53.7857741]],
    [ "Buettel",[8.5098067, 53.6484538]],
    [ "Grasberg Am Berg",[8.9900622, 53.178594]],
    [ "Lilienthal Ahnwers Wiese",[8.9416046, 53.1557049]],
    [ "Station Ostlandstraße",[8.9260938, 53.1566212]],
    [ "Grasberg-Schule",[8.9846604, 53.1821933]],
    [ "Grasberg-Schule",[8.9846992, 53.1822035]],
    [ "Grasberg",[8.9832462, 53.1838365]],
    [ "Grasberg Schützenhof",[8.9853056, 53.1845056]],
    [ "Grasberg Wörpedorfer Straße 20",[8.9875896, 53.1853884]],
    [ "Tarmstedt Bahnhofstraße",[9.0807871, 53.2241477]],
    [ "Tarmstedt Kleine Trift",[9.0834273, 53.2300858]],
    [ "Tarmstedt-Zentrum",[9.0784198, 53.2265506]],
    [ "Henstedt Ulzburg Hamburger Straße 81",[9.9820907, 53.7934227]],
    [ "Station Arpsdamm",[8.910732, 53.1408181]],
    [ "Station Rathaus",[8.9134183, 53.1413168]],
    [ "Station Amtmann-Schroeter-Straße",[8.9129177, 53.134347]],
    [ "Station Hinrich-Winters-Straße",[8.8994113, 53.1412479]],
    [ "Station Altenwohnungen",[8.909751, 53.1476191]],
    [ "Beverstedt Adolf-Butenandt-Straße",[8.8132144, 53.4300553]],
    [ "Beverstedt Im Mürken",[8.8220659, 53.4306598]],
    [ "Beverstedt Kampstraße",[8.8217807, 53.43296]],
    [ "Beverstedt Mittelpunktschule",[8.8154234, 53.434844]],
    [ "Bramstedt Im Bocksacker",[8.6809603, 53.3651885]],
    [ "Bramstedt Industriestraße 9",[8.6746339, 53.3655169]],
    [ "Vollersode Bergstraße",[8.9096186, 53.3218374]],
    [ "Vollersode-Wallhöfen Selworth",[8.8970951, 53.3225595]],
    [ "Gnarrenburg Bahnhofstraße",[9.005463, 53.3805987]],
    [ "Gnarrenburg Kirche",[9.0023992, 53.383003]],
    [ "Worpswede Osterweder Straße Nr. 77",[8.9406118, 53.2305801]],
    [ "Station Falkenberger Landstraße",[8.9176517, 53.1506566]],
    [ "Station Im Uhlenbrook",[8.9218485, 53.1546533]],
    [ "Station Kutscherweg 1",[8.9148324, 53.1483129]],
    [ "Station Kutscherweg 2",[8.9149391, 53.1482187]],
    [ "Station Moorhauser Landstraße",[8.9121943, 53.1526525]],
    [ "Station Peter-Sonnenschein-Straße",[8.9197831, 53.1534118]],
    [ "BT Henstedt-Ulzburg Bgm Steenbuck Straße 66",[10.011475, 53.7940616]],
    [ "Kisdorf Krambek 4",[10.0107123, 53.8048761]],
    [ "Kisdorf Etzberg 51",[10.0130497, 53.8077225]],
    [ "Druckerei Stark",[8.314741, 48.890247]],
    [ "Henstedt Logentwiete",[9.9956239, 53.7870527]],
    [ "Kisdorf Henstedter Str 13",[10.0048464, 53.8040852]],
    [ "Kisdorf Segeberger Straße 3",[10.0195031, 53.8105677]],
    [ "Station Metzgerstraße",[8.2224033, 48.7684885]],
    [ "OHZ-Günnemoor",[8.9168394, 53.2805058]],
    [ "Oersdorf Moorweg",[9.9861154, 53.8357801]],
    [ "Beverstedt Arp-Schnitger-Straße",[8.8260561, 53.435238]],
    [ "Beverstedt Bremer Straße",[8.8222244, 53.4352127]],
    [ "Beverstedt Königsberger Weg",[8.8183966, 53.4304109]],
    [ "Beverstedt Wilhelm-Giese-Straße",[8.8321976, 53.430444]],
    [ "Brunshausen Sandersweg",[8.8079784, 53.4006252]],
    [ "Brunshausen ÜGS Alter Postweg 14",[8.8080087, 53.4005936]],
    [ "Stubben An der Ohe",[8.7884608, 53.4054872]],
    [ "Stubben Elfershuder Straße",[8.7855483, 53.4155985]],
    [ "Stubben Martin-Börsmann-Straße",[8.7753335, 53.4205858]],
    [ "Wellen Neue Dorfstraße",[8.8487453, 53.4059187]],
    [ "Bramstedt-Lohe",[8.7530837, 53.3726556]],
    [ "Heidberg Dannenberger Straße",[8.9839655, 53.1510292]],
    [ "Umspannstation",[14.1839656, 51.1176529]],
    [ "Gnarrenburg Am Schützenhof",[9.0021375, 53.3860727]],
    [ "Gnarrenburg Brilliter Weg",[8.9997055, 53.3893454]],
    [ "Gnarrenburg Elbestraße",[9.0089859, 53.3875757]],
    [ "Albstedt Unter den Linden",[8.7179232, 53.3303943]],
    [ "Hagen Bergulmenweg",[8.6455415, 53.360587]],
    [ "Hagen Grüner Weg",[8.6390762, 53.3568508]],
    [ "Hagen Parkstraße",[8.6374952, 53.3586757]],
    [ "Hagen Siebenbergsweg",[8.6510505, 53.3625775]],
    [ "Hoope Wiesenstraße",[8.7025698, 53.3127344]],
    [ "Bramstedt Am Schießstand",[8.6953767, 53.3676246]],
    [ "Sankt Jürgen Vierhausen",[8.82737, 53.1815174]],
    [ "Freißenbüttel Am Schafkamp",[8.7887733, 53.2586451]],
    [ "Trafostation Reuter",[8.7993583, 53.2233911]],
    [ "Kisdorf Ulzburgerstraße 007",[10.0063996, 53.8070019]],
    [ "DEL Annenheider Str. 243",[8.6410193, 53.0196319]],
    [ "Station 04732",[10.9949688, 51.0131046]],
    [ "Station 04734",[10.997396, 51.0105817]],
    [ "Station 04739",[10.9929961, 51.0130035]],
    [ "Am Garten",[11.0874929, 50.9427528]],
    [ "Schlesierweg",[7.9075873, 51.8684222]],
    [ "Station Feldhausen",[8.8975028, 53.1449158]],
    [ "Station Trupe / Blänken",[8.8825523, 53.1424735]],
    [ "Station Trupe",[8.8921979, 53.1400323]],
    [ "Hambergen Bahnhofstraße",[8.8227129, 53.3063893]],
    [ "Hambergen Bergstraße",[8.8189048, 53.2991311]],
    [ "Hambergen Parallelweg 2",[8.8273621, 53.3101942]],
    [ "Hambergen Querstraße",[8.8213367, 53.310418]],
    [ "Trafostation",[8.7852591, 53.2304048]],
    [ "Trafostation Im Hülsen",[8.8188989, 53.2435946]],
    [ "Worpswede Bergstraße",[8.9284613, 53.2206898]],
    [ "Worpswede Hammeweg",[8.9063633, 53.2229245]],
    [ "Worpswede Hembergstraße",[8.9310333, 53.2206397]],
    [ "Grasberg Am Schiffgraben",[8.9840285, 53.1753299]],
    [ "Grasberg Wiesendamm",[8.9801841, 53.1779663]],
    [ "Grasberg-Rathaus",[8.9836796, 53.1798751]],
    [ "Lilienthal Heidberger Straße",[8.9476585, 53.1589111]],
    [ "Lilienthal Heideweg",[8.9293805, 53.16864]],
    [ "Lilienthal Kastanienweg Wendeplatz",[8.9330606, 53.1621202]],
    [ "Lilienthal Schützenweg",[8.9514356, 53.1595915]],
    [ "Grasberg Wörpedorfer Straße 12",[8.9761279, 53.1816295]],
    [ "Station Am Holzkamp",[8.9081598, 53.1530363]],
    [ "Worpswede Vor den Pferdeweiden",[8.9154843, 53.2268285]],
    [ "Sankt Jürgen Wührden",[8.8574328, 53.1808317]],
    [ "Station Mühlendeich",[8.9257473, 53.1495617]],
    [ "Grasberg Wörpedorfer Straße 4",[8.9656039, 53.1771026]],
    [ "Grasberg-Sportplatz",[8.9806302, 53.1882992]],
    [ "Lilienthal Richtpad",[8.9326813, 53.164898]],
    [ "Lilienthal Trupermoorer Landstraße 40a",[8.9422344, 53.1603572]],
    [ "Station Beim Spieker",[8.9233131, 53.1484055]],
    [ "Sankt Jürgen Oberende",[8.8818485, 53.1788749]],
    [ "Station Falkenweg",[8.9270872, 53.1541193]],
    [ "Bramstedt Kampstraße / Am Vordel",[8.6886834, 53.3610277]],
    [ "Worpswede Überhamm Anfang",[8.9404031, 53.2382402]],
    [ "Worpswede Überhamm",[8.9476089, 53.252375]],
    [ "Trafostation Hitzacker/ Auf der Hammworth",[8.760784, 53.1996739]],
    [ "Vollersode-Wallhöfen Steinrade",[8.9022209, 53.3205384]],
    [ "Vollersode Im Segen",[8.9115648, 53.3251901]],
    [ "Vollersode Grobersweg",[8.9146058, 53.3288854]],
    [ "Ahrensdorf Hamme",[8.9305353, 53.3355269]],
    [ "Grasberg-Ottersteiner Straße 15",[8.9663365, 53.2118104]],
    [ "Netzstation Bahnhofstraße",[9.2371389, 52.9201507]],
    [ "Netzstation Georgstraße",[9.2347892, 52.9187454]],
    [ "Hambergen Am Diekhoop",[8.8257666, 53.2916959]],
    [ "Vollersode-Bornreihe Denkmal",[8.8497782, 53.2983743]],
    [ "Hambergen Malvenstraße",[8.8228387, 53.2938204]],
    [ "Vollersode-Wallhöfen Kulmer Straße",[8.8672157, 53.3170651]],
    [ "Vollersode Auf dem Panzenberg",[8.9161265, 53.33846]],
    [ "Hambergen Lange Reihe",[8.8430318, 53.2961173]],
    [ "Vollersode-Wallhöfen Dorfstraße",[8.8923848, 53.3241442]],
    [ "Vollersode-Wallhöfen Waldstadion",[8.8664029, 53.3233275]],
    [ "Vollersode-Wallhöfen Sportplatz",[8.8750045, 53.3181906]],
    [ "Vollersode Achtern Dahl",[8.9098505, 53.3409464]],
    [ "Vollersode-Giehlermühlen 1d",[8.9137644, 53.3465543]],
    [ "Vollersode-Bornreihe",[8.9139928, 53.2977946]],
    [ "Vollersode-Verlüßmoor",[8.8855047, 53.2928772]],
    [ "Vollersode-Bornreihe Denkmal",[8.903761, 53.3026784]],
    [ "OHZ-Heilshorn Birkhahnweg",[8.7185107, 53.2563632]],
    [ "Sankt Juergen Hoege",[8.8979575, 53.1684115]],
    [ "Lilienthal-Worphausen Sonnenblick",[8.9533803, 53.1857586]],
    [ "Worpswede Wörpedahler Straße",[8.9046705, 53.2085389]],
    [ "Kiefernweg 23",[8.9327838, 53.1885514]],
    [ "Worpswede Weyermoorer Straße 7",[8.8925123, 53.2197378]],
    [ "Worphausen Schnaarsweg",[8.9355987, 53.1919957]],
    [ "Worpswede Moor",[8.9291282, 53.2079837]],
    [ "Worpswede Am Hasenmoor 21",[8.9112019, 53.2125286]],
    [ "Löhnhorst Brander Weg",[8.6494697, 53.2017258]],
    [ "SA Löhnhorst",[8.6347721, 53.2066333]],
    [ "Gleichrichterwerk Görlitzer Bahnhof",[13.4285167, 52.4992915]],
    [ "OHZ-Ohlenstedt Elenbruch 3",[8.7712212, 53.3016416]],
    [ "OHZ-Ohlenstedt Littberg",[8.7702677, 53.3046544]],
    [ "Axstedt An der Borg",[8.7788813, 53.3600368]],
    [ "Axstedt Bahnhof",[8.783805, 53.3434765]],
    [ "Hollen",[8.7071439, 53.4131742]],
    [ "Holste-Hellingst Am Balken",[8.8607878, 53.3856027]],
    [ "Holste-Oldendorf Axstedter Straße",[8.8209553, 53.3669627]],
    [ "Axstedt Harrendorfer Straße",[8.7718176, 53.354138]],
    [ "Axstedt Unter den Eichen",[8.7825325, 53.3403801]],
    [ "Beverstedt-Wellen Aher Weg 46",[8.8756537, 53.4053072]],
    [ "Beverstedt-Wellen Kramelheide",[8.8319785, 53.4096269]],
    [ "Bokel Mittelstraße",[8.7316043, 53.3880887]],
    [ "Holste-Hellingst Dorfstraße 9",[8.8617095, 53.3782142]],
    [ "Axstedt Schulstraße",[8.7716073, 53.3505959]],
    [ "Beverstedt Beverstedter Mühlenstraße",[8.8146457, 53.4256989]],
    [ "Steden Stedener Straße",[8.8545181, 53.3593617]],
    [ "Beverstedt Danziger Straße",[8.8096943, 53.4397885]],
    [ "Beverstedt-Wellen Aher Weg 46",[8.875553, 53.4052969]],
    [ "Bokel Feldstraße",[8.7295416, 53.3826889]],
    [ "Bokel Kanalstraße",[8.7161001, 53.3891387]],
    [ "Bokel Lehmkuhlweg",[8.7618779, 53.3924971]],
    [ "Bramstedt Schulstraße",[8.6890868, 53.3666773]],
    [ "Hambergen Bullwinkel",[8.8226277, 53.3256441]],
    [ "Umspannwerk",[8.7396287, 53.4312271]],
    [ "Axstedt Königsberger Straße",[8.7810168, 53.348633]],
    [ "Lilienthal-Worphausen Mooringer Straße 17",[8.9502914, 53.2057049]],
    [ "Worpswede-Mevenstedt Im Winkelmoor",[8.9814057, 53.2438229]],
    [ "Worpswede-Mevenstedt Neuer Weg",[8.9730365, 53.2407335]],
    [ "Grasberg-Gewerbegebiet Kirchdamm",[8.9805087, 53.1914623]],
    [ "Worpswede Bergedorfer Straße",[8.9497912, 53.216648]],
    [ "Worpswede-Mevenstedt Neuer Weg 19",[8.9727946, 53.24656]],
    [ "Grasberg-Seehauser Straße 54",[9.0238206, 53.212747]],
    [ "Grasberg-Wörpedorfer Ring",[8.9801391, 53.1923835]],
    [ "Lilienthal-Worphausen Mooringer Straße 28",[8.9616612, 53.2099982]],
    [ "Lilienthal-Worphausen Mooringer Straße 3a",[8.9370613, 53.2007384]],
    [ "Grasberg-Seehauser Straße 18b",[8.9952718, 53.201778]],
    [ "Grasberg-Otterstein Mitte",[8.9927802, 53.2216494]],
    [ "Grasberg-Ottersteiner Damm",[8.9792513, 53.2165001]],
    [ "Westerwohld Moeschen",[9.9588124, 53.7957766]],
    [ "Mözen Süd",[10.264286, 53.8943519]],
    [ "Kiefernweg 2",[9.9839396, 53.7603256]],
    [ "Industriestraße",[9.9826622, 53.7621215]],
    [ "Laffenau 01",[11.1226055, 49.1618422]],
    [ "Axstedt Hauptstraße",[8.7715359, 53.3559534]],
    [ "Bokel Schmiedeweg",[8.7639062, 53.3888912]],
    [ "Bederkesa Supermarkt",[8.8380683, 53.6266098]],
    [ "Dorfhagen Alte Lindenstraße 7",[8.681425, 53.3388544]],
    [ "Bederkesa Zum Hasengarten / Hallenbad",[8.8399563, 53.6270289]],
    [ "Bokel Kapellenstraße",[8.7597601, 53.4053055]],
    [ "Bokel Mühlenstraße",[8.75794, 53.395385]],
    [ "Lunestedt Am Geeren",[8.7595306, 53.4330334]],
    [ "OHZ-Ohlenstedt Demster",[8.7679238, 53.318411]],
    [ "Quät",[8.535677, 51.5166434]],
    [ "Wulsbüttel Loge",[8.6727532, 53.3127055]],
    [ "Station 2",[10.6049103, 49.0977066]],
    [ "Henstedt-Ulzburg Möschen",[9.9650263, 53.8042095]],
    [ "Kaltenkirchen Klärwerk Süd",[9.9315814, 53.8099556]],
    [ "Kaltenkirchen Brook",[9.9335005, 53.8172439]],
    [ "Vechta Marienstraße",[8.2859904, 52.7257037]],
    [ "Vechta Kapitelplatz 8",[8.2871597, 52.7268661]],
    [ "10-kV-Station Biete",[7.6195105, 51.7923964]],
    [ "Worpswede Fritz-Mackensen-Weg Nr. 1",[8.9197246, 53.2207379]],
    [ "Worpheim Anfang",[8.9006985, 53.196398]],
    [ "Worpswede Südweder Straße Nr. 8",[8.9162593, 53.2007771]],
    [ "Düvelsbarg",[10.0919514, 53.8028393]],
    [ "Schaltanlage Lübeck Süd",[10.6809908, 53.8545156]],
    [ "Lübberstedt Im Wroog",[8.7882539, 53.3410072]],
    [ "Lübberstedt Schmiedestraße",[8.8138558, 53.3373373]],
    [ "Leuchtenburg Krudops Busch",[8.6612495, 53.1793595]],
    [ "Löhnhorst Vorlöhnhorster Weg",[8.6435238, 53.1961526]],
    [ "Schmalfeld Bollweg 022",[10.0093363, 53.8592024]],
    [ "Nordenham Deichgräfenstraße",[8.4876714, 53.4871057]],
    [ "Lilienthal-Heidberg-Moorweg",[8.9587093, 53.1532813]],
    [ "Lilienthal-Trupermoor Gemeinde",[8.9343289, 53.1635705]],
    [ "Station Elbinger Straße",[8.9211945, 53.1510914]],
    [ "Lilienthal Birkenweg",[8.946054, 53.1623768]],
    [ "Worpswede Hinterm Berg",[8.9175604, 53.2098126]],
    [ "Station Aktiv-Markt",[8.9126415, 53.148869]],
    [ "Huxfeld Mittelsmoorer Straße",[9.0324318, 53.1567941]],
    [ "Ottersberg-Fischerhude Am Dobben",[9.0551765, 53.1168075]],
    [ "Rautendorf-Rautendorfer Straße Anfang",[9.0265583, 53.1356501]],
    [ "Worpswede-Am Bergedorfer Schiffgraben",[8.9339604, 53.2058507]],
    [ "Worpswede-Lindnerstraße",[8.9329991, 53.2100139]],
    [ "Weststraße",[7.3885522, 51.3882128]],
    [ "Bremervörde Dürerstraße",[9.1278461, 53.4809349]],
    [ "Bremervörde Engeoer Straße / Gnarrenburger Straße",[9.132188, 53.4722052]],
    [ "Bremervörde Friedrich-Dedecke-Straße",[9.134095, 53.4836185]],
    [ "Bremervörde Huddelberg 28",[9.145322, 53.4890075]],
    [ "Bremervörde Am Tannenkamp 7",[9.1387312, 53.4755253]],
    [ "Bremervörde Bürgermeister-Hey-Straße 14",[9.1461854, 53.4835598]],
    [ "Bremervörde Alte Straße 50",[9.1399805, 53.4873513]],
    [ "Bremervörde Am Bahnhof-Süd",[9.1402102, 53.4820262]],
    [ "Bremervörde Ernst-Bode-Straße",[9.1398958, 53.4837229]],
    [ "Vollersode-Friedensheimer Straße",[8.9028972, 53.3122452]],
    [ "Vollersode Giehle",[8.9312998, 53.3588923]],
    [ "EKZ Wetter",[7.3867434, 51.3874934]],
    [ "Seebergen Uppen Seebargen",[8.9871128, 53.142029]],
    [ "Station Am Wiestebruch",[9.1656529, 53.1039335]],
    [ "Seebergen Am Fuchsberg",[8.9789678, 53.1426457]],
    [ "Frankenburg Peiner Weg",[8.9136251, 53.1732677]],
    [ "Seebergen Bergstraße",[8.972307, 53.1431363]],
    [ "Hospital",[11.1463869, 51.7900798]],
    [ "Alveslohe Drosselbusch",[9.9295329, 53.7891489]],
    [ "Bremervörde Akazienstraße 1",[9.1512513, 53.487083]],
    [ "Oerel Eichenstraße",[9.0524552, 53.4815703]],
    [ "Oerel Kastanienweg",[9.0504839, 53.4768879]],
    [ "Bremervörde Beim Schlagbaum",[9.1372428, 53.4983277]],
    [ "Bremervörde Eschenstraße",[9.1573549, 53.4879976]],
    [ "Bremervörde Feldstraße",[9.1357424, 53.4913901]],
    [ "Oerel Dorfstraße 13 / Bohlenstraße",[9.0558347, 53.4814258]],
    [ "Oerel Schule",[9.0618141, 53.483037]],
    [ "Bremervörde Hafenstraße 29",[9.1544019, 53.4850853]],
    [ "Bremervörde Neues Feld",[9.1384245, 53.4951366]],
    [ "Bremervörde Stephansplatz",[9.1353233, 53.4869781]],
    [ "Oerel Dorfstraße",[9.0658472, 53.4866661]],
    [ "Oerel Im Saal",[9.052433, 53.4789858]],
    [ "Kattendorf Weeden",[10.044366, 53.8396072]],
    [ "Sevelten Kirchweg",[8.0649949, 52.8078841]],
    [ "Lawaetzstraße",[9.986075, 53.7277488]],
    [ "Schalthaus Garlstedt",[8.7095413, 53.2698381]],
    [ "10kV-Station",[7.2944067, 51.9701402]],
    [ "Henstedt-Ulzburg Beckersbergstraße 95 / Schule",[9.9985793, 53.7903773]],
    [ "An der Heile",[7.338597, 51.38342]],
    [ "Im Korten AT",[7.3349237, 51.3807682]],
    [ "Bergmannsweg",[13.2968388, 51.008228]],
    [ "Talstraße 10a",[8.6707667, 49.5188228]],
    [ "Henstedt-Ulzburg Olivastraße 2",[9.9912971, 53.7960062]],
    [ "Stützpunkt Sterley",[10.8436792, 53.6294604]],
    [ "Bruchstraße",[6.066492, 51.7971818]],
    [ "Trafostation Im Hof",[8.8201087, 53.245848]],
    [ "Trafostation Neuendammer Straße / Am Klostermoor",[8.8209046, 53.2416825]],
    [ "Prisdorf Fischteiche 009",[9.7653836, 53.6922986]],
    [ "Im Hammereisen 17",[6.0508446, 51.7823386]],
    [ "Im Hammereisen 27",[6.0486066, 51.7838943]],
    [ "Im Hammereisen 32",[6.0536027, 51.783729]],
    [ "Im Hammereisen 53",[6.0549391, 51.7832423]],
    [ "Im Hammereisen 55",[6.0553312, 51.783028]],
    [ "im Schlop",[6.0550425, 51.7846068]],
    [ "Worpswede Heinrich-Vogeler-Weg",[8.9284337, 53.21135]],
    [ "Neu St. Jürgen Dorfstraße 4",[8.961909, 53.2551119]],
    [ "Neu St. Jürgen Gedenkstätte",[8.9798511, 53.2564597]],
    [ "Neu St. Jürgen Am Abelhüttenberg",[8.9889935, 53.2590526]],
    [ "Neu St. Jürgen Dorfstraße",[8.996582, 53.2573161]],
    [ "Adolphsdorfer Straße Nr. 245",[9.0185005, 53.2353036]],
    [ "Neu St. Jürgen Hasenweg / Dorfstraße 43",[9.0194479, 53.241686]],
    [ "Neu St. Jürgen Hasenweg",[9.0191454, 53.2448398]],
    [ "Worpswede Spritzenhaus",[8.9244425, 53.2233184]],
    [ "Mehrer Straße",[6.0959102, 51.8034122]],
    [ "Ulzburg Radeland",[9.9781833, 53.8021557]],
    [ "Reepmoorsweg Hamann",[8.4144757, 52.8941039]],
    [ "DEL Fabrikhof",[8.6366575, 53.054208]],
    [ "Wildeshausen Pestruper Straße",[8.4424086, 52.8895682]],
    [ "Wildeshausen Kaiserplatz",[8.4369401, 52.8925108]],
    [ "Forsthaus Nord",[6.0014238, 51.7635481]],
    [ "Frasselt Kirche",[6.0233204, 51.7708616]],
    [ "Driftsethe Tannendorf",[8.6359868, 53.3799486]],
    [ "Hagen Auf der Wurth",[8.644831, 53.3552899]],
    [ "Loxstedt Büttel",[8.5353843, 53.4263736]],
    [ "Loxstedt Neuenlande",[8.5314801, 53.416633]],
    [ "Nordenham Atenser Allee 106",[8.4764732, 53.498703]],
    [ "Nordenham Friedrich-Ebert-Straße 112",[8.4872644, 53.4953648]],
    [ "Nordenham Lutherstraße 9",[8.4807222, 53.4846743]],
    [ "Nordenham Saarstraße",[8.4744549, 53.4804429]],
    [ "Offenwarden Hauptstraße",[8.5321573, 53.3369288]],
    [ "SV Schwingenburg",[8.5510284, 53.4255992]],
    [ "Nordenham Adolf-Vinnen-Straße",[8.4870089, 53.4912302]],
    [ "Nordenham Gymnasium",[8.4789551, 53.4873394]],
    [ "Nordenham Viktoriastraße",[8.4865632, 53.4934677]],
    [ "Nordenham Wesertor",[8.4865518, 53.4853191]],
    [ "Nordenham Berufsschule",[8.4832739, 53.4906931]],
    [ "Nordenham Kabelstraße",[8.4914437, 53.4926634]],
    [ "Nordenham Berliner Straße",[8.4799197, 53.4965665]],
    [ "Rechtenfleth Hermann-Allmers-Straße",[8.5099078, 53.3826683]],
    [ "Rechtenfleth Mittelstraße",[8.5084667, 53.3799905]],
    [ "Sandstedt Deichstrenge",[8.519969, 53.3609097]],
    [ "Schwegen",[8.5732884, 53.416808]],
    [ "Stotel Neuenlandermoor",[8.5899993, 53.405347]],
    [ "DEL Lahusenstraße",[8.6394634, 53.0539075]],
    [ "Trafostation Loger Straße",[8.7835234, 53.2238251]],
    [ "Sandstedt Mühlenstraße",[8.5263612, 53.361167]],
    [ "Kattendorf Buschweg 14",[10.0446639, 53.8344852]],
    [ "Kisdorf Ulmenhof",[10.042474, 53.8191367]],
    [ "Kaltenkirchen Leibnitzstraße 10",[9.9693796, 53.8195048]],
    [ "Kaltenkirchen Schule Hohenmoor",[9.9567848, 53.8256411]],
    [ "Nordergründe",[8.178985, 53.824291]],
    [ "Bremervörde Gutenbergstraße",[9.1242168, 53.4937173]],
    [ "Bremervörde-Elm Biogasanlage",[9.1918717, 53.5041363]],
    [ "Bremervörde-Elm Eddelhoff",[9.2079408, 53.519848]],
    [ "Bremervörde-Elm Westerholz",[9.1920018, 53.5042278]],
    [ "Bremervörde-Elm Katenstraße",[9.204031, 53.5162831]],
    [ "Elm Vogthoff",[9.2088588, 53.5170743]],
    [ "Dedesdorf Fährstraße",[8.5026687, 53.4439463]],
    [ "Henstedt-Ulzburg Wohldweg 22",[10.0506838, 53.7764212]],
    [ "10-kV-Station Erich-Hoepner-Ring 1",[7.1996994, 51.2381957]],
    [ "Breslauer Straße 25",[9.9989111, 53.7949513]],
    [ "Schlesienstraße 19",[9.9998111, 53.7948206]],
    [ "Waldenburger Ring 6a",[10.0016957, 53.7932557]],
    [ "Clara-Schumann-Straße",[9.9859874, 53.7964091]],
    [ "Kaltenkirchen Kisdorfer Weg Ohlandcenter",[9.980503, 53.8311323]],
    [ "Trafo v. Biogas",[12.1151784, 49.9806385]],
    [ "Tarmstedt Rothensteiner Straße Nr. 12",[9.0747791, 53.2282986]],
    [ "Tarmstedt Wilstedter Straße",[9.0800527, 53.2219096]],
    [ "Rheinkaistraße 3",[8.4518878, 49.4868683]],
    [ "Fruchtbahnhofstraße 12",[8.451729, 49.4882462]],
    [ "Henstedt Wöddelkamp",[10.0009093, 53.7870954]],
    [ "Henstedt-Ulzburg Kurt-Körber-Straße GFR",[9.9722916, 53.8109223]],
    [ "Hof Lütte Lohe",[10.0734988, 53.7597081]],
    [ "Henstedter Baum",[9.9931369, 53.7772248]],
    [ "Henstedt-Ulzburg Siebenstücken 2 (Bode)",[9.9672586, 53.8099358]],
    [ "Henstedt-Ulzburg Trögenölk 13",[9.9771881, 53.7770909]],
    [ "Ulzburg Dorf",[9.9781529, 53.7894487]],
    [ "Henstedt-Ulzburg Bahnhof",[9.9826312, 53.7967824]],
    [ "Umspannwerk Eilbek",[10.0410649, 53.5661458]],
    [ "Loge",[9.086929, 52.8589019]],
    [ "Bremervörde Allensteiner Straße",[9.1348359, 53.4775932]],
    [ "Bremervörde Am Lagerberg 20",[9.1421372, 53.4779379]],
    [ "Bremervörde Gnarrenburger Str. 2",[9.1423659, 53.4822297]],
    [ "Bremervörde Ritterstraße 5 / Telekom",[9.1438143, 53.4868423]],
    [ "10 KV Rombacher Hütte 15",[7.1894802, 51.4628979]],
    [ "10 KV",[7.1888016, 51.4627466]],
    [ "Wiesloch/Uferstraße",[8.6935169, 49.2935631]],
    [ "Axstedt Langenstückenweg",[8.7738421, 53.3697156]],
    [ "Henstedt-Ulzburg Wilstedter Straße 115 ONF",[10.0106779, 53.7489071]],
    [ "Festplatz",[11.345554, 50.7166975]],
    [ "Schaltposten Niederfüllbach",[11.0016194, 50.2042805]],
    [ "Wiesloch/Woolworth",[8.7017454, 49.2946755]],
    [ "Umspannanlage Altstadt",[8.7101092, 49.4132984]],
    [ "Station 15",[10.5963366, 49.0371217]],
    [ "Tillmanns Fotostudio",[7.2443198, 51.974094]],
    [ "10 KV",[7.1810424, 51.3995372]],
    [ "Ulzburg Sigeto",[9.9787987, 53.7986607]],
    [ "Henstedt-Ulzburg Virchowring 17",[9.982598, 53.787351]],
    [ "Henstedt-Ulzburg Virchowring 70",[9.9861465, 53.7894119]],
    [ "Wilstedt Dorfteich",[10.0650466, 53.7454975]],
    [ "Kaltenkirchen Kisdorfer Weg 11 Globus",[9.9776169, 53.8299463]],
    [ "Nützen Kirchweg Straßenmeisterei",[9.9429189, 53.8477821]],
    [ "Kaltenkirchen Küsterland",[9.9405653, 53.8440377]],
    [ "Kaden Birkenweg",[9.9396689, 53.7880448]],
    [ "Kirchhatten, Reiherbusch",[8.3632942, 53.0119625]],
    [ "Kirchhatten, Twiestweg",[8.3896208, 53.0149179]],
    [ "Henstedt-Ulzburg Marktplatz 1",[9.9817073, 53.793712]],
    [ "Umspannwerk Kampen",[8.3512604, 54.9703649]],
    [ "Umspannwerk Westerland",[8.3127435, 54.9137798]],
    [ "Reichenbach Siedlung 2",[10.474973, 47.6210163]],
    [ "Schönau/Jägerpfad",[8.8108031, 49.4353025]],
    [ "Pumpstation",[11.0892861, 52.8533278]],
    [ "Henstedt-Ulzburg Nordring 11",[9.9849804, 53.7991736]],
    [ "Kaltenkirchen Albrecht-Dürer-Ring",[9.9343859, 53.8282907]],
    [ "Hambergen Windhornsweg",[8.8268897, 53.3045175]],
    [ "Alveslohe Pinndiek",[9.9238287, 53.7934172]],
    [ "Friesenheimerweg",[7.8753421, 48.3471148]],
    [ "KVS-LUE-426",[8.652522, 49.527886]],
    [ "Sparkasse",[7.8705601, 48.3403723]],
    [ "Fontanestraße",[10.256723, 53.5223194]],
    [ "Sülfeld Zuckerhut 1",[10.2182286, 53.8045163]],
    [ "Neckargemünder Straße",[8.7975825, 49.3613985]],
    [ "Turmstrasse",[7.8764454, 48.3425165]],
    [ "Ärztehaus",[7.885201, 48.3448338]],
    [ "Krankenhaus neu",[7.8852278, 48.344709]],
    [ "Henstedt-Ulzburg Kirchweg 135",[9.9773874, 53.8072893]],
    [ "Heilshorn Fasanenweg",[8.7101131, 53.2487677]],
    [ "Nahe Feuerwehr",[10.1357621, 53.7936897]],
    [ "Beckumer Straße 58",[8.1135126, 51.692656]],
    [ "Kisdorf Am Stocksberg",[10.0055489, 53.8155372]],
    [ "Station Breyell",[6.2546056, 51.3011093]],
    [ "Winsen Klein Winsen",[10.0212854, 53.8322038]],
    [ "Günter-Wüllner-Straße",[7.1627833, 51.4103121]],
    [ "Ulzburg Kirchweg 155",[9.9778014, 53.809524]],
    [ "Ulzburg Krögerskoppel 2",[9.9731746, 53.8053815]],
    [ "Ulzburg Am Redder",[9.9641946, 53.8117222]],
    [ "Kisdorf Segeberger Straße 61",[10.0809096, 53.8200777]],
    [ "Nuttel Stedinger Weg",[8.4314277, 53.0191074]],
    [ "Dingstede Nutteler Str.",[8.4270707, 53.0269669]],
    [ "BF Aulendorf",[9.6456638, 47.9564015]],
    [ "Lechterke 01",[7.9726598, 52.6610535]],
    [ "Friedrichsplatz",[7.9441559, 52.6758568]],
    [ "Schiphorst",[7.9504859, 52.6760576]],
    [ "10 kV-Station Westring 26A",[7.2129145, 51.4824556]],
    [ "SA Kirche",[6.3716687, 51.2858856]],
    [ "Nortrup-06",[7.8804856, 52.6104165]],
    [ "TrSt Waldstr.",[13.2569345, 52.6610935]],
    [ "Station 1.4",[13.5657282, 52.4681033]],
    [ "Station 1.3",[13.5638551, 52.4679967]],
    [ "Station 1.5",[13.5663354, 52.4668163]],
    [ "Station 2.6",[13.5647239, 52.4686077]],
    [ "Station 1.2",[13.5647519, 52.4686293]],
    [ "Station 2.4",[13.560502, 52.4695029]],
    [ "Station 2.3",[13.5626432, 52.4695224]],
    [ "Station 2.2",[13.5638953, 52.4698057]],
    [ "Station 3.3",[13.5632596, 52.4713697]],
    [ "Station 3.2",[13.5633103, 52.4713817]],
    [ "Station 3.1",[13.5658139, 52.470826]],
    [ "Station 2.1",[13.5657996, 52.4690901]],
    [ "Station 1.1",[13.567327, 52.4673306]],
    [ "Nahe Dorfstraße 31c",[10.1346597, 53.7995451]],
    [ "Henstedt-Ulzburg Rudolf-Diesel-Straße 15 Netto",[9.9490629, 53.8055201]],
    [ "Henstedt-Ulzburg Rudolf Dieselstraße 24",[9.949818, 53.8041373]],
    [ "Schule Tempelhofer Straße",[12.2463594, 51.7965949]],
    [ "Tempelhofer Straße",[12.2416576, 51.798603]],
    [ "Kaltenkirchen Am Flottmoorpark",[9.9643926, 53.8296638]],
    [ "TST Leistenfabrik",[11.3328038, 50.7325897]],
    [ "Am Schützenhof",[7.9787108, 52.6698944]],
    [ "Alveslohe Brunskamp",[9.9036035, 53.8058749]],
    [ "10kV Flurstraße",[7.2714143, 51.3287643]],
    [ "Henstedt-Ulzburg Lühmannstr 1",[9.9822365, 53.7959798]],
    [ "Wohldstraße",[7.9695296, 52.6768261]],
    [ "Sievershütten Hasenhörn",[10.1094107, 53.8464349]],
    [ "Gan. Raiffeisenstr.",[8.5431147, 53.0360797]],
    [ "Grothe - 03",[7.9723218, 52.6447625]],
    [ "Lechterke-02",[7.9695699, 52.6639394]],
    [ "Itzstedt Seeweg 25",[10.1489478, 53.8065528]],
    [ "Itzstedt Brook",[10.1641747, 53.7990303]],
    [ "Heinestraße",[7.9539353, 52.6732254]],
    [ "Kaltenkirchen Hohenmoor",[9.9576205, 53.81761]],
    [ "Oering Weidenfeld",[10.163413, 53.8210386]],
    [ "Düvelsbarg Horst",[10.0950918, 53.7987974]],
    [ "Itzstedt Tinners Blöken",[10.153757, 53.8096887]],
    [ "Todesfelde Dorfstraße 75",[10.1703384, 53.8915652]],
    [ "Alveslohe Barmstedter Straße 45",[9.8995399, 53.7835248]],
    [ "Alveslohe Barmstedter Straße 53",[9.8886281, 53.7842533]],
    [ "Oersdorf Brookstraße",[9.9911132, 53.8374951]],
    [ "Nahe Hauptstraße",[10.1391926, 53.7943306]],
    [ "Brünnelweg",[8.6696492, 49.5269533]],
    [ "Gan Robert-Koch-Str. 1",[8.5419251, 53.0444116]],
    [ "Kisdorf Holstenring",[10.0048099, 53.8093704]],
    [ "Nahe Im Busch",[10.1285523, 53.796505]],
    [ "Stuvenborn Segeberger Straße 7",[10.1283364, 53.8541032]],
    [ "Umspannstation Verkehrsamt",[8.187848, 48.6866949]],
    [ "Nuttel Stedinger Weg 5",[8.4336001, 53.0127306]],
    [ "Niedersachsenstraße",[7.952857, 52.6633066]],
    [ "Emsaue 5",[8.4195741, 51.8142023]],
    [ "Gr. Mimmelage-03",[7.9006425, 52.6626193]],
    [ "Nortrup-10",[7.854837, 52.6240704]],
    [ "Gr.Mimmelage-07",[7.8747457, 52.6635978]],
    [ "Gr.Mimmelage-11",[7.8926066, 52.6634318]],
    [ "Henstedt-Ulzburg Marktplatz 2",[9.981869, 53.7940581]],
    [ "10kV Oststraße",[7.3538374, 51.3272746]],
    [ "10kV AVU Lager AT",[7.352341, 51.3281069]],
    [ "Deilbachweg",[7.1598093, 51.3357556]],
    [ "Mintert",[7.1585091, 51.3425836]],
    [ "Itzstedt Kornbusch",[10.1618224, 53.8126898]],
    [ "Nützen Hohlweg",[9.9122778, 53.8516311]],
    [ "Trafostation Stern- und Kreis- Schifffahrt",[13.4647382, 52.4932515]],
    [ "Henstedt-Ulzburg Adlerhorst 2a",[9.9788984, 53.7695283]],
    [ "Zitadelle",[6.3594827, 50.9248831]],
    [ "Fuhlendorf Gutglück",[12.6538017, 54.369208]],
    [ "Bahnflächen 01",[7.945886, 52.6714087]],
    [ "Nutzhorn Hohenkamp",[8.5678586, 53.0935897]],
    [ "Kirchkimmen Im Dorf 2",[8.4476394, 53.0642662]],
    [ "Flensburg Karlsstraße",[9.4396325, 54.7852381]],
    [ "Dinkelskamp",[8.0826022, 51.6973931]],
    [ "Brandheide 14a",[8.5677429, 51.8812237]],
    [ "Arcadis Ost 1",[13.67714, 54.8172808]],
    [ "Plinaustraße",[8.4978188, 49.4457061]],
    [ "Am alten Mühlgraben",[8.2974924, 50.2600707]],
    [ "PVA Pölzig",[12.193605, 50.9268104]],
    [ "Bröckau",[12.1936605, 50.9267306]],
    [ "WP Großenstein",[12.2185359, 50.8757051]],
    [ "Solarpark",[12.2184424, 50.8756626]],
    [ "Schleifreisener Weg",[11.8503275, 50.8902556]],
    [ "Auf der Lind",[8.3103947, 50.2487546]],
    [ "Urbachtal",[7.7340643, 50.1218515]],
    [ "MS-Station IKZ",[13.527284, 52.4309902]],
    [ "MS-Station MBI",[13.527225, 52.4310564]],
    [ "Henstedt-Ulzburg Kranichstraße 1",[9.9782494, 53.7742981]],
    [ "Ganderkesee Hestern 2",[8.5082419, 52.9941342]],
    [ "Gan, Am Kamphusmoor 3",[8.5394575, 53.0144258]],
    [ "Gan Reithalle",[8.5398088, 53.0210445]],
    [ "Gan. Birkenheider Str. 21",[8.5430188, 53.024656]],
    [ "Strudthafe Wildeshauser Landstr. Iben",[8.5430489, 53.0012164]],
    [ "Klein Henstedt Kehrtau",[8.5353389, 52.9676856]],
    [ "Hengsterholz Riehe",[8.5345658, 52.968747]],
    [ "Immer Am Kronenschlatt",[8.512913, 53.0046557]],
    [ "Gan Am Kamphusmoor 100",[8.5292181, 53.0130288]],
    [ "Gan. Elly-Beihorn-Str.",[8.5213432, 53.0350615]],
    [ "Gan. Stettiner Weg",[8.5313961, 53.0383069]],
    [ "Klein Henstedt Schafkamp",[8.5552932, 52.9720959]],
    [ "Harpstedt Amerika",[8.5713127, 52.9223907]],
    [ "Gand. Marderweg 39",[8.541052, 53.0251791]],
    [ "Gan. Lekkerland",[8.5331831, 53.030784]],
    [ "Gan. Elly-Beinhorn-Str. Wendepl.",[8.5228828, 53.0321377]],
    [ "Gan Eichhörnchenweg",[8.5367156, 53.0273178]],
    [ "Ganderkesee Thienfelde 3",[8.5003388, 53.0302626]],
    [ "Hengsterholz Neustadt",[8.5231765, 52.9867904]],
    [ "Gan Handwerksweg",[8.525453, 53.0284329]],
    [ "Immer Stüher Str. 4",[8.5053143, 53.0104698]],
    [ "Gan Bachstr.",[8.5556848, 53.0305041]],
    [ "Schlutterbrink",[8.581491, 53.0304851]],
    [ "Gan. An der Hackhorst 50",[8.5744724, 53.017052]],
    [ "Gan. Am Kamphusmoor 61",[8.5133864, 53.011181]],
    [ "Ganderkesee Industriepark 4",[8.5320754, 53.0287277]],
    [ "Gan. Neddenhüsen",[8.5488405, 53.0283672]],
    [ "Gan. Dehltuner Str. 51",[8.5154854, 53.02846]],
    [ "Gan. Zum Flugplatz",[8.5168015, 53.0329561]],
    [ "Hengsterholz",[8.5274841, 52.9775429]],
    [ "Gan Glockenstein",[8.5491852, 53.0310106]],
    [ "Ganderkesee Bassumer Weg 4",[8.5193402, 53.0219065]],
    [ "Bergedorf Bieresch",[8.4652812, 53.0180174]],
    [ "Gan. Zur Feldhorst 4",[8.4704876, 53.0198394]],
    [ "Bergedorf Ohe",[8.4757937, 53.0228269]],
    [ "Gan. Güterstr. 2",[8.5290229, 53.0306924]],
    [ "Gan Realschule",[8.5408683, 53.0375941]],
    [ "Gan Birkenallee",[8.5208601, 53.0411708]],
    [ "Bergedorf Tange",[8.4624419, 53.0326885]],
    [ "Gan. Mühlenstr.",[8.5445054, 53.0314171]],
    [ "Gan. Pestalozzistr.",[8.5397083, 53.0336394]],
    [ "Gan. Feuerwehr",[8.5478716, 53.0339865]],
    [ "Gan. An der Schmiede",[8.5474216, 53.0352128]],
    [ "Gan. Rathaus",[8.5448014, 53.0326433]],
    [ "Kaltenkirchen Stubbenwiese",[9.9634067, 53.8451369]],
    [ "Theater im Brühl",[11.0167889, 50.9744618]],
    [ "Schlutter Schwarting",[8.5955995, 53.0317022]],
    [ "Gan Riedenweg",[8.553004, 53.0278227]],
    [ "Eichenallee 21",[7.9891393, 51.6504509]],
    [ "Abernettistr.",[8.6036883, 53.0091158]],
    [ "DEL Albertusweg",[8.6359768, 53.0167462]],
    [ "Munderloh Alter Postweg",[8.3753968, 53.0461447]],
    [ "Gan. Heinr.-Schwarz-Weg 40",[8.4538789, 53.0396302]],
    [ "Bergedorf Bergedorfer Landstr.",[8.4769995, 53.0327547]],
    [ "Bookhorn Z. d. Dammwiesen 2",[8.5336867, 53.0538346]],
    [ "G'bühren Holler Weg 3",[8.5290465, 53.0692516]],
    [ "Bookhorn Neumühlen",[8.5429665, 53.0537206]],
    [ "Brüning Gewerbegebiet",[8.5314202, 53.0607894]],
    [ "Brüning, SV Bredendiek",[8.5384208, 53.0565668]],
    [ "Gan Urneburger Str.",[8.5568829, 53.0436283]],
    [ "Wochenendsiedlung",[8.3412556, 52.9671118]],
    [ "Gan Heideweg",[8.5599468, 53.0254094]],
    [ "Havekost Dorf",[8.5345691, 52.9901214]],
    [ "Bookhorn Bookhorner Weg",[8.5300925, 53.0450139]],
    [ "Bookh. An der Bahn",[8.522695, 53.0999638]],
    [ "10 KV Vohwinkeler Straße 34",[7.0652845, 51.2297753]],
    [ "10 KV Vohwinkeler Straße 32",[7.0651873, 51.2299138]],
    [ "OHZ-Garlstedt Wandmachers Weg",[8.7143645, 53.2695037]],
    [ "DEL Hegeler Str.",[8.622909, 53.0498882]],
    [ "Gan Wittekindstr. 10",[8.5441299, 53.0350394]],
    [ "Wolletz TrSt Dorf",[13.9028815, 53.0248572]],
    [ "Annen",[8.6219032, 52.9955847]],
    [ "Iserloy",[8.4458122, 52.9405727]],
    [ "Iserloy Brennereiweg",[8.4446079, 52.9301391]],
    [ "Brettorf Haidhäuser BGA",[8.4691943, 52.9766845]],
    [ "Brettorf Uhlhorner Str. 25",[8.4627342, 52.9664212]],
    [ "Brettorf Z2",[8.456372, 52.953591]],
    [ "DEL Kramermarkt",[8.6227588, 53.0466038]],
    [ "Hengsterh. Schweinsheide",[8.5191236, 52.9696681]],
    [ "Simmerhausen Stolzbruch SV",[8.4820894, 52.9339323]],
    [ "Simmerhausen",[8.4820126, 52.9338609]],
    [ "Prinzhöfte Stolzbruch Gefl. Hennenberg",[8.4858763, 52.9366641]],
    [ "Dötlingen Busch",[8.426232, 52.9270598]],
    [ "Dötlingen Birkenbusch",[8.4308414, 52.9521722]],
    [ "Gan. Grüppenbührener Str. 7",[8.5436491, 53.0372179]],
    [ "Gan. Grüppenbührener Str. 96",[8.5380533, 53.0450977]],
    [ "Dötlingen Badberg",[8.3948851, 52.9285676]],
    [ "Klattenhof Bassumer Weg",[8.4779155, 52.9922187]],
    [ "Ortsvermittlungsstelle",[8.807388, 49.4423505]],
    [ "SW-Umspannwerk Helenenstraße",[8.2348078, 50.0816302]],
    [ "DEL Tiergarten",[8.5985908, 53.0496593]],
    [ "STL Altenheerse",[9.0698559, 51.6333441]],
    [ "Welsburg",[8.4711134, 53.0113057]],
    [ "Welsburger Heide",[8.4560421, 53.0054667]],
    [ "Almsloh",[8.5610425, 53.0567901]],
    [ "Gan. Zur Straßenmeisterei 2",[8.5615307, 53.0465397]],
    [ "Almsloh Auf dem Feldberg",[8.564233, 53.0497431]],
    [ "Annenheide Annen",[8.6131015, 52.9845007]],
    [ "Brettorf Haidhäuser",[8.4692386, 52.9766522]],
    [ "Wildeshausen St-Peter-Str 3A",[8.4404565, 52.8928731]],
    [ "Wil. Neue Straße",[8.4352027, 52.897935]],
    [ "Henstedt-Ulzburg Wohldweg 6",[10.0074667, 53.7857481]],
    [ "Neulandstraße",[7.9189251, 52.6674542]],
    [ "Habbrüggerfurth",[8.5024215, 53.046216]],
    [ "DEL Horster Weg",[8.6372374, 53.0815282]],
    [ "Schmede Dingsteder Sr 54. SV",[8.385551, 53.0294375]],
    [ "Dingstede Löschteich",[8.4250465, 53.0454404]],
    [ "Steinkimmen Im Horn",[8.4481598, 53.0448224]],
    [ "Gan Bissener Weg 6",[8.5260317, 53.0633056]],
    [ "Vielstedt Steinweg 10",[8.4722614, 53.0825255]],
    [ "Vielstedt Am Forsthaus",[8.467766, 53.0722768]],
    [ "Bergedorf Alter Mühlenweg 3",[8.4605154, 53.0154763]],
    [ "DB Eggetunnel Übergabestation Willebadessen 3",[8.9388407, 51.6455897]],
    [ "Steinkimmen Jugendhof",[8.4350136, 53.0319611]],
    [ "Otto-Lilienthal-Str. 23",[8.5057139, 53.0344047]],
    [ "DEL Udetstr. 35",[8.5976695, 52.9938452]],
    [ "Gan Kühlinger Str. 37",[8.5152036, 53.0583449]],
    [ "Habbrügge In der Helle",[8.5001135, 53.0508637]],
    [ "10-kV-Station Sormeckestraße",[8.16495, 51.2430346]],
    [ "Uhlhorn",[8.4951729, 52.9607037]],
    [ "Falkenburg Orthstr. 6",[8.4874253, 53.0474213]],
    [ "Uhlhorn BGA Bitter",[8.4951943, 52.9606682]],
    [ "Gan. Wolfsheide",[8.5535538, 53.0330772]],
    [ "Habbrügge Orth",[8.4905756, 53.0410711]],
    [ "ASt Kerkow BHKW II",[13.9892374, 53.0356701]],
    [ "Angermünde TrSt Markt",[14.0045914, 53.018026]],
    [ "Fa. Drück-Brühl",[8.9799974, 50.3543168]],
    [ "Klöckner-Pentapack",[8.9775116, 50.3536518]],
    [ "10 kV-Station Saladin-Schmitt-Str. 2",[7.2183126, 51.4720854]],
    [ "10 KV Im Ostfeld",[7.5928382, 51.3643379]],
    [ "TS Tierpark",[13.9900684, 53.0095087]],
    [ "Mudauer Ring 11",[8.5407555, 49.4980589]],
    [ "TS TÜV",[13.9916291, 53.0173031]],
    [ "TS Krankenhaus",[13.9884602, 53.0124108]],
    [ "10 kV-Station Karl-Lange-Str. 53",[7.2426275, 51.4873178]],
    [ "10 kV-Station Stadionring 24",[7.233946, 51.4928822]],
    [ "Kirchkimmen Bremer Str. 5",[8.4340319, 53.0657982]],
    [ "Schürenbusch Oldenburger Weg",[8.446075, 53.0869083]],
    [ "Hude Hurreler Str.",[8.42922, 53.1037708]],
    [ "Hurrel Heiligenloh",[8.4038155, 53.0880746]],
    [ "Brettorf Brettorfer Str.",[8.4362454, 52.9689828]],
    [ "Zeltplatz",[11.3324811, 50.7133309]],
    [ "DEL Rudolf-Königer-Str.",[8.6184284, 53.0490079]],
    [ "Holzhausen-04, Bünder Straße",[8.527696, 52.2832623]],
    [ "10 kV-Station Robert-Bosch-Str. 1",[7.2682993, 51.4677708]],
    [ "Gan. Bürsteler Str. 37",[8.5105902, 53.0209405]],
    [ "Rastplatz Stettin",[7.9815013, 51.7245528]],
    [ "Umspannwerk Prenzlauer Berg",[13.4328714, 52.5414525]],
    [ "Angermünde TrSt SERO",[14.0009753, 53.020901]],
    [ "TS Mühlengraben",[13.993764, 53.0123068]],
    [ "Angermünde TrSt Tankstelle",[13.9943847, 53.0285674]],
    [ "Angermünde TrSt Am Wall",[13.9996157, 53.0165014]],
    [ "Angermünde TrSt Seestr.",[14.0053398, 53.0202145]],
    [ "Gan Atlasstr.",[8.5352964, 53.0315889]],
    [ "Gan. Lange Wand",[8.5384671, 53.0297702]],
    [ "Gan. Bergedorfer Str.",[8.5421598, 53.0321264]],
    [ "Poche",[7.9518037, 48.3404918]],
    [ "Dalkeweg",[8.5025845, 51.9083126]],
    [ "Postweg",[10.1620383, 53.5430993]],
    [ "Hamburger Kamp",[10.1568114, 53.5434493]],
    [ "Grafengasse",[11.0321669, 50.975887]],
    [ "Bellavitestraße",[13.1357151, 52.3645961]],
    [ "HH Galileistrasse",[13.140846, 52.3758076]],
    [ "Eisenbahnstraße",[7.9366673, 48.3152337]],
    [ "10 kV Germanenstr. 3/5",[7.2068619, 51.2763929]],
    [ "10 kV-Station Kemnader Str. 535",[7.2520129, 51.4138349]],
    [ "Klattenhof ImStröhen2",[8.4449619, 52.9927191]],
    [ "10 KV V.P.F",[7.2808643, 51.3195062]],
    [ "10 kV-Station Hubertusstr. 8",[7.2160438, 51.472082]],
    [ "Hockensb. Zu den Buchen",[8.4654888, 52.9356731]],
    [ "Trafo 2",[13.5258292, 52.4563209]],
    [ "Trafo 1",[13.5257646, 52.4563438]],
    [ "Seckenheimer Straße",[8.4819521, 49.4791239]],
    [ "Reichskanzler-Müller-Straße",[8.4757919, 49.478485]],
    [ "10 kV-Station Suttner-Nobel-Allee 21",[7.2643224, 51.4674951]],
    [ "Feuerwache West",[7.8191281, 48.354134]],
    [ "Hegel Ginger Str. 26",[7.8283827, 52.8565378]],
    [ "Binnenhafenstraße 14",[8.450333, 49.4974454]],
    [ "Binnenhafenstraße 9",[8.4507032, 49.4965369]],
    [ "Rheinkaistraße 30",[8.4461293, 49.4938109]],
    [ "Rheinkaistraße 19",[8.443973, 49.4956304]],
    [ "Rheinkaistraße 15",[8.4454353, 49.4922538]],
    [ "Rheinkaistraße 11",[8.4467436, 49.4906479]],
    [ "Rheinkaistraße 9",[8.448206, 49.4895067]],
    [ "Rheinkaistraße 5",[8.449989, 49.4882171]],
    [ "Rheinvorlandstraße 7",[8.4541317, 49.4849751]],
    [ "Willy-Brandt-Str.",[7.8863048, 48.3403655]],
    [ "PV EVAG Urbicher Kreuz",[11.096641, 50.9521876]],
    [ "Herman-Hollerith-Straße 1",[11.0893618, 50.9517399]],
    [ "Asola",[11.0913294, 50.9504364]],
    [ "Bahnhofstraße 46/47",[11.034472, 50.9752789]],
    [ "Gericht",[11.0236565, 50.978509]],
    [ "Altes Rathaus",[7.8728449, 48.3420765]],
    [ "Winter",[7.9377227, 48.3132391]],
    [ "EDEKA / Am Bahndamm",[7.9354033, 48.3196164]],
    [ "Werfthallenstraße 33",[8.4443188, 49.5020333]],
    [ "Jungbuschstraße 2",[8.4533304, 49.4946602]],
    [ "Parkring 49a",[8.4556364, 49.4894082]],
    [ "Parkring 47a",[8.4555003, 49.4886721]],
    [ "Kehler Straße",[7.8230709, 48.5213611]],
    [ "Rainer-Haungs-Str.",[7.8250705, 48.3518452]],
    [ "Rainer-Haungs-Str. Mitte",[7.8266445, 48.353694]],
    [ "Gan Fahrener Weg 72",[8.5610935, 53.0396479]],
    [ "Brookseite West Schöpfwerk",[8.4922801, 53.156596]],
    [ "Glüsing Glüsinger Helmer",[8.4804562, 53.1701195]],
    [ "Gutleutkirche",[7.8801328, 48.4018439]],
    [ "PROHOGA",[7.8853593, 48.4109162]],
    [ "Isaak-Blum-Str.",[7.9271212, 48.4428184]],
    [ "Kaufland/Offenburger Str. 11",[7.8418301, 48.3450955]],
    [ "Gottlieb-Daimler-Straße",[8.4962478, 49.4729191]],
    [ "Wildeshausen Altona",[8.4402211, 52.9086012]],
    [ "Brettorf Mühle",[8.4463486, 52.9674235]],
    [ "Neerstedt Heidkamp",[8.4096506, 52.9534387]],
    [ "Technoseum",[8.4986182, 49.4760798]],
    [ "SAP Arena",[8.5185993, 49.4637308]],
    [ "SAP Arena",[8.5186509, 49.4637879]],
    [ "SAP Arena",[8.5172776, 49.4644408]],
    [ "Gottlieb-Daimler-Straße",[8.494971, 49.4743774]],
    [ "Umspannwerk Mehringen",[9.1553203, 52.8314372]],
    [ "Blankenvortweg",[7.9801029, 52.6683622]],
    [ "Stubenriedle",[7.8660995, 48.3163615]],
    [ "Roth Händle Industriehof",[7.8649624, 48.3339126]],
    [ "Lauenhagen",[13.6969227, 53.5257815]],
    [ "Papendorf Funkturm",[13.9298603, 53.4876199]],
    [ "Karlsfelde",[13.7194329, 53.5217017]],
    [ "Groß Miktzow",[13.5937945, 53.5356873]],
    [ "Golm",[13.5627938, 53.5629857]],
    [ "Neetzka Bahnhofstr.",[13.5021359, 53.5322483]],
    [ "Stat. Neetzka Siedlung",[13.5171901, 53.537637]],
    [ "Ueckermünde Klockenberg",[14.0596269, 53.7346201]],
    [ "Ueckermünde Uckerpark 1",[14.0501418, 53.7366495]],
    [ "Klein Henstedt Fischteiche",[8.562996, 52.9582233]],
    [ "10 kV-Station Hans-Dobbertin-Str. 8",[7.2674845, 51.469627]],
    [ "Bahnhofsallee 16",[7.6300049, 51.2206509]],
    [ "Rathausplatz 4 Post",[7.6307076, 51.2179574]],
    [ "Ivenacker Tor",[12.9108079, 53.6973501]],
    [ "Schlittental",[7.847304, 48.3442508]],
    [ "TrSt. LBK",[13.9919821, 53.0232753]],
    [ "Heideweg",[8.9374331, 51.6775978]],
    [ "BSO 144",[10.6979468, 53.9180057]],
    [ "BSO 117",[10.6951577, 53.9122101]],
    [ "BSW 144",[10.6811831, 53.9194212]],
    [ "Tinningstedt",[8.9317503, 54.8115771]],
    [ "Meierhafe",[8.5413739, 52.9798144]],
    [ "Station Viersen",[6.4023424, 51.2449049]],
    [ "Altes Heizwerk/Am Mauernfeld",[7.8516941, 48.3388071]],
    [ "Geroldsecker Vorst.",[7.8956651, 48.340114]],
    [ "Hauptstrasse",[7.8491849, 48.3421213]],
    [ "Hauptstr./Kronenwiese",[7.9365874, 48.4676067]],
    [ "Sportplatz",[7.9230271, 48.3317406]]
];