window.CESIUM_BASE_URL = '/Cesium/';

import * as Cesium from 'cesium';
import {zoomLevelToHeight } from './common.js';

if (!localStorage.getItem("pageReloaded")) {
	localStorage.setItem("pageReloaded", "true");
	window.location.reload(true);
} else {
	console.log("Page already reloaded once. Initializing Cesium...");
	// Initialize your Cesium globe and other code here
}

Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzYTBjYzY2NC0yMGEyLTQxZDYtYmI2MC1hMWE4ZTBlMGNlNjciLCJpZCI6MjU5LCJpYXQiOjE3MzU4NTEwMzF9.nshpaoC5qpRD4F5diwcjwJ3tddp1OjCSXoMrv37Ales';

const layerSettings = {
    "OpenStreetMap": { saturation: 0, brightness: 1.0, contrast: 1.0, hue: 0.0, gamma: 1.0 , alpha: 1.0 },
    "CartoCDNGrayScaleMap": { saturation: 1.0, brightness: 3.0, contrast: 1.0, hue: 0.0, gamma: 1.0, alpha: 1.0 },
	"GoogleMap": { saturation: 0, brightness: 0.8, contrast: 1.2, hue: 0.0, gamma: 1.0, alpha: 1.0 }
};

// Initialize Cesium Viewer for 3D
const openStreetMapProvider = new Cesium.ProviderViewModel({
    name: "OpenStreetMap",
    iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/openStreetMap.png'),
    tooltip: "OpenStreetMap",
	creationFunction: function () {
        return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/'
        });
    }
});


const cartocdnProvider = new Cesium.ProviderViewModel({
    name: "CartoCDNGrayScaleMap",
    iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/stadiaAlidadeSmooth.png'),
    tooltip: "CartoCDNGrayScaleMap",
    creationFunction: function () {
        return new Cesium.UrlTemplateImageryProvider({
            url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png'
        });
    }
});

/*
lyrs Values
---------------
m -	Default map layer with roads, places, and labels.
s -	Satellite imagery without labels.
y -	Hybrid layer: Satellite imagery with roads, labels, and place names overlaid.
t -	Terrain layer with physical features such as elevation and vegetation.
h -	Only roads and place names (useful for overlaying on other imagery).
r -	Street map with a lighter color scheme (designed for mobile use).
*/
const googleMapProvider = new Cesium.ProviderViewModel({
    name: "GoogleMap",
    iconUrl: Cesium.buildModuleUrl('Widgets/Images/ImageryProviders/stadiaAlidadeSmoothDark.png'),
    tooltip: "GoogleMap",
    creationFunction: function () {
        return new Cesium.UrlTemplateImageryProvider({
            url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
			//url: 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=YOUR_API_KEY'
        });
    }
});

let viewer;

export const getViewer = () => {
  if (!viewer) {
	viewer = new Cesium.Viewer('map3d', {
		infoBox: true, // Enables the information box for displaying properties
		creditContainer: document.createElement('div'),
		timeline: false,
		fullscreenButton: false,
		animation: false,
		imageryProviderViewModels: [
			openStreetMapProvider,
			cartocdnProvider,
			googleMapProvider
		],
		selectedImageryProviderViewModel: openStreetMapProvider,
		baseLayerPicker: true,
		terrainProviderViewModels: [],
		backgroundColor: Cesium.Color.WHITE,
		infoBox: false 
		
	});
	//viewer.scene.globe.enableLighting = false; // Disable day/night lighting
	viewer.scene.globe.showGroundAtmosphere = false; // Disable ground atmosphere
	//viewer.scene.skyAtmosphere.show = false; // Disable sky atmosphere
	
	viewer.scene.screenSpaceCameraController.minimumZoomDistance = zoomLevelToHeight[19];
	
	Cesium.subscribeAndEvaluate(viewer.baseLayerPicker.viewModel, 'selectedImagery', function(newSelectedLayer) {
		applySettings(viewer.imageryLayers.get(0), layerSettings[newSelectedLayer.name]);
	});
	
	//for performance
	viewer.scene.globe.maximumScreenSpaceError = 1.5; // Lower value for higher quality
	viewer.scene.globe.preloadSiblings = true;       // Preload neighboring tiles
	viewer.scene.globe.cullWithChildrenBounds = true;


	viewer.scene.globe.maximumSimultaneousTileLoads = 8; // Lower from default (default: 20)
	viewer.scene.screenSpaceCameraController.zoomFactor = 2.0; // Adjust zoom speed (default is 3.0)
	
  }
  return viewer;
}



function setCameraControls(enabled) {
    viewer.scene.screenSpaceCameraController.enableRotate = enabled;
    viewer.scene.screenSpaceCameraController.enableTranslate = enabled;
    viewer.scene.screenSpaceCameraController.enableZoom = enabled;
    viewer.scene.screenSpaceCameraController.enableTilt = enabled;
    viewer.scene.screenSpaceCameraController.enableLook = enabled;
}

function applySettings(layer, settings) {
    if (layer) {
        layer.saturation = settings.saturation;
        layer.brightness = settings.brightness;
		layer.contrast = settings.contrast;
		layer.hue = settings.hue;
		layer.gamma = settings.gamma;
		layer.alpha = settings.alpha;
    }
}







let step = 500000.0;

document.getElementById('zoomIn').addEventListener('click', function() {
//	viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
	const cartographic = Cesium.Cartographic.fromCartesian(viewer.camera.position);
    const currentHeight = cartographic.height;

    // Ensure we don’t go below a safe height
//    const zoomStep = Math.min(step, currentHeight); // Ensure at least 10 meters above the surface
	if (currentHeight <= (step * 2)) {
		if (step == 500000){
			console.log ("old step = ", step);
			step = 100000.0;
			console.log ("reducing step to ", step);
		} else if (step == 100000){
			console.log ("old step = ", step);
			step = 10000;
			console.log ("reducing step to ", step);
		} else if (step == 10000) {
			console.log ("old step = ", step);
			step = 1000;
			console.log ("reducing step to ", step);
		} else if (step == 1000) {
			console.log ("old step = ", step);
			step = 100;
			console.log ("reducing step to ", step);
		} else if (step == 100) {
			console.log ("old step = ", step);
			step = 0;
			console.log ("reducing step to ", step);
		}
	} else {
		if (currentHeight >= 2000000) {
			step = 500000;
		} else if (currentHeight >= 500000) {
			step = 100000;
		} else if (currentHeight >= 30000) {
			step = 10000;
		} else if (currentHeight >= 3000) {
			step = 1000;
		} else if (currentHeight >= 1000) {
			step = 100;
		} else if (currentHeight <= 300) {
			step = 0;
		}
	}	
	console.log ("currentHeight", currentHeight);
	console.log ("new step", step);
	viewer.camera.zoomIn(step); // Adjust zoom distance as needed

	//    viewer.camera.zoomIn(zoomStep);
});

document.getElementById('zoomOut').addEventListener('click', function() {
    viewer.camera.zoomOut(500000); // Adjust zoom distance as needed
});





