export const ukPowerSourceList = ['Biomass', 'Energy', 'PV', 'Gas', 'Pump', 'Reactive', 'Thermal', 'Tidal', 'Wind Offshore', 'Wind Onshore', 'Nuclear', 'Hydro', 'Coal', 'OCGT', 'Waste', 'Demand', 'CHP', 'CCGT'];
const filterTypes = [
    "Biomass",
    "Energy",
    "PV",
    "Gas",
    "Pump",
    "Reactive",
    "Thermal",
    "Tidal",
    "Wind Offshore",
    "Wind Onshore",
];
const typeMappings = [
        { check: (s) => s.startsWith("CCGT"), result: "CCGT" },
        { check: (s) => s.startsWith("CHP"), result: "CHP" },
        { check: (s) => s.startsWith("Coal"), result: "Coal" },
        { check: (s) => s.startsWith("Demand"), result: "Demand" },
        { check: (s) => s.startsWith("Energy"), result: "Energy" },
        { check: (s) => s.startsWith("Gas"), result: "Gas" },
        { check: (s) => s.startsWith("Hydro"), result: "Hydro" },
        { check: (s) => s.startsWith("Nuclear"), result: "Nuclear" },
        { check: (s) => s.startsWith("OCGT"), result: "OCGT" },
        { check: (s) => s.startsWith("Oil"), result: "Oil" },
        { check: (s) => s.startsWith("PV"), result: "PV" },
        { check: (s) => s.startsWith("Pump"), result: "Pump" },
        { check: (s) => s.startsWith("Reactive"), result: "Reactive" },
        { check: (s) => s.startsWith("Thermal"), result: "Thermal" },
        { check: (s) => s.startsWith("Tidal"), result: "Tidal" },
        { check: (s) => s.startsWith("Waste"), result: "Waste" },
        { check: (s) => s.startsWith("Wind Offshore"), result: "Wind Offshore" },
        { check: (s) => s.startsWith("Wind Onshore"), result: "Wind Onshore" },
		{ check: (s) => s.startsWith("Biomass"), result: "Biomass" },
    ];


export function ukPowerSourceType(str) {
    // Iterate through the mapping to find the first match
    for (const mapping of typeMappings) {
        if (mapping.check(str)) {
            if (filterTypes.includes(mapping.result)) {
                if ((mapping.result === 'PV') || (mapping.result === 'Energy' && str.includes('PV'))) {
                    return "ESS+PV";
                }
                return mapping.result;
            }            
        }
    }

    // Default case if no match is found
    //console.log ("Missing String",str);
    return "Misc";
}

export const TILE_BASE_URL = "https://founddigitalmaps.jay-openlayers.org/data";

export const zoomLevelToHeight = {
    1: 20000000,
    2: 10000000,
    3: 5000000,
    4: 2500000,
    5: 1250000,
    6: 650000,
    7: 325000,
    8: 162500,
    9: 81250,
    10: 40625,
    11: 20312.5,
    12: 10156.25,
    13: 5078.125,
    14: 2539.0625,
    15: 1269.53125,
    16: 634.765625,
    17: 317.3828125,
    18: 158.69140625,
    19: 79.345703125
};

export const Germany_PowerLines_Voltages = [
    "110000",
    "380000",
    "220000",
    "20000",
    "110000;30000",
    "380000;110000",
    "380000;220000;110000",
    "220000;110000",
    "110000;0",
    "380000;220000",
    "220000;0",
    "110000;20000",
    "27000",
    "110000;15000",
    "110000;minor",
    "0",
    "380000;220000;220000",
    "30000",
    "10000",
    "380000;380000;110000;110000",
    "110000;110000",
    "65000",
    "15000",
    "60000",
    "200000",
    "medium",
    "400000",
    "3000",
    "6000",
    "400",
    "110000;10000",
    "380",
    "380000;380000;110000;20000",
    "220000;110000;20000",
    "380000;0",
    "500",
    "380000;220000;0",
    "220000;380000;380000",
    "11000",
    "220000;110000;0",
    "132000",
    "380000;110000;110000",
    "380000;380000;110000",
    "110000;65000",
    "35000",
    "220000;380000;380000;380000",
    "220000;220000;110000;110000",
    "380000;380000;220000;220000",
    "155000",
    "150000",
    "110000;middle",
    "22000",
    "450000",
    "110000;110000;110000;20000",
    "63000",
    "110000;35000",
    "225000",
    "25000",
    "110000;25000",
    "110000;220000",
    "35000;10000",
    "380000;220000;110000;25000",
    "220",
    "230",
    "20000;10000",
    "220000;30000",
    "minor",
    "380000;110000;20000",
    "240",
    "110000;35000;10000",
    "fixme",
    "low -> meint das 400V ?",
    "35000;20000",
    "110000;medium",
    "132000;110000",
    "50000",
    "16000",
    "21000",
    "20000 V",
    "380000;220000;110000;30000",
    "33000",
    "10000?",
    "132000;15000",
    "300000",
    "30 kV",
    "750",
    "20000;400",
    "950",
    "320000",
    "400;230",
    "20000;0",
    "110000;16000",
    "23800",
    "690",
    "low",
    "55000",
    "3600",
    "960",
    "medion",
    "20kV",
    "380000;110000;0",
    "380000;110000;15000",
    "990",
    "525000",
    "1500",
    "1000",
    "600",
    "20.000",
    "48",
    "230/400",
    "600000",
    "150",
    "250",
    "3",
    "340",
    "10500",
    "6300",
    "20_000",
    "10_000",
    "220000;380000",
    "400v",
    "30000;10000",
    "5000"
];

export const germany_substation_voltages = [
    "110000",
    "110000;15000",
    "110000;30000",
    "380000",
    "380000;110000",
    "220000;110000",
    "110000;10000",
    "110000;20000",
    "380000;220000;110000",
    "380000;220000",
    "110000;30000;10000",
    "220000",
    "30000;750",
    "110000;30000;750",
    "380000;110000;10000",
    "60000",
    "20000",
    "380000;110000;20000",
    "10000",
    "110000;30000;6000",
    "30000;10000",
    "30000",
    "220000;155000",
    "400",
    "110000;35000",
    "10000;400",
    "25000;1200",
    "15000",
    "110000;medium",
    "20000;400",
    "20000;4000",
    "110000;60000;15000",
    "10000;750",
    "0",
    "132000;110000;15000",
    "110000;11000",
    "30000;20000",
    "100000",
    "65000",
    "60000--",
    "110000;60000",
    "30000;6000",
    "10000;600",
    "6000",
    "110000;30000;11000",
    "11000",
    "30000;11000",
    "110000;60000;20000",
    "380000;220000;110000;65000",
    "60000;15000",
    "20000;400?",
    "35000",
    "110000;20000;11000",
    "110000;65000",
    "medium;low",
    "63000",
    "600",
    "25000",
    "400;20000",
    "20000;6300;400",
    "6300;400",
    "750",
    "110000;10000;400",
    "380000;320000;250000",
    "380000;320000",
    "150000;60000;20000",
    "110000;12500",
    "11000;400",
    "20000;750",
    "110000;20000;10000",
    "380000;300000;150000;110000",
    "15000;400",
    "medium",
    "20000;110000",
    "21000;400",
    "110000;35000;20000",
    "medium;400",
    "1028",
    "20000;10000",
    "660",
    "medium;750",
    "230",
    "5000;220",
    "20000;690",
    "1000",
    "100000;400",
    "110000;10500",
    "440000;380000",
    "15000;1000",
    "10000;230",
    "10 kV",
    "no",
    "15000;low",
    "3000",
    "150000",
    "5000;500",
    "10000;500",
    "110000;33000",
    "30000;15000",
    "low",
    "960",
    "20.000;400",
    "380000;33000",
    "525000;380000",
    "110000;400",
    "*",
    "110000;31500",
    "20000;1000",
    "10;0.4",
    "10000;medium",
    "22000",
    "2000;400",
    "110000;11000;10400;6600",
    "320000",
    "110000;20000;6000",
    "21000",
    "400000",
    "400;230",
    "20",
    "50",
    "380000;220000;110000;30000",
    "600000",
    "800",
    "30000;670",
    "25000;10000",
    "20000;15000",
    "30000;400",
    "320",
    "250000",
    "300000",
    "20000,400",
    "10_kv",
    "10000;low",
    "90000",
    "2000",
    "5000",
    "155000;33000",
    "20_kv",
    "999",
    "5000;20000",
    "high",
    ";400",
    "20000;low",
    "EVR",
    "20000V/400v",
    "50000",
    "10000/400",
    "1ef0000;400"
];