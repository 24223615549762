export const Texas_Substations = [
    [ "Eiland Substation",[-101.48220765370073, 31.995057642907717]],
    [ "Vicksburg Substation",[-101.91826406011535, 33.567475469700284]],
    [ "V. B. Shaw Substation",[-95.28561473258563, 32.74192855465499]],
    [ "Westchase Substation",[-95.55873280331028, 29.718754077259508]],
    [ "Blueridge Substation",[-95.49681232530705, 29.628629472332506]],
    [ "Luling Magnolia Substation",[-97.72564492829174, 29.72101924435148]],
    [ "Freestone Tap",[-96.24170605, 31.549172249999994]],
    [ "Waelder Substation",[-97.30089575000001, 29.678983299999995]],
    [ "Darst Creek Substation",[-97.77847233899419, 29.585052369471743]],
    [ "Galena Park Substation",[-95.23090119235788, 29.745797730220126]],
    [ "Drouet Substation",[-95.28300028936242, 29.667136636635608]],
    [ "Camp Wood Substation",[-100.02259418247127, 29.66420921268693]],
    [ "Utopia Substation",[-99.52178102623847, 29.64620577286048]],
    [ "Leakey Substation",[-99.76190872593115, 29.681075409697954]],
    [ "Tarpley Substation",[-99.2747117022381, 29.66530763588344]],
    [ "Olsen Substation",[-97.56434561594405, 31.776698910914615]],
    [ "Wellington Substation",[-100.21129095, 34.8632709]],
    [ "Shamrock Substation",[-100.20963284999999, 34.853864]],
    [ "Friend Ranch Substation",[-101.14313753583677, 30.70803951346657]],
    [ "Jim Payne Substation",[-102.03998118030168, 32.65406531526579]],
    [ "Fort Lancaster",[-101.77866986213517, 30.663535633439594]],
    [ "Rosita Creek Substation",[-100.40516968016566, 28.69915842120041]],
    [ "Cotulla Substation",[-99.2549959, 28.4481734]],
    [ "Big Wells Substation",[-99.53584105749586, 28.56531900237188]],
    [ "W. A. Parish Station (345 kV)",[-95.62408484901623, 29.480728294568276]],
    [ "Paint Creek Substation",[-99.58066833882826, 33.08004815590608]],
    [ "Chalk Substation",[-101.27643269332764, 32.11123070714153]],
    [ "Runge Substation",[-97.70154374999998, 28.87065485]],
    [ "Nixon Substation",[-97.74079034484755, 29.30813659303573]],
    [ "Holcomb Substation",[-99.36163497066146, 28.03325455561963]],
    [ "Cotulla Substation",[-99.25125488673393, 28.460819451707273]],
    [ "Eagle Hydro Substation",[-100.55205563518113, 28.829748180108687]],
    [ "Eagle Pass Substation",[-100.49041552418531, 28.71604168602675]],
    [ "Conoco-Chittam Ranch Substation",[-100.19722925759771, 28.696427724903263]],
    [ "Escondido Substation",[-100.47445252510636, 28.728473857075333]],
    [ "Maverick Substation",[-100.5505902164685, 28.866760933153998]],
    [ "Wildorado Substation",[-102.20284444193456, 35.21089227502354]],
    [ "Sterling City Substation",[-101.01120143869429, 31.839814286073274]],
    [ "Parker Ranch Substation",[-101.53556365, 30.55341235]],
    [ "Encinal Substation",[-99.35744783645758, 28.046645201163948]],
    [ "La Quinta Substation",[-99.0066386, 27.487472050000004]],
    [ "Zapata Substation",[-99.23374740754761, 26.9328372973962]],
    [ "Mustang Substation",[-102.37044799672837, 32.45299413727345]],
    [ "West Levee Substation",[-96.82598888045472, 32.77763395035196]],
    [ "East Levee Substation",[-96.819714230811, 32.783923017979724]],
    [ "800/900 Network Substation",[-96.81370712082253, 32.787167136679855]],
    [ "28174717",[-97.02539784297377, 32.814789949893886]],
    [ "28469952",[-96.65324947222918, 32.85750525062465]],
    [ "Northwest Carrollton Substation",[-96.9385021721852, 32.984461406083405]],
    [ "Whitestone Substation",[-97.83049723894625, 30.52005674935265]],
    [ "Balcones Substation",[-97.8301995043914, 30.46104063272277]],
    [ "Buttercup Substation",[-97.82387564360327, 30.486911706500976]],
    [ "Avery Ranch Substation",[-97.78782390401433, 30.49135488543124]],
    [ "Blockhouse Substation",[-97.83824140348743, 30.54837261856177]],
    [ "Lake Hubbard Station",[-96.54769962211195, 32.83697961512869]],
    [ "Hilltop Substation",[-97.94148921256351, 29.898601839141342]],
    [ "Strahan Substation",[-97.93242595705254, 29.88966313788931]],
    [ "Zorn Substation",[-97.94956232390592, 29.77266343476722]],
    [ "Tri-Corner Substation",[-96.53752794692404, 32.55366520659568]],
    [ "Industrial Boulevard Substation",[-96.78914105133323, 32.759114999795685]],
    [ "Spicewood Substation",[-97.79692313334661, 30.420292101705826]],
    [ "San Marcos Substation",[-97.91797047029999, 29.877431305215957]],
    [ "Redwood Substation",[-97.94475575132671, 29.850531004395446]],
    [ "Marshall Ford Lower Switchyard",[-97.90755033343771, 30.389976741481096]],
    [ "Pedernales Electric Cooperative",[-98.00335646543611, 29.822985707999262]],
    [ "Wimberley Substation",[-98.10269849794979, 29.99968271625994]],
    [ "Sargent Road Substation",[-96.77416919735724, 32.727861233989444]],
    [ "Parkdale Substation",[-96.72408837165194, 32.77571246086548]],
    [ "Canyon Substation",[-97.90009591475565, 29.911482821770548]],
    [ "Cedar Crest Substation",[-96.80232048156648, 32.72174393803447]],
    [ "Cedar Crest Switching Station",[-96.8042672110756, 32.72183727098996]],
    [ "Watermill Substation",[-96.7261784096957, 32.558540448028445]],
    [ "Wilmer Substation",[-96.6859887775712, 32.58199229275812]],
    [ "Prairie Creek Substation",[-96.66281243860753, 32.7772356982858]],
    [ "W. A. Parish Station (138 kV)",[-95.63042054642267, 29.48278017510261]],
    [ "39610474",[-95.62284978261972, 29.47425125850088]],
    [ "Comanche Peak Station",[-97.78986113466468, 32.297773192414354]],
    [ "South Texas Project Switchyard",[-96.05017106859903, 28.79955927552637]],
    [ "East Mesquite Substation",[-96.57248748460124, 32.775291666080015]],
    [ "Forney Substation",[-96.48688650281558, 32.75787765236176]],
    [ "Trinity Bay Substation",[-94.91163354271714, 29.68922952439209]],
    [ "Cedar Bayou Plant Substation",[-94.92489554146442, 29.748056665892285]],
    [ "Benbrook Substation",[-97.47139291864016, 32.684944725816166]],
    [ "40810727",[-97.41751470050384, 32.67350308516715]],
    [ "40811240",[-97.40281052195544, 32.657967687904204]],
    [ "Calmont Substation",[-97.4497208, 32.73614945]],
    [ "40910567",[-97.47833312027952, 32.74907149332033]],
    [ "Faron Street Substation",[-97.40513321937961, 32.70970928514355]],
    [ "Baytown Substation",[-95.00483324633804, 29.733431490080758]],
    [ "Warvue Substation",[-94.89751911433399, 29.838100607726606]],
    [ "S.R. Bertron Plant",[-95.06048534683616, 29.725080694199487]],
    [ "Lynch Substation",[-95.07247072823387, 29.767576309324543]],
    [ "41378006",[-95.07235927667993, 29.712721757685323]],
    [ "PEMEX Substation",[-95.1238958709801, 29.721985756596453]],
    [ "41527368",[-95.1114915, 29.720109949999998]],
    [ "41702264",[-95.11302757439297, 29.72721012099706]],
    [ "Channelview Substation",[-95.1509275603565, 29.775611122446186]],
    [ "Industrial Substation",[-95.18585332584523, 29.752124123421392]],
    [ "Depot Substation",[-95.12684544999999, 29.752083750000004]],
    [ "43439628",[-97.3893023256323, 32.66886088811915]],
    [ "43705092",[-95.2269433562819, 29.720380272901657]],
    [ "Clinton Substation",[-95.3011662852143, 29.75554143993844]],
    [ "University Substation",[-95.33150258305771, 29.716791125775234]],
    [ "Powell Substation",[-95.03884824260707, 29.62646447580035]],
    [ "Himont Substation",[-95.05593301990616, 29.634305852989055]],
    [ "Lomax Substation",[-95.08420818662354, 29.63385566902374]],
    [ "Garden Villas Substation",[-95.3145245683327, 29.650899350154077]],
    [ "Busch Substation",[-95.26785125238473, 29.774898669363274]],
    [ "South Houston Substation",[-95.22515236707628, 29.628973460707677]],
    [ "Gulfgate Substation",[-95.30010057919215, 29.69310953015244]],
    [ "Dyer Substation",[-106.44258117567114, 31.8012135949116]],
    [ "51445934",[-95.03949062324524, 29.694840738349047]],
    [ "51715260",[-95.07267637442472, 29.700951087249177]],
    [ "51715264",[-95.07441265, 29.705999100000003]],
    [ "Grant Substation",[-95.40150041426847, 29.70339790741293]],
    [ "Garrott Substation",[-95.38586823155326, 29.732847401739185]],
    [ "51916168",[-95.0220148, 29.75897145]],
    [ "Cardiff Substation",[-95.15470761841152, 29.653639011776256]],
    [ "Spencer Substation",[-95.19076889358607, 29.657841156600913]],
    [ "52907101",[-94.92142868679383, 29.82899447017584]],
    [ "Mont Belvieu Substation",[-94.89400905538744, 29.840150589490303]],
    [ "Warvue Substation",[-94.89316291262789, 29.836924970378725]],
    [ "Channelview Cogen Substation",[-95.12347157325057, 29.83664789309992]],
    [ "NASA Substation",[-95.08525021258075, 29.563127388685878]],
    [ "Polaris Substation",[-95.09882019903546, 29.57842082775617]],
    [ "Armand Substation",[-95.08808395531399, 29.624016628064805]],
    [ "Southeast Substation",[-95.1511252609794, 29.629665374585333]],
    [ "P. H. Robinson Switching Station",[-94.98242183963077, 29.487515802346564]],
    [ "Ellington Substation",[-95.18056717164625, 29.58815505220718]],
    [ "East Side Substation",[-95.35311982752827, 29.747041995444924]],
    [ "Pasadena Substation",[-95.22331253354338, 29.689968861990952]],
    [ "Magnolia Park Substation",[-95.31093974139843, 29.74371291963599]],
    [ "Downtown Substation",[-95.37372040172185, 29.75526647642496]],
    [ "Orient Substation",[-100.32687675004404, 31.653940399573866]],
    [ "Webster Distribution Substation",[-95.10749926405859, 29.538767691049376]],
    [ "Webster Substation",[-95.10484781034054, 29.5284753448025]],
    [ "Kirby Substation",[-98.392387220961, 29.465320860424313]],
    [ "Gibbons Creek Station",[-96.08147074109421, 30.622542576933654]],
    [ "Keith Switching Station",[-96.0913737745956, 30.651084536413254]],
    [ "Jack Creek Substation",[-96.2589024372747, 30.85888117620723]],
    [ "Sealy Substation",[-96.17082977226148, 29.7791376468468]],
    [ "Fayette Plant #1 Substation",[-96.75517081182818, 29.918296494958117]],
    [ "Fayette Plant 138 kV Substation",[-96.75511745724441, 29.912299592142567]],
    [ "Holman Substation",[-96.87838355056684, 29.795554750203642]],
    [ "Wallis Substation",[-96.0762112982646, 29.630962729213053]],
    [ "Navasota Substation",[-96.02734183744663, 30.37520231848007]],
    [ "East Bernard Substation",[-96.07025389607615, 29.52356705644735]],
    [ "Peters Substation",[-96.20057453392144, 29.84784089862299]],
    [ "Fayetteville Substation",[-96.68772375890528, 29.962796363649062]],
    [ "Bellville Substation",[-96.26344731134469, 29.959250506345445]],
    [ "Bellville South Substation",[-96.25590075889241, 29.92424498968207]],
    [ "Cagnon Road Substation",[-98.71819355897256, 29.416134504605235]],
    [ "Anderson Substation",[-98.71839312265524, 29.483820162119812]],
    [ "Helotes Substation",[-98.68187369288012, 29.548170785055625]],
    [ "Great Southwest Substation",[-97.07381162630458, 32.74630609543282]],
    [ "Chappel Hill Substation",[-96.32541594895383, 30.13850668861868]],
    [ "Highway 36 Substation",[-96.37388658479524, 30.122613165682957]],
    [ "Waller Substation",[-96.08320995648691, 30.076622351560935]],
    [ "Salem Substation",[-96.42557741148674, 30.11116611911831]],
    [ "Brenham Substation",[-96.39454075479959, 30.15060835523901]],
    [ "Glidden Substation",[-96.57189853329514, 29.696886874331554]],
    [ "Brenham North Substation",[-96.38181860045209, 30.18183061454671]],
    [ "Crabb River Road Substation",[-95.6985156962039, 29.562798045412418]],
    [ "Franz Substation",[-95.75385718866511, 29.802967721734223]],
    [ "Mason Road Substation",[-95.73901055322166, 29.745539279595498]],
    [ "O'Brien Substation",[-95.71610191078476, 29.670697324807964]],
    [ "O'Brien Substation",[-95.7175743178451, 29.67320989009023]],
    [ "Jewett Substation",[-96.13330357531888, 31.371109674277363]],
    [ "Wharton Substation",[-96.10767739119471, 29.313623629246067]],
    [ "South Texas Project Tap",[-96.10722434225814, 28.87592725236987]],
    [ "Hillje Substation",[-96.23681280207245, 29.029810159601123]],
    [ "Ball Park Substation",[-98.48891067334968, 29.396712244262815]],
    [ "Navasota Substation",[-96.09101033750396, 30.383016054541603]],
    [ "East Substation",[-96.29895712862738, 30.659449990205264]],
    [ "College Station Switching Station",[-96.29429146295715, 30.627726166170763]],
    [ "Tabor Substation",[-96.36463074058625, 30.71599995704555]],
    [ "Twin Oaks Substation",[-96.48437134003478, 31.185543685734803]],
    [ "Lewis Creek Substation",[-95.52150469608948, 30.437306060116306]],
    [ "Dansby Substation",[-96.45884992753662, 30.7221963150263]],
    [ "Limestone Station",[-96.24704071756722, 31.424369624948618]],
    [ "Grimes Substation",[-95.90937170839342, 30.62398523172021]],
    [ "Willow Springs Substation",[-96.61393127862515, 29.950199873755935]],
    [ "New Bremen Substation",[-96.39940159507746, 29.919743930891446]],
    [ "Somerville Substation",[-96.5217482841534, 30.33856070892363]],
    [ "King Substation",[-95.21631879686747, 29.91534224723237]],
    [ "Streich Road Substation",[-98.37398480807765, 29.27580550967114]],
    [ "Grissom Road Substation",[-98.64893243597493, 29.47645813130202]],
    [ "Highland Hills Substation",[-98.40910020723713, 29.359010705646504]],
    [ "Grandview Substation",[-98.4441709317113, 29.40494490179738]],
    [ "104746708",[-96.75996766984632, 32.9265993790195]],
    [ "Toyota Substation",[-98.53724610551122, 29.268979048656245]],
    [ "Howard Substation",[-98.57234335145318, 29.280640901218202]],
    [ "Pipe Creek Substation",[-98.89881609229049, 29.71537173945009]],
    [ "Summit Substation",[-97.72378545201195, 30.39812938422806]],
    [ "Elmendorf Substation",[-98.32216944315365, 29.270216049484514]],
    [ "Coliseum Substation",[-98.44432758112882, 29.43262405153203]],
    [ "Holbrook Substation",[-98.41659835201285, 29.46347319933711]],
    [ "Saint Hedwig Substation",[-98.3967320406483, 29.427124403671808]],
    [ "Brooks Field Substation",[-98.44980150329076, 29.33457742676357]],
    [ "Olmos Substation",[-98.49110899998786, 29.484189093518037]],
    [ "Tenth Street Substation",[-98.47983830090786, 29.43266067685314]],
    [ "Skyline Substation",[-98.40877840393847, 29.58055910632256]],
    [ "Weiderstein Substation",[-98.26526569156158, 29.599087874541354]],
    [ "Westside Substation",[-98.56090950732442, 29.439781071070755]],
    [ "Pinn Road Substation",[-98.62344638847127, 29.413259989717314]],
    [ "Westover Hills Substation",[-98.69434293658378, 29.481124573508424]],
    [ "VLSI Substation",[-98.67852117682439, 29.473575197840606]],
    [ "De Zavala Road Substation",[-98.56636076485408, 29.571523614497362]],
    [ "Medical Center Substation",[-98.57571602152545, 29.52076715856377]],
    [ "Castle Hills Substation",[-98.5258404625644, 29.538524739133884]],
    [ "La Sierra Substation",[-98.6080671229292, 29.61718051427345]],
    [ "UTSA Substation",[-98.62593480451085, 29.586508768127523]],
    [ "Oaks Substation",[-98.59059435366272, 29.130858085656048]],
    [ "Texas Research Substation",[-98.7877590104404, 29.420523730465533]],
    [ "Quintana Road Substation",[-98.55651874923363, 29.372974974827095]],
    [ "South San Antonio Substation",[-98.53974794057747, 29.367676240673877]],
    [ "Merida Street Substation",[-98.53977692589926, 29.40948522414473]],
    [ "Hortontown Substation",[-98.09814402438252, 29.71334187245192]],
    [ "GPI Substation",[-98.17796611794567, 29.677061822471344]],
    [ "Comal Substation",[-98.13500546731021, 29.70682939264357]],
    [ "TXI Substation",[-98.04057119355863, 29.803478454181043]],
    [ "Henne Substation",[-98.05106470887111, 29.77360586720465]],
    [ "Highway 46 Substation",[-98.24521058692093, 29.75877464795691]],
    [ "Ballinger Substation",[-99.94455610931922, 31.741188559966194]],
    [ "Tuscola Substation",[-99.79001747924245, 32.20325569657996]],
    [ "Fannin Substation",[-95.40847432386693, 29.689591458342093]],
    [ "Stadium Substation",[-95.40650761656146, 29.685277764384743]],
    [ "Burns Substation",[-97.73433145000001, 26.219096200000003]],
    [ "Oak Ridge Substation",[-95.4389325543999, 30.1699248314535]],
    [ "Lehigh Substation",[-97.8659592085695, 30.029031400093952]],
    [ "Georgetown Substation",[-97.6870814252709, 30.632522865590158]],
    [ "Gabriel Substation",[-97.66078022684395, 30.66728714405934]],
    [ "Hays Energy Station",[-97.99010165584937, 29.783048276935926]],
    [ "Somerset Substation",[-98.64943545532861, 29.24210705133797]],
    [ "Moore Substation",[-99.04847991167765, 29.079430255070093]],
    [ "Pearsall Switchyard",[-99.09261716591242, 28.927386370694606]],
    [ "Robinson Northwest Substation",[-97.1411724273746, 31.473240554561404]],
    [ "Groesbeck Substation",[-96.67740571791742, 31.43759038825611]],
    [ "Business Park Substation",[-96.42250475995543, 30.709176084540186]],
    [ "Farrar Substation",[-96.28191522055295, 31.384087041069563]],
    [ "Rattlesnake Road Substation",[-96.96046746428799, 31.473527151383138]],
    [ "Campbell Substation",[-95.52467150000001, 29.818241949999997]],
    [ "Addicks Substation",[-95.57308218104336, 29.822794051095553]],
    [ "Brittmoore Substation",[-95.57259277576904, 29.79438194800006]],
    [ "Satsuma Substation",[-95.5763761052783, 29.881388274785053]],
    [ "Willow Substation",[-95.56645857515684, 29.99676126504701]],
    [ "San Felipe Substation",[-95.4478756495314, 29.75060599613684]],
    [ "Eureka Substation",[-95.43343780144863, 29.794751326181416]],
    [ "Sauer Substation",[-95.55285829080384, 29.788730653026942]],
    [ "Klein Substation",[-95.57031493369581, 30.023827186619457]],
    [ "Minerva Substation",[-96.95458433313338, 30.745866063026483]],
    [ "Webberville Substation",[-97.48797523515499, 30.24882395273746]],
    [ "Austrop Substation",[-97.48729153517019, 30.2513014316889]],
    [ "Sim Gideon Station",[-97.26880031149017, 30.146048279143056]],
    [ "Tomball Substation",[-95.60599501883887, 30.090156019498203]],
    [ "Rothwood Substation",[-95.48995394310465, 30.090760277639593]],
    [ "Kuykendahl Substation",[-95.5270287452611, 30.08915872274975]],
    [ "Cameron Substation",[-95.64997000173987, 29.918715292942768]],
    [ "Westfield Substation",[-95.42212028447305, 30.020632518828815]],
    [ "Pinehurst Substation",[-95.67295807920608, 30.161362889355992]],
    [ "Louetta Substation",[-95.48049188153423, 30.045462589985178]],
    [ "Flewellen Substation",[-95.82088977827051, 29.695403368396136]],
    [ "Fort Bend Substation",[-95.80047377899918, 29.563211711264493]],
    [ "Brazos Valley Substation",[-95.81246074071382, 29.627046475046892]],
    [ "Orchard Substation",[-95.95376001463164, 29.539258819233417]],
    [ "Treaschwig Substation",[-95.35080874000484, 30.043928026008896]],
    [ "Hardy Substation",[-95.35339099670753, 29.81872672307702]],
    [ "North Side Substation",[-95.34966552675934, 29.784128493434647]],
    [ "Caterpillar Substation",[-97.99719353307435, 29.57927246820167]],
    [ "Seguin West Substation",[-97.98758282571927, 29.58622908198417]],
    [ "Airline Substation",[-95.38937619999999, 29.818677299999997]],
    [ "Todd Substation",[-95.48302296218208, 29.81600577393224]],
    [ "White Oak Substation",[-95.43482721617049, 29.815178197330177]],
    [ "Hockley Substation",[-95.84609188968507, 30.02128392536553]],
    [ "Hillcroft Substation",[-95.49769092591235, 29.722092281027557]],
    [ "Jeanetta Substation",[-95.53669263308352, 29.71871901470685]],
    [ "Westwood Substation",[-95.53668045, 29.689947249999996]],
    [ "Missouri City Substation",[-95.52998954008395, 29.612644948442643]],
    [ "Roark Substation",[-95.56132253667539, 29.66604173893311]],
    [ "Knight Substation",[-95.41309058937333, 29.672251360162207]],
    [ "Stafford Substation",[-95.58918950458559, 29.633726724846596]],
    [ "Alief Substation",[-95.58180836946262, 29.700432042051137]],
    [ "Hayes Substation",[-95.57437449999999, 29.746347999999994]],
    [ "Deihl Substation",[-95.47605629121587, 29.85469726483882]],
    [ "Fairbanks Substation",[-95.5019191807206, 29.89997161359337]],
    [ "Humble Substation",[-95.23506430836832, 30.000072513093563]],
    [ "Crosby Substation",[-95.0202289912816, 29.87269098317029]],
    [ "Haney Substation",[-94.99073998705214, 29.82106805375894]],
    [ "Brays Substation",[-95.44783569403005, 29.68375358210407]],
    [ "Jacinto Substation",[-95.06385704415781, 30.37043204878085]],
    [ "Five Points Substation",[-98.50672354183033, 29.437260800328527]],
    [ "Laredo Street Substation",[-98.50164082342305, 29.415354980853163]],
    [ "Fern Street Substation",[-98.47948014249808, 29.404779383755596]],
    [ "Encino Park Substation",[-98.46965390784365, 29.615636926207607]],
    [ "Nacogdoches Road Substation",[-98.42431246611157, 29.53155271395341]],
    [ "Zenith Substation",[-95.77738077571694, 29.910055728829388]],
    [ "Pecan Bayou Substation",[-99.52656813015965, 32.366456625123455]],
    [ "Eskota Switching Station",[-100.24256130603946, 32.49218580451483]],
    [ "Sunchase Substation",[-97.16530632711485, 26.09031445398494]],
    [ "Downie Substation",[-99.69954899338947, 29.22699152047618]],
    [ "Dallam County Substation",[-102.53173312498785, 36.08034220581497]],
    [ "Dalhart Substation",[-102.52881062513691, 36.064853953957716]],
    [ "Busch Substation",[-95.27273277743183, 29.77625029443006]],
    [ "Lewisville Substation",[-97.00970990964774, 33.06602471580217]],
    [ "Horse Hollow 1 & 2 Substation",[-100.0235080800038, 32.1990507831846]],
    [ "Horse Hollow Kendall Tie",[-100.01711344628475, 32.19025911338762]],
    [ "Bluff Creek Substation",[-100.02305655, 32.196514500000006]],
    [ "Horse Hollow 2 & 3 Switching Station",[-100.02816135077632, 32.19352377110055]],
    [ "Talley Road Substation",[-98.8099031022171, 29.523546690874127]],
    [ "Bergheim Substation",[-98.5263185447081, 29.788738655412082]],
    [ "Fair Oaks Substation",[-98.59318594509276, 29.794944981282693]],
    [ "Antler Substation",[-98.41340055134874, 29.7767857897942]],
    [ "Kendall Substation",[-98.8866871845838, 29.985016722930254]],
    [ "Boerne Substation",[-98.72576107544711, 29.80420081407236]],
    [ "Comfort Substation",[-98.90316969619418, 29.97795752648084]],
    [ "Welfare Substation",[-98.7606052308217, 29.886833000305895]],
    [ "Horse Hollow Generation Tie",[-98.88764882641371, 29.987966087568925]],
    [ "Clear Springs Substation",[-98.0170592767188, 29.651184749826992]],
    [ "Lockhart Substation",[-97.66125788710573, 29.87081307841099]],
    [ "Venus Substation",[-97.07274279081244, 32.44397408823364]],
    [ "147131475",[-97.08079345957773, 32.60510828925625]],
    [ "147135004",[-97.07054631758012, 32.71389336582726]],
    [ "Betka Substation",[-95.86120005, 30.01774995]],
    [ "Porter Substation",[-95.34220873712572, 30.18640384860466]],
    [ "Parkway Substation",[-95.10636260558617, 30.090626394208474]],
    [ "Tamina Substation",[-95.34666585000002, 30.187044150000002]],
    [ "New Caney Substation",[-95.21961257165057, 30.134929709016642]],
    [ "Aldine Substation",[-95.37038462770586, 29.901725783718998]],
    [ "Berry Substation",[-95.3536726335036, 29.83953401871405]],
    [ "Liberty Substation",[-95.28552239999999, 29.807004349999996]],
    [ "Hitchland Substation",[-101.39170282729113, 36.49650748797634]],
    [ "147569609",[-96.59381095634201, 33.315846999302984]],
    [ "Echo Substation",[-95.52068586502195, 29.785420175614437]],
    [ "Bluffs Substation",[-100.5072125277307, 31.435506276074168]],
    [ "Brownwood South Substation",[-98.98189624999999, 31.676045249999998]],
    [ "South Beaumont Substation",[-94.07583196877299, 30.033344032355554]],
    [ "152541637",[-106.53872240862403, 31.813163091391154]],
    [ "Goodrich Substation",[-94.96799677591315, 30.620331459593537]],
    [ "Shepherd Substation",[-94.964913971141, 30.53759757680154]],
    [ "Tiki Island Substation",[-94.90099936197714, 29.30424568310897]],
    [ "Parsons Street Substation",[-98.47948910233283, 29.41213777444331]],
    [ "Cypress Substation",[-94.25720251691962, 30.30363408140458]],
    [ "Eagle Creek Substation",[-98.2538650014985, 29.224816047051732]],
    [ "Rocky Creek Substation",[-97.43995890337924, 32.59566441277693]],
    [ "Dobbin Substation",[-95.7816478352278, 30.351166634209665]],
    [ "Singleton Substation",[-95.97327955617243, 30.661312235218517]],
    [ "Dessau Substation",[-97.64304389226999, 30.373491339421367]],
    [ "Gilleland Creek Substation",[-97.5740888867538, 30.409970408637104]],
    [ "Rye Substation",[-94.76563956102078, 30.452467577732065]],
    [ "Hill Country Substation",[-98.57167791595887, 29.619386231122828]],
    [ "Notrees Substation",[-102.83351316122226, 31.997621549049587]],
    [ "Panther Creek Wind 3 Substation",[-101.13587649026951, 31.982551797155846]],
    [ "Broadview Substation",[-98.58631933440778, 29.479035852790137]],
    [ "Newman Substation",[-106.4348108583527, 31.984161564101665]],
    [ "Calmont Substation",[-97.44932302282582, 32.734571591206105]],
    [ "Caliente Substation",[-106.25054875526331, 31.82609251463255]],
    [ "Tolk Substation",[-102.57111303987685, 34.181227913369206]],
    [ "Potter County Interchange",[-101.92290985000001, 35.31027865]],
    [ "Stonegate Substation",[-98.45491699168974, 29.679228686099602]],
    [ "Bandera Road Substation",[-98.60984800215688, 29.498744735682063]],
    [ "Fredericksburg Road Substation",[-98.5598734976648, 29.502933942159213]],
    [ "Chulie Substation",[-98.47709358931012, 29.511311512358297]],
    [ "North DC-Tie",[-99.18338573681798, 34.085103553858566]],
    [ "Riley Substation",[-99.14533490000002, 34.08526155]],
    [ "Oklaunion North Substation",[-99.18070786393525, 34.086174365581016]],
    [ "Oklaunion South Substation",[-99.1807520187168, 34.082302207966094]],
    [ "East DC-Tie",[-94.84405260128662, 33.05830324512227]],
    [ "165669468",[-94.55103386972166, 32.84931970613346]],
    [ "165669469",[-94.54936236369417, 32.84863663567073]],
    [ "Northwest Texarkana Substation",[-94.1760640849434, 33.48833176602176]],
    [ "Diana Substation",[-94.69829034167918, 32.74432791190367]],
    [ "165784107",[-94.70021314663981, 32.74437528386894]],
    [ "165788673",[-93.57248577173849, 31.142307530805034]],
    [ "Leander Substation",[-97.84160419204684, 30.585721591200183]],
    [ "Hartburg Substation",[-93.73840120311232, 30.266105858021913]],
    [ "TUCO Interchange",[-101.8426309452394, 33.86866657144961]],
    [ "Antelope Station Switchyard",[-101.84260865, 33.86547695]],
    [ "Plant X Substation",[-102.40968673159513, 34.16504134986741]],
    [ "Deaf Smith Interchange",[-102.36076740162592, 34.82078979747762]],
    [ "Valley Switching Station (345 kV)",[-96.37033207988833, 33.62746885772139]],
    [ "Kiowa Switching Station",[-96.35766837323213, 33.63247330590251]],
    [ "Valley Switching Station (138 kV)",[-96.36813756174847, 33.62893364720434]],
    [ "Fisher Road Substation",[-98.42937975861177, 33.88649840029886]],
    [ "Bowman Substation",[-98.60086012232463, 33.81037597053875]],
    [ "Graham Substation",[-98.60945252503262, 33.13841848497936]],
    [ "171410632",[-98.43146926249526, 33.8868636804993]],
    [ "Jacksboro Substation",[-98.10680136698551, 33.27719369732938]],
    [ "171417802",[-98.37270175720738, 33.20304911261634]],
    [ "Henderson Substation",[-97.95293681567297, 33.10268503236214]],
    [ "171423698",[-97.95499330217122, 33.10211809083246]],
    [ "Parker Substation",[-97.67629234159372, 32.80624469830868]],
    [ "Willow Creek Substation",[-97.91031860128152, 33.05617898026314]],
    [ "171435063",[-97.90980208326846, 33.058934432493906]],
    [ "171451771",[-98.33967638753644, 33.04436232887343]],
    [ "Eagle Mountain Substation",[-97.47707368149821, 32.90827466738008]],
    [ "Azle Substation",[-97.51204732903408, 32.85782963672823]],
    [ "171477443",[-97.3744920514754, 32.89701403322527]],
    [ "Alliance Substation",[-97.30596702964313, 32.95664152372645]],
    [ "Roanoke Substation",[-97.26156489431274, 32.991933392429246]],
    [ "Highlands Substation",[-97.03524488811338, 33.07216974814963]],
    [ "Texas-New Mexico Power",[-97.0094632425143, 33.063708256748676]],
    [ "Jones Street Substation",[-97.00399785893946, 33.0641846357584]],
    [ "171583253",[-97.60096586760848, 32.71972756106514]],
    [ "West Denton Substation",[-97.21917913618707, 33.17576666753145]],
    [ "Anna Substation",[-96.63942534168949, 33.317786714485464]],
    [ "Farmersville Substation",[-96.33793826686961, 33.190711846615265]],
    [ "Valley South Substation",[-96.37331972516353, 33.61394530693481]],
    [ "171694620",[-96.52712772644537, 33.32156753030903]],
    [ "Paris Substation",[-95.58104118121071, 33.639047427808904]],
    [ "171704785",[-95.69829659999998, 33.63253735]],
    [ "171704787",[-96.15885332889572, 33.63366034373467]],
    [ "Colony Substation",[-96.88381006749557, 33.08482808912222]],
    [ "Bridges Substation",[-96.85976845739758, 33.12321867517494]],
    [ "Hebron Substation",[-96.90397330526643, 33.04828666646854]],
    [ "Carrollton Frankford Road Substation",[-96.93052008813154, 32.99865161688219]],
    [ "Roman Juarez Substation",[-96.84128925174156, 33.15827750459129]],
    [ "Brazos Electric",[-96.85968057385, 33.06025430750507]],
    [ "171722567",[-96.8063990489587, 33.06128784418637]],
    [ "West Plano Substation",[-96.7773203577775, 33.01276885694781]],
    [ "Mulberry Creek Substation",[-99.82259436564361, 32.523890239912866]],
    [ "Long Creek Substation",[-99.54140971638917, 32.62926280580152]],
    [ "Hackberry Wind Substation",[-99.42282495, 32.76842585]],
    [ "Cook Field Road Substation",[-99.32804825618464, 32.749979462136416]],
    [ "Tonkawa Substation",[-100.6842024868628, 32.530732142050425]],
    [ "Radium Substation",[-99.99351631650416, 32.826817799475464]],
    [ "Champion Wind Farm Substation",[-100.64802298391199, 32.39873533187871]],
    [ "Pyron Wind Farm Substation",[-100.69251065000002, 32.57969115000001]],
    [ "171793193",[-98.90575660452916, 33.10326100830449]],
    [ "Inadale Wind Farm Substation",[-100.59673002892292, 32.49778268536233]],
    [ "Roscoe Wind Farm Substation",[-100.66604143983797, 32.4699675284851]],
    [ "Sweetwater East Substation",[-100.21692958068803, 32.46400401183112]],
    [ "Morgan Creek Substation",[-100.91610728877339, 32.33412682897815]],
    [ "Scurry County South Substation",[-100.8617781526022, 32.59840545373135]],
    [ "San Angelo Red Creek Substation",[-100.32112736494277, 31.528975694942698]],
    [ "Callahan Divide Wind Substation",[-99.97407734739512, 32.30055261548584]],
    [ "Comanche Switching Station",[-98.5532897245601, 31.8689600253831]],
    [ "171853242",[-97.72675856103172, 32.3787169050338]],
    [ "Wolf Hollow Substation",[-97.73213134382331, 32.3355137925003]],
    [ "Monticello substation",[-95.04203582226337, 33.09167406546502]],
    [ "171900306",[-95.58788710000002, 33.6313744]],
    [ "Everman Substation",[-97.25157134225935, 32.6400434506718]],
    [ "Sweetwater Wind 1 Substation",[-100.35113652198349, 32.437935893433135]],
    [ "Loraine Substation",[-100.7271692890856, 32.378503519583575]],
    [ "Bitter Creek Substation",[-100.3521402997934, 32.43783181105397]],
    [ "Sweetwater Wind 3 Substation",[-100.41993549999998, 32.337948350000005]],
    [ "Sweetwater Wind 4 Substation",[-100.49521001089808, 32.28587327332549]],
    [ "Loraine Wind Farm Substation",[-100.74595712147365, 32.445104444827315]],
    [ "Sweetwater Wind 5 Substation",[-100.48761712384679, 32.24204420872712]],
    [ "171941219",[-97.26004827943406, 33.219406255206685]],
    [ "Harmony Hills Substation",[-98.50600377522842, 29.554008883734955]],
    [ "172027675",[-96.96105961729492, 32.66553342142148]],
    [ "172027706",[-97.07752810343686, 32.55103746288405]],
    [ "172027711",[-97.21408394844751, 32.63682275502782]],
    [ "Royse Substation",[-96.35077424650665, 32.9692516508899]],
    [ "Allen Switching Station",[-96.70032799771508, 33.06009458150625]],
    [ "Sulphur Springs Switching Station",[-95.6164958192505, 33.085737857413925]],
    [ "Austin Road Substation",[-98.4080110722869, 29.503904829474763]],
    [ "Fratt Substation",[-98.34311177384518, 29.54853312164672]],
    [ "Parkway Substation",[-98.28991109816918, 29.58270337974505]],
    [ "Twin Buttes Substation",[-100.59802196115338, 31.513799143282856]],
    [ "NextEra Energy",[-100.8344754945266, 31.87043542360427]],
    [ "Divide Substation",[-100.77706986656517, 31.869521088307604]],
    [ "NextEra Energy",[-100.77926859084117, 31.870217499405868]],
    [ "Gasconades Creek Substation",[-100.83018435, 31.9393262]],
    [ "Goat Wind Substation",[-100.8270287760154, 31.94075818032092]],
    [ "Panther Creek Wind 1 Substation",[-101.45085780000001, 32.1004401]],
    [ "Quail Substation",[-102.31627455365175, 31.840003845700465]],
    [ "Elbow Creek Wind Farm",[-101.39608965000001, 32.124034349999995]],
    [ "172212642",[-102.32740203610877, 31.83863265090382]],
    [ "Longshore Substation",[-101.45233624163893, 32.100303252073694]],
    [ "Moss Substation",[-102.49572571235848, 31.813199515542262]],
    [ "Ector Hillmont Substation",[-102.43329966346738, 31.92354467225523]],
    [ "Adobe Meadows Substation",[-102.07316065, 32.06254810000001]],
    [ "Gardendale Substation",[-102.25175629057743, 32.0361085143927]],
    [ "Ben Davis Substation",[-96.61207568358272, 32.957765770121945]],
    [ "McCree Substation",[-96.66343235923087, 32.8724014921624]],
    [ "Martin Lake Substation",[-94.57464818768452, 32.25700877659406]],
    [ "Shamburger Substation",[-95.25346100296707, 32.419892187939]],
    [ "Seagoville Switching Station",[-96.54625235825263, 32.673455132011036]],
    [ "Seagoville Substation",[-96.54890991398247, 32.67386672644741]],
    [ "Tyler Substation",[-95.34645669439232, 32.27984929374787]],
    [ "172356198",[-95.40641858082896, 32.31792884432967]],
    [ "172356202",[-96.31411742836524, 32.53960600757763]],
    [ "172356203",[-95.57918754086458, 32.44651289313976]],
    [ "172359348",[-95.30735600628873, 32.27966390256207]],
    [ "172359353",[-95.25771559649519, 32.27751340974301]],
    [ "North Cleburne Substation",[-97.38943372463551, 32.38867735432032]],
    [ "172362136",[-97.4557008, 32.411325]],
    [ "Southwestern Electric Power Company",[-94.62107765, 32.024613450000004]],
    [ "Oncor",[-94.62430247603935, 32.014698787603535]],
    [ "Tenaska Gateway Station",[-94.62123977090813, 32.01777177172749]],
    [ "172365913",[-94.99325646854535, 31.937776173180254]],
    [ "Crockett Substation",[-95.39546962569763, 31.364387360923022]],
    [ "172375006",[-96.95957006050935, 32.6490360092868]],
    [ "TXU Cedar Hill Substation",[-96.92458182925022, 32.6149568568848]],
    [ "Oncor",[-96.91865452885686, 32.815167985420295]],
    [ "Irving Belt Line Substation",[-96.99380605000002, 32.784800249999996]],
    [ "172433993",[-96.94331602747458, 32.9078388774082]],
    [ "172433996",[-96.9338927363875, 32.887481057979954]],
    [ "Valley Road Substation",[-98.42958932971784, 29.23868040916495]],
    [ "Lufkin Switching Station",[-94.76860916930912, 31.370148708209353]],
    [ "Tradinghouse Substation",[-96.96447271200995, 31.57474543473563]],
    [ "Big Brown Station",[-96.057452754283, 31.819531219978813]],
    [ "Richland Chambers Substation",[-96.11335000589007, 31.890152456823742]],
    [ "Forest Grove Substation",[-95.94928124965115, 32.234710416826736]],
    [ "Tenaska Frontier Station",[-95.91913325123443, 30.593908962736233]],
    [ "Pirkey Substation",[-94.4786051262551, 32.463445611211014]],
    [ "172601852",[-94.43448100128799, 32.39543168298277]],
    [ "172601859",[-94.42779312790478, 32.300709458812804]],
    [ "Temple Pecan Creek Substation",[-97.26106452989485, 31.129639242523584]],
    [ "Temple Switching Station",[-97.30777685415539, 31.079104009983855]],
    [ "Bosque Switching Station",[-97.35929826598385, 31.857889197866506]],
    [ "Whitney Switching Station",[-97.36531948278214, 31.860631451086164]],
    [ "Rogers Hill Substation",[-97.17731828569397, 31.746115801845473]],
    [ "Lake Whitney Substation",[-97.3672526748434, 31.859626449700112]],
    [ "Oak Grove Substation",[-96.48437354581002, 31.183574677059408]],
    [ "Bear Grass Substation",[-96.20628440616197, 31.37698715348999]],
    [ "Nucor Substation",[-96.16330781808045, 31.35244743374577]],
    [ "Sandow Station",[-97.07680662152443, 30.57766501225303]],
    [ "Rogers Substation",[-97.20127719550614, 30.91924763032003]],
    [ "Cedar Hill Substation",[-97.37942075354536, 30.313981271163364]],
    [ "TNP One Substation",[-96.69355594999999, 31.091272499999995]],
    [ "Gibbons Creek Station",[-96.0866633, 30.6211755]],
    [ "Lost Pines Station",[-97.27278856639943, 30.14775176215054]],
    [ "Garfield Substation",[-97.54537121612435, 30.142619105722694]],
    [ "Bluebonnet Substation",[-97.43435224947909, 30.1219725401445]],
    [ "Bastrop West Substation",[-97.33516622892151, 30.10169521779459]],
    [ "Tahitian Village Substation",[-97.3059158836383, 30.09123560166056]],
    [ "Bastrop Energy Switchyard",[-97.54853788512517, 30.145480171150744]],
    [ "Winchester Substation",[-96.98653869701249, 30.04441534675284]],
    [ "Bushland Interchange",[-102.09853684999999, 35.14788795]],
    [ "Green Mountain Substation",[-98.3328807566279, 29.620436075471837]],
    [ "Hollywood Park Substation",[-98.47156573442297, 29.594885120612307]],
    [ "Lytton Springs Substation",[-97.67422223109676, 30.00537837981503]],
    [ "Mendoza Substation",[-97.66762745548041, 30.003539249620815]],
    [ "McCarty Lane Substation",[-97.96474671789213, 29.826510400200593]],
    [ "Killeen Substation",[-97.72295185449225, 31.03898377485173]],
    [ "Salado Switching Station",[-97.51111939999998, 30.972125950000002]],
    [ "Salado Substation",[-97.51303591551365, 30.971238180653323]],
    [ "Bell County Switching Station",[-97.39358596817556, 31.010029510993135]],
    [ "Hutto Switching Station",[-97.56134300585191, 30.551166927827566]],
    [ "Kendall CTEC Substation",[-98.88477554128612, 29.986266547714084]],
    [ "River Oaks Substation",[-98.10582777154755, 29.77775775578525]],
    [ "Horse Hollow 2 Substation",[-100.17697869999999, 32.26955795]],
    [ "Horse Hollow 3 Substation",[-100.2843617879902, 32.2654226340587]],
    [ "Rio Nogales Switchyard",[-97.97493153638538, 29.59310753931389]],
    [ "Boerne Cico Substation",[-98.77608138546057, 29.771151136450985]],
    [ "Marion Substation",[-98.14869083930958, 29.620922469083037]],
    [ "Guadalupe Generating Station",[-98.14105510582358, 29.623169480681984]],
    [ "Elm Creek Substation",[-97.99987960559332, 29.467299385799734]],
    [ "Lolita Substation",[-96.5574016051286, 28.85316645053834]],
    [ "Newgulf Substation",[-95.9193927328002, 29.230159387500095]],
    [ "Karsten Substation",[-95.43005922183575, 29.49070759607318]],
    [ "Oasis Substation",[-95.30831257167098, 29.456208094498855]],
    [ "Cibolo Substation",[-98.20772524168153, 29.57818171144918]],
    [ "Dow Substation",[-95.35808867376612, 28.98627890804709]],
    [ "Cavern Substation",[-95.33904736081791, 29.032383796902014]],
    [ "Biport Substation",[-95.33851291993304, 28.986064920664866]],
    [ "Blessing Substation",[-96.19087063919379, 28.886564294209325]],
    [ "Magnolia Substation",[-95.13398619137725, 29.48835407458821]],
    [ "North Alvin Substation",[-95.25754645250807, 29.458976195584768]],
    [ "San Miguel Switchyard",[-98.47430697486298, 28.70400566073503]],
    [ "O. W. Sommers Switchyard",[-98.3236230985315, 29.310242248436346]],
    [ "Walzem Road Substation",[-98.36927128234885, 29.509693726186523]],
    [ "Beck Road Substation",[-98.31848402967825, 29.38198156695736]],
    [ "Irving Grant Street Substation",[-96.94533952003214, 32.81718557765495]],
    [ "Oncor",[-96.97451921277738, 32.81591886144521]],
    [ "Pawnee Substation",[-98.02379785278599, 28.73984366343541]],
    [ "Cypress Creek Substation",[-98.91333510324824, 29.959201798626527]],
    [ "Coleto Creek Substation",[-97.21257967814556, 28.71682195326534]],
    [ "Lon Hill Substation",[-97.61561257611005, 27.843655486153608]],
    [ "Four Corners Substation",[-97.1644699667614, 28.87161254676184]],
    [ "Calallen Substation",[-97.6462278441242, 27.849226716268806]],
    [ "Fannin Substation",[-97.23372229100048, 28.691647278519145]],
    [ "Gohlke Substation",[-96.73319774789489, 28.746453216894007]],
    [ "Whitepoint Substation",[-97.36056187534874, 27.93580404217811]],
    [ "McKenzie Substation",[-97.57148810000001, 27.80572825]],
    [ "Nelson Sharpe Substation",[-97.6688623217887, 27.56051011409933]],
    [ "Oxy Petro Substation",[-97.58727993910163, 27.81327549149767]],
    [ "Lobo Substation",[-99.27570768786248, 27.579015997160504]],
    [ "Ajo Switching Station",[-97.77439180269559, 27.092030473578145]],
    [ "Zorillo Substation",[-97.58347305002751, 27.003445895730515]],
    [ "Gulf Wind Substation",[-97.58416265677455, 27.0025438161901]],
    [ "Penascal Substation",[-97.52880737369304, 27.103253209116424]],
    [ "F. Yturria Substation",[-97.76560094999999, 26.640982899999997]],
    [ "Rio Hondo Substation",[-97.61339997028034, 26.27748751185738]],
    [ "La Palma 138 kV Substation",[-97.64270960094426, 26.143466828093985]],
    [ "North Edinburg Substation",[-98.18929735758958, 26.344069236758667]],
    [ "La Palma 345 kV Substation",[-97.64277254120252, 26.146585780875792]],
    [ "Moore County Substation",[-101.86303261275175, 35.966739287903266]],
    [ "Valero McKee Substation",[-101.87948908297422, 35.96103909011166]],
    [ "Dumas 19th Street Substation",[-101.96058609998634, 35.84191688657841]],
    [ "Harrington Switchyard",[-101.74725845000003, 35.296447099999995]],
    [ "Cherry Street Substation",[-101.83943710804142, 35.29477782506896]],
    [ "Nichols Switchyard",[-101.74530655000686, 35.28352510361874]],
    [ "174868348",[-101.7952458797991, 34.39909954700592]],
    [ "Arrowhead Substation",[-101.81347370000002, 35.105527050000006]],
    [ "Randall County Interchange",[-101.77574038252655, 35.148467881105404]],
    [ "175541579",[-96.62660071470034, 33.61467024200211]],
    [ "175541594",[-96.61474968310883, 33.67268179222709]],
    [ "175541596",[-96.5920261907374, 33.75892650107277]],
    [ "Glenhaven Substation",[-102.30841977012801, 31.89038311732053]],
    [ "Pucket Substation",[-101.93597592235066, 35.177542871883155]],
    [ "Coulter Substation",[-101.91939396964156, 35.16107486485371]],
    [ "Soncy Substation",[-101.92112482505725, 35.18779464143178]],
    [ "Sunset Substation",[-101.94030602498998, 35.20661239308339]],
    [ "Grandview Substation",[-102.3549628706326, 31.90288114542399]],
    [ "Odessa Substation",[-102.35422078947771, 31.848359603985987]],
    [ "Ector Harper Substation",[-102.52832989999999, 31.798388200000005]],
    [ "Midland West Substation",[-102.12479187248404, 31.996434538291535]],
    [ "182470337",[-97.40222798285798, 32.32032773108593]],
    [ "Hutchinson County Interchange",[-101.43993063111395, 35.63163093619479]],
    [ "Wildorado Wind Substation",[-102.31633024111309, 35.28201572026658]],
    [ "183255039",[-102.80219757363318, 33.16796721201641]],
    [ "183255040",[-102.4557601376266, 33.446382124804664]],
    [ "183255042",[-102.8017532893908, 33.16810343211782]],
    [ "183255047",[-102.45478795, 33.44624484999999]],
    [ "183255055",[-102.45409646672279, 33.520447471016496]],
    [ "183383429",[-102.55753775, 33.45846454999999]],
    [ "183383430",[-102.5587833, 33.45925365000001]],
    [ "Wolfforth Interchange",[-102.0556725190764, 33.491511618998715]],
    [ "183390099",[-102.7841627286549, 32.96532717932175]],
    [ "Carlisle Interchange",[-101.96711718660114, 33.5912644985061]],
    [ "Allen Substation",[-101.8794663275912, 33.52726909303775]],
    [ "Oliver Substation",[-101.88880343243895, 33.49839643863786]],
    [ "Frankford Substation",[-101.94022550000001, 33.49838305000001]],
    [ "McDonald Substation",[-101.95795069999998, 33.58575570000001]],
    [ "Jones Station",[-101.73909769664202, 33.52253482442184]],
    [ "Lubbock South Interchange",[-101.82626636989121, 33.51171233367173]],
    [ "Lubbock East Interchange",[-101.79145371239737, 33.57117185126622]],
    [ "Grassland Interchange",[-101.68501224933634, 33.17998129933744]],
    [ "Holly Substation",[-101.79244198253842, 33.52081049619529]],
    [ "Southeast Substation",[-101.85489260000001, 33.518608549999996]],
    [ "Grapevine Interchange",[-100.89096643971456, 35.401933973053964]],
    [ "183502012",[-100.24378093868268, 35.3728479748463]],
    [ "Amarillo South Interchange",[-101.86391381408431, 35.0996965044127]],
    [ "East Plant Substation",[-101.80410983946531, 35.214559460864024]],
    [ "Whitaker Substation",[-101.75922659999999, 35.2504321]],
    [ "Little River Substation",[-97.36661898029624, 30.985939013431945]],
    [ "Nameless Substation",[-97.92833097810491, 30.528144827298153]],
    [ "Brackenridge Substation",[-97.73292035763227, 30.27273124743364]],
    [ "Pedernales Substation",[-97.72111212370108, 30.249988924930424]],
    [ "Escarpment Substation",[-97.89461979567882, 30.179978323283617]],
    [ "Hamilton Wolfe Road Substation",[-98.58822752253676, 29.515267985349283]],
    [ "Sheriff's Posse Substation",[-98.1625974298888, 29.664657693056448]],
    [ "Vernon Main Street Substation",[-99.28541873304724, 34.16349221373091]],
    [ "Evant Substation",[-98.15933016970577, 31.229762423808644]],
    [ "NextEra Energy",[-100.99361934999997, 31.91104295]],
    [ "NextEra Energy",[-100.95226057473054, 31.97087654581786]],
    [ "NextEra Energy",[-100.89861663889417, 31.90241749881149]],
    [ "Seward Junction Substation",[-97.85092519042723, 30.634519807923176]],
    [ "Carlsbad Substation",[-100.57352862464079, 31.56754020488859]],
    [ "San Angelo College Hills Substation",[-100.49529138240344, 31.43280408002371]],
    [ "San Angelo Southland Hills Substation",[-100.49180560493777, 31.403249001803395]],
    [ "San Angelo Power Station",[-100.49294369546853, 31.395143919281285]],
    [ "Ben Ficklin Substation",[-100.438287511445, 31.402353694486617]],
    [ "San Angelo South Substation",[-100.45727173977991, 31.423293002097473]],
    [ "San Angelo Highland Street Substation",[-100.4259624, 31.45107045]],
    [ "American Electric Power",[-100.42791452464986, 31.449872557105973]],
    [ "San Angelo Concho Substation",[-100.42716420439446, 31.450111985192954]],
    [ "Yellowjacket Substation",[-99.78481894653663, 30.931630004411026]],
    [ "Mason Substation",[-99.23444886329763, 30.738116625481467]],
    [ "Fort Mason Substation",[-99.23176056983559, 30.737731159434002]],
    [ "Mason Substation",[-99.18533115862415, 30.748169473971632]],
    [ "Hext Substation",[-99.53821820000002, 30.872308999999998]],
    [ "Mason Switching Station",[-99.38003235, 30.82056989999999]],
    [ "Gillespie Substation",[-98.90406960112338, 30.30543428393433]],
    [ "Horseshoe Bay Substation",[-98.39252881482463, 30.532800664632926]],
    [ "Nimitz Substation",[-98.86583268565184, 30.30685398197356]],
    [ "Ferguson Substation",[-98.3715284909567, 30.555537834109064]],
    [ "Wirtz Substation",[-98.33736858481268, 30.559686231357773]],
    [ "Sandy Creek Substation",[-98.47751372100849, 30.569166615968903]],
    [ "Spring Creek Substation",[-96.26651921768735, 30.57261253796734]],
    [ "Honey Substation",[-94.44594433810255, 30.39847181354749]],
    [ "North Padre Island Substation",[-97.22148250000002, 27.6000678]],
    [ "Naval Base Substation",[-97.27214945366566, 27.679015378863326]],
    [ "Mustang Island Substation",[-97.14216911866106, 27.72532993831181]],
    [ "Dupont Switching Station",[-97.24679197803839, 27.900268546623742]],
    [ "Taft Substation",[-97.39856698392808, 27.96976345239124]],
    [ "Rincon Substation",[-97.32986186485326, 27.98680619409645]],
    [ "194778886",[-95.25272090205627, 29.76047560946916]],
    [ "Engle Substation",[-97.01310441987383, 29.674530212132446]],
    [ "Flatonia Substation",[-97.10585115582758, 29.678049944365938]],
    [ "Rosenberg Substation",[-95.80867138622759, 29.560081119208437]],
    [ "Roberts Substation",[-99.75940792376743, 32.29972725575371]],
    [ "La Vernia Substation",[-98.05432898188882, 29.3239071380472]],
    [ "Olmos Substation",[-98.07177846375082, 29.400614484410138]],
    [ "Capote Substation",[-97.87825451275886, 29.56836937477031]],
    [ "Seguin Substation",[-97.95637894877261, 29.594252817825346]],
    [ "196190815",[-98.04830170000002, 29.589021700000004]],
    [ "McQueeney Substation",[-98.04960397629601, 29.58854238458795]],
    [ "York Creek Substation",[-97.95005615223444, 29.737508740747703]],
    [ "Terrell Hills Substation",[-98.44676374488664, 29.4866461291482]],
    [ "Jefferson Switching Station",[-94.39208556006066, 32.77303876510924]],
    [ "Co-op Substation",[-101.82821034103468, 33.56719037389507]],
    [ "Murphy Substation",[-101.96534555000001, 33.533881]],
    [ "201055183",[-102.53313002130282, 32.964461366471994]],
    [ "201060931",[-102.42997613971313, 33.046735275153516]],
    [ "Wheless Lane Substation",[-97.68501918207757, 30.31304984681741]],
    [ "203377758",[-96.80147968253189, 31.00826887721089]],
    [ "Holly Substation",[-97.39734895377897, 27.708438141787035]],
    [ "Airline Substation",[-97.3733969862589, 27.695790912118945]],
    [ "Rodd Field Substation",[-97.3824731086065, 27.659188231133292]],
    [ "Wooldridge Substation",[-97.35242305, 27.68176010000001]],
    [ "Cabaniss Substation",[-97.43773144999999, 27.720418949999996]],
    [ "Magnolia Anderson Substation",[-96.00203494999998, 30.436084999999995]],
    [ "Bentwater Substation",[-95.64675336540115, 30.426500067731123]],
    [ "Gosling Substation",[-95.49125738820828, 30.183663441209507]],
    [ "Keith Substation",[-96.10149004151766, 30.649155075356944]],
    [ "Seaholm Substation",[-97.75135892109198, 30.266824482289152]],
    [ "Marshall Ford Upper Switchyard",[-97.90905489999999, 30.389202099999995]],
    [ "Angus Valley Substation",[-97.77756988463635, 30.400563178718148]],
    [ "Riverplace Substation",[-97.842263, 30.398688450000005]],
    [ "North Belt Substation",[-95.36973193995448, 29.940638696075787]],
    [ "Hidden Valley Substation",[-97.90027978975499, 30.384021616295758]],
    [ "Commons Ford Substation",[-97.8547498533044, 30.315278759090496]],
    [ "Paleface Substation",[-98.10881994927661, 30.42676600193536]],
    [ "Lakeway Substation",[-97.97878617642704, 30.358700213312993]],
    [ "Barton Substation",[-97.81947301876899, 30.266117949261783]],
    [ "Salem Walk Substation",[-97.7900278631634, 30.215785598443073]],
    [ "206466956",[-106.58587618106327, 31.879845851475267]],
    [ "Clinton Substation",[-95.29967440635679, 29.7609552597837]],
    [ "Patton Lane Substation",[-97.87048554918196, 30.23827271394877]],
    [ "Cedar Valley Substation",[-97.95250213721812, 30.227011777164975]],
    [ "Kirby Substation",[-95.42291565465375, 29.72974737344828]],
    [ "Bergstrom Substation",[-97.67253903075988, 30.219032689964013]],
    [ "Abilene Power Plant Substation",[-99.72133471344799, 32.44995247595793]],
    [ "Sonterra Substation",[-97.60763321221228, 30.802329683416254]],
    [ "Jarrell Substation",[-97.56182670396979, 30.796453086041968]],
    [ "Jarrell East Substation",[-97.61063503541477, 30.796228365695047]],
    [ "Parkway Substation",[-95.16355544848018, 29.817955568148964]],
    [ "CenterPoint Energy",[-95.1540834, 29.8200214]],
    [ "Wallisville Substation",[-95.29123574235656, 29.78743533745914]],
    [ "DSM Substation",[-95.3697035158732, 29.010340487550756]],
    [ "Velasco Substation",[-95.36265065542838, 28.98011688690485]],
    [ "April Substation",[-95.6146984632931, 30.369079875011053]],
    [ "Walden Substation",[-95.62046783750972, 30.39557182284182]],
    [ "Wolf Lane Substation",[-97.56140145378143, 30.139423185365466]],
    [ "Pilot Knob Substation",[-97.68163296287113, 30.162488101970048]],
    [ "Colton Substation",[-97.67901454883628, 30.139128511678305]],
    [ "217550137",[-106.23292284639737, 31.768604910878178]],
    [ "West Substation",[-97.09655014337741, 31.794848854848343]],
    [ "South Brady Substation",[-99.32948278431064, 31.11807997512652]],
    [ "Dutton Substation",[-99.32304885, 31.1456513]],
    [ "221149551",[-96.76864517019384, 32.91455664222185]],
    [ "USAA #2 Substation",[-98.5713421560307, 29.53049694499933]],
    [ "USAA #1 Substation",[-98.5762953356995, 29.53327781506695]],
    [ "Buckeye Substation",[-98.52459231250097, 29.49368260711173]],
    [ "Austin Dam Substation",[-97.78440365370147, 30.29327791340285]],
    [ "Bee Creek Substation",[-97.78951716538914, 30.29385930505815]],
    [ "Kingsbery Substation",[-97.6872139888945, 30.265292640786274]],
    [ "Carson Creek Substation",[-97.68710033986902, 30.203234658992475]],
    [ "Stratford Substation",[-102.07069219999998, 36.328469500000004]],
    [ "228018569",[-97.11451637239036, 33.62692579157715]],
    [ "228018571",[-97.14397614260521, 33.6357046960086]],
    [ "Dewalt Substation",[-95.56372340721204, 29.604513202330583]],
    [ "Bertram Substation",[-98.05376724999999, 30.75487865]],
    [ "Floresville Substation",[-98.15423615086694, 29.14118030089777]],
    [ "Lake Pauline Substation",[-99.68474833876284, 34.252268657417396]],
    [ "Camp Gary Substation",[-97.8663747951435, 29.881301548144133]],
    [ "Reedville Substation",[-97.85135582111432, 29.875407801243085]],
    [ "Beback Substation",[-97.95559320409289, 29.79709953634791]],
    [ "Coyote Substation",[-106.17840199999999, 31.81340555]],
    [ "Dunlavy Substation",[-95.40185493562157, 29.752642622734236]],
    [ "Vega Substation",[-97.8682554285809, 30.242253395329286]],
    [ "Fair Oaks Ranch Substation",[-98.61239079193886, 29.732803621787536]],
    [ "Georgetown East Substation",[-97.64489074504903, 30.62372347922275]],
    [ "Buffalo Gap 2 Substation",[-100.20759303541232, 32.32823507530921]],
    [ "South Trent Wind Farm Substation",[-100.19345599884996, 32.43306368319733]],
    [ "Buffalo Gap 1 & 3 Substation",[-100.11532349434039, 32.29935614340941]],
    [ "Signal Mountain Wind Substation",[-101.38802034999999, 32.20724529999999]],
    [ "Angleton Substation",[-95.42881557379509, 29.22675939774384]],
    [ "Magnesium Plant Substation",[-97.72261708311487, 30.37331350863301]],
    [ "Southside Substation",[-97.38341043929724, 27.73180290535185]],
    [ "Westside Substation",[-97.4521975555236, 27.73463046989133]],
    [ "Weil Tract Substation",[-97.48546687793743, 27.796766850732567]],
    [ "Bammel Substation",[-95.5025968674533, 29.982660096696193]],
    [ "AMD Substation",[-98.64005073243294, 29.449451246771126]],
    [ "Texas Cryptologic Center Substation",[-98.63871300181533, 29.447997522551223]],
    [ "256028929",[-96.68059428613154, 33.098347563490115]],
    [ "Edith Clarke Substation",[-99.77405918246721, 33.94427226565116]],
    [ "256156161",[-99.67517115982088, 34.05541229562365]],
    [ "Batesville Substation",[-99.60796805999782, 28.86285877251559]],
    [ "Hamilton Substation",[-97.74415299615409, 30.400827820209333]],
    [ "Lufkin Pershing Street Substation",[-94.73556029312196, 31.327492219728224]],
    [ "Central Heights Substation",[-94.63771115053896, 31.7209889428033]],
    [ "Hickory Substation",[-97.16182789951378, 33.215175704622524]],
    [ "Steck Substation",[-97.74159306179912, 30.367260694190335]],
    [ "Blodgett Substation",[-95.36446705258527, 29.720982242895996]],
    [ "El Paso Electric",[-106.49854069751689, 31.769148813351432]],
    [ "El Paso Electric",[-106.49772820294083, 31.76851258461022]],
    [ "O'Conner Substation",[-97.04322747449199, 28.519600993143243]],
    [ "Walnut Creek Substation",[-97.65316780108004, 30.274024611697584]],
    [ "267625272",[-96.85081026627162, 33.83050991073962]],
    [ "Cibolo Creek Substation",[-98.38522725345345, 29.664872799384547]],
    [ "Kings Row Substation",[-97.10521988386722, 33.24855764232407]],
    [ "Bastrop Substation",[-97.32106139812791, 30.130302105252962]],
    [ "Helbig Substation",[-94.15723148341368, 30.156249003183177]],
    [ "Giddings Substation",[-96.95109098752225, 30.18411279182273]],
    [ "276137246",[-95.90667719702174, 33.23145282369599]],
    [ "Decker Switchyard",[-97.61415050000001, 30.3049807]],
    [ "Southwest Research Institute Substation",[-98.61270367333158, 29.437028721307748]],
    [ "Lawson Road Substation",[-96.56305885387164, 32.707735919225016]],
    [ "Mesquite Station",[-96.57547585797536, 32.760099817947356]],
    [ "Crockett Substation",[-95.37988804715017, 29.776839033299837]],
    [ "283011260",[-95.9123193572064, 33.25504090874821]],
    [ "283011272",[-96.06634856716549, 33.373565952430475]],
    [ "283608200",[-96.11353457167114, 33.15614430329916]],
    [ "283611932",[-96.12554437473953, 33.169167947722414]],
    [ "283611935",[-96.12583719240872, 33.17028840843123]],
    [ "283611938",[-96.17197710655299, 33.16661119788089]],
    [ "283611940",[-96.23460053036696, 33.1417200075545]],
    [ "283611943",[-96.45152854464277, 33.06812035514273]],
    [ "Santa Anna Substation",[-99.30483569999998, 31.739666900000003]],
    [ "284514002",[-96.53253091301266, 32.96238946430844]],
    [ "Firewheel Substation",[-96.6403712611968, 32.98126344621558]],
    [ "Apollo Substation",[-96.67747938253294, 32.95381164838373]],
    [ "East Richardson Switching Station",[-96.70079187417959, 32.9567972525554]],
    [ "Richardson Spring Creek Substation",[-96.69627119999998, 32.9831709]],
    [ "Brand Substation",[-96.63770716966934, 32.952258719034155]],
    [ "Fairdale Substation",[-96.66163015580084, 32.9138569887553]],
    [ "Lynn County Interchange",[-101.78949463237488, 33.19658028442503]],
    [ "Woodrow Interchange",[-101.78913994999999, 33.46235335]],
    [ "Sulphur Springs Substation",[-95.61349836233931, 33.1319450088984]],
    [ "286266008",[-95.65941182322429, 33.01685333004004]],
    [ "286266009",[-95.6940917260967, 32.96213398861547]],
    [ "Luling City Substation",[-97.65433612893231, 29.697462710791886]],
    [ "Harwood Substation",[-97.50456411924448, 29.661074948273388]],
    [ "Waelder Substation",[-97.38778422298599, 29.670986053342457]],
    [ "Moulton Substation",[-97.1402473829379, 29.574259137050642]],
    [ "Yoakum Gaetner Road Substation",[-97.15657254999999, 29.279753199999995]],
    [ "Hallettsville Substation",[-96.98729111372461, 29.42882529914161]],
    [ "286473131",[-96.45711577758256, 32.929443423772405]],
    [ "286473134",[-96.51430643982033, 32.91964848276195]],
    [ "286473135",[-96.54997357346386, 32.90411360699189]],
    [ "286473137",[-96.61403526294727, 32.845285695776845]],
    [ "Pharoah Substation",[-97.35450834743702, 27.70477071582241]],
    [ "Reading Substation",[-95.76246191715667, 29.563724422345953]],
    [ "288394171",[-95.40554792950115, 32.49025279927537]],
    [ "Oncor",[-96.72416099159729, 33.001132597777755]],
    [ "Menger Creek Substation",[-98.72030311478834, 29.76264546516057]],
    [ "Lawrence Park Substation",[-101.86899677501592, 35.18597300775661]],
    [ "Sienna Substation",[-95.56129749096722, 29.53809935526684]],
    [ "South Georgia Interchange",[-101.86573695, 35.1633533]],
    [ "Locust Substation",[-97.13291564980715, 33.25481340000748]],
    [ "Stratton Substation",[-97.8792436085084, 27.621815623941384]],
    [ "San Diego Substation",[-98.25118136626186, 27.743756848470706]],
    [ "Centex Substation",[-97.8589175, 30.054033049999997]],
    [ "Mathis Substation",[-97.82388427096409, 28.112171655618837]],
    [ "Sinton Substation",[-97.5126015601122, 28.030866812943394]],
    [ "Heights Substation",[-95.39850171551342, 29.782391396364225]],
    [ "Rocksprings Substation",[-100.19914931062847, 30.022909744323275]],
    [ "Rocksprings Substation",[-100.20181079704939, 30.021221418994045]],
    [ "East Munday Substation",[-99.60310433876582, 33.453395534723946]],
    [ "Munday Substation",[-99.62074286844361, 33.45026601535736]],
    [ "Dunvale Substation",[-95.5189723, 29.733086800000002]],
    [ "Harper Road Substation",[-99.15072420167975, 30.073379002869096]],
    [ "Franklin Substation",[-95.36776803652529, 29.76609777418982]],
    [ "Memorial Substation",[-95.61954365170824, 29.789766457965637]],
    [ "Canyon Rock Substation",[-99.74727110270844, 32.38654485856715]],
    [ "Mueller Substation",[-97.68955664444395, 30.289950578297276]],
    [ "Lamesa Substation",[-101.93777043970928, 32.71941269577617]],
    [ "Killeen Substation",[-97.73861958724383, 31.1041363005824]],
    [ "Hunt Lane Substation",[-98.66708451731446, 29.414988902758317]],
    [ "Pearsall Road Substation",[-98.60155222904936, 29.356925737672633]],
    [ "Alvin Substation",[-95.25397740811286, 29.433716802507323]],
    [ "El Dorado Substation",[-95.1416275, 29.561908350000003]],
    [ "Holmes Substation",[-95.37211734044088, 29.67945277529843]],
    [ "Pearland Substation",[-95.32264298864241, 29.56019169075484]],
    [ "Clodine Substation",[-95.62076856853064, 29.674798317764743]],
    [ "Westheimer Substation",[-95.61730443044534, 29.736607437698208]],
    [ "306163604",[-106.30450466529233, 31.72204777867404]],
    [ "Chocolate Bayou Substation",[-95.35269018723952, 29.627877924834724]],
    [ "312154550",[-96.67499475052752, 33.14823581702523]],
    [ "312174904",[-96.65530659766642, 33.155280692786654]],
    [ "Sharpstown Substation",[-95.48726774092641, 29.701381960924596]],
    [ "Riverview Substation",[-101.41117074489793, 35.71368926704436]],
    [ "Ed Bluestein Substation",[-97.66209784240544, 30.27053655983241]],
    [ "Midtown Substation",[-95.37146489999999, 29.7414929]],
    [ "314548198",[-94.44857069103118, 32.70164524589271]],
    [ "Killeen Park Street Substation",[-97.72945530395909, 31.125689979971952]],
    [ "Castroville Substation",[-98.81590737063448, 29.358770420121093]],
    [ "Lytle Substation",[-98.80417292125796, 29.25070262903332]],
    [ "Leon Creek Switchyard",[-98.57513000253, 29.35029676948589]],
    [ "Medina Base Substation",[-98.63472674448825, 29.380688311194522]],
    [ "OCI Alamo 1",[-98.45566704699812, 29.27143252931907]],
    [ "Panther Springs Substation",[-98.51764049999998, 29.6655355]],
    [ "Rittenhouse Substation",[-95.37941734651619, 29.863630128325173]],
    [ "Cardinal Lane Substation",[-97.76880879999999, 30.2337676]],
    [ "Braunig Switchyard",[-98.38270104061614, 29.2580203255645]],
    [ "Arthur Von Rosenberg Switchyard",[-98.38427406386808, 29.25822351759209]],
    [ "Tri-County Substation",[-98.27085567575011, 29.6100304198925]],
    [ "The University of Texas at San Antonio",[-98.62525122225148, 29.58629439058717]],
    [ "Poteet Substation",[-98.60995419710939, 29.0382492479545]],
    [ "Rossville Substation",[-98.68173675785376, 29.164328781846187]],
    [ "Harlandale Substation",[-98.49584291700606, 29.36334104942051]],
    [ "Los Angeles Heights Substation",[-98.52643293743387, 29.466824968435247]],
    [ "Dresden Drive Substation",[-98.50827151684388, 29.498672181572154]],
    [ "Hot Wells Substation",[-98.45467868945643, 29.37006609672206]],
    [ "36th Street Substation",[-98.5689133505418, 29.39819481925647]],
    [ "Fort Sam Substation",[-98.43794553221116, 29.45537959782782]],
    [ "Hondo Substation",[-99.10397130119712, 29.315554694839477]],
    [ "Hondo Creek Substation",[-99.08438337294349, 29.27239243200055]],
    [ "Devine Substation",[-98.8974348198098, 29.133964280997713]],
    [ "Pearson Substation",[-98.92424539587644, 29.2629574978255]],
    [ "Hondo Substation",[-99.15099362515404, 29.34523765517523]],
    [ "Lytle Substation",[-98.8447026268588, 29.21667015283802]],
    [ "Capitol Cement Substation",[-98.41967259531725, 29.54799869781848]],
    [ "Mauermann Road Substation",[-98.50753994130851, 29.28452440843311]],
    [ "Palo Alto Substation",[-98.54970897888923, 29.318257741131045]],
    [ "Trumbo Substation",[-98.50460146738486, 29.21015040326791]],
    [ "Schertz Substation",[-98.26150336150177, 29.56361051547903]],
    [ "Medina Lake Substation",[-98.93314660322199, 29.612534281489264]],
    [ "Raymond F. Barker Substation",[-99.04617483425145, 29.958329903702392]],
    [ "Verde Creek Substation",[-99.05508540030819, 29.933146450154137]],
    [ "Mason Creek Substation",[-99.02232962142033, 29.76057666570978]],
    [ "Bandera Substation",[-99.11152702708627, 29.736429721214545]],
    [ "Tuttle Switchyard",[-98.41870266794814, 29.52917872115678]],
    [ "Krugerville Substation",[-96.99331961092531, 33.278153587036705]],
    [ "Airport Substation",[-97.44743820704964, 25.906239555121104]],
    [ "Goodyear Substation",[-95.25697031730412, 29.706194127495618]],
    [ "Alamo Cement Substation",[-98.37442288736563, 29.611782977912046]],
    [ "Jett Substation",[-97.80647593660692, 30.374718336205635]],
    [ "McCullough Substation",[-101.87857036044635, 33.56710987498648]],
    [ "Devil's Hill Substation",[-98.4123566511025, 29.9223457468026]],
    [ "330587419",[-106.3851288174815, 31.780138601301488]],
    [ "Jourdanton Substation",[-98.55202337052415, 28.924267764192006]],
    [ "Lexington Substation",[-97.00328989588124, 30.415874945953032]],
    [ "335055309",[-98.6888041746155, 33.807005923500235]],
    [ "Septon Substation",[-95.03671588933048, 29.61623902758568]],
    [ "Bypark Substation",[-95.05942662472822, 29.64230454472555]],
    [ "Fairmont Substation",[-95.10276065000001, 29.649527750000004]],
    [ "Sylvan Substation",[-95.04023822396626, 29.654623032716927]],
    [ "Luzbay Substation",[-95.05536753946602, 29.639685779977285]],
    [ "Bayou Cogen Substation",[-95.04661702309608, 29.62623093759647]],
    [ "Bayway Substation",[-95.04954940739516, 29.609417828100074]],
    [ "Rayford Substation",[-95.44505007996001, 30.12858100717372]],
    [ "Gertie Substation",[-95.68393524999999, 29.863579800000004]],
    [ "336061352",[-95.19716207548664, 29.932726494780947]],
    [ "Atascocita Substation",[-95.0970252511336, 30.004896353326167]],
    [ "Drilco Substation",[-95.38299974650374, 29.964392918152576]],
    [ "Kluge Substation",[-95.61840161148494, 29.98952531015226]],
    [ "336061360",[-94.928222267017, 29.850455599148844]],
    [ "Kingwood Substation",[-95.18928698868562, 30.054489383541302]],
    [ "Cedar Bayou Substation",[-94.9501426, 29.757894650000004]],
    [ "Fredericksburg Substation",[-98.88514479991633, 30.2597203564944]],
    [ "Wolf Creek Substation",[-99.08808995782836, 30.123347786884562]],
    [ "Miller Creek Substation",[-98.29839688291676, 30.2620136878225]],
    [ "Mountain Top Substation",[-98.3703292964186, 30.22997216750639]],
    [ "Castell Substation",[-98.9632074367698, 30.738390202262103]],
    [ "Kerrville Stadium Substation",[-99.13384245492512, 30.05784993215458]],
    [ "Liverpool Substation",[-95.29485894999999, 29.279127949999996]],
    [ "Riwood Substation",[-95.34748005, 29.055759550000005]],
    [ "Stratt Substation",[-95.35475674000004, 29.049992109443]],
    [ "Petson Substation",[-95.22033210905279, 29.253015404894327]],
    [ "Surfside Substation",[-95.33916830819847, 28.9587956640979]],
    [ "Polk Substation",[-95.36167625000002, 29.75223805]],
    [ "Katy Substation",[-95.83935221843755, 29.786955385585344]],
    [ "Intercontinental Substation",[-95.36742999009465, 29.98689436231731]],
    [ "Greens Road Substation",[-95.33322756780825, 29.95084423122586]],
    [ "Plaza Substation",[-95.3895714086384, 29.689427061314195]],
    [ "Hyde Park Substation",[-95.3844158, 29.7479018]],
    [ "Oates Substation",[-95.25214606848806, 29.778661438360604]],
    [ "Freeway Park Substation",[-94.99515065638674, 29.40397120641663]],
    [ "Dickinson Substation",[-95.05691733633962, 29.461136308803237]],
    [ "Friendswood Substation",[-95.15336926722178, 29.522642392681053]],
    [ "Galveston 26th Street Substation",[-94.79740953158402, 29.30285420124079]],
    [ "West Galveston Substation",[-94.82685873108859, 29.29624028559195]],
    [ "Deer Park Substation",[-95.12901913237658, 29.692381773036374]],
    [ "Fondren Substation",[-95.50936319261685, 29.66789034588255]],
    [ "Imperial Substation",[-95.63014775782051, 29.59462331198539]],
    [ "Quail Valley Substation",[-95.5341146, 29.586875850000002]],
    [ "Telview Substation",[-95.48748934999999, 29.579431849999995]],
    [ "Little York Substation",[-95.42991148124017, 29.864100728653575]],
    [ "Underwood Substation",[-95.08905557938384, 29.691999925384287]],
    [ "Exxon Substation",[-95.01951945414363, 29.74967838471052]],
    [ "Hall Substation",[-95.24020315178751, 29.58935235096985]],
    [ "La Porte Substation",[-95.01612628452986, 29.664838894355892]],
    [ "Mary's Creek Substation",[-95.25730523050775, 29.554066563009336]],
    [ "Normandy Substation",[-95.20560565, 29.76955465]],
    [ "Greens Bayou 345kV Substation",[-95.21825901466143, 29.818459181814113]],
    [ "Greens Bayou 69kV Substation",[-95.2208513354926, 29.820096666504]],
    [ "CenterPoint Energy",[-95.12624865102875, 29.820154677743908]],
    [ "Guy Burks Substation",[-96.42287919140098, 30.148131601343742]],
    [ "Big Creek Substation",[-95.75086412475667, 29.471160489071103]],
    [ "Damon Substation",[-95.72931505214798, 29.291765204729277]],
    [ "Needville Substation",[-95.8408987, 29.396122850000005]],
    [ "West Columbia Substation",[-95.65736818292643, 29.15672397753443]],
    [ "West Columbia Local Substation",[-95.6501211, 29.1492563]],
    [ "Lake Jackson Substation",[-95.42397790000001, 29.052934400000005]],
    [ "South Lane City Substation",[-96.0155456404808, 29.194916170152]],
    [ "Pledger Substation",[-95.91157260846549, 29.18846307991384]],
    [ "Wirt Substation",[-95.47962268136139, 29.78523312320997]],
    [ "Harrisburg Substation",[-95.2653107477057, 29.714925640874107]],
    [ "Telephone Substation",[-95.28742390564118, 29.59913515305591]],
    [ "Davson Substation",[-95.1892412, 29.716418749999995]],
    [ "Moody Substation",[-94.85340754551099, 29.260626695976548]],
    [ "Alta Loma Substation",[-95.09097851459133, 29.391559934942556]],
    [ "Hitchcock Substation",[-95.0159021619435, 29.347500737351737]],
    [ "Westbay Substation",[-95.09061993331962, 29.112681600580235]],
    [ "Stewart Substation",[-94.91065570696647, 29.22777122853258]],
    [ "Dupont Switching Station",[-96.945582804695, 28.69293501689962]],
    [ "Edna Substation",[-96.64612082572687, 28.956582792084813]],
    [ "El Campo Substation",[-96.2674232266726, 29.1869899998161]],
    [ "Ganado Substation",[-96.50271790000001, 29.013171149999998]],
    [ "Cy-Fair Substation",[-95.67258178162038, 29.94800635549336]],
    [ "Cuero Hydro Substation",[-97.30800042183431, 29.129450495977512]],
    [ "Cheapside Substation",[-97.39532211686931, 29.30898625356227]],
    [ "Thomaston Substation",[-97.14855134999999, 28.997465]],
    [ "Cuero Substation",[-97.27241819566945, 29.107413206523326]],
    [ "Gonzales Substation",[-97.46806078058042, 29.517895554947135]],
    [ "Sam Rayburn Switchyard",[-97.13304973313157, 28.89311925235078]],
    [ "Vanderbilt Substation",[-96.645839642948, 28.805848286199957]],
    [ "Loop 463 Substation",[-97.07498777757478, 28.816395428519503]],
    [ "Joslin Substation",[-96.5464256387652, 28.64874343127506]],
    [ "Greenlake Substation",[-96.79628765, 28.53488605]],
    [ "Vistron Substation",[-96.83302416093606, 28.574570766933654]],
    [ "Black Bayou Substation",[-96.91965904999999, 28.6424013]],
    [ "Port Lavaca Substation",[-96.6582921412087, 28.61072681135084]],
    [ "Brookhollow Substation",[-96.63383520068636, 28.630406543026513]],
    [ "Oak Hill Substation",[-97.85949077583957, 30.23384359856775]],
    [ "Hearne Substation",[-96.62591756891003, 30.88413980265598]],
    [ "Formosa Substation",[-96.55112213886838, 28.683586301401004]],
    [ "Formosa Plastics Switching Station",[-96.55118505000002, 28.686262500000005]],
    [ "Alcoa Substation",[-96.55763862676625, 28.6575413690202]],
    [ "Bay City Substation",[-95.9693318750014, 28.98771508375657]],
    [ "Markham Substation",[-96.06963480134647, 28.966744417152714]],
    [ "Kenedy Switching Station",[-97.83745905187591, 28.84256960234134]],
    [ "Charlotte Substation",[-98.70210035413294, 28.856041336357904]],
    [ "Carancahua Substation",[-96.42232528730553, 28.744184489765267]],
    [ "Palacios Substation",[-96.21212735, 28.757009049999997]],
    [ "337935569",[-94.05210878224034, 30.014034219012643]],
    [ "Mid County Substation",[-93.95835630404424, 29.941406130550437]],
    [ "Stonegate Substation",[-93.9717869306718, 29.953772711736228]],
    [ "Cheek Substation",[-94.18432589851956, 30.00086684856848]],
    [ "Dayton Bulk Substation",[-94.89252236355546, 30.03710095749772]],
    [ "Huntsville Substation",[-95.54699564999999, 30.71867875]],
    [ "Entergy",[-93.87923470564138, 30.02442911227792]],
    [ "Cargill Wire Substation",[-94.1105863276295, 30.076271642183638]],
    [ "Yankee Doodle Substation",[-94.09369257170229, 30.051014753225363]],
    [ "Crockett Substation",[-94.10965935, 30.074165649999998]],
    [ "Lincoln Substation",[-94.12229268292515, 30.055542716268167]],
    [ "Burt Street 138kV Substation",[-94.07787982170682, 30.066607574619866]],
    [ "Carrol Street Park 138kV Substation",[-94.07888022388371, 30.06656656991911]],
    [ "Entergy",[-94.1905176120167, 30.07791807509529]],
    [ "Amelia Bulk Substation",[-94.23417810544687, 30.077167797427723]],
    [ "Bevil Substation",[-94.23607145645667, 30.159541924822708]],
    [ "Briarcliff Substation",[-94.14276415, 30.104135249999995]],
    [ "Lindbergh Substation",[-94.15507475, 30.0706432]],
    [ "McHale Substation",[-94.13871928358145, 30.1274711036554]],
    [ "Tanglewood Substation",[-94.16868069949915, 30.09530279914593]],
    [ "West End Substation",[-94.13394220741795, 30.082151074689214]],
    [ "Parkdale Substation",[-94.1694455419774, 30.12570337535294]],
    [ "Tyrrell Substation",[-94.13315104796618, 30.036994115552655]],
    [ "Virginia Substation",[-94.10795795, 30.0424051]],
    [ "Lovell Lake Substation",[-94.15537523323657, 29.916472049812732]],
    [ "Jirou Substation",[-94.10807260658329, 30.09129317473976]],
    [ "North End Substation",[-94.11923015, 30.10686115]],
    [ "Travis Substation",[-94.09663207671866, 30.08546187709592]],
    [ "Rosedale Substation",[-94.17137762750484, 30.138317176054393]],
    [ "Fort Worth Substation",[-93.94182894822569, 29.87442676683356]],
    [ "Groves Substation",[-93.92092502524561, 29.945329074726555]],
    [ "Kolbs Substation",[-93.94010200264131, 29.9247246419389]],
    [ "Lakeview Substation",[-93.90111373574886, 29.913282381619208]],
    [ "Port Arthur Water Works Substation",[-93.94440646444662, 29.887782407710528]],
    [ "Texaco Substation",[-93.94539669999999, 29.888905599999998]],
    [ "Raywood Substation",[-94.67285795065096, 30.027456533171208]],
    [ "338086189",[-93.973652, 29.850229400000003]],
    [ "Entergy",[-93.97209649999999, 29.87393295]],
    [ "Port Acres Bulk Substation",[-93.99878875288088, 29.88717248729842]],
    [ "Westside Substation",[-93.95033744605445, 29.868322196450567]],
    [ "Dorsey Substation",[-94.01000356680312, 29.90737410218488]],
    [ "Explorer Substation",[-94.00527176486455, 29.900405788762292]],
    [ "Federal Substation",[-94.04947478440127, 29.965042466627526]],
    [ "Gallier Co Substation",[-94.22525023836623, 30.068435625048004]],
    [ "Meeker Substation",[-94.23234754999999, 30.066311300000002]],
    [ "Mobil Hebert Substation",[-94.0547607, 29.97275485]],
    [ "Orange Bulk Substation",[-93.79044128649497, 30.095206648771693]],
    [ "Poly Substation",[-94.23174005000001, 30.069751699999998]],
    [ "Port Acres Substation",[-94.00902885049425, 29.900442736497446]],
    [ "Sour Lake Substation",[-94.41202960433569, 30.13474725976878]],
    [ "Spurlock Substation",[-94.02908975, 29.965808550000002]],
    [ "Cordrey Substation",[-93.74219546698797, 30.110916774693308]],
    [ "Bertwood Substation",[-95.31415957266938, 29.843965372329425]],
    [ "Gears Substation",[-95.44007449071519, 29.964720528948533]],
    [ "Glenwood Substation",[-95.2659799837688, 29.850619973114146]],
    [ "Lauder Substation",[-95.32153535000002, 29.9112446]],
    [ "Lockwood Substation",[-95.28840195108413, 29.935077355203326]],
    [ "Scenic Woods Substation",[-95.28526468807526, 29.88372277520766]],
    [ "Monsanto Substation",[-95.21166376830807, 29.256272333983016]],
    [ "Alvin Substation",[-95.24944086148236, 29.433312724660055]],
    [ "Mustang Bayou Substation",[-95.20354747759032, 29.36282444657767]],
    [ "Southwyck Substation",[-95.38929147724456, 29.552231479619618]],
    [ "Pisek Substation",[-96.57189935, 29.921139200000002]],
    [ "Welcome Substation",[-96.47851206360896, 30.032737130569046]],
    [ "Almeda Substation",[-95.40362396742208, 29.630098245698324]],
    [ "College Substation",[-95.24484670000001, 29.659717749999995]],
    [ "Hidden Valley Substation",[-95.42288925631505, 29.91125206608174]],
    [ "DuPont Dee Substation",[-94.02849574999999, 30.010021049999995]],
    [ "Magnolia Substation",[-93.9524233071426, 29.97813992728903]],
    [ "Gulfway Substation",[-93.88992779628845, 29.945671984648875]],
    [ "Hanks Substation",[-93.9253402342189, 29.907455524588784]],
    [ "Pansy Substation",[-94.22516890786005, 29.895145120490678]],
    [ "Pine Street Substation",[-93.93416112776363, 29.976149186648538]],
    [ "Port Neches Bulk Substation",[-93.93870735922918, 29.963240877374847]],
    [ "VFW Park Substation",[-93.89722474999999, 29.9617857]],
    [ "Central Substation",[-94.0109483538473, 29.985672115342506]],
    [ "Crowder Substation",[-93.9786958332544, 29.97159049952764]],
    [ "Goodrich Substation",[-93.94686357268974, 29.988070207196778]],
    [ "Linde Substation",[-93.92206996499276, 29.963355720406756]],
    [ "Port Neches Substation",[-93.96099108942165, 29.987362051587137]],
    [ "Union Substation",[-93.98427190135418, 29.995091033256525]],
    [ "Manchester Substation",[-93.90366119999997, 29.933782100000002]],
    [ "338519270",[-95.15030280000002, 29.728997199999995]],
    [ "338519275",[-95.17034994235034, 29.737978075314793]],
    [ "South Channel Substation",[-95.16442921707227, 29.721737248479844]],
    [ "Strawberry Belt Substation",[-95.17203156532, 29.699146474553814]],
    [ "Witter Substation",[-95.20040223858916, 29.716356709800117]],
    [ "CenterPoint Energy",[-95.34149091192062, 29.74609873221232]],
    [ "338552656",[-95.01481015, 29.753522450000002]],
    [ "338552658",[-94.98035889999998, 29.858967699999997]],
    [ "338552661",[-94.92787085, 29.828039200000003]],
    [ "338552664",[-95.01999334018122, 29.742296061631638]],
    [ "Highlands Substation",[-95.03134278327813, 29.800751016613425]],
    [ "PSArco Substation",[-95.12110132531907, 29.81326670595321]],
    [ "Dyann Substation",[-96.07032919591803, 29.289233889270655]],
    [ "Freeman Substation",[-95.84134831569061, 29.875212767554544]],
    [ "Freeport Substation",[-95.34845624144586, 28.940345370286344]],
    [ "League City Main Substation",[-95.08199034787175, 29.500890030875006]],
    [ "Amoco Substation",[-95.18702253491024, 29.233779204091196]],
    [ "Friendswood Substation",[-95.20133650000001, 29.499148749999996]],
    [ "Hastings Substation",[-95.25257404338858, 29.483244625409572]],
    [ "Nash Substation",[-95.59401586328474, 29.243485952554963]],
    [ "Seminole Substation",[-95.17917009583807, 29.468452406064134]],
    [ "Kemah Substation",[-95.02631187439736, 29.53827208176651]],
    [ "Greenbelt Substation",[-94.93458212473632, 29.379626505900944]],
    [ "Apache Substation",[-94.94757567579994, 29.378682304973868]],
    [ "Heights Substation",[-94.94995961750956, 29.38954471234326]],
    [ "Tejas Substation",[-94.92298207643223, 29.38015271925023]],
    [ "Caddo Substation",[-94.94412808199567, 29.379565425146247]],
    [ "La Marque Substation",[-94.96500879675294, 29.367124965644177]],
    [ "Amoco Substation",[-94.9245278749648, 29.363622498196364]],
    [ "Comanche Switching Station",[-94.91486245, 29.373260450000004]],
    [ "Cherokee Switching Station",[-94.91582576622393, 29.367131252568715]],
    [ "Northside Substation",[-94.91358665, 29.40902845]],
    [ "338819909",[-94.94381279999999, 29.378932450000004]],
    [ "338858081",[-94.90186139894423, 29.376744632988537]],
    [ "Terminal Substation",[-94.91128232704307, 29.37058800499507]],
    [ "Choctaw Substation",[-94.90772924128918, 29.376446125183048]],
    [ "ISP Switching Station",[-94.96381483518891, 29.4256314502904]],
    [ "338858213",[-94.8926781319653, 29.383041660501817]],
    [ "Texas City Main Substation",[-94.90152969117045, 29.381484621023972]],
    [ "Clemons Substation",[-95.57344375000001, 28.988761550000003]],
    [ "Clemons Switching Station",[-95.58542130000002, 29.056894050000004]],
    [ "Angleton Substation",[-95.44063741276216, 29.166977524283773]],
    [ "Brazoria Substation",[-95.57758864454696, 29.062365036065245]],
    [ "Sweeny Substation",[-95.68713135331141, 29.055408583687555]],
    [ "West Columbia Main Substation",[-95.65710501006853, 29.154580722246322]],
    [ "Alden Substation",[-95.51525053137392, 30.232063952572595]],
    [ "Plantation Substation",[-95.4333099092762, 30.25164344502197]],
    [ "Conair Substation",[-95.44718872712144, 30.338003308664852]],
    [ "Conroe Bulk Substation",[-95.45525783255303, 30.28902941225071]],
    [ "Fish Creek Substation",[-95.54778738892654, 30.29732414696457]],
    [ "Metro Substation",[-95.46224575076963, 30.174779579359782]],
    [ "Kerrville Legion Substation",[-99.10036132524503, 30.000341898811378]],
    [ "Kerrville Travis Substation",[-99.12454899735228, 30.04262396578395]],
    [ "Goat Creek Substation",[-99.2102282488761, 30.079737337631]],
    [ "Rim Rock Substation",[-99.17313680298305, 30.030130057271098]],
    [ "338951879",[-93.75868448337378, 30.050501964069348]],
    [ "Adams Bayou Substation",[-93.7516059789908, 30.059839994580393]],
    [ "Bunch Gully Substation",[-93.77454685000002, 30.05034055]],
    [ "Cow Substation",[-93.74972117754655, 30.041195865005726]],
    [ "Front Street Substation",[-93.72933248298718, 30.090963677686215]],
    [ "West Orange Substation",[-93.75845164163765, 30.07595709571831]],
    [ "Himex Substation",[-94.39557070000001, 29.559350199999994]],
    [ "Stowell Substation",[-94.40101250873559, 29.791971874807725]],
    [ "Turtle Creek Substation",[-99.20725125043619, 29.96897786388231]],
    [ "Hunt Substation",[-99.3360440645505, 30.076141182308305]],
    [ "Ingram Substation",[-99.24522156755359, 30.08450781497588]],
    [ "Medina Substation",[-99.2538585865745, 29.80634053980235]],
    [ "Met Center Substation",[-97.69314727392471, 30.211019048324005]],
    [ "AMD Substation",[-97.72370735165082, 30.214718777177875]],
    [ "Grove Substation",[-97.71100147428966, 30.216452085614893]],
    [ "Burleson Substation",[-97.73670076591856, 30.214663935477454]],
    [ "Hi Cross Substation",[-97.76906448593662, 30.18623422994886]],
    [ "Jollyville Substation",[-97.78156329949509, 30.479543323037454]],
    [ "Howard Lane Substation",[-97.68868962152185, 30.43317262140908]],
    [ "Tech Ridge Substation",[-97.6658943451942, 30.419643927823486]],
    [ "Williamson Substation",[-97.75024243782742, 30.437188393967993]],
    [ "Northwest Substation",[-97.75730990369327, 30.4645990036312]],
    [ "Round Rock South Substation",[-97.6941709125389, 30.47424081612512]],
    [ "Round Rock Substation",[-97.70223877190128, 30.521210898378488]],
    [ "Fiskville Substation",[-97.68310827308902, 30.362150008577554]],
    [ "Justin Lane Substation",[-97.71614056338055, 30.343543354117827]],
    [ "Wells Branch Substation",[-97.68905166546678, 30.446141488147518]],
    [ "Harris Branch Substation",[-97.58090554644326, 30.371277215119996]],
    [ "Sprinkle Substation",[-97.64554277049103, 30.353124312763182]],
    [ "Celnec Substation",[-95.06481615, 29.628314500000002]],
    [ "Bimont Substation",[-95.05540841017145, 29.64766249631144]],
    [ "Pilgrim Substation",[-95.17498671629173, 29.552306448053358]],
    [ "Thompsons Substation",[-95.5863788, 29.4812446]],
    [ "Trinity Bay Substation",[-94.91441483004674, 29.71931676535955]],
    [ "Ulrich Substation",[-95.48381089817558, 29.735880599515966]],
    [ "Rosharon Substation",[-95.42965488654986, 29.353742134726716]],
    [ "339237010",[-94.92821979999998, 29.850457999999996]],
    [ "339237011",[-94.94055492041436, 29.86071978885349]],
    [ "339237012",[-94.92151636525523, 29.838655707277134]],
    [ "Geronimo Substation",[-97.97061108429709, 29.627437583266293]],
    [ "New Berlin Substation",[-98.0439514, 29.487606899999996]],
    [ "Schumansville Substation",[-98.09707258800087, 29.653871123449434]],
    [ "Randolph Field Substation",[-98.29064582010845, 29.54354393828232]],
    [ "Edison Substation",[-99.57621574026668, 30.333065559494166]],
    [ "Segovia Substation",[-99.65183132249594, 30.409877653517945]],
    [ "Old Ocean Substation",[-95.73726555387955, 29.065778183847893]],
    [ "Sweeny Cogeneration Substation",[-95.7438603573442, 29.07210612216903]],
    [ "Phillips Tank Farm Substation",[-95.74461249248988, 29.07615256338178]],
    [ "Big Hill Substation",[-100.54329057696388, 31.047375688807975]],
    [ "Orsted Substation",[-100.19485176729273, 30.695973883527415]],
    [ "College Station Junction",[-96.29245405169637, 30.62908879954798]],
    [ "Lake Forest Substation",[-95.61453914291634, 30.340392007406653]],
    [ "Robertson Substation",[-96.5693178595557, 30.945135620549795]],
    [ "Elliot Substation",[-96.57588158708592, 30.951427127530433]],
    [ "Franklin Substation",[-96.49205155, 31.042072999999995]],
    [ "Central Austin Substation",[-97.733894, 30.3107185]],
    [ "Balcones Substation",[-97.73020364073845, 30.391032163515064]],
    [ "Koenig Lane Substation",[-97.72702830653344, 30.328349627550736]],
    [ "Northland Substation",[-97.75472532663511, 30.335371273927063]],
    [ "Lakeshore Substation",[-97.78048962769729, 30.31517284164549]],
    [ "Glasscock Substation",[-97.69407770000002, 30.73959685]],
    [ "Rivery Substation",[-97.68955085864752, 30.648531346190197]],
    [ "Chief Brady Substation",[-97.69594864097888, 30.591816032762296]],
    [ "Kent Substation",[-97.80831064171821, 30.495274869847087]],
    [ "Elgin Switching Station",[-97.3591062, 30.417017249999997]],
    [ "Taylor Substation",[-97.39729224624737, 30.566857912587565]],
    [ "Bee Creek Substation",[-98.00432542520886, 30.347045407424353]],
    [ "Stockdale Road Substation",[-98.14486235589217, 29.147319054190696]],
    [ "Cushman Substation",[-97.94567561979531, 29.57422445579605]],
    [ "Hickory Forest Substation",[-97.86417215023057, 29.468391067262765]],
    [ "Highway 123 Substation",[-97.95848508588254, 29.494025255065996]],
    [ "Sutherland Springs Substation",[-98.06397248937083, 29.266929449613485]],
    [ "Wilson Substation",[-98.17283204402634, 29.337662206117148]],
    [ "Rutherford Substation",[-97.9856322000108, 30.117727044711483]],
    [ "Phillips Johnson City Substation",[-98.23933862030921, 30.281901492849634]],
    [ "Dripping Springs Substation",[-98.08373293853256, 30.17375782266691]],
    [ "Lago Vista Substation",[-97.97868763744783, 30.46056966516338]],
    [ "Manchaca Substation",[-97.85043462961644, 30.135281938947273]],
    [ "Henly Substation",[-98.15683361436093, 30.20281316395688]],
    [ "Brodie Lane Substation",[-97.84823522847664, 30.187698769257484]],
    [ "Slaughter Lane Substation",[-97.81150099062805, 30.172195072709]],
    [ "Turnersville Substation",[-97.7829593300726, 30.073837477070654]],
    [ "Warren Substation",[-97.75828709545938, 30.310661519424524]],
    [ "Mula Substation",[-95.56197565000001, 29.633244050000002]],
    [ "Haden Substation",[-95.17300669999999, 29.7596738]],
    [ "Port Substation",[-95.18462617439064, 29.753136871273963]],
    [ "Exxon",[-95.02106546646604, 29.74888856114702]],
    [ "Jacintoport Substation",[-95.1251929975685, 29.751809782144086]],
    [ "Taylor Substation",[-95.1942955017667, 29.75107428278545]],
    [ "340151341",[-95.15424085, 29.727033950000003]],
    [ "Bader Substation",[-98.94249685293663, 29.370576990590468]],
    [ "Big Foot Substation",[-98.95038243140306, 29.05971829577932]],
    [ "Castroville Substation",[-98.88694170013561, 29.37656588199207]],
    [ "Devine Substation",[-98.92432724272022, 29.125302997611996]],
    [ "Moore Substation",[-99.02247229728223, 29.066875194411743]],
    [ "Natalia Substation",[-98.90522140071681, 29.207200752674705]],
    [ "Barclay Substation",[-97.11498915, 31.0759216]],
    [ "Temple Taylor Valley Substation",[-97.40421314999999, 31.034761600000003]],
    [ "Lake Belton Substation",[-97.43411624999999, 31.141439400000007]],
    [ "Temple Southeast Substation",[-97.35496764999999, 31.0712045]],
    [ "Scott and White Substation",[-97.36008439017672, 31.075781252884603]],
    [ "Temple Substation",[-97.33148519526462, 31.088058097944646]],
    [ "Seaton Substation",[-97.28242430541972, 31.080949535831216]],
    [ "Bartlett Substation",[-97.4267401, 30.791420950000006]],
    [ "Granger Substation",[-97.44662111105829, 30.7233672119527]],
    [ "Holland Substation",[-97.4033010318425, 30.87547973885161]],
    [ "Temple Elm Creek Substation",[-97.32999087580839, 31.13087013905169]],
    [ "Whitney Dam Substation",[-97.37152928179859, 31.864588096511696]],
    [ "Round Rock Northeast Substation",[-97.64892450205136, 30.54145232694025]],
    [ "Taylor West Substation",[-97.48663206317137, 30.556999723305804]],
    [ "Georgetown South Substation",[-97.6832025265973, 30.588512918538253]],
    [ "Round Rock Westinghouse Substation",[-97.698535170219, 30.567044289933058]],
    [ "Oncor",[-99.19780923664793, 31.536375178820332]],
    [ "Goldthwaite Substation",[-98.56638310672528, 31.430975776898507]],
    [ "Brown Substation",[-99.19521279428984, 31.53798864399355]],
    [ "Trading Post Substation",[-97.97131438552647, 30.312883308818297]],
    [ "340593713",[-96.80133907768753, 32.80775482039407]],
    [ "Kirkland Park Substation",[-96.76396689055504, 32.89290842902784]],
    [ "Greenville Avenue Substation",[-96.76877019679429, 32.841153987990566]],
    [ "Haskell Substation",[-96.74596020021352, 32.782142280786914]],
    [ "340631516",[-96.81880705, 32.76448145]],
    [ "Calumet Substation",[-96.89454140000001, 32.742144700000004]],
    [ "Wall Street Substation",[-96.78510895643139, 32.76232067663779]],
    [ "340641132",[-97.15640905, 32.7110224]],
    [ "340641133",[-97.18515464999999, 32.71699345]],
    [ "340641135",[-97.07864435, 32.763987500000006]],
    [ "340641147",[-97.06662727627938, 32.778265796812676]],
    [ "340641160",[-97.11559676135664, 32.71368497947034]],
    [ "340663534",[-97.25440285, 32.6749586]],
    [ "340663535",[-97.27002456254424, 32.73341252505733]],
    [ "Hemphill Substation",[-97.33238305, 32.73938585]],
    [ "Mistletoe Heights Substation",[-97.35370565, 32.734544299999996]],
    [ "340699622",[-96.75395555, 33.003930499999996]],
    [ "Armstrong Substation",[-96.79217390815447, 32.822856263335865]],
    [ "Blackwell Street Substation",[-96.76582884598676, 32.86744297081865]],
    [ "340879661",[-97.18696734158101, 32.85089656745604]],
    [ "Oncor",[-97.2265100054261, 32.84601265882939]],
    [ "340879728",[-97.32543715944468, 32.79900292518147]],
    [ "340879729",[-97.30567026151633, 32.463994826609664]],
    [ "340879737",[-97.17395205000001, 32.5842764]],
    [ "340879757",[-97.38274036591028, 32.813522002894054]],
    [ "340879762",[-97.05940692503252, 32.870000992830946]],
    [ "Euless South Substation",[-97.12213577473351, 32.81508315815582]],
    [ "340879786",[-97.30785167575264, 32.875692324948616]],
    [ "340879792",[-97.01166687070338, 32.86551500351635]],
    [ "Oncor",[-97.33644275, 32.742122099999996]],
    [ "340879805",[-97.34764599999998, 32.64782459999999]],
    [ "340879839",[-97.42501421381, 32.495047136840405]],
    [ "340879844",[-97.22325192749614, 32.80553676872673]],
    [ "340879847",[-97.30268100260896, 32.82065932110113]],
    [ "340879848",[-97.38387762657544, 32.46186744835869]],
    [ "340879853",[-97.06359517399002, 32.818615185551074]],
    [ "Bedford Substation",[-97.14731474538955, 32.852673421312105]],
    [ "Bryan Avenue Substation",[-97.32501189999999, 32.704357300000005]],
    [ "Forest Hill Substation",[-97.28616696956735, 32.64490271440698]],
    [ "Forest Hill Switching Station",[-97.28710247711697, 32.64482471615639]],
    [ "Saginaw Switching Station",[-97.32724208608649, 32.821321845333486]],
    [ "Duval Substation",[-97.29522370647311, 32.72536757522808]],
    [ "340991843",[-97.35785517044596, 32.75862044659686]],
    [ "Blackstone Substation",[-97.39171560117752, 32.76473669914926]],
    [ "General Dynamics Substation",[-97.4526988650849, 32.77296802416495]],
    [ "North Main Substation",[-97.33586944999999, 32.762253]],
    [ "341012449",[-97.12185107521347, 32.844313192801295]],
    [ "341012455",[-97.45856743742097, 32.76741152473193]],
    [ "Colleyville Substation",[-97.16532208474187, 32.894518296480676]],
    [ "Keller Wall Price Substation",[-97.25657289311455, 32.92059270737127]],
    [ "341030397",[-97.09108995, 32.900675150000005]],
    [ "Euless Substation",[-97.09931076499183, 32.86532491623057]],
    [ "Grapevine Ball Street",[-97.08495590759054, 32.92613352595727]],
    [ "341093644",[-98.19496617582857, 29.870521709477423]],
    [ "Crane's Mill Substation",[-98.28332787661257, 29.852456993803724]],
    [ "Sattler Substation",[-98.17121675617953, 29.849710318664375]],
    [ "341162122",[-97.02024766532871, 32.92654386778255]],
    [ "Coppell Switching Station",[-97.00784974999998, 32.94908075]],
    [ "Custer Substation",[-96.75244431433342, 33.1598322252253]],
    [ "Collin Steam Electric Station",[-96.81315876833787, 33.19832344640533]],
    [ "Frisco Substation",[-96.83162706095288, 33.181827425076754]],
    [ "Lebanon Substation",[-96.77479134232273, 33.11113917523464]],
    [ "Lebanon Substation",[-96.77489260130585, 33.13695547572647]],
    [ "Navo Substation",[-96.91786180174114, 33.221109182167794]],
    [ "North Lake Substation",[-96.97571656466381, 32.95133748057758]],
    [ "Panther Creek Substation",[-96.87700450264698, 33.20318660897191]],
    [ "Rock Hill Substation",[-96.79329195909499, 33.20312396688164]],
    [ "341282090",[-96.84827439999998, 32.95609935]],
    [ "341282099",[-96.819564866867, 32.96238952293349]],
    [ "341282104",[-96.85677405536741, 33.012179721784484]],
    [ "Oncor",[-96.8490489604263, 32.97593200091563]],
    [ "Carrollton-Josey Lane Substation",[-96.88905402554107, 32.977436843171276]],
    [ "Carrollton-Tarpley Road Substation",[-96.85064297495444, 32.982078954171214]],
    [ "Carrollton-Upfield Substation",[-96.91275448932386, 32.95041760530321]],
    [ "341316593",[-96.99718581309935, 33.00305629870943]],
    [ "Highland Substation",[-97.05195156855224, 33.085989361660396]],
    [ "South Substation",[-97.01086768239065, 33.006291663916365]],
    [ "West Substation",[-97.03502502501952, 33.047907612321644]],
    [ "Fischer Substation",[-98.20578526252018, 29.93314549912652]],
    [ "Highway 32 Substation",[-98.09356166811506, 29.94576872390263]],
    [ "Cemex Substation",[-98.18080862258219, 29.675020030338878]],
    [ "EC Mornhinweg Substation",[-98.20290420205534, 29.658207099513756]],
    [ "Freiheit Road Substation",[-98.08592084006335, 29.703953800548618]],
    [ "Loop 337 Substation",[-98.15997181579935, 29.702245780520514]],
    [ "Seymour Substation",[-99.25457147269498, 33.59466245252336]],
    [ "Hearne Southwest Substation",[-96.60725117538864, 30.861871718973685]],
    [ "Hearne Substation",[-96.60106130189573, 30.859892048870396]],
    [ "Oncor",[-96.8716313, 32.77769225]],
    [ "341698284",[-96.87462936752311, 32.75521093892643]],
    [ "Marshall Substation",[-94.39636426178133, 32.53888959761518]],
    [ "Scottsville Substation",[-94.2543148720594, 32.54329909759956]],
    [ "Southeast Marshall Substation",[-94.3517138, 32.5223399]],
    [ "Waskom Substation",[-94.04639045, 32.47809155]],
    [ "Pooley Road Substation",[-97.74711398425887, 29.695603016102964]],
    [ "Thompson Creek Substation",[-96.41744164654915, 30.595067238383265]],
    [ "Greens Prairie Substation",[-96.25780371913388, 30.554228427628114]],
    [ "342357908",[-97.36382971771697, 32.69058989126301]],
    [ "342357909",[-97.0259471912723, 32.466100946900745]],
    [ "342357910",[-97.03623788011932, 32.4565181615323]],
    [ "Alvarado Substation",[-97.22349670025254, 32.396295545161685]],
    [ "Cleburne Substation",[-97.37487429529024, 32.354097578427464]],
    [ "Keene Substation",[-97.33611482187432, 32.36740397108726]],
    [ "342455288",[-93.92730000000002, 30.930394200000002]],
    [ "Doucette Substation",[-94.43485125217258, 30.835733737624057]],
    [ "Fork Creek Substation",[-94.2936594954172, 30.883321873799442]],
    [ "Jasper Substation",[-94.00061851969994, 30.9508058949709]],
    [ "Kountze Bulk Substation",[-94.33117868770329, 30.359074002777234]],
    [ "Mill Creek Substation",[-94.10604565000001, 30.99427615]],
    [ "Newton Bulk Substtaion",[-93.7877478, 30.869912549999995]],
    [ "Sam Dam Substation",[-94.10482231772629, 31.059218912383084]],
    [ "Turkey Creek Substation",[-94.42413639040844, 30.83669022388253]],
    [ "Warren Substation",[-94.43506156716089, 30.619953896727885]],
    [ "Ranch Road 12 Substation",[-97.99184263260152, 29.9005837509347]],
    [ "342687333",[-96.84596169329728, 32.44451606517291]],
    [ "342687353",[-96.90769119999999, 32.4679745]],
    [ "342687354",[-96.85788613597664, 32.6092307813301]],
    [ "342687361",[-96.74155991698188, 32.49439931026479]],
    [ "342687362",[-96.87729339835212, 32.45776903875291]],
    [ "342687363",[-96.80572313805776, 32.53436944429667]],
    [ "Hutchins Substation",[-96.7053337, 32.6303563]],
    [ "Midlothian South Substation",[-96.9907997005945, 32.4673437726657]],
    [ "Northaven Switching Station",[-96.81005051917367, 32.90137727699204]],
    [ "342701078",[-96.8728433, 32.846812199999995]],
    [ "Morrison Lane Substation",[-96.8934412634273, 32.8831675500263]],
    [ "342737799",[-96.99194215, 32.89492645]],
    [ "JNEC",[-94.10493850000002, 30.8825291]],
    [ "JNEC",[-94.18727071726578, 31.006508568961042]],
    [ "Fletcher Substation",[-94.19029725, 30.262738500000005]],
    [ "Kirbyville Substation",[-93.89050164999999, 30.673097100000003]],
    [ "North Silsbee Substation",[-94.17598629999999, 30.376272600000004]],
    [ "Evadale Substation",[-94.0694034627814, 30.341360932781914]],
    [ "J. K. Spruce Switchyard",[-98.32037580078983, 29.312417364125842]],
    [ "342897831",[-96.84888638761788, 32.81295172146771]],
    [ "Record Crossing Substation",[-96.85588005, 32.81807445]],
    [ "342897833",[-96.83831213370424, 32.808062750320104]],
    [ "Lomo Alto Substation",[-96.81272899852823, 32.84995459987664]],
    [ "Reagan Street Substation",[-96.81920537878337, 32.803684080386674]],
    [ "343001252",[-96.17514834914151, 33.5743125121814]],
    [ "Knippa Substation",[-99.64603365189225, 29.28864009444246]],
    [ "D'Hanis Substation",[-99.27757016254571, 29.321028375139555]],
    [ "Sabinal Substation",[-99.46359021314593, 29.298849958183574]],
    [ "Uvalde Substation",[-99.7985821489902, 29.213014943645756]],
    [ "Uvalde Substation",[-99.72988476333151, 29.222436115562285]],
    [ "343150314",[-96.70054895924589, 32.727396925249906]],
    [ "343150317",[-96.34968611395608, 32.915396217726794]],
    [ "McGregor Substation",[-97.40295665000001, 31.43896675]],
    [ "Waco Sanger Avenue Substation",[-97.19695381749808, 31.52247979543692]],
    [ "Waco Woodway Substation",[-97.22215838496224, 31.512204275579272]],
    [ "Belton Substation",[-97.45864093960078, 31.08319136428077]],
    [ "Waco Atco Substation",[-97.25082231730524, 31.485288180542145]],
    [ "Asphalt Mines Substation",[-100.0241696736704, 29.188127284459775]],
    [ "Brackettville Substation",[-100.39228466948208, 29.324610951007138]],
    [ "Eclipse Substation",[-100.39203980000002, 29.32395135]],
    [ "Odlaw Substation",[-100.19805397345162, 29.268277999998645]],
    [ "West Batesville Substation",[-99.72873314402915, 28.94489845337306]],
    [ "West Uvalde Substation",[-99.8347882019522, 29.18353175415113]],
    [ "343387971",[-96.11667524295277, 32.90306768365473]],
    [ "343387972",[-96.63645807336215, 32.910147403961055]],
    [ "343387978",[-96.25369604877501, 32.732182154802906]],
    [ "Terrell Substation",[-96.28053647770815, 32.7714847028496]],
    [ "343430386",[-94.75663022567997, 32.569932307109724]],
    [ "Longview Heights Substation",[-94.68738942260362, 32.506478457209575]],
    [ "343430410",[-94.70240848451293, 32.42827490028428]],
    [ "Greggton Substation",[-94.81580074708808, 32.51080736658349]],
    [ "Harrison Road Substation",[-94.80905708437028, 32.493650151788295]],
    [ "Knox Lee",[-94.64139655245849, 32.37741804338702]],
    [ "Lake Lamond Substation",[-94.77002789252316, 32.506656459930255]],
    [ "LeTourneau Substation",[-94.73178003007666, 32.458615543179526]],
    [ "Longview Substation",[-94.72989414419347, 32.49242449124923]],
    [ "Spring Hill Substation",[-94.81253237430153, 32.581854252793455]],
    [ "Whitney Substation",[-94.7151764479442, 32.46968111702969]],
    [ "Bakersfield Substation",[-102.28909180000002, 30.97585545]],
    [ "Castle Gap Substation",[-102.29505106869928, 31.11571551221227]],
    [ "McCamey Substation",[-102.20329504804293, 31.137250711337447]],
    [ "North McCamey Subsation",[-102.22321930437313, 31.153618848361543]],
    [ "North McCamey Substation",[-102.22679962559185, 31.15506331893457]],
    [ "Oxy Tap Substation",[-102.29791480312437, 31.119233075622006]],
    [ "Tippet Substation",[-102.13376039999999, 30.992163050000002]],
    [ "Big Sandy Substation",[-95.10574753792312, 32.58882840109674]],
    [ "Perdue Substation",[-94.99806184489455, 32.57301539617594]],
    [ "Anacacho Substation",[-100.1712142, 29.204646]],
    [ "Central Bluff Substation",[-100.15481485000001, 32.117189249999996]],
    [ "Ferris Substation",[-99.74521706666665, 29.323261666666664]],
    [ "Leona Substation",[-99.71146260630995, 29.08552496866338]],
    [ "North Batesville Substation",[-99.61682496255902, 28.963355007286527]],
    [ "Sabinal Substation",[-99.464664477728, 29.3153618542687]],
    [ "South Batesville Substation",[-99.60625682625424, 28.920792332724762]],
    [ "Hilltop Lakes Substation",[-96.22217908974947, 31.080041569539475]],
    [ "North Zulch Substation",[-96.0738158668653, 30.919755778580498]],
    [ "Bedias Substation",[-95.96571271963404, 30.785500427550964]],
    [ "Petteway Substation",[-96.52449417063782, 31.16660883142983]],
    [ "344024177",[-95.8138481152733, 31.63284781011264]],
    [ "344024179",[-95.76378767691747, 31.594426826777372]],
    [ "344025038",[-95.8306778761551, 31.74767043189096]],
    [ "344028187",[-95.70992863813797, 31.655378065509282]],
    [ "344028189",[-95.706572142654, 31.65208056612108]],
    [ "Ranchtown Substation",[-98.73599353030356, 29.617836123403862]],
    [ "Palo Duro Substation",[-99.19058265, 28.845922350000002]],
    [ "South Texas Electric Cooperative",[-99.091733415494, 28.93129959636296]],
    [ "Frio Town Substation",[-99.34344533448909, 29.022088545931364]],
    [ "Derby Substation",[-99.19392212877648, 28.79042400405039]],
    [ "Dilley Substation",[-99.20191137593541, 28.68051343718395]],
    [ "Dilley Substation",[-99.174124427879, 28.664596565620766]],
    [ "Dilley Switching Station",[-99.20512169297707, 28.668791620762782]],
    [ "Pearsall Substation",[-99.07932445, 28.8881174]],
    [ "Pleasanton Substation",[-98.43814582655843, 28.99033950968224]],
    [ "Rough Canyon Substation",[-100.89600540545098, 29.53745788084575]],
    [ "344546760",[-101.02738735000001, 29.460287250000004]],
    [ "Amistad Switchyard",[-101.05574974309162, 29.44949502498506]],
    [ "Amistad Switchyard",[-101.05468270387796, 29.448233266090067]],
    [ "Buena Vista Substation",[-100.91573021931285, 29.40107758913499]],
    [ "Del Rio Substation",[-100.89328152060229, 29.36703472265324]],
    [ "Picacho Substation",[-100.94093770329567, 29.432449327501864]],
    [ "Comstock Substation",[-101.1394552, 29.747458249999994]],
    [ "Comstock Substation",[-101.13839533447828, 29.74803607440313]],
    [ "Illinnois #4 Substation",[-101.53549235324358, 30.554505034306104]],
    [ "Robinson Substation",[-97.10760744999999, 31.445499350000006]],
    [ "Waco M&M Mars 1 Substation",[-97.19653339999999, 31.488107899999996]],
    [ "Waco East Substation",[-97.11965947033381, 31.559735628209875]],
    [ "345041930",[-95.34114771021316, 32.3469103909198]],
    [ "Waco Northwest Substation",[-97.20508294124603, 31.543901182946435]],
    [ "McGregor Substation",[-97.42576140000001, 31.442076649999997]],
    [ "Crawford Substation",[-97.40890033929198, 31.540210414046978]],
    [ "Bellmead Substation",[-97.11038067785813, 31.61185219341909]],
    [ "Waco North Substation",[-97.17930129198527, 31.577443476877626]],
    [ "Cauthorn Substation",[-100.67481554999999, 30.304782599999996]],
    [ "Sonora Substation",[-100.65577535252903, 30.567663752204947]],
    [ "345472986",[-96.82201435048495, 32.9314404172252]],
    [ "345472990",[-96.84724547482348, 32.93313655660221]],
    [ "Oncor",[-96.80096927383107, 32.97646287484567]],
    [ "Richardson Collins Boulevard Substation",[-96.71538989709244, 32.967473727403515]],
    [ "Richardson Woodhaven Substation",[-96.71511428558284, 32.938212028853776]],
    [ "Hallsville Substation",[-94.5780630053099, 32.50603873468778]],
    [ "Marshall Substation",[-94.39458677661473, 32.53840093060151]],
    [ "North Marshall Substation",[-94.36867029999999, 32.56877915]],
    [ "Hillsboro Substation",[-97.13695728689912, 32.01480120095955]],
    [ "345547537",[-96.85113268578613, 32.37741524330524]],
    [ "PAVE PAWS Substation",[-100.5512167, 30.979612300000003]],
    [ "Eldorado Live Oak Substation",[-100.57007836960082, 30.87803471765048]],
    [ "Langford Wind Substation",[-100.63074685000001, 31.1185543]],
    [ "Santiago Substation",[-100.5132428, 31.116265799999994]],
    [ "Schkade Substation",[-100.53169790000001, 31.40451405]],
    [ "San Angelo Avenue N Substation",[-100.47738528621944, 31.44512938594312]],
    [ "Rusthill Substation",[-100.42482348002974, 31.45172565236849]],
    [ "San Angelo Coke Street Substation",[-100.40439852468636, 31.463937775266675]],
    [ "San Angelo Lake Drive Substation",[-100.46637104999999, 31.495977299999993]],
    [ "San Angelo North Substation",[-100.43522229720651, 31.494013784373823]],
    [ "San Angelo Paulann Substation",[-100.3853081320697, 31.48820311887944]],
    [ "Newman Switching Station",[-96.62455223977915, 32.91362209769508]],
    [ "346508738",[-96.68001752475068, 32.93421207110384]],
    [ "Castle Substation",[-96.6141739655997, 32.92135248065217]],
    [ "Garland Substation",[-96.67762924231675, 32.90094095045099]],
    [ "Marquis Substation",[-96.67765626876779, 32.902192241849846]],
    [ "Naaman Substation",[-96.61138848592842, 32.93691318434606]],
    [ "Shiloh Substation",[-96.66687405859676, 32.900590959270346]],
    [ "Walnut Substation",[-96.63170076290403, 32.91642553475876]],
    [ "Centerville Substation",[-96.63457251099022, 32.87090206791883]],
    [ "Miller Substation",[-96.60240490971097, 32.894579554318845]],
    [ "Wynn Joyce Substation",[-96.60159499836764, 32.865524254067715]],
    [ "Crist Road Substation",[-96.62481410160309, 32.93719636716849]],
    [ "Hulldale Substation",[-100.55537945000002, 31.0049199]],
    [ "Arrot Substation",[-100.50966395000002, 31.073597999999997]],
    [ "Christoval Substation",[-100.4907822, 31.191862750000006]],
    [ "San Angelo Emerson Street Substation",[-100.4259275, 31.473927499999995]],
    [ "San Angelo Jackson Street Substation",[-100.45573578871502, 31.45008032470732]],
    [ "San Angelo Lake Nasworthy Substation",[-100.4507583, 31.3623077]],
    [ "San Angelo Mathis Field Substation",[-100.4967651, 31.343775100000006]],
    [ "San Angelo Walnut Street Substation",[-100.45713503368886, 31.46449529953895]],
    [ "346832060",[-96.80024822369697, 31.856472265461704]],
    [ "346832061",[-97.07570418884613, 31.74223914109359]],
    [ "Lorena Substation",[-97.2129077, 31.355317499999998]],
    [ "Abbott Substation",[-97.07770564910943, 31.88499529956289]],
    [ "Eddy Substation",[-97.26045508153737, 31.291968396537726]],
    [ "Grandview TMPP Substation",[-97.24017076040107, 32.336635390821066]],
    [ "Lorena Substation",[-97.2115684323354, 31.376902213114153]],
    [ "Mertens Substation",[-96.89252024999999, 32.05815415]],
    [ "Ross Substation",[-97.11326503228, 31.749587954283303]],
    [ "346878504",[-97.21500645441968, 32.342503148549106]],
    [ "Lillian Substation",[-97.19503171827513, 32.49086183616365]],
    [ "Fort Worth Substation",[-97.15853583433375, 33.16173235059046]],
    [ "346938537",[-97.12599670730502, 33.135381568996216]],
    [ "346938538",[-97.08859628017531, 33.158568830108834]],
    [ "Pockrus Substation",[-97.08460521765993, 33.171992237437166]],
    [ "Teasley Substation",[-97.10838087834121, 33.14491905298547]],
    [ "Sanger North Substation",[-97.18640842552226, 33.369539194347354]],
    [ "Rector Road Substation",[-97.1918294701731, 33.32234304672618]],
    [ "El Paso Electric",[-106.39067888573348, 31.91427761529877]],
    [ "Veribest Substation",[-100.2524489, 31.419303000000003]],
    [ "Harker Heights South Substation",[-97.63701547419763, 31.053837334490915]],
    [ "Belton Southwest Substation",[-97.47630527145591, 31.06134330959048]],
    [ "352526950",[-93.6510976611146, 30.741995989819703]],
    [ "352526951",[-93.65027186048047, 30.723317086328223]],
    [ "Sterling County Substation",[-101.18234140905238, 31.828905869636753]],
    [ "McDonald Road Substation",[-101.27560710129856, 32.086251849753936]],
    [ "359625445",[-101.46618630000002, 36.491995450000005]],
    [ "Noble Power",[-101.39165557506982, 36.47559245552642]],
    [ "360447664",[-102.65836475, 32.7107611]],
    [ "360447666",[-102.75286184999999, 32.697340999999994]],
    [ "360800608",[-102.9319769924556, 32.88634107461247]],
    [ "360800609",[-102.9326289252646, 32.87149539853406]],
    [ "360804535",[-102.81768510332361, 32.96404842824583]],
    [ "360807522",[-102.7948402, 32.6822027]],
    [ "360807523",[-102.7295831311006, 32.60557474894154]],
    [ "Lufkin South Substation",[-94.72504579274538, 31.291760730499053]],
    [ "Kyle Substation",[-97.8842705770753, 29.94987542403185]],
    [ "Pflugerville Substation",[-97.59985830997506, 30.454050783190358]],
    [ "Bartlett Substation",[-97.42836549495263, 30.801035405942127]],
    [ "Terminal Substation",[-95.00757609151454, 29.602155975320688]],
    [ "Alpine Substation",[-103.66325351559796, 30.355622532615268]],
    [ "370422296",[-93.99495325428317, 30.92218956463473]],
    [ "Morris Substation",[-97.40559020147329, 27.784393262309685]],
    [ "Arcadia Substation",[-97.42168794602442, 27.745732747761508]],
    [ "Killeen Elms Road Substation",[-97.71944229999998, 31.076395249999997]],
    [ "372109966",[-106.30700031544156, 31.7501965671726]],
    [ "372791482",[-94.89640307386539, 32.22629632806136]],
    [ "374062083",[-97.14025286520274, 32.5213812965132]],
    [ "374062084",[-97.0595671, 32.5029601]],
    [ "374062085",[-96.94154977586756, 32.50851424285358]],
    [ "Brazos Electric Coop",[-96.90642765000001, 32.4806963]],
    [ "374062088",[-97.21133246871915, 33.22020570896805]],
    [ "374065818",[-97.10475498126826, 33.19682667306101]],
    [ "374356574",[-97.71696870637419, 33.064260374537305]],
    [ "Newark Substation",[-97.52290789999999, 33.03992045]],
    [ "Reno Substation",[-97.63275285000002, 32.949701499999996]],
    [ "374356579",[-97.82940472177054, 33.19844871810823]],
    [ "Aurora Substation",[-97.5482681889052, 33.051214699815354]],
    [ "374356582",[-97.5885389752866, 33.21447035418542]],
    [ "374356583",[-97.73315572171562, 33.21730457001272]],
    [ "374695158",[-97.58724945407377, 33.21419838764196]],
    [ "374695160",[-97.4340898049745, 33.66224733363538]],
    [ "374695161",[-97.33253255341748, 33.63996433292462]],
    [ "Shadow Glen Substation",[-97.53549219566054, 30.357077183139328]],
    [ "374959969",[-96.66417852502065, 33.137206506724716]],
    [ "375256647",[-97.79805065, 33.27467035000001]],
    [ "375256648",[-97.88651780000001, 33.3714187]],
    [ "375256649",[-97.79417098157806, 33.2647569956002]],
    [ "Chavaneaux Road Substation",[-98.51036754127307, 29.335018933148707]],
    [ "384271792",[-101.94555055, 34.981806649999996]],
    [ "Brazosport Substation",[-95.43263940132577, 29.009653452060668]],
    [ "McGregor Phillips Substation",[-97.39469205551752, 31.417520065237795]],
    [ "Sam Switching Station",[-97.01807522611493, 31.89746496266379]],
    [ "Barry Substation",[-96.66586335380865, 32.14909942370793]],
    [ "386809437",[-96.59693992868202, 32.4090761592274]],
    [ "386811002",[-96.57935582462403, 32.39940124454708]],
    [ "386811004",[-96.53959826339735, 32.37465619483951]],
    [ "386811654",[-96.47744136520944, 32.336406811945984]],
    [ "386813038",[-96.736807961805, 32.456052373691165]],
    [ "386836591",[-96.24132531973306, 32.40410165276498]],
    [ "386836603",[-96.33269834195079, 32.6693373385374]],
    [ "386836604",[-96.33159470726383, 32.670301805033894]],
    [ "386838369",[-96.39799631291119, 32.66730270593675]],
    [ "386839420",[-96.45234081641995, 32.64248581469446]],
    [ "386839422",[-96.46636027828126, 32.648000026315174]],
    [ "386871485",[-96.15987017260771, 32.81659396912741]],
    [ "386887068",[-96.0856024997418, 32.90435455093381]],
    [ "Round Rock Southeast Substation",[-97.64504505000002, 30.485315250000003]],
    [ "Anna Street Substation",[-99.52078045000002, 27.5241906]],
    [ "Fort Worth Pipe Substation",[-95.4118833928059, 30.31875387084619]],
    [ "Flat Rock Substation",[-97.59769942440369, 32.87051151750771]],
    [ "Marfa Alamito Creek Substation",[-104.00884579999997, 30.316293049999995]],
    [ "Marfa Substation",[-104.01679938782273, 30.312318064165787]],
    [ "Coryell County Substation",[-97.74167455000001, 31.461944499999998]],
    [ "Sycamore Substation",[-97.71997759999998, 31.475301849999997]],
    [ "South Substation",[-96.31105719999998, 30.5915943]],
    [ "South Switching Station",[-96.31103328861181, 30.593724276100197]],
    [ "South Padre Island Substation",[-97.17457071451449, 26.138336681595792]],
    [ "Shiner Substation",[-97.19220225647139, 29.444407215875202]],
    [ "Webberville Solar Substation",[-97.50233725439008, 30.24315553908013]],
    [ "Shiner Substation",[-97.15465358712648, 29.432652244232415]],
    [ "Pierce Substation",[-101.8350041006376, 35.20184260753406]],
    [ "North Andrews Switching Station",[-102.56083536236632, 32.35757430161682]],
    [ "Lufkin East Substation",[-94.68892572995973, 31.332979098918543]],
    [ "Lufkin Pershing Tap",[-94.74565391974626, 31.29197390313602]],
    [ "Nacogdoches Stephen F. Austin Substation",[-94.64303489337576, 31.622357824983034]],
    [ "Post Oak Substation",[-96.30638638351623, 30.622307251293062]],
    [ "Texas A&M University Substation",[-96.35112261658182, 30.61656097133111]],
    [ "Onion Creek Substation",[-97.61710931128786, 30.210435390898578]],
    [ "Sand Hill Switchyard",[-97.6140846441627, 30.209664284693208]],
    [ "Sam Rayburn Substation",[-96.33840062535242, 30.681870614980063]],
    [ "Nall Lane Substation",[-96.34762018718015, 30.671307676701502]],
    [ "Big Spring Switching Station",[-101.43712981289707, 32.26942439959044]],
    [ "Shady Lane Substation",[-96.33562323675983, 30.63047315193047]],
    [ "Barker Substation",[-95.64324004099552, 29.711696641685027]],
    [ "Duke Substation",[-98.17391109199217, 26.34134107241687]],
    [ "Citgo North Oak Park Substation",[-97.42703787821029, 27.814274393483785]],
    [ "Friess Ranch Substation",[-100.51391705, 30.400373300000002]],
    [ "American Petrofina Substation",[-100.05495785, 30.17140855]],
    [ "Montell Substation",[-100.00176460196042, 29.42795686790613]],
    [ "Crockett Heights Substation",[-101.1175321, 30.702148749999996]],
    [ "Ozona Substation",[-101.19977232655319, 30.719189192806844]],
    [ "Sonora Atlantic Substation",[-100.81971988373719, 30.630316897430472]],
    [ "Falls City Substation",[-97.98582339438767, 29.0105495669341]],
    [ "Nixon Substation",[-97.76653234745187, 29.28926334212739]],
    [ "Comal Street Substation",[-98.50859331256062, 29.42157988559643]],
    [ "Deer Creek Substation",[-97.78368835000339, 29.55528531070097]],
    [ "Helena Substation",[-97.7999986972749, 28.93792437605258]],
    [ "Magnolia Substation",[-97.8667935, 29.015058349999997]],
    [ "Malone Substation",[-97.72129770000001, 29.626555550000003]],
    [ "Milton Substation",[-97.78764697497824, 29.05644660623254]],
    [ "Nash Creek Substation",[-97.75734864075577, 29.544023878473705]],
    [ "Nixon Substation",[-97.74394475000001, 29.2797868]],
    [ "Nordheim Substation",[-97.59965630000002, 28.923560500000008]],
    [ "Nordheim West Substation",[-97.62425554999999, 28.911500599999993]],
    [ "Cost Substation",[-97.5277942043747, 29.438517520463147]],
    [ "Lakewood Substation",[-97.50079993475305, 29.48336942443265]],
    [ "Smiley Substation",[-97.6303070583678, 29.317008926135763]],
    [ "Dale Substation",[-97.58523825, 29.93963225]],
    [ "Gonzales Substation",[-97.4552039932668, 29.519424930611095]],
    [ "Hochheim Substation",[-97.29923638112508, 29.27655999519211]],
    [ "Magnolia Mercer Substation",[-97.70565977122091, 29.759423085655936]],
    [ "Ottine Substation",[-97.47854601570873, 29.613719105690045]],
    [ "Red Rock Substation",[-97.45212653729425, 29.961988414267694]],
    [ "Laredo Plant Statcom Substation",[-99.50838196059699, 27.568733518657467]],
    [ "Laredo VFT South Yard",[-99.50947765000001, 27.570117800000006]],
    [ "Laredo VFT North Yard",[-99.5090266674867, 27.572559477248376]],
    [ "Razorback Substation",[-99.21792189999998, 29.321621349999994]],
    [ "Seco Substation",[-99.2383605, 29.436493799999997]],
    [ "Laredo Heights Substation",[-99.49014659760881, 27.523680211292074]],
    [ "Blanco Substation",[-98.4121316106268, 30.082612719458773]],
    [ "Blanco Tap",[-98.36006277513255, 30.284651557599894]],
    [ "Johnson City Substation",[-98.39841868146632, 30.30058498839201]],
    [ "Pueblo Substation",[-100.46375078281325, 28.678984498807377]],
    [ "Wolf Creek Substation",[-95.14560890000001, 30.6003034]],
    [ "McGregor Hercules Substation",[-97.47113942206516, 31.39908954469051]],
    [ "435318649",[-106.31301103645144, 31.776701927409203]],
    [ "Mesa Substation",[-106.51241535256715, 31.80643400052786]],
    [ "Rio Pecos Substation",[-102.36520288491884, 31.084785300576563]],
    [ "438867340",[-106.5759568571315, 31.83658376121936]],
    [ "Pee Dee Substation",[-95.88573889706717, 30.93008773434723]],
    [ "Bishop Substation",[-95.80281789152188, 30.904501517213607]],
    [ "Staley Substation",[-95.25859167733098, 30.847396192025947]],
    [ "Poco Substation",[-94.9087025652834, 30.73048871876093]],
    [ "Bold Springs Substation",[-94.95180152487147, 30.761599042743075]],
    [ "Israel Road Substation",[-94.90634855625862, 30.73006417039507]],
    [ "Indian Substation",[-94.71349183577767, 30.718910127581346]],
    [ "Menard Substation",[-94.74773840267231, 30.583090559131787]],
    [ "Corrigan Bulk Substation",[-94.81680082815754, 30.994177013960446]],
    [ "440283814",[-96.42866990814183, 33.70808706109967]],
    [ "440284131",[-96.55850399256067, 33.78637664921036]],
    [ "440285219",[-96.54507512185229, 33.74163470963277]],
    [ "440710227",[-96.41120232017253, 33.601857581035425]],
    [ "440720673",[-96.45884553222976, 33.60161294471021]],
    [ "Chalker Substation",[-101.91417192369717, 33.52653641228208]],
    [ "Batton Substation",[-101.91338858331977, 33.52729665382442]],
    [ "442786429",[-96.8994735510525, 33.628741018441104]],
    [ "442929247",[-96.8636590116467, 33.62703210733093]],
    [ "442937255",[-96.69999253239953, 33.61561880069014]],
    [ "444226270",[-96.605566766599, 33.57530779450535]],
    [ "444227637",[-96.00112903353933, 33.58872704250603]],
    [ "North Vernon Substation",[-99.331213380133, 34.20283005732203]],
    [ "White City Substation",[-99.32946774999999, 34.283077299999995]],
    [ "Electra Substation",[-98.92217757700806, 34.01431505726565]],
    [ "Vernon Substation",[-98.95697600000001, 34.022103099999995]],
    [ "Shamrock Substation",[-100.25427549951291, 35.215817737543766]],
    [ "Hansford County Switching Station",[-101.38776635006056, 36.40009950006087]],
    [ "Gruver Substation",[-101.41050190391061, 36.264104942094654]],
    [ "Noble Power",[-101.35115453468936, 36.45542482501107]],
    [ "Lasley Switching Station",[-101.82527555623416, 36.35412202506416]],
    [ "Hilmar Cheese Substation",[-102.5017641087623, 36.08406577511272]],
    [ "Xcel Energy",[-102.53588775, 36.0682731]],
    [ "Valero Sunray Wind Farm Substation",[-101.89250592898195, 35.95297922730787]],
    [ "Etter Rural Substation",[-101.97568575000001, 36.0253479]],
    [ "Exum Substation",[-102.13711822492056, 36.069921384583296]],
    [ "Jardin Substation",[-99.23790847560603, 28.51987996493352]],
    [ "Reveille Substation",[-99.2581053953243, 28.414759034315008]],
    [ "Gateway Substation",[-99.4442159668719, 27.513442944522993]],
    [ "Santo Nino Substation",[-99.46149403021299, 27.475588416811544]],
    [ "Zacate Creek Substation",[-99.50033420000001, 27.5405375]],
    [ "Washington Street Substation",[-99.49860845, 27.509102750000004]],
    [ "Del Mar Substation",[-99.46631162808751, 27.576150269016637]],
    [ "Laredo Plant Substation",[-99.50686358054504, 27.56745534540267]],
    [ "Mines Road Substation",[-99.53022210198102, 27.636025505804344]],
    [ "Botines Substation",[-99.48051067237311, 27.685429630915497]],
    [ "North Laredo Switching Station",[-99.47756469346261, 27.69213779212232]],
    [ "Milo Substation",[-99.49992825000001, 27.61103135]],
    [ "Bordas Substation",[-98.99811751855533, 27.321192226635834]],
    [ "Rio Brava Substation",[-99.41952355352515, 27.313841873195333]],
    [ "University Substation",[-99.42984466443959, 27.58818141564258]],
    [ "Sierra Vista Substation",[-99.44829390988787, 27.437983774644625]],
    [ "Wormser Road Substation",[-99.41747789591734, 27.45460256545176]],
    [ "Bruni Substation",[-98.83002599447872, 27.43554293828649]],
    [ "Bruni Substation",[-98.8359135302199, 27.43957245892384]],
    [ "Whitetail Substation",[-99.00910235, 27.48660905]],
    [ "Cedro Hill Substation",[-98.92009779142025, 27.563807126360413]],
    [ "Molina Substation",[-99.41441950000002, 27.327793200000006]],
    [ "Cenizo Substation",[-99.41155741329453, 27.327584724734162]],
    [ "Bevo Substation",[-99.84010555358275, 28.49651196711704]],
    [ "Big Wells Substation",[-99.56925034999999, 28.544282199999994]],
    [ "Crystal City Substation",[-99.81214194255605, 28.690017438749983]],
    [ "Dimmit Substation",[-99.87366529695471, 28.509189182779785]],
    [ "Carrizo Springs Substation",[-99.88454358095733, 28.493284989732995]],
    [ "La Pyor Substation",[-99.83360739326733, 28.939633799331272]],
    [ "West Crystal City Substation",[-99.95558729999999, 28.66503975]],
    [ "Crystal City Substation",[-99.75446335000001, 28.69863955]],
    [ "Carrizo Springs Substation",[-99.83484668072512, 28.51635101054137]],
    [ "Turtle Creek Substation",[-99.82700004999998, 28.732833799999998]],
    [ "Freer Substation",[-98.86831815617221, 27.940516231781093]],
    [ "Holland Substation",[-99.16583097529103, 28.260366257516925]],
    [ "Hindes Substation",[-98.90265644044871, 28.646329375075865]],
    [ "Cross Substation",[-98.54002263603036, 28.69522479210009]],
    [ "Crutchfield Substation",[-95.93417667935407, 30.584507387290213]],
    [ "Iola Substation",[-96.04634227033463, 30.73149722046003]],
    [ "Carlos Switching Station",[-96.16184611752345, 30.616077297528317]],
    [ "Big Foot Substation",[-98.86280199410844, 28.915554869330045]],
    [ "Charlotte Substation",[-98.69088673399459, 28.86905170128487]],
    [ "Tordillo Substation",[-98.18306493682627, 28.835196790806712]],
    [ "South Texas Electric Cooperative",[-98.48190615408308, 28.68782978082834]],
    [ "Fashing Substation",[-98.184450233205, 28.837897301203245]],
    [ "San Miguel Switchyard",[-98.4698178151437, 28.70028107521865]],
    [ "LKHoward Substation",[-98.57683395000001, 26.410840399999998]],
    [ "JackieHoward Substation",[-98.69841677368368, 26.56494562881557]],
    [ "MigHoward Substation",[-98.59449994464275, 26.487494631084584]],
    [ "Del Sel Substation",[-98.73702094999999, 26.45769445]],
    [ "Roma Switching Station",[-99.0192064204049, 26.522645856297594]],
    [ "Liston Substation",[-98.57639040000001, 26.411361200000005]],
    [ "Garza Substation",[-98.7410917591884, 26.452289975748794]],
    [ "Randado Substation",[-98.92856835, 27.084086799999998]],
    [ "Randado Substation",[-98.9285243, 27.08351605]],
    [ "Sendero Substation",[-98.95248645000002, 27.15954805]],
    [ "Falcon Switching Station",[-99.09551710793947, 26.543886642774726]],
    [ "San Ygnacio Substation",[-99.38446464915559, 27.068714082913086]],
    [ "Lopeno Substation",[-99.10947675, 26.687615599999997]],
    [ "Tubular Substation",[-96.08953120111843, 30.368302459376917]],
    [ "Panther Creek Wind 2 Substation",[-101.30174810000001, 31.95667215]],
    [ "Grelton Substation",[-101.88148520000001, 32.16639650000001]],
    [ "Long Draw Substation",[-101.6329669886736, 32.72112016898287]],
    [ "Bearkat Substation",[-101.53149895, 31.766046000000003]],
    [ "Sand Bluff Substation",[-101.27317559536435, 32.00357600929903]],
    [ "Clear Crossing Substation",[-99.60549439999998, 33.001373799999996]],
    [ "West Shackleford Substation",[-99.5378380049022, 32.635813836766474]],
    [ "Willow Valley Substation",[-101.39611730000001, 32.6506667]],
    [ "Faraday Substation",[-101.39575983053024, 32.648072852092064]],
    [ "Cirrus Substation",[-101.68371185000001, 33.03293779999999]],
    [ "Mesquite Creek Wind Substation",[-101.73975326865511, 32.70626628027712]],
    [ "Stephens Ranch Wind Project I Substation",[-101.65792405, 32.89803385]],
    [ "Alibates Substation",[-101.35964490514681, 35.25495877852136]],
    [ "Spinning Spur 1 Substation",[-102.43468474255943, 35.283196822313066]],
    [ "Spinning Spur 2 Substation",[-102.58255560060245, 35.292166212743446]],
    [ "AJ Swope Substation",[-102.05018605000001, 35.31350915]],
    [ "Hereford Wind Substation",[-102.29809545912651, 34.773053721543135]],
    [ "Windmill Substation",[-102.3045497, 34.7733778]],
    [ "Ogallala Substation",[-102.03868651537138, 34.51973652528061]],
    [ "Longhorn Wind Substation",[-101.24078964414679, 34.31678931119785]],
    [ "Tule Canyon Substation",[-101.35958697486966, 34.432227546462705]],
    [ "Cottonwood Substation",[-100.87653745, 33.7782993]],
    [ "Dermott Substation",[-101.0088003, 32.86022104999999]],
    [ "Cross Substation",[-100.89386901696913, 34.34399237957278]],
    [ "Tesla Substation",[-100.08684144999998, 34.373393650000004]],
    [ "Miami Wind Substation",[-100.54135655, 35.62022695]],
    [ "Gray Substation",[-100.81997140247726, 35.40760453028033]],
    [ "Pattern Panhandle Wind 1 Substation",[-101.2563436029602, 35.41477990338206]],
    [ "Railhead Substation",[-101.1538371, 35.4022323]],
    [ "Pattern Panhandle Wind 2 Substation",[-101.37284414062664, 35.44302829862465]],
    [ "Route 66 Wind Substation",[-101.43496464999998, 35.20591099999999]],
    [ "Grandview Wind Farm 1 Substation",[-101.18578555, 35.25412215]],
    [ "Mulberry Creek Substation",[-99.82239352593538, 32.524736392823364]],
    [ "Kirchhef Substation",[-100.21677549999997, 32.92533135]],
    [ "Gauss Substation",[-99.6615509, 33.4789378]],
    [ "Sand Road Substation",[-99.3059497119311, 34.141989624152075]],
    [ "American Electric Power",[-99.28643794281571, 34.15880017512741]],
    [ "Vernon City Plant Substation",[-99.29630951732504, 34.162275131815306]],
    [ "Blue Summit Wind Substation",[-99.36927256489724, 34.29412927860932]],
    [ "Jim Treece Substation",[-99.43416366994225, 34.23416299675788]],
    [ "Thompson Substation",[-101.92677479246031, 33.542230425007396]],
    [ "West Krum Substation",[-97.31061953098933, 33.3246131663195]],
    [ "Aubrey Substation",[-96.99991190935535, 33.305062553779344]],
    [ "454696070",[-97.00057996716706, 33.02716567441618]],
    [ "454696143",[-96.75712819819279, 33.289383735372596]],
    [ "454696147",[-96.75704236006051, 33.29028637495708]],
    [ "454696151",[-96.7105632, 33.40023885000001]],
    [ "454696160",[-96.72468290288055, 33.36460870330694]],
    [ "454696189",[-97.036220497872, 33.54691449990183]],
    [ "454696193",[-97.39850706628665, 33.4285183170209]],
    [ "454696219",[-97.30781323816883, 32.87665912512209]],
    [ "Hicks Substation",[-97.38395375544285, 32.90413749863517]],
    [ "Peden Road Substation",[-97.45447902509763, 32.94101494065189]],
    [ "Center Point Substation",[-97.54844929120003, 32.93511696290447]],
    [ "Briar Substation",[-97.54968511912648, 32.979243156270606]],
    [ "454866381",[-97.45521772387, 33.076216262548655]],
    [ "Rhome Substation",[-97.47430233579276, 33.07654289857738]],
    [ "Boyd Substation",[-97.54912083681185, 33.02756869932322]],
    [ "East Bell County Substation",[-97.2596030204148, 31.00456531270615]],
    [ "Navarro Switching Station",[-96.51800387994857, 31.963975230820612]],
    [ "Koppril Substation",[-97.86717376156186, 31.952630570434334]],
    [ "Romney Substation",[-98.67387365563062, 32.2448613856233]],
    [ "455133210",[-95.63047239999999, 29.480622549999996]],
    [ "455340176",[-97.39291072612976, 32.39236078208755]],
    [ "Parker Substation",[-97.67593734001987, 32.80839731774892]],
    [ "Sutton Substation",[-96.5277575417118, 30.81529145310633]],
    [ "Big Spring Gulf Substation",[-101.40719085, 32.224542400000004]],
    [ "Colorado River Municipal Water District #4 Substation",[-100.76268939994338, 31.606991154177813]],
    [ "Colorado River Municipal Water District #2 Substation",[-100.17736594999998, 31.5508658]],
    [ "Colorado River Municipal Water District #8 Substation",[-101.95018955670851, 31.864396920567117]],
    [ "Colorado River Municipal Water District #3 Substation",[-100.50015330000001, 31.549751850000003]],
    [ "San Angelo Grape Creek Substation",[-100.57280019434478, 31.574336524518664]],
    [ "Big Hill Substation",[-100.54144645000001, 31.046259550000006]],
    [ "Rollans Substation",[-100.91127521636957, 31.91085356692499]],
    [ "Colorado River Municipal Water District #5 Substation",[-101.17869126979818, 31.70278461275635]],
    [ "Amoco Substation",[-100.86534484066054, 31.649548424437654]],
    [ "455759181",[-95.74757680645298, 32.86832391992926]],
    [ "Big Spring Colorado River Municipal Water District Substation",[-101.46844111384146, 32.29060517130777]],
    [ "Elbow Substation",[-101.47835764999999, 32.168852]],
    [ "Saint Lawrence Substation",[-101.4943253, 31.712167200000007]],
    [ "E.ON",[-101.27888702528215, 32.02510429056088]],
    [ "Cosden Substation",[-101.42167862930715, 32.27101838633781]],
    [ "Salem Substation",[-101.34985815861289, 32.32065277113275]],
    [ "Fairview Substation",[-101.48663625, 32.363297800000005]],
    [ "Luther Substation",[-101.5157994, 32.459730400000005]],
    [ "Colorado City Substation",[-100.94376711966281, 32.41846463945781]],
    [ "Barber Lake Substation",[-100.91546299999999, 32.39983555]],
    [ "Stiles Substation",[-101.5861363529234, 31.434574532523563]],
    [ "East Stiles Substation",[-101.47972119309688, 31.559835824793943]],
    [ "Salt Flat Road Substation",[-101.94081486534684, 31.71886287004003]],
    [ "Skywest Substation",[-101.93009626236532, 31.71846102045081]],
    [ "Driver Substation",[-101.7994443410494, 31.73330914366886]],
    [ "Pembrook Substation",[-101.82130205, 31.56716955]],
    [ "South Midland Substation",[-102.01446246427724, 31.954430015627032]],
    [ "Midkiff Substation",[-101.75871554999999, 31.650533299999996]],
    [ "East Midland Substation",[-101.99856818289713, 32.044256218439656]],
    [ "Koch Substation",[-101.6566572, 32.4861067]],
    [ "Sale Ranch Substation",[-101.89967157075236, 32.20766653521456]],
    [ "Buffalo Substation",[-101.86787112920689, 32.1074457084064]],
    [ "Triangle Substation",[-101.81886402062429, 32.12817246345729]],
    [ "Greenwood Substation",[-101.9118510294241, 32.02477240894756]],
    [ "Grady Substation",[-101.93237358830625, 32.3073644163779]],
    [ "Brown Substation",[-101.7454975, 32.454711350000004]],
    [ "Big 3 Substation",[-102.3451572297848, 31.819347715021767]],
    [ "Odessa Southwest Substation",[-102.39134164999997, 31.7988654]],
    [ "Amoco South Foster Substation",[-102.43881280070451, 31.838936671245587]],
    [ "Westover Substation",[-102.44350704239235, 31.835991683250754]],
    [ "Olefin Substation",[-102.32708965, 31.8273141]],
    [ "Rexene Nylon Substation",[-102.33479137200767, 31.819578142362346]],
    [ "Nylon Switching Station",[-102.3350181298752, 31.826002373602194]],
    [ "Liquid Air Substation",[-102.33518885996288, 31.83963697450721]],
    [ "Colorado River Municipal Water District #8 Substation",[-102.24681481125333, 31.948709482454262]],
    [ "Midland Airport",[-102.22965194711834, 31.935582281518666]],
    [ "Midessa Substation",[-102.17531149999999, 31.967051500000004]],
    [ "Midland Downtown Substation",[-102.07705775909133, 31.99295362461267]],
    [ "Midland County Northwest Substation",[-102.24625654999998, 32.03737915000001]],
    [ "Mockingbird Substation",[-102.17582992049093, 32.0385283788852]],
    [ "Windwood Substation",[-102.07648795000001, 32.019592300000006]],
    [ "Goddard Substation",[-102.12552025720704, 32.036028455347044]],
    [ "Tall City Substation",[-102.0900886, 32.074988450000006]],
    [ "Amoco Midland Farms Substation",[-102.42488011216912, 32.14197742313613]],
    [ "Unocal Parker-Bakke Substation",[-102.59073694132432, 32.26154772424693]],
    [ "XTO University Substation",[-102.57005342065466, 32.19239434558919]],
    [ "Emma Substation",[-102.59258257973374, 32.153796661252876]],
    [ "Oncor",[-102.57726472926635, 31.988825108426795]],
    [ "OXY North Cowden Substation",[-102.51677327456385, 32.006051119967964]],
    [ "North Cowden Substation",[-102.49526245000001, 32.0068838]],
    [ "Ector North Substation",[-102.54917007059858, 31.994750845011463]],
    [ "Ector County Energy Center Substation",[-102.58603353602287, 32.069955267236466]],
    [ "Goldsmith Substation",[-102.63651310000002, 31.975004900000005]],
    [ "Goldsmith Gulf Substation",[-102.63603255, 31.9737185]],
    [ "Goldsmith South Substation",[-102.6148614598304, 31.929952570690094]],
    [ "Odessa Basin Substation",[-102.56685600071572, 31.89756449026907]],
    [ "Goldsmith Junction",[-102.55817992936652, 31.868993655780713]],
    [ "Holt Switching Station",[-102.58412662977078, 32.063804103339145]],
    [ "Texaco Mabee Substation",[-102.2289797208319, 32.20949294318378]],
    [ "Fasken C Ranch Substation",[-102.26886507932855, 32.17694045469445]],
    [ "Oncor",[-102.22604365392648, 32.19750690891853]],
    [ "Oncor",[-102.19124415000002, 32.179337600000004]],
    [ "Oxy South Curtis Ranch Substation",[-102.15961159999999, 32.1298452]],
    [ "Polecat Creek Substation",[-101.4822138048998, 31.994815834914952]],
    [ "Spraberry Substation",[-101.97826937610192, 31.843162461948513]],
    [ "Spraberry Substation",[-101.97795922853442, 31.84136835711981]],
    [ "Rebel Substation",[-101.71541575778365, 31.90451440563523]],
    [ "458134469",[-101.28107506840105, 32.086611947228214]],
    [ "Big Spring Air Park Substation",[-101.513797614713, 32.22784216354703]],
    [ "Big Spring West Substation",[-101.50084753733732, 32.24335276637945]],
    [ "Stanton Wind Energy Substation",[-101.81898294732136, 32.21120595427957]],
    [ "Stanton Substation",[-101.79657534999998, 32.138677200000004]],
    [ "Martin Cap Substation",[-101.79350405, 32.14018225]],
    [ "Stanton East Substation",[-101.75791000145519, 32.15872613808738]],
    [ "Getty Vealmoor Substation",[-101.36165545994524, 32.48512427569767]],
    [ "Knott Substation",[-101.58970443583216, 32.41463468658264]],
    [ "Ackerly Chevron Substation",[-101.65173375, 32.480405749999996]],
    [ "Buzzard Draw Switching Station",[-101.67716213980067, 32.49818543568908]],
    [ "China Grove Switching Station",[-100.87360723443598, 32.523387377054775]],
    [ "Ackerly Substation",[-101.7881449706503, 32.59480634661869]],
    [ "Sparenburg Substation",[-101.81642610000002, 32.6187575]],
    [ "South Lamesa Substation",[-101.90271111445007, 32.690162120400444]],
    [ "Ackerly Substation",[-101.71024725000001, 32.52919395]],
    [ "Gail Substation",[-101.45880195000001, 32.6583351]],
    [ "Key Substation",[-101.78933549999999, 32.69250234999999]],
    [ "Andrews Substation",[-102.64568726993785, 32.29319503879427]],
    [ "Dollarhide Substation",[-102.87125478459727, 32.08192151538302]],
    [ "Notrees Switching Station",[-102.8762998991109, 32.07685068342346]],
    [ "Andrews Substation",[-103.06190162360659, 32.426242572940836]],
    [ "Phillips Andrews Substation",[-102.88661124999999, 32.40919665]],
    [ "Exxon Fullerton Substation",[-102.7974433295263, 32.42298955507926]],
    [ "459062025",[-102.6625086, 32.45154925]],
    [ "Frankel City Substation",[-102.78193582087627, 32.381448500252745]],
    [ "Whalen Lake Substation",[-102.73645890636223, 32.35154922041521]],
    [ "Arena Substation",[-102.67753746965407, 32.22366070664584]],
    [ "Andrews County South Switching Station",[-102.77732984999999, 32.164930399999996]],
    [ "Andrews County South Switching Station",[-102.7768715197471, 32.16350269333862]],
    [ "459062052",[-102.53101940073795, 32.437037755374895]],
    [ "Exxon Means Substation",[-102.54706211615083, 32.43279826735374]],
    [ "Bakke Substation",[-102.5383428356165, 32.291703579581636]],
    [ "Means Substation",[-102.55564679999999, 32.45725185]],
    [ "Andrews Switching Station",[-102.64347014545409, 32.29392722994342]],
    [ "Oxy Welch Substation",[-102.13773045000002, 32.896391200000004]],
    [ "Welch Substation",[-102.10131734461456, 32.902305529782254]],
    [ "Golden Spread Electric Cooperative",[-102.0524972, 32.690798900000004]],
    [ "West Lamesa Substation",[-102.06022355, 32.71566365]],
    [ "Patricia Substation",[-102.00807291762261, 32.5498912702304]],
    [ "Midland Farms Substation",[-102.42579570000001, 32.145804000000005]],
    [ "San Andres Substation",[-102.49754577947779, 32.19175111022409]],
    [ "Wink Substation",[-103.1446454808854, 31.762982142857783]],
    [ "Winkler County #6 Substation",[-103.07248397230367, 31.665726185952813]],
    [ "AA Pipeline Substation",[-103.17632295, 31.764229]],
    [ "Wink Substation",[-103.16284183173963, 31.763284014464123]],
    [ "Kermit #2 Substation",[-103.09602191820788, 31.852057635801366]],
    [ "Keystone Substation",[-103.08097786440354, 31.87918142226186]],
    [ "Kermit #1 Substation",[-103.08157775, 31.848372899999998]],
    [ "Wink Gulf Substation",[-103.13479014999999, 31.78823295]],
    [ "Wink Basin Substation",[-103.13232226337182, 31.790382676575256]],
    [ "Keystone Substation",[-103.1450364195342, 31.907258739594713]],
    [ "Midway Substation",[-102.72702027307797, 31.841508795965638]],
    [ "California Substation",[-103.13063009989531, 31.77471919677804]],
    [ "Vest Substation",[-103.03529254019455, 31.769744424550197]],
    [ "Sandridge Energy Substation",[-102.64813915, 31.857932749999996]],
    [ "Amoco Three-Bar Substation",[-102.80578234999999, 32.14505959999999]],
    [ "Unocal Dollarhide Substation",[-103.0422361, 32.14632735]],
    [ "Cheyenne Substation",[-103.04452535928367, 31.934694320210774]],
    [ "Helios Substation",[-99.69974625000002, 29.22606495]],
    [ "Quaker Substation",[-101.90563957121697, 33.49833955257081]],
    [ "Monahans Substation",[-102.89956564443884, 31.59421938011443]],
    [ "Oncor",[-103.02662138203998, 31.583426121492167]],
    [ "Wickett Substation",[-103.02376600896098, 31.575470232165316]],
    [ "459878011",[-102.99117546039042, 31.581094983517744]],
    [ "Northward Substation",[-103.07215239999998, 31.637342999999994]],
    [ "Goldsmith Amoco Substation",[-102.65312839038342, 31.990651271388487]],
    [ "Edwards Substation",[-102.50446174999999, 31.644141799999996]],
    [ "Southwestern Portland Cement Substation",[-102.54477231472845, 31.746951171180307]],
    [ "Judkins Substation",[-102.59715059999999, 31.71644835]],
    [ "Sandhills Substation",[-102.67204873909104, 31.529330326148834]],
    [ "Wickett Substation",[-103.02120342989869, 31.598044305897833]],
    [ "Permian Basin Station",[-102.95992251680521, 31.58390954272205]],
    [ "460073770",[-103.47617025, 31.70421665]],
    [ "Mason Substation",[-103.81830017927254, 31.80243154487711]],
    [ "El Mar Substation",[-103.61986990000001, 31.78809345]],
    [ "Anderson Ranch Point of Interconnection",[-103.48231354999999, 31.779937550000003]],
    [ "Loving Substation",[-103.40556265904527, 31.7759762996425]],
    [ "Spade Ranch Substation",[-100.84400828077574, 31.97289875575143]],
    [ "460593044",[-102.54014245, 36.07950565]],
    [ "Channing Substation",[-102.3269979005723, 35.68907558883419]],
    [ "Osage Switching Station",[-101.81228023291253, 35.14880331622589]],
    [ "Owens-Corning Substation",[-101.85930157024315, 35.11424745433084]],
    [ "Manhattan Substation",[-101.80462800000001, 35.18495325]],
    [ "Hillside Substation",[-101.9479586, 35.14637945]],
    [ "Vega Substation",[-102.41649819999999, 35.25347185]],
    [ "North Amarillo Substation",[-101.88372855, 35.250522]],
    [ "Hastings Substation",[-101.84994286725042, 35.25030011603053]],
    [ "Rolling Hills Interchange",[-101.84098485000001, 35.295661650000014]],
    [ "Northwest Substation",[-101.93721749999999, 35.2508256]],
    [ "Cliffside Substation",[-101.99086316698323, 35.35254313111195]],
    [ "Spinning Spur 3 Substation",[-102.71029645990681, 35.29717071655107]],
    [ "460785057",[-96.41938933659844, 33.62954528612173]],
    [ "460785059",[-96.23574645454826, 33.58878085074689]],
    [ "460785061",[-96.21500163590858, 33.58487033508886]],
    [ "460785062",[-96.19932546465597, 33.562615266191266]],
    [ "Fain Substation",[-101.86643568330389, 35.54485344932662]],
    [ "461044981",[-101.65217448149265, 35.43028159591867]],
    [ "Canadian River Municipal Water Authority #3 Substation",[-101.60256182787805, 35.56983849471469]],
    [ "Exell Substation",[-101.97343202504949, 35.631082007934225]],
    [ "Steep Hollow Substation",[-96.26946410542988, 30.68767413768225]],
    [ "Spring Valley Substation",[-97.27863756595862, 31.38042492454327]],
    [ "Cotton Belt Substation",[-97.3175104, 31.427908950000003]],
    [ "Baylor Substation",[-97.10429589498659, 31.550806875835384]],
    [ "Troy Substation",[-97.30752948129353, 31.209694032739137]],
    [ "Temple Northwest Substation",[-97.40232869269896, 31.109405970149623]],
    [ "Temple North Substation",[-97.33616772606663, 31.134184910751724]],
    [ "Moffat Substation",[-97.43754719416987, 31.203958276348644]],
    [ "Moody Substation",[-97.37578811481197, 31.275282372149444]],
    [ "Bosque Substation",[-97.37505254094097, 31.685868817299216]],
    [ "China Spring Substation",[-97.3033750333857, 31.67166120759225]],
    [ "China Spring Substation",[-97.30428299999998, 31.671118399999997]],
    [ "Perry Substation",[-96.87622807924865, 31.440253914481794]],
    [ "Mexia Substation",[-96.48637182256392, 31.68298910445253]],
    [ "Groesbeck Main Substation",[-96.54108645506895, 31.5212080338784]],
    [ "Groesbeck Substation",[-96.4755130898827, 31.514122524210418]],
    [ "Ben Hur Substation",[-96.68681172268239, 31.50334684064247]],
    [ "Windsor Substation",[-97.30096630624898, 31.507830088391444]],
    [ "Cayote Substation",[-97.39644067825145, 31.814475089254167]],
    [ "Waco M&M Mars 2 Substation",[-97.19582005346, 31.487078719901554]],
    [ "Waco Lasalle Substation",[-97.071795, 31.543129950000004]],
    [ "Waco South Substation",[-97.1323189112641, 31.526002531696623]],
    [ "Waco Colonial Avenue Substation",[-97.16181804222282, 31.547396740358916]],
    [ "Northcrest Substation",[-97.09359715418955, 31.642539767744513]],
    [ "Waco Katy Substation",[-97.10004443293101, 31.584325893763992]],
    [ "Hallsburg Substation",[-97.00568314167919, 31.524276231302135]],
    [ "Navarro Mills Substation",[-96.68689697002671, 31.958810505683566]],
    [ "461754866",[-96.6723176, 31.951248249999992]],
    [ "Prairie Hill Substation",[-96.77494070245278, 31.652090145103447]],
    [ "Emmett Substation",[-96.81160616805488, 32.021298772332095]],
    [ "Purdon Substation",[-96.64196510358073, 31.978018508475376]],
    [ "Pullman Substation",[-101.70620960000001, 35.200231050000006]],
    [ "Southeast Substation",[-101.75084855, 35.17812]],
    [ "Santa Fe Switching Station",[-97.62995478624669, 31.38644130911034]],
    [ "Santa Fe Substation",[-97.58376703019151, 31.437481246337676]],
    [ "Fort Gates Substation",[-97.70854542613492, 31.414614510432628]],
    [ "462426674",[-97.83065908091568, 31.130821907498085]],
    [ "Killeen Clarke Road Substation",[-97.84339398982716, 31.110383970611036]],
    [ "Killeen Clear Creek Substation",[-97.82110281706909, 31.110003251212397]],
    [ "Killeen Fort Hood West Substation",[-97.81278154527652, 31.109134627448693]],
    [ "Killeen South Substation",[-97.76843575000002, 31.0630382]],
    [ "Gatesville Switching Station",[-97.62178815, 31.378531950000003]],
    [ "Leon Junction Substation",[-97.58372145, 31.34181025]],
    [ "Gatesville Army Substation",[-97.6890244, 31.362646550000004]],
    [ "Cedar Valley Substation",[-97.68631742546539, 30.929264016833972]],
    [ "Ding Dong Substation",[-97.7666802006954, 30.987370833014076]],
    [ "Trimmer Substation",[-97.68147687613548, 31.007858145048733]],
    [ "Killeen Fort Hood Substation",[-97.76651467031883, 31.132506990908634]],
    [ "Nolanville Substation",[-97.54490455000001, 31.08710725]],
    [ "Harker Heights Substation",[-97.67180010924619, 31.09774095614651]],
    [ "Killeen Taft Street Substation",[-97.7075974691928, 31.10120285231282]],
    [ "Temple South Substation",[-97.37437213450019, 31.07669068268919]],
    [ "Cameron Substation",[-96.98952850296544, 30.84937052163031]],
    [ "Marak Substation",[-97.04909223561124, 30.8933671433811]],
    [ "Panda Temple 1 Substation",[-97.31508798067658, 31.057066347500644]],
    [ "Panda Temple 2 Substation",[-97.31653179555119, 31.057537904125045]],
    [ "Knob Creek Switching Station",[-97.31271425000001, 31.056312000000005]],
    [ "Silver City Substation",[-96.92757775261546, 30.916906220225822]],
    [ "Slaton Substation",[-101.83814592323797, 33.53477851679917]],
    [ "Manor Substation",[-97.5092854618455, 30.349482718518253]],
    [ "Swiftex Substation",[-97.31264039580941, 30.20264460472942]],
    [ "Elgin Substation",[-97.34049522964517, 30.33465411736749]],
    [ "Hillbig Substation",[-97.40439078920151, 30.005560165453645]],
    [ "Wyldwood Substation",[-97.47365383619983, 30.131025729645888]],
    [ "Stoney Ridge Substation",[-97.63570945888226, 30.15883808397348]],
    [ "Elroy Substation",[-97.62435388734976, 30.14127386254158]],
    [ "Dunlap Substation",[-97.53973235000001, 30.275384100000004]],
    [ "Webberville Solar Substation",[-97.5026136920334, 30.242631749171878]],
    [ "Industrial Substation",[-101.44348324726418, 35.66258616813293]],
    [ "Huber Cogeneration Station",[-101.43182455, 35.667631099999994]],
    [ "Adobe Creek Substation",[-101.01899703012808, 35.76008380270734]],
    [ "463732568",[-100.81548498602262, 35.75167253136696]],
    [ "Kite Substation",[-100.9813072, 35.57712155]],
    [ "463732611",[-101.44971735, 35.82839345]],
    [ "Highland Park Substation",[-101.65436981314848, 35.25242182427633]],
    [ "Gray County Interchange",[-101.04274605000002, 35.57669225]],
    [ "Damron Substation",[-101.18444021547438, 35.590012865473]],
    [ "Roxana Substation",[-101.12403535, 35.57225880000001]],
    [ "Pringle Interchange",[-101.39199289999999, 35.99481934999999]],
    [ "Phillips #1 Substation",[-101.36718454027601, 35.6982498001312]],
    [ "Burnett Substation",[-101.4106690750874, 35.58894163880321]],
    [ "Blackhawk Station",[-101.36079819238942, 35.69510856808647]],
    [ "Phillips #2 Substation",[-101.37527555, 35.695397449999994]],
    [ "Weatherly Substation",[-101.3988197, 35.63512990000001]],
    [ "Canadian River Municipal Water Authority #22 Substation",[-101.44124889999999, 35.62218585000001]],
    [ "Kingsmill Interchange",[-101.04235451473262, 35.495604560398434]],
    [ "Spring Creek Substation",[-101.31651375000001, 35.664395799999994]],
    [ "Rocky Point Substation",[-101.35757057111026, 35.712145307995016]],
    [ "Canadian River Municipal Water Authority #1 Substation",[-101.54634922181546, 35.7103096458433]],
    [ "Pantex South Substation",[-101.56868305623011, 35.30553312502809]],
    [ "Transpecto Substation",[-101.42747864009083, 35.64566233278924]],
    [ "Sid Richardson Cogeneration Station",[-101.4387338, 35.664776]],
    [ "Borger West Substation",[-101.40586542457798, 35.675780313439766]],
    [ "Sneed Substation",[-101.7016929, 35.810935900000004]],
    [ "463732671",[-101.55709870487871, 35.81630117516336]],
    [ "Herring Substation",[-101.54978394129219, 35.828892225124456]],
    [ "Fritch Rural Substation",[-101.5585251, 35.6453676]],
    [ "Canadian River Municipal Water Authority #2 Substation",[-101.54963839242082, 35.678057117995714]],
    [ "Midstream Energy Substation",[-101.4054302, 35.51337469999999]],
    [ "Midstream Energy Tap",[-101.40437994999998, 35.48661965]],
    [ "Canadian River Municipal Water Authority #4 Substation",[-101.68353950921001, 35.37572738189035]],
    [ "ASARCO Substation",[-101.73083400825908, 35.284902121995465]],
    [ "Llano Estacado Wind Substation",[-101.2392382722424, 35.486572642263596]],
    [ "Carson County Substation",[-101.5618908, 35.382794499999996]],
    [ "Majestic Wind Substation",[-101.5389081752324, 35.38186455693885]],
    [ "Martin Substation",[-101.54037253286695, 35.38209242526463]],
    [ "Pantex North Substation",[-101.55543700105255, 35.318587193315636]],
    [ "Conway Substation",[-101.3821803927371, 35.25115847492123]],
    [ "Yarnell Substation",[-101.6116243757325, 35.21422999035613]],
    [ "Spring Draw Substation",[-101.9256193474759, 35.050909549708784]],
    [ "Estacado Substation",[-101.9048623750773, 35.13221409499077]],
    [ "Crouse Hinds Substation",[-101.85650820886212, 35.13333022506739]],
    [ "34th Street Substation",[-101.89649787485347, 35.17734040834474]],
    [ "Farmers Substation",[-101.86521610000001, 35.13374985]],
    [ "Waterfield Substation",[-102.02873322476773, 35.117851761033286]],
    [ "Bush Substation",[-101.89828102494945, 35.21759694390463]],
    [ "Van Buren Substation",[-101.8386758, 35.2171109]],
    [ "Noble Power",[-101.39153459999999, 36.49444815000001]],
    [ "Palo Duro Wind Substation",[-101.00424399999999, 36.4106027]],
    [ "464627732",[-101.25435055000001, 36.2553082]],
    [ "464627737",[-101.00059337510707, 36.24445190748038]],
    [ "464627743",[-101.00297405201762, 36.33166522800001]],
    [ "Spearman City Substation",[-101.19029659225734, 36.204444742008334]],
    [ "Texas Farms Substation",[-100.92781064999998, 36.33951635]],
    [ "Spearman Interchange",[-101.31930316103127, 36.21179831193636]],
    [ "Ochiltree Interchange",[-100.81708747490924, 36.40886045933466]],
    [ "Dumas South Substation",[-101.97423317482908, 35.85128291400339]],
    [ "Dumas Helium Substation",[-102.02636226331568, 35.819365925108386]],
    [ "464627847",[-101.78807230341135, 36.498902328143465]],
    [ "Frisco Wind Farm Substation",[-101.58653376599742, 36.49614622543373]],
    [ "464627862",[-102.05332625872158, 36.32846627067219]],
    [ "Sherman County Substation",[-102.026300788697, 36.35714127506234]],
    [ "Spurlock Substation",[-101.83040197496668, 36.139363555590165]],
    [ "Exelon Wind 4 Substation",[-101.38775638581893, 36.399601349108316]],
    [ "Stokes & Sheldon Substation",[-101.86372327484715, 35.90850675696644]],
    [ "Hogue Substation",[-102.370423774829, 36.026299106616804]],
    [ "Wheelock Substation",[-101.87875235, 33.55525225]],
    [ "Indiana Substation",[-101.88726515, 33.613385050000005]],
    [ "Planters Substation",[-101.78327054999998, 33.5850498]],
    [ "Hettler Substation",[-101.76644966899045, 33.63481544826871]],
    [ "Shallowater Substation",[-101.95750725705486, 33.69349852522253]],
    [ "Whitehead Substation",[-101.87195052504998, 33.730463756645655]],
    [ "Monroe Substation",[-101.86212890000002, 33.73705470000001]],
    [ "New Deal Interchange",[-101.86228677494995, 33.78104225496747]],
    [ "Northeast Substation",[-101.80224836143289, 33.62096523219135]],
    [ "Wadsworth Substation",[-101.79920465000001, 33.556693550000006]],
    [ "Massengale Station",[-101.84205429444971, 33.6035998061325]],
    [ "Brandon Station",[-101.8865171, 33.5848937]],
    [ "Erskine Substation",[-101.87952276509179, 33.6065282359922]],
    [ "Northwest Substation",[-101.90674361517563, 33.62211924246045]],
    [ "Woodrow Interchange",[-101.78840804218981, 33.46236254924992]],
    [ "Posey Substation",[-101.70997524999999, 33.484097299999995]],
    [ "Union Substation",[-101.79674619999999, 33.44768295]],
    [ "Cooper Substation",[-101.85373463918285, 33.4614094298708]],
    [ "Frenship Substation",[-102.00920195, 33.4474079]],
    [ "Upland Substation",[-101.97343134928926, 33.520927154495254]],
    [ "Ivory Substation",[-101.82725772515226, 33.52770099167151]],
    [ "ACCO Substation",[-101.8066715202136, 33.5483962271558]],
    [ "Clutter Substation",[-101.83328199999998, 33.5594973]],
    [ "Woodward Substation",[-101.90466597525788, 33.44749558021368]],
    [ "Yuma Interchange",[-101.98548784795757, 33.534733557341575]],
    [ "Carlisle Substation",[-101.98761612502747, 33.56617994241244]],
    [ "Doud Substation",[-101.93754642996235, 33.556564928128864]],
    [ "Erskine Substation",[-101.95580903386195, 33.61451147491379]],
    [ "Stephens Ranch Wind Project II Substation",[-101.66754552431526, 32.982623484508295]],
    [ "Alum Creek Substation",[-97.23312862109704, 30.065852007388607]],
    [ "Camp Springs Wind 2 Substation",[-100.82356885000002, 32.75930525]],
    [ "Brand Tapping Station",[-100.9450899, 32.783624950000004]],
    [ "Cogdell Substation",[-100.88666231342515, 32.942254125418316]],
    [ "Union Substation",[-100.99894467458749, 32.706884009238216]],
    [ "Fluvanna Substation",[-101.11514304999997, 32.859327949999994]],
    [ "Oxy Cogdell Substation",[-100.88640931353761, 32.94234389928749]],
    [ "Ennis Creek Substation",[-100.92392245, 32.84104965000001]],
    [ "Snyder Wind Farm Substation",[-100.75742027875724, 32.74559365460086]],
    [ "Plainview Substation",[-100.8246298210685, 32.733291443670964]],
    [ "Enel",[-100.82437163119803, 32.73365801574928]],
    [ "SNTX Substation",[-100.86134690407711, 32.71212316799705]],
    [ "Kinder Morgan",[-100.95503539063374, 32.74705407582333]],
    [ "Sun Switching Station",[-100.96157565411208, 32.7484781254432]],
    [ "Golden Switching Station",[-100.94514997489902, 32.77640105961419]],
    [ "Camp Springs Wind 1 Substation",[-100.82366994009429, 32.75964517894806]],
    [ "Scurry Sante Fe Substation",[-100.9636467916852, 32.66168967520722]],
    [ "Scurry Substation",[-101.0503177, 32.627406699999995]],
    [ "Ira Substation",[-100.9754487, 32.58161525]],
    [ "Snyder Substation",[-100.90269816467726, 32.70400472647677]],
    [ "Lake Thomas Substation",[-101.1383527, 32.5535448]],
    [ "Colorado City Substation",[-100.84148745, 32.392242800000005]],
    [ "China Grove Gulf Substation",[-100.83111240664961, 32.53164427489747]],
    [ "467959274",[-100.84532175000002, 32.52987665]],
    [ "Colorado Basin Substation",[-100.83642708786438, 32.530057075160656]],
    [ "Sacroc Substation",[-100.985654470392, 32.74232584171929]],
    [ "Knapp Substation",[-101.0523786, 32.6915811]],
    [ "Bluff Creek Substation",[-101.05435285, 32.62714535]],
    [ "Kinder Morgan",[-100.95785611411094, 32.7474706750261]],
    [ "Brand Substation",[-100.94433805, 32.78349865]],
    [ "Ennis Creek Switching Station",[-100.92499267878327, 32.84010582179686]],
    [ "Scurry Chevron Substation",[-100.9607921254432, 32.81275829442761]],
    [ "Scurry Switching Station",[-100.97484387533515, 32.87110004066893]],
    [ "467959346",[-101.00446083228613, 32.77415570798669]],
    [ "Red Canyon Wind Substation",[-101.18465704999998, 32.918845049999995]],
    [ "Brazos Wind 1 Substation",[-101.12959983312875, 32.952598854403654]],
    [ "Brazos Wind 2 Substation",[-101.31592798308975, 32.93890869939936]],
    [ "Exxon Sharon Ridge Substation",[-101.14949172470493, 32.62902096211738]],
    [ "Bull Creek Wind Substation",[-101.58426895, 32.92789905]],
    [ "Iatan Howard Substation",[-101.18704481800373, 32.2806092198098]],
    [ "Westbrook Substation",[-101.01440020000001, 32.36022365]],
    [ "Coahoma Substation",[-101.30834992952181, 32.3038899376277]],
    [ "Ocotillo Wind Substation",[-101.39384400218465, 32.132007487084834]],
    [ "Forsan Substation",[-101.41670271190338, 32.08035332072017]],
    [ "Drumright Switching Station",[-101.40855218733296, 32.06480417502103]],
    [ "Big Spring Carbon Substation",[-101.40887910781086, 32.28066660934559]],
    [ "Salt Creek #1 Substation",[-100.88028001858353, 33.2289989353883]],
    [ "Salt Creek #2 Substation",[-100.86992574942357, 33.23106028993512]],
    [ "Spur Substation",[-100.85723099999998, 33.4737594]],
    [ "McAdoo Wind Energy Substation",[-100.99155874101761, 33.7504179750415]],
    [ "468606175",[-106.33787617434781, 31.75076858613579]],
    [ "Milagro Substation",[-106.42294364231863, 31.901679965614587]],
    [ "Kalgary Substation",[-101.11375750845336, 33.40910352471913]],
    [ "SMS Substation",[-100.94778115450997, 33.28966742241354]],
    [ "Afton Substation",[-100.84186545, 33.7338394]],
    [ "Glenn Substation",[-100.84186645, 33.73393115]],
    [ "Espuela Substation",[-100.89707167512809, 33.54389040499185]],
    [ "469144422",[-95.84663944385387, 32.328855417304105]],
    [ "Turkey Substation",[-100.88915947522135, 34.39165970380314]],
    [ "Turkey Substation",[-100.8980346746187, 34.3422878937933]],
    [ "Flomot Substation",[-100.98977869999997, 34.2524224]],
    [ "Muleta Substation",[-100.87280499237869, 34.10519492895993]],
    [ "Whirlwind Substation",[-101.09844092458853, 34.106130995189496]],
    [ "Matador Substation",[-100.83187320339334, 34.011286674804715]],
    [ "Roaring Springs Substation",[-100.84733485000002, 33.897369600000005]],
    [ "473663546",[-96.1770605906209, 32.544970575193126]],
    [ "478412024",[-95.55576429100428, 33.69384059700706]],
    [ "479746295",[-96.66586746918578, 33.01205828483659]],
    [ "479878928",[-98.5324275011779, 33.84431224598679]],
    [ "Simsboro Magnolia Substation",[-96.31465475, 31.673412850000005]],
    [ "Palmito Substation",[-97.3798889, 25.98455735]],
    [ "Laguna Substation",[-97.29197533225836, 27.648995043580157]],
    [ "Alazan Substation",[-97.50220985, 27.5598557]],
    [ "Barney Davis Substation",[-97.31307045137862, 27.607708401623803]],
    [ "488507818",[-97.49928911678194, 27.83638475348094]],
    [ "488507828",[-97.5050203015631, 27.817946657591914]],
    [ "Hearn Road Substation",[-97.6108201167936, 27.84992745371033]],
    [ "Mayo Substation",[-97.42469682523756, 27.909481890806678]],
    [ "Midway Substation",[-97.36539668240867, 27.943976746529856]],
    [ "Gila Substation",[-97.42126545315158, 27.81966446382382]],
    [ "Nueces Bay Substation",[-97.41837552414192, 27.820878520441866]],
    [ "Air Liquide Substation",[-97.47798115, 27.8148983]],
    [ "Kepler Substation",[-97.50485307304497, 27.818583810562227]],
    [ "488507982",[-97.46801645, 27.811832999999993]],
    [ "Cantwell Substation",[-97.44437418203741, 27.81139249831536]],
    [ "Citgo",[-97.42212726173574, 27.80484323962571]],
    [ "Citgo",[-97.42014421169702, 27.80790678417311]],
    [ "Industrial Substation",[-97.43138775447544, 27.80810152571556]],
    [ "Citgo",[-97.42704477594206, 27.812671053474624]],
    [ "Highway 9 Substation",[-97.4458659672385, 27.796450096289433]],
    [ "West Oso Substation",[-97.45212760000001, 27.76620465]],
    [ "Javelina Substation",[-97.45704046170957, 27.805139283199374]],
    [ "Clarkwood Substation",[-97.53548200569047, 27.820263021165523]],
    [ "Hearn's Ferry Substation",[-97.53766886492149, 27.832211257636374]],
    [ "Valero",[-97.47941250666646, 27.816443140954835]],
    [ "Valero East Substation",[-97.48059797203722, 27.8149393963017]],
    [ "Valero West Substation",[-97.48329314905189, 27.815108147887496]],
    [ "Koch Upriver Substation",[-97.52468752203978, 27.827453357785032]],
    [ "488508185",[-97.59603375, 27.811042900000004]],
    [ "Champlin - Citgo West Substation",[-97.496753, 27.809899350000002]],
    [ "Skidmore Substation",[-97.67631008911087, 28.25521077958956]],
    [ "Chase Field Substation",[-97.6707732577357, 28.380588433364068]],
    [ "Clay West Substation",[-98.19877193747007, 28.25104586272602]],
    [ "West George West Substation",[-98.21270343915819, 28.287583804418837]],
    [ "Tynan Substation",[-97.77229931678464, 28.18319750204318]],
    [ "Bee Prison Substation",[-97.80611797136922, 28.334824715389903]],
    [ "Olmos Substation",[-97.79599405, 28.2438148]],
    [ "Mathis Substation",[-97.86158591646387, 28.160355745098194]],
    [ "George West Substation",[-98.07320431792188, 28.33091329636292]],
    [ "Los Angeles Substation",[-99.00110584999999, 28.390901050000004]],
    [ "Fowlerton Substation",[-98.80140534458542, 28.37145412513927]],
    [ "George West Switching Station",[-98.19400694520859, 28.40355138024051]],
    [ "Simmons City Substation",[-98.2058672556216, 28.408887823702013]],
    [ "Tilden Substation",[-98.51863710199659, 28.45937050547748]],
    [ "Choke Canyon Prison Substation",[-98.32001165, 28.441783999999995]],
    [ "Choke Canyon Substation",[-98.28027635000001, 28.453500000000002]],
    [ "North Calliham Substation",[-98.34119037486056, 28.5435999128687]],
    [ "Three Rivers Refinery Substation",[-98.19072004906138, 28.45850744463974]],
    [ "Sigmor Substation",[-98.1868327403127, 28.45536922803977]],
    [ "Choke Canyon Substation",[-98.21903397981609, 28.454896990013076]],
    [ "Choate Substation",[-97.74064010000001, 28.815513650000007]],
    [ "Kenedy Substation",[-97.83240623252753, 28.879930814344096]],
    [ "Coy City Substation",[-97.99395585145085, 28.871855983579845]],
    [ "Berclair Substation",[-97.59852049999999, 28.493301250000005]],
    [ "Beeville Substation",[-97.79789758304145, 28.42493641537105]],
    [ "Normanna Substation",[-97.77399261821093, 28.53353446561138]],
    [ "Tuleta Substation",[-97.7885434921324, 28.598227230619422]],
    [ "Pettus Substation",[-97.79539791931663, 28.620694907668618]],
    [ "Lyssy Substation",[-98.12005345, 28.894397700000003]],
    [ "Karnes City Substation",[-97.87860377579796, 28.85889494418115]],
    [ "Kenedy Substation",[-97.84392675000001, 28.817675050000002]],
    [ "Beeville Substation",[-97.74337204032774, 28.397023664568888]],
    [ "Three Rivers Substation",[-98.15760050000004, 28.480958450000006]],
    [ "Sunniland Substation",[-98.20013983752213, 28.581374929331822]],
    [ "Fashing Substation",[-98.1706636, 28.8245284]],
    [ "Orange Grove Substation",[-97.960163518277, 27.91996697643777]],
    [ "Sandia Substation",[-97.86670835515699, 28.034846031901854]],
    [ "Casa Blanca Substation",[-97.90799303626422, 27.998276785727267]],
    [ "Orange Grove Switching Station",[-97.96141306989071, 27.918953587784216]],
    [ "George West Substation",[-98.13836524999998, 28.302010149999994]],
    [ "Corpus Christi Substation",[-97.50221667491262, 27.68856889515015]],
    [ "Robstown Substation",[-97.63610692488638, 27.768613353752425]],
    [ "Papalote Creek Wind Farm Substation",[-97.42581644432224, 27.951205875437683]],
    [ "Robstown City Substation",[-97.66174585, 27.8004448]],
    [ "Robstown Substation",[-97.65889852771028, 27.789999493753985]],
    [ "Riverside Substation",[-97.71903889007943, 27.847055872098373]],
    [ "Banquete Substation",[-97.79141815199613, 27.796893729317937]],
    [ "Odem Substation",[-97.57378460000001, 27.94253385]],
    [ "Water Works Substation",[-97.62512179591818, 27.8766214149221]],
    [ "Edroy Substation",[-97.68471035000002, 27.975431250000003]],
    [ "Taft Substation",[-97.3581169065938, 28.000651729066863]],
    [ "Sodville Substation",[-97.47287525, 27.969667349999995]],
    [ "Smith Substation",[-97.63215215117434, 27.867816583351193]],
    [ "Stevens Substation",[-97.6194418778107, 27.860122307624863]],
    [ "Elk Station Switchyard",[-101.8389547807281, 33.86622942015891]],
    [ "South Plains Wind 2 Substation",[-101.38162540255773, 34.196040165604494]],
    [ "South Plains Wind 1 Substation",[-101.32385302694819, 34.08052004792976]],
    [ "White River Substation",[-101.17945756532274, 34.0590348745891]],
    [ "Driscoll Substation",[-97.7409524516322, 27.62646682809207]],
    [ "Corpus Christi Switch",[-97.63586205, 27.710991999999997]],
    [ "Kleberg Substation",[-97.85511514999997, 27.492234399999997]],
    [ "South Texas Electric Cooperative",[-97.81932372186601, 27.75560729526875]],
    [ "Bunsen Substation",[-97.81840231100782, 27.761697391866498]],
    [ "Bishop Substation",[-97.84294826153496, 27.579487414899376]],
    [ "490190268",[-97.82081405043571, 27.5688073666766]],
    [ "Celanese Bishop Substation",[-97.83035615000001, 27.560572499999996]],
    [ "King Ranch Gas Plant Substation",[-98.0534067752695, 27.4692226541294]],
    [ "Kingsville Substation",[-97.8862267938018, 27.530704025293346]],
    [ "Central Substation",[-97.91048877504362, 27.67008524499619]],
    [ "Ben Bolt Substation",[-98.06891925, 27.68741835]],
    [ "Alice Substation",[-98.08745937501435, 27.72983520734327]],
    [ "Stadium Substation",[-98.05383969887093, 27.74608159359164]],
    [ "Government Wells Substation",[-98.60159198525601, 27.933149553431917]],
    [ "Freer Substation",[-98.61740764415715, 27.845283073513844]],
    [ "San Diego Substation",[-98.24815255, 27.740942099999998]],
    [ "Gasland Substation",[-102.82738852939104, 32.24969210828722]],
    [ "Andrews County Northwest Substation",[-103.05862173480426, 32.427359775043875]],
    [ "Lufkin Substation",[-94.73829542459805, 31.35788224060718]],
    [ "Cedar Gap Substation",[-99.75282652513988, 32.31361684437458]],
    [ "Maryneal Substation",[-99.92870595, 32.46013565]],
    [ "Moore Substation",[-99.8365898714313, 32.387401822793386]],
    [ "Merkel Substation",[-100.00511180000001, 32.4785045]],
    [ "Scott Substation",[-100.07478272830484, 32.449253556614934]],
    [ "Butman Substation",[-100.07393125755355, 32.44940102165051]],
    [ "Trent Substation",[-100.11797467087354, 32.492739546855596]],
    [ "Fort Chadbourne Substation",[-100.27452172168518, 32.017023776059574]],
    [ "Oak Creek Switching Station",[-100.2929732085688, 32.04673485778405]],
    [ "Nicole Switching Station",[-100.2903649820492, 32.02331640481463]],
    [ "Wylie Substation",[-99.77641291767169, 32.368182365175066]],
    [ "Abilene Rebecca Lane Substation",[-99.78269454021924, 32.39441416237231]],
    [ "Abilene Twilight Trails Substation",[-99.787209, 32.417383650000005]],
    [ "Moore Substation",[-99.8368149876778, 32.387040049080944]],
    [ "McElmurray Substation",[-100.1388056448453, 32.466008700333674]],
    [ "McElmurray Substation",[-100.13833123837802, 32.46584580549729]],
    [ "Oak Creek Substation",[-100.35714386130205, 32.24309249915169]],
    [ "Lonestar Cement Substation",[-100.45958980000003, 32.2465417]],
    [ "Flintkote Substation",[-100.3388919, 32.48151275]],
    [ "US Gypsum Substation",[-100.3630032838382, 32.49192585448261]],
    [ "US Gypsum South Substation",[-100.36057015, 32.4890175]],
    [ "Sweetwater Substation",[-100.44293355706456, 32.457176177792554]],
    [ "Maryneal Substation",[-100.4538247294279, 32.2350751563761]],
    [ "Sweetwater Creek Substation",[-100.45888395, 32.243634400000005]],
    [ "Lonestar Cement Substation",[-100.45712127198348, 32.24445365823639]],
    [ "Turkey Track Wind Substation",[-100.25645060000002, 32.211982750000004]],
    [ "Abilene Elm Creek Substation",[-99.78955225610038, 32.458026009556264]],
    [ "Abilene Industrial Park Substation",[-99.81135815, 32.465194499999996]],
    [ "Abilene Northwest Substation",[-99.78984840673405, 32.50283814098071]],
    [ "Sandusky Substation",[-99.75478985000002, 32.35999580000001]],
    [ "Walker Substation",[-99.70020859021422, 32.35838997505519]],
    [ "Abilene South Substation",[-99.75128440377762, 32.35916554996153]],
    [ "Trent Wind Farm Substation",[-100.23178225000001, 32.449597950000005]],
    [ "Sweetwater Wind 2 Substation",[-100.3797682487832, 32.33094955514416]],
    [ "Roscoe Substation",[-100.54100216506184, 32.4462357190482]],
    [ "Loraine Substation",[-100.71323145000001, 32.415882450000005]],
    [ "Sweetwater Downtown Substation",[-100.40972887099178, 32.46599326254061]],
    [ "Golden Spread Electric Cooperative",[-100.3973868124675, 32.44343172238355]],
    [ "Sharyland Utilities",[-100.71519092597921, 32.38250420968383]],
    [ "Bronte Atlantic Substation",[-100.31101534999999, 31.8218762]],
    [ "Spence Pump Substation",[-100.59718475007013, 31.950586066683513]],
    [ "Bronte Substation",[-100.29330614999998, 31.891341]],
    [ "Bronte Ambassador Substation",[-100.3973224, 31.88512575]],
    [ "Robert Lee Substation",[-100.48483651549697, 31.892157272597107]],
    [ "Edith Substation",[-100.61110697421915, 31.91826365539239]],
    [ "Silver Substation",[-100.67814297535736, 32.07040659087739]],
    [ "Silver Substation",[-100.68008445564206, 32.050972306991596]],
    [ "Cedar Hill Switching Station",[-100.73896111138491, 32.014413430494926]],
    [ "Perkins Substation",[-100.76872336172703, 32.00614176989972]],
    [ "491803246",[-95.8649520799771, 32.69789996893891]],
    [ "Eola Substation",[-100.089891592376, 31.414022774976967]],
    [ "Eden Substation",[-99.83622623377443, 31.21574062453014]],
    [ "Colorado River Municipal Water District #1 Substation",[-99.6982762, 31.49142345]],
    [ "Paint Rock Substation",[-99.91807864999998, 31.5062491]],
    [ "Happy Valley Substation",[-100.09938369257125, 32.14328076839725]],
    [ "Bradshaw/Climax Substation",[-99.87992880000002, 32.09587315]],
    [ "Bradshaw Substation",[-99.88256635808175, 32.09250047096723]],
    [ "Winters Substation",[-99.96215375455725, 31.97096172538187]],
    [ "Hatchell Substation",[-99.9563972, 31.820680649999996]],
    [ "Rowena Substation",[-100.0472679898602, 31.645725824964313]],
    [ "Harriett Substation",[-100.2581491335673, 31.55173195577737]],
    [ "Miles Substation",[-100.17810259999999, 31.593340050000002]],
    [ "Eldorado Shell Bailey Substation",[-100.623076, 30.83157415]],
    [ "Middle Valley Substation",[-100.31105651940226, 30.757902757813117]],
    [ "Page Field Substation",[-100.4642948392999, 30.79852352466631]],
    [ "Fort McKavett Substation",[-100.2642264455186, 30.747376271226585]],
    [ "Eldorado Substation",[-100.5966197, 30.86069835]],
    [ "Sonora City Substation",[-100.64422350000001, 30.570974900000003]],
    [ "Fredonia Substation",[-99.1190717, 30.894662699999998]],
    [ "Puncher Substation",[-99.26179197060797, 30.56229780556671]],
    [ "Mason Phillips Substation",[-99.26911569237411, 30.546487418123924]],
    [ "Puncher Substation",[-99.26098096091908, 30.562673440507165]],
    [ "Streeter Substation",[-99.41112889176497, 30.763742988782116]],
    [ "Shell London Substation",[-99.59288318418055, 30.645720389922204]],
    [ "Hornet Substation",[-99.82381959999998, 30.687096100000005]],
    [ "Menard Phillips Substation",[-99.8237915, 30.68301175]],
    [ "Junction Substation",[-99.77064604999998, 30.492406850000002]],
    [ "Junction Substation",[-99.76951690635397, 30.515172003509857]],
    [ "Camp San Saba Substation",[-99.28163575, 30.9602689]],
    [ "Katemcy Substation",[-99.28171641465808, 30.959666858349387]],
    [ "Brady Substation",[-99.34156241573001, 31.13522522537889]],
    [ "North Brady Substation",[-99.3362320843142, 31.155516625482996]],
    [ "Melvin Substation",[-99.58401101288901, 31.196338531057332]],
    [ "Schaffner Substation",[-99.75421995556415, 31.21495577527927]],
    [ "Cincinnati Substation",[-95.51911217336439, 30.849632055362214]],
    [ "Toledo Bend Substation",[-93.56468549257755, 31.173850140780406]],
    [ "Bulverde Substation",[-98.44122025751803, 29.731898578816093]],
    [ "Blossom Substation",[-98.45934478111909, 29.54651409840636]],
    [ "Glass Ranch Substation",[-102.00874590000001, 32.187637699999996]],
    [ "Solara Substation",[-99.60543472512379, 33.00657180351009]],
    [ "Rankin Substation",[-101.93749794999998, 31.217913949999996]],
    [ "Discovery Canyon Substation",[-101.93496065000001, 30.895708650000003]],
    [ "Cactus Tapping Station",[-101.83204745, 30.787753649999996]],
    [ "Iraan Substation",[-101.89966017502745, 30.9104814893564]],
    [ "McCamey Shell Substation",[-102.19165985, 31.133319500000006]],
    [ "South Cross Substation",[-102.27661695, 31.048444500000002]],
    [ "Pecos River Substation",[-101.81823238840252, 30.787176032055278]],
    [ "Iraan Air Products Substation",[-101.94355209999999, 30.912984]],
    [ "Marathon Yates Substation",[-101.93245693896455, 30.922412521960727]],
    [ "Sheffield Substation",[-101.81545538235771, 30.72506591569897]],
    [ "West Yates Substation",[-101.96581853313324, 30.94925669911662]],
    [ "Mesa View Substation",[-101.99225934999998, 30.89774565]],
    [ "Mesa View Substation",[-102.03241538284041, 30.82461804822899]],
    [ "Desert Sky Wind Substation",[-102.10968197088066, 30.91595844157076]],
    [ "Indian Mesa Wind Substation",[-102.20174185, 30.931671100000003]],
    [ "Bobcat Hills Substation",[-102.29657388070012, 31.105003656614468]],
    [ "Indian Mesa Substation",[-102.27131446573036, 30.97912741925261]],
    [ "Tunas Creek Substation",[-102.27546775, 30.98182725]],
    [ "Pearl Substation",[-102.2745462, 30.982295649999994]],
    [ "Sirius Substation",[-102.2740698, 30.982105349999998]],
    [ "Bootleg Substation",[-102.29148625474254, 30.994456966845142]],
    [ "Greens Prairie Substation",[-96.25775599999999, 30.553654399999996]],
    [ "Dobbin Substation",[-95.78152397887885, 30.350710404710163]],
    [ "496987883",[-96.0939011, 30.36992825]],
    [ "Mockingbird Substation",[-96.09389595890622, 30.370340561294334]],
    [ "Spring Branch Substation",[-95.69860521319342, 30.33324698928085]],
    [ "Independence Substation",[-96.40576525000002, 30.31575585]],
    [ "Caldwell Industrial Substation",[-96.71375086990395, 30.52715735724051]],
    [ "496987939",[-96.44830138424157, 30.72604425308003]],
    [ "Triangle Park Substation",[-96.44917159473384, 30.725374615727766]],
    [ "Carlos Substation",[-96.10005999185562, 30.592942260881117]],
    [ "Kurten Substation",[-96.29283337825231, 30.799775750158414]],
    [ "Speedway Substation",[-96.21648900000001, 30.5419393]],
    [ "Bryan Substation",[-96.40979805, 30.7443327]],
    [ "Bedias Substation",[-95.98752367532366, 30.67067195361024]],
    [ "Industrial Park Substation",[-96.40777480506098, 30.655934867207677]],
    [ "North Substation",[-96.39445178335178, 30.69120240033207]],
    [ "Rattlesnake Den Wind Substation",[-101.46637208465636, 31.74926848170905]],
    [ "Bow Wood Substation",[-99.54852322505253, 31.463111107249794]],
    [ "Wake Wind Wind Energy Substation",[-101.06975970000002, 33.80291484999999]],
    [ "Briscoe Wind Farm Substation",[-101.31488699999998, 34.39250765]],
    [ "Mariah del Norte Wind Substation",[-102.66454582567651, 34.68611549270167]],
    [ "Hereford Wind Substation",[-102.25565425, 34.7192262]],
    [ "Colbeck's Corner Wind Farm Substation",[-101.15117320899753, 35.33540417057524]],
    [ "Kirby Switching Station",[-100.93783155000001, 35.255652149999996]],
    [ "Jericho Substation",[-100.93974449364799, 35.18378970055947]],
    [ "Salt Fork Wind Substation",[-100.94015155083162, 35.16384458431687]],
    [ "498236284",[-100.9400828252985, 35.16680755739793]],
    [ "Childress Amoco Substation",[-100.28223442558368, 34.41071164306451]],
    [ "Carey Substation",[-100.36449179737583, 34.47002563339354]],
    [ "Estelline Substation",[-100.44985453398604, 34.54546911265901]],
    [ "Childress Airport Substation",[-100.27011291734794, 34.43288911599578]],
    [ "Red River Substation",[-100.51790861008247, 34.60021917241903]],
    [ "Memphis Substation",[-100.53371422530616, 34.7198005466859]],
    [ "Northwest Memphis Substation",[-100.54520245, 34.74001675]],
    [ "Memphis Substation",[-100.5683706531619, 34.76133786759084]],
    [ "Hedley Substation",[-100.6635709, 34.85634215]],
    [ "Clarendon Substation",[-100.8657240449608, 34.92575747526594]],
    [ "Clarendon Substation",[-100.88995137294596, 34.93980017624478]],
    [ "Howardwick Substation",[-100.90192195000002, 35.04808665]],
    [ "Chillicothe Substation",[-99.50982965000001, 34.24849645]],
    [ "West Childress Substation",[-100.22704934999999, 34.4194449]],
    [ "Childress Substation",[-100.2101546992684, 34.42090611130814]],
    [ "Kirkland Substation",[-100.06643274999998, 34.37759499999999]],
    [ "Quanah Acme Mills Substation",[-99.8205818, 34.3225336]],
    [ "Quanah Substation",[-99.7405800323527, 34.30156891766001]],
    [ "Henry Substation",[-100.2274615976946, 34.43051474325008]],
    [ "Childress 20th Street Substation",[-100.22474199999999, 34.43599805]],
    [ "Harlingen Switching Station",[-97.69822845, 26.2221971]],
    [ "McLean Rural Substation",[-100.57393652481917, 35.21215366093837]],
    [ "Shamrock Substation",[-100.25414832486548, 35.18328976172623]],
    [ "Grayback Substation",[-99.16745915, 33.994044900000006]],
    [ "Harrold Substation",[-99.0937443246854, 34.042709038519305]],
    [ "Lockett Substation",[-99.37532286046766, 34.05109116979568]],
    [ "Bendix Substation",[-99.1126646, 34.103328250000004]],
    [ "499322820",[-102.30764866876451, 31.85256503578471]],
    [ "Midessa South Substation",[-102.2159717027475, 31.85813707375254]],
    [ "Oncor",[-102.20457544999998, 31.907421200000005]],
    [ "Midland Basin Substation",[-102.00966582550583, 32.011276041003256]],
    [ "Midland Gulf Substation",[-102.0251874514989, 32.01848886720858]],
    [ "Pleasant Farms Substation",[-102.3162418752019, 31.739794955932624]],
    [ "Pegasus Substation",[-102.21313112928458, 31.740817906074046]],
    [ "Pegasus South Substation",[-102.22506400690682, 31.662888920470586]],
    [ "Crane East Substation",[-102.13167995, 31.433112449999996]],
    [ "Upton Substation",[-101.88488632069469, 31.43843428534888]],
    [ "Reagan Shell Substation",[-101.70784760000001, 31.633553799999998]],
    [ "Pembrook Substation",[-101.82220345000002, 31.56782225]],
    [ "Midkiff Switching Station",[-101.80781949999998, 31.520806999999994]],
    [ "Glasscock Substation",[-101.64537267933906, 31.68918565783812]],
    [ "Garden City Substation",[-101.52619196357617, 31.813133172809923]],
    [ "Garden City East Substation",[-101.46897285, 31.86663485]],
    [ "Tex Harvey Substation",[-101.79148068916713, 31.854406029015756]],
    [ "Tex Harvey Substation",[-101.79139463901325, 31.85340847901191]],
    [ "Barnsley Substation",[-102.59785606349932, 31.406267908421775]],
    [ "Exxon Juan Cordona Substation",[-102.56246078686011, 31.325473933461787]],
    [ "Crane County Airport Substation",[-102.3729417, 31.4256723]],
    [ "Dune Field Substation",[-102.43615785, 31.536227600000004]],
    [ "Crane Gulf Substation",[-102.34135595000002, 31.423166549999998]],
    [ "Crane Atlantic Substation",[-102.43770706233364, 31.431988820641216]],
    [ "Texas-New Mexico Pipeline Substation",[-102.38026415000002, 31.42903935]],
    [ "McElroy Substation",[-102.31567629949909, 31.422933300323844]],
    [ "Crane East Substation",[-102.13164707350775, 31.43277164549601]],
    [ "Crane Humble Substation",[-102.34721672984341, 31.428714108803465]],
    [ "Getty Substation",[-102.35177644999999, 31.461997150000002]],
    [ "Crane Substation",[-102.33849603229112, 31.41082414266675]],
    [ "Crane Substation",[-102.3385911429933, 31.409853084113227]],
    [ "Crane Substation",[-102.33729087109911, 31.410823088478498]],
    [ "Arco Substation",[-102.45914017934913, 31.440578058035015]],
    [ "Spudder Flat Substation",[-102.31251887457483, 31.221249345619107]],
    [ "King Mountain Southwest Substation",[-102.27733631657755, 31.2214464004967]],
    [ "King Mountain Northwest Substation",[-102.23838627096933, 31.252639754428234]],
    [ "King Mountain Northeast Substation",[-102.17959820625065, 31.274424171188482]],
    [ "King Mountain Southeast Substation",[-102.13303716847062, 31.227452702754604]],
    [ "Ward Switching Station",[-102.82549559999998, 31.450316250000004]],
    [ "Musquiz Substation",[-103.15829336842559, 31.238370685228343]],
    [ "Waha Substation",[-103.11321430000001, 31.293589599999997]],
    [ "County Road 101 Substation",[-103.10664047512945, 31.275539843427822]],
    [ "Wolf Bone Ranch Substation",[-103.216114, 31.238171950000005]],
    [ "Clovis Substation",[-103.29181177496216, 30.953489991050432]],
    [ "Barrilla Junction Substation",[-103.36536238678292, 30.94725937215809]],
    [ "Hovey Substation",[-103.3614053, 30.952627650000004]],
    [ "Solstice Substation",[-103.36152782916572, 30.948263191428747]],
    [ "Northern Natural Substation",[-102.92458215, 30.99518915]],
    [ "Wolfcamp Substation",[-103.03535298731963, 31.18466668710711]],
    [ "Coyanosa Substation",[-102.9618195, 31.26294755]],
    [ "Royalty Substation",[-102.86947159784286, 31.38849428640318]],
    [ "Saragosa Substation",[-103.65300550280993, 31.04202071733735]],
    [ "Cherry Creek Substation",[-103.66663734999997, 31.114424299999996]],
    [ "Verhalen Substation",[-103.59005155000001, 31.144555250000003]],
    [ "Kunitz Substation",[-104.74904735, 31.73484749999999]],
    [ "Salt Creek West Substation",[-104.04775344284833, 31.809831724787486]],
    [ "Culberson Substation",[-104.304652565241, 31.75778031980629]],
    [ "Culberson Substation",[-104.30377385793666, 31.757832083695007]],
    [ "Black River Substation",[-104.28688722833823, 31.833734895536544]],
    [ "501477428",[-104.04774902521403, 31.81014169512195]],
    [ "501477433",[-104.02407835, 31.920771650000002]],
    [ "Screwbean Substation",[-104.04595277491975, 31.821976215349256]],
    [ "501477439",[-104.04648267612691, 31.817710643720705]],
    [ "Orla Southwest Substation",[-103.93081700773568, 31.807206906252002]],
    [ "501477456",[-103.93163442463741, 31.797723622966252]],
    [ "501477459",[-103.93201355000001, 31.800355449999998]],
    [ "Worsham Field Substation",[-103.33443062168348, 31.35618770719544]],
    [ "Collie Field Tap",[-103.4620137328883, 31.382639774584064]],
    [ "Collie Field Substation",[-103.46929645904817, 31.414645049371547]],
    [ "Quito Draw Substation",[-103.33821085000001, 31.3246916]],
    [ "Birds of Prey Tap",[-103.42372932049906, 31.37800235592279]],
    [ "Birds of Prey Substation",[-103.42679504999998, 31.374102699999998]],
    [ "Pyote Substation",[-103.09800244581264, 31.450603782579634]],
    [ "Stafford Boulevard Substation",[-103.49415181831841, 31.399987815808693]],
    [ "Barrilla Draw Substation",[-103.4307953616967, 31.257454260952155]],
    [ "Flat Top Substation",[-103.35951850000001, 31.199547199999998]],
    [ "Faulkner Substation",[-103.79318474999998, 31.26103105]],
    [ "Pecos Substation",[-103.498707104634, 31.429514548264454]],
    [ "IH 20 Substation",[-103.49097549503911, 31.399778563609246]],
    [ "Reeves County #4 Substation",[-103.62564069989585, 31.396450640756854]],
    [ "Bone Springs Substation",[-103.35568634745066, 31.53125761671433]],
    [ "Mi Vida Substation",[-103.45103082868205, 31.51527795453145]],
    [ "501745965",[-103.4613511, 31.523475249999994]],
    [ "Barstow Northwest Substation",[-103.47191839712059, 31.497302625845986]],
    [ "Cochise Substation",[-103.45206945137699, 31.51395336643742]],
    [ "Bone Springs Tap",[-103.4108055, 31.5446204]],
    [ "Lonestar Substation",[-103.24841045000001, 31.594275549999995]],
    [ "East Harrison Substation",[-97.66507847500712, 26.191412736946944]],
    [ "Oleander Substation",[-97.66455790901591, 26.206661319440027]],
    [ "Sun Valley Substation",[-102.52323012669487, 31.095544140713706]],
    [ "Masterson Field Substation",[-102.64411908336021, 31.106515446139717]],
    [ "Pecos Valley Substation",[-102.79474445000001, 31.096867749999998]],
    [ "Gomez Substation",[-103.02457502567964, 31.0369869970871]],
    [ "Fort Stockton Switching Station",[-103.10291772424718, 31.016752644008665]],
    [ "Fort Stockton Plant Substation",[-102.955182998848, 30.927301054535224]],
    [ "Fort Stockton Substation",[-102.8841656353922, 30.88938071381798]],
    [ "Airport Substation",[-102.91943335, 30.90271995]],
    [ "16th Street Substation",[-102.87325989230666, 30.900418090702274]],
    [ "Woodward Wind 1 Substation",[-102.48099336413976, 30.951575671028625]],
    [ "Woodward Wind 2 Substation",[-102.41694245938811, 30.980071651299514]],
    [ "Tombstone Substation",[-102.67900788017407, 31.002386556192018]],
    [ "White Baker Substation",[-102.42338436505524, 30.88536782751562]],
    [ "Ligon Substation",[-102.3723321344839, 30.833014067610588]],
    [ "Patriot Substation",[-102.46909065, 30.755455100000002]],
    [ "Keo Substation",[-102.36591496943674, 30.818419395019493]],
    [ "White Baker Substation",[-102.42332477037988, 30.886009815276392]],
    [ "Century Plant Substation",[-102.58085689999999, 30.607472050000005]],
    [ "Century Plant Substation",[-102.58153015, 30.607275899999998]],
    [ "503186907",[-102.71372826826487, 30.70191201525117]],
    [ "Sanderson Substation",[-102.4028883, 30.142016950000002]],
    [ "Texas-New Mexico Power",[-102.43655100000001, 30.23151265]],
    [ "Texas-New Mexico Power",[-102.44110043347528, 30.312270716794377]],
    [ "Texas-New Mexico Power",[-102.43739255, 30.398934250000007]],
    [ "Armadillo Substation",[-102.49096754999053, 30.46177356666426]],
    [ "Puckett Tap",[-102.60287778344761, 30.59131035593666]],
    [ "Puckett Substation",[-102.54834513291568, 30.62966157156317]],
    [ "Northern Natural Gas Substation",[-102.49386324999999, 30.672656549999996]],
    [ "Texas-New Mexico Power",[-102.50412016181413, 30.772731374649574]],
    [ "Belding Substation",[-103.06810039999999, 30.759643000000004]],
    [ "Bronco Substation",[-103.45375958726454, 30.49295318295389]],
    [ "Lasso Substation",[-103.47532925980217, 30.465004424762263]],
    [ "Altuda Substation",[-103.4380096, 30.2357894]],
    [ "Conoco Substation",[-102.9436870715301, 30.639260444657832]],
    [ "Persimmon Gap Substation",[-103.11742720000001, 29.7119088]],
    [ "Alpine Substation",[-103.52475380000001, 30.38946575]],
    [ "Paisano Camp Substation",[-103.80170097179322, 30.307008785139516]],
    [ "Fort Davis Substation",[-103.89855109999998, 30.5852055]],
    [ "Espy Wells Substation",[-103.92691955000001, 30.522414499999996]],
    [ "Kennedy Ponder Tap",[-103.9485785, 30.466998299999997]],
    [ "Valentine Tap",[-103.96379143783868, 30.414519324145644]],
    [ "Bryant Ranch Substation",[-104.19600636861549, 30.02520615926401]],
    [ "Acacia Substation",[-104.33445300000001, 29.592951350000003]],
    [ "Gonzales Substation",[-104.3557574, 29.566239500000002]],
    [ "Shafter Mine Substation",[-104.32500339251831, 29.821553922114006]],
    [ "Chinati Substation",[-104.27619793522082, 29.907415681564796]],
    [ "Cinega Substation",[-104.19666826844166, 30.024833397885]],
    [ "Long Loop Substation",[-101.99203008344864, 30.89558310383503]],
    [ "Santa Rita Substation",[-101.65607552791545, 31.228492892547933]],
    [ "Hoefs Road Substation",[-103.45841756845134, 31.031806617361806]],
    [ "Harlingen #1 Substation",[-97.70895884999999, 26.20678405]],
    [ "Big Lake Phillips Pump Substation",[-101.4680861751296, 31.092995394534878]],
    [ "Esmeralda Substation",[-101.30948067521692, 30.969601710643012]],
    [ "Yucca Substation",[-101.07732945000001, 30.9905365]],
    [ "Wolfberry Substation",[-101.38927835000003, 31.1094511]],
    [ "503607842",[-101.36629556703339, 31.108723847959876]],
    [ "Strauss Ranch Substation",[-101.46105484308579, 31.06008377113763]],
    [ "Midway Lane Substation",[-101.37878869999999, 30.908850499999996]],
    [ "Powell Field Substation",[-101.58495388791071, 31.022465825106835]],
    [ "Shell Powell Substation",[-101.57046466376119, 31.118183401010963]],
    [ "Humble Kemper Exxon Substation",[-101.53575252498833, 31.184994391766406]],
    [ "Jerry Substation",[-101.29938884999999, 31.229996200000002]],
    [ "Hargrove Substation",[-100.93897104999998, 31.308310699999996]],
    [ "Pumpjack Substation",[-101.0361695, 31.297373400000005]],
    [ "Mertzon Substation",[-100.84507372518546, 31.25971115861824]],
    [ "Tankersley Substation",[-100.64043985, 31.3447399]],
    [ "Mertzon Substation",[-100.8164491, 31.2578807]],
    [ "Barnhart Substation",[-101.16234520000002, 31.298178450000005]],
    [ "Barnhart Substation",[-101.17226809583198, 31.128094834044163]],
    [ "Rock Hollow Substation",[-101.2993487033908, 31.229461830570084]],
    [ "Ketchum Mountain Substation",[-101.03600745, 31.296938599999997]],
    [ "Newton T. Busby Substation",[-100.93921289999999, 31.3078086]],
    [ "Dow Chemical",[-96.76696115674447, 28.51253426836075]],
    [ "Doss Substation",[-99.13089435810032, 30.457878050742305]],
    [ "Harper Substation",[-99.24014446496167, 30.298966132564136]],
    [ "Goehmann Lane Substation",[-98.84334245, 30.261955649999997]],
    [ "Fredericksburg Phillips Substation",[-98.8472930684589, 30.471769457495956]],
    [ "Hollmig Substation",[-98.88203423433045, 30.252327802689848]],
    [ "Live Oak Substation",[-98.8881897249243, 30.265889494912987]],
    [ "West Harlingen Substation",[-97.72331709502167, 26.181101907209918]],
    [ "504308810",[-96.37573007745168, 32.00131721300316]],
    [ "Kingsland 1 Substation",[-98.45185074966886, 30.653352327164033]],
    [ "Kingsland 2 Substation",[-98.45789685831528, 30.67915600176877]],
    [ "Bluffton Substation",[-98.4814222, 30.826091799999993]],
    [ "Buchanan Substation",[-98.44808524982119, 30.73549175390042]],
    [ "Sunrise Beach Substation",[-98.44361121685951, 30.59114534925826]],
    [ "Pitsburg Substation",[-98.67743231860013, 30.76634680007803]],
    [ "Sandstone Mountain Substation",[-98.6107019, 30.73633635]],
    [ "Nebo Substation",[-98.74731265000001, 30.438892150000004]],
    [ "Lakeway Tap",[-97.94239740407112, 30.377713567413124]],
    [ "Lakeway Substation",[-97.94487453843087, 30.37209398338056]],
    [ "Marble Falls Substation",[-98.25775648119382, 30.55517132417985]],
    [ "Starcke Substation",[-98.26127670058231, 30.552372588860152]],
    [ "Flatrock Substation",[-98.20600496498659, 30.50978199736807]],
    [ "Granite Mountain Substation",[-98.29379836310636, 30.58802581321163]],
    [ "Buchanan Substation",[-98.41776181451927, 30.750777923742532]],
    [ "Lower Colorado River Authority",[-98.39254810657575, 30.688414424173793]],
    [ "Sherwood Shores Substation",[-98.38000118624855, 30.60975941015543]],
    [ "Inks Dam Substation",[-98.38565048973915, 30.73144686369541]],
    [ "Coronado Substation",[-98.41651896926136, 30.750037720896128]],
    [ "Fairland Substation",[-98.25818488037771, 30.645240478225983]],
    [ "Blewett Substation",[-100.0378963118378, 29.20023876300297]],
    [ "Andice Substation",[-97.87576970456176, 30.748965316997182]],
    [ "Burnet Substation",[-98.23738662786097, 30.759123507348235]],
    [ "Copperas Cove East Substation",[-97.8751910106406, 31.111904975892045]],
    [ "Copperas Cove Substation",[-97.90695070183416, 31.113206079201696]],
    [ "Lampasas Substation",[-98.18371275, 31.08451425]],
    [ "Copperas Cove Substation",[-97.96091235, 31.109775299999995]],
    [ "Kempner Substation",[-98.0539779983065, 31.091351222643038]],
    [ "Naruna Substation",[-98.19082519226397, 31.047671610274026]],
    [ "E. Babe Smith Substation",[-98.19821161236486, 30.99158142066395]],
    [ "Dobbyville Substation",[-98.26263489624458, 30.952548315612127]],
    [ "Graphine Mine Substation",[-98.35328180926204, 30.80657739699074]],
    [ "Whiting Substation",[-100.7956837, 29.839736499999997]],
    [ "Devils River Substation",[-100.7967421, 29.8394899]],
    [ "Lometa Substation",[-98.39079951207789, 31.21533292774107]],
    [ "San Saba Substation",[-98.69029666874309, 31.192002432983777]],
    [ "Goldthwaite Wind Substation",[-98.51189551842597, 31.414955280377008]],
    [ "Evant Substation",[-98.17315313995897, 31.43360237253303]],
    [ "Richland Springs Substation",[-98.93163702531504, 31.29539374537941]],
    [ "Terry Substation",[-98.67459505221775, 31.237343183546592]],
    [ "San Saba Switching Substation",[-98.67175340000001, 31.235923400000004]],
    [ "Zephyr Substation",[-98.86165708960662, 31.709453352545836]],
    [ "Mercers Gap Switching Station",[-98.66695225463754, 31.81772443514826]],
    [ "Camp Bowie Substation",[-98.96048475425874, 31.636183408723443]],
    [ "Brownwood Switching Station",[-98.92046652233361, 31.660781436841912]],
    [ "Logan's Gap Wind Substation",[-98.69434369999999, 31.803062699999998]],
    [ "Apex Clean Energy",[-101.18781640160435, 34.06060384992165]],
    [ "Hudson Substation",[-94.77155326213195, 31.330932352736937]],
    [ "Central Angelina Substation",[-94.77977447595791, 31.443097307334256]],
    [ "Proctor Substation",[-98.46717349999999, 31.95306895]],
    [ "Hasse Substation",[-98.50405951909384, 31.92445540558778]],
    [ "Comanche Substation",[-98.60547599007484, 31.89421103030286]],
    [ "Shiloh Substation",[-98.57288616157916, 31.89304127680142]],
    [ "Novice Substation",[-99.66929891901411, 31.98402841388252]],
    [ "Talpa Atlantic Substation",[-99.64222702921155, 31.73943005416777]],
    [ "Valera Substation",[-99.54746069999999, 31.758285700000002]],
    [ "Gouldbusk Substation",[-99.45796069999999, 31.558456399999997]],
    [ "Coleman Junction Substation",[-99.42688295330308, 31.74430025939915]],
    [ "Lake Ivie Booster Substation",[-99.78790275971419, 31.85541082108833]],
    [ "Lake Ivie Pump Substation",[-99.72146959999999, 31.57784415]],
    [ "Coleman Substation",[-99.40405483684042, 31.814542825095362]],
    [ "Lake Brownwood Substation",[-99.0910668557684, 31.766469962941088]],
    [ "Bangs Substation",[-99.127948929738, 31.73334992145115]],
    [ "Blanket Substation",[-98.79616895000001, 31.823759250000005]],
    [ "Brownwood Substation",[-98.97417272839724, 31.71860431268053]],
    [ "Lufkin Hill Street Substation",[-94.74639617475721, 31.349182890669276]],
    [ "Diboll Substation",[-94.79549601624561, 31.193806748845134]],
    [ "Whitney Substation",[-97.31941369981827, 31.950530175133633]],
    [ "Meridian Substation",[-97.67532402324362, 31.94948496487709]],
    [ "Walnut Springs Switching Station",[-97.7476982, 32.06926275]],
    [ "Energy Substation",[-98.37458719816543, 31.74260995004284]],
    [ "Cranfills Gap Substation",[-97.81674858668649, 31.785021449208028]],
    [ "Powell Substation",[-97.52193288170577, 31.889972662769164]],
    [ "Hico Substation",[-98.02824733225623, 31.98019694122619]],
    [ "Hico Switching Station",[-97.86494051677785, 31.957468851189624]],
    [ "Walnut Springs Switching Station",[-97.74783457138169, 32.06880391147913]],
    [ "Hamilton Substation",[-98.1092953, 31.71372455]],
    [ "Meridian Substation",[-97.65865508247342, 31.930388355072555]],
    [ "Valley Mills Substation",[-97.47237917991836, 31.654479197054208]],
    [ "Handley Substation",[-97.5523469080198, 31.730638895620633]],
    [ "Cliffton 2 Substation",[-97.590878722284, 31.786764025432742]],
    [ "Clifton 1 Substation",[-97.57191121802323, 31.786063698595424]],
    [ "Gatesville #1 Substation",[-97.73426524999999, 31.438222000000007]],
    [ "Gatesville #2 Substation",[-97.74646754999999, 31.439203499999998]],
    [ "Gatesville Substation",[-97.76265799179028, 31.503767032996663]],
    [ "Pancake Substation",[-97.797683852934, 31.535577662570468]],
    [ "Jonesboro Substation",[-97.89417422375703, 31.61517245610502]],
    [ "Hamilton Substation",[-98.11858252635892, 31.682336110821026]],
    [ "Indian Gap Substation",[-98.41899487978124, 31.662496511291668]],
    [ "Energy Substation",[-98.37411406998048, 31.74304080569011]],
    [ "Gustine Substation",[-98.3443222719058, 31.81804043896283]],
    [ "Friendship Substation",[-97.94521009338999, 30.166840658991926]],
    [ "Buda Substation",[-97.85113933179181, 30.075395142071805]],
    [ "Goforth Substation",[-97.84155262843059, 29.992567100329833]],
    [ "Rohr Substation",[-97.87887509999999, 29.935561899999996]],
    [ "Holly Plant Switchyard",[-97.71968882549508, 30.25089825873495]],
    [ "Harris Substation",[-97.73254847448803, 30.2882543848054]],
    [ "Fiesta Substation",[-97.71866142939847, 30.29497527742075]],
    [ "Bullick Hollow Substation",[-97.8569739503386, 30.40323104735791]],
    [ "McNeil Substation",[-97.68939031367697, 30.393132331640945]],
    [ "McNeil Substation",[-97.6891489941852, 30.39478372750937]],
    [ "Cameron Substation",[-97.68638842977245, 30.339642135812397]],
    [ "Daffan Gin Substation",[-97.61539899839715, 30.309642913148686]],
    [ "Paige Substation",[-97.13472012937518, 30.21446183135103]],
    [ "Butler Substation",[-97.32471537098827, 30.389468669057887]],
    [ "Smithville Substation",[-97.15800019544334, 30.020538132390843]],
    [ "Rosanky Substation",[-97.3113138590729, 29.929266914144947]],
    [ "Potranco Substation",[-98.65743441187993, 29.446159061994297]],
    [ "509640573",[-97.06692799273432, 30.566818433684634]],
    [ "Buckholts Substation",[-97.19452704702177, 30.85050884388073]],
    [ "Talbert Ridge Substation",[-96.96799303625379, 30.684480179804364]],
    [ "Rockdale Substation",[-97.01888990000002, 30.671068050000002]],
    [ "Rockdale North Substation",[-97.01916935000001, 30.67290555]],
    [ "Salty Substation",[-97.1326174, 30.596278700000003]],
    [ "Thorndale North Substation",[-97.21109295, 30.620047399999997]],
    [ "Coupland Substation",[-97.37355671176915, 30.462713621913032]],
    [ "Atkins Substation",[-96.3744342740371, 30.64656063823177]],
    [ "Briarcrest Substation",[-96.33732800435087, 30.65672006562317]],
    [ "Steele Store Substation",[-96.53273995, 30.63952425]],
    [ "Texas A&M Annex Substation",[-96.46477357007754, 30.65194798110233]],
    [ "Highway 6 Substation",[-96.13112105, 30.4404773]],
    [ "Wellborn Switching Station",[-96.25267149946991, 30.500803583745483]],
    [ "Millican Substation",[-96.21594214571284, 30.475612833012764]],
    [ "Millican Substation",[-96.20442952225218, 30.47284355694912]],
    [ "Koppe Bridge Substation",[-96.31119204999999, 30.5329126]],
    [ "Snook Substation",[-96.44977544017952, 30.53658438799326]],
    [ "Dowling Road Substation",[-96.33338988533774, 30.58574974225405]],
    [ "Southwood Valley Substation",[-96.31041091058256, 30.594014767970265]],
    [ "Sinclair Hearne Substation",[-96.58553815167593, 30.939926716778103]],
    [ "Calvert Substation",[-96.80203535, 31.008492600000004]],
    [ "Tesco Tap",[-96.47507015664388, 30.788849065391684]],
    [ "Humble Hearne Substation",[-96.5701929172223, 30.85654520303894]],
    [ "APL Hearne Substation",[-96.52115955000001, 30.885893149999998]],
    [ "Texas Hearne Substation",[-96.57906687719777, 30.862918107059386]],
    [ "Calvert Substation",[-96.6728186, 30.974692199999996]],
    [ "Phillips Coal Substation",[-96.67275379650837, 31.096501112039256]],
    [ "Brazos Electric",[-96.68649713291425, 31.04433380062239]],
    [ "Branchville Substation",[-96.76975615249832, 30.873417682623266]],
    [ "Milano Substation",[-96.8635207876269, 30.76357414331785]],
    [ "Fayette Plant #2 Substation",[-96.75614723114492, 29.91356619298677]],
    [ "La Grange Substation",[-96.8743391343879, 29.93277415568052]],
    [ "Riverside Substation",[-96.9199105285301, 29.927466435683144]],
    [ "Pilot Grove Substation",[-97.08812339827467, 29.3240497165377]],
    [ "Sweet Home Substation",[-97.0412718711186, 29.35804243261816]],
    [ "Mont Substation",[-97.00908217511954, 29.400989245706334]],
    [ "Hallettsville Substation",[-96.98760112487999, 29.429375804948776]],
    [ "Kokernut Substation",[-97.26920893561423, 29.46209949489455]],
    [ "Moulton Substation",[-97.13959512517343, 29.537302345838288]],
    [ "Weimar Substation",[-96.7836626781523, 29.683666718155358]],
    [ "Weimar Substation",[-96.90777192469024, 29.6723688923806]],
    [ "Plum Substation",[-97.01201659653728, 29.944068493142534]],
    [ "Winchester Power Park Substation",[-96.99492475000001, 30.0255218]],
    [ "Gillette Substation",[-97.72787342186889, 29.232743373673742]],
    [ "Lost Creek Substation",[-97.366214204571, 29.067893831311846]],
    [ "FM 237 Yorktown Substation",[-97.480950676334, 28.987976464705955]],
    [ "Yorktown Substation",[-97.49862059856544, 28.974789316432354]],
    [ "Gay Hill Substation",[-96.47165648197353, 30.271161694806906]],
    [ "Lyons Substation",[-96.5733238, 30.3942918]],
    [ "Deanville Substation",[-96.80120213716584, 30.427121120179514]],
    [ "Warda Substation",[-96.92083701537615, 30.050263736524386]],
    [ "Fayette River Pump Substation",[-96.77542400771354, 29.868447277181723]],
    [ "Frelsburg Substation",[-96.61589529424526, 29.8356957674492]],
    [ "Round Top Substation",[-96.71060217861807, 30.0778523527716]],
    [ "Sandy Hill Substation",[-96.32727525519435, 30.277990028699694]],
    [ "Mill Creek Substation",[-96.57974301153675, 30.1827727265007]],
    [ "511461911",[-96.49956440000001, 31.283766800000002]],
    [ "Kosse Substation",[-96.51120211318693, 31.30390100078616]],
    [ "Otto Junction Substation",[-96.78133639490288, 31.376948396435097]],
    [ "Marlin Substation",[-96.89449787823297, 31.314227412907194]],
    [ "Marlin South Substation",[-96.89051500000001, 31.294470999999998]],
    [ "Marlin South Tap",[-96.88008716785536, 31.28539389640535]],
    [ "Lott Substation",[-97.0316905, 31.198876249999998]],
    [ "Pleasant Grove Substation",[-96.90308453428521, 31.131446250358096]],
    [ "Midway Substation",[-96.99354165338391, 31.292882667907815]],
    [ "Ricardo Substation",[-97.77648248928521, 27.414054025283168]],
    [ "Riviera Substation",[-97.78921830000002, 27.332070550000005]],
    [ "Loyola Substation",[-97.81014836713535, 27.318535655257055]],
    [ "Marana Switchyard",[-97.529633831741, 27.10343771490135]],
    [ "Baffin Substation",[-97.59693591652807, 27.18400244871249]],
    [ "North Ella Substation",[-98.11244124315711, 27.528662472491533]],
    [ "Premont Substation",[-98.12602125000002, 27.383256900000003]],
    [ "Falfurrias Substation",[-98.14183923969331, 27.242300752491822]],
    [ "Crestonio Substation",[-98.59781775903109, 27.365385289192663]],
    [ "Las Pulgas Substation",[-97.7505798751096, 26.460976007505224]],
    [ "ThePalm Substation",[-97.67060634999999, 26.344929649999994]],
    [ "Trees Substation",[-97.5993593428372, 26.321515524876403]],
    [ "Red Fish Substation",[-97.69112355, 26.476043899999997]],
    [ "Central Avenue Substation",[-97.44552748701402, 25.953855719568125]],
    [ "San Roman Wind Substation",[-97.38646099865485, 26.093009943949117]],
    [ "East Rio Hondo Substation",[-97.53403530000001, 26.22752615]],
    [ "Cameron Wind Substation",[-97.46720446674153, 26.195641426442275]],
    [ "Paredes Switching Station",[-97.46618575657585, 26.19548722438657]],
    [ "Moore Field Substation",[-98.31531955653477, 26.359637877424916]],
    [ "Citrus City Substation",[-98.3512880904208, 26.32106484221293]],
    [ "Red Gate Substation",[-98.1771449, 26.449678400000003]],
    [ "Los Mirasoles Substation",[-98.44830929999999, 26.492620500000005]],
    [ "McCook 2 Substation",[-98.31239474942541, 26.620230032722414]],
    [ "McCook 1 Substation",[-98.16471241257864, 26.575605357498596]],
    [ "Faysville Substation",[-98.14027665, 26.406002050000005]],
    [ "Rachal Substation",[-98.16381305000002, 26.8901417]],
    [ "Goodwin Substation",[-98.4515775740502, 26.24529324934778]],
    [ "La Grulla Substation",[-98.6348839256463, 26.306536132163636]],
    [ "Garceno Substation",[-98.93676399783095, 26.41104086880357]],
    [ "Roma Substation",[-99.02176702026094, 26.422704589079597]],
    [ "Rio Grande City Substation",[-98.81938100178243, 26.392602947984685]],
    [ "Rio Grande City Substation",[-98.7422734922113, 26.452793772336413]],
    [ "Continental Substation",[-98.6099155249662, 26.51501549363354]],
    [ "North McAllen Substation",[-98.2387952094487, 26.24796519476784]],
    [ "McColl Road Substation",[-98.19403722238688, 26.309093406196517]],
    [ "Palmhurst Substation",[-98.23789516821631, 26.271971337560664]],
    [ "West Edinburg Substation",[-98.23411754667578, 26.317452458724894]],
    [ "Alton Substation",[-98.29280760000002, 26.26909225]],
    [ "Key Switching Station",[-98.29729374999998, 26.260856899999997]],
    [ "Palmhurst Substation",[-98.33386312749579, 26.261034491993243]],
    [ "Palmview Substation",[-98.36639767224364, 26.235191304881965]],
    [ "Bates Substation",[-98.39679155769183, 26.22108525985291]],
    [ "Frontera Substation",[-98.39713281027159, 26.217894477564545]],
    [ "Las Milpas Substation",[-98.19444015404787, 26.13928457016038]],
    [ "Stewart Road Substation",[-98.15495642757111, 26.125682094087946]],
    [ "Hi Line Substation",[-98.21398185000001, 26.1031852]],
    [ "Hidalgo Substation",[-98.25551733335325, 26.1117780405462]],
    [ "West McAllen Substation",[-98.25494438270103, 26.201370212722022]],
    [ "South McAllen Substation",[-98.2515199830352, 26.16053323349657]],
    [ "Bentsen Substation",[-98.29098847757719, 26.150788290652148]],
    [ "South Mission Substation",[-98.33355840075157, 26.209116858318204]],
    [ "Railroad DC Tie",[-98.32367248779907, 26.166898405193738]],
    [ "Taylor Road Substation",[-98.2808691, 26.176495049999996]],
    [ "Sharyland Substation",[-98.28433409302718, 26.203345772481864]],
    [ "Mayberry Substation",[-98.3214360911733, 26.188005672526398]],
    [ "El Gato Substation",[-98.09781125871109, 26.1676764749216]],
    [ "Doedyns Substation",[-98.14544184411702, 26.214650315523375]],
    [ "Sioux Substation",[-98.05777055565993, 26.208211975080335]],
    [ "Gandy Substation",[-98.00817110091951, 26.189517310781568]],
    [ "North Alamo Substation",[-98.1134081001872, 26.230990509159557]],
    [ "Alberta Switching Station",[-98.13573314484411, 26.250327872180485]],
    [ "Val Verde Substation",[-98.08043749999999, 26.234044100000006]],
    [ "Weslaco Switching Station",[-98.00879762039071, 26.229585314260706]],
    [ "Elsa Substation",[-98.01036347539603, 26.292696857510965]],
    [ "Aderhold Substation",[-98.08380432516452, 26.304189130638257]],
    [ "Southeast Edinburg Substation",[-98.14498974172977, 26.288121941807375]],
    [ "Azteca Substation",[-98.1618938, 26.322729050000007]],
    [ "Pharr Substation",[-98.17411985000001, 26.23735235]],
    [ "Polk Avenue Substation",[-98.19693954226415, 26.20611607251563]],
    [ "Hall Acres Road Substation",[-98.20518265200621, 26.16812704600759]],
    [ "Pharr Substation",[-98.14799604440594, 26.265408872107844]],
    [ "Merett Substation",[-98.17911127825491, 26.270689234436258]],
    [ "San Benito Substation",[-97.64300420816507, 26.143805215702496]],
    [ "Haine Drive Substation",[-97.6507811822631, 26.16719752410567]],
    [ "La Palma 69 kV Substation",[-97.64191411442094, 26.142237726046435]],
    [ "Rangerville Substation",[-97.78564275000001, 26.08718335]],
    [ "Rio Rico Substation",[-97.90048292175007, 26.091805157228773]],
    [ "Rangerville Substation",[-97.72317064070931, 26.119931757576524]],
    [ "Wesmer Substation",[-97.94227892542146, 26.137615334661792]],
    [ "Weslaco Unit Substation",[-98.00818211611403, 26.15483620147492]],
    [ "Heidelberg Substation",[-97.8875857752792, 26.22845388885608]],
    [ "Weslaco Substation",[-97.97546667913483, 26.23261412980704]],
    [ "South Santa Rosa Substation",[-97.82386182534982, 26.23128670641869]],
    [ "North Mercedes Substation",[-97.9118492, 26.23057505]],
    [ "North Weslaco Substation",[-97.99153254275953, 26.229652675269953]],
    [ "Waterport Substation",[-97.41260207502467, 25.95325205670168]],
    [ "Union Carbide Substation",[-97.37553400000002, 25.964496750000002]],
    [ "Coffee Port Substation",[-97.42630562811806, 25.938042071676588]],
    [ "Highway 511 Substation",[-97.40818267494072, 25.937539045361156]],
    [ "Olmito Substation",[-97.51981535, 26.00152885]],
    [ "Palo Alto Substation",[-97.51407409211156, 26.002139726697695]],
    [ "Titan Tire Substation",[-97.47794047620445, 25.994602145395017]],
    [ "Loma Alta Substation",[-97.3844901394572, 25.97732400684645]],
    [ "Union Carbide Substation",[-97.37513232725996, 25.968194658361142]],
    [ "Causeway Substation",[-97.2035131124943, 26.072813700505257]],
    [ "Port Isabel Substation",[-97.29968197416346, 26.09470369171631]],
    [ "Laureles Substation",[-97.5472631, 26.122598049999997]],
    [ "Los Fresnos Substation",[-97.5232474659305, 26.07678036715411]],
    [ "Brownsville Switching Station",[-97.53279940704573, 25.941137486627234]],
    [ "Brownsville Substation",[-97.5049096517234, 25.938512439586464]],
    [ "Military Highway Substation",[-97.53569027540742, 25.936813173327547]],
    [ "Laredo Road Substation",[-97.53283272138658, 25.940972176504697]],
    [ "Villa Cavazos Substation",[-97.59771875, 26.0219992]],
    [ "Sixth Street Substation",[-97.49766196540008, 25.909784646321175]],
    [ "Fort Brown Substation",[-97.46813782686903, 25.899352055048134]],
    [ "Filter Plant Substation",[-97.46535167056112, 25.925359645839272]],
    [ "Southmost Substation",[-97.42490643989537, 25.888434968692636]],
    [ "Midtown Substation",[-97.50557135000001, 25.938485000000004]],
    [ "Price Road Substation",[-97.47966803868793, 25.934529990472026]],
    [ "FM 802 Substation",[-97.49211435948823, 25.95094550197634]],
    [ "Power Plant Substation",[-97.52252290039725, 25.913597849773073]],
    [ "Raymondville #2 Substation",[-97.75214822286252, 26.481596670426104]],
    [ "Armstrong Substation",[-97.78965838195298, 26.927203821529616]],
    [ "East Raymondville Substation",[-97.71543039999999, 26.46710905]],
    [ "La Sara Substation",[-97.84683657477844, 26.451281692514634]],
    [ "Raymondville #1 Substation",[-97.7817735602057, 26.437568484365375]],
    [ "Live Oak Substation",[-97.09368782447919, 28.032273588735684]],
    [ "Fulton Substation",[-97.06417077465746, 28.04670059116459]],
    [ "Rockport Substation",[-97.14208063182276, 27.977592138635742]],
    [ "Seawall Substation",[-97.14537665000002, 27.899933250000007]],
    [ "Port Aransas Substation",[-97.0719142457216, 27.820554224964596]],
    [ "Aransas Pass Substation",[-97.18175520577347, 27.926867180320844]],
    [ "Homeport Substation",[-97.20955000754947, 27.827873788733694]],
    [ "Ingleside Substation",[-97.21684574167693, 27.872111535515007]],
    [ "Ingleside Oxy Substation",[-97.2417822806047, 27.88056731020266]],
    [ "Air Liquide Substation",[-97.23991437520792, 27.879804693015412]],
    [ "voestalpine",[-97.28068143650198, 27.89095127792032]],
    [ "American Electric Power",[-97.27747604999999, 27.891667499999997]],
    [ "Hecker Substation",[-97.28290926404713, 27.904878349179523]],
    [ "Occidental Petroleum",[-97.24606487518092, 27.88575404453471]],
    [ "Ingleside Cogeneration Switchyard",[-97.24661394618454, 27.882027863535463]],
    [ "Reynolds Sherwin Substation",[-97.25741754999999, 27.8841671]],
    [ "Gregory Switchyard",[-97.25752869981147, 27.8889286078185]],
    [ "Dupont PP1 Substation",[-97.24746000000002, 27.88038535]],
    [ "McCampbell Substation",[-97.2686112896208, 27.915083831256492]],
    [ "Gregory Substation",[-97.3092544173512, 27.923299706019858]],
    [ "Portland Substation",[-97.32242756999652, 27.887727909950257]],
    [ "West Sinton Substation",[-97.64242420901246, 28.026522727683993]],
    [ "West Sinton Switching Station",[-97.64749072235657, 27.998391106994998]],
    [ "Bonnieview Substation",[-97.31358332587975, 28.184556341305232]],
    [ "Medio Creek Substation",[-97.33990671435437, 28.253422827142362]],
    [ "Haisley Substation",[-97.49359997490217, 28.09494340807194]],
    [ "Refugio Substation",[-97.34493584999998, 28.349435049999997]],
    [ "Tatton Substation",[-96.98999384999999, 28.209544550000004]],
    [ "Refugio Substation",[-97.2664901653485, 28.301213639653227]],
    [ "Heard Substation",[-97.29659850634916, 28.279050716760562]],
    [ "Woodsboro Substation",[-97.2940298, 28.234888600000005]],
    [ "Bonnieview Substation",[-97.30304428948568, 28.155429045275074]],
    [ "Weser Substation",[-97.36760021471488, 28.83195424321777]],
    [ "Charco Substation",[-97.6164676978892, 28.753560029056537]],
    [ "Schroeder Substation",[-97.22400353802371, 28.822392570747077]],
    [ "Big Oak Substation",[-97.64188901669968, 28.558739199744494]],
    [ "Goliad Substation",[-97.3838348, 28.662183399999996]],
    [ "Aloe Substation",[-97.07032222255675, 28.7803034888454]],
    [ "Airco Substation",[-96.79594975797384, 28.50990249724168]],
    [ "Greta Substation",[-97.19243535000001, 28.38209505]],
    [ "Warburton Substation",[-97.04744485598893, 28.59831347918109]],
    [ "McFadden Substation",[-97.05848967973674, 28.554995147373894]],
    [ "515520140",[-106.32932925, 31.8066205]],
    [ "Placedo Substation",[-96.859755, 28.668779699999998]],
    [ "515589081",[-96.95306294681373, 28.680550882964805]],
    [ "515589103",[-96.95865104424063, 28.675548920549033]],
    [ "515589134",[-96.95498475, 28.682310100000002]],
    [ "Formosa Plastics CFB Substation",[-96.53999896459565, 28.65124289588374]],
    [ "Placedo Substation",[-96.82154464999999, 28.7313127]],
    [ "Kamey Substation",[-96.75333385, 28.65629535]],
    [ "Point Comfort Substation",[-96.55815290000002, 28.6738099]],
    [ "Weaver Road Substation",[-96.94861610000001, 28.701595850000007]],
    [ "515589354",[-96.94848974721614, 28.68179963295112]],
    [ "Big Three Substation",[-96.91402656701146, 28.65138024672277]],
    [ "Port Lavaca Substation",[-96.68472778574842, 28.586824830047902]],
    [ "Chocolate Bayou Substation",[-96.91922436403662, 28.685527780535246]],
    [ "Dow Chemical",[-96.77084358116876, 28.506678386925252]],
    [ "Dow Chemical",[-96.76635316886282, 28.509693237041326]],
    [ "Dow Chemical",[-96.76544330508932, 28.51022483303115]],
    [ "Dow Chemical",[-96.76688356193685, 28.516122178284856]],
    [ "Port O'Connor Substation",[-96.46302649999998, 28.4311979]],
    [ "Seadrift Substation",[-96.66776205000001, 28.41669375]],
    [ "Union Carbide Substation",[-96.77414007069139, 28.514042578205107]],
    [ "Twin Pines Switching Station",[-96.97080105169726, 28.854760033354953]],
    [ "Sam Rayburn Switchyard",[-97.13518023375296, 28.894004254842436]],
    [ "Sam Rayburn Switchyard",[-97.13414318245675, 28.894355631667352]],
    [ "Highlands Substation",[-96.9954508, 28.893276500000002]],
    [ "Lone Tree Substation",[-96.88313463382023, 28.800595656818732]],
    [ "North Victoria Substation",[-96.98350068046032, 28.80706144613781]],
    [ "Leary Lane Substation",[-96.97919151271272, 28.84291168633006]],
    [ "Foster Field Substation",[-96.92968715, 28.819506199999996]],
    [ "Lone Tree Substation",[-96.9425218, 28.8204895]],
    [ "Twin Pines Substation",[-96.97319909999999, 28.846205799999993]],
    [ "Magruder Substation",[-97.00993575168694, 28.84129919462978]],
    [ "Victoria Switching Station",[-97.010447239058, 28.78674545809202]],
    [ "515727661",[-96.83585291932877, 32.41998601566276]],
    [ "Formosa Plastics",[-96.55334875, 28.793088299999997]],
    [ "Van Humble Substation",[-96.63067699999999, 28.803830599999998]],
    [ "Energy Transfer Partners",[-96.53717007545272, 29.10825410346745]],
    [ "ETP Substation",[-96.53602270319413, 29.10824244947233]],
    [ "Cordele Substation",[-96.55411022109601, 29.07803165692604]],
    [ "Vanderbilt Substation",[-96.64568630000001, 28.806508449999995]],
    [ "Louise Substation",[-96.45404001291328, 29.1045438730152]],
    [ "Ganado Substation",[-96.48803995, 29.043464800000006]],
    [ "Edna Substation",[-96.63793834878106, 29.004464692437445]],
    [ "El Toro Switching Station",[-96.72626710490474, 28.960804016997187]],
    [ "El Toro Substation",[-96.70847649999997, 28.960233750000004]],
    [ "Inez Substation",[-96.80394131355492, 28.90865053971003]],
    [ "Fordtran Substation",[-97.0221122335159, 29.105132133416774]],
    [ "Nusery Substation",[-97.09648815497756, 28.92261584449914]],
    [ "Celanese Bay City Substation",[-96.01552766279111, 28.86796268901592]],
    [ "Wadsworth Substation",[-95.93797343781507, 28.785068883464078]],
    [ "Conoco Substation",[-95.9386648048534, 28.784294096974442]],
    [ "515869179",[-95.94386265847326, 28.784098921249466]],
    [ "Bay City Substation",[-95.90375367397323, 28.978267612877968]],
    [ "Tin Top Substation",[-96.1254199441149, 28.772410444088123]],
    [ "Carancahua Substation",[-96.31347807746668, 28.757665774500968]],
    [ "Olivia Substation",[-96.45300615000001, 28.689074150000007]],
    [ "South Texas Project Pump Substation",[-95.9981796, 28.77531685]],
    [ "Palacios Substation",[-96.20139649999997, 28.756915399999997]],
    [ "Danevang Substation",[-96.19232477687746, 29.064735676618344]],
    [ "Midfield West Substation",[-96.25694595947033, 29.01309422472693]],
    [ "Danevang Substation",[-96.21518155, 29.0780219]],
    [ "Red Bluff Substation",[-96.52487844999999, 28.88639735]],
    [ "Tenaris Substation",[-95.9120064, 28.993088200000003]],
    [ "Bay City Pump Substation",[-96.01188979999999, 28.9797729]],
    [ "Magill Substation",[-95.96928056191942, 29.01234934272809]],
    [ "Lane City Pump Substation",[-96.0716946, 29.193341249999996]],
    [ "Round Mott Substation",[-96.41719916441711, 29.23007602480316]],
    [ "Industrial Park Substation",[-96.25137825622556, 29.206025250101657]],
    [ "Painview Substation",[-96.22726303968508, 29.143909675325947]],
    [ "El Campo Substation",[-96.26880000972876, 29.23512328076939]],
    [ "Ricebird Substation",[-96.2784707829625, 29.233941551303083]],
    [ "Hillje Substation",[-96.35552325, 29.190966900000003]],
    [ "Van Vleck Substation",[-95.8975775665848, 29.01920464919961]],
    [ "Clemville Substation",[-96.15057281098964, 28.997804424794836]],
    [ "Clemville South Substation",[-96.14307722526893, 28.98340846471845]],
    [ "Salt Dome Substation",[-96.14170941497468, 28.993480954465124]],
    [ "Tres Palacious Substation",[-96.14090557324013, 29.001083416673126]],
    [ "Clemville North Substation",[-96.13315725000001, 29.010867400000002]],
    [ "Clemville Switching Station",[-96.13346132484497, 29.027942142419825]],
    [ "Rainy Creek Substation",[-99.70084684550814, 32.502308466242916]],
    [ "Abilene Aileen Substation",[-99.70020035, 32.488663700000004]],
    [ "Walnut Street Substation",[-99.72956817245043, 32.473519124692665]],
    [ "Vogel Street Substation",[-99.75862474999998, 32.47905179999999]],
    [ "Maple Street Substation",[-99.72570682507099, 32.404160545443055]],
    [ "Elmdale Substation",[-99.63921694999999, 32.4348783]],
    [ "Abilene East Switching Station",[-99.69801852502627, 32.45331090735242]],
    [ "Abilene East Substation",[-99.69976626887703, 32.45279975693388]],
    [ "Country Club Substation",[-99.74304359997012, 32.4021549776888]],
    [ "Over Street Substation",[-99.75675321789237, 32.41801248483562]],
    [ "Abilene McMurry Substation",[-99.75293024999999, 32.432]],
    [ "Shelton Street Substation",[-99.75442290879593, 32.45272027520805]],
    [ "Hartford Street Substation",[-99.78530265000002, 32.43366335]],
    [ "Dyess Air Force Base #1 Substation",[-99.84484617137622, 32.437868288200804]],
    [ "Dyess Air Force Base #2 Substation",[-99.83504465, 32.414512949999995]],
    [ "Dyess Air Force Base #3 Substation",[-99.81898732503367, 32.41746793964743]],
    [ "Abilene Oil Mill Substation",[-99.72803179999998, 32.439331499999994]],
    [ "Vinson Substation",[-99.7000027, 32.35952665]],
    [ "West Texas Gulf Substation",[-99.71742735, 32.38790805000001]],
    [ "Church Substation",[-99.52550875, 32.367143850000005]],
    [ "Estes Substation",[-99.52563726006893, 32.36586547386163]],
    [ "Potosi Substation",[-99.62308752487264, 32.32904684292301]],
    [ "Holder Substation",[-98.97846783979685, 31.913509883475903]],
    [ "Rising Star Substation",[-98.97270162508016, 32.09445305522806]],
    [ "Cross Plains Substation",[-99.16872763808155, 32.12180427460997]],
    [ "Dressey Substation",[-99.22792075, 32.10572465]],
    [ "Paducah South Substation",[-100.32695115, 33.980251200000005]],
    [ "Paducah City Substation",[-100.29937417516298, 34.01469618783368]],
    [ "Paducah Clare Street Substation",[-100.29927576259124, 34.00518852492835]],
    [ "Anson Substation",[-99.89127772003046, 32.75835216141797]],
    [ "Anson Substation",[-99.87674939251202, 32.7370824249507]],
    [ "Hawley Humble-Exxon Substation",[-99.80921708808432, 32.63168022924153]],
    [ "Whiteacre Substation",[-99.7667850791252, 32.58027135690805]],
    [ "Paint Creek Substation",[-99.57957216721601, 33.079730695032566]],
    [ "Nugent Substation",[-99.67484508512617, 32.69371577562329]],
    [ "California Creek Substation",[-99.55698289650773, 33.07210223274367]],
    [ "Abilene Onyx Substation",[-99.77087420000001, 32.54688835]],
    [ "Hubbard Booster #1 Substation",[-99.41175936756078, 32.65885028623969]],
    [ "Fort Phantom Clear Fork Pump Substation",[-99.69158503226673, 32.610580593705635]],
    [ "Fort Phantom East Pump Substation",[-99.68025321306081, 32.59650662401946]],
    [ "Roberts Prison Substation",[-99.63972360896598, 32.55456262492952]],
    [ "Fort Phantom Switchyard",[-99.68234397953923, 32.58259479411314]],
    [ "Neas Substation",[-99.68823585000001, 32.53223534999999]],
    [ "Horton Substation",[-99.68636695, 32.53258455]],
    [ "Post Oak Substation",[-99.53867845, 32.5418329]],
    [ "Mesquite Substation",[-99.54042002562035, 32.62877300375886]],
    [ "Nimrod Substation",[-99.01055770768284, 32.27173844161529]],
    [ "Rising Star Substation",[-98.88324966661801, 32.09792448361914]],
    [ "Wright Substation",[-98.1911190651656, 32.26291244222513]],
    [ "Norfork Substation",[-98.22362620000001, 32.243128250000005]],
    [ "Carlton Substation",[-98.19021322213011, 31.98350887140552]],
    [ "Mountary Substation",[-98.4318283, 32.075523]],
    [ "Downing Substation",[-98.55175590858858, 32.020958223593034]],
    [ "Stephenville Substation",[-98.23284502954401, 32.205200212434825]],
    [ "Selden Substation",[-98.1572758614559, 32.186770466835924]],
    [ "Johnsville Substation",[-97.94551509766977, 32.12186259351]],
    [ "518402372",[-96.64976783015788, 33.208984980320785]],
    [ "518432626",[-96.63732534106883, 33.18898582435973]],
    [ "518592656",[-96.71005547124504, 33.036189659191336]],
    [ "Hubbard Booster #2 Substation",[-99.22464435, 32.712255899999995]],
    [ "Albany Substation",[-99.29264541213804, 32.72363997520214]],
    [ "Albany Foundry Substation",[-99.23481764727154, 32.665385816243045]],
    [ "Moran Substation",[-99.16337495, 32.54635235]],
    [ "Putnam Substation",[-99.20151851444415, 32.37192966916988]],
    [ "Baird City Substation",[-99.38662076773223, 32.40933671552421]],
    [ "Putnam Switching Station",[-99.19407121577095, 32.383660865793004]],
    [ "Clyde Substation",[-99.5044195363411, 32.415670824737106]],
    [ "Denton Valley Substation",[-99.53945187516366, 32.257552395233645]],
    [ "Clyde Magnolia Substation",[-99.5895703, 32.35795615000001]],
    [ "518658542",[-96.68689730308549, 33.0042726116113]],
    [ "McAdams Substation",[-100.02578944339058, 33.98389367339591]],
    [ "Crowell Substation",[-99.72277636043182, 33.982294225162754]],
    [ "Copper Breaks Substation",[-99.73868174591404, 34.16166071767512]],
    [ "Throckmorton Woodson Oil Substation",[-99.23300950086731, 33.22085685540757]],
    [ "Truscott Humble Substation",[-100.04793140000001, 33.77014475000001]],
    [ "Benjamin Substation",[-99.80096890000002, 33.58457855]],
    [ "Truscott City Substation",[-99.8101996, 33.748730450000004]],
    [ "Munday Substation",[-99.66038228668889, 33.448296225259995]],
    [ "Weinert Substation",[-99.67101683683926, 33.324540024616745]],
    [ "Bush Knob Substation",[-99.17865915, 33.10529879999999]],
    [ "Throckmorton Substation",[-99.17859837495723, 33.17842059026458]],
    [ "Vertigo Wind Substation",[-99.42139446687699, 33.64463568382114]],
    [ "Foxtail Switching Station",[-99.42079397815495, 33.646314396633194]],
    [ "Baker Ranch Substation",[-99.5891473331869, 33.369874319697864]],
    [ "Corinth Substation",[-99.8687297, 32.855097799999996]],
    [ "Stamford Substation",[-99.80951041334447, 32.94387653085797]],
    [ "Haskell Substation",[-99.74785984999998, 33.12309255]],
    [ "Haskell Substation",[-99.729046, 33.15531825]],
    [ "Josselett Substation",[-99.69597486986942, 33.25875230380561]],
    [ "Haskell Texas Pipeline Substation",[-99.60692809194694, 33.290172022631765]],
    [ "Aspermont Substation",[-100.22624348097098, 33.139569686383894]],
    [ "Swenson Substation",[-100.31170724191229, 33.18262387849542]],
    [ "Peacock Substation",[-100.39152169999997, 33.176361199999995]],
    [ "Jayton Substation",[-100.62640687521979, 33.270912844187286]],
    [ "Girard Substation",[-100.66480200000001, 33.359759000000004]],
    [ "Jayton Substation",[-100.5729501, 33.2459049]],
    [ "Clairemont Substation",[-100.86737165000001, 33.2065035]],
    [ "Clairemont Substation",[-100.76572099088261, 33.16045547492769]],
    [ "Wolfgang Switching Station",[-100.52604944602547, 33.00314713888494]],
    [ "Mozart Substation",[-100.53163815000002, 32.99423655]],
    [ "Rotan Substation",[-100.45380281936777, 32.839199064121146]],
    [ "Roby Substation",[-100.37783132903228, 32.748656404792776]],
    [ "Longworth Substation",[-100.35835795, 32.6718767]],
    [ "Hamlin Texas Pipeline Substation",[-100.22778181113227, 32.93513842521942]],
    [ "Aspermont Continental Oil Substation",[-100.19625839999999, 33.088656650000004]],
    [ "Hamlin Shell Pump Substation",[-100.1549071, 32.973239050000004]],
    [ "Hamlin Substation",[-100.09863268928174, 32.8923611167131]],
    [ "Hamlin Substation",[-100.12133116798068, 32.88926246995737]],
    [ "Hamlin Plasterco Substation",[-100.18402575, 32.837601250000006]],
    [ "Round Top Substation",[-100.26482562514457, 32.86616460627291]],
    [ "Rule Substation",[-99.88996391103359, 33.18926090788163]],
    [ "Gillespie Substation",[-99.75178441840606, 33.420316085295674]],
    [ "Knox City Substation",[-99.818760215894, 33.42231402497357]],
    [ "Pointer Switching Station",[-99.83339449999998, 33.40363885]],
    [ "Rochester Substation",[-99.85678267503287, 33.317102491704546]],
    [ "Aspermont Substation",[-100.23347347541515, 33.17797039040479]],
    [ "Bissett Substation",[-100.20994400881172, 33.351663506455935]],
    [ "King Substation",[-100.15739952912524, 33.51889126321695]],
    [ "Katz Substation",[-100.01040060090959, 33.39726751085899]],
    [ "Knox Substation",[-99.89146061351708, 33.388942175050566]],
    [ "Glidden Substation",[-96.57272485964442, 29.69641281969193]],
    [ "Bernardo Substation",[-96.39909719998955, 29.754103555662166]],
    [ "Sky Global Power One Substation",[-96.53847433254617, 29.550284707223778]],
    [ "Rock Island Substation",[-96.5378976144644, 29.550054159709457]],
    [ "Sheridan Substation",[-96.66656411050478, 29.49721557946537]],
    [ "Colorado Substation",[-96.61983943772353, 29.467175913120894]],
    [ "Garwood Ideal Substation",[-96.4533066223188, 29.494924761863416]],
    [ "Pioneer Substation",[-96.45962835, 29.50484685]],
    [ "Garwood Pump Substation",[-96.4090011623061, 29.5156831790842]],
    [ "B&B Gravel Substation",[-96.4161571, 29.520159999999997]],
    [ "Garwood Relift Substation",[-96.46521438154548, 29.514419006443134]],
    [ "Garwood Lone Star Substation",[-96.34649779200777, 29.419677069748015]],
    [ "Eagle Lake Substation",[-96.33274071055445, 29.58005449253597]],
    [ "Prairie Pump Substation",[-96.37849084415488, 29.602354060254754]],
    [ "Parker Substation",[-96.38411859901136, 29.571937389165477]],
    [ "Garwood City Substation",[-96.39192032522735, 29.44778625683169]],
    [ "Matthews Substation",[-96.33269565, 29.506844599999994]],
    [ "Lakeside Pump Substation",[-96.33472370845429, 29.55918191953024]],
    [ "Columbus Substation",[-96.54283017513211, 29.698376362082836]],
    [ "Stafford Hill Substation",[-96.5156714744155, 29.649850388678814]],
    [ "Riverside Pump Substation",[-96.40179640000001, 29.570193549999995]],
    [ "Altair Substation",[-96.46245916809077, 29.56426463623392]],
    [ "Nada Substation",[-96.38704007565478, 29.39206513620411]],
    [ "Mockingbird Substation",[-96.57369026240366, 29.701245940514585]],
    [ "Bryan Substation",[-95.37604042185458, 28.919510976869926]],
    [ "Texas Gulf Sulfur Substation",[-95.90057615211775, 29.263812542946624]],
    [ "Caney Creek Substation",[-96.1186196708672, 29.3247024823182]],
    [ "Colorado Bend Substation",[-96.06890997381902, 29.28917819289411]],
    [ "Colorado Bend 2 Substation",[-96.06463820000002, 29.287628800000004]],
    [ "Bailey Substation",[-95.99218197492263, 29.185384377115284]],
    [ "Seadoc Substation",[-95.4346483, 28.952187799999997]],
    [ "Seaway Substation",[-95.43272623121369, 28.94882060220665]],
    [ "Franklins Camp Substation",[-95.56390781712587, 28.928173376058318]],
    [ "Sargent Substation",[-95.66777554979748, 28.87217212983194]],
    [ "Camden Substation",[-95.35739165, 28.988132300000004]],
    [ "521418873",[-97.109167653904, 33.19936056733293]],
    [ "Einstein Substation",[-101.52060696421931, 31.769594481681544]],
    [ "Endeavor Substation",[-101.97555634049309, 31.817204575135328]],
    [ "Pronghorn Substation",[-101.97325967051465, 31.81772024593649]],
    [ "521837721",[-101.92315344784151, 31.959378632267818]],
    [ "Blue Acre Substation",[-101.92637910610847, 31.95799185777024]],
    [ "Retrieve Substation",[-95.49277315, 29.099848950000002]],
    [ "Dow Chemical",[-95.3941055741913, 28.987516614915]],
    [ "Dow Chemical",[-95.40744301865934, 28.99050340465812]],
    [ "Dow Chemical Company",[-95.35822392674507, 28.985146330701184]],
    [ "Copper Substation",[-95.40512724999999, 29.0067586]],
    [ "522624047",[-95.40596509999999, 29.003657]],
    [ "BASF Substation",[-95.396616131408, 29.001687615638517]],
    [ "Cortez Substation",[-95.32400587833803, 28.9248601664975]],
    [ "Quintana Substation",[-95.31783436519288, 28.93222497791326]],
    [ "Marine Substation",[-95.33971642541732, 28.933494558501703]],
    [ "Sintek Substation",[-95.3573375647033, 28.992280706218494]],
    [ "Dow Chemical Company",[-95.34161153328856, 28.97867645053179]],
    [ "Dow Chemical Company",[-95.31674679607889, 28.955663574091982]],
    [ "Dow Chemical Company",[-95.3499989076349, 28.975917440745835]],
    [ "Dow Chemical",[-95.39374787771943, 28.985600732258042]],
    [ "Booster Substation",[-95.39172769347532, 28.96285841305857]],
    [ "Jones Creek Substation",[-95.38111324337716, 28.957689064432405]],
    [ "524729806",[-97.09269756192353, 33.225535066642514]],
    [ "Gorman Substation",[-98.66018595680062, 32.22165350194221]],
    [ "De Leon Substation",[-98.53508634587635, 32.117982826844816]],
    [ "De Leon Magnolia Substation",[-98.46986720000001, 32.072412299999996]],
    [ "Leon Switching Station",[-98.76883700040666, 32.37397325174614]],
    [ "Leon EBAA Substation",[-98.76909554999999, 32.37285275]],
    [ "Flat Creek Switching Station",[-98.47454518064876, 32.265233787847215]],
    [ "Silver Star Wind Substation",[-98.46577181846146, 32.3310758856611]],
    [ "Lingleville Substation",[-98.46836295850902, 32.260812840670816]],
    [ "533178583",[-96.19777075199751, 32.32290018317825]],
    [ "533856032",[-96.1046391009333, 32.35729324376384]],
    [ "533856034",[-96.05474260380521, 32.33496875890258]],
    [ "535135590",[-96.834387, 32.44141215]],
    [ "535550671",[-95.711990964914, 32.334847898737685]],
    [ "535902917",[-95.8366062397045, 32.231089721831246]],
    [ "537502106",[-96.5052233746629, 33.192416252933285]],
    [ "538117207",[-96.01496939632415, 32.184896421410286]],
    [ "542621769",[-96.61482115115817, 33.21825967412265]],
    [ "543970691",[-96.71571691605197, 33.021821675056025]],
    [ "549848492",[-96.86318463166694, 32.26452050575802]],
    [ "549848494",[-96.7558754860013, 32.2978996624227]],
    [ "549998149",[-96.89273714999999, 32.260005150000005]],
    [ "550156480",[-96.82753049999998, 32.60169945]],
    [ "550174108",[-96.78156521003713, 32.58588835867459]],
    [ "550186234",[-96.13511416816449, 33.11279613459277]],
    [ "Clear Lake Substation",[-95.12648454936017, 29.60836550055782]],
    [ "561577219",[-97.5466872313373, 33.17787634629455]],
    [ "Diekemper Substation",[-101.40779769999997, 33.218780249999995]],
    [ "Garza Substation",[-101.3904977612296, 33.1862720813783]],
    [ "Graham Interchange",[-101.40805969238116, 33.18667272746106]],
    [ "564425902",[-95.56857351105536, 33.65500023031321]],
    [ "Huntington Substation",[-94.5742707750347, 31.268900103927095]],
    [ "Nacogdoches North Substation",[-94.65420832408566, 31.651657725152152]],
    [ "568594069",[-94.17890109458665, 31.760348669006742]],
    [ "568601937",[-94.1710940461496, 31.802862517697392]],
    [ "568601938",[-94.17162092382364, 31.801737415505382]],
    [ "570716969",[-98.97917050694552, 32.39117503005316]],
    [ "Security Substation",[-95.29483465754264, 30.322129232556918]],
    [ "Jayhawker Creek Company Substation",[-95.15877406460717, 30.3223271711689]],
    [ "Cedar Hill Substation",[-95.40490495, 30.226777950000006]],
    [ "Mallard Substation",[-95.35185154999999, 30.265976450000004]],
    [ "Forest Substation",[-95.4474475, 30.206132349999997]],
    [ "Crystal Substation",[-95.38712878458956, 30.321199625176423]],
    [ "Sheawill Substation",[-95.45386552752583, 30.425591749098654]],
    [ "Caney Creek Substation",[-95.45329691012617, 30.426782554918304]],
    [ "Peach Creek Substation",[-95.32811685, 30.41587485]],
    [ "LNG Substation",[-95.52213577031283, 30.444082953255712]],
    [ "Lewis Creek 230 kV Substation",[-95.52011525, 30.440082900000004]],
    [ "Lacon Substation",[-95.52246285548972, 30.437841718143908]],
    [ "Longmire Substation",[-95.50775795001626, 30.33699966719899]],
    [ "Woodhaven Substation",[-95.55620124070259, 30.31947593974422]],
    [ "Panorama Substation",[-95.51752660096228, 30.374674112598697]],
    [ "Egypt Substation",[-95.51507235, 30.39222105]],
    [ "Ponderosa Substation",[-95.4927416, 30.29927295]],
    [ "Deer Lake Substation",[-95.62534753668702, 30.311433276216842]],
    [ "Sandy Substation",[-95.72722569439362, 30.59747702320733]],
    [ "Springwood Substation",[-95.44038817571416, 30.086205764081246]],
    [ "Marconi Substation",[-97.39712211284119, 26.092120474964883]],
    [ "Klemp Substation",[-94.90840834420577, 30.03369589061357]],
    [ "Exposaic Substation",[-94.90871105, 30.03650765]],
    [ "Rolke Road Substation",[-94.89802351369005, 30.03200450427411]],
    [ "Entergy",[-94.8905431253643, 30.03549613493358]],
    [ "Huffman Substation",[-94.99553823514003, 30.104094895049343]],
    [ "Eastgate Substation",[-95.01299186666665, 30.050553266666665]],
    [ "Apollo Substation",[-95.1447786237327, 30.180938611298732]],
    [ "Johnstown Substation",[-95.2870343, 30.214887399999995]],
    [ "Entergy",[-95.25300892945273, 30.201132143339464]],
    [ "576608785",[-102.9673923, 31.52340665]],
    [ "Facebook Data Center",[-97.26168235876392, 32.98572170772219]],
    [ "Amelia North Substation",[-94.23464645, 30.079219600000005]],
    [ "New Long John Substation",[-94.9254239, 30.120014450000003]],
    [ "Tarkington Substation",[-94.97116727637871, 30.23904736333718]],
    [ "Splendora Substation",[-95.13886264163486, 30.2188476138933]],
    [ "Cleveland Substation",[-95.0862111635566, 30.329094185735308]],
    [ "Batiste Creek Substation",[-94.681619254222, 30.232893409485143]],
    [ "China Substation",[-94.3739304727433, 30.045397072102748]],
    [ "579500911",[-94.4901240248568, 29.935147955945343]],
    [ "Petry Woods Substation",[-94.48536895, 30.0103015]],
    [ "Devers Marsh Substation",[-94.58007748438426, 29.97275053676104]],
    [ "Lamesa Solar Substation",[-101.93622993624577, 32.719500728235666]],
    [ "Riggins Substation",[-102.8510042067572, 31.054393674684643]],
    [ "Upton County Solar 2 Substation",[-102.29616273838433, 31.254195975250557]],
    [ "Upton County Solar 1 Substation",[-102.2888950117927, 31.248928074983993]],
    [ "Castillo Substation",[-102.30618150995747, 31.253741424960083]],
    [ "Dermott Wind Substation",[-101.00970322101628, 32.86385438125864]],
    [ "Fluvanna Wind 1 Substation",[-101.09463169133026, 32.88608387533839]],
    [ "Santa Cruz Substation",[-97.53476499249228, 27.58899352503534]],
    [ "Bruenning's Breeze Substation",[-97.6553725515398, 26.522161507531912]],
    [ "Green Pastures Wind Substation",[-99.42078730567411, 33.64455172799446]],
    [ "Baylor Switching Station",[-99.04725140000001, 33.68904955]],
    [ "Seymour Basin Substation",[-99.03939390000001, 33.597378649999996]],
    [ "Brazos Electric",[-99.17971415000001, 33.5439038]],
    [ "Lake Kemp Substation",[-99.14335857662003, 33.74238637285675]],
    [ "Bomarton Substation",[-99.42851805000001, 33.5111099]],
    [ "Seymour Substation",[-99.26377227471319, 33.58871689323516]],
    [ "New Barksdale Substation",[-100.07916210136624, 29.765505082673716]],
    [ "Macedonia Substation",[-95.80990038558727, 30.159704417240782]],
    [ "Seaway Substation",[-95.92629718877185, 30.156013867615847]],
    [ "Sunnyside Substation",[-96.05262619152383, 29.914679089139486]],
    [ "Waller Substation",[-95.92641606654212, 30.04826742633585]],
    [ "Quanex Substation",[-96.21247425, 29.902081649999996]],
    [ "Prairie View Substation",[-95.98239638872097, 30.084882281369378]],
    [ "Foster Substation",[-95.84650494367366, 29.70189953020055]],
    [ "Arcola Substation",[-95.46653157768392, 29.489054817379742]],
    [ "Dow Pump Substation",[-95.55199061871645, 29.0532189654588]],
    [ "Texas Pipeline Substation",[-95.67466160000001, 29.145795900000003]],
    [ "FM 524 Substation",[-95.73519354999998, 29.0643135]],
    [ "583388759",[-95.74829924693, 29.07266781933786]],
    [ "583388812",[-95.74702675000002, 29.071324750000002]],
    [ "583388815",[-95.74526238395632, 29.07558174673213]],
    [ "583388818",[-95.74542515, 29.069991]],
    [ "583388824",[-95.76037376282622, 29.07572014544725]],
    [ "583388825",[-95.75792827137306, 29.078734355916456]],
    [ "Hilcorp Substation",[-95.74538287435418, 29.0456006393256]],
    [ "Meadow Substation",[-95.2579737959509, 29.460296908603077]],
    [ "583417871",[-95.65382170898565, 29.920179409468428]],
    [ "583417872",[-95.6546592023905, 29.92103599366768]],
    [ "Fry Road Substation",[-95.72437314466313, 29.912672454902584]],
    [ "Seawall Substation",[-94.77154375455895, 29.310776532086322]],
    [ "South Shore Substation",[-95.04243986967063, 29.51532279420764]],
    [ "Butler Road Substation",[-95.10943080960188, 29.488907724482523]],
    [ "P. H. Robinson Peaker Substation",[-94.98445763096336, 29.49160484826872]],
    [ "Texas-New Mexico Power",[-94.93816724303426, 29.357499275027973]],
    [ "583928754",[-94.94027268822207, 29.355429625223437]],
    [ "Marathon Pipeline Substation",[-94.94109788872795, 29.368476925381508]],
    [ "Liquid Carbonics Substation",[-94.94058897995008, 29.366588746884297]],
    [ "Praxair Substation",[-94.9472084643791, 29.367869220592198]],
    [ "ARCO Substation",[-94.93941415094174, 29.348176252777687]],
    [ "Intercity Substation",[-94.9533182, 29.379417449999995]],
    [ "Carbide Substation",[-94.94944694207726, 29.37167617847138]],
    [ "Texas City Water Substation",[-94.95603660606494, 29.38653281915191]],
    [ "Grant Avenue Substation",[-94.9392402749563, 29.36267305474707]],
    [ "584135186",[-94.91545412474228, 29.372944811297845]],
    [ "Marathon Oil Substation",[-94.90499725433708, 29.37568286676453]],
    [ "584135232",[-94.91122465000001, 29.371788050000003]],
    [ "584135253",[-94.8917683659771, 29.38205616729529]],
    [ "Bayview Substation",[-94.90125467476507, 29.36766480371897]],
    [ "584135265",[-94.89777667045863, 29.376855955493127]],
    [ "584135269",[-94.89400264999998, 29.379928300000007]],
    [ "584135279",[-94.9009710202017, 29.37659072206105]],
    [ "584135293",[-94.89991055, 29.38006545]],
    [ "584135294",[-94.89727234339634, 29.38014453202795]],
    [ "584135299",[-94.89474812480739, 29.38192809311218]],
    [ "584370757",[-94.9345333227568, 29.378224476869697]],
    [ "584370794",[-94.93034061531738, 29.362106781424032]],
    [ "Genoa Substation",[-95.11776879161782, 29.622747325127804]],
    [ "585069360",[-95.18435213637754, 29.60984025013794]],
    [ "McCabe Substation",[-95.04045262521818, 29.64322785502615]],
    [ "Shore Substation",[-95.04191291014297, 29.623151122159875]],
    [ "Mirage Substation",[-95.05364258732736, 29.645949615893112]],
    [ "Hanna Substation",[-95.0312974, 29.597668849999998]],
    [ "Hunter Substation",[-95.01444089581847, 29.60141857544887]],
    [ "585525280",[-94.28992468239636, 32.171069349521346]],
    [ "585560341",[-94.49612119999999, 32.46755055]],
    [ "Sandy Point Substation",[-95.01169346718396, 29.679886017417587]],
    [ "Alkane Substation",[-95.02144449281354, 29.68144357533867]],
    [ "Big Bay Substation",[-95.04540040231714, 29.623343679763813]],
    [ "Orion Substation",[-95.04507019734139, 29.62870374607152]],
    [ "Choate Substation",[-95.048509, 29.6268951]],
    [ "Intermediate Substation",[-95.44688825, 29.6066748]],
    [ "585792970",[-95.41338253408927, 29.653101059025413]],
    [ "T. H. Wharton Substation",[-95.53313009955315, 29.93992840930826]],
    [ "Jester Substation",[-95.4576558, 29.990107499999997]],
    [ "Veteran Substation",[-95.48551244842268, 29.96812354635889]],
    [ "588164541",[-96.42852984868281, 32.06227906336463]],
    [ "591165566",[-95.45599870000001, 32.463769299999996]],
    [ "591455079",[-95.29544497679193, 32.305452717591855]],
    [ "591695525",[-95.14821065000001, 32.1128353]],
    [ "593621059",[-95.56946730329152, 32.912414684530845]],
    [ "595433463",[-97.06917332839149, 32.741876229526305]],
    [ "Oncor",[-96.74402620000002, 32.96249065]],
    [ "598500448",[-94.85095040823605, 31.817408125082192]],
    [ "Wyntex Substation",[-95.55135149075454, 30.74232212523322]],
    [ "602324725",[-95.15622323931566, 33.39933575256169]],
    [ "604028348",[-94.25355770000002, 30.30353395]],
    [ "Pipeline Substation",[-106.36420050034532, 31.94471007242709]],
    [ "614596099",[-106.41984344679743, 31.7842091503803]],
    [ "614597312",[-106.42057681018063, 31.808522827464973]],
    [ "Oncor",[-97.28231798564799, 32.93008956854324]],
    [ "625891542",[-98.55929422053244, 33.13316310393036]],
    [ "627311069",[-97.718209288592, 32.780196545921065]],
    [ "627314176",[-97.7026299633496, 32.78173284153438]],
    [ "627894197",[-101.82075127495324, 34.9854464569459]],
    [ "Valley View Substation",[-101.90087966244288, 34.984918125187306]],
    [ "628403439",[-102.3461603166538, 34.84957463751598]],
    [ "628403442",[-102.31549663153872, 34.84795505181976]],
    [ "628410061",[-102.18763116756612, 34.9100335939703]],
    [ "628849694",[-102.97288965000001, 34.453277400000005]],
    [ "628891713",[-103.038674, 34.38893425]],
    [ "628891716",[-102.9733320587119, 34.391766429318416]],
    [ "628891717",[-102.90749271580746, 34.33135672549716]],
    [ "Salt Draw Substation",[-103.50293770565547, 31.338146268312794]],
    [ "629291291",[-102.0490902838561, 33.195646337068766]],
    [ "629291292",[-102.03326268453112, 33.03694092493091]],
    [ "Draw Tap",[-101.6657625, 33.189101550000004]],
    [ "Ransom Canyon Substation",[-101.69700572504927, 33.527794257164466]],
    [ "Moulton South Substation",[-97.14021737074732, 29.531074537320002]],
    [ "Pomelo Substation",[-98.47722350000001, 26.4448907]],
    [ "Ridge Substation",[-96.29460745000002, 31.1047961]],
    [ "Limestone Substation",[-96.24628745000001, 31.421884050000003]],
    [ "Butler Substation",[-95.90609326937334, 31.665403224069426]],
    [ "Amerigas Substation",[-96.16035399796799, 31.35607563364915]],
    [ "Brown Magnolia Substation",[-96.13054410000001, 31.230308199999996]],
    [ "Olethea Substation",[-96.42648058358434, 31.4008491586772]],
    [ "Teague Main Substation",[-96.29675050536879, 31.647291775788254]],
    [ "Freestone Substation",[-96.24830910222003, 31.543770288748505]],
    [ "Pleasant Springs Substation",[-95.85247805405089, 31.174835565404134]],
    [ "Pleasant Springs Tap",[-95.88871251691295, 31.329229215085995]],
    [ "Centerville I-45 Substation",[-96.01662168226099, 31.352132593283095]],
    [ "Temple Inland Substation",[-94.86741914999999, 31.381907599999998]],
    [ "Mustang Prairie Substation",[-95.58251415, 31.2378272]],
    [ "Latexo Substation",[-95.42522270000002, 31.364377499999996]],
    [ "Berea Substation",[-95.31856432399411, 31.358316392913252]],
    [ "Grapeland Magnolia Substation",[-95.6874096169858, 31.46011274124134]],
    [ "Explorer Substation",[-95.66801962331877, 31.42221559297664]],
    [ "Crockett Substation",[-95.4772200732898, 31.370775209195994]],
    [ "Point Blank Substation",[-95.22531253649876, 30.75268403138116]],
    [ "Cedar Substation",[-95.66151166300224, 30.86873876444061]],
    [ "Walker Substation",[-95.4951780711475, 30.84337480447776]],
    [ "Dorrell Substation",[-95.3888242347501, 30.52442092890157]],
    [ "Evergreen Switching Station",[-95.46243934041625, 30.532207984364682]],
    [ "Temco Substation",[-95.46298082368473, 30.53216924281442]],
    [ "Georgia Substation",[-95.47324095, 30.5725883]],
    [ "Goree Substation",[-95.50063900203367, 30.680624258971267]],
    [ "Mount Zion Substation",[-95.66953114861651, 30.720917744632953]],
    [ "Cold Spring Substation",[-95.1037653955471, 30.568752118756922]],
    [ "630415514",[-95.01185620252694, 30.41847861221767]],
    [ "Coley Creek Substation",[-94.96674164999997, 30.495007249999993]],
    [ "Rivtrin Substation",[-95.40834708851868, 30.840248386649783]],
    [ "Fermi Substation",[-100.78752740000003, 29.769742050000005]],
    [ "Ogletree Substation",[-94.95257923847419, 30.70402477362014]],
    [ "Livingston Substation",[-94.91756077239027, 30.71425001742655]],
    [ "Bragg Substation",[-94.5833142, 30.433055799999995]],
    [ "Gulf Switching Station",[-94.81500570305901, 30.64608870094226]],
    [ "Gulf Livingston Substation",[-94.81487025, 30.6459232]],
    [ "Carlisle Substation",[-95.17239764441545, 30.837964613193652]],
    [ "Gulf Trinity Substation",[-95.1729137, 30.837754199999992]],
    [ "Onalaska Substation",[-95.10915657774261, 30.850288056678213]],
    [ "Blanchard Substation",[-95.04916791903074, 30.753289589010684]],
    [ "Hightower Substation",[-94.92690413794813, 30.4131670295569]],
    [ "Herty North Switching Station",[-94.67848916120028, 31.419802398379083]],
    [ "Champion Paper Mill Substation",[-94.67937985000002, 31.358347900000005]],
    [ "Diboll Substation",[-94.71639717109846, 31.224485476287256]],
    [ "Biomass Substation",[-94.73896777391882, 31.369261953245406]],
    [ "Corrigan Substation",[-94.8036929965963, 30.992561059044853]],
    [ "Magnolia Groveton Substation",[-95.16380218428588, 30.975694862248726]],
    [ "Kickapoo Substation",[-94.9999681752124, 31.006207756975122]],
    [ "Slocum Substation",[-95.48809303146308, 31.61799705742668]],
    [ "Grapeland Substation",[-95.45844925000002, 31.50850795000001]],
    [ "Broaddus Substation",[-94.26670140092816, 31.291451398486906]],
    [ "Etoile Substation",[-94.39774887666522, 31.336503913407096]],
    [ "Wetsel Substation",[-100.54696418754666, 32.62951112902255]],
    [ "Claytonville Substation",[-100.54563881956155, 32.62805318825113]],
    [ "Two Draw Substation",[-101.4086395900592, 33.21969267432409]],
    [ "Yancy Substation",[-101.3720167, 33.152422200000004]],
    [ "Golden Spread Electric Cooperative",[-101.38806669999998, 33.16973405]],
    [ "Central Substation",[-101.59520164999999, 33.18862609999999]],
    [ "Draw Substation",[-101.66646357482207, 33.00661466132851]],
    [ "Garza Substation",[-101.3580417, 32.97935095]],
    [ "Justiceberg Substation",[-101.1623632, 33.00352545]],
    [ "Slaton Substation",[-101.63484282709253, 33.4403296992569]],
    [ "Slaton Substation",[-101.61642965935772, 33.403315189374744]],
    [ "Southland Substation",[-101.55587539045959, 33.35783227479525]],
    [ "Hackberry Substation",[-101.55700614999999, 33.2867624]],
    [ "Chireno Substation",[-94.35210975, 31.519654600000003]],
    [ "Nacogdoches South Substation",[-94.63899685000001, 31.5789453]],
    [ "Nacogdoches Substation",[-94.67631093493243, 31.608352830231432]],
    [ "Nacogdoches Southwest Substation",[-94.67869860000002, 31.567535049999996]],
    [ "Tater Hill Substation",[-98.60366355823727, 31.68435753009484]],
    [ "Corn Trail Switching Station",[-98.66364778357382, 31.81883299962389]],
    [ "Rafter Substation",[-98.80621068299932, 29.41668820688846]],
    [ "647578148",[-94.9028493, 31.835485699999996]],
    [ "648340316",[-94.54717036612016, 31.680241477111338]],
    [ "650814415",[-94.4217402, 33.4797302]],
    [ "650834921",[-94.49575182189234, 33.19933507385464]],
    [ "652087964",[-96.41647730700974, 32.70286925322332]],
    [ "652089551",[-96.34221078926285, 32.79253630493852]],
    [ "653441000",[-102.3108601, 33.5864075]],
    [ "653442108",[-102.34845071726133, 33.61576572525551]],
    [ "653448234",[-102.34718507528996, 33.6316734946833]],
    [ "653513983",[-102.43061741742767, 33.587961026296966]],
    [ "657093979",[-97.53105386336875, 32.76886173862801]],
    [ "663206942",[-96.80382148277867, 32.65386116641061]],
    [ "663680217",[-95.2596615915524, 32.29475160480571]],
    [ "664032088",[-97.029138424773, 32.45597699401373]],
    [ "664270035",[-96.81540350578555, 32.66190433751196]],
    [ "Kings Row Substation",[-97.09869454228652, 33.24990043632691]],
    [ "666189417",[-97.13077875000002, 32.65614485]],
    [ "667048849",[-97.01204263465397, 32.97371944696099]],
    [ "667275716",[-97.0833185353948, 32.68003556030069]],
    [ "Austin Substation",[-106.42005632167174, 31.783060081554552]],
    [ "671637258",[-96.65794846766322, 32.98364178303424]],
    [ "672337012",[-106.39793577837474, 31.76519294294969]],
    [ "672337013",[-106.39255642214847, 31.7650220356663]],
    [ "673526362",[-102.8730459, 36.1415912]],
    [ "Bordas II Substation",[-98.90667560849036, 27.43927749195569]],
    [ "Martinez Substation",[-98.32236738009212, 29.45069959692207]],
    [ "City of Levelland Substation",[-102.37630209999999, 33.59026075]],
    [ "682519858",[-96.48835344999999, 32.596704100000004]],
    [ "682519859",[-96.49372619782321, 32.5939899526387]],
    [ "682519860",[-96.37358961516206, 32.521872857471244]],
    [ "685837377",[-96.06525074999999, 29.00695545]],
    [ "685920216",[-102.56397795000001, 33.45865435]],
    [ "685937336",[-103.90263966695437, 31.8329944030283]],
    [ "Amber Jack Substation",[-103.8827975328217, 31.848137501572516]],
    [ "685977405",[-102.67875391103212, 32.759107524853604]],
    [ "685977407",[-102.68343504999999, 32.75535239999999]],
    [ "686505923",[-102.80842678377869, 32.99576327485674]],
    [ "686507477",[-102.81751196239574, 32.99743502682762]],
    [ "686507481",[-102.82959008470995, 33.02756336786]],
    [ "686864757",[-103.52404134999999, 31.487794149999996]],
    [ "686972911",[-98.51107500587408, 33.495505475156506]],
    [ "686972912",[-98.51086010822245, 33.49652617588952]],
    [ "686972914",[-98.36111713735076, 33.49649020313442]],
    [ "686972917",[-97.79090317140232, 33.570061790900155]],
    [ "686972919",[-97.48195635000002, 33.67953434999999]],
    [ "687078216",[-102.74324557884903, 32.97290254136461]],
    [ "687078237",[-102.75764889988424, 33.01040285766012]],
    [ "687429194",[-97.8691223529121, 32.41268682721986]],
    [ "687451461",[-96.00281395, 32.266747900000006]],
    [ "Bergheim Substation",[-98.52767451771325, 29.790163095530865]],
    [ "690442715",[-102.22676430954635, 32.19877567410639]],
    [ "690527405",[-102.79395862501667, 32.73262610436753]],
    [ "690564796",[-102.90337765, 33.0096973]],
    [ "691383404",[-96.6114902, 33.55659505]],
    [ "691383409",[-96.62918709927266, 33.61364008158444]],
    [ "701973995",[-104.83220818781813, 31.030772964212755]],
    [ "703477664",[-98.40695115, 33.766959199999995]],
    [ "703477669",[-98.48472774999999, 33.7904347]],
    [ "703477673",[-98.51952750269658, 33.82888231654916]],
    [ "704233028",[-95.79311320838389, 32.63025953751876]],
    [ "704233029",[-95.99716075575472, 32.6164100129551]],
    [ "704873417",[-102.42474204583807, 34.51618568794566]],
    [ "704929105",[-97.0693316576784, 30.567470232651818]],
    [ "704929106",[-97.06965007443158, 30.56885513281103]],
    [ "706096015",[-103.02939054999999, 36.37736975000001]],
    [ "712139805",[-106.36668322291926, 31.771691887520184]],
    [ "713482066",[-106.21103765528284, 31.824786394353456]],
    [ "Biggs Substation",[-106.33966057772182, 31.86407399547193]],
    [ "713486411",[-106.31550068497347, 31.85898649028022]],
    [ "713486424",[-106.31614112494903, 31.830327025409503]],
    [ "730441058",[-94.80099639038822, 32.20494339495927]],
    [ "730441819",[-94.77711517976888, 32.222665345440554]],
    [ "731194230",[-96.7600485, 32.2924622]],
    [ "731194236",[-96.75512395, 32.29574475]],
    [ "731194240",[-96.67521789412449, 32.318573030139774]],
    [ "734907022",[-106.31982789453727, 31.7232651941037]],
    [ "735193180",[-97.79672581395698, 32.782126791294004]],
    [ "Oncor",[-97.7966052099869, 32.78119889802647]],
    [ "735193182",[-97.90927127093639, 32.80305961600307]],
    [ "735193184",[-97.87044715, 32.80650335]],
    [ "Executive Parkway Substation",[-97.07400867150133, 32.751756979139785]],
    [ "736989188",[-96.57617401771252, 33.517898287025936]],
    [ "739644232",[-97.13388901962429, 33.20341292475175]],
    [ "741047017",[-97.06179462660351, 32.99742733657063]],
    [ "746286249",[-94.01593163329292, 31.51739500636844]],
    [ "748880843",[-95.21592894999998, 32.1858043]],
    [ "748882244",[-94.90058931780857, 31.832968765370524]],
    [ "748882246",[-94.98956404587875, 31.93887543948651]],
    [ "754620177",[-96.43796688016978, 33.19336688734118]],
    [ "754620179",[-96.40853726875952, 33.16042038762262]],
    [ "754621637",[-96.35667365734834, 33.029229773846744]],
    [ "759008881",[-99.54610862494599, 31.258592892108158]],
    [ "759304862",[-94.4532593401597, 31.866235792657996]],
    [ "759309011",[-94.34385011085664, 31.90409095463722]],
    [ "759933771",[-95.56318408336263, 32.23594125059476]],
    [ "Oncor",[-98.06591379762725, 32.79914792159091]],
    [ "771501428",[-96.13469253387238, 31.93818248290599]],
    [ "784880809",[-96.61016262674299, 33.56744143526812]],
    [ "798610658",[-96.00780266348706, 32.29753832362128]],
    [ "803419039",[-106.58660530976522, 31.96664291406569]],
    [ "Picante Substation",[-106.31443309055884, 31.88021335182796]],
    [ "803419048",[-106.4038344503024, 31.81921674992675]],
    [ "Yucca Drive Switching Station",[-102.95503355149313, 31.587640119907313]],
    [ "808082717",[-106.5192837728086, 31.807068308501062]],
    [ "809992481",[-98.48547100025955, 33.897267810126664]],
    [ "American Electric Power",[-99.67462803230254, 28.34639811864363]],
    [ "828415759",[-96.54342118643112, 33.42772853015673]],
    [ "828420760",[-106.31474954999999, 31.661541749999994]],
    [ "834817314",[-94.33554609958935, 32.158104254681845]],
    [ "834817325",[-94.3626904, 32.18042305]],
    [ "834941287",[-93.7800200822476, 31.435535853119415]],
    [ "836110453",[-94.28632625855856, 32.15959951123806]],
    [ "836203180",[-96.89334594935964, 33.24533359508497]],
    [ "836910473",[-97.07204145295783, 33.2306550628239]],
    [ "837230389",[-98.42580839999998, 28.704829150000002]],
    [ "837230390",[-98.41043096488268, 28.72057267593306]],
    [ "Energía Buena Vista",[-98.39772857227695, 26.207785911236876]],
    [ "843236579",[-96.98225786872781, 30.043468147277096]],
    [ "Oncor",[-102.32727489317227, 31.941534713631633]],
    [ "844386859",[-97.06498813859218, 32.444407352538775]],
    [ "844416155",[-103.22222074269993, 31.313799871305356]],
    [ "844416157",[-103.15366586297549, 31.294432125024088]],
    [ "844424125",[-103.90491215024855, 31.80802883283422]],
    [ "844424129",[-103.23999213744177, 31.80238842624508]],
    [ "849925843",[-103.68208124848783, 31.78916062143644]],
    [ "849925851",[-103.54420905, 31.976030849999997]],
    [ "Esperanza Substation",[-98.66501127906841, 29.800581925395832]],
    [ "857873381",[-96.21176801179115, 29.922138099241536]],
    [ "857922639",[-95.30727755097452, 28.98068092656504]],
    [ "862801075",[-97.73351658313503, 32.3383326438327]],
    [ "864993510",[-97.21025325958293, 33.21869332521641]],
    [ "864993512",[-97.21150461732887, 33.214980326046714]],
    [ "864993513",[-97.10757041326758, 33.19768651977004]],
    [ "884300901",[-97.58195388012089, 31.43776831378991]],
    [ "884303553",[-98.3545034364082, 32.374787081020486]],
    [ "884303554",[-98.35454551683468, 32.374201602702215]],
    [ "886504097",[-96.47036769169041, 32.106178797362055]],
    [ "886504098",[-96.47013335152675, 32.10526071469995]],
    [ "886599251",[-95.97703610854008, 32.27845332346503]],
    [ "888016147",[-106.598040663181, 31.931347876559318]],
    [ "888021032",[-96.45670437897023, 33.35256339979756]],
    [ "888023955",[-95.86188913165554, 32.53772717870158]],
    [ "Gable Street Substation",[-95.35250197339263, 29.76448640416898]],
    [ "915840073",[-95.6980379, 33.6343416]],
    [ "919204096",[-97.10117496920799, 33.199566863784334]],
    [ "969120430",[-94.04287724237794, 33.133756992180196]],
    [ "992620257",[-94.356743843708, 32.47167038037366]],
    [ "993364695",[-97.6561107887193, 32.78733394255423]],
    [ "995463129",[-96.09917288077577, 33.07770733943111]],
    [ "1006095181",[-102.47174048725432, 31.033707871610265]],
    [ "1006095188",[-102.30951884999999, 31.0136135]],
    [ "Soaptree Switching Station",[-102.39363692490596, 31.0830606071265]],
    [ "Lynx Substation",[-102.39350681981138, 31.07185929572859]],
    [ "Coral Substation",[-103.72265893434667, 31.03750946908647]],
    [ "Cryo Substation",[-103.72069002151105, 31.046801455797777]],
    [ "Sand Lake Switching Station",[-103.46451731061849, 31.510502320169063]],
    [ "Horseshoe Springs Switching Station",[-104.2260972793986, 31.82804484804707]],
    [ "Kyle Ranch Substation",[-103.6516483, 31.93064715]],
    [ "1006279925",[-103.86139132754259, 31.793346290716798]],
    [ "Riverton Switching Station",[-103.86073324021082, 31.806835537024146]],
    [ "Oncor",[-103.8588925254169, 31.86328888959098]],
    [ "Oncor",[-103.86321202259168, 31.86259782781663]],
    [ "Tunstill Substation",[-103.86117604999998, 31.8742393]],
    [ "Owl Hills Substation",[-104.06325972495706, 31.982802544253218]],
    [ "1006279967",[-104.32353862504733, 31.93301940522002]],
    [ "1006279968",[-104.32360826625289, 31.93175303293699]],
    [ "1006279977",[-104.3440119, 31.88476505]],
    [ "1006280001",[-103.98285217291932, 31.8085609250787]],
    [ "3-Mile Draw Substation",[-103.98300762498724, 31.814268495123937]],
    [ "Mentone Substation",[-103.66517903023676, 31.644613192004606]],
    [ "1006401425",[-103.84800672511047, 31.64644240675313]],
    [ "1006401438",[-103.51808147465272, 31.509248705042474]],
    [ "Pecone Substation",[-103.51735905000002, 31.509673650000007]],
    [ "1006401454",[-103.59993178062173, 31.45796429226103]],
    [ "Mcllvain Substation",[-103.61649696506154, 31.50867766811765]],
    [ "1006401463",[-103.70996317504233, 31.904133394038848]],
    [ "Lindley Ranch Substation",[-103.73120763078046, 31.795572005270344]],
    [ "1006401478",[-103.70065939999999, 31.682845649999997]],
    [ "1006401489",[-103.82290954428636, 31.545836324825682]],
    [ "1006401490",[-103.82480299532926, 31.547434416502387]],
    [ "1006401491",[-103.82669992335065, 31.545055585872536]],
    [ "Maverick Draw Substation",[-104.11890249291329, 31.666668674954376]],
    [ "1006404960",[-103.62650825000001, 31.7782306]],
    [ "Quarry Field Switching Station",[-103.63875886566878, 31.782928963995474]],
    [ "1006561802",[-102.6994982, 31.64286465]],
    [ "1006561808",[-102.79226302945655, 31.617544660708447]],
    [ "1006561809",[-102.79383225000001, 31.6075186]],
    [ "Wolf Switching Station",[-102.93274687773935, 31.601806085272663]],
    [ "1006561834",[-103.6237872, 31.786792650000002]],
    [ "1006613097",[-103.11308720000001, 31.276645350000006]],
    [ "1006613098",[-103.08868460000002, 31.2690987]],
    [ "Trans-Pecos Substation",[-103.1176339687499, 31.27538055682054]],
    [ "1006613118",[-103.09506007502398, 31.27366399526799]],
    [ "1006613125",[-103.10704138769424, 31.28291952484474]],
    [ "Lotebush Substation",[-103.11710749999999, 31.291603199999997]],
    [ "1006613148",[-103.15792803205657, 31.294420834828323]],
    [ "1006914121",[-103.06607612506099, 31.167624407131324]],
    [ "1006914128",[-103.02614724999998, 31.33087045]],
    [ "1006914142",[-103.06771412147809, 31.352552804026814]],
    [ "1006914143",[-103.07629310562457, 31.343290297428474]],
    [ "1006914147",[-103.0966174384296, 31.31747968084815]],
    [ "1006914156",[-103.09995509097683, 31.298397716602533]],
    [ "1006922785",[-103.27803220858088, 31.515772592597994]],
    [ "1006922790",[-103.17978719922989, 31.55657341693259]],
    [ "1006922798",[-103.1830437333809, 31.522853947936444]],
    [ "1006922799",[-103.18294712021842, 31.539475545469973]],
    [ "1006922805",[-103.12940348561212, 31.5540074307692]],
    [ "1006929070",[-103.16187660369802, 31.422662833421146]],
    [ "1006929077",[-103.16034635801552, 31.417489869097405]],
    [ "1006929080",[-103.28784791062806, 31.37758667831952]],
    [ "1006929084",[-103.32852707451686, 31.35613129247784]],
    [ "1007121367",[-103.67406533399935, 31.36782857506465]],
    [ "1007121385",[-103.68227595, 31.3593737]],
    [ "Texas-New Mexico Power",[-103.32178720000002, 31.645047350000002]],
    [ "Pecos Substation",[-103.49942227411394, 31.427443286189142]],
    [ "1007123840",[-103.44526655974978, 31.24071167477926]],
    [ "1007123845",[-103.44642545964749, 31.275781824927684]],
    [ "Eagle Claw Midstream Substation",[-103.50377554999999, 31.332815700000005]],
    [ "Torrecillas Substation",[-98.84008482473719, 27.619042193378633]],
    [ "1007582734",[-103.45568814171281, 31.779448224141788]],
    [ "Oncor",[-103.45584977649149, 31.778826947222136]],
    [ "Pinto Creek Substation",[-100.56176930000001, 29.350502700000003]],
    [ "Oncor",[-103.10683090981743, 31.775917120669085]],
    [ "1009150323",[-103.39458548000482, 31.817713757601794]],
    [ "Oncor",[-103.43235104954046, 31.778578478214875]],
    [ "1009182666",[-102.96457019418462, 31.841153368392295]],
    [ "1009182668",[-102.91482728803076, 31.859294725947333]],
    [ "1009182703",[-102.9255028298937, 31.80025929321186]],
    [ "Oncor",[-102.86825606599032, 31.841391369962672]],
    [ "Oncor",[-102.5742173, 31.869692000000004]],
    [ "1009208929",[-99.42188615, 31.235506]],
    [ "1009208939",[-100.59873612964685, 31.516555023132913]],
    [ "1009212698",[-101.65776395, 31.7590067]],
    [ "1009212700",[-101.55893547925984, 31.774509357985295]],
    [ "Southton Substation",[-98.4091283533193, 29.313171518334716]],
    [ "Duke Energy",[-98.74550655, 26.523778900000003]],
    [ "1011139646",[-95.93729564940034, 32.47230249668963]],
    [ "1011139648",[-96.0304434171152, 32.4789187167361]],
    [ "1011139650",[-96.11359724240991, 32.437163823224864]],
    [ "1011244035",[-96.07673768762277, 33.00280081317481]],
    [ "1011244036",[-96.0745965003214, 33.004431865976045]],
    [ "1011244940",[-96.0708035976822, 33.00942584112886]],
    [ "1013379918",[-97.12250519655153, 32.15339560792862]],
    [ "1018394162",[-96.52528760045837, 31.942477839685736]],
    [ "1018942827",[-102.76585098755056, 33.12516840029892]],
    [ "1021855839",[-101.6853558249864, 33.04032694435506]],
    [ "1021855841",[-101.68343047623212, 33.150898400016054]],
    [ "1021859379",[-101.60060757472066, 33.303453497230315]],
    [ "1021871869",[-101.87720194189768, 33.62697364520441]],
    [ "1021871870",[-101.87711475009723, 33.62861591768576]],
    [ "1058281174",[-102.86149870444335, 32.30240782999081]],
    [ "1058281176",[-102.87808115645693, 32.27999936026189]],
    [ "1058281178",[-102.84043459999998, 32.28789904999999]],
    [ "1058313857",[-100.1670669192805, 32.10195699296916]],
    [ "1058316820",[-95.37174078080116, 33.467660953684835]],
    [ "1058327753",[-102.66925938386545, 32.457304052430096]],
    [ "1059099062",[-99.06002947573054, 32.33647360320252]],
    [ "1059099064",[-99.16396425920557, 32.30703617492909]],
    [ "1060258398",[-99.03903635667831, 26.648925867620306]],
    [ "1060258402",[-97.64229744999999, 26.941485600000004]],
    [ "1060258403",[-95.88801000951585, 28.85504177033447]],
    [ "1060258409",[-97.4961961645111, 26.28823878003896]],
    [ "1060364767",[-98.52124772124398, 33.53889562142482]],
    [ "1060364769",[-98.56451936645907, 33.5096440751596]],
    [ "1060364771",[-99.5923191233506, 33.370014806142215]],
    [ "1060364773",[-99.62465242509555, 33.344121255664746]],
    [ "1060364775",[-99.51532892952555, 34.20778290326633]],
    [ "Rainey Street Substation",[-97.73796880105162, 30.256824004547546]],
    [ "1060913794",[-100.57509018428415, 31.792909720853437]],
    [ "1060913797",[-100.71868257456316, 31.83189035425731]],
    [ "1060913800",[-99.89129532650001, 31.446950553356288]],
    [ "1060913802",[-99.88810773349537, 31.444045966828956]],
    [ "1060913805",[-99.86908749999998, 31.260585099999997]],
    [ "1060913809",[-99.7907321, 31.25268035]],
    [ "1060913813",[-100.62235169284573, 31.048869051740038]],
    [ "1062564183",[-96.63300425968352, 33.30543884275694]],
    [ "1070460933",[-98.58965545830425, 33.8622159309379]],
    [ "1070460966",[-96.14502829485447, 33.495117974980516]],
    [ "1070460967",[-96.14572294874581, 33.4949614540999]],
    [ "1070460973",[-96.07538441874472, 33.40429790762194]],
    [ "1070460974",[-96.07553162974249, 33.40484431271212]],
    [ "1070460980",[-97.17951474271366, 33.54961964015137]],
    [ "1070460981",[-97.18014754854686, 33.54972089925531]],
    [ "1075828641",[-94.2772479652828, 33.071649317837654]],
    [ "1075828642",[-94.27648572378831, 33.07167454513675]],
    [ "Reloj del Sol Wind Farm Substation",[-99.33890051422777, 27.110340146334533]],
    [ "1084041611",[-97.07530285105811, 33.01419763267216]],
    [ "1084338519",[-94.71415108558666, 32.26038073161307]],
    [ "1087292802",[-102.5303327210576, 32.12046264229997]],
    [ "1087292803",[-102.5287243570273, 32.121222029650475]],
    [ "1087292809",[-102.2290999137223, 30.98010557570491]],
    [ "1087292815",[-102.25672551990924, 30.975590303031407]],
    [ "1087366829",[-96.42336621773894, 32.53507853258644]],
    [ "1087366831",[-96.42230148661487, 32.53396378838805]],
    [ "GCVC Substation",[-97.3206923481221, 27.932256289146387]],
    [ "1089582179",[-99.60533112479303, 33.00382125359135]],
    [ "1089582184",[-99.61468335000002, 33.00165559999999]],
    [ "1089687516",[-97.77939429855998, 28.125218093426493]],
    [ "1089687517",[-97.78106366533129, 28.12644185512721]],
    [ "Steel Dynamics Substation",[-97.44598209456225, 28.056829622223553]],
    [ "American Electric Power",[-97.4384508583222, 28.044256231525942]],
    [ "1092824590",[-95.4313231, 29.2436597]],
    [ "1094446366",[-95.76113484094763, 29.066538112251834]],
    [ "1095434697",[-97.77097006424492, 26.298521430633112]],
    [ "1095466306",[-95.55742320286983, 33.697983677455156]],
    [ "1095466314",[-95.5060607252978, 33.66601824033922]],
    [ "1095466317",[-95.38361976165233, 33.6412747197594]],
    [ "1095466318",[-95.34240333805447, 33.64295197848435]],
    [ "1095467493",[-95.62648205981726, 33.644144860012325]],
    [ "1095736961",[-97.55666736581362, 28.218273270236928]],
    [ "1095737661",[-97.44471960008833, 28.198990613035573]],
    [ "1095740610",[-97.95334835241725, 28.576356480483234]],
    [ "Hubbard 1 Wind Farm Substation",[-96.88929247250293, 31.751265088340155]],
    [ "1104519148",[-101.94376318801193, 31.06704513608379]],
    [ "1104529827",[-96.81264802067747, 31.5676613172084]],
    [ "1104529829",[-96.94099960128312, 31.54105725641289]],
    [ "1104531038",[-98.31029914065395, 32.658848525520725]],
    [ "1104832213",[-106.49035989186338, 31.753575683450865]],
    [ "1105029787",[-106.39492618625931, 31.759456113875707]],
    [ "1105075139",[-99.63595927503576, 33.60374779178701]],
    [ "1105075140",[-99.64160665000001, 33.60649665]],
    [ "1105398628",[-99.20159464691234, 33.55565614452265]],
    [ "1105398629",[-99.20087694709508, 33.55621759035918]],
    [ "1105404314",[-97.29181722468039, 33.461921105187166]],
    [ "1105404316",[-97.33036595173151, 33.55077422345121]],
    [ "Denton Municipal Electric",[-97.10139052963667, 33.20183961744254]],
    [ "1106486301",[-102.94920912009688, 32.38574772911914]],
    [ "1106486800",[-102.62979852575658, 34.04766131568934]],
    [ "1106488370",[-102.07298601274174, 34.786474597357]],
    [ "1107278733",[-102.60988390843592, 33.52807868722365]],
    [ "1107612363",[-100.032900244759, 31.09620581736681]],
    [ "1107738928",[-101.15543482614476, 31.249500488833924]],
    [ "1107773443",[-96.76807534782881, 33.26459253745047]],
    [ "1107836423",[-97.38052496333381, 32.94728265948657]],
    [ "1108378159",[-101.67138623053083, 33.933560635425]],
    [ "1110596038",[-100.58667452599268, 32.88465620345261]],
    [ "1110596056",[-100.58264781894592, 32.873145037957386]],
    [ "1110902638",[-97.14798934710088, 33.653505524838366]],
    [ "1110902640",[-96.91450605919431, 33.65096842882172]],
    [ "1110902642",[-97.00578267432361, 33.63296854390802]],
    [ "1110902644",[-96.99930173441001, 33.54956430551142]],
    [ "1111863837",[-100.56061981829393, 33.00771404340612]],
    [ "1112112902",[-99.6700245618952, 33.74393079301936]],
    [ "1112286153",[-99.10989185186816, 26.685656092216856]],
    [ "1112286162",[-99.05175268672791, 26.704046069636483]],
    [ "1112303924",[-97.42672670080378, 33.719659870898035]],
    [ "1112303932",[-97.52413800000001, 33.57675390000001]],
    [ "1112303935",[-97.83438866557115, 33.68467517884294]],
    [ "1112488130",[-97.82530684927393, 33.561275217707056]],
    [ "1112493254",[-97.20491694955156, 33.25599681679954]],
    [ "1112505711",[-98.19765765000001, 33.4431426]],
    [ "1112505715",[-98.50981393928767, 33.49665457536998]],
    [ "1112505718",[-98.71562440746935, 33.42212432478745]],
    [ "1112505719",[-98.77684431730205, 33.362893876900394]],
    [ "1112598602",[-98.48330960840323, 33.79569682241508]],
    [ "1112598605",[-98.47699051399442, 33.84372141869765]],
    [ "1112816229",[-98.58689548058442, 33.86044833410465]],
    [ "1113672590",[-98.52229041355409, 33.372206275638675]],
    [ "1113672592",[-98.53622969921949, 33.22587376895832]],
    [ "1113672596",[-98.61915811285495, 33.2181093494598]],
    [ "1115521684",[-94.16035768304288, 33.29100695707271]],
    [ "1115521686",[-94.16079319115806, 33.40177857362601]],
    [ "1119184788",[-95.48329956030157, 31.48642911057809]],
    [ "1119184790",[-95.45270754999999, 31.596732050000004]],
    [ "1119460622",[-95.81229888742305, 29.32645433129615]],
    [ "1119460623",[-95.81390450557883, 29.327637639123655]],
    [ "1119460624",[-95.77073476604615, 29.35968113257923]],
    [ "1119460625",[-95.76927930112842, 29.35847803333351]],
    [ "1119460626",[-95.73364908708726, 29.393207116007247]],
    [ "1119717974",[-97.11801533447061, 32.94863698244454]],
    [ "1120484077",[-103.80479241553194, 31.534363590141076]],
    [ "1122190087",[-99.4209322069426, 33.24618149552145]],
    [ "1122900036",[-102.63029015849983, 34.0326079251535]],
    [ "1122906535",[-101.36267280000003, 35.25438716347429]],
    [ "1122906538",[-101.36280713265462, 34.43161789820649]],
    [ "1122906548",[-101.81368784996157, 33.89692934214048]],
    [ "1122906559",[-101.74821222014907, 33.47551295068425]],
    [ "1122906560",[-101.74598713036734, 33.47490132210073]],
    [ "1122931096",[-98.83159536667698, 33.92944479995422]],
    [ "1122931505",[-99.72631769897023, 33.732198337060666]],
    [ "1122955349",[-98.16141324999998, 33.142795899999996]],
    [ "1122955353",[-97.88169100051614, 33.033241368715515]],
    [ "1122955354",[-97.87101066131636, 33.027439258907926]],
    [ "1123167771",[-98.48452365389238, 33.897036622909454]],
    [ "1124322979",[-98.76531425, 33.5471826]],
    [ "1124322980",[-98.76539137478284, 33.53871742450865]],
    [ "1124325453",[-98.55106085000001, 33.95779315]],
    [ "1124325457",[-98.49805728774787, 33.94286552473095]],
    [ "1127394347",[-96.57280702390874, 33.64198435806283]],
    [ "1127405507",[-96.53214740931548, 33.318201599999995]],
    [ "1127406208",[-96.50146331721547, 33.32303301827007]],
    [ "1127406719",[-96.4911078232202, 33.29432214810303]],
    [ "1127407779",[-96.43862328846818, 33.19661609517224]],
    [ "1127408389",[-96.43904867251598, 33.19332424366822]],
    [ "1127411066",[-96.27477550796323, 33.397719554304736]],
    [ "1127411684",[-96.12008655861008, 33.46617198239913]],
    [ "1127509657",[-96.39904097834362, 33.29217001818062]],
    [ "1127558067",[-96.62881964942048, 33.274703322660585]],
    [ "Seminole",[-102.67561167490916, 32.782860021598516]],
    [ "1127925612",[-102.6834795248393, 32.754915544522184]],
    [ "1128000031",[-102.78652900000002, 32.7260601]],
    [ "1128000034",[-102.67136565000003, 32.588714350000004]],
    [ "1128002103",[-102.84703742020656, 32.7843478003]],
    [ "1128002104",[-102.8866634599464, 32.783065658382334]],
    [ "1128002107",[-102.95047975670666, 32.786248355228786]],
    [ "1128002116",[-102.95303484229625, 32.776879324941575]],
    [ "1128002120",[-102.95291144999999, 32.77508045]],
    [ "1128002129",[-102.94929027435953, 32.812653095177126]],
    [ "1128047197",[-102.73344972504034, 33.03899046044107]],
    [ "1128047200",[-102.73345921103113, 33.04172542482442]],
    [ "1128047201",[-102.73380222957167, 33.07616889823793]],
    [ "1128047202",[-102.73527788647196, 33.07738342487546]],
    [ "1128047215",[-102.88713534999998, 33.18316285]],
    [ "1128047216",[-102.88821234738126, 33.18211878579252]],
    [ "1128047219",[-102.90453986650044, 33.153024990577805]],
    [ "1128087960",[-101.4727504135483, 32.50250939867794]],
    [ "1128108177",[-102.42247219357276, 34.51658202517965]],
    [ "1128108189",[-102.06903637434309, 34.46719512445741]],
    [ "1128227891",[-102.07598805, 34.5098867]],
    [ "1128227900",[-101.91966575000001, 34.445026799999994]],
    [ "1128227903",[-101.99253369999998, 34.3699283]],
    [ "1128268425",[-102.97911381425709, 32.36355756850587]],
    [ "1128271335",[-102.79471006129246, 32.42257709295549]],
    [ "1128327530",[-102.7930249789795, 32.4217512567501]],
    [ "1128364208",[-100.060632805092, 31.094294184046674]],
    [ "1130768042",[-101.96254194992218, 30.989320999176964]],
    [ "1130768440",[-101.71697025653287, 30.985628658068503]],
    [ "1130768442",[-101.71680247658696, 30.98773321128404]],
    [ "1130769450",[-101.35793058227098, 31.171768368399885]],
    [ "1130769452",[-101.4623864805984, 30.968205070604895]],
    [ "1132200605",[-95.30041817196826, 33.474685236031235]],
    [ "1132200607",[-95.3444683219538, 33.44728642116864]],
    [ "1132202831",[-93.69475941360832, 30.721171928251614]],
    [ "1132202835",[-93.77792732954948, 30.755598304634663]],
    [ "1132203362",[-95.34985779319179, 33.41507534068203]],
    [ "1132214380",[-94.96110999148176, 32.24894029104598]],
    [ "1132496442",[-97.31403010019551, 33.32594738698444]],
    [ "1132627193",[-97.53010008512844, 28.242553490103322]],
    [ "1134980249",[-102.53999879999999, 36.06070295]],
    [ "Western Trail Wind Farm Substation",[-99.05737558411231, 33.84322118652957]],
    [ "1138144087",[-98.47925507949509, 31.562075465203627]],
    [ "1144096700",[-98.54022936982344, 31.427033301228224]],
    [ "1144102239",[-102.31047190447214, 35.19889124604092]],
    [ "1144102241",[-102.27914266761131, 35.1357301843482]],
    [ "1144420082",[-99.6726434047662, 33.74328086315705]],
    [ "1144482433",[-97.35314509239304, 33.70556474780001]],
    [ "1151373898",[-100.62011975, 31.532423999999995]],
    [ "1153375088",[-102.48837875000002, 33.52075120000001]],
    [ "1156180416",[-99.19973838947847, 34.09983904050057]],
    [ "1157165691",[-96.06757584313809, 33.387824397167314]],
    [ "1157165705",[-95.63694269428294, 33.51620553035587]],
    [ "1157434646",[-95.2577655249044, 33.23480102962307]],
    [ "1157665913",[-95.42176511246639, 32.32956059787268]],
    [ "1157940852",[-97.2700924219396, 29.894873315378895]],
    [ "1157940853",[-97.26974948576905, 29.896172671303685]],
    [ "1161569241",[-95.66386304765909, 29.192044544081618]],
    [ "1161569242",[-95.66394872765125, 29.190760407630332]],
    [ "1162794463",[-96.46981772292543, 31.897199695711887]],
    [ "1162878332",[-95.73752839055942, 29.39687540756559]],
    [ "1167297825",[-97.57429816785286, 30.412059804630083]],
    [ "1167299875",[-101.08093741922968, 31.161437736379238]],
    [ "1167399067",[-94.81225983870685, 31.58501404202536]],
    [ "1167464447",[-102.03261397079727, 32.0030915933725]],
    [ "1167464448",[-102.03188884999999, 32.0033387]],
    [ "1172743924",[-95.28672927251671, 32.115021254694035]],
    [ "1172774424",[-96.10756236196957, 32.18071869974089]],
    [ "1172774426",[-96.098361230108, 32.18470395495541]],
    [ "1172774427",[-96.10275144610726, 32.12762464092308]],
    [ "1176628753",[-94.9198565260653, 31.529602922010433]],
    [ "1176937416",[-95.76069678797204, 29.17454861407373]],
    [ "1176937417",[-95.76090547748834, 29.173286071271015]],
    [ "1176962903",[-104.44406107619871, 31.736003019933754]],
    [ "1176962909",[-104.45690849892931, 31.684825531985013]],
    [ "1187613094",[-102.42770157516026, 34.319547290901305]],
    [ "1187613096",[-102.42808942520676, 34.36503219218622]],
    [ "Limestone Wind Substation",[-96.62195661831429, 31.853445316903585]],
    [ "1188600268",[-97.01631963946672, 31.863353967361224]],
    [ "1191329163",[-101.26313173739422, 30.90924344266803]],
    [ "1191329506",[-101.46643361701356, 31.799136290994305]],
    [ "1191329509",[-101.29358192054173, 31.73807834798608]],
    [ "1193039145",[-97.6819418902822, 33.92035415521972]],
    [ "1202375489",[-102.3281649619621, 33.84837206962752]],
    [ "1202375490",[-102.32655372518595, 33.80126359429683]],
    [ "1202375491",[-102.44744827469707, 33.78601691559263]],
    [ "1202375494",[-102.51961383624567, 33.787566175019585]],
    [ "1202669863",[-104.44215323923682, 31.72112372557445]],
    [ "1206450819",[-94.08477311773912, 33.5179916345488]],
    [ "1206450821",[-94.0864885, 33.4800593]],
    [ "1212751024",[-102.32749089391875, 33.304540236638545]],
    [ "1212751035",[-102.0578917248458, 33.49134142499776]],
    [ "1212905581",[-102.57744866721104, 33.268247216091375]],
    [ "1212913211",[-102.35691125000001, 33.03597045000001]],
    [ "1212913216",[-102.52669049999999, 33.0512793]],
    [ "1212913223",[-102.33602997495905, 32.97869769459518]],
    [ "1213165206",[-102.61180865, 33.1812336]],
    [ "1213165207",[-102.61107903068223, 33.182200825155824]],
    [ "1213165209",[-102.6032739, 33.05077615]],
    [ "1213289165",[-102.65803402488788, 32.963724990410945]],
    [ "1213289827",[-102.29188027497295, 33.0223234111548]],
    [ "1213743378",[-99.5207641315549, 33.254957734057314]],
    [ "1213743833",[-99.30622439976771, 33.10214738130168]],
    [ "1213743968",[-99.57131834714434, 33.17784361732538]],
    [ "1213743969",[-99.56950830166801, 33.1784095715631]],
    [ "1213744955",[-98.79158196281645, 32.912233725248065]],
    [ "1213744957",[-98.7789805198338, 32.89576584101718]],
    [ "1213744959",[-98.90313259104519, 32.74745816837068]],
    [ "Hornsby Substation",[-97.61384096238878, 30.231970055942508]],
    [ "Tesla Battery Substation",[-97.61583517231205, 30.231405809681917]],
    [ "1219257913",[-95.31137701554911, 32.351092011239224]],
    [ "1219257914",[-95.33792778063437, 32.39022606838515]],
    [ "1219400274",[-95.34215103733962, 32.387760744666295]],
    [ "1226621534",[-95.4219955897202, 32.49003985815931]],
    [ "1239501392",[-102.73324645383647, 34.211894222124336]],
    [ "1241356820",[-95.40066109093858, 32.16378202690461]],
    [ "1241723734",[-97.15796714302263, 33.68171821833834]],
    [ "1248654572",[-94.72099220049661, 33.43305611095085]],
    [ "1248704260",[-96.43814287533684, 33.19496984204569]],
    [ "1250958076",[-95.44950807580933, 30.36904710675035]],
    [ "1250973259",[-95.54172691307306, 30.6766811808792]],
    [ "Rocky Creek substation",[-95.71958291425965, 30.757218984132024]],
    [ "1251330678",[-98.89906047711187, 33.10080810437068]],
    [ "1253007198",[-106.13170078521539, 31.51737733920734]],
    [ "Keller Magnolia substation",[-97.29701875000002, 32.93957915]],
    [ "1256980967",[-95.54001600717494, 32.52622902481666]],
    [ "1263600980",[-101.47960447011782, 36.364360020641136]],
    [ "1263600986",[-101.47669734307703, 36.46787772492946]],
    [ "1266968859",[-95.4287508255175, 29.228165595711374]],
    [ "1268597808",[-102.8014422, 33.02284434999999]],
    [ "1268609263",[-102.82872583707393, 33.02650127504331]],
    [ "1274609358",[-94.03804011493924, 31.95940053255507]],
    [ "1274610531",[-94.12916903472266, 31.5761789969678]],
    [ "1280862978",[-95.05292065769211, 30.149977974689545]],
    [ "1280862993",[-95.09547950366246, 30.160929607334563]],
    [ "1286672598",[-102.49779378868107, 34.29235772474974]],
    [ "1286672653",[-102.82838562476022, 34.30127390798536]],
    [ "1286672659",[-102.74596345, 34.23964685]],
    [ "1286672665",[-102.7410752, 34.2574833]],
    [ "1286672676",[-102.90826365000001, 34.2159244]],
    [ "1286672752",[-102.89778372513364, 34.288097695493576]],
    [ "Durazno Substation",[-106.43586106856068, 31.774790651365333]],
    [ "1286925969",[-106.40758185852471, 31.772092019310477]],
    [ "1286926095",[-106.44526049673208, 31.861010004889504]],
    [ "1286926125",[-106.43492211063956, 31.963115124602453]],
    [ "1286926177",[-106.39598528989015, 31.75890136305751]],
    [ "1286926203",[-106.362005187762, 31.783894808313903]],
    [ "1286926358",[-106.42930669023157, 31.985751875218895]],
    [ "1287149315",[-104.81945863058296, 30.984239904866985]],
    [ "1287149324",[-105.35032626623757, 31.180358981934525]],
    [ "1287149326",[-105.36292770920666, 31.17675826759918]],
    [ "1287149330",[-106.0301112, 31.40896105]],
    [ "1287149338",[-106.25534606214745, 31.583699502560897]],
    [ "1287149341",[-106.15550625984164, 31.510168153151692]],
    [ "1287149348",[-106.29898355000002, 31.641995799999993]],
    [ "1287149368",[-106.24998511221797, 31.672632844562628]],
    [ "1287149379",[-106.19808907507026, 31.685005457726223]],
    [ "1287149383",[-106.21708265000001, 31.728515899999998]],
    [ "1287149420",[-106.46934875000001, 31.7649616]],
    [ "1287206981",[-106.58349627529739, 31.96562118260757]],
    [ "1287206985",[-106.58509228675977, 31.966903037922197]],
    [ "1287207013",[-106.28573210276251, 31.61795947889107]],
    [ "1287207018",[-106.30565309575964, 31.68277347845758]],
    [ "1287255216",[-106.56559655328459, 31.849388578521083]],
    [ "1289230799",[-103.0626634748075, 33.066690857921785]],
    [ "1294684695",[-103.05957719136602, 33.183116748756895]],
    [ "1299535739",[-95.38251132485384, 28.95557597176367]],
    [ "1299535740",[-95.38244430855866, 28.954244097350664]],
    [ "1300376733",[-96.67242515000001, 32.43586645]],
    [ "1300765155",[-96.65176112104085, 32.98326779456543]],
    [ "1302330146",[-96.55997628093739, 32.34715416737169]],
    [ "1302774821",[-97.06899133417532, 32.440883075493204]],
    [ "1302774827",[-97.06403995535314, 32.442485768298866]],
    [ "1302777335",[-97.04448031959772, 32.46048266006306]],
    [ "1302777336",[-97.04522903602387, 32.46047441219582]],
    [ "1306703738",[-96.60870703717575, 33.06083272922236]],
    [ "1308932805",[-96.37228234543976, 32.0300568529873]],
    [ "1316034080",[-101.95518302514209, 34.734214461928005]],
    [ "1316538418",[-97.36384299136652, 33.647802758967316]],
    [ "1316538427",[-97.52608160785421, 33.689497173615194]],
    [ "1316549112",[-96.62652214133482, 33.25595822659943]],
    [ "1316549984",[-95.90570406912927, 33.59845991001543]],
    [ "1317315346",[-95.7784789833155, 32.58859594929294]],
    [ "1317406243",[-96.69951840564349, 33.61500423241144]],
    [ "1318653351",[-96.79978630835355, 32.53751117491423]],
    [ "1318653352",[-96.7998310303145, 32.53861721118774]],
    [ "1337278814",[-95.67498095468238, 32.61698571059111]],
    [ "1337278816",[-95.70393305003334, 32.65511250156336]],
    [ "1337278823",[-95.58230882354319, 32.43078185082521]],
    [ "1337278831",[-95.37134736936224, 32.4344561611446]],
    [ "1337284395",[-95.84785397509543, 32.328764446780276]],
    [ "1337284399",[-95.72285585813782, 32.47247889272756]],
    [ "1337284404",[-95.72139615, 32.471834799999996]],
    [ "1337284405",[-95.72569224282873, 32.472407957668466]],
    [ "1337286542",[-95.59415189958223, 32.246261228283586]],
    [ "1337286544",[-95.65094000377569, 32.17293944421327]],
    [ "1337286546",[-95.52430174680224, 32.10345473772441]],
    [ "1337286550",[-95.39556387761748, 32.0505138588529]],
    [ "1337286554",[-95.39455951591377, 32.126866150804446]],
    [ "1337286556",[-95.51409220663412, 31.821517440261893]],
    [ "1337286942",[-96.21282219840403, 31.69539492152526]],
    [ "1337286944",[-96.21414774492119, 31.71123758381896]],
    [ "1337286947",[-96.18072079762275, 31.710103683927716]],
    [ "1337287723",[-96.40299980322324, 31.770123566478517]],
    [ "1337287725",[-96.3952833, 31.777542699999994]],
    [ "1337287729",[-96.46867790191763, 31.781170264239567]],
    [ "1337287731",[-96.42732051591202, 31.925844430805594]],
    [ "1337288126",[-96.31254334243992, 32.54003719133277]],
    [ "1337288957",[-96.35003626666666, 31.915651976682046]],
    [ "1337288962",[-96.28106145479396, 32.03897124211817]],
    [ "1337288964",[-96.20615753890878, 32.04127459595196]],
    [ "1337290721",[-96.35770910797554, 32.26139596579257]],
    [ "1337292649",[-96.70062218917708, 32.23318187814899]],
    [ "1337292650",[-96.69992304467453, 32.23264898883858]],
    [ "1337372622",[-95.85729458951297, 32.486447450033864]],
    [ "1337453010",[-96.77042073641115, 32.55883983662101]],
    [ "1337997357",[-96.98224700693785, 32.18349633867916]],
    [ "1337997365",[-96.89309265542907, 32.18695942713552]],
    [ "1338135200",[-97.06180023258386, 31.952866155948364]],
    [ "1338507985",[-96.39183729999998, 33.509297450000005]],
    [ "1338507987",[-96.32745737442195, 33.42940060672885]],
    [ "1338531922",[-96.72230631341073, 33.00169501259151]],
    [ "1338532285",[-96.76261001354298, 32.92021258215113]],
    [ "1338532385",[-96.7022870474193, 33.01365098341416]],
    [ "1338533651",[-96.84890461144381, 33.75293083447661]],
    [ "1338534336",[-97.07896765159832, 33.68100621912812]],
    [ "1338534339",[-97.04199729191454, 33.612569983748635]],
    [ "1338620074",[-97.2109305872715, 33.213330513259535]],
    [ "1338620077",[-97.21084442522086, 33.217183840345065]],
    [ "1338650870",[-97.07135748044368, 33.229054306763096]],
    [ "1338943681",[-97.32981758803263, 33.64019091346837]],
    [ "1339058551",[-97.04752906215829, 33.494608275107026]],
    [ "1339059222",[-97.18287681332016, 33.48678515314424]],
    [ "1339067165",[-96.24353878187704, 33.38320093009167]],
    [ "1339067168",[-96.36148700000003, 33.16057055]],
    [ "1339072053",[-96.2492721852977, 33.56425175055231]],
    [ "1339072060",[-96.25827938145268, 33.55466809630948]],
    [ "1339075294",[-96.35055341368573, 32.916574592553026]],
    [ "1339075299",[-96.2534887654173, 32.91406608412326]],
    [ "1339075301",[-96.11928516750827, 32.933653718417425]],
    [ "1339075308",[-96.07531300743386, 33.004497229648756]],
    [ "1339076592",[-96.31105589719276, 32.90076501628898]],
    [ "1339083773",[-97.67552593354714, 32.9629597240559]],
    [ "1339092290",[-98.00268202443763, 33.096838926129465]],
    [ "1339092294",[-97.86062797427645, 32.9206649208537]],
    [ "1339092296",[-97.92650201994599, 32.92007975104459]],
    [ "1339092297",[-98.02853493871392, 32.92038197503357]],
    [ "1339108197",[-97.49633332331904, 33.129294932975235]],
    [ "1339156831",[-101.9053717408021, 32.242002229275656]],
    [ "1339157772",[-96.40476127830613, 32.77220589750835]],
    [ "1339241796",[-97.70563143919028, 32.88796411044742]],
    [ "1339241801",[-97.72264336720039, 32.95938941014651]],
    [ "1339361035",[-95.93870158895135, 33.63524287472411]],
    [ "1339361036",[-95.9389542201773, 33.63632697754995]],
    [ "1339361040",[-95.95061144068707, 33.70278656083249]],
    [ "1340751604",[-93.76820880459417, 31.248276720828436]],
    [ "1340751607",[-94.35091369477956, 31.519357031526617]],
    [ "1340751612",[-94.4115540242122, 31.642304656409834]],
    [ "1340871087",[-95.8610741595414, 28.8349440241605]],
    [ "1340872584",[-96.10808545890788, 29.120118643861385]],
    [ "1340872590",[-96.14280587276878, 29.15889851300494]],
    [ "1340872592",[-96.14989095288985, 29.155953032780875]],
    [ "1340872594",[-96.14885843519532, 29.157280462204984]],
    [ "1340873336",[-95.4463756033511, 29.16805584636604]],
    [ "1341010984",[-94.23821029666554, 31.935703928293044]],
    [ "1341015235",[-94.19084827705872, 32.36670088073406]],
    [ "1341015237",[-94.14278453977953, 32.13052025801767]],
    [ "1341015240",[-94.17099834896929, 32.30633466641853]],
    [ "1341015533",[-94.22113610629543, 32.61030386407601]],
    [ "1341041573",[-96.91233947275275, 32.66139513969276]],
    [ "1341041575",[-96.86158055, 32.66153330000001]],
    [ "1342285642",[-98.21442287391349, 31.74980344377577]],
    [ "1342286231",[-99.10757459029179, 32.404483688790165]],
    [ "1342548518",[-95.5713636803925, 31.626779304776246]],
    [ "1342706659",[-103.92731948160943, 31.79181125588083]],
    [ "1343193974",[-95.86637215991864, 31.813858466885442]],
    [ "1343193975",[-95.81757905151979, 31.92145089511012]],
    [ "1344685160",[-102.88876920000001, 33.0077503]],
    [ "1344685161",[-102.97444365000001, 33.007671]],
    [ "1344685166",[-102.87597256152635, 33.0027543264226]],
    [ "1344685188",[-102.96094039371616, 32.99533062498287]],
    [ "1344685216",[-103.04859400513482, 32.93060277496239]],
    [ "1344685218",[-103.01429390000001, 32.8738151]],
    [ "1344907253",[-102.91484229999999, 32.71863349999999]],
    [ "1344907281",[-102.85604286018578, 32.95493882498667]],
    [ "1344907282",[-102.81840012601985, 32.98785454597192]],
    [ "1344907286",[-102.8468325250616, 32.98100719581395]],
    [ "1344907290",[-102.82052945, 32.94360595]],
    [ "1345266254",[-102.76659104999999, 33.022821949999994]],
    [ "1345266296",[-102.5336126, 32.9634729]],
    [ "1345266303",[-102.69018215, 32.964529500000005]],
    [ "1345335666",[-103.0036512392871, 33.17431292517301]],
    [ "1346106731",[-102.29099263341668, 33.1958429749385]],
    [ "1346106733",[-102.28302020775456, 33.1804823054414]],
    [ "1346106734",[-102.33656017481088, 33.123650461901875]],
    [ "1346212652",[-102.27915707504155, 32.91577059561837]],
    [ "1346212656",[-102.2447846398596, 32.856733271157275]],
    [ "1346212658",[-102.24569080000002, 32.85657955]],
    [ "1346212669",[-102.28021433206567, 32.85857386001551]],
    [ "1346212672",[-102.2953795750643, 32.839081403895634]],
    [ "1346212674",[-102.27899319999999, 32.9582075]],
    [ "1346212676",[-102.36537549999998, 32.8565494]],
    [ "1346304215",[-99.70529773434514, 33.59028488473962]],
    [ "1346307285",[-97.28458605582489, 32.64375519270619]],
    [ "1346322459",[-102.32650776905845, 33.34139301320416]],
    [ "1346322461",[-102.1853153, 33.38401545000001]],
    [ "1346322463",[-102.32734053174096, 33.38439265303676]],
    [ "1346322481",[-102.05002859999999, 33.32526595]],
    [ "1346322483",[-102.20563317519617, 33.19524061130175]],
    [ "1346322487",[-102.12240726995495, 32.91200563790114]],
    [ "1346322489",[-102.12676439999998, 32.8100084]],
    [ "1346765030",[-102.50253312443463, 33.59113315766983]],
    [ "1346765078",[-102.35194424200141, 33.57177842486424]],
    [ "1346765082",[-102.41098545000001, 33.5103139]],
    [ "1346765101",[-102.34920077539991, 33.46555634593334]],
    [ "1346765103",[-102.4454525, 33.4075504]],
    [ "1346874114",[-102.48821277465193, 33.48749690429115]],
    [ "1346874135",[-102.6127082, 33.44943965]],
    [ "1346874137",[-102.5241828, 33.447471050000004]],
    [ "1346874139",[-102.4612407572772, 33.406783025422726]],
    [ "1346988663",[-102.32734075, 33.75036945]],
    [ "1346988665",[-102.33152810551876, 33.73907777528469]],
    [ "1346988670",[-102.33745957552324, 33.70811670632406]],
    [ "Tierra Blanca Substation",[-102.36249552649657, 34.81624664091823]],
    [ "1347689116",[-102.33969342438502, 34.835549095827574]],
    [ "1347689122",[-101.93780317339252, 34.99785121203616]],
    [ "1347705802",[-102.42431130990764, 34.83627342520487]],
    [ "1347712084",[-100.1380500767819, 35.442480836157316]],
    [ "Southwest Vernon Substation",[-99.29526817060622, 34.117238146436776]],
    [ "1347796181",[-96.80986769008616, 33.197389650563814]],
    [ "1347824321",[-97.33747979757261, 27.680141457059406]],
    [ "1347843193",[-102.16277985, 32.44875655]],
    [ "1347843194",[-102.16355392785586, 32.44994471344003]],
    [ "1347843201",[-102.14271323164257, 32.376973737971255]],
    [ "1347850700",[-102.18050366177103, 32.17640683441842]],
    [ "1347850705",[-102.16394059999999, 32.145250600000004]],
    [ "1347850707",[-102.16303966643227, 32.14459304140634]],
    [ "1347902252",[-102.24839219384401, 32.03604772541204]],
    [ "Midland East Substation",[-102.05164926686045, 32.02038296322424]],
    [ "1348406655",[-102.0251575223421, 32.00825913672939]],
    [ "1348406658",[-102.02618118783671, 32.01166637683738]],
    [ "1348406665",[-101.99847335674197, 31.914666121267246]],
    [ "1348407422",[-102.01431609066307, 31.95325043898019]],
    [ "1348407428",[-102.01126652033028, 31.939739840040026]],
    [ "Peck Substation",[-101.8360271, 31.7848768]]
];
