export const France_Substations = [
    [ "L'Union",[1.4647592254475386, 43.66362174322285]],
    [ "Enedis",[4.86051670094138, 46.77843799616739]],
    [ "Enedis",[4.848814707935486, 46.79354597900715]],
    [ "Enedis",[4.841876753628703, 46.74072805286773]],
    [ "Enedis",[4.738412966666667, 46.9271215]],
    [ "Enedis",[4.742763796728973, 46.92859206339565]],
    [ "Enedis",[4.809151257879291, 46.79812376266542]],
    [ "Enedis",[1.3299175362760525, 43.61802826051722]],
    [ "RTE",[5.866573810290111, 43.46600326247929]],
    [ "Poste électrique du Tricastin",[4.715014039217053, 44.32943108739118]],
    [ "Enedis",[3.0654520676464, 50.62087910958941]],
    [ "Poste source de Saint-Florent",[-0.47448911496295226, 46.30742764557727]],
    [ "Enedis",[4.209154468177825, 43.686561883359694]],
    [ "RTE",[4.018189409077019, 48.903385747661154]],
    [ "RTE",[2.405683204152514, 44.444181429262386]],
    [ "Poste de Courèges",[4.839256154940489, 44.12882609894112]],
    [ "Poste électrique de Courrières",[2.9305577442249664, 50.45013023553753]],
    [ "Poste électrique de Neufchâtel",[1.4246060907083924, 49.72436570579232]],
    [ "Poste électrique de Haute-Borne",[2.147874907604235, 49.049911737986214]],
    [ "Enedis",[0.07235884283371107, 43.228830479028005]],
    [ "Poste électrique de Saint-Étienne-du-Rouvray",[1.1051610401752587, 49.36867475446674]],
    [ "Poste électrique de Saint-Orens",[1.511538631181074, 43.55262119981541]],
    [ "Poste électrique de Villeras",[2.1783812021433744, 48.74573466334878]],
    [ "Poste électrique de Saint-Aubin",[2.143183726754171, 48.7303367247151]],
    [ "Poste électrique de Saumade",[3.8951790016879104, 43.62288549179815]],
    [ "Poste électrique de Marmagne",[2.260356522203794, 47.101937259381984]],
    [ "Poste électrique Les Buis",[2.2377387872880035, 46.987595708146245]],
    [ "Poste électrique de Villejust",[2.230281693442895, 48.67621085555686]],
    [ "RTE",[2.1792864202054125, 48.67593466909494]],
    [ "Poste électrique de Mions",[4.975544951684449, 45.67434685130109]],
    [ "Poste électrique du Chaffard",[5.122572640814062, 45.6621700833111]],
    [ "Poste électrique de La Boisse",[5.02312349505779, 45.83844454293925]],
    [ "Poste électrique de Saint-Christol",[4.06960035518223, 43.70431757950024]],
    [ "Poste électrique de Mauguio",[4.003454565603371, 43.626255539834155]],
    [ "RTE",[2.3554619393880376, 48.520692213206694]],
    [ "Poste électrique de Cirolliers",[2.320043572649616, 48.55143580447814]],
    [ "Les Aqueducs",[2.429504900464797, 48.59371110884534]],
    [ "Enedis",[2.4697724670903316, 48.566672773016144]],
    [ "Poste électrique de Malecot",[2.5891091534784065, 48.51638721862911]],
    [ "Poste électrique des Loges",[2.2864032357940203, 48.582683412790715]],
    [ "RTE",[3.938507372364359, 43.572709133843986]],
    [ "Poste électrique de Bois-Tollot",[6.055696675433155, 46.25063557483878]],
    [ "Sous-station SNCF de Castelnau",[3.9074487595060963, 43.62900703601781]],
    [ "RTE",[3.960548003911058, 43.6549416603103]],
    [ "Poste électrique de Saint-Jean-de-Védas",[3.8494156635258228, 43.58054192622123]],
    [ "Sous-station des Carrés",[2.1894202316952605, 48.66173603586598]],
    [ "Poste électrique de Tamareau",[3.716163807866631, 43.632657759196526]],
    [ "Enedis",[2.238893976927511, 48.672613736473686]],
    [ "RTE",[5.856108918473111, 43.087222474510064]],
    [ "Poste électrique des Quatre Seigneurs",[3.8424720943820154, 43.6468942243841]],
    [ "Poste électrique de Courtry",[2.7740366093325948, 48.54791757864086]],
    [ "Poste électrique de Cabriès Realtor",[5.342827557073788, 43.446848666146806]],
    [ "Sous-station RFF de Réaltor",[5.321263073350137, 43.44367596607543]],
    [ "Poste électrique de Morbras",[2.648241856851628, 48.77884698601697]],
    [ "Poste électrique de La Duranne",[5.355342925465983, 43.48540738626178]],
    [ "Groupement de postes de Pessac",[-0.6666384020027887, 44.78811088354931]],
    [ "Poste électrique de Septemes",[5.353815907738354, 43.396216559675516]],
    [ "Poste électrique de Chanceaux",[0.6946717949331812, 47.4820501541461]],
    [ "Poste électrique de Salon",[5.115781104498015, 43.632437804719075]],
    [ "Poste électrique Saint-Doulchard",[2.380918719645106, 47.10620706289762]],
    [ "RTE",[2.437016563370611, 48.775585283858426]],
    [ "Poste électrique de la Plumasserie",[0.17696230751487887, 48.00263204695931]],
    [ "Poste Électrique du Cruchet",[0.14503901212599674, 48.03190211591249]],
    [ "Poste électrique de Arnage",[0.18377320340329295, 47.92149816896823]],
    [ "Poste électrique de Nanterre",[2.2058299748284833, 48.908893607643556]],
    [ "Enedis",[2.6632294582978173, 48.55805870884139]],
    [ "Poste électrique de Villevaudé",[2.6622170478887868, 48.904562857272005]],
    [ "Poste électrique de Tilleul",[0.9687399452448731, 49.03099394115495]],
    [ "Enedis",[-3.47342225, 47.88262765]],
    [ "RTE",[4.855487540819465, 45.541737615109255]],
    [ "Enedis",[2.8099630520647936, 48.37190348248616]],
    [ "Poste de la Moulinelle",[5.89347908159635, 49.17107136548591]],
    [ "Enedis",[2.8153654632729426, 48.37018802912273]],
    [ "Poste Électrique de Claire Fontaine",[0.2357154208909879, 48.00528566490389]],
    [ "Enedis",[2.818179576904572, 48.375730660065805]],
    [ "Enedis",[2.8071988220781505, 48.37146877641387]],
    [ "Enedis",[-0.6119807105760784, 44.800115408995715]],
    [ "Plaisance",[0.15439295609699968, 47.92589138357814]],
    [ "Versailles",[2.14813351237168, 48.79221838059664]],
    [ "Poste électrique de Muhlbach",[7.555796925629028, 47.907107544883274]],
    [ "Enedis",[3.0380396850729428, 50.76989388458323]],
    [ "Enedis",[3.039684740021629, 50.77135103977862]],
    [ "Enedis",[3.044380611265516, 50.77346902409076]],
    [ "Enedis",[2.5098299773838013, 48.60807491370852]],
    [ "Poste électrique de Cormeilles",[2.2000898683191834, 48.951087213160754]],
    [ "Poste électrique Saules",[2.0599797126641404, 48.790344558577296]],
    [ "RTE",[3.4092120583675376, 46.30494170314011]],
    [ "M Tag",[5.732348540617116, 45.18582156539783]],
    [ "SER",[8.099131104632754, 48.86196469905826]],
    [ "Poste électrique Porte du Rhin",[7.79554602663425, 48.55691290657445]],
    [ "Poste électrique de Sporeninsel",[7.7977076906169245, 48.558651402244166]],
    [ "Poste électrique de Chaingy",[1.8033191687367214, 47.875602546794546]],
    [ "Enedis",[1.7446179363929022, 45.739668814731154]],
    [ "Sous-station de la Verrière",[1.9408900861074139, 48.755043392415594]],
    [ "Poste électrique de Sandouville",[0.2954147464295529, 49.475583872963945]],
    [ "Poste électrique de Sainneville",[0.2751043986971372, 49.55207189300156]],
    [ "Poste électrique d'Antifer",[0.1600666396217012, 49.658633297858565]],
    [ "Poste électrique de Montivilliers",[0.2056939018252479, 49.53795281516456]],
    [ "Poste électrique du Pont-Sept",[0.18038283983523926, 49.49523789300435]],
    [ "Poste électrique de Ratier",[0.2284319335133191, 49.47930795426416]],
    [ "Enedis",[6.3534066000000005, 46.90325755]],
    [ "Enedis",[2.4300758601033965, 50.970044469215466]],
    [ "Enedis",[2.4303533529947408, 48.61604152365165]],
    [ "Poste électrique de Paillères",[-0.610508621857351, 44.79637068436921]],
    [ "Enedis",[2.428918876556463, 50.96800903357315]],
    [ "Enedis",[2.42921645, 50.9654507]],
    [ "RTE",[3.1173923758931337, 50.62714126949669]],
    [ "RTE",[-2.2381331014111265, 46.972791376447574]],
    [ "Poste électrique de Romainville",[2.437676328632958, 48.8945025973425]],
    [ "Gonin",[1.4856850521302263, 43.5724518667609]],
    [ "Enedis",[6.352332482521002, 46.90410193790638]],
    [ "Enedis",[6.35574389159996, 46.9036312956956]],
    [ "Centrad",[6.116673841898834, 45.91246346791044]],
    [ "Enedis",[6.357010909947763, 46.903705266742605]],
    [ "Enedis",[6.357762455471004, 46.900982890283714]],
    [ "RTE",[3.0932049261771906, 49.73789365319282]],
    [ "Enedis",[6.352513100000002, 46.90261305000001]],
    [ "Enedis",[6.140587692932172, 45.906517357656]],
    [ "Enedis",[3.1752278137242267, 50.59010333847605]],
    [ "Enedis",[0.7010864220869149, 47.39644579820916]],
    [ "Enedis",[1.4825854560017715, 43.57919631912505]],
    [ "Enedis",[1.4869047, 43.5727219]],
    [ "Enedis",[1.487187998890046, 43.574400982946]],
    [ "Californie",[6.1358778171932675, 45.910890108477496]],
    [ "9 Rue André Theuriet",[6.13687565, 45.906142100000004]],
    [ "Enedis",[0.7033863667220984, 47.39489796266458]],
    [ "Enedis",[3.138923694586695, 50.57814873739453]],
    [ "Poste électrique de Fanaudes",[2.30316104095292, 48.98285988643532]],
    [ "Enedis",[6.3563754999999995, 46.90645255]],
    [ "Poste électrique de Coubert",[2.726248233616921, 48.688443015428135]],
    [ "Enedis",[3.119343985147867, 50.57930148227348]],
    [ "Poste électrique d’Argœuves",[2.2500217492873955, 49.939636073958056]],
    [ "RTE",[2.8581363182151986, 42.69581855144719]],
    [ "Enedis",[6.350624819800694, 46.905655450563906]],
    [ "RTE",[2.923338020302132, 42.685975495976855]],
    [ "Enedis",[6.157164948722937, 48.706804816627965]],
    [ "Enedis",[6.141232724695467, 48.70108946565795]],
    [ "Enedis",[6.351828484184693, 46.90756209462515]],
    [ "Enedis",[6.358537202612432, 46.90544624764027]],
    [ "37138162",[6.3606767500000005, 46.90214555000001]],
    [ "Enedis",[6.144257345914613, 48.69949900990341]],
    [ "Enedis",[6.337189913828608, 46.90797408422774]],
    [ "Enedis",[3.1323099499999993, 50.55853245000001]],
    [ "Enedis",[6.3682082460590825, 47.34648771307044]],
    [ "Enedis",[6.350816259616777, 46.90871770372237]],
    [ "Enedis",[6.1065423484808745, 48.659649062289446]],
    [ "Enedis",[6.107773497394389, 48.65721372927877]],
    [ "Enedis",[6.354233549999999, 46.9072166]],
    [ "Enedis",[6.349772403902002, 46.909862288776395]],
    [ "Enedis",[6.334723990346426, 46.90848733147759]],
    [ "Enedis",[6.3353099, 46.905726]],
    [ "Enedis",[6.332038000000001, 46.9056865]],
    [ "Enedis",[6.333140404905867, 46.903611823868474]],
    [ "Enedis",[6.331150899999999, 46.9026727]],
    [ "Le centre",[6.1190956713993065, 45.899931781023035]],
    [ "Enedis",[6.111523403867768, 48.6605472329477]],
    [ "Les Eyrauds",[5.886100236846355, 45.31296736423927]],
    [ "Enedis",[6.111702842861165, 48.65574335466743]],
    [ "Poste électrique de Beaulieu",[-1.1204308211662624, 46.176329265846846]],
    [ "Enedis",[6.110687601191292, 48.65389141147966]],
    [ "Enedis",[6.1867880178254655, 48.66464056183544]],
    [ "Enedis",[6.197204097640049, 48.67640306480104]],
    [ "Enedis",[6.114589898680363, 48.65436156757091]],
    [ "Enedis",[6.947856895883733, 48.28634028190408]],
    [ "Enedis",[6.341920302270333, 46.91529846848749]],
    [ "Enedis",[0.6824768018012084, 47.39365106058027]],
    [ "Enedis",[6.3442916, 46.91046394999999]],
    [ "Enedis",[0.6841887709993302, 47.393650774012436]],
    [ "Enedis",[0.684875424117965, 47.39323646966527]],
    [ "Enedis",[6.113197232409829, 48.65212182039982]],
    [ "Enedis",[6.3418669, 46.9047292]],
    [ "Enedis",[6.343789882286329, 46.906115091574854]],
    [ "Clos St Germain",[5.21392068901361, 45.61768010061031]],
    [ "SER",[7.72573735, 48.5971275]],
    [ "Enedis",[0.6834654509964715, 47.3916212750154]],
    [ "Poste électrique de Saint-Julien",[6.212433104822877, 49.15266197671033]],
    [ "Maysselier",[5.2150002903064, 45.618842012366876]],
    [ "Enedis",[0.33312151173658056, 46.57859178048145]],
    [ "Enedis",[0.33561509681461105, 46.58013415429052]],
    [ "Enedis",[0.3319949591157275, 46.58014614915131]],
    [ "Enedis",[0.33209895608011464, 46.580611470638324]],
    [ "Érables",[5.175933874220328, 45.595182576750126]],
    [ "Enedis",[0.6855663585245623, 47.390291894563426]],
    [ "Enedis",[0.6855263666532097, 47.39125823119364]],
    [ "Enedis",[0.32866744135851145, 46.574335691804315]],
    [ "Enedis",[0.3426806682649212, 46.58337972312874]],
    [ "Enedis",[0.341856401490259, 46.57933381640011]],
    [ "Enedis",[0.34106957721093756, 46.578412409437945]],
    [ "Pierre Plate",[5.21204775, 45.619700900000005]],
    [ "Le Rempart",[5.211290412553011, 45.61881830330788]],
    [ "Enedis",[6.357703541899855, 46.906950273287656]],
    [ "Poste électrique de Mambelin",[6.6635916318764075, 47.36726218345582]],
    [ "Enedis",[3.027811008105163, 50.6336541600154]],
    [ "RTE",[5.791278248449993, 47.17424492112214]],
    [ "Poste électrique de Cheviré",[-1.6098709601334429, 47.18707128467635]],
    [ "Enedis",[3.0339562914163807, 50.63774109928988]],
    [ "Poste électrique d’Agneaux",[-1.121973590057074, 49.112851268507185]],
    [ "Saint-Germain",[5.204620002005737, 45.615580998806905]],
    [ "Perelle",[5.205253774664673, 45.61868264200232]],
    [ "Enedis",[6.339372480076374, 46.91380135825357]],
    [ "Enedis",[3.0140998125229452, 50.62820299206013]],
    [ "Enedis",[6.3446241673759785, 46.91241006975449]],
    [ "Poste électrique de Pontarlier",[6.357523446442802, 46.91174502795554]],
    [ "Enedis",[3.0092979099956034, 50.630838443095904]],
    [ "Enedis",[3.0097389671900765, 50.63238918255238]],
    [ "RTE",[7.325991687579594, 47.79874794008502]],
    [ "Enedis",[6.346497025275789, 46.910629795885505]],
    [ "Enedis",[6.116826101945702, 48.65279247070686]],
    [ "Collège de filles",[6.122324826901633, 45.89738321723262]],
    [ "Enedis",[3.029258071629986, 50.630368774239976]],
    [ "Enedis",[3.0252125824129297, 50.62888571708954]],
    [ "Cassiopée",[5.206018860438111, 45.62032059990366]],
    [ "Enedis",[0.34213777132341394, 46.580761132841076]],
    [ "Enedis",[0.34418526454766535, 46.58037900713041]],
    [ "Enedis",[3.033001763048947, 50.62635433006042]],
    [ "Enedis",[6.3544568452270305, 46.91045461874582]],
    [ "Enedis",[6.354158800344003, 46.909176774687886]],
    [ "Enedis",[6.333429519105375, 46.93479002981393]],
    [ "Enedis",[6.362502857043769, 46.89902064743399]],
    [ "RTE",[2.031771337719694, 47.22879794787033]],
    [ "Enedis",[3.035547359104126, 50.628497429814445]],
    [ "Enedis",[0.348173622707319, 46.58108402873445]],
    [ "Enedis",[3.0363578747845876, 50.627804681615736]],
    [ "SER",[7.725348290674924, 48.59223202449535]],
    [ "Poste électrique de Eybens",[5.753924021228651, 45.15405608233722]],
    [ "Enedis",[3.0417604469305304, 50.63161972385005]],
    [ "Enedis",[3.0420818253345225, 50.63050934390048]],
    [ "Enedis",[3.0329769577010484, 50.62400821296093]],
    [ "Enedis",[3.034544108981818, 50.62407178885989]],
    [ "Placeboi",[6.128305251918743, 45.89799277641039]],
    [ "Enedis",[-1.5091770677058338, 47.205947306947586]],
    [ "Enedis",[3.035163915105811, 50.61988333646883]],
    [ "Poste électrique de Fustel",[7.751299210493987, 48.575038837993866]],
    [ "Enedis",[3.0391880752431124, 50.62389732590381]],
    [ "Enedis",[3.0422380855873397, 50.62068011934672]],
    [ "Enedis",[1.335194283418722, 43.57858786718148]],
    [ "Enedis",[0.339796009562726, 46.5852656743898]],
    [ "Enedis",[0.691723642325543, 47.39426419435623]],
    [ "Enedis",[0.33961759076938275, 46.58889755607464]],
    [ "Enedis",[3.0651067593258348, 50.631295609846525]],
    [ "Enedis",[2.923045467097055, 42.6916800652452]],
    [ "Enedis",[2.9200256855750855, 42.688707132443525]],
    [ "Enedis",[2.9227923430395486, 42.68991610563277]],
    [ "Enedis",[2.939345816312057, 42.68768671274866]],
    [ "Enedis",[2.933573481234716, 42.691541979061384]],
    [ "Enedis",[2.9303233556950983, 42.691033177652855]],
    [ "Enedis",[2.931559123200235, 42.69171751529618]],
    [ "Enedis",[2.9340394559308876, 42.68777197009919]],
    [ "Enedis",[2.938652904739103, 42.684785282442036]],
    [ "Enedis",[2.9416223325805912, 42.68339975513761]],
    [ "Enedis",[0.33360057151172906, 46.5828324668332]],
    [ "Flandres",[2.9402438757195895, 42.68162160280165]],
    [ "Enedis",[3.066844581700332, 50.63075529192281]],
    [ "Route du Mans",[0.29503023875964157, 48.07475678521103]],
    [ "Entremont",[6.123632252405536, 45.894231956284415]],
    [ "Enedis",[2.940650592182977, 42.680294716480596]],
    [ "Enedis",[2.943304657222901, 42.68071303536523]],
    [ "RTE",[7.732355337681241, 48.57445792778478]],
    [ "Enedis",[2.941452793593026, 42.686082001148854]],
    [ "Enedis",[2.944810732636633, 42.6852617374043]],
    [ "Marquisat",[6.133983732194935, 45.894140238939066]],
    [ "Enedis",[3.0651121997913244, 50.63200529910924]],
    [ "Enedis",[3.067441757495375, 50.63269403633617]],
    [ "Enedis",[2.945491385631649, 42.68034213976874]],
    [ "Bourgogne",[4.567497193285287, 47.24786363923479]],
    [ "Precot",[4.569426629754411, 47.24578945408317]],
    [ "Enedis",[2.9451808712405994, 42.683015697420764]],
    [ "Enedis",[2.9473530401220867, 42.68232035646049]],
    [ "Enedis",[2.947578882491969, 42.68125627359711]],
    [ "Grenache",[2.9508239306287023, 42.68265996939908]],
    [ "Enedis",[2.950693656639466, 42.6836077118373]],
    [ "Mas Beille",[2.9494274446639013, 42.679833475326895]],
    [ "Enedis",[2.9469510467262445, 42.677373383536164]],
    [ "Enedis",[2.948376239087357, 42.67762588080474]],
    [ "Enedis",[3.06996858605509, 50.6293337963618]],
    [ "Enedis",[2.946859488095751, 42.6743098188484]],
    [ "Enedis",[2.9408744824890194, 42.677473545358716]],
    [ "Enedis",[2.944200883889673, 42.675529323035406]],
    [ "Enedis",[2.938071841390291, 42.6761654714124]],
    [ "Enedis",[2.9409971807265385, 42.67541581061777]],
    [ "Enedis",[2.936462338940759, 42.67573403542015]],
    [ "Enedis",[2.9411945385083156, 42.67357702078202]],
    [ "Enedis",[2.9443284576184374, 42.672697390624]],
    [ "Enedis",[3.0756801501226945, 50.63068715810264]],
    [ "Enedis",[2.9342814188888897, 42.675457185994944]],
    [ "Enedis",[2.9379626, 42.677825750000004]],
    [ "Enedis",[2.9367949325578775, 42.680581360274466]],
    [ "Enedis",[2.9334112073586347, 42.68044302168549]],
    [ "Enedis",[2.933660652694103, 42.6826760163232]],
    [ "Enedis",[2.9279024623946546, 42.6881338796439]],
    [ "Enedis",[5.527677359143951, 45.030878328706656]],
    [ "Enedis",[3.0458948881867123, 50.635155402101994]],
    [ "Enedis",[3.0439885085097176, 50.63532188608711]],
    [ "Enedis",[3.0446170552235663, 50.634991456083256]],
    [ "Sous-station de Liesse",[2.1239236994753816, 49.03739470128804]],
    [ "Enedis",[3.04665239685774, 50.63164224856034]],
    [ "Mengots",[5.5229714378895975, 45.03350820865113]],
    [ "Poste électrique de Puiseux",[2.0130969252784006, 49.05455038499644]],
    [ "Enedis",[5.526593916568804, 45.03689532999402]],
    [ "Poste électrique de l'Orangerie",[7.773100819975452, 48.59580430478656]],
    [ "Enedis",[7.150635548706756, 48.89320852441962]],
    [ "Poste électrique de Cergy",[2.0592806721673007, 49.04898781699312]],
    [ "RTE",[2.8831055998297477, 47.502410633200114]],
    [ "Poste électrique de Gauglin",[2.8703340069061927, 47.50657563574986]],
    [ "Poste électrique de Javel",[2.2831828558052103, 48.84547506748428]],
    [ "Enedis",[6.196045441699542, 48.68041229542228]],
    [ "Enedis",[6.1942723138810045, 48.679359573029785]],
    [ "Enedis",[-1.6087311999999998, 48.0890461]],
    [ "Enedis",[-1.6139114000000003, 48.08615055000001]],
    [ "Enedis",[2.7179385345798375, 50.64589012454411]],
    [ "Les Cerisiers",[0.2875629903749643, 48.0775825138776]],
    [ "SRD",[0.3597387549318271, 46.61088927412179]],
    [ "Enedis",[3.051350907225001, 50.77274100029989]],
    [ "Enedis",[-1.6193638495111513, 48.09004211377628]],
    [ "42185760",[-1.6230026290473742, 48.09020444487975]],
    [ "Enedis",[3.0475248245843507, 50.75986562981121]],
    [ "Enedis",[3.054090412569613, 50.771803228682046]],
    [ "Enedis",[6.36347185, 46.9079163]],
    [ "Enedis",[6.360197284445094, 46.90788155508411]],
    [ "Enedis",[2.7152630629552115, 50.64673285704636]],
    [ "Enedis",[2.7146985616589663, 50.645300428800304]],
    [ "Enedis",[3.0538241855620654, 50.769199972138836]],
    [ "Enedis",[3.0539126938479098, 50.76694942361446]],
    [ "Enedis",[2.729663558975766, 50.643547528065845]],
    [ "Enedis",[2.389765700274174, 48.8442665157495]],
    [ "Enedis",[3.0563717818215816, 50.76413322586157]],
    [ "Enedis",[3.0529713579797293, 50.63326537734873]],
    [ "Enedis",[3.051522203306285, 50.6339411781787]],
    [ "Enedis",[3.047758193641022, 50.76715293244869]],
    [ "Enedis",[-1.6123565421569228, 48.08215807238751]],
    [ "Enedis",[3.046884624962238, 50.76807650998405]],
    [ "Enedis",[3.052298905318853, 50.77072239743064]],
    [ "Enedis",[-1.6196908499999991, 48.08778565]],
    [ "Lang",[6.181616124626397, 48.68297038046752]],
    [ "Enedis",[-1.6163829499999995, 48.08581704999999]],
    [ "Enedis",[-1.6191921746988989, 48.08553230290171]],
    [ "Enedis",[-1.6192717332733233, 48.08317236663258]],
    [ "Enedis",[-1.6161133000000003, 48.082624399999986]],
    [ "Enedis",[3.0568567497143064, 50.638457254325665]],
    [ "Enedis",[2.720815199939204, 50.65688791667524]],
    [ "Enedis",[-1.6122388763814681, 48.08841365790918]],
    [ "Enedis",[6.1790582222398305, 48.683239197605204]],
    [ "RTE",[2.729060277483771, 50.64584564035985]],
    [ "RTE",[4.763286620127743, 43.42982364166713]],
    [ "Enedis",[6.18775967133664, 48.684039667335334]],
    [ "Enedis",[0.29865184228661, 46.550679112089206]],
    [ "Enedis",[-4.5058199846635425, 48.40389744746919]],
    [ "Enedis",[-4.500616259873044, 48.42311679691446]],
    [ "Enedis",[-4.498710833251988, 48.4224008366731]],
    [ "Enedis",[-4.502532603616523, 48.42191460909257]],
    [ "LEP Lanroze",[-4.490818144752802, 48.42001766694758]],
    [ "Lanroze",[-4.493648397531238, 48.42098891068604]],
    [ "Enedis",[-4.49048694422262, 48.41582885935483]],
    [ "Enedis",[-4.489848497882793, 48.41708148084384]],
    [ "Enedis",[-4.483038387434736, 48.41693922524899]],
    [ "Enedis",[-4.491905462548198, 48.415249439187264]],
    [ "Enedis",[-4.482610274221579, 48.41354246331527]],
    [ "Enedis",[-4.479051430988871, 48.408289482588984]],
    [ "Enedis",[-4.479575058547829, 48.407674440847806]],
    [ "Enedis",[-4.479319744897959, 48.4119514956854]],
    [ "Pen ar Streat",[-4.475833741395052, 48.4120768554856]],
    [ "Enedis",[-4.4644969327784185, 48.40245329396373]],
    [ "Enedis",[-4.449321329568331, 48.39912590450032]],
    [ "Enedis",[-4.434539709692249, 48.39455718775844]],
    [ "Enedis",[-4.463979292828527, 48.391039579659754]],
    [ "Enedis",[-4.478611093975539, 48.38587406865322]],
    [ "Rue de la Rade",[-4.487315731312848, 48.37997037951832]],
    [ "Enedis",[-4.476604069177917, 48.38622945467242]],
    [ "Enedis",[-4.48271102906419, 48.38411055471316]],
    [ "Enedis",[-4.476371432247657, 48.38734892924886]],
    [ "Enedis",[-4.475129306200373, 48.403422162947834]],
    [ "Massillon",[-4.477375301175743, 48.398247715963244]],
    [ "Fautras",[-4.491257270489708, 48.391381432105874]],
    [ "Enedis",[-4.5030990063876155, 48.38709486728191]],
    [ "Enedis",[-4.5106402, 48.385474200000004]],
    [ "Enedis",[-4.511826335296376, 48.38324228371842]],
    [ "Enedis",[-4.507757013390648, 48.38653996498273]],
    [ "Enedis",[-4.509391114143262, 48.38300562206835]],
    [ "Enedis",[-4.516739146951355, 48.377579547949466]],
    [ "Enedis",[-4.514752954042408, 48.377686531463524]],
    [ "Enedis",[-4.514541553485057, 48.37521988160783]],
    [ "Enedis",[-4.517597083308573, 48.383855305821314]],
    [ "Enedis",[-4.521236393863847, 48.389419445142806]],
    [ "Enedis",[-4.520362524948416, 48.39081264673807]],
    [ "Enedis",[-4.516642663010547, 48.389680306228996]],
    [ "Enedis",[-4.526005341359123, 48.385383875858984]],
    [ "Enedis",[-4.5253485000000016, 48.39170975]],
    [ "Enedis",[-4.527927599999999, 48.38281524999999]],
    [ "Enedis",[-4.54306583448588, 48.38193593344057]],
    [ "Enedis",[-4.545082127463527, 48.381745698948194]],
    [ "Enedis",[-4.544241118960841, 48.37968913378414]],
    [ "Enedis",[-4.531781202641854, 48.36999868973471]],
    [ "Enedis",[-4.554621646447091, 48.362203946881465]],
    [ "Enedis",[-4.553509226063899, 48.38173979299981]],
    [ "Poste Électrique de Saint-Jamme-sur-Sarthe",[0.16709265715025645, 48.146138789216906]],
    [ "Enedis",[6.173132483859962, 48.69154507050503]],
    [ "Enedis",[2.72794035, 50.648804750000004]],
    [ "École du Montet",[6.184913118755123, 48.68156546036644]],
    [ "Enedis",[-4.539794751121937, 48.383813286342466]],
    [ "Enedis",[-4.5362663131600165, 48.38462011704016]],
    [ "Enedis",[-4.533051865752941, 48.39151675612837]],
    [ "Enedis",[-4.53749302788074, 48.38838675334784]],
    [ "Enedis",[-4.537401983534662, 48.39164024409955]],
    [ "Veronese",[-4.527073283825325, 48.39492265288211]],
    [ "Xavier Grall",[-4.52676445, 48.39660264999999]],
    [ "Vivaldi",[-4.522388755976995, 48.39492966690326]],
    [ "Mozart",[-4.5291048499999995, 48.39686759999999]],
    [ "Tino Rossi",[-4.525459473467417, 48.39822679472655]],
    [ "Enedis",[-4.510404545250773, 48.41071560269272]],
    [ "Enedis",[-4.502306804520965, 48.416274123361106]],
    [ "Enedis",[-4.500467323876272, 48.419731090427014]],
    [ "Enedis",[-4.4947739008147805, 48.42572247019325]],
    [ "Enedis",[-4.485277598328126, 48.42438559903819]],
    [ "Enedis",[-4.487997183662352, 48.42370896685432]],
    [ "Enedis",[-4.4685036, 48.427414500000005]],
    [ "Enedis",[-4.486215863735295, 48.42006327871155]],
    [ "Enedis",[-4.481146887641657, 48.418643384104406]],
    [ "Enedis",[-4.473291005299613, 48.419968781022575]],
    [ "Enedis",[-4.48079071156053, 48.41572675011654]],
    [ "Enedis",[-4.474374253473971, 48.41518900635601]],
    [ "Thebaide",[-4.4778198, 48.41477435]],
    [ "Keranfurust",[-4.479004514527479, 48.41316145150041]],
    [ "Enedis",[-4.471330445979956, 48.41880888350837]],
    [ "Enedis",[-4.466890951822694, 48.42333736553086]],
    [ "Enedis",[-4.461121135739616, 48.41480456530312]],
    [ "Enedis",[-4.463316331358593, 48.413298317907234]],
    [ "Enedis",[-4.4650635724611085, 48.41816303660942]],
    [ "Enedis",[-4.491151743655096, 48.38761649017973]],
    [ "Harteloire",[-4.492308401057008, 48.39367227336544]],
    [ "Saint Michel",[-4.48032136098652, 48.391074155724695]],
    [ "Enedis",[-4.480334513382666, 48.38934487662747]],
    [ "Enedis",[-4.487271493269387, 48.384290894078084]],
    [ "Brossolette",[-4.491510736426203, 48.3837435829136]],
    [ "Enedis",[-4.501410479531633, 48.386314817516336]],
    [ "Enedis",[-4.465374046254698, 48.4543623218108]],
    [ "Enedis",[-4.416340728390419, 48.4118598473825]],
    [ "Enedis",[-4.443911671028737, 48.4223154386752]],
    [ "Enedis",[-4.416649119242855, 48.44336044843533]],
    [ "Enedis",[-4.3878842, 48.44039310000001]],
    [ "Enedis",[-4.400497854087394, 48.438002208980514]],
    [ "Enedis",[-4.4573651, 48.41390985]],
    [ "Enedis",[-4.459389549999999, 48.41657695]],
    [ "Enedis",[-4.403976189668358, 48.432148939015185]],
    [ "Enedis",[-4.377241208501461, 48.376552134799304]],
    [ "Enedis",[-4.3733937, 48.375557300000004]],
    [ "Enedis",[-4.370919266940808, 48.37589014834489]],
    [ "Enedis",[-4.371928054174991, 48.377236735602736]],
    [ "Enedis",[-4.366986500267384, 48.37584121668942]],
    [ "Enedis",[-4.368771124894314, 48.37846504865078]],
    [ "Enedis",[-4.365382165115258, 48.37835901710945]],
    [ "ZAC Fontaine Blanche",[-4.3624216958099336, 48.37133974256507]],
    [ "Enedis",[-4.368300432282369, 48.37415639288577]],
    [ "Enedis",[-4.363769168657505, 48.374466871615816]],
    [ "Enedis",[-4.366318621049395, 48.373060266698836]],
    [ "Enedis",[-4.367468767683041, 48.369129648265954]],
    [ "Enedis",[-4.36716105, 48.37156244999999]],
    [ "Enedis",[-4.363363499999999, 48.3699633]],
    [ "Enedis",[-4.376918703356835, 48.37080211723422]],
    [ "Enedis",[-4.3794857, 48.375081999999985]],
    [ "Enedis",[-4.375411534080591, 48.372895503437384]],
    [ "Enedis",[-4.37790915, 48.3808864]],
    [ "Enedis",[-4.376644783290043, 48.37898830011157]],
    [ "Enedis",[-4.3730151877755805, 48.381701576327096]],
    [ "Enedis",[-4.369147704385515, 48.38187805930572]],
    [ "Enedis",[-4.368037636443665, 48.38105012675613]],
    [ "Enedis",[-4.365332600000002, 48.381638300000006]],
    [ "Enedis",[-4.362498013561532, 48.377001852084135]],
    [ "Enedis",[-4.358206024545003, 48.38240050546591]],
    [ "Enedis",[-4.353066771033364, 48.38203482416132]],
    [ "Enedis",[-4.349664345734182, 48.3816846086724]],
    [ "Enedis",[-4.3856861, 48.3689827]],
    [ "Enedis",[-4.357388048050411, 48.37855397986354]],
    [ "Enedis",[-4.370707979245992, 48.3693791954001]],
    [ "Enedis",[-4.372598352287928, 48.36905313922781]],
    [ "Enedis",[-4.37654504919629, 48.36883908609557]],
    [ "Enedis",[-4.382418950000001, 48.37209754999999]],
    [ "Keraliou",[-4.401876216234032, 48.37914490964219]],
    [ "Enedis",[-4.375612235984957, 48.38633627408292]],
    [ "Zacty Menez",[-4.349479325070124, 48.38615144040879]],
    [ "Enedis",[-4.351925188856385, 48.384497874859]],
    [ "Enedis",[-4.343683578009362, 48.38134192694601]],
    [ "Enedis",[-4.324916900000001, 48.358862249999994]],
    [ "Enedis",[-4.621749474410255, 48.34270140430769]],
    [ "Enedis",[-4.617663350000002, 48.3425559]],
    [ "Enedis",[-4.618632917712767, 48.38473893162589]],
    [ "Enedis",[-4.620445261084103, 48.38077395779585]],
    [ "Enedis",[-4.6186318291408455, 48.378887793717]],
    [ "Enedis",[-4.615451557853107, 48.378802229717515]],
    [ "Enedis",[-4.615965581891047, 48.383398096781846]],
    [ "Enedis",[-4.6034580115392245, 48.375062510644746]],
    [ "Enedis",[-4.592080637095794, 48.36724416797529]],
    [ "Enedis",[-4.594791721460947, 48.37012012822459]],
    [ "Enedis",[-4.5929101469063065, 48.371075092342075]],
    [ "Enedis",[-4.590950179814329, 48.37008108315525]],
    [ "Enedis",[-4.599544626947955, 48.362000087996485]],
    [ "Enedis",[-4.584959780357143, 48.36283876448412]],
    [ "Enedis",[-4.579416564593592, 48.36101757361235]],
    [ "Enedis",[-4.5728068345788175, 48.361192131992354]],
    [ "Enedis",[-4.563420701517102, 48.359408619825786]],
    [ "Enedis",[-4.566606758468949, 48.362305997865015]],
    [ "Enedis",[-4.567167226197136, 48.361016841227304]],
    [ "Enedis",[-4.564577727561061, 48.361320108608645]],
    [ "Enedis",[-4.580015122135505, 48.37198832856022]],
    [ "Enedis",[-4.58309637463812, 48.370394490963754]],
    [ "Enedis",[-4.588152616405309, 48.36805074956521]],
    [ "Enedis",[-4.58738696570009, 48.36660570371594]],
    [ "Enedis",[-4.58970684470899, 48.372537946424316]],
    [ "Enedis",[-4.582339729462046, 48.374080249221024]],
    [ "Enedis",[-4.576509617648184, 48.37437432489453]],
    [ "Enedis",[-4.575066642667596, 48.376170081699925]],
    [ "Enedis",[-4.564325816077524, 48.37474334819343]],
    [ "Enedis",[-4.580526692591461, 48.376916490216466]],
    [ "Enedis",[-4.582231093249715, 48.37577613207025]],
    [ "Enedis",[-4.586597724002751, 48.37724235476351]],
    [ "Enedis",[-4.584797563342513, 48.37888406703084]],
    [ "Enedis",[-4.589966171735318, 48.377204691466346]],
    [ "Enedis",[-4.592622236244032, 48.37338203793805]],
    [ "Enedis",[-4.589435912062296, 48.37455157803709]],
    [ "Enedis",[-4.589252544856134, 48.37861414124077]],
    [ "Enedis",[-4.591162119610517, 48.37546171513324]],
    [ "Enedis",[-4.598865632325219, 48.37836714492725]],
    [ "Enedis",[-4.597082877773895, 48.380624058098896]],
    [ "Enedis",[-4.597777762919569, 48.38312046735603]],
    [ "Enedis",[-4.603359657426803, 48.38163163964585]],
    [ "Enedis",[-4.6053660453713805, 48.3820991049418]],
    [ "Enedis",[-4.600371755454642, 48.381129888877716]],
    [ "Enedis",[-4.6028005537374295, 48.38375161617629]],
    [ "Enedis",[-4.5981813411205135, 48.3854661745119]],
    [ "Enedis",[-4.6022706637631146, 48.38549005916947]],
    [ "Enedis",[-4.392768300521126, 48.40829359847195]],
    [ "Enedis",[-4.390081585757391, 48.40407552521657]],
    [ "Enedis",[-4.38431095, 48.403055]],
    [ "Enedis",[-4.398637617259761, 48.396374821295936]],
    [ "Enedis",[-4.419455600188802, 48.40057883331268]],
    [ "Enedis",[-4.39090883077789, 48.410789313605356]],
    [ "Enedis",[2.698058800000001, 50.6524823]],
    [ "Enedis",[6.194458563732842, 48.67739187980589]],
    [ "Enedis",[6.189789458250189, 48.67966559645126]],
    [ "Enedis",[6.118814486242979, 48.65490613694205]],
    [ "RTE",[1.7942854155042278, 43.362575561551814]],
    [ "Enedis",[3.0553042920700704, 50.64087123176288]],
    [ "Enedis",[3.0493023446870278, 50.63093319784813]],
    [ "Enedis",[3.049560191232682, 50.629525341778795]],
    [ "Enedis",[3.0530970538340503, 50.630470567205066]],
    [ "Enedis",[2.695064369876538, 50.65512832506256]],
    [ "Enedis",[6.1208769775143965, 48.65688338827987]],
    [ "Enedis",[6.123482599858883, 48.65574983444053]],
    [ "Enedis",[3.0504247675711396, 50.62827282759109]],
    [ "Enedis",[3.050968033742293, 50.62885768783217]],
    [ "Enedis",[6.185818540854561, 48.682642521280194]],
    [ "Enedis",[6.1252829710829735, 48.659327812524815]],
    [ "Faculté Dentaire",[6.191056879781369, 48.68198130043789]],
    [ "Enedis",[4.93317459850069, 45.710025037427755]],
    [ "Enedis",[4.934558785235422, 45.711723617787875]],
    [ "Enedis",[2.7178102856578126, 50.6353024317305]],
    [ "Enedis",[2.720716149999999, 50.637161799999994]],
    [ "Enedis",[4.93102575, 45.714006300000015]],
    [ "Enedis",[4.925219234294953, 45.716621767106005]],
    [ "Enedis",[4.9339272, 45.717609249999995]],
    [ "Enedis",[4.934902970572345, 45.71922224623645]],
    [ "Enedis",[6.18583133430595, 48.68450254550994]],
    [ "Poste electrique de Pithiviers",[2.221259003804008, 48.16894118337965]],
    [ "Poste électrique de Nîmes",[4.371523660267431, 43.83634643697419]],
    [ "Enedis",[4.3729876186876755, 43.83488235794397]],
    [ "Enedis",[-0.4275525519142969, 45.43244755546024]],
    [ "Enedis",[6.938948920484639, 48.29961517021065]],
    [ "Didion",[6.18804183645686, 48.68882208611547]],
    [ "Enedis",[6.183962622408562, 48.68647498895648]],
    [ "Enedis",[2.7094658642694185, 50.637384097174156]],
    [ "Enedis",[0.1672041686930331, 48.137885798205225]],
    [ "Enedis",[3.0533162121463135, 50.62838194656301]],
    [ "Mourmant",[3.052540403604119, 50.62763241953709]],
    [ "Enedis",[6.183581942509552, 48.69507440114472]],
    [ "Enedis",[6.951716325838706, 48.285994989018796]],
    [ "Enedis",[6.952678146183807, 48.285563580079284]],
    [ "Enedis",[6.944923069694569, 48.28885010240296]],
    [ "NA Chauff St Julien",[6.194380870615718, 48.68673901802291]],
    [ "Enedis",[2.7651173426386007, 49.70300175794799]],
    [ "Enedis",[2.765638963108019, 49.70004406471537]],
    [ "Enedis",[2.775957225115728, 49.704627770020345]],
    [ "Enedis",[2.782337764651798, 49.70158479537394]],
    [ "Enedis",[0.3247341066515988, 46.57140423259661]],
    [ "Enedis",[0.3255040722526185, 46.57403576776499]],
    [ "Enedis",[2.798628023939151, 49.689231800947425]],
    [ "Enedis",[2.7917879500000002, 49.7084431]],
    [ "Enedis",[2.790306349999999, 49.70139869999999]],
    [ "Enedis",[2.779109887754675, 49.708442274342175]],
    [ "RTE",[-3.718302708171721, 47.86615101179651]],
    [ "Enedis",[2.7822305188140364, 49.70451059509828]],
    [ "Enedis",[6.190739128046393, 48.68760300879449]],
    [ "Enedis",[0.31248863087345957, 46.57064745370588]],
    [ "Enedis",[0.31318950675948776, 46.56863199862446]],
    [ "Enedis",[0.31572956203510644, 46.56598453068139]],
    [ "Enedis",[2.789193345749474, 49.69753702331602]],
    [ "Enedis",[6.364860323762035, 46.89732884618946]],
    [ "Enedis",[6.364581975637619, 46.89906693836248]],
    [ "Enedis",[6.370259813263659, 46.89975599026337]],
    [ "Poste source de la Sarre",[3.120437727304309, 45.771183296473666]],
    [ "Enedis",[6.364559146421221, 46.90287218429349]],
    [ "Enedis",[6.362989368888307, 46.90511702266361]],
    [ "Enedis",[6.364873731594595, 46.904347725564214]],
    [ "Enedis",[0.3081983245136184, 46.56483471217774]],
    [ "Enedis",[0.31128271218242193, 46.566794669113435]],
    [ "Enedis",[0.3116217796560859, 46.55483910743105]],
    [ "Enedis",[0.3113353578992565, 46.55766115961409]],
    [ "Enedis",[0.3163246797063215, 46.55776143224341]],
    [ "Enedis",[0.31055630249730276, 46.5602702834651]],
    [ "Enedis",[6.935504144755265, 48.2883327927282]],
    [ "Strasbourg Électricité Réseaux",[7.769114355257915, 48.5692029433396]],
    [ "Enedis",[0.6895755351673218, 47.39628361616957]],
    [ "Enedis",[0.6917433198835962, 47.3967091999795]],
    [ "Enedis",[0.31802870735351485, 46.57432774955456]],
    [ "Enedis",[0.31723215000000005, 46.57770800000001]],
    [ "Enedis",[0.31983924999999996, 46.577023499999996]],
    [ "Enedis",[0.3248193009317217, 46.57914548257564]],
    [ "Enedis",[6.962804482021556, 48.29421508327475]],
    [ "Enedis",[6.967860758359719, 48.294203018829585]],
    [ "Enedis",[0.3234571017570137, 46.58060520381443]],
    [ "Enedis",[2.2613997546147804, 48.99110100134595]],
    [ "Poste électrique de Chalampe",[7.525372355888321, 47.81404513417323]],
    [ "Enedis",[6.954296216683765, 48.28608089151425]],
    [ "Enedis",[0.3248999255463367, 46.581828171086045]],
    [ "Enedis",[6.942867591243762, 48.29132915268346]],
    [ "Enedis",[2.260700175219998, 48.988725759527206]],
    [ "Enedis",[6.952699875586609, 48.288506404889446]],
    [ "Enedis",[6.956394714368078, 48.28831883492244]],
    [ "Poste électrique de Chevilly",[2.365261606887254, 48.773907975570296]],
    [ "Grand Cimetière",[0.19320926571780553, 48.018996291845866]],
    [ "Enedis",[-1.6848111866514976, 48.113102980996906]],
    [ "44510468",[-1.6793394414061267, 48.11566438118919]],
    [ "Enedis",[-1.6129340167164077, 46.735719999183566]],
    [ "Enedis",[5.171528150000001, 43.3346911]],
    [ "Enedis",[5.170476790449137, 43.335248147889665]],
    [ "Enedis",[5.1709730517648715, 43.33725594203626]],
    [ "Enedis",[5.1764239, 43.3362188]],
    [ "Enedis",[4.824620212430827, 45.73475200658696]],
    [ "Enedis",[-1.6836184499983622, 48.115446583470764]],
    [ "Enedis",[6.961406385442176, 48.28683290545151]],
    [ "Enedis",[2.258508461659195, 48.98817341002586]],
    [ "Enedis",[4.8264694, 45.73641114999999]],
    [ "Enedis",[6.959073934722263, 48.29084796848274]],
    [ "Le Chateau",[6.9550029043142, 48.29473125620161]],
    [ "Enedis",[-1.7794961059066974, 48.042722301908725]],
    [ "Enedis",[6.959662019340488, 48.295535406162564]],
    [ "Poste électrique de Larçay",[0.7675103850826569, 47.35192022790439]],
    [ "Enedis",[6.963993627572553, 48.292066734572984]],
    [ "Enedis",[4.832259372604417, 45.72858531772577]],
    [ "Enedis",[2.7025099856464245, 50.63780262379846]],
    [ "Terraube 268",[4.3640813653348856, 43.82986550266994]],
    [ "Enedis",[6.945521875348719, 48.293460527551886]],
    [ "Enedis",[2.701484363771948, 50.63180857908843]],
    [ "Enedis",[6.9483466, 48.2933307]],
    [ "Enedis",[6.949990904122744, 48.29841042394515]],
    [ "Enedis",[6.953432737209697, 48.29894150065971]],
    [ "Enedis",[2.2558771701760825, 48.98919401766744]],
    [ "Enedis",[5.1808654500000015, 43.3333277]],
    [ "Enedis",[6.943292250000002, 48.29677654999999]],
    [ "Enedis",[6.941815620672672, 48.297679849890436]],
    [ "Enedis",[6.9371667357440145, 48.297127860360874]],
    [ "Poste électrique de Robertsau",[7.780419156605986, 48.61432121948557]],
    [ "Enedis",[4.845030385508985, 45.72634066639069]],
    [ "Enedis",[4.844126024015756, 45.72845783118316]],
    [ "Enedis",[4.846713013058562, 45.72917472103591]],
    [ "Enedis",[-1.5394919, 47.2284117]],
    [ "Enedis",[6.173324505443753, 48.698187095194925]],
    [ "Vignonel",[4.36242962231979, 43.82312111978646]],
    [ "Charmille",[4.362793117950874, 43.81958563254215]],
    [ "Enedis",[4.3651682, 43.818278199999995]],
    [ "Enedis",[2.7247603000000002, 50.63781109999999]],
    [ "Enedis",[2.7365512002176287, 50.638470377297445]],
    [ "Enedis",[2.2574041827667624, 48.98972295714844]],
    [ "Enedis",[5.150840757652534, 43.333133081255326]],
    [ "Enedis",[5.1524933, 43.334353300000004]],
    [ "Enedis",[5.1527763768081645, 43.334242553498996]],
    [ "Enedis",[6.332868300000001, 46.90022975]],
    [ "Enedis",[5.1583730397023375, 43.32852272190944]],
    [ "Enedis",[5.1548931499999995, 43.339068299999994]],
    [ "Enedis",[5.158155744532023, 43.337760216026915]],
    [ "Simon",[5.15163785, 43.33550904999999]],
    [ "SER",[7.971083491070813, 49.011240071595424]],
    [ "Enedis",[5.1561809, 43.33596755000001]],
    [ "Enedis",[-1.588123461745334, 47.20573461087668]],
    [ "Enedis",[-0.5009184695467985, 43.89800810258012]],
    [ "SER",[7.458650843947847, 48.63715581944764]],
    [ "Enedis",[3.0635446589618787, 50.63307655976473]],
    [ "Arrighi",[2.414612897623841, 48.78569553663007]],
    [ "Enedis",[5.022525290458677, 47.31482889460451]],
    [ "Enedis",[1.442739008714669, 43.60272473927799]],
    [ "Enedis",[1.441532975795814, 43.60452690667554]],
    [ "Étang",[-0.46556932773831444, 43.89293639372972]],
    [ "Enedis",[-0.5059159, 43.893882749999996]],
    [ "Enedis",[6.3446252268012335, 46.925803233557616]],
    [ "Enedis",[6.961245688417921, 48.28880500474988]],
    [ "Enedis",[6.96919118624658, 48.29114390822327]],
    [ "Enedis",[6.5707400840948225, 45.29865473496165]],
    [ "Enedis",[-1.7145390148279172, 48.233894020803]],
    [ "Enedis",[-1.7376969319200788, 48.202958755700124]],
    [ "Enedis",[-1.7357132739557906, 48.19784105326591]],
    [ "Enedis",[4.846129243217028, 45.72956233649653]],
    [ "Enedis",[-0.4836735169549338, 43.88072766733593]],
    [ "Enedis",[2.2590957395591875, 48.99130956064395]],
    [ "Enedis",[6.344804149999999, 46.9195215]],
    [ "Enedis",[4.933133928361461, 45.71064424545132]],
    [ "Enedis",[4.938442432835513, 45.70918657698359]],
    [ "Enedis",[6.350588741762893, 46.91714327968986]],
    [ "Enedis",[6.3445461707987585, 46.916953807153405]],
    [ "Enedis",[6.3425810045747575, 46.91999216610174]],
    [ "217 Palmiers",[-0.5006980000000001, 43.885992200000004]],
    [ "215 Verdi",[-0.49713152619504747, 43.885421844360756]],
    [ "Enedis",[-0.49446356737930036, 43.88534390156364]],
    [ "Enedis",[6.947094536020743, 48.2836851383443]],
    [ "Enedis",[6.949924569759205, 48.284253412990886]],
    [ "Jules Verne",[-1.8849133000000007, 47.5430076]],
    [ "Enedis",[6.3544572000000015, 46.91532479999999]],
    [ "Enedis",[6.3551119499999995, 46.912969450000006]],
    [ "Enedis",[6.352407402368533, 46.91886333214381]],
    [ "RTE",[3.2309470310243613, 49.596944231747976]],
    [ "46984982",[-0.49096372880846445, 43.885558782428966]],
    [ "Enedis",[-0.48864574999999993, 43.8845763]],
    [ "Enedis",[6.347858249999999, 46.911884449999995]],
    [ "SER",[7.790184708797765, 48.59184877316356]],
    [ "Enedis",[0.3539519821082668, 46.58144864823147]],
    [ "Enedis",[-0.8270802, 46.42481025000001]],
    [ "Enedis",[0.35718474771782255, 46.57923402571083]],
    [ "Enedis",[-1.609242466925118, 46.735903901580244]],
    [ "Enedis",[4.830485614389351, 49.82292177392284]],
    [ "Enedis",[-1.6039504612223177, 46.73505585833231]],
    [ "Enedis",[-1.60212815, 46.7336272]],
    [ "Enedis",[6.178716889802987, 48.67427430061383]],
    [ "Enedis",[-1.602860573807512, 46.737354702633546]],
    [ "Enedis",[6.174136149999999, 48.6741479]],
    [ "Enedis",[5.0375669487714445, 47.31627629044739]],
    [ "Enedis",[5.031726384714888, 47.317275937615356]],
    [ "La Hiroire",[-0.47921311654605475, 43.89088715290395]],
    [ "Enedis",[5.030726527778912, 47.32314211890626]],
    [ "Galop",[-0.4657695726371143, 43.88927400536408]],
    [ "Enedis",[6.937261338502182, 48.28963074401563]],
    [ "Enedis",[6.939492871102562, 48.287921020835675]],
    [ "Enedis",[6.9375900400916315, 48.29072138289962]],
    [ "Enedis",[6.9521349512481265, 48.268777104118826]],
    [ "Enedis",[6.951654309884334, 48.27138748023134]],
    [ "Enedis",[6.943486218436468, 48.26711059976483]],
    [ "Enedis",[6.948984672192551, 48.263778666565024]],
    [ "Enedis",[6.947766533580717, 48.26693607167785]],
    [ "Enedis",[5.024502535603985, 47.324947547509]],
    [ "Enedis",[5.021393296551648, 47.325906031593256]],
    [ "Enedis",[-0.4943905109460464, 43.89303302153377]],
    [ "Enedis",[5.019589411293241, 47.32382656405658]],
    [ "Enedis",[5.03092271582124, 47.31881421448569]],
    [ "Enedis",[4.095067000000001, 47.776913449999995]],
    [ "Enedis",[5.026878485245252, 47.319671317288716]],
    [ "Enedis",[5.023973655360908, 47.31979505688564]],
    [ "Enedis",[6.7175928, 45.382430899999996]],
    [ "Poste électrique de Longefan",[6.35802700278043, 45.28420864740978]],
    [ "Hoche",[2.1951691668117523, 48.90352844996633]],
    [ "Enedis",[2.189249867915786, 48.89619183856934]],
    [ "Poste électrique de Ottmarsheim",[7.519184976122028, 47.77415265505965]],
    [ "Curtil-Burnand (SNCF)",[4.612853141593128, 46.58445515439816]],
    [ "Enedis",[6.336384402960368, 46.91163583567769]],
    [ "Enedis",[6.341721339391643, 46.90960309514435]],
    [ "Poste électrique de Caderousse",[4.721546865770965, 44.094719776770035]],
    [ "Enedis",[5.015830531549297, 47.32693848403756]],
    [ "Enedis",[5.01881645, 47.32840234999999]],
    [ "Enedis",[5.016001118423857, 47.3247540201278]],
    [ "Enedis",[5.013016528972599, 47.32497854326945]],
    [ "Enedis",[6.9465710937195775, 48.28146351505289]],
    [ "Enedis",[-0.46902782506777674, 43.87968430578589]],
    [ "Enedis",[6.170958371750873, 48.69432292074991]],
    [ "Enedis",[6.952627822574248, 48.27734349769145]],
    [ "Barbe",[5.038695735972564, 47.32890107036601]],
    [ "Dard",[5.03657320259438, 47.333485147206254]],
    [ "SER",[7.7643408446726365, 48.7984134677397]],
    [ "Enedis",[6.168694729235095, 48.668949029682466]],
    [ "Enedis",[6.18692286653698, 48.67738159957012]],
    [ "Enedis",[6.186980668599211, 48.677413736092575]],
    [ "Enedis",[4.8274667193648035, 49.822858099258994]],
    [ "Charles Temeraire",[5.025939286987079, 47.33109811651017]],
    [ "Enedis",[5.030174518970399, 47.329056994800354]],
    [ "meyerbefr",[5.02796289440008, 47.33082660320722]],
    [ "Les Rochats",[5.9555303, 46.57228655000001]],
    [ "RTE",[7.554455566872242, 47.625161840858816]],
    [ "Vatican",[5.947276949999999, 46.5748083]],
    [ "Salave de Bise",[5.946378550000001, 46.5771401]],
    [ "Les Crêts",[5.9619203500000015, 46.57665039999999]],
    [ "La Savine",[5.974770240898555, 46.574911661279636]],
    [ "Les Jourats",[5.952556167754862, 46.58101485376032]],
    [ "École",[4.610230127194038, 45.85046799664452]],
    [ "Enedis",[5.44514493069749, 46.85242346697003]],
    [ "Brice",[6.182363436351531, 48.678911717587134]],
    [ "Enedis",[5.018142412901828, 47.33268767028536]],
    [ "Enedis",[5.019271723385746, 47.33378647005298]],
    [ "Enedis",[5.02102675434287, 47.333963284817884]],
    [ "Enedis",[2.2529393046821142, 48.98362580467785]],
    [ "École",[5.955603430163376, 46.59309557197253]],
    [ "Enedis",[5.962446738188856, 46.60747217049039]],
    [ "Paul Bert",[5.032464, 47.330619649999996]],
    [ "Enedis",[6.164416011253807, 48.66812300261674]],
    [ "Enedis",[6.164833636527608, 48.672003574303375]],
    [ "Poste électrique de Cognac",[-0.3497872270694247, 45.695499465415764]],
    [ "Enedis",[6.950708893212631, 48.27664336154356]],
    [ "Enedis",[4.826086393922965, 49.821970475247774]],
    [ "Enedis",[6.951472227122906, 48.27910982530863]],
    [ "Enedis",[6.952697092622171, 48.28040662766597]],
    [ "Enedis",[6.1677106904568975, 48.693341303004956]],
    [ "SER",[7.79738701949228, 48.812302646099916]],
    [ "Enedis",[3.745805944493566, 43.69016797930107]],
    [ "Enedis",[4.109032707358739, 47.756183466725076]],
    [ "Enedis",[6.177554324502702, 48.66849407877759]],
    [ "Enedis",[6.34642805, 46.927314100000004]],
    [ "Poste électrique de Port-du-Rhin",[7.799129310584871, 48.52467392954436]],
    [ "Enedis",[-0.5022550354312021, 43.90112347925978]],
    [ "Enedis",[-0.5017415366887416, 43.90410192498895]],
    [ "Enedis",[-0.504085359144068, 43.90170433976699]],
    [ "Enedis",[4.827820260721505, 49.818780791073465]],
    [ "Hopital Ste Anne",[-0.5001587, 43.899267949999995]],
    [ "Enedis",[-0.5025793827246332, 43.899142433748736]],
    [ "Enedis",[6.338645149999999, 46.91647425]],
    [ "Enedis",[6.3399304, 46.918367399999994]],
    [ "Enedis",[6.342716988937091, 46.91875858006859]],
    [ "Enedis",[6.340670499999999, 46.91997429999999]],
    [ "Poste Électrique de Vaston",[-0.19443212191523215, 48.91449319166755]],
    [ "Enedis",[-0.5051261230755923, 43.89786460535938]],
    [ "réséda",[6.179874764485627, 49.112371234816194]],
    [ "Enedis",[1.1530069462754535, 42.97802751784185]],
    [ "Poste électrique de Fessenheim",[7.568278190025461, 47.914548918917845]],
    [ "SER",[7.732886704923947, 48.82312842141535]],
    [ "EDF",[1.1549350833016652, 42.97286295189657]],
    [ "RTE",[0.8573180132072835, 44.11154422214622]],
    [ "49011362",[1.143906513851992, 42.98015061846933]],
    [ "Poste électrique de Sierentz",[7.47224490748178, 47.64663556568653]],
    [ "Enedis",[7.371258993607132, 48.73486308423642]],
    [ "Enedis",[-0.4859327170513556, 43.89880404675047]],
    [ "Enedis",[1.4912989893448476, 43.56898331948171]],
    [ "Enedis",[1.4916477344588612, 43.56354254621199]],
    [ "Maridor Nord",[-0.4641959792380028, 43.907614195516224]],
    [ "Enedis",[-0.46643123394204183, 43.90428916696322]],
    [ "Enedis",[6.151781790897922, 48.671302990685874]],
    [ "Enedis",[6.155525056769466, 48.6695535368711]],
    [ "Enedis",[6.1558662550439704, 48.67066004498721]],
    [ "Enedis",[6.154538120894038, 48.673748831712714]],
    [ "Enedis",[6.156677487878552, 48.672389297096245]],
    [ "Enedis",[6.156451120366324, 48.67785580711397]],
    [ "Enedis",[6.179409291499302, 48.67318470965312]],
    [ "Enedis",[6.181119495216295, 48.673714952104575]],
    [ "Enedis",[-0.46776263323114925, 43.898568902642175]],
    [ "Enedis",[1.4912619003473648, 43.565634883340174]],
    [ "Enedis",[-0.4704919529974772, 43.90171226697361]],
    [ "Enedis",[2.193571781850679, 48.90217546777365]],
    [ "Enedis",[2.193291651163369, 48.90368664284976]],
    [ "Poste électrique Ney (RATP)",[2.347985215925516, 48.89757999191895]],
    [ "Poste électrique de Lesquive",[1.2733564440503597, 43.81666954824317]],
    [ "Enedis",[-1.6676869999999997, 48.3092892]],
    [ "Enedis",[2.171483516201278, 48.89329566667696]],
    [ "Enedis",[6.634921513441222, 48.33894657610267]],
    [ "Enedis",[6.186135881333827, 48.66813730101337]],
    [ "Enedis",[6.158352049698947, 48.67925616358542]],
    [ "Enedis",[6.155626852831489, 48.68190073242576]],
    [ "Aristide Briand",[6.157293752760897, 48.68422557419218]],
    [ "Voltaire",[6.16077647167682, 48.68154807056683]],
    [ "Enedis",[2.4336650293366247, 44.91118171262719]],
    [ "Enedis",[2.255400299999999, 48.99049905]],
    [ "Enedis",[6.63270542345324, 48.34234091717805]],
    [ "Enedis",[3.0672239650558453, 50.64172099212044]],
    [ "Enedis",[-0.4724078436027244, 43.88918919266697]],
    [ "Enedis",[3.0679618103096233, 50.64308666562687]],
    [ "Enedis",[3.066055171875, 50.645494115625]],
    [ "RTE",[5.711085817090949, 45.171900045870636]],
    [ "Enedis",[6.174649682370446, 48.67671234796076]],
    [ "Enedis",[-0.4664172000000001, 43.88385215]],
    [ "Enedis",[-0.47062725000000005, 43.8830253]],
    [ "Enedis",[-0.4753776132778962, 43.88452742781272]],
    [ "Enedis",[2.8569009000000003, 43.2159792]],
    [ "Enedis",[2.265016333540222, 48.980996749826446]],
    [ "Enedis",[6.170390329357215, 48.68585316437229]],
    [ "Enedis",[6.1505062841925815, 48.669093135962974]],
    [ "Enedis",[6.151442979776747, 48.683988930381695]],
    [ "Illkirch",[7.729450744216986, 48.519762646227925]],
    [ "Enedis",[-1.589361422227575, 47.199444849376306]],
    [ "Portique électrique de Beynost",[5.018923183918838, 45.83602088001915]],
    [ "Enedis",[-3.8877817744690115, 47.86482309664203]],
    [ "Enedis",[6.381757238389171, 46.91229243003516]],
    [ "Enedis",[3.1479236499999996, 50.6474379]],
    [ "Enedis",[3.150475699999999, 50.64740685]],
    [ "Enedis",[6.3696499000000015, 46.905871399999995]],
    [ "Enedis",[4.831768100000001, 45.792578750000004]],
    [ "Enedis",[3.1462195685318144, 50.6413732258857]],
    [ "Enedis",[3.1466618731910345, 50.64056417783845]],
    [ "Enedis",[3.1841480039510284, 50.659832282470774]],
    [ "Enedis",[6.3473667, 46.90366675]],
    [ "Enedis",[3.2358077907203944, 50.63531442292412]],
    [ "Enedis",[3.234639332575008, 50.63222547481042]],
    [ "Enedis",[0.9889507948672135, 47.1239865825848]],
    [ "Enedis",[3.210584272052339, 50.66518121699535]],
    [ "Enedis",[1.40706615, 43.58008674999999]],
    [ "Enedis",[3.119443810137193, 50.6490972146772]],
    [ "Enedis",[2.200428619553234, 48.87970506488432]],
    [ "Enedis",[2.199196617558672, 48.88215842529984]],
    [ "Enedis",[3.9734840206826956, 50.27967019495037]],
    [ "Enedis",[3.9730326713814987, 50.277253154714934]],
    [ "SER",[7.757833971569323, 48.83028536113944]],
    [ "SER",[7.744032405774694, 48.83108759114276]],
    [ "Cap Sud",[1.4920778282073959, 43.570678485575506]],
    [ "SER",[7.738877542938604, 48.83230297676948]],
    [ "SER",[7.742156336034641, 48.83198263668954]],
    [ "SER",[7.742327512132065, 48.83193995847427]],
    [ "Lafaurie",[1.491953532775484, 43.57476585310304]],
    [ "Enedis",[1.4943558520275633, 43.574083376650414]],
    [ "SER",[7.7542741500000005, 48.82003579999999]],
    [ "Bourdette",[1.4665957975502295, 43.55445954642847]],
    [ "Enedis",[3.8855720592017318, 43.61518701697412]],
    [ "Enedis",[1.40721705, 43.5856213]],
    [ "Enedis",[1.409120156141624, 43.58736473117012]],
    [ "Belleville",[6.637864260374998, 45.77283319617419]],
    [ "Enedis",[4.51262641932644, 45.4765943537907]],
    [ "52995849",[4.769557064027692, 46.32106778897849]],
    [ "Enedis",[4.514889843988939, 45.47855215709284]],
    [ "Enedis",[4.507323416510129, 45.47415222725297]],
    [ "Poste électrique de Lamorue",[2.2118112846427906, 48.9082276323255]],
    [ "Enedis",[1.051320866116492, 49.49330551232869]],
    [ "Enedis",[0.3483082266054651, 46.57239353829853]],
    [ "Enedis",[0.35006976829370096, 46.572567058597485]],
    [ "Enedis",[2.4431718167263012, 44.922832268121745]],
    [ "Enedis",[0.3487861467317151, 46.571005479663]],
    [ "Enedis",[3.1071557011174167, 50.65225873297079]],
    [ "Enedis",[0.34721929771971666, 46.56918602454344]],
    [ "Enedis",[0.3443415023688882, 46.567236176278875]],
    [ "Enedis",[3.0951197, 50.65663360000001]],
    [ "Enedis",[-4.598964068559994, 48.41487667851883]],
    [ "Enedis",[-4.613141190849307, 48.43708635353265]],
    [ "Enedis",[-4.613865534695984, 48.43419840510234]],
    [ "Enedis",[-4.618317339747378, 48.43520791875542]],
    [ "Enedis",[-4.6159766017199235, 48.43822454228086]],
    [ "Enedis",[-4.62249125234222, 48.43112710052569]],
    [ "Enedis",[-4.620380596443482, 48.43312811497841]],
    [ "Enedis",[-4.625500595366239, 48.43149493146634]],
    [ "Enedis",[-4.631001858448059, 48.42733052804016]],
    [ "Enedis",[-4.6218936, 48.42485799999999]],
    [ "Enedis",[-4.619090754601058, 48.42680005226788]],
    [ "Enedis",[-4.621226619877978, 48.42860191142086]],
    [ "Enedis",[-4.616982899999999, 48.42927685]],
    [ "Enedis",[-4.618955950000001, 48.43162525]],
    [ "Enedis",[-4.613893259151326, 48.42578267874457]],
    [ "Enedis",[-4.60827405, 48.4259072]],
    [ "Enedis",[-4.659138030890894, 48.47934219549087]],
    [ "Enedis",[-4.562749873416742, 48.469751522942616]],
    [ "Enedis",[-4.567866563995486, 48.46389845366758]],
    [ "Enedis",[-4.681625, 48.3877965]],
    [ "Enedis",[-4.628520204422708, 48.35219292646895]],
    [ "Enedis",[-4.664399769416516, 48.355031979143135]],
    [ "Enedis",[-4.643691791637523, 48.37056428559968]],
    [ "Enedis",[-4.6562347670603135, 48.37039814887604]],
    [ "Enedis",[-4.640754951854717, 48.47149958262586]],
    [ "Enedis",[-4.649106400767289, 48.47191986686859]],
    [ "Enedis",[-4.610252085163612, 48.475812989365565]],
    [ "Enedis",[-4.65119528312363, 48.561117979254774]],
    [ "Enedis",[-4.5669343499999995, 48.48703990000001]],
    [ "Portez",[-4.776394038893335, 48.357129820391215]],
    [ "Landeilo",[-4.773485862335576, 48.35940459446692]],
    [ "Enedis",[-4.769155766722099, 48.357218251146456]],
    [ "Enedis",[-4.771117692552592, 48.35900687836291]],
    [ "Poste électrique de Convention",[2.295891384782646, 48.837717647703485]],
    [ "Enedis",[3.0727026098246513, 50.6526764849355]],
    [ "Enedis",[3.0775302837250105, 50.653083514300725]],
    [ "Enedis",[0.35055039107971236, 46.5660283961544]],
    [ "Enedis",[0.350208911478421, 46.5684605709751]],
    [ "Pont des Demoiselles",[1.459868721719932, 43.59231874555366]],
    [ "Enedis",[-4.1026698, 48.2498983]],
    [ "Enedis",[-4.073276099999998, 48.30065379999999]],
    [ "Enedis",[-4.269949342587482, 48.43746423697255]],
    [ "Enedis",[-4.2806167, 48.42819925]],
    [ "Enedis",[-4.2750525999999995, 48.43422445000001]],
    [ "Enedis",[-4.194992847311083, 48.31832843309895]],
    [ "Prat an Dour",[-4.2866145, 48.335142800000014]],
    [ "Kerliver",[-4.2983031, 48.32546444999999]],
    [ "Enedis",[-4.293053939900709, 48.39438138037471]],
    [ "Enedis",[-4.303925450000001, 48.3759791]],
    [ "Enedis",[-4.303091355310795, 48.37828074417061]],
    [ "Enedis",[-4.302177734801103, 48.3790418781308]],
    [ "Enedis",[-4.305725623394062, 48.38130333375613]],
    [ "Enedis",[-4.3082283814158675, 48.37951164517924]],
    [ "Rostiviec",[-4.32554095, 48.34629244999999]],
    [ "Enedis",[0.3507239285328159, 46.5698090406546]],
    [ "Enedis",[-4.5713021507770275, 48.59434764750623]],
    [ "P 51 Mechou Ranouelen",[-4.590670693790759, 48.58965373329659]],
    [ "Enedis",[-4.58780200611558, 48.592252265628964]],
    [ "Enedis",[-4.567820414784775, 48.5870671928873]],
    [ "Enedis",[-4.56404682488662, 48.5951391533259]],
    [ "Enedis",[-4.546467803624019, 48.591797458701215]],
    [ "Enedis",[-4.580522844338819, 48.58367306613412]],
    [ "Enedis",[-4.582572449596463, 48.58585634415617]],
    [ "P1 Bourg",[-4.573170718728855, 48.58565645475566]],
    [ "Enedis",[-4.569994278716153, 48.58586037191161]],
    [ "Enedis",[-4.558031012736125, 48.583075119199684]],
    [ "Enedis",[-4.5567035619644205, 48.59420600553421]],
    [ "Enedis",[-4.5704114993330585, 48.58808545085494]],
    [ "Enedis",[-4.517761626868299, 48.56837169675311]],
    [ "Enedis",[-4.5150495, 48.570010200000006]],
    [ "Enedis",[-4.514886298349738, 48.57658114440946]],
    [ "Enedis",[-4.517016457333196, 48.585982373314884]],
    [ "Enedis",[-4.589384939788458, 48.57141737628802]],
    [ "Enedis",[-4.562522100000001, 48.57009464999999]],
    [ "Enedis",[-4.4329106260001065, 48.50768425569112]],
    [ "Poste électrique Laos",[2.3006880426769722, 48.84923965755223]],
    [ "Enedis",[-4.458962711343607, 48.546301623465325]],
    [ "Enedis",[-4.481371485747974, 48.54448093523656]],
    [ "Enedis",[-4.5976137999999995, 48.5691212]],
    [ "Enedis",[-4.537495637611229, 48.551906495741164]],
    [ "Enedis",[-4.28545175, 48.2670174]],
    [ "Enedis",[-4.603220366975269, 48.26884192533575]],
    [ "Enedis",[-4.5973501500000005, 48.269709799999994]],
    [ "Enedis",[-4.595419718245646, 48.27200512491867]],
    [ "Enedis",[-4.4998309500000016, 48.223479149999996]],
    [ "Enedis",[-4.518388749999999, 48.22425125]],
    [ "Enedis",[-4.54720433149776, 48.33000506461341]],
    [ "Enedis",[-4.333296135200984, 48.56618933535901]],
    [ "Enedis",[-4.33489195, 48.568221349999995]],
    [ "Enedis",[-4.339721100000001, 48.56075815000001]],
    [ "Enedis",[-4.342311599999999, 48.5634969]],
    [ "Enedis",[-4.342194849999999, 48.55407965]],
    [ "Enedis",[-4.365719487949556, 48.64644116655768]],
    [ "Enedis",[-4.372957134971081, 48.646456752974665]],
    [ "Enedis",[-4.322527781460801, 48.56917100239746]],
    [ "Enedis",[-4.324649582958077, 48.57156540007471]],
    [ "Enedis",[-4.3206943971383085, 48.57334596703565]],
    [ "Enedis",[-4.315996899339705, 48.57090523590428]],
    [ "Enedis",[-4.318352649999999, 48.57247505]],
    [ "Enedis",[-4.319670462074158, 48.57504267749805]],
    [ "Enedis",[-4.326603400000001, 48.57543085000001]],
    [ "Enedis",[-4.33007555, 48.5692919]],
    [ "Enedis",[-4.32828805, 48.5717789]],
    [ "Enedis",[-4.3297246000000005, 48.574049599999995]],
    [ "Enedis",[-4.318060832599821, 48.577582797888674]],
    [ "Enedis",[-4.3240463, 48.57850855]],
    [ "Enedis",[-4.325002663276726, 48.579787080352716]],
    [ "Enedis",[-4.313143088728288, 48.5743657992622]],
    [ "Enedis",[-4.311340029825333, 48.57253004835442]],
    [ "Enedis",[-4.313187875042848, 48.56963962448399]],
    [ "Enedis",[-4.312322823559227, 48.568641705295676]],
    [ "Enedis",[-4.32777933835157, 48.56453878072564]],
    [ "Enedis",[-4.293133818810867, 48.57253805363062]],
    [ "Enedis",[-4.312857734991499, 48.537267544557054]],
    [ "Poste électrique Garibaldi",[2.3043761034463075, 48.8476451610529]],
    [ "CRAS",[6.030244746407647, 47.25128991726796]],
    [ "Poste Électrique de Montfort",[-1.947556759171084, 48.14823931145627]],
    [ "Enedis",[1.4906176091591992, 43.57777871742525]],
    [ "Enedis",[1.49479955, 43.580496000000004]],
    [ "Enedis",[1.494767537139334, 43.580543977814635]],
    [ "Enedis",[1.4948706999999999, 43.58263095]],
    [ "Enedis",[1.4875987849743326, 43.58031086764308]],
    [ "Enedis",[1.489812442123963, 43.583453970531394]],
    [ "Enedis",[6.357968392553084, 46.91080287069041]],
    [ "Enedis",[6.35824085099895, 46.91384169881207]],
    [ "Enedis",[6.3602525, 46.9121846]],
    [ "Enedis",[6.356045344090845, 46.91676100098848]],
    [ "Enedis",[6.3600788999999995, 46.914639650000005]],
    [ "Enedis",[6.358588850125548, 46.915780133270914]],
    [ "Enedis",[6.35781745, 46.91714855]],
    [ "Enedis",[6.3606331, 46.91722190000001]],
    [ "Enedis",[1.0311878745654777, 47.7942411154293]],
    [ "Enedis",[0.3558616500000013, 46.57073829999999]],
    [ "Enedis",[6.3495181, 46.9362527]],
    [ "Enedis",[0.3573138564180346, 46.56797866093662]],
    [ "Enedis",[2.213486766906515, 48.9131594924958]],
    [ "Enedis",[2.212517679730444, 48.91268066526049]],
    [ "Enedis",[-2.1309850481282018, 47.11303880638979]],
    [ "Enedis",[-2.1245633830742823, 47.11437082973505]],
    [ "Enedis",[-2.115702237373751, 47.109992982977985]],
    [ "Enedis",[-2.11646299889159, 47.110925333403664]],
    [ "Enedis",[-2.0796421179451103, 47.104249352362224]],
    [ "Enedis",[-2.078840258443038, 47.107275458559585]],
    [ "Enedis",[-2.08441706028142, 47.10501638953383]],
    [ "Enedis",[-2.0863229278148707, 47.10810391130853]],
    [ "Enedis",[-2.09117363342515, 47.11041641662175]],
    [ "Enedis",[-2.093061318884514, 47.11359700311277]],
    [ "Enedis",[-2.097964873824436, 47.10818930437116]],
    [ "Enedis",[-2.097473346604039, 47.11238908110606]],
    [ "Enedis",[-2.1026259186403884, 47.11300391709779]],
    [ "Enedis",[-2.102995239114681, 47.1086663003931]],
    [ "Enedis",[-2.0922982643233214, 47.117628849792055]],
    [ "Enedis",[-2.0994525052265103, 47.116777386506826]],
    [ "Enedis",[-2.11288845, 47.116715250000006]],
    [ "Enedis",[1.0370540302358524, 47.78995791105304]],
    [ "réséda",[6.089664066497193, 49.07183780802178]],
    [ "Enedis",[0.3658800714048031, 46.573410199058806]],
    [ "Enedis",[1.0358299368895885, 47.79504117028462]],
    [ "Enedis",[-1.5611782269542445, 47.20018754015842]],
    [ "Enedis",[1.471949868049099, 43.556528829327675]],
    [ "Île Verte",[5.741696638012565, 45.19611327779443]],
    [ "Enedis",[6.364406474869934, 46.89452997242976]],
    [ "Enedis",[6.3743668499999995, 46.900398]],
    [ "Enedis",[1.4846847703426311, 43.579000878658746]],
    [ "Église",[1.467277666673058, 43.550145249745945]],
    [ "Enedis",[6.3560786999999985, 46.89913675000001]],
    [ "Enedis",[1.4882978, 43.585014449999996]],
    [ "Enedis",[2.1739182326537065, 48.708563175376106]],
    [ "Enedis",[2.1774950754722684, 48.709322876766876]],
    [ "Enedis",[2.18592145, 48.71057730000001]],
    [ "Enedis",[1.4740737748290718, 43.553429729661715]],
    [ "Enedis",[1.4701185465387425, 43.55192917385938]],
    [ "Enedis",[1.4698100447020503, 43.55431398158808]],
    [ "Poste électrique de Cardinal Lemoine",[2.353153223943814, 48.84888702173378]],
    [ "ZAC Labège Station Épuration",[1.5023908844307623, 43.551812324320174]],
    [ "Enedis",[1.5062121719443484, 43.55469213343682]],
    [ "Enedis",[6.133832699999999, 43.04558095]],
    [ "Enedis",[3.7271909024084473, 47.99978194245032]],
    [ "Enedis",[3.730810505326256, 48.00044133737868]],
    [ "Enedis",[3.727961123140014, 48.001269757488586]],
    [ "Enedis",[6.3450612, 46.92218325]],
    [ "Enedis",[7.317475972574409, 47.96274844100883]],
    [ "Enedis",[1.0417563499999987, 47.79560899999999]],
    [ "Enedis",[3.168451287630473, 50.719565278803906]],
    [ "Enedis",[3.171116809963551, 50.718057134111]],
    [ "Enedis",[3.1711655499999996, 50.718667350000004]],
    [ "Enedis",[1.0439538499999996, 47.79321729999999]],
    [ "Poste électrique de Bissy",[5.900734464724601, 45.590254611658885]],
    [ "SNCF Réseau",[5.902568851311395, 45.5887877958474]],
    [ "RTE",[5.888409951538944, 45.59929208444191]],
    [ "Enedis",[6.41111696670948, 44.52682559919972]],
    [ "Enedis",[3.164427656573975, 50.71310325727616]],
    [ "Poste électrique de Beaubourg",[2.357435305909476, 48.860894206591865]],
    [ "RTE",[5.907196920796079, 45.58100338786227]],
    [ "Poste électrique de La Bathie",[6.443392466117953, 45.644695364204516]],
    [ "Poste électrique d'Albertville",[6.418252587318359, 45.66191433169304]],
    [ "Enedis",[6.354600304380839, 46.89936876802959]],
    [ "Enedis",[3.081192548041922, 50.65256643845404]],
    [ "Enedis",[3.0774571421134334, 50.64932385873371]],
    [ "Enedis",[4.817080832845321, 45.735367813169496]],
    [ "réséda",[6.173296414125185, 49.11732701240689]],
    [ "Poste électrique de Cholet",[-0.9021375520306452, 47.058730014426196]],
    [ "Enedis",[1.9366046682783526, 48.77481559429711]],
    [ "Enedis",[6.365369926956283, 46.88530160738213]],
    [ "Parc de la Toison d'or n°1",[5.053731919355342, 47.35468490544497]],
    [ "Enedis",[5.0660270026972105, 47.355139952798424]],
    [ "Enedis",[5.067343202826272, 47.35230818729092]],
    [ "Enedis",[5.0692139362452036, 47.35050873055623]],
    [ "Enedis",[6.344839482694428, 46.89766122216928]],
    [ "Enedis",[5.054653228978265, 47.35170170981295]],
    [ "Enedis",[5.0556468877216085, 47.35267002208875]],
    [ "Enedis",[5.05713406248199, 47.35290574377757]],
    [ "Enedis",[3.079977076335855, 50.64739806679127]],
    [ "Enedis",[5.061058310411171, 47.35245022140782]],
    [ "Enedis",[5.0665304944749545, 47.344652140014986]],
    [ "Enedis",[5.065001420049688, 47.342455159190244]],
    [ "Enedis",[5.060876109702567, 47.338909168986774]],
    [ "Enedis",[5.062641088644012, 47.33825007557878]],
    [ "Enedis",[5.067689802453726, 47.3391489247612]],
    [ "SER",[7.763316343608522, 48.62783493538688]],
    [ "Enedis",[6.3488430281673125, 46.898600417430096]],
    [ "Enedis",[6.344153567003498, 46.89510219364339]],
    [ "Enedis",[6.349682034308208, 46.8981596256688]],
    [ "Enedis",[6.353054989862492, 46.897618474794896]],
    [ "Enedis",[6.345336549593271, 46.89634108469324]],
    [ "Enedis",[5.0711686581371875, 47.34638959127024]],
    [ "Enedis",[0.3600018953566615, 46.56750494390082]],
    [ "Enedis",[1.0378973136651495, 47.786821124356834]],
    [ "Enedis",[1.0417607780844897, 47.78537907327846]],
    [ "Enedis",[1.041238945507898, 47.78219098319761]],
    [ "Enedis",[1.4791287773790485, 43.576437403322394]],
    [ "Enedis",[1.478973633821105, 43.57719558885825]],
    [ "Enedis",[3.1155501245582933, 50.65402870522461]],
    [ "Enedis",[3.1144701499999985, 50.65634134999999]],
    [ "Enedis",[6.403361679375396, 44.5253876337433]],
    [ "Enedis",[3.057396933333333, 50.62777586666667]],
    [ "Enedis",[3.052726563991104, 50.62604858966698]],
    [ "Enedis",[6.350609286713887, 46.92488358320538]],
    [ "Enedis",[6.35201675, 46.92978455]],
    [ "Enedis",[6.355122234349073, 46.926003048382185]],
    [ "Enedis",[6.3551831, 46.929191249999995]],
    [ "Baume les Dames",[6.35483871134456, 47.3462267863556]],
    [ "Enedis",[1.02850095, 47.80052919999999]],
    [ "Enedis",[6.344005750000001, 46.93044975000001]],
    [ "Enedis",[3.0417056000000002, 50.6855703]],
    [ "Enedis",[6.316762349999999, 46.91460735]],
    [ "RTE",[3.1839381222130734, 50.60638736198082]],
    [ "Enedis",[1.45982645, 43.568083349999995]],
    [ "Enedis",[1.4632240280804205, 43.57068190918517]],
    [ "Enedis",[1.4597955999999999, 43.5663427]],
    [ "Enedis",[-3.6340706323541743, 47.81819167657797]],
    [ "Enedis",[1.458339499398038, 43.56137102466659]],
    [ "Enedis",[1.461300911637664, 43.57000905617205]],
    [ "Enedis",[-3.6288698324430526, 47.8059093489777]],
    [ "Garzon",[-3.6326055891860856, 47.810078791743145]],
    [ "Enedis",[3.1572422865321736, 50.715676952642745]],
    [ "Enedis",[1.4602122862274098, 43.5642663609566]],
    [ "Enedis",[-4.481377343903613, 48.395068941352136]],
    [ "Enedis",[1.5636905999999993, 43.516228950000006]],
    [ "RTE",[7.777412649654949, 48.51905840494762]],
    [ "SER",[7.801437004770027, 48.652404310559085]],
    [ "60419058",[7.767855641544843, 48.665441373116884]],
    [ "Enedis",[4.365419449511478, 47.98979215484441]],
    [ "Enedis",[4.367023124133887, 47.99169512772977]],
    [ "Enedis",[1.6465733999999996, 46.156809499999994]],
    [ "Enedis",[1.6440764579142662, 46.15505932545587]],
    [ "Enedis",[1.4431816226803178, 43.60530035074045]],
    [ "Enedis",[1.4443498190795916, 43.60751248055246]],
    [ "Enedis",[1.7710137408818394, 48.861033891325484]],
    [ "Enedis",[1.772196499128979, 48.860085141645285]],
    [ "RTE",[2.6115982734940917, 48.73768723513286]],
    [ "Enedis",[2.21362035692674, 48.9106535662805]],
    [ "60778763",[2.217189150000001, 48.91457280000001]],
    [ "Enedis",[3.088443627130928, 50.67288767908766]],
    [ "Poste électrique de Pietrosella",[8.784487096480735, 41.816038999648164]],
    [ "Enedis",[6.309314549999999, 46.922462149999994]],
    [ "Enedis",[6.326614717855934, 46.88472358442024]],
    [ "Enedis",[6.3352378, 46.8903213]],
    [ "Enedis",[6.332299849999999, 46.88865694999999]],
    [ "Poste électrique de Saint-Florent",[-0.47480731486241223, 46.306178476503575]],
    [ "Enedis",[1.457205204780877, 43.567947577472566]],
    [ "Enedis",[1.4545002980349544, 43.564820341836125]],
    [ "Enedis",[0.3688162249310429, 46.57125674607128]],
    [ "Poste CSTB",[5.771776099999999, 45.185841249999996]],
    [ "La Boule",[2.2003730541483795, 48.887618723231874]],
    [ "Enedis",[1.4347424937768982, 43.6000595822184]],
    [ "Enedis",[1.4338323245568037, 43.599228823988206]],
    [ "Enedis",[3.090140082825319, 50.6545547113023]],
    [ "Enedis",[6.3133346999999995, 46.91314439999999]],
    [ "Enedis",[6.31110117028214, 46.914427696908156]],
    [ "Enedis",[6.310747592675541, 46.91675417838581]],
    [ "Enedis",[0.36255735028875535, 46.57052992751939]],
    [ "Enedis",[7.296322759053592, 47.948671344469666]],
    [ "Enedis",[3.040156380680956, 50.68754354641725]],
    [ "Enedis",[1.4335726933348198, 43.59446713978136]],
    [ "Enedis",[7.292680859492625, 47.95235051831287]],
    [ "Enedis",[7.2988034000000015, 47.95457620000001]],
    [ "Enedis",[1.4156533808157497, 43.57066725767216]],
    [ "Enedis",[0.36559008769792106, 46.56761075750131]],
    [ "Enedis",[4.359012610421758, 47.98949940728387]],
    [ "Enedis",[4.363986197736974, 47.98577766622796]],
    [ "Enedis",[5.049158543851216, 47.32520758602055]],
    [ "Enedis",[-0.622595, 43.22011815]],
    [ "Enedis",[3.2338702499999994, 50.62032735]],
    [ "Enedis",[-0.62600505, 43.21327045]],
    [ "Enedis",[-0.6213864343361605, 43.21618151489306]],
    [ "Enedis",[-0.6192849726168133, 43.216917145696065]],
    [ "Enedis",[-0.6203629164622508, 43.21954464908897]],
    [ "Enedis",[-0.6146742500000006, 43.22338419999999]],
    [ "Enedis",[5.5355115012096565, 46.7615306375607]],
    [ "Enedis",[5.5229396346021336, 46.772374184119826]],
    [ "Le Bois du Breuil",[0.19929102410839056, 48.03195570451769]],
    [ "Enedis",[1.438853008051618, 43.670526348681655]],
    [ "Enedis",[1.4171392199883108, 43.67857029073279]],
    [ "Enedis",[1.4171550602182883, 43.682100225767854]],
    [ "Enedis",[1.4350575693342782, 43.66998289469366]],
    [ "Enedis",[1.427866449543593, 43.66979191727447]],
    [ "Enedis",[1.4327183487612503, 43.67278712671355]],
    [ "Enedis",[1.421508321162883, 43.665186057029075]],
    [ "Enedis",[1.4301952206321498, 43.66373185877875]],
    [ "Enedis",[1.4134198079352993, 43.67256650875762]],
    [ "Enedis",[1.4200239306476223, 43.672180375785594]],
    [ "Enedis",[1.4249512989008857, 43.663197419660314]],
    [ "Enedis",[1.4360141851115005, 43.66841927065939]],
    [ "Enedis",[1.4218230760276938, 43.66970335426222]],
    [ "Enedis",[1.4275422223501766, 43.66071525392372]],
    [ "Enedis",[1.4245878518243926, 43.669615882499286]],
    [ "Enedis",[1.4162867302313815, 43.67582673878957]],
    [ "Enedis",[1.430034888136147, 43.67377736731656]],
    [ "Enedis",[1.4229621327594193, 43.667944105878085]],
    [ "Enedis",[1.4366355620297202, 43.676678187227004]],
    [ "Enedis",[1.4341631999999995, 43.6676156]],
    [ "Enedis",[1.413250470301046, 43.671337567202805]],
    [ "Enedis",[1.4289731160327617, 43.66020532881472]],
    [ "Enedis",[1.4289594323166879, 43.67264679908091]],
    [ "Enedis",[1.418067590764591, 43.665695963753656]],
    [ "Enedis",[1.4243316776289332, 43.6623894740483]],
    [ "Enedis",[1.416361548078407, 43.680135984005695]],
    [ "Enedis",[1.4234858034973856, 43.6648024214282]],
    [ "Enedis",[1.424379410151116, 43.6749893473383]],
    [ "Enedis",[1.4369350973800497, 43.67314950243771]],
    [ "Enedis",[1.4242415843752636, 43.66663339664594]],
    [ "Enedis",[1.4297647966838942, 43.66173191573246]],
    [ "Enedis",[1.4340554431825179, 43.67314039738381]],
    [ "Enedis",[1.422426069740024, 43.67826569301827]],
    [ "Carrières",[1.4212630607464876, 43.67431615014932]],
    [ "Enedis",[1.4095554994367578, 43.67967349607931]],
    [ "Enedis",[1.4185268475245185, 43.673188910569344]],
    [ "Enedis",[1.4193024667021708, 43.6759801683975]],
    [ "Enedis",[1.4104894898909155, 43.67152136415653]],
    [ "Enedis",[1.4238611918712931, 43.672350917814896]],
    [ "Enedis",[1.4227227833446485, 43.67167115002183]],
    [ "Saint-Pavace ZI",[0.19446350000000004, 48.0262025]],
    [ "Enedis",[1.4533585706134733, 43.5688791857448]],
    [ "Poste électrique de Fallou",[2.2613713377967124, 48.93790066375885]],
    [ "Poste électrique de Fallou",[2.259989542243848, 48.939330452190994]],
    [ "Enedis",[4.878827066419355, 45.42550006652024]],
    [ "Bon Temps",[4.9233953886421675, 45.42698684371959]],
    [ "Mattafard",[4.904627633468181, 45.42287426685389]],
    [ "Saint-Alban de Varèze",[4.905737800000002, 45.42213385]],
    [ "Enedis",[4.866729570672625, 45.40470460732797]],
    [ "Enedis",[-1.31270145, 46.32676015]],
    [ "Enedis",[4.855657969241186, 45.75632188135864]],
    [ "Enedis",[4.8567850324235335, 45.75667655769798]],
    [ "Enedis",[4.851440402507914, 45.78316648280105]],
    [ "Poste électrique de Saint-Amour",[4.850424771254242, 45.75532926551431]],
    [ "RTE",[4.850731015664112, 45.769907462410586]],
    [ "Enedis",[4.8483791706732315, 45.770229323689016]],
    [ "Enedis",[1.4505786258011855, 43.56990889272306]],
    [ "Enedis",[4.860784024860652, 45.763801054625894]],
    [ "Enedis",[4.891924028278141, 45.75045535424768]],
    [ "Enedis",[4.866847436448733, 45.75884772663276]],
    [ "Enedis",[4.8769268917365745, 45.744897533521566]],
    [ "Enedis",[4.875203341929101, 45.747780222252224]],
    [ "Enedis",[4.866766329206213, 45.749386310617155]],
    [ "Enedis",[4.893090564538303, 45.74123682349381]],
    [ "Enedis",[4.867792399999999, 45.75430765]],
    [ "Enedis",[4.869363840088626, 45.74759742377483]],
    [ "Enedis",[4.894989371046026, 45.746870641669645]],
    [ "Enedis",[4.890347863599368, 45.74508339457771]],
    [ "Enedis",[4.861225900977549, 45.750702279757455]],
    [ "Enedis",[4.877651154140425, 45.74572047954558]],
    [ "Enedis",[4.875269191816199, 45.751296084638305]],
    [ "Enedis",[4.885004955660927, 45.74163657886167]],
    [ "Enedis",[4.868495900000001, 45.75147330000001]],
    [ "Enedis",[4.87577035, 45.75133935]],
    [ "Enedis",[4.8735794, 45.74885329999999]],
    [ "Enedis",[4.879891534108359, 45.7484761907417]],
    [ "Enedis",[4.862576551928187, 45.7619637991677]],
    [ "Enedis",[4.870554482834688, 45.752185907704295]],
    [ "Enedis",[4.869112321938442, 45.74956165531207]],
    [ "62344869",[4.875061653067606, 45.74235600427719]],
    [ "Enedis",[4.88147545207643, 45.740090606736864]],
    [ "Enedis",[4.861523363378703, 45.741804173673856]],
    [ "Enedis",[4.864183231513256, 45.72861404645681]],
    [ "Enedis",[4.8540114999999995, 45.71926269999999]],
    [ "Enedis",[4.882063158891331, 45.73591562388728]],
    [ "Enedis",[4.88035764177098, 45.74076792050719]],
    [ "Enedis",[4.876614002245004, 45.738806009922996]],
    [ "Enedis",[4.8850123121886595, 45.726897939427666]],
    [ "Enedis",[4.8631655, 45.74136455]],
    [ "Enedis",[4.865136078268818, 45.742360881733624]],
    [ "62348406",[4.881221386311855, 45.739472056420375]],
    [ "Enedis",[4.872163146045831, 45.724998865009766]],
    [ "Enedis",[4.862082326273822, 45.73073891265122]],
    [ "Enedis",[4.880954540176523, 45.72852694227585]],
    [ "Enedis",[4.8628528398721285, 45.72701594082651]],
    [ "Enedis",[4.8634066894551475, 45.747978266719535]],
    [ "Enedis",[4.876456143551039, 45.733288281024784]],
    [ "Enedis",[4.876468560459013, 45.74177051467099]],
    [ "Enedis",[4.879061740718061, 45.74159674760361]],
    [ "Enedis",[4.884013917477132, 45.73999473340324]],
    [ "Enedis",[4.862689218052964, 45.731638002267196]],
    [ "Enedis",[4.863897736310977, 45.746860750516895]],
    [ "Enedis",[4.86235245, 45.728139350000006]],
    [ "Enedis",[4.866300714238948, 45.74554745004782]],
    [ "Enedis",[4.873564851227663, 45.72562520323438]],
    [ "Enedis",[4.867013026337645, 45.74247906354183]],
    [ "Enedis",[4.870351186915247, 45.74060042022148]],
    [ "Enedis",[4.868047731596573, 45.74284951002788]],
    [ "Enedis",[2.3546284578458074, 48.82010116224758]],
    [ "Enedis",[1.4236625656803188, 43.56889851138745]],
    [ "Enedis",[1.4238002433638912, 43.57328808797523]],
    [ "Enedis",[1.4234386861788304, 43.57094560589126]],
    [ "Enedis",[1.424247700849438, 43.57072453099904]],
    [ "RTE",[1.4337659112857382, 43.56633958444476]],
    [ "Enedis",[1.4362270874561156, 43.57030322442724]],
    [ "Enedis",[2.4688371006512297, 48.7661577672154]],
    [ "Romulus",[2.3109477996375225, 48.945910905418465]],
    [ "Asperge",[2.334996213186371, 48.937388114256265]],
    [ "Enedis",[2.33606852594622, 48.93579211898265]],
    [ "Caille",[2.3102716999999995, 48.9411384]],
    [ "Thais",[2.32897004464486, 48.943660385345176]],
    [ "ESSeyssel",[6.127929023787925, 45.93339797931599]],
    [ "Than",[2.3263105500000005, 48.94438295000001]],
    [ "Hameau du Veran",[6.1114597, 45.951315099999995]],
    [ "La Tuilerie",[6.128324655618364, 45.94668584075615]],
    [ "ESSeyssel",[6.1274182687178, 45.94055469257316]],
    [ "Promery Village",[6.111544699999999, 45.953667300000006]],
    [ "Grafenberg",[6.123795649999998, 45.93789545]],
    [ "ESSeyssel",[6.088468631312247, 45.96978735319088]],
    [ "Les Carts",[6.109854417923513, 45.95073138707657]],
    [ "Enedis",[1.4886388653702398, 43.52512706246896]],
    [ "Iris Bleu",[1.4778333246969613, 43.52877011824247]],
    [ "Enedis",[1.4833802999999999, 43.53239905]],
    [ "Enedis",[1.4842728805545593, 43.52371388693166]],
    [ "Enedis",[1.4944868453536864, 43.52671163971022]],
    [ "Enedis",[1.4933944931379848, 43.52869358770446]],
    [ "Enedis",[1.491404133001245, 43.53291361909506]],
    [ "Enedis",[1.4817506698242342, 43.52297331572561]],
    [ "Enedis",[1.4963425439879423, 43.53037075882599]],
    [ "Enedis",[1.4850321242447992, 43.53195829825283]],
    [ "Enedis",[1.488052013181969, 43.52814992840932]],
    [ "Enedis",[1.480708497142968, 43.52496046239058]],
    [ "Enedis",[1.4807851919152941, 43.53120392949342]],
    [ "SRD",[0.4118613036249348, 46.38137257889739]],
    [ "Enedis",[1.4856643167574834, 43.52816621467158]],
    [ "Enedis",[1.4777143876175298, 43.51823075839109]],
    [ "SRD",[0.40889534461564514, 46.36837867888403]],
    [ "Enedis",[1.5051615499999997, 43.50106095]],
    [ "Enedis",[1.5097471233286963, 43.501420537185446]],
    [ "Enedis",[1.5061692736109338, 43.50350654567029]],
    [ "Enedis",[4.04390955, 45.2035928]],
    [ "Rosset",[6.193340158723693, 45.93426436856756]],
    [ "Carroz Nord",[6.195656517488177, 45.93418587975781]],
    [ "Vers les Moulins",[6.190481600000001, 45.93542764999999]],
    [ "Enedis",[2.858021671766119, 42.71031481079014]],
    [ "Enedis",[-0.2619628914863848, 43.20470940510705]],
    [ "Enedis",[-0.25568271936213954, 43.209691562278245]],
    [ "Enedis",[-0.25701100000000004, 43.204336149999996]],
    [ "Enedis",[-0.25940505462524005, 43.204643295200924]],
    [ "Enedis",[-0.2531168353977107, 43.21239346749094]],
    [ "Enedis",[1.4519050816506858, 43.51593282311853]],
    [ "Enedis",[1.459954000524098, 43.52957692777429]],
    [ "Plein Soleil",[2.0720851713588266, 42.57753220565903]],
    [ "Enedis",[2.0774797761914616, 42.58929218990117]],
    [ "Enedis",[2.0734284473658295, 42.58297847011324]],
    [ "Le Soula",[2.078744307184152, 42.58933893370682]],
    [ "Enedis",[1.83425595, 42.548564199999994]],
    [ "Enedis",[-1.3991342182564102, 43.770724333400985]],
    [ "Enedis",[-1.3988291499999999, 43.77532905]],
    [ "Enedis",[1.8933478599043718, 42.45994980826504]],
    [ "Enedis",[1.8894013776428007, 42.46601738239769]],
    [ "Enedis",[1.8980856574769576, 42.46221826090365]],
    [ "Enedis",[1.8906566639632338, 42.459198590808334]],
    [ "Enedis",[1.89151789333612, 42.46556068525928]],
    [ "Enedis",[1.889557633426818, 42.463633125887085]],
    [ "Enedis",[6.169770618173376, 45.958328914767876]],
    [ "Les Vignes",[6.18337402362141, 45.93956576186294]],
    [ "La Rainche",[6.205447334721615, 45.954259151748666]],
    [ "Enedis",[6.1802278347237625, 45.94198082834167]],
    [ "Enedis",[6.1661103, 45.952973650000004]],
    [ "Plan Morget",[6.169304887674081, 45.95493019406517]],
    [ "Fruitiga",[6.1948813499999975, 45.95177074999999]],
    [ "Enedis",[6.171808943955352, 45.9555057508787]],
    [ "Enedis",[6.189824357339265, 45.949319392215536]],
    [ "Enedis",[2.011982684030605, 42.46798775116545]],
    [ "Enedis",[2.0152635532987073, 42.47005266076795]],
    [ "Enedis",[-0.26802784096068266, 43.28396769355308]],
    [ "ZA de Trevas",[4.1642948767599295, 45.25499123974532]],
    [ "AC3T Champ des Bois",[4.185560398949007, 45.236648692993384]],
    [ "AR Aqueduc",[2.3353531155917993, 48.80179883127905]],
    [ "Enedis",[2.495673061875222, 48.59584862421064]],
    [ "Enedis",[2.49401756547921, 48.590846065897566]],
    [ "Enedis",[2.4942366258901023, 48.60002015732789]],
    [ "Enedis",[-0.2885465662676729, 43.29116313361523]],
    [ "Enedis",[2.5005649213842194, 48.6004922592458]],
    [ "Enedis",[-0.28975144788925833, 43.291699742596464]],
    [ "Enedis",[2.4931475394405447, 48.587759309891965]],
    [ "Enedis",[2.499897878742581, 48.588157054789576]],
    [ "Enedis",[2.543717872839691, 48.77062085466818]],
    [ "Enedis",[2.5601798825474193, 48.78026454896534]],
    [ "Enedis",[2.545848602014707, 48.76928400864975]],
    [ "Enedis",[2.5474630467840194, 48.77868222100344]],
    [ "Enedis",[2.541334847631953, 48.77314270703971]],
    [ "Enedis",[2.5441540426784606, 48.77339696710983]],
    [ "Enedis",[2.542438995907086, 48.77052751926859]],
    [ "Enedis",[6.145786148881889, 45.923916763449725]],
    [ "Anjou",[6.16364680839678, 45.91082462033935]],
    [ "Enedis",[6.163741363882477, 45.92153252907081]],
    [ "Enedis",[2.988821993511819, 42.5967121894883]],
    [ "Bello",[6.121492140407573, 45.923460680105336]],
    [ "Enedis",[6.154370491188633, 45.908758815039114]],
    [ "Enedis",[6.156059426002222, 45.92466189507173]],
    [ "Paillard",[6.1671951156781475, 45.931174885633524]],
    [ "Res d'Albigny",[6.145151352496274, 45.90646365230728]],
    [ "Enedis",[6.151349751798319, 45.91461836497466]],
    [ "Les Carrés",[6.139934619108439, 45.91117574878201]],
    [ "Petit Port",[6.1598068999999995, 45.906187849999995]],
    [ "Pacobat",[6.1197750000000015, 45.92222075]],
    [ "Enedis",[6.147617517224637, 45.9074648028427]],
    [ "Enedis",[6.168242043766026, 45.92769790660582]],
    [ "Enedis",[6.1529369, 45.9252315]],
    [ "Les Barattes - Rue du Pont de Thé",[6.1600294858002025, 45.91130773299063]],
    [ "Baronnie",[2.421481480938818, 48.722737295126585]],
    [ "Manant",[2.420136330954634, 48.72284194646045]],
    [ "Enedis",[6.1310499839768795, 45.92770252268605]],
    [ "Enedis",[6.162746541700243, 45.929047876477014]],
    [ "Enedis",[6.1663405034676755, 45.92007458355273]],
    [ "Enedis",[6.148272303915217, 45.913714179836035]],
    [ "Foch",[2.422275684160618, 48.725338851430436]],
    [ "Résidence du Port",[6.154835961832247, 45.90625053308808]],
    [ "Enedis",[6.166668050000001, 45.926170299999995]],
    [ "Clarines 1",[6.138418733814609, 45.91530152562008]],
    [ "Enedis",[6.1338856999999996, 45.924884199999994]],
    [ "Enedis",[-0.3154426652494498, 43.29964994482567]],
    [ "Enedis",[-0.3160884888843747, 43.29799650397589]],
    [ "Enedis",[6.141404750437278, 45.924106798178464]],
    [ "Enedis",[-0.31016197040129284, 43.29007029566388]],
    [ "Enedis",[-0.30801252146867, 43.299156667951245]],
    [ "Marolly",[6.1586443253025065, 45.90677919472693]],
    [ "Centre technique",[6.1542064, 45.92907795]],
    [ "Enedis",[6.159955057208216, 45.923150424427426]],
    [ "Enedis",[-0.30467418155415993, 43.301089541941394]],
    [ "Genie",[6.119327715921673, 45.923110017538484]],
    [ "Enedis",[6.123036147697198, 45.930405409029476]],
    [ "Clarines 2",[6.14068665, 45.91607755]],
    [ "Enedis",[1.9219032640693332, 42.45947691432548]],
    [ "Enedis",[6.139746926121689, 45.92361287773892]],
    [ "Enedis",[6.147057605684755, 45.91632096942291]],
    [ "Chavanon",[4.190178250000001, 45.2740783]],
    [ "La source",[6.161519800000001, 45.90082460000001]],
    [ "Enedis",[1.9119601931166605, 42.46102396702351]],
    [ "Pont-de-Lignon",[4.1467020745263525, 45.25956708938438]],
    [ "Enedis",[6.151316850000001, 45.91842970000001]],
    [ "Enedis",[6.147529980309867, 45.92982120431148]],
    [ "Enedis",[-0.29646885, 43.2994993]],
    [ "Enedis",[6.155545057886994, 45.909961725091264]],
    [ "Enedis",[6.171709290720178, 45.926293568556225]],
    [ "Enedis",[6.128715899999999, 45.9282575]],
    [ "Enedis",[6.170324850000001, 45.9308304]],
    [ "Enedis",[-0.3113324532345261, 43.29799814391073]],
    [ "Guillermin",[6.148923610637024, 45.90893539426745]],
    [ "La Borie 2",[4.183349999999999, 45.28137974999999]],
    [ "La Borie 1",[4.184954591622196, 45.277585468493925]],
    [ "FT.ROMEU-ST.PAUL",[2.0426388834851394, 42.50432609609396]],
    [ "La Campagne",[4.166915582622125, 45.31633269870389]],
    [ "ESSeyssel",[6.128355407389777, 45.94353097387353]],
    [ "Magie",[6.152845958302805, 45.9489575128127]],
    [ "ESSeyssel",[6.129794576291669, 45.94402981020673]],
    [ "ESSeyssel",[6.139688463423543, 45.936407789836124]],
    [ "SRD",[0.37114248438486935, 46.59259605296665]],
    [ "SRD",[0.35052751617700084, 46.59731117335166]],
    [ "SRD",[0.3632879470081149, 46.595014083767104]],
    [ "SRD",[0.3489022833552515, 46.60107109939214]],
    [ "Enedis",[2.904787343700091, 42.73586964959855]],
    [ "SRD",[0.35805450553753365, 46.59404291898766]],
    [ "Enedis",[6.1056642267493855, 45.91601790575439]],
    [ "Enedis",[6.0869375377924975, 45.91307524388856]],
    [ "SRD",[0.3575948547185882, 46.603790626930866]],
    [ "Enedis",[6.104624909087209, 45.91848772894118]],
    [ "Enedis",[6.101545699999999, 45.91455280000001]],
    [ "Pallud",[6.09494358115203, 45.91036385567139]],
    [ "Cote Merle",[6.098472900000001, 45.9209699]],
    [ "Enedis",[6.090245500000001, 45.90942444999999]],
    [ "Enedis",[0.347873753797032, 46.60196886890086]],
    [ "Meythet",[6.09532755, 45.91289154999999]],
    [ "Enedis",[6.0876672733670425, 45.916000601707154]],
    [ "SRD",[0.3544873488825695, 46.60458584814264]],
    [ "SRD",[0.3623229526197224, 46.59685524425914]],
    [ "ZI N2",[6.100443066712726, 45.9133929482699]],
    [ "Enedis",[6.088442499999999, 45.91467125]],
    [ "Z4Meythet",[6.10310215, 45.915397950000006]],
    [ "Enedis",[6.092332180776588, 45.91336620276613]],
    [ "Enedis",[6.097499003616526, 45.918115516671484]],
    [ "SRD",[0.3517195668574642, 46.598382999399426]],
    [ "Enedis",[6.093133318240464, 45.913413557480496]],
    [ "Enedis",[6.103478233617747, 45.92429020303375]],
    [ "ZI N1",[6.099170267680947, 45.912377595653076]],
    [ "Enedis",[6.089488649856424, 45.912749224327634]],
    [ "Enedis",[2.911702769670741, 42.73946807893564]],
    [ "Groupe scolaire",[6.094404172550643, 45.91635206512556]],
    [ "Enedis",[2.9164883424746084, 42.741810787903965]],
    [ "Enedis",[0.35526531896517405, 46.61075366925373]],
    [ "SRD",[0.3638526326677455, 46.60016481114833]],
    [ "Enedis",[2.9174768150030554, 42.74626115257564]],
    [ "SRD",[0.3661945090347193, 46.59811366947807]],
    [ "Enedis",[2.9143278271941244, 42.74690869151171]],
    [ "Enedis",[2.898507398036731, 42.73807711594476]],
    [ "ESSeyssel",[6.0231077471386225, 46.00200366670763]],
    [ "ESSeyssel",[6.043504803006012, 45.98709291649966]],
    [ "ESSeyssel",[6.072517383456773, 46.004519626149815]],
    [ "Enedis",[2.8986039707629727, 42.74166792612292]],
    [ "ESSeyssel",[6.041752551645315, 45.99894882229591]],
    [ "ESSeyssel",[6.067091699999999, 45.997115099999995]],
    [ "SRD",[0.3577133403033613, 46.59783353196504]],
    [ "Enedis",[2.487157388720601, 48.61706142026468]],
    [ "SRD",[0.3622881982128346, 46.59871153344191]],
    [ "SRD",[0.36844275445234853, 46.5983531696988]],
    [ "Enedis",[2.502481847491408, 48.622204087903775]],
    [ "Enedis",[2.495200305030235, 48.61753400863662]],
    [ "Enedis",[2.488095305274778, 48.62860343614274]],
    [ "Enedis",[2.491710989548041, 48.635766788109805]],
    [ "Enedis",[2.487633349623905, 48.634326051082105]],
    [ "Enedis",[2.5007456116433286, 48.620334469828904]],
    [ "Enedis",[2.4951197000286482, 48.6285574433852]],
    [ "Enedis",[2.5010040716364434, 48.61891123165407]],
    [ "Enedis",[2.0461391128662907, 42.45864669805235]],
    [ "Enedis",[2.0280009420792786, 42.45227550763011]],
    [ "Enedis",[2.490443150000001, 48.63734315]],
    [ "Enedis",[2.0384860500000013, 42.463983799999994]],
    [ "Enedis",[2.034739804989252, 42.465255144770346]],
    [ "Enedis",[2.0269883317527224, 42.453288176220575]],
    [ "Enedis",[2.042143590867859, 42.45777467971258]],
    [ "Enedis",[2.0403266845910655, 42.45562634567075]],
    [ "Enedis",[1.9968318000000012, 42.50020105]],
    [ "Le Grand Epgny Es",[6.083448799210202, 45.93367351435974]],
    [ "ZI Mandallaz 1",[6.07778631430334, 45.93735993954296]],
    [ "Crets",[6.094022882586141, 45.940585005794006]],
    [ "Enedis",[6.0763881663260895, 45.94015217009934]],
    [ "Enedis",[6.089224246965688, 45.93913729502204]],
    [ "Rue des Peupliers",[6.081401719223471, 45.93494435027903]],
    [ "Enedis",[6.08886425726873, 45.93211382491263]],
    [ "Chez Pinget",[6.091429849999999, 45.94689165]],
    [ "Perdrix",[6.070778229867926, 45.9401243023738]],
    [ "Enedis",[6.087077846549835, 45.93848462001948]],
    [ "Nante",[6.085059055465837, 45.93712797688154]],
    [ "Mandalla3",[6.079561584801088, 45.93894228941023]],
    [ "Enedis",[6.068045721417613, 45.93794563219054]],
    [ "ZI Mandallaz 2",[6.079803083707855, 45.936910309657264]],
    [ "Enedis",[6.07239049920737, 45.93971034512291]],
    [ "Centre Village",[6.089811993717202, 45.939366188942856]],
    [ "Enedis",[6.091199715555671, 45.937130561586464]],
    [ "Enedis",[6.084039737478238, 45.92688006174531]],
    [ "Enedis",[7.258614084647802, 47.95359319862497]],
    [ "Enedis",[7.257413, 47.9552367]],
    [ "Enedis",[1.9710143499999997, 42.4146791]],
    [ "Enedis",[2.4244502116358504, 48.80499310244301]],
    [ "Enedis",[2.41530475, 48.81491665]],
    [ "Enedis",[2.423737489321504, 48.80590337369844]],
    [ "Enedis",[2.4255407000000004, 48.802896749999995]],
    [ "Enedis",[2.4288861138407225, 48.78036722406679]],
    [ "Enedis",[2.4255787499999997, 48.79433635]],
    [ "Enedis",[1.981830187827667, 42.41870841134453]],
    [ "Enedis",[1.9824300660889314, 42.41536728327583]],
    [ "Enedis",[2.425739493852647, 48.79259287407796]],
    [ "Enedis",[1.9857817906398654, 42.411927637011964]],
    [ "Enedis",[2.4110733158355475, 48.80904476410276]],
    [ "Enedis",[1.9899501374914756, 42.410918084585205]],
    [ "Enedis",[2.4181477177746196, 48.77764798039259]],
    [ "Eudes",[2.4275957125665415, 48.79643701313777]],
    [ "Enedis",[2.4259443604984345, 48.80039118308115]],
    [ "Enedis",[1.8964244049411672, 43.899725814208374]],
    [ "Enedis",[-0.7763937416183404, 43.487144136244446]],
    [ "Enedis",[1.8657691110368373, 43.87795046055796]],
    [ "Enedis",[1.883301258063458, 43.89387856672109]],
    [ "Enedis",[-0.7796421904692798, 43.48855760669128]],
    [ "Enedis",[-0.7663469689357155, 43.48814180085475]],
    [ "Enedis",[-0.7645575440467723, 43.47967762256696]],
    [ "Enedis",[-0.7885772938904708, 43.48244170436619]],
    [ "Enedis",[-0.7743377499999999, 43.4968578]],
    [ "Enedis",[-0.7734736237275522, 43.48818162944094]],
    [ "Enedis",[-0.8058282937176753, 43.47794578181899]],
    [ "Enedis",[-0.7648106085837818, 43.49173964162843]],
    [ "Enedis",[-0.7681003055782847, 43.48917807580412]],
    [ "Enedis",[-0.7845246249576238, 43.49032440399154]],
    [ "Enedis",[-0.7841278074295388, 43.48261433881563]],
    [ "Enedis",[-0.7677947525770968, 43.4808877839025]],
    [ "Enedis",[-0.7704345500000002, 43.495019150000005]],
    [ "Enedis",[-0.7783070670622632, 43.484863766618]],
    [ "Enedis",[-0.7765448590614108, 43.494019973405585]],
    [ "Enedis",[-0.7689855191871704, 43.4916168303583]],
    [ "Enedis",[-0.7870410420527397, 43.48491407282144]],
    [ "Enedis",[-0.7782531613763057, 43.497037404993314]],
    [ "Enedis",[-0.7748710999999999, 43.483459749999994]],
    [ "Enedis",[-0.7774564844151832, 43.49935792357603]],
    [ "Longiret",[6.095332255203757, 45.884424259579376]],
    [ "Sacconges",[6.102329245050947, 45.87477298349088]],
    [ "Enedis",[6.113164580125785, 45.8883554787485]],
    [ "Enedis",[6.058277825292543, 45.84253229680476]],
    [ "PMP Car Crolard",[6.1117134404084315, 45.88520019958676]],
    [ "Enedis",[6.102340172775031, 45.88123002158837]],
    [ "La Fiarde",[6.065843418795758, 45.88305015433263]],
    [ "Enedis",[6.115058288254734, 45.89210546535905]],
    [ "Enedis",[6.077068049364721, 45.88566438444476]],
    [ "OTI",[6.108631649999999, 45.89421955000001]],
    [ "ESSeyssel",[6.088832288820098, 45.8616141300399]],
    [ "Menu",[6.116558617720862, 45.89162676149347]],
    [ "Les Matines",[6.083620337946419, 45.86258548619808]],
    [ "Branchy",[6.0708101876209515, 45.88217772617303]],
    [ "Enedis",[6.099461927752712, 45.88325364016937]],
    [ "Enedis",[6.073184769010798, 45.885402969463485]],
    [ "Enedis",[6.091286749999999, 45.88018885]],
    [ "ESSeyssel",[6.086625220325222, 45.86191007960556]],
    [ "Barral P2",[6.115081075457227, 45.89061938676688]],
    [ "Cedipack",[6.080730146966784, 45.88196144964033]],
    [ "Les Feuillantines",[6.081395646115172, 45.86084728457346]],
    [ "Les Forges",[6.081042071396384, 45.85868582419584]],
    [ "Enedis",[6.107402259412988, 45.88523717863863]],
    [ "ESSeyssel",[6.08922006592485, 45.86441645903115]],
    [ "Sainte-Catherine",[6.110430988546609, 45.891202296933955]],
    [ "Enedis",[6.091129047886434, 45.870282383190904]],
    [ "Daviet",[6.115307175147295, 45.88829309660169]],
    [ "ESSeyssel",[6.068513032758108, 45.8639022571002]],
    [ "Enedis",[6.085318590735596, 45.88366911127936]],
    [ "SDEL",[6.112428756674133, 45.88332334864393]],
    [ "La Piece du Chateau",[6.091083850525831, 45.859661905849514]],
    [ "P2 Square Joffre",[1.8967709999999987, 43.90343565]],
    [ "62594012",[6.111704564044268, 45.88522628644897]],
    [ "Enedis",[6.104182633906787, 45.88599270098892]],
    [ "Enedis",[6.109154509865673, 45.88482777320804]],
    [ "STADE DE LA VALLÉE",[6.0919962080593075, 45.885661737468475]],
    [ "Enedis",[6.086655463145061, 45.86945108095803]],
    [ "Enedis",[6.1150969287068495, 45.886190790884164]],
    [ "Pré de Vassy 1",[6.092377082310752, 45.88921918097143]],
    [ "Enedis",[6.083289033782458, 45.88511000440866]],
    [ "Enedis",[-0.7517692854688641, 43.320312662928025]],
    [ "Enedis",[-0.7611171597270101, 43.32386191022649]],
    [ "ZAE Cesardes 1",[6.082515410281516, 45.889724396669216]],
    [ "Enedis",[-0.7496083203229176, 43.32214114804773]],
    [ "Medan Duret",[6.112845848887623, 45.88269457609162]],
    [ "PREGOUGEON",[6.088395154385678, 45.88645902939678]],
    [ "Enedis",[6.09646543388168, 45.88275196610014]],
    [ "Neigeos",[6.091652462599416, 45.88236322509982]],
    [ "USN",[6.1086429568116, 45.89318224711752]],
    [ "Chez Blot",[6.08529403940197, 45.85778285536655]],
    [ "Rava SARL Nickelag",[6.116062326825659, 45.88941090211294]],
    [ "ESSeyssel",[6.084980260331728, 45.85975808733811]],
    [ "Michelin",[6.113030813382492, 45.88552542506803]],
    [ "Prés Bouveaux 2",[6.08170136215122, 45.882182675464406]],
    [ "Les terrasses du Semnoz",[6.104726960632087, 45.884799561435855]],
    [ "Enedis",[6.068384803731386, 45.83507247110333]],
    [ "Enedis",[6.113979849999999, 45.89235379999999]],
    [ "Enedis",[6.107040866167776, 45.89329738287162]],
    [ "ESSeyssel",[6.086483300000001, 45.86416460000001]],
    [ "ESSeyssel",[6.091967513341934, 45.856739934372634]],
    [ "Champ Pâté",[6.088855806337465, 45.85931207287414]],
    [ "Les Barras",[6.0857306640662605, 45.88174402720611]],
    [ "Enedis",[-0.6284727761004693, 43.37168126576227]],
    [ "Enedis",[-0.6227308969479182, 43.37524434294681]],
    [ "Enedis",[-0.63308218775545, 43.36908679741439]],
    [ "Enedis",[-0.6312609971391621, 43.37625118486378]],
    [ "Enedis",[-0.6267606670465256, 43.368133149636606]],
    [ "Enedis",[-0.6333393634227776, 43.37163587457171]],
    [ "Enedis",[-0.6304679081295033, 43.36656379463613]],
    [ "Enedis",[-0.6250880863964462, 43.3722749386452]],
    [ "Enedis",[3.5360946210402475, 47.86195954283583]],
    [ "Enedis",[3.525128463894051, 47.871579778510416]],
    [ "Enedis",[3.5279389231178166, 47.87418517439525]],
    [ "Enedis",[3.520890945828337, 47.881106367987485]],
    [ "Enedis",[3.53155465, 47.86510175]],
    [ "Enedis",[3.527995429447511, 47.86944097214294]],
    [ "Enedis",[3.5275343015292373, 47.88162539425019]],
    [ "Enedis",[3.5253040643508733, 47.87821401662175]],
    [ "Enedis",[3.518157351772845, 47.86986187558471]],
    [ "Enedis",[3.5188805204017344, 47.87104619657123]],
    [ "Enedis",[3.514884789969359, 47.92712100257359]],
    [ "Enedis",[3.557335767913171, 47.81001383706086]],
    [ "Enedis",[3.5826599755699493, 47.789987325165676]],
    [ "Enedis",[3.580298806433046, 47.78272914501787]],
    [ "Enedis",[3.566169661272454, 47.793859230576004]],
    [ "Enedis",[3.5778304220322386, 47.82811902779587]],
    [ "Enedis",[3.5495720822330887, 47.79352575615322]],
    [ "Enedis",[3.5640548769646005, 47.79121467111497]],
    [ "Enedis",[3.587832779417575, 47.810739610195625]],
    [ "Enedis",[3.5582432848529586, 47.7878216612332]],
    [ "Enedis",[3.5543818130438907, 47.80973510637611]],
    [ "Enedis",[3.5783227159616, 47.78330281759919]],
    [ "Enedis",[3.582595070873381, 47.80491525585737]],
    [ "Enedis",[3.5786666192298866, 47.82520489345444]],
    [ "Enedis",[3.5805830048035947, 47.78336221449541]],
    [ "Enedis",[3.5836986358398133, 47.779622355958026]],
    [ "Enedis",[3.5787619578000482, 47.82250948648391]],
    [ "62632273",[3.559386420718878, 47.81422619401096]],
    [ "Enedis",[3.5731837307105185, 47.808592472299765]],
    [ "Enedis",[3.585937697692487, 47.779597564262374]],
    [ "Enedis",[3.584430896588565, 47.82209748597477]],
    [ "Enedis",[3.5924858568253404, 47.80445046629773]],
    [ "Enedis",[3.5936490696302403, 47.78341689895628]],
    [ "Enedis",[3.567149109271955, 47.796679593859736]],
    [ "Enedis",[3.574846669513804, 47.822434445150456]],
    [ "Enedis",[3.5846062815016944, 47.8101350948258]],
    [ "Enedis",[3.5515862554705917, 47.78926767266359]],
    [ "Enedis",[3.5891678798882216, 47.78156920148852]],
    [ "Enedis",[3.5736580123627473, 47.79124054484845]],
    [ "Enedis",[3.579331977676464, 47.77921116420674]],
    [ "Enedis",[3.5744041756861473, 47.79733597961051]],
    [ "Enedis",[3.5795598122270857, 47.78051200424722]],
    [ "Enedis",[3.5821649223482157, 47.79814953222301]],
    [ "Enedis",[3.5852258164972115, 47.80622577431678]],
    [ "Enedis",[3.5607083930794996, 47.811331252545806]],
    [ "Enedis",[3.558188393809944, 47.78614809686322]],
    [ "Enedis",[3.5645537092302866, 47.8073904931573]],
    [ "Enedis",[3.5872437, 47.7796819]],
    [ "Enedis",[3.5689000832835047, 47.814920503572935]],
    [ "Enedis",[3.5659205807540304, 47.786937016235846]],
    [ "Enedis",[3.5558492427480997, 47.8101628065453]],
    [ "Enedis",[3.5576056264060467, 47.79019105295805]],
    [ "Enedis",[3.5628431235950795, 47.81433347920518]],
    [ "Enedis",[3.589562144645553, 47.783347726353504]],
    [ "ZA le Marais",[6.099201827898902, 45.94795351839396]],
    [ "Genotte",[6.11049305, 45.9363982]],
    [ "Enedis",[6.102839959344255, 45.942057533667395]],
    [ "Enedis",[6.107313291190626, 45.9353359254673]],
    [ "Enedis",[6.120701011741391, 45.938079718832746]],
    [ "Enedis",[6.116741455623928, 45.92907467740265]],
    [ "Le Grand Champ",[6.099374999999999, 45.93800215]],
    [ "Enedis",[6.103276150000001, 45.94025609999999]],
    [ "Enedis",[6.109913746288303, 45.93245249550146]],
    [ "Aerodrome",[6.106030149999999, 45.928435349999994]],
    [ "Le Tramblay",[6.1061971236549555, 45.9465712446867]],
    [ "Enedis",[6.108611349999999, 45.94234885]],
    [ "Enedis",[6.098341824292813, 45.93920750657492]],
    [ "Portes d'Annecy",[6.108966918733671, 45.906660937808475]],
    [ "Camp militaire",[6.096741015622998, 45.89264248964244]],
    [ "Coupure",[6.1095558113360875, 45.910352247496135]],
    [ "Molasses",[6.109049850000002, 45.89514665]],
    [ "Saint-Francois",[6.096470335980251, 45.90285994327907]],
    [ "Levray P2",[6.0871735962898805, 45.89156761936344]],
    [ "Poste électrique de Tasset",[6.102684852100731, 45.90913274606225]],
    [ "Centre paroissial",[6.1103102722249965, 45.90759191799294]],
    [ "Poste électrique de Cran",[6.106812449045937, 45.90488178394591]],
    [ "Enedis",[6.086835550000001, 45.900967650000005]],
    [ "Benjamins",[6.116018226964761, 45.90992390854597]],
    [ "Hameau Perolliere",[6.092286051775235, 45.900930122805995]],
    [ "ZA Levray",[6.088269304438375, 45.89358857903411]],
    [ "Miko",[6.086981490193786, 45.89172529568232]],
    [ "Renault",[6.10419260799134, 45.908407526709965]],
    [ "Enedis",[6.114077774255346, 45.907190816078156]],
    [ "Enedis",[6.106550096919324, 45.89753403802429]],
    [ "Liberte 19 rue",[6.113463572361738, 45.90905970898619]],
    [ "Eclairage public",[6.109229852770536, 45.90120478134865]],
    [ "Les coteaux",[6.104932899999999, 45.89632374999999]],
    [ "Perolles",[6.10374305, 45.89599905000001]],
    [ "Enedis",[6.111488588850211, 45.91290316385832]],
    [ "Les Asters",[6.096662544420763, 45.90396076477835]],
    [ "La Croix de l'Arbre",[4.138614849999999, 45.2307645]],
    [ "Cimetière",[4.139423207040673, 45.22688179237899]],
    [ "ZA Larjallier",[4.136643927706724, 45.23056452206981]],
    [ "Chatcourt",[4.140545765854805, 45.24014837637369]],
    [ "Lotissement Le Sabot",[4.148065493532338, 45.22564212139303]],
    [ "ZA Lachamp",[4.1337771, 45.2278478]],
    [ "Wilson",[5.795713492831593, 45.18255678587277]],
    [ "Montbarnier",[4.132090866517033, 45.13904134825842]],
    [ "Dunkerque Yssingeaux",[4.118112249999999, 45.1439929]],
    [ "GMF",[4.12892075, 45.13283704999999]],
    [ "Lotissement Gaucher",[4.126124299999999, 45.13696805]],
    [ "Enedis",[4.11745925, 45.14461895]],
    [ "La Galoche",[4.121693178819685, 45.14581411300466]],
    [ "L.E.P.",[4.122893668236472, 45.129955677105606]],
    [ "Enedis",[1.4373712500000002, 43.52670125]],
    [ "ZA Villeneuve",[4.10735315, 45.150688599999995]],
    [ "Chenelettes",[4.128288228558476, 45.13855940336893]],
    [ "Enedis",[1.4499072983414265, 43.51887871404424]],
    [ "Enedis",[1.442459744058753, 43.528487106874884]],
    [ "Enedis",[1.4286346847161566, 43.52461387292577]],
    [ "Enedis",[1.4387834520090588, 43.53387603314095]],
    [ "Enedis",[1.4437851050403852, 43.51749148815322]],
    [ "Enedis",[1.4431776367193379, 43.525758793582725]],
    [ "Enedis",[1.43173565, 43.578112499999996]],
    [ "Enedis",[1.4271231822310813, 43.57089368197742]],
    [ "Enedis",[1.4248962788958175, 43.57740510701846]],
    [ "Enedis",[1.4271011393757889, 43.57814017549591]],
    [ "Enedis",[2.5088420562330547, 48.61495142976446]],
    [ "Enedis",[2.5046892513065857, 48.60615132747044]],
    [ "Crets Viry Sud",[6.00361904888967, 45.81761463161018]],
    [ "Enedis",[2.50871654145273, 48.618678147953524]],
    [ "Enedis",[2.50337935, 48.6133632]],
    [ "Enedis",[6.027528015819623, 45.81519932551659]],
    [ "Enedis",[6.025835480309126, 45.81473814356792]],
    [ "Enedis",[6.031295454148639, 45.81201936443363]],
    [ "Enedis",[2.4981972664042025, 48.61448517303437]],
    [ "Enedis",[6.031529800379933, 45.82064478029829]],
    [ "Enedis",[2.5040115070712226, 48.61661602305797]],
    [ "ESSeyssel",[5.904675094940726, 45.97226432563958]],
    [ "Enedis",[4.135630912890576, 43.65992957904455]],
    [ "Enedis",[4.1215400866415, 43.68801446851455]],
    [ "Enedis",[4.13402704189391, 43.66811728849282]],
    [ "Enedis",[4.149122960738314, 43.68064491573176]],
    [ "Enedis",[4.13446079631438, 43.67238503186426]],
    [ "Enedis",[4.121451882864774, 43.685785314663576]],
    [ "Enedis",[4.11728523188273, 43.674285273736444]],
    [ "Enedis",[4.137563157245712, 43.67988364329646]],
    [ "Enedis",[4.139708415598534, 43.66946226432596]],
    [ "Enedis",[4.1372125673723295, 43.68570470068064]],
    [ "Enedis",[4.13920357624024, 43.67088791538387]],
    [ "Enedis",[4.149574965106595, 43.68435699280201]],
    [ "Enedis",[4.12383744438082, 43.67302516872347]],
    [ "Enedis",[4.114629267432018, 43.67939006712797]],
    [ "Enedis",[4.134099849111742, 43.6794174663917]],
    [ "Enedis",[4.1320670271100415, 43.665998529460474]],
    [ "Enedis",[4.127488508495983, 43.67391445091632]],
    [ "Enedis",[4.132530484946892, 43.669064657644654]],
    [ "Enedis",[4.12349315, 43.689325249999996]],
    [ "Enedis",[4.129613900000001, 43.6865981]],
    [ "Enedis",[4.138438249999999, 43.676990800000006]],
    [ "Enedis",[4.128170473058091, 43.677630658379435]],
    [ "Enedis",[4.133640168921645, 43.68267216706154]],
    [ "Enedis",[4.1408957699273765, 43.68509170231213]],
    [ "Enedis",[4.143393227174689, 43.6746946567394]],
    [ "Enedis",[4.130069099556938, 43.67294447290783]],
    [ "Enedis",[4.125184509773458, 43.66940161688047]],
    [ "Enedis",[4.146827749194663, 43.681498746719164]],
    [ "Enedis",[4.136602751304395, 43.66792883374981]],
    [ "Enedis",[4.1240042606244085, 43.668325080647854]],
    [ "Enedis",[4.112513301722814, 43.677258229523595]],
    [ "Enedis",[4.129896471171182, 43.66669626084448]],
    [ "Enedis",[4.144510198495606, 43.678699633272856]],
    [ "Enedis",[4.138417934954955, 43.68866342938739]],
    [ "Enedis",[4.136803799575047, 43.66612367032123]],
    [ "Enedis",[4.1435407044048125, 43.67695250807878]],
    [ "Enedis",[4.143750963754008, 43.671137988999845]],
    [ "Enedis",[4.122295865951305, 43.677113620947615]],
    [ "Enedis",[4.1266089538745625, 43.68802290095446]],
    [ "Enedis",[4.132189080564659, 43.67082010514319]],
    [ "Enedis",[4.128417581784198, 43.68228883008724]],
    [ "SRD",[0.30993952564061605, 46.57282946540737]],
    [ "SRD",[0.3085981180008216, 46.57485292144029]],
    [ "SRD",[0.3046858272012651, 46.575009256666895]],
    [ "SRD",[0.312782006261343, 46.576576385249574]],
    [ "Enedis",[4.138785697794928, 43.67186214237413]],
    [ "SRD",[0.310941778780507, 46.59758349626936]],
    [ "Enedis",[4.1292216, 43.6765105]],
    [ "Enedis",[4.129758002752985, 43.67514155301812]],
    [ "Enedis",[4.129690518337731, 43.66860647478387]],
    [ "Enedis",[4.120035215497035, 43.67324801045043]],
    [ "Enedis",[4.1270275000000005, 43.68537590000001]],
    [ "Enedis",[4.126047003425491, 43.66806211458789]],
    [ "62741987",[4.112888974397996, 43.67513304178881]],
    [ "Enedis",[4.138313021885796, 43.675338396105126]],
    [ "Enedis",[4.121031571441561, 43.67459330253317]],
    [ "Enedis",[6.389690788098906, 47.40923646618873]],
    [ "Enedis",[4.139184416245088, 43.684237449282776]],
    [ "Enedis",[4.125256345770751, 43.68628393509881]],
    [ "Enedis",[4.121475516804137, 43.669221709457894]],
    [ "SRD",[0.40132518800910094, 46.61973225763367]],
    [ "Enedis",[2.4382873006502295, 50.854295868770826]],
    [ "Enedis",[5.7779751, 45.5395908]],
    [ "Enedis",[0.33266552259193016, 46.557069115973206]],
    [ "Enedis",[0.34464940000000027, 46.559155950000005]],
    [ "Enedis",[0.34898333435339185, 46.55759911063083]],
    [ "Enedis",[0.3657311064259123, 46.5615154005794]],
    [ "Enedis",[0.3412702585345876, 46.543060740910164]],
    [ "Enedis",[0.3353734720865981, 46.55432324224749]],
    [ "Enedis",[0.34551645000000003, 46.56122735]],
    [ "Enedis",[0.33079766662953436, 46.55636189886539]],
    [ "Enedis",[0.32351884730417657, 46.5534076650032]],
    [ "Enedis",[0.35547193163514307, 46.56392533131397]],
    [ "Enedis",[0.31791826660035444, 46.547854876272105]],
    [ "Enedis",[0.325825014848283, 46.55078597251013]],
    [ "Enedis",[0.3433002820584494, 46.561547734442854]],
    [ "Enedis",[0.35967674821125356, 46.56448428504762]],
    [ "Enedis",[0.331470928194937, 46.542188586220206]],
    [ "Enedis",[2.4674912022663995, 48.635233476961695]],
    [ "Enedis",[2.4599635013459142, 48.640578553835205]],
    [ "Enedis",[2.47308475, 48.63039419999999]],
    [ "Enedis",[2.4773484790207965, 48.636582295012715]],
    [ "Enedis",[2.47728315, 48.63319985]],
    [ "Enedis",[-1.500865814786153, 48.22586113006831]],
    [ "Enedis",[2.459283680674007, 48.642009869473554]],
    [ "Enedis",[0.32300437516692343, 46.55571042683615]],
    [ "Enedis",[-1.5103103844435173, 48.2105244965516]],
    [ "Enedis",[2.465486667871885, 48.63726583804645]],
    [ "Enedis",[2.4679452178885457, 48.63778044405046]],
    [ "Enedis",[0.3186697447253272, 46.5606611183806]],
    [ "Enedis",[0.3341476299042554, 46.55551351542937]],
    [ "Enedis",[-1.5089407807773825, 48.212521013418254]],
    [ "Enedis",[0.34203035786838365, 46.55646836343337]],
    [ "Enedis",[0.35898107811131724, 46.56333204464164]],
    [ "Enedis",[-1.5117651576214406, 48.213372517755445]],
    [ "Enedis",[0.37074256040569703, 46.55492472597293]],
    [ "Enedis",[0.35344521168414983, 46.552438178999665]],
    [ "Enedis",[-1.5086202499999997, 48.21567569999999]],
    [ "Enedis",[0.3408639921229773, 46.55015807109094]],
    [ "Enedis",[-1.5035406717456326, 48.21724965512014]],
    [ "Enedis",[0.3168104597039576, 46.55325528034888]],
    [ "Enedis",[0.3498991999999992, 46.550125650000005]],
    [ "Enedis",[-1.513588358039617, 48.21812693192479]],
    [ "Rochat",[6.030325051829802, 45.917439696895094]],
    [ "Enedis",[6.020441565539159, 45.91879122205827]],
    [ "Enedis",[0.31551662357262583, 46.54497278338125]],
    [ "Poncet Juillard",[6.026110218313155, 45.917399095589495]],
    [ "Enedis",[0.3556914434089044, 46.56180969969476]],
    [ "Enedis",[0.36424327650171373, 46.56296456285746]],
    [ "Enedis",[-1.513609150621912, 48.20751344006982]],
    [ "Enedis",[0.3600714463134679, 46.563124728312914]],
    [ "Enedis",[0.34414824507950204, 46.55758848932209]],
    [ "Enedis",[-1.519799441934915, 48.18820672153383]],
    [ "Enedis",[0.3630698917933131, 46.563691668775895]],
    [ "Enedis",[0.3202707680300298, 46.54684157507727]],
    [ "Enedis",[0.35042581539296486, 46.55160604705507]],
    [ "Enedis",[2.508319339092408, 48.637443545113825]],
    [ "Enedis",[2.511485425772298, 48.63567867834988]],
    [ "Enedis",[2.5364974628273376, 48.64234797873312]],
    [ "Enedis",[2.5052946653402337, 48.6386670372179]],
    [ "Enedis",[2.508327579906062, 48.64132306190852]],
    [ "Enedis",[2.514105675076038, 48.646271440189075]],
    [ "Enedis",[2.510278862782011, 48.643015030847025]],
    [ "Enedis",[2.5101222590270984, 48.639371631037584]],
    [ "Enedis",[0.9852027499999998, 47.39671365]],
    [ "Enedis",[0.9879598952915172, 47.424542106575835]],
    [ "Enedis",[0.959245026861037, 47.402405052123314]],
    [ "Enedis",[0.9845028450555521, 47.40038617179995]],
    [ "Enedis",[0.9875238581132363, 47.41440267450668]],
    [ "Enedis",[0.9720462462972219, 47.40027550577629]],
    [ "Enedis",[0.9935588727287782, 47.4140978296791]],
    [ "Enedis",[0.9794003102906412, 47.4111341075372]],
    [ "Enedis",[0.9903968182945894, 47.403025581534244]],
    [ "Enedis",[0.9702891588350623, 47.40552892494205]],
    [ "Enedis",[0.9984300064706795, 47.403675492695506]],
    [ "Enedis",[0.9983820077784379, 47.41849518763779]],
    [ "Enedis",[0.9980400466457281, 47.40657233707417]],
    [ "Enedis",[0.9771587782540643, 47.40589812607121]],
    [ "Enedis",[0.99104615, 47.4106199]],
    [ "Enedis",[0.9815552816830441, 47.39853934631318]],
    [ "Enedis",[0.9868960508022204, 47.42237288890509]],
    [ "Enedis",[0.99038545, 47.3981445]],
    [ "Enedis",[0.9665614599921553, 47.40581522771588]],
    [ "Enedis",[0.300970952866067, 46.547891946375756]],
    [ "SRD",[0.2861384964205015, 46.53497126734106]],
    [ "SRD",[0.28898171977994075, 46.544174386548015]],
    [ "SRD",[0.2917233402454918, 46.53959361755748]],
    [ "SRD",[0.2877104755733021, 46.53675899277872]],
    [ "SRD",[0.28641341584209584, 46.5422845338727]],
    [ "Enedis",[6.861825378906245, 47.606951027970396]],
    [ "Enedis",[6.843612954595108, 47.605190423114024]],
    [ "Enedis",[6.858907739646931, 47.607680235910294]],
    [ "Enedis",[6.844815680932397, 47.60815765375531]],
    [ "Enedis",[6.85905455, 47.6060652]],
    [ "Enedis",[6.860506291984103, 47.604950182264716]],
    [ "Enedis",[1.2104496114754095, 49.444949073114756]],
    [ "Enedis",[6.864366189023147, 47.60097341363253]],
    [ "Enedis",[1.2075735782756074, 49.439707695642106]],
    [ "Enedis",[6.847198941294949, 47.607882295226396]],
    [ "Enedis",[1.2216986519216875, 49.45245691998104]],
    [ "Enedis",[1.202304284293581, 49.443510875750114]],
    [ "Enedis",[1.21603985, 49.45515819999999]],
    [ "Enedis",[1.20066437529919, 49.44530729411411]],
    [ "Enedis",[6.881841897486063, 47.586723810790765]],
    [ "Enedis",[3.057131379243302, 45.70549978948531]],
    [ "Enedis",[3.0549959965278664, 45.706259419804496]],
    [ "Enedis",[6.830697299999999, 47.6104245]],
    [ "Enedis",[6.828693843444268, 47.60639827356224]],
    [ "Enedis",[6.830526269560011, 47.60759864453152]],
    [ "Enedis",[6.819489353526374, 47.60056480646008]],
    [ "Enedis",[6.821414797191858, 47.60557412594196]],
    [ "Enedis",[2.4245432894423042, 48.66110968259227]],
    [ "Enedis",[2.3975734654343253, 48.679892154712974]],
    [ "Enedis",[2.41322925, 48.69187545]],
    [ "Enedis",[2.4197060500000007, 48.6932831]],
    [ "Enedis",[2.38977765, 48.688898349999995]],
    [ "Enedis",[2.4337624764395938, 48.682799335992975]],
    [ "Enedis",[2.42271341523929, 48.68907449605427]],
    [ "Enedis",[2.4027963263971652, 48.68116079476029]],
    [ "Enedis",[2.419050347016989, 48.68606826243214]],
    [ "Enedis",[2.407126034242457, 48.68624505558526]],
    [ "Enedis",[2.422455112064135, 48.690065732870295]],
    [ "Enedis",[2.4165952869066487, 48.69209614975473]],
    [ "Enedis",[2.4088147180211186, 48.6911374481867]],
    [ "Enedis",[2.4007247535417426, 48.684842566385946]],
    [ "Enedis",[2.390120562120134, 48.6849653720815]],
    [ "Enedis",[2.3990899, 48.68741724999999]],
    [ "Enedis",[2.3953923799599273, 48.68379589578629]],
    [ "Enedis",[2.4215452321886293, 48.691647619259435]],
    [ "Enedis",[2.428861179428633, 48.68670788297979]],
    [ "Enedis",[2.4257469, 48.692453799999996]],
    [ "Hautot",[6.7708613, 47.73441489999999]],
    [ "Enedis",[2.432189026534568, 48.837218622553664]],
    [ "Enedis",[2.420594481412839, 48.82902632636314]],
    [ "Enedis",[2.4494915838148974, 48.84272101128603]],
    [ "Enedis",[2.42070835, 48.824408250000005]],
    [ "Enedis",[2.4266159874800075, 48.824366875747685]],
    [ "Enedis",[2.4430824765591397, 48.819175207694144]],
    [ "Enedis",[3.102755409482037, 50.54462779322869]],
    [ "Enedis",[3.12960130311561, 50.5420674130626]],
    [ "Enedis",[-1.3141198131135061, 43.75432667334727]],
    [ "Enedis",[3.119360903632909, 50.540170459844454]],
    [ "Enedis",[3.126506659557402, 50.54046324542469]],
    [ "SRD",[0.6724799186431502, 46.45026589934702]],
    [ "Enedis",[-1.316924469338306, 43.74471304574446]],
    [ "Maisonnette",[4.3218197, 45.18585215]],
    [ "Enedis",[0.45688335402118857, 47.45631111855908]],
    [ "Enedis",[0.4533258, 47.45015045]],
    [ "ESSeyssel",[6.078660628484671, 45.84801531646826]],
    [ "Enedis",[3.12097003482276, 45.750643346888516]],
    [ "Enedis",[3.1355261499999996, 45.75548465]],
    [ "Enedis",[3.1476565405290144, 45.757432782705656]],
    [ "Enedis",[3.1035442675861833, 45.74855867513643]],
    [ "Enedis",[3.137445088598688, 45.753437740007456]],
    [ "Enedis",[3.1436668067285694, 45.75778704574083]],
    [ "Enedis",[3.116012929332216, 45.7581776813818]],
    [ "Enedis",[3.145903902814201, 45.75395779201513]],
    [ "Enedis",[3.1196461127005595, 45.758780420388106]],
    [ "Enedis",[3.1492196253275915, 45.755740408066856]],
    [ "Enedis",[3.1272159005162705, 45.75719896655453]],
    [ "Enedis",[3.1188515155718695, 45.75750345615962]],
    [ "Enedis",[3.1083272665298516, 45.74587489183486]],
    [ "Enedis",[3.10451545, 45.754097200000004]],
    [ "Enedis",[3.148407420883895, 45.755860937214926]],
    [ "Enedis",[3.111592549963215, 45.75503356384426]],
    [ "Pasteur",[3.107899598914378, 45.75550343070657]],
    [ "Enedis",[3.107352820125482, 45.744245897411695]],
    [ "Enedis",[3.115263787545379, 45.755638960257386]],
    [ "Enedis",[3.1050456750703144, 45.75628988715589]],
    [ "Enedis",[3.1109270999999996, 45.74550335000001]],
    [ "Enedis",[3.107994640858743, 45.751229595392786]],
    [ "Replat des Cézeaux",[3.105140971869526, 45.76189341913201]],
    [ "Enedis",[3.1314180796751847, 45.74972034284891]],
    [ "Enedis",[3.1225606520609337, 45.75599216876817]],
    [ "Enedis",[3.143815264124226, 45.755504829859625]],
    [ "Enedis",[3.103029393766671, 45.75121741627368]],
    [ "Enedis",[3.1389407690222746, 45.756159680110976]],
    [ "Enedis",[3.13540939368193, 45.75321533352132]],
    [ "Enedis",[3.1035556230294046, 45.75894506424512]],
    [ "Enedis",[3.109922169863672, 45.7477705604582]],
    [ "Enedis",[3.1412601442669006, 45.751887990963475]],
    [ "Enedis",[3.1306399313899917, 45.74747744357044]],
    [ "Enedis",[3.14352025, 45.75908425]],
    [ "Enedis",[3.1033156327213782, 45.75785716355369]],
    [ "Enedis",[3.13734665, 45.749531350000005]],
    [ "Enedis",[3.118674100829254, 45.75458593272656]],
    [ "Enedis",[3.135182758682713, 45.7471125607655]],
    [ "Enedis",[1.1554115005052659, 49.44386856835555]],
    [ "Enedis",[6.069758359321213, 45.914136012159425]],
    [ "Enedis",[6.055659642835377, 45.912697499320416]],
    [ "Enedis",[6.085071070464824, 45.91030559710189]],
    [ "Enedis",[1.149185287728008, 49.44351618794637]],
    [ "Enedis",[6.077095821251241, 45.910772609069845]],
    [ "Enedis",[3.0271599091655617, 42.54232365232362]],
    [ "Brassilly Village",[6.076468175929375, 45.90716573240182]],
    [ "Enedis",[1.1436703761139335, 49.43899770424771]],
    [ "Enedis",[3.0405947558213358, 42.5489234378109]],
    [ "Enedis",[6.052692642200505, 45.91323104720342]],
    [ "Enedis",[4.8604497390193115, 45.84608950948109]],
    [ "Enedis",[1.17140805, 49.44215115]],
    [ "Enedis",[6.0611484803229, 45.92078217819874]],
    [ "Enedis",[3.026591135044881, 42.549441696246745]],
    [ "Enedis",[1.1707233174256308, 49.438691176388325]],
    [ "Enedis",[3.0449686080720673, 42.55026871036364]],
    [ "Enedis",[-1.3238104384599692, 43.757134116749434]],
    [ "Enedis",[1.151395685808421, 49.448925219811684]],
    [ "Enedis",[3.0414103626173503, 42.56036201785997]],
    [ "Enedis",[0.7233588730750012, 46.91320434551131]],
    [ "COURTALS",[3.0213693651990328, 42.550589173063706]],
    [ "Enedis",[3.0342612744369086, 42.544496509601245]],
    [ "Enedis",[3.011290506588555, 42.551260267685514]],
    [ "Enedis",[0.7253793150549995, 46.94085391982321]],
    [ "Enedis",[-1.3276977616119374, 43.75818577363271]],
    [ "Enedis",[-1.3326203255951743, 43.75811110140539]],
    [ "Enedis",[-1.3252754663718451, 43.76716070141566]],
    [ "Enedis",[-1.3296683282129278, 43.762156640380226]],
    [ "Enedis",[-1.3180588707109417, 43.76603805636514]],
    [ "Fier",[6.072722992842024, 45.907701642044636]],
    [ "Enedis",[6.055031601545048, 45.91789939960796]],
    [ "Enedis",[6.072538389029023, 45.91752858800081]],
    [ "Enedis",[6.077336919901856, 45.917433411818706]],
    [ "Enedis",[0.7180615887401596, 46.94267711782336]],
    [ "Enedis",[-1.3224826427482672, 43.75540871713126]],
    [ "Enedis",[6.825181585847721, 47.62267361301261]],
    [ "Enedis",[6.845060623013302, 47.627055238372925]],
    [ "Enedis",[6.834472434750219, 47.62400956108164]],
    [ "Enedis",[1.1479949057891619, 49.435830883417694]],
    [ "Enedis",[6.826686398438002, 47.61460082690785]],
    [ "Vernod Bas",[6.08076205, 45.915690999999995]],
    [ "Enedis",[6.838200715596573, 47.61982542059942]],
    [ "Enedis",[6.839665748944353, 47.62355763342162]],
    [ "Enedis",[6.8463984246393155, 47.62204106384318]],
    [ "Enedis",[6.827785136373036, 47.62416198495367]],
    [ "Enedis",[3.035306802506505, 42.54644966528677]],
    [ "Enedis",[6.828675099999999, 47.62547945]],
    [ "Enedis",[-0.34136770122749477, 43.29983726347378]],
    [ "Enedis",[6.831799454361011, 47.628476164636474]],
    [ "Enedis",[6.832780739183282, 47.62587812264191]],
    [ "Enedis",[6.8296228562441, 47.6165243300987]],
    [ "Enedis",[6.835472272163543, 47.62056986450551]],
    [ "Enedis",[6.832519055884579, 47.62131576687455]],
    [ "Enedis",[6.841731907284247, 47.625398335523464]],
    [ "Enedis",[6.829451472233628, 47.62104624722044]],
    [ "Enedis",[6.839626516524623, 47.62737364968219]],
    [ "Enedis",[6.828386178636474, 47.61176831018122]],
    [ "Loup",[-0.35507471795426854, 43.311438720020895]],
    [ "Enedis",[-0.3444035500000013, 43.30326540000001]],
    [ "Enedis",[-0.35022335, 43.3218946]],
    [ "Enedis",[3.0246946042142606, 42.54556188685252]],
    [ "Enedis",[-0.3410063, 43.3083636]],
    [ "Enedis",[3.037762108306062, 42.5455485500173]],
    [ "Enedis",[-0.36514430460121333, 43.292456311261276]],
    [ "Enedis",[3.009828797176166, 42.55267508201674]],
    [ "Enedis",[3.0039092715554103, 42.56823140493258]],
    [ "Enedis",[3.046147193324692, 42.55240033199396]],
    [ "Enedis",[-0.3656050784531481, 43.3035183807456]],
    [ "Enedis",[3.042264270732707, 42.565271386411816]],
    [ "Enedis",[3.0262629483336636, 42.54419115926879]],
    [ "Enedis",[-0.3247672002632882, 43.30597707114544]],
    [ "Enedis",[3.0456722985364526, 42.56024890971518]],
    [ "Enedis",[0.5972205099362499, 47.271757028247976]],
    [ "Enedis",[6.821689993425573, 47.63226528895021]],
    [ "Enedis",[6.815273564509668, 47.63412093484323]],
    [ "Enedis",[6.826181790057974, 47.63754699175757]],
    [ "Enedis",[6.82948105518924, 47.63485798577019]],
    [ "Enedis",[6.824338900000001, 47.63318959999999]],
    [ "Enedis",[6.824318305176918, 47.635524035783384]],
    [ "Enedis",[6.830552200106017, 47.63825799488995]],
    [ "Enedis",[6.828776168379119, 47.63227639744896]],
    [ "Enedis",[6.827404042439676, 47.63009033849451]],
    [ "Enedis",[6.827391955151021, 47.634468679129974]],
    [ "Enedis",[6.8152966500000005, 47.63309480000001]],
    [ "Enedis",[6.823631933928858, 47.630044701395484]],
    [ "Bois Louis",[-0.36306904068001117, 43.291795015552985]],
    [ "Enedis",[2.4946490697914396, 48.570249127903544]],
    [ "Enedis",[2.493810523955574, 48.57215868896421]],
    [ "Enedis",[2.4882037146685474, 48.57113642722143]],
    [ "Enedis",[-0.34230650083688924, 43.32028450759736]],
    [ "Enedis",[0.9962509249221855, 47.16307315463877]],
    [ "Enedis",[5.349560259265417, 43.65842567161025]],
    [ "Enedis",[5.353420131709236, 43.66139797093761]],
    [ "Enedis",[0.9514885560248941, 47.17860190139304]],
    [ "Enedis",[5.3473295015627, 43.66016973128633]],
    [ "Enedis",[5.3365376, 43.66136155]],
    [ "Enedis",[5.345738841465033, 43.66366640099564]],
    [ "Enedis",[0.9724175668637998, 47.18093808490941]],
    [ "Enedis",[5.348210335154495, 43.666737641660234]],
    [ "Enedis",[5.351037209488709, 43.66103657060538]],
    [ "Enedis",[-0.352414926749269, 43.32287957256692]],
    [ "Enedis",[-0.36542900246605675, 43.30468456548506]],
    [ "Gendarmerie",[-0.36872964999999996, 43.31517815]],
    [ "Enedis",[3.0412577465825064, 45.72351701604239]],
    [ "Enedis",[3.041870075552053, 45.70480268710205]],
    [ "Enedis",[3.0130220000000003, 45.745667600000004]],
    [ "Enedis",[3.0182569674389805, 45.71991493455235]],
    [ "Enedis",[3.023596313719806, 45.70511796666666]],
    [ "Enedis",[3.04326843857926, 45.72167770899449]],
    [ "Enedis",[3.012840950334594, 45.70735243155509]],
    [ "Enedis",[3.0473043728645077, 45.725116426474806]],
    [ "Enedis",[3.01743561489406, 45.70718263635544]],
    [ "Montpellaz",[6.187781156797755, 45.86920549450639]],
    [ "Veyrier",[6.176641622803722, 45.88528096643606]],
    [ "Perouzes",[6.174416909144696, 45.88770706440133]],
    [ "Enedis",[1.5144019350575764, 43.494700650941674]],
    [ "Enedis",[1.513665140165684, 43.491793584967056]],
    [ "Chabloux",[6.184300433133717, 45.8741089510306]],
    [ "La Brune",[6.1762149499999985, 45.879237700000004]],
    [ "La Dossene",[6.174414147774312, 45.88603603218695]],
    [ "Le Port",[6.175486968299033, 45.881934102055205]],
    [ "Chavoires",[6.166901899999999, 45.895504]],
    [ "La Bonnoirde",[6.171581408350932, 45.890079758561455]],
    [ "Boutet",[6.185123990012043, 45.86988811400835]],
    [ "Enedis",[1.5316896163042892, 43.493805149457124]],
    [ "Enedis",[1.522323569647806, 43.49181925747414]],
    [ "Enedis",[1.5270308642204315, 43.49355731728081]],
    [ "Enedis",[1.0180203379794703, 47.1241518569692]],
    [ "Enedis",[-1.3328820349826698, 43.75313823369669]],
    [ "Enedis",[-1.3362602946261604, 43.75310697812295]],
    [ "Enedis",[-1.3229323499999999, 43.749943200000004]],
    [ "Enedis",[-1.3274810429487571, 43.74884184927299]],
    [ "Enedis",[-1.3233956309921782, 43.74706138182584]],
    [ "Enedis",[-1.3311226409609123, 43.75089043646852]],
    [ "Enedis",[2.9954159000000002, 42.705716349999996]],
    [ "Enedis",[2.980928067019432, 42.70786666990205]],
    [ "Enedis",[3.0285707865921254, 42.69231148073684]],
    [ "Enedis",[1.5277547999999983, 43.48419155]],
    [ "Enedis",[2.9999268499999996, 42.70443805]],
    [ "Enedis",[3.0263139962772527, 42.69423057482518]],
    [ "Enedis",[1.5226661957515257, 43.47863395999446]],
    [ "Enedis",[3.0200273008681013, 42.704498950662234]],
    [ "Enedis",[3.0178560467794497, 42.70764728561004]],
    [ "Enedis",[3.0058986781784873, 42.70430399139141]],
    [ "Enedis",[3.0280509795972046, 42.69966213390187]],
    [ "Enedis",[3.0072906767646654, 42.699760365392635]],
    [ "Enedis",[3.034305292082538, 42.69784683179469]],
    [ "Enedis",[3.0297979900354393, 42.70100441030853]],
    [ "Enedis",[3.0344644057671446, 42.69980189952987]],
    [ "Enedis",[3.0081196033809268, 42.70398321466288]],
    [ "Enedis",[3.0301829976811288, 42.678088929060436]],
    [ "Enedis",[2.992380161755545, 42.706508773912226]],
    [ "Allée",[6.214747994647059, 45.88092491741176]],
    [ "Moulin 5A",[6.198018773210866, 45.86196734637448]],
    [ "Les Choseaux",[6.1971806571681824, 45.85795862689819]],
    [ "Enedis",[6.191413792375747, 45.85640611853469]],
    [ "Enedis",[6.196115646657945, 45.86226963537225]],
    [ "Les Plantees",[6.191379592127618, 45.866630559583164]],
    [ "Enedis",[3.0158462418177616, 42.70126595377224]],
    [ "Enedis",[3.0057202581216327, 42.70695456581986]],
    [ "Enedis",[2.9799088537261182, 42.684754012349664]],
    [ "Enedis",[6.211070650000001, 45.8734187]],
    [ "Enedis",[2.9982343850302113, 42.70666232846468]],
    [ "Enedis",[3.0306919640406633, 42.69206981718822]],
    [ "Enedis",[3.0296194998792325, 42.687950194665554]],
    [ "Enedis",[3.0088029098470765, 42.70045868610507]],
    [ "Enedis",[3.015587280647956, 42.704186763253645]],
    [ "Enedis",[3.0127862699716097, 42.70718141680616]],
    [ "Enedis",[6.847157373241837, 47.66388119610569]],
    [ "Enedis",[6.8528632037671136, 47.66171006734999]],
    [ "Enedis",[6.843127333529114, 47.66557299012433]],
    [ "Enedis",[3.011384148027961, 42.70097232268356]],
    [ "Enedis",[6.840195274211871, 47.66859155010267]],
    [ "Enedis",[6.850650514867384, 47.663496045753696]],
    [ "Enedis",[3.029024032355788, 42.69522323812772]],
    [ "Enedis",[3.0306720532543263, 42.68104298703708]],
    [ "Enedis",[3.0222759548087974, 42.70291169035874]],
    [ "Enedis",[3.016871738433982, 42.70653490557731]],
    [ "Enedis",[3.0326778850927334, 42.69722602014069]],
    [ "Enedis",[3.026174222597567, 42.696362126377124]],
    [ "Enedis",[3.0260277928236614, 42.69776802571136]],
    [ "Enedis",[6.851078782806173, 47.66078160173543]],
    [ "Enedis",[3.0379524810969887, 42.70486209600463]],
    [ "Enedis",[6.842564647980887, 47.6625419309582]],
    [ "Enedis",[6.841347968456253, 47.675565955536534]],
    [ "Enedis",[6.845472520775779, 47.66832835312505]],
    [ "Enedis",[5.865837161293139, 46.06152974696031]],
    [ "Enedis",[3.004464814349797, 42.69886625290662]],
    [ "Enedis",[5.8743046200254865, 46.05450130244857]],
    [ "Enedis",[5.853616202626263, 46.050877573737374]],
    [ "Enedis",[3.0341636186606813, 42.70173331955078]],
    [ "Enedis",[3.032418089786557, 42.69954715536766]],
    [ "Enedis",[3.022271806052458, 42.70690908353863]],
    [ "Enedis",[3.0111694773906605, 42.70413213825543]],
    [ "Enedis",[3.033194522451026, 42.68815903337746]],
    [ "Enedis",[5.295071593170745, 43.62217588602112]],
    [ "Enedis",[3.0142328924864605, 42.70016784228441]],
    [ "Enedis",[3.029477605506349, 42.69627511009581]],
    [ "Enedis",[3.0045143505511405, 42.70586374386706]],
    [ "Enedis",[3.0337751246047415, 42.68528830581838]],
    [ "Enedis",[5.299129335399378, 43.62295718104466]],
    [ "Enedis",[3.002531781713097, 42.69991389529509]],
    [ "RTE",[2.985805627903401, 42.70783411170678]],
    [ "Saint-Hilaire",[2.5142874765164485, 48.69956574054418]],
    [ "Enedis",[2.371120661000999, 48.52949932899226]],
    [ "Enedis",[2.3742957678953753, 48.53353397292795]],
    [ "Enedis",[2.3770612499999983, 48.5236209]],
    [ "Enedis",[2.3711354014198984, 48.530898956149635]],
    [ "Varache",[2.3808419054546963, 48.522295056964666]],
    [ "Enedis",[2.366491756656901, 48.5289695677743]],
    [ "Enedis",[2.374817925953403, 48.53672364427419]],
    [ "Couronne",[2.1187612705059276, 48.76940799440908]],
    [ "Enedis",[2.385326680287741, 48.52223920504226]],
    [ "Enedis",[2.3760512499999997, 48.5191384]],
    [ "Enedis",[2.378353422045847, 48.52387221131034]],
    [ "Pierre Curie",[2.375679017087324, 48.52700389479295]],
    [ "Enedis",[0.8260465345513668, 47.034917328671334]],
    [ "Enedis",[2.3733380309315795, 48.52733580291826]],
    [ "Enedis",[2.3880259622596367, 48.525140496924195]],
    [ "Enedis",[0.8127282483688516, 47.04274041821537]],
    [ "Enedis",[2.3778946363445637, 48.53196007993336]],
    [ "Enedis",[0.8184477610391834, 47.04056199885433]],
    [ "Enedis",[0.8200325781745414, 47.04896420574499]],
    [ "Enedis",[6.082880119160786, 45.89786024737838]],
    [ "Enedis",[2.374083066886288, 48.52850330495196]],
    [ "Piper",[2.121583307493705, 48.766261868312164]],
    [ "Enedis",[6.081099778647473, 45.89536531986618]],
    [ "Fruitiere",[6.063067650000002, 45.88432545]],
    [ "Enedis",[2.3821295419427466, 48.530328080855135]],
    [ "Enedis",[2.3874532639118997, 48.52331725056705]],
    [ "Enedis",[0.8195238263823342, 47.03738531429497]],
    [ "Enedis",[1.4553127893502857, 43.50846368723727]],
    [ "Rosset",[6.058855955242258, 45.880585360371185]],
    [ "Enedis",[2.386154900000001, 48.51997775]],
    [ "Enedis",[1.4602177271484178, 43.50554039991406]],
    [ "Enedis",[2.3889758456621233, 48.53081781903007]],
    [ "Eterzy",[6.075352399592983, 45.89608298674433]],
    [ "Enedis",[2.3769019, 48.53070954999999]],
    [ "Enedis",[6.0760415514373305, 45.897678414142604]],
    [ "Enedis",[6.081218733396559, 45.89840805832631]],
    [ "Enedis",[3.070810261198239, 42.524839237245494]],
    [ "CARAVELLE",[2.117476182639861, 48.76652822026922]],
    [ "Enedis",[2.3889593820565613, 48.5268376300388]],
    [ "Enedis",[1.4370319785393577, 43.51341283216602]],
    [ "Enedis",[1.460185955869217, 43.50860986830963]],
    [ "Enedis",[6.06470292186022, 45.88786745775118]],
    [ "Enedis",[2.3844748947189762, 48.52563969442557]],
    [ "Enedis",[2.3810589196364402, 48.525282155603485]],
    [ "Enedis",[2.3913409745397365, 48.52544600366784]],
    [ "Enedis",[2.3853716422856186, 48.52513302442612]],
    [ "Enedis",[3.0738206487346966, 42.519961549200296]],
    [ "Saint-Jacques",[3.075868056774412, 42.52074651628298]],
    [ "Enedis",[3.0729190452979633, 42.52265305383208]],
    [ "Enedis",[3.07427745949149, 42.51948987549808]],
    [ "Enedis",[1.4412823697727297, 43.58760933883222]],
    [ "Enedis",[1.4431662368075573, 43.59192103940461]],
    [ "Enedis",[1.4402528500000003, 43.586965449999994]],
    [ "Enedis",[1.444630776820573, 43.5859246788215]],
    [ "Enedis",[1.44403525882588, 43.58412839159933]],
    [ "Bizanos",[-0.35407510485417804, 43.289384071418446]],
    [ "Enedis",[-0.3177272576766037, 43.304706964286055]],
    [ "Enedis",[-0.3514000156976687, 43.28660199332404]],
    [ "Enedis",[-0.3169985384762281, 43.30249852579835]],
    [ "Enedis",[-0.35007351598971403, 43.287426359254546]],
    [ "Enedis",[-0.3161794751025163, 43.305486843458624]],
    [ "Yser",[-0.35447959440399196, 43.28729537280641]],
    [ "Enedis",[-0.3553495802555787, 43.27896159196796]],
    [ "Enedis",[-0.35457178805274797, 43.28122215679289]],
    [ "Enedis",[-0.359346694075442, 43.277975264444166]],
    [ "Enedis",[-0.3603779882872772, 43.27660704145338]],
    [ "Enedis",[-0.35540641415773255, 43.27486982705178]],
    [ "Enedis",[-0.35670993095561865, 43.28236361095287]],
    [ "Enedis",[-0.3618909900738195, 43.27977911704292]],
    [ "Enedis",[-0.3605696363219932, 43.275032811345916]],
    [ "Enedis",[-0.37488810445932597, 43.27799834626951]],
    [ "Enedis",[-0.36490583785536373, 43.27916308072228]],
    [ "Enedis",[-0.37471607069416585, 43.27927063534167]],
    [ "Enedis",[3.394831229124771, 47.98296565741935]],
    [ "Enedis",[3.379046051022905, 47.969750509451934]],
    [ "Enedis",[-0.3685088702735788, 43.26940383953227]],
    [ "Enedis",[-0.3711651092488079, 43.28041828312293]],
    [ "Enedis",[3.4220267705752123, 47.9756062891398]],
    [ "Enedis",[-0.3756995551655112, 43.28880393218557]],
    [ "Enedis",[-0.3791223219799138, 43.27828457520485]],
    [ "Enedis",[3.3954106654983565, 47.97203582927157]],
    [ "Enedis",[3.4049553106759274, 47.97565884543574]],
    [ "Enedis",[3.412134551988619, 47.98153675036639]],
    [ "Enedis",[3.379131994738594, 47.97908847276057]],
    [ "Enedis",[-0.37001676546718987, 43.25021892989639]],
    [ "Enedis",[-0.377916299671238, 43.284958102254365]],
    [ "Enedis",[-0.37212002945119416, 43.28329066717935]],
    [ "Enedis",[-0.3746156502043906, 43.284932735804624]],
    [ "Enedis",[3.41536865373908, 47.98010210674472]],
    [ "Enedis",[3.3792254221566185, 47.97726047348102]],
    [ "Enedis",[-0.3773652620532384, 43.278001176120185]],
    [ "Enedis",[3.3854243109200253, 47.974047572275005]],
    [ "Enedis",[-0.3775646919209606, 43.28445832891446]],
    [ "Enedis",[-0.3753947371243457, 43.28738566675271]],
    [ "Enedis",[-0.36281744437198354, 43.28174672680403]],
    [ "Enedis",[-0.376362659425315, 43.28551431573459]],
    [ "Travailleurs",[-0.37046691365602424, 43.28627727891945]],
    [ "Enedis",[3.39675849788044, 47.98487008775846]],
    [ "Enedis",[3.3800045899648197, 47.99091654473048]],
    [ "Enedis",[3.4257010789393973, 47.97974834001023]],
    [ "Enedis",[3.3938565445961566, 47.97540434648765]],
    [ "Enedis",[3.389287331215961, 47.97676613419598]],
    [ "Enedis",[3.3980105089733033, 47.98212304569277]],
    [ "Enedis",[3.3945063820397112, 47.97937725368661]],
    [ "Enedis",[3.4226465070711667, 47.978151543126515]],
    [ "Enedis",[3.379184716581775, 47.98804250710929]],
    [ "Enedis",[3.4088844026207306, 47.98446976828314]],
    [ "Enedis",[3.3770885212279156, 47.97697382353578]],
    [ "Enedis",[3.387883812669879, 47.97819114465838]],
    [ "Enedis",[3.414733889652473, 47.982927412346065]],
    [ "Enedis",[3.4193848542552496, 47.98359648046359]],
    [ "Enedis",[3.406746186963361, 47.980678958284095]],
    [ "Enedis",[3.408567731877691, 47.97842577307787]],
    [ "Enedis",[3.3890020000000005, 47.98150195000001]],
    [ "Enedis",[3.3752707162270257, 47.98365003868668]],
    [ "Enedis",[3.3710476021759095, 47.97608640087456]],
    [ "Enedis",[3.402876602186424, 47.985217299429806]],
    [ "Enedis",[3.4207350646833805, 47.975798808584344]],
    [ "Enedis",[3.367792404444581, 47.97594732191758]],
    [ "Enedis",[3.421300654444173, 47.98219046125288]],
    [ "Enedis",[3.4391644126939456, 47.89237404056941]],
    [ "SRD",[0.35284122894839554, 46.51194777013476]],
    [ "SRD",[0.34631593165993463, 46.518144447057836]],
    [ "SRD",[0.3533300000000007, 46.508634949999994]],
    [ "SRD",[0.35863158221433017, 46.508494428244916]],
    [ "SRD",[0.34943156585648083, 46.51985604583332]],
    [ "SRD",[0.3464928500000009, 46.513291499999994]],
    [ "SRD",[0.34755329009910746, 46.52194469420077]],
    [ "SRD",[0.36005046225209303, 46.50489418837131]],
    [ "SRD",[0.34714010066162776, 46.520316315703205]],
    [ "SRD",[0.3495413370742831, 46.52118855996062]],
    [ "SRD",[0.34931259950931437, 46.50699314806413]],
    [ "Enedis",[1.8793302222478652, 48.87322862965306]],
    [ "Enedis",[1.9011160626751786, 48.85516878089088]],
    [ "Enedis",[1.8794601062567662, 48.85047362113985]],
    [ "Enedis",[1.8737584466896142, 48.86177979522022]],
    [ "Panoramic P9 P10 38567 R 0019",[5.875621739407962, 45.10659624790221]],
    [ "Les Pinnacelles",[4.34656902247044, 45.21630607349425]],
    [ "Écrin des Neiges",[5.875691089066585, 45.10513542476456]],
    [ "Enedis",[5.875247483886779, 45.10399860834248]],
    [ "La Côte",[4.341690067255864, 45.22096410219061]],
    [ "Zone A de Ville",[4.3576534, 45.207866100000004]],
    [ "Rochefoy",[4.3455274, 45.21059735]],
    [ "Enedis",[6.8980715, 47.669364]],
    [ "Enedis",[6.895148466911386, 47.66667766146317]],
    [ "Enedis",[1.2643748932534407, 49.44573673235169]],
    [ "Enedis",[2.404709951306304, 48.689333933227225]],
    [ "Enedis",[2.3888154626149656, 48.68362287294496]],
    [ "Enedis",[2.417393497138191, 48.6900108133276]],
    [ "Enedis",[2.3915553717698614, 48.68808274296488]],
    [ "Enedis",[1.4241423, 43.584727199999996]],
    [ "Enedis",[3.135447917183835, 45.73845645221404]],
    [ "Enedis",[3.1427262298524568, 45.740538834966344]],
    [ "Enedis",[3.1390363587331165, 45.73907397127623]],
    [ "Enedis",[2.4257786387750415, 48.69059716536286]],
    [ "Enedis",[3.1468594616693144, 45.736968939815725]],
    [ "Enedis",[3.1449083370121067, 45.72730739725872]],
    [ "Enedis",[2.4365029429275147, 48.68566502811532]],
    [ "Enedis",[2.4132230131319106, 48.6686700091898]],
    [ "Enedis",[3.146069463181875, 45.73234783119237]],
    [ "Enedis",[3.142431617987349, 45.725336148432035]],
    [ "Enedis",[2.4364507625456846, 48.66794217327018]],
    [ "Enedis",[2.42770765, 48.68999675]],
    [ "Enedis",[0.6833085581610086, 46.23686406274926]],
    [ "Enedis",[0.6817861805373672, 46.23183918580632]],
    [ "Enedis",[0.69226395, 46.23061545]],
    [ "Enedis",[3.2548800500000006, 45.46029135]],
    [ "Stade",[-0.393998623986546, 43.29031789114888]],
    [ "Enedis",[-0.39999656648779675, 43.28940538833157]],
    [ "Enedis",[1.219849180003555, 49.49390418038414]],
    [ "Enedis",[-0.4009623049677562, 43.29179482798315]],
    [ "Enedis",[-0.39926135412137315, 43.29067981976878]],
    [ "Enedis",[2.208583090511959, 48.73388733110957]],
    [ "Enedis",[2.9431657263314595, 45.51542378981029]],
    [ "Enedis",[1.4538982675047893, 43.58520634921921]],
    [ "Enedis",[1.4453160511109082, 43.591081031039685]],
    [ "Enedis",[2.939169949545953, 45.515633395548726]],
    [ "Enedis",[2.932302299999999, 45.5133023]],
    [ "Enedis",[1.456214220577396, 43.586026589483154]],
    [ "Enedis",[2.936524331252639, 45.50966549641155]],
    [ "Enedis",[1.4514226420403342, 43.5872806217552]],
    [ "Enedis",[-0.3825281989760096, 43.280468568720764]],
    [ "Enedis",[1.448235719407352, 43.58521081906557]],
    [ "Enedis",[2.9234980626519484, 45.50900225501992]],
    [ "Enedis",[1.990626048261374, 48.76713636892805]],
    [ "Enedis",[3.168031599840871, 45.80308437584852]],
    [ "Enedis",[1.995672357452861, 48.77935665334186]],
    [ "Enedis",[3.1684274037835625, 45.797595756624105]],
    [ "Enedis",[3.1715273375117645, 45.80135740148065]],
    [ "Enedis",[2.0015336315133236, 48.76809484958271]],
    [ "Enedis",[1.986639966666667, 48.76478001201699]],
    [ "Enedis",[2.0055280522334447, 48.77017462322896]],
    [ "Enedis",[3.171811736985003, 45.79629187425991]],
    [ "Enedis",[3.161940821166396, 45.794526385866014]],
    [ "Enedis",[3.1678997440698464, 45.80083245908602]],
    [ "Enedis",[3.1629698212714366, 45.792816704494804]],
    [ "Enedis",[3.168129963100921, 45.79952691943482]],
    [ "Enedis",[3.163378069342454, 45.79836027698085]],
    [ "Enedis",[3.1638914509353047, 45.802418199790466]],
    [ "Enedis",[3.1650876480484387, 45.802680731715874]],
    [ "Enedis",[3.16518834775241, 45.80054767970437]],
    [ "Enedis",[3.1651217517246515, 45.79209409665362]],
    [ "Enedis",[3.160410146202173, 45.79699392122854]],
    [ "Enedis",[1.9852716478207912, 48.796983768812886]],
    [ "Enedis",[3.172244845256127, 45.792317022641214]],
    [ "Enedis",[3.1687857468481377, 45.79312815171919]],
    [ "Enedis",[1.9784337678381467, 48.77410318696791]],
    [ "Enedis",[3.1594894021987048, 45.79452224488339]],
    [ "Enedis",[1.9883546483089334, 48.76549830633485]],
    [ "GreenAlp",[5.91036957413208, 45.25757431722788]],
    [ "Enedis",[1.9940574076457946, 48.76790106213019]],
    [ "Enedis",[2.0120287297425135, 48.77236437067325]],
    [ "Enedis",[1.996620614825278, 48.77051924634548]],
    [ "Enedis",[2.0206171935738433, 48.78089749707469]],
    [ "Enedis",[1.9880634007645523, 48.77283102739574]],
    [ "Enedis",[1.9909830439339464, 48.76730340243708]],
    [ "Enedis",[1.998051778325347, 48.778712534360615]],
    [ "Enedis",[1.9888743449912767, 48.76572626633076]],
    [ "Komarov",[1.9968899984858088, 48.76909674732943]],
    [ "Enedis",[1.9920956921877957, 48.76663288392433]],
    [ "Enedis",[1.993660134645854, 48.77986144436789]],
    [ "Enedis",[1.9996565970875106, 48.77196525155532]],
    [ "Enedis",[1.9979299162183894, 48.76952769766892]],
    [ "Enedis",[1.981677766712594, 48.79373713526228]],
    [ "Enedis",[1.9837088926958182, 48.7635438428968]],
    [ "Enedis",[1.9795018418354686, 48.768626025748546]],
    [ "Enedis",[1.9787929753669262, 48.763445222512004]],
    [ "Enedis",[2.000730075865418, 48.769893819348624]],
    [ "Enedis",[5.92959355, 45.21844720000001]],
    [ "Enedis",[3.3116833439536872, 45.43855379428854]],
    [ "Enedis",[1.6062827059748963, 42.965833735313915]],
    [ "Enedis",[2.2294924847154713, 48.746042339995405]],
    [ "Enedis",[3.075220908744741, 45.82550154275127]],
    [ "IG CACHIN",[2.2357044489086144, 48.731445059265404]],
    [ "Enedis",[3.079395918596621, 45.82657524812378]],
    [ "Enedis",[3.07319079510279, 45.825402531104835]],
    [ "Enedis",[3.0821802, 45.828200900000006]],
    [ "Enedis",[3.079349395975687, 45.82851327534756]],
    [ "IG Ruchères",[2.232665126724644, 48.7326819360481]],
    [ "Enedis",[3.0833141162685433, 45.83319199442627]],
    [ "Enedis",[2.2301109253875446, 48.736241562388166]],
    [ "Enedis",[3.0701288490073266, 45.82714303096142]],
    [ "Enedis",[3.0799573000000002, 45.83544285000001]],
    [ "Enedis",[3.0742834195278745, 45.839828714161676]],
    [ "Enedis",[3.0740915264578557, 45.82861793006507]],
    [ "Enedis",[3.0794467302372586, 45.832008179040784]],
    [ "Enedis",[2.226858520614225, 48.746573536420584]],
    [ "Enedis",[2.223350687593712, 48.73151440043531]],
    [ "Enedis",[2.2228225642403014, 48.73769924916717]],
    [ "Enedis",[0.9316283890582918, 47.572011415225475]],
    [ "Enedis",[3.200677514494808, 45.762744020561456]],
    [ "Enedis",[3.2036921999999994, 45.77385605]],
    [ "Enedis",[3.1999084379171348, 45.76638052648563]],
    [ "Enedis",[3.1927661999999986, 45.775228549999994]],
    [ "Enedis",[3.181818639018913, 45.7694846839702]],
    [ "Enedis",[3.17658625, 45.77091455000001]],
    [ "Enedis",[3.1808370608393304, 45.77182171505013]],
    [ "Enedis",[3.1874708306392714, 45.76696380786226]],
    [ "Enedis",[3.203232971437237, 45.77190654750291]],
    [ "Enedis",[3.1867310905272297, 45.76867899627813]],
    [ "Enedis",[3.1995374500000002, 45.78022805]],
    [ "Enedis",[3.205342972587558, 45.77045604528302]],
    [ "Enedis",[3.203691280221058, 45.76571531351004]],
    [ "Enedis",[3.198353771337714, 45.77195336423588]],
    [ "Enedis",[3.205762284416871, 45.777620580300876]],
    [ "Enedis",[3.18558368377542, 45.77014833909732]],
    [ "Enedis",[3.1855588961714103, 45.77637589106416]],
    [ "Enedis",[3.1749901499999997, 45.778208600000006]],
    [ "Enedis",[3.1985469151671326, 45.76232386987869]],
    [ "Enedis",[3.201649550000001, 45.775724299999986]],
    [ "Enedis",[3.175098550000001, 45.77820445]],
    [ "Enedis",[3.1883798804581494, 45.77276558442642]],
    [ "Enedis",[3.181918837974272, 45.77350298102574]],
    [ "Enedis",[3.1828919168864953, 45.77299984779462]],
    [ "Enedis",[3.18536602971257, 45.77180145513814]],
    [ "Enedis",[3.19785345, 45.76500165]],
    [ "Enedis",[3.195556275294907, 45.773350405705756]],
    [ "Enedis",[3.1725084999999997, 45.77623500000001]],
    [ "Enedis",[3.2049505970271532, 45.77675386475087]],
    [ "Enedis",[3.2006861, 45.773977349999996]],
    [ "Enedis",[3.193774267199132, 45.771909262937925]],
    [ "Enedis",[3.1973539797116137, 45.769458205129325]],
    [ "Enedis",[3.1953971259398166, 45.768484362085395]],
    [ "Enedis",[3.2002776, 45.764547]],
    [ "Poste électrique des Buttes-Chaumont",[2.3762276352238456, 48.875490851319285]],
    [ "Enedis",[0.9118623933211221, 47.22718805792394]],
    [ "Enedis",[0.9192288722935371, 47.223419682545696]],
    [ "Enedis",[3.027055584156977, 42.81246893665213]],
    [ "Enedis",[2.378446348049073, 48.87854380197453]],
    [ "Enedis",[3.035092633451971, 42.783803804559035]],
    [ "Enedis",[3.0205012459047706, 42.815518032575206]],
    [ "Enedis",[3.026572819915952, 42.81402238518377]],
    [ "Enedis",[4.381255480219718, 45.43541995645072]],
    [ "Enedis",[3.0376861999999996, 42.80525065]],
    [ "Enedis",[3.0286766736960558, 42.81863085180765]],
    [ "Enedis",[3.0213338896790765, 42.81399289920411]],
    [ "Enedis",[2.3793680999999998, 48.876689250000005]],
    [ "Enedis",[4.416141954171987, 45.44697278627034]],
    [ "Enedis",[3.0287144870926537, 42.81153064905241]],
    [ "Enedis",[4.3947638499999995, 45.43263065]],
    [ "Enedis",[3.0314592361024797, 42.789878153166214]],
    [ "Enedis",[3.0311601880459556, 42.785403124929196]],
    [ "Enedis",[4.421846630133314, 45.42770506879092]],
    [ "Sablière 95 1353",[4.383994738089298, 45.42932747342806]],
    [ "Mouettes",[3.037589403267867, 42.803915877898824]],
    [ "Enedis",[3.030785959744483, 42.788419350893385]],
    [ "Enedis",[4.372935446063733, 45.46416660118006]],
    [ "Enedis",[3.0260190683738832, 42.805766361391925]],
    [ "F Villon",[4.405760434356348, 45.432006581236635]],
    [ "Enedis",[1.7222214259157782, 43.81899512940301]],
    [ "Enedis",[3.0365210718575373, 42.80982582469209]],
    [ "Enedis",[1.724825444193936, 43.82472397792349]],
    [ "Enedis",[3.0290760788357027, 42.809641132673825]],
    [ "Le jetée",[3.037299862190119, 42.79568383117945]],
    [ "Enedis",[4.396046180754142, 45.44247237008231]],
    [ "Enedis",[3.029371476225872, 42.823474574917185]],
    [ "Enedis",[1.7187270676799171, 43.818175748324244]],
    [ "Enedis",[3.02820892509587, 42.815892743625774]],
    [ "Enedis",[3.0230914041069217, 42.81360324317847]],
    [ "Enedis",[4.38613725, 45.434989349999995]],
    [ "Enedis",[4.392410887269189, 45.451483405047895]],
    [ "Enedis",[3.0312622560065643, 42.78757332055491]],
    [ "Maullers",[3.033996825414342, 42.82195342005905]],
    [ "C Bertholon",[4.391510043214211, 45.4298093971029]],
    [ "Enedis",[3.028583544348411, 42.81294570152781]],
    [ "Enedis",[3.0247888814816424, 42.81307924342104]],
    [ "Enedis",[3.0262944670017826, 42.81759606674185]],
    [ "Enedis",[3.0195413103915514, 42.81338328623497]],
    [ "Enedis",[1.680862169263259, 43.81282004542946]],
    [ "Enedis",[4.4181478364817925, 45.41559019354128]],
    [ "Enedis",[4.401577122365283, 45.45335292465426]],
    [ "Enedis",[1.7166206362665106, 43.81447865378721]],
    [ "Enedis",[4.3909910763120035, 45.44264858446194]],
    [ "Enedis",[4.381581458253499, 45.41960802232458]],
    [ "Enedis",[4.4082507, 45.45240640000001]],
    [ "Enedis",[1.7195835216121327, 43.82470529118566]],
    [ "Enedis",[4.380859765670359, 45.41613306137971]],
    [ "Enedis",[4.405104419614996, 45.43836494932232]],
    [ "Enedis",[1.724627100000001, 43.826600449999994]],
    [ "Enedis",[4.402355482734772, 45.42548945953671]],
    [ "Enedis",[4.394846905834052, 45.41692099415231]],
    [ "Enedis",[4.415705179533647, 45.44827232001619]],
    [ "Enedis",[0.9969721610187334, 47.132123432472376]],
    [ "Enedis",[4.40092632165408, 45.41945425016777]],
    [ "Enedis",[4.405980380416994, 45.45097321511155]],
    [ "Enedis",[4.383179933369123, 45.416735649791505]],
    [ "Enedis",[4.42197935, 45.43356655]],
    [ "Enedis",[4.402779440290626, 45.43924621203717]],
    [ "Jardin des Plantes",[4.398209278505566, 45.436999547436415]],
    [ "Enedis",[4.4003105258488295, 45.448228665006845]],
    [ "63268269",[1.006503319493336, 47.12925615107969]],
    [ "Enedis",[4.392233967162454, 45.43231774540721]],
    [ "Enedis",[4.418451970017798, 45.43505066053204]],
    [ "Enedis",[4.421794878418827, 45.42207297184328]],
    [ "Enedis",[4.392298758452195, 45.44070457096278]],
    [ "Enedis",[4.392983909415304, 45.42790928145802]],
    [ "Enedis",[4.419024513898938, 45.42183487434656]],
    [ "Enedis",[4.375339152316384, 45.435150835141236]],
    [ "Enedis",[4.3839249825269295, 45.434020002208335]],
    [ "Enedis",[0.995946875161559, 47.12870875676745]],
    [ "Enedis",[0.9972076259943887, 47.12992869932106]],
    [ "63275129",[1.0081441500000001, 47.1402779]],
    [ "Enedis",[0.9887974568471871, 47.120495896788995]],
    [ "Enedis",[4.389803677479688, 45.45903428935724]],
    [ "Enedis",[4.377440566408998, 45.421210776813034]],
    [ "Poste électrique de La Rivière",[4.4042677845518785, 45.413830103746136]],
    [ "Enedis",[2.845048346096242, 42.53491578448618]],
    [ "Enedis",[-0.3980823350111956, 43.22122503529646]],
    [ "Enedis",[0.9938760182960099, 47.129154047112415]],
    [ "Enedis",[4.381331682110181, 45.41625257283532]],
    [ "Enedis",[-0.4070092803356637, 43.20751598024857]],
    [ "Enedis",[2.831676933655618, 42.52825428469622]],
    [ "Chantegrillet",[4.397400725508164, 45.43862159092056]],
    [ "Enedis",[4.381005443427254, 45.450658847917644]],
    [ "Enedis",[4.387863428181947, 45.47004353447757]],
    [ "Enedis",[4.398500223018909, 45.45219058867539]],
    [ "Enedis",[2.8276141261863597, 42.5287371154298]],
    [ "Enedis",[2.829607907854195, 42.527948225270244]],
    [ "Enedis",[4.3912279550924005, 45.43373649429147]],
    [ "Enedis",[4.375860777668267, 45.46368550313717]],
    [ "Enedis",[2.8426047999999997, 42.53042570000001]],
    [ "Enedis",[2.8344619907061266, 42.52901221503577]],
    [ "Enedis",[4.432477407366958, 45.43714931082734]],
    [ "Enedis",[4.392871590588613, 45.460770201062715]],
    [ "Enedis",[4.3817711730582785, 45.453871679560365]],
    [ "Soulary",[4.402001128378229, 45.43224045668017]],
    [ "Enedis",[4.384261825608256, 45.42156297018941]],
    [ "Enedis",[4.3956617499999995, 45.421641349999994]],
    [ "Enedis",[4.385364531147071, 45.432569575663514]],
    [ "Enedis",[4.382009474791192, 45.41886915060665]],
    [ "Enedis",[4.389616099491769, 45.4445424728392]],
    [ "Enedis",[4.406410130338936, 45.44151439766809]],
    [ "Enedis",[4.395300992263632, 45.426183090627575]],
    [ "Enedis",[4.387236094358875, 45.436721362961364]],
    [ "Enedis",[4.3983372170768575, 45.416738912720724]],
    [ "Enedis",[4.423629201819471, 45.43063247324101]],
    [ "Enedis",[4.388784950000001, 45.410592799999996]],
    [ "Enedis",[4.416290331933972, 45.44918671261656]],
    [ "Enedis",[4.3841760999999995, 45.45723495000001]],
    [ "Frères Chappes",[4.391839520127203, 45.43989161320367]],
    [ "Enedis",[4.420405388177672, 45.44249500934368]],
    [ "Enedis",[4.39220545, 45.423862]],
    [ "Enedis",[4.388521900000001, 45.457938500000004]],
    [ "Sablière 2",[4.3839690610671145, 45.43498098200524]],
    [ "Enedis",[4.37556117834553, 45.4256490954358]],
    [ "Enedis",[4.382030386932935, 45.419132375323144]],
    [ "Allemane 29",[4.390615172855504, 45.41901689536652]],
    [ "Enedis",[4.385566396715239, 45.435558482832846]],
    [ "Enedis",[-0.3657554935431089, 43.35955033960114]],
    [ "Enedis",[-0.37461847923786884, 43.35506269565335]],
    [ "Enedis",[4.416278650000001, 45.44715885]],
    [ "Bois d'Avaize",[4.418807795776248, 45.43956264348034]],
    [ "Enedis",[4.380940209006183, 45.46762398023776]],
    [ "Enedis",[4.383447307518122, 45.40849340234021]],
    [ "Enedis",[4.382509057510054, 45.41837592782791]],
    [ "Enedis",[4.373522762562622, 45.42716357019381]],
    [ "Enedis",[4.3911208370636245, 45.427906721796255]],
    [ "A Briand",[4.3817040645259695, 45.43872046824215]],
    [ "Enedis",[4.38692445, 45.4106221]],
    [ "Enedis",[4.42220874979752, 45.42176542487252]],
    [ "Enedis",[4.396792447772131, 45.44244793299044]],
    [ "Enedis",[4.395974383079136, 45.44814018027817]],
    [ "Enedis",[4.387882542823441, 45.469937740184406]],
    [ "Enedis",[4.387556261048963, 45.43104920921372]],
    [ "Enedis",[4.381398693156013, 45.428351565632006]],
    [ "Enedis",[4.417203096603732, 45.44417007853879]],
    [ "Enedis",[4.380264764510718, 45.43187817462957]],
    [ "Enedis",[4.382227311780266, 45.43047445316629]],
    [ "Enedis",[4.404386693806684, 45.434101697967016]],
    [ "Enedis",[4.411175174619574, 45.42003121070097]],
    [ "Enedis",[4.38402098291738, 45.44084923352075]],
    [ "Lycée Mimard",[4.397757168761559, 45.43730065832244]],
    [ "Enedis",[4.382962620918684, 45.44599160921505]],
    [ "Enedis",[4.395241475993641, 45.45749643210534]],
    [ "Enedis",[4.414602687980867, 45.41907339357292]],
    [ "Enedis",[4.39015082580752, 45.41795642128422]],
    [ "Enedis",[4.389624199999999, 45.46234584999999]],
    [ "Enedis",[4.380757079840007, 45.445164191888246]],
    [ "Enedis",[4.367177900000001, 45.4466366]],
    [ "Enedis",[4.392706264654065, 45.43848773172431]],
    [ "Enedis",[4.378322193883819, 45.44108664284457]],
    [ "Rochetaillé II",[4.397389066328126, 45.42121771393229]],
    [ "Enedis",[2.9873111260240233, 42.77627566748136]],
    [ "Enedis",[4.415166216094875, 45.41570159870817]],
    [ "Enedis",[2.995349535842897, 42.76924933134318]],
    [ "Enedis",[4.388639311014993, 45.44659207799798]],
    [ "Les vergers",[2.990435373709506, 42.77636915788969]],
    [ "Enedis",[4.370747168421275, 45.44083264630982]],
    [ "Enedis",[2.995237394714789, 42.77616314666238]],
    [ "Enedis",[2.9777745258728223, 42.77138129492312]],
    [ "Rompoudes",[2.994653060437301, 42.77915004781464]],
    [ "Enedis",[2.998107163715236, 42.77201043321947]],
    [ "Enedis",[2.9933886079001, 42.77075869670913]],
    [ "Enedis",[2.998877768838235, 42.77499112282672]],
    [ "Enedis",[2.5163076682724923, 48.78251487099188]],
    [ "Enedis",[3.007764793545812, 42.77364951459117]],
    [ "Enedis",[2.990476858246168, 42.77385746158878]],
    [ "Enedis",[3.0123351202668625, 42.775895778618505]],
    [ "Enedis",[2.9962934881690226, 42.77383183822675]],
    [ "Enedis",[-0.38984709999999995, 43.367746450000006]],
    [ "Enedis",[-0.37297815000000006, 43.3713767]],
    [ "Enedis",[-0.38674439075656497, 43.359892280832625]],
    [ "Enedis",[3.0034238098457497, 42.772387860254064]],
    [ "Enedis",[2.994296585442248, 42.77749383799633]],
    [ "Enedis",[-0.3776296287560986, 43.37095116054155]],
    [ "Enedis",[2.9905252193177905, 42.76905261219998]],
    [ "Enedis",[-0.3742346960831601, 43.369938339777136]],
    [ "Enedis",[-0.38681864681608524, 43.36486986477989]],
    [ "Enedis",[3.007253381563924, 42.7711244518148]],
    [ "Enedis",[-0.3732266499999993, 43.374088750000006]],
    [ "Enedis",[-0.38264144999999994, 43.37625305]],
    [ "Enedis",[-0.383516881226581, 43.36439482444852]],
    [ "Enedis",[2.5204130517257655, 48.7807812768309]],
    [ "Enedis",[-0.3814902, 43.358380299999986]],
    [ "Enedis",[-0.3777369413582008, 43.36020207950791]],
    [ "Enedis",[-0.38397384632694115, 43.367146164051384]],
    [ "Enedis",[2.534603894739, 48.78660171742507]],
    [ "Enedis",[-0.3784099542134669, 43.356042132074315]],
    [ "Enedis",[-0.38126396557676734, 43.36441401831311]],
    [ "Enedis",[-0.38786755000000134, 43.368916]],
    [ "Enedis",[2.535099094907085, 48.78292856724822]],
    [ "Enedis",[-0.3820022923923559, 43.36099618452212]],
    [ "Enedis",[-0.38217752493383894, 43.36805654084222]],
    [ "Enedis",[-0.3838844637455746, 43.35838327919969]],
    [ "Enedis",[2.9916414521320895, 42.7722531290502]],
    [ "Enedis",[2.9920080723141886, 42.77769106049134]],
    [ "Enedis",[2.987730905695484, 42.76765855195658]],
    [ "Enedis",[2.993990426870256, 42.76740566059453]],
    [ "Enedis",[2.985713866392611, 42.765427167072176]],
    [ "Enedis",[3.9635777317061445, 50.27990968247544]],
    [ "Enedis",[2.9845658512887483, 42.77566567887093]],
    [ "Enedis",[2.9872463073703566, 42.766516110560204]],
    [ "Enedis",[2.989198545451352, 42.77186592448874]],
    [ "Enedis",[2.9866159500000014, 42.777772000000006]],
    [ "Enedis",[2.534873278934645, 48.78042438901187]],
    [ "Enedis",[2.5490026051332055, 48.78876073261169]],
    [ "Enedis",[2.542290457461888, 48.78671914137543]],
    [ "Enedis",[2.5242420988985668, 48.778959414660825]],
    [ "Enedis",[2.5289216805372714, 48.78348329576177]],
    [ "Enedis",[2.5325702711737628, 48.79165009539821]],
    [ "Enedis",[0.6684973203161279, 47.40819072221162]],
    [ "Enedis",[0.6728364398157026, 47.40036642516855]],
    [ "Enedis",[0.660080443344489, 47.403522685770014]],
    [ "Enedis",[0.6472248614822121, 47.40694412336257]],
    [ "Enedis",[0.6577847970869336, 47.39578681390417]],
    [ "RTE",[0.6777224929699794, 47.40174002848859]],
    [ "Enedis",[0.6491180527881799, 47.41307230933496]],
    [ "Enedis",[0.6523851182446244, 47.39666960965442]],
    [ "Enedis",[0.6563502864918279, 47.41803937279304]],
    [ "Enedis",[0.6547141295607428, 47.415975296493706]],
    [ "Enedis",[0.6686933824907614, 47.4215113195736]],
    [ "Enedis",[0.6764662656675053, 47.40868129036212]],
    [ "Enedis",[0.6584675823784186, 47.41407639273645]],
    [ "Enedis",[1.527641850000001, 47.913757450000006]],
    [ "Enedis",[0.6517521111682829, 47.4118119199814]],
    [ "Enedis",[1.5279747194416717, 47.908859186210485]],
    [ "Enedis",[0.6592005528919604, 47.42236953314082]],
    [ "Enedis",[0.6554536450232877, 47.42755036687014]],
    [ "Enedis",[0.6755395095367677, 47.40726384593075]],
    [ "Enedis",[1.5232842496422674, 47.90542855401037]],
    [ "Enedis",[1.5266528863026316, 47.91496628723]],
    [ "Enedis",[1.572623154433917, 47.896761301040485]],
    [ "Enedis",[0.6673172368195676, 47.417175313198996]],
    [ "Enedis",[0.6472638653051235, 47.40871467045196]],
    [ "Enedis",[1.5737794813890205, 47.89924619778704]],
    [ "Enedis",[0.6509905885340846, 47.395642092485346]],
    [ "Enedis",[1.5277393, 47.91212274999999]],
    [ "Enedis",[1.5231151330913468, 47.90835475159774]],
    [ "Enedis",[0.654971909533508, 47.42120167406653]],
    [ "Enedis",[0.6524497359660688, 47.41542739613622]],
    [ "Enedis",[3.9602644121266186, 50.28035806619416]],
    [ "Enedis",[0.6464611064275029, 47.43173048634716]],
    [ "Enedis",[0.6636076469183568, 47.40953564474136]],
    [ "Enedis",[0.6732319553168933, 47.41414991636702]],
    [ "Enedis",[0.6584490581207487, 47.41777062758176]],
    [ "Enedis",[0.6483813060132544, 47.42354911806892]],
    [ "Enedis",[1.3306186288136272, 43.685272555598736]],
    [ "Enedis",[0.6467530399798539, 47.41094185123573]],
    [ "Enedis",[0.6487492416523506, 47.393657572520794]],
    [ "Enedis",[0.6514543889119704, 47.419211666597704]],
    [ "Enedis",[0.6419883472076546, 47.428736965486614]],
    [ "Enedis",[0.6672982763064509, 47.41024427390579]],
    [ "Enedis",[1.3148148577904764, 43.68172265942857]],
    [ "Enedis",[1.3203425392431092, 43.68773273563226]],
    [ "Enedis",[0.6597855640904593, 47.41107681780831]],
    [ "Enedis",[0.6747466283867953, 47.40753360194722]],
    [ "Enedis",[1.3137917416287426, 43.68367477607594]],
    [ "Enedis",[0.6550721320454771, 47.39693418414077]],
    [ "Enedis",[0.6439506724307626, 47.4116254049788]],
    [ "Enedis",[0.6497649133539322, 47.42190990295487]],
    [ "Enedis",[1.3134006305842005, 43.68497270292376]],
    [ "Enedis",[1.3190258661336638, 43.67309003847897]],
    [ "Enedis",[1.3247657499999999, 43.6963062]],
    [ "Enedis",[1.3247143646159987, 43.69102646412541]],
    [ "Enedis",[1.3163926008246987, 43.68435201946847]],
    [ "Enedis",[1.3178866191554737, 43.68072315875692]],
    [ "Enedis",[1.334369125573212, 43.688628872009645]],
    [ "Enedis",[1.3388113194859388, 43.6883172902026]],
    [ "Enedis",[1.3456300341865204, 43.67109780228653]],
    [ "Enedis",[1.504738308306599, 43.51482205460358]],
    [ "Enedis",[1.5053002372794253, 43.51835227478384]],
    [ "Enedis",[1.320990374784271, 43.67642268903412]],
    [ "Enedis",[1.4953556077739387, 43.518401083218826]],
    [ "Enedis",[1.5107928129650041, 43.52197919944779]],
    [ "Enedis",[1.5030187239668977, 43.51875729395494]],
    [ "Enedis",[1.5039167367415291, 43.52408963149751]],
    [ "Enedis",[1.5231019442273497, 43.51455883022851]],
    [ "Enedis",[1.4990977451797411, 43.5194002899871]],
    [ "Enedis",[1.5105302590803102, 43.52421537030506]],
    [ "Enedis",[1.512561683434638, 43.52305941433396]],
    [ "Enedis",[1.4989632660050447, 43.515577034602934]],
    [ "Enedis",[1.5066076999999998, 43.52374485]],
    [ "Enedis",[1.4919559701118292, 43.51893959053571]],
    [ "Enedis",[1.488200304532625, 43.51366503364326]],
    [ "Enedis",[1.4982981141437501, 43.522049426313046]],
    [ "Enedis",[1.5151817699937982, 43.509630789693915]],
    [ "Enedis",[1.5114869927035615, 43.51309772854365]],
    [ "Enedis",[1.5013425521352943, 43.52093928265654]],
    [ "Enedis",[1.4996993336089548, 43.52477720127838]],
    [ "Enedis",[1.4972270690388823, 43.51708511573674]],
    [ "Enedis",[1.50584664479541, 43.5093138009419]],
    [ "Enedis",[3.0224611942376, 42.76424528461513]],
    [ "Enedis",[2.9965677635464956, 42.75376277277916]],
    [ "Enedis",[1.510924124645551, 43.52079804167547]],
    [ "Enedis",[1.48885335, 43.522814]],
    [ "Enedis",[2.98986705, 42.76145149999999]],
    [ "Enedis",[3.02496585, 42.763133350000004]],
    [ "Enedis",[1.5121049744911663, 43.51780697878035]],
    [ "Se Canto F3",[1.511035772588808, 43.51127044876521]],
    [ "Enedis",[1.4918903319026227, 43.52158487254692]],
    [ "Enedis",[1.5047544872099587, 43.519729341756396]],
    [ "Enedis",[1.508565564087676, 43.50981336158748]],
    [ "Enedis",[1.5196307549757775, 43.51138856004602]],
    [ "Enedis",[3.0344621264703555, 42.7616554833245]],
    [ "Enedis",[2.9922635029367983, 42.755272123043994]],
    [ "Enedis",[1.5192847351857766, 43.5202429985526]],
    [ "Enedis",[1.5049898072504118, 43.52232384822555]],
    [ "SRD",[0.656484081204749, 46.53689735130824]],
    [ "Enedis",[1.498093355376762, 43.512026016402466]],
    [ "Enedis",[1.497375019499905, 43.51939735379941]],
    [ "Enedis",[1.5168724928324104, 43.523824332722135]],
    [ "Enedis",[1.52378745, 43.51386905000001]],
    [ "Enedis",[1.5137880357854046, 43.513040417134825]],
    [ "Enedis",[1.495879567321716, 43.520221573531536]],
    [ "Enedis",[1.5092435372775046, 43.517275583391765]],
    [ "Enedis",[1.501198215792759, 43.51309438901374]],
    [ "Enedis",[1.4967170416409068, 43.52414636950026]],
    [ "Enedis",[1.5069392758243412, 43.5216357651309]],
    [ "Enedis",[1.4945468, 43.5163282]],
    [ "Enedis",[1.4949193322822718, 43.514225047904326]],
    [ "Enedis",[1.5079766491607183, 43.51380622849508]],
    [ "Enedis",[1.5068176202753236, 43.51193151250193]],
    [ "Enedis",[1.496117632328937, 43.51316365148671]],
    [ "Enedis",[1.50857426630697, 43.519320336252626]],
    [ "Enedis",[1.486101, 43.51518365]],
    [ "Sarance",[-0.3952888666666666, 43.300911253505014]],
    [ "Enedis",[2.867188191694352, 42.63559606943522]],
    [ "Enedis",[2.8739583821360064, 42.63880820965295]],
    [ "Enedis",[2.864585451214769, 42.637912352471616]],
    [ "Enedis",[-0.40225920248668945, 43.29550535197068]],
    [ "Enedis",[2.8702568030053817, 42.64062432856127]],
    [ "Enedis",[2.8752195000000005, 42.6436866]],
    [ "Enedis",[2.8738361272397537, 42.64538757918815]],
    [ "Enedis",[2.8770926554961562, 42.645030368179604]],
    [ "Enedis",[2.8654841611701807, 42.640170336451774]],
    [ "Enedis",[2.876686778381236, 42.6412109464727]],
    [ "Enedis",[2.869870701222393, 42.63270551712376]],
    [ "Enedis",[2.87089747696426, 42.64604296484153]],
    [ "Slice",[-0.3949123479355589, 43.29708854752799]],
    [ "Enedis",[0.6264390941917115, 46.55975461323777]],
    [ "Enedis",[0.6240726405281231, 46.56360259577418]],
    [ "Enedis",[0.6298702525009252, 46.570083636716454]],
    [ "Enedis",[0.6363134716054146, 46.56870191338727]],
    [ "Enedis",[2.0428186093943457, 50.97543226937629]],
    [ "Enedis",[2.0417873189585185, 50.97881852326769]],
    [ "Enedis",[2.093202604516089, 50.99321533130513]],
    [ "Enedis",[0.6334332990362268, 46.56900316961208]],
    [ "Enedis",[2.0792685710448757, 51.00046066090156]],
    [ "Enedis",[0.6253939065619573, 46.56203009153097]],
    [ "Enedis",[0.6236487130565116, 46.557128391641065]],
    [ "Enedis",[2.124662782727103, 48.696436863271785]],
    [ "Enedis",[2.0089499248355844, 50.99193309226632]],
    [ "Grignon",[2.2387914521560788, 48.97819733689439]],
    [ "Enedis",[2.0421290071073672, 50.995595025617526]],
    [ "Enedis",[1.9907862203815674, 50.95897677860308]],
    [ "Enedis",[2.1109016727388092, 48.6946872443593]],
    [ "Enedis",[2.1480789336505475, 48.70131107914698]],
    [ "Enedis",[2.0833252130604913, 50.98803440506697]],
    [ "Enedis",[2.036834033357428, 50.97965262640836]],
    [ "Enedis",[2.0655452670747634, 50.98134881049235]],
    [ "Enedis",[3.116301323114926, 50.689370371061685]],
    [ "Enedis",[3.094336787650099, 50.687256204155844]],
    [ "Enedis",[2.945813835679842, 50.74450211600119]],
    [ "Enedis",[2.143795428027078, 48.6971387415646]],
    [ "Enedis",[2.130893140655945, 48.69641741372853]],
    [ "Marks et Specenr",[1.0946518493740072, 49.44292842838294]],
    [ "SRD",[0.6556381478001873, 46.56186723020169]],
    [ "SRD",[0.6507237130754131, 46.559312480074006]],
    [ "SRD",[0.6481869292650644, 46.575190828575025]],
    [ "SRD",[0.6483067056982498, 46.57350712437656]],
    [ "SRD",[0.6533862908562104, 46.563911618337514]],
    [ "SRD",[0.6434794198360387, 46.576835845571445]],
    [ "SRD",[0.6464564718067162, 46.572987037938816]],
    [ "SRD",[0.6504817624870157, 46.56310060678651]],
    [ "SRD",[0.6442730311387407, 46.56003306424119]],
    [ "SRD",[0.6514683, 46.5675619]],
    [ "SRD",[0.6470045887417885, 46.5623884389488]],
    [ "SRD",[0.6446432000000001, 46.565774399999995]],
    [ "Enedis",[1.9670639, 48.855130499999994]],
    [ "Enedis",[1.9699918102407024, 48.85941471303854]],
    [ "Topi",[1.9898366893809627, 48.84972629761507]],
    [ "Enedis",[1.982034776065661, 48.85354889566764]],
    [ "Enedis",[1.981972410765995, 48.84049587076346]],
    [ "Enedis",[1.9820981681002419, 48.83894586480569]],
    [ "Enedis",[3.1600716599768317, 45.747835966970754]],
    [ "Enedis",[3.17267005, 45.74035945]],
    [ "Enedis",[3.2003903805267018, 45.725577725447764]],
    [ "Enedis",[3.2104519114356815, 45.73270124797958]],
    [ "Enedis",[3.2162557723593195, 45.739597200327275]],
    [ "Enedis",[0.7881724168476824, 49.50209055170404]],
    [ "Enedis",[3.198144475360586, 45.72860265536125]],
    [ "Enedis",[3.155932290563743, 45.74607951170067]],
    [ "Enedis",[3.179287, 45.72595245000001]],
    [ "Enedis",[0.7658285994855968, 49.52797940077162]],
    [ "Enedis",[3.1903991237589335, 45.73271228077453]],
    [ "Enedis",[3.19108235, 45.73134525]],
    [ "Enedis",[3.2019840119116147, 45.73522558033711]],
    [ "Enedis",[3.1910776000000003, 45.72681765]],
    [ "Enedis",[3.1711129911794425, 45.742942842202005]],
    [ "Enedis",[3.167597807540165, 45.745037638939735]],
    [ "Enedis",[3.2080756209277985, 45.74067514951749]],
    [ "Enedis",[3.19480396461747, 45.737920626425655]],
    [ "Enedis",[3.1720897211120715, 45.739098562588886]],
    [ "Enedis",[3.1979251749702864, 45.74134739354898]],
    [ "Enedis",[3.1589635392630924, 45.74866906690687]],
    [ "Enedis",[3.1664252972752096, 45.74296005832115]],
    [ "Enedis",[3.1790824658372214, 45.732370397511666]],
    [ "Enedis",[3.2042592384740525, 45.7400380206344]],
    [ "Enedis",[3.15409426844399, 45.75630990034571]],
    [ "Enedis",[3.1797766657123385, 45.73177300531697]],
    [ "Enedis",[3.205122163123082, 45.74387902210537]],
    [ "Enedis",[3.1877568124015436, 45.72875203054923]],
    [ "Enedis",[3.2069360650098795, 45.7345588499468]],
    [ "Enedis",[3.168388806475635, 45.74237471345541]],
    [ "Enedis",[3.1784743817304513, 45.745238043317855]],
    [ "Enedis",[3.1953662848656252, 45.73308645233005]],
    [ "Enedis",[3.209533432590539, 45.73757607717908]],
    [ "Enedis",[3.1974485500000007, 45.7334223]],
    [ "Enedis",[3.1648273831409037, 45.740821900528324]],
    [ "Enedis",[3.18495925, 45.73065655]],
    [ "Enedis",[3.2107884221453293, 45.73483137981545]],
    [ "Enedis",[3.2009941737252605, 45.73814415350584]],
    [ "Enedis",[3.1790627946677352, 45.742977616409185]],
    [ "Enedis",[3.190166978802115, 45.74097164983009]],
    [ "Enedis",[3.1604221499999996, 45.74156035]],
    [ "Enedis",[3.1801050999999996, 45.734702350000006]],
    [ "Enedis",[3.203405006948105, 45.747450984461175]],
    [ "Enedis",[3.2015304386612993, 45.74293907845892]],
    [ "Enedis",[3.1961723692343957, 45.73945394420373]],
    [ "Enedis",[3.1729255421472335, 45.738777296924376]],
    [ "Enedis",[3.182135283742881, 45.743224756354586]],
    [ "Enedis",[3.2090496407239786, 45.73045076973803]],
    [ "Enedis",[3.163356638324148, 45.74360550130364]],
    [ "Enedis",[3.2115552, 45.731636800000004]],
    [ "Enedis",[3.188604399999999, 45.72992275]],
    [ "Enedis",[3.205124784596468, 45.72582307769934]],
    [ "Enedis",[3.1649219659392465, 45.743639848467026]],
    [ "Enedis",[3.1983422500000005, 45.744297599999996]],
    [ "Enedis",[3.1774735000000005, 45.7251115]],
    [ "Enedis",[3.2000975010834973, 45.74740293659508]],
    [ "Enedis",[3.204851610373444, 45.73173010134057]],
    [ "Enedis",[3.2073457799607796, 45.72875174267343]],
    [ "Enedis",[3.2084665054001, 45.73973925204723]],
    [ "Enedis",[3.211677365733296, 45.7366577966888]],
    [ "Enedis",[-0.38021912551379267, 43.33931095590359]],
    [ "Enedis",[3.193248638234016, 45.730343130131786]],
    [ "Enedis",[3.2036909, 45.75090244999999]],
    [ "Enedis",[3.18037451747089, 45.726835790950176]],
    [ "Enedis",[3.1834527500000003, 45.7360691]],
    [ "Enedis",[3.1878583820242166, 45.732790425705765]],
    [ "Enedis",[3.1564392575236457, 45.746566843135575]],
    [ "Enedis",[3.1624927499999997, 45.749471850000006]],
    [ "Enedis",[-3.1576144332518767, 47.34275574206477]],
    [ "Enedis",[-3.156280199999999, 47.34422715]],
    [ "Enedis",[-3.1647193500000004, 47.34469959999999]],
    [ "Enedis",[3.1970512494661296, 45.73740186786277]],
    [ "Enedis",[-3.1726003000000005, 47.359322299999995]],
    [ "Enedis",[3.18827195, 45.739107100000005]],
    [ "Enedis",[3.1922587956414934, 45.736359381148155]],
    [ "Enedis",[3.165234857565912, 45.74487256097061]],
    [ "Enedis",[3.1800989136449203, 45.73931688237058]],
    [ "Enedis",[3.1702700078502803, 45.74077553471461]],
    [ "Enedis",[3.1759020149099544, 45.742896906714535]],
    [ "Enedis",[-0.39319405482308556, 43.33215192408776]],
    [ "Enedis",[3.2042625276142136, 45.73399914543068]],
    [ "Enedis",[3.1613689635590014, 45.73937261480516]],
    [ "Enedis",[3.2203910014911603, 45.74022345754577]],
    [ "Enedis",[3.1844068658897227, 45.73488389940137]],
    [ "Enedis",[3.15596755, 45.743632299999994]],
    [ "Enedis",[3.1923641763186548, 45.733896156373]],
    [ "Enedis",[4.355759463511099, 45.46198796302976]],
    [ "Enedis",[3.186479823031475, 45.74136254108927]],
    [ "Enedis",[4.355170887643882, 45.466072442032015]],
    [ "PIAF",[-0.3874252134525603, 43.33040081839238]],
    [ "Enedis",[3.18395597327558, 45.732609989657796]],
    [ "Enedis",[3.214506218173255, 45.73913485845867]],
    [ "Enedis",[3.156923260397588, 45.75322066466482]],
    [ "Enedis",[-0.39520844233286134, 43.334282669151165]],
    [ "Enedis",[3.2135170003807834, 45.734089458032884]],
    [ "Enedis",[3.187084991607594, 45.73686654651709]],
    [ "Enedis",[3.181083455216273, 45.73750043242033]],
    [ "Enedis",[-0.38829630307841834, 43.32965106851372]],
    [ "Enedis",[3.1689110087805106, 45.74590626420435]],
    [ "Enedis",[3.2073775999999996, 45.737766500000006]],
    [ "Enedis",[-0.3945922229938196, 43.33299853860276]],
    [ "Enedis",[3.1970563384986423, 45.730265725082845]],
    [ "Enedis",[-0.3887695823151423, 43.332364291780415]],
    [ "Enedis",[3.1955309096170335, 45.74360623052169]],
    [ "Enedis",[3.1821725333920443, 45.72858960014143]],
    [ "Enedis",[-0.3880446362375261, 43.33517738846101]],
    [ "Enedis",[3.2037724509122825, 45.7379536857884]],
    [ "Enedis",[3.1995941898810636, 45.73590368685471]],
    [ "Enedis",[3.1807275500000007, 45.746739500000004]],
    [ "Enedis",[-0.3934132737873728, 43.33750377649965]],
    [ "Enedis",[-0.38638325, 43.336337500000006]],
    [ "Enedis",[-0.3840433500000001, 43.33009245]],
    [ "Enedis",[-0.39257168833215134, 43.319371582402646]],
    [ "Enedis",[-0.389869967376629, 43.327835650939804]],
    [ "Enedis",[-0.386270448946213, 43.33227070026157]],
    [ "Enedis",[-0.39080399433071883, 43.3360100929915]],
    [ "Enedis",[-0.3934892766319241, 43.33555336515718]],
    [ "Enedis",[-0.38555853765125614, 43.32819938736702]],
    [ "Enedis",[-0.39041626578173216, 43.3348964332215]],
    [ "Enedis",[2.536861653888279, 48.76356292301971]],
    [ "Enedis",[-0.3828133121542742, 43.33677697517465]],
    [ "Enedis",[2.5329255, 48.757316499999995]],
    [ "Enedis",[2.5206968750345498, 48.7749864471806]],
    [ "Enedis",[2.5114190893308477, 48.779835536499704]],
    [ "Enedis",[2.51792115, 48.76902520000001]],
    [ "Enedis",[2.5229150999999996, 48.76731410000001]],
    [ "Enedis",[2.5295855328878365, 48.76795007879062]],
    [ "Enedis",[2.5356956500000005, 48.77282314999999]],
    [ "Enedis",[2.51200255, 48.76347719999999]],
    [ "Enedis",[-0.887819952800645, 43.22720564889716]],
    [ "Enedis",[-0.8931640081067336, 43.22174240695141]],
    [ "Enedis",[-0.8931145500000001, 43.21344644999999]],
    [ "Enedis",[-0.8917659232168996, 43.218860122869565]],
    [ "Enedis",[-0.8832595290554702, 43.224529311490336]],
    [ "Enedis",[-0.8777891000000001, 43.22346515]],
    [ "Enedis",[-0.8781091999999998, 43.22643420000001]],
    [ "Enedis",[-0.8892431001548303, 43.21846900085971]],
    [ "P6 Triple Gema",[-0.893837757779322, 43.212010377646514]],
    [ "Enedis",[-0.8876537999999999, 43.2229825]],
    [ "Enedis",[-0.8905228099145577, 43.22351006586928]],
    [ "Enedis",[-0.8831719225614895, 43.22190183575505]],
    [ "Enedis",[-0.8805105334825233, 43.23162725083686]],
    [ "Enedis",[-0.8893628551026602, 43.22022453133946]],
    [ "Enedis",[-0.8816237641590182, 43.22126931840112]],
    [ "Enedis",[-0.8863603862888952, 43.22823200549889]],
    [ "Enedis",[-0.8792387173692682, 43.22942143106049]],
    [ "Enedis",[-0.8864153012191309, 43.222323133268354]],
    [ "SRD",[0.1787258455754798, 46.6415650311979]],
    [ "SRD",[0.16412962048028554, 46.63132261801924]],
    [ "SRD",[0.16604975, 46.62939484999999]],
    [ "SRD",[0.1847124662635852, 46.63083138566364]],
    [ "SRD",[0.1656982999999993, 46.639219049999994]],
    [ "SRD",[0.16517127823547573, 46.64274261458389]],
    [ "SRD",[0.17332951060619978, 46.639767712414155]],
    [ "SRD",[0.1705776659567104, 46.6422518338831]],
    [ "SRD",[0.20139773652456924, 46.63624231176978]],
    [ "SRD",[0.17721300115616856, 46.63977163255122]],
    [ "SRD",[0.16193465, 46.6387399]],
    [ "Enedis",[3.936631033126724, 50.278822224927126]],
    [ "Enedis",[3.9364660384255834, 50.2808270882029]],
    [ "Enedis",[-0.4194765899122255, 43.33411762881563]],
    [ "Enedis",[-0.4472309999999996, 43.341613249999995]],
    [ "Hourquie",[-0.43470445119320045, 43.335547483088156]],
    [ "Coopegaz",[-0.4398269224658027, 43.33676095487228]],
    [ "Enedis",[-0.43105539088389033, 43.33213912899822]],
    [ "Enedis",[-0.431150825021131, 43.33470492844823]],
    [ "Enedis",[-0.427112059330534, 43.330619800632945]],
    [ "Enedis",[-0.4354714471785029, 43.331811012493915]],
    [ "Enedis",[-0.42327847689924303, 43.32998148834249]],
    [ "3 ponts",[-0.439599804075075, 43.32341933300092]],
    [ "Enedis",[-0.4050772276231682, 43.3323641638543]],
    [ "Enedis",[-0.4044652789760881, 43.33094506206517]],
    [ "Enedis",[-0.4442123291524234, 43.333027167170364]],
    [ "Enedis",[-0.4410987349712572, 43.330992645981276]],
    [ "Enedis",[-0.42408552542921, 43.33647075378428]],
    [ "Enedis",[-0.44195461117519214, 43.33318626836514]],
    [ "Enedis",[-0.45090009999999897, 43.34232985]],
    [ "Enedis",[-0.44451756867739806, 43.334518637258405]],
    [ "Enedis",[-0.4075578, 43.33217899999999]],
    [ "Enedis",[-0.44599590369280645, 43.340207744042296]],
    [ "Enedis",[-0.44158337464764547, 43.33476248673393]],
    [ "Enedis",[-0.43516208750324215, 43.330007604409026]],
    [ "Enedis",[-0.4413602982387823, 43.3217138836384]],
    [ "Hauteville",[-0.43846506250761763, 43.3379958068792]],
    [ "Enedis",[-0.4419875686777275, 43.32558919808949]],
    [ "Enedis",[-0.47743763011325174, 43.34775017082824]],
    [ "Enedis",[-0.4784627395237204, 43.3461146857568]],
    [ "Enedis",[0.8752485192782165, 46.422552027482716]],
    [ "Enedis",[1.4427431092828369, 43.59383142882316]],
    [ "Enedis",[0.8702470898662067, 46.42965017273715]],
    [ "Enedis",[1.438656094939745, 43.59037404506385]],
    [ "Enedis",[0.8569667215780452, 46.41876089458154]],
    [ "Enedis",[0.8660938093615386, 46.423818880023255]],
    [ "Enedis",[0.8638996201508585, 46.42697611576557]],
    [ "Enedis",[0.8690879999999997, 46.426587049999995]],
    [ "Enedis",[2.970109276126659, 50.60664341773749]],
    [ "Enedis",[2.972437162038953, 50.60639087381542]],
    [ "Enedis",[0.869073798771485, 46.42495341007095]],
    [ "SRD",[0.8482516731042586, 46.4177407926045]],
    [ "Enedis",[0.8708270630735668, 46.41959533220958]],
    [ "SRD",[0.8903995089125306, 46.43184260794901]],
    [ "Enedis",[2.9793033454051727, 50.613528554928706]],
    [ "Enedis",[2.9815003636240403, 50.61277001719335]],
    [ "SRD",[0.8476023790244095, 46.418369541192575]],
    [ "Enedis",[2.985059220020063, 50.611723971412886]],
    [ "Enedis",[0.8725990157940837, 46.42130495556523]],
    [ "Enedis",[2.9766586618900326, 50.61721622212074]],
    [ "Enedis",[2.9782104056540586, 50.61485719105043]],
    [ "Enedis",[2.9805112811630154, 50.615780470668085]],
    [ "Enedis",[0.8850146084784442, 46.43136290335454]],
    [ "Enedis",[0.8653223032115016, 46.432398078105976]],
    [ "Enedis",[2.978641259236941, 50.61691058781814]],
    [ "SRD",[0.8538489991948464, 46.417870553574495]],
    [ "Enedis",[0.8658842547740373, 46.42849115467586]],
    [ "SRD",[0.8749918077145755, 46.43631939469242]],
    [ "Enedis",[2.991867521209677, 50.60408305026193]],
    [ "Enedis",[2.9892441475046567, 50.60984354728577]],
    [ "Enedis",[2.990033281754541, 50.61215900711675]],
    [ "Enedis",[2.995989829894384, 50.61000433670702]],
    [ "Enedis",[2.9975561728486544, 50.61389511593719]],
    [ "Enedis",[3.0006218695502223, 50.60919281436418]],
    [ "Enedis",[3.0000966753107345, 50.61226540259888]],
    [ "Enedis",[2.997752124350447, 50.61425706861328]],
    [ "Enedis",[2.996680365661253, 50.6168403675174]],
    [ "Enedis",[6.970113556209384, 43.61377026550112]],
    [ "Sables Blanc",[-3.6663085440705028, 48.68896538379633]],
    [ "Enedis",[-3.64473795, 48.69263905]],
    [ "Enedis",[-3.655253877876106, 48.69303075924665]],
    [ "Enedis",[-3.653193960561235, 48.69130282935642]],
    [ "Enedis",[-3.6504233508339925, 48.691823599991245]],
    [ "Enedis",[-3.6585463689379236, 48.676969119258615]],
    [ "Enedis",[-3.6560586336603595, 48.68901515533754]],
    [ "La Bérardière",[0.1532563999999995, 47.9739828]],
    [ "Les Tisserands",[0.1595342514000701, 47.961579404808916]],
    [ "Enedis",[1.4290260911410277, 43.59065283029349]],
    [ "Enedis",[0.1436812235691803, 47.944788174841314]],
    [ "Foyer personnes agées",[0.1561308868352963, 47.96358446448972]],
    [ "Claude Chappe",[0.15381658532676865, 47.968534382249864]],
    [ "Picardie",[0.17071876863177984, 47.9553882550149]],
    [ "Île de France",[0.17178843997630042, 47.95214880588549]],
    [ "T67 Einstein",[0.14495035364822242, 47.97472531814119]],
    [ "Enedis",[0.1455304963079815, 47.973065250640204]],
    [ "Métairies",[0.1663164449694946, 47.961133834719185]],
    [ "La Chopinière",[0.1299471, 47.948501650000004]],
    [ "Le Broussil",[0.1640067, 47.955892049999996]],
    [ "Zone d'aménagement commerciale du Vivier",[0.1495075273139124, 47.975278434560074]],
    [ "Périgord",[0.16790006153257994, 47.952088655406904]],
    [ "Le Vivier",[0.1514111499999995, 47.973391150000005]],
    [ "Maison d'accueil",[0.16524372247767827, 47.958090560089765]],
    [ "Raterie",[0.1559814, 47.97331479999999]],
    [ "Les Cordiers",[0.15771140000000086, 47.962633399999994]],
    [ "Les Perrières",[0.1550311, 47.95995945]],
    [ "Chaufferie",[0.15783987795974072, 47.97228674677585]],
    [ "Enedis",[0.1457929336702341, 47.94302651536861]],
    [ "Le Peuplier",[0.1485919346705995, 47.97982263360866]],
    [ "Muloteries",[0.16822120000000002, 47.959640900000004]],
    [ "Essilor",[0.1529033566131154, 47.97335219903395]],
    [ "Enedis",[0.14562288854696787, 47.97304361058202]],
    [ "Vendée",[0.1679461000000007, 47.954208750000014]],
    [ "Enedis",[2.47597256363707, 48.59889816154258]],
    [ "Enedis",[2.453415311420191, 48.61370666162827]],
    [ "Enedis",[2.478657619540099, 48.616056056805824]],
    [ "Enedis",[2.4657664063576292, 48.61118320552754]],
    [ "Enedis",[2.4666793076581435, 48.602095483203215]],
    [ "Enedis",[2.4776359, 48.57351795]],
    [ "Enedis",[2.4618601095813446, 48.60075857553581]],
    [ "Enedis",[2.470383254902626, 48.58631439407868]],
    [ "Enedis",[2.4656168693145806, 48.61938713765039]],
    [ "Enedis",[2.453990120670641, 48.61224124376175]],
    [ "Enedis",[2.4716133540745866, 48.602627995512634]],
    [ "Enedis",[2.47886942596614, 48.5948940785256]],
    [ "Enedis",[2.470412835183642, 48.588947162269314]],
    [ "Enedis",[6.373013699999999, 46.867691099999995]],
    [ "Enedis",[6.377427799999999, 46.8673628]],
    [ "Enedis",[6.371857635563037, 46.85820446331303]],
    [ "Enedis",[2.476808060051702, 48.596323467659]],
    [ "Enedis",[3.934984726913052, 50.27396279837985]],
    [ "Enedis",[3.938468584493322, 50.272513453467724]],
    [ "Enedis",[2.4772448955006428, 48.5799197626434]],
    [ "Enedis",[2.4778243730575475, 48.59167407078604]],
    [ "Enedis",[2.464314521371944, 48.61556557601077]],
    [ "Enedis",[1.0876177693570328, 49.44250593770401]],
    [ "Enedis",[3.9480921028494897, 50.27433226321108]],
    [ "Enedis",[3.9452379998390366, 50.27430149955036]],
    [ "Enedis",[0.0875808281419471, 49.35209160922746]],
    [ "Enedis",[0.08085525158759929, 49.348308661618326]],
    [ "Enedis",[0.0752548644078934, 49.353515002082695]],
    [ "Enedis",[0.06361287743199284, 49.35398938365831]],
    [ "Enedis",[0.07347049139410738, 49.3515848276913]],
    [ "Enedis",[0.08109737325161429, 49.34586493529938]],
    [ "Enedis",[0.08771219661888863, 49.35766692805324]],
    [ "Enedis",[0.06606442056578582, 49.36010425438505]],
    [ "Enedis",[0.0706868129011643, 49.35179705170083]],
    [ "Enedis",[0.07359662550949204, 49.34986813987796]],
    [ "Enedis",[0.09030711092345858, 49.35556576725393]],
    [ "Enedis",[0.8112459000000001, 49.47711555000001]],
    [ "Enedis",[0.8149310015136162, 49.47032500110142]],
    [ "Enedis",[0.8173437331211225, 49.474762324654215]],
    [ "Enedis",[0.8167788824509286, 49.479027108734925]],
    [ "Enedis",[0.8150740405959701, 49.47345304086762]],
    [ "Enedis",[0.35013862371716226, 46.95595144235292]],
    [ "Enedis",[0.3422410841887979, 46.95633588065157]],
    [ "Enedis",[6.3081019000000005, 46.91783315]],
    [ "Paris",[2.4222387455254126, 48.81183158931955]],
    [ "Poste électrique de Faidherbe",[2.382419757166446, 48.85259788967674]],
    [ "Enedis",[2.3988032598449998, 48.84871706324869]],
    [ "Poste HT du Père-Lachaise",[2.3861177741337074, 48.86359847497954]],
    [ "81 Bd de Charonne",[2.3959546475976055, 48.8544128675416]],
    [ "OCRS F208 Bd Charonne",[2.390515147963846, 48.85819259979608]],
    [ "Enedis",[0.6309178189448414, 47.44135663447618]],
    [ "Poste HT du Père-Lachaise",[2.384509446532155, 48.86492740856596]],
    [ "OCRS F",[2.3981713771257036, 48.851636027712075]],
    [ "Enedis",[1.4555596, 43.5944772]],
    [ "Enedis",[1.5645421499999999, 43.5452922]],
    [ "Enedis",[1.571031551219153, 43.53915966756323]],
    [ "Enedis",[1.56700875, 43.5423595]],
    [ "Enedis",[1.5558553243747966, 43.536655919957475]],
    [ "Enedis",[1.5635957998263563, 43.54691505340565]],
    [ "Enedis",[1.5703532978456194, 43.542257010648974]],
    [ "Enedis",[1.5612131591218876, 43.54804143014146]],
    [ "Enedis",[3.948109012935784, 50.27293723391128]],
    [ "Enedis",[2.93144285720308, 42.72234962224867]],
    [ "Enedis",[2.9338306720586016, 42.734680585384965]],
    [ "Enedis",[2.935251615102906, 42.72997964067249]],
    [ "Enedis",[2.9348679880985187, 42.72736747026421]],
    [ "Enedis",[2.9292106214833895, 42.72535089500909]],
    [ "Enedis",[2.9275649655531253, 42.72425470798733]],
    [ "Enedis",[-0.5667172499999996, 43.39734355000001]],
    [ "Ecole",[-0.5731180913263243, 43.39745576730149]],
    [ "Enedis",[-0.560303710834337, 43.39264765502493]],
    [ "Enedis",[-0.5636728499999988, 43.39213279999999]],
    [ "Enedis",[-0.5720465464011184, 43.3995923262099]],
    [ "Enedis",[-0.56008045752845, 43.389585451358414]],
    [ "Enedis",[-0.5616365205858239, 43.39829704722169]],
    [ "Enedis",[-0.5669737105883932, 43.39257673361663]],
    [ "Enedis",[-0.5766576973084409, 43.39979240419387]],
    [ "Enedis",[-0.5639527658153709, 43.39799050224152]],
    [ "Enedis",[-0.5620030825644242, 43.39415739627305]],
    [ "Enedis",[-0.5716272411078958, 43.39473750575353]],
    [ "Enedis",[0.3184964399430404, 47.01217405583985]],
    [ "Enedis",[-0.5640852298789334, 43.40374125819201]],
    [ "Enedis",[-0.5597216888831511, 43.400739114592724]],
    [ "Enedis",[-0.5573718999999999, 43.3982794]],
    [ "Enedis",[0.3219825276251648, 47.02757521624685]],
    [ "Enedis",[0.32145598329781105, 47.028345921598074]],
    [ "Enedis",[-3.1529858152073733, 47.30357768177162]],
    [ "Enedis",[-3.23167812758186, 47.3148881461127]],
    [ "Enedis",[-3.22575465, 47.30650295]],
    [ "Enedis",[-3.1869038500000015, 47.3149016]],
    [ "Enedis",[-3.2215772499999984, 47.31079705]],
    [ "Enedis",[-3.2284291, 47.319849149999996]],
    [ "Enedis",[-0.6432708533983585, 43.42038738274317]],
    [ "Enedis",[-0.6289909922925085, 43.41353910202086]],
    [ "Enedis",[-0.62118085, 43.41336865]],
    [ "Enedis",[1.4451802499999993, 43.571055900000005]],
    [ "Enedis",[1.4496104007046555, 43.57238666662711]],
    [ "Enedis",[1.4470898577238016, 43.568338415389334]],
    [ "Enedis",[1.4395994687570732, 43.569140577185074]],
    [ "Enedis",[1.4490469618990192, 43.572972554752916]],
    [ "Enedis",[0.5684529843342825, 49.49465432093807]],
    [ "Enedis",[0.5701768594386666, 49.485042856511356]],
    [ "Enedis",[0.5768410862709901, 49.48061651907701]],
    [ "Enedis",[0.5834421499999999, 49.48966265]],
    [ "Enedis",[0.5772196499059646, 49.48081265217317]],
    [ "Enedis",[3.037103498578284, 42.72833566673481]],
    [ "Enedis",[3.031192554249674, 42.724239246573774]],
    [ "Enedis",[3.036429395984736, 42.7270830321636]],
    [ "Enedis",[3.0179976376760083, 42.725750054208234]],
    [ "Enedis",[3.032516800628931, 42.73935341229274]],
    [ "Enedis",[3.0182930656448335, 42.72872583149277]],
    [ "Enedis",[3.0214598500000007, 42.72466975]],
    [ "Enedis",[3.0345104725721397, 42.726237712347945]],
    [ "Enedis",[3.0145085993561405, 42.72909193200658]],
    [ "Enedis",[3.0354448736469943, 42.736804470215226]],
    [ "Enedis",[-3.084048033881193, 47.294042597400576]],
    [ "Enedis",[-3.1030893541681546, 47.29383278197825]],
    [ "Enedis",[-3.1251302937489407, 47.31175501755686]],
    [ "Enedis",[-3.074563833639122, 47.30594419945689]],
    [ "Enedis",[2.9835741685768147, 42.7246319457577]],
    [ "Enedis",[2.986858936120606, 42.726367858100154]],
    [ "Enedis",[2.9684141242234796, 42.724128414631046]],
    [ "Enedis",[-5.123099656987498, 48.45722779547371]],
    [ "Enedis",[-5.0961780999999995, 48.45710915]],
    [ "Enedis",[-5.0773372204928835, 48.453570283258586]],
    [ "Cost ar Reun",[-5.1253037, 48.45550005000001]],
    [ "Enedis",[-5.088421447542421, 48.44887133286148]],
    [ "Enedis",[-5.064281005587788, 48.45846983515391]],
    [ "Enedis",[-5.11203645, 48.461043450000005]],
    [ "Enedis",[2.8354007130467247, 42.6729876268881]],
    [ "Enedis",[2.42441357998048, 48.64013031560339]],
    [ "Enedis",[2.8326782942612208, 42.66507917547588]],
    [ "Enedis",[2.45360950084593, 48.62567101671686]],
    [ "Enedis",[2.836198490681704, 42.66618668467329]],
    [ "Darniche",[2.427973151891415, 48.6210905744005]],
    [ "Enedis",[2.438522814780857, 48.617031299377174]],
    [ "Enedis",[6.9220055, 46.02580595]],
    [ "SST Vallorcine",[6.933025000238746, 46.032772267699904]],
    [ "Vallorci",[6.931840615752229, 46.03237105178513]],
    [ "Enedis",[6.925464249323595, 46.02716739996728]],
    [ "Enedis",[6.93833005, 46.04175105]],
    [ "Enedis",[6.923307528570918, 46.023970007598464]],
    [ "Enedis",[2.8383251077419014, 42.67413603141967]],
    [ "Enedis",[2.830130706850554, 42.667795477451286]],
    [ "Enedis",[6.933411309720701, 46.035758700307184]],
    [ "Enedis",[2.8241347269365535, 42.67253736053082]],
    [ "Enedis",[6.9178137534331015, 46.023290967936724]],
    [ "Enedis",[2.8276486083257666, 42.67292248399356]],
    [ "Enedis",[2.836847382521948, 42.66914194061202]],
    [ "Enedis",[2.82007765291577, 42.66314321719029]],
    [ "Enedis",[2.8349610133487086, 42.66920568669103]],
    [ "Enedis",[2.8393464092027836, 42.66658976752042]],
    [ "Enedis",[2.8322205252051904, 42.673379246465444]],
    [ "Enedis",[2.8349800017879643, 42.66331407497428]],
    [ "Jabouille",[2.429954338425948, 48.61769403536822]],
    [ "Enedis",[2.8341449124238145, 42.671433832595994]],
    [ "Enedis",[2.4456906282712345, 48.631984397054055]],
    [ "Enedis",[2.4469324201447438, 48.631110293865895]],
    [ "Enedis",[2.450139795197298, 48.61923784645696]],
    [ "Enedis",[2.826288852090427, 42.668439124181766]],
    [ "Enedis",[2.827110587471508, 42.67073644084649]],
    [ "Enedis",[2.4389667190643665, 48.63607427344984]],
    [ "Enedis",[2.437036399813573, 48.63017048691301]],
    [ "Enedis",[2.431028142536149, 48.63113831842827]],
    [ "Enedis",[2.448296173844212, 48.621305421942665]],
    [ "Enedis",[2.9552362345148375, 42.65469403085677]],
    [ "Enedis",[2.9447135432821145, 42.65888894130064]],
    [ "Enedis",[2.9535737367564647, 42.65075825972138]],
    [ "Enedis",[2.9517665233149932, 42.65777277147702]],
    [ "Enedis",[2.9307150666332995, 42.656578363672004]],
    [ "Enedis",[2.9532483708268678, 42.66026673656198]],
    [ "Enedis",[2.9537026715538492, 42.6538040385714]],
    [ "Enedis",[2.9461170100355285, 42.65580127709413]],
    [ "Enedis",[2.9564362069719605, 42.65614259367686]],
    [ "Enedis",[2.9479688459621722, 42.65810761529145]],
    [ "Enedis",[2.9517214310532123, 42.65261605149961]],
    [ "Enedis",[2.942283318520262, 42.65924098509444]],
    [ "Enedis",[2.956186210328512, 42.65798558000969]],
    [ "Enedis",[2.938636131197912, 42.652914397552934]],
    [ "Enedis",[2.9504883830626034, 42.6546857539096]],
    [ "Enedis",[2.9415230097472715, 42.65439842138563]],
    [ "Enedis",[2.954740960100467, 42.658978490700015]],
    [ "Enedis",[2.961665424544789, 42.65127840288066]],
    [ "Enedis",[2.927001024911604, 42.658129434779816]],
    [ "Enedis",[2.9304463551183377, 42.66380510392585]],
    [ "Enedis",[2.9595754062588906, 42.65796504163111]],
    [ "Enedis",[-3.2268577, 47.37672465]],
    [ "Enedis",[2.9436829874450607, 42.65149263140181]],
    [ "Enedis",[2.929709710361571, 42.65701878825249]],
    [ "Enedis",[2.947780944959647, 42.65453062418823]],
    [ "Enedis",[-3.22750855, 47.34265355000001]],
    [ "Enedis",[-3.221612975078853, 47.375781293082355]],
    [ "Enedis",[-3.220140641976808, 47.37342497036693]],
    [ "Enedis",[1.0278873452809978, 46.96521434789452]],
    [ "Enedis",[1.2307217491860503, 47.20890661707018]],
    [ "Enedis",[1.455247532530852, 43.59089283118089]],
    [ "Enedis",[0.8355978089338392, 47.267768246706005]],
    [ "Enedis",[0.8329173896893604, 47.2650953214257]],
    [ "Enedis",[2.8353346357262534, 42.649369323715774]],
    [ "Lasseube P56",[-0.4762440686043706, 43.219798418737064]],
    [ "Enedis",[-0.48134822957531953, 43.22143986579447]],
    [ "Enedis",[2.8369128222832285, 42.65510941805927]],
    [ "Enedis",[2.8356594000000004, 42.652901299999996]],
    [ "Enedis",[-0.4748546150174097, 43.21640166731832]],
    [ "Enedis",[2.841884290598731, 42.65701259570822]],
    [ "Enedis",[2.827049816780842, 42.6540338225423]],
    [ "Enedis",[2.8443343005552966, 42.659176001056856]],
    [ "Enedis",[2.8382925174341582, 42.65687221370838]],
    [ "Enedis",[2.8336921600134484, 42.64186087471071]],
    [ "Enedis",[2.8375233158305555, 42.64462427448544]],
    [ "Enedis",[3.937572706017405, 50.269212492710885]],
    [ "Enedis",[2.398408954387668, 48.64189827980431]],
    [ "Enedis",[2.4085328385676372, 48.65867162629511]],
    [ "Enedis",[2.40613535, 48.65746480000001]],
    [ "Enedis",[2.423738457783873, 48.64423468621297]],
    [ "Enedis",[0.8544370732387381, 47.27223756514338]],
    [ "Enedis",[2.414372382747535, 48.64139737041642]],
    [ "Enedis",[2.417137077699712, 48.659001712561846]],
    [ "Enedis",[2.4140966411306026, 48.64789991420572]],
    [ "Enedis",[2.3989157327422572, 48.650691243633204]],
    [ "Enedis",[2.399636320379491, 48.647746544986184]],
    [ "Enedis",[2.4137212313947245, 48.640212743071125]],
    [ "Enedis",[2.4052554131457713, 48.65250676532421]],
    [ "Enedis",[2.39090467204053, 48.63947568952323]],
    [ "Enedis",[2.410762736824033, 48.65354677918536]],
    [ "Enedis",[2.396641699999999, 48.64430705]],
    [ "Enedis",[2.4342743, 48.64951225000001]],
    [ "Enedis",[2.420057741515997, 48.6518930518066]],
    [ "Enedis",[2.407928584108243, 48.656581751935015]],
    [ "Enedis",[2.3974840120532246, 48.648741204318284]],
    [ "Enedis",[2.8253258200586515, 42.70233828724042]],
    [ "Enedis",[2.81325553457136, 42.7062148525054]],
    [ "Enedis",[7.0068636878310695, 43.585103658802296]],
    [ "Enedis",[-0.6447518337685532, 43.18884146823871]],
    [ "Enedis",[2.3621223698694416, 48.73961880222613]],
    [ "Enedis",[-0.6234021500000001, 43.1822547]],
    [ "Enedis",[-0.6029972034484509, 43.19386039378455]],
    [ "Enedis",[-0.6031780688932898, 43.19849428508881]],
    [ "Tréville",[-0.6077075540960548, 43.197371777035876]],
    [ "Enedis",[-0.5928732101178363, 43.19438488562079]],
    [ "Enedis",[-0.5936519000000001, 43.19612374999999]],
    [ "Enedis",[-0.6361126830360511, 43.18247735306121]],
    [ "Hospice",[-0.6059229984309631, 43.19806474592675]],
    [ "Enedis",[0.7242034200750952, 49.526652829792255]],
    [ "Enedis",[0.722651299999999, 49.52903265]],
    [ "Enedis",[0.7284951146238673, 49.52508560756445]],
    [ "Enedis",[0.7201491351073018, 49.526879973709384]],
    [ "Enedis",[0.722746099761151, 49.52515549607957]],
    [ "Enedis",[0.719917286326708, 49.535094865399635]],
    [ "Enedis",[1.6762092526099839, 43.78160456766686]],
    [ "Enedis",[1.6969585, 43.770236350000005]],
    [ "Enedis",[1.6803495500000003, 43.7738514]],
    [ "Enedis",[1.672053365613283, 43.7730842291198]],
    [ "Enedis",[1.7021644134369214, 43.76019214565816]],
    [ "Enedis",[1.6945038783636555, 43.77437222226817]],
    [ "Enedis",[1.688088020089637, 43.77383192558089]],
    [ "Enedis",[1.6842968340666524, 43.772982946279484]],
    [ "Enedis",[1.7025593013796998, 43.7729366172802]],
    [ "Enedis",[1.6899788811638305, 43.774721474601584]],
    [ "Enedis",[1.6731037893496283, 43.77769259389156]],
    [ "Enedis",[1.68353543402605, 43.774781109565765]],
    [ "Enedis",[1.6860532967968693, 43.76813538488266]],
    [ "Enedis",[1.6876818137608125, 43.764916631171054]],
    [ "Enedis",[1.6907525143258444, 43.762974768934114]],
    [ "Enedis",[1.6711702755404783, 43.77663281330738]],
    [ "Enedis",[1.6872000642784437, 43.769346277493334]],
    [ "Enedis",[1.6904643, 43.767452250000005]],
    [ "Enedis",[1.6947513162056405, 43.77103219814224]],
    [ "Enedis",[1.6956990711561297, 43.76373392273311]],
    [ "Enedis",[1.6910681815308501, 43.76577611128324]],
    [ "Enedis",[1.6922937971604965, 43.76459189048158]],
    [ "Enedis",[1.6756821288255235, 43.77118758169943]],
    [ "Enedis",[1.6937466000000003, 43.7691593]],
    [ "Enedis",[-1.6907257183593647, 48.149329198834536]],
    [ "Enedis",[1.6766598541981863, 43.77907671948291]],
    [ "Enedis",[-1.686848999999998, 48.1420068]],
    [ "Enedis",[-1.684681015149718, 48.1489039245601]],
    [ "Enedis",[1.6803596801092424, 43.77657457090871]],
    [ "Enedis",[-1.68755193798141, 48.151953659199734]],
    [ "Enedis",[-1.6926447550308759, 48.134677603186354]],
    [ "Enedis",[-1.6864453724335564, 48.14948078491344]],
    [ "Enedis",[-1.6720237500000001, 48.159329700000015]],
    [ "Enedis",[1.6744731928083423, 43.778822122396015]],
    [ "Enedis",[1.7002829152892172, 43.772945320090706]],
    [ "Enedis",[1.6802387387134312, 43.78352983937918]],
    [ "Enedis",[-1.684962353331067, 48.143344579240086]],
    [ "Enedis",[1.6792840135456335, 43.780164345209776]],
    [ "Enedis",[1.677351845460422, 43.77166690059377]],
    [ "63900389",[-1.6874124416451068, 48.13759162606612]],
    [ "Enedis",[-1.684696310137306, 48.13645264884604]],
    [ "Petit Houlbert",[-1.6736183138254441, 48.13809481096767]],
    [ "Enedis",[-1.6893911085874946, 48.13556168153836]],
    [ "Enedis",[-1.684565783065506, 48.15090695026393]],
    [ "Enedis",[-1.69667475, 48.153556449999996]],
    [ "Enedis",[-1.6905540371689087, 48.13843703795644]],
    [ "Enedis",[-1.6537362849068813, 48.14661049815957]],
    [ "Enedis",[-1.6719370022743547, 48.15688643033497]],
    [ "63904019",[-1.6898028033940382, 48.13860423739984]],
    [ "Enedis",[-1.688084968450599, 48.14649902647321]],
    [ "Enedis",[4.6240135508121645, 45.08371271455874]],
    [ "Enedis",[4.630102309918624, 45.08712117526951]],
    [ "Valjean",[2.4346423857545028, 48.80089800666642]],
    [ "Salanson",[2.4290197279903647, 48.79964284586804]],
    [ "GE Petis Marais",[2.3039621809604975, 48.94628298213126]],
    [ "Enedis",[2.29557958071372, 48.94458012335885]],
    [ "Enedis",[2.295472329468174, 48.94439994156033]],
    [ "Louveteau",[2.3080291973551743, 48.946119629378714]],
    [ "Enedis",[2.2986881066304345, 48.93120322236298]],
    [ "Enedis",[2.2950967310245725, 48.946391050946104]],
    [ "Enedis",[2.296118649675002, 48.94481586374157]],
    [ "Bouillaud",[2.3053552201704828, 48.94828359578014]],
    [ "Poste électrique Seguin",[2.2559300478514355, 48.938653983620554]],
    [ "Enedis",[1.4487735892806195, 43.57654427991809]],
    [ "Enedis",[1.4405467198776098, 43.58253753618091]],
    [ "Enedis",[1.4473342645746472, 43.57513202217541]],
    [ "Louvain",[2.433252198774078, 48.79869426399634]],
    [ "Enedis",[2.339247017713782, 48.917039297804834]],
    [ "La Croix",[5.984056629201478, 45.344743078029275]],
    [ "Franc Bat",[2.3673318, 48.92569030000001]],
    [ "Enedis",[1.4559285286108528, 43.556849789592434]],
    [ "Enedis",[1.4534447651457743, 43.55426568992342]],
    [ "Enedis",[1.4472254455966904, 43.54281310466827]],
    [ "Enedis",[1.4556595015301095, 43.55724533963484]],
    [ "Fondeville 8",[1.4564166329101667, 43.54912784890195]],
    [ "Enedis",[1.458930467434756, 43.55416868501437]],
    [ "Enedis",[1.4584650430241228, 43.54462359019389]],
    [ "Enedis",[1.4568847735780168, 43.552178900181154]],
    [ "Haut-Pouvourville 1",[1.4595870127858979, 43.54648119505885]],
    [ "Enedis",[1.4413007576279682, 43.55649840233307]],
    [ "Enedis",[1.4476196680452225, 43.55779831807665]],
    [ "Enedis",[1.4315631890261606, 43.55612212560763]],
    [ "Enedis",[1.4480222999999999, 43.55118650000001]],
    [ "GreenAlp",[6.073443122121245, 45.28011083288786]],
    [ "Enedis",[0.5975453662909105, 47.41091760345012]],
    [ "Enedis",[0.592818477508122, 47.4041700762479]],
    [ "Enedis",[0.6306849878692085, 47.40029291044482]],
    [ "Enedis",[0.5896413064362084, 47.44161408211356]],
    [ "Enedis",[0.609263918471132, 47.40995422318133]],
    [ "Enedis",[0.5939266593917701, 47.40773600894184]],
    [ "Enedis",[0.596085967215845, 47.399097243673864]],
    [ "Enedis",[0.6036460961880802, 47.40884468667489]],
    [ "Enedis",[0.6046821178300773, 47.40173851366187]],
    [ "Enedis",[0.6367819189370414, 47.4027344599933]],
    [ "Enedis",[0.646683953316332, 47.39378235267535]],
    [ "Enedis",[0.5959741633817985, 47.40274473144857]],
    [ "Enedis",[0.5955048497028588, 47.40024964576173]],
    [ "Enedis",[0.6261214530947271, 47.427762210146]],
    [ "Enedis",[0.6050034401098905, 47.39241868388278]],
    [ "Enedis",[0.6162918113281529, 47.397536801577246]],
    [ "Enedis",[0.5998198236295802, 47.39976279488937]],
    [ "Enedis",[0.5878963222536713, 47.40161771264757]],
    [ "Enedis",[0.6226102449443447, 47.40669512157347]],
    [ "Enedis",[0.60673102864398, 47.407854340787736]],
    [ "Enedis",[0.5963396453679628, 47.40479422746854]],
    [ "Enedis",[0.5931265421456277, 47.39669122131167]],
    [ "Enedis",[0.5918837280454664, 47.39888573646635]],
    [ "Enedis",[0.6399754513609955, 47.398578704482574]],
    [ "Enedis",[0.605130042271844, 47.405247549260615]],
    [ "Enedis",[0.6226073730229293, 47.40376158240524]],
    [ "Enedis",[0.5997885604854916, 47.3970555750196]],
    [ "Enedis",[0.6033023043676464, 47.397725601821755]],
    [ "Enedis",[0.6094389225648928, 47.39123104509977]],
    [ "Enedis",[0.5178622431626415, 49.53434583027736]],
    [ "Enedis",[0.5258969923875436, 49.53236902314584]],
    [ "Enedis",[0.5517357859208399, 49.53970291456205]],
    [ "Enedis",[0.519058678059069, 49.53525045710461]],
    [ "Enedis",[0.5313638843700127, 49.52502063312699]],
    [ "Enedis",[0.5284870258529338, 49.51921011933738]],
    [ "Enedis",[0.5309251700479221, 49.522152457717226]],
    [ "Enedis",[0.5310528504238284, 49.52970313331707]],
    [ "Enedis",[0.5439478476971518, 49.52139326780813]],
    [ "Enedis",[0.5350502727887994, 49.47424332124648]],
    [ "Enedis",[0.5343778474292101, 49.517864180374154]],
    [ "Enedis",[0.5499158201193843, 49.5232789053827]],
    [ "Enedis",[0.5438964555842304, 49.516757879486555]],
    [ "Enedis",[0.5348185128369967, 49.51950765157611]],
    [ "Enedis",[3.9770280786896195, 50.266431803760355]],
    [ "Enedis",[4.0038426391425155, 50.27181043033215]],
    [ "Enedis",[4.007869392243668, 50.270779129645796]],
    [ "Enedis",[4.0114038334648505, 50.27209925487425]],
    [ "Enedis",[4.006533127753954, 50.27493586390417]],
    [ "Enedis",[4.012775714801724, 50.27941763736169]],
    [ "Enedis",[4.073811675213014, 44.08703164387512]],
    [ "Poste électrique de Flandre",[2.376830755264039, 48.88965559742678]],
    [ "Poste électrique de Crimée",[2.377772335496054, 48.89017765254581]],
    [ "SRD",[0.23780062407417663, 47.02415796551527]],
    [ "Enedis",[2.778998892902153, 42.67928755383048]],
    [ "Enedis",[2.785785577352841, 42.68072333525304]],
    [ "Enedis",[2.7754700255465043, 42.67765540311714]],
    [ "Enedis",[2.782583473968868, 42.68044246364557]],
    [ "Enedis",[2.7768193145878404, 42.67959526975279]],
    [ "Enedis",[2.7980733091240246, 42.683967088552535]],
    [ "Enedis",[2.7931704916322446, 42.68256236210034]],
    [ "Enedis",[2.8064970270506486, 42.68487311393925]],
    [ "Enedis",[2.8041306414864615, 42.68487381219805]],
    [ "Enedis",[2.7844620537869362, 42.679789701776514]],
    [ "Enedis",[3.9776191906109433, 50.27331966344814]],
    [ "Enedis",[3.979565450872865, 50.27378086901799]],
    [ "Enedis",[3.9795235247656, 50.273059379508034]],
    [ "Enedis",[2.7567113008516975, 42.62600562547096]],
    [ "Enedis",[2.7611099000000006, 42.626622299999994]],
    [ "Enedis",[2.759315276721815, 42.62867510848637]],
    [ "Enedis",[2.7517652701035384, 42.62582731506456]],
    [ "Enedis",[2.7541672870373732, 42.6248354988669]],
    [ "Enedis",[2.748175155856911, 42.62791314889319]],
    [ "Enedis",[2.7638537500000004, 42.632760100000006]],
    [ "Enedis",[2.7557451367377466, 42.63317451833272]],
    [ "Enedis",[2.751437250671338, 42.63612605715464]],
    [ "Enedis",[2.7510553292656157, 42.632027806020695]],
    [ "Enedis",[2.753585273084889, 42.62108121798164]],
    [ "Enedis",[2.755290832357031, 42.62358459934764]],
    [ "Enedis",[3.1289339336048676, 42.48163384658838]],
    [ "Enedis",[3.1352336242209193, 42.478544204751614]],
    [ "Enedis",[3.127579186721992, 42.472890128215774]],
    [ "Enedis",[3.1326870525113404, 42.47654534787078]],
    [ "Enedis",[3.1391890286958826, 42.47875678692387]],
    [ "Enedis",[3.1169655845507966, 42.472332537531486]],
    [ "Enedis",[3.137754521084229, 42.47785315072789]],
    [ "Enedis",[3.12440245, 42.480081950000006]],
    [ "Enedis",[3.1162790222261525, 42.474918239908845]],
    [ "Enedis",[2.6665496055333713, 42.47136668280566]],
    [ "Enedis",[2.664941127952408, 42.47633302934646]],
    [ "Enedis",[2.6630534165681854, 42.4757494172513]],
    [ "Enedis",[1.4200279094166954, 43.596601763544946]],
    [ "Enedis",[1.4220191137973057, 43.60318361380828]],
    [ "Enedis",[1.4207831001583768, 43.59999692672389]],
    [ "Enedis",[1.4243372250092294, 43.5980766769851]],
    [ "Enedis",[3.9634357345449076, 50.28184976098021]],
    [ "Enedis",[3.9744510838155285, 50.28365404598219]],
    [ "Enedis",[-1.9975650292874858, 46.97215176537223]],
    [ "Enedis",[-2.00328903385837, 46.98697648283779]],
    [ "Enedis",[-1.9876596832686655, 46.97848829943563]],
    [ "Enedis",[-2.0202878528039787, 46.97272926682102]],
    [ "Enedis",[-2.0473728999999996, 46.949633049999996]],
    [ "Enedis",[-1.9894785500000003, 46.97480405]],
    [ "Enedis",[-2.0671128294684387, 46.933185144576264]],
    [ "Enedis",[-2.0023185395248926, 46.97497252400809]],
    [ "Enedis",[-2.00425975, 46.97233375000001]],
    [ "Enedis",[-1.9754278026112737, 47.02197726684967]],
    [ "Enedis",[-2.058572699999999, 46.933895150000005]],
    [ "Enedis",[3.92711361982547, 50.26539282001222]],
    [ "Enedis",[3.9286897964664647, 50.26738398352211]],
    [ "Enedis",[1.546580993575016, 43.51844204436299]],
    [ "Enedis",[1.5024811310904993, 43.59755585318084]],
    [ "Cheminot",[1.4954550447519876, 43.60989866914196]],
    [ "Enedis",[1.5061206905386917, 43.59711972567949]],
    [ "Buffon 4",[1.4939322896376097, 43.608279971873635]],
    [ "Enedis",[1.4948531276970005, 43.602263894580105]],
    [ "Enedis",[1.4991526500000014, 43.61449104999999]],
    [ "Enedis",[1.4875473744138106, 43.6249365744228]],
    [ "Enedis",[1.497829604091394, 43.63468212966702]],
    [ "Enedis",[1.4989562408322283, 43.59517014553624]],
    [ "Enedis",[1.4916142264117722, 43.63330260502242]],
    [ "Le Parc Saint-Anne",[1.5061135444493616, 43.59393513255736]],
    [ "Enedis",[1.48810755, 43.62885849999999]],
    [ "Enedis",[1.4880091750637714, 43.62670115372608]],
    [ "Enedis",[1.5135667941156155, 43.611494933195885]],
    [ "Plaine Ariane",[1.493088932626231, 43.604840638374405]],
    [ "Enedis",[1.5046937479630225, 43.60286745782107]],
    [ "Enedis",[1.4957482342522532, 43.61754459721102]],
    [ "Enedis",[1.5044359499999997, 43.60920955]],
    [ "Enedis",[1.5093670960676369, 43.618962525573316]],
    [ "Enedis",[1.5088410000000005, 43.60640225]],
    [ "Enedis",[1.495275881591252, 43.60573894353743]],
    [ "Enedis",[1.4987009479658506, 43.60403096529068]],
    [ "Enedis",[1.5136386499999999, 43.589303550000004]],
    [ "Arènes Forains",[1.4889895173033545, 43.611520583189765]],
    [ "Enedis",[1.4861290151543243, 43.624788570774015]],
    [ "Enedis",[1.519610012618868, 43.616757256321684]],
    [ "Enedis",[1.4904121380273787, 43.61097724140219]],
    [ "Enedis",[1.5126423000000007, 43.603653249999994]],
    [ "Enedis",[1.4941790490715636, 43.613565285839734]],
    [ "Enedis",[1.4843711735890284, 43.62848335154002]],
    [ "Enedis",[1.5101068876370443, 43.598073628916744]],
    [ "Enedis",[1.5068899298872755, 43.60800199346539]],
    [ "Enedis",[1.5084471999999998, 43.61144625]],
    [ "Enedis",[1.49014391632182, 43.626861573469185]],
    [ "Iris",[1.5043023731726324, 43.61171156445846]],
    [ "Enedis",[1.4836895238674521, 43.62754478397323]],
    [ "Bel Horizon",[1.5047973476333805, 43.598428216856874]],
    [ "Enedis",[1.5116384393331124, 43.61267323177356]],
    [ "Truffaut",[1.5001099642141729, 43.63437276929738]],
    [ "Enedis",[1.4949839381734102, 43.612099940221]],
    [ "Enedis",[1.494802240986645, 43.6189452074593]],
    [ "Enedis",[1.511501481583417, 43.61604799192937]],
    [ "Enedis",[1.5011848882883918, 43.5959884535023]],
    [ "Enedis",[1.497223831044971, 43.60802345850533]],
    [ "Mimosa",[1.4957983975651672, 43.608971621921334]],
    [ "Enedis",[1.5180705253865854, 43.61917398974109]],
    [ "Enedis",[1.4870183500411074, 43.62844517288099]],
    [ "Enedis",[1.506916205141921, 43.60588699800491]],
    [ "Enedis",[1.5073811999999989, 43.604426450000005]],
    [ "Enedis",[1.4905660499999989, 43.614614499999995]],
    [ "Enedis",[1.4966157408758956, 43.60668236656642]],
    [ "Enedis",[1.5001234351628738, 43.600890086256136]],
    [ "Enedis",[1.5017110000000002, 43.6076981]],
    [ "Enedis",[1.5049266904742147, 43.61978424896299]],
    [ "Enedis",[1.506680091231017, 43.616289106361435]],
    [ "Enedis",[1.4847209554492946, 43.628919918131615]],
    [ "Enedis",[1.5116504763576075, 43.60520386993441]],
    [ "Enedis",[1.5046196584936795, 43.61286036609813]],
    [ "Enedis",[1.499658749999999, 43.609725450000006]],
    [ "Enedis",[1.5167452159145767, 43.60649781790153]],
    [ "Enedis",[1.5125309908099156, 43.60821087564801]],
    [ "Enedis",[1.501801796663221, 43.6044436664034]],
    [ "Enedis",[1.5152188236160216, 43.61392679366432]],
    [ "Enedis",[1.5072834499999996, 43.62247870000001]],
    [ "Enedis",[1.48926762445303, 43.62848541931082]],
    [ "Enedis",[1.4899629893851802, 43.61435104484148]],
    [ "Enedis",[1.5014418155908427, 43.59903887273619]],
    [ "Enedis",[1.0764800999483133, 49.409814601064085]],
    [ "Enedis",[1.0771223124922857, 49.40897983672675]],
    [ "Enedis",[3.1022099396365004, 45.75795473491722]],
    [ "Enedis",[3.101686556003571, 45.758533420100584]],
    [ "Enedis",[5.949388072136272, 46.136416939480426]],
    [ "Enedis",[5.962025287882494, 46.142754483622575]],
    [ "Enedis",[5.951713560120545, 46.13809682031914]],
    [ "Enedis",[5.961759142787597, 46.14467148226658]],
    [ "Enedis",[3.083537224891846, 45.75255116586824]],
    [ "Enedis",[3.0879703736805877, 45.74903118832692]],
    [ "Enedis",[3.077682514304891, 45.74805912583202]],
    [ "Enedis",[3.1040463877616187, 45.74430198156557]],
    [ "Enedis",[3.08074115185039, 45.75451643825364]],
    [ "Enedis",[3.0823916044493163, 45.755510965283264]],
    [ "Enedis",[3.0752288084477915, 45.75442615207023]],
    [ "Enedis",[3.0958246832187384, 45.750214383617184]],
    [ "Enedis",[3.0873588999999995, 45.745278600000006]],
    [ "Enedis",[3.0775774901755892, 45.7540401436056]],
    [ "Enedis",[3.0848050993448073, 45.754873352007756]],
    [ "Enedis",[3.090466294159331, 45.7532906468621]],
    [ "Enedis",[3.074460728569456, 45.74613393932239]],
    [ "Enedis",[3.085751270891226, 45.749032934766]],
    [ "Enedis",[3.08575095, 45.74446389999999]],
    [ "Enedis",[3.096414701903153, 45.74490029870004]],
    [ "Enedis",[3.0974328338479147, 45.75110276683818]],
    [ "Enedis",[3.0819914582777246, 45.746274770787]],
    [ "Enedis",[3.0821841179445997, 45.74843243820366]],
    [ "Enedis",[3.074870963438523, 45.75223316539239]],
    [ "Enedis",[3.0759421895198744, 45.745132163914015]],
    [ "Enedis",[3.089434481202281, 45.75049491807815]],
    [ "Enedis",[3.0909564813304744, 45.744668861604595]],
    [ "Enedis",[3.0698881918989454, 45.750408053502454]],
    [ "Enedis",[3.07645863491353, 45.75241777006025]],
    [ "Enedis",[3.087993523020198, 45.75959507809578]],
    [ "Enedis",[3.0753010992184433, 45.750657162877296]],
    [ "Enedis",[3.0895357751528367, 45.745061309910426]],
    [ "Enedis",[3.0770814692765485, 45.74561738056408]],
    [ "Enedis",[3.091164288257737, 45.74925537036879]],
    [ "Enedis",[3.101001611697215, 45.72671532641271]],
    [ "Enedis",[3.10347265361095, 45.73745581199332]],
    [ "Enedis",[3.097454019449901, 45.743514058642624]],
    [ "Enedis",[3.1003278527710845, 45.73128720308434]],
    [ "Enedis",[3.106731988991969, 45.7336947454325]],
    [ "Enedis",[3.080251736422708, 45.72229314968236]],
    [ "Enedis",[3.0934601577298317, 45.726542955269494]],
    [ "Enedis",[3.1066605305859265, 45.73491331779273]],
    [ "Enedis",[3.0972024747204916, 45.73597184802352]],
    [ "Enedis",[3.0873147611173035, 45.72607410383605]],
    [ "Enedis",[3.096168525039106, 45.72298800450679]],
    [ "Enedis",[3.0878292091988127, 45.705954774218576]],
    [ "Enedis",[3.1068758163825976, 45.740501051535134]],
    [ "Enedis",[3.0892474195056083, 45.72769318172096]],
    [ "Enedis",[3.109940385665085, 45.73925840506414]],
    [ "Enedis",[3.098869940412564, 45.73800481944624]],
    [ "Enedis",[3.100631532257102, 45.73648376753376]],
    [ "Enedis",[3.0953884459579792, 45.7435196565311]],
    [ "Enedis",[3.0690299616499153, 45.71372784692782]],
    [ "Enedis",[3.0801707093011585, 45.701811283347254]],
    [ "Enedis",[3.0954219000000003, 45.73219385]],
    [ "Enedis",[3.0990060988422052, 45.74095638371633]],
    [ "Enedis",[3.097718904318183, 45.723655804443126]],
    [ "Enedis",[3.1025394900408694, 45.74057533409269]],
    [ "Enedis",[3.099150171135448, 45.74318966525276]],
    [ "Enedis",[3.0679234500000003, 45.70989274999999]],
    [ "Enedis",[3.0904078740401704, 45.725386596550244]],
    [ "Enedis",[3.0959810760843944, 45.72755888158888]],
    [ "Enedis",[3.1092522760733816, 45.73668500571613]],
    [ "Enedis",[3.101921502195097, 45.741793067890725]],
    [ "Enedis",[3.0996972581469175, 45.73297873499464]],
    [ "Enedis",[3.1049205893530476, 45.73534053862933]],
    [ "Enedis",[3.0997349586327734, 45.742442851428784]],
    [ "Enedis",[3.0908026450170563, 45.730486963597066]],
    [ "Enedis",[3.086316148162622, 45.707225864752836]],
    [ "Enedis",[2.184055534817504, 48.80475701308763]],
    [ "Enedis",[2.191060884415442, 48.80005400714449]],
    [ "SRD",[0.6564564309299975, 46.119725813311355]],
    [ "SRD",[0.6592030833659085, 46.12066945025977]],
    [ "Enedis",[1.5606804691851253, 43.647535863889345]],
    [ "Enedis",[1.5572828838469792, 43.64484009627891]],
    [ "Enedis",[1.5590493701446622, 43.64738141351982]],
    [ "Enedis",[1.5593770121027777, 43.65030811850471]],
    [ "Enedis",[1.5580304218720253, 43.64791139414912]],
    [ "Enedis",[1.5597620047741154, 43.644262150682465]],
    [ "Enedis",[1.3679976891823868, 43.67047600492251]],
    [ "Enedis",[1.3821486743224274, 43.66165389854541]],
    [ "Enedis",[1.3866501915028637, 43.66288662749273]],
    [ "Enedis",[1.3820823252669037, 43.66584996045868]],
    [ "Enedis",[1.3830643562524307, 43.663333034376315]],
    [ "Enedis",[1.3783955174646827, 43.66464075804155]],
    [ "Enedis",[1.3726601, 43.6626668]],
    [ "Enedis",[1.3743117176258957, 43.671825513429]],
    [ "Enedis",[1.3843197934133462, 43.66556776867802]],
    [ "Enedis",[1.3633755111740367, 43.664021879510635]],
    [ "Enedis",[1.3700575836077267, 43.66396085025597]],
    [ "Enedis",[1.360012327684069, 43.6647818525345]],
    [ "Enedis",[1.3667332616735381, 43.667597924001186]],
    [ "Enedis",[1.3770925831037437, 43.66303967040828]],
    [ "Enedis",[1.3742549631196153, 43.66464388041744]],
    [ "Enedis",[1.3757118969457316, 43.66088989160184]],
    [ "Enedis",[1.3722761499999998, 43.666753199999995]],
    [ "Enedis",[1.3738945584625306, 43.67005629353284]],
    [ "Enedis",[1.3719761509137687, 43.6686474244715]],
    [ "Enedis",[1.3767952500000007, 43.66643315]],
    [ "Enedis",[1.37729043835812, 43.65704671946857]],
    [ "Enedis",[1.3792013185834975, 43.66324478427889]],
    [ "Enedis",[1.370525315049612, 43.67193524539843]],
    [ "Enedis",[1.37898675, 43.6609289]],
    [ "Enedis",[1.3788095334072428, 43.65877264996304]],
    [ "Enedis",[1.3864786577923043, 43.66161914027599]],
    [ "Enedis",[1.366153445499033, 43.66900016786207]],
    [ "Enedis",[1.3676562869738265, 43.666066361578146]],
    [ "Enedis",[1.3641776062155972, 43.667509539125135]],
    [ "Enedis",[1.3750447847689127, 43.662979866096904]],
    [ "Enedis",[1.0958360583543763, 49.44476751659416]],
    [ "Enedis",[1.0962185499999997, 49.44574855]],
    [ "Enedis",[1.5696421848779265, 43.50941123130375]],
    [ "Enedis",[1.5658761322636723, 43.50912556517689]],
    [ "Enedis",[-2.1733107416405018, 47.15697537946277]],
    [ "Enedis",[-2.182410993310143, 47.13501764768301]],
    [ "Lavoir",[4.099894250372428, 45.259269558194845]],
    [ "Poste Bourg",[4.098568649999999, 45.26058515]],
    [ "Le Verdoyer",[4.099913475419113, 45.258262604629394]],
    [ "La Charreyre",[4.09408215, 45.2544631]],
    [ "Blanchard",[4.097185999999999, 45.25651815]],
    [ "Enedis",[0.4810717913457752, 49.55918862971099]],
    [ "Enedis",[0.4936428718693434, 49.561759374024504]],
    [ "Champs Collet",[6.355716689269484, 46.156916045468456]],
    [ "Enedis",[-0.21574169295370102, 46.97565884019097]],
    [ "Enedis",[-0.2161171242338594, 46.97748412762068]],
    [ "Enedis",[6.487399602200062, 44.869244117310565]],
    [ "Enedis",[1.0822874212702072, 49.40249420052037]],
    [ "Enedis",[-0.2160180342040307, 46.97649595922264]],
    [ "Enedis",[-0.1954775026914923, 46.98996902558045]],
    [ "Enedis",[6.492107882254923, 44.85665353424877]],
    [ "Enedis",[3.1555688993071302, 42.459881618577796]],
    [ "Enedis",[3.1663545374453137, 42.439773896699165]],
    [ "Enedis",[3.1630556244508745, 42.447125052011664]],
    [ "Enedis",[-2.2191714179724547, 47.12937770262935]],
    [ "Enedis",[3.1622654940260437, 42.45627503098421]],
    [ "Enedis",[-2.2084170178154077, 47.126863453237355]],
    [ "Enedis",[-2.2210485818990673, 47.129571870616175]],
    [ "Enedis",[-2.2044405408632755, 47.12876225640046]],
    [ "Enedis",[3.104247994505205, 42.518327345424275]],
    [ "Enedis",[3.1037260738751145, 42.5158534270202]],
    [ "Enedis",[3.1011382068992868, 42.52359211321703]],
    [ "Enedis",[3.103304918500561, 42.52086477048583]],
    [ "Enedis",[3.1017269769670324, 42.522362091143535]],
    [ "Enedis",[3.0994597752851325, 42.518905395468316]],
    [ "Enedis",[3.104269080887058, 42.52226999948032]],
    [ "Enedis",[3.1048796948998683, 42.515898883211676]],
    [ "Enedis",[3.1040244, 42.52148825661914]],
    [ "Enedis",[3.108289897058938, 42.52079290254025]],
    [ "Enedis",[3.1063269236284223, 42.519742843427444]],
    [ "Enedis",[3.100496112815943, 42.51828477952096]],
    [ "Enedis",[3.1031261518253834, 42.51795564506773]],
    [ "Enedis",[1.7853944934118746, 45.28204603215408]],
    [ "Enedis",[-2.1609929271861494, 47.163761839931844]],
    [ "Enedis",[-2.1570851471959274, 47.161697477653796]],
    [ "Enedis",[3.9362019237924453, 50.283971079233304]],
    [ "Enedis",[3.936928306879722, 50.2856522701847]],
    [ "Enedis",[-2.1586929859731874, 47.15877181037274]],
    [ "Enedis",[3.933903408689552, 50.283548966369665]],
    [ "Enedis",[-2.1463485348102096, 47.1698676193692]],
    [ "Enedis",[-2.154220064695789, 47.163506474908516]],
    [ "Enedis",[-2.1594352470368636, 47.16087512118527]],
    [ "Enedis",[1.7118921202829354, 45.38722349808008]],
    [ "Enedis",[3.9410128309635626, 50.28470472737993]],
    [ "Enedis",[3.9426070422924577, 50.28293728100457]],
    [ "Enedis",[3.9455182869174865, 50.283828982715896]],
    [ "Enedis",[3.940836549313366, 50.28241883987372]],
    [ "Enedis",[2.9164685338662544, 42.62964855636927]],
    [ "Enedis",[2.9195868719226965, 42.63067340944067]],
    [ "Enedis",[2.917620910755527, 42.63123042768808]],
    [ "Enedis",[2.9099505342871548, 42.6390971808777]],
    [ "Enedis",[2.9120034907789853, 42.6405309247645]],
    [ "Enedis",[2.921748749683119, 42.62501844543457]],
    [ "Enedis",[2.9155602185354024, 42.63828627866783]],
    [ "Enedis",[2.9192245169390225, 42.636920912723596]],
    [ "Enedis",[2.9149459160112707, 42.636275815938326]],
    [ "Enedis",[2.9215927450852495, 42.6388260683621]],
    [ "Enedis",[2.914628590317977, 42.62982335157314]],
    [ "Enedis",[2.919793975173103, 42.63329163419474]],
    [ "Enedis",[2.913685764652729, 42.63798871931246]],
    [ "Enedis",[2.9110511077193015, 42.637816965111654]],
    [ "Enedis",[2.921567035717711, 42.636198032074084]],
    [ "Enedis",[2.9189475095827744, 42.63915019087272]],
    [ "Enedis",[2.9206478421165833, 42.63257257835534]],
    [ "Enedis",[2.917080870149084, 42.62771934882998]],
    [ "Enedis",[2.8981298557400903, 42.65518159133092]],
    [ "Enedis",[2.924279753442058, 42.629903957458914]],
    [ "Enedis",[2.9025257079856166, 42.564030854341645]],
    [ "Enedis",[2.900082935750443, 42.56426401030119]],
    [ "Enedis",[2.9061704372491866, 42.57556189354075]],
    [ "Enedis",[2.9034899999999997, 42.565171850000006]],
    [ "Enedis",[2.8997099281006817, 42.56986076755756]],
    [ "Enedis",[2.902645955566085, 42.56673981955595]],
    [ "Enedis",[1.567966849122119, 43.80870091571396]],
    [ "Enedis",[3.9532531967585696, 50.28344292053036]],
    [ "Enedis",[1.5684649672356317, 43.80788832584345]],
    [ "Enedis",[3.9554627346476883, 50.28571004181016]],
    [ "Enedis",[3.959139876168385, 50.283333255913846]],
    [ "Enedis",[3.958025239970457, 50.28403635544187]],
    [ "Enedis",[3.958711953516901, 50.28661736208708]],
    [ "Enedis",[3.9564497050778233, 50.28614265895961]],
    [ "Enedis",[-0.37211519389896586, 45.21106433392507]],
    [ "Enedis",[0.4699897585617072, 49.5725323517041]],
    [ "Enedis",[0.4808799, 49.576454100000014]],
    [ "Enedis",[0.48341212688420654, 49.58166042044494]],
    [ "Enedis",[0.4809430320679619, 49.58525306444631]],
    [ "Enedis",[0.4724655924185576, 49.57321180434682]],
    [ "Enedis",[0.48889245146675103, 49.59120467999526]],
    [ "Enedis",[0.4725814238828751, 49.57124046583966]],
    [ "Enedis",[0.47130656360879053, 49.57211370970565]],
    [ "Enedis",[0.4770638717352172, 49.57371178641292]],
    [ "Enedis",[0.4848238602386152, 49.58863331473404]],
    [ "Enedis",[0.4849691419622223, 49.592748404774056]],
    [ "Enedis",[0.4721101139507308, 49.570184805562604]],
    [ "Enedis",[0.47775498202957023, 49.57473644666425]],
    [ "Enedis",[0.4830548651262198, 49.57089687708384]],
    [ "Enedis",[2.2612562956837046, 49.84742495604215]],
    [ "Enedis",[2.271847968081916, 49.84819188016706]],
    [ "Enedis",[2.269775867644931, 49.84504470202044]],
    [ "Enedis",[2.264044330273996, 49.848605310816005]],
    [ "Enedis",[2.2808411104998028, 49.874048641490724]],
    [ "Enedis",[2.2743158945764566, 49.874185660389465]],
    [ "Enedis",[2.2744109550411595, 49.856149789121694]],
    [ "Enedis",[2.269267309420198, 49.84281988565551]],
    [ "Enedis",[2.2665133567329336, 49.844550950825486]],
    [ "Enedis",[3.9557337525582033, 50.28753113320972]],
    [ "Enedis",[3.9629357972610366, 50.2875411392707]],
    [ "Enedis",[3.9576912456026507, 50.28883797997455]],
    [ "Enedis",[3.9602026173887457, 50.28920236767218]],
    [ "Enedis",[3.9599385072184066, 50.291344596846045]],
    [ "Enedis",[3.965092950000001, 50.289704699999994]],
    [ "Enedis",[3.961746799320701, 50.29283765209288]],
    [ "Enedis",[2.2311334992469005, 49.85345775762445]],
    [ "Enedis",[2.2289911386403607, 49.85836481543382]],
    [ "Enedis",[2.242375243903084, 49.85820218582236]],
    [ "Enedis",[2.2263407962865114, 49.8596063775625]],
    [ "Enedis",[2.2286908731445654, 49.86112363641789]],
    [ "Enedis",[2.2376623918371816, 49.85658974511093]],
    [ "Enedis",[3.9704600216325194, 50.29294197942576]],
    [ "Enedis",[3.966903416162733, 50.29314659162427]],
    [ "64333442",[-0.26211628324482994, 46.640247961003915]],
    [ "Enedis",[2.2529003508693197, 49.86934275594635]],
    [ "Enedis",[2.234660121379809, 49.86799056945211]],
    [ "Enedis",[2.2660389499999987, 49.87324180000002]],
    [ "Enedis",[2.2485225933115047, 49.867450935633535]],
    [ "Enedis",[2.258350271490323, 49.86752768008765]],
    [ "Enedis",[2.255517035006085, 49.864959315075076]],
    [ "Enedis",[-1.42880715, 46.783070849999994]],
    [ "Enedis",[-1.4323638500000004, 46.778136399999994]],
    [ "Enedis",[-1.4287102883097589, 46.74753505964886]],
    [ "Enedis",[-1.4260162981870204, 46.78414839449432]],
    [ "Enedis",[-1.422497, 46.78229005]],
    [ "Enedis",[-1.4190005339595864, 46.77280558375221]],
    [ "Enedis",[-1.4325402541890364, 46.785116717053995]],
    [ "Enedis",[-1.428886200000001, 46.785204799999995]],
    [ "Enedis",[-1.4344395235123721, 46.78255637390654]],
    [ "Enedis",[-1.4252165268822217, 46.77872599426223]],
    [ "Enedis",[-1.4281683000000003, 46.7734802]],
    [ "Enedis",[-1.4340441110068352, 46.787974521342655]],
    [ "Enedis",[2.5766683413854827, 47.09795048834586]],
    [ "Enedis",[2.6468987812067586, 47.035975307166126]],
    [ "Enedis",[2.644867659259465, 47.03861916234959]],
    [ "Enedis",[2.6503789473860446, 47.038403807054]],
    [ "Enedis",[1.1076202609671708, 49.438884763463726]],
    [ "Enedis",[1.1113120285768214, 49.43985914545628]],
    [ "Enedis",[1.3959278522550544, 43.6365745622084]],
    [ "Enedis",[1.3893402870682723, 43.619215169156625]],
    [ "Pamir",[2.351508078317152, 48.78842067831716]],
    [ "Enedis",[1.4003213695914865, 43.634713759708085]],
    [ "Enedis",[1.3868277559434063, 43.636925201242725]],
    [ "Enedis",[1.37848065, 43.63347030000001]],
    [ "Enedis",[1.3795518423117867, 43.6293565822563]],
    [ "Enedis",[1.3808261024904513, 43.62722183186574]],
    [ "Enedis",[1.3971139269058044, 43.637593582771615]],
    [ "Enedis",[1.3959439967220024, 43.623305218226044]],
    [ "Enedis",[1.379183278284314, 43.648058137183085]],
    [ "Enedis",[1.3938358996737217, 43.639766572949846]],
    [ "Enedis",[1.3937048856343268, 43.63480319255671]],
    [ "Enedis",[1.3759664066793424, 43.64118862903452]],
    [ "Enedis",[1.3801126108175286, 43.64036350788969]],
    [ "Enedis",[1.3801754, 43.6430565]],
    [ "Enedis",[1.3824484198719214, 43.64634451709611]],
    [ "Enedis",[1.3843739533659922, 43.62935824783482]],
    [ "Enedis",[1.3739673074726233, 43.64811452036485]],
    [ "Enedis",[1.3873688999999996, 43.6511772]],
    [ "Enedis",[1.3781891518634894, 43.650285766247706]],
    [ "Enedis",[1.3677073782992053, 43.64010743698823]],
    [ "Enedis",[1.3848790360356846, 43.63585390050221]],
    [ "Enedis",[1.376161667136035, 43.644987049496315]],
    [ "Enedis",[1.392421410681588, 43.62869912839511]],
    [ "Enedis",[1.3704865000000002, 43.640809899999994]],
    [ "Enedis",[1.3919971246886325, 43.63860020557781]],
    [ "Enedis",[1.3766634188327445, 43.63474095412145]],
    [ "Enedis",[1.3648004747725806, 43.64690317620626]],
    [ "Chapelle",[2.362455658872215, 48.80258610668853]],
    [ "Enedis",[1.3772562337188146, 43.630559774119135]],
    [ "Enedis",[1.3868650500000008, 43.638295199999995]],
    [ "Vil Gaité",[2.3496961086813517, 48.78584954576655]],
    [ "Enedis",[1.396460378581182, 43.62675223804532]],
    [ "Enedis",[1.3687769898413242, 43.641182223328705]],
    [ "Enedis",[1.3986228821148137, 43.624529601763484]],
    [ "Enedis",[1.3915847846601224, 43.633450505581834]],
    [ "Enedis",[1.372054224463319, 43.635777279934906]],
    [ "Enedis",[1.3664536860218863, 43.6441356845418]],
    [ "Les nauzes",[1.3821809141871821, 43.63267447052568]],
    [ "Enedis",[1.391671744107705, 43.647844630776696]],
    [ "Enedis",[0.5865471107314956, 49.46252945964152]],
    [ "Enedis",[1.3711827725982084, 43.63840088614865]],
    [ "Enedis",[1.400688501993874, 43.65008075607071]],
    [ "Enedis",[1.3786544924960549, 43.64362014984418]],
    [ "Enedis",[1.3841619747949927, 43.63066693241114]],
    [ "Enedis",[1.393630734576356, 43.642425122363456]],
    [ "Enedis",[1.38680065, 43.62962640000001]],
    [ "Enedis",[1.3683141947432647, 43.64252536937013]],
    [ "Enedis",[1.3784965259571968, 43.631454688968184]],
    [ "RTE",[1.3630527239228918, 43.65165933465115]],
    [ "Enedis",[1.376313017209056, 43.64662097090396]],
    [ "Enedis",[1.3937757183365282, 43.63971789277789]],
    [ "Enedis",[1.3875103850554287, 43.62790900731549]],
    [ "Enedis",[1.3948503505126266, 43.63321617648042]],
    [ "Enedis",[1.3970453476733542, 43.62613191853614]],
    [ "Enedis",[1.3744158330519292, 43.63644887308268]],
    [ "Enedis",[1.3817623663079786, 43.63635191862091]],
    [ "Enedis",[1.3934108698102832, 43.62618035390756]],
    [ "Enedis",[1.3871060514410507, 43.64686837083996]],
    [ "Saoulous",[1.3948381692702756, 43.64252820826647]],
    [ "Enedis",[1.3876827379020427, 43.64361799307263]],
    [ "Michelet",[1.3974745497568575, 43.64205177385186]],
    [ "Enedis",[1.3677072999999997, 43.647732950000005]],
    [ "Enedis",[1.4003923618703038, 43.63602047749374]],
    [ "Enedis",[1.388838149999999, 43.63726245]],
    [ "Enedis",[1.398156455453248, 43.62235123282576]],
    [ "Enedis",[1.3789631251434828, 43.63875558568153]],
    [ "Enedis",[1.385148574482854, 43.627894846810584]],
    [ "Enedis",[1.3799434504199657, 43.63638962668445]],
    [ "Enedis",[2.3522861227710354, 48.79347652211229]],
    [ "Enedis",[1.3927812370043653, 43.63714276921152]],
    [ "Enedis",[1.3887525500000004, 43.64021835]],
    [ "Enedis",[1.3893130604468422, 43.64530277893946]],
    [ "Enedis",[1.3920697328773455, 43.62174985290693]],
    [ "Enedis",[1.385240494947978, 43.64173383847109]],
    [ "Enedis",[1.3809977386549834, 43.62577232596181]],
    [ "Enedis",[1.3974714019372754, 43.631073895862514]],
    [ "Enedis",[1.3836339654662504, 43.62070502516962]],
    [ "Enedis",[1.3838471995619883, 43.65024858400565]],
    [ "Enedis",[1.3693958253320193, 43.639807750380555]],
    [ "Enedis",[1.3814781000000003, 43.62309794999999]],
    [ "Enedis",[2.34812298452289, 48.797503792461306]],
    [ "Enedis",[1.385084145753175, 43.62785635701975]],
    [ "Enedis",[1.3746590030682493, 43.639995298544086]],
    [ "Enedis",[1.3941339654758889, 43.64030161349331]],
    [ "Enedis",[1.3817393577640937, 43.649239375133]],
    [ "Enedis",[1.39586075, 43.6396533]],
    [ "Enedis",[1.3798193795073628, 43.63272875966265]],
    [ "Enedis",[1.0915559057710709, 49.42434708114473]],
    [ "Enedis",[2.561912103834548, 47.04869312065941]],
    [ "Enedis",[0.2978365294828554, 46.15803105160204]],
    [ "Enedis",[0.2967661270664774, 46.147919781003786]],
    [ "Enedis",[0.29672752280400594, 46.159598050386265]],
    [ "Enedis",[0.2957299869873334, 46.144087434231274]],
    [ "Enedis",[0.2908194911994292, 46.13930311843605]],
    [ "SRD",[0.2864237051583224, 46.148256396163156]],
    [ "Enedis",[0.2921263444015913, 46.14778722183664]],
    [ "Enedis",[0.2920052654002002, 46.14536738543159]],
    [ "Enedis",[0.29575759385673267, 46.138909371243734]],
    [ "Enedis",[0.29610434447297324, 46.150690217252254]],
    [ "Enedis",[0.29535827974287765, 46.1411435860151]],
    [ "Enedis",[1.2305896566881702, 43.6162191260847]],
    [ "Enedis",[1.2470525844681026, 43.61197677141476]],
    [ "Enedis",[1.2331734479040577, 43.614867933580435]],
    [ "Enedis",[1.247804880537048, 43.609211549742234]],
    [ "Enedis",[1.23310805, 43.6130155]],
    [ "Enedis",[1.109648829238771, 49.43148995068277]],
    [ "Enedis",[1.1057979462648815, 49.43384630122652]],
    [ "Enedis",[1.1158687616717526, 49.43522190365775]],
    [ "Enedis",[0.08903178413972916, 47.00777542268051]],
    [ "Enedis",[0.08064448532595626, 47.006947580737936]],
    [ "Enedis",[0.07665988218164116, 47.005161717361815]],
    [ "Enedis",[0.07752821396024556, 47.00699971065986]],
    [ "Enedis",[3.9689555547552877, 50.290119986661416]],
    [ "Enedis",[3.9725413520107575, 50.290609525920665]],
    [ "Enedis",[3.9763664431517776, 50.2906712886864]],
    [ "Enedis",[3.9708027128564254, 50.29002831251366]],
    [ "Enedis",[0.07798981564788666, 47.011168787226694]],
    [ "Enedis",[0.07661865827174533, 47.012696581805145]],
    [ "Enedis",[3.9670616085852886, 50.29157672686871]],
    [ "Enedis",[3.976594997549308, 50.28677561198368]],
    [ "Enedis",[1.4587059582062138, 43.58438549813291]],
    [ "Enedis",[1.4524339999999998, 43.57826444999999]],
    [ "Enedis",[1.4562146674982674, 43.58517503333333]],
    [ "Enedis",[1.449641001326782, 43.58041427460314]],
    [ "Enedis",[1.4528914319773782, 43.5821659434001]],
    [ "Enedis",[1.4588698581284314, 43.580046195913916]],
    [ "Enedis",[3.984635470703003, 50.287257867984245]],
    [ "Enedis",[3.9805325049082843, 50.28517852587773]],
    [ "Enedis",[3.9903209445523307, 50.28785882754144]],
    [ "Enedis",[3.987821506597511, 50.285901869556994]],
    [ "Enedis",[-3.1223420999999996, 47.50585709999999]],
    [ "Enedis",[-3.1336971, 47.520113699999996]],
    [ "Enedis",[-3.13172595, 47.514854549999995]],
    [ "Enedis",[-3.1408693672758217, 47.52968109874935]],
    [ "Enedis",[-3.1360766760390786, 47.536203302922935]],
    [ "Enedis",[-0.1317964852520451, 46.82134660478209]],
    [ "Carré des Antilles",[-0.4187904499999999, 45.43737265]],
    [ "Enedis",[1.3958584125571913, 43.688164012896664]],
    [ "Enedis",[1.382617761458554, 43.69976063702953]],
    [ "Enedis",[1.3842569166824679, 43.68316360116931]],
    [ "Enedis",[1.3770632086581622, 43.68843414548574]],
    [ "Enedis",[1.3839508872142945, 43.698493367989336]],
    [ "Enedis",[1.401080884838572, 43.679751203498796]],
    [ "Enedis",[1.3962995121437094, 43.681806256002666]],
    [ "Enedis",[1.4002339720966317, 43.682651089274145]],
    [ "Enedis",[1.3909193181308184, 43.69330740801086]],
    [ "Enedis",[1.3970721999999995, 43.6794544]],
    [ "Enedis",[1.3942520203793758, 43.6850969234453]],
    [ "Enedis",[1.0854056818678028, 49.41280382594597]],
    [ "Enedis",[1.3980557983783966, 43.68592635780516]],
    [ "Enedis",[1.3880810893093796, 43.68897836244288]],
    [ "Enedis",[1.404390850757287, 43.668458866723505]],
    [ "Enedis",[1.0854049134359145, 49.41111546740446]],
    [ "Enedis",[1.4043925057556281, 43.68580471246716]],
    [ "Enedis",[1.3977099474854318, 43.68063630838987]],
    [ "Enedis",[1.380899799831832, 43.685710716714766]],
    [ "Enedis",[1.3933213003672587, 43.67578502170118]],
    [ "Enedis",[1.3939333309533748, 43.682921076293766]],
    [ "Sibadies",[1.3839217972374904, 43.687288236142074]],
    [ "Enedis",[1.391596373659455, 43.680220825572654]],
    [ "Enedis",[1.4081944712544612, 43.66810010990765]],
    [ "Enedis",[1.3923081020266124, 43.683715728829746]],
    [ "Enedis",[1.3976529824971924, 43.677584697248825]],
    [ "Enedis",[1.3816929390582573, 43.68003755810723]],
    [ "Pal",[1.8076992888977044, 43.850615172298724]],
    [ "Enedis",[1.4212912665646023, 43.72294617348945]],
    [ "Enedis",[1.3981381999999984, 43.72098495]],
    [ "Enedis",[1.3974930395913947, 43.72446926753931]],
    [ "Enedis",[1.395902101534635, 43.726588507983855]],
    [ "Enedis",[-1.2331573214744247, 43.1669674565713]],
    [ "Enedis",[-1.2368502968135042, 43.16855490405269]],
    [ "Enedis",[2.40962280961572, 47.08422426829807]],
    [ "Enedis",[2.403793053725375, 47.083752542977294]],
    [ "Enedis",[2.3896432351240375, 47.08113927916668]],
    [ "Enedis",[2.3821639986646614, 47.07637373853477]],
    [ "Enedis",[2.3941928858360417, 47.08783011531868]],
    [ "Enedis",[2.400311407829231, 47.07581808088431]],
    [ "Enedis",[2.385538671043192, 47.077007268565715]],
    [ "Enedis",[2.3868792716645744, 47.08082604172587]],
    [ "Enedis",[2.405418390138794, 47.07867252260254]],
    [ "Enedis",[2.402542926903327, 47.08192442636852]],
    [ "Enedis",[2.2527881553886657, 48.98032757960189]],
    [ "Enedis",[2.4092279000000003, 47.07828605]],
    [ "Enedis",[2.39691901888994, 47.08455210848312]],
    [ "Enedis",[2.3858251760031495, 47.08661225422232]],
    [ "Enedis",[2.3813362, 47.0837325]],
    [ "Enedis",[2.4023384292725494, 47.09328758519288]],
    [ "Enedis",[2.3897131918209142, 47.08385960074355]],
    [ "Enedis",[2.388217045116892, 47.084612554718376]],
    [ "Enedis",[2.4003879499999994, 47.0916705]],
    [ "Enedis",[2.3930500970251525, 47.090857007372485]],
    [ "Enedis",[2.3986052499999997, 47.09022355]],
    [ "Enedis",[2.38105301786065, 47.07986610329682]],
    [ "Enedis",[2.3997332784028136, 47.07900977606132]],
    [ "Enedis",[2.3884737067767023, 47.07963705588497]],
    [ "Enedis",[2.81218107282665, 42.49196569054729]],
    [ "Enedis",[2.8021856729779264, 42.49265326028614]],
    [ "Enedis",[2.8005314402212784, 42.49156386602227]],
    [ "Enedis",[2.9226883333059215, 42.53895577923519]],
    [ "Enedis",[2.905985168092185, 42.55829715247259]],
    [ "Enedis",[2.9234909112143517, 42.53732312382551]],
    [ "Enedis",[2.9266718429841103, 42.54339691107134]],
    [ "Enedis",[2.925399531831711, 42.54011368808907]],
    [ "Enedis",[2.9242731748845183, 42.54132956812851]],
    [ "Enedis",[2.917828246931581, 42.54127911196295]],
    [ "Enedis",[2.9263124454142178, 42.53721470823886]],
    [ "Enedis",[2.918614529471057, 42.546626235210695]],
    [ "Enedis",[2.9213350932193367, 42.551758465658224]],
    [ "Enedis",[2.9204063500685877, 42.53654841865569]],
    [ "Enedis",[2.9163067, 42.5456086]],
    [ "Enedis",[2.919611419717755, 42.54164182520751]],
    [ "Enedis",[2.924675815210133, 42.53491862264774]],
    [ "Enedis",[2.9349413499458956, 42.565760950924926]],
    [ "Enedis",[2.916568505848522, 42.550576374359785]],
    [ "Enedis",[2.919846082080737, 42.54513570110576]],
    [ "Enedis",[1.4649918708132794, 47.901449906612626]],
    [ "Enedis",[3.0462768328667966, 50.685810141369075]],
    [ "Poste électrique de Perret",[2.283671994368513, 48.89737077164669]],
    [ "Enedis",[1.4384270560581498, 43.61292996982919]],
    [ "Enedis",[1.4400626162118149, 43.614632064392076]],
    [ "Enedis",[1.0867395166486944, 49.40196704076153]],
    [ "Enedis",[2.9373314057168676, 42.52205279412876]],
    [ "Enedis",[2.92955228329711, 42.527899265742484]],
    [ "Enedis",[2.926084449433962, 42.52389628830189]],
    [ "Enedis",[2.926002448830985, 42.52151265217942]],
    [ "Enedis",[2.932719839407995, 42.521423253083356]],
    [ "Enedis",[2.9394942977611316, 42.526203394695344]],
    [ "Enedis",[2.929116876909452, 42.520056670686586]],
    [ "Enedis",[2.938559539074396, 42.52414973096628]],
    [ "Enedis",[2.9334554466065166, 42.52842912575203]],
    [ "Enedis",[2.9238853799492284, 42.518881136536066]],
    [ "Enedis",[2.941841720881247, 42.5253123378339]],
    [ "SRD",[0.11788763538881518, 47.038068315092254]],
    [ "SRD",[0.057308487399213695, 46.71954938118655]],
    [ "SRD",[0.03636776545573047, 46.75193013906402]],
    [ "SRD",[0.18519818354182144, 46.775774585004896]],
    [ "SRD",[0.17714218069450097, 46.7830166053547]],
    [ "SRD",[0.18367936917894445, 46.77891378126089]],
    [ "Enedis",[1.0852375119492248, 49.42073708899861]],
    [ "Enedis",[1.0876502230993168, 49.41963404720149]],
    [ "Enedis",[1.0838760061733392, 49.41834004949653]],
    [ "Enedis",[1.089658645809095, 49.418746000310605]],
    [ "Enedis",[3.986350847196103, 50.28071777841265]],
    [ "Enedis",[3.9846055146913755, 50.28308174758603]],
    [ "Enedis",[3.988890289908142, 50.28361059952891]],
    [ "Enedis",[3.9914547806360448, 50.28399987852524]],
    [ "Enedis",[6.968110378204867, 43.613433876302324]],
    [ "Enedis",[1.107211500000001, 49.447379950000006]],
    [ "Enedis",[-0.5997644335008789, 43.15954530195925]],
    [ "Enedis",[-0.5980039450495555, 43.16040184683763]],
    [ "Enedis",[-0.5971626214546555, 43.157345997938464]],
    [ "Enedis",[-0.6015069234404072, 43.174725610101106]],
    [ "Enedis",[-0.6044269331020258, 43.17325829263569]],
    [ "Enedis",[-0.6049233627375197, 43.1721810204476]],
    [ "Enedis",[-0.6038397275199573, 43.17404212754811]],
    [ "Enedis",[-0.6115279787423079, 43.17435128887155]],
    [ "GRPH Cheylas",[6.00169461563285, 45.386788020973]],
    [ "Enedis",[6.985942865197011, 43.62757819696311]],
    [ "Enedis",[4.01623484487431, 50.28525157015178]],
    [ "Enedis",[4.0161157342037965, 50.286293764818055]],
    [ "Enedis",[4.021908329633332, 50.28636977414241]],
    [ "Enedis",[4.025106850337102, 50.287075942779644]],
    [ "Enedis",[4.022533117456729, 50.288840222411125]],
    [ "SENEQUIERE",[6.97606968752914, 43.62475267403595]],
    [ "Enedis",[6.974000582492729, 43.624044453508304]],
    [ "Enedis",[1.0865475574904901, 49.403756719663726]],
    [ "Enedis",[1.0909881314407586, 49.40414138087401]],
    [ "Enedis",[1.0920842962262038, 49.40539136911165]],
    [ "Enedis",[1.0842938564204265, 49.407374841216324]],
    [ "Enedis",[1.0889467975520675, 49.40378283827065]],
    [ "SRD",[-0.021025250000000793, 46.881428299999996]],
    [ "SRD",[0.020243100000001187, 46.861434800000005]],
    [ "SRD",[-0.014096025548071841, 46.88112419702853]],
    [ "SRD",[-0.017507491630180613, 46.884198180150605]],
    [ "SRD",[-0.008581853189742129, 46.88331781686093]],
    [ "SRD",[0.19094970516987153, 46.18958487842579]],
    [ "Enedis",[1.10751174883685, 49.456082285684126]],
    [ "Enedis",[1.132986481204418, 49.46474647763611]],
    [ "Enedis",[1.141670834441308, 49.466249359135546]],
    [ "SRD",[0.09365569328944814, 46.708685163235806]],
    [ "Enedis",[-1.8288558434029358, 46.8894473967358]],
    [ "Enedis",[-1.826954504655912, 46.892234482762824]],
    [ "Enedis",[-1.8223044205188665, 46.890406323073904]],
    [ "Enedis",[-1.850373732531972, 46.88960273375023]],
    [ "Enedis",[-1.83343437473109, 46.89410004491799]],
    [ "Enedis",[-1.83156225, 46.8957915]],
    [ "Enedis",[-1.8372717500000004, 46.89241345]],
    [ "Enedis",[-1.835813562217324, 46.88903217513062]],
    [ "Enedis",[-1.8283481049982828, 46.8958019797486]],
    [ "Enedis",[-1.844035396868747, 46.89891303291679]],
    [ "SRD",[0.18104830782385342, 47.09227366838252]],
    [ "SRD",[0.17937449266788524, 47.093913362871035]],
    [ "Enedis",[-1.8240358710471423, 46.89538955506884]],
    [ "Enedis",[-1.8324767499999997, 46.890037799999995]],
    [ "Enedis",[-1.8375413999999992, 46.8863842]],
    [ "Enedis",[-1.81586268247636, 46.87286309337582]],
    [ "Enedis",[3.949479751427248, 50.268090305294855]],
    [ "Enedis",[1.4598655411750643, 43.602029744512784]],
    [ "Enedis",[1.4665939816293687, 43.60339306059995]],
    [ "Enedis",[1.4635260058172062, 43.60579434628092]],
    [ "Enedis",[1.4725627690560608, 43.606282466782126]],
    [ "Enedis",[1.4624305851516073, 43.605127359098496]],
    [ "Aufréry",[1.472960844128434, 43.60180037988243]],
    [ "Enedis",[0.7713632440280704, 47.226299164068756]],
    [ "Enedis",[0.7760342130620261, 47.2238040239915]],
    [ "Enedis",[0.6963728226984522, 47.28221986711802]],
    [ "Enedis",[0.7164163426401259, 47.28370177459829]],
    [ "Enedis",[0.7031650377230252, 47.28648127296237]],
    [ "Enedis",[1.4657058827630336, 43.598402433699455]],
    [ "Enedis",[1.4680024245076426, 43.60043715409423]],
    [ "Enedis",[1.4587874151296232, 43.600123284457204]],
    [ "Enedis",[1.467919635414061, 43.594432258221765]],
    [ "Enedis",[1.4706203997765779, 43.59346202446941]],
    [ "Enedis",[5.442196794367722, 45.57699766676942]],
    [ "Enedis",[3.2387451481319327, 50.61559763683738]],
    [ "Enedis",[3.230711720099489, 50.61397214545328]],
    [ "Enedis",[3.229598893247124, 50.61117303807769]],
    [ "Enedis",[3.2374730750344294, 50.61446869503828]],
    [ "Enedis",[3.2290424472465573, 50.609793396487575]],
    [ "Enedis",[3.2272840658183637, 50.617155113134345]],
    [ "Enedis",[3.230889809204808, 50.61829782179206]],
    [ "Enedis",[3.2343575061486476, 50.61583927138883]],
    [ "Poste Trevins",[-0.39177651004359837, 46.33692984644412]],
    [ "Genneteau",[-0.38594798056623003, 46.337710178419464]],
    [ "Enedis",[3.046416547688434, 50.68958493702493]],
    [ "Enedis",[2.2532327916463055, 48.90496552054686]],
    [ "Enedis",[2.2481330701008218, 48.90304304964364]],
    [ "Enedis",[1.0995001543726093, 49.400319689806196]],
    [ "Enedis",[1.0988722400654523, 49.40538513803817]],
    [ "Enedis",[3.229958926448782, 50.64587218502042]],
    [ "Enedis",[3.2257488538661447, 50.64739421349727]],
    [ "Enedis",[3.2276152467558465, 50.648432781937984]],
    [ "Enedis",[3.2184492960958466, 50.647274703796825]],
    [ "Enedis",[3.221949213982851, 50.648015407144946]],
    [ "Enedis",[3.2197515958887464, 50.646161106301776]],
    [ "Enedis",[3.2173129403358875, 50.64443258607217]],
    [ "Enedis",[1.4098496639884146, 43.59506952516695]],
    [ "Enedis",[1.4155659123345108, 43.605613225019226]],
    [ "Enedis",[1.4104013845452144, 43.60582440311114]],
    [ "Enedis",[1.4084374804581572, 43.60328515229859]],
    [ "Enedis",[1.4093572076654204, 43.59545286841174]],
    [ "Enedis",[1.4160979500000002, 43.60309410000001]],
    [ "Enedis",[1.399244938042418, 43.59660803981997]],
    [ "Enedis",[1.40529783548709, 43.593296125202826]],
    [ "Enedis",[1.4144186763866087, 43.60305574712229]],
    [ "Enedis",[1.4009291046222219, 43.60070076204444]],
    [ "Enedis",[1.4162458178542634, 43.599871877789724]],
    [ "Enedis",[1.418733399212461, 43.604107081437796]],
    [ "Enedis",[1.4035461763275165, 43.5985980549797]],
    [ "Enedis",[3.225554121663007, 50.664033690190344]],
    [ "Enedis",[3.2261795000000006, 50.66238955000001]],
    [ "Enedis",[3.2269932582068237, 50.66566319120879]],
    [ "Enedis",[3.2284391999488693, 50.66767382743224]],
    [ "Enedis",[3.2340103751781695, 50.65756146042922]],
    [ "Enedis",[3.2341992333782583, 50.66900129866181]],
    [ "Enedis",[3.2392640007799294, 50.66207622383356]],
    [ "Enedis",[3.2347101000000005, 50.6693662]],
    [ "Enedis",[2.39976356077829, 47.10760400761142]],
    [ "Enedis",[2.397342899516735, 47.118409883373836]],
    [ "Enedis",[2.414731411783841, 47.11819387077538]],
    [ "Enedis",[2.4014842537845085, 47.11286863641584]],
    [ "Enedis",[2.4092998999999993, 47.11472715]],
    [ "Enedis",[2.402935483045479, 47.11166109842764]],
    [ "Enedis",[2.3968986593918653, 47.10787922697389]],
    [ "Enedis",[-0.591897606380354, 43.20006129824515]],
    [ "Enedis",[-0.5922948145307912, 43.198258325867066]],
    [ "Enedis",[-0.5863499961403068, 43.20464322015267]],
    [ "Enedis",[5.148984052593917, 48.445039569262875]],
    [ "Enedis",[5.158034761777923, 48.445829216625334]],
    [ "Enedis",[1.08367624423088, 49.44954342490258]],
    [ "Enedis",[1.060777926775594, 49.453077870441525]],
    [ "Enedis",[1.060243925734582, 49.45061490703267]],
    [ "Enedis",[1.0737902511531885, 49.44406750995629]],
    [ "Enedis",[3.240557406131601, 50.675714441870824]],
    [ "Enedis",[3.235358722096123, 50.686898689205904]],
    [ "Enedis",[1.0928590815878192, 49.45266552645899]],
    [ "Enedis",[3.241782021549124, 50.68293181067145]],
    [ "Enedis",[3.2374336421382868, 50.68698674361104]],
    [ "Enedis",[3.2347661575602866, 50.68421906583721]],
    [ "Enedis",[3.2539151431197975, 50.6806592329668]],
    [ "Enedis",[3.248118964371153, 50.682729197358114]],
    [ "Enedis",[3.2380583366187854, 50.67399152690239]],
    [ "Enedis",[3.220303534287019, 50.68265231124583]],
    [ "Enedis",[3.2208006734345362, 50.686819793505855]],
    [ "Enedis",[1.0672586780996767, 49.450028590885466]],
    [ "Enedis",[3.234862385007766, 50.68852078228014]],
    [ "Enedis",[3.2403955498540893, 50.668534296482335]],
    [ "Enedis",[3.2230382928277583, 50.686399876849705]],
    [ "Enedis",[1.0548776503520427, 49.44992867907865]],
    [ "Enedis",[3.2304093170343235, 50.68332393162349]],
    [ "Enedis",[3.252093888215706, 50.682797817847266]],
    [ "Enedis",[1.0483575459562564, 49.44954069766892]],
    [ "Enedis",[3.2491271538389843, 50.67768355135734]],
    [ "Enedis",[3.2439114852437845, 50.6771230281608]],
    [ "Enedis",[3.248725073628953, 50.68046818063088]],
    [ "Enedis",[1.070288316378792, 49.45220110336633]],
    [ "Repartition Saint-Gervais",[1.0837517437806918, 49.44925492468297]],
    [ "Enedis",[1.0822270886651175, 49.441269840710795]],
    [ "Enedis",[1.0741451750331312, 49.45281479758075]],
    [ "Maladrerie",[1.0926431181667444, 49.44922815599001]],
    [ "Enedis",[1.0700299964913011, 49.44116549026901]],
    [ "Enedis",[1.0625654326936576, 49.43967053355598]],
    [ "Enedis",[1.0663243631948833, 49.453278246212356]],
    [ "Enedis",[1.0457452388947255, 49.44657465294701]],
    [ "La Tricherie",[-2.163923314869348, 48.555329832206304]],
    [ "Enedis",[-2.1451652993668406, 48.57925505005229]],
    [ "Enedis",[4.857152044008321, 45.74723233141668]],
    [ "Enedis",[4.8480453840769195, 45.74958371057748]],
    [ "Ville Asselin",[-2.152643881741365, 48.57828826435846]],
    [ "Enedis",[4.857629628447572, 45.74617533560576]],
    [ "Enedis",[1.0990851697483561, 49.44876350315904]],
    [ "Enedis",[1.089720292284254, 49.44661406615863]],
    [ "Rampe Cauchoise",[1.087244829559693, 49.44616475219475]],
    [ "Enedis",[-4.1605299, 48.65237360000001]],
    [ "Rue de Buffon",[1.084737151890755, 49.44611628854189]],
    [ "Enedis",[1.0861864514111743, 49.40940316871687]],
    [ "Enedis",[1.1097339007096743, 49.4646188247575]],
    [ "Rue des Champs",[1.1141241830175115, 49.47772305049193]],
    [ "La Vielle",[1.11242465, 49.47431545]],
    [ "Enedis",[1.1173746037296088, 49.479349790770904]],
    [ "Enedis",[1.111129653886666, 49.46521628125721]],
    [ "Enedis",[1.1149884283391187, 49.46244754711361]],
    [ "Enedis",[1.1196627945898394, 49.472302632732195]],
    [ "Enedis",[1.1161364073794575, 49.47118840486701]],
    [ "Enedis",[1.1268027980462259, 49.471860171120134]],
    [ "Enedis",[1.1210932831906693, 49.46395444100486]],
    [ "Enedis",[1.1254353600969058, 49.47368153224716]],
    [ "Enedis",[1.1048772063798473, 49.461785464364915]],
    [ "Enedis",[1.1222379304810568, 49.467884429789464]],
    [ "Enedis",[1.117746319982259, 49.477379670779925]],
    [ "Enedis",[1.1100526559716206, 49.475347384176146]],
    [ "Enedis",[1.1253688866766478, 49.47865486214799]],
    [ "Enedis",[1.1046481999999997, 49.473594599999984]],
    [ "Enedis",[1.1198830185179647, 49.47058090694048]],
    [ "Enedis",[1.1086569041536867, 49.47298604541364]],
    [ "Enedis",[7.036108473513617, 43.54351830831873]],
    [ "Enedis",[3.950841757041205, 50.265533752482355]],
    [ "Enedis",[1.115376315792502, 49.402239866982455]],
    [ "Enedis",[3.9588973968203685, 50.267414176122685]],
    [ "La Croze",[5.251760583132247, 45.584268555721785]],
    [ "Galerie Marchande",[5.240491525320432, 45.59013558301407]],
    [ "La Botte",[5.237961557315557, 45.58753615563592]],
    [ "Intermarché",[5.242323433366135, 45.5903240565111]],
    [ "Les Blaches",[5.23869711925329, 45.58437474213005]],
    [ "Le Cret",[5.2487254, 45.588294000000005]],
    [ "Domarin",[5.246328738803971, 45.58593467516325]],
    [ "Coutannes",[5.242825550000001, 45.5867765]],
    [ "La Ferronière",[5.248804315775375, 45.586367924037134]],
    [ "Enedis",[-1.8534219868135584, 46.93596612539062]],
    [ "Enedis",[-1.9069631894850856, 46.94409021895581]],
    [ "Enedis",[-1.8864959999999997, 46.9266869]],
    [ "Enedis",[-1.94711205, 46.97700415]],
    [ "Enedis",[-1.9221985626406475, 46.96647558030395]],
    [ "Enedis",[3.966735899575372, 50.257171285211214]],
    [ "Enedis",[-1.7674705194015927, 46.825651636828454]],
    [ "Enedis",[-1.7641364000000004, 46.8360875]],
    [ "Enedis",[-1.7643081433290437, 46.82486667318747]],
    [ "Enedis",[-1.77308915, 46.82655255]],
    [ "Enedis",[-1.7682275400241252, 46.819937474030276]],
    [ "Enedis",[-1.7783291499999998, 46.8060307]],
    [ "Enedis",[-1.7165547, 46.81298130000001]],
    [ "Enedis",[-1.7626166500000002, 46.825940200000005]],
    [ "Enedis",[-1.7117527871576743, 46.82991798079423]],
    [ "Enedis",[-2.1383553000000006, 46.89162494999999]],
    [ "Enedis",[-2.1222722, 46.8887524]],
    [ "Enedis",[-2.116646684592138, 46.86157376732854]],
    [ "Enedis",[-2.1240212553976114, 46.888783867634395]],
    [ "Enedis",[-2.135650371206841, 46.88457819623568]],
    [ "Enedis",[-2.102490800000001, 46.873836749999995]],
    [ "Enedis",[-2.12423872672625, 46.88811928503758]],
    [ "Enedis",[-2.103267598331121, 46.889346033390225]],
    [ "Enedis",[-2.1435223829781003, 46.890203983136544]],
    [ "Enedis",[-2.128698260724011, 46.89035127814667]],
    [ "Enedis",[-2.1350854767354277, 46.889488456094256]],
    [ "Enedis",[-2.1383364499999997, 46.8860961]],
    [ "Enedis",[-2.11058345, 46.87991675]],
    [ "Enedis",[-2.12338965, 46.8787007]],
    [ "Enedis",[-2.122537183074825, 46.88578120169582]],
    [ "Enedis",[-2.11595445, 46.88892909999999]],
    [ "Enedis",[-2.121924550000001, 46.889447200000006]],
    [ "Enedis",[3.256709136284252, 50.5962514666433]],
    [ "Enedis",[2.9497776793331965, 42.52184010813613]],
    [ "Enedis",[2.956997796180286, 42.52311345448682]],
    [ "Enedis",[2.9601285371008212, 42.527848536770975]],
    [ "Enedis",[2.9630465330909663, 42.53031597755904]],
    [ "Enedis",[2.962672861629505, 42.50961837882989]],
    [ "Enedis",[2.9629922604166676, 42.5344371]],
    [ "Enedis",[2.9599285491323126, 42.52561406669969]],
    [ "Enedis",[2.9509086212138858, 42.53468338682585]],
    [ "Enedis",[2.9546577435010906, 42.5344884995623]],
    [ "Enedis",[2.9528995573952033, 42.524954920237924]],
    [ "Enedis",[2.959363603737889, 42.53077208441765]],
    [ "Enedis",[2.9477309500000004, 42.48309740000001]],
    [ "Enedis",[2.964898625342669, 42.50819739833167]],
    [ "Enedis",[2.9621200607814817, 42.52837758426445]],
    [ "Enedis",[2.950937091635939, 42.52384221945962]],
    [ "Enedis",[2.965118033086058, 42.53143305138853]],
    [ "Enedis",[2.96361375, 42.53303520000001]],
    [ "Enedis",[2.9617665761321907, 42.53521409302325]],
    [ "Enedis",[2.9524857407282545, 42.532694218483776]],
    [ "Enedis",[2.9571958576110644, 42.52926705227392]],
    [ "Enedis",[2.9546479722569354, 42.52293535518912]],
    [ "Enedis",[2.9562094147607754, 42.53012179361878]],
    [ "Enedis",[1.0870195026807705, 49.42551845687111]],
    [ "Enedis",[1.091910309224265, 49.42957984345653]],
    [ "Enedis",[1.0808310999999993, 49.435496650000005]],
    [ "Enedis",[1.0806301536578742, 49.424480190573234]],
    [ "Enedis",[1.082957180600663, 49.42445689189803]],
    [ "Enedis",[1.1003263026622505, 49.43311216108452]],
    [ "Enedis",[1.0804852, 49.42197765000001]],
    [ "Enedis",[1.081485953265602, 49.42585540261248]],
    [ "Enedis",[1.0968635165295038, 49.42522432175382]],
    [ "Enedis",[1.085955021009821, 49.42468619550781]],
    [ "Poste électrique de Rouen Lessard",[1.0893846738963389, 49.4331010383715]],
    [ "Enedis",[1.089458493419374, 49.43012082445828]],
    [ "Enedis",[1.1035780325944082, 49.43029349126656]],
    [ "Enedis",[1.0791716500000004, 49.438351749999995]],
    [ "Enedis",[1.0919764927686308, 49.41892006606835]],
    [ "Enedis",[1.093838148643094, 49.41685583688832]],
    [ "Enedis",[1.0920779692422236, 49.41688239342206]],
    [ "Enedis",[1.1020880495773349, 49.40624761607433]],
    [ "Enedis",[2.3949661000000004, 47.097235]],
    [ "Enedis",[2.406428970676972, 47.1038394334164]],
    [ "Enedis",[2.4006831070942245, 47.09850431765368]],
    [ "Enedis",[2.406956491194956, 47.1058297571632]],
    [ "Enedis",[2.3951926390415825, 47.09942449745205]],
    [ "Enedis",[1.0772009267710836, 49.41285428747808]],
    [ "Enedis",[1.0804349622681046, 49.415494900392225]],
    [ "Enedis",[1.0807936756008436, 49.41210125149959]],
    [ "Enedis",[1.0783382963361245, 49.41787397048588]],
    [ "Enedis",[2.961710776684229, 42.572909897643484]],
    [ "Enedis",[2.954990966720177, 42.5729215993133]],
    [ "Enedis",[1.0785417579177259, 49.41582479632284]],
    [ "Enedis",[2.9620720945324193, 42.57559345147343]],
    [ "Enedis",[2.9607330848875026, 42.57370685373333]],
    [ "Enedis",[2.967148158664169, 42.57697913489964]],
    [ "Enedis",[2.949708766265133, 42.572735374082384]],
    [ "Enedis",[2.9572527913256654, 42.56731454076758]],
    [ "Enedis",[2.9661420612695553, 42.570276746112285]],
    [ "Enedis",[2.962777681697072, 42.57246151587987]],
    [ "Enedis",[2.959211350824946, 42.57076785303873]],
    [ "Enedis",[2.962649662655771, 42.569379186039]],
    [ "Enedis",[2.9623344496877633, 42.56489791674266]],
    [ "Enedis",[2.962995649999999, 42.5779014]],
    [ "Enedis",[2.960508110367189, 42.56945215266836]],
    [ "Enedis",[2.972890499835667, 42.58200521694161]],
    [ "Enedis",[2.963964638938139, 42.571090772747795]],
    [ "Enedis",[2.9830588081458242, 42.55239068658811]],
    [ "Enedis",[2.97323073126393, 42.552259726757825]],
    [ "Enedis",[2.971109182250776, 42.54714115414184]],
    [ "Enedis",[2.978756410076385, 42.547728248638585]],
    [ "Enedis",[2.9643002002406322, 42.54930299862495]],
    [ "Enedis",[2.965730987048661, 42.55322396401152]],
    [ "Enedis",[2.978265785126002, 42.55373543068987]],
    [ "Enedis",[2.971571270998191, 42.554140386917204]],
    [ "Enedis",[2.9650300926275923, 42.55070105587621]],
    [ "Enedis",[2.9774395900128194, 42.54970377888425]],
    [ "Enedis",[2.969385578409653, 42.55348357766005]],
    [ "Enedis",[2.9749690920002423, 42.55275270723998]],
    [ "Enedis",[2.973638831992307, 42.54899324330945]],
    [ "Enedis",[2.979665553962082, 42.552470527152764]],
    [ "R Houppeville",[1.0923084255987223, 49.47328260890316]],
    [ "Enedis",[1.0718753, 49.457811050000004]],
    [ "Enedis",[1.0643485155853358, 49.4615064773496]],
    [ "Enedis",[1.0954562875249887, 49.477012692842855]],
    [ "Enedis",[1.083758449331212, 49.46912398555452]],
    [ "Enedis",[1.08747045058183, 49.47549877068344]],
    [ "Enedis",[1.0875927524211721, 49.468184831218544]],
    [ "Enedis",[1.08274255190937, 49.47718649649185]],
    [ "Enedis",[1.0907557572005615, 49.46516816229576]],
    [ "Enedis",[-0.1940060279642658, 45.21939019123511]],
    [ "Pommeraie",[1.1018304752861077, 49.46954675481579]],
    [ "Charmes",[0.22925258881771243, 47.976585525520825]],
    [ "Fra Angelico",[0.21524009316711287, 47.972021513096436]],
    [ "Enedis",[1.0897273753358276, 49.4701193539144]],
    [ "Enedis",[1.0983880923236635, 49.46828603174659]],
    [ "Enedis",[1.0801894296576422, 49.46464340697349]],
    [ "Enedis",[1.1006816770371821, 49.47117467515737]],
    [ "Ruaudin",[0.23209675, 47.97106325000001]],
    [ "Enedis",[1.07027205, 49.470442]],
    [ "La Palmeraie",[0.19915941984313218, 47.96012262292382]],
    [ "LEP Hélène Boucher",[0.22552878054249428, 47.98477123551221]],
    [ "Szarpak 2",[1.0985111499999984, 49.47565245]],
    [ "Enedis",[0.2268101759481666, 47.98215814614917]],
    [ "Les Raineries",[0.19774020852613275, 47.96082324968224]],
    [ "Combattants",[1.0826798881272277, 49.46625532449375]],
    [ "Balsamines",[0.22802056093606254, 47.97483022270973]],
    [ "Clinique Pontlieue",[0.20962360220122037, 47.983511009026984]],
    [ "Vauban",[0.21323896529550918, 47.980296916895185]],
    [ "CFPA",[0.21756039999999996, 47.96775509999999]],
    [ "Joly",[0.2063081110429334, 47.97617418099272]],
    [ "Tours 2",[0.21842925000000002, 47.970623700000004]],
    [ "Mahonias",[0.23391564999999936, 47.975570950000005]],
    [ "Bouchardon",[0.2115178999999998, 47.962002999999996]],
    [ "Complexe Californie",[0.2337408683835054, 47.98490729020429]],
    [ "Enedis",[0.219033, 47.9645898]],
    [ "Navarre",[0.20304635000000007, 47.96140295]],
    [ "Hélène Boucher",[0.20516305452537414, 47.96368830897453]],
    [ "Michel Ange",[0.2163939382083439, 47.97819961574337]],
    [ "Volo",[0.2238819721710888, 47.98611560865464]],
    [ "Timonière",[0.21607734590029368, 47.96590365626493]],
    [ "Les Bruyères",[0.2311637500000008, 47.9775146]],
    [ "Behra",[0.22568275000000002, 47.973804449999996]],
    [ "Gibet",[0.21696655982717455, 47.979649353458065]],
    [ "Gerbault",[0.2157200499999995, 47.963335349999994]],
    [ "Millet",[0.22256974999999943, 47.9744851]],
    [ "Guetteloup Printemps",[0.2251047633175532, 47.96820981972069]],
    [ "Mutuelles",[0.23972448218476475, 47.9844709972667]],
    [ "CES Vauguyon",[0.22188798883297872, 47.974899032650086]],
    [ "Pied Sec 1",[0.20372342547818253, 47.9831517569809]],
    [ "Fertré",[0.21324584092682017, 47.96380867889644]],
    [ "Enedis",[0.2357942778718286, 47.976251415134875]],
    [ "Tours 1",[0.2143593450120704, 47.97761331903957]],
    [ "Centre Co Vauguyon",[0.2162141000000013, 47.97487234999999]],
    [ "Petits Deshayes",[0.1998023077497195, 47.96511260151643]],
    [ "Noguès",[0.19757298387529576, 47.965004771524114]],
    [ "Huchepie",[0.20178814133655007, 47.96797264069553]],
    [ "Toulouse",[0.23190983358915734, 47.97356789910817]],
    [ "Pigalle",[0.21316984908546874, 47.96858628069913]],
    [ "Enedis",[0.21912821469181662, 47.969244630804766]],
    [ "Enedis",[0.20980537197265559, 47.979141179950666]],
    [ "2 Ormeaux",[0.22555228300670693, 47.980231388534314]],
    [ "Funay 1",[0.21989034999999996, 47.984439099999996]],
    [ "Pavoine",[0.20208842268809987, 47.974813074105306]],
    [ "Les Genêts",[0.23060368766371672, 47.979208375879175]],
    [ "Les Fontaines",[0.2081746864411992, 47.95925035976764]],
    [ "Fresnellerie",[0.20916842757289114, 47.96909855419911]],
    [ "Persigan",[0.21992297636758237, 47.979808133045594]],
    [ "Hêtres",[0.23251744999999996, 47.9807804]],
    [ "Soleil",[0.2276752216117342, 47.96842782055093]],
    [ "Enedis",[0.2255137720103434, 47.98564573970578]],
    [ "Pissarro",[0.2252232000000006, 47.972135949999995]],
    [ "Centre Artisanal",[0.23120234999999936, 47.982985400000004]],
    [ "Enedis",[0.21993922944479202, 47.9844442628251]],
    [ "Biolay",[0.2223133552558583, 47.9689101823431]],
    [ "Funay 2",[0.22337576455882677, 47.98404531771748]],
    [ "Guy Bouriat",[0.19984407850827535, 47.963025480866015]],
    [ "Maroc",[0.20584243319221718, 47.97451783409823]],
    [ "Sisley",[0.21646415000000005, 47.97671475]],
    [ "La Pièce des Landes",[0.23865293144852798, 47.97560185781766]],
    [ "Fontenelles",[0.22949549418280357, 47.972705832795604]],
    [ "Carcassonne",[0.2329458625036521, 47.97460287880514]],
    [ "Chapalain",[0.2221334833485045, 47.9671511498503]],
    [ "Denis Papin",[0.20002094016429675, 47.97597506727028]],
    [ "Gallouédec",[0.22775210000000004, 47.96576285]],
    [ "Lazare Weiller",[0.20512539715511244, 47.96664528870331]],
    [ "Robert Collet",[0.2183956187344725, 47.97537034920106]],
    [ "Béarn",[0.21776992570437037, 47.96765884674802]],
    [ "Enedis",[0.2258165, 47.9832208]],
    [ "Henri Champion",[0.21716906115359946, 47.9814830215927]],
    [ "JJ Rousseau",[0.2026146994257399, 47.97703212286039]],
    [ "Angelika",[0.20114770051774133, 47.97054973802917]],
    [ "Geneslay",[0.2097507468688531, 47.97913445445701]],
    [ "Rif",[0.2052713052402978, 47.973780736514385]],
    [ "Argonne",[0.21350895, 47.983635449999994]],
    [ "Maine",[0.1989292348763249, 47.97005369448763]],
    [ "Genevoix",[0.2052315, 47.97956610000001]],
    [ "Falconet",[0.2156341834718041, 47.97031159902147]],
    [ "Oasis",[0.20112342130521782, 47.96093358014427]],
    [ "Enedis",[-1.083537951043641, 43.731008087241015]],
    [ "Enedis",[-1.0497008969563013, 43.7235127109197]],
    [ "Enedis",[-1.0496385462876798, 43.72653983216709]],
    [ "Enedis",[-1.0460686408858622, 43.72882522553985]],
    [ "Enedis",[-1.0484491165178567, 43.725144381956845]],
    [ "Enedis",[-1.0445210539950147, 43.726174999347116]],
    [ "Enedis",[-1.0442580062068976, 43.72326146045977]],
    [ "Enedis",[-1.0909828732258537, 43.72948433583018]],
    [ "Enedis",[-1.057514243601151, 43.733592880419884]],
    [ "Enedis",[-1.0575489319059794, 43.732115738336496]],
    [ "Frayret",[-1.067326370208069, 43.73004334766399]],
    [ "Nèche",[-1.0685480499999997, 43.72688955]],
    [ "Plumets",[-1.072453649999999, 43.72801505]],
    [ "Enedis",[-1.0620183924101272, 43.723831845851606]],
    [ "Les Chênes Verts",[-1.0926591, 43.7281375]],
    [ "Enedis",[3.076367806424737, 45.73315193476377]],
    [ "Enedis",[-1.0859081500000003, 43.72667325]],
    [ "Enedis",[3.059503227800244, 45.746406552873886]],
    [ "Enedis",[3.0605864981381323, 45.741858038602516]],
    [ "Enedis",[-1.0535279783076374, 43.72789262540421]],
    [ "Enedis",[3.078210608706078, 45.73405539114636]],
    [ "Enedis",[3.0621879712694153, 45.74501736433371]],
    [ "Enedis",[3.074625349799429, 45.7298427850361]],
    [ "Enedis",[3.067777684458077, 45.7461378126278]],
    [ "Enedis",[3.0600138871152462, 45.75101680967738]],
    [ "Enedis",[3.0675493262352904, 45.733872374689106]],
    [ "Enedis",[3.059178145688243, 45.74845786771964]],
    [ "Enedis",[-1.0530248533276232, 43.730328861664695]],
    [ "Enedis",[3.0614180523219665, 45.75659980626312]],
    [ "Enedis",[3.0608132082865502, 45.75247336050251]],
    [ "Station d'épuration",[-1.0733820052434124, 43.72239394363644]],
    [ "Enedis",[3.0717214136324045, 45.729432151045295]],
    [ "Enedis",[3.0672891354751446, 45.72261409711706]],
    [ "Enedis",[-1.0538802660783377, 43.72470732769763]],
    [ "Enedis",[-1.0536984481251495, 43.722926344613846]],
    [ "Enedis",[3.0569054748743927, 45.74868216181697]],
    [ "Enedis",[3.0622823555894567, 45.73853888397412]],
    [ "Enedis",[3.0586580000000003, 45.738504850000005]],
    [ "Enedis",[-1.0506740784549289, 43.730012527041445]],
    [ "Enedis",[3.0590021785524564, 45.74477020136121]],
    [ "Enedis",[-1.0498231, 43.72887335000001]],
    [ "Enedis",[3.064339025121508, 45.73004891117444]],
    [ "Enedis",[3.0573371857259444, 45.73973822839231]],
    [ "Enedis",[3.060791038279639, 45.73787359990097]],
    [ "Enedis",[3.074962743200572, 45.75697813850518]],
    [ "Enedis",[3.0639121070403834, 45.760340727323246]],
    [ "Enedis",[3.0670778011740722, 45.730542551887616]],
    [ "Enedis",[3.9558709399623773, 50.26096537559491]],
    [ "SRD",[0.056522707043599046, 47.120346082134795]],
    [ "Enedis",[3.944718878589575, 50.25686540261061]],
    [ "Enedis",[3.2278405727474366, 50.669781476561695]],
    [ "Enedis",[3.21142219938773, 50.67503733587405]],
    [ "Enedis",[3.2148373709295046, 50.66867589431968]],
    [ "Enedis",[3.2090124660791206, 50.670792856533225]],
    [ "Enedis",[3.214444643752805, 50.67498094483323]],
    [ "Enedis",[3.2187284498076765, 50.669526638615274]],
    [ "Enedis",[3.2158101690881864, 50.672017161256974]],
    [ "Enedis",[3.209852534781289, 50.67256668283678]],
    [ "Enedis",[3.2148086791475454, 50.67872184075439]],
    [ "Enedis",[4.874487624269763, 48.58757726947138]],
    [ "Enedis",[3.2068591472680037, 50.67688903780028]],
    [ "Enedis",[3.2263547654296283, 50.67365697423836]],
    [ "Enedis",[3.2212692722419827, 50.66951505564255]],
    [ "Enedis",[3.228738856466367, 50.67432759544672]],
    [ "Enedis",[3.944010332702515, 50.25544016509731]],
    [ "Enedis",[3.938417379181266, 50.25381617377878]],
    [ "Enedis",[3.2280453273806105, 50.67210884958927]],
    [ "Enedis",[6.626269473579262, 45.95134468361582]],
    [ "Enedis",[6.628655135633836, 45.951383681763716]],
    [ "Enedis",[-0.02253153490794592, 45.170324092468505]],
    [ "Enedis",[3.0578444991737195, 50.624000195205035]],
    [ "Belle Fontaine",[-1.5260702499999996, 47.2007027]],
    [ "Enedis",[-1.4941559333024284, 47.18825205504027]],
    [ "Enedis",[0.6956084129460505, 46.97881579694208]],
    [ "Enedis",[0.6964910799744565, 46.97433337888301]],
    [ "Enedis",[0.6856514975287384, 46.98048630757637]],
    [ "Enedis",[0.6801350356244306, 46.98535255505345]],
    [ "Enedis",[0.6843482289138165, 46.97939595368281]],
    [ "Enedis",[0.6906976604419177, 46.978606792672295]],
    [ "65232403",[0.7888073193947728, 47.13544067125172]],
    [ "Enedis",[0.7877815681371247, 47.13720977194855]],
    [ "ZI BOCCA",[6.95760505023112, 43.54259401767216]],
    [ "La Garde",[4.65358995, 45.225071400000004]],
    [ "Enedis",[4.687661917301411, 45.25375503993721]],
    [ "SRD",[0.2092601554282583, 46.623643528889055]],
    [ "SRD",[0.23193730360793474, 46.606983443126744]],
    [ "Enedis",[3.7521693, 45.381326300000005]],
    [ "Enedis",[1.0622722443939105, 49.43202126767016]],
    [ "Enedis",[1.059757983664917, 49.41799466558442]],
    [ "Enedis",[1.066485359739649, 49.427253734584795]],
    [ "Enedis",[1.0727709552553677, 49.41337297566725]],
    [ "Enedis",[1.0519166735042742, 49.4202446068376]],
    [ "Enedis",[1.0695777878212713, 49.41299918280531]],
    [ "Harmonie 393",[1.0635271820244176, 49.4301555839662]],
    [ "Chemin de Moly",[4.784019672643658, 45.71425124992172]],
    [ "Enedis",[0.6308143029099621, 44.3921787075602]],
    [ "Enedis",[0.6327972404298287, 44.399839144468494]],
    [ "Enedis",[3.6718230511849006, 45.57204690687456]],
    [ "Enedis",[3.0993266019744365, 43.99645660239086]],
    [ "Enedis",[1.0338107710365565, 49.40919999300819]],
    [ "Enedis",[1.0327268760811084, 49.412582117298406]],
    [ "Enedis",[1.0510917700617186, 49.416909435554764]],
    [ "Enedis",[1.03142675, 49.406456500000004]],
    [ "Enedis",[1.0456617202559197, 49.397097108014094]],
    [ "Enedis",[1.029587415674509, 49.4041411054044]],
    [ "Enedis",[1.0307028218206158, 49.40127620974703]],
    [ "Enedis",[1.0335299454722493, 49.41087864287569]],
    [ "Enedis",[1.0446422529132358, 49.42348501799234]],
    [ "Enedis",[1.0290853123439894, 49.40772721723943]],
    [ "SRD",[0.3941548883302266, 46.357308938524206]],
    [ "Enedis",[0.9813289088157265, 47.32918331646165]],
    [ "Enedis",[1.0064282085426506, 47.32617981585002]],
    [ "Enedis",[0.9940583326489241, 47.32634037780731]],
    [ "Enedis",[1.001072549892542, 47.322681868119176]],
    [ "Enedis",[0.993972243404136, 47.32242713740041]],
    [ "Enedis",[0.9971877204404402, 47.31918422578841]],
    [ "Enedis",[1.01047475, 47.320138050000004]],
    [ "Enedis",[0.989227903401818, 47.32740285678443]],
    [ "Enedis",[1.0048699160291457, 47.29727989571062]],
    [ "Enedis",[0.9823978128640051, 47.32824100550683]],
    [ "Enedis",[0.9954648266418261, 47.32632383524556]],
    [ "Enedis",[1.00552619250364, 47.32116339905917]],
    [ "Enedis",[0.992235458567837, 47.32122277160199]],
    [ "Enedis",[0.9992810295760314, 47.319450408558296]],
    [ "Enedis",[0.9970977668041914, 47.32385709145215]],
    [ "Enedis",[1.0237577797202926, 49.45174630571474]],
    [ "Enedis",[1.0277499351372914, 49.44173869304543]],
    [ "Enedis",[1.0290247270225767, 49.444150356583535]],
    [ "Enedis",[1.0253384219948616, 49.446788294685646]],
    [ "Enedis",[1.0269491392670518, 49.44372501922968]],
    [ "Enedis",[1.0260044400474777, 49.4487712569852]],
    [ "Enedis",[1.020422648204448, 49.43511648969368]],
    [ "Enedis",[1.0350278734659832, 49.44561467925917]],
    [ "Enedis",[1.037905989585554, 49.44695425655973]],
    [ "Enedis",[1.0157849246237636, 49.44542826517381]],
    [ "Enedis",[1.023245371531259, 49.44126966874601]],
    [ "Enedis",[1.0304016201113617, 49.447239054703545]],
    [ "Enedis",[1.0182134755753525, 49.43277711039347]],
    [ "Enedis",[1.026863249344618, 49.438965701046115]],
    [ "Enedis",[1.032490761046089, 49.443647959640806]],
    [ "Enedis",[1.0136606211314712, 49.413948765141235]],
    [ "Enedis",[1.054365272173831, 49.46752488697004]],
    [ "Enedis",[1.0458117619670348, 49.4631907235132]],
    [ "Enedis",[1.0560360760315515, 49.45734875547988]],
    [ "Enedis",[1.0571155579767895, 49.459171558098824]],
    [ "Enedis",[1.054116596561794, 49.456862179465354]],
    [ "Enedis",[1.0586359633419398, 49.45677012148588]],
    [ "Enedis",[1.0521122855630705, 49.463075819732225]],
    [ "Enedis",[1.0563975999999984, 49.459002]],
    [ "Enedis",[1.0530678059158072, 49.46484064765041]],
    [ "Enedis",[1.045834737904621, 49.462788596021745]],
    [ "Enedis",[1.0557448772023008, 49.46409983620928]],
    [ "Enedis",[1.0517973665818365, 49.47000384563817]],
    [ "Enedis",[1.052294973959214, 49.47503746469331]],
    [ "Enedis",[5.460042214914423, 45.537164921241526]],
    [ "Enedis",[-1.6073337433210781, 48.058102636580884]],
    [ "Enedis",[3.0618437323502703, 50.62059670912555]],
    [ "Enedis",[3.9088194930368436, 50.28119604344497]],
    [ "Enedis",[3.9025301915658277, 50.27989154373794]],
    [ "Enedis",[3.9225989766339304, 50.27967268119506]],
    [ "Enedis",[3.9059711988195733, 50.28306467576878]],
    [ "Enedis",[3.9069650090071795, 50.278879737383576]],
    [ "Enedis",[3.903075799777301, 50.28172264988458]],
    [ "Enedis",[3.0726172202705766, 50.623167916441275]],
    [ "Enedis",[0.6932567778213461, 44.39656063179258]],
    [ "Enedis",[0.6689031160055725, 44.41593410369183]],
    [ "Enedis",[0.6905867592232666, 44.39529994693421]],
    [ "Enedis",[0.681287129241809, 44.40370825910614]],
    [ "Enedis",[0.691766141107293, 44.40246912861655]],
    [ "Enedis",[0.6881412700443749, 44.40592359501385]],
    [ "Enedis",[0.6915068444148934, 44.39873522793697]],
    [ "Marais",[0.21435971631297362, 47.99418641689239]],
    [ "Émile Zola",[0.20350933857227255, 47.99289412771996]],
    [ "Enedis",[0.205921473886174, 47.999183239499814]],
    [ "Enedis",[0.19530981921281096, 47.99733300646379]],
    [ "Imp Lebrun",[0.21461802246687145, 47.993521010109596]],
    [ "87 Cavaignac",[0.20747994696126087, 47.994028582766155]],
    [ "Fuie",[0.20265641155131559, 47.99818376969431]],
    [ "Désiles",[0.21259204705304965, 47.990858066456546]],
    [ "Mésanges",[0.1775081174342834, 47.9929419789436]],
    [ "Des Champs",[0.19817513918857801, 48.000285802369405]],
    [ "Girard",[0.2057550095561508, 48.00131204125369]],
    [ "Marais 25",[0.2135992773404581, 47.9943974463336]],
    [ "Heuzé",[0.1755747264220016, 47.99355970525083]],
    [ "Sous-station Polygone",[0.19884185000000043, 47.9828372]],
    [ "Lenoir",[0.20837242146645, 48.00033634743767]],
    [ "Pavillon",[0.20768873200161453, 47.989892282031725]],
    [ "52 Bollée",[0.20789889469140557, 48.001112477387124]],
    [ "Activité",[0.18448865587661126, 47.986029195753204]],
    [ "Paris 2",[0.23222135989153003, 47.99818763095103]],
    [ "Bossuet",[0.22123674999999998, 47.99594875]],
    [ "Ifs",[0.20313188758120732, 48.00122247632736]],
    [ "Scarron",[0.20822496634690252, 47.99825198891376]],
    [ "Barbin",[0.20283899140000966, 47.98887581961804]],
    [ "Tri Monthéard",[0.21664794761130152, 47.995197081440466]],
    [ "Mariette",[0.21122509484146834, 47.998418313264956]],
    [ "Bitche",[0.19753114973677982, 47.99556543571772]],
    [ "Enedis",[0.20001370614124417, 47.999656497828845]],
    [ "SSR6 Pontlieue",[0.20874945499902697, 47.985952877041925]],
    [ "Arsenal",[0.21057034524786347, 47.99399210309568]],
    [ "Les Rottes",[0.22453365104926631, 47.99637723935787]],
    [ "Ste Anne",[0.20947225, 48.001428450000006]],
    [ "Commandant de la Boudinière",[0.21892176039680927, 47.99254257544285]],
    [ "Halage",[0.17954618145823067, 47.99599100149296]],
    [ "Bourg Belé",[0.19855396653354915, 47.998487448444344]],
    [ "Moulin de l'Huisne",[0.18105351950004125, 47.99325082998934]],
    [ "Clinique Enfant Jésus",[0.207024601764414, 48.001307077719666]],
    [ "Vosges",[0.2226922, 47.99280375000001]],
    [ "Poste Principal",[0.1878816000000014, 47.99646114999999]],
    [ "Enedis",[0.23552060117804374, 47.99368754406812]],
    [ "Maraîchers",[0.19638185855192214, 47.98952346469678]],
    [ "Beaufils",[0.20958198506755607, 47.98874428204753]],
    [ "La pointe",[0.18629919156139915, 47.984406749941385]],
    [ "Ferdinand de Lesseps",[0.19791023849534767, 47.990467551046585]],
    [ "Novaxis",[0.189838723354312, 47.99533114570966]],
    [ "Floréal",[0.19623639999999998, 47.98829374999999]],
    [ "Magasins généraux",[0.18177508784422874, 47.99839070800782]],
    [ "Galopière",[0.18564653085850963, 47.987658108252674]],
    [ "Fromendière",[0.19600982481269233, 47.98501136136015]],
    [ "10 rue arnage",[0.19247124587163644, 47.9906127515317]],
    [ "SSR9 Cugnot",[0.22711441912217634, 47.9911056636428]],
    [ "Tamaris",[0.1889346306851942, 47.98410020488127]],
    [ "Enedis",[0.23556630115211655, 47.993621750859845]],
    [ "Bobillot",[0.19439675545236684, 47.99301246331573]],
    [ "Roumanie",[0.2339381, 47.9965237]],
    [ "Acacias",[0.1824151289030477, 47.99764591228197]],
    [ "Liboisne",[0.174473896471902, 47.987707985285866]],
    [ "Australie",[0.18491310889693569, 47.99465181641353]],
    [ "Enedis",[0.2042226896107365, 47.99167918889323]],
    [ "Abri Sablons",[0.23092530617647114, 47.99674237447343]],
    [ "Riffaudière",[0.17586071898930844, 47.99002398078028]],
    [ "Bérard",[0.2032189982472403, 47.985601061037414]],
    [ "Espérance",[0.17514175447027738, 47.99294598013089]],
    [ "Marguerite",[0.19926228401107246, 47.985342304831406]],
    [ "Enedis",[0.2058913927872004, 47.99007122171947]],
    [ "Louis Crétois",[0.2034713261885931, 47.9848505113272]],
    [ "Enedis",[2.144697121404395, 47.93107747442776]],
    [ "Enedis",[2.1488946071115747, 47.93303829945777]],
    [ "Enedis",[2.1084692192165644, 47.89053781659648]],
    [ "Enedis",[2.1049802245761047, 47.890291641206744]],
    [ "Enedis",[2.109026384953854, 47.89513765417667]],
    [ "Enedis",[2.1115991546950617, 47.89316140375425]],
    [ "Enedis",[2.112853251384986, 47.89027902893082]],
    [ "Enedis",[4.683690016603424, 45.03496545008566]],
    [ "Enedis",[6.130314347812824, 46.1396829840486]],
    [ "Enedis",[6.1211167812323515, 46.14037903247882]],
    [ "SRD",[0.06588644513317886, 46.65651694919531]],
    [ "SRD",[0.061474850000000004, 46.66065095]],
    [ "Enedis",[1.4261246491477788, 43.59635599735684]],
    [ "Enedis",[1.4195383193388327, 43.594689610580154]],
    [ "SRD",[-0.08788487956989303, 47.09404639354839]],
    [ "Enedis",[4.395580861873551, 45.39714442178107]],
    [ "PLANFOY",[4.440884064914583, 45.38250645387961]],
    [ "Enedis",[4.444810780651962, 45.46737423671232]],
    [ "Enedis",[4.448503392964987, 45.45203502445277]],
    [ "SRD",[0.013759896710495214, 47.061799154925964]],
    [ "Enedis",[0.8242419927429054, 45.86942268114369]],
    [ "Enedis",[0.8208556899260783, 45.87145990016345]],
    [ "Enedis",[0.8391818145631695, 45.861063629426134]],
    [ "SRD",[0.017595149999999997, 47.06316569999999]],
    [ "SRD",[0.0228108146843962, 47.06507901480048]],
    [ "Enedis",[2.191427127712038, 48.70308743906777]],
    [ "Pelerin",[2.1780166298482473, 48.695605945755084]],
    [ "Paillolle",[2.19455355, 48.70601835]],
    [ "Enedis",[2.1858265410397357, 48.68642463032199]],
    [ "Enedis",[2.1790729000000004, 48.69787300000001]],
    [ "Enedis",[2.18318962062574, 48.69671348605229]],
    [ "Enedis",[2.1815006088036015, 48.6769841461856]],
    [ "Enedis",[2.197381861818869, 48.696561262387846]],
    [ "Enedis",[2.1802589283491693, 48.68116395573574]],
    [ "CHEMIN",[2.2012352, 48.705014250000005]],
    [ "Enedis",[2.189463525189367, 48.68778940517523]],
    [ "Enedis",[2.1945932999999997, 48.704054899999996]],
    [ "Enedis",[3.9229668092670043, 50.27944598819674]],
    [ "Enedis",[3.926524721747823, 50.27610771655564]],
    [ "Enedis",[0.8621168872306029, 45.87822525315194]],
    [ "Enedis",[0.8701852433343983, 45.87893184095108]],
    [ "Enedis",[0.8842702847790044, 45.880687075890535]],
    [ "Enedis",[0.8683795470970554, 45.8814070377313]],
    [ "SRD",[0.2823198863715509, 46.97939048047188]],
    [ "Enedis",[6.358286689765281, 47.35161786028724]],
    [ "Enedis",[6.35026405, 47.35618314999999]],
    [ "Enedis",[6.368203466360675, 47.35007486881847]],
    [ "Enedis",[6.352468399999999, 47.35356225]],
    [ "Enedis",[6.346383301661852, 47.34707612235913]],
    [ "Enedis",[6.363058477420591, 47.34951273157291]],
    [ "Enedis",[6.347134313979639, 47.34094724146573]],
    [ "Enedis",[6.338317849999998, 47.34589619999999]],
    [ "Enedis",[6.3526937843097135, 47.35129149974967]],
    [ "Enedis",[6.356920383911023, 47.340354491423405]],
    [ "Enedis",[6.366057599999999, 47.34421725]],
    [ "Enedis",[6.361072557186505, 47.34903093462128]],
    [ "Enedis",[6.376877484190313, 47.3418649881336]],
    [ "Enedis",[6.369882670427645, 47.34864795277189]],
    [ "Enedis",[6.351452954407381, 47.35702647972566]],
    [ "Enedis",[6.355695793914624, 47.34906038065396]],
    [ "Enedis",[6.353276905035924, 47.352367281832514]],
    [ "Enedis",[6.335899208503516, 47.35098256862322]],
    [ "Enedis",[6.35829584258567, 47.34625965730526]],
    [ "Enedis",[3.9297443147979454, 50.29403278608927]],
    [ "Enedis",[3.9342202016507613, 50.29733239269386]],
    [ "Enedis",[6.3651444999999995, 47.351775]],
    [ "Enedis",[6.364827637425048, 47.350931960308486]],
    [ "Enedis",[4.3186470943640085, 45.43045733628582]],
    [ "Enedis",[4.320507850297691, 45.429838582574185]],
    [ "Enedis",[4.32683355008451, 45.43350496212941]],
    [ "Enedis",[4.326615965527327, 45.4364210353348]],
    [ "Enedis",[4.316866130875907, 45.425946713674655]],
    [ "Enedis",[4.3199190949982045, 45.43378542323262]],
    [ "Enedis",[4.319334836759614, 45.43541680193184]],
    [ "Enedis",[4.314837485774058, 45.4325454655509]],
    [ "Enedis",[4.3153501841474275, 45.42249799572801]],
    [ "Enedis",[4.317299677992314, 45.43372749327322]],
    [ "Enedis",[4.3239289738041, 45.43686759084499]],
    [ "Enedis",[4.3225983141721, 45.42946745477562]],
    [ "Enedis",[4.314988373577476, 45.43461148751378]],
    [ "Enedis",[0.5678140282016406, 49.5258533446426]],
    [ "Enedis",[0.5773179800108647, 49.527070238814424]],
    [ "Enedis",[0.5681559355555549, 49.529870291515735]],
    [ "Enedis",[0.5746716129144891, 49.5283636475185]],
    [ "Enedis",[0.566831167312045, 49.52886572592738]],
    [ "Enedis",[0.5647754792596996, 49.525598235748966]],
    [ "Enedis",[0.5725042731058237, 49.52726522603628]],
    [ "Enedis",[0.5717096354789251, 49.5295442406117]],
    [ "Enedis",[0.5761862004267888, 49.525603783311446]],
    [ "Enedis",[0.5793731604946109, 49.52883869492358]],
    [ "Enedis",[0.6651908096286008, 43.85273820407945]],
    [ "Enedis",[0.6655868411475173, 43.850242735869664]],
    [ "Enedis",[0.6578111842984985, 43.85516530610162]],
    [ "Enedis",[0.6606317580931237, 43.842906699545935]],
    [ "Enedis",[0.6588383417163466, 43.84156737506439]],
    [ "Enedis",[0.6645297687211503, 43.84113152268749]],
    [ "Enedis",[0.6569869512238613, 43.84787200014731]],
    [ "Enedis",[0.6608383372595779, 43.85057517547124]],
    [ "Enedis",[0.66476177986512, 43.843572608300704]],
    [ "Enedis",[0.6625599393907762, 43.85484276621125]],
    [ "Enedis",[0.6621765741400231, 43.84964927449066]],
    [ "Enedis",[0.6259056389934047, 44.44570720916967]],
    [ "Enedis",[0.6302625787771085, 44.43919072155641]],
    [ "Enedis",[0.6163092538449563, 44.43845837552183]],
    [ "Enedis",[0.6236337289356735, 44.44080040995155]],
    [ "Enedis",[0.6140965086478071, 44.44273465617126]],
    [ "Enedis",[0.6221031702558508, 44.44517493474578]],
    [ "Enedis",[0.6181566746786256, 44.444636183766825]],
    [ "Ponfilin",[6.351891237084702, 46.161606569944816]],
    [ "Enedis",[6.347829492754564, 46.170934572728015]],
    [ "Enedis",[2.0157931738486714, 47.84389861457117]],
    [ "Enedis",[2.044592957556786, 47.84856616895482]],
    [ "Enedis",[2.00277155, 47.849003849999995]],
    [ "Enedis",[3.036826706121631, 50.68704398870554]],
    [ "Enedis",[2.02467985, 47.8398671]],
    [ "Enedis",[2.0446789494474347, 47.847770918948925]],
    [ "Enedis",[2.0140052653740868, 47.84327717529011]],
    [ "Enedis",[3.1880392295070004, 50.72221174928216]],
    [ "Enedis",[3.1877344940159333, 50.72528343659312]],
    [ "Enedis",[-2.043858332461046, 46.91155986628313]],
    [ "Enedis",[-2.047898497687408, 46.915923183845905]],
    [ "Enedis",[-2.0436622, 46.9186642]],
    [ "Enedis",[-2.0385930685131686, 46.93012580299396]],
    [ "Enedis",[-2.0244396351147302, 46.91213877265864]],
    [ "Enedis",[-2.05163835, 46.92564560000001]],
    [ "Enedis",[-2.0456656026671554, 46.919366599510894]],
    [ "Enedis",[-2.071833435239651, 46.933390872385615]],
    [ "Enedis",[-2.0333278000000004, 46.913391700000005]],
    [ "Enedis",[-2.0274522999999998, 46.907318249999996]],
    [ "Enedis",[-2.0364225499999997, 46.91154485]],
    [ "Enedis",[-2.0398529500000007, 46.918805549999995]],
    [ "Enedis",[3.92701085, 50.29884535]],
    [ "Enedis",[-2.041250551203059, 46.915879761641506]],
    [ "Enedis",[-2.0432209208232037, 46.924207784552365]],
    [ "Enedis",[-2.104068229883668, 46.90770774680343]],
    [ "Enedis",[-2.0254873499999992, 46.913327]],
    [ "Enedis",[-2.04253165, 46.91329740000001]],
    [ "Enedis",[-2.034902757411286, 46.9093821670139]],
    [ "Enedis",[-2.03854250177366, 46.91036046100714]],
    [ "Enedis",[-2.0430243444622818, 46.91524772625727]],
    [ "Enedis",[0.4242815062017292, 47.11414115120846]],
    [ "Enedis",[0.5765534000000001, 47.22608580000001]],
    [ "Enedis",[2.4196805052942962, 48.77076784331872]],
    [ "Enedis",[0.42285202168872693, 47.11533704370258]],
    [ "Enedis",[0.5755746596651342, 47.22336228626002]],
    [ "Enedis",[2.415636717891074, 48.75510370108458]],
    [ "SRD",[0.17743739999999958, 46.97076755]],
    [ "Enedis",[2.4181234999999996, 48.775191850000006]],
    [ "Enedis",[0.6073498771447294, 47.117791704359504]],
    [ "Alliés",[2.404525063276284, 48.76419921665852]],
    [ "Enedis",[2.413332994537326, 48.75645367476327]],
    [ "Enedis",[2.414169474790767, 48.770354847356124]],
    [ "Mairie",[2.41055140833317, 48.76294515965394]],
    [ "Enedis",[2.4202706698455265, 48.771893817606646]],
    [ "Enedis",[2.423360656014739, 48.773719842780345]],
    [ "Tuilerie 2",[5.69009222795404, 45.170738066335346]],
    [ "Enedis",[5.684821769359314, 45.16706889148885]],
    [ "Paris 1",[0.22835500504061562, 47.997674340359026]],
    [ "Renan",[0.2267074882871121, 47.9999549011742]],
    [ "Mallarmé",[0.22438198764196385, 48.010041203814595]],
    [ "Solitude",[0.2196130245533487, 48.001756008309634]],
    [ "Maillets",[0.2113363862556339, 48.014827981681556]],
    [ "Flore 1",[0.20732547865620984, 48.00240040639112]],
    [ "Rivière",[0.21461841548950664, 48.008902893131946]],
    [ "Fauchard",[0.21461475668182906, 48.002911167422795]],
    [ "Les Homelets",[0.23450779999999946, 48.016118950000006]],
    [ "Sainte-Croix",[0.20341720733459415, 48.00408285298399]],
    [ "Du Bellay",[0.23383818659711741, 47.99951129189422]],
    [ "Ardoise",[0.22531519751525608, 48.00778736562554]],
    [ "Mordret",[0.21347328441329166, 48.014466570231704]],
    [ "Saint-Exupéry",[0.21292697676596928, 48.00034072414686]],
    [ "Buffon",[0.22269100136182735, 48.003251100949576]],
    [ "Méhoncourt",[0.22564508341125483, 48.0058407027462]],
    [ "Alouettes",[0.21956693789065304, 48.00826980496265]],
    [ "Saint-Sébastien",[0.23012447365610647, 48.0179553588931]],
    [ "Cimetière Éventail",[0.21676710165335084, 48.00431723800674]],
    [ "Forsythias",[0.22490334145867455, 48.01312298684035]],
    [ "Paul Éluard",[0.23086500194117116, 48.00213566853003]],
    [ "Négrier",[0.20705162453732953, 48.009260118642416]],
    [ "Enedis",[-0.6392833614609293, 43.25044538439874]],
    [ "Enedis",[0.20776182406259128, 48.00781449506938]],
    [ "De Staël",[0.2280606654682194, 48.004864672263075]],
    [ "Chalets",[0.20798178645080173, 48.01073893896157]],
    [ "Enedis",[-0.6484757294233578, 43.308203704798615]],
    [ "Osier",[0.21842024716088687, 48.01587012643781]],
    [ "Dubignon",[0.2062939484199294, 48.006023072535505]],
    [ "Valence",[0.2250217614388257, 48.01616018733666]],
    [ "Enedis",[0.20874934518836777, 48.013561722700786]],
    [ "Douce Amie",[0.23502288070187735, 48.003365757936976]],
    [ "Cadix",[0.2218005285747222, 48.01791889589029]],
    [ "Arche",[0.2111438302323943, 48.002501215316904]],
    [ "Maupertuis",[0.20577972858704105, 48.003098813211494]],
    [ "Yzeuville",[0.21799472270170456, 48.00556936948879]],
    [ "Belot",[0.21215821177634256, 48.00983430366854]],
    [ "Vignes",[0.22986134132229605, 48.01222791749215]],
    [ "Paris 3",[0.2212525431725253, 47.997334335657946]],
    [ "Massenet",[0.22052218129030557, 48.005712564310464]],
    [ "Tristan Bernard",[0.22181048760503203, 48.013559180773484]],
    [ "Edelweiss",[0.21795424678208508, 48.012724925982184]],
    [ "Casablanca",[0.23066718880935147, 48.014946913890974]],
    [ "Isaac",[0.22416978881953065, 48.01474205730691]],
    [ "Ambroise Gentil",[0.21212529898342436, 48.005538279120465]],
    [ "Tertre Saint-Pierre",[0.2186186499999992, 48.0006925]],
    [ "Barcelone",[0.2269410233788967, 48.01895650240417]],
    [ "Duval",[0.22373712251813682, 48.01502248259736]],
    [ "Vigny",[0.22738865999105237, 48.00233290670419]],
    [ "Tripoli",[0.2332257392108285, 48.01815911738072]],
    [ "Les Petits Carreaux",[0.21642315624425731, 48.00980302765009]],
    [ "Kaboul",[0.22366349731060586, 48.01915247088923]],
    [ "Augier",[0.22544136505871065, 48.004915568938536]],
    [ "Saint-Blaise",[0.2341259288074247, 48.00697139648852]],
    [ "Vert Village",[0.23383165000000075, 48.0019874]],
    [ "Burgos",[0.22781064658585598, 48.01591513736184]],
    [ "Flore 2",[0.20683328456269134, 48.00270072607735]],
    [ "Gazonfier",[0.22194187151620276, 48.00456284257564]],
    [ "Gaston Simon",[0.21825224999999918, 48.0116827]],
    [ "Besnier",[0.21725963912964594, 47.99999930467497]],
    [ "TV Champion",[0.21846623280987607, 48.015882851864355]],
    [ "Malpalu",[0.21228131889913626, 48.0015588405567]],
    [ "Santander",[0.22644135580092953, 48.0169663610615]],
    [ "Coppée",[0.22427012349676606, 48.000510932381225]],
    [ "Repart Binet",[4.6600778, 45.2384246]],
    [ "Enedis",[-1.242959376894267, 48.50735342123307]],
    [ "Enedis",[3.188739274111588, 50.718485737608724]],
    [ "Enedis",[3.229887713503579, 50.694523122489336]],
    [ "Enedis",[3.2587464798204433, 50.698310498210894]],
    [ "Murgin",[6.3564344551272365, 46.166594815856136]],
    [ "Bernaudin",[4.670784196465301, 45.23006932007613]],
    [ "66287499",[-0.5036748465381893, 46.84067891678128]],
    [ "Les Marronniers",[2.8929927122807024, 43.944671528070174]],
    [ "Enedis",[2.8935873355299297, 43.961944342129186]],
    [ "L g rey 30",[2.2565978285005515, 48.91216370966556]],
    [ "Enedis",[2.256962940674595, 48.91053083704543]],
    [ "Le Plan",[4.713393422290379, 45.71588682269451]],
    [ "Enedis",[3.5523289889463068, 47.848339145004985]],
    [ "Enedis",[3.578044318801319, 47.82984956547416]],
    [ "Enedis",[3.563218082278174, 47.84426594828675]],
    [ "Enedis",[3.5539401265226775, 47.8530111817554]],
    [ "Enedis",[3.5517824870199135, 47.84677942904688]],
    [ "Enedis",[3.6140635752928287, 47.857476276995584]],
    [ "Enedis",[3.578858913933396, 47.8365513147308]],
    [ "Enedis",[3.617363867091914, 47.85610152374055]],
    [ "Enedis",[3.5587800819850246, 47.85004804189801]],
    [ "Enedis",[3.551146968662515, 47.84739875060902]],
    [ "Enedis",[3.582495864777468, 47.83801606307355]],
    [ "Enedis",[3.572779758483488, 47.8411249491976]],
    [ "Enedis",[3.576319634598225, 47.84940848929437]],
    [ "Enedis",[3.5656887848204373, 47.84655807649328]],
    [ "Enedis",[1.82628905, 43.798362950000005]],
    [ "Enedis",[2.252720013990281, 48.98293841909215]],
    [ "Enedis",[2.2537649782515903, 48.98524467162367]],
    [ "Enedis",[2.2507873042767916, 48.9855061181159]],
    [ "Enedis",[5.679368361285468, 45.15235980860037]],
    [ "AFU 1",[5.696216094700781, 45.158922527473955]],
    [ "Enedis",[3.0550517784676594, 45.766483112008856]],
    [ "Enedis",[3.0578868399068355, 45.76548062647386]],
    [ "Enedis",[3.0566450426029306, 45.76093822523604]],
    [ "Enedis",[3.0596267662449015, 45.760038633624596]],
    [ "Enedis",[3.0501863932332935, 45.76557734717341]],
    [ "Enedis",[3.053391123929375, 45.767292905396445]],
    [ "Bikert",[4.702492129989214, 45.67501777508261]],
    [ "Enedis",[-3.1328663472083185, 47.49148443337744]],
    [ "Kermorvan",[-3.1111580000000005, 47.48731094999999]],
    [ "Enedis",[-3.1113451, 47.4755961]],
    [ "Enedis",[-1.5121372086626923, 46.73013627349854]],
    [ "Enedis",[-3.11649102408071, 47.48083004410145]],
    [ "Enedis",[-1.5082725499999998, 46.73235785]],
    [ "Enedis",[-3.1309287776875703, 47.48935326798696]],
    [ "Enedis",[-1.51067335, 46.7236982]],
    [ "Enedis",[-1.5136563500000004, 46.72757065]],
    [ "Enedis",[-3.1189165363553073, 47.49241418248895]],
    [ "Enedis",[-3.1213197661892824, 47.49572949274399]],
    [ "Kervozes",[-3.1276324856560924, 47.478619521861]],
    [ "Enedis",[-3.09647365, 47.48193899999999]],
    [ "Enedis",[-3.1229927500000008, 47.48254810000001]],
    [ "Enedis",[-3.1357580841132733, 47.49447069851137]],
    [ "Enedis",[-1.5096889536404197, 46.725721417815045]],
    [ "Enedis",[-3.102310753220142, 47.48450056686979]],
    [ "Enedis",[-3.116007203885973, 47.49065976113364]],
    [ "Enedis",[-3.108652484969337, 47.47408747587149]],
    [ "Enedis",[-3.11085265, 47.48266985]],
    [ "Enedis",[-3.1280256354675022, 47.482893273778814]],
    [ "21 R Port Pech",[-3.1294422344035304, 47.47695881636206]],
    [ "Enedis",[-3.1057136378443273, 47.48608842002863]],
    [ "Enedis",[-3.1123545101217016, 47.47723067408801]],
    [ "Enedis",[-3.1079287671707627, 47.48484629779458]],
    [ "Enedis",[-3.1084583886835984, 47.47780957265816]],
    [ "Rue Pouligner",[-3.120406300944899, 47.48013760672146]],
    [ "Enedis",[-0.49478845658950105, 46.8343825441983]],
    [ "Enedis",[4.134756127067226, 43.51458497916293]],
    [ "Enedis",[4.131772481104887, 43.51336695786761]],
    [ "Enedis",[4.130094084103095, 43.516109744431894]],
    [ "Enedis",[4.130597610660214, 43.52095735813398]],
    [ "Enedis",[4.145269067436652, 43.52797752251553]],
    [ "Enedis",[4.126095711454463, 43.51684680872956]],
    [ "Enedis",[4.139465662546754, 43.52256681237695]],
    [ "Enedis",[4.132928519224668, 43.52024943758786]],
    [ "Enedis",[4.109287118716454, 43.55371969754436]],
    [ "Enedis",[4.137511695847268, 43.51752541225019]],
    [ "Enedis",[4.140898599999999, 43.51532315]],
    [ "Enedis",[4.151618085114711, 43.525513214279314]],
    [ "Enedis",[4.125498133627528, 43.51375143716372]],
    [ "Enedis",[4.128087433113344, 43.52131939580555]],
    [ "Enedis",[4.136388833333334, 43.5415277519774]],
    [ "Enedis",[4.141734668614735, 43.517277498530966]],
    [ "Enedis",[4.133642755761393, 43.54221266482867]],
    [ "Enedis",[4.129131309456584, 43.51513618855227]],
    [ "Enedis",[4.135869259864597, 43.52092117617252]],
    [ "Enedis",[4.136437616610182, 43.51871809346011]],
    [ "Enedis",[4.133307776765813, 43.521410051026386]],
    [ "Enedis",[5.466716038926426, 43.57741537069257]],
    [ "Enedis",[5.467013184220791, 43.582699301813705]],
    [ "Enedis",[5.481150754547227, 43.60109366381721]],
    [ "Enedis",[5.472413566177457, 43.582267450570754]],
    [ "Enedis",[5.470429721998682, 43.576653605170044]],
    [ "Enedis",[5.471849467632781, 43.57814966734222]],
    [ "Enedis",[5.471459704591344, 43.58168508644483]],
    [ "Enedis",[5.468510132801931, 43.57648473386474]],
    [ "Enedis",[5.4757832564676, 43.58533321576099]],
    [ "Enedis",[5.473273302405642, 43.58383013710523]],
    [ "Enedis",[5.474855456705856, 43.58790287247174]],
    [ "Enedis",[5.467770769994147, 43.5805285942613]],
    [ "Enedis",[5.472921662907395, 43.579925284191596]],
    [ "Poste ERDF La Rocheliere",[4.752169106665884, 45.71591485187201]],
    [ "66577657",[4.744371844326707, 45.71187233625885]],
    [ "Poste ERDF ZI Les Troques",[4.761304986113704, 45.697820513678266]],
    [ "réséda",[6.323037011364537, 49.07792738761189]],
    [ "réséda",[6.3099152506533995, 49.080503833088805]],
    [ "réséda",[6.315355671343369, 49.07839412655715]],
    [ "réséda",[6.310039058909852, 49.076280943522015]],
    [ "réséda",[6.310417913203762, 49.077814901980155]],
    [ "réséda",[6.31959985464096, 49.07893543378496]],
    [ "Enedis",[5.694564591220706, 45.188079916280266]],
    [ "Enedis",[-4.336905717223818, 47.81069064629741]],
    [ "Enedis",[-4.3711119499999995, 47.79887665]],
    [ "Enedis",[-4.351792142530356, 47.798314150751175]],
    [ "Enedis",[-4.329303300000001, 47.812929]],
    [ "Enedis",[5.075823064061679, 45.03239064595406]],
    [ "Enedis",[-4.344305296674763, 47.81392844708979]],
    [ "Enedis",[-4.3316345344110045, 47.8022040032715]],
    [ "Kersuluan",[-4.361347454023681, 47.805712769195274]],
    [ "Enedis",[-4.3719718639461504, 47.80600274340205]],
    [ "Enedis",[-4.358564047940825, 47.79685127194117]],
    [ "Enedis",[-4.305191867017124, 47.80632356622954]],
    [ "Enedis",[5.709953323900729, 45.20619648505108]],
    [ "Enedis",[5.712736870775782, 45.20649119540314]],
    [ "Olivier",[4.099597143317419, 43.56522001793359]],
    [ "Enedis",[4.091974223111535, 43.56543872070406]],
    [ "Enedis",[4.095544836106607, 43.565404648861694]],
    [ "Enedis",[4.0988887900729125, 43.56575524884471]],
    [ "Enedis",[4.08634137676633, 43.5598335433945]],
    [ "Enedis",[4.083287668937584, 43.56571207997263]],
    [ "Scottish",[4.103611350599773, 43.570204412686564]],
    [ "Enedis",[4.063983810601878, 43.55905385460026]],
    [ "Enedis",[4.092123149471614, 43.566420523766055]],
    [ "Enedis",[4.074442220787961, 43.56687510966425]],
    [ "Greens",[4.105671058580234, 43.57176089500854]],
    [ "Enedis",[4.0733591839509735, 43.564776623860574]],
    [ "Enedis",[4.086121067169906, 43.55720646030662]],
    [ "Enedis",[4.074070051896813, 43.56076406016347]],
    [ "Enedis",[4.748871615079555, 45.5862973326223]],
    [ "Électricité de France",[9.156649043334989, 41.386989005126935]],
    [ "Enedis",[1.55096205, 43.52747689999999]],
    [ "Enedis",[1.541716043431195, 43.528186417252904]],
    [ "Enedis",[1.5546885705164362, 43.52035234348672]],
    [ "Enedis",[1.556100439330064, 43.51891639476899]],
    [ "Enedis",[1.5544830291056948, 43.50981936677012]],
    [ "Enedis",[1.5536848828231293, 43.516467601360546]],
    [ "Enedis",[1.556129988738816, 43.52195285828889]],
    [ "Enedis",[1.5600304296482719, 43.523417121182675]],
    [ "Enedis",[1.5562002152143846, 43.513160387284465]],
    [ "Enedis",[1.5554902492340728, 43.529801834675894]],
    [ "Enedis",[1.5623663664137286, 43.52553618039729]],
    [ "Enedis",[1.560852069456347, 43.51570693025639]],
    [ "Enedis",[1.5575183574200508, 43.51113020019615]],
    [ "Enedis",[1.5641875500000009, 43.51902555]],
    [ "Enedis",[1.5507074161784928, 43.51784337502089]],
    [ "Enedis",[1.5528406499999987, 43.521872]],
    [ "Enedis",[1.5511535000000005, 43.52896435000001]],
    [ "Enedis",[1.5457371683627548, 43.53189361727736]],
    [ "Enedis",[1.5559642303049372, 43.51540003348058]],
    [ "Enedis",[1.5558379648116742, 43.523429291958514]],
    [ "Enedis",[1.5496976186948745, 43.53194431102621]],
    [ "Enedis",[1.5688867603332506, 43.526136348770365]],
    [ "Enedis",[1.5432901401715489, 43.52496271600861]],
    [ "Enedis",[3.131081360238297, 45.784513323161555]],
    [ "Enedis",[3.131486525143477, 45.78386426532355]],
    [ "Enedis",[3.139523178198376, 45.788646898215426]],
    [ "Enedis",[3.1315884645579515, 45.781052157237596]],
    [ "Enedis",[3.143034414250774, 45.78878043189433]],
    [ "Enedis",[3.1219362710033907, 45.78761162206959]],
    [ "Enedis",[3.123474011684668, 45.77944407143966]],
    [ "Enedis",[3.1401332289374198, 45.78212127530806]],
    [ "Enedis",[3.1386706582712827, 45.77963030395163]],
    [ "Enedis",[3.124191077736993, 45.778523078750105]],
    [ "Enedis",[3.125450071821655, 45.784733938603196]],
    [ "Enedis",[3.127625257061426, 45.7864143855109]],
    [ "Enedis",[3.1286982589487153, 45.785399503674576]],
    [ "Enedis",[3.1371534119650644, 45.786588795073115]],
    [ "Enedis",[3.136706147819776, 45.7888292465764]],
    [ "Enedis",[3.132697857927669, 45.78006447680287]],
    [ "Enedis",[3.1344668, 45.783739049999994]],
    [ "Enedis",[3.127495500612728, 45.782141928245366]],
    [ "Enedis",[3.131848075453092, 45.787078970247784]],
    [ "Enedis",[3.1360669466289286, 45.784567666759756]],
    [ "Enedis",[3.1307375659479124, 45.7866107287657]],
    [ "Enedis",[3.133843581318904, 45.783243910136875]],
    [ "Enedis",[3.1387630324930136, 45.77918120567432]],
    [ "Enedis",[3.1239979997337226, 45.78200013903994]],
    [ "Enedis",[3.1236496999503354, 45.78396395652057]],
    [ "Enedis",[3.1432583908562326, 45.78874232334398]],
    [ "Enedis",[3.126474675191086, 45.78451570822618]],
    [ "Enedis",[3.120999602293914, 45.79497584303278]],
    [ "Enedis",[3.1398762868836783, 45.786148057722464]],
    [ "Enedis",[3.125360757439962, 45.78807476353751]],
    [ "Enedis",[3.1274723882235027, 45.785789430091995]],
    [ "Enedis",[3.126376705828485, 45.78171577532792]],
    [ "Enedis",[3.1369800529816763, 45.78231843401747]],
    [ "Enedis",[3.123661049696251, 45.78401288258938]],
    [ "Enedis",[3.1279938006002044, 45.788107818416115]],
    [ "Enedis",[3.121718155930853, 45.77870789872223]],
    [ "Enedis",[4.7282234, 45.745526850000005]],
    [ "Enedis",[0.670968608906852, 49.507954240923155]],
    [ "Enedis",[0.6765486060409015, 49.51448422801603]],
    [ "Enedis",[0.6646910935500208, 49.49595413642629]],
    [ "Enedis",[3.1254992718990815, 45.768604255294214]],
    [ "Enedis",[3.1202300871065503, 45.77560641775431]],
    [ "Enedis",[3.1351628511807355, 45.76956938427014]],
    [ "Enedis",[3.1266138900213827, 45.763479967236876]],
    [ "Rue de Rassat",[3.1201087, 45.76897655]],
    [ "Enedis",[3.1289434690659657, 45.77619703472911]],
    [ "Enedis",[3.1211836568840927, 45.76517628254546]],
    [ "Enedis",[3.1255694976676445, 45.77429154112849]],
    [ "Enedis",[3.133233828012849, 45.775830537118]],
    [ "Enedis",[3.131173602107563, 45.76919343305296]],
    [ "Enedis",[3.1229474012950242, 45.76894494734356]],
    [ "Enedis",[3.1256455197217936, 45.76331705250505]],
    [ "Enedis",[3.1237394592665777, 45.77251177535426]],
    [ "Enedis",[3.122273967400275, 45.7667154562586]],
    [ "Enedis",[3.128293703440124, 45.77973700158964]],
    [ "Enedis",[3.1199489, 45.767398850000006]],
    [ "Enedis",[3.1217191869459238, 45.771048516291266]],
    [ "Enedis",[3.1423245506473245, 45.77907205739634]],
    [ "Enedis",[4.780370337786421, 45.63690219551101]],
    [ "Enedis",[4.7855171743812654, 45.634437253637365]],
    [ "Enedis",[4.786256659514562, 45.63271763006954]],
    [ "Tourtiere",[4.776374129175713, 45.63778477036726]],
    [ "Enedis",[3.1309045793934414, 45.811239186607594]],
    [ "Enedis",[3.112113898607075, 45.816933724114755]],
    [ "Enedis",[3.1414672432281727, 45.761891234388685]],
    [ "Enedis",[3.126198391303953, 45.810884498163965]],
    [ "Enedis",[3.1097950612733705, 45.815652182595]],
    [ "Enedis",[3.1261431309436127, 45.81518942575314]],
    [ "Enedis",[3.1297737424002663, 45.813799374342295]],
    [ "66736524",[3.1312678660883484, 45.764873943372955]],
    [ "Enedis",[3.1439886343163397, 45.76024998229956]],
    [ "Enedis",[3.1065473072107417, 45.81520599197329]],
    [ "Enedis",[3.143394660278518, 45.760566514115254]],
    [ "Enedis",[3.150481096787022, 45.75680191520946]],
    [ "Enedis",[3.132055752302782, 45.811977543966414]],
    [ "Enedis",[3.1342478008710946, 45.76640924605743]],
    [ "Enedis",[-1.76394436803563, 48.03719075172406]],
    [ "Enedis",[3.978064936976698, 50.30370308402192]],
    [ "Enedis",[4.17175055, 43.668321600000006]],
    [ "Enedis",[3.113786122109905, 45.810314893253725]],
    [ "Enedis",[3.1280856539248836, 45.81116594083247]],
    [ "Enedis",[3.114251697149454, 45.81323939233416]],
    [ "Enedis",[3.106170640859993, 45.81243286220847]],
    [ "Enedis",[3.1185737059913543, 45.80896179800288]],
    [ "Enedis",[3.120029281471286, 45.81278621970686]],
    [ "Enedis",[3.1096809564593917, 45.81318486812264]],
    [ "Enedis",[3.1042097472841466, 45.81172205170268]],
    [ "Enedis",[3.122993900278081, 45.81053256282854]],
    [ "Enedis",[3.113001675703477, 45.810646573282334]],
    [ "Enedis",[3.1196808059367105, 45.81475481067196]],
    [ "Enedis",[4.17884305, 43.6631621]],
    [ "Enedis",[4.173257897710442, 43.660098271895464]],
    [ "Enedis",[4.1743099500000005, 43.6642722]],
    [ "Enedis",[4.173472068447046, 43.661651929114676]],
    [ "Enedis",[4.18172722997117, 43.66091084536335]],
    [ "Enedis",[4.176811922312059, 43.66955642759594]],
    [ "Enedis",[4.176355168446171, 43.66215774866283]],
    [ "Enedis",[3.5570368149659872, 47.865977157908176]],
    [ "Enedis",[3.588563561997289, 47.87765901593613]],
    [ "Enedis",[3.1061040497740384, 45.80889312515511]],
    [ "Enedis",[3.1244433731469083, 45.80795794964321]],
    [ "Enedis",[3.11195806821621, 45.80668418142638]],
    [ "Enedis",[3.1175684500000025, 45.8042111]],
    [ "Enedis",[6.644290052031223, 46.40373610824908]],
    [ "Enedis",[6.6594816877149094, 46.40253830225123]],
    [ "Enedis",[6.660728850845705, 46.398130325126964]],
    [ "Enedis",[6.649670125405813, 46.404843893242735]],
    [ "Enedis",[6.654342546160176, 46.400061640451256]],
    [ "Enedis",[6.68162906623009, 46.406484252027084]],
    [ "Enedis",[6.663795456210799, 46.403676505908074]],
    [ "Enedis",[6.6685588592457306, 46.40278785858642]],
    [ "Enedis",[6.691211518706854, 46.40706843027547]],
    [ "Enedis",[6.652734199141016, 46.38538219241231]],
    [ "Enedis",[-1.7619138364311286, 47.96006103538851]],
    [ "Enedis",[-1.77039804120679, 47.98911246643238]],
    [ "Enedis",[-1.805645428764368, 47.99180879353244]],
    [ "Enedis",[-1.7787768549936172, 47.9453267233482]],
    [ "Enedis",[-1.7656730201784956, 47.933166435866106]],
    [ "Enedis",[3.1255257112178962, 45.8068910330412]],
    [ "Enedis",[3.1064091166589303, 45.8071216305547]],
    [ "Enedis",[3.1048901606190964, 45.80603524852907]],
    [ "Enedis",[3.119048323183032, 45.798951513943656]],
    [ "Enedis",[3.1262214322389426, 45.80551186565437]],
    [ "Enedis",[3.1127693228226767, 45.80032564733096]],
    [ "Enedis",[3.108228808140915, 45.80570021341246]],
    [ "Enedis",[3.1302855559179723, 45.79966258220054]],
    [ "Enedis",[3.1300616822739697, 45.799683392443235]],
    [ "Enedis",[3.1179508304481924, 45.80230359741144]],
    [ "Docteur Bousquet",[3.1076452251595468, 45.80229795757472]],
    [ "Enedis",[6.702133685319046, 46.39146793922388]],
    [ "Enedis",[6.730073343249741, 46.39202215442668]],
    [ "Enedis",[6.705256638842384, 46.38925102055943]],
    [ "Enedis",[6.698634309855915, 46.3889631182674]],
    [ "Enedis",[6.737476520298054, 46.39496763349515]],
    [ "Enedis",[6.735315865829988, 46.39459790428731]],
    [ "Enedis",[6.719222930523195, 46.38980847206774]],
    [ "Enedis",[6.736606834201978, 46.39374597884517]],
    [ "Enedis",[6.831773485323831, 46.27096873084084]],
    [ "Enedis",[6.82605965, 46.25655785000001]],
    [ "Enedis",[6.840764002834778, 46.25564027392286]],
    [ "Enedis",[6.83594483489822, 46.26867482569453]],
    [ "Enedis",[6.819344804750947, 46.25431547150872]],
    [ "Enedis",[6.836106159364983, 46.254808591131216]],
    [ "Enedis",[6.814607058469158, 46.252226745444275]],
    [ "Enedis",[6.84158422552385, 46.27588794337771]],
    [ "Enedis",[6.8379173540694875, 46.264241778767904]],
    [ "Petit Châtel",[6.839030967536193, 46.275477676089494]],
    [ "Enedis",[6.829227806217823, 46.27413360051485]],
    [ "Enedis",[3.145672576739685, 45.801740209412486]],
    [ "Enedis",[3.145529333186468, 45.799579908257]],
    [ "Enedis",[3.141807776488882, 45.807367908876756]],
    [ "Enedis",[3.1362738, 45.789409649999996]],
    [ "Enedis",[3.131270988860713, 45.787921781594775]],
    [ "Enedis",[3.122149308739766, 45.79916697187236]],
    [ "Enedis",[3.1367036134280992, 45.79098263000204]],
    [ "Enedis",[3.122662516389469, 45.79755656088465]],
    [ "Enedis",[3.13000103803434, 45.78742893354459]],
    [ "Enedis",[3.1356852471423537, 45.788752948502506]],
    [ "Enedis",[3.1328457149196964, 45.788227402009674]],
    [ "Enedis",[3.1292523131642094, 45.80822920554985]],
    [ "Enedis",[3.145977180566584, 45.795803222869196]],
    [ "Enedis",[3.142615113227339, 45.80730662959038]],
    [ "Enedis",[3.1356415044538184, 45.79062177218138]],
    [ "Enedis",[3.1186713441870277, 45.79156768888348]],
    [ "Enedis",[3.120911285239951, 45.79884783690164]],
    [ "Enedis",[3.1429776523289807, 45.80646157915166]],
    [ "Enedis",[3.1392239851631416, 45.79159787129281]],
    [ "Enedis",[3.1460856887565702, 45.803122448572324]],
    [ "Enedis",[3.1494977765932135, 45.79807398452269]],
    [ "Enedis",[3.119980664362616, 45.785706965454835]],
    [ "Enedis",[3.130702712614998, 45.79014022315491]],
    [ "Enedis",[3.1433110883035273, 45.79208402759788]],
    [ "Enedis",[6.788722612720727, 46.295884358335925]],
    [ "Enedis",[6.780743316540328, 46.293786943515315]],
    [ "Enedis",[6.780546273704988, 46.29406014395677]],
    [ "Enedis",[6.812233828302309, 46.30084587749111]],
    [ "Enedis",[-2.2869840499999996, 47.02355155]],
    [ "Enedis",[6.803051000000001, 46.29427855]],
    [ "Enedis",[6.815028212325186, 46.282646161246575]],
    [ "Enedis",[6.786443556632635, 46.29497107839536]],
    [ "Enedis",[6.804124472681111, 46.294359586925125]],
    [ "Enedis",[6.798889677396152, 46.29444249113469]],
    [ "Enedis",[6.782525784532907, 46.29584636438872]],
    [ "Enedis",[6.7969689804261195, 46.29310000438414]],
    [ "Enedis",[1.0720293923007629, 46.637271895384586]],
    [ "Enedis",[-2.2840422, 47.01384869999999]],
    [ "Enedis",[1.086202282327075, 46.63260835548264]],
    [ "Enedis",[1.0792745452305035, 46.631375137256995]],
    [ "Enedis",[1.0613631652045419, 46.63833239716908]],
    [ "Enedis",[-2.300119874597497, 47.01985000167296]],
    [ "Enedis",[1.0645061467612005, 46.63680349765367]],
    [ "Enedis",[1.0658836694431628, 46.62914214803065]],
    [ "Calvaire",[1.0589300509142827, 46.625476510232026]],
    [ "Enedis",[1.0734115477390989, 46.64113042491177]],
    [ "Enedis",[0.6009833755305057, 49.53215565771131]],
    [ "Enedis",[1.0605446280160746, 46.633652330639755]],
    [ "Enedis",[1.0561837130339515, 46.6265500739583]],
    [ "Enedis",[1.0648265365428864, 46.63380922336938]],
    [ "Enedis",[1.0630026810761097, 46.62958181729503]],
    [ "Enedis",[1.0539863330128927, 46.64192035787721]],
    [ "Enedis",[1.0723723141996528, 46.63066002765609]],
    [ "Enedis",[1.0653129431994983, 46.63088861644137]],
    [ "Enedis",[6.643489313309187, 46.35328000594011]],
    [ "Chez Les Racles",[6.651124231578364, 46.354551264369846]],
    [ "Enedis",[6.692616498526023, 46.359989512068005]],
    [ "Vernay",[6.672621043208786, 46.35671542392144]],
    [ "Fruitière",[6.679244869727693, 46.36155064319404]],
    [ "Enedis",[6.696517444062857, 46.35925954897267]],
    [ "Trossy",[6.687296423438462, 46.35977575322279]],
    [ "Enedis",[6.676269919174011, 46.35882903207058]],
    [ "Enedis",[4.7986775526369545, 45.638028689545024]],
    [ "Enedis",[4.803214349999999, 45.639957200000005]],
    [ "Enedis",[4.8036167, 45.638837099999996]],
    [ "HEURTEVENT",[4.805774250000001, 45.63606740000001]],
    [ "Enedis",[0.6713192495348511, 49.52813344311524]],
    [ "Enedis",[0.6979493909276979, 49.526844475426344]],
    [ "Enedis",[0.664511002943149, 49.529282471971584]],
    [ "Enedis",[0.6617126332743026, 49.52931811540732]],
    [ "Maxilly",[6.633741789804139, 46.398467375564]],
    [ "Enedis",[6.639777524648397, 46.40308001020861]],
    [ "Enedis",[6.6124051999999995, 46.39537450000001]],
    [ "Enedis",[6.600162399999999, 46.396684900000004]],
    [ "Enedis",[6.588606900000001, 46.38674225]],
    [ "Enedis",[5.198767800000001, 45.04060095]],
    [ "Enedis",[5.200965389048597, 45.03889173474789]],
    [ "Enedis",[6.5453337, 46.38719150000001]],
    [ "Crouzille",[3.11632665, 45.76658305000001]],
    [ "Enedis",[3.1178385350233397, 45.79428364205525]],
    [ "Enedis",[6.476483432095467, 46.36774668703009]],
    [ "Enedis",[6.505799148318802, 46.3926522726237]],
    [ "L'Emerillon",[6.484626831484251, 46.369878254471146]],
    [ "Enedis",[6.48812273958441, 46.38097904238]],
    [ "Enedis",[6.496897850194599, 46.38547103166238]],
    [ "Enedis",[6.498374232773218, 46.37714376900004]],
    [ "Enedis",[6.471146477892149, 46.35456677707667]],
    [ "Enedis",[6.486526980112698, 46.37034307484068]],
    [ "Enedis",[6.490462013890656, 46.364046127340096]],
    [ "Enedis",[6.477331619291439, 46.363157334670376]],
    [ "Enedis",[6.477966272686762, 46.36844206801211]],
    [ "Enedis",[6.45660825, 46.36564725]],
    [ "Enedis",[6.448699671487649, 46.36378655679999]],
    [ "Enedis",[6.470812295885245, 46.36395996754296]],
    [ "Enedis",[6.505533075655256, 46.39289968401146]],
    [ "Enedis",[6.502064283249429, 46.38691029968457]],
    [ "Enedis",[6.4525863254449245, 46.36294578389649]],
    [ "Enedis",[6.4547967175566185, 46.36658275648508]],
    [ "Enedis",[6.458875434596486, 46.35988980439335]],
    [ "Enedis",[6.4862312210087, 46.36515626987598]],
    [ "Enedis",[6.491283889442326, 46.36049363313303]],
    [ "Enedis",[6.475804861525656, 46.35832831636329]],
    [ "Enedis",[6.496995149999999, 46.3825038]],
    [ "Vongy",[6.506222733315471, 46.38311225098428]],
    [ "Saint-Disdille",[6.503332567624268, 46.39636441042203]],
    [ "Enedis",[6.495246280647655, 46.38174257409486]],
    [ "Enedis",[6.462989735517853, 46.36324075767596]],
    [ "Enedis",[6.4550214, 46.361438750000005]],
    [ "Enedis",[6.456089901205969, 46.36298658732751]],
    [ "Enedis",[6.486243066762141, 46.3781704686512]],
    [ "Enedis",[6.48120277925846, 46.357004301746315]],
    [ "Enedis",[6.478569028527964, 46.3647922441018]],
    [ "Enedis",[6.483227543030377, 46.36814205964162]],
    [ "Enedis",[6.499223286577039, 46.37502750603926]],
    [ "Enedis",[6.45886233733657, 46.36713948766032]],
    [ "Enedis",[4.048152489214315, 50.32719171666958]],
    [ "Enedis",[6.444455533664574, 46.35041493996687]],
    [ "Enedis",[6.504160529918653, 46.389381362870935]],
    [ "Ranch",[6.50601703232104, 46.380753358896996]],
    [ "Enedis",[6.4904809901465725, 46.37582768914743]],
    [ "Enedis",[6.508128509961874, 46.39573365616793]],
    [ "Enedis",[6.507813234731712, 46.39341099921039]],
    [ "Enedis",[6.4928151299476715, 46.3876749999359]],
    [ "Enedis",[6.50406740798318, 46.391100790683915]],
    [ "Enedis",[6.5004686171646044, 46.3841967634916]],
    [ "Enedis",[6.490476801751762, 46.381544498579885]],
    [ "Enedis",[6.505617264273527, 46.38919931204973]],
    [ "Enedis",[6.498180058843014, 46.38732651752099]],
    [ "Enedis",[6.483743469146334, 46.385421902440854]],
    [ "Enedis",[6.467026201197097, 46.35690005494234]],
    [ "Enedis",[6.487930223761019, 46.378789714389484]],
    [ "Enedis",[6.472565429393765, 46.358683184774556]],
    [ "Enedis",[6.4836674146958915, 46.38048450146312]],
    [ "Enedis",[6.47805125483024, 46.36067670690459]],
    [ "Enedis",[6.4873248000000014, 46.37570240000001]],
    [ "Enedis",[6.503826214191196, 46.366302402128134]],
    [ "Enedis",[6.483758074423497, 46.36113458343992]],
    [ "Enedis",[6.487539613115676, 46.367451969582746]],
    [ "Enedis",[6.470018111789785, 46.357547408128504]],
    [ "Enedis",[6.4967676368232485, 46.3728173544891]],
    [ "Enedis",[6.498860680154894, 46.36723386728592]],
    [ "Enedis",[6.457861026005497, 46.35660614529683]],
    [ "Enedis",[6.457988920289069, 46.364735022086194]],
    [ "Enedis",[6.493447745563203, 46.38584984276141]],
    [ "Enedis",[6.4641345999999995, 46.3579128]],
    [ "Corniche 144",[6.450799963072143, 46.361004461912884]],
    [ "Enedis",[6.491329820380641, 46.365525943558616]],
    [ "Enedis",[6.489384328238928, 46.38419021093065]],
    [ "Enedis",[6.507783786360439, 46.39741604071634]],
    [ "Enedis",[6.479810587188473, 46.37552637000164]],
    [ "Enedis",[6.4721240905298565, 46.3679732063547]],
    [ "Enedis",[6.5011992, 46.369976550000004]],
    [ "Enedis",[6.44747696959673, 46.35899745348726]],
    [ "Enedis",[6.4953726666334335, 46.35960812684398]],
    [ "Enedis",[6.50520052716123, 46.39186833961529]],
    [ "Enedis",[6.466852291484402, 46.3681938870144]],
    [ "Sarre Distribution",[3.1152810791550243, 45.76937787432896]],
    [ "Jacques Prévert",[3.11525655, 45.7698654]],
    [ "St Thérèse",[3.1128223999999998, 45.79620049999999]],
    [ "Enedis",[3.1123722159898186, 45.79817020306965]],
    [ "Enedis",[3.113105838752139, 45.79775702450825]],
    [ "Enedis",[3.1151783213018076, 45.79614789947291]],
    [ "Enedis",[3.1186529032734747, 45.79634963725936]],
    [ "Enedis",[3.1157630019900497, 45.796569054157786]],
    [ "Enedis",[3.1179509738743336, 45.79732797977958]],
    [ "Enedis",[3.109012860950007, 45.775531521250834]],
    [ "Enedis",[3.109531269330876, 45.776596937562765]],
    [ "Enedis",[-0.6165570000000001, 45.75906465]],
    [ "Enedis",[3.1123032186230635, 45.77942215276869]],
    [ "Enedis",[3.1064259274939166, 45.77050757250608]],
    [ "Enedis",[3.1082773717889713, 45.777652182834736]],
    [ "Enedis",[3.108678272097405, 45.765133570075164]],
    [ "Enedis",[3.1082318518766905, 45.769990091520086]],
    [ "Enedis",[-0.6260250121982316, 45.74586352853144]],
    [ "Enedis",[-0.6039567925218803, 45.74967308667772]],
    [ "Enedis",[4.942539400000001, 44.96371705000001]],
    [ "Enedis",[4.9363336346890385, 44.95805474935615]],
    [ "Enedis",[4.958948879818859, 44.9676193554055]],
    [ "Enedis",[4.057002442088324, 50.289579599703465]],
    [ "Enedis",[4.040328949780586, 50.29016217927869]],
    [ "Enedis",[4.030420968883408, 50.286942532312096]],
    [ "Enedis",[7.509001131010298, 48.53389029860777]],
    [ "Enedis",[6.528236182842359, 46.37550175888219]],
    [ "Enedis",[6.52731825, 46.380952350000015]],
    [ "Enedis",[6.511634908935356, 46.38059647485173]],
    [ "Enedis",[6.520016940117163, 46.37408801489712]],
    [ "Enedis",[6.533420591472396, 46.3861745702861]],
    [ "Enedis",[6.5214756980134405, 46.37709510356413]],
    [ "Enedis",[6.53381513863383, 46.376669081051176]],
    [ "Enedis",[6.518031257796506, 46.378862383350096]],
    [ "Enedis",[6.5239638499999995, 46.37532315]],
    [ "Enedis",[6.526833638601862, 46.384555487952255]],
    [ "Planchettes",[3.107471227820823, 45.79788991068838]],
    [ "Enedis",[3.1064034379837158, 45.77286286536118]],
    [ "Enedis",[3.105145472518123, 45.80140185733429]],
    [ "Enedis",[3.1076206792971246, 45.79938891106385]],
    [ "Enedis",[3.1058480499999996, 45.7810055]],
    [ "Enedis",[3.1148827792794087, 45.78432959953822]],
    [ "Enedis",[3.107759027950041, 45.78036983658549]],
    [ "Enedis",[3.1121249332008816, 45.782163074058964]],
    [ "Enedis",[3.1098228477747507, 45.791679628519525]],
    [ "Enedis",[3.115398837931864, 45.78260476443297]],
    [ "Enedis",[4.059322664256232, 50.282386444667466]],
    [ "Enedis",[3.107189254506863, 45.78862966596222]],
    [ "Enedis",[3.1036443197976493, 45.78912664663718]],
    [ "Enedis",[3.10303546900112, 45.77316998139856]],
    [ "Enedis",[3.1086608935915354, 45.79302798528835]],
    [ "Enedis",[4.073335590258253, 50.298611154074614]],
    [ "Enedis",[4.075291666358035, 50.28933878660537]],
    [ "Enedis",[6.556865652850636, 46.37679083020149]],
    [ "Enedis",[6.558204181881223, 46.370059370263284]],
    [ "Enedis",[7.100667750000001, 48.0698158]],
    [ "Enedis",[3.1020646285532494, 45.766089260433496]],
    [ "Enedis",[3.102217375634957, 45.770232936108314]],
    [ "Enedis",[3.10164905, 45.764883250000004]],
    [ "Enedis",[3.10332305, 45.7643519]],
    [ "Enedis",[3.102278377379017, 45.77128138735778]],
    [ "Enedis",[3.1034990941742184, 45.798518897528375]],
    [ "Enedis",[3.100358376426697, 45.79534432414826]],
    [ "Paix",[2.3581509443386794, 48.80495284973185]],
    [ "DP Forteresse",[2.350229235000416, 48.80471684599143]],
    [ "Malon",[2.3496042, 48.8042109]],
    [ "Enedis",[0.7244196522921855, 49.50798948812543]],
    [ "Enedis",[3.0956605734718154, 45.791866668202125]],
    [ "Enedis",[3.0773133174801943, 45.78589496567673]],
    [ "Enedis",[3.0826329613743377, 45.78363008329588]],
    [ "Enedis",[3.0779091364477025, 45.78674033535082]],
    [ "Enedis",[3.079922944161158, 45.784337536782225]],
    [ "Enedis",[2.2546484329989553, 48.925608208871125]],
    [ "Enedis",[3.094055792992342, 45.786094382508885]],
    [ "Enedis",[3.067508667576498, 45.78362584484566]],
    [ "Enedis",[3.088611175214866, 45.786202010231776]],
    [ "Enedis",[3.0674302033659995, 45.7875949301885]],
    [ "Enedis",[3.0862600632187225, 45.78484408326582]],
    [ "Enedis",[3.0846459908622315, 45.785353779215285]],
    [ "Enedis",[3.100370810684299, 45.78771648163581]],
    [ "Enedis",[3.09099230930904, 45.78681476742229]],
    [ "Enedis",[3.0684772579838806, 45.785293279391624]],
    [ "Enedis",[0.7705698604749264, 49.4795710259315]],
    [ "Enedis",[0.7721936731933748, 49.48297774435017]],
    [ "Enedis",[0.7719711064143994, 49.48161579851247]],
    [ "Enedis",[3.538938506857986, 47.930327303201985]],
    [ "Enedis",[3.073199629847932, 45.78172620959172]],
    [ "Enedis",[3.0710301828061612, 45.781337038155876]],
    [ "Enedis",[3.076028281403203, 45.78681149836794]],
    [ "Enedis",[3.0709241440649744, 45.7890674969922]],
    [ "Enedis",[3.072161246922396, 45.780219663881354]],
    [ "Enedis",[3.0660811838058097, 45.78473201494623]],
    [ "Enedis",[-1.2274682499999998, 43.694674649999996]],
    [ "Enedis",[-1.2279684520646486, 43.69086484843888]],
    [ "Enedis",[-1.2379455070929364, 43.682901656056686]],
    [ "Enedis",[-1.2348852935625898, 43.68140167443754]],
    [ "Enedis",[-1.2356759223900962, 43.68519727522422]],
    [ "Enedis",[3.1034687971198602, 45.78126253131144]],
    [ "Enedis",[3.0771913180054384, 45.781106150490544]],
    [ "Enedis",[3.334454648013315, 47.85161449936247]],
    [ "Enedis",[0.9662911071005311, 47.7863555629274]],
    [ "Enedis",[0.962024222771728, 47.79585020699352]],
    [ "Enedis",[-1.7735397439230938, 48.1293343346918]],
    [ "Enedis",[-1.7648947581693382, 48.14048368615923]],
    [ "Enedis",[-1.8083171, 48.1606448]],
    [ "Enedis",[0.9144511134906591, 47.80962424763144]],
    [ "Enedis",[2.2581468388576, 48.9243933570523]],
    [ "CCAS",[0.18056647675985, 48.00062497449353]],
    [ "Les Grandes Courbes",[0.1713347484136043, 47.97911827830847]],
    [ "Quéru",[0.1830146424256208, 48.01600092724879]],
    [ "Pierre Martin n°2",[0.1729103427750989, 47.96962885083601]],
    [ "Infanterie",[0.17957182062923951, 48.01185723263002]],
    [ "Culterie",[0.16685772173334917, 47.98830251650365]],
    [ "Étamines",[0.18893435379958834, 48.01750001009465]],
    [ "St Georges",[0.1636346966796958, 47.98599245053453]],
    [ "Baudelaire",[0.16830966085577106, 47.99973155400116]],
    [ "Trianon",[0.18682580937936766, 48.01315562910813]],
    [ "OTV",[0.17191608465660196, 47.982243202867956]],
    [ "Poste Bâtiment A",[0.158982001324869, 48.01380807635125]],
    [ "Béranger",[0.18914367422960393, 48.00371784960631]],
    [ "Marcel Pagnol",[0.16729614049182864, 48.01101461985227]],
    [ "Équinoxe 1",[0.1877578638218931, 48.00521313093732]],
    [ "Enclos",[0.2043319, 48.01346854999999]],
    [ "Enedis",[-1.272907, 43.583458549999996]],
    [ "Enedis",[0.19443108833115974, 48.000366258796724]],
    [ "Enedis",[-1.2729793851088596, 43.57901922714204]],
    [ "Renard",[0.18382696419856268, 48.02653134248767]],
    [ "Grand Colombier",[0.16565078480264397, 47.991128579791074]],
    [ "Moulin l'Évêque",[0.18758972760383064, 48.02306617589785]],
    [ "Laroche",[0.19095749436226916, 48.008076771716254]],
    [ "Ballon",[0.20318863207852106, 48.01972512227784]],
    [ "Lefaucheux",[0.1773564136733751, 47.96072164671927]],
    [ "Garage Berliet",[0.17999545696892907, 47.95515937808946]],
    [ "Amsterdam",[0.2256815601387453, 48.02110143545093]],
    [ "Eichthal",[0.18437338148956636, 48.00031140846606]],
    [ "Bourges",[0.17914297338299986, 48.00490129569729]],
    [ "Espelette",[0.16294116001325842, 47.98869742091129]],
    [ "Côteaux de la Gironde",[0.21394078297321037, 48.02260833246966]],
    [ "Raynal",[0.18705318118504927, 48.00089904901051]],
    [ "Portes de l'Atlantique",[0.1559415579301979, 48.01563138100252]],
    [ "PMU",[0.17518998374906555, 47.975700299639726]],
    [ "Le Léard",[0.18627137304524483, 48.02056828651792]],
    [ "Morancé",[0.1810958940528174, 48.002582229828036]],
    [ "Laval",[0.171929895955144, 48.0135949286372]],
    [ "Enedis",[0.15781039332591787, 48.0210064993893]],
    [ "Union Set Le Mans",[0.16296749999999963, 48.00915005]],
    [ "Carnot",[0.18055152567320013, 48.006926590182864]],
    [ "Coq Hardi",[0.1860120083345382, 48.01521358610115]],
    [ "Cosmonautes",[0.178705465144864, 47.984944938659645]],
    [ "Hôtel de Ville",[0.19719324347055775, 48.00722405804713]],
    [ "Lycée Montesquieu",[0.20048995439963424, 48.01120419764237]],
    [ "Saint-Lazare",[0.1817587844346251, 47.99966650030549]],
    [ "Anémones",[0.22026580381638367, 48.01875438922008]],
    [ "Beaugé",[0.17514702987756844, 48.009681148314705]],
    [ "Tabur",[0.17866327225068585, 47.95665400381231]],
    [ "Lamarck",[0.1619757166032538, 48.02132755590289]],
    [ "Schweitzer",[0.1824684727983886, 48.00637285015889]],
    [ "Allégret",[0.1638854677330758, 48.0122430485495]],
    [ "Gendarmerie",[0.17797238720657563, 48.01046675364986]],
    [ "Bessemer",[0.16954643378903167, 47.97332402714792]],
    [ "Gravor",[0.1499221252147733, 48.01498456602631]],
    [ "Marceau",[0.18106351326244377, 48.01022362666651]],
    [ "Maisons blanches",[0.1758095391290471, 48.023047055836486]],
    [ "Enedis",[0.19793900000000003, 48.01377165]],
    [ "Seripharm",[0.15394279874396227, 48.01743696404317]],
    [ "Bon Pasteur",[0.19590012525645326, 48.01257094128931]],
    [ "Enedis",[0.17124392563703975, 47.97933414583878]],
    [ "Palmiers",[0.2019203374977674, 48.01732917510014]],
    [ "Wagram",[0.1957040063385916, 47.99954168180614]],
    [ "Enedis",[0.17277184866436077, 48.03131333267268]],
    [ "Petites Gallières",[0.1863705116719082, 48.0272403765853]],
    [ "Docks Fouquet",[0.17691146215771508, 47.97208913253887]],
    [ "PTT Foucaudière",[0.19125325, 47.97364865]],
    [ "Bartholdi",[0.1596970572542389, 48.01934524680814]],
    [ "Pré",[0.19374771857909115, 48.01151140903113]],
    [ "Enedis",[0.16238714999999995, 48.01061179999999]],
    [ "Hector Berlioz",[0.15365775, 48.01298294999999]],
    [ "Maillets Fontenelles",[0.21457234832391675, 48.01669073512579]],
    [ "Édith Piaf",[0.1545208953854635, 48.02038027165244]],
    [ "Foresterie 1",[0.14383801177241892, 48.0155551747962]],
    [ "Delagenière 1",[0.20228485, 48.014240449999996]],
    [ "Enedis",[0.18091522114819242, 48.01348889652721]],
    [ "Aster",[0.1536812708919196, 48.01742409769177]],
    [ "Duhamel",[0.18162080000000003, 48.02610514999999]],
    [ "CUM Rocade",[0.17975089963323312, 47.98769273335456]],
    [ "Louis Bréguet",[0.16691068791131844, 47.97676810257489]],
    [ "Poste Épuration",[0.1786828039738592, 47.95661024695662]],
    [ "Petits Étangs",[0.18332464825740252, 47.95376647580492]],
    [ "Pipêche",[0.17498115677902473, 48.00207171858811]],
    [ "Dorée",[0.1939914608620891, 48.006015702618896]],
    [ "Compain Laurent",[0.17745371784385994, 48.0012149833312]],
    [ "Balyver",[0.1725188564349336, 48.010811477214986]],
    [ "Le Palis",[0.18357216454271105, 48.02416870698937]],
    [ "Enedis",[0.1819512372005092, 47.970956748457034]],
    [ "Ambroise Paré",[0.1779699191836203, 48.00439346609632]],
    [ "Enedis",[0.15827369946728784, 48.012463265663925]],
    [ "Champs Elysées",[0.18372539999999998, 48.001799199999994]],
    [ "Enedis",[0.17675177683703613, 48.02736987403058]],
    [ "Bouverie",[0.15213412528545617, 48.021968638459455]],
    [ "Ducreux",[0.1822102792696387, 48.00905761048885]],
    [ "Résidence Libération",[0.1870655989082014, 48.00493594088549]],
    [ "Sargé Fontenelles",[0.21380978580423846, 48.016833259739975]],
    [ "Delagenière 2",[0.20324706726068856, 48.016633739680046]],
    [ "Huet Lanoe",[0.18436716688754942, 47.96125297838692]],
    [ "BMW",[0.19199758184388632, 47.981674734846585]],
    [ "Montsaulnière",[0.18010754734504894, 48.021437550003164]],
    [ "Les Gentianes",[0.17077462734618265, 47.98636264113752]],
    [ "Campanule",[0.170934983550059, 47.98841130274919]],
    [ "Ohmic GT",[0.1806876701917238, 48.02644795511133]],
    [ "Briqueterie",[0.1671565698149262, 47.992458348126625]],
    [ "Sablé",[0.15861806927074018, 47.98423671267409]],
    [ "N°4 Piffault",[0.18348453304863543, 47.98355014731179]],
    [ "Fleming",[0.18668291205262225, 48.024728269605035]],
    [ "Mexico",[0.2105021943239676, 48.0209407578289]],
    [ "Stade Claircigny",[0.19181752407398267, 48.01443803739682]],
    [ "Enedis",[0.19308291263401395, 47.99691445542075]],
    [ "Foussier",[0.17813596868700893, 47.9605318735102]],
    [ "Saint-Victeur",[0.1923139976818393, 48.008510134138284]],
    [ "Triage",[0.18085799424037557, 47.96906303481741]],
    [ "SSR2 Klotz",[0.16617350509014364, 48.01917080204312]],
    [ "Banjean",[0.20533800803263857, 48.01676574479264]],
    [ "Edison 7",[0.18036202356617215, 48.02501922031046]],
    [ "Aloès",[0.17325957637149092, 47.99011127562614]],
    [ "Gravor Color",[0.14869753071914152, 48.01531399860193]],
    [ "Épine 1",[0.18178834026407997, 48.019301305724014]],
    [ "Centre de Transfert de Technologie",[0.15993160944558762, 48.01886007566879]],
    [ "La Teillaie",[0.17005634771221737, 48.02288009585199]],
    [ "Popope",[0.1697008778655445, 47.998238939478085]],
    [ "Ledru Rollin",[0.19485405188632643, 48.01056758137449]],
    [ "Luxembourg",[0.20878910139679566, 48.01821411630735]],
    [ "Roussel",[0.17734319999999998, 47.96076540000001]],
    [ "SSR3 Hôpital",[0.17508231033446203, 48.013977286474656]],
    [ "Bichat",[0.16525426515781966, 48.020399515609924]],
    [ "Ardriers",[0.17078279285764997, 48.001290977828006]],
    [ "Becquerel",[0.1826130371323574, 47.96457445127641]],
    [ "AFP",[0.15961446309165, 48.01454559514358]],
    [ "Budapest",[0.2065615500000004, 48.020621]],
    [ "Cuisine Centrale",[0.18137624504230035, 47.95812632912732]],
    [ "Georges Méliès",[0.16137092518963966, 48.013815090718914]],
    [ "Le Gravier",[0.1733859380232056, 48.022106449418565]],
    [ "Jacques Brel",[0.14940965590827143, 48.01892948763469]],
    [ "Jacquard",[0.1802988633464948, 47.967035221135355]],
    [ "Résidence Saint-Lazare",[0.18086217392718903, 48.00029434357602]],
    [ "Montbarbet",[0.20128547618864262, 48.01133108340993]],
    [ "Angers Desserte",[0.19763095561217547, 47.95599347673685]],
    [ "Enedis",[0.1816965128614306, 47.99970493730255]],
    [ "Piffault",[0.18595658325223127, 47.96148100975455]],
    [ "Enedis",[0.15851677035464404, 47.98491206097306]],
    [ "n°2 rue Fleury",[0.186165878404455, 48.009616407741944]],
    [ "Enedis",[0.19282434464729614, 48.00112825192125]],
    [ "Enedis",[0.17464739389456846, 48.03185977965732]],
    [ "Petites Courbes",[0.17177817321027658, 47.97428341906876]],
    [ "Sargé 2",[0.2151491349470145, 48.02010563079334]],
    [ "Bourdonnière",[0.16912207953522973, 47.979010067104774]],
    [ "Enedis",[0.1846730680703585, 48.011356601929585]],
    [ "Enedis",[0.17554338420740928, 48.01017201346461]],
    [ "Enedis",[3.6023693388259597, 47.92911883142625]],
    [ "Enedis",[3.479746973764806, 47.994860902442234]],
    [ "Enedis",[4.837112852832103, 45.69618951065752]],
    [ "Enedis",[4.822122723085249, 45.711352348468466]],
    [ "Enedis",[4.807014645309468, 45.70439588222972]],
    [ "Enedis",[3.4812402196008314, 47.96740102389929]],
    [ "Enedis",[3.4767892021505378, 47.967362678763436]],
    [ "Enedis",[3.4811240220945763, 47.96499711226724]],
    [ "Enedis",[3.4848256218540823, 47.96546987983269]],
    [ "Enedis",[3.4947080894925606, 47.963360487317765]],
    [ "Enedis",[3.471004227071506, 47.96761444203901]],
    [ "Enedis",[3.486222565015574, 47.9641087028838]],
    [ "Enedis",[3.4828738133931543, 47.97016762871769]],
    [ "Enedis",[3.0759056891568264, 45.77799319672658]],
    [ "Enedis",[3.09414136479535, 45.77369325969999]],
    [ "Enedis",[1.086097466754287, 46.2136170315463]],
    [ "Enedis",[1.08063957525445, 46.21693574289368]],
    [ "Enedis",[3.4930309389083507, 43.608853685445666]],
    [ "Enedis",[3.4853361, 43.607361149999996]],
    [ "Enedis",[3.4891597560549292, 43.60141463832729]],
    [ "Enedis",[3.491756467002559, 43.599761317791724]],
    [ "Enedis",[0.3564327298603097, 42.897803009324825]],
    [ "Enedis",[3.0768471717652366, 45.7781426239773]],
    [ "Enedis",[3.4924275994498495, 47.94239652205526]],
    [ "Enedis",[3.4982645231603744, 47.952234767724164]],
    [ "Enedis",[3.4970902692897154, 47.936943458399554]],
    [ "Enedis",[5.358363487448087, 43.421827488206254]],
    [ "Enedis",[5.355795535273524, 43.46633634350109]],
    [ "Enedis",[5.35057195, 43.44622524999999]],
    [ "Enedis",[5.38061510652793, 43.42669362890573]],
    [ "Enedis",[5.335997827491524, 43.44648209513042]],
    [ "Enedis",[5.333013538162544, 43.45738743816255]],
    [ "Enedis",[5.38190035, 43.431218750000006]],
    [ "Enedis",[5.356517745911429, 43.46307906947561]],
    [ "Enedis",[5.373687645897757, 43.424322984499305]],
    [ "Enedis",[5.364389149999999, 43.42287185]],
    [ "Enedis",[5.357055710057237, 43.42059366936496]],
    [ "Enedis",[5.365688029964982, 43.42566724806744]],
    [ "Enedis",[5.3631838744310985, 43.419895311578955]],
    [ "Enedis",[5.337679297536999, 43.469460360555985]],
    [ "Enedis",[5.375521085720051, 43.42345223082972]],
    [ "Enedis",[5.354321100000001, 43.446322200000004]],
    [ "Enedis",[5.354788079905772, 43.468480858408626]],
    [ "Enedis",[5.357277600000001, 43.4455631]],
    [ "Enedis",[5.3676970055781075, 43.42315306361449]],
    [ "Enedis",[5.3836443648889905, 43.42858780294869]],
    [ "Enedis",[5.3355470504482065, 43.44784611654596]],
    [ "Enedis",[5.3556156064472145, 43.46912077989871]],
    [ "Enedis",[5.3296282539866295, 43.45666597729663]],
    [ "NY Victor Noir 9",[2.26394889774992, 48.88209255532521]],
    [ "Enedis",[5.331227792556983, 43.44978419489321]],
    [ "Enedis",[5.332811416802996, 43.43365696403592]],
    [ "Enedis",[5.359217844484833, 43.41923797613949]],
    [ "Enedis",[5.336207767883874, 43.47004128854978]],
    [ "Enedis",[5.331905428560011, 43.44568494965606]],
    [ "Enedis",[5.354038671950499, 43.46266475717953]],
    [ "Enedis",[5.377889199999999, 43.4260126]],
    [ "Enedis",[3.0766844516835414, 45.77261389053511]],
    [ "Enedis",[3.098427159435627, 45.773284860846566]],
    [ "Enedis",[3.095769050499172, 45.77159985878426]],
    [ "Poste électrique Pasquier",[2.3231000597800433, 48.872462265659756]],
    [ "Enedis",[-2.2534319500000013, 47.02526780000001]],
    [ "Enedis",[3.094082438178503, 45.76780988927884]],
    [ "Enedis",[3.095578294589207, 45.7703812364506]],
    [ "Enedis",[3.077623435000067, 45.76739592889931]],
    [ "Enedis",[3.081073063290002, 45.76859362484815]],
    [ "Enedis",[3.07956146331256, 45.76872984208517]],
    [ "Enedis",[3.08477991065958, 45.76641724373997]],
    [ "Enedis",[3.098423692270829, 45.76593879739699]],
    [ "Enedis",[3.0953162517373363, 45.76798877697211]],
    [ "Enedis",[3.088298391326241, 45.767519491621755]],
    [ "Enedis",[3.0984355182788623, 45.77055669815992]],
    [ "Enedis",[3.0923143410181115, 45.766751518769745]],
    [ "Enedis",[3.096855608769279, 45.76677734470754]],
    [ "Enedis",[3.087909930826019, 45.76620098472828]],
    [ "Enedis",[3.075086233858172, 45.76715482655516]],
    [ "Enedis",[3.0799403855711915, 45.762899322825014]],
    [ "Enedis",[3.083242415715635, 45.764527017648334]],
    [ "Enedis",[3.078034217468067, 45.76607312941456]],
    [ "Enedis",[3.0792514366150887, 45.76725694393092]],
    [ "Enedis",[3.0822197577129407, 45.76091440795473]],
    [ "Enedis",[3.4746877259989284, 47.94861229807232]],
    [ "Enedis",[3.0836573887642844, 45.75924442982428]],
    [ "Enedis",[3.0939219724296456, 45.759157961379266]],
    [ "Enedis",[3.0898453999999993, 45.7644543]],
    [ "Enedis",[3.089808357215853, 45.76315204987174]],
    [ "Enedis",[3.0913064541658657, 45.76347907684419]],
    [ "Enedis",[3.075088035697546, 45.75975563322294]],
    [ "Enedis",[3.093396159953188, 45.76184376653078]],
    [ "Enedis",[3.0971972837212625, 45.760088782966044]],
    [ "Enedis",[3.1029479135408757, 45.81474507479118]],
    [ "Enedis",[3.0970885024039485, 45.7629304766257]],
    [ "Enedis",[3.0998353520569766, 45.76396138501233]],
    [ "Enedis",[3.09770555, 45.764259700000004]],
    [ "Enedis",[3.078851142921225, 45.75686492036929]],
    [ "Enedis",[3.0983290824927323, 45.760502885816265]],
    [ "Enedis",[3.1002441895821837, 45.81425170614043]],
    [ "Enedis",[3.098654787906545, 45.808466332975875]],
    [ "Enedis",[3.088605936696015, 45.795876077528206]],
    [ "Enedis",[3.1022456, 45.80646999999999]],
    [ "Enedis",[3.1013206500000003, 45.808537449999996]],
    [ "Enedis",[3.082954686959076, 45.79617745265226]],
    [ "Enedis",[3.0859422733920847, 45.802706335994664]],
    [ "Enedis",[3.1016597380666147, 45.80771741614954]],
    [ "Enedis",[3.0842825209148588, 45.80148153085983]],
    [ "Enedis",[3.0775301812982008, 45.795075134890766]],
    [ "Enedis",[3.078520209750086, 45.796361329008874]],
    [ "Enedis",[3.076415558372258, 45.796959368878554]],
    [ "Enedis",[3.0735685189085453, 45.79634804158745]],
    [ "Enedis",[3.079479766491252, 45.797563116575795]],
    [ "Enedis",[3.0819402750113998, 45.79965040428637]],
    [ "Enedis",[3.078772742163696, 45.793568514817046]],
    [ "Enedis",[3.0743804455572623, 45.793207094118436]],
    [ "Enedis",[3.0649071641849948, 45.791976911764344]],
    [ "Enedis",[3.076318866369226, 45.79359877607765]],
    [ "Enedis",[3.070269923718349, 45.79297499678239]],
    [ "Enedis",[3.0870254824564096, 45.79286107166458]],
    [ "Enedis",[3.088766158794977, 45.79336235848474]],
    [ "Enedis",[3.082892457142036, 45.790878200372475]],
    [ "Enedis",[3.076334617515288, 45.791751525781706]],
    [ "Enedis",[3.0799638701121994, 45.79084910666142]],
    [ "Enedis",[3.085714258716596, 45.79781839989811]],
    [ "Enedis",[3.0693087992317545, 45.79109244424295]],
    [ "Enedis",[3.078231641499278, 45.79019504687398]],
    [ "Enedis",[1.5849060078338517, 46.234227329047755]],
    [ "RTE",[3.0988723967772125, 45.79109575933657]],
    [ "Enedis",[3.0933569912646703, 45.7898955481291]],
    [ "Enedis",[3.09827431633905, 45.789239376313326]],
    [ "Enedis",[3.063503792043083, 45.7890009119741]],
    [ "Enedis",[3.093957973276273, 45.790867246820305]],
    [ "Enedis",[3.0554167476543137, 45.78905887500097]],
    [ "Enedis",[0.7800606775097273, 47.389198331022534]],
    [ "Enedis",[0.775687408395036, 47.38909389536565]],
    [ "Enedis",[0.7845948869215745, 47.39352379278809]],
    [ "Enedis",[0.7664085811942789, 47.38258597520687]],
    [ "Enedis",[0.785422489753177, 47.392240181298966]],
    [ "Enedis",[0.7655435013339366, 47.39448611766605]],
    [ "Enedis",[0.7697626803293539, 47.38873122934005]],
    [ "Enedis",[0.7737986174456623, 47.38894461888825]],
    [ "Enedis",[0.7685669193262585, 47.386711558954765]],
    [ "Enedis",[0.766636302540672, 47.38889647745062]],
    [ "Enedis",[0.7603168567545097, 47.397384388366255]],
    [ "Enedis",[0.7783855471955996, 47.39447115204775]],
    [ "Enedis",[1.50743315, 46.278701500000004]],
    [ "Enedis",[0.7649801029179135, 47.38808313638367]],
    [ "Enedis",[0.7683459164892268, 47.382400699092095]],
    [ "Enedis",[0.7723861748883459, 47.39533960192197]],
    [ "Enedis",[1.5095232618914247, 46.27740171950549]],
    [ "Enedis",[1.6228862999999998, 46.38287065]],
    [ "Enedis",[0.9176641997111354, 47.596422059449175]],
    [ "Enedis",[0.9128299768850133, 47.59363982233981]],
    [ "Enedis",[0.8963261692966097, 47.58691163298707]],
    [ "Enedis",[0.9212299104323337, 47.59840824685815]],
    [ "Enedis",[0.9209976187023579, 47.600506664631865]],
    [ "Enedis",[0.9165325112143028, 47.5915925030973]],
    [ "Enedis",[0.9065816594042378, 47.58920987529933]],
    [ "Enedis",[1.6620442296727675, 46.30790334603439]],
    [ "Enedis",[0.8995064652327209, 47.59371960181862]],
    [ "Enedis",[0.9183847556761882, 47.593014456324255]],
    [ "Enedis",[0.9054930133901374, 47.590868041354135]],
    [ "Enedis",[0.9171829384033663, 47.58854952551323]],
    [ "Enedis",[0.9176026246998328, 47.59002035898851]],
    [ "Enedis",[0.9096905534542553, 47.59118113487708]],
    [ "Enedis",[0.8955333168968184, 47.59388044753238]],
    [ "Enedis",[0.8948401055229426, 47.58964227450682]],
    [ "Enedis",[0.9009925054597239, 47.5947609887238]],
    [ "Enedis",[0.8915116057749929, 47.590868194978924]],
    [ "Enedis",[0.9036361289954586, 47.591956821266]],
    [ "Enedis",[0.9027717335431098, 47.58796958378522]],
    [ "Enedis",[0.9124469117157857, 47.59122913029755]],
    [ "Enedis",[0.89587423550035, 47.59221391500918]],
    [ "Enedis",[3.087334651091862, 45.791612634310106]],
    [ "Enedis",[3.0601877470378755, 45.78681147632926]],
    [ "Enedis",[1.4667704063467746, 46.35413562742644]],
    [ "Enedis",[3.087434599999999, 45.788061400000004]],
    [ "Enedis",[5.353621788632018, 43.335995600914686]],
    [ "Enedis",[1.8606710500000012, 46.172729200000006]],
    [ "Enedis",[1.8720660240895803, 46.19295980612901]],
    [ "Enedis",[1.86505985, 46.17996895]],
    [ "Enedis",[1.8816642355017215, 46.18316926691661]],
    [ "Enedis",[1.8660131822112647, 46.176573949168265]],
    [ "Enedis",[1.86716699037105, 46.16047835404571]],
    [ "Enedis",[1.8909726389175359, 46.18290533422264]],
    [ "Enedis",[1.8650205006821565, 46.18127395040713]],
    [ "Enedis",[1.8769493499999998, 46.17718715]],
    [ "Enedis",[1.8817169500000002, 46.1903644]],
    [ "Enedis",[1.8724270749687109, 46.192978655569455]],
    [ "Enedis",[1.8747093841254714, 46.19270276778115]],
    [ "Enedis",[1.861193, 46.17626224999999]],
    [ "Enedis",[1.8613147757428432, 46.178976345251066]],
    [ "Enedis",[1.86853505, 46.1720238]],
    [ "Enedis",[1.4703212303746866, 43.655727094891716]],
    [ "Enedis",[-0.7674796863757858, 48.07516730736046]],
    [ "Enedis",[-0.7823209560144685, 48.084244065789754]],
    [ "Enedis",[-0.7632020064318595, 48.05340758236593]],
    [ "Enedis",[-0.7627031215865068, 48.0718229770289]],
    [ "Mazagran",[-0.7681904500000001, 48.069658800000006]],
    [ "Enedis",[-0.764424669214192, 48.073316162203824]],
    [ "Enedis",[-0.7413692000000001, 48.03788385]],
    [ "Enedis",[-0.7677044186090045, 48.06043090981422]],
    [ "Enedis",[-0.7588554013659219, 48.07517501094011]],
    [ "Enedis",[-0.7677554228634381, 48.07353407192679]],
    [ "Enedis",[-0.7617845355707754, 48.0739789216281]],
    [ "Enedis",[-0.7891875159807195, 48.06229518641576]],
    [ "Enedis",[-0.7607110923492723, 48.07089383221761]],
    [ "Enedis",[-0.7638020203170687, 48.056262948944216]],
    [ "Enedis",[-0.7561476166521052, 48.07250442818957]],
    [ "Dacterie",[-0.7754796808996003, 48.061449360980674]],
    [ "Enedis",[4.608737621742983, 44.71844650047063]],
    [ "Enedis",[0.7134816003767472, 45.618194574902944]],
    [ "Enedis",[1.47353305, 43.66553825]],
    [ "Enedis",[1.4728072849995333, 43.66196521865318]],
    [ "Enedis",[1.4600224000000002, 43.66374235]],
    [ "Enedis",[1.4720392973092309, 43.65111853241117]],
    [ "Enedis",[1.4861934024028847, 43.66150265432434]],
    [ "Enedis",[1.4894443968814852, 43.66327086161311]],
    [ "Enedis",[1.4752220910274692, 43.665240607206776]],
    [ "Enedis",[1.4720863, 43.6643039]],
    [ "Enedis",[1.4739453445673019, 43.6572262890645]],
    [ "Enedis",[1.4752942509596514, 43.66276810373486]],
    [ "Enedis",[1.465592865904524, 43.66157446615337]],
    [ "Enedis",[1.47421286402826, 43.65913666779647]],
    [ "Enedis",[1.48229961673481, 43.661807149264924]],
    [ "Enedis",[1.4823259846914418, 43.664131411728604]],
    [ "Enedis",[1.470799488282191, 43.65323362181008]],
    [ "Enedis",[1.478327216353363, 43.66010151772511]],
    [ "Enedis",[1.4704870259685754, 43.66185439264187]],
    [ "Enedis",[1.4764444, 43.65591644999999]],
    [ "Enedis",[1.4853322129655215, 43.66499577352281]],
    [ "Enedis",[1.4834624524650286, 43.66340084911293]],
    [ "Enedis",[1.4795174960063906, 43.65934648439439]],
    [ "Enedis",[1.4792667383993008, 43.66526277770246]],
    [ "Enedis",[1.4762732202719413, 43.6641694051916]],
    [ "Enedis",[1.4703629825970148, 43.657957025326326]],
    [ "Enedis",[1.4788858675107497, 43.66344254507884]],
    [ "Enedis",[4.830150841238114, 45.03097518762539]],
    [ "Enedis",[4.827439499169152, 45.039862705699264]],
    [ "Enedis",[4.830183199337544, 45.03326491325939]],
    [ "Enedis",[4.8304537343390015, 45.0374189836801]],
    [ "Enedis",[-2.2491017150609314, 47.0099234176842]],
    [ "Enedis",[-2.2486019500000003, 47.00040755]],
    [ "Enedis",[-2.2455825499999995, 47.00919775]],
    [ "Enedis",[-2.2452973990731455, 47.00420342670814]],
    [ "Enedis",[-2.2412962424136627, 47.01120443317362]],
    [ "Enedis",[-2.254475724861297, 47.00352071235988]],
    [ "Enedis",[-2.252046533320189, 47.00609930041311]],
    [ "Enedis",[-2.2416284, 47.00781405000001]],
    [ "Enedis",[-2.251282749999999, 47.00975429999999]],
    [ "Souz ux",[-2.2277398000000006, 47.0147568]],
    [ "Enedis",[-2.2246168328960874, 47.01330050204905]],
    [ "Enedis",[3.111301053827161, 45.84755312493827]],
    [ "Enedis",[3.08262605, 45.8512338]],
    [ "Enedis",[3.089699629840308, 45.84352306451517]],
    [ "Enedis",[3.0866222000000016, 45.856817199999995]],
    [ "Enedis",[3.106019796087009, 45.85170473312984]],
    [ "Enedis",[3.107226628109553, 45.845443190885035]],
    [ "Enedis",[3.087746879015699, 45.85394732586595]],
    [ "Enedis",[3.109677957690026, 45.85265467532837]],
    [ "Enedis",[3.087637036694522, 45.85397969301417]],
    [ "Enedis",[3.107658150000001, 45.85225234999999]],
    [ "Enedis",[3.0837043512104665, 45.853914833288435]],
    [ "Le Tricou",[1.5373729831797012, 43.53724598591535]],
    [ "Enedis",[1.4982913092685524, 46.23407646632963]],
    [ "V1llage Expo",[1.5128248674417466, 43.54867350311105]],
    [ "Enedis",[1.5044380543515266, 43.545511522626256]],
    [ "Hemodia",[1.5212603157601117, 43.54075206963386]],
    [ "Enedis",[1.5245657523754845, 43.52873384510779]],
    [ "Cegecol",[1.5186628630734391, 43.54521404003488]],
    [ "Enedis",[1.502999315551326, 43.54640465203752]],
    [ "Enedis",[1.4825569611241394, 46.22776977516653]],
    [ "Enedis",[1.5272584447658275, 43.533450703085286]],
    [ "Enedis",[1.5020903109526855, 43.548472358786185]],
    [ "Tricou",[1.5380440525077541, 43.54003423845223]],
    [ "Enedis",[1.4855501500000003, 46.236876]],
    [ "Enedis",[1.5110499804581436, 43.54234569878429]],
    [ "Enedis",[1.5157089346229975, 43.54605472624159]],
    [ "Enedis",[1.513018416101278, 43.54002525065861]],
    [ "Rank Xerox",[1.5039431757178001, 43.54910126584129]],
    [ "Enedis",[1.5140638855927309, 43.5360594912867]],
    [ "Enedis",[1.5030609729823696, 43.54746168079073]],
    [ "Pomarède",[1.5307975691417839, 43.535657268667734]],
    [ "Enedis",[1.5079221968767524, 43.543386489370235]],
    [ "Enedis",[1.4784840499999998, 46.23917685]],
    [ "Enedis",[1.5320124654378693, 43.52660922826134]],
    [ "La Payssière",[1.5331519648327028, 43.53316599314632]],
    [ "Enedis",[1.5374871278577227, 43.528395781005095]],
    [ "Enedis",[1.5101584045176748, 43.54591813067758]],
    [ "Enedis",[1.4377076846605819, 46.25570243040079]],
    [ "Galilée",[1.5046177999999986, 43.549057350000005]],
    [ "Enedis",[1.505313141563528, 43.54722179683724]],
    [ "Enedis",[1.510358658028106, 43.550697982561616]],
    [ "Enedis",[1.4970881160934046, 46.239422481150726]],
    [ "Enedis",[1.5125899000000003, 43.546295750000006]],
    [ "Enedis",[1.50185275, 46.24865825]],
    [ "Enedis",[1.5054965515741106, 43.54552879542619]],
    [ "Enedis",[1.5014988939855878, 43.547999934829996]],
    [ "Enedis",[1.5433537619428659, 43.52954638944995]],
    [ "Enedis",[1.5082401879724607, 43.542977932710414]],
    [ "La Prade",[1.5316161584339045, 43.538578870182924]],
    [ "Enedis",[1.513024684858213, 43.550170406579326]],
    [ "Enedis",[1.5286580015658067, 43.52938712458772]],
    [ "Enedis",[1.5246488831915437, 43.530212450495746]],
    [ "Enedis",[1.5184948661870148, 43.53636075082751]],
    [ "Centre d'Affaires",[1.50523415, 43.54843735]],
    [ "Enedis",[1.541677667358545, 43.533544457049835]],
    [ "Enedis",[1.505190417263053, 43.5444763763262]],
    [ "Canteloup",[1.533169224749905, 43.54102085698957]],
    [ "Chêne Vert",[1.5192211783780234, 43.54172909877066]],
    [ "Enedis",[1.513089400225884, 43.5437794773746]],
    [ "Enedis",[1.5354424261724424, 43.52971740464906]],
    [ "Enedis",[1.51608249177606, 43.53564547945695]],
    [ "Enedis",[1.5363844789889898, 43.53489272419325]],
    [ "Enedis",[1.4889306999999998, 46.23688919999999]],
    [ "Enedis",[1.514124365295019, 43.54341253395739]],
    [ "Enedis",[1.5099258596511953, 43.552944562802075]],
    [ "Enedis",[1.5051653607614064, 43.5445075500644]],
    [ "Enedis",[1.5167549911015545, 43.53511328450725]],
    [ "Enedis",[1.5311417534384122, 43.52976234704316]],
    [ "Enedis",[1.4864904000000003, 46.2386834]],
    [ "Enedis",[1.4750572340360513, 46.23717825383201]],
    [ "Enedis",[1.4931589999999995, 46.23165025]],
    [ "Enedis",[1.493942850000001, 46.2414795]],
    [ "Enedis",[1.4787426499999998, 46.22784375]],
    [ "Enedis",[1.4858115392159361, 46.22553517004763]],
    [ "Enedis",[1.4819934986571264, 46.24523813611702]],
    [ "Enedis",[1.5043155, 46.23297315]],
    [ "Enedis",[1.5154908500000002, 46.24065785]],
    [ "Enedis",[1.4815627499999997, 46.232351249999994]],
    [ "Enedis",[1.5037441064435029, 46.23520772782607]],
    [ "Enedis",[1.4880430000000002, 46.23021265]],
    [ "Enedis",[1.4857997661042526, 46.23980775153271]],
    [ "Enedis",[1.4949591210323043, 46.24339679420737]],
    [ "Enedis",[1.491925970856306, 46.23552074662571]],
    [ "Enedis",[1.4914278165716102, 46.238343509318064]],
    [ "Enedis",[1.4994005000000004, 46.2459797]],
    [ "Enedis",[1.3932993000000002, 46.202704499999996]],
    [ "RTE",[2.5433212976111816, 48.91496577210163]],
    [ "Enedis",[2.250092067627266, 48.97866403841032]],
    [ "Enedis",[2.2439775999999996, 48.977877299999996]],
    [ "Enedis",[3.3050070918422976, 47.99674310365972]],
    [ "Enedis",[3.339817268055347, 47.99285652609392]],
    [ "Enedis",[3.3292593295604203, 47.994962107528025]],
    [ "Enedis",[3.298579254988232, 48.01556183975204]],
    [ "Enedis",[3.3349496330013366, 47.994193481979025]],
    [ "Enedis",[3.312613843609471, 47.99415238502431]],
    [ "Enedis",[3.34249967675047, 47.9914901444479]],
    [ "Enedis",[2.1836498903930237, 48.895778551407844]],
    [ "Lumas",[4.765286597310717, 44.65382716319871]],
    [ "Mongheol",[3.593246694492011, 45.552536370538235]],
    [ "Enedis",[2.2148897848702056, 50.83460799642535]],
    [ "Enedis",[2.2120865522905393, 50.834618116312186]],
    [ "Enedis",[2.2203983470984783, 50.83509707353962]],
    [ "Enedis",[1.0323663375146133, 47.79094193024917]],
    [ "Enedis",[1.0260028755336315, 47.79770930238078]],
    [ "Enedis",[1.0491409971404786, 47.787132250518205]],
    [ "Enedis",[1.0195386, 47.8012636]],
    [ "Enedis",[1.034412447723106, 47.77003560893016]],
    [ "Enedis",[4.105885027677411, 48.32731827225529]],
    [ "Enedis",[4.110187928259367, 48.31954046415043]],
    [ "Enedis",[4.0911687361706015, 48.319641100038424]],
    [ "Enedis",[4.10800446381811, 48.32062882414671]],
    [ "Enedis",[4.107523842946269, 48.32732025525611]],
    [ "Enedis",[2.778838503462842, 50.624952072854285]],
    [ "Enedis",[2.7725746567200167, 50.628813271619094]],
    [ "Enedis",[2.7781994891199386, 50.62731202962948]],
    [ "Enedis",[2.7698317586588677, 50.62734835363136]],
    [ "Enedis",[2.7709312265542274, 50.61907519357516]],
    [ "Enedis",[2.776744698838911, 50.623792287339306]],
    [ "Enedis",[2.7679148104204803, 50.6332956319988]],
    [ "Enedis",[2.772814537963286, 50.623063922084086]],
    [ "Enedis",[2.7710508215089154, 50.625026481452515]],
    [ "Enedis",[2.1902039418174617, 48.88786135603977]],
    [ "Enedis",[2.186028721260479, 48.888006616155934]],
    [ "Enedis",[2.8344648670173354, 50.65452824499926]],
    [ "Enedis",[2.8348899348255654, 50.656150353816564]],
    [ "Enedis",[2.834124123920618, 50.655842686753964]],
    [ "Enedis",[2.8279564100409047, 50.65324980901482]],
    [ "Enedis",[1.842868360986024, 44.485676570184346]],
    [ "Enedis",[7.118102232996258, 43.58825559959021]],
    [ "Enedis",[7.101488446556864, 43.58979310978885]],
    [ "Enedis",[7.136384557622954, 43.55393482031292]],
    [ "Enedis",[7.094777193438807, 43.60446303117135]],
    [ "Enedis",[-2.2273813459312826, 46.99830189969691]],
    [ "Enedis",[-2.244225399999999, 46.99804255000001]],
    [ "Enedis",[-2.2233003, 47.00761845]],
    [ "Enedis",[-2.240221800000001, 47.00436965]],
    [ "Enedis",[-2.234768741423876, 47.00634097877422]],
    [ "Enedis",[-2.2414457433507726, 46.99986947302076]],
    [ "Enedis",[-2.2201564168946955, 46.99505780147763]],
    [ "Enedis",[-2.2287395828995566, 47.002204046273064]],
    [ "Enedis",[1.545030249999999, 46.388451]],
    [ "Enedis",[2.5268208969952264, 47.30359986391463]],
    [ "Enedis",[2.5214483366759044, 47.30308339461047]],
    [ "Bouleau",[7.1320363556030415, 48.04144380625899]],
    [ "Enedis",[7.142642681046939, 48.04144891909398]],
    [ "Enedis",[7.146996596109714, 48.039158670112954]],
    [ "Enedis",[7.1601087561126695, 48.04359831150874]],
    [ "Enedis",[7.165054869506655, 48.04338312516776]],
    [ "Enedis",[7.154782900000001, 48.03958335000001]],
    [ "Enedis",[7.1654164718994195, 48.04580072464439]],
    [ "Enedis",[7.14202445, 48.03808115000001]],
    [ "Enedis",[7.134186525946149, 48.04026857452972]],
    [ "Enedis",[7.134097523811851, 48.03963060296225]],
    [ "Enedis",[7.155377570636726, 48.04226545508329]],
    [ "Enedis",[7.149528312580118, 48.04113537749954]],
    [ "Enedis",[7.121378235923728, 48.04614313570786]],
    [ "Enedis",[7.1597246000000005, 48.043615700000004]],
    [ "bains",[7.137359223288098, 48.042588299656835]],
    [ "Hopital",[7.132781540839826, 48.043575159633235]],
    [ "Enedis",[7.165136756907508, 48.04338617090661]],
    [ "Sur le rocher",[6.631092383915834, 45.86269456359647]],
    [ "caravaning",[6.620061123731108, 45.86010141744602]],
    [ "Enedis",[6.631113127139352, 45.86013799011416]],
    [ "Poste électrique de Mégève",[6.613459669558208, 45.858640432567995]],
    [ "Les Mélèzes",[6.624042147280094, 45.86148040888828]],
    [ "Enedis",[6.6414108191839585, 45.83598579011159]],
    [ "Ccas",[6.630928600000001, 45.86248135]],
    [ "Enedis",[6.638893262553091, 45.846385083467254]],
    [ "Enedis",[6.636031961547922, 45.847782768086844]],
    [ "Les Pratz",[6.5870266661454435, 45.84203709559857]],
    [ "Enedis",[6.591750698222332, 45.838902269002396]],
    [ "Moulin Neuf",[6.588877183377623, 45.84166164307605]],
    [ "Enedis",[6.617504468405433, 45.85702774173455]],
    [ "Enedis",[1.5118646166565914, 46.148829903183746]],
    [ "Enedis",[2.1139228313411236, 43.91735735364495]],
    [ "Enedis",[2.114030937433146, 43.91619250560676]],
    [ "Enedis",[1.035685243539556, 46.139031754918335]],
    [ "Enedis",[1.0364735600697839, 46.14254481064433]],
    [ "Enedis",[1.0621727875103852, 46.1533307002009]],
    [ "Enedis",[1.0599066089553473, 46.15772971441698]],
    [ "Poste électrique du Bras de la Plaine",[55.46298009032502, -21.2828472864619]],
    [ "Enedis",[1.563952949999999, 46.1344245]],
    [ "Enedis",[1.5170391999999997, 46.1427892]],
    [ "Enedis",[4.091154880971237, 50.255413904890275]],
    [ "Enedis",[6.524507727033328, 48.59875459367161]],
    [ "Enedis",[6.531580158905517, 48.59841303692032]],
    [ "Enedis",[6.532889081671887, 48.60023501955702]],
    [ "Enedis",[6.526680421850651, 48.59805532339307]],
    [ "Enedis",[2.9040515500000006, 43.31167875]],
    [ "Enedis",[-0.633063855697947, 45.76934501903568]],
    [ "Enedis",[1.0639379529827875, 46.12831900860837]],
    [ "Enedis",[1.0494988180603524, 46.12251922053501]],
    [ "Enedis",[1.063588448913521, 46.12291309976792]],
    [ "Enedis",[1.0541437388368688, 46.12613495208481]],
    [ "Enedis",[1.0434302999999998, 46.12261095]],
    [ "Enedis",[1.0615552959400676, 46.12654055038988]],
    [ "Enedis",[1.0499543363593042, 46.128257344918204]],
    [ "Enedis",[1.0694430611070909, 46.1220365513903]],
    [ "Enedis",[1.0526063204817142, 46.12983889544264]],
    [ "Enedis",[1.0590376466480107, 46.11759919714764]],
    [ "Enedis",[1.0587297254655177, 46.129187234585]],
    [ "Enedis",[1.0561134800432275, 46.128948641397436]],
    [ "Enedis",[1.0555358485669544, 46.10513115811369]],
    [ "Enedis",[1.0570627576841203, 46.126875201680605]],
    [ "Enedis",[1.0673525499999998, 46.1083626]],
    [ "Enedis",[1.057636021700648, 46.1208158421965]],
    [ "Enedis",[1.0523588327788995, 46.12197529364674]],
    [ "Enedis",[1.0450878603089473, 46.12665887659293]],
    [ "Enedis",[1.0559670862953021, 46.123970746842765]],
    [ "Enedis",[1.052857542810173, 46.119781339932864]],
    [ "Enedis",[1.0416928674770571, 46.12426999837156]],
    [ "Enedis",[1.051431717839731, 46.11607088002109]],
    [ "Ménardeau",[2.2516019614558553, 48.9732843159147]],
    [ "Enedis",[2.053620692933891, 48.051484463505965]],
    [ "Enedis",[2.0466884216149923, 48.06164263450752]],
    [ "Enedis",[2.0541713654644544, 48.06878421102677]],
    [ "Enedis",[2.0622976629613166, 48.069054739439764]],
    [ "Enedis",[2.0560082398696076, 48.07430086210187]],
    [ "Enedis",[2.061006370184295, 48.06426541463847]],
    [ "Enedis",[2.0647633317896905, 48.06478050258139]],
    [ "Enedis",[2.0517012280242737, 48.077383798188784]],
    [ "Enedis",[2.0605672000000004, 48.06956605]],
    [ "Enedis",[5.4351270184962095, 43.51204319176329]],
    [ "Enedis",[5.437342319633612, 43.5123746596575]],
    [ "Enedis",[3.4693673573326116, 43.62171768782157]],
    [ "Enedis",[3.4733771092846166, 43.62221295972859]],
    [ "Enedis",[3.472176246021331, 43.62408499630002]],
    [ "Enedis",[3.4681266000000006, 43.62392155]],
    [ "Cité Universitaire",[5.447903405489623, 43.52310561405597]],
    [ "Saut de l'Arc",[5.4727090938703045, 43.51290248403413]],
    [ "Allée de Beaumanoir",[5.469291500000001, 43.514434900000005]],
    [ "Val St Andre 2",[5.471632476537217, 43.515086976877896]],
    [ "Enedis",[5.471232886878288, 43.51674833494727]],
    [ "École St André",[5.466976849794319, 43.518903463113354]],
    [ "Enedis",[-2.043651864324103, 46.78055448203975]],
    [ "Enedis",[-2.088092431067191, 46.79262264744915]],
    [ "Enedis",[-2.0555992348615666, 46.77746858331557]],
    [ "Enedis",[-2.038654757916649, 46.78060908764264]],
    [ "Enedis",[-2.0695947781826924, 46.7867614150294]],
    [ "Enedis",[-2.0245784000000002, 46.7668969]],
    [ "Enedis",[-2.0781481832789503, 46.78838343217948]],
    [ "Enedis",[-2.0694193500000004, 46.78761785]],
    [ "Enedis",[-2.0386661911991024, 46.777993730045445]],
    [ "Enedis",[-2.0732740540943175, 46.79104424033625]],
    [ "Enedis",[-2.07617365, 46.791179050000004]],
    [ "Enedis",[-2.0506715499999997, 46.77886425]],
    [ "Enedis",[-2.0304511499999998, 46.77267449999999]],
    [ "Enedis",[-2.0513781000000004, 46.7769352]],
    [ "Enedis",[-2.0647550785316207, 46.782491864635425]],
    [ "Enedis",[-2.0336422332939033, 46.77212034982177]],
    [ "Enedis",[-2.0717696500000002, 46.789040299999996]],
    [ "Enedis",[-2.03585555, 46.773267950000005]],
    [ "Enedis",[-2.0552677833205597, 46.778620949829566]],
    [ "Enedis",[4.818496818455211, 44.861614587585194]],
    [ "68350159",[2.32079372436366, 43.20861788299783]],
    [ "Enedis",[1.910181222205508, 47.946096576304384]],
    [ "Enedis",[1.8893842557458222, 47.937320755518876]],
    [ "Enedis",[1.9060124120892874, 47.95061507503202]],
    [ "Enedis",[1.8643715715567033, 47.950526213525194]],
    [ "Enedis",[1.8684125268292677, 47.95075472682927]],
    [ "Enedis",[1.87477855, 47.93833765]],
    [ "Enedis",[1.8866222269520216, 47.95061403310431]],
    [ "Enedis",[1.8625220683263362, 47.949763340509136]],
    [ "Enedis",[1.8930431566405703, 47.944429079090625]],
    [ "Enedis",[1.8917434567498397, 47.93651468016635]],
    [ "Enedis",[1.88834941525648, 47.93281947051387]],
    [ "Enedis",[1.8709131000000008, 47.93250544999999]],
    [ "Enedis",[1.8909042014950053, 47.95735166949575]],
    [ "Enedis",[1.8992214576364033, 47.95419281477031]],
    [ "Enedis",[1.8760746092307359, 47.9350829438721]],
    [ "Enedis",[1.8696279489130432, 47.937051715579706]],
    [ "Enedis",[1.873993363344792, 47.93290948152244]],
    [ "Enedis",[1.8933741418917827, 47.958448120102354]],
    [ "Enedis",[1.8583022376922438, 47.947747306220826]],
    [ "Enedis",[1.86762602115942, 47.93026856374176]],
    [ "Enedis",[1.8835126380921294, 47.94542110791448]],
    [ "Enedis",[1.8810553016787719, 47.95497183969286]],
    [ "Enedis",[1.9024351718999961, 47.95178292276091]],
    [ "Enedis",[1.883306807024727, 47.950447471769735]],
    [ "Enedis",[1.8837456584916719, 47.927508542190104]],
    [ "Enedis",[1.8903241509014292, 47.935648832856]],
    [ "Enedis",[1.8905609490730384, 47.94305569781901]],
    [ "Enedis",[1.8727362230333158, 47.947738378864116]],
    [ "Enedis",[1.8844600027432836, 47.93020137575372]],
    [ "Enedis",[1.8814553120736335, 47.9513496734423]],
    [ "Enedis",[1.870858456845298, 47.94121775291675]],
    [ "Enedis",[1.9087879874467322, 47.94794752843212]],
    [ "RTE",[1.8896590096883077, 47.962524429495026]],
    [ "Enedis",[1.8961284908649452, 47.953150766256265]],
    [ "Enedis",[1.9004386153851973, 47.947587700089784]],
    [ "Enedis",[1.8795369147334045, 47.9530754656049]],
    [ "Enedis",[1.906112600000001, 47.95337785]],
    [ "Enedis",[1.8849598935679086, 47.94326644875869]],
    [ "Enedis",[1.8783087567478238, 47.927103459360836]],
    [ "Enedis",[1.902520657104296, 47.94480452397043]],
    [ "Enedis",[1.900423203357181, 47.94525424620376]],
    [ "Enedis",[1.8766016645965355, 47.95218278076327]],
    [ "Enedis",[1.8924537550210043, 47.933416121869314]],
    [ "Enedis",[1.881750178953737, 47.94943244638547]],
    [ "Enedis",[1.889514300649119, 47.93133061669965]],
    [ "Enedis",[1.8858894292096753, 47.92904389418589]],
    [ "Enedis",[1.8837400699957412, 47.963655186349506]],
    [ "Enedis",[1.8845548426950856, 47.945453270815676]],
    [ "Enedis",[1.8928372932396516, 47.935430969497325]],
    [ "Enedis",[1.894097027944352, 47.941505726552364]],
    [ "Enedis",[4.006359515776934, 50.2560876768595]],
    [ "Enedis",[1.8585381301356763, 47.950729442502784]],
    [ "Enedis",[1.8880760092750866, 47.93588599515764]],
    [ "Enedis",[1.8899053624544877, 47.960026272689234]],
    [ "Enedis",[4.009413802160675, 50.2509305660381]],
    [ "Enedis",[1.8877457500000006, 47.92987879999999]],
    [ "Enedis",[1.8690344260913887, 47.94712496132092]],
    [ "Enedis",[4.002633720496221, 50.25514029240969]],
    [ "Enedis",[1.8839051846386212, 47.93165362617166]],
    [ "Enedis",[3.9981205460000506, 50.25534904654138]],
    [ "Enedis",[4.000209341230177, 50.25048484441774]],
    [ "Enedis",[3.972235084890586, 50.242597440439035]],
    [ "Enedis",[3.997629117857279, 50.26360077811196]],
    [ "Enedis",[2.124428503860481, 48.72308724586296]],
    [ "Enedis",[5.169069291678781, 45.31790747663779]],
    [ "Enedis",[5.203275603792186, 43.41212138636502]],
    [ "Enedis",[0.6684679971920092, 47.06531161745366]],
    [ "Enedis",[0.6679919519198535, 47.067421253630116]],
    [ "Enedis",[5.1976013000000005, 43.410104000000004]],
    [ "Enedis",[5.200582777092616, 43.41931861190502]],
    [ "Enedis",[5.19888055, 43.411666350000004]],
    [ "Enedis",[5.201223416897933, 43.420051377162736]],
    [ "Enedis",[5.202777439191182, 43.417674203472615]],
    [ "Enedis",[5.199091271047833, 43.410680509885715]],
    [ "Enedis",[5.207660983477631, 43.417951207794786]],
    [ "Enedis",[5.197123050000001, 43.411244550000006]],
    [ "Enedis",[5.201586529741661, 43.413717423609334]],
    [ "Enedis",[5.20667355, 43.410625399999994]],
    [ "Enedis",[5.2029084849257465, 43.41523418445938]],
    [ "Enedis",[5.201358196890093, 43.41199888984917]],
    [ "Enedis",[5.1953984070874855, 43.41029113333333]],
    [ "Enedis",[5.201792177780405, 43.41083772151661]],
    [ "Enedis",[0.45728754448897363, 47.3446714098337]],
    [ "Enedis",[0.4609830969138127, 47.34696382144022]],
    [ "Enedis",[3.07092325, 47.992746100000005]],
    [ "Enedis",[3.0449344999999997, 48.03566085000001]],
    [ "Enedis",[3.0525306527461002, 48.043041422309294]],
    [ "Enedis",[3.04872312595876, 48.03402854023292]],
    [ "Enedis",[3.0806607036643663, 48.00863225181433]],
    [ "Enedis",[3.0849894260531987, 47.999468546023834]],
    [ "Enedis",[3.066129990341219, 47.99888302107537]],
    [ "Enedis",[3.0890371513978483, 48.00549934231361]],
    [ "Enedis",[3.1037043235619532, 48.02481733079257]],
    [ "Enedis",[3.071676866734607, 47.99989998346072]],
    [ "Enedis",[3.088868287371738, 48.0006036556763]],
    [ "Enedis",[0.457571868029485, 47.34459367714136]],
    [ "Enedis",[3.0694028999999996, 48.04063575]],
    [ "Enedis",[3.103913489470228, 48.03487540677803]],
    [ "Enedis",[2.2563108391735454, 48.97615861746186]],
    [ "Enedis",[3.0540316109540697, 48.03760121885575]],
    [ "Enedis",[3.0637241917414575, 48.042456566870584]],
    [ "Enedis",[3.0578379891615595, 48.03516607166338]],
    [ "Enedis",[3.0840415193632684, 48.00511369501069]],
    [ "Enedis",[3.049309541086455, 48.04051707340871]],
    [ "Enedis",[3.0819114, 48.00576005]],
    [ "Enedis",[1.6687513999999997, 46.33787614999999]],
    [ "Enedis",[3.7957970499999996, 45.59340614999999]],
    [ "Enedis",[3.8063580568481976, 45.58937965702894]],
    [ "Enedis",[0.48016489258139344, 44.869833308315286]],
    [ "Enedis",[0.48385938552799485, 44.86877327693989]],
    [ "Poste électrique du tunnel sous la Manche",[1.7832751895503631, 50.92021609147432]],
    [ "Enedis",[0.7288611587481645, 47.11132723145158]],
    [ "Enedis",[0.1672274, 47.2739702]],
    [ "Enedis",[0.16986245637888073, 47.28864247874463]],
    [ "Enedis",[0.5842979889314991, 47.373222134274485]],
    [ "Enedis",[0.5956694376685604, 47.3739557240191]],
    [ "Enedis",[0.812528108435988, 47.28360662056661]],
    [ "Enedis",[0.7819516919463599, 47.29235649701572]],
    [ "Enedis",[0.7626116740666341, 47.31688438490201]],
    [ "Enedis",[0.7898621151724855, 47.291145194517256]],
    [ "Enedis",[0.7914510500635933, 47.28807119308074]],
    [ "Enedis",[0.7823091673153691, 47.28791735928144]],
    [ "Enedis",[0.7882274990717187, 47.28789307343432]],
    [ "Enedis",[0.78578342437578, 47.2834496308888]],
    [ "Enedis",[0.7838080423425483, 47.290696900210854]],
    [ "Enedis",[6.524768927662711, 45.48610985365295]],
    [ "RTE",[6.535053205515138, 45.48201386203608]],
    [ "Enedis",[-1.4462773500000001, 49.054545450000006]],
    [ "Enedis",[-1.425137293557266, 49.06071007178554]],
    [ "NT TIR 65",[2.220107864771452, 48.881935603245715]],
    [ "nt verdun 5",[2.218586020965816, 48.883962025004024]],
    [ "Enedis",[2.209593886409186, 48.88604129967682]],
    [ "nt plaideurs 84",[2.2162010320787635, 48.879724471379035]],
    [ "Enedis",[2.2588910602391103, 48.97522522302938]],
    [ "Enedis",[2.2624733388281344, 48.97440660337165]],
    [ "Enedis",[2.0684442194144164, 48.06993891766849]],
    [ "Enedis",[3.5096904, 43.618451150000006]],
    [ "Enedis",[3.5161059999999997, 43.61610339999999]],
    [ "Enedis",[3.512435075131467, 43.615216089572336]],
    [ "Enedis",[2.869599129523191, 50.65000932276509]],
    [ "Enedis",[2.8716584991754632, 50.6474414061303]],
    [ "Enedis",[2.8902433980935225, 50.65776827153854]],
    [ "Enedis",[2.8958168892628344, 50.65351312714124]],
    [ "Enedis",[2.8736297004790647, 50.64986474509761]],
    [ "Enedis",[2.876116786122832, 50.651918240834505]],
    [ "Enedis",[2.878567528871391, 50.64416032755906]],
    [ "Enedis",[2.870494417326345, 50.65861165196515]],
    [ "Enedis",[0.7204957, 47.341649908526016]],
    [ "68766644",[0.6912058000000001, 47.33379395]],
    [ "Enedis",[0.6935389076638916, 47.348143660215115]],
    [ "Enedis",[0.7144495157389877, 47.34109121821279]],
    [ "Enedis",[0.7021438156751847, 47.331288403564244]],
    [ "Enedis",[0.7040417135339055, 47.3370477820559]],
    [ "Enedis",[0.7035991443816727, 47.32768997959207]],
    [ "Enedis",[0.7033247825214904, 47.33990535551099]],
    [ "Enedis",[0.7103523078389034, 47.33779550934098]],
    [ "Enedis",[0.7125127143395991, 47.314438332554]],
    [ "Enedis",[0.717025969475633, 47.345170301983146]],
    [ "Enedis",[0.7136201561324297, 47.3380456343717]],
    [ "Enedis",[0.6960659227147935, 47.344762501678595]],
    [ "Enedis",[0.7193559918550817, 47.33918048840405]],
    [ "Enedis",[0.7253811357254649, 47.344131233972554]],
    [ "Enedis",[0.698142696845382, 47.33311832780687]],
    [ "Enedis",[0.7048945629063099, 47.31973841121733]],
    [ "Enedis",[0.6990840083686771, 47.33577038523844]],
    [ "Enedis",[0.7176540885633895, 47.32040069713442]],
    [ "Enedis",[0.7185000540131269, 47.34294770349063]],
    [ "Enedis",[0.7165420471783533, 47.34239172247592]],
    [ "Enedis",[0.7016409301532772, 47.328532545255854]],
    [ "Enedis",[0.6996242311653323, 47.352313595500306]],
    [ "Enedis",[0.7086015247478048, 47.320093244281374]],
    [ "Enedis",[0.7056822024345819, 47.349954684862865]],
    [ "Enedis",[0.7221175781528008, 47.34803996578968]],
    [ "Enedis",[0.7117818176301133, 47.31896638843454]],
    [ "Enedis",[0.7095849888148744, 47.336278270989794]],
    [ "Enedis",[0.7304693831498551, 47.33975985231656]],
    [ "Enedis",[0.7063999419371575, 47.348051276153925]],
    [ "Enedis",[0.7188614885455751, 47.32977994439745]],
    [ "Enedis",[0.7069015993671116, 47.341656315475966]],
    [ "Enedis",[0.702067808646902, 47.33178927704295]],
    [ "Enedis",[0.7113282135832331, 47.342605351854104]],
    [ "Enedis",[0.7009364499999999, 47.33672285]],
    [ "Enedis",[0.7024983186213193, 47.342156428192965]],
    [ "Enedis",[0.6954946512090701, 47.35123886006192]],
    [ "Enedis",[0.6988666, 47.34860019999999]],
    [ "Enedis",[0.6894609954544384, 47.34292375563909]],
    [ "Enedis",[0.7057719647788167, 47.338447538836895]],
    [ "Enedis",[0.7041664916380846, 47.34090279534381]],
    [ "Enedis",[0.6916479805799284, 47.33999295378123]],
    [ "Enedis",[0.7134954499503948, 47.33943212169559]],
    [ "Enedis",[2.5118376205987576, 51.07571606095475]],
    [ "Enedis",[2.522071708330125, 51.07926270166858]],
    [ "Enedis",[2.5353312687237883, 51.07293340785679]],
    [ "Enedis",[2.5267029495147595, 51.07121618046465]],
    [ "Enedis",[2.511285438891477, 51.06813520584031]],
    [ "Enedis",[2.530307933400164, 51.07187397222868]],
    [ "Enedis",[2.520302916881164, 51.062328726119794]],
    [ "Enedis",[2.5157643727031775, 51.069803428040466]],
    [ "Enedis",[2.514839073420951, 51.07231183063325]],
    [ "Enedis",[2.513270290904117, 51.07398268958726]],
    [ "Enedis",[2.5372534516526297, 51.07214769061258]],
    [ "Enedis",[2.5208687288568097, 51.07898853082431]],
    [ "Enedis",[2.5222457992150433, 51.08113446983138]],
    [ "Enedis",[2.5238097096367937, 51.06880173754459]],
    [ "Enedis",[2.5192275794953005, 51.08008367533464]],
    [ "Enedis",[2.551816642576068, 51.07520240120056]],
    [ "Enedis",[2.519039594932027, 51.06766924369987]],
    [ "Enedis",[2.5435237525072774, 51.07409490556223]],
    [ "Enedis",[2.516291351641609, 51.068325996317256]],
    [ "Enedis",[2.504613780424666, 51.067006698760565]],
    [ "Enedis",[2.5222764840989402, 51.07060978096584]],
    [ "Enedis",[2.5447099915658207, 51.087820534734924]],
    [ "Enedis",[2.547412278591785, 51.07441144042143]],
    [ "Enedis",[2.513540970361127, 51.06756824131481]],
    [ "Enedis",[2.514897776621449, 51.07758471263013]],
    [ "Enedis",[2.5169977877126377, 51.07486686977134]],
    [ "Enedis",[2.5442580170089713, 51.065884283708954]],
    [ "Enedis",[1.0053299500000001, 47.33841265]],
    [ "Enedis",[1.586474197211961, 43.52337086622755]],
    [ "Enedis",[2.4807606119686807, 51.04832507905481]],
    [ "Enedis",[2.531364192310503, 51.05151412507764]],
    [ "Enedis",[2.5243311, 51.057150850000006]],
    [ "Enedis",[2.5214788905431154, 51.047775168315326]],
    [ "Enedis",[2.5156255476709197, 51.05144321218594]],
    [ "Enedis",[2.5254567627959412, 51.05481097125141]],
    [ "Enedis",[2.495048670488298, 51.04922088471606]],
    [ "Enedis",[2.52794687941731, 51.051868047404184]],
    [ "Enedis",[2.5296953732493583, 51.05062541122003]],
    [ "Enedis",[2.496202643597189, 51.055092267610526]],
    [ "Enedis",[2.523329292486309, 51.06133687665584]],
    [ "Enedis",[2.5235557, 51.051697950000005]],
    [ "Enedis",[2.5254524934265734, 51.048895864035]],
    [ "Enedis",[2.5225003474422154, 51.053028080300365]],
    [ "Enedis",[2.502957065184718, 51.04971999082305]],
    [ "Enedis",[2.2369877603491197, 48.88272273625982]],
    [ "Px cartault 29",[2.229332914976163, 48.8817676958912]],
    [ "Px Cartault 2",[2.2280245448113147, 48.88148657419412]],
    [ "Px fusilles",[2.2255164923034356, 48.88423317369726]],
    [ "Enedis",[2.4884543727103785, 51.06843024733918]],
    [ "Enedis",[2.488403587236328, 51.07041678072453]],
    [ "Enedis",[2.489631513916106, 51.06259403808149]],
    [ "Enedis",[2.4825707352408695, 51.067606841747]],
    [ "Enedis",[2.4940112896854685, 51.066305186351855]],
    [ "Enedis",[2.4908811551787604, 51.06374796811923]],
    [ "Enedis",[2.4882641208446565, 51.06844591229771]],
    [ "Enedis",[2.4930280759169623, 51.05940208244561]],
    [ "Enedis",[2.4892407800879113, 51.06444294305233]],
    [ "Enedis",[-1.3147462499999996, 48.03751555000001]],
    [ "Le Gy",[6.083390437661211, 46.26069116980346]],
    [ "Vergers d'Alexis",[6.078699411015988, 46.24712820371764]],
    [ "Les Aglands",[6.066855584166026, 46.25462002482007]],
    [ "Zac Clos de Magny 1",[6.08524932864995, 46.24816960649494]],
    [ "Ravoire sud",[6.080630307065975, 46.25587701634211]],
    [ "ZAC Clos de Magny 3",[6.089860519532187, 46.24946881654485]],
    [ "Le Martinet",[6.065295897165201, 46.280127505669604]],
    [ "Bourg",[6.080324592440483, 46.25214386484136]],
    [ "Les tulipiers",[6.077092105213583, 46.25347858078582]],
    [ "Allée des chênes",[6.078883834378474, 46.25804385470313]],
    [ "Rousseau",[6.093951795705755, 46.25762939324301]],
    [ "L'Épine",[6.086307818262601, 46.264376904862544]],
    [ "Les Cygnes",[6.0954729092918445, 46.25821509608012]],
    [ "Magny",[6.093031777340805, 46.2552898570919]],
    [ "Marlis",[6.075089959253984, 46.25700521413061]],
    [ "Champ-Corboz",[6.087176246768387, 46.26215678591296]],
    [ "Les grands prés",[6.0773613474833335, 46.25172021292062]],
    [ "ZAC Clos de Magny 2",[6.086999539478638, 46.24878352523102]],
    [ "Molène",[6.08012993000026, 46.25015706398929]],
    [ "Balcons Mont Blanc",[6.086929779257974, 46.25596307510185]],
    [ "Crets 4B",[6.067368849999999, 46.2822348]],
    [ "Le clos",[6.089363899455799, 46.25985722420645]],
    [ "Les chênes",[6.09134853612251, 46.25362691521855]],
    [ "Moëns mairie",[6.092607198646391, 46.26170806201163]],
    [ "Les Charmais",[6.074704491889027, 46.24762648685653]],
    [ "Les fruitiers",[6.0902087097271655, 46.25285116666667]],
    [ "Les Hauts de Magny",[6.085383923294199, 46.2547401170648]],
    [ "La prairie",[6.094199987881892, 46.266787997272424]],
    [ "Boverie",[6.065498858885234, 46.28187955294506]],
    [ "Le parc du chateau",[6.071554482421706, 46.26367287926352]],
    [ "La clef des champs",[6.096246345109604, 46.26127906027719]],
    [ "Les chenevières",[6.088865811631026, 46.25390519654232]],
    [ "Les Cols Verts",[6.0843326330945535, 46.249800838181926]],
    [ "Maray",[6.076634187423134, 46.24981581460727]],
    [ "Rappes",[6.0806849911896865, 46.2595172230595]],
    [ "Route de Mategnin",[6.081435065923895, 46.246940785523314]],
    [ "Le clos du prieuré",[6.082962030041471, 46.25097777239702]],
    [ "Les perrieres",[6.096220450204921, 46.26774231493992]],
    [ "Poussins",[6.0840319873788955, 46.252216505251944]],
    [ "Enedis",[4.157716456147548, 50.24188772922006]],
    [ "Enedis",[3.530910243174018, 43.58930463247607]],
    [ "Enedis",[3.5012678407151294, 43.59117963582406]],
    [ "Enedis",[3.52733925, 43.59120825]],
    [ "Enedis",[3.5309279050265947, 43.594453583992376]],
    [ "Enedis",[3.5265579705783416, 43.59339752171734]],
    [ "Enedis",[6.730931253472585, 47.97142364368996]],
    [ "Poste électrique de Turgot",[2.345626974340323, 48.880733881837564]],
    [ "Enedis",[5.3922804000000015, 43.3131183]],
    [ "Enedis",[0.5520322, 47.39141030000001]],
    [ "Enedis",[0.5427598878997113, 47.388534687238476]],
    [ "Enedis",[0.5503815238545563, 47.39432687440026]],
    [ "Enedis",[0.4071520863382595, 47.32875784149504]],
    [ "Enedis",[0.5435526137292996, 47.403509983485975]],
    [ "Enedis",[0.5535181499999999, 47.392601899999995]],
    [ "Enedis",[0.5483605308847739, 47.392585121887656]],
    [ "Enedis",[0.4091224465745199, 47.325212487957145]],
    [ "Enedis",[0.55676065, 47.39178050000001]],
    [ "Enedis",[-2.277846250000001, 46.9834081]],
    [ "Enedis",[0.40534626473838736, 47.32577196381363]],
    [ "Enedis",[0.401081397047131, 47.330391758474846]],
    [ "Enedis",[0.4028179023658437, 47.32762165948373]],
    [ "Enedis",[0.40741227228125465, 47.33858563911952]],
    [ "Enedis",[0.40783480998107874, 47.33265455960989]],
    [ "Enedis",[0.40856999870387734, 47.329888049982536]],
    [ "Enedis",[0.40365271621111637, 47.33127282894051]],
    [ "Enedis",[5.403800207257955, 43.31307033279779]],
    [ "Barbier",[5.392946517580659, 43.31160159818367]],
    [ "Petit Jean",[5.396706296828382, 43.30878033175226]],
    [ "RTE",[6.934595477874554, 43.66068517697675]],
    [ "Enedis",[2.258694156456236, 48.97142656658414]],
    [ "Enedis",[2.2610095000000006, 48.97223720000001]],
    [ "Enedis",[2.2584794642580084, 48.97029642713602]],
    [ "Contamines",[6.4181095146168925, 46.355570497197505]],
    [ "ZAC Marclaz 2",[6.441922333570562, 46.350455300589246]],
    [ "Domaine de la Quietude",[6.4133527500000005, 46.351658400000005]],
    [ "Enedis",[6.4386226, 46.36036640000001]],
    [ "ZAC Pré Biollat 2",[6.436730949999999, 46.34858534999999]],
    [ "Enedis",[6.439219799999999, 46.34937775]],
    [ "Enedis",[6.437425569455837, 46.351408169838656]],
    [ "Enedis",[6.416567132704704, 46.356282896459824]],
    [ "Enedis",[6.432967899999999, 46.35190145]],
    [ "Heneides",[6.432350449282917, 46.35597062011835]],
    [ "Grands Champs",[6.408862512761054, 46.35122446908607]],
    [ "Poulaillère",[6.411302400000001, 46.34804425]],
    [ "Enedis",[6.412084245077438, 46.355720517581126]],
    [ "Enedis",[6.442668456582754, 46.35925068925126]],
    [ "Ebaux",[6.4223863273447535, 46.358010953152586]],
    [ "Les Lanches",[6.440218252332373, 46.35780234735632]],
    [ "Enedis",[6.4154812315095535, 46.35581892540378]],
    [ "Jardins",[6.326518721880527, 46.37052220447816]],
    [ "Falaises",[6.322276366704893, 46.36828975024182]],
    [ "Enedis",[6.3357231623257935, 46.36473970651131]],
    [ "Enedis",[6.325627991578369, 46.36870452737318]],
    [ "Chenallet",[6.3267409500000005, 46.365342850000005]],
    [ "Bossenaz",[6.323154162424422, 46.36575525886468]],
    [ "Batardes",[6.331753444541173, 46.3680835759433]],
    [ "Poste électrique de Montessuy",[2.299665598299306, 48.85982595477603]],
    [ "Les Meules",[6.402638247132118, 46.3489022733967]],
    [ "Enedis",[6.414927217139503, 46.343584401863744]],
    [ "Enedis",[6.425876764827807, 46.33591680371308]],
    [ "Lotissement Bachaix",[6.434966116753274, 46.34319920554979]],
    [ "Enedis",[6.418138250000001, 46.34321574999999]],
    [ "Enedis",[6.421155650391065, 46.344784255763166]],
    [ "Ronsuaz",[6.415493899094905, 46.34055946707933]],
    [ "Sechex Port",[6.403326700000001, 46.349787750000004]],
    [ "Zusinges",[6.428511158829732, 46.331882358562645]],
    [ "Enedis",[6.42513273300107, 46.32575035052955]],
    [ "Enedis",[6.419874845130608, 46.325201860527685]],
    [ "Enedis",[-1.7765251483431286, 49.59261978293412]],
    [ "Enedis",[-1.7729991296276066, 49.59435616599289]],
    [ "Allinges",[6.46146288270879, 46.33431699876002]],
    [ "Enedis",[6.481731747426257, 46.33837432402439]],
    [ "Enedis",[6.482250100000001, 46.341129550000005]],
    [ "Fortune Est",[6.454364861873107, 46.33256253720378]],
    [ "Leyriat",[6.488336778592101, 46.334521926336194]],
    [ "Chapelle",[6.4682002, 46.33592729999999]],
    [ "Enedis",[6.4397341213377555, 46.324252623991164]],
    [ "Enedis",[6.478022851092418, 46.33956130955029]],
    [ "Enedis",[6.443097160455136, 46.32894891795645]],
    [ "Enedis",[6.462008808276136, 46.338312852423044]],
    [ "Enedis",[6.441368486043405, 46.32827164634576]],
    [ "Enedis",[6.4851850135371105, 46.334946022782596]],
    [ "Enedis",[6.4858296499999994, 46.3560916]],
    [ "Enedis",[6.485373653259626, 46.340360884051336]],
    [ "Enedis",[6.4779949769805105, 46.33507875391037]],
    [ "Enedis",[6.460146331395957, 46.33572930677747]],
    [ "Enedis",[6.477201133198553, 46.34190786639325]],
    [ "Enedis",[6.475297449999999, 46.3518256]],
    [ "Enedis",[6.448984205303464, 46.32924011585153]],
    [ "Poste électrique Baudry",[2.3067516056343322, 48.872267213661644]],
    [ "Enedis",[-2.2649111894818614, 46.97336887925087]],
    [ "Enedis",[-2.268486732813027, 46.97070575214162]],
    [ "Enedis",[-2.2710696108270634, 46.97399436893686]],
    [ "Enedis",[-2.2692914409129195, 46.97633692873931]],
    [ "Enedis",[-2.2749073706495593, 46.977794745121635]],
    [ "RTE",[5.379007455291707, 43.28943002761185]],
    [ "Enedis",[2.2396698994624105, 48.17347397300892]],
    [ "Enedis",[2.2512398367854636, 48.175932264135824]],
    [ "Enedis",[2.2460852128108697, 48.18683108276178]],
    [ "Enedis",[2.246423094084101, 48.18588201544657]],
    [ "Enedis",[2.2434674902896288, 48.171149707596975]],
    [ "Enedis",[2.249125452283778, 48.185119110572174]],
    [ "Enedis",[2.2537952132872205, 48.18262803359806]],
    [ "Enedis",[2.246022604519305, 48.18108949998615]],
    [ "Enedis",[2.2498975065342943, 48.189188998035895]],
    [ "Enedis",[2.2558302125357224, 48.172478808848425]],
    [ "Enedis",[2.253595700000002, 48.18109435]],
    [ "Enedis",[2.243713957156195, 48.174682274372074]],
    [ "Enedis",[2.2435016987093723, 48.176871417817615]],
    [ "Enedis",[2.2480837945949124, 48.17472905662883]],
    [ "Enedis",[2.2416508206895758, 48.185630954957865]],
    [ "Enedis",[2.2470924916089325, 48.1820181418626]],
    [ "Enedis",[2.2447754022377864, 48.16450591573546]],
    [ "Enedis",[2.255962308645166, 48.16521588909]],
    [ "Poste électrique de Folies",[2.34436949981159, 48.87229643753971]],
    [ "Enedis",[-1.5504087999999996, 46.72237964999999]],
    [ "Enedis",[-1.6124415669079815, 46.74205925123482]],
    [ "Enedis",[-1.6081633240139404, 46.72662924463349]],
    [ "Enedis",[-1.6099356827134546, 46.729640202181585]],
    [ "Enedis",[-1.6065013661860796, 46.74206430310738]],
    [ "Enedis",[-1.6080545999999998, 46.747757199999995]],
    [ "Enedis",[-1.5894044181393938, 46.74401267486232]],
    [ "Enedis",[-1.6107034829654985, 46.74309919834649]],
    [ "Enedis",[-1.59621645, 46.7393697]],
    [ "Enedis",[-1.621819150000001, 46.73326669999999]],
    [ "Enedis",[-1.5914861392066104, 46.742343479397334]],
    [ "Enedis",[-1.6060515278411216, 46.74538215567622]],
    [ "Enedis",[-1.6159292175662396, 46.74790919736431]],
    [ "Enedis",[-1.6044110134040668, 46.74325701872932]],
    [ "Enedis",[-1.5662799500000002, 46.72425035]],
    [ "Enedis",[-1.6136415499999999, 46.7497298]],
    [ "Enedis",[-1.6141666420626652, 46.73997381874407]],
    [ "Enedis",[-1.6168357, 46.74199974999999]],
    [ "Enedis",[-1.61676635, 46.73921850000001]],
    [ "Enedis",[-1.5704160221905639, 46.72348719269478]],
    [ "Enedis",[-1.6175504000000003, 46.744609800000006]],
    [ "Enedis",[-1.612529, 46.745132800000015]],
    [ "Vieux bourg",[0.7723496499999999, 45.17228335000001]],
    [ "ZAE Le Ponteix",[0.7625019499999999, 45.18421634999999]],
    [ "Poste électrique de Plan de Grasse",[6.9402220623828255, 43.65568600461641]],
    [ "Enedis",[0.6558119, 45.218578699999995]],
    [ "Enedis",[0.6506556000000007, 45.22184109999999]],
    [ "Enedis",[0.700354772173733, 47.43712454102019]],
    [ "Enedis",[0.6878066025939408, 47.434409022671886]],
    [ "Enedis",[0.6798732409541342, 47.42984885138274]],
    [ "Enedis",[0.6771730979222113, 47.43291650242171]],
    [ "Enedis",[0.6765867508027479, 47.42638951155703]],
    [ "Enedis",[0.7036598500000001, 47.4301712]],
    [ "Enedis",[0.6735406389539023, 47.42779082118649]],
    [ "Enedis",[0.6777045488587982, 47.43506778584612]],
    [ "Enedis",[0.6938937917829218, 47.43373029542167]],
    [ "Enedis",[0.6862011393804829, 47.43232831279455]],
    [ "Enedis",[0.6910625050791711, 47.4285943491212]],
    [ "Enedis",[0.6771037661778704, 47.42745353070663]],
    [ "Enedis",[0.6871625223064062, 47.4266666049799]],
    [ "Enedis",[0.6970382940730128, 47.43595579544262]],
    [ "Enedis",[0.7038821388836405, 47.43520961965884]],
    [ "Enedis",[0.6831041980760696, 47.43026618160801]],
    [ "Enedis",[0.6837163123598576, 47.43764223294056]],
    [ "Enedis",[0.6942318145084772, 47.43250678043296]],
    [ "Enedis",[0.7014267241730379, 47.436862226684326]],
    [ "Enedis",[0.6837720827317899, 47.42655499190031]],
    [ "Enedis",[6.648921573140531, 46.37626524887237]],
    [ "Enedis",[6.657766903044749, 46.366142666210685]],
    [ "Enedis",[6.635149938602879, 46.37592228498028]],
    [ "Enedis",[6.63682049547904, 46.37923472896466]],
    [ "Enedis",[6.639547726430047, 46.355578414995676]],
    [ "Enedis",[6.659898292540809, 46.36523647681351]],
    [ "Enedis",[6.634069966132003, 46.370452032947526]],
    [ "Enedis",[6.614077189707142, 46.36352469869654]],
    [ "Enedis",[6.657834174809561, 46.358114818839006]],
    [ "Enedis",[6.621851887089, 46.36239748541622]],
    [ "Enedis",[6.630312884284916, 46.37831390437547]],
    [ "Enedis",[6.656783599570814, 46.36134176537911]],
    [ "Enedis",[0.6780901220452566, 47.42063408407532]],
    [ "Enedis",[0.6930763157294204, 47.423202762003264]],
    [ "Enedis",[0.6758725270319214, 47.4204331089574]],
    [ "Enedis",[0.70197175, 47.422837349999995]],
    [ "Enedis",[0.6965027500000001, 47.41952659999999]],
    [ "rue Follereau",[0.7014561500000007, 47.4255704]],
    [ "Enedis",[0.7047479176748889, 47.427556053336986]],
    [ "Enedis",[0.7090915914491377, 47.42931929239046]],
    [ "Enedis",[0.69327005, 47.42569274999999]],
    [ "Enedis",[0.7068216560414177, 47.43129882686653]],
    [ "Enedis",[0.710619434115564, 47.42181949548368]],
    [ "Enedis",[0.6914797382066791, 47.41892752135156]],
    [ "Enedis",[0.682793513371602, 47.424050677589946]],
    [ "Enedis",[0.6753883419917532, 47.423934062048374]],
    [ "Enedis",[1.017494148898709, 49.400776493109575]],
    [ "Enedis",[1.0381589182834334, 49.3884370849501]],
    [ "Enedis",[1.0356183949633744, 49.38460712319084]],
    [ "Enedis",[1.0218512140942428, 49.38687737661558]],
    [ "Enedis",[1.0148059592782228, 49.40083062155146]],
    [ "Enedis",[1.0399748999999998, 49.390175799999994]],
    [ "Enedis",[1.0299878632768709, 49.39565560245952]],
    [ "Enedis",[1.0310335851063834, 49.39680190146016]],
    [ "Enedis",[1.03434925, 49.38857974999999]],
    [ "Enedis",[1.0264819691135398, 49.38500769562943]],
    [ "Enedis",[1.0352384, 49.37912095]],
    [ "Enedis",[1.0293872047329515, 49.39440783311513]],
    [ "Enedis",[1.0233473354587166, 49.39360374757679]],
    [ "Enedis",[1.0229218788370946, 49.39063154163116]],
    [ "Enedis",[1.0353705, 49.38602505]],
    [ "Enedis",[1.03646755113062, 49.38698244524]],
    [ "Enedis",[1.0554398999999985, 49.39013955]],
    [ "Enedis",[1.0248165822998776, 49.396183342418134]],
    [ "Enedis",[1.0328301455991016, 49.39361213764227]],
    [ "Enedis",[1.0324063095727811, 49.38758551303937]],
    [ "Enedis",[0.6807523416461443, 47.410956419752125]],
    [ "Enedis",[0.6842461386093277, 47.41673317691297]],
    [ "Enedis",[0.6886492887717122, 47.40216102074029]],
    [ "Enedis",[0.6906949539247309, 47.41129934656007]],
    [ "Enedis",[0.685702961455784, 47.40736813002777]],
    [ "Enedis",[0.6808043183576393, 47.40487993690617]],
    [ "Enedis",[0.6794126150202664, 47.41322979908033]],
    [ "Enedis",[0.6868709885250286, 47.41696586049709]],
    [ "Enedis",[0.6831943696773533, 47.41471162548826]],
    [ "Enedis",[0.6883570273280951, 47.410185968776524]],
    [ "Enedis",[0.686871461839939, 47.40972665964411]],
    [ "Enedis",[0.6836809596301838, 47.41155226778929]],
    [ "Enedis",[0.6836791044369304, 47.40921298232337]],
    [ "Enedis",[0.6960110055920289, 47.40842434804539]],
    [ "Enedis",[0.701805748470896, 47.41450789179766]],
    [ "Enedis",[0.6896613103901437, 47.4065524312423]],
    [ "Enedis",[0.7000141133436757, 47.41237219961128]],
    [ "Enedis",[0.712233787135534, 47.406125399390326]],
    [ "Enedis",[0.7110736515227284, 47.402536661429934]],
    [ "Enedis",[6.235923207815829, 44.67221573407445]],
    [ "Enedis",[0.7057775548153554, 47.415321593653736]],
    [ "Enedis",[0.7096761734565964, 47.40956982883178]],
    [ "Enedis",[0.7045248185076416, 47.41057476756821]],
    [ "Enedis",[0.7161170857785413, 47.403271691284665]],
    [ "Enedis",[0.7059757871795921, 47.41209714691216]],
    [ "Enedis",[0.7081936210920678, 47.41567886037592]],
    [ "Enedis",[0.7064909036632825, 47.41777566927222]],
    [ "Enedis",[6.575148448706104, 46.37216660381847]],
    [ "Enedis",[6.566624884800291, 46.368704598028955]],
    [ "Enedis",[6.5711824347047045, 46.37658684156974]],
    [ "Enedis",[6.59532672327113, 46.368081883644344]],
    [ "Enedis",[6.496007935621732, 46.34964457390143]],
    [ "Enedis",[6.5125443, 46.35053265]],
    [ "Enedis",[6.4974594, 46.35515365]],
    [ "Enedis",[6.519059115933212, 46.35216427513777]],
    [ "Enedis",[6.499095809933222, 46.34905716430162]],
    [ "Enedis",[6.500450679314922, 46.34963944293806]],
    [ "Pinteville",[5.883372934711332, 48.67269060115665]],
    [ "Enedis",[5.8910612150211215, 48.66944325001625]],
    [ "Enedis",[5.891078948462296, 48.67396604086574]],
    [ "Enedis",[5.8994209942087545, 48.68859920848148]],
    [ "Clemenceau",[5.880099954088164, 48.68062111025327]],
    [ "Clos l'Arsenal",[5.890572199008003, 48.679617027304154]],
    [ "Enedis",[5.895382200000001, 48.67544335000001]],
    [ "Enedis",[5.893209951167672, 48.67680912048806]],
    [ "Kennedy",[5.896899143296173, 48.69548721831749]],
    [ "Enedis",[5.910158847055374, 48.69309614372129]],
    [ "L'Embanie",[5.876789123337866, 48.66594870471555]],
    [ "Enedis",[0.6724536968304974, 47.38079376993065]],
    [ "Enedis",[0.6766521997491663, 47.375516153496555]],
    [ "Enedis",[0.670155900642175, 47.37612284512044]],
    [ "Enedis",[0.6730515828273633, 47.38543912067768]],
    [ "Enedis",[0.6719150553893521, 47.3763538708315]],
    [ "Enedis",[0.6691403405549158, 47.37722662898892]],
    [ "Enedis",[0.6732736764763702, 47.389922624205624]],
    [ "Enedis",[0.6717297500000008, 47.374696799999995]],
    [ "Enedis",[0.6918530092879456, 47.375829651979345]],
    [ "Enedis",[0.686549594666666, 47.374687618712265]],
    [ "Enedis",[0.6905137558849775, 47.384401262531526]],
    [ "Enedis",[0.6838500815015714, 47.38786233057936]],
    [ "Enedis",[0.6836508992829797, 47.37911342385677]],
    [ "Enedis",[0.6846548600295153, 47.38227207407885]],
    [ "Enedis",[0.6788909441025196, 47.38326495773842]],
    [ "Enedis",[0.6941247343089224, 47.376522045294536]],
    [ "Enedis",[0.677754993059036, 47.385717206189184]],
    [ "Enedis",[0.67805501321139, 47.37632836638195]],
    [ "Enedis",[0.6832961834964123, 47.378170217677756]],
    [ "Enedis",[0.6860516933504871, 47.385631243135386]],
    [ "Enedis",[0.6804678500000001, 47.37971355]],
    [ "Enedis",[0.681154713661801, 47.38832046462631]],
    [ "Enedis",[0.6910252684260135, 47.38141381948402]],
    [ "Enedis",[0.6840149804427124, 47.388003190065355]],
    [ "Enedis",[0.68413165, 47.37593875]],
    [ "Enedis",[0.6831415726695261, 47.37771289029506]],
    [ "Enedis",[0.6893783578717498, 47.37657421144069]],
    [ "Enedis",[0.6890361872544832, 47.37393266557874]],
    [ "Enedis",[0.689024248847443, 47.38864897288102]],
    [ "Enedis",[0.6819545754120258, 47.38140262002755]],
    [ "Enedis",[0.678750228314595, 47.387805754934945]],
    [ "Enedis",[6.552512450000002, 46.35422845]],
    [ "Enedis",[6.5643147000000015, 46.352959299999995]],
    [ "Enedis",[6.54823425, 46.35815320000001]],
    [ "Enedis",[6.5391009516910135, 46.365577593834026]],
    [ "Enedis",[6.618908676370609, 46.349170752214754]],
    [ "Enedis",[5.314893849999999, 43.3641766]],
    [ "Enedis",[5.3158474, 43.3628403]],
    [ "Enedis",[5.31347518250081, 43.3702595998513]],
    [ "Enedis",[5.309326428996, 43.36195436262723]],
    [ "Enedis",[5.346730008071825, 43.35454919638142]],
    [ "Enedis",[5.3345080861640595, 43.36038922241777]],
    [ "Enedis",[5.341984551790911, 43.35337357971395]],
    [ "Enedis",[5.336911919631952, 43.357697397000315]],
    [ "Enedis",[5.3273253041910165, 43.36216407924831]],
    [ "Enedis",[5.34757378989815, 43.35171196703994]],
    [ "Enedis",[5.327500100000001, 43.35477365]],
    [ "Enedis",[5.329707405684153, 43.36078185295873]],
    [ "Enedis",[5.337788624188531, 43.35605976040954]],
    [ "Enedis",[5.332788049031666, 43.35450525978385]],
    [ "Enedis",[5.3496272000000005, 43.354757199999995]],
    [ "Enedis",[5.330823731239345, 43.35579038592276]],
    [ "Enedis",[5.337980743830158, 43.3548980741737]],
    [ "Enedis",[5.33177918190067, 43.36028035817224]],
    [ "Enedis",[5.346086379484546, 43.35117975655292]],
    [ "Enedis",[5.334090181099013, 43.35903945662642]],
    [ "Enedis",[5.342840255264349, 43.357336050816194]],
    [ "Enedis",[5.338836874755144, 43.35922360014495]],
    [ "Enedis",[5.331427964574634, 43.364094736823546]],
    [ "Enedis",[5.3338642577768915, 43.35797533481915]],
    [ "Enedis",[5.31336569695306, 43.36280907118719]],
    [ "Enedis",[5.345552950094437, 43.360994666271026]],
    [ "Enedis",[5.326860584347789, 43.36051860480927]],
    [ "Enedis",[5.329968561433205, 43.36209464472659]],
    [ "Enedis",[5.3104093662282805, 43.36948481142359]],
    [ "Enedis",[5.342458617344687, 43.360587428997164]],
    [ "Enedis",[5.352110443399128, 43.35266917579511]],
    [ "Enedis",[5.33438645, 43.36738115]],
    [ "Enedis",[5.311802465444299, 43.367681196060424]],
    [ "Enedis",[5.303254474144906, 43.363385807129774]],
    [ "Enedis",[5.340032982027715, 43.35193030847507]],
    [ "Enedis",[5.322637553316238, 43.360081367337806]],
    [ "Enedis",[5.347301012521987, 43.35339297618223]],
    [ "Enedis",[5.309795730877976, 43.36334585560413]],
    [ "Enedis",[5.318463807793809, 43.36260237902235]],
    [ "Enedis",[5.339013275285529, 43.35559945211351]],
    [ "Enedis",[5.337407468724513, 43.35339355914136]],
    [ "Enedis",[5.3119076168811, 43.361623328031634]],
    [ "Enedis",[5.332317157342394, 43.36888882380521]],
    [ "Enedis",[5.300693620602655, 43.36103665248689]],
    [ "Enedis",[5.328978987272188, 43.361337778882344]],
    [ "Enedis",[5.310402936654029, 43.36444205021503]],
    [ "Enedis",[5.33170530401261, 43.36420468159731]],
    [ "Enedis",[5.32336647009983, 43.36094119242684]],
    [ "Enedis",[5.321607500000001, 43.362631849999985]],
    [ "Enedis",[5.34228236153121, 43.34985792920882]],
    [ "Enedis",[5.346601617124484, 43.36550755094171]],
    [ "Enedis",[5.340619171223406, 43.35679174478946]],
    [ "Enedis",[5.319542119871416, 43.35856222350503]],
    [ "Enedis",[5.336889034660656, 43.35898637097955]],
    [ "Enedis",[5.3339715117356485, 43.35695461332769]],
    [ "Enedis",[5.342766058778667, 43.358785434240666]],
    [ "Enedis",[5.321962720727272, 43.36134304024243]],
    [ "Enedis",[5.3509277104078885, 43.35441408066349]],
    [ "Enedis",[5.347655100000001, 43.34206050000001]],
    [ "Enedis",[5.321680577442055, 43.36360711670649]],
    [ "Enedis",[5.3387523034508755, 43.35353298107032]],
    [ "Enedis",[5.38043192380047, 43.35323045579153]],
    [ "Enedis",[2.8423160454638223, 50.46151004884741]],
    [ "Enedis",[2.8601823704821085, 50.473191873917386]],
    [ "Enedis",[2.875045629647041, 50.47703577381461]],
    [ "Enedis",[2.862008350789623, 50.46748882820951]],
    [ "Enedis",[2.842443280756292, 50.461426966832086]],
    [ "Enedis",[2.8637436301757058, 50.47795970535893]],
    [ "Enedis",[2.8424811686659, 50.44961342089521]],
    [ "Enedis",[2.8607185951640757, 50.46490300759931]],
    [ "Enedis",[2.867910961487331, 50.47506721294181]],
    [ "Enedis",[2.8628568364661575, 50.478870232662175]],
    [ "Enedis",[2.8724330929670905, 50.479790431740554]],
    [ "Enedis",[2.86085496754988, 50.4702003982921]],
    [ "Enedis",[2.860261484650796, 50.477065417843775]],
    [ "Enedis",[2.8441243737092714, 50.45705059139514]],
    [ "Enedis",[2.8669029441025415, 50.46744981163607]],
    [ "Enedis",[2.834187021712694, 50.46746132145924]],
    [ "Enedis",[2.8697164920443847, 50.47337265328642]],
    [ "Enedis",[2.8406359481966295, 50.461784707909395]],
    [ "Enedis",[2.8365932499236988, 50.45260798114895]],
    [ "Enedis",[2.8618325258757653, 50.47424517102692]],
    [ "Enedis",[2.8449866572939717, 50.4532692654027]],
    [ "Enedis",[2.861334320024031, 50.45741141950887]],
    [ "Enedis",[2.8269792373982936, 50.46441739204804]],
    [ "Enedis",[2.8425016497699427, 50.46169998528189]],
    [ "Enedis",[2.8274975121716026, 50.46382060586163]],
    [ "Enedis",[2.843051538421911, 50.45455699327726]],
    [ "Enedis",[2.8703225350141452, 50.47780119306953]],
    [ "Enedis",[2.8588687211210875, 50.47878035814067]],
    [ "Enedis",[-0.4637358549095987, 49.33664553734393]],
    [ "Enedis",[4.03757386237747, 50.20249626456538]],
    [ "Enedis",[6.833752174877231, 43.51922121237352]],
    [ "Enedis",[6.725754028690818, 43.38614866984373]],
    [ "Cenon",[-0.5279192481919885, 44.85486806109923]],
    [ "Enedis",[0.1240365563897915, 47.236155846465]],
    [ "Enedis",[0.7402090663222705, 47.28368181352127]],
    [ "Enedis",[0.7371335019146668, 47.28246164897562]],
    [ "Enedis",[0.7399009942027363, 47.28190809127331]],
    [ "Enedis",[0.736701582560521, 47.28809912974852]],
    [ "Enedis",[0.7416448185152102, 47.27751233167556]],
    [ "Enedis",[0.7411305350169352, 47.288692124125944]],
    [ "Enedis",[0.717515069094019, 47.30539126636083]],
    [ "Enedis",[0.7061189503945221, 47.308187170555136]],
    [ "Enedis",[0.6964372433307787, 47.38071799929421]],
    [ "Enedis",[0.6966703187545779, 47.389699866562026]],
    [ "Enedis",[0.6999372294799671, 47.38696909350525]],
    [ "Enedis",[0.7011954595562601, 47.37540116343536]],
    [ "Enedis",[0.6937447251862987, 47.385730069407764]],
    [ "Enedis",[0.7051685409526007, 47.384737622112354]],
    [ "Enedis",[0.7076486818249412, 47.37928219734065]],
    [ "Enedis",[0.6965141499999999, 47.377675049999986]],
    [ "Enedis",[0.7062019416987839, 47.38873972165287]],
    [ "Enedis",[0.7016109534804877, 47.38953430929679]],
    [ "Enedis",[0.7073960212620425, 47.38200103665877]],
    [ "Enedis",[6.875640156259316, 43.520659285043486]],
    [ "Enedis",[0.9219970500706874, 46.108910487442145]],
    [ "Enedis",[0.6972265506952879, 47.377323721842366]],
    [ "Enedis",[0.7071071055285643, 47.37762849525233]],
    [ "Enedis",[0.7033372134177734, 47.37663806473417]],
    [ "Enedis",[0.7102587027200028, 47.38012249511943]],
    [ "Enedis",[6.875681287134617, 43.521728511991654]],
    [ "Enedis",[3.49224705, 43.57524815000001]],
    [ "Enedis",[2.8863691415724717, 50.615615165971775]],
    [ "Enedis",[2.88354055240611, 50.61478733239533]],
    [ "Poste électrique de Champagnier",[5.709151667840281, 45.10635545978711]],
    [ "Montagna",[0.09529837315313522, 43.25377215747435]],
    [ "Enedis",[3.3168526900056383, 49.38773226201175]],
    [ "Enedis",[3.3099266121470783, 49.37505142037645]],
    [ "Enedis",[3.328280370920246, 49.38406004870424]],
    [ "Enedis",[3.315085110009961, 49.37260457441407]],
    [ "Enedis",[3.3105720499999998, 49.37385294999999]],
    [ "Enedis",[3.312750855377359, 49.38529334936147]],
    [ "Enedis",[3.318402050619636, 49.386532283710466]],
    [ "Enedis",[3.3157538075715043, 49.37769612507422]],
    [ "Enedis",[3.3221512707646554, 49.3887994005006]],
    [ "Enedis",[3.3316051369816813, 49.38285062936321]],
    [ "Enedis",[3.3052747831099465, 49.3814392318749]],
    [ "Enedis",[3.3091367999999988, 49.369082799999994]],
    [ "Enedis",[3.3225216341811765, 49.372652212963764]],
    [ "Enedis",[3.3322102602815336, 49.376198096219]],
    [ "Enedis",[3.3262628722754015, 49.37420238677183]],
    [ "Enedis",[3.341588171590785, 49.38314632507643]],
    [ "Enedis",[3.3355835355474235, 49.375403199414464]],
    [ "Enedis",[3.3057818220921344, 49.373076111022336]],
    [ "Enedis",[3.319612424252567, 49.37361190566466]],
    [ "Enedis",[3.3118537999999997, 49.37784859999999]],
    [ "Enedis",[3.320987423668631, 49.380335541411476]],
    [ "Enedis",[3.317482492229687, 49.39076901658668]],
    [ "Enedis",[3.3195453733144142, 49.3847810967991]],
    [ "Enedis",[3.324328415978277, 49.37884874838493]],
    [ "Enedis",[3.30568405, 49.37452354999999]],
    [ "Enedis",[3.322620527468742, 49.38085999500906]],
    [ "Enedis",[3.327964405671483, 49.381440059092064]],
    [ "Enedis",[-2.275764091822031, 48.02071433059813]],
    [ "69881551",[-2.146982475247533, 48.05698649498971]],
    [ "Enedis",[0.15846324142352988, 49.48070765603724]],
    [ "Enedis",[0.15406925184758877, 49.480273720432095]],
    [ "Enedis",[0.157452184428729, 49.47868467285243]],
    [ "SRD",[0.015396270806867918, 46.813384434771685]],
    [ "SRD",[0.016859128177458032, 46.810326980335724]],
    [ "Enedis",[0.11044009704440386, 49.494264121981985]],
    [ "Saint-Maude",[-3.3894604500000005, 47.744910700000005]],
    [ "Enedis",[6.7776740902727735, 47.951119836925415]],
    [ "Enedis",[1.082308309996134, 49.44608377349768]],
    [ "Enedis",[6.63330755, 46.34279979999999]],
    [ "Enedis",[6.638998350000001, 46.34030065000001]],
    [ "Enedis",[6.635099089551247, 46.34011959324905]],
    [ "Fion",[6.645050100000001, 46.331292250000004]],
    [ "Enedis",[6.637182515955375, 46.33040113917848]],
    [ "Enedis",[1.0968646798157948, 47.80217006989516]],
    [ "Enedis",[1.0947133706616439, 47.80402645607057]],
    [ "Enedis",[3.1899336831988827, 50.66962419047896]],
    [ "Enedis",[3.1707242022013107, 50.661980293374874]],
    [ "Enedis",[3.1779687904975087, 50.65542145717282]],
    [ "Enedis",[3.1739642416513836, 50.6700613817993]],
    [ "69978966",[3.2040265362740037, 50.664058451903934]],
    [ "Enedis",[3.184700177545118, 50.663337987617396]],
    [ "Enedis",[3.1716166898829115, 50.66645596425512]],
    [ "Enedis",[3.176944068923804, 50.66666544032683]],
    [ "Enedis",[3.1901723674085756, 50.65038304881568]],
    [ "Enedis",[3.1813601970086225, 50.657151849850436]],
    [ "Enedis",[3.187057967295697, 50.65733100935776]],
    [ "Enedis",[3.1867519911136304, 50.65287762639765]],
    [ "Enedis",[3.195399974614446, 50.65742996645018]],
    [ "Enedis",[3.196425051594469, 50.66406179379663]],
    [ "Enedis",[3.198191652847566, 50.6613890498815]],
    [ "Enedis",[3.1772207929102834, 50.67068166904449]],
    [ "Enedis",[3.196943012710063, 50.64852801953353]],
    [ "Enedis",[3.2028324832712305, 50.66785862838331]],
    [ "Enedis",[3.2005186497888545, 50.666032763027516]],
    [ "Poste électrique de Gambetta",[2.399302622001705, 48.867970113030495]],
    [ "École Julien Lacroix",[2.3854322095707254, 48.86967607999743]],
    [ "Enedis",[6.760741853844835, 47.99155052710091]],
    [ "Enedis",[2.3998121630786398, 50.80715787876634]],
    [ "Enedis",[2.3826709322990456, 50.80612197102358]],
    [ "Enedis",[0.4363832165543696, 47.27146039957154]],
    [ "Enedis",[2.40492535905823, 50.80341439936226]],
    [ "Enedis",[0.46525585674351344, 47.26961783257602]],
    [ "Enedis",[0.45960190300383885, 47.26268949984495]],
    [ "Enedis",[0.4643184144198159, 47.260477304005434]],
    [ "Enedis",[0.47147130000000004, 47.26508745]],
    [ "Enedis",[0.4666578875225359, 47.260341871468086]],
    [ "Enedis",[0.7391954693407685, 47.41785123581279]],
    [ "Enedis",[0.7577606031395969, 47.41381931140555]],
    [ "Enedis",[0.7409418965051908, 47.41335081247277]],
    [ "Enedis",[0.7567998777328094, 47.41743883494913]],
    [ "Enedis",[0.7549602534367623, 47.42862203017512]],
    [ "Enedis",[0.4964582034921262, 47.3617939963522]],
    [ "Enedis",[0.5132956503999517, 47.3702295387485]],
    [ "Enedis",[0.7591620337604162, 47.422768620382705]],
    [ "Enedis",[0.7366115973522482, 47.405905379928]],
    [ "Enedis",[0.7636298096636176, 47.42021142592456]],
    [ "Enedis",[0.5157992191292492, 47.36970069690373]],
    [ "RTE",[2.6451589477025967, 46.53645290665]],
    [ "Enedis",[5.369650045469075, 43.28919549807307]],
    [ "Enedis",[2.4897128139747786, 46.372296015812495]],
    [ "Enedis",[2.4835485270281157, 46.36554015534173]],
    [ "Enedis",[2.4523060999747783, 46.38050748549293]],
    [ "Enedis",[2.4696838551214615, 46.37309251557266]],
    [ "Enedis",[2.47572252599561, 46.368402363493104]],
    [ "Enedis",[2.478394693973433, 46.37379440837357]],
    [ "Enedis",[2.502527029422029, 46.36644319174875]],
    [ "Enedis",[1.450359293211076, 43.597974079028745]],
    [ "Enedis",[1.4494099693913602, 43.597033065558584]],
    [ "Enedis",[6.675538723292256, 46.32137026800548]],
    [ "Enedis",[6.680111749999999, 46.32578145]],
    [ "Enedis",[6.66408262649535, 46.32158687117016]],
    [ "Enedis",[6.65949425, 46.326551]],
    [ "Enedis",[1.185946892451339, 45.83497933508484]],
    [ "Dojo",[1.2821901689465582, 45.81795633586508]],
    [ "Bollée Eni",[1.2877170343102853, 45.8177277733285]],
    [ "Enedis",[1.2590005999999998, 45.7983058]],
    [ "Enedis",[6.83247965, 47.9741713]],
    [ "Enedis",[6.830917823236146, 47.96535241970136]],
    [ "Enedis",[6.831013116862409, 47.942300660250694]],
    [ "Enedis",[6.8353401499999995, 47.96209135]],
    [ "Enedis",[6.8381776450226255, 47.962129284992464]],
    [ "26 avenue Gambetta",[2.3957775326480775, 48.864874120826215]],
    [ "Enedis",[0.6630226286517582, 47.44716591384301]],
    [ "Enedis",[0.7935010062024227, 47.411630184231264]],
    [ "Enedis",[0.5526044032029285, 47.547882731760474]],
    [ "Enedis",[0.78168778022016, 47.41806181254563]],
    [ "Enedis",[0.8225417375506863, 47.41201949292483]],
    [ "Enedis",[0.8116976377775219, 47.411914973913866]],
    [ "Enedis",[0.7982066785704229, 47.41125097709699]],
    [ "Enedis",[0.8046811550598613, 47.41275941875929]],
    [ "Enedis",[0.8015590180501998, 47.41810293047348]],
    [ "Enedis",[0.7973614108905375, 47.41506628692551]],
    [ "Enedis",[3.956953606893978, 46.79251168418251]],
    [ "PL Aqueduc",[2.2761330958656925, 48.78148925866962]],
    [ "Enedis",[1.1038894763313636, 47.81175160896984]],
    [ "Enedis",[1.1010682058288812, 47.8113417753]],
    [ "Enedis",[2.3984427462727402, 48.701610582707545]],
    [ "Lycée d'Athis",[2.3780363539713485, 48.69757000436542]],
    [ "Enedis",[2.392625545992391, 48.69599287529239]],
    [ "Salvers",[2.3988983166963336, 48.709450104173165]],
    [ "Enedis",[2.4071382999999997, 48.7164778]],
    [ "Enedis",[3.2071463999999996, 50.6057096]],
    [ "Enedis",[3.2044552263749364, 50.60729341622163]],
    [ "Enedis",[3.20279714931325, 50.60569440745861]],
    [ "Enedis",[3.2127384233356318, 50.61094036821421]],
    [ "Enedis",[3.211340386613407, 50.61662191030588]],
    [ "Enedis",[3.2135849925640043, 50.6095785559912]],
    [ "Enedis",[3.200181533475293, 50.609175130028156]],
    [ "Enedis",[3.2076730613063953, 50.613149129770235]],
    [ "Enedis",[3.2098518385651724, 50.61215144463922]],
    [ "Enedis",[3.1956073228723936, 50.611442296449034]],
    [ "Enedis",[4.0123934645840835, 50.20057232380479]],
    [ "Enedis",[3.1872331229166666, 50.63026657291666]],
    [ "Enedis",[3.1921846422542752, 50.6348455583274]],
    [ "Enedis",[3.187780394127896, 50.63197857421153]],
    [ "Enedis",[3.1926838138524922, 50.63273378139234]],
    [ "Enedis",[3.1894531622232667, 50.63567216150995]],
    [ "Enedis",[3.187844748341513, 50.635210845288036]],
    [ "Enedis",[3.1892351673348664, 50.63720605215022]],
    [ "R L Étang",[-3.4991598841096487, 47.7384309121034]],
    [ "Enedis",[2.2591594037755725, 48.96352494205394]],
    [ "Enedis",[2.262378439385345, 48.96715382940958]],
    [ "Enedis",[2.2654334181153457, 48.96468889687777]],
    [ "Enedis",[2.2672775722737497, 48.9659102296979]],
    [ "Enedis",[-2.097013452084946, 48.001967842902]],
    [ "Enedis",[-2.0846409468951714, 48.00732309109858]],
    [ "Enedis",[-2.045905764772879, 48.02858772993793]],
    [ "Enedis",[2.248329277182935, 48.958987322457354]],
    [ "Enedis",[2.2560581363036816, 48.96224226895158]],
    [ "Enedis",[2.254176739343042, 48.96008884206632]],
    [ "Enedis",[2.2459580106018944, 48.95854454030795]],
    [ "Enedis",[2.2482430110295795, 48.96097813328298]],
    [ "Enedis",[2.261843461415636, 48.95690448900694]],
    [ "Enedis",[2.2538988708295573, 48.95325763048393]],
    [ "Enedis",[2.25851115088481, 48.95621972021374]],
    [ "Enedis",[2.251893275298519, 48.95798450431343]],
    [ "Enedis",[2.2613120520938637, 48.95898609736429]],
    [ "Enedis",[2.2569139946005286, 48.96036757505108]],
    [ "Enedis",[-1.7029904329651866, 47.98755754463257]],
    [ "Enedis",[-1.7022781074238031, 47.989156004033234]],
    [ "Enedis",[-1.7104420507169977, 47.978442665355765]],
    [ "Enedis",[0.09914289447595222, 49.49500430792077]],
    [ "Enedis",[0.18008101991540112, 49.51298634365987]],
    [ "Enedis",[0.18571837185070258, 49.50255909617804]],
    [ "Enedis",[2.172167083302883, 48.765349619040826]],
    [ "Enedis",[2.181035253101179, 48.76811008403326]],
    [ "Enedis",[2.1673959105404914, 48.768787893756894]],
    [ "Enedis",[2.14711865, 48.771046049999995]],
    [ "Enedis",[2.1510027965904377, 48.76908256470383]],
    [ "Les Metz",[2.161883041968446, 48.774522886177735]],
    [ "Enedis",[2.1682841905061223, 48.76767059029434]],
    [ "Enedis",[2.160687177823208, 48.76854925533693]],
    [ "Enedis",[-3.4967691215013184, 47.82198030593195]],
    [ "Villeneuve Élle",[-3.519629119146384, 47.77072006742765]],
    [ "Enedis",[-3.5206751110217573, 47.77454973574737]],
    [ "Enedis",[-3.4906224898578198, 47.7904969299526]],
    [ "Loisir Coët Mor P136",[-3.5164480221425225, 47.779118902744116]],
    [ "Enedis",[-3.495189187944005, 47.7975231241603]],
    [ "Enedis",[-3.4859629483454246, 47.79151328377971]],
    [ "Enedis",[-3.5178961725807594, 47.77634740293065]],
    [ "Enedis",[-3.50433255, 47.773470800000005]],
    [ "Enedis",[-3.4849749455228296, 47.819389752610206]],
    [ "Enedis",[-3.5004989135106634, 47.79474870719719]],
    [ "Enedis",[-3.4774915271281386, 47.81606739327147]],
    [ "Enedis",[-3.4996044, 47.796328800000005]],
    [ "Kerhouat",[-3.5177634752293776, 47.76804084333224]],
    [ "Les terrasses",[-3.52456095, 47.77080625]],
    [ "Enedis",[-3.4924685508262394, 47.78288673411545]],
    [ "Enedis",[-3.4913247500000004, 47.795439900000005]],
    [ "Enedis",[-3.4893528775468687, 47.78814067657522]],
    [ "Enedis",[-3.4922720999690218, 47.78572954313812]],
    [ "Enedis",[-3.4931838000000006, 47.7973362]],
    [ "Enedis",[-3.4656103519736137, 47.79687451667469]],
    [ "Enedis",[-3.484531497313449, 47.81718113792285]],
    [ "Enedis",[-3.5179623668074838, 47.77409811427931]],
    [ "RTE",[2.5905929476462752, 46.386809095533216]],
    [ "Poste électrique de Montluçon",[2.604230087757722, 46.320221361218294]],
    [ "BV CORDY",[2.212864039352082, 48.7509896789838]],
    [ "BV BièvresVillage",[2.2164589, 48.75622405]],
    [ "Enedis",[2.2327095386820055, 48.76606198014188]],
    [ "Enedis",[2.2064834473928423, 48.756327770773495]],
    [ "Enedis",[2.2118286887610825, 48.75294527983098]],
    [ "Enedis",[2.1949758883422104, 48.76174712422915]],
    [ "CELINE",[2.2135083000000004, 48.75449545000001]],
    [ "Enedis",[2.2194187679946182, 48.75018765559513]],
    [ "Enedis",[2.1974959065315876, 48.76099572241332]],
    [ "Enedis",[2.2175268000545967, 48.75160624991531]],
    [ "Enedis",[2.2191637546497915, 48.754174453265385]],
    [ "Enedis",[2.220385892704626, 48.75951914516996]],
    [ "Castelmat",[2.2196893081215316, 48.75269585994431]],
    [ "BV Petit Bievres",[2.215118706170055, 48.752838811981576]],
    [ "Enedis",[2.2092719373639857, 48.75148463184143]],
    [ "Enedis",[2.222529587326608, 48.761291953891195]],
    [ "Kergornet",[-3.434289414211807, 47.81339641465562]],
    [ "Kerguestenen",[-3.4272410500000006, 47.80354425]],
    [ "Enedis",[1.0837733163140053, 49.390626643391435]],
    [ "Enedis",[1.0847355003430124, 49.388932166788884]],
    [ "Enedis",[1.0643676364725387, 49.395447920726646]],
    [ "Enedis",[1.10823975118459, 49.37428072464079]],
    [ "Enedis",[1.085046703961902, 49.396929047309904]],
    [ "Enedis",[1.093320504387668, 49.370919280780335]],
    [ "Enedis",[1.1041141202872526, 49.367007991741474]],
    [ "Enedis",[1.0691997798428547, 49.403974122875056]],
    [ "Enedis",[1.0957417116489303, 49.36929126543083]],
    [ "Enedis",[1.080405915268045, 49.39181807615014]],
    [ "Enedis",[1.1025940180459686, 49.37663662094021]],
    [ "Enedis",[1.0730113760395714, 49.39752216157485]],
    [ "Enedis",[1.0704866428879742, 49.398720926313665]],
    [ "Enedis",[1.1047423153818452, 49.3657442761576]],
    [ "Enedis",[1.1102847622913925, 49.36464266839209]],
    [ "Enedis",[1.107672842673641, 49.37774830614308]],
    [ "Enedis",[1.0699169355559206, 49.398330018023664]],
    [ "Enedis",[1.0894740733470405, 49.38870344894919]],
    [ "Enedis",[1.0987758780741153, 49.374708897229624]],
    [ "Enedis",[1.0836729818849453, 49.39054236359069]],
    [ "Enedis",[1.0953635103479324, 49.37779185517346]],
    [ "Enedis",[1.0687817212966582, 49.40269907625888]],
    [ "Enedis",[1.0704796237398373, 49.39867669040649]],
    [ "Enedis",[1.1110039301185677, 49.377524794615766]],
    [ "Enedis",[1.0842837338675768, 49.39089184241547]],
    [ "Enedis",[1.0730388009728573, 49.39677849488099]],
    [ "Enedis",[1.1049799309125903, 49.364582982991166]],
    [ "Enedis",[1.103386784716702, 49.37193222557957]],
    [ "Enedis",[1.094770581994257, 49.37115708393703]],
    [ "Enedis",[1.085326799189712, 49.392344433380366]],
    [ "Enedis",[1.1003657811778267, 49.397444526522264]],
    [ "Enedis",[1.0744166680256992, 49.40239096120535]],
    [ "Enedis",[1.077224958528924, 49.386779749738494]],
    [ "Enedis",[1.341844584794239, 43.537659886067495]],
    [ "Enedis",[1.340470006888493, 43.53631929045936]],
    [ "Enedis",[1.3414196333849133, 43.546134253307564]],
    [ "Enedis",[1.3428726052896032, 43.53990558485415]],
    [ "Enedis",[1.325313566311835, 43.55369703711821]],
    [ "Enedis",[1.3380311434077872, 43.54367917423973]],
    [ "Enedis",[1.3498337441642907, 43.548833531909196]],
    [ "Enedis",[1.3485140461873852, 43.538328343594614]],
    [ "Enedis",[1.336656070076092, 43.53886261002578]],
    [ "Enedis",[1.3234036653286378, 43.54503703143644]],
    [ "Léo Lagrange",[1.3505690351908324, 43.53810184488956]],
    [ "Enedis",[1.3481284381381362, 43.54482159541767]],
    [ "Enedis",[5.1702019, 43.3312507]],
    [ "Enedis",[1.3276506307978837, 43.55191230923287]],
    [ "Enedis",[1.3439634625756862, 43.54793024624862]],
    [ "Enedis",[1.3563303459263665, 43.552461662520734]],
    [ "Enedis",[1.3646384799825337, 43.552243977827544]],
    [ "Enedis",[1.3396294864360632, 43.549301336992556]],
    [ "Rond Point",[1.3459947535259715, 43.54925824922109]],
    [ "Enedis",[1.3341179345884029, 43.54118071626879]],
    [ "Enedis",[1.3488956840469086, 43.53481880223124]],
    [ "Enedis",[1.335775816474481, 43.54263990029068]],
    [ "Enedis",[1.3444629008347615, 43.537782933534196]],
    [ "Enedis",[1.3384280795668273, 43.53411248615135]],
    [ "Enedis",[1.3728917343351636, 43.554202607127976]],
    [ "Enedis",[1.3598893683136448, 43.552609449188886]],
    [ "Enedis",[1.3472418967717121, 43.54170554066947]],
    [ "Enedis",[1.327579892037757, 43.54306946609228]],
    [ "Enedis",[1.348194566779525, 43.5327043815439]],
    [ "Enedis",[1.3361690576771532, 43.545315835590436]],
    [ "Enedis",[1.3376337430568337, 43.55796283223591]],
    [ "Enedis",[1.347544568378761, 43.53963242513737]],
    [ "Enedis",[1.344901419337971, 43.535934812578034]],
    [ "Enedis",[1.351389472622086, 43.54151413693443]],
    [ "Enedis",[1.354180071416979, 43.5531585285465]],
    [ "Enedis",[1.359638573942081, 43.53350248564677]],
    [ "Enedis",[1.3315123784007072, 43.53894135136612]],
    [ "Enedis",[1.3463325233250623, 43.54571739854393]],
    [ "Enedis",[1.3635297397267434, 43.53573695484082]],
    [ "Enedis",[1.3430780713024326, 43.5418296623234]],
    [ "Enedis",[1.3516272724916338, 43.54657170986495]],
    [ "Enedis",[1.3324370788435653, 43.55572476069138]],
    [ "Enedis",[1.367844375856911, 43.53248294247313]],
    [ "Enedis",[1.3518057994750061, 43.5560728164679]],
    [ "Enedis",[1.3413267218716605, 43.542851463721945]],
    [ "Enedis",[1.3594426054848323, 43.53659001511023]],
    [ "Enedis",[1.344523108150361, 43.53346151246994]],
    [ "Enedis",[1.345058016230504, 43.53111779364046]],
    [ "Enedis",[1.3541460424893068, 43.540481491184934]],
    [ "Enedis",[1.3554734830829867, 43.534801284145644]],
    [ "Enedis",[1.3387380205207386, 43.53820334117389]],
    [ "Enedis",[1.3667306196786309, 43.55325306165832]],
    [ "Enedis",[1.3415693964839681, 43.533558013728396]],
    [ "Enedis",[1.3629994260142884, 43.55730454742554]],
    [ "Enedis",[1.3691701401818233, 43.553968754931326]],
    [ "Enedis",[1.3507933414514894, 43.533192889437174]],
    [ "Enedis",[1.33016740165104, 43.53537936306261]],
    [ "Enedis",[1.3716794459493864, 43.55226616457887]],
    [ "Enedis",[1.3429726793442847, 43.53659093482016]],
    [ "Enedis",[1.3364892449064143, 43.532835782706044]],
    [ "Enedis",[1.3532860713319044, 43.5360606473588]],
    [ "Enedis",[1.3422817746686568, 43.557637043170715]],
    [ "Enedis",[1.339162647339969, 43.54444055972773]],
    [ "Enedis",[6.1892727, 45.79268940000001]],
    [ "Cimetière",[-3.414002, 47.792643]],
    [ "Duingt Coupure",[6.200663545913759, 45.82621701196192]],
    [ "Enedis",[6.183959473534618, 45.82244241773833]],
    [ "Enedis",[6.189172628660041, 45.828978494085504]],
    [ "Camping municipal",[6.188792484873223, 45.82656899797494]],
    [ "Enedis",[6.208177879512524, 45.82560909766982]],
    [ "Enedis",[6.187849652385285, 45.82355663838316]],
    [ "La Baie des Voiles",[6.2004368035399136, 45.82770524485328]],
    [ "Les Perris",[6.1905185271092025, 45.8252847335278]],
    [ "Enedis",[6.202958993564323, 45.82807084605247]],
    [ "Enedis",[6.1965586109463455, 45.81911019302833]],
    [ "Fergy",[6.187321153611438, 45.82116812607915]],
    [ "Enedis",[0.9216812436851852, 46.0582351693523]],
    [ "Enedis",[0.9254244046936254, 46.05716041738516]],
    [ "Enedis",[0.9184168499093477, 46.06113350408413]],
    [ "Enedis",[0.9218331676955563, 46.07052123020458]],
    [ "Vignes",[6.140143365369875, 45.85104662856443]],
    [ "Grands vignobles",[6.149152883250162, 45.84430346578722]],
    [ "ZA Grands Prés",[6.151055406718114, 45.845028356251234]],
    [ "Cordon bleu",[6.140076976113602, 45.862424843104115]],
    [ "Herisson",[6.139039558183901, 45.86585939326657]],
    [ "Brouillet",[6.147362657203099, 45.84719527092309]],
    [ "Les Mongets",[6.14948565, 45.850724549999995]],
    [ "Enedis",[6.141444991574015, 45.876350886584845]],
    [ "Les Daudes",[6.1380622, 45.85863579999999]],
    [ "Enedis",[6.14178465, 45.859031699999996]],
    [ "Lycée Rural",[6.142338130529679, 45.85445424819934]],
    [ "Enedis",[6.143524223713647, 45.8518855620326]],
    [ "Boubioz",[6.140558841128308, 45.85422797901562]],
    [ "Vignes du Bessard",[6.140242134854647, 45.87954929825275]],
    [ "Pompage Avollions",[6.143107020553571, 45.867004304057744]],
    [ "Letras",[6.139512080024263, 45.87442333196945]],
    [ "Cret",[6.140778225926251, 45.869994440383415]],
    [ "Enedis",[6.145458098323966, 45.851159384781816]],
    [ "Vers Rives - Station refoulement 1",[6.146234400000003, 45.85459579999999]],
    [ "Le Clos",[6.1373714894806435, 45.85491048316001]],
    [ "Choseaux",[6.141931124485101, 45.855887702086775]],
    [ "Sevrier Avellard",[6.139485410009893, 45.84876849799588]],
    [ "Enedis",[6.121050755576237, 45.88549279756782]],
    [ "Reservoir Belevder",[6.132465465391298, 45.89191485245109]],
    [ "Rafimidi",[6.115106371126637, 45.88269988988685]],
    [ "Enedis",[6.118437692549305, 45.88705618777698]],
    [ "SPMR",[6.116808413871337, 45.88449870407256]],
    [ "Bouverie",[6.116947681491356, 45.885745292956514]],
    [ "Television",[6.131060773706682, 45.88348891021938]],
    [ "Vovray Coupure",[6.123388752553398, 45.88986519168774]],
    [ "Refoulement 2",[6.213768463781538, 45.842471474982176]],
    [ "Enedis",[6.209765315953782, 45.85197615300468]],
    [ "LES NOLLIATS",[6.216082478821326, 45.849736613587815]],
    [ "Grange 6E",[6.214335749893759, 45.84449158331116]],
    [ "Angon",[6.224547274748811, 45.82643341702182]],
    [ "Refoulement N3",[6.213695950000001, 45.8442596]],
    [ "Enedis",[6.213393291520944, 45.855056524383215]],
    [ "ZA Perroix",[6.2129853, 45.84834765]],
    [ "Bise",[6.211331240159228, 45.83915668238828]],
    [ "Enedis",[6.211336946818532, 45.85058869451138]],
    [ "Pompage Bellerive",[6.219084849999999, 45.832693150000004]],
    [ "Enedis",[6.23797795, 45.836190849999994]],
    [ "Enedis",[3.977432886595647, 47.85924130082365]],
    [ "Enedis",[4.0000685, 47.85000385000001]],
    [ "Enedis",[3.9791667269707887, 47.86125296242496]],
    [ "Enedis",[3.9645794341001053, 47.854861985399964]],
    [ "Scea du petit beru",[4.005069105368046, 47.85347509858188]],
    [ "Enedis",[4.007988580014383, 47.85056841095289]],
    [ "Enedis",[4.020680705773879, 47.84894209434058]],
    [ "Enedis",[3.973739096233297, 47.863329774859906]],
    [ "Enedis",[3.9719800831432837, 47.85334823818582]],
    [ "Enedis",[3.991999172912672, 47.86191213122335]],
    [ "les lourdes",[3.986757110429028, 47.86186752476117]],
    [ "Enedis",[6.1698188259068125, 45.82456449927785]],
    [ "Chapel4A",[6.159111562998105, 45.83516333308921]],
    [ "Enedis",[6.172044922756148, 45.826240724102114]],
    [ "ZAC Tuilerie",[6.177663565453155, 45.83279074488745]],
    [ "Enedis",[6.169935598578325, 45.82267995725874]],
    [ "Monetier",[6.151468549999999, 45.8258041]],
    [ "Port",[6.172309986186167, 45.83890034942118]],
    [ "Croix de Filly",[6.172140142292077, 45.83093241649916]],
    [ "Clos Donjean",[6.1667254, 45.836226599999996]],
    [ "Precoeur",[6.15559754840152, 45.827637066711425]],
    [ "Les bourrales",[6.16397690412289, 45.826347520951906]],
    [ "Charafine",[6.151772828410845, 45.83330313728663]],
    [ "Bergeret",[6.152719907379251, 45.82692401204561]],
    [ "Laudon 3",[6.1631708000000005, 45.83354985]],
    [ "Doucet",[6.1598725000000005, 45.829383500000006]],
    [ "Vieille Église",[6.176663457483322, 45.837385538717506]],
    [ "Enedis",[6.162007618146787, 45.83196400457409]],
    [ "Plage",[6.172872278808498, 45.84251731531936]],
    [ "Enedis",[6.174156550682712, 45.823000784716015]],
    [ "Enedis",[6.167936261821439, 45.83252110718794]],
    [ "Combes",[6.156938693447625, 45.836282923166564]],
    [ "Hameau du Verger",[6.17000262507417, 45.82873549876042]],
    [ "Enedis",[6.184690207400824, 45.8268910482306]],
    [ "Enedis",[-1.88993555, 46.852803249999994]],
    [ "Enedis",[-1.889964561312722, 46.85263262320344]],
    [ "Résidence Météor",[-2.5225792688554116, 47.30057049475565]],
    [ "Enedis",[2.2816576049186366, 48.731099869401795]],
    [ "Enedis",[2.2870721137551095, 48.73326343682223]],
    [ "PALAISEAU 16",[2.2452268955170083, 48.72880719941624]],
    [ "Enedis",[2.27803175, 48.72103725]],
    [ "MY SEPARATION",[2.244922904680831, 48.72647316640642]],
    [ "Enedis",[3.112911302521876, 45.74127763870956]],
    [ "Enedis",[0.6624264598650048, 47.393229286725806]],
    [ "Enedis",[0.6474631827683175, 47.38585271759615]],
    [ "Enedis",[0.6528299642046441, 47.38178583261742]],
    [ "Enedis",[0.6565840988194053, 47.38671412811984]],
    [ "Enedis",[0.6554876809765054, 47.38798265137314]],
    [ "Enedis",[0.663647133515283, 47.38350457596193]],
    [ "Enedis",[0.6545045024830923, 47.3841092783512]],
    [ "Enedis",[0.6624763211882024, 47.37897647109892]],
    [ "Enedis",[0.6577459221738939, 47.38236083069285]],
    [ "Enedis",[0.6425449851187574, 47.38388955968765]],
    [ "Enedis",[0.6643333746240296, 47.38940709077067]],
    [ "Enedis",[0.6078044000000001, 47.3749361]],
    [ "MY Churchill 4",[2.257608870336529, 48.727702110831316]],
    [ "Enedis",[-3.363373706410302, 47.86614516078463]],
    [ "Enedis",[1.1292472110945861, 45.79654427346232]],
    [ "Enedis",[1.1354718468158498, 45.79271068233963]],
    [ "Enedis",[1.1107055632543532, 45.79894561847326]],
    [ "Enedis",[1.1318588370550864, 45.79456972542357]],
    [ "70702437",[1.1323571220198085, 45.795980819336926]],
    [ "Enedis",[1.1264638483837637, 45.794820100103315]],
    [ "Enedis",[1.136462155117213, 45.79874567749769]],
    [ "Enedis",[1.1111402499999998, 45.800557399999995]],
    [ "Enedis",[1.1302484148392815, 45.793634357445114]],
    [ "Enedis",[1.1340129197109037, 45.79061336579451]],
    [ "Enedis",[1.1329134521842354, 45.7976553268794]],
    [ "Enedis",[1.1483245125244104, 45.79833389930945]],
    [ "Enedis",[1.1236557937820133, 45.797161342032425]],
    [ "Enedis",[1.1216037088189024, 45.798147191090244]],
    [ "Enedis",[1.1277019707364235, 45.79275490040294]],
    [ "Enedis",[1.1365263607343439, 45.79398387463621]],
    [ "Enedis",[1.1384569798544224, 45.7968790072401]],
    [ "Enedis",[0.16109597999370867, 49.52221079316524]],
    [ "Enedis",[5.950566449999999, 45.703483]],
    [ "Enedis",[1.3321519727271864, 45.77009082295586]],
    [ "Solférino",[2.2742979479899708, 48.91346233568296]],
    [ "Duremir",[2.2814344500000003, 48.914781000000005]],
    [ "Enedis",[2.293625685463541, 48.915798260784996]],
    [ "Enedis",[-1.8785211000000006, 46.854082899999995]],
    [ "Enedis",[-1.8822727000000001, 46.85526725]],
    [ "Enedis",[-1.8813826999999999, 46.8527761]],
    [ "Enedis",[-1.875873749999999, 46.853634199999995]],
    [ "Enedis",[-1.86980495, 46.8541057]],
    [ "Enedis",[-1.8753312167998317, 46.85715284931698]],
    [ "Enedis",[-1.8749172000000003, 46.855487000000004]],
    [ "Enedis",[-1.8699508999999999, 46.8567885]],
    [ "Enedis",[-1.8666641379059632, 46.85266753019781]],
    [ "Enedis",[-1.8721470500000001, 46.85905805]],
    [ "Enedis",[-1.8674257893420043, 46.85658107067697]],
    [ "Enedis",[-1.8766432999999991, 46.85294785]],
    [ "Enedis",[1.963322936465588, 48.75809995406026]],
    [ "Enedis",[1.947167727831195, 48.75745560336538]],
    [ "Enedis",[1.959191065511488, 48.750951899463914]],
    [ "Enedis",[0.15222649166220523, 43.06644014437228]],
    [ "Enedis",[0.1452757706311258, 43.06427710147076]],
    [ "Enedis",[6.530640549999999, 46.3414663]],
    [ "Enedis",[6.5357532507422915, 46.340679242455835]],
    [ "Enedis",[6.521700100000001, 46.33860655]],
    [ "Enedis",[5.047013384406309, 47.32768165749158]],
    [ "Enedis",[2.417847126063336, 48.73384354672039]],
    [ "Enedis",[2.421060594814795, 48.73140957409622]],
    [ "Enedis",[2.422694799999999, 48.740067149999994]],
    [ "Enedis",[2.4250616, 48.73077]],
    [ "Enedis",[2.441401636817267, 48.73514452466125]],
    [ "Enedis",[2.4376036, 48.73054585]],
    [ "Enedis",[-3.3418477502339576, 47.810397899117426]],
    [ "Enedis",[-3.3787181633797223, 47.80385594393323]],
    [ "Enedis",[-3.311793293864175, 47.808534198448996]],
    [ "Le Floride Av. de Bresson-11",[5.7498091183355875, 45.14694250350073]],
    [ "Enedis",[5.738859941272921, 45.15863829941959]],
    [ "CHAMP DE FOIRE",[5.747940185225075, 45.148657750064054]],
    [ "Les Marronniers",[5.756145705659325, 45.1460958927469]],
    [ "Enedis",[1.5876496279066898, 46.28567009188799]],
    [ "Enedis",[1.0110651477688182, 47.09920956680964]],
    [ "Enedis",[1.0108851712370583, 47.096301406654575]],
    [ "Enedis",[4.968033122995385, 44.889587968133974]],
    [ "Enedis",[4.96098263467505, 44.89827656720737]],
    [ "Enedis",[-1.9055402579400516, 46.84594888699368]],
    [ "Enedis",[3.4376468051125766, 46.19533481002958]],
    [ "Enedis",[3.4335430803927203, 46.20746442040545]],
    [ "Enedis",[3.433528966788987, 46.198035750150375]],
    [ "Enedis",[3.438134362510182, 46.20381033454889]],
    [ "Enedis",[3.4400189046731287, 46.204553954876154]],
    [ "Enedis",[-1.900916888986114, 46.84988597320857]],
    [ "Enedis",[-1.8918557286613524, 46.84808359355901]],
    [ "Enedis",[-1.8976996499999992, 46.84598544999999]],
    [ "Enedis",[-1.8897185176289093, 46.849984502519895]],
    [ "Enedis",[-1.8962723373898507, 46.85193747415014]],
    [ "Enedis",[-1.8939552677526603, 46.849395353485455]],
    [ "Enedis",[-1.8965009323334638, 46.84969534716271]],
    [ "Enedis",[2.230889363302813, 48.87493156491507]],
    [ "Enedis",[2.23050205, 48.872291450000006]],
    [ "Pr suresnes lt2",[2.224380025737757, 48.8748083461758]],
    [ "Su cottages 34",[2.2191178454390403, 48.877386126231116]],
    [ "Enedis",[2.2114633918157725, 48.86576495148591]],
    [ "Enedis",[2.2327833437050844, 48.87348714517298]],
    [ "Su bas rogers 115 coupure",[2.2238441928528054, 48.882140223750795]],
    [ "Enedis",[2.21467795, 48.869153000000004]],
    [ "Enedis",[2.267438449967024, 48.74362611667867]],
    [ "Enedis",[2.2761728, 48.749304]],
    [ "Enedis",[2.281448518720002, 48.745107105400756]],
    [ "Enedis",[2.2823395673337403, 48.745094592068526]],
    [ "Enedis",[2.2510302844943557, 48.74088409645002]],
    [ "Enedis",[0.09291449827960861, 49.498427388670905]],
    [ "Enedis",[0.09940090717299514, 49.49580295063291]],
    [ "Enedis",[0.10597654650463806, 49.49527752649001]],
    [ "Enedis",[0.09231300522088433, 49.50101649812015]],
    [ "Enedis",[0.11355549999999912, 49.496672149999995]],
    [ "Enedis",[0.8299589757629928, 49.45137385663392]],
    [ "Jules Janet",[0.8285144292533414, 49.449168302128285]],
    [ "Enedis",[0.8343965721822542, 49.457278159232615]],
    [ "Enedis",[0.83023185, 49.454242550000004]],
    [ "Enedis",[0.8240122068159351, 49.459586887076846]],
    [ "Enedis",[2.257958440419049, 48.739074931674736]],
    [ "VR Poulinat",[2.258691564577815, 48.745995354610905]],
    [ "Enedis",[2.245273001856384, 48.73925413227532]],
    [ "Enedis",[1.4880244860625962, 45.82369862649935]],
    [ "Enedis",[4.087307820897239, 50.175773702580365]],
    [ "Enedis",[2.2592516485431, 48.74246111477809]],
    [ "Enedis",[2.2382435945680825, 48.7412205993265]],
    [ "Enedis",[2.2764800687729307, 48.748818592514745]],
    [ "Enedis",[2.2752198597440034, 48.74402822242464]],
    [ "Enedis",[2.274295914767936, 48.75227369514731]],
    [ "Enedis",[2.2301913753665015, 48.74869753654952]],
    [ "Gros Platane",[2.272446667623252, 48.74831975143488]],
    [ "Enedis",[2.238754786256014, 48.74038945266732]],
    [ "Enedis",[2.256038704680549, 48.743393352119355]],
    [ "Enedis",[2.2791091364001215, 48.74318753645689]],
    [ "Enedis",[2.261118325891404, 48.74203689897862]],
    [ "Enedis",[0.10949764936361238, 49.4973041181876]],
    [ "Enedis",[0.10716334538733722, 49.49807743312774]],
    [ "Enedis",[-1.8895619000000006, 46.84081760000001]],
    [ "Enedis",[-1.8928602286561758, 46.84485954476105]],
    [ "Enedis",[-1.8918494000000001, 46.84260215000001]],
    [ "Enedis",[-3.3386955483463847, 47.76533359268041]],
    [ "Enedis",[-3.3522573185773887, 47.76655884320265]],
    [ "Enedis",[-3.344224454868903, 47.759231080756855]],
    [ "Enedis",[-3.3421243188899568, 47.75824562760868]],
    [ "Enedis",[-3.339574014961452, 47.76227588831956]],
    [ "Enedis",[-3.3499445303530306, 47.77301635679587]],
    [ "Enedis",[-3.3586312330941888, 47.76613820621777]],
    [ "Enedis",[-3.3411963172089605, 47.768069176107275]],
    [ "Enedis",[-3.354667661628877, 47.7668699952982]],
    [ "Enedis",[-3.3384301505549265, 47.76704434320108]],
    [ "Enedis",[-3.3504713458032502, 47.77024818079632]],
    [ "Enedis",[-3.345438059845723, 47.76865329879853]],
    [ "Enedis",[-3.3467473162203243, 47.76225156246368]],
    [ "Enedis",[-3.326743243119919, 47.75912317509079]],
    [ "Enedis",[-3.3466428233430507, 47.77235597322608]],
    [ "Enedis",[-3.3368499242201057, 47.75902372869971]],
    [ "Enedis",[-3.332375886337371, 47.76237342756572]],
    [ "Enedis",[-3.3369726012009018, 47.771703680806375]],
    [ "Enedis",[-0.8397344830293756, 45.583806048409826]],
    [ "Enedis",[-0.8301762639263418, 45.58835688089963]],
    [ "Enedis",[-0.8303393017381924, 45.583136342873416]],
    [ "Enedis",[-0.8316510694145219, 45.580633702279094]],
    [ "Enedis",[-0.8351535358505213, 45.58513272748946]],
    [ "Enedis",[-0.8335844061347556, 45.58393298362092]],
    [ "Enedis",[-0.8335262721736201, 45.58801488251627]],
    [ "Enedis",[-0.8337008105179147, 45.582153256446134]],
    [ "Enedis",[-0.8269889224193702, 45.58579344274636]],
    [ "Enedis",[-0.8444420331388804, 45.577507452556496]],
    [ "Enedis",[-0.84654842795143, 45.58636192203859]],
    [ "Enedis",[-0.8256585393306143, 45.58784306746747]],
    [ "Enedis",[-0.833906563023573, 45.59079624075589]],
    [ "Enedis",[-0.8406722651321271, 45.565854737526756]],
    [ "Enedis",[-0.8366095090800425, 45.58614803434769]],
    [ "Enedis",[-0.828921994901109, 45.581238089600994]],
    [ "Enedis",[-0.835904566553075, 45.58091449056331]],
    [ "Enedis",[-0.8415146447026672, 45.58005079208646]],
    [ "Enedis",[4.098371534195605, 50.17505404797376]],
    [ "Enedis",[4.099083389879454, 50.17155603154497]],
    [ "Enedis",[4.097235919109372, 50.17738695051805]],
    [ "Enedis",[0.1737204229682698, 49.51110825477473]],
    [ "Enedis",[0.16985419999999907, 49.51057910000001]],
    [ "Enedis",[0.170402249999999, 49.5126011]],
    [ "Enedis",[0.6759579238284393, 47.345771435552635]],
    [ "Enedis",[0.6690258768523039, 47.36051122617548]],
    [ "Enedis",[0.6271115074477499, 47.34465474573598]],
    [ "Enedis",[0.6741769262555937, 47.36196408426286]],
    [ "Enedis",[0.6644379800820517, 47.35473720110668]],
    [ "Enedis",[0.6887732060174554, 47.347222926296304]],
    [ "Enedis",[0.639538518954237, 47.34775140720865]],
    [ "Enedis",[0.6650525737822948, 47.343406782039814]],
    [ "Enedis",[0.6677145500710457, 47.34977516558283]],
    [ "Enedis",[0.6419683397809763, 47.33938875616071]],
    [ "Enedis",[0.6971319693891259, 47.35585327619547]],
    [ "Enedis",[0.691715319586339, 47.353664608099535]],
    [ "Enedis",[0.6926895495386474, 47.35175110794559]],
    [ "Enedis",[0.6804883249086612, 47.35815496766217]],
    [ "Enedis",[0.6718115608113939, 47.342752578159065]],
    [ "Enedis",[0.681533806260474, 47.345525732659354]],
    [ "Enedis",[0.652576951400935, 47.338269743565036]],
    [ "Enedis",[0.6751230201286221, 47.36097718346824]],
    [ "Enedis",[0.6528895566931068, 47.342389073002124]],
    [ "Enedis",[0.6907186869078833, 47.35465014184583]],
    [ "Enedis",[0.6862284089837067, 47.350741661293746]],
    [ "Enedis",[0.6727342267875398, 47.35490663819781]],
    [ "Enedis",[0.6365716655937061, 47.35044206739929]],
    [ "Enedis",[0.6439153999999986, 47.35269209999999]],
    [ "Enedis",[0.6594883186672823, 47.33796695219614]],
    [ "Enedis",[0.665297758281429, 47.34690200378093]],
    [ "Enedis",[0.6509440964398754, 47.357298943862965]],
    [ "Enedis",[0.6937975087177626, 47.353938570545786]],
    [ "Enedis",[0.6615129488873212, 47.34864109743349]],
    [ "Enedis",[0.629531506277853, 47.337969289601496]],
    [ "Enedis",[0.663709973358629, 47.34210210711432]],
    [ "Enedis",[0.6527753449970394, 47.34894230278271]],
    [ "Enedis",[0.67436169696205, 47.35103690918782]],
    [ "Enedis",[0.6893175857271627, 47.358277253044875]],
    [ "Enedis",[0.6635748393776658, 47.355201319789195]],
    [ "Enedis",[0.6510937725048789, 47.3468665199434]],
    [ "Enedis",[0.6801847676228454, 47.35377482598077]],
    [ "Enedis",[0.8197540172263393, 49.434097974061]],
    [ "Enedis",[0.6512207757648341, 47.34420486582284]],
    [ "Enedis",[0.6854947280536189, 47.359941411447096]],
    [ "Enedis",[0.6628012090204011, 47.32165488431406]],
    [ "Enedis",[0.6688166939435848, 47.342754187103004]],
    [ "Enedis",[0.63697365, 47.34307345]],
    [ "Enedis",[0.6510981677605093, 47.34678004290591]],
    [ "Enedis",[0.6765898189367601, 47.34402805183774]],
    [ "Enedis",[0.6545855583102248, 47.357626834244606]],
    [ "Enedis",[0.6876610997077917, 47.357162604502165]],
    [ "Enedis",[0.6577193267072027, 47.337729715374756]],
    [ "Enedis",[0.6556632101687304, 47.34562927660688]],
    [ "Enedis",[0.6988677026958009, 47.35398845082821]],
    [ "Enedis",[0.6512009136884129, 47.363552856807615]],
    [ "Enedis",[0.681494728683561, 47.35184417608501]],
    [ "Enedis",[0.6482674670062373, 47.359736647161874]],
    [ "Enedis",[0.6543223943455357, 47.3384233225831]],
    [ "Enedis",[0.6584066026841808, 47.34229700855351]],
    [ "Enedis",[0.6817243118911942, 47.34879734984504]],
    [ "Enedis",[0.1673177631735965, 49.51254642682299]],
    [ "Enedis",[0.6533395611269898, 47.339287121023595]],
    [ "Enedis",[0.6950309277797748, 47.35885551024443]],
    [ "Enedis",[0.6339634196841168, 47.34362496500339]],
    [ "Enedis",[0.6400656979987311, 47.343719636732985]],
    [ "Enedis",[0.6555631993526664, 47.3486680842205]],
    [ "Enedis",[0.6774134801606785, 47.34742557849574]],
    [ "Enedis",[0.6817987055875792, 47.35507860960156]],
    [ "Enedis",[6.308903816736856, 46.879325601652134]],
    [ "Enedis",[6.318660117717102, 46.8816316025243]],
    [ "Enedis",[6.325731442316341, 46.879886967671524]],
    [ "Enedis",[0.10768665617714203, 49.49929008474175]],
    [ "Enedis",[0.09487234807758727, 49.49953646730444]],
    [ "Enedis",[0.11050243835789555, 49.50073852039152]],
    [ "RTE",[3.056486986019987, 45.7891885860021]],
    [ "RTE",[3.1027453913052883, 45.810376772299705]],
    [ "Poste électrique de Malintrat",[3.1954954225843775, 45.80921493814173]],
    [ "Commanderie 5",[5.736366142481637, 45.14275681148826]],
    [ "Enedis",[0.1661728, 49.516524049999994]],
    [ "Enedis",[0.1638482978227345, 49.51350332658647]],
    [ "Enedis",[0.16523195239174435, 49.51589084868637]],
    [ "Pont Rouge",[5.6918702630282585, 45.11913126521167]],
    [ "Enedis",[5.678857165947121, 45.142991615966054]],
    [ "Beyke Stendhal",[5.672174540015942, 45.1208673470525]],
    [ "Les Perouses",[5.68092503500448, 45.12557895468483]],
    [ "Carrières",[5.69087625608456, 45.114343499424365]],
    [ "Les Saules",[5.686347734781208, 45.124903505901024]],
    [ "Bourg",[5.672492852449775, 45.119503679563174]],
    [ "SUZE",[5.690011141052387, 45.11481884129885]],
    [ "Enedis",[5.6702028, 45.13942175]],
    [ "Libération",[5.692361040096015, 45.117801751395085]],
    [ "Enedis",[5.669009582911391, 45.126400046647476]],
    [ "Croix Blanche",[5.675576774511096, 45.125969714457675]],
    [ "Le Rif",[5.677540250000001, 45.120059999999995]],
    [ "Marais",[5.6847958685367574, 45.11243444647386]],
    [ "Enedis",[5.671538425738845, 45.125302293654826]],
    [ "Enedis",[5.664797483920471, 45.107405651587236]],
    [ "Novembre",[5.6738944455665346, 45.11663590908431]],
    [ "Enedis",[5.665554768564681, 45.109820031714875]],
    [ "La Chenaie",[5.6715896879692655, 45.11402462193691]],
    [ "Clos des Pierres",[5.678179420682884, 45.12373998961075]],
    [ "Enedis",[5.670696347893511, 45.130030844660595]],
    [ "Enedis",[5.667506733849099, 45.1103521537866]],
    [ "Enedis",[-3.0111661699116596, 48.8379996349359]],
    [ "Poste Église",[-3.354731477382393, 47.69084184544066]],
    [ "Enedis",[0.6075366479625409, 47.33333370510758]],
    [ "Enedis",[0.6248944189324249, 47.34704477336941]],
    [ "Enedis",[0.6099065530803438, 47.357786305925465]],
    [ "Enedis",[0.6060999399531711, 47.35877192783645]],
    [ "Enedis",[0.6087097228399556, 47.34273842784839]],
    [ "Enedis",[0.6197032530391339, 47.33953268601166]],
    [ "Enedis",[0.6010307320770163, 47.32827147961424]],
    [ "Enedis",[0.6160290952656975, 47.34296257805727]],
    [ "Enedis",[0.6077723819512205, 47.33991769430895]],
    [ "Enedis",[0.6024380999999998, 47.329362749999994]],
    [ "Enedis",[0.6124603521749061, 47.344295645533784]],
    [ "Enedis",[0.6228385793364981, 47.36197576239393]],
    [ "Enedis",[0.6025946077212019, 47.312853715910755]],
    [ "Enedis",[0.6089598000000002, 47.34587809999999]],
    [ "Enedis",[0.6342525693190527, 47.36121914219346]],
    [ "Enedis",[0.6065461437164069, 47.33859458716019]],
    [ "Enedis",[0.6156302323614121, 47.33556149750945]],
    [ "Enedis",[0.6107702500000002, 47.33932875]],
    [ "Enedis",[0.61670665, 47.3470079]],
    [ "Enedis",[0.6032447079821386, 47.32794583615099]],
    [ "Enedis",[0.6367233246389835, 47.35660157987897]],
    [ "Enedis",[0.6226274708111244, 47.33392538558996]],
    [ "Enedis",[0.59898164186455, 47.31485431642614]],
    [ "Enedis",[0.6354291461506626, 47.36003974840258]],
    [ "Enedis",[0.6135245156610023, 47.339062960217454]],
    [ "Enedis",[0.6397934796476036, 47.360005995381265]],
    [ "Enedis",[0.6105152013576786, 47.33673620102624]],
    [ "Enedis",[-1.8815305499999997, 46.84869135]],
    [ "Enedis",[-1.877829661793914, 46.85138046979916]],
    [ "Enedis",[-1.8863256489324152, 46.85154511662011]],
    [ "Enedis",[-1.8867913831604175, 46.84927284871822]],
    [ "Enedis",[-1.8795787000000002, 46.84819294999999]],
    [ "Enedis",[0.8525383718640449, 49.48949329579332]],
    [ "Enedis",[0.8721584050118466, 49.48253568310553]],
    [ "Enedis",[0.8715227729081066, 49.480424830322825]],
    [ "Rue de Franche Comté",[6.453981850000001, 46.89833125]],
    [ "Enedis",[1.3717673643716959, 43.5275306633917]],
    [ "Enedis",[1.3516361740065181, 43.52493268923397]],
    [ "Enedis",[1.3410943383832057, 43.52513853534934]],
    [ "Enedis",[1.3488826190730847, 43.52774672146154]],
    [ "Enedis",[1.3685129246050889, 43.525012996404094]],
    [ "Enedis",[1.3453681253409304, 43.52154234649755]],
    [ "Enedis",[1.3286794597394553, 43.52790037664052]],
    [ "Enedis",[1.3265457478678282, 43.53082680195139]],
    [ "Enedis",[1.3613141986376758, 43.5261468839762]],
    [ "Enedis",[1.3559934000000002, 43.5218111]],
    [ "Enedis",[1.340624409155936, 43.517964694896435]],
    [ "Enedis",[1.3432271895204881, 43.525796724607545]],
    [ "Enedis",[1.339413036524845, 43.52861916447463]],
    [ "Enedis",[1.3502177069737324, 43.52297332704554]],
    [ "Enedis",[1.3626345060867078, 43.52307312327714]],
    [ "Enedis",[1.3267631331268002, 43.52652438568065]],
    [ "Enedis",[1.329955519461078, 43.531742820283675]],
    [ "Enedis",[1.3316969145005482, 43.5294059915004]],
    [ "Enedis",[1.3283412982239209, 43.52345027699227]],
    [ "Rossignolis",[1.365001979986221, 43.52656320285911]],
    [ "Enedis",[1.3354065219077071, 43.52502057541772]],
    [ "Enedis",[1.3688262000000002, 43.527131149999995]],
    [ "Enedis",[1.3324493822983539, 43.527456322481065]],
    [ "Enedis",[1.342441737455599, 43.519202385151]],
    [ "Enedis",[1.3417522350024857, 43.522590651957806]],
    [ "Enedis",[1.3406474830226467, 43.52699046883637]],
    [ "Enedis",[1.3334068330165916, 43.52774070480168]],
    [ "Enedis",[1.3365993033951933, 43.53150158105092]],
    [ "Enedis",[1.332763428493826, 43.52442093144333]],
    [ "Enedis",[1.3496346898317844, 43.52582489711388]],
    [ "Enedis",[1.3449043019744318, 43.52980777030263]],
    [ "Enedis",[1.3463351533381125, 43.52816248919591]],
    [ "Enedis",[1.346953380785514, 43.52619323560765]],
    [ "Enedis",[1.3391036743088802, 43.531939215782415]],
    [ "Enedis",[1.3380125558885243, 43.52392902843104]],
    [ "Enedis",[1.373568552809834, 43.52385357593202]],
    [ "Enedis",[1.3273768179018286, 43.525376247545715]],
    [ "Enedis",[1.3348952176017845, 43.52956687021836]],
    [ "Enedis",[1.3446114449327047, 43.52343654214411]],
    [ "Enedis",[1.3421803502842071, 43.52998326139413]],
    [ "Enedis",[1.3543820529544428, 43.52483810452095]],
    [ "Enedis",[0.8282198080498341, 48.7162996416364]],
    [ "Enedis",[-3.275051323591643, 47.79056784614912]],
    [ "Enedis",[-3.2764065901796786, 47.81191067152879]],
    [ "Enedis",[-3.2692850000000004, 47.79156745]],
    [ "Enedis",[7.0969467721523065, 48.054486404969346]],
    [ "Poste électrique de Pougny",[5.974142048418121, 46.160746217175486]],
    [ "Enedis",[-1.6090429887694868, 43.423721229224846]],
    [ "Enedis",[-1.6130674982186708, 43.41777688601896]],
    [ "Enedis",[-1.6055343999999998, 43.423920949999996]],
    [ "Enedis",[-1.6081862322326643, 43.42180180305922]],
    [ "Enedis",[-1.6078053226137645, 43.42526224636107]],
    [ "Enedis",[2.9378255999999987, 43.894958200000005]],
    [ "Enedis",[2.256121240939424, 48.712871426023796]],
    [ "Enedis",[2.2383367001750734, 48.72584302027785]],
    [ "Enedis",[2.2441620506530646, 48.723192595502695]],
    [ "SOLEMS",[2.2533347, 48.71225439999999]],
    [ "MARIANNE",[2.246308185575492, 48.71468625135815]],
    [ "Enedis",[2.233160098730012, 48.70496115724107]],
    [ "Lozère",[2.2116097499999996, 48.706303250000005]],
    [ "CERISAIE",[2.2441440683652414, 48.72318624417871]],
    [ "Enedis",[2.236134312953572, 48.7095141329134]],
    [ "SABLIERE",[2.2350508300221623, 48.72724399320819]],
    [ "Poste 3B19",[2.228081988130153, 48.71544610069362]],
    [ "Enedis",[2.2515860214284293, 48.724601377517324]],
    [ "SANTOS",[2.2622815531295912, 48.716581932081624]],
    [ "CASSINI",[2.256222819856049, 48.71285494199425]],
    [ "IBRIDE",[2.2587293568899147, 48.71054268429561]],
    [ "Enedis",[2.213916074673935, 48.703367986762295]],
    [ "BERTEAUX",[2.2479159480713533, 48.719970440424596]],
    [ "BESSEMER",[2.256006978011407, 48.71476815497004]],
    [ "71351745",[2.240835333191199, 48.71176755135916]],
    [ "Enedis",[2.256107099207067, 48.71503229162647]],
    [ "DIMARCO",[2.2541913436549543, 48.711279040912984]],
    [ "LAROTIE",[2.262285197947051, 48.71995461384468]],
    [ "Enedis",[2.2390307663741518, 48.70656236876145]],
    [ "Chapelle Bauclart",[4.111718079426544, 50.166301439132845]],
    [ "Desmoulins",[2.2381003403530015, 48.709337103448675]],
    [ "Enedis",[3.9173289152972957, 50.25882976396598]],
    [ "Enedis",[3.9368057085408785, 50.25132747632976]],
    [ "Enedis",[3.929187607191279, 50.253882042259775]],
    [ "Enedis",[3.913215376944027, 50.25916282492479]],
    [ "Enedis",[3.932085489182728, 50.246403022476166]],
    [ "Enedis",[5.164197570879324, 43.32942949642379]],
    [ "Enedis",[0.9237365052081524, 48.73641098291486]],
    [ "Enedis",[0.9152974784469206, 48.74215180137499]],
    [ "Enedis",[0.9112197050782261, 48.73331317329642]],
    [ "Enedis",[0.9359030286810885, 48.73697774621008]],
    [ "Enedis",[5.169202663233559, 43.33134733158962]],
    [ "Enedis",[4.805117862646842, 44.74072979379401]],
    [ "Enedis",[-3.9005102709566186, 48.59991298439704]],
    [ "Enedis",[-3.8910689740584092, 48.60454863649017]],
    [ "Enedis",[-3.9314385309957975, 48.5980890472926]],
    [ "Enedis",[-3.9032567542192047, 48.60043695859397]],
    [ "Enedis",[-3.8952376018882173, 48.60392341352272]],
    [ "Enedis",[-3.908500443881562, 48.58333384820591]],
    [ "Enedis",[-3.8905902221363795, 48.59935931889092]],
    [ "RTE",[3.237007572446648, 45.745676151850965]],
    [ "Enedis",[-0.4429155221327822, 46.32215765289711]],
    [ "Enedis",[3.9250534175694956, 50.2494825335897]],
    [ "Enedis",[3.9270843499999994, 50.25097494999999]],
    [ "CM Champagne 1",[2.2498241709401867, 48.786948822372196]],
    [ "Enedis",[2.239731685272558, 48.779069938967766]],
    [ "CM Versailles 14",[2.2475138290743697, 48.78295222411835]],
    [ "Enedis",[2.2426351332434376, 48.78516231601344]],
    [ "CM Bourgogne",[2.2393375194659924, 48.78161728006643]],
    [ "Enedis",[-3.9221070140472967, 48.672119347795125]],
    [ "Enedis",[-3.9199864933909088, 48.65666286898943]],
    [ "Carantec",[-3.923313520619614, 48.68180645662834]],
    [ "Enedis",[-3.9246758553415884, 48.686389566979855]],
    [ "Enedis",[3.92392292978477, 50.24761416648422]],
    [ "Enedis",[3.921474433576643, 50.24536058622262]],
    [ "Enedis",[3.927396239018358, 50.24379889862726]],
    [ "Enedis",[3.929770700540648, 50.244303473617684]],
    [ "Enedis",[3.920556076322666, 50.25000920870041]],
    [ "Enedis",[5.1653644449164515, 43.33769251573984]],
    [ "Enedis",[5.158676445152929, 43.333132171817674]],
    [ "Enedis",[5.148435013427857, 43.33245697322728]],
    [ "Enedis",[5.163107944987624, 43.33435547146176]],
    [ "Enedis",[5.161291564331655, 43.332573372157185]],
    [ "Enedis",[5.141509150000001, 43.3304908]],
    [ "Enedis",[5.162107657718792, 43.338000577557885]],
    [ "Enedis",[5.141725409621192, 43.33324782226097]],
    [ "Enedis",[5.1661228520740705, 43.333836389648006]],
    [ "Enedis",[5.143173749999999, 43.33272695]],
    [ "Enedis",[5.1639369, 43.33207845]],
    [ "Enedis",[5.166009618479752, 43.335517396764665]],
    [ "Enedis",[5.136604154736759, 43.33021521018491]],
    [ "Enedis",[5.160057938889988, 43.336206922512424]],
    [ "Enedis",[5.159868092446688, 43.33461109927265]],
    [ "Enedis",[5.163274108988263, 43.336844484426905]],
    [ "Enedis",[5.154329093710206, 43.33056172783372]],
    [ "Enedis",[5.159545499999998, 43.329284550000004]],
    [ "Enedis",[5.148972396998137, 43.334289342268626]],
    [ "Marius",[5.148143722454674, 43.337927935146446]],
    [ "Enedis",[5.13473421778134, 43.32994737544287]],
    [ "Enedis",[2.2579364104801916, 48.79274106828923]],
    [ "Enedis",[2.2624351848918804, 48.79109457832832]],
    [ "BL Larochefoucau 12",[2.2400885345996953, 48.8461850052772]],
    [ "BL Bartholdi 3",[2.242657712129312, 48.84448486249126]],
    [ "Enedis",[6.302836101899188, 46.926855333399175]],
    [ "Enedis",[6.307302666221175, 46.92416174827779]],
    [ "Enedis",[6.3065109, 46.923500250000004]],
    [ "GALLOIS",[6.020779311703954, 47.2568007633685]],
    [ "Enedis",[5.974438064682848, 47.23304082049408]],
    [ "Enedis",[5.9846112949593655, 47.24397534967775]],
    [ "Enedis",[5.9856821496331305, 47.242423626980674]],
    [ "Enedis",[2.7314119275627182, 42.681538393918]],
    [ "Enedis",[2.7377891314536855, 42.682145295794314]],
    [ "Poste électrique de Rulhat",[3.270363615717739, 45.81346328751169]],
    [ "Poste électrique de Bayet",[3.2951427185569497, 46.273059082104204]],
    [ "SLY Kerbourg",[-2.3654451851187206, 47.36560652844411]],
    [ "Poste électrique de Culhat",[3.3569577523803877, 45.86344760390604]],
    [ "Enedis",[3.9167757901394333, 50.24928915357127]],
    [ "Enedis",[-1.5444725999999998, 43.46883349999999]],
    [ "Enedis",[-1.5437215991513837, 43.477505504899256]],
    [ "Enedis",[-1.5515287000000002, 43.48473]],
    [ "Enedis",[-1.5357198198609532, 43.471535807811904]],
    [ "Enedis",[-1.5453280069112143, 43.45385070331352]],
    [ "Enedis",[-1.5609723050800726, 43.47688927552989]],
    [ "Enedis",[-1.5473939896945195, 43.471707880175195]],
    [ "Enedis",[-1.53873315, 43.4572417]],
    [ "Enedis",[6.585908426983471, 48.025717890131155]],
    [ "Enedis",[-1.5491898914152908, 43.47295747651785]],
    [ "Enedis",[-1.55358115, 43.466265750000005]],
    [ "Enedis",[-1.5576785473814712, 43.47874343768677]],
    [ "Enedis",[-1.5546975749672545, 43.484298046964575]],
    [ "Enedis",[-1.5403231499999999, 43.4808873]],
    [ "Enedis",[-1.5483815158533296, 43.46151974533374]],
    [ "Enedis",[-1.566187326569461, 43.48176996387201]],
    [ "Enedis",[-1.5440276739808856, 43.47227060776782]],
    [ "Enedis",[-1.5613023627122744, 43.47511676439329]],
    [ "Enedis",[-1.5428516988095247, 43.4740820404762]],
    [ "Enedis",[-1.536898215684808, 43.47790783984077]],
    [ "Enedis",[-1.5362965969411284, 43.47525540006354]],
    [ "Enedis",[-1.5422964570891735, 43.46726279869447]],
    [ "Enedis",[-1.8679331, 46.85188780000001]],
    [ "Enedis",[-1.8747428867620994, 46.85244218142669]],
    [ "Enedis",[-1.8665693499999998, 46.8471868]],
    [ "Enedis",[-1.5505165880998713, 43.47359738612132]],
    [ "Enedis",[-1.5556763590037237, 43.46287460546685]],
    [ "Enedis",[-1.5494827425468731, 43.470085508310916]],
    [ "Enedis",[0.7456932324811093, 47.44063115801774]],
    [ "Enedis",[0.7290515232989305, 47.44750689600263]],
    [ "Enedis",[0.7438468, 47.4366894]],
    [ "Enedis",[0.7159178194758028, 47.44095903972527]],
    [ "Enedis",[0.7261073333342283, 47.4501201884661]],
    [ "Enedis",[0.70988075, 47.46231950000001]],
    [ "Enedis",[0.7061113372496066, 47.45392684258385]],
    [ "Enedis",[0.7051678212733581, 47.45113777433784]],
    [ "Enedis",[0.7030053879423224, 47.45070758370487]],
    [ "Enedis",[0.7115637152493766, 47.452016855072266]],
    [ "Enedis",[0.6945621600305261, 47.44021484547433]],
    [ "Enedis",[0.7016361149599237, 47.442040034495825]],
    [ "Enedis",[0.7068597765930044, 47.45266553930807]],
    [ "Enedis",[0.7070331566481652, 47.462258626532886]],
    [ "Enedis",[0.7091380500000001, 47.461367100000004]],
    [ "Enedis",[0.7007312255957278, 47.45167189193551]],
    [ "Enedis",[0.712725428313475, 47.45353574175847]],
    [ "Enedis",[0.7005297577767429, 47.457490768432315]],
    [ "BL Ch de Gaulle 4",[2.2375139847656436, 48.84863476514953]],
    [ "BL Parchamp 4",[2.2382479855544513, 48.846679916495376]],
    [ "Enedis",[-1.7684738759181986, 43.3641838040139]],
    [ "Enedis",[-1.7799083791698014, 43.37054765002926]],
    [ "Enedis",[-1.7764757547137806, 43.350485783062425]],
    [ "Enedis",[-1.7685996817862328, 43.37268780329725]],
    [ "Enedis",[-1.7620694817478615, 43.35805065874784]],
    [ "Enedis",[-1.777120711652501, 43.372010042628666]],
    [ "Enedis",[-3.863167991210334, 48.62480884353885]],
    [ "Enedis",[-1.768115064553354, 43.3434079300047]],
    [ "Enedis",[-1.7725587192707448, 43.37002645902082]],
    [ "Enedis",[-1.7689778937413907, 43.35390161799822]],
    [ "Enedis",[-1.7683224124231993, 43.355290088064976]],
    [ "Enedis",[-1.7698594685309168, 43.36679362044902]],
    [ "Enedis",[-1.781098377753713, 43.35070684374814]],
    [ "Enedis",[-1.771413464436837, 43.35741290190666]],
    [ "Enedis",[-1.772607856607692, 43.34403223148933]],
    [ "Enedis",[-1.7721574999999996, 43.344772750000004]],
    [ "Enedis",[-1.7762310109003394, 43.357334484064744]],
    [ "Enedis",[-1.7701050393508464, 43.37302206563342]],
    [ "Enedis",[-1.7692811311128303, 43.344715102264935]],
    [ "Enedis",[-1.76301885, 43.35697929999999]],
    [ "Enedis",[-3.988855973350517, 48.705541944361784]],
    [ "Enedis",[-3.9990581105138445, 48.711827877582934]],
    [ "Enedis",[-3.977786040227753, 48.71929175620848]],
    [ "Enedis",[-3.9846947259318126, 48.714416913544255]],
    [ "Enedis",[-3.983886940168857, 48.72527966616075]],
    [ "Enedis",[-3.9910889390530664, 48.7236288728551]],
    [ "Enedis",[-3.987546684017122, 48.72303496499439]],
    [ "Enedis",[-3.9831224439444473, 48.70681216232886]],
    [ "Enedis",[-3.982147440481862, 48.71547336001278]],
    [ "Enedis",[-3.987140141803951, 48.71721316202448]],
    [ "Enedis",[-3.980585785016931, 48.71854086413488]],
    [ "Enedis",[-3.9852249480296944, 48.71978022393119]],
    [ "Enedis",[1.4375585867354919, 43.718023417733676]],
    [ "Enedis",[1.434998533303222, 43.718250500802974]],
    [ "Enedis",[1.4413832846192638, 43.72188148003026]],
    [ "Enedis",[1.4405075808519752, 43.72048259849176]],
    [ "Enedis",[1.4369088, 43.71976675]],
    [ "Enedis",[1.416780350318301, 43.715297166554336]],
    [ "Enedis",[1.4177532665938275, 43.71371714982379]],
    [ "Enedis",[1.4383204274449852, 43.724700695395015]],
    [ "Enedis",[1.4333863500000001, 43.7154745]],
    [ "Enedis",[1.4205798499999998, 43.715324949999996]],
    [ "Enedis",[2.811509887705457, 42.76128927896121]],
    [ "Enedis",[2.8163425742481594, 42.75254938553228]],
    [ "Enedis",[2.8084824000000004, 42.75179395000001]],
    [ "Enedis",[2.8167275197906285, 42.75342931413303]],
    [ "Enedis",[2.8066173272372286, 42.74839573611899]],
    [ "Enedis",[3.910431900000001, 50.2492669]],
    [ "Enedis",[2.8639981387740328, 42.56492964600902]],
    [ "Enedis",[2.86923000232302, 42.56277327145596]],
    [ "Enedis",[2.8668295017150562, 42.56376390319887]],
    [ "Enedis",[2.8725716154486642, 42.56001505944174]],
    [ "Enedis",[3.9109596853164565, 50.24737618776371]],
    [ "Enedis",[2.872076706830687, 42.56429309461703]],
    [ "Enedis",[2.861312888331652, 42.56767938313753]],
    [ "Enedis",[-3.9900043740966544, 48.6783993703643]],
    [ "Enedis",[-3.990243397536404, 48.68376511576562]],
    [ "Enedis",[-3.9884820234869243, 48.67545101214967]],
    [ "Enedis",[-4.00163937612526, 48.67768719836032]],
    [ "Enedis",[-3.960399934416257, 48.65663607458934]],
    [ "Enedis",[-3.991690706263545, 48.68279964161327]],
    [ "Enedis",[-3.9875336999999984, 48.656890649999994]],
    [ "Enedis",[-3.9877328555128093, 48.6730122861731]],
    [ "Enedis",[-3.9962090704083835, 48.675845030654685]],
    [ "Enedis",[-3.995165145603925, 48.66843616998847]],
    [ "Enedis",[-3.9828867377009116, 48.68176154327877]],
    [ "Enedis",[-4.002838997236622, 48.67866777448795]],
    [ "Enedis",[1.4384338053698666, 43.6888074612379]],
    [ "Enedis",[1.458267307838507, 43.68636869767548]],
    [ "Enedis",[1.4469981887320693, 43.69399542901642]],
    [ "Enedis",[1.4260979563746874, 43.695692729778465]],
    [ "Enedis",[1.446977388374998, 43.68612618581466]],
    [ "Enedis",[1.4430142939934687, 43.690616181639534]],
    [ "Enedis",[1.4419653018567795, 43.685925822977204]],
    [ "Enedis",[1.4408954468918107, 43.685344457375066]],
    [ "Enedis",[1.4119575941009364, 43.70952536973027]],
    [ "Enedis",[1.4573345500000001, 43.69427290000001]],
    [ "Enedis",[1.4296630885058677, 43.69572836992913]],
    [ "Enedis",[1.4275173469553064, 43.689445509438706]],
    [ "Enedis",[1.4521774866544603, 43.69548177898443]],
    [ "Enedis",[1.4338310169463953, 43.69743118024286]],
    [ "Enedis",[1.436440449419459, 43.68565341345697]],
    [ "Enedis",[1.4332536838238812, 43.68691444227813]],
    [ "Enedis",[1.4372034666666667, 43.692355166666665]],
    [ "Jeanne d'Arc",[1.4252415950271602, 43.696862852857954]],
    [ "Enedis",[1.434360577036473, 43.692333127481234]],
    [ "Enedis",[1.429638714784452, 43.69879954361092]],
    [ "Enedis",[1.4359840142934923, 43.69764441219412]],
    [ "Enedis",[1.427462219420745, 43.687296819652616]],
    [ "Enedis",[1.449960760260202, 43.68726371882515]],
    [ "Enedis",[1.4439586263190702, 43.68762243972913]],
    [ "Enedis",[1.4327835238131168, 43.698892543991754]],
    [ "Enedis",[1.4410820412591046, 43.68798428924962]],
    [ "Enedis",[1.4430887121512679, 43.68340647031973]],
    [ "Enedis",[1.449483261984963, 43.692013876696194]],
    [ "Enedis",[1.4272271035422532, 43.690504832293286]],
    [ "Enedis",[1.4383247206994139, 43.69485246959344]],
    [ "Enedis",[1.4456468825296742, 43.692442520705974]],
    [ "Enedis",[1.433208334140205, 43.69004579053444]],
    [ "Enedis",[2.1737352911503556, 48.77173003668587]],
    [ "Enedis",[-0.644798508166391, 47.262784466046774]],
    [ "Enedis",[-1.8832309499999995, 46.84484835000001]],
    [ "Enedis",[-1.8821171999999997, 46.843902199999995]],
    [ "Enedis",[2.059183767998233, 48.8674341133346]],
    [ "Enedis",[2.075977963946486, 48.86781742865813]],
    [ "Enedis",[2.0623625870062483, 48.87044501821307]],
    [ "Enedis",[1.5268167430052175, 43.67758825134041]],
    [ "Enedis",[1.53945225, 43.6796703]],
    [ "Enedis",[1.5344646417016556, 43.68230802886428]],
    [ "Enedis",[1.5259511031785982, 43.67687950885167]],
    [ "Enedis",[1.540184850038088, 43.686484290935056]],
    [ "Enedis",[1.536749396741996, 43.6811790990274]],
    [ "Enedis",[1.5312803503652093, 43.67668320503487]],
    [ "Enedis",[1.5129900152795976, 43.67732792866271]],
    [ "Enedis",[1.5338771258797255, 43.67799722888699]],
    [ "Enedis",[1.5353495388692657, 43.676369587000906]],
    [ "Enedis",[1.5071479000000003, 43.67100635000001]],
    [ "Enedis",[1.5224717344432823, 43.68016516771382]],
    [ "Enedis",[1.5290087705091153, 43.6742595540099]],
    [ "Enedis",[1.527479643923172, 43.682680493564725]],
    [ "Enedis",[1.5338644716264105, 43.67516603103418]],
    [ "Enedis",[1.5316194906217833, 43.675393056964964]],
    [ "Enedis",[1.427489399583105, 43.74572519070388]],
    [ "Enedis",[1.423695301959387, 43.750749434247716]],
    [ "Enedis",[2.2298200578395195, 48.84370950915129]],
    [ "BL Escudier 18",[2.235377605405166, 48.84323662933889]],
    [ "Enedis",[1.382425800000001, 43.812587050000005]],
    [ "Enedis",[1.4469765852769798, 43.66388978940775]],
    [ "Enedis",[1.450803509177898, 43.6710779498836]],
    [ "Enedis",[1.4452718193455851, 43.65932057267294]],
    [ "Enedis",[1.3675414617855792, 43.77882116306714]],
    [ "Enedis",[1.4404681865654063, 43.674627354040325]],
    [ "Enedis",[1.4502779855133425, 43.659492700299644]],
    [ "Enedis",[1.4564819590110814, 43.67090104832196]],
    [ "Enedis",[1.45080015, 43.6741856]],
    [ "Enedis",[1.4643797880631733, 43.67550638191927]],
    [ "Enedis",[1.4572927092780512, 43.657363767444004]],
    [ "Enedis",[1.4410924904693145, 43.655875026450055]],
    [ "LA POINTE",[1.4450329586139006, 43.670459277919086]],
    [ "Enedis",[1.440788852830999, 43.663412175007444]],
    [ "Enedis",[1.455438246309634, 43.6735885823363]],
    [ "Enedis",[1.4453940907929628, 43.65678388250181]],
    [ "Enedis",[1.4548502407544166, 43.66089479338752]],
    [ "Encourse",[1.4534495175889215, 43.65414643416226]],
    [ "Enedis",[1.4480045449159247, 43.678549470130115]],
    [ "Enedis",[1.459903825517069, 43.676269192725044]],
    [ "Enedis",[1.4475762133253234, 43.67490586620318]],
    [ "Enedis",[1.4505572161309874, 43.65728540489482]],
    [ "Enedis",[2.2119778108623582, 49.106427319737186]],
    [ "Enedis",[1.4404368442997446, 43.66717104519286]],
    [ "Enedis",[1.4543834153300819, 43.65775755298909]],
    [ "Enedis",[1.4532002182551398, 43.67837827825466]],
    [ "Enedis",[1.4507820491592311, 43.678186276234946]],
    [ "Enedis",[1.4559858108983426, 43.676743481832965]],
    [ "Enedis",[1.4459617017843827, 43.66947680026012]],
    [ "Enedis",[1.4507152984688345, 43.67584897089474]],
    [ "Enedis",[1.452199211096402, 43.674808911818296]],
    [ "Enedis",[1.4487260227846748, 43.672778256173736]],
    [ "Enedis",[1.4485487804036996, 43.6622787186618]],
    [ "Enedis",[-4.021311056006201, 48.71075986704849]],
    [ "Enedis",[-4.018881523215884, 48.741273388996554]],
    [ "Enedis",[-4.004903790400794, 48.742601980535085]],
    [ "Enedis",[2.2044167872768936, 48.87351435557014]],
    [ "Enedis",[2.2021037849545375, 48.87323037875824]],
    [ "Enedis",[3.8972966016251718, 50.24316911731378]],
    [ "Enedis",[3.9185422, 50.24431475000001]],
    [ "Enedis",[3.904773646418147, 50.242523461278395]],
    [ "Enedis",[-3.8501037202017034, 48.57876251778627]],
    [ "Enedis",[0.7051299437341304, 47.47338209704524]],
    [ "Enedis",[0.6997681357125702, 47.46671468084867]],
    [ "Enedis",[-3.8520075000000005, 48.57844674999999]],
    [ "Enedis",[-3.8567787496286847, 48.558167839810615]],
    [ "Enedis",[0.6943387000000005, 47.45888705000001]],
    [ "Enedis",[-3.8487878, 48.5823825]],
    [ "Enedis",[2.319225716748661, 51.04163857127224]],
    [ "Enedis",[2.3407579841698625, 51.05610713274945]],
    [ "Enedis",[2.3301254734954755, 51.04166270840469]],
    [ "Enedis",[2.323602552136829, 51.04083623289502]],
    [ "Enedis",[2.330827100000002, 51.04146015]],
    [ "Enedis",[2.327352421655558, 51.05000698681201]],
    [ "Enedis",[-3.991037131961332, 48.6303002580643]],
    [ "72362895",[-0.45354053177902026, 46.31258433244452]],
    [ "Enedis",[-1.8702508000000004, 46.84341545000001]],
    [ "Enedis",[-1.87122535, 46.84210159999999]],
    [ "Enedis",[-1.8675726069490795, 46.84401402994437]],
    [ "Enedis",[-1.8732973500000003, 46.84550444999999]],
    [ "Enedis",[-1.8757363093568233, 46.8447822817562]],
    [ "Enedis",[5.943249198556432, 47.216007314849676]],
    [ "Enedis",[3.47279737795789, 43.31569346839455]],
    [ "Enedis",[-1.0194994713323748, 43.45286463009939]],
    [ "Enedis",[4.973643102353912, 44.91177967293892]],
    [ "Enedis",[3.475541583954383, 43.31098635131217]],
    [ "Enedis",[3.491613449999999, 43.30698494999999]],
    [ "Enedis",[3.494801371889887, 43.30642251922923]],
    [ "Enedis",[3.477059043567859, 43.29939232972609]],
    [ "Enedis",[3.4731305073206196, 43.29924906236501]],
    [ "Enedis",[3.4726695845790783, 43.30056614520203]],
    [ "Enedis",[-1.648723139829363, 43.38827237588678]],
    [ "Enedis",[-1.6152116351706554, 43.411375747325074]],
    [ "Enedis",[-1.6320299946496002, 43.39609265957531]],
    [ "Enedis",[-1.6360589050264724, 43.39238999980365]],
    [ "Enedis",[-1.6573000281495813, 43.392856479228826]],
    [ "Enedis",[-1.6195215213984255, 43.40964678990558]],
    [ "Enedis",[-1.6248363424592773, 43.405533391911455]],
    [ "Enedis",[-1.6396477868816948, 43.398404218642085]],
    [ "Enedis",[-1.622930616590438, 43.40495670232639]],
    [ "Enedis",[-1.6131432674630217, 43.412060163759584]],
    [ "Enedis",[-1.6230422542815504, 43.40698654285578]],
    [ "Enedis",[-1.6345375336562085, 43.395188782772955]],
    [ "Enedis",[-1.6508384865701833, 43.39214674861684]],
    [ "Enedis",[-1.653190135135398, 43.39541968563853]],
    [ "Enedis",[-1.6212942572903908, 43.407060576208]],
    [ "Enedis",[-1.636405979414798, 43.39414407731582]],
    [ "Enedis",[-1.655989106874217, 43.38867168723287]],
    [ "Enedis",[-1.652271333747786, 43.38405212984768]],
    [ "Enedis",[-1.6458800029384757, 43.38301372612071]],
    [ "Enedis",[-1.8618921433049085, 46.85688941841094]],
    [ "Enedis",[-1.858302534847492, 46.85084926887114]],
    [ "Enedis",[-1.8525380754977911, 46.85152636521063]],
    [ "Enedis",[-1.8617065224264997, 46.85243355373811]],
    [ "Enedis",[-1.8553188500000002, 46.8559949]],
    [ "Enedis",[-1.8547703088816359, 46.85096183187533]],
    [ "Enedis",[-1.8617267999999991, 46.85111855]],
    [ "Enedis",[6.49868745, 44.83944825]],
    [ "Enedis",[6.498985140358128, 44.84420483421543]],
    [ "Enedis",[6.494003545987857, 44.84087742741604]],
    [ "Enedis",[6.497809962549956, 44.844960633496626]],
    [ "Enedis",[6.499972714727008, 44.83698884757357]],
    [ "Enedis",[6.508493010950139, 44.83317942973393]],
    [ "Enedis",[6.5010862377558, 44.841181503821346]],
    [ "Enedis",[6.49815636683985, 44.84340268631708]],
    [ "Enedis",[6.480545625976969, 44.855073467069566]],
    [ "Enedis",[6.49166095362114, 44.84922851712454]],
    [ "Enedis",[6.486749384097239, 44.55096045118119]],
    [ "Enedis",[6.481340004636938, 44.55188667217394]],
    [ "Enedis",[5.017704874773185, 44.72850908225268]],
    [ "Enedis",[-1.7588502999999998, 43.34445374999999]],
    [ "Enedis",[2.1962540642929684, 48.866422481534165]],
    [ "Enedis",[-1.6724499428661048, 43.374745733898756]],
    [ "Enedis",[-1.6925297000000001, 43.383661000000004]],
    [ "St Maximin",[6.183762376843358, 49.11564655999272]],
    [ "Enedis",[5.932289804957714, 45.714214470822405]],
    [ "Enedis",[-1.6856379500000003, 43.33692190000001]],
    [ "Enedis",[-1.6903414944160238, 43.38624202481319]],
    [ "Enedis",[-1.7631335522900733, 43.34546833616261]],
    [ "Enedis",[-1.6951232083455767, 43.38331304612648]],
    [ "Enedis",[-1.6875450305695745, 43.39144014640711]],
    [ "Enedis",[-1.6706867517567825, 43.37676161739375]],
    [ "Enedis",[-1.7570877068976685, 43.347562907815195]],
    [ "Enedis",[6.029118573136426, 47.25903684064407]],
    [ "Enedis",[6.046695886562761, 47.26339811500602]],
    [ "Enedis",[-1.6670597505069191, 43.37757208124674]],
    [ "Enedis",[-1.6835670883175649, 43.380848127862336]],
    [ "Enedis",[-1.6723604496259126, 43.38069593158473]],
    [ "Enedis",[-1.6550082328854403, 43.37197408305808]],
    [ "Enedis",[-1.6735955000000002, 43.38598045]],
    [ "Enedis",[-1.6805305279536316, 43.3863918058058]],
    [ "Enedis",[-1.6759491167335643, 43.38295897268658]],
    [ "Enedis",[-1.6609796999999995, 43.375279]],
    [ "Enedis",[-1.6775799, 43.38665755]],
    [ "Enedis",[-1.6774628676373775, 43.37868351192033]],
    [ "Enedis",[-1.661560966044396, 43.377893067040304]],
    [ "Enedis",[-1.6746303814391048, 43.38748253877849]],
    [ "Enedis",[-1.673352748877583, 43.38441763737802]],
    [ "Enedis",[-1.67630625, 43.38108335]],
    [ "Enedis",[-1.6656778371808931, 43.37649205062579]],
    [ "Enedis",[-1.670005026670445, 43.38273817282575]],
    [ "Grange du Collège",[6.005711002829375, 47.248863682632425]],
    [ "HORTENSIAS",[6.036809265445412, 47.2575532980272]],
    [ "Enedis",[-1.621364821176422, 43.34670732361288]],
    [ "Enedis",[-1.6248353896238652, 43.354582707522695]],
    [ "Enedis",[-1.6258432898934956, 43.35306798781307]],
    [ "Enedis",[-1.62668244797767, 43.34353609552398]],
    [ "Enedis",[-1.6300881708794668, 43.34872295292164]],
    [ "Enedis",[-1.632477420059637, 43.35167537181712]],
    [ "Enedis",[-1.6245676149890902, 43.34896162914082]],
    [ "Enedis",[-1.6203028186565516, 43.34055775639292]],
    [ "Enedis",[-1.6167888178112273, 43.35069902492212]],
    [ "Enedis",[-1.617151659574565, 43.34642410436816]],
    [ "Enedis",[5.091415977757676, 43.33459576964693]],
    [ "Enedis",[5.096705724870657, 43.330954844578095]],
    [ "Enedis",[5.1112603005512165, 43.33814024436038]],
    [ "Enedis",[5.114573169076593, 43.341049410159776]],
    [ "Enedis",[5.092976141796905, 43.335639631810515]],
    [ "Enedis",[5.100281150000001, 43.33310249999999]],
    [ "Enedis",[5.0987575737417385, 43.336241044465474]],
    [ "Enedis",[5.105133719694573, 43.3321020933234]],
    [ "Enedis",[5.094976426490702, 43.33504464399743]],
    [ "Enedis",[5.1143787310016515, 43.3414983936768]],
    [ "Enedis",[5.106675559960476, 43.34008625274611]],
    [ "Enedis",[5.102965682714683, 43.33387200269353]],
    [ "Enedis",[5.114334259098109, 43.337726937355704]],
    [ "Enedis",[5.114553109124881, 43.33973357380209]],
    [ "Enedis",[5.112089744213109, 43.3365946358424]],
    [ "Enedis",[5.105247374373022, 43.33722799403788]],
    [ "Enedis",[5.108184286879067, 43.33447130886364]],
    [ "Enedis",[5.101835449999999, 43.33549239999999]],
    [ "Enedis",[5.111467387335632, 43.3352744451455]],
    [ "Enedis",[5.097979208967596, 43.33303924394525]],
    [ "Enedis",[5.095790008150165, 43.3329181588155]],
    [ "Enedis",[5.104895817202219, 43.33170883473107]],
    [ "Enedis",[5.1127438143739665, 43.33803134246207]],
    [ "Enedis",[5.1046227155364825, 43.33247795514974]],
    [ "Enedis",[5.1067421514143225, 43.33695559243576]],
    [ "Enedis",[5.104875142776523, 43.33348790525848]],
    [ "Enedis",[5.1109039496412665, 43.33503572093252]],
    [ "Enedis",[5.101636422782783, 43.34025175281517]],
    [ "Enedis",[5.108477649999999, 43.33881155]],
    [ "Bourg",[0.4527871427746979, 47.73257480227122]],
    [ "BL Aguesseau 52",[2.236755586584015, 48.84214085933994]],
    [ "Enedis",[-1.4888888532652527, 43.49898743791432]],
    [ "Enedis",[-1.4643334329580082, 43.49987690824194]],
    [ "Enedis",[-1.493036654560382, 43.48663155857983]],
    [ "Enedis",[-1.4937705260376, 43.49298413083386]],
    [ "Enedis",[-1.4743582358212737, 43.496528384747826]],
    [ "Enedis",[-1.463409873365033, 43.51156981789501]],
    [ "Enedis",[-1.4949107062895421, 43.477476559629736]],
    [ "Enedis",[-1.4620517740953425, 43.495697370826434]],
    [ "Enedis",[-1.4885581344411445, 43.4887591836386]],
    [ "Enedis",[-1.4758835468020282, 43.505130320110496]],
    [ "Enedis",[-1.4788682316412458, 43.500719795348026]],
    [ "Enedis",[-1.4936272501610939, 43.478460586552956]],
    [ "Enedis",[-1.4515566723415965, 43.490210959579954]],
    [ "Enedis",[-1.4609109238838307, 43.49794273151165]],
    [ "Enedis",[-1.492236824498259, 43.50068258357381]],
    [ "Enedis",[-1.4943522406064536, 43.4952225722396]],
    [ "Enedis",[-1.4829658999999997, 43.47370990000001]],
    [ "Enedis",[-1.4580815931176982, 43.506685100407026]],
    [ "Enedis",[-1.4878524327881175, 43.49804031666408]],
    [ "Enedis",[-1.4847894997875604, 43.502529466656824]],
    [ "Enedis",[-1.4833564229677876, 43.48446821285251]],
    [ "Enedis",[-1.4883686854642746, 43.48246537789027]],
    [ "Enedis",[-1.471344660248766, 43.51098187819994]],
    [ "Enedis",[-1.4870661, 43.51104140000001]],
    [ "Enedis",[3.736655956432722, 48.00557768989511]],
    [ "Enedis",[-1.4667625000000006, 43.50502555]],
    [ "Enedis",[-1.4650550668887896, 43.491940416039064]],
    [ "Enedis",[-1.5042014616229469, 43.47798311611747]],
    [ "MARQUISAT Bayonne",[-1.4651537998910322, 43.49626025483828]],
    [ "Enedis",[-1.4677852796001158, 43.48063346604849]],
    [ "Enedis",[-1.487151920028231, 43.49255934911371]],
    [ "Enedis",[-1.4443512506977534, 43.493412184162004]],
    [ "Enedis",[-1.4673236373685559, 43.49916096961462]],
    [ "Enedis",[-1.4952488740722993, 43.48174805467331]],
    [ "Enedis",[-1.4506866, 43.49989035]],
    [ "Enedis",[-1.4950378919657896, 43.47676311947431]],
    [ "Enedis",[-1.4781395698277273, 43.48985239889525]],
    [ "Enedis",[-1.44657861162401, 43.49402794677452]],
    [ "Enedis",[-1.4627473000000009, 43.50448264999999]],
    [ "JULES FERRY",[-1.4670804542588496, 43.49398745682972]],
    [ "Enedis",[3.7708456122163825, 48.00605220386234]],
    [ "Enedis",[-1.4962230001287065, 43.4795298492126]],
    [ "Enedis",[-1.454988998876002, 43.496151935428195]],
    [ "Jardin Public",[3.731752072460517, 47.998082310068895]],
    [ "Enedis",[-1.49137895, 43.499116650000005]],
    [ "Enedis",[3.745816330245783, 47.997854513039265]],
    [ "Enedis",[-1.4361089955448894, 43.4976521666494]],
    [ "Enedis",[-1.471834971850009, 43.49207910906638]],
    [ "Enedis",[3.7342864864570493, 47.99629652813399]],
    [ "Enedis",[-1.4808886971630415, 43.49324303221112]],
    [ "Enedis",[3.7540487036649828, 48.010299209581355]],
    [ "Enedis",[3.7544948651894683, 47.99639050239617]],
    [ "Enedis",[-1.4727964999999998, 43.5058528]],
    [ "Enedis",[-1.4862629295847445, 43.49552009914521]],
    [ "Enedis",[-1.498267851723078, 43.48201487352518]],
    [ "Enedis",[3.6923649264159364, 47.987431157137856]],
    [ "Enedis",[3.7365149859235944, 47.98534643453423]],
    [ "CENTRE DE GROS",[-1.4929066869885181, 43.49104871565444]],
    [ "Enedis",[-1.4545308190628559, 43.489808798972476]],
    [ "Enedis",[-1.4840829000000009, 43.49024905]],
    [ "Enedis",[-1.4795917816394557, 43.48389022771305]],
    [ "Enedis",[-1.5021254681267042, 43.481922471471556]],
    [ "Enedis",[-1.4376834799817488, 43.49544185122376]],
    [ "Enedis",[-1.455167421621185, 43.488234449684676]],
    [ "Enedis",[-1.4957810851140942, 43.48337796272717]],
    [ "Enedis",[-1.4576988530645942, 43.47961123309152]],
    [ "Enedis",[-1.4356075085626905, 43.49911426920167]],
    [ "Enedis",[-1.46048894411093, 43.48408319491143]],
    [ "Enedis",[-1.4423464894617424, 43.49484870136642]],
    [ "Enedis",[-1.451329347473258, 43.48729767933214]],
    [ "Enedis",[-1.455856782443682, 43.48731589614428]],
    [ "Enedis",[-1.498536485507193, 43.485309265686695]],
    [ "Enedis",[-1.4479131450982405, 43.50390029682059]],
    [ "Enedis",[-1.4925438716683677, 43.48511010636271]],
    [ "Enedis",[-1.434907760173093, 43.50937475411009]],
    [ "Enedis",[-1.461293267237283, 43.50192774084008]],
    [ "Enedis",[-1.4886565212392622, 43.479120619887425]],
    [ "Enedis",[-1.474516048697572, 43.48107163810777]],
    [ "BAYONNE Pont de Fer St Esprit",[-1.4628957734221628, 43.49019375133554]],
    [ "Enedis",[-1.4830783198557256, 43.507953270544334]],
    [ "LASSEGUETTE",[-1.482363395928, 43.48145842873312]],
    [ "Ayhele",[-1.4329091000000003, 43.51130439999999]],
    [ "Enedis",[-1.495999590237138, 43.490908065197985]],
    [ "Enedis",[-1.479385935558172, 43.48030494490565]],
    [ "ARGOTTE B E",[-1.4649354197851299, 43.49572485708365]],
    [ "Enedis",[-1.4713236953697915, 43.505184815962146]],
    [ "Enedis",[-1.4791363670088014, 43.4845753816648]],
    [ "Enedis",[-1.465178488652735, 43.508072013016275]],
    [ "Enedis",[-1.4871338500000002, 43.48593460000001]],
    [ "Enedis",[-1.4920895838541122, 43.493192594215564]],
    [ "Enedis",[3.671999754002591, 48.077879490374386]],
    [ "Enedis",[2.185453789406216, 48.861981388342635]],
    [ "Enedis",[2.189300471235576, 48.862860333239524]],
    [ "Enedis",[3.745778870773405, 47.906417988246375]],
    [ "Enedis",[3.7614740332552965, 47.902049231824556]],
    [ "Enedis",[5.362373590035935, 44.76512120021747]],
    [ "Enedis",[5.39232048854178, 44.74056894588487]],
    [ "Enedis",[-0.19920642993226703, 46.40564459021101]],
    [ "Geredis",[-0.2126197833902466, 46.402958305827916]],
    [ "Enedis",[-0.19750659875759102, 46.418168431957206]],
    [ "Enedis",[-0.20618282055263926, 46.41286772531417]],
    [ "Enedis",[-0.1964356594674666, 46.41656532024247]],
    [ "Enedis",[-0.20018740294211404, 46.41401144165012]],
    [ "Enedis",[-0.20676191835219446, 46.41565718068464]],
    [ "Enedis",[-0.1948592436451152, 46.41822759117094]],
    [ "Enedis",[-0.19971636883813496, 46.416163327173]],
    [ "Enedis",[-0.20844431209106687, 46.41167092742008]],
    [ "Enedis",[4.081108226816856, 48.26196531389684]],
    [ "VICTOR HUGO",[4.0718648, 48.262374550000004]],
    [ "Enedis",[3.5026274804774435, 47.95682594775857]],
    [ "Enedis",[3.515354018284947, 47.969634952190034]],
    [ "Enedis",[3.5342339, 47.97129090000001]],
    [ "Enedis",[3.5106251114796985, 47.963723707954905]],
    [ "Enedis",[3.5364445203429775, 47.97435122015081]],
    [ "Enedis",[3.5067524027759953, 47.959068768503485]],
    [ "Enedis",[3.539022140107497, 47.97058012935856]],
    [ "Enedis",[3.5313226324851557, 47.97215603037807]],
    [ "Enedis",[3.535061615591619, 47.96118375413007]],
    [ "Enedis",[3.5144915355551882, 47.9602634280679]],
    [ "Enedis",[3.5137812955557126, 47.958263076653836]],
    [ "Enedis",[3.5346287018648916, 47.97110465030714]],
    [ "Enedis",[3.5326428215548478, 47.97026527354108]],
    [ "Enedis",[3.5388507845937633, 47.9723991420084]],
    [ "Enedis",[3.5354711590513963, 47.97160754417554]],
    [ "Enedis",[3.5097483466802135, 47.96269519026189]],
    [ "Enedis",[3.5304134628180495, 47.96278499557424]],
    [ "Enedis",[3.51315403480256, 47.96805970156525]],
    [ "Enedis",[3.5285677694523825, 47.97452037312223]],
    [ "Enedis",[3.5123764969089044, 47.97297290544374]],
    [ "Enedis",[3.543401751679183, 47.97132255938924]],
    [ "Enedis",[3.5221308000000002, 47.97271126369636]],
    [ "Enedis",[3.5031758741816392, 47.9690262445675]],
    [ "Enedis",[1.0262347170147097, 47.20424136586428]],
    [ "Enedis",[3.534718401019333, 47.971255560457124]],
    [ "Enedis",[-1.575229779493854, 43.449748448530215]],
    [ "Enedis",[-1.58973120396412, 43.4399918789671]],
    [ "Enedis",[-1.573349966058809, 43.45187302689324]],
    [ "Enedis",[-1.5929512000000008, 43.430257350000005]],
    [ "Enedis",[-1.5719200123090749, 43.44881217373735]],
    [ "Enedis",[-1.5836753177579057, 43.4457587895627]],
    [ "Ecoles",[-1.5885652717210754, 43.43848087351788]],
    [ "Enedis",[-1.5464078659274765, 43.44485730200661]],
    [ "Enedis",[-1.5785600279083616, 43.445246967920156]],
    [ "Enedis",[-1.568095715993854, 43.4501681009633]],
    [ "Enedis",[-1.5464508612612111, 43.44483338554534]],
    [ "Enedis",[-1.5762779151856814, 43.446243873595236]],
    [ "Enedis",[-1.5873086335029691, 43.439675471303374]],
    [ "Enedis",[-1.8601197, 46.8427033]],
    [ "Enedis",[-1.8584014500000003, 46.84508145]],
    [ "Enedis",[-1.8624757499999998, 46.84957534999999]],
    [ "Enedis",[-1.8620712300958793, 46.84130199628489]],
    [ "Enedis",[-1.8616000184961128, 46.84805114030232]],
    [ "Enedis",[-1.8619743499999997, 46.843509749999996]],
    [ "Enedis",[-1.859380974197944, 46.8449476905859]],
    [ "Enedis",[3.317465773701246, 48.192520945694994]],
    [ "Enedis",[3.287005312614939, 48.19788201956852]],
    [ "Enedis",[3.2888212078298698, 48.189958377773436]],
    [ "Enedis",[3.2915682789531835, 48.19793738756971]],
    [ "Enedis",[3.303024736419877, 48.206134804297214]],
    [ "Enedis",[3.271633410422275, 48.19705908328672]],
    [ "Enedis",[3.3006836833397815, 48.20315469897251]],
    [ "Enedis",[3.3040455025511943, 48.19876384907255]],
    [ "Enedis",[3.2809090545286983, 48.1864968494734]],
    [ "Enedis",[3.278707234657111, 48.199856192468644]],
    [ "Enedis",[3.322887110215523, 48.195420983285075]],
    [ "Enedis",[3.320214374992343, 48.1947013026046]],
    [ "Enedis",[3.282685630513745, 48.20005040941334]],
    [ "Enedis",[3.294056307655464, 48.189744069418396]],
    [ "Enedis",[3.2882099339038997, 48.19761221152776]],
    [ "Enedis",[3.29726943884053, 48.18844024443486]],
    [ "Enedis",[3.314583811268998, 48.19333846048999]],
    [ "Enedis",[3.2774417282154045, 48.20572528895339]],
    [ "Enedis",[3.2699195999999997, 48.20722279999999]],
    [ "Enedis",[3.276144929812392, 48.20696362161149]],
    [ "Enedis",[3.2854555166670214, 48.195614021659395]],
    [ "Enedis",[-0.47028160861092383, 46.31386462195699]],
    [ "Enedis",[3.27006968209194, 48.199525465288495]],
    [ "Enedis",[3.283251593520325, 48.18507840627789]],
    [ "Enedis",[3.292561130962571, 48.20224958716979]],
    [ "Enedis",[3.3021565314246346, 48.20284616917449]],
    [ "Enedis",[3.28565745, 48.21000175]],
    [ "Enedis",[3.321056380516004, 48.191319225696745]],
    [ "Enedis",[3.289386708574972, 48.19012643654202]],
    [ "Enedis",[3.2636218315946373, 48.19929985237151]],
    [ "Enedis",[3.317790972110244, 48.19486677514404]],
    [ "Enedis",[3.3189759519420816, 48.194849084810954]],
    [ "Enedis",[3.277694279089963, 48.20497610773837]],
    [ "Enedis",[3.2760746335973523, 48.19312082079891]],
    [ "BL Béranger 12",[2.227648273401015, 48.83995859976346]],
    [ "Enedis",[4.02872348634063, 50.16951662962876]],
    [ "Enedis",[4.021084312077875, 50.16532523567731]],
    [ "EDF",[4.079803943189499, 48.27471717137468]],
    [ "8 mai 1945",[4.074196906767947, 48.27549417673043]],
    [ "Ribot 68",[4.051172329823444, 48.29936345570753]],
    [ "Les Noes 48",[4.055254036577922, 48.29852458990981]],
    [ "Enedis",[-1.8585653500000001, 46.83805389999999]],
    [ "Enedis",[-1.8603637671240687, 46.83337605199233]],
    [ "Enedis",[-1.85696685, 46.83280295]],
    [ "Enedis",[-1.8552707500000003, 46.8354742]],
    [ "Enedis",[-1.8630238160066148, 46.838621955421075]],
    [ "Enedis",[-1.8590564179651246, 46.83873340322781]],
    [ "Enedis",[-1.8608908393997303, 46.835979470784274]],
    [ "Enedis",[-1.0156970819508064, 45.62753009298939]],
    [ "Enedis",[-1.0197696500000004, 45.65418935000001]],
    [ "Enedis",[-1.002438332782328, 45.617430571512024]],
    [ "Enedis",[-1.0494482997167676, 45.62727383697194]],
    [ "Enedis",[-1.0324112177196307, 45.63838540577356]],
    [ "Enedis",[-1.045175151712633, 45.62376203853282]],
    [ "Enedis",[-1.0220371290240156, 45.63655834103433]],
    [ "Enedis",[-1.0097952745475116, 45.62092909819004]],
    [ "Enedis",[-1.0332774153567366, 45.635664381768485]],
    [ "Enedis",[-0.9964583466441708, 45.61957082613517]],
    [ "Enedis",[-1.035429967629913, 45.63489556560168]],
    [ "Enedis",[-1.0209498651052926, 45.62448343268767]],
    [ "Enedis",[-0.9987139785593692, 45.62392319372168]],
    [ "Enedis",[-1.023855628643631, 45.63778935916383]],
    [ "Enedis",[-1.0208283415486852, 45.63578148440151]],
    [ "Enedis",[-1.0091909242502217, 45.62681880458609]],
    [ "Enedis",[-1.044054855288491, 45.625795270400914]],
    [ "Enedis",[-1.0389536411162634, 45.62911574608707]],
    [ "Enedis",[-1.022693378058421, 45.62404798307859]],
    [ "Enedis",[-1.0305316682928933, 45.63814700270241]],
    [ "Enedis",[-1.02193454718433, 45.626961104538644]],
    [ "Enedis",[-1.0244542812874922, 45.65419946068347]],
    [ "Enedis",[-1.0126391225464384, 45.62103930219385]],
    [ "Enedis",[-1.0348000338976773, 45.62264393735976]],
    [ "Enedis",[-1.0227555499999998, 45.63284985]],
    [ "Enedis",[-1.0053943954985836, 45.61590779719131]],
    [ "Enedis",[-1.0278285860416145, 45.6315757287725]],
    [ "Enedis",[-1.041878810786312, 45.63233548063803]],
    [ "Enedis",[-0.9808058139197048, 45.63412093157566]],
    [ "Enedis",[-1.04117065, 45.6287062]],
    [ "Enedis",[-1.0011756813979247, 45.63135379746349]],
    [ "Enedis",[-1.035772568542074, 45.63797042562464]],
    [ "Enedis",[-1.0362235499999997, 45.62775969999999]],
    [ "Enedis",[-1.0178788500000002, 45.633703000000004]],
    [ "Enedis",[-1.0313029953475603, 45.622612869028174]],
    [ "Enedis",[-1.0297891105538137, 45.64362809749216]],
    [ "Enedis",[-1.0418371559537165, 45.64212579491728]],
    [ "Enedis",[-1.045101399999999, 45.63010934999999]],
    [ "Enedis",[-1.0273981697425265, 45.64502333196393]],
    [ "Enedis",[-1.0406297769503698, 45.63710504704298]],
    [ "Enedis",[-1.051019670812966, 45.63630471659749]],
    [ "Enedis",[-1.0309836945769002, 45.630968019912316]],
    [ "Enedis",[-1.0196179208219662, 45.63254523785187]],
    [ "Enedis",[-1.0255215157469242, 45.64157371675893]],
    [ "Enedis",[-1.0391593648025281, 45.63634951376581]],
    [ "Enedis",[-1.039622017671703, 45.621967301673244]],
    [ "Enedis",[-1.005437166431452, 45.61728735150422]],
    [ "Enedis",[-1.023742459877855, 45.63279727969307]],
    [ "Enedis",[-1.0351200374423895, 45.62086805359506]],
    [ "Enedis",[-1.0085928572054192, 45.62966684853372]],
    [ "Enedis",[-1.0387672328330495, 45.64001420884895]],
    [ "Enedis",[-1.0168111999999998, 45.63042635]],
    [ "Enedis",[-1.0036320049914014, 45.62333539770529]],
    [ "Enedis",[-1.0523441277770123, 45.629816936940735]],
    [ "Enedis",[-0.9988468137600593, 45.6330033268772]],
    [ "Enedis",[-1.0304575798093702, 45.65055632978924]],
    [ "Enedis",[-1.0171597761431967, 45.62432764141015]],
    [ "Enedis",[-1.0129007516477275, 45.625523832857866]],
    [ "Enedis",[-1.0119703366163817, 45.61754698359618]],
    [ "Enedis",[-1.0486045978628853, 45.63916554117656]],
    [ "Enedis",[-1.0508377520931205, 45.6277825235202]],
    [ "Enedis",[-1.0059591202434472, 45.6188257728481]],
    [ "Enedis",[-1.0183035450400724, 45.62815778059833]],
    [ "Enedis",[-1.0368420515711962, 45.64906634179978]],
    [ "Enedis",[-1.0224543875464913, 45.628685003183584]],
    [ "Enedis",[-1.049544479500825, 45.62166790931263]],
    [ "Enedis",[-1.01997605, 45.6341001]],
    [ "Enedis",[-1.0243489682623983, 45.64558899142589]],
    [ "Enedis",[4.067507113592597, 48.27792245057324]],
    [ "Sorea",[6.419239760121785, 45.20564771245031]],
    [ "Planchamps",[6.4193256504886, 45.20863169177161]],
    [ "Enedis",[6.4015014884505606, 46.83471808212514]],
    [ "Salle Polyvalente",[-0.020717815156651203, 47.82601251639775]],
    [ "Enedis",[1.1746921854286885, 49.219402356980844]],
    [ "Enedis",[1.1793032382133573, 49.207563968695474]],
    [ "Enedis",[1.1656183141097747, 49.21425706947304]],
    [ "Enedis",[-2.163528739997175, 46.922027326849594]],
    [ "Enedis",[1.1627739847769316, 49.21457534359615]],
    [ "Enedis",[-2.161490405653762, 46.914369917393806]],
    [ "Enedis",[-0.05289939166666636, 47.754054966666665]],
    [ "Enedis",[-2.1888088999999997, 46.95352685]],
    [ "Enedis",[1.1722267710268321, 49.212806330694015]],
    [ "Enedis",[-2.166171920555556, 46.927420794888896]],
    [ "Enedis",[-2.181595636993138, 46.9446266696326]],
    [ "Enedis",[1.1630247673094836, 49.2132509684709]],
    [ "Enedis",[-2.1716635500000003, 46.93651385]],
    [ "Enedis",[-2.1667582999999997, 46.93096465000001]],
    [ "Enedis",[-2.183402256513218, 46.951878132192775]],
    [ "Enedis",[-2.177302217606837, 46.94504259731054]],
    [ "Enedis",[-2.1785887, 46.94229695000001]],
    [ "Enedis",[-2.1730968287905044, 46.93957425506021]],
    [ "Enedis",[0.9652351235647438, 49.157057084905134]],
    [ "Enedis",[-1.8501017779359903, 46.83507689217776]],
    [ "Enedis",[4.069099871009149, 48.30204906291632]],
    [ "Enedis",[4.085768366389499, 48.289044007245955]],
    [ "Enedis",[4.063594404143764, 48.292269300935224]],
    [ "Enedis",[4.084706650151898, 48.285009216624445]],
    [ "Enedis",[4.092757223768523, 48.3077726671548]],
    [ "Enedis",[4.076229317484973, 48.28251437068579]],
    [ "Enedis",[4.092633251628077, 48.30769497661654]],
    [ "Enedis",[4.080472332126351, 48.290692122137536]],
    [ "Brossolette 5",[4.074660535142542, 48.292190451624634]],
    [ "Enedis",[4.083480294299678, 48.29158281693987]],
    [ "Enedis",[4.090803423149337, 48.310006067573816]],
    [ "Enedis",[4.088482847575823, 48.29811951772]],
    [ "Enedis",[4.087925616223583, 48.30111662653345]],
    [ "Enedis",[4.067080849999999, 48.29175775]],
    [ "Dumont NN10",[4.068306906003811, 48.30713947508373]],
    [ "Enedis",[4.0884668681161385, 48.296868353196885]],
    [ "Enedis",[4.072576337734561, 48.289053407503005]],
    [ "Enedis",[4.080853668454909, 48.28416069344064]],
    [ "Enedis",[4.083784226949443, 48.30773042677806]],
    [ "Enedis",[4.093719945949215, 48.29872677646748]],
    [ "Expo 1",[4.074085517638386, 48.289762854347174]],
    [ "Enedis",[4.066672397093077, 48.290918094957554]],
    [ "Enedis",[4.079160275489094, 48.28863379371142]],
    [ "Enedis",[1.2139541183475442, 49.26867463663607]],
    [ "Enedis",[1.2118292150267418, 49.2685932273898]],
    [ "Enedis",[1.2022532633362084, 49.270563980812724]],
    [ "Enedis",[1.1930253058136002, 49.258534744234055]],
    [ "Enedis",[4.088748265879058, 48.308530472874644]],
    [ "Enedis",[1.2191620233739837, 49.267756454979676]],
    [ "Enedis",[1.2247748269128662, 49.267343758369336]],
    [ "Enedis",[1.2090913594781076, 49.26685582461714]],
    [ "Enedis",[4.079138709208401, 48.28609368842218]],
    [ "Parc Expo 4",[4.071692438069927, 48.29071686711739]],
    [ "Enedis",[1.2159625486501775, 49.264786631763116]],
    [ "Enedis",[1.1989082696032902, 49.27375726700131]],
    [ "Enedis",[4.0837011, 48.29312965]],
    [ "Enedis",[1.19683689698882, 49.2561959265527]],
    [ "Enedis",[1.2154028422753593, 49.26625604568036]],
    [ "Enedis",[1.2266770007757626, 49.26630718542564]],
    [ "Enedis",[1.2159775309883687, 49.27501741273927]],
    [ "Enedis",[1.2190001086409634, 49.27377922439464]],
    [ "Enedis",[1.2063402553037075, 49.27868602102843]],
    [ "Enedis",[1.2081193391986047, 49.275621550132676]],
    [ "73141352",[1.9120833358342977, 47.91200749525682]],
    [ "Enedis",[1.312986599436951, 43.653854686341376]],
    [ "Enedis",[1.3146394419096628, 43.64642332857874]],
    [ "Enedis",[1.3124881715161802, 43.65816364335966]],
    [ "Enedis",[1.3162142704444681, 43.65321357118472]],
    [ "Enedis",[1.3087496500000002, 43.6565053]],
    [ "Enedis",[1.3394466500000004, 43.65147005000001]],
    [ "Crabinet",[1.319137376529887, 43.64257579431014]],
    [ "Enedis",[1.320343529566078, 43.6555563545983]],
    [ "Enedis",[1.3173436201659536, 43.65472407602758]],
    [ "Enedis",[1.3245245192479478, 43.64675187959608]],
    [ "Enedis",[1.3323098084944098, 43.6518933454705]],
    [ "Enedis",[1.3368632537596814, 43.64861671680428]],
    [ "Enedis",[1.3119564459917206, 43.656009996382615]],
    [ "Enedis",[1.3346955560652016, 43.657613710469654]],
    [ "Enedis",[1.3280422787136432, 43.65250609321401]],
    [ "Enedis",[1.3224171999999998, 43.64938605]],
    [ "Enedis",[1.3441510089583686, 43.64865593324596]],
    [ "Enedis",[1.3142739783277824, 43.65462175709102]],
    [ "Enedis",[1.3221275808621507, 43.66034149786831]],
    [ "Enedis",[1.3095327296044743, 43.65444510832779]],
    [ "Enedis",[1.3277569338647033, 43.65091199376746]],
    [ "Enedis",[1.3246727687155793, 43.644332927587556]],
    [ "Enedis",[1.337625264045517, 43.64654166409667]],
    [ "Enedis",[1.320753549999999, 43.6470125]],
    [ "Enedis",[1.314364967453455, 43.65676091479356]],
    [ "Enedis",[1.3304779826024775, 43.64080813433445]],
    [ "Enedis",[1.3210729279154159, 43.64370000491684]],
    [ "Enedis",[1.3237830003793984, 43.65523040546169]],
    [ "Enedis",[1.3379394754528746, 43.6620889793034]],
    [ "Enedis",[1.3017556999999997, 43.65588819999999]],
    [ "Enedis",[4.0829300940141975, 48.284230615857176]],
    [ "Enedis",[0.48428608529069656, 47.596609379815895]],
    [ "Enedis",[0.47630966049560475, 47.60140293399374]],
    [ "Enedis",[0.48221045000000007, 47.601832800000004]],
    [ "Enedis",[0.4762664482061412, 47.603034564287874]],
    [ "Enedis",[0.48434394999999997, 47.60310719999999]],
    [ "Enedis",[1.4998716999999997, 43.473816299999996]],
    [ "Enedis",[0.6995530607717813, 47.62192612489358]],
    [ "Enedis",[0.5385027424158298, 47.64092149359218]],
    [ "Enedis",[0.5940227281366857, 47.60523970675233]],
    [ "Enedis",[1.4993135000000002, 43.47752909999999]],
    [ "Wilson",[2.4081862649485415, 48.88129390815639]],
    [ "Enedis",[-2.23864745, 46.96761595]],
    [ "Enedis",[-2.2116449590412866, 46.961745715400625]],
    [ "Enedis",[-2.2542153999999996, 46.959638399999996]],
    [ "Enedis",[-2.229207861553867, 46.96857622114037]],
    [ "Enedis",[-2.2168384903809817, 46.9671865316193]],
    [ "Enedis",[-2.2498980817490635, 46.96678850271979]],
    [ "Enedis",[-2.2070575000000003, 46.96013790000001]],
    [ "Enedis",[2.1791035242885344, 48.86447095206104]],
    [ "Enedis",[-1.8422353000000014, 46.835645]],
    [ "Enedis",[3.396879805368314, 48.25251152768433]],
    [ "Enedis",[4.0347691, 48.337693599999994]],
    [ "Enedis",[4.045049069644177, 48.3336729597571]],
    [ "Enedis",[0.6703586066344918, 47.5698906414704]],
    [ "Enedis",[4.805065782640298, 45.71594188264295]],
    [ "Enedis",[4.793573423584352, 45.72134468998271]],
    [ "Enedis",[4.787420954284735, 45.721781498160816]],
    [ "Enedis",[4.796814860038275, 45.710828482338236]],
    [ "Enedis",[4.819208283549783, 45.71557522236653]],
    [ "Enedis",[4.811085050647467, 45.70714424612383]],
    [ "73382297",[4.810519377972125, 45.718913934839826]],
    [ "Enedis",[4.818841662879754, 45.711652129413]],
    [ "Enedis",[4.805104786072685, 45.722462319045455]],
    [ "Enedis",[4.801529682066517, 45.71007857890972]],
    [ "Enedis",[4.805628775199219, 45.717908572572355]],
    [ "Enedis",[4.8052436499999995, 45.71937024999999]],
    [ "Enedis",[4.8185738803929645, 45.718368696653805]],
    [ "Enedis",[4.818488674275363, 45.71661170018019]],
    [ "Enedis",[3.0775830902671766, 48.281824635627075]],
    [ "Enedis",[3.3087336820793047, 48.126475431130416]],
    [ "Enedis",[3.3058256820245187, 48.12988350290171]],
    [ "Enedis",[3.748580094011368, 47.667640661226244]],
    [ "Enedis",[3.303920018835642, 48.130533217700055]],
    [ "Enedis",[3.3444968783125892, 48.13368058986182]],
    [ "Enedis",[3.647721700366788, 47.81143916034111]],
    [ "Esclavelles",[2.4247186380945167, 48.88225937505192]],
    [ "Iberic",[2.4233405738646536, 48.879933983807476]],
    [ "Enedis",[2.423545418389902, 48.88196111496163]],
    [ "Depinay",[2.4235739513506616, 48.88245824934884]],
    [ "Enedis",[2.4174334260805432, 48.881803253245856]],
    [ "Sablière",[2.4206993720789924, 48.88051934030495]],
    [ "Anticipation",[2.415816525108586, 48.88178566033497]],
    [ "Pensée",[2.4282313957577806, 48.880302058159586]],
    [ "Chatterley",[2.4194080467043566, 48.88172782988187]],
    [ "Convention",[2.4164427041134373, 48.88297285536977]],
    [ "Enedis",[2.42623622483258, 48.885571594830616]],
    [ "PC Nobel",[2.4140423406894325, 48.876912782039454]],
    [ "Enedis",[2.42245217719627, 48.879133347157676]],
    [ "Enedis",[2.4250033886971814, 48.884126014286664]],
    [ "Enedis",[-0.6733353649216464, 46.335890492166804]],
    [ "Enedis",[3.703550013176731, 43.932126152023926]],
    [ "Enedis",[3.708931365053167, 43.93619028215463]],
    [ "Enedis",[3.7068582079337937, 43.938290106152735]],
    [ "Enedis",[3.711595433107784, 43.9341115568242]],
    [ "Enedis",[3.7026796812939637, 43.9334691415331]],
    [ "Enedis",[3.707339680927721, 43.93248226594623]],
    [ "Enedis",[2.1788080115507675, 48.899925065843725]],
    [ "Enedis",[2.1775408883047342, 48.898349833179175]],
    [ "Enedis",[-0.6086218810426866, 47.517763958466446]],
    [ "Genêts 3",[-0.584994649999999, 47.501011]],
    [ "Mendès 22",[-0.58515405, 47.5024826]],
    [ "Enedis",[-0.9860038455083843, 45.58851436541363]],
    [ "Enedis",[-0.9958943166666661, 45.613033650000006]],
    [ "Enedis",[-0.9982859974416778, 45.61086263755663]],
    [ "Enedis",[-1.0005695097022869, 45.604070877184135]],
    [ "Enedis",[-0.9870068799283814, 45.5909176800991]],
    [ "Enedis",[-0.9843694502140625, 45.609228899986164]],
    [ "Enedis",[-0.9857691347496599, 45.58947539213544]],
    [ "Enedis",[-0.9869552020120144, 45.59658085284487]],
    [ "Enedis",[-1.0077062796177572, 45.612011029827414]],
    [ "Enedis",[-0.9930034601541111, 45.60795181690155]],
    [ "Enedis",[-1.0092859511204955, 45.60375162177161]],
    [ "Enedis",[-0.982479252412183, 45.6078867320801]],
    [ "Enedis",[-0.9838541193984487, 45.60478371462446]],
    [ "Enedis",[-0.9886108330896594, 45.59721239191157]],
    [ "Enedis",[-1.002100159044509, 45.60713780087371]],
    [ "Enedis",[-0.9867224230023628, 45.60371839640662]],
    [ "Enedis",[-0.9854210435638078, 45.584477387585494]],
    [ "Enedis",[-0.9859020500000005, 45.61085814999999]],
    [ "Enedis",[-0.9993487040157255, 45.606456157193676]],
    [ "Enedis",[-0.9880057419207807, 45.61222280249938]],
    [ "Enedis",[-0.978535618644179, 45.605813254210624]],
    [ "Enedis",[-0.9892189808219176, 45.610199772716896]],
    [ "Enedis",[-1.0045762733968517, 45.61266612055302]],
    [ "Enedis",[-0.9855947193799687, 45.586117438060775]],
    [ "Enedis",[-0.9768647588381969, 45.59721149285891]],
    [ "Enedis",[-0.9949510122989357, 45.60878154103124]],
    [ "Enedis",[-0.9866813214872424, 45.59910394120232]],
    [ "Enedis",[-0.9918895002419011, 45.60295681793223]],
    [ "Enedis",[-0.9864207724188224, 45.59374093958858]],
    [ "Enedis",[-0.9703067144569651, 45.606762534169846]],
    [ "Enedis",[-0.9741146380966714, 45.57902119595975]],
    [ "Enedis",[-0.9722737903567616, 45.6037102652722]],
    [ "Enedis",[-1.0079990309024942, 45.60277186280845]],
    [ "Enedis",[-0.999585336816062, 45.61379799526261]],
    [ "Enedis",[-1.012403619552008, 45.60216586342482]],
    [ "Enedis",[-0.9800927922522951, 45.59915065474762]],
    [ "Enedis",[-0.9845706045697509, 45.595293661438156]],
    [ "Enedis",[-0.9886817363292904, 45.608442779304205]],
    [ "Enedis",[-0.990924970391509, 45.601754633633846]],
    [ "Enedis",[-0.9953858244330441, 45.60317002847964]],
    [ "Enedis",[-0.996024291534974, 45.6101199937198]],
    [ "Enedis",[-1.0044510773333064, 45.605700709130865]],
    [ "Enedis",[-0.9718738739598629, 45.587632501370535]],
    [ "Enedis",[-0.9863107102327815, 45.601247234111085]],
    [ "Enedis",[-1.0045604, 45.6071453]],
    [ "Enedis",[-0.9970458623090893, 45.60140002669234]],
    [ "Enedis",[-0.9924056047284674, 45.60983332646711]],
    [ "Enedis",[-0.9891679416751904, 45.61480424174338]],
    [ "Enedis",[-1.0056818999999995, 45.600900849999995]],
    [ "Enedis",[-0.9682002085859842, 45.6057133619608]],
    [ "Enedis",[-1.0077331164867986, 45.60012719828597]],
    [ "Enedis",[-0.98942485, 45.6132937]],
    [ "Enedis",[-0.9911534999999999, 45.61222155]],
    [ "Enedis",[-0.9943746113292412, 45.60507730824395]],
    [ "Enedis",[-0.9833155014662076, 45.6014181580508]],
    [ "Enedis",[-0.9825717500000001, 45.595099850000004]],
    [ "Enedis",[-1.0081854779858355, 45.61012039831842]],
    [ "Enedis",[-0.9936763000000006, 45.6121853]],
    [ "Enedis",[-1.0012682022724717, 45.60928088371021]],
    [ "Enedis",[-0.9878206099819885, 45.59324877761635]],
    [ "Enedis",[-1.0021754421524214, 45.61024328018684]],
    [ "Enedis",[-0.9701037566066474, 45.607564114708524]],
    [ "Enedis",[-1.009727152934512, 45.60298741080897]],
    [ "Enedis",[-0.9883042076601403, 45.57882893578076]],
    [ "Enedis",[2.270318054326536, 48.80054809884856]],
    [ "Enedis",[2.2742157566492915, 48.800897126172316]],
    [ "Enedis",[-0.7472099, 46.91855954999999]],
    [ "Enedis",[3.2236150708007503, 50.70353438988062]],
    [ "Enedis",[-0.7446736999999999, 46.91988920000001]],
    [ "Enedis",[3.231664300505357, 50.708640319991915]],
    [ "Enedis",[3.2013441329732006, 50.70163628751043]],
    [ "Enedis",[3.224858294012576, 50.70673663160493]],
    [ "Enedis",[3.22837067603403, 50.70823965547332]],
    [ "Enedis",[3.1842933098418915, 50.71157390855056]],
    [ "Enedis",[2.4295916655368797, 48.88295807357421]],
    [ "Enedis",[3.177316973460817, 50.7138658890827]],
    [ "Enedis",[3.1823409363030013, 50.714591363625935]],
    [ "Enedis",[3.220291620488965, 50.709528384764724]],
    [ "Enedis",[3.2127845220854505, 50.69521589695887]],
    [ "Enedis",[3.202268017817766, 50.698430273095035]],
    [ "Enedis",[3.218194931119431, 50.70245161966249]],
    [ "Enedis",[2.4481444889128197, 48.87781903105692]],
    [ "Enedis",[3.217958246906222, 50.69790680742152]],
    [ "Enedis",[3.2027401208896107, 50.70538436882664]],
    [ "Cachin",[2.42994060742863, 48.87910737386602]],
    [ "Enedis",[3.2156112572995363, 50.700085584164704]],
    [ "Enedis",[3.1808618176807677, 50.70900233916468]],
    [ "Enedis",[3.186198951696783, 50.71316779336965]],
    [ "Enedis",[3.1858883807231524, 50.70825904417021]],
    [ "Enedis",[3.2476656915684434, 50.70865653716446]],
    [ "Enedis",[3.2150532394774394, 50.697849252565824]],
    [ "Enedis",[3.1775149817068913, 50.71247727457931]],
    [ "Enedis",[3.2112829490996044, 50.70186325006606]],
    [ "Enedis",[3.179847883300237, 50.71596925180555]],
    [ "Enedis",[3.241877524024715, 50.70624037506853]],
    [ "Enedis",[3.2101319243791755, 50.69872063470593]],
    [ "Enedis",[3.2257951866693366, 50.70454165969726]],
    [ "Enedis",[3.211785366830121, 50.70531646454176]],
    [ "Enedis",[3.221019591142044, 50.7073954824942]],
    [ "Enedis",[3.214119314531652, 50.701317381061074]],
    [ "Enedis",[3.2348359511259703, 50.71082229065909]],
    [ "Enedis",[3.179140966465983, 50.711380774307244]],
    [ "Enedis",[0.6988921272747303, 47.39678161961452]],
    [ "Enedis",[4.134797537675943, 48.30035790664267]],
    [ "Enedis",[3.8452436820185536, 50.22523970950807]],
    [ "Enedis",[3.8464627635726165, 50.220619636049484]],
    [ "Enedis",[3.8468148883141002, 50.22341549622389]],
    [ "Enedis",[3.852904138043119, 50.22089865991502]],
    [ "Enedis",[3.8576530537493383, 50.21692776377061]],
    [ "Enedis",[3.853939136626694, 50.21884758755142]],
    [ "Enedis",[3.848774860755251, 50.225172773685216]],
    [ "Enedis",[3.8492459633698024, 50.22203404872887]],
    [ "Enedis",[2.329855480114335, 48.916931747517836]],
    [ "Enedis",[2.3327299731364595, 48.91177597834306]],
    [ "Enedis",[2.3317923249198906, 48.91654794714986]],
    [ "Enedis",[1.4782597622927856, 43.5437475826867]],
    [ "Enedis",[1.4848962477117926, 43.55276297119103]],
    [ "Chappe",[1.479669600000001, 43.55212645000001]],
    [ "Enedis",[1.4871335872800728, 43.551723065982344]],
    [ "Enedis",[1.461264529783688, 43.54182018497585]],
    [ "Enedis",[1.4833844047215514, 43.55332450877283]],
    [ "Mimosas",[1.476473301593818, 43.552691751159735]],
    [ "Enedis",[1.475187372235985, 43.53566914470375]],
    [ "Complexe Sportif",[1.4807450849780177, 43.54793036735926]],
    [ "Enedis",[1.4700306915656964, 43.54761077754087]],
    [ "Enedis",[1.4877174180625592, 43.538849719163956]],
    [ "Enedis",[2.33751433004112, 48.90632295878678]],
    [ "Enedis",[1.468527899275737, 43.53385901672702]],
    [ "Enedis",[1.4678967452047693, 43.53198063105236]],
    [ "Enedis",[1.4582058687555972, 43.539726852665915]],
    [ "Enedis",[1.4767133246600808, 43.55525771354793]],
    [ "Enedis",[1.4839440046877135, 43.544448544079984]],
    [ "Enedis",[1.4819284774704329, 43.54487198015615]],
    [ "Enedis",[1.489590684913041, 43.55212811162015]],
    [ "Enedis",[1.4785601898882652, 43.53801752500226]],
    [ "Enedis",[1.476765867470363, 43.550833767285056]],
    [ "Moulin",[1.466270252075314, 43.546864766093165]],
    [ "Enedis",[1.4883504242663308, 43.54373210937904]],
    [ "Gleyze-Vieille",[1.4636671755699289, 43.544575137015386]],
    [ "Enedis",[1.477470626919703, 43.54517271938561]],
    [ "Enedis",[1.4851463635379514, 43.55134208860367]],
    [ "Decale",[1.4742215539308594, 43.55425056912463]],
    [ "Enedis",[1.460601518444269, 43.538785640800214]],
    [ "Enedis",[1.4746119188147708, 43.546496193546254]],
    [ "Enedis",[1.486160423136177, 43.549795548252085]],
    [ "Enedis",[1.477974514402353, 43.53717794064976]],
    [ "Pouciquot",[1.4726248768402408, 43.552019073582564]],
    [ "Enedis",[1.4800414795337602, 43.54112925450571]],
    [ "Enedis",[1.4848541360791514, 43.54935056194206]],
    [ "Bac",[1.4758055787082505, 43.54887866748245]],
    [ "Enedis",[1.478982737168075, 43.54602472391453]],
    [ "Enedis",[1.4907292590466072, 43.55331916243979]],
    [ "Enedis",[1.4751340081507835, 43.54283427935533]],
    [ "Enedis",[1.479697948713339, 43.54329916133659]],
    [ "Enedis",[1.480922641519378, 43.54200666588619]],
    [ "Enedis",[1.486200241121668, 43.54148400428109]],
    [ "Enedis",[1.4802420961550329, 43.550316819944605]],
    [ "Enedis",[1.4874332035148825, 43.55266155219566]],
    [ "Enedis",[1.4807243, 43.53677779999999]],
    [ "Enedis",[1.4627886728149528, 43.538287687067076]],
    [ "Enedis",[1.478095587136753, 43.53524552234794]],
    [ "Enedis",[1.482922681554843, 43.53840057617569]],
    [ "Enedis",[1.4721237182466576, 43.53431377225551]],
    [ "Enedis",[1.4560463955570069, 43.542101545411484]],
    [ "Enedis",[4.149322389185567, 48.23208232117642]],
    [ "Enedis",[3.8628135619072053, 50.21375071769266]],
    [ "Enedis",[3.876735813607345, 50.21649630337528]],
    [ "Enedis",[2.119404998645341, 48.819082244816286]],
    [ "Enedis",[-1.8614681008275413, 46.820347383114054]],
    [ "Enedis",[-1.8461557790205048, 46.814225384245525]],
    [ "Enedis",[4.06002726745147, 47.8570443345135]],
    [ "Enedis",[3.31049931131393, 48.14810298224524]],
    [ "Enedis",[3.3140602339062597, 48.14680432742057]],
    [ "Enedis",[4.085458955078801, 47.84469188102121]],
    [ "Enedis",[3.3142731090172215, 48.15029785963011]],
    [ "Enedis",[4.08133064894388, 47.8266162447347]],
    [ "Enedis",[4.0418582800809135, 48.29788736833456]],
    [ "Chalmel 21",[4.062114126728491, 48.29622367004065]],
    [ "Rue de l'Ouest",[4.059687800997543, 48.29338316169968]],
    [ "Blanche Pierre",[4.061095120271879, 48.29161271677671]],
    [ "Enedis",[4.053417956696059, 48.29431693606746]],
    [ "Lamartine 27",[4.046353462951769, 48.2996090823448]],
    [ "Hamelet 81",[4.036580316337739, 48.30113869742865]],
    [ "Lamoriciere 4",[4.05596115, 48.29298045000001]],
    [ "Lamartine 11",[4.048277338746155, 48.29955669204637]],
    [ "Gallieni 116",[4.051110690363995, 48.29363476499158]],
    [ "Gallieni 134",[4.048972247438401, 48.29334929256062]],
    [ "Dumont D'Urville",[4.040201887120296, 48.29228057273641]],
    [ "11 Novembre 1918",[4.059375763020966, 48.291344329154796]],
    [ "Brassens 21",[4.0330035827852715, 48.299901448947814]],
    [ "Enedis",[-0.0013370844665316517, 43.242583802773176]],
    [ "Enedis",[0.05778985897243589, 43.26505704440133]],
    [ "Enedis",[2.1682478813292296, 48.80671257715472]],
    [ "Enedis",[2.1691357323429967, 48.80391275993888]],
    [ "Enedis",[2.17156127745677, 48.79908742385931]],
    [ "Enedis",[2.16429462605514, 48.80616135149163]],
    [ "Enedis",[2.1714057317022872, 48.80568365051091]],
    [ "Enedis",[2.1670553207217966, 48.80815033099414]],
    [ "Enedis",[2.1686606682224268, 48.79932763471471]],
    [ "Enedis",[2.1659972118158217, 48.80525284524693]],
    [ "Enedis",[2.18135480483633, 48.799945800879534]],
    [ "Enedis",[2.1791664721080277, 48.8075523976533]],
    [ "Enedis",[2.161349337224559, 48.80122894804727]],
    [ "Enedis",[2.173222760910975, 48.80111540374858]],
    [ "Boissier",[2.1772000209846123, 48.8000444572517]],
    [ "Enedis",[2.175239692817789, 48.80321740484087]],
    [ "Enedis",[2.1837440872158638, 48.80000146017746]],
    [ "Enedis",[2.1713591, 48.807395750000005]],
    [ "Enedis",[2.1763536047300467, 48.806481304230694]],
    [ "Les Chenes",[5.706937066078118, 45.048878617951964]],
    [ "Enedis",[1.58670315, 43.58572230000001]],
    [ "Enedis",[1.5840456416899789, 43.58562873355743]],
    [ "Enedis",[1.5784368383309542, 43.58525649967699]],
    [ "Enedis",[1.5768879636868984, 43.58247111493581]],
    [ "Enedis",[1.5809307628175977, 43.58597363164757]],
    [ "Enedis",[4.789356481891763, 45.71978672891724]],
    [ "Enedis",[-0.5847652000000005, 46.369198749999995]],
    [ "Enedis",[-0.5775511257882886, 46.37110128152685]],
    [ "Enedis",[1.3661036340502106, 43.75715054145124]],
    [ "Enedis",[-0.5858550631640804, 46.37225467053918]],
    [ "Enedis",[-0.5998022673251696, 46.36228765293421]],
    [ "Enedis",[-0.5885304500000007, 46.37041165000001]],
    [ "Enedis",[-0.6055271989226197, 46.36606151670127]],
    [ "Enedis",[-0.6013473404700963, 46.367877685875264]],
    [ "Enedis",[-0.5961453464356785, 46.369289983865926]],
    [ "Enedis",[-0.5983998541683421, 46.36682008230718]],
    [ "Enedis",[-0.5966049212588467, 46.38115075517785]],
    [ "Enedis",[3.8321762354993982, 50.21143289999999]],
    [ "Enedis",[-0.7371201999999991, 46.31711655]],
    [ "Enedis",[-0.7403669032046104, 46.31426163291862]],
    [ "Enedis",[-0.7323343000000001, 46.311384350000004]],
    [ "Enedis",[-1.0684762473596285, 45.635762242215314]],
    [ "Enedis",[-1.0628660400790249, 45.63731033461432]],
    [ "Enedis",[-1.057167696397663, 45.63534865408594]],
    [ "Enedis",[-1.062519636047322, 45.65033255240757]],
    [ "Enedis",[-1.0676596687214235, 45.64947190223189]],
    [ "Enedis",[-1.0633073176309247, 45.64049862702493]],
    [ "Enedis",[-1.0706055933061507, 45.63825196706158]],
    [ "Enedis",[-1.0582675844300102, 45.64814282673561]],
    [ "Enedis",[-1.0656947351482615, 45.64280394516342]],
    [ "Enedis",[-1.0703584635008607, 45.64475232878472]],
    [ "Enedis",[-1.0637453564828003, 45.64195488328923]],
    [ "Enedis",[-1.0559618761192642, 45.63267523843522]],
    [ "Enedis",[-1.0606286059315577, 45.638591709204775]],
    [ "Enedis",[-1.0547866599533016, 45.646169862493636]],
    [ "Enedis",[-1.0576409547782348, 45.62853734902607]],
    [ "Enedis",[-1.0647286211830291, 45.63608618233456]],
    [ "Enedis",[-1.0733982, 45.639086150000004]],
    [ "Enedis",[-1.0645829973189522, 45.63290688358501]],
    [ "Enedis",[-1.0602876784867457, 45.65782649919461]],
    [ "Enedis",[-1.06193543576504, 45.630215821334495]],
    [ "Enedis",[-1.0608232827526947, 45.64180573612975]],
    [ "Enedis",[-1.0601980685607262, 45.631339837588236]],
    [ "Enedis",[-1.0737148844572466, 45.636348454972875]],
    [ "Enedis",[-1.0530405322243537, 45.629516598956684]],
    [ "Enedis",[-1.0584622747939028, 45.64053280550803]],
    [ "Enedis",[-1.0679745039793591, 45.63291113367843]],
    [ "Enedis",[-1.0660492666666668, 45.63156065884862]],
    [ "Enedis",[-1.0678546838028924, 45.641978280560345]],
    [ "Enedis",[-1.0434899000000002, 45.651218099999994]],
    [ "Enedis",[-1.0666446999999997, 45.647619950000006]],
    [ "Enedis",[-1.0565702159521961, 45.63858001006093]],
    [ "Enedis",[-1.0619769492665816, 45.65236524276905]],
    [ "Enedis",[-1.0544960159479246, 45.63141847678817]],
    [ "Enedis",[-1.063065645067498, 45.63149166000908]],
    [ "Enedis",[-1.0667626677313304, 45.638399984215624]],
    [ "Vernays",[6.234083597750543, 45.775782665683124]],
    [ "Camping Nubliere",[6.2173507795511505, 45.790368313410106]],
    [ "Doussar5",[6.227857066605709, 45.78070346923519]],
    [ "Res Combe d'Ire",[6.218666302906757, 45.77636505671951]],
    [ "Pre de Foire",[6.223990472251373, 45.775559709987384]],
    [ "Gare",[6.2299300551997305, 45.78220943954264]],
    [ "Couardet",[6.2322262856354556, 45.777426677547865]],
    [ "La Creuse",[6.228852991667193, 45.77545758424148]],
    [ "Enedis",[6.227757550000001, 45.779600099999996]],
    [ "Enedis",[6.206436116496672, 45.772799995693035]],
    [ "Vorget",[6.223864475739016, 45.78206481268456]],
    [ "Enedis",[6.2088779190480174, 45.805183969701275]],
    [ "Chez Monnet",[6.226027934359816, 45.78405781695193]],
    [ "Marceau Dessus",[6.208719800000001, 45.768947250000004]],
    [ "Enedis",[6.22730686204194, 45.778152850509116]],
    [ "Fiocca",[6.233195696289187, 45.77645044200069]],
    [ "Sautreau",[6.212372911660582, 45.79568221298465]],
    [ "Pres de la Croix",[6.210715499999999, 45.86965279999999]],
    [ "Enedis",[-0.6879882163056327, 46.3897586839963]],
    [ "Enedis",[6.237006904255971, 45.88920225432429]],
    [ "ZA Alex 2",[6.24146965, 45.89427450000001]],
    [ "Enedis",[6.213515505945678, 45.792518102018754]],
    [ "Enedis",[6.2070968533209445, 45.799018161204394]],
    [ "Porte",[6.205346882608733, 45.796022146611904]],
    [ "Enedis",[6.210934199635463, 45.788953426657514]],
    [ "Enedis",[-1.1209171, 45.65119775]],
    [ "Enedis",[-1.0749858604442875, 45.6440725817811]],
    [ "Enedis",[-1.0668445781821614, 45.65376046780885]],
    [ "Enedis",[-1.0804534000000001, 45.63942135000001]],
    [ "Enedis",[-1.0910893787655178, 45.644509650174776]],
    [ "Enedis",[-1.0826647484287184, 45.65414726228029]],
    [ "Enedis",[-1.0798361582460958, 45.650868446028156]],
    [ "Enedis",[-1.1058728784197442, 45.651134282140504]],
    [ "Enedis",[-1.1087076074974538, 45.65015503902083]],
    [ "Enedis",[-1.0823584432535795, 45.64903230610724]],
    [ "Enedis",[-1.0783501480567115, 45.640370561382]],
    [ "Enedis",[-1.1085860413561643, 45.647602423635746]],
    [ "Enedis",[-1.072423529041725, 45.651286804779865]],
    [ "Enedis",[-1.0834176367039448, 45.648118611085735]],
    [ "Enedis",[-1.1082464810286419, 45.64674992015194]],
    [ "Enedis",[-1.107763827966453, 45.64840193859821]],
    [ "Enedis",[-1.0813353258985048, 45.65762502058651]],
    [ "Enedis",[-1.074252142910191, 45.64320946609037]],
    [ "Enedis",[-1.1042388002081347, 45.64908861661318]],
    [ "Enedis",[-1.0927816, 45.65346144999999]],
    [ "Enedis",[-1.0966812023622199, 45.64698838255056]],
    [ "Enedis",[-1.0841471000000011, 45.65203855]],
    [ "Enedis",[-1.0941996832253809, 45.647130490866175]],
    [ "Enedis",[-1.0841110393400786, 45.63987959850658]],
    [ "Enedis",[-1.0755770658605872, 45.64587639580365]],
    [ "Enedis",[-1.1149600000000002, 45.64877834999999]],
    [ "Enedis",[-1.0715841591268762, 45.64598622503434]],
    [ "Enedis",[-1.095619562112071, 45.64529767370657]],
    [ "Enedis",[-1.083219925896572, 45.642882991753645]],
    [ "Enedis",[-1.0699734443656435, 45.65274891160154]],
    [ "Enedis",[-1.1000281448058065, 45.64780536436094]],
    [ "Enedis",[-1.0969041383541378, 45.65334117853924]],
    [ "Enedis",[-1.0989787298174452, 45.6503751912779]],
    [ "Enedis",[-1.079704539154169, 45.64272868101343]],
    [ "Enedis",[-1.1087363499999998, 45.65170759999999]],
    [ "Enedis",[-1.0772958774713406, 45.650863486841665]],
    [ "Enedis",[-1.0920769788602551, 45.65146329413161]],
    [ "Enedis",[-1.0871655946545182, 45.642093349620524]],
    [ "Enedis",[-1.0807894834001999, 45.63742114541295]],
    [ "Enedis",[-1.1053963999999996, 45.650391150000004]],
    [ "Enedis",[-1.070459955486259, 45.647492283624196]],
    [ "Enedis",[-1.0826826893199468, 45.64633980606031]],
    [ "Enedis",[-1.0873393407055194, 45.65573713912818]],
    [ "Enedis",[-1.0797099326402546, 45.646304801069405]],
    [ "Enedis",[-1.0882118663786995, 45.64388067418702]],
    [ "Enedis",[-1.094693190377347, 45.64208307647647]],
    [ "Enedis",[-1.0882306613134896, 45.64673508963852]],
    [ "PRE DE L ORME 2",[5.685817938980068, 45.10882545083421]],
    [ "ZAC PRE DE L ORME",[5.68648365, 45.107374899999996]],
    [ "Enedis",[5.667216975326217, 45.093628258007115]],
    [ "Enedis",[-1.291429916197866, 43.48962276792757]],
    [ "Ruisseau St Paul",[-1.2954965797365832, 43.48834474281987]],
    [ "Enedis",[-1.2983433726582545, 43.490764056055774]],
    [ "Enedis",[-1.3004784499999993, 43.4889711]],
    [ "Bourg",[-1.2927278534124347, 43.49449839170887]],
    [ "Geredis",[-0.60583005, 46.43957035]],
    [ "Enedis",[4.79857295, 45.71930425]],
    [ "Enedis",[1.5620429618943052, 43.59272357573415]],
    [ "Enedis",[1.5446416332269104, 43.60130140022634]],
    [ "Enedis",[1.56193245, 43.59442475]],
    [ "Enedis",[1.558638335950811, 43.59516291008068]],
    [ "Enedis",[1.570712233104159, 43.59452379916302]],
    [ "Groupe Scolaire",[5.683286798219736, 45.08959952724214]],
    [ "Les Plantées",[5.68846871790883, 45.09103146665443]],
    [ "Enedis",[1.4382573618421055, 43.68190546140351]],
    [ "Enedis",[1.4275490959350599, 43.67609534928597]],
    [ "Enedis",[1.4309614988790886, 43.676171804882436]],
    [ "Enedis",[1.4365086238691052, 43.67973620162312]],
    [ "Enedis",[1.4481557280357908, 43.68188593530113]],
    [ "Enedis",[1.4329210133008834, 43.67889392538268]],
    [ "Enedis",[1.4265352, 43.6774837]],
    [ "Enedis",[1.4286172410679763, 43.67450402670214]],
    [ "Enedis",[1.4344586342800973, 43.68286174545141]],
    [ "Enedis",[1.4338085018297728, 43.67698484074136]],
    [ "Cimetière",[6.221015411858728, 45.906418922743214]],
    [ "REThones",[6.203621484735473, 45.91813566723887]],
    [ "Enedis",[6.572676397261818, 48.031065682451484]],
    [ "Enedis",[6.578023600000002, 48.02907985]],
    [ "Enedis",[6.574889453453296, 48.0301762826716]],
    [ "Enedis",[6.574701984146833, 48.02905829743712]],
    [ "Enedis",[6.5804171, 48.027699999999996]],
    [ "Enedis",[6.5787268, 48.026077099999995]],
    [ "Enedis",[3.834797523910207, 50.20742588491256]],
    [ "Enedis",[3.8301491343441647, 50.210269295276056]],
    [ "Enedis",[3.827892358527721, 50.20862219094348]],
    [ "Enedis",[3.8293660999999997, 50.209704849999994]],
    [ "Enedis",[1.3101869591146105, 43.5563289428537]],
    [ "Enedis",[1.2888749247360016, 43.595547690701984]],
    [ "Enedis",[3.8285464914327307, 50.19998398946198]],
    [ "Enedis",[3.8261253353285607, 50.201718951845294]],
    [ "Enedis",[3.8257152685961797, 50.203403513561724]],
    [ "Enedis",[1.419888541716596, 43.57213777036359]],
    [ "Enedis",[-1.9183446999999998, 46.832871850000004]],
    [ "Enedis",[1.4069896661719878, 43.55932085988276]],
    [ "Enedis",[1.3985612384890653, 43.55074142394439]],
    [ "Enedis",[1.416119011981907, 43.55471435993403]],
    [ "Enedis",[1.3800077603756498, 43.56972314451953]],
    [ "Enedis",[1.3682549181833938, 43.56143614812037]],
    [ "Enedis",[1.3672656824826972, 43.55969912235037]],
    [ "Enedis",[1.4227443858023758, 43.55582334443488]],
    [ "Enedis",[1.3720268442655161, 43.564276262258716]],
    [ "Enedis",[1.4001049989670153, 43.54598602764747]],
    [ "Enedis",[1.389500226117921, 43.56904627763557]],
    [ "Enedis",[1.385594639285443, 43.569679325337965]],
    [ "Enedis",[1.4093509532905055, 43.55694611297279]],
    [ "Enedis",[1.3701857258872974, 43.556759590475856]],
    [ "Enedis",[1.4005932231553717, 43.548017312467486]],
    [ "Enedis",[1.369571926636183, 43.56948330775856]],
    [ "Enedis",[1.3942923214395755, 43.54724925666981]],
    [ "Enedis",[1.397330245634362, 43.549480865242174]],
    [ "Enedis",[1.4168182796865842, 43.57060769988814]],
    [ "Enedis",[1.4116287780533163, 43.5617657084246]],
    [ "Enedis",[1.38649995, 43.56352395]],
    [ "Enedis",[1.4118134692796285, 43.5505619835853]],
    [ "Enedis",[1.3778720677242222, 43.56829808205206]],
    [ "Enedis",[1.4141639863250048, 43.568345456820616]],
    [ "Enedis",[1.388356376181447, 43.56479434601808]],
    [ "Enedis",[1.3957965053683015, 43.54337741029274]],
    [ "Enedis",[1.3759828034644068, 43.55483266671501]],
    [ "Enedis",[1.4160842626544112, 43.55467847943421]],
    [ "Enedis",[1.4038797500000002, 43.55504545]],
    [ "Enedis",[1.4147048523759047, 43.54932037661421]],
    [ "Enedis",[1.4227928854327927, 43.55580687366167]],
    [ "Enedis",[1.374093523499461, 43.56504141340869]],
    [ "Enedis",[1.3931992391714842, 43.551908532999455]],
    [ "Enedis",[1.4048952500000003, 43.5631659]],
    [ "Enedis",[1.3740347499999996, 43.558505800000006]],
    [ "Enedis",[1.3876906696065325, 43.56828122465054]],
    [ "Enedis",[1.4038598000000002, 43.55970175]],
    [ "Enedis",[1.3712375336203801, 43.55980584919268]],
    [ "Enedis",[1.4037855701151596, 43.56468060206826]],
    [ "Enedis",[1.40967937409543, 43.56380554854705]],
    [ "Enedis",[1.402446419134878, 43.549956083228174]],
    [ "Enedis",[1.4167956780963837, 43.5523677153058]],
    [ "Enedis",[1.4115936357340926, 43.554220106162596]],
    [ "Enedis",[1.3752127499999998, 43.56245065]],
    [ "Enedis",[1.387035441553386, 43.56846677387111]],
    [ "Enedis",[1.366408493447096, 43.560684385605136]],
    [ "Enedis",[1.4137037999999995, 43.56506765000001]],
    [ "Enedis",[1.3913593512881568, 43.55762018936448]],
    [ "Enedis",[1.4035281830938928, 43.55145635024878]],
    [ "Enedis",[1.405997081985649, 43.56554514475216]],
    [ "Enedis",[-1.3036207201825671, 43.385608560008976]],
    [ "Enedis",[-1.3173408744544524, 43.38684469322928]],
    [ "Enedis",[1.4034399081726907, 43.56659462048509]],
    [ "Enedis",[-1.3025996913527833, 43.38372577590014]],
    [ "Enedis",[-1.3046138512597185, 43.38749651640706]],
    [ "Enedis",[1.3930214628006232, 43.54105824962941]],
    [ "Enedis",[1.3933514999999999, 43.55431555]],
    [ "Enedis",[-1.31716625, 43.38440599999999]],
    [ "Enedis",[-1.3157674315593977, 43.38249953694477]],
    [ "Enedis",[1.3938776189747395, 43.54207529053477]],
    [ "Enedis",[1.3760397999999994, 43.5523282]],
    [ "Enedis",[1.382920080025451, 43.56090175635999]],
    [ "Enedis",[1.3966031616834846, 43.544857734545914]],
    [ "Enedis",[1.4226613501210292, 43.55585915033248]],
    [ "Enedis",[1.396335369331395, 43.54904576734496]],
    [ "Enedis",[-1.3078022694339388, 43.3835547436364]],
    [ "Enedis",[1.392103024565828, 43.56609112939272]],
    [ "Enedis",[-1.2998991605735633, 43.384546631035874]],
    [ "Enedis",[1.369204238943243, 43.56355855461764]],
    [ "Enedis",[1.384903749113779, 43.568955853344775]],
    [ "Enedis",[1.3982945402670672, 43.5459749352091]],
    [ "Enedis",[1.3834196140232402, 43.56951206665088]],
    [ "Enedis",[-1.2990774158860838, 43.38754525601508]],
    [ "Enedis",[-1.3047391074225356, 43.380887577149615]],
    [ "Enedis",[1.3987266521266075, 43.55929034500495]],
    [ "Enedis",[1.4126741364804942, 43.56082216645617]],
    [ "Enedis",[1.4084956093959147, 43.5582365291373]],
    [ "Enedis",[1.3824565685781298, 43.55400419683193]],
    [ "Enedis",[1.408703752530607, 43.57238718038621]],
    [ "Enedis",[1.4021486217749544, 43.55301548027621]],
    [ "Enedis",[-1.304264199026227, 43.38319689535828]],
    [ "Enedis",[1.3947371573429546, 43.54183533585484]],
    [ "Enedis",[-1.3102834999999997, 43.3854809]],
    [ "Enedis",[-1.375584744476234, 43.428988031308926]],
    [ "Enedis",[-1.3033333681557822, 43.37983290334696]],
    [ "Enedis",[1.4100169803148124, 43.5711437346987]],
    [ "Enedis",[1.3752667064969661, 43.55691763649056]],
    [ "Enedis",[1.3803177500000003, 43.56672249999999]],
    [ "Enedis",[1.4106623682688388, 43.56622480327243]],
    [ "Enedis",[1.3778958222435918, 43.56250428492027]],
    [ "Enedis",[1.3908029866860292, 43.567838111730836]],
    [ "Enedis",[1.3900258600000515, 43.56677908726008]],
    [ "Enedis",[1.4084700317594503, 43.56152030219134]],
    [ "Enedis",[1.4091619, 43.56008715]],
    [ "Enedis",[1.4107500500000008, 43.55910645]],
    [ "Enedis",[1.3863888202590338, 43.57163713464383]],
    [ "Enedis",[1.3959571648734177, 43.54784117025316]],
    [ "Enedis",[1.3932601040996289, 43.53900696672566]],
    [ "Enedis",[1.4157474332160696, 43.556860633798884]],
    [ "Enedis",[-1.8940340574200996, 46.836235375431926]],
    [ "Enedis",[-1.8987210999999993, 46.83334050000001]],
    [ "Gérédis",[-0.5844057190091687, 46.22660741539848]],
    [ "Gérédis",[-0.5866833386039663, 46.22695699633926]],
    [ "Gérédis",[-0.5813764277836214, 46.23044757863262]],
    [ "Enedis",[1.274658521247501, 43.551460190022304]],
    [ "Enedis",[1.2983744422368317, 43.55902053593297]],
    [ "Enedis",[1.287552122870585, 43.58242072384933]],
    [ "Enedis",[1.2943438025680936, 43.57451869401427]],
    [ "Enedis",[1.315185115002651, 43.56600996551805]],
    [ "Enedis",[1.3004319167960476, 43.59349564288403]],
    [ "Enedis",[1.2985917212429083, 43.56646324636513]],
    [ "Enedis",[1.2995442757076558, 43.54790556198407]],
    [ "Enedis",[1.3127050279070793, 43.56415512512967]],
    [ "Enedis",[1.2608895300101775, 43.55919710697103]],
    [ "Enedis",[1.2946824195950846, 43.56658282094924]],
    [ "Enedis",[1.30085829990448, 43.560628737498824]],
    [ "Enedis",[1.30381575, 43.55710079999999]],
    [ "Enedis",[1.2679978595577583, 43.54726350435511]],
    [ "Enedis",[1.314951463053818, 43.556688618149394]],
    [ "Enedis",[1.290444843242552, 43.56404073607173]],
    [ "Enedis",[1.2920227853770478, 43.559864903181285]],
    [ "Enedis",[1.2893931878119262, 43.56094506771737]],
    [ "Enedis",[1.2889007965742856, 43.55980119976765]],
    [ "Enedis",[1.3210645972226829, 43.564602646950576]],
    [ "Enedis",[3.8692255155829542, 43.580475372366465]],
    [ "Enedis",[3.8694233718644284, 43.58174793840793]],
    [ "Enedis",[1.3053141502480026, 43.56655335637381]],
    [ "Enedis",[1.2865740310166736, 43.56342946634855]],
    [ "Enedis",[1.2908799399059796, 43.56711710167806]],
    [ "Enedis",[1.3120108517910154, 43.57148871557932]],
    [ "Enedis",[1.2961538521839822, 43.564893615839324]],
    [ "Enedis",[1.2705945779394892, 43.56657108551525]],
    [ "Enedis",[1.3013067150766746, 43.57088269565863]],
    [ "Enedis",[1.3035623500000002, 43.56760515]],
    [ "Enedis",[1.2860043036040794, 43.56565466924598]],
    [ "Enedis",[1.2778861897164828, 43.56519556658657]],
    [ "Enedis",[1.3039853431678359, 43.5687751037174]],
    [ "Enedis",[1.2685586332819256, 43.56678480371851]],
    [ "Enedis",[1.3004664682596467, 43.57238385977603]],
    [ "Enedis",[1.2972679952957624, 43.59146909825111]],
    [ "Enedis",[1.3048776866905685, 43.56218235172529]],
    [ "Enedis",[1.3070082998978148, 43.56884508439645]],
    [ "Enedis",[1.291216184199215, 43.56943548210579]],
    [ "Enedis",[1.283970428764067, 43.56982699175432]],
    [ "Enedis",[1.2881291480223869, 43.55420553318931]],
    [ "Enedis",[1.2814445057842478, 43.56443713979027]],
    [ "Enedis",[1.2857962392374893, 43.567516039194466]],
    [ "Enedis",[1.3065872120824602, 43.55935088165907]],
    [ "Enedis",[1.2959314914229472, 43.563382967510314]],
    [ "Enedis",[1.2809050343689166, 43.568284944835355]],
    [ "Enedis",[1.2832192894178287, 43.56042242133232]],
    [ "Enedis",[1.2788244543328933, 43.56351675771886]],
    [ "Enedis",[1.29125954346277, 43.59261026922593]],
    [ "Enedis",[1.2941119228132496, 43.5601513412477]],
    [ "Enedis",[1.3056979449509283, 43.5672942546614]],
    [ "Enedis",[1.2864850435737947, 43.57066505727799]],
    [ "Enedis",[1.317915777484224, 43.56427018846514]],
    [ "Enedis",[1.3083639201519612, 43.56252213848972]],
    [ "Enedis",[1.2767559957115011, 43.561334421442496]],
    [ "Enedis",[1.2967300820492638, 43.57230021063402]],
    [ "Enedis",[1.306257502785472, 43.55693592173566]],
    [ "Enedis",[1.2724366678066954, 43.55780477871839]],
    [ "Enedis",[1.300113506068391, 43.56371210617216]],
    [ "Enedis",[1.2825204370515262, 43.55757345458383]],
    [ "Enedis",[1.308205638352372, 43.56086452082099]],
    [ "Enedis",[1.2988314525148854, 43.55265707172362]],
    [ "Enedis",[1.2639707930541848, 43.56248657455883]],
    [ "Enedis",[1.3209884565721883, 43.560291946169485]],
    [ "Enedis",[1.3101303812224308, 43.56798856099559]],
    [ "Enedis",[1.289840463838919, 43.5779825625605]],
    [ "Enedis",[1.281997517678167, 43.5625138903371]],
    [ "Enedis",[1.2886308386645162, 43.58494379503734]],
    [ "Enedis",[1.291724336810459, 43.59488269009122]],
    [ "Enedis",[1.3004557495282203, 43.557701919491365]],
    [ "Enedis",[1.296605843137049, 43.56925362942351]],
    [ "Enedis",[1.3121988097297295, 43.56859511324324]],
    [ "Enedis",[1.2950053361894842, 43.55383913882673]],
    [ "Enedis",[1.2802508868023015, 43.555605247387895]],
    [ "Enedis",[1.3047244815170125, 43.56341453038983]],
    [ "Enedis",[1.2985446855231868, 43.554462367414544]],
    [ "Enedis",[-0.8612327394489313, 45.554606910532584]],
    [ "Enedis",[-0.89347185, 45.56679450000001]],
    [ "Enedis",[-0.8609518333689116, 45.55271936766694]],
    [ "Enedis",[1.3784121456555798, 43.83489791112994]],
    [ "Enedis",[1.3799398198526498, 43.83719046022285]],
    [ "Enedis",[1.3919545831489581, 43.8323982698139]],
    [ "Route de Grenade",[1.3749226684864853, 43.698291017873444]],
    [ "Enedis",[1.3736617341528792, 43.7023853283632]],
    [ "Enedis",[1.3789514500000002, 43.695924500000004]],
    [ "Enedis",[1.3769140371270807, 43.694262645099734]],
    [ "Enedis",[1.3740578891682391, 43.69474593109307]],
    [ "Enedis",[1.372445116597065, 43.70395128993097]],
    [ "Enedis",[1.3769448523507661, 43.70213577386864]],
    [ "Enedis",[1.3717673014190204, 43.70134569899602]],
    [ "Enedis",[1.3784694699636988, 43.697738885302506]],
    [ "Enedis",[0.6792332813823417, 47.393267924054754]],
    [ "Enedis",[0.6988200698198533, 47.39474790333497]],
    [ "Enedis",[3.8305498050299622, 50.20355203384939]],
    [ "Enedis",[3.831468652235647, 50.20281802678925]],
    [ "Enedis",[3.835052415178103, 50.20577873380246]],
    [ "Enedis",[3.8313088146062086, 50.20143562589666]],
    [ "Enedis",[-0.78779005, 46.342079999999996]],
    [ "Enedis",[-0.8703340829598857, 46.3689493995807]],
    [ "Enedis",[-0.8353419499999999, 46.349996649999994]],
    [ "Enedis",[-0.8671408286081248, 46.36610368447988]],
    [ "Enedis",[-0.8637342999999997, 46.3604747]],
    [ "Gérédis",[-0.5836568279037859, 46.23799423442321]],
    [ "Enedis",[0.6954963815554754, 47.36191425278245]],
    [ "Enedis",[0.6777654784414532, 47.36379685938713]],
    [ "Enedis",[0.6979602456555264, 47.358975795272876]],
    [ "Enedis",[0.6740618612481102, 47.372635530012275]],
    [ "Enedis",[0.6787814784919824, 47.36503320711136]],
    [ "Enedis",[0.6806265155527059, 47.363405393257445]],
    [ "Enedis",[0.6838527500000012, 47.3650878]],
    [ "Enedis",[0.7048406359742598, 47.36507501902916]],
    [ "Enedis",[0.6815993894367582, 47.36362628961379]],
    [ "Enedis",[0.7031335091873345, 47.34894833545469]],
    [ "Enedis",[0.6968747926020853, 47.362808872074226]],
    [ "Enedis",[0.6854998000000003, 47.36741599999999]],
    [ "Enedis",[0.70580840804949, 47.35793703723635]],
    [ "74390841",[0.6984750878039829, 47.358557719150696]],
    [ "Enedis",[0.7014180135254879, 47.36356692592546]],
    [ "Enedis",[0.7006146722080484, 47.35680896436887]],
    [ "Enedis",[1.4367335153201162, 45.85583528106112]],
    [ "Enedis",[3.0872964923776283, 50.52460595128193]],
    [ "Enedis",[3.0993144778181816, 50.55107047643181]],
    [ "Enedis",[-1.89645185, 46.8317607]],
    [ "Enedis",[-1.8948471382809389, 46.82919051729672]],
    [ "Enedis",[3.1317244603116183, 45.896300882774334]],
    [ "Enedis",[3.0827446562527476, 50.54111055501527]],
    [ "Enedis",[3.122285627394635, 45.89128216666667]],
    [ "Enedis",[3.1056031206495507, 45.89267969871848]],
    [ "Enedis",[3.107094413899791, 45.897685750905296]],
    [ "Enedis",[3.1290019066366943, 45.897979707000644]],
    [ "Enedis",[3.101143879728051, 45.896690124285804]],
    [ "Enedis",[3.1296691725497463, 45.882420790148466]],
    [ "Enedis",[3.12349930814009, 45.90620802946368]],
    [ "Enedis",[3.123009697492745, 45.896257299221205]],
    [ "Enedis",[3.120903781298395, 45.89766185272933]],
    [ "Enedis",[3.106482340665874, 45.89443696706808]],
    [ "Enedis",[3.124377998835044, 45.88548200257713]],
    [ "Enedis",[3.098830555328228, 45.88442068499257]],
    [ "Enedis",[3.1471036500000005, 45.894717750000005]],
    [ "Enedis",[3.098745566527184, 45.9045381090958]],
    [ "Enedis",[3.12661631820316, 45.89457855224078]],
    [ "Enedis",[3.095568492099521, 45.89812079204332]],
    [ "Enedis",[3.0995209062667417, 45.89455968506979]],
    [ "Enedis",[3.1331289680845464, 45.906571249894846]],
    [ "Enedis",[3.103740444194553, 45.88533660685937]],
    [ "Enedis",[3.1147654369479523, 45.901889271985155]],
    [ "Enedis",[3.1255043356068364, 45.88047663062458]],
    [ "Enedis",[3.100436836567262, 45.8994697968684]],
    [ "Enedis",[3.1268169598133215, 45.879284661236554]],
    [ "Enedis",[1.3838880909675617, 43.7096453539637]],
    [ "Enedis",[1.3914481227260056, 43.71595277776199]],
    [ "Enedis",[1.3835373999999991, 43.711068950000005]],
    [ "Enedis",[1.384867409759407, 43.70465160880779]],
    [ "Enedis",[1.382980665646721, 43.70654703951369]],
    [ "Enedis",[1.3945158314773882, 43.71316104746488]],
    [ "Enedis",[1.3871213, 43.71937359999999]],
    [ "Enedis",[1.3937983324619139, 43.70804714229586]],
    [ "Enedis",[1.3810821477718542, 43.70381059965646]],
    [ "Enedis",[1.3900270629558547, 43.715916683969844]],
    [ "Enedis",[1.3857180155027342, 43.72036670875617]],
    [ "Enedis",[1.3849206500000013, 43.70830465]],
    [ "Enedis",[1.3853932506970645, 43.72327943630313]],
    [ "ETAT",[2.300692099569313, 48.90110641926547]],
    [ "Enedis",[1.3917467327285484, 43.713718278811186]],
    [ "Enedis",[1.3864274668372247, 43.70719629262336]],
    [ "Enedis",[1.5592661590179748, 43.705356125564]],
    [ "Enedis",[1.5610242005883201, 43.70401630077008]],
    [ "Enedis",[1.5577123315402133, 43.702295295757885]],
    [ "Enedis",[1.5563510000000003, 43.70426179999999]],
    [ "Enedis",[1.5644504898558276, 43.70822870180785]],
    [ "Enedis",[1.55931615, 43.701154100000004]],
    [ "Enedis",[1.5651815825015916, 43.70450473884113]],
    [ "Enedis",[1.5653905999999997, 43.7105462]],
    [ "Enedis",[2.8060040662835246, 42.609352346679444]],
    [ "Enedis",[1.5612601499999998, 43.717037399999995]],
    [ "Enedis",[2.8144333185491757, 42.60974310897097]],
    [ "Enedis",[1.5614157086857627, 43.708974965666656]],
    [ "Enedis",[2.809818282573786, 42.60628581425776]],
    [ "Enedis",[2.8088984363537954, 42.61200361842631]],
    [ "Enedis",[2.808275585970832, 42.60999711451667]],
    [ "Enedis",[2.8124602358793207, 42.613654218425104]],
    [ "Enedis",[4.793008466687879, 45.72173463156137]],
    [ "Enedis",[4.795822796453054, 45.71915971472589]],
    [ "Enedis",[-0.5939516000000011, 43.87198685]],
    [ "Enedis",[2.252789052627473, 48.82875258222546]],
    [ "Enedis",[2.2496415705363724, 48.82658507307051]],
    [ "Enedis",[4.7899516227650425, 45.715212346535296]],
    [ "Enedis",[1.5847498610276252, 43.67830594065357]],
    [ "Enedis",[1.5745959499999997, 43.698572250000005]],
    [ "Enedis",[1.5842252187059742, 43.68274328940777]],
    [ "Enedis",[-1.5769055835684753, 47.21076411849805]],
    [ "Enedis",[6.60158170773379, 48.02113006628632]],
    [ "Enedis",[1.5732928955894752, 43.70463012937974]],
    [ "Enedis",[0.5770526236117538, 47.73778354937607]],
    [ "Mairie",[0.5736022996562058, 47.72972451731439]],
    [ "Châtillon",[0.5766729289070609, 47.72749758808452]],
    [ "Enedis",[0.5986289024338356, 47.72690058116887]],
    [ "Enedis",[0.5728573514890496, 47.72657613521183]],
    [ "Enedis",[0.5672204911133082, 47.73216115511519]],
    [ "Enedis",[0.5538180636038139, 47.73317989597089]],
    [ "Porte-chaise",[-1.532093035309123, 47.1999973125837]],
    [ "Enedis",[6.641574900000001, 44.88036264999999]],
    [ "Geredis",[-0.19377666836717913, 46.42736275490225]],
    [ "Enedis",[1.5000582500000001, 43.573486749999994]],
    [ "Enedis",[1.5082932050596065, 43.565543542223374]],
    [ "Enedis",[1.4958741781657259, 43.56198975119762]],
    [ "Enedis",[1.5019497039832344, 43.56170166087884]],
    [ "Enedis",[1.4988603000000003, 43.5740203]],
    [ "Enedis",[1.5035859960786313, 43.56254302390593]],
    [ "Enedis",[1.5022577294968318, 43.5618674116875]],
    [ "Enedis",[1.5064391704545574, 43.56374826757556]],
    [ "Enedis",[1.5133399999999988, 43.575225149999994]],
    [ "74578209",[-0.30734004562008094, 46.35360376951975]],
    [ "Enedis",[-1.879181260473774, 46.83664292507615]],
    [ "Enedis",[-1.8807370801801178, 46.83590894518354]],
    [ "Enedis",[-1.8853837, 46.83895405]],
    [ "74605686",[-0.4204262399984794, 46.29512284529903]],
    [ "Enedis",[2.1260205803112298, 48.79338670907908]],
    [ "Enedis",[2.102870599273657, 48.78433843316938]],
    [ "Enedis",[2.13112405328066, 48.79789836102596]],
    [ "Enedis",[2.144654057377693, 48.813231392843925]],
    [ "Enedis",[2.150607554738513, 48.8021844187149]],
    [ "Provence",[2.139261597991132, 48.8057714726565]],
    [ "Enedis",[2.1608727039239297, 48.798025651476884]],
    [ "Enedis",[2.134866234304105, 48.80295657359357]],
    [ "Enedis",[2.125327180533043, 48.799696853752444]],
    [ "Enedis",[2.145216111317782, 48.817049506445166]],
    [ "Enedis",[2.0962821235184714, 48.78431319507575]],
    [ "Enedis",[2.140461539281167, 48.814079437863214]],
    [ "Picardie coupure",[2.1463512451909423, 48.80823763532594]],
    [ "Enedis",[2.1352715085540814, 48.80549719550436]],
    [ "Enedis",[2.1515493464740136, 48.79865356076013]],
    [ "Despagne",[2.1330160221471464, 48.810436921004815]],
    [ "Enedis",[2.1562210085700184, 48.79528923251821]],
    [ "Enedis",[2.1536632969339005, 48.80113508668312]],
    [ "Enedis",[2.149267931301297, 48.80112973838796]],
    [ "Saint-Cloud",[2.1303020500000005, 48.804660150000004]],
    [ "Enedis",[3.1029963403796827, 50.674895631077774]],
    [ "Enedis",[3.1001888510238222, 50.67676918861027]],
    [ "Enedis",[3.0966807405664505, 50.66289320216146]],
    [ "Enedis",[3.0746473879514795, 50.667972217376]],
    [ "Enedis",[3.108971481130233, 50.66751353807363]],
    [ "Enedis",[3.1009877408979056, 50.68116721528286]],
    [ "Enedis",[3.091516752801849, 50.66127547289099]],
    [ "Enedis",[3.096180616892837, 50.6614637023002]],
    [ "Enedis",[3.1049935572042893, 50.681944419953275]],
    [ "Enedis",[3.1008747144936195, 50.66127465774917]],
    [ "Enedis",[3.090561039226693, 50.67144401196553]],
    [ "Enedis",[3.106838244843196, 50.670017014862175]],
    [ "Enedis",[3.0966436138165934, 50.65541205604075]],
    [ "Enedis",[3.0786862322107984, 50.67008086090957]],
    [ "Enedis",[3.0794202474478993, 50.657983008917846]],
    [ "Enedis",[3.0751915493862154, 50.6626997238892]],
    [ "Enedis",[3.1057555085858146, 50.67307232668723]],
    [ "Enedis",[3.084451186374631, 50.6593282648916]],
    [ "Enedis",[3.1083960217974185, 50.673049871181675]],
    [ "Enedis",[3.095153654786403, 50.677246568430505]],
    [ "Enedis",[3.087533195787853, 50.68030458757916]],
    [ "Enedis",[3.0855212615918046, 50.67119482523726]],
    [ "Enedis",[3.086028620758434, 50.657728630938756]],
    [ "Enedis",[3.0982962039133928, 50.683429243274404]],
    [ "Enedis",[3.1175546920871913, 50.67497305715177]],
    [ "Enedis",[3.0913688744031957, 50.66421125255847]],
    [ "Enedis",[3.0842333255188197, 50.67385111704532]],
    [ "Enedis",[3.087206431888432, 50.65858479208836]],
    [ "Enedis",[3.105493065628988, 50.6626064009077]],
    [ "Enedis",[3.0947818479188745, 50.68158333743266]],
    [ "Enedis",[3.095609488974659, 50.65178622983368]],
    [ "Enedis",[3.0984122675260464, 50.68000028315445]],
    [ "Enedis",[3.0939775450177986, 50.67871726305568]],
    [ "Enedis",[3.0808656166309554, 50.659270039382804]],
    [ "Enedis",[3.0764499190761114, 50.66436130535225]],
    [ "Enedis",[3.0768832298022595, 50.667655645374296]],
    [ "Enedis",[3.091883733588814, 50.67820550219713]],
    [ "Enedis",[3.089834249999999, 50.65997674999999]],
    [ "Enedis",[3.0844526170954887, 50.65783450008406]],
    [ "Enedis",[3.089209992110582, 50.6583023322552]],
    [ "Enedis",[3.098152514094952, 50.655465853759964]],
    [ "Enedis",[3.0869192651261375, 50.66952860454634]],
    [ "Enedis",[3.1067637385301015, 50.68347104923795]],
    [ "Enedis",[-1.8649029499999992, 46.83473185]],
    [ "Enedis",[-1.8749042048106794, 46.83578315487617]],
    [ "Enedis",[-1.8692975812495916, 46.83713873425943]],
    [ "Enedis",[-1.8672746999999998, 46.8350672]],
    [ "Enedis",[-1.8678820999999997, 46.8402543]],
    [ "Enedis",[-1.8712127000000007, 46.839852750000006]],
    [ "Enedis",[-1.8704647174646378, 46.83421045297538]],
    [ "Enedis",[1.3329653374190247, 43.75861205264349]],
    [ "Enedis",[1.2925845798576143, 43.76071378935452]],
    [ "Enedis",[1.2883995004280036, 43.771384368154486]],
    [ "Enedis",[1.3013966875488214, 43.75581251682828]],
    [ "Enedis",[1.2899546000000002, 43.775537449999995]],
    [ "Enedis",[1.298503564653276, 43.75642269742474]],
    [ "Enedis",[1.293016942930322, 43.75617021468326]],
    [ "Enedis",[1.4701903166718244, 43.5869225141754]],
    [ "Enedis",[1.4581324499999997, 43.59428514999999]],
    [ "Enedis",[1.4622587881650246, 43.58863033317228]],
    [ "Enedis",[1.4666202670055277, 43.588325107707576]],
    [ "Enedis",[1.4695645154621, 43.58419903451692]],
    [ "Enedis",[1.4791621557746506, 43.58084974063517]],
    [ "Enedis",[1.473480221930311, 43.585026216502236]],
    [ "Enedis",[1.3707025805572124, 43.58158450980785]],
    [ "ALEMBERT",[1.3841297595914532, 43.58796101545202]],
    [ "Enedis",[1.3914523493432422, 43.582314172763276]],
    [ "Enedis",[1.380204801014046, 43.576653732849145]],
    [ "Enedis",[1.3760625391461947, 43.58092310711274]],
    [ "Enedis",[1.3895445812842573, 43.588634747774144]],
    [ "Enedis",[1.3982882378140467, 43.57507912045439]],
    [ "Enedis",[1.3862521999999984, 43.582839400000005]],
    [ "Enedis",[3.756866629113062, 45.37841380655001]],
    [ "Station d'Ambert",[3.7464561390313365, 45.57375406161761]],
    [ "Enedis",[1.39263408051314, 43.58608098140795]],
    [ "Enedis",[1.3855731841563146, 43.586236220224585]],
    [ "Enedis",[1.3907941286514776, 43.58122083281011]],
    [ "Enedis",[1.3737797195883865, 43.580979706063935]],
    [ "Enedis",[1.3914373600011634, 43.5774402600692]],
    [ "Enedis",[1.3840925672744377, 43.576564200776595]],
    [ "Enedis",[1.4069597681275745, 43.57731914771309]],
    [ "Enedis",[1.3815639706218175, 43.583882242704426]],
    [ "Enedis",[1.4013452902052683, 43.58365791800306]],
    [ "Enedis",[1.3687071176365517, 43.57998180772381]],
    [ "Enedis",[1.3698109350113374, 43.57867921223843]],
    [ "Enedis",[1.3865677326997836, 43.58602745467589]],
    [ "Enedis",[1.3883878812419006, 43.582385874515566]],
    [ "Enedis",[1.3864277174440247, 43.57371325710469]],
    [ "Enedis",[1.3931127428071874, 43.57596537023483]],
    [ "Enedis",[1.384352089307869, 43.5849433741758]],
    [ "Enedis",[1.3882970999999995, 43.57837015]],
    [ "Enedis",[1.39015865, 43.584963550000005]],
    [ "Enedis",[1.3927329129737742, 43.580408495810865]],
    [ "Enedis",[1.3842137256566185, 43.581302633926214]],
    [ "Enedis",[1.389798203136872, 43.57524319284402]],
    [ "Enedis",[1.3947970753208812, 43.5867875438455]],
    [ "DOUJAT",[1.3988188291519683, 43.58983167269928]],
    [ "Enedis",[1.3823843962325295, 43.57887279627729]],
    [ "Enedis",[1.3814528881335097, 43.57403035405779]],
    [ "Enedis",[1.3810432653495697, 43.579820381541005]],
    [ "Enedis",[1.4046568999999998, 43.58103850000001]],
    [ "Enedis",[1.39949265, 43.5802638]],
    [ "Enedis",[1.3829648481394572, 43.57498735943333]],
    [ "Enedis",[1.395752443226352, 43.58516304661033]],
    [ "Enedis",[1.3870343763182293, 43.58880432283914]],
    [ "Poste électrique d'Issoire",[3.254790218011281, 45.55486141186437]],
    [ "Enedis",[1.3898449160196837, 43.60912011990754]],
    [ "Enedis",[1.399155237416, 43.617924317879066]],
    [ "BOURIETTE",[1.3734004499999999, 43.606906200000005]],
    [ "Enedis",[1.3619758930895243, 43.60512708774764]],
    [ "Enedis",[1.3871158103187524, 43.599271491322156]],
    [ "POSTE LARDENNE R POINT C18",[1.3993464304627143, 43.59190696772063]],
    [ "Enedis",[1.3972823059691533, 43.616659521142026]],
    [ "Enedis",[1.3964770266143036, 43.61984828760652]],
    [ "Enedis",[1.366874940403673, 43.60922471031511]],
    [ "Enedis",[1.390405580857387, 43.59687800892159]],
    [ "Enedis",[1.3963255500000007, 43.59396695]],
    [ "TOURNEFEUILLE 180",[1.3747545136003454, 43.60304481537963]],
    [ "FLAMBERE 53",[1.3932681617436846, 43.613319151965314]],
    [ "Enedis",[1.3828816000000002, 43.59677359999999]],
    [ "Enedis",[1.3891214841869617, 43.60247413348529]],
    [ "Enedis",[1.3645081608125336, 43.60342773728723]],
    [ "Enedis",[1.3932956, 43.5977722]],
    [ "Enedis",[1.3764406691845854, 43.61023474646758]],
    [ "Enedis",[1.3782735963209027, 43.59596483289988]],
    [ "Enedis",[1.388906299597349, 43.592594529640046]],
    [ "Enedis",[1.3926414, 43.598748300000004]],
    [ "Enedis",[1.3711941332576232, 43.59717729919068]],
    [ "CLOS",[1.3652096121678887, 43.60599764488247]],
    [ "Enedis",[1.3945668652848742, 43.607684806341766]],
    [ "Enedis",[1.3669494000000015, 43.609224700000006]],
    [ "Enedis",[1.4030508486143034, 43.5920757998994]],
    [ "Enedis",[1.3733706694492986, 43.59375499361024]],
    [ "Enedis",[1.3728504594951094, 43.5980730214427]],
    [ "Enedis",[1.3952287367815095, 43.610788744855604]],
    [ "Enedis",[1.3709432, 43.6007751]],
    [ "Enedis",[1.3937173348860052, 43.603508315552745]],
    [ "Enedis",[1.3919310602831496, 43.59377432003452]],
    [ "CAULET",[1.3577797717494546, 43.606292061844435]],
    [ "Enedis",[1.4073697800452178, 43.60776671926865]],
    [ "Enedis",[1.3780949500000008, 43.5937426]],
    [ "Enedis",[1.390477350601925, 43.60779154865968]],
    [ "Enedis",[1.3752195262728741, 43.606749773188106]],
    [ "Enedis",[1.4002868337579917, 43.61433425398353]],
    [ "Enedis",[1.3788048957612236, 43.590832073509254]],
    [ "Enedis",[1.3692718292328212, 43.601652505220564]],
    [ "Enedis",[1.3846665105162335, 43.60830762826409]],
    [ "Enedis",[1.3799277721165943, 43.595216547529034]],
    [ "Enedis",[1.3967142167934208, 43.617921383193014]],
    [ "Enedis",[1.3629384669145796, 43.60318674897116]],
    [ "Enedis",[1.3955070390601734, 43.6096974789492]],
    [ "Enedis",[1.3930948351337604, 43.61379704022368]],
    [ "Enedis",[1.3952402907807255, 43.59243665253325]],
    [ "Enedis",[1.3775289827066153, 43.592129105694184]],
    [ "Enedis",[1.3844340474386294, 43.612454616701974]],
    [ "Enedis",[1.3685011290318985, 43.60328512785289]],
    [ "Enedis",[1.3987278917788712, 43.619696468960925]],
    [ "RTE",[3.5296836774315334, 45.85511676302991]],
    [ "Enedis",[1.378306463579656, 43.59599731524985]],
    [ "Enedis",[-1.4039068996326016, 43.35310586946264]],
    [ "Assantzaborda",[-1.3961773776996975, 43.348326740583985]],
    [ "Enedis",[-1.4098882423903718, 43.36332911866153]],
    [ "Enedis",[1.3929906999999997, 43.607878]],
    [ "Enedis",[1.3591475026254491, 43.6010414298338]],
    [ "Marienia",[-1.3989724259420635, 43.349281205832874]],
    [ "Enedis",[-1.3940656698542802, 43.34985051069558]],
    [ "Enedis",[-1.4113835714433198, 43.357042629081604]],
    [ "Enedis",[-1.39729385, 43.3501494]],
    [ "Enedis",[-1.4099244933486188, 43.3551986196973]],
    [ "Enedis",[-1.4170206100781055, 43.366719714117735]],
    [ "Enedis",[-1.40827798804528, 43.35825799341341]],
    [ "Enedis",[-1.4115476827116038, 43.367312448209276]],
    [ "Etienne Flandrin",[-1.5382638944654743, 47.20358483186259]],
    [ "Enedis",[6.918404856125225, 47.47266768773121]],
    [ "Enedis",[3.8430606940554823, 50.206538967879276]],
    [ "Enedis",[3.836305273600118, 50.20603915803377]],
    [ "Enedis",[3.8387927684412477, 50.208537423635846]],
    [ "Enedis",[0.16112250000000003, 49.48488175]],
    [ "Enedis",[-0.14199739724487312, 46.22736399677781]],
    [ "Enedis",[-0.12993793074255405, 46.23180224644023]],
    [ "Enedis",[-0.1362704428865358, 46.222147071114755]],
    [ "Enedis",[-0.14021362031460882, 46.21205543977968]],
    [ "Enedis",[-0.13943481093113383, 46.233336229072634]],
    [ "Enedis",[-0.1479180925164631, 46.2208338113827]],
    [ "74756608",[-0.14454112472783523, 46.22488111233083]],
    [ "Enedis",[-0.14034723242877487, 46.223759750697994]],
    [ "Enedis",[-0.14112241287234784, 46.230519002132475]],
    [ "Enedis",[-0.13780224911061628, 46.23083210167635]],
    [ "Enedis",[-0.1532407307221861, 46.21802495930526]],
    [ "Enedis",[-0.13742015647428166, 46.23188434954236]],
    [ "Enedis",[-0.14486683513904805, 46.220256538713656]],
    [ "Enedis",[-0.13669959844123863, 46.227532995831545]],
    [ "Enedis",[-0.13765721667097394, 46.2252689811696]],
    [ "Enedis",[-0.135998826444063, 46.23341220953381]],
    [ "Enedis",[-0.1447273181593023, 46.21617128210446]],
    [ "Enedis",[-0.14961171799759065, 46.22337323845317]],
    [ "Enedis",[-0.14273944769765695, 46.22263969667335]],
    [ "Enedis",[-0.15401698913970532, 46.22205332596022]],
    [ "Enedis",[-0.13317127701657183, 46.22431012808853]],
    [ "Enedis",[-0.13416439646353234, 46.23016234368221]],
    [ "Enedis",[-0.14633171545083162, 46.22470048549943]],
    [ "Enedis",[-0.15060132618819722, 46.220439040430506]],
    [ "Enedis",[-0.15293885912501448, 46.2280838888088]],
    [ "Enedis",[-1.48408474283882, 43.516209872395606]],
    [ "Enedis",[-1.4964865605903153, 43.52750306034021]],
    [ "Enedis",[-1.4844160500000008, 43.52816715000001]],
    [ "Enedis",[-1.4846754819214774, 43.51853024863163]],
    [ "Enedis",[-1.4834077506693597, 43.517288098740494]],
    [ "Enedis",[-1.4833279662922627, 43.53738600165045]],
    [ "Enedis",[-1.4768120951808668, 43.52088513093283]],
    [ "Enedis",[-1.478819911447333, 43.52885107908309]],
    [ "Enedis",[-1.4652036396667618, 43.527464123302124]],
    [ "Enedis",[-1.4746316438068707, 43.5265265727688]],
    [ "Enedis",[-1.4890804727858893, 43.527163155960416]],
    [ "Enedis",[-1.4865465781077918, 43.522207574992194]],
    [ "Enedis",[-1.4777325651011077, 43.5342158453245]],
    [ "Enedis",[-1.4845321422390556, 43.525460710356874]],
    [ "Enedis",[-1.4860076207165893, 43.529816325283456]],
    [ "Enedis",[-1.4630239535744916, 43.531667332590644]],
    [ "Enedis",[-1.4794238379329425, 43.516558346901995]],
    [ "Enedis",[-1.4862519910004366, 43.51444844645293]],
    [ "Enedis",[-1.4935963242106571, 43.52471763906568]],
    [ "Enedis",[-1.4805527996684646, 43.51542240741453]],
    [ "Enedis",[-1.4820180972202872, 43.527782268677285]],
    [ "Enedis",[-1.4834600803374385, 43.532739934625475]],
    [ "Enedis",[-1.473135871618583, 43.5180880925866]],
    [ "Enedis",[-1.4807244769101064, 43.53152256819636]],
    [ "Enedis",[-1.4773122271053292, 43.53137799351384]],
    [ "Enedis",[1.349531, 43.55809145]],
    [ "Enedis",[1.3385584112548727, 43.58065943784645]],
    [ "Enedis",[1.3233457612798263, 43.59056844767921]],
    [ "Enedis",[1.3308139294313552, 43.57764229376231]],
    [ "Enedis",[1.343339316976118, 43.58592380283318]],
    [ "Enedis",[1.3387075840522344, 43.57282506272142]],
    [ "Enedis",[1.32567235, 43.5747321]],
    [ "Enedis",[1.3381204500000006, 43.5911737]],
    [ "Enedis",[1.3283098292783866, 43.583026977905305]],
    [ "Enedis",[1.327498645247653, 43.58446705826205]],
    [ "Enedis",[1.3306120853231114, 43.58433137228899]],
    [ "Saint-Pierre Bourde",[1.3168556614875495, 43.58629677268535]],
    [ "Enedis",[1.3493964228409494, 43.58901195069411]],
    [ "Enedis",[1.3167094000000013, 43.579049]],
    [ "Enedis",[1.3458243828496923, 43.57680960252304]],
    [ "Enedis",[1.3134181947178178, 43.587599223841686]],
    [ "Enedis",[1.313890172858709, 43.592973207426944]],
    [ "Enedis",[1.3295895390308352, 43.562657526466054]],
    [ "Enedis",[1.3378261935935671, 43.582982487612455]],
    [ "Enedis",[1.3525987514876427, 43.58858483322909]],
    [ "Enedis",[1.36231145, 43.5837197]],
    [ "Enedis",[1.3447098006121707, 43.591522845514056]],
    [ "Enedis",[1.3653678696999763, 43.589909513347116]],
    [ "Enedis",[1.3123844217650273, 43.59450449567484]],
    [ "Enedis",[1.3397190712859774, 43.58772190037896]],
    [ "Enedis",[1.3016311273772472, 43.58119279496759]],
    [ "Enedis",[1.3513055500000002, 43.56095265]],
    [ "Enedis",[1.3196131863462846, 43.59368770844023]],
    [ "Enedis",[1.364274071009281, 43.575838026490196]],
    [ "Enedis",[1.3685364689379595, 43.58902041625947]],
    [ "Enedis",[1.3059841994576589, 43.58283293530404]],
    [ "Enedis",[1.3402362624387398, 43.58913835227891]],
    [ "Enedis",[1.3343558921986904, 43.572645761140734]],
    [ "Enedis",[1.3306933639819383, 43.582284046736824]],
    [ "Enedis",[3.840852554849333, 50.20237298102122]],
    [ "Enedis",[1.3707749050365376, 43.588993827037655]],
    [ "Enedis",[1.3252266643399824, 43.58441592225404]],
    [ "Enedis",[1.3179691168998522, 43.57780780048744]],
    [ "Enedis",[1.3559645752445546, 43.55779705042371]],
    [ "Enedis",[1.329814258725397, 43.5658488128062]],
    [ "Enedis",[1.3628094460258784, 43.59122383093038]],
    [ "Enedis",[1.3418024895991925, 43.58421838562684]],
    [ "Enedis",[1.3313979333982704, 43.579576897782864]],
    [ "Enedis",[1.3093057850356615, 43.577427956735306]],
    [ "Enedis",[1.313874988655882, 43.578841430657086]],
    [ "Enedis",[1.332980163129905, 43.56712905046019]],
    [ "Enedis",[1.3302694155833616, 43.57009845556044]],
    [ "COTEAUX",[1.3417681739062874, 43.5916754767272]],
    [ "Enedis",[1.3597092460177287, 43.56392597604196]],
    [ "Enedis",[1.3153367999999999, 43.59265085]],
    [ "Enedis",[1.3469747172341016, 43.56440928262107]],
    [ "Enedis",[1.312712083914645, 43.58057740295679]],
    [ "Enedis",[1.3100824500000003, 43.592504500000004]],
    [ "Enedis",[1.3537402502238916, 43.59030660148756]],
    [ "Enedis",[1.344748858387478, 43.5744857966333]],
    [ "Enedis",[1.3228129569441036, 43.58182688162835]],
    [ "Enedis",[1.3250607500000002, 43.5888402]],
    [ "Enedis",[1.2959703978606592, 43.58164164172962]],
    [ "Enedis",[1.3289466537832413, 43.59130316352567]],
    [ "Enedis",[1.3119766992635489, 43.59070955146936]],
    [ "Enedis",[1.3143906165184311, 43.57404009615444]],
    [ "Enedis",[1.318448152234572, 43.590792395922335]],
    [ "Enedis",[1.3438765039513147, 43.5721475504327]],
    [ "Enedis",[1.3408146989187848, 43.57841238321657]],
    [ "Enedis",[1.3330340233653302, 43.57051651488655]],
    [ "Enedis",[-1.8859914499999992, 46.82614945000001]],
    [ "Enedis",[1.3390104524533577, 43.577491016012054]],
    [ "Enedis",[1.3481154325144526, 43.5756127473548]],
    [ "St Nicolas 3",[1.3194893999999997, 43.575940249999995]],
    [ "Enedis",[1.344378550527975, 43.58842500113656]],
    [ "Enedis",[1.322884216874289, 43.57917930098032]],
    [ "La Ramée 1",[1.3361171, 43.56694540000001]],
    [ "Enedis",[1.32343255, 43.59302295]],
    [ "Enedis",[1.3371976493522368, 43.58912523833295]],
    [ "Enedis",[1.3272090500000002, 43.58943815]],
    [ "Enedis",[1.3731616499999995, 43.58599045]],
    [ "Enedis",[1.3514342500000003, 43.586513800000006]],
    [ "Enedis",[1.3281193351704244, 43.577250023767505]],
    [ "Enedis",[1.3200855141010763, 43.580412792552764]],
    [ "Enedis",[1.351737647461329, 43.5901475595893]],
    [ "Enedis",[1.35924960635175, 43.58701066823123]],
    [ "Enedis",[1.350151238493715, 43.587308968722084]],
    [ "Enedis",[1.3515960549627144, 43.581957379031444]],
    [ "Enedis",[1.3359117965516096, 43.561476967145055]],
    [ "Enedis",[1.3275719335903575, 43.580947250561174]],
    [ "Enedis",[1.3577143952245863, 43.561016823741774]],
    [ "Enedis",[1.3228931999999995, 43.576650699999995]],
    [ "Enedis",[1.3204279950263238, 43.58928422817532]],
    [ "Enedis",[1.3270980129123804, 43.579880088636195]],
    [ "Enedis",[1.3341235955240363, 43.583607936289326]],
    [ "Enedis",[1.3532841071344792, 43.57814814805786]],
    [ "BAYLAC",[1.348170086545185, 43.582651100452566]],
    [ "Enedis",[1.3522060520982535, 43.558247267507525]],
    [ "Enedis",[1.3588353999999998, 43.57901305]],
    [ "Enedis",[1.3558364923255812, 43.5666804121473]],
    [ "Enedis",[1.3506883329759567, 43.59152934347348]],
    [ "Enedis",[1.34980390959618, 43.57671280639469]],
    [ "Enedis",[1.3399287095000747, 43.57103546782887]],
    [ "Enedis",[1.3355057400993326, 43.58726496863843]],
    [ "Enedis",[1.34606065, 43.5899695]],
    [ "Enedis",[1.3085117466683067, 43.590904815572394]],
    [ "COTES DU TOUCH",[1.3658060137923234, 43.58418060524274]],
    [ "Enedis",[1.349971360163824, 43.58574094570122]],
    [ "Enedis",[1.3281718592154692, 43.57560961703113]],
    [ "Enedis",[1.3475233041455954, 43.57690231947682]],
    [ "Enedis",[1.3304451422507957, 43.57148902175477]],
    [ "Enedis",[1.3405677999999999, 43.5855923]],
    [ "Enedis",[1.2961730945413104, 43.579732255746514]],
    [ "Enedis",[1.3716132980155653, 43.589695670062454]],
    [ "Enedis",[1.3545699518241054, 43.563410276880674]],
    [ "Enedis",[1.3652053212632973, 43.58621195494114]],
    [ "Enedis",[1.3412197423864727, 43.559825230097275]],
    [ "Enedis",[1.356793879517508, 43.5856589416066]],
    [ "Enedis",[1.3198784, 43.586892250000005]],
    [ "Enedis",[1.3514687859071697, 43.578131025996434]],
    [ "Enedis",[1.363650897322404, 43.584785138829545]],
    [ "Enedis",[1.3684481802761186, 43.582085295669714]],
    [ "Enedis",[1.3326189123812875, 43.5773463875894]],
    [ "Enedis",[1.3550100950868518, 43.581592216837194]],
    [ "Enedis",[1.3256176, 43.5668365]],
    [ "Enedis",[1.3442721175922188, 43.57552713729787]],
    [ "Enedis",[1.3377051500845245, 43.57914475191809]],
    [ "Enedis",[1.3354379740118367, 43.581526224357965]],
    [ "Enedis",[1.33422929265821, 43.59190191005072]],
    [ "Enedis",[1.3367353611341002, 43.58541512052037]],
    [ "Enedis",[1.3600800291463573, 43.58885793379827]],
    [ "Enedis",[-1.867651900075426, 46.82833981666421]],
    [ "Enedis",[1.3209186170145546, 43.57850224093661]],
    [ "Enedis",[1.346934010936313, 43.56059468457126]],
    [ "Enedis",[-1.873038833296457, 46.8266610430857]],
    [ "Enedis",[1.3195434675385818, 43.59167040529945]],
    [ "Enedis",[1.3375429976683684, 43.58792175397426]],
    [ "Enedis",[1.3263848597837433, 43.59371740761348]],
    [ "Gayets",[6.589792133010362, 44.93241735150826]],
    [ "Eyrette",[6.590023105645967, 44.935453934153486]],
    [ "Enedis",[1.3215828383462953, 43.623606800648325]],
    [ "Enedis",[0.1375885875395537, 49.48627277429444]],
    [ "Enedis",[0.13917385000000088, 49.48548075]],
    [ "Enedis",[1.3194985108712913, 43.61999812851811]],
    [ "Enedis",[1.316679454168117, 43.617323944203676]],
    [ "Enedis",[1.3187751379551647, 43.61824497223735]],
    [ "Enedis",[1.3166049776993798, 43.61987910271396]],
    [ "Enedis",[3.802186685365393, 48.042471849118705]],
    [ "Enedis",[3.7943764741176866, 48.04038105449457]],
    [ "Enedis",[1.0011932763877815, 47.85435298616551]],
    [ "Enedis",[1.3342372781170617, 43.61973189662809]],
    [ "Enedis",[1.3250212783414792, 43.62128462070955]],
    [ "Enedis",[1.332945161560777, 43.61937598194566]],
    [ "Enedis",[1.3229173986667637, 43.61935899999637]],
    [ "Enedis",[1.3308058059734518, 43.622273799999995]],
    [ "Enedis",[1.3251911254091957, 43.61940004247153]],
    [ "Enedis",[1.3332891678225702, 43.61810170845696]],
    [ "Enedis",[1.332380526472017, 43.6215334953484]],
    [ "Enedis",[1.3310717871989466, 43.61951151486667]],
    [ "Enedis",[1.3221324537665011, 43.62159294758518]],
    [ "Enedis",[0.13873190000000002, 49.489520899999995]],
    [ "Enedis",[1.331117117348289, 43.625544589435634]],
    [ "Enedis",[0.13637535000000003, 49.49005305]],
    [ "Enedis",[0.13328295975466803, 49.49164857345002]],
    [ "Enedis",[1.3210580666194947, 43.61459568022289]],
    [ "Enedis",[1.32293215, 43.616184200000006]],
    [ "Enedis",[1.3274308292499508, 43.61434149326367]],
    [ "Enedis",[1.3318050293986534, 43.61534149244287]],
    [ "Enedis",[3.1162932419386142, 50.6405288752081]],
    [ "Enedis",[3.11038257556842, 50.63921261355505]],
    [ "Enedis",[3.1116282208266983, 50.63841884215616]],
    [ "Enedis",[3.1106917707468207, 50.63716217805766]],
    [ "Enedis",[3.1062877420548505, 50.63882104942866]],
    [ "Enedis",[3.1025311383103724, 50.63957641993657]],
    [ "Enedis",[3.111332563202463, 50.63563674964661]],
    [ "Enedis",[1.3253663635157729, 43.61293802046225]],
    [ "Enedis",[1.3236287192495504, 43.61124487861225]],
    [ "Enedis",[3.9177627089422704, 47.49789818883617]],
    [ "Enedis",[3.91383418834017, 47.48743660290113]],
    [ "Enedis",[3.0999676936125216, 50.63764573217963]],
    [ "Enedis",[3.115023320383306, 50.63933320918841]],
    [ "Enedis",[3.1032309074172395, 50.64199023511864]],
    [ "Enedis",[3.921249319584027, 47.48719886542212]],
    [ "Enedis",[3.1123637962785065, 50.640266500834684]],
    [ "Enedis",[3.1015043228018513, 50.635625787092515]],
    [ "Enedis",[3.10525923825377, 50.639504919854915]],
    [ "Enedis",[3.9093804112758064, 47.50742014681208]],
    [ "Enedis",[3.9087674083454518, 47.5003556315379]],
    [ "Enedis",[3.914272652236394, 47.49640050203343]],
    [ "Enedis",[3.9163081229665986, 47.495034810958494]],
    [ "Enedis",[3.90668765, 47.49459715]],
    [ "Enedis",[3.9185542015099126, 47.49804782296898]],
    [ "Enedis",[3.9047392, 47.4968207]],
    [ "Enedis",[3.9125764531727376, 47.49869506774553]],
    [ "Enedis",[1.3252565982683824, 43.60917746991964]],
    [ "Enedis",[5.37550545, 45.56659765]],
    [ "Enedis",[3.0951115323969303, 50.64276014520651]],
    [ "Enedis",[3.1048150697603436, 50.64745560799401]],
    [ "Enedis",[3.10216977856573, 50.64672781694584]],
    [ "Enedis",[3.106186060570641, 50.64702797958648]],
    [ "Enedis",[3.1023679923404655, 50.64423406760066]],
    [ "Enedis",[3.1068152249708003, 50.64298880665712]],
    [ "Enedis",[3.820782455564089, 50.20538297301701]],
    [ "Enedis",[3.8086798368252484, 50.204958869825056]],
    [ "Enedis",[3.813554882194772, 50.205164382602064]],
    [ "Enedis",[3.113423541063663, 50.646570523072654]],
    [ "Enedis",[3.1152418757139233, 50.644146807884304]],
    [ "Enedis",[-1.4556733292339559, 43.3937372829836]],
    [ "Enedis",[-1.4495819757914932, 43.392041600497755]],
    [ "Enedis",[-1.4530630795679171, 43.39506384841983]],
    [ "Enedis",[-1.4540712760811458, 43.39259316641108]],
    [ "Enedis",[3.08206530138889, 48.08284408252925]],
    [ "Enedis",[-1.4567720433418392, 43.38744433057367]],
    [ "Enedis",[1.4690136935880975, 43.58105427534505]],
    [ "Enedis",[1.3379397, 43.61445695000001]],
    [ "Enedis",[1.3367555535972162, 43.61443837533066]],
    [ "Enedis",[1.3355672485578525, 43.61553839942801]],
    [ "Enedis",[1.3377428342040483, 43.617981117246]],
    [ "Enedis",[1.3363968411928762, 43.617557604213765]],
    [ "Enedis",[0.7304392282793993, 47.38082814082711]],
    [ "Enedis",[0.7374034180267653, 47.39665618157267]],
    [ "Enedis",[0.7147584131333422, 47.39355386184992]],
    [ "Enedis",[0.7302732461398378, 47.3826184822639]],
    [ "Enedis",[0.7270625380117651, 47.380924959803494]],
    [ "Enedis",[0.7106536958856285, 47.39385449041873]],
    [ "Enedis",[0.7401751669132642, 47.382131891321464]],
    [ "Enedis",[0.7146925470381174, 47.38756034422623]],
    [ "Enedis",[0.7229056241300428, 47.396650862456106]],
    [ "Enedis",[0.74901535, 47.40162904999999]],
    [ "Enedis",[0.743811830500545, 47.40039449657726]],
    [ "Enedis",[0.7242184981486799, 47.39122776067297]],
    [ "Enedis",[0.7221141243232051, 47.39238994620024]],
    [ "Enedis",[0.7227184065414719, 47.389506074987246]],
    [ "Enedis",[0.7260152471981322, 47.391036783787854]],
    [ "Enedis",[0.7498413226816857, 47.39794205256105]],
    [ "Enedis",[0.7427704000000007, 47.391088350000004]],
    [ "Enedis",[0.7110385669679322, 47.3967044617405]],
    [ "Enedis",[0.7607499766567754, 47.3858836149357]],
    [ "Enedis",[0.7307727364436251, 47.396598328497056]],
    [ "Enedis",[0.7126703756322763, 47.39213960256924]],
    [ "Enedis",[0.7365091043705153, 47.3937475613829]],
    [ "Enedis",[0.7428512478656553, 47.39713204548212]],
    [ "Enedis",[0.7283731585144361, 47.389618293618085]],
    [ "Enedis",[0.7513220319668739, 47.3937169821823]],
    [ "Enedis",[0.7180423195010023, 47.37860947763306]],
    [ "Enedis",[0.7201992312484145, 47.38679130204411]],
    [ "Enedis",[2.1394121222434266, 50.514972784152825]],
    [ "COMMINGE",[1.328414881964644, 43.608119136686305]],
    [ "Enedis",[0.7568163775101321, 47.38630585661577]],
    [ "Enedis",[0.755934053180022, 47.38589669440097]],
    [ "Enedis",[2.1303240008467625, 50.51310740145782]],
    [ "Enedis",[2.1369038980447255, 50.51256679589501]],
    [ "Enedis",[2.1280783391896922, 50.50543367391266]],
    [ "Enedis",[2.145307686055143, 50.51682498223932]],
    [ "Enedis",[2.13379753724581, 50.512855629349]],
    [ "Enedis",[2.1435905503147734, 50.516720467526504]],
    [ "Enedis",[2.1172068777604864, 50.5111506952512]],
    [ "Enedis",[2.135812429699928, 50.51711531626536]],
    [ "Enedis",[0.28914960000000045, 48.01096805]],
    [ "Enedis",[0.2683973167065721, 48.01306042933687]],
    [ "Enedis",[0.27995084523403413, 48.01924908279326]],
    [ "Bener",[0.2407627542093873, 48.000510386768774]],
    [ "Les Pêcheurs",[0.24127597141266466, 47.9976167035522]],
    [ "Les Logis",[0.24458563587206092, 47.999048677593095]],
    [ "La Grappe",[0.2524066784487068, 48.00380651639172]],
    [ "Enedis",[0.2686536346928984, 48.0149732852392]],
    [ "Enedis",[0.30639996071558784, 48.0484938321014]],
    [ "Le Pont",[0.27411409999999997, 48.018102150000004]],
    [ "Le Ruisseau",[0.24539104999999928, 48.0012651]],
    [ "Enedis",[0.2640342168914822, 48.015667190391085]],
    [ "Enedis",[0.28578427268354134, 48.01117285658921]],
    [ "Enedis",[0.2599448803332808, 48.02588743692922]],
    [ "Les Bergères",[0.26537160161485035, 48.013681974197254]],
    [ "Enedis",[1.3429750557455007, 43.609634262311005]],
    [ "Enedis",[1.3433621335246155, 43.610258195288885]],
    [ "Enedis",[1.341386240997444, 43.60989610970434]],
    [ "Enedis",[1.4664861754397185, 43.58376693426927]],
    [ "Enedis",[0.086017175198328, 49.52023314447516]],
    [ "Enedis",[0.07878994994004952, 49.52062855643229]],
    [ "Enedis",[0.07536219238317807, 49.521993713064134]],
    [ "Enedis",[1.345588132441302, 43.60581549817716]],
    [ "Enedis",[1.3413039411872334, 43.608028065226755]],
    [ "Enedis",[1.3471435382748895, 43.60795857847951]],
    [ "Enedis",[1.3506246742057746, 43.608314853582684]],
    [ "Enedis",[0.08402725, 49.51923435000001]],
    [ "Enedis",[0.07947717547134, 49.51863949776011]],
    [ "Enedis",[0.08484196489313325, 49.5179443295471]],
    [ "Enedis",[1.340119370158007, 43.61324436206159]],
    [ "Enedis",[1.342271750000001, 43.6142673]],
    [ "Enedis",[1.35202295, 43.61197295]],
    [ "Enedis",[1.3454028999999998, 43.6151716]],
    [ "Enedis",[1.3463064000000005, 43.61303265]],
    [ "Enedis",[1.3464504574273832, 43.616198048449114]],
    [ "Enedis",[1.346927508406496, 43.61804144467976]],
    [ "Enedis",[1.3431375325101276, 43.622527964551374]],
    [ "Enedis",[1.3441964026441022, 43.61874889864928]],
    [ "Enedis",[1.340684461253213, 43.61620297892207]],
    [ "Enedis",[1.3435666327068339, 43.61650958888574]],
    [ "Enedis",[1.341814708618634, 43.61847206874467]],
    [ "Enedis",[1.3494334315687495, 43.62031105944538]],
    [ "Enedis",[1.3434404322598479, 43.61686530091158]],
    [ "Enedis",[1.3455845017268477, 43.622077934336026]],
    [ "Enedis",[1.5277406028530265, 43.55408202300196]],
    [ "Enedis",[1.5307849326098621, 43.56952533393953]],
    [ "Enedis",[1.519776277038845, 43.553914656579224]],
    [ "Enedis",[1.5270397613729088, 43.56459598799027]],
    [ "Enedis",[1.531427957781091, 43.555820749776395]],
    [ "Enedis",[1.5362467513202964, 43.55168967996791]],
    [ "Enedis",[1.5337848320964749, 43.557277709482584]],
    [ "Enedis",[1.5470077, 43.540547950000004]],
    [ "Enedis",[1.5402977088042178, 43.552937364759515]],
    [ "Enedis",[1.5157737000000002, 43.55846130000001]],
    [ "Enedis",[1.5248214690506063, 43.55614524926809]],
    [ "Enedis",[1.529048047059919, 43.563179641764926]],
    [ "Enedis",[1.508744715618396, 43.55924570231841]],
    [ "Enedis",[1.5521819567292812, 43.53409181295718]],
    [ "Enedis",[1.5458769221910038, 43.55265293766478]],
    [ "Enedis",[1.5177479000000003, 43.554621749999995]],
    [ "Enedis",[1.5469344852743023, 43.554236516085254]],
    [ "Enedis",[1.3365778897708158, 43.62113664855323]],
    [ "Enedis",[1.5197881325273255, 43.550367375384845]],
    [ "Enedis",[1.3446582342914326, 43.62987001769497]],
    [ "Enedis",[1.33468865, 43.62240325]],
    [ "Enedis",[1.5444672458638997, 43.54213716346466]],
    [ "Enedis",[1.5376990827338908, 43.54442283954562]],
    [ "Enedis",[1.5210551480150925, 43.55202146607991]],
    [ "Enedis",[1.5175785021276134, 43.55256004614236]],
    [ "HLM Catala 1",[1.518504223154545, 43.570077754784826]],
    [ "Enedis",[1.5170344162404428, 43.56133330156867]],
    [ "Enedis",[1.5466514672422351, 43.55539537602134]],
    [ "Enedis",[1.54176255, 43.5566904]],
    [ "Enedis",[1.5187189142315445, 43.551113260484634]],
    [ "Enedis",[1.53436445, 43.5472062]],
    [ "Enedis",[1.5221340140983604, 43.556323304396116]],
    [ "Enedis",[1.5218521097106927, 43.55353236035136]],
    [ "Enedis",[1.5154970385473656, 43.56379812451253]],
    [ "Enedis",[1.5331148294615635, 43.55396567934021]],
    [ "Enedis",[1.5200065315691045, 43.5602791383968]],
    [ "Enedis",[1.547776856993838, 43.539535171025406]],
    [ "Enedis",[1.5282500011020939, 43.549775867052404]],
    [ "Enedis",[1.5256474812223446, 43.56151459444045]],
    [ "Enedis",[1.5360569750785646, 43.55278555404016]],
    [ "Enedis",[1.5206287752097725, 43.565604159866815]],
    [ "Enedis",[1.5209509914330452, 43.56258989633755]],
    [ "Enedis",[1.5423686351718549, 43.540801819351636]],
    [ "Enedis",[-1.8702404240033037, 46.82275329025064]],
    [ "Enedis",[1.5245567365800727, 43.56298227715359]],
    [ "Enedis",[1.5299690694074817, 43.55510449679445]],
    [ "Enedis",[1.5364525877717294, 43.555380124794496]],
    [ "Enedis",[1.5296885414037715, 43.55755254836559]],
    [ "Enedis",[1.5316836872580157, 43.549821826878784]],
    [ "HAMEAU DE FIRMIS",[1.5389577500000002, 43.54911475000001]],
    [ "Village",[1.5328724467017374, 43.55494376615284]],
    [ "Enedis",[1.545239092180586, 43.54000384222884]],
    [ "Les Arcades",[1.5362709082632247, 43.549393955827405]],
    [ "Enedis",[1.5339138224462117, 43.55344155271604]],
    [ "Enedis",[1.5422661399179285, 43.54192509080232]],
    [ "Enedis",[1.5275869853303916, 43.55790400093531]],
    [ "Lot Catala 3",[1.5193703000655103, 43.57241726666097]],
    [ "Enedis",[1.541821833183857, 43.550714615844534]],
    [ "Enedis",[1.5191051031517935, 43.561756235829236]],
    [ "Partanays",[1.5287020944471261, 43.56135855634958]],
    [ "Enedis",[1.518921520490261, 43.55813551968624]],
    [ "Enedis",[1.518362150000001, 43.5567048]],
    [ "Enedis",[1.5312998929991102, 43.55263432640894]],
    [ "Enedis",[1.54559845, 43.5377972]],
    [ "Enedis",[1.5157528831423148, 43.57200926083997]],
    [ "Enedis",[1.5439398650201586, 43.55381557537722]],
    [ "Enedis",[1.5419971512106911, 43.55151496652278]],
    [ "Enedis",[1.541069578132469, 43.5415143716826]],
    [ "Enedis",[1.5119291264461763, 43.55795710403161]],
    [ "Enedis",[1.5137620965268594, 43.56282000017709]],
    [ "Enedis",[3.883257112427622, 43.60642707309264]],
    [ "Enedis",[3.8015517999999995, 50.209]],
    [ "Enedis",[2.8295888682200685, 42.77682589414069]],
    [ "Enedis",[2.7982547970553995, 42.77970635704974]],
    [ "Enedis",[1.3066783530711086, 43.61886746315091]],
    [ "Enedis",[2.8388488416702637, 42.777352087283745]],
    [ "Enedis",[2.8157876103266397, 42.777686671964105]],
    [ "Enedis",[1.2979000978413386, 43.61733571104997]],
    [ "Enedis",[1.3033385314657608, 43.61773684025402]],
    [ "Enedis",[2.833755142564162, 42.780174684333886]],
    [ "Enedis",[2.838235012800594, 42.77288061851159]],
    [ "Enedis",[1.3105743183413368, 43.61738759261845]],
    [ "Enedis",[1.3079465856585355, 43.62122630106272]],
    [ "Enedis",[1.3038825000000005, 43.62244655000001]],
    [ "Enedis",[1.2985239674058258, 43.61499605285997]],
    [ "Enedis",[1.3154186128361325, 43.614712665719956]],
    [ "Enedis",[1.3184463998812468, 43.61344086081235]],
    [ "Enedis",[1.3136802009241986, 43.60735494505615]],
    [ "Enedis",[1.318481166491711, 43.60916883322962]],
    [ "Enedis",[1.3197000263690355, 43.608067240575835]],
    [ "Enedis",[1.3106472587369455, 43.61076538114338]],
    [ "Enedis",[1.3058089178591923, 43.61014231540468]],
    [ "Enedis",[1.306490412685226, 43.61233458703491]],
    [ "Enedis",[1.3110613655028462, 43.60894074027324]],
    [ "Enedis",[1.295309367301209, 43.60668349266309]],
    [ "Enedis",[1.309439424132999, 43.611027955390554]],
    [ "Enedis",[1.3016262451014933, 43.608099004378175]],
    [ "Enedis",[1.4793381279964848, 47.872027325979495]],
    [ "Enedis",[1.4993533240119066, 47.87574220450228]],
    [ "Enedis",[1.317275912375763, 43.604925919684675]],
    [ "Enedis",[1.305977556574504, 43.599309201901185]],
    [ "Enedis",[1.3182351468167062, 43.606041961106854]],
    [ "Enedis",[1.2977211232774717, 43.595889620305805]],
    [ "Enedis",[1.312556317860036, 43.60476132237571]],
    [ "Enedis",[1.3206390502141512, 43.605649703369195]],
    [ "Enedis",[1.3005888973790176, 43.59654970006588]],
    [ "Enedis",[1.302684391521642, 43.59920694201249]],
    [ "P 785 BAYON NASQUE EI",[1.3083598078534688, 43.600426554071724]],
    [ "Enedis",[1.3109293274941185, 43.598447135126136]],
    [ "Enedis",[1.3022855439877619, 43.5970167172057]],
    [ "Enedis",[1.3057581667277234, 43.60357557399002]],
    [ "Enedis",[1.3224180606837606, 43.60607937919622]],
    [ "Enedis",[1.2992948000000002, 43.59823695]],
    [ "Enedis",[1.3047357273224796, 43.600920242101175]],
    [ "Enedis",[1.3194284274067674, 43.605899864209576]],
    [ "Enedis",[1.3113087781622785, 43.60266274132359]],
    [ "Enedis",[1.3140879463556883, 43.60157301776498]],
    [ "Enedis",[1.314938649999999, 43.60161585]],
    [ "LOURDON",[1.318014493155608, 43.60297953248649]],
    [ "Enedis",[1.3190733498450737, 43.59544628329543]],
    [ "Enedis",[1.3373213007551359, 43.59995455097486]],
    [ "Enedis",[1.3271564653869534, 43.6037071022641]],
    [ "Enedis",[1.3184521986173698, 43.598216427741505]],
    [ "Enedis",[1.3269461889365934, 43.600972805819914]],
    [ "Enedis",[1.3229800766897306, 43.602117285703685]],
    [ "Enedis",[1.3330195846246184, 43.60198519077654]],
    [ "Enedis",[1.3301734663536866, 43.59716666718528]],
    [ "Enedis",[1.3381764198262451, 43.5969917663826]],
    [ "Enedis",[1.3306443642967176, 43.60086108509302]],
    [ "Enedis",[1.3345055717661596, 43.60509389434236]],
    [ "Avenue des Marots",[1.3223927852306354, 43.59927643155847]],
    [ "Enedis",[1.335635744413629, 43.604866404415276]],
    [ "Enedis",[1.3252339, 43.60249735]],
    [ "Enedis",[1.3277450361802894, 43.60621431342706]],
    [ "Enedis",[1.331335051406656, 43.605239251452566]],
    [ "Enedis",[1.334865376670461, 43.595172788182644]],
    [ "Enedis",[1.3367881084322364, 43.60174957064042]],
    [ "Enedis",[1.3352899103883562, 43.60157291021116]],
    [ "Enedis",[1.3314178266086394, 43.599606075036824]],
    [ "Enedis",[1.3379588134277902, 43.60368692293731]],
    [ "Neste 1",[1.3240493097372978, 43.601361277926166]],
    [ "Enedis",[0.08700988138128729, 49.51601109697533]],
    [ "Enedis",[3.0392026369171647, 50.68314645111593]],
    [ "Geredis",[-0.46131323987158873, 46.984513255454274]],
    [ "Enedis",[3.8080579643983765, 50.20137095770086]],
    [ "INGRES",[1.3506554473223984, 43.596958885049936]],
    [ "Enedis",[1.3559931836891843, 43.59068470107449]],
    [ "Enedis",[1.3411165636282636, 43.599744001329945]],
    [ "Enedis",[1.3506549169549378, 43.60148400506354]],
    [ "Enedis",[1.3408025480821415, 43.5964654212652]],
    [ "Enedis",[1.351845900000001, 43.59419655]],
    [ "Enedis",[1.3572495, 43.5964277]],
    [ "Enedis",[1.3531536615096162, 43.59484216494577]],
    [ "Enedis",[1.3596198, 43.59470485]],
    [ "Enedis",[1.365518351316961, 43.595591959864784]],
    [ "Enedis",[1.3451755834431383, 43.59271418714648]],
    [ "Enedis",[1.3428712370783902, 43.5945437445685]],
    [ "Enedis",[3.8095267750580595, 50.20014819088629]],
    [ "Enedis",[3.810459075287723, 50.20258254406129]],
    [ "Enedis",[1.3455929434605434, 43.604458675454175]],
    [ "Enedis",[1.3432066537979328, 43.60289583156641]],
    [ "Enedis",[1.3494689899540344, 43.60561121169326]],
    [ "Enedis",[1.3479369765737554, 43.60312420848607]],
    [ "Enedis",[1.3409184545184243, 43.60389275873281]],
    [ "Enedis",[1.3511641376147128, 43.60636279574629]],
    [ "Enedis",[-2.006187232807443, 46.813393601132695]],
    [ "Enedis",[-1.99185249683341, 46.82143356471218]],
    [ "Ed Branly",[2.1554158745206347, 43.9350635259214]],
    [ "Enedis",[2.189137603148584, 43.914241441398865]],
    [ "Pioulet",[2.151843698207284, 43.934091216632766]],
    [ "Enedis",[2.134780339071554, 43.92313517214993]],
    [ "Enedis",[2.135332517022265, 43.91340006538387]],
    [ "Enedis",[2.183765474705529, 43.930756605560326]],
    [ "Enedis",[3.8929284851040675, 43.60334296045296]],
    [ "Enedis",[3.043830539719459, 50.68329622832415]],
    [ "Enedis",[3.8899954458461474, 43.61343794656982]],
    [ "Enedis",[3.8919844377440898, 43.61286756400689]],
    [ "Enedis",[1.5104903803209495, 43.69106107987311]],
    [ "Enedis",[1.5171687851267603, 43.69483981680751]],
    [ "Enedis",[1.5250045, 43.687223700000004]],
    [ "Enedis",[0.7374703986030173, 47.35038671826029]],
    [ "Enedis",[0.7451229969766904, 47.36120188863758]],
    [ "Enedis",[0.7518438327008441, 47.363101716388584]],
    [ "Enedis",[0.7414299714255029, 47.350447539318374]],
    [ "Enedis",[0.7525203632516773, 47.357992991478824]],
    [ "Enedis",[0.7318704136673583, 47.366129623126746]],
    [ "Enedis",[0.7377778862263873, 47.35744613418674]],
    [ "Enedis",[0.7232202331515012, 47.36763103465258]],
    [ "Enedis",[0.7206191740819966, 47.369390066181815]],
    [ "Enedis",[0.7152216149545486, 47.367394176111254]],
    [ "Enedis",[0.7576618663131789, 47.35940915514409]],
    [ "Enedis",[0.7369861199500701, 47.36226778192728]],
    [ "Enedis",[0.7243883030827607, 47.36222159451261]],
    [ "Enedis",[0.7315553842909857, 47.35708349156397]],
    [ "Enedis",[-1.9914207698102437, 46.873441459284024]],
    [ "Enedis",[0.7531426859525899, 47.364656321510104]],
    [ "Enedis",[0.7433508881593206, 47.35340492138129]],
    [ "Enedis",[0.7388121465353724, 47.34827965051099]],
    [ "Enedis",[0.7177170011236873, 47.37147504841625]],
    [ "Enedis",[0.7431653833905378, 47.36168410965745]],
    [ "Enedis",[0.7339862506911611, 47.36434079356029]],
    [ "Enedis",[0.7187137363856676, 47.361948578316394]],
    [ "Enedis",[0.7386983999999999, 47.365252]],
    [ "Enedis",[0.746136327279306, 47.35925914108713]],
    [ "Aragon",[0.7395362407717594, 47.36035196367771]],
    [ "Enedis",[0.7273278097741115, 47.35675973307177]],
    [ "Enedis",[0.744739632612604, 47.34590648154842]],
    [ "Enedis",[0.7100915697116245, 47.35698053652439]],
    [ "Enedis",[0.7244340947449113, 47.3679783377727]],
    [ "Enedis",[0.74655193028578, 47.36663807989219]],
    [ "Enedis",[0.7122693499999994, 47.35577165]],
    [ "Enedis",[0.7193528474816021, 47.367292298336565]],
    [ "Enedis",[0.73588073091749, 47.352931709566434]],
    [ "Enedis",[0.7397570298588443, 47.345624364100544]],
    [ "Enedis",[0.7351928668478884, 47.34513551603747]],
    [ "Enedis",[0.7323184239233983, 47.36913421240474]],
    [ "Enedis",[0.7187833851974291, 47.35988645235803]],
    [ "Enedis",[0.744568507702628, 47.3565733764854]],
    [ "Enedis",[0.7061954619921416, 47.35181340419869]],
    [ "Pau Valer",[0.740836342004505, 47.36124030961054]],
    [ "Enedis",[0.71672435, 47.3706116]],
    [ "Enedis",[0.722656185999642, 47.35940172205073]],
    [ "Enedis",[0.7510855485970264, 47.35603648114028]],
    [ "Enedis",[0.7424913358221503, 47.364188351974136]],
    [ "Enedis",[0.7482693949564875, 47.35411277179588]],
    [ "Enedis",[0.7094802310850404, 47.35504389027472]],
    [ "Enedis",[0.724089666322105, 47.36999740141599]],
    [ "Enedis",[0.7324043783062478, 47.343193535058965]],
    [ "Enedis",[0.7220486000000007, 47.3539121]],
    [ "Enedis",[0.7346272108423821, 47.358247849002204]],
    [ "Enedis",[0.75891875, 47.365306200000006]],
    [ "Enedis",[0.7314090325743736, 47.36794548693291]],
    [ "Enedis",[0.7321627316942825, 47.34648994855229]],
    [ "Enedis",[0.7535492444450089, 47.36604389659952]],
    [ "Enedis",[0.7560148213740029, 47.369264472251295]],
    [ "Enedis",[-1.9369168500000002, 46.868145950000006]],
    [ "Enedis",[-1.923855740918271, 46.88455622176736]],
    [ "Enedis",[-1.9401471000000006, 46.8688659]],
    [ "Enedis",[0.1609944441903034, 49.49194953490353]],
    [ "Enedis",[0.1523905972794631, 49.49392250684881]],
    [ "Enedis",[0.15890478872397934, 49.49749928077578]],
    [ "Gérédis",[-0.4385809899409718, 46.98826701916825]],
    [ "Gérédis",[-0.1875411498766144, 47.05269883335256]],
    [ "75415378",[-0.20636297089834144, 46.259453918224295]],
    [ "Enedis",[-0.09936842305320179, 46.10932782107319]],
    [ "Enedis",[-0.0973631514237597, 46.1095568905814]],
    [ "75432223",[-0.06928165996199077, 46.10773093792876]],
    [ "Enedis",[0.7703197778202853, 47.34965725306065]],
    [ "Enedis",[0.18567659715291432, 49.49802222659132]],
    [ "Enedis",[0.18500178234841144, 49.49776964543184]],
    [ "Enedis",[1.4686362020591077, 43.57933171332807]],
    [ "75462677",[-0.2523544194135114, 46.291703532034155]],
    [ "Enedis",[0.5804251787130281, 47.34962226512428]],
    [ "Enedis",[0.5924796342887273, 47.35656614351722]],
    [ "Enedis",[0.5470819808717863, 47.34438746889945]],
    [ "Enedis",[0.541626026636675, 47.35943111451972]],
    [ "Enedis",[-1.9785130992617999, 46.848829666641805]],
    [ "Enedis",[-2.01351355, 46.86111575]],
    [ "RTE",[2.8262584406582825, 46.14285258580406]],
    [ "RTE",[2.7562647789981347, 46.287551264241245]],
    [ "Poste électrique de Montvicq",[2.8210869475543907, 46.305831919277416]],
    [ "Enedis",[3.8395697843018017, 50.18671788060811]],
    [ "Enedis",[3.829708588516271, 50.17793991873276]],
    [ "EMBOUCHURE",[1.4140972312346678, 43.611014161703054]],
    [ "Enedis",[1.4160408673377924, 43.618069671020045]],
    [ "Enedis",[1.4165467663369675, 43.63905337331412]],
    [ "Enedis",[1.4149853079711512, 43.63268092787064]],
    [ "Enedis",[1.4124537128776256, 43.61853511953762]],
    [ "Enedis",[1.4081666045689603, 43.62913596875125]],
    [ "Enedis",[1.419653149999999, 43.63980775]],
    [ "Enedis",[1.4121828232069267, 43.65947389131945]],
    [ "Enedis",[1.415468564285575, 43.62073425761028]],
    [ "Enedis",[1.4100362895326497, 43.614783792177455]],
    [ "Enedis",[1.4207735623163422, 43.646092245771634]],
    [ "Enedis",[1.4156129759575773, 43.64297961076526]],
    [ "Enedis",[1.4083301402632016, 43.623061124621024]],
    [ "Enedis",[1.420354116807647, 43.64162909653947]],
    [ "Eberhardt 16",[1.4144685, 43.641385549999995]],
    [ "Enedis",[1.4130506920960408, 43.62086062278311]],
    [ "Enedis",[1.407831803711868, 43.66158427413481]],
    [ "Enedis",[1.4052800068687807, 43.6199560568116]],
    [ "Enedis",[1.4059123743729625, 43.620865474595824]],
    [ "Enedis",[0.665450446435987, 47.2762259826688]],
    [ "Enedis",[0.6436765387268599, 47.272491015694214]],
    [ "Enedis",[0.6404830972213637, 47.299283682138274]],
    [ "Enedis",[0.6473297616872372, 47.28147205027236]],
    [ "Enedis",[0.6520702889057349, 47.280910316714326]],
    [ "Enedis",[0.6566608507314682, 47.29881195162731]],
    [ "Enedis",[0.6436792540910602, 47.299749334488645]],
    [ "Enedis",[0.6567210612638666, 47.280369537289566]],
    [ "Enedis",[0.6535372180179772, 47.297835631897684]],
    [ "Enedis",[0.6371091290044928, 47.296138938901585]],
    [ "Enedis",[0.6477937500000001, 47.2977399]],
    [ "Enedis",[0.6208837288035662, 47.27646461974404]],
    [ "Enedis",[0.6386114560170271, 47.275553374580355]],
    [ "Enedis",[0.6577705948181862, 47.282432839418796]],
    [ "Enedis",[-1.9012333852734402, 46.85606083090529]],
    [ "Enedis",[-1.892903177054588, 46.857160955559465]],
    [ "Enedis",[-1.9464795499999996, 46.858753549999996]],
    [ "Enedis",[-1.952377421866589, 46.86521660546622]],
    [ "Enedis",[-1.943045257980548, 46.85823051858447]],
    [ "Enedis",[-1.9566106500000002, 46.86086265]],
    [ "Enedis",[-1.9397427999999992, 46.85201240000001]],
    [ "Enedis",[2.9524689488688436, 42.62778983416633]],
    [ "Enedis",[2.9551666922101485, 42.62180995214335]],
    [ "Enedis",[3.859429815395112, 50.17636183157231]],
    [ "Enedis",[3.2669615140155472, 50.31615932645656]],
    [ "Enedis",[3.253493942396465, 50.31306235359094]],
    [ "Enedis",[3.266295891528114, 50.31385256257194]],
    [ "Enedis",[0.6226501499999999, 47.277861299999984]],
    [ "Enedis",[0.6529321143044889, 47.286720748426355]],
    [ "Enedis",[0.6404622740294874, 47.29685349665916]],
    [ "Enedis",[0.668450073885512, 47.28432936027901]],
    [ "Enedis",[0.626715300455062, 47.276946124179965]],
    [ "Enedis",[0.6580814513503046, 47.2964582060174]],
    [ "Enedis",[0.6491824499999999, 47.2827043]],
    [ "Enedis",[0.6676261, 47.27675730000001]],
    [ "Enedis",[0.6541510941796638, 47.28291619349045]],
    [ "Enedis",[0.6499848368888143, 47.28474860542282]],
    [ "Enedis",[0.5410042903291234, 47.30886797168112]],
    [ "Enedis",[2.776661049175819, 48.15512983342273]],
    [ "Enedis",[2.9532400816201227, 42.62090570952664]],
    [ "Enedis",[2.9522561575294226, 42.61967071832839]],
    [ "Enedis",[2.9484537916005875, 42.62625743791483]],
    [ "Enedis",[2.9537570321502744, 42.62274230110178]],
    [ "Enedis",[2.951694061118514, 42.62312305025444]],
    [ "Enedis",[2.7655683411128194, 48.14694069328312]],
    [ "Enedis",[2.9793072490148687, 42.60794100858191]],
    [ "Enedis",[2.976511375615438, 42.599302353735695]],
    [ "Enedis",[2.7887008697177387, 48.09613332876664]],
    [ "Enedis",[2.7872604363153424, 48.09166160088092]],
    [ "Enedis",[2.7850624744291212, 48.09040714564383]],
    [ "Enedis",[2.8047307008470064, 48.095224853143584]],
    [ "Enedis",[2.791379712054352, 48.10305060643535]],
    [ "Enedis",[2.7886120543498194, 48.085165803154716]],
    [ "Enedis",[2.8048479276758456, 48.096251075528386]],
    [ "Enedis",[2.794638879031142, 48.10396739430712]],
    [ "Enedis",[2.7910633330203867, 48.0896482092256]],
    [ "Enedis",[2.7904178118029135, 48.09162177663184]],
    [ "Enedis",[2.807498873506386, 48.09597051499794]],
    [ "Enedis",[2.7866472941250806, 48.08632199964819]],
    [ "Enedis",[2.9697788211948577, 42.59529454482984]],
    [ "Enedis",[2.9750028750506794, 42.60782865514518]],
    [ "Toilettes publiques",[2.970875651816542, 42.6002510331043]],
    [ "Enedis",[2.970852463058866, 42.60277180752917]],
    [ "Enedis",[2.9676508324093813, 42.60199945107795]],
    [ "Enedis",[2.9826405321120566, 42.606974065219106]],
    [ "Enedis",[2.9668744803588196, 42.59853461455216]],
    [ "Enedis",[2.976689099612659, 42.60652729201075]],
    [ "Enedis",[-1.9374956824705858, 46.80692375255441]],
    [ "Enedis",[1.6388409677869191, 43.35757522482458]],
    [ "Enedis",[1.6296449999999998, 43.357470299999996]],
    [ "Enedis",[1.6375178999999995, 43.356878349999995]],
    [ "Enedis",[1.6217318932788305, 43.35833598086987]],
    [ "Poste électrique des Batignolles",[2.3219501863773293, 48.88370416492125]],
    [ "Enedis",[3.9226535124202395, 50.219182831957404]],
    [ "Giratoire RN 21",[0.7644202055298368, 45.18823313490265]],
    [ "Enedis",[3.8571168729623766, 43.62048823021024]],
    [ "Geredis",[0.14595266015783678, 46.33327568438812]],
    [ "Enedis",[2.7764042366064063, 42.58151155079662]],
    [ "Enedis",[2.7804236437764303, 42.58138728060453]],
    [ "Enedis",[2.780576616488565, 42.57919276919314]],
    [ "Enedis",[2.776028787893864, 42.57656584032062]],
    [ "Enedis",[-1.8985277850575768, 46.81000862838912]],
    [ "Impetus",[-2.3426386047771426, 47.26953925184711]],
    [ "Les algues",[-2.3405469691377796, 47.26046007044367]],
    [ "Océan",[-2.34835641527533, 47.26885802159181]],
    [ "Monnier",[-2.3423815426869954, 47.26662803634599]],
    [ "Enedis",[2.8336534890095058, 50.48689785168963]],
    [ "Enedis",[0.590290429515298, 47.43170374238491]],
    [ "Enedis",[0.6056118980239986, 47.46807724370976]],
    [ "Enedis",[0.6096717550526369, 47.46896130113521]],
    [ "Enedis",[0.6047753499999999, 47.4661326]],
    [ "Enedis",[3.5381064824876467, 50.103574218932174]],
    [ "Enedis",[3.5412978673987996, 50.10228743299447]],
    [ "Enedis",[3.5331494522877884, 50.10340730171095]],
    [ "Enedis",[3.5541064096139245, 50.1071664328836]],
    [ "Enedis",[3.539012102712865, 50.09577207188582]],
    [ "Enedis",[3.533638539045586, 50.100900629996026]],
    [ "Enedis",[3.5416309996728885, 50.1080159501828]],
    [ "Enedis",[3.5352724200531798, 50.1061304178967]],
    [ "Enedis",[3.538537428185328, 50.10141354967702]],
    [ "Enedis",[3.5517081, 50.10352365]],
    [ "Enedis",[3.5380500132278767, 50.10895301848653]],
    [ "Enedis",[3.5229093400440776, 50.08318963308047]],
    [ "Enedis",[3.5466008278993018, 50.10707327486271]],
    [ "Enedis",[3.5466088072952426, 50.101741076789494]],
    [ "Enedis",[3.5412867948212763, 50.10373638078425]],
    [ "Enedis",[3.542645633802875, 50.1047380546529]],
    [ "Enedis",[3.53981315, 50.10660554999999]],
    [ "Enedis",[3.536949240192748, 50.08785994929359]],
    [ "Enedis",[3.545171587829889, 50.10374156897835]],
    [ "Enedis",[3.5465081336720528, 50.09717182466378]],
    [ "Enedis",[3.5197362494252875, 50.10857015077755]],
    [ "Enedis",[3.534606017752831, 50.099423571331315]],
    [ "Enedis",[3.533629289256487, 50.09631701097081]],
    [ "Enedis",[3.5327270935131923, 50.10228387841422]],
    [ "Enedis",[3.539699246465064, 50.10335023514133]],
    [ "Enedis",[0.5987131114538364, 47.4954323984127]],
    [ "Enedis",[6.261597250528686, 45.81132643030875]],
    [ "Enedis",[6.865267364325564, 47.53937254966876]],
    [ "Enedis",[6.867324676929963, 47.540081743742995]],
    [ "Enedis",[6.869040650292446, 47.54005719739072]],
    [ "Enedis",[6.86996380565065, 47.546612389409624]],
    [ "FC Claude Bonne 3",[2.22385866668623, 48.98499912352715]],
    [ "Enedis",[2.2349416256149977, 48.98711915764233]],
    [ "Enedis",[0.49485035981301084, 47.446097427978245]],
    [ "Enedis",[0.4966592505095288, 47.44430532204887]],
    [ "Enedis",[0.4951754700880371, 47.445270404516215]],
    [ "Enedis",[-1.1792893170098564, 46.84884779904779]],
    [ "Enedis",[-1.17061026783102, 46.85192250245678]],
    [ "Enedis",[-1.1407825999999992, 46.8862525]],
    [ "Enedis",[-1.1719303930742944, 46.85685726855105]],
    [ "Enedis",[-1.1683590499999998, 46.8536937]],
    [ "Enedis",[-1.1816841579312156, 46.850904919397514]],
    [ "Enedis",[-1.1743481499999993, 46.852728850000005]],
    [ "Enedis",[-1.1748796000000001, 46.84764665]],
    [ "Enedis",[-1.18522174116103, 46.84964807031189]],
    [ "Enedis",[-1.6060625498323269, 43.36121536849425]],
    [ "Enedis",[-1.5748533112854342, 43.35972862380593]],
    [ "Enedis",[-1.5689937500000002, 43.357199]],
    [ "Enedis",[3.83380895, 43.6148268]],
    [ "Enedis",[-1.5516389999999998, 43.35765524999999]],
    [ "Enedis",[-1.5265133789424843, 43.350241996925554]],
    [ "Enedis",[3.8547104917138095, 43.62235685769472]],
    [ "Poste électrique Lamarck",[2.3276617529840316, 48.89177900799573]],
    [ "Poste électrique Caulaincourt",[2.3338310212912012, 48.8912104441659]],
    [ "Enedis",[0.1376708494705566, 49.498208224848845]],
    [ "Enedis",[-1.186461345077607, 46.948998767159765]],
    [ "Enedis",[-1.1907464500000005, 46.9502829]],
    [ "Enedis",[-1.1895940999999999, 46.953741650000005]],
    [ "Enedis",[3.842236258274809, 43.64726314374839]],
    [ "Enedis",[-1.2133961803937252, 46.94583429439584]],
    [ "Enedis",[3.8351900775692362, 43.6420802092689]],
    [ "Enedis",[0.165576435735469, 49.50638667424685]],
    [ "Enedis",[0.1592875376893606, 49.506490224585654]],
    [ "Enedis",[0.16725866344670198, 49.505673218799295]],
    [ "Enedis",[0.16233372769487778, 49.51061951746592]],
    [ "Enedis",[3.8704101795835015, 43.64490582578809]],
    [ "Enedis",[0.17830572455669, 49.50333305239119]],
    [ "Enedis",[0.13473680054445006, 49.50000000580574]],
    [ "Enedis",[-1.3040838000000001, 46.9208025]],
    [ "Enedis",[-1.2977628969338955, 46.94367381698078]],
    [ "Enedis",[-1.4310464660672875, 43.476879622284535]],
    [ "Enedis",[-1.438661841169005, 43.48105764656742]],
    [ "Enedis",[-1.4344455317245843, 43.48053165181719]],
    [ "Enedis",[-1.2926522500000002, 46.94693705]],
    [ "Enedis",[-1.2837448967181317, 46.94404616696607]],
    [ "Enedis",[-1.412317628872881, 43.464608313737905]],
    [ "Enedis",[-1.3011542474582183, 46.948937351869525]],
    [ "Enedis",[-1.2863369346753004, 46.944013673163475]],
    [ "Enedis",[-1.4322124352694698, 43.47929723511596]],
    [ "Enedis",[-1.2905532800548407, 46.944632030936816]],
    [ "Enedis",[-1.2912305329517888, 46.94093095118499]],
    [ "Enedis",[-1.2939197500000004, 46.93152660000001]],
    [ "Enedis",[-1.3063514000000005, 46.96008174999999]],
    [ "Enedis",[-1.2450200832684224, 46.944430648597354]],
    [ "Enedis",[-1.2869531999999995, 46.94827739999999]],
    [ "Enedis",[-1.2978296, 46.9473746]],
    [ "Enedis",[0.11193092522243488, 49.49802161254767]],
    [ "Enedis",[-1.315671974499707, 46.975321255259324]],
    [ "Enedis",[-1.3285829999999998, 46.97695585]],
    [ "Enedis",[-1.3039891831913208, 46.98163604815385]],
    [ "Enedis",[-1.3104002999999997, 46.970768400000004]],
    [ "Enedis",[0.13241258738088238, 49.49790879988021]],
    [ "Enedis",[-1.3010806000000004, 46.967613150000005]],
    [ "Enedis",[-1.3068855114016877, 46.97295797507606]],
    [ "Enedis",[-1.3134323000000003, 46.97763665]],
    [ "Enedis",[-1.308104849999999, 46.967565500000006]],
    [ "Enedis",[-1.308202855479204, 46.97872013764315]],
    [ "Enedis",[-1.3082221, 46.975837799999994]],
    [ "Enedis",[-1.3218413499999997, 46.9825152]],
    [ "Enedis",[-1.30908405, 46.970978650000006]],
    [ "Enedis",[-1.3152244127839687, 46.97074551905789]],
    [ "Enedis",[-1.3250466554017526, 46.97696568258081]],
    [ "Enedis",[-1.311853214252152, 46.975284975919074]],
    [ "Enedis",[-1.3120424639515105, 46.97701547187241]],
    [ "Enedis",[-1.3135126668905301, 46.98008419747859]],
    [ "Enedis",[-1.3045478000000001, 46.979279600000005]],
    [ "Enedis",[-1.3073811157515451, 46.981552833593724]],
    [ "Enedis",[-1.3164230678535365, 46.98020374907186]],
    [ "Enedis",[3.966813688759329, 50.21495016827639]],
    [ "Enedis",[3.9789097909593507, 50.22436297450924]],
    [ "Enedis",[3.9612692150145867, 50.21483332403739]],
    [ "Enedis",[-1.2371446499999998, 46.92855295000001]],
    [ "Enedis",[-1.3023797, 46.953507900000005]],
    [ "Enedis",[-1.5389322768477958, 43.454930443659116]],
    [ "Enedis",[-1.304904376733465, 46.95588330573901]],
    [ "Enedis",[1.3827477330298439, 47.23253133762751]],
    [ "Enedis",[-1.8960706999999999, 46.796454350000005]],
    [ "Enedis",[-1.8759301500000007, 46.803534049999996]],
    [ "Enedis",[-1.8931571500000004, 46.7932954]],
    [ "Enedis",[-1.8997846458530663, 46.80389291746236]],
    [ "Enedis",[-1.9021644, 46.79582615]],
    [ "Enedis",[-1.8931468999999999, 46.7958668]],
    [ "Enedis",[-1.8945888632908183, 46.79822737591608]],
    [ "Enedis",[-1.8992840000000002, 46.8003145]],
    [ "Enedis",[4.904472551499468, 45.658246285274174]],
    [ "Enedis",[2.202862579461816, 48.88298463795975]],
    [ "Enedis",[0.13870379045952885, 49.51006503533079]],
    [ "Enedis",[3.896228828170164, 43.616299693365605]],
    [ "Enedis",[3.9019685079741566, 43.618572845751174]],
    [ "Enedis",[3.887182117846878, 43.62239839836532]],
    [ "Enedis",[3.887468487484157, 43.62151488478897]],
    [ "Enedis",[3.88026315858262, 43.61997237122849]],
    [ "Enedis",[3.8834555941080673, 43.62331827756871]],
    [ "Enedis",[3.8877966568253606, 43.62069757589169]],
    [ "Enedis",[3.884077334057649, 43.622358384850976]],
    [ "Enedis",[3.89064295, 43.61571845]],
    [ "Enedis",[3.8923314294392233, 43.6188218566439]],
    [ "Enedis",[3.884682452011793, 43.61994336514915]],
    [ "Enedis",[3.8897219194739043, 43.61797170712303]],
    [ "Enedis",[3.8857602961802065, 43.617665849408375]],
    [ "Enedis",[3.8843824156584765, 43.62129124244657]],
    [ "Enedis",[3.883811471880557, 43.61713588801318]],
    [ "Enedis",[0.14403939999999957, 49.510142050000006]],
    [ "Enedis",[0.1543689541793724, 49.51095643230096]],
    [ "Enedis",[0.1466995978758659, 49.51186834171333]],
    [ "Enedis",[0.15897396736541425, 49.511310825074275]],
    [ "Enedis",[0.15799718586937098, 49.512440133690596]],
    [ "Enedis",[3.882255561342352, 43.62430954353641]],
    [ "Enedis",[3.8823690729744116, 43.622260166478696]],
    [ "Enedis",[3.877448900728983, 43.615917031648785]],
    [ "Enedis",[4.900949906996423, 44.115919405261735]],
    [ "Enedis",[4.890745867937428, 44.11430225359051]],
    [ "Enedis",[4.90818415, 44.116614250000005]],
    [ "Enedis",[4.9005693693279815, 44.11887365589185]],
    [ "Enedis",[4.900712582479993, 44.11319497985651]],
    [ "Enedis",[4.909680599879854, 44.11851330111336]],
    [ "Enedis",[4.906479308119242, 44.122780063969905]],
    [ "Enedis",[4.895257776159582, 44.115584146350194]],
    [ "Enedis",[4.915117658016803, 44.10878296573519]],
    [ "Enedis",[4.897194526638359, 44.126045292853206]],
    [ "Enedis",[4.896480997254042, 44.120576391923656]],
    [ "Enedis",[3.860203995238265, 43.632286331272546]],
    [ "Enedis",[1.0731574958669765, 47.7756101685175]],
    [ "Enedis",[1.0643210123299136, 47.80762409607115]],
    [ "Enedis",[1.0375899845697594, 47.803153285263214]],
    [ "Enedis",[1.0561211985176648, 47.80636836293704]],
    [ "Enedis",[1.0219462776149095, 47.82033399063318]],
    [ "Enedis",[1.0796025497391548, 47.78651057670507]],
    [ "Enedis",[1.0366694242736088, 47.79984554802047]],
    [ "Enedis",[1.044859978500654, 47.80490833602543]],
    [ "Enedis",[1.076870986446541, 47.79565909943396]],
    [ "Enedis",[1.2405767947900377, 46.10639651079525]],
    [ "Enedis",[1.0469976886911556, 47.81449045724711]],
    [ "Enedis",[1.0401441764690549, 47.80493810914855]],
    [ "Enedis",[1.0643580545710638, 47.79765199930307]],
    [ "Enedis",[1.0404508354944202, 47.802989872109386]],
    [ "Enedis",[1.0274214855014339, 47.812253506508064]],
    [ "Enedis",[1.0650602472119681, 47.79162875533968]],
    [ "Enedis",[4.848288360849625, 44.06490245303694]],
    [ "Enedis",[4.826268278826149, 44.052679905286745]],
    [ "Enedis",[1.0771765247606433, 47.80282560378539]],
    [ "Enedis",[1.0693031, 47.795224399999995]],
    [ "Enedis",[1.0697565293303768, 47.79592558852829]],
    [ "Enedis",[4.825424174640816, 44.056034156234034]],
    [ "Enedis",[1.0830793654638173, 47.781265253713556]],
    [ "Enedis",[4.83502035, 44.0516791]],
    [ "Enedis",[1.0589863831061996, 47.79706034216836]],
    [ "Enedis",[1.0705793975868636, 47.787812765122105]],
    [ "Enedis",[1.0691249506224128, 47.78529276057566]],
    [ "Enedis",[1.0575102448306393, 47.80481442865484]],
    [ "Enedis",[1.0702596000000006, 47.8016333]],
    [ "Enedis",[1.0287070203592987, 47.808810734419545]],
    [ "Enedis",[1.065498735874601, 47.80580864540843]],
    [ "Enedis",[1.0743595556499972, 47.778560760514445]],
    [ "Enedis",[1.0749538501626492, 47.777806501661104]],
    [ "Enedis",[1.0501751132506227, 47.8023125845037]],
    [ "Enedis",[1.0693978637046169, 47.79787356134021]],
    [ "Enedis",[1.06772045, 47.7811602]],
    [ "Enedis",[1.0670985917053426, 47.785592321021724]],
    [ "Enedis",[1.048909266600645, 47.80718080085298]],
    [ "Enedis",[1.0496024931464634, 47.80483055822607]],
    [ "Enedis",[1.0714464325682154, 47.79866454929212]],
    [ "Enedis",[1.0439220653997572, 47.801213097912985]],
    [ "Enedis",[1.064556502011018, 47.8036492296571]],
    [ "Enedis",[1.0667632806362648, 47.791283157533194]],
    [ "Enedis",[4.8777432373097085, 44.08390733246711]],
    [ "Enedis",[4.880121293634776, 44.0882484971893]],
    [ "Enedis",[4.8847465375134425, 44.08513635823125]],
    [ "Enedis",[4.885543306732157, 44.08305907548422]],
    [ "Enedis",[4.889517145630692, 44.08964917509965]],
    [ "Enedis",[4.886740401054541, 44.087076941121495]],
    [ "Enedis",[4.876690412383355, 44.07168591630386]],
    [ "Enedis",[4.881723258615201, 44.08576419791573]],
    [ "Enedis",[4.874471767716344, 44.07940458156017]],
    [ "Enedis",[4.887370144977732, 44.07948031309016]],
    [ "Enedis",[4.891269032986571, 44.08572282630374]],
    [ "Enedis",[4.882644450000001, 44.096469600000006]],
    [ "Enedis",[4.880298631640994, 44.0817605844579]],
    [ "Enedis",[-1.4586522528615435, 43.47838017085224]],
    [ "Enedis",[-1.4409743999999998, 43.468663649999996]],
    [ "Enedis",[-1.4472660845711862, 43.468168144360895]],
    [ "Enedis",[0.10767492848928173, 49.500807945593536]],
    [ "COLLEGE",[-1.444107477321276, 43.473087384740346]],
    [ "Enedis",[-1.4452157964148131, 43.47472865574075]],
    [ "PTT",[4.70835565282124, 47.308373367245544]],
    [ "Enedis",[-1.3491012091856485, 46.989990630939545]],
    [ "Enedis",[-1.3280333500000001, 46.9801323]],
    [ "76260769",[-1.3234568919806493, 47.0049754918411]],
    [ "Enedis",[-1.29833711764367, 46.98188739908426]],
    [ "Enedis",[-1.8564029499999999, 46.7998831]],
    [ "76266105",[-1.3326715329601562, 46.978259720122864]],
    [ "Enedis",[-1.33007135, 46.976817249999996]],
    [ "Enedis",[-1.3565948524695797, 47.00027458889328]],
    [ "Enedis",[-1.3108553003535734, 46.98329026668003]],
    [ "Enedis",[-1.2982729000000002, 46.98190795]],
    [ "Enedis",[-1.3317456999999997, 47.001079250000004]],
    [ "Enedis",[-1.2562588852283227, 47.02321761806959]],
    [ "Enedis",[-1.2693519436498204, 47.04826488168938]],
    [ "Enedis",[-1.2645106500000003, 47.05011385]],
    [ "Enedis",[1.4598639153473771, 43.62660700834132]],
    [ "Enedis",[1.4349489978551848, 43.62678164180355]],
    [ "Enedis",[1.4510615515307477, 43.62850189130952]],
    [ "Enedis",[1.453274379447115, 43.64434170600961]],
    [ "Enedis",[1.4547771220484025, 43.6370276202919]],
    [ "Enedis",[1.4212418020364335, 43.61832892717279]],
    [ "Enedis",[1.4573929419932647, 43.62987682831016]],
    [ "Enedis",[1.469831750000001, 43.628908]],
    [ "Enedis",[1.4596920844153467, 43.61926833486606]],
    [ "Enedis",[1.419186936803395, 43.65820095327195]],
    [ "Enedis",[1.4753346949969794, 43.625369302148535]],
    [ "Enedis",[1.422448234885831, 43.620935616203]],
    [ "Enedis",[1.4731922951182579, 43.61661572817268]],
    [ "Enedis",[1.4273602500000002, 43.63208800000001]],
    [ "Enedis",[1.4702092156379007, 43.61452367540759]],
    [ "Enedis",[1.4446168809132827, 43.65050172172984]],
    [ "Enedis",[1.4735372499999997, 43.6213061]],
    [ "Enedis",[1.4575091659003632, 43.6294687231069]],
    [ "Enedis",[1.4623563715883072, 43.621379560724364]],
    [ "Enedis",[1.4613005148562086, 43.64096454095687]],
    [ "Enedis",[1.444796485422684, 43.631465476834194]],
    [ "Enedis",[1.433231634591195, 43.645060789535655]],
    [ "Enedis",[1.4621580038106037, 43.64559216347398]],
    [ "Enedis",[1.4490289867862454, 43.63182684560647]],
    [ "Enedis",[1.464849752846459, 43.65188181636905]],
    [ "Enedis",[1.4263901499999998, 43.61842075]],
    [ "Enedis",[1.469397005908412, 43.633312106045345]],
    [ "Enedis",[1.449746704820356, 43.62415813788071]],
    [ "Enedis",[1.4257717839329194, 43.6522865794249]],
    [ "Enedis",[1.4591915361030876, 43.63559035387596]],
    [ "Enedis",[1.425964146522067, 43.62639835932115]],
    [ "Enedis",[1.45614176188798, 43.62351570097097]],
    [ "Enedis",[1.480030584081727, 43.61414917676901]],
    [ "Enedis",[1.4291002499999998, 43.6464365]],
    [ "Enedis",[1.456070759605433, 43.62167046882995]],
    [ "Enedis",[1.43569338486036, 43.63512860766634]],
    [ "Enedis",[1.4234388500000001, 43.62443295]],
    [ "Enedis",[1.4262390996936722, 43.6417623805942]],
    [ "Enedis",[1.464845238485767, 43.622491077076425]],
    [ "Enedis",[1.4239868745455522, 43.61249434080399]],
    [ "Enedis",[1.4350823613796042, 43.64580777602767]],
    [ "Enedis",[1.4332988582008623, 43.627514957609556]],
    [ "Enedis",[1.4256134772032236, 43.65757325864477]],
    [ "Enedis",[1.4678465172220945, 43.624745704584484]],
    [ "Enedis",[1.4748054467941276, 43.62815505014458]],
    [ "Enedis",[1.4296778447433978, 43.649416951197466]],
    [ "Enedis",[1.463452727450769, 43.627424634755236]],
    [ "Enedis",[1.4732747499999999, 43.62339774999999]],
    [ "Enedis",[1.4259849567933693, 43.64683850875283]],
    [ "Enedis",[1.4275935418416188, 43.6359861928619]],
    [ "Enedis",[1.4695490959823072, 43.61229233417621]],
    [ "Enedis",[1.429833653297302, 43.6219928490022]],
    [ "Enedis",[1.439331997767312, 43.64261825636085]],
    [ "Enedis",[1.4326556171255111, 43.63570588100841]],
    [ "Enedis",[1.4753795438291735, 43.6196102472742]],
    [ "Enedis",[1.4821178602321865, 43.632778819721935]],
    [ "Enedis",[1.4216274996984473, 43.62730954962743]],
    [ "Enedis",[1.4452443598265154, 43.65202673063558]],
    [ "Enedis",[1.4573473204528613, 43.62983872586324]],
    [ "Enedis",[1.4655475571458578, 43.643369025661684]],
    [ "Enedis",[1.4736119771717806, 43.62048779454998]],
    [ "Enedis",[1.4390312500000002, 43.644211899999995]],
    [ "Enedis",[1.4346434585544838, 43.635712156620855]],
    [ "Enedis",[1.4747440886788739, 43.634775411045716]],
    [ "Enedis",[1.4529383381732468, 43.618881712352504]],
    [ "Enedis",[1.4689082337657922, 43.62796076637276]],
    [ "Enedis",[1.4305026616271148, 43.63366787861758]],
    [ "Enedis",[1.4543842540127714, 43.6318803234777]],
    [ "Enedis",[1.443640802132197, 43.63321939400616]],
    [ "Enedis",[1.4311506513990093, 43.62535621079183]],
    [ "Enedis",[1.4523893146592293, 43.625444603932564]],
    [ "Enedis",[1.4555225500000002, 43.6348054]],
    [ "Enedis",[1.453809139180191, 43.622333588989875]],
    [ "Enedis",[1.4661216600984126, 43.63922302733703]],
    [ "Enedis",[1.4338331196637313, 43.63042027549049]],
    [ "Enedis",[1.4498400643802174, 43.634716005101815]],
    [ "Enedis",[1.4250959000000003, 43.63267755]],
    [ "Enedis",[1.4331180033578343, 43.62211784646588]],
    [ "Enedis",[1.4746642636282536, 43.613940301413045]],
    [ "Enedis",[1.422974068766359, 43.61938668529716]],
    [ "Enedis",[1.4459163444129672, 43.6445564661297]],
    [ "Enedis",[1.4731083731986547, 43.62967394609453]],
    [ "Enedis",[1.4739607999999997, 43.6285704]],
    [ "Enedis",[1.422487032302325, 43.65457639759081]],
    [ "Enedis",[1.4556163667920714, 43.64058401453515]],
    [ "Enedis",[1.434926833813468, 43.62683548162961]],
    [ "Enedis",[1.4603308569508766, 43.628304563565784]],
    [ "Enedis",[1.4349560282744689, 43.62681189250381]],
    [ "Enedis",[1.4617987516260162, 43.65018033333333]],
    [ "Enedis",[1.463382707609342, 43.631869471879156]],
    [ "Enedis",[1.4350819257621106, 43.65143446923619]],
    [ "FENOUILLET - 92",[1.4218619993591792, 43.63445007617965]],
    [ "Enedis",[1.4455505499999999, 43.63016595000001]],
    [ "Enedis",[1.4589221691909076, 43.624320949197475]],
    [ "Enedis",[1.4516842036459394, 43.63744377988657]],
    [ "Enedis",[1.4734399169655468, 43.63621738034597]],
    [ "Enedis",[1.4659686889057872, 43.62651292565467]],
    [ "Enedis",[1.442950753253591, 43.639817364805]],
    [ "Enedis",[1.4647251933025123, 43.64247631318911]],
    [ "Enedis",[1.4296244972620402, 43.640646094958235]],
    [ "Enedis",[1.4397659078592446, 43.629853140415506]],
    [ "Enedis",[1.4538925452792566, 43.619874401635315]],
    [ "Enedis",[1.4437352741182226, 43.63222937987629]],
    [ "Enedis",[1.463441392673713, 43.64255385817541]],
    [ "Enedis",[1.461288198846355, 43.62450281646127]],
    [ "Enedis",[1.4768995369347673, 43.612922514728986]],
    [ "Enedis",[1.4356349199395149, 43.64138063475365]],
    [ "Enedis",[1.4388370742017134, 43.641310293185356]],
    [ "Enedis",[1.4270703063274028, 43.61392771803698]],
    [ "Enedis",[1.4393550826273331, 43.65494894572109]],
    [ "Enedis",[1.4387391824104514, 43.62828018570084]],
    [ "Enedis",[1.4498507030230658, 43.6258555206032]],
    [ "Enedis",[1.433211910243782, 43.623385813672606]],
    [ "Enedis",[1.4435728833926342, 43.626062516284584]],
    [ "Enedis",[1.437567227122961, 43.63977488565567]],
    [ "Enedis",[1.4656732000000012, 43.647496499999995]],
    [ "Enedis",[1.4500195999999999, 43.648757149999994]],
    [ "Enedis",[1.4482687260868126, 43.62912283377704]],
    [ "Enedis",[1.4356562897771816, 43.654332926768504]],
    [ "Enedis",[1.4393585460270055, 43.61709568014102]],
    [ "Enedis",[1.4387310696255076, 43.64705451194635]],
    [ "Enedis",[1.441280409927483, 43.61804771332658]],
    [ "Enedis",[1.4695025339642065, 43.63869348321726]],
    [ "Enedis",[1.4590234662624255, 43.63736394790213]],
    [ "Enedis",[1.4472668540323723, 43.63057285340001]],
    [ "Enedis",[1.4512254821784276, 43.629788002707684]],
    [ "Enedis",[1.429905, 43.63475330000001]],
    [ "Enedis",[1.4401404965779039, 43.61932671868288]],
    [ "Enedis",[1.442751780806256, 43.619939406529284]],
    [ "Enedis",[1.431200760061189, 43.62871061506279]],
    [ "Enedis",[1.4665262809133377, 43.63367167711361]],
    [ "Enedis",[1.4383885436136117, 43.6326030216265]],
    [ "Enedis",[1.436430379912023, 43.62247479849707]],
    [ "Enedis",[1.4535364000000008, 43.6483246]],
    [ "Enedis",[1.4294835718063605, 43.624631856473115]],
    [ "Enedis",[1.4600355349875034, 43.64079903802632]],
    [ "Enedis",[1.4378972674548018, 43.655635641440796]],
    [ "Enedis",[1.4735875836785737, 43.62602069696872]],
    [ "Enedis",[1.4319505306452973, 43.65337479665178]],
    [ "Enedis",[1.4541254505747125, 43.6265587183908]],
    [ "Enedis",[1.43911135, 43.637613849999994]],
    [ "Enedis",[1.429187800000001, 43.6350583]],
    [ "Enedis",[1.0622798537141833, 47.80593613958525]],
    [ "Enedis",[1.0808399483965325, 47.78786727942146]],
    [ "Enedis",[1.0690228282753174, 47.778048304610564]],
    [ "Enedis",[1.0659094839863135, 47.790903076679]],
    [ "Enedis",[1.070268867883996, 47.79636617667288]],
    [ "Enedis",[1.0601105891837097, 47.81617664257439]],
    [ "Enedis",[1.0685398270775082, 47.79284886405324]],
    [ "Enedis",[1.0768146274349206, 47.800100738527476]],
    [ "Enedis",[1.06812945, 47.779903499999996]],
    [ "Enedis",[1.0789800552974547, 47.780578252048365]],
    [ "Enedis",[1.0588601249875063, 47.80126930049975]],
    [ "Enedis",[1.060270062832188, 47.796886447373296]],
    [ "Enedis",[1.0615856149300802, 47.79133529614761]],
    [ "Enedis",[1.0656441473322635, 47.78259009837616]],
    [ "Enedis",[1.0722843927790495, 47.794508290490725]],
    [ "Enedis",[1.052147752412607, 47.805276281107545]],
    [ "Enedis",[1.0537313016760017, 47.813477985931314]],
    [ "Enedis",[1.0471739813428063, 47.81111574824221]],
    [ "Enedis",[1.0508325711633255, 47.815193648491075]],
    [ "Enedis",[1.0810627706470008, 47.80006359668306]],
    [ "Enedis",[1.0470586751657625, 47.799328259795054]],
    [ "Enedis",[1.0544925356049206, 47.797413085961125]],
    [ "Enedis",[1.0721570978676591, 47.77885586562711]],
    [ "Enedis",[1.0701387869772807, 47.78248942124198]],
    [ "Enedis",[1.054786542453751, 47.80425969181116]],
    [ "Enedis",[1.0622080219182086, 47.813754544022025]],
    [ "Enedis",[1.0728179013506909, 47.79729702966065]],
    [ "Enedis",[1.0750505276999582, 47.78276853233667]],
    [ "Enedis",[1.056375061086736, 47.79914341197795]],
    [ "Enedis",[1.0488080053472533, 47.797715204086835]],
    [ "Enedis",[1.0750388669586968, 47.786720736628844]],
    [ "Enedis",[1.0252178080560068, 47.814331111086]],
    [ "Enedis",[3.8814291289700456, 43.626001694054544]],
    [ "FERRAND",[3.88482476644796, 43.637482199169696]],
    [ "Enedis",[3.8831279138143646, 43.638362702956556]],
    [ "Enedis",[3.872230774366479, 43.63863893624112]],
    [ "Enedis",[2.422420814812191, 51.04271489136633]],
    [ "Enedis",[2.4047877159820805, 51.03975668078931]],
    [ "Enedis",[2.4300020499999997, 51.0556015]],
    [ "Enedis",[2.3226498069253942, 51.02456320104108]],
    [ "Enedis",[2.3512905434083, 51.00701265798607]],
    [ "Enedis",[2.32936557386081, 51.01409123896557]],
    [ "Enedis",[2.3532146500000004, 51.018310150000005]],
    [ "Enedis",[2.3813302394689218, 51.03563946915196]],
    [ "Enedis",[2.4185162688892463, 51.045135172060235]],
    [ "Enedis",[2.368709873934867, 51.032431153757116]],
    [ "Enedis",[2.350821639607865, 51.01858749900018]],
    [ "Enedis",[4.013633013092313, 50.22353451495262]],
    [ "Enedis",[2.3703968136298275, 51.03645285639408]],
    [ "Enedis",[2.344301174188261, 51.00646391968137]],
    [ "Enedis",[2.3652506528430637, 51.02902277450828]],
    [ "Enedis",[2.3208303784730595, 51.0043640522862]],
    [ "Enedis",[2.3845166015409878, 51.03078026105896]],
    [ "Enedis",[2.421939081195877, 51.05016359079]],
    [ "Enedis",[2.3261367130572483, 51.01338742994051]],
    [ "Enedis",[2.378888359920644, 51.02637316418642]],
    [ "Enedis",[2.36182210763866, 51.01803647890843]],
    [ "Enedis",[2.3743417588049596, 51.03487215457038]],
    [ "Enedis",[2.3855831763554742, 51.04827221356385]],
    [ "Enedis",[2.3558616245695108, 51.01232344615174]],
    [ "Enedis",[2.3818732349139426, 51.04043145486696]],
    [ "Enedis",[2.327219011834279, 51.0160619464619]],
    [ "Enedis",[2.3737756622435664, 51.03961967878772]],
    [ "Enedis",[2.3330112524597464, 51.00635286959653]],
    [ "Enedis",[2.3898803237804627, 51.044728156812255]],
    [ "Enedis",[2.403752105319988, 51.03686299693131]],
    [ "Enedis",[2.4088326497300865, 51.04452313371722]],
    [ "Enedis",[2.431397320948773, 51.05450170804584]],
    [ "Enedis",[2.3643684438788624, 51.047496988918454]],
    [ "Enedis",[2.363128098354333, 51.03041857497069]],
    [ "Enedis",[2.3949469846520066, 51.04609635420989]],
    [ "Enedis",[2.313808827986953, 51.02506315902255]],
    [ "Enedis",[2.3335772479968258, 51.00430332544147]],
    [ "Enedis",[2.3371631856649397, 51.02092523626944]],
    [ "Enedis",[2.3535495883770756, 51.02160432098303]],
    [ "Enedis",[2.3842956230441215, 51.03701449291393]],
    [ "Enedis",[2.3759451565109653, 51.04085731243258]],
    [ "Enedis",[2.4024152666855056, 51.03958694953939]],
    [ "Enedis",[2.3223875589542007, 51.01945514629736]],
    [ "Enedis",[2.4155594587079694, 51.04877743099924]],
    [ "Enedis",[2.3264093659872276, 51.02014424122345]],
    [ "Enedis",[2.3935642912645116, 51.03524825209619]],
    [ "Enedis",[2.3410542737571474, 51.00583287353035]],
    [ "Enedis",[2.4259301219525837, 51.044776143570616]],
    [ "Enedis",[2.3940827509629483, 51.038611855892064]],
    [ "Enedis",[2.347870424417213, 51.02098566440137]],
    [ "Enedis",[2.411669041869884, 51.049467783932634]],
    [ "Enedis",[2.317665673708793, 51.024112991888245]],
    [ "Enedis",[2.4316582494257224, 51.049363883566826]],
    [ "Enedis",[2.3172230868320054, 51.00009076776776]],
    [ "Enedis",[2.347154709863847, 51.00431465862068]],
    [ "Enedis",[2.4290558732320324, 51.04855959858525]],
    [ "Enedis",[2.3363463774959605, 51.006460038165926]],
    [ "Enedis",[2.390983391500878, 51.04690143047055]],
    [ "Enedis",[2.360759595398078, 51.02040175289673]],
    [ "Enedis",[2.3445926083320527, 51.008962816135664]],
    [ "Enedis",[2.4357590761428405, 51.042747657778975]],
    [ "Enedis",[2.433054126569213, 51.05194460307882]],
    [ "Enedis",[2.3349334759552374, 51.02096053693057]],
    [ "Enedis",[2.3487608689098125, 51.00693672113925]],
    [ "Enedis",[2.3905372249636168, 51.03476129400698]],
    [ "Enedis",[2.419771150433419, 51.05013781670069]],
    [ "Enedis",[2.383237043557572, 51.041907464639465]],
    [ "Enedis",[2.3392445357446596, 51.019403670961985]],
    [ "Enedis",[2.3621613079024426, 51.02063402491774]],
    [ "Enedis",[2.3582168841478395, 51.025238450354585]],
    [ "Enedis",[2.396487318123488, 51.04242947972252]],
    [ "Enedis",[2.4063400862449704, 51.03928916476404]],
    [ "Enedis",[2.3215034500000002, 51.022797249999996]],
    [ "Enedis",[2.32305923156263, 51.002517784963224]],
    [ "Enedis",[2.4096081241167213, 51.048762184251174]],
    [ "Enedis",[2.3797630999999986, 51.04588140000001]],
    [ "Enedis",[2.3099400003244726, 51.02431303285185]],
    [ "Enedis",[2.34227995, 51.0156394]],
    [ "Enedis",[2.3779633757114986, 51.03404198678918]],
    [ "Enedis",[2.406543387240782, 51.04941507241066]],
    [ "Enedis",[2.248331032582816, 51.022255651772774]],
    [ "Enedis",[2.4124792220092206, 51.05273398941839]],
    [ "Enedis",[2.37758489588479, 51.04206728424983]],
    [ "Enedis",[2.268071271170836, 50.997114891944975]],
    [ "Enedis",[2.3440411678404818, 51.00737433026937]],
    [ "Enedis",[2.406949601888808, 51.04742424950705]],
    [ "Enedis",[2.377786359701803, 51.036921384573354]],
    [ "Enedis",[2.37192934907458, 51.025818616316684]],
    [ "Enedis",[2.3485950902371635, 51.01508300886663]],
    [ "Enedis",[2.424234547118616, 51.04707926490566]],
    [ "Enedis",[2.351270331902308, 51.01601543331378]],
    [ "Enedis",[2.3659196274961745, 51.0230865478692]],
    [ "Enedis",[2.3308351980893014, 51.0039436820478]],
    [ "Enedis",[2.3278748825265367, 51.01190933357609]],
    [ "Enedis",[2.42471644526606, 51.04888781331952]],
    [ "Enedis",[2.336823619206413, 51.004849267606396]],
    [ "Enedis",[2.3370925902040574, 51.01754695105875]],
    [ "Enedis",[2.374589735621538, 51.02679049892433]],
    [ "Enedis",[2.3803827250721845, 51.04190903200625]],
    [ "Enedis",[2.3804473240854556, 51.02702383616884]],
    [ "Enedis",[2.4181622500000013, 51.04827355000001]],
    [ "Enedis",[2.4274808728141157, 51.05058944425273]],
    [ "Enedis",[2.420447264534017, 51.048571224261174]],
    [ "Enedis",[2.364819183247863, 51.0203261210851]],
    [ "Enedis",[2.3791178614737714, 51.03078146217189]],
    [ "Enedis",[2.3945863499874345, 51.04089898136671]],
    [ "Enedis",[2.3231147500000002, 51.00378615]],
    [ "Enedis",[2.3413653304185997, 51.01838418668065]],
    [ "Enedis",[2.411997069568529, 51.04311125779142]],
    [ "Enedis",[2.3877099436905485, 51.04543954272858]],
    [ "Enedis",[2.3567119372132495, 51.02056971874632]],
    [ "Enedis",[2.3852168361595907, 51.03461592474728]],
    [ "Enedis",[2.351960971208682, 51.004955639190214]],
    [ "Enedis",[2.3809289356712293, 51.03491672873335]],
    [ "Enedis",[2.344210577244094, 51.02030194246006]],
    [ "Enedis",[2.400762840406562, 51.03557409590347]],
    [ "Enedis",[2.4039775943564394, 51.04858190341393]],
    [ "Enedis",[2.35915954984617, 51.02387071843937]],
    [ "Enedis",[2.3762858921469205, 51.02962568593008]],
    [ "Enedis",[2.3363085753413846, 51.01645072737155]],
    [ "Enedis",[2.3973580500000007, 51.03356685]],
    [ "Enedis",[2.4295801060635136, 51.05190536488667]],
    [ "Enedis",[2.3801791468955447, 51.037161075061185]],
    [ "Enedis",[2.3735992230288834, 51.02901797781198]],
    [ "Enedis",[2.3387985, 51.00656845]],
    [ "Enedis",[2.4006544306140594, 51.034213557041554]],
    [ "Enedis",[2.3233102508684524, 51.01708026151773]],
    [ "Enedis",[2.398681791568015, 51.037583452337664]],
    [ "Enedis",[2.3685431340368712, 51.015551850908054]],
    [ "Enedis",[2.3440639305273354, 51.00731214770692]],
    [ "Enedis",[2.3678473999999996, 51.035489399999996]],
    [ "Enedis",[2.3636459742487523, 51.02973907640964]],
    [ "Enedis",[2.390189911581157, 51.03183284939356]],
    [ "Enedis",[2.383216042803623, 51.0398350589409]],
    [ "Enedis",[2.3994499237505877, 51.04063594258065]],
    [ "Enedis",[2.3652664658694174, 51.032408924567974]],
    [ "Enedis",[2.371609253229221, 51.04161527984642]],
    [ "Enedis",[2.4009818099646933, 51.044377380724]],
    [ "Enedis",[2.3935612073571626, 51.04520080897672]],
    [ "Enedis",[2.330685938489642, 51.01995761074534]],
    [ "Enedis",[2.3313818691149417, 51.011905411317386]],
    [ "Enedis",[2.421022062706135, 51.0466084816214]],
    [ "Enedis",[2.3372379437564117, 51.020933750759426]],
    [ "Enedis",[2.390874369063535, 51.038271503278814]],
    [ "Enedis",[2.3794828019885492, 51.02839321657875]],
    [ "Enedis",[2.3286118840225507, 51.006436279152325]],
    [ "Enedis",[2.3438903, 51.0054926]],
    [ "Enedis",[2.4163014642341967, 51.05066265088077]],
    [ "Enedis",[2.359372700990449, 51.02548960197976]],
    [ "Enedis",[2.363233091082335, 51.023882265430316]],
    [ "Enedis",[2.4086905515068717, 51.051589768233775]],
    [ "Enedis",[2.2497089620006463, 51.0193357134509]],
    [ "Enedis",[2.397123639455479, 51.03683378483226]],
    [ "Enedis",[2.345711438804316, 51.01910516026136]],
    [ "Enedis",[2.4348515746661117, 51.04792063699606]],
    [ "Enedis",[2.3866063886168285, 51.03251147585961]],
    [ "Enedis",[2.4451565111982005, 51.04662537062039]],
    [ "Enedis",[2.3389692347509623, 51.00242740873579]],
    [ "Enedis",[2.376569623889294, 51.04406689544933]],
    [ "Enedis",[2.3471130825485127, 51.02313197009098]],
    [ "Enedis",[2.329169322934705, 51.01750515230115]],
    [ "Enedis",[2.345017124933963, 51.01666189851913]],
    [ "Enedis",[2.4039701042129153, 51.04011639804154]],
    [ "Enedis",[2.362599887265533, 51.01678585001321]],
    [ "Enedis",[2.3364533301839683, 51.00554734745447]],
    [ "Enedis",[1.4762887576902604, 43.60959988302288]],
    [ "Enedis",[1.4817815114180959, 43.611341100483784]],
    [ "Enedis",[1.4722014629568216, 43.607949275827096]],
    [ "Enedis",[1.4770976950981125, 43.605485137663734]],
    [ "Enedis",[1.4791465466083316, 43.61093823336837]],
    [ "Enedis",[1.4584398748367147, 43.60922822514741]],
    [ "Enedis",[1.4679932167894478, 43.6075376670922]],
    [ "Enedis",[1.478374561394404, 43.608661613951796]],
    [ "Enedis",[1.4565669502344232, 43.6087880414345]],
    [ "Enedis",[1.4599935, 43.60652999999999]],
    [ "Enedis",[1.4800282960300049, 43.60999931761216]],
    [ "Enedis",[1.48143530601358, 43.60609133312652]],
    [ "Enedis",[1.4829143069489354, 43.60571248562103]],
    [ "Enedis",[-1.2532237000000002, 47.06511175]],
    [ "Enedis",[-1.250624856154824, 47.06280388069428]],
    [ "Enedis",[-1.2551164666523802, 47.06668384958481]],
    [ "Enedis",[-1.2555051301760638, 47.05546652930006]],
    [ "Enedis",[-1.2551307465538086, 47.059253768399834]],
    [ "Enedis",[-1.2578452436102234, 47.06188748340449]],
    [ "Enedis",[-1.2474198499999996, 47.06527865]],
    [ "Enedis",[-1.2526809500000002, 47.05607485]],
    [ "Enedis",[-1.2633481000000009, 47.06450595]],
    [ "Enedis",[-1.2419885999999993, 47.06573780000001]],
    [ "Geredis",[-0.1861995612077405, 46.38937318459323]],
    [ "Enedis",[-1.2548607981010351, 47.0632094667513]],
    [ "Geredis",[-0.1750368196049646, 46.412012228094405]],
    [ "Geredis",[-0.16872326479572536, 46.41055266889228]],
    [ "Enedis",[3.84139232659471, 43.59740581701992]],
    [ "Enedis",[1.4828377003392266, 43.60097308335661]],
    [ "Enedis",[1.4790267202089153, 43.602503572811116]],
    [ "Enedis",[1.47756235, 43.59655305]],
    [ "Enedis",[1.4802043234129405, 43.60386177750373]],
    [ "Enedis",[1.4862911324888368, 43.60079878865565]],
    [ "Enedis",[1.4767944667179258, 43.603484393858885]],
    [ "Enedis",[1.4777639431294982, 43.58523859085214]],
    [ "Enedis",[1.4829732874216164, 43.585960384251976]],
    [ "Enedis",[1.4876684392437727, 43.59450052987679]],
    [ "Enedis",[1.4807326139407522, 43.59219268431666]],
    [ "Enedis",[1.479544401338372, 43.587940490590846]],
    [ "Enedis",[1.4832588366944999, 43.587341422367366]],
    [ "Enedis",[1.48328441898464, 43.593334248457005]],
    [ "Enedis",[1.4822788005398728, 43.587049202280646]],
    [ "Enedis",[1.487734082117406, 43.597481232952525]],
    [ "Enedis",[1.4817620662378883, 43.60373614970118]],
    [ "Enedis",[1.4759931870567948, 43.58864205876708]],
    [ "Enedis",[1.4815517920352868, 43.59487308674409]],
    [ "Enedis",[1.4827000030515625, 43.59019731609231]],
    [ "Enedis",[1.4844299582536125, 43.59542261216983]],
    [ "Enedis",[1.4816253319780701, 43.589163416476744]],
    [ "Enedis",[1.481190087818781, 43.58767288057121]],
    [ "Enedis",[1.4748147072975777, 43.59285731794208]],
    [ "Enedis",[1.4822958406702038, 43.5881544700911]],
    [ "Enedis",[1.4829525638224208, 43.59071121856754]],
    [ "Enedis",[-1.0208832999999993, 46.87857415]],
    [ "Enedis",[-1.0277172500000005, 46.867532999999995]],
    [ "Enedis",[-1.0095994324630804, 46.86588595262863]],
    [ "Enedis",[-1.004653451235302, 46.8508604332903]],
    [ "Enedis",[-1.00969577807591, 46.85820971509521]],
    [ "Enedis",[-1.0636660620515188, 46.87501052267422]],
    [ "Enedis",[-1.0119510056081036, 46.85933038197527]],
    [ "Enedis",[-1.00158089538409, 46.85401898293225]],
    [ "Enedis",[-1.0068830583202752, 46.852426376167905]],
    [ "Enedis",[-1.07274505, 46.847837150000004]],
    [ "Enedis",[-1.001574420336722, 46.85244780507966]],
    [ "Enedis",[-1.003732770815422, 46.84766670502881]],
    [ "Enedis",[-1.021919741553208, 46.87346671857649]],
    [ "Enedis",[-1.02894285, 46.8625135]],
    [ "Enedis",[-1.0272448500000007, 46.859246549999995]],
    [ "Enedis",[-1.0139878508286357, 46.861487233419524]],
    [ "Enedis",[-1.0179797, 46.856592]],
    [ "Enedis",[-1.0370107819242926, 46.89746690379464]],
    [ "Enedis",[-0.9946395500000005, 46.86908164999999]],
    [ "Enedis",[-1.0113202893308384, 46.8654704206676]],
    [ "Enedis",[-1.0155578042315123, 46.86357033293876]],
    [ "Enedis",[-1.0179043620255017, 46.87153422116961]],
    [ "Enedis",[-1.0814716387896965, 46.88098056836888]],
    [ "Enedis",[-1.0393589500000002, 46.86691609999999]],
    [ "Enedis",[-1.0193859, 46.858787400000004]],
    [ "Enedis",[2.9203262049130645, 50.548896019405355]],
    [ "Enedis",[2.9232854265045347, 50.55085779019791]],
    [ "Enedis",[2.9185814925202833, 50.551198901809244]],
    [ "Enedis",[2.9235198953171193, 50.54826451721871]],
    [ "Enedis",[2.914031600493124, 50.54784398333425]],
    [ "Enedis",[3.1435609264445645, 50.67007383617679]],
    [ "Enedis",[3.1420626235114786, 50.66684042042641]],
    [ "Enedis",[3.3200252999999997, 46.55298315]],
    [ "Les Sauldres",[3.315042587756949, 46.55467855946641]],
    [ "Enedis",[-0.9050497542836721, 46.88271222189791]],
    [ "Enedis",[-0.9376937999271011, 46.89418733332403]],
    [ "Enedis",[3.1445066105943553, 50.65425584928267]],
    [ "Enedis",[3.1419243826132095, 50.6594693889051]],
    [ "Enedis",[3.137073926622384, 50.658759565383434]],
    [ "Enedis",[3.1454028169665404, 50.65278744704862]],
    [ "Enedis",[3.1445888406105555, 50.65633585328389]],
    [ "Enedis",[3.1439923021771152, 50.66154861965657]],
    [ "Enedis",[3.1420743994305993, 50.65146866645059]],
    [ "Enedis",[3.137862816659854, 50.65519385014959]],
    [ "Enedis",[3.936540800426505, 50.128379022396594]],
    [ "Enedis",[3.311688630073534, 46.56911418715108]],
    [ "Enedis",[3.3175567510298696, 46.56036377597138]],
    [ "Enedis",[3.3153244769876973, 46.56527042556596]],
    [ "Demilune",[3.3126736815478424, 46.558508572246694]],
    [ "Enedis",[3.3086123212533067, 46.55765136436217]],
    [ "Enedis",[3.3083907771747674, 46.564267098062935]],
    [ "Enedis",[3.307748283371898, 46.56068383738935]],
    [ "Enedis",[3.286142735965035, 46.55760612278848]],
    [ "Enedis",[-0.9041748238981122, 46.95687149431701]],
    [ "Enedis",[-0.9101147103469497, 46.95593692235615]],
    [ "Enedis",[-0.8934138884572701, 46.95371921014921]],
    [ "Enedis",[-0.8844367, 46.963929650000004]],
    [ "Enedis",[-0.8865964509744604, 46.962385547803834]],
    [ "Enedis",[-0.8959035495455537, 46.9570269860014]],
    [ "Enedis",[-0.897909978698984, 46.960066381321795]],
    [ "Enedis",[-0.8953968252475308, 46.95902321137115]],
    [ "Enedis",[-0.8961215546980169, 46.95482328807128]],
    [ "Enedis",[-0.8967468059696061, 46.94195927588867]],
    [ "Enedis",[-0.892710552980277, 46.957444434956166]],
    [ "Enedis",[-0.8806124166516919, 46.95519664940237]],
    [ "Enedis",[-0.8905999060102473, 46.959688064934056]],
    [ "Enedis",[1.2719605773945941, 43.610602161962476]],
    [ "Enedis",[1.2721320699133587, 43.60900272393574]],
    [ "Enedis",[1.2878678752465853, 43.616532897063585]],
    [ "Enedis",[1.2751349726798742, 43.61481729434527]],
    [ "Enedis",[1.265564997812411, 43.60759532824803]],
    [ "Enedis",[1.2744392610183, 43.61346441064966]],
    [ "Enedis",[-1.913712417252281, 46.78446530196]],
    [ "Enedis",[1.2898226819539111, 43.61471250827653]],
    [ "Enedis",[1.2875357382339796, 43.61333124368251]],
    [ "Enedis",[1.2552424629050984, 43.604666135342086]],
    [ "Enedis",[1.2666029539321828, 43.61753115642805]],
    [ "Enedis",[1.2686745087989337, 43.61549512989401]],
    [ "Enedis",[1.2807954821741387, 43.61430331682745]],
    [ "Enedis",[1.2616494466153665, 43.60679367682742]],
    [ "Enedis",[1.2832641162777143, 43.61934365909158]],
    [ "Enedis",[1.26584005, 43.61069035]],
    [ "Enedis",[3.332768909351684, 46.55709530586141]],
    [ "Enedis",[1.263417280320271, 43.61406166177181]],
    [ "Enedis",[3.328667375091599, 46.558490951797424]],
    [ "Enedis",[1.292439036048796, 43.61673335219942]],
    [ "Enedis",[1.2811801244661027, 43.609876227379935]],
    [ "Enedis",[1.2869268914180987, 43.61048144490807]],
    [ "Enedis",[1.2745256411391475, 43.60986118003351]],
    [ "Enedis",[1.2791732356832297, 43.61657125418376]],
    [ "Enedis",[3.333998084911673, 46.557813246489275]],
    [ "Enedis",[1.2633675958728334, 43.6171569075553]],
    [ "Enedis",[1.283461832797867, 43.61646029489803]],
    [ "Enedis",[1.2810326398338001, 43.61774755431396]],
    [ "Enedis",[1.2680518047843135, 43.60951979529411]],
    [ "Enedis",[1.279453438518945, 43.61955746069969]],
    [ "Enedis",[1.2720534300174515, 43.61490637486911]],
    [ "Enedis",[1.2675948133479036, 43.61121519858154]],
    [ "Enedis",[1.2927100511970921, 43.62008895246219]],
    [ "Enedis",[1.2800305752206902, 43.61263773741675]],
    [ "Enedis",[1.2820832553091255, 43.60841767926347]],
    [ "Enedis",[2.220198894904457, 48.91733312165605]],
    [ "Enedis",[2.2205559213004036, 48.9186579859465]],
    [ "Enedis",[4.8068660236163865, 45.71536554373342]],
    [ "Enedis",[3.154692284532479, 50.65787833314886]],
    [ "Enedis",[3.156094950965893, 50.65352345516442]],
    [ "Enedis",[3.1668643922409316, 50.65088284451318]],
    [ "Enedis",[3.154827944035111, 50.65028406954328]],
    [ "Enedis",[3.1222441103319345, 50.65159191786613]],
    [ "Enedis",[3.1338100340642123, 50.649767106708424]],
    [ "Enedis",[3.130303832030869, 50.65032096624486]],
    [ "Enedis",[3.12845065, 50.6494]],
    [ "Enedis",[3.1245857992528143, 50.64946205561717]],
    [ "Enedis",[3.1284939090218695, 50.647478958791]],
    [ "Enedis",[3.1201688103688148, 50.64992268102584]],
    [ "Enedis",[3.1270884452690093, 50.64772574534516]],
    [ "Enedis",[3.1300916105570122, 50.650434452498686]],
    [ "Enedis",[3.1640916676201316, 50.643597647133575]],
    [ "Enedis",[3.159854462971985, 50.64438056946814]],
    [ "Enedis",[3.1630172025512637, 50.64502113209285]],
    [ "Enedis",[3.9315465340260842, 50.12709555197092]],
    [ "Enedis",[-1.1007612, 47.00593585]],
    [ "Enedis",[-1.04819645, 46.995259850000004]],
    [ "Enedis",[-1.0646300187123254, 47.00269449434654]],
    [ "Enedis",[-0.9018927499999999, 46.9254359]],
    [ "Enedis",[-0.9025817662794536, 46.929890149443025]],
    [ "Enedis",[-0.8998735666579902, 46.934561850588544]],
    [ "Enedis",[-0.8787096499999999, 46.9220327]],
    [ "Enedis",[-1.1853004999999999, 46.90744435]],
    [ "Enedis",[-1.2500848500000001, 46.899590100000005]],
    [ "Enedis",[-1.1961697499999993, 46.88319749999999]],
    [ "Enedis",[-1.250621628522793, 46.891643247197166]],
    [ "Enedis",[-1.247699433767051, 46.89352013244801]],
    [ "Enedis",[-1.2469573327970185, 46.89941539930007]],
    [ "Enedis",[-1.24381865, 46.897138999999996]],
    [ "Enedis",[-1.2542892150454859, 46.89291094572814]],
    [ "Enedis",[-1.25406475, 46.8866539]],
    [ "Enedis",[-1.2409435000000002, 46.9149442]],
    [ "Enedis",[-1.2506117842136533, 46.89403642871583]],
    [ "Enedis",[-1.2455653500000001, 46.88558975]],
    [ "Enedis",[-1.0664977, 46.9703176]],
    [ "Enedis",[-1.0486747499999993, 46.972211650000006]],
    [ "Enedis",[-1.0550119272386218, 46.973868105103705]],
    [ "Enedis",[-1.06722495, 46.986431249999995]],
    [ "Enedis",[-1.11872295, 46.92122015]],
    [ "Enedis",[-1.09341095, 46.9076102]],
    [ "Enedis",[-1.0753186000000001, 46.900985150000004]],
    [ "Enedis",[-1.109068266888322, 46.90520119869666]],
    [ "Enedis",[-1.0908601500000001, 46.913580950000004]],
    [ "Enedis",[-1.0876568574882473, 46.909887880837246]],
    [ "Enedis",[-1.082580526032535, 46.9047094456398]],
    [ "Enedis",[-1.1006588754632476, 46.917122244273926]],
    [ "Enedis",[-1.13203985, 46.93571304999999]],
    [ "Enedis",[-1.13733975, 46.9089491]],
    [ "Enedis",[-1.135956200000001, 46.90681840000001]],
    [ "Enedis",[-1.8894352617606847, 46.7922837266828]],
    [ "Enedis",[-1.1402929820346237, 46.907525146710455]],
    [ "Enedis",[-1.8786128500000001, 46.78205005]],
    [ "Enedis",[-1.888128911266914, 46.79091572866562]],
    [ "Enedis",[-1.8587101500000003, 46.79190935]],
    [ "Enedis",[-1.8834336999999985, 46.7917267]],
    [ "Enedis",[5.341695429960307, 46.28359004605995]],
    [ "Enedis",[5.329781451900594, 46.27384615951299]],
    [ "Enedis",[6.3317029499999995, 46.847975950000006]],
    [ "Enedis",[6.33181143950967, 46.8539384226078]],
    [ "Enedis",[6.34017268305873, 46.85507029897924]],
    [ "Enedis",[6.330509799999999, 46.85049804999999]],
    [ "Enedis",[3.922348969046159, 50.122194995064994]],
    [ "Enedis",[-1.2276849371660425, 46.95597457489692]],
    [ "Enedis",[-1.2368248706033245, 46.976475184391994]],
    [ "Enedis",[-1.2530176, 46.959637]],
    [ "Enedis",[-1.2562952144664647, 46.985527918290096]],
    [ "Enedis",[-1.2583797819926101, 46.963663153604706]],
    [ "Enedis",[-0.9334268000000001, 46.982502000000004]],
    [ "Enedis",[-0.9385876500000002, 46.99250600000001]],
    [ "Enedis",[-0.9404372499999991, 46.974529849999996]],
    [ "Enedis",[-0.9489827158332111, 46.985429769410835]],
    [ "Enedis",[-0.9552053, 46.99197075]],
    [ "Enedis",[-0.9477023, 46.996187150000004]],
    [ "Enedis",[-0.9527074307425926, 46.98559374598151]],
    [ "Enedis",[-0.9306487988406148, 46.9805431331879]],
    [ "Enedis",[-0.9533211250568553, 46.99666844433615]],
    [ "Enedis",[-0.9530584690741647, 46.99905469593462]],
    [ "Enedis",[-0.9513413000000001, 46.990697399999995]],
    [ "Enedis",[-0.9393723273009332, 46.98851053507899]],
    [ "Enedis",[-0.9320973, 46.98661335]],
    [ "Enedis",[-0.9523434, 46.9892267]],
    [ "Enedis",[-0.9507505500000001, 47.002499199999995]],
    [ "Enedis",[-0.9512735999999999, 46.98682015000001]],
    [ "Enedis",[-0.9510403500000004, 46.992233799999994]],
    [ "Enedis",[-0.9757971, 46.99916150000001]],
    [ "Enedis",[-0.9588550999999992, 46.9861606]],
    [ "Enedis",[-0.9488666499999999, 46.998936199999996]],
    [ "Enedis",[-0.9372453500000001, 46.975534849999995]],
    [ "Enedis",[-0.9464867000000001, 46.9915547]],
    [ "Enedis",[-0.94889145, 46.98975219999999]],
    [ "Enedis",[-0.95305535, 46.992864250000004]],
    [ "Enedis",[-0.9478074500000001, 46.99356554999999]],
    [ "Enedis",[-0.9474937999999999, 46.98639515]],
    [ "Enedis",[-0.9322646499999991, 46.984336449999994]],
    [ "Enedis",[-0.9902722604478453, 46.96079362058229]],
    [ "Enedis",[-1.00295765, 46.96040155]],
    [ "Enedis",[-0.97070155, 46.97303230000001]],
    [ "Enedis",[-0.9935680819219364, 46.96114505335309]],
    [ "Enedis",[-0.9955468174866327, 46.95783574770514]],
    [ "Enedis",[-0.966851693887748, 46.97824631916403]],
    [ "Enedis",[-1.0010694747267699, 46.96128185546476]],
    [ "Enedis",[-0.9951434234369521, 46.96225369507063]],
    [ "Enedis",[-0.9649474341169355, 46.97796351943015]],
    [ "Enedis",[-0.9990115331287405, 46.9549039523959]],
    [ "Enedis",[-0.9977382795523385, 46.959571334298836]],
    [ "Enedis",[-0.9961646835764025, 46.9555142510533]],
    [ "Enedis",[-1.004832032315463, 46.95349250173583]],
    [ "Enedis",[-1.0024687018242013, 46.96505818330338]],
    [ "Enedis",[-0.9697095499999999, 46.974932749999994]],
    [ "Enedis",[-0.9933282814777508, 46.957307867127916]],
    [ "Enedis",[-0.99953405, 46.96574295]],
    [ "Enedis",[-1.0051800500000008, 46.96154715000001]],
    [ "Enedis",[-0.8639308499999993, 46.910751949999984]],
    [ "Enedis",[-0.9844415338039313, 46.92879484439436]],
    [ "Enedis",[-0.96881145, 46.9196162]],
    [ "Enedis",[-0.9558639281248568, 46.918083744838036]],
    [ "Enedis",[-1.0734197889741115, 46.94123293258768]],
    [ "Enedis",[-1.08862875, 46.9339093]],
    [ "Enedis",[-1.0734482500000009, 46.937402150000004]],
    [ "Enedis",[-1.0911841204465225, 46.9554114545334]],
    [ "Enedis",[-1.0748760389762597, 46.94323337515443]],
    [ "Enedis",[-1.0643327499999997, 46.94617385]],
    [ "Enedis",[-1.0640865499999999, 46.939919599999996]],
    [ "Enedis",[-1.1163224027855059, 46.96640773293204]],
    [ "Enedis",[-1.1237490499999998, 46.96374535]],
    [ "Enedis",[-1.124999340676272, 46.99328572369532]],
    [ "Enedis",[-1.1235776389312637, 46.952273820945926]],
    [ "Enedis",[-1.1234054314565591, 46.962427246319265]],
    [ "Enedis",[-1.1197863743536483, 46.95971720557517]],
    [ "Enedis",[0.36577210154878176, 46.67446332570502]],
    [ "Enedis",[0.3865436433566934, 46.69149870139922]],
    [ "Enedis",[0.3698952704178543, 46.67755218455843]],
    [ "Enedis",[0.3741091358702368, 46.69187337612845]],
    [ "Enedis",[0.3521528578647963, 46.7101308460643]],
    [ "Enedis",[-1.2200146910025713, 46.98520901945159]],
    [ "Enedis",[-1.2424962316995856, 47.0089990034382]],
    [ "Enedis",[-1.2309574, 46.982796199999996]],
    [ "Enedis",[-1.1817349568241957, 46.97268088260341]],
    [ "Enedis",[0.370252666607731, 46.715418295769425]],
    [ "Enedis",[-1.2297198726151295, 46.985616834883544]],
    [ "Enedis",[-1.2244319000000001, 46.98080295]],
    [ "Enedis",[-1.2216592499999999, 46.98843170000001]],
    [ "Enedis",[-1.2319170870715046, 46.99029291925817]],
    [ "Enedis",[0.3701836471102475, 46.691502449571]],
    [ "Enedis",[-1.2250136999999999, 46.98719280000001]],
    [ "Enedis",[0.31150313190259704, 46.68744023093209]],
    [ "Enedis",[0.3854687758871729, 46.71034080065486]],
    [ "Enedis",[-1.2295463500000003, 46.984291999999996]],
    [ "Enedis",[-1.2207724, 46.98114354999999]],
    [ "Enedis",[-1.2357581, 46.98444045000001]],
    [ "Enedis",[1.4644416384393009, 43.57783916745012]],
    [ "Enedis",[1.46087735, 43.57522945]],
    [ "Enedis",[1.46173648690825, 43.57606594438481]],
    [ "Enedis",[1.4637373499999997, 43.573340099999996]],
    [ "Enedis",[1.4612193298567153, 43.57707950951689]],
    [ "Enedis",[3.936529878638772, 50.1276099628378]],
    [ "Enedis",[2.16755645, 48.682104200000005]],
    [ "Enedis",[3.92752639016042, 50.12056352993404]],
    [ "Enedis",[3.9317368133126758, 50.12231445533035]],
    [ "Enedis",[3.9284630313949385, 50.12223079043703]],
    [ "Enedis",[2.162752627790696, 48.68019985050735]],
    [ "Enedis",[3.861221192573729, 47.81558613260459]],
    [ "Enedis",[3.861988341110583, 47.81427208024302]],
    [ "FC Chanzy 6",[2.2353884085976055, 48.994328497783535]],
    [ "Enedis",[2.2351451827588202, 48.996763114111545]],
    [ "FC Jules César 77",[2.2364792974754875, 48.99642573546451]],
    [ "Enedis",[2.234398701855038, 48.99103647544079]],
    [ "Enedis",[1.2624289027747964, 43.599411332421475]],
    [ "Enedis",[3.141410981207981, 50.64284187251709]],
    [ "Enedis",[3.140325688120552, 50.64273187258637]],
    [ "Enedis",[3.13542119239922, 50.64245408245858]],
    [ "Enedis",[3.1438216390153166, 50.642463112166226]],
    [ "Enedis",[3.1385703058486007, 50.64280036035949]],
    [ "Enedis",[3.1199619211578167, 50.6397605444333]],
    [ "Enedis",[3.1200038323833117, 50.63735222667147]],
    [ "Enedis",[3.1210397958970657, 50.641395939819844]],
    [ "Enedis",[3.1210858192446973, 50.64161743152628]],
    [ "Enedis",[3.1259321347417464, 50.6436552635974]],
    [ "Enedis",[3.148115638677413, 50.63979193098456]],
    [ "Enedis",[3.1572798170552363, 50.64181100111169]],
    [ "Enedis",[3.149283900573695, 50.63824218693764]],
    [ "Enedis",[3.152629867766112, 50.64630334441616]],
    [ "Enedis",[3.1462720413216396, 50.63827474405027]],
    [ "Enedis",[3.157460938927621, 50.64063597131908]],
    [ "Enedis",[3.1589937386199933, 50.6375368608095]],
    [ "Enedis",[3.1535957000000003, 50.64007844999999]],
    [ "Enedis",[3.1491692059697627, 50.638470494941416]],
    [ "Enedis",[3.1569130867090505, 50.645835287723884]],
    [ "Enedis",[3.149295898168825, 50.63917547237183]],
    [ "Enedis",[3.1464371071295063, 50.63987226497479]],
    [ "Enedis",[3.1523471006916446, 50.64071200370227]],
    [ "Enedis",[3.1553899392119837, 50.6413221167787]],
    [ "Enedis",[3.323910982978287, 46.55762317109726]],
    [ "Enedis",[-0.11535304559601202, 46.36017238243909]],
    [ "Remparts",[-0.11132215000000001, 46.35953955000001]],
    [ "Enedis",[-0.1097924968054556, 46.36413847855676]],
    [ "Enedis",[-0.11552311036400396, 46.35691304433613]],
    [ "Enedis",[-0.11846674933007437, 46.35613548451023]],
    [ "Enedis",[3.9521560891254746, 50.09116751611534]],
    [ "Enedis",[3.1613578594362823, 50.63448122986107]],
    [ "Enedis",[3.1569420008807314, 50.62912609961518]],
    [ "Enedis",[3.1707872917647504, 50.62890328275114]],
    [ "Enedis",[3.1565751253171874, 50.632482568892264]],
    [ "Enedis",[3.158655380131684, 50.63354776611909]],
    [ "Enedis",[3.149902112378303, 50.6308451594776]],
    [ "Enedis",[3.1635738000000004, 50.63456235000001]],
    [ "Enedis",[3.168686003844728, 50.63320578288443]],
    [ "Geredis",[-0.010132070478051949, 46.26051115292776]],
    [ "Gérédis",[-0.005522225092630176, 46.26437929530237]],
    [ "Geredis",[-0.0030004950165088183, 46.266260484683094]],
    [ "Geredis",[-0.010161293666522558, 46.26465688875956]],
    [ "Anne de Beaujeu",[3.3290079636860734, 46.566914548495184]],
    [ "Enedis",[3.3339418196278783, 46.56347095153427]],
    [ "Enedis",[2.474729296830317, 50.61495656695664]],
    [ "Enedis",[2.468881059220818, 50.61728148192003]],
    [ "Enedis",[2.478443910235282, 50.60974369997314]],
    [ "Enedis",[2.457523160728458, 50.62425089751684]],
    [ "Enedis",[2.4444268946231538, 50.62639708040117]],
    [ "Enedis",[2.4617962872400656, 50.61237869836013]],
    [ "Enedis",[2.4561161350445606, 50.61816477834869]],
    [ "Enedis",[2.47036431673682, 50.61951428625123]],
    [ "Enedis",[2.4730358726916783, 50.60719944241292]],
    [ "Enedis",[2.4655416334900604, 50.609186798099685]],
    [ "Enedis",[2.4446290516256592, 50.61053684132472]],
    [ "Poste électrique d’Isbergues (Ugine Aciers)",[2.4749099261048717, 50.623761887717066]],
    [ "TOURNESOL",[2.172153426403439, 48.68364030655622]],
    [ "Enedis",[2.1659735854821407, 48.682937517924785]],
    [ "Enedis",[2.1731328343448366, 48.68597419876286]],
    [ "Enedis",[2.455108870692424, 50.625217838022266]],
    [ "Enedis",[2.1709996, 48.68444890000001]],
    [ "Enedis",[2.4754349671332854, 50.60766221535654]],
    [ "Enedis",[2.4508897952879387, 50.611613286322246]],
    [ "Enedis",[2.4481102553896488, 50.625204939243076]],
    [ "Enedis",[2.4700186341588486, 50.60697519653026]],
    [ "Enedis",[2.4618164277289836, 50.61020197933919]],
    [ "Enedis",[2.4714715855090525, 50.608662254074446]],
    [ "Enedis",[2.457824439114847, 50.618917321830196]],
    [ "Enedis",[1.4204300574234292, 43.58683040833682]],
    [ "Enedis",[3.1317241287820132, 50.63441092813682]],
    [ "Enedis",[3.135173308363579, 50.63829188869633]],
    [ "Enedis",[3.13121183542472, 50.64181095619492]],
    [ "Enedis",[3.138856441647631, 50.63693302146824]],
    [ "77427573",[-1.4248833718901275, 46.968402784592364]],
    [ "Enedis",[-1.4409753515189199, 46.97158880809758]],
    [ "Enedis",[3.130241014282465, 50.634655756488826]],
    [ "Enedis",[3.1246097155910593, 50.63524685522876]],
    [ "Enedis",[3.122104770302074, 50.63239979701548]],
    [ "Enedis",[3.1262673424880383, 50.63537948858053]],
    [ "Enedis",[3.127002089792143, 50.63224918471827]],
    [ "Enedis",[3.1235139467349415, 50.63758975709427]],
    [ "Enedis",[3.1241280573368098, 50.63370107699991]],
    [ "Enedis",[3.1260604586665597, 50.631251511397295]],
    [ "Enedis",[3.122177492276919, 50.63238609018673]],
    [ "Enedis",[1.8136126723141848, 48.787011732530495]],
    [ "Enedis",[1.8204396504711058, 48.78806354059036]],
    [ "Enedis",[3.1292190879652355, 50.6253572313186]],
    [ "Enedis",[3.133398403023914, 50.63197003676095]],
    [ "Enedis",[3.1295643008158653, 50.62594802542105]],
    [ "Enedis",[3.1294014212738337, 50.63043379921738]],
    [ "Enedis",[3.1321962881671888, 50.62969978035927]],
    [ "Enedis",[3.0758557079801565, 50.61845254755994]],
    [ "Enedis",[3.072861021513331, 50.62243515587574]],
    [ "Enedis",[3.0765823077006638, 50.617146679414525]],
    [ "Enedis",[3.074569286773066, 50.61655784004195]],
    [ "Enedis",[3.075407186672215, 50.62190404814914]],
    [ "Enedis",[3.068562782371648, 50.622923295460666]],
    [ "Enedis",[2.23352535, 48.9266614]],
    [ "Enedis",[2.240083265263995, 48.92911553870416]],
    [ "Enedis",[3.1551417500000007, 50.718710900000005]],
    [ "Enedis",[3.1376213912776603, 50.72337866617839]],
    [ "Enedis",[3.1474216265163384, 50.71916196828109]],
    [ "Enedis",[3.135311211913376, 50.73691194916366]],
    [ "Enedis",[3.1614063286665264, 50.73023562114149]],
    [ "Enedis",[3.148691333534706, 50.69568693529981]],
    [ "Enedis",[3.148009892018654, 50.72845432573074]],
    [ "Enedis",[3.1758303429568975, 50.716543974713005]],
    [ "Enedis",[3.139340549550374, 50.7237449760732]],
    [ "Enedis",[3.1583494500000007, 50.73163364999999]],
    [ "Enedis",[3.135253011791713, 50.729712217042646]],
    [ "Enedis",[3.1561769025553716, 50.70576113981001]],
    [ "Enedis",[3.1475820733817303, 50.738146750937695]],
    [ "Enedis",[3.130834741948026, 50.72883553203738]],
    [ "Enedis",[3.1470938711207816, 50.73149656267783]],
    [ "Enedis",[3.165396306749905, 50.72902346637737]],
    [ "Enedis",[3.1494333297671058, 50.72005850970126]],
    [ "Enedis",[3.167118275440835, 50.71588611838443]],
    [ "Enedis",[3.159313532036177, 50.72821182065136]],
    [ "Enedis",[3.165447290624808, 50.73111243780124]],
    [ "Enedis",[3.136436821754306, 50.72218106264511]],
    [ "Enedis",[3.153433419405914, 50.69503487672172]],
    [ "Enedis",[3.168267246433851, 50.73240875683411]],
    [ "Enedis",[3.1814621067301996, 50.7295541091314]],
    [ "Enedis",[3.1909867452414438, 50.73724738273522]],
    [ "Enedis",[3.142624741502877, 50.73720283380256]],
    [ "Enedis",[3.134370658154717, 50.724141004817795]],
    [ "Enedis",[3.1352524003922766, 50.726361650573125]],
    [ "Enedis",[3.17413775843654, 50.73123114962671]],
    [ "Enedis",[3.1534863414132523, 50.7370637150993]],
    [ "Enedis",[3.1314407546879415, 50.73304754661332]],
    [ "Enedis",[3.152539580399775, 50.701889893888975]],
    [ "Enedis",[3.137835586796429, 50.72456476749394]],
    [ "Enedis",[3.1404233729917257, 50.73933131196288]],
    [ "Enedis",[3.1566193041660355, 50.718343110763755]],
    [ "Enedis",[3.137317149728668, 50.735925840822624]],
    [ "Enedis",[3.1643944340854127, 50.72745099578747]],
    [ "Enedis",[3.1890553930100696, 50.72801053481777]],
    [ "Enedis",[3.1543429071755757, 50.73671728463398]],
    [ "Enedis",[3.1331760626651532, 50.72860665959226]],
    [ "Enedis",[3.159384361178716, 50.71741761751048]],
    [ "Enedis",[3.1700106924492695, 50.734349011201566]],
    [ "Enedis",[3.1358198860454256, 50.73317669231682]],
    [ "Enedis",[3.147498535108673, 50.74081867716807]],
    [ "Enedis",[3.1726253214871534, 50.71460986947838]],
    [ "Enedis",[3.1528591440985383, 50.71844529688685]],
    [ "Enedis",[3.1636446032056287, 50.74018999443801]],
    [ "Enedis",[3.1300522398492467, 50.73201530699272]],
    [ "Enedis",[3.163742375266284, 50.73362658892447]],
    [ "Enedis",[3.18002176069048, 50.72770764714293]],
    [ "Enedis",[3.162341226518071, 50.732456294992296]],
    [ "Enedis",[3.160027638425059, 50.74029867230495]],
    [ "Enedis",[3.1557615860002635, 50.734253932064426]],
    [ "Enedis",[3.147970648294753, 50.71829977827623]],
    [ "Enedis",[3.1500368313436016, 50.69348474755103]],
    [ "Enedis",[3.1507509036004047, 50.695176789275635]],
    [ "Enedis",[3.1584406000000005, 50.735786399999995]],
    [ "Enedis",[3.1744346681758064, 50.72846871224434]],
    [ "Enedis",[3.154699521502615, 50.69648627530269]],
    [ "Enedis",[3.1536235962963373, 50.71579577249137]],
    [ "Enedis",[3.1729704764518125, 50.731458980520216]],
    [ "Enedis",[3.1588223287321155, 50.73929218455844]],
    [ "Enedis",[3.1342230143504115, 50.73258674562837]],
    [ "Enedis",[3.1465609028181287, 50.7360026000218]],
    [ "Enedis",[3.1568124131543263, 50.706617269243296]],
    [ "Enedis",[3.1449207972754927, 50.71742304887361]],
    [ "Enedis",[3.1711138999999995, 50.7167483]],
    [ "Enedis",[3.1600659052803155, 50.70484771742953]],
    [ "Enedis",[3.1687599500700347, 50.73509735814545]],
    [ "Enedis",[3.1670054447463314, 50.74107601996592]],
    [ "Enedis",[3.1472836329003764, 50.69708954741364]],
    [ "Enedis",[3.1656908266547146, 50.73308103108944]],
    [ "Enedis",[3.163585314371366, 50.727086733828244]],
    [ "Enedis",[3.152066936149997, 50.69995748592787]],
    [ "Enedis",[3.1478423694707955, 50.698034400363895]],
    [ "Enedis",[3.18889471419267, 50.73637740205695]],
    [ "Enedis",[3.1904292548295796, 50.73864333736961]],
    [ "Enedis",[3.151629672557064, 50.695151386222165]],
    [ "Enedis",[3.139388320452506, 50.72180286847202]],
    [ "Enedis",[3.1674974557422977, 50.73079900980394]],
    [ "Enedis",[3.1481136162989203, 50.695112717315915]],
    [ "Enedis",[3.13344656920188, 50.735453211991235]],
    [ "Enedis",[3.1416677996904556, 50.738215584395526]],
    [ "Enedis",[3.1638527923657125, 50.73793764190915]],
    [ "Enedis",[3.1472422969897984, 50.73944296683524]],
    [ "Enedis",[3.166760232215701, 50.73448717563135]],
    [ "Enedis",[3.1474560972799543, 50.71692384297734]],
    [ "Enedis",[3.168171918709996, 50.73643623445462]],
    [ "Enedis",[3.1933761727682586, 50.73717451551245]],
    [ "Enedis",[3.1746980041481034, 50.73495657745596]],
    [ "Enedis",[3.156934959013798, 50.72451349258446]],
    [ "Enedis",[3.1715368500433305, 50.73195073334885]],
    [ "Enedis",[3.1238667251891634, 50.72891704114848]],
    [ "Enedis",[3.1401479885243053, 50.73599430201973]],
    [ "Enedis",[3.1408798597301613, 50.71827631846416]],
    [ "Enedis",[3.1563851294488203, 50.72284817148738]],
    [ "Enedis",[3.1544884114257568, 50.71671753556582]],
    [ "Enedis",[3.1389313579973983, 50.73770888002147]],
    [ "Enedis",[3.1762989290845693, 50.72768221796451]],
    [ "Enedis",[3.141716588459441, 50.7202503891155]],
    [ "Enedis",[3.153359467655517, 50.70600221145376]],
    [ "Enedis",[3.1607091932178006, 50.71659734073779]],
    [ "Enedis",[3.1443552438660176, 50.720395324003746]],
    [ "Enedis",[3.135029432244992, 50.73452208669352]],
    [ "Enedis",[3.1204734098335702, 50.726434105107366]],
    [ "Enedis",[3.156772483844552, 50.73995288278509]],
    [ "Enedis",[3.153022764444661, 50.694409022111145]],
    [ "Enedis",[3.1344915562413598, 50.72047213461086]],
    [ "Enedis",[3.1613763760723157, 50.73329577099628]],
    [ "Enedis",[3.147018846288055, 50.695334188938325]],
    [ "Enedis",[3.1820294957838255, 50.72539108536574]],
    [ "Enedis",[1.8384276890533855, 48.61730128209819]],
    [ "Enedis",[3.188866049701574, 50.73786829356438]],
    [ "Enedis",[3.151637422594068, 50.69699726378515]],
    [ "Enedis",[2.1726724952310223, 48.67804602981449]],
    [ "Enedis",[6.092681441765143, 46.14611054860357]],
    [ "Le Chesnay",[6.082070299926734, 46.132960433334674]],
    [ "Autoroute Blanche",[6.086950210228268, 46.1300371338271]],
    [ "Chez Jacques",[6.108878936020362, 46.13810189437837]],
    [ "Au pas de Calais",[6.103662028773577, 46.14026571868595]],
    [ "Lathoy",[6.103947562810215, 46.141358017475206]],
    [ "Thairy",[6.065574290900029, 46.14048243305758]],
    [ "Cervonnex",[6.085145567974033, 46.13191315288617]],
    [ "Enedis",[6.07841536998694, 46.143807959179775]],
    [ "Enedis",[6.074806719589727, 46.14203963124875]],
    [ "Enedis",[6.093994626489127, 46.147856775425495]],
    [ "Puy Saint-Martin",[6.090153954675071, 46.143512232099056]],
    [ "Enedis",[6.0753061822916905, 46.146081680986846]],
    [ "Enedis",[6.0603944793718485, 46.149558598680045]],
    [ "Enedis",[4.681414859129169, 43.97790581554176]],
    [ "Enedis",[2.1397889146347464, 45.52830776505848]],
    [ "Enedis",[2.14249497019023, 45.525044741850415]],
    [ "Enedis",[2.1339959974914966, 45.53207193508716]],
    [ "Enedis",[2.1557341, 45.53747825]],
    [ "Enedis",[3.3352122614445063, 46.555591353317496]],
    [ "Enedis",[3.3376356553444704, 46.55061581209917]],
    [ "Enedis",[2.1777027304630696, 48.67619470363567]],
    [ "Enedis",[3.3391496565618186, 46.5466120292821]],
    [ "Enedis",[3.338584987190036, 46.53959099629156]],
    [ "Enedis",[3.338093508174282, 46.545958043741976]],
    [ "Enedis",[2.2296052741268464, 48.92206779048051]],
    [ "Enedis",[2.2281400833079434, 48.92158871848568]],
    [ "Enedis",[2.230010547345018, 48.92156734074781]],
    [ "Enedis",[2.232529329977877, 48.92308048488451]],
    [ "Enedis",[2.226619177029514, 48.92021083138324]],
    [ "Enedis",[2.2285721675440504, 48.922184576409215]],
    [ "Enedis",[2.2296726120103676, 48.92212536903824]],
    [ "Enedis",[2.2267375464717603, 48.92022158098635]],
    [ "Enedis",[4.684819095959469, 43.97862954921313]],
    [ "Enedis",[2.6167502572785164, 46.35144380668857]],
    [ "Enedis",[2.6255072242155246, 46.35401398337291]],
    [ "Enedis",[2.6213462449216074, 46.35941711408325]],
    [ "Enedis",[2.6227123568260446, 46.354317965689184]],
    [ "Enedis",[2.6198646570787987, 46.35125557524828]],
    [ "Enedis",[2.6125678767538596, 46.35814207208223]],
    [ "Enedis",[2.6218155997351023, 46.34988631664424]],
    [ "Enedis",[2.622427729629208, 46.36276115807431]],
    [ "Enedis",[2.6236178443511315, 46.36461713469266]],
    [ "Enedis",[2.6245506138243195, 46.36873578180283]],
    [ "Enedis",[2.6187869569379596, 46.34543553603513]],
    [ "Enedis",[2.617280053626603, 46.3491409301457]],
    [ "Enedis",[2.6200732282538777, 46.34838821611122]],
    [ "Enedis",[2.625380819131892, 46.35851992896852]],
    [ "Enedis",[2.6217994349030302, 46.365883873377264]],
    [ "Enedis",[2.6180865859290394, 46.35962623983404]],
    [ "Enedis",[2.628838187118122, 46.35163763121799]],
    [ "Enedis",[2.6181593913017056, 46.35438016170033]],
    [ "Enedis",[2.616714247437804, 46.345734793515525]],
    [ "Enedis",[2.622513228490044, 46.347678877905615]],
    [ "Enedis",[2.6244354887703643, 46.34965335974378]],
    [ "Enedis",[2.5995198011927703, 46.35447396182419]],
    [ "Enedis",[2.6104704174958275, 46.349118102065916]],
    [ "Enedis",[2.6058767, 46.34873745]],
    [ "Enedis",[2.6213528603450054, 46.32767372589537]],
    [ "Enedis",[2.596023933494518, 46.32922399170854]],
    [ "Enedis",[2.6075579816857863, 46.35491873281761]],
    [ "Enedis",[2.614407718537781, 46.32680804912053]],
    [ "Enedis",[2.586524803798814, 46.34658688622728]],
    [ "Enedis",[2.6120323588614935, 46.32217448150188]],
    [ "Enedis",[2.584280532475435, 46.35027438336823]],
    [ "Enedis",[2.5956293091420997, 46.361554608580946]],
    [ "Enedis",[2.6168252084586645, 46.32029002890464]],
    [ "Enedis",[2.6171062999999997, 46.31912700000001]],
    [ "Enedis",[2.5817521971887234, 46.3536446071385]],
    [ "Enedis",[2.6207809837392055, 46.33157929864217]],
    [ "Enedis",[2.594272958803307, 46.3450887678762]],
    [ "77-83 Rue Lepic",[2.336309570260647, 48.88744156623916]],
    [ "Enedis",[2.5925749756706398, 46.34235670012842]],
    [ "Enedis",[2.588233455858484, 46.36317719985534]],
    [ "Enedis",[2.60137278910621, 46.33575524202175]],
    [ "Enedis",[2.583254476230913, 46.32805876209192]],
    [ "Enedis",[2.592584755643875, 46.36564356954026]],
    [ "Enedis",[2.5774071952421536, 46.34671078836072]],
    [ "Enedis",[2.611871642142325, 46.31950032278188]],
    [ "Enedis",[2.593821799431428, 46.34052716773706]],
    [ "Enedis",[2.609335974130948, 46.33375154365307]],
    [ "Enedis",[2.596348036317944, 46.3417778149237]],
    [ "Enedis",[2.585318947867575, 46.34072780184184]],
    [ "Enedis",[2.6041301848390725, 46.33376693412455]],
    [ "Enedis",[2.5987203944323283, 46.35304993700018]],
    [ "Enedis",[2.574902285598247, 46.332940387156775]],
    [ "Enedis",[2.5891237961266467, 46.348590846524786]],
    [ "Enedis",[2.61497182959798, 46.344011651907906]],
    [ "Enedis",[2.596773421784776, 46.35725754409448]],
    [ "Enedis",[2.606070475908277, 46.35693254422923]],
    [ "Enedis",[2.6133106206156107, 46.34280637505512]],
    [ "Enedis",[2.585501659369157, 46.356056498057605]],
    [ "Enedis",[2.609070611395834, 46.34672062066285]],
    [ "Enedis",[2.5905257183319836, 46.345814866480794]],
    [ "Enedis",[2.6120418529305685, 46.34072451215995]],
    [ "Enedis",[2.5996095471834915, 46.32742675925163]],
    [ "Enedis",[2.6086664345525183, 46.330684465535676]],
    [ "Enedis",[2.581421495044941, 46.33942565043019]],
    [ "Enedis",[2.5845477495487743, 46.32234252935871]],
    [ "Enedis",[2.588936811356627, 46.326940792512644]],
    [ "Enedis",[2.6116517557861534, 46.32390775105106]],
    [ "Enedis",[2.591032668457221, 46.35144097946461]],
    [ "Enedis",[2.5986952563836403, 46.33332809762946]],
    [ "Enedis",[2.5878690423731365, 46.33699211272993]],
    [ "Enedis",[2.5920186350661667, 46.33483340365329]],
    [ "Enedis",[2.596306684615765, 46.35104170892658]],
    [ "Enedis",[2.5733615751958703, 46.34531377308611]],
    [ "Enedis",[2.5774594175419403, 46.33322562366886]],
    [ "Enedis",[2.6005502498460977, 46.35675425419072]],
    [ "Enedis",[2.607276849561701, 46.34190549283377]],
    [ "Enedis",[2.6055168546128655, 46.34589218736176]],
    [ "Enedis",[2.6132819821150446, 46.329963615708365]],
    [ "Enedis",[2.5946419159907674, 46.354502427362014]],
    [ "Enedis",[2.6223037493059693, 46.33341600878325]],
    [ "Enedis",[2.599396607642419, 46.36283002962404]],
    [ "Enedis",[2.6042892, 46.35309935]],
    [ "Enedis",[2.6052819365595417, 46.32539679245936]],
    [ "Enedis",[2.5888630089467624, 46.35338314579448]],
    [ "Enedis",[2.6065216312545716, 46.336214638135125]],
    [ "Enedis",[2.5896224869633224, 46.35814141440875]],
    [ "Enedis",[2.595633333727556, 46.36524562523656]],
    [ "Enedis",[2.6015404941542566, 46.33141533666336]],
    [ "Enedis",[2.6170954473725185, 46.32466513487738]],
    [ "Enedis",[2.602821401625952, 46.34352042512999]],
    [ "Enedis",[2.5812320211090305, 46.34580053837032]],
    [ "Enedis",[2.5812387625393853, 46.34296155933824]],
    [ "Enedis",[2.6057126483180273, 46.33535911989138]],
    [ "Enedis",[2.61435233615436, 46.33955383230905]],
    [ "Enedis",[2.5846383935630466, 46.327668950367084]],
    [ "Enedis",[2.617824400506451, 46.34438044311486]],
    [ "Enedis",[2.5970424390901288, 46.338642435335196]],
    [ "Enedis",[2.592584457809928, 46.346193596308325]],
    [ "Enedis",[2.590406746139343, 46.368391331022316]],
    [ "Enedis",[2.6167951632429998, 46.338792581280956]],
    [ "Enedis",[2.6182388858462833, 46.33368359296455]],
    [ "Enedis",[2.6060427919998856, 46.33467763542457]],
    [ "Enedis",[2.605425230956846, 46.32302532209908]],
    [ "Enedis",[2.5796826590558326, 46.33146803041307]],
    [ "Enedis",[-3.3005651, 48.81131104999999]],
    [ "Enedis",[-3.2982411500000013, 48.81019964999999]],
    [ "Enedis",[-3.278822271096685, 48.84063754859997]],
    [ "Enedis",[-3.3126140232862262, 48.833959755601285]],
    [ "Enedis",[-3.276528732374151, 48.834855943709975]],
    [ "Enedis",[-3.303763583021643, 48.82435711992803]],
    [ "Enedis",[-3.2845649789708067, 48.836078563087575]],
    [ "Enedis",[-3.32098500920376, 48.83295334546211]],
    [ "Enedis",[-3.2957455, 48.81439125]],
    [ "Enedis",[-3.3167824853359935, 48.83349452564367]],
    [ "Enedis",[-3.32172613874804, 48.836282942571394]],
    [ "Enedis",[3.317366664050632, 46.5841191786498]],
    [ "Enedis",[3.3203274000000014, 46.576775950000005]],
    [ "Enedis",[3.3093566232905847, 46.58983644950396]],
    [ "Enedis",[3.292833027719438, 46.612114912430535]],
    [ "Enedis",[3.3026332033669665, 46.59528654891686]],
    [ "Enedis",[3.3064976891018523, 46.59040195643231]],
    [ "Enedis",[3.31095971945541, 46.59210256027478]],
    [ "Enedis",[3.3181084378661545, 46.587813931804355]],
    [ "Enedis",[3.312017486568956, 46.58926529751849]],
    [ "Enedis",[3.306454800604138, 46.58911801648542]],
    [ "Le Cret",[6.153541572077198, 46.14224504231914]],
    [ "Enedis",[3.3234900847907176, 46.573709003250165]],
    [ "Enedis",[3.3315585902991662, 46.57086219084033]],
    [ "Enedis",[3.1527532954526567, 50.62361220011893]],
    [ "Enedis",[3.160126058297283, 50.62468629919599]],
    [ "Antoine",[3.1630360799980246, 50.62185588858479]],
    [ "Enedis",[3.1658337212610093, 50.6249335478253]],
    [ "Enedis",[2.5756756923059934, 46.354986838097894]],
    [ "Enedis",[2.5246739207353692, 46.35953555091433]],
    [ "Enedis",[2.540074687045551, 46.36120236829697]],
    [ "Enedis",[2.5338054931189227, 46.36359736549987]],
    [ "Enedis",[2.567678498301434, 46.34136922738938]],
    [ "Enedis",[2.5334661557030067, 46.36651331319085]],
    [ "Enedis",[2.539301347982593, 46.36421848258311]],
    [ "Enedis",[2.5660232077448564, 46.36406007432044]],
    [ "Enedis",[2.5311160500000005, 46.367785049999995]],
    [ "Enedis",[2.568466812684267, 46.37381875712985]],
    [ "Enedis",[2.5128481445918016, 46.3529580114782]],
    [ "Enedis",[2.5684815723712173, 46.367490408292944]],
    [ "Enedis",[2.5318921107436925, 46.36072268539216]],
    [ "Enedis",[2.5786282992376366, 46.35603152364663]],
    [ "Enedis",[2.5304736222393163, 46.351728500470394]],
    [ "Enedis",[2.53837484178202, 46.35570195287908]],
    [ "Enedis",[2.53406335, 46.369024700000004]],
    [ "Enedis",[2.5282137720351257, 46.3978948024805]],
    [ "Enedis",[2.5721555326847816, 46.360439900432866]],
    [ "Enedis",[2.573794327009442, 46.34816509064335]],
    [ "Enedis",[2.564756114137144, 46.347170766281614]],
    [ "Enedis",[2.5230771570344674, 46.38729616385842]],
    [ "Enedis",[3.3634864167885565, 46.51028468442574]],
    [ "Enedis",[3.139757899439178, 50.62778623401449]],
    [ "Enedis",[3.145028063323881, 50.624249668788956]],
    [ "Enedis",[3.1439030531874144, 50.627012831131466]],
    [ "Enedis",[3.1403791836569024, 50.62730049278076]],
    [ "Enedis",[3.1455736435081567, 50.62795744259307]],
    [ "Enedis",[3.141531284528555, 50.62969354055022]],
    [ "Enedis",[3.1487376312018673, 50.62569203071721]],
    [ "Enedis",[3.139773424549383, 50.625342753145]],
    [ "Enedis",[3.143070875335687, 50.630761868419995]],
    [ "Enedis",[3.350360491990629, 46.55923905330448]],
    [ "Enedis",[3.353371156639652, 46.578063949289074]],
    [ "Enedis",[3.354213450000001, 46.55738755]],
    [ "Enedis",[3.3482125661091366, 46.55312089530443]],
    [ "Enedis",[3.340871556893618, 46.55826351159819]],
    [ "Enedis",[3.3479485670039812, 46.560702403191804]],
    [ "Enedis",[3.345087868845599, 46.573322846236266]],
    [ "Enedis",[3.3506294809139994, 46.54560688471688]],
    [ "Enedis",[3.358372353277682, 46.54513425208842]],
    [ "Enedis",[3.3545168884108763, 46.554422701969656]],
    [ "Enedis",[3.361052205198181, 46.53719582180637]],
    [ "Enedis",[3.35290284003373, 46.55293561610657]],
    [ "Enedis",[3.3578636614737944, 46.564589923968434]],
    [ "Enedis",[3.3519920000000014, 46.56450385]],
    [ "Enedis",[3.3562860309928815, 46.55762188167596]],
    [ "Enedis",[3.354819579325431, 46.56952559676804]],
    [ "Enedis",[3.340644273626651, 46.55545567509683]],
    [ "Enedis",[3.366308018810165, 46.566642754356636]],
    [ "Enedis",[3.3641430164047654, 46.55085238161721]],
    [ "Enedis",[3.339565273670645, 46.55117082855156]],
    [ "Enedis",[3.360481447946979, 46.573717830537575]],
    [ "Enedis",[3.3509105037701397, 46.556711275855065]],
    [ "Enedis",[3.3480154278622622, 46.57410468924908]],
    [ "Enedis",[3.339128943657197, 46.5798542039853]],
    [ "Enedis",[3.352622984311558, 46.557161515192426]],
    [ "Enedis",[3.3537515662400144, 46.57290965460683]],
    [ "Enedis",[3.3622169445312005, 46.56428941631964]],
    [ "Enedis",[3.350039415735067, 46.56873848501104]],
    [ "Enedis",[3.3615487539282607, 46.576506264977645]],
    [ "Enedis",[3.3616901710081266, 46.566673430697676]],
    [ "Enedis",[3.3604531035418126, 46.557934658562914]],
    [ "Enedis",[3.3483891488216777, 46.563409739598356]],
    [ "Enedis",[3.3688462419332046, 46.55609770438467]],
    [ "Enedis",[3.358596103985621, 46.552121082022126]],
    [ "Enedis",[3.3447989842087678, 46.54269103639527]],
    [ "Enedis",[3.3614418322884707, 46.55506497017908]],
    [ "Enedis",[3.3442525825304497, 46.57145243395304]],
    [ "Enedis",[3.3376715593530935, 46.55306401639262]],
    [ "Enedis",[3.3485299531544763, 46.56995529499395]],
    [ "Enedis",[3.3542944452546646, 46.57474424215623]],
    [ "Enedis",[3.358076147173006, 46.54866107927842]],
    [ "Enedis",[3.9529991860482587, 50.17683421126791]],
    [ "Enedis",[0.4243618499999997, 46.6956762]],
    [ "Enedis",[-0.5590425512724323, 44.84537725132867]],
    [ "Enedis",[-0.5614915500000001, 44.8462489]],
    [ "Enedis",[-0.5562355459135964, 44.84543097005047]],
    [ "Enedis",[3.2980220244645997, 46.636873067795555]],
    [ "Enedis",[2.258027889545514, 48.93256576184438]],
    [ "Enedis",[2.2563624126246196, 48.936399043293726]],
    [ "Enedis",[-0.557746791105285, 44.84056680441624]],
    [ "Enedis",[-3.3600820101349007, 48.82057670779895]],
    [ "Enedis",[-3.3553123890451357, 48.81904721361475]],
    [ "Enedis",[-3.349721898954354, 48.820088816503514]],
    [ "Royau",[-3.34226685812787, 48.82463390743216]],
    [ "Enedis",[-3.3426681206201683, 48.81985223153236]],
    [ "Enedis",[-3.3468732571789945, 48.82129256110828]],
    [ "Enedis",[-3.3430952594616694, 48.82216690655057]],
    [ "Enedis",[-3.343093061946092, 48.816334055797256]],
    [ "Enedis",[2.3943981744108727, 48.84085683095105]],
    [ "Enedis",[3.4288151750283764, 46.46781295039727]],
    [ "Enedis",[3.2646098916446062, 46.46880596110316]],
    [ "Poste électrique de Coriolis",[2.3916055812104284, 48.83579968758361]],
    [ "Geredis",[0.1724246725302422, 46.140811497887455]],
    [ "Geredis",[0.18049516801047405, 46.13353530246852]],
    [ "Enedis",[0.16888117663851596, 46.12980994619097]],
    [ "Enedis",[0.14424574140444432, 46.12440050165334]],
    [ "Geredis",[0.2028317917607127, 46.140462562069914]],
    [ "Enedis",[0.1488717212621103, 46.12607613461072]],
    [ "Enedis",[0.17222347900998108, 46.1329183588393]],
    [ "Enedis",[4.864626650000001, 45.6349011]],
    [ "Enedis",[-0.2464122151136126, 44.5551078749668]],
    [ "Enedis",[-0.26434716079855247, 44.53484022556547]],
    [ "Enedis",[-0.2599397311009575, 44.53409069093097]],
    [ "Croix Blanche",[-2.73351615, 48.51879925000001]],
    [ "Enedis",[-2.7381424402024024, 48.51985275955707]],
    [ "Enedis",[-0.26218371908109506, 44.53767175996235]],
    [ "Enedis",[-2.7526447526407836, 48.497376719818504]],
    [ "Enedis",[-2.8010253499999997, 48.517682199999996]],
    [ "Aire de Loisirs",[-2.755120850000001, 48.5109994]],
    [ "Poste électrique d'Austerlitz",[2.3691520942519837, 48.8494552704557]],
    [ "Enedis",[4.961228330161153, 45.82757686045103]],
    [ "Enedis",[4.966590743107605, 45.824439783904914]],
    [ "Gérédis",[-0.19452068039576575, 47.01213965672322]],
    [ "Enedis",[-0.9520938711437411, 45.55737995671206]],
    [ "Enedis",[-0.952700614075121, 45.55434732316398]],
    [ "Enedis",[-0.9498198459213921, 45.555498419149394]],
    [ "Enedis",[-0.9521133428257006, 45.563729084292355]],
    [ "Enedis",[-0.9507526723609092, 45.568550282708316]],
    [ "Enedis",[-0.9570391033275346, 45.55917528427073]],
    [ "Enedis",[-0.9568012218570254, 45.56466624593262]],
    [ "Enedis",[-0.9835276045643353, 45.57758785105956]],
    [ "Enedis",[-0.956548050743188, 45.56990566592102]],
    [ "Enedis",[-0.9454244423800531, 45.555992830992324]],
    [ "Enedis",[-0.9639320270222275, 45.56541519512948]],
    [ "Enedis",[-0.9379637500000002, 45.56225455]],
    [ "Enedis",[-0.9568178327454585, 45.556303958317216]],
    [ "Enedis",[-0.9630050730904467, 45.56755322433407]],
    [ "Enedis",[-0.9497467520994001, 45.556472730419884]],
    [ "Enedis",[-0.9492660845686227, 45.5606945052607]],
    [ "Enedis",[-0.9634342252797975, 45.560932752234606]],
    [ "Enedis",[-0.9491385499999999, 45.57227855000001]],
    [ "Enedis",[-0.9581515759233489, 45.576103624881235]],
    [ "Enedis",[-0.963789958044866, 45.56293201449486]],
    [ "Enedis",[-0.9781297500000001, 45.57555879999999]],
    [ "Enedis",[-0.9523481853666911, 45.564253356070815]],
    [ "Enedis",[-0.9614786064971411, 45.57570483364159]],
    [ "Enedis",[-0.967197871491962, 45.56614521773346]],
    [ "Enedis",[-0.9618397083341639, 45.557779819113506]],
    [ "Enedis",[-0.9679129976894787, 45.562622509987136]],
    [ "Enedis",[-0.9436286808591845, 45.561709352324314]],
    [ "Enedis",[-0.9539115673113401, 45.5602063806486]],
    [ "Enedis",[-0.9546051500000001, 45.56675924999999]],
    [ "Enedis",[-0.9605943132363338, 45.56543222731109]],
    [ "Enedis",[-0.9642164369999999, 45.566987636263164]],
    [ "Enedis",[-0.9581236276825801, 45.56177854702447]],
    [ "Enedis",[3.366996587114847, 46.44995486065766]],
    [ "Enedis",[3.3455382, 46.473104400000004]],
    [ "Enedis",[-0.95411565, 45.5584907]],
    [ "Enedis",[-0.9697549633795381, 45.56547694917031]],
    [ "Enedis",[-0.95049655, 45.56768555]],
    [ "Enedis",[3.355785349999999, 46.471698499999995]],
    [ "Enedis",[3.37065971068187, 46.43558071054573]],
    [ "Enedis",[-3.8259040737059067, 48.59108218644322]],
    [ "Enedis",[-3.8169096706954133, 48.5758286063637]],
    [ "Enedis",[-3.8290346004323004, 48.58125557712177]],
    [ "Enedis",[-3.8180625149875196, 48.572326027108424]],
    [ "Enedis",[-3.8255767852459255, 48.56787532940215]],
    [ "Enedis",[-3.837158524774943, 48.579169826065694]],
    [ "Enedis",[3.248934813740459, 46.66505173486005]],
    [ "Enedis",[0.13874420243396954, 49.50060033835828]],
    [ "Enedis",[2.254190834670343, 48.92816105073059]],
    [ "Enedis",[2.2565330510741313, 48.92723709492191]],
    [ "Enedis",[2.259257482658867, 48.92630082983055]],
    [ "Enedis",[1.5241033441567369, 45.033334605392604]],
    [ "Enedis",[4.010547700000001, 50.161490699999995]],
    [ "Enedis",[4.013896138210117, 50.1624591023479]],
    [ "T3 La Tremblais BL",[-2.236397517040666, 48.49436762876053]],
    [ "Enedis",[-2.2512315115627275, 48.50378873961955]],
    [ "Enedis",[-2.2514387188108023, 48.47420884723398]],
    [ "Crech Allano",[-2.997203124185247, 48.852393715506295]],
    [ "Semaphore",[-3.0019038999999994, 48.85546]],
    [ "Kervillon",[-2.997442219770298, 48.85749474577108]],
    [ "Merida",[2.1891659, 48.67840455]],
    [ "Enedis",[2.1870301000000003, 48.680830949999994]],
    [ "Enedis",[2.185425443675575, 48.676854882274306]],
    [ "Enedis",[4.8005297943632845, 44.151560666306445]],
    [ "Enedis",[4.800118453555011, 44.153664945360774]],
    [ "Enedis",[4.799387649999999, 44.1496996]],
    [ "Enedis",[2.5990652554300064, 46.30936299341973]],
    [ "Enedis",[2.601714314658673, 46.3180369087475]],
    [ "Enedis",[2.609800640953761, 46.314687688317676]],
    [ "Enedis",[2.5912553639083775, 46.313505704198434]],
    [ "Enedis",[2.5408901408459528, 46.31740433172653]],
    [ "Enedis",[2.5762180339832725, 46.3288204478877]],
    [ "Enedis",[2.6704638015880633, 46.281698106010325]],
    [ "Enedis",[2.671462441749979, 46.29271211342392]],
    [ "Enedis",[2.6556026185262893, 46.282913089796295]],
    [ "Enedis",[2.6565203739951113, 46.28800456479096]],
    [ "Enedis",[2.660621338547361, 46.29211434162391]],
    [ "Enedis",[2.6529830078207026, 46.28479853390317]],
    [ "Enedis",[2.6664625284454875, 46.28441982161259]],
    [ "Enedis",[2.65580935, 46.2935428]],
    [ "Enedis",[3.157650480450614, 50.70745830021726]],
    [ "Enedis",[3.1775325735794784, 50.73404714587338]],
    [ "Enedis",[3.179300757796191, 50.74747312119286]],
    [ "Enedis",[3.186237235801368, 50.7388635386857]],
    [ "Enedis",[3.179056663906803, 50.7444270759542]],
    [ "Enedis",[3.1831466214527437, 50.74359648527198]],
    [ "Enedis",[3.1763237766530374, 50.7388965587063]],
    [ "Enedis",[3.173543928364582, 50.744611761531225]],
    [ "Enedis",[3.1746627499999995, 50.7390232]],
    [ "Enedis",[4.800009599999998, 44.145909499999995]],
    [ "Enedis",[4.8029705, 44.14497455000001]],
    [ "Enedis",[4.80539455, 44.141008799999994]],
    [ "Enedis",[4.809569225230301, 44.143190994163334]],
    [ "78421225",[4.797532499999998, 44.14068955]],
    [ "78421311",[4.808133685603119, 44.143277479802535]],
    [ "Enedis",[4.798533863453973, 44.14529153076847]],
    [ "Enedis",[4.809145649999999, 44.13996895]],
    [ "Enedis",[4.803026575298577, 44.14360940609797]],
    [ "Enedis",[-0.5435079805101611, 44.82317766360932]],
    [ "Enedis",[2.7646143126770286, 42.62359888422028]],
    [ "Enedis",[2.7722224038725796, 42.61981338069563]],
    [ "Enedis",[4.81026495, 44.13721495000001]],
    [ "Enedis",[3.1932068075962374, 50.55797860143705]],
    [ "Enedis",[3.1964130490814555, 50.56095802720971]],
    [ "Enedis",[4.82140135, 45.629534850000006]],
    [ "SRD",[0.42933323093728165, 46.73668552791307]],
    [ "SRD",[0.4391165336819421, 46.73110514886015]],
    [ "Enedis",[2.81282994945832, 42.576731240259015]],
    [ "Enedis",[2.8458920514052846, 42.75390583320579]],
    [ "Enedis",[2.8548954525953483, 42.754111276624734]],
    [ "Enedis",[2.8508246117845117, 42.75247986868687]],
    [ "Enedis",[2.853610162462537, 42.75520992028101]],
    [ "Enedis",[2.856809720577079, 42.75494808272492]],
    [ "Enedis",[2.84366147134909, 42.75492839473901]],
    [ "Chaynais",[6.106923663796564, 46.092378795905816]],
    [ "Enedis",[6.115234468726606, 46.09575915348338]],
    [ "Les Eplanes",[6.103106968490716, 46.0992092954221]],
    [ "Juge Guerin",[6.103701271846276, 46.103050146189524]],
    [ "Neydens",[6.102398151667699, 46.11918284581882]],
    [ "La Forge",[6.1085805713384165, 46.11893313339198]],
    [ "Moisin",[6.1125494499999995, 46.11551455]],
    [ "Enedis",[6.096697666569114, 46.124906876812155]],
    [ "Oree du Bois",[6.091829350653555, 46.11761101434809]],
    [ "Enedis",[6.085853795471781, 46.121891304933534]],
    [ "Résidence du Salève",[6.097796301757617, 46.11539203790315]],
    [ "La Cure",[6.106954468028857, 46.121127491911984]],
    [ "Mouvy",[6.091362620726054, 46.12023054342708]],
    [ "Enedis",[3.168576199999999, 50.7427768]],
    [ "Enedis",[3.183856764031399, 50.73133414121109]],
    [ "Enedis",[3.1788064923438113, 50.7310502203846]],
    [ "Enedis",[3.187150596022839, 50.732767253481406]],
    [ "Enedis",[-0.05483819634721945, 46.19060159892304]],
    [ "Enedis",[3.1416078843462416, 50.732153395451526]],
    [ "Enedis",[3.152776850305882, 50.73429451525376]],
    [ "Enedis",[3.1407017520881237, 50.730035359361466]],
    [ "Enedis",[3.14244992567145, 50.73442491123399]],
    [ "Enedis",[3.141336709633196, 50.72870498576016]],
    [ "Enedis",[3.149015507010431, 50.73067251368545]],
    [ "Enedis",[3.1499070347493756, 50.73193267853726]],
    [ "Enedis",[3.1512394244797077, 50.72940060531716]],
    [ "Enedis",[3.1439261888312764, 50.730295687709294]],
    [ "Enedis",[3.150967230232042, 50.73472681792787]],
    [ "Enedis",[3.1387104201493754, 50.728386318829166]],
    [ "Enedis",[3.1486584839464946, 50.729122097310615]],
    [ "Enedis",[3.1383678371244677, 50.73011539697041]],
    [ "Enedis",[4.799844, 44.13868409999999]],
    [ "Enedis",[3.1527941723788393, 50.72167104305428]],
    [ "Enedis",[3.1515916115165807, 50.72389080916949]],
    [ "Enedis",[3.155449796784592, 50.723294935127186]],
    [ "Enedis",[2.5907753122018344, 46.3835913366055]],
    [ "Enedis",[2.605944010904008, 46.404562811835966]],
    [ "Enedis",[2.6138825335384084, 46.37469436808737]],
    [ "Enedis",[2.6228358318566265, 46.39191306103415]],
    [ "Enedis",[2.6196724664495914, 46.38257523032265]],
    [ "Enedis",[2.6183158499999997, 46.3912598]],
    [ "Enedis",[2.600400414727572, 46.375915440483375]],
    [ "Enedis",[2.618221194321636, 46.374711127470306]],
    [ "Enedis",[2.613569705826897, 46.382473833312844]],
    [ "Enedis",[2.616990125161773, 46.3839662607633]],
    [ "Enedis",[2.4511233823450067, 48.79141589486477]],
    [ "Enedis",[2.4378516363425566, 48.78359180605827]],
    [ "Enedis",[2.4389417184277615, 48.78041868687374]],
    [ "78528853",[2.4347744679000924, 48.770996093586184]],
    [ "Enedis",[2.435087909659933, 48.7868660275808]],
    [ "Enedis",[4.801525550000002, 44.1331411]],
    [ "Enedis",[4.805952829176031, 44.13442070231]],
    [ "Enedis",[4.802805104577228, 44.13325905333715]],
    [ "Enedis",[4.801184103650208, 44.12803881697257]],
    [ "Sanche",[4.80118774472508, 44.13607842068069]],
    [ "Gasparin",[4.803780641379457, 44.136518721806155]],
    [ "Enedis",[4.79938215, 44.128007600000004]],
    [ "Enedis",[4.8016176999999995, 44.13218145]],
    [ "Enedis",[2.472621407940273, 48.76332398300795]],
    [ "Enedis",[2.4702736756488117, 48.780555495426775]],
    [ "Enedis",[2.4653327499999995, 48.78092385]],
    [ "Enedis",[2.7561330866097697, 42.69007882692772]],
    [ "Enedis",[2.765993009036098, 42.69556364375374]],
    [ "Enedis",[-3.3811437016390204, 48.81041987135938]],
    [ "Enedis",[-3.3641841583490484, 48.79169448799678]],
    [ "Enedis",[-3.3838135702518324, 48.81442659420858]],
    [ "Enedis",[4.847988038504266, 45.64811929209047]],
    [ "Enedis",[-3.37997989154422, 48.80063794940607]],
    [ "Enedis",[-3.3725085213725485, 48.813471020516936]],
    [ "Enedis",[-3.3718594080326083, 48.80796064460803]],
    [ "Enedis",[-3.370087365521695, 48.81315667300178]],
    [ "Enedis",[-3.371444836734629, 48.803575771172746]],
    [ "Enedis",[-3.370026115845247, 48.810134014574494]],
    [ "Enedis",[-3.373205121006194, 48.8095684114289]],
    [ "Enedis",[4.787554857649444, 44.08892704894939]],
    [ "La Combe",[5.758470743534479, 45.10930692996913]],
    [ "Vial",[5.740462510174284, 45.08808847248901]],
    [ "Enedis",[3.1488948501429537, 50.74161861651796]],
    [ "Enedis",[3.149905027638985, 50.7586924470986]],
    [ "Enedis",[3.173519830682051, 50.74662097536071]],
    [ "Enedis",[3.1631525051459732, 50.744033031510824]],
    [ "Enedis",[3.1797561689219944, 50.750198422453096]],
    [ "Enedis",[3.175832749999999, 50.749256200000005]],
    [ "Enedis",[3.1590648161459787, 50.74806837650668]],
    [ "Enedis",[3.15302870077232, 50.74446641692387]],
    [ "Enedis",[3.1559986012254364, 50.748225887500546]],
    [ "Enedis",[3.1695717818917717, 50.74852664273599]],
    [ "Enedis",[3.1672292455281745, 50.75417328969625]],
    [ "Enedis",[3.1649317923156803, 50.76309268203531]],
    [ "Enedis",[3.1506646207866527, 50.74103434998986]],
    [ "Enedis",[3.1552699314802144, 50.74951479579791]],
    [ "Enedis",[3.1490316597518286, 50.74816360135065]],
    [ "Enedis",[3.16125783530645, 50.751510736797364]],
    [ "Enedis",[3.1601418442475495, 50.743046184852915]],
    [ "Enedis",[3.145600537529001, 50.723858821313996]],
    [ "Enedis",[3.143311259964819, 50.72560672579301]],
    [ "Enedis",[3.142960481269405, 50.7240206162773]],
    [ "Enedis",[3.1451792288131886, 50.72344633086914]],
    [ "Enedis",[4.768280300000002, 44.1486349]],
    [ "Enedis",[4.840356383384805, 44.118227901729455]],
    [ "Enedis",[3.3289382482941003, 46.57742008494741]],
    [ "Enedis",[3.342123555451646, 46.568920429546345]],
    [ "Enedis",[3.3366285988984745, 46.57449302572412]],
    [ "Enedis",[3.3340177447543735, 46.57215633902155]],
    [ "Enedis",[3.341226616201588, 46.57144790085084]],
    [ "Enedis",[3.339264408034175, 46.56899249638371]],
    [ "Enedis",[3.1564259755218544, 50.72585590227336]],
    [ "Enedis",[3.1475302306783317, 50.72696643996578]],
    [ "Enedis",[3.1447434777321965, 50.72698730268334]],
    [ "Enedis",[3.1544102752122836, 50.72608248435016]],
    [ "Enedis",[2.321004378971709, 48.77801747201899]],
    [ "Enedis",[3.1712169193508997, 50.72461511975882]],
    [ "Enedis",[3.1678639002388875, 50.725127461459536]],
    [ "Enedis",[3.174535892794536, 50.724786567787035]],
    [ "Enedis",[3.1635811361532524, 50.719710303150976]],
    [ "Enedis",[3.1650342333405423, 50.723819501830974]],
    [ "Enedis",[3.1739465424092645, 50.71977152291903]],
    [ "Enedis",[3.1758858188448427, 50.72039797536677]],
    [ "Enedis",[3.1729855942455933, 50.724243872945635]],
    [ "Enedis",[3.1719805261034035, 50.72479168008239]],
    [ "Enedis",[3.1705986447821517, 50.72605452740287]],
    [ "Enedis",[6.669182903512016, 45.92162594463128]],
    [ "Enedis",[6.703643705887707, 45.91838209240116]],
    [ "Cardinolins",[6.71363575, 45.917809]],
    [ "Enedis",[6.6813917499999995, 45.923122549999995]],
    [ "Pres Montfort",[6.709989587024721, 45.91781184317056]],
    [ "Enedis",[6.717454788682056, 45.909309454782736]],
    [ "Enedis",[6.665713273508666, 45.925205824728536]],
    [ "Enedis",[6.68215054902141, 45.91642584498671]],
    [ "Enedis",[6.715015634837666, 45.90794384516759]],
    [ "Cite EDF",[6.7149122, 45.908767499999996]],
    [ "Aerodrome",[6.706702224432671, 45.91359895559666]],
    [ "Faubourg Chedde",[6.712589742046063, 45.91935228145162]],
    [ "Enedis",[3.1688415593023715, 50.711252180018995]],
    [ "Enedis",[3.1655015585788875, 50.71138204185366]],
    [ "Enedis",[3.1677884999999995, 50.70904205]],
    [ "Enedis",[3.15144565, 50.708671550000005]],
    [ "Enedis",[3.1525066121339855, 50.71087965097617]],
    [ "Enedis",[3.1555322380634316, 50.70819924630477]],
    [ "Enedis",[3.1478884967505296, 50.714775459341325]],
    [ "Enedis",[3.151216209253795, 50.712638698408846]],
    [ "Enedis",[3.151855965246911, 50.70964011556009]],
    [ "Enedis",[3.155898224456908, 50.71396395620363]],
    [ "Enedis",[3.152225320760984, 50.71323650256441]],
    [ "Enedis",[3.14955743158762, 50.71162356494501]],
    [ "Enedis",[3.152783511493157, 50.7118214064582]],
    [ "Enedis",[6.8399025058562515, 47.65767945897888]],
    [ "Enedis",[6.834573121160012, 47.646225837924156]],
    [ "Enedis",[6.836831349423405, 47.65159682369055]],
    [ "Enedis",[6.838402364640065, 47.659867795158405]],
    [ "Enedis",[6.842639899999999, 47.6452964]],
    [ "Enedis",[6.83775556217888, 47.647681942796815]],
    [ "Enedis",[3.179797100465863, 50.69334470343454]],
    [ "Enedis",[3.175803210736152, 50.69474614035324]],
    [ "Enedis",[3.171735914454277, 50.68867172610619]],
    [ "Enedis",[3.1748773906804697, 50.690153034993315]],
    [ "Enedis",[3.90612644866926, 50.13273405134773]],
    [ "Enedis",[-1.5485918312795361, 43.41848968870126]],
    [ "Enedis",[-1.5442507688129654, 43.44357241654201]],
    [ "Enedis",[6.8553704783542075, 47.64791583126413]],
    [ "Enedis",[6.8437623793612445, 47.658406649306386]],
    [ "Enedis",[-1.5551191000000009, 43.4313582]],
    [ "Enedis",[6.856529384055043, 47.652729908769004]],
    [ "Enedis",[6.844409108138786, 47.65448288639494]],
    [ "Enedis",[1.713164556985445, 49.4821008706207]],
    [ "Enedis",[-1.5526340456721914, 43.42558819531702]],
    [ "Enedis",[-1.552519962281988, 43.433717647753284]],
    [ "Enedis",[6.8642960957475925, 47.65434817639848]],
    [ "Enedis",[6.848463299999999, 47.6457501]],
    [ "Enedis",[6.849773587487389, 47.646709340784824]],
    [ "Enedis",[6.86205017565942, 47.65159970328512]],
    [ "Enedis",[6.848528620545755, 47.651283086982886]],
    [ "Enedis",[6.856572325454672, 47.64918385376942]],
    [ "Enedis",[6.84875398707519, 47.65991619484252]],
    [ "Enedis",[6.8640206131367165, 47.64945766476537]],
    [ "Enedis",[1.7291107532136774, 49.488304098915584]],
    [ "Enedis",[1.7312343500000003, 49.481403050000004]],
    [ "Enedis",[1.7307652330998482, 49.481882592119355]],
    [ "Enedis",[1.707620322781583, 49.486318177743186]],
    [ "Enedis",[1.7149990658479817, 49.47906601142484]],
    [ "Enedis",[1.7225734337097824, 49.471006072977694]],
    [ "Enedis",[1.7132094708131085, 49.48216114121041]],
    [ "Enedis",[3.9216521077336446, 50.13283168812254]],
    [ "Enedis",[2.843615333572779, 42.627985800373615]],
    [ "Enedis",[2.8463964061591427, 42.62780856764856]],
    [ "Enedis",[2.8166063162324786, 42.628080906015235]],
    [ "Enedis",[-1.3367933433691725, 43.45862641649837]],
    [ "Enedis",[-1.330203, 43.4603973]],
    [ "Enedis",[2.8434667991422304, 42.627079349146506]],
    [ "Enedis",[3.1777721537289665, 50.52078394812852]],
    [ "Enedis",[-3.142661230881379, 48.83938928237244]],
    [ "Enedis",[2.9760931054142934, 42.66352098229279]],
    [ "Enedis",[2.9891418254911257, 42.67118629174065]],
    [ "Enedis",[3.2872398178174094, 50.46138730942519]],
    [ "Enedis",[3.2959490435997774, 50.45210411656418]],
    [ "Enedis",[2.98954314448337, 42.665198688290076]],
    [ "Enedis",[2.99547846213149, 42.66861012038884]],
    [ "Enedis",[3.287637813900252, 50.45847292910521]],
    [ "Enedis",[2.9908112335284978, 42.65859003309975]],
    [ "Enedis",[2.9873111503344476, 42.66541081163419]],
    [ "Enedis",[3.2839555714522546, 50.45715889159758]],
    [ "Enedis",[2.970918935710097, 42.65468646735557]],
    [ "Enedis",[3.2961944449982656, 50.45793959608447]],
    [ "Enedis",[2.994114315934204, 42.66655837665309]],
    [ "Enedis",[2.9858437332044114, 42.66457577800215]],
    [ "Enedis",[3.268982707246376, 50.446922340579704]],
    [ "Enedis",[2.985239770778715, 42.669413157389116]],
    [ "Enedis",[2.9964088805473117, 42.65513848450674]],
    [ "Enedis",[2.985791862536889, 42.66734520701487]],
    [ "Enedis",[2.9915145319502088, 42.664999291910824]],
    [ "Enedis",[3.3027621712208837, 44.022824671116]],
    [ "Enedis",[3.1325890437720023, 50.621078336257554]],
    [ "Enedis",[3.125897850028105, 50.616047211669475]],
    [ "Enedis",[3.1326936039288173, 50.61791013627369]],
    [ "Enedis",[3.1340565397749423, 50.61794069935743]],
    [ "Enedis",[3.136586225436854, 50.618977843827615]],
    [ "Enedis",[3.127888683103162, 50.61900985277939]],
    [ "Triolo",[3.1369685304928794, 50.620419195403144]],
    [ "Enedis",[3.135341855071417, 50.62212204188564]],
    [ "Enedis",[3.123815380729479, 50.623271896990396]],
    [ "Enedis",[3.122251176032058, 50.623286395463865]],
    [ "Enedis",[3.133991201866154, 50.62343134759393]],
    [ "Enedis",[3.1772641082457835, 50.73289628175745]],
    [ "Enedis",[3.1787071221329275, 50.734902027503374]],
    [ "Enedis",[3.177217965815309, 50.730599994597696]],
    [ "Enedis",[3.140816110323536, 50.724968730346724]],
    [ "Enedis",[3.174920813348237, 50.61870943752562]],
    [ "Enedis",[6.990363291053128, 47.500131209490995]],
    [ "Enedis",[3.1613875940787923, 50.62011596520017]],
    [ "Enedis",[3.1621975947100203, 50.61902229533896]],
    [ "Enedis",[3.1666056534225437, 50.620335821341605]],
    [ "Enedis",[3.163826489054773, 50.62117780853425]],
    [ "Enedis",[6.98423045598086, 47.49640311743474]],
    [ "Enedis",[6.98831148255193, 47.508434832418864]],
    [ "Triangle",[3.165727354606195, 50.617661057052565]],
    [ "Enedis",[3.171797332672962, 50.61861535198584]],
    [ "Quennelet",[3.1608892415881544, 50.62147519983048]],
    [ "Enedis",[7.012332499999999, 47.504976600000006]],
    [ "Enedis",[7.008240649999999, 47.509730399999995]],
    [ "Enedis",[6.99899771878809, 47.50821045374412]],
    [ "Enedis",[7.00492955, 47.508053000000004]],
    [ "Enedis",[6.992458623348339, 47.50684346477579]],
    [ "Enedis",[6.9942077009934485, 47.504594116693085]],
    [ "Enedis",[6.999864746937688, 47.51552956111652]],
    [ "Enedis",[7.003571562307819, 47.51256072749606]],
    [ "Enedis",[7.001096171434773, 47.51220536562144]],
    [ "Enedis",[7.0085658842859395, 47.514439872904916]],
    [ "Enedis",[7.0011705061500615, 47.51499736974169]],
    [ "Enedis",[7.00150687384111, 47.51977474078492]],
    [ "Enedis",[7.0089137711810165, 47.51248693687257]],
    [ "Enedis",[4.816467810862457, 44.14337102463396]],
    [ "Enedis",[4.817933599999999, 44.139428699999996]],
    [ "Enedis",[7.004134698648453, 47.50673472508506]],
    [ "Enedis",[7.0081269499999985, 47.50283285]],
    [ "Enedis",[7.0041870710310805, 47.50374963921679]],
    [ "Edison",[2.360369442465139, 48.829127102895676]],
    [ "Enedis",[1.705052364501511, 43.39806233062909]],
    [ "Enedis",[6.83428226620339, 47.643272406103286]],
    [ "Enedis",[6.836549825790229, 47.63752650875341]],
    [ "Enedis",[6.838171584826627, 47.645019467747446]],
    [ "Enedis",[6.842794437008687, 47.637953281318154]],
    [ "Enedis",[2.9314512304869464, 50.71192637856495]],
    [ "Enedis",[2.957616983733899, 50.69678120249764]],
    [ "Enedis",[2.9348110433694456, 50.70862136218313]],
    [ "Enedis",[2.9331576, 50.70802435000001]],
    [ "Enedis",[2.1956873783153825, 48.675333653960564]],
    [ "Enedis",[2.1938136999999998, 48.678905799999995]],
    [ "Enedis",[2.199752167082006, 48.680299702837466]],
    [ "Enedis",[2.1971698999999996, 48.678734299999995]],
    [ "Enedis",[2.2039933, 48.681359549999996]],
    [ "FANTOMAS",[2.1965728999999996, 48.677294200000006]],
    [ "Misérables",[2.382116498001915, 48.817908693283485]],
    [ "Enedis",[2.3853524021032335, 48.80914938305585]],
    [ "Mozart",[2.370717850006903, 48.816814589848434]],
    [ "Enedis",[2.378567145805264, 48.82096000006884]],
    [ "79151128",[2.382258699684637, 48.807963001499715]],
    [ "Enedis",[2.3918554234769633, 48.81240736907797]],
    [ "Grand Champ",[6.081841316685852, 46.11732934341927]],
    [ "Feigères Nord",[6.081600043164325, 46.11448280507897]],
    [ "Enedis",[6.084727396911269, 46.10217513378299]],
    [ "Chez Jolliet",[6.071488367025223, 46.12305615437164]],
    [ "Groassaz",[6.0665377, 46.11844030000001]],
    [ "Les sorbiers",[6.077521996410257, 46.123265393422166]],
    [ "Enedis",[6.874530370076904, 47.64017907602208]],
    [ "Enedis",[6.8871493256471945, 47.64249137453293]],
    [ "Enedis",[6.883962808360212, 47.64332147791749]],
    [ "Enedis",[6.879183485952129, 47.64183058891115]],
    [ "Enedis",[6.875961060888737, 47.63934994304771]],
    [ "Enedis",[6.868236509780536, 47.644937598231465]],
    [ "Enedis",[6.877987159371724, 47.640330980224775]],
    [ "Enedis",[6.882913880523991, 47.64103579561631]],
    [ "Enedis",[6.874842945160018, 47.64437071727411]],
    [ "Enedis",[0.14302099999999907, 49.50690604999999]],
    [ "Enedis",[7.498876750000003, 48.39959325]],
    [ "Enedis",[7.498845195873273, 48.3955075022657]],
    [ "Enedis",[7.493980349999999, 48.396860700000005]],
    [ "Enedis",[4.95469167084666, 45.65546370093671]],
    [ "Enedis",[4.4473316781252255, 45.47985049358227]],
    [ "RTE",[4.844358258820498, 45.706501804783784]],
    [ "Enedis",[6.154361392539915, 48.697126400500366]],
    [ "Enedis",[6.158660468160985, 48.68804383697094]],
    [ "Enedis",[6.149925906498128, 48.697656790294154]],
    [ "Enedis",[6.157923888975826, 48.692955888670994]],
    [ "Enedis",[6.144640497045287, 48.69436099239086]],
    [ "Enedis",[6.155894625595574, 48.690204933415785]],
    [ "Enedis",[6.139691447932592, 48.69055428503203]],
    [ "Enedis",[6.157048935005933, 48.687990876768104]],
    [ "Enedis",[6.1467637537361925, 48.69665512274204]],
    [ "Enedis",[6.1461897067236855, 48.68952172179494]],
    [ "Enedis",[6.16183219165043, 48.698663685490295]],
    [ "Enedis",[6.15188168384432, 48.69576389427248]],
    [ "Enedis",[6.161074757428918, 48.688936905245484]],
    [ "Pompes Beauregard",[6.142683897117777, 48.6909612289468]],
    [ "Enedis",[6.159522567734187, 48.69778345654123]],
    [ "Enedis",[6.1494944478825815, 48.691443960051295]],
    [ "Enedis",[6.15940974438618, 48.689964301491585]],
    [ "Enedis",[6.160944782731766, 48.69460923883657]],
    [ "Enedis",[4.822950312023001, 44.135192223069794]],
    [ "Enedis",[4.8169713000000005, 44.137740949999994]],
    [ "Enedis",[4.809869778235757, 44.13887680041696]],
    [ "Enedis",[4.81358645, 44.1328549]],
    [ "79252146",[4.827678560731402, 44.122885794356264]],
    [ "Acadie",[4.824905048865336, 44.1229975617545]],
    [ "Enedis",[4.8277316099860235, 44.13383182073192]],
    [ "Enedis",[4.831590204267768, 44.12873613759963]],
    [ "Marronnier n•33",[4.82282715, 44.1287]],
    [ "Enedis",[4.8206490825986705, 44.13245964219713]],
    [ "Veynes",[4.816906899999999, 44.132793199999995]],
    [ "79254003",[4.81260701689562, 44.131652657184176]],
    [ "Enedis",[4.830633731754564, 44.13403624659911]],
    [ "Pourtoules",[4.812666785238251, 44.13559777372213]],
    [ "Enedis",[4.81494775, 44.12829185]],
    [ "Enedis",[4.818074900000001, 44.124549900000005]],
    [ "Enedis",[4.833542223560035, 44.130288056472374]],
    [ "Enedis",[4.815278522653563, 44.13461971166112]],
    [ "Enedis",[4.820196537132167, 44.12668258908951]],
    [ "EDF",[4.838810900829669, 44.12875061882868]],
    [ "Enedis",[4.821462731811172, 44.123731535854375]],
    [ "Enedis",[4.833635974327338, 44.12220919368768]],
    [ "CES-CET de l'Argensol",[4.826233359043091, 44.1316901259262]],
    [ "Enedis",[4.8215252112449045, 44.12688881759274]],
    [ "Enedis",[4.828914849999999, 44.118507300000005]],
    [ "Toulouse-Lautrec",[2.316180149999999, 48.76694315000001]],
    [ "Enedis",[2.318898859838377, 48.76610802265603]],
    [ "Enedis",[-1.3077510643019266, 46.33429386723223]],
    [ "Enedis",[-1.314237247657361, 46.335042717249536]],
    [ "Enedis",[-2.5510907732911203, 48.57849559228957]],
    [ "Enedis",[-1.3020880997840922, 46.32945433332754]],
    [ "Enedis",[-1.2981458499999998, 46.33036015]],
    [ "Enedis",[-2.5506504618925643, 48.58046447326601]],
    [ "Enedis",[-1.3097987055895932, 46.33369814644586]],
    [ "Enedis",[-1.9665521994242918, 48.1390966605632]],
    [ "Enedis",[-1.969246686947231, 48.14014797903599]],
    [ "Enedis",[-1.9656262482216875, 48.142172058387885]],
    [ "Enedis",[-1.9670436909078832, 48.14385236268705]],
    [ "Eaux Belles",[6.221901433486653, 46.17508637747781]],
    [ "Enedis",[6.227054641256913, 46.17979414970327]],
    [ "Lot Montant",[6.226294117140261, 46.178570998131555]],
    [ "Enedis",[6.196612616854066, 46.167801555129415]],
    [ "Enedis",[6.193182687139117, 46.169753726855504]],
    [ "Enedis",[5.0072692465055555, 45.64893730549116]],
    [ "Enedis",[5.006495840032063, 45.64415582985774]],
    [ "Enedis",[5.001152693853021, 45.64168010094524]],
    [ "Enedis",[5.02625628135015, 45.64487544362061]],
    [ "Enedis",[5.005909633120571, 45.64602500664611]],
    [ "Enedis",[5.02121126869619, 45.646806210012414]],
    [ "Enedis",[5.016475572578402, 45.64839631371517]],
    [ "Enedis",[5.014897950221215, 45.64866165120569]],
    [ "Enedis",[4.997753992748959, 45.6424336997577]],
    [ "Enedis",[5.001991793905778, 45.661520095715126]],
    [ "Enedis",[4.996969406712351, 45.63972119301303]],
    [ "Enedis",[5.013172410222524, 45.647020754503416]],
    [ "Enedis",[5.009472889202473, 45.646853971516336]],
    [ "Enedis",[5.01110830814901, 45.64579187382614]],
    [ "Enedis",[5.0098236139833485, 45.64383340126473]],
    [ "Enedis",[5.005224167108609, 45.65990664767472]],
    [ "Enedis",[6.144268252994336, 48.678173090263805]],
    [ "Enedis",[6.1529678371418175, 48.67620629050234]],
    [ "Enedis",[6.1433995167274995, 48.68320345082141]],
    [ "Enedis",[6.138230808149488, 48.68669230457505]],
    [ "Enedis",[6.141478954769606, 48.67597130471568]],
    [ "Enedis",[6.144851916984883, 48.68708339894519]],
    [ "Enedis",[6.152027768906069, 48.67819006047838]],
    [ "Enedis",[6.141961860539355, 48.67775067897828]],
    [ "Enedis",[2.5333801684260746, 42.78150432226628]],
    [ "Enedis",[2.8695157963472955, 42.57910829866639]],
    [ "Enedis",[2.865080836004607, 42.58287860104547]],
    [ "Enedis",[-1.3021276499999999, 46.33158294999999]],
    [ "Enedis",[-1.2972803500000005, 46.32738785]],
    [ "Enedis",[-1.3193195500000003, 46.33154695]],
    [ "Enedis",[3.8778406981918003, 50.15182994343244]],
    [ "Enedis",[2.229045885596908, 48.909850997405314]],
    [ "Enedis",[2.228830815559027, 48.90904355573828]],
    [ "Bricole",[2.23015155, 48.9082349]],
    [ "Enedis",[3.155930047622126, 50.61959138542029]],
    [ "Enedis",[3.1588125104320075, 50.61884500234038]],
    [ "Enedis",[3.1430914257437337, 50.62160706763069]],
    [ "Enedis",[3.1443288496942485, 50.61914591774468]],
    [ "Enedis",[3.153261308819062, 50.618033725845066]],
    [ "Enedis",[3.1548132267054934, 50.62126532536291]],
    [ "Enedis",[3.1408646390010624, 50.61957212520594]],
    [ "Enedis",[3.146677460617822, 50.621819804549794]],
    [ "Enedis",[3.1596695, 50.6087228]],
    [ "Enedis",[3.160422610368157, 50.60822872136355]],
    [ "Enedis",[3.161400410184364, 50.607793584085364]],
    [ "Enedis",[3.1323523774213933, 50.60625481009111]],
    [ "Enedis",[3.1568405353291373, 50.60587724346942]],
    [ "Enedis",[3.1348472985110725, 50.61047993655019]],
    [ "Enedis",[3.1495285499999994, 50.604728]],
    [ "Enedis",[3.1447964, 50.6034467]],
    [ "Enedis",[3.1512115894523283, 50.60937471685656]],
    [ "Enedis",[3.137935216767272, 50.61098118334316]],
    [ "Enedis",[3.1484964349787687, 50.61111925275711]],
    [ "Enedis",[3.1414586645742517, 50.61362346047911]],
    [ "Enedis",[5.222646341512393, 46.36424689596952]],
    [ "Enedis",[5.266316488465824, 46.36413873022179]],
    [ "Enedis",[3.1816011639552957, 50.69242578913339]],
    [ "Enedis",[3.1864395733970987, 50.689041716398606]],
    [ "Enedis",[3.1837559885758338, 50.69322013148304]],
    [ "Enedis",[3.1838934686197504, 50.694499806637225]],
    [ "Enedis",[3.1875198921440466, 50.695932885640474]],
    [ "Enedis",[3.183210755563409, 50.695429583383834]],
    [ "Enedis",[3.189263341961895, 50.69016042715653]],
    [ "Enedis",[3.1863823365862407, 50.69360348665277]],
    [ "Enedis",[3.1821343335182872, 50.697942518968716]],
    [ "Enedis",[3.18715062297958, 50.694038890399]],
    [ "Enedis",[3.1573799912195284, 50.61303543176801]],
    [ "Enedis",[3.157066961156025, 50.61328841063121]],
    [ "Enedis",[3.1501649255176516, 50.613551022714184]],
    [ "Enedis",[3.168529048729158, 50.614913287801606]],
    [ "Enedis",[3.1616590618962372, 50.611864695952015]],
    [ "Enedis",[3.1634029751960813, 50.61035147900885]],
    [ "Enedis",[3.165014904773427, 50.612371422019365]],
    [ "Cousin",[3.1619755, 50.6155792]],
    [ "Enedis",[3.1650797335127248, 50.68211598977469]],
    [ "Enedis",[3.157312794899839, 50.61671517916825]],
    [ "Enedis",[3.145886, 50.613700050000006]],
    [ "Enedis",[3.168522697628129, 50.6101703831979]],
    [ "Evisa",[3.1503387, 50.61559665]],
    [ "Enedis",[3.161561479390903, 50.68642063984409]],
    [ "Enedis",[3.1759323757043973, 50.68618275802652]],
    [ "Enedis",[3.176120808145363, 50.68620536732628]],
    [ "Enedis",[3.1619373, 50.68495469999999]],
    [ "Enedis",[3.1720205595784505, 50.67256945292731]],
    [ "Enedis",[0.8951115731769659, 47.57073597589837]],
    [ "RTE",[2.5348059089050663, 45.79779188340481]],
    [ "Enedis",[7.282653500000002, 47.7384386]],
    [ "Enedis",[7.280569323440097, 47.73847166190853]],
    [ "Enedis",[7.285501653460077, 47.73681892795945]],
    [ "Enedis",[7.254074719720374, 47.736585594595866]],
    [ "Enedis",[7.269330916841289, 47.73471655116496]],
    [ "Enedis",[7.260788559184827, 47.737160431663355]],
    [ "Enedis",[7.2723361, 47.733887800000005]],
    [ "Enedis",[7.267285363180596, 47.73794092385394]],
    [ "Rue de l'Église",[7.272523100000001, 47.7370761]],
    [ "Enedis",[7.285519349999999, 47.7392813]],
    [ "Enedis",[7.257697200000001, 47.736086799999995]],
    [ "Source",[7.277730699999999, 47.73433025]],
    [ "Enedis",[4.798680642238825, 45.71549847291709]],
    [ "Enedis",[-1.4522449408461642, 43.33894761317349]],
    [ "Enedis",[-1.45453565, 43.34185755]],
    [ "Enedis",[-1.4499743, 43.34241465]],
    [ "BOURG",[-1.446729200000001, 43.34016284999999]],
    [ "Enedis",[-1.4505822499999999, 43.3459456]],
    [ "Enedis",[-1.44386155, 43.341171949999996]],
    [ "Enedis",[-1.4492619442178538, 43.33749768158293]],
    [ "Enedis",[-1.3357939936143592, 43.484363340525796]],
    [ "Enedis",[-1.3372574649306852, 43.485847329368525]],
    [ "Enedis",[-1.3592027749397948, 43.48819555020556]],
    [ "Enedis",[6.848997155050944, 47.620951067035776]],
    [ "Enedis",[6.85986411816957, 47.63005934827076]],
    [ "Enedis",[6.856966689858803, 47.62936563383826]],
    [ "Enedis",[6.857793498042867, 47.6321754169265]],
    [ "Enedis",[6.863763177377743, 47.63201743750076]],
    [ "Enedis",[6.847658200571558, 47.62278480169181]],
    [ "Enedis",[6.848885460058117, 47.63071382012105]],
    [ "Enedis",[6.864253043525761, 47.62993497596447]],
    [ "Enedis",[6.860168770555122, 47.626758717473066]],
    [ "Enedis",[6.8526976663091705, 47.63048305434525]],
    [ "Enedis",[6.858228815865808, 47.62711385447803]],
    [ "Enedis",[6.852485122193123, 47.62475640018996]],
    [ "Enedis",[6.851425410650426, 47.62881129293709]],
    [ "Enedis",[4.796591964655317, 45.715315548218626]],
    [ "Enedis",[4.800526360738862, 45.71633351508261]],
    [ "Enedis",[3.187370677804335, 50.67860319898025]],
    [ "Enedis",[3.1894591819123823, 50.67209133560964]],
    [ "Enedis",[3.1836083427279473, 50.67766437129827]],
    [ "Enedis",[3.1823463443243987, 50.674103563379184]],
    [ "Enedis",[3.1907436912069467, 50.67823698575403]],
    [ "Enedis",[3.1845720972906406, 50.67526350035438]],
    [ "Poste électrique du Crouzet",[2.2289780444688585, 44.54652374861628]],
    [ "Enedis",[5.495552161093467, 45.423764528865014]],
    [ "Enedis",[5.47238521223324, 45.42205784347956]],
    [ "Enedis",[-1.3163411676157954, 46.33903389727275]],
    [ "Enedis",[-1.3085883815957606, 46.33884894629048]],
    [ "Enedis",[-1.3128087327151872, 46.33929879806934]],
    [ "Enedis",[-1.314954723654493, 46.34166625533814]],
    [ "Enedis",[5.261697356858569, 44.18310246977458]],
    [ "Enedis",[5.268464000000001, 44.183912600000006]],
    [ "Les Vannes",[5.517524769354341, 45.432820860735255]],
    [ "Poste électrique Denfert",[2.334383637966903, 48.83269574615295]],
    [ "Enedis",[5.766437057585052, 45.09271199506413]],
    [ "Enedis",[5.764768540006894, 45.094875998425415]],
    [ "Enedis",[5.759063053391932, 45.09577743565473]],
    [ "Enedis",[-1.510875410620368, 43.448478724866746]],
    [ "Enedis",[-1.5046410871107103, 43.44161706241256]],
    [ "Enedis",[-1.512274390598549, 43.44656010509187]],
    [ "Enedis",[-1.5016618151738121, 43.44382257074502]],
    [ "Enedis",[-1.3934624032700862, 43.486958066931805]],
    [ "Enedis",[-1.39063875, 43.48924560000001]],
    [ "Enedis",[-1.3923068399418748, 43.4818277031093]],
    [ "Enedis",[0.27987181185584126, 49.49389296430582]],
    [ "Enedis",[0.3013685142179895, 49.5069456821631]],
    [ "Enedis",[0.27578490883661005, 49.4943260909124]],
    [ "Enedis",[0.30349515000000005, 49.508647550000006]],
    [ "Enedis",[6.3109850340458395, 46.16264914488421]],
    [ "Enedis",[5.4500836, 46.82782845]],
    [ "Enedis",[3.112883160382005, 50.61772996380526]],
    [ "Enedis",[3.1097034962948777, 50.61135200183541]],
    [ "Enedis",[3.114071915708371, 50.61594857182592]],
    [ "Enedis",[3.11649800398599, 50.617106315870906]],
    [ "Le Mas de Cernille",[5.565228983755141, 45.40715424972194]],
    [ "Les Cotins",[5.548747957382442, 45.41892120676271]],
    [ "Pagère",[5.546982817384937, 45.41595198802504]],
    [ "Pompage",[5.5601254, 45.418328100000004]],
    [ "Les Galbits",[5.564397781779568, 45.40524314791453]],
    [ "Colombier",[5.552838194695322, 45.41614281915899]],
    [ "Enedis",[3.117768814086406, 50.61239003335936]],
    [ "Enedis",[3.112455195515574, 50.61088403039474]],
    [ "Enedis",[3.1219961761031376, 50.61350099610236]],
    [ "Garangere",[5.538443916572968, 45.412088149772636]],
    [ "Enedis",[3.1140645548355326, 50.612978950156396]],
    [ "Enedis",[2.3137240000000006, 48.763405]],
    [ "Enedis",[4.753016401544858, 46.94507486134017]],
    [ "Enedis",[6.855422253567186, 47.644532004284656]],
    [ "Enedis",[6.8520391, 47.63479205]],
    [ "Enedis",[6.849032124906645, 47.638314983147694]],
    [ "Enedis",[6.852049270880945, 47.644857643114854]],
    [ "Enedis",[6.857840843804539, 47.63565354572426]],
    [ "Enedis",[6.860417299866312, 47.63725348504502]],
    [ "Enedis",[6.843943759625962, 47.643638709460944]],
    [ "Enedis",[6.862664019226748, 47.63391532833538]],
    [ "Enedis",[6.865127012152963, 47.64312957366111]],
    [ "Enedis",[6.851606077797416, 47.63335093185155]],
    [ "Peupliers",[2.2586092458761926, 48.91160502183825]],
    [ "Villebois",[2.266589100104391, 48.917077830393566]],
    [ "Tuiliere",[5.572138881895397, 45.39580132608499]],
    [ "Poste électrique d'Alésia",[2.3364292234503723, 48.82530327823553]],
    [ "Poste électrique René Coty (RER)",[2.3366715550974457, 48.82489402322404]],
    [ "Enedis",[3.180541131685306, 50.684776904588276]],
    [ "Enedis",[3.180278974906915, 50.68708488856001]],
    [ "Enedis",[3.1959511094855824, 50.67052108082847]],
    [ "Enedis",[3.199947466275357, 50.671902299700356]],
    [ "Enedis",[3.163929754361931, 50.69283925290696]],
    [ "Le Mail",[5.591860276565317, 45.365002981940336]],
    [ "Enedis",[3.1626529809157082, 50.69021555819531]],
    [ "Enedis",[3.168084176241224, 50.68990752786702]],
    [ "Enedis",[3.16561223621566, 50.69006610313002]],
    [ "Enedis",[3.161064950959807, 50.696061255730335]],
    [ "Enedis",[3.1660774927354747, 50.69343072550326]],
    [ "Enedis",[2.6089684692001076, 50.63895405312728]],
    [ "Enedis",[2.6247934638722743, 50.6493314343699]],
    [ "Enedis",[2.6270549499999998, 50.63771719999999]],
    [ "Enedis",[2.6010699775429096, 50.66068043739032]],
    [ "Enedis",[2.6358328749126736, 50.64534120465087]],
    [ "Enedis",[2.63616966039981, 50.64074343289308]],
    [ "Enedis",[2.6408756697229983, 50.64559353843625]],
    [ "Enedis",[2.6417359671770257, 50.62365828019731]],
    [ "Enedis",[2.619482977120045, 50.640919879747365]],
    [ "Enedis",[2.617159410797806, 50.639939578446096]],
    [ "Enedis",[2.641203875347893, 50.63611731152096]],
    [ "Enedis",[2.6472227490065645, 50.64492405558416]],
    [ "Enedis",[2.644726590750711, 50.65055544520741]],
    [ "Enedis",[2.6464178820049304, 50.64751277729598]],
    [ "Enedis",[2.6217722196644657, 50.63743077903144]],
    [ "Enedis",[2.6291429751989988, 50.64734748904392]],
    [ "Enedis",[2.6405666656658497, 50.62565035862407]],
    [ "Enedis",[2.047739189663585, 47.24231614318514]],
    [ "Enedis",[2.0486274721549074, 47.241686151444235]],
    [ "Poste électrique du Chesnoy",[2.8668011257885238, 48.389321720092845]],
    [ "RTE",[2.7384331147671666, 48.43603025095055]],
    [ "Enedis",[2.7980918441523372, 48.39261051325209]],
    [ "Loisinges II",[6.275651199999997, 46.1100701]],
    [ "Enedis",[6.259427572178478, 46.09914341863517]],
    [ "Enedis",[6.278442147359859, 46.11336938227811]],
    [ "Les Vuardes",[6.280422177167559, 46.10796893762707]],
    [ "Les Moulins",[6.2419020102055525, 46.11698579141046]],
    [ "Roguet",[6.251149135691219, 46.092296848213635]],
    [ "Les Pauses",[6.284650381294162, 46.09681166659713]],
    [ "Enedis",[6.284803181333352, 46.10063274112686]],
    [ "Enedis",[6.1750738993320455, 48.70402887903143]],
    [ "Enedis",[6.179340887819338, 48.708161303582]],
    [ "Enedis",[6.183665431648647, 48.704648109047696]],
    [ "Enedis",[6.179380240546923, 48.70458056526912]],
    [ "Enedis",[6.176257213903712, 48.70618353453078]],
    [ "RTE",[3.0011990924195704, 48.569220538656715]],
    [ "RTE",[2.9433881792632715, 48.586210240528104]],
    [ "Enedis",[6.171266028515034, 48.702196017706825]],
    [ "Enedis",[6.1708489195828795, 48.69976345921835]],
    [ "Enedis",[6.1739867595248565, 48.70191154092375]],
    [ "RTE",[2.9288166260493687, 48.60033682792727]],
    [ "Enedis",[3.1570403159675164, 50.6980245317894]],
    [ "Bordeaux-Centre",[-0.5771289083864424, 44.84008699288616]],
    [ "Enedis",[3.1933494731531327, 50.702902909622324]],
    [ "Enedis",[3.1794405040215774, 50.707217216189505]],
    [ "Enedis",[3.192655679261339, 50.69492531347629]],
    [ "Enedis",[6.274153912150978, 46.20355104847779]],
    [ "Guillot",[6.263840479817519, 46.199315863940114]],
    [ "Les Biches",[6.27067055, 46.20400624999999]],
    [ "SIVMAA",[6.268129646897632, 46.20415979242624]],
    [ "Enedis",[6.26511040136828, 46.20034982483844]],
    [ "Enedis",[3.1840701754189733, 50.68132259805806]],
    [ "Enedis",[3.1806962915702544, 50.682367719923356]],
    [ "Enedis",[3.1840584132423224, 50.682834154520734]],
    [ "Enedis",[6.2501832506159305, 46.191274213586425]],
    [ "Enedis",[6.253678235273978, 46.1934471783652]],
    [ "Enedis",[3.2119201510345907, 50.68406772852765]],
    [ "Enedis",[3.203596353076359, 50.68890545533467]],
    [ "Enedis",[3.2060776313666763, 50.689768296211945]],
    [ "Enedis",[3.2031765982883513, 50.68062723414534]],
    [ "Enedis",[3.2078048667957457, 50.67812376354503]],
    [ "Enedis",[3.213235822323411, 50.688203301021424]],
    [ "Enedis",[3.19186367968845, 50.691179003718204]],
    [ "Enedis",[3.1942653262824092, 50.68245508934259]],
    [ "Enedis",[-1.65328025, 47.98572025]],
    [ "Les Courrères",[-1.2427198757590683, 47.19592814137376]],
    [ "80171819",[-1.2651668882282736, 47.17529416056857]],
    [ "Enedis",[-1.666874335041695, 48.135516498430384]],
    [ "80184909",[-1.6664178451739897, 48.13725199424297]],
    [ "Enedis",[-1.6708114393919196, 48.132774827206944]],
    [ "80185065",[-1.668744197418521, 48.13248748332178]],
    [ "P1086 Condate",[-1.6724212387905784, 48.13571307154759]],
    [ "80185564",[-1.674348463139954, 48.132650037651736]],
    [ "Enedis",[-1.6795759845065041, 48.1232041728703]],
    [ "Enedis",[-1.6691060157843163, 48.12982953971388]],
    [ "Enedis",[-1.663445380760525, 48.13454814611438]],
    [ "Enedis",[0.710357041731085, 49.24018818814682]],
    [ "Enedis",[-1.6637009657151354, 48.129886862779664]],
    [ "Enedis",[-1.6569353401493443, 48.14070737790223]],
    [ "Enedis",[-1.6594315776767357, 48.13403511865324]],
    [ "Les Gayeulles",[-1.6517828794514615, 48.12844462826923]],
    [ "P 1479 Marin",[-1.6627533340422753, 48.13717190763027]],
    [ "Enedis",[2.45545298951896, 48.78373658031819]],
    [ "Enedis",[2.4639335016577237, 48.78731575791187]],
    [ "Enedis",[2.469484630010627, 48.78743866336976]],
    [ "Enedis",[2.831148967587498, 42.564062242960624]],
    [ "Enedis",[2.83201435, 42.56069785]],
    [ "Enedis",[2.839623994469133, 42.58597410810319]],
    [ "Enedis",[2.835787158635093, 42.58800803033727]],
    [ "Enedis",[2.833934200281308, 42.588988118303156]],
    [ "Enedis",[2.8340251870506514, 42.59088971914848]],
    [ "Enedis",[2.84412854218254, 42.589595077090735]],
    [ "Enedis",[2.8384969299752023, 42.588129907102775]],
    [ "Enedis",[2.1918941, 50.72386155]],
    [ "Enedis",[2.1961596999999995, 50.7264419]],
    [ "Enedis",[2.1851720587868897, 50.72842793128081]],
    [ "Enedis",[2.46584515, 48.7852278]],
    [ "Poste électrique de Castagnary",[2.310419264786614, 48.835658999370146]],
    [ "Enedis",[2.463435111015263, 48.789914281840005]],
    [ "Enedis",[-1.684870250000001, 48.1289951]],
    [ "Enedis",[-1.6871710500899528, 48.12766226637457]],
    [ "Enedis",[-1.6869340486766118, 48.13136729276158]],
    [ "Enedis",[-1.6845524314901572, 48.12374555525841]],
    [ "Enedis",[-1.68821675, 48.12288065]],
    [ "Enedis",[-1.682271229318999, 48.12735458438439]],
    [ "Enedis",[-1.6839994794679167, 48.13319463847105]],
    [ "Enedis",[-1.6818825946650615, 48.12444382832637]],
    [ "Enedis",[-1.6826202804313553, 48.132840991265915]],
    [ "Cloustière",[-1.6940577460412773, 48.13203589993551]],
    [ "Enedis",[-1.6929605236815317, 48.124251379124715]],
    [ "Enedis",[-1.7111831844557026, 48.12681096397909]],
    [ "Enedis",[-1.6986013060400307, 48.130024805229105]],
    [ "Enedis",[-1.6912134000000005, 48.13105475]],
    [ "Enedis",[-1.696792401394999, 48.12462309372393]],
    [ "Enedis",[-1.6973418366308322, 48.12934139474969]],
    [ "Enedis",[2.798917039791563, 50.45916755096551]],
    [ "Enedis",[2.785834987529149, 50.45231758318125]],
    [ "Enedis",[2.7952907413527446, 50.45259097584882]],
    [ "Enedis",[2.7969077715232022, 50.44651553803061]],
    [ "Enedis",[2.759144244142001, 50.44602853411036]],
    [ "Enedis",[2.7741234126055967, 50.45393643741921]],
    [ "Enedis",[2.7890023499999996, 50.450861849999995]],
    [ "Enedis",[2.7657160192880625, 50.45162092186809]],
    [ "Enedis",[2.7948745799003643, 50.46061464197482]],
    [ "Enedis",[2.805457949036211, 50.45537188334441]],
    [ "Enedis",[2.798376821800849, 50.450080255690985]],
    [ "Enedis",[2.8047457043086625, 50.458669725435826]],
    [ "Enedis",[2.8018877351366935, 50.446524157525516]],
    [ "Enedis",[2.805568090865172, 50.445735024198505]],
    [ "Enedis",[2.757871227675085, 50.451721659226976]],
    [ "Enedis",[2.7909905343503985, 50.4589178075069]],
    [ "Enedis",[2.798139892712173, 50.44815420555238]],
    [ "Enedis",[2.7877628164282253, 50.45804723064623]],
    [ "Enedis",[2.789776189085388, 50.45211941209361]],
    [ "Enedis",[2.760499722843308, 50.44407517857837]],
    [ "Enedis",[2.7612292008016888, 50.45231860482934]],
    [ "Enedis",[5.828021150028607, 45.20179653908563]],
    [ "Enedis",[2.799628596986302, 50.46105845926941]],
    [ "Enedis",[2.7901525955599222, 50.45639589077276]],
    [ "Enedis",[2.3127285132315563, 51.028246287164265]],
    [ "Enedis",[2.309644088325981, 51.03072240819607]],
    [ "Enedis",[2.307869355137163, 51.02681724131936]],
    [ "Enedis",[2.3151943393020655, 51.03062410730195]],
    [ "Enedis",[2.3027769741479163, 51.03038878760716]],
    [ "Enedis",[2.299916778360635, 51.027535283367584]],
    [ "Enedis",[2.314873110708436, 51.02919127687461]],
    [ "Enedis",[2.304690232563579, 51.027607304303324]],
    [ "Enedis",[4.895629316569237, 45.694108202254824]],
    [ "Enedis",[2.3172436814488906, 51.026340677628696]],
    [ "Enedis",[2.3086326810802458, 51.03057799220553]],
    [ "Enedis",[4.8894502716295385, 45.693764953812476]],
    [ "Enedis",[2.2987049435487927, 51.03145809375079]],
    [ "Enedis",[2.304907434434741, 51.02974805183193]],
    [ "Enedis",[2.3180696363899704, 51.029380478973124]],
    [ "Enedis",[-1.723703699755761, 48.12123376063502]],
    [ "Enedis",[-1.7126695225303403, 48.13427600009185]],
    [ "Enedis",[-1.7123415741113555, 48.13121937189116]],
    [ "Enedis",[-1.7236942026900686, 48.102585491823774]],
    [ "Enedis",[-1.7270632114067883, 48.101964146554046]],
    [ "Enedis",[-1.7336780568596737, 48.100932656016944]],
    [ "Enedis",[-1.7305810499999996, 48.11022355000001]],
    [ "Poste électrique de la Barre-Thomas",[-1.7256775388181245, 48.11039521360723]],
    [ "Enedis",[-1.7305896228568054, 48.106830451749616]],
    [ "Enedis",[-1.7332557306510519, 48.107100685795245]],
    [ "Enedis",[-1.723950705093099, 48.10646481835607]],
    [ "Enedis",[-1.7235718784229352, 48.103567572032645]],
    [ "Enedis",[-1.7340619817973486, 48.109321357368835]],
    [ "Enedis",[-1.7113378000000001, 48.11786145000001]],
    [ "Enedis",[2.5055796552792913, 50.500970178123595]],
    [ "Enedis",[2.507968998309399, 50.504449792321935]],
    [ "Enedis",[3.1791699409705583, 50.699482892313455]],
    [ "Enedis",[3.1714713323806576, 50.69968753725317]],
    [ "Enedis",[3.1799521224208998, 50.7051950593148]],
    [ "Enedis",[3.1763563965103208, 50.70647013659274]],
    [ "Enedis",[3.101040760481303, 50.61463256432668]],
    [ "Enedis",[3.1321543501929043, 50.603717516666386]],
    [ "Enedis",[3.135007943568381, 50.60202980932207]],
    [ "Enedis",[3.13329640445472, 50.6032859684437]],
    [ "Enedis",[3.1310655341150193, 50.60522164902289]],
    [ "Enedis",[5.598741870324909, 45.318054796768095]],
    [ "Enedis",[3.1734613335042, 50.6955789063755]],
    [ "Enedis",[3.1734412000000005, 50.69819615000001]],
    [ "Enedis",[-1.7107891362337173, 48.114810673411334]],
    [ "Enedis",[-1.7066332838987155, 48.11543614026148]],
    [ "Enedis",[-1.7042982559102013, 48.1139404955174]],
    [ "Enedis",[-1.7037815269136984, 48.1152221192918]],
    [ "Enedis",[1.3922792767393188, 47.52331832441774]],
    [ "Enedis",[1.375801681112175, 47.53385929529066]],
    [ "Enedis",[1.3792192737298599, 47.52676128863266]],
    [ "Enedis",[1.4072026000000002, 47.511894399999996]],
    [ "Enedis",[1.3856481683702553, 47.52360343589486]],
    [ "Enedis",[1.3777831500000002, 47.5340646]],
    [ "Enedis",[6.851755250513531, 47.554445619690476]],
    [ "Enedis",[6.847725143009505, 47.55300975152802]],
    [ "Enedis",[6.8507611732845985, 47.557000717301]],
    [ "Enedis",[6.845435416893158, 47.56076803248874]],
    [ "Enedis",[6.849161870718016, 47.55783588311423]],
    [ "Enedis",[6.853265949019607, 47.554666385087714]],
    [ "Enedis",[6.855639083728915, 47.55739870121363]],
    [ "Enedis",[6.852630514025444, 47.55951448389089]],
    [ "Enedis",[6.849610740131755, 47.561128550255226]],
    [ "Enedis",[6.851634898028315, 47.56280252111677]],
    [ "Enedis",[6.840032849999999, 47.55836695]],
    [ "Enedis",[6.851395824732163, 47.551739843778115]],
    [ "Enedis",[2.1538651371424375, 48.439946776916756]],
    [ "Enedis",[2.154401303617365, 48.439026628082175]],
    [ "Enedis",[2.163824380863514, 48.43393896099425]],
    [ "Enedis",[2.1155623999999995, 48.426826]],
    [ "Enedis",[2.167313649999999, 48.4257344]],
    [ "Enedis",[2.1601182246649047, 48.45227753369673]],
    [ "Enedis",[2.166923896113139, 48.43461005315671]],
    [ "Enedis",[4.863748083735324, 45.72482811735707]],
    [ "Enedis",[2.148618682781797, 48.42749216066937]],
    [ "Enedis",[2.1624158912973086, 48.43281806332735]],
    [ "Enedis",[4.8608458500000005, 45.72480475]],
    [ "Enedis",[2.1500152041178766, 48.4506324113388]],
    [ "Enedis",[4.855268674532243, 45.71923945510001]],
    [ "Enedis",[4.857346799999999, 45.7217175]],
    [ "Enedis",[2.167143107201414, 48.44571705887235]],
    [ "Enedis",[4.8637925828920805, 45.7232396873928]],
    [ "Enedis",[2.1363913298851855, 48.426218574104986]],
    [ "Enedis",[2.169348576003278, 48.43078914833758]],
    [ "Enedis",[4.865474173694729, 45.72589435381573]],
    [ "Enedis",[4.86102375, 45.72310145]],
    [ "Enedis",[4.86260965, 45.72112154999999]],
    [ "Enedis",[4.86322975, 45.72548550000001]],
    [ "Enedis",[2.146166839746849, 48.434908842767996]],
    [ "Enedis",[4.857683400000001, 45.72469915]],
    [ "Enedis",[2.1443917112421187, 48.412149652265995]],
    [ "Enedis",[2.17259921327205, 48.43271988437995]],
    [ "Enedis",[2.1648495388474935, 48.43877599480965]],
    [ "Enedis",[4.8657125, 45.723999549999995]],
    [ "Enedis",[2.1390965499999997, 48.438396449999985]],
    [ "Enedis",[2.134654670166854, 48.42765300598846]],
    [ "Enedis",[4.859977434343954, 45.7206865747259]],
    [ "Enedis",[4.857801467006237, 45.72709699418486]],
    [ "Enedis",[2.143493848721742, 48.42560061857968]],
    [ "Enedis",[4.862545250000001, 45.72018885000001]],
    [ "Enedis",[2.143279654357989, 48.43368077385865]],
    [ "Enedis",[4.870305227377108, 45.72452319285971]],
    [ "Enedis",[2.160506734365894, 48.43525268658674]],
    [ "Enedis",[2.1355451010996847, 48.427561131343104]],
    [ "Enedis",[2.1466587725720614, 48.43264109948776]],
    [ "Enedis",[2.1425509, 48.4347304]],
    [ "Enedis",[2.1605854433393445, 48.43425806065841]],
    [ "Enedis",[2.1708979969141113, 48.43227961031247]],
    [ "Enedis",[2.162153090393437, 48.43759325762083]],
    [ "Enedis",[2.176365628847241, 48.43251643523907]],
    [ "Enedis",[2.1595451296971495, 48.45010028714033]],
    [ "Enedis",[2.149608848149025, 48.451972383851285]],
    [ "Enedis",[2.1476203633363817, 48.452234372975454]],
    [ "Enedis",[2.1690522287141074, 48.42807153333334]],
    [ "Enedis",[2.1711924306459767, 48.450955158695265]],
    [ "Enedis",[2.1698289177005514, 48.43842664652365]],
    [ "Enedis",[2.140453827769454, 48.42433238491052]],
    [ "Enedis",[2.169563373729193, 48.44891586544185]],
    [ "Enedis",[2.146447631643194, 48.42132490267597]],
    [ "Enedis",[2.1663998468038246, 48.42941894737606]],
    [ "Enedis",[2.124067333824338, 48.54660521265599]],
    [ "Enedis",[2.113776572388751, 48.54544470206984]],
    [ "Enedis",[2.121334977032445, 48.55163341730257]],
    [ "Enedis",[2.1175114966663955, 48.55413301652925]],
    [ "Enedis",[2.0214105150589203, 48.520767020296596]],
    [ "Enedis",[2.022454391886953, 48.524512501988234]],
    [ "Enedis",[2.008927727067627, 48.52940869425088]],
    [ "Enedis",[2.0022527299045856, 48.53712768826198]],
    [ "Enedis",[2.013550866394154, 48.52270981000217]],
    [ "Enedis",[2.0174324139176942, 48.52342858094613]],
    [ "Enedis",[2.00147134747087, 48.53433604201428]],
    [ "Enedis",[2.0235719849123934, 48.525791362263156]],
    [ "Enedis",[2.5737841466253646, 49.08527762799778]],
    [ "Chef Lieu",[6.4138896999999995, 45.92313645]],
    [ "Répartition St Jean",[6.409590001597581, 45.925282451579186]],
    [ "Bersat",[6.2769872725215405, 46.125966014936125]],
    [ "Les Balcoms dEsery",[6.237193065151681, 46.146383022618394]],
    [ "Enedis",[6.281341086758684, 46.12603128169489]],
    [ "Enedis",[6.249137788848463, 46.142979732596906]],
    [ "Enedis",[6.2455292, 46.150850649999995]],
    [ "Melaz",[6.258010550000001, 46.134494200000006]],
    [ "Enedis",[6.23596375603347, 46.14101148480652]],
    [ "Enedis",[2.7858653190900196, 48.40821011111371]],
    [ "Enedis",[5.611039652517827, 45.311653516000085]],
    [ "Charbonnier",[5.625845924591861, 45.30248751319858]],
    [ "80577195",[5.651461804136424, 45.26604914508543]],
    [ "Enedis",[5.618558390413573, 45.303466331093375]],
    [ "Enedis",[5.6173812075581, 45.30354648612315]],
    [ "Les Jardins de Victorine",[5.637850312303091, 45.30253643351056]],
    [ "Abattoirs",[5.638530659038439, 45.300116851069866]],
    [ "Stalingrad",[5.631923415411603, 45.29343169569057]],
    [ "Les Lauriers",[5.634923913462519, 45.29576166959125]],
    [ "Terrasses Château",[5.643311733397546, 45.29053013404885]],
    [ "Le Chevallon",[5.649177435847178, 45.27906919168311]],
    [ "Beauplan",[5.633139654737549, 45.27467090076516]],
    [ "Enedis",[5.621953379196845, 45.28993155110585]],
    [ "Enedis",[5.624810581118087, 45.27789591447809]],
    [ "Courte Paille",[5.625142350000001, 45.28897]],
    [ "Enedis",[5.621739563522057, 45.29090433852116]],
    [ "Enedis",[5.622189928048613, 45.28743651820599]],
    [ "Volouise 1",[5.642530031600384, 45.28571257939842]],
    [ "Rhonalcop 3",[5.64137445188281, 45.286155328892605]],
    [ "Rhonalcop 1",[5.641234865892534, 45.282600776829995]],
    [ "Volouise 2",[5.642195320174656, 45.28476095537041]],
    [ "Rhonalcop 2",[5.640279894677266, 45.284294887473735]],
    [ "Morletière",[5.646051164352969, 45.275221862528035]],
    [ "Les Platanes",[5.6455953, 45.2773867]],
    [ "Chassolière",[5.6458028892834555, 45.280834885901065]],
    [ "Jardin du Chevalon",[5.649395137261804, 45.27400614362705]],
    [ "Givors",[5.647662399999998, 45.2778637]],
    [ "Enedis",[2.7539732488554907, 50.64286132975825]],
    [ "Enedis",[2.7141253764612485, 50.633322671511614]],
    [ "Enedis",[2.7353562737200106, 50.63542211647997]],
    [ "Enedis",[2.748195611456635, 50.641433531547435]],
    [ "Pre des Cours",[6.216538142076589, 46.09103855977577]],
    [ "Chez Vachoux",[6.215638304264085, 46.09916684826243]],
    [ "Biollay",[6.190712704405823, 46.07614126556943]],
    [ "Presbytere",[6.218102096578817, 46.09220080643009]],
    [ "Enedis",[2.9277353693141386, 42.60843554783958]],
    [ "Enedis",[2.920308284210945, 42.607022776210016]],
    [ "Enedis",[2.936684411581651, 42.606337994320135]],
    [ "Enedis",[2.9255526870137207, 42.609254011859385]],
    [ "Enedis",[2.9362029667733385, 42.60831811282832]],
    [ "RTE",[2.835944346436791, 45.92124159184099]],
    [ "Enedis",[2.472013487004162, 49.25623838118269]],
    [ "Enedis",[2.47253595901651, 49.26606810031146]],
    [ "Enedis",[2.47209786996951, 49.24717985667064]],
    [ "Enedis",[2.5025384166982736, 49.245383522859946]],
    [ "Enedis",[2.5012264759107246, 50.508978787623704]],
    [ "Enedis",[-1.6371502515930665, 48.123096493422025]],
    [ "Donzelot",[-1.6394323906303567, 48.12348898045954]],
    [ "Enedis",[5.4845169884733025, 47.086700537842816]],
    [ "Enedis",[5.4871400999999995, 47.08703825]],
    [ "Enedis",[5.4769334, 47.081585549999986]],
    [ "Enedis",[5.47559905, 47.0947655]],
    [ "Enedis",[5.488924999999999, 47.09040625]],
    [ "Paix",[5.495468982636694, 47.09631249723685]],
    [ "Enedis",[5.5028437, 47.09741715]],
    [ "Chardonet",[-1.6455163399098383, 48.10754478104388]],
    [ "Madeleine",[-1.6421159046039273, 48.107506407174256]],
    [ "Poste électrique de Saint-Victor",[2.804404423825332, 44.05121202333057]],
    [ "Enedis",[-1.6330413142612685, 48.09122777157079]],
    [ "Enedis",[-1.633920269172389, 48.09295703403061]],
    [ "Enedis",[2.077400299563631, 47.20534119193695]],
    [ "Enedis",[2.707104439125274, 45.64836781468254]],
    [ "Geredis",[0.02139988257752043, 46.12164053981671]],
    [ "Enedis",[2.3197771523075335, 45.55651446768636]],
    [ "RTE",[5.753378544352549, 46.90108535795838]],
    [ "Enedis",[5.694168250000001, 46.8500524]],
    [ "Enedis",[5.6973460573304155, 46.84805532924872]],
    [ "Enedis",[5.705810949002076, 46.83276008090856]],
    [ "Enedis",[5.7160753500000006, 46.831719350000014]],
    [ "Enedis",[5.711924842786251, 46.8325753728596]],
    [ "Enedis",[5.70175295, 46.830992949999995]],
    [ "Enedis",[5.699040366419507, 46.83420396646397]],
    [ "Enedis",[5.696430883430606, 46.83953275022741]],
    [ "Enedis",[5.698554950000001, 46.841271899999995]],
    [ "Enedis",[5.6918940000000005, 46.8490029]],
    [ "Enedis",[5.6975157372994785, 46.84921267420324]],
    [ "Enedis",[5.707491920293939, 46.83431553911903]],
    [ "Enedis",[5.703821305310371, 46.83390263112269]],
    [ "Enedis",[5.699842450000001, 46.838050100000004]],
    [ "Enedis",[5.713687934993226, 46.833286566739474]],
    [ "Enedis",[5.694911449999999, 46.8449862]],
    [ "80784318",[5.7019183999999985, 46.845859600000004]],
    [ "Enedis",[5.692139075728715, 46.84236909668563]],
    [ "Enedis",[2.4906435499999993, 49.270923]],
    [ "Enedis",[2.4613282623805, 48.803528668997835]],
    [ "Enedis",[2.4607174275599077, 49.27916435618038]],
    [ "Enedis",[2.4583780333402645, 48.79977705002325]],
    [ "Enedis",[2.463941789364246, 48.805191176201184]],
    [ "Enedis",[2.4766594772558514, 49.274057131180875]],
    [ "Enedis",[2.46516157927682, 48.80383873540302]],
    [ "Enedis",[2.4743658499999994, 49.27006039999999]],
    [ "Enedis",[2.4580380920007556, 49.28191007156048]],
    [ "Enedis",[2.4686816152673274, 49.27348774983945]],
    [ "Gérédis",[-0.05945206667201133, 46.49907836351698]],
    [ "Guynemer",[-1.6889351660048024, 48.09267581809629]],
    [ "Enedis",[2.4565310359078283, 48.795875039669575]],
    [ "Enedis",[2.46191765, 48.79883664999999]],
    [ "Enedis",[5.575030302965295, 45.5503763573097]],
    [ "Enedis",[-1.6799270999999991, 48.09363825]],
    [ "Leguen",[-1.6845047414577907, 48.08984543221057]],
    [ "80830774",[-0.5841885148870262, 46.733303009622425]],
    [ "80832814",[-0.5809340939075526, 46.73136451602818]],
    [ "Lobineau",[-1.6844175878444287, 48.09688852893213]],
    [ "Enedis",[-1.6788269999999996, 48.094200349999994]],
    [ "Enedis",[-1.653819794147338, 48.09350671247943]],
    [ "Enedis",[-1.6556573499999996, 48.0933193]],
    [ "Enedis",[-1.6691217844695532, 48.098670045191184]],
    [ "Enedis",[2.282943096381218, 48.75853342017566]],
    [ "Enedis",[-1.6691248785108972, 48.12666351566669]],
    [ "Maupertuis",[-1.6672282512613035, 48.12251357946355]],
    [ "AN Nouvelle Gare",[2.2813577201503255, 48.756747943920665]],
    [ "Enedis",[-1.6859560090906647, 48.12188859018769]],
    [ "Enedis",[-1.6832597813628158, 48.12066931709363]],
    [ "Enedis",[2.7324534991214384, 45.65036575609925]],
    [ "Enedis",[2.729489351240194, 45.650170518534175]],
    [ "Enedis",[-1.698185377137017, 48.11528885739944]],
    [ "Enedis",[-1.7138779316765183, 48.11093805175622]],
    [ "B Lancelot",[-1.656652450946779, 48.11894407156962]],
    [ "Enedis",[1.1944015708173357, 43.6671822434408]],
    [ "Danton",[-1.6515925168742143, 48.118530095718626]],
    [ "La Fontaine",[-1.6537691623313506, 48.11925007225127]],
    [ "Enedis",[-1.656708426125081, 48.11449690893379]],
    [ "Enedis",[-1.6483382309846284, 48.11447317210901]],
    [ "Enedis",[-1.6624481500000001, 48.114953799999995]],
    [ "Enedis",[-1.6622929438652165, 48.11297068272963]],
    [ "Canton de Bordeau",[-0.2904383733018993, 45.448241838577765]],
    [ "Niffer",[7.364956153740439, 47.771086605030014]],
    [ "ZUP14 Berry",[5.722666415031501, 45.155716401125396]],
    [ "Moreau de Jones",[-1.6552609876813063, 48.108618946946535]],
    [ "Enedis",[-1.6569499913154384, 48.1084837705278]],
    [ "Ph Nordmann",[-1.6586510644647119, 48.10684435068089]],
    [ "Enedis",[-1.6551415146319441, 48.107696242255]],
    [ "Corderie",[-1.651004450693066, 48.1074758968936]],
    [ "Pierre Amys",[-1.6566652356142655, 48.1092442660434]],
    [ "Enedis",[3.0667116781704284, 45.7674187540408]],
    [ "Enedis",[3.0623286585947915, 45.767846660585775]],
    [ "Enedis",[3.0613848811824855, 45.76885290274275]],
    [ "FTPF",[5.72491905, 45.14903279999999]],
    [ "Enedis",[3.0700187411649935, 45.767576909182125]],
    [ "Herault",[5.70870906651995, 45.15144688477339]],
    [ "Enedis",[5.717561741569718, 45.150872874189425]],
    [ "Enedis",[5.711769618784213, 45.150659509723425]],
    [ "Enedis",[5.710441923224165, 45.15194938512065]],
    [ "CES Semaphore",[5.720263849999999, 45.150155350000006]],
    [ "COMMANDERIE 4",[5.735103405340657, 45.14674578391041]],
    [ "Commanderie 3",[5.736695513549186, 45.144021858503145]],
    [ "Français Libres",[-1.6669065162201824, 48.10504261542516]],
    [ "Saint-Hélier",[-1.6654307846209864, 48.10481944394682]],
    [ "La Chapelle-Rambaud",[6.237406917413651, 46.070610924540304]],
    [ "Dom Morice",[-1.6628549215885313, 48.098076714138955]],
    [ "Quineleu",[-1.668297339272571, 48.10208955023034]],
    [ "81046256",[-1.6601172613849486, 48.09652674352063]],
    [ "Enedis",[-1.6644311704845411, 48.09968434952112]],
    [ "Enedis",[5.708588438436485, 45.14149789067264]],
    [ "Enedis",[5.711228886528663, 45.14421415373673]],
    [ "ZAC Sud",[5.711477238959994, 45.13766848011751]],
    [ "Enedis",[5.708033895003524, 45.1433225691211]],
    [ "Plein Sud Langevin",[5.72457284900951, 45.1448162661208]],
    [ "Enedis",[5.721244592261638, 45.14858040307214]],
    [ "Porte Sud",[5.716133919629354, 45.14514944377204]],
    [ "Enedis",[5.711033834089103, 45.14685535982392]],
    [ "Héroult 11",[5.711742849726357, 45.14969348556958]],
    [ "Caterpillar",[5.709595470157045, 45.15044067082752]],
    [ "Enedis",[5.713226229676857, 45.144676710223706]],
    [ "Enedis",[3.864710904798205, 43.533188445050506]],
    [ "Enedis",[-1.7604681500000006, 48.11967564999999]],
    [ "Enedis",[-1.752014374903012, 48.119072246632776]],
    [ "Enedis",[-1.7576444499999992, 48.1202765]],
    [ "Enedis",[-1.75272903609509, 48.117380059672605]],
    [ "Enedis",[-1.7423786766537719, 48.10547804374453]],
    [ "Enedis",[-1.754853168539937, 48.11752738131854]],
    [ "Enedis",[-1.7520019633114514, 48.11499996666667]],
    [ "Enedis",[-1.752580411886017, 48.12147933992763]],
    [ "Enedis",[-1.756036468074328, 48.121857776435924]],
    [ "Enedis",[-1.7433332121555527, 48.103472238517135]],
    [ "Enedis",[-1.7428077125450558, 48.10901804017958]],
    [ "Enedis",[-1.7488055659681219, 48.11418899181737]],
    [ "Enedis",[-1.7399049147173173, 48.10379236415437]],
    [ "Enedis",[-1.7545646440892038, 48.12076301292811]],
    [ "Enedis",[-1.7587057908414099, 48.11502092338904]],
    [ "Enedis",[-1.737063597244789, 48.10621830252035]],
    [ "Enedis",[-1.766406052355143, 48.119714622497646]],
    [ "Enedis",[-1.7602194616530706, 48.11717056063451]],
    [ "Enedis",[-1.7595777447935237, 48.12161767672232]],
    [ "Enedis",[-1.739220397353069, 48.107553401408104]],
    [ "Enedis",[-1.746930105504908, 48.104885778875534]],
    [ "Enedis",[-1.7555055729413136, 48.11462922038775]],
    [ "Enedis",[-1.7518952510444266, 48.11194572082183]],
    [ "Enedis",[-1.75835035006536, 48.11802354794117]],
    [ "Enedis",[-1.736321273492566, 48.10455828808616]],
    [ "Enedis",[5.22937755, 46.44837645]],
    [ "Enedis",[3.0756280844163926, 45.768918897074954]],
    [ "Cabine Nouvelle",[2.3374588303324533, 48.816261964037004]],
    [ "Enedis",[2.338907646094735, 48.81314370318453]],
    [ "Enedis",[2.3508109827453336, 48.81154264764782]],
    [ "Enedis",[5.701223117638978, 45.14726927993989]],
    [ "Enedis",[5.701460878985453, 45.148141177678646]],
    [ "Enedis",[5.701664849435408, 45.15272124980216]],
    [ "Le Rondeau",[5.708347508710314, 45.15720379109373]],
    [ "René Thomas",[5.7065601894468525, 45.154176813278234]],
    [ "Citée",[5.705139296952064, 45.1572728377599]],
    [ "Gresivaudans",[5.706473248819279, 45.15214746166084]],
    [ "Enedis",[5.704592530749136, 45.148828328925255]],
    [ "Enedis",[5.695000789549754, 45.14946941881147]],
    [ "Enedis",[6.9205166, 47.55231515]],
    [ "Enedis",[6.913126587945389, 47.54944733566434]],
    [ "Enedis",[6.929314021800381, 47.55554477416884]],
    [ "Enedis",[6.925679099999999, 47.55578479999999]],
    [ "Enedis",[6.918870300000001, 47.54940814999999]],
    [ "Enedis",[2.2641163602360495, 48.89363405714038]],
    [ "Enedis",[-1.6909788198366997, 48.09847223513131]],
    [ "Poste électrique de Saint-Jean de Sixt",[6.418656502139701, 45.91455588469202]],
    [ "Les Lombardes",[6.413411675606948, 45.914995510108504]],
    [ "REThones",[6.409925357388076, 45.932271183530595]],
    [ "Enedis",[-1.667435061022467, 48.11049243789042]],
    [ "Enedis",[-1.6740788649962572, 48.11595948296407]],
    [ "Enedis",[2.7240710911233394, 50.739253130729246]],
    [ "Enedis",[2.746165567789411, 50.73923496930203]],
    [ "Enedis",[2.7200242948795923, 50.732957301475444]],
    [ "Enedis",[2.73749995, 50.72513725]],
    [ "Enedis",[2.729775834057084, 50.73297337498226]],
    [ "Enedis",[2.730482160161514, 50.730140029590274]],
    [ "Enedis",[2.7361208609542897, 50.7405720649614]],
    [ "Enedis",[2.754585300000001, 50.72710645]],
    [ "Enedis",[2.7378330581173738, 50.726081011136394]],
    [ "Enedis",[2.7397997733222925, 50.74398700518948]],
    [ "Enedis",[2.7246684682221054, 50.73182885291326]],
    [ "Enedis",[2.746021413522496, 50.73124158545584]],
    [ "Enedis",[2.7412656309753816, 50.74131045888265]],
    [ "Enedis",[2.72783244901038, 50.742675726504665]],
    [ "Enedis",[2.728258560304269, 50.737261901476664]],
    [ "Enedis",[2.741974558029393, 50.73983033263412]],
    [ "Enedis",[2.736483220715849, 50.72965905842181]],
    [ "Enedis",[2.732830315240303, 50.73970029256987]],
    [ "Saint-Georges",[-1.6749395494298196, 48.11191130281608]],
    [ "Enedis",[2.721650483438489, 50.734772309274476]],
    [ "Enedis",[2.728205488958413, 50.73033099576328]],
    [ "Enedis",[2.7503692610798423, 50.72776183141972]],
    [ "Enedis",[2.6782587380081764, 50.71303061014525]],
    [ "Enedis",[2.7338982960584173, 50.7261481905489]],
    [ "Enedis",[-1.7076604743956678, 48.10549049382684]],
    [ "Enedis",[5.477651250000001, 47.108728799999994]],
    [ "Enedis",[5.470975000006839, 47.10511890020204]],
    [ "Enedis",[5.486463389671868, 47.105663280464405]],
    [ "Enedis",[5.50793225, 47.110753599999995]],
    [ "Enedis",[5.476485649999999, 47.10062215000001]],
    [ "Les Clos",[4.157470554734937, 43.75034829482625]],
    [ "Enedis",[5.505642849999999, 47.085016849999995]],
    [ "Enedis",[5.492828549558931, 47.082830886482675]],
    [ "Enedis",[5.5014933500000005, 47.08342605000001]],
    [ "Enedis",[5.501310382723374, 47.086393047534955]],
    [ "Enedis",[5.51746198338763, 47.04136859548118]],
    [ "Enedis",[5.505918673752099, 47.077619260442816]],
    [ "Enedis",[5.494533412311012, 47.086433661494524]],
    [ "Enedis",[5.498235561274206, 47.08537882259356]],
    [ "Poste électrique de Serein",[3.740952311377788, 47.886508596609225]],
    [ "Metraux",[5.781533159830993, 45.12098587071914]],
    [ "Enedis",[2.799404321858951, 45.6794470922109]],
    [ "Enedis",[2.4410181434517053, 48.90165752696972]],
    [ "Enedis",[4.9312296203408765, 43.94692851548926]],
    [ "Enedis",[4.93362245, 43.950770250000005]],
    [ "Enedis",[4.930289200000001, 43.9496669]],
    [ "Enedis",[4.9386359052376525, 43.94904973221994]],
    [ "Enedis",[4.837138022777999, 45.6066698333599]],
    [ "Enedis",[-1.3256853612264987, 46.332531772865934]],
    [ "Enedis",[2.4881283789304605, 50.80091744471067]],
    [ "Enedis",[2.539193703410341, 50.72069210387429]],
    [ "Enedis",[2.5249358861505717, 50.729783103768966]],
    [ "Enedis",[2.5496889634472386, 50.71173155696343]],
    [ "Enedis",[2.5331913561638157, 50.711422982422306]],
    [ "Enedis",[2.525104152431174, 50.721789749685854]],
    [ "Enedis",[2.5463538009398627, 50.725650802761876]],
    [ "Enedis",[2.541534426868259, 50.713548092162085]],
    [ "Enedis",[2.544412764409495, 50.71600705591073]],
    [ "Enedis",[2.530700477192798, 50.71723163623258]],
    [ "Enedis",[2.5429938304494315, 50.73159934860185]],
    [ "Enedis",[2.5280239182525395, 50.71137428373404]],
    [ "Enedis",[2.540264270764615, 50.718810912120226]],
    [ "Enedis",[2.5287657284692573, 50.731224994482304]],
    [ "Enedis",[2.532861931281332, 50.73194683071843]],
    [ "Enedis",[2.5281244688341067, 50.72563927563249]],
    [ "Enedis",[2.53597917988838, 50.736816193818136]],
    [ "Enedis",[2.5538463817251684, 50.73709347653928]],
    [ "Enedis",[2.5289378889147978, 50.73373601909893]],
    [ "Enedis",[2.531029046014444, 50.710555428532714]],
    [ "Enedis",[2.5430388832097215, 50.739843299083375]],
    [ "Enedis",[2.5492777500000003, 50.729694250000016]],
    [ "Enedis",[2.5398436170352046, 50.722578213220146]],
    [ "Enedis",[2.521591693298402, 50.715596918613734]],
    [ "Enedis",[2.5253243951436892, 50.73203582306536]],
    [ "Enedis",[2.5422416205374083, 50.73428747715272]],
    [ "Enedis",[2.558757704829915, 50.72847172262783]],
    [ "Enedis",[2.5340161205259966, 50.724717916514614]],
    [ "Enedis",[2.5526039239580216, 50.736368351019486]],
    [ "Enedis",[2.5389619158398813, 50.71682703540808]],
    [ "Enedis",[2.526390616848516, 50.712534822750975]],
    [ "Enedis",[2.5533721831438982, 50.729450955031446]],
    [ "Enedis",[2.5405991736281734, 50.731212615233424]],
    [ "Enedis",[2.5425726054417397, 50.72371330231649]],
    [ "Enedis",[2.544291532596929, 50.70540016653528]],
    [ "Enedis",[2.5454731950959038, 50.711137066406835]],
    [ "Enedis",[2.5524736000000003, 50.732368400000006]],
    [ "Enedis",[2.5318029679901883, 50.715355070820024]],
    [ "Enedis",[2.538226954733844, 50.7254970190714]],
    [ "Enedis",[-1.3229345499999992, 46.33371605]],
    [ "Enedis",[2.53688095, 50.7302067]],
    [ "Enedis",[-1.3277899609872932, 46.33331917466122]],
    [ "Enedis",[2.526251340801555, 50.73451165321685]],
    [ "Enedis",[2.538180998174593, 50.732494566836365]],
    [ "Enedis",[2.5507182426913224, 50.73886625687078]],
    [ "Enedis",[2.5204604474352204, 50.72661225589967]],
    [ "Enedis",[2.521181356977041, 50.72267422457445]],
    [ "Enedis",[2.534926876323568, 50.722805003528656]],
    [ "Enedis",[2.5253009589967346, 50.71808858099602]],
    [ "Enedis",[2.5444030842028345, 50.71790297811983]],
    [ "Enedis",[2.5292880520326197, 50.72257434493513]],
    [ "Enedis",[2.5457665734219384, 50.714016935234014]],
    [ "Enedis",[2.517927120280793, 50.726447304254464]],
    [ "Enedis",[-1.2537397999999995, 46.34955745]],
    [ "Enedis",[2.538608018898712, 50.72284239708245]],
    [ "Enedis",[2.5390711912772224, 50.72635633175183]],
    [ "Enedis",[2.5258952840435045, 50.71991963313604]],
    [ "Enedis",[2.5515620678812154, 50.7182990745187]],
    [ "Enedis",[2.5300154645337605, 50.72036927728153]],
    [ "Enedis",[2.546829056699737, 50.73891816743072]],
    [ "Enedis",[2.5553664954320254, 50.73604955752871]],
    [ "Enedis",[-1.323641735283032, 46.331147310021194]],
    [ "Enedis",[-1.318156940644189, 46.32980923544108]],
    [ "Enedis",[-1.32279365, 46.32916015]],
    [ "Enedis",[2.303260635702464, 45.55282328587257]],
    [ "Enedis",[1.332410529521099, 43.48013275010133]],
    [ "Enedis",[1.33312465, 43.47831779999999]],
    [ "Enedis",[1.3391585740257363, 43.47871939523009]],
    [ "Enedis",[1.3350595686538413, 43.48152823709731]],
    [ "Enedis",[1.340550405122037, 43.4809371744042]],
    [ "Enedis",[1.3349817550884653, 43.484293686111705]],
    [ "Enedis",[1.3363091000000002, 43.4783617]],
    [ "Enedis",[1.33400383078661, 43.480000497552865]],
    [ "Enedis",[1.3368825, 43.4716721]],
    [ "Enedis",[5.446992308860845, 46.24501917725523]],
    [ "Enedis",[6.279411164042401, 46.912672960463624]],
    [ "Enedis",[6.268201878018423, 46.91914004985135]],
    [ "Enedis",[6.271778199999999, 46.91228735]],
    [ "Enedis",[6.266743699999999, 46.916487800000006]],
    [ "Poste électrique de Vendôme",[1.0558010381840126, 47.791550250959986]],
    [ "Enedis",[-1.2913771521711994, 46.33061246706604]],
    [ "Enedis",[-1.29221605, 46.32686605000001]],
    [ "Enedis",[5.752794250213969, 49.51893412309802]],
    [ "Saint-Amand-sur-Sèvre",[-0.7960985240069065, 46.868622546876814]],
    [ "Gérédis",[-0.7903265029046688, 46.87095489999252]],
    [ "Gérédis",[-0.798057469304473, 46.87077682585984]],
    [ "81537439",[-0.21052106956256897, 46.960331238543404]],
    [ "Enedis",[5.7713920466971365, 49.515217764007296]],
    [ "Enedis",[1.3342684620432974, 43.47647177919938]],
    [ "Enedis",[2.8361499228746316, 42.690839842724536]],
    [ "Enedis",[2.903730610709271, 42.68783067058172]],
    [ "Enedis",[2.8808347651266257, 42.70582606415127]],
    [ "Enedis",[2.9095489442769127, 42.68134542590879]],
    [ "Enedis",[2.8903429573288126, 42.69609852457298]],
    [ "Enedis",[2.8764089477109347, 42.72032174167613]],
    [ "Enedis",[2.857008472144817, 42.68750125335897]],
    [ "Enedis",[2.8883309411776565, 42.729323821742625]],
    [ "Enedis",[2.887445552225161, 42.66476736096279]],
    [ "Enedis",[2.89707726215645, 42.725476180555326]],
    [ "Enedis",[2.8807571129278498, 42.71890782753017]],
    [ "Enedis",[2.9048191526436327, 42.7017159431829]],
    [ "Enedis",[2.865106882995728, 42.693342360680354]],
    [ "Enedis",[2.8757489429805485, 42.70391396427006]],
    [ "Enedis",[2.8864855539452514, 42.68759158293001]],
    [ "Enedis",[-1.3207097316134147, 46.34241760345418]],
    [ "Enedis",[-1.3182379781355376, 46.341503144904884]],
    [ "Enedis",[2.9064380492428072, 42.6759423256069]],
    [ "Enedis",[1.3405199178091696, 43.46958313061547]],
    [ "Enedis",[1.33639205, 43.468728750000004]],
    [ "Enedis",[2.91310803581634, 42.68385994988326]],
    [ "Enedis",[2.893624896528427, 42.68058116691756]],
    [ "Enedis",[2.8492269637025824, 42.674898160675006]],
    [ "Enedis",[2.8726017993852437, 42.68246635009095]],
    [ "Enedis",[2.848669166631482, 42.67144160689625]],
    [ "Enedis",[2.888486097636085, 42.686154957578395]],
    [ "Enedis",[-1.323329509430159, 46.34348652926769]],
    [ "Enedis",[2.8958837237579047, 42.721427500090336]],
    [ "Enedis",[2.8905295170091705, 42.710743080577004]],
    [ "Enedis",[2.8919261199533124, 42.68878083865998]],
    [ "Enedis",[2.9013765712287976, 42.685674908553544]],
    [ "San Remo",[2.936244876638072, 42.69904013146912]],
    [ "Enedis",[2.8964477729842293, 42.71131510578618]],
    [ "Enedis",[1.3377172745614028, 43.463894841228075]],
    [ "Enedis",[2.9416996716889594, 42.70061457435521]],
    [ "Enedis",[1.3351833284272419, 43.46313304221882]],
    [ "Enedis",[2.8859730828534, 42.67249445034309]],
    [ "Enedis",[2.8549094569319347, 42.68137139942294]],
    [ "Enedis",[2.907242629980276, 42.7028577]],
    [ "Enedis",[2.89657539138813, 42.710124830586416]],
    [ "Enedis",[2.848457798507487, 42.68041448259223]],
    [ "Enedis",[2.88944853426411, 42.719334514052655]],
    [ "Enedis",[2.852555741239762, 42.68452021196008]],
    [ "Enedis",[2.9360027775966966, 42.70173197868247]],
    [ "Enedis",[2.9074781817261375, 42.706037492748486]],
    [ "Enedis",[2.915553894042779, 42.68517092873726]],
    [ "Enedis",[2.885331521315012, 42.72225321287616]],
    [ "Enedis",[2.8705887595073274, 42.68458433307348]],
    [ "Enedis",[2.9010893629741763, 42.69049185103592]],
    [ "Enedis",[2.916608422817839, 42.69552547405089]],
    [ "Enedis",[2.9080325307270063, 42.6905674462517]],
    [ "Enedis",[2.9062110843712223, 42.67880697211436]],
    [ "Enedis",[2.9017332710426356, 42.7024237306002]],
    [ "Enedis",[2.8979578670710793, 42.71021358072802]],
    [ "Enedis",[2.849088352126393, 42.69213005250252]],
    [ "Enedis",[1.3292411285182921, 43.46512401248566]],
    [ "Enedis",[2.9056566656063527, 42.67262395513675]],
    [ "Enedis",[1.329498554471802, 43.47081671872318]],
    [ "Enedis",[1.3261254407044873, 43.46996857900585]],
    [ "Enedis",[1.3244776699891294, 43.47081529536671]],
    [ "Enedis",[2.911400395330664, 42.689078510081714]],
    [ "Enedis",[1.3278046, 43.4701507]],
    [ "Enedis",[1.3286460500000008, 43.46725349999999]],
    [ "Enedis",[2.900130390430841, 42.68928011370371]],
    [ "Enedis",[2.8514860955677, 42.67487801594144]],
    [ "Enedis",[2.9078872952203443, 42.68242364008496]],
    [ "Enedis",[2.87631105, 42.661764700000006]],
    [ "Enedis",[5.770952703822509, 49.51109996413322]],
    [ "Enedis",[2.884235855077302, 42.66014641959921]],
    [ "Enedis",[5.76712419991383, 49.51642222561101]],
    [ "Enedis",[2.905213550000001, 42.6700555]],
    [ "Enedis",[2.8794446504274456, 42.70531570207325]],
    [ "Enedis",[2.910961757024314, 42.67939515059601]],
    [ "Enedis",[2.865289986441616, 42.686422332558564]],
    [ "Enedis",[2.887573965007508, 42.68426119788059]],
    [ "Enedis",[2.9234088688385707, 42.69599573017534]],
    [ "Enedis",[2.87566325, 42.6834422]],
    [ "Enedis",[2.8675573868042656, 42.69063901014406]],
    [ "Enedis",[2.932491551563562, 42.69909735170057]],
    [ "Enedis",[2.889475803763956, 42.67434963891124]],
    [ "Enedis",[2.9183865624001837, 42.697201902715044]],
    [ "Enedis",[2.9027384249070862, 42.69025450548197]],
    [ "Enedis",[2.8927063357535516, 42.73516207795388]],
    [ "Enedis",[2.8786590393954437, 42.722482381863045]],
    [ "Enedis",[2.9071274644503005, 42.68365666918653]],
    [ "Enedis",[2.9165891459060864, 42.68873218975924]],
    [ "Enedis",[1.3208821000000002, 43.469756350000004]],
    [ "Enedis",[2.887195412068039, 42.69244174064027]],
    [ "Enedis",[2.9035425563815025, 42.704039966284846]],
    [ "Enedis",[1.3237592, 43.47180970000001]],
    [ "Enedis",[1.3205091548680283, 43.47250498294867]],
    [ "Enedis",[1.3215361297701562, 43.462822643365755]],
    [ "Enedis",[1.3161128, 43.466061350000004]],
    [ "Enedis",[1.3204366200290616, 43.46474964502269]],
    [ "Enedis",[1.324132871457747, 43.467528894301665]],
    [ "Enedis",[2.9117855987725907, 42.68176053299061]],
    [ "Enedis",[1.3224838500000002, 43.47070105]],
    [ "Enedis",[1.3184866000000006, 43.46939345]],
    [ "Enedis",[2.9381918431981933, 42.69305849587828]],
    [ "Enedis",[2.8969067744549255, 42.68805882661398]],
    [ "Enedis",[2.894187948260729, 42.71125729917303]],
    [ "Enedis",[2.9398849500000006, 42.69546245]],
    [ "Enedis",[2.9082149013342162, 42.71367568155123]],
    [ "Enedis",[2.877930786311007, 42.68833373808674]],
    [ "Enedis",[2.902478008923208, 42.68373350322627]],
    [ "Enedis",[2.8873428499999982, 42.726032399999994]],
    [ "Enedis",[2.8859368549895845, 42.71016213118707]],
    [ "Enedis",[2.9149875865941777, 42.72286669393798]],
    [ "Enedis",[2.8678703259743665, 42.69464864252009]],
    [ "Enedis",[2.8813608133369013, 42.68849293001357]],
    [ "Enedis",[2.870431967548284, 42.68582454221905]],
    [ "Enedis",[2.8719925954172183, 42.69535524916602]],
    [ "Enedis",[2.8495714222340953, 42.68230406367417]],
    [ "Enedis",[2.846742617109259, 42.67543723036343]],
    [ "Enedis",[2.8729453500000006, 42.688011100000004]],
    [ "Enedis",[2.8823847796191244, 42.70396750292964]],
    [ "Enedis",[2.881507438310808, 42.66612884140007]],
    [ "Enedis",[2.8724983793171357, 42.69086445255932]],
    [ "Enedis",[2.9310672553937342, 42.70695858330393]],
    [ "Enedis",[2.8788287424524643, 42.68786172929764]],
    [ "Enedis",[2.845037415670669, 42.68182644549234]],
    [ "Enedis",[2.875642399112975, 42.68653920646401]],
    [ "Enedis",[-1.1984829668686734, 46.39121949831401]],
    [ "Enedis",[2.893947365036957, 42.678519408516955]],
    [ "Enedis",[2.873464858392968, 42.68486841630401]],
    [ "Enedis",[2.8821487150194063, 42.6906246489516]],
    [ "Enedis",[2.927347404462567, 42.69669912332082]],
    [ "Enedis",[2.9417075807709736, 42.69302727848763]],
    [ "Enedis",[1.3253684483690327, 43.462221575206776]],
    [ "Enedis",[1.3293991378531076, 43.46376132090396]],
    [ "Enedis",[1.3201790454660127, 43.45624902374686]],
    [ "Enedis",[2.8917154470096764, 42.66953386845658]],
    [ "Enedis",[2.915001318834889, 42.6874263984294]],
    [ "Le Nobel",[-1.6320365771719847, 48.12124723666681]],
    [ "Enedis",[2.8964457947218705, 42.72002383280472]],
    [ "Enedis",[1.3237965277613104, 43.46340121113026]],
    [ "Enedis",[2.891784349924578, 42.69424371224146]],
    [ "Becquerel",[-1.632023936818777, 48.121404751040345]],
    [ "Moulin de Joué",[-1.6465351441547376, 48.112376376519016]],
    [ "Couplan",[-1.6429681668623761, 48.11379541781325]],
    [ "Enedis",[2.865376739709639, 42.68638562565543]],
    [ "Enedis",[2.89195318517213, 42.725497167614755]],
    [ "Enedis",[2.940143759145427, 42.694001068258174]],
    [ "Enedis",[1.3266019394802522, 43.46489418748514]],
    [ "Enedis",[1.3244546238981505, 43.45691311076838]],
    [ "Enedis",[1.326931126374809, 43.462366896711366]],
    [ "Enedis",[1.3279506, 43.46463165]],
    [ "Enedis",[2.8812746846340005, 42.705007390357586]],
    [ "Enedis",[2.8779729839385153, 42.68469137545164]],
    [ "Enedis",[2.847015050540075, 42.68466118040286]],
    [ "Enedis",[2.887220978680632, 42.661557930877144]],
    [ "Tanneur",[-1.6732956455883297, 48.12002288001289]],
    [ "Enedis",[2.8517140957133704, 42.692917198639414]],
    [ "Enedis",[2.8811987828837897, 42.715552798474874]],
    [ "Enedis",[2.8694755000000005, 42.69416725000001]],
    [ "Enedis",[2.8784622776779774, 42.66281290427285]],
    [ "Enedis",[2.8853026685785497, 42.71172403285109]],
    [ "Enedis",[2.880037482065221, 42.681582971801184]],
    [ "Enedis",[2.895490427048879, 42.73273176980277]],
    [ "Enedis",[2.9000240784590052, 42.73076173001452]],
    [ "Enedis",[2.900114022814069, 42.70483453164351]],
    [ "Enedis",[1.3159459499999997, 43.46407655000001]],
    [ "Enedis",[2.8913483945051746, 42.682274183145914]],
    [ "Enedis",[2.890042705902474, 42.67886710741504]],
    [ "Enedis",[2.8761544000000003, 42.69524965]],
    [ "Enedis",[2.9065739654411265, 42.687225703707654]],
    [ "Enedis",[2.904315856794217, 42.6914942487163]],
    [ "Enedis",[2.9321135186105964, 42.695898617967025]],
    [ "Enedis",[2.9191336556365717, 42.71588764472801]],
    [ "Enedis",[2.8764311166279857, 42.71700485012564]],
    [ "Enedis",[2.9064991107089106, 42.68263127788239]],
    [ "Enedis",[2.9008955729655796, 42.69699456303636]],
    [ "Enedis",[2.9070052183682953, 42.674623365665475]],
    [ "Enedis",[2.889456119060444, 42.70449340351645]],
    [ "Enedis",[2.910826615233099, 42.69578239906806]],
    [ "Enedis",[2.894588711766182, 42.70682833722613]],
    [ "Enedis",[2.968974277426496, 42.6996029659964]],
    [ "Enedis",[2.8909369500515836, 42.70944213106808]],
    [ "Enedis",[2.8970409164362154, 42.734462833625265]],
    [ "Enedis",[2.9013746759745844, 42.718600830790564]],
    [ "Enedis",[2.8902997478652024, 42.71456243243713]],
    [ "Enedis",[2.894205820299391, 42.69864686612637]],
    [ "Enedis",[2.8706419840830164, 42.69324168188516]],
    [ "Enedis",[2.8893673771759407, 42.67815868531086]],
    [ "Enedis",[2.889844163218562, 42.66616831760278]],
    [ "Le Sec",[2.4244559018926575, 48.87822920627327]],
    [ "Enedis",[2.424808522890433, 48.86803374828807]],
    [ "Enedis",[1.3132303866487243, 43.44547638050345]],
    [ "Enedis",[1.3211726831389319, 43.445184985329206]],
    [ "Enedis",[1.3193853000000006, 43.45131179999999]],
    [ "Enedis",[1.3179592198137728, 43.44729029292189]],
    [ "Enedis",[1.3221582, 43.44751140000001]],
    [ "Enedis",[1.3123670070915254, 43.446323818609415]],
    [ "Enedis",[1.3150312330687304, 43.44173777914603]],
    [ "Enedis",[1.3219456, 43.45514609999999]],
    [ "Enedis",[1.3232883051228705, 43.45218502014668]],
    [ "Enedis",[1.3150734686308783, 43.448415299303846]],
    [ "Enedis",[1.31931835, 43.45351854999999]],
    [ "Enedis",[1.3152535500000002, 43.4460121]],
    [ "Enedis",[1.3162699999999998, 43.45196090000001]],
    [ "Enedis",[1.3182256057797752, 43.44494821922856]],
    [ "Enedis",[1.3208176736708166, 43.44820589372615]],
    [ "Enedis",[1.323494071062211, 43.4456207911487]],
    [ "Enedis",[1.3209667840507016, 43.446415802236125]],
    [ "Enedis",[1.313239583795511, 43.44771498139364]],
    [ "Poste électrique de Vogelgrun",[7.572465911910159, 48.0188432560212]],
    [ "Poste électrique de Vigy",[6.276941809559346, 49.19774007233592]],
    [ "Enedis",[2.4955587225651326, 50.49779996714451]],
    [ "Enedis",[2.500685438717445, 50.504094364491884]],
    [ "Enedis",[2.504071031961793, 50.51203231748715]],
    [ "Enedis",[2.504109379517655, 50.50413559220902]],
    [ "Enedis",[2.492684644017539, 50.50407784335669]],
    [ "Enedis",[2.4892590085920068, 50.502553607743394]],
    [ "Enedis",[2.50263058597285, 50.50206399049774]],
    [ "Enedis",[2.4960699357757647, 50.500277269174255]],
    [ "Enedis",[2.4996997596501402, 50.5012526627159]],
    [ "Enedis",[2.506896357237938, 50.5105641491557]],
    [ "Enedis",[2.512861872738166, 50.50862464721968]],
    [ "Enedis",[2.491308615369348, 50.49733859332553]],
    [ "Enedis",[2.511672710389614, 50.50480441013053]],
    [ "Enedis",[2.4873156164651014, 50.49888223130593]],
    [ "Enedis",[2.50907994804811, 50.51078080309063]],
    [ "Enedis",[2.9099679158550367, 42.665476223315906]],
    [ "Enedis",[2.8926464954204554, 42.71255698634115]],
    [ "Enedis",[2.877055152955021, 42.704430538158185]],
    [ "Enedis",[2.89444860702222, 42.71472187764669]],
    [ "Enedis",[2.8933716771400886, 42.696379546964806]],
    [ "Château d'Eau",[2.910813188671721, 42.6985800355851]],
    [ "Enedis",[2.8949660747687025, 42.69102342813021]],
    [ "Enedis",[2.943464348720842, 42.70617795665914]],
    [ "Poste électrique de Bezaumont",[6.097575084803659, 48.85729622823036]],
    [ "Enedis",[2.8840495179670698, 42.69524261998335]],
    [ "Enedis",[2.9103654345148624, 42.684909425619054]],
    [ "Enedis",[2.9088962423116853, 42.67774474411183]],
    [ "Enedis",[2.892458804623749, 42.67298028779026]],
    [ "Enedis",[2.9147852283282547, 42.69674690293906]],
    [ "Enedis",[2.920096946160905, 42.69546348803523]],
    [ "Enedis",[2.902465267149168, 42.68915803513072]],
    [ "Enedis",[2.9134440153615246, 42.682330905742624]],
    [ "Enedis",[2.8676862301993333, 42.69631361416488]],
    [ "Enedis",[2.9059891045269337, 42.6842113502017]],
    [ "Enedis",[2.8435953648096777, 42.68240326329999]],
    [ "Enedis",[2.934812612816441, 42.694380582661324]],
    [ "Enedis",[2.8930350969554834, 42.72003167542207]],
    [ "Enedis",[2.879530938505272, 42.66117454632102]],
    [ "Enedis",[2.8824463842766526, 42.71396408197254]],
    [ "Enedis",[2.8821822277182294, 42.69988016202244]],
    [ "Enedis",[2.8820776622975184, 42.66422804805656]],
    [ "Enedis",[2.905809089695263, 42.687805377800764]],
    [ "Enedis",[2.91177189322572, 42.67623437974107]],
    [ "Enedis",[2.8432289786521885, 42.684364662470145]],
    [ "Enedis",[-1.6820415038167669, 48.11804631069344]],
    [ "Enedis",[2.9231764801350666, 42.69898335171897]],
    [ "Enedis",[2.902951335037154, 42.69514795131606]],
    [ "Enedis",[2.856547804384264, 42.69092985391922]],
    [ "Enedis",[2.906742821341494, 42.70457742390638]],
    [ "Enedis",[2.8980229760228755, 42.68561996808831]],
    [ "Enedis",[2.8683977908511222, 42.69112905255636]],
    [ "Enedis",[2.8518403834547956, 42.68717111806185]],
    [ "Enedis",[-1.2480252327785484, 46.35181315173371]],
    [ "Enedis",[2.902114918236724, 42.68739011955364]],
    [ "Enedis",[2.9620595584370655, 42.703337330160096]],
    [ "Enedis",[2.8863100160637867, 42.67020253642563]],
    [ "Enedis",[2.9265140174188717, 42.69822406184698]],
    [ "Enedis",[2.9180288920639983, 42.69368158143385]],
    [ "Enedis",[2.8872715279170436, 42.70963518433127]],
    [ "Enedis",[2.891564327319761, 42.667802245060905]],
    [ "Enedis",[2.8926680335547332, 42.683912374896366]],
    [ "Enedis",[2.9250368462201743, 42.67254855731904]],
    [ "Enedis",[5.927462289226562, 45.686273490390626]],
    [ "Enedis",[2.870549460681228, 42.69825360157747]],
    [ "Enedis",[2.9006808434581126, 42.73339012266576]],
    [ "Enedis",[2.9378659871893404, 42.694730511369116]],
    [ "Enedis",[2.8919929321453557, 42.7086367452571]],
    [ "Enedis",[2.89364718016882, 42.6863167443868]],
    [ "Enedis",[2.91564906489595, 42.70295855717583]],
    [ "Enedis",[2.900482596112993, 42.70750378769193]],
    [ "Enedis",[2.87897223398363, 42.66578555709424]],
    [ "Enedis",[2.8829180263939858, 42.71240096484967]],
    [ "Enedis",[2.8897187111666245, 42.72260236611137]],
    [ "Enedis",[2.8707651574118813, 42.69698699898814]],
    [ "Enedis",[2.8883920136834274, 42.667700767078195]],
    [ "Enedis",[2.892074166064445, 42.71891744533198]],
    [ "Enedis",[2.847219044453625, 42.68533138113328]],
    [ "Enedis",[2.925510673216885, 42.6929438]],
    [ "81681461",[2.864412530843358, 42.69517222394701]],
    [ "Enedis",[2.945523162857142, 42.707440130985596]],
    [ "Enedis",[2.88017135, 42.68423514999999]],
    [ "Enedis",[2.8857545508276177, 42.71389339113295]],
    [ "Enedis",[2.899122062152299, 42.69275292642421]],
    [ "Enedis",[2.9044352472882298, 42.680225794731484]],
    [ "Enedis",[2.8903305832632578, 42.67049023195421]],
    [ "Enedis",[2.863109196136462, 42.684773409015996]],
    [ "Enedis",[2.9210969524770407, 42.69791459983918]],
    [ "Enedis",[2.916420336398558, 42.6670687186455]],
    [ "Enedis",[2.8825900314078394, 42.69251039897214]],
    [ "Enedis",[2.9076428275486923, 42.68441214013785]],
    [ "Enedis",[2.8714183742110855, 42.69330784882891]],
    [ "Enedis",[2.8906091609076254, 42.67215332434923]],
    [ "Enedis",[2.8783662188453154, 42.70247909564271]],
    [ "Enedis",[2.8960157686542605, 42.718418074787834]],
    [ "Enedis",[2.8892352236951035, 42.663704556864005]],
    [ "Enedis",[2.916347600239977, 42.69756293083263]],
    [ "Enedis",[2.8863304330599715, 42.67361511790005]],
    [ "Enedis",[2.9111371999999993, 42.691216]],
    [ "Enedis",[2.8821380548435784, 42.6621442719431]],
    [ "Enedis",[2.920567525968427, 42.69403189521281]],
    [ "Enedis",[2.942773907258123, 42.697476790333255]],
    [ "Enedis",[2.9051771230030403, 42.6856194916345]],
    [ "Enedis",[2.8755731961397877, 42.730624484613465]],
    [ "Enedis",[2.873839422885867, 42.684240112516875]],
    [ "Enedis",[2.8450974403979434, 42.6766635169283]],
    [ "Enedis",[2.890097839085396, 42.690963448370475]],
    [ "Enedis",[2.895757237329601, 42.708798255744156]],
    [ "Enedis",[2.930582686056538, 42.6977140585045]],
    [ "Enedis",[2.9177779671807995, 42.69457806483728]],
    [ "Enedis",[2.8925140455305587, 42.71004688560986]],
    [ "Enedis",[2.9341799352674407, 42.697001087661725]],
    [ "Enedis",[2.941472526425422, 42.695566255339294]],
    [ "Enedis",[2.917126643490504, 42.69866565004949]],
    [ "Enedis",[2.9061247000000003, 42.68498915]],
    [ "Enedis",[5.921383679196577, 45.69682196768164]],
    [ "Enedis",[5.924641064773515, 45.69641680759957]],
    [ "Enedis",[2.904076600524211, 42.68937456729699]],
    [ "Enedis",[2.8762452821368747, 42.68840474534884]],
    [ "Enedis",[4.995964583714267, 45.73620215886305]],
    [ "Enedis",[5.005230850000002, 45.7339915]],
    [ "Enedis",[3.0261647294404574, 50.61465838390931]],
    [ "Enedis",[5.014670599999999, 45.7397063]],
    [ "Enedis",[4.994169525377996, 45.72864874535939]],
    [ "Enedis",[5.004145171168265, 45.735192104899525]],
    [ "Enedis",[3.001120736629144, 50.61487858976703]],
    [ "Enedis",[5.00907665, 45.72164205000001]],
    [ "Enedis",[5.021463999999999, 45.741623149999995]],
    [ "Enedis",[5.000866732521882, 45.73144435850881]],
    [ "Enedis",[5.011612606137946, 45.735749660223085]],
    [ "Enedis",[5.0221351192753705, 45.72622009840609]],
    [ "Enedis",[5.012251695405202, 45.732009790322415]],
    [ "Enedis",[5.013505478456317, 45.734641743862255]],
    [ "Enedis",[4.99788105, 45.728721400000005]],
    [ "Enedis",[4.982490764347662, 45.73347779834009]],
    [ "Enedis",[4.991430597088068, 45.72956743258302]],
    [ "Enedis",[3.0125144392971444, 50.61207293199587]],
    [ "Enedis",[4.99940157835022, 45.734790610846964]],
    [ "Enedis",[3.0169765567691043, 50.61865792947626]],
    [ "Enedis",[5.002783564865711, 45.73278937323991]],
    [ "Enedis",[5.02067833063787, 45.729074798431164]],
    [ "Enedis",[3.013887248693261, 50.61525192419951]],
    [ "Enedis",[5.02788327159398, 45.72736604712053]],
    [ "Enedis",[3.043019742607329, 50.60454560090885]],
    [ "Enedis",[4.991923877616873, 45.73372380785093]],
    [ "Enedis",[4.983199061487873, 45.73171586840261]],
    [ "Enedis",[3.0075030969041556, 50.6213803639497]],
    [ "Enedis",[4.998328695872182, 45.72978911703931]],
    [ "Enedis",[5.002007890119493, 45.72991170277701]],
    [ "Enedis",[5.008366287515658, 45.730537979926424]],
    [ "Enedis",[5.024833000000002, 45.7283601]],
    [ "Enedis",[5.008910898964547, 45.73571214822635]],
    [ "Enedis",[5.0082555499999994, 45.71729255]],
    [ "Enedis",[5.017155196288209, 45.730661529039295]],
    [ "Enedis",[5.019272296427273, 45.73984077584219]],
    [ "Enedis",[4.9964649747748915, 45.73157114809436]],
    [ "Enedis",[3.0166863970509614, 50.622322605564904]],
    [ "Enedis",[3.0097246882395376, 50.61743963333333]],
    [ "Enedis",[3.0013806974585315, 50.61221155973982]],
    [ "Enedis",[5.014441277071038, 45.73639386447826]],
    [ "Enedis",[5.021078899999998, 45.73020779999999]],
    [ "Enedis",[3.008736274940204, 50.614450628958096]],
    [ "Enedis",[5.000227732876444, 45.73306878364833]],
    [ "Enedis",[3.0183695253713796, 50.61246431800492]],
    [ "Enedis",[5.018795292617582, 45.73445547443213]],
    [ "Enedis",[3.0218539574945926, 50.620285165555444]],
    [ "Enedis",[5.00018205, 45.72213875]],
    [ "Enedis",[5.025938068508534, 45.73336078418688]],
    [ "Enedis",[5.00346075, 45.724710249999994]],
    [ "Enedis",[4.9939326, 45.734656349999995]],
    [ "Enedis",[3.0166617951628254, 50.61304674837023]],
    [ "Enedis",[5.015413361844681, 45.73169402264217]],
    [ "Enedis",[5.0177787707064665, 45.74352069721918]],
    [ "Enedis",[5.018314800000001, 45.72761020000001]],
    [ "Enedis",[5.00895265, 45.72075335]],
    [ "Enedis",[5.006934709907696, 45.72476941899631]],
    [ "Enedis",[3.003048563706548, 50.613994409402736]],
    [ "Enedis",[4.9841140763260094, 45.73493974811362]],
    [ "Enedis",[5.017726973013629, 45.73879399320462]],
    [ "Enedis",[3.015893030921624, 50.61180159406193]],
    [ "Enedis",[4.995954649999999, 45.7294975]],
    [ "Enedis",[3.018577584259268, 50.624629835528495]],
    [ "Enedis",[3.0424338252179193, 50.60474094143556]],
    [ "Enedis",[5.001783105033797, 45.73125460164463]],
    [ "Enedis",[4.98301778271306, 45.731475958331785]],
    [ "Enedis",[5.013420459194264, 45.74136142488455]],
    [ "Enedis",[4.99870835, 45.72132049999999]],
    [ "Enedis",[5.003638318507635, 45.72614357423797]],
    [ "Enedis",[5.00524968272533, 45.71787711638253]],
    [ "Enedis",[5.0101559500000015, 45.727748150000004]],
    [ "Enedis",[4.990798616136603, 45.732515205217496]],
    [ "Enedis",[5.01303061973541, 45.737458435572165]],
    [ "Enedis",[5.0040751, 45.72271200000001]],
    [ "Enedis",[4.979799626828872, 45.722936183091356]],
    [ "Enedis",[4.9873788055056725, 45.72962518014107]],
    [ "Enedis",[5.017745492750845, 45.73718192631174]],
    [ "Enedis",[5.012632350000001, 45.72774164999999]],
    [ "G 060",[5.0201131321627175, 45.717822525333204]],
    [ "Enedis",[5.008004320990851, 45.73168929422465]],
    [ "Enedis",[5.012859800000002, 45.733435750000005]],
    [ "Enedis",[5.015375288986283, 45.72782126989693]],
    [ "Enedis",[5.003939743314288, 45.72788610337956]],
    [ "Enedis",[4.993123900000001, 45.730214849999996]],
    [ "Enedis",[5.017335273204546, 45.74092744328051]],
    [ "Enedis",[-1.4945157583740638, 48.127466000114566]],
    [ "Enedis",[5.007136541331984, 45.71856842529346]],
    [ "Cv faidherbe 77",[2.2579747524145235, 48.90587943089442]],
    [ "Enedis",[3.385132873119708, 49.62299387869601]],
    [ "RTE",[5.902347472802502, 45.6961698177518]],
    [ "Chemin du Pêcheur",[5.897202827570584, 45.69504012640419]],
    [ "Poste électrique de Vincey",[6.326423012924321, 48.35379543297757]],
    [ "Enedis",[1.328352529089684, 43.45782998610469]],
    [ "Enedis",[1.3345525827046774, 43.45868715446764]],
    [ "Enedis",[1.3317308999999995, 43.4565158]],
    [ "PC Girardot",[2.440453283446938, 48.860451794492704]],
    [ "Enedis",[2.4461672153915166, 48.852891170681175]],
    [ "Enedis",[1.3338247500000002, 43.45926415]],
    [ "Enedis",[1.3365916999999998, 43.45156885]],
    [ "Enedis",[1.3342768157457292, 43.45449128277412]],
    [ "Enedis",[1.3423169999999998, 43.45907425000001]],
    [ "Enedis",[1.337555568101387, 43.46079609738881]],
    [ "Enedis",[1.3417530333118304, 43.45642285003245]],
    [ "Enedis",[1.3359567794152045, 43.456606720109164]],
    [ "Enedis",[1.3412875500000006, 43.45743354999999]],
    [ "Legal",[2.4259888802268366, 48.85512193444685]],
    [ "Champs Péans",[-1.628296434656474, 48.11445776841675]],
    [ "Châteauneuf",[-1.631252158332513, 48.11609536132189]],
    [ "81777416",[-1.6248285211902764, 48.11287634390053]],
    [ "Morgane",[-1.6323046698476273, 48.11350922186621]],
    [ "Enedis",[1.3285757499999997, 43.44682625]],
    [ "Enedis",[1.3274966967990662, 43.44784842027582]],
    [ "Enedis",[1.32887249676508, 43.45007460799557]],
    [ "Vaux Parés",[-1.626994630118556, 48.1123451057498]],
    [ "Enedis",[1.3301787439469364, 43.454755590723714]],
    [ "Rabin",[-1.627197989857441, 48.117091881246026]],
    [ "Enedis",[1.3290216692509542, 43.45635777382642]],
    [ "Enedis",[-1.6240752950676207, 48.133336381893386]],
    [ "Enedis",[1.3267733891422748, 43.451370450823994]],
    [ "Enedis",[-1.5962901245133567, 48.15360858195732]],
    [ "Enedis",[1.3440100759819589, 43.44192600916258]],
    [ "Enedis",[1.332418995353605, 43.453182386619716]],
    [ "Enedis",[1.3289512366050618, 43.452589240167384]],
    [ "Enedis",[1.3269223999999995, 43.43787904999999]],
    [ "Enedis",[1.3357745698737749, 43.449892958615706]],
    [ "Enedis",[1.3449408000000003, 43.43303519999999]],
    [ "Enedis",[1.3390410999999998, 43.443518350000005]],
    [ "Enedis",[-1.6225801069277013, 48.13174989766797]],
    [ "Enedis",[-1.6288770492106524, 48.11681584016966]],
    [ "Enedis",[-1.6001027498628917, 48.136552160601795]],
    [ "Enedis",[-1.6248445606535724, 48.1359397660702]],
    [ "Clos Breton",[-1.6312768019374624, 48.114208619402326]],
    [ "Taillis",[-1.6231495947113448, 48.11483776280448]],
    [ "Grand Champ",[-1.6232639440687435, 48.11691692868089]],
    [ "Transpac",[-1.6259697734131258, 48.13043084620774]],
    [ "Bois Frin",[-1.631039347331924, 48.11855635735276]],
    [ "Foyer Chataigneraie",[-1.6287957492735914, 48.11932977129327]],
    [ "Enedis",[-1.622389274172221, 48.129877268752374]],
    [ "Enedis",[-1.610837827749676, 48.14379883734311]],
    [ "Enedis",[1.3097478241659881, 43.40916889157883]],
    [ "Enedis",[1.3128321303957717, 43.4159762527719]],
    [ "Enedis",[1.3166598500000002, 43.42140295000001]],
    [ "Enedis",[1.2915824000000007, 43.440579050000004]],
    [ "Enedis",[1.2966715000000009, 43.446761349999996]],
    [ "Enedis",[1.2910705, 43.4421969]],
    [ "Enedis",[1.30649794442259, 43.4646487690705]],
    [ "Enedis",[1.3094820093513235, 43.46657211745556]],
    [ "Enedis",[1.3102948794394198, 43.468763572006175]],
    [ "Enedis",[1.3018703500000002, 43.4653905]],
    [ "Enedis",[1.3105274000000005, 43.46966145]],
    [ "Enedis",[1.3016639903770784, 43.46705887885564]],
    [ "Enedis",[5.77424133459738, 46.22625757571535]],
    [ "Enedis",[-1.68262760021401, 48.116595582363736]],
    [ "Enedis",[-1.1639705750894798, 46.454559040365126]],
    [ "Enedis",[-1.1686002825188297, 46.45000959527063]],
    [ "Enedis",[2.4288803832298678, 48.85177867304902]],
    [ "Enedis",[2.4639757512922498, 48.857788899335134]],
    [ "Fronton",[-1.4398919000000001, 43.36693544999999]],
    [ "Enedis",[2.4572163000000002, 48.8559544]],
    [ "Enedis",[-1.9489397066540357, 48.18173060707577]],
    [ "Enedis",[-1.9308416248068383, 48.17764771690629]],
    [ "Enedis",[-1.946602696842719, 48.18391887081072]],
    [ "Enedis",[-1.949344372180486, 48.17672489280186]],
    [ "Enedis",[-1.937193643128467, 48.17955845617917]],
    [ "Enedis",[-1.9386355500000003, 48.17577635000001]],
    [ "Enedis",[-1.9316233587628864, 48.17995598419244]],
    [ "Enedis",[2.454981195750589, 48.866879747459784]],
    [ "Enedis",[-1.9342388894056988, 48.18078906917651]],
    [ "Enedis",[-1.935989095897107, 48.17744404947912]],
    [ "81861848",[-1.9853423655083382, 48.18204541308714]],
    [ "Enedis",[-1.9488892499999997, 48.17900724999999]],
    [ "Enedis",[-1.9310203598145779, 48.179376471824376]],
    [ "Enedis",[-1.9446339276506321, 48.182199058657]],
    [ "Enedis",[-1.9313930934696597, 48.17967575805498]],
    [ "Enedis",[-0.19291166626336606, 43.26980312104991]],
    [ "Enedis",[-0.18889594441308524, 43.259972467239685]],
    [ "Enedis",[-0.1847862017325363, 43.260679098145495]],
    [ "Enedis",[-0.18400315961934194, 43.26275394125515]],
    [ "Enedis",[-0.18278642268424242, 43.2603222765075]],
    [ "Enedis",[-0.18149177973821506, 43.262697061868444]],
    [ "Enedis",[-0.19212115525084197, 43.26427617189769]],
    [ "Enedis",[-1.5723273618053868, 43.40368824074393]],
    [ "Enedis",[-1.1379403499999998, 46.46146625]],
    [ "Enedis",[3.3439149276113325, 49.62084774161033]],
    [ "Enedis",[-1.1611789349406407, 46.45539941784432]],
    [ "Enedis",[-1.1574124000000001, 46.4562113]],
    [ "Enedis",[-3.454475314032721, 48.80423917432123]],
    [ "Enedis",[-3.443679831848552, 48.81357498426132]],
    [ "Enedis",[-3.435381317819353, 48.81386624410571]],
    [ "Enedis",[-1.1803157884438185, 46.45737922113156]],
    [ "Enedis",[-3.449710375133403, 48.813261809904965]],
    [ "Enedis",[-3.442227477924232, 48.81389928432929]],
    [ "Enedis",[-3.4619477947859676, 48.807270714420255]],
    [ "Enedis",[-3.4860851143046983, 48.83000748312097]],
    [ "Enedis",[-3.471595881475985, 48.823119115036384]],
    [ "Enedis",[-3.464361780008375, 48.816315145827275]],
    [ "Enedis",[-3.4645253671694602, 48.804133374029206]],
    [ "Enedis",[-3.4489857301813553, 48.80822361289081]],
    [ "Enedis",[-3.4715960509775727, 48.80335716308369]],
    [ "Enedis",[-3.44091405024244, 48.816814718302034]],
    [ "Enedis",[-3.4320669045705468, 48.81714556508672]],
    [ "Enedis",[-3.4478183522081762, 48.79916485249446]],
    [ "Enedis",[-3.4621354005978313, 48.8097874280041]],
    [ "Enedis",[-3.4435494702749927, 48.816555115974346]],
    [ "Enedis",[-3.4632317358731672, 48.816716572460685]],
    [ "Enedis",[-3.4741424878487686, 48.81826381335746]],
    [ "Enedis",[-3.445376246017841, 48.813585165783635]],
    [ "Enedis",[-3.4532707354824264, 48.81138454817551]],
    [ "Enedis",[-3.4472933908650725, 48.80292368159992]],
    [ "Enedis",[-3.477381618740419, 48.82223793203919]],
    [ "Enedis",[-3.4647941101320385, 48.818088028066676]],
    [ "Enedis",[-3.450296101922403, 48.808402177209345]],
    [ "Enedis",[-3.4609664499999995, 48.81796919999999]],
    [ "Enedis",[-3.4323054601680165, 48.818263272184936]],
    [ "RTE",[2.894984568450724, 46.67582220935122]],
    [ "Enedis",[-3.445357473969965, 48.8160511222491]],
    [ "Enedis",[-3.455303003847146, 48.8117447596431]],
    [ "Enedis",[-3.44527485578079, 48.80759082963117]],
    [ "Enedis",[-3.4649274261396337, 48.80886211519764]],
    [ "Enedis",[-3.437088312215355, 48.81869531528349]],
    [ "Enedis",[-1.641944889093215, 48.17160704636841]],
    [ "Enedis",[-1.6370403780611034, 48.18070904862941]],
    [ "Enedis",[-1.6335005925125534, 48.1758856944621]],
    [ "Enedis",[-1.649551473654789, 48.18495066128582]],
    [ "Enedis",[-1.6382274334121305, 48.17927281747049]],
    [ "Enedis",[-1.6390984215662447, 48.17746703457927]],
    [ "Enedis",[4.147794674663305, 43.75314765732707]],
    [ "Enedis",[1.1421394418454813, 49.028128021771536]],
    [ "Enedis",[1.1258409193152878, 49.02366556932092]],
    [ "La Rivaudière",[-1.6456452499999994, 48.22034954999999]],
    [ "Enedis",[-1.636080119452348, 48.243933903810465]],
    [ "Enedis",[1.150065759659376, 49.01377054899396]],
    [ "Enedis",[1.135795721679877, 49.031135705449266]],
    [ "Bord du lac",[5.782695508690012, 45.02389741647212]],
    [ "Enedis",[-3.938728934254549, 48.52659467477957]],
    [ "Enedis",[-1.1724132177976487, 45.688387952464126]],
    [ "Enedis",[-1.1660132267771177, 45.68373823352332]],
    [ "Enedis",[-1.147179968818433, 45.71818387112095]],
    [ "Enedis",[-1.1838685321289406, 45.69068069747185]],
    [ "Enedis",[-1.17274685, 45.6905512]],
    [ "Maine Pluchon",[-1.1517538707881612, 45.71652172414514]],
    [ "Enedis",[-1.1457344714202198, 45.72027075671531]],
    [ "Enedis",[-1.1820266277929115, 45.686100577308935]],
    [ "Enedis",[-1.17391485, 45.6934857]],
    [ "Néré",[-1.1451439270894197, 45.725769446717685]],
    [ "Enedis",[-1.172078433337197, 45.68688845011856]],
    [ "Enedis",[-1.1832041279310415, 45.68883579544676]],
    [ "Enedis",[-1.1804769326491007, 45.69142753222779]],
    [ "Enedis",[-1.1776836774904487, 45.6912076334847]],
    [ "Enedis",[-1.1704761447215428, 45.690702050674254]],
    [ "Enedis",[-1.1439691915552535, 45.718014900735206]],
    [ "La Baraque",[-1.1609938177101966, 45.712639405625126]],
    [ "Enedis",[-1.1726064423875668, 45.69373020575367]],
    [ "Enedis",[-1.164641743481282, 45.71443541440059]],
    [ "Tournegand",[-1.176779573100219, 45.695064635943574]],
    [ "Enedis",[-1.1782145901146912, 45.694266236248126]],
    [ "Enedis",[-1.1754188765492959, 45.6925158608047]],
    [ "Enedis",[-1.1806111, 45.692663950000004]],
    [ "Enedis",[-1.1751424265561778, 45.68442944388296]],
    [ "Enedis",[-1.1771879, 45.692013349999996]],
    [ "Enedis",[2.046243959219268, 47.22699783166695]],
    [ "Enedis",[2.049926892964105, 47.22972556872491]],
    [ "Enedis",[2.050278694561996, 47.229223780580526]],
    [ "Enedis",[2.039748681141437, 47.2295027246925]],
    [ "Poste électrique de Gambsheim",[7.911892268936948, 48.684703839761454]],
    [ "Enedis",[-3.581390101757693, 48.7768439547006]],
    [ "Enedis",[-3.5624778166917834, 48.76734368371612]],
    [ "Enedis",[-3.5652192268783063, 48.77070827366349]],
    [ "Enedis",[-3.5755247908144394, 48.76851877063766]],
    [ "Enedis",[-3.5777430905342817, 48.778975336349255]],
    [ "Enedis",[-3.5762382500000003, 48.77072625]],
    [ "Enedis",[-3.5809267728626635, 48.774823658398304]],
    [ "Enedis",[-3.5699259969384363, 48.76807458514697]],
    [ "Enedis",[-3.58314892531293, 48.77135759640451]],
    [ "Enedis",[-3.8330228000000006, 47.9599938]],
    [ "Saint-Philibert",[-3.8333386499999995, 47.8060002]],
    [ "Enedis",[5.454824249999998, 46.47696174999999]],
    [ "82206338",[3.8881707039801383, 43.576829186078506]],
    [ "Enedis",[3.8905336023051675, 43.58213936641505]],
    [ "RTE",[3.166722965461676, 46.45678895503697]],
    [ "Poste Peltre",[6.20559926748553, 49.06212471014294]],
    [ "Enedis",[2.274966892913757, 48.90701181922715]],
    [ "Enedis",[-2.016374024072368, 46.92532070665299]],
    [ "CV Saint-Denis 129",[2.265214537586646, 48.89865759916664]],
    [ "Enedis",[3.1362699167110195, 50.683535202023904]],
    [ "Enedis",[3.1321656807107368, 50.6789728996946]],
    [ "Enedis",[3.147692198984794, 50.692966201070845]],
    [ "Enedis",[3.1137832774298273, 50.671461476713255]],
    [ "Enedis",[3.125639567114615, 50.655342524635934]],
    [ "Enedis",[3.1218332393160884, 50.685024753745395]],
    [ "Enedis",[3.125897361113669, 50.68793744639406]],
    [ "Enedis",[3.122979298657675, 50.668918278336484]],
    [ "Enedis",[3.1222044858695357, 50.68721860679647]],
    [ "Enedis",[3.1333357963385415, 50.66312346417752]],
    [ "Enedis",[3.136117428853191, 50.678768553088794]],
    [ "Enedis",[3.1282842133775746, 50.67547095731832]],
    [ "Enedis",[3.128622031475816, 50.668161860226014]],
    [ "Enedis",[3.1286234365464938, 50.679273775599036]],
    [ "Enedis",[3.145189089659951, 50.6929808496521]],
    [ "Roquette",[3.1219255558814596, 50.67060723891627]],
    [ "Enedis",[3.120880552833804, 50.65606908247631]],
    [ "Enedis",[3.1334695204223166, 50.68874983259288]],
    [ "Enedis",[3.1359926616437908, 50.6774659157578]],
    [ "Enedis",[3.1462028368868227, 50.68816849182765]],
    [ "Enedis",[3.1283242324657583, 50.682223952685]],
    [ "Enedis",[3.137354681701286, 50.69020589683482]],
    [ "Enedis",[3.1191973723739403, 50.65520851526622]],
    [ "Enedis",[3.1152910324001346, 50.687363685690535]],
    [ "Enedis",[3.137965339463467, 50.680796215783836]],
    [ "Enedis",[3.119393156148492, 50.687687075096676]],
    [ "Enedis",[3.127425828535544, 50.65434004649586]],
    [ "Enedis",[3.1293005000000007, 50.67688155000001]],
    [ "Enedis",[3.124348909636868, 50.65273582064276]],
    [ "Enedis",[3.129238299121655, 50.67344279042431]],
    [ "Enedis",[3.134723660135418, 50.68083417660507]],
    [ "Enedis",[3.120017340695528, 50.68162603048718]],
    [ "Enedis",[3.1296003067856146, 50.66575098678331]],
    [ "Enedis",[3.1303237463692986, 50.686338324649896]],
    [ "Enedis",[3.127755707656969, 50.66671672061489]],
    [ "Enedis",[3.1367262704354215, 50.67710175719809]],
    [ "Enedis",[3.1401201538545687, 50.68451718317241]],
    [ "Enedis",[3.1349147117158123, 50.68219489894276]],
    [ "Enedis",[3.124651413327435, 50.687329749765816]],
    [ "Enedis",[3.1289043922432453, 50.661122662401745]],
    [ "Enedis",[3.1454606436917314, 50.691361416965236]],
    [ "Enedis",[3.122794624465038, 50.677051533519155]],
    [ "Enedis",[3.1175526166684846, 50.684570850186546]],
    [ "Enedis",[3.1163473690768053, 50.68210806411087]],
    [ "Enedis",[3.1285066118709577, 50.673411049580835]],
    [ "Enedis",[3.1461465412666327, 50.686135326174835]],
    [ "Enedis",[3.1251595578235127, 50.663117647763364]],
    [ "Enedis",[3.1182616857187484, 50.67195209349816]],
    [ "Enedis",[3.1224971537326884, 50.65333345923035]],
    [ "Enedis",[3.1190611452305057, 50.67185029975941]],
    [ "Enedis",[3.1424076677139823, 50.68299114471582]],
    [ "Enedis",[3.1221267797142795, 50.66527492890062]],
    [ "Enedis",[3.1246910861391335, 50.67792351363364]],
    [ "Enedis",[3.142688538631665, 50.68642825310476]],
    [ "Enedis",[3.1274986739169868, 50.65434952922794]],
    [ "Enedis",[3.119371829079509, 50.66164446808134]],
    [ "Enedis",[3.1308865216198494, 50.66406698261588]],
    [ "Poste électrique de Super-Besse",[2.8745204086949747, 45.49649741835177]],
    [ "Enedis",[1.39044925, 47.25084635]],
    [ "Poste électrique de Le Breuil",[2.21415178780385, 45.246270702866944]],
    [ "Enedis",[4.121095152980167, 50.12162366539026]],
    [ "Enedis",[2.254538016846676, 48.8975428446092]],
    [ "Enedis",[-2.000852175784879, 46.908060205726414]],
    [ "Enedis",[-1.9808388499999998, 46.893634250000005]],
    [ "Enedis",[-1.9900280999999995, 46.915127299999995]],
    [ "Enedis",[-2.004059222749653, 46.90181440552504]],
    [ "82351339",[2.1945534273689518, 48.679545056272325]],
    [ "Enedis",[2.196051507858897, 48.68175037217348]],
    [ "Enedis",[2.193319917888973, 48.68832914286406]],
    [ "Enedis",[2.2001169380088856, 48.68446537637798]],
    [ "Enedis",[2.20228035, 48.687501049999995]],
    [ "Enedis",[2.203642147494391, 48.69009388250859]],
    [ "Enedis",[2.20164412677545, 48.68232065575051]],
    [ "HANGAR",[2.2010679, 48.686375]],
    [ "Enedis",[3.9141672744313705, 43.57332449154419]],
    [ "Poste électrique de Port-du-Temple",[4.832262643302113, 45.76053582958957]],
    [ "Enedis",[2.3026630885402084, 45.55521214363431]],
    [ "Enedis",[2.3067989443384103, 45.55492886559252]],
    [ "Enedis",[3.933460102474936, 43.59052785377489]],
    [ "Enedis",[2.310345107786695, 45.53650259415009]],
    [ "Enedis",[3.9304727838730633, 43.585072629018434]],
    [ "Enedis",[3.925703325948997, 43.58462865186961]],
    [ "Enedis",[2.3040045180655526, 45.555404226036394]],
    [ "Enedis",[6.260908299586258, 48.69864938246902]],
    [ "Enedis",[6.2541541659899975, 48.70067735578112]],
    [ "Enedis",[6.258294213812484, 48.70714096105778]],
    [ "Enedis",[6.254371012749218, 48.6961282546674]],
    [ "Enedis",[6.25420648445891, 48.70285712254959]],
    [ "Enedis",[6.253490460897435, 48.699782617307704]],
    [ "Enedis",[6.25440265819581, 48.707826129859065]],
    [ "Enedis",[6.260678854760549, 48.7094903146861]],
    [ "Enedis",[6.260988047174588, 48.70123963587522]],
    [ "Enedis",[6.25678611468945, 48.705556452975436]],
    [ "Enedis",[6.261517603177517, 48.70430493416189]],
    [ "Enedis",[6.259101062041312, 48.701563258086814]],
    [ "Enedis",[6.261624593905112, 48.70053067434262]],
    [ "Enedis",[6.252475032465692, 48.70465246237552]],
    [ "Enedis",[6.257429980034292, 48.70464011564859]],
    [ "Enedis",[6.249924128079664, 48.70730796685381]],
    [ "Enedis",[6.252398911350738, 48.70733723408682]],
    [ "Enedis",[6.265198585461584, 48.70475872634808]],
    [ "Enedis",[6.258610682379588, 48.697899668466434]],
    [ "Enedis",[6.250536254161152, 48.710802215935125]],
    [ "Enedis",[6.262258618269231, 48.708333963461534]],
    [ "Enedis",[3.4093729264642154, 49.63800812338708]],
    [ "Enedis",[3.3999393675548593, 49.644293909665606]],
    [ "Enedis",[3.4281861111512764, 49.65199091562056]],
    [ "Poste électrique d'Alsace",[2.2483553237000398, 48.89090332619311]],
    [ "Enedis",[1.4388094642037983, 44.4574913559337]],
    [ "Enedis",[-1.170469934765312, 46.45472571276709]],
    [ "Enedis",[-1.1713671500000002, 46.447963349999995]],
    [ "Enedis",[-1.1694862499999998, 46.4511028]],
    [ "Enedis",[3.7344412489296643, 45.54396694373088]],
    [ "Enedis",[3.737455854178621, 45.540127249964776]],
    [ "Enedis",[3.7407202061055327, 45.55574957084742]],
    [ "Enedis",[3.740564594524379, 45.549313972741814]],
    [ "Saint-Joseph",[3.742901157372466, 45.54775024591194]],
    [ "Enedis",[3.7282191751777405, 45.53871943868823]],
    [ "Enedis",[3.7662889604079615, 45.538417017790174]],
    [ "Enedis",[-1.3595441667336112, 46.80205379974811]],
    [ "Enedis",[-1.3605158246058875, 46.82439086491595]],
    [ "Enedis",[-1.3390096166577536, 46.812890500272246]],
    [ "Enedis",[-1.3619396200429026, 46.821512604254686]],
    [ "Enedis",[-1.373073506998189, 46.809447767517426]],
    [ "Enedis",[-1.42108545, 46.87002919999999]],
    [ "Enedis",[-1.3617770527175075, 46.819546246192076]],
    [ "Enedis",[-1.3540450000000004, 46.82067625]],
    [ "Enedis",[2.2876360516033234, 48.7501662404667]],
    [ "Enedis",[-1.3497345435505115, 43.31608637024796]],
    [ "Enedis",[-1.3528246257671832, 43.316579276721846]],
    [ "Enedis",[-2.00617545, 46.8896048]],
    [ "Enedis",[-2.0156474, 46.878786399999996]],
    [ "Enedis",[6.796259228509883, 47.51460120609602]],
    [ "Enedis",[6.796798755973965, 47.51944789536638]],
    [ "Poste électrique de Méry-sur-Seine",[3.918310757488458, 48.519880212586756]],
    [ "Enedis",[-1.8336332499999985, 48.19138605]],
    [ "Enedis",[-1.83357905, 48.19406545]],
    [ "Enedis",[2.311666423333762, 48.757175545596674]],
    [ "Enedis",[4.041351194585449, 48.27746805537662]],
    [ "Enedis",[4.035112185242081, 48.27239229671568]],
    [ "Enedis",[4.039628989416956, 48.272924952423615]],
    [ "Enedis",[4.0409007500000005, 48.27525025]],
    [ "Geredis",[0.03826149730070068, 46.24763947198912]],
    [ "Enedis",[-1.893290175500566, 48.042060599590016]],
    [ "Enedis",[-1.9074999381845121, 48.05306574574553]],
    [ "Enedis",[-1.8541968947142118, 48.058712592178104]],
    [ "Lemas",[5.658656033849177, 45.25815995113772]],
    [ "Enedis",[-1.4892083999999999, 48.105756150000005]],
    [ "Enedis",[-1.485388716089997, 48.108190433004964]],
    [ "Enedis",[-1.4793094598532748, 48.10563521138628]],
    [ "Enedis",[-1.916654111363394, 48.15237978841275]],
    [ "Enedis",[2.0520265428647493, 47.21390467116407]],
    [ "Enedis",[2.093649719389148, 47.24147048801141]],
    [ "Enedis",[5.417266841300886, 46.46891775794103]],
    [ "Poste électrique de Chambry",[2.9072506064531454, 48.98407286250369]],
    [ "Enedis",[1.9783685000000002, 43.76133255]],
    [ "Geredis",[-0.09989919999999955, 46.3851024]],
    [ "Poste électrique de Plessis-Gassot",[2.4326928440726063, 49.03408698756386]],
    [ "Poste électrique de Villiers-le-Bel",[2.4078299391140683, 49.01087276737201]],
    [ "Sous-station de Gonesse",[2.442026140072855, 49.00928901125534]],
    [ "Poste électrique de Mitry-Mory",[2.631386503223531, 48.96668336435672]],
    [ "Enedis",[0.11477980058232805, 46.1337194272844]],
    [ "Enedis",[0.10269966181171414, 46.13255606772576]],
    [ "Enedis",[0.10913185866397905, 46.13367495696756]],
    [ "Enedis",[0.10805958078235554, 46.13100085205674]],
    [ "Enedis",[0.10138240798812463, 46.13454071958626]],
    [ "Enedis",[0.10480515059294788, 46.13152267336215]],
    [ "Enedis",[0.10761605577656612, 46.128254956429465]],
    [ "Enedis",[0.11723073854372888, 46.13513380079138]],
    [ "Enedis",[0.1063448008863633, 46.1339656424472]],
    [ "Enedis",[0.1098434124777824, 46.13217698460741]],
    [ "Enedis",[0.1027366659894568, 46.136591849912136]],
    [ "Enedis",[0.12224315958078588, 46.134152179917564]],
    [ "Enedis",[0.11371402169130226, 46.13329543176792]],
    [ "Enedis",[0.10618796538949712, 46.127605958561]],
    [ "Enedis",[0.12030508754961311, 46.133175503345676]],
    [ "Enedis",[2.8442383689083686, 49.16095786288973]],
    [ "Enedis",[2.846202446704388, 49.16513926310544]],
    [ "Enedis",[6.145840705732792, 48.68587289516931]],
    [ "Enedis",[6.147426770149387, 48.68495023988125]],
    [ "Enedis",[6.143319767296102, 48.68058387954768]],
    [ "Enedis",[6.1552730560819775, 48.685096235397005]],
    [ "Enedis",[6.153937448989263, 48.68691012512747]],
    [ "Enedis",[6.145760465218881, 48.680579126459996]],
    [ "Enedis",[4.113991040152268, 48.53303922776916]],
    [ "RTE",[4.010453998019302, 48.76740868510536]],
    [ "Enedis",[1.0295634923984986, 47.89116161000137]],
    [ "Poste électrique de Vesle",[4.24055317672798, 49.20151866320351]],
    [ "Pontfaverger",[4.339924675473641, 49.299465458068724]],
    [ "Poste électrique de Lonny",[4.572872065285259, 49.82747483626427]],
    [ "Poste électrique de Seuil",[4.434041761300077, 49.45354372479469]],
    [ "Enedis",[5.57409465, 46.682793999999994]],
    [ "Enedis",[5.557153699999999, 46.68446095]],
    [ "Colbert",[5.5469444028565285, 46.675167773944644]],
    [ "Champ de Foire",[5.54642199810279, 46.6736817676195]],
    [ "Ripley",[5.569020268632107, 46.6813485975918]],
    [ "Enedis",[5.559558943512562, 46.69200812206408]],
    [ "Enedis",[5.559478826301629, 46.66855334258375]],
    [ "Salines Coupure",[5.5640590641306265, 46.66733374239353]],
    [ "Enedis",[5.560894933268566, 46.684578157917834]],
    [ "Enedis",[5.552361250000001, 46.681259600000004]],
    [ "Clos Patro",[5.562569965903358, 46.67708528642788]],
    [ "Enedis",[5.548547650000001, 46.68421850000001]],
    [ "Maison pour tous",[5.56300445, 46.66956484999999]],
    [ "Enedis",[5.557022549999999, 46.666330200000004]],
    [ "Bussenet",[5.564051979816838, 46.6746934132147]],
    [ "Enedis",[5.55893185, 46.68811469999999]],
    [ "Enedis",[5.55910809043633, 46.66534880387253]],
    [ "Poste électrique de Baixas",[2.804247313850023, 42.73229026891493]],
    [ "Solvan",[5.5651972443408075, 46.681755551145436]],
    [ "Bois Blanc",[5.559994546005055, 46.674124948068226]],
    [ "BCC",[5.573624124687104, 46.6834339455134]],
    [ "Les Dombes",[5.545980069946626, 46.670929583979316]],
    [ "Combes",[5.565708973083227, 46.684829604079894]],
    [ "Enedis",[5.5807786, 46.6845068]],
    [ "Richebourg",[5.555701695490741, 46.6781572326396]],
    [ "Ciloe",[5.57123425, 46.67261480000001]],
    [ "Enedis",[5.543943141696347, 46.67665693858826]],
    [ "Enedis",[5.55815215, 46.68144325000001]],
    [ "Le Pontot",[5.545404476357226, 46.679369431877475]],
    [ "Berthelot",[5.539872849999999, 46.664522049999995]],
    [ "Caisse Épargne",[5.55206675, 46.67205525]],
    [ "Marché Couvert",[5.543877088129386, 46.67326234152171]],
    [ "Enedis",[5.540885578821658, 46.660447076794114]],
    [ "Champ des Martyrs",[5.540633688160682, 46.67179181783137]],
    [ "Enedis",[5.549505350000001, 46.6785093]],
    [ "Mendes France",[5.56541145, 46.67674675]],
    [ "Les Toupes",[5.5408655499999995, 46.66525015]],
    [ "Cdt Villard",[5.567482986970305, 46.6731977952953]],
    [ "Vallière",[5.544320251740723, 46.674468833940004]],
    [ "Centre Médico Social",[5.55021209247128, 46.67558006780252]],
    [ "Enedis",[5.56178495, 46.67126335]],
    [ "Centre aéré",[5.5386885999999995, 46.666735349999996]],
    [ "Levant",[5.575460102541553, 46.68052420364364]],
    [ "Château d'Eau",[5.5678284, 46.677803149999995]],
    [ "Octroi",[5.556608710446173, 46.67946636697228]],
    [ "Gare",[5.550043199999999, 46.668625750000004]],
    [ "Fontaine de Rome",[5.549482615057534, 46.670553954596144]],
    [ "Enedis",[2.0488578356049514, 47.2324559027153]],
    [ "CESML",[3.829025384124972, 43.652129747918664]],
    [ "CESML",[3.83045185, 43.650707800000006]],
    [ "CESML",[3.8325711012439374, 43.64899523411697]],
    [ "CESML",[3.8286913500000006, 43.64236185]],
    [ "CESML",[3.83032755, 43.645736400000004]],
    [ "CESML",[3.8270883327361673, 43.643466666619204]],
    [ "CESML",[3.8353935, 43.651837]],
    [ "CESML",[3.8309276000000003, 43.648994099999996]],
    [ "CESML",[3.8303806272499616, 43.6438145842013]],
    [ "CESML",[3.827619780456046, 43.65115281658248]],
    [ "Enedis",[5.049094091129317, 43.897624220735906]],
    [ "Enedis",[-1.3996654, 48.1101198]],
    [ "Enedis",[-1.3950833447184567, 48.11522381333577]],
    [ "Enedis",[-1.3980890866769997, 48.11256731597953]],
    [ "Enedis",[-1.383981043813527, 48.11332126842699]],
    [ "Enedis",[-1.4030125351244556, 48.11019678165254]],
    [ "Enedis",[-1.417843952148698, 48.11032455430979]],
    [ "Enedis",[-1.4001094314499902, 48.11508515782049]],
    [ "Enedis",[-1.4000477565645544, 48.11281041100527]],
    [ "Enedis",[-1.414894074041497, 48.10435493494408]],
    [ "Enedis",[-1.3871360776223116, 48.11287637436911]],
    [ "Enedis",[-1.4153160948943295, 48.10223578968032]],
    [ "Enedis",[-1.379084799288119, 48.11128857616489]],
    [ "Enedis",[-1.3948410857890035, 48.11231506428413]],
    [ "Enedis",[-1.3970824205157517, 48.11319777524995]],
    [ "Enedis",[-1.4048471821408761, 48.11084078557959]],
    [ "Enedis",[-1.4048174498677297, 48.09986516195414]],
    [ "Enedis",[-1.41023280713272, 48.10016167083734]],
    [ "Poste électrique des Mazures",[4.62588566732806, 49.90568304637471]],
    [ "Poste électrique de Revin",[4.6132168231036585, 49.92559862451688]],
    [ "Enedis",[6.860034497456738, 47.62476475145213]],
    [ "Enedis",[6.863690723226351, 47.620157787027026]],
    [ "Enedis",[6.864932532650573, 47.61286489003947]],
    [ "Enedis",[6.858531911523419, 47.62608175428287]],
    [ "Enedis",[6.854141135860545, 47.615896310375916]],
    [ "Enedis",[6.855027123143953, 47.61806855324735]],
    [ "Enedis",[6.871070178202333, 47.61639248416711]],
    [ "Enedis",[6.859881114242306, 47.612919088774156]],
    [ "Enedis",[6.866357506751503, 47.614835501605185]],
    [ "Enedis",[6.859364990403442, 47.61544658352451]],
    [ "Enedis",[6.86157355, 47.61406905]],
    [ "Enedis",[6.8633891065936075, 47.62326770244748]],
    [ "Enedis",[6.865991827406227, 47.62000744602918]],
    [ "Enedis",[6.865043776927506, 47.617364558432655]],
    [ "Enedis",[6.861549536746302, 47.617144829920356]],
    [ "Enedis",[6.849732116260206, 47.616251796608864]],
    [ "Enedis",[6.849583333007745, 47.619233993626366]],
    [ "Enedis",[6.859876693176932, 47.62267809451216]],
    [ "Enedis",[6.855194302163888, 47.6131089945635]],
    [ "Enedis",[6.872140516181388, 47.6154030012054]],
    [ "Poste électrique de Mastaing",[3.290724027978754, 50.29646312721854]],
    [ "Poste électrique de Gros-Caillou",[3.3386772085351266, 50.31717250175045]],
    [ "Enedis",[5.4624021, 46.485630349999994]],
    [ "Enedis",[-1.3169303090819262, 46.32598681901184]],
    [ "Enedis",[-1.3162484175678237, 46.3246818488373]],
    [ "Enedis",[-1.3157519500000003, 46.323583199999995]],
    [ "Enedis",[-1.2661833214141616, 43.36649330354202]],
    [ "Enedis",[-1.310355475757577, 46.31976142727273]],
    [ "Enedis",[-1.20294285, 43.35480939999999]],
    [ "Enedis",[-1.3975513220824762, 48.11515852217631]],
    [ "Enedis",[-1.3824321436691676, 48.11179915783301]],
    [ "Enedis",[2.33003981257372, 48.759808671879185]],
    [ "SRD",[0.0930902, 46.6779081]],
    [ "Enedis",[5.329471164227955, 46.43034187700532]],
    [ "Enedis",[-1.556257973850768, 47.24082738358105]],
    [ "Enedis",[6.267142581091667, 48.71549962018432]],
    [ "Enedis",[6.25590797136252, 48.71156837249813]],
    [ "Enedis",[6.2714235749079155, 48.71381816194869]],
    [ "Enedis",[6.252473077997601, 48.712682924400475]],
    [ "Enedis",[6.266797348944415, 48.72077517388914]],
    [ "Enedis",[6.266120819192418, 48.71925696676679]],
    [ "Enedis",[6.257336815629717, 48.71423302746616]],
    [ "Enedis",[6.264906221115901, 48.71461507120845]],
    [ "Enedis",[6.273286858918742, 48.714757126152335]],
    [ "Enedis",[6.263441190155628, 48.71926066789721]],
    [ "Enedis",[6.251620675555389, 48.71478355517133]],
    [ "Enedis",[6.266599072672089, 48.71168876171545]],
    [ "Enedis",[6.259333754057416, 48.71294414831097]],
    [ "Enedis",[6.262727229122751, 48.715828063394746]],
    [ "Enedis",[6.275330981613261, 48.71436993088268]],
    [ "Enedis",[6.2651435823832955, 48.71253504782262]],
    [ "Enedis",[6.274667749228833, 48.713576155587035]],
    [ "Enedis",[4.0085463292717325, 48.284824442353155]],
    [ "Riviere Jaures",[4.03794189689503, 48.289007115995105]],
    [ "Enedis",[4.0292083000000005, 48.281667000000006]],
    [ "Enedis",[4.0142320499999995, 48.28364725]],
    [ "Enedis",[4.021926609660814, 48.28109053286523]],
    [ "Enedis",[4.0246028746695, 48.27863734069878]],
    [ "Enedis",[4.019188799093674, 48.27761573369509]],
    [ "Victor Hugo",[4.015518171938705, 48.280742968189855]],
    [ "Enedis",[5.356944127656807, 46.455495505509575]],
    [ "Poste électrique de Cernay",[4.086372164426298, 49.27226778420889]],
    [ "Nouettes",[4.098013467711316, 49.227291915836474]],
    [ "CESML",[3.794901108289118, 43.64931550791346]],
    [ "CESML",[3.80408175, 43.64771285]],
    [ "83114221",[2.2269810779925825, 43.61006156627815]],
    [ "Poste électrique de Soissons-Notre-Dame",[3.32007121985862, 49.35596416092265]],
    [ "Enedis",[-1.83904701039106, 47.942508022384075]],
    [ "Enedis",[-1.8292924127904413, 47.93665447504959]],
    [ "Enedis",[2.2322218428529474, 43.596462172688426]],
    [ "Petit Train",[2.230532607760222, 43.59754220815275]],
    [ "Enedis",[2.234309354424171, 43.59429803619776]],
    [ "Enedis",[-1.5198287999999998, 47.23482939999999]],
    [ "Enedis",[-1.809135650183305, 48.03680343752366]],
    [ "Enedis",[-1.8038790465581187, 48.021840895745356]],
    [ "Enedis",[6.802377499624487, 47.51379219775765]],
    [ "Enedis",[6.807448404088756, 47.51375345043003]],
    [ "Enedis",[6.80641614854465, 47.515059651758456]],
    [ "Enedis",[3.373722727066548, 49.596480008504926]],
    [ "Enedis",[6.809714487026914, 47.516888561358456]],
    [ "Enedis",[-4.0615139000000005, 47.87087570000001]],
    [ "Enedis",[2.312659903811563, 48.814984001120244]],
    [ "MT Racine",[2.3126056746465835, 48.8128856823569]],
    [ "Enedis",[2.313929552436299, 48.81571662363459]],
    [ "MT Roger",[2.3055685792276437, 48.81357401649273]],
    [ "Enedis",[2.310363808402901, 48.81515490359563]],
    [ "Arthur",[2.3083522377504826, 48.81486862110751]],
    [ "Renaudel",[2.3085115432065133, 48.8132458246322]],
    [ "MT Toit",[2.3059121672253675, 48.81610194708577]],
    [ "MT Hertz",[2.30670262963087, 48.81095205459097]],
    [ "Enedis",[2.306823998491839, 48.812471689477874]],
    [ "MT Chopin",[2.304520879896873, 48.81211980433555]],
    [ "MT Pugno",[2.309160304391902, 48.81148898104434]],
    [ "Mulin",[2.3116607690382165, 48.812276525531566]],
    [ "MT Hippolyte",[2.310231947170674, 48.81095629919377]],
    [ "Enedis",[2.2533993, 43.593639350000004]],
    [ "Enedis",[2.304634177949931, 45.541434663189385]],
    [ "Enedis",[2.2680562604770103, 45.520075739271704]],
    [ "Poste électrique de Génissiat",[5.804613205739158, 46.0558914222819]],
    [ "Enedis",[-1.3651121493618854, 47.97062384986502]],
    [ "Enedis",[-1.3672435359806019, 47.95586685595838]],
    [ "Enedis",[-1.3393405267904308, 47.97196996366137]],
    [ "Enedis",[4.235143778850147, 43.6751710040694]],
    [ "Enedis",[-1.5204904351858446, 47.965843178564484]],
    [ "Enedis",[5.085980978023687, 43.84630863923455]],
    [ "Enedis",[5.091317099999999, 43.839985049999996]],
    [ "L Ambillien",[6.228852627765324, 46.190193894636025]],
    [ "Enedis",[3.069342470683602, 45.765686786108205]],
    [ "Enedis",[3.0718093257643244, 45.77182922105543]],
    [ "Enedis",[3.0668351613644336, 45.77150751408784]],
    [ "Enedis",[-1.802431129404005, 48.60514331478525]],
    [ "Enedis",[-1.9454270877654771, 48.247666122260355]],
    [ "Enedis",[-1.9492496466021514, 48.24473175205824]],
    [ "Enedis",[-1.9530286075235297, 48.250114101152334]],
    [ "Enedis",[-1.9509872964788486, 48.25310738379349]],
    [ "519055",[7.822038857925323, 48.64322355656528]],
    [ "Enedis",[-1.0146736499999998, 45.671675300000004]],
    [ "Enedis",[-1.0143200917757913, 45.66197910289845]],
    [ "Enedis",[-1.0152217002800032, 45.66705538628491]],
    [ "Enedis",[-1.0112227, 45.675520899999995]],
    [ "Enedis",[-1.0125885721479135, 45.67373111949437]],
    [ "Enedis",[-1.0125679566798167, 45.6645684222074]],
    [ "Enedis",[-1.011892877731757, 45.67111771206754]],
    [ "Enedis",[-1.009579190815755, 45.66463312117567]],
    [ "Enedis",[-1.0079268213365353, 45.67138271651938]],
    [ "Enedis",[-1.0086749542548756, 45.673769726884586]],
    [ "Enedis",[-1.0407307798819638, 45.66691089462743]],
    [ "Enedis",[-1.01189138385214, 45.6682980424126]],
    [ "Enedis",[-1.0163199313743998, 45.669562595622516]],
    [ "SICAE Oise",[2.5707238842410653, 49.52038521286671]],
    [ "SICAE Oise",[2.568635244942107, 49.523127278874554]],
    [ "Magniolias",[-1.8688472104935703, 48.10175352572929]],
    [ "Enedis",[-1.8641705675742393, 48.107229960105954]],
    [ "Enedis",[2.260723892934073, 45.6998153819142]],
    [ "Enedis",[-1.894194250623198, 48.075428699482444]],
    [ "519057",[7.825669697869267, 48.64977711671361]],
    [ "Enedis",[3.724035713438282, 43.92337040716367]],
    [ "Enedis",[3.72485155, 43.92217850000001]],
    [ "Enedis",[-1.8907016865894206, 48.216169230408575]],
    [ "Enedis",[-1.8870017429766472, 48.2174180749972]],
    [ "Enedis",[-1.896824164540266, 48.22020962316592]],
    [ "Enedis",[-1.886593319509032, 48.214055107488164]],
    [ "Enedis",[-1.8872000653877232, 48.21740589543924]],
    [ "Enedis",[-1.8953514479240852, 48.218555525415375]],
    [ "Enedis",[-1.895827292982457, 48.21565998011695]],
    [ "Enedis",[-1.888297433267222, 48.214921310402616]],
    [ "Enedis",[2.0636752169788686, 47.21060918891355]],
    [ "Enedis",[2.0633342816026303, 47.203712239467]],
    [ "Enedis",[2.0574960343047217, 47.217235003437594]],
    [ "Enedis",[2.06801224977973, 47.20962802776699]],
    [ "Enedis",[2.0554455643657197, 47.215793440476666]],
    [ "Enedis",[3.61516535, 43.99011869999999]],
    [ "Enedis",[2.0687373452420452, 47.20597570485347]],
    [ "Enedis",[2.0692299834979955, 47.213866250319896]],
    [ "Enedis",[2.0603562808444535, 47.20962867477721]],
    [ "Enedis",[3.608775579115779, 43.99185896069029]],
    [ "Enedis",[3.605352565777923, 43.99215242474292]],
    [ "Enedis",[3.6058873700252376, 43.98834235220345]],
    [ "Enedis",[3.61585333603167, 43.99024823072145]],
    [ "Enedis",[3.0719062729822673, 45.77409224534491]],
    [ "Enedis",[3.0707112648991517, 45.77678189219973]],
    [ "Enedis",[1.9444687891890833, 48.826111274846056]],
    [ "Enedis",[2.0633800641339093, 47.220234967067626]],
    [ "Enedis",[1.9360849721551991, 48.82923125090271]],
    [ "Enedis",[1.9620344459809658, 48.80010929398277]],
    [ "Enedis",[1.965651750000001, 48.820556999999994]],
    [ "Enedis",[1.91775332369128, 48.81952474411067]],
    [ "Enedis",[1.9474910644342394, 48.81703561849726]],
    [ "Enedis",[1.969617529282815, 48.80183723562985]],
    [ "Enedis",[1.9456837596125578, 48.830391771369406]],
    [ "Enedis",[1.95923363885561, 48.81089593483549]],
    [ "Enedis",[1.9612020428985462, 48.8223240336786]],
    [ "Enedis",[1.95356515, 48.82670314999999]],
    [ "519026",[7.823692700000001, 48.657002750000004]],
    [ "519049",[7.8203551413531525, 48.657239117312336]],
    [ "519002",[7.81922995, 48.658101900000005]],
    [ "Enedis",[-0.003500831530020835, 48.74103017166113]],
    [ "Enedis",[-0.012341648928700879, 48.74943524262192]],
    [ "Enedis",[-0.026343177553861244, 48.740063523766004]],
    [ "Enedis",[-0.02930539524972269, 48.74854618742341]],
    [ "Enedis",[-0.0077596975525128795, 48.717819043116016]],
    [ "Enedis",[-0.025288763073708573, 48.7469511609]],
    [ "Enedis",[-0.017586484156073164, 48.74884137865384]],
    [ "Enedis",[-0.005321020785673219, 48.74350555761246]],
    [ "Enedis",[-0.010639537010271113, 48.72067167504099]],
    [ "Enedis",[-0.009497615964747757, 48.742656760751906]],
    [ "Enedis",[-0.020771783719611598, 48.751520448866565]],
    [ "Enedis",[-0.032011329004755354, 48.751660858511904]],
    [ "Enedis",[-0.010148158889390939, 48.74823568762562]],
    [ "Enedis",[-0.0026504170418439753, 48.745832869732126]],
    [ "Enedis",[-0.02720662526934374, 48.7442476849494]],
    [ "Enedis",[-0.015072625512050705, 48.73997869474234]],
    [ "Enedis",[-0.032123394307983756, 48.755249803496476]],
    [ "Enedis",[-0.03252785333297356, 48.74865979184889]],
    [ "Enedis",[-0.03701351400512358, 48.73201151232564]],
    [ "Enedis",[-0.007979416416974376, 48.757066472002236]],
    [ "Enedis",[-0.011399140850248025, 48.75056225042914]],
    [ "Enedis",[-0.027188993300753594, 48.74406330472362]],
    [ "Enedis",[-0.037966452911346225, 48.75494751660147]],
    [ "Enedis",[3.071600027164884, 45.777363008231475]],
    [ "Le Revermont",[5.503200012643678, 46.638297617528735]],
    [ "Poste électrique de la Vérinnerie",[1.9429102559529459, 48.806135065902424]],
    [ "Enedis",[3.063123192368986, 45.779090281530365]],
    [ "Enedis",[3.0635871293564647, 45.78079318936355]],
    [ "Enedis",[3.0633561628771284, 45.78243742558295]],
    [ "Enedis",[3.0575865013784354, 45.770309218150885]],
    [ "Enedis",[3.0557900884729015, 45.772627050421335]],
    [ "Enedis",[3.0570542000380407, 45.78013426980586]],
    [ "Enedis",[3.0607895075448917, 45.780526234141966]],
    [ "Enedis",[3.0651199913613016, 45.78264275405248]],
    [ "Enedis",[3.064201401928374, 45.78377163140495]],
    [ "Enedis",[3.053961288558837, 45.77102355811258]],
    [ "Enedis",[3.053417262583608, 45.774924212148264]],
    [ "Enedis",[3.053907700489659, 45.77598479250631]],
    [ "Enedis",[3.049118868023216, 45.77876013893268]],
    [ "Enedis",[3.0489214499999995, 45.780556499999996]],
    [ "Enedis",[3.052417649702058, 45.779275549274466]],
    [ "Enedis",[3.053043059180393, 45.78180601120882]],
    [ "Enedis",[3.054144698752144, 45.78163413214939]],
    [ "Enedis",[3.0535773417375047, 45.78443953269919]],
    [ "Poste électrique de Rueyres",[2.6891888693379142, 44.77925379527624]],
    [ "Poste électrique de La Muette",[2.272360461655139, 48.8562710063103]],
    [ "Enedis",[4.810615708776666, 43.92807173366225]],
    [ "Enedis",[4.799748149999999, 43.9288781]],
    [ "Enedis",[4.804737430960624, 43.93495697632255]],
    [ "Enedis",[4.810888110073385, 43.9252319219212]],
    [ "Enedis",[4.798833225926129, 43.933493356954195]],
    [ "Enedis",[4.810474769060492, 43.927532818858054]],
    [ "Enedis",[4.809496960866461, 43.94953661512376]],
    [ "Enedis",[4.800471281958622, 43.93522691028974]],
    [ "Enedis",[4.810809185790422, 43.952083600279906]],
    [ "Enedis",[4.781208837474508, 43.93102829852256]],
    [ "Enedis",[4.794431091281285, 43.92791242320824]],
    [ "Enedis",[4.80208577221449, 43.933309755378836]],
    [ "Enedis",[4.799010017151705, 43.925561498067864]],
    [ "Enedis",[4.8026543270766995, 43.94222230848434]],
    [ "Enedis",[4.92257475, 43.889250000000004]],
    [ "Enedis",[4.7811757347288815, 43.93272286295759]],
    [ "Enedis",[4.803008143168599, 43.94574941192932]],
    [ "Enedis",[4.782794299999999, 43.92980534999999]],
    [ "Enedis",[4.806330598779157, 43.94434900887014]],
    [ "Enedis",[4.802526308068755, 43.93506412519125]],
    [ "Enedis",[4.804947625632898, 43.93933660497261]],
    [ "Enedis",[4.896549923052656, 43.90770781135395]],
    [ "Enedis",[4.809776564510695, 43.950763555869976]],
    [ "Enedis",[4.7962736, 43.936284150000006]],
    [ "Enedis",[4.797026779311643, 43.95258586265879]],
    [ "Enedis",[4.8121555, 43.95182645]],
    [ "Enedis",[4.80619784587389, 43.94631957854477]],
    [ "Enedis",[4.805867382746958, 43.93106456720917]],
    [ "Enedis",[4.810689737055335, 43.934770833726525]],
    [ "Enedis",[4.788530362828182, 43.93407532313876]],
    [ "Enedis",[4.777695359790179, 43.93350192864558]],
    [ "Enedis",[4.811127269829957, 43.94576155893681]],
    [ "Enedis",[4.798083180518074, 43.930476206858025]],
    [ "Enedis",[4.812591750000001, 43.9461828]],
    [ "Enedis",[4.8103022203681, 43.95247570961981]],
    [ "Enedis",[4.813665540868691, 43.94541172214335]],
    [ "Enedis",[4.807002400000001, 43.93528295000001]],
    [ "Enedis",[4.847252846688569, 43.93431974857875]],
    [ "Enedis",[4.802973733493311, 43.95104038280392]],
    [ "Enedis",[4.811784604124666, 43.94855902710894]],
    [ "Enedis",[4.799081880481067, 43.93108835427396]],
    [ "Enedis",[4.803733841490553, 43.92334213043649]],
    [ "Enedis",[4.896559071692443, 43.90764550492861]],
    [ "Enedis",[4.810409159175312, 43.92938793477161]],
    [ "Enedis",[4.800725648629273, 43.940072482621225]],
    [ "Enedis",[4.804991234873445, 43.94922553679858]],
    [ "Enedis",[4.7810595284464945, 43.9345611258642]],
    [ "MK 12 Février 97",[2.306820263812197, 48.81897324896138]],
    [ "Enedis",[2.2960409364400225, 48.8144297682636]],
    [ "Enedis",[2.2928316811557425, 48.813972882662746]],
    [ "Enedis",[2.296112323283739, 48.819856251576184]],
    [ "Enedis",[2.277679662513105, 48.81405758000861]],
    [ "Enedis",[2.294737713670192, 48.81419523414211]],
    [ "MK Barbusse RPT",[2.287536701396885, 48.814716284304076]],
    [ "Enedis",[2.28188100086077, 48.8139468181515]],
    [ "Enedis",[2.2882480833437806, 48.81569786586671]],
    [ "MK Gambetta 53",[2.30905515, 48.82172996666666]],
    [ "Cortenaz",[6.309752606552754, 46.19085787356342]],
    [ "Enedis",[6.324435230160765, 46.18922888966505]],
    [ "519051",[7.812755900000001, 48.668413550000004]],
    [ "Enedis",[2.43278232732438, 48.84828553410597]],
    [ "Enedis",[2.4498809, 48.85057505]],
    [ "Enedis",[2.440502145835363, 48.84982446311634]],
    [ "Enedis",[2.4515144307297256, 48.8478980462822]],
    [ "Enedis",[2.454357037476113, 48.847359958012554]],
    [ "Poste source de Vincennes",[2.4245568434111857, 48.84879194732742]],
    [ "Enedis",[2.429518957804121, 48.845701721334194]],
    [ "À la Guide",[5.697009049999999, 46.584570049999996]],
    [ "Poitte",[5.6887760138405925, 46.5783283617645]],
    [ "Lesenet",[5.700756301382839, 46.58293344473607]],
    [ "Enedis",[5.69434848100011, 46.58191985623431]],
    [ "Schweitzer",[2.2865496812403103, 48.74235084762791]],
    [ "RTE",[4.899093464022467, 45.707047285752395]],
    [ "Enedis",[4.927708203956829, 45.71193943287445]],
    [ "Lazard",[4.9282653819526185, 45.713627303220356]],
    [ "Général Buat",[-1.53996566545222, 47.22903199083381]],
    [ "Enedis",[-1.54528057475021, 47.227110415738395]],
    [ "Chalatres 2",[-1.5370774094521507, 47.230616282655824]],
    [ "Enedis",[-1.6555582209911472, 47.960791240114396]],
    [ "Enedis",[-1.6282981753062205, 47.94725726884712]],
    [ "Enedis",[4.9454552000000005, 43.908263049999995]],
    [ "Enedis",[4.945580907985482, 43.89442610762251]],
    [ "Enedis",[4.949570099999998, 43.89970344999999]],
    [ "Enedis",[4.94707268636994, 43.89187966899284]],
    [ "Enedis",[4.9355145256359165, 43.89589429555423]],
    [ "Enedis",[4.951410449999999, 43.895099900000005]],
    [ "519056",[7.8234383, 48.662631950000005]],
    [ "519024",[7.8227232554060455, 48.660254820678034]],
    [ "Enedis",[6.138384845760865, 48.70662271169336]],
    [ "Enedis",[-1.6725648184769912, 46.76637615323417]],
    [ "Enedis",[-1.6892042280191157, 46.750785955226895]],
    [ "Enedis",[-1.6898577000000001, 46.75545730000001]],
    [ "Enedis",[-1.6844069705257674, 46.758298947631204]],
    [ "Enedis",[-1.6930554701174816, 46.75541259507351]],
    [ "Enedis",[-1.683245974721593, 46.751575906193494]],
    [ "Enedis",[-1.6807067, 46.75640585000001]],
    [ "Enedis",[-1.6502136505017952, 47.93356737066693]],
    [ "Enedis",[-1.649360163018435, 47.93641850720119]],
    [ "Enedis",[-2.0604635367224606, 48.631650377873626]],
    [ "Enedis",[3.0471678491860597, 45.83517113844641]],
    [ "Enedis",[2.279837016700906, 48.73999779002207]],
    [ "Enedis",[4.958278856336712, 45.71784727824597]],
    [ "Enedis",[4.9520037476123075, 45.71912943169066]],
    [ "Enedis",[2.762994332104444, 50.68954695124884]],
    [ "Enedis",[2.7711433184378405, 50.700510164547005]],
    [ "Enedis",[2.768314052990456, 50.700235305867466]],
    [ "Enedis",[3.0553606015187857, 45.81331338257393]],
    [ "Enedis",[3.0591240570803833, 45.81564096540138]],
    [ "Enedis",[2.447753645016794, 48.894275430698926]],
    [ "Enedis",[-0.82640775, 48.077499200000005]],
    [ "Enedis",[-0.8239941225851038, 48.077102428472024]],
    [ "Enedis",[-1.554699908228796, 47.22469632058301]],
    [ "Enedis",[-1.5368839999999997, 47.18306065]],
    [ "Enedis",[-1.5467262020677734, 47.1894728330421]],
    [ "Poste électrique de Creys-Malville",[5.46646024567849, 45.75615546019532]],
    [ "Enedis",[-1.1480719098353618, 46.45864052459079]],
    [ "Enedis",[3.054246580640924, 45.79934916979465]],
    [ "Enedis",[3.054515408494623, 45.79153783573934]],
    [ "Enedis",[3.0576838555214603, 45.795254060375406]],
    [ "Enedis",[3.056286413809752, 45.79385460310503]],
    [ "Enedis",[5.823580420631401, 46.10698287340302]],
    [ "Poste électrique de Grande-Île",[6.028007791515305, 45.47076339695172]],
    [ "Enedis",[2.0692936939173268, 47.21902347235274]],
    [ "Enedis",[2.065255425647699, 47.218109074152274]],
    [ "Enedis",[2.06650385544273, 47.220186927663775]],
    [ "réséda",[6.619984349999999, 49.04915870000001]],
    [ "réséda",[6.5969200714071565, 49.05435460153934]],
    [ "réséda",[6.5939248593058055, 49.05861513895871]],
    [ "réséda",[6.6083373610752885, 49.0450515673284]],
    [ "réséda",[6.596029628217822, 49.040901336399635]],
    [ "réséda",[6.596671294814586, 49.045519835467815]],
    [ "réséda",[6.59836942552749, 49.05117835029211]],
    [ "réséda",[6.60689731042025, 49.051980686058904]],
    [ "réséda",[6.592971724801712, 49.047796111223214]],
    [ "réséda",[6.608350049999999, 49.05428799999999]],
    [ "réséda",[6.600031273634937, 49.047968304800776]],
    [ "réséda",[6.5954085116835115, 49.06569272985085]],
    [ "réséda",[6.615246999999999, 49.047792699999995]],
    [ "réséda",[6.59740915, 49.047889500000004]],
    [ "réséda",[6.5900781301734455, 49.06846649801953]],
    [ "réséda",[6.603154159507476, 49.05337391839608]],
    [ "réséda",[6.600084059292098, 49.04018128594918]],
    [ "réséda",[6.610617422114497, 49.05241988726016]],
    [ "réséda",[6.592652115760666, 49.03772080059144]],
    [ "réséda",[6.591325340947822, 49.059958426026355]],
    [ "réséda",[6.607581168762375, 49.046883928921865]],
    [ "réséda",[6.603478246142619, 49.04413524447457]],
    [ "réséda",[6.595477150000001, 49.07991560000001]],
    [ "Enedis",[0.5575536521585274, 46.82756368748699]],
    [ "Enedis",[0.5456889929163848, 46.843362908252054]],
    [ "SRD",[0.5333364686891153, 46.80105684524566]],
    [ "Enedis",[0.5572420603798801, 46.824391457248524]],
    [ "Enedis",[0.5245188424064855, 46.81723490842017]],
    [ "Enedis",[0.5739721026098534, 46.83064765351391]],
    [ "SRD",[0.528718088961821, 46.80693831443116]],
    [ "Enedis",[0.5503289312653714, 46.83081339725858]],
    [ "Enedis",[0.550257502464855, 46.82025969023971]],
    [ "Enedis",[0.5491095999999995, 46.840308449999995]],
    [ "Enedis",[0.5315913048003916, 46.82859033539064]],
    [ "SRD",[0.5313170097655986, 46.79478057038581]],
    [ "Enedis",[0.534286962883173, 46.825745560564904]],
    [ "Enedis",[0.5294772925998469, 46.812561202183446]],
    [ "Enedis",[0.5474113003087013, 46.84949441826703]],
    [ "Enedis",[0.5500777587683092, 46.81645010686575]],
    [ "Enedis",[0.5504893824651428, 46.81109661333309]],
    [ "Enedis",[0.5116403079895779, 46.821998296086214]],
    [ "Enedis",[0.5497320557860349, 46.81772622380885]],
    [ "Enedis",[0.5596007254230523, 46.81055288151916]],
    [ "Enedis",[0.5631830655712441, 46.81488829354553]],
    [ "Enedis",[0.5449131875834705, 46.81095624474016]],
    [ "Enedis",[0.5272568620654544, 46.81818718895241]],
    [ "Enedis",[0.5758027999999985, 46.819538350000016]],
    [ "Enedis",[0.5282177004855492, 46.8264967056185]],
    [ "Enedis",[0.5432900341365464, 46.80653578373494]],
    [ "SRD",[0.5191884053295, 46.81107788790265]],
    [ "Enedis",[0.5588897412308786, 46.81573103639274]],
    [ "Enedis",[0.5564295760175192, 46.818379640785096]],
    [ "Enedis",[0.5290794321622142, 46.82109915392954]],
    [ "Enedis",[0.5503724148119287, 46.82420059380641]],
    [ "Enedis",[0.5534540329498326, 46.81861517452349]],
    [ "Enedis",[0.5429546722471035, 46.83837668413652]],
    [ "SRD",[0.5198025548902907, 46.8059196064088]],
    [ "Enedis",[0.539086376953102, 46.80268383766707]],
    [ "Enedis",[0.5545401163127661, 46.82101870050727]],
    [ "Enedis",[0.540209434875394, 46.84426253299629]],
    [ "Enedis",[0.5448924902698764, 46.82576780726562]],
    [ "SRD",[0.5319324774619528, 46.79814216211517]],
    [ "Enedis",[0.5673202243099338, 46.83055476899068]],
    [ "Enedis",[0.5444458465770458, 46.81710184700421]],
    [ "Enedis",[0.5433403444962221, 46.84089125333216]],
    [ "Enedis",[0.5292330673799424, 46.80688221816973]],
    [ "Enedis",[0.544764132367847, 46.83040654698311]],
    [ "SRD",[0.5255901816542461, 46.79607741289381]],
    [ "Enedis",[0.5393917121506766, 46.804041238853294]],
    [ "Enedis",[0.5423080148624873, 46.84610397364683]],
    [ "Enedis",[0.5541782676344844, 46.80459237489796]],
    [ "Enedis",[0.5438469348275723, 46.80987193446177]],
    [ "Enedis",[0.5525862151227166, 46.83070174147351]],
    [ "Enedis",[0.5399696712341966, 46.80805134494431]],
    [ "Enedis",[0.5334313, 46.81743684999999]],
    [ "Enedis",[0.5395206467363436, 46.81514794796518]],
    [ "Enedis",[0.5281557114056152, 46.81256130171965]],
    [ "Enedis",[0.5522300650843781, 46.82653478233004]],
    [ "SRD",[0.5244427324300794, 46.79711122924501]],
    [ "Enedis",[0.5510484419276906, 46.80839297761969]],
    [ "SRD",[0.5286700645708311, 46.7938297772544]],
    [ "SRD",[0.52447975, 46.79414715000001]],
    [ "SRD",[0.523990899999292, 46.79888039588611]],
    [ "Enedis",[0.5373511773312758, 46.81981120692308]],
    [ "Enedis",[0.5524107803128845, 46.84827596427023]],
    [ "Enedis",[0.5461129500000009, 46.824870499999996]],
    [ "Enedis",[0.5474874913503278, 46.81622935889773]],
    [ "519026",[7.827253560305166, 48.65746168712261]],
    [ "84297973",[2.4465741255472278, 48.940972085191945]],
    [ "SRD",[0.3421842999999995, 46.63408915]],
    [ "Enedis",[6.963130208943902, 49.19384670972018]],
    [ "Enedis",[6.949209798498704, 49.19325459429737]],
    [ "SRD",[0.30225263230591787, 46.63110013411588]],
    [ "Enedis",[6.967624666396651, 49.190064756320595]],
    [ "SRD",[0.30389269700401994, 46.62258272759289]],
    [ "Enedis",[6.97142130101468, 49.18826514425326]],
    [ "SRD",[0.31603961227640603, 46.62193208599605]],
    [ "SRD",[0.30890165440796746, 46.614839082754145]],
    [ "Enedis",[6.9489472164928845, 49.202534347690445]],
    [ "Enedis",[6.955345552393911, 49.19354922847678]],
    [ "SRD",[0.30605802985741787, 46.62393519005506]],
    [ "SRD",[0.30135099827908124, 46.624392824668185]],
    [ "Enedis",[6.969852347370714, 49.19150274919603]],
    [ "SRD",[0.27714158166516867, 46.6272946851295]],
    [ "SRD",[0.31972910025217405, 46.61995405536864]],
    [ "SRD",[0.3314893387131548, 46.62945563238118]],
    [ "SRD",[0.31647345000000005, 46.618288050000004]],
    [ "SRD",[0.31989925010049247, 46.63378194508594]],
    [ "SRD",[0.33281571902178486, 46.634776108298986]],
    [ "SRD",[0.32454285300661384, 46.63302798754123]],
    [ "Poste électrique Cardinet",[2.317733469659035, 48.88352388148067]],
    [ "SRD",[0.30426566388365767, 46.626113104268676]],
    [ "SRD",[0.3355269999999997, 46.628622199999995]],
    [ "SRD",[0.3201031167431674, 46.63182689608997]],
    [ "SRD",[0.3168694640484649, 46.627870520635476]],
    [ "Poste source des Ternes",[2.2932308995396244, 48.87651801530919]],
    [ "SRD",[0.31152572683680324, 46.624238015687126]],
    [ "SRD",[0.3134696046911969, 46.62288240529307]],
    [ "SRD",[0.31555300838402717, 46.634378588892076]],
    [ "Enedis",[6.959756364548396, 49.182830439325805]],
    [ "Enedis",[-1.17141938790395, 46.46003655089977]],
    [ "Enedis",[6.959338601460098, 49.1803818256972]],
    [ "Enedis",[3.0869885213101402, 50.58725835016565]],
    [ "Enedis",[3.0676061058578794, 50.59531082808776]],
    [ "Enedis",[3.066445245196298, 50.60632995151275]],
    [ "Enedis",[3.0767881330819753, 50.60233388852814]],
    [ "Enedis",[3.086569796665412, 50.58821752332582]],
    [ "Enedis",[3.0761476500000002, 50.59466655000001]],
    [ "Enedis",[3.0643300292375883, 50.60201987163121]],
    [ "Enedis",[3.0551564623863614, 50.60174785449994]],
    [ "Enedis",[3.0786343000000014, 50.593085249999994]],
    [ "Enedis",[3.0721323200556183, 50.598398207181106]],
    [ "Enedis",[3.087935334637546, 50.58455285156408]],
    [ "Enedis",[3.0637653909556533, 50.60460420923445]],
    [ "Enedis",[3.063445805494779, 50.60601523556557]],
    [ "Enedis",[3.0728191679930457, 50.594205773484106]],
    [ "Enedis",[3.0791258496216574, 50.59640678070349]],
    [ "Enedis",[3.073392810566188, 50.595805968301434]],
    [ "Enedis",[-1.1744298499999997, 46.461074700000005]],
    [ "Enedis",[3.0734944655994454, 50.60483636589094]],
    [ "Enedis",[3.071922768288752, 50.59178978582491]],
    [ "Enedis",[-1.17753945, 46.460932400000004]],
    [ "Enedis",[3.0630710364075986, 50.60368709753959]],
    [ "Enedis",[3.072954845283554, 50.58838451434814]],
    [ "Enedis",[-1.179929820922023, 46.46196235515385]],
    [ "Enedis",[-1.1799907602690936, 46.46022097986519]],
    [ "Enedis",[3.0742353500000004, 50.59775655000001]],
    [ "Enedis",[3.076309108265666, 50.5885330543693]],
    [ "Enedis",[-1.1779871707373317, 46.45862105516371]],
    [ "Enedis",[3.0720729197066627, 50.60401603278352]],
    [ "Enedis",[3.0609229062481473, 50.60829249963165]],
    [ "Enedis",[3.0769966746583153, 50.595885857819916]],
    [ "Enedis",[-1.1657834428991947, 46.45804326783749]],
    [ "Enedis",[3.070165084600099, 50.60736673566107]],
    [ "Enedis",[3.060086493422595, 50.60634886568705]],
    [ "Enedis",[3.0727589490224454, 50.594245067561964]],
    [ "Enedis",[2.073482544541437, 47.22130439434226]],
    [ "Enedis",[0.27400613053046624, 46.686759335263524]],
    [ "Enedis",[2.079534050882236, 47.21994289732295]],
    [ "Enedis",[2.0868756347164164, 47.219911795510136]],
    [ "Enedis",[0.26434310093270325, 46.68346604335092]],
    [ "Enedis",[-1.15609185, 46.4603212]],
    [ "Enedis",[3.367659472807901, 49.662426254150375]],
    [ "Enedis",[1.4002980946916872, 43.54166742009236]],
    [ "Enedis",[1.4027548660054683, 43.52419570230397]],
    [ "Enedis",[3.378222883031972, 49.661362473526395]],
    [ "Enedis",[1.4058035178892492, 43.52261957039002]],
    [ "Enedis",[0.24786872725177747, 46.67793574934687]],
    [ "Enedis",[0.24165662596656676, 46.69001994582679]],
    [ "Enedis",[7.243638590842833, 47.956794132719395]],
    [ "Enedis",[0.2435229419743733, 46.68456975189918]],
    [ "Enedis",[-1.16217885, 46.458150599999996]],
    [ "Enedis",[1.4029688723571156, 43.538263988755716]],
    [ "Enedis",[0.2309271278307301, 46.678510771910084]],
    [ "Enedis",[1.4105473027238453, 43.53671838277805]],
    [ "Enedis",[1.3899674750687239, 43.51417757483768]],
    [ "Enedis",[7.24302873218782, 47.9591924392517]],
    [ "Enedis",[1.4161710133678391, 43.53255242098395]],
    [ "Enedis",[3.3650870085863462, 49.66088695382926]],
    [ "Enedis",[0.25670495205254107, 46.68640830094351]],
    [ "Enedis",[7.238828049507632, 47.96188534639312]],
    [ "Enedis",[3.362527203853153, 49.66182249553762]],
    [ "Enedis",[1.406924490687164, 43.53806409020713]],
    [ "Enedis",[6.879349475474824, 49.169979568180416]],
    [ "Enedis",[1.3900995651572992, 43.51546375785427]],
    [ "Enedis",[1.3929032117933733, 43.516699318121425]],
    [ "Enedis",[0.24858603259655204, 46.68965598489809]],
    [ "Enedis",[3.3724219499999992, 49.65978725000001]],
    [ "Enedis",[1.3774982374453224, 43.52462328483701]],
    [ "Enedis",[3.3621433289984965, 49.66441974624517]],
    [ "Enedis",[1.4052115151891642, 43.54241083228901]],
    [ "Enedis",[7.245483226596521, 47.95562959971232]],
    [ "Enedis",[7.235728254554923, 47.96313988549639]],
    [ "Enedis",[1.4255854261900212, 43.53737934536386]],
    [ "Enedis",[0.25115284695799683, 46.681519674827435]],
    [ "Enedis",[1.3953736249593633, 43.53762480647666]],
    [ "Enedis",[7.238892844401907, 47.95962392434161]],
    [ "Enedis",[1.3881999279331796, 43.52892773857998]],
    [ "Enedis",[1.4111712637336833, 43.54358741357883]],
    [ "Enedis",[1.3948630620562175, 43.51727223865845]],
    [ "Enedis",[0.2564634625849863, 46.6844397031429]],
    [ "Enedis",[1.3814310960318035, 43.534471834649196]],
    [ "Enedis",[6.8768544494675306, 49.17465288930432]],
    [ "Enedis",[6.868926019315881, 49.16870776461669]],
    [ "Enedis",[1.406373082784271, 43.54087943625057]],
    [ "Enedis",[1.3943068205691387, 43.51836131099735]],
    [ "Enedis",[6.87046225030633, 49.16162072033895]],
    [ "Enedis",[6.873521377748148, 49.17367581054809]],
    [ "Enedis",[1.403700290231222, 43.52605302395718]],
    [ "Enedis",[1.4053941956078433, 43.53628516109805]],
    [ "Enedis",[1.4146673635595741, 43.52643426751364]],
    [ "Enedis",[1.3928996200345707, 43.538220650646814]],
    [ "Enedis",[1.3978210999999992, 43.535538550000005]],
    [ "Enedis",[1.4123232317956356, 43.53174301114711]],
    [ "Enedis",[1.3907498703017613, 43.52626277279048]],
    [ "Enedis",[1.3985267166974584, 43.52088551610625]],
    [ "Enedis",[1.3771940401682485, 43.53061481098026]],
    [ "Enedis",[0.2637435563751777, 46.682106266654245]],
    [ "Enedis",[1.4122018499999986, 43.54017360000001]],
    [ "Enedis",[6.867421445663456, 49.166450304906476]],
    [ "Enedis",[1.4030318548976342, 43.519567213718275]],
    [ "Enedis",[1.4125018585245828, 43.52650176956749]],
    [ "Enedis",[1.4257200536372276, 43.53795386976548]],
    [ "Enedis",[1.4144314950126615, 43.537367061647466]],
    [ "Enedis",[6.872755294507158, 49.16560933304348]],
    [ "Enedis",[1.4032849712419246, 43.5215185975019]],
    [ "Enedis",[1.404921045705061, 43.53166698045252]],
    [ "Enedis",[1.3830738668581728, 43.51713117446203]],
    [ "Enedis",[-1.1599523499999997, 46.45206399999999]],
    [ "Enedis",[1.4126437673249261, 43.54207153077805]],
    [ "Enedis",[0.24573957075749944, 46.691076013718195]],
    [ "Enedis",[1.387896691172066, 43.53563341980227]],
    [ "Enedis",[1.4101890057927784, 43.524225745897496]],
    [ "Enedis",[1.3865937541629492, 43.52692427659186]],
    [ "Enedis",[0.2517157207388748, 46.68515703895886]],
    [ "Enedis",[1.3982084864397653, 43.532408565903395]],
    [ "Enedis",[1.3883591502284973, 43.53266889281706]],
    [ "Enedis",[1.4090044148946965, 43.52159220490463]],
    [ "Enedis",[1.3876916249053703, 43.514485355408226]],
    [ "Enedis",[-1.1557651938810316, 46.45022301844139]],
    [ "Enedis",[1.4022114404039643, 43.53085617605898]],
    [ "Enedis",[1.3894600512595738, 43.518542538503034]],
    [ "Enedis",[0.24283647370278846, 46.678574085998356]],
    [ "Enedis",[0.2605312272236623, 46.68295976196952]],
    [ "Enedis",[3.0855055940739673, 45.036571645555476]],
    [ "Enedis",[1.403562055880516, 43.53689447326181]],
    [ "Enedis",[0.23756008271025353, 46.68723572362868]],
    [ "Enedis",[1.40301375, 43.53009555]],
    [ "Enedis",[1.4131361322043305, 43.54549811771385]],
    [ "Enedis",[1.4065353091956607, 43.53519561066325]],
    [ "Enedis",[1.413564461211305, 43.52989147309511]],
    [ "Enedis",[0.24334643219335525, 46.688678889232044]],
    [ "Enedis",[0.2516733410761056, 46.68560277449052]],
    [ "Enedis",[0.2455556165669189, 46.68759831982275]],
    [ "Enedis",[1.410187442198654, 43.539226833551936]],
    [ "Enedis",[1.3941779143794264, 43.5237803169548]],
    [ "Enedis",[1.3824850574628698, 43.53156067946605]],
    [ "Enedis",[1.4082305500704109, 43.53673295013934]],
    [ "Enedis",[1.3983778544520156, 43.51680848469586]],
    [ "Enedis",[1.3998093077929097, 43.52716030789445]],
    [ "Enedis",[1.4012008206378044, 43.524678319153196]],
    [ "Enedis",[1.378976646125203, 43.52847709826236]],
    [ "Enedis",[1.3990060279450045, 43.53690973691378]],
    [ "Enedis",[1.3863312164615007, 43.51961638334784]],
    [ "Enedis",[1.3836372188856394, 43.529300843830484]],
    [ "Enedis",[1.4104757565257924, 43.53836683579863]],
    [ "Enedis",[1.3993244454954914, 43.53849846162342]],
    [ "Enedis",[1.3965525404, 43.51964141919999]],
    [ "Enedis",[1.415027866296128, 43.54334880191142]],
    [ "Enedis",[1.395087986332744, 43.535459750660735]],
    [ "Enedis",[1.4060642124151577, 43.52722392018935]],
    [ "Enedis",[1.4085744907005677, 43.5275841514363]],
    [ "Enedis",[1.4089776725162428, 43.530810497051625]],
    [ "Enedis",[1.399026996438669, 43.51905383664824]],
    [ "Enedis",[1.4083258069231168, 43.541800255846]],
    [ "Enedis",[1.4085682105590742, 43.524240757168045]],
    [ "Enedis",[3.0521126008754313, 50.62066477408039]],
    [ "Enedis",[3.0463133204624757, 50.624517654688944]],
    [ "Enedis",[3.0559518761834, 50.62383068016094]],
    [ "Enedis",[3.054322474529342, 50.61866849486592]],
    [ "Enedis",[3.0458321462615343, 50.62316051728687]],
    [ "Enedis",[3.0526641109260155, 50.62187390669381]],
    [ "Enedis",[-3.8443600307090273, 48.69210333123522]],
    [ "SRD",[0.5646348100186526, 46.87118496851729]],
    [ "SRD",[0.566394148383754, 46.87611291212288]],
    [ "Enedis",[2.0605046204673485, 47.22290178013568]],
    [ "Enedis",[2.0543896999999998, 47.227065350000004]],
    [ "Enedis",[3.0535457940198296, 50.647028944506225]],
    [ "Enedis",[3.054516391091596, 50.64257874944543]],
    [ "Poste électrique de Charpenay",[4.689928624738643, 45.818783050244065]],
    [ "Poste électrique de Échalas",[4.71931487472394, 45.54284253568632]],
    [ "Régie Municipale Martres-Tolosane",[1.0097491000000003, 43.20085170000001]],
    [ "Poste électrique Pivoz-Courdier",[4.761172826950037, 45.409338090118844]],
    [ "Poste électrique de Coulange",[4.723430069713878, 44.63241097630191]],
    [ "Enedis",[4.14319480044675, 43.756056271778206]],
    [ "Enedis",[-0.7255842899783417, 47.209173053631524]],
    [ "Enedis",[-0.7527376770860131, 47.21985099199611]],
    [ "519014",[7.829032463463133, 48.66005042457359]],
    [ "Enedis",[-3.8204600441791174, 48.56771652840956]],
    [ "Enedis",[-3.821832615242108, 48.5714337910915]],
    [ "Enedis",[-3.8845461867788416, 48.55288066850212]],
    [ "Enedis",[-3.883524475132598, 48.557920024920286]],
    [ "Enedis",[-3.877834836681547, 48.559927825198415]],
    [ "Enedis",[-3.87277865636213, 48.55702995985569]],
    [ "Enedis",[3.1064943548868804, 45.82513139869216]],
    [ "Enedis",[3.09466025, 45.83328]],
    [ "Enedis",[3.1023947558834997, 45.82252836487591]],
    [ "Porte de Dijon",[3.1197514499999994, 45.838105899999995]],
    [ "Enedis",[3.1024338563449305, 45.827284357667814]],
    [ "Enedis",[3.0998176375168414, 45.81761188917184]],
    [ "Enedis",[3.101683016864877, 45.82830556067123]],
    [ "Enedis",[3.10608744921168, 45.8236093696766]],
    [ "84552351",[3.1220808232544224, 45.83828047856073]],
    [ "Enedis",[3.1065886000000003, 45.831744650000005]],
    [ "Enedis",[3.096285528799856, 45.83353245804887]],
    [ "Enedis",[3.1131388961146738, 45.81744186425249]],
    [ "Enedis",[3.11701413149924, 45.83620910160352]],
    [ "Enedis",[3.0960956532920862, 45.828738142931535]],
    [ "Enedis",[3.1091844359484417, 45.83388310693277]],
    [ "Enedis",[3.101200065267633, 45.833803878628544]],
    [ "Enedis",[3.1206668439043637, 45.84138852144002]],
    [ "84554845",[3.124038944568243, 45.840825311981476]],
    [ "Enedis",[3.1047849260392337, 45.820624856721835]],
    [ "Enedis",[-1.3353537, 46.336700349999994]],
    [ "Enedis",[3.1025972620875653, 45.82435802762084]],
    [ "Enedis",[-1.3297819592898406, 46.336332548043714]],
    [ "Enedis",[3.0981789534729587, 45.8230971662229]],
    [ "Enedis",[3.104346735886941, 45.818332839459934]],
    [ "Enedis",[3.099918800000001, 45.820557150000006]],
    [ "Enedis",[3.125378558224209, 45.83722255286423]],
    [ "Enedis",[3.101613200418559, 45.83795588185502]],
    [ "Enedis",[3.0966171849619517, 45.83728462103554]],
    [ "Enedis",[3.107773884971795, 45.8199085729888]],
    [ "Enedis",[3.0923385373472927, 45.84273775058131]],
    [ "Enedis",[3.098342406341706, 45.82520987061469]],
    [ "Enedis",[3.118782199999999, 45.838154249999995]],
    [ "Enedis",[3.1012167229990286, 45.83051463124674]],
    [ "Enedis",[3.1094276499999998, 45.8314132]],
    [ "Enedis",[3.1150261733772537, 45.842375703295104]],
    [ "Enedis",[3.1223878945351804, 45.83713111162531]],
    [ "Enedis",[3.0938955741677328, 45.83794481238339]],
    [ "Enedis",[3.1019901240250958, 45.81920714163389]],
    [ "Enedis",[3.1235733866122546, 45.84054427526572]],
    [ "Enedis",[3.0865981871813104, 45.833835129628845]],
    [ "Enedis",[3.098761748096997, 45.832278988623614]],
    [ "Enedis",[3.1067710192504325, 45.82944815565243]],
    [ "Enedis",[3.108736953915988, 45.816934375720265]],
    [ "Enedis",[3.1055674010753465, 45.82826276807678]],
    [ "Enedis",[3.0899515, 45.83272495]],
    [ "Enedis",[-1.3335551217148602, 46.33826084583078]],
    [ "Enedis",[-1.3375256499999997, 46.3393971]],
    [ "Enedis",[-1.34618719894639, 46.34498446280042]],
    [ "Enedis",[4.40211215, 45.335945849999995]],
    [ "Enedis",[4.417499243263234, 45.34190604605612]],
    [ "Enedis",[4.417798946525225, 45.34044068409571]],
    [ "Enedis",[-1.06848145, 46.52283495]],
    [ "Enedis",[-1.1202114198490445, 46.52447384711366]],
    [ "Enedis",[-1.0858413926308923, 46.52302337658971]],
    [ "Enedis",[2.195319678630106, 43.953709525574084]],
    [ "Enedis",[2.179054708789126, 43.95941615869719]],
    [ "Chambaud",[4.976324105087014, 45.00140729048102]],
    [ "Poste électrique de Beaumont Monteux",[4.927969761989227, 45.01921084476493]],
    [ "Enedis",[4.865677339251956, 45.77111552426203]],
    [ "SRD",[0.17706918907631858, 46.28537900032719]],
    [ "SRD",[0.18601639315037077, 46.303186607693085]],
    [ "SRD",[0.1814461430158145, 46.295359387658074]],
    [ "SRD",[0.1834848417934377, 46.29941767291195]],
    [ "519047",[7.8339417000000005, 48.65596299999999]],
    [ "Enedis",[2.181905474513453, 43.95390683298999]],
    [ "Enedis",[4.835923011658212, 45.77059273854243]],
    [ "Enedis",[-3.5860010500000006, 48.5140423]],
    [ "Enedis",[-3.579238345631845, 48.517688117003786]],
    [ "Enedis",[-3.585587561091407, 48.509536817132414]],
    [ "Enedis",[-3.5984614667546246, 48.512677652587435]],
    [ "Enedis",[-3.600859115623599, 48.53122560004077]],
    [ "Enedis",[-3.5861301717177168, 48.51241025300708]],
    [ "Enedis",[-3.575025783398868, 48.52312025723693]],
    [ "Enedis",[-3.593467497635258, 48.51845095887005]],
    [ "Enedis",[-3.590595963636525, 48.51632858041252]],
    [ "Enedis",[-3.5854073999999994, 48.51697074999999]],
    [ "Enedis",[1.1744508694884046, 49.011992537646286]],
    [ "Enedis",[1.1790955554655382, 49.002542730575335]],
    [ "Enedis",[2.3091894634090373, 48.68638704763488]],
    [ "Enedis",[2.313248609569547, 48.680906114295254]],
    [ "Enedis",[2.309402668762678, 48.68250812772006]],
    [ "Enedis",[2.307415831999705, 48.68028242008743]],
    [ "519006",[7.840012624985803, 48.65787730959681]],
    [ "519030",[7.83560965, 48.65730870000001]],
    [ "519027",[7.83516285, 48.658409400000004]],
    [ "Enedis",[4.824738800038408, 45.768820874335354]],
    [ "Enedis",[3.3928683694951856, 49.665547776554064]],
    [ "Enedis",[3.3901262509571444, 49.6659264989564]],
    [ "Enedis",[-3.7092673099680904, 48.566207295027205]],
    [ "Enedis",[-3.78985135, 48.579182900000006]],
    [ "Enedis",[-3.696368514964382, 48.564620988242154]],
    [ "Enedis",[-3.702352525351238, 48.56798104949028]],
    [ "Enedis",[-3.7106116278692394, 48.574423904823774]],
    [ "Enedis",[-3.6981090831573074, 48.56631071939205]],
    [ "Enedis",[2.31490115, 48.75756694999999]],
    [ "SRD",[0.4735970473465542, 46.763666222456855]],
    [ "Enedis",[1.210438737988178, 47.68193066793456]],
    [ "SRD",[0.505173900086178, 46.77133925121846]],
    [ "SRD",[0.48734082670364176, 46.76083476131787]],
    [ "SRD",[0.478951340299792, 46.762806290843166]],
    [ "SRD",[0.48248005244055703, 46.7510505669764]],
    [ "SRD",[0.48364536997809915, 46.75936260432106]],
    [ "SRD",[0.48217078277079684, 46.76417615017132]],
    [ "Poste électrique de Bonneterre",[4.882281849999999, 45.76391525]],
    [ "Enedis",[4.87971705, 45.755277199999995]],
    [ "519019",[7.8426635000000005, 48.658263149999996]],
    [ "Enedis",[3.105712597683772, 47.83993835899707]],
    [ "Enedis",[-4.0808613, 48.4048383]],
    [ "SRD",[0.2983870952241338, 46.56945217528997]],
    [ "SRD",[0.30264350928686473, 46.60064537915766]],
    [ "SRD",[0.26893043348028, 46.610165139663884]],
    [ "SRD",[0.27791650078075725, 46.60334594659262]],
    [ "SRD",[0.267124694917452, 46.57040061902127]],
    [ "SRD",[0.27057562790842116, 46.60603335854234]],
    [ "SRD",[0.2700971869401231, 46.57440694403583]],
    [ "SRD",[0.2734836658758369, 46.577133191470026]],
    [ "SRD",[0.2981093517040828, 46.56860952083206]],
    [ "Domaine de Fleury",[0.27329366107305647, 46.56982332378367]],
    [ "SRD",[0.2763771247682344, 46.597740194910685]],
    [ "SRD",[0.6113343133090687, 46.474895687398806]],
    [ "SRD",[0.6403763244960067, 46.49208250575915]],
    [ "SRD",[0.65056155, 46.48984464999999]],
    [ "SRD",[0.6409718454629809, 46.50201224103836]],
    [ "SRD",[0.6358284979703227, 46.49091093961259]],
    [ "SRD",[0.6351495309024331, 46.51147232388436]],
    [ "SRD",[0.646885408501434, 46.481938189907424]],
    [ "SRD",[0.6158587273191879, 46.47810977207969]],
    [ "SRD",[0.6399946185767118, 46.50767493406356]],
    [ "Enedis",[2.0704959067736803, 47.22282392970615]],
    [ "Enedis",[2.0669617384631955, 47.22413335349361]],
    [ "SRD",[0.2638155045713684, 46.429683248911914]],
    [ "SRD",[0.25869130850851857, 46.427107869908]],
    [ "SRD",[0.2677454999999999, 46.4335454]],
    [ "SRD",[0.24808211766143118, 46.41955853345966]],
    [ "SRD",[0.26738245382147374, 46.43637736889003]],
    [ "SRD",[0.2684548016616005, 46.438709453903726]],
    [ "SRD",[0.2614797316769712, 46.42652364158461]],
    [ "SRD",[0.249770296472183, 46.4234642226778]],
    [ "SRD",[0.2539468999999999, 46.42839010000001]],
    [ "SRD",[0.2717444108515205, 46.43423765558824]],
    [ "Enedis",[6.152816315434917, 48.690267917473975]],
    [ "Enedis",[6.150031614147562, 48.68950881116003]],
    [ "Enedis",[0.30892038627363, 46.71279615242893]],
    [ "Enedis",[0.28608480000000036, 46.69684184999999]],
    [ "Enedis",[3.084490278233535, 50.64428801736915]],
    [ "Enedis",[3.0831948191636243, 50.64026824325312]],
    [ "Enedis",[0.3047847016913153, 46.73917294125483]],
    [ "Enedis",[3.084751453321946, 50.64222289033951]],
    [ "SRD",[0.30120120172351866, 46.75379415838421]],
    [ "Enedis",[0.30793510480148467, 46.73442255358505]],
    [ "Enedis",[0.3261199880613475, 46.73617566863555]],
    [ "Enedis",[0.32454675061918176, 46.72682343716347]],
    [ "Enedis",[0.3111648926370002, 46.73550347829429]],
    [ "Enedis",[0.3052189112284931, 46.73381366461258]],
    [ "Enedis",[3.090375131394824, 50.63640551125771]],
    [ "Enedis",[3.092346960877197, 50.63783449972863]],
    [ "Enedis",[3.082622215930726, 50.636311327572464]],
    [ "Enedis",[3.093910521901632, 50.63599824630642]],
    [ "Enedis",[3.087671510265071, 50.64406145576742]],
    [ "Enedis",[3.087098249999998, 50.648532749999994]],
    [ "Enedis",[3.092645141585556, 50.646579727816814]],
    [ "Enedis",[3.0894902479000295, 50.65201487820418]],
    [ "Enedis",[3.0855783929350573, 50.6443815305123]],
    [ "Enedis",[-2.0009172835632, 48.214937963102436]],
    [ "Enedis",[2.69054763642715, 45.53349586856596]],
    [ "Enedis",[2.69405175389053, 45.535188630683486]],
    [ "Enedis",[2.6887866598918686, 45.53428773829859]],
    [ "519028",[7.8364299369104, 48.66054809627768]],
    [ "519022",[7.83090635, 48.663592300000005]],
    [ "Enedis",[6.682865054339553, 45.90479659677441]],
    [ "Eurotim",[-1.1192618538255001, 46.17722716327497]],
    [ "Enedis",[-3.8289061288961213, 48.20684075494381]],
    [ "Enedis",[-3.8494612499999996, 48.1923403]],
    [ "Paul Sérusier",[-3.807446880160417, 48.18833622692749]],
    [ "Enedis",[2.719514766004742, 45.469292259636084]],
    [ "Achille Lignon",[4.860851417321987, 45.78502664296921]],
    [ "Enedis",[3.005821824368348, 50.76034196847289]],
    [ "Enedis",[2.9938333152625187, 50.75298226790172]],
    [ "Enedis",[3.0074992023271556, 50.761782803114805]],
    [ "Enedis",[3.0122082207874596, 50.7640218945505]],
    [ "Enedis",[2.998378757304548, 50.757942423021035]],
    [ "Enedis",[3.026715019780416, 50.73883166133261]],
    [ "Enedis",[3.00328159068599, 50.760566409240525]],
    [ "Enedis",[3.014869014345089, 50.7367910221674]],
    [ "Enedis",[3.0036305382903725, 50.7573219187663]],
    [ "Enedis",[3.0102154105449315, 50.75947755014917]],
    [ "Enedis",[3.012875751224587, 50.76758963283966]],
    [ "Enedis",[2.9963398440323776, 50.740505833693554]],
    [ "Enedis",[3.013428002874638, 50.76360290691771]],
    [ "Enedis",[3.014745816478113, 50.73463881576915]],
    [ "Enedis",[3.017698850872478, 50.75941333605427]],
    [ "Enedis",[3.0198144167223995, 50.75158920354459]],
    [ "Enedis",[3.01160630904587, 50.73917835226502]],
    [ "Enedis",[3.015887239651998, 50.738836788519556]],
    [ "Enedis",[3.0156258499999997, 50.7408397]],
    [ "Enedis",[4.372242086166406, 45.40558034182118]],
    [ "Enedis",[4.3738331, 45.407458199999994]],
    [ "Enedis",[2.9967677312031156, 50.75831057520559]],
    [ "Enedis",[3.0157322930616957, 50.757805569945965]],
    [ "Enedis",[4.365604858628319, 45.398583487905604]],
    [ "Enedis",[3.001975448707386, 50.75860924014554]],
    [ "Enedis",[3.036809631444415, 50.76899539781496]],
    [ "Enedis",[3.001740524270622, 50.763792015584826]],
    [ "Enedis",[3.0140324713010127, 50.75991411014784]],
    [ "Enedis",[3.014061499356953, 50.762109285134]],
    [ "Enedis",[3.0062463676752347, 50.757952100973384]],
    [ "Enedis",[3.018042001183543, 50.742361518692]],
    [ "Enedis",[3.000195213627755, 50.76051639215305]],
    [ "Enedis",[3.019313007726078, 50.7620810361833]],
    [ "Enedis",[3.005300131524901, 50.76340719776617]],
    [ "Enedis",[3.02256981921907, 50.75781280700877]],
    [ "Enedis",[3.002919799999998, 50.7610479]],
    [ "Enedis",[3.0037355498239267, 50.754665501358645]],
    [ "Enedis",[3.0249475620364974, 50.76476124780824]],
    [ "Enedis",[3.0157176963271732, 50.749470700875946]],
    [ "Enedis",[3.010675632885122, 50.75703009503254]],
    [ "Enedis",[3.0098148087619427, 50.75491998339007]],
    [ "Enedis",[2.990356085939429, 50.75363809021515]],
    [ "Enedis",[3.0202843404753885, 50.74489482785911]],
    [ "Enedis",[3.006237131139156, 50.752734762946716]],
    [ "Enedis",[2.996225166106061, 50.75334057399999]],
    [ "Enedis",[3.012982550000001, 50.743481550000006]],
    [ "Enedis",[4.373238699992945, 45.411232129970074]],
    [ "Enedis",[4.367529974190259, 45.40449906784625]],
    [ "Enedis",[4.373556118056588, 45.406931108667166]],
    [ "Enedis",[4.364379792153351, 45.40153462387872]],
    [ "Enedis",[6.172405350000001, 48.669710450000004]],
    [ "Enedis",[6.175617092489291, 48.66968346037989]],
    [ "Enedis",[6.237307062929399, 46.19034386735264]],
    [ "SRD",[0.037381751671742576, 46.42010148911138]],
    [ "SRD",[0.04238556701510968, 46.412727155437246]],
    [ "Enedis",[4.880211699999999, 45.786050749999994]],
    [ "SRD",[0.041712468195623506, 46.42328466494749]],
    [ "Enedis",[3.356966249550815, 49.68707496963723]],
    [ "Poste électrique de Terrier",[2.2589200716359845, 49.21770263673624]],
    [ "Enedis",[2.466190336866189, 48.8153528659213]],
    [ "Enedis",[-1.2019543999999993, 46.39349369999999]],
    [ "Enedis",[-1.2206404553160382, 46.53533480950246]],
    [ "Enedis",[2.4790904993830747, 48.81507346423646]],
    [ "Enedis",[2.4719092544528514, 48.8190365668351]],
    [ "Enedis",[4.885747857398918, 45.78362217119712]],
    [ "Enedis",[0.37520265519702184, 46.65979206958067]],
    [ "Temps Modernes 28",[0.373185530071768, 46.661635965707376]],
    [ "Enedis",[0.35912751001787707, 46.6335456981554]],
    [ "Enedis",[0.3742571516373812, 46.65693518963916]],
    [ "Enedis",[0.3394900186760073, 46.66063822168336]],
    [ "Enedis",[0.35718444681761496, 46.63711913952882]],
    [ "Enedis",[0.36196977901114424, 46.66871659684025]],
    [ "Enedis",[0.3732281459717453, 46.66380554497324]],
    [ "Enedis",[0.37176247223938413, 46.65726806899998]],
    [ "Enedis",[0.35820649999999954, 46.665042500000006]],
    [ "Enedis",[0.3750454500000007, 46.667014300000005]],
    [ "Enedis",[0.37570258415932095, 46.65066932663402]],
    [ "XXI Siècle 26",[0.37506465, 46.658417]],
    [ "Clos du Planty I",[0.3421592889669006, 46.65865176166558]],
    [ "Enedis",[0.36285619999999974, 46.62367145]],
    [ "Enedis",[0.3635083185129913, 46.66796353892777]],
    [ "Enedis",[0.3637430623699553, 46.658077380340664]],
    [ "Bretelle 21 Siecle",[0.37618965000000043, 46.6677206]],
    [ "Beau Soleil",[0.37281765, 46.655221049999994]],
    [ "Enedis",[3.107299521037633, 50.62325185929567]],
    [ "Enedis",[3.118776808829532, 50.62414530635363]],
    [ "Enedis",[3.11486605208904, 50.6293028707257]],
    [ "Enedis",[3.1120166542355263, 50.625378811867364]],
    [ "Enedis",[3.0932251008827296, 50.623590254195705]],
    [ "Enedis",[3.1176200254887303, 50.631164185871995]],
    [ "Enedis",[3.107026044113255, 50.61954947942381]],
    [ "Enedis",[3.0868343807397967, 50.61848173933399]],
    [ "Enedis",[3.1129963809095864, 50.63156647110326]],
    [ "Enedis",[3.1159611928096873, 50.62753495654333]],
    [ "Enedis",[3.101615046888014, 50.63264966824119]],
    [ "Enedis",[3.1095786116997104, 50.623758982719124]],
    [ "Enedis",[3.1110819733821318, 50.629524765393455]],
    [ "Enedis",[3.1124634050808404, 50.63069499934853]],
    [ "Enedis",[3.1170984227162224, 50.63261821104598]],
    [ "Enedis",[3.0866554317293824, 50.619646236770734]],
    [ "Enedis",[3.1083556284330998, 50.63463621627245]],
    [ "Enedis",[3.105405080507694, 50.62694478596551]],
    [ "Enedis",[3.0971965200784006, 50.62997590868417]],
    [ "Enedis",[3.1071607272586528, 50.63156064937487]],
    [ "Enedis",[3.0993601701112437, 50.62993671495011]],
    [ "Enedis",[3.119049078099816, 50.63331360396004]],
    [ "Enedis",[3.1130453478128377, 50.62772841775287]],
    [ "Enedis",[3.104150887585328, 50.6199405339574]],
    [ "Enedis",[3.1152004848236854, 50.634994179959016]],
    [ "Enedis",[3.0903519888624267, 50.62512759954389]],
    [ "Enedis",[5.041192095168979, 43.849785644418354]],
    [ "Enedis",[5.043968033741943, 43.83818535364833]],
    [ "Enedis",[5.025892128014317, 43.849608887316464]],
    [ "Enedis",[5.04720625964708, 43.846343629855404]],
    [ "Enedis",[5.077210667390948, 43.818772654136346]],
    [ "Enedis",[5.037873810511378, 43.82706202874107]],
    [ "Enedis",[5.044864746374152, 43.82680441295801]],
    [ "Enedis",[5.035368649048105, 43.82814666672809]],
    [ "Enedis",[5.03272366721482, 43.82910639910521]],
    [ "Enedis",[5.05062915, 43.83253195]],
    [ "Enedis",[5.0306641999999995, 43.8374726]],
    [ "Enedis",[4.984574794250344, 43.87675999924955]],
    [ "Enedis",[5.052723972779839, 43.83075125571449]],
    [ "Enedis",[5.037881894693414, 43.84065668819648]],
    [ "Enedis",[5.0065799878206, 43.881138623601466]],
    [ "Enedis",[5.0507143999999995, 43.84014050000001]],
    [ "Enedis",[5.038455895006474, 43.83280805944686]],
    [ "Enedis",[5.049446082576006, 43.831255900680006]],
    [ "Enedis",[5.0213586013050495, 43.84875681643295]],
    [ "Enedis",[5.048236388851801, 43.831422739818834]],
    [ "Enedis",[5.050109815794519, 43.841701280922095]],
    [ "Enedis",[5.0557851788490105, 43.832492532243336]],
    [ "Enedis",[5.0371258999999995, 43.83148034999999]],
    [ "Enedis",[4.989741400000001, 43.863571150000006]],
    [ "Enedis",[5.033025185164786, 43.85182343763657]],
    [ "Enedis",[5.042067088241997, 43.83684822940261]],
    [ "Enedis",[5.0445606, 43.84171624999999]],
    [ "Enedis",[5.0499796958867735, 43.829676381170856]],
    [ "Enedis",[5.032471152989463, 43.83257660081054]],
    [ "Enedis",[5.0386137, 43.84327975000001]],
    [ "Enedis",[5.0494274, 43.835735650000004]],
    [ "Enedis",[5.042448502730802, 43.8357781440937]],
    [ "Enedis",[5.042818911823822, 43.839016971600536]],
    [ "Enedis",[5.03675805, 43.853620150000005]],
    [ "Enedis",[5.03528095756111, 43.85188640673347]],
    [ "Enedis",[5.045566613738305, 43.81348692013995]],
    [ "Enedis",[5.0362409, 43.837790250000005]],
    [ "Enedis",[5.03449465, 43.85056455]],
    [ "Enedis",[5.036198191977596, 43.833282320421624]],
    [ "Enedis",[5.04044495, 43.8508716]],
    [ "Enedis",[5.0399226, 43.831145049999996]],
    [ "Enedis",[5.043112100000001, 43.850294149999996]],
    [ "Enedis",[5.043037208674909, 43.815199528993205]],
    [ "Enedis",[5.047457607148139, 43.837394328658284]],
    [ "Enedis",[5.03703691375733, 43.83010789095549]],
    [ "Enedis",[5.0383102043993, 43.82550532675632]],
    [ "Enedis",[5.044058267196833, 43.84274706792536]],
    [ "Enedis",[5.0535022, 43.8377023]],
    [ "Enedis",[5.0409181836683175, 43.84017684918042]],
    [ "Enedis",[5.043707533135199, 43.848005048653214]],
    [ "Enedis",[5.0338853, 43.832858449999996]],
    [ "Enedis",[5.060541953083036, 43.8262619097929]],
    [ "Enedis",[5.043223540249055, 43.83193938647988]],
    [ "Enedis",[5.035909315392342, 43.83601972475007]],
    [ "Enedis",[5.050017125812585, 43.83412655673001]],
    [ "Enedis",[5.038687083656691, 43.852178550829485]],
    [ "Enedis",[5.05121005, 43.83697615]],
    [ "519033",[7.817206018808178, 48.67511635748377]],
    [ "519035",[7.814649829172279, 48.67091031954474]],
    [ "Enedis",[2.8510249083989363, 45.83075416873127]],
    [ "Enedis",[2.8502839280542993, 45.83199981749623]],
    [ "Enedis",[0.40233814545158647, 46.67341067674341]],
    [ "Enedis",[0.4059520935816166, 46.66644621389413]],
    [ "Enedis",[6.24894826732782, 46.18669063210535]],
    [ "Enedis",[5.43972135, 43.772068749999995]],
    [ "Enedis",[5.443684350000001, 43.7696258]],
    [ "SRD",[0.7255263958690409, 46.29442402562075]],
    [ "Enedis",[6.893583185007278, 49.19404533668123]],
    [ "Enedis",[6.891498343193933, 49.192632865438036]],
    [ "Enedis",[6.887699197844836, 49.17598854454301]],
    [ "Enedis",[6.915819718308828, 49.18342331986195]],
    [ "Enedis",[6.90508282843813, 49.18494190415984]],
    [ "Enedis",[6.872925037976776, 49.19646917685287]],
    [ "Enedis",[6.910165566498623, 49.191419979718354]],
    [ "Enedis",[6.90430004857438, 49.19546218519319]],
    [ "Enedis",[6.879632214726369, 49.1839586756751]],
    [ "Enedis",[6.885448454584596, 49.177357900514735]],
    [ "Enedis",[6.922927154451202, 49.18996510100511]],
    [ "Enedis",[6.898411948882777, 49.19203764083351]],
    [ "Enedis",[6.899103289136596, 49.1846212960152]],
    [ "Enedis",[6.874880244967911, 49.19854028362328]],
    [ "Enedis",[6.904771701902967, 49.19822767181074]],
    [ "Enedis",[6.882553989597385, 49.180206991052515]],
    [ "Enedis",[6.894273497102273, 49.18397527512985]],
    [ "Enedis",[6.870912014082331, 49.188945676277946]],
    [ "Enedis",[6.882583059381898, 49.17566343642385]],
    [ "Enedis",[6.899423449819752, 49.17842530206952]],
    [ "Enedis",[6.903477360598144, 49.1917945739737]],
    [ "Enedis",[6.890753183728192, 49.178280632653376]],
    [ "Enedis",[6.886165958047678, 49.17540047632388]],
    [ "Enedis",[6.897347892785804, 49.197548955992026]],
    [ "Enedis",[6.896639551562155, 49.18878295875993]],
    [ "Enedis",[6.909540900303326, 49.182284452620614]],
    [ "Enedis",[6.904068120011617, 49.18967942475582]],
    [ "Enedis",[6.920270874273723, 49.182937042769865]],
    [ "Enedis",[6.898191858325256, 49.18640190743396]],
    [ "Enedis",[6.906684652661885, 49.18690427082208]],
    [ "Enedis",[6.867806912616553, 49.19652901753838]],
    [ "Enedis",[6.913728598029304, 49.18863484341973]],
    [ "Enedis",[6.896237565152649, 49.195816478377246]],
    [ "Enedis",[6.8652573528170695, 49.18154141602408]],
    [ "Enedis",[6.9179711611736785, 49.18768921411454]],
    [ "Enedis",[6.888144682653061, 49.17920156621315]],
    [ "Enedis",[6.883145019725804, 49.17512759865232]],
    [ "Enedis",[6.8810388653146335, 49.17764703102097]],
    [ "Enedis",[6.890467809870296, 49.182033415750595]],
    [ "Enedis",[6.929984766466563, 49.18632770269567]],
    [ "Enedis",[6.880230102304743, 49.19791325826522]],
    [ "Enedis",[6.896133831399366, 49.18370344534077]],
    [ "Enedis",[6.931012721424821, 49.19478771348332]],
    [ "Enedis",[6.894966218101656, 49.19665010305559]],
    [ "Enedis",[6.872580432981104, 49.18687940877274]],
    [ "Enedis",[4.899349060438089, 45.76615002454423]],
    [ "Enedis",[-1.3523864999999995, 46.34696745]],
    [ "Enedis",[-1.3046886500000001, 46.31488490000001]],
    [ "Enedis",[-1.3070834499999995, 46.3169861]],
    [ "Enedis",[-1.3098629, 46.31585855]],
    [ "Enedis",[-1.30798265, 46.31242855]],
    [ "Enedis",[-1.3034641, 46.3124682]],
    [ "Poste électrique des Attaques",[1.9138301098988222, 50.92111028384748]],
    [ "Poste électrique de Warande",[2.1603189204522333, 50.94911049463042]],
    [ "Enedis",[4.894950264844551, 45.76808096039278]],
    [ "Poste électrique de Chevallet",[2.6383708199481415, 50.17966292890551]],
    [ "Poste électrique de Vigneres",[6.148063868459478, 45.916356057332415]],
    [ "SRD",[0.13588430735533655, 46.43639853377339]],
    [ "SRD",[0.13556935000000003, 46.439317100000004]],
    [ "SRD",[0.11856243658769945, 46.435964235969514]],
    [ "SRD",[0.13212183808180608, 46.437012524165496]],
    [ "SRD",[0.11537028019070875, 46.43593826253852]],
    [ "SRD",[0.11329044999999999, 46.43062044999999]],
    [ "SRD",[0.11699573262748464, 46.4309531332325]],
    [ "SRD",[0.12310305675484905, 46.434570487459254]],
    [ "SRD",[0.12980360707436128, 46.43760171817399]],
    [ "SRD",[0.1339399628657916, 46.43925547266492]],
    [ "SRD",[0.11677411048227225, 46.4333023578314]],
    [ "Enedis",[3.6331686389596016, 50.24849458712682]],
    [ "Poste électrique de Pertain",[2.8571315998275426, 49.79529977306368]],
    [ "Poste électrique de Laténa",[2.938161114898894, 49.65763874819672]],
    [ "Enedis",[3.9278397804726364, 46.88068044453657]],
    [ "Enedis",[-1.7177831055145885, 48.158713463088056]],
    [ "Enedis",[3.1571520911613162, 50.67003012268629]],
    [ "Enedis",[3.1398014067196556, 50.67808653611719]],
    [ "Enedis",[-1.7028058181644359, 48.14410811918418]],
    [ "Enedis",[3.151975674551052, 50.67408026593633]],
    [ "Enedis",[3.1485269613457274, 50.690559401019485]],
    [ "Enedis",[-1.725288072274438, 48.15821928534607]],
    [ "Enedis",[3.1451574112663443, 50.67589183124027]],
    [ "Enedis",[3.151802680624164, 50.676640696649706]],
    [ "Enedis",[-1.7151336821609582, 48.15610229263804]],
    [ "Enedis",[3.145859748341983, 50.672431432559364]],
    [ "Enedis",[3.139666640046446, 50.67593459337927]],
    [ "Enedis",[3.1575921149351776, 50.6750750587177]],
    [ "Enedis",[3.1431114498348527, 50.67435408003699]],
    [ "Enedis",[3.1533154691213587, 50.677419051938514]],
    [ "Enedis",[3.145973239937388, 50.68120508517177]],
    [ "Enedis",[3.1577064460642466, 50.6670083030819]],
    [ "Enedis",[3.148136120693545, 50.673536533272475]],
    [ "Enedis",[3.146415718754801, 50.675949309699995]],
    [ "Enedis",[3.1492654428142095, 50.6829777488974]],
    [ "Enedis",[3.151252454370034, 50.66528826035956]],
    [ "Enedis",[3.1587543958969078, 50.6854179492543]],
    [ "Enedis",[3.1637532651388685, 50.66764888052668]],
    [ "Enedis",[3.164280302644081, 50.674448990305045]],
    [ "Enedis",[3.165724911513314, 50.66940472261694]],
    [ "Enedis",[3.1578592646226284, 50.681053737501315]],
    [ "Enedis",[3.160061098708357, 50.681340231121446]],
    [ "Enedis",[3.146039715514923, 50.6812610340574]],
    [ "Enedis",[3.147029335000554, 50.67476715473281]],
    [ "Enedis",[3.1410007336117327, 50.6767023561387]],
    [ "Enedis",[3.1566287252384138, 50.67746130125642]],
    [ "Enedis",[3.163411708653645, 50.6808908769263]],
    [ "Enedis",[3.154114126891022, 50.678126863711356]],
    [ "Enedis",[3.1509248125191958, 50.68047682791527]],
    [ "Enedis",[3.1598495040083554, 50.68247277209478]],
    [ "Enedis",[3.1531266688808928, 50.68621596508815]],
    [ "Enedis",[3.1532910414292594, 50.67239040062922]],
    [ "Enedis",[3.1562529621628936, 50.68422547375594]],
    [ "Enedis",[3.144756573741701, 50.67380657560974]],
    [ "Enedis",[0.5930100004997216, 46.91615142091809]],
    [ "Enedis",[0.604312018401686, 46.94915004692057]],
    [ "Enedis",[0.6096967000000001, 46.93167775000001]],
    [ "Enedis",[0.6019959674882663, 46.92884526733149]],
    [ "Enedis",[0.6038491344715639, 46.936699332793744]],
    [ "Enedis",[0.6016704256302183, 46.92446384499895]],
    [ "Enedis",[0.5700166313387643, 46.93006437795947]],
    [ "Enedis",[0.583338045775756, 46.926095098615264]],
    [ "Enedis",[0.5945268000000005, 46.93096625]],
    [ "Enedis",[0.5955951935078482, 46.91522808166495]],
    [ "Enedis",[2.4431975558297894, 48.768076378183466]],
    [ "Enedis",[2.4620046832525055, 48.74706450122211]],
    [ "Enedis",[2.4430230499999994, 48.7645478]],
    [ "Enedis",[2.467644, 48.74905375]],
    [ "Enedis",[2.463407300000001, 48.747923400000005]],
    [ "Enedis",[2.4541405000000003, 48.750034850000006]],
    [ "Poste électrique de La Herse",[2.7021133001427518, 49.36070923687755]],
    [ "Poste électrique de Moimont",[2.529013165334908, 49.09104227125612]],
    [ "Enedis",[3.081073020571815, 50.73860240142698]],
    [ "Enedis",[3.0794320499999994, 50.73378799999999]],
    [ "Enedis",[3.0811045496353935, 50.73663144921968]],
    [ "Enedis",[-0.49003052972256084, 47.4993515833041]],
    [ "Enedis",[3.0888945215246633, 50.74579725381165]],
    [ "Enedis",[3.0854628267165176, 50.73641576346362]],
    [ "Enedis",[3.0837845449422328, 50.74206546520687]],
    [ "Enedis",[3.0820388696859053, 50.739489133417734]],
    [ "Enedis",[3.0892726267413693, 50.73543818608237]],
    [ "Enedis",[3.0863868489885267, 50.74892322045477]],
    [ "Enedis",[2.685760191443535, 45.606612021752596]],
    [ "Enedis",[2.688243532102908, 45.607081770580386]],
    [ "Enedis",[2.695554740230888, 45.610311912925766]],
    [ "RTE",[2.6871072385377928, 45.61089287362869]],
    [ "Le Beulet",[6.242818921206684, 46.19107100764753]],
    [ "Poste électrique de Niort",[-0.4334049411103238, 46.348891353328916]],
    [ "Enedis",[2.2995521168882123, 48.68846198409943]],
    [ "Enedis",[2.299934360152986, 48.69476087307485]],
    [ "Enedis",[2.2904644344140332, 48.68604080473089]],
    [ "Enedis",[2.29443181718867, 48.684600829181676]],
    [ "Enedis",[2.291417732440732, 48.69068314205135]],
    [ "Enedis",[2.293660509520291, 48.685773020997985]],
    [ "Enedis",[2.299667624271518, 48.70307998708007]],
    [ "Enedis",[2.2986230975970607, 48.69787701431023]],
    [ "Diligence",[2.291034668625248, 48.69429780876266]],
    [ "Enedis",[5.543979463200128, 43.64406722722593]],
    [ "519032",[7.81148147903678, 48.67670857981117]],
    [ "Enedis",[-1.5644241197910056, 47.23017866917082]],
    [ "Enedis",[-1.562749619752968, 47.22884287932587]],
    [ "Enedis",[4.819254143681821, 45.77134540632686]],
    [ "Enedis",[4.824266407250194, 45.7696299826689]],
    [ "Enedis",[5.311777219741385, 43.7168980332]],
    [ "Enedis",[0.29186057495801015, 48.797867609426575]],
    [ "Poste électrique d’Avelin",[3.082279533140669, 50.52484805919222]],
    [ "Poste électrique de Weppes",[2.894944190777186, 50.644338895235215]],
    [ "Sous-station SNCF de Weppes",[2.896274841707127, 50.645493900109116]],
    [ "Enedis",[-1.3690121830859785, 48.0016719708711]],
    [ "Enedis",[-1.5823352058572446, 47.8738205386229]],
    [ "Enedis",[-1.5974941558139353, 47.86901905019785]],
    [ "Enedis",[4.82926234047796, 45.77014886507294]],
    [ "Enedis",[3.2467479976663864, 50.48059867920409]],
    [ "Enedis",[3.235093365523453, 50.47636490403243]],
    [ "Enedis",[3.236718371671567, 50.46504884166209]],
    [ "Enedis",[3.2498180707138125, 50.466041322122585]],
    [ "Enedis",[3.24746675, 50.47123925]],
    [ "Enedis",[3.2317112000000003, 50.47637274999999]],
    [ "Enedis",[3.2214034618150147, 50.474279931067684]],
    [ "Enedis",[3.2511979, 50.475654399999996]],
    [ "Enedis",[3.226828447248485, 50.469067017171014]],
    [ "Enedis",[3.2308245834674794, 50.4704387582681]],
    [ "Enedis",[3.2617956617727244, 50.465008859522705]],
    [ "Enedis",[3.2311986429005697, 50.47451101118232]],
    [ "Enedis",[3.241325279042494, 50.47352998408077]],
    [ "Enedis",[3.256219316486045, 50.46433912160472]],
    [ "Enedis",[3.2460085494419872, 50.4779736857876]],
    [ "Enedis",[3.2441508083079005, 50.47111224760936]],
    [ "Enedis",[3.2278599318037813, 50.47282210203472]],
    [ "Enedis",[3.238249790851776, 50.46883752066026]],
    [ "Enedis",[3.2519184010811055, 50.47306557489432]],
    [ "Enedis",[3.2332192668110693, 50.47775431747431]],
    [ "Enedis",[3.245917114919942, 50.4724474977606]],
    [ "Enedis",[3.2552325873220713, 50.47776389389711]],
    [ "Enedis",[2.095334532029173, 47.2141063514875]],
    [ "Enedis",[2.1077106404031496, 47.205252175881256]],
    [ "Enedis",[2.091854302857847, 47.206621763890944]],
    [ "Enedis",[2.1080777773849424, 47.208352086602886]],
    [ "Enedis",[2.1099608476461293, 47.20767961575038]],
    [ "Enedis",[-1.5789198633637338, 47.23332148909046]],
    [ "Poste électrique de Barnabos",[1.0123451804494707, 49.65711145486723]],
    [ "Enedis",[3.6515245717608042, 50.24342394839391]],
    [ "Enedis",[3.6558258077085863, 50.23751126213438]],
    [ "Enedis",[3.6525779179879136, 50.23948550354864]],
    [ "Poste électrique de Penly",[1.2274810268706917, 49.968578231035586]],
    [ "SRD",[0.26257927648061374, 46.530211413073424]],
    [ "SRD",[0.26488400006574414, 46.53546666666721]],
    [ "SRD",[0.2768821932943886, 46.52932947720344]],
    [ "SRD",[0.26064919716741725, 46.537511110086754]],
    [ "SRD",[0.26022466703244584, 46.53328558935176]],
    [ "SRD",[0.26149320159390294, 46.534210615397015]],
    [ "SRD",[0.2710472507575045, 46.5268169402183]],
    [ "SRD",[0.2633949433583798, 46.54050608416501]],
    [ "SRD",[0.2815319129348703, 46.53131201725859]],
    [ "SRD",[0.2635697201976584, 46.52694046997337]],
    [ "SRD",[0.2640748145287881, 46.5368293901488]],
    [ "SRD",[0.2676073996630066, 46.537943508818]],
    [ "SRD",[0.26055908439212, 46.541747540591935]],
    [ "SRD",[0.268138844442131, 46.5360280489199]],
    [ "SRD",[0.2761709824000361, 46.53834573056606]],
    [ "Poste électrique de Amargue",[2.254009702312197, 49.857876479581634]],
    [ "Enedis",[6.230690962502556, 46.185639954660566]],
    [ "SRD",[0.40260195, 46.559134449999995]],
    [ "SRD",[0.4186353349337177, 46.53077185303888]],
    [ "SRD",[0.41416171832175414, 46.54591091942515]],
    [ "SRD",[0.40380717808591404, 46.56436555581015]],
    [ "SRD",[0.4085336339731478, 46.54378980626554]],
    [ "SRD",[0.4158878783699536, 46.537700740313696]],
    [ "SRD",[0.4149149805732819, 46.54070966936236]],
    [ "SRD",[0.41057359130308774, 46.543451877987465]],
    [ "SRD",[0.4260410376908886, 46.531019319045384]],
    [ "SRD",[0.4046892476065304, 46.548981003380376]],
    [ "SRD",[0.401839423657115, 46.53951015676198]],
    [ "SRD",[0.40990163137681046, 46.559121062257354]],
    [ "SRD",[0.40628854304632983, 46.54615549849567]],
    [ "SRD",[0.4175513693349595, 46.53485414499219]],
    [ "SRD",[0.492475893882647, 46.82144358108615]],
    [ "Poste électrique de Remise",[1.9302099659335807, 49.28793447180019]],
    [ "SRD",[0.4623732487201416, 46.83320176573437]],
    [ "SRD",[0.45721470866691094, 46.83483170384004]],
    [ "SRD",[0.4667321000000001, 46.83187560000001]],
    [ "SRD",[0.49504473737165533, 46.81803367317113]],
    [ "SRD",[0.46630345130745854, 46.829583899948176]],
    [ "Poste électrique de Rougemontier",[0.7355471588321583, 49.34348974267447]],
    [ "Poste électrique de La Vaupalière",[1.0100612505541788, 49.48313439694993]],
    [ "Enedis",[0.30909926132401794, 46.80341143867218]],
    [ "Enedis",[0.32139857133576144, 46.78969732279696]],
    [ "Enedis",[0.3336685393325566, 46.821317458388265]],
    [ "Enedis",[0.3281098552717046, 46.821263261842404]],
    [ "Enedis",[0.32155374999999997, 46.81587485000001]],
    [ "Enedis",[0.3224195972856363, 46.81872966223934]],
    [ "Enedis",[0.3320183200074201, 46.81637533824895]],
    [ "Poste électrique de Menuel",[-1.5770848093835403, 49.4765615623169]],
    [ "Poste électrique de Tollevast",[-1.5994349313811616, 49.594627050138975]],
    [ "RTE",[-0.752094959657352, 47.213047729845194]],
    [ "Enedis",[2.027444594659074, 48.80210723301383]],
    [ "Enedis",[2.029053984748062, 48.7994077122093]],
    [ "Enedis",[2.0043399056919182, 48.80225666695248]],
    [ "Enedis",[2.0096242999999996, 48.80244174999999]],
    [ "Enedis",[2.037141587030033, 48.80439207586449]],
    [ "Enedis",[0.7094233521058824, 46.4038986340549]],
    [ "SRD",[0.7191974789412041, 46.398329805176864]],
    [ "Enedis",[0.724599339858403, 46.400838281383045]],
    [ "Enedis",[0.7206121120082116, 46.40160020553084]],
    [ "SRD",[0.7054673089429804, 46.396002636214945]],
    [ "SRD",[0.7317197404511934, 46.400136773767244]],
    [ "SRD",[0.7160794443747431, 46.39881866344972]],
    [ "SRD",[0.7276148659996703, 46.39796891959878]],
    [ "Enedis",[2.7292098152961533, 46.265694890226065]],
    [ "Enedis",[2.6985425249025723, 46.250726714667664]],
    [ "Poste électrique de Terrette",[-1.187484376456122, 49.16136254037375]],
    [ "Poste électrique de Launay",[-1.2603461102393414, 48.56372878938414]],
    [ "SRD",[0.9163209156355338, 46.35214222886099]],
    [ "SRD",[0.9578544957465054, 46.33508782034194]],
    [ "SRD",[0.9363889880548738, 46.32295366913763]],
    [ "SRD",[0.9608180784790797, 46.33672907035522]],
    [ "Poste électrique de Tourbe",[-0.2642733540252519, 49.10824354913042]],
    [ "Enedis",[2.551351876888449, 50.52160932740362]],
    [ "Enedis",[2.5188590054195648, 50.50511394820142]],
    [ "Enedis",[2.548196572471621, 50.51932218750503]],
    [ "Enedis",[2.52591645, 50.509838800000004]],
    [ "Enedis",[2.543397129706826, 50.515613561437576]],
    [ "Enedis",[2.5406137209486106, 50.51768889601633]],
    [ "Enedis",[2.5552689280327248, 50.52332598924936]],
    [ "85926009",[2.5308546029729806, 50.51780903340585]],
    [ "Enedis",[2.541789026746599, 50.513547906289446]],
    [ "Enedis",[-1.5738751386473426, 47.22336460528031]],
    [ "Semitan Auvours",[-1.563638673156821, 47.22160147801453]],
    [ "Enedis",[-1.5754067534368235, 47.225617843727186]],
    [ "Auvours",[-1.5644390345148564, 47.221951729335146]],
    [ "SRD",[0.3052818748086278, 46.486339252215224]],
    [ "SRD",[0.28009535000000096, 46.49924835]],
    [ "SRD",[0.3146953254089886, 46.486673370699826]],
    [ "SRD",[0.31242500000000045, 46.49891074999999]],
    [ "SRD",[0.3140351, 46.48497735]],
    [ "SRD",[0.307182025426033, 46.4870037516996]],
    [ "SRD",[0.31299528092701984, 46.493787349665396]],
    [ "SRD",[0.31947429852494164, 46.50069678520658]],
    [ "SRD",[0.31313385569384067, 46.489008488708414]],
    [ "SRD",[0.3099096, 46.48714855]],
    [ "SRD",[0.3050015287668431, 46.487518561731996]],
    [ "SRD",[0.3100747623264593, 46.49866667838383]],
    [ "SRD",[0.30092504781347573, 46.4771199900714]],
    [ "Poste électrique de Coquainvilliers",[0.19571605461957223, 49.211242644185674]],
    [ "SRD",[0.47068807753966885, 46.55682184906024]],
    [ "SRD",[0.49636286159276705, 46.55763131955342]],
    [ "SRD",[0.5097652569156188, 46.55785144168172]],
    [ "Enedis",[2.030533225604667, 48.89811593955335]],
    [ "Enedis",[2.046708056714636, 48.900694732511454]],
    [ "Enedis",[2.0378439140864915, 48.908523620486264]],
    [ "Enedis",[2.049333952150043, 48.90642185088848]],
    [ "Enedis",[2.046425505695367, 48.9012698860019]],
    [ "Enedis",[2.0431899263815634, 48.906925287876234]],
    [ "Enedis",[2.0381717851107566, 48.9040194760446]],
    [ "Enedis",[2.038718734464166, 48.90606779656816]],
    [ "Enedis",[2.042465587009065, 48.90495772119269]],
    [ "Enedis",[0.33061663974848365, 46.51895116458068]],
    [ "Enedis",[3.1280521164287713, 50.41570288413098]],
    [ "Enedis",[0.3205531030467904, 46.52527854958745]],
    [ "Enedis",[0.3281847008839404, 46.51609659312678]],
    [ "Enedis",[3.1390188380265895, 50.414679607496396]],
    [ "Enedis",[3.1324969999999994, 50.41975599999999]],
    [ "Enedis",[3.130451093275487, 50.41504571759116]],
    [ "Enedis",[0.33111574984878506, 46.52397536694004]],
    [ "Enedis",[3.1329632434108547, 50.41128845953489]],
    [ "Enedis",[0.31676493559263896, 46.51985377705049]],
    [ "Enedis",[0.3257737465947361, 46.523718813571456]],
    [ "Enedis",[0.3203970042643949, 46.54237491248772]],
    [ "Enedis",[0.32338733093194494, 46.516752275434705]],
    [ "Enedis",[0.32681635884703847, 46.52158911748359]],
    [ "Enedis",[0.33029893842274183, 46.5219473745988]],
    [ "Enedis",[0.3204900536012594, 46.52527001265761]],
    [ "Enedis",[0.3227822110148634, 46.53637473145829]],
    [ "Enedis",[0.33539348358965215, 46.532981897315494]],
    [ "SRD",[0.2806999166863871, 46.5204217746369]],
    [ "Enedis",[0.3320950930856436, 46.52623526934131]],
    [ "Enedis",[0.3192023048196238, 46.523939558367815]],
    [ "Enedis",[0.43335666190206035, 46.812908538711895]],
    [ "Enedis",[0.41558653440393706, 46.81126467889393]],
    [ "Poste électrique de Domloup",[-1.556114615240404, 48.09291698283413]],
    [ "Enedis",[3.1555591765307613, 50.40194852433144]],
    [ "Poste électrique de Plaine-Haute",[-2.8779143117801533, 48.429917084365194]],
    [ "SRD",[0.43676087268259045, 46.495774546226244]],
    [ "Enedis",[3.1368887362768496, 50.39588181384247]],
    [ "Enedis",[3.1251227037914693, 50.40151233412322]],
    [ "Enedis",[3.132290749721184, 50.396836762382726]],
    [ "Enedis",[3.1329214999999997, 50.39807999999999]],
    [ "SRD",[0.4110079308121266, 46.50269954940968]],
    [ "Enedis",[3.1260944999999993, 50.40101000000001]],
    [ "SRD",[0.4100987026153115, 46.50428318102711]],
    [ "SRD",[0.4108433221873568, 46.49945574776487]],
    [ "SRD",[0.41523364655251865, 46.505997138559216]],
    [ "SRD",[0.4028583372007543, 46.507042022614606]],
    [ "SRD",[0.41613868617412514, 46.51386531297467]],
    [ "SRD",[0.41322438739888595, 46.50615141645814]],
    [ "SRD",[0.4171069313218392, 46.50999291493496]],
    [ "Enedis",[6.84102167576903, 49.21002360827495]],
    [ "Enedis",[6.8720743118029, 49.21413206634932]],
    [ "Enedis",[6.84448888592499, 49.21132283288665]],
    [ "Enedis",[6.859824867828345, 49.21141723197792]],
    [ "Enedis",[6.847394683860088, 49.20665682942189]],
    [ "Enedis",[6.853927389208079, 49.20749965695365]],
    [ "Enedis",[6.865890678173083, 49.21731705588347]],
    [ "Enedis",[6.855212276624391, 49.212808290807544]],
    [ "Enedis",[6.876050998463717, 49.21274567843293]],
    [ "Enedis",[6.853350216111879, 49.20112869452302]],
    [ "PY ABBAYE",[2.033737034007238, 48.9253293290167]],
    [ "Enedis",[-1.7193159999999998, 48.357606]],
    [ "Enedis",[-1.7119097999999997, 48.35858185000001]],
    [ "Enedis",[5.007391430697167, 43.590288953380714]],
    [ "Enedis",[5.013710192307692, 43.58027251153846]],
    [ "Enedis",[5.015543940804014, 43.56984585745241]],
    [ "Enedis",[5.011419516375144, 43.58030314724175]],
    [ "Enedis",[4.999696134185329, 43.599523466743086]],
    [ "Enedis",[5.018027888820675, 43.582649234669745]],
    [ "Enedis",[5.000818459089021, 43.598587556186665]],
    [ "Enedis",[5.017510791296399, 43.57198869014446]],
    [ "Enedis",[5.002108418982331, 43.57441325803419]],
    [ "Enedis",[5.003005070427082, 43.57626762570261]],
    [ "Enedis",[4.99715128944383, 43.587835320820155]],
    [ "Enedis",[5.002632541180687, 43.57831937486256]],
    [ "Enedis",[5.010631672700168, 43.57588951962862]],
    [ "Enedis",[5.003548395335956, 43.60235933242319]],
    [ "Enedis",[5.016102830456993, 43.58899460232999]],
    [ "Enedis",[4.9955856, 43.584112700000006]],
    [ "Enedis",[5.000575260576564, 43.606549237129975]],
    [ "Enedis",[5.006090736390165, 43.58172436564447]],
    [ "Enedis",[5.007696268332744, 43.57577150872212]],
    [ "Enedis",[4.987904112591358, 43.586339719705926]],
    [ "Enedis",[4.996777380484615, 43.60263788199358]],
    [ "Enedis",[5.000969889799481, 43.59812628244559]],
    [ "Enedis",[4.993056605640463, 43.59720986551244]],
    [ "Enedis",[5.016450481824442, 43.586165486250614]],
    [ "Enedis",[5.009836220251709, 43.59387058545665]],
    [ "Enedis",[5.016710211609735, 43.583369550927955]],
    [ "Enedis",[5.028239883298128, 43.563386800609216]],
    [ "Enedis",[5.015880850061617, 43.583943958399125]],
    [ "Enedis",[5.000194971987223, 43.58392524212406]],
    [ "Enedis",[5.004069283382498, 43.60090030006169]],
    [ "Enedis",[4.9999226375989245, 43.58025199179356]],
    [ "Enedis",[5.001501564384562, 43.5752989427057]],
    [ "Enedis",[5.002551063692371, 43.58183807156418]],
    [ "Enedis",[5.010414964718501, 43.58302677805705]],
    [ "Enedis",[5.003275308910825, 43.572843572474966]],
    [ "Enedis",[5.0152955404473545, 43.58196403592087]],
    [ "Enedis",[5.014522582298318, 43.57816059844076]],
    [ "Enedis",[5.000783252298795, 43.575110801531814]],
    [ "Enedis",[5.025964610308523, 43.589309271914146]],
    [ "Enedis",[4.995918649999999, 43.61227785]],
    [ "Enedis",[5.017052856082345, 43.57800717640732]],
    [ "Enedis",[4.996456723514474, 43.5892716363585]],
    [ "Enedis",[5.002285927856067, 43.5913897028271]],
    [ "Enedis",[5.005796317894157, 43.574902996054355]],
    [ "Enedis",[4.999217078668021, 43.60303935730131]],
    [ "Enedis",[4.992728587926565, 43.58423709596832]],
    [ "Enedis",[5.000961593761055, 43.572637769972346]],
    [ "Enedis",[4.9944162187082615, 43.57731439393225]],
    [ "Enedis",[4.999292550617522, 43.57776008349987]],
    [ "Enedis",[5.008895847556369, 43.57749498028186]],
    [ "Enedis",[4.993831339299463, 43.60475643637439]],
    [ "Enedis",[4.998747733608725, 43.59706480130045]],
    [ "Enedis",[4.997009905297471, 43.59766924523571]],
    [ "Enedis",[5.015470323317383, 43.5896301542721]],
    [ "Enedis",[5.006509064089973, 43.588635326220384]],
    [ "Enedis",[4.995186313959921, 43.59551769857116]],
    [ "Enedis",[4.999668807695037, 43.58909342299556]],
    [ "Enedis",[5.010970026234983, 43.585174559942985]],
    [ "Enedis",[5.004949638197165, 43.58888623566321]],
    [ "Enedis",[5.000033173532043, 43.58117997446876]],
    [ "Enedis",[4.99159696337001, 43.60160033523461]],
    [ "Enedis",[5.005318995427694, 43.58745481438039]],
    [ "Enedis",[5.002736212578661, 43.5938658810466]],
    [ "Enedis",[4.997241054192934, 43.598950816002535]],
    [ "Enedis",[4.995891499676934, 43.57394187173706]],
    [ "Enedis",[5.0042762447669205, 43.57540671735889]],
    [ "Enedis",[4.99899361695486, 43.58273226374773]],
    [ "Enedis",[5.008528958835678, 43.58776395188327]],
    [ "Enedis",[5.002371150174265, 43.599705548310716]],
    [ "Enedis",[5.001913816425754, 43.58680777410777]],
    [ "Enedis",[5.020725436111243, 43.58394198572994]],
    [ "Enedis",[4.99960778346792, 43.595650251078766]],
    [ "Enedis",[5.003456387836751, 43.59598753264782]],
    [ "Enedis",[4.99993358435248, 43.58264216820749]],
    [ "Enedis",[5.007322950000001, 43.585135]],
    [ "Enedis",[5.014574692305706, 43.58655929651071]],
    [ "Enedis",[5.006656347607561, 43.582258968431034]],
    [ "Enedis",[4.997084839707081, 43.59041614990804]],
    [ "Enedis",[5.023030284198483, 43.5803477539702]],
    [ "Enedis",[4.9979421217817, 43.57404738047014]],
    [ "Enedis",[5.009689918170022, 43.590436658863055]],
    [ "Enedis",[4.998610859017333, 43.57217951576722]],
    [ "Enedis",[5.004346, 43.579792899999994]],
    [ "Enedis",[5.01446125340475, 43.58363882957006]],
    [ "Enedis",[4.99316752448547, 43.57612249377122]],
    [ "Enedis",[5.009847124176715, 43.59584151766825]],
    [ "Enedis",[5.0133836484886825, 43.585846947700254]],
    [ "Enedis",[4.994560509407347, 43.59752908885114]],
    [ "Enedis",[-1.4288221, 46.73947339999999]],
    [ "Enedis",[-1.331979178234183, 46.79241533447606]],
    [ "Enedis",[-1.4265192999999998, 46.74142360000001]],
    [ "Enedis",[-1.4113337500000012, 46.73823349999999]],
    [ "Enedis",[-1.387586722035623, 46.743695151959294]],
    [ "Enedis",[-1.3973702735025668, 46.73849205563582]],
    [ "Enedis",[-1.3896455884440273, 46.775031769770266]],
    [ "Enedis",[-1.3724607506966002, 46.755095033285556]],
    [ "Enedis",[-1.438643424587471, 46.67631394411916]],
    [ "Enedis",[-1.4430480447415617, 46.67341294564657]],
    [ "Enedis",[-1.4460700000000002, 46.671364]],
    [ "Enedis",[6.239780625425555, 46.19956824968756]],
    [ "HLM Sauvegarde",[6.24022864324422, 46.20079297709183]],
    [ "Poste électrique des Quintes",[0.24049046288498613, 47.88611363822247]],
    [ "Enedis",[6.231062310176217, 46.19543618776811]],
    [ "Le Sylvania",[6.23180432125175, 46.19460628126957]],
    [ "Enedis",[5.169701067187158, 43.48093320585552]],
    [ "Enedis",[5.192412615661252, 43.51854430909756]],
    [ "Enedis",[5.174176177639872, 43.47757298395778]],
    [ "Enedis",[5.167779682269482, 43.48513053483083]],
    [ "Enedis",[5.17359837983004, 43.478646204348266]],
    [ "Enedis",[5.1693903500000005, 43.477045049999994]],
    [ "Enedis",[5.176245723607761, 43.478422366218844]],
    [ "Enedis",[5.172417838487357, 43.48972755862747]],
    [ "Enedis",[5.177640582476306, 43.5071942461932]],
    [ "Enedis",[5.171981361940779, 43.48094668268202]],
    [ "Enedis",[5.158991985447136, 43.495751250459186]],
    [ "Enedis",[5.160460459801134, 43.493137679047045]],
    [ "Enedis",[5.165678949999999, 43.4794971]],
    [ "Enedis",[5.168809491398606, 43.489988022617624]],
    [ "Enedis",[5.166410856011753, 43.47509012236074]],
    [ "Enedis",[5.176646254625696, 43.48318413296468]],
    [ "Enedis",[5.192789749999999, 43.51687755]],
    [ "Enedis",[5.1663157, 43.4719161]],
    [ "Enedis",[5.1686765999999995, 43.47807995]],
    [ "Enedis",[5.176357466246317, 43.48650929011683]],
    [ "Enedis",[5.1500457, 43.45904575]],
    [ "Enedis",[5.1748860500000005, 43.48181350000001]],
    [ "Enedis",[5.168524395453621, 43.478964177195245]],
    [ "Enedis",[5.168255090068273, 43.47304031593159]],
    [ "Enedis",[5.17008775, 43.4924091]],
    [ "Enedis",[5.174847995643013, 43.48697187577274]],
    [ "Enedis",[5.174061861401386, 43.48844637427207]],
    [ "Enedis",[5.178675350680662, 43.482974113124534]],
    [ "Poste électrique de La Picocherie",[0.9756024283447287, 47.573016653146155]],
    [ "Enedis",[-1.35340935, 46.6790235]],
    [ "Enedis",[-1.413459, 46.679658]],
    [ "Enedis",[-1.4115965, 46.6774215]],
    [ "Enedis",[-1.412160249999999, 46.67359065]],
    [ "Enedis",[-1.4102005, 46.67884075]],
    [ "Enedis",[-1.4095105000000003, 46.67376099999999]],
    [ "Enedis",[-1.409811331992388, 46.67087064521577]],
    [ "Enedis",[-1.3862543416895061, 46.67153088113936]],
    [ "Enedis",[-1.3735449295014677, 46.69328884515314]],
    [ "Enedis",[-1.40477295, 46.67181035]],
    [ "Enedis",[-1.4032090572161806, 46.68577901821697]],
    [ "Enedis",[-1.4131385, 46.67724025]],
    [ "Enedis",[-1.4036797499999998, 46.67612999999999]],
    [ "Enedis",[-1.4024248264897874, 46.67401502421562]],
    [ "Enedis",[5.040078646697642, 43.55171216803087]],
    [ "Enedis",[5.041473645595399, 43.5362056814233]],
    [ "Enedis",[5.034346279319619, 43.55209394042758]],
    [ "Enedis",[5.037416230478406, 43.544550646228814]],
    [ "Enedis",[5.04011175, 43.54561465000001]],
    [ "Enedis",[5.03518575, 43.54924865]],
    [ "Enedis",[5.0374296075358425, 43.5362826669978]],
    [ "Enedis",[5.032244150000001, 43.54726285]],
    [ "Enedis",[5.0298938315189226, 43.550639884706754]],
    [ "Enedis",[5.042538476093511, 43.53502787234984]],
    [ "Enedis",[5.037288844902566, 43.54839224153554]],
    [ "Enedis",[5.0315811712093454, 43.55026641476561]],
    [ "Enedis",[5.033059619988405, 43.553969887639205]],
    [ "Enedis",[-1.432632737226151, 46.69275237304253]],
    [ "Enedis",[-1.432623890656947, 46.69279977239773]],
    [ "Enedis",[-1.4308047499999998, 46.694638499999996]],
    [ "Enedis",[-1.4262358276113527, 46.69390809442035]],
    [ "Enedis",[-1.4315892499999998, 46.6947015]],
    [ "Enedis",[-1.4286924846751627, 46.693301874769446]],
    [ "Enedis",[-1.4272677384793944, 46.710667420785825]],
    [ "Enedis",[-1.43283595, 46.693988850000004]],
    [ "Enedis",[-1.427693, 46.70605075]],
    [ "Enedis",[-1.4331467500000001, 46.69999675]],
    [ "Enedis",[-1.4272539115859166, 46.708412676899094]],
    [ "Enedis",[-1.4304110000000003, 46.698795749999995]],
    [ "Enedis",[-1.4283027499999998, 46.69499975]],
    [ "Enedis",[-1.4888204500000002, 46.6767497]],
    [ "Enedis",[-1.4716360999999991, 46.6817381]],
    [ "Enedis",[-1.4784670000000015, 46.65800575000001]],
    [ "Enedis",[-1.459619345240359, 46.66586386731731]],
    [ "Enedis",[-1.4608957730307184, 46.666168405721535]],
    [ "Enedis",[-1.4577182499999997, 46.660868750000006]],
    [ "Enedis",[-1.4556053, 46.664643850000004]],
    [ "Enedis",[-1.424515, 46.671029250000004]],
    [ "Enedis",[-1.44027475, 46.6647]],
    [ "Enedis",[-1.443044, 46.6642625]],
    [ "Enedis",[-1.4241347999999998, 46.68358584999999]],
    [ "Enedis",[-1.44749175, 46.664173000000005]],
    [ "Enedis",[-1.4254854499999998, 46.6766711]],
    [ "Enedis",[-1.443604, 46.6673665]],
    [ "Enedis",[-1.4330282196009303, 46.689378464059246]],
    [ "Enedis",[-1.4419579135802467, 46.658014293209874]],
    [ "Enedis",[-1.4481598, 46.679295350000004]],
    [ "Les Quarts",[6.032045373411876, 45.90081016644267]],
    [ "Enedis",[6.040429693541249, 45.908025444020446]],
    [ "Les Guenards",[6.033490744607776, 45.9024471882045]],
    [ "Enedis",[-1.45766465, 46.66713204999999]],
    [ "Fruitiere",[6.035621951685964, 45.90301579009572]],
    [ "Enedis",[-1.4541402499999998, 46.663255]],
    [ "Enedis",[-1.4520355000000003, 46.66490700000001]],
    [ "Enedis",[-1.4368487197169288, 46.6602832541977]],
    [ "Enedis",[-1.4499929122995452, 46.65851527279486]],
    [ "Enedis",[-1.4547784999999998, 46.666593]],
    [ "Enedis",[-1.45716175, 46.665361499999996]],
    [ "Enedis",[-1.4391285000000011, 46.6612439]],
    [ "Enedis",[-1.4502928812611189, 46.66099335159453]],
    [ "Enedis",[-1.4533639000000007, 46.6872993]],
    [ "Enedis",[-1.4246900000000007, 46.67471775]],
    [ "Enedis",[-1.4475967500000002, 46.66164375]],
    [ "Enedis",[-1.4168052371320827, 46.68410568130481]],
    [ "Enedis",[-1.4415184999999997, 46.668839750000004]],
    [ "Enedis",[-1.4546562737257462, 46.660855544596956]],
    [ "Enedis",[-1.4269487016879268, 46.68448790103135]],
    [ "Enedis",[-1.433698616751665, 46.689056483320435]],
    [ "Enedis",[-1.4448415000000008, 46.6845765]],
    [ "Enedis",[-1.4396586751652503, 46.67076236638337]],
    [ "Enedis",[-1.4284442499999994, 46.68853324999999]],
    [ "Enedis",[-1.44328813479604, 46.678132536596294]],
    [ "Enedis",[-1.4295414999999998, 46.677583]],
    [ "Enedis",[-1.4303725104260796, 46.687100518281234]],
    [ "Enedis",[-1.4376460366357051, 46.684360304387496]],
    [ "Enedis",[-1.4334412504666034, 46.677279752835936]],
    [ "Enedis",[-1.43844775, 46.66877849999999]],
    [ "Enedis",[-1.4313106307398196, 46.68364788535523]],
    [ "Enedis",[-1.4196645, 46.671269249999995]],
    [ "Enedis",[-1.4177019000000002, 46.670602]],
    [ "Enedis",[-1.4223034098429566, 46.66518986963469]],
    [ "Enedis",[-1.427010256840246, 46.660859181760834]],
    [ "Enedis",[-1.4182742000000002, 46.664887300000004]],
    [ "Enedis",[-1.4171832, 46.66581150000001]],
    [ "Enedis",[-1.4251424698057789, 46.66672844534164]],
    [ "Enedis",[-1.4295784999999999, 46.659808250000005]],
    [ "Enedis",[-1.42844475, 46.66213449999999]],
    [ "Enedis",[-1.4322966521342457, 46.663539833145855]],
    [ "Enedis",[-1.45199775, 46.66911950000001]],
    [ "Enedis",[-1.4278436922852908, 46.66935167996342]],
    [ "Enedis",[-1.4350072843693242, 46.65826982521962]],
    [ "Enedis",[-1.44639412832417, 46.68274928346569]],
    [ "Enedis",[-1.419574716411564, 46.66225194472789]],
    [ "Enedis",[-1.4168156692999783, 46.65905701514155]],
    [ "Enedis",[-1.416963154974237, 46.66184316963932]],
    [ "Enedis",[-1.4295513082152975, 46.66687960113315]],
    [ "Enedis",[-1.429844000000001, 46.682748]],
    [ "Enedis",[-1.4215579999999999, 46.6679945]],
    [ "Enedis",[-1.4426937999999994, 46.68440915]],
    [ "Enedis",[-1.4324739773130595, 46.67235143124864]],
    [ "Enedis",[-1.431108499999999, 46.665999500000005]],
    [ "Enedis",[-1.4434827802163754, 46.67991618505856]],
    [ "Enedis",[-1.4333242499999999, 46.66176124999999]],
    [ "Enedis",[-1.4282378, 46.682148850000004]],
    [ "Enedis",[-1.4480097240682726, 46.6767610556278]],
    [ "Enedis",[-1.4324999999999997, 46.68770275]],
    [ "Enedis",[-1.4209884500000007, 46.65901635]],
    [ "Enedis",[-1.4322352378109453, 46.65794415024875]],
    [ "Enedis",[-1.4374306264025054, 46.6735930596447]],
    [ "Enedis",[-1.4164942500000006, 46.68224875000001]],
    [ "Chez Gaillard",[6.00218555, 45.9852249]],
    [ "Enedis",[6.243608324750667, 46.18325434904903]],
    [ "Enedis",[6.238723692322836, 46.182435346392054]],
    [ "Poste électrique du Verger",[1.597980175013039, 47.71189595716531]],
    [ "Poste électrique de Dambron",[1.8557068019908511, 48.10687510028803]],
    [ "Enedis",[-1.6957829088952436, 46.884951119187434]],
    [ "Enedis",[-1.7040105, 46.88138850000001]],
    [ "Enedis",[-1.6930100000000001, 46.85739375000001]],
    [ "Enedis",[-1.7013383388417165, 46.88167637991146]],
    [ "Enedis",[-1.7017938999999989, 46.8783723]],
    [ "Enedis",[6.268505322614699, 46.19577104581811]],
    [ "Enedis",[6.267112346411823, 46.195110415139766]],
    [ "Enedis",[-2.0914627618670223, 46.85108447137293]],
    [ "Enedis",[-2.1366054999999995, 46.83703599999999]],
    [ "Enedis",[-2.13198385, 46.8364214]],
    [ "Enedis",[-2.1297639999999998, 46.830597000000004]],
    [ "Enedis",[-2.1349492000000003, 46.83849885000001]],
    [ "Enedis",[-2.12933875, 46.8438315]],
    [ "Enedis",[-2.1300096184045825, 46.824820383997746]],
    [ "Les Bandieres",[6.256972933435286, 46.19601466399415]],
    [ "Enedis",[-2.1436254999999997, 46.831205000000004]],
    [ "Enedis",[-2.13152345, 46.83235814999999]],
    [ "Enedis",[-2.1250604, 46.84111625]],
    [ "Les Pléiades",[6.250238770395256, 46.197103247541435]],
    [ "Enedis",[-2.1379018757305746, 46.834604204544]],
    [ "Enedis",[-2.1274291319310863, 46.84181054305449]],
    [ "Enedis",[-2.1204662499999998, 46.83326305]],
    [ "Enedis",[-2.1281843, 46.82579485]],
    [ "Enedis",[-2.1297102500000005, 46.84147110000001]],
    [ "Enedis",[-2.135420499999999, 46.83393450000001]],
    [ "Enedis",[-2.129426914250433, 46.8466574223983]],
    [ "Enedis",[-2.1338790999999997, 46.841729699999995]],
    [ "Enedis",[-2.128971860494114, 46.83678278018335]],
    [ "Enedis",[-2.1337752500000007, 46.831263699999994]],
    [ "Enedis",[-2.14013975, 46.83080599999999]],
    [ "Enedis",[-2.1314417499999996, 46.83941449999999]],
    [ "Enedis",[-2.1314250260904974, 46.82227160553491]],
    [ "Enedis",[-2.12942075, 46.828672749999996]],
    [ "Enedis",[-2.143929750000001, 46.834317]],
    [ "Enedis",[-2.1414719999999994, 46.827676999999994]],
    [ "Enedis",[-2.1319752, 46.82559395]],
    [ "Enedis",[-2.1368182499999993, 46.838798000000004]],
    [ "Enedis",[-1.4464619999999997, 46.65591075]],
    [ "Enedis",[-1.4538944999999999, 46.6558105]],
    [ "Enedis",[-1.444874, 46.652862999999996]],
    [ "Enedis",[-1.44322, 46.649336500000004]],
    [ "Enedis",[-1.4462007500000007, 46.65168575000001]],
    [ "Enedis",[-1.4634975, 46.717852]],
    [ "Enedis",[-1.4386850000000007, 46.65391775]],
    [ "Enedis",[-1.4474615, 46.653509500000006]],
    [ "Enedis",[-1.4492447000000004, 46.6547999]],
    [ "Enedis",[-1.4499290054204272, 46.65157898150509]],
    [ "Enedis",[-1.4545075000000003, 46.654705]],
    [ "Enedis",[-1.4424514449782657, 46.655650802104766]],
    [ "Enedis",[-1.4369215000000002, 46.650732500000004]],
    [ "Enedis",[-1.4522430926851073, 46.65771236756974]],
    [ "Enedis",[-1.44519625, 46.64949575]],
    [ "Enedis",[-1.4410135, 46.64772024999999]],
    [ "Enedis",[-1.4260251999999998, 46.65112695]],
    [ "Enedis",[-1.434671630075267, 46.653180304477075]],
    [ "Enedis",[-1.4267016397857541, 46.657422173782656]],
    [ "Enedis",[-1.435415902742789, 46.657370932163424]],
    [ "Enedis",[-1.4288107499999991, 46.65186175]],
    [ "Enedis",[-1.4253560094564592, 46.652617280391674]],
    [ "Enedis",[-1.415673749999999, 46.65357724999999]],
    [ "Enedis",[-1.4274195, 46.6540281]],
    [ "Enedis",[-1.4540255000000002, 46.72330575]],
    [ "Enedis",[-1.43147595, 46.71714765]],
    [ "Enedis",[-1.4546162505796312, 46.71660725014808]],
    [ "Enedis",[-1.4310793000000002, 46.714767450000004]],
    [ "Enedis",[-1.4263959999999996, 46.72351699999999]],
    [ "Enedis",[-1.4567871712361835, 46.72650999721371]],
    [ "Enedis",[-1.4317813262350454, 46.71993715575675]],
    [ "Enedis",[-1.4330506943205465, 46.65568015640798]],
    [ "Enedis",[-1.428745226835342, 46.6498930884212]],
    [ "Enedis",[-1.4164497500000006, 46.65558925]],
    [ "Enedis",[-1.4309207499999999, 46.6537105]],
    [ "Enedis",[-1.4626575000000008, 46.720252]],
    [ "Enedis",[-1.45057775, 46.71415225]],
    [ "Enedis",[-1.4575689999999997, 46.716370000000005]],
    [ "Enedis",[-1.4523875000000002, 46.7206125]],
    [ "Enedis",[-1.4555915, 46.7205685]],
    [ "Enedis",[-1.4579273, 46.720385300000004]],
    [ "Enedis",[-1.4512809999999996, 46.72492199999999]],
    [ "Enedis",[-1.435359, 46.64068425]],
    [ "Enedis",[-1.453674749999999, 46.71413775]],
    [ "Enedis",[-1.4274633964656893, 46.633716767016594]],
    [ "Enedis",[-1.4270348, 46.714719900000006]],
    [ "Enedis",[-1.441249671559633, 46.64629698124363]],
    [ "Enedis",[-1.4613296, 46.72648985]],
    [ "Enedis",[-1.4365965724169691, 46.646847890994806]],
    [ "Enedis",[-1.4312764871071946, 46.715314275851355]],
    [ "Enedis",[-1.4323081198138172, 46.72497767206423]],
    [ "Enedis",[-1.41804825, 46.64441925]],
    [ "Enedis",[-1.450232, 46.7167735]],
    [ "Enedis",[-1.4583110985880574, 46.72204263329228]],
    [ "Enedis",[-1.4609447500000001, 46.72246825]],
    [ "Enedis",[-1.4283041945945023, 46.70818847978578]],
    [ "Enedis",[-1.4023274734121556, 46.66021326275959]],
    [ "Enedis",[-1.4125367500000001, 46.645261749999996]],
    [ "Enedis",[-1.4083367500000004, 46.66731649999999]],
    [ "Enedis",[-1.3992907816924105, 46.66773524712323]],
    [ "Enedis",[-1.399308270651051, 46.66399440546008]],
    [ "Enedis",[-1.4089755, 46.665778]],
    [ "Enedis",[-1.4041807500000003, 46.657417]],
    [ "Enedis",[-1.4110662499999997, 46.65839625000001]],
    [ "Enedis",[-1.4137115, 46.66084175]],
    [ "Enedis",[-1.4024049999999997, 46.6634085]],
    [ "Enedis",[-1.4137859231045622, 46.66558789372491]],
    [ "Enedis",[-1.4031167432145348, 46.66595701769899]],
    [ "Enedis",[-1.4025672500000006, 46.668110500000004]],
    [ "Enedis",[-1.4133080000000002, 46.64357825]],
    [ "Enedis",[-1.3987453890291728, 46.66012102614394]],
    [ "Enedis",[-1.4107725, 46.666275750000004]],
    [ "Enedis",[-1.4021398, 46.6648643]],
    [ "Enedis",[-1.407012, 46.66373025000001]],
    [ "Enedis",[-1.4151974999999999, 46.644462000000004]],
    [ "Enedis",[-1.41187791516129, 46.66248589387098]],
    [ "Enedis",[-1.4142493802345062, 46.6589887922948]],
    [ "Poste électrique du Gâtinais",[2.3848606743333303, 48.08907307951201]],
    [ "Enedis",[-1.4094979999999997, 46.64073325]],
    [ "Enedis",[-1.4153652499999991, 46.642652250000005]],
    [ "Enedis",[-1.4138561999999986, 46.64138515]],
    [ "Enedis",[-1.4091673, 46.6399593]],
    [ "Enedis",[-1.40513925, 46.635774749999996]],
    [ "Enedis",[-1.406052, 46.625896000000004]],
    [ "Les Esserts",[6.2637637837480895, 46.199006849191385]],
    [ "P P C M",[6.26368427353452, 46.19871133465903]],
    [ "Cocolet",[6.260723577951316, 46.1985234509367]],
    [ "Perrothon",[6.263380652274928, 46.19761656703671]],
    [ "Dutreve",[6.256786646936481, 46.19650720421493]],
    [ "Enedis",[-1.1530707010603432, 46.45457773357914]],
    [ "Enedis",[-1.1556815745400149, 46.4540680942612]],
    [ "Enedis",[-1.1545105247604144, 46.45535010795657]],
    [ "Enedis",[-1.1594144699724303, 46.44813800465505]],
    [ "La Charmille",[0.1888948216635678, 48.038848895155944]],
    [ "Poste électrique de Tabarderie",[2.5350759373560514, 47.747013828305676]],
    [ "Poste électrique de Boismorand",[2.749760230675358, 47.79251241912345]],
    [ "Enedis",[2.318431292255839, 48.7565226629335]],
    [ "Enedis",[2.3254957, 48.7571066]],
    [ "Enedis",[2.0467570735675125, 48.93156997978071]],
    [ "Enedis",[2.285790815977464, 48.73986089760697]],
    [ "Enedis",[1.3593642120836054, 47.608528017875024]],
    [ "Enedis",[2.337615029775878, 48.7541638520349]],
    [ "Enedis",[5.025496661623018, 45.15520134920883]],
    [ "Enedis",[2.567854483380817, 50.519408983539094]],
    [ "Enedis",[2.561745420118343, 50.522614179487185]],
    [ "Enedis",[2.5601076275547006, 50.524277713552934]],
    [ "Enedis",[2.5772651288984516, 50.53374835727005]],
    [ "Poste électrique de Theix",[-2.6809620479284537, 47.662157090094894]],
    [ "Veroncle",[5.228064694634536, 43.915760120617186]],
    [ "Enedis",[4.346919902353691, 45.398045850856704]],
    [ "Enedis",[0.08761916514477981, 49.36296442063564]],
    [ "Enedis",[0.0869071155783616, 49.37569938365098]],
    [ "Enedis",[0.09238600642057083, 49.3559589337595]],
    [ "Enedis",[0.10835338787556961, 49.372624671890115]],
    [ "Enedis",[0.11894333090555015, 49.37062419221032]],
    [ "Enedis",[0.090179755051371, 49.35934313944767]],
    [ "Enedis",[0.1042331061244143, 49.384017159032645]],
    [ "Enedis",[0.09030061899965686, 49.3632732516578]],
    [ "Jean Jaurès",[4.324642403182739, 45.39591111545148]],
    [ "Enedis",[0.09095472478097608, 49.36247562081768]],
    [ "Enedis",[0.11256983477287803, 49.37897234243379]],
    [ "Enedis",[4.330501748532816, 45.39646907332183]],
    [ "Enedis",[0.10868055375522663, 49.38090283430197]],
    [ "Enedis",[0.1042345104588394, 49.37713257801819]],
    [ "Enedis",[0.11544567103670188, 49.3762161009795]],
    [ "Enedis",[0.11061956270385376, 49.3839130308774]],
    [ "Enedis",[0.09540848371820068, 49.36115547374979]],
    [ "Enedis",[0.08835064329981247, 49.36550003196953]],
    [ "Enedis",[4.058303055575458, 49.28288881723379]],
    [ "Enedis",[4.054233748058759, 49.28529825952249]],
    [ "Enedis",[4.0485716, 49.282656450000005]],
    [ "SRD",[0.09345786925611685, 46.83752103957659]],
    [ "Enedis",[0.12835608160992112, 49.401934835968355]],
    [ "SRD",[0.2638568, 46.648764]],
    [ "SRD",[0.23900473817673817, 46.64686535906428]],
    [ "SRD",[0.23001064421944908, 46.64450735099514]],
    [ "SRD",[0.28285241804807487, 46.655848756106224]],
    [ "SRD",[0.23316965459770164, 46.6472268]],
    [ "SRD",[0.24215679661595638, 46.64515979161274]],
    [ "SRD",[0.2251374143292471, 46.647029198836485]],
    [ "SRD",[0.23427425, 46.64335025]],
    [ "SRD",[0.9943132302475414, 46.43686194876229]],
    [ "Enedis",[0.8119109180518321, 46.786720566666666]],
    [ "Enedis",[0.8135241236416813, 46.786935082524785]],
    [ "Enedis",[0.8238762087102757, 46.78032504626176]],
    [ "Enedis",[0.8037183710730322, 46.77356967649939]],
    [ "Enedis",[0.7976438228248477, 46.79410593234778]],
    [ "Enedis",[0.8130684469809387, 46.78830218700163]],
    [ "Enedis",[0.8241996549305595, 46.77845667354743]],
    [ "Enedis",[4.045594725040707, 49.22456975767155]],
    [ "DP La Fayette",[4.050885753743317, 49.2242073976249]],
    [ "ESSeyssel",[6.012650195353835, 45.99633221374079]],
    [ "Enedis",[3.98535645, 49.24899644999999]],
    [ "Enedis",[3.979035234544253, 49.25719164641242]],
    [ "Enedis",[3.99209991869427, 49.247223108335945]],
    [ "Enedis",[3.9909794903617586, 49.246670219827884]],
    [ "Enedis",[3.99242765, 49.24423525]],
    [ "Enedis",[3.994992699999999, 49.24227095]],
    [ "Enedis",[3.9850977471318982, 49.2396346855615]],
    [ "Enedis",[3.991732903855216, 49.25260462365246]],
    [ "Enedis",[4.004331089491016, 49.25056805710646]],
    [ "Enedis",[3.994554369988035, 49.243641699581076]],
    [ "Enedis",[3.9821519999999997, 49.247806399999995]],
    [ "Enedis",[3.985627082167359, 49.23858599807445]],
    [ "SRD",[0.24343637428289566, 46.88480893992556]],
    [ "Enedis",[-1.6941214577533261, 47.69674466266632]],
    [ "Enedis",[0.5273163720173883, 46.718560294865036]],
    [ "Enedis",[0.5424914584345082, 46.716313241135886]],
    [ "SRD",[0.1653983300761402, 46.20563814931351]],
    [ "Enedis",[3.9622526189893987, 49.253830356086425]],
    [ "Enedis",[3.965031850000001, 49.25522110000001]],
    [ "Thillois 3",[3.9687356480338103, 49.25436605609396]],
    [ "SRD",[0.15881742512302688, 46.213424577138674]],
    [ "Enedis",[3.96706923460619, 49.25427948518002]],
    [ "Enedis",[0.5744683174476558, 46.6811748001739]],
    [ "Enedis",[0.5667858212399683, 46.687646020113895]],
    [ "Enedis",[0.5635861954270599, 46.68535621276372]],
    [ "Enedis",[0.5698221305303908, 46.67748039163567]],
    [ "Enedis",[0.6532453347560038, 46.67334272800741]],
    [ "SRD",[0.6427167000000001, 46.6711913]],
    [ "Enedis",[-1.94310245, 46.72299405]],
    [ "Enedis",[-1.96639215, 46.6990355]],
    [ "Enedis",[-1.9561366000000007, 46.7138124]],
    [ "Enedis",[-1.961098408614805, 46.70115418177789]],
    [ "Enedis",[-1.9554606899949372, 46.709893728898464]],
    [ "Enedis",[-1.952537519252862, 46.70571296993994]],
    [ "Enedis",[-1.9446785, 46.706233250000004]],
    [ "Enedis",[-1.946567, 46.70439]],
    [ "Enedis",[-1.9633043499999996, 46.69510115]],
    [ "Enedis",[-1.9538042500000008, 46.72019224999999]],
    [ "Enedis",[-1.948122300000001, 46.706283850000005]],
    [ "Enedis",[-1.967626950000001, 46.7163267]],
    [ "Enedis",[-1.9617930500000007, 46.70618635]],
    [ "Enedis",[-1.94672807870497, 46.723278884724124]],
    [ "Enedis",[-1.9771093, 46.70737855]],
    [ "Enedis",[0.3997089999999999, 47.7108055]],
    [ "Enedis",[0.3978966928735631, 47.70951519356321]],
    [ "Enedis",[0.4004092314814813, 47.71549033333333]],
    [ "Enedis",[0.4018055702977469, 47.71941705186223]],
    [ "Enedis",[-1.9774368000000002, 46.70374785]],
    [ "Enedis",[-1.944366500000001, 46.70495980000001]],
    [ "Enedis",[-1.9464030000000005, 46.725060750000004]],
    [ "Enedis",[-1.96732425, 46.714313000000004]],
    [ "Enedis",[-1.9531717917481413, 46.70382845675672]],
    [ "SRD",[0.4120431500000001, 46.89942175000001]],
    [ "Enedis",[-1.9690912500000002, 46.704668749999996]],
    [ "Enedis",[-1.9786941178645947, 46.70592360268244]],
    [ "SRD",[0.41960885800444564, 46.9169367939981]],
    [ "Enedis",[-1.9750194306726554, 46.71068429730337]],
    [ "Enedis",[-1.969370645664311, 46.706762904547595]],
    [ "Enedis",[-1.971780527571043, 46.71093340589819]],
    [ "Enedis",[-1.9653228, 46.70954315]],
    [ "Enedis",[-1.9561435260935482, 46.72425289450313]],
    [ "Enedis",[-1.9662335846349401, 46.70068163421069]],
    [ "Enedis",[-1.9571957500000003, 46.7053355]],
    [ "Enedis",[-1.9653496623409663, 46.717563623460556]],
    [ "Enedis",[-1.9500727778365063, 46.70945430726145]],
    [ "Enedis",[-1.9704507499999997, 46.715972750000006]],
    [ "Enedis",[-1.9458314999999997, 46.70756125]],
    [ "Enedis",[-1.96640265, 46.6957302]],
    [ "Enedis",[-1.9689532590308378, 46.69771255976505]],
    [ "Enedis",[-1.9526904500000002, 46.7071069]],
    [ "Enedis",[-1.9589790195059442, 46.716921247466026]],
    [ "Enedis",[-1.952793788350161, 46.71308792433346]],
    [ "Enedis",[-1.9475607000000008, 46.71912565000001]],
    [ "Enedis",[-1.9482350000000006, 46.709725500000005]],
    [ "Enedis",[-1.9679334423912778, 46.71247209699969]],
    [ "Enedis",[-1.9494258000000007, 46.712715749999994]],
    [ "Enedis",[-1.9719252581030342, 46.6990081720891]],
    [ "Enedis",[-1.9735877499999996, 46.703514999999996]],
    [ "SRD",[0.4709716606692847, 46.567891424528284]],
    [ "Enedis",[0.618393116270848, 46.58950723493753]],
    [ "Enedis",[0.8481193717030178, 46.67909909626334]],
    [ "Enedis",[4.117992355947775, 49.19265242919367]],
    [ "Enedis",[4.112714194011053, 49.193219959801525]],
    [ "SRD",[0.32286036637611565, 46.16092228925828]],
    [ "SRD",[0.3116277830092008, 46.16062563939432]],
    [ "Enedis",[-2.000269250000001, 46.74143275]],
    [ "Enedis",[-2.010681992648551, 46.7472518316676]],
    [ "Enedis",[-2.0439212500000004, 46.774552250000006]],
    [ "Enedis",[-2.0480285999999994, 46.772815800000004]],
    [ "Enedis",[-2.0144157500000004, 46.75014975]],
    [ "Enedis",[-2.0423024999999995, 46.77861625]],
    [ "Enedis",[-1.9411193333388315, 46.72867700025071]],
    [ "Enedis",[-2.0270889999999993, 46.76097624999999]],
    [ "Enedis",[-2.0258745000000005, 46.759321500000006]],
    [ "Enedis",[-2.0251465, 46.75770425000001]],
    [ "Enedis",[-1.9898325125051493, 46.740015006963326]],
    [ "Enedis",[-2.0397730000000003, 46.77612325]],
    [ "Enedis",[-1.949144765156093, 46.733605833333336]],
    [ "Enedis",[-2.0337132500000012, 46.764991499999994]],
    [ "Enedis",[-1.9763982500000001, 46.746935500000006]],
    [ "Enedis",[-2.0168195, 46.75175025]],
    [ "Enedis",[-1.9653666, 46.7740464]],
    [ "Enedis",[-2.0184420000000003, 46.7495935]],
    [ "Enedis",[-1.9926509500000003, 46.74124739999999]],
    [ "Enedis",[-2.04597075, 46.77721425]],
    [ "Enedis",[-1.9252575265081107, 46.73112659608326]],
    [ "Enedis",[-1.9919650000000002, 46.738785750000005]],
    [ "Enedis",[-2.0441942499999994, 46.778531]],
    [ "Enedis",[-2.011843499999999, 46.74788399999999]],
    [ "Enedis",[-1.9493510000000007, 46.72955674999999]],
    [ "Enedis",[-2.0524246414055427, 46.7721390388126]],
    [ "Enedis",[-2.0454437765148845, 46.77428977202274]],
    [ "Enedis",[-1.9300245403665437, 46.747627921664595]],
    [ "Enedis",[-1.9298973332507068, 46.73321609985917]],
    [ "Enedis",[-2.027551, 46.756961]],
    [ "Enedis",[-1.9927328500000001, 46.74002079999999]],
    [ "Enedis",[-2.0018705, 46.7410005]],
    [ "Enedis",[-1.99768745, 46.742983699999996]],
    [ "Enedis",[0.6032487468619421, 46.981904020222586]],
    [ "Enedis",[3.630973712585932, 50.253203392244224]],
    [ "Enedis",[0.6093384499999994, 46.971043449999996]],
    [ "Enedis",[3.644377327029466, 50.25527985940166]],
    [ "Enedis",[3.64094955, 50.25549165]],
    [ "Enedis",[3.6473450116336408, 50.254990303275946]],
    [ "Enedis",[3.6431616428488844, 50.25599559914901]],
    [ "Enedis",[2.985580057649085, 42.639974439970864]],
    [ "Enedis",[2.982711570872744, 42.643322825980526]],
    [ "Enedis",[2.9667491786125817, 42.6406546691407]],
    [ "Enedis",[2.9917598195114707, 42.645176152892546]],
    [ "Enedis",[2.9789263644913992, 42.63631724032591]],
    [ "Enedis",[2.9804087026496204, 42.64410499975912]],
    [ "Château d'Eau",[2.977189600000001, 42.63946379999999]],
    [ "Enedis",[2.9725331829388106, 42.641709001856576]],
    [ "Enedis",[2.975739303809613, 42.64252262211009]],
    [ "SRD",[1.0032101, 46.383004549999995]],
    [ "Enedis",[6.242156247947516, 46.193918567336986]],
    [ "Enedis",[4.0999948854797905, 49.33807579483982]],
    [ "Enedis",[4.100809907069032, 49.34104072704382]],
    [ "SRD",[0.4587485591597555, 46.48385868292224]],
    [ "SRD",[0.4489671453298408, 46.4898489234137]],
    [ "SRD",[0.4753825777198067, 46.50198989178033]],
    [ "SRD",[0.4555735000000001, 46.49317035]],
    [ "SRD",[0.4549911183660245, 46.48549880140628]],
    [ "Enedis",[0.36996007772956546, 46.820827342938]],
    [ "Enedis",[0.34998368793319146, 46.8038702358235]],
    [ "Enedis",[0.7596805, 46.73461915000001]],
    [ "Enedis",[0.7762891384178366, 46.7369529209571]],
    [ "Enedis",[0.7706986614386598, 46.73790292839201]],
    [ "Enedis",[0.7621527108510066, 46.738618716116264]],
    [ "Chef Lieu",[6.182103488549532, 45.978878130879664]],
    [ "Les Vernes",[6.101611164674891, 45.97879792428368]],
    [ "Enedis",[7.0795627032376, 49.11174849644336]],
    [ "Enedis",[7.039334146412495, 49.11231016356389]],
    [ "Enedis",[7.078103600000001, 49.10860195]],
    [ "Enedis",[7.12201274607145, 49.127008181031655]],
    [ "Enedis",[7.098418809273239, 49.10236168086151]],
    [ "Enedis",[7.052068454894053, 49.11028665676115]],
    [ "Enedis",[7.063131664862103, 49.108718848968856]],
    [ "Enedis",[7.088082305622098, 49.107129350598875]],
    [ "Enedis",[7.046006112073971, 49.09669285040763]],
    [ "Enedis",[7.105568227694147, 49.10661288418458]],
    [ "Enedis",[7.098526148714693, 49.11052826067079]],
    [ "Enedis",[7.101367993308316, 49.119898698499064]],
    [ "Enedis",[7.084240637154379, 49.10369225350963]],
    [ "Enedis",[7.068109448409578, 49.11194285234255]],
    [ "SRD",[0.40146503372456943, 46.1448518092723]],
    [ "Enedis",[7.09094249527874, 49.11007643044367]],
    [ "SRD",[0.3978814724565517, 46.150564055609195]],
    [ "SRD",[0.40557383449210804, 46.14527778989736]],
    [ "Enedis",[7.100698112102519, 49.11356721424905]],
    [ "Enedis",[7.036811465964443, 49.1193927421734]],
    [ "Enedis",[7.065988026856971, 49.106384190200245]],
    [ "Enedis",[7.084311289648135, 49.114244972674705]],
    [ "Enedis",[7.0417208839688215, 49.105490958835496]],
    [ "Enedis",[7.090306200000001, 49.127236849999996]],
    [ "Enedis",[7.0781526492970315, 49.12300396669202]],
    [ "Enedis",[7.104206137711142, 49.10397946781806]],
    [ "Enedis",[7.02572165, 49.1228459]],
    [ "Enedis",[7.058220555980152, 49.111582746727315]],
    [ "Enedis",[7.060434685011147, 49.10669853171016]],
    [ "Enedis",[7.062994164907243, 49.11222181136817]],
    [ "Enedis",[7.116464132020966, 49.12500634814405]],
    [ "Enedis",[7.042192000111045, 49.117665888485426]],
    [ "Enedis",[7.095813226249378, 49.11363047677983]],
    [ "Enedis",[7.068740587477819, 49.1139293068754]],
    [ "Enedis",[7.064932598511541, 49.11076749452325]],
    [ "Enedis",[7.095889338542029, 49.11802557101053]],
    [ "Enedis",[7.0758829902309825, 49.11174310059986]],
    [ "Enedis",[7.0943545420234555, 49.121363070435486]],
    [ "Enedis",[7.064742144313624, 49.10201722519409]],
    [ "Enedis",[7.045572489469236, 49.1150258164196]],
    [ "Enedis",[7.083801441411072, 49.12277157621023]],
    [ "Enedis",[7.067747590747578, 49.108454574032]],
    [ "Enedis",[7.047841107271876, 49.113970824443065]],
    [ "Enedis",[7.0539044452152275, 49.106260880887994]],
    [ "Enedis",[7.077983372806865, 49.12017733522293]],
    [ "Enedis",[7.079091400898651, 49.11694735799633]],
    [ "Enedis",[7.03501041438323, 49.115158931914586]],
    [ "Enedis",[7.097551205178145, 49.12026758363336]],
    [ "Enedis",[7.125252348358932, 49.111950069243015]],
    [ "Enedis",[7.079469375834452, 49.09967542607341]],
    [ "SRD",[0.5453989196167394, 46.770095712562416]],
    [ "Enedis",[0.6766673270730362, 46.231149822849716]],
    [ "Enedis",[3.631611995580098, 50.258589393318054]],
    [ "ESSeyssel",[6.12837865, 45.9935008]],
    [ "Chez Mouthon 1",[6.144524973026008, 45.96207654192531]],
    [ "ACOD 3",[6.144520224631276, 45.96205368623995]],
    [ "La Gare",[6.158181973616218, 45.96608136291372]],
    [ "Les Chapaliers",[6.143936106259769, 45.97609369272156]],
    [ "ESSeyssel",[6.162901227452309, 45.968130826638905]],
    [ "Mercier Haut",[6.163064415864344, 45.96455200085084]],
    [ "Mercier Bas",[6.16647366471465, 45.96148708405709]],
    [ "Enedis",[3.9850112352464224, 49.2424268722651]],
    [ "Poste électrique de Ormes",[3.9753453206419263, 49.236192430144264]],
    [ "Enedis",[3.9641216375965866, 49.270475071726906]],
    [ "Enedis",[3.9671270413299884, 49.26678878715182]],
    [ "Enedis",[3.962380808478804, 49.26493447412719]],
    [ "Croix Blanche",[3.9703433844177756, 49.26690575719849]],
    [ "Mairie",[3.9728696500000016, 49.268681150000006]],
    [ "Enedis",[3.963427684833532, 49.26865282727067]],
    [ "Enedis",[3.9729238613384896, 49.265797256792]],
    [ "Enedis",[3.966715455389408, 49.26969761896158]],
    [ "Enedis",[3.9635889919760046, 49.265550148512844]],
    [ "Enedis",[3.967131432063381, 49.268560150628666]],
    [ "Enedis",[3.9686686162934333, 49.26576891302924]],
    [ "Cret des Pierres",[6.2165967856983295, 45.97601752784886]],
    [ "Enedis",[6.23161505, 45.98646570000001]],
    [ "Enedis",[5.995908249999999, 47.28806374999999]],
    [ "Enedis",[5.993031950000001, 47.28635710000001]],
    [ "Enedis",[5.993763555990222, 47.28912964058681]],
    [ "Enedis",[5.997825144378893, 47.289560931825626]],
    [ "Enedis",[5.992074805452292, 47.286828059479554]],
    [ "Enedis",[5.993507500000001, 47.287791000000006]],
    [ "Enedis",[5.99588468361582, 47.29005628305086]],
    [ "Enedis",[5.996464131182795, 47.2910007827957]],
    [ "Le colporteur",[6.2480892430336, 45.99644457051683]],
    [ "Enedis",[6.205400833507947, 46.00835542823369]],
    [ "Corageoud",[6.2062950446117044, 46.000581537815656]],
    [ "Usillon",[6.279685403391084, 45.98450066665139]],
    [ "Enedis",[6.245725132058583, 45.99478747207694]],
    [ "Scierie de Sales",[6.253863744279998, 45.99795123009448]],
    [ "Le Refuge",[6.328152951969472, 45.96433486647599]],
    [ "La Mitry",[6.2438273224993095, 45.99631649677062]],
    [ "Les Combes",[6.249324284783511, 45.99432923311826]],
    [ "Enedis",[6.214245012009238, 46.00907075467667]],
    [ "Cerisier",[6.251759942077285, 46.00094579939344]],
    [ "Cimetiere",[6.2487558000000005, 45.9982324]],
    [ "Le Biot",[6.239559532940981, 45.99711521621612]],
    [ "Le Vernay",[6.2869915, 45.975935549999996]],
    [ "Chef-Lieu",[6.251094985794226, 45.99604636404826]],
    [ "La Mouille",[6.22493986217527, 45.998197152234155]],
    [ "Doucy",[6.157403041662994, 45.98241175056008]],
    [ "La Culaz",[6.16405296962868, 45.97763806648335]],
    [ "Le Calvaire",[6.15407271837378, 45.97547784405454]],
    [ "ZAE1 Bromines",[6.066822049999999, 45.9410537]],
    [ "ESSeyssel",[6.045671277239484, 45.95204555932153]],
    [ "ESSeyssel",[6.077416467736826, 45.94506693239156]],
    [ "Pompier",[6.038831804355196, 45.94791498387242]],
    [ "Enedis",[6.012887092464782, 45.88755339034661]],
    [ "Enedis",[5.9938389999999995, 45.866200649999996]],
    [ "Enedis",[5.994795626489997, 45.87327490935255]],
    [ "ESSeyssel",[6.006110597114882, 45.84567800320225]],
    [ "ESSeyssel",[6.02161822954171, 45.83118855538192]],
    [ "Chapeiry",[6.055880606223587, 45.83605899562151]],
    [ "ESSeyssel",[6.032330251315359, 45.804242896621396]],
    [ "Enedis",[6.07134265, 45.808039400000006]],
    [ "Enedis",[6.053683649999999, 45.818612099999996]],
    [ "Enedis",[2.0623654356086463, 47.23150802548351]],
    [ "Enedis",[2.065642011481315, 47.2380477687347]],
    [ "Enedis",[2.060039542003634, 47.24152798741106]],
    [ "Enedis",[2.058738403336373, 47.23755600409349]],
    [ "Enedis",[2.069551931231587, 47.2273422579334]],
    [ "Enedis",[2.0581471141254144, 47.23004884770916]],
    [ "Enedis",[2.0668649409041193, 47.2300234374367]],
    [ "Enedis",[-1.9242969152119331, 46.70638071737957]],
    [ "Enedis",[-1.9035608724138915, 46.71563025544995]],
    [ "Enedis",[-1.89463945, 46.723878449999994]],
    [ "Enedis",[-1.9285341362540336, 46.70415398148698]],
    [ "Enedis",[-1.8948065499999998, 46.71508149999999]],
    [ "Enedis",[-1.9150162499999999, 46.706858]],
    [ "Enedis",[-1.921957950723152, 46.70193636670083]],
    [ "Enedis",[-1.9316830000000003, 46.709626500000006]],
    [ "Enedis",[-1.9257396499999995, 46.70054245]],
    [ "Enedis",[-1.9278143000000005, 46.70247755]],
    [ "Enedis",[-1.9270855000000002, 46.705717]],
    [ "Enedis",[-1.8725561399806878, 46.73191968234906]],
    [ "Enedis",[-1.9240344000000007, 46.70441045]],
    [ "Enedis",[-1.886358650000001, 46.7119112]],
    [ "Enedis",[-1.899205291634421, 46.720865600480025]],
    [ "Enedis",[-1.864654615174506, 46.71113369418311]],
    [ "Enedis",[-1.9250194073833142, 46.70277518178826]],
    [ "Enedis",[-1.918394150000001, 46.70486475]],
    [ "Enedis",[-1.9014980499999998, 46.7186882]],
    [ "Enedis",[-1.9307722847868878, 46.70539501752255]],
    [ "Enedis",[-1.9206804241212485, 46.706344643891136]],
    [ "Enedis",[3.527674772151899, 50.05771044725739]],
    [ "Enedis",[3.5315727476635517, 50.05493555140186]],
    [ "Enedis",[3.4979383252032514, 50.055553056910576]],
    [ "Amadeus",[5.437151194139496, 43.53651149493224]],
    [ "Beisson A",[5.446462470754947, 43.53933805087049]],
    [ "Bellevue",[5.44125066480881, 43.54211455174969]],
    [ "Dauphine",[5.435237040387217, 43.53671760056387]],
    [ "Jardin Bellegarde",[5.450686959593288, 43.53280357493581]],
    [ "La Molle",[5.438458803631414, 43.53252273920995]],
    [ "Charmette",[5.442645227759987, 43.53988806075437]],
    [ "Tassigny",[5.438758112209664, 43.53059942972328]],
    [ "Violette",[5.450655792920373, 43.53512678547662]],
    [ "Isaac",[5.45238594211744, 43.53613274020661]],
    [ "Hameau de la Torse",[5.465732881437266, 43.525566567838766]],
    [ "Guillotin",[5.457057599488711, 43.523751916315675]],
    [ "Coubertin",[5.460611380649823, 43.52252497516723]],
    [ "Gallet Cantant",[5.458720512767802, 43.529364404639736]],
    [ "Chantemerle",[5.4689005170774445, 43.52287322276263]],
    [ "Enedis",[5.455845442958096, 43.52556692454978]],
    [ "Thiers",[5.456700916205723, 43.531081747197995]],
    [ "Enedis",[4.9544680308131115, 44.00078165928873]],
    [ "Enedis",[4.96042095, 44.00456595]],
    [ "Enedis",[5.93025782326622, 47.24455997762864]],
    [ "Enedis",[5.9272835839653295, 47.242768704586496]],
    [ "Enedis",[1.28318009515479, 43.66682549565932]],
    [ "Enedis",[1.2815337482705045, 43.66836429201897]],
    [ "Enedis",[1.2887954229974483, 43.67014163779646]],
    [ "Enedis",[1.2800536654181003, 43.670928136235815]],
    [ "Enedis",[1.28373575, 43.66580770000001]],
    [ "Enedis",[1.2858953005348561, 43.66478799691103]],
    [ "Enedis",[1.2804299000000001, 43.66689045]],
    [ "Enedis",[1.2834835, 43.671410900000005]],
    [ "Enedis",[1.2862696875614794, 43.67585546539743]],
    [ "Enedis",[1.2971336662311455, 43.66584775070206]],
    [ "Enedis",[1.2787193742525662, 43.66866318572067]],
    [ "Enedis",[1.2834269300855075, 43.675828896528195]],
    [ "Enedis",[1.285247342440744, 43.66922052786703]],
    [ "Enedis",[1.2822882999999998, 43.67455939999999]],
    [ "Enedis",[1.276665, 43.67197755]],
    [ "Enedis",[1.3026445330678327, 43.660356818805724]],
    [ "Enedis",[1.2998310032058256, 43.66594790747711]],
    [ "Enedis",[5.993782, 47.27415225000001]],
    [ "Enedis",[5.983679861713107, 47.27106753973168]],
    [ "Enedis",[5.9929307594696954, 47.2794028655303]],
    [ "Enedis",[5.993601311032403, 47.277326175127904]],
    [ "Enedis",[5.99098802534113, 47.27347867251461]],
    [ "Enedis",[5.981619689497716, 47.270401789954335]],
    [ "Enedis",[5.9860301664182805, 47.2702254947839]],
    [ "Enedis",[5.999641937601484, 47.27518358594293]],
    [ "Enedis",[5.987878430564639, 47.26888283758448]],
    [ "Enedis",[5.994122250896058, 47.284053530465954]],
    [ "Enedis",[5.9913321079349435, 47.27146613044193]],
    [ "Malouesse",[5.429227118753496, 43.49163135412584]],
    [ "Enedis",[5.4308339000000005, 43.515728849999995]],
    [ "Enedis",[5.434672044419707, 43.47381938888009]],
    [ "Barrage Bimont",[5.532604858203836, 43.54184376840791]],
    [ "Enedis",[-4.571508071080482, 48.375014646568616]],
    [ "Enedis",[-4.594954321947788, 48.37359303963064]],
    [ "Enedis",[-4.475971049999999, 48.41677595]],
    [ "CT Marce Cachin 138",[2.296910342938206, 48.80859822865546]],
    [ "Enedis",[2.2860422215892746, 48.79692713700128]],
    [ "Henri Gatinot 18",[2.294729187463427, 48.80553693935781]],
    [ "Enedis",[2.289450456878482, 48.808443979548585]],
    [ "Enedis",[2.288220618019708, 48.79620029774092]],
    [ "CT Louvois 51B",[2.296152559565423, 48.80379837711076]],
    [ "CT Courtois",[2.294342327935786, 48.8033283340134]],
    [ "Enedis",[2.290664430963055, 48.804911353220035]],
    [ "CT Malakoff",[2.2870244036811713, 48.80606619828504]],
    [ "Enedis",[2.2852508046662665, 48.80210878899712]],
    [ "CT Stalingrad 7",[2.2986133373042352, 48.810888050320415]],
    [ "Enedis",[2.2795679769595676, 48.80105323118879]],
    [ "Enedis",[2.2888053982171597, 48.8079140690205]],
    [ "CT Pierrelais Bat D",[2.2889444117428694, 48.797963829270635]],
    [ "Enedis",[2.298100157469653, 48.8104484822554]],
    [ "Enedis",[3.9818417571180764, 49.34342532557899]],
    [ "Enedis",[3.9844272129095315, 49.34350554912819]],
    [ "Enedis",[3.97651625, 49.34711849999999]],
    [ "Enedis",[3.9807992816545776, 49.34201395370802]],
    [ "87459078",[5.962743349999999, 47.2862116]],
    [ "Enedis",[5.990968039548022, 47.28268635028249]],
    [ "Enedis",[5.9917987511111095, 47.28526393333333]],
    [ "87460995",[5.969297269234794, 47.28426340706235]],
    [ "Enedis",[4.284483114691673, 45.391059199083195]],
    [ "Enedis",[4.287402053109714, 45.38233779280224]],
    [ "Enedis",[4.302034810740012, 45.381788956777996]],
    [ "Enedis",[4.284410235001412, 45.39108277177355]],
    [ "Enedis",[-2.06171375, 46.796663499999994]],
    [ "Enedis",[-2.04312875, 46.78767225]],
    [ "Enedis",[-2.057876366666666, 46.7929475]],
    [ "Enedis",[-2.03297892221637, 46.788511941401026]],
    [ "Enedis",[-2.0894508056432968, 46.793149132632195]],
    [ "Enedis",[-2.0316324999999997, 46.786094750000004]],
    [ "Enedis",[-1.9961542391890719, 46.76275271530017]],
    [ "Enedis",[-2.0474752499999997, 46.78826275000001]],
    [ "Enedis",[-2.0943832499999995, 46.8061025]],
    [ "Enedis",[-2.0283404999999997, 46.79307669999999]],
    [ "Enedis",[-2.07116625, 46.79752775000001]],
    [ "Enedis",[-2.0644862032449804, 46.79257418895373]],
    [ "Enedis",[-2.0679458500000005, 46.79681225]],
    [ "Enedis",[-2.053213788665121, 46.796965125165]],
    [ "Enedis",[-2.0427175, 46.786271500000005]],
    [ "Enedis",[-2.05534161416643, 46.795068649314025]],
    [ "Enedis",[-2.022773746136792, 46.781820717114826]],
    [ "Enedis",[-2.075376, 46.79903074999999]],
    [ "Enedis",[-2.0476483999999995, 46.79318415]],
    [ "Enedis",[-2.0387915000000008, 46.794890249999995]],
    [ "Enedis",[-2.027829821283085, 46.78988648410647]],
    [ "Enedis",[-2.079258, 46.800661250000005]],
    [ "Enedis",[-2.0141329999999997, 46.778636]],
    [ "Enedis",[-2.0502085, 46.796176249999995]],
    [ "Enedis",[-2.0283306999999993, 46.79312505]],
    [ "Enedis",[-2.0616134144325837, 46.792490670543465]],
    [ "Enedis",[-2.0588870000000004, 46.79794724999999]],
    [ "Enedis",[-2.0221335, 46.785578]],
    [ "Enedis",[-2.04366015, 46.79301205]],
    [ "Enedis",[-2.0390768, 46.785947199999995]],
    [ "Enedis",[-1.98642795, 46.76414695]],
    [ "Enedis",[-2.044857300000001, 46.78860275000001]],
    [ "Enedis",[-2.0541687499999997, 46.79347905000001]],
    [ "Enedis",[-2.0209693500000006, 46.780124400000005]],
    [ "Enedis",[-2.066700499999999, 46.795042499999994]],
    [ "Enedis",[-2.0671965, 46.7976765]],
    [ "Enedis",[-2.04893725, 46.794869750000004]],
    [ "Enedis",[-2.0286429443847, 46.786394010695055]],
    [ "Enedis",[-2.0685505, 46.79883575]],
    [ "Enedis",[-2.0578117113743346, 46.7960420252276]],
    [ "Enedis",[-2.0370279805575544, 46.788831495618304]],
    [ "Enedis",[-2.005856, 46.77088075]],
    [ "Enedis",[-2.0490229999999996, 46.79122949999999]],
    [ "Enedis",[-2.0400149398228007, 46.7894084212695]],
    [ "Enedis",[-2.0351227500000006, 46.79106350000001]],
    [ "Enedis",[-2.02375865, 46.79000195]],
    [ "Enedis",[-2.0546045, 46.791419999999995]],
    [ "Enedis",[6.12530409294653, 47.109138400227536]],
    [ "Enedis",[2.0533014629767954, 48.93374762973436]],
    [ "PSA",[2.046948586134775, 48.943905343540095]],
    [ "PSA",[2.0468441076106263, 48.943789317193556]],
    [ "SRD",[0.18834065068987216, 46.42035027912529]],
    [ "87680798",[0.5571380924118436, 46.74047835289238]],
    [ "Enedis",[0.34199778120411156, 46.61138508957415]],
    [ "SRD",[0.5772001155089717, 46.75488542652088]],
    [ "Enedis",[0.34023101973401976, 46.60688966066066]],
    [ "Enedis",[0.3412590000000005, 46.613125999999994]],
    [ "Enedis",[0.34395447081956904, 46.61527482507472]],
    [ "Enedis",[0.33837265315315396, 46.614029710810804]],
    [ "Enedis",[0.3305656274509809, 46.60588767450981]],
    [ "Enedis",[0.32832863568773396, 46.60524867162329]],
    [ "Enedis",[0.3327613502164502, 46.606811020779226]],
    [ "SRD",[0.30858975501154173, 46.27162071895275]],
    [ "SRD",[1.0334980978496382, 46.46179783039046]],
    [ "SRD",[1.0400283501038199, 46.4651449755129]],
    [ "SRD",[0.20357134053008827, 46.36684813099294]],
    [ "SRD",[0.3022946448257825, 46.65722876735791]],
    [ "SRD",[0.3295478579746636, 46.65891852404454]],
    [ "SRD",[0.31365490420184444, 46.66461579565419]],
    [ "SRD",[0.3117972676491826, 46.32581146477805]],
    [ "Enedis",[2.1121411000162285, 47.21412550568903]],
    [ "Enedis",[2.0973992512000974, 47.228077011062624]],
    [ "Enedis",[5.4349201384128, 43.531313825837906]],
    [ "Enedis",[5.386944034882603, 43.945934954454145]],
    [ "Poste électrique Simon 4",[6.923798666580666, 49.213426151720434]],
    [ "SRD",[0.7025305647505633, 46.808648466204694]],
    [ "Enedis",[5.457808847783615, 43.52166873669622]],
    [ "Parc Jourdan",[5.449672891794467, 43.5195265893485]],
    [ "Enedis",[5.444784032877581, 43.521201599363245]],
    [ "Sainte-Anne",[5.460195755208239, 43.52014487708712]],
    [ "Mascotte",[5.461405153019489, 43.512876590695356]],
    [ "Arc de Meyran",[5.4553542384770015, 43.513569574561515]],
    [ "Puget",[5.453490368223437, 43.519395727124085]],
    [ "Enedis",[5.446795322007518, 43.52188253636433]],
    [ "Saint-Benoit",[5.462820510987649, 43.51413165165392]],
    [ "Sup Mche St Jme",[5.461801740069166, 43.51670893962117]],
    [ "Enedis",[5.44399224497811, 43.52266121142177]],
    [ "Enedis",[5.459792449109128, 43.51783682413965]],
    [ "SRD",[0.874815967402969, 46.557406661809445]],
    [ "SRD",[0.8792176025231065, 46.56580467329729]],
    [ "Enedis",[-0.2190854999999997, 45.86694345000001]],
    [ "Enedis",[4.0215650592477665, 49.27108376911501]],
    [ "Enedis",[4.001754946699253, 49.26034648016957]],
    [ "Enedis",[4.020506210912835, 49.25597334261068]],
    [ "Enedis",[4.0289676000000005, 49.27283195]],
    [ "Enedis",[4.022138608389789, 49.2651042568155]],
    [ "Enedis",[4.02458916267666, 49.261016878934214]],
    [ "Enedis",[4.011782298442611, 49.269993946026545]],
    [ "Enedis",[4.012060734559337, 49.257739485853605]],
    [ "Enedis",[4.014313708009603, 49.26452038700732]],
    [ "Enedis",[4.026107678171722, 49.28347474398439]],
    [ "Enedis",[4.00250110933323, 49.28692990286988]],
    [ "Enedis",[4.004970988806206, 49.259196473558525]],
    [ "Enedis",[4.005674512610238, 49.282818513241125]],
    [ "Enedis",[4.002240600481958, 49.263938124873796]],
    [ "Enedis",[3.997459907759787, 49.29623225563313]],
    [ "Enedis",[4.005021424933997, 49.26111099266647]],
    [ "Enedis",[4.02238219728511, 49.269658281248354]],
    [ "Enedis",[4.000590647863374, 49.273572795633136]],
    [ "Enedis",[4.015219716563857, 49.25481614719942]],
    [ "Enedis",[4.004627613358133, 49.26262159889295]],
    [ "Enedis",[4.018008129203998, 49.274748794935206]],
    [ "Enedis",[4.004388323714213, 49.284870449395456]],
    [ "Enedis",[4.01886984820892, 49.259181539474696]],
    [ "Enedis",[4.0102869627994036, 49.27733218760369]],
    [ "CEAIR SEPT",[2.071726511688231, 48.67111364908969]],
    [ "Enedis",[2.06792006040568, 48.67262216674936]],
    [ "Enedis",[2.0707837232788013, 48.67415212353785]],
    [ "Enedis",[6.23202909726436, 46.1957418082955]],
    [ "SRD",[0.545627260995626, 46.856326093432095]],
    [ "SRD",[0.5465690375176067, 46.85436053571082]],
    [ "SRD",[0.5433679747293498, 46.85419156676834]],
    [ "Enedis",[0.329375, 46.599043]],
    [ "Enedis",[0.33513690133879115, 46.60284037784599]],
    [ "Enedis",[0.3248984548933845, 46.60038718571168]],
    [ "Enedis",[0.33220648137384395, 46.58326504460589]],
    [ "Enedis",[0.32150999999999996, 46.59648500000001]],
    [ "Enedis",[0.3282976668963937, 46.592510176200314]],
    [ "Enedis",[0.3289862604588399, 46.58394571524966]],
    [ "SRD",[0.865199948416897, 46.56569333873398]],
    [ "SRD",[0.8625303374269006, 46.567224666666675]],
    [ "Enedis",[0.3300339777247412, 46.59661462795016]],
    [ "SRD",[0.8680810441872311, 46.5719032895894]],
    [ "Enedis",[0.32822469575334456, 46.60252465406244]],
    [ "Enedis",[0.3264573231372549, 46.58874901490196]],
    [ "Enedis",[0.33127411421410635, 46.59588084317937]],
    [ "Enedis",[0.33925499548787347, 46.601276528482806]],
    [ "Enedis",[0.3440373375281171, 46.60102278369506]],
    [ "SRD",[0.5902095215450187, 46.412057839312254]],
    [ "SRD",[0.5901153995421841, 46.40644138986952]],
    [ "SRD",[0.5978162791163641, 46.41055435030514]],
    [ "SRD",[0.5931192819364316, 46.417625518770706]],
    [ "SRD",[0.5919455499999999, 46.409013650000006]],
    [ "SRD",[0.5901655904654807, 46.41768014862331]],
    [ "SRD",[0.5925235669446298, 46.41308935449826]],
    [ "SRD",[0.5986565175605062, 46.39328067798776]],
    [ "Enedis",[-1.7147962413326867, 48.04527506698692]],
    [ "Enedis",[-1.7086682664594428, 48.0462616331881]],
    [ "Enedis",[-1.6972602, 48.03678450000001]],
    [ "Enedis",[-1.7111785607727568, 48.043858707825805]],
    [ "Enedis",[-1.6911294018682745, 48.04077552550126]],
    [ "SRD",[0.15539650935081148, 46.71664325689139]],
    [ "SRD",[0.16042579210143915, 46.72014062667698]],
    [ "Enedis",[-1.592593762032525, 47.96033749987861]],
    [ "Enedis",[-1.5547435428270937, 48.00027435543051]],
    [ "Enedis",[-1.6027158318878267, 47.971496223692]],
    [ "Enedis",[0.367871, 46.5623085]],
    [ "Enedis",[0.3687932848811127, 46.56539717406908]],
    [ "Enedis",[0.3710605, 46.561428]],
    [ "Enedis",[0.37247750000000024, 46.569187]],
    [ "Enedis",[0.39486832666666666, 46.556752323333335]],
    [ "Enedis",[0.3787226245392319, 46.56280073407056]],
    [ "Enedis",[0.36502973659343196, 46.56443285022442]],
    [ "Enedis",[0.3911688333333333, 46.571467000000005]],
    [ "Enedis",[0.3773481884245305, 46.577064712623866]],
    [ "Enedis",[0.38159040154083207, 46.57272838572161]],
    [ "Enedis",[0.39442375458341977, 46.57395447610958]],
    [ "Enedis",[0.3922901671977905, 46.55835299511365]],
    [ "Enedis",[0.38846507239819034, 46.57566969155354]],
    [ "Enedis",[0.37355368377581116, 46.577119388790564]],
    [ "Enedis",[0.39387211330049254, 46.57138140886699]],
    [ "Enedis",[0.37117100000000064, 46.571735999999994]],
    [ "Enedis",[0.37423462853595824, 46.55938643078834]],
    [ "Enedis",[0.37488351924587593, 46.567345537051594]],
    [ "Enedis",[0.3923638214978662, 46.57441583779588]],
    [ "Enedis",[0.3914738342558427, 46.56004048554736]],
    [ "Enedis",[0.3936719999999999, 46.56056449999999]],
    [ "Enedis",[0.3781443499999987, 46.576977799999995]],
    [ "Enedis",[0.37232302697699043, 46.56753182156396]],
    [ "Enedis",[0.36958919044658617, 46.5619977954616]],
    [ "Enedis",[0.37382750000000003, 46.563683999999995]],
    [ "Enedis",[0.39437278287461697, 46.55867294189601]],
    [ "Enedis",[0.3718159656799655, 46.56256667202917]],
    [ "Enedis",[0.373884528548124, 46.563186985318104]],
    [ "Enedis",[6.077609160432254, 47.27001968994181]],
    [ "Enedis",[4.024221059196098, 49.21710368242833]],
    [ "Enedis",[4.037784130853805, 49.23469699305816]],
    [ "J B Clement",[4.005869075367462, 49.24414477753547]],
    [ "Enedis",[4.007141791489212, 49.22690273516327]],
    [ "Enedis",[4.024734697363087, 49.23611298881893]],
    [ "Enedis",[4.03417485, 49.235136100000005]],
    [ "Enedis",[4.015024251528942, 49.231757915541536]],
    [ "beaulieu",[4.0266446547275745, 49.21586483041281]],
    [ "gide 14",[4.0295680455215335, 49.21909866483695]],
    [ "Enedis",[4.020656507915844, 49.21488776322707]],
    [ "Enedis",[4.03974295, 49.23214050000001]],
    [ "Edmond Rostand",[4.021687584566024, 49.21761903555298]],
    [ "bonne femme",[4.0248112289652545, 49.23772200549504]],
    [ "Gide",[4.028226972856243, 49.21934290087485]],
    [ "Enedis",[4.002463023691685, 49.24110114461288]],
    [ "Andaine",[0.19871263300080608, 48.01828520465766]],
    [ "Hameau des Marches",[0.2053690867849905, 48.0286223687184]],
    [ "ZI Coulaines",[0.19709167459904117, 48.027240003409474]],
    [ "NTI Coulaines",[0.1997551, 48.02724765]],
    [ "Chatelier",[0.21531569999999994, 48.035611249999995]],
    [ "Monaco",[0.19803003702661262, 48.023316080855814]],
    [ "Champ Fleuri",[0.2003169917731967, 48.028997071790876]],
    [ "Gironde",[0.20561316324489848, 48.025628829927186]],
    [ "Saint-Germain",[0.20159902437804378, 48.02666105602317]],
    [ "La Closerie",[0.20765007995700055, 48.031293107256076]],
    [ "Coulaines Bourg",[0.20459032306480196, 48.023271650337676]],
    [ "Ruelle Verte",[0.2083879683937776, 48.024995838230325]],
    [ "Le Ponceau",[0.21414963451466998, 48.02589623553062]],
    [ "Hameau du Plessis",[0.2068078518589285, 48.028156685038425]],
    [ "Rue du Bosquet",[0.21112941205542973, 48.03105461507847]],
    [ "Boileau",[0.19967101369145887, 48.020591075367555]],
    [ "Saint-Nicolas",[0.20268929793955037, 48.03021363228487]],
    [ "Enedis",[0.4023666535947712, 46.604447457516336]],
    [ "Enedis",[0.3744169999999992, 46.589815]],
    [ "Enedis",[0.37179866666666667, 46.58855899999999]],
    [ "Enedis",[0.3748037478958698, 46.58834596388726]],
    [ "Enedis",[0.3758093839557381, 46.58074289294606]],
    [ "Enedis",[0.38236252187748604, 46.588284337311066]],
    [ "Enedis",[0.37445539499553226, 46.585811212179244]],
    [ "Enedis",[0.40326669999999976, 46.58098144999999]],
    [ "Enedis",[0.37465917429911116, 46.59125374558515]],
    [ "Enedis",[0.37295344903581357, 46.58440828374656]],
    [ "Enedis",[0.36682174761233727, 46.5878770323313]],
    [ "Enedis",[0.38014662240299796, 46.585789420544636]],
    [ "Enedis",[0.3937333482779095, 46.59675217290182]],
    [ "Enedis",[0.4056400388888884, 46.59343870555556]],
    [ "Enedis",[4.102616500000002, 50.069493]],
    [ "Enedis",[4.100759612939053, 50.0596145313064]],
    [ "Enedis",[3.1199303047351146, 50.40150534302234]],
    [ "Enedis",[3.1383068217353944, 50.400002981099654]],
    [ "Enedis",[4.9472946, 43.92126595]],
    [ "Enedis",[4.9434693709681605, 43.924011575636165]],
    [ "Enedis",[4.957913974114038, 43.92968764195141]],
    [ "Enedis",[4.935933499999999, 43.93010815000001]],
    [ "Enedis",[4.948152196519302, 43.926551495304956]],
    [ "Enedis",[0.917897380470439, 46.99027312875934]],
    [ "SRD",[0.2645610890944974, 46.344038845373795]],
    [ "Enedis",[-1.8381863532292624, 46.761274322518105]],
    [ "Enedis",[-1.8424100000000003, 46.760480900000005]],
    [ "Enedis",[-1.8362008500000002, 46.76317564999999]],
    [ "Enedis",[-1.847821500353748, 46.76068023332819]],
    [ "Enedis",[-1.7984525999999996, 46.74934655]],
    [ "Enedis",[-1.8589102449111654, 46.7707754171562]],
    [ "Enedis",[3.7169659999999993, 47.820698]],
    [ "Poste Manuel",[5.453705803182109, 43.52829419311653]],
    [ "Sextius",[5.444063957870997, 43.52894888740016]],
    [ "Gambetta",[5.453380273249766, 43.52464266057608]],
    [ "Enedis",[1.339918849999998, 47.618671150000004]],
    [ "Enedis",[1.3402680082311165, 47.61679243901121]],
    [ "Enedis",[2.6692636666666663, 50.472873929824566]],
    [ "Enedis",[2.6807464822190608, 50.465286333807484]],
    [ "Enedis",[2.659020284837324, 50.481438336402704]],
    [ "Enedis",[2.6682402954100826, 50.4688453420617]],
    [ "Enedis",[2.6775305439518586, 50.46646767557891]],
    [ "Enedis",[2.671736355948869, 50.46902923289789]],
    [ "Enedis",[2.6703983333333334, 50.483970982683985]],
    [ "Enedis",[2.6732090304073437, 50.48194859495124]],
    [ "Enedis",[2.656099273636364, 50.475269568484855]],
    [ "Enedis",[2.6523193207818925, 50.474243932304525]],
    [ "Enedis",[2.6658493851851848, 50.478274199999994]],
    [ "Enedis",[2.677140730305363, 50.47411763843984]],
    [ "Enedis",[2.6615794999999993, 50.47737800000001]],
    [ "Enedis",[2.6704079514611188, 50.46564794914975]],
    [ "Enedis",[2.656401365384616, 50.47721607649573]],
    [ "Enedis",[2.6601855, 50.484517000000004]],
    [ "Enedis",[2.6698493333333335, 50.48172085057472]],
    [ "Enedis",[2.66030038095238, 50.481047723588034]],
    [ "Enedis",[2.6751075000000006, 50.475559]],
    [ "Enedis",[2.6763323344827588, 50.463533009195416]],
    [ "Enedis",[2.6650918646698547, 50.47590784075845]],
    [ "Enedis",[2.6727105000000004, 50.4873285]],
    [ "Enedis",[2.6663325390070924, 50.48241566666666]],
    [ "Enedis",[2.671061250300223, 50.47866484782982]],
    [ "Enedis",[2.6631392808837657, 50.47252544149856]],
    [ "Enedis",[2.67309535033394, 50.486721476624176]],
    [ "Enedis",[2.658851603949827, 50.474712226848155]],
    [ "Enedis",[2.6691325, 50.475612500000004]],
    [ "Enedis",[2.6749290240099777, 50.465611346741504]],
    [ "Enedis",[2.673688, 50.46244749999999]],
    [ "Enedis",[2.6651801463414637, 50.4634865691057]],
    [ "Enedis",[2.667560597007947, 50.47409083683965]],
    [ "Enedis",[2.6622794076372305, 50.46977832999205]],
    [ "Enedis",[2.663231946175637, 50.48562131916903]],
    [ "Enedis",[2.682590475512676, 50.47683668402264]],
    [ "Enedis",[2.241806177716605, 43.584800049963114]],
    [ "Poste électrique de Gourjade",[2.2529839507408096, 43.62310871704036]],
    [ "SRD",[0.4737735532286214, 46.715949427574174]],
    [ "SRD",[0.4446085335608651, 46.71910721501708]],
    [ "Enedis",[5.408803229937227, 43.507746140500046]],
    [ "Enedis",[2.087421554235946, 47.22089911733967]],
    [ "Poste électrique de Bléville",[0.10475481158176306, 49.52699012625303]],
    [ "Enedis",[4.094470238216201, 50.05876079621789]],
    [ "SRD",[0.24133039869517492, 46.767439865995954]],
    [ "Enedis",[4.0265575, 48.321045500000004]],
    [ "Enedis",[4.035035082982254, 48.323857448359504]],
    [ "Enedis",[4.036201863492064, 48.32491119682539]],
    [ "Enedis",[4.0345337535014005, 48.32527712324929]],
    [ "Enedis",[4.030353948166666, 48.32252632466666]],
    [ "Enedis",[4.035919471940518, 48.321489558284014]],
    [ "Enedis",[4.03533286076696, 48.32088541356933]],
    [ "Enedis",[4.035560913877266, 48.32322931799164]],
    [ "Enedis",[4.05210905, 48.31989]],
    [ "Enedis",[-1.93002075, 46.687665]],
    [ "Enedis",[-1.9335760500000003, 46.6995178]],
    [ "Enedis",[-1.9286695000000007, 46.69748575]],
    [ "Enedis",[-1.9536423, 46.6960388]],
    [ "Enedis",[-1.9217105, 46.69537575]],
    [ "Enedis",[-1.9214875, 46.682724]],
    [ "Enedis",[-1.9206162191422733, 46.69706800384048]],
    [ "Enedis",[-1.9306782499999997, 46.698345249999996]],
    [ "Enedis",[-1.955869870188847, 46.69488890507908]],
    [ "SRD",[0.6400462000000011, 46.87003655]],
    [ "Enedis",[-1.9148822500000002, 46.693006749999995]],
    [ "Enedis",[-1.9048569499999999, 46.69399775]],
    [ "Enedis",[-1.93014925, 46.68528150000001]],
    [ "Enedis",[-1.9176545000000003, 46.6813565]],
    [ "Enedis",[-1.9303331669178219, 46.69154654739078]],
    [ "Enedis",[-1.91669675, 46.690759]],
    [ "Enedis",[-1.9143891703939622, 46.68961944498783]],
    [ "Enedis",[-1.9264457499999996, 46.6895595]],
    [ "Enedis",[-1.9117607499999998, 46.69652790000001]],
    [ "Enedis",[-1.9390440500000008, 46.6987135]],
    [ "Enedis",[-1.9258500866045547, 46.69590088896139]],
    [ "Enedis",[-1.9367627499999998, 46.69155925]],
    [ "Enedis",[-1.9417577499999998, 46.69995425]],
    [ "Enedis",[-1.922099861674527, 46.68676705339453]],
    [ "Enedis",[-1.9023482999999999, 46.690219799999994]],
    [ "Enedis",[-1.9562769499999992, 46.70153345]],
    [ "Enedis",[-1.94944475, 46.698453]],
    [ "Enedis",[-1.9142198000000001, 46.686470050000004]],
    [ "Enedis",[-1.938895047775511, 46.69020828282941]],
    [ "Enedis",[-1.9384229216520041, 46.69643219100684]],
    [ "Enedis",[-1.9130205849904767, 46.67620978206206]],
    [ "Enedis",[-1.9412157500000005, 46.692556]],
    [ "Enedis",[-1.9473596389094332, 46.703071328423334]],
    [ "Enedis",[-1.9257709, 46.69211674999999]],
    [ "Enedis",[-1.9198272499999993, 46.6930095]],
    [ "Enedis",[-1.932909733533673, 46.69579172618277]],
    [ "Enedis",[-1.952687034150346, 46.69926479892634]],
    [ "Enedis",[-1.9432946061649383, 46.697905251005494]],
    [ "Enedis",[-1.906537578162578, 46.690322110485816]],
    [ "Enedis",[-1.90733425, 46.69238675]],
    [ "Enedis",[-1.9363552499999992, 46.689036849999994]],
    [ "Enedis",[-1.9127175, 46.68970225]],
    [ "Enedis",[-1.9247200000000007, 46.688712499999994]],
    [ "Enedis",[-1.9265032500000006, 46.698144400000004]],
    [ "Enedis",[-1.918130716783, 46.683791300568735]],
    [ "Enedis",[-1.9148338500000002, 46.6777946]],
    [ "Enedis",[-1.91198175, 46.69215775000001]],
    [ "Poste électrique de Meyrargues",[5.513697794660359, 43.64372774404431]],
    [ "Enedis",[4.097406379331811, 50.05736681981046]],
    [ "Poste électrique des Mureaux",[1.895018099420514, 48.992865562431774]],
    [ "Poste électrique de Flins",[1.8556495840584786, 48.98379901725419]],
    [ "Poste électrique de Montalet",[1.7864342419996229, 48.977152383503594]],
    [ "Poste électrique de Porcheville",[1.7627504811047081, 48.97139507572105]],
    [ "Enedis",[6.5379386324773225, 44.82369147541249]],
    [ "Enedis",[6.5342058206561155, 44.8244094976499]],
    [ "Enedis",[6.560913523544653, 44.80853139633069]],
    [ "Enedis",[0.6852251798703861, 46.97438777515562]],
    [ "Enedis",[0.6740387685624623, 46.9753641387164]],
    [ "Enedis",[0.6764105427163485, 46.97439417722551]],
    [ "Enedis",[0.6740941355310429, 46.9761576609395]],
    [ "Enedis",[0.6477507000000006, 46.9757528]],
    [ "Enedis",[0.678480864564632, 46.97660135957618]],
    [ "Poste électrique de Mézerolles",[1.7414665298091014, 48.93301538081487]],
    [ "Enedis",[6.165166627831715, 48.65187110097087]],
    [ "Enedis",[6.133991841800152, 48.65397431624715]],
    [ "Enedis",[6.145831388432737, 48.64856304298993]],
    [ "Enedis",[6.159494299057793, 48.65055867623711]],
    [ "Enedis",[6.139845992389006, 48.65145485271318]],
    [ "Enedis",[6.156974366445916, 48.65123307947019]],
    [ "Enedis",[6.156143355269573, 48.65049866149946]],
    [ "Enedis",[6.159996540738686, 48.65365682243658]],
    [ "Enedis",[6.1528531344109085, 48.65359361474275]],
    [ "Enedis",[6.153320350193395, 48.64927075711594]],
    [ "Enedis",[6.1530187279884, 48.64783752558525]],
    [ "Enedis",[6.136877888831747, 48.65355426039257]],
    [ "Enedis",[6.1311446752624175, 48.65589547260104]],
    [ "Enedis",[6.148472669259925, 48.65547645461799]],
    [ "Enedis",[6.159670407025368, 48.64874080773163]],
    [ "Enedis",[6.162824178826894, 48.650711038626596]],
    [ "Enedis",[6.15674709671848, 48.64929624122049]],
    [ "SRD",[0.26908104584972276, 46.150205753666164]],
    [ "SRD",[0.25291142745255585, 46.16759704762339]],
    [ "Enedis",[6.165231638544799, 48.660859354399676]],
    [ "Enedis",[6.174244094046793, 48.65423729766042]],
    [ "Enedis",[6.162373828282829, 48.655496946127954]],
    [ "Enedis",[6.177233832278729, 48.65732851863137]],
    [ "Enedis",[6.1668363727551485, 48.66377166053439]],
    [ "Enedis",[6.161826431038738, 48.661437166157775]],
    [ "Enedis",[6.166156988931931, 48.66567352628667]],
    [ "Enedis",[6.173990824880599, 48.65220434440149]],
    [ "Enedis",[6.168733852961198, 48.65566812707057]],
    [ "Enedis",[6.166808, 48.6637325]],
    [ "Enedis",[6.169550413756891, 48.652961683206435]],
    [ "Enedis",[6.168192845833333, 48.66161251762821]],
    [ "Enedis",[6.174682669827889, 48.65711406006323]],
    [ "Enedis",[6.15721817096703, 48.65727266297404]],
    [ "Enedis",[4.104464175205565, 50.05903020910815]],
    [ "Enedis",[6.133423828811051, 48.65119399440882]],
    [ "Promogim",[7.0831578, 43.61615885000001]],
    [ "Résidence Étudiants",[7.076005731645723, 43.61756958413161]],
    [ "Enedis",[6.17990513624756, 48.654289507135346]],
    [ "Enedis",[6.185783743659421, 48.65344594456522]],
    [ "Enedis",[6.179404681686648, 48.65754885448533]],
    [ "SRD",[0.3173129006999217, 46.4673740181559]],
    [ "Enedis",[6.180997785471827, 48.65789404073319]],
    [ "Gendarmerie",[1.2931281666666665, 43.825533099999994]],
    [ "Nipolis",[7.080545700000001, 43.61197455]],
    [ "Tandurat",[1.294393117974755, 43.82343114446978]],
    [ "Enedis",[6.189543807910793, 48.66377054646188]],
    [ "ORTF",[6.191856450000001, 48.66765285]],
    [ "Enedis",[6.190759, 48.660903999999995]],
    [ "OPAM",[7.079620606745795, 43.6158051777448]],
    [ "Enedis",[-4.236705617035899, 48.43477982015462]],
    [ "Enedis",[-4.232013514509103, 48.43956442591292]],
    [ "Enedis",[-4.23066435, 48.45453204999999]],
    [ "Poste électrique d’Étupes",[6.847868776808242, 47.507382823617384]],
    [ "VA Paul Doumer",[6.186540282989468, 48.67445344877114]],
    [ "SRD",[0.8882066468691159, 46.539325090490934]],
    [ "SRD",[0.8515589413443452, 46.53226919752467]],
    [ "SRD",[0.6899373354997136, 46.40329941469925]],
    [ "SRD",[0.6921885685691, 46.41041447896989]],
    [ "SRD",[0.6864970100265522, 46.407131522521624]],
    [ "SRD",[0.21683746830708675, 46.919640121325465]],
    [ "SRD",[0.06843115, 46.46585925]],
    [ "SRD",[-0.0046959054078065405, 46.49793923311606]],
    [ "Enedis",[0.8863987653387772, 46.694531162378325]],
    [ "Enedis",[6.667460697062653, 49.25099386080418]],
    [ "Enedis",[6.668116878294227, 49.248183354937424]],
    [ "Enedis",[6.661978557106159, 49.25217541753497]],
    [ "Enedis",[6.657284344496675, 49.25300700661474]],
    [ "Enedis",[6.657829881197565, 49.24760269004815]],
    [ "SRD",[0.6023450111577953, 46.44055912967079]],
    [ "Enedis",[0.8184155652861009, 47.35475946591601]],
    [ "Enedis",[0.8078334370911304, 47.35909644244574]],
    [ "Enedis",[0.8040711897171351, 47.35726214772058]],
    [ "Enedis",[0.826522501661308, 47.35221533269024]],
    [ "Enedis",[0.8284693608180138, 47.35349038638592]],
    [ "Enedis",[0.8256657044412762, 47.350060748266486]],
    [ "Enedis",[0.8155980144950732, 47.352662703974005]],
    [ "Enedis",[0.8079578850548423, 47.355970340224886]],
    [ "Enedis",[0.8034378800663694, 47.353726989167335]],
    [ "Enedis",[6.640575178899581, 49.27341453140654]],
    [ "SRD",[0.11334063700833183, 46.94783139981269]],
    [ "SRD",[0.08040080044405117, 46.94744762130615]],
    [ "SRD",[0.11502843653006477, 46.94419586770822]],
    [ "SRD",[0.21999513551047334, 46.70519954375722]],
    [ "SRD",[0.07365895, 46.55740205]],
    [ "SRD",[0.07817335450156931, 46.558266485662564]],
    [ "Enedis",[-4.248026449010749, 48.482534126970315]],
    [ "RTE",[5.778333893320165, 43.83144567411065]],
    [ "Enedis",[-4.245369779100339, 48.48923484240164]],
    [ "Enedis",[-4.279319246300088, 48.47917033291411]],
    [ "Enedis",[-4.243663804791084, 48.48455086601201]],
    [ "Église",[-1.8528269500000005, 48.17670725000001]],
    [ "Enedis",[6.871419646317186, 47.514834818403]],
    [ "Enedis",[6.851304499999999, 47.5097575]],
    [ "Enedis",[6.856824076233183, 47.5102567683109]],
    [ "Enedis",[6.87625, 47.516317]],
    [ "Enedis",[6.865551227659086, 47.51252011647139]],
    [ "Enedis",[6.859381208365608, 47.51771309656596]],
    [ "Enedis",[6.868234431406385, 47.51356936180616]],
    [ "Vesonnes",[6.258641797854753, 45.76662944734632]],
    [ "Enedis",[-4.330997331783479, 48.479696774657]],
    [ "Enedis",[-4.331754657943523, 48.44995142081627]],
    [ "89445017",[-0.5307865520679447, 45.946105601747554]],
    [ "Enedis",[4.92374, 43.95280180000001]],
    [ "Enedis",[4.938600885877617, 43.95813811715194]],
    [ "Enedis",[4.937364330645161, 43.95909119552688]],
    [ "Enedis",[4.923148699999999, 43.965337850000004]],
    [ "Enedis",[4.941349450000001, 43.957071150000004]],
    [ "Enedis",[4.929839682058556, 43.95873906781731]],
    [ "Enedis",[4.9348747100901695, 43.95759892539191]],
    [ "Enedis",[4.9254663999999995, 43.95622625]],
    [ "Enedis",[4.925968375068714, 43.96252337927131]],
    [ "Enedis",[-4.2313993000000005, 48.4441374]],
    [ "Enedis",[-4.2601283, 48.43841749999999]],
    [ "Enedis",[-4.25006056644864, 48.462139121078614]],
    [ "Enedis",[-4.253805892521014, 48.46236704922453]],
    [ "Enedis",[-4.257385482517494, 48.46460427972168]],
    [ "Enedis",[-4.261969773397484, 48.461142554196265]],
    [ "Enedis",[-4.267484685425625, 48.46621285545126]],
    [ "Enedis",[-4.2589563122136465, 48.459797168615715]],
    [ "Enedis",[2.4167815012722644, 49.37772875318066]],
    [ "Enedis",[2.429925594655688, 49.38157229377573]],
    [ "Enedis",[2.409522837083861, 49.381978854193015]],
    [ "Enedis",[2.411356973486522, 49.381348820444835]],
    [ "Enedis",[6.853448784885896, 47.504471239805454]],
    [ "Enedis",[6.857373934497376, 47.50893211454514]],
    [ "Enedis",[6.862944784127986, 47.50736619440059]],
    [ "Enedis",[6.847225674257648, 47.505565094887245]],
    [ "Enedis",[6.846103500000001, 47.506447500000014]],
    [ "Enedis",[1.7638470987055013, 46.68654970711974]],
    [ "Enedis",[2.2523993455942315, 48.766535346153276]],
    [ "Enedis",[2.2799806205235758, 48.76344530043693]],
    [ "Enedis",[-4.272533038580956, 48.458540227195414]],
    [ "Enedis",[-4.268879059463533, 48.459331298019116]],
    [ "Enedis",[-4.264034616772306, 48.45973874782389]],
    [ "Enedis",[-4.255107953248235, 48.451360343375626]],
    [ "Enedis",[-4.279293817251698, 48.45248734896857]],
    [ "Enedis",[-4.264304593348875, 48.45278100875532]],
    [ "Enedis",[-4.252300105192841, 48.46090781905055]],
    [ "Enedis",[-4.270063507563026, 48.460764930120284]],
    [ "Enedis",[-4.276766289736775, 48.449072861035184]],
    [ "Enedis",[-4.276684500118495, 48.450395120257355]],
    [ "Enedis",[-4.271204572577952, 48.45037558061697]],
    [ "Enedis",[-4.250525286272195, 48.45895633577988]],
    [ "Enedis",[-4.254745965300361, 48.45688217631458]],
    [ "Enedis",[-4.242623720348124, 48.45579537134654]],
    [ "Enedis",[-4.2599471, 48.4572413]],
    [ "Enedis",[-4.24486456883174, 48.459340177153386]],
    [ "Enedis",[-4.264795452953365, 48.456253203707476]],
    [ "Enedis",[-4.241739329660217, 48.457933761785036]],
    [ "Enedis",[-4.246850206419967, 48.456618351684334]],
    [ "Enedis",[-4.256777980841638, 48.45531948748806]],
    [ "Enedis",[-4.242819896714778, 48.44940301719289]],
    [ "Enedis",[-4.246602913707292, 48.44950276341592]],
    [ "Enedis",[-4.249448033678961, 48.445533343889785]],
    [ "Enedis",[-4.259883809060931, 48.44521966603081]],
    [ "Enedis",[-4.26581962895087, 48.440369518982315]],
    [ "Enedis",[-4.261089103869662, 48.444611724937076]],
    [ "Enedis",[-4.263791664579782, 48.44401202000016]],
    [ "Enedis",[4.937204750009304, 44.09521425024899]],
    [ "Enedis",[4.940484221561074, 44.066007907184805]],
    [ "Enedis",[4.939588723401204, 44.08886200582844]],
    [ "Enedis",[4.971611074629783, 44.08302939131592]],
    [ "Enedis",[4.962509850681242, 44.08333158044691]],
    [ "Enedis",[4.935064759957045, 44.10865501190225]],
    [ "Enedis",[4.971742203527928, 44.08851409164291]],
    [ "Enedis",[6.723044288124801, 49.090239407671135]],
    [ "Enedis",[-4.247121205349646, 48.4522599144854]],
    [ "Enedis",[6.741815916434868, 49.09875322288679]],
    [ "Enedis",[6.738163999168971, 49.10972656840962]],
    [ "Enedis",[6.78432319088023, 49.09621253018759]],
    [ "Enedis",[6.74302664224099, 49.10137518186448]],
    [ "Enedis",[4.950529650000001, 44.07228725]],
    [ "Enedis",[6.726025625971415, 49.09436568434904]],
    [ "Enedis",[6.728814375614386, 49.09452896778884]],
    [ "Enedis",[6.737961980938311, 49.09931323552408]],
    [ "SRD",[0.47296499999999997, 46.59942100000001]],
    [ "SRD",[0.4684968084400186, 46.59875858179232]],
    [ "SER",[7.508041734951684, 48.45933117169922]],
    [ "Enedis",[-1.6321650000000003, 47.15759494999999]],
    [ "Enedis",[-4.24843168950613, 48.453249674400475]],
    [ "Enedis",[-4.250985982547266, 48.45133758359689]],
    [ "Enedis",[-4.252698400766759, 48.454850096350114]],
    [ "Enedis",[-4.25568294286013, 48.44905568197087]],
    [ "Enedis",[-4.251864878096638, 48.45010127487538]],
    [ "Enedis",[-4.280400838990239, 48.44146196685419]],
    [ "Enedis",[-4.268805535434824, 48.44857690927883]],
    [ "Enedis",[-4.203911894875062, 48.475764631324644]],
    [ "Enedis",[-4.2058239, 48.47251325]],
    [ "Rue Brémond d'Ars",[-3.5460737500000015, 47.874063250000006]],
    [ "Resid St Michel",[-3.5524157817109145, 47.87118294690266]],
    [ "Rue des Ajoncs",[-3.5497769999999997, 47.886641]],
    [ "Lotissement Kerneuzec",[-3.552323708379888, 47.88165494264431]],
    [ "Gorrequer",[-3.54363275, 47.876692]],
    [ "Lot Morvezan",[-3.5587677500000003, 47.8751355]],
    [ "Kerstrado",[-3.519068699999999, 47.873691]],
    [ "Lotissement Botlan",[-3.5484212815420557, 47.88434894548287]],
    [ "Thiers",[-3.5470392657569847, 47.87284038206627]],
    [ "Croix Kervail",[-3.57472445, 47.868937849999995]],
    [ "Roalis",[-3.5365042499999984, 47.87394685]],
    [ "Ty Bordeaux",[-3.5503197500000003, 47.8653515]],
    [ "Rue Terre de Vannes",[-3.5428347500000004, 47.872232000000004]],
    [ "Le Combout",[-3.544801457677445, 47.88168116516169]],
    [ "Beaubois",[-3.5607257881924714, 47.872033152921254]],
    [ "Petit Lichern",[-3.5314514999999997, 47.87349575]],
    [ "Bourgneuf",[-3.5430719999999996, 47.8695685]],
    [ "Bel Air",[-3.549348161604742, 47.86862628073654]],
    [ "Enedis",[-3.5529943855421697, 47.87274171485943]],
    [ "Enedis",[-3.571726, 47.869769]],
    [ "Enedis",[-3.5670679798030367, 47.866307228175586]],
    [ "ZA Villeneuve Braouic",[-3.5282936, 47.86304280000001]],
    [ "Cimetière",[-3.5375912635658904, 47.87115562790698]],
    [ "La Plaine",[-3.5483610499999996, 47.855721200000005]],
    [ "Rue Louis Noel",[-3.556077000000001, 47.872107500000006]],
    [ "Zone ind n°2",[-3.5324468453066946, 47.86239661793929]],
    [ "SRD",[0.7775878696913648, 46.5047931455434]],
    [ "SRD",[0.5598299999999993, 46.56264949999999]],
    [ "SRD",[0.1533482203493153, 46.7089761810207]],
    [ "SRD",[0.33677056030995683, 46.10841576722521]],
    [ "SRD",[0.41460318905144167, 46.25320918009421]],
    [ "SRD",[0.6907829216416415, 46.367540647447434]],
    [ "Enedis",[4.876027722236665, 44.0315528554157]],
    [ "Enedis",[2.042519545119307, 50.37209001648589]],
    [ "Enedis",[2.0311960000000004, 50.374520000000004]],
    [ "Enedis",[4.9027, 44.044052400000005]],
    [ "Enedis",[2.0390625, 50.370447500000004]],
    [ "Enedis",[4.891689894190256, 44.044017271420095]],
    [ "SRD",[0.5187411187033913, 46.47733297071763]],
    [ "SRD",[0.5145103588569624, 46.47948366956188]],
    [ "SRD",[0.5386015549380148, 46.48577980631318]],
    [ "Poste électrique de Graffenstaden",[7.683645221900069, 48.530411619442326]],
    [ "Enedis",[6.223407349999999, 46.18996865]],
    [ "Poste électrique Meinau",[7.734959157465034, 48.559305050844394]],
    [ "SER",[7.731111622473082, 48.57351507114437]],
    [ "Enedis",[0.7711475554915515, 46.84903727187021]],
    [ "Enedis",[0.7662047377445378, 46.84907749867676]],
    [ "SRD",[0.7527514900995524, 46.64291519513317]],
    [ "SRD",[0.11527303906787034, 46.53614141587891]],
    [ "Enedis",[4.81895503608889, 45.773457691555556]],
    [ "Enedis",[4.8172312999999995, 45.196541700000004]],
    [ "SRD",[0.6128612799475752, 46.77932448833552]],
    [ "Enedis",[0.1076415246195308, 49.51955877302831]],
    [ "Enedis",[1.3568322152155738, 47.61780750587603]],
    [ "SRD",[0.8974506499999999, 46.47822850000001]],
    [ "SRD",[0.6173974247102457, 46.73266631553746]],
    [ "SRD",[0.13967065, 46.55079985000001]],
    [ "90192010",[-2.0683173095866314, 47.86743253298153]],
    [ "Enedis",[5.790142500000001, 47.17643350000001]],
    [ "Enedis",[5.797119149999999, 47.18067490000001]],
    [ "Enedis",[5.798837050000001, 47.17764040000001]],
    [ "Enedis",[5.803578464553789, 47.18554959547845]],
    [ "Enedis",[0.3654315, 46.58595400000001]],
    [ "Enedis",[0.3638474243531293, 46.584334742901135]],
    [ "Enedis",[0.3637956012701181, 46.581716704393415]],
    [ "Enedis",[0.3525146492593523, 46.59239252883086]],
    [ "Enedis",[0.35577375434422026, 46.59210074446608]],
    [ "Enedis",[0.3614104999999992, 46.5857585]],
    [ "Enedis",[0.3483761669477235, 46.59305299550309]],
    [ "Enedis",[0.36160436850675753, 46.58722018058948]],
    [ "SRD",[0.2430837249890893, 46.104793942443706]],
    [ "SRD",[0.2320438616712536, 46.09982136654575]],
    [ "Enedis",[-2.9526558360477746, 47.63785296742672]],
    [ "SRD",[0.013518708083055043, 46.97028274409201]],
    [ "Enedis",[4.813437999951435, 45.19643391771879]],
    [ "Enedis",[0.10120001907955019, 49.52046688412184]],
    [ "Enedis",[0.1050431725416364, 49.520751755455336]],
    [ "Enedis",[4.8156031897222995, 45.203736257197164]],
    [ "Enedis",[0.10267010000000056, 49.51978494999999]],
    [ "La Bergère",[5.341222999999999, 45.93607900000001]],
    [ "Bourg Sud",[5.339318538569425, 45.938546328658255]],
    [ "Enedis",[5.69148845, 47.1293034]],
    [ "Enedis",[4.815813355850728, 45.77857474282099]],
    [ "Enedis",[4.815499862889122, 45.777358461525004]],
    [ "Enedis",[6.820041749563813, 49.151645586862564]],
    [ "Enedis",[6.80580028525429, 49.14274487376954]],
    [ "Enedis",[6.811664847789721, 49.14506742811838]],
    [ "Poste Château d'Eau Peyerimhoff",[6.774973021177784, 49.16010819113272]],
    [ "Enedis",[6.82645390050782, 49.1491103549164]],
    [ "Enedis",[6.8286663232287745, 49.151134749316505]],
    [ "Enedis",[6.822746587340482, 49.150501421577175]],
    [ "Enedis",[6.784021165147578, 49.14428743023749]],
    [ "Enedis",[6.779039099449694, 49.14308233347815]],
    [ "Enedis",[6.804663849875831, 49.15897301029997]],
    [ "Enedis",[6.8022758248737105, 49.141383180846674]],
    [ "Enedis",[6.8209224864827975, 49.147088535991905]],
    [ "SRD",[0.8664118178008464, 46.601780269765236]],
    [ "Enedis",[6.7714098422583575, 49.16093084954853]],
    [ "Enedis",[6.812791243666452, 49.1487814468948]],
    [ "Enedis",[6.81745043070019, 49.156778010174776]],
    [ "Enedis",[6.799552826625286, 49.142972495133854]],
    [ "Enedis",[6.8054877873745845, 49.14674910391325]],
    [ "Enedis",[6.8261845216014905, 49.147625448161946]],
    [ "Enedis",[6.806633505647987, 49.143534635431024]],
    [ "Enedis",[6.797602156053973, 49.15428434501421]],
    [ "Enedis",[6.791361473528233, 49.14403607873882]],
    [ "Enedis",[6.815845644199587, 49.153827520254694]],
    [ "Enedis",[6.807371233086105, 49.14953296500736]],
    [ "Enedis",[6.801264587349915, 49.14235269157998]],
    [ "Enedis",[6.805474540719425, 49.14232967088729]],
    [ "Enedis",[6.802382661554526, 49.14156286709911]],
    [ "Enedis",[6.818884480692861, 49.15390413917498]],
    [ "Enedis",[6.802943012769841, 49.14247878590057]],
    [ "Enedis",[6.812223506014674, 49.1486945016196]],
    [ "Enedis",[6.797077355374286, 49.145224847793315]],
    [ "Enedis",[6.7848912057197275, 49.14232375444291]],
    [ "Enedis",[6.809344421307928, 49.14104560200397]],
    [ "Enedis",[6.832901778715847, 49.14969048590163]],
    [ "SRD",[0.43085495430459503, 46.07463686681541]],
    [ "Enedis",[0.09685931161057397, 49.51885587051454]],
    [ "Enedis",[6.92684127234892, 49.1658827369423]],
    [ "Enedis",[6.944837252427865, 49.16365896294315]],
    [ "Enedis",[6.9336566505432335, 49.172982675137256]],
    [ "Enedis",[6.934148386418895, 49.169209182312706]],
    [ "Enedis",[6.93987929649255, 49.1761748030811]],
    [ "Enedis",[6.944955107854168, 49.16904469060524]],
    [ "Enedis",[6.931297458281635, 49.169927184812224]],
    [ "Enedis",[6.936275570465952, 49.16745899696127]],
    [ "Enedis",[6.948946257239244, 49.16602979998851]],
    [ "Enedis",[6.928971780374819, 49.17285967823373]],
    [ "Enedis",[6.957185900000001, 49.1559786]],
    [ "Enedis",[6.940422197204134, 49.174091254684996]],
    [ "Enedis",[6.933460004017728, 49.17557194857181]],
    [ "Enedis",[6.942628297560004, 49.16560042491563]],
    [ "Enedis",[0.09365944999999909, 49.519454100000004]],
    [ "SRD",[0.08413563668070696, 46.7885423592841]],
    [ "Enedis",[6.953381435138413, 49.150605351080905]],
    [ "Enedis",[6.951011263968666, 49.15152743764963]],
    [ "Enedis",[6.9504035946684795, 49.15391269538545]],
    [ "Enedis",[6.348319762491971, 47.148869712202945]],
    [ "Poste électrique de Nourottes",[2.012898390490518, 48.96669134692175]],
    [ "Enedis",[7.018013203005577, 49.159030813713585]],
    [ "Enedis",[7.025851566547716, 49.16257217448936]],
    [ "Enedis",[5.929519, 47.158794]],
    [ "Enedis",[7.0246663436337755, 49.16462373750623]],
    [ "Enedis",[7.004813502544501, 49.13891724390437]],
    [ "Enedis",[7.002769978037564, 49.14228636967045]],
    [ "Enedis",[7.007355419166466, 49.136009655604106]],
    [ "Enedis",[6.96726631147718, 49.166512780862355]],
    [ "Enedis",[6.958472443215761, 49.16749186954242]],
    [ "Enedis",[-1.7400607425742571, 48.54145918151815]],
    [ "Enedis",[-1.7405628677672156, 48.54895832866433]],
    [ "SRD",[0.21231939125188526, 46.4235869]],
    [ "SRD",[0.6941752214731468, 46.95861761479135]],
    [ "SRD",[0.6986721799905171, 46.94927395229967]],
    [ "Enedis",[-1.7474656062846576, 48.54279325077017]],
    [ "Enedis",[-1.7531922764761758, 48.54125974467375]],
    [ "Enedis",[-1.7351438012690676, 48.5529380509335]],
    [ "Enedis",[-1.741640895178197, 48.55224502725367]],
    [ "Enedis",[-1.7467493689138578, 48.54592542747047]],
    [ "Enedis",[-1.743633651727542, 48.54628854389602]],
    [ "Enedis",[4.818977154984536, 45.77508922969307]],
    [ "Enedis",[4.8185195, 45.775139499999995]],
    [ "Enedis",[4.816055126323474, 45.779257631495675]],
    [ "Graves",[3.4317047564851193, 46.13235646423895]],
    [ "Vosges",[3.4294465, 46.1345465]],
    [ "Enedis",[3.414194238396624, 46.14679894936708]],
    [ "Germot",[3.420508346637639, 46.1318446913643]],
    [ "Clos de Celestines",[3.434518748782896, 46.12868936776536]],
    [ "Vendée",[3.4355200000000004, 46.1305804]],
    [ "Les Combes",[3.44364625, 46.11718499999999]],
    [ "Enedis",[3.4340021112992987, 46.12710986023066]],
    [ "Enedis",[3.418838282544965, 46.13743954100309]],
    [ "Enedis",[3.4162341952117865, 46.12788452486189]],
    [ "Enedis",[3.422709495726496, 46.14143524786324]],
    [ "Enedis",[3.4270150000000004, 46.1357065]],
    [ "Bourgogne Vendée",[3.4349485000000017, 46.128634000000005]],
    [ "Enedis",[3.4134220824372763, 46.14578858422938]],
    [ "Enedis",[7.173162399149951, 49.104025806932874]],
    [ "Enedis",[7.183415814159656, 49.12394500175423]],
    [ "SRD",[0.3074960547292883, 46.82924132959022]],
    [ "SRD",[0.2588326561516422, 46.89234723749182]],
    [ "Enedis",[6.232337491061072, 46.194111957375185]],
    [ "Enedis",[4.1144605, 50.0286634]],
    [ "Enedis",[4.1099709845440495, 50.02736517104585]],
    [ "Enedis",[4.119023265739667, 50.03218653650058]],
    [ "Le Champa",[5.645476249999999, 45.0270965]],
    [ "Saint Barthelemy",[5.6303381689008045, 45.00009398570151]],
    [ "Enedis",[5.648946205223221, 45.02513344467972]],
    [ "BOURG",[5.64503489102564, 45.022703053418795]],
    [ "Enedis",[4.046946663524067, 49.26188920126718]],
    [ "Enedis",[4.0550145, 49.247584499999995]],
    [ "Enedis",[6.604357, 47.05403124999999]],
    [ "Enedis",[6.604730750000001, 47.06346954999999]],
    [ "Enedis",[6.591260718473315, 47.059076969403456]],
    [ "Enedis",[6.604645999999999, 47.059500250000006]],
    [ "Enedis",[6.598447249999999, 47.05409574999999]],
    [ "Enedis",[6.6100175, 47.05614099999999]],
    [ "Enedis",[6.613847250000002, 47.058729000000014]],
    [ "Enedis",[6.606192976591059, 47.06152420980694]],
    [ "Enedis",[6.594625, 47.061011]],
    [ "Enedis",[6.601028756964463, 47.05389574402937]],
    [ "Enedis",[6.592410666666666, 47.05113718032786]],
    [ "Enedis",[6.602868749999999, 47.05616]],
    [ "Enedis",[6.599371, 47.057969]],
    [ "Enedis",[6.6114992500000005, 47.05695325000001]],
    [ "Enedis",[6.5993770000000005, 47.056202500000005]],
    [ "Enedis",[4.058371514785789, 49.253905457001046]],
    [ "Enedis",[6.606886700000001, 47.056011]],
    [ "Enedis",[6.60213375, 47.0586085]],
    [ "Enedis",[6.59599825, 47.06065374999999]],
    [ "Enedis",[6.584509805774278, 47.057842683989506]],
    [ "Enedis",[6.607958749999999, 47.06028025]],
    [ "Enedis",[6.601700499999998, 47.05718425]],
    [ "Enedis",[6.6019535000000005, 47.06163875]],
    [ "Enedis",[4.057770303466737, 49.248869357841755]],
    [ "Enedis",[4.077465048131443, 49.259113235953606]],
    [ "Enedis",[2.1549476657546736, 48.68857251117191]],
    [ "BU Guyonnerie",[2.1571205559647364, 48.702724811516575]],
    [ "BU Haras",[2.1555225654635537, 48.696693771408356]],
    [ "Enedis",[2.141881396090766, 48.69833980146783]],
    [ "Enedis",[6.666802809217578, 47.067640593783494]],
    [ "Enedis",[6.679560663568108, 47.06086416815636]],
    [ "Enedis",[6.665117095052474, 47.07113369165418]],
    [ "Enedis",[6.666831650211566, 47.064201668077104]],
    [ "Enedis",[6.663709658045977, 47.06246998275863]],
    [ "Enedis",[6.67688, 47.059210500000006]],
    [ "Enedis",[6.6747744, 47.070041599999996]],
    [ "Enedis",[6.692539822306238, 47.062664470069315]],
    [ "Enedis",[6.672562302897975, 47.0645914442239]],
    [ "Enedis",[6.6853500833333355, 47.0697725]],
    [ "Enedis",[6.681165036423842, 47.0723184183223]],
    [ "Enedis",[6.668379321699545, 47.062696158826505]],
    [ "Enedis",[6.66810232688432, 47.06645099395405]],
    [ "Enedis",[6.628256396969697, 47.077955954545466]],
    [ "Poste électrique de Le Broc-Carros",[7.191599545887984, 43.80309836528203]],
    [ "Poste électrique de Petite Rosselle",[6.882409823216296, 49.19727966719973]],
    [ "Enedis",[6.088877316472114, 44.56386500389107]],
    [ "Enedis",[6.097235525854966, 44.567771974454054]],
    [ "Enedis",[5.046061153526646, 44.06500019894073]],
    [ "Enedis",[5.04996175, 44.076603000000006]],
    [ "Enedis",[5.088823900883833, 44.05531195230112]],
    [ "Enedis",[5.02759705, 44.05851535]],
    [ "90985407",[4.0970416408952195, 48.28088441030858]],
    [ "Enedis",[4.0914373961312664, 48.268616968526636]],
    [ "Enedis",[4.092624500000001, 48.26951675000001]],
    [ "Enedis",[2.5429182408637003, 48.83362128434124]],
    [ "91031217",[4.1000013, 48.28093105]],
    [ "Enedis",[4.086542166240836, 48.269824306813305]],
    [ "Enedis",[4.687518537838522, 44.532774759807644]],
    [ "Enedis",[4.690048949522698, 44.53344318137851]],
    [ "Enedis",[-4.050701750000001, 47.9079225]],
    [ "SRD",[0.5690466247086244, 46.520935681235436]],
    [ "Enedis",[6.350969423324718, 47.07692169512143]],
    [ "Apollo",[4.735321750000001, 49.73921575]],
    [ "Enedis",[4.706356170308426, 49.77414251982736]],
    [ "Enedis",[4.711019185928829, 49.772852986287965]],
    [ "Enedis",[4.714276590626976, 49.77540270413486]],
    [ "Enedis",[4.71897128151104, 49.77316465838933]],
    [ "Paquis",[4.72853612195122, 49.78124480487805]],
    [ "Enedis",[4.7046255194274025, 49.76128308588958]],
    [ "Mont Olympe",[4.726617691131498, 49.77923736085627]],
    [ "Enedis",[6.745336584480368, 47.199465003991314]],
    [ "Cimetière",[4.709449800000002, 49.77588095000001]],
    [ "Enedis",[2.2174775, 47.873942]],
    [ "Enedis",[2.226710246139873, 47.86323892733879]],
    [ "Enedis",[2.210195, 47.8710935]],
    [ "Enedis",[2.223352279559203, 47.86211041565936]],
    [ "Enedis",[2.228144219607843, 47.89283201029413]],
    [ "Enedis",[2.226151041880341, 47.86945107094016]],
    [ "Enedis",[2.222824413013382, 47.864152768650975]],
    [ "Enedis",[2.2316800000000003, 47.874483500000004]],
    [ "Enedis",[2.2203774999999997, 47.867956]],
    [ "Enedis",[2.226790197393656, 47.873332132782565]],
    [ "Enedis",[2.232801304356956, 47.86326244808399]],
    [ "Enedis",[2.209886749928551, 47.86674533495285]],
    [ "Enedis",[2.234846578018452, 47.86589277617329]],
    [ "Enedis",[2.281764336160855, 47.95851316818515]],
    [ "Enedis",[2.2646484071838207, 47.94092425495523]],
    [ "Enedis",[2.264030332351497, 47.94437700736377]],
    [ "Enedis",[2.2678154555739516, 47.94009048399559]],
    [ "Enedis",[2.13499388708738, 47.871663418650655]],
    [ "Enedis",[2.1267792537313426, 47.88024691044775]],
    [ "Enedis",[2.125693141732283, 47.87881929658792]],
    [ "Enedis",[2.11529669375, 47.874618897916676]],
    [ "Enedis",[2.111690040327912, 47.875846599629774]],
    [ "Enedis",[2.1335734211248285, 47.86966181755829]],
    [ "Enedis",[2.137803974935045, 47.86854133272199]],
    [ "Enedis",[2.1300657490636707, 47.87815156179776]],
    [ "Enedis",[2.1613645, 47.8989365]],
    [ "Enedis",[2.125573884083045, 47.874436764129186]],
    [ "Enedis",[2.1356228037472214, 47.87598347454219]],
    [ "Enedis",[2.1304236816748654, 47.873133483667544]],
    [ "Enedis",[5.969793850202429, 47.26271196176338]],
    [ "Enedis",[3.1565639999999995, 45.8281805]],
    [ "Enedis",[3.156350997277326, 45.83098976001556]],
    [ "Enedis",[3.133658494276323, 45.83670267550719]],
    [ "Enedis",[3.1409509428263216, 45.82337193527508]],
    [ "Enedis",[3.1448771453923454, 45.81937472931366]],
    [ "Enedis",[3.152027757266354, 45.82543015621808]],
    [ "Enedis",[3.1377873257820927, 45.81525117475728]],
    [ "Enedis",[3.1499279999999996, 45.81540699999999]],
    [ "Enedis",[3.137048455782313, 45.83625975510204]],
    [ "91283880",[3.1387205382379766, 45.83767617502682]],
    [ "Enedis",[3.1400834705882352, 45.82478661437908]],
    [ "Enedis",[3.1532257594936706, 45.82007334177215]],
    [ "Enedis",[3.1405060000000002, 45.834210999999996]],
    [ "Enedis",[3.1518772391304353, 45.81234294202899]],
    [ "Enedis",[3.1446659999999995, 45.81344500000001]],
    [ "Enedis",[3.1573553432098764, 45.83595648209876]],
    [ "Enedis",[3.146473542222223, 45.81602064244444]],
    [ "Enedis",[3.155218194087904, 45.82188781641384]],
    [ "Enedis",[3.1526908239316245, 45.83086101310541]],
    [ "Enedis",[3.143776384734399, 45.826630711363244]],
    [ "Enedis",[3.144654917060679, 45.82053620508275]],
    [ "Enedis",[3.1475984946236557, 45.835019290322585]],
    [ "Enedis",[3.1560253517915307, 45.82846272747014]],
    [ "Enedis",[3.1477013229706383, 45.828586727115706]],
    [ "Enedis",[3.1283387021040974, 45.826142841638976]],
    [ "Enedis",[3.13822419082569, 45.81392592782875]],
    [ "Enedis",[3.1513749541930935, 45.81148778646935]],
    [ "Enedis",[3.132870759844703, 45.834687033277866]],
    [ "Enedis",[3.1556489389294398, 45.82521091824816]],
    [ "Enedis",[3.1448535000000013, 45.8169345]],
    [ "Enedis",[3.149612540332147, 45.825617342230125]],
    [ "Enedis",[3.151786269333333, 45.821387966476195]],
    [ "Enedis",[3.148752187807824, 45.830409635256956]],
    [ "Enedis",[3.1450041814525673, 45.828225893304946]],
    [ "Enedis",[3.1549464999999994, 45.84032700000001]],
    [ "Enedis",[3.1559970099350525, 45.81990190582763]],
    [ "Enedis",[3.1487542434052758, 45.83275249280575]],
    [ "Enedis",[3.142380252357601, 45.832436102099834]],
    [ "Enedis",[3.1475349999999995, 45.8188245]],
    [ "Enedis",[3.150048434362629, 45.83595122406966]],
    [ "Enedis",[3.1461586392302916, 45.83100061824952]],
    [ "Enedis",[3.1320816, 45.8341327]],
    [ "Enedis",[3.1534239511426314, 45.82391727895981]],
    [ "Enedis",[3.1541367874720367, 45.817158031319906]],
    [ "Enedis",[3.1454274423546917, 45.81259775592392]],
    [ "Enedis",[3.136788670552318, 45.834524089369964]],
    [ "Enedis",[3.1430802412691166, 45.81524761089553]],
    [ "Enedis",[3.1471424532954018, 45.82028132479848]],
    [ "Fuvelle",[6.433643866735259, 47.13345710050759]],
    [ "Enedis",[5.951565499999999, 47.255527]],
    [ "Enedis",[5.952441049999999, 47.25113395]],
    [ "Poste électrique de Saint-Estève",[5.384681943078952, 43.69314883175811]],
    [ "Enedis",[6.241337749788959, 46.17898771338069]],
    [ "Poste électrique de Gargenville",[1.8200381775024903, 48.980377498076194]],
    [ "Enedis",[2.0712888399953546, 47.236977241261314]],
    [ "Enedis",[2.0799142491521705, 47.22740260013118]],
    [ "Enedis",[2.088377196655396, 47.234153546616376]],
    [ "Enedis",[2.080248717816822, 47.23161192409147]],
    [ "Enedis",[2.085891002678823, 47.226368040758864]],
    [ "Enedis",[2.0773759244298553, 47.22840804775397]],
    [ "Enedis",[2.0706468158231766, 47.23478133701286]],
    [ "Enedis",[2.0773869084832595, 47.22292973257438]],
    [ "Enedis",[2.0908403229517347, 47.229304275381644]],
    [ "Enedis",[1.6237961033333335, 43.10035426133334]],
    [ "Enedis",[1.60664265, 43.13664025]],
    [ "Enedis",[1.607169156457058, 43.134740378521165]],
    [ "Enedis",[2.122804588731546, 47.866193710254095]],
    [ "Enedis",[2.142935271794872, 47.83835293846154]],
    [ "Enedis",[2.1204519540229883, 47.865290691654174]],
    [ "Enedis",[2.1220232577777765, 47.862979462222214]],
    [ "Enedis",[2.1229736021916925, 47.86742549080799]],
    [ "Enedis",[2.113819808798646, 47.866685035532996]],
    [ "Enedis",[2.1262009156550987, 47.86547380335321]],
    [ "Enedis",[2.099766502512563, 47.86337957118928]],
    [ "Enedis",[2.10406681553245, 47.853484459042214]],
    [ "Enedis",[2.0974695000000003, 47.857431000000005]],
    [ "Enedis",[2.0956515822388995, 47.85987370106317]],
    [ "Enedis",[2.1055256116392975, 47.86365176490925]],
    [ "Enedis",[6.23638381928272, 46.18157222316311]],
    [ "Planet",[6.236627805559418, 46.18462250446173]],
    [ "Poste Électrique de Magnanville",[1.695853832168276, 48.97405676427678]],
    [ "Enedis",[2.041030252744845, 47.91688333045227]],
    [ "Enedis",[2.0320116038528258, 47.89574103907604]],
    [ "Enedis",[2.029604098118473, 47.905051465478074]],
    [ "Enedis",[2.026524388625845, 47.895226900302596]],
    [ "Enedis",[2.0304206801438727, 47.89327140557608]],
    [ "Enedis",[2.0200906157112524, 47.898040089171964]],
    [ "Enedis",[2.0254049999999997, 47.914907]],
    [ "Enedis",[2.0389536396208, 47.91443606412936]],
    [ "Enedis",[2.0328464432267026, 47.90499876886456]],
    [ "Enedis",[2.0364413240826402, 47.90478139198272]],
    [ "Enedis",[1.998426499869153, 47.89920598953222]],
    [ "Enedis",[2.02845641844093, 47.908591000399035]],
    [ "Enedis",[2.033410696353799, 47.89283900644554]],
    [ "Enedis",[2.0150954728950405, 47.91296615224913]],
    [ "Enedis",[2.020731276789245, 47.895425030051406]],
    [ "Enedis",[1.9959051779695844, 47.90031583929304]],
    [ "Enedis",[2.0118313786685413, 47.90045815466479]],
    [ "Enedis",[5.729903088507157, 43.98556008780163]],
    [ "Pré Chardon",[-0.911629079254086, 45.603748358322306]],
    [ "Enedis",[-0.9431597499999999, 45.60486089999999]],
    [ "Enedis",[-0.9164319544859489, 45.62032856590897]],
    [ "Enedis",[-0.9470885257409135, 45.60624632145134]],
    [ "Enedis",[-0.9221490499999999, 45.6135768]],
    [ "Enedis",[-0.891211579090073, 45.60952991918217]],
    [ "Enedis",[-0.9158422064153287, 45.60392279759546]],
    [ "Enedis",[-0.9134528456973514, 45.60070362084927]],
    [ "Enedis",[-0.9080161178248333, 45.59952499665612]],
    [ "Enedis",[-0.8965778985177472, 45.58786216522271]],
    [ "Enedis",[-0.9195158277818757, 45.61672366576625]],
    [ "Enedis",[-0.8914330306270083, 45.58816507002926]],
    [ "Enedis",[-0.9210327574982516, 45.61098625161355]],
    [ "Enedis",[-0.9088975498487655, 45.60094920223766]],
    [ "Enedis",[6.836253224201931, 47.65540994803266]],
    [ "Enedis",[6.829362349726775, 47.65264721229509]],
    [ "Enedis",[6.834093213836478, 47.64861494878706]],
    [ "Enedis",[6.831804558558557, 47.64949530630629]],
    [ "Enedis",[6.835640999999999, 47.6572745]],
    [ "Enedis",[6.835121600377446, 47.653543284501055]],
    [ "Enedis",[6.8339366329284745, 47.65346030634279]],
    [ "CS Bignon",[2.03841655, 48.94657284999999]],
    [ "Poste électrique de Lairon",[-1.1106782295758704, 48.57650025847094]],
    [ "Enedis",[3.18187, 43.177833]],
    [ "Enedis",[3.1394106605765337, 43.23341849370686]],
    [ "Enedis",[3.198374816290859, 43.18522464318593]],
    [ "Enedis",[3.1927027316606917, 43.18786145328554]],
    [ "Enedis",[3.173785400871461, 43.24282830718955]],
    [ "Enedis",[3.183042310043668, 43.175976430567694]],
    [ "Enedis",[3.2291763333333336, 43.21720004878049]],
    [ "Enedis",[3.1938505184112045, 43.18377183604974]],
    [ "Enedis",[3.1809471793020445, 43.17302501925391]],
    [ "Enedis",[3.1841485000000005, 43.18180100000001]],
    [ "Enedis",[3.190429306519743, 43.18695427015611]],
    [ "Enedis",[3.1827785, 43.17412549999999]],
    [ "Enedis",[3.182012528205128, 43.17664822051282]],
    [ "Enedis",[3.1367772828660434, 43.22680413831776]],
    [ "Enedis",[3.1352759999999997, 43.231604]],
    [ "Enedis",[3.139507, 43.231694000000005]],
    [ "Enedis",[3.231857315386646, 43.214714536641154]],
    [ "Enedis",[3.1878599349760437, 43.18027129842574]],
    [ "Enedis",[3.18575865293669, 43.18210945080092]],
    [ "Enedis",[3.1898308095952026, 43.18509984707646]],
    [ "Enedis",[4.884479469856465, 43.95458707914106]],
    [ "Enedis",[4.8900368499999995, 43.9652665]],
    [ "Enedis",[4.90211005, 43.97068154999999]],
    [ "Enedis",[4.90841770875693, 43.9759756200019]],
    [ "Enedis",[4.91366345, 43.9765046]],
    [ "Enedis",[4.90973395, 43.982479049999995]],
    [ "Enedis",[4.89818785, 43.98361085]],
    [ "Enedis",[4.9145607574291725, 43.97792188165147]],
    [ "Enedis",[4.9095596523050515, 43.987617778622514]],
    [ "Enedis",[4.909317699999999, 43.9743293]],
    [ "Enedis",[4.919894049999998, 43.97890569999999]],
    [ "Enedis",[4.911427551076109, 43.98008823336936]],
    [ "Enedis",[4.916617792293217, 43.980651605776465]],
    [ "Enedis",[4.901237049999999, 43.969261700000004]],
    [ "Enedis",[4.9014308228091, 43.987177617277226]],
    [ "Enedis",[4.908034146280512, 43.972219531147395]],
    [ "Enedis",[4.907313, 43.97934765000001]],
    [ "Enedis",[4.90317705, 43.98120945]],
    [ "Enedis",[4.903035369903382, 43.973557444800605]],
    [ "Enedis",[4.904232490118189, 43.984159525182974]],
    [ "Enedis",[4.900364316438226, 43.97397172484344]],
    [ "Enedis",[4.90298255, 43.98548864999999]],
    [ "Enedis",[4.906052549999999, 43.97406215]],
    [ "Enedis",[4.899538302289169, 43.969240251957196]],
    [ "Enedis",[4.917343649999999, 43.97288875]],
    [ "Enedis",[4.902141149999999, 43.96684764999999]],
    [ "Enedis",[4.9147968491341985, 43.98318216703368]],
    [ "Enedis",[4.88841225, 43.98384885]],
    [ "Enedis",[4.902417991649902, 43.977578760423384]],
    [ "Enedis",[4.911654216757568, 43.98281547249254]],
    [ "Enedis",[4.897777100000001, 43.970480249999994]],
    [ "Enedis",[2.2924019999999996, 48.79442349999999]],
    [ "FT Maisonette",[2.2885583887337058, 48.790133081471126]],
    [ "FT Sauveur",[2.295828, 48.792664]],
    [ "SNCF Réseau",[2.3071242622600305, 48.6273622034517]],
    [ "Enedis",[2.4641931412429385, 45.802816192090404]],
    [ "Enedis",[2.4683616997507363, 45.80044324563788]],
    [ "Enedis",[2.966327000000002, 43.14240850000001]],
    [ "Enedis",[4.110016530149935, 49.221013952723595]],
    [ "Enedis",[4.109137500000001, 49.2215634]],
    [ "Enedis",[4.095833680271747, 49.22420173838038]],
    [ "Enedis",[4.1139106000000005, 49.220086450000004]],
    [ "Enedis",[4.0973234802284075, 49.22594807210724]],
    [ "Enedis",[4.106730067444635, 49.22197205199348]],
    [ "Enedis",[4.115049589063617, 49.21668065925661]],
    [ "Enedis",[4.110049737489626, 49.22094943246335]],
    [ "Enedis",[4.098350971650547, 49.226574352894836]],
    [ "Enedis",[4.1173847801218155, 49.218536370019564]],
    [ "Enedis",[4.118678836416969, 49.219609131035426]],
    [ "Enedis",[3.1631702118644056, 43.15574342655366]],
    [ "Enedis",[3.170644526699334, 43.16472379369267]],
    [ "Enedis",[3.0075657, 43.18587934999999]],
    [ "Enedis",[2.985390891666667, 43.15790368333333]],
    [ "Enedis",[2.9837858461538467, 43.159053251282046]],
    [ "Enedis",[2.9782789999999997, 43.155978]],
    [ "Enedis",[3.0125800000000003, 43.192164500000004]],
    [ "Enedis",[2.9925734127507555, 43.15889557708161]],
    [ "Enedis",[3.0930799244591496, 47.89125267063986]],
    [ "Enedis",[3.0954715548996727, 47.88057938360967]],
    [ "Enedis",[3.09502555, 47.8846559]],
    [ "Enedis",[3.093647225546461, 47.887171484040735]],
    [ "Enedis",[3.0273553529128083, 43.18871360397377]],
    [ "Enedis",[3.196543034077914, 48.076508940211454]],
    [ "Enedis",[3.2160022403771724, 48.080979142448186]],
    [ "Enedis",[2.9941170637254904, 43.15599460294118]],
    [ "Enedis",[3.159739535753575, 43.1559765716905]],
    [ "Mazagran",[2.996747456396119, 43.17638817274762]],
    [ "Enedis",[3.6962070694190308, 47.76057895786528]],
    [ "Enedis",[3.69831045, 47.760533349999996]],
    [ "Enedis",[3.703438219390547, 47.7624700407245]],
    [ "Enedis",[2.9834239697916667, 43.15386652857143]],
    [ "Enedis",[2.9871194000381176, 43.16124861044407]],
    [ "Enedis",[6.830895333333332, 47.48227114814815]],
    [ "Enedis",[3.1683020159074475, 43.16256282574115]],
    [ "Enedis",[3.1568483422459894, 43.15718841532978]],
    [ "Douane",[2.9913276500000006, 43.177624249999994]],
    [ "Enedis",[3.1724114788823905, 43.166014823261854]],
    [ "Enedis",[2.984317873563219, 43.16192782471265]],
    [ "Enedis",[3.0179981307493535, 43.19460133901809]],
    [ "Enedis",[2.9889525189393935, 43.16208784090909]],
    [ "Enedis",[3.0291075000000003, 43.180530299999994]],
    [ "Bereven",[-4.13241, 47.872100499999995]],
    [ "Enedis",[-4.121915597246531, 47.86722324221211]],
    [ "GIE BAT",[-4.1518345, 47.88797850000001]],
    [ "MENEZ NOAZ",[-4.1635635, 47.876394]],
    [ "Enedis",[-4.162184699999999, 47.86354314999999]],
    [ "Foyer Logement",[-4.1544764999999995, 47.888912749999996]],
    [ "Enedis",[-4.167669666772745, 47.84772575035436]],
    [ "Enedis",[-4.1660706, 47.85000355]],
    [ "Enedis",[-4.154444888605769, 47.857901626974936]],
    [ "Enedis",[-4.152809573135409, 47.85600889469312]],
    [ "Enedis",[-4.156513628487558, 47.859168154955896]],
    [ "Teven",[-4.156900524779637, 47.852302344788136]],
    [ "Enedis",[-4.15805145, 47.85544579999999]],
    [ "Rue des Mousses",[-4.168158521359895, 47.841581615176885]],
    [ "Enedis",[-4.16862505, 47.84317019999999]],
    [ "Enedis",[-4.16141685, 47.85361405]],
    [ "Enedis",[2.8507789999999997, 50.4925285]],
    [ "Enedis",[2.853223999073216, 50.48980427247451]],
    [ "Enedis",[2.870299129572324, 50.49709935896215]],
    [ "Enedis",[2.8459813524003303, 50.4931605219685]],
    [ "Enedis",[2.8437160507111927, 50.49343183147804]],
    [ "Enedis",[2.852871140265367, 50.48760738315732]],
    [ "Enedis",[2.839861849721829, 50.4908033018969]],
    [ "Enedis",[2.8677524064171123, 50.50476789839572]],
    [ "Enedis",[2.8510934944071575, 50.4946142580164]],
    [ "Enedis",[2.840874125211505, 50.49348535871404]],
    [ "Enedis",[2.843795657405316, 50.49519086838341]],
    [ "Enedis",[2.8493066747447036, 50.48857781862521]],
    [ "Enedis",[2.8567216591886315, 50.49262000859974]],
    [ "Enedis",[2.83742454372019, 50.48820032697403]],
    [ "Enedis",[2.8566058625994692, 50.48720669266137]],
    [ "Enedis",[2.840281971496437, 50.48939316943785]],
    [ "Enedis",[2.844733550363448, 50.4894274132918]],
    [ "Enedis",[2.8451515, 50.495875]],
    [ "Enedis",[2.8431695, 50.4880025]],
    [ "Enedis",[2.842301500000001, 50.492557000000005]],
    [ "Enedis",[2.8589594036620882, 50.494193219725354]],
    [ "Enedis",[4.880694800000001, 45.06045684999999]],
    [ "Enedis",[4.156697045080583, 48.27965374783205]],
    [ "Enedis",[6.847659538852096, 49.15988468971767]],
    [ "Enedis",[6.85150287288304, 49.15664818927241]],
    [ "Enedis",[6.849784067844752, 49.15800985081187]],
    [ "Enedis",[6.8603216120786, 49.164441906468916]],
    [ "Enedis",[6.923504605871989, 49.16601317561993]],
    [ "Enedis",[6.8961661960606655, 49.14712214735121]],
    [ "Enedis",[6.922254550391542, 49.15198388897808]],
    [ "Enedis",[6.921179759333703, 49.162938191456156]],
    [ "Enedis",[6.924313420289855, 49.163711388405794]],
    [ "Enedis",[6.932738237994856, 49.12311970468671]],
    [ "Enedis",[2.9023753946360156, 49.2251731532567]],
    [ "Enedis",[2.8982963929564423, 49.220214853259186]],
    [ "Enedis",[2.8984062675852065, 49.236311771331884]],
    [ "Enedis",[2.8760728841123058, 49.2316766283429]],
    [ "Enedis",[2.8942377129529957, 49.231677584858275]],
    [ "Enedis",[4.79272860791864, 43.96038215860886]],
    [ "Enedis",[4.799153007965922, 43.9616701226411]],
    [ "Enedis",[4.7894879999999995, 43.956975449999995]],
    [ "Enedis",[4.800481582042081, 43.96405663373374]],
    [ "Enedis",[4.793447349999999, 43.957399200000005]],
    [ "MG Lesseps 29",[2.3396339500000005, 48.70303285000001]],
    [ "Enedis",[4.790903449999999, 43.95390209999999]],
    [ "Enedis",[4.792349332793939, 43.95579420375836]],
    [ "Enedis",[2.57075792003167, 45.75447746793349]],
    [ "Enedis",[4.7818579, 43.95095815]],
    [ "Enedis",[4.78066011423189, 43.944130168119656]],
    [ "Poste Électrique des Groux",[1.569872309133059, 49.09442775901673]],
    [ "Poste électrique de Morvent",[1.566854050361987, 49.01628256831925]],
    [ "Poste électrique de Béranville",[1.5588228169896248, 49.03333647950243]],
    [ "Poste électrique de Bonnières",[1.5592797339666138, 49.02249122069806]],
    [ "Enedis",[4.063777650853085, 48.27716616303136]],
    [ "Baltet 42",[4.060324525816051, 48.28166461567664]],
    [ "Paix 43",[4.057313776108143, 48.276429215363954]],
    [ "Enedis",[4.051026053638264, 48.272357365694674]],
    [ "Enedis",[4.061118934085408, 48.27589637243966]],
    [ "Enedis",[4.054678915576232, 48.27233244837668]],
    [ "Auxerre 89",[4.0523997627186965, 48.27067479021527]],
    [ "Enedis",[4.92007817133173, 44.00138369273019]],
    [ "Enedis",[4.917639941822464, 44.004295780272194]],
    [ "Enedis",[4.92677033744696, 43.9960048015323]],
    [ "Enedis",[4.94167725144342, 43.992038426061534]],
    [ "Enedis",[4.927317317336403, 44.00214693368628]],
    [ "Enedis",[4.9164192864298855, 43.99170599605969]],
    [ "Enedis",[4.925522553559351, 43.999945211168445]],
    [ "Enedis",[4.9153535241946855, 44.00060604699576]],
    [ "Enedis",[4.937277069312549, 44.00598270479951]],
    [ "Enedis",[4.9200673603753335, 43.994795350066774]],
    [ "Enedis",[4.916279168471314, 43.993433499928734]],
    [ "Enedis",[4.912809892498506, 44.00261742495696]],
    [ "Linguet",[4.065671216827656, 49.267840068052216]],
    [ "Enedis",[4.938745152571474, 44.00669422719511]],
    [ "Enedis",[4.93333147835435, 43.99942284649064]],
    [ "Enedis",[4.911213082297483, 43.99940441350949]],
    [ "Enedis",[4.9253247682158205, 44.00645060298529]],
    [ "Enedis",[4.916752212088186, 43.99810181188309]],
    [ "Enedis",[0.31914107822433024, 47.44366148267499]],
    [ "Kerambechennec",[-4.092642308828355, 47.86762864483438]],
    [ "Enedis",[5.569433980392156, 43.312258739064866]],
    [ "Enedis",[5.559743500000001, 43.28474]],
    [ "Enedis",[5.598861262864628, 43.316914791935844]],
    [ "Enedis",[5.006407156872056, 43.885156611522255]],
    [ "Enedis",[4.9883786, 43.94224680000001]],
    [ "Enedis",[4.9856389, 43.92599015]],
    [ "Enedis",[4.986751043632327, 43.931865090019755]],
    [ "Enedis",[4.99928835, 43.9339472]],
    [ "Enedis",[4.9879370000000005, 43.924054049999995]],
    [ "Enedis",[4.9906894500000005, 43.927262299999995]],
    [ "RTE",[3.2298902502625966, 43.330001340817155]],
    [ "Enedis",[5.3899966466460265, 43.427274818369455]],
    [ "Enedis",[5.393352500000001, 43.426585]],
    [ "Enedis",[5.3853124999999995, 43.423598999999996]],
    [ "Enedis",[5.395166874565561, 43.42644869225908]],
    [ "Enedis",[5.3895563459915605, 43.424585987341764]],
    [ "Enedis",[5.394125658553076, 43.429088252197424]],
    [ "Enedis",[5.395574405477622, 43.42641761055444]],
    [ "Enedis",[-0.7498783298611111, 47.006255986111114]],
    [ "Enedis",[-0.7544702159911196, 47.01059830455707]],
    [ "Enedis",[-0.74724015, 47.009281599999994]],
    [ "Enedis",[-0.7470254500000001, 47.01381329999999]],
    [ "Enedis",[-0.7444162499999994, 47.010308]],
    [ "Enedis",[-0.7438062499999998, 47.0128395]],
    [ "Enedis",[-0.7624397659969112, 47.01340479850289]],
    [ "Enedis",[-0.7361517, 47.010154699999994]],
    [ "Enedis",[-0.7511412499999999, 47.011121]],
    [ "Enedis",[-0.7407351499999997, 47.0117892]],
    [ "Enedis",[-0.7394790499999989, 47.00780725000001]],
    [ "Enedis",[-1.2168297189934096, 48.12018353624926]],
    [ "Enedis",[-1.2214229999999997, 48.113735000000005]],
    [ "Enedis",[-1.2217257786917353, 48.131372620838235]],
    [ "Enedis",[-1.2158662264150946, 48.12708899685534]],
    [ "Enedis",[-1.1972000791950657, 48.11768123060695]],
    [ "Enedis",[-1.1978910000000014, 48.11540050000001]],
    [ "Enedis",[-1.2144559289506385, 48.114508207952106]],
    [ "Enedis",[-1.199760210645016, 48.11474885836717]],
    [ "Enedis",[-1.2014032894534254, 48.1181694665127]],
    [ "Enedis",[-1.2124649008264459, 48.12432479889807]],
    [ "Enedis",[-1.2056936214335419, 48.1087988907446]],
    [ "Enedis",[-1.2006498398268397, 48.111021986147186]],
    [ "Enedis",[-1.1995473028846153, 48.107996357371796]],
    [ "Enedis",[-1.2145534921472518, 48.132862827948685]],
    [ "Enedis",[-1.1944105, 48.118504]],
    [ "Enedis",[-1.1864247088989435, 48.11757585369532]],
    [ "Enedis",[-1.2012382793381227, 48.12078744937949]],
    [ "Enedis",[-1.2028876254545458, 48.11672319757575]],
    [ "Enedis",[-1.18715303240488, 48.11904776988896]],
    [ "Enedis",[-1.1946930163831126, 48.12387932640202]],
    [ "Enedis",[-1.1917440000000001, 48.121202]],
    [ "Enedis",[-1.2093196805896802, 48.107247266994264]],
    [ "Enedis",[-1.197797535331906, 48.10882317630264]],
    [ "Enedis",[-1.2177046829190576, 48.114886984703624]],
    [ "Enedis",[-1.204547238353366, 48.11447735297352]],
    [ "Enedis",[-1.2177453688815965, 48.13073619059338]],
    [ "Enedis",[-1.1847694999999998, 48.120025999999996]],
    [ "Enedis",[-1.1929830000000008, 48.12234149999999]],
    [ "Enedis",[-1.219582, 48.131848500000004]],
    [ "Enedis",[-1.197441806649359, 48.11920653678062]],
    [ "Enedis",[-1.1838465000000002, 48.11277100000001]],
    [ "Enedis",[-1.1861672811244988, 48.11577694779116]],
    [ "Enedis",[-1.2054231215599707, 48.10710800603384]],
    [ "Enedis",[-1.1928939751737837, 48.10364882389938]],
    [ "Enedis",[-1.1915786544467075, 48.11722145621181]],
    [ "Enedis",[-1.193349759514848, 48.112617734002505]],
    [ "Enedis",[-1.2092625, 48.111331]],
    [ "Enedis",[-1.2245545000000002, 48.1144905]],
    [ "Enedis",[-1.2116832987249546, 48.131182526411656]],
    [ "Enedis",[-1.211950281862244, 48.12513354454229]],
    [ "Enedis",[-1.1851331097308468, 48.12160718426501]],
    [ "Enedis",[-1.210277, 48.1220745]],
    [ "Enedis",[-1.198169421609435, 48.124030044571626]],
    [ "Enedis",[-1.2219582972459642, 48.11794702849003]],
    [ "Poste électrique de la Chabossière",[-1.6854145128422353, 47.20669609384681]],
    [ "Enedis",[-1.1918344301891337, 48.11893931731117]],
    [ "Enedis",[-1.1915803223658443, 48.114544011750866]],
    [ "Enedis",[-1.1928172555555554, 48.117599059259256]],
    [ "Enedis",[-1.2104434373737372, 48.11318271919192]],
    [ "Enedis",[-1.181632941297632, 48.121525245451416]],
    [ "Enedis",[-1.1863078221757326, 48.111036339260814]],
    [ "Enedis",[-1.222771204694836, 48.13274677276995]],
    [ "Enedis",[-1.2143575337402504, 48.13424382807731]],
    [ "Enedis",[-1.1968281865715988, 48.12139465953655]],
    [ "Enedis",[-1.2121676182495345, 48.129500337057735]],
    [ "Enedis",[-1.1995168575851398, 48.11322581940144]],
    [ "Enedis",[-1.189355281364829, 48.11230583622048]],
    [ "Enedis",[-1.2028603730496457, 48.124279459574474]],
    [ "Enedis",[-1.2097159753753755, 48.11839462882883]],
    [ "Enedis",[-1.2103865, 48.109251500000006]],
    [ "Enedis",[2.0505751194968553, 47.165074213836476]],
    [ "Enedis",[2.0501191500000018, 47.16254095]],
    [ "Enedis",[2.05205845, 47.16336405]],
    [ "Enedis",[2.0522302619047625, 47.175607063492066]],
    [ "Enedis",[2.049370285911603, 47.19732010451198]],
    [ "Enedis",[2.0464530000000005, 47.203356]],
    [ "Enedis",[2.0478815973106865, 47.20506287119604]],
    [ "Enedis",[0.9599377045322025, 43.06397849542665]],
    [ "Enedis",[-1.5911966629811631, 48.15663297174447]],
    [ "Enedis",[-1.5772636916426515, 48.16195496157542]],
    [ "Enedis",[-1.584511944301628, 48.15753640616966]],
    [ "Enedis",[-1.5865893252073815, 48.162209321483]],
    [ "Fouillard T55",[-1.5860941458333333, 48.16040430208332]],
    [ "Enedis",[-1.5810496953610718, 48.16370172214252]],
    [ "Enedis",[-1.5813279999999998, 48.1599675]],
    [ "Le Corre",[-4.231401452314417, 47.86290771724625]],
    [ "TREBEHORET",[-4.230762499999999, 47.86012900000001]],
    [ "PLACE GAMBETTA",[-4.2228255500000005, 47.86418200000001]],
    [ "Rosveing",[-4.204293150000001, 47.878306]],
    [ "PORS MORO",[-4.2153542569151075, 47.86839697676943]],
    [ "LOT TOUZE",[-4.225109860366252, 47.86152462502214]],
    [ "LAMBOUR",[-4.21778342811218, 47.870718706712914]],
    [ "TREOUGUY",[-4.224329317863186, 47.85750580386754]],
    [ "DOURIC COZ",[-4.222673149999999, 47.85926995]],
    [ "TROLIGUER",[-4.1887973, 47.8772194]],
    [ "H~L~M",[-4.219537169819003, 47.86132004540088]],
    [ "VALLOU R DANTON",[-4.222344902193913, 47.86497784741685]],
    [ "Rue de La Source",[-4.228736831309864, 47.859196695762826]],
    [ "TROLIGUER 2",[-4.193535278055985, 47.87826914455187]],
    [ "RUE MUGUET",[-4.227918712885813, 47.86589203325279]],
    [ "Enedis",[-1.8331244772609823, 48.157279746033986]],
    [ "Enedis",[-1.8373215000000003, 48.1530957]],
    [ "Enedis",[-1.8368145000000011, 48.1497009]],
    [ "Enedis",[-1.829726743978909, 48.1560063728944]],
    [ "Enedis",[-1.833622037857122, 48.153657995112226]],
    [ "Poste électrique de Neoules",[6.022482457568335, 43.27631415693426]],
    [ "Enedis",[1.4196465499999997, 43.5821257]],
    [ "Enedis",[1.4161550981986653, 43.57753304672404]],
    [ "Enedis",[1.4110061272218903, 43.57456446496802]],
    [ "Enedis",[3.066827672579453, 45.88231751071693]],
    [ "Enedis",[3.0511065424192667, 45.84528492610838]],
    [ "Enedis",[2.436682914083742, 49.24595172321914]],
    [ "Enedis",[3.785978769230769, 43.555676143984215]],
    [ "Enedis",[3.789380438342967, 43.55799311078998]],
    [ "Enedis",[3.7703932499999997, 43.55302175]],
    [ "Enedis",[3.784683668081094, 43.55683262140499]],
    [ "Enedis",[5.880821991596313, 47.107891503999824]],
    [ "Enedis",[6.322491744033477, 46.938633164316755]],
    [ "Enedis",[6.32078225, 46.94050025000001]],
    [ "Enedis",[6.32248125, 46.94154474999999]],
    [ "Enedis",[6.26498765, 46.877354]],
    [ "Chaboz",[6.269135904517305, 46.88037713084886]],
    [ "Enedis",[7.288608449999999, 48.33691345]],
    [ "RTE",[1.7512226645051063, 48.990763168950046]],
    [ "Poste électrique de Marianne",[1.761500009369639, 48.98620872185863]],
    [ "Sous-station RFF des Martraits",[1.7200722816982608, 48.982025498229014]],
    [ "RTE",[1.7658246314819284, 48.981964027081915]],
    [ "Enedis",[-4.24219235, 47.870082549999985]],
    [ "LOT DANIEL",[-4.23187795, 47.869054899999995]],
    [ "Enedis",[-4.220526469752739, 47.799941193298814]],
    [ "SICAE-Oise",[2.6850126000000003, 49.59311555]],
    [ "Enedis",[-4.265993305891849, 47.809253645682006]],
    [ "Enedis",[-4.2477867499999995, 47.79919865]],
    [ "Enedis",[-4.270287321137574, 47.80212574212486]],
    [ "Enedis",[-4.2629847000000005, 47.80204735]],
    [ "Enedis",[-4.27145365590355, 47.7973429746616]],
    [ "Enedis",[-4.2630411, 47.808030499999994]],
    [ "Enedis",[-4.26754047254509, 47.79865119746159]],
    [ "Enedis",[-4.255882300000001, 47.8033325]],
    [ "Enedis",[-4.268769, 47.80647800000001]],
    [ "Enedis",[1.6291082560137455, 50.71355469415808]],
    [ "Enedis",[1.6246400794400697, 50.73427564916885]],
    [ "Enedis",[1.6159147999999999, 50.705949499999996]],
    [ "Enedis",[1.6308666958811222, 50.730442536031155]],
    [ "Enedis",[1.6257456535188257, 50.725399209416906]],
    [ "Enedis",[1.6317437499999992, 50.72858675]],
    [ "Enedis",[1.6228934285714285, 50.71029661904761]],
    [ "Enedis",[1.6209477, 50.734557099999996]],
    [ "Enedis",[1.6351007, 50.72649265]],
    [ "Enedis",[1.6286233068662264, 50.71970082327075]],
    [ "Enedis",[1.6820596041666669, 50.72722309583334]],
    [ "Enedis",[1.6757256407022758, 50.732740668892184]],
    [ "Enedis",[1.6308342954650898, 50.71480177201786]],
    [ "Enedis",[1.6346488393719807, 50.7222784263285]],
    [ "Enedis",[1.660779180467091, 50.71760093977997]],
    [ "Enedis",[1.668951, 50.733430999999996]],
    [ "Enedis",[1.6154195, 50.70562549999999]],
    [ "Enedis",[1.6247214124626157, 50.73750042453896]],
    [ "Enedis",[1.6429523499999998, 50.724491099999995]],
    [ "Enedis",[1.633571361111111, 50.71363734722222]],
    [ "Enedis",[1.6294168349475382, 50.72679910714285]],
    [ "Enedis",[1.673787933069307, 50.72887586099011]],
    [ "Enedis",[1.6360116478869917, 50.72919943347198]],
    [ "Enedis",[1.6388045734989645, 50.725450435669906]],
    [ "Enedis",[1.6376476043327204, 50.716263723210034]],
    [ "Enedis",[1.6575267999999996, 50.717455900000004]],
    [ "Enedis",[1.6102496726955382, 50.709611993024396]],
    [ "Enedis",[1.6270800038152122, 50.738063761466364]],
    [ "Enedis",[1.6344420000000002, 50.7278445]],
    [ "Enedis",[1.6314638705187208, 50.72565455445714]],
    [ "Enedis",[1.6663678236774617, 50.71719188036513]],
    [ "Enedis",[1.6413795000000002, 50.727717999999996]],
    [ "Enedis",[1.6440112290249433, 50.72599608692365]],
    [ "Enedis",[1.6274232163606326, 50.73273356893034]],
    [ "Enedis",[1.6190495, 50.70797950000001]],
    [ "Enedis",[1.6228706162706985, 50.732638470842325]],
    [ "Enedis",[1.6619275000000011, 50.71597145]],
    [ "Enedis",[1.6685473418693983, 50.7309686585574]],
    [ "Enedis",[1.6131845, 50.706218400000004]],
    [ "Enedis",[1.620826652261596, 50.710977030826854]],
    [ "Enedis",[1.6225111330796327, 50.71211254670857]],
    [ "Enedis",[1.6247944419339655, 50.710099865779036]],
    [ "Enedis",[1.6347319004237288, 50.724612370291894]],
    [ "Enedis",[1.6697465393317532, 50.729526353591154]],
    [ "Enedis",[1.6311412048311373, 50.73209215909435]],
    [ "Enedis",[1.61543685, 50.7094262]],
    [ "Enedis",[1.6414687214811818, 50.7263233093416]],
    [ "Enedis",[1.6232181146514164, 50.73857054625998]],
    [ "Enedis",[1.6243096649528705, 50.71589598457584]],
    [ "Enedis",[1.6498931499999991, 50.720841549999996]],
    [ "Enedis",[1.672428630489192, 50.71894251854379]],
    [ "Enedis",[6.40586985, 46.2084024]],
    [ "Enedis",[4.01153415738426, 46.27826903517863]],
    [ "Enedis",[4.884276116203487, 45.07316837149808]],
    [ "Enedis",[4.890401571726817, 45.07571335630812]],
    [ "Enedis",[4.869750517031205, 45.06225192972113]],
    [ "Enedis",[4.8758227587654215, 45.068731430701476]],
    [ "Enedis",[4.892908634717265, 45.070390599604245]],
    [ "Enedis",[4.885435340082406, 45.05528704938622]],
    [ "Enedis",[4.86481093382249, 45.04242833523195]],
    [ "Enedis",[4.869669044475415, 45.04359615203688]],
    [ "Enedis",[6.396371408677396, 46.20657224539303]],
    [ "Enedis",[4.046630865858141, 50.009373527139466]],
    [ "Enedis",[4.0548129680313, 50.00634726117799]],
    [ "Enedis",[1.464109635398765, 43.5823804817285]],
    [ "Enedis",[-4.290573349999999, 47.802678750000005]],
    [ "Enedis",[-4.275239, 47.800698]],
    [ "Enedis",[-4.287164122767588, 47.79965514427505]],
    [ "Enedis",[-4.28355865, 47.8018715]],
    [ "Enedis",[-4.289131619124211, 47.79808670370673]],
    [ "Enedis",[-4.28731375, 47.79405285]],
    [ "Enedis",[-4.291346640557295, 47.799745743843076]],
    [ "Enedis",[-4.27689835, 47.7975368]],
    [ "Enedis",[-4.275062081917063, 47.802044515952865]],
    [ "Enedis",[-4.28603295, 47.79665795000001]],
    [ "Enedis",[4.9483533800599755, 44.05230627439532]],
    [ "ZA",[-2.217122, 48.5147435]],
    [ "P8 Champs de Foire",[-2.236821006603922, 48.52346945632942]],
    [ "Le Haras",[-2.2253714999999996, 48.519736999999985]],
    [ "Enedis",[4.853925178872727, 45.06580216717074]],
    [ "Enedis",[4.852343904779938, 45.06732337616764]],
    [ "Enedis",[4.843511821957671, 45.06955059871992]],
    [ "Enedis",[4.86054235998778, 45.060741792244784]],
    [ "Enedis",[4.839825663270839, 45.06864767437693]],
    [ "Enedis",[4.84985502948047, 45.06879008176576]],
    [ "Enedis",[4.8324841321395695, 45.072014443128204]],
    [ "Enedis",[4.844494271470614, 45.07294068348649]],
    [ "Enedis",[4.849068350294226, 45.07188318683474]],
    [ "Enedis",[4.861759828211301, 45.06961428689551]],
    [ "Enedis",[4.83541870939139, 45.07121707498416]],
    [ "Enedis",[4.8667721966453605, 45.0663626238847]],
    [ "Enedis",[4.840710524544898, 45.07108672609353]],
    [ "Enedis",[4.842658014442954, 45.07123089715105]],
    [ "Enedis",[4.862964394209647, 45.0652418354709]],
    [ "Enedis",[4.854177979941385, 45.06986064092148]],
    [ "Enedis",[4.853451396133653, 45.06771109783067]],
    [ "Enedis",[4.84527555786811, 45.070523702328046]],
    [ "Enedis",[4.8465526903872345, 45.068182357942305]],
    [ "Enedis",[4.861941488228275, 45.06855514428535]],
    [ "Enedis",[4.831166078279106, 45.07431643948956]],
    [ "Enedis",[4.857122493117807, 45.065480134160445]],
    [ "Enedis",[4.865599446997879, 45.0683688009033]],
    [ "Enedis",[-3.030266428571241, 47.60758838201082]],
    [ "Salines de Kerdual",[-3.0537970000000008, 47.588775999999996]],
    [ "Enedis",[1.4631414107190306, 43.58563170351184]],
    [ "Enedis",[1.4631190723886605, 43.58567123022467]],
    [ "Le men du",[-3.051125106344951, 47.58070924140873]],
    [ "RTE",[2.220285049275022, 43.95082592015758]],
    [ "Enedis",[-3.144807153181432, 47.70467380036985]],
    [ "Enedis",[4.065788687877047, 50.01907673099595]],
    [ "Enedis",[4.060593123119847, 50.02099529830324]],
    [ "Enedis",[4.057109974975604, 50.021713948728724]],
    [ "Enedis",[4.059721252939986, 50.02629352383228]],
    [ "Enedis",[-4.107414465361222, 47.99467750778817]],
    [ "Enedis",[-4.130155750000002, 47.990758750000005]],
    [ "Enedis",[-4.0437167, 47.983281899999994]],
    [ "Enedis",[-4.118069424407223, 47.991532853167726]],
    [ "Enedis",[-4.101279469483568, 48.00341941314554]],
    [ "Enedis",[-4.046083749999999, 47.98382345]],
    [ "Enedis",[-4.110355999999999, 48.001548]],
    [ "Enedis",[-4.088372264349377, 48.00625454673473]],
    [ "Enedis",[-3.50463675, 47.838541750000005]],
    [ "Enedis",[-3.5062252499999995, 47.84437425]],
    [ "Enedis",[-4.324312016184096, 47.91790274899302]],
    [ "94494627",[-2.7979430636704126, 48.489527602996255]],
    [ "Valbonne",[7.067499183070189, 43.616722385781664]],
    [ "Karharo",[-3.5681656000000004, 47.78169505]],
    [ "Kerrou",[-3.5589575000000004, 47.768229500000004]],
    [ "Saint-Julien",[-3.5361183000000005, 47.768054]],
    [ "Garlouet",[-3.5497057500000007, 47.81217295]],
    [ "Enedis",[-3.6100788217299575, 47.77542102953587]],
    [ "La Plage",[-3.5472901665710546, 47.766520300943654]],
    [ "Langlazic",[-3.5828962330557763, 47.7866444682958]],
    [ "Cottage Lann march",[-3.5536613333333333, 47.77108360283688]],
    [ "Enedis",[4.83896705, 49.72821185]],
    [ "Enedis",[4.844168541814495, 49.72797284321676]],
    [ "Enedis",[4.8433702, 49.72963549999999]],
    [ "Enedis",[4.856529333526472, 49.73893355492504]],
    [ "Enedis",[4.8473980550092035, 49.73522189237962]],
    [ "Enedis",[4.864558999999999, 49.74199449999999]],
    [ "Enedis",[2.392842, 49.402026500000005]],
    [ "Enedis",[2.387207821353065, 49.39881030056378]],
    [ "Enedis",[2.3593078567493118, 49.385424684572996]],
    [ "Enedis",[1.4594200917159408, 43.57326203373002]],
    [ "Enedis",[4.7541793212785395, 50.104990822648396]],
    [ "Enedis",[4.764024041792044, 50.106692443664315]],
    [ "Boisouze",[-2.009699635014009, 48.635468981034194]],
    [ "Enedis",[1.672720123076922, 47.65297318974359]],
    [ "Enedis",[1.672954928072339, 47.65591633621044]],
    [ "Enedis",[2.2663510641182456, 48.82604553754106]],
    [ "Enedis",[2.2671879628886646, 48.82697445436309]],
    [ "Enedis",[1.653580292237442, 47.587752442922366]],
    [ "Enedis",[4.851650853555211, 45.02574427706584]],
    [ "Enedis",[4.851820139140776, 45.03041406065693]],
    [ "Enedis",[0.3321357, 47.87290095000001]],
    [ "Enedis",[0.2987262612282845, 47.87233592068793]],
    [ "Poste électrique de Murigny",[4.017821657466528, 49.21694111328654]],
    [ "Lot Semaeb",[-3.578181725723761, 47.90332435779076]],
    [ "Pen ar Prat",[-3.5828250999999995, 47.9028759]],
    [ "Kerroué",[-3.6032584500000002, 47.91820415]],
    [ "Ty Bonal",[-3.5774655373921753, 47.898451970449905]],
    [ "Lethy",[-3.6056244, 47.8892862]],
    [ "Feuteuniou",[-3.6083712500000003, 47.900563999999996]],
    [ "Enedis",[0.09413997738261286, 49.51645242146071]],
    [ "Enedis",[4.853676353571423, 45.08738345235768]],
    [ "Enedis",[4.843535400000001, 45.090028999999994]],
    [ "Enedis",[4.849213830461592, 45.08685750229257]],
    [ "Enedis",[4.041997377629261, 46.068471700715044]],
    [ "Enedis",[4.050158512256686, 46.069215566691476]],
    [ "Enedis",[4.086216219213293, 46.099955954524695]],
    [ "Enedis",[4.04880467324351, 46.06791346174932]],
    [ "Enedis",[4.0515133, 46.0675767]],
    [ "Enedis",[4.058701735640205, 46.08739184150351]],
    [ "Enedis",[4.0459212007374985, 46.09321819992533]],
    [ "Palestine",[4.074799849999999, 46.071332300000016]],
    [ "Enedis",[4.044483522735806, 46.07246653165373]],
    [ "Enedis",[4.0489987964886165, 46.072145267659195]],
    [ "Soissons",[4.076729408230958, 46.07309243262257]],
    [ "Monastir",[4.07776315, 46.071882900000006]],
    [ "Enedis",[4.05087011320831, 46.06373420127229]],
    [ "Enedis",[2.727141, 48.000731]],
    [ "Enedis",[2.7473236590765335, 48.008474695129664]],
    [ "Enedis",[2.7314290893701774, 47.99191463754047]],
    [ "Enedis",[2.7434762715756134, 48.000968862496705]],
    [ "Enedis",[2.7491451016574584, 47.997567297974214]],
    [ "Enedis",[2.7334479999999997, 47.99124899999999]],
    [ "Enedis",[2.7368010882901204, 48.002103340111844]],
    [ "Enedis",[2.7413324999999995, 47.993807]],
    [ "Enedis",[2.722844274679661, 48.00407804457679]],
    [ "Ancien Palais",[2.733695240530304, 47.99653386742424]],
    [ "Enedis",[2.7292728614431176, 47.99411041257193]],
    [ "Pen ar Prat",[-3.5525938000000004, 47.92696200000001]],
    [ "Enedis",[2.721181322334574, 48.00129466169948]],
    [ "Enedis",[2.7284705, 48.00356500000001]],
    [ "Enedis",[2.7419778110871906, 47.99885096698959]],
    [ "Enedis",[2.747637, 48.0056095]],
    [ "Enedis",[2.7258856694348323, 47.99627150334486]],
    [ "Enedis",[2.7494665000000005, 47.9952675]],
    [ "Enedis",[2.739842032497679, 48.006668674094705]],
    [ "Enedis",[2.7358563421197144, 48.00103028775397]],
    [ "Enedis",[2.733517, 47.9945915]],
    [ "Enedis",[4.824625647170323, 45.77650576902248]],
    [ "Enedis",[4.822755422965235, 45.77690152721279]],
    [ "Enedis",[4.825559676295999, 45.77653447302287]],
    [ "E Sachz",[-3.4058813, 47.92565695000002]],
    [ "Enedis",[-3.416135523843396, 47.93500604167499]],
    [ "Enedis",[4.034548372688939, 50.02246353137491]],
    [ "Enedis",[-1.5936244520079195, 47.197364887533865]],
    [ "Enedis",[-1.5879848243231567, 47.197530831243164]],
    [ "Enedis",[-1.6165832589818627, 47.198427032203334]],
    [ "Poste électrique de Villarodin",[6.7152425658386115, 45.212749296450426]],
    [ "Enedis",[-1.8912858513312452, 48.62583569665482]],
    [ "Enedis",[4.043263932129376, 50.02489436904405]],
    [ "Enedis",[4.041523834284889, 50.02495332723169]],
    [ "Enedis",[4.046311811138738, 50.02514846104871]],
    [ "Enedis",[6.614179138531069, 45.92303573932426]],
    [ "Enedis",[6.611571440574048, 45.9252771464106]],
    [ "Poste électrique de Vouglans",[5.654585932782089, 46.39345430435102]],
    [ "Algérie",[6.199237000000001, 48.689756]],
    [ "Enedis",[6.196377920143113, 48.68187243205672]],
    [ "Enedis",[6.202260233840724, 48.68202516412972]],
    [ "Enedis",[6.198871619124363, 48.68636372696749]],
    [ "Enedis",[6.2050221246867165, 48.6879299536341]],
    [ "Enedis",[6.202031949526485, 48.684496168279736]],
    [ "Enedis",[6.202187713647079, 48.68704214801269]],
    [ "Enedis",[4.043229556407415, 50.02340739548094]],
    [ "Enedis",[4.045020163372404, 50.0234111409908]],
    [ "Enedis",[4.044318484607357, 50.021837249244825]],
    [ "Enedis",[3.0094834142151603, 50.626912754276724]],
    [ "Enedis",[2.9878403027860276, 50.62150595757317]],
    [ "Enedis",[2.98300474569378, 50.63282062312599]],
    [ "Enedis",[2.9864530332778703, 50.626277173599554]],
    [ "Enedis",[2.985120639607644, 50.626876835785545]],
    [ "Enedis",[2.981816, 50.631139]],
    [ "Enedis",[2.9807555, 50.6340985]],
    [ "Enedis",[4.831130495664038, 45.11325858370293]],
    [ "Enedis",[4.821190757359078, 45.113873346698284]],
    [ "Enedis",[4.81515606566842, 45.13265077709988]],
    [ "Enedis",[4.817731601615595, 45.13390884731641]],
    [ "Enedis",[1.4180350768303431, 43.57692991531722]],
    [ "Enedis",[4.049743270526216, 50.02005171292022]],
    [ "Enedis",[4.050305118777785, 50.01917465435474]],
    [ "Enedis",[4.054640266615056, 50.02056909448739]],
    [ "Enedis",[4.056793537735125, 50.01972377367534]],
    [ "Enedis",[6.769411781966524, 47.57886460943873]],
    [ "Poste électrique de Squividan",[-4.053511386932763, 48.01717124658628]],
    [ "Enedis",[4.062639743449467, 50.01654020131619]],
    [ "Enedis",[5.832338023123679, 46.10757272331306]],
    [ "Enedis",[4.056998004561378, 50.01555913700628]],
    [ "Enedis",[4.060770123515919, 50.01531954820551]],
    [ "Poste électrique de Penhars",[-4.127303734757518, 48.005883168565695]],
    [ "Enedis",[4.03598305, 50.01310065000001]],
    [ "Enedis",[4.03845878302272, 50.00996986627607]],
    [ "Moulin",[3.983443428571428, 49.26347123809524]],
    [ "Enedis",[3.993913319248828, 49.264991173708914]],
    [ "Enedis",[3.9838078920041533, 49.26567474558671]],
    [ "Enedis",[3.981265500000001, 49.2692915]],
    [ "Labes",[3.9884227422885576, 49.260911056716424]],
    [ "Enedis",[3.9937717368421057, 49.26862993567252]],
    [ "Pontons",[3.9818794999999994, 49.265777]],
    [ "Enedis",[3.990175422964264, 49.2620623681898]],
    [ "pagnol",[3.989825248356808, 49.26390400187793]],
    [ "Enedis",[3.9924642994977457, 49.27240420145098]],
    [ "Enedis",[3.9894867777777785, 49.26616679166666]],
    [ "Enedis",[3.9940260410404624, 49.2611982813102]],
    [ "Cite Verrerie",[3.9946905, 49.263613]],
    [ "Enedis",[3.999610386571483, 49.28024855936467]],
    [ "Enedis",[6.866500719491558, 49.13821316634948]],
    [ "Poste électrique de l'Aurence",[1.2225108166425813, 45.8356913639832]],
    [ "Enedis",[6.856410697346286, 49.14392895566499]],
    [ "Enedis",[6.856356237032057, 49.140090932593594]],
    [ "Enedis",[6.811564409166818, 49.13773926071278]],
    [ "Enedis",[6.814865844787151, 49.13267294106084]],
    [ "Enedis",[6.815562969692113, 49.12365900613308]],
    [ "Enedis",[6.871687482646903, 49.1211005349392]],
    [ "Enedis",[6.879278200840654, 49.12048682744975]],
    [ "Enedis",[6.877551362445812, 49.118249096170906]],
    [ "Enedis",[6.869231319726764, 49.118372188651804]],
    [ "Poste électrique de Beaubreuil",[1.2810134680109686, 45.86178493911049]],
    [ "Enedis",[3.6835887, 48.31796025]],
    [ "Enedis",[2.7294532834718375, 47.980500091412736]],
    [ "Enedis",[2.79030298342308, 47.96780928011826]],
    [ "Enedis",[2.7501351972573844, 47.99920428850211]],
    [ "Enedis",[2.758491152380952, 47.96736670476191]],
    [ "Enedis",[2.7708216090200075, 48.00010997185486]],
    [ "Enedis",[2.738115000000001, 47.9796605]],
    [ "Enedis",[2.7630212448657194, 47.99659393048974]],
    [ "Enedis",[2.7286032931553468, 47.964532369846864]],
    [ "Enedis",[2.773895696007683, 47.971544663172814]],
    [ "Enedis",[2.7303683153153147, 47.97800925225224]],
    [ "Enedis",[2.764023399389893, 47.98928169669371]],
    [ "Enedis",[2.7412716690223795, 47.97079951825678]],
    [ "Enedis",[2.7675798834710186, 47.97865771018873]],
    [ "Enedis",[2.7873848321416417, 47.977820824651005]],
    [ "Enedis",[2.7603770227038185, 47.977900668730655]],
    [ "Enedis",[2.733393255319149, 47.97749289361701]],
    [ "Enedis",[2.7717175000000007, 47.974937499999996]],
    [ "Enedis",[2.751131, 47.968668]],
    [ "Enedis",[2.7756742365591403, 47.990724827956996]],
    [ "Enedis",[2.775810233333334, 47.967071686666664]],
    [ "Enedis",[2.7877989515445187, 47.96255206359782]],
    [ "Enedis",[2.7839013243090807, 47.96850401466441]],
    [ "Enedis",[2.789939771929826, 48.00060194736842]],
    [ "Enedis",[2.74881134179357, 47.989501841159054]],
    [ "Enedis",[2.7593280000000004, 47.977246]],
    [ "Enedis",[2.787389837837838, 47.9674174954955]],
    [ "Enedis",[2.7474730752688172, 47.974318163978495]],
    [ "Enedis",[2.7431230565891473, 47.975362366666666]],
    [ "Enedis",[2.7799458465179177, 48.001975961460445]],
    [ "Enedis",[2.7331155, 47.9823625]],
    [ "Enedis",[2.759991499999999, 47.98982650000001]],
    [ "Enedis",[2.7644561378362127, 48.00148568084558]],
    [ "Enedis",[2.7503986666666664, 47.97240798373984]],
    [ "Enedis",[2.786135174564927, 47.97867153440428]],
    [ "Enedis",[2.751825136436597, 47.990163426966284]],
    [ "Enedis",[2.7616247492063493, 47.99831146349207]],
    [ "Enedis",[2.7906863872714966, 47.966006820356576]],
    [ "Enedis",[2.771063333333333, 47.99211433333334]],
    [ "Enedis",[2.771165739130435, 48.0006474057971]],
    [ "Enedis",[2.729522500000001, 47.975142000000005]],
    [ "Enedis",[2.7768714999999995, 47.9667715]],
    [ "Enedis",[2.7384965453384416, 47.97659618773946]],
    [ "Enedis",[2.735113872238233, 47.98343022382325]],
    [ "Enedis",[2.755984949331382, 48.00025061633541]],
    [ "Enedis",[2.7436244999999997, 47.989529]],
    [ "Enedis",[2.7635447819314636, 47.992535373208725]],
    [ "Enedis",[2.770426711000682, 48.00379327389653]],
    [ "Enedis",[2.773563925892857, 47.97643346170636]],
    [ "Enedis",[2.731979576558461, 47.98512575304032]],
    [ "Enedis",[2.774190756476685, 47.97520303454231]],
    [ "Enedis",[2.7338955000000005, 47.978879]],
    [ "Enedis",[2.7708191410877765, 48.00007876790522]],
    [ "Enedis",[-2.0157722653282018, 48.45115202660483]],
    [ "Enedis",[4.045017190015834, 50.01434940535335]],
    [ "Enedis",[2.4053997144049424, 49.354990527124016]],
    [ "Enedis",[6.972260652683662, 49.12013016706377]],
    [ "Poste Nord-Ouest 63 kV",[5.712207409349195, 45.1989344345487]],
    [ "Pariset",[5.699779959520622, 45.18016654148725]],
    [ "Enedis",[3.7551448396517833, 43.57947505560236]],
    [ "Enedis",[3.7674990499563066, 43.588416866686124]],
    [ "Enedis",[3.7604285000000006, 43.58589775]],
    [ "RTE",[1.2270097824914055, 45.60874023466921]],
    [ "Enedis",[4.050345372407766, 50.0109970735194]],
    [ "Enedis",[4.0495333623513545, 50.01226570377589]],
    [ "Enedis",[4.048431882438173, 50.01779170983014]],
    [ "Enedis",[4.048914161466561, 50.016107973742436]],
    [ "Enedis",[4.048253673077353, 50.017085454464144]],
    [ "Enedis",[4.052581643025407, 50.017893459435776]],
    [ "Enedis",[-1.7377395357888277, 49.6733443130755]],
    [ "Enedis",[4.966879, 43.98213260000001]],
    [ "Enedis",[5.062700400000001, 44.02068439999999]],
    [ "Enedis",[-2.0574628017480387, 48.45285921973967]],
    [ "Enedis",[1.580375584934705, 50.707539273846756]],
    [ "Enedis",[1.5754933952762924, 50.7062311697861]],
    [ "Enedis",[1.569376261754998, 50.70479866870511]],
    [ "Enedis",[1.5783801726640014, 50.70654050777432]],
    [ "Enedis",[1.5741503728790363, 50.703718179392446]],
    [ "Enedis",[1.59041394050933, 50.713567370759876]],
    [ "Enedis",[1.5710224, 50.70061580000001]],
    [ "Enedis",[1.5821210666852916, 50.72036752607906]],
    [ "Enedis",[1.5729246499999996, 50.70806785]],
    [ "Enedis",[1.5817490000000014, 50.721911000000006]],
    [ "Enedis",[1.5770004499999999, 50.710478800000004]],
    [ "Enedis",[1.5804086092017307, 50.710167254408134]],
    [ "Enedis",[1.5897811664943127, 50.715533501895905]],
    [ "Enedis",[1.5864581479088136, 50.71293495135524]],
    [ "Enedis",[1.5708574592322158, 50.70658732603164]],
    [ "Enedis",[1.5814993000000002, 50.71344030000001]],
    [ "Enedis",[1.583172169335103, 50.71239835400266]],
    [ "Enedis",[1.5834828173913034, 50.70888897487923]],
    [ "Enedis",[1.5800663140521363, 50.71271642480333]],
    [ "Enedis",[1.5713465390964068, 50.702664819281395]],
    [ "Enedis",[1.5848112499999998, 50.71109619999999]],
    [ "Enedis",[1.5880123700255238, 50.71964985458983]],
    [ "Enedis",[1.5892606013309138, 50.71814679249849]],
    [ "Enedis",[1.5808604999999998, 50.70917849999999]],
    [ "Enedis",[1.5738218119987175, 50.70499164420919]],
    [ "Enedis",[1.5839221827466319, 50.70938302963928]],
    [ "Enedis",[1.5851913499999999, 50.71433325]],
    [ "Enedis",[1.57276397156509, 50.709681494518435]],
    [ "Enedis",[1.5770019036490364, 50.708593972119736]],
    [ "Enedis",[1.5867351418805278, 50.717995173655446]],
    [ "Poste électrique de Kerhellegan",[-3.1238988913072236, 47.60999920658798]],
    [ "Enedis",[1.4141597500000007, 43.573697949999996]],
    [ "Enedis",[1.4193238936138164, 43.57334243218027]],
    [ "Enedis",[4.088872061290322, 49.99249134086022]],
    [ "Poste électrique de Volvon",[4.321383313617866, 45.55948227243152]],
    [ "Enedis",[6.937241949079663, 49.10767699153923]],
    [ "Enedis",[6.932200012064755, 49.11246297415945]],
    [ "Enedis",[6.944522755975533, 49.108667789799306]],
    [ "Enedis",[6.9460791804664135, 49.11060190591446]],
    [ "Enedis",[6.956159027780158, 49.108580704790974]],
    [ "Enedis",[6.89406362564539, 49.130639055998714]],
    [ "Enedis",[6.8815074014198165, 49.12094880401629]],
    [ "Enedis",[6.679701755696943, 49.08117935799559]],
    [ "Enedis",[6.680228264904404, 49.07904533242839]],
    [ "Enedis",[6.682336996149993, 49.069721139230005]],
    [ "Enedis",[6.683580215478763, 49.07957026724262]],
    [ "Enedis",[6.675730787175761, 49.08179634698767]],
    [ "Enedis",[6.681947079086441, 49.07714613001852]],
    [ "Enedis",[6.6851031, 49.06855295]],
    [ "Enedis",[6.671890460008451, 49.08630116104547]],
    [ "Enedis",[6.682442452655548, 49.07517491070512]],
    [ "Enedis",[2.9571100139040016, 42.758591633055254]],
    [ "Enedis",[2.951328983739837, 42.77336132520325]],
    [ "Enedis",[2.9534700000000007, 42.76342850000001]],
    [ "Enedis",[-2.070432464224164, 48.44755453038178]],
    [ "Enedis",[2.959886331641286, 42.762847974619284]],
    [ "Poste électrique de Cailloux sur Fontaines",[4.899104207960054, 45.865160617842214]],
    [ "Enedis",[1.6187725446009404, 50.72890576219636]],
    [ "Enedis",[1.6113248264765037, 50.71955401261656]],
    [ "Enedis",[1.6072286842444774, 50.72554946008707]],
    [ "Enedis",[1.6134652, 50.72216909999999]],
    [ "Enedis",[1.6100171280695423, 50.73392735655964]],
    [ "Enedis",[1.6227779022319242, 50.72968327656478]],
    [ "Enedis",[1.6080889999999999, 50.716138]],
    [ "Enedis",[1.5979981173131508, 50.72016615247554]],
    [ "Enedis",[1.615895868563686, 50.72648117859079]],
    [ "Enedis",[1.6073392729448017, 50.72322181713104]],
    [ "Enedis",[1.6091459618604649, 50.72708316806201]],
    [ "Enedis",[1.5979206334642946, 50.73686126130498]],
    [ "Enedis",[1.6100933500000003, 50.71826585]],
    [ "Enedis",[1.6051580000000003, 50.731840500000004]],
    [ "Enedis",[1.5997863999999997, 50.7124323]],
    [ "Enedis",[1.5948228109998592, 50.71572287630988]],
    [ "Enedis",[1.6091875366228114, 50.72356543208262]],
    [ "Enedis",[1.6059830877192984, 50.72942622807018]],
    [ "Enedis",[1.5912804276178554, 50.71995929080796]],
    [ "Enedis",[1.5930305399014277, 50.717111534758786]],
    [ "Enedis",[1.6138230735494816, 50.71247729517468]],
    [ "Enedis",[1.6140123929585461, 50.740270809767175]],
    [ "Enedis",[1.5923065270518515, 50.7204653216]],
    [ "Enedis",[1.602083071385098, 50.729334907242254]],
    [ "Enedis",[1.6032735, 50.723192499999996]],
    [ "Enedis",[1.6100890268646164, 50.72546533121245]],
    [ "Enedis",[1.6136866375220282, 50.72433917012336]],
    [ "Enedis",[1.6183553549419771, 50.71346815286114]],
    [ "Enedis",[1.6083194558204763, 50.724806918186076]],
    [ "Enedis",[1.6083054564111563, 50.72857100506743]],
    [ "Enedis",[1.6145301755722865, 50.73028670402116]],
    [ "Enedis",[1.5962716154581453, 50.73969566147754]],
    [ "Enedis",[1.6014778830519558, 50.71732590037471]],
    [ "Enedis",[1.6215289999999996, 50.7184105]],
    [ "Enedis",[1.6136602212075093, 50.72020565516659]],
    [ "Enedis",[1.6162343999999995, 50.723044949999995]],
    [ "Enedis",[1.5949788309022148, 50.71409498703403]],
    [ "Enedis",[1.623428082891432, 50.72030468462182]],
    [ "Enedis",[1.5913227656924436, 50.717163078034844]],
    [ "Enedis",[1.6097675972350938, 50.72896633047576]],
    [ "Enedis",[1.6051888129812981, 50.72113665830583]],
    [ "Enedis",[1.6162842296918771, 50.72081944817928]],
    [ "Enedis",[-2.044970984992056, 48.474931382314445]],
    [ "Enedis",[1.9360887999374121, 43.91136300015326]],
    [ "Enedis",[1.9584955613980468, 43.914284400761076]],
    [ "Enedis",[1.955976577472152, 43.91572845605245]],
    [ "Enedis",[-2.1456774258221047, 48.60287494393691]],
    [ "Enedis",[-2.1580803259911896, 48.605436525697506]],
    [ "Enedis",[-2.1499523152478957, 48.6092293221079]],
    [ "Enedis",[-2.1503534221014498, 48.60628855887682]],
    [ "Enedis",[-2.150805, 48.613111499999995]],
    [ "Enedis",[-2.154865803278689, 48.60789137704918]],
    [ "Enedis",[-2.141543637426901, 48.59189469590643]],
    [ "Enedis",[-2.1487165999999998, 48.59844905]],
    [ "Enedis",[-2.1432486042086403, 48.60619916879337]],
    [ "Enedis",[1.6194329705271902, 50.73554508551266]],
    [ "Enedis",[1.6164849400171382, 50.72617908944697]],
    [ "Enedis",[1.610782041077441, 50.73958082065096]],
    [ "Enedis",[1.6176042937293729, 50.73252806930692]],
    [ "Enedis",[1.6176797617994103, 50.73072617477875]],
    [ "Enedis",[1.6203751066158978, 50.7315587783595]],
    [ "Enedis",[1.6092863500000003, 50.71150520000001]],
    [ "Enedis",[1.60457278081211, 50.72669915556919]],
    [ "Enedis",[1.6135073220648615, 50.7359173539892]],
    [ "Enedis",[1.6029095535254163, 50.727503161161856]],
    [ "Enedis",[1.6151922751562162, 50.71543106140918]],
    [ "Enedis",[1.5999238963854223, 50.71908770149356]],
    [ "Enedis",[1.599996662665067, 50.727891669467795]],
    [ "Enedis",[1.6162151174060735, 50.73930600338523]],
    [ "Enedis",[1.6022413632805468, 50.725896419023364]],
    [ "Enedis",[1.607197617297698, 50.73079642065701]],
    [ "Enedis",[1.6178575, 50.7115595]],
    [ "Enedis",[1.617761744075021, 50.71831450417732]],
    [ "Enedis",[1.6209724133820584, 50.72656501722788]],
    [ "Enedis",[1.6071920000000002, 50.7348485]],
    [ "Enedis",[1.5941987303619398, 50.716860257942145]],
    [ "Enedis",[1.6193024100719424, 50.72710231654677]],
    [ "Enedis",[6.417388823129252, 46.141825918367346]],
    [ "Enedis",[1.6143310999999994, 50.734445550000004]],
    [ "Enedis",[1.6237131, 50.7221386]],
    [ "Enedis",[1.596290515834956, 50.73057962074352]],
    [ "Enedis",[1.615848993621545, 50.712395763760924]],
    [ "Enedis",[1.5994514337532588, 50.729326498730146]],
    [ "Enedis",[1.6158437484035735, 50.716428221796505]],
    [ "Enedis",[1.6208858108672934, 50.72824933676669]],
    [ "Enedis",[1.6072169631857904, 50.732415647328466]],
    [ "Enedis",[1.620111833958594, 50.71671403501459]],
    [ "Enedis",[1.6010175500000001, 50.729096950000006]],
    [ "Enedis",[1.6110187325364214, 50.727881855186155]],
    [ "Enedis",[1.6016343499999997, 50.72545074999999]],
    [ "Enedis",[1.6144124000000002, 50.72870095000001]],
    [ "Enedis",[1.6332152500000001, 50.72345]],
    [ "Enedis",[1.5972771499999991, 50.71466370000002]],
    [ "Enedis",[1.6180854171345398, 50.721511363069254]],
    [ "Enedis",[1.6114030231578949, 50.721761171228074]],
    [ "Enedis",[0.7159617500000001, 43.11215864999999]],
    [ "Enedis",[0.7258722915779801, 43.101291416264715]],
    [ "Enedis",[0.74277364373133, 43.11402553148381]],
    [ "Enedis",[0.718329817281252, 43.110985346784744]],
    [ "Enedis",[0.7351234361603236, 43.12641930722813]],
    [ "Enedis",[0.7171990425185079, 43.10891517710483]],
    [ "Enedis",[0.7493214000000001, 43.11515574999999]],
    [ "Enedis",[0.7218230342096668, 43.10802910026767]],
    [ "Enedis",[0.7166417387103184, 43.10529331657833]],
    [ "Enedis",[0.7298369469189442, 43.10648433801785]],
    [ "Enedis",[0.7220571163543803, 43.109504568734145]],
    [ "Enedis",[0.7251271659498623, 43.11496053214444]],
    [ "Enedis",[0.7310222812572741, 43.11466921769523]],
    [ "Enedis",[0.7136210566179944, 43.107185114563606]],
    [ "Enedis",[0.7371589664162042, 43.115075327496456]],
    [ "Enedis",[0.7498988672396782, 43.11705145099434]],
    [ "Enedis",[0.7358127, 43.107098750000006]],
    [ "Enedis",[0.7449187262688295, 43.11664528337348]],
    [ "Enedis",[0.7401304500000004, 43.118319500000005]],
    [ "Enedis",[0.7214044857609734, 43.112717228657736]],
    [ "RTE",[0.725647585103473, 43.09454883595528]],
    [ "Enedis",[0.7386987502982496, 43.10817938565725]],
    [ "Enedis",[0.7521085168867079, 43.114973554598755]],
    [ "Enedis",[4.0939541717635075, 49.989959361875634]],
    [ "Enedis",[4.094968604579478, 49.993055620873434]],
    [ "Enedis",[4.098693107867392, 49.99153328599703]],
    [ "Enedis",[6.45705511637081, 46.136038246548324]],
    [ "Enedis",[-1.2400163999999998, 46.77790365000001]],
    [ "Enedis",[-1.5930603654564457, 48.58847267886671]],
    [ "Enedis",[4.8238312493638675, 45.78377703562341]],
    [ "Poste électrique des Casseaux",[1.2750748311984343, 45.831952918198034]],
    [ "Enedis",[-1.2263063414497735, 46.773129078379164]],
    [ "Enedis",[4.099774290282903, 49.989041622386225]],
    [ "Enedis",[4.0968684999999985, 49.9864199]],
    [ "Poste électrique de Remire - Dégrad des Cannes",[-52.27421274999999, 4.863861750000001]],
    [ "Enedis",[3.264693067961435, 50.33679063430789]],
    [ "Enedis",[3.246209188327946, 50.32780823014436]],
    [ "Enedis",[3.2548579214352786, 50.325791662334005]],
    [ "Enedis",[3.255323594828874, 50.32512735323067]],
    [ "Enedis",[3.252471836085087, 50.33539685450207]],
    [ "Enedis",[3.2579354972549948, 50.33643728831006]],
    [ "Enedis",[3.2444689921693146, 50.332210328267095]],
    [ "Enedis",[3.251167108654912, 50.32668160999503]],
    [ "Enedis",[3.255187823300519, 50.32684348335499]],
    [ "Enedis",[3.25095326580658, 50.32979663269835]],
    [ "Enedis",[3.2520165889316908, 50.33786432638388]],
    [ "Enedis",[3.2605189012268188, 50.326644864907266]],
    [ "Enedis",[3.2484399239268713, 50.32082148660387]],
    [ "Enedis",[3.258550990375046, 50.32302924459691]],
    [ "Enedis",[-1.7007878166089971, 48.093389029988465]],
    [ "Enedis",[3.2581421954120837, 50.33573351319288]],
    [ "Enedis",[3.2610008012965124, 50.33321402309088]],
    [ "Enedis",[3.2569219575256887, 50.32988984035018]],
    [ "Cité de l'air",[-1.718253178925405, 48.066174159624424]],
    [ "Enedis",[4.8779555408113096, 43.97871561548863]],
    [ "Enedis",[4.878133999999999, 43.983443449999996]],
    [ "Enedis",[4.88232985, 43.97268905000001]],
    [ "Enedis",[4.8615617110612295, 43.96564434336927]],
    [ "Enedis",[4.859250283191233, 43.97823574880682]],
    [ "Enedis",[4.858490036523576, 43.97635028343563]],
    [ "Enedis",[4.879592181525025, 43.979683195845524]],
    [ "Enedis",[4.869054467237688, 43.97868711590052]],
    [ "Enedis",[4.874696712636924, 43.97977459804487]],
    [ "Enedis",[4.867882511138461, 43.97286777662173]],
    [ "Enedis",[4.861621595869307, 43.98624832237296]],
    [ "Enedis",[4.856245905827151, 43.96999003316594]],
    [ "Enedis",[4.882031231988303, 43.980481958669586]],
    [ "Enedis",[4.866107204871736, 43.97673427434471]],
    [ "Enedis",[4.860273597935105, 43.96331861681416]],
    [ "Enedis",[4.856964498363177, 43.974562113519546]],
    [ "Enedis",[4.869144450000001, 43.97666355]],
    [ "Enedis",[2.0288435000000002, 50.367157500000005]],
    [ "Enedis",[2.045811037626628, 50.377741673420154]],
    [ "Enedis",[1.4159303986889955, 43.58499873451709]],
    [ "Enedis",[1.412923942279056, 43.586691219496515]],
    [ "Enedis",[4.093631920881971, 49.98110327107653]],
    [ "RTE",[2.599547825687486, 48.776495290884334]],
    [ "Poste électrique de Bastillac",[0.04009916823256439, 43.225317361209]],
    [ "Poste source de Saint-Maur",[2.4771132865585437, 48.81180177722157]],
    [ "Poste électrique du Palais",[1.3272968214169836, 45.86286291270628]],
    [ "Enedis",[6.060521667666916, 46.34106849337334]],
    [ "Enedis",[6.056752213991769, 46.32478541926894]],
    [ "Enedis",[6.061294737760034, 46.344497921832314]],
    [ "Enedis",[6.059631790960453, 46.32934738418079]],
    [ "Enedis",[6.051781338871139, 46.35144456869009]],
    [ "Enedis",[6.074217156573922, 46.33935966175668]],
    [ "Enedis",[6.058637696233722, 46.32346409362901]],
    [ "Enedis",[6.053347960986067, 46.327371155198286]],
    [ "Enedis",[6.054640344619106, 46.34288553315195]],
    [ "Enedis",[6.052504999999998, 46.34937124999999]],
    [ "Enedis",[6.066625615384616, 46.34158666666668]],
    [ "Enedis",[6.073258839890555, 46.33977015463724]],
    [ "Enedis",[6.033601392886684, 46.36032775103391]],
    [ "Enedis",[6.055258012570438, 46.33273766016471]],
    [ "Enedis",[6.071832108215509, 46.33793570067461]],
    [ "Enedis",[6.05744263683818, 46.33601223639075]],
    [ "Enedis",[6.087081823997143, 46.32536851102319]],
    [ "Enedis",[6.07159, 46.335598]],
    [ "Enedis",[6.056460290248001, 46.32996063408994]],
    [ "Enedis",[6.06899221072797, 46.33582854406129]],
    [ "Enedis",[6.060819198347106, 46.3186098292011]],
    [ "Enedis",[6.050594299131476, 46.32664107810722]],
    [ "Enedis",[6.049156516556292, 46.354927832229585]],
    [ "Enedis",[6.085038053435115, 46.320390760814256]],
    [ "Enedis",[6.05467332815735, 46.34433202484472]],
    [ "Enedis",[6.075671658486707, 46.34046198773006]],
    [ "Enedis",[6.0903568213535095, 46.32346086717108]],
    [ "Enedis",[6.058365640217024, 46.34019855306884]],
    [ "Enedis",[6.052645268133176, 46.32346843935791]],
    [ "Enedis",[6.051167200828158, 46.32504215113872]],
    [ "Enedis",[6.059445155216284, 46.347727170483466]],
    [ "Enedis",[6.074484780013414, 46.33722454258887]],
    [ "Enedis",[6.063666151515152, 46.33623960606061]],
    [ "Poste électrique de Gennes",[6.14306994552979, 47.243694993966166]],
    [ "ESSeyssel",[6.023699594541366, 45.96454986383848]],
    [ "Enedis",[4.091911, 47.51368]],
    [ "Enedis",[3.2743135069351235, 48.16909682684564]],
    [ "Enedis",[3.2556600813790344, 48.156165792744034]],
    [ "Enedis",[3.2806395000000004, 48.16353049999999]],
    [ "Enedis",[3.271621175655088, 48.16971399299207]],
    [ "Enedis",[3.2674832129864635, 48.164215208862096]],
    [ "Enedis",[3.268878853679655, 48.17056352066699]],
    [ "Enedis",[3.2647570639995034, 48.17219480241905]],
    [ "Enedis",[3.1319896353677623, 48.26821418466353]],
    [ "Enedis",[3.2582925, 48.113800499999996]],
    [ "Enedis",[3.0298543202915216, 48.173290323360185]],
    [ "Enedis",[3.030372010416668, 48.17044373958334]],
    [ "Enedis",[3.3296647866497677, 48.18088464835939]],
    [ "Enedis",[3.328438359172455, 48.18106097057093]],
    [ "Enedis",[3.3268712479882954, 48.18146911582541]],
    [ "Enedis",[3.3383736428184276, 48.179285800542004]],
    [ "Enedis",[3.3352110172494176, 48.178377237762234]],
    [ "Enedis",[3.331292321464903, 48.17871970498474]],
    [ "Enedis",[3.3302330170132324, 48.18108340012603]],
    [ "Enedis",[6.073024499999999, 46.314542]],
    [ "Enedis",[3.3335355471571906, 48.180152834336674]],
    [ "Enedis",[6.065213699260212, 46.327374458239525]],
    [ "Enedis",[6.073375154525388, 46.31939945695364]],
    [ "Enedis",[6.075186347490346, 46.313060844015446]],
    [ "Enedis",[6.0661955945945945, 46.326274658697166]],
    [ "Enedis",[6.065426921104537, 46.31820169033531]],
    [ "Enedis",[6.094547600228605, 46.321235663380484]],
    [ "Enedis",[6.066794464669739, 46.30261976113672]],
    [ "Enedis",[6.068232666666667, 46.323290009009]],
    [ "Enedis",[6.062180578824192, 46.32573359290214]],
    [ "Enedis",[6.068254, 46.326417500000005]],
    [ "Enedis",[6.071890455384615, 46.31197977374359]],
    [ "Enedis",[6.074553703021719, 46.317943139046264]],
    [ "Enedis",[6.073742643312102, 46.3170071953291]],
    [ "Enedis",[6.089345500000001, 46.32134550000001]],
    [ "Enedis",[6.068611990049751, 46.3209248358209]],
    [ "Enedis",[6.0660384999999994, 46.3041885]],
    [ "Enedis",[6.0706910333333335, 46.319228388262914]],
    [ "Enedis",[3.3063086335732756, 48.174025204706965]],
    [ "Enedis",[6.068695332360949, 46.31264599533256]],
    [ "Enedis",[6.081785495843815, 46.3183019977469]],
    [ "Enedis",[6.0768601674958544, 46.31771599539341]],
    [ "Enedis",[3.302521840340909, 48.17501511363636]],
    [ "Enedis",[6.080133817805383, 46.31765393466759]],
    [ "Enedis",[6.067172692151754, 46.32308004141327]],
    [ "Enedis",[6.077772991860464, 46.314703870155036]],
    [ "Enedis",[6.116652500000001, 46.3369265]],
    [ "Enedis",[6.101374891560583, 46.33258367609617]],
    [ "Enedis",[-1.230814017487492, 46.76851160239363]],
    [ "Enedis",[-1.2291836, 46.769982150000004]],
    [ "Enedis",[-1.2332109227869386, 46.770414744298726]],
    [ "Enedis",[-0.4616722999999999, 43.844488999999996]],
    [ "Enedis",[-0.4597554831818905, 43.843300151729636]],
    [ "Enedis",[2.363778410853326, 48.66265261078303]],
    [ "Enedis",[5.674148206720149, 48.35434780839437]],
    [ "Enedis",[2.3658331340343492, 48.67761893071884]],
    [ "Enedis",[2.3718470503976854, 48.67798475680571]],
    [ "Enedis",[5.479561504742478, 48.38360759651539]],
    [ "Enedis",[6.9341175999999995, 45.99727565]],
    [ "Enedis",[2.3920282761557625, 48.67459726862028]],
    [ "Enedis",[2.3788469621524313, 48.68494623549593]],
    [ "Enedis",[4.0191476, 50.01707404999999]],
    [ "Enedis",[2.3644038990244307, 48.66918341261968]],
    [ "Enedis",[2.3661745751670367, 48.66594510896639]],
    [ "Enedis",[2.3629778491337405, 48.6670439662969]],
    [ "Enedis",[2.362266512328842, 48.67371492138795]],
    [ "Enedis",[2.35562455, 48.6705685]],
    [ "Enedis",[2.369151436251461, 48.66272440417427]],
    [ "Enedis",[4.8659682361661725, 43.992575902441885]],
    [ "Enedis",[2.3657578444989533, 48.67037988313289]],
    [ "Enedis",[4.875366099538412, 44.017985627649985]],
    [ "Enedis",[4.91068887663124, 43.9932341856315]],
    [ "Enedis",[4.8864044931389286, 43.98470079756095]],
    [ "Enedis",[4.8761652235252315, 44.01530677887941]],
    [ "Enedis",[4.891286405399682, 43.989158872666316]],
    [ "Enedis",[4.8593951041501615, 44.02572845084504]],
    [ "Enedis",[4.874679150000001, 44.01219365]],
    [ "Enedis",[4.8716121999999995, 43.992238699999994]],
    [ "Enedis",[4.874897355423081, 43.990300760234]],
    [ "Enedis",[4.893493349999999, 44.00451565]],
    [ "Enedis",[4.897976, 43.98956485]],
    [ "Enedis",[4.868674250826497, 44.00087117916109]],
    [ "Enedis",[4.8742944201792895, 43.99898459098494]],
    [ "Enedis",[4.872023022096249, 44.006332911546615]],
    [ "Enedis",[4.885110500040737, 44.0003112795056]],
    [ "Enedis",[4.887095077646959, 44.00438608456045]],
    [ "Enedis",[4.885618384344418, 44.012450660184825]],
    [ "Enedis",[4.8860847060215615, 44.02493822214232]],
    [ "Enedis",[4.870961793695677, 44.011221566237964]],
    [ "Enedis",[4.87817286068338, 44.00808208724432]],
    [ "Enedis",[4.86676796916351, 43.99628123322952]],
    [ "Enedis",[4.845763851776853, 44.00349727503997]],
    [ "Enedis",[4.9018371751681675, 44.00324440619543]],
    [ "Enedis",[4.870209623318616, 44.00439733713553]],
    [ "Enedis",[4.910983223263454, 44.91330304109491]],
    [ "Enedis",[4.8608774621546225, 44.01808932462474]],
    [ "Enedis",[4.859816609853508, 44.01484967568977]],
    [ "Enedis",[4.886777010884656, 44.00553514993353]],
    [ "Enedis",[4.876408028714234, 44.01175350088172]],
    [ "Enedis",[4.88367615, 44.002458149999995]],
    [ "Enedis",[4.866794100882256, 44.01638124772464]],
    [ "Enedis",[6.186488249999999, 47.087965]],
    [ "Enedis",[6.193427501419624, 47.08197725068505]],
    [ "Enedis",[6.189023166979924, 47.082509317398625]],
    [ "Enedis",[7.222485, 49.04790299999999]],
    [ "Enedis",[7.2232143623836125, 49.06776163314711]],
    [ "ESSeyssel",[6.036774679826158, 45.973240196603996]],
    [ "Sous-station des Meunières",[4.871859937155364, 45.922453991588256]],
    [ "Enedis",[2.375822391335384, 48.679535699810906]],
    [ "Enedis",[2.3749670699343244, 48.67367158348691]],
    [ "Enedis",[2.3785902684762323, 48.68101888388864]],
    [ "Enedis",[4.891947711061834, 44.92181141439109]],
    [ "Enedis",[2.3775025844403115, 48.6759529124961]],
    [ "Enedis",[2.3707609778845615, 48.670307514019946]],
    [ "Enedis",[2.374824606210907, 48.675083102371296]],
    [ "Enedis",[2.374128746789449, 48.66523809592421]],
    [ "Enedis",[-3.758724690521029, 47.83010559698682]],
    [ "Enedis",[0.8352054230730169, 43.380587105152564]],
    [ "Enedis",[5.829884431830647, 48.30290304826149]],
    [ "RTE",[4.818739906071113, 45.89941234603255]],
    [ "Enedis",[5.360739296744791, 43.35443192621528]],
    [ "Enedis",[5.364372228179228, 43.360291593887595]],
    [ "Enedis",[5.3648961695616215, 43.3564283349876]],
    [ "Enedis",[5.365227343725642, 43.354932813198225]],
    [ "Enedis",[5.36256327147541, 43.363950200655744]],
    [ "Enedis",[5.373284853493223, 43.35843297636427]],
    [ "Enedis",[5.364904912221472, 43.35819819153725]],
    [ "Enedis",[5.363315111675128, 43.35429480203045]],
    [ "Enedis",[5.365928940483975, 43.35703519184653]],
    [ "Enedis",[6.0168305, 46.54637275000001]],
    [ "Enedis",[6.0159338, 46.5338739]],
    [ "Enedis",[6.00846, 46.53741275]],
    [ "Enedis",[6.010158045712677, 46.54049768218787]],
    [ "Enedis",[6.015144350000002, 46.53767485000001]],
    [ "Enedis",[6.018099180212753, 46.54107099569271]],
    [ "Enedis",[6.016364449999999, 46.55297805000001]],
    [ "Enedis",[6.018282333038348, 46.561041908048885]],
    [ "Enedis",[5.977255255054644, 46.520465001065546]],
    [ "Enedis",[6.01694325, 46.535795]],
    [ "Enedis",[6.01329775, 46.534867]],
    [ "Enedis",[6.0113495056150255, 46.55164500428974]],
    [ "Enedis",[5.9955948, 46.534283099999996]],
    [ "Enedis",[7.171242822982778, 48.04345485533306]],
    [ "Enedis",[7.1745692318840595, 48.04222696618357]],
    [ "Enedis",[5.366324886792454, 43.35114471698113]],
    [ "Enedis",[4.0150872082071976, 49.69638647740778]],
    [ "Enedis",[4.024742804113436, 49.690813868623714]],
    [ "Enedis",[4.011398318603383, 49.69449616584834]],
    [ "Poste électrique des Preles",[3.6080050530849657, 47.80799316483474]],
    [ "Enedis",[4.007725045584046, 50.016505653259586]],
    [ "Enedis",[3.8801581499999998, 43.65755720000001]],
    [ "97895982",[3.8874371298701313, 43.66580383116883]],
    [ "Enedis",[5.199780999999999, 45.43193255]],
    [ "Enedis",[3.4600964999999997, 43.61862925]],
    [ "Enedis",[3.4549689999999997, 43.6166356]],
    [ "Enedis",[3.4205720000000004, 43.62751425]],
    [ "Enedis",[3.4235019999999996, 43.6256362]],
    [ "Barrière",[3.417040578545577, 43.63243903325472]],
    [ "Enedis",[3.41927875, 43.630331500000004]],
    [ "Enedis",[3.4524964375770586, 43.61778263008743]],
    [ "Arnet",[3.418775250000001, 43.6275125]],
    [ "Fontrouge",[3.4166710000000005, 43.629908500000006]],
    [ "Enedis",[3.4195256000000005, 43.625626999999994]],
    [ "Enedis",[3.41902125, 43.633969]],
    [ "Enedis",[3.4524219499999984, 43.62168324999999]],
    [ "Enedis",[3.4147242499999995, 43.623673249999996]],
    [ "Enedis",[3.4530053000000005, 43.618573000000005]],
    [ "Enedis",[1.9799523000000003, 43.85386655]],
    [ "Enedis",[7.530239200000001, 47.6145765]],
    [ "Bourg",[-2.1685275, 48.479976]],
    [ "Enedis",[-1.9097985, 46.7456515]],
    [ "Enedis",[-1.8849994885130241, 46.76792202931781]],
    [ "Enedis",[-1.914551, 46.7428695]],
    [ "Enedis",[6.302099126890021, 46.21418163556296]],
    [ "Enedis",[4.03382746662231, 49.43058132401863]],
    [ "Poste électrique de Villechétive",[3.479172191891807, 48.09775596894562]],
    [ "Poste source de Malgovert",[6.789339299501406, 45.619327853368496]],
    [ "Enedis",[4.907745951633246, 44.91760593529022]],
    [ "Enedis",[6.026665, 46.51657175]],
    [ "Enedis",[6.024276966734861, 46.531193045374195]],
    [ "Enedis",[6.012604253575736, 46.52846236360473]],
    [ "Enedis",[6.022417269473645, 46.52232161638033]],
    [ "Enedis",[6.025513000000001, 46.51516675]],
    [ "Enedis",[6.024438749999999, 46.520281999999995]],
    [ "Enedis",[6.024383223121232, 46.5175833094667]],
    [ "Enedis",[6.023535999999999, 46.51601900000001]],
    [ "Enedis",[6.029081902151718, 46.52360616880922]],
    [ "Enedis",[6.025667550000001, 46.52923155]],
    [ "Enedis",[6.020965250000001, 46.5312385]],
    [ "Enedis",[6.0243135, 46.52839149999999]],
    [ "Enedis",[6.0279486841411325, 46.52239069570939]],
    [ "Enedis",[6.01822552911167, 46.530572679800535]],
    [ "Enedis",[6.020793749999999, 46.521688]],
    [ "Enedis",[6.02757925, 46.527266749999995]],
    [ "Enedis",[6.0200692, 46.5181067]],
    [ "Enedis",[6.0230463499999995, 46.518195000000006]],
    [ "Enedis",[6.01897110056958, 46.52009765019129]],
    [ "Enedis",[6.026471500000001, 46.52177725000001]],
    [ "Enedis",[2.4674019024529374, 50.88500072808519]],
    [ "Enedis",[2.468052172137126, 50.873778469000726]],
    [ "Enedis",[2.486454331986143, 50.88503646834103]],
    [ "Enedis",[2.4721514999999994, 50.878124500000006]],
    [ "Enedis",[2.4609449999999997, 50.87882750000001]],
    [ "Enedis",[2.486390918838103, 50.883166184251756]],
    [ "Enedis",[2.4746385, 50.88015850000001]],
    [ "Enedis",[2.462920226811104, 50.8832826402618]],
    [ "Enedis",[2.466117449235474, 50.87913692568807]],
    [ "Enedis",[2.459368021529323, 50.88387718040089]],
    [ "Enedis",[2.4627376410912194, 50.89170226513213]],
    [ "Enedis",[2.4710048333333328, 50.88038933333334]],
    [ "Enedis",[2.462376325753269, 50.87712597820731]],
    [ "Enedis",[2.4652703542798027, 50.88947771735196]],
    [ "Enedis",[2.478982272855132, 50.882689226441634]],
    [ "Enedis",[2.4650935000000014, 50.882801500000014]],
    [ "Enedis",[4.943233213862762, 44.86506568243195]],
    [ "Enedis",[4.953165883195021, 44.8561217330156]],
    [ "Enedis",[4.94630495709294, 44.86643823008321]],
    [ "Enedis",[4.944087778753065, 44.85957454006544]],
    [ "Enedis",[4.94743178845315, 44.864296139210786]],
    [ "Enedis",[4.948182787824136, 44.86312991458612]],
    [ "Enedis",[4.941599730214273, 44.86132832158675]],
    [ "Enedis",[4.952379318621179, 44.85674537275528]],
    [ "Enedis",[4.940358540684184, 44.871483977794774]],
    [ "Enedis",[2.2336154728529216, 48.6065349989288]],
    [ "Enedis",[2.23075753211899, 48.6016985998099]],
    [ "Enedis",[2.2312342515104873, 48.60174434375136]],
    [ "Enedis",[2.213195228776564, 48.59109221361287]],
    [ "Enedis",[2.239143857254871, 48.6017490457615]],
    [ "Enedis",[2.212879191819114, 48.59244149678788]],
    [ "Enedis",[2.2326927, 48.59102634999999]],
    [ "Enedis",[2.2204084642250455, 48.59100412847357]],
    [ "Enedis",[2.220055219528816, 48.589544926008315]],
    [ "Enedis",[2.2370642389338307, 48.591470706119466]],
    [ "Enedis",[-2.1921339346927886, 48.197508352521794]],
    [ "Enedis",[-0.09131034984986247, 49.274631054265576]],
    [ "Enedis",[4.011747671503627, 50.014128677257936]],
    [ "Serraliere",[5.797621668728014, 45.17425306617262]],
    [ "Poste électrique de Vaux",[-1.0591894795329668, 45.660522875568134]],
    [ "Enedis",[4.823893282341684, 45.78214160392826]],
    [ "Enedis",[3.1024418547008548, 50.2275537008547]],
    [ "Enedis",[2.6635043343982963, 50.54738198935037]],
    [ "Enedis",[2.6597160000000004, 50.5481795]],
    [ "Enedis",[2.6561060469109026, 50.549768825358186]],
    [ "Enedis",[2.6615820480718435, 50.548980831484414]],
    [ "Enedis",[2.6506126342899186, 50.54564417218543]],
    [ "Enedis",[4.99102955, 43.57843865]],
    [ "Enedis",[4.991311749999999, 43.53374895000001]],
    [ "Enedis",[4.9813769500000005, 43.543854350000004]],
    [ "Enedis",[5.009909939878569, 43.546861324779265]],
    [ "Enedis",[4.99924355784613, 43.531714576630954]],
    [ "Enedis",[6.910601880608602, 49.17229341655023]],
    [ "Enedis",[6.918639185645961, 49.1780655152779]],
    [ "Enedis",[6.919171991080276, 49.17001233168153]],
    [ "Enedis",[5.002121868340077, 43.514356551220644]],
    [ "Enedis",[6.921730999999999, 49.172025000000005]],
    [ "Enedis",[-0.09351171019209625, 49.275628718157876]],
    [ "Enedis",[-0.0946652559408872, 49.27841695522334]],
    [ "Enedis",[-0.0931164474494891, 49.278901898516224]],
    [ "Enedis",[-0.09961594066669054, 49.27976913609905]],
    [ "Poste électrique de La Gaudière",[2.6774013967641936, 43.24104518562649]],
    [ "La Lilette",[6.094005651498737, 46.2996929588299]],
    [ "Enedis",[6.094854498089172, 46.29692197070063]],
    [ "Mairie",[6.097778637970791, 46.305075182679985]],
    [ "Versonnex",[6.096427007951453, 46.301876173048754]],
    [ "Les Rippes",[6.098947693848354, 46.296965540772526]],
    [ "Écureuils Verson",[6.100992499999999, 46.2958395]],
    [ "Centre technique / CPI",[6.100758967741935, 46.30178535483871]],
    [ "Genévriers",[6.1027396, 46.3068131]],
    [ "Perdrix",[6.104521948846621, 46.294686024227694]],
    [ "Enedis",[6.095891499999999, 46.311010499999995]],
    [ "Villars dame",[6.112028377128953, 46.30400237469586]],
    [ "Bois chatton",[6.104577261394103, 46.296101949955315]],
    [ "Chemin levé",[6.095803809353944, 46.31099007847801]],
    [ "Enedis",[2.1730518870523414, 47.200754278236914]],
    [ "98939367",[6.740834562546383, 45.2308318833767]],
    [ "Poste électrique de Setier",[3.345994851015603, 49.84306065682443]],
    [ "Enedis",[-0.10000197088224459, 49.276793479173975]],
    [ "Enedis",[-0.10012143897765977, 49.27889242079052]],
    [ "Perroud",[5.809381115765187, 45.17113586166795]],
    [ "réséda",[6.13655905, 49.1518458]],
    [ "réséda",[6.159300899999996, 49.143041399999994]],
    [ "réséda",[6.153576999999999, 49.151892999999994]],
    [ "réséda",[6.1522575, 49.181966]],
    [ "réséda",[6.1555838000000005, 49.1553997]],
    [ "réséda",[6.1471172292517, 49.156331097959175]],
    [ "réséda",[6.146452934622468, 49.151474837937386]],
    [ "réséda",[6.151091199999998, 49.168940600000006]],
    [ "réséda",[6.147292297727643, 49.15432710652281]],
    [ "réséda",[6.1615042, 49.1804665]],
    [ "réséda",[6.159570174829222, 49.14840050642583]],
    [ "réséda",[6.1529565, 49.1485135]],
    [ "réséda",[6.157759802705497, 49.15921805029159]],
    [ "réséda",[6.1486873225963885, 49.143599475841874]],
    [ "réséda",[6.1472464272726555, 49.1452868461589]],
    [ "réséda",[6.152238567789742, 49.144140624985525]],
    [ "réséda",[6.153234599999999, 49.153428600000005]],
    [ "réséda",[6.160205383567871, 49.14680500019884]],
    [ "réséda",[6.159929407294543, 49.1447026813426]],
    [ "réséda",[6.148931999999999, 49.149403]],
    [ "réséda",[6.158984691785983, 49.151002551285266]],
    [ "réséda",[6.1617785000000005, 49.17432250000001]],
    [ "réséda",[6.153245887226082, 49.16626123570284]],
    [ "réséda",[6.1588457379811965, 49.15891261344687]],
    [ "réséda",[6.161420487248787, 49.148631329660425]],
    [ "réséda",[6.163559, 49.15217]],
    [ "réséda",[6.14050105, 49.15762805000001]],
    [ "réséda",[6.1626829215726024, 49.16790844196626]],
    [ "réséda",[6.144608700000001, 49.14468290000001]],
    [ "réséda",[6.138823351941061, 49.15288324718995]],
    [ "réséda",[6.1596646999999995, 49.144491499999994]],
    [ "réséda",[6.153185606643201, 49.14272933107655]],
    [ "réséda",[6.134359419301895, 49.15617823572991]],
    [ "réséda",[6.147165390277887, 49.14846377392824]],
    [ "réséda",[6.14346988905145, 49.15415371689831]],
    [ "réséda",[6.1575195, 49.152409000000006]],
    [ "Enedis",[5.0164556000000005, 43.4049422]],
    [ "Enedis",[5.037951303299534, 43.40920138086467]],
    [ "Enedis",[5.038236217703751, 43.331546978682006]],
    [ "Enedis",[5.046845915015752, 43.332121521375136]],
    [ "Enedis",[5.074369967199764, 43.395959036657814]],
    [ "Enedis",[5.0759885221468934, 43.39558288594462]],
    [ "Enedis",[5.042384765500089, 43.33399230347196]],
    [ "Enedis",[5.021766350000001, 43.41080614999999]],
    [ "Enedis",[4.994354480372525, 43.39138301794065]],
    [ "Enedis",[5.0442235418034755, 43.33063740993089]],
    [ "Enedis",[5.041623284008395, 43.33248252084228]],
    [ "Enedis",[5.025795499999999, 43.40733305]],
    [ "Les Dryades",[-2.3632655051455833, 47.28139676418172]],
    [ "Ateliers municipaux",[6.122663750000001, 49.142466049999996]],
    [ "Croix de Lorry",[6.113490124308118, 49.14154424489363]],
    [ "réséda",[6.136018096416383, 49.14182340756542]],
    [ "réséda",[6.1189680000000015, 49.14622350000001]],
    [ "Dames Belles 2",[6.125459688335638, 49.14058429368373]],
    [ "réséda",[6.128148077077076, 49.143595710710706]],
    [ "Beau site",[6.128894199999999, 49.1420731]],
    [ "réséda",[6.125089500000001, 49.142398500000006]],
    [ "Lorry carrefour",[6.126888142461964, 49.14512668326418]],
    [ "réséda",[6.137418499999998, 49.1425915]],
    [ "réséda",[6.120066, 49.141355]],
    [ "Enedis",[2.6074620420383683, 50.75132315083225]],
    [ "Enedis",[2.598562651427718, 50.75613769194024]],
    [ "Enedis",[2.600757580005459, 50.75905442484591]],
    [ "Enedis",[2.60650235, 50.75811715]],
    [ "Enedis",[2.6059543933608733, 50.75938522968716]],
    [ "Enedis",[-0.08952511106410987, 49.28182461227612]],
    [ "Enedis",[6.995735804062125, 49.153901842293905]],
    [ "Enedis",[6.990665241286346, 49.15228970248412]],
    [ "Enedis",[6.094619756718278, 49.31752745702082]],
    [ "Galliane",[6.103084193690199, 49.31663353685026]],
    [ "Enedis",[6.099873780014377, 49.319679754133716]],
    [ "Enedis",[6.096787, 49.32036050000001]],
    [ "Enedis",[4.542611643547709, 49.88863036485919]],
    [ "Enedis",[6.089524413624383, 49.31780971671452]],
    [ "Enedis",[6.101629616228069, 49.319189695175446]],
    [ "Enedis",[4.5388451700680275, 49.890693972789116]],
    [ "Enedis",[6.098533766666667, 49.321191852596975]],
    [ "Enedis",[6.09972495059679, 49.317041406291985]],
    [ "Enedis",[-0.09510860528279666, 49.28221141692197]],
    [ "Enedis",[-0.09322099793001824, 49.283329355491915]],
    [ "Enedis",[-0.0966549461407785, 49.28329471376025]],
    [ "RTE",[6.143750528120566, 49.32619522496848]],
    [ "Triangle",[6.107348788370521, 49.31564991480731]],
    [ "Enedis",[6.125722708111308, 49.31837759325044]],
    [ "Enedis",[6.202060698448518, 48.66827636314057]],
    [ "Enedis",[6.201420586466165, 48.660425689223075]],
    [ "Enedis",[6.200898499999999, 48.6670765]],
    [ "Enedis",[4.926791547866818, 44.951060633088716]],
    [ "Enedis",[6.201269617305348, 48.665178688747666]],
    [ "Enedis",[6.206007112449798, 48.67175009638554]],
    [ "Enedis",[6.208812986951018, 48.661449319992215]],
    [ "Alsace",[6.199798388924526, 48.67511774278384]],
    [ "Enedis",[6.209158319932999, 48.663713035175874]],
    [ "Enedis",[6.198222737357259, 48.665717132898315]],
    [ "Enedis",[6.207339941407026, 48.65890665546536]],
    [ "Enedis",[6.212175290123455, 48.66210364586191]],
    [ "Enedis",[6.209265794553604, 48.667905579110645]],
    [ "Enedis",[6.2117206, 48.667539899999994]],
    [ "Enedis",[6.204735854925524, 48.669341432344474]],
    [ "Enedis",[6.2026064678002895, 48.665505291365164]],
    [ "Enedis",[6.201066305043051, 48.67246054896394]],
    [ "Enedis",[6.215502008550526, 48.667929198125286]],
    [ "Enedis",[6.210735843805329, 48.65928767077525]],
    [ "Enedis",[6.2072866563706555, 48.67064547747747]],
    [ "Enedis",[6.207654963107157, 48.66873734093438]],
    [ "Enedis",[6.206779883161513, 48.662720390378006]],
    [ "Enedis",[6.211396666713209, 48.67075310051601]],
    [ "Enedis",[2.694653596749053, 50.74158331618795]],
    [ "Enedis",[2.6920395895725235, 50.73650231183592]],
    [ "Enedis",[2.6915829739201302, 50.738222176854116]],
    [ "Enedis",[2.692355169215087, 50.73951401427115]],
    [ "Enedis",[2.689438728572828, 50.742203651483976]],
    [ "Enedis",[4.7512537, 49.86207869999999]],
    [ "Enedis",[4.761770760456274, 49.85328161723701]],
    [ "Enedis",[4.730900212815616, 49.854778019520474]],
    [ "Enedis",[4.739091, 49.8631005]],
    [ "Enedis",[2.5215795, 50.828004500000006]],
    [ "Enedis",[4.764111749297358, 49.857226827431134]],
    [ "Enedis",[4.767864503595369, 49.85085883205364]],
    [ "Enedis",[4.73691811120616, 49.860982917308235]],
    [ "Enedis",[4.742776168571649, 49.85616187800327]],
    [ "Enedis",[4.774602592760181, 49.840382424333846]],
    [ "Enedis",[4.759427153996101, 49.86452579532164]],
    [ "Enedis",[6.67196108850458, 49.113262348592755]],
    [ "Enedis",[6.656095497098648, 49.11216924951644]],
    [ "Enedis",[6.653658849137931, 49.120115677442534]],
    [ "Enedis",[6.637371817425355, 49.11384996573666]],
    [ "Enedis",[6.654226138702461, 49.107510082774056]],
    [ "Enedis",[6.689653750000001, 49.1088065]],
    [ "Enedis",[6.64785725, 49.11369475000001]],
    [ "Enedis",[6.649786845935191, 49.10468773147621]],
    [ "Enedis",[6.65525938827098, 49.11427723019886]],
    [ "Enedis",[6.653317999999999, 49.11240550000001]],
    [ "Enedis",[6.652860759515152, 49.105613076121216]],
    [ "Enedis",[6.644740324731181, 49.117141987096765]],
    [ "Enedis",[2.573718, 48.77975644999999]],
    [ "Enedis",[2.5854394684801734, 48.78238395753161]],
    [ "Enedis",[2.5773502500000003, 48.7782655]],
    [ "Enedis",[2.586331613168724, 48.793087748971196]],
    [ "Enedis",[2.5745011709836914, 48.79309847361818]],
    [ "Enedis",[2.579221354740986, 48.79005624923352]],
    [ "Enedis",[2.5897593, 48.78795455]],
    [ "Enedis",[2.576466814225052, 48.782352585633404]],
    [ "Enedis",[2.562233500000001, 48.793284250000006]],
    [ "Enedis",[2.5848272051860204, 48.77880046899662]],
    [ "Enedis",[2.5786770720164616, 48.78210924588478]],
    [ "Enedis",[2.57094275, 48.789114749999996]],
    [ "Enedis",[2.5874355, 48.791002]],
    [ "Enedis",[2.5682635, 48.79656275000001]],
    [ "Enedis",[2.445399165845649, 50.9764646633826]],
    [ "Enedis",[2.443175119095591, 50.97249000335796]],
    [ "Enedis",[2.4505510000000004, 50.974629500000006]],
    [ "Enedis",[2.4520790943485458, 50.97648861669425]],
    [ "Enedis",[2.447598156059932, 50.97146684349504]],
    [ "Enedis",[2.446439445954186, 50.973789180733384]],
    [ "Enedis",[2.4427172512583892, 50.975162625559285]],
    [ "Poste électrique de Concarneau",[-3.8920200532644627, 47.894916302695286]],
    [ "Enedis",[4.816100804291527, 50.14136759859416]],
    [ "Enedis",[4.827499168115943, 50.13447141449276]],
    [ "Enedis",[4.833172175970229, 50.13441097873472]],
    [ "Enedis",[4.812722209523809, 50.14333886666667]],
    [ "Enedis",[4.831435238828309, 50.13758982659825]],
    [ "Enedis",[4.841045830658292, 50.13516330844383]],
    [ "Enedis",[2.5718453338793337, 50.547219493311495]],
    [ "Enedis",[2.5602794913323423, 50.541632343239236]],
    [ "Enedis",[2.5648806144122616, 50.536168201154574]],
    [ "Enedis",[2.5672893308793454, 50.53871898200409]],
    [ "Enedis",[2.5715005725971367, 50.539643564417176]],
    [ "Enedis",[4.8271, 45.77707]],
    [ "Enedis",[-0.09804445775868471, 49.286602922305995]],
    [ "Enedis",[5.039133300000001, 43.78996920000001]],
    [ "Enedis",[5.041840570833356, 43.7912113590029]],
    [ "Enedis",[5.035977292418799, 43.78819427447111]],
    [ "Enedis",[5.034791682078606, 43.78862395369635]],
    [ "Poste électrique de Distré",[-0.114321693166064, 47.20793098255358]],
    [ "Enedis",[5.8050715, 44.904931499999996]],
    [ "Enedis",[5.795851924864076, 44.89132293702308]],
    [ "Enedis",[4.855290200000001, 43.8881699]],
    [ "Enedis",[4.849151673261502, 43.88663152792596]],
    [ "Enedis",[4.855652225917117, 43.88279669689716]],
    [ "Enedis",[4.852218577186192, 43.87292320564091]],
    [ "Enedis",[4.8491023735802, 43.883457163324586]],
    [ "Enedis",[4.867605600000002, 43.879956750000005]],
    [ "Enedis",[4.8494755000000005, 43.8781431]],
    [ "Enedis",[4.86883330319667, 43.883156336283946]],
    [ "Enedis",[4.824266065240304, 43.877879588165214]],
    [ "Enedis",[4.852980199999999, 43.87463940000001]],
    [ "Enedis",[4.86869085, 43.87207954999999]],
    [ "Enedis",[4.869781070396957, 43.88553580227225]],
    [ "Enedis",[4.848390585102586, 43.88483011606304]],
    [ "Enedis",[4.856557750000001, 43.883664200000005]],
    [ "Enedis",[4.8672519, 43.87732335]],
    [ "Enedis",[4.853943145865391, 43.88030779590998]],
    [ "Enedis",[4.853419, 43.87072165000001]],
    [ "Enedis",[4.8523173033203, 43.87960422534026]],
    [ "Enedis",[4.862840039781048, 43.88393062576328]],
    [ "Enedis",[4.85579555, 43.8717719]],
    [ "Enedis",[4.853377002359278, 43.88198064296277]],
    [ "Enedis",[4.858472750000001, 43.87197390000001]],
    [ "Enedis",[4.842746579769555, 43.88310844210378]],
    [ "Enedis",[4.863654862482066, 43.87488803423003]],
    [ "Enedis",[4.864843400000002, 43.884636150000006]],
    [ "Enedis",[4.861230864323036, 43.882962018790415]],
    [ "Enedis",[4.852548615555896, 43.88263589212489]],
    [ "Enedis",[4.8472352999999995, 43.87608695]],
    [ "Enedis",[4.858737670642054, 43.876961544829484]],
    [ "Enedis",[4.869855800000001, 43.8753048]],
    [ "Enedis",[4.859635921670386, 43.87437497747623]],
    [ "Enedis",[4.8513609625367335, 43.87963808088207]],
    [ "Enedis",[4.85900865, 43.88254825]],
    [ "Enedis",[4.852231951437852, 43.87625648725275]],
    [ "Enedis",[4.85645995, 43.87304609999998]],
    [ "Enedis",[4.851577421692981, 43.88908817489537]],
    [ "Enedis",[4.8685994500000005, 43.87702964999999]],
    [ "Enedis",[4.860077637710571, 43.880820749034505]],
    [ "Enedis",[4.860374560635583, 43.88547076915677]],
    [ "Enedis",[4.8536856, 43.8870443]],
    [ "Enedis",[4.8510598, 43.88090239999999]],
    [ "Enedis",[4.848989023164097, 43.882210643379835]],
    [ "Enedis",[4.819284348473982, 50.11520316327994]],
    [ "Enedis",[4.81657010400533, 50.12547374902156]],
    [ "Enedis",[4.818968078625701, 50.12756855797818]],
    [ "Enedis",[4.814050500000001, 50.12232099999999]],
    [ "Enedis",[-2.401520999999999, 47.289321]],
    [ "Enedis",[-2.3969362500000004, 47.28936675]],
    [ "RTE",[0.5824848774782859, 42.79304348435364]],
    [ "Enedis",[4.543595226074396, 49.65230797219213]],
    [ "Enedis",[3.860213250496032, 43.66880781398809]],
    [ "Enedis",[3.849136379014989, 43.67198072091363]],
    [ "Enedis",[3.8534255, 43.66709099999999]],
    [ "Enedis",[3.850170699999999, 43.65907225]],
    [ "Enedis",[3.854625532299742, 43.66983201981052]],
    [ "Enedis",[3.843554000144175, 43.66673435385429]],
    [ "transfo",[3.8507113499999996, 43.6658132]],
    [ "Enedis",[3.8444390672202453, 43.66990166864373]],
    [ "Enedis",[3.8584702829660715, 43.671516706330884]],
    [ "Enedis",[4.825428, 49.732571]],
    [ "Enedis",[4.819586883629191, 49.73353159171598]],
    [ "Enedis",[4.826481682271348, 49.739149844386645]],
    [ "Enedis",[4.888229681524603, 44.91104130861347]],
    [ "Enedis",[4.830868166666666, 49.73213783333333]],
    [ "Poste électrique de Vertou",[-1.4820794172823377, 47.18185770624999]],
    [ "Enedis",[-0.448224634071712, 43.91599588495656]],
    [ "Enedis",[-0.4479099259063772, 43.91613267469961]],
    [ "Enedis",[4.808557770666666, 49.872915023999994]],
    [ "Enedis",[4.804875972010179, 49.87255319592875]],
    [ "Enedis",[4.809951656057628, 49.8765898988867]],
    [ "Enedis",[4.808609499999999, 49.88155049999999]],
    [ "Enedis",[4.80685318018018, 49.88921435225226]],
    [ "Enedis",[4.845070002183407, 49.877372607714705]],
    [ "Enedis",[4.8416859194422734, 49.887064065572055]],
    [ "Enedis",[4.84305218292586, 49.8840051100974]],
    [ "Enedis",[4.8522154441223835, 49.86888614138488]],
    [ "Poste électrique de Boutre",[5.83230924946305, 43.690902435946995]],
    [ "Strasbourg Électricité Réseaux",[7.585245494127906, 48.361778828572184]],
    [ "Enedis",[2.6428415000000007, 50.695702]],
    [ "Enedis",[2.640020101331789, 50.695153623045734]],
    [ "Enedis",[-0.09842573873873875, 49.28039906306307]],
    [ "Enedis",[-0.09242278797814155, 49.294249049617484]],
    [ "Enedis",[-0.1008734344063687, 49.29371970446522]],
    [ "Enedis",[-0.10589890149311612, 49.28548627128175]],
    [ "Enedis",[-0.09972166594045023, 49.2920136325345]],
    [ "Enedis",[-0.09982788113695037, 49.29008925581396]],
    [ "Enedis",[-0.10024235540069644, 49.2911860248548]],
    [ "Enedis",[-0.09573917656299095, 49.29101481841031]],
    [ "Enedis",[-0.10309602139495072, 49.28817166752246]],
    [ "Enedis",[-0.10266364281230367, 49.28580867294414]],
    [ "Enedis",[-0.10224668450390034, 49.28326717725752]],
    [ "Enedis",[-0.09901038861491396, 49.294502640886456]],
    [ "Enedis",[-0.10584520096805183, 49.28132253609115]],
    [ "Enedis",[-0.09276139182120711, 49.29195301997147]],
    [ "Enedis",[-1.4220471106587231, 46.49000272256908]],
    [ "Enedis",[-1.4231000000000003, 46.49815890000001]],
    [ "Enedis",[0.09349249774010288, 49.51478994374953]],
    [ "Enedis",[0.09072038884895298, 49.51397424607094]],
    [ "Enedis",[0.08987545305883912, 49.51253057976245]],
    [ "Tralenta 2",[7.056249599999998, 45.375032649999994]],
    [ "100195800",[7.05341965, 45.374812199999994]],
    [ "Enedis",[2.9646547550644566, 50.54382895089012]],
    [ "Sous-station Brevannes",[2.4681752042182183, 48.75790063208283]],
    [ "Enedis",[2.9543576243617795, 50.52010684646243]],
    [ "Enedis",[2.9646424999999996, 50.522226499999995]],
    [ "Poste électrique des Thermes",[3.434513221913002, 46.13437819463986]],
    [ "Enedis",[5.098953612298353, 43.63504540994662]],
    [ "Van Gogh",[5.097726676872716, 43.648552994363826]],
    [ "Enedis",[5.100175796457661, 43.63593480366252]],
    [ "Enedis",[5.117129250545517, 43.635664216282095]],
    [ "Enedis",[5.096903055659565, 43.63645644860162]],
    [ "Clos des Abeilles",[5.10940837795014, 43.634416238523734]],
    [ "réséda",[6.58402774417751, 49.05292411791792]],
    [ "réséda",[6.582273283510712, 49.048825954627944]],
    [ "réséda",[6.581454749999998, 49.05723075]],
    [ "réséda",[6.579207299999999, 49.060255149999996]],
    [ "réséda",[6.571320849999999, 49.0464917]],
    [ "réséda",[6.5836201, 49.054961999999996]],
    [ "réséda",[6.585428223425457, 49.057058191957886]],
    [ "réséda",[6.587367870327993, 49.07342530282228]],
    [ "réséda",[6.582805829792473, 49.06170079784469]],
    [ "réséda",[6.5849147000000015, 49.058279850000005]],
    [ "réséda",[6.581095550430513, 49.05067738321753]],
    [ "réséda",[6.584143502552204, 49.069694380201085]],
    [ "réséda",[6.598034072133638, 49.08414585421412]],
    [ "réséda",[6.579711029406707, 49.05703601596445]],
    [ "Enedis",[6.206281675665542, 48.649935653918256]],
    [ "Enedis",[6.194652144648261, 48.64629254616763]],
    [ "Enedis",[6.209626386681843, 48.64885677209258]],
    [ "Enedis",[6.204856877442274, 48.6498512652457]],
    [ "Enedis",[6.199016532414054, 48.654238197955394]],
    [ "Enedis",[6.206584103975533, 48.64590729663609]],
    [ "Enedis",[6.191228029973899, 48.64977964224973]],
    [ "Enedis",[6.21152919271255, 48.64950028286099]],
    [ "Enedis",[6.187174224334603, 48.644782344317704]],
    [ "Enedis",[6.196220500467573, 48.65926949920862]],
    [ "Enedis",[6.187269721010509, 48.645752442879356]],
    [ "Enedis",[6.191025638744463, 48.659001680146346]],
    [ "Enedis",[6.211327445570571, 48.65017209271773]],
    [ "Enedis",[6.188467775577559, 48.64955303630363]],
    [ "Enedis",[6.190829596434847, 48.65593538139231]],
    [ "Enedis",[6.193204633012821, 48.64904493830128]],
    [ "Enedis",[6.210336779614324, 48.651768556473826]],
    [ "Enedis",[6.184789838383839, 48.65041265095399]],
    [ "Enedis",[6.192150687301586, 48.64662963650793]],
    [ "Enedis",[6.2007844595221515, 48.65284486290884]],
    [ "Enedis",[6.191163776349615, 48.652495904027425]],
    [ "Enedis",[6.198997999999999, 48.65182149999999]],
    [ "Enedis",[6.183338616368286, 48.64739628900255]],
    [ "Enedis",[6.204534828555369, 48.65079698650928]],
    [ "Enedis",[6.195920554018444, 48.653994245718046]],
    [ "Enedis",[6.1975096672176315, 48.641548512396696]],
    [ "Enedis",[6.1859085, 48.6494265]],
    [ "Enedis",[6.1879960395942355, 48.651747026169915]],
    [ "Enedis",[3.595877666666667, 47.734017176870736]],
    [ "réséda",[6.217588999999999, 49.233434999999986]],
    [ "réséda",[6.2140689999999985, 49.22176799999999]],
    [ "réséda",[6.2207191131716595, 49.226527828340465]],
    [ "réséda",[6.212129636806825, 49.220509168799516]],
    [ "réséda",[6.209032924534257, 49.223843392631665]],
    [ "réséda",[6.230935800000003, 49.233237550000005]],
    [ "réséda",[6.213402692782526, 49.22469908357075]],
    [ "réséda",[6.22012015460993, 49.22809397446809]],
    [ "réséda",[6.209016257309941, 49.2185770760234]],
    [ "Enedis",[4.092323177672956, 47.80339406918238]],
    [ "réséda",[6.212794553793103, 49.223033479770116]],
    [ "réséda",[6.221391999999999, 49.223409950000004]],
    [ "réséda",[6.224090675977064, 49.232028903597204]],
    [ "réséda",[6.212043683119224, 49.230579367365344]],
    [ "réséda",[6.22680461904603, 49.23421591371495]],
    [ "Enedis",[4.09155669204431, 47.79736214904331]],
    [ "réséda",[6.215984500000001, 49.223940999999996]],
    [ "Enedis",[3.8871556208445637, 47.77059335354896]],
    [ "Enedis",[4.073328304878049, 47.450064678281066]],
    [ "Enedis",[3.5425633157090815, 47.79860653789214]],
    [ "Enedis",[3.5311439333849726, 47.81019642576814]],
    [ "Enedis",[3.5315740099920063, 47.8003960831335]],
    [ "Enedis",[3.5279670000000003, 47.805543]],
    [ "Enedis",[3.5380839570957088, 47.80025277557755]],
    [ "Enedis",[3.525990736605318, 47.80915819386503]],
    [ "Enedis",[3.5325302997601917, 47.80322672122302]],
    [ "Enedis",[3.533489357984995, 47.796425201500526]],
    [ "Enedis",[3.5347934999999997, 47.80389350000001]],
    [ "Enedis",[3.5362425965034965, 47.79725934708625]],
    [ "Enedis",[3.52960918696187, 47.79798093726937]],
    [ "Enedis",[3.52856378895258, 47.802897157547335]],
    [ "réséda",[6.165395618090453, 49.091051961474044]],
    [ "réséda",[6.148153614587486, 49.10020014454631]],
    [ "réséda",[6.161444037210817, 49.09807223735197]],
    [ "réséda",[6.154146463420059, 49.09206032866356]],
    [ "réséda",[6.1520992178525375, 49.09078820371606]],
    [ "réséda",[6.1738588284313725, 49.09006995686275]],
    [ "Enedis",[1.5136462603066447, 43.648175261783074]],
    [ "réséda",[6.143359563270039, 49.09883131898342]],
    [ "réséda",[6.149882851594426, 49.10223896949511]],
    [ "réséda",[6.159586375378266, 49.08599345662541]],
    [ "réséda",[6.161293520120121, 49.087780133333325]],
    [ "réséda",[6.144483236947791, 49.09233934939759]],
    [ "réséda",[6.163522499999999, 49.08563649999999]],
    [ "réséda",[6.155808572819832, 49.08796784417884]],
    [ "Enedis",[1.5121552594644523, 43.64561997229917]],
    [ "réséda",[6.153110507685142, 49.102478999534235]],
    [ "réséda",[6.156842500000001, 49.09986650000001]],
    [ "réséda",[6.132502343285215, 49.0986071516623]],
    [ "Enedis",[1.5450314754346184, 43.644311823885104]],
    [ "Enedis",[2.8793318004366815, 50.5360342103348]],
    [ "Enedis",[2.873939325076669, 50.53599146992216]],
    [ "réséda",[6.148495277678571, 49.0938884733631]],
    [ "Enedis",[2.864798140709667, 50.53387130798812]],
    [ "Enedis",[2.8625100000000008, 50.533572]],
    [ "réséda",[6.169928415545591, 49.08855112144993]],
    [ "réséda",[6.146058183724554, 49.090400456860756]],
    [ "réséda",[6.137371263940519, 49.09587982527882]],
    [ "réséda",[6.148348683385834, 49.10337768564736]],
    [ "réséda",[6.16187642060479, 49.10386863969978]],
    [ "réséda",[6.1537560000000004, 49.09003049999999]],
    [ "Poste électrique de Cudraz",[6.447278196216422, 45.618550870352394]],
    [ "réséda",[6.1587001331402, 49.09517533134166]],
    [ "réséda",[6.161239204609698, 49.08992278386605]],
    [ "réséda",[6.158345143984676, 49.10373490626184]],
    [ "réséda",[6.150907930348258, 49.09802897014926]],
    [ "Enedis",[1.5387095, 43.642078]],
    [ "réséda",[6.144861550000001, 49.10069409999999]],
    [ "réséda",[6.151530500000001, 49.0891315]],
    [ "réséda",[6.1582315580005025, 49.10219984300773]],
    [ "réséda",[6.148221939269407, 49.10141263333333]],
    [ "réséda",[6.154542113519092, 49.08645160696403]],
    [ "réséda",[6.156091485454306, 49.1029185978021]],
    [ "réséda",[6.158596144444443, 49.10522463333333]],
    [ "Poste électrique de Cornier",[6.295157110410811, 46.08462831232308]],
    [ "Enedis",[1.9994912374369107, 49.03673346872943]],
    [ "Enedis",[-0.09830629154297862, 49.287862741648404]],
    [ "Enedis",[1.1989051794323724, 49.25537452262202]],
    [ "Enedis",[1.1957781190476182, 49.260647294217684]],
    [ "Enedis",[1.1964043362193362, 49.25200782943723]],
    [ "Enedis",[-1.8191448500000003, 49.514369450000004]],
    [ "Enedis",[-1.813751833427551, 49.514223949900426]],
    [ "Enedis",[-1.8095151000000003, 49.513641050000004]],
    [ "Enedis",[-1.808898688622754, 49.517039535373705]],
    [ "Enedis",[-1.8090498005680664, 49.51641743639684]],
    [ "Enedis",[-1.806112682945737, 49.5162764751938]],
    [ "Enedis",[-1.8180460488084678, 49.51512647081812]],
    [ "Enedis",[-1.8118305, 49.51017714999999]],
    [ "Enedis",[-1.8111535999999988, 49.50897865]],
    [ "Enedis",[-1.8143461063104036, 49.51306648569263]],
    [ "Enedis",[-1.812029982259622, 49.5136695527573]],
    [ "Enedis",[-1.8163505999999998, 49.513991499999996]],
    [ "Enedis",[-1.8179388306010926, 49.50833550273224]],
    [ "Enedis",[3.0684886988987374, 50.37785618291699]],
    [ "Enedis",[3.0580597765873017, 50.38911430436508]],
    [ "Enedis",[3.0669140876199474, 50.37913841167217]],
    [ "Enedis",[3.0694337507002807, 50.37947395518208]],
    [ "Enedis",[3.0773065000000006, 50.3828565]],
    [ "Enedis",[3.0684869999999997, 50.37625250000001]],
    [ "Enedis",[3.082298829252982, 50.38406304770873]],
    [ "Enedis",[3.0659555000000003, 50.384176000000004]],
    [ "Enedis",[3.0702956322629964, 50.382399920871556]],
    [ "Enedis",[3.0598167282051287, 50.38331567015385]],
    [ "Enedis",[3.0666362224070998, 50.374527874811825]],
    [ "Enedis",[3.0742031014404554, 50.379457763035106]],
    [ "Enedis",[3.0627838318140754, 50.379278945388855]],
    [ "Enedis",[6.836406, 49.11661]],
    [ "Enedis",[6.8343186114578325, 49.10742295135221]],
    [ "Enedis",[6.831968314079422, 49.10751397593261]],
    [ "Enedis",[6.864552653700705, 49.10434652836305]],
    [ "Enedis",[6.854776958765628, 49.10370034397445]],
    [ "Enedis",[6.8509718259023344, 49.09331252229299]],
    [ "Enedis",[6.863234829981229, 49.102381519442204]],
    [ "Enedis",[6.85205504329741, 49.104908794290026]],
    [ "Enedis",[6.890059288424312, 49.09346469299265]],
    [ "Enedis",[6.883479751243782, 49.095044532338314]],
    [ "Enedis",[6.915571583333333, 49.07595840625]],
    [ "Enedis",[6.921095708037825, 49.06975597635934]],
    [ "Enedis",[0.3296019500000021, 46.5783367]],
    [ "Enedis",[6.9216003455978985, 49.05100393955321]],
    [ "Enedis",[6.9323060000000005, 49.053234]],
    [ "Enedis",[6.902889667134832, 49.04761449274344]],
    [ "Enedis",[6.9231034671623295, 49.05286766914498]],
    [ "Enedis",[5.3509142732268025, 43.40439628984934]],
    [ "Enedis",[5.3652909499999994, 43.40023835]],
    [ "Enedis",[5.351618412451907, 43.401161074921305]],
    [ "Enedis",[5.347457601207518, 43.40611308357191]],
    [ "Enedis",[5.358756415045187, 43.388918067385404]],
    [ "Enedis",[7.042852061084531, 49.052821896491224]],
    [ "Enedis",[7.032174881005174, 49.048544284552854]],
    [ "Enedis",[5.368386700000001, 43.39984870000001]],
    [ "Enedis",[7.0390225000000015, 49.0779435]],
    [ "Enedis",[7.033785390526581, 49.08179926413034]],
    [ "Enedis",[7.0243663801498135, 49.07383173220975]],
    [ "Enedis",[7.052648166373984, 49.04836083967481]],
    [ "Enedis",[7.017096118279569, 49.05072266666667]],
    [ "Enedis",[7.039123309086935, 49.08708181804282]],
    [ "Enedis",[7.03293939030532, 49.06458867201763]],
    [ "Enedis",[7.041457620481928, 49.06695356827309]],
    [ "Enedis",[7.035254882882882, 49.06296018918919]],
    [ "Enedis",[7.025134186544342, 49.058889467889905]],
    [ "Enedis",[7.034464999999999, 49.05101299999999]],
    [ "Enedis",[7.040401931439184, 49.081156938909224]],
    [ "Enedis",[5.349769859889127, 43.406472738923036]],
    [ "Enedis",[5.355048879608335, 43.40253561433549]],
    [ "Enedis",[5.351964036649812, 43.40167174244183]],
    [ "Enedis",[3.0848715, 50.380221]],
    [ "Enedis",[3.0738818828541, 50.36856128647497]],
    [ "Enedis",[3.080565563758389, 50.376634682326625]],
    [ "Enedis",[3.0659703976510064, 50.37071071979866]],
    [ "Enedis",[3.073091507701377, 50.367550914363626]],
    [ "Enedis",[-0.9618105882837213, 45.64321521884296]],
    [ "Enedis",[-0.9649236324003639, 45.64520246102594]],
    [ "Enedis",[-0.9615257990389346, 45.647511833572686]],
    [ "Enedis",[-0.9616912000000002, 45.64571465000001]],
    [ "Enedis",[-0.9596461158156508, 45.642372653102086]],
    [ "Enedis",[-0.9703839556341758, 45.64941953040719]],
    [ "Enedis",[-0.9570530191567369, 45.64134697442713]],
    [ "Enedis",[-0.9588127634002034, 45.650350497486336]],
    [ "Enedis",[-0.9659270647455117, 45.64702415250704]],
    [ "Enedis",[-0.9604054, 45.63670805000001]],
    [ "Enedis",[-0.9646339666666668, 45.639621762443845]],
    [ "Enedis",[-0.9384039658847655, 45.622019646100064]],
    [ "Enedis",[-0.968506245801968, 45.6429040917452]],
    [ "Enedis",[-0.9630579000000004, 45.641549950000005]],
    [ "Enedis",[-0.9510096152823014, 45.637133801413015]],
    [ "Enedis",[-0.9830491999999998, 45.63169765000001]],
    [ "Enedis",[-0.9455942987829615, 45.66132216186613]],
    [ "Enedis",[-0.9588508, 45.638628399999995]],
    [ "Enedis",[-0.9868862957401109, 45.63039171621025]],
    [ "Enedis",[-0.9653398478215436, 45.663296822085584]],
    [ "Enedis",[-0.9626577784863555, 45.65277695139525]],
    [ "Enedis",[-0.9544764821255343, 45.63950235888681]],
    [ "Enedis",[-0.9670596462046791, 45.64227426836527]],
    [ "Enedis",[2.6845900529186166, 50.80350141687656]],
    [ "Enedis",[2.597241970294494, 50.77838745629535]],
    [ "Enedis",[6.434194205480171, 48.184844988110825]],
    [ "Enedis",[6.45755399540854, 48.16647312605118]],
    [ "Enedis",[6.437420673311227, 48.17609787129547]],
    [ "Enedis",[6.43132546270998, 48.17874469761916]],
    [ "Enedis",[6.463907749045476, 48.195447053975265]],
    [ "Enedis",[6.45148254883466, 48.19981540425669]],
    [ "Chat Potté",[6.472541950681847, 48.18263947696363]],
    [ "Enedis",[6.46481561656579, 48.16261384978625]],
    [ "Enedis",[2.6423663987294463, 50.79223823056801]],
    [ "Enedis",[2.642276837388953, 50.79459798030129]],
    [ "RTE",[2.2009476110029507, 47.16654072040429]],
    [ "Enedis",[2.5807385715922115, 50.83693770561285]],
    [ "Enedis",[2.5794010257861637, 50.81211416572328]],
    [ "Enedis",[2.577029756335282, 50.80996144249513]],
    [ "Enedis",[2.590068106041079, 50.80605133279634]],
    [ "Enedis",[2.584371109634552, 50.80857432115171]],
    [ "Enedis",[2.5779627870143984, 50.808815280358594]],
    [ "Enedis",[2.5691721658734354, 50.816360495681295]],
    [ "Enedis",[2.6000042138364785, 50.810729953207556]],
    [ "Enedis",[2.607214293711126, 50.835866300391615]],
    [ "Enedis",[6.6644798945443435, 45.195252619821076]],
    [ "Enedis",[6.671193000000001, 45.20401065000001]],
    [ "Enedis",[6.67538040504499, 45.20116442439063]],
    [ "Enedis",[6.668879024919346, 45.2060096465392]],
    [ "Enedis",[6.657367430160925, 45.17676094750728]],
    [ "Enedis",[6.674745765692086, 45.20411124440971]],
    [ "Enedis",[6.671191425336127, 45.199957317338914]],
    [ "Enedis",[6.679229476338385, 45.206069954758156]],
    [ "Enedis",[6.6658324706864445, 45.200588433203194]],
    [ "Enedis",[6.680944229676241, 45.205393342501424]],
    [ "Enedis",[6.686707531130265, 45.20753552158501]],
    [ "Enedis",[6.657337387663824, 45.17671929314452]],
    [ "Enedis",[3.0812984740990985, 50.368712460210205]],
    [ "Enedis",[3.0870181658454987, 50.36911768804744]],
    [ "Enedis",[3.077846587489433, 50.365945190757955]],
    [ "Enedis",[3.089178118380062, 50.37379257943925]],
    [ "Enedis",[3.0857705375640605, 50.37771420619343]],
    [ "Enedis",[3.0786385000000003, 50.36416]],
    [ "Enedis",[3.0805981281543793, 50.36582189576117]],
    [ "Enedis",[1.1091525920976177, 49.32974265756343]],
    [ "Enedis",[1.0790203698964052, 49.320077700182814]],
    [ "Enedis",[1.0799961704968264, 49.32891152374698]],
    [ "Enedis",[1.092346130038556, 49.329557403434976]],
    [ "Enedis",[1.101911664881749, 49.32652184069612]],
    [ "Enedis",[1.103614047488585, 49.32472767305936]],
    [ "Enedis",[1.1141483413654614, 49.33345537911646]],
    [ "Enedis",[1.0706243045563544, 49.31755283213428]],
    [ "Enedis",[1.098666228933369, 49.33074275646921]],
    [ "Enedis",[1.073206312581063, 49.31864633592737]],
    [ "Enedis",[1.0743358494623654, 49.319004316648126]],
    [ "Enedis",[1.0617120000000004, 49.31290450000001]],
    [ "Enedis",[1.0944148545994066, 49.33167228387734]],
    [ "Enedis",[1.0652530000000011, 49.316464499999995]],
    [ "Enedis",[1.064831, 49.325562999999995]],
    [ "Enedis",[2.1411004368191717, 47.194390894335505]],
    [ "Enedis",[2.1353738535394635, 47.19734411906699]],
    [ "Enedis",[2.676412364734299, 50.65952223913043]],
    [ "Enedis",[2.6686615, 50.659280499999994]],
    [ "Enedis",[4.026277137037038, 50.04248305555556]],
    [ "Enedis",[4.02549362251494, 50.043771352238075]],
    [ "Enedis",[2.715090470528273, 50.68197218438463]],
    [ "Enedis",[2.723314963945551, 50.68107069789342]],
    [ "Enedis",[2.717335945664364, 50.68129338074494]],
    [ "Enedis",[2.7119466841093143, 50.68008349376934]],
    [ "Enedis",[2.5026241837465575, 50.75658460964188]],
    [ "Enedis",[2.5568895956659263, 50.775528677434544]],
    [ "Enedis",[5.482261915701103, 43.46391102922033]],
    [ "Enedis",[5.465369832501347, 43.43860645540792]],
    [ "Enedis",[5.468389799999999, 43.43548965]],
    [ "Enedis",[5.453133129446936, 43.454448775838145]],
    [ "Enedis",[5.359521136602631, 43.41359376664761]],
    [ "Enedis",[5.469345972758942, 43.453881085941916]],
    [ "Enedis",[5.458973014777731, 43.458731325238915]],
    [ "Enedis",[5.467346304716861, 43.44240529308886]],
    [ "Enedis",[5.3536455467433575, 43.417994895083254]],
    [ "Enedis",[5.350255322807474, 43.41655336383473]],
    [ "Enedis",[5.3648647456794345, 43.41804597759071]],
    [ "Enedis",[5.337757871110798, 43.41984951456892]],
    [ "Enedis",[5.332304567152502, 43.40785274949016]],
    [ "Enedis",[5.309470794712691, 43.41072232410201]],
    [ "Enedis",[5.279951238968017, 43.40026342147521]],
    [ "Enedis",[5.466503528693545, 43.43532256497332]],
    [ "Enedis",[5.317110931382399, 43.40951614632981]],
    [ "Enedis",[5.44663218171502, 43.44635879838597]],
    [ "Enedis",[5.4533897499999995, 43.44754139999999]],
    [ "Enedis",[5.288082256482904, 43.41847390570318]],
    [ "Enedis",[5.366061429530469, 43.419035791465674]],
    [ "Enedis",[5.285518565892382, 43.4190683163677]],
    [ "Enedis",[5.356293507926621, 43.4167770797261]],
    [ "Enedis",[5.35431635, 43.41384659999999]],
    [ "Enedis",[5.2803565276205875, 43.41802062031797]],
    [ "Enedis",[5.359943797816595, 43.41334247089484]],
    [ "Enedis",[5.364179633505758, 43.41661020007874]],
    [ "Enedis",[5.350742949999998, 43.41513149999999]],
    [ "Enedis",[5.4638436018992, 43.43517678358679]],
    [ "Enedis",[5.278286421468348, 43.41901555402444]],
    [ "Enedis",[5.27508375, 43.394919550000004]],
    [ "Enedis",[5.470254072012316, 43.446977109839224]],
    [ "Enedis",[5.4614378692709735, 43.458650614201915]],
    [ "Enedis",[5.479881630677451, 43.46253221616099]],
    [ "Enedis",[5.470280115950811, 43.46074209247273]],
    [ "Enedis",[5.287195300659318, 43.41750081833243]],
    [ "Enedis",[5.3040785451483075, 43.40839002264689]],
    [ "Enedis",[5.336248130263403, 43.42076139453035]],
    [ "Enedis",[5.471719299999999, 43.4410305]],
    [ "Enedis",[5.358195649999999, 43.41296575]],
    [ "Enedis",[5.453918920121631, 43.44522713373914]],
    [ "Enedis",[5.354852086839534, 43.41484395838799]],
    [ "Enedis",[5.453052580731543, 43.45531680957678]],
    [ "Enedis",[5.355341899941113, 43.4175140666977]],
    [ "Enedis",[5.465467972601279, 43.45533448359979]],
    [ "Enedis",[5.356856731649786, 43.42036304489984]],
    [ "Enedis",[5.467125291573913, 43.454297284649094]],
    [ "Enedis",[5.468387909319733, 43.44443787909358]],
    [ "Enedis",[5.353382668801146, 43.376133538585364]],
    [ "Enedis",[5.30259035, 43.422277900000005]],
    [ "Enedis",[5.340717963507897, 43.41916844838142]],
    [ "Enedis",[5.469730763006617, 43.445483352900865]],
    [ "Enedis",[5.367205530619819, 43.415056057258646]],
    [ "Enedis",[5.448422054780198, 43.45082887066595]],
    [ "Enedis",[5.28236315526285, 43.41934014933566]],
    [ "Enedis",[5.454254991865034, 43.448566293295364]],
    [ "Enedis",[5.362332650000001, 43.41082145]],
    [ "Enedis",[5.280364216036822, 43.417297950629845]],
    [ "Enedis",[5.282400236211352, 43.418217292629286]],
    [ "Enedis",[5.4697060777354745, 43.455930811419144]],
    [ "Enedis",[5.34589128440004, 43.37943105146896]],
    [ "Enedis",[5.312292819789363, 43.409999578173924]],
    [ "Enedis",[5.312526366515725, 43.40906739639896]],
    [ "Enedis",[5.303923793962043, 43.4186766929065]],
    [ "Enedis",[5.297192214893968, 43.42004348573558]],
    [ "Enedis",[5.350269924465107, 43.37735065400918]],
    [ "Enedis",[5.277246399999999, 43.417892949999995]],
    [ "Enedis",[5.35453865, 43.41503095000001]],
    [ "Enedis",[0.31598982435801987, 46.57725014756138]],
    [ "Enedis",[5.359886117574085, 43.41332991640191]],
    [ "Enedis",[5.352093395441667, 43.4135845283624]],
    [ "Enedis",[5.321890411500728, 43.409498488791485]],
    [ "Enedis",[5.281389680945925, 43.396930998011754]],
    [ "Enedis",[5.279398188356152, 43.41728050581441]],
    [ "Poste électrique de Cossigny",[2.678858625842243, 48.72873808858369]],
    [ "Poste électrique de La Palun",[5.486185463423265, 43.46657748161417]],
    [ "Enedis",[3.0519439252128353, 50.600146990438766]],
    [ "Enedis",[3.0535385, 50.59367950000001]],
    [ "Enedis",[3.059898909722222, 50.59254726276882]],
    [ "Enedis",[3.0410972402259895, 50.59226361996233]],
    [ "Enedis",[3.050763796213371, 50.60011264663368]],
    [ "Enedis",[3.046988722610722, 50.58238004428905]],
    [ "Enedis",[3.044055676882777, 50.59153491329403]],
    [ "Enedis",[3.0280660000000004, 50.583771000000006]],
    [ "Enedis",[3.05386663564288, 50.5880286677008]],
    [ "Enedis",[3.0553880646607663, 50.589786829262536]],
    [ "Enedis",[3.025901500000001, 50.580932000000004]],
    [ "Enedis",[3.037244654987836, 50.58348217518249]],
    [ "Enedis",[3.0564473032090436, 50.59744568323854]],
    [ "Enedis",[3.051413198550299, 50.589770544225686]],
    [ "Muette",[2.0370549, 49.066784399999996]],
    [ "101171833",[2.0658095, 49.065409249999995]],
    [ "Enedis",[2.155743389436442, 47.16935630224918]],
    [ "Poste électrique de Grisolles",[6.136568761289733, 44.578338335547556]],
    [ "Poste électrique des Mauges",[-0.9439696732213139, 47.26679163516717]],
    [ "Enedis",[5.252606874020477, 43.42721206440339]],
    [ "Enedis",[5.233624235342974, 43.44502988202326]],
    [ "Enedis",[5.24627281724825, 43.43832179919322]],
    [ "Enedis",[5.269625384077384, 43.41724835218705]],
    [ "Poste Électrique de Galères",[2.602921321016152, 48.88140133471764]],
    [ "Enedis",[5.258706749999999, 43.42446745]],
    [ "Enedis",[5.25353215, 43.424202199999996]],
    [ "Enedis",[5.238275999999999, 43.426548499999996]],
    [ "Enedis",[5.244486299999999, 43.42276930000001]],
    [ "Enedis",[5.240549792650589, 43.42873553441162]],
    [ "Enedis",[5.252438049999999, 43.425353599999994]],
    [ "Enedis",[5.239652855679336, 43.425717965982706]],
    [ "Enedis",[5.2436582000000005, 43.443681250000004]],
    [ "Enedis",[5.2416346053254435, 43.42781113922973]],
    [ "Enedis",[5.288913531450872, 43.42616143047011]],
    [ "Enedis",[5.26102261668724, 43.426061475924556]],
    [ "Enedis",[5.24733815, 43.437652150000005]],
    [ "Enedis",[5.2349718545946144, 43.439273684272194]],
    [ "Enedis",[5.2388540322847685, 43.43757854197905]],
    [ "Enedis",[5.241304529424311, 43.441742106354944]],
    [ "Enedis",[5.245971899999999, 43.43784254999999]],
    [ "Enedis",[5.229621239207732, 43.44303105895652]],
    [ "Enedis",[5.291572973733442, 43.42128760549419]],
    [ "Enedis",[5.283924739906346, 43.42698178007118]],
    [ "Enedis",[5.255776933501066, 43.43049156704729]],
    [ "Enedis",[5.254461614732325, 43.42474979302824]],
    [ "Enedis",[5.281528506007665, 43.420637126084294]],
    [ "Enedis",[5.238079893011667, 43.42696233447429]],
    [ "Enedis",[5.242710205259573, 43.427272066512614]],
    [ "Enedis",[5.254121378076124, 43.4230807970625]],
    [ "Enedis",[5.269969000000001, 43.419715700000005]],
    [ "Enedis",[5.255021716941403, 43.43175277868324]],
    [ "Enedis",[5.226046206315905, 43.44604046992229]],
    [ "Enedis",[5.257201487439165, 43.42743810214124]],
    [ "Enedis",[5.24936926375977, 43.43651857858018]],
    [ "Enedis",[5.2354965319995515, 43.43756153381721]],
    [ "Enedis",[5.2425614814714905, 43.42262498567161]],
    [ "Enedis",[5.24416415, 43.42537205]],
    [ "Enedis",[5.23734389781707, 43.43022231646523]],
    [ "Enedis",[5.239629399999999, 43.43995319999999]],
    [ "Enedis",[5.270975550000001, 43.417933700000006]],
    [ "Enedis",[5.258468999999998, 43.4233123]],
    [ "Enedis",[5.2362288, 43.44647774999999]],
    [ "Enedis",[5.246579416460129, 43.42710189849102]],
    [ "Enedis",[5.2633100499999985, 43.42214499999999]],
    [ "Enedis",[5.265016361026168, 43.432871845726915]],
    [ "Enedis",[5.22615297561712, 43.445524203741186]],
    [ "Enedis",[5.25517545146249, 43.42635915747816]],
    [ "Enedis",[5.240503167556003, 43.434016528356295]],
    [ "Enedis",[5.234297837463083, 43.443670331968335]],
    [ "Enedis",[5.247357683400196, 43.438197549909056]],
    [ "Enedis",[5.241419416569361, 43.44499100579942]],
    [ "Enedis",[5.233785, 43.4463847]],
    [ "Enedis",[5.262540049192623, 43.42418089226683]],
    [ "Enedis",[5.247853813095165, 43.4363125023645]],
    [ "Enedis",[5.244103000000001, 43.4253341]],
    [ "Enedis",[5.240044407891536, 43.43286517798203]],
    [ "Enedis",[5.245198360830666, 43.43343415329806]],
    [ "Enedis",[5.238115330078396, 43.42905931928153]],
    [ "Gobin",[-0.5144551310021577, 44.88176900375348]],
    [ "Marchecourt",[-0.5144408065847493, 44.88180208005566]],
    [ "Enedis",[5.2463598305422225, 43.42364563625919]],
    [ "Enedis",[5.232693867580873, 43.44736165143716]],
    [ "Enedis",[5.258405925246674, 43.424573239763035]],
    [ "Enedis",[5.2394926, 43.4294515]],
    [ "Enedis",[5.250724635602593, 43.423631487959845]],
    [ "Enedis",[5.239206805781918, 43.43615891935252]],
    [ "Enedis",[5.276040309373253, 43.42777315077545]],
    [ "Enedis",[5.292561575798464, 43.427420661632176]],
    [ "Enedis",[5.24914345, 43.4371654]],
    [ "Enedis",[2.0220370720371164, 49.06632893685939]],
    [ "Enedis",[5.2345269000000005, 43.440670350000005]],
    [ "Enedis",[5.255703, 43.42774325]],
    [ "Enedis",[2.0014527783259757, 49.060284557690636]],
    [ "Enedis",[5.266051793625952, 43.42126617521474]],
    [ "Enedis",[5.291199040253748, 43.42758711034626]],
    [ "Enedis",[5.256172721833006, 43.42877866030747]],
    [ "Enedis",[5.2366783351723365, 43.42821762057596]],
    [ "Enedis",[5.266977024634118, 43.42957474874182]],
    [ "Enedis",[5.237054164573569, 43.427501154208464]],
    [ "Enedis",[5.278172441288796, 43.42771379724032]],
    [ "Enedis",[5.277138295173841, 43.42207744320177]],
    [ "Enedis",[5.24421115, 43.4300684]],
    [ "Enedis",[5.241629549565102, 43.43728460680874]],
    [ "Enedis",[5.231435292011302, 43.447717091583804]],
    [ "Enedis",[5.279012389261536, 43.425860272187386]],
    [ "Enedis",[5.252519602660685, 43.423773046144326]],
    [ "Enedis",[5.260490284999305, 43.421755131797426]],
    [ "Enedis",[5.235499241263555, 43.44409376772933]],
    [ "Enedis",[5.260344198274047, 43.42543080076674]],
    [ "Enedis",[5.264962810268028, 43.43077801758487]],
    [ "Enedis",[5.237431514553949, 43.427407704094755]],
    [ "Enedis",[5.242912877357959, 43.43972118548208]],
    [ "RTE",[2.5149216365104476, 48.686791060574535]],
    [ "RTE",[2.5129393083994898, 48.69213715671666]],
    [ "Enedis",[3.4600246535816623, 43.64473976752627]],
    [ "Enedis",[5.543570153885405, 46.64469922362385]],
    [ "Enedis",[5.545832046788626, 46.63479449959905]],
    [ "Enedis",[4.826316644120708, 45.7777828775581]],
    [ "Patornay ZA",[5.717049049999999, 46.58840429999999]],
    [ "101349296",[5.7022229, 46.583225299999995]],
    [ "Séchoir Paget",[5.7065232, 46.5853973]],
    [ "Camping du Moulin",[5.700615800877035, 46.587278283038]],
    [ "Lagarde",[5.683335008447035, 46.58979801001007]],
    [ "Enedis",[3.05536800210084, 50.37064002521008]],
    [ "Enedis",[3.0535998115942014, 50.37245589855072]],
    [ "Enedis",[3.052850000000001, 50.373591000000005]],
    [ "Enedis",[3.0622816823217724, 50.37201206888246]],
    [ "Enedis",[3.457675984081371, 43.663714548744515]],
    [ "FPA",[-2.2636697435355955, 47.25636846065043]],
    [ "Baltique",[-2.2663127500000004, 47.2561535]],
    [ "Enedis",[3.086397489859026, 50.35059467494315]],
    [ "Enedis",[3.075184058358062, 50.3587634671942]],
    [ "Enedis",[3.064996, 50.3655355]],
    [ "Enedis",[3.0617088115890834, 50.36485162575555]],
    [ "Enedis",[3.0768965000000006, 50.3596465]],
    [ "Enedis",[3.0888441206302315, 50.360914653372724]],
    [ "Enedis",[3.0845358399222293, 50.3607762147332]],
    [ "Enedis",[6.239546057891637, 46.18051545912838]],
    [ "Enedis",[3.45487881374637, 43.67619591844143]],
    [ "Poste Source Haute-Tension",[4.830188769715187, 45.775904533403406]],
    [ "Garde Mobile",[6.24716455, 46.193598650000006]],
    [ "Enedis",[-0.8807638443724037, 45.52712332954925]],
    [ "Enedis",[-0.8567558045226443, 45.52546621134847]],
    [ "Enedis",[-0.8786342971135054, 45.52806566254489]],
    [ "Enedis",[-0.8801299210066956, 45.529000165022026]],
    [ "Enedis",[-0.8830501182081725, 45.52933933621516]],
    [ "Enedis",[3.0949792564281764, 50.38067661190783]],
    [ "Enedis",[3.0964995464788725, 50.37632368769953]],
    [ "Enedis",[-2.20218370682309, 47.2724771279986]],
    [ "Enedis",[-2.207035376028526, 47.28510053582503]],
    [ "Enedis",[-2.2034858394427643, 47.27207988147003]],
    [ "Bajoyer Est",[-2.201355219171668, 47.272519873962445]],
    [ "Enedis",[2.1537641556300637, 47.18128814938995]],
    [ "Enedis",[2.1564314716981134, 47.18391492795884]],
    [ "Enedis",[2.1523377913475743, 47.180842834340986]],
    [ "Enedis",[2.1591069999999988, 47.180055]],
    [ "Enedis",[2.154829314298362, 47.17779259539619]],
    [ "Enedis",[2.9603515919102614, 50.61390222057329]],
    [ "Enedis",[2.9505686608748665, 50.60484273342478]],
    [ "Enedis",[2.962032426581544, 50.61075576881408]],
    [ "Enedis",[2.959654, 50.60612283333334]],
    [ "Enedis",[2.9614578288288294, 50.609574939939954]],
    [ "Enedis",[2.9635809999999996, 50.61523450000001]],
    [ "Enedis",[2.958848459398069, 50.61171532595117]],
    [ "Enedis",[2.9581247446808505, 50.60710261465721]],
    [ "Enedis",[2.9639281673910185, 50.61940050375347]],
    [ "Enedis",[2.953831463203464, 50.605845463203465]],
    [ "Enedis",[3.0965195000000003, 50.39440749999999]],
    [ "Enedis",[3.1017443320659064, 50.38875852091255]],
    [ "Enedis",[3.080163500000001, 50.394363500000004]],
    [ "Enedis",[3.1030173818064304, 50.39401975714978]],
    [ "Enedis",[3.0846281359223293, 50.394044084142394]],
    [ "Enedis",[3.0818203980734187, 50.39030134591685]],
    [ "Enedis",[3.0891227124727347, 50.39067852508427]],
    [ "Enedis",[3.0888191348999134, 50.39725077458659]],
    [ "Enedis",[3.0943169021820927, 50.39273931828442]],
    [ "Enedis",[3.0837761980198026, 50.39027246104609]],
    [ "Enedis",[3.0927873112391935, 50.39651956195966]],
    [ "Enedis",[3.091599328735633, 50.39365367688377]],
    [ "Enedis",[3.0990481891891895, 50.39687804004003]],
    [ "Enedis",[3.0942660460481117, 50.39298828109966]],
    [ "Enedis",[3.1003759135200966, 50.39205689118959]],
    [ "Enedis",[3.084356495329597, 50.39247111689353]],
    [ "Enedis",[3.0950408253370947, 50.39102400721228]],
    [ "Enedis",[3.089588700152207, 50.38888848276479]],
    [ "Enedis",[3.081003779889807, 50.391526291184576]],
    [ "Enedis",[1.6731641020422106, 47.50426394826838]],
    [ "Enedis",[1.6820894049836062, 47.49397337300975]],
    [ "Maison des associations",[1.682945060538657, 47.500633466784976]],
    [ "Enedis",[1.675784768792278, 47.50895692518002]],
    [ "Enedis",[2.5905060999999994, 48.7982612]],
    [ "Enedis",[2.5854915, 48.80259449999999]],
    [ "Enedis",[2.5691757500000003, 48.812392749999994]],
    [ "Enedis",[2.5608295, 48.80465475]],
    [ "Enedis",[2.581660749999999, 48.809912999999995]],
    [ "Enedis",[2.570135750000001, 48.81408050000001]],
    [ "Enedis",[2.569683194789816, 48.808819397276494]],
    [ "Enedis",[2.57315925, 48.80980624999999]],
    [ "Enedis",[2.5908086, 48.79938725000001]],
    [ "Enedis",[2.5596097499999995, 48.8083075]],
    [ "Enedis",[2.5851745000000004, 48.8078965]],
    [ "Enedis",[2.5762470899940793, 48.806265717288326]],
    [ "Enedis",[2.591333949999999, 48.801065949999995]],
    [ "Enedis",[2.587838186379928, 48.799974573476696]],
    [ "Enedis",[2.572139027905642, 48.801574691839214]],
    [ "Enedis",[2.57923725, 48.80960174999999]],
    [ "Enedis",[2.5906110000000004, 48.803015]],
    [ "Enedis",[4.1617410747959225, 49.42638245442153]],
    [ "Enedis",[2.5743605, 48.8082585]],
    [ "Enedis",[2.5659307499999997, 48.81452250000001]],
    [ "Enedis",[2.165050044500138, 47.176978381838694]],
    [ "Enedis",[2.1699310466443325, 47.174719966554996]],
    [ "Enedis",[2.1779131699911347, 47.1674024793883]],
    [ "Enedis",[-2.233219962248496, 47.284616373712225]],
    [ "SCEA Ferchal",[-2.1940981925342804, 48.53040590306417]],
    [ "Enedis",[-2.2476597499999995, 47.283901549999996]],
    [ "Enedis",[-2.240731130718954, 47.28284266666665]],
    [ "Enedis",[-2.2471600499999997, 47.282788700000005]],
    [ "Enedis",[-2.2434770832969844, 47.2807800508741]],
    [ "RTE",[2.7667343754210494, 48.748747037067716]],
    [ "Enedis",[7.0617693126030625, 49.073828767962304]],
    [ "Enedis",[7.074155565461849, 49.07888343855422]],
    [ "Enedis",[7.04990849289012, 49.07777837820868]],
    [ "Enedis",[7.051811391086001, 49.07939879661017]],
    [ "Enedis",[7.065741, 49.0774065]],
    [ "Enedis",[0.08882172994444446, 49.5109045042963]],
    [ "Enedis",[6.930947684042066, 49.20885188340192]],
    [ "Enedis",[6.706595272873841, 49.089081102781144]],
    [ "Enedis",[6.692624712328767, 49.07694580365296]],
    [ "Enedis",[6.702125000000002, 49.0798825]],
    [ "Enedis",[6.698671195294605, 49.07867733125129]],
    [ "Enedis",[6.718077506029933, 49.084591752808095]],
    [ "Enedis",[6.698204, 49.08865900000001]],
    [ "Enedis",[6.714018327808472, 49.089554484960104]],
    [ "Enedis",[6.707792830243548, 49.08238871901126]],
    [ "Enedis",[6.709530663746959, 49.076647501995126]],
    [ "Enedis",[6.72173138076923, 49.0920812948718]],
    [ "Enedis",[4.0182671313731095, 50.07993366786701]],
    [ "Enedis",[6.151299001561889, 49.19242119146166]],
    [ "Maternelle",[4.7203120621568, 43.64767682390453]],
    [ "Vert Pré",[4.722928, 43.64487634999999]],
    [ "Bascule 13004",[4.7198394199159965, 43.64426079756519]],
    [ "Vacances",[4.6405882621990235, 43.64926224668321]],
    [ "Sous-station d'Arles",[4.635515780936611, 43.67748985922317]],
    [ "Prés Fleuri 13004",[4.717767036369078, 43.644083742305924]],
    [ "Loisirs ",[4.640990799999999, 43.65156485000001]],
    [ "Paumiers 13004",[4.715663619779398, 43.64486340829204]],
    [ "1038 Gabian",[4.619829066787761, 43.68314407898723]],
    [ "Enedis",[4.62234690678481, 43.67431301930846]],
    [ "Prévert",[4.720290998869052, 43.645502194818036]],
    [ "Servannes 13004",[4.712351536763589, 43.660701297750286]],
    [ "Sous-station de Ballarin",[4.690064556758194, 43.64771536159635]],
    [ "Enedis",[4.727151646481604, 43.64515370399396]],
    [ "Fourchon",[4.636908299999999, 43.65778255]],
    [ "Stade Municipal",[4.717019675957631, 43.646071956023235]],
    [ "Enedis",[4.7264426125517724, 43.64693563662284]],
    [ "Enedis",[4.724547414591345, 43.644730272202864]],
    [ "Enedis",[1.841496719909137, 47.94980783071793]],
    [ "Enedis",[3.1291504265020187, 50.39514066761656]],
    [ "Enedis",[3.099514157852739, 50.3799304422198]],
    [ "Enedis",[3.0991006176620726, 50.37139404389995]],
    [ "Enedis",[3.1056831005484447, 50.37224519256552]],
    [ "Enedis",[3.110024202440728, 50.376848611317534]],
    [ "Enedis",[3.110776123533471, 50.372411657925014]],
    [ "Enedis",[3.1003272474437624, 50.37519285276073]],
    [ "Enedis",[0.09441063001728835, 49.50838800322801]],
    [ "Enedis",[3.1038394999999994, 50.384128]],
    [ "Enedis",[3.1173138378689704, 50.387278438012956]],
    [ "Enedis",[3.103891128719722, 50.386730033217994]],
    [ "Enedis",[3.1131272568179305, 50.38408467682455]],
    [ "Enedis",[3.107786352882068, 50.38422298392253]],
    [ "Enedis",[3.1127031910858225, 50.38967751517308]],
    [ "Enedis",[3.114149311935329, 50.38795933048027]],
    [ "Enedis",[3.1193178721798134, 50.38236888388465]],
    [ "Enedis",[3.121585203057494, 50.389373274177466]],
    [ "102312844",[2.753741160021265, 48.85695912759171]],
    [ "Enedis",[3.0363534999999993, 50.371634]],
    [ "Enedis",[3.0394488277634966, 50.36958747986289]],
    [ "Enedis",[3.045843414030261, 50.3721965365658]],
    [ "Enedis",[3.039918510946756, 50.36896483305737]],
    [ "Enedis",[3.041139500000001, 50.371629000000006]],
    [ "Enedis",[3.0321833237167928, 50.369761488279835]],
    [ "Enedis",[3.053993478215921, 50.386625167774326]],
    [ "Enedis",[3.0558866645818856, 50.38189853231411]],
    [ "Enedis",[3.0570751618233616, 50.38544948774928]],
    [ "Enedis",[3.0585424999999997, 50.3800215]],
    [ "Enedis",[2.3797205, 47.76545550000001]],
    [ "Enedis",[2.362981, 47.761483]],
    [ "Enedis",[2.3623787992424234, 47.764314053030304]],
    [ "Enedis",[2.38258875, 47.75932759999999]],
    [ "Enedis",[2.3606402443885046, 47.76281408349066]],
    [ "Enedis",[2.3762851725616296, 47.75983097856378]],
    [ "Enedis",[2.3277741602714697, 47.76719000287132]],
    [ "Enedis",[2.3644105805739524, 47.761922114790295]],
    [ "Enedis",[3.051056947945434, 50.380225157167956]],
    [ "Enedis",[3.0433127455158937, 50.3786966538803]],
    [ "Enedis",[3.043837823837258, 50.381858468979495]],
    [ "Enedis",[3.047995, 50.3824835]],
    [ "Enedis",[3.0554109999999994, 50.380225499999995]],
    [ "Enedis",[3.0537225000000006, 50.375844500000014]],
    [ "Enedis",[3.0500525, 50.38430100000001]],
    [ "Enedis",[3.0574970000000006, 50.3778155]],
    [ "Enedis",[3.0367929007252523, 50.37862877649054]],
    [ "Enedis",[3.0478421943410634, 50.37842289698445]],
    [ "Enedis",[3.041909000000001, 50.383388499999995]],
    [ "Enedis",[3.0372150000000007, 50.37967150000001]],
    [ "Enedis",[3.028108920353982, 50.37727681710915]],
    [ "Enedis",[3.0333575000000006, 50.382841000000006]],
    [ "Enedis",[3.03986865577338, 50.38260588828145]],
    [ "Enedis",[3.051780124979083, 50.37735212153497]],
    [ "Enedis",[3.0537449858481547, 50.37908166704916]],
    [ "Enedis",[3.0372364111587986, 50.37712418855507]],
    [ "Enedis",[5.034051572984945, 43.68802819420342]],
    [ "Enedis",[5.02726815579409, 43.69011908584966]],
    [ "Enedis",[5.0361465410537605, 43.68784962903567]],
    [ "Enedis",[5.03891756036308, 43.687104208018376]],
    [ "Enedis",[5.025513210738471, 43.69139290356874]],
    [ "Enedis",[5.03398002984567, 43.700832208436175]],
    [ "Enedis",[5.031735631552226, 43.69175200047391]],
    [ "Enedis",[5.0294726240196415, 43.68780634542013]],
    [ "Enedis",[3.5099894076351537, 43.56719008139102]],
    [ "Enedis",[4.024948368835787, 50.09161654554047]],
    [ "Enedis",[2.110207302636331, 49.03636971578187]],
    [ "Oxford",[2.1015006701093437, 49.045202697435926]],
    [ "Dome",[2.1036308566390374, 49.041934406728416]],
    [ "France",[2.133780313715186, 49.06082796777019]],
    [ "Borgne",[2.1318094313078255, 49.06234351570392]],
    [ "Enedis",[2.1302258229246984, 49.06024298535081]],
    [ "Enedis",[4.019401673707503, 50.09231603238785]],
    [ "Enedis",[1.9882574999999998, 48.918496000000005]],
    [ "Enedis",[1.9773208247332903, 48.91828966448944]],
    [ "Enedis",[1.9801262513661197, 48.918787431693985]],
    [ "Enedis",[1.98957747516679, 48.91665463577959]],
    [ "Enedis",[6.188772453956271, 48.69072322786805]],
    [ "Lauras",[2.961137708511483, 43.97525337028256]],
    [ "Enedis",[1.8779510000000008, 48.8872975]],
    [ "Enedis",[4.040902436316848, 46.27044011739323]],
    [ "Enedis",[4.041812604974546, 46.27621385850422]],
    [ "Enedis",[4.038793013179205, 46.279090226304966]],
    [ "Enedis",[4.0488979701658065, 46.277328245230336]],
    [ "Enedis",[4.044646129545496, 46.27213575262528]],
    [ "Enedis",[4.040205691225429, 46.27388636141486]],
    [ "Enedis",[0.09776404999999999, 49.5026141]],
    [ "Enedis",[0.09707711881336503, 49.5038870787067]],
    [ "Enedis",[3.0765520000000004, 50.351172]],
    [ "Enedis",[3.067954036615751, 50.360281756906076]],
    [ "Enedis",[3.064379394105134, 50.349971930112424]],
    [ "Enedis",[3.073198543568465, 50.34859900296384]],
    [ "Enedis",[3.0595941506067095, 50.360745695217695]],
    [ "Enedis",[3.0611953443154474, 50.354142511243595]],
    [ "Enedis",[3.0782487630303037, 50.352536389696965]],
    [ "Enedis",[3.0708152446227914, 50.35573884644194]],
    [ "Enedis",[3.0745006199558835, 50.35465876320227]],
    [ "Enedis",[3.0732288830528622, 50.349786309026]],
    [ "Enedis",[3.0643717312886243, 50.352272845426114]],
    [ "Enedis",[3.0578117539019662, 50.352898454484105]],
    [ "Enedis",[3.0660648377916906, 50.35555299582622]],
    [ "Enedis",[3.0926815620528774, 50.344698686366]],
    [ "Enedis",[3.061720331345262, 50.355238451292244]],
    [ "Enedis",[3.938490672578881, 46.462999046274255]],
    [ "RTE",[2.859881070904696, 42.55048470204325]],
    [ "RTE",[2.8916002839194683, 42.80118820077637]],
    [ "RTE",[2.510277953898088, 42.81033654810435]],
    [ "Enedis",[-1.92118803992674, 48.179144564468864]],
    [ "Enedis",[-1.9186766238388835, 48.17909239202063]],
    [ "Enedis",[3.136588957288765, 50.373045826833796]],
    [ "Enedis",[3.1403964999999996, 50.39421199999999]],
    [ "Enedis",[3.1215980594479835, 50.37781588110403]],
    [ "Enedis",[3.117358089492754, 50.37329167844204]],
    [ "Enedis",[3.129178230103634, 50.37598531632961]],
    [ "RTE",[2.648367219253371, 42.65654825253762]],
    [ "RTE",[2.727353065880998, 42.49491391498828]],
    [ "Enedis",[6.066618620092571, 46.49095447622944]],
    [ "Enedis",[6.086032184470792, 46.47764438241647]],
    [ "Enedis",[6.100010132905539, 46.487804403511845]],
    [ "Enedis",[6.0932865000000005, 46.4923354]],
    [ "Enedis",[6.046714400000001, 46.479364650000015]],
    [ "Enedis",[6.0630684, 46.48543614999999]],
    [ "Enedis",[6.0568805, 46.488037999999996]],
    [ "Enedis",[6.08459675, 46.443843050000005]],
    [ "Enedis",[6.053851000000001, 46.488987]],
    [ "Enedis",[6.055572257671958, 46.484814955517756]],
    [ "Enedis",[6.06459091702036, 46.489126024817715]],
    [ "Enedis",[6.058492715560599, 46.48448214607849]],
    [ "Enedis",[6.066007611799173, 46.48950546029653]],
    [ "Enedis",[6.027081600000001, 46.50268295000001]],
    [ "Enedis",[6.06126725, 46.4848585]],
    [ "Enedis",[6.055761321593492, 46.49017740449416]],
    [ "Enedis",[6.053359, 46.487089499999996]],
    [ "Enedis",[6.0990737038621905, 46.51441596687304]],
    [ "Enedis",[6.111823834498469, 46.51153780347598]],
    [ "Enedis",[6.0713826378682825, 46.45878855204347]],
    [ "Enedis",[6.066288500000001, 46.477135000000004]],
    [ "Enedis",[6.073265668023869, 46.486409801393876]],
    [ "Enedis",[6.028694999999999, 46.505059]],
    [ "Enedis",[6.0686626520414375, 46.48002553199269]],
    [ "Enedis",[6.061491350000001, 46.48127565000001]],
    [ "Enedis",[6.070284594511541, 46.454307838916954]],
    [ "Enedis",[6.070054900000001, 46.488168]],
    [ "Enedis",[6.062002510466988, 46.4853913236715]],
    [ "Enedis",[6.072484973004019, 46.46369059315529]],
    [ "Enedis",[6.06720221222148, 46.48599783211607]],
    [ "SNCF Réseau",[2.90072936115724, 42.67190885112244]],
    [ "Enedis",[1.4910268036047236, 48.439207118292934]],
    [ "Enedis",[1.490673733897509, 48.43552465287573]],
    [ "Enedis",[1.4954703604766635, 48.441602773915925]],
    [ "Enedis",[1.4907982607709755, 48.44023430385487]],
    [ "Enedis",[1.4857866447488588, 48.442027721461194]],
    [ "Enedis",[1.4831527161623863, 48.446794675565734]],
    [ "Enedis",[1.4779152051056335, 48.437546583920195]],
    [ "Enedis",[1.4804889330337645, 48.43977321812394]],
    [ "Enedis",[1.475020351457841, 48.44109343656423]],
    [ "Enedis",[1.4771085626652574, 48.43976513907985]],
    [ "Enedis",[1.4794126672991779, 48.442097502213784]],
    [ "Enedis",[1.4770676315263183, 48.442263832369115]],
    [ "Enedis",[1.4779362322040983, 48.43750060250942]],
    [ "Enedis",[1.474407306176084, 48.43952353365455]],
    [ "Enedis",[1.4738922584314669, 48.44084865969719]],
    [ "Enedis",[1.4733584663753339, 48.442017494173335]],
    [ "RTE",[3.007383829264549, 42.549755865669916]],
    [ "Enedis",[1.484452684913904, 48.43315867823181]],
    [ "Enedis",[1.4803774827078735, 48.43415041280354]],
    [ "Enedis",[1.4862350987318835, 48.449860584239126]],
    [ "Enedis",[1.4842423144208028, 48.44910416130205]],
    [ "Enedis",[1.5413991750741842, 48.45327945796242]],
    [ "Enedis",[1.5283310000000003, 48.45030149999999]],
    [ "Enedis",[1.5314486138045607, 48.43783826446157]],
    [ "Enedis",[1.52792531632208, 48.44585547334903]],
    [ "Enedis",[1.5314218755449867, 48.455279252477226]],
    [ "Enedis",[1.5299383677668592, 48.44931428201897]],
    [ "Enedis",[1.54306, 48.452381499999994]],
    [ "Enedis",[1.5271979999999998, 48.444906]],
    [ "Enedis",[1.5393835, 48.4509785]],
    [ "Enedis",[1.5383365043015798, 48.45163133442828]],
    [ "Enedis",[1.5396253371496722, 48.452360471019674]],
    [ "Enedis",[1.538153939516129, 48.44810129099462]],
    [ "Enedis",[1.5412386054054061, 48.45228286666666]],
    [ "Enedis",[1.522043753001278, 48.450948438569604]],
    [ "Enedis",[1.538137649717514, 48.44805120150659]],
    [ "Enedis",[1.52248200656317, 48.44778737788716]],
    [ "Enedis",[1.4926336861617138, 48.45123409819431]],
    [ "Enedis",[1.49586, 48.449240999999994]],
    [ "Enedis",[1.49734093236715, 48.44653126570048]],
    [ "Enedis",[1.4963719999999991, 48.4470065]],
    [ "Enedis",[1.4920449064253647, 48.45274287794613]],
    [ "Enedis",[1.496829715222141, 48.45029971158048]],
    [ "Enedis",[1.4927211233682662, 48.4599159797757]],
    [ "Enedis",[1.4953498190380088, 48.45534038849646]],
    [ "Enedis",[1.5249605989089867, 48.439833293712326]],
    [ "Enedis",[1.5160478671875004, 48.44115555642362]],
    [ "Enedis",[1.521478742971888, 48.44276759036144]],
    [ "Enedis",[4.007302442746043, 50.0946107444355]],
    [ "Enedis",[1.4870318279069772, 48.4523549875969]],
    [ "Enedis",[1.485067330369272, 48.45758518438928]],
    [ "Enedis",[1.4907061558224894, 48.462375968301636]],
    [ "Enedis",[1.4835445000000003, 48.4580965]],
    [ "Enedis",[1.4885659061488663, 48.456772067961154]],
    [ "Enedis",[1.4902508204393503, 48.46000929990449]],
    [ "Enedis",[1.4821774149697815, 48.462366709902376]],
    [ "Enedis",[1.486357, 48.454083499999996]],
    [ "RTE",[3.156197367233183, 42.45797724487108]],
    [ "Enedis",[3.0940739173069884, 50.39966157336261]],
    [ "Enedis",[3.06022983360522, 50.39829049555918]],
    [ "Enedis",[3.0504185141463402, 50.401930665040645]],
    [ "Enedis",[3.054369714073686, 50.39838901187358]],
    [ "Enedis",[3.0612292756083774, 50.4009989745331]],
    [ "Enedis",[3.0673313423524986, 50.40340397444571]],
    [ "Enedis",[3.0231461517165004, 50.40237383277962]],
    [ "Enedis",[3.0444178842767298, 50.39961240377358]],
    [ "Enedis",[3.085542, 50.4007365]],
    [ "Enedis",[3.052670689032258, 50.39967053075269]],
    [ "Enedis",[3.066035332614943, 50.398797995689655]],
    [ "Enedis",[3.05884533118971, 50.400120707395494]],
    [ "Enedis",[3.098726894211576, 50.39973170059879]],
    [ "Enedis",[3.048390744498494, 50.40114796386379]],
    [ "Enedis",[3.045693889671362, 50.39642470187793]],
    [ "Enedis",[3.0859954331172665, 50.39871491141229]],
    [ "Enedis",[1.5189378287485575, 43.646749103799706]],
    [ "Enedis",[1.5222003906485666, 43.646797690799396]],
    [ "Enedis",[1.5252210902302425, 43.64591570400332]],
    [ "Enedis",[3.137621392549346, 50.46216670308589]],
    [ "Enedis",[3.1628186666666664, 50.45456833333333]],
    [ "Enedis",[3.1404774076696174, 50.44477507138643]],
    [ "Enedis",[1.4960424424514198, 48.443179349775775]],
    [ "Enedis",[3.1031214003345404, 50.349711296533776]],
    [ "Enedis",[1.4909676897877282, 48.44922452218982]],
    [ "Enedis",[3.1206054119996733, 50.35208192549115]],
    [ "Enedis",[3.119823828793775, 50.35423675313446]],
    [ "Enedis",[3.0989423455872767, 50.355744707730416]],
    [ "Enedis",[1.4866311517423911, 48.44643116843111]],
    [ "Enedis",[1.4846618063781314, 48.444121032866896]],
    [ "Enedis",[3.102819, 50.3453365]],
    [ "Enedis",[3.1046609618090453, 50.34491017420436]],
    [ "Enedis",[3.095799277575758, 50.344219458181826]],
    [ "Enedis",[3.0978227532247122, 50.345867953835715]],
    [ "Enedis",[3.10453505959806, 50.34396466943866]],
    [ "Enedis",[3.116013003371259, 50.35632230889169]],
    [ "Enedis",[3.1130459765013057, 50.35747202610966]],
    [ "Enedis",[3.108832938808374, 50.35027081964574]],
    [ "Enedis",[3.099523503341144, 50.34529816627615]],
    [ "Enedis",[3.097271877022653, 50.344402197411]],
    [ "Enedis",[4.0968294510779435, 48.33201368656717]],
    [ "Enedis",[3.112617091482649, 50.36629036487906]],
    [ "Enedis",[3.102315217210991, 50.36866994556765]],
    [ "Enedis",[3.10960223773158, 50.36629617479847]],
    [ "Enedis",[3.9205030549881332, 48.42012013693428]],
    [ "Enedis",[3.1163675107387303, 50.366437952324766]],
    [ "Enedis",[3.1213594855721394, 50.362179692537325]],
    [ "Enedis",[3.1209378096586433, 50.35908047192071]],
    [ "Enedis",[3.1145626453447055, 50.36312104904051]],
    [ "Enedis",[3.1181815, 50.36851400000001]],
    [ "Enedis",[3.1153350340136057, 50.35928634693877]],
    [ "Enedis",[3.1179605998153708, 50.36183816732056]],
    [ "Enedis",[3.1270694851207184, 50.36671016470147]],
    [ "Enedis",[3.122188720430107, 50.36918017204301]],
    [ "Enedis",[3.1170098602941185, 50.362912455065356]],
    [ "Bérivière",[5.782988494839102, 45.218646822100794]],
    [ "Enedis",[5.777126169312169, 45.210032502645504]],
    [ "Sept Laux",[5.784503676102564, 45.21624700861538]],
    [ "Enedis",[3.11485130235012, 50.36094853342926]],
    [ "Enedis",[3.1094433450476435, 50.36475768983303]],
    [ "Enedis",[3.1025806618635925, 50.3597898049952]],
    [ "Enedis",[3.1051258360061604, 50.36042670236477]],
    [ "Enedis",[3.105212583068477, 50.362617187132805]],
    [ "Enedis",[3.1093763968129573, 50.363396949320794]],
    [ "Enedis",[3.1105073354197725, 50.36049749786388]],
    [ "Enedis",[3.0986010178662586, 50.36339300918836]],
    [ "Enedis",[3.104979718484144, 50.36569534029389]],
    [ "Enedis",[3.0992865928812674, 50.36582329332293]],
    [ "Enedis",[1.5081998231810494, 48.43132996615906]],
    [ "Enedis",[1.5135330921717174, 48.43053438573233]],
    [ "Enedis",[1.5020055000000014, 48.436858]],
    [ "Enedis",[1.50473323041261, 48.43029159434399]],
    [ "Enedis",[1.505720970430108, 48.43192580107527]],
    [ "Enedis",[1.508588256681795, 48.43494246646496]],
    [ "Enedis",[1.5021558264081258, 48.43436123176362]],
    [ "Enedis",[1.5117478677351017, 48.4332401422124]],
    [ "Enedis",[1.5096764999999999, 48.442697500000016]],
    [ "Enedis",[1.5103687628961362, 48.44553775063613]],
    [ "Enedis",[1.5094998660758243, 48.4460462545544]],
    [ "Enedis",[1.5119012477064222, 48.4451174648318]],
    [ "Enedis",[1.5105914999999992, 48.446928500000006]],
    [ "Enedis",[1.5000517000389184, 48.44197139141856]],
    [ "Enedis",[1.5036212770511292, 48.44625106520016]],
    [ "Enedis",[1.5040913195084493, 48.45019157296466]],
    [ "Enedis",[1.4982971544424566, 48.44416124601755]],
    [ "Enedis",[1.512128031811488, 48.44172980481101]],
    [ "Enedis",[1.5118600000000002, 48.448257999999996]],
    [ "Enedis",[1.4825458316782023, 48.45172250707192]],
    [ "Enedis",[1.4821030000000002, 48.4501175]],
    [ "Enedis",[1.4781900000000001, 48.458216]],
    [ "Enedis",[1.4621212764887856, 48.45672785344161]],
    [ "Enedis",[1.479567736510861, 48.45057430884949]],
    [ "Enedis",[1.4763371624332093, 48.45190651993424]],
    [ "Enedis",[1.4757998747913192, 48.46028024318308]],
    [ "Enedis",[1.4794169306358387, 48.45298331984586]],
    [ "Enedis",[1.4812401003813074, 48.45639319499867]],
    [ "Enedis",[1.4739090308641976, 48.45430268209876]],
    [ "Enedis",[2.069714884786325, 49.03492793059829]],
    [ "Geredis",[-0.0369265, 46.394284999999996]],
    [ "réséda",[6.657777500000001, 49.058512500000006]],
    [ "réséda",[6.663850532071503, 49.059437787592]],
    [ "réséda",[6.655536, 49.05877050000001]],
    [ "réséda",[6.662277, 49.0551565]],
    [ "réséda",[6.661413, 49.060102500000006]],
    [ "Enedis",[3.7265566858513193, 48.40076991366906]],
    [ "Enedis",[3.743088197372743, 48.400690458456495]],
    [ "Enedis",[3.7312141821207794, 48.3960346509748]],
    [ "Enedis",[3.735472716087783, 48.39984583998618]],
    [ "Enedis",[3.72470675, 48.402797]],
    [ "Enedis",[5.480842299999999, 45.2011101]],
    [ "réséda",[6.2132775574061725, 49.12452132844769]],
    [ "réséda",[6.213686586260879, 49.13286984513745]],
    [ "réséda",[6.2147964912280695, 49.121893883040926]],
    [ "réséda",[6.20342231990794, 49.12464204564634]],
    [ "réséda",[6.211040362487853, 49.130148061224496]],
    [ "réséda",[6.214426981362008, 49.13604733906809]],
    [ "réséda",[6.23120218106996, 49.11954257613168]],
    [ "réséda",[6.2260124999999995, 49.121928999999994]],
    [ "réséda",[6.227535476246564, 49.12632928229289]],
    [ "réséda",[6.223810878131709, 49.11999235360521]],
    [ "Enedis",[0.3726229002624668, 48.351292120019096]],
    [ "réséda",[6.217409299999998, 49.126451599999996]],
    [ "réséda",[6.207732999999999, 49.1273975]],
    [ "CTRE SOCIAL SAULNOIS",[6.229088531962034, 49.1232930488055]],
    [ "réséda",[6.218581257032892, 49.12268867506534]],
    [ "Enedis",[0.37906585, 48.350642699999995]],
    [ "Enedis",[0.36426294548063126, 48.35318328694404]],
    [ "Enedis",[0.36522793952802346, 48.35284098525074]],
    [ "réséda",[6.216779610837439, 49.12114670607554]],
    [ "Enedis",[0.36667165382851863, 48.34341232141219]],
    [ "réséda",[6.210224, 49.134316999999996]],
    [ "Enedis",[0.37936506548577553, 48.353055184111646]],
    [ "réséda",[6.211782255707762, 49.12071592237444]],
    [ "réséda",[6.214358167958145, 49.12992126549153]],
    [ "Enedis",[0.3667541351351352, 48.35577154954955]],
    [ "Enedis",[0.3695167709187359, 48.355408676020275]],
    [ "Enedis",[0.3707841425762042, 48.35115423598819]],
    [ "Enedis",[0.3719220139082062, 48.346864943439954]],
    [ "réséda",[6.189196578557172, 49.07878017080126]],
    [ "réséda",[6.1939105, 49.070809000000004]],
    [ "réséda",[6.181635999999999, 49.0754135]],
    [ "réséda",[6.182207621691727, 49.08749316559982]],
    [ "réséda",[6.1834811904118325, 49.073044583624274]],
    [ "réséda",[6.182928724566029, 49.083022612502745]],
    [ "réséda",[6.1858793702894594, 49.06708633788458]],
    [ "réséda",[6.186122644233432, 49.070512457804156]],
    [ "réséda",[6.188597981951873, 49.080974079768275]],
    [ "réséda",[6.194757, 49.07191]],
    [ "réséda",[6.197091273092369, 49.075825530120476]],
    [ "réséda",[6.188639, 49.07421600000001]],
    [ "réséda",[6.191266339964632, 49.08079302122016]],
    [ "réséda",[6.188090166666666, 49.07107200000001]],
    [ "réséda",[6.191076216284988, 49.07160946541754]],
    [ "réséda",[6.183829769571968, 49.07693341960523]],
    [ "réséda",[6.190420598645738, 49.0745147513081]],
    [ "réséda",[6.191100891932176, 49.069709722563815]],
    [ "réséda",[6.187860018169112, 49.08686983927324]],
    [ "réséda",[6.187267449999999, 49.077602549999995]],
    [ "réséda",[6.189473875895491, 49.120765735250735]],
    [ "66 André Malraux",[6.179872904923734, 49.10321282619748]],
    [ "réséda",[6.187799820145694, 49.112025482290875]],
    [ "réséda",[6.182211426796114, 49.09571523236245]],
    [ "réséda",[6.175465484494097, 49.1029016600884]],
    [ "réséda",[6.17360113851779, 49.10613672349847]],
    [ "Théatre",[6.173091978378828, 49.122116175807655]],
    [ "réséda",[6.179756825287357, 49.098977826819926]],
    [ "réséda",[6.184297903231349, 49.10172213526143]],
    [ "réséda",[6.174749801355357, 49.10005570552732]],
    [ "réséda",[6.170364500000001, 49.100891]],
    [ "réséda",[6.163597218316734, 49.108220569615305]],
    [ "réséda",[6.169306494084408, 49.099207542809474]],
    [ "réséda",[6.183475783603318, 49.095292500792134]],
    [ "réséda",[6.164829727849535, 49.12935867527758]],
    [ "réséda",[6.177253792210483, 49.09442351854154]],
    [ "réséda",[6.193218000000001, 49.11207050000001]],
    [ "réséda",[6.17662655, 49.09845160000001]],
    [ "réséda",[6.18552871025641, 49.09988062820512]],
    [ "réséda",[6.181670200000001, 49.09200739999999]],
    [ "réséda",[6.1835344321160886, 49.12859664737238]],
    [ "réséda",[6.140871389937107, 49.067589805031446]],
    [ "réséda",[6.148589704002246, 49.06834567501291]],
    [ "réséda",[6.171071430142834, 49.060550216275374]],
    [ "réséda",[6.162540346029613, 49.064101912437245]],
    [ "réséda",[6.149693064825474, 49.07777796740496]],
    [ "réséda",[6.168059355669515, 49.06744002472934]],
    [ "réséda",[6.1426975, 49.085752500000005]],
    [ "réséda",[6.146150932748539, 49.08701283479532]],
    [ "réséda",[6.158495089455679, 49.06493623127808]],
    [ "réséda",[6.1641323001071795, 49.0550926395141]],
    [ "réséda",[6.165664098653197, 49.05820815331088]],
    [ "réséda",[6.1667847490636705, 49.060576449438194]],
    [ "réséda",[6.150433151038389, 49.070234638137194]],
    [ "réséda",[6.148971000000001, 49.0835025]],
    [ "réséda",[6.145084425637702, 49.074400256268916]],
    [ "réséda",[6.151996, 49.060648500000006]],
    [ "réséda",[6.162353031785438, 49.06266935258293]],
    [ "réséda",[6.148614299999999, 49.080631000000004]],
    [ "réséda",[6.148163313803817, 49.08366908300045]],
    [ "réséda",[6.150565500000001, 49.0750345]],
    [ "réséda",[6.167828449999999, 49.064482000000005]],
    [ "réséda",[6.1421798333608955, 49.072763501736404]],
    [ "réséda",[6.151967214763176, 49.067309179515725]],
    [ "réséda",[6.161725163636363, 49.05766799653679]],
    [ "réséda",[6.159949169473931, 49.067483953963965]],
    [ "réséda",[6.143485898236093, 49.07217915241973]],
    [ "réséda",[6.158564, 49.0614565]],
    [ "réséda",[6.165271367931449, 49.067111916565025]],
    [ "réséda",[6.149236473829201, 49.06595719834711]],
    [ "réséda",[6.154654001054268, 49.08032591636466]],
    [ "réséda",[6.153885220608575, 49.06290032572613]],
    [ "réséda",[6.156275599999999, 49.0621453]],
    [ "réséda",[6.1410002, 49.06128619999999]],
    [ "réséda",[6.169762229918781, 49.057033032812626]],
    [ "réséda",[6.147030300000001, 49.0761136]],
    [ "réséda",[6.159103218144091, 49.0570607381714]],
    [ "réséda",[6.1511363333333335, 49.07300617874395]],
    [ "réséda",[6.1700854000000005, 49.0636326]],
    [ "réséda",[6.147606216075437, 49.06166659633923]],
    [ "Poste Électrique de Reinière",[0.5455900645751696, 48.50485784543006]],
    [ "Enedis",[2.5403614819324822, 48.70048502682902]],
    [ "Enedis",[2.5600230115099096, 48.710346617596706]],
    [ "Enedis",[2.542564999999999, 48.710872]],
    [ "Enedis",[2.543666545872647, 48.70054526796377]],
    [ "Enedis",[4.010949312314854, 50.09214896231795]],
    [ "Enedis",[2.5594812499999997, 48.696355499999996]],
    [ "Enedis",[2.5535829999999997, 48.695642]],
    [ "Enedis",[6.989623955010225, 49.06867265712338]],
    [ "Enedis",[6.991125838608029, 49.07115122400329]],
    [ "Enedis",[6.994105832908163, 49.07114701955781]],
    [ "Enedis",[6.998005370316301, 49.072732682238446]],
    [ "Enedis",[7.021758, 49.07019150000001]],
    [ "Enedis",[6.996250412454213, 49.06902828351649]],
    [ "Enedis",[7.009997361998362, 49.07631993775594]],
    [ "Enedis",[7.007043684271484, 49.07431257261987]],
    [ "Enedis",[2.531945438596491, 48.720138666666664]],
    [ "Enedis",[2.5268632499999986, 48.7111341]],
    [ "Enedis",[2.5264929999999994, 48.72903674999999]],
    [ "Enedis",[2.52828875, 48.709784250000006]],
    [ "Enedis",[2.5241902499999993, 48.72309525]],
    [ "Enedis",[2.5389644, 48.72184874999999]],
    [ "Enedis",[2.544995400000001, 48.71995460000001]],
    [ "Enedis",[2.5356209999999995, 48.72478175]],
    [ "Enedis",[2.532355756313477, 48.7230512547235]],
    [ "Enedis",[2.5211505, 48.719676500000006]],
    [ "Enedis",[2.5217680000000002, 48.726569500000004]],
    [ "Poste électrique de Villeneuve-Saint-Georges",[2.4459707573836833, 48.75429817906942]],
    [ "Enedis",[2.5603605000000003, 48.74096600000001]],
    [ "Enedis",[2.5581912499999997, 48.736518249999996]],
    [ "Enedis",[2.55518075, 48.73010475]],
    [ "Enedis",[2.54009375, 48.727256749999995]],
    [ "Enedis",[4.007427974041564, 50.08890911546252]],
    [ "Enedis",[4.008810162323561, 50.08500429261672]],
    [ "RTE",[-2.063239322899869, 47.67543595631066]],
    [ "Poste électrique de Sévérac",[-2.045909422883146, 47.54182291276965]],
    [ "Enedis",[4.829650956774115, 45.77879233518588]],
    [ "Enedis",[2.540564186435828, 48.749906938621024]],
    [ "Enedis",[2.5349465, 48.75409150000001]],
    [ "Enedis",[2.539377, 48.75235025]],
    [ "Enedis",[2.53099226127351, 48.75283614004079]],
    [ "Enedis",[2.5095587499999996, 48.749729]],
    [ "Enedis",[2.5101937499999996, 48.7492065]],
    [ "Enedis",[2.5022860000000007, 48.748938]],
    [ "Enedis",[2.51184375, 48.74571625]],
    [ "Enedis",[-1.8804614999999998, 46.67664775000001]],
    [ "Enedis",[-1.9002805500000002, 46.66854285]],
    [ "Enedis",[-1.8842335, 46.67512275]],
    [ "Enedis",[-1.8951379999999989, 46.66901800000001]],
    [ "Enedis",[-1.8916625359221384, 46.670903370820135]],
    [ "Enedis",[-1.895515111102148, 46.672361278362516]],
    [ "Enedis",[-1.88316375, 46.673202249999996]],
    [ "Enedis",[-1.8854011661877135, 46.670385494581836]],
    [ "Enedis",[-1.88753775, 46.673604]],
    [ "Enedis",[-1.88829899007269, 46.671578029684554]],
    [ "Enedis",[-1.901535, 46.6704745]],
    [ "Enedis",[3.0688329121973434, 50.280233517924806]],
    [ "Enedis",[3.0763390463355105, 50.28255153760638]],
    [ "Enedis",[3.0568139473001987, 50.280682624860496]],
    [ "Enedis",[3.0705210820199325, 50.28257809830752]],
    [ "Verdaret",[5.61560075, 45.276831249999994]],
    [ "Enedis",[3.903515192592595, 43.62252632423652]],
    [ "Enedis",[3.9048909999999997, 43.6229995]],
    [ "Enedis",[3.9263500000000002, 43.638377000000006]],
    [ "Enedis",[3.9276643557422957, 43.63837732773108]],
    [ "Enedis",[3.538945645251809, 48.28230083074878]],
    [ "Enedis",[1.5404912339748726, 43.64068862921678]],
    [ "Enedis",[-1.8224142500000007, 46.738918000000005]],
    [ "Enedis",[7.035571, 49.0124555]],
    [ "Enedis",[7.04562271692535, 49.02602589283075]],
    [ "Enedis",[1.9920046558527982, 48.82841463637942]],
    [ "Enedis",[1.9900502580582229, 48.82385327630233]],
    [ "Enedis",[1.9940660459222828, 48.82325144053997]],
    [ "Enedis",[1.993586749887453, 48.824791247657444]],
    [ "Enedis",[1.9715998169146507, 48.818271031412]],
    [ "PARSTA",[1.9920518560942655, 48.81839182522499]],
    [ "Enedis",[1.9788708789997072, 48.82012550395784]],
    [ "Enedis",[1.9746216523969602, 48.828383060688914]],
    [ "Enedis",[1.9681017864402424, 48.82615749406411]],
    [ "Enedis",[1.9880897185176605, 48.820347997366966]],
    [ "Enedis",[1.993092080298761, 48.820964515640014]],
    [ "Enedis",[1.975786869014977, 48.81951479098232]],
    [ "Enedis",[1.9738121333650611, 48.82694080428947]],
    [ "Enedis",[2.022499311611351, 48.90234902204918]],
    [ "Enedis",[2.0859529667353063, 48.838946891291144]],
    [ "Enedis",[2.080002500665306, 48.84030057756105]],
    [ "Enedis",[2.0709176431292664, 48.83600832196135]],
    [ "Enedis",[2.0791894664429877, 48.838920499189555]],
    [ "Enedis",[2.05963466555981, 48.84658753281762]],
    [ "Poste électrique de L'Ardoise",[4.703382185628216, 44.08635273282664]],
    [ "Poste électrique de Keller-Leleux",[4.694586638544936, 44.089337397038236]],
    [ "Enedis",[2.0426245188046415, 48.833455867360776]],
    [ "Enedis",[1.521309, 43.634215000000005]],
    [ "Poste électrique de Estreux",[3.609541378345159, 50.35913731374723]],
    [ "Enedis",[4.879726463016472, 44.87274003069009]],
    [ "Enedis",[4.875552, 44.871308049999996]],
    [ "Enedis",[4.8875850720580605, 44.862073396149]],
    [ "Enedis",[2.015432464251208, 48.83395588686868]],
    [ "Enedis",[2.004066437782017, 48.82979270782107]],
    [ "Enedis",[1.9962177141058435, 48.83178870385181]],
    [ "Enedis",[2.0042332971522328, 48.81622672934807]],
    [ "Enedis",[1.9914272500000003, 48.83168915]],
    [ "Enedis",[1.9991116444901895, 48.82016089313727]],
    [ "Enedis",[1.9951093037370233, 48.83287789866428]],
    [ "Poste électrique de La Mole",[2.3566629914180597, 45.3940452493141]],
    [ "Enedis",[2.001692631378868, 48.8156120966708]],
    [ "Enedis",[2.0014194617771106, 48.817925143595744]],
    [ "Le Luc",[1.1202052493492072, 44.116553499447015]],
    [ "Enedis",[4.884079839965109, 44.893937261900994]],
    [ "Enedis",[4.883707949624235, 44.89976824468952]],
    [ "Enedis",[4.8874169186980145, 44.89510368396277]],
    [ "Enedis",[4.888221550000002, 44.893116500000005]],
    [ "Enedis",[2.1981963414869177, 48.88452915795717]],
    [ "Enedis",[2.21492783640657, 48.88924777681702]],
    [ "Enedis",[2.2193393377520025, 48.91508204639602]],
    [ "Enedis",[3.0397036333629495, 50.2767573362706]],
    [ "Enedis",[3.032448000000001, 50.27489799999999]],
    [ "Enedis",[3.0389887657657653, 50.27937674774775]],
    [ "Enedis",[3.0439979, 50.2793147]],
    [ "Enedis",[3.038674901801802, 50.27633887027027]],
    [ "Enedis",[3.0382227659758203, 50.27873305181347]],
    [ "Enedis",[3.049947276119404, 50.280191579601976]],
    [ "Poste électrique de Châteauneuf-du Rhône",[4.709232399507036, 44.49552179616463]],
    [ "Enedis",[1.5292956918128662, 49.0769535631579]],
    [ "Enedis",[6.939590201773835, 47.49729728677014]],
    [ "Enedis",[6.942203265245707, 47.50399943872113]],
    [ "Enedis",[6.9417374288376426, 47.498895336361514]],
    [ "Enedis",[6.949150043093924, 47.50578272903623]],
    [ "Enedis",[6.952499523604466, 47.50691073444977]],
    [ "Enedis",[1.4335128289368504, 49.07961145750066]],
    [ "Avron",[2.527023892101166, 48.872247520358194]],
    [ "Saint-Barthélemy-de-Séchilienne",[5.826355510376135, 45.044471632684825]],
    [ "Poste électrique de la Feuillane",[4.906242358416199, 43.45490088097895]],
    [ "RTE",[4.849231071419238, 43.45445128219827]],
    [ "Enedis",[1.8433689757843763, 50.94817937144663]],
    [ "Enedis",[1.859512199626864, 50.937952818097024]],
    [ "Enedis",[1.8573195874883879, 50.947465005471265]],
    [ "Enedis",[1.8472355780184522, 50.947070087043734]],
    [ "Enedis",[1.8427494632962003, 50.94973120936875]],
    [ "Enedis",[1.847069499999999, 50.942191]],
    [ "Enedis",[1.8578692532058938, 50.93895066963615]],
    [ "Enedis",[1.84874938700565, 50.95111514689266]],
    [ "Enedis",[1.8484411035476325, 50.945963243291914]],
    [ "Enedis",[1.8588442437453185, 50.94937946382022]],
    [ "Enedis",[1.865607359477125, 50.93971675163399]],
    [ "Enedis",[1.8515262970614292, 50.95098147085293]],
    [ "Enedis",[1.8616389436175023, 50.93948129454217]],
    [ "Enedis",[1.8528097645021642, 50.9461145012987]],
    [ "Enedis",[1.843325466231779, 50.95032423300437]],
    [ "Enedis",[1.8417339230507332, 50.949755249574395]],
    [ "Enedis",[1.829856512721, 50.961278113410955]],
    [ "Enedis",[1.8332474888497647, 50.96136186189358]],
    [ "Enedis",[1.8375855942028985, 50.962175228663455]],
    [ "CARNOT",[4.834869350784424, 45.06558297483908]],
    [ "Monet",[1.848526264977478, 50.95567936959459]],
    [ "Enedis",[1.8523480390609184, 50.96244716008905]],
    [ "Ravisse",[1.8498767936716487, 50.960047949635964]],
    [ "Enedis",[5.8143272569755275, 45.121720604807045]],
    [ "Le Bourg",[5.810226233395328, 45.11950630069848]],
    [ "Enedis",[3.2590467999999997, 48.2188145]],
    [ "Enedis",[3.1467893933547697, 47.99895035155412]],
    [ "104779194",[6.4369038, 46.042170999999996]],
    [ "Enedis",[3.2966424256578115, 48.21916832663142]],
    [ "Enedis",[3.2860922080706167, 48.21253167969734]],
    [ "Enedis",[3.282400694117647, 48.221274668235296]],
    [ "Enedis",[3.286698456528055, 48.2162166716165]],
    [ "Enedis",[3.2962843833690734, 48.22526960257327]],
    [ "Enedis",[3.289303319531576, 48.216423846507745]],
    [ "Enedis",[3.2896730033352948, 48.22050348126349]],
    [ "Enedis",[3.2817438014184392, 48.213737411347516]],
    [ "Enedis",[3.28181578247898, 48.21672447626798]],
    [ "Enedis",[6.4879802, 46.02868599999999]],
    [ "Enedis",[6.482267959763851, 46.04987516730245]],
    [ "Enedis",[6.503473940843821, 46.05047534850567]],
    [ "Enedis",[3.0491865000000007, 48.30346800000001]],
    [ "Enedis",[3.052038898989899, 48.30381642424242]],
    [ "Enedis",[2.566296015905299, 48.83711685261421]],
    [ "Enedis",[2.907111367330989, 50.4045719660805]],
    [ "Enedis",[2.9000710077192977, 50.39450666736841]],
    [ "Enedis",[2.9021897432766393, 50.3913781703736]],
    [ "Enedis",[2.906127305805081, 50.40372953945716]],
    [ "Enedis",[2.9172269586238966, 50.40431676011157]],
    [ "Enedis",[2.912021994117089, 50.40459418237025]],
    [ "Enedis",[2.908434500000001, 50.39121900000001]],
    [ "Enedis",[2.9201895129449844, 50.39947926569579]],
    [ "Enedis",[2.912273928522862, 50.412007853136146]],
    [ "Enedis",[4.739831927732226, 45.73058560241336]],
    [ "Enedis",[3.2484601813315783, 48.18959613497861]],
    [ "Enedis",[3.265381418719211, 48.19412027093596]],
    [ "Enedis",[3.2572398937396017, 48.18997482544886]],
    [ "Enedis",[3.2455490669895077, 48.18784835996771]],
    [ "Enedis",[3.24361922554773, 48.18171005203443]],
    [ "Enedis",[3.2519905825231903, 48.1906139976226]],
    [ "Enedis",[3.2554963384786846, 48.19227985468561]],
    [ "Enedis",[3.293105828788996, 48.138156664455906]],
    [ "Enedis",[3.255882337931034, 48.22634388493648]],
    [ "Poste source Vaudreuil",[1.1976997165087266, 49.27721609383127]],
    [ "Enedis",[3.306520400000002, 48.2923429]],
    [ "Enedis",[3.3095638500000004, 48.289273]],
    [ "Enedis",[2.8852938624338624, 50.41332425396827]],
    [ "Enedis",[2.8731188974496167, 50.40481303549135]],
    [ "Enedis",[2.8699998261060005, 50.399852232150685]],
    [ "Enedis",[2.8638248777739053, 50.40613069106541]],
    [ "Enedis",[2.8729235, 50.39761500000001]],
    [ "Enedis",[2.890514669114647, 50.41722251407589]],
    [ "Enedis",[2.8647150894630196, 50.40929736666666]],
    [ "Enedis",[2.8589455276284346, 50.408174864247314]],
    [ "Enedis",[2.886445050942641, 50.418308986361815]],
    [ "Enedis",[2.8771458986788954, 50.39968968948574]],
    [ "Enedis",[2.869177500000001, 50.39852150000001]],
    [ "Enedis",[2.863334627497822, 50.402601355530685]],
    [ "Enedis",[2.8639318070693167, 50.3985966383839]],
    [ "Enedis",[2.8896060000000015, 50.411446999999995]],
    [ "Enedis",[2.9290135000000004, 50.395507499999994]],
    [ "Enedis",[2.9292615018026056, 50.39664899874682]],
    [ "Enedis",[5.8062549820013, 45.10663434632878]],
    [ "Les Mailles",[5.8028692999999985, 45.101752499999996]],
    [ "Enedis",[6.35435602304829, 46.145143009653]],
    [ "Enedis",[2.8663220000000003, 50.58121450000001]],
    [ "Enedis",[2.8509538817758933, 50.57753064722525]],
    [ "Enedis",[2.861052056818716, 50.57913850960872]],
    [ "Enedis",[7.125826970056146, 49.13318764566438]],
    [ "Enedis",[7.1427713968712405, 49.12370076534297]],
    [ "Enedis",[7.083699602708806, 49.14883779382995]],
    [ "Enedis",[7.103975279652352, 49.15193365388548]],
    [ "Enedis",[7.219992861261261, 49.11763732072072]],
    [ "Enedis",[1.8456378386747807, 50.953444831507774]],
    [ "Gentilhomme",[2.4442221645699815, 48.984570800458904]],
    [ "Enedis",[1.8235226133333315, 50.944763214117636]],
    [ "Enedis",[1.828142777137367, 50.945067933717574]],
    [ "Enedis",[1.8322570068834967, 50.94435315160903]],
    [ "Enedis",[1.8284000718215379, 50.9475957821991]],
    [ "Enedis",[3.0633810043873075, 50.34183339883686]],
    [ "Enedis",[1.8314092303692147, 50.94815745085804]],
    [ "Enedis",[1.8245742533936655, 50.94782104449471]],
    [ "Enedis",[3.057393498735457, 50.346467059382896]],
    [ "Enedis",[3.0686383462532305, 50.342150351421196]],
    [ "Enedis",[3.0608026682277547, 50.34870386106318]],
    [ "Enedis",[3.0606936902106563, 50.34184639405205]],
    [ "Enedis",[1.827542515310586, 50.948927328958874]],
    [ "Enedis",[3.0582446977058035, 50.339896612685564]],
    [ "Enedis",[3.065623, 50.343368]],
    [ "Enedis",[3.064237, 50.3408755]],
    [ "Enedis",[7.260406999999998, 49.042556]],
    [ "Enedis",[7.268535000000001, 49.055698]],
    [ "Enedis",[7.276669631304732, 49.0464938193105]],
    [ "Enedis",[7.279335, 49.048529]],
    [ "Enedis",[7.262309467430755, 49.054343205348616]],
    [ "Enedis",[7.276077998336104, 49.04315072490294]],
    [ "Enedis",[7.26593625120773, 49.04426408695653]],
    [ "Enedis",[6.142190054325998, 48.66955634827974]],
    [ "Enedis",[6.139995630303029, 48.66364631030302]],
    [ "Enedis",[6.141207645737896, 48.66635124324081]],
    [ "Enedis",[6.147379027586864, 48.667838609488356]],
    [ "Enedis",[6.1289775061003064, 48.65771972138555]],
    [ "Enedis",[6.136802093650793, 48.656873862481966]],
    [ "Enedis",[6.1614683306010924, 48.668830422131144]],
    [ "Enedis",[6.13774473705058, 48.65541905118829]],
    [ "Enedis",[6.1555769895085675, 48.66570122513712]],
    [ "Enedis",[6.1304792500000005, 48.658053599999995]],
    [ "Enedis",[6.15209115897837, 48.66482570263227]],
    [ "Enedis",[6.137076560850324, 48.66542412359729]],
    [ "Enedis",[6.139536326843068, 48.66861683541356]],
    [ "Enedis",[6.134954975859621, 48.65556760571583]],
    [ "Enedis",[2.78068807753898, 50.41524550653181]],
    [ "Enedis",[2.7780583602197173, 50.42615290488581]],
    [ "Enedis",[2.7641239999999994, 50.42942250000001]],
    [ "Enedis",[2.776014953860403, 50.42167412099015]],
    [ "Enedis",[2.789445323484351, 50.42348525782446]],
    [ "Enedis",[2.783999824367477, 50.42727297617292]],
    [ "Enedis",[2.767677739809194, 50.43261794261347]],
    [ "Enedis",[2.751070000000001, 50.427649]],
    [ "Enedis",[2.797530988658368, 50.42493310511756]],
    [ "Enedis",[2.7680473229118063, 50.43139270244206]],
    [ "Enedis",[2.770704185697438, 50.42422166398628]],
    [ "Enedis",[2.79229332183908, 50.41026593103449]],
    [ "Enedis",[2.7398290000000007, 50.426607999999995]],
    [ "Enedis",[2.7852838124812482, 50.43003199459946]],
    [ "Enedis",[2.7749382905982904, 50.41829083760683]],
    [ "Enedis",[2.7622578589988, 50.43100710703946]],
    [ "Enedis",[2.7620918216922186, 50.416264487223174]],
    [ "Enedis",[2.7968168315032673, 50.42716829725491]],
    [ "Enedis",[2.7855295333333343, 50.42287915555556]],
    [ "Enedis",[2.7513239052594916, 50.4362357001045]],
    [ "Enedis",[2.7679240583941613, 50.419397625304136]],
    [ "Enedis",[2.767696168448976, 50.413035759346755]],
    [ "Enedis",[2.762689510085112, 50.41772177439664]],
    [ "Enedis",[2.795434446816479, 50.42696321610486]],
    [ "Enedis",[2.7891199287366963, 50.42620085454266]],
    [ "Enedis",[2.7476606803441435, 50.42794912993603]],
    [ "Enedis",[2.764869930717999, 50.42469656635803]],
    [ "Enedis",[2.786546, 50.413160500000004]],
    [ "Enedis",[2.781271121989529, 50.42833918708553]],
    [ "Enedis",[2.7748692993884605, 50.428027764778875]],
    [ "Enedis",[2.742088347985348, 50.42633015018315]],
    [ "Enedis",[2.7734251445221436, 50.423009136752135]],
    [ "Enedis",[2.7818302888888886, 50.423930155555574]],
    [ "Enedis",[2.7551433610737046, 50.42488357904659]],
    [ "Enedis",[2.787964318132464, 50.426777351791536]],
    [ "Enedis",[2.7637990000000006, 50.42335850000001]],
    [ "Enedis",[2.7615711022927685, 50.42086740035274]],
    [ "Enedis",[2.784986674542293, 50.42903352531451]],
    [ "Enedis",[2.7992661203703713, 50.41102073765431]],
    [ "Enedis",[2.7901320000000003, 50.424565]],
    [ "Enedis",[2.7474511823149523, 50.43115402781917]],
    [ "Enedis",[2.774527824320457, 50.43196433676681]],
    [ "Enedis",[2.7713226001335123, 50.414685871829114]],
    [ "Enedis",[2.768277747713824, 50.41747751873767]],
    [ "Enedis",[2.7718866173420476, 50.43244576592593]],
    [ "Enedis",[2.7747992022792025, 50.43561751566952]],
    [ "Enedis",[2.7608511025850326, 50.428285597188214]],
    [ "Enedis",[2.780001609952433, 50.43796296706915]],
    [ "Enedis",[2.7716351570957096, 50.41148670627064]],
    [ "Enedis",[2.7751106609479503, 50.413373847242404]],
    [ "Enedis",[2.7814534795439307, 50.41015816968478]],
    [ "Enedis",[2.7702164109844936, 50.41986620228533]],
    [ "Enedis",[2.770156459016394, 50.430285484517306]],
    [ "Enedis",[2.791008626050421, 50.42393167086836]],
    [ "Enedis",[2.750309, 50.431546]],
    [ "Enedis",[2.772571620996614, 50.42957928176101]],
    [ "Enedis",[2.787508808723068, 50.41067787475917]],
    [ "Enedis",[2.739665331412658, 50.42672748871602]],
    [ "Enedis",[2.778481371335224, 50.420483698392225]],
    [ "Enedis",[2.788492911372549, 50.43988873788235]],
    [ "Enedis",[2.7746522930817603, 50.41684226289309]],
    [ "Enedis",[2.7810110126126126, 50.42216727027027]],
    [ "Enedis",[2.764483292762088, 50.41937335589095]],
    [ "Enedis",[2.7929384999999995, 50.4239915]],
    [ "Enedis",[2.766634657464212, 50.41611019529652]],
    [ "Enedis",[2.779525701470893, 50.432295133209024]],
    [ "Enedis",[2.7562730948905108, 50.43349185158151]],
    [ "Enedis",[2.768961844086021, 50.42816425537635]],
    [ "Enedis",[2.787261670826833, 50.419731973998964]],
    [ "Enedis",[2.7474000900818925, 50.433344309978764]],
    [ "Enedis",[2.7902782374130606, 50.413275450327006]],
    [ "Enedis",[2.7851973732142854, 50.41482187083333]],
    [ "Enedis",[2.7713275491329483, 50.417445233782914]],
    [ "Enedis",[2.791735106776181, 50.41559330458589]],
    [ "Enedis",[2.744489006162465, 50.427947663865545]],
    [ "Enedis",[2.7439300000000006, 50.429364750000005]],
    [ "Enedis",[2.7730540102509726, 50.419769187345345]],
    [ "Enedis",[2.7598230643500634, 50.415609656370656]],
    [ "Enedis",[2.7513864999999997, 50.429483]],
    [ "Enedis",[2.7805777331656887, 50.43146964403465]],
    [ "Enedis",[2.780166671720653, 50.42527200964852]],
    [ "Enedis",[2.792411315423191, 50.434235408658274]],
    [ "Enedis",[2.764421257293161, 50.426880734098525]],
    [ "Enedis",[3.1296285961260475, 50.354258653946225]],
    [ "Enedis",[3.1324737126940647, 50.35692403232876]],
    [ "Enedis",[3.126698279888138, 50.35808958851861]],
    [ "Enedis",[4.83129454198087, 45.069051738671]],
    [ "Enedis",[6.1088282050158265, 48.653494218164106]],
    [ "RTE",[5.736654926423083, 45.08483803097885]],
    [ "Darse",[4.847465500418746, 43.428339661557494]],
    [ "105211326",[4.833091404854635, 43.43578489535135]],
    [ "Enedis",[1.943050078484527, 49.157816440793674]],
    [ "Enedis",[1.9203198500000003, 49.16283765]],
    [ "Enedis",[1.937955965886287, 49.160978731326644]],
    [ "Enedis",[1.9327160191582002, 49.154773578229324]],
    [ "Enedis",[1.9362759089726918, 49.15806770134374]],
    [ "Enedis",[1.9468914146981613, 49.15820372265968]],
    [ "Enedis",[1.935316030612244, 49.16480461700681]],
    [ "Enedis",[1.936114884516634, 49.15861427386316]],
    [ "Enedis",[3.124828001110803, 50.347586721096]],
    [ "Enedis",[3.1314531882240217, 50.351072100513896]],
    [ "Enedis",[3.1164087125980533, 50.3453163465646]],
    [ "Enedis",[3.1280961002710033, 50.349037682926834]],
    [ "Enedis",[3.1113646268663815, 50.3446390425107]],
    [ "Enedis",[3.106617778597786, 50.34268615088152]],
    [ "Enedis",[3.1700490569928763, 50.38660970866141]],
    [ "Enedis",[3.1527151505565314, 50.39054367721149]],
    [ "Enedis",[3.1668192234766583, 50.38007913499881]],
    [ "Enedis",[3.1556683365617433, 50.39118568442292]],
    [ "Enedis",[3.161723171952652, 50.39021951911086]],
    [ "Enedis",[3.1534697155909965, 50.392283442063295]],
    [ "Enedis",[3.1686259447731753, 50.38834783234714]],
    [ "Enedis",[3.1528277968638636, 50.38972545505663]],
    [ "Enedis",[3.173560333333333, 50.38055799999999]],
    [ "Enedis",[3.165744603500931, 50.39455605415271]],
    [ "Enedis",[3.1677095427593054, 50.39202082454717]],
    [ "Enedis",[3.17286731884058, 50.38968253359684]],
    [ "Enedis",[3.1708297603305793, 50.37867912243648]],
    [ "Enedis",[3.159148036441586, 50.38665580600213]],
    [ "Enedis",[3.155414745895789, 50.38731462883655]],
    [ "Enedis",[3.150964400611621, 50.39355614678899]],
    [ "Enedis",[3.160410327956989, 50.382250956989246]],
    [ "Enedis",[3.1708462632964722, 50.39377690170266]],
    [ "105260066",[4.849863925693426, 43.44302309045377]],
    [ "Enedis",[3.112969240310078, 50.41325704651164]],
    [ "Enedis",[3.1141040739064856, 50.41814391372549]],
    [ "Enedis",[3.123797235277052, 50.41972343777198]],
    [ "Enedis",[3.1129270669914746, 50.42138781404791]],
    [ "Enedis",[3.108406944862155, 50.419389862155384]],
    [ "Enedis",[3.1159912091836723, 50.42172109013606]],
    [ "Enedis",[3.108982421057862, 50.41276888666521]],
    [ "Enedis",[3.1119117232733426, 50.41910825334564]],
    [ "Enedis",[3.116123246562077, 50.4199049535154]],
    [ "Enedis",[3.109960407899104, 50.41517426374599]],
    [ "Enedis",[6.07023437275625, 45.09448680529958]],
    [ "Enedis",[3.0893631593616546, 50.41718894066083]],
    [ "Enedis",[6.073140817726726, 45.09120159805575]],
    [ "Enedis",[3.092290333333333, 50.41706436752137]],
    [ "Enedis",[3.0989451384167803, 50.414664315418776]],
    [ "Enedis",[3.1043280019147916, 50.411442817296944]],
    [ "Enedis",[3.1049651178211968, 50.416292698638244]],
    [ "Enedis",[6.0713737000000005, 45.0923492]],
    [ "Enedis",[6.071612535472283, 45.091377581617714]],
    [ "Enedis",[6.0705136500000005, 45.09049255000001]],
    [ "Enedis",[-0.28996343774855926, 49.13599802065617]],
    [ "Enedis",[-0.30186279487179557, 49.13766894871795]],
    [ "Enedis",[-0.29344450000000005, 49.132494]],
    [ "Enedis",[-0.29943, 49.13176725000001]],
    [ "Enedis",[-0.2782155, 49.1353608]],
    [ "Enedis",[-0.29443800000000003, 49.1354935]],
    [ "Enedis",[-0.3026453500000001, 49.13976035000001]],
    [ "Enedis",[-0.29052660730908947, 49.134490731228624]],
    [ "Enedis",[-0.29550600000000005, 49.13902949999999]],
    [ "Enedis",[-0.29724759857349803, 49.1360898026301]],
    [ "Enedis",[-0.304334890547264, 49.13626480597015]],
    [ "Enedis",[-0.291758, 49.136965000000004]],
    [ "Enedis",[-0.29973589920159677, 49.13432508782435]],
    [ "Enedis",[3.104720685030606, 50.40032464106844]],
    [ "Enedis",[3.108836320020882, 50.40873253275907]],
    [ "Enedis",[3.098516905660377, 50.40796325157233]],
    [ "Enedis",[3.1033471628787876, 50.4027365219697]],
    [ "Enedis",[3.107309784905661, 50.40916192201258]],
    [ "Enedis",[1.8604727113344504, 50.933555289161866]],
    [ "Enedis",[1.8805066666666665, 50.93912429803922]],
    [ "Enedis",[1.8877255000000002, 50.935337499999996]],
    [ "Enedis",[1.8834228258064516, 50.93744070537634]],
    [ "Enedis",[1.850971698412698, 50.9355349047619]],
    [ "Enedis",[1.8517159470148021, 50.93318325433174]],
    [ "Enedis",[1.8600021934021653, 50.930340857634505]],
    [ "Enedis",[1.8546043501118568, 50.935458784116335]],
    [ "Enedis",[1.8860210581818175, 50.938158869090906]],
    [ "Enedis",[1.8473187719298236, 50.936307087719285]],
    [ "Enedis",[5.0086025, 43.615446500000004]],
    [ "Enedis",[5.010909657907426, 43.613733178373764]],
    [ "Enedis",[5.007812499999999, 43.611439999999995]],
    [ "Enedis",[5.009859836421388, 43.614328483677454]],
    [ "Enedis",[5.003683187091366, 43.61013527004749]],
    [ "Enedis",[5.006227265306122, 43.617166305150626]],
    [ "Enedis",[3.0560437358490553, 50.411851257861635]],
    [ "Enedis",[3.06570789921714, 50.41698374099711]],
    [ "Enedis",[3.0628350728150835, 50.41384125745333]],
    [ "Enedis",[3.0852558258362164, 50.40737402652826]],
    [ "Enedis",[3.091351933972311, 50.40596787326944]],
    [ "Enedis",[3.0616298817637055, 50.42368578391915]],
    [ "Enedis",[3.037555293693694, 50.41340881945946]],
    [ "Enedis",[3.0822455715464776, 50.40756916764674]],
    [ "Enedis",[3.090031649874055, 50.40415026952141]],
    [ "Enedis",[3.089835, 50.408253404371585]],
    [ "Enedis",[3.0653176458910436, 50.420607038781164]],
    [ "Enedis",[3.056819761743067, 50.41420602905112]],
    [ "Enedis",[3.0548543404313397, 50.4149448008008]],
    [ "Enedis",[3.0516013089566028, 50.410099046722074]],
    [ "Enedis",[3.049222950081446, 50.417216576262405]],
    [ "RTE",[6.071331362548934, 45.087162165784264]],
    [ "Enedis",[1.8814356794646583, 50.95071953994145]],
    [ "Enedis",[1.9037838875855329, 50.94641624535679]],
    [ "Enedis",[1.8841004693798444, 50.95461375232558]],
    [ "Enedis",[1.8967909770377622, 50.9525277136794]],
    [ "Enedis",[1.8995422993197275, 50.95105515646259]],
    [ "Enedis",[1.8875665046554928, 50.95515477802608]],
    [ "Enedis",[1.8979895000000007, 50.94499100000001]],
    [ "Enedis",[1.8967990870837916, 50.95057438492499]],
    [ "Enedis",[1.909186752698727, 50.946588241740265]],
    [ "Enedis",[1.8994366200762391, 50.95360525285895]],
    [ "Enedis",[1.8890117147839873, 50.94783259857312]],
    [ "Enedis",[1.893001118419588, 50.94417286076794]],
    [ "Enedis",[1.916227233340714, 50.95117961323888]],
    [ "Enedis",[3.011197764044944, 50.407152625468164]],
    [ "Enedis",[3.0131634929577467, 50.41079550704225]],
    [ "Enedis",[3.010152, 50.408341500000006]],
    [ "Enedis",[3.0177822635658913, 50.40764964341085]],
    [ "Enedis",[3.0059138559511687, 50.42473986802306]],
    [ "Enedis",[3.00853617961856, 50.41985947224594]],
    [ "Enedis",[3.025811, 50.418240499999996]],
    [ "Enedis",[3.017719521629241, 50.41373913434432]],
    [ "Enedis",[3.014503, 50.41833350000001]],
    [ "Enedis",[3.0215967931034484, 50.42146906103845]],
    [ "Enedis",[3.0155256442953022, 50.421574791946306]],
    [ "Enedis",[3.0143629999999995, 50.4083505]],
    [ "Enedis",[3.0232205651802486, 50.41629476354008]],
    [ "Enedis",[3.02588901075558, 50.42005662713991]],
    [ "105500947",[-1.1909834452893957, 45.001856307139846]],
    [ "Enedis",[6.060191168444589, 45.09074449770942]],
    [ "Poste électrique de Braud",[-0.6820200896708689, 45.25776485445326]],
    [ "Enedis",[2.8137181289693096, 50.4054007624623]],
    [ "Enedis",[2.8285695000000004, 50.4158215]],
    [ "Enedis",[2.810496189670726, 50.39901415336039]],
    [ "Enedis",[2.9805902522522523, 50.368038432432435]],
    [ "Enedis",[2.8106039477569533, 50.402072676725886]],
    [ "Enedis",[2.977077126666666, 50.368287269999996]],
    [ "Enedis",[2.9772425, 50.365115]],
    [ "Enedis",[2.8399600000000005, 50.40134349999999]],
    [ "Enedis",[2.830831580994583, 50.40516777613655]],
    [ "Enedis",[2.835162252873563, 50.40234808812261]],
    [ "Enedis",[2.820113, 50.409417]],
    [ "Enedis",[2.814059181163539, 50.40911798389236]],
    [ "Enedis",[2.8453510989999997, 50.42245627633333]],
    [ "Enedis",[2.8285400504544036, 50.403764871722544]],
    [ "Enedis",[2.8073603880666473, 50.39869379372616]],
    [ "Enedis",[2.8383780108534773, 50.39648514471305]],
    [ "Poste électrique de Préguillac",[-0.6059329950725686, 45.67386039951009]],
    [ "Enedis",[3.023606805031447, 50.33795823480083]],
    [ "Enedis",[3.025133188405797, 50.335026849733026]],
    [ "Enedis",[3.0270455619787167, 50.33766728904228]],
    [ "Enedis",[3.0202238954935376, 50.331288902138446]],
    [ "Enedis",[3.0062796666666665, 50.337898610062894]],
    [ "Enedis",[3.0246679895246564, 50.340273054155546]],
    [ "Enedis",[3.019465778006873, 50.33662143505155]],
    [ "Enedis",[3.0240415, 50.33657149999999]],
    [ "Poste électrique de Granzay",[-0.5185456036053292, 46.218682032669406]],
    [ "Enedis",[3.0165305882571545, 50.338988800817546]],
    [ "Enedis",[3.0281632263771607, 50.332761938078]],
    [ "Enedis",[3.0097726541169267, 50.33552311723294]],
    [ "Enedis",[3.016352104542626, 50.336358702551344]],
    [ "Enedis",[3.014664, 50.34232050000001]],
    [ "Enedis",[3.0216647921306614, 50.333277971046776]],
    [ "Enedis",[3.0063245479408987, 50.33394081368542]],
    [ "Enedis",[3.0211335870562257, 50.331090460732696]],
    [ "Enedis",[3.0195712432432438, 50.33506057657659]],
    [ "Enedis",[3.029249626666666, 50.32927978666666]],
    [ "Enedis",[3.014555641975309, 50.34094812345679]],
    [ "Enedis",[3.0166259999999996, 50.33767550000001]],
    [ "RTE",[3.0638965399617755, 46.58013083464738]],
    [ "Poste électrique de Fléac",[0.08788608149900433, 45.69929011531427]],
    [ "Poste électrique de Saintes",[-0.6127250674571275, 45.75132035858411]],
    [ "Enedis",[3.0505333512259774, 50.396258833001994]],
    [ "Enedis",[3.0420995000000004, 50.389733500000006]],
    [ "Enedis",[3.051826208144795, 50.39248266764262]],
    [ "Enedis",[3.048298164670658, 50.39181759680638]],
    [ "Enedis",[3.0271774999999996, 50.392818500000004]],
    [ "Enedis",[3.055124325986566, 50.33141897691016]],
    [ "Enedis",[3.0516750918444555, 50.332002222726594]],
    [ "Enedis",[3.046581766773163, 50.333773167199155]],
    [ "Poste électrique de Valdivienne",[0.6488948603550161, 46.46572161955535]],
    [ "Sous-station de Mouremboz",[6.1373925813081085, 46.86362038545944]],
    [ "Sous-station de Mesnay",[5.796740271965223, 46.910856047195395]],
    [ "Poste Électrique de Vimoutiers",[0.19604659343041353, 48.939296526960234]],
    [ "Enedis",[2.4641959499999984, 48.855571149999996]],
    [ "Enedis",[2.4682475956733714, 48.84379021633151]],
    [ "Enedis",[2.461907657788103, 48.85271967992454]],
    [ "Enedis",[2.4620597899390444, 48.84455987486771]],
    [ "Coucou",[2.463880513714307, 48.84556243252827]],
    [ "Enedis",[2.459398750000002, 48.8522367]],
    [ "Enedis",[2.4719195499999995, 48.84447335]],
    [ "Enedis",[2.47432295, 48.85923675]],
    [ "Enedis",[2.459289243349324, 48.84862115966979]],
    [ "Enedis",[2.457076400088527, 48.85377284242573]],
    [ "Enedis",[2.4661782118125317, 48.850337538285125]],
    [ "Enedis",[2.475759795080238, 48.846367145852376]],
    [ "Enedis",[2.48627, 48.84627435000001]],
    [ "Enedis",[2.487586919447859, 48.848056971397945]],
    [ "Enedis",[2.4658613999701497, 48.84422948319592]],
    [ "DP Mauconseil",[2.4690816778286924, 48.84750548108748]],
    [ "Enedis",[2.469698260580559, 48.85185278746622]],
    [ "Enedis",[2.4597870387534413, 48.845903371868005]],
    [ "Enedis",[2.464537350138644, 48.84215958806823]],
    [ "Enedis",[2.47895779310789, 48.84650586547192]],
    [ "Enedis",[2.4716699884946327, 48.84251291765136]],
    [ "Enedis",[2.477547861335024, 48.84698565434237]],
    [ "Enedis",[2.4752804527034904, 48.84429275249359]],
    [ "Enedis",[0.11034485955372605, 48.80395491218949]],
    [ "Enedis",[0.10597765097256256, 48.804375616757476]],
    [ "Rivière",[3.71938575, 43.555012749999996]],
    [ "Enedis",[3.7351130786048463, 43.557526858055056]],
    [ "Enedis",[0.15923300166450804, 48.73931109151043]],
    [ "Enedis",[2.803881083839612, 50.421984014580794]],
    [ "Enedis",[4.843341167855445, 45.05757055682358]],
    [ "Enedis",[4.850539, 45.056722500000014]],
    [ "Enedis",[4.836386350033401, 45.060866823647295]],
    [ "Enedis",[4.817019169921875, 45.07189440136718]],
    [ "Enedis",[4.836474545842439, 45.06269803413467]],
    [ "Poste Électrique d'Éguzon",[1.620889662804575, 46.458187012986244]],
    [ "Poste électrique d'Éguzon",[1.6180451020892939, 46.4564528985081]],
    [ "105789601",[6.567022250000002, 46.06850215000001]],
    [ "Enedis",[6.575141063537475, 46.07925616727761]],
    [ "Enedis",[6.55370990606475, 46.07463506794345]],
    [ "Enedis",[6.576995402061857, 46.064510068728524]],
    [ "Enedis",[6.569481200841221, 46.05649197546443]],
    [ "Enedis",[6.560746672788822, 46.073015797035005]],
    [ "Enedis",[3.1800092972150935, 50.37110753702119]],
    [ "Enedis",[3.1977945000000005, 50.364504000000004]],
    [ "Enedis",[6.564175999999999, 46.070556999999994]],
    [ "Enedis",[3.1788278018648013, 50.370771107226105]],
    [ "Enedis",[6.560049234719428, 46.071397447432105]],
    [ "Enedis",[3.1792097418742586, 50.37625944428628]],
    [ "Enedis",[6.5933125000000015, 46.0496955]],
    [ "Enedis",[3.1815636525134217, 50.375393142508536]],
    [ "Enedis",[3.1822525367965366, 50.3718896017316]],
    [ "Enedis",[3.1787356854224686, 50.37289158532962]],
    [ "Enedis",[6.558253741921207, 46.073969781909405]],
    [ "Mairie",[6.5812124008438815, 46.05989973417722]],
    [ "Enedis",[6.566348441147378, 46.05599945202769]],
    [ "Enedis",[6.5515145, 46.070950499999995]],
    [ "Enedis",[6.54619232785888, 46.07161978163016]],
    [ "Poste électrique du Marquis",[-0.5512377637498878, 45.01043482790023]],
    [ "Enedis",[2.9348766029850744, 50.56744498781095]],
    [ "Enedis",[2.9301349, 50.5702842]],
    [ "Enedis",[2.940662041684079, 50.574999897817506]],
    [ "Enedis",[2.9256319499999996, 50.55479629999999]],
    [ "Enedis",[2.9419588340950003, 50.56734110270046]],
    [ "Enedis",[2.9342921113006395, 50.576397253518124]],
    [ "Enedis",[2.9389888708277407, 50.573245056420575]],
    [ "Enedis",[2.9278202045390977, 50.565966165322145]],
    [ "Enedis",[2.9385158258706467, 50.574853349751244]],
    [ "Enedis",[2.919122821739131, 50.564722685507256]],
    [ "Enedis",[2.8529204066509553, 50.62009562372349]],
    [ "Enedis",[2.8511621241252305, 50.60909638342542]],
    [ "Enedis",[3.2256023795096023, 50.379457298336774]],
    [ "Enedis",[3.2306971909722217, 50.37236288975694]],
    [ "Enedis",[3.1921515181384468, 50.37146990512197]],
    [ "Enedis",[3.221047000000001, 50.3800915]],
    [ "Enedis",[3.2235452192615015, 50.37430549474794]],
    [ "Enedis",[3.199836, 50.37123199999999]],
    [ "Enedis",[3.213436270631628, 50.377155959428315]],
    [ "Enedis",[3.1938866666666663, 50.370182467532466]],
    [ "Enedis",[3.189486499999999, 50.370714500000005]],
    [ "Enedis",[3.222548304219571, 50.37185483057586]],
    [ "Enedis",[3.2160344211397254, 50.37796369769366]],
    [ "Enedis",[3.234851830447668, 50.38798627016066]],
    [ "Enedis",[3.1997961940298514, 50.36921097678276]],
    [ "Enedis",[3.2238300064257026, 50.379156833734946]],
    [ "Enedis",[3.22346423988154, 50.376822964462]],
    [ "Enedis",[3.2049250694582927, 50.37641721967431]],
    [ "Poste électrique de Bréval",[2.015030652901905, 49.02194738933237]],
    [ "Enedis",[3.2314242580073427, 50.3958164180276]],
    [ "Enedis",[3.226910827686702, 50.39325550710383]],
    [ "Enedis",[2.127871383812436, 48.85598504470701]],
    [ "Poste Électrique de Donzac",[0.8228435501636293, 44.10262785843304]],
    [ "Poste électrique de Brennilis",[-3.867477810366251, 48.35461226564455]],
    [ "Enedis",[6.08263185, 43.2328247]],
    [ "Enedis",[6.069067691287879, 43.239775116477276]],
    [ "Enedis",[6.077922461223236, 43.2261558693921]],
    [ "Enedis",[6.073964334356154, 43.232426307999916]],
    [ "Enedis",[6.0816033, 43.238628]],
    [ "Enedis",[6.069366534280804, 43.229410504611025]],
    [ "Enedis",[6.08139865, 43.23125455]],
    [ "Enedis",[6.0754542, 43.22294545]],
    [ "Enedis",[6.079616271779898, 43.22904401434133]],
    [ "Enedis",[6.076739576860435, 43.22382835996057]],
    [ "Enedis",[2.070829689451477, 49.45867352354431]],
    [ "Enedis",[2.0818600138888885, 49.47092769444445]],
    [ "Enedis",[2.1216651962846136, 49.43546419324687]],
    [ "Enedis",[-0.2902047921308569, 49.14985742440318]],
    [ "Enedis",[-0.29450916666666593, 49.149364]],
    [ "Enedis",[-0.29231179181151024, 49.14175893047508]],
    [ "Enedis",[-0.2905636804423825, 49.14611826973339]],
    [ "Poste Électrique Prodair",[2.1189654718014896, 49.416454944440666]],
    [ "Poste électrique de Wage",[2.1180648957945203, 49.4193778359334]],
    [ "Enedis",[4.839167612256436, 44.77319215325847]],
    [ "Enedis",[6.518590967346939, 46.07163039115646]],
    [ "Enedis",[6.52801958631374, 46.05449872886343]],
    [ "Enedis",[6.534024087635136, 46.05475664909115]],
    [ "Enedis",[3.7964383295915183, 47.81203520049891]],
    [ "Enedis",[3.771789013832199, 47.81637702040817]],
    [ "Enedis",[6.515776380126852, 46.06152308217055]],
    [ "Enedis",[6.543069766846746, 46.07929980172496]],
    [ "Les Lacs",[6.544317317627568, 46.077787050563295]],
    [ "Enedis",[6.544050978942093, 46.08142169407539]],
    [ "Enedis",[6.528789500000002, 46.083003000000005]],
    [ "Enedis",[6.524913262796165, 46.08381460083198]],
    [ "Enedis",[6.5333595, 46.081999999999994]],
    [ "Enedis",[6.535622474498858, 46.07727635084158]],
    [ "Enedis",[6.526537374284455, 46.082774171290176]],
    [ "Enedis",[6.534237568345324, 46.077718493205445]],
    [ "Champ de Gond",[6.551187673389886, 46.07723209373727]],
    [ "Enedis",[6.546998371782177, 46.08197205924092]],
    [ "Enedis",[7.095417499999999, 49.08994299999999]],
    [ "Enedis",[7.081593515151515, 49.090405272727274]],
    [ "Enedis",[7.103149278131635, 49.0854346581741]],
    [ "Enedis",[7.087967351324337, 49.092823350324835]],
    [ "Enedis",[7.081329217375501, 49.090598229371146]],
    [ "Enedis",[2.808027709430756, 50.439625831681305]],
    [ "Enedis",[2.812222078100732, 50.427762425893704]],
    [ "Enedis",[2.795119735204598, 50.442129463194675]],
    [ "Enedis",[2.7991349999999993, 50.43111799999999]],
    [ "Enedis",[2.804856649550143, 50.44413407131886]],
    [ "Enedis",[2.810433464307883, 50.43437206828057]],
    [ "Enedis",[2.8039912010168164, 50.43315053878242]],
    [ "Enedis",[2.813168, 50.429978]],
    [ "Enedis",[2.8289584897349758, 50.454458461739456]],
    [ "Enedis",[2.8205600115303975, 50.44452731691125]],
    [ "Enedis",[2.841316602165464, 50.43758234564131]],
    [ "Enedis",[2.809424177593197, 50.44554452892316]],
    [ "Enedis",[2.8086382816901407, 50.43284482159624]],
    [ "Enedis",[2.799643677838067, 50.44071216523034]],
    [ "Enedis",[7.11525442013129, 49.09374362873815]],
    [ "Enedis",[7.1178952293472, 49.088842554592716]],
    [ "Enedis",[2.830470305762543, 50.42657932960755]],
    [ "Enedis",[2.810069204828843, 50.42966001680231]],
    [ "Enedis",[7.107770095944609, 49.09102027002968]],
    [ "Enedis",[7.110747361702128, 49.0858046855792]],
    [ "Enedis",[2.8111408808660627, 50.43098518841894]],
    [ "Enedis",[2.8083746106416987, 50.44150995584988]],
    [ "Enedis",[2.83721652354049, 50.42413266854991]],
    [ "Enedis",[2.822590810584477, 50.445827269101265]],
    [ "Enedis",[2.8079717181339765, 50.43548526759432]],
    [ "Enedis",[2.7934879133858272, 50.43813416797901]],
    [ "Enedis",[2.8389326959459456, 50.43356633558559]],
    [ "Enedis",[2.8090103172567504, 50.43748214528314]],
    [ "Enedis",[2.848148667584308, 50.43884150194999]],
    [ "Enedis",[2.8432447002230625, 50.43047896372806]],
    [ "Enedis",[2.8292040287526428, 50.44299544975335]],
    [ "Enedis",[2.841867531876138, 50.44206560655738]],
    [ "Enedis",[2.8214999081652263, 50.45418457118156]],
    [ "Enedis",[2.8405499205205667, 50.430178396235185]],
    [ "Enedis",[2.827261470419125, 50.442157270691496]],
    [ "Enedis",[2.817832348605267, 50.42859840564125]],
    [ "Enedis",[2.7961615530449757, 50.43510216483753]],
    [ "Enedis",[2.837215, 50.44267199999999]],
    [ "Enedis",[2.837007692840857, 50.435235167624256]],
    [ "Enedis",[2.7941166841485967, 50.43177605463103]],
    [ "Enedis",[2.8379861158175914, 50.42842235050563]],
    [ "Enedis",[2.835826462824158, 50.43224202162487]],
    [ "Enedis",[2.8027712925170074, 50.438189666666666]],
    [ "Enedis",[2.818079833083458, 50.44940849775113]],
    [ "Enedis",[2.8154357913669052, 50.44847196882494]],
    [ "Enedis",[2.82422432353475, 50.45388582162946]],
    [ "Enedis",[2.7955208653846157, 50.43042959615386]],
    [ "Enedis",[2.835996683316683, 50.44074702830503]],
    [ "Enedis",[2.8384174499999992, 50.44442515]],
    [ "Enedis",[2.819376754385965, 50.455144385964914]],
    [ "Enedis",[2.8179385031535165, 50.45455878656737]],
    [ "Enedis",[2.8012421684981685, 50.42830424908425]],
    [ "Enedis",[2.845976333333333, 50.438535666666674]],
    [ "Enedis",[2.839825674661337, 50.42408268620919]],
    [ "Enedis",[2.8417370468018723, 50.4315585273011]],
    [ "Enedis",[2.8123595158403876, 50.44495134139461]],
    [ "Enedis",[2.831435211733334, 50.45641133653334]],
    [ "Enedis",[2.834745620548745, 50.42910762171627]],
    [ "Enedis",[2.798923873333333, 50.44349131743589]],
    [ "Enedis",[3.20205967530985, 50.41602606474233]],
    [ "Enedis",[3.1704983269406393, 50.42671502374429]],
    [ "Enedis",[3.1942032035087733, 50.421045442105275]],
    [ "Enedis",[3.188549493849619, 50.42725364085239]],
    [ "Enedis",[3.1838235424965475, 50.41889380634467]],
    [ "Enedis",[3.208089173126614, 50.416155838316726]],
    [ "Enedis",[3.1824552420228294, 50.42597826866371]],
    [ "Enedis",[3.1743759551593733, 50.424698901674766]],
    [ "Enedis",[3.1645775, 50.429164]],
    [ "Enedis",[3.178962569361277, 50.4286399730539]],
    [ "Enedis",[3.1702794106373227, 50.41823585923888]],
    [ "Enedis",[3.1855955000000002, 50.427718000000006]],
    [ "Enedis",[3.1889077749999992, 50.41519317083334]],
    [ "Enedis",[3.1997845191286842, 50.418904599304405]],
    [ "Enedis",[2.0929905, 49.415114]],
    [ "Enedis",[2.097399715379264, 49.43258818789145]],
    [ "Enedis",[2.08067392321117, 49.41747383769633]],
    [ "Enedis",[2.0887339632307373, 49.431319605178]],
    [ "Enedis",[2.1000806376811596, 49.43028998550724]],
    [ "Enedis",[2.1001275098039223, 49.42037674509804]],
    [ "Enedis",[2.1110975, 49.4393365]],
    [ "Enedis",[2.1070529029749827, 49.439044132352926]],
    [ "Enedis",[2.107201, 49.43982699999999]],
    [ "Enedis",[2.0659442838628763, 49.4415178995262]],
    [ "Sous-station de Moisenay",[2.7517003608635138, 48.56948444039408]],
    [ "Enedis",[-0.3078400422405876, 49.124491825068866]],
    [ "Enedis",[-0.29796900000000004, 49.11785450000001]],
    [ "Enedis",[-0.29230082561036486, 49.122055491778774]],
    [ "Enedis",[-0.2956576670291028, 49.1192067830018]],
    [ "Enedis",[-0.30908499999999994, 49.122936]],
    [ "Enedis",[-0.30651980218579233, 49.12646843562842]],
    [ "Enedis",[-0.30545422703412023, 49.12890309448819]],
    [ "Enedis",[-0.3078891286159325, 49.12443770226969]],
    [ "Enedis",[-0.2704605, 49.116803999999995]],
    [ "Enedis",[-0.2956296701132447, 49.11806856720827]],
    [ "Enedis",[1.36321525, 43.563048599999995]],
    [ "Enedis",[6.35857155, 45.76511]],
    [ "Buissière",[5.657722364340898, 45.31673242656304]],
    [ "Enedis",[5.658147500000001, 45.329436]],
    [ "Enedis",[-1.35883116889623, 48.33751704823266]],
    [ "Poste source de Breau",[3.5493350845336376, 47.80900703548582]],
    [ "Enedis",[1.8932742945252947, 50.95958491268191]],
    [ "Enedis",[1.883082574074074, 50.956021951851845]],
    [ "Enedis",[1.8983543840063348, 50.96718931749802]],
    [ "Enedis",[1.890574461714668, 50.96664002596965]],
    [ "Enedis",[1.8974196486096064, 50.95857067100035]],
    [ "Enedis",[1.8865040586080593, 50.9564051904762]],
    [ "Enedis",[1.8763240000000005, 50.960555]],
    [ "Enedis",[1.8884209238897158, 50.964111285124645]],
    [ "Enedis",[1.6656308404080287, 50.8861261704508]],
    [ "Enedis",[1.654768060606061, 50.8823418815427]],
    [ "Enedis",[1.6609556651638115, 50.88606167057408]],
    [ "Enedis",[1.6634977075664625, 50.88394506134969]],
    [ "Enedis",[1.6584519217470428, 50.881745377312704]],
    [ "Enedis",[1.6610373670250893, 50.88678886881719]],
    [ "Enedis",[1.671649915126626, 50.88849260848734]],
    [ "Enedis",[1.6664330228514659, 50.8819363323398]],
    [ "Enedis",[1.6683348785460992, 50.88773356648936]],
    [ "Enedis",[-1.831654900000001, 46.698464900000005]],
    [ "Enedis",[-1.8423866500000003, 46.69302654999999]],
    [ "Enedis",[-1.83637475, 46.70238425000001]],
    [ "Enedis",[1.7458965000000002, 50.875643499999995]],
    [ "Enedis",[1.7413571930894312, 50.86839992682926]],
    [ "Enedis",[2.320309, 48.538219500000004]],
    [ "Enedis",[2.33071384826725, 48.543864731189515]],
    [ "Enedis",[2.3364750673684216, 48.54464382385965]],
    [ "Enedis",[1.608284500960283, 50.84907335576554]],
    [ "Enedis",[1.6115253441358022, 50.85230901543209]],
    [ "Enedis",[1.586861938464814, 50.85015585169807]],
    [ "Enedis",[1.5882368616746587, 50.86878467060125]],
    [ "Enedis",[1.6083269789656025, 50.849090345459054]],
    [ "Enedis",[4.850034352654899, 45.78913123761686]],
    [ "Enedis",[4.860374858772944, 45.79340669660044]],
    [ "Enedis",[4.836523741674646, 45.783037314983]],
    [ "Enedis",[4.849251513548331, 45.79635219185792]],
    [ "Enedis",[4.827271449999999, 45.7865204]],
    [ "Enedis",[4.839307131443936, 45.78560660429845]],
    [ "Enedis",[4.83230622689532, 45.791492560096486]],
    [ "Enedis",[4.844828445133654, 45.79266149860331]],
    [ "Enedis",[4.8353386124816655, 45.788882878863056]],
    [ "Enedis",[4.835986408761033, 45.78918778108591]],
    [ "Enedis",[4.843809741152986, 45.792129143845]],
    [ "Enedis",[4.855995381918835, 45.79755330437568]],
    [ "Enedis",[4.8387689640509075, 45.78453760165026]],
    [ "Enedis",[4.832886938931558, 45.788365129456]],
    [ "Enedis",[4.845957896592409, 45.79536262670789]],
    [ "Enedis",[4.8296101852933635, 45.78547542662055]],
    [ "Enedis",[4.850792477074384, 45.79711365701717]],
    [ "Enedis",[4.851595961111697, 45.8025144380636]],
    [ "Enedis",[6.003135496480331, 48.69042431532091]],
    [ "Enedis",[5.976554595505617, 48.6911768576779]],
    [ "Petit Coucou",[5.962210415713867, 48.68975458498973]],
    [ "Enedis",[5.966784083615819, 48.696564687005655]],
    [ "Enedis",[5.972612944693571, 48.696607665171896]],
    [ "Enedis",[5.959739166931128, 48.69034180033405]],
    [ "Enedis",[5.969437641968048, 48.69285031177105]],
    [ "Enedis",[5.968545999999999, 48.6959655]],
    [ "Enedis",[5.963079674931131, 48.695395479338835]],
    [ "Enedis",[6.005337107896324, 48.69082219409283]],
    [ "Montchamps 3",[5.973567775280899, 48.69356788389513]],
    [ "Lavoir",[5.959561825134256, 48.69399864710272]],
    [ "Enedis",[1.4270634396681283, 47.417439286876984]],
    [ "Enedis",[1.4320153691013353, 47.40718863076128]],
    [ "Enedis",[1.4226307881809372, 47.42010687279827]],
    [ "Enedis",[1.4498397604674338, 47.42858482734653]],
    [ "Enedis",[1.4284460813477737, 47.425264388688326]],
    [ "Enedis",[1.4358221597497514, 47.413543632439584]],
    [ "Enedis",[1.4342183193507096, 47.41860020095368]],
    [ "Enedis",[1.4412155346242457, 47.41500864561769]],
    [ "Enedis",[1.4384780902505647, 47.420282067278556]],
    [ "Enedis",[1.437392660305479, 47.40822866891071]],
    [ "Enedis",[1.4438748443031573, 47.42577290289004]],
    [ "Enedis",[1.4255977285398882, 47.422240873609496]],
    [ "Enedis",[1.4683176364258324, 47.421684232986955]],
    [ "Enedis",[1.4437794, 47.42726445]],
    [ "Enedis",[1.4173698690684573, 47.416260911365555]],
    [ "RTE",[5.787728971327848, 45.20619276508632]],
    [ "Enedis",[1.7951745000000001, 50.93430200000001]],
    [ "Enedis",[1.8071385779949327, 50.94252381614188]],
    [ "Enedis",[1.8005669999999998, 50.9427415]],
    [ "Enedis",[1.8026743618126457, 50.941690829124134]],
    [ "Enedis",[1.8058619999999999, 50.92796499999999]],
    [ "Enedis",[1.8013185000000003, 50.938267999999994]],
    [ "Enedis",[1.7983214079647363, 50.93478651010792]],
    [ "Enedis",[1.817223500747385, 50.93513179970104]],
    [ "Enedis",[1.7993130000000004, 50.941123]],
    [ "Enedis",[1.8533735, 50.9267155]],
    [ "Enedis",[1.797998214635185, 50.931110957881295]],
    [ "Enedis",[1.85096, 50.923489000000004]],
    [ "Enedis",[1.8286845539748955, 50.92070616541144]],
    [ "Enedis",[1.8281256922498121, 50.91538353197894]],
    [ "Enedis",[1.824341039747064, 50.917201320686544]],
    [ "Enedis",[1.810518367868853, 50.90878376896174]],
    [ "Enedis",[2.300938, 48.53126616666667]],
    [ "Enedis",[1.7613728251473477, 47.36720944597249]],
    [ "Enedis",[1.7286410598613737, 47.36814082230623]],
    [ "Enedis",[1.7422908004778974, 47.342683070091596]],
    [ "Enedis",[1.737515, 47.379149999999996]],
    [ "Enedis",[1.7713651251342124, 47.34326528618839]],
    [ "Enedis",[1.766628619444444, 47.35763469722222]],
    [ "Enedis",[1.7522557115384614, 47.34737203418804]],
    [ "Enedis",[1.7596817795734625, 47.33889455147222]],
    [ "Enedis",[1.749097830928598, 47.34854282537921]],
    [ "Enedis",[1.7316095656523554, 47.37103823607058]],
    [ "Enedis",[1.7140753213800612, 47.36746700923662]],
    [ "Enedis",[1.7580468258274078, 47.3400217967694]],
    [ "Enedis",[1.7471359999999987, 47.37672750000001]],
    [ "Enedis",[1.7335360000000002, 47.36825899999999]],
    [ "Enedis",[1.7235359999999997, 47.352464999999995]],
    [ "Enedis",[1.744171615191461, 47.352802189579414]],
    [ "Enedis",[1.74545261882032, 47.34158526809865]],
    [ "Enedis",[1.7293950431432974, 47.38270432306112]],
    [ "Enedis",[1.7245077025809088, 47.364314163730704]],
    [ "Enedis",[1.7484552670454545, 47.3640478465909]],
    [ "Enedis",[1.7586584005499544, 47.35799727558814]],
    [ "Enedis",[1.7438289722051648, 47.355623662723495]],
    [ "Enedis",[1.7475723052568857, 47.34705593308631]],
    [ "Enedis",[1.7432035000000001, 47.367850250000004]],
    [ "Enedis",[1.7560818443968595, 47.355892869379026]],
    [ "Enedis",[1.7476660000000002, 47.369153499999996]],
    [ "Enedis",[1.7286502617018098, 47.35297817915359]],
    [ "Enedis",[1.7497315327536231, 47.350328284057966]],
    [ "Enedis",[1.7485113153594778, 47.351284120286586]],
    [ "Enedis",[1.7635537120660352, 47.34817144428259]],
    [ "Enedis",[1.7400132013846465, 47.34170046976176]],
    [ "Enedis",[1.7679792132183911, 47.350052962068965]],
    [ "Enedis",[1.745253423535943, 47.365586275326116]],
    [ "Enedis",[1.733824242608344, 47.371476902794655]],
    [ "Enedis",[1.767481859649122, 47.34473881871344]],
    [ "Enedis",[1.7447705000000002, 47.35403600000001]],
    [ "Enedis",[1.7523050739893207, 47.344327250953484]],
    [ "Enedis",[1.7511589428049896, 47.36442871656018]],
    [ "Enedis",[1.7378389552852318, 47.37174785528214]],
    [ "Enedis",[1.7118664322501531, 47.36975966135295]],
    [ "Enedis",[1.7214731499999998, 47.36625085000001]],
    [ "Enedis",[1.7317039808104768, 47.35615914245401]],
    [ "Enedis",[1.7423479242032727, 47.359500233850135]],
    [ "Enedis",[1.7689605471167373, 47.367540724050635]],
    [ "Enedis",[1.7399795000000002, 47.3766495]],
    [ "Enedis",[1.7561065000000002, 47.345164000000004]],
    [ "Enedis",[1.7444988266288062, 47.36978455229532]],
    [ "Enedis",[1.743411821932682, 47.35079798588491]],
    [ "Enedis",[1.7658383748344364, 47.346416047240616]],
    [ "Enedis",[1.7276600000000002, 47.360263]],
    [ "Enedis",[1.7530203395889186, 47.349451480786406]],
    [ "Enedis",[1.7413197734180936, 47.36521262369179]],
    [ "Enedis",[1.7625263156966493, 47.34594433980012]],
    [ "Enedis",[1.732910329896907, 47.38009898281786]],
    [ "Enedis",[1.753427214361892, 47.358206782912674]],
    [ "Enedis",[1.7401074244593775, 47.36769349035173]],
    [ "Enedis",[1.769949, 47.35265199999999]],
    [ "Enedis",[1.7608703729468589, 47.35143555109624]],
    [ "Enedis",[1.733184001557281, 47.38281250005531]],
    [ "Enedis",[1.7428102513661201, 47.365042431693986]],
    [ "Enedis",[1.7401430400000002, 47.33681018666666]],
    [ "Enedis",[1.754700119658119, 47.35223568376069]],
    [ "Enedis",[1.759234, 47.3546995]],
    [ "Enedis",[1.7359820000000001, 47.374018500000005]],
    [ "Enedis",[1.751235675385588, 47.359734853056835]],
    [ "Enedis",[1.7560212643330453, 47.34911388043791]],
    [ "Enedis",[1.7487121702369224, 47.37482670513225]],
    [ "Enedis",[1.7368566612534584, 47.34364075496211]],
    [ "Enedis",[1.7458640740680922, 47.36121328674472]],
    [ "Enedis",[2.47741401671334, 48.770949103853916]],
    [ "Enedis",[2.4821630000000003, 48.773588249999996]],
    [ "Enedis",[2.4763822500000012, 48.764639249999995]],
    [ "Enedis",[2.5050394954954953, 48.78082565765765]],
    [ "Enedis",[2.4779287500000002, 48.7633325]],
    [ "Enedis",[3.1062724999999993, 50.4404035]],
    [ "Enedis",[3.1074813124891434, 50.43001903196109]],
    [ "Enedis",[3.1207775000000013, 50.43032750000001]],
    [ "Enedis",[3.118819310471525, 50.439871378444586]],
    [ "Enedis",[-0.6719699999999997, 47.52145049999999]],
    [ "Enedis",[4.979459077092511, 47.22901507929515]],
    [ "Enedis",[4.976167300020821, 47.22777904372267]],
    [ "Enedis",[4.9710865291237285, 47.225619422129654]],
    [ "Enedis",[7.315444922077921, 48.99398918614718]],
    [ "Enedis",[7.304969328885064, 48.99396176553308]],
    [ "Enedis",[7.301886247756622, 48.98982385379733]],
    [ "Enedis",[-0.6619402323471402, 47.556004118737675]],
    [ "Enedis",[3.074793500000001, 50.428116]],
    [ "Enedis",[3.10061934439834, 50.439422308437074]],
    [ "Enedis",[3.09012851910349, 50.4334105561071]],
    [ "Enedis",[3.0893953263157887, 50.44398176701754]],
    [ "Enedis",[3.09872978125, 50.44186412061402]],
    [ "Enedis",[3.08956740702537, 50.45648080773161]],
    [ "Enedis",[7.1816435855704714, 49.04639534340045]],
    [ "Enedis",[7.184282512, 49.042576688000004]],
    [ "Enedis",[7.176733019470406, 49.0313821678349]],
    [ "Enedis",[7.128233999999999, 49.023307]],
    [ "Enedis",[7.15312124544306, 49.022095639776225]],
    [ "Poste de l'église",[1.6601023333333338, 43.657348666666664]],
    [ "Enedis",[7.338007336216622, 49.053763821110685]],
    [ "Enedis",[7.307736393939395, 49.05681724242424]],
    [ "Enedis",[7.30149, 49.048624499999995]],
    [ "Enedis",[7.2730396975206615, 49.07433653278237]],
    [ "Poste électrique du Confluent",[5.702935825337907, 45.19698103203753]],
    [ "Enedis",[3.077819046511629, 50.64487845877379]],
    [ "Enedis",[3.079749374167776, 50.646271422547706]],
    [ "Enedis",[1.7853976782178207, 50.91638181188119]],
    [ "Enedis",[1.7717337537878777, 50.91649480871212]],
    [ "Hibou",[1.5242266005509637, 48.94513372623219]],
    [ "Enedis",[-0.10947486562834054, 47.54695585516873]],
    [ "Enedis",[-0.10653171987767586, 47.538436419134996]],
    [ "Enedis",[-0.11139264857341621, 47.53974217341684]],
    [ "Enedis",[-0.10190667399816715, 47.534393476630854]],
    [ "Enedis",[-0.09387999999999999, 47.5332325]],
    [ "Enedis",[-0.10217536952998411, 47.54636177849811]],
    [ "Enedis",[-0.09869773363949459, 47.541142471106006]],
    [ "Enedis",[-0.09297346132619148, 47.53355855799042]],
    [ "Enedis",[-0.10425941452295633, 47.5357288102681]],
    [ "Enedis",[-0.10124884251036155, 47.54908182297217]],
    [ "Enedis",[-0.12006060220125758, 47.553943171383644]],
    [ "Enedis",[-0.10064375576770189, 47.53222504826305]],
    [ "Enedis",[3.8547516774685917, 50.28821143923916]],
    [ "Enedis",[3.8580519187567632, 50.28566967040425]],
    [ "Enedis",[3.854652999999999, 50.29035500000001]],
    [ "Enedis",[2.7979964999999996, 50.559959500000005]],
    [ "Enedis",[2.791199862745098, 50.56884566666667]],
    [ "Enedis",[2.7852235, 50.571334]],
    [ "Enedis",[4.845298545454545, 45.05999857575757]],
    [ "Enedis",[4.84234986472072, 45.0639081512072]],
    [ "Enedis",[4.832047132581508, 45.066198475168086]],
    [ "Enedis",[4.82626780370717, 45.070653550852576]],
    [ "Enedis",[4.8120387246085015, 45.06787252371365]],
    [ "Enedis",[4.839844247706421, 45.05217207033639]],
    [ "Enedis",[4.834481708872903, 45.05817235731415]],
    [ "Enedis",[4.838096951735979, 45.055888125651784]],
    [ "Enedis",[4.8462439999999996, 45.0632095]],
    [ "Enedis",[4.815679028340081, 45.05755751799371]],
    [ "Enedis",[4.792671739463602, 45.046460923371654]],
    [ "Enedis",[4.8531225, 45.061394500000006]],
    [ "Enedis",[4.836469549672214, 45.05217648966213]],
    [ "Enedis",[4.836072, 45.049969999999995]],
    [ "Enedis",[4.822235354166666, 45.07261018749999]],
    [ "Enedis",[4.83335, 45.058833500000006]],
    [ "Enedis",[4.8431955, 45.06072300000001]],
    [ "Enedis",[4.80436692019722, 45.06374591167459]],
    [ "Enedis",[4.840037410330445, 45.04865109207571]],
    [ "Enedis",[4.847219333333333, 45.04710357142858]],
    [ "Enedis",[4.832983441193023, 45.05403277809042]],
    [ "Enedis",[4.808319, 45.06639450000001]],
    [ "Enedis",[4.841223320659377, 45.05646054464877]],
    [ "Enedis",[4.838626282051282, 45.0576464497631]],
    [ "Enedis",[6.563663898516783, 49.227811788446516]],
    [ "Enedis",[6.5125141398728426, 46.085751698455944]],
    [ "Enedis",[6.501763646033929, 46.086757563961484]],
    [ "Enedis",[6.512275231561599, 46.07777640626287]],
    [ "Enedis",[6.509648094677872, 46.07576655686275]],
    [ "Enedis",[6.504375895238095, 46.07825264761904]],
    [ "Enedis",[6.510730000000001, 46.0844325]],
    [ "Enedis",[6.494663861111112, 46.0845805]],
    [ "Enedis",[6.50751375, 46.09120815]],
    [ "Enedis",[6.50745227388535, 46.083290185774935]],
    [ "Enedis",[6.498455616516129, 46.07612372163441]],
    [ "Enedis",[6.507440740412979, 46.076920115044246]],
    [ "Enedis",[6.499740453769558, 46.080442293029876]],
    [ "Enedis",[6.486085822705804, 46.08018097469636]],
    [ "Enedis",[6.491725, 46.084299]],
    [ "Enedis",[6.511001571428572, 46.08888384761906]],
    [ "Enedis",[6.485615832967567, 46.0787995113387]],
    [ "Enedis",[6.504591780097425, 46.07617719276269]],
    [ "Enedis",[6.499955948998179, 46.08364456587736]],
    [ "Enedis",[6.4840016194240535, 46.07680743007717]],
    [ "Enedis",[6.491806497862002, 46.06766435034014]],
    [ "Enedis",[6.49798589879518, 46.06658125823294]],
    [ "Enedis",[2.2778882806844356, 48.55080484402764]],
    [ "Enedis",[2.2652144841795434, 48.550740872373474]],
    [ "Enedis",[0.1331056826090209, 49.51236046314999]],
    [ "Enedis",[2.2973303062460166, 48.56205703824092]],
    [ "Enedis",[2.287880674210278, 48.561381028288544]],
    [ "Enedis",[2.309746185714286, 48.56504796190476]],
    [ "Enedis",[2.2923442147684616, 48.55928670988737]],
    [ "Enedis",[2.304192000000001, 48.5601595]],
    [ "Poste électrique de Rosières-prés-Troyes",[4.054319473519293, 48.261941862680835]],
    [ "Enedis",[-0.05581360131162319, 47.50369073971053]],
    [ "Bois Candides",[6.094516049999999, 46.244213949999995]],
    [ "Les dauphins",[6.108896952546296, 46.255574682870375]],
    [ "Crêt de la neige",[6.1114696442541785, 46.2611682631579]],
    [ "Hameau de Voltaire",[6.099800317282695, 46.254337652763645]],
    [ "Église",[6.108855362689394, 46.257988267803036]],
    [ "Saint Georges",[6.12047589610521, 46.258917163480014]],
    [ "Mairie 5 G",[6.1080389598096705, 46.25500673651116]],
    [ "Jargillières",[6.114421141233764, 46.25439664718614]],
    [ "Enedis",[6.121296449999998, 46.24918005]],
    [ "Enedis",[6.123414058333334, 46.251791254166676]],
    [ "Rue de Meyrin",[6.102496499999999, 46.2509585]],
    [ "Terrasses du Levant",[6.113147280202401, 46.262719630613525]],
    [ "Route de Versoix",[6.112084916599356, 46.25684741256058]],
    [ "OPAC de l'Ain",[6.117247670515175, 46.26094347546575]],
    [ "Florentine",[6.111792311111109, 46.26043446889818]],
    [ "Ferney nord",[6.107346543412266, 46.25993381360049]],
    [ "Cache Malet",[6.10157972663911, 46.25321158935083]],
    [ "Les manoirs Ferney",[6.115392000000001, 46.25612066666667]],
    [ "Enedis",[6.1149565, 46.2633795]],
    [ "Le clos des vergers",[6.1143944999999995, 46.257209]],
    [ "Croix de Mission",[6.043899590389015, 48.79357224612255]],
    [ "St Aman",[6.04351865, 48.790635050000006]],
    [ "Enedis",[4.114534858741724, 43.78908297513234]],
    [ "RTE",[2.4321261009335133, 44.9993800358417]],
    [ "Enedis",[3.8070636985286335, 50.29237692283264]],
    [ "Enedis",[3.805974980392157, 50.29039632843136]],
    [ "Enedis",[3.7957490000000007, 50.294917]],
    [ "Enedis",[3.8033489007407413, 50.29338473580246]],
    [ "Enedis",[3.8101668391699093, 50.29861030739299]],
    [ "Enedis",[3.790612707243763, 50.29757687285843]],
    [ "Enedis",[3.7992456666666663, 50.2952358235294]],
    [ "Enedis",[3.7989711405895688, 50.29758134240363]],
    [ "Enedis",[3.80591257815735, 50.295304600155276]],
    [ "Enedis",[3.797184137997257, 50.29880531723823]],
    [ "Enedis",[3.788320490196079, 50.29485423137255]],
    [ "Enedis",[3.796091599951034, 50.299964663851156]],
    [ "Enedis",[3.7933040836762686, 50.29770069272977]],
    [ "Enedis",[3.7944797528957515, 50.299083101029595]],
    [ "Enedis",[3.7920937227065186, 50.29345904856787]],
    [ "Enedis",[3.790865524162039, 50.30156132613614]],
    [ "Enedis",[3.785259, 50.29156600000001]],
    [ "Enedis",[3.8083866365476475, 50.29379691660594]],
    [ "Enedis",[3.2536481208407912, 45.79953724538649]],
    [ "Enedis",[3.2361281, 45.8052314]],
    [ "Enedis",[3.237777650000001, 45.8014891]],
    [ "Enedis",[-0.8058283834795262, 46.46267771532239]],
    [ "Enedis",[-0.7756667409348048, 46.47044211641164]],
    [ "Enedis",[-0.7921772177454218, 46.46893420285145]],
    [ "Enedis",[-0.7829342499999999, 46.46847875]],
    [ "Enedis",[-0.76867475, 46.45740875]],
    [ "Enedis",[-0.7839725000000001, 46.46062685]],
    [ "Enedis",[-0.7908175000000001, 46.468714]],
    [ "Enedis",[-0.8242961500000001, 46.4629594]],
    [ "Enedis",[-0.8256299317082842, 46.462134641854945]],
    [ "Enedis",[-0.8240023500000001, 46.4678218]],
    [ "Enedis",[-0.8158622499999999, 46.47131974999999]],
    [ "Enedis",[-0.787261807042072, 46.46132430933401]],
    [ "Enedis",[-0.8214167106814191, 46.46984847960305]],
    [ "Enedis",[-0.8128736961002786, 46.4690231553331]],
    [ "Enedis",[-0.801907866697449, 46.47194920024215]],
    [ "Enedis",[-0.8015013303713605, 46.46524019575571]],
    [ "Enedis",[-0.774240882285837, 46.467683695675476]],
    [ "Enedis",[-0.8114754958598667, 46.462639832847685]],
    [ "Enedis",[-0.8034924999999999, 46.47572025000001]],
    [ "Enedis",[-0.7998914999999999, 46.4672335]],
    [ "Enedis",[-0.7790546, 46.45773785]],
    [ "Enedis",[-0.8033334999999999, 46.455196]],
    [ "Enedis",[-0.797989239324599, 46.45128107492163]],
    [ "Enedis",[-0.8165455999999999, 46.4643428]],
    [ "Enedis",[-0.79353325, 46.46225575]],
    [ "Enedis",[-0.8126931499999999, 46.465747449999995]],
    [ "Enedis",[-0.78174565, 46.4721686]],
    [ "Enedis",[-0.7987955000000004, 46.474133499999986]],
    [ "Enedis",[-0.7927629999999998, 46.45841475000001]],
    [ "Enedis",[-0.7887264161780403, 46.459808898738586]],
    [ "Enedis",[-0.8220345576952014, 46.46565648072457]],
    [ "Enedis",[-0.797272070726915, 46.46593361460379]],
    [ "Enedis",[-0.7847271, 46.462021500000006]],
    [ "Enedis",[-0.8087475, 46.449596050000004]],
    [ "Enedis",[-0.7962197333079111, 46.46142984880262]],
    [ "Enedis",[-0.7777863201882458, 46.46905135563838]],
    [ "Enedis",[-0.7839715485232066, 46.457065924050625]],
    [ "Enedis",[-0.8034217295234639, 46.46458274437652]],
    [ "Enedis",[-0.8190409836192779, 46.465658650659535]],
    [ "Enedis",[-0.8055748827469607, 46.45860149722576]],
    [ "Enedis",[-0.8174945899107124, 46.4783375849416]],
    [ "Enedis",[-0.8064567470850741, 46.464043433009905]],
    [ "Enedis",[-0.7851767250284474, 46.453214158594584]],
    [ "Enedis",[-0.797767718238048, 46.46430407480776]],
    [ "Enedis",[-0.80194265, 46.45874130000001]],
    [ "Enedis",[-0.82509, 46.4467895]],
    [ "Enedis",[-0.7885630329072747, 46.46355149792083]],
    [ "Enedis",[-0.7845042019722616, 46.453329817095046]],
    [ "Enedis",[-0.7867860000000003, 46.45854875]],
    [ "Enedis",[-0.806409517611778, 46.46646115058307]],
    [ "Enedis",[-0.7855202500000001, 46.466478249999994]],
    [ "Enedis",[-0.7943595687975485, 46.45436313374923]],
    [ "Enedis",[0.31415542375997274, 47.57324843506765]],
    [ "Enedis",[0.3264785, 47.54420099999999]],
    [ "Enedis",[0.32334733385173664, 47.54830399222394]],
    [ "Enedis",[0.31896149999999923, 47.549457000000004]],
    [ "Enedis",[0.3317523848950212, 47.54534188352252]],
    [ "Enedis",[0.32528190523525485, 47.547294261762765]],
    [ "Enedis",[0.32798934732142837, 47.546681542261915]],
    [ "Enedis",[1.8758170000000003, 48.9072065]],
    [ "RTE",[2.6671134756103347, 48.51781436484616]],
    [ "Enedis",[3.240932461674231, 45.769420315179026]],
    [ "RTE",[2.813056583558811, 48.64390288642604]],
    [ "Enedis",[-1.7514765613232726, 46.78514032214985]],
    [ "Enedis",[3.0606426805051044, 50.443237486594015]],
    [ "Enedis",[3.067708249728556, 50.44590066015201]],
    [ "Enedis",[3.0657564999999996, 50.4446065]],
    [ "Enedis",[3.055413023080337, 50.42576649356413]],
    [ "Enedis",[3.0601445446371227, 50.429605304431604]],
    [ "Enedis",[3.0599800324025304, 50.428140495433794]],
    [ "Enedis",[3.066038168469119, 50.439422059360744]],
    [ "Enedis",[3.0631394999999992, 50.4414085]],
    [ "Enedis",[3.0654245034712577, 50.426940833287055]],
    [ "Enedis",[3.0638739911719943, 50.43326316560121]],
    [ "Enedis",[3.0545090901960785, 50.43923177254902]],
    [ "Enedis",[3.0715185000000003, 50.43766]],
    [ "Plateau Andey",[6.415099400347927, 46.05407730076845]],
    [ "GEBonneville",[6.399366636960665, 46.072956620258466]],
    [ "Bordets 1",[6.420478992004568, 46.0733413253379]],
    [ "Enedis",[6.418193732687477, 46.07340003157517]],
    [ "Enedis",[6.3567818, 46.110112799999996]],
    [ "Aravis",[6.39474375, 46.07150349999999]],
    [ "Enedis",[6.421648155463796, 46.08138952641384]],
    [ "Enedis",[6.441907250000001, 46.081551450000006]],
    [ "Enedis",[6.42984354365942, 46.078232907608694]],
    [ "Poste électrique d’Herblay",[2.1725676160148018, 49.004573702425795]],
    [ "Enedis",[4.813778549459101, 45.40587605728072]],
    [ "Enedis",[4.809076499999999, 45.374200499999986]],
    [ "Enedis",[4.810116781046603, 45.37539654812116]],
    [ "Enedis",[4.80697693946065, 45.37524668501192]],
    [ "Aiguillon",[4.16369652685072, 43.77764798658969]],
    [ "Enedis",[4.823814007018939, 45.380250826115756]],
    [ "Enedis",[4.810825763535449, 45.37098256005864]],
    [ "SRD",[0.17619000000000065, 46.676244]],
    [ "SRD",[0.171023419333769, 46.6669647940344]],
    [ "SRD",[0.17350510106899875, 46.67287271331389]],
    [ "Enedis",[3.033216729888965, 50.45298465420349]],
    [ "Enedis",[3.0208439491670958, 50.461110322342435]],
    [ "Enedis",[3.027273538057016, 50.45938232927393]],
    [ "Enedis",[3.019805090476191, 50.46662509142857]],
    [ "Enedis",[3.0252483282471507, 50.46584251992088]],
    [ "Enedis",[3.0290045229344105, 50.454022313162994]],
    [ "Enedis",[3.0233690581427393, 50.460783494994025]],
    [ "Enedis",[3.0309398193548382, 50.462565655197125]],
    [ "Enedis",[3.0254746969696966, 50.45718424242424]],
    [ "Enedis",[3.034727529199711, 50.45022927437315]],
    [ "Enedis",[3.033382340686275, 50.456956897058824]],
    [ "Enedis",[3.0393476666666674, 50.45053115819209]],
    [ "Enedis",[3.0238402688172044, 50.46383131182795]],
    [ "Enedis",[3.030845905204102, 50.457938884697235]],
    [ "Enedis",[3.7816117587828506, 50.31201944370678]],
    [ "Enedis",[3.7827301590106006, 50.338493313309776]],
    [ "Enedis",[3.030432657657658, 50.43721099099099]],
    [ "Enedis",[3.0352902859422364, 50.43511015701292]],
    [ "Enedis",[3.028330400311526, 50.43760914077103]],
    [ "Enedis",[3.0374376039437805, 50.437968603734014]],
    [ "Enedis",[3.0245746405020206, 50.43980769814932]],
    [ "Enedis",[3.0340270126537483, 50.43872791947615]],
    [ "Enedis",[3.039682172401985, 50.43436583564458]],
    [ "Enedis",[3.04283891015625, 50.43883415071614]],
    [ "Enedis",[3.0262023304232804, 50.443349559656085]],
    [ "Enedis",[4.660885255839173, 43.80803898362715]],
    [ "Enedis",[4.6638685400137385, 43.806324325478556]],
    [ "Enedis",[4.660132773468399, 43.80710871312795]],
    [ "Enedis",[4.666368039591918, 43.80925099526229]],
    [ "Enedis",[4.657464855371456, 43.806923287157296]],
    [ "Poste Sectionnement Tarascon",[4.666628246627736, 43.80600773145477]],
    [ "Enedis",[4.80603270877193, 45.36876715789474]],
    [ "RTE",[4.793678124774688, 45.35836311763138]],
    [ "Enedis",[4.799790121995691, 45.368473778054046]],
    [ "Enedis",[4.803685, 45.36313499999999]],
    [ "Enedis",[4.8036875000000006, 45.3602165]],
    [ "Enedis",[4.810712445828391, 45.36362181969157]],
    [ "Enedis",[2.9869480418705825, 50.430735075584565]],
    [ "Enedis",[2.990323586963933, 50.432126297947775]],
    [ "Enedis",[2.987025089399744, 50.43408720817369]],
    [ "Enedis",[2.985618269959615, 50.43346138055296]],
    [ "Enedis",[2.976417095803643, 50.41674842676168]],
    [ "Enedis",[2.9939424752902153, 50.43995576484245]],
    [ "Enedis",[2.983853877529287, 50.42988195335464]],
    [ "Enedis",[2.9817005, 50.44476950000001]],
    [ "Enedis",[2.9829651671603976, 50.435982598552634]],
    [ "Enedis",[2.995223095238095, 50.43001038095239]],
    [ "Enedis",[2.9901319599574427, 50.447111769755296]],
    [ "Enedis",[2.9963534108289145, 50.43330681198251]],
    [ "Enedis",[2.9845077106891105, 50.434724462091786]],
    [ "Enedis",[2.9932360647702714, 50.42884629721815]],
    [ "Enedis",[2.9894025, 50.435939499999996]],
    [ "Enedis",[2.9629263444624074, 50.42726548678818]],
    [ "Enedis",[2.988834393306249, 50.43981119539562]],
    [ "Enedis",[2.984092083287063, 50.43794439330001]],
    [ "RTE",[6.908797505544512, 49.203245775142655]],
    [ "Enedis",[6.471405065426556, 49.17729282659825]],
    [ "Enedis",[-1.6665348510548683, 46.607017383272336]],
    [ "Enedis",[-1.6543490954743254, 46.61649334830287]],
    [ "Enedis",[-1.6590608999999994, 46.61886375]],
    [ "Enedis",[-1.6479288, 46.60322755]],
    [ "Clos des Ancolies",[6.358565092643678, 46.06788611862069]],
    [ "Enedis",[6.377486959327441, 46.0730712305493]],
    [ "Penay",[6.382685968102073, 46.07517271419457]],
    [ "École Toisinges",[6.386526389725652, 46.07164633051034]],
    [ "Peterat",[6.374428666569843, 46.04713485117791]],
    [ "Les Gentianes",[6.3705238500000005, 46.0554641]],
    [ "Toisinges",[6.391009738903408, 46.07497668919452]],
    [ "Église",[6.358730619533385, 46.04761508334575]],
    [ "Enedis",[6.365151479011581, 46.04449375247356]],
    [ "Chaudanne 2",[6.359034034659098, 45.274156652802624]],
    [ "Enedis",[6.3507902000000005, 45.9766076]],
    [ "La Ville",[6.392517558558558, 45.97109643243243]],
    [ "Glieres",[6.388398349999999, 45.99698519999999]],
    [ "Jalandre",[6.398299026845816, 46.00510084710636]],
    [ "Creravy",[6.3982151554912345, 46.00363363724071]],
    [ "Enedis",[6.39579875, 46.02611615000001]],
    [ "Enedis",[6.779661423136309, 45.243613364400446]],
    [ "Pl. Dr Chiron",[5.910863052968035, 45.56433441369863]],
    [ "Enedis",[6.812273907924535, 45.26664934726439]],
    [ "Enedis",[3.005689868620129, 50.429111185770374]],
    [ "Enedis",[3.0006253553794564, 50.42214580757899]],
    [ "Enedis",[2.993724999999999, 50.41766600000001]],
    [ "Enedis",[2.997376323847168, 50.41144184795784]],
    [ "Enedis",[2.978572509869137, 50.40981349628712]],
    [ "Enedis",[2.994199948291588, 50.42160812720129]],
    [ "Enedis",[2.9981267614257634, 50.418903786385556]],
    [ "Enedis",[2.978672, 50.407051]],
    [ "Enedis",[2.9795498642533933, 50.41764434012066]],
    [ "Enedis",[2.9862196935515293, 50.41220942158582]],
    [ "Enedis",[2.9785138694221676, 50.40983470841046]],
    [ "Enedis",[2.987534580103754, 50.41290036222155]],
    [ "Enedis",[2.979616655042139, 50.41742603923279]],
    [ "Enedis",[2.981474376392572, 50.4128295801061]],
    [ "Enedis",[2.988104500000001, 50.41434850000001]],
    [ "Enedis",[2.989735655813953, 50.41352164031009]],
    [ "Enedis",[3.003839333333334, 50.42486952511416]],
    [ "Enedis",[2.996029779991698, 50.42435831257782]],
    [ "Enedis",[2.991356822665749, 50.41441985868319]],
    [ "Enedis",[2.9937974585152842, 50.420648832605536]],
    [ "RTE",[2.442740670723225, 48.612497641500475]],
    [ "RTE",[2.4674475056424607, 48.621730762765246]],
    [ "RTE",[2.4580049759488123, 48.611162006748934]],
    [ "Enedis",[6.5881454999999995, 49.124839]],
    [ "Poste électrique de Caen",[-0.3456417176396651, 49.181246855209295]],
    [ "RTE",[3.512600333832737, 45.738669365495454]],
    [ "Poste électrique de Ranville",[-0.2817669874382699, 49.21639457519334]],
    [ "Poste électrique du Grand Colombier",[2.160982153187115, 51.00030971804506]],
    [ "RTE",[2.1546673999999997, 51.00021795]],
    [ "Enedis",[2.9951100308955803, 50.62828600391082]],
    [ "Enedis",[3.0005068093397744, 50.64480566070853]],
    [ "Enedis",[3.014161232321315, 50.637541030170766]],
    [ "Enedis",[2.9732742177371585, 50.637827686130144]],
    [ "Enedis",[3.0085055, 50.64342649999999]],
    [ "Enedis",[3.0089169999999994, 50.639214499999994]],
    [ "Enedis",[2.980733126751692, 50.64042467674382]],
    [ "Enedis",[2.993302181189488, 50.649637010142925]],
    [ "Enedis",[3.0025246294357184, 50.64051107667248]],
    [ "Enedis",[3.006017063157895, 50.645034682456135]],
    [ "Enedis",[3.0130823790597328, 50.64147128223181]],
    [ "Enedis",[2.971443653043848, 50.63702001873138]],
    [ "Enedis",[2.992735462285711, 50.64183367369115]],
    [ "Enedis",[3.009142698223284, 50.64600959692389]],
    [ "Enedis",[2.976787500000001, 50.6409795]],
    [ "Enedis",[3.0093103104761902, 50.64491715809525]],
    [ "RTE",[1.1630308591995906, 48.225145925756046]],
    [ "BCC",[6.360154353424821, 45.27580837960488]],
    [ "Enedis",[2.369140772357724, 48.603033276422764]],
    [ "Enedis",[2.3629206432029797, 48.60710844674115]],
    [ "Enedis",[2.3881410299490855, 48.62204857262653]],
    [ "Enedis",[2.3746192490842493, 48.62213556043957]],
    [ "Enedis",[2.946179296182894, 50.416699221065535]],
    [ "Enedis",[2.9454164217623084, 50.419748245531515]],
    [ "Enedis",[2.9478734999999987, 50.427111999999994]],
    [ "Enedis",[2.9420199999999994, 50.42958599999999]],
    [ "Enedis",[2.9389236731848642, 50.422799303847135]],
    [ "Enedis",[2.947540124876115, 50.42073995490585]],
    [ "Enedis",[2.949871707399864, 50.42010441412084]],
    [ "Enedis",[2.9456654999999987, 50.429452000000005]],
    [ "Enedis",[2.9492686067869402, 50.42211075515464]],
    [ "Enedis",[2.9732217083985404, 50.448868740219105]],
    [ "Enedis",[2.9517098676698588, 50.41878723246694]],
    [ "Enedis",[2.99286387218522, 50.64078715635903]],
    [ "Enedis",[2.978132714828897, 50.64499516096325]],
    [ "Enedis",[2.988572691593826, 50.644536521959644]],
    [ "Enedis",[2.9770614999999996, 50.639117]],
    [ "Enedis",[3.0097886969037253, 50.64501833212385]],
    [ "Enedis",[2.9934270377104375, 50.639616168350166]],
    [ "Enedis",[2.9717184999999993, 50.6368995]],
    [ "Enedis",[2.997516209523809, 50.64905204761905]],
    [ "Enedis",[3.0075845632747584, 50.63041965004769]],
    [ "Enedis",[2.9746183, 50.6488345]],
    [ "Enedis",[2.9701613420967217, 50.63825197565726]],
    [ "Enedis",[2.9891805000000002, 50.6502825]],
    [ "Enedis",[3.015690704206242, 50.637220313885116]],
    [ "Enedis",[3.0131079999999995, 50.6405065]],
    [ "Enedis",[2.978240291737569, 50.6433528572509]],
    [ "Enedis",[2.979973059367517, 50.64723910940467]],
    [ "Enedis",[3.771637407185629, 50.32606285229541]],
    [ "Enedis",[3.7703405000000005, 50.3227485]],
    [ "Enedis",[3.465778998093586, 46.893403336568454]],
    [ "Enedis",[3.469489442200648, 46.886856788349526]],
    [ "Enedis",[3.4683817558525716, 46.887023035004056]],
    [ "Enedis",[3.4763531243469177, 46.892799775339604]],
    [ "Enedis",[6.8045173, 45.259165100000004]],
    [ "18 Clos Dumoulin",[5.893794918367347, 45.57380530612245]],
    [ "Enedis",[2.4054650461215936, 48.550525668064296]],
    [ "Enedis",[2.4023471128987963, 48.54771135262904]],
    [ "Rue du Sire",[5.891325, 45.57934650000001]],
    [ "Enedis",[2.4061777160725453, 48.54795133958724]],
    [ "Le Grand Arietaz",[5.8945206579710145, 45.582887497101446]],
    [ "Rue de l'Alpette",[5.8906785874633325, 45.58100255411457]],
    [ "Enedis",[-0.13720181297134285, 49.28672106711915]],
    [ "Enedis",[-0.13244968835482593, 49.280381370847984]],
    [ "Enedis",[-0.13549670599135238, 49.277377453572164]],
    [ "Enedis",[-0.13354566835016835, 49.284210006734]],
    [ "Enedis",[-0.12419088559322009, 49.289727772598866]],
    [ "Enedis",[-0.1236965, 49.280803999999996]],
    [ "Enedis",[-0.10709609586224647, 49.29523325700333]],
    [ "Enedis",[-0.13173780312185296, 49.28851492346425]],
    [ "Enedis",[-0.12782076094929892, 49.28608510960087]],
    [ "Enedis",[-0.13808450000000042, 49.282473499999995]],
    [ "Enedis",[-0.12492082051282, 49.28772117948718]],
    [ "Enedis",[-0.13750393419434276, 49.28556689667897]],
    [ "Enedis",[-0.13176339355365202, 49.28215575471236]],
    [ "Enedis",[-0.13536570451237306, 49.28228928129548]],
    [ "Enedis",[-0.13168512256267392, 49.28514313286063]],
    [ "Enedis",[-0.126123, 49.28209800000001]],
    [ "Enedis",[2.3057896064013286, 48.58961617381545]],
    [ "Enedis",[-0.1288502279425481, 49.28671354336877]],
    [ "Enedis",[2.3008661159420294, 48.587714420289856]],
    [ "Enedis",[2.3052279999999996, 48.59058199999999]],
    [ "Enedis",[2.302949153477218, 48.59343987889689]],
    [ "Enedis",[2.306572886178862, 48.60679527235772]],
    [ "Enedis",[2.3109149326113116, 48.620133042633654]],
    [ "Enedis",[2.3055376717791414, 48.59257399591002]],
    [ "Enedis",[2.3055668272485663, 48.59278809497831]],
    [ "Enedis",[2.35961712145871, 48.573539235232055]],
    [ "Enedis",[-1.6616600000000006, 46.79494400000001]],
    [ "Enedis",[-1.6609482500000006, 46.79749325]],
    [ "Enedis",[2.3595777075268805, 48.57527045806451]],
    [ "Enedis",[2.356731098864395, 48.570858249165]],
    [ "Enedis",[3.744812529184946, 50.30454673647121]],
    [ "Enedis",[3.750514335001925, 50.30358353587472]],
    [ "Enedis",[2.3663020636063616, 48.55128108760876]],
    [ "Sous-station SNCF Réseau de Mussay",[2.0499091459088, 47.11828723394854]],
    [ "Enedis",[2.3634202171466403, 48.55078034988414]],
    [ "Enedis",[2.3728236679127725, 48.554659011214945]],
    [ "Enedis",[4.803659089238845, 45.35715776640421]],
    [ "Enedis",[4.807049451612903, 45.35777184946237]],
    [ "Enedis",[4.811215678960873, 45.357971554629046]],
    [ "Enedis",[2.9701813658536595, 50.38599906504065]],
    [ "Enedis",[2.973897499999999, 50.388453999999996]],
    [ "Enedis",[2.977071885245901, 50.39705186748634]],
    [ "Enedis",[2.9639691094335814, 50.3865637140899]],
    [ "Enedis",[2.9742379999999997, 50.404489]],
    [ "Enedis",[2.9648139449541278, 50.40793417507646]],
    [ "Enedis",[2.97350975, 50.3978048]],
    [ "Enedis",[2.968991623859253, 50.40633331037726]],
    [ "Enedis",[2.9707256810870764, 50.390447164170816]],
    [ "Enedis",[2.970407665477057, 50.397625674801894]],
    [ "Enedis",[2.970474, 50.405686]],
    [ "Enedis",[2.9636039093323494, 50.39108569375384]],
    [ "Enedis",[2.969126205086914, 50.40819516360047]],
    [ "Enedis",[2.9660054980906767, 50.39573700105777]],
    [ "Enedis",[2.9623799941992375, 50.39261156369674]],
    [ "Enedis",[2.974250041356493, 50.398985351530186]],
    [ "Enedis",[2.9633020092488165, 50.39025800397023]],
    [ "Enedis",[2.971601, 50.413343000000005]],
    [ "Enedis",[2.9631831082926072, 50.4203525799395]],
    [ "Enedis",[2.946713500972552, 50.40957333146027]],
    [ "Enedis",[2.9631586672475554, 50.41333250682545]],
    [ "Enedis",[2.9475152051853253, 50.40798560986915]],
    [ "Enedis",[2.96567875, 50.411969]],
    [ "Enedis",[2.9447291618936293, 50.41141000428599]],
    [ "Enedis",[2.9245909770114937, 50.40384593633952]],
    [ "Enedis",[2.941938, 50.40837700000001]],
    [ "Enedis",[2.9581265394672176, 50.4126551682886]],
    [ "Enedis",[2.9701655000000002, 50.417368499999995]],
    [ "Enedis",[2.965655, 50.41526075]],
    [ "Enedis",[2.941677092695588, 50.410574822395255]],
    [ "Enedis",[2.952875536517079, 50.40911166402688]],
    [ "Enedis",[2.955799, 50.412164000000004]],
    [ "Enedis",[2.9591393972602735, 50.41521287899544]],
    [ "Enedis",[2.9692186499999997, 50.41387975000001]],
    [ "Enedis",[2.9463535945663533, 50.41367232706374]],
    [ "réséda",[6.311389999999999, 49.06517250000001]],
    [ "réséda",[6.30444374812818, 49.06869757472297]],
    [ "réséda",[6.3025435, 49.06473275]],
    [ "réséda",[6.3085398196176214, 49.069075960099745]],
    [ "Enedis",[3.5614664666279183, 50.44334916254965]],
    [ "Enedis",[3.5578047899653056, 50.44727635681878]],
    [ "Enedis",[3.578084793387534, 50.43132967718158]],
    [ "Enedis",[3.5624291774112944, 50.43965565067466]],
    [ "Enedis",[3.5678310208073247, 50.4415383383271]],
    [ "Enedis",[3.5809010380313198, 50.43620792393736]],
    [ "Enedis",[3.5776488406983096, 50.42829350900163]],
    [ "Enedis",[3.5855854999999996, 50.438787]],
    [ "Enedis",[3.5606799999999996, 50.433580000000006]],
    [ "Enedis",[3.5809390451081042, 50.43307491071706]],
    [ "Enedis",[3.5754332882744486, 50.43345414558797]],
    [ "Enedis",[3.5796583551941232, 50.42711668328086]],
    [ "Enedis",[3.569387503886762, 50.43387833379742]],
    [ "Enedis",[3.5714428881602913, 50.438939829872496]],
    [ "Enedis",[3.559660628731183, 50.43650473006451]],
    [ "Enedis",[3.558926225413402, 50.44075515317668]],
    [ "Enedis",[3.5776533719368917, 50.434962863712656]],
    [ "Enedis",[3.574476395218105, 50.43095504729575]],
    [ "Enedis",[3.58274202632803, 50.435430188296586]],
    [ "Enedis",[3.5693246681113355, 50.43542449321004]],
    [ "Enedis",[3.5649861792452833, 50.442339355345915]],
    [ "Enedis",[-1.8842797500000001, 46.6342885]],
    [ "Enedis",[-1.85502955, 46.63390725]],
    [ "Enedis",[-1.8709380999999998, 46.6307682]],
    [ "Enedis",[-1.8726963500000002, 46.635606149999994]],
    [ "Enedis",[-1.8750223127499237, 46.63363152417106]],
    [ "Enedis",[-1.8620894, 46.62948200000001]],
    [ "Enedis",[-1.8693867531575057, 46.62503291832373]],
    [ "Enedis",[-1.8740459000000003, 46.6319208]],
    [ "Enedis",[-1.8706947500000002, 46.629124749999995]],
    [ "Enedis",[-1.8920456999999995, 46.64094509999999]],
    [ "Enedis",[-1.881836820748676, 46.6318191077905]],
    [ "Enedis",[-1.8466463711700845, 46.60845615492561]],
    [ "Enedis",[-1.8854923496781666, 46.63512623329473]],
    [ "Enedis",[-1.84992475, 46.62472525]],
    [ "Enedis",[-1.8832998332167266, 46.63673264923539]],
    [ "Enedis",[-1.8664996000000003, 46.623220350000004]],
    [ "Enedis",[-1.8884889999999999, 46.63967675000001]],
    [ "Enedis",[-1.8465007368241508, 46.61042110528984]],
    [ "Enedis",[-1.8581829360471271, 46.62542777036653]],
    [ "Enedis",[-1.8916175, 46.64904699999999]],
    [ "Enedis",[-1.8697177999999999, 46.62183790000001]],
    [ "Enedis",[1.1729698210246162, 49.23252574783767]],
    [ "Enedis",[-1.8647823499999994, 46.617552450000005]],
    [ "Enedis",[-1.8863358000000001, 46.636789300000004]],
    [ "Enedis",[-1.8640639999999997, 46.62398205]],
    [ "Enedis",[-1.8630512999999997, 46.63282]],
    [ "Enedis",[-1.8505079098672066, 46.60820312162001]],
    [ "Enedis",[-1.8558571724418376, 46.636110705631516]],
    [ "Enedis",[-1.881240100000001, 46.63357150000001]],
    [ "Enedis",[-1.89000925, 46.6457645]],
    [ "Enedis",[-1.8589926370564782, 46.6278057512154]],
    [ "Enedis",[-1.8583767500000001, 46.6312225]],
    [ "Enedis",[-1.8875595, 46.63808925000001]],
    [ "Enedis",[-1.8671909999999998, 46.6201065]],
    [ "Enedis",[-1.8791615499999992, 46.63325899999999]],
    [ "Enedis",[-1.8741867000662775, 46.63459383333551]],
    [ "Enedis",[-1.84954925, 46.606335249999994]],
    [ "Enedis",[-1.87282925, 46.62864575]],
    [ "Enedis",[-1.887779167085957, 46.63582371255887]],
    [ "Enedis",[-1.8876352499999995, 46.64408374999999]],
    [ "Enedis",[-1.8504012999999995, 46.629778249999994]],
    [ "Enedis",[-1.8728989081296332, 46.625915168510765]],
    [ "Enedis",[-1.8561236341398095, 46.613169146160075]],
    [ "Enedis",[-1.8671992499999996, 46.618487300000005]],
    [ "Enedis",[-1.8634561254185444, 46.629152605716236]],
    [ "Enedis",[-1.8480432286090425, 46.62203079474785]],
    [ "Enedis",[-1.8541895000000002, 46.6120455]],
    [ "Enedis",[-1.8367065999999999, 46.60366165]],
    [ "Poste Électrique de Condé-sur-Noireau",[-0.5473549087109498, 48.857130102665685]],
    [ "Enedis",[-1.8304932895666084, 46.60834513003858]],
    [ "Enedis",[-1.8329705500000004, 46.5995878]],
    [ "Enedis",[-1.8395408000000002, 46.6095432]],
    [ "Enedis",[-1.83594635, 46.611811949999996]],
    [ "Enedis",[-1.8246208000000002, 46.60299845]],
    [ "Enedis",[-1.8329126499999997, 46.60421045]],
    [ "Enedis",[-1.8338533617401118, 46.60795726990026]],
    [ "Enedis",[-1.8327704499999995, 46.60951815]],
    [ "Enedis",[-1.836414181460066, 46.605588279034556]],
    [ "Enedis",[-1.8254088386961775, 46.60150037251411]],
    [ "Enedis",[-1.8440561726071292, 46.604417039914566]],
    [ "Enedis",[-1.8379889550750792, 46.6029588821756]],
    [ "Enedis",[-1.8288844999999998, 46.6005475]],
    [ "Enedis",[3.0729696018044113, 50.613655067394376]],
    [ "Enedis",[3.03899164408203, 50.6152153563821]],
    [ "Enedis",[3.07647253457849, 50.61288357142227]],
    [ "Enedis",[3.0497924852938905, 50.61347148826658]],
    [ "Enedis",[3.027218078463326, 50.616599016334874]],
    [ "Enedis",[3.055352, 50.604918600000005]],
    [ "Enedis",[3.029935931690853, 50.61580427067967]],
    [ "Enedis",[3.0675025757332506, 50.609666060557096]],
    [ "Enedis",[3.0572357501839638, 50.60606294435776]],
    [ "Enedis",[3.0432706998578207, 50.607856207324595]],
    [ "Enedis",[3.0691030135925352, 50.6128493901337]],
    [ "Enedis",[3.0524073764658213, 50.614793362665935]],
    [ "Enedis",[3.0416274654504813, 50.61105013792807]],
    [ "Enedis",[3.0242261127307533, 50.61299847502903]],
    [ "Enedis",[3.051348280943308, 50.60213681963244]],
    [ "Enedis",[3.04671184277663, 50.614254656711765]],
    [ "Enedis",[3.0741628791877753, 50.611747428218905]],
    [ "Enedis",[3.040744829697666, 50.6145301142141]],
    [ "Enedis",[3.0483990009069752, 50.60703316483744]],
    [ "Enedis",[3.0497348684916754, 50.613474317626256]],
    [ "Enedis",[3.0559872305553584, 50.613719435057675]],
    [ "Enedis",[3.0497293594172064, 50.6047643756578]],
    [ "Enedis",[3.026551703366154, 50.61561461794407]],
    [ "Enedis",[3.033779400000001, 50.61621905000001]],
    [ "Enedis",[3.041970552313715, 50.615009801996024]],
    [ "Enedis",[3.0237537862030806, 50.61789297771465]],
    [ "Enedis",[3.059642787629764, 50.61264795536698]],
    [ "Enedis",[3.045296377660503, 50.612398991401804]],
    [ "Enedis",[3.070716184481393, 50.651666041171815]],
    [ "Enedis",[3.0688625391866013, 50.65737935801379]],
    [ "Enedis",[3.0587177975009188, 50.65218818412348]],
    [ "Enedis",[3.0617995000000002, 50.6533205]],
    [ "Enedis",[3.0704784743108733, 50.655619957902985]],
    [ "Enedis",[3.078489689655172, 50.65425055442867]],
    [ "Enedis",[3.056816129411765, 50.65442819738562]],
    [ "Enedis",[3.06481506077348, 50.65643120165746]],
    [ "Enedis",[3.0601394999999996, 50.6558945]],
    [ "Enedis",[3.069733957833089, 50.665446776671544]],
    [ "Enedis",[3.066466071005256, 50.66398679861345]],
    [ "Enedis",[3.063519000204342, 50.655948760463]],
    [ "Enedis",[3.0720772299057924, 50.659980191621564]],
    [ "Enedis",[-0.07231294977168962, 49.30165637899543]],
    [ "Enedis",[-0.08232049690123167, 49.29866483400016]],
    [ "Enedis",[-0.061280223617781064, 49.30047855079423]],
    [ "Enedis",[-0.0552818, 49.304455499999996]],
    [ "Enedis",[-0.0734111658202638, 49.29933131466269]],
    [ "Enedis",[-0.07710684579339736, 49.294327234504784]],
    [ "Enedis",[-0.08455658644067836, 49.29741631412429]],
    [ "Enedis",[-0.07942074871026766, 49.29773328007988]],
    [ "Enedis",[-0.07101391445095388, 49.29341491707977]],
    [ "Enedis",[-0.082437329474192, 49.29640501447177]],
    [ "Enedis",[-0.061008686945500504, 49.30336566413181]],
    [ "Enedis",[-0.062301, 49.29871549999999]],
    [ "Enedis",[-0.0799065, 49.30035145000001]],
    [ "Enedis",[-0.07732424872182675, 49.29687475826312]],
    [ "Enedis",[2.9643389976492647, 50.44564800069896]],
    [ "Enedis",[2.9541776857089226, 50.43441000012764]],
    [ "Enedis",[2.94940496111949, 50.42402830517848]],
    [ "Enedis",[2.9455835, 50.431400499999995]],
    [ "Enedis",[2.952577656459394, 50.426970523048915]],
    [ "Enedis",[2.9491875226398103, 50.41826266838615]],
    [ "Enedis",[2.9572687499999994, 50.42248849999999]],
    [ "Enedis",[2.947930208086862, 50.43009806816351]],
    [ "Enedis",[5.981093820426863, 46.23358452642753]],
    [ "Enedis",[5.981550925756586, 46.237067311778794]],
    [ "Enedis",[5.988079304703475, 46.24867952760736]],
    [ "Enedis",[5.968053258064517, 46.22407933333334]],
    [ "Enedis",[5.975571, 46.234007]],
    [ "Enedis",[5.991540842398884, 46.226301733612274]],
    [ "Enedis",[5.974311873563217, 46.23809933333333]],
    [ "Enedis",[5.969588992636229, 46.226086368679425]],
    [ "Enedis",[5.97630064603393, 46.23772977670794]],
    [ "Enedis",[5.990343978052126, 46.249773331275726]],
    [ "Enedis",[5.9890367845294215, 46.25306419067574]],
    [ "Enedis",[6.0012925, 46.2293635]],
    [ "Enedis",[5.978971177993528, 46.24392394822007]],
    [ "Enedis",[5.984546196850393, 46.245255719160106]],
    [ "Enedis",[5.991879772277228, 46.25302713531353]],
    [ "Enedis",[5.9657829454806315, 46.22222330750837]],
    [ "Enedis",[5.982676137471263, 46.24257569057471]],
    [ "Enedis",[5.9691143544973535, 46.2406796984127]],
    [ "Enedis",[5.9791735, 46.24363000000001]],
    [ "Enedis",[5.981116668284397, 46.23267648928253]],
    [ "Enedis",[5.986825999999999, 46.2469825]],
    [ "Enedis",[5.97031680239521, 46.24135795209582]],
    [ "Enedis",[2.9803905251462, 50.33117149707603]],
    [ "Enedis",[2.99010814660767, 50.32295695752212]],
    [ "Enedis",[2.986760283433135, 50.33296745366411]],
    [ "Enedis",[2.976673978988327, 50.32458572736705]],
    [ "Enedis",[2.9849647836577464, 50.331918134022715]],
    [ "Enedis",[2.983170064667292, 50.32912776694783]],
    [ "Enedis",[2.9841934774774765, 50.33620410810811]],
    [ "Enedis",[2.9867610515534646, 50.32115814574145]],
    [ "Enedis",[2.986071753630985, 50.32612959315044]],
    [ "Enedis",[2.9768698227006216, 50.324592159972]],
    [ "Enedis",[2.9809926309574015, 50.33260566975959]],
    [ "Enedis",[2.9725006815365544, 50.328354074349434]],
    [ "Enedis",[2.9804182315215657, 50.32403370707071]],
    [ "Enedis",[2.992335832234969, 50.328660439589946]],
    [ "Enedis",[3.0287541658358257, 50.30839097008973]],
    [ "Enedis",[3.028836430379747, 50.306796043037984]],
    [ "Enedis",[3.02655545, 50.311117700000004]],
    [ "Enedis",[3.0215385832129664, 50.308645842882356]],
    [ "Enedis",[3.0558925, 50.31403199999999]],
    [ "Enedis",[3.0551929409908243, 50.31069252180159]],
    [ "Enedis",[3.0524548, 50.30916500000001]],
    [ "réséda",[6.3847966774677465, 49.016023516651664]],
    [ "réséda",[6.391684158303767, 49.0089455343808]],
    [ "réséda",[6.382657498031229, 49.01321806028513]],
    [ "réséda",[6.387360000000001, 49.0130599]],
    [ "réséda",[6.394821699841849, 49.006147666608896]],
    [ "réséda",[6.393130679961325, 49.00933548368383]],
    [ "réséda",[6.396817970654627, 49.008173662152]],
    [ "réséda",[6.394053699999999, 49.00596984999999]],
    [ "réséda",[6.398569186167694, 49.01202618355022]],
    [ "réséda",[6.382230740149096, 49.01629943876465]],
    [ "réséda",[6.40108051168573, 49.007050326371626]],
    [ "réséda",[6.395074844390832, 49.01208903417773]],
    [ "Enedis",[6.7540708495908, 49.03277684007963]],
    [ "Enedis",[2.511749, 50.688974333333334]],
    [ "Enedis",[2.571529178743962, 50.68164402972873]],
    [ "Enedis",[2.5153311753948455, 50.68973997090606]],
    [ "Enedis",[2.513401850635129, 50.6825886875039]],
    [ "Enedis",[2.5132833704481143, 50.69259119186527]],
    [ "Enedis",[2.5731830811965817, 50.682521788461536]],
    [ "Enedis",[-1.7664042224377627, 46.50806260362109]],
    [ "Enedis",[-1.7868684407633755, 46.51055382695401]],
    [ "Enedis",[-1.7926416611794553, 46.52798216954509]],
    [ "Enedis",[-1.7844812, 46.508118300000014]],
    [ "Enedis",[-1.80039, 46.52113799999999]],
    [ "Enedis",[-1.7551594999999998, 46.5291235]],
    [ "Enedis",[-1.7702163026844218, 46.51173334390192]],
    [ "Enedis",[-1.7685184999999999, 46.521527]],
    [ "Enedis",[-1.754282667489712, 46.508810867078196]],
    [ "Enedis",[-1.7894445970497712, 46.510303255863306]],
    [ "Enedis",[-1.7660808399372643, 46.509150314526046]],
    [ "Enedis",[-1.7691422124744143, 46.50619959471235]],
    [ "Enedis",[-1.7721028785554929, 46.51414315926531]],
    [ "Enedis",[-1.781006591120889, 46.52114857516305]],
    [ "Enedis",[-1.7708226348868386, 46.53599078300647]],
    [ "Enedis",[-1.7718716766013813, 46.50568748150251]],
    [ "Enedis",[-1.772592, 46.50727009999999]],
    [ "Enedis",[-1.756530259329941, 46.51992147964377]],
    [ "Energie",[-1.7612519, 46.5098801]],
    [ "Enedis",[-1.7953374290834099, 46.53272381036218]],
    [ "Enedis",[-1.7665192485112566, 46.50641921517792]],
    [ "Enedis",[-1.8090720347083848, 46.55390364713301]],
    [ "Enedis",[-1.7603589120567937, 46.51941652728252]],
    [ "Enedis",[-1.7740913613636002, 46.536332129940675]],
    [ "Enedis",[-1.77175255, 46.51251190000001]],
    [ "Enedis",[-1.7598641330222715, 46.532802198347916]],
    [ "Enedis",[-1.7829662499999999, 46.523033000000005]],
    [ "Enedis",[-1.7697028004631092, 46.53232445349964]],
    [ "Enedis",[-1.759909120546607, 46.51161128257687]],
    [ "Enedis",[-1.794040744476466, 46.51228804322767]],
    [ "Enedis",[-1.7722833379271843, 46.52260317558438]],
    [ "Enedis",[-1.7770050999999993, 46.516213]],
    [ "Enedis",[-1.76750696215119, 46.53758777704034]],
    [ "Enedis",[-1.79083405, 46.52193325000001]],
    [ "Enedis",[-1.7873773543031894, 46.51534684687637]],
    [ "Enedis",[-1.753199400000001, 46.506791150000005]],
    [ "Enedis",[-1.7902773333333335, 46.51479945185184]],
    [ "Enedis",[-1.775183088889568, 46.5141144767139]],
    [ "Enedis",[-1.7803869999999995, 46.5109635]],
    [ "Enedis",[-1.7559665000000002, 46.507223]],
    [ "Enedis",[-1.7875451443800243, 46.50870021712482]],
    [ "Enedis",[-1.788816986402059, 46.51290131270673]],
    [ "Enedis",[-1.7810056785312172, 46.51645074479882]],
    [ "Enedis",[-1.7778255000000003, 46.537315]],
    [ "Enedis",[-1.7908373, 46.52439785]],
    [ "Enedis",[-1.7612574999999997, 46.51401525000001]],
    [ "Enedis",[-1.7730000000000012, 46.526199250000005]],
    [ "Enedis",[-1.7661366, 46.512359749999995]],
    [ "Enedis",[-1.7792860499999998, 46.5342663]],
    [ "Enedis",[-1.7799069, 46.5093809]],
    [ "Enedis",[2.4089090055639857, 50.74226885044679]],
    [ "Enedis",[-1.7957055499999983, 46.538781349999994]],
    [ "Enedis",[-1.7773450786075822, 46.510572876562335]],
    [ "Enedis",[-1.7785231269462527, 46.52367819456621]],
    [ "Enedis",[-1.7692162499999993, 46.523713750000006]],
    [ "Enedis",[-1.7715305, 46.52881150000001]],
    [ "Enedis",[-1.7732683601835277, 46.53786572821527]],
    [ "Enedis",[-1.77425775, 46.5284225]],
    [ "Enedis",[-1.7754258901117028, 46.53369526961414]],
    [ "Enedis",[-1.7734890750670247, 46.511686689008044]],
    [ "Enedis",[-1.8296422500000005, 46.587221]],
    [ "Enedis",[-1.770802557952273, 46.53425546913037]],
    [ "Enedis",[-1.78398865, 46.51052485]],
    [ "Enedis",[-1.7792995000000014, 46.513971000000005]],
    [ "Enedis",[-1.773144167574594, 46.51942589743835]],
    [ "Enedis",[-1.76582425, 46.5363356]],
    [ "Enedis",[1.1313487331809293, 43.216767271733026]],
    [ "Enedis",[1.3105598321627274, 43.251365805650856]],
    [ "Enedis",[1.2503048296296297, 43.250776941203696]],
    [ "Enedis",[2.47306195335277, 50.72996467395529]],
    [ "Enedis",[2.470452613609404, 50.73211425913944]],
    [ "Enedis",[3.626581971354167, 50.312578567708336]],
    [ "Enedis",[3.6285224032941943, 50.30757319740704]],
    [ "Enedis",[6.7890138, 45.2326967]],
    [ "Enedis",[6.789701476873275, 45.233750073636116]],
    [ "Enedis",[6.784526194952431, 45.23230645518601]],
    [ "Enedis",[2.1546425430932703, 48.91146338036205]],
    [ "Enedis",[6.815790050000001, 45.2759809]],
    [ "Enedis",[6.817826161746988, 45.27669621536145]],
    [ "Enedis",[6.815796955622795, 45.27986629702472]],
    [ "Enedis",[6.815906081639086, 45.27762734097714]],
    [ "Enedis",[1.141340860912555, 43.28113845372112]],
    [ "Enedis",[-1.6129910000000003, 46.42085745]],
    [ "Enedis",[-1.5756401500000006, 46.4101773]],
    [ "Enedis",[-1.6008107999999999, 46.415745799999996]],
    [ "Enedis",[-1.5723650000000002, 46.4128952]],
    [ "Enedis",[-1.5761235736482437, 46.42057489410252]],
    [ "Enedis",[-1.5639452249490022, 46.41667455593596]],
    [ "Enedis",[-1.6131005000000005, 46.41805525]],
    [ "Enedis",[-1.5675752499999998, 46.420846000000004]],
    [ "Enedis",[-1.61996695, 46.41950409999999]],
    [ "Enedis",[-1.5737267580132346, 46.42717143122132]],
    [ "Enedis",[-1.5853179000000006, 46.41875105]],
    [ "Enedis",[-1.5945948396610237, 46.420830124801874]],
    [ "Enedis",[-1.60346675, 46.41746]],
    [ "Enedis",[-1.5786580500000003, 46.412293000000005]],
    [ "Enedis",[-1.5887709391621132, 46.419909076337134]],
    [ "Enedis",[-1.5951139820330964, 46.418334251063825]],
    [ "Enedis",[-1.5727737300944666, 46.409545318488526]],
    [ "Enedis",[-1.5831787194210762, 46.421100104344696]],
    [ "Enedis",[-1.61436075, 46.41622400000001]],
    [ "Enedis",[-1.5873337500000002, 46.420570999999995]],
    [ "Enedis",[-1.5778708521560572, 46.417121043891164]],
    [ "Enedis",[-1.569669149999999, 46.4257772]],
    [ "Enedis",[-1.59787138732201, 46.4199229713992]],
    [ "Enedis",[-1.5799728000000002, 46.4134635]],
    [ "Enedis",[-1.56684365, 46.41896464999999]],
    [ "Enedis",[-1.588782138504059, 46.41195230450373]],
    [ "Enedis",[-1.5732317993710705, 46.41088472327044]],
    [ "Enedis",[-1.5866238000000004, 46.411874100000006]],
    [ "Enedis",[-1.5706327499999992, 46.414556999999995]],
    [ "Enedis",[-1.5847607500000005, 46.420509250000016]],
    [ "Enedis",[-1.57964735, 46.42133655]],
    [ "Enedis",[-1.5921164, 46.410790199999994]],
    [ "Enedis",[-1.5712486999999997, 46.417914599999996]],
    [ "Enedis",[-1.56859275, 46.41562874999999]],
    [ "Enedis",[-1.5701799499999993, 46.4096459]],
    [ "Enedis",[-1.5738258315557805, 46.41944614680781]],
    [ "Enedis",[-1.56927275, 46.411559]],
    [ "Pont d'Arvan",[6.351468573491894, 45.271513705092914]],
    [ "Enedis",[-1.5809769664949374, 46.41624506967258]],
    [ "Enedis",[-1.5934777912836071, 46.413480831445206]],
    [ "Enedis",[-1.6020949999999987, 46.413288]],
    [ "Enedis",[-1.5689304000000002, 46.4208521]],
    [ "Enedis",[-1.6099875, 46.42243399999999]],
    [ "Enedis",[-1.5907373500000006, 46.421336249999996]],
    [ "Enedis",[2.1484045000000003, 48.9147045]],
    [ "Enedis",[2.1518752841449422, 48.910424567142144]],
    [ "Enedis",[-0.6324568392665009, 44.94702879528924]],
    [ "Enedis",[-0.6368095360073993, 44.946779111501456]],
    [ "RTE",[1.3930742284544797, 43.617737165547695]],
    [ "RTE",[1.3567721013635201, 43.60086553926286]],
    [ "Enedis",[1.950851670088782, 48.77026967751412]],
    [ "Enedis",[1.971962306284805, 48.79413646232526]],
    [ "Enedis",[1.955566915023854, 48.77826771716626]],
    [ "Enedis",[1.9805725, 48.794697]],
    [ "Enedis",[1.946721219666216, 48.77085155525484]],
    [ "Enedis",[1.953454328234183, 48.787194166194524]],
    [ "Enedis",[1.9734670917285262, 48.794644597737715]],
    [ "Enedis",[1.94393, 48.7771165]],
    [ "Enedis",[1.9480120374006547, 48.768363672510525]],
    [ "Enedis",[1.9571770704769258, 48.76801330810323]],
    [ "Enedis",[1.9736777243035544, 48.773547732949076]],
    [ "Enedis",[1.9699041182495347, 48.78219620018621]],
    [ "Enedis",[1.946721, 48.7795515]],
    [ "Enedis",[1.9436065, 48.7867905]],
    [ "Axel",[1.9629057, 48.793728400000006]],
    [ "Enedis",[1.9478146010498683, 48.7764782887139]],
    [ "Enedis",[1.952249422559421, 48.76891451622605]],
    [ "Enedis",[1.9494739416641085, 48.769775357691124]],
    [ "Enedis",[1.9744849138888887, 48.759526547916664]],
    [ "Enedis",[3.6120463333333337, 50.32528404040404]],
    [ "Enedis",[3.607017693877551, 50.32858666666667]],
    [ "Enedis",[3.6019331554145033, 50.326608794191195]],
    [ "Enedis",[3.607590847859122, 50.32875589031912]],
    [ "Enedis",[3.599900142417244, 50.332977981524245]],
    [ "Enedis",[2.4918235000000006, 48.75828025]],
    [ "Enedis",[2.4896395, 48.75857924999999]],
    [ "Enedis",[2.4910408922946328, 48.75668466753732]],
    [ "Enedis",[2.48670925, 48.7443375]],
    [ "Enedis",[2.4840412499999993, 48.742675000000006]],
    [ "Enedis",[2.471009161258604, 48.76190246214357]],
    [ "Enedis",[2.487794398720682, 48.74655008599859]],
    [ "Enedis",[2.4945557831547127, 48.75737979373454]],
    [ "Enedis",[2.4764875000000006, 48.743587749999996]],
    [ "Enedis",[2.5042666778656573, 48.74060236546247]],
    [ "Enedis",[2.5030375000000014, 48.742554500000004]],
    [ "Enedis",[2.4767380000000006, 48.753472650000006]],
    [ "Enedis",[2.50043675, 48.747248]],
    [ "Enedis",[2.482085109634698, 48.75578233139718]],
    [ "Enedis",[2.4947286607396744, 48.747063379427175]],
    [ "Enedis",[2.4902866751824817, 48.74941496642336]],
    [ "Enedis",[3.0408860753270086, 50.303005464944825]],
    [ "Enedis",[-1.6032867499999994, 46.83474250000001]],
    [ "Poste électrique de Drumettaz",[5.912957992731375, 45.64738819831404]],
    [ "Enedis",[6.031001170653909, 46.46315085645933]],
    [ "Enedis",[6.049065700164734, 46.45918586249232]],
    [ "Enedis",[6.072648755231847, 46.4315222613603]],
    [ "Enedis",[6.06575277029703, 46.453383844884485]],
    [ "Enedis",[6.0399680999999985, 46.471159050000004]],
    [ "Enedis",[6.066885849999999, 46.452649650000005]],
    [ "Enedis",[6.028430899999998, 46.48812724999999]],
    [ "Enedis",[6.052456524881603, 46.4505052157377]],
    [ "Enedis",[6.042103333333334, 46.46684174603174]],
    [ "Enedis",[3.067253276071663, 50.297544587226895]],
    [ "Enedis",[1.197990943023038, 43.25473887411906]],
    [ "Enedis",[1.1282595530019426, 43.23726537261847]],
    [ "Enedis",[2.133430999999999, 48.892459249999995]],
    [ "Enedis",[-1.4894683, 46.408542000000004]],
    [ "Enedis",[-1.53878, 46.410601750000005]],
    [ "Enedis",[-1.5292673999999997, 46.414301599999995]],
    [ "Enedis",[-1.4746253, 46.3881807]],
    [ "Enedis",[-1.4886442176615127, 46.42422950709267]],
    [ "Enedis",[-1.5253806999999997, 46.41618665]],
    [ "Enedis",[-1.46217725, 46.37800575000001]],
    [ "Enedis",[-1.5082541500000006, 46.402547350000006]],
    [ "Enedis",[-1.4902786404529584, 46.42842531916041]],
    [ "Enedis",[-1.4839797885416655, 46.42472797291667]],
    [ "Enedis",[-1.48542715, 46.390998700000004]],
    [ "Enedis",[-1.4409139943262408, 46.4078826729951]],
    [ "Enedis",[-1.4975049424231979, 46.41219091979181]],
    [ "Enedis",[-1.4866626000000003, 46.4213262]],
    [ "Enedis",[-1.43220535, 46.4067662]],
    [ "Enedis",[-1.5208914302884602, 46.41351516458334]],
    [ "Enedis",[-1.4888312499999998, 46.406846699999996]],
    [ "Enedis",[-1.4937738999999999, 46.41783285]],
    [ "Enedis",[-1.4965471680989426, 46.42115810346761]],
    [ "Enedis",[-1.48916615, 46.42231234999999]],
    [ "Enedis",[-1.4939050500000004, 46.421917650000005]],
    [ "Enedis",[-1.4663807500000001, 46.38587515]],
    [ "Enedis",[-1.5011962500000002, 46.4053535]],
    [ "Enedis",[-1.4875845, 46.40357609999999]],
    [ "Enedis",[-1.469437781543955, 46.38739486586315]],
    [ "Enedis",[-1.4646910000000004, 46.377610999999995]],
    [ "Enedis",[-1.4871270499999996, 46.4252586]],
    [ "Enedis",[-1.4911254999999999, 46.418304899999995]],
    [ "Enedis",[-1.4840676500000007, 46.42186675]],
    [ "Enedis",[2.397585207281731, 48.66889537913103]],
    [ "Enedis",[-1.5370311821960634, 46.41438350249013]],
    [ "Enedis",[-1.5509627499999998, 46.40838055]],
    [ "Enedis",[-1.5407057263273647, 46.4125276754634]],
    [ "Enedis",[-1.5462553500000003, 46.41556965]],
    [ "Enedis",[-1.5500873500000005, 46.40889505]],
    [ "Enedis",[-1.55868095, 46.417388]],
    [ "Enedis",[-1.5544927499999999, 46.44412165]],
    [ "Enedis",[-1.5485646098511696, 46.41126650222065]],
    [ "Enedis",[-1.53343496427765, 46.41529776829819]],
    [ "Enedis",[-1.53870875, 46.412766649999995]],
    [ "Enedis",[-1.5302527000000001, 46.41570479999999]],
    [ "Enedis",[-1.5537449999999997, 46.414875900000006]],
    [ "Enedis",[-1.553988124112114, 46.408948545501296]],
    [ "Enedis",[2.5589352499999998, 48.80007625]],
    [ "Enedis",[2.5272672318959413, 48.78679516360138]],
    [ "Enedis",[2.5377390000000015, 48.792025]],
    [ "Enedis",[2.5267782359758915, 48.79412991562355]],
    [ "Enedis",[2.535352, 48.8007565]],
    [ "Enedis",[2.5415825, 48.80153275]],
    [ "Enedis",[2.53337195, 48.79587565]],
    [ "Enedis",[2.5291861754597926, 48.79834888951537]],
    [ "Enedis",[2.5351707500000007, 48.792190999999995]],
    [ "Enedis",[2.529504249999999, 48.787273500000005]],
    [ "Enedis",[2.5393841153164973, 48.79869227531198]],
    [ "Enedis",[2.539642441717791, 48.80469630470347]],
    [ "Enedis",[2.5273494999999997, 48.78589625000001]],
    [ "Enedis",[2.5293384999999997, 48.79349875]],
    [ "Enedis",[2.5335380000000005, 48.798337000000004]],
    [ "Enedis",[2.5402414999999996, 48.801287499999994]],
    [ "Enedis",[2.5313757500000005, 48.7939505]],
    [ "Enedis",[2.54099675, 48.79167]],
    [ "Enedis",[2.5299057664230817, 48.803623833035786]],
    [ "Enedis",[2.529305499999998, 48.797073]],
    [ "Enedis",[1.1574777127078795, 43.2979971714313]],
    [ "Enedis",[3.128197359978249, 50.309365240891786]],
    [ "Enedis",[3.1477910000000002, 50.3263335]],
    [ "Enedis",[3.1488585, 50.328284]],
    [ "Enedis",[2.5812791500000003, 48.8256809]],
    [ "Enedis",[2.5756257, 48.82336965]],
    [ "Enedis",[2.1765219543998175, 48.89212907987272]],
    [ "Enedis",[2.176335359431793, 48.89212139706761]],
    [ "Enedis",[3.175247847957841, 50.34211114967062]],
    [ "Enedis",[3.165633573293173, 50.33648231024097]],
    [ "Enedis",[3.168202022371365, 50.336943395973165]],
    [ "Enedis",[3.174141, 50.3408945]],
    [ "Enedis",[3.1696585, 50.345934]],
    [ "Enedis",[3.1694525000000002, 50.333699499999994]],
    [ "Enedis",[3.1613686883351395, 50.337659968219576]],
    [ "Enedis",[3.1730292325581404, 50.34269017054263]],
    [ "Enedis",[3.1709462234533694, 50.34479224099723]],
    [ "Enedis",[3.1659772852308414, 50.34143812351945]],
    [ "Enedis",[3.1672334544078695, 50.341578075671585]],
    [ "Enedis",[3.578553871345029, 50.34061919883041]],
    [ "Enedis",[3.5739933283696077, 50.3435892386407]],
    [ "Enedis",[3.578720118012422, 50.3433701962733]],
    [ "Enedis",[3.576924229937565, 50.33731210537537]],
    [ "Enedis",[3.5765673346556075, 50.333344528428896]],
    [ "Enedis",[3.5727691646968385, 50.33734466474488]],
    [ "Enedis",[-1.6664264999999996, 46.442719000000004]],
    [ "Enedis",[-1.6634872499999993, 46.443025250000005]],
    [ "Enedis",[-1.6263716895571585, 46.46850162072777]],
    [ "Enedis",[-1.670381, 46.444515249999995]],
    [ "Enedis",[-1.6036022178316556, 46.46982470561935]],
    [ "Enedis",[-1.6173408500000002, 46.465882750000006]],
    [ "Enedis",[-1.5988753385120296, 46.45424383935411]],
    [ "Enedis",[-1.6354672, 46.46898205]],
    [ "Enedis",[-1.6345457207137086, 46.433484504023646]],
    [ "Enedis",[-1.660760250000001, 46.44208375]],
    [ "Enedis",[-1.612474283615911, 46.459545183194294]],
    [ "Enedis",[-1.6257214160906939, 46.46708973202031]],
    [ "Enedis",[-1.6728177088694112, 46.44270582021146]],
    [ "Enedis",[-1.61967425, 46.465258500000004]],
    [ "Enedis",[-1.6634282500000002, 46.44085925]],
    [ "Enedis",[-1.6176910000000002, 46.467754]],
    [ "Enedis",[-1.596026051793936, 46.45544297053096]],
    [ "Enedis",[-1.62633785, 46.46551175]],
    [ "Enedis",[-1.60427125, 46.46545234999999]],
    [ "Enedis",[-1.6817125000000002, 46.44880225000001]],
    [ "Enedis",[-1.615246031790085, 46.46091639415769]],
    [ "Enedis",[-1.6137315000000003, 46.465141749999994]],
    [ "Enedis",[-1.6733007499999997, 46.4434536]],
    [ "Enedis",[-1.6685821500000013, 46.4474893]],
    [ "Enedis",[-1.6662762499999995, 46.4455555]],
    [ "Enedis",[-1.63421695, 46.464040499999996]],
    [ "Enedis",[2.387997499999999, 48.61223749999999]],
    [ "Enedis",[2.367842157376791, 48.60865127405134]],
    [ "Enedis",[3.1905001934130475, 50.33424445437382]],
    [ "Enedis",[3.199855456231884, 50.35394060289855]],
    [ "Enedis",[3.193881906373379, 50.33414722899042]],
    [ "Enedis",[3.199135544473845, 50.33732420958002]],
    [ "Enedis",[3.1910478888888885, 50.33597986666666]],
    [ "Enedis",[3.2041075000000006, 50.34572]],
    [ "Enedis",[3.186418281244961, 50.33854954442832]],
    [ "Enedis",[3.1874065824139084, 50.341644811099975]],
    [ "Enedis",[3.18843441025641, 50.33957366666667]],
    [ "Enedis",[3.200240107526883, 50.34673922580645]],
    [ "Enedis",[3.1925989999999995, 50.3386]],
    [ "Enedis",[3.2028229813289406, 50.350837872597474]],
    [ "Enedis",[3.2012014720101782, 50.34378158524173]],
    [ "Enedis",[3.205091499999999, 50.335743]],
    [ "Enedis",[3.1963687804878043, 50.34619022764227]],
    [ "Enedis",[3.219481833815493, 50.354191008357446]],
    [ "Enedis",[3.2167190000000003, 50.3526405]],
    [ "Enedis",[2.5099792499999998, 48.821972]],
    [ "Enedis",[2.537471750000003, 48.812402250000005]],
    [ "Enedis",[2.5250052499999995, 48.80965975]],
    [ "Enedis",[2.508309, 48.82681425000001]],
    [ "Courte Paille",[2.485398515879734, 48.82780686758715]],
    [ "Enedis",[2.50850625, 48.822972]],
    [ "Enedis",[2.5213916000000007, 48.8123625]],
    [ "Enedis",[2.5188482499999996, 48.82866925]],
    [ "Enedis",[2.5047255923604315, 48.812345216193265]],
    [ "Enedis",[2.520605, 48.81850625]],
    [ "Enedis",[2.54789225, 48.816483250000005]],
    [ "Enedis",[2.5080552937133613, 48.813747758843746]],
    [ "Enedis",[2.5363724999999997, 48.8089865]],
    [ "Enedis",[2.5217889196165184, 48.81609814896755]],
    [ "Enedis",[2.5347154999999995, 48.81472825]],
    [ "Enedis",[2.5195012500000007, 48.82051425]],
    [ "Enedis",[2.53587075, 48.81226175]],
    [ "Enedis",[2.5409619999999995, 48.81385]],
    [ "Enedis",[2.5148667041015624, 48.822131419270825]],
    [ "Enedis",[-0.848634216843143, 46.920551551163726]],
    [ "Enedis",[2.5520022499999993, 48.805935000000005]],
    [ "Enedis",[-0.8482382499999997, 46.923233]],
    [ "Enedis",[-0.865588590319243, 46.91318667851475]],
    [ "Enedis",[2.51507575, 48.81979475]],
    [ "Enedis",[2.5233671530977992, 48.80569330977983]],
    [ "Enedis",[2.5499879999999995, 48.81341775]],
    [ "Enedis",[2.5552395000000003, 48.815884]],
    [ "Enedis",[2.5209803268152506, 48.81406352298851]],
    [ "Enedis",[2.5319812500000007, 48.810654500000005]],
    [ "Enedis",[2.4821213656245575, 48.82015970179574]],
    [ "Enedis",[2.5330561756539884, 48.806255069330774]],
    [ "Enedis",[2.504634645776566, 48.82292048047229]],
    [ "Enedis",[2.5618564999999998, 48.81316800000001]],
    [ "Enedis",[2.5599507499999996, 48.82991355]],
    [ "Enedis",[2.561803550958009, 48.831442007074806]],
    [ "Enedis",[2.5599346550294935, 48.834827282113125]],
    [ "Enedis",[2.5589808055969927, 48.83054603169562]],
    [ "Poste électrique la Blanchardière",[-0.8414220386955386, 47.06768234654698]],
    [ "Enedis",[-1.6193457499999997, 46.78826449999999]],
    [ "Enedis",[-1.6211968499999991, 46.784800749999995]],
    [ "Enedis",[-1.6191914000000007, 46.808504150000005]],
    [ "Enedis",[-1.6153199999999996, 46.807054499999985]],
    [ "Enedis",[-1.6131677500000001, 46.80871575]],
    [ "Enedis",[-1.6273327499999997, 46.803231999999994]],
    [ "Enedis",[-1.6207685, 46.8082335]],
    [ "Enedis",[3.5502256602564115, 50.33716144551283]],
    [ "Enedis",[3.549788644358741, 50.33943165970962]],
    [ "Enedis",[3.5500676568241465, 50.34376797244094]],
    [ "Enedis",[3.5506341110510675, 50.35142834513195]],
    [ "Enedis",[3.5558804999999998, 50.33496500000001]],
    [ "Enedis",[3.5431575, 50.34733950000001]],
    [ "Enedis",[3.5553698296392873, 50.345212006518906]],
    [ "Enedis",[3.5628993173843697, 50.34941751537936]],
    [ "Enedis",[3.546781772608467, 50.343478658302836]],
    [ "Enedis",[3.5455385298804782, 50.33711032934927]],
    [ "Enedis",[3.5484206250559294, 50.345122690827736]],
    [ "Enedis",[3.5617778734030194, 50.340023471544704]],
    [ "Enedis",[3.5460442361111113, 50.34046933845029]],
    [ "Enedis",[3.5474798261787472, 50.33813479544921]],
    [ "Enedis",[3.5538815714285716, 50.33868424999999]],
    [ "Enedis",[3.5225559195052716, 50.33914484144363]],
    [ "Enedis",[3.5268820141342756, 50.34006891166077]],
    [ "Enedis",[3.5653777916666676, 50.343758875]],
    [ "Enedis",[3.5491285, 50.348176]],
    [ "Enedis",[3.5584131820185783, 50.349941487797196]],
    [ "Enedis",[3.244331769612403, 50.362971551937974]],
    [ "Enedis",[3.2396186460277074, 50.359070671661485]],
    [ "Enedis",[-1.3855787202871321, 46.35064643532303]],
    [ "Enedis",[-1.4573737500000001, 46.3447865]],
    [ "Enedis",[-1.37862295, 46.34807935]],
    [ "Enedis",[-1.3934702709815685, 46.34467254506346]],
    [ "Enedis",[-1.4044504, 46.34266864999999]],
    [ "Enedis",[-1.4546423203557581, 46.36000024259082]],
    [ "Enedis",[-1.457154397864172, 46.34382753300576]],
    [ "Enedis",[-1.4377042201213261, 46.35019266427174]],
    [ "Enedis",[-1.4528000000000003, 46.342697750000006]],
    [ "Enedis",[-1.4223816999999992, 46.35270205]],
    [ "Enedis",[-1.3866741999999999, 46.34439174999999]],
    [ "Enedis",[-1.4667927843956217, 46.347693417159135]],
    [ "Enedis",[-1.4490414999999992, 46.341803750000004]],
    [ "Enedis",[-1.433814772233785, 46.34520186401972]],
    [ "Enedis",[-1.4482774738827777, 46.348027793953165]],
    [ "Charles Chauveau",[-1.4122890999581768, 46.34566473442074]],
    [ "Enedis",[-1.3841568130444755, 46.347768931190636]],
    [ "Nolleaux TSM",[-1.406995804328871, 46.34462745463404]],
    [ "Les Camélias",[-1.4172622000000008, 46.347172400000005]],
    [ "Enedis",[-1.4411305259156786, 46.3434446052517]],
    [ "Enedis",[-1.4506492500000003, 46.345562]],
    [ "Enedis",[-1.4227938783314686, 46.35074801546595]],
    [ "Enedis",[-1.4336589382925975, 46.35260540602196]],
    [ "Enedis",[-1.442003505543725, 46.34707299483343]],
    [ "Enedis",[-1.4515084999999999, 46.3495255]],
    [ "Enedis",[-1.4543565104001492, 46.344370264847115]],
    [ "Enedis",[-1.4039343000000002, 46.3473479]],
    [ "Enedis",[-1.4641992500000007, 46.345326500000006]],
    [ "Enedis",[-1.3904085798026007, 46.34716803856565]],
    [ "Enedis",[-1.4567010313770574, 46.36265745819757]],
    [ "Enedis",[-1.3665967500000002, 46.349766]],
    [ "Enedis",[-1.3932529500000002, 46.3417847]],
    [ "Enedis",[-1.4639249246061103, 46.347750056362074]],
    [ "Enedis",[-1.4431143999999998, 46.34134505000001]],
    [ "Enedis",[-1.4374645000000001, 46.342993500000006]],
    [ "Verdun",[-1.4236938855085681, 46.3490542971301]],
    [ "Enedis",[-1.4143047748651723, 46.34887939588035]],
    [ "Enedis",[-1.4186560999999998, 46.35167525]],
    [ "Enedis",[-1.4365318000000007, 46.3471957]],
    [ "Enedis",[-1.457339373364865, 46.35600879425415]],
    [ "Enedis",[-1.4465142499999997, 46.34440324999999]],
    [ "Enedis",[-1.3905787500000009, 46.34509775]],
    [ "Enedis",[-1.40148185, 46.34465860000001]],
    [ "Enedis",[-1.3571117499999994, 46.348099000000005]],
    [ "Enedis",[-1.461563, 46.34655225]],
    [ "Enedis",[-1.4300971764561479, 46.349787435806064]],
    [ "Enedis",[-1.4309680247457923, 46.34716994403148]],
    [ "Enedis",[-1.3849058376561858, 46.34663387803679]],
    [ "Enedis",[-1.458872, 46.3590515]],
    [ "Enedis",[-1.4655156114266297, 46.344380923217095]],
    [ "Enedis",[-1.3841013607253732, 46.34325577247913]],
    [ "Enedis",[-1.4067581000000002, 46.34959435000001]],
    [ "Enedis",[-1.412332800000001, 46.349860699999994]],
    [ "Enedis",[-1.418593350000001, 46.35002515]],
    [ "Enedis",[-1.4111830104181744, 46.34855830092606]],
    [ "Enedis",[-1.4383904, 46.34087010000001]],
    [ "Enedis",[-1.4605522858766122, 46.34382768859034]],
    [ "Enedis",[2.3522884165958646, 48.52443178504672]],
    [ "Enedis",[2.3493695, 48.52568799999999]],
    [ "Enedis",[3.2517187187033483, 50.37674661868203]],
    [ "Enedis",[3.258260333333333, 50.38215033333333]],
    [ "Enedis",[3.2565070058947834, 50.37732039873207]],
    [ "Enedis",[2.337561, 48.514731499999996]],
    [ "Enedis",[2.329185205921936, 48.512743715118894]],
    [ "Enedis",[6.965529000000001, 49.014368499999996]],
    [ "Enedis",[6.966664397877411, 49.01676020901019]],
    [ "Enedis",[2.358883, 48.509499]],
    [ "Enedis",[2.3639799134275616, 48.509399811542984]],
    [ "Enedis",[2.3507250597658653, 48.52091056561922]],
    [ "Enedis",[2.347718162282656, 48.528561876801895]],
    [ "réséda",[6.120806776817558, 49.09043084430727]],
    [ "réséda",[6.119023847291688, 49.08989651579816]],
    [ "réséda",[6.119474500000001, 49.0914708]],
    [ "réséda",[6.1157698056808405, 49.089534827996786]],
    [ "réséda",[6.129958499999999, 49.09079249999999]],
    [ "réséda",[6.126518999999998, 49.096067]],
    [ "réséda",[6.104183690493093, 49.10322042201558]],
    [ "réséda",[6.1032755, 49.107771]],
    [ "réséda",[6.1229471051282065, 49.089010871794876]],
    [ "réséda",[6.128177740637639, 49.09180029835831]],
    [ "réséda",[6.108119800000001, 49.08229409999999]],
    [ "réséda",[6.113875630240926, 49.08624019504447]],
    [ "réséda",[6.1299653613526575, 49.133955265700486]],
    [ "réséda",[6.132981332153391, 49.133205509734516]],
    [ "réséda",[6.12763618288296, 49.13330206807067]],
    [ "réséda",[6.132616337525507, 49.1307710342206]],
    [ "St-Jacques",[6.132513882388664, 49.13502401484481]],
    [ "réséda",[6.121036620879121, 49.1306046941392]],
    [ "réséda",[6.135493461918894, 49.13287284668645]],
    [ "Enedis",[2.3397703178519595, 48.531360967747155]],
    [ "Enedis",[2.341494708530059, 48.53588145703495]],
    [ "Poste électrique de Verfeil",[1.6721882975098004, 43.629551379639324]],
    [ "Enedis",[6.024334236975563, 48.70030467127708]],
    [ "Forêt de Haye 11",[6.055048529885059, 48.70349967816093]],
    [ "Enedis",[6.016452, 48.69244849999999]],
    [ "Enedis",[6.016834676306407, 48.69014090588581]],
    [ "Enedis",[6.073319664896939, 48.695153369768896]],
    [ "Enedis",[6.030063570446735, 48.69609768728523]],
    [ "Enedis",[6.025395137237237, 48.691340938338335]],
    [ "Enedis",[6.034039574330563, 48.69203266481994]],
    [ "Enedis",[6.0368960000000005, 48.69571399999999]],
    [ "Poste 6",[6.06110755, 48.70385415000001]],
    [ "Enedis",[6.027014902439025, 48.69881166666667]],
    [ "Enedis",[6.038935749999999, 48.695648750000004]],
    [ "Enedis",[6.07020826841626, 48.707665456992444]],
    [ "Enedis",[6.051556794879199, 48.69461553027241]],
    [ "Enedis",[6.062946270386265, 48.69585295851216]],
    [ "Enedis",[6.033655948743662, 48.695280148420515]],
    [ "Enedis",[-1.528052333002317, 46.82405349255213]],
    [ "Enedis",[-1.1585957000000005, 46.541885199999996]],
    [ "Enedis",[-1.17308685, 46.53200125]],
    [ "Poste électrique de Issel",[1.9831075417639237, 43.38431494432155]],
    [ "Enedis",[-1.3439644813015346, 46.95675494659156]],
    [ "Enedis",[-1.33681875, 46.974173]],
    [ "Enedis",[-1.352540607520206, 46.96267662850305]],
    [ "Enedis",[-1.3399477000000009, 46.9643176]],
    [ "Enedis",[-1.3681934999999998, 46.951415]],
    [ "Enedis",[-1.3230652499999995, 46.97362979999999]],
    [ "Enedis",[-1.351474282393232, 46.963744002339496]],
    [ "Enedis",[-1.3255900000000007, 46.956920000000004]],
    [ "Enedis",[-1.3273202666666668, 46.97308399999999]],
    [ "Enedis",[-1.3276062499999999, 46.968958]],
    [ "Enedis",[-1.3373769000000002, 46.96596955]],
    [ "Enedis",[-1.3517979999999998, 46.95466899999999]],
    [ "Enedis",[-1.3251537, 46.97171649999999]],
    [ "Enedis",[-1.3444768999999999, 46.962048550000006]],
    [ "Enedis",[-1.33342665, 46.97036059999999]],
    [ "Enedis",[-1.3204482702901954, 46.794030934303635]],
    [ "Enedis",[-1.31012602749107, 46.787944610718114]],
    [ "Enedis",[3.845121245333334, 43.57114017333334]],
    [ "Enedis",[3.84846274571983, 43.581152873068135]],
    [ "Enedis",[-0.7472636739324392, 44.89247080582951]],
    [ "Enedis",[-0.7370246296200647, 44.8844260442868]],
    [ "RTE",[1.8265237626436044, 43.67374784290364]],
    [ "Poste Électrique le Bourg",[2.0454521976479185, 47.316527760236504]],
    [ "Enedis",[-2.0804682578803675, 47.93514754951601]],
    [ "RTE",[2.0960692101290856, 47.21536233753877]],
    [ "Enedis",[5.212947387460818, 43.49609569404388]],
    [ "Enedis",[5.204398572599398, 43.4976961389489]],
    [ "Poste électrique de Tavel",[4.64284713715393, 44.01497155555928]],
    [ "Enedis",[1.9176162162913437, 48.60976722048772]],
    [ "Enedis",[3.5273146506644393, 50.35480045279343]],
    [ "Enedis",[3.5231365116022095, 50.36536783683241]],
    [ "Enedis",[3.526665302993031, 50.35396470397704]],
    [ "Enedis",[3.529877193576389, 50.34782262152778]],
    [ "Enedis",[3.5223316686009025, 50.34638284461638]],
    [ "Enedis",[3.5247116608956097, 50.35589600087662]],
    [ "Enedis",[3.5163165710158886, 50.348028846573584]],
    [ "Enedis",[3.5167176856240125, 50.34146797314375]],
    [ "Enedis",[3.5284519311579787, 50.347480237829956]],
    [ "Enedis",[3.5170170328042327, 50.3610043888133]],
    [ "Enedis",[3.53231136523126, 50.350704921850074]],
    [ "Enedis",[3.5177483312289564, 50.35626087941921]],
    [ "Enedis",[3.52113016878113, 50.349500331547794]],
    [ "Enedis",[3.5183939999999994, 50.3443835]],
    [ "Enedis",[3.5230568698983147, 50.348503182615964]],
    [ "Enedis",[3.5239084433497543, 50.35311220853858]],
    [ "Enedis",[3.5349053377777784, 50.349780494861115]],
    [ "Enedis",[3.5241664871794867, 50.35427769230769]],
    [ "Enedis",[-1.7873843581247542, 48.327330852442806]],
    [ "Enedis",[1.8344555081696443, 48.530267452887614]],
    [ "Enedis",[1.8320576056721813, 48.516775846714694]],
    [ "Enedis",[1.834286024858459, 48.52892989891455]],
    [ "Enedis",[1.8392846776620828, 48.51656935209178]],
    [ "Enedis",[1.8358662776216026, 48.51903123766697]],
    [ "Enedis",[1.8365901751845257, 48.52917746841626]],
    [ "Enedis",[1.8382950589811355, 48.51496878609698]],
    [ "Enedis",[1.8397630978896542, 48.51870886132879]],
    [ "Enedis",[1.8369971479068368, 48.51311630829538]],
    [ "Enedis",[1.833008021278161, 48.53225526164347]],
    [ "Enedis",[1.8342273290235978, 48.512431233615935]],
    [ "Enedis",[1.842306213758036, 48.51561889895469]],
    [ "Enedis",[5.2866726116708, 45.88423857633976]],
    [ "Enedis",[5.276773133468287, 45.89033740845705]],
    [ "Enedis",[5.283184927681833, 45.888415639900046]],
    [ "Enedis",[5.280325291586403, 45.88791741111809]],
    [ "Enedis",[-1.174102818891398, 46.633073746623644]],
    [ "Enedis",[-1.316447199999999, 47.96095385000001]],
    [ "Enedis",[-1.197880739176773, 46.6500724201659]],
    [ "Enedis",[-1.1520122500000003, 46.6182607]],
    [ "Enedis",[-1.1629577500000003, 46.63786075]],
    [ "Enedis",[-1.173067, 46.63628649999999]],
    [ "Enedis",[-1.183727615306402, 46.667144232558535]],
    [ "Enedis",[-1.1307703500000006, 46.61316545]],
    [ "Enedis",[-1.1724807499999998, 46.640102750000004]],
    [ "Enedis",[-1.1692306227985148, 46.63541262391852]],
    [ "Enedis",[-1.1781392924978396, 46.63693393238369]],
    [ "Enedis",[-1.1959254999999998, 46.667351999999994]],
    [ "Enedis",[-0.8382587241741334, 43.82592341115033]],
    [ "Enedis",[-2.0058985746613995, 48.04251414544357]],
    [ "Enedis",[-1.3205251013942, 46.887119083327356]],
    [ "Enedis",[-1.343275517026416, 46.87790249867961]],
    [ "Enedis",[-1.3566974165414374, 46.889484299191494]],
    [ "Enedis",[-1.3786331, 46.8770065]],
    [ "Enedis",[-1.3173289999999993, 46.8887475]],
    [ "Enedis",[-0.8545892812500001, 43.82714689583333]],
    [ "Enedis",[5.257412908335928, 43.34340759306551]],
    [ "Enedis",[-2.232072941894678, 48.14274035132464]],
    [ "Enedis",[-2.2186218392338177, 48.134634950198155]],
    [ "Enedis",[-2.224763313326188, 48.13137131296892]],
    [ "Enedis",[-2.219888072198778, 48.1312163731833]],
    [ "Enedis",[-1.3294969, 46.6673141]],
    [ "Enedis",[-1.284920999999999, 46.6749725]],
    [ "Enedis",[-1.30222575, 46.670128999999996]],
    [ "Enedis",[-1.2980332500000005, 46.676314000000005]],
    [ "Enedis",[-1.2882430000000002, 46.668845000000005]],
    [ "Enedis",[-1.2615615098274875, 46.69738563068377]],
    [ "Enedis",[-1.29184585, 46.6761696]],
    [ "Enedis",[-1.3392925000000002, 46.6659935]],
    [ "Enedis",[-1.29653355, 46.67036294999998]],
    [ "Enedis",[-1.3185985, 46.637532500000006]],
    [ "Enedis",[-1.29086325, 46.669093499999995]],
    [ "Enedis",[-1.3032792500000001, 46.67200625]],
    [ "Enedis",[-1.286660788170625, 46.70447252195546]],
    [ "Enedis",[-1.290345, 46.673274]],
    [ "Enedis",[-1.2861539999999996, 46.673098499999995]],
    [ "Charme",[4.574898045725646, 47.85423872597746]],
    [ "Enedis",[4.574992924583742, 47.85658728664707]],
    [ "Enedis",[6.045051356807512, 48.63818922065728]],
    [ "Enedis",[6.044050224893917, 48.641363603017446]],
    [ "Enedis",[6.05094977429626, 48.62986899681679]],
    [ "Enedis",[6.047608982248521, 48.633479721893494]],
    [ "Cimetière",[6.0523714, 48.636227749999996]],
    [ "Poste électrique de Cazaril",[0.5134818969399495, 43.17131924672593]],
    [ "Poste électrique de Les Jumeaux",[-0.07150839930046288, 46.80181624810027]],
    [ "Enedis",[-0.35755817829457387, 49.321062054263564]],
    [ "Enedis",[-0.34813099999999997, 49.31612700000001]],
    [ "Enedis",[-0.3475831716566869, 49.313961519247215]],
    [ "Enedis",[-0.34975666126855603, 49.31694023076923]],
    [ "Poste électrique de Portet Saint-Simon",[1.3868315893907246, 43.52816995641907]],
    [ "Enedis",[3.280625793878528, 50.40530054997609]],
    [ "Enedis",[3.282368600000001, 50.4035865]],
    [ "Enedis",[3.2919610432499233, 50.41013583101638]],
    [ "Enedis",[3.2699034861111116, 50.41331013888889]],
    [ "Enedis",[3.2776549999999993, 50.41089999999999]],
    [ "Poste électrique de Airvault",[-0.12514944915330536, 46.82407334781551]],
    [ "Poste électrique de la Dronnière",[-0.338888246868868, 49.13867509096743]],
    [ "R. Vercors",[5.932161125541127, 45.56052487445887]],
    [ "Poste électrique du Grand Verger",[5.908562549359247, 45.580418958962596]],
    [ "Enedis",[-1.3848178716762367, 46.186184116598696]],
    [ "La Blanche",[-1.3914903033817638, 46.18470272773185]],
    [ "La Caillette",[-1.39573505, 46.188088050000005]],
    [ "Laverie Guignardes",[-1.3816294500000006, 46.1816782]],
    [ "Le Morinant",[-1.3748758695263437, 46.18955253810344]],
    [ "Les Gollandieres",[-1.3926537912353336, 46.18287662167187]],
    [ "Le Petit Sergent",[-1.4005005264241248, 46.18215952625499]],
    [ "Etoiles",[-1.387066036471239, 46.18542171785052]],
    [ "Enedis",[-1.3691257827762728, 46.18923833767651]],
    [ "Les Ardillers",[-1.40201786718169, 46.18736544836305]],
    [ "Clos des Vignes",[-1.3788756966703468, 46.19028136270681]],
    [ "Le Polygone",[-1.4018876718118358, 46.1845774769866]],
    [ "GBU Soleil Levant",[-1.388252147138335, 46.18896732232142]],
    [ "Enedis",[-1.3969355328077104, 46.183396114760086]],
    [ "Les Islates",[-1.3864988234639004, 46.18862226692034]],
    [ "La Grange",[-1.3988065499999995, 46.185437]],
    [ "Les Pingettes",[-1.3886239262407039, 46.1815993321012]],
    [ "Enedis",[-1.3703734, 46.193215349999996]],
    [ "CBU La Poiziere",[-1.3743062, 46.1878022]],
    [ "Le Bailly",[-1.3935738, 46.18765315]],
    [ "Enedis",[-1.3787780993920071, 46.187748459165334]],
    [ "Enedis",[-1.3903486652866242, 46.183264145576786]],
    [ "Enedis",[-1.3814591147963216, 46.18750058465159]],
    [ "La Grouille",[-1.38835035, 46.183953849999995]],
    [ "Enedis",[-1.4061962855479482, 46.18688124758978]],
    [ "515 Av. des Landiers",[5.905620714074074, 45.588303903703704]],
    [ "Enedis",[5.8906375, 45.609396499999995]],
    [ "Poste électrique de Moreau",[2.390334633547345, 43.21138377496941]],
    [ "Poste électrique de Rognac",[5.21743030800389, 43.50726996530406]],
    [ "Enedis",[3.245509500000001, 50.43483700000001]],
    [ "Enedis",[3.212424340220386, 50.43563866969697]],
    [ "Enedis",[3.181280608923885, 50.44695637007874]],
    [ "Enedis",[3.2064740000000005, 50.435522999999996]],
    [ "Enedis",[3.1989752298213165, 50.451293532347506]],
    [ "Enedis",[-0.012311730342871054, 49.318394943026554]],
    [ "Enedis",[-0.015153684095860365, 49.31018369803922]],
    [ "Enedis",[0.00030732340425549217, 49.32237656453901]],
    [ "Enedis",[0.007445500000000001, 49.328453]],
    [ "Enedis",[-0.0026330781249999965, 49.31993241145834]],
    [ "Enedis",[0.0012640000000000001, 49.320226500000004]],
    [ "Enedis",[-0.00500360807123416, 49.31998976547466]],
    [ "Enedis",[0.007627153374233183, 49.3257977402863]],
    [ "Enedis",[0.020466549963385088, 49.30439364769323]],
    [ "Enedis",[-0.012965046744574176, 49.317078177518084]],
    [ "Enedis",[-0.010162486148346936, 49.31996741197497]],
    [ "Enedis",[0.0011407100186683194, 49.31748144285418]],
    [ "Enedis",[-0.002869994202898964, 49.32175351014493]],
    [ "Enedis",[0.009268255489021602, 49.32873916566867]],
    [ "Enedis",[0.005099853128990785, 49.31899269604086]],
    [ "Enedis",[0.007896132821824612, 49.32792485745949]],
    [ "Enedis",[0.006242299995782438, 49.32362631135236]],
    [ "Enedis",[0.003029942742524029, 49.32149583005106]],
    [ "Enedis",[0.0010875, 49.319524]],
    [ "Enedis",[-0.012709736848529232, 49.31415245270353]],
    [ "Enedis",[2.8494803319268636, 50.6979595066104]],
    [ "Enedis",[2.8420557492163017, 50.71591373719957]],
    [ "Enedis",[2.8527445, 50.688797499999986]],
    [ "Enedis",[2.8538552945736444, 50.699737785764604]],
    [ "Enedis",[2.8518725036001698, 50.70391394070308]],
    [ "Enedis",[2.8466902941087384, 50.69974231515978]],
    [ "Enedis",[2.8616947603245246, 50.69789182332516]],
    [ "Enedis",[2.8590014177949707, 50.696390336557066]],
    [ "Enedis",[2.843424, 50.70015649999999]],
    [ "Enedis",[0.034992440305343236, 49.33790332468193]],
    [ "Enedis",[0.036913728285077434, 49.32666772754269]],
    [ "Enedis",[0.037243236499641355, 49.33281044799673]],
    [ "Enedis",[0.03919450000000001, 49.328284000000004]],
    [ "Enedis",[0.038910669061466535, 49.334000507355455]],
    [ "Enedis",[0.03700495285380934, 49.32511563480234]],
    [ "Enedis",[0.03347851166407504, 49.33488446345256]],
    [ "Enedis",[0.029874663683818276, 49.33592698434005]],
    [ "Enedis",[0.025922701219512664, 49.337731930894314]],
    [ "Enedis",[0.03680367149390244, 49.33610200711382]],
    [ "Enedis",[0.027935310717039318, 49.33889596093549]],
    [ "Enedis",[0.035972721740804924, 49.33961395243597]],
    [ "Enedis",[0.029012953970662732, 49.337345652503785]],
    [ "Enedis",[0.0183515, 49.334067999999995]],
    [ "Enedis",[0.04035049337189681, 49.33043613449024]],
    [ "Enedis",[0.03714858900695241, 49.32962218627837]],
    [ "Enedis",[3.193448699218194, 50.48994704932481]],
    [ "Enedis",[3.2038623694199417, 50.481876319967924]],
    [ "Enedis",[3.206059330811901, 50.48119647352495]],
    [ "Enedis",[2.8889904999999994, 50.68677699999999]],
    [ "Enedis",[2.869587752986513, 50.689796130443156]],
    [ "Enedis",[2.8696439999999996, 50.6941445]],
    [ "Enedis",[2.8665161601423486, 50.68493148357671]],
    [ "Enedis",[2.8670782000000004, 50.693973]],
    [ "Enedis",[2.8736805, 50.68181799999999]],
    [ "Enedis",[2.895219572708291, 50.69454518650233]],
    [ "Enedis",[2.8914003190904545, 50.69849077586207]],
    [ "Enedis",[2.8716540483210484, 50.68214975020476]],
    [ "Enedis",[2.8805576929012346, 50.68643707578875]],
    [ "Enedis",[2.8759664465980213, 50.68096075005048]],
    [ "Enedis",[2.8843889702017402, 50.689821583194515]],
    [ "Enedis",[2.8744783175947024, 50.684432605448826]],
    [ "Enedis",[2.8635251865622955, 50.69447216699282]],
    [ "Enedis",[2.8905267444120506, 50.69495937738905]],
    [ "Enedis",[2.87104697997548, 50.69202107723743]],
    [ "Enedis",[2.8864823080216713, 50.67994467738553]],
    [ "Enedis",[2.8878944719898616, 50.704441135361215]],
    [ "Enedis",[3.1485246677646628, 50.48339750645073]],
    [ "Enedis",[2.888498420819491, 50.69313426024363]],
    [ "Enedis",[2.8934838198271193, 50.68641597082659]],
    [ "Enedis",[3.142210336219336, 50.482919978354964]],
    [ "Enedis",[2.8732415557920876, 50.69116294536101]],
    [ "Enedis",[3.155278292655852, 50.477628020217516]],
    [ "Enedis",[3.1326745000000003, 50.48551199999999]],
    [ "Enedis",[2.889724737197013, 50.68997570566794]],
    [ "Enedis",[3.148048168093385, 50.479500283268486]],
    [ "Enedis",[2.8700411457309154, 50.686890262812916]],
    [ "Enedis",[2.88804321627985, 50.700550524386145]],
    [ "Enedis",[3.1681235236460714, 50.48007936765828]],
    [ "Enedis",[2.8656431666666666, 50.68845416666666]],
    [ "Enedis",[2.8734470724637675, 50.694228797101445]],
    [ "Enedis",[2.888670631446541, 50.6884971836478]],
    [ "Enedis",[2.8831020881057254, 50.702637807635824]],
    [ "Enedis",[2.8840073727931013, 50.682344242128394]],
    [ "Enedis",[2.8834480000000005, 50.70441666666666]],
    [ "Enedis",[2.8809638256138395, 50.68172783212426]],
    [ "Enedis",[2.8821464999999997, 50.687940499999996]],
    [ "Enedis",[2.8848783162878786, 50.688358946969686]],
    [ "Enedis",[2.886995486215539, 50.692169909774435]],
    [ "Enedis",[2.875276000000001, 50.682198]],
    [ "Enedis",[3.1033501666666665, 50.48838516666666]],
    [ "Enedis",[3.075750838445808, 50.473419654396736]],
    [ "Enedis",[3.1217543926564257, 50.48515880129886]],
    [ "Enedis",[3.107666372004357, 50.481130885076254]],
    [ "Enedis",[4.540118926302184, 47.87097305473184]],
    [ "Enedis",[4.536386309198225, 47.87448850520615]],
    [ "Enedis",[5.911052915151514, 45.60602648282827]],
    [ "Enedis",[3.0905745000000002, 50.600784]],
    [ "Enedis",[3.086091834532374, 50.6102805059952]],
    [ "Enedis",[3.088325273221345, 50.59205041551383]],
    [ "Enedis",[3.093207432409739, 50.59747718527847]],
    [ "Enedis",[3.09239143319502, 50.59634918699862]],
    [ "Enedis",[3.094850839215267, 50.59849024415945]],
    [ "Enedis",[3.0935109913785017, 50.601766145815766]],
    [ "Enedis",[3.09294385, 50.5986407]],
    [ "Enedis",[3.095120370752354, 50.60427112780832]],
    [ "Enedis",[3.1009204733637747, 50.60047016286148]],
    [ "Enedis",[3.09488487452455, 50.60380933043741]],
    [ "Enedis",[3.083271669505498, 50.59458160719497]],
    [ "Enedis",[3.0832520261912886, 50.600192064494166]],
    [ "Enedis",[3.0772971901955977, 50.60761908756854]],
    [ "Enedis",[3.0873275, 50.595989]],
    [ "Enedis",[3.0760844295211487, 50.60402634393165]],
    [ "Enedis",[3.0849065424836604, 50.605814427741464]],
    [ "Enedis",[3.089800193473194, 50.60849933644134]],
    [ "Enedis",[3.095131813836479, 50.597191969811334]],
    [ "Enedis",[3.0923389999999995, 50.6006955]],
    [ "Enedis",[3.0889565436145334, 50.605111726496055]],
    [ "Enedis",[3.1064231975151775, 50.611350439926596]],
    [ "Enedis",[3.098219690022173, 50.59809251189949]],
    [ "Enedis",[3.0877515, 50.597432000000005]],
    [ "Enedis",[3.082914067237258, 50.61058586783869]],
    [ "Enedis",[3.092962912190881, 50.59349306315814]],
    [ "Enedis",[3.0787492053111376, 50.60921214783988]],
    [ "Enedis",[3.082654436534216, 50.61181655849889]],
    [ "Enedis",[3.087930348752186, 50.59965181688127]],
    [ "Enedis",[3.0939030000000005, 50.596124499999995]],
    [ "Enedis",[3.088052, 50.603224499999996]],
    [ "Enedis",[3.075512331716257, 50.61012082147477]],
    [ "Enedis",[3.0850266395698918, 50.5970190511828]],
    [ "Enedis",[3.0852280702654338, 50.60333665915101]],
    [ "Enedis",[3.079961500000001, 50.603735500000006]],
    [ "Enedis",[3.0980441890854706, 50.6013293424913]],
    [ "Enedis",[3.082225499999998, 50.596712499999995]],
    [ "Enedis",[3.0920775000000003, 50.60802749999999]],
    [ "Enedis",[3.0564416627906974, 50.47513601328904]],
    [ "Enedis",[3.0635248, 50.474262700000004]],
    [ "Enedis",[5.925418258255214, 45.597134475278985]],
    [ "Montagny Le Moulin",[5.923224107158485, 45.6076848341565]],
    [ "Enedis",[3.146965630584192, 50.346815597938146]],
    [ "Enedis",[3.1480326159122085, 50.35408328336273]],
    [ "Enedis",[3.1364217291666665, 50.34997604619565]],
    [ "Enedis",[3.1550656404734174, 50.34750871071012]],
    [ "Enedis",[3.1392309063545154, 50.35180181382386]],
    [ "Enedis",[3.1435502445810672, 50.3505214504611]],
    [ "Enedis",[3.1517580904109583, 50.34968016073059]],
    [ "Enedis",[3.146214184585547, 50.348281464878994]],
    [ "Enedis",[3.148002891674702, 50.35010475024108]],
    [ "Enedis",[3.1445914999999998, 50.343719]],
    [ "Enedis",[3.145193590211741, 50.352696659186]],
    [ "Enedis",[3.158577575686275, 50.34479135241829]],
    [ "Enedis",[3.1445029999999994, 50.34575199999999]],
    [ "Enedis",[3.149604985786801, 50.35162745702199]],
    [ "Enedis",[3.168403157781684, 50.312883236477255]],
    [ "Enedis",[3.1210018056691196, 50.28331545707279]],
    [ "Enedis",[3.0996110000000003, 50.282112500000004]],
    [ "Enedis",[3.116336, 50.282831]],
    [ "Enedis",[3.1009798400695345, 50.282826291612345]],
    [ "Enedis",[3.1045343622161665, 50.28365975603997]],
    [ "Enedis",[3.103393000000002, 50.278615]],
    [ "Enedis",[3.1094882075167787, 50.2765801550783]],
    [ "Enedis",[3.102827272727272, 50.280003090909084]],
    [ "Enedis",[3.1082934054283298, 50.27993863273961]],
    [ "Enedis",[3.119949499999999, 50.28396250000001]],
    [ "Enedis",[3.1106448744098203, 50.2821538177526]],
    [ "Enedis",[3.1098128193898065, 50.27858130282367]],
    [ "Enedis",[3.097179279843137, 50.28462558996078]],
    [ "Enedis",[3.1230865000000017, 50.28136899999999]],
    [ "Enedis",[2.5487948688524584, 50.48751735519126]],
    [ "Enedis",[2.5531242657396676, 50.47681304866744]],
    [ "Enedis",[2.5345630880906556, 50.481127254222784]],
    [ "Enedis",[2.561970193502825, 50.49804642278719]],
    [ "Enedis",[2.553620986157875, 50.47123383277217]],
    [ "Enedis",[2.555537419660142, 50.47098144787388]],
    [ "Enedis",[2.5541452946521286, 50.48432929023885]],
    [ "Enedis",[2.5852779999999993, 50.49661950000001]],
    [ "Enedis",[2.5387941226415105, 50.47399935191538]],
    [ "Enedis",[2.5584798005759537, 50.49652184449244]],
    [ "Enedis",[2.5419256667611756, 50.47953050222097]],
    [ "Enedis",[2.5338593490204904, 50.49667063401637]],
    [ "Enedis",[2.565289176733781, 50.48395365548098]],
    [ "Enedis",[2.579830042105263, 50.4988086245614]],
    [ "Enedis",[2.5376845000000015, 50.477211499999996]],
    [ "Enedis",[2.5479302564906776, 50.48393693448336]],
    [ "Enedis",[2.5441262419495216, 50.480676140121844]],
    [ "Enedis",[2.5429773333333334, 50.469092496124034]],
    [ "Enedis",[2.5617764999999997, 50.48297149999999]],
    [ "Enedis",[2.540488890089113, 50.46940127882545]],
    [ "Enedis",[2.5588352149901383, 50.4710746607495]],
    [ "Enedis",[2.5245727462686567, 50.48852606965175]],
    [ "Enedis",[2.5268667345895883, 50.4915465540296]],
    [ "Enedis",[2.5515758371845005, 50.468395171584305]],
    [ "Enedis",[2.5491015772167414, 50.49308434854819]],
    [ "Enedis",[2.5493442978768135, 50.480882722934616]],
    [ "Enedis",[2.5806727888728087, 50.49708332368549]],
    [ "Enedis",[2.5458633608264596, 50.469957816129295]],
    [ "Enedis",[2.5459485790754255, 50.47317663364616]],
    [ "Enedis",[2.561993396126761, 50.489093834507045]],
    [ "Enedis",[2.568908389179755, 50.49274380069807]],
    [ "Enedis",[2.543777002439521, 50.488842516771705]],
    [ "Enedis",[2.5605339638009053, 50.48646559276018]],
    [ "Enedis",[2.5485255429096685, 50.47682816829614]],
    [ "Poste électrique de Ginestous",[1.4117002848166087, 43.657880917475374]],
    [ "RTE",[1.4179798676070856, 43.61775500960586]],
    [ "Enedis",[2.5340296561954627, 50.47702594764398]],
    [ "Enedis",[2.5491450579710144, 50.46994084057972]],
    [ "Enedis",[2.5548455000000003, 50.496092000000004]],
    [ "Enedis",[2.5411486567131156, 50.47777352377793]],
    [ "Enedis",[2.5486280076252728, 50.48210206971679]],
    [ "Enedis",[2.571174415003867, 50.48319817092035]],
    [ "Enedis",[2.567611986535474, 50.48523533160712]],
    [ "Enedis",[2.5400406268521043, 50.48398532260703]],
    [ "Enedis",[2.5437535969715825, 50.47420458929682]],
    [ "Enedis",[2.5562021575663025, 50.47330531530595]],
    [ "Enedis",[2.536299974352996, 50.47915417315614]],
    [ "Enedis",[2.53234360120846, 50.47898780161128]],
    [ "Enedis",[2.583684523681858, 50.49484616175157]],
    [ "Enedis",[2.573239937937384, 50.489710562983426]],
    [ "Enedis",[2.560684659337438, 50.47736509527998]],
    [ "Enedis",[2.5389405420552897, 50.48144017158222]],
    [ "Enedis",[2.544640070495696, 50.48683612110419]],
    [ "Enedis",[2.5543836796230863, 50.479251136042414]],
    [ "Enedis",[2.5556856695855226, 50.48968848511384]],
    [ "Enedis",[2.558065082505519, 50.488150161975724]],
    [ "Enedis",[2.548231378760912, 50.48997469421136]],
    [ "Enedis",[3.1623087214496413, 50.26525065528866]],
    [ "Enedis",[3.1670084890193535, 50.26375832237359]],
    [ "Enedis",[3.212966763495953, 50.27233692455631]],
    [ "Enedis",[3.2077004692700846, 50.2717355586967]],
    [ "Enedis",[3.2150734192, 50.268068655466664]],
    [ "Enedis",[3.219053785512167, 50.26687436276175]],
    [ "Enedis",[3.2256556532516782, 50.26528694546515]],
    [ "Enedis",[0.05371318227046541, 49.34977861374943]],
    [ "Enedis",[0.04383602908805031, 49.34575210927673]],
    [ "Enedis",[0.048241036124795744, 49.347618906403945]],
    [ "Enedis",[0.051937257071866, 49.344606088302754]],
    [ "Enedis",[0.05302611128485822, 49.349384065676304]],
    [ "Enedis",[0.050220213221321953, 49.348705774477445]],
    [ "Enedis",[0.03773079084967298, 49.342222937908495]],
    [ "Enedis",[0.0530665, 49.33552099999999]],
    [ "Enedis",[2.860917838156484, 50.68124399142551]],
    [ "Enedis",[2.836086815450644, 50.67656457749164]],
    [ "Enedis",[2.859148565003283, 50.67753743204202]],
    [ "Enedis",[2.8404660630980074, 50.676328673648825]],
    [ "Enedis",[2.813840540689799, 50.66947884422215]],
    [ "Enedis",[2.8366146571979654, 50.67459774574784]],
    [ "Enedis",[2.8450943702127662, 50.678730160283685]],
    [ "Enedis",[2.862454643783371, 50.68296176201374]],
    [ "Enedis",[2.859381853678732, 50.680826970664775]],
    [ "Enedis",[2.8535398201859232, 50.67800443904383]],
    [ "Enedis",[3.198314159256468, 50.29084109620698]],
    [ "Enedis",[3.188941627906977, 50.28650078294574]],
    [ "Enedis",[3.1570786896551732, 50.291554961685826]],
    [ "Enedis",[3.1522113986866795, 50.28717723233271]],
    [ "Enedis",[3.1624801486117797, 50.29401135371529]],
    [ "Éducateur",[2.5768157857208775, 48.87968828304565]],
    [ "Chaillet",[2.5884073126730582, 48.873407170607024]],
    [ "PC Chilpéric",[2.588083612840467, 48.873948754215306]],
    [ "Enedis",[2.5841940006790836, 48.88028036903376]],
    [ "Enedis",[3.2437632706552706, 50.28548812535613]],
    [ "Enedis",[3.2419734901960773, 50.2865522745098]],
    [ "Friedland",[2.574805250207813, 48.879526150457195]],
    [ "Elisabeth",[2.574681081188026, 48.88434893442741]],
    [ "Opel",[2.5921972497834074, 48.870872162600584]],
    [ "DP Caillou",[2.581796712917422, 48.87801272016568]],
    [ "DP Sylvie",[2.5918527333333325, 48.86880780888888]],
    [ "RTE",[1.4799967971552372, 43.55374756950215]],
    [ "Meunier",[2.5873813126319427, 48.87056162330056]],
    [ "Patronage",[2.58070871875, 48.873256357638894]],
    [ "Chelles Beausejour",[2.585225776592836, 48.88078736778782]],
    [ "Enedis",[2.5832640117274166, 48.87215927823561]],
    [ "Besson",[2.5909206867657053, 48.877250159627145]],
    [ "Nouveau",[2.5761386997033853, 48.8771124660939]],
    [ "La Ragotterie",[-1.4306018670312999, 46.1960630397721]],
    [ "Enedis",[-1.4599120500000005, 46.211101000000006]],
    [ "Bois Henry IV",[-1.4438286499999997, 46.19920479999999]],
    [ "Les Brardes",[-1.4399639500000003, 46.19756834999999]],
    [ "Le Ventoux",[-1.4270738, 46.197058399999996]],
    [ "Square",[-1.4234318476788568, 46.195876183721225]],
    [ "Nouralene",[-1.4415653927672003, 46.19837630633625]],
    [ "Hortie",[-1.4346720775460429, 46.1966334456147]],
    [ "Anneries",[-1.4251925578573348, 46.19061596944038]],
    [ "Rue du Petit Noue",[-1.4334616, 46.19371530000001]],
    [ "Passage Réa",[-1.4377158999999995, 46.195466499999995]],
    [ "La Motte",[-1.4259319445157854, 46.19349972590653]],
    [ "Belle Rive",[-1.4480086968449941, 46.2016302770919]],
    [ "Daviere",[-1.46812405, 46.204088999999996]],
    [ "Enedis",[-1.4285645044044042, 46.19189741571571]],
    [ "Le fond des airs",[-1.4414355717845113, 46.20084326461953]],
    [ "Les Folies",[-1.4197897852890133, 46.191268790345916]],
    [ "La Couarde",[-1.421909765432099, 46.192880827160494]],
    [ "CBU Terroir",[-1.4255267999999999, 46.192553000000004]],
    [ "Goisil",[-1.4287578254184712, 46.20208880824269]],
    [ "Enedis",[-1.4233059999999993, 46.1943315]],
    [ "Zone Artisanale 2",[-1.4365812337837085, 46.19863980154766]],
    [ "Enedis",[-1.4550682085194604, 46.20311867784845]],
    [ "La Raigon",[-1.4218789189344851, 46.19703665183585]],
    [ "Enedis",[6.202211482708883, 48.706600586969444]],
    [ "Enedis",[6.21274370857754, 48.69850007583742]],
    [ "Enedis",[6.215771256042053, 48.7076847598449]],
    [ "Enedis",[6.2037088426145175, 48.70119118989959]],
    [ "Enedis",[6.212058517278689, 48.70273516646522]],
    [ "Enedis",[6.209771629834778, 48.703701570546606]],
    [ "Enedis",[6.201908629850234, 48.70189677448393]],
    [ "Enedis",[6.2039064477731, 48.71382776014612]],
    [ "Enedis",[6.206169421516865, 48.711928303667925]],
    [ "Enedis",[6.204415967141309, 48.70354049500399]],
    [ "Enedis",[6.206890005951091, 48.698528215045]],
    [ "Enedis",[6.20916461421684, 48.70637335452033]],
    [ "Enedis",[6.210794969547367, 48.70111895369105]],
    [ "Enedis",[6.209397385691135, 48.69926231676138]],
    [ "Les Fleuries",[6.294258829037799, 46.04665556013745]],
    [ "Central 6",[6.309654206508781, 46.06545767752537]],
    [ "Château de Chant 2",[6.3135003, 46.053832899999996]],
    [ "Les Biolles",[6.284503249999999, 46.035769249999994]],
    [ "Enedis",[6.308433999999999, 46.059010099999995]],
    [ "Château de Chant",[6.314223577786501, 46.05479164060565]],
    [ "Suffer",[6.303433331687244, 46.07252132510288]],
    [ "Enedis",[-1.835561189814815, 48.08079996296296]],
    [ "Pasteur",[6.310716750000001, 46.0648255]],
    [ "Enedis",[6.303850364066195, 46.07572964420804]],
    [ "Thabuis",[6.295881, 46.07968950000001]],
    [ "Cimetière",[-1.5167720000000002, 46.207002833333334]],
    [ "Radia",[-1.5280516000000002, 46.207494450000006]],
    [ "ZA",[-1.5058247297816687, 46.20686536754284]],
    [ "Les Ormeaux",[-1.5129224528486194, 46.20436683391963]],
    [ "CBU La Petite Grange",[-1.5165678, 46.2055442]],
    [ "Le Nez",[-1.5321484499999998, 46.20338965]],
    [ "Le Corneau",[-1.5115889973384107, 46.20520986652095]],
    [ "Enedis",[6.185686806211181, 48.73064236273293]],
    [ "Brise Marine",[-1.52117995, 46.2093944]],
    [ "Enedis",[6.185971677103264, 48.710112593030665]],
    [ "La Batterie",[-1.5322798, 46.22117625]],
    [ "Grignon",[-1.529302, 46.20302074999999]],
    [ "Motronne",[-1.524747982864868, 46.202533598415386]],
    [ "Enedis",[6.181435367379259, 48.71341808076009]],
    [ "Enedis",[6.1832455, 48.714489]],
    [ "Enedis",[-1.5269339890960953, 46.20400177401671]],
    [ "Enedis",[6.184533640628963, 48.717720248119036]],
    [ "Mission",[-1.5110281231292528, 46.20715218639455]],
    [ "Enedis",[-1.5270181, 46.20596935]],
    [ "Enedis",[0.06406852361396295, 49.32461892265571]],
    [ "Enedis",[6.189339283123203, 48.70568621256359]],
    [ "Enedis",[0.059140826913199815, 49.35177149306625]],
    [ "Enedis",[6.181068168881939, 48.70881027964555]],
    [ "Enedis",[6.19848841463222, 48.706586655526586]],
    [ "Enedis",[0.056411367027113024, 49.35220658094344]],
    [ "Enedis",[6.181695688292785, 48.7340195419006]],
    [ "Enedis",[0.08911014562002216, 49.315603850967]],
    [ "Enedis",[6.191325986724077, 48.71547368424829]],
    [ "Enedis",[6.184614703736743, 48.70711109743284]],
    [ "Enedis",[6.180843755541999, 48.715937532845224]],
    [ "Enedis",[6.18579391566265, 48.71437624497992]],
    [ "Enedis",[0.064553812814403, 49.33223251522373]],
    [ "Enedis",[6.192372882550336, 48.71313949440716]],
    [ "Enedis",[6.184163626103287, 48.71375838760564]],
    [ "Enedis",[0.06030632234647871, 49.352445968412795]],
    [ "Enedis",[0.06723894133054006, 49.35003976305221]],
    [ "Enedis",[0.055823037294170014, 49.350048721673346]],
    [ "Enedis",[6.1759824, 48.7155359]],
    [ "Enedis",[0.06302631266846326, 49.34921667026055]],
    [ "Enedis",[6.199030920555903, 48.71058129599669]],
    [ "Enedis",[6.181704247742994, 48.73399296599835]],
    [ "Enedis",[6.176199226387358, 48.71365154208013]],
    [ "Enedis",[0.06050900000000069, 49.3428195]],
    [ "Enedis",[6.190971287769784, 48.70812792805756]],
    [ "Enedis",[0.06171400967866819, 49.353012831204026]],
    [ "Enedis",[6.183542500000001, 48.708759]],
    [ "Enedis",[6.177960549395297, 48.72086091343932]],
    [ "Enedis",[6.188884443024733, 48.71878932309648]],
    [ "Enedis",[6.3386510000000005, 46.06702450000001]],
    [ "Enedis",[6.333656519901333, 46.070471764997684]],
    [ "Le Perthuis",[-1.4322357034271724, 46.22469988641371]],
    [ "Oiselliere",[-1.4433616935184777, 46.22460006961011]],
    [ "Enedis",[-1.4385506691021748, 46.222376752159384]],
    [ "Enedis",[-1.4297803934509994, 46.2286490574702]],
    [ "Église",[-1.4383591252252252, 46.224347285176094]],
    [ "CBU La Vette",[-1.440198062973681, 46.225446903265805]],
    [ "Loix Bourg",[-1.441832244354294, 46.22209873116971]],
    [ "Le Petit Boucheau",[-1.42626095, 46.227416149999996]],
    [ "Courolles",[-1.45858365, 46.2153985]],
    [ "Le Peulx",[-1.4347320742036511, 46.225428253344525]],
    [ "CBU Les Pecheurs",[-1.4319001500000001, 46.227626650000005]],
    [ "Les Minees",[-1.433542979768155, 46.22338423610927]],
    [ "Enedis",[-1.4373189868310396, 46.22390802144364]],
    [ "CUB Les Tourettes",[-1.440365966402326, 46.21745989806502]],
    [ "Enedis",[6.324996785942064, 46.046507069588316]],
    [ "Enedis",[-1.31704575, 46.1824095]],
    [ "L'Ardiller",[-1.3241608999999999, 46.1826913]],
    [ "Les marais",[-1.3130987235385327, 46.186237804017395]],
    [ "Les Pelletantes",[-1.3367022640271644, 46.18780099113393]],
    [ "Enedis",[-1.3325332496033864, 46.190999996206294]],
    [ "Balnéothérapie",[-1.3175538165137608, 46.186248618456546]],
    [ "Bel Air",[-1.3322638853503121, 46.18086809312083]],
    [ "Enedis",[-1.32818875, 46.18914115]],
    [ "La serpente",[-1.3348788947078285, 46.193281390481445]],
    [ "La Touche",[-1.3337637004940832, 46.189711525706684]],
    [ "Enedis",[-1.339366, 46.189594]],
    [ "Enedis",[-1.3366652999999997, 46.19324230000001]],
    [ "Enedis",[-1.329373949999999, 46.1833018]],
    [ "Enedis",[-1.3317417404316525, 46.18766877976354]],
    [ "Moulin Blanc",[-1.333306823489479, 46.186542714188725]],
    [ "La casse",[-1.3100154173448506, 46.18494791921544]],
    [ "Enedis",[-1.3215487750068813, 46.186380605973035]],
    [ "Île Blanche",[-1.3507623572798295, 46.189628970070686]],
    [ "Airmorin",[-1.3268683099839136, 46.185723443467374]],
    [ "La Grainetiere",[-1.3430795500000001, 46.1894437]],
    [ "Le Lizet",[-1.3175716436170215, 46.18493399012158]],
    [ "Enedis",[-1.3252221077046582, 46.1865020569603]],
    [ "Enedis",[-1.3016237672605486, 46.18690765110164]],
    [ "Pantouflette",[-1.3304490000000002, 46.18826249999999]],
    [ "La Pree",[-1.29265030362173, 46.18036239691481]],
    [ "Roses Tremieres",[-1.3363828000000006, 46.19159395]],
    [ "Zone Artisanale La Flotte",[-1.3238896418383674, 46.18069695333266]],
    [ "Les Comtesses",[-1.331717495479123, 46.184011490654825]],
    [ "Enedis",[-1.3371490000000001, 46.189717]],
    [ "Enedis",[-1.3404953850179913, 46.18800365685312]],
    [ "Enedis",[-1.3175175000000001, 46.181566499999995]],
    [ "Les mottes",[-1.3108908450573402, 46.18870328116018]],
    [ "Usine Electrique",[-1.330211242248775, 46.18582313335847]],
    [ "Enedis",[-1.3278287638212447, 46.19008558963078]],
    [ "Chanterelle",[-1.4922296445556946, 46.24895732874427]],
    [ "Les Portes",[-1.5007771974110036, 46.24912933656957]],
    [ "Enedis",[-1.4978, 46.252809000000006]],
    [ "Moulin du Roc",[-1.4993472499999998, 46.2465665]],
    [ "Trousse Chemise",[-1.4816723033605823, 46.23233303814714]],
    [ "Levee Verte",[-1.4849240259713703, 46.24020757983327]],
    [ "Les Fourneau",[-1.4845547999999997, 46.238646599999996]],
    [ "La Redoute",[-1.485022184549356, 46.24768002288984]],
    [ "La Platriere",[-1.4879281292073832, 46.248062119280284]],
    [ "La Grande Plante",[-1.4943025443132842, 46.255201811691954]],
    [ "La Riviere",[-1.512885, 46.2479805]],
    [ "La Filatte",[-1.5020863299565839, 46.253147242643514]],
    [ "La Barre",[-1.4924362999999998, 46.2459177]],
    [ "Villeneuve",[-1.5054191765765763, 46.24821103333333]],
    [ "Les Salines",[-1.4952952882404953, 46.24734189036252]],
    [ "Les Cytons",[-1.4897485000000001, 46.25136950000001]],
    [ "Enedis",[-1.5011725314034199, 46.256958341628575]],
    [ "Le Golf",[-1.4856543999999998, 46.23362795]],
    [ "Place de la Françoise",[-1.5011829472870617, 46.24972214185604]],
    [ "Bergeronnettes",[-1.4969692500000005, 46.247918]],
    [ "La Loge",[-1.4835832434753882, 46.24264407928642]],
    [ "Gibouleau",[-1.4948540000000003, 46.248360999999996]],
    [ "Rente du Fier",[-1.4829185, 46.2367335]],
    [ "Enedis",[-1.493077743659421, 46.251304907608706]],
    [ "Enedis",[-1.1416156666666668, 48.26692751111111]],
    [ "La Redoute",[-1.27055464962553, 46.156678283279525]],
    [ "Les Coulisses",[-1.2719312127659574, 46.15493166666666]],
    [ "CBU Les Palmes",[-1.279383344052156, 46.16167368155767]],
    [ "Les Peux",[-1.2709488000000002, 46.15707145000001]],
    [ "Enedis",[-1.2782976499999983, 46.157624600000005]],
    [ "Les Bragauds",[-1.2903120000647925, 46.16775871872336]],
    [ "Le Fond du Marais",[-1.2830374792616714, 46.158700404017374]],
    [ "Enedis",[-1.2834343428527557, 46.170208735759246]],
    [ "Enedis",[-1.7689138821814132, 47.70793956299982]],
    [ "Rue du Rhin",[-1.5398174999999998, 46.2238945]],
    [ "Enedis",[-1.5509734592994573, 46.2290088385134]],
    [ "La Mardelle",[-1.5526225000000002, 46.236723500000004]],
    [ "Le Reveil",[-1.5596181765339077, 46.23961192500898]],
    [ "Arche de Noé",[-1.554672, 46.240047]],
    [ "Les Perouses",[-1.554656834548696, 46.2379514970021]],
    [ "Le Chabot",[-1.542239210180624, 46.22906213441238]],
    [ "La Tricherie",[-1.5380553465227818, 46.225829676258996]],
    [ "Les Rentiers",[-1.5440835000000002, 46.22886825]],
    [ "Les Doraux",[-1.5582515000000001, 46.237695499999994]],
    [ "La Cure",[-1.553514, 46.242585500000004]],
    [ "Rue du Chaume",[-1.5531836230769227, 46.24018456666667]],
    [ "Le Godinand",[-1.5438144999999999, 46.232366]],
    [ "Gillieux",[-1.5491485432616667, 46.23748431218318]],
    [ "Les Volets",[-1.548038211453744, 46.2287822143906]],
    [ "Relevement",[-1.540522495808566, 46.22609916567596]],
    [ "Enedis",[3.4911030000000007, 50.358406499999994]],
    [ "Enedis",[3.5130019149425293, 50.367722321839075]],
    [ "Enedis",[3.5069481597444097, 50.361125179978686]],
    [ "Enedis",[3.5023711299249536, 50.35089446685428]],
    [ "Enedis",[3.500178928221133, 50.351074084262144]],
    [ "Enedis",[3.5119800157814645, 50.362158946801976]],
    [ "Enedis",[3.5097155567651637, 50.35035124713027]],
    [ "Enedis",[3.495064, 50.352878088888886]],
    [ "Enedis",[3.5146234999999995, 50.35376349999999]],
    [ "Enedis",[3.4887892197921517, 50.360204644020484]],
    [ "Enedis",[3.514375757807235, 50.35100140335504]],
    [ "Enedis",[3.509683329033718, 50.342167849513466]],
    [ "Enedis",[3.515456025143142, 50.33973897983571]],
    [ "Enedis",[3.4974442037437, 50.34961414182865]],
    [ "Enedis",[3.503929901454437, 50.3569513280647]],
    [ "Enedis",[3.4949511640211623, 50.35752975132275]],
    [ "Enedis",[3.5126325848909645, 50.350362788551394]],
    [ "Enedis",[3.502737, 50.3443935]],
    [ "Enedis",[3.5060516475996684, 50.35539552196851]],
    [ "Enedis",[3.4956852549244593, 50.36186684700708]],
    [ "Enedis",[3.491944171521036, 50.36156649406689]],
    [ "Enedis",[3.4932931160779983, 50.361949688925684]],
    [ "Enedis",[3.5048028049509186, 50.35787053649167]],
    [ "Enedis",[3.5003328884390457, 50.352607698605496]],
    [ "RTE",[1.403995767265902, 43.69318500125648]],
    [ "Enedis",[-1.3621896138268823, 46.20119440830744]],
    [ "Le Couronneau",[-1.3534643195026377, 46.20277955360709]],
    [ "La Cible",[-1.3538547807414962, 46.201179946865516]],
    [ "Hopital",[-1.3675109612903222, 46.2015209003778]],
    [ "Les Justices",[-1.3552255693299131, 46.20009586611996]],
    [ "Enedis",[-1.3790679999999995, 46.200005700000006]],
    [ "Pasteur",[-1.3730209, 46.2033911]],
    [ "Enedis",[-1.3670806305995047, 46.2045243456386]],
    [ "Enedis",[-1.3530688, 46.196161399999994]],
    [ "Enedis",[-1.352191157554475, 46.198236348442286]],
    [ "Les Egaux",[-1.35983375, 46.198344999999996]],
    [ "Clos Gilson",[-1.3658804185303506, 46.20382430138445]],
    [ "Poudriere",[-1.3641009, 46.199873950000004]],
    [ "Enedis",[-1.370863755457227, 46.20043091976401]],
    [ "Ceiros",[-1.35718675, 46.189780750000004]],
    [ "Enedis",[-1.3688025000000001, 46.203052363333335]],
    [ "Arsenal",[-1.365105, 46.20576175]],
    [ "Enedis",[2.576331750000001, 48.72900335]],
    [ "Enedis",[2.5747774999999997, 48.714542249999994]],
    [ "Enedis",[2.5707997500000004, 48.727398]],
    [ "Enedis",[2.570030359987807, 48.72027914457075]],
    [ "Enedis",[2.5681022499999995, 48.71760849999999]],
    [ "Enedis",[2.5741384999999997, 48.71874825]],
    [ "Enedis",[2.571227232340364, 48.72241740607464]],
    [ "Enedis",[2.5746130000000003, 48.72650349999999]],
    [ "Poste électrique d'Amiens",[2.294152786138498, 49.912067612222245]],
    [ "L'Oisiere",[-1.3353492528032564, 46.15435125496975]],
    [ "CBU Mur Auger",[-1.3076834184501847, 46.1505217297663]],
    [ "Village",[-1.3148770967097543, 46.15135828370544]],
    [ "Petit Labat",[-1.3089124500000004, 46.1465671]],
    [ "Tonnelle",[-1.3342075549088195, 46.156856023462545]],
    [ "Port Notre Dame",[-1.3000950000000002, 46.14656249999999]],
    [ "Enedis",[-1.3359775000000007, 46.152713000000006]],
    [ "Enedis",[-1.3259570000000003, 46.153303199999996]],
    [ "ZAC des Clémorinands",[-1.3328859737358896, 46.1609164415985]],
    [ "Enedis",[-1.3564169208833046, 46.158620760765274]],
    [ "La Ferlandiere",[-1.3210246102636545, 46.15398586704332]],
    [ "La Vernaude",[-1.3288509751737836, 46.157517176100626]],
    [ "La Malette",[-1.3111375171521036, 46.15178628770227]],
    [ "Les Grenettes",[-1.359337973476709, 46.161329536625324]],
    [ "Bois Salé",[-1.32946075, 46.154011499999996]],
    [ "Les Jaulaines",[-1.3121670922959507, 46.14631727157272]],
    [ "111276881",[2.82951257943606, 44.15964638914151]],
    [ "Enedis",[-1.3161915999999998, 46.153184149999994]],
    [ "Enedis",[-1.348546387484751, 46.156430721111875]],
    [ "La Cailletiere",[-1.3276773335071537, 46.15525294804452]],
    [ "HLM",[-1.3309133750000004, 46.158533614583334]],
    [ "Les Tilleuls",[-1.3329379947753393, 46.15565619278996]],
    [ "Enedis",[-1.3527262000000002, 46.157144200000005]],
    [ "Galinees",[-1.3167737219103575, 46.155244258802774]],
    [ "Les Fregonds Sud",[-1.352128527377521, 46.1619321104707]],
    [ "Enedis",[6.097538016355904, 49.30261932613673]],
    [ "Marie thérèse",[6.1022727483169845, 49.31387575995383]],
    [ "Enedis",[6.1026375, 49.302535000000006]],
    [ "Enedis",[6.105809187793427, 49.30275013849765]],
    [ "Enedis",[6.097477544103392, 49.313701319332246]],
    [ "Enedis",[-0.03227756787983855, 49.30938268399768]],
    [ "Enedis",[-1.3780755089319652, 45.97448416381604]],
    [ "Enedis",[-1.343993667098166, 45.97726700496224]],
    [ "Enedis",[-1.376848, 45.9597305]],
    [ "Enedis",[-1.3283085000000001, 45.981461499999995]],
    [ "Enedis",[-1.3127769999999999, 45.988171]],
    [ "Enedis",[-1.351903590570289, 45.97280883827354]],
    [ "Enedis",[-1.3784353104855167, 45.971917519787844]],
    [ "Enedis",[-1.345568918661117, 45.9741830541103]],
    [ "Enedis",[-1.356105091659029, 45.971867300336086]],
    [ "Enedis",[-1.8609477000000008, 47.71837990000001]],
    [ "Enedis",[-1.3356028360477739, 45.93390152117265]],
    [ "Enedis",[-1.2948367798474363, 45.93966310750398]],
    [ "Enedis",[-1.314738629353234, 45.94954587313432]],
    [ "Enedis",[-1.3056494999999986, 45.93538849999999]],
    [ "Enedis",[-1.3336063333333332, 45.92372546405228]],
    [ "Enedis",[-1.298640604651163, 45.94680624031008]],
    [ "Enedis",[-1.3158683464052279, 45.94582401960785]],
    [ "Enedis",[-1.2967804775536769, 45.941219253957925]],
    [ "Enedis",[-1.3195720270845428, 45.917760332559496]],
    [ "Enedis",[-1.3100371615035107, 45.91410099339116]],
    [ "Enedis",[-1.2997269384615386, 45.94083734615384]],
    [ "Enedis",[-1.3285475653128436, 45.92105830113429]],
    [ "Enedis",[-1.3129755801589473, 45.94584130620262]],
    [ "Enedis",[-1.3285493917394464, 45.91725555731442]],
    [ "111367482",[-1.3031133134986226, 45.9435069785124]],
    [ "Enedis",[-1.3256468000000001, 45.9151467]],
    [ "Enedis",[-1.3085906093348036, 45.93840817089305]],
    [ "Enedis",[-1.3323508397085608, 45.92144995154826]],
    [ "Enedis",[-1.3308249629629632, 45.9167758425926]],
    [ "Enedis",[-1.3180503357766142, 45.94395795357766]],
    [ "Enedis",[-1.2884701716312061, 45.933620665856125]],
    [ "Enedis",[-1.3268157330016583, 45.919115351575456]],
    [ "Enedis",[-1.3010615000000005, 45.943216]],
    [ "Enedis",[-1.3029553079178884, 45.940586966764414]],
    [ "Enedis",[-1.338087945063879, 45.91909989128919]],
    [ "Enedis",[-1.2975975641025639, 45.937836794871785]],
    [ "Enedis",[-1.2981716838721877, 45.93621852051427]],
    [ "Enedis",[-1.314042131840796, 45.93976737064676]],
    [ "Enedis",[3.2167212513661205, 50.18979639562841]],
    [ "Enedis",[3.2280405, 50.192065]],
    [ "Enedis",[3.21863972847682, 50.188448853078235]],
    [ "Enedis",[3.216571381598793, 50.18544735143288]],
    [ "Enedis",[3.217996587616471, 50.18200778689509]],
    [ "Enedis",[3.208724333333333, 50.19325105454545]],
    [ "Enedis",[3.213997499999999, 50.1893085]],
    [ "Enedis",[3.2238698761855487, 50.187711345404374]],
    [ "Enedis",[3.2180501935137458, 50.1811169548969]],
    [ "Enedis",[3.227389919871795, 50.18742237820512]],
    [ "Enedis",[4.906511000505184, 44.8553505872025]],
    [ "Enedis",[4.913998653972521, 44.85620373050933]],
    [ "Enedis",[4.911275, 44.85591335]],
    [ "Enedis",[4.909900607512373, 44.85564992434315]],
    [ "RTE",[1.2755336583151953, 43.679683631818484]],
    [ "Poste électrique de Marsillon",[-0.5954595220442903, 43.38588681989842]],
    [ "Poste électrique de Floirac",[-0.5241767558227751, 44.820953941308154]],
    [ "Poste électrique de Saucats",[-0.6136875659120723, 44.6141079212705]],
    [ "Poste électrique de Cantegrit",[-0.8874731635478581, 44.01307996889379]],
    [ "Enedis",[5.4789448, 46.846583800000005]],
    [ "Poste électrique de Argia",[-1.4301433844915779, 43.421398450782135]],
    [ "Poste électrique de Mouguerre",[-1.44147083534767, 43.48444364321335]],
    [ "Enedis",[0.09360881500339445, 49.33883407739308]],
    [ "Enedis",[0.09092199999999999, 49.338367833333336]],
    [ "Enedis",[0.08187860000000041, 49.32757135000001]],
    [ "Enedis",[0.10211277065923842, 49.339448848653674]],
    [ "Enedis",[0.10007747780468124, 49.34050533979015]],
    [ "Enedis",[0.08540833264980632, 49.343244510594666]],
    [ "Enedis",[0.08902335193798448, 49.33564356744186]],
    [ "Enedis",[0.09984903426791299, 49.3393501152648]],
    [ "Enedis",[0.09195683213429257, 49.319696007194246]],
    [ "Enedis",[0.0900485, 49.33319649999999]],
    [ "Enedis",[0.08900822218759834, 49.339810372390154]],
    [ "Enedis",[0.08301667219152854, 49.344089063535904]],
    [ "Enedis",[0.08707181998919503, 49.33129553019989]],
    [ "Enedis",[0.08417524206661497, 49.34591765460268]],
    [ "Enedis",[0.09619267901234563, 49.34781275925926]],
    [ "Enedis",[0.11877750427350398, 49.35047184371185]],
    [ "Enedis",[0.09535698259623256, 49.3558383396806]],
    [ "Enedis",[0.11377637948501419, 49.36608072083861]],
    [ "Enedis",[0.10039078372014769, 49.34790683249243]],
    [ "Enedis",[0.11247009708737843, 49.34953979611652]],
    [ "Enedis",[0.10523036800739705, 49.340270315765146]],
    [ "Enedis",[0.10120570588235266, 49.34728020915033]],
    [ "Enedis",[0.1081544496572837, 49.34923916157583]],
    [ "Enedis",[0.09418683188908146, 49.35469349046795]],
    [ "Enedis",[0.10109549999999999, 49.350668500000005]],
    [ "Enedis",[0.11405183316945268, 49.36067750606359]],
    [ "Enedis",[0.10619200000000001, 49.346418666666665]],
    [ "Enedis",[0.09814013798136284, 49.356986153025716]],
    [ "Enedis",[0.09781119075144509, 49.35359958101243]],
    [ "Enedis",[0.11159031902206325, 49.350663337507456]],
    [ "Enedis",[0.09930909978768603, 49.35242573673037]],
    [ "Enedis",[0.09915391587200656, 49.347163796990884]],
    [ "Enedis",[0.10164623519458514, 49.34564461252115]],
    [ "Enedis",[0.09291267359907567, 49.354893514153666]],
    [ "Enedis",[0.09915667674381955, 49.35407215304676]],
    [ "Enedis",[0.11110245855012164, 49.36434641900797]],
    [ "Enedis",[0.09618629077072798, 49.34521283966022]],
    [ "Enedis",[0.0960395, 49.357397500000005]],
    [ "Enedis",[0.10144650000000001, 49.35118249999999]],
    [ "Enedis",[0.09461778917975527, 49.3479942565445]],
    [ "Enedis",[0.10454511760101765, 49.35157079943254]],
    [ "Enedis",[0.0982677567567569, 49.34328114414415]],
    [ "Enedis",[0.09897554307116348, 49.342360315022894]],
    [ "Enedis",[6.088069472832705, 46.3486799988989]],
    [ "Blé en herbe",[6.073079706309325, 46.29595234505862]],
    [ "Enedis",[6.067738298194239, 46.30050597852611]],
    [ "Enedis",[6.076823, 46.298930999999996]],
    [ "Enedis",[6.078005465629053, 46.2966188300908]],
    [ "Ségny",[6.081052231977558, 46.29008655429406]],
    [ "La Rogeraie",[6.066425655480982, 46.29649454362416]],
    [ "Enedis",[3.7610846531661846, 50.08424696335583]],
    [ "Serop",[6.613632223529411, 46.03949405490196]],
    [ "Enedis",[6.625371853263097, 46.01366690529704]],
    [ "Enedis",[6.626817690273859, 46.01200497633526]],
    [ "Enedis",[6.631729490542723, 45.99291373838764]],
    [ "Pre devant",[6.613312421671527, 46.03909105694849]],
    [ "Enedis",[6.631866299999997, 45.98298369999999]],
    [ "Enedis",[5.4535128450399055, 46.86365200419376]],
    [ "Enedis",[2.101736328594358, 48.879774243674994]],
    [ "Enedis",[2.0966866152941157, 48.868389559215686]],
    [ "Enedis",[2.1038455263658866, 48.876348167713694]],
    [ "Enedis",[2.1010615300341065, 48.875760888146445]],
    [ "Enedis",[2.096362669321747, 48.872069372194055]],
    [ "111764080",[6.697436650187266, 45.20247118277154]],
    [ "111764189",[6.702725150000001, 45.201539749999995]],
    [ "111764192",[6.695657583882094, 45.201755706992785]],
    [ "111764473",[6.6990626714758275, 45.20173225371142]],
    [ "111764475",[6.696066586023963, 45.20174501653154]],
    [ "Régie Villarodin",[6.706671679499659, 45.210409834608136]],
    [ "111765245",[6.704494072727082, 45.20208080418719]],
    [ "Poste électrique de Serre-Ponçon",[6.267792938611846, 44.469422616884025]],
    [ "Enedis",[0.23810301812865464, 45.90746340994152]],
    [ "Poste électrique de Flers",[-0.5518117197136838, 48.73694107450621]],
    [ "Enedis",[2.5589886, 48.83997099999999]],
    [ "Enedis",[2.549978527686603, 48.84861265830692]],
    [ "Enedis",[2.5506768336186396, 48.84799726924995]],
    [ "Enedis",[-1.7572822500000003, 46.69505674999999]],
    [ "Enedis",[-1.7317909999999987, 46.702171]],
    [ "Enedis",[-1.7078152432006584, 46.71531573046416]],
    [ "Enedis",[-1.7328900000000003, 46.70284949999999]],
    [ "Enedis",[-1.7526050000000004, 46.695356749999995]],
    [ "Enedis",[-1.7534820000000004, 46.698330250000005]],
    [ "Enedis",[-1.7573979999999998, 46.70421425000001]],
    [ "Enedis",[-1.7602395500000008, 46.69683675]],
    [ "Enedis",[-1.75709925, 46.70150575]],
    [ "Enedis",[2.5954905500000014, 48.781182400000006]],
    [ "Enedis",[2.600176399999999, 48.80642799999999]],
    [ "Enedis",[2.61042475, 48.79011225]],
    [ "Enedis",[2.6107879133578433, 48.7783090766544]],
    [ "Enedis",[2.61629975, 48.79536175]],
    [ "Enedis",[2.609330296267018, 48.80705323276241]],
    [ "Enedis",[2.6088325, 48.78499]],
    [ "Enedis",[2.6124399721449136, 48.790688843999405]],
    [ "Enedis",[2.6025164999999997, 48.781476000000005]],
    [ "Enedis",[2.5955677017214693, 48.8070919692157]],
    [ "Enedis",[2.5882683669633955, 48.77729523369801]],
    [ "Enedis",[2.596645705837424, 48.77963352394982]],
    [ "Enedis",[2.6162520000000002, 48.79871225]],
    [ "Enedis",[2.61525875, 48.78775575000001]],
    [ "Enedis",[2.59971666232303, 48.779838172635316]],
    [ "Enedis",[2.6102434999999997, 48.803723049999995]],
    [ "Enedis",[2.6005072, 48.777526200000004]],
    [ "Enedis",[2.6048043500000007, 48.79167429999999]],
    [ "Enedis",[2.6096891335387773, 48.79993690498202]],
    [ "Enedis",[2.6068952415964786, 48.80389365338796]],
    [ "Enedis",[2.6154950000000006, 48.786697499999995]],
    [ "Enedis",[2.61496925, 48.79444200000001]],
    [ "Enedis",[2.594075695915725, 48.78395253297236]],
    [ "Enedis",[2.613181125423729, 48.7887236819209]],
    [ "Enedis",[2.6084957401496265, 48.786834053699074]],
    [ "Enedis",[2.590150477570739, 48.782028745341606]],
    [ "Enedis",[2.5974445604161147, 48.80858156801551]],
    [ "Enedis",[2.6034100000000002, 48.7778335]],
    [ "Enedis",[2.6013699783783, 48.778264910834075]],
    [ "Enedis",[2.59114925, 48.78085875000001]],
    [ "Enedis",[2.6174530000000007, 48.80297125]],
    [ "Enedis",[2.6156924999999993, 48.78933325]],
    [ "Enedis",[2.61289575, 48.8005745]],
    [ "Enedis",[2.593487999999999, 48.780746]],
    [ "Enedis",[2.6051797, 48.807445650000005]],
    [ "Enedis",[2.614675656565656, 48.78307631313132]],
    [ "Enedis",[2.6130607500000003, 48.79839150000001]],
    [ "Enedis",[2.6007280257191203, 48.79499566937393]],
    [ "Enedis",[2.6031165, 48.789240500000005]],
    [ "Enedis",[2.597791945433658, 48.787789530538014]],
    [ "Enedis",[2.146855006044122, 48.900760667119975]],
    [ "Enedis",[2.16517695, 48.902590399999994]],
    [ "Enedis",[2.1629100376359074, 48.90477604321159]],
    [ "Enedis",[2.1562846264815856, 48.88711164093301]],
    [ "Enedis",[-0.14294540671908493, 49.28386091827496]],
    [ "Enedis",[-0.15428632280701698, 49.286592175730995]],
    [ "Enedis",[-0.15512823690204952, 49.28413126044039]],
    [ "Enedis",[-0.14190450000000163, 49.286372500000006]],
    [ "Enedis",[-1.6175028662381392, 49.62825622773186]],
    [ "Enedis",[-1.6114782740550706, 49.63915240539959]],
    [ "Enedis",[-1.6275585434295503, 49.63063905179913]],
    [ "Enedis",[-1.6550294999999997, 49.63150649999999]],
    [ "Enedis",[-1.6389778405058364, 49.637368556641626]],
    [ "RUE ASSELIN",[-1.6358850791366897, 49.641722187050355]],
    [ "Enedis",[-1.6339709999999987, 49.64161599999999]],
    [ "Enedis",[-1.6296591800411526, 49.637567806584364]],
    [ "Enedis",[-1.6116647307996832, 49.64057253595336]],
    [ "Enedis",[-1.6053530000000003, 49.63819649999999]],
    [ "Enedis",[-1.6399956818181813, 49.631418143939385]],
    [ "Enedis",[-1.6520659872188133, 49.62981069785276]],
    [ "Enedis",[-1.6484756684961575, 49.628879993779734]],
    [ "Enedis",[-1.6077248214898723, 49.639094370408515]],
    [ "Enedis",[-1.64307272066459, 49.63120533748701]],
    [ "Enedis",[-1.622308882360521, 49.639966429970364]],
    [ "Enedis",[-1.6238333724212823, 49.64166497176981]],
    [ "Enedis",[-1.6052585000000001, 49.640496999999996]],
    [ "Enedis",[-1.6443837147258167, 49.638982836105974]],
    [ "Enedis",[-1.6418845999999998, 49.6360173]],
    [ "Enedis",[-1.6065957931847836, 49.64023079532336]],
    [ "Enedis",[-1.6278832180173213, 49.63367310082671]],
    [ "Enedis",[-1.6168311192660543, 49.639536788990824]],
    [ "Enedis",[2.978777733453463, 50.25910270597031]],
    [ "Enedis",[2.981304094299492, 50.26193067404573]],
    [ "Enedis",[2.1186595880315564, 48.747260231607974]],
    [ "Poste électrique de Champfleur",[0.1055195377818433, 48.37461656931115]],
    [ "Poste Électrique de Chevain",[0.1377332891691247, 48.42026989875622]],
    [ "Poste Électrique d'Alençon",[0.05738335985483999, 48.432519967783584]],
    [ "Enedis",[-1.579024790842064, 49.63706516259216]],
    [ "112194331",[-1.5960818221448623, 49.637648758241056]],
    [ "Enedis",[-1.5827646285714283, 49.6329399047619]],
    [ "Enedis",[-1.5997485, 49.64237399999999]],
    [ "Enedis",[-1.565972729927008, 49.63943384914842]],
    [ "Enedis",[-1.5773119999999996, 49.641709502067066]],
    [ "Enedis",[-1.5928443681522757, 49.63591732915507]],
    [ "Enedis",[-1.582547325581395, 49.64094066666667]],
    [ "Enedis",[-1.602150587124384, 49.64128225248861]],
    [ "Enedis",[-1.5724642084652358, 49.63828211408137]],
    [ "Enedis",[-1.5837819999999994, 49.645826]],
    [ "Enedis",[-1.5941114999999997, 49.63766999999999]],
    [ "Enedis",[-1.5839357780116956, 49.6366830437427]],
    [ "Enedis",[2.2579040514776945, 48.81240181306272]],
    [ "Enedis",[-1.5955230210526317, 49.64736432982456]],
    [ "Enedis",[-1.5695191927582532, 49.645237228967]],
    [ "Enedis",[2.2683490387225445, 48.806406187145924]],
    [ "Enedis",[-1.5861461658523346, 49.63726651330076]],
    [ "Enedis",[-1.5802071673134972, 49.650116990513155]],
    [ "Enedis",[2.270201965590141, 48.80712972222559]],
    [ "Enedis",[-1.5700958157894753, 49.64308985087719]],
    [ "Enedis",[-1.5776273075608718, 49.63572174654706]],
    [ "Enedis",[-1.5747235000000002, 49.650525]],
    [ "Valpré",[-0.2574775, 49.28156349999999]],
    [ "Mairie",[-0.25843674080996887, 49.277216904672905]],
    [ "Base nautique",[-0.27308994594594593, 49.29063464864864]],
    [ "Route de Lion",[-0.267981500000001, 49.286779]],
    [ "Enedis",[-1.5903264694508914, 49.645561837587]],
    [ "Terrasses",[-0.278804480389951, 49.29217234998125]],
    [ "Enedis",[-1.5994530000000011, 49.6441535]],
    [ "Enedis",[-1.5959908117097015, 49.644441522792484]],
    [ "Clos Neuf",[-0.26398574283973186, 49.27558843144424]],
    [ "Enedis",[-1.5805853390513325, 49.64693872800519]],
    [ "Enedis",[2.263340922222222, 48.80804214861111]],
    [ "11 novembre",[-0.2653847191011234, 49.28367678572836]],
    [ "Logis Coop",[-0.26366471785552026, 49.278985524610036]],
    [ "Charnettes",[-0.27070381105169367, 49.289152297682705]],
    [ "Tour de Ville",[-0.2615141856035438, 49.27713647109634]],
    [ "Leverier",[-0.2583644195402305, 49.285535764367815]],
    [ "Plage",[-0.2539408408413997, 49.28739254027054]],
    [ "Petit bonheur 02",[-0.2615555, 49.27539975]],
    [ "Petit casino",[-0.2627751731409549, 49.28676044802071]],
    [ "Guynemer",[-0.2572710386753686, 49.283902829103205]],
    [ "Port",[-0.2506197998885171, 49.278256106465996]],
    [ "Jean Bart",[-0.25337200000000004, 49.27473650000001]],
    [ "Forains",[-0.25148232367149725, 49.280619671497575]],
    [ "Saint-Exupéry",[-0.26308737167026625, 49.28101346580274]],
    [ "Enedis",[-0.2701489876084262, 49.28249966604709]],
    [ "Enedis",[2.575400088648215, 48.603869265543516]],
    [ "Enedis",[2.5530538237973244, 48.6032211807572]],
    [ "Enedis",[2.583510499999999, 48.5793185]],
    [ "Enedis",[2.5645917955647963, 48.59873366528066]],
    [ "Enedis",[2.589546793650794, 48.59223685714285]],
    [ "Enedis",[2.5902045000000014, 48.5853515]],
    [ "Enedis",[2.5669997492877488, 48.606451467236475]],
    [ "Enedis",[2.5571420000000002, 48.608210500000006]],
    [ "Enedis",[2.5766425934959347, 48.58020584552846]],
    [ "Enedis",[2.569369, 48.606018]],
    [ "Enedis",[2.5735506439281597, 48.60237264590543]],
    [ "Enedis",[2.563115497587581, 48.60357901531361]],
    [ "Enedis",[2.5733907331339316, 48.59486634529743]],
    [ "Enedis",[2.587310061873319, 48.58003128760088]],
    [ "Enedis",[2.578033289558664, 48.59813269716541]],
    [ "Enedis",[2.580626769920572, 48.581104970023056]],
    [ "Enedis",[2.5513239182795697, 48.606669905376336]],
    [ "Enedis",[2.583593020091324, 48.58929117260274]],
    [ "Enedis",[2.5788358061366807, 48.5877929372385]],
    [ "Enedis",[2.5791945, 48.57894549999999]],
    [ "Enedis",[2.5587893127419106, 48.60787955442019]],
    [ "Enedis",[2.5690744999999997, 48.6076605]],
    [ "Enedis",[2.5926244999999994, 48.5825235]],
    [ "Enedis",[2.5935129357541893, 48.57586021554935]],
    [ "Enedis",[2.5654604948706843, 48.56425575567114]],
    [ "Enedis",[2.590229450980389, 48.5925087254902]],
    [ "Enedis",[2.5626395173866197, 48.60496683357822]],
    [ "Enedis",[2.5906874280770062, 48.584596715985285]],
    [ "Enedis",[2.5922500000000017, 48.57918]],
    [ "Enedis",[2.5811835000000003, 48.57203350000001]],
    [ "Enedis",[2.5581830523189395, 48.60382592597669]],
    [ "Enedis",[2.590506325242719, 48.59651380420713]],
    [ "Enedis",[2.5801279999999998, 48.582552500000006]],
    [ "Enedis",[2.583807, 48.580943000000005]],
    [ "Enedis",[-1.684789437751004, 49.65787109638554]],
    [ "Enedis",[-1.6505379999999998, 49.65056]],
    [ "Enedis",[-1.6703273159722218, 49.64116703472222]],
    [ "Enedis",[-1.6873167800288629, 49.657037241235976]],
    [ "Enedis",[-1.665589603003591, 49.6434187166177]],
    [ "Enedis",[-1.6523238314005801, 49.65404745307838]],
    [ "Enedis",[-1.6522240000000008, 49.639681499999995]],
    [ "Enedis",[-1.6637768372843087, 49.64902082986615]],
    [ "Enedis",[-1.6550404165936312, 49.651309649722464]],
    [ "Enedis",[-1.693174306419349, 49.656825291143846]],
    [ "Enedis",[-1.6720886666666668, 49.654671444444446]],
    [ "Enedis",[-1.6854958500000004, 49.6602866]],
    [ "Enedis",[-1.643903497448406, 49.647500470048755]],
    [ "Enedis",[-1.6496305000000002, 49.650496749999995]],
    [ "Enedis",[-1.68401265, 49.65934065]],
    [ "Enedis",[-1.6726868258527827, 49.65533837283065]],
    [ "Enedis",[-1.6841335558624935, 49.64928558456108]],
    [ "Enedis",[-1.679603731845113, 49.660507447346]],
    [ "Enedis",[-1.6615898539325848, 49.650182579362]],
    [ "Enedis",[-1.6613877972665152, 49.64917687505274]],
    [ "Enedis",[-1.6510340000000001, 49.6453665]],
    [ "Enedis",[-1.6499307003141221, 49.650993083393665]],
    [ "Enedis",[-1.6508737251966992, 49.64300335981577]],
    [ "Enedis",[-1.6600827350427345, 49.64496193162394]],
    [ "Enedis",[-1.6452753000000002, 49.648170199999996]],
    [ "Enedis",[-1.6893245, 49.66053755]],
    [ "Enedis",[-1.6478790833333339, 49.64694688095238]],
    [ "Enedis",[-1.6489533500000009, 49.6504258]],
    [ "Enedis",[-1.6868859999999999, 49.6615985]],
    [ "Enedis",[-1.660356699078487, 49.64636146488719]],
    [ "Enedis",[-1.6608858087056124, 49.647530155784644]],
    [ "Enedis",[-1.6597221890874883, 49.640529081530254]],
    [ "Enedis",[-1.682179238855923, 49.6577712705858]],
    [ "Enedis",[-1.654683268106163, 49.654069553613574]],
    [ "Enedis",[-1.6914662520612491, 49.65839675971732]],
    [ "Enedis",[-1.675292352380952, 49.65311702857142]],
    [ "Enedis",[-1.6680046328423346, 49.64673168194217]],
    [ "Enedis",[-1.648184, 49.6483925]],
    [ "Enedis",[-1.6752948444444442, 49.65551383174603]],
    [ "Enedis",[3.721490644268776, 50.103157828722]],
    [ "RTE",[-1.2456078583427117, 46.76133319750028]],
    [ "Poste de la Mérlatière",[-1.3303474162355762, 46.776433292589246]],
    [ "Mauléon",[-0.722329726759968, 46.93081763372458]],
    [ "Enedis",[6.145291117366364, 48.65018915218938]],
    [ "Enedis",[5.995411669046404, 46.2499224672786]],
    [ "Enedis",[5.994126614182022, 46.258295517029595]],
    [ "Enedis",[5.996398499999999, 46.252132499999995]],
    [ "Enedis",[5.993344665654112, 46.260940004860274]],
    [ "Enedis",[6.001967643362463, 46.250767835622135]],
    [ "Enedis",[6.007873858287642, 46.247428123717135]],
    [ "Enedis",[5.9979795, 46.2533982]],
    [ "Enedis",[6.009062590551181, 46.249022016622924]],
    [ "Enedis",[2.5655326512702077, 48.66218073595073]],
    [ "Enedis",[2.5576362322097372, 48.66535147191009]],
    [ "Enedis",[2.5511261797990965, 48.665246542899276]],
    [ "Enedis",[2.5523666957602877, 48.65300030411538]],
    [ "Enedis",[2.573467852459016, 48.66307475956284]],
    [ "Enedis",[2.5455042350918946, 48.662180210762926]],
    [ "Enedis",[2.5597701986394563, 48.64849452244898]],
    [ "Bouvreuil",[2.5681937508417505, 48.652849562289575]],
    [ "Enedis",[2.5666792165861505, 48.66442361030596]],
    [ "Enedis",[2.5731431708212558, 48.65903587101448]],
    [ "Poste électrique de Cordemais",[-1.8704371826262862, 47.28614568908631]],
    [ "Enedis",[2.5570887751375135, 48.665655573377336]],
    [ "Enedis",[2.552320198295523, 48.648419583723445]],
    [ "Enedis",[2.5745715000000002, 48.66119249999999]],
    [ "Enedis",[2.5580149999999997, 48.64986]],
    [ "Enedis",[2.5499459347181013, 48.65112115380811]],
    [ "Enedis",[2.5685377927697126, 48.65803976487333]],
    [ "Enedis",[2.5615416016153674, 48.66531317288801]],
    [ "Enedis",[2.5519619999999996, 48.6522575]],
    [ "Enedis",[2.657649167389419, 48.53461904047413]],
    [ "Enedis",[2.6601407134894095, 48.53009616945373]],
    [ "Enedis",[2.642631453707074, 48.54980020240263]],
    [ "Enedis",[2.662642669117647, 48.53124597058824]],
    [ "Enedis",[2.650571273224044, 48.55101074863388]],
    [ "Enedis",[2.6678857000000002, 48.551316549999996]],
    [ "Enedis",[2.6739360000000003, 48.550861499999996]],
    [ "Enedis",[2.6575998593750003, 48.530463421875005]],
    [ "Enedis",[2.6754115946474624, 48.54470079612342]],
    [ "Enedis",[2.6670924999999994, 48.54074300000001]],
    [ "Enedis",[2.6751366631393294, 48.543638007054675]],
    [ "Enedis",[2.6514911725711205, 48.54433708293076]],
    [ "Enedis",[2.651339898110267, 48.545052673095654]],
    [ "Enedis",[2.6549755611902524, 48.53743216479898]],
    [ "Enedis",[2.6578103312412833, 48.525501018131095]],
    [ "Enedis",[2.6602524076563463, 48.535851851478526]],
    [ "Enedis",[2.665945993753549, 48.526311463940935]],
    [ "Enedis",[2.6627978996929373, 48.544374697031735]],
    [ "Enedis",[2.658214205128205, 48.55030244416874]],
    [ "Enedis",[2.6660017657657664, 48.53854366666667]],
    [ "Enedis",[2.664689016161616, 48.55184617171717]],
    [ "Enedis",[2.648380278039092, 48.54542296738789]],
    [ "Enedis",[2.655585, 48.542097]],
    [ "Enedis",[2.6517427375886515, 48.547137929078026]],
    [ "Enedis",[2.669195558415842, 48.54396936061606]],
    [ "Enedis",[2.655573810563291, 48.52948952553005]],
    [ "Enedis",[2.6791923333333325, 48.54376066666667]],
    [ "Enedis",[2.6762824999999997, 48.5425275]],
    [ "Enedis",[2.654595213345964, 48.52541335627829]],
    [ "Enedis",[0.19027189999999952, 49.28838865000001]],
    [ "Enedis",[0.18768979612505504, 49.27905543989432]],
    [ "Enedis",[0.18853033013972098, 49.28607315874917]],
    [ "Enedis",[0.18659549690721722, 49.287649575257724]],
    [ "Enedis",[0.19012849999999998, 49.291014999999994]],
    [ "Enedis",[0.20130319512195152, 49.28471987398375]],
    [ "Enedis",[0.1787456573017267, 49.28448003160668]],
    [ "Enedis",[0.18190230000000002, 49.28640375]],
    [ "Enedis",[0.18113216993231235, 49.2801064872343]],
    [ "Enedis",[0.18107128107627316, 49.283139535468095]],
    [ "Enedis",[1.5774239500000011, 50.63749554999998]],
    [ "Enedis",[1.579276899119484, 50.635164149353514]],
    [ "Enedis",[1.5776707191713317, 50.639345188638735]],
    [ "Enedis",[1.5822248, 50.63834365]],
    [ "Enedis",[1.5789916999999987, 50.6274526]],
    [ "Enedis",[1.5821446405330166, 50.630280395743824]],
    [ "Enedis",[1.5790600000000001, 50.62512085000001]],
    [ "Enedis",[1.5776351000000002, 50.63220080000001]],
    [ "RTE",[2.5483737925610885, 43.19041830208396]],
    [ "Enedis",[1.5236817114427847, 43.583560200995024]],
    [ "Enedis",[1.5372666614759642, 43.589605909501245]],
    [ "Enedis",[1.535801597003746, 43.585080291385765]],
    [ "Enedis",[1.5389631951754383, 43.588610907894726]],
    [ "Enedis",[1.5254357490636703, 43.584343295880146]],
    [ "Enedis",[1.5155625586121435, 43.58725217348203]],
    [ "Enedis",[1.5386823203378823, 43.58314655360625]],
    [ "Enedis",[1.5216525027849852, 43.5831075466054]],
    [ "Enedis",[1.5169540698924724, 43.581817398924734]],
    [ "Enedis",[1.533597075471698, 43.58876869182389]],
    [ "Enedis",[1.5151369154486036, 43.58146724046345]],
    [ "Enedis",[1.526110078275666, 43.588098615239176]],
    [ "Enedis",[1.5349238317460312, 43.58369350294784]],
    [ "Enedis",[1.5361763478260868, 43.5823787826087]],
    [ "Enedis",[1.5391868740740746, 43.58584777777778]],
    [ "Enedis",[1.5135948217522661, 43.58299601409869]],
    [ "Enedis",[1.5173629244577413, 43.58360217252555]],
    [ "Enedis",[1.53034075, 43.583902]],
    [ "Enedis",[1.5329544999999998, 43.584086]],
    [ "Enedis",[1.5373069335548168, 43.585409821705426]],
    [ "Enedis",[1.5089263, 43.580574999999996]],
    [ "Enedis",[1.5272689259259253, 43.58457335185185]],
    [ "Enedis",[1.534223681747873, 43.59193598143851]],
    [ "Enedis",[1.541999343121261, 43.58824714301251]],
    [ "Enedis",[1.5155348476621415, 43.583400188536956]],
    [ "Enedis",[1.5317770000000002, 43.585884]],
    [ "Enedis",[1.5172870385786814, 43.580232233558945]],
    [ "Enedis",[1.528421376117339, 43.58145609650455]],
    [ "Enedis",[1.5301387180920434, 43.58678473262531]],
    [ "Enedis",[1.540117142322097, 43.58749971535581]],
    [ "Enedis",[1.5240559168265269, 43.58145565018758]],
    [ "Enedis",[1.5398299999999998, 43.581120000000006]],
    [ "Enedis",[1.5838477723302689, 50.63249255182291]],
    [ "Enedis",[1.5827846095217468, 50.63467792548533]],
    [ "Enedis",[1.5994872295077496, 50.63596125533842]],
    [ "Enedis",[1.582491167329803, 50.63591576161714]],
    [ "Enedis",[1.6046884864246445, 50.640823227344214]],
    [ "Enedis",[1.5797528659059232, 50.63362989910359]],
    [ "Enedis",[1.5907646, 50.6287098]],
    [ "Enedis",[1.5855318327795431, 50.62945889854869]],
    [ "Enedis",[1.5905384163768164, 50.630003221566284]],
    [ "Enedis",[1.5864288090999017, 50.63083053808111]],
    [ "Enedis",[1.5844979000000001, 50.63883320000001]],
    [ "Enedis",[1.5913483892990392, 50.639295258077325]],
    [ "Enedis",[1.59733555, 50.64124210000001]],
    [ "Enedis",[1.5873912926572658, 50.63753592094503]],
    [ "Enedis",[1.6087593, 50.64163400000001]],
    [ "Enedis",[6.204907482283378, 48.749956908004]],
    [ "Enedis",[6.196642732323232, 48.750949080808084]],
    [ "Enedis",[6.184381000000001, 48.74546399999999]],
    [ "Enedis",[6.191381910315146, 48.74856019616826]],
    [ "Enedis",[6.1727445, 48.7474605]],
    [ "Enedis",[6.200083970535085, 48.74830370166007]],
    [ "Enedis",[6.1937285, 48.75073799999999]],
    [ "Enedis",[6.201561741100323, 48.746461851132686]],
    [ "Enedis",[6.1978372434394196, 48.74652333947516]],
    [ "Enedis",[6.1842046509274855, 48.751188167509845]],
    [ "Enedis",[6.1814003114406795, 48.746464238347464]],
    [ "Enedis",[6.187474683563748, 48.747444520737325]],
    [ "Enedis",[6.2045032305600865, 48.751594471451874]],
    [ "Enedis",[6.21063116150351, 48.75016150805452]],
    [ "Enedis",[6.198077499999999, 48.754537]],
    [ "Enedis",[6.1754935, 48.748628]],
    [ "Enedis",[6.209955629890108, 48.752291960146515]],
    [ "Enedis",[1.6078648999999998, 50.62380600000001]],
    [ "Enedis",[1.6094664019563585, 50.628478325357406]],
    [ "Enedis",[1.5991965480799353, 50.631981951129916]],
    [ "Enedis",[1.6040362697004946, 50.62533325245769]],
    [ "Enedis",[1.615386309385536, 50.62588247502025]],
    [ "Enedis",[1.602846, 50.62840728205128]],
    [ "Enedis",[1.6113495999999996, 50.631699649999995]],
    [ "Enedis",[1.6088827000000003, 50.62652785]],
    [ "Enedis",[1.6175586, 50.63009855]],
    [ "Enedis",[1.6065521999999999, 50.6330877]],
    [ "Enedis",[1.5954684000000008, 50.62909665000001]],
    [ "Enedis",[1.5842135065128877, 50.62664723106479]],
    [ "Enedis",[1.624352253069172, 50.624610331809336]],
    [ "Enedis",[1.6264058471953577, 50.61885162540297]],
    [ "Enedis",[1.61557865, 50.63217055]],
    [ "Enedis",[1.60948505, 50.63565775000001]],
    [ "Enedis",[1.6450761470957433, 50.621426789683895]],
    [ "Enedis",[1.6514336491228074, 50.61959133333334]],
    [ "Enedis",[1.6406987698980695, 50.61641434851443]],
    [ "Enedis",[1.6398167595731317, 50.61984744821093]],
    [ "Enedis",[1.6389535, 50.61752350000001]],
    [ "Enedis",[2.404465, 50.69231500000001]],
    [ "Enedis",[2.39865234055355, 50.69066397954273]],
    [ "Enedis",[3.9810168147086915, 43.54554493791786]],
    [ "Enedis",[4.014719330422126, 43.61624199126638]],
    [ "Enedis",[4.018293999999999, 43.6153955]],
    [ "Enedis",[4.00423762752076, 43.61104989561092]],
    [ "Enedis",[4.009764943341961, 43.6133437837216]],
    [ "Enedis",[3.9338064999999998, 43.58841949999999]],
    [ "RTE",[1.3058058627198956, 43.78460611881578]],
    [ "RTE",[1.3727948397751795, 43.73727531828739]],
    [ "Enedis",[3.9378207499999993, 43.58896824999999]],
    [ "Enedis",[3.9517865, 43.5794925]],
    [ "Enedis",[3.9393753363682347, 43.589824966616085]],
    [ "Enedis",[3.9366340640350876, 43.59036122368421]],
    [ "Enedis",[4.014591661555935, 43.617880056218056]],
    [ "Enedis",[4.014265185111, 43.62106555249541]],
    [ "Enedis",[4.019415156118144, 43.610983713080174]],
    [ "Enedis",[4.018041120147729, 43.620745937136576]],
    [ "Enedis",[2.3676355, 50.730430500000004]],
    [ "Enedis",[2.3614963725788067, 50.73865036675527]],
    [ "Enedis",[2.36814704891407, 50.72766017261568]],
    [ "Enedis",[2.3708347207751936, 50.72686305751938]],
    [ "Enedis",[5.4091999002841415, 43.324382688776424]],
    [ "Enedis",[5.407795513161701, 43.3254875958924]],
    [ "Enedis",[2.2651537007430167, 48.825199540353566]],
    [ "Enedis",[0.13346221980405068, 49.50949171462348]],
    [ "Poste Électrique Mazières",[2.393052063863976, 47.06574525237961]],
    [ "Poste électrique de Plaud",[0.8585043532095565, 45.912158373851355]],
    [ "Enedis",[5.844903395774647, 44.542519726291076]],
    [ "Pélissier",[2.1380446286729047, 43.954478836372374]],
    [ "SER",[7.740321024029213, 48.61560267757605]],
    [ "Enedis",[2.562908487439371, 48.849696274319506]],
    [ "Enedis",[2.5582114, 48.84919265]],
    [ "Enedis",[1.5264725727060604, 43.64300444422386]],
    [ "Enedis",[5.7337075770123285, 44.26284661945856]],
    [ "Poste électrique de Naoutot",[-0.5183613233138354, 43.859747206807256]],
    [ "Bec",[-0.5945745080530357, 45.03339981348771]],
    [ "Enedis",[-1.49130585, 46.8434955]],
    [ "Enedis",[-1.4957235000000006, 46.84704175]],
    [ "Enedis",[-1.499996754290463, 46.84409746753808]],
    [ "Enedis",[-1.4977859544312775, 46.847925017257076]],
    [ "Enedis",[-1.4952016118747606, 46.84419327854143]],
    [ "Enedis",[-1.4929703, 46.839921549999985]],
    [ "ESSeyssel",[6.085661389440925, 46.00921723547849]],
    [ "Hangar District",[6.1206675, 45.9968425]],
    [ "Langin",[6.124264127373069, 45.998597881898455]],
    [ "L'Arny",[6.12624295, 45.98591130000001]],
    [ "ESSeyssel",[6.165010699616858, 46.01048006781609]],
    [ "Les Longerays",[6.1704577960784315, 46.01040419215687]],
    [ "MAPAD",[6.171818348827209, 46.007568899822736]],
    [ "Chef Lieu",[6.169684999999999, 46.01797425]],
    [ "CEG HLM",[6.177925211200001, 46.004907338133336]],
    [ "Menibel",[6.184896999999999, 46.0244104]],
    [ "Le Plot",[6.186969051765217, 46.004690031189085]],
    [ "Lonchamp",[6.180215461561561, 46.000441989789785]],
    [ "Deossenay",[6.198361500000001, 46.0196025]],
    [ "Sarnieux Nord",[6.186240716815966, 46.0375774161502]],
    [ "ESSeyssel",[6.176099232980482, 46.012360020046316]],
    [ "La Fleurette",[6.179510101483217, 45.99887183918815]],
    [ "Enedis",[2.593696858295512, 48.51451269188099]],
    [ "Enedis",[2.6124167821185615, 48.51272207269194]],
    [ "Enedis",[2.629753583333333, 48.515987249999995]],
    [ "Enedis",[2.6332435000000003, 48.511272500000004]],
    [ "Enedis",[2.647128603333334, 48.53000037]],
    [ "Enedis",[2.636999910544728, 48.51737979110446]],
    [ "Enedis",[2.6459217999999995, 48.530435205555555]],
    [ "Enedis",[2.6092036281631446, 48.51056071142205]],
    [ "Enedis",[2.6371856194007717, 48.51954850627905]],
    [ "Enedis",[2.634979038510101, 48.517705579335015]],
    [ "Enedis",[2.64134255, 48.51862836666667]],
    [ "Enedis",[2.635085680031447, 48.515290800471696]],
    [ "Enedis",[2.6400794999999997, 48.53096850000001]],
    [ "Enedis",[2.6256925, 48.522597999999995]],
    [ "Enedis",[2.6315588135021097, 48.51568435021098]],
    [ "Enedis",[2.6483193981211537, 48.525437815354714]],
    [ "Portique 400 kV",[2.6868160872013633, 44.76615175490486]],
    [ "Enedis",[2.4321317206963844, 50.966046732853066]],
    [ "Enedis",[2.4389703595446113, 50.97169117738946]],
    [ "Enedis",[2.427928250793651, 50.96749805714286]],
    [ "Enedis",[2.4336188326359833, 50.970952009762904]],
    [ "Enedis",[2.43965515512675, 50.96724580476732]],
    [ "Poste électrique de Bois-Durand",[-0.03344831566237389, 45.59122224688406]],
    [ "Enedis",[3.301281503479036, 48.54617462619685]],
    [ "Enedis",[2.5331333008130072, 48.558475252032515]],
    [ "Enedis",[2.524351500000001, 48.567847500000006]],
    [ "Enedis",[2.5299867243746927, 48.5662176887363]],
    [ "Enedis",[2.5449297067395262, 48.56650360769581]],
    [ "Enedis",[2.533015622960045, 48.53917415156632]],
    [ "Enedis",[2.5200289999999996, 48.53502400000001]],
    [ "Enedis",[2.539804287309448, 48.570313346926895]],
    [ "Enedis",[2.5263519999999993, 48.57030700000001]],
    [ "Enedis",[2.533562891518737, 48.56272114990138]],
    [ "Enedis",[2.5203411565040654, 48.530206987804874]],
    [ "Enedis",[2.543584329828251, 48.53074306659657]],
    [ "Enedis",[2.5416488164251203, 48.56590944927536]],
    [ "Enedis",[2.532376799220273, 48.56949797270954]],
    [ "Enedis",[2.528146288158796, 48.55664754620122]],
    [ "Enedis",[2.537208988939165, 48.56272983257919]],
    [ "Enedis",[2.548198883742231, 48.52942834622177]],
    [ "Enedis",[2.5414512955618505, 48.56320858262511]],
    [ "Enedis",[2.5329071462539257, 48.56550845132346]],
    [ "Enedis",[2.519918621993127, 48.56915407216495]],
    [ "Enedis",[2.5165889916415916, 48.533753329655624]],
    [ "Enedis",[2.536735827586207, 48.55787166666667]],
    [ "Enedis",[2.533795833016356, 48.570939497400786]],
    [ "Enedis",[2.535992677546983, 48.531974829871416]],
    [ "Enedis",[2.5278454104193133, 48.53296187632359]],
    [ "Enedis",[2.540866881392582, 48.53143478311167]],
    [ "Enedis",[0.7073815860598687, 44.022010286318014]],
    [ "Enedis",[2.587626929283046, 48.86264369667032]],
    [ "Poste électrique de Montézic",[2.6387162854833965, 44.73447056411694]],
    [ "Enedis",[6.126002299999999, 45.995866750000005]],
    [ "Trevilly",[6.1319856, 46.002450350000004]],
    [ "Combe",[6.1371772, 45.9960701]],
    [ "Enedis",[6.1302686, 46.001794849999996]],
    [ "Enedis",[6.13092716893424, 45.997059212774]],
    [ "Enedis",[6.133606710485903, 45.997743932631344]],
    [ "Villy le Pelloux",[6.129855, 45.9994845]],
    [ "Enedis",[2.6063837056891357, 48.57138347904349]],
    [ "Enedis",[2.5992952520325194, 48.57466025203252]],
    [ "Enedis",[2.600098501033426, 48.56748033148029]],
    [ "Enedis",[2.6042680000000007, 48.567094999999995]],
    [ "Enedis",[2.5976949999999994, 48.58624149999999]],
    [ "Enedis",[2.6034859999999997, 48.5720685]],
    [ "Enedis",[2.5930057331054432, 48.55510923910946]],
    [ "Enedis",[2.6073722531565653, 48.56052404145624]],
    [ "Enedis",[2.6033580000000014, 48.57466349999999]],
    [ "Enedis",[2.565477, 48.419734500000004]],
    [ "Enedis",[2.5637896550017674, 48.41709043195477]],
    [ "Enedis",[2.566626, 48.414165]],
    [ "Enedis",[2.5647704999999994, 48.404531000000006]],
    [ "Enedis",[2.563001, 48.40830399999999]],
    [ "Enedis",[2.7862897721518993, 48.566071324894516]],
    [ "Enedis",[2.779407385416667, 48.566405746527785]],
    [ "Enedis",[2.7813139057815848, 48.57029612871757]],
    [ "Enedis",[2.7835326816479404, 48.56590242322097]],
    [ "Enedis",[2.5983185000000004, 48.4423235]],
    [ "Enedis",[2.6131691449275363, 48.446222562318844]],
    [ "Enedis",[2.6042294147053453, 48.44063515270936]],
    [ "Enedis",[2.609508000000001, 48.520897]],
    [ "Enedis",[2.628988136980985, 48.52833007256499]],
    [ "Enedis",[2.5928238489583335, 48.524532927083335]],
    [ "Enedis",[2.592559959303694, 48.528268618442716]],
    [ "Enedis",[2.588609830188679, 48.526322716981134]],
    [ "Enedis",[2.5626124149132528, 48.53598689442598]],
    [ "Enedis",[2.5957784999999998, 48.538262]],
    [ "Enedis",[2.5848954999999996, 48.5398925]],
    [ "Enedis",[2.855091943749508, 48.57053013146093]],
    [ "Enedis",[2.5608229999999996, 48.50233]],
    [ "Enedis",[2.5667033446363847, 48.52439079697164]],
    [ "Enedis",[2.5604166188876447, 48.4944393139231]],
    [ "Enedis",[2.549812273191032, 48.53540010028191]],
    [ "Enedis",[2.564972928753181, 48.50638280916031]],
    [ "Enedis",[2.5681261290548845, 48.527716221111696]],
    [ "Enedis",[2.570069646825397, 48.510880682539685]],
    [ "Enedis",[2.5400763851428563, 48.457602845333334]],
    [ "Enedis",[2.6070327136533886, 48.45986445170809]],
    [ "Enedis",[2.610038446735395, 48.466704611683845]],
    [ "Enedis",[2.6026721936486776, 48.465229550966015]],
    [ "Enedis",[2.604269972487693, 48.47022197451492]],
    [ "Enedis",[2.6037373319386337, 48.46691401255231]],
    [ "Enedis",[2.795075665178202, 48.50754049797404]],
    [ "Enedis",[2.794503401980387, 48.50529218461391]],
    [ "Enedis",[2.7905564999999997, 48.507462]],
    [ "Enedis",[2.7849088621616045, 48.50478727154371]],
    [ "Enedis",[2.7971937767584096, 48.50554894495412]],
    [ "Enedis",[2.8937704046374475, 48.54821230518697]],
    [ "Enedis",[2.781933693163752, 48.500038040628866]],
    [ "Enedis",[2.7940658867576937, 48.50380342446901]],
    [ "Enedis",[2.7994895, 48.505610999999995]],
    [ "Enedis",[2.7802742023121385, 48.50060134489403]],
    [ "Enedis",[2.8038994999999995, 48.50442549999999]],
    [ "Enedis",[2.798814125925926, 48.497394074074066]],
    [ "Enedis",[2.7846254999999998, 48.501971]],
    [ "Enedis",[2.782949326647564, 48.497774478510024]],
    [ "Poste électrique de Saint-Vincent",[3.2188776201910456, 43.36881814347281]],
    [ "Enedis",[2.8438441860940697, 48.66402596932515]],
    [ "Enedis",[2.743464441281138, 48.59786317319098]],
    [ "Enedis",[2.740953, 48.595337]],
    [ "Enedis",[2.776753665242165, 48.58256999430199]],
    [ "Enedis",[2.775141049583334, 48.584017326041675]],
    [ "Enedis",[2.755656770135687, 48.48908354454916]],
    [ "Enedis",[2.7482222058626466, 48.490117388163036]],
    [ "réséda",[6.20716345756353, 49.24715692807606]],
    [ "réséda",[6.2012344328725035, 49.251192974347155]],
    [ "réséda",[6.203215273037543, 49.24601564368601]],
    [ "réséda",[6.2054222999999995, 49.2446553]],
    [ "réséda",[6.202515427061558, 49.239366982346105]],
    [ "réséda",[6.204808260964914, 49.19429588377193]],
    [ "réséda",[6.204380318565401, 49.18647345126582]],
    [ "réséda",[6.200516177726926, 49.188132341723886]],
    [ "réséda",[6.205500928097135, 49.18385679814077]],
    [ "réséda",[6.201745227848101, 49.19464944725738]],
    [ "réséda",[6.208778854223188, 49.21285931560861]],
    [ "réséda",[6.203192174857142, 49.196252189333336]],
    [ "réséda",[6.2123213183856505, 49.20695447832586]],
    [ "réséda",[6.2077865, 49.20848850000001]],
    [ "Enedis",[2.5537992562305294, 48.62865532632399]],
    [ "Enedis",[2.5460392485875705, 48.64340642372881]],
    [ "Enedis",[2.5486892442464746, 48.63260909403613]],
    [ "Enedis",[2.548379371368198, 48.6343005340729]],
    [ "Enedis",[2.550898295125165, 48.62782041721564]],
    [ "Enedis",[2.5474930000000007, 48.647946]],
    [ "Enedis",[2.549346357911984, 48.636932668773404]],
    [ "Enedis",[2.542252, 48.64292700000001]],
    [ "Enedis",[2.5614341210414318, 48.62025188972336]],
    [ "Enedis",[2.554538156846, 48.627997982920576]],
    [ "Enedis",[2.5603749999999996, 48.626053]],
    [ "Enedis",[2.5412221255813936, 48.63093542054264]],
    [ "Enedis",[2.5476262666666676, 48.64280724761906]],
    [ "Enedis",[2.5524424999999997, 48.641192999999994]],
    [ "Enedis",[2.549459499999999, 48.630013]],
    [ "Enedis",[2.5460155000000007, 48.63886299999999]],
    [ "Enedis",[2.5460297721166034, 48.63764717110266]],
    [ "Enedis",[2.5690195403902036, 48.62398031498547]],
    [ "Enedis",[2.551791007123557, 48.63497916949152]],
    [ "Enedis",[2.5567662859855687, 48.63596816875555]],
    [ "Enedis",[2.54759477324066, 48.6430277590501]],
    [ "Enedis",[2.555158, 48.6365815]],
    [ "Enedis",[2.5520628181143294, 48.63708101336302]],
    [ "Enedis",[2.559521000000001, 48.634451000000006]],
    [ "Enedis",[2.5475208272827286, 48.63290565933261]],
    [ "Enedis",[2.68893161400332, 48.50987005749208]],
    [ "Enedis",[2.6795245000000003, 48.51054849999999]],
    [ "Enedis",[2.6760265000000016, 48.514724]],
    [ "Enedis",[2.685768634952194, 48.5128562769448]],
    [ "Enedis",[2.68462, 48.506609499999996]],
    [ "Enedis",[2.684215463276836, 48.515371785310734]],
    [ "Enedis",[2.6845436509554137, 48.51057915265394]],
    [ "Enedis",[2.679916, 48.507833500000004]],
    [ "Enedis",[2.6805228979155533, 48.513776733475865]],
    [ "Enedis",[2.692322666666667, 48.510177831168825]],
    [ "Enedis",[2.684929999999999, 48.517275]],
    [ "Enedis",[2.683157089598997, 48.50931330921052]],
    [ "Enedis",[2.826144115142718, 48.454056541364295]],
    [ "Enedis",[2.8301861834194924, 48.45870748611909]],
    [ "Enedis",[2.69798341867529, 48.54906333774252]],
    [ "Enedis",[2.7019640632737274, 48.55009391425952]],
    [ "Enedis",[2.680208662283996, 48.595027830578516]],
    [ "Enedis",[2.632225810353169, 48.551493541364295]],
    [ "Enedis",[2.6409149000884176, 48.54522908429119]],
    [ "Enedis",[2.634556, 48.537380999999996]],
    [ "Enedis",[2.640000540262617, 48.541158615567156]],
    [ "Enedis",[2.6306801812262615, 48.534450879363355]],
    [ "Enedis",[2.631417173988583, 48.53039211367586]],
    [ "Enedis",[2.6411934076190473, 48.53868275510204]],
    [ "Enedis",[2.646274639309984, 48.5451226863565]],
    [ "Enedis",[2.6380626678657078, 48.54432097122302]],
    [ "Enedis",[2.6185866348949918, 48.54476669036079]],
    [ "Enedis",[2.6248739999999997, 48.53814199999999]],
    [ "Enedis",[2.63981, 48.543633]],
    [ "Enedis",[2.643761488262911, 48.54288584507042]],
    [ "Enedis",[2.63963188320356, 48.547575008898775]],
    [ "Enedis",[2.641059774647887, 48.54697594600938]],
    [ "Enedis",[2.6210531622995696, 48.53589584135054]],
    [ "Enedis",[2.63337, 48.543281]],
    [ "Enedis",[2.5720793861171374, 48.58436731308749]],
    [ "Enedis",[2.5728375066666667, 48.58830038333335]],
    [ "Enedis",[2.5562728288016117, 48.57877317925478]],
    [ "Enedis",[2.566586383051678, 48.588966803330884]],
    [ "Enedis",[2.5641264503836316, 48.585325131798804]],
    [ "Enedis",[2.5642670593480275, 48.5863934136529]],
    [ "Enedis",[2.5717410135561165, 48.586815385981495]],
    [ "Enedis",[0.5723742383357902, 43.55864326578375]],
    [ "Enedis",[2.0225482895837357, 48.77622702283986]],
    [ "Enedis",[2.0250275911949687, 48.77866744025157]],
    [ "Enedis",[2.015344348978705, 48.77629045795306]],
    [ "RTE",[-0.4662296770337378, 47.20666502793085]],
    [ "Enedis",[0.11194473831394158, 49.276789651153294]],
    [ "Enedis",[0.12936797944338885, 49.26935407084124]],
    [ "Enedis",[0.11021092763150905, 49.278538864688045]],
    [ "Enedis",[5.877263313275629, 47.088310061297705]],
    [ "Enedis",[6.158420649999998, 47.04324449999999]],
    [ "Enedis",[6.09070671568418, 47.029299830730146]],
    [ "Poste électrique - Leygues",[2.430704675422174, 44.70775793310503]],
    [ "Enedis",[1.9165849264180772, 47.90216777244658]],
    [ "Enedis",[1.9166789573351444, 47.90377076308697]],
    [ "Enedis",[1.9035330534890609, 47.90157614620625]],
    [ "Enedis",[1.9143532181031826, 47.914244957059005]],
    [ "Enedis",[1.9016354665389754, 47.910697482956195]],
    [ "Enedis",[1.9091426270643879, 47.91446790592067]],
    [ "Enedis",[1.9123574450950842, 47.913193356584145]],
    [ "Enedis",[1.9114479666666664, 47.91957323333333]],
    [ "Enedis",[1.9040766559139783, 47.91540403942652]],
    [ "Enedis",[1.9032939966718736, 47.91050299905317]],
    [ "Enedis",[1.9062317389146919, 47.91393340839982]],
    [ "Enedis",[1.9152503555779103, 47.91218304109237]],
    [ "Enedis",[1.9035186440200542, 47.91238356774627]],
    [ "Enedis",[1.8599341999999983, 47.89934035000001]],
    [ "Enedis",[1.8760327713239133, 47.89943623600208]],
    [ "Enedis",[1.8696447326696024, 47.9031377479818]],
    [ "Enedis",[1.8654836707932143, 47.89815725951399]],
    [ "Enedis",[1.87634975, 47.90142264999999]],
    [ "Enedis",[1.8668628616246503, 47.905217720728295]],
    [ "Enedis",[1.8704776199769777, 47.89835660603313]],
    [ "Enedis",[1.8678133, 47.902845299999996]],
    [ "Enedis",[1.8695142743849134, 47.897062090171104]],
    [ "Enedis",[1.8725901915531333, 47.90507392007265]],
    [ "Enedis",[1.8724636954079303, 47.90375185840356]],
    [ "Enedis",[1.8767192098519991, 47.904067536411155]],
    [ "Enedis",[1.861387, 47.9004275]],
    [ "Enedis",[1.868440592538134, 47.904889867308086]],
    [ "Enedis",[1.865857550644567, 47.90101765377532]],
    [ "Enedis",[1.8705877099358976, 47.905342854166676]],
    [ "Enedis",[1.8652175000000002, 47.903298]],
    [ "Enedis",[1.8620565982812212, 47.902068102943865]],
    [ "Enedis",[1.8739624999999995, 47.90602849999999]],
    [ "Enedis",[5.527212500000001, 45.14377550000001]],
    [ "Enedis",[2.0478100445730543, 48.794341805259414]],
    [ "Enedis",[2.049340795019738, 48.79688081637818]],
    [ "Enedis",[3.6576430592394744, 50.07618804692865]],
    [ "Enedis",[2.0458061997785157, 48.77522116566999]],
    [ "Enedis",[2.043297668803419, 48.77796101566952]],
    [ "KIRCHE MONTIGNY",[2.0465543352401387, 48.77018349755691]],
    [ "Enedis",[2.048366824668705, 48.76937302038736]],
    [ "Enedis",[2.041796065043478, 48.77408037878261]],
    [ "Enedis",[2.034657728843441, 48.77201740314999]],
    [ "Enedis",[3.354059782981328, 50.466208146311594]],
    [ "Enedis",[2.0209431488844705, 48.76808190177454]],
    [ "Enedis",[2.0356736672592595, 48.76273201125926]],
    [ "Enedis",[-1.503822225635347, 46.9360220161991]],
    [ "Enedis",[-1.5053690000000002, 46.9378705]],
    [ "Enedis",[-1.5280621499999998, 46.94839909999999]],
    [ "Enedis",[-1.509032478027526, 46.9435868657707]],
    [ "Enedis",[-1.5099367499999998, 46.9378355]],
    [ "Enedis",[-1.5130071499999997, 46.9359811]],
    [ "Enedis",[6.018729185992734, 46.28373326494879]],
    [ "Enedis",[5.9615352, 46.28720499999999]],
    [ "Enedis",[6.011473798453698, 46.275700182744686]],
    [ "Poizieux",[6.030315942803269, 46.280245798868634]],
    [ "Enedis",[6.021518000000001, 46.2710975]],
    [ "Enedis",[6.006122985815602, 46.274011674525596]],
    [ "Les marais",[6.022406366612991, 46.2827728427268]],
    [ "Le Perchet",[6.013516165394402, 46.28304998218829]],
    [ "Enedis",[6.023974808119693, 46.26385073066654]],
    [ "Enedis",[0.07747449999999999, 49.515508249999996]],
    [ "Enedis",[0.07157575, 49.51515374999999]],
    [ "Enedis",[1.9112405444156966, 47.904683777274684]],
    [ "Enedis",[1.9122276839378243, 47.9006877029361]],
    [ "Enedis",[6.1644771922422095, 48.71122066540825]],
    [ "Enedis",[6.168695250000002, 48.709262050000014]],
    [ "Enedis",[6.16870218313269, 48.70918641640736]],
    [ "Enedis",[6.162385313319708, 48.70625498807699]],
    [ "Enedis",[6.170318073484214, 48.720375143708665]],
    [ "Enedis",[6.1662968052805285, 48.717359212046205]],
    [ "Enedis",[6.167292278518038, 48.709642543603074]],
    [ "Enedis",[6.168051500283126, 48.7119136912986]],
    [ "Enedis",[6.168154433716643, 48.715662074303644]],
    [ "Enedis",[6.1662155, 48.71888299999999]],
    [ "Enedis",[6.168722328726969, 48.71935708291458]],
    [ "Enedis",[6.163581, 48.713252]],
    [ "Poste électrique de Morinant",[-1.3612120826857952, 46.190131677657725]],
    [ "Enedis",[1.910948268668206, 47.89960893841416]],
    [ "Enedis",[1.9051110000000013, 47.90080925]],
    [ "Enedis",[1.9044329827476847, 47.900616779060435]],
    [ "Enedis",[1.897730333595927, 47.904074166789705]],
    [ "Enedis",[1.8946510641539993, 47.90028613114189]],
    [ "Enedis",[1.8978469424431268, 47.90109690358209]],
    [ "Enedis",[1.9036391526466365, 47.89954498404865]],
    [ "Enedis",[1.9022476233118728, 47.899480751847854]],
    [ "Poste électrique des Jonquières",[4.583663510508419, 43.828994432809644]],
    [ "Geredis",[-0.12445463366028343, 46.224711680678816]],
    [ "Geredis",[-0.13348343606557386, 46.22477837559198]],
    [ "Geredis",[-0.12773038917845722, 46.227913652519234]],
    [ "Enedis",[2.55430545, 48.842927849999995]],
    [ "Enedis",[2.6037805087719295, 48.628535463157895]],
    [ "Enedis",[2.59436077032654, 48.628212527722994]],
    [ "Enedis",[2.6046893398692808, 48.6273933006536]],
    [ "Enedis",[2.5755692488262913, 48.63236059624413]],
    [ "Enedis",[2.583826748792271, 48.630366594202904]],
    [ "Enedis",[2.5907757908946536, 48.630469050467624]],
    [ "Enedis",[2.554728544836255, 48.4752385737518]],
    [ "Enedis",[2.565154035220126, 48.62108915723271]],
    [ "Enedis",[2.593368278244141, 48.63333234749912]],
    [ "Enedis",[2.5720764999999997, 48.630117]],
    [ "Enedis",[2.5808863563004345, 48.62578282169461]],
    [ "Enedis",[2.5983939862059717, 48.63243875197535]],
    [ "Enedis",[2.5920805, 48.629225]],
    [ "Enedis",[2.6026129119496857, 48.62444181132075]],
    [ "Enedis",[2.596327670228555, 48.6254790917186]],
    [ "Enedis",[2.5755704241359108, 48.630192246631516]],
    [ "Enedis",[2.599101128844555, 48.62562609725686]],
    [ "Enedis",[2.5899115866801377, 48.633508810137236]],
    [ "Enedis",[2.585110408821036, 48.631174782018654]],
    [ "Enedis",[2.5848500000000003, 48.6277245]],
    [ "Enedis",[2.595444097348103, 48.59644534676065]],
    [ "Enedis",[2.623786580836899, 48.61123325653828]],
    [ "Enedis",[2.597211, 48.597390000000004]],
    [ "Enedis",[2.634197107903781, 48.60952512439863]],
    [ "Enedis",[2.607845482142859, 48.60519426785714]],
    [ "Enedis",[2.6840435000000005, 48.553934]],
    [ "Enedis",[2.6754104355355994, 48.55458566281805]],
    [ "Enedis",[2.6798531630243656, 48.56161883622206]],
    [ "Enedis",[2.6792135000000004, 48.5557715]],
    [ "Enedis",[2.682431324200913, 48.5556506347032]],
    [ "Enedis",[2.5698134430869475, 48.43568727550397]],
    [ "Enedis",[2.9231069250749244, 48.5564436876457]],
    [ "Enedis",[2.6623454948311513, 48.507404832069845]],
    [ "Enedis",[2.6656535, 48.50937449999999]],
    [ "Enedis",[2.6610344999999995, 48.5090845]],
    [ "Enedis",[2.6681790000000003, 48.523272500000004]],
    [ "Enedis",[2.669970999999999, 48.498485]],
    [ "Enedis",[2.5605437486338802, 48.49937140437159]],
    [ "Enedis",[2.5366206019900495, 48.506240910447765]],
    [ "Enedis",[2.548237481283422, 48.496177433155076]],
    [ "Enedis",[2.5489828055331722, 48.55778454839287]],
    [ "Enedis",[2.5475054999999998, 48.5556295]],
    [ "Bel-Air",[2.193863446205077, 44.43305478876793]],
    [ "Poste électrique de Cusset",[4.916276094627422, 45.76496614558368]],
    [ "Enedis",[2.6830799999999995, 48.53166249999999]],
    [ "Enedis",[2.6910518129045946, 48.52709818943012]],
    [ "Enedis",[2.679037909685535, 48.53099382113207]],
    [ "Enedis",[2.6828298856640287, 48.51852523043096]],
    [ "Enedis",[2.675590811222064, 48.5320839657632]],
    [ "Enedis",[2.6919509310443486, 48.53516815064378]],
    [ "Enedis",[2.6799068603508784, 48.51779119859649]],
    [ "Enedis",[2.680794192792792, 48.52649953513514]],
    [ "Enedis",[2.698559999999998, 48.52772749999999]],
    [ "Enedis",[2.6825137282816685, 48.526309306544206]],
    [ "Enedis",[2.6770470000000004, 48.5271625]],
    [ "Enedis",[2.6934760608501107, 48.52610879642059]],
    [ "Enedis",[2.688161416309013, 48.53259025894135]],
    [ "Enedis",[2.6924263097321663, 48.53376551816494]],
    [ "Enedis",[2.674310400191022, 48.52428350716332]],
    [ "Enedis",[2.6733336593406607, 48.530946659340664]],
    [ "Enedis",[2.6824528958333334, 48.52492524479167]],
    [ "Enedis",[2.6915251131660063, 48.536715714663146]],
    [ "Enedis",[2.682880906171601, 48.52206870362937]],
    [ "Enedis",[2.678231364609053, 48.52247325596708]],
    [ "Enedis",[2.672703602440264, 48.53781684799188]],
    [ "Enedis",[2.6825274659300176, 48.53619842725598]],
    [ "Enedis",[2.67504218735518, 48.52054284872559]],
    [ "Enedis",[2.6855255000000002, 48.51969049999999]],
    [ "Enedis",[2.6772867426833904, 48.519314027366015]],
    [ "Enedis",[2.678980688493995, 48.52621846802985]],
    [ "Enedis",[2.687525759921324, 48.53525054842069]],
    [ "Enedis",[2.6886555578156486, 48.51833509107568]],
    [ "Enedis",[2.684178527876631, 48.534856056939496]],
    [ "Enedis",[0.08098286692015227, 49.51720449809887]],
    [ "Enedis",[4.256755992572861, 44.63870152066227]],
    [ "Enedis",[6.0627423821059425, 48.74946768895349]],
    [ "Enedis",[6.097033575531827, 48.75962831470905]],
    [ "Enedis",[6.063990386973179, 48.75144355938696]],
    [ "Hautes Alpes",[6.057568878868259, 48.767457881922674]],
    [ "Enedis",[6.067525127473099, 48.74973634687383]],
    [ "Enedis",[6.064692884664131, 48.75836523751584]],
    [ "Enedis",[6.061013328798186, 48.76683197052154]],
    [ "Enedis",[6.091160785502081, 48.75416367498514]],
    [ "Enedis",[6.0636054357481175, 48.7529123709139]],
    [ "Enedis",[6.088476669127297, 48.75694397867454]],
    [ "Enedis",[6.059987184454756, 48.7653230340294]],
    [ "Enedis",[6.087959265710222, 48.75195280481894]],
    [ "Enedis",[6.073078980149135, 48.74469994266425]],
    [ "Enedis",[6.0544558694131005, 48.76615416933437]],
    [ "Enedis",[6.0496070189067, 48.76986165844636]],
    [ "Enedis",[6.053474133229044, 48.77031964776431]],
    [ "Pompage Val Fleuri",[6.0596955230652885, 48.75853748374087]],
    [ "Enedis",[6.051840943164361, 48.77382479938556]],
    [ "Enedis",[6.06080901679261, 48.769214909599775]],
    [ "Enedis",[6.05944758598726, 48.77192650530785]],
    [ "Enedis",[6.064185270844213, 48.74652886927764]],
    [ "Enedis",[6.057487750816799, 48.76910111618456]],
    [ "Enedis",[6.101923868658661, 48.76122615796332]],
    [ "Poste électrique de Louisfert",[-1.440929679107441, 47.68042828738127]],
    [ "Poste électrique de Verlhaguet",[1.317399996766846, 43.982704577227715]],
    [ "Poste électrique de Saint-Eloi",[3.2122389023269693, 46.99099999245735]],
    [ "Enedis",[6.868961963758206, 48.99083847442714]],
    [ "Enedis",[6.832393228445563, 48.9969504501783]],
    [ "Enedis",[6.833318166109253, 48.99954848606466]],
    [ "Enedis",[2.0608168716749224, 48.78201990313704]],
    [ "Enedis",[2.060303166091052, 48.784367527053895]],
    [ "Enedis",[0.06927625, 49.51357624999999]],
    [ "Enedis",[0.07952875000000001, 49.50853675]],
    [ "Enedis",[2.056101621399177, 48.776630711934146]],
    [ "Enedis",[2.057880866228069, 48.77485719078947]],
    [ "Enedis",[0.07955124999999999, 49.5115385]],
    [ "Enedis",[2.0547256241264056, 48.77298382831966]],
    [ "Enedis",[1.4004813457475873, 43.09450012525584]],
    [ "Poste électrique de Roanne",[4.087587265183202, 46.033233884148366]],
    [ "Enedis",[4.178303859935262, 45.91139694742116]],
    [ "RTE",[2.721620208425127, 44.26714797565575]],
    [ "RTE",[2.665978049035025, 44.18448069734203]],
    [ "Enedis",[0.08229050000000014, 49.515211750000006]],
    [ "Enedis",[0.08057069287504467, 49.514761360902256]],
    [ "Enedis",[1.2482349910614525, 43.547064836499075]],
    [ "Enedis",[1.2457261422764232, 43.54737518089431]],
    [ "Enedis",[5.567178750000001, 46.8282205]],
    [ "Enedis",[5.563051249999999, 46.827418]],
    [ "Enedis",[5.563952749999999, 46.8290824]],
    [ "Enedis",[5.5602812, 46.8302149]],
    [ "Enedis",[6.110442300000001, 46.8089797]],
    [ "Poste électrique de Tolmer",[-1.6870598464060576, 49.48981958700915]],
    [ "Poste électrique de la Haye-du-Puits",[-1.5447721534836358, 49.28798837805751]],
    [ "Poste électrique de Périers",[-1.4010314453757844, 49.194584916341405]],
    [ "Enedis",[3.236469510795156, 50.334240717219586]],
    [ "Enedis",[3.223758724258289, 50.336030849912746]],
    [ "Enedis",[3.231931217303081, 50.339472028223575]],
    [ "Enedis",[3.2314665188405796, 50.32871075942028]],
    [ "Enedis",[3.231765333747927, 50.32652747042565]],
    [ "Enedis",[3.220365260940033, 50.329633013506225]],
    [ "Enedis",[3.2371799355027844, 50.32984996716505]],
    [ "Enedis",[3.228348260119677, 50.333449050686376]],
    [ "Enedis",[3.2262656402744145, 50.32836982389023]],
    [ "Enedis",[3.242312299150425, 50.330218283758114]],
    [ "Enedis",[3.2278945770317624, 50.33133994212727]],
    [ "La Clastre",[4.198152999999999, 43.787113749999996]],
    [ "Closade",[4.20026925, 43.78526525]],
    [ "Vinouse",[4.197343999999999, 43.789224999999995]],
    [ "Zone Artisanale",[4.19826075, 43.78154075]],
    [ "Enedis",[0.0831605, 49.5077365]],
    [ "Enedis",[1.2655224476458182, 43.57671703138908]],
    [ "Enedis",[1.2541620645168543, 43.57341257765376]],
    [ "Enedis",[1.2770739999999998, 43.58321]],
    [ "Enedis",[1.2763600556792867, 43.57585580178173]],
    [ "Enedis",[1.2890625, 43.571727499999994]],
    [ "Enedis",[1.2509200858591996, 43.57666336703185]],
    [ "Enedis",[1.2663611377819675, 43.56933003195341]],
    [ "Enedis",[1.2745018268398267, 43.574671508284816]],
    [ "Enedis",[1.2723306236867238, 43.57556213753582]],
    [ "Enedis",[1.268623826330532, 43.57117338375349]],
    [ "Enedis",[1.2408365000000003, 43.5720535]],
    [ "Enedis",[1.2676785282583625, 43.5799151799308]],
    [ "Enedis",[1.27943113121547, 43.57893246639042]],
    [ "Enedis",[1.2566570435982352, 43.575701085540835]],
    [ "Enedis",[1.281754071090047, 43.578538281200636]],
    [ "Enedis",[1.2581196201912384, 43.58196159083834]],
    [ "Enedis",[1.2878956189339696, 43.5746054814638]],
    [ "Enedis",[1.2436012585760514, 43.574002182847885]],
    [ "Enedis",[1.2616331700895203, 43.57662926171669]],
    [ "Enedis",[1.2526705, 43.57991799999999]],
    [ "Enedis",[1.2474066, 43.578112299999994]],
    [ "Enedis",[1.275538, 43.582596499999994]],
    [ "Enedis",[1.2810852592592588, 43.581604056030386]],
    [ "Enedis",[1.2509968499999997, 43.573102750000004]],
    [ "Enedis",[1.2554088433333332, 43.582647291904756]],
    [ "Enedis",[1.2441331506673978, 43.57039277632108]],
    [ "Enedis",[1.2725525049309667, 43.56825283431952]],
    [ "Enedis",[1.2400887166666665, 43.569531919999996]],
    [ "Enedis",[1.2542527201783724, 43.581446093645475]],
    [ "Enedis",[1.2810505, 43.575030999999996]],
    [ "Enedis",[1.264458178970918, 43.567942172259514]],
    [ "SNCF Réseau",[-0.06008173203841963, 47.20787646534261]],
    [ "Enedis",[-1.5202217500000001, 46.98154675]],
    [ "Enedis",[-1.51426275, 46.98566975]],
    [ "Enedis",[-1.5394730999999995, 46.9658236]],
    [ "Enedis",[-1.5178039999999995, 46.99178325000001]],
    [ "Enedis",[-1.5194268499999999, 46.987305049999996]],
    [ "Enedis",[-1.52166995, 46.98983654999999]],
    [ "Enedis",[-1.5229864999999991, 46.98664575]],
    [ "Enedis",[-1.4831024999999995, 46.96350150000001]],
    [ "Enedis",[1.250205482105264, 43.57924080185835]],
    [ "Enedis",[0.08050125000000001, 49.503650750000006]],
    [ "Poste électrique de Lavéra Martigues",[5.023807251588407, 43.38332228028088]],
    [ "Enedis",[0.08593547166941393, 49.50828870490923]],
    [ "Enedis",[0.08732500000000001, 49.5104195]],
    [ "Enedis",[0.08617733613109513, 49.50551800639489]],
    [ "Enedis",[0.08788050000000001, 49.50442350000001]],
    [ "RTE",[4.316800732889109, 46.21144667639354]],
    [ "Enedis",[4.70909303066271, 43.78604328288824]],
    [ "Enedis",[4.712747322580644, 43.794313053763446]],
    [ "Enedis",[3.940203095661546, 43.57321872568041]],
    [ "Enedis",[3.9371504004417353, 43.57585646680509]],
    [ "Enedis",[3.936904392301392, 43.574885178542175]],
    [ "Enedis",[3.938305248589386, 43.573593133722284]],
    [ "Enedis",[3.9462696000000004, 43.574962]],
    [ "Enedis",[6.199511587260872, 48.69767463225393]],
    [ "Enedis",[6.200246695909928, 48.698054148334606]],
    [ "Enedis",[6.196239816399288, 48.69772191379031]],
    [ "Enedis",[6.187494751948202, 48.7042381727973]],
    [ "Enedis",[6.190647569810866, 48.69940668268883]],
    [ "Enedis",[6.189763536050157, 48.70241491536049]],
    [ "Enedis",[6.19615409379845, 48.689641456072344]],
    [ "Enedis",[2.4041933221915044, 48.609997513780684]],
    [ "Enedis",[2.410508123269824, 48.62038321429735]],
    [ "Enedis",[2.406950000000001, 48.6216605]],
    [ "Enedis",[2.466047999406, 48.402513601326596]],
    [ "Enedis",[2.457184893724097, 48.39887551672587]],
    [ "Enedis",[2.4570193293450906, 48.40131023647468]],
    [ "Enedis",[2.4594965000000006, 48.402440500000004]],
    [ "Enedis",[2.4626442933333337, 48.403361949333345]],
    [ "Enedis",[6.132973, 48.705794499999996]],
    [ "Enedis",[6.140413386611313, 48.70411632935478]],
    [ "Enedis",[6.1332569999999995, 48.702993]],
    [ "Enedis",[6.139525717567156, 48.71251593826313]],
    [ "Enedis",[6.127956766872617, 48.70414716172161]],
    [ "Enedis",[6.156808376425855, 48.71329378200254]],
    [ "Enedis",[6.148021721926489, 48.70676892395438]],
    [ "Enedis",[6.126133981780537, 48.70304583416148]],
    [ "Enedis",[6.12990994160584, 48.704330654501206]],
    [ "Enedis",[6.139999428961749, 48.70579867008197]],
    [ "Enedis",[6.124298653679652, 48.699955965367955]],
    [ "Enedis",[2.4573727253483386, 48.406688449624866]],
    [ "Enedis",[2.4529022075150784, 48.40105958541391]],
    [ "Enedis",[2.472269206767474, 48.39771769386215]],
    [ "Enedis",[2.476974750000001, 48.3968209]],
    [ "Briard",[2.8290027395596056, 48.64375695383447]],
    [ "Enedis",[2.8352129999999995, 48.647234499999996]],
    [ "Enedis",[2.627414226132291, 48.56241485043988]],
    [ "Enedis",[2.629848453811207, 48.58406584115767]],
    [ "Enedis",[2.6330156672864384, 48.56730335027373]],
    [ "Enedis",[2.6322944999999995, 48.563553]],
    [ "Enedis",[2.615007, 48.5686655]],
    [ "Enedis",[2.6190590000000005, 48.563594]],
    [ "Enedis",[2.6266198504338942, 48.56980583205716]],
    [ "Enedis",[2.636570129277567, 48.568870839036755]],
    [ "Enedis",[2.610475636289223, 48.56507565839017]],
    [ "Enedis",[2.6263234999999994, 48.56475100000001]],
    [ "Enedis",[2.6338369999999998, 48.5697465]],
    [ "Enedis",[2.6091243042278256, 48.56924242177895]],
    [ "Enedis",[2.4683297512437807, 48.404370915422874]],
    [ "Enedis",[2.4759041435472735, 48.13894369427191]],
    [ "Enedis",[2.478528620530565, 48.13837727566321]],
    [ "Enedis",[2.47429332835249, 48.13563465613027]],
    [ "Enedis",[2.9919585846994536, 48.367532715846984]],
    [ "Enedis",[2.474455, 48.1421685]],
    [ "Enedis",[2.9850685, 48.36067849999999]],
    [ "Enedis",[2.9839514977825714, 48.36762874817212]],
    [ "Enedis",[2.975283847129009, 48.362654961222965]],
    [ "Enedis",[2.9838515412956177, 48.35846080932426]],
    [ "Enedis",[2.97201790444738, 48.361139474240424]],
    [ "Enedis",[2.4676617932794134, 48.39743540533791]],
    [ "Enedis",[2.4714263513513512, 48.400879882882876]],
    [ "Enedis",[2.4732717014361314, 48.39909424036281]],
    [ "Enedis",[2.7072191041597704, 48.15575608904335]],
    [ "Enedis",[2.8007917047736135, 48.39741585228437]],
    [ "Enedis",[2.6999860639269406, 48.14852525342466]],
    [ "Enedis",[2.6873606703068122, 48.136459274050964]],
    [ "Enedis",[2.7982407665647298, 48.39110541182466]],
    [ "Enedis",[2.7954229954954957, 48.39184910810811]],
    [ "Enedis",[2.7963553977044135, 48.39872786569637]],
    [ "Enedis",[2.7996952192572833, 48.40692464954414]],
    [ "Enedis",[2.681906256772673, 48.14130118786807]],
    [ "Enedis",[2.7516055, 48.1470375]],
    [ "Enedis",[2.7977285, 48.406568]],
    [ "Enedis",[2.8017359753623188, 48.399583104347826]],
    [ "Enedis",[2.6813691031653, 48.130743881594384]],
    [ "Enedis",[2.796051041506203, 48.39350321394951]],
    [ "Enedis",[2.811115614035088, 48.39534242807018]],
    [ "Enedis",[2.7099035763094728, 48.1511242072568]],
    [ "Enedis",[2.6958154833772054, 48.149374263917316]],
    [ "Enedis",[2.7011020419769807, 48.14960960528098]],
    [ "Enedis",[2.7120131006116526, 48.15044221263012]],
    [ "Enedis",[2.8021877049941923, 48.390137699767706]],
    [ "Enedis",[2.687434503056769, 48.154299168267826]],
    [ "Enedis",[2.6963365000000006, 48.150161999999995]],
    [ "Enedis",[2.805764681887366, 48.39679478747096]],
    [ "Enedis",[2.7951363613991425, 48.397023352689054]],
    [ "Enedis",[2.700015852631579, 48.14625960350876]],
    [ "Les Vauroux",[2.8982990551500407, 48.385454226277375]],
    [ "Enedis",[2.9048848546027455, 48.38227164280892]],
    [ "Enedis",[2.9044594597701163, 48.38719822988506]],
    [ "Rubrette",[2.9066139703661213, 48.38701711050569]],
    [ "Enedis",[2.8636928173313643, 48.180089164943375]],
    [ "Enedis",[2.875525478886205, 48.17075327741342]],
    [ "Enedis",[2.8684263743922105, 48.179291675341155]],
    [ "Enedis",[2.8911935000000004, 48.16974450000001]],
    [ "Enedis",[2.8769209536891682, 48.17338604709577]],
    [ "Enedis",[2.8942441940298513, 48.16844211442786]],
    [ "Enedis",[2.9011009313524605, 48.38590806215847]],
    [ "Enedis",[2.9015785000000003, 48.3889215]],
    [ "Vendôme",[2.90763130581761, 48.382631259040885]],
    [ "Enedis",[2.898615727115717, 48.3873385953235]],
    [ "Enedis",[2.8862293416551092, 48.38769514516875]],
    [ "Les Muses",[4.169946171130074, 43.73691760284542]],
    [ "Enedis",[4.175314221930974, 43.74080464438619]],
    [ "Enedis",[2.4772279878554384, 48.40098182103127]],
    [ "Enedis",[2.485879707894121, 48.40100496814241]],
    [ "Enedis",[4.198255168413311, 43.71342543188086]],
    [ "Fonzu",[4.1830525000000005, 43.7376705]],
    [ "Enedis",[2.4671358333333346, 48.400949999999995]],
    [ "Enedis",[2.4659590937500004, 48.407809864583335]],
    [ "Enedis",[2.465926329650092, 48.410333327808466]],
    [ "Poste électrique de Saint-Julien",[6.091190980634266, 46.12917828444061]],
    [ "Enedis",[2.470841532685483, 48.40635820036618]],
    [ "Enedis",[6.975132021806853, 49.11006033021807]],
    [ "Enedis",[2.474607071718117, 48.40281053040224]],
    [ "Enedis",[3.0252585000000014, 48.381240999999996]],
    [ "Enedis",[3.0270018770685576, 48.38555722222222]],
    [ "Enedis",[2.4669535, 48.408828500000006]],
    [ "Enedis",[2.732454410919541, 48.34435129597701]],
    [ "Enedis",[2.740711000718612, 48.336888075864906]],
    [ "Enedis",[2.7457630000000006, 48.334914250000004]],
    [ "Enedis",[2.4658125190951425, 48.41773454664005]],
    [ "Enedis",[2.465318314672953, 48.42223206874878]],
    [ "Enedis",[2.5033317058823523, 48.384014392156864]],
    [ "Enedis",[2.4939190199203187, 48.38453316069057]],
    [ "Enedis",[2.461204799999998, 48.407359199999995]],
    [ "Enedis",[2.4611988677170515, 48.404661739208926]],
    [ "Enedis",[2.677656999999999, 48.270218500000006]],
    [ "Enedis",[2.691769, 48.251813750000004]],
    [ "Enedis",[2.6811134180302565, 48.272914702788924]],
    [ "Enedis",[2.4184708000000006, 48.39759945000001]],
    [ "Enedis",[2.4172923456635846, 48.39882873315004]],
    [ "Enedis",[2.681812136683661, 48.264884768633166]],
    [ "Enedis",[2.6803889003115255, 48.26758779750779]],
    [ "Enedis",[2.6911860818522557, 48.26136083798815]],
    [ "Enedis",[2.6788019848805322, 48.27132011891892]],
    [ "Enedis",[2.692913790903427, 48.25003806504672]],
    [ "Enedis",[2.6808512863376976, 48.27030934810339]],
    [ "Enedis",[2.680526117971443, 48.26415429030035]],
    [ "Enedis",[2.6950872082406265, 48.252023413267395]],
    [ "Enedis",[2.698094340668296, 48.2462428785656]],
    [ "Enedis",[2.6832673063590873, 48.28156945331381]],
    [ "Enedis",[2.6903762569563727, 48.274050894766965]],
    [ "Enedis",[2.689756310569107, 48.2641816796748]],
    [ "Enedis",[2.6693264999999995, 48.275561499999995]],
    [ "Enedis",[2.6760178228013807, 48.265105178864694]],
    [ "Enedis",[2.6861445, 48.26324200000001]],
    [ "Enedis",[2.6877790122896577, 48.273995941009645]],
    [ "Enedis",[2.676416499999999, 48.28478700000001]],
    [ "Enedis",[2.682168697764819, 48.269632662779394]],
    [ "Enedis",[2.672611273675427, 48.27662204881101]],
    [ "Enedis",[2.6934444715639807, 48.25698867969984]],
    [ "Enedis",[2.6034494477611942, 48.34162480099503]],
    [ "Staline",[2.6063196762513305, 48.34690186794462]],
    [ "Enedis",[2.7429022304009583, 48.426517739078385]],
    [ "Enedis",[2.7521313201947604, 48.484905516886926]],
    [ "Enedis",[2.7597559423728817, 48.45400663107345]],
    [ "Enedis",[2.743849682653877, 48.45602010391687]],
    [ "Enedis",[2.746765829354553, 48.17518529796639]],
    [ "Enedis",[2.847738477192983, 48.38269635183413]],
    [ "Enedis",[2.864800150598614, 48.38734150031505]],
    [ "Enedis",[2.7218366, 48.1941588]],
    [ "Enedis",[2.8568537655631903, 48.388851554653336]],
    [ "Enedis",[2.8607711079950797, 48.38694130676507]],
    [ "Enedis",[2.7408017767457857, 48.18755204815962]],
    [ "Enedis",[2.9699534856737597, 48.581992779858155]],
    [ "Enedis",[2.9706814999999995, 48.584177499999996]],
    [ "Enedis",[0.12574475000000002, 49.54155025]],
    [ "Enedis",[2.639856649999999, 48.84687485]],
    [ "Enedis",[0.9921010000000001, 49.90496399999999]],
    [ "Enedis",[1.0249737380224262, 49.915580074753656]],
    [ "Enedis",[3.366621462293388, 48.530800168087225]],
    [ "Enedis",[2.3618172410666673, 48.62393228845715]],
    [ "Enedis",[2.3606405, 48.641155]],
    [ "Enedis",[2.3606023512022634, 48.639971412494106]],
    [ "Enedis",[2.335067606943218, 48.622865222124155]],
    [ "Enedis",[2.363273131990614, 48.64255689414678]],
    [ "Enedis",[2.360589814007964, 48.62179984000937]],
    [ "Enedis",[2.3597010000000003, 48.62010649999999]],
    [ "Enedis",[2.3515849999999996, 48.6236335]],
    [ "Enedis",[2.364237160959167, 48.623919221098596]],
    [ "Enedis",[2.354546, 48.621646999999996]],
    [ "Enedis",[2.361767329962925, 48.62227999089989]],
    [ "Enedis",[2.361652697296109, 48.63749646728218]],
    [ "Enedis",[2.3662791800387755, 48.643182895838315]],
    [ "Enedis",[2.3664515, 48.640987749999994]],
    [ "Enedis",[2.3630619223015543, 48.638193303933924]],
    [ "Enedis",[2.36427475, 48.64204925]],
    [ "Enedis",[2.3594415, 48.64298074999999]],
    [ "Enedis",[2.3630824999999995, 48.6396335]],
    [ "Enedis",[2.3630385, 48.6439175]],
    [ "Enedis",[2.3610402159119976, 48.63254904783831]],
    [ "Enedis",[2.365644024142464, 48.62954609943828]],
    [ "Enedis",[2.109669583817735, 48.867745853521626]],
    [ "Enedis",[2.1097523009092023, 48.87230239728405]],
    [ "Enedis",[2.364177575859743, 48.630924745336024]],
    [ "Enedis",[2.116206306047708, 48.86132658225074]],
    [ "Enedis",[2.365117100249185, 48.634998746789336]],
    [ "Enedis",[2.3650087166018166, 48.63718234392564]],
    [ "Enedis",[2.6896469999999995, 48.9407285]],
    [ "Enedis",[2.678940908965039, 48.93878027518172]],
    [ "Varlin",[2.693685999999999, 48.940734]],
    [ "Gabrielle",[2.6857284657419647, 48.93884198456653]],
    [ "Enedis",[2.667714418960245, 48.95352101681957]],
    [ "Arzillières",[2.694330084757348, 48.93889868853953]],
    [ "Mazure",[2.6759808104575167, 48.93962366666668]],
    [ "Sermaine",[2.6812548528769122, 48.93954918815246]],
    [ "Églantier",[2.6714236000000002, 48.83015749999999]],
    [ "Mazure",[2.6764269192071253, 48.93984963605569]],
    [ "Gilberte",[2.6837504876508107, 48.94012015980636]],
    [ "Taillevert",[2.67051544, 48.94314210666667]],
    [ "PC-DP la Forêt",[2.6773133, 48.82909035]],
    [ "Palmier",[2.6738525000000006, 48.82826599999999]],
    [ "Cocotier",[2.6796797500000014, 48.829430500000015]],
    [ "Enedis",[2.1139119333336622, 48.8691070863299]],
    [ "Enedis",[2.3520329999999996, 48.62072500000001]],
    [ "Kiosque",[2.3826205000000025, 48.64465099999999]],
    [ "Enedis",[2.35194, 48.6207235620915]],
    [ "Enedis",[2.3516133033821305, 48.62071569426216]],
    [ "Enedis",[3.078190191570881, 49.08747765900383]],
    [ "Enedis",[3.0736926020372324, 49.08992059088515]],
    [ "RTE",[1.4923737640093857, 43.616446009401606]],
    [ "Enedis",[2.5941885000000005, 48.826322499999996]],
    [ "Enedis",[2.6265435, 48.808865999999995]],
    [ "Poste électrique de Finhan",[1.2434670740617857, 43.90739066474552]],
    [ "RTE",[1.420925986768987, 43.567108132928404]],
    [ "RTE",[1.1984850644000131, 43.56523032992384]],
    [ "Poste électrique de Leguevin",[1.27422513621782, 43.5982084773152]],
    [ "Enedis",[-1.4361759500000002, 46.902512]],
    [ "Enedis",[-1.47059095, 46.888643450000004]],
    [ "Enedis",[0.12001171317829402, 49.551065334883724]],
    [ "Bonneau",[0.39388353283119343, 46.5984907315759]],
    [ "Enedis",[-0.604456653549646, 44.830023819104305]],
    [ "Poste électrique Lion d’Or",[-1.5147502190028175, 47.18909125589574]],
    [ "Enedis",[6.178644464204287, 48.645749471956236]],
    [ "Enedis",[6.180448098630138, 48.6383827456621]],
    [ "Enedis",[6.1760043750828375, 48.64941035498123]],
    [ "Enedis",[6.192774962427559, 48.644072569316926]],
    [ "Enedis",[6.170793667068272, 48.64739599518073]],
    [ "Enedis",[6.175926000000002, 48.63977]],
    [ "Enedis",[6.17705378833648, 48.64147105454164]],
    [ "Enedis",[6.170736054916985, 48.64428256023841]],
    [ "Enedis",[6.175360654182271, 48.64687032833958]],
    [ "Enedis",[6.190551873333332, 48.64345007652174]],
    [ "Enedis",[6.18744080134468, 48.64428176783276]],
    [ "Enedis",[6.183258665129337, 48.640106288483395]],
    [ "Enedis",[6.178134926335174, 48.637105699815834]],
    [ "Enedis",[6.1847025, 48.637577]],
    [ "Enedis",[6.184423830825353, 48.64507490971272]],
    [ "Enedis",[6.173330902523161, 48.649069929036074]],
    [ "115502102",[6.186004702799305, 48.643827665586386]],
    [ "Enedis",[6.177499295243354, 48.639052454503634]],
    [ "RTE",[2.4199640453267817, 44.26882187359471]],
    [ "Rodez",[2.574033630115207, 44.3432778544481]],
    [ "Enedis",[6.116309380690802, 48.624039729255095]],
    [ "Enedis",[6.116938472548021, 48.62676235407106]],
    [ "Enedis",[6.126776750135209, 48.63375290156842]],
    [ "Enedis",[6.112774931232092, 48.626405545367724]],
    [ "Enedis",[6.121975719307933, 48.627513159580346]],
    [ "Enedis",[6.117939855896062, 48.62842281493604]],
    [ "Enedis",[6.124480000000001, 48.63081699999999]],
    [ "Enedis",[6.129604206736762, 48.63359804630062]],
    [ "Enedis",[6.130085333333332, 48.637443999999995]],
    [ "Enedis",[6.124929852347797, 48.62952133815389]],
    [ "Enedis",[5.062994056374016, 47.323570907393126]],
    [ "Enedis",[5.064133564123088, 47.32237176350364]],
    [ "École",[6.987291096908748, 43.69861470640745]],
    [ "RTE",[7.477739703430579, 43.8827304008308]],
    [ "Enedis",[6.52451975, 48.5854469]],
    [ "CHAPELLE 37189 P08",[0.923381, 46.856274]],
    [ "Gare 189 P 28",[0.9291686203342336, 46.85014169998999]],
    [ "Enedis",[6.528425184043496, 48.58295669688158]],
    [ "Enedis",[6.483442073364909, 48.58448987869328]],
    [ "Enedis",[-1.5687104716925757, 46.63882109519261]],
    [ "Enedis",[-1.57223175, 46.65604375]],
    [ "Enedis",[-1.56439605, 46.65731075]],
    [ "Enedis",[-1.5684337500000003, 46.65631625000001]],
    [ "Enedis",[-1.560135522721827, 46.64278552375771]],
    [ "Enedis",[-1.5670574999999984, 46.658845500000005]],
    [ "RTE",[2.2663586011035277, 44.27304320400392]],
    [ "Enedis",[0.07021739303482585, 48.41863310945273]],
    [ "Enedis",[0.06090860463192757, 48.42220720264682]],
    [ "Enedis",[0.07001150000000086, 48.42112550000001]],
    [ "Enedis",[0.0843691602279927, 48.42216883111673]],
    [ "Enedis",[0.06842632587621178, 48.416435031319914]],
    [ "Enedis",[0.06234520167564332, 48.422752964093355]],
    [ "Enedis",[0.07430221006289307, 48.41840640754718]],
    [ "Enedis",[0.06142973179866544, 48.4246974447794]],
    [ "Enedis",[0.06372663479992757, 48.421969455187394]],
    [ "Enedis",[0.061327499999999265, 48.421203]],
    [ "Enedis",[0.08300985425855109, 48.42044867558562]],
    [ "Enedis",[0.07985125921086936, 48.41898815564847]],
    [ "Enedis",[0.07987740192926174, 48.416742609217586]],
    [ "Enedis",[0.07849238211382142, 48.42170274796748]],
    [ "Enedis",[0.06435287429378464, 48.41839843644069]],
    [ "Enedis",[0.055689, 48.423841]],
    [ "Enedis",[0.07229990318118941, 48.41353168188105]],
    [ "Enedis",[0.0761544881383185, 48.420766369823085]],
    [ "Enedis",[0.056684808560604906, 48.424706852717364]],
    [ "Enedis",[0.06757508447488587, 48.419322086105666]],
    [ "Enedis",[3.128726020114943, 48.94740331276468]],
    [ "Enedis",[3.1259361239269063, 48.95249596568687]],
    [ "Enedis",[3.125249857599326, 48.9493904144533]],
    [ "Enedis",[2.0570791250555813, 48.7873607185509]],
    [ "Enedis",[2.055908052534058, 48.78457064070364]],
    [ "Enedis",[2.0596923574794146, 48.78757334174146]],
    [ "Enedis",[2.056085893933326, 48.78465675044587]],
    [ "Enedis",[3.0277909974497934, 49.073491978110724]],
    [ "Enedis",[2.0481733000000006, 48.77443385]],
    [ "Enedis",[2.0512067408871597, 48.77595606883303]],
    [ "Enedis",[2.0522665000000004, 48.7736073]],
    [ "Enedis",[2.0539827902995, 48.77438802827142]],
    [ "Enedis",[2.0514545176888395, 48.773090659521436]],
    [ "Enedis",[-0.49448675000000003, 43.784934]],
    [ "Enedis",[4.6531452999999985, 46.65238715]],
    [ "Enedis",[4.6455062499999995, 46.64037824999999]],
    [ "Enedis",[-0.4864277000688828, 43.78800916114459]],
    [ "Poste électrique de Rance-Poste",[-2.0320841247742663, 48.61589006431116]],
    [ "Poste électrique de Rance-Poste",[-2.033165217397482, 48.617450804407795]],
    [ "Enedis",[2.0620843973025864, 48.77319425984965]],
    [ "Enedis",[2.0620814236458425, 48.76957475621418]],
    [ "Enedis",[2.066417700537176, 48.76370624284825]],
    [ "Enedis",[2.0783117587623408, 48.769037640958786]],
    [ "Enedis",[2.0617419333333333, 48.76281045309381]],
    [ "Enedis",[2.054902145103557, 48.767231345609545]],
    [ "Enedis",[2.069160902627491, 48.7666556681337]],
    [ "Enedis",[2.0651990400133124, 48.758480558193625]],
    [ "Enedis",[2.0615141799083325, 48.7677700406268]],
    [ "Enedis",[4.662478078918658, 46.612869967961394]],
    [ "Enedis",[3.289573064150943, 50.3592543527044]],
    [ "Enedis",[3.281379499999999, 50.37850350000001]],
    [ "Enedis",[3.287650891304348, 50.364784210144926]],
    [ "Enedis",[3.284700596736597, 50.3559163020313]],
    [ "Enedis",[3.2838707158469953, 50.35893654597291]],
    [ "Enedis",[3.2700275042016784, 50.35939324126985]],
    [ "Enedis",[3.272446028742515, 50.36598182894211]],
    [ "Enedis",[3.274470070199588, 50.360984246616205]],
    [ "Enedis",[3.2663437932949604, 50.3701565216601]],
    [ "Enedis",[3.277421165266106, 50.3570425070028]],
    [ "Enedis",[3.2675058290993078, 50.36008049499615]],
    [ "Enedis",[3.276625499999999, 50.3664125]],
    [ "Enedis",[3.271737489407695, 50.363043576307824]],
    [ "Enedis",[3.2614064968137257, 50.37291377977942]],
    [ "Enedis",[3.268017727483899, 50.37247207897793]],
    [ "Enedis",[3.2693595, 50.357096999999996]],
    [ "Enedis",[3.2641749972222227, 50.35575489444445]],
    [ "Enedis",[3.266233912674436, 50.35875512386708]],
    [ "Enedis",[3.275181499999999, 50.350306]],
    [ "Enedis",[3.287784565776459, 50.351959914606]],
    [ "Enedis",[3.2826155436720152, 50.34661019172113]],
    [ "Enedis",[3.276978774152271, 50.35241418106206]],
    [ "Enedis",[3.2777429055191774, 50.340712695977544]],
    [ "Enedis",[3.3147863409223586, 50.36582401050788]],
    [ "Enedis",[3.309670807200929, 50.360368660859464]],
    [ "Enedis",[2.3848181226701346, 51.02755409102731]],
    [ "Enedis",[2.3805267129587326, 51.00926454484544]],
    [ "Enedis",[2.408264, 51.0155865]],
    [ "Enedis",[2.371929874584467, 51.016481555454824]],
    [ "Enedis",[2.3755077920792087, 51.01394993729374]],
    [ "Enedis",[2.3950478489464326, 51.022927164508765]],
    [ "Enedis",[2.3793038960207955, 51.014681244551085]],
    [ "Enedis",[2.3682505000000007, 51.02141300000001]],
    [ "Enedis",[2.3961764999999997, 51.03201049999999]],
    [ "Enedis",[2.3917978364587875, 51.025955711440616]],
    [ "Enedis",[2.3925094999999987, 51.0239435]],
    [ "Enedis",[2.3920911276127614, 51.01842635203521]],
    [ "Enedis",[2.393832747807877, 51.030629725690346]],
    [ "Enedis",[2.40538561712721, 51.0316101965895]],
    [ "Enedis",[2.378458575953259, 51.016567473144725]],
    [ "Enedis",[2.3987595, 51.0195525]],
    [ "Enedis",[2.3763394999999994, 51.02325049999999]],
    [ "Enedis",[2.395445777064002, 51.019919275755406]],
    [ "Enedis",[2.3999979999999987, 51.0316745]],
    [ "Enedis",[2.3764636812730258, 51.01211285317207]],
    [ "Enedis",[2.3875309090909096, 51.019836258098216]],
    [ "Enedis",[2.4039386691871454, 51.021947016383116]],
    [ "Enedis",[2.407541365082809, 51.01339116221885]],
    [ "Enedis",[2.386127, 51.01296700000001]],
    [ "Enedis",[2.3912886885760294, 51.020699565511144]],
    [ "Enedis",[2.3798755267449443, 51.02016319471624]],
    [ "Enedis",[2.3987632691282825, 51.027218169902305]],
    [ "Enedis",[2.3972393949094144, 51.0187627011226]],
    [ "Enedis",[2.3935846864843446, 51.02181719688863]],
    [ "Enedis",[2.401929778200253, 51.01589091634981]],
    [ "Enedis",[2.3813602066934396, 51.00712754413208]],
    [ "Enedis",[2.3863975, 51.015189]],
    [ "Enedis",[2.374645418168577, 51.01609525414163]],
    [ "Enedis",[2.4031251796489013, 51.021845529380855]],
    [ "Enedis",[2.3825399932756945, 51.015343300672434]],
    [ "Enedis",[2.3931172399741785, 51.01771134261277]],
    [ "Enedis",[2.3950246666666675, 51.03183]],
    [ "Enedis",[2.3918038025776216, 51.0198889718805]],
    [ "Enedis",[2.3699034999999973, 51.011359]],
    [ "Enedis",[2.3903111097954786, 51.013232297811264]],
    [ "Enedis",[2.383756500000001, 51.015658]],
    [ "Enedis",[2.3962298931034485, 51.026172144827584]],
    [ "Enedis",[2.400232336947593, 51.02876040783636]],
    [ "Enedis",[2.3928432815184997, 51.0275325444503]],
    [ "Enedis",[2.4035589361729843, 51.025580046502554]],
    [ "Enedis",[2.3949355765111, 51.02882027558299]],
    [ "Enedis",[2.3742016295625947, 51.01899372961288]],
    [ "Enedis",[2.386452137887413, 51.0196037311828]],
    [ "Enedis",[2.3802113577480064, 51.02307247422896]],
    [ "Enedis",[2.390355732519355, 51.03081181310853]],
    [ "Enedis",[2.3900748104389398, 51.02616204452565]],
    [ "Enedis",[2.3700909386706446, 51.01466510376263]],
    [ "Enedis",[2.404433585307243, 51.02253834569173]],
    [ "Enedis",[2.370025783772819, 51.01897910831643]],
    [ "Enedis",[2.3999902167611853, 51.021293860743526]],
    [ "Enedis",[2.4032557486648884, 51.03339707254116]],
    [ "Enedis",[2.3850576175134237, 51.0220939190417]],
    [ "Enedis",[3.2954257702552723, 50.36638994302627]],
    [ "Enedis",[3.2967722676224613, 50.36146994305057]],
    [ "Enedis",[3.303925834624608, 50.365314991145546]],
    [ "Enedis",[3.3001650537913165, 50.36784406135234]],
    [ "Enedis",[3.2944432535211265, 50.3706800516432]],
    [ "Enedis",[3.300708630522089, 50.35883573627843]],
    [ "Enedis",[3.2990316451094754, 50.365809133612295]],
    [ "Enedis",[2.616520681804046, 48.869016939243124]],
    [ "Enedis",[2.6149384999999996, 48.8689155]],
    [ "Enedis",[3.3360718112678867, 50.36860265896127]],
    [ "Enedis",[3.335902603174603, 50.37081522943723]],
    [ "Enedis",[3.3326666943674983, 50.36999503878116]],
    [ "Enedis",[3.331250951347882, 50.36244834848096]],
    [ "Enedis",[3.318656252229827, 50.35683344036506]],
    [ "Enedis",[3.3373439999999994, 50.3647145]],
    [ "Enedis",[3.3288735693940246, 50.36086881867263]],
    [ "Enedis",[3.3186818485257708, 50.424174837047055]],
    [ "Enedis",[-2.050995883333333, 47.90770281666667]],
    [ "Enedis",[3.318632210990029, 50.39765377934925]],
    [ "Enedis",[3.3012721050821554, 50.39897368628201]],
    [ "Enedis",[3.3144597260273976, 50.39765628614916]],
    [ "Enedis",[3.328523, 50.410067500000004]],
    [ "Enedis",[3.210755868913857, 50.309279741573036]],
    [ "Enedis",[3.204457822127356, 50.303963650302386]],
    [ "Enedis",[3.207211, 50.3191095]],
    [ "Enedis",[3.2098961120987655, 50.30836577037038]],
    [ "Enedis",[3.211053324324324, 50.30264016216216]],
    [ "Enedis",[3.205965427664792, 50.32255181984711]],
    [ "Enedis",[3.2047294999999996, 50.300253999999995]],
    [ "Enedis",[3.197019937823835, 50.30086180656304]],
    [ "Enedis",[3.2064890671892496, 50.304768178798064]],
    [ "Enedis",[3.208569601770349, 50.30556332160422]],
    [ "Enedis",[3.2170805268817215, 50.30675602648051]],
    [ "Enedis",[-0.8954979999999998, 46.056616999999996]],
    [ "P7 Gare",[-3.9199876803028895, 47.877561012439585]],
    [ "RTE",[2.0162132527494174, 44.351584504905425]],
    [ "Enedis",[-0.8516397101914214, 46.166991164928646]],
    [ "RTE",[0.3438715275823059, 46.60837369895548]],
    [ "Enedis",[4.6554267992501694, 46.43156318098159]],
    [ "Enedis",[-0.928489680089485, 46.05367880760626]],
    [ "Enedis",[-0.9339985, 46.0531425]],
    [ "Enedis",[-0.9345338183622325, 46.05631133680131]],
    [ "Enedis",[-0.9252065889648471, 46.040922328745694]],
    [ "Enedis",[-0.9323634999999986, 46.05971050000001]],
    [ "Poste électrique de Chaumont (Chasseneuil-du-Poitou)",[0.3591901243809802, 46.63170275818135]],
    [ "FARGHET",[7.2760064433811795, 43.95698611483253]],
    [ "Enedis",[6.028324351831299, 46.25448778061412]],
    [ "Enedis",[6.026995000000001, 46.257077499999994]],
    [ "Enedis",[6.02794946488493, 46.25901459688196]],
    [ "Enedis",[6.029541906616416, 46.2574224057789]],
    [ "Enedis",[6.026259228177642, 46.254960567126076]],
    [ "Enedis",[4.699185825384385, 46.708937941955924]],
    [ "Enedis",[4.696991082495017, 46.70381841075778]],
    [ "Condemines",[4.709643717472645, 46.71381032369636]],
    [ "Saint-Jaques",[4.695400900981608, 46.709532733149494]],
    [ "Les Franets",[4.695169310793097, 46.71569389497448]],
    [ "Centrale électrique",[0.36088119651072276, 46.55736844975587]],
    [ "Bourg",[4.602973984271098, 46.6715907842298]],
    [ "Enedis",[6.157617240292084, 48.74183816219353]],
    [ "Enedis",[6.159216378825476, 48.726600296939615]],
    [ "Enedis",[6.163939836201401, 48.73368703632886]],
    [ "Enedis",[6.15511434060704, 48.7291748670079]],
    [ "Enedis",[6.150536239479116, 48.726989598973056]],
    [ "Enedis",[6.1714625000000005, 48.727723499999996]],
    [ "Enedis",[6.169478500000002, 48.744153]],
    [ "Enedis",[6.148410825082507, 48.72129194719472]],
    [ "Enedis",[6.1514185, 48.735627]],
    [ "Enedis",[6.16953889539007, 48.744142957446805]],
    [ "Enedis",[6.166209828003456, 48.74331561480841]],
    [ "Enedis",[6.154615776237624, 48.73015907128713]],
    [ "Enedis",[6.153629184205483, 48.723597550277944]],
    [ "Enedis",[6.1553091587410975, 48.72645980932689]],
    [ "Enedis",[6.1521866141502555, 48.722891072210075]],
    [ "Enedis",[6.15629759883796, 48.73976790583172]],
    [ "Enedis",[6.1686330573025865, 48.73182192452831]],
    [ "Enedis",[6.158981053778144, 48.732818916507156]],
    [ "Enedis",[6.15777070185887, 48.72528938368525]],
    [ "Enedis",[6.168951049711224, 48.734279165841585]],
    [ "Enedis",[6.160299632240205, 48.72958264381034]],
    [ "Enedis",[6.160756710818789, 48.73942789106538]],
    [ "Enedis",[6.16812188872433, 48.738609564968414]],
    [ "Enedis",[6.4873749, 45.437144599999996]],
    [ "Enedis",[-1.4296289999999998, 46.932022499999995]],
    [ "Enedis",[-0.9225369379847141, 44.03473832270143]],
    [ "RTE",[2.863549588668121, 48.95660660124087]],
    [ "Sous-station de Villenoy",[2.8629926275344957, 48.93793915150312]],
    [ "Enedis",[5.206718499999999, 46.21576725]],
    [ "Poste électrique d'Onet le Chateau",[2.6074795015007926, 44.387281920692494]],
    [ "RTE",[2.6069467442535696, 44.35855491513227]],
    [ "Sous-station d'Ablaincourt",[2.826893797702731, 49.83450899516495]],
    [ "Clamart",[2.2593240749626413, 48.79945455434415]],
    [ "Saint-Ferjeux",[5.983287285117726, 47.234301692751124]],
    [ "Enedis",[4.927332869939988, 45.78877132133116]],
    [ "Enedis",[-2.050629090299987, 48.2327355517995]],
    [ "Poste électrique de Decines",[4.935424301318435, 45.75107707304624]],
    [ "réséda",[6.083898488379422, 49.1095448138344]],
    [ "réséda",[6.08689253968254, 49.11152518342151]],
    [ "réséda",[6.0892023353433835, 49.11108603819094]],
    [ "réséda",[6.092121273309324, 49.11141194797919]],
    [ "Enedis",[-2.0495182499999993, 48.20381725]],
    [ "Enedis",[-2.048091285513362, 48.196712083825595]],
    [ "Enedis",[-2.043580911970534, 48.20197690534069]],
    [ "Enedis",[-2.048981632598039, 48.2055089382353]],
    [ "Enedis",[-2.050620840275726, 48.19217299315607]],
    [ "Enedis",[-2.0500946607219483, 48.19319992759056]],
    [ "Enedis",[-2.044375475483041, 48.20408801571512]],
    [ "Enedis",[-2.0523479119004264, 48.20507672717508]],
    [ "Enedis",[-2.032713699999998, 48.1974122]],
    [ "Enedis",[-2.057993287578761, 48.19213593389399]],
    [ "Enedis",[-2.040698347769029, 48.19626266876641]],
    [ "Enedis",[-2.096614677700852, 48.2201445774598]],
    [ "Enedis",[-2.1104801238457895, 48.21400119518234]],
    [ "Enedis",[-2.03257525, 48.20001895000001]],
    [ "Enedis",[-2.0515666, 48.196752]],
    [ "Enedis",[-2.089134155758036, 48.20936883091623]],
    [ "Enedis",[-2.0675072144092925, 48.19666365608803]],
    [ "Enedis",[-2.0573342299935775, 48.19207659890816]],
    [ "Enedis",[-2.0530264999999996, 48.2019755]],
    [ "Enedis",[-2.043736442456141, 48.200414403859654]],
    [ "Enedis",[-2.0317394999999987, 48.19857075]],
    [ "Enedis",[-2.04573905, 48.1944501]],
    [ "Enedis",[-2.0465852, 48.2016352]],
    [ "Enedis",[-2.038906820379486, 48.19826383256296]],
    [ "Enedis",[-2.053928961185604, 48.19882496254999]],
    [ "Enedis",[-2.0427400485666096, 48.194095478058834]],
    [ "Enedis",[-0.9001735655172414, 44.02040089655172]],
    [ "IS Gallieni 83",[2.268177979872978, 48.82308007867008]],
    [ "Enedis",[2.4128287071915597, 48.625908549980714]],
    [ "Enedis",[2.4225430000000006, 48.6230095]],
    [ "Annie",[2.4242015408618123, 48.619048656265484]],
    [ "Enedis",[2.4285295543959524, 48.61515181293486]],
    [ "Enedis",[2.4509979785788927, 48.585521827324975]],
    [ "Enedis",[4.86901605, 45.537802000000006]],
    [ "Enedis",[4.875177217816955, 45.51510754638998]],
    [ "Enedis",[4.865141910525796, 45.540520583900566]],
    [ "Enedis",[4.85459999022179, 45.50755172460605]],
    [ "Enedis",[4.875086657486228, 45.53877770989819]],
    [ "Enedis",[4.8685825, 45.5048335]],
    [ "Enedis",[4.896039253333334, 45.51321033333333]],
    [ "Enedis",[2.4759428105582804, 50.48394492291521]],
    [ "Enedis",[2.480028444493751, 50.49297818142149]],
    [ "Enedis",[2.4661296065576366, 50.49499492707847]],
    [ "Enedis",[2.477934120827179, 50.483988301526345]],
    [ "Enedis",[2.4988711821154164, 50.483646036782744]],
    [ "Enedis",[2.496167612227075, 50.48532780931586]],
    [ "Enedis",[2.48596456173253, 50.49501661821835]],
    [ "Enedis",[2.5088735563308617, 50.488722281581616]],
    [ "Enedis",[2.4771395587348612, 50.48613391272936]],
    [ "Enedis",[2.481865864788233, 50.49351209427609]],
    [ "Enedis",[2.472057886433394, 50.484928174340084]],
    [ "Enedis",[2.478006894603545, 50.48187623456067]],
    [ "Enedis",[2.487273943908711, 50.48897811314689]],
    [ "Enedis",[2.4780167148780246, 50.48183954700608]],
    [ "Enedis",[2.496355265199162, 50.487009054507325]],
    [ "Enedis",[2.4876774306484917, 50.4843679692689]],
    [ "Enedis",[2.4846683575757575, 50.486802577272734]],
    [ "Enedis",[2.4994727653053617, 50.484489544967644]],
    [ "Déchetterie",[-1.4076422480301813, 48.26168917666936]],
    [ "Enedis",[4.944637822347754, 47.13599229417654]],
    [ "Enedis",[4.970045153012569, 47.13142602080624]],
    [ "Enedis",[4.940851749999999, 47.14440025]],
    [ "Enedis",[4.96408164290831, 47.133509858285585]],
    [ "Enedis",[4.9371590437598725, 47.146131381990514]],
    [ "Enedis",[4.96694264607119, 47.13288679762704]],
    [ "Enedis",[4.94138949122807, 47.14268696257311]],
    [ "Enedis",[4.947988414649763, 47.139742814957096]],
    [ "Enedis",[4.960873883356385, 47.13437182572614]],
    [ "Enedis",[4.95797106077348, 47.129758681399636]],
    [ "Enedis",[4.938911799338478, 47.1454992091143]],
    [ "Enedis",[4.948644856975185, 47.141953600100614]],
    [ "Enedis",[6.282024603059078, 46.84036357032916]],
    [ "Enedis",[2.449010925349923, 48.532851692586824]],
    [ "Enedis",[2.4411713364177134, 48.53862903547036]],
    [ "Enedis",[2.4405940464333797, 48.53059356348138]],
    [ "Enedis",[2.4434085000000003, 48.531319]],
    [ "réséda",[6.117456955475946, 49.15565616240191]],
    [ "Enedis",[0.17463199999999998, 49.577676499999995]],
    [ "réséda",[6.119299897738876, 49.15707442793581]],
    [ "Enedis",[0.18372941817268895, 49.56243829060794]],
    [ "RTE",[-0.20241159005877274, 47.0003237891248]],
    [ "La Rousselière",[-1.4117751931780362, 48.25749970682195]],
    [ "Enedis",[3.641801000000002, 50.0739225]],
    [ "Enedis",[-0.9963476844954493, 46.28378106946145]],
    [ "réséda",[6.1119204499999995, 49.1570691]],
    [ "réséda",[6.110898177856725, 49.15915854115729]],
    [ "réséda",[6.1094045, 49.1603155]],
    [ "La Garenne",[5.0233105, 45.68896600000001]],
    [ "Haut du Château",[5.026565999999999, 45.685452250000004]],
    [ "Enedis",[5.024272, 45.692431500000005]],
    [ "Les Templiers",[-3.4614336000000008, 48.736532600000004]],
    [ "Poste électrique d'Yvelines-Ouest",[1.8288185348619965, 48.804375626410014]],
    [ "Enedis",[4.0687455168682805, 43.620258298870965]],
    [ "Enedis",[4.0667115, 43.61952175000001]],
    [ "Enedis",[4.066459999999999, 43.616956499999986]],
    [ "Enedis",[4.0721637500000005, 43.62203725]],
    [ "Enedis",[4.06935475, 43.62216325]],
    [ "Enedis",[2.1587669864341086, 48.89790967393411]],
    [ "Enedis",[2.160106266597867, 48.9006053501892]],
    [ "Enedis",[2.1638308409804314, 48.900748309623985]],
    [ "Enedis",[5.505377696419558, 46.50226072640887]],
    [ "Enedis",[5.5051571547077, 46.50458874909149]],
    [ "RTE",[1.678667981794997, 46.800366882123996]],
    [ "Plein Soleil 2",[5.034863694835681, 45.702347957746476]],
    [ "Les Mésanges",[5.0307095, 45.7011872]],
    [ "Enedis",[-1.4077867686610281, 46.879863331822605]],
    [ "Enedis",[-1.3998828000000003, 46.8710072]],
    [ "Enedis",[-1.3963914, 46.895149749999995]],
    [ "Enedis",[-1.4214937, 46.881868450000006]],
    [ "réséda",[6.1048636499999995, 49.15544835]],
    [ "Côteau du Plâtre",[5.035364293564716, 45.68642354880694]],
    [ "Enedis",[-3.4548455359765065, 48.74579684140969]],
    [ "Kra Douar",[-3.463173868707483, 48.74037373197279]],
    [ "Clair Matin 1",[5.045010627062706, 45.6794007920792]],
    [ "Poste Électrique de Mousseaux",[1.72643680282859, 46.811867811878116]],
    [ "RTE",[1.7353215223498615, 46.823635018760235]],
    [ "Poste électrique d'Avoine",[0.16348462720139453, 47.22425638733647]],
    [ "Poste électrique de Chinon",[0.16968862340229057, 47.23087912906168]],
    [ "RTE",[1.4988735475305688, 46.60678840742735]],
    [ "Enedis",[-0.86306825, 46.326719499999996]],
    [ "Enedis",[0.16733399999999893, 49.553623249999994]],
    [ "Enedis",[0.1736997499999996, 49.554471750000005]],
    [ "Poste électrique du Rochereau",[0.12868322607829616, 46.69651464914266]],
    [ "Enedis",[2.3246330000000004, 46.846700500000004]],
    [ "Enedis",[2.3233148237302603, 46.85469396585574]],
    [ "Enedis",[2.3226100000000005, 46.85728500000001]],
    [ "Enedis",[2.3116441159420313, 46.854596826086954]],
    [ "Enedis",[4.833820931747111, 47.03483810351235]],
    [ "Poste électrique de Reichstett",[7.731060735022639, 48.64499097872224]],
    [ "Enedis",[0.16737550000000004, 49.54671299999999]],
    [ "Enedis",[0.167906, 49.5438795]],
    [ "Baconnier",[5.044067000000001, 45.68444999999999]],
    [ "Grande Terre",[5.046941175059953, 45.67865748920863]],
    [ "Chante Alouette",[5.057322152180937, 45.674920459322315]],
    [ "Enedis",[4.793395433799526, 45.72479786246403]],
    [ "RTE",[4.9704270076505015, 45.82769884736216]],
    [ "RTE",[4.837064497313079, 45.84742581055964]],
    [ "Enedis",[4.761746185437395, 45.80654234986503]],
    [ "Enedis",[0.17024904320156026, 49.552137583303754]],
    [ "Enedis",[2.462084672485454, 50.48238848877806]],
    [ "Enedis",[2.4731798047249915, 50.48168995939461]],
    [ "Enedis",[0.169178, 49.549502000000004]],
    [ "Enedis",[2.4289047572552214, 50.48206256983998]],
    [ "Enedis",[-0.5057834999999993, 44.8377975]],
    [ "RTE",[6.04462510807856, 47.22722397166699]],
    [ "Enedis",[3.7554966682044317, 47.2424063540824]],
    [ "Enedis",[3.94467768888889, 47.18496653333334]],
    [ "Enedis",[3.951081538440305, 47.18159980717736]],
    [ "Enedis",[3.949961080931958, 47.183387280923014]],
    [ "Enedis",[3.937603168658699, 47.26118917481589]],
    [ "Enedis",[3.669807010733453, 47.250415827668455]],
    [ "Enedis",[3.685367324496811, 47.256898180166914]],
    [ "Enedis",[3.6819007994835666, 47.259522171639134]],
    [ "Enedis",[3.673721689846308, 47.24664798286722]],
    [ "Enedis",[3.6744909999999997, 47.2466575]],
    [ "Enedis",[3.6894508750877186, 47.257333712982444]],
    [ "Enedis",[3.6578784251207725, 47.061636597826094]],
    [ "Enedis",[4.782507397325693, 45.74502109837631]],
    [ "Enedis",[4.783111899371069, 45.73972930188679]],
    [ "Enedis",[5.0178698366216645, 47.337906594353385]],
    [ "Enedis",[5.0162682654202255, 47.33670785754182]],
    [ "Enedis",[0.17044904929975277, 49.54971058335081]],
    [ "Y019",[4.790809197654942, 45.74698854606365]],
    [ "Enedis",[4.792759780532126, 45.744940919737346]],
    [ "Enedis",[4.789006499999999, 45.74252200000001]],
    [ "Enedis",[5.022397398536725, 47.335542407622235]],
    [ "Enedis",[5.026796110656669, 47.33602024585321]],
    [ "Enedis",[5.023254499999998, 47.336895000000005]],
    [ "Enedis",[5.03218215455225, 47.350905845753495]],
    [ "Enedis",[5.018671513111447, 47.33932761333838]],
    [ "Enedis",[5.027769223911969, 47.34724378004451]],
    [ "Enedis",[5.029493999999999, 47.3443345]],
    [ "Enedis",[5.025380114540247, 47.33831483943154]],
    [ "Enedis",[5.021261708614232, 47.348340905617974]],
    [ "Enedis",[5.024335044181022, 47.3453968286896]],
    [ "Enedis",[5.015000692441924, 47.344630261498274]],
    [ "Enedis",[5.0192144999999995, 47.347155]],
    [ "Enedis",[5.025545713850387, 47.34946741248546]],
    [ "Enedis",[5.034345902232487, 47.336641403643824]],
    [ "Enedis",[5.022134124246284, 47.34252949273885]],
    [ "Enedis",[5.017027121004565, 47.34040918493151]],
    [ "Enedis",[5.034589078431372, 47.34908224836602]],
    [ "Enedis",[5.012216744585139, 47.34608906441556]],
    [ "Enedis",[5.038576857944731, 47.33699805699482]],
    [ "Enedis",[5.034118751937984, 47.347422093023255]],
    [ "Enedis",[5.028825731182797, 47.33699470967742]],
    [ "Enedis",[5.028699995132128, 47.33541095340751]],
    [ "Enedis",[5.019406095146822, 47.343372805055985]],
    [ "Enedis",[5.025909383874849, 47.33469158483755]],
    [ "Enedis",[5.027659207183531, 47.3468110262812]],
    [ "Enedis",[5.030905778303074, 47.35559561022079]],
    [ "Enedis",[5.030318655812857, 47.352639187856916]],
    [ "Enedis",[5.028220708850291, 47.34587547394542]],
    [ "Enedis",[5.0367015857519775, 47.343251646394]],
    [ "Enedis",[5.034570999999999, 47.33818]],
    [ "Enedis",[5.028166815828187, 47.35598451338629]],
    [ "Enedis",[5.030691268968162, 47.352805429535856]],
    [ "Enedis",[5.0322629278664115, 47.350898779264625]],
    [ "Enedis",[5.0320529505717655, 47.346560419418616]],
    [ "Enedis",[5.028550171171171, 47.33969425225225]],
    [ "Enedis",[5.027583, 47.3421545]],
    [ "Enedis",[5.031785697579825, 47.3454761655481]],
    [ "Enedis",[5.014852069853721, 47.3416341851516]],
    [ "Enedis",[5.03122858030412, 47.3383771914617]],
    [ "Enedis",[5.020000500000003, 47.340306000000005]],
    [ "Enedis",[5.038261080379431, 47.33897224529871]],
    [ "Enedis",[5.026096755460079, 47.33947155364603]],
    [ "Enedis",[5.032279323238782, 47.341116457377474]],
    [ "Enedis",[5.029826773767024, 47.347192779584816]],
    [ "Enedis",[5.026877291262135, 47.35379355177994]],
    [ "Enedis",[5.03613347302024, 47.34184417512351]],
    [ "Enedis",[5.026923694040041, 47.353627477350514]],
    [ "Enedis",[5.019223689907038, 47.34149159672422]],
    [ "Enedis",[5.026155118087505, 47.341073731052255]],
    [ "Enedis",[5.034300657232705, 47.34038924799464]],
    [ "Enedis",[5.027562344037356, 47.35693259547413]],
    [ "Enedis",[5.029775285276796, 47.34876305371024]],
    [ "Poste électrique du Magré",[1.276143042495184, 45.80330505004451]],
    [ "Enedis",[3.8104788411379658, 47.299282010574025]],
    [ "Enedis",[3.821518260162602, 47.282233276422765]],
    [ "Enedis",[0.17748624999999965, 49.553112250000005]],
    [ "Poste électrique de Magny-sur-Tille",[5.166677855606175, 47.293359107713634]],
    [ "Enedis",[-1.1523858, 46.7966759]],
    [ "Enedis",[-1.1454169310380138, 46.80029114844816]],
    [ "Enedis",[4.996032104496769, 47.34411182622053]],
    [ "Enedis",[4.998507828316792, 47.33920199820122]],
    [ "Enedis",[4.994094506695534, 47.342435499238235]],
    [ "Enedis",[5.00532190960452, 47.336095853107345]],
    [ "Enedis",[5.010104974550743, 47.34494728627818]],
    [ "Enedis",[4.993344683315065, 47.3381228452496]],
    [ "Enedis",[5.005049672613739, 47.34307083437406]],
    [ "Enedis",[5.009756111222612, 47.33594270162235]],
    [ "Enedis",[5.000511063141701, 47.33940061863142]],
    [ "Enedis",[4.991479823376008, 47.334373355618766]],
    [ "Enedis",[4.991330290248288, 47.33710214275249]],
    [ "Enedis",[4.998076183264473, 47.34257321032981]],
    [ "Enedis",[5.012436, 47.33589]],
    [ "117835083",[5.008555414307005, 47.342163320914054]],
    [ "Enedis",[5.0106163000000015, 47.34047305000001]],
    [ "Enedis",[4.993859319427616, 47.33849914836421]],
    [ "Enedis",[5.007511896907218, 47.34417180412371]],
    [ "Enedis",[5.0111007940224646, 47.335480375235335]],
    [ "Enedis",[5.011078667593881, 47.34272598980065]],
    [ "Enedis",[4.9954366636098255, 47.3423254850429]],
    [ "Enedis",[5.004711859887006, 47.34204232768362]],
    [ "Enedis",[4.993946330505498, 47.34060233355815]],
    [ "Enedis",[5.0066071008130075, 47.34063993170731]],
    [ "Enedis",[5.004674110470701, 47.33985382132565]],
    [ "Enedis",[5.012977881046299, 47.34050635377211]],
    [ "Enedis",[5.0077577490850445, 47.331965367533456]],
    [ "Enedis",[2.0610176826104634, 48.763708225088045]],
    [ "Enedis",[2.0639710009968795, 48.75314016721003]],
    [ "Enedis",[2.0590207207946345, 48.76477333752048]],
    [ "Enedis",[2.0565731163605214, 48.76618699970307]],
    [ "Enedis",[2.0370449905237282, 48.75904567395018]],
    [ "Enedis",[0.6260403366399978, 49.8304472816961]],
    [ "Enedis",[4.800304970856963, 45.74918751402016]],
    [ "Enedis",[4.801049882694542, 45.74875367131243]],
    [ "Enedis",[0.18740924999999914, 49.55313475]],
    [ "Enedis",[4.803069945736434, 45.747485279069764]],
    [ "Enedis",[4.804944257703082, 45.74718446218487]],
    [ "Enedis",[4.337212408805032, 47.61666425157233]],
    [ "Enedis",[-1.1567049948373704, 46.750815918540425]],
    [ "Enedis",[2.425013705676621, 48.56700135781544]],
    [ "Enedis",[2.438322921798631, 48.57497898598892]],
    [ "Poste électrique de Gavrelle",[2.8853466458981707, 50.33726372400016]],
    [ "Poste électrique d’Azincourt",[3.2686285134986024, 50.316755619390385]],
    [ "Enedis",[-1.18048725, 46.72101999999999]],
    [ "Enedis",[-1.1668561832509876, 46.734499549523015]],
    [ "Enedis",[-1.1757419999999998, 46.72369374999999]],
    [ "Enedis",[-1.1768497860960174, 46.72149826581432]],
    [ "Poste électrique de La Capelle",[3.935112182325215, 49.97365746445144]],
    [ "Enedis",[4.839522994504333, 45.78106161699429]],
    [ "Poste électrique de Calais",[1.847924873678353, 50.931843306224735]],
    [ "Poste électrique de Logelbach",[7.306425645730387, 48.0835187176166]],
    [ "Enedis",[2.4363290000000006, 48.567671000000004]],
    [ "Poste électrique de Marlenheim",[7.52645200973943, 48.611849021110274]],
    [ "SER",[7.565950100814111, 48.539325705603474]],
    [ "SER",[7.488074823379498, 48.531994467716814]],
    [ "Enedis",[0.18039827028255673, 49.55240249515094]],
    [ "Enedis",[0.18013500000000007, 49.55064174999999]],
    [ "Enedis",[4.7473029252578085, 45.738242289885605]],
    [ "Poste électrique de Sélestat",[7.425997869457195, 48.276706771619466]],
    [ "Enedis",[2.4503346861894433, 48.57070754013015]],
    [ "Enedis",[2.4455192375690604, 48.56430882946593]],
    [ "Poste électrique d’Arlod",[5.817719347558451, 46.09725920083633]],
    [ "Enedis",[2.4352515, 48.558787499999994]],
    [ "CERN",[6.053741086382289, 46.255269002407246]],
    [ "RTE",[5.80384795862573, 46.01838138291575]],
    [ "Poste électrique de Grépilles",[4.117042841686407, 46.08309172174268]],
    [ "Poste électrique de Riorges",[4.05596044275632, 46.05807545047738]],
    [ "Poste électrique de Matel",[4.097851360344026, 46.053212116239294]],
    [ "Poste électrique de Aoste",[5.611449148979053, 45.5838518931498]],
    [ "Poste électrique de Givors",[4.786765256266494, 45.57191835853562]],
    [ "Poste électrique de Meyzieu",[5.026581992897253, 45.761549226656136]],
    [ "Poste électrique de Givors Bans",[4.782332969298631, 45.57566719280136]],
    [ "Poste électrique de Pontchâteau",[-2.0998679550056907, 47.43200471252781]],
    [ "Poste Électrique de Vigny",[1.9330519167750149, 49.08150541604338]],
    [ "Enedis",[0.18651099999999998, 49.54933850000002]],
    [ "Enedis",[0.18614, 49.55178875]],
    [ "Enedis",[0.18393624999999955, 49.55176825]],
    [ "Enedis",[0.18334298300959836, 49.54913875269413]],
    [ "Enedis",[-1.0249591723237599, 46.11758154186249]],
    [ "Enedis",[4.772369996068702, 45.740759991679816]],
    [ "Enedis",[3.0451000110252107, 50.543255650049595]],
    [ "Enedis",[-0.8319129499999998, 46.30861724999999]],
    [ "Enedis",[2.254315827895431, 48.59728032057667]],
    [ "RTE",[2.1282551727730334, 50.98110146943236]],
    [ "Électricité de France",[2.13411755, 51.0124831]],
    [ "RTE",[2.135939100462896, 51.013069067025]],
    [ "Électricité de France",[2.138836483553361, 51.01496405059892]],
    [ "Électricité de France",[2.133152465954192, 51.011976698571814]],
    [ "Poste électrique de Denna",[2.154198172003124, 51.01130521546166]],
    [ "Poste électrique de Brode",[2.1644419998837834, 51.01340041617322]],
    [ "Électricité de France",[2.137840816187163, 51.014452898914485]],
    [ "Électricité de France",[2.1365960079205717, 51.01379123388718]],
    [ "Enedis",[0.19554499999999997, 49.5477655]],
    [ "Enedis",[-0.4836451063829784, 44.767135751773054]],
    [ "Enedis",[-0.48550500235792654, 44.77159399691443]],
    [ "Enco-de-Botte",[5.480837385421014, 43.32087643224692]],
    [ "Poste électrique de Compiègne",[2.8409705175595374, 49.43495128676299]],
    [ "Poste électrique de Moru",[2.674600613433512, 49.311289675708196]],
    [ "RTE",[2.79382735068547, 49.29712038544172]],
    [ "RTE",[2.8932551404857767, 49.47985213379445]],
    [ "Poste électrique de Chantereine",[2.884423520698453, 49.4863270455591]],
    [ "SNCF Réseau",[2.7318021789627207, 49.34460371014273]],
    [ "RTE",[2.9384026986931464, 49.50906754115559]],
    [ "Enedis",[0.7989689839477911, 49.87649390182219]],
    [ "Enedis",[0.7963409222797933, 49.8704904835924]],
    [ "Enedis",[5.822212799999999, 46.19494715]],
    [ "Enedis",[5.815704948598773, 46.19334804378807]],
    [ "Enedis",[4.755891750000001, 45.76475050000001]],
    [ "private",[-0.2539361523670401, 49.13902572526678]],
    [ "Sous-station de Frénecourt",[-0.2947537379394962, 49.15551832537579]],
    [ "Enedis",[4.780970607476635, 45.75679414661842]],
    [ "Enedis",[4.785427691464289, 45.760046478020286]],
    [ "Enedis",[4.782026517241379, 45.76255847356321]],
    [ "Enedis",[4.779820277202725, 45.75702253580746]],
    [ "Poste électrique d’Étreville",[0.6534015221628103, 49.377139605669406]],
    [ "Poste électrique du Havre",[0.14819892970510218, 49.47773131746289]],
    [ "Poste source de Châtillon",[2.2658909811605925, 48.78905895014152]],
    [ "Enedis",[2.2813676889107235, 48.72296265599334]],
    [ "Enedis",[0.7038307094017089, 49.86111682151835]],
    [ "Enedis",[0.7085060000000002, 49.87023400000001]],
    [ "RTE",[5.591387876871368, 43.285579155087916]],
    [ "Enedis",[-0.9853474999999998, 47.880621000000005]],
    [ "Enedis",[1.5291225499999999, 43.644984550000004]],
    [ "Enedis",[5.39978735, 47.19966510000001]],
    [ "Enedis",[6.278627026104416, 45.75421857429719]],
    [ "Enedis",[6.2910705, 45.748678999999996]],
    [ "La digue",[6.292372000000001, 45.7430345]],
    [ "Enedis",[6.273979943506007, 45.75015586167417]],
    [ "Enedis",[6.301308324466961, 45.76135902913236]],
    [ "Enedis",[6.299502590573726, 45.75148560499946]],
    [ "Curiale",[6.2967925, 45.74254424999999]],
    [ "Enedis",[6.305301632704402, 45.75085259245284]],
    [ "Blanc",[6.295662455518395, 45.74556170412487]],
    [ "Enedis",[6.282329099999999, 45.750953499999994]],
    [ "Enedis",[6.291420166080501, 45.747941196561165]],
    [ "Enedis",[6.281349, 45.74933699999999]],
    [ "Salfete1",[6.2973069572133715, 45.74764407476643]],
    [ "Enedis",[6.2971344855072475, 45.75806805072464]],
    [ "Poste électrique de Sénart",[2.584797085890065, 48.60870116939496]],
    [ "RTE",[2.5742091692583147, 48.61413015160527]],
    [ "118339986",[2.6803776999999993, 48.574536050000006]],
    [ "Enedis",[4.76766436689038, 45.78092995973154]],
    [ "Enedis",[4.758654749999999, 45.779361]],
    [ "Enedis",[4.7663883758865255, 45.78405345153664]],
    [ "RTE",[5.468421104827371, 43.46382257232636]],
    [ "RTE",[2.532298304176798, 48.57096673819358]],
    [ "118354784",[2.5286072, 48.56194610000001]],
    [ "RTE",[2.47966268615996, 48.568826282381146]],
    [ "Enedis",[5.4213857454796415, 47.06174412422361]],
    [ "Enedis",[5.412684144366735, 47.0670707210871]],
    [ "Enedis",[5.413157930816354, 47.07030389440671]],
    [ "Enedis",[5.416356464426576, 47.078857224786994]],
    [ "Enedis",[5.419063876778286, 47.07821580792184]],
    [ "Enedis",[5.416875614469134, 47.0816111173296]],
    [ "Enedis",[5.406569434254307, 47.076243930815686]],
    [ "Enedis",[5.414341956934851, 47.078244218070026]],
    [ "RTE",[2.757917006071746, 48.41380110277468]],
    [ "Sous-station de la Voulzie",[2.944452090919787, 48.41548720470763]],
    [ "Poste électrique de Patou",[2.983206213253968, 48.38527050732118]],
    [ "Poste électrique de Grande-Paroisse",[2.9345565895048837, 48.389655978557165]],
    [ "Poste électrique de Boctois",[3.511435211181698, 48.51937452772629]],
    [ "Poste électrique de Barbuise",[3.592072108716252, 48.560956867404016]],
    [ "Riveneuve",[1.6410192498321452, 43.12056463703706]],
    [ "Poste électrique de Tarascon",[1.5973987946249324, 42.851521691890625]],
    [ "Enedis",[-3.9046784999999993, 47.868201500000005]],
    [ "Enedis",[-3.9351799015819457, 47.88711273897863]],
    [ "Enedis",[-3.9300127499999995, 47.88343340000001]],
    [ "Enedis",[5.807689549999999, 46.1811402]],
    [ "Enedis",[5.798169952481229, 46.16163651441264]],
    [ "Enedis",[5.804114549999999, 46.166776850000005]],
    [ "Enedis",[-3.9202377499999996, 47.892000749999994]],
    [ "Orée du Bois",[5.307075150859107, 45.562767069072166]],
    [ "Ferme Ailloud",[5.304558321412709, 45.5745964637407]],
    [ "Les deux plages",[-3.9084071, 47.85099615000001]],
    [ "Kersaux",[-3.8979609999999996, 47.859885500000004]],
    [ "Enedis",[-3.8914238318474, 47.86181020528332]],
    [ "Enedis",[-3.8963288, 47.868571599999996]],
    [ "Enedis",[-3.9190181698187376, 47.88836911620591]],
    [ "Enedis",[-3.9155539999999998, 47.893821499999994]],
    [ "Enedis",[-3.914645, 47.890122000000005]],
    [ "Enedis",[-3.917316338827838, 47.89110697802197]],
    [ "Enedis",[-1.5727117999185436, 48.02988889922842]],
    [ "Enedis",[-1.5422908082940954, 48.01261102082213]],
    [ "RTE",[5.427257267600817, 43.51818618329277]],
    [ "Enedis",[-3.914191444296757, 47.878206579065015]],
    [ "RTE",[0.605285191520715, 43.651401489270384]],
    [ "Enedis",[5.391294273550092, 43.42339846331838]],
    [ "RTE",[5.38827005190644, 43.418666717393585]],
    [ "Keramporiel",[-3.913749666666667, 47.90267945925926]],
    [ "ZI Kersalé",[-3.9085277500000006, 47.9062095]],
    [ "RTE",[5.859912503087557, 49.46786244780351]],
    [ "Poste électrique du Mont Saint-Martin",[5.75990216689588, 49.54108306100666]],
    [ "Enedis",[-3.4276250837169653, 48.791397383120206]],
    [ "Enedis",[1.332391398875869, 47.58889525473944]],
    [ "Enedis",[1.3306558301827274, 47.59133918394206]],
    [ "Poste électrique de Chooz",[4.795752943171838, 50.08748545452768]],
    [ "Poste électrique de Chooz",[4.7916698752075195, 50.087485593266756]],
    [ "Caillols",[5.459706671831243, 43.29330990281049]],
    [ "RTE",[5.5293231235660265, 43.284977784337414]],
    [ "Sous-station RFF de La Penne",[5.507767538445829, 43.28575389508773]],
    [ "Enedis",[1.3248639999999994, 47.5808475]],
    [ "Enedis",[1.3268714073199521, 47.58262708854782]],
    [ "RTE",[5.580383357255785, 43.25130353670081]],
    [ "Enedis",[1.32734478388998, 47.58782216699411]],
    [ "Enedis",[1.3254264999999998, 47.59008449999999]],
    [ "Enedis",[1.3283877438743732, 47.58962158120121]],
    [ "Enedis",[1.3286860417049984, 47.59034348359399]],
    [ "Enedis",[1.329123, 47.590583]],
    [ "Enedis",[1.3266216312465, 47.59076654005389]],
    [ "Enedis",[1.3476994202898547, 47.597502884057974]],
    [ "Enedis",[1.3530733166175026, 47.595018149459186]],
    [ "Enedis",[1.3342517633697797, 47.599939736100715]],
    [ "Enedis",[1.3352793333333335, 47.597575361111126]],
    [ "Enedis",[1.332854968289921, 47.59818130030201]],
    [ "Enedis",[1.334444331859711, 47.596008005599764]],
    [ "Enedis",[1.3304743678261226, 47.59515785032207]],
    [ "Enedis",[1.3453341849608882, 47.59322613575574]],
    [ "Enedis",[1.3419563676268866, 47.596638077815186]],
    [ "Enedis",[1.3385702493438312, 47.598923167978995]],
    [ "Enedis",[1.3382283333333334, 47.594870874074076]],
    [ "Enedis",[1.3362895000000001, 47.5985425]],
    [ "Enedis",[1.3403856688625382, 47.60121221607378]],
    [ "Enedis",[1.3280080718475076, 47.610416171065495]],
    [ "Enedis",[1.3280580952380958, 47.61072574829933]],
    [ "Enedis",[1.3376617973744294, 47.61479633276255]],
    [ "Enedis",[1.3376691777777774, 47.614840881481484]],
    [ "Enedis",[1.3383141387940836, 47.60633032878271]],
    [ "Enedis",[1.3359757347625052, 47.6069773715847]],
    [ "Enedis",[1.3352368986928105, 47.607082562091506]],
    [ "Enedis",[1.3349965000000001, 47.60537299999999]],
    [ "Enedis",[1.3342914512378987, 47.60334316621889]],
    [ "Enedis",[1.331276114586241, 47.61017425439822]],
    [ "Enedis",[1.3312972725212464, 47.61012594976393]],
    [ "Enedis",[1.3307582952282875, 47.6048579605218]],
    [ "Enedis",[1.3282687313432837, 47.60707965671643]],
    [ "Enedis",[1.329077, 47.60539300000001]],
    [ "Enedis",[1.3290917888647866, 47.60521697180043]],
    [ "Enedis",[1.3282099208633091, 47.61328240543566]],
    [ "Enedis",[1.3279275665529013, 47.6140527701934]],
    [ "Enedis",[1.3323960113378683, 47.61210182539683]],
    [ "Enedis",[1.3366085621949613, 47.613590780240074]],
    [ "Enedis",[1.3413333187751004, 47.583902611320276]],
    [ "Enedis",[1.3443822655004865, 47.58201979689018]],
    [ "Poste électrique de Lignat",[3.265725922492675, 45.72017624792377]],
    [ "Enedis",[0.20776275, 49.554454]],
    [ "Enedis",[1.3168445421245423, 47.587293667582415]],
    [ "Enedis",[1.32156575, 47.587011000000004]],
    [ "Enedis",[1.3033491314553998, 47.587206234741785]],
    [ "Enedis",[1.3128032, 47.5931021]],
    [ "Enedis",[1.2989185, 47.59141674999999]],
    [ "Enedis",[1.3099536540532633, 47.59042984081912]],
    [ "Enedis",[1.3078895000000004, 47.590385999999995]],
    [ "Enedis",[1.305486, 47.591412000000005]],
    [ "Enedis",[1.2979052895522387, 47.593704037810944]],
    [ "Pré du Sud",[5.043398143176927, 45.792744628038456]],
    [ "Enedis",[1.300815, 47.599734500000004]],
    [ "Enedis",[1.3034459999999999, 47.599990500000004]],
    [ "Enedis",[1.3072543367147182, 47.594213565937004]],
    [ "Enedis",[1.3064185, 47.595558]],
    [ "Enedis",[1.3021616524822692, 47.59673104018912]],
    [ "Enedis",[1.321562000000001, 47.598417]],
    [ "Enedis",[1.3226648609625664, 47.597424852049905]],
    [ "Enedis",[1.3127544899193548, 47.60488709274193]],
    [ "Enedis",[1.3149879720786375, 47.606100234767524]],
    [ "Enedis",[1.309919749999999, 47.602315000000004]],
    [ "Enedis",[1.3225209068716934, 47.59417558440884]],
    [ "Enedis",[1.3222636510186008, 47.596113248007086]],
    [ "Enedis",[1.3167353518518519, 47.59904123333333]],
    [ "Enedis",[1.3122167907621256, 47.60009082921681]],
    [ "Enedis",[1.318937666666667, 47.60070600000001]],
    [ "Enedis",[1.321175, 47.60148600000001]],
    [ "Enedis",[1.316376903222527, 47.601792800853914]],
    [ "Enedis",[1.315706396759153, 47.6036151438454]],
    [ "Enedis",[1.915974325639449, 47.905654842557006]],
    [ "Enedis",[1.923290053740861, 47.90512903192565]],
    [ "Enedis",[1.9095281485060887, 47.90759176213685]],
    [ "Enedis",[1.9073661218692262, 47.90734474458277]],
    [ "Enedis",[1.9078860000000002, 47.90648916666666]],
    [ "Enedis",[1.9029299637944985, 47.909137654733016]],
    [ "RTE",[3.4801303000000003, 45.95666]],
    [ "Enedis",[1.4045265047619053, 47.63866914285715]],
    [ "Enedis",[1.4081891663541386, 47.64039305232609]],
    [ "Enedis",[1.3961545, 47.643043999999996]],
    [ "Enedis",[1.4078983754972152, 47.64337237045877]],
    [ "Enedis",[1.4052171666666669, 47.63621800000001]],
    [ "Enedis",[1.4023599999999998, 47.64385049999999]],
    [ "Enedis",[1.4040352614770457, 47.64419132135729]],
    [ "Enedis",[1.3460438295165393, 47.602193455470726]],
    [ "Enedis",[1.3499765675116402, 47.599824254181755]],
    [ "Enedis",[1.3501587210031343, 47.611602916405424]],
    [ "Enedis",[1.351786333333333, 47.6138141025641]],
    [ "Enedis",[1.3504737256748274, 47.6132039820046]],
    [ "Enedis",[1.3485848482384823, 47.615219306233065]],
    [ "Enedis",[1.3546922460911837, 47.61495755840231]],
    [ "Enedis",[1.3576125000000003, 47.6171995]],
    [ "Enedis",[0.19724875000000003, 49.54584524999999]],
    [ "Enedis",[1.357920333333333, 47.614326318518515]],
    [ "Enedis",[1.3515325000000007, 47.60223299999999]],
    [ "Enedis",[1.349977, 47.60300549999999]],
    [ "Enedis",[1.354804015964912, 47.611997354561396]],
    [ "Enedis",[1.3538384999999997, 47.6116235]],
    [ "Enedis",[1.360973481727574, 47.61023397729789]],
    [ "Enedis",[1.3536443355119825, 47.607272533769056]],
    [ "Enedis",[1.3497033962512948, 47.60589096260714]],
    [ "Enedis",[1.3493751035381747, 47.60936411098696]],
    [ "Enedis",[1.3480112827715354, 47.60573606741573]],
    [ "Enedis",[1.3652310605362064, 47.61288472162127]],
    [ "Enedis",[1.3674759273557489, 47.616145672478204]],
    [ "Enedis",[1.371779332326284, 47.61810199295065]],
    [ "Enedis",[2.4212245, 48.54768175]],
    [ "Enedis",[1.3777352500000002, 47.634785]],
    [ "Enedis",[1.3955973531202437, 47.63086178538813]],
    [ "Enedis",[1.3784641253117824, 47.61995266097975]],
    [ "Enedis",[1.3848571562198653, 47.626790560591445]],
    [ "Enedis",[1.3765143232323231, 47.62193797979798]],
    [ "Enedis",[2.3318140977011494, 51.027642696679436]],
    [ "Enedis",[2.326770369767734, 51.023931157424016]],
    [ "Enedis",[2.325707043628374, 51.02715712554928]],
    [ "Enedis",[2.346620286809325, 51.034561454700295]],
    [ "Enedis",[2.344666899607842, 51.03098773803921]],
    [ "Enedis",[2.3403425, 51.0257315]],
    [ "Enedis",[2.323387318302387, 51.02782246652198]],
    [ "Enedis",[2.3570227652566276, 51.02850752205302]],
    [ "Enedis",[1.326547, 47.59210625]],
    [ "Enedis",[0.18976524999999997, 49.5426675]],
    [ "Enedis",[1.3282865, 47.5998005]],
    [ "Enedis",[1.3270985, 47.60061050000001]],
    [ "Enedis",[1.3225134787414963, 47.60731631122449]],
    [ "Enedis",[1.3254104935046886, 47.59933798883619]],
    [ "Enedis",[1.3241516058969165, 47.6066634418186]],
    [ "Enedis",[1.32523825, 47.59724799999999]],
    [ "Enedis",[1.1799936968272615, 47.47942307324715]],
    [ "Enedis",[1.1683508691308695, 47.4738878158508]],
    [ "Enedis",[1.1892449099628108, 47.480823258954786]],
    [ "Enedis",[2.3594167398843937, 51.033383853564544]],
    [ "Enedis",[2.353303565462754, 51.03530410571858]],
    [ "Enedis",[2.3291879235880404, 51.032158230343306]],
    [ "Enedis",[2.3577932249780127, 51.0320454223395]],
    [ "Enedis",[2.342768192222496, 51.024274849208304]],
    [ "Enedis",[2.3589556929577467, 51.02999305696401]],
    [ "Enedis",[2.3415446698605344, 51.03424816544236]],
    [ "Enedis",[2.3396163210476555, 51.03587078759699]],
    [ "Enedis",[2.353685999999999, 51.034066499999994]],
    [ "Enedis",[2.3546445, 51.03499300000001]],
    [ "Enedis",[2.329099524357132, 51.02267321673854]],
    [ "Enedis",[2.3527202742159323, 51.02577882542325]],
    [ "Enedis",[2.332936959880124, 51.03097692440062]],
    [ "Enedis",[2.3441884118685192, 51.03578321332451]],
    [ "Enedis",[2.3473347127667474, 51.03101955416759]],
    [ "Enedis",[2.3315244022698614, 51.0291412671991]],
    [ "Enedis",[2.319722210526316, 51.03324443325706]],
    [ "Enedis",[2.359254, 51.032061000000006]],
    [ "Enedis",[2.3453922950191557, 51.02839945210727]],
    [ "Enedis",[2.3519193324515, 51.02831378174603]],
    [ "Enedis",[2.3303254999999994, 51.023998]],
    [ "Enedis",[2.332691225645816, 51.03302622502334]],
    [ "Enedis",[2.3379210000000006, 51.023591499999995]],
    [ "Enedis",[2.3346370000000003, 51.022293000000005]],
    [ "Enedis",[2.331553700881286, 51.025682032659404]],
    [ "Enedis",[2.3370522051282054, 51.027083053846155]],
    [ "Enedis",[2.341452210451977, 51.030906081920904]],
    [ "Enedis",[2.3468272762487254, 51.02456985524975]],
    [ "Enedis",[2.3271367481575074, 51.02985533817646]],
    [ "Enedis",[2.3490089507667475, 51.027379097659406]],
    [ "Enedis",[2.326503, 51.033294]],
    [ "Enedis",[2.3428471617385607, 51.02647282323035]],
    [ "Enedis",[2.357061851164043, 51.03521496945229]],
    [ "Enedis",[2.3423553173241847, 51.028028007261284]],
    [ "Enedis",[2.3357195488848146, 51.03533157118851]],
    [ "Enedis",[2.3540773955596297, 51.03248270760769]],
    [ "Enedis",[2.340640661669443, 51.02365319100499]],
    [ "Enedis",[2.347869764519427, 51.03301750501022]],
    [ "Enedis",[1.3176256446941323, 47.58419744144819]],
    [ "Enedis",[1.3226041219638243, 47.58392307059431]],
    [ "Enedis",[1.3139978877551024, 47.581808685374156]],
    [ "Enedis",[1.3102710000000002, 47.5815005]],
    [ "Enedis",[1.3175232561373635, 47.581414030509436]],
    [ "Enedis",[1.3135520626349895, 47.579561149748024]],
    [ "Enedis",[1.3167813325626205, 47.576743497366735]],
    [ "Enedis",[2.3505695949865335, 51.031469515848364]],
    [ "Enedis",[1.7636400893444708, 48.8114124725386]],
    [ "Enedis",[1.760427105919003, 48.81415912772586]],
    [ "Enedis",[1.7557540163076004, 48.82375306328545]],
    [ "Enedis",[0.18635174999999998, 49.535601]],
    [ "RATP",[2.4840179913127542, 48.80497582912567]],
    [ "Enedis",[4.0960754, 46.0556695]],
    [ "Enedis",[4.096121332394366, 46.05570646901409]],
    [ "Acare",[4.075230250000001, 46.067333250000004]],
    [ "Polygone",[4.085161000000001, 46.053180000000005]],
    [ "Polygone 11",[4.0813857533279005, 46.0544978856289]],
    [ "ZAC de Valmy",[4.084606300596853, 46.067107764152645]],
    [ "ZI Curie",[4.085712457163234, 46.04990031573735]],
    [ "Enedis",[0.19192087242798284, 49.52349324279835]],
    [ "RTE",[2.091721369976621, 42.49509006369919]],
    [ "RTE",[2.148455033565732, 42.50440276368539]],
    [ "Enedis",[1.34665286985173, 47.58705962493135]],
    [ "Enedis",[1.3439928618618622, 47.58572213933933]],
    [ "Poste électrique de Grand-Couronne",[0.9931905251252137, 49.35050586224182]],
    [ "Etables",[-0.560852767123529, 44.82999504604341]],
    [ "Enedis",[5.0294066908420945, 47.332891909509975]],
    [ "Enedis",[1.30709817232848, 47.57325948434675]],
    [ "Enedis",[1.308389784137192, 47.576984171275456]],
    [ "Enedis",[1.3007411594746714, 47.57246231207003]],
    [ "Enedis",[1.2870385137844607, 47.55341291550305]],
    [ "Enedis",[1.2914154, 47.55412785000001]],
    [ "Enedis",[1.2904815770796978, 47.557251663758]],
    [ "Enedis",[1.295952679012346, 47.557419135802476]],
    [ "Enedis",[1.2955656933333326, 47.56067966385965]],
    [ "Enedis",[5.036879560464894, 47.33036618472451]],
    [ "Enedis",[5.037387581130678, 47.331862106611965]],
    [ "Enedis",[3.5277115, 50.07695800000001]],
    [ "Enedis",[-2.980034260309149, 48.06121943819497]],
    [ "Enedis",[-2.9885660000000014, 48.056181499999994]],
    [ "Enedis",[3.4623315, 50.0348]],
    [ "Enedis",[3.467643332459589, 50.03511100480559]],
    [ "Enedis",[3.4417245575014594, 50.03881229363689]],
    [ "Enedis",[3.484790828208659, 50.02696835627964]],
    [ "Enedis",[4.710128993066959, 49.99377789746397]],
    [ "Enedis",[4.692338939968404, 49.9876464028436]],
    [ "Enedis",[4.702284499999999, 49.9903985]],
    [ "Enedis",[4.697871901910829, 49.99064629596603]],
    [ "Enedis",[4.6820559261648755, 49.98852788960573]],
    [ "Enedis",[4.708306225256694, 49.99351050333439]],
    [ "Enedis",[4.688436906256813, 49.9920468441247]],
    [ "Enedis",[4.709342592034632, 49.99227852848485]],
    [ "Enedis",[-2.9615350000000005, 48.069951999999994]],
    [ "Enedis",[-2.9558797129333816, 48.07054744965093]],
    [ "Enedis",[-2.960437999999999, 48.076054]],
    [ "Enedis",[-2.964310487341772, 48.076146951898735]],
    [ "Enedis",[-2.9596425861657005, 48.079405531240155]],
    [ "Enedis",[-2.9612976540084386, 48.077344019562716]],
    [ "Enedis",[4.512086216117215, 45.48483678032443]],
    [ "Enedis",[4.483608911564627, 45.490855638483964]],
    [ "Enedis",[4.498640892270023, 45.46418035257082]],
    [ "Enedis",[4.526891675577157, 45.47961429323613]],
    [ "Enedis",[4.508295675213675, 45.480203760683764]],
    [ "Enedis",[4.522145362442485, 45.482391917468405]],
    [ "Enedis",[-2.9570377499999996, 48.06031875]],
    [ "Enedis",[-2.9553485000000004, 48.05966349999999]],
    [ "Enedis",[4.48173825, 45.44980735]],
    [ "Enedis",[4.5274515087719305, 45.482595631578945]],
    [ "Enedis",[4.503766185721177, 45.47893285721177]],
    [ "Enedis",[4.521507, 45.490604000000005]],
    [ "Enedis",[-2.9632709616182575, 48.05804782659059]],
    [ "Enedis",[4.4703376499999985, 45.441501099999996]],
    [ "Enedis",[-2.972537333333334, 48.07303206802721]],
    [ "Enedis",[4.522512632148378, 45.488084662029884]],
    [ "Enedis",[4.524708198814655, 45.49212736605603]],
    [ "Rive 3",[4.513858661423622, 45.47996469963566]],
    [ "Enedis",[-2.9717603755103084, 48.06958769761435]],
    [ "Enedis",[4.504940352037408, 45.482048494321965]],
    [ "Enedis",[4.510361071149124, 45.46550394364985]],
    [ "Enedis",[4.521134927496442, 45.46240419255661]],
    [ "Enedis",[-2.9731034819754196, 48.06258334901803]],
    [ "Enedis",[-2.9746958717948724, 48.07372800940171]],
    [ "Enedis",[-2.9738582311958046, 48.065363195502286]],
    [ "Enedis",[4.529385813297713, 45.48206496141288]],
    [ "Enedis",[-2.9774571379334747, 48.06514545060156]],
    [ "Enedis",[-2.9766543999999993, 48.06227274999999]],
    [ "Enedis",[-2.977119883436458, 48.06319333227811]],
    [ "Enedis",[4.521877249999999, 45.48463859999999]],
    [ "Enedis",[4.522911835676493, 45.488472234920636]],
    [ "Enedis",[4.5141027157132925, 45.48314704895655]],
    [ "Enedis",[4.5365185, 45.479359]],
    [ "Enedis",[4.524033321481481, 45.48953420888889]],
    [ "Enedis",[4.529459107807807, 45.47751373427713]],
    [ "Enedis",[4.512254473601583, 45.48470578458398]],
    [ "Enedis",[4.495438962699822, 45.48855801716992]],
    [ "Enedis",[4.52757455, 45.46577674999999]],
    [ "Enedis",[4.521228, 45.476188666666665]],
    [ "Enedis",[4.513389491111112, 45.48260377079365]],
    [ "Enedis",[4.5156841851851865, 45.47945674074074]],
    [ "Enedis",[4.5182564772769425, 45.4883688393619]],
    [ "Enedis",[4.511585078423116, 45.48235779439083]],
    [ "Enedis",[4.523461654897494, 45.48881810971905]],
    [ "Enedis",[4.5022527069418405, 45.45471555353346]],
    [ "Enedis",[3.3454467918699184, 47.39456759674797]],
    [ "Enedis",[-2.959992016359919, 48.06336666564417]],
    [ "Enedis",[-2.955947242320957, 48.06342407046054]],
    [ "Enedis",[-0.32929749999999997, 49.143075]],
    [ "Enedis",[-0.330437, 49.147122]],
    [ "Enedis",[-0.33097550000000003, 49.140446000000004]],
    [ "Enedis",[-0.35322428678987067, 49.136252447638604]],
    [ "Enedis",[-0.32200382690887697, 49.135485492246346]],
    [ "Enedis",[-0.3283559999999989, 49.14492200000001]],
    [ "Enedis",[-0.32731064622996664, 49.14102255006991]],
    [ "Enedis",[-0.6328323261297008, 44.94559885323081]],
    [ "Enedis",[-3.204680326007326, 48.71467091208791]],
    [ "Enedis",[-0.4142995, 49.174020500000005]],
    [ "Enedis",[-0.42286099999999927, 49.185370500000005]],
    [ "Enedis",[-0.41459949999999995, 49.1854765]],
    [ "Enedis",[-0.41737811881188003, 49.1850357590759]],
    [ "Enedis",[-0.4098799822940723, 49.167989826020005]],
    [ "Enedis",[-0.4101050000000006, 49.173283]],
    [ "Enedis",[-0.4072858112094396, 49.16873804129794]],
    [ "Enedis",[-0.41685078858102426, 49.17428504802687]],
    [ "Enedis",[-0.42020841017074945, 49.18411369673348]],
    [ "Enedis",[5.0392089126556145, 47.33298549340709]],
    [ "RTE",[1.5856410013806566, 43.470264587280475]],
    [ "RTE",[1.5892417442252547, 43.472503494118016]],
    [ "SNCF Réseau",[1.6187331888203615, 43.12346219416534]],
    [ "RTE",[1.605760066624062, 43.1183140483646]],
    [ "Sous-station SNCF de Montauban (Miroulette)",[1.3212864594522054, 43.984654817453055]],
    [ "Enedis",[-3.0193014500000004, 48.81715890000001]],
    [ "Enedis",[6.0528286477987425, 45.14001303773584]],
    [ "ZAC des Vernières",[2.76526, 45.59045424999999]],
    [ "Enedis",[2.76664287542533, 45.588996632829236]],
    [ "Enedis",[2.7420307666666672, 45.58563178333334]],
    [ "Enedis",[-0.9684172182156482, 46.08109632368873]],
    [ "Enedis",[-0.3938835, 49.1585125]],
    [ "Enedis",[-0.3921275, 49.15625504999999]],
    [ "Enedis",[0.7105968868778283, 44.423683817496226]],
    [ "Enedis",[0.7386442601626032, 44.406271468834696]],
    [ "Enedis",[0.7294696374981211, 44.415951173658094]],
    [ "Enedis",[0.7263545000000003, 44.409034]],
    [ "Enedis",[0.7320292500000012, 44.413315]],
    [ "Enedis",[0.722496640062598, 44.42214629170579]],
    [ "Enedis",[0.731521, 44.41947249999999]],
    [ "Enedis",[0.7387715064102559, 44.406573564814806]],
    [ "Enedis",[0.7147731878181486, 44.421171720730214]],
    [ "Enedis",[0.7369924945115259, 44.40777012440542]],
    [ "Enedis",[0.7338447560549314, 44.40850162372035]],
    [ "Enedis",[0.7203670982726182, 44.41932566439199]],
    [ "Enedis",[0.7122865816849165, 44.41012602156444]],
    [ "Enedis",[0.7066487180685358, 44.422885925233636]],
    [ "Enedis",[0.7219076546365911, 44.40798131077694]],
    [ "Enedis",[0.7129101598224203, 44.41360367850536]],
    [ "Enedis",[0.7045733416666665, 44.408305858333335]],
    [ "Enedis",[0.7041371027383161, 44.42032573152151]],
    [ "Enedis",[0.7069608800959237, 44.40924277697842]],
    [ "Enedis",[0.7138927223462859, 44.40961186750038]],
    [ "Enedis",[0.7020889, 44.42279229999999]],
    [ "Enedis",[0.7071639519461366, 44.407078128020665]],
    [ "Enedis",[0.7210499022387213, 44.41318208426144]],
    [ "Enedis",[0.7102959508909163, 44.4170820208605]],
    [ "SNCF Réseau",[1.6116146000462728, 42.830486096585965]],
    [ "Poste électrique de Mérens",[1.8311451999999997, 42.6424556]],
    [ "EDF",[1.9124160227120406, 42.689201252212285]],
    [ "Enedis",[4.760307750000001, 45.4589005]],
    [ "Enedis",[4.774011161506276, 45.46884561143654]],
    [ "Enedis",[4.749721388965518, 45.46683739390805]],
    [ "Enedis",[4.776049550000001, 45.47146035]],
    [ "Enedis",[4.766800918906144, 45.47263629836674]],
    [ "Enedis",[4.774570502117363, 45.46947532123411]],
    [ "Enedis",[4.768702067191371, 45.46413868415682]],
    [ "Enedis",[4.772002232879819, 45.46617528956916]],
    [ "Enedis",[3.6859254999999984, 50.123822000000004]],
    [ "Enedis",[0.6954098284164121, 44.39975196982028]],
    [ "Enedis",[0.6982944633663354, 44.40475112937294]],
    [ "Enedis",[0.7003507264276224, 44.402095843293495]],
    [ "Enedis",[0.7014912803638315, 44.40110816960941]],
    [ "Enedis",[0.706224, 44.3981405]],
    [ "Enedis",[0.691368, 44.40730699999999]],
    [ "Enedis",[0.6980748569245464, 44.40498401977077]],
    [ "Enedis",[0.6991593672482826, 44.394828863282726]],
    [ "Enedis",[0.6986878898809529, 44.432541229166674]],
    [ "Enedis",[0.7115045996336996, 44.39171492710622]],
    [ "Enedis",[0.6930838999999999, 44.404716699999994]],
    [ "Enedis",[0.7066938894154814, 44.39877669510268]],
    [ "Enedis",[0.70547048, 44.40039667733333]],
    [ "Enedis",[0.6950457868443733, 44.395678635737646]],
    [ "Enedis",[0.7057779396981194, 44.39527730530152]],
    [ "Enedis",[0.7105151096075777, 44.39493419891746]],
    [ "Enedis",[0.6949185718117535, 44.406671219946354]],
    [ "Enedis",[0.7126941035093938, 44.391605290440744]],
    [ "Enedis",[0.7111590000000003, 44.39787450000001]],
    [ "Enedis",[0.7142939948320414, 44.399098643410845]],
    [ "Enedis",[-1.206483, 46.16129600000001]],
    [ "Enedis",[-1.1605709557458956, 46.14630308684273]],
    [ "Enedis",[-1.165377523905724, 46.13937433400673]],
    [ "Enedis",[-1.157663500000001, 46.14954375000001]],
    [ "Enedis",[-1.153604, 46.15122625000001]],
    [ "Enedis",[-1.1571011727982166, 46.15255783612041]],
    [ "Enedis",[-1.149567050000002, 46.161659650000004]],
    [ "Enedis",[-1.1705244999999997, 46.163372499999994]],
    [ "PLEAIDES",[6.976004, 43.680598999999994]],
    [ "Enedis",[5.841669446462531, 47.422989141316194]],
    [ "RTE",[2.5431814146760283, 50.83193394128941]],
    [ "Enedis",[6.215730674233128, 48.67885549202454]],
    [ "Enedis",[6.223702689473685, 48.67466782061404]],
    [ "Enedis",[6.210200919512839, 48.68900956621657]],
    [ "Enedis",[6.210799862414007, 48.69121613070669]],
    [ "Enedis",[6.221112316595223, 48.67740436333946]],
    [ "Enedis",[6.218321904279837, 48.67958975522634]],
    [ "Enedis",[6.218984000000001, 48.685700999999995]],
    [ "Enedis",[6.223303, 48.6730795]],
    [ "Enedis",[6.211414669316991, 48.68672315305616]],
    [ "Enedis",[6.215465004322166, 48.682893609671126]],
    [ "Enedis",[6.22020367422827, 48.67628053705186]],
    [ "Enedis",[6.2153491499292794, 48.685712404211856]],
    [ "Enedis",[6.2140184761904775, 48.69193732600732]],
    [ "Enedis",[6.215994500000001, 48.67700949999999]],
    [ "Enedis",[6.218436929770477, 48.68659781964757]],
    [ "Enedis",[6.218995659700231, 48.68564871838717]],
    [ "Enedis",[6.231975350000002, 48.67368675000001]],
    [ "Enedis",[6.230991281570192, 48.674455686227546]],
    [ "Enedis",[6.229674864239829, 48.67551119157745]],
    [ "Enedis",[6.226228821254732, 48.69250849012079]],
    [ "Enedis",[6.215752657055213, 48.68759832924335]],
    [ "Enedis",[6.21887492456336, 48.68369716387959]],
    [ "Enedis",[6.209850934266328, 48.68539485771841]],
    [ "Gare de Saint-Savin",[5.2819749499999995, 45.623234849999996]],
    [ "Enedis",[5.2744511, 45.5991963]],
    [ "Enedis",[6.318933999997693, 45.789197000139204]],
    [ "Enedis",[6.309428500000001, 45.7661205]],
    [ "Enedis",[6.315328500000001, 45.763749499999996]],
    [ "Enedis",[6.3047925, 45.7643865]],
    [ "Enedis",[2.099674617930288, 48.719915878395675]],
    [ "Enedis",[2.0965951198680735, 48.71739385629242]],
    [ "Enedis",[2.082425799999999, 48.724986099999995]],
    [ "Enedis",[2.0902117607073025, 48.721688171638625]],
    [ "Enedis",[2.083825191704174, 48.742595626350344]],
    [ "Enedis",[2.0602790788907868, 48.74348728088349]],
    [ "Enedis",[0.1783044485407064, 49.53919332104455]],
    [ "Enedis",[3.3090318753881682, 47.4782527978299]],
    [ "Enedis",[3.250475141414141, 47.464175673400675]],
    [ "Enedis",[0.1893947499999987, 49.543763]],
    [ "Gy",[5.793457080053017, 47.38813299639247]],
    [ "Les Orchidées",[5.258928167858115, 45.60886684810792]],
    [ "Enedis",[7.531129187627074, 43.90608867505913]],
    [ "Enedis",[5.275818892512077, 45.614037661835745]],
    [ "RTE",[-0.9258935063563379, 48.29026660505383]],
    [ "Pont Rouge",[5.2535326342769775, 45.6022287756269]],
    [ "La Grive 2",[5.23360086073744, 45.599167116751595]],
    [ "Ladrière",[5.23506796673429, 45.595230568490145]],
    [ "Enedis",[-1.629158383564024, 47.92281737982592]],
    [ "Enedis",[-1.6111829981451318, 47.934093184980085]],
    [ "Enedis",[1.3936938492569002, 43.49891969639066]],
    [ "Braille",[5.2441237, 45.59130805]],
    [ "Asimov",[5.249056633413346, 45.58979243226896]],
    [ "Enedis",[5.262836182153476, 45.586044239675324]],
    [ "Augier",[5.2631025753540825, 45.586060810715]],
    [ "Le Pelu",[5.2638780002086865, 45.585636335445734]],
    [ "Venise 92",[5.2579115, 45.58839915]],
    [ "Enedis",[5.247806600000001, 45.590422700000005]],
    [ "Les Sétives",[5.246722211147195, 45.59252895703026]],
    [ "Espace Barbusse",[5.265948024513609, 45.59705248971968]],
    [ "Pasteur",[5.265055665841819, 45.60012505491472]],
    [ "Les Muriers",[5.261524974908222, 45.58942451058038]],
    [ "Lavoisier",[5.2639445, 45.592463]],
    [ "Etissey",[5.268580999999999, 45.5935705]],
    [ "Les Plagnes",[5.260949042750435, 45.59310655173126]],
    [ "Ed Marion Horizal",[5.271049500000002, 45.5870385]],
    [ "Jaurès",[5.269144248528902, 45.58851456663206]],
    [ "Enedis",[6.225873342374924, 48.70289451657625]],
    [ "Enedis",[6.247306889261745, 48.701704901565996]],
    [ "Côté Nature",[5.2740145, 45.580314]],
    [ "Route de Saint-Jean",[5.2751715546875, 45.5785671187066]],
    [ "Enedis",[6.238131, 48.71002650000001]],
    [ "Enedis",[6.2272004999999995, 48.706200499999994]],
    [ "Enedis",[6.2226401260941095, 48.70264436301415]],
    [ "Enedis",[6.245194, 48.7098405]],
    [ "Bion",[5.272034090740305, 45.58476497458559]],
    [ "Enedis",[6.227518076586433, 48.704283754194016]],
    [ "Magasins Généraux",[5.271957500000001, 45.58292174999999]],
    [ "Enedis",[6.234964176322419, 48.708543554715924]],
    [ "Enedis",[6.220822000000001, 48.706123500000004]],
    [ "Enedis",[6.2206890920682225, 48.70304472644479]],
    [ "Enedis",[6.217357737598026, 48.70994956322143]],
    [ "Enedis",[6.23361818516458, 48.706610541400096]],
    [ "Enedis",[6.220634333333333, 48.71166143421053]],
    [ "Enedis",[6.219678863854976, 48.711033816500176]],
    [ "Enedis",[6.222284693524009, 48.71255564085571]],
    [ "Enedis",[6.248266388876363, 48.70676652100715]],
    [ "Enedis",[6.222196184458968, 48.715487967320264]],
    [ "Enedis",[6.248290724220625, 48.705270062350124]],
    [ "Enedis",[6.244187955911823, 48.71078719372077]],
    [ "Enedis",[6.2233948296485915, 48.70084726235357]],
    [ "Enedis",[6.229966408838057, 48.704587050227175]],
    [ "Enedis",[6.229810162997733, 48.70065004610356]],
    [ "Enedis",[6.217714579970104, 48.70131639162929]],
    [ "Libération",[5.275274991921452, 45.595299198732285]],
    [ "Résidence Pasteur",[5.268554, 45.598229]],
    [ "Mouturier",[5.270599750000001, 45.596587]],
    [ "Enedis",[3.688493002621232, 50.12392401441678]],
    [ "Enedis",[3.6863540195488733, 50.12808333283209]],
    [ "Diet",[5.27429085, 45.5860255]],
    [ "Enedis",[5.274794516091668, 45.58789989931332]],
    [ "Degrange",[5.276031105711815, 45.585962720928606]],
    [ "Enedis",[-1.975957901155637, 47.78827531334234]],
    [ "Enedis",[0.17458800000000088, 49.5412175]],
    [ "Enedis",[2.7541185002778756, 42.49179614812245]],
    [ "Kerilos",[5.2808459652428805, 45.58703330946398]],
    [ "Cuvière",[5.278760956202226, 45.58622043182689]],
    [ "Enedis",[5.279905, 45.590307499999994]],
    [ "Gaudin-Sofileta",[5.287247065278682, 45.586320311862934]],
    [ "Pouchelon",[5.285315765935215, 45.58558705956113]],
    [ "Boussieu",[5.302851589093641, 45.57868971727429]],
    [ "Enedis",[0.17599365, 49.54147855]],
    [ "Enedis",[0.18164175, 49.54538050000001]],
    [ "Enedis",[0.18513875, 49.546001499999996]],
    [ "Enedis",[0.18840075, 49.5466205]],
    [ "Enedis",[-1.3026654284052392, 46.76631844947849]],
    [ "Enedis",[-1.3010289465715386, 46.76930174951079]],
    [ "Enedis",[-0.720582163769662, 45.4619912212921]],
    [ "Enedis",[-0.6913935465341364, 45.476263825187246]],
    [ "Enedis",[0.1705329, 49.54413625]],
    [ "Enedis",[0.17337065, 49.5453362]],
    [ "Enedis",[0.1728434844761065, 49.54409121649471]],
    [ "Enedis",[0.17696625000000044, 49.54967225]],
    [ "Enedis",[0.17343125000000004, 49.54698950000001]],
    [ "Enedis",[0.17112775, 49.54584925]],
    [ "Enedis",[0.17045139999999928, 49.54740755000001]],
    [ "Enedis",[3.2170973510355814, 49.61217732731457]],
    [ "Enedis",[0.17902699999999996, 49.547891499999984]],
    [ "Enedis",[0.17850750000000015, 49.54631699999999]],
    [ "Enedis",[3.221460498013245, 49.613012324503316]],
    [ "Enedis",[0.17681950000000002, 49.54605700000001]],
    [ "Enedis",[3.2203115000000007, 49.6111705]],
    [ "Enedis",[2.50808575, 48.828453249999995]],
    [ "Enedis",[2.522599476190475, 48.82985273265306]],
    [ "Enedis",[3.2346818076323993, 49.618305605659394]],
    [ "Enedis",[3.224658030224526, 49.61714183290156]],
    [ "Enedis",[3.2240034034162277, 49.61154782240643]],
    [ "Enedis",[3.220045125714285, 49.61455369714285]],
    [ "Enedis",[3.213617620384951, 49.61658268941383]],
    [ "Enedis",[3.22001418886723, 49.62252530431015]],
    [ "Enedis",[3.2241570000000004, 49.62558950000001]],
    [ "Enedis",[3.2202628490497074, 49.621807563230995]],
    [ "Enedis",[2.5197515, 48.839606749999994]],
    [ "Enedis",[2.51556475, 48.83957225]],
    [ "Enedis",[2.5250561813125687, 48.84159883388951]],
    [ "Enedis",[2.5248493621071275, 48.84290843813635]],
    [ "Enedis",[2.5200042499999986, 48.84254249999999]],
    [ "Enedis",[2.5172310213024955, 48.847410329985784]],
    [ "Enedis",[2.5157618332276024, 48.84388752790113]],
    [ "Enedis",[2.489045249999999, 48.83386125]],
    [ "Enedis",[2.46849772283927, 48.83117537807991]],
    [ "Église Cre et DP Ngt",[2.4767404232804235, 48.832601856999865]],
    [ "Enedis",[2.4799689999999996, 48.834315999999994]],
    [ "Enedis",[2.4805040000000003, 48.832863749999994]],
    [ "Enedis",[2.477231, 48.835590499999995]],
    [ "Rolland",[2.4817576902356895, 48.83424216316316]],
    [ "Enedis",[2.468871499999999, 48.84093549999999]],
    [ "Enedis",[2.4724702500000006, 48.83728825]],
    [ "Enedis",[2.4763050000000004, 48.83998]],
    [ "Enedis",[2.4814464999999992, 48.841251]],
    [ "Enedis",[2.475155, 48.836776]],
    [ "Enedis",[2.470311399557453, 48.839357863758494]],
    [ "Enedis",[2.475622746361418, 48.838234554087]],
    [ "Enedis",[2.490589552492477, 48.836736686102306]],
    [ "Enedis",[2.4886455, 48.845305999999994]],
    [ "Enedis",[2.481914087334725, 48.83692959088379]],
    [ "Enedis",[2.4850264469095227, 48.84204253601753]],
    [ "Enedis",[3.206518858656074, 49.624135340284674]],
    [ "Enedis",[3.2113288700280114, 49.623815163585434]],
    [ "Enedis",[3.204453042467949, 49.62404346217949]],
    [ "Enedis",[3.209811999999999, 49.62578449999999]],
    [ "Enedis",[3.1963356073858114, 49.63140054907677]],
    [ "Enedis",[3.2292248995433788, 49.62603579908676]],
    [ "Enedis",[3.2271585, 49.62620749999999]],
    [ "RTE",[5.265110482647263, 45.83999882325276]],
    [ "Enedis",[3.2214664165803115, 49.625395654576856]],
    [ "Enedis",[3.22231431785787, 49.62398143616372]],
    [ "Enedis",[3.208905870081663, 49.62340311358575]],
    [ "RTE",[5.32897165047683, 45.956565502200185]],
    [ "Enedis",[3.225213671040755, 49.62255151336527]],
    [ "Enedis",[3.229411054305109, 49.62139683404787]],
    [ "Poste électrique de Champvans",[5.444234868817117, 47.09816885117602]],
    [ "Enedis",[3.222953773289061, 49.61950679136691]],
    [ "Enedis",[3.2235755180255135, 49.61865583250139]],
    [ "Enedis",[3.228060120389909, 49.61905541573711]],
    [ "RTE",[5.5128106, 47.1076512]],
    [ "Enedis",[3.221173690594882, 49.61721682220006]],
    [ "Enedis",[3.228533774664818, 49.61717692889505]],
    [ "Enedis",[3.2343083236587513, 49.61577131838172]],
    [ "Enedis",[3.220598619814544, 49.616213717423136]],
    [ "Enedis",[3.212353999999999, 49.612979499999994]],
    [ "Enedis",[3.2231623061224495, 49.60661423401362]],
    [ "Poste électrique de Triey",[5.36553890650639, 47.327291241180234]],
    [ "Enedis",[3.2205241863818905, 49.607729033588896]],
    [ "Enedis",[3.226599453466038, 49.60455219731099]],
    [ "Enedis",[3.2271348986047808, 49.60442832297279]],
    [ "Enedis",[3.2180520990990993, 49.603873684684686]],
    [ "Poste électrique de Pusy",[6.16372801454689, 47.652885195674116]],
    [ "Poste électrique de Rolampont",[5.289869570012303, 47.923488891469866]],
    [ "Enedis",[-1.0848635126406634, 46.73913182727145]],
    [ "RTE",[5.152574401638834, 48.118092831921814]],
    [ "Enedis",[2.496252123620717, 48.85667453272676]],
    [ "Poste Électrique de Villegusien",[5.3242519500000025, 47.742929350000004]],
    [ "Poste électrique de Prauthoy",[5.2799888261749315, 47.688300378030576]],
    [ "Enedis",[-1.1259374633478987, 46.69990366614213]],
    [ "Poste électrique de Creney-prés-Troyes 400kV",[4.1376956846364035, 48.34711693277458]],
    [ "Poste électrique de Creney-prés-Troyes 63kV",[4.1369657026736615, 48.34336714093491]],
    [ "Enedis",[3.386298595171774, 47.36723233797586]],
    [ "Enedis",[3.3851678814432997, 47.35886019501718]],
    [ "Enedis",[3.386178011700321, 47.35563565490344]],
    [ "Enedis",[3.382496167113494, 47.35764591737753]],
    [ "Enedis",[3.388255269413032, 47.36045955702496]],
    [ "Enedis",[3.384592193939392, 47.36852377575757]],
    [ "Poste électrique de Froncles",[5.148848069848601, 48.294497528956796]],
    [ "Enedis",[-1.1345927499999986, 46.46002434999999]],
    [ "Enedis",[-1.1726755, 46.45669325]],
    [ "Enedis",[-1.1477757841104081, 46.45746331467255]],
    [ "Enedis",[-1.1820705, 46.45145835]],
    [ "Enedis",[-1.1884834499999992, 46.4591492]],
    [ "Enedis",[-1.18691525, 46.45799675000001]],
    [ "Enedis",[-1.14301625, 46.45936025]],
    [ "Enedis",[-1.1828564999999998, 46.45553950000001]],
    [ "Enedis",[-1.1558756648114104, 46.44808541925521]],
    [ "Enedis",[-1.1714360000000006, 46.451814500000005]],
    [ "Enedis",[-1.1393062802009866, 46.459128702563774]],
    [ "Gray",[5.592639491465082, 47.454550552701285]],
    [ "Enedis",[-1.8233799999999993, 46.674386999999996]],
    [ "Enedis",[-1.7979125499999995, 46.691422550000006]],
    [ "Enedis",[-1.7951412, 46.691476550000004]],
    [ "Enedis",[-1.8318222500000008, 46.671946]],
    [ "Enedis",[2.450134175371694, 48.81196185533989]],
    [ "Enedis",[2.449814017566001, 48.809951243532744]],
    [ "Calorifuge",[2.440165886426833, 48.8015597265125]],
    [ "TV Lettre",[2.4397564705764854, 48.80001369573544]],
    [ "RTE",[5.960137544721778, 48.71951440576604]],
    [ "Poste électrique de Croix-de-Metz",[5.9163744244882, 48.69208295129455]],
    [ "Enedis",[1.3545695173093233, 43.42218893332019]],
    [ "Enedis",[1.3540711415649027, 43.42735446404341]],
    [ "Enedis",[1.3529462499999987, 43.4255731]],
    [ "Enedis",[1.3509964658914728, 43.423982933333335]],
    [ "Enedis",[1.3556648999999987, 43.429524400000005]],
    [ "Enedis",[1.3509108128340828, 43.42512978256403]],
    [ "RTE",[6.329393028785555, 48.476058818523434]],
    [ "Poste électrique de Quevilloncourt",[6.097195212679383, 48.4753733102428]],
    [ "Enedis",[2.443411437591543, 48.799474470009564]],
    [ "Hemely",[2.4406394947517116, 48.79710583065335]],
    [ "Enedis",[2.438555436477577, 48.789809983019566]],
    [ "Enedis",[2.4362154348765266, 48.78987669196561]],
    [ "Lyon",[2.42529487618941, 48.80698273558461]],
    [ "Enedis",[2.4352274499999997, 48.8024551]],
    [ "Enedis",[2.4418469500000004, 48.80714100000001]],
    [ "Enedis",[2.4427313745989307, 48.80862934562208]],
    [ "Poste électrique de Vittel",[5.950897916104561, 48.189989155966785]],
    [ "Poste électrique de Dogneville",[6.453076935419254, 48.21758269969587]],
    [ "Poste Électrique de Golbey",[6.426255919467869, 48.2169659212592]],
    [ "Enedis",[2.43448668313375, 48.81305447550565]],
    [ "Malf Bois",[2.4385768488639963, 48.81017418272389]],
    [ "Enedis",[2.430617297253637, 48.812155382802025]],
    [ "Enedis",[2.4266846400999245, 48.814780801931306]],
    [ "Enedis",[3.6930308491374317, 50.110831984601134]],
    [ "Enedis",[2.0902181810148495, 48.31843416088185]],
    [ "Enedis",[2.091432177083334, 48.31333993749999]],
    [ "Enedis",[2.090040999999998, 48.312014000000005]],
    [ "Enedis",[2.0878274940282293, 48.31534903944988]],
    [ "Poste électrique de Jeuxey",[6.488138197692773, 48.18880183456941]],
    [ "Poste Électrique de Saint-Nabord",[6.604611586905196, 48.07208889630176]],
    [ "Enedis",[-1.1490757006802717, 45.84523495238094]],
    [ "Enedis",[-1.1371836852723598, 45.84607560165882]],
    [ "Enedis",[-1.158346438758846, 45.84851957593903]],
    [ "Enedis",[-1.156742158866995, 45.8467880139573]],
    [ "Enedis",[-1.166399831566549, 45.847726016489986]],
    [ "Enedis",[-1.1400711675126904, 45.84213167738296]],
    [ "Enedis",[-1.1498340357815444, 45.850248672316376]],
    [ "Enedis",[-1.144433974358974, 45.839924347985345]],
    [ "Poste Électrique de Remiremont",[6.599816095752814, 48.017649005461955]],
    [ "Poste électrique de Aston",[1.6765423586204955, 42.776543333819745]],
    [ "Poste électrique de Auzat",[1.4777752425030517, 42.763025335324286]],
    [ "RTE",[0.3731705347258328, 43.088368746012485]],
    [ "RTE",[0.058747700000000014, 43.26761075]],
    [ "Poste électrique de Sisco",[9.478402645872746, 42.80693267813489]],
    [ "Poste électrique rue Ernest Prévot - Cayenne",[-52.332486229382916, 4.9298204049982735]],
    [ "Poste électrique de Remire - Dégrad des Cannes",[-52.274860100000005, 4.863995999999999]],
    [ "Poste de transformation haute-tension - Suzini",[-52.296409908508146, 4.921462955682925]],
    [ "Poste électrique du carrefour Balata",[-52.33391893781602, 4.890145192338736]],
    [ "Poste Électrique de Plombières",[6.43883484750856, 47.93225011139141]],
    [ "Poste électrique de Fougerolles",[6.393549793599266, 47.88296392925954]],
    [ "Sous-station de Moyenmoutier",[6.8728381996854475, 48.37800047483378]],
    [ "Poste électrique d'Étival-Clairefontaine",[6.8717165860054275, 48.378834004886606]],
    [ "Poste électrique de Ganges",[3.724252490124423, 43.945887993873214]],
    [ "RTE",[3.599639111877659, 43.97949886034028]],
    [ "Enedis",[-1.2177418438885927, 45.87763952297321]],
    [ "Enedis",[-1.2104703785217716, 45.882197446487375]],
    [ "Enedis",[-1.209867836206897, 45.87261483620689]],
    [ "RTE",[4.151158618710496, 44.16643601609826]],
    [ "Poste électrique de Viradel",[4.177372105845895, 44.13783019084999]],
    [ "Enedis",[4.135083948197157, 44.13811550607905]],
    [ "Poste Électrique d'Anould",[6.960320732097594, 48.18476797752907]],
    [ "Enedis",[1.6836349500000005, 47.4925303]],
    [ "RTE",[1.757590485421849, 47.339601700744716]],
    [ "Enedis",[3.7032594953546347, 50.1184665778382]],
    [ "Enedis",[-1.2684780000000002, 45.911991]],
    [ "Enedis",[-1.2844415843003414, 45.90194462866895]],
    [ "Enedis",[-1.2569107342688999, 45.911689966349776]],
    [ "RTE",[7.094550353529513, 48.11521807483765]],
    [ "Enedis",[-0.9566534999999999, 45.729778499999995]],
    [ "Enedis",[-0.9369807500000001, 45.72583075000001]],
    [ "Poste électrique de Gatellier",[2.221894922725282, 44.94669987653184]],
    [ "121152771",[2.031502546508726, 44.98300053624755]],
    [ "Poste électrique de Roquerousse",[5.101037600850574, 43.6735126605551]],
    [ "RTE",[5.512105998895499, 43.70649992603386]],
    [ "Poste électrique de Champigny-lès-Langres",[5.340318065534814, 47.882361946886604]],
    [ "Enedis",[0.1490653224637681, 47.92310604347827]],
    [ "Les Aulnays",[0.16193132653061254, 47.92687807823129]],
    [ "Centre Commercial",[0.152751, 47.924092]],
    [ "Enedis",[0.1488094999999999, 47.92058]],
    [ "Enedis",[0.15163392156862765, 47.92508367973856]],
    [ "Enedis",[0.15156399999999973, 47.92129]],
    [ "Enedis",[0.17687667085014194, 47.92807993933961]],
    [ "Enedis",[0.17050722195779786, 47.94140553641124]],
    [ "Enedis",[0.1525365, 47.930023500000004]],
    [ "Enedis",[0.15532350000000003, 47.92461500000001]],
    [ "Enedis",[0.14681205345911935, 47.92516619968553]],
    [ "Enedis",[0.1623775, 47.942074999999996]],
    [ "9 Mars 1962",[0.14766549999999848, 47.921428500000005]],
    [ "Enedis",[0.14902500000000002, 47.925054]],
    [ "Enedis",[0.15490236887786765, 47.92949980264518]],
    [ "Butagaz",[0.16583871306666648, 47.934959855466666]],
    [ "Poste électrique de Montsinéry",[-52.50755251670909, 4.897487456756912]],
    [ "Bassigny",[5.348887902697364, 48.03989866166281]],
    [ "Poste Électrique de Montigny-le-Roi",[5.485609744023961, 47.99774258823981]],
    [ "Enedis",[-1.8143446769072946, 46.64720804091819]],
    [ "Enedis",[-1.8256128414677932, 46.644767523148175]],
    [ "Enedis",[-1.8064672500000003, 46.64686175]],
    [ "Enedis",[-1.8050020000000004, 46.64901849999999]],
    [ "Enedis",[-1.1122606793743897, 45.82614553176931]],
    [ "Enedis",[-1.10897671278826, 45.82627147053342]],
    [ "Enedis",[-1.100218133903134, 45.831625786324786]],
    [ "Enedis",[-1.103534657992565, 45.83191379306072]],
    [ "Enedis",[-1.1375525848610288, 45.82475429193771]],
    [ "Enedis",[-1.1044456914128595, 45.83115004187817]],
    [ "Enedis",[-1.1045134833127315, 45.824480851256695]],
    [ "Enedis",[-1.1149262110403402, 45.830546925265395]],
    [ "Enedis",[-1.124019320531758, 45.8262359753816]],
    [ "Enedis",[-1.1010698813029896, 45.82804847969656]],
    [ "Enedis",[-1.1054245643340863, 45.826933319036876]],
    [ "Enedis",[-1.1176893256356426, 45.829239489853045]],
    [ "Enedis",[-1.0878559999999997, 45.8189455]],
    [ "Enedis",[-1.0974732639206715, 45.824202835240264]],
    [ "Enedis",[-1.1107293835296619, 45.82172126934097]],
    [ "Enedis",[-1.0986877901234557, 45.825664543209875]],
    [ "Enedis",[-1.1080119999999993, 45.8227485]],
    [ "Enedis",[-1.1022390000000006, 45.823835499999994]],
    [ "Enedis",[-1.7981061840416337, 46.63555965169562]],
    [ "Enedis",[-1.8115749999999997, 46.637650750000006]],
    [ "Enedis",[-1.1235873813405792, 45.83301619044384]],
    [ "Enedis",[-1.8045364499999998, 46.64484375]],
    [ "Enedis",[-1.8049755000000005, 46.639843500000005]],
    [ "Enedis",[-1.1152695341760297, 45.82163577855805]],
    [ "Poste électrique de Trans en Provence",[6.47514663057172, 43.51007304351555]],
    [ "RTE",[-0.5666765796668598, 45.01359766587983]],
    [ "Enedis",[-1.37002975, 46.029320049999995]],
    [ "SNCF Réseau",[5.615661296535136, 48.2384743925835]],
    [ "Enedis",[4.13147290347284, 46.453780324369255]],
    [ "Enedis",[4.116517110871058, 46.458435627152895]],
    [ "Enedis",[4.135689746810438, 46.45100877370784]],
    [ "Enedis",[4.099460412526411, 46.444509955437624]],
    [ "Enedis",[4.119930866675496, 46.44828387035075]],
    [ "Enedis",[4.116896573297997, 46.46545408702106]],
    [ "Enedis",[4.129453133469599, 46.44948580578528]],
    [ "Enedis",[4.112042619503324, 46.462023037559426]],
    [ "RTE",[5.767460600000001, 43.68287205]],
    [ "RTE",[5.82633190873201, 43.72533301571981]],
    [ "Souvenir",[-1.3802742500000003, 46.0350143]],
    [ "Chassiron",[-1.4007939999999999, 46.04304650000001]],
    [ "Enedis",[-1.3983906823751575, 46.04008512280039]],
    [ "Enedis",[-1.65150225, 46.820828750000004]],
    [ "Folie",[6.132699113152538, 48.311458376572475]],
    [ "Jeanne d'Arc",[6.135135796030477, 48.30349455409297]],
    [ "Stade",[6.1433746722085845, 48.301263645214945]],
    [ "Groupe de livraison EDF / Groupe de secours (L09)",[6.107514677771791, 48.28964504754959]],
    [ "Enedis",[6.13364215, 48.310911899999994]],
    [ "Enedis",[6.072275330802502, 48.31897675723214]],
    [ "Enedis",[6.137133594833645, 48.27303627481277]],
    [ "Enedis",[6.1291512276712945, 48.282363977550375]],
    [ "Enedis",[3.503090459020037, 43.65061792106316]],
    [ "Enedis",[3.503481972867613, 43.64602976936453]],
    [ "Enedis",[3.50141675, 43.649578500000004]],
    [ "Enedis",[3.4988616730513127, 43.650143993419505]],
    [ "Enedis",[3.506708099715317, 43.64592787536718]],
    [ "RTE",[5.65660341150918, 43.6804416090279]],
    [ "Poste électrique de Saint-Tulle",[5.774807046291939, 43.783556683068106]],
    [ "Poste électrique du Biançon",[6.835855694028622, 43.59507265828493]],
    [ "Enedis",[4.853786159678524, 46.80136413285678]],
    [ "Champ Fleuri",[4.833155781977874, 46.792317316520695]],
    [ "Enedis",[4.860727457685872, 46.78289125528738]],
    [ "Enedis",[4.836322313280396, 46.798934240948725]],
    [ "Enedis",[4.845593616157547, 46.786875033642154]],
    [ "Enedis",[4.841151329909085, 46.79931185581661]],
    [ "Enedis",[4.863304379155868, 46.794329441222395]],
    [ "Enedis",[4.86353175, 46.78372105]],
    [ "Enedis",[4.858996473887526, 46.79122529633224]],
    [ "Enedis",[4.852172013418178, 46.806975220342636]],
    [ "Enedis",[4.838820644512689, 46.77883713435652]],
    [ "ORSA BETON",[4.873126792084924, 46.80058035832389]],
    [ "Enedis",[4.838822568110652, 46.780513535732034]],
    [ "Enedis",[4.839504235470835, 46.783471759387474]],
    [ "Enedis",[4.865006904788891, 46.79169000394824]],
    [ "Enedis",[4.840926686154455, 46.79215627301802]],
    [ "Enedis",[4.856645310265842, 46.79954626755117]],
    [ "Enedis",[4.862633678754863, 46.799253090332996]],
    [ "Enedis",[4.865698126954963, 46.79081875633356]],
    [ "Enedis",[4.855049627852683, 46.78402733483668]],
    [ "Enedis",[4.8490224999999985, 46.7718753]],
    [ "Enedis",[4.859394240832571, 46.787699602792664]],
    [ "Enedis",[4.854660453688736, 46.803603852476066]],
    [ "Enedis",[4.870955461201922, 46.786685292624206]],
    [ "Enedis",[4.836089227563116, 46.79391433995183]],
    [ "Crafer",[4.830004693281915, 46.79137081632828]],
    [ "Enedis",[4.84786458886138, 46.78841380896121]],
    [ "Enedis",[4.853965962491958, 46.79482731254916]],
    [ "Enedis",[4.856467493187505, 46.77040412966603]],
    [ "Enedis",[5.635187716695439, 46.7671771502257]],
    [ "Enedis",[4.838845574797704, 46.79721224521344]],
    [ "Enedis",[4.819990217186098, 46.77877399762398]],
    [ "Enedis",[4.854805716194718, 46.7868465398351]],
    [ "Enedis",[4.861323588053985, 46.770008906486716]],
    [ "Enedis",[4.833830656741315, 46.789454494956615]],
    [ "Enedis",[4.820259964994424, 46.78135727396504]],
    [ "Enedis",[4.84536204518158, 46.80308366727843]],
    [ "Centre Routier",[4.864510200000001, 46.774802750000006]],
    [ "Enedis",[4.849704908708323, 46.78894142975772]],
    [ "LYCEE H. CHARDONNET",[4.865649849719453, 46.80280757721082]],
    [ "Enedis",[4.855992516242287, 46.790658210487976]],
    [ "Enedis",[4.844108279511202, 46.783688355048604]],
    [ "Enedis",[4.845268048750978, 46.78289478435548]],
    [ "DEPOT PETROLIER",[4.866338164961926, 46.80429795343432]],
    [ "Enedis",[4.851379555292263, 46.79760953509852]],
    [ "Enedis",[4.856565552624491, 46.7952580260285]],
    [ "Enedis",[4.850278730228367, 46.78248035375889]],
    [ "Enedis",[4.862126164342094, 46.78957364316457]],
    [ "SAVIEM",[4.857449917193553, 46.812777846449244]],
    [ "Enedis",[4.826550784554611, 46.781749266699094]],
    [ "Enedis",[4.86217236039471, 46.789607037729546]],
    [ "Enedis",[4.84989153, 46.78551096]],
    [ "Enedis",[4.847883297114987, 46.791207050604314]],
    [ "Enedis",[4.855524217521936, 46.782409969864645]],
    [ "Enedis",[4.8645621499999985, 46.791988999999994]],
    [ "Enedis",[4.849509355493032, 46.78170248946419]],
    [ "HENRI DUNAND",[4.863518712702902, 46.80318316384168]],
    [ "Enedis",[4.84898400550544, 46.786875519176434]],
    [ "Enedis",[4.85321724311244, 46.78734353371919]],
    [ "Enedis",[4.852613464716455, 46.79034568287019]],
    [ "Enedis",[4.856715190199519, 46.78546770827239]],
    [ "Enedis",[4.835772349071588, 46.77972111956421]],
    [ "Enedis",[4.859037019610046, 46.76705661166244]],
    [ "Enedis",[4.854083891261883, 46.78576767985387]],
    [ "Enedis",[-0.7706699457364345, 45.95450035400518]],
    [ "Enedis",[-0.7758019805907157, 45.95616564894515]],
    [ "Enedis",[2.5037674999999995, 49.284659]],
    [ "Enedis",[2.196723179962209, 47.153591727923555]],
    [ "Enedis",[5.27941225, 45.55679675]],
    [ "Parc",[5.89495645614161, 48.18441059762534]],
    [ "Enedis",[5.895969576403311, 48.186355841073244]],
    [ "Enedis",[5.8915517916266955, 48.18528920761276]],
    [ "Enedis",[5.888887379658159, 48.18439916864056]],
    [ "Enedis",[5.899003050779681, 48.1737498169465]],
    [ "Enedis",[5.8852862855707135, 48.184999604734756]],
    [ "Enedis",[5.88896645, 48.18851125000001]],
    [ "Chaille",[5.882131906526824, 48.18736080728326]],
    [ "Enedis",[5.887853407297884, 48.1869208349633]],
    [ "Enedis",[5.898052281947998, 48.19566512703562]],
    [ "Enedis",[2.5328037484014394, 48.85050901596857]],
    [ "Enedis",[5.948614616792744, 48.201061968856436]],
    [ "Enedis",[5.947096295886465, 48.19579615470973]],
    [ "Enedis",[5.956812748939077, 48.2085999904386]],
    [ "Enedis",[5.953836327570878, 48.2083647579213]],
    [ "Enedis",[5.967336238618284, 48.201038334301145]],
    [ "Enedis",[5.944515670494859, 48.20354800470998]],
    [ "Enedis",[5.953159535528063, 48.20583100369929]],
    [ "Enedis",[5.9404359943858, 48.20713802660816]],
    [ "Enedis",[5.965371021517803, 48.20298818395794]],
    [ "Enedis",[5.954152783773286, 48.198965836474855]],
    [ "Enedis",[5.947778649906175, 48.19422845509212]],
    [ "Enedis",[5.953072309268243, 48.202577104179106]],
    [ "Enedis",[5.962280501802932, 48.199026337847144]],
    [ "Enedis",[5.951658989876932, 48.19515356570997]],
    [ "Enedis",[5.935555024798323, 48.19762733100795]],
    [ "Enedis",[5.949672382181791, 48.208075960203516]],
    [ "Enedis",[5.952896242422385, 48.19253899043645]],
    [ "Enedis",[5.959643716351408, 48.199825412975194]],
    [ "Enedis",[5.957975668220128, 48.20206083591142]],
    [ "Enedis",[5.945100757055661, 48.2168920608697]],
    [ "Enedis",[5.944673594869506, 48.20252610781504]],
    [ "Enedis",[5.938953480884742, 48.19997610312866]],
    [ "Enedis",[5.957641467300184, 48.195030639241295]],
    [ "Enedis",[5.967383412765885, 48.20502231621339]],
    [ "Enedis",[5.947557884049604, 48.21029231257751]],
    [ "Enedis",[5.959920546288869, 48.19356317782828]],
    [ "Enedis",[5.946471516894078, 48.20538884682451]],
    [ "Enedis",[5.957210903884795, 48.20595047089931]],
    [ "Enedis",[5.9463903633120845, 48.201390256139895]],
    [ "Enedis",[5.950639155158447, 48.19907184424589]],
    [ "Enedis",[5.940272624861559, 48.204049311783244]],
    [ "Enedis",[5.948480759878112, 48.2000641797395]],
    [ "Enedis",[5.961440456177319, 48.209775279849175]],
    [ "Enedis",[5.94307037008029, 48.20185426550221]],
    [ "Enedis",[5.953114549736143, 48.19792079163608]],
    [ "Enedis",[5.949375070766501, 48.202196617860906]],
    [ "Champeau",[5.091662338864986, 47.316772669534934]],
    [ "Enedis",[5.098430762557077, 47.31213788127854]],
    [ "Enedis",[5.100170814971292, 47.308284705557355]],
    [ "Enedis",[5.099270241177794, 47.31166431872331]],
    [ "Enedis",[5.100658436866981, 47.30637321449471]],
    [ "Enedis",[5.1026964999999995, 47.306349000000004]],
    [ "EDF SEI",[-61.27632081515188, 16.26513056969589]],
    [ "Poste électrique de PETIT PEROU",[-61.508904424700916, 16.253843981748606]],
    [ "Électricité de France",[-61.536577362486014, 16.25421174791171]],
    [ "Enedis",[5.768622499999999, 46.894971250000005]],
    [ "Enedis",[5.75622205, 46.91461425]],
    [ "Enedis",[5.75300675, 46.913640750000006]],
    [ "APEI",[5.767236628602172, 46.90884388589538]],
    [ "Électricité de France",[-61.71776157719516, 15.98598238704376]],
    [ "EDF SEI",[-61.703595366970696, 16.31291317637184]],
    [ "Enedis",[6.031780336714718, 46.243055565937]],
    [ "Enedis",[6.032802530413626, 46.25072757177616]],
    [ "Enedis",[6.021446000000001, 46.239935]],
    [ "Enedis",[6.017521178464607, 46.243663656364234]],
    [ "Le bugnon saint genis",[6.03534495819209, 46.25045766779661]],
    [ "Enedis",[6.033171345610805, 46.27810751688152]],
    [ "Enedis",[6.0215435, 46.242293000000004]],
    [ "Le château",[6.039598638020832, 46.25796685286459]],
    [ "Îlot des jardins",[6.035296749999999, 46.24243325]],
    [ "Enedis",[6.019564499999999, 46.241358500000004]],
    [ "Enedis",[6.025461871137905, 46.25107617131375]],
    [ "Hameau Amandine",[6.039598, 46.262074999999996]],
    [ "Pouilly",[6.035172792359827, 46.254414051756015]],
    [ "Enedis",[6.033101234564714, 46.248344845345734]],
    [ "Les croz Saint Genis",[6.034129882767039, 46.24513749626999]],
    [ "Les Cheminets",[6.038405727849535, 46.26370367527759]],
    [ "Les Lilas",[6.031180310077518, 46.24985850968992]],
    [ "Enedis",[6.019521452145215, 46.23942040346535]],
    [ "Enedis",[6.018548484791763, 46.24207414069568]],
    [ "Enedis",[6.020731652258853, 46.241715297924294]],
    [ "Enedis",[6.029279696132821, 46.24163865545512]],
    [ "121794753",[5.902664383291625, 47.81713720362851]],
    [ "La Dance",[6.048541708121704, 47.691903349784624]],
    [ "Enedis",[1.055028440395141, 49.90470470471233]],
    [ "Enedis",[1.0548131280844348, 49.90706833618302]],
    [ "Enedis",[1.0291070000000002, 49.9165605]],
    [ "Poste Électrique de Conflans",[6.2103513285778424, 47.81456754752782]],
    [ "Enedis",[-1.4267162500000006, 46.726649]],
    [ "Enedis",[-1.5164370000000003, 46.776449250000006]],
    [ "Enedis",[-1.495156422969759, 46.76581606139993]],
    [ "Enedis",[-1.5142804999999993, 46.76401869999999]],
    [ "Enedis",[-1.4337209240773665, 46.737014344485424]],
    [ "Enedis",[-1.5116502499999995, 46.768649999999994]],
    [ "Enedis",[-1.42113175, 46.726375749999995]],
    [ "Enedis",[-1.5144670000000007, 46.77341325]],
    [ "Enedis",[-1.4302044744093543, 46.73430049392163]],
    [ "Enedis",[-1.5084317708914718, 46.7716284549328]],
    [ "Enedis",[-1.43804625, 46.731345850000004]],
    [ "Enedis",[-1.4228710410555518, 46.72537281878469]],
    [ "Enedis",[-1.5190961167288368, 46.77100735100115]],
    [ "Enedis",[-1.5149243326059463, 46.76885609776802]],
    [ "Enedis",[-1.495979851015234, 46.77484373340164]],
    [ "Enedis",[-1.5197473720430987, 46.764337505655]],
    [ "Enedis",[-1.5097006499999988, 46.76922765]],
    [ "Enedis",[-1.50214625, 46.765035000000005]],
    [ "Enedis",[-1.5022360655353117, 46.769919832757445]],
    [ "Enedis",[-1.50401565, 46.7676656]],
    [ "Enedis",[-1.5138802575521753, 46.77067623109035]],
    [ "Enedis",[-1.5052618996063223, 46.77001198115052]],
    [ "Enedis",[-1.510790043903549, 46.77505800664953]],
    [ "Enedis",[3.7640375000000006, 50.138619999999996]],
    [ "Enedis",[1.0625893405654174, 49.48449482577252]],
    [ "Enedis",[1.041967125349831, 49.4897617107085]],
    [ "Enedis",[1.0489339643104656, 49.490999083160084]],
    [ "Enedis",[1.0618566471663624, 49.47335917489336]],
    [ "Enedis",[1.052213745601522, 49.47934874239183]],
    [ "Enedis",[1.0638770146995244, 49.48712566796369]],
    [ "Enedis",[1.052330242627243, 49.47946362955264]],
    [ "Enedis",[1.05792829843802, 49.4817934546361]],
    [ "Enedis",[0.7553679126290709, 49.61615435451417]],
    [ "Enedis",[0.7511980937564823, 49.61523488155984]],
    [ "Enedis",[0.7753671703136397, 49.61174487600292]],
    [ "Enedis",[0.744740278163232, 49.620254760540305]],
    [ "Enedis",[0.757782955752212, 49.616241339233035]],
    [ "Enedis",[0.7530827343029443, 49.60764617996926]],
    [ "Enedis",[0.7612203495024873, 49.61431044846082]],
    [ "Enedis",[0.7611907188587334, 49.61957167153793]],
    [ "Enedis",[0.7552758092243191, 49.62743328930817]],
    [ "Enedis",[0.7506855436082627, 49.61783361699509]],
    [ "Enedis",[0.7529151427094105, 49.6120825536022]],
    [ "Enedis",[0.7574371900368989, 49.61054140651906]],
    [ "Enedis",[3.1940100000000005, 49.61005600000001]],
    [ "Enedis",[3.1969658977987407, 49.61130054245283]],
    [ "Marcilly-sur-Tille / Poste de distribution 63kV",[5.134854795569482, 47.51336175681797]],
    [ "Poste électrique de Champs-Regnaud",[5.059895195067061, 47.35261734143141]],
    [ "SNCF Baulme la Roche",[4.784948788119558, 47.34084207079957]],
    [ "SNCF Réseau",[5.151770246905016, 47.17645887216061]],
    [ "Poste électrique de Couchey",[4.999988039248621, 47.25477398561729]],
    [ "Vesoul",[6.144974247430175, 47.62177136629635]],
    [ "Le Plesse",[-0.5114402499999999, 46.04523025]],
    [ "Enedis",[3.7715097798270345, 50.13781176973115]],
    [ "Enedis",[3.768306054521277, 50.13526653014185]],
    [ "Poste électrique de Chevigny-Saint-Sauveur",[5.1480163360382685, 47.290808169621954]],
    [ "Poste électrique de Darney",[6.045944744363044, 48.09987180178123]],
    [ "Poste électrique de Bains-les-Bains",[6.274143354307612, 48.00591347893278]],
    [ "SNCF Réseau",[6.328113814826531, 48.35220341239874]],
    [ "Enedis",[3.7538931733333327, 50.134641007441864]],
    [ "Enedis",[-0.6167282919318486, 45.94345925320335]],
    [ "Poste électrique de Laveline",[6.753391304711329, 48.178823628184645]],
    [ "Poste électrique de Pouxeux",[6.564163751774668, 48.11101465879502]],
    [ "Enedis",[3.8226888000000003, 45.1614863]],
    [ "RTE",[-1.326374507324411, 45.94143179049012]],
    [ "Enedis",[0.049659426118284326, 43.22371745634997]],
    [ "Enedis",[0.0681255, 43.245192]],
    [ "Enedis",[0.075905, 43.2338085]],
    [ "122335538",[0.07398738583518402, 43.23684033682725]],
    [ "122336048",[0.0632236615443713, 43.23687086336279]],
    [ "Poste électrique de Pymont",[5.561860218028523, 46.691693449536736]],
    [ "Poste source de Lons-le-Saunier",[5.562917672495304, 46.692307325319]],
    [ "Enedis",[6.048801459386666, 48.312025076938056]],
    [ "Enedis",[6.053978496886659, 48.310552400299635]],
    [ "Enedis",[6.067990921201083, 48.3102164164157]],
    [ "Enedis",[-1.0899985877232485, 48.21824957816652]],
    [ "Mouthier",[6.285414330454226, 47.0295666860889]],
    [ "Poste électrique de Champagnole",[5.907559333158087, 46.758248294777744]],
    [ "Sonella",[5.2621150000000005, 45.56914885]],
    [ "Enedis",[5.246345699999999, 45.5577476]],
    [ "Enedis",[-1.25457225, 46.351429249999995]],
    [ "Enedis",[-1.2476175717877174, 46.303071404530165]],
    [ "Enedis",[-1.2468752, 46.3463228]],
    [ "Enedis",[-1.2554170000000002, 46.354864250000006]],
    [ "Enedis",[-1.2510964999999998, 46.3556235]],
    [ "Enedis",[-1.250336, 46.34944124999999]],
    [ "Enedis",[-1.2526989999999996, 46.353352]],
    [ "Enedis",[-1.2581444999999998, 46.34884425]],
    [ "Enedis",[-1.2462454597028756, 46.34862902016737]],
    [ "Enedis",[-1.2597075, 46.352853499999995]],
    [ "Enedis",[-1.2620447499999998, 46.34992274999999]],
    [ "Enedis",[-1.107314953080375, 45.742672173806604]],
    [ "Enedis",[-1.1394462809152373, 45.75627994914196]],
    [ "Enedis",[4.039429485661989, 43.647482333943465]],
    [ "Enedis",[4.036606999999999, 43.64720225]],
    [ "Enedis",[3.151280015454906, 43.65056510703218]],
    [ "Enedis",[3.05602775, 47.948195000000005]],
    [ "Enedis",[3.051769, 47.9440715]],
    [ "Enedis",[5.379760053236716, 46.370376007632856]],
    [ "122541175",[5.398152935064934, 46.37376832359307]],
    [ "Enedis",[-0.7544813575558698, 45.5086375167995]],
    [ "Poste électrique de Grosne",[4.71101014245348, 46.62957828984784]],
    [ "Enedis",[-1.0436229847892224, 45.68630112212082]],
    [ "Chalon",[4.861159191235574, 46.762978263950636]],
    [ "Enedis",[5.8309362500000015, 46.570641249999994]],
    [ "Enedis",[5.802326930260048, 46.57330462056739]],
    [ "Enedis",[6.170176840944685, 48.61569066910981]],
    [ "Enedis",[6.1706088392555, 48.623443155910074]],
    [ "Enedis",[6.186726621089225, 48.617986754731554]],
    [ "Enedis",[6.180888419818624, 48.613736307675296]],
    [ "Enedis",[6.174565341984733, 48.6217374437659]],
    [ "Enedis",[6.202071500901364, 48.61466349343291]],
    [ "Enedis",[6.1857669611742425, 48.60992934147728]],
    [ "Enedis",[6.172937732325415, 48.61157306292297]],
    [ "Enedis",[6.161804392672129, 48.62372676650655]],
    [ "Enedis",[6.1885577670723455, 48.61539202974983]],
    [ "Enedis",[6.18562530979626, 48.61274464973486]],
    [ "Enedis",[6.17489536249015, 48.62568768321513]],
    [ "Enedis",[6.177025372390831, 48.6264123268024]],
    [ "Enedis",[6.158430710357471, 48.61948745432325]],
    [ "Enedis",[6.162373676938879, 48.613250820749876]],
    [ "Enedis",[6.173547558545091, 48.61852019282511]],
    [ "Enedis",[6.185011782825801, 48.6153706706778]],
    [ "Enedis",[6.1652318398531465, 48.61340841144449]],
    [ "Enedis",[6.1562018010850945, 48.620126909899106]],
    [ "Enedis",[6.1621356955046656, 48.61470443426632]],
    [ "Enedis",[6.195082844783716, 48.607216788804074]],
    [ "Enedis",[6.167354219757746, 48.616536645744844]],
    [ "Enedis",[6.177601646799118, 48.616480189845475]],
    [ "Enedis",[6.185110534552844, 48.61238258384147]],
    [ "Enedis",[6.1626881694402424, 48.61697401260717]],
    [ "Enedis",[6.183278280163598, 48.615433151329235]],
    [ "Enedis",[6.165607, 48.62709284999999]],
    [ "Enedis",[6.180772400956284, 48.61428522882514]],
    [ "Enedis",[6.1598120000000005, 48.615835999999994]],
    [ "Enedis",[6.174855391932158, 48.614432787913515]],
    [ "Enedis",[6.1744160515873014, 48.61997919146825]],
    [ "Enedis",[6.168412516765287, 48.61189461932939]],
    [ "Enedis",[6.1714855038978484, 48.6131501592742]],
    [ "Enedis",[6.18204024848485, 48.610836406060606]],
    [ "Enedis",[6.180575307498423, 48.621613959042215]],
    [ "Enedis",[6.168798816993463, 48.62004031045752]],
    [ "Enedis",[6.183172263269639, 48.617776581740976]],
    [ "Enedis",[6.159973628899836, 48.62633319704433]],
    [ "Enedis",[6.188156824726136, 48.61516046322379]],
    [ "Enedis",[6.172980647342995, 48.62416495169082]],
    [ "Enedis",[6.179575811185223, 48.62334464990594]],
    [ "Enedis",[6.182252166666666, 48.61294516666666]],
    [ "Enedis",[6.185556473626484, 48.610154074982546]],
    [ "Enedis",[6.180028658812441, 48.62506898052152]],
    [ "Enedis",[6.169200313868614, 48.6180694403893]],
    [ "Enedis",[6.193303988304093, 48.60849722709552]],
    [ "Enedis",[6.170795288820331, 48.61762351917143]],
    [ "Enedis",[6.175802815896737, 48.6213064384058]],
    [ "Enedis",[6.1628623157417595, 48.62053963705541]],
    [ "Enedis",[-1.0749969999999998, 45.7270004]],
    [ "Enedis",[1.2317296847389558, 48.58047520783133]],
    [ "Enedis",[1.246405, 48.58276649999999]],
    [ "Enedis",[1.2369669999999993, 48.589760250000005]],
    [ "Enedis",[1.2468015, 48.583943]],
    [ "Enedis",[1.2393495, 48.584907]],
    [ "Enedis",[1.2338535, 48.58527275]],
    [ "Enedis",[1.2359186895306864, 48.5813405824308]],
    [ "Enedis",[1.2358111417165667, 48.57875372854292]],
    [ "Enedis",[1.236516773333334, 48.58460694666666]],
    [ "Enedis",[1.234513901185771, 48.592149168642955]],
    [ "Enedis",[1.23683625, 48.587486500000004]],
    [ "Enedis",[1.241682, 48.57988400000001]],
    [ "Enedis",[1.2340272911668473, 48.581410641403124]],
    [ "RTE",[5.24748616450654, 47.10520285133375]],
    [ "RTE",[5.3846033010460275, 47.02212155919672]],
    [ "RTE",[5.249838890013376, 47.107312505996696]],
    [ "Enedis",[-0.8749437644176135, 45.62885746764199]],
    [ "Enedis",[-0.6737241355887245, 45.76987188586396]],
    [ "Poste électrique de Cize",[5.462685973827629, 46.2048763373604]],
    [ "Poste électrique de Fleyriat",[5.209880267366257, 46.232383597461414]],
    [ "Poste électrique d’Izernore",[5.549946296845021, 46.19883521978559]],
    [ "Poste électrique de Mûr-de-Bretagne",[-3.0053129910581626, 48.19808042561869]],
    [ "Enedis",[-4.085255899999999, 48.197057050000005]],
    [ "Pont Neuf",[-4.090304263857021, 48.19852777336959]],
    [ "Enedis",[2.324242178015479, 48.56655748160709]],
    [ "Enedis",[2.31248450867524, 48.559684642171874]],
    [ "Enedis",[2.2634968233486945, 48.58488399078341]],
    [ "Enedis",[2.2558408028359205, 48.588949021156864]],
    [ "Enedis",[2.257223716475095, 48.578914950191574]],
    [ "Enedis",[2.2452280737593937, 48.58003719875112]],
    [ "Enedis",[2.254498812671528, 48.583703467341]],
    [ "Enedis",[2.2628399567099575, 48.587023043290046]],
    [ "Enedis",[2.2627364963756356, 48.59288416661257]],
    [ "Enedis",[2.262491724770643, 48.59027274006116]],
    [ "Enedis",[2.261529599934362, 48.58316030795318]],
    [ "Enedis",[2.253467724559511, 48.58073054548724]],
    [ "Enedis",[2.255944878940731, 48.58076780117697]],
    [ "Enedis",[2.265271664926527, 48.581396514211136]],
    [ "Enedis",[2.2591287543131293, 48.59035169093089]],
    [ "Enedis",[2.261919114982178, 48.58126993066575]],
    [ "Enedis",[2.264260666666667, 48.58282218018017]],
    [ "Enedis",[5.402497049999999, 46.40957545]],
    [ "Enedis",[5.38507048905348, 46.40992360035656]],
    [ "Enedis",[5.394524188303336, 46.422463177826785]],
    [ "Enedis",[-0.8059433453683443, 45.53879010466813]],
    [ "Enedis",[-0.5383745000000001, 43.87006529999999]],
    [ "Enedis",[-0.5172582000000009, 43.87995395000001]],
    [ "Enedis",[-0.5204023437675724, 43.88908078150578]],
    [ "Pyrénées",[-0.4976398773615119, 43.88112319788665]],
    [ "Enedis",[-0.5105944999999996, 43.875414750000004]],
    [ "Enedis",[-0.6024219999999999, 43.91654099999999]],
    [ "Enedis",[5.37392775, 46.43951015]],
    [ "Enedis",[-1.1023444810126581, 45.73350615443039]],
    [ "Enedis",[-1.0978912046571376, 45.73317511275441]],
    [ "Enedis",[-1.1015195, 45.7378915]],
    [ "Enedis",[-1.1026250532905781, 45.73606285707741]],
    [ "Enedis",[-4.340082408486586, 48.09325428174508]],
    [ "Enedis",[-4.328444131695022, 48.07972590548204]],
    [ "Enedis",[-4.325872666666667, 48.09059997468355]],
    [ "Enedis",[-4.325347395085677, 48.08907329065631]],
    [ "Enedis",[-4.328303654825341, 48.090277920071046]],
    [ "Enedis",[-4.324973661333334, 48.094601492]],
    [ "Enedis",[-4.317414856584093, 48.08628428074751]],
    [ "Enedis",[-4.330971542115574, 48.086781052889336]],
    [ "Enedis",[-4.3279997, 48.084206099999996]],
    [ "Enedis",[-4.341070666666666, 48.09435979069768]],
    [ "Enedis",[-4.32710169750367, 48.09519793392071]],
    [ "Enedis",[-4.312329588310037, 48.08823522778484]],
    [ "Enedis",[-4.331769882157282, 48.09012230292231]],
    [ "Enedis",[-4.320314368805424, 48.08509581446705]],
    [ "Enedis",[-4.330528, 48.08369799999999]],
    [ "Enedis",[-4.322853960831491, 48.0888589886201]],
    [ "Enedis",[-4.328670923876331, 48.0875113024162]],
    [ "Enedis",[3.0499208460868865, 50.63042845591693]],
    [ "Enedis",[1.3647154000000006, 43.680264699999995]],
    [ "Enedis",[1.347583538888889, 43.687990757291665]],
    [ "Enedis",[1.34912525, 43.68890815]],
    [ "Enedis",[1.3584785, 43.68415949999999]],
    [ "Enedis",[1.3627915, 43.683274]],
    [ "Enedis",[1.3576328372943933, 43.6863116658834]],
    [ "Enedis",[1.3609334999999998, 43.688707]],
    [ "Gueugnon (EGD)",[4.075916918895306, 46.60965909975844]],
    [ "Enedis",[6.402472937002992, 46.210177673297366]],
    [ "Enedis",[6.404061068669311, 46.20680524604472]],
    [ "Enedis",[6.399417499999999, 46.204100499999996]],
    [ "Enedis",[3.146024009558674, 50.70463368517389]],
    [ "Enedis",[3.1383128783643888, 50.697961873706]],
    [ "Enedis",[3.144805030076366, 50.70899371493129]],
    [ "Enedis",[3.1353131628303497, 50.69851998721228]],
    [ "Enedis",[3.1364575, 50.699901999999994]],
    [ "Enedis",[3.1510642860940705, 50.70112517811861]],
    [ "Enedis",[3.1394930000000003, 50.705712000000005]],
    [ "Enedis",[3.1462890000000003, 50.702716]],
    [ "Enedis",[3.131208564681724, 50.70500832375085]],
    [ "Enedis",[3.1457625000000005, 50.7083665]],
    [ "Enedis",[3.1376954036522218, 50.7082224066503]],
    [ "Enedis",[3.1396815, 50.7073675]],
    [ "Enedis",[3.13538012605042, 50.70683574229691]],
    [ "Enedis",[3.128342746972242, 50.699874097261045]],
    [ "Enedis",[3.148866020474138, 50.70701033387213]],
    [ "Enedis",[3.1297155714285694, 50.70837926739927]],
    [ "Enedis",[3.13940715821256, 50.710208048309184]],
    [ "Enedis",[3.136138, 50.69723950000001]],
    [ "Enedis",[3.1406436691042052, 50.691809485070074]],
    [ "Enedis",[3.141388333024977, 50.71443599599136]],
    [ "Enedis",[3.1369861923296267, 50.70393167650413]],
    [ "Blanzy",[4.4073280758758075, 46.708507060592794]],
    [ "Enedis",[3.129608343644656, 50.72158445488797]],
    [ "Enedis",[3.124941369085174, 50.71082680967402]],
    [ "Enedis",[3.1280565, 50.719525499999996]],
    [ "Enedis",[3.1301460989010987, 50.72072484249084]],
    [ "Enedis",[3.090529181105611, 50.70686803712871]],
    [ "Enedis",[3.1227820000000013, 50.724900999999996]],
    [ "Enedis",[3.0761646410256405, 50.70321195266272]],
    [ "Enedis",[3.119689344455611, 50.72022967484087]],
    [ "Enedis",[3.1087404999999997, 50.71608299999999]],
    [ "Enedis",[3.122432397003746, 50.72400280791868]],
    [ "Enedis",[3.0732249139072843, 50.71755518984548]],
    [ "Enedis",[3.0779646708822646, 50.70935301565794]],
    [ "Enedis",[3.1109955, 50.71658899999999]],
    [ "Enedis",[3.0826575, 50.7056295]],
    [ "Enedis",[3.0804537454363605, 50.70380355397182]],
    [ "Enedis",[3.100153466447323, 50.702812162519706]],
    [ "Enedis",[3.086444, 50.687684000000004]],
    [ "Enedis",[3.0846685000000007, 50.690677]],
    [ "Enedis",[3.101915, 50.701691]],
    [ "Enedis",[3.0949722250872944, 50.704919449368795]],
    [ "Enedis",[3.0906863723669504, 50.696541451465905]],
    [ "Enedis",[3.1011570347685713, 50.706458497071345]],
    [ "Enedis",[1.4184110366088634, 43.607311649325624]],
    [ "Enedis",[1.4156004089615055, 43.6093695832405]],
    [ "Enedis",[1.102131900000001, 45.870943399999994]],
    [ "Enedis",[1.0801163625258223, 45.86971690529156]],
    [ "Enedis",[-0.014541366861030875, 48.751298617057394]],
    [ "Enedis",[1.423198221052632, 43.61065332342622]],
    [ "Enedis",[1.423513040438135, 43.61136982033559]],
    [ "Enedis",[5.775731060091916, 46.57772596945075]],
    [ "Millet Parterre",[5.752143364794009, 46.576846736329585]],
    [ "Camping",[5.756128631866654, 46.5656182912792]],
    [ "Lotissement du Lac",[5.7548385, 46.56912575]],
    [ "Soyria",[5.737441138586807, 46.56085276275397]],
    [ "Tournerie DP",[5.753994750000002, 46.577691]],
    [ "Champ d'Augeon",[5.7468734999999995, 46.5749255]],
    [ "Route du Lac",[5.750934218536591, 46.57414660953398]],
    [ "Église",[5.74948305, 46.57583185]],
    [ "Route de Lons",[5.747528573642819, 46.57909245304799]],
    [ "Grande Raye",[5.740907956505524, 46.58449372517052]],
    [ "Groupe Scolaire",[5.756041200000002, 46.57477585]],
    [ "Enedis",[5.744423956760736, 46.574337157646205]],
    [ "Grisière",[5.7567103999999985, 46.56156944999999]],
    [ "Saint-Ouen",[5.760780112469727, 46.552927499601175]],
    [ "Belle Croix",[5.74480315, 46.57979435000001]],
    [ "Enedis",[-0.6773380128429658, 45.57221118447168]],
    [ "Enedis",[-0.6766336548844444, 45.57011578694893]],
    [ "Enedis",[3.1197949499999984, 50.75024440000001]],
    [ "Enedis",[3.1334638530805687, 50.73639033807267]],
    [ "Enedis",[3.119512168110363, 50.74832468088975]],
    [ "Enedis",[3.122258815426997, 50.75162284022039]],
    [ "Enedis",[3.1271908124550034, 50.75104397840172]],
    [ "Enedis",[3.1283032235528943, 50.749086153692616]],
    [ "Enedis",[3.1156635489322193, 50.75777633649025]],
    [ "Enedis",[3.108519330497551, 50.72495216550658]],
    [ "Enedis",[3.1278130212765967, 50.757292283687946]],
    [ "Enedis",[3.1331681654026724, 50.741796443120265]],
    [ "Enedis",[3.1038331846590905, 50.72461137121211]],
    [ "Enedis",[3.1375531597165436, 50.7456935200327]],
    [ "Enedis",[3.1269700000000005, 50.740106999999995]],
    [ "Enedis",[3.1291176075949374, 50.743939248945146]],
    [ "Enedis",[3.1291317588155074, 50.73816682563803]],
    [ "Enedis",[3.11942403236246, 50.73944466828478]],
    [ "Enedis",[3.121779900061501, 50.76748425261377]],
    [ "Enedis",[3.1128890044840465, 50.7563468446485]],
    [ "Enedis",[3.1306954226348376, 50.750453093132926]],
    [ "Enedis",[3.12114163859535, 50.74763144393865]],
    [ "Enedis",[3.1369679112662014, 50.7442689890329]],
    [ "Enedis",[3.1339501735537185, 50.74067112672176]],
    [ "Enedis",[3.1306807508090615, 50.74230658899676]],
    [ "Enedis",[3.1331903722088366, 50.74468741558233]],
    [ "Enedis",[3.1213247816581484, 50.75927183276547]],
    [ "Enedis",[3.1158867686618503, 50.74026507155142]],
    [ "Enedis",[3.1188123349753685, 50.73609044170772]],
    [ "Enedis",[3.1186513061224486, 50.73888127324263]],
    [ "Enedis",[3.1212488428781207, 50.7377374640235]],
    [ "Enedis",[3.126403403292182, 50.74351525102882]],
    [ "Enedis",[3.124093105882352, 50.74970302509804]],
    [ "Enedis",[3.1230320908004767, 50.74829993548387]],
    [ "Enedis",[1.428439818666667, 43.60876182933333]],
    [ "Enedis",[1.4293143895110123, 43.60912840144242]],
    [ "Enedis",[2.99260453386196, 50.71161419015752]],
    [ "Enedis",[3.0427773219696976, 50.7126297282197]],
    [ "Enedis",[3.0014904999999996, 50.719671]],
    [ "Enedis",[2.9958841650306236, 50.700035596526554]],
    [ "Enedis",[3.0183980805497574, 50.7095804243215]],
    [ "Enedis",[3.005273076677316, 50.714871675186366]],
    [ "Enedis",[3.0042576953807743, 50.70553581980858]],
    [ "Enedis",[2.9977108087302407, 50.70920823438742]],
    [ "Enedis",[3.0073979206394243, 50.714185924328426]],
    [ "Enedis",[3.0031802312747007, 50.70678409241694]],
    [ "Enedis",[1.4335359385297815, 43.6091468769124]],
    [ "Enedis",[1.4341375995526957, 43.605865750036244]],
    [ "RTE",[6.127189351706816, 47.108764349033144]],
    [ "Enedis",[-0.5949333072716061, 45.706534819067855]],
    [ "Seloncourt",[6.863248514014628, 47.466603293340256]],
    [ "RTE",[6.836845671207421, 47.45210841919871]],
    [ "Enedis",[-0.5967885135679737, 45.56575734238551]],
    [ "Enedis",[-0.5920692281750597, 45.571233929102476]],
    [ "Enedis",[-3.225404536986302, 47.73667845981736]],
    [ "Poste électrique de Docelles",[6.6204777, 48.14777010000001]],
    [ "Poste électrique de Argiesans",[6.814023099422416, 47.60182420710688]],
    [ "RTE",[6.76160164091998, 47.57187665844947]],
    [ "Sous-station d'Héricourt",[6.770430908597445, 47.57576307014581]],
    [ "Kerhelene",[-3.2155246325951166, 47.674241971039194]],
    [ "Les Haut de Keryvon",[-3.2556577153075827, 47.70120272818312]],
    [ "Enedis",[-3.2401437380397864, 47.71175061258387]],
    [ "Magouër",[-3.214805666666667, 47.6595530877193]],
    [ "Poste électrique de Puyrenaud",[0.15576722607193386, 45.70479049996245]],
    [ "Essert",[6.83937839182822, 47.6369742702775]],
    [ "Alsthom",[6.839952044940767, 47.65095396033297]],
    [ "Enedis",[1.038575634361233, 49.47551975624083]],
    [ "Enedis",[1.045410500000001, 49.481402500000016]],
    [ "Enedis",[1.0242671, 49.476576900000005]],
    [ "Enedis",[1.0430535409133816, 49.476936784070276]],
    [ "Enedis",[1.0265586650406504, 49.48452379349593]],
    [ "Enedis",[1.0385066395730707, 49.47351728817734]],
    [ "Enedis",[1.0362512216526303, 49.47205419748565]],
    [ "Enedis",[1.0400875148514863, 49.47719132178218]],
    [ "Enedis",[1.0226184301292103, 49.4830426790649]],
    [ "Enedis",[4.798026146282973, 45.751983930455644]],
    [ "Enedis",[4.8027833, 45.752585]],
    [ "Enedis",[4.800624991031391, 45.75132202790235]],
    [ "Enedis",[4.797133754385966, 45.750753499556964]],
    [ "Enedis",[1.04700522925959, 49.477980916443656]],
    [ "Enedis",[-1.0191784021164025, 45.70343158933659]],
    [ "Enedis",[-1.0287014999999997, 45.707552899999996]],
    [ "Enedis",[-1.021718861297539, 45.70302179642058]],
    [ "Basses Amarres",[-1.0294628808193669, 45.711097247672264]],
    [ "Enedis",[-1.0343686998254802, 45.70930502094241]],
    [ "RTE",[6.627714981441447, 47.69561238980624]],
    [ "Enedis",[-0.7936403931623931, 45.47793288034188]],
    [ "Enedis",[-0.7845393342788113, 45.484049914381764]],
    [ "Enedis",[3.1912983163821766, 50.61660787498707]],
    [ "Enedis",[3.18915800126024, 50.6162903226213]],
    [ "Enedis",[3.191459552247352, 50.611635826891884]],
    [ "Enedis",[3.193633630355643, 50.62398176243816]],
    [ "Enedis",[3.1897365, 50.6041475]],
    [ "Enedis",[3.179565665393032, 50.60011277672022]],
    [ "Enedis",[3.1895873468169116, 50.611377825869205]],
    [ "Enedis",[3.1863984558482112, 50.604056265815366]],
    [ "Enedis",[3.197794, 50.60205100000001]],
    [ "Enedis",[3.18729930697085, 50.606431544486696]],
    [ "Poste électrique de Lure",[6.50566326545462, 47.6738057069945]],
    [ "Enedis",[1.6083897990002627, 50.76586507682188]],
    [ "Enedis",[1.6303584570446732, 50.78078558075601]],
    [ "Enedis",[1.612650025604887, 50.76458920108057]],
    [ "Enedis",[1.6102764999999999, 50.782102]],
    [ "Enedis",[1.6094287200000006, 50.773487093333344]],
    [ "Enedis",[1.6221548908761851, 50.78667470829342]],
    [ "Enedis",[1.6125580532407409, 50.77863291203704]],
    [ "Enedis",[1.6174905, 50.781840499999994]],
    [ "Enedis",[1.6105259697327043, 50.77672823388364]],
    [ "Enedis",[1.6193936576711643, 50.78009652773613]],
    [ "Enedis",[1.6065905852417302, 50.74669605597964]],
    [ "Enedis",[1.6071676268115944, 50.763884742753625]],
    [ "Enedis",[1.6127784887191947, 50.77225983211847]],
    [ "Enedis",[1.6308506702865764, 50.78317352126696]],
    [ "Enedis",[1.6073655746376811, 50.75253222608695]],
    [ "Enedis",[1.6059404739015526, 50.76102703419227]],
    [ "Enedis",[1.6261040462417427, 50.78200482520979]],
    [ "Enedis",[1.6173037165815842, 50.77856604310834]],
    [ "Enedis",[1.6155688667891597, 50.78031190353698]],
    [ "Enedis",[1.607219869131181, 50.76685664748089]],
    [ "Enedis",[1.620109013320013, 50.763916666333664]],
    [ "Enedis",[1.6226583875923766, 50.77677119467133]],
    [ "Enedis",[1.625384849999999, 50.743205849999995]],
    [ "Enedis",[1.6207716363013638, 50.73948385388725]],
    [ "Enedis",[1.6322455869565224, 50.76460585688407]],
    [ "Enedis",[1.6231362761904753, 50.76308306258503]],
    [ "Enedis",[1.6477200792249207, 50.74438860951681]],
    [ "Enedis",[1.6155109215686272, 50.76990518627451]],
    [ "Enedis",[1.6262414499999998, 50.741834499999996]],
    [ "Enedis",[1.6302633803687743, 50.7736223466519]],
    [ "Enedis",[1.616289228271252, 50.76569893696275]],
    [ "Enedis",[1.6369911079225747, 50.77064008102633]],
    [ "Enedis",[1.630171562347188, 50.767104798696]],
    [ "Enedis",[1.6321957260210576, 50.77240644533149]],
    [ "Enedis",[1.6458521233799894, 50.77092870632452]],
    [ "Enedis",[1.6147605745614035, 50.76872896820175]],
    [ "Enedis",[1.6269076349244616, 50.76424038508518]],
    [ "Enedis",[1.6275152527178451, 50.765605623850156]],
    [ "Enedis",[1.6478163560391053, 50.78091174456007]],
    [ "Enedis",[4.798410512922465, 45.75479959045726]],
    [ "Enedis",[4.796756807732497, 45.75445914524556]],
    [ "Enedis",[4.8005447929977425, 45.75473508712488]],
    [ "Enedis",[3.802678499999999, 50.1577385]],
    [ "Enedis",[-3.10711969032902, 47.78625432371874]],
    [ "Enedis",[3.210056, 50.590095500000004]],
    [ "Enedis",[3.2073359999999997, 50.5983335]],
    [ "Enedis",[3.2138055000000003, 50.5932915]],
    [ "Enedis",[3.2104813396107974, 50.59030152981796]],
    [ "Enedis",[3.2073095184764067, 50.59833868182678]],
    [ "Enedis",[3.2015854505632033, 50.59740783145598]],
    [ "Enedis",[3.2020221849682766, 50.59366980087847]],
    [ "Enedis",[3.2066904999999997, 50.5926595]],
    [ "Enedis",[3.2037635152895247, 50.59399482975493]],
    [ "Enedis",[3.210717465428276, 50.59601480495355]],
    [ "Douvot",[6.257076437895874, 47.312489015114856]],
    [ "Enedis",[3.073254245614035, 50.574314736842105]],
    [ "Enedis",[3.0751374000000005, 50.570775999999995]],
    [ "Enedis",[3.070649, 50.57324199999999]],
    [ "Enedis",[3.070597954764698, 50.5737318884109]],
    [ "Enedis",[3.0751559999999993, 50.571648499999995]],
    [ "Enedis",[3.0784404500900897, 50.57787201477478]],
    [ "Enedis",[3.07648527339778, 50.575487005513786]],
    [ "Enedis",[3.07734131349968, 50.57954641074856]],
    [ "Enedis",[3.083099571109623, 50.57717533623576]],
    [ "Enedis",[3.0850920000000004, 50.57905342222222]],
    [ "Enedis",[3.077039264069264, 50.573752965367966]],
    [ "Enedis",[5.3257100707731615, 46.166357947669766]],
    [ "Enedis",[2.962736336778639, 50.64609102153316]],
    [ "Enedis",[2.9592354999999997, 50.647813000000006]],
    [ "Enedis",[2.9614475940525584, 50.64318195504841]],
    [ "Enedis",[2.9560396937882762, 50.64735384426947]],
    [ "Enedis",[2.965596883580859, 50.639081581435654]],
    [ "Enedis",[2.9599917772692597, 50.64490747139589]],
    [ "Enedis",[2.9971898999999995, 50.59902045]],
    [ "Enedis",[3.0057539999999996, 50.590234]],
    [ "Enedis",[3.032477304526749, 50.57213321563786]],
    [ "Enedis",[1.3391634827562375, 47.57838508832678]],
    [ "Enedis",[1.347054667294413, 47.58449101192717]],
    [ "Enedis",[2.950721285121974, 50.725582547006084]],
    [ "Enedis",[2.973385820410868, 50.726529195493704]],
    [ "Enedis",[2.9446520000000005, 50.73487000000001]],
    [ "Enedis",[2.948256302889325, 50.734163412422454]],
    [ "Enedis",[2.9801296651388505, 50.57808470189629]],
    [ "Enedis",[2.999899344226579, 50.563230043572986]],
    [ "Enedis",[2.9958331644640235, 50.573364966226144]],
    [ "Enedis",[2.9923805239338557, 50.57467233420366]],
    [ "Enedis",[2.9851261203633372, 50.5716527623424]],
    [ "Enedis",[2.9890371720430102, 50.574128963133646]],
    [ "Enedis",[3.089054, 50.7624745]],
    [ "Enedis",[3.0732493872832376, 50.77266527437379]],
    [ "Enedis",[3.069749769230769, 50.77272703496503]],
    [ "Enedis",[3.078873952242525, 50.77236992524917]],
    [ "Enedis",[3.0723268463073854, 50.76893446107785]],
    [ "Enedis",[3.0734311892116177, 50.77142625864454]],
    [ "Enedis",[3.0714355, 50.767457]],
    [ "Enedis",[3.0765928857142852, 50.76899623809523]],
    [ "Enedis",[3.0652811020998367, 50.7730677476424]],
    [ "Enedis",[3.083118527224436, 50.768416547882545]],
    [ "Enedis",[3.0696931327978585, 50.76993631191432]],
    [ "Enedis",[3.0787267450980393, 50.766983915032675]],
    [ "Enedis",[3.0891261800788956, 50.765465221104535]],
    [ "Enedis",[3.0752053941259048, 50.766957775402226]],
    [ "Enedis",[1.6458367401129943, 50.40627204406779]],
    [ "Enedis",[1.6524925000000004, 50.401351500000004]],
    [ "Enedis",[1.6500347069239816, 50.398934351179356]],
    [ "Enedis",[1.6493601339977855, 50.39758018383167]],
    [ "Enedis",[1.6525798529522062, 50.407560584640784]],
    [ "Enedis",[1.638801519872814, 50.40279621303656]],
    [ "Enedis",[1.647774742552984, 50.409901907608166]],
    [ "Enedis",[-2.958064838372044, 47.389944280353824]],
    [ "Enedis",[3.104950173194081, 50.58414404177545]],
    [ "Enedis",[3.1337368583844567, 50.592403025562376]],
    [ "Enedis",[3.1114840000000004, 50.588530000000006]],
    [ "Enedis",[3.088606, 50.5877375]],
    [ "Enedis",[3.119596333333334, 50.59033963408521]],
    [ "Enedis",[3.091522321193335, 50.58036215911145]],
    [ "Enedis",[3.102045340680873, 50.59260548934606]],
    [ "Enedis",[3.1048744878534444, 50.58561283671844]],
    [ "Enedis",[3.1067198613138687, 50.586144931873484]],
    [ "Enedis",[3.1071827559033505, 50.581774917627676]],
    [ "Enedis",[3.098208955014749, 50.588822195427724]],
    [ "Enedis",[3.1064030000000002, 50.58624066666667]],
    [ "Enedis",[3.1177885, 50.59125149999999]],
    [ "Enedis",[3.1053966677955893, 50.59435150997215]],
    [ "Enedis",[3.088641188312845, 50.583765588989856]],
    [ "Enedis",[3.1169140000000004, 50.5891645]],
    [ "Enedis",[3.1129344999999993, 50.592541999999995]],
    [ "Enedis",[3.1208009999999993, 50.58101349999999]],
    [ "Enedis",[3.0951695000000004, 50.581874000000006]],
    [ "Enedis",[3.0899425, 50.5876735]],
    [ "Enedis",[3.1080394853641935, 50.590341417517585]],
    [ "Enedis",[3.1117135, 50.57670950000001]],
    [ "Enedis",[3.0952311123174834, 50.58188667477849]],
    [ "Enedis",[3.1099745, 50.5832435]],
    [ "Enedis",[3.0898817548076924, 50.587614319978634]],
    [ "Enedis",[3.0895165, 50.580045999999996]],
    [ "Enedis",[3.103194478558559, 50.59102616792793]],
    [ "Enedis",[3.10954507514832, 50.593917828169644]],
    [ "Enedis",[3.1284294999999998, 50.58821449999999]],
    [ "Enedis",[3.108136244357396, 50.581009584032834]],
    [ "Enedis",[3.1285356982872194, 50.590231194993414]],
    [ "Enedis",[3.0888811797289324, 50.590457265566684]],
    [ "Enedis",[3.120747536441562, 50.58950300613953]],
    [ "Enedis",[3.123358446480068, 50.59050980972576]],
    [ "Enedis",[3.114347524934385, 50.58732140419948]],
    [ "Enedis",[3.1068678530901717, 50.5926406127153]],
    [ "Enedis",[3.100451033589088, 50.58833008900256]],
    [ "Enedis",[3.1057433600368327, 50.581283337200745]],
    [ "Enedis",[3.1030010089267597, 50.57694884053561]],
    [ "Enedis",[-0.9046025, 45.686268]],
    [ "Enedis",[-0.9067842, 45.688274]],
    [ "Enedis",[-0.905529, 45.6823825]],
    [ "Enedis",[-1.1006768354628054, 45.67839299176604]],
    [ "Enedis",[-1.0889279971193415, 45.67135371563787]],
    [ "Enedis",[-1.110476441281139, 45.68419117319099]],
    [ "Enedis",[-1.0800490335446071, 45.66706900194371]],
    [ "Enedis",[3.7366254961597543, 47.74234533179723]],
    [ "Enedis",[1.3314783734939752, 43.522380687014724]],
    [ "Enedis",[1.3281131792880259, 43.52067547055016]],
    [ "Enedis",[1.3111614999999999, 43.519388000000006]],
    [ "Enedis",[1.3312609310614962, 43.51099731394439]],
    [ "Enedis",[1.3347835805346127, 43.51535716152616]],
    [ "Enedis",[1.320891735483871, 43.51129415268817]],
    [ "Enedis",[1.3128314906321896, 43.52112572206096]],
    [ "Enedis",[1.31937625, 43.52445875]],
    [ "Enedis",[1.3253163408095694, 43.514761639325]],
    [ "Enedis",[1.3293660000000007, 43.50964925]],
    [ "Enedis",[-2.89822795, 47.74108455000001]],
    [ "Enedis",[1.329410177033493, 43.51888247011915]],
    [ "Enedis",[1.322692622222223, 43.52706266222223]],
    [ "Enedis",[1.310678, 43.516316499999995]],
    [ "Enedis",[1.323580158003301, 43.5165800140264]],
    [ "Enedis",[1.3241961694915254, 43.51992249643822]],
    [ "Enedis",[1.3147728530380245, 43.520451766117866]],
    [ "Enedis",[1.321071, 43.52137033333333]],
    [ "Enedis",[1.3262329858510196, 43.51819233291719]],
    [ "Enedis",[1.3303355034099253, 43.515794333753014]],
    [ "Enedis",[1.3229153237125073, 43.51498555932842]],
    [ "Enedis",[1.3262312730893706, 43.51176616529748]],
    [ "Enedis",[1.3339878290854574, 43.51841424477762]],
    [ "Enedis",[1.3321732849296248, 43.51923929181586]],
    [ "Enedis",[1.3316605000000006, 43.513422999999996]],
    [ "Enedis",[1.31433032011631, 43.51408951784298]],
    [ "Enedis",[1.3276716211382116, 43.51500180975609]],
    [ "Enedis",[1.3173971468782772, 43.5214431319744]],
    [ "Enedis",[1.315248333333332, 43.515339000000004]],
    [ "Enedis",[1.306752331943286, 43.52342750403114]],
    [ "Enedis",[1.3295890701754383, 43.514016807017555]],
    [ "Enedis",[1.3219506748810073, 43.518269642714564]],
    [ "Enedis",[1.3229910575794301, 43.52492827395958]],
    [ "Enedis",[1.3212546357025698, 43.52468919681064]],
    [ "Enedis",[1.3204399999999998, 43.5152115]],
    [ "Enedis",[1.3210039666666664, 43.522226429078]],
    [ "Enedis",[1.330133934082397, 43.52086772846442]],
    [ "Enedis",[1.5904109999999998, 50.48071625]],
    [ "Enedis",[1.5960443838383835, 50.48108671717171]],
    [ "Enedis",[1.5873469999999998, 50.48162349999999]],
    [ "Enedis",[1.6303195167230367, 50.48267441450582]],
    [ "Enedis",[1.6087491024208551, 50.480023361887014]],
    [ "Enedis",[1.592763588050316, 50.478318496855344]],
    [ "Enedis",[1.601834227635989, 50.47817244186647]],
    [ "Enedis",[1.6176330000000014, 50.477215]],
    [ "Enedis",[1.6118368024170187, 50.47571583842396]],
    [ "Enedis",[1.6239055000000011, 50.5011305]],
    [ "Enedis",[1.5931823988095237, 50.47519191071429]],
    [ "Enedis",[1.6050438438775512, 50.48298372636054]],
    [ "Enedis",[1.6118404400919761, 50.48418004607046]],
    [ "Enedis",[1.6216971435217493, 50.49388808885788]],
    [ "Enedis",[1.5970941094527367, 50.481832278606966]],
    [ "Enedis",[1.6177032090831192, 50.48413554498715]],
    [ "Enedis",[1.6018953825330964, 50.47515927603241]],
    [ "Enedis",[1.5768510000000002, 50.47810049999999]],
    [ "Enedis",[1.6011655000000002, 50.483890499999994]],
    [ "Enedis",[1.623695963099631, 50.50668534809348]],
    [ "Enedis",[1.6304312322097374, 50.50153678901373]],
    [ "Enedis",[1.5796761812865499, 50.479479467836256]],
    [ "Enedis",[1.5893267664127666, 50.4807314461371]],
    [ "Enedis",[1.6219615000000005, 50.4794335]],
    [ "Enedis",[1.6250931532074089, 50.48733465936673]],
    [ "Enedis",[1.5782838899258567, 50.48109163395032]],
    [ "Enedis",[1.6050732471406677, 50.48026666949652]],
    [ "Enedis",[6.499632889193252, 47.68108405220526]],
    [ "Enedis",[2.7486085535976503, 50.439835672540376]],
    [ "Enedis",[2.7466333404255314, 50.446479971631206]],
    [ "Enedis",[2.740199307628265, 50.45266845748777]],
    [ "Enedis",[2.7446760000000006, 50.44079000000001]],
    [ "Enedis",[2.7551860746849948, 50.44973708247423]],
    [ "Enedis",[2.737842752315804, 50.4524710555793]],
    [ "Enedis",[2.735840342026379, 50.45210937612409]],
    [ "Enedis",[2.7507510133020334, 50.44996082785602]],
    [ "Enedis",[2.7443051516117607, 50.45510531420475]],
    [ "Enedis",[2.737688451652387, 50.44902317217462]],
    [ "Enedis",[2.7300930000000005, 50.45405583333334]],
    [ "123641375",[2.7542436918633224, 50.45388274790053]],
    [ "Enedis",[2.7475231069469834, 50.45172915874466]],
    [ "Enedis",[2.7422308640968613, 50.447521340993326]],
    [ "Enedis",[2.7432109999999996, 50.449512]],
    [ "Enedis",[2.7529913785310733, 50.45249006779661]],
    [ "RTE",[2.5819531649389007, 48.601239364378]],
    [ "RTE",[6.134273517228624, 47.28226398803157]],
    [ "Enedis",[3.2390145354200985, 50.54244142504118]],
    [ "Enedis",[3.2283728393393396, 50.54309165915916]],
    [ "RTE",[6.3245560636938105, 47.14418219728712]],
    [ "Enedis",[2.7189315049620544, 50.43977092761238]],
    [ "Enedis",[2.734163432178007, 50.43829474668378]],
    [ "Enedis",[2.721199503144654, 50.44885161857196]],
    [ "Enedis",[2.718825379583455, 50.45585773873081]],
    [ "Enedis",[2.720022087490102, 50.45060025851147]],
    [ "Enedis",[2.7088629999999996, 50.447827499999995]],
    [ "Enedis",[2.712883972422438, 50.4609254224381]],
    [ "Enedis",[2.7381260567375887, 50.441050666666655]],
    [ "Enedis",[2.7261345286537813, 50.4500683224822]],
    [ "Enedis",[2.7127315307536817, 50.44127232726922]],
    [ "Enedis",[2.7239555668829167, 50.457592713623725]],
    [ "Enedis",[2.7281274510592652, 50.43702182327702]],
    [ "Enedis",[2.734108741052632, 50.44814767017544]],
    [ "Enedis",[2.739973343878149, 50.44277903339192]],
    [ "Enedis",[2.7128713662671844, 50.44376689520621]],
    [ "Enedis",[2.7220571859013503, 50.44631946224303]],
    [ "Enedis",[2.718883506512142, 50.447325497378586]],
    [ "Enedis",[2.728812000000001, 50.438494000000006]],
    [ "Enedis",[2.7163846612392586, 50.44146509407508]],
    [ "Enedis",[2.7186865, 50.460188]],
    [ "Enedis",[2.7018995000000006, 50.45838449999999]],
    [ "Enedis",[2.741667311111111, 50.44008041333332]],
    [ "Enedis",[2.7198704466666674, 50.44337369578947]],
    [ "Enedis",[2.722601652346609, 50.43882774422493]],
    [ "Enedis",[2.7145555000000003, 50.439449499999995]],
    [ "Enedis",[2.7143557389243105, 50.436792470819036]],
    [ "Enedis",[2.7234531932429524, 50.45039443662578]],
    [ "Enedis",[2.7350199920695824, 50.43723463187515]],
    [ "Enedis",[2.731075, 50.4400305]],
    [ "Enedis",[2.711234181257296, 50.44997397198599]],
    [ "Enedis",[2.713438283433133, 50.45334545366409]],
    [ "Enedis",[2.720864098342542, 50.45432521178637]],
    [ "Enedis",[2.7298595692562895, 50.44584880499494]],
    [ "Enedis",[2.7195096052631587, 50.43641101392068]],
    [ "Enedis",[2.7134935197472347, 50.44284196208531]],
    [ "Enedis",[2.720246, 50.4382565]],
    [ "Enedis",[2.720587417065684, 50.44646865905463]],
    [ "Enedis",[2.7287661639315335, 50.438527170989936]],
    [ "Enedis",[-0.7561279999999999, 45.442149]],
    [ "Enedis",[5.355185898504273, 46.142511201388885]],
    [ "Enedis",[5.398478398196843, 46.1386943010268]],
    [ "MOINANS",[5.3830347939584415, 46.1387585259298]],
    [ "Enedis",[5.39240129160146, 46.15899274021909]],
    [ "Enedis",[6.853634700202278, 49.07140754583201]],
    [ "Enedis",[-0.9229475002327115, 45.67540693083868]],
    [ "Enedis",[-0.916076, 45.674611500000005]],
    [ "Enedis",[-0.9242421466666667, 45.68210545913044]],
    [ "Enedis",[-0.9234773000000001, 45.678952300000006]],
    [ "Enedis",[-0.9282177500000001, 45.666264549999994]],
    [ "Enedis",[-0.933683499999999, 45.6711515]],
    [ "Enedis",[-0.9545708496985364, 45.6871840292851]],
    [ "Enedis",[-0.9207062500000001, 45.667984]],
    [ "Enedis",[-0.9228778761904767, 45.6722512952381]],
    [ "Enedis",[-0.9410155829618627, 45.67820731599802]],
    [ "Enedis",[-1.7749447500000002, 48.093641]],
    [ "Enedis",[-0.9207240000000001, 45.66401450000001]],
    [ "Enedis",[-0.9237179615497076, 45.662116663888895]],
    [ "Enedis",[-0.9246775000000013, 45.672152000000004]],
    [ "Enedis",[-0.9287596319218242, 45.673052881650385]],
    [ "Enedis",[-1.760516224310175, 48.08531364395849]],
    [ "Enedis",[-0.9230381605301914, 45.665708960726555]],
    [ "Enedis",[-1.7679012929680373, 48.09521122009132]],
    [ "Enedis",[-1.7806377403351417, 48.11257440162235]],
    [ "Enedis",[-0.9302686671581638, 45.684283502408334]],
    [ "Enedis",[-1.811373699498066, 48.1034161889156]],
    [ "Enedis",[-0.9296565834871172, 45.67059593315563]],
    [ "Enedis",[-0.9252603472532953, 45.67589274869501]],
    [ "Poste Le Bouchaud",[5.575890152999966, 46.84480666199883]],
    [ "poste Gysserie",[5.600521587214918, 46.8341429588017]],
    [ "Enedis",[3.8022044999999998, 50.1773065]],
    [ "Enedis",[-1.4486185000000003, 48.0436935]],
    [ "Chamonal",[5.2152199999999995, 46.339898]],
    [ "Enedis",[5.1915713000000006, 46.33476240000001]],
    [ "Enedis",[5.18562225, 46.33550524999999]],
    [ "Enedis",[-1.3745385, 48.094039]],
    [ "Enedis",[-1.3695106277864177, 48.095009203732516]],
    [ "Enedis",[-1.369586807047949, 48.09678148180242]],
    [ "Enedis",[4.791888656587926, 45.80672578089239]],
    [ "Enedis",[-1.1432118558558553, 45.765891666666676]],
    [ "Enedis",[-1.1602397450519302, 45.786924946698015]],
    [ "Enedis",[-1.1553425242218098, 45.783141158936296]],
    [ "Enedis",[-1.1613508316831684, 45.796311066006595]],
    [ "Enedis",[-1.1238901995714723, 45.78018285093358]],
    [ "Enedis",[-1.1625501676789987, 45.788604995398494]],
    [ "Phare",[-1.2306954999999995, 45.696827000000006]],
    [ "Enedis",[-1.1479755000000007, 45.765890500000005]],
    [ "Enedis",[-1.1348869599678137, 45.76103550070408]],
    [ "Enedis",[-1.1573029642834807, 45.79148374954651]],
    [ "Enedis",[-1.1522302113821135, 45.793369501053895]],
    [ "Enedis",[-1.142450574774238, 45.76947238096657]],
    [ "Enedis",[-1.1700267743940334, 45.79403921110421]],
    [ "Enedis",[1.9291971783360178, 47.90075664030907]],
    [ "Enedis",[1.924429682852807, 47.90148616135559]],
    [ "Enedis",[1.9248032775784338, 47.90002202088509]],
    [ "Enedis",[1.9331176000000003, 47.90331865]],
    [ "RTE",[6.275415304223837, 46.79323545662698]],
    [ "EDF",[3.6347312056885235, 46.24117708095204]],
    [ "Enedis",[6.035379458113494, 48.30852017222057]],
    [ "Enedis",[-0.5125431564102567, 49.296681823076916]],
    [ "Enedis",[2.335365884735202, 48.51218822429906]],
    [ "Enedis",[2.3462236035583466, 48.51705825871272]],
    [ "Enedis",[2.284381418362404, 48.82911880838178]],
    [ "Enedis",[4.666852939717565, 47.59844792156517]],
    [ "Enedis",[4.647925922789439, 47.60012086628446]],
    [ "Kerdaniel",[-3.525720126302142, 47.87720486678385]],
    [ "Chemin de Kerstrado",[-3.51504795, 47.8749042]],
    [ "Enedis",[2.3356532000000008, 48.782919549999995]],
    [ "Enedis",[2.353202044108381, 48.77583466792691]],
    [ "Enedis",[2.3501468930390486, 48.77190552688172]],
    [ "Enedis",[5.398600770022302, 46.51444697973766]],
    [ "Enedis",[0.6081848630549284, 49.40817095711061]],
    [ "Assistair",[5.072278520062941, 45.71699542006253]],
    [ "Champ Vallet",[5.105085483998151, 45.70828289494218]],
    [ "Jules Ferry",[5.128137878648299, 45.7153291057412]],
    [ "Prairie",[5.130102378282353, 45.71878209439381]],
    [ "Trièves",[5.128793487606623, 45.71463621673438]],
    [ "Enedis",[3.984447481089907, 46.48178219117619]],
    [ "Enedis",[4.003908107828641, 46.494284769538865]],
    [ "Enedis",[4.002552061787008, 46.492152681835684]],
    [ "Enedis",[3.9946140998705357, 46.478486358492326]],
    [ "Enedis",[3.989236756247516, 46.476476236447155]],
    [ "Enedis",[3.972645179385124, 46.481517641861075]],
    [ "Enedis",[4.019783592508927, 46.495830167179015]],
    [ "Enedis",[3.9912684241378735, 46.47376128729774]],
    [ "Enedis",[3.980016499572811, 46.478956347880604]],
    [ "Enedis",[3.9918955096286703, 46.48859095891623]],
    [ "Enedis",[3.9803624886574887, 46.48142229134751]],
    [ "Champ Prévois",[5.062297002093509, 47.31172550400094]],
    [ "Essilor",[5.064059154471545, 47.308415455284546]],
    [ "RTE",[5.877124919845423, 47.10076467331369]],
    [ "Enedis",[5.029222166335503, 44.9807332173347]],
    [ "Enedis",[4.975311845184706, 44.96600631150149]],
    [ "Enedis",[4.976590645664386, 44.98842070592135]],
    [ "Enedis",[5.391144786664497, 46.528240084691326]],
    [ "Enedis",[4.7821615425101225, 45.745974172739544]],
    [ "Enedis",[4.786060652905198, 45.74553603058104]],
    [ "Enedis",[4.7841323740469415, 45.74916136891414]],
    [ "Enedis",[4.790070836097416, 45.75034604133672]],
    [ "Enedis",[1.299997353413654, 47.327199947791165]],
    [ "Enedis",[1.341784312053668, 47.29459702863738]],
    [ "Enedis",[2.938850999999999, 50.640818]],
    [ "Enedis",[2.9155223484848487, 50.63970976818182]],
    [ "Enedis",[2.9434734999999996, 50.637372000000006]],
    [ "Enedis",[2.968415433319983, 50.63868833213189]],
    [ "Enedis",[2.9209769999999993, 50.645507499999994]],
    [ "Enedis",[2.9290624302496333, 50.64286282476749]],
    [ "Enedis",[2.908865372703412, 50.642589958005246]],
    [ "Enedis",[0.08796013172751206, 48.44795256329192]],
    [ "RTE",[6.392640631015505, 47.81505012692845]],
    [ "Enedis",[0.06344992504295531, 48.43115195489691]],
    [ "Enedis",[0.07764183277216612, 48.43814252076319]],
    [ "Enedis",[0.07769761937716221, 48.43126973471742]],
    [ "Enedis",[0.07219075654367656, 48.434737073793755]],
    [ "Enedis",[0.06795570764381402, 48.43261447029157]],
    [ "Enedis",[0.07844874051695183, 48.43697316549179]],
    [ "Enedis",[0.08304476065382341, 48.4380427429739]],
    [ "Enedis",[0.0822716921027828, 48.43239984339548]],
    [ "Enedis",[0.6637863422888487, 46.008055318690126]],
    [ "Enedis",[0.6439572519292397, 45.99382374943257]],
    [ "Enedis",[0.09435968381564862, 48.4130423494105]],
    [ "Enedis",[0.09446882558756119, 48.41050248353396]],
    [ "Enedis",[4.781529438824663, 45.752093497591524]],
    [ "Samoens",[6.7198033463398925, 46.07637477806984]],
    [ "Poste électrique de la Saisse",[5.693157367265484, 46.57993958324444]],
    [ "RTE",[6.026636260816095, 46.50189349526729]],
    [ "Enedis",[2.050324612616751, 48.81051378145605]],
    [ "Enedis",[2.0402629346168886, 48.81161359859743]],
    [ "Enedis",[2.0396454861115956, 48.8142415388215]],
    [ "Enedis",[2.047471364148912, 48.808342386259156]],
    [ "Enedis",[2.039587502326101, 48.8105131310043]],
    [ "Enedis",[3.4819349999999996, 49.703625]],
    [ "Enedis",[3.483490142857143, 49.70193881904761]],
    [ "Enedis",[0.09383875126345835, 48.43779283432213]],
    [ "Enedis",[0.100316, 48.43733100000001]],
    [ "Centre d'appels",[4.086697520864064, 46.04450104019108]],
    [ "Quai du Canal",[4.084926782373728, 46.04221279973614]],
    [ "Résidence Albert Thomas",[4.073510969826396, 46.052190656053895]],
    [ "Enedis",[4.080054645404605, 46.02004570151038]],
    [ "Enedis",[2.5911338916909825, 44.35168892525843]],
    [ "Enedis",[2.5832056339454152, 44.359022674557664]],
    [ "Canal 2",[4.086811081723722, 46.04462896653015]],
    [ "Enedis",[2.567517881667587, 44.34615786432909]],
    [ "Enedis",[2.5682519053870148, 44.343399503379636]],
    [ "Enedis",[2.592410850978175, 44.3522143659699]],
    [ "Enedis",[2.5895913987942127, 44.3532681802894]],
    [ "Enedis",[2.5678020705427524, 44.35806161194965]],
    [ "Enedis",[2.5664567, 44.3446179]],
    [ "Enedis",[5.10258755, 46.40009630000001]],
    [ "Enedis",[5.113781634557728, 46.400551096402154]],
    [ "Enedis",[5.11055175, 46.401713]],
    [ "Les Balmes",[4.082573863987459, 46.022863623701454]],
    [ "Enedis",[2.570923420379488, 44.35671018113717]],
    [ "L'Herminier 84",[4.083462061007982, 46.04053405201122]],
    [ "Rodez Av. de Bordeaux",[2.5760342370514713, 44.35734182876035]],
    [ "Enedis",[2.578411154765406, 44.35069824093133]],
    [ "Enedis",[4.899073640023682, 45.73649450858496]],
    [ "Enedis",[4.89858834390602, 45.73675825403817]],
    [ "Enedis",[4.895250294243268, 45.73784737852554]],
    [ "Enedis",[1.929674592757069, 47.903588627363405]],
    [ "Enedis",[1.9369151423797837, 47.93340458716116]],
    [ "Enedis",[1.926481677876689, 47.90854466271019]],
    [ "Enedis",[1.9353293413657042, 47.915111748858514]],
    [ "Enedis",[1.9304261598351762, 47.90481116851009]],
    [ "Enedis",[1.9287148542026835, 47.90673775335474]],
    [ "Enedis",[1.9261175373285617, 47.912316574497574]],
    [ "Enedis",[1.9356347, 47.909674100000004]],
    [ "Enedis",[1.9373324499999989, 47.920350899999995]],
    [ "Enedis",[1.9343413, 47.909208400000004]],
    [ "Enedis",[1.9195762668223992, 47.90951256518071]],
    [ "Enedis",[1.9320336340852144, 47.91384081704261]],
    [ "Enedis",[1.9247236499999991, 47.9100001]],
    [ "Enedis",[1.9283452640454584, 47.908687845843644]],
    [ "Enedis",[1.9334986099716223, 47.917547224968516]],
    [ "Enedis",[1.9377269000000001, 47.9131811]],
    [ "Enedis",[1.939899754998507, 47.918256564408644]],
    [ "Enedis",[1.916969159942425, 47.91460907940082]],
    [ "Enedis",[1.9417176640805005, 47.92693832411289]],
    [ "Enedis",[1.9329006116018141, 47.912365245802505]],
    [ "Enedis",[1.935491128675024, 47.929516252807666]],
    [ "Enedis",[1.9132345134264497, 47.909952997358275]],
    [ "Enedis",[1.938103673859815, 47.90824160245391]],
    [ "Enedis",[1.9245863737039421, 47.91240229439819]],
    [ "Enedis",[1.9278528676727027, 47.90986298256203]],
    [ "Enedis",[1.928580525552279, 47.92480058949156]],
    [ "Enedis",[1.9321391999999997, 47.91010835000001]],
    [ "Enedis",[1.9299803169618635, 47.910717216573914]],
    [ "Enedis",[1.9409724191105602, 47.92122678204631]],
    [ "Enedis",[1.928748589871403, 47.9167052500045]],
    [ "Enedis",[1.9375937132847987, 47.92578008304261]],
    [ "Enedis",[1.9194767827276284, 47.91225007385698]],
    [ "Enedis",[1.93662599923103, 47.92768466065376]],
    [ "Enedis",[1.936160666871984, 47.91209349758751]],
    [ "Enedis",[1.9368278029996804, 47.91681926045786]],
    [ "Enedis",[1.927926, 47.9131471]],
    [ "Enedis",[1.9345518500000003, 47.907380749999994]],
    [ "Enedis",[1.9298091499999992, 47.9198839]],
    [ "Enedis",[1.9199556702135079, 47.914803039635075]],
    [ "Enedis",[1.931109792013589, 47.91578335062415]],
    [ "Enedis",[1.9271854763486786, 47.90669517829457]],
    [ "Enedis",[0.1009933092198585, 48.430442856737585]],
    [ "RTE",[0.33758991381758524, 45.02735081918299]],
    [ "Poste électrique de Menesplet",[0.1284708916990091, 45.009206225712106]],
    [ "RTE",[6.856847984780593, 47.661866507690036]],
    [ "RTE",[6.829344516925904, 47.73505338025094]],
    [ "Enedis",[4.409094868696328, 46.79407833619012]],
    [ "Enedis",[4.423281864188874, 46.79051305618827]],
    [ "Enedis",[4.434798974764345, 46.81101198893528]],
    [ "Enedis",[4.44748075, 46.81472349999999]],
    [ "RTE",[4.701512595494435, 45.236637031654645]],
    [ "RTE",[6.819904020386419, 47.50919488728641]],
    [ "RTE",[6.8173797677351, 47.51510415915344]],
    [ "Enedis",[4.4178787005001965, 46.790328660023526]],
    [ "Enedis",[0.09140450000000075, 48.412993500000006]],
    [ "Enedis",[0.11398962886202296, 48.4356414513529]],
    [ "Enedis",[0.07412914462299199, 48.42776175566543]],
    [ "Enedis",[0.07090800000000128, 48.429955500000005]],
    [ "Enedis",[0.06310069597069551, 48.428529417582425]],
    [ "Enedis",[0.07737775623359651, 48.42573693110236]],
    [ "Enedis",[1.817637202898551, 46.44458876811593]],
    [ "Enedis",[1.8296840000000003, 46.4295265]],
    [ "Enedis",[1.8299025809196534, 46.43366721371298]],
    [ "Enedis",[1.8219110269759915, 46.433635773630115]],
    [ "Enedis",[5.039812259520109, 47.33742993292364]],
    [ "Enedis",[4.887817270358218, 45.72997000792999]],
    [ "Enedis",[4.9154024822111815, 45.72541569173662]],
    [ "Enedis",[4.914605267624047, 45.72159899826655]],
    [ "Enedis",[-3.463906936967374, 47.861780020069126]],
    [ "P55 Kerloret",[-3.4599497000000006, 47.8652758]],
    [ "SER",[8.027997718474985, 48.838670598120366]],
    [ "Enedis",[2.9531647304490556, 42.63937983432993]],
    [ "Enedis",[2.9396331624442875, 42.646132021581046]],
    [ "Enedis",[2.9513041230329042, 42.641268139405504]],
    [ "Enedis",[2.9324594793261873, 42.64588269167945]],
    [ "Enedis",[2.9565365, 42.6381265]],
    [ "Enedis",[2.9442544121257677, 42.635109165226105]],
    [ "Enedis",[2.9560904999999997, 42.643386]],
    [ "Enedis",[2.953530522033898, 42.637983654237296]],
    [ "Enedis",[2.954698017917676, 42.636809669087974]],
    [ "Enedis",[2.9499921698113205, 42.63955499091544]],
    [ "Drapeau",[5.044045740391294, 47.3367780193701]],
    [ "Enedis",[0.08904700000000002, 48.426869]],
    [ "Enedis",[0.08615165268427373, 48.429982403417924]],
    [ "muriers",[5.041525117437054, 47.335359060828665]],
    [ "Enedis",[0.09084088591800346, 48.424286139928704]],
    [ "Enedis",[0.0893181841834717, 48.420786186160534]],
    [ "Poste électrique Les Abers",[-4.68640706587481, 48.51572532401002]],
    [ "Enedis",[0.19141158101601882, 47.20736260512819]],
    [ "Enedis",[0.26757987653520826, 46.6848870956672]],
    [ "Poste électrique Laneuveville",[6.234825399438019, 48.64882271440976]],
    [ "Enedis",[4.825575207042625, 45.80215946491925]],
    [ "Poste électrique de Vaise",[4.806304245416707, 45.77576633851752]],
    [ "Poste électrique des Espagnoux",[6.139240694415176, 45.887165741574705]],
    [ "Enedis",[5.130942989454881, 46.33308324685001]],
    [ "Enedis",[0.19905849999999997, 49.5155735]],
    [ "Enedis",[2.96835831963995, 50.59070047199082]],
    [ "Enedis",[2.975763984266544, 50.58988479392256]],
    [ "Enedis",[2.956500074487896, 50.594839348230906]],
    [ "Enedis",[2.967139203179056, 50.597128658438514]],
    [ "Enedis",[2.974078206389238, 50.591703298024385]],
    [ "Enedis",[2.9619538924945914, 50.59560018788485]],
    [ "Enedis",[2.9637095380811083, 50.59038983283877]],
    [ "Enedis",[2.9646096903954806, 50.59799203728813]],
    [ "Enedis",[2.9652279334677423, 50.59596868514785]],
    [ "Enedis",[2.9690744999999987, 50.58674349999999]],
    [ "Enedis",[2.975083716753022, 50.59303436096719]],
    [ "Enedis",[2.957925958733953, 50.59494753559688]],
    [ "Enedis",[2.961482670995671, 50.59362501731602]],
    [ "Enedis",[2.9541489431119317, 50.59356736008611]],
    [ "Enedis",[2.9489958375116063, 50.59247970380688]],
    [ "Enedis",[2.978154052910053, 50.59509779407407]],
    [ "Enedis",[2.9678234999999997, 50.5928335]],
    [ "Enedis",[2.972313300766283, 50.58939065325671]],
    [ "Enedis",[2.9637147963690817, 50.60004605596107]],
    [ "Enedis",[5.130143734949191, 46.38085773224975]],
    [ "Poste électrique de Tuilières",[0.6359211821295248, 44.84447736794641]],
    [ "Enedis",[0.19832155534411672, 49.48366708246114]],
    [ "Enedis",[0.199902, 49.48293549999999]],
    [ "RTE",[0.8072677817869979, 44.85458750246756]],
    [ "Roscasgen",[-3.5224058506781657, 47.86676521686361]],
    [ "Enedis",[3.4875458454317876, 49.843956011681264]],
    [ "Enedis",[3.4946226390509536, 49.83957115091405]],
    [ "Enedis",[5.045805096454981, 47.33930125789784]],
    [ "Enedis",[3.4067842812607063, 49.85269429770469]],
    [ "Enedis",[3.3697577657342657, 49.85116184498835]],
    [ "Enedis",[0.20470502024241666, 49.46595749070039]],
    [ "Enedis",[0.20495149999999968, 49.46707075]],
    [ "Enedis",[0.22481205, 49.481390700000006]],
    [ "Enedis",[3.318919628985507, 49.83757781449276]],
    [ "Enedis",[3.327406749140894, 49.867805054982824]],
    [ "Enedis",[3.306551753121782, 49.86301589587194]],
    [ "Enedis",[3.2233561896116987, 49.851737466464954]],
    [ "Enedis",[3.2882305000000005, 49.833142]],
    [ "Enedis",[3.289649685687113, 49.820263168489454]],
    [ "Enedis",[3.298592500000001, 49.817465]],
    [ "Enedis",[3.2850276333845363, 49.82960368356375]],
    [ "Enedis",[3.288379697548736, 49.821802959467284]],
    [ "Enedis",[3.2911480814963254, 49.82324577087508]],
    [ "Enedis",[3.2871796630920467, 49.82790698391421]],
    [ "Enedis",[3.273691488897253, 49.825385163091205]],
    [ "Enedis",[3.29316, 49.8124805]],
    [ "Enedis",[3.280394983739838, 49.82952400813008]],
    [ "Enedis",[3.271173744114638, 49.82565761173661]],
    [ "Enedis",[3.291957873550822, 49.830590446211914]],
    [ "Enedis",[3.298483818415744, 49.824824439321226]],
    [ "Enedis",[3.2787259618667566, 49.82764910841371]],
    [ "Enedis",[3.320359, 49.877387999999996]],
    [ "Enedis",[3.3607381667816867, 49.83347727007132]],
    [ "Cimetière",[0.1853513611898457, 47.932913376454806]],
    [ "Les Baladins",[0.1794552795156408, 47.94770694080054]],
    [ "Les Tilleuls",[0.1878396806584349, 47.94345098847737]],
    [ "Bourg Arnage",[0.18709167990788714, 47.93143414795626]],
    [ "Porte d'Arnage",[0.18199848687734327, 47.94988267148724]],
    [ "Enedis",[0.18137250000000002, 47.94339600000001]],
    [ "Enedis",[0.18626684891407028, 47.937740197356]],
    [ "Petit Saule",[0.19063700000000153, 47.94136649999999]],
    [ "La Vallée",[0.1865427048146941, 47.925387436909034]],
    [ "Le Bosquet",[0.1829989407431136, 47.922932264445876]],
    [ "Daf Nauguibert",[0.18519477310924384, 47.94017265266106]],
    [ "Le Noyer",[0.17466393046776246, 47.91739418036241]],
    [ "Gautrie 6",[0.1834395, 47.945647]],
    [ "Enedis",[0.1849156916221034, 47.926455481283426]],
    [ "Écoles",[0.18597563690476235, 47.92779632738097]],
    [ "Val de Sarthe",[0.1804448317757014, 47.920024302180686]],
    [ "Enedis",[0.18337, 47.938671]],
    [ "Albert Guérin",[0.18546345911949771, 47.93623772955975]],
    [ "La Rivière",[0.1835235, 47.9516925]],
    [ "François Monier",[0.1876035416903014, 47.94976450085081]],
    [ "Fonderie Mancelle",[0.18720738558136468, 47.916798243997654]],
    [ "Enedis",[0.1876608717487446, 47.943507967243846]],
    [ "Enedis",[0.19397226866046682, 47.92743583345899]],
    [ "Enedis",[2.9317232821215176, 50.612654545224025]],
    [ "Enedis",[3.3392849637561794, 49.806896836079076]],
    [ "Enedis",[0.20292850000000004, 49.507543500000004]],
    [ "Enedis",[3.2009466513855624, 49.83226377431861]],
    [ "Bourg",[0.2496414757709251, 47.90767878707782]],
    [ "Enedis",[0.22881899999999922, 47.940862]],
    [ "Enedis",[0.25455908647798753, 47.91033937264151]],
    [ "Bodonnerie",[0.24899183130199262, 47.906303424840395]],
    [ "Enedis",[0.2482062548523207, 47.917187452039386]],
    [ "Enedis",[0.2414944500000012, 47.912657200000005]],
    [ "Enedis",[0.23342022454672243, 47.94227091631798]],
    [ "Enedis",[0.2554260637986532, 47.91867713901083]],
    [ "Enedis",[0.2513849599542333, 47.91246883962624]],
    [ "La Petite Houssière",[0.2353525, 47.9066675]],
    [ "Rhonne",[0.23345323754789268, 47.903792038314165]],
    [ "La Chapellerie",[0.24572464021164034, 47.910572613756614]],
    [ "Enedis",[0.22654824999999998, 47.942567499999996]],
    [ "La Pinède",[0.24968451029093752, 47.914828500063614]],
    [ "Les Porteaux",[0.24598731549019545, 47.90750296980392]],
    [ "Enedis",[0.2510066189653515, 47.919734695076286]],
    [ "Enedis",[0.25313475192421553, 47.91724395263469]],
    [ "Enedis",[0.25983891320037966, 47.91650593561253]],
    [ "Enedis",[0.24268849584637905, 47.91047200215244]],
    [ "Enedis",[0.8668363875009134, 45.95363096591428]],
    [ "Enedis",[0.8581880971497767, 45.95463008804966]],
    [ "Enedis",[0.8621248024700744, 45.9531126997971]],
    [ "Enedis",[0.8538837128015284, 45.92917380085114]],
    [ "Enedis",[0.8606544942089346, 45.95271373683628]],
    [ "Enedis",[3.9991535758718193, 43.66568235155514]],
    [ "Enedis",[4.02570195, 43.6622775]],
    [ "Enedis",[0.27696134148329266, 47.93420849144255]],
    [ "Audi",[0.23194098373983826, 47.9532147804878]],
    [ "Enedis",[0.270145845738661, 47.94628729816314]],
    [ "Le Pressoir",[0.2688965405934628, 47.942593185720746]],
    [ "Pré du Chêne",[0.26308257494407133, 47.942840183445185]],
    [ "Enedis",[0.238954, 47.944814]],
    [ "125446459",[0.7740280068794715, 46.05838053222643]],
    [ "Enedis",[0.7747117257422625, 46.06300315391909]],
    [ "Enedis",[0.7810808984889888, 46.060400302496916]],
    [ "Enedis",[2.8881797379134864, 50.55987763613233]],
    [ "Enedis",[2.905068772663876, 50.553847949790786]],
    [ "Enedis",[2.8899939823889467, 50.5621622547744]],
    [ "Enedis",[2.9108454999999998, 50.55883]],
    [ "Enedis",[2.907027500000001, 50.555284499999985]],
    [ "Enedis",[2.889081500000001, 50.55701700000001]],
    [ "Enedis",[2.9069840000000005, 50.5634045]],
    [ "Enedis",[2.892771970856102, 50.567699197364206]],
    [ "Enedis",[2.8978475, 50.55651600000001]],
    [ "Enedis",[2.900171000000001, 50.565002500000006]],
    [ "Enedis",[2.9088076792792785, 50.560034032432426]],
    [ "Enedis",[2.8992654399910935, 50.565050335782686]],
    [ "Enedis",[2.8949751999303377, 50.56720340473703]],
    [ "Enedis",[2.900561915506299, 50.55760522004357]],
    [ "Poste électrique de Pratclaux",[3.6499616663240633, 44.97343089091968]],
    [ "Poste électrique 63kV de Pratclaux",[3.650141939391774, 44.97240861861353]],
    [ "Poste électrique de Sanssac l'église",[3.8003287650869266, 45.04511030609549]],
    [ "Enedis",[0.7192498531874383, 45.87604266257258]],
    [ "Enedis",[0.7211963345070754, 45.87777564619105]],
    [ "Enedis",[0.7200854534351018, 45.87277994361623]],
    [ "Enedis",[0.7168092934960686, 45.87430160172772]],
    [ "Enedis",[0.7320393086522431, 45.878819153555106]],
    [ "Enedis",[0.7399922594250178, 45.87919594834331]],
    [ "Enedis",[0.7161990293440078, 45.877472097274904]],
    [ "Enedis",[0.7272617270974879, 45.882239641632]],
    [ "Enedis",[0.7102316246377596, 45.87400664686299]],
    [ "Enedis",[0.7206286741125433, 45.86916196297084]],
    [ "Poste électrique de Saint-Pierre",[-4.539234662377603, 48.39132139474721]],
    [ "Enedis",[0.7549023341062404, 45.92422127895992]],
    [ "Enedis",[0.19547822156499078, 49.505413549656154]],
    [ "Enedis",[0.20142400000000077, 49.504824]],
    [ "Enedis",[3.239869669503546, 49.892130008510634]],
    [ "Enedis",[3.2272767139874743, 49.88453016144746]],
    [ "Enedis",[3.2474754887102946, 49.88473732797551]],
    [ "Enedis",[3.242358290463692, 49.88841099037621]],
    [ "Enedis",[0.4081767818257957, 45.989173072361815]],
    [ "Enedis",[0.402571579244316, 45.99173999735722]],
    [ "Enedis",[3.3291822104868904, 49.899545032958805]],
    [ "Enedis",[3.3268195593175856, 49.89608072939632]],
    [ "Enedis",[3.4019743156596784, 49.780039322646935]],
    [ "Enedis",[3.4669003863404693, 49.79738364118248]],
    [ "Enedis",[3.4521281132616473, 49.79481097156511]],
    [ "Enedis",[3.471284646090535, 49.792000218107]],
    [ "Enedis",[3.4758296191709843, 49.80909477115717]],
    [ "Enedis",[3.466657559882006, 49.79840132920353]],
    [ "Enedis",[3.4535263001796506, 49.793045837334645]],
    [ "Enedis",[3.4627622024432805, 49.79741768237348]],
    [ "Enedis",[2.9579783999999996, 50.6217147]],
    [ "Poste électrique de Loscoat",[-4.497017753009086, 48.42531686403905]],
    [ "Enedis",[4.792059828190779, 45.756619489231475]],
    [ "Enedis",[4.793456224478043, 45.75966897149028]],
    [ "Enedis",[4.794167170662206, 45.75971266127734]],
    [ "Enedis",[4.7866505, 45.760205]],
    [ "Enedis",[4.7963555, 45.761953999999996]],
    [ "Enedis",[4.793420311818317, 45.75906007799193]],
    [ "Enedis",[4.794254049044056, 45.75641701496259]],
    [ "Enedis",[4.795088679591223, 45.7613459200481]],
    [ "Enedis",[4.789673660468312, 45.75060177049582]],
    [ "Enedis",[4.792253426549978, 45.753452385776086]],
    [ "Enedis",[3.0304184999999997, 50.562511]],
    [ "Enedis",[3.029039186300302, 50.54718126386047]],
    [ "Enedis",[3.023804184824159, 50.54712666265291]],
    [ "Enedis",[3.0233463447669306, 50.5481259780123]],
    [ "Enedis",[3.0191231688613485, 50.54123549367416]],
    [ "Enedis",[3.02117759589041, 50.543358312609755]],
    [ "Enedis",[3.0243986972334476, 50.548396310330524]],
    [ "Enedis",[3.0348490000000004, 50.55602900000001]],
    [ "Enedis",[3.0140590548340547, 50.54075716883117]],
    [ "Enedis",[3.046586680478481, 50.54380448403009]],
    [ "Enedis",[3.0158422814045496, 50.542472352621175]],
    [ "Enedis",[3.0435102638169256, 50.5480098156304]],
    [ "Enedis",[3.026934, 50.54224999999999]],
    [ "Enedis",[3.0594885, 50.55299549999999]],
    [ "Enedis",[3.021550690140845, 50.540566953051645]],
    [ "Enedis",[3.0405869610279277, 50.56174219528512]],
    [ "Enedis",[3.020694999999999, 50.546130999999995]],
    [ "Enedis",[3.038720894014963, 50.55084768848711]],
    [ "Enedis",[3.050330133963254, 50.55057262309711]],
    [ "Enedis",[3.0420759999999984, 50.5508495]],
    [ "Enedis",[3.032154000000002, 50.554956]],
    [ "Enedis",[3.0344475844259042, 50.552655157727465]],
    [ "Enedis",[3.0434070000000006, 50.54567]],
    [ "Enedis",[3.0565482531737405, 50.549951944504905]],
    [ "Enedis",[3.0333576731085525, 50.54730471580318]],
    [ "Enedis",[3.0386363721881375, 50.547221033791025]],
    [ "Enedis",[3.038847738020286, 50.55935706785589]],
    [ "Enedis",[3.0405040272756114, 50.55315646225817]],
    [ "Enedis",[5.044696457810408, 47.332126395894655]],
    [ "RTE",[0.7125106134272726, 44.835120806358425]],
    [ "Enedis",[-0.4973565999999999, 43.89262754999999]],
    [ "Poste électrique de Coataudon",[-4.42809341830974, 48.42316491342993]],
    [ "Enedis",[4.49496670703253, 46.74777807762534]],
    [ "Enedis",[4.46770911900369, 46.75112875492005]],
    [ "Enedis",[-0.5135226907134712, 43.893920974916945]],
    [ "Enedis",[4.477948663553479, 46.75516746497665]],
    [ "Enedis",[4.463488767676768, 46.742906118457306]],
    [ "RTE",[5.048583831762763, 45.842920378363225]],
    [ "Enedis",[0.4414265981906012, 45.83839594985408]],
    [ "26ie dragons",[5.0488100235027655, 47.33495645090658]],
    [ "RTE",[5.08740958723464, 45.848812157748654]],
    [ "Enedis",[4.799284174353943, 45.756839338894345]],
    [ "Enedis",[5.08034758953168, 47.33762212121212]],
    [ "Enedis",[5.0863736284283565, 47.33583623215484]],
    [ "Enedis",[5.094430500000001, 47.339237999999995]],
    [ "Enedis",[5.091455505894693, 47.32904249255297]],
    [ "Enedis",[5.082962336640942, 47.33648983204704]],
    [ "Enedis",[5.072664267285861, 47.34098014344685]],
    [ "Enedis",[5.082068879509133, 47.346536105821926]],
    [ "Enedis",[5.078646231374638, 47.34509076844784]],
    [ "Enedis",[5.099042793441105, 47.335454838084516]],
    [ "Enedis",[5.088743742265643, 47.33552042149337]],
    [ "Enedis",[5.075669825473818, 47.33958398473008]],
    [ "Enedis",[5.084894601166326, 47.33670234724239]],
    [ "Enedis",[5.076645141623489, 47.34252067587795]],
    [ "Enedis",[5.077955267006184, 47.34054602291742]],
    [ "Enedis",[5.075723, 47.332938000000006]],
    [ "Enedis",[5.078028638104953, 47.3365746726737]],
    [ "Enedis",[5.090324155701754, 47.33353782061403]],
    [ "Enedis",[5.086946113865932, 47.33394737557392]],
    [ "Enedis",[5.078243797512931, 47.332912452624626]],
    [ "Enedis",[5.083651967141969, 47.32794217646156]],
    [ "Enedis",[5.072706904625655, 47.340996645684314]],
    [ "Enedis",[5.083452079691799, 47.346288844395524]],
    [ "Enedis",[5.091412074074074, 47.33869279012346]],
    [ "Longenes",[5.075504999999999, 47.326944000000005]],
    [ "Enedis",[5.084789771395565, 47.33051494712556]],
    [ "Enedis",[5.086010382166489, 47.329236407423906]],
    [ "Enedis",[5.089759508077271, 47.33098500196565]],
    [ "Enedis",[5.080050981921488, 47.33534851704545]],
    [ "Poste électrique de Vénissieux",[4.906080987140933, 45.71639677415112]],
    [ "Enedis",[3.3227184931023417, 47.9569186628168]],
    [ "Enedis",[3.315685106538526, 47.96580404422863]],
    [ "Enedis",[3.055428208333332, 50.66099734374998]],
    [ "Les Ménapiens",[3.0337781314536945, 50.66286493687349]],
    [ "Enedis",[3.049125, 50.66008399999999]],
    [ "Enedis",[3.0450350455545085, 50.65476734432925]],
    [ "Enedis",[3.0390741642905637, 50.65914698234894]],
    [ "Tassigny",[3.0407226866712653, 50.656655514830994]],
    [ "Enedis",[3.054575293055556, 50.655029365277784]],
    [ "Sardine",[3.0309327178709085, 50.6665860507912]],
    [ "Enedis",[3.045745145823041, 50.66218645736492]],
    [ "Enedis",[3.0513725, 50.6606395]],
    [ "Enedis",[3.0618165, 50.6662465]],
    [ "Enedis",[3.0558661635481634, 50.668691995149]],
    [ "Les Chardonnerets",[3.0331289999999997, 50.665462]],
    [ "Enedis",[3.04624940543444, 50.66487165143761]],
    [ "Les Serviens",[3.035862574087163, 50.6648392680801]],
    [ "Enedis",[3.050269797673615, 50.661671731670154]],
    [ "Enedis",[4.829207, 45.76322150000001]],
    [ "Enedis",[1.874336384269663, 50.94550280877475]],
    [ "Enedis",[1.8725692786177104, 50.938971113750895]],
    [ "Enedis",[1.8700852890092268, 50.95269394785399]],
    [ "Enedis",[1.8637793874796025, 50.96156604094349]],
    [ "Enedis",[1.8674090052428245, 50.94594979111479]],
    [ "Enedis",[1.8728916666666664, 50.94170047257383]],
    [ "Enedis",[1.8684674221863216, 50.94834628217555]],
    [ "Enedis",[1.8689783652291176, 50.96135054695903]],
    [ "Enedis",[1.8648666698564593, 50.95714483413078]],
    [ "Enedis",[1.8656062111506526, 50.95612365005932]],
    [ "Enedis",[1.8762940108516157, 50.94928367421562]],
    [ "Enedis",[1.8760356666666667, 50.9395496329114]],
    [ "Enedis",[1.8746685000000005, 50.95675599999999]],
    [ "Enedis",[1.8747042104733125, 50.95454792145015]],
    [ "Enedis",[1.877633593879239, 50.943103830438375]],
    [ "Enedis",[1.8735934084535875, 50.959755067035104]],
    [ "Enedis",[1.8759733603178543, 50.94449788974421]],
    [ "Enedis",[1.8635692117150047, 50.94815805849379]],
    [ "Enedis",[1.869333167325776, 50.955265550048345]],
    [ "Enedis",[1.8673176297468348, 50.94466969883967]],
    [ "Enedis",[0.37554873361227337, 45.64598695397489]],
    [ "Enedis",[0.36871588128154537, 45.64546525499232]],
    [ "Enedis",[3.0312760128325515, 50.65316616651018]],
    [ "Enedis",[3.017348348258707, 50.65921793532338]],
    [ "Enedis",[3.014485783696662, 50.65126564153417]],
    [ "Enedis",[3.0213633713883015, 50.65816510500352]],
    [ "Enedis",[3.0137314459161133, 50.64933578650268]],
    [ "Enedis",[3.029707638149957, 50.65173652488886]],
    [ "125884326",[3.023017180172879, 50.64764502917342]],
    [ "Enedis",[3.023891510633576, 50.6427650618906]],
    [ "Enedis",[3.0134532024675043, 50.646757344789606]],
    [ "SNCF Réseau",[3.023572815233165, 50.65961247836]],
    [ "Enedis",[3.0347638824183436, 50.648244676040655]],
    [ "Enedis",[3.0063714903279055, 50.66129053762218]],
    [ "Enedis",[3.007777780274657, 50.65958034465251]],
    [ "Enedis",[3.0016298623188393, 50.668589047740824]],
    [ "Enedis",[3.0076563577863586, 50.6554086010296]],
    [ "Enedis",[2.8934150000000005, 50.669256499999996]],
    [ "Enedis",[2.8931080000000002, 50.670934]],
    [ "Enedis",[0.22607039184627398, 49.56295574725179]],
    [ "Enedis",[2.9211964218230215, 50.65767320248392]],
    [ "Enedis",[2.8896701972569585, 50.67620833629151]],
    [ "Enedis",[2.8862273287467954, 50.678518992715496]],
    [ "Enedis",[2.8982826322629966, 50.669403079128436]],
    [ "Enedis",[0.22157489999999944, 49.56248579999999]],
    [ "Enedis",[2.887527262214118, 50.58557613524808]],
    [ "Enedis",[0.2349731921224004, 49.559264253921796]],
    [ "Enedis",[0.23343849999999997, 49.56290124999998]],
    [ "Enedis",[2.868984861506708, 50.67048331888544]],
    [ "Enedis",[2.8739559759846296, 50.678861677233435]],
    [ "Enedis",[0.24056298787878766, 49.558664345454545]],
    [ "Enedis",[2.8953502994713243, 50.672603462290034]],
    [ "Enedis",[0.2131500931372551, 49.558089129901965]],
    [ "Enedis",[0.2263591805180345, 49.5607051044194]],
    [ "Enedis",[2.9028204253330223, 50.672199804938856]],
    [ "Enedis",[2.8991033223002343, 50.66386206987629]],
    [ "Enedis",[0.2301179731530313, 49.562915543022854]],
    [ "Enedis",[2.9027000000000003, 50.66843050000001]],
    [ "Enedis",[2.918046, 50.66592400000001]],
    [ "Enedis",[2.8694091168408824, 50.67656886062717]],
    [ "Enedis",[2.9050206350574697, 50.666086742816084]],
    [ "Enedis",[2.8841153355601943, 50.66182682136396]],
    [ "Enedis",[2.8919465171198415, 50.67294655723397]],
    [ "Kerfontaine",[-3.554796303969138, 47.87949961625681]],
    [ "Enedis",[0.6539474021051085, 45.91422565044067]],
    [ "Poste électrique de Chabanais",[0.6941458306849959, 45.876073430497364]],
    [ "Enedis",[5.069175753846154, 47.331140861538465]],
    [ "Enedis",[5.058154500000001, 47.32923099999999]],
    [ "Enedis",[5.0610505, 47.33027049999999]],
    [ "Enedis",[5.060704254936292, 47.33388354975197]],
    [ "Enedis",[5.059438784602785, 47.33256057739558]],
    [ "Enedis",[5.062781251461989, 47.33292194152046]],
    [ "Enedis",[5.061273958847736, 47.33510283950617]],
    [ "Enedis",[5.0608231141552515, 47.332416629599784]],
    [ "Enedis",[5.06203637851153, 47.32933844213836]],
    [ "Enedis",[5.0638004074176175, 47.32553704258168]],
    [ "Enedis",[5.058183770605074, 47.32483644033166]],
    [ "Enedis",[5.057877419827012, 47.32340812175649]],
    [ "Enedis",[1.5723123288888887, 43.55475619111112]],
    [ "Enedis",[1.56200585, 43.5564839]],
    [ "Enedis",[1.5579906710054714, 43.55560870854555]],
    [ "Enedis",[1.5699100357894742, 43.554909178245616]],
    [ "Enedis",[1.5613554844967816, 43.55474988284886]],
    [ "Enedis",[0.671886069301348, 46.00599200917837]],
    [ "Enedis",[0.6657257693892294, 46.02022344977745]],
    [ "Enedis",[0.6665203195997408, 46.02260546777187]],
    [ "Enedis",[0.6731256964488324, 46.01018184786864]],
    [ "Enedis",[0.675499890446053, 46.0210450992054]],
    [ "Enedis",[0.6716423074469848, 45.999912022511474]],
    [ "Enedis",[0.668142146181033, 46.01437010678982]],
    [ "Enedis",[0.6758555297144329, 46.01761975390778]],
    [ "Enedis",[0.6580556473774383, 46.017162210710616]],
    [ "Enedis",[5.0555475, 47.3129895]],
    [ "Enedis",[0.6717120058144436, 46.001575699185416]],
    [ "Enedis",[0.675953549791984, 46.01027676130224]],
    [ "Enedis",[0.6738303545703402, 46.006406632208616]],
    [ "Enedis",[5.063008687004481, 47.314909730782475]],
    [ "Enedis",[0.6592780919618748, 46.01925137957639]],
    [ "Enedis",[0.6717903321746955, 46.01839011099599]],
    [ "Enedis",[0.6608844820035213, 46.01888860410527]],
    [ "Enedis",[0.6704022578557455, 46.025172304647]],
    [ "Enedis",[5.062728696447704, 47.315692963286004]],
    [ "Enedis",[0.6561465144838441, 46.02005003392166]],
    [ "Enedis",[0.6733140025082514, 46.013960000421044]],
    [ "Enedis",[0.6609354999999991, 46.0178965]],
    [ "Enedis",[5.058403012147716, 47.31156949220433]],
    [ "Enedis",[0.6704220897361839, 46.020087106331204]],
    [ "Enedis",[0.6704177565076649, 46.01228509568147]],
    [ "Enedis",[0.6724503721048549, 46.00623835905418]],
    [ "Enedis",[0.6737894885672079, 46.01313797746639]],
    [ "Enedis",[5.057864273054427, 47.313363809716584]],
    [ "Enedis",[5.057526275551598, 47.31790666359948]],
    [ "Enedis",[5.060076246876966, 47.31990155819178]],
    [ "Enedis",[5.054850840064621, 47.31594427032849]],
    [ "Enedis",[5.059946180734384, 47.31311868144968]],
    [ "Enedis",[5.054110156770832, 47.314203068750004]],
    [ "Enedis",[5.05635853359426, 47.31144990662566]],
    [ "Église",[0.2820787499999998, 47.98751175000001]],
    [ "Enedis",[0.27547284639016895, 47.98116755657962]],
    [ "Enedis",[0.27088575, 47.97695475000001]],
    [ "Enedis",[0.28878772829212085, 47.993777004219275]],
    [ "Gymnase",[0.28236599999999995, 47.9893385]],
    [ "Enedis",[0.28556487297146066, 47.987969047938826]],
    [ "Enedis",[0.24601782311869677, 47.973388167571756]],
    [ "Bourg",[0.27951576464489375, 47.98620747278383]],
    [ "Tannerie",[0.2827807500000001, 47.985507500000004]],
    [ "Enedis",[0.26971283238815297, 47.975484696597356]],
    [ "La Perrière",[0.271723037422552, 47.988361742998755]],
    [ "Enedis",[0.27700718573704425, 47.98361425161654]],
    [ "Enedis",[0.27385603074772874, 47.97310368343816]],
    [ "Les Arboras",[4.782223750000001, 45.597512949999995]],
    [ "Enedis",[-0.5150167499999999, 47.457181750000004]],
    [ "Enedis",[-0.4808974180340293, 47.45388060114646]],
    [ "Enedis",[-0.5082315000000015, 47.457094]],
    [ "Camus 1",[-0.5080081999999988, 47.45203900000001]],
    [ "Enedis",[-0.4800662, 47.4537898]],
    [ "Enedis",[-0.4825463, 47.4576432]],
    [ "Enedis",[-0.47620255000000006, 47.452603599999996]],
    [ "Enedis",[-0.4831651, 47.45519379999999]],
    [ "Enedis",[-0.473780375397186, 47.450295337418666]],
    [ "Perreyeux",[-0.5111271143008733, 47.45682146705599]],
    [ "Enedis",[3.187894353356963, 46.99445943579941]],
    [ "Enedis",[3.1294493222921838, 46.992128636634234]],
    [ "Enedis",[3.1903348234895925, 47.0018136491252]],
    [ "Enedis",[3.1632482083937554, 46.99603960329882]],
    [ "Enedis",[3.152539079273156, 46.9899744498376]],
    [ "Enedis",[3.145278389333736, 47.001407828626284]],
    [ "Enedis",[3.1243086798707544, 46.98988805947116]],
    [ "Enedis",[3.1887360558389823, 46.99934021702389]],
    [ "Enedis",[3.1485535435110603, 47.00425560164919]],
    [ "Enedis",[3.1872934614643627, 46.99916225701164]],
    [ "Enedis",[3.153414167434802, 46.99491284842096]],
    [ "Enedis",[3.1899901726293556, 47.000631370511385]],
    [ "Enedis",[3.1575519840894306, 47.000899982664535]],
    [ "Enedis",[3.1766741450442733, 46.99509628997429]],
    [ "Enedis",[3.180997081896465, 46.99277556416571]],
    [ "Enedis",[3.118992970464134, 46.98802067535866]],
    [ "Enedis",[3.1642685063227733, 46.997424639338554]],
    [ "Enedis",[3.154159220598194, 47.005601089868506]],
    [ "Enedis",[3.1527146624994713, 47.0002202135384]],
    [ "Enedis",[3.1654997006342023, 47.00365900137788]],
    [ "Enedis",[3.161606710461519, 46.98813959999321]],
    [ "Enedis",[3.1233294436588266, 46.98808345001732]],
    [ "Enedis",[3.155795210785034, 47.00420865529426]],
    [ "Enedis",[3.133628814441358, 46.99101217232581]],
    [ "Enedis",[3.157872343400793, 46.99229309839901]],
    [ "Enedis",[3.163308546087446, 46.99854391678493]],
    [ "Enedis",[3.1856301400875666, 46.992313725149245]],
    [ "Enedis",[3.1395620223561256, 46.99124562151844]],
    [ "Enedis",[3.154866553293848, 46.99963286403978]],
    [ "Enedis",[3.1723352207393383, 46.98988240215139]],
    [ "Enedis",[5.055838166984531, 47.30775449502013]],
    [ "Enedis",[5.0483482154882156, 47.31061644107746]],
    [ "Enedis",[5.052269700000001, 47.310949]],
    [ "Enedis",[5.024435999999999, 47.31377899999999]],
    [ "Enedis",[5.020132722222222, 47.31576806432748]],
    [ "Enedis",[5.01801743987539, 47.3155146093458]],
    [ "Enedis",[5.018426164370468, 47.3181279485423]],
    [ "Enedis",[5.058596772344732, 47.30824247022478]],
    [ "Enedis",[0.26375500000000063, 49.5036885]],
    [ "Enedis",[0.2546685, 49.47691075]],
    [ "Enedis",[0.2782305983739823, 49.50909724471544]],
    [ "Enedis",[0.24692, 49.46130775]],
    [ "Enedis",[0.26895845, 49.496796950000004]],
    [ "Enedis",[0.2536627499999994, 49.464086]],
    [ "Enedis",[0.24653074999999855, 49.464090000000006]],
    [ "Enedis",[0.265923, 49.4960205]],
    [ "Enedis",[0.2639951184417214, 49.49599275777569]],
    [ "Enedis",[5.05172074457967, 47.36673327888212]],
    [ "Enedis",[5.051647339204545, 47.33933171761363]],
    [ "Enedis",[5.042264659388645, 47.355366676855894]],
    [ "Enedis",[5.053746515851699, 47.34352708893372]],
    [ "Renan",[5.047255738688827, 47.344781388734994]],
    [ "Enedis",[5.045756567775479, 47.345472737338845]],
    [ "Enedis",[5.05088550737965, 47.344326898659126]],
    [ "Enedis",[5.0456567269538795, 47.34161445020713]],
    [ "Enedis",[5.049253708366749, 47.362458519112536]],
    [ "Enedis",[5.05300771948998, 47.3458918306011]],
    [ "Enedis",[5.043842833635991, 47.35671632319593]],
    [ "Enedis",[5.043226276470212, 47.3506908356503]],
    [ "Enedis",[5.044673809987508, 47.354971161752005]],
    [ "Enedis",[5.062985270841336, 47.34960759969267]],
    [ "Enedis",[5.0548564956316495, 47.34250399230607]],
    [ "Enedis",[5.04969476633143, 47.36735737292744]],
    [ "Dumouriez",[5.0496098816982915, 47.364761414790664]],
    [ "Enedis",[5.063231500000001, 47.353117000000005]],
    [ "Enedis",[5.041119584988963, 47.351215995584994]],
    [ "Enedis",[5.04851643312102, 47.36587780113234]],
    [ "Enedis",[5.043305982502391, 47.35802265813453]],
    [ "Herbues",[5.049245930348259, 47.36408175124377]],
    [ "Enedis",[5.05140110779172, 47.36540802346694]],
    [ "Enedis",[5.059225298229103, 47.34835730806557]],
    [ "Enedis",[5.0422884606426495, 47.34427092208698]],
    [ "Enedis",[5.037072608464388, 47.353015112285924]],
    [ "Enedis",[5.044350849630046, 47.349033551555]],
    [ "Enedis",[5.0582031824428535, 47.34506474842238]],
    [ "Enedis",[5.039857906810035, 47.3523784432497]],
    [ "Enedis",[5.041772573468403, 47.353543326579825]],
    [ "Aragon",[5.0446090522235805, 47.35133172315634]],
    [ "Vigier",[5.043620020369191, 47.34825367472948]],
    [ "Enedis",[6.6546736501943355, 46.22974585970757]],
    [ "La Villaz",[6.673263691291695, 46.25285335751064]],
    [ "Enedis",[6.6741782382984765, 46.20817852366549]],
    [ "Enedis",[5.080870944444445, 47.349672321428564]],
    [ "Enedis",[5.073625646791596, 47.348419434050896]],
    [ "Enedis",[5.065538990029856, 47.35017374202627]],
    [ "Enedis",[5.068944621991428, 47.34930949010881]],
    [ "Enedis",[5.075781676085309, 47.351500162606904]],
    [ "Enedis",[5.072460469900953, 47.344925275742845]],
    [ "Enedis",[5.070558480340699, 47.344345605985175]],
    [ "Enedis",[2.352357881768127, 48.75869511488356]],
    [ "Enedis",[2.362162544105379, 48.754011523428616]],
    [ "Enedis",[0.7201081976460846, 46.03173343018688]],
    [ "Enedis",[4.7883043500000015, 45.64636124999999]],
    [ "Bas Privas",[4.781446565821693, 45.64893687235792]],
    [ "Enedis",[0.8054705142034316, 45.88662107406583]],
    [ "Enedis",[2.340347099901321, 48.74954968167831]],
    [ "Enedis",[2.343759514622984, 48.75292556046707]],
    [ "Enedis",[2.344233133757901, 48.75423330033219]],
    [ "Enedis",[2.3477837684147076, 48.75184362613019]],
    [ "Enedis",[2.340971978552746, 48.753022138610675]],
    [ "Enedis",[2.3421128462606253, 48.749987570861805]],
    [ "Enedis",[2.3498577381441312, 48.751977069461184]],
    [ "Enedis",[2.3475407308569896, 48.752759454976186]],
    [ "Pascal",[2.059578278911564, 48.795935843537414]],
    [ "Enedis",[2.0726031666666667, 48.80270633333333]],
    [ "Enedis",[2.057760327180229, 48.80552151933133]],
    [ "Enedis",[2.071810197132616, 48.79905177419355]],
    [ "Jean Jaures",[2.0709129291590798, 48.80032625308336]],
    [ "Enedis",[3.1271248565423666, 46.98612883370647]],
    [ "Enedis",[3.134872802259075, 46.97932729838185]],
    [ "Enedis",[3.181146135305385, 46.98631058515968]],
    [ "Enedis",[3.1971562767901234, 46.986421172504976]],
    [ "Enedis",[3.1305597535697167, 46.982391777272]],
    [ "Enedis",[3.123662723874289, 46.98583042438449]],
    [ "Enedis",[3.1354131250243773, 46.97863292327845]],
    [ "Enedis",[3.167133368749481, 46.99284560969931]],
    [ "Enedis",[3.1382356533345033, 46.99548817282019]],
    [ "Enedis",[3.1233388492741256, 46.992575349549575]],
    [ "Enedis",[3.151067716275238, 46.98870418046971]],
    [ "Enedis",[3.129289033484342, 46.9763736510399]],
    [ "Enedis",[3.127243060277461, 46.981545805497845]],
    [ "Enedis",[3.1465849484640573, 46.98949415228933]],
    [ "Enedis",[3.155653478658312, 46.98006717869934]],
    [ "Enedis",[3.1277884303882293, 46.97531427743658]],
    [ "Enedis",[3.129239927747266, 46.99020832079208]],
    [ "Enedis",[3.1295234792185087, 46.979645501090644]],
    [ "Enedis",[3.1268595953900746, 46.977390862518334]],
    [ "Enedis",[3.1403071903435866, 46.9856212438867]],
    [ "Enedis",[3.1279704030965396, 46.98387334772314]],
    [ "Enedis",[3.138393653008027, 46.99385786298702]],
    [ "Enedis",[3.189437045653244, 46.984752613225155]],
    [ "Enedis",[3.1480187608729326, 46.9978051806989]],
    [ "Enedis",[3.1409331130508233, 46.982569240832525]],
    [ "Enedis",[3.162799670640665, 47.00671481340612]],
    [ "Enedis",[0.6779932056036682, 45.885408609118706]],
    [ "Enedis",[0.6522532940226546, 45.89106023843096]],
    [ "Enedis",[0.14482849877213014, 46.02697246088339]],
    [ "Enedis",[0.08125378846153848, 46.08059194871795]],
    [ "Frontigny",[4.7829675, 45.6406113]],
    [ "Enedis",[4.79648977774661, 45.638639922767645]],
    [ "Enedis",[1.4168577995305167, 43.590607255399064]],
    [ "Enedis",[1.412926210816778, 43.59406005298013]],
    [ "Enedis",[4.814221068965518, 46.76815716091954]],
    [ "Enedis",[4.83450408, 46.770153086666674]],
    [ "Enedis",[4.843973709081528, 46.77231485531476]],
    [ "Enedis",[4.817741584721751, 46.76614168230761]],
    [ "Enedis",[4.820748078364949, 46.774345338979224]],
    [ "Enedis",[4.828994206060605, 46.77508615515152]],
    [ "Enedis",[4.833944829931973, 46.76149400680273]],
    [ "Enedis",[4.827022535344573, 46.76330792043773]],
    [ "Enedis",[4.8404035, 46.7612245]],
    [ "Enedis",[4.829910999999999, 46.761573999999996]],
    [ "Enedis",[4.827151, 46.76770599999999]],
    [ "Enedis",[4.830792834205933, 46.77026948691099]],
    [ "Enedis",[4.827484801556421, 46.765711835278864]],
    [ "Enedis",[4.843975934800616, 46.76321431937049]],
    [ "Enedis",[4.83295382380802, 46.7741304293548]],
    [ "Enedis",[4.82054064516129, 46.764301698924726]],
    [ "Enedis",[4.812897488957371, 46.77143790446843]],
    [ "Enedis",[4.8241816114631595, 46.77617542665863]],
    [ "Enedis",[4.829528527131783, 46.76530833333334]],
    [ "Enedis",[4.822276318085557, 46.77216596272766]],
    [ "Enedis",[4.824366367716459, 46.76974624877204]],
    [ "Enedis",[4.818621478342751, 46.768640063841815]],
    [ "Enedis",[4.841688483839373, 46.76494746896133]],
    [ "Enedis",[4.814930435105362, 46.77215152849616]],
    [ "Enedis",[4.838387453739216, 46.76274449161074]],
    [ "Enedis",[4.825739018657434, 46.77373151413733]],
    [ "Enedis",[0.7789045496666597, 46.010259024892555]],
    [ "Enedis",[0.7792811522103577, 46.00751600204222]],
    [ "Enedis",[5.037814665502778, 47.30606050976458]],
    [ "Enedis",[5.039039497255355, 47.30075764746557]],
    [ "Enedis",[5.057338064367816, 47.306601841379305]],
    [ "Enedis",[5.054606161290322, 47.30580100637196]],
    [ "Enedis",[5.059078934808715, 47.3063671658089]],
    [ "Enedis",[5.059388301726661, 47.3037540763828]],
    [ "Enedis",[5.03545312077135, 47.308072862369144]],
    [ "Enedis",[5.036391187721733, 47.30038569718975]],
    [ "Enedis",[1.8941472111754352, 47.92758782479654]],
    [ "Enedis",[1.881913320927196, 47.90569954652302]],
    [ "Enedis",[1.8937489890781658, 47.91811599775139]],
    [ "Enedis",[1.8900753040621263, 47.924383972122655]],
    [ "Enedis",[1.8917337508432317, 47.906433341545686]],
    [ "Enedis",[1.8972702957026188, 47.9096125542325]],
    [ "Enedis",[1.8945016444226366, 47.90572281125287]],
    [ "Enedis",[1.89062155, 47.92263264999999]],
    [ "Enedis",[1.8858905300653592, 47.900673634871204]],
    [ "Enedis",[1.8894357295537099, 47.92133339454558]],
    [ "Enedis",[1.8932435748927436, 47.898698653609394]],
    [ "Enedis",[1.9018939999999998, 47.919403550000006]],
    [ "Enedis",[1.899062591123701, 47.91895476487252]],
    [ "Enedis",[1.8990387110471096, 47.91521094444739]],
    [ "Enedis",[1.8970393213646302, 47.92105630742476]],
    [ "Enedis",[1.889040571948162, 47.8991935945858]],
    [ "Enedis",[1.8813155499999998, 47.90251035]],
    [ "Enedis",[1.8954157890780587, 47.91833052138101]],
    [ "Enedis",[1.8862666679801101, 47.90784349779529]],
    [ "Enedis",[1.8930205067942587, 47.91981716121212]],
    [ "Enedis",[1.8919584451265483, 47.90467106736673]],
    [ "Enedis",[1.8897393150221846, 47.91359467218621]],
    [ "Enedis",[1.8917625195996002, 47.9193658198999]],
    [ "Enedis",[1.8893609999999998, 47.900306699999994]],
    [ "Enedis",[1.8796602, 47.90138799999999]],
    [ "Enedis",[1.8909170697230182, 47.925464256160474]],
    [ "Enedis",[1.8902252938552013, 47.91200625024695]],
    [ "Enedis",[1.8961157293074962, 47.926509594161544]],
    [ "Enedis",[1.8851321074766358, 47.898125799065426]],
    [ "Enedis",[1.8902355147985181, 47.91203967201726]],
    [ "Enedis",[1.8964312417325149, 47.90889590643422]],
    [ "Sargé - Les Sablons",[0.23658449999999998, 48.03277249999999]],
    [ "Enedis",[0.23729779360910488, 48.03770795184943]],
    [ "Enedis",[0.23743663097199325, 48.036088301796504]],
    [ "Enedis",[0.24464899999999998, 48.036599]],
    [ "La Pointe",[0.2369805, 48.024195999999996]],
    [ "Enedis",[0.23348449999999962, 48.0264437]],
    [ "Bourg",[0.2408937573333335, 48.03437965333334]],
    [ "Chemin Vert",[0.2348949999999992, 48.03483349999999]],
    [ "Enedis",[0.24197867261904782, 48.04020533928572]],
    [ "Enedis",[0.2426440413145535, 48.03339262347417]],
    [ "Le Bignon",[0.24369212748065908, 48.030755176925666]],
    [ "Enedis",[0.5879000499999993, 45.88845754999999]],
    [ "Enedis",[0.5863367661315704, 45.89059864683664]],
    [ "Enedis",[0.5752341397152481, 45.89224155140042]],
    [ "Enedis",[0.5905003973336168, 45.88973717546455]],
    [ "Enedis",[4.739201250000001, 45.78325800000001]],
    [ "Enedis",[4.747717249999999, 45.7734025]],
    [ "Enedis",[4.7343438710455565, 45.78584416833563]],
    [ "Enedis",[1.4933079709418196, 49.089245570363225]],
    [ "Enedis",[1.4959282499999995, 49.08851324999999]],
    [ "Stade",[1.4922021528510427, 49.09762044599348]],
    [ "PARC",[1.4792705032123488, 49.08878483370881]],
    [ "Bully",[1.4793230000000008, 49.093001]],
    [ "Enedis",[1.5005043378076055, 49.08397201208053]],
    [ "La Ravine",[1.4971736500000001, 49.098605400000004]],
    [ "Les Prés",[1.4882393354448278, 49.1001432327213]],
    [ "Enedis",[4.867525751902611, 46.778496042808335]],
    [ "Enedis",[4.870353350701555, 46.760738646143345]],
    [ "Enedis",[4.81716230279232, 46.798265069808025]],
    [ "Rue de la Guerlande",[4.8286355, 46.795907]],
    [ "Enedis",[4.817118851830825, 46.79213942725896]],
    [ "Enedis",[4.813986500000001, 46.8026055]],
    [ "Enedis",[4.806767725093633, 46.80192905518101]],
    [ "Enedis",[4.804917950000001, 46.778311550000005]],
    [ "Enedis",[4.812298733918129, 46.800717663742695]],
    [ "Enedis",[4.812427465313028, 46.786891906514384]],
    [ "Enedis",[4.806974274905054, 46.795728030382705]],
    [ "Enedis",[4.8120022, 46.792751]],
    [ "Enedis",[4.810044582422294, 46.78639596698821]],
    [ "Châtenoy - Commerce",[4.8161411, 46.79429700000001]],
    [ "Enedis",[4.802568352580372, 46.80072743813452]],
    [ "Enedis",[4.7979297212336895, 46.7818526544484]],
    [ "Enedis",[4.8130243561899375, 46.7898813522329]],
    [ "Enedis",[4.820669658569502, 46.79685833198381]],
    [ "Enedis",[4.805351449999999, 46.80435929999999]],
    [ "Enedis",[4.8120235000000005, 46.79736749999999]],
    [ "Enedis",[4.828622624251497, 46.795961532934136]],
    [ "Enedis",[4.81019047073407, 46.80039326779004]],
    [ "Enedis",[4.81795191147276, 46.801537591412746]],
    [ "Enedis",[4.826782369075493, 46.792813047059624]],
    [ "Enedis",[2.9124668999999996, 50.60739545]],
    [ "Enedis",[2.9237403499999988, 50.60053689999999]],
    [ "Enedis",[5.062341168667811, 47.306541986277864]],
    [ "Enedis",[0.6384980995352089, 45.94563893381946]],
    [ "Enedis",[0.6172324434398018, 45.95034230308845]],
    [ "Enedis",[0.6368633033860622, 45.94206460037622]],
    [ "Enedis",[4.9817585, 47.304717999999994]],
    [ "Enedis",[5.0122817346386945, 47.31631534414918]],
    [ "Enedis",[4.99648, 47.31845249999999]],
    [ "Enedis",[5.000835744602777, 47.31440260923906]],
    [ "Enedis",[5.0106208, 47.31030744444445]],
    [ "Enedis",[4.987901057343792, 47.315954649355334]],
    [ "Enedis",[4.990052387456578, 47.32012221690141]],
    [ "126411988",[5.0172343314741035, 47.30422551580345]],
    [ "Enedis",[5.007059445284921, 47.31538391477558]],
    [ "Enedis",[5.001448323420743, 47.31680447571416]],
    [ "126412935",[5.012215511863087, 47.305347832749895]],
    [ "La Cras",[4.9962370955296524, 47.32420837752444]],
    [ "Enedis",[4.993815672758091, 47.31664533761482]],
    [ "Enedis",[5.012584553904961, 47.303853174789346]],
    [ "Enedis",[5.006627477507373, 47.3099271191003]],
    [ "Enedis",[5.009476015952597, 47.31268344427225]],
    [ "126414263",[5.012149571351642, 47.308302411416264]],
    [ "Enedis",[4.991314806912309, 47.313964756355325]],
    [ "Poste électrique de Lutterbach",[7.25744951807288, 47.75279304170139]],
    [ "RTE",[7.300174322012696, 47.74867635946564]],
    [ "Enedis",[-1.6112331742537893, 46.69579934471339]],
    [ "Enedis",[-1.6194439999999999, 46.6647605]],
    [ "Le Haut du Bourg",[-1.3984450186545467, 48.798981433931075]],
    [ "La Coque",[-1.3913092638281226, 48.7977564398792]],
    [ "Enedis",[2.894794221508886, 50.638781827167215]],
    [ "Enedis",[0.97788735, 48.13792564999999]],
    [ "Enedis",[4.851969435867216, 46.74999673639756]],
    [ "Enedis",[4.847839508107583, 46.75306569200894]],
    [ "Enedis",[4.854087533287577, 46.75352758796615]],
    [ "Enedis",[4.845054639103271, 46.75661985667035]],
    [ "Enedis",[4.840312794580971, 46.816639533081286]],
    [ "Enedis",[4.8429750697167755, 46.75422230392156]],
    [ "Enedis",[4.838797805194805, 46.818482069264064]],
    [ "Enedis",[4.844562102674763, 46.81377988294944]],
    [ "Enedis",[4.834571866941016, 46.8261068696845]],
    [ "Enedis",[4.836690274977897, 46.804642977453575]],
    [ "Enedis",[2.8728583, 50.556705300000004]],
    [ "Enedis",[2.871362302206023, 50.557541905383964]],
    [ "Enedis",[2.8720350000000003, 50.5540466]],
    [ "Enedis",[2.8386249416157496, 50.534013282416836]],
    [ "Enedis",[2.8422943907306615, 50.53177919346858]],
    [ "Enedis",[2.8366884999999997, 50.530487]],
    [ "Enedis",[2.8354883424657533, 50.53479334246575]],
    [ "Enedis",[2.8449911336302898, 50.53075330363772]],
    [ "Bélon",[-3.7065275, 47.815954]],
    [ "Rosbraz",[-3.7416595500000005, 47.82040505]],
    [ "Enedis",[0.5513981708915326, 45.779224831695124]],
    [ "Enedis",[0.8976753649215564, 45.98413263947118]],
    [ "Enedis",[0.9043276775648388, 45.98630988436401]],
    [ "Enedis",[0.31560717438271585, 46.00568943981482]],
    [ "Enedis",[6.705501000000002, 46.071471]],
    [ "Enedis",[6.696936426624068, 46.08686976826411]],
    [ "Enedis",[6.692055675224647, 46.07859281942662]],
    [ "Enedis",[2.811437207238761, 50.53598502276707]],
    [ "Enedis",[0.5314853000000012, 45.8626588]],
    [ "Enedis",[3.019554, 50.672090499999996]],
    [ "Enedis",[3.0138796630236793, 50.698345987613855]],
    [ "Enedis",[2.9941735000000005, 50.687115000000006]],
    [ "Enedis",[3.000118846368715, 50.68117080540037]],
    [ "Enedis",[2.996631845557543, 50.6799911098688]],
    [ "Duhamel",[-0.5646148778135115, 44.83167413557275]],
    [ "Enedis",[4.9894674000000006, 45.82544030000001]],
    [ "HLM",[4.97851685, 45.831379299999995]],
    [ "La Poste",[4.9766496, 45.82921234999999]],
    [ "Enedis",[4.988304600000001, 45.824372350000004]],
    [ "Enedis",[4.9800431, 45.8305292]],
    [ "Enedis",[4.76724095, 46.794034]],
    [ "Enedis",[4.758648314285714, 46.798724666666665]],
    [ "Enedis",[4.75717360331825, 46.79717576168929]],
    [ "Enedis",[4.769600965517241, 46.805493655172405]],
    [ "Enedis",[4.769779395790097, 46.80079028214251]],
    [ "Enedis",[4.76709478095238, 46.802270676190474]],
    [ "Enedis",[4.994801300000001, 45.828838399999995]],
    [ "Pâtissière",[4.993818567662959, 45.840621150678516]],
    [ "Enedis",[4.99536145, 45.828039649999994]],
    [ "Pechu",[5.006790099999999, 45.8389577]],
    [ "Enedis",[4.9987664999999994, 45.8357805]],
    [ "Enedis",[4.864127349756691, 46.64328267579074]],
    [ "Enedis",[4.843579026943557, 46.736848022364214]],
    [ "Enedis",[4.838479281447239, 46.74023633432918]],
    [ "Enedis",[4.842795157199682, 46.738915444550514]],
    [ "RTE",[4.781162243833309, 43.94420416498931]],
    [ "Enedis",[3.7709651441981222, 46.62055970481326]],
    [ "Enedis",[3.763401175746924, 46.61668772349151]],
    [ "Enedis",[3.7764921499999993, 46.62073129999999]],
    [ "Enedis",[3.753911000000001, 46.62501249999999]],
    [ "126766919",[3.7691905, 46.617352]],
    [ "Enedis",[3.76922340672998, 46.622350242584126]],
    [ "Église",[3.775055, 46.619940500000006]],
    [ "Enedis",[3.7831514818481846, 46.62671919801979]],
    [ "Enedis",[3.7413739346049035, 46.62209470663033]],
    [ "Enedis",[3.7570497573696144, 46.60705415192743]],
    [ "Enedis",[3.779081960276339, 46.625360215889465]],
    [ "Enedis",[2.9791588398159767, 50.66850660644082]],
    [ "Enedis",[2.9814710000000013, 50.66813849999999]],
    [ "Enedis",[2.9689662880794705, 50.67260433664459]],
    [ "Enedis",[2.9741905302245253, 50.67402887219343]],
    [ "Enedis",[2.965492437485502, 50.66366316283925]],
    [ "Enedis",[2.9755510000000007, 50.6698395]],
    [ "Enedis",[2.9733843132530122, 50.659695510040166]],
    [ "Enedis",[2.9786859992800574, 50.660506655147586]],
    [ "Stade",[2.966792494569152, 50.667493335022925]],
    [ "Enedis",[2.9527916463043766, 50.67523747018379]],
    [ "Enedis",[5.023111517970687, 47.365932898661974]],
    [ "Enedis",[5.036883008762301, 47.35560849708119]],
    [ "Enedis",[5.036373007041888, 47.357386496412985]],
    [ "Enedis",[5.024235359263664, 47.36713542586711]],
    [ "Enedis",[-3.954004453802862, 48.57786552538993]],
    [ "Lilas",[2.964772, 50.665138999999996]],
    [ "Enedis",[2.9483386534216334, 50.657302519205295]],
    [ "Le Cerf",[2.9611704999999997, 50.66732450000001]],
    [ "Enedis",[2.9311052521031207, 50.66032427118951]],
    [ "Enedis",[-0.5890055000000001, 47.471157500000004]],
    [ "G Lekeu 1 FPA",[-0.5236598969276507, 47.471367763296996]],
    [ "Toublanc",[-0.54391875, 47.47976575]],
    [ "Enedis",[-0.5243770000000001, 47.460082]],
    [ "Enedis",[-0.5506653209412942, 47.455868967330076]],
    [ "La Rochefoucauld",[-0.5572416, 47.4760907]],
    [ "Du Clon 11",[-0.5513998974760665, 47.46089521462141]],
    [ "Enedis",[-0.5418875416836766, 47.447816884798094]],
    [ "Enedis",[-0.5991516499999999, 47.4787728]],
    [ "Enedis",[-0.521116224673335, 47.474818072289814]],
    [ "Enedis",[-0.5487641999999999, 47.48289475]],
    [ "Enedis",[-0.544675, 47.469508749999996]],
    [ "Enedis",[-0.6071044, 47.47628135000001]],
    [ "Enedis",[-0.5307549999999995, 47.461321250000005]],
    [ "Enedis",[-0.5399766660133234, 47.48642394869702]],
    [ "Enedis",[-0.5584819999999998, 47.476412249999996]],
    [ "Enedis",[-0.5565699975071975, 47.45866125498016]],
    [ "Cul d’anon",[-0.5150041716371334, 47.47743915474355]],
    [ "Arago 2",[-0.5567346768072112, 47.47821763402403]],
    [ "Champ bataille 8",[-0.5652338333333335, 47.482032000000004]],
    [ "Enedis",[-0.5446974853408685, 47.480890652184165]],
    [ "Nazareth 18 DDSIS",[-0.5767986, 47.489098250000005]],
    [ "Enedis",[-0.5245506526380807, 47.4526259443268]],
    [ "Enedis",[-0.5444677499999999, 47.48800974999999]],
    [ "Nazareth 120",[-0.5774583499999998, 47.4833884]],
    [ "Enedis",[7.162540333333334, 49.09254000000001]],
    [ "RTE",[5.485959203082559, 48.54800855218543]],
    [ "RTE",[-0.37402885995780655, 48.5848729480877]],
    [ "Enedis",[3.211860792974589, 46.02909467638267]],
    [ "Enedis",[3.20715554618474, 46.031467769745646]],
    [ "Enedis",[3.2060217966058757, 46.01499095187436]],
    [ "Enedis",[3.2051192914883506, 46.02400166096053]],
    [ "Enedis",[3.2083695, 46.03005000000001]],
    [ "Enedis",[3.196544, 46.010703500000005]],
    [ "Enedis",[3.1992203790677225, 46.01548916886544]],
    [ "Enedis",[3.201511722546162, 46.02321233576287]],
    [ "Enedis",[3.2054692296960643, 46.02152792874937]],
    [ "Enedis",[3.2048958469945354, 46.028587077868856]],
    [ "Enedis",[3.144221930348259, 46.00255028109453]],
    [ "Enedis",[3.144516677401129, 46.003957336158194]],
    [ "Enedis",[3.138840554421769, 46.00414668239796]],
    [ "Enedis",[2.9164550000000005, 50.690464500000004]],
    [ "Enedis",[2.9431874999999996, 50.6754335]],
    [ "Enedis",[2.898943792178851, 50.68715592473582]],
    [ "Enedis",[2.9137583379586802, 50.690261959913656]],
    [ "Enedis",[2.9047152631335336, 50.68692744465015]],
    [ "Enedis",[2.9185388014829794, 50.697115659925856]],
    [ "Enedis",[2.9218570000000015, 50.695558000000005]],
    [ "Enedis",[2.928461, 50.698288999999995]],
    [ "Enedis",[2.9327191629997635, 50.68773400473149]],
    [ "Enedis",[2.9002731655830085, 50.688049011703505]],
    [ "Enedis",[2.90894736115993, 50.68843781693029]],
    [ "Enedis",[2.905410698056186, 50.68929791867573]],
    [ "Enedis",[5.0661153, 43.81358566666667]],
    [ "RTE",[-0.6267762002926208, 48.59376183465394]],
    [ "Enedis",[3.7787969500000003, 46.62292025]],
    [ "L'Église",[4.831680071902393, 45.90876101359867]],
    [ "Les Grives",[4.830538988009592, 45.911991247002405]],
    [ "Grand Champ",[4.83408, 45.9101105]],
    [ "Pharmacie",[4.81654915, 45.908735500000006]],
    [ "ZA Massieux",[4.817900749999999, 45.90311049999998]],
    [ "Le Saule",[4.822183770542637, 45.90652256666667]],
    [ "Genetière",[4.818742980950929, 45.906645545141565]],
    [ "Varennes",[4.8195105, 45.91093875]],
    [ "ARCHANDRIS-N137",[4.842506666666667, 46.83746331060606]],
    [ "Sous-station SNCF de Velars",[4.926832805306614, 47.32657629236987]],
    [ "Enedis",[4.065436995818699, 46.59907884880415]],
    [ "Enedis",[4.0797467901234565, 46.60381895061728]],
    [ "Enedis",[4.052264393470791, 46.60176835051547]],
    [ "Enedis",[4.074183, 46.59268585]],
    [ "Enedis",[4.035911724330686, 46.61347963871728]],
    [ "Enedis",[4.058740517476557, 46.60743690451832]],
    [ "Enedis",[4.060966306660836, 46.60212575455473]],
    [ "Enedis",[4.073180461216782, 46.60472164009173]],
    [ "ZI Samzac",[5.015740788050684, 45.70281270821132]],
    [ "Enedis",[4.7439012535461, 46.7779570531915]],
    [ "Enedis",[4.739762500000001, 46.78074525000001]],
    [ "Enedis",[4.741506521685892, 46.77410180563706]],
    [ "Cimetière",[4.7504912500000005, 46.78102730000001]],
    [ "Enedis",[4.7463944635053466, 46.78004758112506]],
    [ "Enedis",[4.7402826943089424, 46.77803947317073]],
    [ "Enedis",[4.746234829354554, 46.785305042440314]],
    [ "Enedis",[4.741409762682343, 46.78327287219682]],
    [ "Enedis",[4.7493185, 46.77695099999999]],
    [ "Enedis",[4.751543500000001, 46.779106500000005]],
    [ "Pièces Bourgeoises",[4.764940225815777, 46.785286010195]],
    [ "Enedis",[4.740222295715779, 46.78507064994775]],
    [ "Enedis",[4.73780175, 46.774626]],
    [ "Enedis",[4.719345104659014, 46.77904569029934]],
    [ "Zone Artisanale",[4.767027953739732, 46.78465512278426]],
    [ "Sous-station SNCF de Perrigny",[5.026006473359221, 47.290465382392384]],
    [ "Poste électrique de Kir",[4.977399879470741, 47.33104951103963]],
    [ "Enedis",[4.797311343906511, 46.860239431274344]],
    [ "Enedis",[4.777767133249263, 46.86278916855822]],
    [ "Poste électrique de Romelet",[5.039161863150873, 47.28078427538192]],
    [ "127383694",[4.8494954, 46.37935595]],
    [ "Enedis",[4.86358218128655, 46.377642957764785]],
    [ "Enedis",[-1.7180257743398477, 46.65896279471226]],
    [ "Enedis",[-1.7118596000000001, 46.63836134999999]],
    [ "Enedis",[-1.7133652999999998, 46.663968]],
    [ "Enedis",[-1.706992926835777, 46.63607076439088]],
    [ "Enedis",[-1.71337305, 46.64000875]],
    [ "Enedis",[-1.7152004617093823, 46.63968857777288]],
    [ "Enedis",[4.829496064015904, 45.890660285950965]],
    [ "Enedis",[4.834265375268817, 45.89124969569892]],
    [ "Enedis",[4.832745698934575, 45.894414837632816]],
    [ "RTE",[4.96339230475016, 47.123131242376296]],
    [ "SNCF Réseau",[4.856367505298939, 47.03061438583912]],
    [ "Enedis",[4.850745687595874, 47.04565189130103]],
    [ "Enedis",[4.136716500000001, 46.6918572]],
    [ "Enedis",[4.1366428514285705, 46.68486618857142]],
    [ "Enedis",[4.133940684939091, 46.69499295127354]],
    [ "Enedis",[4.148460471057883, 46.687321253493]],
    [ "127500232",[4.143099514977061, 46.6914744546968]],
    [ "Notre Dame",[5.2279082622975785, 46.20582148108452]],
    [ "Enedis",[5.210344341964549, 46.1980031567799]],
    [ "creve - coeur",[5.2238204305835, 46.20837000989268]],
    [ "Enedis",[5.227244599107712, 46.1885556475462]],
    [ "PELOUX",[5.21375056873527, 46.19811743100288]],
    [ "J MERMOZ",[5.209919190160062, 46.192020828428106]],
    [ "Privas",[5.222556750000001, 46.2088705]],
    [ "Enedis",[5.230712, 46.1873735]],
    [ "AVENIR",[5.213420474784748, 46.19680338007381]],
    [ "Enedis",[5.210600089283218, 46.20026070139271]],
    [ "Enedis",[3.1570418170996994, 46.98672861347299]],
    [ "Enedis",[3.148250383918637, 46.98740291916896]],
    [ "BERTHELOT",[5.2116778241758235, 46.19439576831502]],
    [ "ZA Pennessuy",[5.248058187964503, 46.20137625396561]],
    [ "Enedis",[3.152946633003607, 46.984399997304806]],
    [ "Enedis",[3.159556195416474, 46.98621215062924]],
    [ "Enedis",[3.1459515231707855, 46.983846559275726]],
    [ "Enedis",[3.1470580499761924, 46.98700088250061]],
    [ "Enedis",[3.1527420193579014, 46.9871443214768]],
    [ "Enedis",[3.1597347241379836, 46.989931043385084]],
    [ "RTE",[5.656592605289872, 45.20861797126055]],
    [ "Enedis",[2.176647, 48.8971975]],
    [ "Poste Source Châtenoy-Le-Royal",[4.793090751416341, 46.78199962509025]],
    [ "Varennes (SNCF)",[4.861987193790078, 46.717618901293825]],
    [ "Sous Station de Chalon-sur-Saône",[4.843752040787886, 46.776965539713856]],
    [ "RTE",[4.786946584256821, 46.83767885552747]],
    [ "RTE",[4.748407183510486, 46.90577707194416]],
    [ "SNCF Réseau",[4.794217441379251, 46.967383023552465]],
    [ "Enedis",[-1.76160605, 46.62458905]],
    [ "Enedis",[-1.7791516749168386, 46.5980452941191]],
    [ "Enedis",[6.604542987627338, 48.130007202748395]],
    [ "Enedis",[6.586142629293872, 48.14356371926904]],
    [ "Enedis",[6.601626704604234, 48.13686821951633]],
    [ "Enedis",[6.600555412405261, 48.141390725484634]],
    [ "Enedis",[4.84561647398571, 47.00108271003735]],
    [ "Sous-station SNCF",[5.2198980370121895, 47.06173083135013]],
    [ "Navilly (SNCF)",[5.141124173648634, 46.9316068276231]],
    [ "Champ-Fournier Mervans",[5.181470681085984, 46.8035961394288]],
    [ "Enedis",[4.751804219129194, 46.81468997287652]],
    [ "Saint-Usuge (SNCF)",[5.195061612792809, 46.6709167355926]],
    [ "Louhans",[5.213809673090424, 46.619117225970975]],
    [ "Enedis",[1.3158318490399754, 44.031976616617605]],
    [ "Enedis",[-1.2501255079365081, 44.45038833333334]],
    [ "Enedis",[-1.1704352771179731, 44.382829602850364]],
    [ "Enedis",[-1.154621302441457, 44.40225975734928]],
    [ "Enedis",[-1.1683018929292923, 44.38147169124579]],
    [ "Enedis",[-1.1610813503092623, 44.396949348220616]],
    [ "Enedis",[-1.1680113500000004, 44.39309885]],
    [ "Enedis",[-1.2478547623386473, 44.45087545482156]],
    [ "Enedis",[-1.240523, 44.4547535]],
    [ "Enedis",[-1.1618508271857921, 44.38074233688524]],
    [ "Enedis",[-1.1556359194414612, 44.38755600322234]],
    [ "Enedis",[-1.2456799705497654, 44.45461933547925]],
    [ "Enedis",[-1.1630838668098817, 44.38748209846044]],
    [ "Enedis",[-1.1534374999999994, 44.384436]],
    [ "Enedis",[-1.2411344151696602, 44.45292818363273]],
    [ "Enedis",[-1.2479969723618092, 44.45285417671692]],
    [ "Enedis",[-1.160804654761905, 44.378816122448974]],
    [ "Enedis",[-1.1601043000000002, 44.384046700000006]],
    [ "Enedis",[-1.2512753000000003, 44.4496476]],
    [ "Enedis",[-1.244269773264684, 44.44430685526316]],
    [ "Enedis",[-1.161010139280125, 44.37735356651017]],
    [ "Enedis",[-1.163960527322405, 44.37755126034348]],
    [ "Enedis",[-1.2513967784586817, 44.44466391671309]],
    [ "Enedis",[-1.1627480960451981, 44.398352762711866]],
    [ "Enedis",[-1.1581258472954499, 44.383439453036516]],
    [ "Enedis",[-1.1657733430656936, 44.39990976885646]],
    [ "Enedis",[-1.1715438152469857, 44.385943598988725]],
    [ "Enedis",[-1.2419528952380952, 44.44538021088435]],
    [ "Enedis",[-1.1743689693444137, 44.384222431578955]],
    [ "Enedis",[-1.2407029999999997, 44.448657]],
    [ "Enedis",[-1.1612921490581496, 44.403890986998356]],
    [ "Enedis",[-1.1578363874113475, 44.40279379713357]],
    [ "Enedis",[-1.2486864139508294, 44.439689118353336]],
    [ "Enedis",[-1.160347386666667, 44.398388626666666]],
    [ "Enedis",[-1.2461800620296355, 44.4424577747194]],
    [ "Enedis",[-1.2521872595005428, 44.44210444154904]],
    [ "Enedis",[-1.153904500000001, 44.40148849999999]],
    [ "Enedis",[-1.2456597920936119, 44.446532540986716]],
    [ "Enedis",[-1.2435364486772493, 44.4549532915344]],
    [ "Enedis",[-1.1639170950871633, 44.38548316640254]],
    [ "Enedis",[-1.1590447286384975, 44.387269230046954]],
    [ "Enedis",[-1.252390325800127, 44.440824093396216]],
    [ "Enedis",[-1.2539235999999994, 44.4414726]],
    [ "Enedis",[-1.2401929297188752, 44.456567087751004]],
    [ "Enedis",[-1.152309888683887, 44.392530115621156]],
    [ "Enedis",[1.3756431174404984, 44.03805199560137]],
    [ "Enedis",[1.3843858000000002, 44.041594700000005]],
    [ "Enedis",[1.5302029, 43.65850419999999]],
    [ "Enedis",[1.526007806087781, 43.66939223223922]],
    [ "Enedis",[1.5246762199588506, 43.6726025992725]],
    [ "Enedis",[1.5193264827537336, 43.67181129906637]],
    [ "Enedis",[1.529633632264954, 43.66807445152415]],
    [ "Enedis",[1.5225545295868759, 43.66918454200784]],
    [ "RTE",[5.3861161735832574, 47.18871355470329]],
    [ "RTE",[5.372279958239181, 47.194898479080614]],
    [ "RTE",[5.230715282171848, 47.24655599075215]],
    [ "SNCF Réseau",[5.256366290101029, 47.22653588626481]],
    [ "SNCF Réseau",[5.437586947866753, 47.11560942053364]],
    [ "Sous-station SNCF de Crimolois",[5.115063828204655, 47.28062828827039]],
    [ "Enedis",[6.617411988221951, 48.16838817719005]],
    [ "Enedis",[1.4986145929295553, 43.67293470097911]],
    [ "Enedis",[1.5165452549019607, 43.64974059477124]],
    [ "Enedis",[1.4973934999999992, 43.659322]],
    [ "Enedis",[1.505373659936601, 43.664910470811996]],
    [ "Enedis",[1.5065318412637991, 43.65911348635961]],
    [ "Enedis",[1.496615, 43.665918000000005]],
    [ "Enedis",[1.512067324321618, 43.65617452117754]],
    [ "Enedis",[1.5143520354609932, 43.65378966666666]],
    [ "Enedis",[1.519222, 43.6507445]],
    [ "Enedis",[1.4944789999999997, 43.658359000000004]],
    [ "Enedis",[1.4954896077441069, 43.67096015656565]],
    [ "Enedis",[1.5067760000000003, 43.655842500000006]],
    [ "Enedis",[1.499596268544601, 43.662569452179746]],
    [ "Enedis",[1.505754744468758, 43.667254066131775]],
    [ "Enedis",[1.5212240284491438, 43.64834450906344]],
    [ "Enedis",[1.490713582524272, 43.67466466343042]],
    [ "Enedis",[1.5090076603295308, 43.6564797287706]],
    [ "Enedis",[1.4988229999999996, 43.6602495]],
    [ "Enedis",[1.5218803013698627, 43.651433166115574]],
    [ "Enedis",[1.5047838268558502, 43.65568400390706]],
    [ "Enedis",[1.5080373620170273, 43.65383576293386]],
    [ "Enedis",[1.4900473371212122, 43.66663998484848]],
    [ "Enedis",[1.4986906501816155, 43.66384582285553]],
    [ "Enedis",[1.4984642878628884, 43.65683505001749]],
    [ "Enedis",[1.5100376670802313, 43.661522990074445]],
    [ "Enedis",[1.5021035901412356, 43.670627199575364]],
    [ "Enedis",[1.5040561650485436, 43.660909792880254]],
    [ "Enedis",[1.5007732509803926, 43.65781941960784]],
    [ "Enedis",[1.489017861423221, 43.67294340823971]],
    [ "Enedis",[1.5072899999999994, 43.651528500000005]],
    [ "Enedis",[1.4978020921590485, 43.67089820085471]],
    [ "Enedis",[1.5018039271523174, 43.66301818763797]],
    [ "Enedis",[1.495162187396351, 43.66268603980099]],
    [ "Enedis",[1.5142095, 43.6503065]],
    [ "Enedis",[1.5028583180011355, 43.65502559965929]],
    [ "Enedis",[1.4936278329142219, 43.665332507124894]],
    [ "Enedis",[1.5036156575519897, 43.66523655146434]],
    [ "Enedis",[1.4966938326359833, 43.657010492887025]],
    [ "Enedis",[1.5014301958087761, 43.669165273084474]],
    [ "Enedis",[1.5205599115133237, 43.65433163624937]],
    [ "Enedis",[1.5005774999999997, 43.667213000000004]],
    [ "Enedis",[1.5000019999999998, 43.6540095]],
    [ "Enedis",[1.5020835, 43.66065249999999]],
    [ "Enedis",[1.5000055, 43.670074]],
    [ "Enedis",[1.5106284871209654, 43.660276325399415]],
    [ "Enedis",[1.4960467083333329, 43.673748348118274]],
    [ "Enedis",[1.5015613982706442, 43.66484289182878]],
    [ "Enedis",[1.4979221644774412, 43.669565423979954]],
    [ "Poste électrique de Vire",[-0.8619161125753297, 48.85289135604386]],
    [ "Enedis",[1.3857828499999998, 44.00700904999999]],
    [ "RTE",[6.579355032713106, 44.273957830162765]],
    [ "Enedis",[1.4815281749174916, 43.688818273927396]],
    [ "Enedis",[1.4772040000000002, 43.681928]],
    [ "Enedis",[1.477678436318068, 43.685226681476415]],
    [ "Enedis",[1.4955494017094018, 43.69238233333334]],
    [ "Enedis",[1.4785520684365776, 43.686527746312684]],
    [ "Enedis",[1.4863723104106978, 43.68341202674308]],
    [ "Enedis",[1.4925096960514235, 43.68911695775941]],
    [ "Enedis",[1.4878797138576778, 43.68584942397004]],
    [ "Enedis",[1.484648, 43.68072]],
    [ "Enedis",[1.4947180556792878, 43.68711719821827]],
    [ "Enedis",[1.476969971660357, 43.68667014820623]],
    [ "Enedis",[1.480252, 43.686510000000006]],
    [ "Enedis",[1.4836929999999993, 43.687288499999994]],
    [ "Enedis",[1.483666, 43.6860785]],
    [ "Enedis",[1.4749996111354038, 43.70005185337414]],
    [ "Enedis",[1.475380336389101, 43.6958375118411]],
    [ "Enedis",[1.479809192393736, 43.69658046979866]],
    [ "Enedis",[1.472942073750991, 43.69936065715041]],
    [ "Enedis",[1.4774759999999998, 43.692976]],
    [ "Enedis",[1.4852808558558557, 43.693486936936935]],
    [ "Enedis",[1.477885704427961, 43.698641573162504]],
    [ "Enedis",[1.4719794999999993, 43.6958135]],
    [ "Enedis",[1.4831709999999998, 43.69356249999999]],
    [ "Enedis",[1.4731830000000001, 43.6971515]],
    [ "Enedis",[6.743355647406348, 44.45919903748782]],
    [ "Poste source de Pont-Évêque",[4.929220303229956, 45.52226179412122]],
    [ "Poste électrique de Gampaloup",[4.833394432267075, 45.33527731056649]],
    [ "Polliat",[5.125968687332401, 46.24471163649489]],
    [ "Croix-Léonard",[4.886661862651102, 46.57061246160959]],
    [ "La Grange (SNCF)",[4.908711801734299, 46.589422699667416]],
    [ "RTE",[5.378371949268375, 46.50083736693633]],
    [ "RTE",[5.331740838882491, 46.44001276279185]],
    [ "RTE",[5.333704079574315, 46.44392108512581]],
    [ "RTE",[5.333749263459043, 46.436845096113]],
    [ "Bény (SNCF)",[5.296307925941385, 46.31792510218664]],
    [ "14 Juillet",[4.078416458279884, 48.294121981605684]],
    [ "Cruet",[4.997312696501992, 46.2277574081426]],
    [ "Vonnas",[4.995725375435926, 46.22713817308563]],
    [ "Enedis",[3.619641999999999, 49.903323500000006]],
    [ "Enedis",[3.631249389937106, 49.89546419496856]],
    [ "Enedis",[3.6282554010416668, 49.900523786458336]],
    [ "Enedis",[3.62479331617736, 49.89932738999637]],
    [ "Enedis",[3.6303378075567583, 49.90002980198344]],
    [ "Enedis",[3.6284768186347773, 49.90703033383159]],
    [ "Enedis",[3.6408762226512223, 49.904312712870016]],
    [ "Enedis",[3.6318049330771687, 49.90268868427793]],
    [ "Enedis",[3.62332386260454, 49.90356481362007]],
    [ "Enedis",[3.634885417992786, 49.90146512836836]],
    [ "Enedis",[3.631867452877925, 49.89334492599621]],
    [ "Enedis",[3.613520499999999, 49.905719999999995]],
    [ "Enedis",[1.4659010000000001, 43.70810049999999]],
    [ "Enedis",[1.4638691793386434, 43.6978965174994]],
    [ "Enedis",[1.462485680685358, 43.70581801869158]],
    [ "Enedis",[1.4572322970895117, 43.70936055738604]],
    [ "Enedis",[1.4637268155668357, 43.70421708685843]],
    [ "Enedis",[1.4478549754148742, 43.71483280926039]],
    [ "Enedis",[1.4588654086021497, 43.69997433333333]],
    [ "Impasse de Trélivalaire",[-3.57264935, 47.8645157]],
    [ "Sous-station de Sarry",[4.0875475319753205, 47.66852045554715]],
    [ "Poste électrique de Tonnerre",[4.010492145242405, 47.852604632672694]],
    [ "Enedis",[0.18470595025398492, 46.03245584252934]],
    [ "Enedis",[0.20458458117647035, 46.0317181890196]],
    [ "Enedis",[0.18223199999999956, 46.025264500000006]],
    [ "Enedis",[0.19887673484848511, 46.022493330808075]],
    [ "Enedis",[0.19784689100817412, 46.04220442415985]],
    [ "Enedis",[0.1981388830623287, 46.044386233720175]],
    [ "Enedis",[0.20425399999999996, 46.027001999999996]],
    [ "Enedis",[0.2066822431114359, 46.03414085613265]],
    [ "Enedis",[0.187153, 46.02691983333333]],
    [ "Enedis",[0.19101799999999997, 46.02178849999999]],
    [ "Enedis",[0.20339687902523915, 46.03361024630112]],
    [ "Enedis",[0.19152350000000004, 46.02894100000001]],
    [ "Frontenaud (SNCF)",[5.27554561801091, 46.55654850391225]],
    [ "Enedis",[0.27048011428571417, 49.514115817460315]],
    [ "Enedis",[0.260846, 49.51637800000001]],
    [ "Enedis",[0.2654865000000006, 49.514307499999994]],
    [ "Enedis",[0.2693166038500688, 49.51856798857553]],
    [ "Enedis",[0.2672869405529142, 49.51582533993661]],
    [ "Enedis",[0.2702800775433662, 49.51651606390061]],
    [ "Enedis",[0.26313176044738623, 49.52008552996761]],
    [ "Enedis",[0.26210152349936244, 49.51435483052363]],
    [ "Enedis",[0.23689972503201082, 49.535944954971995]],
    [ "Enedis",[-0.8431709499999999, 46.5026236]],
    [ "Enedis",[-0.8493368603930356, 46.502073426690664]],
    [ "Enedis",[-0.8423305564282972, 46.49864447447396]],
    [ "Enedis",[1.460636, 43.711349500000004]],
    [ "Enedis",[1.4602284999999997, 43.7049285]],
    [ "Enedis",[1.4679694853046592, 43.70583532831541]],
    [ "Enedis",[1.4662850318979266, 43.69955968580542]],
    [ "Enedis",[1.4603288401360544, 43.70088739115646]],
    [ "Enedis",[1.459994500000001, 43.7069295]],
    [ "Enedis",[1.4662214999999998, 43.712672]],
    [ "Enedis",[1.462765057142857, 43.71271578730158]],
    [ "Enedis",[1.459358, 43.7115145]],
    [ "Enedis",[1.4649582027027008, 43.694162186936936]],
    [ "Enedis",[1.4485164999999998, 43.71899500000001]],
    [ "Enedis",[1.4630986407506703, 43.71087342389038]],
    [ "Enedis",[1.4630368143459913, 43.70036537552743]],
    [ "Enedis",[1.4618740171996136, 43.70981915503877]],
    [ "Enedis",[1.4690008455284553, 43.70484756097561]],
    [ "Enedis",[1.4618612383763845, 43.70305880934809]],
    [ "RTE",[3.816930787148539, 47.40611926781843]],
    [ "Enedis",[-3.1428853500000002, 48.567682]],
    [ "Poste électrique de Dantou",[0.8305797616071556, 44.46155384597742]],
    [ "Enedis",[-1.53211625, 46.71186850000001]],
    [ "Enedis",[-1.5175785000000002, 46.684673]],
    [ "Enedis",[-1.515490182497712, 46.68458699806347]],
    [ "Enedis",[-1.5087474999999997, 46.685884]],
    [ "Enedis",[-1.5069445000000004, 46.684128]],
    [ "Enedis",[-1.52064545, 46.67897455000001]],
    [ "Enedis",[-1.577080768530166, 46.70339749638947]],
    [ "Enedis",[-1.512576998595686, 46.684969588618635]],
    [ "Enedis",[-1.517185, 46.67984025000001]],
    [ "Enedis",[-1.5169519879392213, 46.666566577302945]],
    [ "Enedis",[-1.512665932996228, 46.65206594824143]],
    [ "Enedis",[-1.5124544999999996, 46.68766024999999]],
    [ "Enedis",[-1.5082825000000002, 46.68130875]],
    [ "Enedis",[-1.5366617001001, 46.64377874624624]],
    [ "Enedis",[-1.51557825, 46.69051025]],
    [ "Enedis",[5.494376745218537, 46.74456271635244]],
    [ "RTE",[2.5050818674654023, 50.951347350938086]],
    [ "Enedis",[0.8497093669638153, 46.002751823023985]],
    [ "Enedis",[5.460995495132417, 46.7489974182182]],
    [ "Église",[5.453114762172284, 46.74681970580525]],
    [ "Enedis",[5.446216408303926, 46.74459808503435]],
    [ "Enedis",[5.444813980742406, 46.74899470174027]],
    [ "Enedis",[5.4472887832326515, 46.74865804959325]],
    [ "Enedis",[5.455915787436523, 46.7473808140096]],
    [ "GMF",[5.44948637939535, 46.7482011655292]],
    [ "Enedis",[5.450186249999999, 46.74589725]],
    [ "Enedis",[5.460982580250211, 46.74713915881917]],
    [ "Enedis",[5.4621705, 46.74994249999999]],
    [ "Enedis",[5.470929750000001, 46.74882850000001]],
    [ "Enedis",[5.465411324458937, 46.749632755611344]],
    [ "Enedis",[6.727754650000001, 48.08990575000001]],
    [ "Enedis",[4.195942549549445, 43.69069477963102]],
    [ "Enedis",[4.200794666626368, 43.69074905297531]],
    [ "Enedis",[4.211696189871743, 43.68539352082507]],
    [ "Enedis",[4.206988884057971, 43.688209220289856]],
    [ "Enedis",[4.190185402630248, 43.7022803474962]],
    [ "Enedis",[4.198101005975839, 43.69146200259601]],
    [ "Enedis",[4.207579354245085, 43.681621830196576]],
    [ "Enedis",[4.2049122500000005, 43.691802249999995]],
    [ "Enedis",[-4.080020999999999, 48.50973400000001]],
    [ "Enedis",[-4.086074127056155, 48.507042787672525]],
    [ "Enedis",[-4.060372150344522, 48.49936941654571]],
    [ "Enedis",[-4.0610387415107425, 48.50854606237007]],
    [ "Enedis",[-4.073933121488231, 48.50103868818021]],
    [ "Enedis",[-4.0548425, 48.51538750000001]],
    [ "Enedis",[-4.080309249999999, 48.499267749999994]],
    [ "Enedis",[-4.0797505, 48.512318]],
    [ "Enedis",[-4.057839749262538, 48.51278145132745]],
    [ "Enedis",[-4.071519400719423, 48.51356864868106]],
    [ "Enedis",[-4.0808085, 48.50679300000001]],
    [ "Enedis",[-4.078561000000001, 48.501748000000006]],
    [ "Enedis",[-4.0733314122624495, 48.51302730780968]],
    [ "Enedis",[-4.0763485, 48.51623175000001]],
    [ "Enedis",[-4.060327309641558, 48.4994023693138]],
    [ "Enedis",[-4.0632556056489575, 48.50666926601659]],
    [ "Enedis",[-4.06387, 48.512215]],
    [ "Enedis",[-4.081953833333333, 48.49858683333334]],
    [ "Enedis",[-4.091049911183193, 48.49908222412946]],
    [ "Enedis",[-4.059949783229073, 48.520355799509026]],
    [ "Enedis",[-4.0909706963997845, 48.50842472828228]],
    [ "Enedis",[-4.063947693333333, 48.504511959999995]],
    [ "Enedis",[-4.0584145000000005, 48.508197]],
    [ "Enedis",[-4.0770218, 48.509494700000005]],
    [ "Enedis",[-4.05901, 48.51515750000001]],
    [ "Enedis",[-4.053642000000001, 48.5112065]],
    [ "Enedis",[-4.083839965377958, 48.50269916714753]],
    [ "Enedis",[-4.0766839, 48.4977409]],
    [ "Rue Grammont",[5.168912250000001, 45.750451749999996]],
    [ "Wagner",[5.1740041456430745, 45.75632257924277]],
    [ "Poste électrique du Cruel",[-1.7263532250688813, 48.22789281358024]],
    [ "RTE",[-1.826304172767179, 48.320369180327006]],
    [ "RTE",[0.6742435995112122, 47.43113104331424]],
    [ "Poste électrique de Tours",[0.6743845298159201, 47.377563955303785]],
    [ "Poste électrique de Saint-Avertin",[0.7287028760779823, 47.346975755465266]],
    [ "RTE",[0.6446837919805056, 47.3552106205007]],
    [ "Les Vallées",[1.2417207315630139, 47.51638406688623]],
    [ "RTE",[1.1772102384862275, 47.49321354538198]],
    [ "Enedis",[0.6876734005807891, 46.05204509969065]],
    [ "Poste électrique de La Commanderie",[0.9899776166444572, 47.42581703552033]],
    [ "RTE",[1.4478616271643128, 47.67641874649347]],
    [ "SNCF Réseau",[1.570736447431669, 47.74330629859167]],
    [ "Enedis",[0.4797457904483323, 45.94271388701486]],
    [ "Enedis",[0.1639410393162395, 46.07601617606837]],
    [ "Enedis",[0.7044096548329815, 45.96509190686088]],
    [ "Enedis",[0.6997351493561541, 45.962837267218795]],
    [ "Enedis",[5.011212800000001, 47.29793055]],
    [ "Montbertrand",[5.151616, 45.728161]],
    [ "Église",[5.144945987437075, 45.72724666922011]],
    [ "Refeuille",[5.1408434, 45.730170099999995]],
    [ "Les Courelles",[1.6776451055942716, 47.81983680430691]],
    [ "Enedis",[-0.2645935, 47.6732315]],
    [ "Enedis",[-0.245057, 47.671369000000006]],
    [ "Enedis",[-0.2611960410474167, 47.67784007619721]],
    [ "RTE",[1.680518463960778, 47.82987458338799]],
    [ "Enedis",[-0.23325300000000002, 47.67927750000001]],
    [ "Enedis",[-0.23394770076103502, 47.67500193668189]],
    [ "Enedis",[-0.2332497790148448, 47.676758169197036]],
    [ "Enedis",[-0.24269965514758876, 47.67563802591793]],
    [ "Enedis",[-0.22787400000000002, 47.67578999999999]],
    [ "Chaffard",[5.143055675877708, 45.66887675892907]],
    [ "Satolas",[5.1289676529058115, 45.69432896065464]],
    [ "Ropinaud",[5.137639179710145, 45.69404416231884]],
    [ "Revolay",[5.084121191552973, 45.66130097462947]],
    [ "Chatanay",[5.083654499999999, 45.667317749999995]],
    [ "Enedis",[2.108290233836021, 48.87578229450511]],
    [ "Enedis",[2.106552803746858, 48.873106198080876]],
    [ "Enedis",[5.136975666666667, 45.62641202531647]],
    [ "Rue de Luzais",[5.0979465, 45.6456505]],
    [ "Avenue de Tharabie P1",[5.1044725, 45.644698500000004]],
    [ "Noirée",[5.108144087155964, 45.64374064556576]],
    [ "Micronde",[5.105276749999999, 45.646472]],
    [ "Le Bourg",[5.112058282298045, 45.63210345261009]],
    [ "MLP",[5.109312699999999, 45.6401969]],
    [ "Enedis",[5.108880905086581, 45.645085365622776]],
    [ "Enedis",[5.103790278954802, 45.66333202966102]],
    [ "Ponthiepe",[5.1136300000000015, 45.6371455]],
    [ "Bois de Fallavier",[5.132936165281626, 45.62695402585411]],
    [ "A-Z Électronique",[5.108859654790183, 45.64481554869359]],
    [ "Moulin",[5.104770260449058, 45.641606234913525]],
    [ "Enedis",[5.113720203146886, 45.629868831960536]],
    [ "Enedis",[7.142708413087934, 49.05282085071575]],
    [ "Enedis",[7.148292527642275, 49.053438548780484]],
    [ "Enedis",[4.848757728613569, 46.360315876106206]],
    [ "Enedis",[4.831294492273733, 46.351471227373075]],
    [ "Enedis",[4.842763500000001, 46.357936]],
    [ "Poste électrique de Marckolsheim",[7.587257922265336, 48.153727542962265]],
    [ "Enedis",[1.4496788400335012, 47.56106759882747]],
    [ "Enedis",[1.4506879999999998, 47.566989]],
    [ "Enedis",[1.451060299125365, 47.56369070456754]],
    [ "Enedis",[1.445481, 47.562663]],
    [ "Enedis",[1.4473015601617796, 47.55491184866868]],
    [ "Enedis",[1.4507806992046275, 47.567009025307314]],
    [ "Enedis",[1.438121300047551, 47.566370658582976]],
    [ "Enedis",[1.4515035419511346, 47.558229069140886]],
    [ "Enedis",[-1.4325455082832697, 43.64909809047879]],
    [ "Enedis",[-1.4246850422405883, 43.64297698714417]],
    [ "Enedis",[-1.4265384999999997, 43.65462850000001]],
    [ "Enedis",[-1.4325498450560652, 43.627102180428146]],
    [ "Enedis",[-1.4282762681281613, 43.63167408375491]],
    [ "Enedis",[-1.4250305279181248, 43.63568793574298]],
    [ "Enedis",[-1.4275471900311527, 43.642526483715656]],
    [ "Enedis",[-1.428247436385986, 43.633806742061054]],
    [ "Enedis",[-1.4249216497175137, 43.65490879378531]],
    [ "Enedis",[-1.4305529036295372, 43.63202128368793]],
    [ "Enedis",[-1.4293024345693541, 43.62914676339024]],
    [ "Enedis",[-1.4210868910645966, 43.63557331105108]],
    [ "Enedis",[-1.4285798413391557, 43.6535842547307]],
    [ "Enedis",[-1.4311159816272971, 43.650589745406826]],
    [ "Enedis",[-1.4211598333333324, 43.64698883333333]],
    [ "Enedis",[-1.4248908770433544, 43.64737079815211]],
    [ "Enedis",[-1.4236096060606058, 43.65258323030302]],
    [ "Enedis",[-1.4293297824951605, 43.64659959562911]],
    [ "Enedis",[-1.429453636740072, 43.64924982326369]],
    [ "Enedis",[5.688517838928029, 43.438536473269785]],
    [ "RTE",[3.108253684074867, 43.86624278979399]],
    [ "RTE",[2.753305584952378, 49.358337998331194]],
    [ "Enedis",[1.5836443107108673, 50.57241852431915]],
    [ "Enedis",[1.5842158857343145, 50.579229284963205]],
    [ "Enedis",[1.579616559139786, 50.571013580645165]],
    [ "Enedis",[1.6159566257325286, 50.56944916784746]],
    [ "Enedis",[1.6130441263174449, 50.57793919285827]],
    [ "Enedis",[1.5908457477477476, 50.572429504504505]],
    [ "Enedis",[1.6184844999999994, 50.56696250000001]],
    [ "Enedis",[1.601127024160697, 50.57128033277145]],
    [ "Enedis",[1.5946363837398378, 50.57363768617886]],
    [ "Enedis",[1.5973316252359382, 50.571440750755]],
    [ "Enedis",[1.5992860270974465, 50.573586667361475]],
    [ "Enedis",[1.6171142608257805, 50.56489494864048]],
    [ "Enedis",[1.6165370323488046, 50.562809828410685]],
    [ "Enedis",[1.612262749999999, 50.5754495]],
    [ "Enedis",[1.579379493253373, 50.573025281359314]],
    [ "Enedis",[1.5823395000000013, 50.577836999999995]],
    [ "Enedis",[1.5846837296480245, 50.57543644583603]],
    [ "Enedis",[1.603124900000001, 50.572953000000005]],
    [ "Enedis",[1.5908366322006287, 50.57124569911669]],
    [ "Enedis",[1.5787301321243037, 50.57566692337773]],
    [ "Enedis",[1.6150642302798979, 50.56841309160305]],
    [ "Enedis",[1.5815628962962958, 50.575032281481484]],
    [ "Enedis",[1.5932249319587626, 50.57122368178694]],
    [ "Enedis",[1.6008686209254461, 50.57333426342695]],
    [ "Enedis",[1.6128647574304886, 50.5727859399169]],
    [ "Enedis",[4.167873886679874, 47.505715275849525]],
    [ "Enedis",[4.176845932263291, 47.49530289254791]],
    [ "Enedis",[4.1715842499999995, 47.50363090000001]],
    [ "Enedis",[4.175906794060836, 47.50445635865779]],
    [ "Poste électrique de Champblain",[4.812163045812799, 45.21702914151878]],
    [ "Enedis",[6.486324750000001, 47.68440945]],
    [ "Enedis",[2.4336732041180795, 48.58297254953251]],
    [ "Enedis",[2.4206102537878778, 48.61123492045454]],
    [ "Enedis",[2.4180150000000005, 48.60385099999999]],
    [ "Enedis",[2.41547719009478, 48.61216053210519]],
    [ "Enedis",[2.4315428479166665, 48.59259192499999]],
    [ "Enedis",[2.4328226725333337, 48.5935694736]],
    [ "Enedis",[2.4329333429421185, 48.60435503157173]],
    [ "Enedis",[2.4191714999999996, 48.607203]],
    [ "Enedis",[2.4355864999999994, 48.603801749999995]],
    [ "Enedis",[2.4316706539278132, 48.60698308280254]],
    [ "Enedis",[2.4349695, 48.596149499999996]],
    [ "Enedis",[2.411743, 48.60797399999999]],
    [ "Enedis",[2.4179822917997864, 48.609399266240686]],
    [ "Enedis",[2.42050227886252, 48.60400884950435]],
    [ "Enedis",[2.436626443631035, 48.60178303433949]],
    [ "Enedis",[2.4205241642193585, 48.613384433922675]],
    [ "La Pinède",[-1.59406395035461, 49.04067381560283]],
    [ "Enedis",[-1.5940221850187268, 49.031513476404484]],
    [ "Les Cygnes",[-1.587280528833553, 49.03402812297947]],
    [ "Siméon Luce",[-1.5995803798203903, 49.04510125937666]],
    [ "Rue Lefèvre",[-1.596242261599512, 49.04063912973138]],
    [ "Rue Docteur Viaud",[-1.594610179865552, 49.03515942646336]],
    [ "Hameau Mare",[-1.5906498962472402, 49.042069728476825]],
    [ "Les Dunes",[-1.5966399999999998, 49.032956500000004]],
    [ "Lotissement Panicaut",[-1.587390284521013, 49.04098153014878]],
    [ "Le Bourg",[-1.5772178007338422, 49.04210653880892]],
    [ "Le Senequet",[-1.6016547071803215, 49.052546357930915]],
    [ "Eugène Fontaine",[-1.588636615606341, 49.03898926559605]],
    [ "La Castel",[-1.5792713204301068, 49.0470165032258]],
    [ "Avenue des Pins",[-1.5931621818250865, 49.04567195773711]],
    [ "SNCF Réseau",[3.5334817572921478, 47.964770372975934]],
    [ "Enedis",[3.544881598362008, 47.95989504068811]],
    [ "Enedis",[2.964202239102062, 43.029973537196554]],
    [ "Maeva",[-3.5062591685707862, 47.74970329687741]],
    [ "129348197",[7.589777467958656, 48.92174988621877]],
    [ "Enedis",[1.5914861299093648, 50.70561633836858]],
    [ "Enedis",[1.5895013964659461, 50.70759458878023]],
    [ "Enedis",[1.5897012000000015, 50.69968505]],
    [ "Enedis",[1.6058551606986302, 50.69889457034427]],
    [ "Enedis",[1.6043899193852065, 50.69029799746398]],
    [ "Enedis",[1.60365365, 50.69458015000001]],
    [ "Enedis",[1.5783011181792477, 50.7016792929036]],
    [ "Enedis",[1.583046294025157, 50.705953488993714]],
    [ "Enedis",[1.5865250153035366, 50.70241260889834]],
    [ "Enedis",[1.5957952058436229, 50.71179086951559]],
    [ "Enedis",[1.5730404, 50.70203980000001]],
    [ "Enedis",[1.5961673131339782, 50.700154618608565]],
    [ "Enedis",[1.586118967113983, 50.70856372680583]],
    [ "Enedis",[1.5943175731142019, 50.70606977876107]],
    [ "Enedis",[1.5963319999999999, 50.70450549999999]],
    [ "Enedis",[1.6023469999999997, 50.694348850000004]],
    [ "Enedis",[1.5958066334249945, 50.69372105393227]],
    [ "Enedis",[1.599361, 50.6927035]],
    [ "Enedis",[1.5932003552894216, 50.7073996986028]],
    [ "Enedis",[1.6034236771781496, 50.70246156567865]],
    [ "Enedis",[1.5954624072039079, 50.70737344749695]],
    [ "Enedis",[1.608051777625263, 50.70693228495224]],
    [ "Enedis",[1.5897940000000002, 50.7102495]],
    [ "Enedis",[1.5772853755197478, 50.69285813661531]],
    [ "Enedis",[1.6023534, 50.69128714999999]],
    [ "Enedis",[1.58649424993739, 50.70595514300024]],
    [ "Enedis",[1.5833804200097772, 50.70677826596164]],
    [ "Enedis",[1.588125622119815, 50.69903019508448]],
    [ "Enedis",[1.6042821772053084, 50.692032710382506]],
    [ "Enedis",[1.5981348421146535, 50.69125462039192]],
    [ "Enedis",[1.5979435423154322, 50.707851636055494]],
    [ "Enedis",[1.5962710999999994, 50.70208605]],
    [ "Enedis",[1.5926220500000001, 50.700715100000004]],
    [ "Enedis",[1.6024464135588183, 50.69884037508614]],
    [ "Enedis",[1.5942164905050502, 50.709738503164985]],
    [ "Enedis",[1.5861891391930678, 50.69765473004221]],
    [ "Enedis",[1.5768272838311208, 50.699224901087774]],
    [ "Enedis",[1.6004297777777767, 50.7093712510288]],
    [ "Enedis",[1.5920600500000002, 50.6990774]],
    [ "Enedis",[1.5809964602159414, 50.69845732886322]],
    [ "Enedis",[1.5922221473769154, 50.71366053268532]],
    [ "Enedis",[1.5879914995911693, 50.70918667756882]],
    [ "Enedis",[1.6025650153219213, 50.70373612828117]],
    [ "Poste électrique de Saint-Avold",[6.7036502444330734, 49.138535647845565]],
    [ "Poste électrique de Dechy",[3.1389567442739414, 50.36252823325239]],
    [ "Poste électrique de Dechy",[3.138342853635416, 50.36130933913802]],
    [ "Verrey",[4.685177587091987, 47.4320122630216]],
    [ "RTE",[4.791765982260518, 47.583405336207306]],
    [ "RTE",[4.223743844872479, 47.274001396741795]],
    [ "RTE",[4.678984342577696, 47.18432356115331]],
    [ "RTE",[4.482809326727107, 47.12017138026315]],
    [ "Poste électrique de Montbron",[0.49332514652633025, 45.67356330602582]],
    [ "129565379",[-1.553555, 47.2889475]],
    [ "SNCF Réseau",[2.390674212056655, 50.994913458514134]],
    [ "RTE",[2.463650807443901, 51.050792974759474]],
    [ "Poste électrique de Foutein",[2.2952407313917296, 50.99019165619291]],
    [ "Poste électrique de Holque",[2.211643906165633, 50.85078290846656]],
    [ "Le Cimetière",[5.093483602783048, 45.59754328589501]],
    [ "Grimaudière",[5.09050889771623, 45.58770392832847]],
    [ "Bleuet",[5.095596821124361, 45.596150990346395]],
    [ "Isoflon",[5.0925939465153975, 45.59693029443544]],
    [ "Charmeil",[5.094471999999999, 45.5938975]],
    [ "SNCF Réseau",[2.1951821607727298, 50.844772500721156]],
    [ "Poste électrique d’Hénin",[2.9809714669049603, 50.41833251701767]],
    [ "RTE",[2.9556962380586658, 50.471051064519145]],
    [ "RTE",[4.994431861747209, 45.581527344415925]],
    [ "Poste électrique de Famars",[3.5057224771441056, 50.311624764413985]],
    [ "Poste électrique de Mohon",[4.736868234723379, 49.752781764469]],
    [ "Enedis",[5.580915488461121, 45.092209418885794]],
    [ "Poste électrique de Lumes",[4.7962340685638924, 49.74808632112499]],
    [ "Enedis",[0.6381124007212775, 45.8507140703761]],
    [ "RTE",[6.79849919188061, 47.32238430348814]],
    [ "RTE",[6.7695533557943754, 47.380070487592]],
    [ "Poste source de Tilliers",[2.3065884819818336, 48.946403667860245]],
    [ "RTE",[6.758521109088216, 47.33809507192195]],
    [ "Enedis",[4.2680774999999995, 44.388608500000004]],
    [ "Poste électrique de Fouscais",[3.457538901867532, 43.60648611753509]],
    [ "RTE",[3.313524267009139, 43.72328095729875]],
    [ "Enedis",[3.5136011844074484, 43.66043191675921]],
    [ "RTE",[6.930450852430196, 47.339523284289704]],
    [ "RTE",[6.7702202784739205, 47.48617308123533]],
    [ "Enedis",[-2.4812035954868166, 47.406044941517415]],
    [ "Enedis",[-2.471593336701591, 47.40011064521798]],
    [ "Enedis",[-0.4276755535976511, 48.54417962041116]],
    [ "Enedis",[-0.41608149999999955, 48.54927500000001]],
    [ "Enedis",[-0.4225753999999999, 48.549828500000004]],
    [ "Enedis",[-0.4230718956043957, 48.545143912087916]],
    [ "Enedis",[-0.42678399999999994, 48.548692]],
    [ "Enedis",[-0.42315301156846924, 48.56014633118106]],
    [ "Enedis",[-0.4279770844645548, 48.553500309200594]],
    [ "Enedis",[-0.4061721833226033, 48.550052642566236]],
    [ "Enedis",[-0.4102549983798045, 48.554540001228155]],
    [ "RTE",[6.684576974763075, 47.453945011209605]],
    [ "RTE",[6.581329443831724, 47.4379873268097]],
    [ "RTE",[0.22799511862135824, 49.49400109441818]],
    [ "Enedis",[6.10153205624951, 48.603435205986344]],
    [ "Enedis",[6.10252130676665, 48.59945323729691]],
    [ "Enedis",[6.096492370554177, 48.60376009511994]],
    [ "Enedis",[6.099261584649122, 48.600532212719294]],
    [ "Enedis",[6.096154455489287, 48.605534878727624]],
    [ "Enedis",[6.10356988290778, 48.59713125851644]],
    [ "Enedis",[1.711723561712847, 50.83311829303107]],
    [ "Enedis",[1.6953780957701066, 50.83923258436316]],
    [ "RTE",[6.464648164204375, 47.50588370649466]],
    [ "RTE",[6.785378903588492, 47.247822224682864]],
    [ "RTE",[6.873107822528381, 47.18736250534241]],
    [ "Poste électrique de Balaruc",[3.706113015408195, 43.44586258661979]],
    [ "RTE",[6.639586465653495, 47.08558172541133]],
    [ "Delle",[6.992931422488203, 47.501461934649136]],
    [ "Enedis",[1.7340169999999988, 50.6484635]],
    [ "Enedis",[1.7397513029119893, 50.63602886285994]],
    [ "Enedis",[1.743763389882788, 50.6378831704709]],
    [ "Enedis",[1.7541092016806723, 50.6411275470188]],
    [ "Enedis",[1.751297910966341, 50.64163590662323]],
    [ "Enedis",[1.732867982988505, 50.6382067245977]],
    [ "Enedis",[1.7345926907025893, 50.644022537902806]],
    [ "Enedis",[1.7475370000000003, 50.640874000000004]],
    [ "Enedis",[1.7465086071784643, 50.638883308740446]],
    [ "Enedis",[1.743160122089364, 50.63540828340676]],
    [ "Enedis",[1.7543230487804875, 50.642912308943096]],
    [ "Enedis",[1.7496532321494547, 50.63498624752025]],
    [ "Enedis",[1.7530400000000002, 50.64540099999999]],
    [ "Enedis",[1.7462380248833593, 50.63250645930535]],
    [ "Enedis",[2.3529512437508417, 48.603379851339724]],
    [ "Enedis",[2.353088456411197, 48.6033815763138]],
    [ "Enedis",[2.353051000000001, 48.603719999999996]],
    [ "Enedis",[6.128188975983065, 48.773981345816814]],
    [ "Enedis",[6.10707193234148, 48.76240789670564]],
    [ "Enedis",[6.127435353672317, 48.77013185178908]],
    [ "Enedis",[6.129437439220591, 48.775556079090734]],
    [ "Enedis",[6.125174809729341, 48.77030383526661]],
    [ "Enedis",[6.136804931350115, 48.78009077726925]],
    [ "Enedis",[6.1151715107415106, 48.763061416493414]],
    [ "Enedis",[6.135151899999999, 48.7775131]],
    [ "Enedis",[6.123668718847352, 48.76788413989486]],
    [ "Enedis",[6.110636902968616, 48.76306567268872]],
    [ "Enedis",[6.124997849510075, 48.76500253041227]],
    [ "Enedis",[6.131161702085526, 48.7793458281498]],
    [ "Enedis",[6.120856866784975, 48.764814157054126]],
    [ "Enedis",[6.021720050679969, 48.71527461645411]],
    [ "Enedis",[6.001957258155804, 48.740353082061894]],
    [ "Enedis",[6.102866249999999, 48.8207335]],
    [ "Enedis",[6.094313949341439, 48.81977227963526]],
    [ "Poste électrique de Rousson",[3.2784607817759133, 48.09467553969052]],
    [ "Sous-station de Villeneuve-sur-Yonne",[3.2789341864182524, 48.09585090060353]],
    [ "Enedis",[0.5872659384079234, 44.39777645490747]],
    [ "Enedis",[0.5843970000000015, 44.39924300000001]],
    [ "Sous-station de Carisey",[3.831285995525972, 47.916891001718]],
    [ "SNCF Réseau",[3.810525660435061, 43.50914880053804]],
    [ "Enedis",[6.51028463438927, 47.68699448224447]],
    [ "RTE",[3.752190414040667, 43.43625123050055]],
    [ "Poste électrique de Île-Napoléon",[7.382439813959529, 47.7690930926847]],
    [ "Enedis",[6.5015763769461605, 47.68918790906502]],
    [ "Gardettes",[7.116784851806738, 43.70333072919204]],
    [ "Enedis",[-1.7027383144815766, 48.01392751499572]],
    [ "Poste de transformation HTA/BT",[7.639986220784475, 48.95578986345869]],
    [ "Poste de transformation HTA/BT",[7.651857307240705, 48.94947806196999]],
    [ "Poste de transformation HTA/BT",[7.6474317021947735, 48.94513513358156]],
    [ "Poste de transformation HTA/BT",[7.637377557758843, 48.949393666537574]],
    [ "Poste de transformation HTA/BT",[7.632167830106912, 48.95247408158715]],
    [ "Poste de transformation HTA/BT",[7.651622, 48.962794499999994]],
    [ "Poste de transformation HTA/BT",[7.6556952993197305, 48.948233026010406]],
    [ "Poste de transformation HTA/BT",[7.641666116477272, 48.95099121309524]],
    [ "Poste de transformation HTA/BT",[7.630539500000001, 48.957426999999996]],
    [ "Poste de transformation HTA/BT",[7.642299762790696, 48.95806378996808]],
    [ "Poste de transformation HTA/BT",[7.647292644972884, 48.94893820191906]],
    [ "Poste de transformation HTA/BT",[7.642397692701069, 48.95943892840539]],
    [ "Poste de transformation client HTA/BT",[7.62959586317984, 48.96317619880525]],
    [ "Poste de transformation HTA/BT",[7.653470837606838, 48.94381294301994]],
    [ "La Régie d'électricité et de téléservices de Niederbronn-Reichshoffen",[7.6446984605597965, 48.95704929707379]],
    [ "Poste de transformation HTA/BT",[7.644313141022544, 48.96017380835548]],
    [ "Poste électrique de La Madeleine",[4.646930681463675, 45.54021603509302]],
    [ "Enedis",[3.2909365, 49.86170090000001]],
    [ "Tiron",[3.294115111226204, 49.83523762652192]],
    [ "Enedis",[3.3091220000000003, 49.83736299999999]],
    [ "Moustoir",[-3.1887485000000004, 47.705472]],
    [ "Enedis",[3.2751548230912477, 49.85702153072626]],
    [ "Enedis",[3.2599882380952385, 49.84075165201466]],
    [ "Enedis",[3.2986485293276124, 49.8330003404864]],
    [ "Enedis",[3.3215483526137133, 49.83235561289884]],
    [ "Enedis",[3.282011766707667, 49.850608329233296]],
    [ "Enedis",[3.2895690253411307, 49.86026271734893]],
    [ "Enedis",[3.2662562383530758, 49.85883176379019]],
    [ "Enedis",[3.3030545, 49.863345]],
    [ "Enedis",[3.304428417415959, 49.834354762980965]],
    [ "Enedis",[3.31323451632653, 49.83279042721088]],
    [ "Enedis",[3.260139238888214, 49.83626644283052]],
    [ "Enedis",[3.2855717932175357, 49.84871743341605]],
    [ "Enedis",[3.283076532577904, 49.83676088731509]],
    [ "Enedis",[3.3060739173895075, 49.83010432672449]],
    [ "Enedis",[3.2709415600811487, 49.847440185237204]],
    [ "Enedis",[3.297251843634117, 49.859587065925005]],
    [ "Enedis",[3.27800325093633, 49.84482361423222]],
    [ "Enedis",[3.2869276068459654, 49.839185453463735]],
    [ "Enedis",[3.261300887129073, 49.84191271764063]],
    [ "Enedis",[3.2674547447916664, 49.85108311979167]],
    [ "Enedis",[3.2760723991416305, 49.850727198855516]],
    [ "Enedis",[3.276685740054077, 49.83623752813184]],
    [ "Enedis",[3.2952007384740925, 49.846110975520205]],
    [ "Enedis",[3.2964520294554784, 49.855196166193764]],
    [ "Enedis",[3.2708342503982157, 49.85565390432197]],
    [ "Enedis",[3.308753939155391, 49.85737846100861]],
    [ "Enedis",[0.2373911291172597, 49.14675475845411]],
    [ "Enedis",[0.23173065719360575, 49.14729273771463]],
    [ "Enedis",[0.22494649999999994, 49.1314735]],
    [ "Enedis",[0.22459641038082676, 49.136491531245404]],
    [ "Enedis",[0.22346564995581236, 49.139946806700415]],
    [ "Enedis",[0.2580617167113495, 49.14819739142091]],
    [ "Enedis",[0.2339097053057748, 49.14662914312096]],
    [ "Enedis",[0.23215119449128677, 49.13186355874086]],
    [ "Enedis",[0.2351985590179414, 49.14061499527856]],
    [ "Enedis",[0.251613679076983, 49.14665533759938]],
    [ "Enedis",[0.22949516561844913, 49.14054196960168]],
    [ "130316158",[0.22108953720508204, 49.14817698971566]],
    [ "Enedis",[0.23033331337938068, 49.13405165822461]],
    [ "Enedis",[0.22323052636282417, 49.14388551027703]],
    [ "Enedis",[0.27845952868217055, 49.14355307342193]],
    [ "Enedis",[0.22911056584659895, 49.1377112826821]],
    [ "Enedis",[0.23978499999999933, 49.14278]],
    [ "Enedis",[0.2358392609123323, 49.13797315381778]],
    [ "Enedis",[0.274959, 49.14435149999999]],
    [ "Enedis",[0.24131222312994857, 49.142782958301076]],
    [ "Enedis",[0.26957800000000004, 49.1472435]],
    [ "Enedis",[0.22987900000000003, 49.135906999999996]],
    [ "Enedis",[0.24721011475409865, 49.14566829872495]],
    [ "Enedis",[0.2699067429968045, 49.145263584508356]],
    [ "Enedis",[0.252378685685687, 49.15211101501502]],
    [ "Enedis",[0.2274932364665145, 49.138519149649525]],
    [ "Enedis",[0.25915000000000005, 49.149263]],
    [ "Enedis",[0.22227915893492095, 49.144360357893]],
    [ "Poste électrique de Millery",[4.771440066450436, 45.64837505416511]],
    [ "Enedis",[0.700682495772621, 44.39158433153444]],
    [ "Enedis",[0.685988032064127, 44.39117857381429]],
    [ "Enedis",[0.690927526772055, 44.38620418035016]],
    [ "Enedis",[0.6968373443223438, 44.39200515604395]],
    [ "Enedis",[0.6968656085182355, 44.3856569020658]],
    [ "Enedis",[0.700282038701623, 44.38911405617978]],
    [ "Enedis",[0.6893788558897246, 44.39165576754385]],
    [ "Enedis",[0.6815005273132653, 44.38763619732441]],
    [ "Enedis",[0.6767051071647268, 44.391875939987756]],
    [ "Poste électrique de Chariez",[6.067413999353831, 47.62561411266038]],
    [ "Poste électrique de Renaucourt",[5.778087820310539, 47.64507766459481]],
    [ "SICAE-Est",[5.766316407414987, 47.81187903817716]],
    [ "Enedis",[0.17120249999999998, 49.136562500000004]],
    [ "Enedis",[0.19935852140482094, 49.13002879343309]],
    [ "Enedis",[0.20466832098765433, 49.13280757201647]],
    [ "Enedis",[0.19755683715846997, 49.12793252459016]],
    [ "Enedis",[0.19947416845878138, 49.13131799283155]],
    [ "Poste électrique de Livière",[2.9816884871255915, 43.19682740317278]],
    [ "Enedis",[4.665036473244047, 45.82058619327381]],
    [ "Gare",[4.667974569699337, 45.82104316113497]],
    [ "Enedis",[4.672028547910864, 45.82651365338904]],
    [ "Enedis",[4.690544045126774, 45.816333482786696]],
    [ "RTE",[2.914235783906709, 43.285749495569]],
    [ "Poste électrique de Castirla",[9.133272783550604, 42.38035754445917]],
    [ "Enedis",[3.1585626146512413, 46.98926651198887]],
    [ "RTE",[3.2513276139221783, 43.44306577828636]],
    [ "RTE",[3.2312131770009844, 43.330384808822565]],
    [ "RTE",[3.128106361260401, 43.29427084477835]],
    [ "Poste électrique du Soleil",[4.408672269629131, 45.46162054945643]],
    [ "Sous-Station de Terrenoire",[4.455565741688593, 45.439056251420645]],
    [ "Vias",[3.4274665608302395, 43.31686427829305]],
    [ "Poste électrique de Jacquard",[4.376053827429727, 45.4439074628893]],
    [ "RTE",[4.284669706589708, 45.37905655512963]],
    [ "RTE",[4.31040354999812, 45.39013476680063]],
    [ "Salvador Allende",[5.134613, 45.63574350000001]],
    [ "Hôpital",[5.14743631731781, 45.63174723422011]],
    [ "EDF la Verpillière",[5.156357, 45.63280724999999]],
    [ "Les Loipes",[5.134692457250235, 45.64186882451196]],
    [ "Chapeau Rouge",[5.13096075, 45.63716]],
    [ "Vigne 2",[5.132194343258893, 45.63505597146402]],
    [ "Salle des Fêtes",[5.147859191215823, 45.633225724549156]],
    [ "Enedis",[5.137165830876733, 45.634115523092035]],
    [ "Paquerettes",[5.1387381520236906, 45.63695922277065]],
    [ "Le Vérin",[5.146409851387305, 45.63150465640442]],
    [ "Les Vignes n°1",[5.134940171758317, 45.635009464358454]],
    [ "Grand Planot",[5.15622975, 45.63287100000001]],
    [ "Enedis",[5.135021000732063, 45.63895172742801]],
    [ "Gendarmerie",[5.145265276670165, 45.63882655823715]],
    [ "Danet",[5.142014700000001, 45.64063985000001]],
    [ "Illiates",[5.137212000000001, 45.644291]],
    [ "Enedis",[5.145198499409015, 45.633838730122164]],
    [ "Poste électrique de Florensac",[3.4815509880752646, 43.38642905037342]],
    [ "RTE",[3.5064508845165006, 43.30849812267685]],
    [ "SNCF Réseau",[3.5685025, 43.3358144]],
    [ "RTE",[3.6067278938054943, 43.465318804165044]],
    [ "RTE",[3.410677182488278, 43.44851314690123]],
    [ "Enedis",[3.083218608090067, 47.02607321060874]],
    [ "Enedis",[3.088669768915865, 47.0182253760892]],
    [ "Enedis",[3.0927236826902407, 47.01128392597459]],
    [ "Enedis",[3.082984779986595, 47.02042043572319]],
    [ "Enedis",[3.081273299204017, 47.012659799209366]],
    [ "Enedis",[3.078929996687486, 47.02925166765348]],
    [ "Enedis",[3.0834277500000002, 47.01462725000001]],
    [ "Enedis",[3.079647649172254, 47.022793970575066]],
    [ "Enedis",[3.0895730634794947, 47.01143449346007]],
    [ "Enedis",[3.088302622843432, 47.023672272072005]],
    [ "Enedis",[3.0871504871074666, 47.01578488256495]],
    [ "Enedis",[3.0810843313192726, 47.019806110896454]],
    [ "Enedis",[3.0842684093626347, 47.0236168351589]],
    [ "Poste électrique de Trevas",[4.164836085457508, 45.24445179982497]],
    [ "Enedis",[6.163582390170164, 48.76314373792866]],
    [ "Enedis",[6.157078999999999, 48.74637]],
    [ "Enedis",[6.156715999999999, 48.754364499999994]],
    [ "Enedis",[6.1538044833333325, 48.7594343]],
    [ "Enedis",[6.175312046677471, 48.75524868730415]],
    [ "Enedis",[6.165251394366199, 48.75536414777554]],
    [ "Enedis",[6.1617104128336155, 48.74845289778535]],
    [ "Enedis",[6.163356108411431, 48.758851302556984]],
    [ "Enedis",[6.1723886615956856, 48.75147743577301]],
    [ "Enedis",[6.155239934479932, 48.756671189189184]],
    [ "Enedis",[6.158775638838002, 48.75894118250022]],
    [ "Enedis",[6.1666600438596495, 48.74774710025063]],
    [ "Enedis",[6.162513026948907, 48.760813853863816]],
    [ "Enedis",[6.158716453442113, 48.75605057487697]],
    [ "Enedis",[6.163701870805961, 48.75365160573097]],
    [ "Enedis",[-1.4599542980392155, 43.59396644705881]],
    [ "Enedis",[-1.431868749298409, 43.59453559089492]],
    [ "Enedis",[-1.4311845, 43.59753499999999]],
    [ "Enedis",[6.700142450365427, 48.00677878333011]],
    [ "Enedis",[-1.455065, 43.594625]],
    [ "Enedis",[-1.4264397959183674, 43.59198244531659]],
    [ "Enedis",[-1.43207275, 43.590497150000004]],
    [ "Enedis",[-1.4476065978281398, 43.58794543966007]],
    [ "Enedis",[-1.4369930500000008, 43.57818249999999]],
    [ "Enedis",[-1.4274250000000004, 43.59324449999999]],
    [ "Enedis",[-1.4390218, 43.5906954]],
    [ "Enedis",[-1.4304124710860362, 43.59281849835448]],
    [ "Enedis",[2.4109978768197076, 50.88800627584919]],
    [ "Enedis",[2.4324236395854126, 50.89126104510391]],
    [ "Enedis",[2.4281423981215253, 50.88786877630823]],
    [ "Enedis",[2.4205540792125606, 50.888841527927504]],
    [ "Enedis",[4.24330410684502, 45.334365434042056]],
    [ "réséda",[6.362662000000001, 49.233790500000005]],
    [ "réséda",[6.333552514193893, 49.221967332262096]],
    [ "Poste électrique de St-Just-sur-Loire",[4.262135955049587, 45.49162551511471]],
    [ "RTE",[4.287668150221405, 45.39377876987126]],
    [ "RTE",[4.286631148749505, 45.39309076791163]],
    [ "RTE",[4.273969951261754, 45.39119795069397]],
    [ "Enedis",[6.414031, 49.22448975000001]],
    [ "RTE",[4.306814205464635, 45.550468151378396]],
    [ "Enedis",[6.151476846354165, 48.742314369791664]],
    [ "Enedis",[6.118423999999999, 48.760648499999995]],
    [ "Enedis",[6.127906883270576, 48.760242719383186]],
    [ "Enedis",[6.108790607108549, 48.757195641690686]],
    [ "Enedis",[6.134772221173761, 48.75229145216724]],
    [ "Enedis",[6.112592548812664, 48.758745969803584]],
    [ "Enedis",[6.148831599999999, 48.74253005]],
    [ "Enedis",[6.159236702113156, 48.74462093796864]],
    [ "Enedis",[6.104088999999998, 48.7568065]],
    [ "Enedis",[6.142052837579126, 48.75380997915701]],
    [ "Enedis",[6.142879800000001, 48.741998200000005]],
    [ "Enedis",[6.132968242384106, 48.750650773509925]],
    [ "Enedis",[6.145917552511416, 48.75128132420092]],
    [ "Enedis",[6.134514670120897, 48.75471465284973]],
    [ "Enedis",[6.1471288745810515, 48.74803105153475]],
    [ "Enedis",[6.136931459119496, 48.75378833333333]],
    [ "Enedis",[6.105263408648648, 48.7587298184985]],
    [ "Enedis",[6.1116216188226025, 48.75773286079938]],
    [ "Enedis",[6.126250166854354, 48.76152311955705]],
    [ "Enedis",[6.123776133887297, 48.75869522048584]],
    [ "Enedis",[6.138853243589743, 48.75619262820512]],
    [ "Enedis",[6.132823006791849, 48.75647535397523]],
    [ "Enedis",[6.133315531016001, 48.7619787061559]],
    [ "Enedis",[6.131194992664222, 48.75295138557298]],
    [ "Enedis",[6.130783666666666, 48.758035151515145]],
    [ "Enedis",[6.127796224089636, 48.764305338935564]],
    [ "Enedis",[6.148955230414746, 48.74724951459294]],
    [ "Enedis",[6.1457503688765245, 48.75285585149127]],
    [ "Enedis",[6.123051824252268, 48.76057754824739]],
    [ "Enedis",[6.149014757880436, 48.74856530751812]],
    [ "Enedis",[6.133842031708596, 48.759232979887315]],
    [ "Enedis",[2.4288622999999987, 50.95193979999999]],
    [ "Enedis",[2.4258150000000005, 50.952079]],
    [ "La Motte",[4.8273777629670285, 43.989210999091775]],
    [ "Cosarde",[2.332938631436314, 48.778720184552846]],
    [ "Enedis",[2.3949385000000007, 47.069232500000005]],
    [ "Enedis",[2.3827548313656046, 47.074621018890205]],
    [ "Enedis",[2.3683695955555555, 47.075906106868686]],
    [ "Enedis",[2.3583316316626886, 47.05824081543358]],
    [ "Enedis",[2.3538957619047625, 47.05645182539682]],
    [ "Enedis",[2.374504387075172, 47.08460013644734]],
    [ "Enedis",[2.390484039708266, 47.07098697541869]],
    [ "Enedis",[2.368513, 47.079862000000006]],
    [ "Enedis",[2.3779833333333333, 47.07339181818182]],
    [ "Enedis",[2.3610219986225887, 47.08027955647382]],
    [ "Enedis",[2.3637863743842367, 47.06555760426929]],
    [ "Enedis",[2.379454666554861, 47.08448694341746]],
    [ "Enedis",[2.3866562216740244, 47.06572144230935]],
    [ "Enedis",[2.3644075137111513, 47.08184131931749]],
    [ "Enedis",[2.368473909090908, 47.078312406478574]],
    [ "Enedis",[2.3430153300760046, 47.044023992399566]],
    [ "Enedis",[2.387862715104615, 47.06220670879909]],
    [ "Enedis",[2.37043245, 47.06711049999999]],
    [ "Enedis",[2.358549526368779, 47.082769190114824]],
    [ "Enedis",[2.374411237073099, 47.07314314885299]],
    [ "Enedis",[2.3902430050987897, 47.068044596558316]],
    [ "Enedis",[2.3829785622394954, 47.07185966858172]],
    [ "Enedis",[2.3613588570756936, 47.07796485801598]],
    [ "Enedis",[2.3792529155930695, 47.07257260832223]],
    [ "Enedis",[2.3753761421722843, 47.08350781498127]],
    [ "Enedis",[2.389850861271676, 47.07453452023121]],
    [ "Enedis",[4.085645999999999, 44.122102500000004]],
    [ "Enedis",[4.1006143999999995, 44.132169851851856]],
    [ "Enedis",[4.094286115818001, 44.13404275067751]],
    [ "Enedis",[4.084155463462291, 44.12204504259476]],
    [ "Enedis",[4.076386388721047, 44.116620727760996]],
    [ "Enedis",[4.08519679109063, 44.12658770660522]],
    [ "Enedis",[4.087858270328719, 44.13542616205306]],
    [ "Enedis",[4.082223660464445, 44.14836133275638]],
    [ "Enedis",[4.09298746810117, 44.13350984786712]],
    [ "Enedis",[4.085568237732011, 44.139862414357154]],
    [ "Enedis",[4.081635302964571, 44.130077368763565]],
    [ "Enedis",[4.087627964558099, 44.146084321668916]],
    [ "Enedis",[4.0812565, 44.11078999999999]],
    [ "Enedis",[4.0979775, 44.13962050000001]],
    [ "Enedis",[4.069876, 44.125109764227645]],
    [ "Enedis",[4.0921555, 44.114556500000006]],
    [ "Enedis",[4.093545524796862, 44.136561681796955]],
    [ "Enedis",[4.079360083377217, 44.13012852483764]],
    [ "Enedis",[4.058963833975842, 44.12245449601645]],
    [ "Enedis",[4.09761726039853, 44.13186070148964]],
    [ "Enedis",[4.071990433210579, 44.13099732485213]],
    [ "Enedis",[4.090045555095278, 44.13556269953051]],
    [ "Enedis",[4.0890485, 44.1253115]],
    [ "Enedis",[4.085260756853703, 44.1229878578017]],
    [ "Enedis",[4.0733858962075855, 44.117210727744514]],
    [ "Enedis",[4.074667250980392, 44.10833055686275]],
    [ "Enedis",[4.076741815476191, 44.119950889880954]],
    [ "Enedis",[4.081108479655401, 44.12763116514248]],
    [ "Enedis",[4.0905627177024995, 44.124543592958126]],
    [ "Enedis",[4.067549069557362, 44.11957767931347]],
    [ "Enedis",[4.087318395397765, 44.12682523721236]],
    [ "Enedis",[4.0894346710349465, 44.1299200611559]],
    [ "Enedis",[4.091953798041521, 44.13374052863298]],
    [ "Enedis",[4.081607633, 44.13756864133334]],
    [ "Enedis",[4.08313010317024, 44.117096054792015]],
    [ "Enedis",[4.082203217125382, 44.10694105810398]],
    [ "Enedis",[4.0688010209424075, 44.131044382198944]],
    [ "Enedis",[4.099363692623493, 44.13082282730764]],
    [ "Enedis",[4.102883231936127, 44.14329646719893]],
    [ "Enedis",[4.076077354541649, 44.13466395953677]],
    [ "Enedis",[4.091309722422896, 44.1313024211355]],
    [ "Enedis",[4.0772642626613695, 44.12797986362133]],
    [ "RTE",[4.225239663170079, 45.23358666648082]],
    [ "RTE",[4.3370385035272845, 45.215338309304855]],
    [ "réséda",[6.251005085094568, 49.195437566198365]],
    [ "Andagne",[7.024678430278885, 45.33933481938911]],
    [ "Chaudane",[6.9924849500000015, 45.31871]],
    [ "Les Choseaux",[6.432352288914198, 45.17313849635535]],
    [ "Les Granges",[6.434646474261603, 45.1759559814346]],
    [ "Enedis",[4.140758059038765, 45.30247346147038]],
    [ "RTE",[4.374770422918675, 45.63516823206815]],
    [ "Enedis",[1.3357024471544716, 47.581010739837396]],
    [ "Enedis",[3.45179650012086, 46.82073544210781]],
    [ "Enedis",[3.463283856577086, 46.82940154247997]],
    [ "Enedis",[3.4674884438457863, 46.82596327933429]],
    [ "Enedis",[3.4746884307944303, 46.82787525880426]],
    [ "Enedis",[3.4536699999999994, 46.82710349999999]],
    [ "Enedis",[3.4509625144557816, 46.82436719302721]],
    [ "Enedis",[3.4557294294169503, 46.82665168102255]],
    [ "Enedis",[3.4457178045605112, 46.82760465749943]],
    [ "Enedis",[3.4691095806451617, 46.840727333333334]],
    [ "Enedis",[3.470451214239482, 46.824127507443364]],
    [ "Enedis",[3.46632335483871, 46.83689421505376]],
    [ "Enedis",[3.4662739241005935, 46.83146209701834]],
    [ "Enedis",[3.4579707661691548, 46.8333881840796]],
    [ "Enedis",[3.470154634218289, 46.82862482300885]],
    [ "Enedis",[3.459544856403144, 46.82918082015719]],
    [ "Enedis",[3.453280977466977, 46.82970740792541]],
    [ "Enedis",[3.4690902724302877, 46.83604943740521]],
    [ "Enedis",[3.4503768898335108, 46.82744869783917]],
    [ "Enedis",[3.463483181300813, 46.82745705528455]],
    [ "Enedis",[3.4528637321428572, 46.82456093452382]],
    [ "Enedis",[3.445899593656448, 46.825620339583594]],
    [ "Enedis",[3.473378080083857, 46.82924070020965]],
    [ "Les Vernes",[6.773483027433995, 45.61623399143931]],
    [ "Enedis",[6.765160158368896, 45.61955054860123]],
    [ "Mollard",[6.780606734358224, 45.623680674383486]],
    [ "Enedis",[6.765606228321994, 45.61302090521505]],
    [ "Enedis",[6.785506174270073, 45.622273988442814]],
    [ "Abattoirs",[6.77264625, 45.611033250000006]],
    [ "Enedis",[6.776368657445827, 45.620936987090815]],
    [ "Enedis",[6.777364038451339, 45.6224071487669]],
    [ "Enedis",[6.769643663986278, 45.608687907901796]],
    [ "Olympiades",[6.776083974503051, 45.62436959453705]],
    [ "Les Chapieux",[6.733137501557401, 45.694565768551506]],
    [ "Enedis",[6.781817702475954, 45.5789386558047]],
    [ "Enedis",[2.165447116983869, 48.56749410100277]],
    [ "Enedis",[2.155080733906992, 48.559580933788126]],
    [ "RTE",[4.236400616053976, 45.73186401625562]],
    [ "Enedis",[6.535196449275362, 49.196368391304354]],
    [ "Poste électrique d'Annemasse",[6.233114240323346, 46.18362810149462]],
    [ "Enedis",[6.909264677390582, 45.289332615704964]],
    [ "Enedis",[6.91385735, 45.29143699999999]],
    [ "Enedis",[6.9112947, 45.289609]],
    [ "Le Mollaret",[6.912887950000001, 45.29207535]],
    [ "Enedis",[6.914639779402804, 45.28964547242535]],
    [ "Enedis",[6.886255499999999, 45.28639315]],
    [ "Enedis",[6.876463577172504, 45.285833693904024]],
    [ "Enedis",[6.891346595537835, 45.28501352308934]],
    [ "Enedis",[6.880653954922896, 45.285623083036775]],
    [ "Enedis",[6.893013361863059, 45.286224059713376]],
    [ "Enedis",[6.875196373944402, 45.28457487472909]],
    [ "Enedis",[6.6030127999999975, 48.09269015]],
    [ "Enedis",[2.4928285740953853, 50.911109318757184]],
    [ "Enedis",[2.461323279660398, 49.202491315617394]],
    [ "Poste électrique de Vertefeuille",[3.03714957923499, 50.67644363505266]],
    [ "Enedis",[0.20256267455366725, 45.970840831433286]],
    [ "RTE",[4.273664539564006, 45.83307778556054]],
    [ "Enedis",[2.481473792730969, 48.56787684712162]],
    [ "Enedis",[2.4864544706827307, 48.562047157429724]],
    [ "Enedis",[2.486110690055962, 48.564388146362454]],
    [ "Enedis",[2.5064638417508416, 48.56736003367003]],
    [ "Enedis",[2.4814549910235058, 48.56589894225431]],
    [ "Enedis",[2.496817267489712, 48.56259306584363]],
    [ "Enedis",[2.48288344, 48.56536811333333]],
    [ "Enedis",[2.4923456045679404, 48.56122930017893]],
    [ "Enedis",[2.478953034188034, 48.566862170940176]],
    [ "Enedis",[2.4942692942528737, 48.56360054252874]],
    [ "Enedis",[2.4879064596705716, 48.567552245712335]],
    [ "RTE",[3.4937664219327282, 43.51199132437136]],
    [ "Enedis",[2.1913908, 48.94705724999999]],
    [ "Enedis",[2.1882816462378987, 48.94430976941943]],
    [ "Enedis",[2.165107768774178, 48.94637290700438]],
    [ "Saint-Lamartine F15",[2.159327064931541, 48.93726850427173]],
    [ "Enedis",[2.1865511250923992, 48.94581763475202]],
    [ "Enedis",[2.191252314253717, 48.945057964732236]],
    [ "Enedis",[2.188637154003388, 48.94716674056869]],
    [ "Enedis",[2.1701657441592026, 48.94812087854835]],
    [ "RTE",[4.318543279332459, 45.97938573835798]],
    [ "Poste Source Moingt",[4.080893620473274, 45.59588100065043]],
    [ "Enedis",[2.188167521839035, 48.915467081976054]],
    [ "Enedis",[2.1616096255696204, 48.90840057999738]],
    [ "Enedis",[2.1827422778244046, 48.910231190041]],
    [ "Enedis",[2.167537852423411, 48.91212073865032]],
    [ "Enedis",[2.1832614473409238, 48.907506456210655]],
    [ "Enedis",[2.186321656264438, 48.91354376791885]],
    [ "Enedis",[2.16460758271878, 48.910718249177386]],
    [ "Enedis",[4.2012728114654205, 45.53995614658065]],
    [ "Poste électrique de St-Bonnet-le-Château",[4.071525862380596, 45.429373110824905]],
    [ "Montverdun",[4.059751622744088, 45.71472789442723]],
    [ "RTE",[4.5017476756565715, 45.46942921054961]],
    [ "Poste électrique de Villerest",[4.050407094507558, 45.985714342454244]],
    [ "Poste électrique de Lentigny",[3.9794008392361677, 45.9878857998363]],
    [ "ZI Landes Oie",[-1.1280515, 46.802298249999986]],
    [ "RTE",[3.895606674276831, 46.14445950418351]],
    [ "Allées de Régina",[5.895226165415254, 48.69548193302519]],
    [ "Poste électrique du Péage de Vizille",[5.777734932074528, 45.057281596192404]],
    [ "RTE",[4.55288242792054, 45.492456011529654]],
    [ "Poste source de l'Horme",[4.552131354681054, 45.49100613535953]],
    [ "SNCF Réseau",[4.977059055097607, 45.85947737204095]],
    [ "La Ville",[5.550130238380811, 44.896927659170416]],
    [ "Chauveterre",[5.558311499999999, 44.901193]],
    [ "Les Perrins",[5.56700623866767, 44.89874358452291]],
    [ "Joux",[4.723810509418012, 46.00953343413207]],
    [ "Enedis",[3.3571552436578176, 49.75108189911504]],
    [ "Enedis",[3.3564676874661674, 49.74915246175308]],
    [ "Enedis",[3.359304128769357, 49.75346786878565]],
    [ "Enedis",[3.3620749111111117, 49.75456973333334]],
    [ "RTE",[4.703989439707061, 45.85750265476512]],
    [ "Enedis",[2.354563030578477, 48.97185993686784]],
    [ "Poste électrique de Sinard",[5.662574680710461, 44.95097638756165]],
    [ "RTE",[4.660179890471632, 45.866440991723145]],
    [ "Enedis",[0.07855197189695483, 46.01288399687744]],
    [ "Enedis",[2.312595825270464, 49.01642783282302]],
    [ "Enedis",[2.2980795000000005, 49.0008815]],
    [ "Enedis",[2.3110855305747124, 49.008210158620685]],
    [ "Enedis",[2.2981685, 48.99668199999999]],
    [ "Enedis",[2.3049150437481747, 49.000101396362844]],
    [ "Enedis",[2.3094737306666664, 49.008650925333335]],
    [ "Enedis",[2.295954921604938, 48.99686965452675]],
    [ "Enedis",[2.2983958250244387, 48.99896153023322]],
    [ "131838238",[4.939928439761969, 45.92363925106223]],
    [ "Enedis",[3.020537131749943, 47.18718392508775]],
    [ "Enedis",[3.025882417862839, 47.174803293460926]],
    [ "Enedis",[3.0100484078540504, 47.18911264996908]],
    [ "Enedis",[3.026766974269006, 47.172753178947374]],
    [ "Enedis",[3.0269747234436486, 47.170665532824195]],
    [ "Enedis",[3.0216389600829454, 47.177612909279425]],
    [ "Enedis",[3.1666339642529024, 47.007656081683784]],
    [ "Enedis",[3.1669687935943056, 47.009474654804265]],
    [ "Enedis",[3.176304266546149, 47.00962556854475]],
    [ "Enedis",[3.1804176818054386, 47.00139535968601]],
    [ "Enedis",[3.184823306306306, 47.00503795675676]],
    [ "Enedis",[3.175402731391586, 47.00641687055016]],
    [ "Enedis",[3.178717794695931, 47.007509330132606]],
    [ "Enedis",[3.177827435304198, 47.01403838067695]],
    [ "Enedis",[3.180485, 47.01112650000001]],
    [ "Enedis",[2.5388414755452917, 50.886651890916085]],
    [ "Enedis",[2.3705654697225795, 49.0298803218101]],
    [ "Enedis",[2.3644911827047728, 49.029402983621324]],
    [ "Gardannes",[6.283605805274373, 46.06540447987924]],
    [ "Champ Falquet",[6.285494746218629, 46.055942058699685]],
    [ "Faverges",[6.2800104999999995, 46.056662800000005]],
    [ "Pre de Charny",[6.288370283292585, 46.07694625627547]],
    [ "SNCF Réseau",[1.0735091636882177, 44.10178978315338]],
    [ "Chez le Parisien",[6.237959700000001, 46.026969400000006]],
    [ "Chez Gonin",[6.231110033333333, 46.03969196666666]],
    [ "ESSeyssel",[6.2250863, 46.03758605]],
    [ "ESSeyssel",[6.233397964214711, 46.02300173664678]],
    [ "Enedis",[2.3853143297068597, 50.93401198428528]],
    [ "Enedis",[6.1997828996521545, 46.71093126676722]],
    [ "SNCF Réseau",[4.751085171380728, 45.92214803915862]],
    [ "Enedis",[5.523306258113342, 47.19718374593799]],
    [ "Enedis",[3.229831127137547, 49.59424368599752]],
    [ "Enedis",[3.2335382550242557, 49.59286556652807]],
    [ "SNCF Réseau",[4.720194018172828, 45.97784581779576]],
    [ "Clermont-l'Hérault",[3.4362032241338483, 43.62126071878077]],
    [ "RTE",[3.1693706834977737, 43.61332922235472]],
    [ "Enedis",[5.215101379779528, 45.906797931181366]],
    [ "Les Marnelles",[5.392767050000001, 46.17799300000001]],
    [ "Clos du Tour",[6.834153318422747, 46.26772135714848]],
    [ "Enedis",[2.1951219090937295, 48.81629020704361]],
    [ "Enedis",[2.188384905709637, 48.80935261447073]],
    [ "Enedis",[2.1887610338491585, 48.8049491223057]],
    [ "Enedis",[2.1854989939542073, 48.809824644007904]],
    [ "Enedis",[2.1959054064863803, 48.79795980735004]],
    [ "Enedis",[2.198466720214995, 48.81867800878331]],
    [ "Enedis",[2.1808046805031442, 48.809750676729564]],
    [ "Enedis",[2.1893316010670962, 48.81416801699343]],
    [ "Enedis",[2.1877389830515104, 48.79739342588603]],
    [ "Enedis",[2.1869461996503174, 48.81313029269041]],
    [ "Enedis",[2.191728386730573, 48.80790974682069]],
    [ "Enedis",[2.187575580556481, 48.811535127885705]],
    [ "Enedis",[2.1908874026156786, 48.810964146505235]],
    [ "Enedis",[2.1877682972333194, 48.80124861038686]],
    [ "Enedis",[1.5668321441013455, 50.419053230403804]],
    [ "Enedis",[1.5604842639593923, 50.405640184433175]],
    [ "Enedis",[1.5756243553699854, 50.405637203665044]],
    [ "Enedis",[1.5706128325148354, 50.422556037650914]],
    [ "Enedis",[1.5936029999999999, 50.4117285]],
    [ "Enedis",[1.5806450938362462, 50.415185289175106]],
    [ "Enedis",[1.5699565000000006, 50.41383449999999]],
    [ "Enedis",[1.5936351438484122, 50.40724127088337]],
    [ "Enedis",[1.580920297116181, 50.40939515443115]],
    [ "Enedis",[1.583242603674541, 50.41106421259843]],
    [ "Enedis",[1.5992591833174756, 50.40173253409451]],
    [ "Enedis",[1.5868136467661695, 50.41476155538099]],
    [ "Enedis",[1.5717244721141361, 50.41307651491569]],
    [ "Enedis",[1.5629151968850519, 50.41026792517352]],
    [ "Enedis",[1.5698933606370873, 50.406481717861205]],
    [ "Enedis",[1.5704315000000004, 50.417615999999995]],
    [ "Enedis",[1.5864038775270333, 50.4083388041843]],
    [ "Enedis",[1.5666299999999997, 50.41599949999999]],
    [ "Enedis",[1.5657921857895631, 50.40443813173551]],
    [ "Enedis",[1.5734394999999999, 50.411329999999985]],
    [ "Enedis",[1.6004264999999998, 50.409889]],
    [ "Enedis",[1.5688636340082491, 50.40416034493438]],
    [ "Enedis",[1.5909382379533041, 50.41410694436165]],
    [ "Enedis",[1.5744837321645977, 50.41015697796445]],
    [ "Enedis",[1.568304262074791, 50.41139946103049]],
    [ "Enedis",[1.5622146936936931, 50.41010434234234]],
    [ "Enedis",[1.5691015, 50.40817199999999]],
    [ "Enedis",[1.5631568920374292, 50.40684934242584]],
    [ "Enedis",[1.593147200294551, 50.40214682449681]],
    [ "Enedis",[1.5949071578231289, 50.4165434707483]],
    [ "Enedis",[1.5986535509250115, 50.40820134340641]],
    [ "Enedis",[1.5658764199134196, 50.40837025108225]],
    [ "Enedis",[1.5649226981757876, 50.40247023355445]],
    [ "Enedis",[1.5732337771980667, 50.403276255458934]],
    [ "Enedis",[1.577091394715013, 50.40120848998696]],
    [ "Enedis",[1.5718053717948708, 50.418939097165996]],
    [ "Enedis",[1.589174148052805, 50.406188058349834]],
    [ "RTE",[4.590724855615935, 45.520022646656564]],
    [ "RTE",[4.635546635616471, 45.537909267128605]],
    [ "Enedis",[6.090147149962881, 48.62657163845582]],
    [ "Enedis",[6.089725572985927, 48.61992809687906]],
    [ "Enedis",[6.091591006535948, 48.6238762377451]],
    [ "Enedis",[6.076307381093349, 48.62655129208604]],
    [ "Enedis",[6.094658917067584, 48.62184104707904]],
    [ "Enedis",[6.089070693135032, 48.61831545853289]],
    [ "Enedis",[6.082762355563015, 48.624421617905824]],
    [ "Enedis",[6.070423237288136, 48.62663271186442]],
    [ "Enedis",[2.0841671191139963, 48.90060502877227]],
    [ "Enedis",[2.066172178563098, 48.90088174107769]],
    [ "Enedis",[2.0735975146291414, 48.90484263463796]],
    [ "Enedis",[2.1002646783417926, 48.89787180411007]],
    [ "Enedis",[2.078803455535376, 48.899226661060915]],
    [ "Enedis",[2.073205844461297, 48.901071092155306]],
    [ "SG Saint-Léger 60",[2.068494916587083, 48.89739319964002]],
    [ "Enedis",[2.088182843594311, 48.90386833834398]],
    [ "Enedis",[2.07805888166607, 48.89810830705061]],
    [ "Enedis",[2.0733019362787206, 48.89756225072928]],
    [ "Enedis",[2.069534665706743, 48.90211046623619]],
    [ "Enedis",[2.081921656514009, 48.88785074143217]],
    [ "Enedis",[2.094406362137423, 48.901221525886925]],
    [ "Enedis",[2.0933349624473503, 48.89456550773409]],
    [ "Enedis",[2.0686942705265525, 48.893872590872924]],
    [ "Enedis",[2.0798123469124454, 48.89069814011157]],
    [ "Enedis",[1.6350921190736116, 50.67143054688067]],
    [ "Enedis",[1.6099002000000013, 50.67338920000001]],
    [ "Enedis",[1.6205913694935778, 50.6844426961421]],
    [ "Enedis",[1.6121149000000001, 50.685272]],
    [ "Enedis",[1.6277827500000004, 50.684476849999996]],
    [ "Enedis",[1.6078882670305559, 50.670022847326436]],
    [ "Enedis",[1.62054517557449, 50.67705951820294]],
    [ "Enedis",[1.6238725999999997, 50.6817332]],
    [ "Enedis",[1.6274856, 50.683439650000004]],
    [ "Enedis",[1.6356456999999995, 50.67120455]],
    [ "Enedis",[1.6330083714728574, 50.67891223143235]],
    [ "Enedis",[1.6274738, 50.680652]],
    [ "Enedis",[1.6395132442173213, 50.67526236449704]],
    [ "Enedis",[1.6118223999999999, 50.6900478]],
    [ "Enedis",[1.58890845, 50.66194185]],
    [ "Enedis",[1.604870284008947, 50.66928620551521]],
    [ "Enedis",[1.6017673499999998, 50.66814054999999]],
    [ "Enedis",[1.634808578046453, 50.674693768428526]],
    [ "Enedis",[1.5944932244190468, 50.66442899382858]],
    [ "Enedis",[1.6252706000000001, 50.6815522]],
    [ "Enedis",[1.6171627, 50.6856777]],
    [ "Enedis",[1.6290832000000006, 50.6771705]],
    [ "Enedis",[1.625963618281871, 50.67975086600171]],
    [ "Enedis",[1.6325624499999996, 50.67595244999999]],
    [ "Enedis",[1.6367570147809443, 50.67462161531263]],
    [ "Enedis",[1.8511032297951702, 48.91647599729309]],
    [ "Enedis",[1.8489122999999998, 48.90662000000001]],
    [ "Enedis",[1.8687555846031505, 48.91553998711582]],
    [ "Enedis",[1.8732230594765718, 48.917759773782635]],
    [ "Enedis",[1.8550264210385152, 48.91241908029182]],
    [ "Enedis",[1.8663821781526333, 48.90663159449774]],
    [ "Enedis",[1.8635168671393991, 48.91666298010578]],
    [ "Enedis",[1.849619042415719, 48.91684211957759]],
    [ "Enedis",[1.8482458442928635, 48.910951839806515]],
    [ "Enedis",[1.86976148769376, 48.90853362319879]],
    [ "Enedis",[1.86163711721367, 48.90530728374157]],
    [ "Enedis",[1.866258061559685, 48.91154137375294]],
    [ "Enedis",[1.8476487503183818, 48.91282328859233]],
    [ "Enedis",[1.8563590194716697, 48.90475216752908]],
    [ "Enedis",[1.8600604364504894, 48.883568473902066]],
    [ "Enedis",[1.6897403381906497, 50.857800738615666]],
    [ "Sous Station de Saint-Cloud",[2.206500555255541, 48.83891662209425]],
    [ "Enedis",[2.3661538931661354, 49.13533128703046]],
    [ "Enedis",[2.409303120273768, 49.13677473057326]],
    [ "Enedis",[2.362051704439682, 49.13375732722172]],
    [ "Enedis",[2.3758763020281775, 49.140617231878]],
    [ "Enedis",[2.3552190433533733, 49.1317684479292]],
    [ "Enedis",[2.364070027060653, 49.13454119237947]],
    [ "Enedis",[2.354369389177154, 49.135154969444216]],
    [ "Enedis",[2.3519861896831094, 49.134297785926606]],
    [ "Enedis",[1.682985761819179, 48.88952129164104]],
    [ "Enedis",[1.6868627120497715, 48.891841758218725]],
    [ "Enedis",[1.6621876568320277, 48.89040085630983]],
    [ "Enedis",[1.6568692561431675, 48.8896250788842]],
    [ "RTE",[4.639218700519717, 45.53793880123444]],
    [ "Enedis",[1.6628120892773897, 48.75688912214452]],
    [ "Sous-Station de Rive de Gier",[4.596859058409722, 45.5221576192747]],
    [ "La Rive",[6.0310055, 45.0527745]],
    [ "SNCF Réseau",[4.791876241186737, 45.59342826186275]],
    [ "réséda",[6.197228464186046, 49.13067431968992]],
    [ "réséda",[6.209473428641119, 49.14126695436156]],
    [ "réséda",[6.2235699516972725, 49.141040429215366]],
    [ "réséda",[6.195452837498398, 49.12822853581956]],
    [ "réséda",[6.20194945, 49.122919499999995]],
    [ "réséda",[6.219831449999999, 49.142086799999994]],
    [ "réséda",[6.201397926002014, 49.131902391540784]],
    [ "réséda",[6.214724800000001, 49.14276709999999]],
    [ "Enedis",[2.151782972563344, 48.950929266053826]],
    [ "réséda",[6.20850995, 49.1395908]],
    [ "réséda",[6.219248493803721, 49.140352108887704]],
    [ "réséda",[6.205334850000001, 49.14026785]],
    [ "Croisette",[6.2127069536423845, 49.16892419426049]],
    [ "Malroy Sud",[6.2129318553546575, 49.17256068752898]],
    [ "Malroy",[6.211264444444444, 49.17669911111111]],
    [ "Poste électrique d’Armentières",[2.8984365217331423, 50.6808548683266]],
    [ "réséda",[6.226530083583629, 49.16253910051576]],
    [ "réséda",[6.229111448569994, 49.15761479695601]],
    [ "réséda",[6.224426416332627, 49.15849755333297]],
    [ "réséda",[6.2426013, 49.17549290000001]],
    [ "Léoville",[6.424728700000001, 49.116821200000004]],
    [ "Enedis",[6.4563975000000005, 49.134718]],
    [ "Enedis",[6.444804999999998, 49.142259499999994]],
    [ "Enedis",[6.46554229426434, 49.13358128096426]],
    [ "Enedis",[7.152333537905068, 48.95254712947009]],
    [ "Poste électrique de Sagone",[8.703815900828811, 42.12342800880578]],
    [ "Enedis",[6.475733663787588, 49.15233694241842]],
    [ "Enedis",[6.528332332245096, 48.11937380630618]],
    [ "Enedis",[6.558701615240604, 48.11650048133658]],
    [ "réséda",[6.146488942434851, 49.121777153442245]],
    [ "réséda",[6.145491667651112, 49.12254849675133]],
    [ "réséda",[6.147762199999999, 49.11971825]],
    [ "réséda",[6.146698, 49.127521]],
    [ "réséda",[6.146229000000001, 49.12423700000001]],
    [ "réséda",[6.140459763259573, 49.1276718247278]],
    [ "réséda",[6.142675002492212, 49.12274681495327]],
    [ "réséda",[6.151887545887963, 49.12758433770362]],
    [ "réséda",[6.1491465000000005, 49.126497]],
    [ "réséda",[6.137561580638063, 49.12912212035204]],
    [ "réséda",[6.1942604201550395, 49.169059762126246]],
    [ "réséda",[6.190218700000001, 49.16567919999999]],
    [ "réséda",[6.185264798611111, 49.17105408796296]],
    [ "réséda",[6.185051932177265, 49.1543375761079]],
    [ "réséda",[6.191565730247527, 49.17002335981053]],
    [ "réséda",[6.186360212818844, 49.164166097385134]],
    [ "Poste Électrique de Dives",[-0.10985053153485917, 49.284910325824555]],
    [ "Enedis",[5.965658668462176, 48.75607465583043]],
    [ "Francheville",[5.9322139527034405, 48.738847001595964]],
    [ "Jardins",[6.001276900262433, 48.793176972504476]],
    [ "Enedis",[2.3859954999999995, 50.8869615]],
    [ "Enedis",[2.04710185, 48.3470258]],
    [ "Sous-station RFF de Thionville",[2.034342693424572, 48.351276415518626]],
    [ "Enedis",[1.1993682530574516, 47.924222459508634]],
    [ "Enedis",[1.619977467096059, 48.999802017115826]],
    [ "Enedis",[1.6194995550724303, 48.993209158166806]],
    [ "Enedis",[1.6199031762579779, 48.99792072430661]],
    [ "Enedis",[1.6231261671006008, 49.001737351133656]],
    [ "Enedis",[1.6229731821616147, 48.99683667862532]],
    [ "Enedis",[1.6225427247470836, 48.99980229960288]],
    [ "Enedis",[1.6279036810949687, 48.99797508725962]],
    [ "Enedis",[1.6181074837105351, 49.00297615439114]],
    [ "Enedis",[1.6301428754207945, 48.994434831625526]],
    [ "Enedis",[1.6326988767317463, 48.99991788468046]],
    [ "Enedis",[1.1943396629323269, 47.92645880053878]],
    [ "RTE",[5.054284529995905, 45.325639605510176]],
    [ "Enedis",[2.4084381694950765, 50.829687014456304]],
    [ "Enedis",[5.259539443189815, 45.383520611336586]],
    [ "RTE",[5.4320199074671445, 45.42856626307928]],
    [ "Enedis",[1.1690080925495923, 49.15227720085252]],
    [ "Enedis",[1.8830970573287074, 48.755764268863835]],
    [ "Enedis",[1.8807325804585164, 48.756083656338745]],
    [ "Enedis",[1.8785626645063953, 48.75552116776119]],
    [ "SER",[7.5850087889176, 48.919446335343096]],
    [ "Enedis",[6.446324149425287, 49.19006296168582]],
    [ "Poste ERDF Bissardière",[4.740645999999999, 45.62786329999999]],
    [ "Poste ERDF Lotissement de l'Église",[4.7466045997054875, 45.6221139921463]],
    [ "Poste Grandes Bruyeres",[4.7451422794045595, 45.61983333174825]],
    [ "Poste Goiffieu",[4.760953499999999, 45.623006999999994]],
    [ "Enedis",[7.140765183814458, 48.961358276600706]],
    [ "Enedis",[4.9622973066116955, 47.19874457443884]],
    [ "Enedis",[4.94453694069117, 47.202072417800345]],
    [ "Enedis",[4.9617065831701135, 47.19666777080159]],
    [ "Enedis",[2.5077796138203814, 50.78240224291918]],
    [ "réséda",[6.363459999999998, 49.167902000000005]],
    [ "Enedis",[6.5063492203389846, 47.69383944632768]],
    [ "Enedis",[6.502716210475641, 47.69167689196996]],
    [ "Enedis",[6.5089040889830505, 47.68867531603108]],
    [ "Enedis",[6.505179956528417, 47.69119205760368]],
    [ "Poste Source Haute Tension Boulieu Les Annonay",[4.668762201143317, 45.26516123104376]],
    [ "SNCF Réseau",[4.8061273150041695, 45.16796672735138]],
    [ "RTE",[4.8235332500000005, 45.109592549999995]],
    [ "Enedis",[1.3886474999999998, 44.73808300000001]],
    [ "P27 De Gaulle",[1.385057645618646, 44.73767969884512]],
    [ "Enedis",[1.9265232794204812, 48.50722765431895]],
    [ "Enedis",[1.7804585662752077, 48.802839169755664]],
    [ "Enedis",[1.8977178897461755, 48.77756772649095]],
    [ "Enedis",[1.9169018507389686, 48.75979658668744]],
    [ "Enedis",[1.9134624808370446, 48.769518437070566]],
    [ "Enedis",[1.9056694919920587, 48.766938580787624]],
    [ "Enedis",[1.9368673647299164, 48.77080259480941]],
    [ "Enedis",[1.91939236829214, 48.757741395702524]],
    [ "Enedis",[1.9356312809201086, 48.76499349944802]],
    [ "Enedis",[1.9153073188838763, 48.759892895727575]],
    [ "Enedis",[1.9091210713451758, 48.766955226519016]],
    [ "Enedis",[1.9407306917006826, 48.76338045910514]],
    [ "Enedis",[1.9325415792057192, 48.76343293926962]],
    [ "Enedis",[1.9058315851829393, 48.76745534435129]],
    [ "Enedis",[1.9040957744087732, 48.77631209877329]],
    [ "Enedis",[2.0143267838136056, 48.59021538739334]],
    [ "Enedis",[1.9401994956835051, 48.769789087537966]],
    [ "Enedis",[1.919300431873781, 48.76000225215562]],
    [ "Enedis",[6.452129491514445, 49.169211128125404]],
    [ "La Gallière",[4.86589772392031, 46.81125546810307]],
    [ "Enedis",[4.86798935, 46.821757600000005]],
    [ "Enedis",[1.8885586999999997, 48.711954]],
    [ "Enedis",[1.8891723475779836, 48.71516876884355]],
    [ "Enedis",[1.9001013499999992, 48.713495300000005]],
    [ "Enedis",[1.9037000328474938, 48.728131153164355]],
    [ "Enedis",[1.8931513999999998, 48.72378044999999]],
    [ "Enedis",[1.8973216180862675, 48.72418069692303]],
    [ "Enedis",[1.8974010274980384, 48.72606008865141]],
    [ "Enedis",[1.8992362857880378, 48.71396448555843]],
    [ "Enedis",[1.8786428273998006, 48.7110743147517]],
    [ "Enedis",[1.8929713313972323, 48.71601853016516]],
    [ "Enedis",[2.962708803080907, 43.13702833559943]],
    [ "RTE",[4.8793324283120265, 44.982558726235624]],
    [ "RTE",[4.859428912830498, 44.93430763523634]],
    [ "Enedis",[3.2162024999999996, 43.335667]],
    [ "RTE",[4.858682790272633, 44.919744136332646]],
    [ "Enedis",[3.2554559, 43.36318849999999]],
    [ "Lot Le Moulin",[6.379755499999999, 49.135606499999994]],
    [ "CD 71",[6.379773175807287, 49.14524450323815]],
    [ "Valence",[4.927101192527553, 44.91797786621497]],
    [ "réséda",[6.33338336923077, 49.14687421025641]],
    [ "Enedis",[3.9831602999999993, 43.674244599999994]],
    [ "Enedis",[6.427933500000002, 49.15141950000001]],
    [ "Enedis",[6.132007801007558, 48.6892455440806]],
    [ "Enedis",[6.129325262761704, 48.68793946647848]],
    [ "RTE",[4.9832440423232, 45.03515665563227]],
    [ "Enedis",[3.2282077735849057, 43.34520730660377]],
    [ "Enedis",[3.2379209183015005, 43.34054253584764]],
    [ "Enedis",[3.236891710433764, 43.3377004493943]],
    [ "Enedis",[3.227184751080914, 43.34435493679226]],
    [ "133264455",[4.934989769589172, 45.05365096133481]],
    [ "RTE",[4.865607440494452, 45.05066704857341]],
    [ "SNCF Réseau",[4.817913035644734, 45.134538825845404]],
    [ "RTE",[4.815876966153054, 45.19788644707724]],
    [ "RTE",[4.8584977786876316, 45.26876296449715]],
    [ "RTE",[6.2145498925968585, 49.41435179920426]],
    [ "RTE",[6.213578241518772, 49.413009431411886]],
    [ "RTE",[6.221637882587926, 49.41899319747235]],
    [ "RTE",[6.21976246222844, 49.41820016979828]],
    [ "Poste électrique de Salaise",[4.802876101691398, 45.3510422272842]],
    [ "RTE",[4.800863540787895, 45.348503252385086]],
    [ "RTE",[4.799369251296396, 45.349286503288745]],
    [ "RTE",[4.690110277503545, 44.519806189484626]],
    [ "SNCF Réseau",[4.7552519908402715, 45.363340970345]],
    [ "Enedis",[4.702823389806518, 45.38373974559263]],
    [ "Enedis",[4.435594837249783, 46.7791740635335]],
    [ "Enedis",[4.431333113989637, 46.779679742659766]],
    [ "Enedis",[4.429755000000001, 46.7824385]],
    [ "Enedis",[4.43842973163017, 46.77891535547445]],
    [ "Coupure",[5.870767399999999, 46.51177355]],
    [ "Enedis",[5.866739249999998, 46.50893895000001]],
    [ "Enedis",[5.863996566353352, 46.50504744934296]],
    [ "RTE",[4.77034269841743, 45.43310607101606]],
    [ "SNCF Réseau",[4.82495142574074, 45.48772804235445]],
    [ "Enedis",[4.525733887878788, 46.75927062424242]],
    [ "Enedis",[3.4379247159387836, 46.164540923852186]],
    [ "Enedis",[3.4125379696969707, 46.15192427272728]],
    [ "Enedis",[3.4164265, 46.153197000000006]],
    [ "Enedis",[3.433474985432734, 46.171730665809775]],
    [ "Enedis",[3.433633330694811, 46.1632538117854]],
    [ "Enedis",[3.4158269999999997, 46.117974000000004]],
    [ "Enedis",[3.393287267123288, 46.13183639041095]],
    [ "Enedis",[3.4148601047603466, 46.11488476812667]],
    [ "Enedis",[3.404614238550378, 46.11622134448426]],
    [ "Enedis",[3.3927073466905897, 46.127971552365764]],
    [ "Enedis",[3.4062310783347045, 46.120643709394685]],
    [ "La Pierrette",[2.992444977180436, 50.623182951559656]],
    [ "Enedis",[1.5645909888888883, 47.16408653333333]],
    [ "Enedis",[1.5525485163178323, 49.06213325492554]],
    [ "chemin",[1.5490376967696098, 49.06364172098628]],
    [ "Enedis",[1.5421811565196037, 49.04846273175903]],
    [ "villez",[1.5360892699551554, 49.05695170306427]],
    [ "Enedis",[3.4779658210116726, 46.15467248638133]],
    [ "Clos des Preludines",[3.4365142903914587, 46.12838566903915]],
    [ "Normandie",[3.4397745000000004, 46.128494999999994]],
    [ "Enedis",[3.448870835394862, 46.13796317348557]],
    [ "Enedis",[3.439157524999999, 46.146084389393934]],
    [ "Enedis",[3.444039754310345, 46.142556852729875]],
    [ "Enedis",[3.450528695448822, 46.13606315344485]],
    [ "Enedis",[3.4675952410599615, 46.13628163104171]],
    [ "Enedis",[3.473063564213564, 46.13725441798942]],
    [ "Enedis",[3.4302147623873873, 46.142266844594594]],
    [ "Enedis",[3.447766768707483, 46.124485546161324]],
    [ "La Moulat",[3.4686812500000004, 46.145748000000005]],
    [ "La Source",[3.4415126094159594, 46.13820586264025]],
    [ "Lycée de Presles",[3.438438925979422, 46.133184939355665]],
    [ "La Perche",[3.452639194659748, 46.14284764839371]],
    [ "Depalle",[3.4422672352788584, 46.129508077561596]],
    [ "RTE",[4.828066683622806, 45.49242928914449]],
    [ "Enedis",[3.2412423282536347, 43.33414266386408]],
    [ "Enedis",[3.24442425, 43.339923250000005]],
    [ "Enedis",[3.244599492545896, 43.33707128157487]],
    [ "Enedis",[3.242596204692097, 43.34092461809783]],
    [ "Enedis",[3.242649835673426, 43.33690451690068]],
    [ "Enedis",[3.2456750889150228, 43.339351075752]],
    [ "Enedis",[3.2380848406961182, 43.33628982730924]],
    [ "SNCF Réseau",[4.825074925695362, 45.49818855938159]],
    [ "Enedis",[4.216716500960708, 46.61488789141487]],
    [ "Enedis",[4.210472466853286, 46.614539037635815]],
    [ "Enedis",[5.943076617212452, 48.80618426620534]],
    [ "Enedis",[6.8691452422027295, 47.58484205360623]],
    [ "Enedis",[6.8662667396373065, 47.58567092141624]],
    [ "Enedis",[6.861696499999999, 47.587272999999996]],
    [ "Ruisseau",[5.926659411636911, 48.784609019910974]],
    [ "Les Pâquis",[5.919294981854491, 48.7837610016455]],
    [ "Villey-Saint-Étienne n°1",[5.976619670128568, 48.731321041052695]],
    [ "Château d'Eau",[5.981152816612233, 48.73456340151201]],
    [ "Chauffour",[5.9782302736733595, 48.72725080127216]],
    [ "Enedis",[5.1160683444775605, 45.50077174262546]],
    [ "RTE",[5.137960414595207, 45.63058524748924]],
    [ "RTE",[5.127313916549387, 45.640687134252886]],
    [ "RTE",[5.431664700625523, 45.56850689335356]],
    [ "RTE",[5.277278284331312, 45.594371604206074]],
    [ "Enedis",[6.532148141289437, 49.16326117969821]],
    [ "Enedis",[3.2478952032813133, 43.373337335734284]],
    [ "Enedis",[2.4318742761948533, 50.95119488893995]],
    [ "Enedis",[2.4552577999999996, 50.93411869999999]],
    [ "RTE",[5.211859141593408, 45.62809446165617]],
    [ "Pont Barrau",[3.4697067269273716, 46.07046995370815]],
    [ "RTE",[5.167367508373617, 45.74746731951141]],
    [ "RTE",[5.18414445953734, 45.74874328488916]],
    [ "RTE",[5.359845808663954, 45.950709857834404]],
    [ "Poste électrique de Moirans",[5.553324870452962, 45.328289395340285]],
    [ "Enedis",[4.8773095673365665, 46.710191689304686]],
    [ "Enedis",[4.844287474972369, 46.70017024364441]],
    [ "Enedis",[4.869061016729401, 46.71796748849853]],
    [ "Enedis",[4.863416, 46.723578499999995]],
    [ "Enedis",[4.861815000000001, 46.73589799999999]],
    [ "Enedis",[4.861634690291262, 46.725687336569585]],
    [ "Enedis",[4.860439748427673, 46.731966044025164]],
    [ "Enedis",[4.856885765882056, 46.72694934649457]],
    [ "Enedis",[4.931723750000001, 46.836164749999995]],
    [ "Enedis",[4.931280000000001, 46.83219275]],
    [ "Enedis",[4.926347061787333, 46.8315972653642]],
    [ "Poste électrique de Charenton",[2.406798388907246, 48.820845274569244]],
    [ "Enedis",[4.861028986324786, 46.84109966239316]],
    [ "Enedis",[4.870319, 46.846485]],
    [ "RTE",[5.553342099510248, 45.08310361809515]],
    [ "RTE",[5.087966170431023, 45.03885762858067]],
    [ "RTE",[4.606301957384839, 45.82489526217319]],
    [ "RTE",[4.444522560582226, 45.88937790583274]],
    [ "Poste EDF de Montalieu",[5.402539297625639, 45.80741878366193]],
    [ "Enedis",[3.4981523333333335, 49.86864851515151]],
    [ "Enedis",[3.553558309050773, 49.88429053642384]],
    [ "Enedis",[-1.7781094999999998, 46.566297500000005]],
    [ "Enedis",[-1.7978532000000007, 46.581787649999995]],
    [ "Enedis",[-1.7748741392011236, 46.555529669635426]],
    [ "Enedis",[-1.7723336000000005, 46.55426805]],
    [ "Enedis",[-1.8041460000000002, 46.582455499999995]],
    [ "Enedis",[-1.7780302717783478, 46.563396244740346]],
    [ "Enedis",[-1.7722890294962046, 46.56611881619887]],
    [ "Enedis",[-1.7819884758065032, 46.56127513393953]],
    [ "Enedis",[-1.7841485500000007, 46.5628696]],
    [ "Enedis",[-1.781086059668578, 46.55879401994125]],
    [ "réséda",[6.236727171974522, 49.23886706723284]],
    [ "réséda",[6.239258306666669, 49.24081298035088]],
    [ "réséda",[6.2363990000000005, 49.236013500000006]],
    [ "réséda",[6.250959137870855, 49.235453813263526]],
    [ "réséda",[6.2413255, 49.23867750000001]],
    [ "Enedis",[2.3346120511969426, 50.86665889297928]],
    [ "Poste électrique du Verney",[6.046857442737605, 45.14974226559232]],
    [ "RTE",[4.8451095166352145, 45.677349945971386]],
    [ "RTE",[4.854897572180119, 45.68263711822793]],
    [ "Poste électrique d'Oullins",[4.819641334316827, 45.71085591876751]],
    [ "Sous-Station de Sibelin",[4.844357537172101, 45.652712473124005]],
    [ "Enedis",[1.553878767173081, 49.04164989864957]],
    [ "Enedis",[6.564996839181286, 49.2574680267335]],
    [ "Enedis",[1.4494449590136176, 43.59430839976966]],
    [ "réséda",[6.258550110349495, 48.998037804100825]],
    [ "réséda",[6.202017999999999, 48.96575949999999]],
    [ "réséda",[6.228430249158248, 48.97214476296296]],
    [ "Enedis",[1.3338054986651413, 47.58033615884822]],
    [ "réséda",[6.238111952681387, 48.97825415352261]],
    [ "réséda",[6.2448269000000005, 48.98371029999999]],
    [ "Enedis",[7.543911, 48.337725250000005]],
    [ "réséda",[6.25212504287232, 48.969165067282454]],
    [ "réséda",[6.252299, 48.96676649999999]],
    [ "réséda",[6.247951694789976, 48.96761370326303]],
    [ "Enedis",[7.534127750000001, 48.33673949999999]],
    [ "Enedis",[7.539559500000002, 48.33746225]],
    [ "Enedis",[7.53805, 48.339133000000004]],
    [ "Enedis",[7.542508, 48.335781999999995]],
    [ "Enedis",[1.3421590000000014, 47.57781550000001]],
    [ "réséda",[6.285619163711097, 48.980915376669074]],
    [ "Enedis",[2.325743434637246, 50.868001644934814]],
    [ "réséda",[6.18178932152231, 48.966430989501326]],
    [ "réséda",[6.183392721370162, 48.96041298238651]],
    [ "réséda",[6.185516717060367, 48.96521221272967]],
    [ "réséda",[6.187918446799254, 48.964792690698154]],
    [ "réséda",[6.168900902004456, 48.94766240489977]],
    [ "réséda",[6.18343852260526, 48.9631701555067]],
    [ "Enedis",[2.90535280952381, 43.288916190476186]],
    [ "Enedis",[2.9497662302405496, 43.2566813769495]],
    [ "Enedis",[2.9406422128035317, 43.263613661810155]],
    [ "Enedis",[2.955488593609586, 43.260043269096364]],
    [ "Enedis",[2.935765084142395, 43.27221081553398]],
    [ "Enedis",[2.942224105927229, 43.26110276144367]],
    [ "Poste électrique de Sanilhac",[0.6853809700189403, 45.14318908924838]],
    [ "Poste électrique de Lambesc",[5.2536189789133365, 43.648174260342586]],
    [ "Enedis",[4.845840333333333, 46.361022783783795]],
    [ "134231742",[5.192166796095651, 49.49441010848764]],
    [ "La Chaudanne",[4.7340987, 45.605459749999994]],
    [ "Enedis",[4.631871679867986, 45.79609930693069]],
    [ "réséda",[6.230610581837955, 49.01253521261556]],
    [ "réséda",[6.288421800000001, 49.01793449999999]],
    [ "Enedis",[4.325812736196318, 46.66653194205862]],
    [ "Enedis",[5.425790145098166, 45.86774544067741]],
    [ "Enedis",[-1.7409402566320662, 47.80415603229527]],
    [ "Enedis",[3.0601088194217128, 43.00977003273322]],
    [ "Chateaubriand",[3.057000146757679, 43.01130676336746]],
    [ "Enedis",[3.06303059922179, 43.01470334803285]],
    [ "Enedis",[3.044109095528455, 43.01547371341463]],
    [ "Enedis",[3.055330680272109, 43.01390697959184]],
    [ "Enedis",[3.0426677715355805, 43.01724953558053]],
    [ "Enedis",[3.0512286243386244, 43.01928607760142]],
    [ "Enedis",[3.0471829916666664, 43.015880287222224]],
    [ "Enedis",[3.045133662291169, 43.01186474980112]],
    [ "Enedis",[3.046111928451461, 43.017894915574956]],
    [ "Enedis",[3.0504517217414113, 43.010028454760025]],
    [ "Nautilus",[3.057838, 43.01161799999999]],
    [ "Enedis",[3.035580346056896, 43.01974430980675]],
    [ "Enedis",[3.0567467832047988, 43.01232037103684]],
    [ "Enedis",[3.045863360206148, 43.01276619804896]],
    [ "Enedis",[3.0362079809825677, 43.020202837823554]],
    [ "Enedis",[3.0336351770592764, 43.02153186836027]],
    [ "Pêcheurs",[3.034659873294347, 43.02184582300196]],
    [ "Enedis",[3.0583679999999998, 43.007299]],
    [ "Enedis",[3.0538944955514173, 43.01464533126423]],
    [ "Enedis",[3.046635901515152, 43.011833136363634]],
    [ "Enedis",[3.0496066037340053, 43.012819801971894]],
    [ "Enedis",[-1.6453974071906263, 46.587363082118856]],
    [ "Enedis",[-1.6490874999999998, 46.5933895]],
    [ "Enedis",[-1.6510223999999996, 46.590140399999996]],
    [ "Enedis",[2.4583929716174806, 49.29309361570714]],
    [ "Enedis",[2.4665836094069524, 49.28933878118609]],
    [ "Enedis",[1.6326315000000002, 50.426395499999984]],
    [ "Enedis",[1.6461479051987764, 50.41916078593273]],
    [ "Enedis",[1.6072003050847454, 50.413081120527316]],
    [ "Enedis",[1.6321315000000003, 50.429629000000006]],
    [ "Enedis",[1.6273614766764957, 50.428638961744916]],
    [ "Enedis",[1.637826582364792, 50.419731214318915]],
    [ "Enedis",[1.6114080523070142, 50.41715185893624]],
    [ "Enedis",[1.647279633323468, 50.4167572496794]],
    [ "Enedis",[1.6173351899345056, 50.41437620613583]],
    [ "Enedis",[1.613195418056354, 50.41810372206249]],
    [ "Enedis",[1.64096840585774, 50.415017042770806]],
    [ "Enedis",[1.6209404700039738, 50.420192505760824]],
    [ "Enedis",[1.6346711972124177, 50.418683557426014]],
    [ "Enedis",[1.6231953720630825, 50.41421023666988]],
    [ "Enedis",[1.6357641608133087, 50.42345516943931]],
    [ "Enedis",[1.6341141270627058, 50.41611821204621]],
    [ "Enedis",[1.6401732885906035, 50.4181399732721]],
    [ "RTE",[5.700865313136408, 45.1482275273344]],
    [ "Village",[1.4782902973084067, 43.77829259484792]],
    [ "Enedis",[1.4818152000000009, 43.773360049999994]],
    [ "Enedis",[1.4665270511352135, 43.78285802386616]],
    [ "Les Champs Fleuris",[6.246873928205129, 49.02487556198056]],
    [ "Orny",[6.244591809440218, 49.021828339184715]],
    [ "Enedis",[1.2866768194982803, 44.070159070990535]],
    [ "Vergers d’Anjou",[-0.5065716499999986, 47.467559349999995]],
    [ "Hartuis",[-0.4965307166385765, 47.46571425274353]],
    [ "Enedis",[-0.4901629499999993, 47.4667597]],
    [ "Enedis",[-0.4872865348613563, 47.456431918969706]],
    [ "Oisonnière",[-0.5035160331749823, 47.463084549827784]],
    [ "Ranloue",[-0.5008013531210792, 47.46861914259612]],
    [ "Enedis",[-0.49324085000000006, 47.463968200000004]],
    [ "ETS Martin Rondeau",[-0.5053879547187016, 47.46452656605878]],
    [ "Enedis",[-0.5060117161913056, 47.4685429811446]],
    [ "Enedis",[-0.48024144999999996, 47.47339125]],
    [ "Enedis",[-0.4996319496261603, 47.46544487604428]],
    [ "Enedis",[-0.5021977115438641, 47.46420315752812]],
    [ "Enedis",[-0.5121414715806796, 47.46753871299952]],
    [ "Cybelle",[-0.50504755, 47.467647400000004]],
    [ "Enedis",[-0.5124344499999985, 47.46566510000001]],
    [ "Joliot-Curie",[-0.504224331725507, 47.46575508136679]],
    [ "Paperie Villechien",[-0.511769978099489, 47.46345087766647]],
    [ "Enedis",[-0.5145552668961647, 47.47122380031661]],
    [ "Enedis",[-0.5131412224110916, 47.472877856309445]],
    [ "Enedis",[-0.4980567096087035, 47.46715878180884]],
    [ "Enedis",[4.767332949999999, 45.5000585]],
    [ "Enedis",[3.685074466937354, 46.5200893466744]],
    [ "Enedis",[3.686424776592305, 46.515921875525386]],
    [ "Enedis",[3.690751729899155, 46.51624963968383]],
    [ "Enedis",[3.6776194273310683, 46.52463972744432]],
    [ "Enedis",[3.6842785, 46.51273349999999]],
    [ "Enedis",[3.692246408926876, 46.53088892858499]],
    [ "Enedis",[3.6748517450980387, 46.525738098039206]],
    [ "Enedis",[3.6735394023413437, 46.52414412815774]],
    [ "Enedis",[3.6737925000000002, 46.521079500000006]],
    [ "Enedis",[3.6744740070467645, 46.51858066773436]],
    [ "Enedis",[3.0667502515061598, 46.58821044177682]],
    [ "Enedis",[3.051291, 46.586642]],
    [ "Enedis",[3.0548111769775317, 46.585920759002775]],
    [ "Enedis",[3.058131637687589, 46.58515576565388]],
    [ "Enedis",[6.4496137, 47.4334576]],
    [ "Enedis",[0.2078629593946419, 49.50422877041909]],
    [ "Enedis",[2.067113350241546, 48.64528427294687]],
    [ "Enedis",[2.0690939423400674, 48.64036174208754]],
    [ "Enedis",[2.094925, 48.65547899999999]],
    [ "Enedis",[2.0661857151597762, 48.64143144202636]],
    [ "jarlin",[2.081637559916493, 48.64389671635351]],
    [ "Enedis",[2.0707883866209613, 48.6428882213566]],
    [ "Enedis",[2.079751879120879, 48.64373986446886]],
    [ "Enedis",[2.0894072750217587, 48.64490277197564]],
    [ "Enedis",[2.0620905, 48.64505]],
    [ "Enedis",[2.08602269579288, 48.643305359223305]],
    [ "Enedis",[2.078850487523993, 48.645964583493274]],
    [ "Enedis",[2.0783647250892567, 48.641577349561835]],
    [ "Enedis",[2.089629400527011, 48.64212618782023]],
    [ "Enedis",[2.080212086074178, 48.638668122929786]],
    [ "Enedis",[2.0647769226041954, 48.64318479092505]],
    [ "Enedis",[2.073447503875969, 48.64526762790697]],
    [ "Enedis",[2.0769525, 48.64706100000001]],
    [ "Enedis",[2.0890079999999998, 48.64017966666667]],
    [ "Enedis",[2.083094425434638, 48.640535294230894]],
    [ "Enedis",[2.0724508334426224, 48.64115189056467]],
    [ "Enedis",[2.0680059469836207, 48.64431021564406]],
    [ "Enedis",[-0.07711544504797509, 47.69234093597939]],
    [ "Enedis",[-0.07949729325941253, 47.70199986160709]],
    [ "Enedis",[-0.07206155652337971, 47.69012701863543]],
    [ "Enedis",[-0.10145295751198943, 47.71724757604967]],
    [ "Enedis",[-0.06334159649057612, 47.70089267120828]],
    [ "Enedis",[-0.0809895859394421, 47.695594187767234]],
    [ "Enedis",[-0.06392422408252427, 47.69939505200086]],
    [ "Enedis",[-0.06694954093869755, 47.70386001074944]],
    [ "Enedis",[-0.05848092755286664, 47.70114172634094]],
    [ "Enedis",[-0.060589331588459215, 47.71314509710743]],
    [ "Enedis",[-0.06856308470961779, 47.70855429668584]],
    [ "Enedis",[-0.1068591099941819, 47.71251353980788]],
    [ "Enedis",[-0.06037580196807022, 47.701849724693076]],
    [ "Enedis",[-0.06462874728490424, 47.69046806288806]],
    [ "Enedis",[-0.0625761682665028, 47.7115912095583]],
    [ "Enedis",[-0.04787604687722576, 47.68897742267244]],
    [ "Enedis",[-0.07603533569495038, 47.70612044178267]],
    [ "Enedis",[-0.04956549440858874, 47.70272813342506]],
    [ "Enedis",[-0.08587484862765901, 47.70451708500794]],
    [ "Enedis",[-0.08431822465637319, 47.70311885557908]],
    [ "Enedis",[-0.059466636768595336, 47.69907462030192]],
    [ "Enedis",[-0.06299390457074266, 47.704996715525176]],
    [ "Enedis",[-0.0740598007277293, 47.70134685722412]],
    [ "Enedis",[-0.09260299942720615, 47.699241035098474]],
    [ "Enedis",[-0.06850645640557335, 47.692899713132014]],
    [ "Enedis",[-0.07216648372859659, 47.70022628828144]],
    [ "Enedis",[-0.0844686978623307, 47.713687764549654]],
    [ "Enedis",[-0.09769831656027328, 47.706670644178764]],
    [ "Enedis",[-0.10633616801534722, 47.720010484875594]],
    [ "Enedis",[-0.10230284944316967, 47.71060912658336]],
    [ "Enedis",[-0.04793437477597222, 47.703639182405325]],
    [ "Enedis",[-0.08059805042108802, 47.7037608088273]],
    [ "Enedis",[-0.08710604945215404, 47.69776491843207]],
    [ "Enedis",[-0.09435965124986277, 47.709561280002845]],
    [ "Enedis",[-0.06110757973961839, 47.71511684744832]],
    [ "Enedis",[-0.10304364905678988, 47.720072672807824]],
    [ "Enedis",[-0.06960171018430336, 47.69961721901026]],
    [ "Enedis",[5.863320999999999, 46.392017249999995]],
    [ "Saint-Romain",[5.86494725, 46.3873755]],
    [ "HLM Avignonets",[5.86288775, 46.400334550000004]],
    [ "Enedis",[5.8684572500000005, 46.39624825]],
    [ "Enedis",[5.853948358001266, 46.43766317710311]],
    [ "Jura Hôtel",[5.861014749999999, 46.38963325]],
    [ "Enedis",[5.86782521116324, 46.41386865950081]],
    [ "Enedis",[5.8288843825005126, 46.38084254919533]],
    [ "Enedis",[5.805812884000993, 46.35395990273241]],
    [ "Enedis",[5.857684691244239, 46.39114252104455]],
    [ "Enedis",[5.848748197318808, 46.44815983345093]],
    [ "Enedis",[5.8630625, 46.394930249999994]],
    [ "Enedis",[5.8145521, 46.3677364]],
    [ "Enedis",[5.852516999999999, 46.3804725]],
    [ "Enedis",[5.86188375, 46.387898250000006]],
    [ "Enedis",[5.8603295000000015, 46.38452470000001]],
    [ "Enedis",[5.843278800000001, 46.45793579999999]],
    [ "Enedis",[5.862496716305725, 46.40104104617164]],
    [ "Voltaire",[5.864842877485314, 46.392943896040286]],
    [ "Enedis",[5.859037, 46.39045325]],
    [ "Enedis",[5.862394000000001, 46.392848]],
    [ "Enedis",[5.875313395448079, 46.37995088240872]],
    [ "Enedis",[5.864366850000001, 46.4012072]],
    [ "Enedis",[5.8690981969230664, 46.395712154520965]],
    [ "Enedis",[5.856627050621466, 46.38126881711608]],
    [ "Enedis",[5.863997035432988, 46.389863382966176]],
    [ "Enedis",[5.862732984083979, 46.38621175326469]],
    [ "Mairie",[5.865038999999999, 46.389021850000006]],
    [ "Enedis",[5.83280475514736, 46.38349255490771]],
    [ "Poste électrique de Porte-Sachet",[5.803170185785182, 46.36589409208666]],
    [ "Enedis",[0.0428942, 47.178940600000004]],
    [ "Enedis",[0.05165030000000097, 47.182083600000006]],
    [ "Enedis",[0.046780199999999994, 47.182274050000004]],
    [ "Enedis",[0.04856521949720765, 47.18844335380658]],
    [ "Bourg",[0.0520493413417274, 47.18402612001884]],
    [ "RTE",[4.819565540027969, 44.757552196823276]],
    [ "Enedis",[4.793369242005184, 44.816295602592156]],
    [ "Avignon",[4.854952560941203, 43.948186221509815]],
    [ "Enedis",[4.865464477215014, 43.98487083267114]],
    [ "réséda",[6.362549159922928, 49.0358484662813]],
    [ "réséda",[6.379845830422126, 49.02122227438138]],
    [ "réséda",[6.366979743751882, 49.03475191809696]],
    [ "réséda",[6.3417833945578215, 49.05332285034014]],
    [ "Poste Électrique de Marnière",[1.4058396158854385, 49.00880470125453]],
    [ "RTE",[4.842041920864287, 44.13179312416221]],
    [ "Sous-station SNCF de Crémade",[4.840987599707049, 44.13299285277969]],
    [ "RTE",[4.692932858132653, 43.82448998844024]],
    [ "Enedis",[2.5519509999999994, 51.0128235]],
    [ "Poste électrique de Chateaurenard",[4.85687039502575, 43.864291140071515]],
    [ "RTE",[4.791741012783813, 43.93691889672456]],
    [ "SNCF Réseau",[4.893223079061148, 44.059644198129654]],
    [ "RTE",[4.884850526583569, 44.01276890545522]],
    [ "Enedis",[4.898050883297599, 43.95968321611533]],
    [ "Poste électrique de Logis-Neuf",[4.791391738240541, 44.67464875776088]],
    [ "Enedis",[0.05349985819358635, 47.21711555928215]],
    [ "RTE",[4.691873141297381, 44.5397209541667]],
    [ "RTE",[4.740173431834143, 44.56192281320629]],
    [ "Poste électrique de Montjoyer",[4.8634286771370405, 44.47599472247785]],
    [ "Poste électrique de Phénix",[4.7137848479767825, 44.1521863776085]],
    [ "Poste électrique de Chabrillan",[4.945899841529987, 44.700204216323684]],
    [ "RTE",[5.007162035653176, 44.72574817548281]],
    [ "RTE",[-0.9152936949499261, 43.91874514715521]],
    [ "Enedis",[-1.6062616893090211, 48.83665155488694]],
    [ "Enedis",[-1.56597189644116, 48.8417178094296]],
    [ "Enedis",[-1.5823265078507114, 48.83387305250256]],
    [ "Enedis",[-1.5877205429241443, 48.83593805440344]],
    [ "Enedis",[-1.5852812621411791, 48.83638155819183]],
    [ "Enedis",[-1.6010618396422172, 48.83422509513278]],
    [ "Enedis",[-1.5844638652139202, 48.83549663731669]],
    [ "Enedis",[-1.5819213281074538, 48.838729243631214]],
    [ "Enedis",[-1.5900233683729046, 48.83821579023379]],
    [ "ZAC Pretot",[-1.5507110497853525, 48.83628639850337]],
    [ "Enedis",[-1.5683293470220816, 48.82902540678765]],
    [ "Enedis",[-1.596978193589567, 48.837550880463525]],
    [ "Enedis",[-1.5587961006823647, 48.83414520203653]],
    [ "Enedis",[-1.5805010012354856, 48.832696990313785]],
    [ "Enedis",[-1.5506080392216741, 48.833309597838316]],
    [ "Enedis",[-1.5750689488004699, 48.83348490801443]],
    [ "Enedis",[-1.5770860592732383, 48.830522131070225]],
    [ "Enedis",[-1.5795025234132936, 48.83419287815198]],
    [ "Enedis",[-1.5556732505954383, 48.83510732054543]],
    [ "Enedis",[-1.6025209668153895, 48.836749624104364]],
    [ "Enedis",[-1.5748903214608727, 48.843458271817866]],
    [ "Enedis",[-1.546540650547975, 48.835062742518055]],
    [ "Enedis",[-1.6087898518237223, 48.833611985942326]],
    [ "Enedis",[-1.5649375507923027, 48.83977044867175]],
    [ "Enedis",[-1.5731914422121391, 48.820599052908285]],
    [ "Enedis",[-1.576664672448467, 48.83512192316975]],
    [ "Enedis",[-1.5688204952804812, 48.841216196783904]],
    [ "Enedis",[-1.582174650995201, 48.83655716959688]],
    [ "Enedis",[-1.5718008642301013, 48.84069023342571]],
    [ "Enedis",[-1.5417786677190943, 48.836480976311826]],
    [ "Enedis",[-1.5876477718434707, 48.83806336001325]],
    [ "Enedis",[-1.5727768999137883, 48.84302550587264]],
    [ "Enedis",[-1.5721771014319648, 48.81724974456451]],
    [ "Enedis",[-1.5449576253225161, 48.8377401027304]],
    [ "Enedis",[-1.5639456963284553, 48.83669323003914]],
    [ "Enedis",[-1.5845232756676195, 48.83869357172688]],
    [ "Enedis",[-1.576738881030601, 48.84068498492686]],
    [ "Enedis",[-1.562186499167998, 48.840109485535365]],
    [ "Enedis",[-1.5832185635082077, 48.831072914900076]],
    [ "Enedis",[-1.370090183114842, 48.691576517766165]],
    [ "Enedis",[-1.3713500879331981, 48.691207750628685]],
    [ "Poste électrique de Belle-Etoile",[4.846142173156594, 45.69158582613183]],
    [ "Enedis",[2.4648801618422125, 49.33545315770697]],
    [ "Enedis",[2.4634192412847624, 49.32436143282414]],
    [ "Mairie",[-3.702153055763813, 48.0261309102316]],
    [ "Enedis",[3.4645147500000015, 43.51763405]],
    [ "Poste électrique de Masquet",[-0.9497360052630565, 44.619900080538955]],
    [ "SNCF Réseau",[4.829098252119814, 45.04519949231013]],
    [ "Enedis",[-1.8049855052881585, 48.11855635415911]],
    [ "Enedis",[2.3216312500000003, 48.65418149999999]],
    [ "Enedis",[2.3504445000000005, 48.63992050000001]],
    [ "Enedis",[2.3260072658922213, 48.65218452662148]],
    [ "Enedis",[2.3489639676544654, 48.642984392213826]],
    [ "Enedis",[2.3302437757296466, 48.63055364990923]],
    [ "Enedis",[2.326076013545971, 48.64710061264174]],
    [ "Enedis",[2.3339645, 48.628491499999996]],
    [ "Enedis",[2.3429227200132536, 48.63781818853544]],
    [ "Enedis",[2.309660878168152, 48.652671342247864]],
    [ "Enedis",[2.3554011034332905, 48.639398714689264]],
    [ "Enedis",[2.3180909533333334, 48.64643995333334]],
    [ "Enedis",[2.3194381, 48.648981649999996]],
    [ "Enedis",[2.3528228869526364, 48.64256767962466]],
    [ "Enedis",[2.3361261812786625, 48.64202966127147]],
    [ "135606370",[2.3494739853004756, 48.63765433203631]],
    [ "Enedis",[2.342473000303006, 48.647522998122554]],
    [ "Enedis",[2.321572249999999, 48.65226825000001]],
    [ "Enedis",[2.3063212385288723, 48.651037548919945]],
    [ "Poste électrique d’Envermeu",[1.2558873065717444, 49.909308768772746]],
    [ "Enedis",[-1.4287987402284263, 48.405777242476425]],
    [ "Enedis",[-1.4137082036821131, 48.40787164777255]],
    [ "Enedis",[-1.4463416084577636, 48.404137888921525]],
    [ "Enedis",[-1.406913452318801, 48.3504978499782]],
    [ "Croizetière",[-3.331210769286081, 47.712769444889574]],
    [ "RTE",[3.5378150389817753, 50.11295592617835]],
    [ "Enedis",[2.514803872773537, 50.529442928753184]],
    [ "Enedis",[2.5087561970178442, 50.52838864018576]],
    [ "Enedis",[2.512253551768906, 50.532091250568]],
    [ "Enedis",[2.507208632887993, 50.53197093966894]],
    [ "Poste de Boudeyre",[4.372992246231931, 44.575492486199316]],
    [ "Chemin des Douves",[-3.4475658499999997, 47.69814735]],
    [ "RTE",[3.853066977039125, 44.73776993349749]],
    [ "Enedis",[-1.9198667498721103, 48.03219230094102]],
    [ "RTE",[3.8852549423792238, 45.01079042839184]],
    [ "RTE",[4.021548840692476, 44.95918501940242]],
    [ "Enedis",[-1.7133895000000001, 46.56448725]],
    [ "Enedis",[-1.7160964999999997, 46.56126129999999]],
    [ "Enedis",[-1.715928843054446, 46.55855806535371]],
    [ "Enedis",[-1.7189969999999997, 46.55963904999999]],
    [ "Enedis",[-1.70800275, 46.566866]],
    [ "Enedis",[-1.7126575000000002, 46.568343000000006]],
    [ "Enedis",[-1.7052905602713726, 46.565858426768855]],
    [ "Enedis",[-1.71812275, 46.568320250000006]],
    [ "Enedis",[-1.720346695081012, 46.556336917435736]],
    [ "Enedis",[-1.7160407, 46.56688454999999]],
    [ "Enedis",[-1.7120663999999999, 46.565906999999996]],
    [ "Enedis",[0.314351547923323, 49.53973525601705]],
    [ "Enedis",[0.31022540400307935, 49.53868466832179]],
    [ "Enedis",[4.594742200000001, 45.81150485000001]],
    [ "Bourg",[4.600119835766409, 45.811005681067755]],
    [ "Enedis",[4.5972922500000015, 45.81052325]],
    [ "Enedis",[3.669795301454385, 43.418895556192155]],
    [ "Hôpital",[4.616235684577115, 45.84153695880596]],
    [ "EDF",[3.0739434914706942, 44.92204064267488]],
    [ "Poste électrique de Grandval",[3.072414813558425, 44.92146347942375]],
    [ "Enedis",[0.31303600000000004, 49.5639775]],
    [ "Enedis",[-2.072446288681568, 47.75981002346782]],
    [ "Poste électrique de Savignac",[3.0337527657512613, 45.10246990229288]],
    [ "Enedis",[4.853730172093377, 45.50537813140924]],
    [ "Enedis",[4.874797302464121, 45.536173972380176]],
    [ "RTE",[3.109666656083883, 45.03533882389257]],
    [ "Enedis",[-1.580552188065329, 46.611474277998575]],
    [ "Enedis",[-1.5839460000000003, 46.60968920000001]],
    [ "Enedis",[-1.5821289999999997, 46.61523275]],
    [ "Enedis",[-1.5876901535654233, 46.61833951717008]],
    [ "Enedis",[-1.58379975, 46.61258600000001]],
    [ "RTE",[3.092980644379313, 45.02910674868769]],
    [ "Enedis",[0.3911448376068378, 49.74430005698006]],
    [ "Enedis",[0.36265211412990533, 49.75495785256598]],
    [ "Enedis",[0.3834898037796538, 49.752074729392845]],
    [ "Enedis",[0.38656629460146563, 49.75604128562883]],
    [ "Enedis",[0.38793952552301336, 49.75117084407253]],
    [ "Enedis",[0.3977368078967355, 49.75616965679575]],
    [ "Enedis",[0.38585932339720763, 49.75392084220488]],
    [ "Enedis",[0.37777139792112957, 49.75425622978656]],
    [ "Enedis",[0.37091040962470195, 49.74674919339739]],
    [ "Enedis",[0.36848625000000007, 49.745725]],
    [ "Enedis",[0.3942653496137546, 49.75228378461666]],
    [ "Enedis",[0.369179449963654, 49.76425235740248]],
    [ "Enedis",[0.3686152499999992, 49.74779425]],
    [ "Enedis",[0.3614379070935344, 49.75861965913371]],
    [ "Enedis",[0.39056833333333335, 49.74460256862746]],
    [ "Enedis",[0.3992901968253968, 49.750916460702264]],
    [ "Enedis",[0.3872544464047054, 49.7525037558585]],
    [ "Enedis",[0.3899366048303141, 49.743096156777014]],
    [ "Enedis",[0.41081249625836935, 49.74879966495996]],
    [ "Enedis",[0.3877720828455141, 49.75460095322518]],
    [ "Enedis",[0.36209598525915365, 49.757179828815985]],
    [ "Enedis",[0.3812563812949636, 49.75655213589129]],
    [ "Enedis",[0.3824343462973873, 49.754422812806915]],
    [ "Enedis",[0.36635200835654647, 49.7563055273909]],
    [ "Enedis",[0.4025687500000002, 49.748478]],
    [ "Enedis",[0.390735168374817, 49.74480043338212]],
    [ "Enedis",[0.3901310449876018, 49.75728682359192]],
    [ "Enedis",[0.38668450560035805, 49.75572872334229]],
    [ "Enedis",[6.597973929728079, 49.16325629666972]],
    [ "réséda",[6.386028721430297, 49.02867804397198]],
    [ "réséda",[6.503804012173913, 49.063420471304354]],
    [ "Sarrault",[-1.2817116666666657, 46.164204000000005]],
    [ "Surveillance",[-1.2617227848829347, 46.16071387416274]],
    [ "Digentoise",[-1.2817464500000004, 46.160923149999995]],
    [ "Rue Charles de Gaulle",[-1.286556244683716, 46.16394225365517]],
    [ "Enedis",[3.9634907502152585, 45.052026635453096]],
    [ "RTE",[3.9078263627298586, 45.048920720901656]],
    [ "Poste électrique de Seltz",[8.088674946916367, 48.897924929707514]],
    [ "Enedis",[4.125548720121074, 45.11786659025045]],
    [ "Enedis",[4.28538350474398, 45.073658829365876]],
    [ "Enedis",[2.521891197442046, 50.468580326938444]],
    [ "Enedis",[2.505071171157685, 50.48196597504991]],
    [ "Enedis",[2.506375837962963, 50.46620152314814]],
    [ "Enedis",[2.5102578686868693, 50.48290864571643]],
    [ "Enedis",[2.509278833737537, 50.47049099461062]],
    [ "Enedis",[2.509294813398427, 50.4704601472742]],
    [ "Enedis",[2.5197690059523814, 50.47087610714286]],
    [ "Enedis",[2.485341299541093, 50.47879557321652]],
    [ "Enedis",[2.5123965, 50.4745965]],
    [ "Enedis",[2.4935443333333334, 50.474855000000005]],
    [ "Enedis",[2.5016902950271955, 50.46325753954933]],
    [ "Enedis",[2.4877963335583306, 50.475409502418714]],
    [ "Enedis",[2.504683666158795, 50.47090999576773]],
    [ "Enedis",[2.5124030948085254, 50.47468630237908]],
    [ "Enedis",[2.515201, 50.4812375]],
    [ "Enedis",[2.4893464047361467, 50.4731696155788]],
    [ "Enedis",[2.4990360913978735, 50.466125417863374]],
    [ "RTE",[3.780748489576041, 45.10760439122083]],
    [ "Poste Source Bellevue La Montagne",[3.8149915607031635, 45.22031179102166]],
    [ "Sous-station de Billy",[3.4214975119717486, 46.22349135381772]],
    [ "Poste électrique de La Font",[3.472368746781081, 46.15901741339968]],
    [ "RTE",[3.465500889486101, 46.075561652497626]],
    [ "RTE",[3.71139834005257, 45.96614246067167]],
    [ "RTE",[3.7113138104057404, 45.96578561940381]],
    [ "Enedis",[2.240555, 48.5899465]],
    [ "Enedis",[2.240756999999999, 48.583101]],
    [ "Enedis",[2.2462310086825217, 48.58839649414874]],
    [ "Enedis",[2.244008915960452, 48.58156359533898]],
    [ "RTE",[3.1468797089828375, 45.250856780536076]],
    [ "SNCF Réseau",[3.0270347630608914, 45.11033082409329]],
    [ "Poste électrique de Neussargues-Moissac",[2.985290770355449, 45.13586019261601]],
    [ "Enedis",[3.4929320098461445, 45.200425353171]],
    [ "Enedis",[3.49789151194204, 45.115077723022196]],
    [ "Ancelpont",[3.635198463270061, 44.82179241692719]],
    [ "Enedis",[-0.2949363999999998, 47.63763675]],
    [ "Poste électrique de Sarreguemines",[7.07752620500853, 49.09587066159972]],
    [ "Margeride",[3.281544906520632, 44.79151118445564]],
    [ "Enedis",[-0.30500584279903015, 47.648179703443994]],
    [ "Enedis",[-1.5970716499999997, 46.56745275]],
    [ "Enedis",[4.9683201122807015, 47.455855761403505]],
    [ "Enedis",[5.337889035614459, 47.02718086957054]],
    [ "RTE",[3.170043302871014, 46.85729875523388]],
    [ "RTE",[2.9946165064110266, 44.857145370207846]],
    [ "Enedis",[3.3399875600761035, 45.39478183141192]],
    [ "Enedis",[-0.6831426, 46.52746044999999]],
    [ "Enedis",[-0.7076686072434951, 46.53619174751402]],
    [ "Enedis",[-0.6866658499999996, 46.527748450000004]],
    [ "RTE",[3.2524881023267858, 45.563099443080596]],
    [ "Enedis",[-3.6746811423446433, 47.92408755899404]],
    [ "Poste électrique d'Enval",[2.9994614343667383, 45.765331515925595]],
    [ "Poste électrique de Prauliat",[3.096111313051775, 45.74585888210608]],
    [ "L'église",[4.6822047359013865, 45.859434512532104]],
    [ "RTE",[3.0875087966300065, 45.57384344607479]],
    [ "136491903",[9.433093252230648, 42.665915027256005]],
    [ "Bonifacio SACOI HVDC Cable Terminal",[9.202795267844458, 41.37138146785724]],
    [ "Lucciana SACOI Static Inverter Plant",[9.450056327740178, 42.52848845211728]],
    [ "Enedis",[6.577203347604485, 49.27946341896025]],
    [ "Enedis",[-0.6520535123130217, 45.740849426758736]],
    [ "Enedis",[2.4394933338693963, 49.33856013601365]],
    [ "Enedis",[2.4416363885381247, 49.32705710854783]],
    [ "Enedis",[2.635507834041348, 50.54240100764656]],
    [ "Enedis",[2.6539669999999997, 50.530078]],
    [ "Enedis",[2.6447415000000003, 50.5265315]],
    [ "Enedis",[2.635956263977806, 50.53635995561246]],
    [ "Enedis",[2.640902738412698, 50.52674365460317]],
    [ "Enedis",[2.627512150007677, 50.513492369107944]],
    [ "Enedis",[2.629083332508478, 50.53066950215379]],
    [ "Enedis",[2.640829614457831, 50.52443762751004]],
    [ "Enedis",[2.6299999710144917, 50.530322304347834]],
    [ "Enedis",[2.647754437968959, 50.530497827217594]],
    [ "Enedis",[2.640117, 50.5243139543379]],
    [ "Enedis",[2.637325121749409, 50.54021710401891]],
    [ "Enedis",[2.6474284922508344, 50.543545833452555]],
    [ "Enedis",[2.633972306447403, 50.53302542208301]],
    [ "Enedis",[2.632084543075327, 50.513880665628704]],
    [ "Enedis",[2.6296593333333336, 50.53479931050229]],
    [ "Enedis",[2.6462811647020303, 50.521805971185316]],
    [ "Enedis",[2.6486109563365283, 50.53621044195953]],
    [ "Enedis",[2.6360573963963954, 50.52899698498498]],
    [ "Enedis",[2.6489333671874995, 50.525707940104176]],
    [ "Enedis",[2.6292981286754, 50.51487986928675]],
    [ "Enedis",[2.6596418010236715, 50.52233452591171]],
    [ "Enedis",[2.629745333399067, 50.52399050187339]],
    [ "Enedis",[2.6251847630593335, 50.52426083776859]],
    [ "Enedis",[2.6355383567362423, 50.52629499810247]],
    [ "Enedis",[2.647831904109588, 50.514428776255706]],
    [ "Enedis",[2.6408504699297057, 50.534534166276146]],
    [ "Enedis",[2.6376346749769155, 50.53427552262234]],
    [ "Enedis",[2.6631566761114485, 50.53681624576672]],
    [ "Enedis",[2.656200317119814, 50.53832152540118]],
    [ "Enedis",[2.6301322293251106, 50.53211379602604]],
    [ "Enedis",[2.6465056670035367, 50.54204200421088]],
    [ "Enedis",[2.6211839401364467, 50.52223088767176]],
    [ "Enedis",[2.6297328132928843, 50.5371076796286]],
    [ "Enedis",[2.6535572482379233, 50.53876485232938]],
    [ "Enedis",[2.6501866511350056, 50.54091177060932]],
    [ "Enedis",[2.6439600000000003, 50.5131695]],
    [ "Enedis",[2.6354628355874503, 50.53348822062748]],
    [ "Enedis",[2.6510713985401457, 50.519467690267646]],
    [ "Enedis",[2.6386204999999987, 50.527015000000006]],
    [ "Enedis",[2.646785222956139, 50.53304468555537]],
    [ "Enedis",[2.6273489611624474, 50.53980544917636]],
    [ "Enedis",[2.636588331406551, 50.54145559601799]],
    [ "Enedis",[2.6386390197637977, 50.54365711344098]],
    [ "Enedis",[2.657839622247107, 50.5252126536021]],
    [ "Enedis",[2.666028223534042, 50.53600853010626]],
    [ "Enedis",[2.6318778804639487, 50.528263101964846]],
    [ "Enedis",[2.6204064954734525, 50.51960325487317]],
    [ "Enedis",[2.6531085, 50.524862500000005]],
    [ "Enedis",[2.63061683191836, 50.52947349798472]],
    [ "Enedis",[2.649036048122686, 50.52189334197074]],
    [ "Enedis",[2.6526780841423947, 50.52180949838188]],
    [ "Enedis",[2.634041435722412, 50.53910177393143]],
    [ "Enedis",[2.634021290322581, 50.524456795698924]],
    [ "Enedis",[2.6322779578197752, 50.52671433237468]],
    [ "Enedis",[2.6481593291915937, 50.529380778493625]],
    [ "Enedis",[2.651703332472007, 50.52701500430664]],
    [ "Enedis",[2.6270995528190513, 50.52173737356672]],
    [ "Enedis",[2.635378358281358, 50.52708969057518]],
    [ "Enedis",[2.634227775781033, 50.53021779780665]],
    [ "Enedis",[2.654414086226852, 50.52077094155092]],
    [ "Enedis",[2.636867359994306, 50.523983306670814]],
    [ "Poste électrique de Furiani",[9.424424754442455, 42.66367323463688]],
    [ "CT Chartres 25",[2.2921267755090655, 48.796076730934445]],
    [ "RTE",[2.6669696384849795, 45.35306589009065]],
    [ "Enedis",[1.4601290334145849, 43.52586825671339]],
    [ "RTE",[2.4461417233214235, 45.35367441862769]],
    [ "Poste électrique de Bonifacio",[9.186950352343322, 41.4247564057379]],
    [ "Fricasse",[4.194739682705159, 43.56901546725475]],
    [ "Enedis",[4.199267, 43.577365500000006]],
    [ "réséda",[6.118576459724717, 49.11737379581089]],
    [ "réséda",[6.121039399999999, 49.10968449999999]],
    [ "réséda",[6.126455763928727, 49.11484691338549]],
    [ "réséda",[6.113487323733166, 49.106389804120624]],
    [ "réséda",[6.128309100000001, 49.1105062]],
    [ "réséda",[6.118092879839785, 49.10701609692745]],
    [ "réséda",[6.114624000000001, 49.108526999999995]],
    [ "réséda",[6.122171, 49.1158385]],
    [ "réséda",[6.12654064473342, 49.110871074035536]],
    [ "réséda",[6.115615414044444, 49.11593767368889]],
    [ "réséda",[6.107075773884541, 49.11720784305975]],
    [ "réséda",[6.1183178300089835, 49.10970666091643]],
    [ "réséda",[6.12382022418879, 49.11145878171092]],
    [ "réséda",[6.139134900000001, 49.11386555]],
    [ "réséda",[6.154098614597862, 49.11699320750039]],
    [ "réséda",[6.135992963188626, 49.11386117872557]],
    [ "réséda",[6.138646500000001, 49.1181995]],
    [ "St Symphorien",[6.158048582849128, 49.11243340866964]],
    [ "Enedis",[0.22899247492783573, 49.4165317435563]],
    [ "Enedis",[0.22863961224489732, 49.41798085292048]],
    [ "Enedis",[0.23045718442924898, 49.42147655599929]],
    [ "Enedis",[0.23891025000000082, 49.41117975]],
    [ "Enedis",[0.2511428877192983, 49.415721550877194]],
    [ "Enedis",[0.22840916416666665, 49.42431430916666]],
    [ "Enedis",[0.2320794342797056, 49.41670294637224]],
    [ "Enedis",[0.2378882612221798, 49.4180964052873]],
    [ "Enedis",[0.2480929894179888, 49.4280071005291]],
    [ "Enedis",[0.24150483375104428, 49.416420994430524]],
    [ "Enedis",[0.24701684011190014, 49.414595489993545]],
    [ "Enedis",[0.25389370927318305, 49.41603503508771]],
    [ "Enedis",[0.22517423668314102, 49.42347856287754]],
    [ "Enedis",[0.24287901106301107, 49.41273383261183]],
    [ "Enedis",[0.23666316049382613, 49.41664816049383]],
    [ "Enedis",[4.741950655313817, 45.97669596776048]],
    [ "Grange Blazet",[4.71859851104101, 45.97942155520504]],
    [ "Enedis",[6.15018710095371, 48.60862630728076]],
    [ "Enedis",[6.170021149999999, 48.60975695]],
    [ "Enedis",[6.140892885443037, 48.61114737573839]],
    [ "Enedis",[6.1247495, 48.61277549999999]],
    [ "Enedis",[6.148978314977973, 48.6098085051395]],
    [ "Enedis",[6.13036486229764, 48.61200068948702]],
    [ "Enedis",[6.151906681165662, 48.60955141973873]],
    [ "Enedis",[6.150452172610419, 48.611958172320485]],
    [ "Enedis",[6.151415499999999, 48.61083750000001]],
    [ "Enedis",[6.173821150000001, 48.6071243]],
    [ "Enedis",[6.168066133736053, 48.5922468307744]],
    [ "Enedis",[6.165791362666666, 48.59866846866667]],
    [ "Enedis",[6.175106331450094, 48.59325648462021]],
    [ "Enedis",[6.1688331666666665, 48.60123300000001]],
    [ "Enedis",[6.169485753081142, 48.597190185438485]],
    [ "Enedis",[6.167654202219006, 48.5951441756874]],
    [ "Enedis",[6.172090249534451, 48.58893482335726]],
    [ "Enedis",[6.175435880000001, 48.58446865333333]],
    [ "Enedis",[6.179713823861748, 48.59186352143569]],
    [ "Enedis",[6.168748868927589, 48.59954664314086]],
    [ "Enedis",[6.17646447593482, 48.594031168314956]],
    [ "Enedis",[6.169332115204396, 48.60489620972693]],
    [ "Enedis",[6.1466267583450875, 48.59171553361543]],
    [ "Enedis",[6.148711061728394, 48.58779468209877]],
    [ "Enedis",[6.14923641339492, 48.58457982063125]],
    [ "Enedis",[6.152372674497241, 48.59522846574124]],
    [ "Enedis",[6.148588652098507, 48.589657039542146]],
    [ "Enedis",[6.1471465, 48.585338]],
    [ "Enedis",[6.150864787460148, 48.590291945448094]],
    [ "Enedis",[6.152831655187938, 48.58841346477605]],
    [ "Enedis",[6.2070226734186225, 48.64409283546553]],
    [ "Enedis",[6.211275499999999, 48.627847499999994]],
    [ "Enedis",[6.208271, 48.64177050000001]],
    [ "Enedis",[6.19743516251815, 48.62259996183364]],
    [ "Enedis",[6.206833387878787, 48.62529775151515]],
    [ "Enedis",[6.195870919641272, 48.618095914136035]],
    [ "Enedis",[6.2060254754673965, 48.616910207706326]],
    [ "Enedis",[6.186624999999999, 48.637595]],
    [ "Enedis",[6.21111204964539, 48.62645600000001]],
    [ "Enedis",[6.209703892538318, 48.61606062186289]],
    [ "Enedis",[6.194911886178862, 48.62294472357724]],
    [ "Enedis",[6.208588150921453, 48.62584507156916]],
    [ "Enedis",[6.199256882574534, 48.622113811107525]],
    [ "Enedis",[6.19934, 48.6104635]],
    [ "Enedis",[6.20438571227261, 48.61250016397643]],
    [ "Enedis",[6.186675658836093, 48.63763546378361]],
    [ "Enedis",[6.198337688657051, 48.61115672118237]],
    [ "Enedis",[6.205992721461187, 48.61301316894977]],
    [ "Enedis",[6.20921461610063, 48.62744369937107]],
    [ "Enedis",[6.1893495000000005, 48.63925600000001]],
    [ "Enedis",[6.198815848526026, 48.61271659632582]],
    [ "Enedis",[6.2050115587167065, 48.62385999757869]],
    [ "Enedis",[-0.76114075, 46.40690814999999]],
    [ "Les Saignes",[4.674036697052154, 45.69170182925169]],
    [ "réséda",[6.094663598915215, 49.111133346179464]],
    [ "réséda",[6.092746151138717, 49.107450683229814]],
    [ "réséda",[6.091771722431866, 49.105207408385745]],
    [ "Enedis",[4.715861499999999, 45.5510715]],
    [ "poste_5213",[2.632188, 49.359493]],
    [ "RTE",[1.2756546044683794, 45.1332778302253]],
    [ "Poste électrique de Marienau",[6.866626461387205, 49.17423847236615]],
    [ "Enedis",[3.2522495699871588, 49.59702537772886]],
    [ "Enedis",[3.2457818757222734, 49.59575560400745]],
    [ "Enedis",[3.231889007905138, 49.595338832674564]],
    [ "Enedis",[3.2482059379844963, 49.592857724806194]],
    [ "Enedis",[3.247974199134199, 49.59844763203462]],
    [ "Enedis",[2.9425076811071005, 47.935289161251504]],
    [ "Enedis",[2.9031879090909087, 47.93970966666666]],
    [ "Enedis",[2.9306312499999994, 47.924771249999985]],
    [ "Enedis",[2.9105758122308356, 47.925169752153316]],
    [ "Enedis",[2.9192201030690947, 47.9245237282127]],
    [ "Enedis",[2.9272095, 47.9206045]],
    [ "Enedis",[2.8892855, 47.905381500000004]],
    [ "Enedis",[2.9237041643151938, 47.92333233506095]],
    [ "Enedis",[2.894585565692353, 47.92735654650133]],
    [ "Enedis",[2.5041154509606516, 45.4165411068759]],
    [ "RTE",[2.264662879949006, 45.38790966104827]],
    [ "Poste électrique de La Mouche",[4.844337948093986, 45.72006949834421]],
    [ "RTE",[2.3487218730062347, 45.21845551980186]],
    [ "Enedis",[0.45102657186339956, 49.488949110764665]],
    [ "Enedis",[0.465129, 49.4804305]],
    [ "Sous Station de Meudon",[2.2474815000000006, 48.81420900000001]],
    [ "Enedis",[2.450146249999999, 48.75053775000001]],
    [ "Enedis",[2.4454357500000006, 48.72371625]],
    [ "Enedis",[2.430456164869051, 48.75289303558204]],
    [ "Enedis",[2.44724275, 48.75111449999999]],
    [ "Enedis",[2.4527990965829067, 48.738238013119854]],
    [ "Enedis",[2.4572201027831064, 48.744932150141885]],
    [ "Enedis",[2.4366264946723684, 48.746641259203436]],
    [ "Enedis",[2.449295856841324, 48.732025448352765]],
    [ "Poste électrique de Touvre",[0.24373529680065753, 45.67036264862891]],
    [ "Poste électrique de Volvic",[3.0657123305011362, 45.88953430629296]],
    [ "RTE",[3.1094561721751286, 45.8440612296874]],
    [ "RTE",[3.1184573135329745, 45.84408538395219]],
    [ "RTE",[2.841024782344564, 45.924478551544006]],
    [ "RTE",[2.837419910062357, 45.92174201016602]],
    [ "Sous-station de Flamina",[3.1325469404222335, 45.81339129683531]],
    [ "RTE",[3.134566489565951, 45.80489751749516]],
    [ "RTE",[3.107341958175906, 45.796144069758334]],
    [ "RTE",[3.1226493779794344, 45.884024485902444]],
    [ "Enedis",[0.29534371957084793, 49.47181393895673]],
    [ "Enedis",[0.3061355, 49.47768075]],
    [ "Enedis",[3.206956202154004, 46.099003859451415]],
    [ "Enedis",[3.1972194624419386, 46.02526047665379]],
    [ "Enedis",[0.36090175000000013, 49.52731874999999]],
    [ "Enedis",[0.35660399537264387, 49.52778101969806]],
    [ "Enedis",[0.35696210819672103, 49.531021798907105]],
    [ "Enedis",[6.106928726231386, 48.624582329133254]],
    [ "Enedis",[6.1017386718563555, 48.621929149031615]],
    [ "Enedis",[6.1057630000000005, 48.6285145]],
    [ "Enedis",[6.103751525335231, 48.62557282591669]],
    [ "Enedis",[6.10627575975976, 48.62697699417064]],
    [ "Enedis",[6.107018934116244, 48.621025260814754]],
    [ "Enedis",[6.112131423103689, 48.62339170215728]],
    [ "Enedis",[6.1095015482354595, 48.615745984915456]],
    [ "Enedis",[6.122214783262925, 48.614319723798026]],
    [ "Enedis",[6.116318799956226, 48.61367389363099]],
    [ "Enedis",[6.118487508474576, 48.61619804733547]],
    [ "Enedis",[2.8111031248429805, 45.725941022519685]],
    [ "RTE",[2.930947983718185, 46.102617531296325]],
    [ "RTE",[2.500442076803654, 46.033649455607595]],
    [ "RTE",[2.750275703072982, 46.295803537695996]],
    [ "RTE",[2.7522078462088615, 46.28851821078909]],
    [ "RTE",[2.222380432722644, 46.35363164352268]],
    [ "RTE",[2.8499057242593895, 46.41035789586152]],
    [ "Enedis",[2.3109717807807804, 50.90567366066066]],
    [ "Enedis",[6.101006328308208, 48.610909660134006]],
    [ "Enedis",[0.3437946249447113, 48.065555745456166]],
    [ "Enedis",[6.09869824106113, 48.61463796539793]],
    [ "Enedis",[6.094668133230611, 48.613613902413974]],
    [ "Enedis",[6.0978963900563805, 48.616417365436526]],
    [ "Enedis",[6.1283285, 48.692472499999994]],
    [ "Enedis",[6.136399354855236, 48.69641901332309]],
    [ "Enedis",[6.131145736730362, 48.693263781316354]],
    [ "Enedis",[6.137153722203331, 48.6989881194605]],
    [ "Enedis",[6.12946706062261, 48.691039331694896]],
    [ "Enedis",[6.134021687576875, 48.69571853136531]],
    [ "Enedis",[6.125962136150236, 48.69318423943662]],
    [ "Enedis",[6.129026544910179, 48.69477725548901]],
    [ "Enedis",[6.134017277083335, 48.69072336041667]],
    [ "Poste électrique de Séminaire",[3.3614911795871265, 46.53525729622636]],
    [ "Maison Rose",[4.700428513709361, 45.61868071073553]],
    [ "Platières",[4.703568306968485, 45.61559119383044]],
    [ "Rossbon",[4.686875764198933, 45.61848425743749]],
    [ "Francois Garbit",[4.703059920430108, 45.6179058032258]],
    [ "Poste électrique des Fosses",[3.110815105280967, 48.789934608802085]],
    [ "RTE",[0.3476554151654339, 46.633483661765794]],
    [ "RTE",[1.2983007983814163, 47.583665119484415]],
    [ "Dompierre",[3.6922290281087036, 46.51639259017212]],
    [ "Enedis",[0.3666274587196465, 49.557633629580586]],
    [ "Enedis",[0.3697141037197908, 49.5560735383317]],
    [ "Le Donjon",[3.798254984309059, 46.36241368189341]],
    [ "Enedis",[6.129181000000002, 48.69625]],
    [ "Enedis",[6.123521144278607, 48.69614123880597]],
    [ "Sous-station électrique",[6.131195526636227, 48.69930357990867]],
    [ "RTE",[2.240886551126922, 46.18569821922385]],
    [ "Poste d'Aubusson",[2.1891499379736286, 45.953668090324776]],
    [ "réséda",[6.0956011694352155, 49.11710352048726]],
    [ "réséda",[6.099769802469136, 49.119774462277086]],
    [ "réséda",[6.096702609022556, 49.12121773934837]],
    [ "réséda",[6.088189503267974, 49.11619914215685]],
    [ "réséda",[6.089012671094244, 49.120587457938]],
    [ "réséda",[6.084877738461538, 49.114052648717944]],
    [ "réséda",[6.0797962, 49.1257097]],
    [ "réséda",[6.085347566679453, 49.116536109321046]],
    [ "réséda",[6.0861495, 49.118879]],
    [ "réséda",[6.094254567430025, 49.11284237065309]],
    [ "AV 2 La libération",[6.1023122315661205, 49.10912337030267]],
    [ "Piscine",[6.098851331209289, 48.611931011894654]],
    [ "Enedis",[6.092191215574548, 48.61189322507121]],
    [ "Enedis",[6.10313619548872, 48.615370659147864]],
    [ "Enedis",[6.104601996665009, 48.612808999763175]],
    [ "Enedis",[6.102609408897014, 48.617440767215115]],
    [ "Enedis",[6.105962045158288, 48.61511665751086]],
    [ "Enedis",[6.104170363753925, 48.61696227520167]],
    [ "Enedis",[6.100960215629522, 48.61885334298119]],
    [ "réséda",[6.087371503321818, 49.10115316724245]],
    [ "réséda",[6.088350999999999, 49.10330550000001]],
    [ "réséda",[6.093035532390431, 49.09813215749956]],
    [ "RTE",[3.6412574064631897, 45.87329017302128]],
    [ "RTE",[3.0696169889112914, 48.658287339142866]],
    [ "Enedis",[-0.2948080225197023, 49.12351925809344]],
    [ "Enedis",[-0.31006985943775034, 49.11946215261043]],
    [ "Enedis",[-0.30285003856749326, 49.12277633057851]],
    [ "Enedis",[-0.29931820000000003, 49.124248550000004]],
    [ "Enedis",[-0.29591250000000086, 49.120529]],
    [ "Enedis",[-0.3095464999999995, 49.120832500000006]],
    [ "Enedis",[-0.30458368971751454, 49.12168255954803]],
    [ "Enedis",[-0.3055931094559577, 49.12315890986615]],
    [ "Enedis",[-0.29903764584674813, 49.12123292487659]],
    [ "Enedis",[-1.6725405500000001, 46.54274825]],
    [ "Enedis",[-1.6788220000000007, 46.545197249999994]],
    [ "RTE",[2.4782834979376576, 46.18506802477492]],
    [ "réséda",[6.103641539858417, 49.074727320406275]],
    [ "réséda",[6.1146274841618435, 49.06362037010656]],
    [ "réséda",[6.105227322678844, 49.07515094672755]],
    [ "réséda",[6.113176648963116, 49.077275833630104]],
    [ "réséda",[6.110743056565656, 49.07943516363636]],
    [ "réséda",[6.134358349999999, 49.046930849999995]],
    [ "réséda",[6.110605709362662, 49.08046285273547]],
    [ "réséda",[6.119017346014493, 49.0641269673913]],
    [ "réséda",[6.106738677506774, 49.07558101355013]],
    [ "réséda",[6.1084761666666685, 49.07385883333334]],
    [ "réséda",[6.119837228131823, 49.05561955546159]],
    [ "réséda",[6.119306, 49.059942]],
    [ "réséda",[6.113225899999998, 49.0590817]],
    [ "réséda",[6.108576999999999, 49.07541633333333]],
    [ "réséda",[6.103287, 49.0765665]],
    [ "réséda",[6.1052917350759, 49.077239048387675]],
    [ "réséda",[6.1157445, 49.06546469999999]],
    [ "réséda",[6.091069097165992, 49.094022693657216]],
    [ "réséda",[6.090844617513905, 49.09304720476006]],
    [ "réséda",[6.080785760614272, 49.09239605058717]],
    [ "réséda",[6.081040141935484, 49.09505082150538]],
    [ "réséda",[6.085605499999999, 49.092222500000005]],
    [ "réséda",[6.090544999999999, 49.0910955]],
    [ "Enedis",[2.3125825411445273, 45.56442433652637]],
    [ "RTE",[2.2650293292554204, 45.52262461467312]],
    [ "RTE",[2.2686868963818445, 45.524932177733234]],
    [ "Poste électrique de Sainte-Lucie de Porto-Vecchio",[9.33643995330089, 41.69978911327842]],
    [ "réséda",[6.073411999999999, 49.070791]],
    [ "réséda",[6.078011399999999, 49.0753395]],
    [ "réséda",[6.067920000000001, 49.078295999999995]],
    [ "réséda",[6.076963494265106, 49.07694467742699]],
    [ "réséda",[6.072504500000003, 49.0800375]],
    [ "réséda",[6.071867147400612, 49.07704171559633]],
    [ "réséda",[6.0586755000000005, 49.07993100000001]],
    [ "réséda",[6.079458666666666, 49.076918]],
    [ "réséda",[6.085166500000001, 49.07763]],
    [ "réséda",[6.08257145, 49.07639634999999]],
    [ "réséda",[6.075188805595755, 49.07373954992765]],
    [ "réséda",[6.067178791066281, 49.0796962567243]],
    [ "réséda",[6.063043, 49.0786455]],
    [ "réséda",[6.159982627482555, 49.04083984004294]],
    [ "réséda",[6.1599056817637186, 49.043105161993765]],
    [ "réséda",[6.161722120900635, 49.04523125012237]],
    [ "RTE",[2.0751935943371786, 45.400526580020866]],
    [ "réséda",[6.123367580168775, 49.023044427004216]],
    [ "réséda",[6.160860293532339, 49.031652457711445]],
    [ "réséda",[6.151905646129541, 49.01707395434439]],
    [ "réséda",[6.155277667802772, 49.03393948239036]],
    [ "réséda",[6.162789886990484, 49.032146072895465]],
    [ "réséda",[6.155703469466891, 49.01929415516594]],
    [ "réséda",[6.162760568847353, 49.03398979626169]],
    [ "réséda",[6.120960200000001, 49.022543]],
    [ "réséda",[6.193076261530923, 49.04446312416936]],
    [ "réséda",[6.187109823144767, 49.05011483804744]],
    [ "réséda",[6.183617312987011, 49.050961364502164]],
    [ "réséda",[6.190553999999999, 49.043606000000004]],
    [ "réséda",[6.197629208333335, 49.04081908333334]],
    [ "Enedis",[3.008303959248099, 46.114302829817774]],
    [ "RTE",[2.137795743048672, 45.68497007570418]],
    [ "Poste électrique de Pournoy la Grasse",[6.21509590082077, 49.01924873191609]],
    [ "Enedis",[4.220390133036527, 47.2811509469966]],
    [ "Enedis",[4.24381703047382, 47.277764796169826]],
    [ "Enedis",[4.234164374631268, 47.27517346902656]],
    [ "Enedis",[4.226804324045408, 47.2833441382869]],
    [ "Enedis",[4.2294256975097095, 47.27812354969157]],
    [ "Enedis",[4.2243454587517215, 47.27471461626304]],
    [ "Enedis",[4.226104479166666, 47.279684051767674]],
    [ "RTE",[1.9355382278948534, 45.7656416352314]],
    [ "138070758",[4.433889, 45.889498999999994]],
    [ "Hacienda 243 068",[4.4338806521739125, 45.88630220289855]],
    [ "Danguin n°498 33",[4.432648281397544, 45.88610484513692]],
    [ "Enedis",[4.415574135853617, 45.89308981097877]],
    [ "Blanchisserie 243046",[4.431302947826087, 45.895473771014494]],
    [ "Enedis",[4.428988666318038, 45.89478543283217]],
    [ "Enedis",[2.8217165934248434, 49.42099518703574]],
    [ "Enedis",[2.8164653452380954, 49.42078077380951]],
    [ "Enedis",[2.820063097959184, 49.42341232721088]],
    [ "Enedis",[2.8213342915049595, 49.42292763065976]],
    [ "Poste de Peyrat-le-Château",[1.803454117421442, 45.80185598105052]],
    [ "réséda",[6.160055377708114, 49.009087392551116]],
    [ "réséda",[6.16250420540062, 49.01030683709606]],
    [ "Enedis",[2.836977840014445, 49.41153469748404]],
    [ "Enedis",[2.831781699842022, 49.413093810426545]],
    [ "Enedis",[2.831235, 49.415516499999995]],
    [ "réséda",[6.1891941615686275, 49.00212265098038]],
    [ "réséda",[6.191057645161293, 49.004570978494634]],
    [ "MD Barbusse 99",[2.241955330914745, 48.807286396389394]],
    [ "Poste électrique d'Évreux",[1.180515737241658, 49.020417850026845]],
    [ "Poste Électrique de Cambole",[1.0952977568378672, 49.041460474793915]],
    [ "Poste électrique de Saint-Pierre-de-Bailleul",[1.3719880960931368, 49.12755945756408]],
    [ "réséda",[6.267681800000002, 49.0940605]],
    [ "réséda",[6.267498499999999, 49.0906215]],
    [ "réséda",[6.271518002551519, 49.095785281084474]],
    [ "réséda",[6.275584212678938, 49.0946407402863]],
    [ "RTE",[2.341680482341974, 45.86866704442364]],
    [ "Coincy Village",[6.28055475, 49.109894000000004]],
    [ "Lotissement Vignes Coincy",[6.284036977798269, 49.11103469670817]],
    [ "RTE",[3.1101583070584695, 46.7986007059472]],
    [ "réséda",[6.270652112023794, 49.14815742765473]],
    [ "réséda",[6.277875540263544, 49.150653335773555]],
    [ "réséda",[6.245335670088241, 49.156395563299114]],
    [ "réséda",[6.241741171531533, 49.15653481801802]],
    [ "RTE",[0.9014026477087403, 45.40995858210434]],
    [ "Poste électrique de Porto-Vecchio",[9.26687368200825, 41.590138095236924]],
    [ "RTE",[1.8900798984380023, 46.18280429154725]],
    [ "Poste électrique de Saint-Feyre",[1.921074503879546, 46.196708535725435]],
    [ "Enedis",[7.299882074950691, 43.69755923964497]],
    [ "Poste électrique de Combourg",[-1.7210011574876138, 48.42012913873508]],
    [ "Poste électrique de Taglio-Isolaccio",[9.502428652417464, 42.44060202433318]],
    [ "Poste électrique de Cervione",[9.540076574244702, 42.314548256376]],
    [ "Enedis",[2.87266699122807, 47.429491873684206]],
    [ "Enedis",[2.8751754969818917, 47.42478655734407]],
    [ "Poste électrique de Révigny sur Ornain",[4.998007903344064, 48.8178620143411]],
    [ "Poste RTE de Lavaud",[1.8958264963397047, 46.15010689742711]],
    [ "Enedis",[5.619378215940909, 46.1422204670961]],
    [ "Enedis",[5.619447022710624, 46.1405354051282]],
    [ "Poste électrique de Ghisonaccia",[9.370514393447646, 42.065145384082534]],
    [ "Enedis",[1.9033847545595126, 45.33872562570838]],
    [ "Benodet",[-4.055191080724926, 47.887059416783025]],
    [ "Enedis",[2.623062429904363, 45.560649831817116]],
    [ "Enedis",[7.228867999999999, 43.7280455]],
    [ "Enedis",[2.5503552708333324, 45.52606878125]],
    [ "Poste électrique de Bruges",[-0.6366033331683376, 44.890914106620635]],
    [ "Enedis",[7.227420457300276, 43.72349579155188]],
    [ "Enedis",[1.7562020565145726, 45.2539051445876]],
    [ "RTE",[1.7453038641904304, 45.342314695393576]],
    [ "Enedis",[7.227798899413243, 43.71818951383067]],
    [ "Enedis",[7.22755140217036, 43.72112770687063]],
    [ "Enedis",[7.245720307735584, 43.72111331758087]],
    [ "Enedis",[7.246156087459588, 43.718468900516136]],
    [ "Enedis",[2.8287326567164173, 49.422101985074626]],
    [ "RTE",[1.9529815176318512, 45.10477028450873]],
    [ "Enedis",[2.854568886037449, 49.42887778015397]],
    [ "Enedis",[2.843303, 49.425253833333336]],
    [ "Enedis",[2.847098326635755, 49.43171626300876]],
    [ "Enedis",[2.835454122270006, 49.42605365916182]],
    [ "Enedis",[2.8474925000000004, 49.427207499999994]],
    [ "Enedis",[2.843513211877651, 49.42724796584059]],
    [ "Enedis",[2.8326633511973727, 49.42358587553109]],
    [ "Enedis",[2.838024, 49.42133450000001]],
    [ "Enedis",[2.8475344034171806, 49.42722948615725]],
    [ "RTE",[2.0372278117711877, 45.21572363519305]],
    [ "Enedis",[6.243614710489788, 48.681230054134446]],
    [ "Enedis",[6.2444381358024685, 48.68738511111111]],
    [ "Enedis",[6.24042260422861, 48.68795222629666]],
    [ "Enedis",[6.241770122544123, 48.678745724109234]],
    [ "Enedis",[6.247882907420664, 48.688440421856356]],
    [ "Enedis",[6.251069944377323, 48.69153498697335]],
    [ "Enedis",[6.242403690058478, 48.69193804093567]],
    [ "Enedis",[6.241446593301435, 48.68947681180224]],
    [ "Enedis",[6.237379900772993, 48.6868656961666]],
    [ "Enedis",[6.245156735492578, 48.6923971705803]],
    [ "Enedis",[6.234902227390181, 48.677572552971576]],
    [ "Enedis",[6.25011932768893, 48.692575975540926]],
    [ "Enedis",[6.236559661465952, 48.6783903308955]],
    [ "Enedis",[6.2467154032258065, 48.699506602150535]],
    [ "Enedis",[6.24564381459028, 48.68991370748712]],
    [ "Enedis",[6.2381288038125495, 48.676131732327235]],
    [ "Enedis",[6.235159643235706, 48.68564217963738]],
    [ "Enedis",[6.245256327164888, 48.693884830130486]],
    [ "Poste électrique de Corte",[9.165829678600314, 42.301208724550875]],
    [ "Poste électrique de Donzenac",[1.5223870721315964, 45.21457994561432]],
    [ "Enedis",[2.8261821730566825, 49.42432467839422]],
    [ "Enedis",[2.8148468436578167, 49.431266595870206]],
    [ "Enedis",[2.8226652728114026, 49.423073383305564]],
    [ "Jeanne d'Arc",[2.827967006656157, 49.42905683307059]],
    [ "Enedis",[2.820334830951841, 49.4262704819687]],
    [ "Enedis",[2.8132624349510134, 49.42313255365687]],
    [ "Enedis",[2.82388977967192, 49.42996115171009]],
    [ "Enedis",[2.820459146341464, 49.4281653902439]],
    [ "Enedis",[2.8068178491484184, 49.426276540145984]],
    [ "Enedis",[2.8283903334887692, 49.430464502059536]],
    [ "Enedis",[2.8094436652472203, 49.426949503430336]],
    [ "Enedis",[2.8158506470195355, 49.427135486810265]],
    [ "Enedis",[2.8074807592740325, 49.425411719399015]],
    [ "Bringer",[2.8338676030160217, 49.42921044209865]],
    [ "Enedis",[2.8236741131280896, 49.428277716804324]],
    [ "Enedis",[2.8223156089113557, 49.424878845736224]],
    [ "Enedis",[6.606194227888811, 49.139638050769584]],
    [ "Enedis",[6.607656785964912, 49.13830484561404]],
    [ "Enedis",[6.611052017412937, 49.142230099502484]],
    [ "Enedis",[0.358473, 49.64107950000001]],
    [ "Enedis",[0.36410641761533874, 49.649931801477926]],
    [ "Enedis",[2.8036488183454855, 49.414338250067516]],
    [ "Enedis",[2.799564000000001, 49.416903]],
    [ "Enedis",[2.7791625000000004, 49.409713999999994]],
    [ "réséda",[6.14801068224299, 49.18364846417445]],
    [ "réséda",[6.149091627051872, 49.17239821098709]],
    [ "réséda",[6.1500643, 49.1803856]],
    [ "réséda",[6.14861523578508, 49.178158086071996]],
    [ "Enedis",[2.778884630701755, 49.40840115350876]],
    [ "réséda",[6.106511830240808, 49.178426197994455]],
    [ "réséda",[6.096318399999999, 49.1835326]],
    [ "Enedis",[2.7719889999999996, 49.41255299999999]],
    [ "Enedis",[2.7782556766666664, 49.40952504]],
    [ "Enedis",[2.8081410000000004, 49.4139125]],
    [ "Enedis",[7.2350307804859, 43.71344043941879]],
    [ "Enedis",[7.234320215499485, 43.71491461813422]],
    [ "Enedis",[2.8057733030913985, 49.404429668682795]],
    [ "Enedis",[6.288815203380878, 48.63474273005811]],
    [ "Enedis",[6.288056639464483, 48.62917367877547]],
    [ "Enedis",[6.293629106060605, 48.62930924053029]],
    [ "Enedis",[6.292099399206053, 48.63220012107679]],
    [ "Enedis",[6.286038042944785, 48.61906582617588]],
    [ "Enedis",[6.286546500000002, 48.621016000000004]],
    [ "Enedis",[6.283357849999998, 48.62076065000001]],
    [ "Poste électrique de Varennes-Sur-Fouzon",[1.596775689483296, 47.19176784016709]],
    [ "Enedis",[6.296929184126983, 48.622675195238095]],
    [ "Enedis",[6.297103492909987, 48.626533833436085]],
    [ "Enedis",[6.294077000000001, 48.623208666666685]],
    [ "Enedis",[6.289666672347332, 48.62186198133495]],
    [ "Enedis",[6.294893639423617, 48.624249139817614]],
    [ "Enedis",[6.293613323624595, 48.62588262561258]],
    [ "Enedis",[6.289350635814888, 48.62581344533869]],
    [ "Poste électrique de Talamet",[1.9488252860371267, 44.96690501819315]],
    [ "RTE",[1.790734263491113, 44.99726327464485]],
    [ "RTE",[1.9486498174559141, 44.95349576611242]],
    [ "Enedis",[6.299057212755831, 48.633811324290015]],
    [ "Enedis",[6.303132022534164, 48.6348709084733]],
    [ "Enedis",[6.300653014461316, 48.63190913810557]],
    [ "Enedis",[6.301671886920438, 48.629049169419844]],
    [ "Enedis",[2.8601151929345456, 49.430081021326394]],
    [ "Enedis",[6.307920976843811, 48.632717267338194]],
    [ "Enedis",[6.30981570390071, 48.62834485283688]],
    [ "Enedis",[6.307721180811808, 48.63094066174661]],
    [ "Enedis",[6.299349823720708, 48.622577676327104]],
    [ "Enedis",[6.302804, 48.626301]],
    [ "Enedis",[6.298356666666667, 48.62718901449275]],
    [ "Enedis",[6.301229831591887, 48.62501243904938]],
    [ "réséda",[6.326746339354407, 49.18999650878074]],
    [ "réséda",[6.365287314767932, 49.20538445822785]],
    [ "réséda",[6.32698328148148, 49.18764882378167]],
    [ "Enedis",[6.307539666666667, 48.62647651476793]],
    [ "réséda",[6.279105709240445, 49.18393255055636]],
    [ "réséda",[6.26843711982571, 49.186822237472775]],
    [ "réséda",[6.281215607221388, 49.18289348331188]],
    [ "réséda",[6.2642275000000005, 49.1872155]],
    [ "réséda",[6.279605585944919, 49.18143288319087]],
    [ "réséda",[6.2662116103286385, 49.19007970422535]],
    [ "Enedis",[4.745311785003248, 45.50534714629488]],
    [ "Enedis",[7.237384147001393, 43.69726816903765]],
    [ "Enedis",[-0.746169, 43.5936829]],
    [ "Enedis",[-0.7453918000000002, 43.59163430000001]],
    [ "réséda",[6.140303079136691, 49.191380767386086]],
    [ "réséda",[6.13824227293065, 49.190500436241614]],
    [ "réséda",[6.128568709915106, 49.19238694473811]],
    [ "réséda",[6.114800914544425, 49.17074489869835]],
    [ "réséda",[6.146271202998847, 49.190863637831605]],
    [ "réséda",[6.1198908385195905, 49.196323626991166]],
    [ "réséda",[6.149681, 49.1974425]],
    [ "réséda",[6.1140585, 49.176717]],
    [ "réséda",[6.142428357634669, 49.19249818388012]],
    [ "réséda",[6.146586522640479, 49.18679467484997]],
    [ "réséda",[6.1167869237956305, 49.19253469421484]],
    [ "réséda",[6.136090800000002, 49.1931405]],
    [ "réséda",[6.147721957831735, 49.19042727547118]],
    [ "réséda",[6.1491232810164425, 49.18474996860987]],
    [ "Ferouge",[1.4663567188474875, 44.91564983550991]],
    [ "Enedis",[7.230715622856577, 43.68874986113588]],
    [ "Enedis",[2.7841249779527555, 49.387764675328086]],
    [ "Enedis",[2.795891487664042, 49.38711532703412]],
    [ "Enedis",[2.7839575, 49.39350050000001]],
    [ "Enedis",[2.7888830701786813, 49.38406173234752]],
    [ "Enedis",[2.7951668333333335, 49.388764]],
    [ "Enedis",[2.793318173051519, 49.38672299383532]],
    [ "Enedis",[2.7870795000000004, 49.394941]],
    [ "Enedis",[2.7884007027863777, 49.38979183694531]],
    [ "Enedis",[2.795838830174375, 49.39097980414455]],
    [ "Enedis",[2.7888487499999997, 49.383928749999995]],
    [ "Enedis",[2.788693380090497, 49.39426046848739]],
    [ "Enedis",[6.9603, 48.0631015]],
    [ "Enedis",[5.845179121103116, 48.72296333309352]],
    [ "Poste électrique de Calvi",[8.746743198374277, 42.560691535381736]],
    [ "Poste électrique de L'Île-Rousse",[8.9372696900059, 42.62630569090708]],
    [ "Poste électrique d'Ocana",[8.934556215251416, 41.94641210173059]],
    [ "RTE",[4.899683596048452, 43.453349970646805]],
    [ "Poste électrique de Corscia",[9.044613790869189, 42.34242440651239]],
    [ "Enedis",[6.146754682297064, 48.79482745613126]],
    [ "Enedis",[6.1431465, 48.792212]],
    [ "Enedis",[6.133159833663366, 48.78818752673266]],
    [ "Enedis",[6.146673566666667, 48.79058669921937]],
    [ "Enedis",[6.141126817813766, 48.79043821952316]],
    [ "Enedis",[6.139580499999999, 48.79375199999999]],
    [ "Enedis",[6.137360049333332, 48.78313660266666]],
    [ "Enedis",[6.155321215840718, 48.79670173011706]],
    [ "Enedis",[6.135474599999999, 48.7879394]],
    [ "Enedis",[6.145829172308434, 48.78149602983853]],
    [ "Enedis",[6.144196342507647, 48.788590162079515]],
    [ "Enedis",[6.1565632729658795, 48.79496659055119]],
    [ "Enedis",[6.1450183075, 48.78424991583333]],
    [ "Enedis",[6.13563914710799, 48.78321453263122]],
    [ "Enedis",[6.117504402534702, 48.794696674311]],
    [ "Enedis",[6.151395741233374, 48.79182158917776]],
    [ "Enedis",[6.203324234692387, 48.793111465292995]],
    [ "Enedis",[6.196900766051012, 48.79192169129288]],
    [ "Enedis",[6.200338220444446, 48.79197429066667]],
    [ "Enedis",[6.164512, 48.7975435]],
    [ "Enedis",[6.163247227627625, 48.793731595795805]],
    [ "Enedis",[6.161113391042982, 48.79374725819056]],
    [ "Enedis",[6.168226180180181, 48.79304397297297]],
    [ "Enedis",[6.161566999999999, 48.7918325]],
    [ "Enedis",[6.116284803921569, 48.80103458823529]],
    [ "Enedis",[1.7997138332840967, 50.105233497685866]],
    [ "Enedis",[1.8073231525984774, 50.12373327077126]],
    [ "Poste électrique de Propriano",[8.917298775116631, 41.66205260349033]],
    [ "Poste électrique de Santa-Maria-Sicche",[8.973085451872095, 41.881183772882615]],
    [ "Enedis",[1.820999, 50.09092533333333]],
    [ "Enedis",[1.8282605228758155, 50.09429675816993]],
    [ "Enedis",[1.8701915, 50.09533150000001]],
    [ "Enedis",[1.8552965088924098, 50.09740456013383]],
    [ "Enedis",[1.8426961889946285, 50.092950993826264]],
    [ "Enedis",[1.8619041653717243, 50.10930767154174]],
    [ "Enedis",[1.8625428194956943, 50.108503182964334]],
    [ "Enedis",[1.8665260113330768, 50.11224112910744]],
    [ "Enedis",[1.86252975, 50.111045499999996]],
    [ "Enedis",[1.844922082077052, 50.10455379899498]],
    [ "Enedis",[1.843467097085789, 50.099851696954815]],
    [ "Enedis",[1.8457490616472971, 50.10170982078492]],
    [ "Enedis",[1.8351564348717946, 50.10292797846153]],
    [ "Enedis",[1.8372399962449468, 50.10498074147891]],
    [ "Enedis",[1.833457124844611, 50.10078019534718]],
    [ "Enedis",[1.8390757500000001, 50.102808749999994]],
    [ "Enedis",[1.835296626623156, 50.10558726684342]],
    [ "Enedis",[1.8292336590182974, 50.107874688449996]],
    [ "Enedis",[1.830287489131378, 50.10882950835715]],
    [ "Enedis",[1.8315294693644917, 50.107157638453494]],
    [ "Enedis",[1.833733921557234, 50.107181739686226]],
    [ "Enedis",[0.9985764, 49.363919100000004]],
    [ "Enedis",[1.0153459532866203, 49.34404515582248]],
    [ "CITE LOHEAC",[0.9934155932203386, 49.34727318079096]],
    [ "Enedis",[1.0241360526180696, 49.34553900624571]],
    [ "Enedis",[1.0009500215633425, 49.35119760287511]],
    [ "Enedis",[1.0253539999999997, 49.346244]],
    [ "Enedis",[1.0176924977924948, 49.33698318432671]],
    [ "Enedis",[1.0191598866532527, 49.3418858562486]],
    [ "Enedis",[1.0063386636455183, 49.35854451548339]],
    [ "Enedis",[1.0175952516288758, 49.34170745126401]],
    [ "Enedis",[1.0226733962264145, 49.341061379874205]],
    [ "Enedis",[1.8507314395026302, 50.10521899234816]],
    [ "Enedis",[1.8270313543990087, 50.11107757372987]],
    [ "Enedis",[1.8288702324295159, 50.11180834641846]],
    [ "Enedis",[1.817583302405498, 50.11861121950172]],
    [ "Enedis",[1.839250943202189, 50.11802696395887]],
    [ "Enedis",[1.8208856796747974, 50.10749603902439]],
    [ "Enedis",[1.8293110270717698, 50.097377779530625]],
    [ "Enedis",[1.8422570107526883, 50.10889473655914]],
    [ "Enedis",[1.85912325, 50.100955750000004]],
    [ "Enedis",[1.8356080499999992, 50.111793899999995]],
    [ "Enedis",[1.8343039999999995, 50.11781924999999]],
    [ "Enedis",[1.8260067524020693, 50.10957651441242]],
    [ "Enedis",[1.82052455332652, 50.1171976224607]],
    [ "Enedis",[1.8176146421368222, 50.1106107331698]],
    [ "Enedis",[1.8256044177777775, 50.12025244533333]],
    [ "Enedis",[1.8362559819121453, 50.11139912107789]],
    [ "Enedis",[1.8212378371076683, 50.11123349225267]],
    [ "Enedis",[1.8397143144654091, 50.11113166666666]],
    [ "Enedis",[1.8487866896262783, 50.10965961471427]],
    [ "Enedis",[1.809371622469199, 50.11021619531317]],
    [ "Enedis",[1.8499782502931124, 50.09753025064826]],
    [ "Enedis",[1.8197037405376735, 50.11912761243413]],
    [ "Enedis",[1.8505374999999997, 50.10234049999999]],
    [ "Enedis",[1.8520056914167855, 50.10808021719817]],
    [ "Enedis",[1.8382851206700737, 50.110563925042584]],
    [ "Enedis",[1.8308264337274414, 50.110696980294534]],
    [ "Enedis",[1.8541239653791128, 50.10457238865046]],
    [ "Enedis",[1.8483617821716716, 50.11329949716944]],
    [ "Bourg Puycelsi",[1.7104657874015747, 43.993164076115484]],
    [ "Enedis",[3.09726031128238, 46.024904758724034]],
    [ "Enedis",[2.3705367518697025, 48.78044707445341]],
    [ "Enedis",[2.3810282939670357, 48.79431333525365]],
    [ "139575503",[2.032556259774493, 44.98285881348499]],
    [ "Poste de Hautefage",[1.9759950245316587, 45.07114812762248]],
    [ "Poste électrique de Mougins",[7.007803909270581, 43.58412629576627]],
    [ "RTE",[3.3394778897915285, 47.98385895827553]],
    [ "Enedis",[-0.8264479999999998, 43.62983034999999]],
    [ "Enedis",[-0.5824835, 44.7745735]],
    [ "Enedis",[-0.5694028273588793, 44.780244006180475]],
    [ "Loiseau",[-0.5890621106719365, 44.78642722793148]],
    [ "Enedis",[-0.5725223194007822, 44.778173439333294]],
    [ "Poste electrique de Montceau",[1.839800931948483, 45.576007448120556]],
    [ "Enedis",[-0.5771386421219318, 44.775891186064925]],
    [ "Enedis",[-0.558547500000001, 44.78107049999999]],
    [ "Enedis",[-0.5669889939775784, 44.78322908786642]],
    [ "Poste électrique de La Veytisou",[1.6711698996047932, 45.73253284823528]],
    [ "Enedis",[-0.3218646440352897, 49.30408767868559]],
    [ "Enedis",[-0.32517286868686873, 49.302136565656575]],
    [ "Enedis",[-0.3188027205604951, 49.29966572891405]],
    [ "Enedis",[-0.32943350536864713, 49.30276466690527]],
    [ "Enedis",[-0.32666450488058163, 49.30370446554517]],
    [ "Enedis",[-0.3202427668097279, 49.29702547424892]],
    [ "Enedis",[0.34671935309183627, 48.066580579579835]],
    [ "Enedis",[6.880127681115446, 43.64300257766218]],
    [ "Poste RTE de Saint-Léonard",[1.4865700245778044, 45.84746427168055]],
    [ "Enedis",[2.268378505413499, 49.93387933448515]],
    [ "Enedis",[2.2814391451839375, 49.93388127014884]],
    [ "Enedis",[2.282193632776935, 49.92614618070308]],
    [ "Enedis",[2.283116789437788, 49.92719552898762]],
    [ "Enedis",[2.283732038318351, 49.92812065894852]],
    [ "Enedis",[2.281898335910321, 49.9342815023837]],
    [ "Poste RTE de Maureix",[1.4636688960680209, 45.92555010710454]],
    [ "RTE",[1.7878323028283456, 45.9640826860185]],
    [ "Enedis",[2.29710777365545, 49.91923984967884]],
    [ "Enedis",[2.294608693519514, 49.92279503938418]],
    [ "Enedis",[2.3043987419189986, 49.924674714961036]],
    [ "Enedis",[2.3025405, 49.924451499999996]],
    [ "Enedis",[2.2932501931330473, 49.92047053505007]],
    [ "RTE",[1.5773792261255093, 45.97939416062304]],
    [ "RTE",[1.4664364837464858, 45.980326331723376]],
    [ "Enedis",[5.6851539526881725, 46.18422697741936]],
    [ "RTE",[1.6161328647920876, 46.001137815591086]],
    [ "Enedis",[2.98099164126984, 49.56969852310406]],
    [ "Enedis",[2.975959405009634, 49.571616700963396]],
    [ "Enedis",[3.001477709090909, 49.586810355331096]],
    [ "Enedis",[2.9937551166666676, 49.58771426111111]],
    [ "Enedis",[2.9929937989623863, 49.57413902204929]],
    [ "Enedis",[2.997258335378323, 49.57470350306748]],
    [ "Enedis",[3.0161321294919454, 49.57393760594795]],
    [ "Enedis",[3.0036026901798065, 49.576189535269705]],
    [ "Enedis",[2.99184, 49.575992]],
    [ "Enedis",[2.9839684000000006, 49.586783800000006]],
    [ "Enedis",[3.019773802612994, 49.573364158545196]],
    [ "Enedis",[3.025895999999999, 49.573361000000006]],
    [ "Enedis",[3.0250636969696982, 49.57605297607656]],
    [ "Enedis",[3.0190406568969523, 49.57910624726679]],
    [ "Enedis",[3.0102088828516385, 49.58906673448941]],
    [ "Enedis",[3.0235680000000005, 49.57568200000001]],
    [ "Enedis",[3.0143654999999994, 49.580184499999994]],
    [ "Enedis",[2.999133113475177, 49.58560617133258]],
    [ "Enedis",[3.007520016682229, 49.57914688839092]],
    [ "Enedis",[2.991220970810195, 49.580756169870426]],
    [ "Enedis",[2.9876161854334233, 49.58514248882932]],
    [ "Enedis",[3.01074013317783, 49.57336516961012]],
    [ "Enedis",[3.0214130000000003, 49.5760075]],
    [ "Enedis",[2.9951118809073725, 49.577232576559545]],
    [ "Enedis",[3.0257462035610327, 49.57339069645607]],
    [ "Enedis",[2.99693691126943, 49.577971435880826]],
    [ "Enedis",[3.0133258383884627, 49.58216111296784]],
    [ "Poste RTE de St Marc",[1.4569415634633374, 45.926843861184246]],
    [ "Enedis",[7.2720445626500565, 43.72071895321523]],
    [ "Enedis",[7.2615240387153195, 43.720188941648786]],
    [ "Enedis",[7.262723199837224, 43.71717067255319]],
    [ "Enedis",[7.263432090963055, 43.71624360877156]],
    [ "Bois de la Ville",[6.043850749999999, 49.18361829999999]],
    [ "Jaumont",[6.041895900000001, 49.190285849999995]],
    [ "Patural",[6.035681300000001, 49.19163809999999]],
    [ "St Jacques",[6.040012075557487, 49.18589139345498]],
    [ "Conot",[6.0438795999999995, 49.18726235]],
    [ "Enedis",[2.3293035, 49.91007850000001]],
    [ "Enedis",[2.3180633046413495, 49.912221820253166]],
    [ "Enedis",[2.3147892499999996, 49.91416549999999]],
    [ "Enedis",[2.3248103157038766, 49.90875954456153]],
    [ "Enedis",[2.316126713121434, 49.90056995110025]],
    [ "Enedis",[2.3005355922865016, 49.91724676584023]],
    [ "Enedis",[2.3123091122931436, 49.90178027304965]],
    [ "Poste électrique de la Martinerie",[1.3769052753360576, 45.903357507599296]],
    [ "Enedis",[2.2926087421279933, 49.90566488080094]],
    [ "Enedis",[2.2624725, 49.923669]],
    [ "Enedis",[2.2646587114040337, 49.924420062512475]],
    [ "Enedis",[2.2640033772163117, 49.92255204432625]],
    [ "Enedis",[2.2789633561020044, 49.915058863843356]],
    [ "Enedis",[2.2734129999999992, 49.917525000000005]],
    [ "Enedis",[2.31181675, 49.89951249999999]],
    [ "Poste électrique C.G.E.P.",[1.3277373917918338, 45.879370484764436]],
    [ "Enedis",[2.253601430317848, 49.91764779054605]],
    [ "Enedis",[2.2465968376611913, 49.91314417011129]],
    [ "Enedis",[2.247343534262486, 49.91300278629501]],
    [ "Enedis",[2.2494101909318758, 49.91384501480976]],
    [ "Enedis",[2.2582661758701477, 49.914758992302545]],
    [ "Enedis",[2.279097924624954, 49.90756062605196]],
    [ "Enedis",[2.272860546047039, 49.905069843770654]],
    [ "Enedis",[2.2747509938151045, 49.907079764322916]],
    [ "Enedis",[2.2860251818865094, 49.90319652198421]],
    [ "Enedis",[2.282016448536355, 49.90585789305949]],
    [ "Enedis",[7.277884180217851, 43.7093392719013]],
    [ "Enedis",[7.278104791204006, 43.703955813194]],
    [ "Enedis",[2.2975652996188796, 49.89939694810906]],
    [ "SNCF Réseau",[1.3973806529083768, 45.68205249022701]],
    [ "Enedis",[2.276359833333332, 49.901970000000006]],
    [ "Enedis",[2.2829406568038886, 49.90017530331618]],
    [ "Enedis",[2.2601672389251997, 49.897721087872185]],
    [ "Enedis",[2.2613267055016184, 49.896457828478965]],
    [ "Enedis",[2.263139849180328, 49.89867656174863]],
    [ "Enedis",[2.2756505805964515, 49.89743021819555]],
    [ "Sous-station du repaire",[1.4862178163945128, 45.60499573991392]],
    [ "RTE",[1.5501047372474472, 45.43009486561982]],
    [ "SNCF Réseau",[1.5506045010311007, 45.52092017651544]],
    [ "RTE",[1.4732073247293351, 45.31922301761058]],
    [ "RTE",[1.495516733715958, 45.238236995282925]],
    [ "Enedis",[7.229521, 43.68789600000001]],
    [ "Enedis",[7.218689685703186, 43.68723328554779]],
    [ "RTE",[1.4650943873148188, 45.28489922395782]],
    [ "Enedis",[2.2910121837398365, 49.89118697091237]],
    [ "Enedis",[2.2879250949494954, 49.89164884385028]],
    [ "Enedis",[2.292156, 49.89290450000001]],
    [ "Enedis",[2.2957263269024653, 49.89317584030011]],
    [ "Enedis",[2.2964523678160917, 49.892424747126434]],
    [ "Enedis",[2.299881297442509, 49.888476554480974]],
    [ "RTE",[1.403929718352302, 45.429114888791666]],
    [ "Enedis",[2.3043386780432304, 49.8950468378027]],
    [ "Enedis",[2.3057253135011444, 49.89486152479023]],
    [ "Enedis",[2.3091185751789975, 49.88842970246619]],
    [ "Enedis",[2.325151476190476, 49.89094975238095]],
    [ "Enedis",[2.319451784946237, 49.8926935483871]],
    [ "Enedis",[2.319691888470589, 49.890642852862754]],
    [ "Enedis",[2.3151275409041143, 49.89134745935658]],
    [ "Enedis",[2.3252226518188563, 49.889572524127686]],
    [ "Enedis",[2.319429253665165, 49.89266131774296]],
    [ "RTE",[1.2210104236523367, 45.536652200311174]],
    [ "Enedis",[3.2983378447488594, 46.02439482100457]],
    [ "Enedis",[2.3184381719909646, 49.88633492900936]],
    [ "Enedis",[2.323246721853176, 49.88556632175097]],
    [ "Enedis",[2.3187306174661746, 49.885522292435425]],
    [ "Enedis",[2.3344413333333334, 49.885054468468475]],
    [ "Enedis",[2.3356049799691836, 49.88179616640987]],
    [ "RTE",[1.5519556469316351, 45.164377816492646]],
    [ "RTE",[1.6394578488801608, 45.191420479734795]],
    [ "Poste électrique de Juniat",[1.34805216398314, 45.95033283462775]],
    [ "Enedis",[7.5255655, 48.31264899999999]],
    [ "Enedis",[7.528431749999999, 48.312830999999996]],
    [ "Enedis",[2.268670927913469, 49.886315575219214]],
    [ "Enedis",[2.3005512731182782, 49.88479252759856]],
    [ "Poste électrique de Saint-Junien",[0.920112448988259, 45.87747725155349]],
    [ "Poste électrique de Gorre (Sethelec)",[0.8130013732916298, 45.872733829545005]],
    [ "RTE",[0.8125593961233172, 45.87251132330065]],
    [ "Poste électrique de Tannin",[0.8101085679439403, 45.87800079786138]],
    [ "Enedis",[2.3167609631613315, 49.88646907204801]],
    [ "Poste électrique de Champagnac",[0.8988440119087082, 45.719563459943174]],
    [ "Enedis",[2.331321641025641, 49.880682794871795]],
    [ "Enedis",[2.341655, 49.877449000000006]],
    [ "Enedis",[2.3351269105945844, 49.877552355097066]],
    [ "Enedis",[2.335873424892703, 49.87854763519313]],
    [ "Enedis",[2.326855797281161, 49.88250053439883]],
    [ "Enedis",[2.3243641836569577, 49.88090395954693]],
    [ "Enedis",[2.3207288251872176, 49.88105416498028]],
    [ "Enedis",[2.311434853497502, 49.88348291291935]],
    [ "Enedis",[2.3076945989222475, 49.88222277829099]],
    [ "Enedis",[2.3165656666666656, 49.88215299999999]],
    [ "Enedis",[2.335590395541004, 49.867432576941056]],
    [ "Enedis",[2.3388177189676362, 49.86468841963676]],
    [ "Enedis",[2.3231995000000003, 49.87012325]],
    [ "Enedis",[2.3305137698026877, 49.8689513148413]],
    [ "Enedis",[2.32671175, 49.86849074999999]],
    [ "Enedis",[2.3364035, 49.869685749999995]],
    [ "Enedis",[2.335201507550077, 49.87250999506934]],
    [ "Enedis",[2.329457104914412, 49.8718907826247]],
    [ "Enedis",[2.3313502801079227, 49.87305363944076]],
    [ "Enedis",[2.33385420603201, 49.87630854574891]],
    [ "Enedis",[2.3326636175799083, 49.87507447031964]],
    [ "Enedis",[2.3277922656716408, 49.87569326208955]],
    [ "Enedis",[2.3192701584062196, 49.875202283770655]],
    [ "Enedis",[2.3158187275472324, 49.875155396677286]],
    [ "Enedis",[2.316030282346856, 49.87089848281977]],
    [ "Enedis",[2.3173495, 49.8714485]],
    [ "Enedis",[2.324648009043215, 49.87052798699132]],
    [ "Enedis",[2.31641500283371, 49.869331233468856]],
    [ "Enedis",[2.320872742954587, 49.87679105797882]],
    [ "Enedis",[2.3202500537229045, 49.87759755105246]],
    [ "Enedis",[2.3276127438232836, 49.88214161390285]],
    [ "Enedis",[2.3128370608699895, 49.871840133996]],
    [ "Enedis",[2.3117941704935556, 49.869469547385286]],
    [ "Enedis",[2.3116605000000003, 49.873538249999996]],
    [ "Enedis",[2.30747215266408, 49.86912548671549]],
    [ "Enedis",[2.308266633235268, 49.86566376999197]],
    [ "Enedis",[2.310205170642717, 49.87576848900739]],
    [ "Enedis",[2.3104318599822005, 49.87930133056462]],
    [ "Enedis",[2.3078263133579946, 49.87932948409371]],
    [ "Enedis",[2.309266274261604, 49.87847075527427]],
    [ "Enedis",[2.292205647338403, 49.88425584785594]],
    [ "Enedis",[2.2883583545937736, 49.88843966818528]],
    [ "Enedis",[2.28826, 49.87954450000001]],
    [ "Enedis",[2.2889866032171575, 49.88337706479]],
    [ "Enedis",[2.286444944444444, 49.88153341269841]],
    [ "Poste électrique du Bréjou",[1.2891202405744842, 45.75531399889186]],
    [ "Poste électrique Saint-Martin-le-Vieux",[1.132264612423309, 45.75732185795063]],
    [ "Poste électrique de la Rochefoucauld",[0.3909608788054238, 45.727195255477]],
    [ "Poste électrique de Loubert",[0.5685356760818829, 45.89180059973697]],
    [ "Enedis",[2.284250271728548, 49.87140429244889]],
    [ "Enedis",[2.2884814325500438, 49.869623217580504]],
    [ "Enedis",[2.286856227138643, 49.87776705899706]],
    [ "Enedis",[2.2870246037280704, 49.880444874342096]],
    [ "Enedis",[2.2853798931623936, 49.871741739316235]],
    [ "Enedis",[2.277876315433311, 49.88784334075529]],
    [ "Enedis",[2.2823636978193145, 49.891117461059196]],
    [ "Enedis",[2.2821920354191265, 49.88552034022039]],
    [ "Enedis",[2.272001819661458, 49.878426526692714]],
    [ "Enedis",[2.2813406534943286, 49.871841231613615]],
    [ "Enedis",[2.2824622521537443, 49.86700388701127]],
    [ "Enedis",[2.280622666666667, 49.888706612612616]],
    [ "Enedis",[2.286912663503953, 49.89072545809405]],
    [ "Enedis",[2.2848244999999996, 49.891256]],
    [ "Enedis",[2.2852960584034547, 49.868124023789]],
    [ "Poste électrique des Aubreaux",[0.06387772821825642, 45.609582763353075]],
    [ "Enedis",[4.706533666666667, 46.77343583333333]],
    [ "Poste électrique de Langlois",[2.660147496360383, 48.841599021870685]],
    [ "Poste électrique Holzmatt",[7.70473558095029, 48.570981058423435]],
    [ "TUILIERES",[0.6331381197303264, 44.846122431001184]],
    [ "RTE",[0.6700072318263124, 45.029258594628146]],
    [ "Enedis",[2.9270947720685117, 46.139040047430825]],
    [ "Enedis",[2.9438720592991916, 46.13798498113208]],
    [ "RTE",[0.7545977089855731, 45.17744363164442]],
    [ "SER",[7.575870018383908, 48.37703253608639]],
    [ "Enedis",[2.339435567049809, 49.91565431417626]],
    [ "Enedis",[2.32076096231522, 49.899572385800546]],
    [ "Enedis",[2.3221112801120447, 49.90242556302521]],
    [ "Enedis",[2.337931646376812, 49.914717791304355]],
    [ "Enedis",[2.338043933451118, 49.91347676069886]],
    [ "Enedis",[2.338520885496184, 49.9117124249364]],
    [ "Enedis",[2.3325368792708328, 49.89817031437501]],
    [ "Enedis",[2.32631275671406, 49.90707306793048]],
    [ "Enedis",[2.334810014358974, 49.914717317948714]],
    [ "Enedis",[2.32904786984127, 49.908383796220704]],
    [ "Enedis",[2.3304937535274677, 49.90668954498148]],
    [ "Enedis",[2.3305647985667894, 49.903081272085984]],
    [ "RTE",[1.1902401211129743, 45.06202822067122]],
    [ "Enedis",[0.33998392581063525, 46.56882859818417]],
    [ "140933825",[0.33684466037735833, 46.569899220125784]],
    [ "Enedis",[0.333059348431331, 46.569166375787795]],
    [ "Enedis",[0.33528503925712144, 46.56693560183873]],
    [ "Enedis",[2.868891838095238, 50.519813666666664]],
    [ "Enedis",[2.864203643493761, 50.51882757754011]],
    [ "Enedis",[2.867385810639732, 50.51817085441078]],
    [ "Enedis",[2.877765680672269, 50.52492494397759]],
    [ "Enedis",[2.8636530000000002, 50.51633499999999]],
    [ "Enedis",[2.8593633704627326, 50.514351278193395]],
    [ "Enedis",[2.877793313232831, 50.52303552931323]],
    [ "Enedis",[2.877120235454051, 50.520419854268624]],
    [ "Enedis",[2.8730327825015385, 50.523419033887855]],
    [ "Enedis",[2.8618380751237074, 50.51371555069726]],
    [ "Enedis",[2.8616257660550457, 50.51172464678899]],
    [ "Enedis",[2.85968063525675, 50.51198537038998]],
    [ "Enedis",[2.865513259259259, 50.520116061728395]],
    [ "Enedis",[2.855166500000001, 50.5237025]],
    [ "Sous-station de Colmar",[7.350997819763425, 48.09211748616429]],
    [ "RTE",[1.4861828043340561, 45.14853689548093]],
    [ "Enedis",[2.364418065913371, 49.918619305084746]],
    [ "Enedis",[2.3679675676855894, 49.919567096069876]],
    [ "RTE",[1.4844832106843109, 45.15648798776542]],
    [ "Enedis",[2.3383659999999993, 49.893249499999996]],
    [ "Enedis",[2.347131833094076, 49.88869451124509]],
    [ "Enedis",[2.3449110508359916, 49.89366086005606]],
    [ "Enedis",[2.337431682194617, 49.897870037267076]],
    [ "Enedis",[2.3589911599304654, 49.88776329161234]],
    [ "Enedis",[2.34211932514556, 49.88727492903931]],
    [ "Enedis",[2.3433474776966743, 49.89926227656123]],
    [ "Enedis",[2.346355776397515, 49.88635855279502]],
    [ "Enedis",[2.342843, 49.892551999999995]],
    [ "Enedis",[2.347000913978495, 49.884653666666665]],
    [ "Enedis",[2.341601641744549, 49.89760855140188]],
    [ "Enedis",[2.334817558880716, 49.89693679457265]],
    [ "SER",[7.458207732882052, 48.74546610302101]],
    [ "Enedis",[0.8760766173962483, 47.47942730111806]],
    [ "Enedis",[0.8782770000000002, 47.478454]],
    [ "Enedis",[2.3501547356488173, 49.87233134394597]],
    [ "Enedis",[2.3607894999999997, 49.8727925]],
    [ "Enedis",[2.365029, 49.867965999999996]],
    [ "Enedis",[2.3574251666666663, 49.87236199999999]],
    [ "Enedis",[2.3556514336874055, 49.869206323722814]],
    [ "Enedis",[2.3551975708920185, 49.87507460187794]],
    [ "Enedis",[2.35317520391502, 49.87143717125893]],
    [ "Enedis",[2.3561028863750635, 49.873481990447466]],
    [ "Enedis",[2.367072745556998, 49.86777203034244]],
    [ "Enedis",[2.3926255000000003, 49.86566]],
    [ "Enedis",[2.3752251897533205, 49.86779575363693]],
    [ "Enedis",[2.3790252345583998, 49.86899074087615]],
    [ "Enedis",[2.3909627303619416, 49.86763025794215]],
    [ "Enedis",[2.3947595, 49.876622000000005]],
    [ "Poste électrique de Champniers",[0.18575797722022347, 45.694819031515664]],
    [ "Poste électrique de Soyaux",[0.18672941060414733, 45.631321038776676]],
    [ "Poste électrique de Chavenat",[0.18251149482048548, 45.4476600630628]],
    [ "Enedis",[2.345643404896421, 49.86435715819209]],
    [ "Enedis",[2.3522033985385042, 49.85580411204797]],
    [ "Enedis",[2.3435166084468126, 49.85853164120782]],
    [ "Enedis",[2.340111503274645, 49.85833733367356]],
    [ "Enedis",[2.3469965192233757, 49.856780347366396]],
    [ "Enedis",[2.3445803588706053, 49.85691532308246]],
    [ "Enedis",[2.3433213292558612, 49.86050098301054]],
    [ "Enedis",[7.250420303216974, 43.71363534223135]],
    [ "Enedis",[2.3127129364739467, 49.83674060885082]],
    [ "Enedis",[2.304253207412861, 49.83791884560628]],
    [ "Enedis",[2.304808749999999, 49.83409075]],
    [ "Enedis",[2.30985975, 49.836545750000006]],
    [ "Enedis",[2.3982221704016453, 49.84962350698279]],
    [ "Enedis",[2.37590761904762, 49.86357692063492]],
    [ "Enedis",[2.390316421003931, 49.845453100508905]],
    [ "Enedis",[2.4014594999999996, 49.848697]],
    [ "Enedis",[2.3774215, 49.851653999999996]],
    [ "Enedis",[2.3915455808695656, 49.849872846376805]],
    [ "Enedis",[2.393194927140255, 49.844404346083785]],
    [ "Enedis",[2.377611263157896, 49.848721944862156]],
    [ "Enedis",[2.400500575107296, 49.841489094420595]],
    [ "Enedis",[2.397921711138593, 49.84862949062513]],
    [ "Enedis",[2.3918148395773295, 49.85000901440922]],
    [ "Enedis",[2.387926358206664, 49.84433653385537]],
    [ "Enedis",[2.3441314221086924, 49.831652133200066]],
    [ "Poste électrique de la Couronne",[0.10310925847071135, 45.61619343991934]],
    [ "Poste électrique de Rabion",[0.13051443565615375, 45.636929192022144]],
    [ "Enedis",[2.563206196065941, 45.85168749431747]],
    [ "Enedis",[2.9340698715218325, 50.42829194122981]],
    [ "Enedis",[2.93162534295416, 50.4395084861347]],
    [ "Enedis",[2.938697850818093, 50.437214835739475]],
    [ "Enedis",[2.936922944892472, 50.42981934408601]],
    [ "Enedis",[2.9327244146722165, 50.437798895941725]],
    [ "Enedis",[2.926055820504699, 50.43402443300601]],
    [ "Enedis",[2.9244393641887396, 50.44102231168708]],
    [ "Enedis",[2.9306771424310214, 50.43480840417599]],
    [ "Enedis",[2.9274493000672797, 50.43647465702325]],
    [ "Enedis",[2.9256248157181575, 50.43497146124662]],
    [ "Enedis",[2.927007214533874, 50.43884892279482]],
    [ "Enedis",[2.9304836792842393, 50.432991529249826]],
    [ "Enedis",[2.9330170248882443, 50.431186100881966]],
    [ "Enedis",[2.910741554319042, 50.4326910349831]],
    [ "Enedis",[2.9006549736408567, 50.42173192421746]],
    [ "Enedis",[2.8959645002251824, 50.42012680177572]],
    [ "Enedis",[2.889072358974361, 50.42006686480186]],
    [ "Enedis",[2.9071042316076294, 50.420939110202845]],
    [ "Enedis",[2.9036307474747476, 50.433465383838396]],
    [ "Enedis",[2.9029432502047507, 50.42353715520064]],
    [ "Enedis",[6.58638939292365, 45.20232150521416]],
    [ "Enedis",[6.622646499999999, 45.19660069999999]],
    [ "Serhy",[6.646646559817351, 45.19156244018265]],
    [ "Pont du Charmaix",[6.652710246003377, 45.19196344800354]],
    [ "Enedis",[3.4538363287539178, 50.50324082742831]],
    [ "Enedis",[3.4437358, 50.496326499999995]],
    [ "Enedis",[1.37361525, 43.65091675000001]],
    [ "Enedis",[1.37298525, 43.64647775000001]],
    [ "Enedis",[1.3707002500000014, 43.65106775]],
    [ "Enedis",[1.3734915263008651, 43.65258669686658]],
    [ "Enedis",[1.3722117521237953, 43.649138996626746]],
    [ "Enedis",[7.2578419847094775, 43.7163946911315]],
    [ "Enedis",[3.078521833333333, 45.88046016666666]],
    [ "Enedis",[3.0793384681867533, 45.87620398479914]],
    [ "Enedis",[2.230452330752432, 49.84218683641055]],
    [ "Enedis",[2.876665179196218, 46.19377832119779]],
    [ "Enedis",[2.7194610268656723, 49.306421658706476]],
    [ "Enedis",[2.7358982636307307, 49.31334369578686]],
    [ "Enedis",[2.716508980392157, 49.30668149509803]],
    [ "Enedis",[2.7235375000000004, 49.306426]],
    [ "Enedis",[2.7218116100373004, 49.30599353679213]],
    [ "Enedis",[2.846757045991299, 46.204203131551694]],
    [ "Enedis",[2.603405830090791, 49.29186798832685]],
    [ "Enedis",[5.42975000581119, 46.5317819985639]],
    [ "Enedis",[5.41749715, 46.52765704999999]],
    [ "Enedis",[5.160321375005065, 46.658408257356356]],
    [ "Enedis",[5.17647625, 46.6528685]],
    [ "Enedis",[5.164531800000001, 46.6509122]],
    [ "Enedis",[5.194536564774382, 46.622480761280926]],
    [ "Enedis",[0.7022814248159834, 47.755280139852786]],
    [ "Enedis",[5.125818131911532, 47.520201037914696]],
    [ "Enedis",[0.9381425414551606, 47.75177683756345]],
    [ "Enedis",[6.2375785, 48.736027500000006]],
    [ "Enedis",[-0.8425439157594617, 43.782918503732496]],
    [ "Enedis",[5.255229700000001, 46.6415745]],
    [ "Enedis",[5.254108071483195, 46.62122594270352]],
    [ "Enedis",[4.843625786317321, 45.46722634235808]],
    [ "Enedis",[4.839999234285714, 45.46617702095237]],
    [ "Enedis",[5.87023411573237, 49.16510358468957]],
    [ "Enedis",[5.870454500000001, 49.15858475]],
    [ "Enedis",[2.8369989267015705, 50.516283726003486]],
    [ "Enedis",[2.835690726368158, 50.52320693532337]],
    [ "Enedis",[2.837044685214625, 50.50848666242713]],
    [ "Enedis",[2.808608015540628, 50.508788907414726]],
    [ "Enedis",[2.8282631774011295, 50.5056179740113]],
    [ "Enedis",[2.836162586541202, 50.50638672607166]],
    [ "Enedis",[2.8339762454748825, 50.5157934313606]],
    [ "Enedis",[6.1248530744392236, 45.013873553264936]],
    [ "Nord",[6.123886559348853, 45.019351134102735]],
    [ "Enedis",[6.116112363699102, 45.035430917874386]],
    [ "Enedis",[0.7839486999999988, 47.69541235]],
    [ "Enedis",[4.864380337338675, 45.53526049132176]],
    [ "Enedis",[4.864669765135615, 45.532153601630874]],
    [ "Poste Électrique de Douvres",[-0.3941353359750481, 49.27704337058678]],
    [ "Enedis",[-0.6040166080808077, 45.18431234343435]],
    [ "Enedis",[5.237093149770718, 46.60748548326667]],
    [ "Enedis",[5.249098135740633, 46.596861069655034]],
    [ "Enedis",[2.04234941024288, 44.43893523958323]],
    [ "Enedis",[-0.723832147359043, 47.213640081976564]],
    [ "Enedis",[5.157476560870859, 45.6104436561636]],
    [ "Joseph Serlin",[5.1362595, 45.619367]],
    [ "Enedis",[5.1791251, 45.59783335]],
    [ "Biclimatique",[5.170145499999999, 45.603709500000015]],
    [ "Enedis",[5.15814725, 45.60960475]],
    [ "Les Ardennes",[5.144001324023277, 45.60746320509837]],
    [ "Folies",[5.157016392717488, 45.61236502356427]],
    [ "Simone Signoret",[5.159200749999998, 45.61073674999999]],
    [ "La Frênaie",[5.157434793226284, 45.61162086544276]],
    [ "Lémand",[5.158355, 45.61997924999999]],
    [ "Les Oursons C6",[5.154650999999999, 45.619234000000006]],
    [ "Les Halles",[5.1740034999999995, 45.60259349999999]],
    [ "Enedis",[5.18423828350139, 45.603584711661554]],
    [ "Enedis",[5.15649, 45.611999]],
    [ "Enedis",[5.159187500000001, 45.608561]],
    [ "Les Aulnes",[5.1741788563803945, 45.606823876225825]],
    [ "Buisson Rond",[5.15640354841998, 45.60997365239552]],
    [ "Enedis",[5.137212666666666, 45.62100566666667]],
    [ "Enedis",[5.164428000000001, 45.6132546]],
    [ "Gymnase N° 1",[5.1549995000000015, 45.6068155]],
    [ "Coccinelles",[5.161842186470166, 45.61138948111759]],
    [ "Le Clos des Vignes",[5.17260162085177, 45.60104263864307]],
    [ "Village Bois",[5.1685946593886465, 45.602129040756914]],
    [ "Émile Romanet",[5.14316775, 45.6175135]],
    [ "Les Aviles",[5.139714139853146, 45.61633892100265]],
    [ "Enedis",[5.17628053476612, 45.60116083101559]],
    [ "Le Pont",[5.15341975, 45.6144023]],
    [ "Polican",[5.1815209, 45.6060422]],
    [ "Mornard",[5.1664863500000004, 45.627454750000005]],
    [ "La Pinède n°3",[5.153255999999999, 45.609117000000005]],
    [ "Denis Papin",[5.162556749999999, 45.6197655]],
    [ "Enedis",[5.174180558560729, 45.60444354355489]],
    [ "Muissiat",[5.160832449999999, 45.614760499999996]],
    [ "Enedis",[5.1635967, 45.6145356]],
    [ "Les Gentianes",[5.1497345821663325, 45.608476182147285]],
    [ "Le Ginet",[5.1449895, 45.611039999999996]],
    [ "Enedis",[5.146114022914756, 45.616960676749166]],
    [ "Muissiat P.3",[5.1646476932103935, 45.61257302374966]],
    [ "La Forestière",[5.1413022, 45.624587049999995]],
    [ "Résidence Plein Sud",[5.158362276613812, 45.60765510343125]],
    [ "Enedis",[5.138981, 45.619743]],
    [ "Les Moulins d’Aillat",[5.158684350808373, 45.61330353304686]],
    [ "Les Crêtes",[5.1765544000000006, 45.60826290000001]],
    [ "Enedis",[5.147176536435537, 45.619210806803345]],
    [ "Pivoley",[5.157701580508475, 45.613329521186444]],
    [ "Bretagne",[5.147611003403927, 45.62067325966068]],
    [ "Jacques Brel",[5.1378822500000005, 45.61877225]],
    [ "Le Clou - B7",[5.154409320588683, 45.616650362615694]],
    [ "Vert Village - Picotière",[5.1454039774037135, 45.60519309109486]],
    [ "Bourg",[5.14612095137461, 45.61210884993637]],
    [ "Belledonne",[5.144820425483191, 45.62180311935513]],
    [ "Roches",[5.145941737379361, 45.61931179266739]],
    [ "Poste électrique des Perrotins",[0.1133000199815776, 45.3621000252148]],
    [ "Crachier",[5.2258172, 45.549739300000006]],
    [ "Poste électrique de la Courtillère",[0.046861149600419774, 45.274978313127185]],
    [ "Le Ribollet",[5.19655525, 45.5795235]],
    [ "Enedis",[6.838601386595483, 48.140962813391]],
    [ "Parc Muissiat",[5.166336659591195, 45.618661854264936]],
    [ "Condorcet",[5.17078325, 45.62605949999999]],
    [ "Belmont",[5.1944388, 45.6095679]],
    [ "Kerneos",[5.178021782738107, 45.62041075053402]],
    [ "Enedis",[5.168983000000001, 45.616305999999994]],
    [ "Cime de Vaulx",[5.177182050000001, 45.6128837]],
    [ "Nautan",[5.18956115, 45.610353350000004]],
    [ "Parc Techno 1",[5.16814165, 45.62752980000001]],
    [ "Residen de l etang",[5.174697, 45.615971999999985]],
    [ "Enedis",[5.169513799999999, 45.61341765]],
    [ "Enedis",[2.514602375, 49.29752895833334]],
    [ "Enedis",[2.5184545000000003, 49.297616999999995]],
    [ "Enedis",[2.362011802095929, 48.48212695767837]],
    [ "Enedis",[2.3553273119533524, 48.47979095529641]],
    [ "Enedis",[2.349084201600693, 48.48142444667964]],
    [ "Enedis",[2.3598892741433017, 48.48561868847353]],
    [ "Enedis",[2.3450997567757828, 48.47957508447729]],
    [ "Enedis",[2.3580512387419024, 48.47689153804729]],
    [ "Enedis",[2.3659691555362823, 48.48060869702227]],
    [ "Enedis",[2.3631987214185717, 48.48085161284803]],
    [ "Poste électrique de Bessanges",[-0.05743245868816279, 45.08487103507826]],
    [ "Poste électrique de Montguyon",[-0.2184332467583052, 45.20346975388731]],
    [ "Enedis",[2.513228, 49.26445600000001]],
    [ "Enedis",[2.528293367984747, 49.28166977443422]],
    [ "Enedis",[2.78788141475827, 50.270380122137404]],
    [ "Enedis",[2.79111925, 50.26879325]],
    [ "Enedis",[2.7890408310502286, 50.27267701369863]],
    [ "Enedis",[2.7624790000000012, 50.28011250000001]],
    [ "Enedis",[2.7307812980786177, 50.294842037017446]],
    [ "Enedis",[2.7284717907629576, 50.29515712977638]],
    [ "Enedis",[2.7304599756452657, 50.29326849354663]],
    [ "Enedis",[2.73308315, 50.2939866]],
    [ "Poste électrique de Grézillac",[-0.23187692640930038, 44.8133265172076]],
    [ "Poste électrique d’Odon",[-0.5299219716661476, 49.1922443162133]],
    [ "RTE",[3.508995302798069, 47.4519637816199]],
    [ "Poste électrique de Trégueux",[-2.7708042768730854, 48.49079655778131]],
    [ "Poste électrique de Garchizy",[3.084828601217563, 47.031206126118114]],
    [ "RTE",[3.183733392630805, 46.99831540920709]],
    [ "RTE",[-0.7256743372911657, 46.64788242101648]],
    [ "Poste électrique de Brains",[-1.6934484149397968, 47.17336307482877]],
    [ "Poste électrique de Champvert",[3.4878621621151, 46.84181242159411]],
    [ "Poste électrique de Vignol",[3.649012029074791, 47.34382961949668]],
    [ "Poste électrique de Plourhan",[-2.8931589565306695, 48.63294934705788]],
    [ "Enedis",[5.311666949999999, 45.59599005000001]],
    [ "Enedis",[7.556520000000001, 48.34412999999999]],
    [ "Poste de Sirmière",[-1.4119844284079344, 46.634322449165424]],
    [ "RTE",[4.403826099200618, 44.6023407610145]],
    [ "Enedis",[6.220551, 48.655543]],
    [ "Enedis",[6.2345123875653075, 48.65205886196446]],
    [ "Enedis",[6.21379367528654, 48.66123752893815]],
    [ "Enedis",[6.2180126, 48.65695949999999]],
    [ "Enedis",[6.217198287304646, 48.65808868143866]],
    [ "Enedis",[6.257463197790666, 48.642308798398226]],
    [ "Enedis",[6.224890674271777, 48.660317114816536]],
    [ "Enedis",[6.232228831353919, 48.65651733166184]],
    [ "Enedis",[6.249782333333334, 48.64364004444445]],
    [ "Enedis",[6.225004293577982, 48.652154455657495]],
    [ "Enedis",[6.222369812499999, 48.650242734375006]],
    [ "Enedis",[6.228335783177956, 48.650055824842966]],
    [ "Enedis",[6.233290674200322, 48.64958725531524]],
    [ "Enedis",[6.218633760708011, 48.65997554897986]],
    [ "Enedis",[6.25279873042432, 48.64223002088801]],
    [ "Enedis",[6.223498, 48.653566500000004]],
    [ "Enedis",[6.2808340000000005, 48.6368975]],
    [ "Enedis",[6.233545573122528, 48.646076910408425]],
    [ "Enedis",[6.2142851116807805, 48.65545353158621]],
    [ "Enedis",[6.228208285549513, 48.65845958840006]],
    [ "Enedis",[6.263082499999999, 48.641875500000005]],
    [ "Enedis",[6.270584116410101, 48.63898927900191]],
    [ "Enedis",[6.217620787234044, 48.65361408249346]],
    [ "Enedis",[6.221564165479825, 48.66206450762971]],
    [ "Enedis",[2.754403896979962, 47.638841317809764]],
    [ "Enedis",[2.760152658757063, 47.63983997288136]],
    [ "Enedis",[2.730264831819433, 47.64554299449491]],
    [ "Enedis",[2.7338095000000004, 47.65326100000001]],
    [ "Enedis",[2.7459496006463526, 47.62476520729456]],
    [ "Enedis",[2.747316628087704, 47.62791747293922]],
    [ "Enedis",[2.7395116777316737, 47.63399446887966]],
    [ "Enedis",[2.7267302609063955, 47.652971959127484]],
    [ "Enedis",[2.7352834999999995, 47.64695150000001]],
    [ "Enedis",[2.7335776638513516, 47.65572100788288]],
    [ "Enedis",[2.7458833333333326, 47.64163833333333]],
    [ "Enedis",[2.736959532675129, 47.65173644992949]],
    [ "Enedis",[5.3220753858720435, 45.62382377057976]],
    [ "Poste électrique de Colayrac",[0.5636695063431391, 44.22782887236459]],
    [ "Enedis",[-0.8924148999999998, 43.75780474999999]],
    [ "RTE",[0.5403799335247883, 44.23152824520304]],
    [ "Poste électrique de Montauban",[1.3407483921038477, 44.00850364977848]],
    [ "RTE",[0.869682881077209, 43.624516123823824]],
    [ "RTE",[0.7342167667195805, 43.502720764104694]],
    [ "Enedis",[-2.7584170000000015, 47.52771299999999]],
    [ "Enedis",[-2.7587726573193123, 47.53106547838425]],
    [ "Enedis",[6.868332207893901, 48.070637778173705]],
    [ "RTE",[0.5720794802785892, 43.07651425044132]],
    [ "RTE",[0.6684472805838734, 43.095141046257055]],
    [ "RTE",[0.6112037195755925, 43.08992318978161]],
    [ "RTE",[4.088726295262632, 43.68050540563692]],
    [ "RTE",[0.7005046923542312, 43.097497253154444]],
    [ "Poste Électrique de Creully",[-0.548164658676307, 49.2727228093023]],
    [ "Enedis",[5.177126197849462, 45.65243063333333]],
    [ "Charamel",[5.180588842893016, 45.65756939789193]],
    [ "Poste Électrique de Bayeux",[-0.6913411440407874, 49.259795218858635]],
    [ "Poste Électrique de Saonnet",[-0.8898006362915684, 49.258866552026575]],
    [ "Enedis",[6.881397866933758, 48.071681599043195]],
    [ "Enedis",[3.258265, 46.03868800000001]],
    [ "Poste électrique de Bocage",[-0.6314140235657539, 49.089294281056645]],
    [ "142805921",[2.2956655812971345, 45.06096498597286]],
    [ "Enedis",[5.457901019712314, 46.26200909574343]],
    [ "Enedis",[0.9992078895027627, 47.79095966206262]],
    [ "Enedis",[1.002454654993515, 47.803762477302215]],
    [ "Enedis",[0.9911786582053003, 47.80838368603874]],
    [ "Bochet",[5.186534499999999, 45.746906499999994]],
    [ "RTE",[0.6300765737399843, 45.38659640585618]],
    [ "Enedis",[2.449288320768663, 48.57451717960089]],
    [ "Enedis",[2.459377246556474, 48.57350311570248]],
    [ "Enedis",[2.4515157626137305, 48.572590154673286]],
    [ "Enedis",[2.4527023044096734, 48.574336111901374]],
    [ "Enedis",[2.4736874999999996, 48.577698999999996]],
    [ "Enedis",[2.194816470324118, 48.52989806845271]],
    [ "Les Tâches",[5.162954550000001, 45.6809642]],
    [ "Les Archinauds",[5.1624939536276715, 45.67799691692077]],
    [ "Poste électrique de Rungis",[2.368232906165653, 48.747029797181604]],
    [ "Enedis",[6.852332520772193, 48.05799152792703]],
    [ "Enedis",[6.448645549999999, 48.19410865]],
    [ "Marence",[5.261810734192954, 45.64097404306931]],
    [ "Poste électrique de la Nicerie",[-0.32170613659642355, 45.67794051615248]],
    [ "Enedis",[5.452944525089648, 46.32812930466419]],
    [ "Enedis",[5.4434927917610505, 46.33024500879612]],
    [ "Enedis",[5.437561680570206, 46.329737122313446]],
    [ "Enedis",[5.432745750000001, 46.35939725]],
    [ "La Gare",[5.31562118394113, 45.68100431981401]],
    [ "Poste électrique de Guersac",[-2.168113029091499, 47.33806244548518]],
    [ "Poste électrique de Mesnil",[-1.027533451869043, 49.072230141285644]],
    [ "Enedis",[5.820696565728605, 43.09413809533649]],
    [ "Enedis",[5.810269783328682, 43.09905428681738]],
    [ "Enedis",[6.040635099999999, 45.20240695]],
    [ "Poste électrique du Rivier",[6.043725150000001, 45.20511945]],
    [ "Église",[6.040233500000001, 45.20253850000001]],
    [ "Château",[6.043796027276928, 45.2049863127333]],
    [ "Enedis",[2.7814487358300037, 44.427209430586984]],
    [ "Enedis",[2.8702308333333337, 46.224096]],
    [ "Enedis",[2.915760356780475, 46.226818673111204]],
    [ "RTE",[2.2684030016627057, 48.83191551830835]],
    [ "Enedis",[2.465533338729017, 48.58338433033574]],
    [ "Enedis",[2.456913095839346, 48.585616415464166]],
    [ "Enedis",[2.41304875, 48.748549499999996]],
    [ "Enedis",[2.41752925, 48.7494935]],
    [ "Enedis",[2.3874740000000005, 48.74197175]],
    [ "Enedis",[2.4094760933333337, 48.749130320000006]],
    [ "Enedis",[2.378056, 48.747999500000006]],
    [ "Enedis",[2.403289946296297, 48.74390419907407]],
    [ "Enedis",[2.3950890017437256, 48.74113395680791]],
    [ "Enedis",[2.3933315, 48.749846500000004]],
    [ "Enedis",[2.416861418209408, 48.74441027324229]],
    [ "Enedis",[2.4214901532564337, 48.75013202353285]],
    [ "Forclum",[2.079722735619068, 49.04624603202815]],
    [ "Enedis",[-0.90139195, 43.765496049999996]],
    [ "Enedis",[-1.5607536327885225, 47.22520444531462]],
    [ "Enedis",[2.8418885, 46.124776000000004]],
    [ "Enedis",[7.303913554917004, 43.83177371800074]],
    [ "Enedis",[-1.3890151543859652, 43.53752930877194]],
    [ "Enedis",[-1.3743594145758662, 43.50840868219833]],
    [ "Enedis",[-1.4114748082251087, 43.528001020779215]],
    [ "Enedis",[-1.3870816077621482, 43.53292467610169]],
    [ "Enedis",[-1.4039851499999998, 43.55063500000001]],
    [ "Enedis",[-1.3912804999999997, 43.533345499999996]],
    [ "Enedis",[-1.4109597997734353, 43.52604448003399]],
    [ "Enedis",[-1.3939587500000001, 43.54138725000001]],
    [ "Enedis",[-1.3900934999999999, 43.540547999999994]],
    [ "Enedis",[-1.3596590819619234, 43.50972181348822]],
    [ "Enedis",[-1.3818679668246443, 43.54169016745656]],
    [ "Enedis",[-1.3836225, 43.543617000000005]],
    [ "Enedis",[-1.4152925, 43.529343499999996]],
    [ "Enedis",[-1.3919335, 43.5441285]],
    [ "Enedis",[-1.3856930000000005, 43.53941749999999]],
    [ "Enedis",[-1.3797547500000003, 43.54169475]],
    [ "143462025",[5.466040380051389, 45.67872678930156]],
    [ "Enedis",[5.440927737658033, 45.69738052055173]],
    [ "Enedis",[2.3977932911877393, 48.763476957854415]],
    [ "Enedis",[2.3685919985790127, 48.76196818450378]],
    [ "Enedis",[2.4032900000000006, 48.7603325]],
    [ "Enedis",[6.031944, 46.310450499999995]],
    [ "Enedis",[6.047680500000001, 46.29705900000001]],
    [ "Enedis",[6.035244979942693, 46.307309827125124]],
    [ "Enedis",[6.042636, 46.31218]],
    [ "Enedis",[6.050028230303029, 46.309109957575764]],
    [ "Enedis",[6.043858500000001, 46.3103115]],
    [ "Enedis",[6.062285385214008, 46.3027696005188]],
    [ "Enedis",[6.0509246257635505, 46.31019420668058]],
    [ "Enedis",[6.037315706214688, 46.318067898305074]],
    [ "Enedis",[6.03859643942029, 46.30653927826088]],
    [ "Enedis",[6.031294161034164, 46.31130560683287]],
    [ "Enedis",[1.995599803322407, 49.73493966595803]],
    [ "Panossas",[5.205386816679816, 45.67826304980902]],
    [ "Enedis",[6.87434905, 48.063188600000004]],
    [ "Enedis",[5.248074626897229, 45.74201599323062]],
    [ "Enedis",[0.07496331318944845, 47.70520501870503]],
    [ "Enedis",[0.07640062513350003, 47.70621671496381]],
    [ "Enedis",[0.0691045, 47.703872999999994]],
    [ "Enedis",[0.08107800000000001, 47.7062655]],
    [ "Enedis",[0.08233854644808761, 47.69620578688525]],
    [ "Crizieu",[5.264721300653595, 45.692018065359484]],
    [ "Poste électrique d’Auxerre",[3.592950983128434, 47.79419227182367]],
    [ "Enedis",[2.236714941704035, 50.71320260355422]],
    [ "Enedis",[2.243294683997718, 50.71056406531918]],
    [ "Enedis",[2.22587964817975, 50.71092890017064]],
    [ "Enedis",[2.286746331164136, 48.60186798481562]],
    [ "Enedis",[2.31808852671254, 48.604939096008124]],
    [ "Enedis",[2.3160581673525376, 48.60507401005945]],
    [ "Enedis",[2.3139107152096177, 48.6063895496462]],
    [ "Enedis",[2.2955931630506243, 48.609915474687696]],
    [ "Enedis",[2.3060965886426597, 48.60320929132041]],
    [ "Enedis",[2.311958655091575, 48.60912838857142]],
    [ "Enedis",[2.314558317398026, 48.602632662028775]],
    [ "Enedis",[2.3224227237866306, 48.606400466117215]],
    [ "Enedis",[2.311282499999999, 48.58844]],
    [ "Enedis",[2.3009905045045045, 48.60339682882883]],
    [ "Enedis",[2.290427698478562, 48.59846631673583]],
    [ "Enedis",[2.2996340772200767, 48.59758869498069]],
    [ "Enedis",[2.312462289074026, 48.6070478483034]],
    [ "Enedis",[2.2940578280960304, 48.598369964123286]],
    [ "Enedis",[2.2868691299619357, 48.59754016367591]],
    [ "Enedis",[2.3037035000000006, 48.602864999999994]],
    [ "Enedis",[2.303287295933442, 48.59553215605319]],
    [ "Ronsard",[2.287037386590585, 48.59987807227771]],
    [ "Enedis",[2.2996633047760944, 48.59961854473968]],
    [ "Enedis",[2.3041220000000004, 48.6009205]],
    [ "Enedis",[2.3027904125323433, 48.60892120283497]],
    [ "Enedis",[2.319051296128252, 48.606615545069566]],
    [ "Enedis",[2.303668574287143, 48.61283609621478]],
    [ "Enedis",[0.006821525214793089, 47.62002743107956]],
    [ "Enedis",[2.3113055000000005, 48.64132125]],
    [ "Enedis",[2.3147030644658413, 48.63787790288858]],
    [ "Enedis",[2.3107040155681653, 48.642540589989814]],
    [ "Enedis",[2.329989329046744, 48.633751118187384]],
    [ "Enedis",[2.320575289869609, 48.628345047810086]],
    [ "Enedis",[2.3131245, 48.64208625]],
    [ "Enedis",[2.3131815000000002, 48.634335000000014]],
    [ "Enedis",[2.308643324021383, 48.63698897413347]],
    [ "Enedis",[2.3160342952646245, 48.64345928783658]],
    [ "Enedis",[2.3180930000000006, 48.634994500000005]],
    [ "Enedis",[2.3041545, 48.626396]],
    [ "Enedis",[2.3054769695329727, 48.63736569873722]],
    [ "Enedis",[2.301713642694333, 48.63716770627996]],
    [ "Enedis",[2.3181600064670316, 48.629398466329256]],
    [ "Enedis",[2.30250551547421, 48.632266882750976]],
    [ "Enedis",[2.3182758285991207, 48.63163921665548]],
    [ "Enedis",[2.3171225915670846, 48.6267417174779]],
    [ "Enedis",[3.2238649000000006, 45.66606405]],
    [ "Enedis",[5.911697324894515, 48.66754001687763]],
    [ "Stade",[5.901936565581573, 48.67257965676755]],
    [ "Enedis",[5.9157907776277305, 48.67145104208868]],
    [ "Enedis",[5.915175928547009, 48.669029325811955]],
    [ "Enedis",[5.911256076632586, 48.67156254103343]],
    [ "Enedis",[5.911349878496348, 48.6698102164618]],
    [ "Enedis",[5.907637522551179, 48.66745783418971]],
    [ "Enedis",[2.686379268848981, 46.10425315010987]],
    [ "Enedis",[2.6938455725104564, 46.10836187747259]],
    [ "Enedis",[2.6920102268219064, 46.11146205045278]],
    [ "Enedis",[2.6885336020438833, 46.10363339855726]],
    [ "Enedis",[2.6908025753086418, 46.10588918271604]],
    [ "Enedis",[2.6950086189506655, 46.11067225833228]],
    [ "Enedis",[2.3324664999999998, 48.61542450000001]],
    [ "Enedis",[2.324297396722492, 48.61120919502971]],
    [ "Enedis",[2.327217, 48.611445999999994]],
    [ "Enedis",[2.330407, 48.61306849999999]],
    [ "Enedis",[2.3311629793153945, 48.61689817224969]],
    [ "Enedis",[2.329946734317862, 48.60872229578058]],
    [ "Enedis",[2.3216574316394434, 48.61216974984876]],
    [ "Enedis",[2.3275839999999994, 48.6087575]],
    [ "Enedis",[2.3215788606965178, 48.613683350248756]],
    [ "Enedis",[6.878335316949236, 48.06293045606692]],
    [ "Enedis",[2.0190658425019774, 48.54298829039927]],
    [ "SNCF Réseau",[2.115802361096684, 48.54723407518973]],
    [ "Enedis",[3.3634173947026844, 47.875322261655846]],
    [ "Enedis",[3.35244466210185, 47.874279425131924]],
    [ "Enedis",[3.3589122627848096, 47.87874187021096]],
    [ "Enedis",[2.81915716975659, 46.02975281346954]],
    [ "Enedis",[2.812291500000002, 46.02961150000001]],
    [ "Enedis",[2.820251381771382, 46.02780026289927]],
    [ "Enedis",[2.824510333969465, 46.029007644402036]],
    [ "Enedis",[2.819702336099585, 46.030612475795294]],
    [ "Enedis",[2.828352982940699, 46.024513582453295]],
    [ "Enedis",[2.808944121602288, 46.03148233237959]],
    [ "Enedis",[3.4648297868852462, 49.75046284426229]],
    [ "Enedis",[3.4204575, 49.775238]],
    [ "Enedis",[3.527800931315733, 49.79192773535094]],
    [ "Enedis",[3.3790286442163775, 49.72117617682275]],
    [ "Enedis",[3.379673080000001, 49.74282094666666]],
    [ "Enedis",[2.7414228500000015, 50.306854349999995]],
    [ "Enedis",[2.728219019203073, 50.2998081669067]],
    [ "Enedis",[-2.2786038666666673, 48.08060870751174]],
    [ "Enedis",[-2.290138796254682, 48.082118086766556]],
    [ "Enedis",[-2.277956911227652, 48.084587736350436]],
    [ "Enedis",[-2.2811443999999996, 48.079179599999996]],
    [ "Enedis",[-2.2825487038570524, 48.083390812327806]],
    [ "Poste électrique de Froges",[5.917246629797044, 45.26584224563982]],
    [ "Enedis",[3.4612004533700858, 49.97895378696594]],
    [ "Enedis",[3.4505791234240224, 49.98044260937846]],
    [ "Enedis",[3.458495682125605, 49.98420232270531]],
    [ "Enedis",[3.4624312113230395, 49.98310983560399]],
    [ "Enedis",[3.4567351666666664, 49.982627]],
    [ "Enedis",[3.463097396550258, 49.97951712660379]],
    [ "Enedis",[3.444974179800246, 49.97734500852223]],
    [ "Enedis",[3.4556896523966336, 49.98708887467983]],
    [ "Enedis",[3.4430444431074516, 49.98759108697489]],
    [ "Enedis",[3.4516711333333343, 49.98423312941176]],
    [ "Enedis",[3.3520083778966128, 49.716091807486634]],
    [ "Enedis",[2.4956176195807096, 48.86923798608423]],
    [ "RTE",[3.478273099999999, 48.49914669999999]],
    [ "RTE",[3.148260370188694, 48.31790336796143]],
    [ "Enedis",[2.7868007500000003, 50.283243750000004]],
    [ "Enedis",[2.7803578873821393, 50.282244951124774]],
    [ "Enedis",[2.7770178288288285, 50.282102866366365]],
    [ "Enedis",[2.774622000000001, 50.279795500000006]],
    [ "Enedis",[2.7826506882985544, 50.28554773267229]],
    [ "Enedis",[2.780259325753269, 50.284608978207324]],
    [ "Enedis",[2.784748585741812, 50.28465767630058]],
    [ "Enedis",[2.786131435105068, 50.28045666460651]],
    [ "Enedis",[6.848330499999999, 47.596707]],
    [ "Enedis",[2.800297148815713, 50.28334377631426]],
    [ "Enedis",[2.8041151431632803, 50.28120592534218]],
    [ "Enedis",[2.8061254749262536, 50.281514265486734]],
    [ "Enedis",[2.797014598876511, 50.28254269618204]],
    [ "Enedis",[2.8068959939029097, 50.28627238612332]],
    [ "Enedis",[2.8032777465857355, 50.28808077187658]],
    [ "Enedis",[2.807483805200341, 50.28891597783462]],
    [ "Enedis",[2.7998812188639906, 50.288142094754214]],
    [ "Enedis",[2.800303145466405, 50.28591535290281]],
    [ "Enedis",[2.806479512467621, 50.284943179401665]],
    [ "Enedis",[2.7981184547796474, 50.28674430554789]],
    [ "Enedis",[2.7960591538114565, 50.285622089309875]],
    [ "Enedis",[2.7869945000000005, 50.278287750000004]],
    [ "Enedis",[2.789505121371109, 50.276763631339634]],
    [ "Enedis",[2.7886860267476594, 50.27759954725128]],
    [ "Enedis",[2.7914037484250276, 50.27432184044921]],
    [ "Enedis",[2.7881751294498383, 50.27604864724919]],
    [ "Enedis",[2.7754111432950195, 50.2784283210728]],
    [ "Enedis",[2.78140475, 50.27887199999999]],
    [ "Enedis",[2.77811225, 50.278198999999994]],
    [ "Enedis",[2.7853777912787367, 50.27536567513471]],
    [ "Enedis",[2.790979152151534, 50.2845597245213]],
    [ "144221344",[2.791492563468445, 50.278895240567806]],
    [ "Enedis",[6.358799250688705, 48.61626354820937]],
    [ "Enedis",[6.358233843980878, 48.60950794828335]],
    [ "Enedis",[6.369801467532466, 48.61892615800867]],
    [ "Enedis",[6.3648440000000015, 48.614943999999994]],
    [ "Enedis",[6.370472781526103, 48.617102026506025]],
    [ "Enedis",[6.350064000000001, 48.62626050000001]],
    [ "Enedis",[6.3527704218009475, 48.61028626224329]],
    [ "Enedis",[6.352825304304602, 48.60735466278955]],
    [ "Enedis",[6.356164167723749, 48.621641507097564]],
    [ "Enedis",[6.346698322666667, 48.62061202311111]],
    [ "Enedis",[6.365104734900177, 48.60356533601214]],
    [ "Enedis",[6.359953842978635, 48.61169731777639]],
    [ "Enedis",[6.352367684478372, 48.61477265394402]],
    [ "Enedis",[6.362988622340425, 48.60138810319149]],
    [ "Enedis",[6.355046298495644, 48.6308873016627]],
    [ "Enedis",[6.349934383032823, 48.61801938446602]],
    [ "Enedis",[6.35140490465913, 48.619609750976366]],
    [ "Enedis",[6.3580843153457, 48.6147269655237]],
    [ "Enedis",[6.348738999999999, 48.6249605]],
    [ "Enedis",[6.361493000000001, 48.6186285]],
    [ "Enedis",[6.358512499999998, 48.622133]],
    [ "Enedis",[6.3466254174398635, 48.61705158883161]],
    [ "Enedis",[6.3565814134916705, 48.61244576909121]],
    [ "Enedis",[6.345484055506865, 48.6232468165352]],
    [ "Enedis",[6.351363189905364, 48.62111545762357]],
    [ "Enedis",[6.350215561047949, 48.61250015488548]],
    [ "Enedis",[6.3520085773340345, 48.612994362235064]],
    [ "Enedis",[6.345791343401655, 48.62513883782812]],
    [ "Enedis",[6.354954011148272, 48.61824364548494]],
    [ "Enedis",[6.368396000000001, 48.61585850000001]],
    [ "Enedis",[6.350598497461819, 48.616547303968225]],
    [ "Enedis",[6.3633394999999995, 48.619730499999996]],
    [ "Enedis",[6.37046779403621, 48.62056777795527]],
    [ "Enedis",[6.347866129300393, 48.628001467867314]],
    [ "Enedis",[2.7900505507637012, 50.28914023090747]],
    [ "Enedis",[2.7906681168996186, 50.2878257622194]],
    [ "Enedis",[2.793671754584275, 50.28778454835468]],
    [ "Enedis",[2.7891774006409067, 50.28741143028057]],
    [ "Enedis",[2.7816664814471252, 50.28827054607297]],
    [ "Enedis",[2.7884185, 50.29079749999999]],
    [ "Enedis",[2.7867693525394928, 50.29131111672206]],
    [ "Enedis",[2.7845751923309736, 50.29167795793475]],
    [ "Enedis",[2.7860145000000007, 50.29391650000001]],
    [ "Enedis",[2.728108014936481, 50.30431487841225]],
    [ "Enedis",[2.732378395404356, 50.30388675380484]],
    [ "Enedis",[2.732633, 50.3049835]],
    [ "Enedis",[2.7322054999999987, 50.302615499999995]],
    [ "Enedis",[2.7422544999999987, 50.30357625]],
    [ "Enedis",[2.737791986427795, 50.303135261129206]],
    [ "Enedis",[2.737803397260275, 50.29915938213471]],
    [ "Enedis",[2.736411537717602, 50.30099819084462]],
    [ "Enedis",[2.7387075999999992, 50.303849099999994]],
    [ "Enedis",[2.736714705398712, 50.3033044532937]],
    [ "Enedis",[2.7330630824135405, 50.29962570198676]],
    [ "Enedis",[2.750462168527379, 50.29995698936735]],
    [ "Enedis",[2.749159695584668, 50.30222204463853]],
    [ "Enedis",[2.738913464547677, 50.29837736457485]],
    [ "Enedis",[2.738183611721611, 50.29636072161171]],
    [ "Enedis",[2.7407041204638993, 50.293660268799115]],
    [ "Enedis",[2.7463870000000004, 50.298779499999995]],
    [ "Enedis",[2.7455762385594364, 50.29567270809639]],
    [ "Enedis",[2.7484284021554166, 50.29799629854414]],
    [ "Enedis",[2.7559582500000004, 50.292916500000004]],
    [ "Enedis",[2.750752, 50.295313500000006]],
    [ "Enedis",[2.754638884499497, 50.29738814685861]],
    [ "Enedis",[2.7526989755747135, 50.292529205459765]],
    [ "Enedis",[2.7400773858214045, 50.284333346966605]],
    [ "Enedis",[2.7459657243886926, 50.28770005817653]],
    [ "Enedis",[2.74480916777042, 50.2900320042452]],
    [ "Enedis",[2.7687950999999997, 50.28699495]],
    [ "Enedis",[2.760519067282322, 50.28615332739665]],
    [ "Enedis",[2.769306310260903, 50.28499941394081]],
    [ "Enedis",[2.7662914086521853, 50.281439782124]],
    [ "Enedis",[2.773471199827481, 50.297442113493524]],
    [ "Enedis",[2.7703699272485864, 50.29602100874733]],
    [ "Enedis",[2.771968736601476, 50.29660642455238]],
    [ "Enedis",[2.769806938077403, 50.29246732534333]],
    [ "144278530",[2.7704176499999993, 50.294556033333336]],
    [ "Enedis",[2.769287822222222, 50.293926159477124]],
    [ "Enedis",[2.7797035000000005, 50.294126]],
    [ "Enedis",[2.7830494999999997, 50.2974685]],
    [ "Enedis",[2.7802563777777776, 50.295886288888894]],
    [ "Enedis",[2.7729836686045637, 50.294343163662646]],
    [ "Enedis",[2.7681, 50.2957225]],
    [ "Enedis",[2.768234588424878, 50.29683294349584]],
    [ "Enedis",[2.777214368298369, 50.292509060606065]],
    [ "Poids public",[2.78216796804543, 50.292696839024025]],
    [ "Enedis",[2.7742906387843442, 50.287682535543205]],
    [ "Enedis",[2.7796813517126147, 50.2872221487051]],
    [ "144290084",[2.763915823129252, 50.295144666666666]],
    [ "Enedis",[2.763412800925523, 50.29590529899685]],
    [ "Enedis",[2.783193935897436, 50.2982322852564]],
    [ "Enedis",[2.781674513725492, 50.298509231372556]],
    [ "Enedis",[2.7635060910349605, 50.29017567878159]],
    [ "Enedis",[2.7564809284116327, 50.2887185458613]],
    [ "Enedis",[2.758844, 50.287739]],
    [ "Enedis",[2.757224215518993, 50.286042052813315]],
    [ "Enedis",[2.7666340000000003, 50.289218000000005]],
    [ "Enedis",[2.774338673043478, 50.29150448579709]],
    [ "Enedis",[2.773788356784495, 50.29048126781861]],
    [ "Enedis",[2.7517982742927436, 50.286152351783514]],
    [ "Enedis",[2.7686754913410616, 50.29079450007156]],
    [ "Enedis",[2.7565957098487117, 50.29059774170926]],
    [ "Enedis",[2.766653131984086, 50.2923636327625]],
    [ "Enedis",[2.7750458099173554, 50.29023766942149]],
    [ "Enedis",[2.730056950739936, 50.29731830430507]],
    [ "Enedis",[2.7333037436205454, 50.297395610716755]],
    [ "Enedis",[2.7801121418975643, 50.29009482283795]],
    [ "Enedis",[2.7294095735529145, 50.29707369890903]],
    [ "Enedis",[2.7770526578598984, 50.28924853001785]],
    [ "Enedis",[2.7767433836182596, 50.291776442983284]],
    [ "Enedis",[3.5704704599662223, 49.752948832911116]],
    [ "EDF",[5.712009794290458, 45.05294050019051]],
    [ "Enedis",[6.882717518353385, 48.07544420306676]],
    [ "Enedis",[2.845365724645956, 44.394479136255825]],
    [ "Enedis",[-3.5517491999999997, 48.27396065]],
    [ "Enedis",[-3.544972678021506, 48.27896357569355]],
    [ "Enedis",[-0.6142836734607214, 47.476549212314225]],
    [ "Enedis",[-0.6192450000000005, 47.480194999999995]],
    [ "RP Grand Périgné",[-0.6149136000000001, 47.47374225000001]],
    [ "Enedis",[-0.9815643043439706, 43.713335429734784]],
    [ "SRD",[0.5312114505083443, 46.85224518196609]],
    [ "Enedis",[2.8087609748088167, 45.576322547908234]],
    [ "Enedis",[2.7948664424242424, 45.58732495757575]],
    [ "Enedis",[2.809864805739514, 45.57532747019867]],
    [ "Enedis",[2.79782325, 45.58705565]],
    [ "Enedis",[2.8052678270676688, 45.57552895989975]],
    [ "Enedis",[2.8022693378995442, 45.58415533789954]],
    [ "Enedis",[2.8105906146788993, 45.575300264016306]],
    [ "Enedis",[2.805756001776829, 45.57911034612652]],
    [ "Enedis",[2.8085093949177877, 45.580345695067265]],
    [ "Enedis",[2.7743938367582808, 50.2681361763721]],
    [ "Enedis",[2.789135165829146, 50.2640695041876]],
    [ "Enedis",[2.7846397893033554, 50.269763962527676]],
    [ "Enedis",[2.783044129874214, 50.26525055587001]],
    [ "Enedis",[2.7818053545020347, 50.25804035550445]],
    [ "Enedis",[2.7905304999999996, 50.261765499999996]],
    [ "Enedis",[2.7774924837005863, 50.255113332920686]],
    [ "Enedis",[2.7863235, 50.264024]],
    [ "Enedis",[2.793236032558597, 50.2632433092443]],
    [ "Enedis",[2.7892629999999996, 50.267542250000005]],
    [ "Enedis",[2.78003775, 50.265187]],
    [ "Enedis",[2.7770285601521634, 50.26396713076557]],
    [ "Enedis",[-1.6687033217882572, 48.1080226465242]],
    [ "Enedis",[2.7790119499999997, 50.2683416]],
    [ "Enedis",[2.7872227257724047, 50.26950905104586]],
    [ "Enedis",[2.77757721629244, 50.26608035123115]],
    [ "Enedis",[2.793612905198777, 50.26545538837921]],
    [ "Enedis",[2.7867897078128823, 50.25808464756306]],
    [ "Enedis",[2.7995699339339337, 50.26706578678679]],
    [ "Enedis",[2.7751652999999994, 50.26508325]],
    [ "Enedis",[2.7922214654805106, 50.25902170363333]],
    [ "Enedis",[2.796646002397219, 50.26109772587798]],
    [ "Enedis",[2.8016686578366445, 50.268793039735094]],
    [ "Enedis",[2.794530591656131, 50.26579635608934]],
    [ "Enedis",[2.7976190082550527, 50.264555664104755]],
    [ "Enedis",[2.796287822909002, 50.26891915264491]],
    [ "Enedis",[2.8004121529596055, 50.25851897571893]],
    [ "Enedis",[2.7811045, 50.263789]],
    [ "Enedis",[2.797411226439791, 50.26759044502618]],
    [ "Enedis",[2.7871765114258364, 50.267283478584176]],
    [ "144649747",[2.7633059858618827, 50.26225239532354]],
    [ "Enedis",[2.7524935667963684, 50.26443038002594]],
    [ "Enedis",[2.7568627850624186, 50.26640948283385]],
    [ "Enedis",[2.759936043900381, 50.26635883551429]],
    [ "Enedis",[2.7568858391472872, 50.26218198320414]],
    [ "Enedis",[2.7583104999999986, 50.25582375]],
    [ "Enedis",[2.7617725, 50.258575500000006]],
    [ "Enedis",[2.7553165481481474, 50.25989133333333]],
    [ "Enedis",[2.760076449758617, 50.26123974536882]],
    [ "Enedis",[2.756967405637766, 50.25817279609359]],
    [ "Enedis",[1.4169487405443622, 43.69266256804524]],
    [ "Enedis",[1.4122781679851093, 43.68312755405615]],
    [ "Enedis",[1.4113418845470698, 43.69407065127293]],
    [ "Enedis",[1.4189456792121442, 43.69057251223184]],
    [ "Enedis",[1.4001567911985018, 43.70500368913857]],
    [ "Enedis",[1.4107163121516169, 43.6955705477146]],
    [ "Enedis",[1.3993388386003225, 43.70258841658598]],
    [ "Enedis",[1.4193745461288834, 43.682651637459436]],
    [ "Enedis",[1.4151895, 43.695643999999994]],
    [ "Enedis",[1.424371080257761, 43.68028962858817]],
    [ "Enedis",[1.3963903500000003, 43.70665030000001]],
    [ "Enedis",[1.4211265485105222, 43.67912032513437]],
    [ "Enedis",[1.4104591678891605, 43.68527055990221]],
    [ "Enedis",[1.4022475168377455, 43.69965578480101]],
    [ "Enedis",[1.4163274999999989, 43.694993499999995]],
    [ "Enedis",[1.4061434563591015, 43.68803418786367]],
    [ "Enedis",[1.4202955065913374, 43.68686759322034]],
    [ "Enedis",[1.4221883742286374, 43.684908802217336]],
    [ "Enedis",[1.409567814757078, 43.69736670849572]],
    [ "Enedis",[1.4307403944265802, 43.6801107738478]],
    [ "Enedis",[1.4143709999999998, 43.684171]],
    [ "Enedis",[1.4226805272541287, 43.69068569779336]],
    [ "Enedis",[1.4209767831415991, 43.689712992671495]],
    [ "Enedis",[1.414104674407354, 43.68610202612482]],
    [ "Enedis",[1.4169661999999998, 43.69052639999999]],
    [ "Enedis",[1.4279178999999989, 43.68171220000001]],
    [ "Enedis",[1.4154976442577019, 43.68285617535013]],
    [ "Enedis",[1.4210188245027262, 43.684588110944595]],
    [ "Enedis",[1.4015997154138637, 43.69641454996587]],
    [ "Enedis",[1.4240962074391987, 43.68444408583691]],
    [ "Enedis",[1.414774382222222, 43.687547339999995]],
    [ "Enedis",[1.4052264742002063, 43.69775938364294]],
    [ "Enedis",[1.4155813597701155, 43.68979757816093]],
    [ "Enedis",[1.4132369653626728, 43.6933139808476]],
    [ "Preventorium",[-2.7264280000000003, 48.54538624999999]],
    [ "Enedis",[2.767545340686275, 50.27220116421567]],
    [ "Enedis",[2.765794076473713, 50.269260836873784]],
    [ "Enedis",[2.78078165, 50.270710699999995]],
    [ "Enedis",[2.766670922213092, 50.273674621473575]],
    [ "Enedis",[2.7610336196894227, 50.27122222756128]],
    [ "Enedis",[2.756738284615385, 50.27288315470085]],
    [ "Enedis",[2.769678114478114, 50.277409288439955]],
    [ "Enedis",[2.7701678255451716, 50.266868473520255]],
    [ "Enedis",[2.7719025, 50.27855699999999]],
    [ "Enedis",[2.757681366634271, 50.27468222360821]],
    [ "Enedis",[2.7706389751801446, 50.27636783213239]],
    [ "Enedis",[2.763607676635514, 50.274316820560756]],
    [ "Enedis",[2.743070500000001, 50.280280499999996]],
    [ "Enedis",[2.773688182485696, 50.273464896297675]],
    [ "Enedis",[2.766579390758901, 50.26667120752717]],
    [ "Enedis",[2.777714170617062, 50.27660164766477]],
    [ "Enedis",[2.7416330000000007, 50.278734500000006]],
    [ "Enedis",[2.7775263510324484, 50.26963620353982]],
    [ "Enedis",[2.757463, 50.277711000000004]],
    [ "Enedis",[2.7764032430752765, 50.27409354932707]],
    [ "Enedis",[2.7547452638213343, 50.27386159980136]],
    [ "Enedis",[2.7552845000000006, 50.270182000000005]],
    [ "Enedis",[2.76312, 50.2678795]],
    [ "Enedis",[2.7731390703181216, 50.27007620444701]],
    [ "Enedis",[2.7685950602207505, 50.27056596891832]],
    [ "144742177",[2.764759221141648, 50.27886845031713]],
    [ "Enedis",[2.7721798210659907, 50.271878100676815]],
    [ "Enedis",[2.7514979542300924, 50.27498387097442]],
    [ "Enedis",[2.7702427404418826, 50.26913551757926]],
    [ "Enedis",[2.768982591838346, 50.26825553516458]],
    [ "Enedis",[2.7736099485551478, 50.26611016866098]],
    [ "Enedis",[2.7379619699842026, 50.282956576619284]],
    [ "Enedis",[2.7346833999999993, 50.28594615]],
    [ "Enedis",[2.7322827206992444, 50.28254965395312]],
    [ "Enedis",[2.7234610476190477, 50.281793257142866]],
    [ "Enedis",[2.716372876753041, 50.284566308070964]],
    [ "Enedis",[2.7388095, 50.28688555]],
    [ "Enedis",[2.7110791952334314, 50.275257656872355]],
    [ "Enedis",[2.7250776666666665, 50.26872446929824]],
    [ "Enedis",[2.7179987499999996, 50.27854849999999]],
    [ "Enedis",[2.7351979999999996, 50.292117999999995]],
    [ "Enedis",[2.7267783253778837, 50.28137394431185]],
    [ "Enedis",[2.725069861145448, 50.27608982117841]],
    [ "Enedis",[2.7318196765353377, 50.29164252516511]],
    [ "Enedis",[2.735071218045114, 50.29155281704261]],
    [ "Enedis",[2.7234005175053735, 50.28436505159064]],
    [ "Enedis",[2.735915494749125, 50.28211848144691]],
    [ "Enedis",[2.7201859092495635, 50.270221151832466]],
    [ "Enedis",[2.7203208828070187, 50.27668879017544]],
    [ "Enedis",[-2.7716950999999996, 48.5298142]],
    [ "Parking ZA Ch VR",[-2.7553962500000004, 48.5306395]],
    [ "Enedis",[-2.7700128262687236, 48.528906540040246]],
    [ "SRD",[0.27782794999999955, 46.73906755]],
    [ "Enedis",[4.43255033095238, 45.472373014285715]],
    [ "Enedis",[2.723802274640089, 50.280021936784784]],
    [ "Enedis",[2.7391257500000004, 50.29052975]],
    [ "Enedis",[2.7178305000000003, 50.271485999999996]],
    [ "Enedis",[2.7172852072205727, 50.28184503363006]],
    [ "Enedis",[2.721811725267149, 50.2750002092382]],
    [ "Enedis",[2.7302574999999996, 50.280553]],
    [ "Enedis",[2.7214311532192337, 50.2680630415648]],
    [ "Enedis",[2.7364707435897433, 50.28437856410255]],
    [ "Enedis",[4.4293534999999995, 45.4668575615942]],
    [ "Enedis",[2.720388558882236, 50.26987265668662]],
    [ "Enedis",[4.426799848587572, 45.475445385310735]],
    [ "Enedis",[2.7172072245190932, 50.272148119724385]],
    [ "Enedis",[2.729790670223438, 50.27923352904696]],
    [ "Enedis",[2.7330489206546993, 50.286814548145294]],
    [ "Enedis",[2.7424561733080335, 50.28633509044907]],
    [ "Enedis",[2.733360046898639, 50.28495600605144]],
    [ "Enedis",[2.72140464881475, 50.2693448276851]],
    [ "Enedis",[3.756994802185792, 49.52972156437158]],
    [ "Enedis",[3.7564775704828666, 49.52044739271807]],
    [ "Enedis",[3.756461798148148, 49.52360059398147]],
    [ "Enedis",[-1.005597999999999, 43.71110675]],
    [ "Enedis",[-1.0139386687253762, 43.71196372788312]],
    [ "Enedis",[-1.0264560360232646, 43.69415132864413]],
    [ "Enedis",[3.3729243998136074, 49.76607384032309]],
    [ "Enedis",[2.7015732490685007, 50.316497341358556]],
    [ "Enedis",[2.7023734886466864, 50.314718010899185]],
    [ "Enedis",[2.6990662559066316, 50.31538345402789]],
    [ "Enedis",[2.6986667372330553, 50.32242215784587]],
    [ "Enedis",[2.7167776663666365, 50.32528149444944]],
    [ "Enedis",[2.714013000000001, 50.318287000000005]],
    [ "Enedis",[2.7091448351798326, 50.32434629086385]],
    [ "Enedis",[2.7132512722443556, 50.320822719787515]],
    [ "Enedis",[2.713823968777198, 50.32819777839425]],
    [ "Enedis",[2.6965608216526498, 50.32389597476973]],
    [ "Enedis",[2.722437357877452, 50.32580467686457]],
    [ "Enedis",[2.703672787704131, 50.32526352929875]],
    [ "Enedis",[2.70851490962963, 50.32919836677249]],
    [ "Enedis",[2.7132285, 50.32536275000001]],
    [ "Enedis",[2.705464, 50.32178999999999]],
    [ "Enedis",[2.7060865620114645, 50.3243388249088]],
    [ "Enedis",[2.704750482859227, 50.31990484828593]],
    [ "Enedis",[2.7059256472189808, 50.32737355950992]],
    [ "Enedis",[2.701032814740368, 50.324251931993295]],
    [ "Enedis",[6.060843183924877, 48.91972299548275]],
    [ "Poste électrique de Boëge",[6.418308985636693, 46.2102625484876]],
    [ "Lavoir",[3.257464232095909, 45.971359915562694]],
    [ "Enedis",[2.7655722305981767, 50.30042935379142]],
    [ "Enedis",[2.7621845, 50.30961725]],
    [ "Enedis",[2.765118763071589, 50.31715727571023]],
    [ "Enedis",[2.7678842984665057, 50.31421104455206]],
    [ "Enedis",[2.7591278891724085, 50.30435188143234]],
    [ "Enedis",[2.7606043708653973, 50.314222272580004]],
    [ "Enedis",[2.7649812822383097, 50.31115261326508]],
    [ "Enedis",[2.756918963464432, 50.304811172576834]],
    [ "Enedis",[2.7626307411683815, 50.31216988365224]],
    [ "Enedis",[2.7680286000000005, 50.30774815]],
    [ "144980174",[2.7565065, 50.30619]],
    [ "Enedis",[2.764034462368936, 50.307058378592004]],
    [ "Enedis",[2.75879515375321, 50.31548883023713]],
    [ "Enedis",[2.76057525, 50.30745375]],
    [ "Enedis",[2.762576, 50.31906575]],
    [ "Enedis",[2.7613988015977595, 50.31501457945477]],
    [ "Enedis",[2.7526992891832225, 50.31687827041942]],
    [ "Enedis",[2.7560889913872657, 50.31260645155337]],
    [ "Enedis",[2.7440555, 50.3178035]],
    [ "Enedis",[2.7373984480655604, 50.31605064894225]],
    [ "144983476",[2.7514092338308456, 50.308019139303475]],
    [ "Enedis",[2.7451635000000003, 50.31203799999999]],
    [ "Enedis",[2.725604846095372, 50.3123918770602]],
    [ "Enedis",[2.7341895227529402, 50.31791455359514]],
    [ "Enedis",[2.7504966367614876, 50.31060215390225]],
    [ "Enedis",[2.742191114740096, 50.31558553962457]],
    [ "Enedis",[2.7462025081717063, 50.31442493274705]],
    [ "Enedis",[2.734936, 50.314104750000006]],
    [ "Enedis",[2.7375017076549115, 50.31820803078564]],
    [ "Enedis",[2.7502718515895945, 50.31343427820069]],
    [ "Enedis",[2.347204, 48.663341749999994]],
    [ "Enedis",[2.3528782500000003, 48.6557685]],
    [ "Enedis",[2.353318, 48.66426425000001]],
    [ "Guérinière",[2.3458190000000014, 48.66560075]],
    [ "Enedis",[2.34828975, 48.651932499999994]],
    [ "Enedis",[2.354208749999999, 48.66708874999999]],
    [ "Enedis",[2.353881, 48.669380499999995]],
    [ "Gilquinière",[2.34437975, 48.647355250000004]],
    [ "Enedis",[2.354717499999999, 48.649595000000005]],
    [ "Enedis",[2.35717675, 48.66309649999999]],
    [ "Enedis",[2.354935249999999, 48.654966249999994]],
    [ "Enedis",[2.3560784999999997, 48.651653499999995]],
    [ "Enedis",[2.35413825, 48.6585045]],
    [ "144995716",[2.3466352587678325, 48.66441081719988]],
    [ "Enedis",[2.360424749999999, 48.659868249999995]],
    [ "Enedis",[2.360068000000001, 48.65780524999999]],
    [ "Enedis",[2.3472032499999997, 48.6530125]],
    [ "Enedis",[2.3420805000000002, 48.65806925]],
    [ "Enedis",[2.35790099098675, 48.653394749947736]],
    [ "Enedis",[2.3492084999999996, 48.663879]],
    [ "Enedis",[2.348801, 48.64730349999999]],
    [ "Enedis",[2.34995475, 48.659651]],
    [ "Enedis",[2.3429909999999996, 48.662597749999996]],
    [ "Enedis",[2.343536694634703, 48.66011186244292]],
    [ "Enedis",[2.345590705964378, 48.65778818577677]],
    [ "Enedis",[2.350741324410452, 48.65039902294455]],
    [ "Enedis",[2.3531252499999997, 48.64629299999999]],
    [ "Enedis",[2.3552869999999992, 48.66592]],
    [ "Enedis",[2.350039, 48.65694774999999]],
    [ "Enedis",[2.3473770000000016, 48.650088999999994]],
    [ "Enedis",[2.3590210000000003, 48.661210000000004]],
    [ "Enedis",[2.3545225, 48.6695145]],
    [ "Enedis",[2.3537382499999997, 48.66195224999999]],
    [ "Enedis",[2.357732249999999, 48.655243250000005]],
    [ "Enedis",[2.351093, 48.66278125]],
    [ "Enedis",[2.3597338638874037, 48.666201015321576]],
    [ "Enedis",[2.3607749916302123, 48.64925000575307]],
    [ "Enedis",[2.3617556803797535, 48.653995997953885]],
    [ "Enedis",[2.3528452616487456, 48.64267540818049]],
    [ "Enedis",[2.352307678605055, 48.6608209833164]],
    [ "Enedis",[2.349316170894861, 48.657087649798534]],
    [ "Poste électrique de Langon",[-0.24214870785911927, 44.53567509270793]],
    [ "Enedis",[4.376815100000002, 45.488970900000005]],
    [ "Enedis",[-0.9139919718196459, 43.681159824476644]],
    [ "Enedis",[3.21683850543933, 45.897500335285905]],
    [ "Enedis",[3.6633059948927476, 49.58825047803881]],
    [ "Enedis",[3.6482241550482413, 49.59017247492866]],
    [ "Enedis",[3.675085966615465, 49.60062049947262]],
    [ "Enedis",[3.6521565028607754, 49.581606131595684]],
    [ "Enedis",[3.6519251741827725, 49.59150247578143]],
    [ "Enedis",[3.657776950280673, 49.59147099037691]],
    [ "Enedis",[3.652747107370865, 49.58412825304701]],
    [ "Enedis",[-2.7593985080157295, 48.05696366999395]],
    [ "Enedis",[-2.762200500000001, 48.05609825]],
    [ "F D'amboise",[-2.7680829944541734, 47.659737838704025]],
    [ "Defforges",[-2.7762433689830655, 47.6517441836228]],
    [ "Enedis",[0.054671894528675005, 47.44670810019775]],
    [ "Enedis",[3.7384399944444446, 49.556553616666655]],
    [ "Enedis",[3.6669958044444457, 49.57216547555556]],
    [ "Enedis",[3.26331668800813, 45.836302127032525]],
    [ "Poste électrique de Buire",[4.049265824744612, 49.9158733343533]],
    [ "RTE",[3.7673954073224154, 49.75084634630752]],
    [ "Enedis",[2.8187438639455773, 50.275961768707475]],
    [ "Enedis",[2.8127158924762745, 50.27765119430584]],
    [ "Enedis",[2.8178150999999994, 50.279698799999984]],
    [ "Enedis",[2.810025305805081, 50.29035646054284]],
    [ "Enedis",[2.8106395, 50.2737385]],
    [ "Enedis",[2.82156395, 50.27753740000001]],
    [ "Enedis",[2.813792975308642, 50.28847139506174]],
    [ "Enedis",[2.8105015422885575, 50.289284895522385]],
    [ "Enedis",[2.818171905660378, 50.290025260910994]],
    [ "Enedis",[2.8120008003429833, 50.275613983129894]],
    [ "Enedis",[2.810210771324441, 50.28663430287354]],
    [ "Enedis",[2.8078840713962943, 50.289561765777975]],
    [ "Enedis",[2.8110496060438552, 50.2726586298139]],
    [ "Enedis",[2.814652297730306, 50.291885955852244]],
    [ "Enedis",[2.8111798478335626, 50.28502727762494]],
    [ "Enedis",[2.8129994999999997, 50.28711900000001]],
    [ "Enedis",[2.816347547638876, 50.274875496573536]],
    [ "Enedis",[2.8158080977292315, 50.27702844706333]],
    [ "Enedis",[6.9487617291099, 43.92685970390554]],
    [ "RTE",[5.936610418074022, 43.12699681392029]],
    [ "RTE",[2.518792479997926, 48.85513452479314]],
    [ "Enedis",[2.1901851713796066, 48.46075065981149]],
    [ "Enedis",[2.179380787234043, 48.44603135525467]],
    [ "Enedis",[2.2240645, 48.42253050000001]],
    [ "Enedis",[2.1707991321467106, 48.45476127382236]],
    [ "Enedis",[2.1798780499999997, 48.49238384999999]],
    [ "Enedis",[2.198969762328173, 48.49447852010006]],
    [ "Enedis",[2.2000059115448276, 48.491806215038594]],
    [ "Enedis",[2.1781526885153935, 48.486735265173046]],
    [ "145501082",[-0.033232954887218884, 46.57614240175439]],
    [ "Enedis",[-0.9964888961384815, 43.67866622148247]],
    [ "Enedis",[-0.9890014999999999, 43.68131925]],
    [ "Enedis",[-1.0046444499999994, 43.6143334]],
    [ "Enedis",[-0.9917012256177249, 43.61195950465206]],
    [ "Enedis",[-1.0138806558603495, 43.61091204239401]],
    [ "Enedis",[-0.9852769220489982, 43.608603064773575]],
    [ "Enedis",[-0.9961265656565653, 43.603853666666666]],
    [ "Enedis",[-0.9926693317590689, 43.60819091909651]],
    [ "Enedis",[-0.9970260493519854, 43.61018153387718]],
    [ "Enedis",[-1.0020510647660688, 43.60241226971693]],
    [ "Enedis",[-1.007702628129516, 43.60467380583229]],
    [ "Enedis",[-1.022482, 43.62759095]],
    [ "Enedis",[-0.9998647499999999, 43.61037395]],
    [ "Enedis",[-0.9919927461855652, 43.60650028211753]],
    [ "Enedis",[-0.9998729999999997, 43.60591075000001]],
    [ "Enedis",[-1.0025368403184618, 43.607777262130085]],
    [ "Gérédis",[-0.0357724, 46.54006705]],
    [ "Gérédis",[-0.026527345165238296, 46.5800653382293]],
    [ "Poste électrique de Bègles",[-0.5396480179366185, 44.81565015112614]],
    [ "Enedis",[3.341606982280174, 46.0268777742561]],
    [ "Enedis",[2.778361380181638, 50.30776478356264]],
    [ "Enedis",[2.7767084863582454, 50.302467192170816]],
    [ "Enedis",[2.7788659175704993, 50.30378716992046]],
    [ "Enedis",[2.7885815000000003, 50.30225250000001]],
    [ "Enedis",[2.783222607650272, 50.30493674535519]],
    [ "Enedis",[2.7768162499999995, 50.305319]],
    [ "Enedis",[2.789437, 50.30418075000001]],
    [ "Enedis",[2.7910053707336213, 50.306926132862564]],
    [ "Enedis",[2.770353499999999, 50.30465825]],
    [ "Enedis",[2.7806976960018925, 50.30753164632125]],
    [ "Enedis",[2.7824187833981844, 50.30163908171207]],
    [ "Enedis",[2.782647360921387, 50.30633834163654]],
    [ "Enedis",[2.777653154826528, 50.30150596853119]],
    [ "Enedis",[2.7738468287568483, 50.30524987790249]],
    [ "Enedis",[3.3566896060606073, 46.00986193939393]],
    [ "Enedis",[2.3919859999999997, 47.05691099999999]],
    [ "Enedis",[-2.9371039999999997, 48.066351000000004]],
    [ "Enedis",[-2.8686337867963454, 48.064722343353964]],
    [ "Enedis",[-2.885645180744777, 48.062629644686645]],
    [ "Enedis",[-2.8805112094395278, 48.06700903539823]],
    [ "Enedis",[0.9080046643186093, 45.88137359957838]],
    [ "Enedis",[0.9340045067748165, 45.92120498883892]],
    [ "Enedis",[0.9301162474904775, 45.9272749901372]],
    [ "Enedis",[0.8869750392679716, 45.890528147356925]],
    [ "Enedis",[0.8891356115589175, 45.89243935841357]],
    [ "Enedis",[0.895545109847469, 45.88077516516595]],
    [ "Enedis",[0.9305673597082077, 45.90902824290163]],
    [ "Enedis",[0.921576907658604, 45.900857296988505]],
    [ "Enedis",[0.9203427413857587, 45.88848597527639]],
    [ "Enedis",[0.9118760441172167, 45.89956145079367]],
    [ "Enedis",[0.9156429883754117, 45.88924199562901]],
    [ "Enedis",[0.90309319449226, 45.88527360528841]],
    [ "Enedis",[0.9083179999375076, 45.883770003166276]],
    [ "Enedis",[0.9333437551920644, 45.90087899881788]],
    [ "Enedis",[0.8979297034158817, 45.895186106357336]],
    [ "Enedis",[0.907225789543597, 45.89807669400945]],
    [ "Enedis",[0.92481980210668, 45.89786110070915]],
    [ "Enedis",[0.9138089974867979, 45.89130950226506]],
    [ "Enedis",[0.9311925387777222, 45.899876604921566]],
    [ "Enedis",[0.9025565751885811, 45.89202125912092]],
    [ "Enedis",[0.8930056306403046, 45.886996106570706]],
    [ "Enedis",[0.9047732417631795, 45.88996143131307]],
    [ "Enedis",[0.9034339142975232, 45.88211388632509]],
    [ "Enedis",[0.9169717261720659, 45.89188162039698]],
    [ "Enedis",[0.9149080088390046, 45.9041665119525]],
    [ "Enedis",[0.9076859522347378, 45.89463235212913]],
    [ "Enedis",[0.9002531928313267, 45.88082835131601]],
    [ "Enedis",[0.8377716653799672, 45.897441426300766]],
    [ "Enedis",[0.9136271544371931, 45.904540856927134]],
    [ "Enedis",[0.8813170868325877, 45.88719430013914]],
    [ "Leclerc St-Junien",[0.9145264090644888, 45.896272496386324]],
    [ "Enedis",[0.9170183002422344, 45.884875201422574]],
    [ "Enedis",[0.8902194946882924, 45.87807345007068]],
    [ "Enedis",[0.9103065448418148, 45.8959953304833]],
    [ "Enedis",[0.9314918632847604, 45.90195005935845]],
    [ "Enedis",[0.9185875079767469, 45.895221506018274]],
    [ "Enedis",[0.9126782560038597, 45.88559830533418]],
    [ "Enedis",[0.9197959382779852, 45.896875258952484]],
    [ "Enedis",[0.900550007573946, 45.88819349496677]],
    [ "Enedis",[0.9170772598287904, 45.90370895717185]],
    [ "Enedis",[0.9200167303133034, 45.89215524180878]],
    [ "Enedis",[0.8960659573182226, 45.88238235147859]],
    [ "Enedis",[0.894267152867457, 45.895434649276226]],
    [ "Enedis",[-0.32272700000000004, 49.1464455]],
    [ "HB Dodu",[-2.3316339999999998, 47.441646999999996]],
    [ "HB Bourg",[-2.3167830634737454, 47.448849733602614]],
    [ "Enedis",[2.8052561152482274, 50.30106293617021]],
    [ "Enedis",[2.794606, 50.293751]],
    [ "Enedis",[2.8118084011246487, 50.30345222638758]],
    [ "Enedis",[2.7946490000000006, 50.30127575000001]],
    [ "Enedis",[2.7969473252143415, 50.3046152535074]],
    [ "Enedis",[2.7919034500000004, 50.29442615]],
    [ "Enedis",[2.799841437256156, 50.301052061733095]],
    [ "Enedis",[2.8069445082480087, 50.290451847838455]],
    [ "Enedis",[2.822400249999998, 50.31359975]],
    [ "Enedis",[2.8082340806201547, 50.30477662015504]],
    [ "Enedis",[2.8035068037895785, 50.305602169284484]],
    [ "Enedis",[2.8197046681806706, 50.30522850643452]],
    [ "Enedis",[2.792720250000001, 50.302589000000005]],
    [ "Enedis",[2.7838981415525113, 50.29836120960378]],
    [ "Enedis",[2.801133, 50.2912715]],
    [ "Enedis",[2.80405345, 50.291005399999996]],
    [ "Enedis",[2.8043236596543957, 50.29525729501628]],
    [ "Enedis",[2.8004105, 50.2944045]],
    [ "Enedis",[2.790858171641791, 50.29170901658375]],
    [ "Enedis",[2.8025355489044745, 50.3031116679108]],
    [ "Enedis",[2.802088162295893, 50.29467300478312]],
    [ "Enedis",[2.806160726354994, 50.30883244741743]],
    [ "Enedis",[2.7910041375661367, 50.297425149645605]],
    [ "Enedis",[2.814841, 50.29724999999999]],
    [ "Enedis",[2.806246, 50.291504249999996]],
    [ "Enedis",[2.7909742493224936, 50.29674654200543]],
    [ "Enedis",[2.8064125759511254, 50.305634012403964]],
    [ "Enedis",[2.7978289999999997, 50.307019749999995]],
    [ "Enedis",[2.8008474999999997, 50.30033824999999]],
    [ "Enedis",[2.800146706440959, 50.30408300096339]],
    [ "Enedis",[2.7971068736783877, 50.30221129121016]],
    [ "Enedis",[2.8330416876498896, 50.295217252004875]],
    [ "Enedis",[2.7955701787816665, 50.29361762904035]],
    [ "Enedis",[4.532826666666668, 45.486981622222224]],
    [ "Enedis",[4.559344798463483, 45.49227162082884]],
    [ "Enedis",[4.53555716161616, 45.489201989899]],
    [ "Enedis",[4.531804793103448, 45.48957020689655]],
    [ "Enedis",[4.5311251736956315, 45.48794282224739]],
    [ "Enedis",[2.7718725, 50.33241724999999]],
    [ "Enedis",[2.4386803295677537, 48.69087143256861]],
    [ "Enedis",[2.4411998334047613, 48.68843743120833]],
    [ "Enedis",[2.445235783446252, 48.69081915624279]],
    [ "Enedis",[2.4389894756156107, 48.68866175318147]],
    [ "Enedis",[2.2842775011573515, 48.631988604281005]],
    [ "Enedis",[2.292012200146728, 48.62458269978644]],
    [ "Enedis",[2.2827250339205882, 48.64805305305917]],
    [ "Enedis",[2.2902629782098605, 48.630338798811145]],
    [ "Enedis",[2.2734783357498523, 48.645325586349934]],
    [ "Enedis",[2.257406721851329, 48.64788041056975]],
    [ "Enedis",[2.2778066267252806, 48.63681177078265]],
    [ "Enedis",[2.260494140395663, 48.640006945111814]],
    [ "Enedis",[2.2571801662958078, 48.641442410465196]],
    [ "Enedis",[2.2782924478420075, 48.639192083270615]],
    [ "Enedis",[2.2706364768560885, 48.64012090569436]],
    [ "Enedis",[2.272881758741642, 48.63723915751859]],
    [ "Enedis",[2.2505856691549844, 48.64447930316764]],
    [ "Enedis",[2.2673674138687603, 48.645158366512185]],
    [ "Enedis",[2.2624197999999995, 48.646377050000005]],
    [ "Enedis",[2.269548295048332, 48.64156663299282]],
    [ "Enedis",[2.273163451535951, 48.640843654782174]],
    [ "Enedis",[2.264403888047991, 48.6417097275293]],
    [ "Enedis",[2.2771718174725666, 48.63361418366035]],
    [ "Enedis",[2.258074296720177, 48.62349738997839]],
    [ "Enedis",[2.2702322879926458, 48.63429358724385]],
    [ "Enedis",[2.2664301772832895, 48.62608594897229]],
    [ "Enedis",[2.2623591500855103, 48.63255106167168]],
    [ "Enedis",[2.251095199999999, 48.615118499999994]],
    [ "Enedis",[2.267738457006031, 48.63032737674176]],
    [ "Enedis",[2.2600794119242296, 48.62934232155276]],
    [ "Enedis",[2.2613401440584613, 48.6288463936442]],
    [ "Enedis",[2.267814491386011, 48.633480269623284]],
    [ "Enedis",[2.275646015836717, 48.6316761674303]],
    [ "Enedis",[2.2469849259028627, 48.615096796030464]],
    [ "Enedis",[2.2664270316211295, 48.631239884653546]],
    [ "Enedis",[2.2402496267391823, 48.630230194604785]],
    [ "Enedis",[2.275105032040431, 48.62803414656781]],
    [ "Enedis",[2.2610809736109916, 48.63039104662205]],
    [ "Enedis",[2.2662321958096707, 48.629554418095]],
    [ "Enedis",[2.2701537509445493, 48.62391599519947]],
    [ "Enedis",[2.2648270819857177, 48.62811799399825]],
    [ "Enedis",[2.2574846536957964, 48.61894858780326]],
    [ "Enedis",[2.2552289627047855, 48.624691871415486]],
    [ "Enedis",[2.2663471878732757, 48.63780073042292]],
    [ "Enedis",[2.2644213681058, 48.63658944581436]],
    [ "Enedis",[2.260571374267277, 48.63857109395934]],
    [ "Enedis",[2.2710693517906666, 48.63147493831375]],
    [ "Enedis",[2.269470573559261, 48.621574848289754]],
    [ "Enedis",[2.27007225, 48.6201309]],
    [ "Enedis",[2.266777799828053, 48.61512967332674]],
    [ "Enedis",[2.2619669011213484, 48.619662170849026]],
    [ "Enedis",[2.265382196099946, 48.61685511148714]],
    [ "Enedis",[6.074843332152206, 48.84523724266339]],
    [ "Enedis",[6.068888665171061, 48.84502370125258]],
    [ "MEDIATHEQUE",[2.23176473957758, 48.642828345007615]],
    [ "Enedis",[2.213620216308411, 48.6638271504412]],
    [ "Enedis",[2.2393265240248525, 48.63640675989911]],
    [ "Enedis",[1.830237246411065, 48.636598205347596]],
    [ "Enedis",[1.8231868981781065, 48.65000097086501]],
    [ "Enedis",[1.8180294268428832, 48.63752479602006]],
    [ "Enedis",[1.825342099969729, 48.62426763802068]],
    [ "Enedis",[1.8261944858825925, 48.6374676478525]],
    [ "Enedis",[1.846888675390933, 48.64449279674572]],
    [ "Enedis",[1.8525053683667212, 48.65211975749724]],
    [ "Enedis",[1.828369704825107, 48.634889602414155]],
    [ "Enedis",[1.82652475513877, 48.62225228627813]],
    [ "Enedis",[1.8287506217194103, 48.64429378371933]],
    [ "Enedis",[1.8243386295063284, 48.644304061109985]],
    [ "Enedis",[1.821493308471358, 48.64117658612445]],
    [ "Enedis",[1.8272104387847903, 48.65294935387005]],
    [ "Enedis",[1.8473647896874315, 48.655946740801156]],
    [ "Enedis",[1.823856807407408, 48.62595824074074]],
    [ "Enedis",[1.8315174798334144, 48.64160705244079]],
    [ "Enedis",[1.8258624746416452, 48.64136206021669]],
    [ "Enedis",[1.8315608138622241, 48.636563128250934]],
    [ "Enedis",[1.824949757372045, 48.6474319471895]],
    [ "Enedis",[1.8522931041567965, 48.649321705873845]],
    [ "Enedis",[1.8291375749642658, 48.63286909648389]],
    [ "Enedis",[1.8197078315769017, 48.65152902104571]],
    [ "Enedis",[1.8560245415517316, 48.642714920662875]],
    [ "Enedis",[1.8505558165428437, 48.649932226547804]],
    [ "Enedis",[1.8316397833148297, 48.63277833584638]],
    [ "Enedis",[1.8521395728600263, 48.653315178828166]],
    [ "Enedis",[1.8379061379513244, 48.655561054646405]],
    [ "Enedis",[1.8304694390431784, 48.64857061428768]],
    [ "Enedis",[1.836530833475763, 48.64068577279628]],
    [ "Enedis",[1.8352701667579578, 48.638440275193695]],
    [ "Enedis",[1.826688002528776, 48.650771009900076]],
    [ "Enedis",[1.829504150934368, 48.64053174096798]],
    [ "Enedis",[1.8242608860950607, 48.63709008625074]],
    [ "Enedis",[1.8615420199715116, 48.64507537595784]],
    [ "Enedis",[1.8244122454339147, 48.644939456671416]],
    [ "Enedis",[1.8489823252599904, 48.65316346627067]],
    [ "Enedis",[1.8272788829364985, 48.64679087941551]],
    [ "Enedis",[-1.0344645775470973, 43.642633561514806]],
    [ "Enedis",[4.679461582784694, 46.65781948394519]],
    [ "Enedis",[2.3007266782072153, 48.65970835120393]],
    [ "Enedis",[2.298832389794963, 48.661165011811704]],
    [ "Enedis",[2.3065080145496473, 48.662092600313265]],
    [ "Enedis",[2.2909052950130397, 48.673249921661146]],
    [ "BG Verdun 58",[2.3180034499999995, 48.8018907]],
    [ "Enedis",[2.312026088295595, 48.80309505748982]],
    [ "Enedis",[2.306421550517762, 48.80027746680683]],
    [ "BG Rene Cros",[2.30473355, 48.79995955]],
    [ "Enedis",[2.316589302096055, 48.80295572329227]],
    [ "Enedis",[2.30111337095065, 48.79241313625382]],
    [ "BG-Dormoy",[2.3166241426037146, 48.80956564956615]],
    [ "BG Caisse",[2.310122475832505, 48.80160885650624]],
    [ "BG Fortin 31",[2.298642655942806, 48.79495047071789]],
    [ "Enedis",[2.3075268435796317, 48.79957298310353]],
    [ "Enedis",[2.2948257657597946, 48.79808027515991]],
    [ "BG Prémontière",[2.2971216298001815, 48.797191942649796]],
    [ "Baudoin",[2.3040491178115765, 48.798524459174565]],
    [ "Enedis",[2.31598567134468, 48.807783324660186]],
    [ "Enedis",[2.3995054179742157, 48.39711899792235]],
    [ "Enedis",[2.3791162583095216, 48.40264081371865]],
    [ "Enedis",[2.2084218337662698, 48.81831823712569]],
    [ "Enedis",[2.201149932689592, 48.81936019790599]],
    [ "Enedis",[2.2072018300171816, 48.81953467690408]],
    [ "Enedis",[2.2312029566030076, 48.57045873886133]],
    [ "Enedis",[2.249607181498307, 48.562697227559816]],
    [ "Enedis",[4.697570840775063, 45.064865889918565]],
    [ "Enedis",[4.739246901960784, 45.066019490196076]],
    [ "Enedis",[4.732292970775096, 45.06271746759848]],
    [ "Enedis",[4.681073499916207, 45.06471011094352]],
    [ "Enedis",[4.707817126095967, 45.0538585872788]],
    [ "Enedis",[2.2653955248647004, 48.56908789524442]],
    [ "Enedis",[2.269747687271207, 48.569786869365664]],
    [ "Enedis",[2.276422412198796, 48.568968341690976]],
    [ "Enedis",[2.2671803083576054, 48.57029673170321]],
    [ "Enedis",[2.266614958678873, 48.57291931538719]],
    [ "Enedis",[2.2756797659364567, 48.68789189753422]],
    [ "Creusiere",[2.269801968664832, 48.67249144143823]],
    [ "Enedis",[2.279141134916977, 48.68878257050519]],
    [ "Enedis",[2.2557922634930923, 48.67358940708117]],
    [ "Enedis",[2.266095696247152, 48.67230047419028]],
    [ "Enedis",[2.274508987682563, 48.69385903712253]],
    [ "Enedis",[2.2764894160431632, 48.673187867087265]],
    [ "Enedis",[2.2573342911614915, 48.675024790674755]],
    [ "carnot",[2.2728149335159777, 48.67089602643656]],
    [ "Enedis",[2.2640985399720654, 48.690380157428315]],
    [ "Enedis",[2.272085613877672, 48.7073103850807]],
    [ "RTE",[5.570609118885482, 45.37685530310475]],
    [ "RTE",[5.4909481007954515, 45.3646212050537]],
    [ "RTE",[5.665241336742463, 45.23399138309602]],
    [ "RTE",[5.60898396479304, 45.307501128562095]],
    [ "RTE",[5.627884238911705, 45.29322367379767]],
    [ "Poste électrique d’Aube",[0.5565826282243344, 48.74285801370216]],
    [ "Poste électrique de Bézon",[-2.4171207576681795, 47.9066602099399]],
    [ "Poste électrique de Laval",[-0.7927410604662659, 48.05119593541834]],
    [ "Poste électrique de Poteau-Rouge",[-3.3117163915584915, 47.82518493411131]],
    [ "RTE",[1.2107814105277737, 44.87330493334036]],
    [ "Poste électrique de La Farradiere",[-0.8604627772886477, 45.967066915742556]],
    [ "RTE",[0.4839208599160863, 45.12197171491705]],
    [ "Poste électrique de Batzendorf",[7.724372417796951, 48.7992953544635]],
    [ "Poste électrique de Miegebat",[-0.4470345938754899, 42.93162961495041]],
    [ "Poste électrique de Aussois",[6.712248427196948, 45.214758503949646]],
    [ "Poste électrique de Commerveil",[0.375974691689531, 48.31674916283651]],
    [ "Poste électrique de Guebwiller",[7.2265186176524425, 47.8993361628255]],
    [ "Poste électrique de Hirsingue",[7.260267887220744, 47.59685278217552]],
    [ "Poste électrique de Lescar",[-0.43462246443360786, 43.315184724740526]],
    [ "Enedis",[2.124972000828844, 48.36759405562664]],
    [ "Enedis",[2.125733789027948, 48.36986291750482]],
    [ "Enedis",[2.128243421135834, 48.359405091675626]],
    [ "Enedis",[2.110782225212032, 48.34488315371926]],
    [ "Enedis",[2.1199656891634553, 48.362674662268574]],
    [ "Enedis",[2.1208602087331934, 48.357979647793336]],
    [ "Enedis",[2.1488533582710527, 48.403887835927065]],
    [ "Enedis",[2.143513209238492, 48.4022120605513]],
    [ "146526330",[2.527078853232782, 48.95214025246876]],
    [ "Poste électrique de Beautor",[3.326703855078923, 49.653942434216106]],
    [ "Poste électrique de La Marnise",[5.6962851781846675, 45.728083901393475]],
    [ "Poste électrique de Nogentel",[3.406525405862824, 49.02365820755849]],
    [ "Poste électrique de Sisteron",[5.921334898760815, 44.212589798295944]],
    [ "Sous-station de Vezilly",[3.6938942005131032, 49.167062492431135]],
    [ "Enedis",[2.3466740759948967, 48.68408916046439]],
    [ "Enedis",[2.3323381269073504, 48.68152873183797]],
    [ "Enedis",[2.3554345000000003, 48.67128985]],
    [ "Enedis",[2.3608901993288645, 48.68371237406341]],
    [ "Enedis",[2.357554477236031, 48.67782679614981]],
    [ "Enedis",[2.3509483495047196, 48.69061272158645]],
    [ "Enedis",[2.362406397227856, 48.67864821683569]],
    [ "Enedis",[2.354036207887609, 48.678115138361484]],
    [ "Enedis",[2.35490605, 48.67139]],
    [ "Enedis",[2.342173804709486, 48.676120208097146]],
    [ "Enedis",[2.3369914783984296, 48.681123257766004]],
    [ "Enedis",[2.359271667937803, 48.67887695777827]],
    [ "Enedis",[7.5069657028545755, 47.59701713958193]],
    [ "Enedis",[2.3513996477517813, 48.67656122125452]],
    [ "Enedis",[2.3391315749279276, 48.669637245207305]],
    [ "Enedis",[2.3340149288711336, 48.68480599669632]],
    [ "Enedis",[2.3597106951181357, 48.688747981419]],
    [ "Enedis",[2.3555013617542686, 48.67745242825295]],
    [ "Enedis",[2.3307493999960136, 48.687304270451634]],
    [ "Enedis",[2.3370090656115794, 48.688969187577165]],
    [ "Varangéville",[6.333899450861692, 48.63093189483777]],
    [ "Poste électrique de L'Hospitalet",[1.7974344691269795, 42.58839209785626]],
    [ "Poste électrique de Lingostière",[7.187849357042364, 43.71875569127625]],
    [ "Poste électrique des Ayvelles",[4.767382513179311, 49.73667778056339]],
    [ "Poste électrique de Saint-Auban",[6.003060855185399, 44.07428047503851]],
    [ "RTE",[0.3621836351293409, 45.30333538404213]],
    [ "SNCF Réseau",[6.449381404762263, 46.348156354393446]],
    [ "Poste électrique de Thonon",[6.475692649244273, 46.35510263517339]],
    [ "Poste électrique de Publier",[6.510269161154421, 46.38832762456796]],
    [ "Enedis",[2.681502168401573, 45.99334249641453]],
    [ "Enedis",[3.11186761728395, 45.96052486419753]],
    [ "Enedis",[3.1092306921568627, 45.96139237058825]],
    [ "Enedis",[4.809338362962963, 44.9840993037037]],
    [ "Enedis",[4.8239496712643675, 44.9868271635468]],
    [ "Enedis",[4.798139835239568, 44.97469560020608]],
    [ "Enedis",[3.627752504192259, 49.556192278087124]],
    [ "Enedis",[3.632305542866516, 49.56842837299481]],
    [ "Enedis",[3.633055459279041, 49.574006549804395]],
    [ "Enedis",[3.6381126365652583, 49.56260613544562]],
    [ "Enedis",[3.624191897638254, 49.55530607744209]],
    [ "Enedis",[3.654109826645988, 49.573338134619384]],
    [ "Enedis",[3.6444414033495924, 49.56808304203352]],
    [ "Enedis",[3.6428505046804265, 49.57383314094055]],
    [ "Enedis",[3.63753776685861, 49.56637908317783]],
    [ "Enedis",[3.6056165698408784, 49.571430052789964]],
    [ "Enedis",[3.6258088238352917, 49.55397303995298]],
    [ "Enedis",[3.616750386485484, 49.55077647761855]],
    [ "Enedis",[3.629338942323745, 49.57199302145627]],
    [ "Enedis",[3.642865533159659, 49.58237020041306]],
    [ "Enedis",[3.6254243930464782, 49.57740703681562]],
    [ "Enedis",[3.628263599720636, 49.578335670861]],
    [ "Enedis",[3.60749111324081, 49.5697633730308]],
    [ "Enedis",[3.645777099332511, 49.581795694172314]],
    [ "Enedis",[3.6370570815049055, 49.5736926101482]],
    [ "Enedis",[3.629748053002376, 49.576331371182526]],
    [ "Enedis",[3.601753683431376, 49.571668423554605]],
    [ "Enedis",[3.6440417377054475, 49.58740761694283]],
    [ "Enedis",[3.625972903035876, 49.556734020716135]],
    [ "Enedis",[3.5981200782187766, 49.56958938597727]],
    [ "Enedis",[3.611439529486776, 49.54788973471533]],
    [ "Enedis",[3.6145449162347574, 49.56322164950234]],
    [ "Enedis",[3.625319831754457, 49.57010733424336]],
    [ "Enedis",[3.613931932607679, 49.57063572745395]],
    [ "Enedis",[3.6419733679209743, 49.56861529569095]],
    [ "Enedis",[3.603480527292885, 49.55488644639818]],
    [ "Enedis",[3.6197484408460756, 49.552637772046594]],
    [ "Enedis",[3.6290548833613623, 49.57017757870406]],
    [ "Enedis",[3.6274536073943997, 49.56620961141986]],
    [ "Enedis",[3.652315543295392, 49.56811192759273]],
    [ "Enedis",[3.627154729848674, 49.55444820162235]],
    [ "Enedis",[3.6171603984804337, 49.564674059588604]],
    [ "Enedis",[3.620221068274336, 49.553256206521496]],
    [ "Enedis",[3.647894974813972, 49.56871859466776]],
    [ "Enedis",[3.623153026875006, 49.56428414657259]],
    [ "Enedis",[3.6344394125973163, 49.567093962698394]],
    [ "Poste électrique de La Martyre",[-4.193367303657998, 48.44395782659013]],
    [ "Enedis",[6.573581739284512, 46.05162906586885]],
    [ "Enedis",[6.5371503238230835, 46.01192598475188]],
    [ "Enedis",[-0.9947528064746958, 43.74899087826828]],
    [ "Enedis",[-1.007015, 43.74017599999999]],
    [ "Enedis",[-1.005732899999999, 43.74245635]],
    [ "Poste électrique de Saint-Contest",[-0.40808177102478577, 49.217739833646]],
    [ "Enedis",[2.3190089029532985, 48.990204927618215]],
    [ "Enedis",[2.3223135581416594, 48.988476642210244]],
    [ "Poste Électrique de Gouvieux",[2.377313838172783, 49.18299275499787]],
    [ "Enedis",[-1.0326619999999993, 43.704145499999996]],
    [ "Enedis",[-1.045695165948718, 43.69320800492308]],
    [ "Enedis",[-1.0418570321543408, 43.712543669346196]],
    [ "Enedis",[-1.0521017306086577, 43.716170611294224]],
    [ "Enedis",[-1.05743732892562, 43.695797993168036]],
    [ "Enedis",[-1.0343020036795927, 43.7053318399849]],
    [ "Enedis",[-1.03481181573229, 43.70023146805737]],
    [ "Enedis",[-1.0421694309011273, 43.68948333547666]],
    [ "Enedis",[-1.0432975, 43.706694000000006]],
    [ "Enedis",[-1.054916661516604, 43.71949333084709]],
    [ "Enedis",[-1.0483750011213904, 43.69385664199608]],
    [ "Enedis",[-1.0494997309606595, 43.70926715961]],
    [ "Enedis",[-1.0540514237685688, 43.71683372999739]],
    [ "Enedis",[-1.0624305818618616, 43.69570041045045]],
    [ "Enedis",[-1.042846028207215, 43.71448018958504]],
    [ "Enedis",[-1.0392353612479472, 43.70127820361249]],
    [ "Enedis",[-1.0394668794051078, 43.71412819657291]],
    [ "Enedis",[-1.0451708211708095, 43.71464852953755]],
    [ "Enedis",[-1.0365130239043825, 43.70134033997344]],
    [ "Enedis",[-1.04265077726038, 43.706413874660456]],
    [ "Enedis",[-1.053274875131164, 43.719108356768096]],
    [ "Enedis",[-1.0471013373916245, 43.71540575391994]],
    [ "Enedis",[-1.0563135, 43.718057]],
    [ "Enedis",[-1.0406726987600292, 43.704096536105034]],
    [ "Enedis",[-1.048796020028612, 43.711831345064375]],
    [ "Enedis",[-1.0388720358876928, 43.705431831750055]],
    [ "Enedis",[-1.0449964605838145, 43.68362087467225]],
    [ "Enedis",[-1.0373789236897284, 43.690431707756815]],
    [ "Enedis",[-1.0478673083682848, 43.69882435130817]],
    [ "Enedis",[-1.0419411375661374, 43.707382804232815]],
    [ "Enedis",[-1.0575702787878785, 43.715962401212124]],
    [ "Enedis",[-1.0466733259069574, 43.70955483755868]],
    [ "Enedis",[-1.0471449740804277, 43.70950351915379]],
    [ "Ferrabouc Dax",[-1.0704986552437716, 43.70001108294016]],
    [ "Enedis",[-1.0402383664414416, 43.694008601689184]],
    [ "Enedis",[-1.034018, 43.7027405]],
    [ "Enedis",[-1.0449689540182836, 43.69050658752899]],
    [ "Enedis",[-1.0603881281233392, 43.71531123817118]],
    [ "Enedis",[-1.039914, 43.70907316666667]],
    [ "Enedis",[-1.0506501184079602, 43.71758602686565]],
    [ "Enedis",[-1.0350454724279836, 43.70372992263374]],
    [ "Enedis",[-1.0546757845363595, 43.713124546836184]],
    [ "SNCF Réseau",[6.2439089294239745, 48.64632969058413]],
    [ "Enedis",[2.762585455294602, 45.77011618005768]],
    [ "RTE",[-1.7586376053656814, 43.361316506367054]],
    [ "Enedis",[2.7056961886608013, 45.791130409579665]],
    [ "Enedis",[2.8011305, 45.723898]],
    [ "RTE",[4.367056126159423, 44.650757364900215]],
    [ "Enedis",[0.16958800000000002, 48.045511000000005]],
    [ "Edison Pavy",[0.17655301528342057, 48.02405971928807]],
    [ "La Gabelle",[0.17659799999999995, 48.03917199999999]],
    [ "Panorama",[0.1620178007054674, 48.03548195767196]],
    [ "Palluau",[0.1701760751173699, 48.046267821596246]],
    [ "Enedis",[0.16420012433793546, 48.03319128557784]],
    [ "Enedis",[0.17798490417376492, 48.0356888339012]],
    [ "Enedis",[0.156549594594595, 48.03522284684685]],
    [ "Enedis",[0.17142002979737775, 48.042495340484706]],
    [ "Enedis",[0.1597465, 48.0339605]],
    [ "Enedis",[0.15788378936910052, 48.032593668156984]],
    [ "Enedis",[0.17171663997591824, 48.040141770419424]],
    [ "Billot",[0.17599849999999997, 48.045356]],
    [ "Frères Lumière",[0.1740853840126954, 48.02554029187741]],
    [ "Enedis",[0.15432187558386426, 48.03752451515925]],
    [ "Pulutenia",[-1.6422946539432295, 43.39732292014454]],
    [ "Enedis",[2.8163709433788715, 45.839548768538705]],
    [ "Enedis",[-1.367579760598196, 43.36490002152755]],
    [ "Poste Electrique Saint Jean le Vieux",[-1.2189146103269186, 43.17828907663759]],
    [ "Enedis",[-1.4814702500000003, 49.034375749999995]],
    [ "Enedis",[-1.4816064999999992, 49.033206500000006]],
    [ "Enedis",[6.393824333362824, 46.11038141670018]],
    [ "Sorea",[6.398561994676357, 45.25434876391015]],
    [ "RTE",[-1.5351118969614554, 43.45869078908813]],
    [ "Enedis",[4.648013678466077, 45.03629300884956]],
    [ "Enedis",[4.622350482634732, 45.04679848804613]],
    [ "SNCF Réseau",[-1.4695074810919158, 43.4843174995289]],
    [ "Enedis",[4.617484078743962, 45.105633382608694]],
    [ "RTE",[-1.0183277181588615, 43.46736190537546]],
    [ "Enedis",[2.7369034210526304, 45.603173918128654]],
    [ "Enedis",[2.76090089352595, 45.59362552791154]],
    [ "Enedis",[2.7470549999999996, 45.5956245]],
    [ "RTE",[-0.9055963583720621, 43.52393000403273]],
    [ "EDF",[-0.8408840914261728, 43.50994669928525]],
    [ "RTE",[-0.7799451275806453, 43.50170577908586]],
    [ "Poste électrique de Neuves Maisons",[6.112514483612708, 48.61077327094235]],
    [ "Poste électrique de Tore Supra",[5.76645776725168, 43.701685276492036]],
    [ "Enedis",[2.7768701543624164, 45.53478712975391]],
    [ "Enedis",[5.247433654947056, 47.57893810728261]],
    [ "Enedis",[5.206488272801586, 47.57222600408723]],
    [ "Poste électrique de Hériménil",[6.488954879949914, 48.5706903915549]],
    [ "Enedis",[6.869382208163264, 48.3410644952381]],
    [ "147119587",[6.875385879763042, 48.36887374523154]],
    [ "RTE",[-0.6278435206001131, 43.41249705287214]],
    [ "Enedis",[3.604340710735332, 45.89904666384833]],
    [ "Enedis",[3.596192200438116, 45.88921002555677]],
    [ "Enedis",[3.5977411931914887, 45.893704520283684]],
    [ "Vieux-Moulins",[6.375739822561589, 45.270907661882795]],
    [ "Poste électrique de La Coche",[6.509932326320008, 45.49640083366607]],
    [ "Poste électrique de La Praz Saint-André",[6.583583496069717, 45.20533397391628]],
    [ "La Saussaz II",[6.476512546635631, 45.210031351039724]],
    [ "Poste électrique de Vaujany",[6.050703273668761, 45.14552043970896]],
    [ "Poste électrique de Chatillon-sur-Seine",[4.559593974416092, 47.85221531496009]],
    [ "Poste électrique de Couesque",[2.5836502323684973, 44.69534542314905]],
    [ "Poste électrique de Couesque",[2.581852499422908, 44.69535192682858]],
    [ "Poste électrique de Creney-prés-Troyes 225kV",[4.139655469352024, 48.34507010979568]],
    [ "Poste électrique de Favary",[5.6158045556460925, 43.47095902675858]],
    [ "Poste électrique de Godin",[2.2276914505952736, 44.540788700343576]],
    [ "Poste électrique de La Doberie",[-2.4543519520532198, 48.50672665672446]],
    [ "Poste électrique de Lardit",[2.583532582697814, 44.66190749842132]],
    [ "EDF",[5.022417208405749, 43.35870247069239]],
    [ "Poste électrique de Port-de-Bouc",[4.991923555295711, 43.41526605414187]],
    [ "Poste électrique de Rospez",[-3.4030584430481308, 48.72419494520753]],
    [ "Poste électrique de Saint-Chamas",[5.071219616761833, 43.52762470069244]],
    [ "Poste électrique de Beaucouzé",[-0.6329312231961314, 47.46260021050159]],
    [ "Poste électrique de Roumoules",[6.157583866406852, 43.78899202521716]],
    [ "Électricité de France",[5.205099269358808, 43.71566704176131]],
    [ "Enedis",[6.872212826291171, 48.3088536700305]],
    [ "Nompatelize",[6.851367480255669, 48.324669198514734]],
    [ "Poste électrique de Barquet",[0.8159227750160668, 49.03308249388875]],
    [ "Poste électrique de Boscherville",[0.8827162218072933, 49.2814538820596]],
    [ "Enedis",[6.827898959723335, 48.323977824934815]],
    [ "Enedis",[6.841422369676085, 48.40660882965771]],
    [ "Enedis",[6.8457691200563575, 48.40475026144537]],
    [ "Enedis",[6.849015577697202, 48.4033278195471]],
    [ "Enedis",[6.8510522499999995, 48.40983745]],
    [ "Enedis",[2.071767997070604, 48.88330192872829]],
    [ "Enedis",[2.073794833542843, 48.88105613523229]],
    [ "Enedis",[2.073267813805977, 48.87468784792888]],
    [ "Enedis",[2.0838247335664923, 48.87889784587857]],
    [ "Enedis",[2.0789429775175523, 48.88199225087925]],
    [ "Enedis",[3.62983225, 45.905305250000005]],
    [ "RTE",[-0.8692922339536476, 43.234516681000294]],
    [ "RTE",[-0.6347457869756039, 43.207645843527345]],
    [ "Enedis",[2.792261933865072, 50.32282824725822]],
    [ "Enedis",[2.7840943268983263, 50.323159464607464]],
    [ "Enedis",[2.787118650244119, 50.32590927341324]],
    [ "Enedis",[2.7818874283305233, 50.325916742551996]],
    [ "Électricité de France",[55.49264819999999, -20.891195449999998]],
    [ "Enedis",[3.7705106278185267, 49.55571017214525]],
    [ "Enedis",[3.7658436662786183, 49.5520595064028]],
    [ "Enedis",[3.5119905, 47.450987999999995]],
    [ "Enedis",[3.5213291664364186, 47.46308351358463]],
    [ "Enedis",[3.5168459323542502, 47.45775729773031]],
    [ "Enedis",[3.5209201173549984, 47.45860874114195]],
    [ "Enedis",[3.5240396062053607, 47.46035246173011]],
    [ "Enedis",[3.514680495775134, 47.471741331436455]],
    [ "Enedis",[3.5169002688642435, 47.46059721025365]],
    [ "Enedis",[3.513561007920794, 47.44843829966996]],
    [ "Enedis",[3.517669333333333, 47.471112752136754]],
    [ "Enedis",[3.520674287608596, 47.45568432967536]],
    [ "Enedis",[3.5265371379419945, 47.463871991259445]],
    [ "Enedis",[3.5095552491909383, 47.45716803236245]],
    [ "Enedis",[3.5197303382263, 47.47374351009174]],
    [ "Enedis",[3.514298336973479, 47.45025817524702]],
    [ "Enedis",[3.53219718875502, 47.4540239558233]],
    [ "Enedis",[3.515313883683255, 47.45903092163361]],
    [ "Enedis",[-1.0481403999999999, 43.80675720000001]],
    [ "Poste électrique de Bacalan",[-0.5458377627311363, 44.88230499013461]],
    [ "Poste électrique de Barjac",[4.343398426691356, 44.31476674228249]],
    [ "Poste électrique de Belle-Épine",[-1.753748468227127, 48.12830164387268]],
    [ "Poste électrique de Cordéac",[5.857835262149573, 44.833708360959974]],
    [ "Poste électrique de Damery",[3.8711134187530227, 49.07579236709739]],
    [ "Poste électrique de Landerneau",[-4.236608845826578, 48.46053471691607]],
    [ "Poste électrique du Sautet",[5.908594335878543, 44.816313952803114]],
    [ "Poste électrique de Marolles",[4.614400450631383, 48.71932924334569]],
    [ "Poste électrique de Pied-de-Borne",[3.985920938243218, 44.47896630272602]],
    [ "Poste électrique de Pleyber-Christ",[-3.8719017270258593, 48.526410239982255]],
    [ "Poste électrique de Pont-Escoffier",[6.0695688719748295, 45.00305171282851]],
    [ "Poste électrique de Pont-la-Ville",[4.897163775163182, 48.0786161124769]],
    [ "Poste électrique de Recouvrance",[-1.2349036013693344, 47.07678480630553]],
    [ "Poste électrique de Recy",[4.34056371830477, 48.994788659745325]],
    [ "Poste électrique de Villemandeur",[2.7162997028118703, 47.99402813872716]],
    [ "Poste électrique de Les Ansereuilles",[2.937878343010478, 50.553582019196035]],
    [ "Poste électrique de Maubeuge",[3.92938339494316, 50.26373897013666]],
    [ "Poste électrique de Montois",[6.011474144112454, 49.21296969164918]],
    [ "Poste électrique de Petitmenin",[3.1466239347359775, 50.742309910140314]],
    [ "Poste électrique de Stenay",[5.200232289845745, 49.50971173442071]],
    [ "Poste électrique de Vandières",[6.022081281631949, 48.95083450860078]],
    [ "Poste électrique de Void",[5.6138112011432515, 48.70910858948901]],
    [ "Poste électrique de Millery",[6.119498897507908, 48.80693941518091]],
    [ "Poste électrique de Cattenom (Station de pompage)",[6.234506143730017, 49.39067890415007]],
    [ "RTE",[-0.6246599329563244, 43.1088284791579]],
    [ "Enedis",[2.243195679898051, 49.14477964022066]],
    [ "Enedis",[2.216103885640291, 49.129600792563025]],
    [ "Enedis",[2.242953354826233, 49.146390957064796]],
    [ "Enedis",[2.2643312433512683, 49.1296817116577]],
    [ "Enedis",[2.3748187214976975, 49.12474091543169]],
    [ "Enedis",[2.384681799999999, 49.122816750000005]],
    [ "Enedis",[2.3680004866068214, 49.13045612249766]],
    [ "Enedis",[2.4180832707618802, 49.120582369453906]],
    [ "Enedis",[2.421528444246607, 49.120205235619416]],
    [ "Enedis",[2.3681154504138204, 49.12602387379027]],
    [ "Enedis",[2.3765670019498564, 49.12666459368247]],
    [ "Enedis",[2.373252645654846, 49.134043042660274]],
    [ "Enedis",[2.3675964381754655, 49.11906607676381]],
    [ "Seugy",[2.401363632098765, 49.119538866666666]],
    [ "RTE",[-0.38609164234278476, 43.27347811350085]],
    [ "Enedis",[2.997567658395368, 42.60929503722085]],
    [ "Enedis",[3.003557682487274, 42.61026056802862]],
    [ "Enedis",[3.00003557776627, 42.610904654928355]],
    [ "Enedis",[2.9974294946236557, 42.60453466666667]],
    [ "Enedis",[2.999252720221607, 42.61179059372114]],
    [ "Enedis",[3.004604308488612, 42.6064165931677]],
    [ "Enedis",[3.001580998496887, 42.60818739846826]],
    [ "Enedis",[2.431700718762973, 49.12315555673652]],
    [ "Enedis",[2.4228589670724223, 49.129050824681144]],
    [ "Enedis",[2.4324694884872264, 49.12574424787846]],
    [ "Enedis",[2.430159778986463, 49.12612198960016]],
    [ "Enedis",[2.4197823812543593, 49.13211697702666]],
    [ "Enedis",[2.999569750227067, 42.60561348433243]],
    [ "Enedis",[2.426695427967204, 49.12391031227631]],
    [ "Enedis",[2.4277545220172336, 49.1232257627141]],
    [ "Enedis",[2.4247983129134885, 49.120274371706664]],
    [ "Enedis",[2.4295211008226576, 49.12735714345503]],
    [ "Enedis",[2.438138251714132, 49.12149910878998]],
    [ "Enedis",[2.3397122219668924, 48.985628198055714]],
    [ "Enedis",[6.268193883392228, 48.656259762073034]],
    [ "Enedis",[6.270532062755249, 48.65773423747483]],
    [ "Enedis",[6.2603687, 48.658636200000004]],
    [ "Enedis",[6.268671952380953, 48.65968705238096]],
    [ "Enedis",[6.267651833207737, 48.65276949786486]],
    [ "Enedis",[6.254164149880094, 48.661827027178255]],
    [ "Enedis",[6.241383300000002, 48.66277644999999]],
    [ "Enedis",[6.304883699822382, 48.666051568383665]],
    [ "Enedis",[6.304343200000002, 48.668380400000004]],
    [ "Enedis",[6.300627475392669, 48.66679561692845]],
    [ "Poste électrique d’Allinges",[6.478891670909336, 46.33032515327252]],
    [ "Poste électrique de Cléon",[1.040153588708101, 49.31651181248252]],
    [ "Poste électrique de Cruseilles",[6.097930236214062, 46.04071689758276]],
    [ "Poste électrique de Douvrin",[2.8463443447539385, 50.50919213448017]],
    [ "Poste électrique d’Echinghen",[1.6397663199787607, 50.696625606353614]],
    [ "Poste électrique de Grand-Coeur",[6.488385705350952, 45.51582329971601]],
    [ "Poste électrique de Guarbecque",[2.504419717472785, 50.61900409808696]],
    [ "Poste électrique des Brévières",[6.924595992288183, 45.504268490356466]],
    [ "Poste électrique de Patis",[2.1005672699179865, 49.39201805131065]],
    [ "Poste électrique de Pont-sur-Sambre",[3.8637889370753937, 50.22939578779821]],
    [ "Poste électrique de Pressy",[6.560792740153918, 46.07978760796808]],
    [ "Poste électrique de Saint-Calais",[0.7289003087145107, 47.91788589005963]],
    [ "Poste électrique de Serrières",[5.841917292953691, 45.887375887091075]],
    [ "Poste source de Sorrus",[1.7217730643994613, 50.45568955535898]],
    [ "Poste électrique de Valescourt",[2.451512230418535, 49.48287796020777]],
    [ "Poste électrique de Yainville",[0.822387039642597, 49.45951932126892]],
    [ "Poste électrique de Thiot",[-0.04367459671755768, 48.758278586284064]],
    [ "Poste électrique de Saint-Omer",[2.2465851187749863, 50.74168824798976]],
    [ "Le Hourat",[-0.4291161505305294, 42.98433169112527]],
    [ "Poste électrique de Longuenesse",[2.2284010621001142, 50.74466647332366]],
    [ "Enedis",[0.8969560867579907, 47.78689229680365]],
    [ "Enedis",[2.0403389703435866, 48.85234816752434]],
    [ "Enedis",[2.016603234260087, 48.85476577309701]],
    [ "Enedis",[3.1290422455422537, 47.36969535656918]],
    [ "Enedis",[3.1137485381046144, 47.37307552409131]],
    [ "Enedis",[3.1127730773111986, 47.37252647688802]],
    [ "Enedis",[3.1251862499999996, 47.371998000000005]],
    [ "Enedis",[3.1118995220412597, 47.37392626710098]],
    [ "Enedis",[3.111490041802389, 47.36966569896852]],
    [ "Enedis",[3.117048249999999, 47.370370750000006]],
    [ "Enedis",[3.1262495543227007, 47.37323971890892]],
    [ "Enedis",[3.12847723573412, 47.37144131152021]],
    [ "RTE",[-0.8100989279402439, 43.67092908582393]],
    [ "RTE",[-1.0215759241526352, 43.639886809702254]],
    [ "poste_4672",[2.746151134398782, 49.534908235616435]],
    [ "poste_4721",[2.744548982141843, 49.53542465103574]],
    [ "Poste électrique de Dax",[-1.039005770987788, 43.726648717337746]],
    [ "Enedis",[2.139446265044062, 49.219421877857044]],
    [ "Enedis",[2.1268264999999995, 49.2346452]],
    [ "Enedis",[2.1275434280706507, 49.24552495274426]],
    [ "Enedis",[2.755477899999999, 49.09575409999999]],
    [ "Enedis",[2.211490809377946, 44.30360749930494]],
    [ "Enedis",[6.43178830989715, 48.21931129185634]],
    [ "Enedis",[6.45292265, 48.2159848]],
    [ "Enedis",[5.116412821000342, 47.51944962329565]],
    [ "Enedis",[5.112229217252396, 47.524369162939294]],
    [ "Enedis",[5.110978364378109, 47.517169207064676]],
    [ "Enedis",[5.110236166666666, 47.522458]],
    [ "Enedis",[5.104788967777777, 47.52488084388889]],
    [ "Enedis",[5.112571783370342, 47.516586547742406]],
    [ "Enedis",[5.104928279243623, 47.51616740337563]],
    [ "Enedis",[5.138935, 47.534083]],
    [ "Enedis",[5.105593516389309, 47.522037790384935]],
    [ "Enedis",[5.102485250000001, 47.514431599999995]],
    [ "Enedis",[5.1206945, 47.51827599999999]],
    [ "Enedis",[5.107136865532549, 47.51741130642185]],
    [ "Enedis",[5.1149731, 47.527783899999996]],
    [ "Enedis",[5.11231337485172, 47.5144423262159]],
    [ "Enedis",[5.115402749999999, 47.522509750000005]],
    [ "Enedis",[5.109140016739446, 47.528096825691414]],
    [ "Enedis",[5.15300345, 47.52725545]],
    [ "Enedis",[5.10875726468647, 47.520370170957094]],
    [ "Enedis",[5.087378999999999, 47.500987]],
    [ "Enedis",[5.117992, 47.5162165]],
    [ "Enedis",[5.080146768263473, 47.55111704187181]],
    [ "Enedis",[5.1064546938509645, 47.527712999560585]],
    [ "Carnot",[5.123532790849673, 47.52082008496732]],
    [ "Enedis",[5.1060642000000005, 47.51410194999999]],
    [ "Enedis",[2.050632432748538, 48.308818953216374]],
    [ "Enedis",[2.9682158208317575, 49.34504051528904]],
    [ "Enedis",[2.983224404912162, 49.35374248676771]],
    [ "Enedis",[2.970031328264499, 49.349004657045434]],
    [ "RTE",[-0.26213925455827414, 43.70701586675907]],
    [ "Poste électrique de Beauchamps",[1.5248566356045454, 50.00674597239885]],
    [ "Poste électrique de Mouissonnes",[5.022880448927661, 43.921518657247155]],
    [ "Poste électrique de Roye",[2.8004278491380115, 49.6941781782086]],
    [ "Poste électrique de Vins",[6.112555858702218, 43.43588456757078]],
    [ "Poste électrique de Bollène",[4.735304465410888, 44.30342096243049]],
    [ "RTE",[-0.5684387813818215, 43.6549873298895]],
    [ "RTE",[-0.526046869883171, 43.7640567075607]],
    [ "Enedis",[2.381856654361786, 48.685254965720006]],
    [ "Enedis",[2.38413985, 48.68658]],
    [ "Enedis",[2.3854171500000003, 48.68689655]],
    [ "Enedis",[2.3837865222921164, 48.687630735796226]],
    [ "Enedis",[2.3772090130003796, 48.686327879499814]],
    [ "Enedis",[2.3800382973382734, 48.68634362032918]],
    [ "Enedis",[2.375172799087946, 48.68497846674096]],
    [ "Enedis",[2.3831717500000003, 48.683853400000004]],
    [ "Enedis",[2.384252041310054, 48.68389878917312]],
    [ "Enedis",[2.380693249305798, 48.685665306450026]],
    [ "Enedis",[2.0919495973470257, 48.30707274154899]],
    [ "Enedis",[2.0528343, 48.322365950000005]],
    [ "Blanc caillou",[6.395065684004171, 48.25999301851234]],
    [ "Enedis",[3.6661085000000004, 49.473502]],
    [ "Enedis",[3.6682760000000005, 49.47472899999999]],
    [ "Enedis",[3.665932483899041, 49.46878020626631]],
    [ "Enedis",[2.5841452737528647, 49.10368614031376]],
    [ "Enedis",[2.584527424836601, 49.10065275163397]],
    [ "Enedis",[4.15998310179169, 47.77747814156331]],
    [ "Enedis",[1.823031497316171, 49.854081811947616]],
    [ "Enedis",[2.0408031632999943, 49.760901165756366]],
    [ "Enedis",[2.0413207559102906, 49.75810985519152]],
    [ "Enedis",[2.417671673264888, 48.839451434130865]],
    [ "Enedis",[6.093738, 48.565544]],
    [ "Enedis",[6.100863439056357, 48.566087184359986]],
    [ "Enedis",[6.096655866448325, 48.56255138173218]],
    [ "Enedis",[6.096164893693694, 48.56615386486486]],
    [ "Enedis",[6.102820579710145, 48.565826666666666]],
    [ "Enedis",[6.0956521579487175, 48.5642976851282]],
    [ "Enedis",[6.061445480186481, 48.5833178974359]],
    [ "Enedis",[6.063820405333334, 48.58218439066666]],
    [ "Enedis",[1.7892513999999993, 49.781994049999994]],
    [ "Enedis",[1.7941937281470413, 49.78248677221244]],
    [ "Enedis",[6.099412083142987, 48.585433148469626]],
    [ "Enedis",[6.088407, 48.59332449999999]],
    [ "Enedis",[6.086538203994674, 48.586303560497115]],
    [ "Enedis",[6.097884400760995, 48.58789677656845]],
    [ "Enedis",[6.312793908964543, 48.69807434849966]],
    [ "Enedis",[6.312728048848392, 48.69227398025815]],
    [ "Enedis",[6.0276327, 48.5866381]],
    [ "Enedis",[6.0290473976890215, 48.5886914440844]],
    [ "Enedis",[-1.4463266666666665, 43.559775]],
    [ "Enedis",[-1.4713678217522659, 43.57175901409869]],
    [ "Enedis",[-1.4403562176069435, 43.56714739512296]],
    [ "Enedis",[-1.463836916274202, 43.568909199372044]],
    [ "Enedis",[-1.4411155000000002, 43.564636500000006]],
    [ "Enedis",[-1.4510243206044113, 43.57254664503947]],
    [ "Enedis",[-1.451428665485537, 43.561019253679035]],
    [ "Enedis",[-1.4557526075044638, 43.56729014122165]],
    [ "Enedis",[-1.4683423563282851, 43.572840174799026]],
    [ "Enedis",[-1.4584915, 43.570503]],
    [ "Enedis",[-1.451403, 43.56770900000001]],
    [ "Enedis",[-1.4492079999999998, 43.570266999999994]],
    [ "Enedis",[-1.4440085, 43.5561185]],
    [ "Enedis",[-1.450744145454545, 43.5697111939394]],
    [ "Enedis",[-1.449202, 43.57488425]],
    [ "Enedis",[-1.4604373746130028, 43.57093532094944]],
    [ "Enedis",[-1.4354274999999999, 43.5694515]],
    [ "Enedis",[-1.44662843720491, 43.563967684608116]],
    [ "Enedis",[-1.434142166666667, 43.56520216666667]],
    [ "Enedis",[-1.4374721571317046, 43.566352670346824]],
    [ "Enedis",[-1.4539693177147555, 43.563204675709]],
    [ "Enedis",[-1.444421470819029, 43.5666166540788]],
    [ "Enedis",[-1.463805209523809, 43.570566771428574]],
    [ "Enedis",[-1.4618232132787632, 43.56848805457026]],
    [ "Enedis",[-1.4655712268980474, 43.56882694345625]],
    [ "Poste électrique de Trie-Château",[1.8275674816136689, 49.28515594491188]],
    [ "Enedis",[2.833719052830189, 50.3007788427673]],
    [ "Enedis",[2.835109250000001, 50.3041375]],
    [ "Enedis",[2.8377112381238736, 50.300955442373215]],
    [ "Enedis",[2.840611, 50.319137000000005]],
    [ "Enedis",[2.8383040293890183, 50.30465563959784]],
    [ "Enedis",[2.5726577844866068, 44.37978442793898]],
    [ "Les Thermes",[2.5748689725950786, 44.37086128243848]],
    [ "Enedis",[2.5695576510327838, 44.38184513856358]],
    [ "Enedis",[2.5753270314840284, 44.37759441311325]],
    [ "CIL 6 - Centre social",[2.5947648246364423, 44.36672714666195]],
    [ "CIL 1 Saint Paul",[2.5923458375049466, 44.36347676431207]],
    [ "Les Vignes",[2.603500493055091, 44.365496992554256]],
    [ "Enedis",[2.568332898666667, 44.375835090666655]],
    [ "Bergeronnettes",[2.5734348518300987, 44.37821284643273]],
    [ "P Oeillets",[2.5933497784621204, 44.36231826531098]],
    [ "Enedis",[2.5773882625482636, 44.37255531145431]],
    [ "Enedis",[2.569099717283951, 44.37998020493827]],
    [ "Enedis",[2.5847281856228457, 44.36429004830134]],
    [ "Enedis",[2.606953426160337, 44.36008159915612]],
    [ "SAN PAU",[2.6046019032310443, 44.36243602792662]],
    [ "Enedis",[2.839399851123326, 50.29499985025552]],
    [ "Enedis",[2.837696887640449, 50.29611271161048]],
    [ "Enedis",[2.85071596891747, 50.292248389067524]],
    [ "Enedis",[2.8511712415169654, 50.274668772455094]],
    [ "Enedis",[2.8277628000000012, 50.29751520000001]],
    [ "Enedis",[2.8545122499999995, 50.2915045]],
    [ "Enedis",[2.8538684407328665, 50.273449689187224]],
    [ "RTE",[-0.03295501533493367, 43.10167875257404]],
    [ "Enedis",[3.334233176183991, 48.63699599776063]],
    [ "RTE",[-0.26425181040248075, 43.882051201276205]],
    [ "Enedis",[2.718573465280137, 48.34064029249829]],
    [ "Enedis",[2.716169302464896, 48.34874051858582]],
    [ "Enedis",[2.7244541824900703, 48.32571699454105]],
    [ "Enedis",[2.700884501325542, 48.34486678334284]],
    [ "Enedis",[2.6954271826560285, 48.34312786425539]],
    [ "Celium",[2.685162041727553, 48.332018428214816]],
    [ "Enedis",[2.7022426815069807, 48.340093513840394]],
    [ "Enedis",[2.712010603850515, 48.33907487699701]],
    [ "Enedis",[2.7087474145143164, 48.341339200006445]],
    [ "Enedis",[6.223573721668024, 44.66225490766404]],
    [ "Enedis",[3.4936775000000004, 47.513248000000004]],
    [ "RTE",[1.0276587062695377, 44.76446918267216]],
    [ "Enedis",[-1.39798325, 43.7808711]],
    [ "Enedis",[-1.4062879541666673, 43.78693962083334]],
    [ "Enedis",[-1.4100623103721293, 43.78564084085511]],
    [ "Enedis",[-1.4038586282015728, 43.791235770319005]],
    [ "Enedis",[-1.4034453075601374, 43.79439722379725]],
    [ "Enedis",[-1.4062817479047385, 43.788441645008724]],
    [ "Enedis",[-1.3930533248175188, 43.794024970802916]],
    [ "Enedis",[-1.4106684664811537, 43.78808421713467]],
    [ "Enedis",[-1.3955208283828378, 43.78864033573357]],
    [ "Enedis",[-1.3979919679132042, 43.789040604108955]],
    [ "Enedis",[-1.3978819999999998, 43.793431000000005]],
    [ "Enedis",[-1.39673095, 43.78319405]],
    [ "Enedis",[-1.3926733336922383, 43.79254449771198]],
    [ "EDF",[6.059843649860888, 44.44724510814566]],
    [ "RTE",[6.628125459852483, 44.379545801851386]],
    [ "Enedis",[6.324878122778629, 44.37591594413548]],
    [ "Embrun",[6.505674838514018, 44.56457935490789]],
    [ "Poste électrique de Briançon",[6.616301742852702, 44.86607042008022]],
    [ "RTE",[6.615758984780115, 44.65783101058612]],
    [ "Enedis",[3.4150730697954264, 47.479897052948246]],
    [ "RTE",[6.68614826122468, 44.585680495609886]],
    [ "Poste électrique de Serre-Barbin",[6.544400784621132, 44.95581307555248]],
    [ "Enedis",[-1.0583063398692811, 43.687491527233114]],
    [ "Enedis",[-1.0650319999999995, 43.685562000000004]],
    [ "Enedis",[-1.0629610614389653, 43.686328261924004]],
    [ "Enedis",[-1.10855525, 43.669154500000005]],
    [ "Enedis",[-1.1079637788778878, 43.66644245591702]],
    [ "Enedis",[-1.0924557000000001, 43.675308449999996]],
    [ "Poste Électrique de Percy",[-0.0753423556244016, 49.06120615444324]],
    [ "Enedis",[6.213751898745056, 48.71349921076155]],
    [ "Enedis",[6.217290724483775, 48.714668578171086]],
    [ "Enedis",[6.211871000000001, 48.71340400000001]],
    [ "Enedis",[6.210818343704246, 48.71046386151781]],
    [ "Enedis",[6.216341603286385, 48.71635362558685]],
    [ "Poste électrique de L'Argentière la Bessée",[6.559728103536917, 44.791082197191024]],
    [ "RTE",[5.783614140034579, 44.91388559199213]],
    [ "Enedis",[6.1671824689655175, 48.698740280459766]],
    [ "RTE",[1.4384404052146202, 48.890722216682505]],
    [ "Enedis",[6.162659973532308, 48.70077110079791]],
    [ "RTE",[-0.031652480176534374, 43.961188725922746]],
    [ "RTE",[-0.3059387082967101, 43.59284538789463]],
    [ "RTE",[-0.9497182234869216, 46.321898756825135]],
    [ "Sous-station RFF de Mouthiers",[0.12279442271726967, 45.5524410548925]],
    [ "RTE",[-0.3211282314876944, 43.46051202735254]],
    [ "Nancruz",[6.386003638892671, 46.130252973791684]],
    [ "Enedis",[6.390727799999999, 46.12654110000001]],
    [ "Senoche",[6.392789115550876, 46.134668902573026]],
    [ "RTE",[-0.38079047496647184, 43.325309548155936]],
    [ "RTE",[-0.4915559837608318, 43.88454654780207]],
    [ "Chardons Bleus",[6.310130199999999, 46.0902422]],
    [ "Cimetière",[6.303655599999999, 46.091499899999995]],
    [ "Riret",[6.297942883245384, 46.08851800041344]],
    [ "Enedis",[6.274887100877193, 46.083785635964915]],
    [ "Bathia",[6.312031799999999, 46.08704524999999]],
    [ "RTE",[-0.12142717775440244, 47.11494463174038]],
    [ "Enedis",[3.1267560287548855, 45.66930879676158]],
    [ "Enedis",[3.127268758032128, 45.672462921686744]],
    [ "Enedis",[3.132257499999999, 45.672695]],
    [ "Enedis",[3.127141766304348, 45.67406496014492]],
    [ "Enedis",[3.1196495000000004, 45.6736385]],
    [ "Enedis",[3.1338781846073833, 45.67290014404576]],
    [ "Enedis",[3.130167828660437, 45.67046052024922]],
    [ "Enedis",[3.124238331560283, 45.66857653014184]],
    [ "Enedis",[3.120922714285714, 45.669819523809515]],
    [ "RTE",[-0.9080749837232464, 44.03639893566854]],
    [ "RTE",[-0.9055434092911003, 44.03129363416089]],
    [ "Enedis",[3.1067185821163217, 45.66993872431463]],
    [ "Enedis",[3.1080759999999996, 45.671482499999996]],
    [ "Enedis",[3.1172229744996773, 45.667387130837106]],
    [ "Enedis",[3.111669638629283, 45.669893043613705]],
    [ "Enedis",[3.11737060899067, 45.670562044105175]],
    [ "Enedis",[3.10378793684566, 45.66701477575142]],
    [ "Enedis",[3.1135345, 45.672740000000005]],
    [ "Enedis",[3.1051073689966713, 45.669164043271515]],
    [ "Enedis",[-1.5773911290883615, 48.84980746453986]],
    [ "Enedis",[-1.5742723447816545, 48.84761752197719]],
    [ "Enedis",[-1.5750872913582683, 48.84970870248527]],
    [ "Enedis",[-1.5772749120367162, 48.854921853080285]],
    [ "Enedis",[-1.5834736658944788, 48.84852168158544]],
    [ "Enedis",[-1.5874559970479694, 48.84390049884884]],
    [ "Enedis",[-1.5742028930628689, 48.85311424823599]],
    [ "Enedis",[-1.5715747497687098, 48.84728635436939]],
    [ "RTE",[-1.0810407884127278, 44.34858434757257]],
    [ "Enedis",[-1.5743123027766073, 48.85102053201719]],
    [ "Enedis",[-1.5760524391655688, 48.84569399042549]],
    [ "Enedis",[-1.5671701052927502, 48.84938564847079]],
    [ "Enedis",[3.129047277096114, 45.708931959100205]],
    [ "Enedis",[3.130043104066346, 45.706229070626016]],
    [ "Enedis",[3.1268233834296724, 45.69910232177265]],
    [ "Enedis",[3.139661403019744, 45.70201019279907]],
    [ "Enedis",[3.132627865671642, 45.7041461880597]],
    [ "Enedis",[3.136882646684095, 45.70978000991616]],
    [ "Enedis",[3.1555689282051276, 45.71459021965813]],
    [ "Enedis",[3.12810024196406, 45.70968654087574]],
    [ "Enedis",[3.136679205753597, 45.702125053158234]],
    [ "Enedis",[3.1213456604598675, 45.69875589772887]],
    [ "Enedis",[3.1252242161984554, 45.708537886807946]],
    [ "Enedis",[3.1493701796567475, 45.724147732010714]],
    [ "Enedis",[3.154576842941772, 45.71505468790638]],
    [ "Enedis",[3.1364187976366322, 45.70369629295913]],
    [ "Enedis",[3.118583616161616, 45.70011990572391]],
    [ "Enedis",[3.130965793346552, 45.70147146970699]],
    [ "Gergovie",[3.126132278657121, 45.71254390823013]],
    [ "Enedis",[3.156069214067279, 45.7128005880297]],
    [ "Enedis",[3.1226231458555676, 45.709822178939866]],
    [ "Enedis",[3.1254612047742314, 45.71397136870866]],
    [ "SNCF Réseau",[-0.9207432640368576, 44.20263829697373]],
    [ "Poste électrique de Labouheyre",[-0.9213855771731321, 44.201848735494714]],
    [ "Poste électrique de Podensac",[-0.34397457524930997, 44.64313259769359]],
    [ "Poste électrique de Cérons",[-0.34739308289075455, 44.640942617207315]],
    [ "EYRANS",[-0.5119775376456196, 44.72183178164711]],
    [ "Martillac",[-0.525417642547886, 44.72199831272396]],
    [ "RTE",[0.9718568388595469, 44.89659150712164]],
    [ "Enedis",[6.333183330636798, 46.1125255032151]],
    [ "SNCF Réseau",[1.4647221730835234, 44.90310510203742]],
    [ "SNCF Réseau",[1.4814051816495435, 45.01675071428662]],
    [ "Camping Arsine",[6.362992, 45.03126675]],
    [ "Enedis",[6.339371883923202, 45.04221300698768]],
    [ "Enedis",[6.4039872468966035, 45.034980469263054]],
    [ "Pied du col",[6.358597099999998, 45.035360100000005]],
    [ "Enedis",[-1.0786111999999999, 43.673518449999996]],
    [ "Enedis",[-1.0808624999999998, 43.67018995000001]],
    [ "Enedis",[-1.0816095000000006, 43.67642925]],
    [ "Enedis",[-1.0845166666666668, 43.66899418095238]],
    [ "Enedis",[-1.0808134061479553, 43.67325487090377]],
    [ "RTE",[5.9169539816324574, 44.340879923250654]],
    [ "Enedis",[5.809014286227438, 44.53050536201926]],
    [ "RTE",[5.761405356421712, 44.344808135748764]],
    [ "Enedis",[1.0978467500000002, 49.91671925]],
    [ "Sainte Elisabeth",[3.163083100000001, 45.59004325]],
    [ "Enedis",[1.0790404553448751, 49.90589883154714]],
    [ "Enedis",[1.08289875, 49.904025]],
    [ "Enedis",[1.0804709632258132, 49.901782621083356]],
    [ "Enedis",[1.0841717499999992, 49.906989999999986]],
    [ "Enedis",[1.07643675, 49.91005675]],
    [ "Enedis",[1.0786250000000002, 49.910312]],
    [ "Centrale hydroélectrique de La Brillanne",[5.864102247668105, 43.877329176482526]],
    [ "RTE",[5.858327200292158, 43.8545862969437]],
    [ "Enedis",[5.741940512909293, 44.002272215339175]],
    [ "Électricité de France",[7.569321299314443, 47.914257500514175]],
    [ "Enedis",[3.0628132499999996, 43.2292057]],
    [ "Enedis",[3.0635657945205472, 43.23828095433789]],
    [ "Enedis",[3.051477096177421, 43.23605713718934]],
    [ "Enedis",[3.052697083758505, 43.226137557114505]],
    [ "Enedis",[3.056909989473684, 43.22988741403509]],
    [ "Enedis",[3.0554829386454183, 43.22553328658698]],
    [ "Enedis",[3.0578799010189224, 43.22815452498787]],
    [ "Enedis",[3.0585548428207305, 43.23497492268479]],
    [ "Enedis",[3.536264326765188, 50.29150781116585]],
    [ "Enedis",[3.535953336051045, 50.30078443613376]],
    [ "Enedis",[3.5387664883812913, 50.290932841985565]],
    [ "Enedis",[3.5393226608767585, 50.29308301819686]],
    [ "Enedis",[3.543701506659965, 50.288642827008466]],
    [ "Enedis",[3.2914202155625656, 50.46875915141956]],
    [ "Enedis",[3.283703672839507, 50.47561000823046]],
    [ "Enedis",[5.452957857885665, 46.3990407532908]],
    [ "Enedis",[5.454398398628726, 46.394373224810366]],
    [ "Enedis",[3.3057443306772907, 50.472211525896405]],
    [ "Enedis",[3.2983611752738655, 50.47437687089201]],
    [ "Enedis",[3.281284136955291, 50.47672635540464]],
    [ "Ecole",[3.5858217085158155, 47.43629059270072]],
    [ "Séminaire",[2.4060036235809825, 48.822329602562654]],
    [ "Enedis",[5.059207000000001, 47.2884345]],
    [ "Enedis",[5.053218749999999, 47.280772]],
    [ "Enedis",[5.048208, 47.2829075]],
    [ "Enedis",[5.055496874213838, 47.28381419496855]],
    [ "Enedis",[5.05563305, 47.278610400000005]],
    [ "Blés Or",[5.06779225, 47.2866385]],
    [ "Enedis",[5.054988333333333, 47.29923753333332]],
    [ "Enedis",[5.063878149327671, 47.28834882307148]],
    [ "Lumen",[5.063967000000001, 47.283501]],
    [ "Enedis",[5.05198675, 47.2872525]],
    [ "SFR Longvic",[5.031938453815261, 47.295410702811246]],
    [ "Enedis",[5.061044249999999, 47.27933325]],
    [ "Enedis",[5.0586053637080886, 47.299888903111274]],
    [ "Thibaut",[5.059888006808852, 47.28343238840493]],
    [ "Bergame",[5.0318639835343735, 47.2954126255293]],
    [ "Bertin",[5.031805051338468, 47.29338320132015]],
    [ "Enedis",[5.061826000000001, 47.276007]],
    [ "Malraux",[5.071438249999999, 47.28862800000001]],
    [ "Enedis",[5.073243567851623, 47.287277462029884]],
    [ "Longvic Village",[5.062681016355485, 47.2848269812446]],
    [ "Floreal",[5.066366834633072, 47.288335545490895]],
    [ "Enedis",[5.062403666154896, 47.289780970146715]],
    [ "Enedis",[5.04042900353692, 47.28057825382317]],
    [ "Enedis",[5.059190325217442, 47.280657894422]],
    [ "Enedis",[5.056525645135531, 47.28514964368321]],
    [ "Enedis",[2.0088318419309643, 49.403366524646536]],
    [ "Enedis",[5.183966510729145, 47.525335919888036]],
    [ "Enedis",[3.2786196045567038, 44.79510604728966]],
    [ "RTE",[3.273340924669621, 44.796738424300365]],
    [ "Poste Électrique de Saint-André-de-l'Eure",[1.252767706158018, 48.91026101333489]],
    [ "Enedis",[5.5277882499999995, 46.44505925]],
    [ "Enedis",[2.4366910473582175, 48.818297686980934]],
    [ "Enedis",[2.4466285500000007, 48.81783885]],
    [ "Enedis",[5.750601, 44.818696]],
    [ "Enedis",[2.511522119984847, 48.850285628679735]],
    [ "Enedis",[2.494327599813261, 48.85059352884473]],
    [ "Enedis",[2.496437899915167, 48.84000142883118]],
    [ "Enedis",[2.513789554658083, 48.8352724490584]],
    [ "Enedis",[2.5091780183237375, 48.84726935397825]],
    [ "Enedis",[2.500922190962036, 48.834268327652076]],
    [ "Enedis",[2.50960715, 48.837917999999995]],
    [ "Enedis",[2.4968186740637157, 48.845976674646614]],
    [ "Enedis",[2.50198635, 48.83579890000001]],
    [ "Enedis",[2.50233964174734, 48.84793817274134]],
    [ "Enedis",[2.5084221856892444, 48.84173037013486]],
    [ "Enedis",[2.510761112324304, 48.83746477115317]],
    [ "Enedis",[2.5129760500000002, 48.835990300000006]],
    [ "Enedis",[2.5005136418491993, 48.83701938091665]],
    [ "Enedis",[2.5120951510977143, 48.8314749690821]],
    [ "Enedis",[2.505331279666208, 48.84929769343648]],
    [ "Enedis",[2.511808, 48.8352936]],
    [ "Enedis",[2.5055686, 48.83445465]],
    [ "Enedis",[2.506653011504566, 48.838420382177794]],
    [ "Enedis",[2.5099958080431746, 48.833071872642]],
    [ "Enedis",[2.5089642999999993, 48.845019900000004]],
    [ "Enedis",[2.50097522632995, 48.84554475062051]],
    [ "Enedis",[2.5106572557998694, 48.84028852475217]],
    [ "Enedis",[2.497201279427475, 48.839100723851345]],
    [ "Enedis",[2.499529956425959, 48.83359727396484]],
    [ "Enedis",[2.5099727797795715, 48.84139964580172]],
    [ "Enedis",[2.4961006228263964, 48.84623119709314]],
    [ "Enedis",[2.5023527686612677, 48.844431562958036]],
    [ "Enedis",[2.5144315277229765, 48.83597789487904]],
    [ "Enedis",[2.5037912083032863, 48.83788890619244]],
    [ "Enedis",[2.5073090119504133, 48.84522970236798]],
    [ "Enedis",[2.5087255447742245, 48.83270602169544]],
    [ "Enedis",[2.4946191133998536, 48.837852466565415]],
    [ "Enedis",[2.503607183121208, 48.846976632383324]],
    [ "RTE",[-0.6226096520541389, 46.5481695299675]],
    [ "RTE",[-0.2534060775758509, 46.65988524367981]],
    [ "RTE",[-0.7789256708484444, 46.45722635007119]],
    [ "Enedis",[5.492172032593764, 46.55284552733939]],
    [ "Enedis",[5.486898007674688, 46.39166312380235]],
    [ "Bourg",[5.464497456303171, 46.73700690235885]],
    [ "Le Corbusier",[5.76395536095522, 45.18247567171617]],
    [ "EDF",[5.867709126949707, 44.345529678457225]],
    [ "Enedis",[1.1069648211576844, 49.93206910798402]],
    [ "Enedis",[1.0998587040229886, 49.93276542698537]],
    [ "Enedis",[1.0971840158762196, 49.93043599553056]],
    [ "Enedis",[1.0892395000000001, 49.933766500000004]],
    [ "Enedis",[1.091996323638714, 49.93059909371466]],
    [ "Enedis",[1.1184569197753709, 49.92335318431609]],
    [ "Enedis",[1.119488, 49.92687450000001]],
    [ "Enedis",[1.10462013847252, 49.92419830692363]],
    [ "Enedis",[1.0953481696735397, 49.92529748969073]],
    [ "Enedis",[1.09689724918968, 49.92484267652016]],
    [ "Enedis",[1.101188248322148, 49.91950116331096]],
    [ "Enedis",[1.080611, 49.928726]],
    [ "Enedis",[1.0759992199612398, 49.926747347868215]],
    [ "Enedis",[1.0560306963659842, 49.92125736174136]],
    [ "Enedis",[1.0661491, 49.9122102]],
    [ "Enedis",[1.068657409313724, 49.90835854289217]],
    [ "Enedis",[1.064695, 49.90743675]],
    [ "Enedis",[1.07573, 49.9088345]],
    [ "Enedis",[2.403963574771916, 48.66514182731719]],
    [ "Enedis",[2.396917619732276, 48.666321395471826]],
    [ "Enedis",[2.3995208821248544, 48.66447661057257]],
    [ "Enedis",[2.394171994184266, 48.669582648607296]],
    [ "Enedis",[1.06931094824651, 49.91560132516173]],
    [ "Enedis",[2.400761, 48.65921]],
    [ "Enedis",[2.396832175501494, 48.65667116508749]],
    [ "Enedis",[2.395277616771597, 48.656409501061646]],
    [ "Enedis",[2.389824501237964, 48.65991949325997]],
    [ "Enedis",[2.599015488213021, 48.24666490757961]],
    [ "Enedis",[2.36690727620467, 48.64555205216096]],
    [ "Enedis",[2.3689534820636453, 48.646825218151875]],
    [ "Enedis",[2.3926313089360343, 48.64808076949601]],
    [ "Enedis",[2.392015905107137, 48.64822873111003]],
    [ "Enedis",[2.3760314739053157, 48.651864248020686]],
    [ "Enedis",[2.3771404390346187, 48.65134127245527]],
    [ "Enedis",[2.3822433463835435, 48.647573633488165]],
    [ "Enedis",[2.3770865803921564, 48.64768646117648]],
    [ "Enedis",[2.379172721354166, 48.649875062499994]],
    [ "Enedis",[2.374387108640727, 48.651075790635005]],
    [ "Enedis",[2.3707954499999997, 48.6474361]],
    [ "Enedis",[2.380835075812274, 48.64817601323707]],
    [ "Enedis",[2.3779506760055704, 48.653581168509874]],
    [ "Enedis",[2.377015, 48.64640550000001]],
    [ "Enedis",[2.368663592556946, 48.648933793711905]],
    [ "Enedis",[2.3797880501232536, 48.647864726650226]],
    [ "Enedis",[2.3938077066370593, 48.656202336540844]],
    [ "Enedis",[2.3954088999999996, 48.650866549999996]],
    [ "Enedis",[2.3816435, 48.655162]],
    [ "Enedis",[2.383329704705551, 48.65920384418146]],
    [ "Enedis",[2.384446830511418, 48.65666852878354]],
    [ "Enedis",[2.3889535, 48.652879500000004]],
    [ "Enedis",[2.3876769489445264, 48.65713632940598]],
    [ "Enedis",[2.3868476666666667, 48.65431760130719]],
    [ "Enedis",[2.39273445, 48.655736000000005]],
    [ "Enedis",[2.3912650036968577, 48.65581903333333]],
    [ "Enedis",[1.0843745, 49.91661325]],
    [ "Enedis",[1.0860455, 49.913115499999996]],
    [ "Enedis",[1.0875929999999998, 49.91402949999999]],
    [ "Enedis",[1.0832687239455778, 49.91379611442177]],
    [ "Enedis",[1.0956494999999997, 49.919221500000006]],
    [ "Enedis",[1.07622850443686, 49.90433164641638]],
    [ "Enedis",[1.0708277990340758, 49.917409521509704]],
    [ "Poste électrique de Prémy",[3.1831598086176123, 50.172890531523954]],
    [ "Enedis",[2.4436008316348365, 48.695530864169484]],
    [ "Enedis",[3.318349920187793, 45.49138459624413]],
    [ "Enedis",[1.795791774254251, 45.53416292242325]],
    [ "Enedis",[2.3636324972384783, 44.351048592615115]],
    [ "Enedis",[-0.6632105, 46.3991805]],
    [ "Enedis",[-0.6681684999999999, 46.401368999999995]],
    [ "Enedis",[-0.6589015, 46.39769975]],
    [ "Enedis",[2.5456807269677997, 48.20248660242567]],
    [ "Enedis",[1.6411990999999992, 45.18132625]],
    [ "Enedis",[1.7503667031644716, 43.79272057473509]],
    [ "Enedis",[1.7452733000000002, 43.79162385]],
    [ "Enedis",[3.2817205000000014, 45.7517935]],
    [ "Enedis",[3.280182928416486, 45.75158184960231]],
    [ "Enedis",[1.1733603050556973, 49.301407277634965]],
    [ "Enedis",[3.090111359300476, 45.97875272390037]],
    [ "Enedis",[6.317694393014757, 45.531646599167026]],
    [ "Enedis",[6.306378311796631, 45.54564664905264]],
    [ "-te Authezat",[3.1716949305881936, 45.634827443408476]],
    [ "Enedis",[2.535326761203093, 48.67463096972456]],
    [ "Enedis",[2.526538522674433, 48.6746229877312]],
    [ "Enedis",[3.1820854813614163, 45.715226631244974]],
    [ "Enedis",[3.1963217570061464, 45.72310387295073]],
    [ "Enedis",[3.18363942409786, 45.71891170042814]],
    [ "Enedis",[3.1878367294552175, 45.72346152151432]],
    [ "Enedis",[3.19367, 45.71725874999999]],
    [ "Enedis",[3.18062675, 45.718405600000004]],
    [ "Enedis",[3.1876341, 45.718188]],
    [ "Enedis",[3.186746814345991, 45.71337962447257]],
    [ "Enedis",[3.196169499999998, 45.72518025000001]],
    [ "Enedis",[3.190169499999999, 45.72582425]],
    [ "Enedis",[3.181461536209942, 45.71937682185212]],
    [ "Enedis",[3.190991773411107, 45.71989663892332]],
    [ "Enedis",[3.19354750515124, 45.722918915515095]],
    [ "Enedis",[3.1864372500000004, 45.7116269]],
    [ "Enedis",[3.185083408602151, 45.72771073333333]],
    [ "Enedis",[3.186171, 45.72652649999999]],
    [ "Enedis",[3.189542688586473, 45.71728397587837]],
    [ "Enedis",[3.1907859, 45.71554899999999]],
    [ "Enedis",[3.1815923102523103, 45.72139264239516]],
    [ "Enedis",[3.1889804999999996, 45.713262500000006]],
    [ "Enedis",[3.184071750000001, 45.7164415]],
    [ "Enedis",[3.1948259999999995, 45.72073575]],
    [ "Enedis",[3.1944511371808852, 45.72577863089242]],
    [ "Enedis",[3.1291677343887416, 45.68951985048373]],
    [ "Enedis",[3.1248298666666656, 45.689017799999995]],
    [ "Enedis",[3.133507119635512, 45.68998775449308]],
    [ "Enedis",[3.143783471937531, 45.68560896290874]],
    [ "Enedis",[3.125955181341865, 45.685746703482174]],
    [ "Enedis",[4.738458648401826, 45.975857771689505]],
    [ "Enedis",[2.8139324937044394, 45.92344123591783]],
    [ "Enedis",[2.8133870423402616, 45.92172537721324]],
    [ "Enedis",[2.809661406854615, 45.926163184079606]],
    [ "Enedis",[2.8108947988209283, 45.92361544436256]],
    [ "Enedis",[2.797597954186414, 45.97181420221168]],
    [ "Enedis",[5.14903425, 47.29111225000001]],
    [ "Enedis",[5.136515618219462, 47.280408389233955]],
    [ "150087582",[5.135352499999999, 47.2945005]],
    [ "Enedis",[-0.7415479999999999, 46.35549125000001]],
    [ "Enedis",[-0.7413053000000001, 46.3735159]],
    [ "Enedis",[-0.7409975317945621, 46.368294946913295]],
    [ "Enedis",[-0.7390887103614427, 46.37071417821967]],
    [ "Le Creusot",[6.310596327673854, 45.5196683708216]],
    [ "Epinglette",[6.307846539090147, 45.542120937711026]],
    [ "Enedis",[6.306832995862717, 45.54317977212036]],
    [ "Enedis",[3.186322683716965, 45.814237583120196]],
    [ "Enedis",[3.1878733700787403, 45.816177824146976]],
    [ "Enedis",[3.1919174039133478, 45.81829319357093]],
    [ "Enedis",[3.205108905195591, 45.80250507296481]],
    [ "Enedis",[3.1834021300533943, 45.81591146643783]],
    [ "Enedis",[3.186500797138047, 45.81294561994949]],
    [ "Enedis",[2.940448282296651, 45.95995490430622]],
    [ "Enedis",[2.9488339999999993, 45.961595499999994]],
    [ "Enedis",[2.9310134455445547, 46.097029864686476]],
    [ "Enedis",[2.928871169842245, 46.100746335996725]],
    [ "Enedis",[2.9286992814371247, 46.10035346684409]],
    [ "Enedis",[2.911503132376396, 46.10822932376396]],
    [ "Enedis",[2.9077179208510646, 46.1041337058156]],
    [ "Enedis",[2.9052615, 46.105188500000004]],
    [ "Enedis",[3.1242376723679213, 45.8731704686431]],
    [ "Enedis",[2.7092157988200594, 45.90675342300885]],
    [ "Enedis",[1.7173148174962292, 43.77598447360483]],
    [ "Enedis",[1.7281142795698925, 43.81791527803379]],
    [ "Enedis",[1.6949637785888088, 43.77752454663422]],
    [ "Enedis",[1.702698268716558, 43.80249251604294]],
    [ "Enedis",[1.737002475268817, 43.80852606594981]],
    [ "Enedis",[5.901711978952216, 47.0634377825573]],
    [ "Enedis",[5.896543554572388, 47.053687645443134]],
    [ "Enedis",[0.12419199999999997, 47.50740049999999]],
    [ "Enedis",[0.12179633158927407, 47.50956450185306]],
    [ "Enedis",[0.1216296370269039, 47.51226176151391]],
    [ "Enedis",[0.11820709909909857, 47.507517760360344]],
    [ "Enedis",[0.12301770902394242, 47.514150027624304]],
    [ "Enedis",[0.11197313039778665, 47.51170790002127]],
    [ "Enedis",[0.1306243669724774, 47.51596524464832]],
    [ "Enedis",[0.10816821854304634, 47.512572971302426]],
    [ "Enedis",[0.13148558850574615, 47.51786618099617]],
    [ "Enedis",[0.11724049999999998, 47.510327000000004]],
    [ "Enedis",[0.11106721701913391, 47.50605518126887]],
    [ "Enedis",[0.11377583028741017, 47.51395750999688]],
    [ "Enedis",[0.12640394866933327, 47.51698035143707]],
    [ "Enedis",[5.900194556041735, 47.05820367390985]],
    [ "Enedis",[0.11594571246273119, 47.51243618783541]],
    [ "RTE",[5.7565877101011065, 43.86696446240024]],
    [ "Enedis",[6.274486950000001, 45.48314049999999]],
    [ "Enedis",[3.49087488150438, 45.84581166151468]],
    [ "Enedis",[3.4846159999999986, 45.836231000000005]],
    [ "Enedis",[3.500421709219858, 45.838538085106386]],
    [ "Poste électrique de Coudon",[5.97276002293639, 43.15782310062448]],
    [ "La Minière",[6.280041911949684, 45.51026075471698]],
    [ "RTE",[5.963750468986614, 43.13129554001075]],
    [ "Enedis",[4.574293463475637, 44.910841529797324]],
    [ "Enedis",[4.569457285507247, 44.918589511594206]],
    [ "Enedis",[2.676911336025848, 45.86747354981152]],
    [ "Enedis",[2.6696075328683975, 45.8667744853416]],
    [ "Enedis",[2.6731266452599387, 45.86382073263434]],
    [ "RTE",[5.978543798269491, 43.38455846103922]],
    [ "Enedis",[2.97171920463847, 46.08422969122329]],
    [ "RTE",[5.617401938525948, 43.36386360692111]],
    [ "RTE",[2.09154061045736, 50.02606149160695]],
    [ "Enedis",[5.602406463804383, 43.47814061022674]],
    [ "Enedis",[6.293154466712014, 45.45886279730833]],
    [ "Enedis",[2.2101969475487615, 48.779066735020216]],
    [ "Enedis",[2.1888725, 48.797265]],
    [ "Morane",[2.2189998499999994, 48.78380769999999]],
    [ "Enedis",[1.7910768080808077, 49.28390333333332]],
    [ "Enedis",[1.7897748586150997, 49.2660536751705]],
    [ "Enedis",[1.784729881175735, 49.288236143214505]],
    [ "Enedis",[1.761229369925672, 49.27819723670669]],
    [ "Enedis",[1.7651886003596318, 49.28316284221173]],
    [ "Enedis",[1.790225000000001, 49.28707100000001]],
    [ "Enedis",[1.7817817066666672, 49.27110265600001]],
    [ "Enedis",[1.7887206907103816, 49.28536353486339]],
    [ "Enedis",[1.784063, 49.27227099999999]],
    [ "Enedis",[1.7795322887266833, 49.28723842903488]],
    [ "Enedis",[1.7723883271375458, 49.281502664188345]],
    [ "Enedis",[1.779888, 49.2756745]],
    [ "Enedis",[1.7853923014819728, 49.276870842512714]],
    [ "Enedis",[1.7706388917696467, 49.28252321096842]],
    [ "Enedis",[0.2755427655213984, 47.8246945617842]],
    [ "Enedis",[0.28230329753914923, 47.82746555033556]],
    [ "Enedis",[0.27491108870967784, 47.821055799731184]],
    [ "Enedis",[0.2751838421820307, 47.82674953199146]],
    [ "Enedis",[0.28841416475258747, 47.82436730526017]],
    [ "Enedis",[0.2778788108818774, 47.81631265493527]],
    [ "Enedis",[0.27504300000000004, 47.83011199999999]],
    [ "Enedis",[0.270602705284553, 47.82849049220868]],
    [ "Enedis",[0.2628886633115256, 47.83806482502936]],
    [ "Enedis",[0.2769374505928855, 47.82214616864295]],
    [ "Enedis",[0.274545419263456, 47.8316285118036]],
    [ "Église",[0.27288849051260267, 47.82979416256018]],
    [ "Enedis",[0.2786786537216828, 47.823433543689326]],
    [ "Enedis",[0.2638426560766516, 47.8358425279879]],
    [ "Enedis",[0.27955952093802333, 47.824403249581245]],
    [ "Enedis",[0.2780776369157641, 47.8191737340023]],
    [ "Mega P",[1.3495375000000003, 49.174523199999996]],
    [ "Enedis",[1.3341037500000008, 49.16674]],
    [ "Enedis",[1.3426111742618538, 49.18105118070626]],
    [ "Charlemagne",[1.3363070000000004, 49.167511250000004]],
    [ "Manoir",[1.330311, 49.169866]],
    [ "Enedis",[1.356379731243155, 49.17030435441635]],
    [ "Rue Verdun",[1.3378181, 49.17797534999999]],
    [ "Enedis",[1.3333162099804339, 49.17027232634063]],
    [ "Enedis",[1.334062, 49.18063000000001]],
    [ "RTE",[5.355661415204867, 43.544151126346044]],
    [ "Poste Électrique de Bonnétable",[0.4128012116734178, 48.184818969291804]],
    [ "RTE",[5.253155725443593, 43.41223061168052]],
    [ "Poste électrique de Salon-Croix Blanche",[5.112079502937537, 43.639322407289335]],
    [ "Enedis",[1.5243787500000003, 43.639131750000004]],
    [ "Enedis",[1.5177778240343345, 43.64240391816881]],
    [ "Enedis",[1.5296228288, 43.65137950506667]],
    [ "Enedis",[1.5136629999999998, 43.6433535]],
    [ "Enedis",[1.5220374999999995, 43.638054499999996]],
    [ "Enedis",[1.5194629999999998, 43.642407]],
    [ "Enedis",[1.5342375, 43.6489835]],
    [ "Enedis",[1.5165020542699728, 43.64305075509641]],
    [ "Enedis",[1.5198164848260547, 43.64504717147791]],
    [ "Enedis",[1.5260156272772045, 43.64752931954702]],
    [ "Enedis",[1.518375946782777, 43.64532416303821]],
    [ "Enedis",[1.5316410000000005, 43.6483315]],
    [ "Enedis",[1.5238221600000004, 43.64221435833334]],
    [ "Enedis",[1.5239356706884308, 43.640926241069316]],
    [ "Enedis",[0.9819706528417818, 49.06107797542243]],
    [ "RTE",[5.186414540072085, 43.729918825666836]],
    [ "Enedis",[5.036285993050179, 43.850485061061065]],
    [ "Enedis",[2.4000905991509827, 49.162729222563875]],
    [ "Enedis",[2.380629201051525, 49.166952377076775]],
    [ "Enedis",[2.4370818104431695, 49.156725710013475]],
    [ "Enedis",[2.457229431248044, 49.15339927060116]],
    [ "Enedis",[2.4410436724748927, 49.15934433903399]],
    [ "Enedis",[2.4216546240246215, 49.167460273227405]],
    [ "SNCF Réseau",[4.968274215895302, 43.923779470565165]],
    [ "RTE",[4.9218864922792305, 43.89652108266605]],
    [ "Enedis",[-0.7093002, 46.42324634999999]],
    [ "Enedis",[-0.67665795, 46.41911895]],
    [ "Enedis",[-0.6784459592031981, 46.44007846933111]],
    [ "Enedis",[-0.6895242500000005, 46.41757574999999]],
    [ "Enedis",[-0.6815255470409953, 46.42701653355921]],
    [ "Enedis",[-0.6788807031563845, 46.42295686715686]],
    [ "Enedis",[-0.6803031103957349, 46.419084727634555]],
    [ "Enedis",[-0.74941975, 46.43835074999999]],
    [ "Enedis",[1.0106806989247308, 49.108070693548385]],
    [ "Enedis",[0.9078220742277542, 49.13798032042416]],
    [ "Enedis",[0.9051695158139162, 49.14719783113139]],
    [ "Enedis",[0.9053592870170172, 49.14888532199377]],
    [ "Enedis",[0.8966349999999996, 49.14946499999999]],
    [ "Enedis",[0.8958139651022862, 49.14653015884476]],
    [ "Enedis",[0.9113476218365876, 49.154895678235725]],
    [ "Enedis",[6.860665788214767, 48.07742871077378]],
    [ "Enedis",[2.439262950534928, 49.18975978145585]],
    [ "Enedis",[2.4013632234375826, 49.193229072411135]],
    [ "Sous-station RFF de Chateauneuf",[4.70831328466122, 44.48401230631158]],
    [ "SNCF Réseau",[4.6880045522789375, 44.46528141923775]],
    [ "Enedis",[2.4810028807370696, 49.142521552505364]],
    [ "Enedis",[2.0705739999999997, 44.5761545]],
    [ "réséda",[6.397127326669731, 49.10554289269301]],
    [ "réséda",[6.391610169876422, 49.13671960216658]],
    [ "réséda",[6.3926907460317475, 49.1092035925926]],
    [ "réséda",[6.39131855, 49.110813949999994]],
    [ "réséda",[6.3921854000000025, 49.134473850000006]],
    [ "réséda",[6.399549682896381, 49.11016616104869]],
    [ "réséda",[6.38273355, 49.11017594166666]],
    [ "réséda",[6.399059521985298, 49.11165097179787]],
    [ "réséda",[6.4054055, 49.108081]],
    [ "réséda",[6.392026657555254, 49.10730826341904]],
    [ "réséda",[6.410747, 49.11054049999999]],
    [ "réséda",[6.400328955566874, 49.10471018100227]],
    [ "réséda",[6.410199357575758, 49.10836795050505]],
    [ "réséda",[6.401724131498472, 49.113381186544345]],
    [ "réséda",[6.399673049999999, 49.103674000000005]],
    [ "réséda",[6.395119452380953, 49.11152455952381]],
    [ "réséda",[6.412048280609508, 49.108688378206836]],
    [ "réséda",[6.400052278219196, 49.10735646525414]],
    [ "RTE",[-0.46845302169786657, 46.833686300844604]],
    [ "Point du Jour",[-2.8071699985333374, 48.555122503302464]],
    [ "Roger Collin",[-2.82084400743074, 48.568983505430104]],
    [ "Fosse Argent",[-2.800504012984808, 48.57274574785708]],
    [ "Coteaux Etang",[-2.807426998909411, 48.57262274947859]],
    [ "Pordic Lot Duchesne",[-2.8248165027874563, 48.567105753784354]],
    [ "Rue de la Poste",[-2.818109514983475, 48.56825905263636]],
    [ "Rue Ville Neuve",[-2.816614996784504, 48.572621734599636]],
    [ "ZI IACM 3224",[-2.8107400215245475, 48.556869989475224]],
    [ "PM Curie",[-2.811285755952743, 48.55994400192842]],
    [ "Enedis",[5.8164431, 45.80624639999999]],
    [ "Enedis",[5.794490340266188, 45.80161021347536]],
    [ "Enedis",[2.507186207133053, 49.134997361693]],
    [ "Enedis",[2.5137155159775366, 49.12701273658176]],
    [ "Enedis",[3.1914121998036333, 46.066203479135986]],
    [ "Bourg",[-2.860123867401332, 48.553785494563485]],
    [ "Enedis",[5.2696269617913, 47.5592644480572]],
    [ "Enedis",[2.662170082811413, 45.82555477870562]],
    [ "Enedis",[1.6236417740832474, 47.768387748035785]],
    [ "Enedis",[1.6305740000000002, 47.773498]],
    [ "Enedis",[2.0964483358622052, 49.861378281825374]],
    [ "Enedis",[3.0981201052346075, 45.665860862083]],
    [ "Enedis",[3.0899125, 45.659586999999995]],
    [ "Enedis",[3.088172972363226, 45.65658590129724]],
    [ "Enedis",[3.08479, 45.658281]],
    [ "Enedis",[3.0824475864227243, 45.66020985981383]],
    [ "Enedis",[3.0924050296296297, 45.660044337037036]],
    [ "Enedis",[3.0978860000000004, 45.663233999999996]],
    [ "Enedis",[4.745752531249998, 45.052586859375]],
    [ "Enedis",[2.6517634999999977, 45.9140085]],
    [ "Enedis",[2.6463768787003636, 45.9128392700361]],
    [ "SNCF Réseau",[3.1012562061104463, 48.810161848697675]],
    [ "Enedis",[0.04814604208194836, 47.790958633813226]],
    [ "Enedis",[-0.29494952367752364, 47.84833367706168]],
    [ "Enedis",[-0.2927923432634552, 47.85233052372195]],
    [ "Enedis",[-0.2954285, 47.835015999999996]],
    [ "Enedis",[-0.2959037103361607, 47.8500970587496]],
    [ "Enedis",[0.1652776500000008, 48.0546564]],
    [ "Enedis",[0.15878786305927683, 48.0631596167586]],
    [ "Parc des Sports",[0.15537594493392085, 48.061822154552125]],
    [ "Grandes Marniéres",[0.1733570000000004, 48.047839999999994]],
    [ "Petites Marniéres",[0.1691996970849176, 48.04778256653993]],
    [ "Maison de l'enfance",[0.1449322264467858, 48.05994357749275]],
    [ "Enedis",[0.16576934901960824, 48.048854699607844]],
    [ "Enedis",[0.15068831667483254, 48.058197170504656]],
    [ "Enedis",[-0.6144883825825802, 46.38697178440354]],
    [ "Enedis",[1.8152990572774719, 49.74098369885548]],
    [ "Enedis",[1.3081299126706483, 47.903500192951704]],
    [ "Enedis",[1.732843682587357, 49.65057446455446]],
    [ "Enedis",[6.859960709097275, 48.080286039598505]],
    [ "Enedis",[6.212784749999999, 47.06714275]],
    [ "Enedis",[6.2176525, 47.06421225]],
    [ "Enedis",[4.037110996328295, 43.664976718934476]],
    [ "Enedis",[2.389826208712868, 44.28612589419142]],
    [ "Enedis",[1.1797513311797079, 49.6197294407753]],
    [ "Enedis",[3.0438342171229342, 50.12922826359274]],
    [ "Enedis",[3.060350047614164, 50.14370201716424]],
    [ "Enedis",[3.0611972476830203, 50.145838564453285]],
    [ "Enedis",[3.0134970919861614, 50.12964882522071]],
    [ "Enedis",[3.0124797898003273, 50.140081736732846]],
    [ "Enedis",[3.0161653655797864, 50.1281120292046]],
    [ "Enedis",[3.0125438582616995, 50.12805144564818]],
    [ "Enedis",[3.015556207894047, 50.12830358528717]],
    [ "Enedis",[3.0642828983944974, 50.16394707174615]],
    [ "SNCF Réseau",[3.196003592422128, 43.7366528258558]],
    [ "Enedis",[1.7514706165305982, 49.7201606552077]],
    [ "Enedis",[1.7607768707669673, 49.753501745119095]],
    [ "Enedis",[-0.09886819999999999, 47.2363171]],
    [ "Enedis",[1.7844196000000008, 49.68610614999999]],
    [ "Enedis",[-2.3865017668521946, 46.72645605565862]],
    [ "Enedis",[-2.3595363737075035, 46.71045787686582]],
    [ "Enedis",[-2.354732883480182, 46.71114464837217]],
    [ "Enedis",[-2.3710597919191923, 46.72083003636363]],
    [ "Enedis",[-2.3576916, 46.72159725]],
    [ "Enedis",[-2.366967, 46.723212000000004]],
    [ "Enedis",[-2.3556726, 46.721668449999996]],
    [ "Enedis",[-2.3658006091954027, 46.7211102183908]],
    [ "Enedis",[-2.3667105238795005, 46.712402231937304]],
    [ "Enedis",[-2.3662870915032688, 46.71891666013073]],
    [ "P14 Saint-Amand",[-2.361953050000001, 46.7194962]],
    [ "Enedis",[-2.3609627777815043, 46.714742197047975]],
    [ "Enedis",[2.396901950992733, 44.33828592494563]],
    [ "Enedis",[2.420424452424635, 44.301058816933995]],
    [ "Enedis",[-2.3488805, 46.700723499999995]],
    [ "Enedis",[-2.3441532926560535, 46.70135611831463]],
    [ "Enedis",[5.760430484692206, 46.88428178060276]],
    [ "Enedis",[-2.3469586, 46.709777599999995]],
    [ "Enedis",[-2.3413882499999987, 46.70425675]],
    [ "Enedis",[1.8641919724283167, 49.68304349581667]],
    [ "Enedis",[-2.349025365069678, 46.71880781019225]],
    [ "Enedis",[-2.3440778, 46.7196179]],
    [ "Enedis",[-2.3460204663837407, 46.720174666810685]],
    [ "Enedis",[-2.34388421000848, 46.71682837877273]],
    [ "Enedis",[-2.3542761, 46.71376789999999]],
    [ "Enedis",[-2.351270009518195, 46.721267430117734]],
    [ "Enedis",[-2.3514232499999994, 46.7174747]],
    [ "Enedis",[-2.343106045989357, 46.721519541724675]],
    [ "Enedis",[-2.3413222826485085, 46.71582019936327]],
    [ "Enedis",[5.7937563936289695, 46.97222677357548]],
    [ "Enedis",[5.795539372353332, 46.969508643171345]],
    [ "Enedis",[5.796108506942433, 46.97199491570023]],
    [ "Enedis",[5.794547849999999, 46.983507949999996]],
    [ "Enedis",[5.816366683213884, 46.953752595806]],
    [ "Enedis",[5.830146600000002, 46.94999165]],
    [ "RTE",[1.639397826834032, 48.0086364542497]],
    [ "Enedis",[5.879788854925523, 46.94749515195895]],
    [ "Enedis",[5.874398500000001, 46.94809299999999]],
    [ "151950372",[5.8758719500000005, 46.9489007]],
    [ "Enedis",[6.348157499999999, 46.115949099999995]],
    [ "Enedis",[6.345138922959055, 46.13343056971478]],
    [ "Villy",[6.336168000000001, 46.138637499999994]],
    [ "Enedis",[-2.3444601051487775, 46.723557766263156]],
    [ "Enedis",[-2.344854349211671, 46.723987366703334]],
    [ "Enedis",[-2.352379137480799, 46.72787206758833]],
    [ "Enedis",[-2.353647000000001, 46.72530645]],
    [ "Enedis",[-2.3411078387896036, 46.7137478734619]],
    [ "Enedis",[-2.3377665, 46.7177601]],
    [ "Enedis",[-2.333423212126643, 46.70991977272998]],
    [ "Enedis",[-2.335596999999999, 46.7121466]],
    [ "Enedis",[-2.331154035076338, 46.70608904924973]],
    [ "réséda",[6.097551810755178, 49.00521947973755]],
    [ "réséda",[6.095381465277779, 49.00697879861112]],
    [ "Lot Dessous La Ville",[6.1002711, 49.001144950000004]],
    [ "réséda",[6.098303860854249, 48.99885747688243]],
    [ "réséda",[6.316153470767184, 49.15881718428372]],
    [ "réséda",[6.352232881422419, 49.160063740845054]],
    [ "réséda",[6.298900342035566, 49.15761318539538]],
    [ "réséda",[6.311483740924091, 49.174754079207915]],
    [ "réséda",[6.310117175910821, 49.17024497824906]],
    [ "Enedis",[-2.3324258264223254, 46.699688355692324]],
    [ "Enedis",[-2.3345283527512746, 46.7033317730389]],
    [ "Enedis",[-2.3230354999999996, 46.701603250000005]],
    [ "Enedis",[-2.32189335, 46.69782975]],
    [ "Enedis",[-2.31626575, 46.6968175]],
    [ "Enedis",[-2.3253183768374974, 46.71395121506627]],
    [ "Enedis",[-2.3214769999999993, 46.7113535]],
    [ "Enedis",[-2.326172026300908, 46.70951290597592]],
    [ "Enedis",[-2.3167922499999993, 46.70873875]],
    [ "Enedis",[-2.3256130573445004, 46.71787408241913]],
    [ "Enedis",[-2.3093972610125615, 46.6968408284347]],
    [ "Enedis",[-2.2934645076511857, 46.697398579937754]],
    [ "Enedis",[-2.3034076060988142, 46.70462681758989]],
    [ "Enedis",[5.818705601360569, 46.96944810048868]],
    [ "Enedis",[5.84772860915662, 46.980366944009106]],
    [ "Enedis",[5.857794564715921, 46.99885923155069]],
    [ "Enedis",[3.0425326067825984, 50.66199477265995]],
    [ "Enedis",[3.043821127781945, 50.66436938059515]],
    [ "Enedis",[3.042122704119851, 50.667447943820214]],
    [ "Enedis",[3.0439451749234103, 50.666442533662114]],
    [ "Enedis",[3.0386511703521717, 50.66801442653563]],
    [ "Enedis",[3.0462146941896027, 50.66650518654433]],
    [ "Enedis",[3.0754166531910916, 50.67943052947782]],
    [ "Enedis",[3.065430502280952, 50.6812556992292]],
    [ "Enedis",[3.0725128619243067, 50.67204890542636]],
    [ "Enedis",[6.34605719833617, 45.66081715863112]],
    [ "Enedis",[6.3463392744667875, 45.65758365252894]],
    [ "Enedis",[6.358504311745528, 45.66422983919288]],
    [ "Enedis",[6.360714232695142, 45.655229255621016]],
    [ "Enedis",[3.069089023113881, 50.675074653811194]],
    [ "Enedis",[3.0660279999999993, 50.669056000000005]],
    [ "Enedis",[3.0715784534357162, 50.672239189407364]],
    [ "Enedis",[3.0681755771178634, 50.66852612638122]],
    [ "Enedis",[3.0724457583600358, 50.66921035387397]],
    [ "Enedis",[1.62075225, 42.82494675]],
    [ "Enedis",[1.6147485, 42.83442515]],
    [ "Enedis",[3.063652495678699, 50.679080335197426]],
    [ "Enedis",[3.061081188523115, 50.67520603723692]],
    [ "Enedis",[3.0613722448210914, 50.679573338041436]],
    [ "Poste électrique de L'Orangerie",[0.5767072369581663, 46.844786738810605]],
    [ "SRD",[0.5282347201591934, 46.779976567279675]],
    [ "SNCF Réseau",[0.5272063592499318, 46.78505346017424]],
    [ "Aux Pessettes",[5.839575621594724, 46.510800933780324]],
    [ "Prénovel de Bise",[5.854883295765231, 46.525400841583405]],
    [ "Les Bérods",[5.8359126926400196, 46.507625666016985]],
    [ "Enedis",[6.373975527045499, 45.672575591681316]],
    [ "Enedis",[5.844326949999999, 46.5174602]],
    [ "Enedis",[5.8420274229381, 46.51269356318821]],
    [ "Enedis",[6.390828999999999, 45.679942749999995]],
    [ "Enedis",[6.362193333813779, 45.66423150235419]],
    [ "Berlioz",[6.3846141685520355, 45.66915992835597]],
    [ "Enedis",[6.3814908, 45.658180200000004]],
    [ "Enedis",[6.376002333333334, 45.663817333333334]],
    [ "Enedis",[6.399930333333334, 45.66583004918033]],
    [ "Enedis",[6.39252062705314, 45.67938326570048]],
    [ "Enedis",[6.166570203600972, 48.70071554812652]],
    [ "Enedis",[6.168463870983936, 48.701015738453805]],
    [ "Poste électrique de Coulommiers",[3.1060376409317314, 48.815539447370114]],
    [ "Enedis",[1.7432181593842297, 49.591393192630136]],
    [ "Enedis",[6.1452815, 48.70244250000002]],
    [ "Enedis",[6.1461985, 48.703915]],
    [ "Enedis",[6.1537842538268865, 48.70354216383709]],
    [ "Enedis",[6.153242499999999, 48.705163]],
    [ "Enedis",[6.143514338695148, 48.700380197743755]],
    [ "Enedis",[6.14494951146738, 48.701083719216214]],
    [ "Enedis",[6.164612319332159, 48.70221977045385]],
    [ "Enedis",[-1.4130542537449982, 48.681720329845625]],
    [ "Enedis",[6.366589899999999, 45.665546899999995]],
    [ "Enedis",[0.04902446285714281, 47.59168283238096]],
    [ "Enedis",[6.366033719354839, 45.66858280967742]],
    [ "Enedis",[1.7768647267632827, 49.62876022365915]],
    [ "Enedis",[-0.8869187659183856, 47.68739305457473]],
    [ "Enedis",[1.8349789227919353, 49.65216014940261]],
    [ "Enedis",[6.388272691542288, 45.66693570646767]],
    [ "Enedis",[1.7429938624508754, 49.57625895551472]],
    [ "Poste électrique d'Arles",[4.6486814151085945, 43.66212686199786]],
    [ "Laberut",[6.39045375, 45.67019125000001]],
    [ "Enedis",[6.389031467542811, 45.66384751772202]],
    [ "Enedis",[6.9677655000000005, 48.2741775]],
    [ "Enedis",[6.961943853080091, 48.270880857300774]],
    [ "Enedis",[6.970696127995145, 48.270234321201094]],
    [ "Enedis",[6.959466942768567, 48.2699938201914]],
    [ "Enedis",[6.957157371742111, 48.268704190672146]],
    [ "Enedis",[6.96032662976418, 48.269817062715106]],
    [ "Enedis",[6.9748494423922605, 48.27365922603341]],
    [ "Enedis",[6.9791194, 48.268379800000005]],
    [ "Enedis",[6.972789909090909, 48.2738922969697]],
    [ "Enedis",[6.9759880387146715, 48.27587934572202]],
    [ "Enedis",[6.970371691480561, 48.27349597684036]],
    [ "Enedis",[6.968439999999999, 48.270105]],
    [ "Enedis",[6.9615906689000555, 48.273351163595756]],
    [ "Enedis",[6.972907560439561, 48.27075045421246]],
    [ "Enedis",[6.968287648378445, 48.276118261155816]],
    [ "Enedis",[6.971954830953999, 48.27538850328574]],
    [ "Enedis",[6.988491616940582, 48.270889345975554]],
    [ "Enedis",[6.966781050000002, 48.274021399999995]],
    [ "Enedis",[6.974074645064054, 48.278200754584276]],
    [ "Enedis",[6.970477499999999, 48.27789549999999]],
    [ "Enedis",[6.962954553645987, 48.26860496203515]],
    [ "réséda",[6.22453317181648, 49.24400101404494]],
    [ "réséda",[6.232438336320638, 49.24797551082897]],
    [ "réséda",[6.2361821651209315, 49.244206557192214]],
    [ "réséda",[6.221885208084825, 49.245068544731616]],
    [ "réséda",[6.228798254150703, 49.24686775606641]],
    [ "réséda",[6.233782050653595, 49.242658843954246]],
    [ "réséda",[6.229774749425287, 49.2461629724138]],
    [ "Enedis",[4.061134631282215, 43.67255165474914]],
    [ "Enedis",[6.413645082730923, 45.66455497188754]],
    [ "Enedis",[2.521289990484404, 49.86771724560703]],
    [ "Enedis",[2.504167099256692, 49.87019785839344]],
    [ "Enedis",[2.5145459999999993, 49.86347075000001]],
    [ "Enedis",[2.523968939304698, 49.8702367137112]],
    [ "Enedis",[2.509545336013929, 49.86412928559181]],
    [ "Enedis",[2.5156743525856795, 49.87228098753784]],
    [ "Enedis",[2.5207510349848685, 49.865466204961194]],
    [ "Enedis",[2.5268554782294146, 49.85667441744453]],
    [ "Enedis",[2.5320277851440394, 49.86247072939327]],
    [ "Enedis",[2.506196718142865, 49.86779587061191]],
    [ "Enedis",[2.5165973337137886, 49.868387719248354]],
    [ "Enedis",[2.5198959815543347, 49.87066494943089]],
    [ "Enedis",[2.5124111545259624, 49.868611764960654]],
    [ "réséda",[6.207920749999998, 49.00973989999999]],
    [ "réséda",[6.199851676730586, 49.007443223283026]],
    [ "réséda",[6.20462898652745, 49.00503357505333]],
    [ "réséda",[6.201789099999999, 49.012228900000004]],
    [ "réséda",[6.212174806784661, 49.004978332743356]],
    [ "réséda",[6.199559999999999, 49.00891299999999]],
    [ "RTE",[3.214218958556974, 50.146370037273726]],
    [ "Sous-station Jaunay-Clan",[0.36575505288560883, 46.694073373355934]],
    [ "Enedis",[4.432089955555556, 45.94544846666667]],
    [ "Enedis",[1.8828269724670006, 48.084855894360786]],
    [ "Enedis",[1.8827754403418753, 48.08628410427351]],
    [ "Enedis",[1.8751868162044592, 48.082195872213155]],
    [ "Enedis",[1.8809194999999999, 48.079520999999986]],
    [ "Enedis",[1.8783748467966581, 48.07675898421541]],
    [ "Enedis",[1.8788886930268203, 48.08606304183908]],
    [ "Poste de Bergé",[-0.8721002166303213, 43.83635741101004]],
    [ "Enedis",[6.261690864490386, 48.80343603018648]],
    [ "DIGNE",[6.229220694612546, 44.09227109565261]],
    [ "Poste électrique de Val de Sèvre",[-0.6564168778205282, 46.75113214690696]],
    [ "Enedis",[2.0590214613046167, 47.88803697009078]],
    [ "Enedis",[2.0539909125483016, 47.895365238153346]],
    [ "Enedis",[2.069080246246246, 47.895210432432435]],
    [ "Enedis",[2.0556827880831925, 47.88961895278246]],
    [ "Enedis",[6.390114048872181, 45.67828516228071]],
    [ "Enedis",[6.177353800000001, 49.3708451]],
    [ "Enedis",[6.1805496149057735, 49.37332874187773]],
    [ "Enedis",[3.4312329999999998, 43.6062805]],
    [ "Enedis",[3.4353300775970776, 43.60705485331816]],
    [ "Enedis",[3.4310248999999993, 43.607771099999994]],
    [ "Enedis",[0.39892072514619886, 43.50749439181286]],
    [ "Enedis",[0.4088502005710203, 43.511839570068986]],
    [ "réséda",[6.049596000000001, 49.025065]],
    [ "réséda",[6.042076999999999, 49.0244859]],
    [ "réséda",[6.046140270631627, 49.02430004057169]],
    [ "réséda",[6.044673373504273, 49.02272754017094]],
    [ "Enedis",[3.5171729999999997, 47.10326499999999]],
    [ "Enedis",[3.510883491049616, 47.10940957708055]],
    [ "Enedis",[3.5146256083715604, 47.10519661506116]],
    [ "Enedis",[2.4466894999999993, 48.51766500000001]],
    [ "Enedis",[2.4470653015064716, 48.51626934500318]],
    [ "Enedis",[2.4481550669732908, 48.51445197095134]],
    [ "Enedis",[1.2129770388011227, 43.44740853435649]],
    [ "Enedis",[1.2522540999999998, 43.46629215]],
    [ "Enedis",[1.247963099999999, 43.4638476]],
    [ "Enedis",[1.2523346164206957, 43.465335067198886]],
    [ "RTE",[0.33823680950947277, 46.813927312417]],
    [ "RTE",[0.18786868441826932, 46.777691447004386]],
    [ "Enedis",[4.542719596333034, 45.02938685482416]],
    [ "Enedis",[6.379991500000001, 45.676522500000004]],
    [ "Enedis",[1.3358275000000004, 46.032985000000004]],
    [ "Enedis",[6.382652746543779, 45.67151401109404]],
    [ "Enedis",[6.373306500000001, 45.669485]],
    [ "Enedis",[6.381999472564018, 45.66182523112737]],
    [ "Poste électrique de Montmorillon",[0.8785575224644325, 46.433065245789784]],
    [ "Poste électrique des Jaumes",[0.8921755022824109, 46.43182861949503]],
    [ "Enedis",[6.380191330496455, 45.66668945957447]],
    [ "Poste électrique de Saint-Laurent-de-Jourde",[0.5514638165402548, 46.40669747419193]],
    [ "Enedis",[3.178417424976523, 43.68571672399087]],
    [ "Enedis",[0.17927363143098177, 48.609481256864406]],
    [ "Enedis",[0.12880154659153903, 48.58873349621282]],
    [ "Enedis",[0.17645238308457672, 48.60862037711443]],
    [ "Enedis",[0.1769645, 48.61317999999999]],
    [ "Enedis",[0.1796581334437692, 48.607602757408436]],
    [ "Enedis",[0.16162599999999938, 48.596343999999995]],
    [ "Enedis",[0.1764882174657541, 48.59627297203196]],
    [ "Enedis",[0.13167089718643288, 48.593740303202104]],
    [ "Enedis",[0.1661778599999999, 48.60841068814815]],
    [ "Enedis",[0.20012399999999916, 48.6107585]],
    [ "Enedis",[0.17489562513144025, 48.60716734016824]],
    [ "Enedis",[0.1588120269883453, 48.59646859088121]],
    [ "RTE",[0.778119154964538, 46.74584841678246]],
    [ "Enedis",[6.3778202779097395, 45.65951214489311]],
    [ "Jean Moulin",[6.383677697092656, 45.668724001655924]],
    [ "RTE",[3.1748898076039347, 43.5588773526644]],
    [ "RTE",[3.0432343284223977, 43.4196149203508]],
    [ "Poste électrique des Arpents",[1.3841300926635909, 48.709568712060985]],
    [ "RTE",[3.090496367028097, 43.43645715440273]],
    [ "Enedis",[2.824621664538102, 50.35652495317157]],
    [ "Enedis",[2.82227185, 50.36045785]],
    [ "Enedis",[2.222010109381144, 48.58090628214201]],
    [ "Enedis",[2.221999827766863, 48.580987836716886]],
    [ "Enedis",[2.228339812735968, 48.5803779030959]],
    [ "Personnes Agées",[-1.8366244226764932, 49.66212776690403]],
    [ "Hameau Fabien",[-1.8282636999999997, 49.6617043]],
    [ "Enedis",[2.806517202312138, 50.37293934489403]],
    [ "Enedis",[2.8502224999999997, 50.37760049999999]],
    [ "Enedis",[2.816655406973249, 50.368250298166515]],
    [ "Enedis",[2.8045690000000003, 50.37797499999999]],
    [ "Enedis",[2.8056757006092257, 50.3692742332463]],
    [ "Enedis",[2.802812, 50.372025666666666]],
    [ "Enedis",[2.8075727629399583, 50.374847848516225]],
    [ "Enedis",[2.8115426958576584, 50.377028541840424]],
    [ "Enedis",[2.8156489573070607, 50.37634383743843]],
    [ "Enedis",[2.8038670000000003, 50.37489049999999]],
    [ "Enedis",[2.803678830601092, 50.38178066814856]],
    [ "Enedis",[2.8126626365248226, 50.36881994858157]],
    [ "Enedis",[2.8227440096737904, 50.37182771188601]],
    [ "Enedis",[2.8138417254901964, 50.37192809803921]],
    [ "Enedis",[2.8081271228745592, 50.36848133846649]],
    [ "Enedis",[2.818935148594377, 50.37396228514056]],
    [ "Enedis",[2.8094336659711345, 50.366663017388284]],
    [ "Enedis",[2.8067944303193078, 50.37960591465234]],
    [ "Enedis",[-1.5225452617250934, 48.725039114668725]],
    [ "Enedis",[2.8108669321533926, 50.373584864306785]],
    [ "Enedis",[-1.5176287803611064, 48.72757634336505]],
    [ "Enedis",[-1.5136941500408925, 48.72998267226951]],
    [ "Enedis",[2.7972945066109953, 50.37152960635584]],
    [ "Enedis",[2.8120935355710364, 50.37355455362044]],
    [ "Enedis",[4.689785919894597, 43.795357690382076]],
    [ "Enedis",[4.691857724782899, 43.79291796993988]],
    [ "farigoule",[4.667533136546186, 43.811939574297185]],
    [ "Enedis",[4.662920280980782, 43.815989650099404]],
    [ "Enedis",[4.674506472356417, 43.8056022494185]],
    [ "Enedis",[4.6651408845413735, 43.804713726534104]],
    [ "tamaris",[4.665711193809948, 43.816703656877294]],
    [ "Enedis",[4.665410190634422, 43.80075027350389]],
    [ "Enedis",[4.691945053214479, 43.795271386061586]],
    [ "Enedis",[4.669816185004074, 43.82040563732681]],
    [ "margarido",[4.66858246353732, 43.8058471398289]],
    [ "Enedis",[4.664298823975431, 43.81929116940206]],
    [ "marcel",[4.664714401774397, 43.812738801013936]],
    [ "Enedis",[4.665364868938198, 43.78168568494015]],
    [ "Enedis",[4.670717055910544, 43.80824187504438]],
    [ "Provence",[4.666661815358068, 43.815588466206506]],
    [ "Enedis",[4.665036317641466, 43.796472679029954]],
    [ "Dsitrict",[4.6564345461784615, 43.80167118121604]],
    [ "drujon",[4.6622418675213675, 43.817273209401705]],
    [ "Enedis",[4.656833933290956, 43.79014870268743]],
    [ "Enedis",[4.668197444678701, 43.80286252892191]],
    [ "Enedis",[4.66871215, 43.81935545]],
    [ "Enedis",[4.6938876752136744, 43.794571324786325]],
    [ "Enedis",[4.672467160414762, 43.808414660872224]],
    [ "Enedis",[4.65754967705469, 43.7937493431103]],
    [ "bobet",[4.670294550000001, 43.7987838]],
    [ "Stamp",[4.667778470724637, 43.81448987333333]],
    [ "Enedis",[4.68300694251796, 43.799087007810066]],
    [ "Enedis",[4.692623214002812, 43.824393591874276]],
    [ "Enedis",[-1.699548179047619, 49.6571524742857]],
    [ "Enedis",[-1.6985003759999995, 49.66497825066666]],
    [ "Enedis",[-1.7124055, 49.658693500000005]],
    [ "Enedis",[-1.7044471875811904, 49.657091358794496]],
    [ "153637577",[-1.6864289858579509, 49.66520432567575]],
    [ "Enedis",[-1.6923216000000003, 49.665872099999994]],
    [ "Enedis",[-1.6840389182055084, 49.662686746668925]],
    [ "Enedis",[-1.7045105000000003, 49.6563235]],
    [ "Enedis",[-1.6890989883819694, 49.665461503000415]],
    [ "SNCF Réseau",[-0.9339849676104305, 43.87660615697129]],
    [ "Enedis",[5.548768666666667, 46.31382266700068]],
    [ "153701447",[4.5863910150507206, 45.82106198907513]],
    [ "RTE",[1.9351459888088003, 47.832903004224384]],
    [ "Enedis",[1.9382616576517253, 47.83473594308809]],
    [ "Enedis",[1.9322360471660722, 47.82531332500991]],
    [ "Enedis",[1.9171595000000001, 47.83023750000001]],
    [ "Enedis",[1.9295445852949105, 47.824299165777894]],
    [ "Enedis",[1.9269716663230243, 47.83819249561856]],
    [ "Enedis",[1.922192, 47.826745999999986]],
    [ "Enedis",[1.9383046619618913, 47.82558051823101]],
    [ "Enedis",[1.941503911091419, 47.82173664599025]],
    [ "Enedis",[1.9237079999999998, 47.8329935]],
    [ "Enedis",[1.9257823818846942, 47.835571471466196]],
    [ "Enedis",[1.9445985000000001, 47.82128349999999]],
    [ "Enedis",[1.9404951120943952, 47.81526074412409]],
    [ "Enedis",[1.9215221417102963, 47.83041224729494]],
    [ "Enedis",[1.9359513625730995, 47.82895096101365]],
    [ "Enedis",[1.9304904999999997, 47.818709500000004]],
    [ "Enedis",[1.9255421307466531, 47.82497757810551]],
    [ "Enedis",[6.232796167755313, 48.586055925868315]],
    [ "Enedis",[6.2330654652014665, 48.58952979853479]],
    [ "Enedis",[6.232124243309004, 48.59134384184916]],
    [ "Enedis",[6.3609082435713304, 48.65753676791156]],
    [ "Poste électrique de Mamers",[0.3572786979770283, 48.34607939114197]],
    [ "SNCF Réseau",[0.5691939059908587, 46.87426498977501]],
    [ "Enedis",[2.3012941719298246, 47.812498063157896]],
    [ "Enedis",[2.9250976666666664, 47.41390966666667]],
    [ "Enedis",[2.9436462584916523, 47.41228806332759]],
    [ "Enedis",[2.9328447915746065, 47.41416146486964]],
    [ "Enedis",[2.9380030240725006, 47.42462866241858]],
    [ "Enedis",[2.939770192803802, 47.42305820638152]],
    [ "gare",[2.9314363546553985, 47.41422270644519]],
    [ "Enedis",[2.9333884602630076, 47.409069350485986]],
    [ "Enedis",[2.9427885, 47.40784699999999]],
    [ "Enedis",[2.933591664800299, 47.416410991041445]],
    [ "Enedis",[2.924098847795373, 47.41407233183969]],
    [ "Enedis",[2.9434266878431385, 47.41839498352942]],
    [ "Enedis",[2.9828214999999996, 47.43049275]],
    [ "Enedis",[2.9739830000000005, 47.42232499999999]],
    [ "Enedis",[2.937771, 47.427076]],
    [ "Enedis",[2.9316111017811703, 47.42043511704835]],
    [ "Enedis",[2.9379761678583582, 47.430372175349]],
    [ "Enedis",[2.987598659157449, 47.44034282383476]],
    [ "Enedis",[2.928120634637455, 47.42005212905581]],
    [ "Enedis",[2.9342927229357816, 47.42779204668706]],
    [ "Enedis",[2.9395653269384496, 47.41013602717827]],
    [ "Enedis",[2.9289456710134982, 47.409536428277285]],
    [ "Enedis",[2.931273444050633, 47.42525679172997]],
    [ "Enedis",[2.934856393939394, 47.422289746352405]],
    [ "Enedis",[2.9290995041086747, 47.41551775808849]],
    [ "Enedis",[2.927023130240941, 47.41637342563081]],
    [ "Enedis",[-1.0652255, 49.1035465]],
    [ "Poste électrique de Cornimont",[6.81550902409026, 47.95268526923634]],
    [ "Enedis",[-1.2668402632533784, 49.59319122595395]],
    [ "Enedis",[6.832051831089062, 47.51330650342546]],
    [ "Enedis",[6.829780499999999, 47.516239]],
    [ "Enedis",[6.8388166775599135, 47.51640102832244]],
    [ "Enedis",[6.835670496243575, 47.51684216805061]],
    [ "Enedis",[6.83326617584132, 47.512685525592445]],
    [ "Enedis",[6.82515444, 47.51702674222222]],
    [ "RTE",[0.5873429863921313, 46.907884550342445]],
    [ "Sous-station SNCF du Colombier",[0.6023262186759973, 46.986878061907376]],
    [ "Enedis",[-1.7569969486997639, 49.385429592356196]],
    [ "Enedis",[-1.7582361754703955, 49.37982660802693]],
    [ "Enedis",[-1.7940610000000001, 49.38237000000001]],
    [ "Enedis",[-1.764751163035312, 49.36495284823442]],
    [ "Enedis",[-1.7852287063063064, 49.37819628378377]],
    [ "Enedis",[-1.7916005, 49.382489]],
    [ "Enedis",[-1.7606276, 49.362703599999996]],
    [ "RTE",[1.5490424718409617, 47.28607255263256]],
    [ "Enedis",[6.1403110000000005, 48.69572349999999]],
    [ "Enedis",[6.0756719471766845, 49.44418004371585]],
    [ "Poste électrique de Borly",[6.280400251817858, 46.180140849889575]],
    [ "Enedis",[1.7249426817813776, 45.58522561322536]],
    [ "Enedis",[-0.7848648, 46.7907551]],
    [ "Enedis",[-0.8459270000000001, 46.7648465]],
    [ "Enedis",[-0.8323340999999999, 46.779948999999995]],
    [ "Enedis",[-0.8319711744778648, 46.78271499419255]],
    [ "Enedis",[-0.8636634000000002, 46.76080109999999]],
    [ "Enedis",[-0.8269543794671448, 46.777497854462744]],
    [ "Enedis",[-0.8400082499999998, 46.775520549999996]],
    [ "Enedis",[-0.8437936330531586, 46.78031668657248]],
    [ "Enedis",[-0.8423272004838568, 46.77039913317829]],
    [ "Enedis",[5.345681868350859, 43.21979248334343]],
    [ "Enedis",[5.346169185871429, 43.21429215181093]],
    [ "Enedis",[5.347596564432414, 43.215822136770896]],
    [ "Enedis",[5.3549755999999995, 43.2306901]],
    [ "Enedis",[5.355932688169471, 43.232146561000306]],
    [ "Enedis",[4.273538521453811, 45.54072134258791]],
    [ "Enedis",[4.273647787421383, 45.54043460610962]],
    [ "Enedis",[4.304054764281566, 45.535734773428445]],
    [ "Enedis",[-0.5497532105263161, 44.86986958245613]],
    [ "Enedis",[-0.5472459999999986, 44.869443499999996]],
    [ "RTE",[0.27743478606252225, 46.55182564309206]],
    [ "RTE",[0.3024021640011391, 46.54400184509267]],
    [ "Poste Électrique de la Corbière",[-0.47807168535510175, 47.4858609380153]],
    [ "Enedis",[1.8921960897844263, 47.8907176711813]],
    [ "Enedis",[1.8959721737230335, 47.893067509392765]],
    [ "Enedis",[1.8871470000000001, 47.8914865]],
    [ "Enedis",[1.904840600000002, 47.89345584999999]],
    [ "Enedis",[1.9255461687366693, 47.87300950257182]],
    [ "Enedis",[1.8998896833049728, 47.89323403478918]],
    [ "Enedis",[1.900269, 47.8927505]],
    [ "Enedis",[1.8990897000000007, 47.88206565]],
    [ "Enedis",[1.9011147302477784, 47.88581505563347]],
    [ "Enedis",[1.9007839335177574, 47.88352474968205]],
    [ "Enedis",[1.9036861312447797, 47.881394354970766]],
    [ "Enedis",[1.8927933500000003, 47.8878793]],
    [ "Enedis",[1.8931602366630869, 47.88097310955962]],
    [ "Enedis",[1.9160271858974358, 47.86541265384614]],
    [ "Enedis",[1.9140370422514748, 47.881951068462755]],
    [ "Enedis",[1.9113507953944888, 47.87907484371462]],
    [ "Enedis",[1.9068077500000002, 47.87838005]],
    [ "Enedis",[1.8981377, 47.89397119999999]],
    [ "Enedis",[1.9191122013834507, 47.86836709175151]],
    [ "Enedis",[1.888380130924448, 47.892077763647734]],
    [ "Enedis",[1.9105572484848488, 47.87636958181818]],
    [ "Enedis",[1.9084382881136948, 47.87346005167957]],
    [ "Enedis",[1.9070397500000005, 47.879696499999994]],
    [ "Enedis",[1.9136315649625084, 47.877675966666665]],
    [ "Enedis",[1.902687185970902, 47.8859254621637]],
    [ "Enedis",[1.9054841565617804, 47.88471475722692]],
    [ "Enedis",[1.9032506165345, 47.89158871552274]],
    [ "Enedis",[1.896859865378811, 47.88250435426099]],
    [ "Enedis",[1.8903857903485262, 47.893006826809646]],
    [ "Enedis",[1.9098559999999998, 47.88725060377358]],
    [ "Enedis",[1.88099605, 47.88248305]],
    [ "Enedis",[1.8993168275245755, 47.887029835567475]],
    [ "Enedis",[1.8910344180137515, 47.888791907921664]],
    [ "Enedis",[1.8886641613226167, 47.88783447719933]],
    [ "Enedis",[1.8966782670768245, 47.89348195504023]],
    [ "Enedis",[1.920202938989403, 47.87234256120086]],
    [ "Enedis",[1.9030665253881673, 47.87946636005035]],
    [ "Enedis",[1.9184115065483733, 47.877588669624]],
    [ "Enedis",[1.9105549742810866, 47.882682064803575]],
    [ "Enedis",[1.9055741263403463, 47.892502193307784]],
    [ "Enedis",[1.9103186281179136, 47.881564678911566]],
    [ "Enedis",[1.8995653384115732, 47.890848214762336]],
    [ "Enedis",[1.9201733585632341, 47.872356470515]],
    [ "Enedis",[1.9114630268959436, 47.89239877733686]],
    [ "Enedis",[1.9084618805444156, 47.87564038911168]],
    [ "Poste électrique de Courtenay",[3.064573347212916, 48.029153475586774]],
    [ "RTE",[2.9887704132134574, 48.16832629305141]],
    [ "Enedis",[5.388495826514189, 43.25427078487929]],
    [ "Enedis",[5.399902213896066, 43.27757306431238]],
    [ "Enedis",[5.401444800000001, 43.2757924]],
    [ "Enedis",[5.400361665564739, 43.27659049421489]],
    [ "Château",[0.18855525880425916, 48.03442435462736]],
    [ "Les Pommiers",[0.18792038209931697, 48.03684325810289]],
    [ "Enedis",[0.17959324999999998, 48.041249500000006]],
    [ "Saules",[0.1913129412568303, 48.03484567868853]],
    [ "Le Lauzai",[0.19103949999999997, 48.04228199999999]],
    [ "Le Housaye",[0.19698836986301313, 48.03411099543379]],
    [ "Enedis",[4.838091880237301, 46.79249844493882]],
    [ "Poste électrique de Douarnenez",[-4.309577115498281, 48.07465669888533]],
    [ "Enedis",[-0.6145884786366753, 44.863060555153744]],
    [ "Enedis",[-1.497533726318733, 48.71215893437667]],
    [ "OP HLM GMF",[5.679311233083521, 45.05639555100512]],
    [ "Les Gracieux",[5.6785152756483335, 45.05716168076489]],
    [ "Square",[5.669892499999999, 45.0559735]],
    [ "ZI 3",[5.6826162, 45.057729300000005]],
    [ "Enedis",[5.676134500000001, 45.051676]],
    [ "Enedis",[5.669232650745384, 45.049192246223946]],
    [ "Speyres",[5.679011982719454, 45.054414704812245]],
    [ "Enedis",[5.384198995852614, 43.258313666947096]],
    [ "Enedis",[5.387350844331642, 43.260911297800334]],
    [ "Enedis",[5.3875234999999995, 43.256699000000005]],
    [ "Enedis",[1.5379258804177547, 43.62756912332462]],
    [ "Enedis",[1.541249687972508, 43.6290624041237]],
    [ "Enedis",[6.803204230769231, 47.5187636060606]],
    [ "Enedis",[6.806549305229796, 47.51974354315902]],
    [ "Enedis",[6.813154216635725, 47.52285659463797]],
    [ "Enedis",[6.809082251282052, 47.52233143076923]],
    [ "Enedis",[6.8167618057159025, 47.52215494889644]],
    [ "Enedis",[6.813863461693713, 47.517562322838465]],
    [ "Enedis",[2.850273688513738, 47.94730145448527]],
    [ "Enedis",[2.852698585209003, 47.94422802465167]],
    [ "Fontanus",[2.2700131273907105, 48.59847657001366]],
    [ "Enedis",[2.2575807145230975, 48.59904144167497]],
    [ "Enedis",[2.2729183593380613, 48.60138144365642]],
    [ "Enedis",[2.2838941410597426, 48.596772217669404]],
    [ "Grilleres",[2.265907038992688, 48.598042596533986]],
    [ "Bretionniere",[2.271925225473489, 48.59564850318987]],
    [ "Poste électrique de Rouillac",[-0.07450523234844574, 45.76399251553102]],
    [ "Sous-station RFF de Ruffec",[0.1816021664732383, 46.02639397765821]],
    [ "Poste électrique de Pons",[-0.5628975946633538, 45.57286003864064]],
    [ "Poste électrique de Pinier",[-0.6658941196674675, 45.75194048406175]],
    [ "Poste électrique de Tonnay-Charente",[-0.9230221150816423, 45.95050841902121]],
    [ "Sous-station RFF de la Planche",[0.28585429027357384, 46.44137684916095]],
    [ "Poste électrique Papault",[0.32310891672138164, 46.50148750283918]],
    [ "Poste électrique de Confolens",[0.6717369357584431, 45.99283205207441]],
    [ "RTE",[0.23708002061841021, 46.23345342030481]],
    [ "Poste électrique de Palant",[0.12674218539102827, 45.826820958696146]],
    [ "Poste électrique d'Aigre",[0.001738244318234016, 45.88744611420879]],
    [ "RTE",[0.21009284817648521, 46.37582185685742]],
    [ "Enedis",[-0.039201999999999994, 49.230969]],
    [ "Enedis",[-0.03915843073047826, 49.23267864399665]],
    [ "Enedis",[-0.04264467943456452, 49.22723597264022]],
    [ "Enedis",[-0.04829910256410295, 49.229101376068385]],
    [ "Enedis",[-0.04720939612448119, 49.233537883276036]],
    [ "Enedis",[-0.045003270424836525, 49.22763649346406]],
    [ "RTE",[-0.21027614965069957, 46.41647240858266]],
    [ "RTE",[-0.11819611355964799, 46.35254854130884]],
    [ "RTE",[-0.9857621741199895, 46.29946204532703]],
    [ "RTE",[-0.6644405408686296, 46.101969967423535]],
    [ "Poste électrique de Saint-Jean d'Angély",[-0.5275360559628395, 45.92857434365022]],
    [ "RTE",[-0.34671809096135564, 46.03333955429051]],
    [ "Poste électrique de Matha",[-0.32730715158058776, 45.87145878958564]],
    [ "Enedis",[5.583792501106249, 43.249396220600495]],
    [ "Poste électrique de Jarnac",[-0.17275571445801077, 45.69583575659127]],
    [ "Poste électrique de La Farradière",[-0.7046111800644383, 45.91775098781286]],
    [ "Poste électrique d'Arnoult",[-0.8474052213888738, 45.8199201393509]],
    [ "RTE",[-0.9899619730741016, 45.94929749296358]],
    [ "réséda",[6.236423048448995, 49.1352142288326]],
    [ "réséda",[6.233499825214507, 49.136478740147936]],
    [ "réséda",[6.235859220403528, 49.1345008948241]],
    [ "Poste électrique de Marennes",[-1.1228745973568628, 45.82644538707293]],
    [ "RTE",[-1.1469820933511667, 45.749515856144384]],
    [ "réséda",[6.225628071608673, 49.13011963741806]],
    [ "réséda",[6.232529999999999, 49.126773]],
    [ "réséda",[6.230742355242229, 49.129235367462044]],
    [ "réséda",[6.234924, 49.129512000000005]],
    [ "Enedis",[3.456015640692064, 43.506471780740235]],
    [ "Poste électrique de Saujon",[-0.9093260264620948, 45.67877376330046]],
    [ "Poste électrique Jonzac",[-0.4399660241103377, 45.436567378428805]],
    [ "Enedis",[5.6267771590544875, 43.29277124038461]],
    [ "Enedis",[2.3186653198653198, 48.675188971717176]],
    [ "Enedis",[2.3220266602898554, 48.67900718028985]],
    [ "Enedis",[2.334377175744372, 48.67281944371823]],
    [ "Enedis",[2.312724167402177, 48.6774915023046]],
    [ "Enedis",[2.3331171083953244, 48.67166292823238]],
    [ "Enedis",[2.316979978253814, 48.66665487925998]],
    [ "Enedis",[2.313267008910212, 48.666171292970844]],
    [ "Enedis",[2.3175585536480683, 48.67899316165952]],
    [ "Enedis",[2.3313580343229026, 48.66891895292858]],
    [ "Enedis",[2.319714659142784, 48.676282973455066]],
    [ "Enedis",[2.33577329578717, 48.676744208453016]],
    [ "Enedis",[2.3432562499999996, 48.760676999999994]],
    [ "Enedis",[2.3347409999999997, 48.76723125]],
    [ "Poste électrique de Mortain",[-0.9748164646225397, 48.66340726218952]],
    [ "ARDENELLES",[2.11635670781759, 48.5669961501072]],
    [ "Enedis",[2.1188688317564783, 48.58402343219525]],
    [ "Enedis",[2.1281910000000006, 48.5838415]],
    [ "Enedis",[2.1241700281690132, 48.584139458160735]],
    [ "Enedis",[0.16724666085271256, 44.4967450116279]],
    [ "Enedis",[-0.5815610852849442, 44.826785965849595]],
    [ "Enedis",[-0.5828764110761318, 44.82152985118576]],
    [ "RTE",[2.940410864239505, 48.59860421168326]],
    [ "Enedis",[1.4064563498964804, 45.96334882401656]],
    [ "Enedis",[1.3997395, 45.9562545]],
    [ "Enedis",[1.419211445269936, 45.95364446854878]],
    [ "Enedis",[1.4031861125541123, 45.9580852560297]],
    [ "Enedis",[1.3977606675485006, 45.945090485890645]],
    [ "Enedis",[1.3972230493096651, 45.95180359566075]],
    [ "Enedis",[-0.39606089105253994, 49.17564268873936]],
    [ "Enedis",[-0.4043158920863312, 49.18051878657075]],
    [ "Enedis",[-0.38368650000000004, 49.18202599999999]],
    [ "Montaigu",[-0.3595680000000006, 49.173608]],
    [ "Enedis",[-0.38337551202460846, 49.17525675922818]],
    [ "Enedis",[-0.40732507905739246, 49.181692672748]],
    [ "Enedis",[-0.40613572395833436, 49.188654270833325]],
    [ "Enedis",[-0.3833780086289549, 49.18024683061681]],
    [ "Enedis",[-0.40131350000000166, 49.1787485]],
    [ "Enedis",[-0.37040049999999947, 49.17648]],
    [ "Enedis",[-0.3992729999999992, 49.1823055]],
    [ "Enedis",[-0.4107223245763395, 49.186349163869295]],
    [ "Enedis",[-0.3748124999999994, 49.18996700000001]],
    [ "Enedis",[-0.40728816460339295, 49.18682254745529]],
    [ "Enedis",[-0.38727267073880134, 49.173222517510176]],
    [ "Enedis",[-0.4045925870646769, 49.18643867661692]],
    [ "Enedis",[-0.3908910238645109, 49.17657786314917]],
    [ "Enedis",[-0.3952511934089681, 49.18679658995138]],
    [ "Enedis",[-0.38877565264474184, 49.17246657767388]],
    [ "Enedis",[-0.37894640142650193, 49.17653819736989]],
    [ "Beau Site",[-0.3895988159392789, 49.173300518659076]],
    [ "Enedis",[-0.40054723955669247, 49.18883234953111]],
    [ "Enedis",[-0.39907182175622513, 49.18293033726518]],
    [ "Couture",[-0.39049558147439944, 49.17898859706929]],
    [ "Brebœuf",[-0.3912050500000008, 49.1784191]],
    [ "Enedis",[-0.40194347061882446, 49.17552611596463]],
    [ "Enedis",[-0.393947861906366, 49.17053427944313]],
    [ "Enedis",[-0.37731231412074934, 49.181255771666805]],
    [ "Enedis",[-0.38901226678989526, 49.16915504929144]],
    [ "SNCF Réseau",[0.30499173978594735, 46.52707918354015]],
    [ "Enedis",[-0.3748001193887298, 49.18529582999044]],
    [ "Enedis",[-0.39934949999999936, 49.18232]],
    [ "Enedis",[-0.37999909999999976, 49.17689229999999]],
    [ "Les Olympiades",[-0.3882934877192974, 49.177465880701746]],
    [ "Chaussée Ferrée",[-0.3567284624171808, 49.17506382773589]],
    [ "Enedis",[-0.3989966858322279, 49.182915160400995]],
    [ "Enedis",[-0.3849414005305038, 49.19403270733863]],
    [ "Enedis",[-0.3748455, 49.1922055]],
    [ "Enedis",[-0.4028948210216832, 49.186376988974644]],
    [ "Enedis",[-0.3966955792349727, 49.18621940437159]],
    [ "Philippon",[-0.382570169021666, 49.169878497510425]],
    [ "Enedis",[-0.3807768169014085, 49.17437096713615]],
    [ "Enedis",[-0.385237, 49.182143499999995]],
    [ "Enedis",[-0.38802399999999926, 49.18738449999999]],
    [ "Detolle",[-0.389649, 49.178574]],
    [ "Pétroles",[-0.3871441158782933, 49.18214115289172]],
    [ "Enedis",[-0.3870196913254073, 49.17126004623514]],
    [ "Enedis",[-0.5788112713286718, 44.82629076829837]],
    [ "Enedis",[-0.38109699999999996, 49.179604499999996]],
    [ "Enedis",[-0.39971269121339004, 49.18555122705718]],
    [ "Enedis",[-0.380007, 49.173682]],
    [ "Enedis",[-0.3647207804613296, 49.167621536047044]],
    [ "Enedis",[-0.37401984165417135, 49.18296597565198]],
    [ "Enedis",[-0.39966072231497035, 49.17299245130774]],
    [ "Place d'armes",[-0.35243400464787356, 49.1793323630393]],
    [ "Enedis",[-0.3745012510524302, 49.18863395369307]],
    [ "Enedis",[-0.3784798906666679, 49.18358944480001]],
    [ "Enedis",[-0.3741140682456928, 49.18068411361713]],
    [ "Enedis",[-0.3863285517631533, 49.191273531397314]],
    [ "Vaucelles",[-0.35504198159901595, 49.1757981005166]],
    [ "Enedis",[-0.38465135628672564, 49.18332872387778]],
    [ "Enedis",[-0.403386160736375, 49.18552754667831]],
    [ "Enedis",[-0.3729177898473545, 49.18680856798013]],
    [ "Enedis",[0.16602905156537778, 44.50232184714549]],
    [ "Enedis",[0.16610466666666665, 44.50239296]],
    [ "Enedis",[-0.5734781681468328, 44.82700604262877]],
    [ "Enedis",[3.400567, 43.61128375000001]],
    [ "Enedis",[6.890451750716829, 48.079672416628085]],
    [ "Enedis",[5.605474999999999, 43.18522]],
    [ "Enedis",[5.605893819721718, 43.18721722988506]],
    [ "Enedis",[5.589951703961862, 43.19259849835681]],
    [ "Enedis",[5.601767935839038, 43.189068692672684]],
    [ "Enedis",[5.599958391842253, 43.18768843149126]],
    [ "Enedis",[5.603396839211431, 43.187697361005604]],
    [ "Enedis",[5.612759986163522, 43.19221448574424]],
    [ "Enedis",[5.606553705179282, 43.18469969853918]],
    [ "Enedis",[5.60177362962963, 43.173678716775605]],
    [ "Enedis",[5.59131638418079, 43.18221511299436]],
    [ "Enedis",[5.60197131786372, 43.17062194254144]],
    [ "Enedis",[5.604446259857791, 43.16871682697695]],
    [ "Enedis",[5.626805802774165, 43.18912576853056]],
    [ "Enedis",[5.5999341192368846, 43.19123446740858]],
    [ "Enedis",[5.593989922077923, 43.178961363636375]],
    [ "Enedis",[5.597784806069227, 43.18163504978663]],
    [ "Enedis",[3.4158073499999997, 43.5849859]],
    [ "Enedis",[5.614823467157731, 43.199270051510304]],
    [ "Enedis",[5.604032457360868, 43.19419617168042]],
    [ "Enedis",[5.632587049999999, 43.198392]],
    [ "Enedis",[5.6309421, 43.199460949999995]],
    [ "Enedis",[5.624063583326208, 43.20292644997831]],
    [ "Enedis",[5.606669200000002, 43.1960027]],
    [ "Enedis",[5.6156881922159485, 43.19928826779207]],
    [ "Enedis",[5.605576116300795, 43.1947140191655]],
    [ "Enedis",[5.6076259, 43.202297550000004]],
    [ "Enedis",[5.60353788106434, 43.20092320415504]],
    [ "Enedis",[5.613947319114493, 43.20196752670178]],
    [ "Enedis",[5.609548453165983, 43.19634472883578]],
    [ "Enedis",[5.6134848705176665, 43.204017284700434]],
    [ "Enedis",[5.623482632651922, 43.201848697701905]],
    [ "Enedis",[2.7386391875563567, 48.00895677802826]],
    [ "Enedis",[2.7342105, 48.00622549999999]],
    [ "Enedis",[2.7037809999999998, 48.016800999999994]],
    [ "Enedis",[2.7409037833076795, 48.030328056439195]],
    [ "Enedis",[2.7356788597907324, 48.00876782929746]],
    [ "Enedis",[2.710744598378148, 48.00469588518993]],
    [ "Enedis",[2.7404198006379583, 48.03149352153108]],
    [ "Enedis",[2.7230671363513883, 48.01136972325519]],
    [ "Enedis",[2.735483667385832, 48.00979843976987]],
    [ "Enedis",[2.710483560547945, 48.00680417497717]],
    [ "Enedis",[2.72225834433657, 48.01350184012944]],
    [ "Enedis",[2.7224215000000003, 48.0113865]],
    [ "Enedis",[2.7429375269481637, 48.03545909371781]],
    [ "Enedis",[2.709229799817914, 48.01967945783544]],
    [ "Enedis",[2.7182394999999997, 48.004879]],
    [ "Enedis",[2.712563, 48.02004349999999]],
    [ "Enedis",[2.7080485, 48.005408749999994]],
    [ "Enedis",[2.722277811735942, 48.0072521809291]],
    [ "Enedis",[2.7400235000000013, 48.0263155]],
    [ "Enedis",[2.7298485546978557, 48.01168229746588]],
    [ "Enedis",[2.7346721012260433, 48.01450871202555]],
    [ "Auguin",[-0.5960515129078015, 44.82890366553192]],
    [ "Enedis",[2.7373425000000005, 48.02323249999999]],
    [ "Enedis",[2.7142859999999995, 48.011548499999996]],
    [ "Enedis",[-0.6065367780355763, 44.821833939675166]],
    [ "Enedis",[2.7081369999999994, 48.0097515]],
    [ "Enedis",[2.737411127463314, 48.015529266247384]],
    [ "Enedis",[2.721845999999999, 48.0132665]],
    [ "Enedis",[2.704816495100865, 48.00844483323728]],
    [ "Enedis",[2.7096370727883534, 48.01766748320269]],
    [ "Enedis",[2.734302548290831, 48.03056673223006]],
    [ "Les Camélias",[-0.5915412509662805, 44.82551643582567]],
    [ "Enedis",[2.731515528377805, 48.03328003896654]],
    [ "Haut Queyron",[-0.5921264661944623, 44.82662630864993]],
    [ "Enedis",[2.7355898108026104, 48.033664306933694]],
    [ "Enedis",[-0.5923337614084949, 44.825334620874784]],
    [ "Enedis",[2.733523938871139, 48.01688074930778]],
    [ "Enedis",[2.719153023430349, 48.019578601043385]],
    [ "Enedis",[2.717285147679325, 48.00242423603872]],
    [ "Enedis",[2.708324622950819, 48.01126833191661]],
    [ "Enedis",[2.732488129319955, 48.01553287430323]],
    [ "Enedis",[-2.2859773919016635, 47.919374154736076]],
    [ "Enedis",[-2.264393499999999, 47.93051450000001]],
    [ "Enedis",[-0.10359497370032737, 49.27897081194819]],
    [ "Enedis",[1.6169984891587164, 50.49424453396935]],
    [ "Enedis",[1.6229213792753985, 50.49718181108015]],
    [ "Enedis",[1.625447824398853, 50.507581554599604]],
    [ "Enedis",[1.5870823419434195, 50.51067083025831]],
    [ "Enedis",[1.5906994999999993, 50.519931]],
    [ "Enedis",[1.6008730976138832, 50.50089380043384]],
    [ "Enedis",[1.6009757978883863, 50.50220705731524]],
    [ "Enedis",[1.5803015, 50.516727]],
    [ "Enedis",[1.5928266665668958, 50.50521876723536]],
    [ "Enedis",[1.580576, 50.5084795]],
    [ "Enedis",[1.5958544302161655, 50.52106833677946]],
    [ "Enedis",[1.5868386711051932, 50.507694075898804]],
    [ "Enedis",[1.5830746948322307, 50.51628335292678]],
    [ "Enedis",[1.5810139245742085, 50.52037818920853]],
    [ "Enedis",[1.6082072254901958, 50.49305268627451]],
    [ "Enedis",[1.5862056460980034, 50.504774457955236]],
    [ "Enedis",[1.5983001444850382, 50.50956357245998]],
    [ "Enedis",[1.5804210000000003, 50.518234500000005]],
    [ "Enedis",[1.598389964099701, 50.52596667429316]],
    [ "Enedis",[1.5813572132505178, 50.51947889648033]],
    [ "Enedis",[1.6197941650103995, 50.51063725776135]],
    [ "Enedis",[1.6097138849259163, 50.500104553100876]],
    [ "Enedis",[1.5864152250872952, 50.50655655063121]],
    [ "Enedis",[1.5827853803289693, 50.52208625276938]],
    [ "Enedis",[1.5957168294045256, 50.52384164867935]],
    [ "Enedis",[1.59019638418079, 50.50756094350282]],
    [ "Enedis",[1.5879789058399791, 50.532146150158646]],
    [ "Enedis",[1.5949148391411476, 50.517332244280176]],
    [ "Enedis",[1.6030139999999997, 50.5086365]],
    [ "Enedis",[1.583791849423917, 50.52602516646802]],
    [ "Enedis",[1.6115517295859354, 50.49104316817025]],
    [ "Enedis",[1.5890203000000005, 50.53094160000001]],
    [ "Enedis",[1.6033805255570122, 50.51522787418086]],
    [ "Enedis",[1.5866811458016559, 50.52219090369996]],
    [ "Enedis",[1.5828103046471595, 50.52318693516926]],
    [ "Enedis",[1.5830550672199166, 50.521011496265565]],
    [ "Enedis",[1.5888436948532276, 50.52834483851125]],
    [ "Enedis",[1.5892092180942554, 50.50736837186103]],
    [ "Enedis",[1.6003475516549637, 50.51748863390169]],
    [ "Enedis",[3.4045993500000007, 43.57325345000001]],
    [ "Enedis",[3.3980634999999997, 43.573566750000005]],
    [ "Enedis",[3.39891375, 43.57651425]],
    [ "Enedis",[3.39301865, 43.57403945]],
    [ "Enedis",[3.811663584192441, 46.504175264604804]],
    [ "Enedis",[2.2573489631351107, 48.98740406356802]],
    [ "Enedis",[3.47441885, 43.67489575]],
    [ "Enedis",[3.4790495000000004, 43.67431225]],
    [ "Enedis",[4.0733646475707035, 43.64946226951898]],
    [ "Enedis",[4.071532, 43.651101000000004]],
    [ "Flacé",[4.8377482351319, 46.32487847883827]],
    [ "Enedis",[3.1263002499999986, 50.68345775]],
    [ "Enedis",[3.1206000000000005, 50.678967]],
    [ "Enedis",[3.125061670375516, 50.65221401046176]],
    [ "Enedis",[3.1356301566526255, 50.68694474785838]],
    [ "Enedis",[3.122895272908769, 50.66468714868079]],
    [ "Enedis",[5.642318100000001, 43.2073895]],
    [ "Enedis",[5.671501361770353, 43.23458317480997]],
    [ "Enedis",[5.661694975391956, 43.208086949625084]],
    [ "Enedis",[5.651758933107223, 43.22509000152463]],
    [ "Enedis",[1.6461521148036256, 50.522971012487425]],
    [ "Enedis",[1.6299606898210792, 50.52043602420689]],
    [ "Enedis",[1.6379133296908315, 50.518285623489696]],
    [ "Enedis",[1.6301396191419137, 50.52594670627063]],
    [ "156561591",[1.6475843753964285, 50.517005720413955]],
    [ "Enedis",[1.6327425, 50.5189075]],
    [ "Enedis",[1.6395223874636335, 50.530862297452174]],
    [ "Enedis",[1.6342119999999998, 50.518397000000014]],
    [ "Enedis",[1.6423635, 50.51337699999999]],
    [ "Enedis",[1.6367715428221858, 50.5270071673464]],
    [ "Enedis",[1.6313166612466123, 50.5186859701897]],
    [ "Enedis",[1.6478680833944732, 50.51438531450233]],
    [ "Enedis",[1.6787462857142854, 50.5264613452381]],
    [ "Enedis",[1.6271368369800907, 50.52357833806427]],
    [ "Enedis",[1.6404545, 50.52094950000001]],
    [ "Enedis",[1.6570374190476194, 50.5093514952381]],
    [ "Enedis",[1.6528260613026817, 50.51666632375479]],
    [ "Enedis",[1.6455193774462624, 50.52226323307668]],
    [ "Enedis",[1.6582156149366218, 50.52062588215142]],
    [ "Enedis",[1.6380485629096517, 50.51274119988863]],
    [ "Enedis",[1.6449264954177898, 50.51592498706199]],
    [ "Enedis",[1.6556070656648987, 50.52368780805344]],
    [ "Enedis",[1.653759432693969, 50.514983709348535]],
    [ "Enedis",[1.637086967443025, 50.51728437966441]],
    [ "Enedis",[1.6546993431553112, 50.51809201043586]],
    [ "Enedis",[1.633025928526249, 50.52639875913978]],
    [ "Enedis",[1.6385726223862245, 50.52140630873309]],
    [ "Enedis",[1.6422974188646478, 50.523877681645494]],
    [ "Enedis",[1.6388085, 50.52725990579711]],
    [ "Enedis",[1.643070116959064, 50.52050519298245]],
    [ "Enedis",[1.632542499999999, 50.529286500000005]],
    [ "Enedis",[1.64030092327044, 50.52239589559748]],
    [ "Enedis",[1.6468243412541252, 50.5211508409241]],
    [ "Enedis",[1.6363133164556964, 50.52243232383966]],
    [ "Enedis",[1.650546904340965, 50.51921175791944]],
    [ "Enedis",[1.6498201401431534, 50.51876268027962]],
    [ "Enedis",[1.6415834194493255, 50.51677248506151]],
    [ "Enedis",[1.630631913336198, 50.517956154848875]],
    [ "Enedis",[1.6481505545980188, 50.51863108466836]],
    [ "Enedis",[0.022598209951764262, 48.39191628078193]],
    [ "Enedis",[0.024459469387755096, 48.39979833560092]],
    [ "Enedis",[0.024390324444444667, 48.39811204444444]],
    [ "Enedis",[5.562829208903191, 43.396248418342715]],
    [ "Enedis",[0.0339875721495749, 48.43429129823353]],
    [ "Enedis",[0.027921245933277855, 48.42922525282603]],
    [ "Enedis",[0.048862121212120906, 48.4354497070707]],
    [ "Enedis",[0.054621475049900246, 48.43112659880241]],
    [ "Enedis",[0.049611399207528016, 48.43706427835562]],
    [ "Enedis",[0.05664377627520757, 48.43656653238434]],
    [ "Enedis",[0.05642222622735313, 48.43219061851121]],
    [ "Enedis",[0.05409638814464531, 48.43352519271517]],
    [ "Enedis",[0.060358787980590384, 48.42993356550951]],
    [ "Enedis",[0.06056121250000068, 48.43373894166666]],
    [ "Enedis",[0.03263849999999935, 48.433147000000005]],
    [ "Enedis",[0.05972077892708649, 48.430816560288]],
    [ "Enedis",[0.04981889434523809, 48.43893545089285]],
    [ "réséda",[6.329571490891658, 49.0966800137424]],
    [ "réséda",[6.329364546472563, 49.09842120455394]],
    [ "réséda",[6.326138748208549, 49.096415149987656]],
    [ "Enedis",[6.8796919999999995, 47.663125499999985]],
    [ "Enedis",[6.873283000000002, 47.657514]],
    [ "Enedis",[6.8872447902962435, 47.66378965407044]],
    [ "Enedis",[6.8668295, 47.656976500000006]],
    [ "Enedis",[6.8757275, 47.666757]],
    [ "Enedis",[6.879475666666665, 47.65982666666666]],
    [ "Enedis",[6.875520500000001, 47.663034999999994]],
    [ "Enedis",[6.873473199999999, 47.65933602222223]],
    [ "Enedis",[6.8705825, 47.6611595]],
    [ "Enedis",[6.85880183424408, 47.6617759708561]],
    [ "Enedis",[6.867664499999998, 47.659594500000004]],
    [ "Enedis",[6.863360687447345, 47.65630093765796]],
    [ "156685897",[4.356493909267346, 48.964150655992235]],
    [ "Enedis",[4.366811677640037, 48.96230862866569]],
    [ "156693337",[4.3642654565623875, 48.9651851509529]],
    [ "Poste électrique d'Isle-Jourdain (Millac)",[0.6819557523286247, 46.223863401218054]],
    [ "156713507",[4.347013426845097, 48.982138203960986]],
    [ "Les Ouches",[4.470701499999998, 48.97628083333333]],
    [ "Enedis",[4.466559, 48.9777995]],
    [ "Enedis",[4.476486808580858, 48.9778377590759]],
    [ "Enedis",[4.470634550000001, 48.98213795000001]],
    [ "Les moissons",[4.467894988888889, 48.97527280303031]],
    [ "Enedis",[4.47391875, 48.98143025]],
    [ "basilique",[4.4697601069182396, 48.97803259119496]],
    [ "Enedis",[4.3572440710988, 48.92427468605725]],
    [ "Le voyeur",[4.352921030273357, 48.92281060781994]],
    [ "Poste de la Mothe-Achard",[-1.6504932394061365, 46.62617846804194]],
    [ "Enedis",[0.0565728256802721, 48.46901301870748]],
    [ "Enedis",[0.06082334342822457, 48.45595679002625]],
    [ "Enedis",[0.05460389310009744, 48.44776781632654]],
    [ "Enedis",[0.04571419167282584, 48.44923447844297]],
    [ "Enedis",[0.0488389527559053, 48.444969808398945]],
    [ "Enedis",[0.07972170786948178, 48.45364844555343]],
    [ "Enedis",[0.07108283999999998, 48.44333561333334]],
    [ "Enedis",[0.07494901807723893, 48.44294957080252]],
    [ "Enedis",[0.07253149999999889, 48.4420875]],
    [ "Enedis",[0.07639641622760762, 48.452646888654726]],
    [ "Enedis",[0.07507095664181192, 48.450137601767466]],
    [ "Enedis",[0.06666109779482238, 48.443518208373284]],
    [ "Enedis",[0.08596564525993923, 48.45381791568371]],
    [ "Enedis",[0.08340736571428543, 48.452796188571426]],
    [ "Enedis",[0.07354765689865687, 48.447445822954826]],
    [ "Enedis",[0.07494768338558054, 48.4532120292581]],
    [ "Enedis",[0.07760900000000001, 48.45203142635659]],
    [ "Enedis",[0.07876956287425153, 48.4474234253992]],
    [ "Enedis",[0.0746191827956987, 48.440731978494625]],
    [ "Enedis",[0.08283289378757616, 48.448140042752165]],
    [ "Enedis",[0.06052107273024473, 48.44485989221215]],
    [ "Enedis",[0.12416111444815274, 48.45095567630701]],
    [ "Enedis",[0.12989569729453804, 48.44333303981624]],
    [ "Enedis",[0.12525446861184844, 48.44443216003536]],
    [ "Enedis",[0.11627142994189993, 48.44532833563032]],
    [ "Enedis",[0.12598118878288522, 48.440681532523854]],
    [ "Enedis",[0.10750330718954253, 48.453377137254904]],
    [ "Enedis",[0.095774, 48.4508915]],
    [ "Enedis",[0.1100344011391382, 48.462499201699664]],
    [ "Enedis",[0.0891286216450215, 48.45555210822511]],
    [ "Enedis",[0.11192545636732608, 48.463186692541846]],
    [ "Enedis",[0.11593725478348438, 48.46305044712992]],
    [ "Enedis",[3.4895686833149706, 47.34764345525768]],
    [ "Enedis",[2.54611341649324, 49.31466950034606]],
    [ "SNCF Réseau",[2.595035749596327, 49.308208137575825]],
    [ "Enedis",[2.4859512560587045, 49.26939403466934]],
    [ "Poste électrique de Mâcon",[4.7732279229606265, 46.28807088475626]],
    [ "RTE",[4.791251891933012, 46.26107577465248]],
    [ "Enedis",[0.10219857514929484, 47.97168080743397]],
    [ "Enedis",[0.09659984999999997, 47.974103299999996]],
    [ "Enedis",[0.10246748479294865, 47.972806914731414]],
    [ "Enedis",[0.10005171707478965, 47.973962550357896]],
    [ "Enedis",[0.09633640697337735, 47.96856602855305]],
    [ "Enedis",[2.327983, 48.7662467]],
    [ "Enedis",[4.409984126371007, 46.79027229995231]],
    [ "Enedis",[4.41932670250896, 46.78530384587813]],
    [ "Enedis",[2.9653105473653474, 49.39786330427952]],
    [ "Enedis",[2.968755742445587, 49.39641171961767]],
    [ "Enedis",[2.9691026870235278, 49.401493947957114]],
    [ "Enedis",[0.47562082312925175, 47.862078285714276]],
    [ "Maison de retraite",[0.47303375, 47.85979675]],
    [ "Enedis",[0.46378074999999996, 47.86820475]],
    [ "Enedis",[0.4637322364451693, 47.86480190284005]],
    [ "Bourg",[0.4700765987582465, 47.86526668296469]],
    [ "La Prairie",[0.46328785, 47.86976309999999]],
    [ "Enedis",[3.001838249185423, 49.39524015947232]],
    [ "Poste électrqiue de Grand Lieu",[-1.6115240243498141, 47.050551231357424]],
    [ "Enedis",[0.08683043452380922, 48.39115548809524]],
    [ "Enedis",[0.0844835, 48.3975385]],
    [ "Enedis",[0.09509857004830949, 48.39921225120772]],
    [ "Enedis",[0.08626095326142784, 48.39301330362951]],
    [ "157059796",[0.089453, 48.4071495]],
    [ "Enedis",[0.08928484597236316, 48.40576796798113]],
    [ "Enedis",[0.0917401628352486, 48.39859482136015]],
    [ "Enedis",[0.08627131959437351, 48.40047179718679]],
    [ "Enedis",[0.08749490725126473, 48.396835155143336]],
    [ "Enedis",[0.11459700000000052, 48.4154785]],
    [ "Enedis",[0.10502529418742587, 48.409904593515215]],
    [ "Enedis",[0.11073600000000014, 48.42090650000001]],
    [ "Enedis",[0.12690049625697564, 48.418687664897234]],
    [ "Enedis",[0.095809, 48.405473]],
    [ "Enedis",[0.09800972653236151, 48.407670607515364]],
    [ "Enedis",[0.10748746423992052, 48.41232961588812]],
    [ "Enedis",[0.12460673469387754, 48.430773387755096]],
    [ "Enedis",[5.816534259891538, 46.15745942713818]],
    [ "SNCF Réseau",[4.715899677945485, 46.04948830328914]],
    [ "SNCF Réseau",[4.730262834557159, 46.120256536077044]],
    [ "RTE",[4.729774356469326, 46.11986922459791]],
    [ "RTE",[4.719733715882584, 46.05099772446007]],
    [ "SNCF Réseau",[4.756726311779194, 46.194071464672334]],
    [ "Senozan (SNCF)",[4.871732985979124, 46.394834242992694]],
    [ "Fleurville (SNCF)",[4.89090256677054, 46.46267940317262]],
    [ "Le Villars (SNCF)",[4.934835051172173, 46.52133135778696]],
    [ "RTE",[4.497038018668849, 46.02945624417741]],
    [ "RTE",[4.291506424529736, 46.09651189563411]],
    [ "Enedis",[4.2885029258816125, 46.03578168678401]],
    [ "RTE",[4.1632452032056655, 46.155689555409815]],
    [ "Enedis",[-1.5311737073974665, 43.49931063038401]],
    [ "Enedis",[-1.502683760923488, 43.50861954092731]],
    [ "Enedis",[-1.5211254270005754, 43.51029144670407]],
    [ "Enedis",[-1.503295450000001, 43.51816915]],
    [ "Enedis",[-1.513186005403868, 43.50249366851535]],
    [ "Enedis",[-1.5148226222188894, 43.497531074696276]],
    [ "Enedis",[-1.5031305744931631, 43.50374216808109]],
    [ "Enedis",[-1.5291223482849603, 43.50575175637642]],
    [ "Enedis",[-1.5178455, 43.51394225]],
    [ "Enedis",[-1.50114225, 43.5100415]],
    [ "Enedis",[-1.53460625, 43.498044900000004]],
    [ "Enedis",[-1.51909779466119, 43.49918214510609]],
    [ "Enedis",[-1.5240381159974505, 43.51621739643085]],
    [ "Enedis",[-1.520730024417671, 43.496972728032134]],
    [ "Enedis",[-1.5028899645625688, 43.505825191583604]],
    [ "Enedis",[-1.4975755, 43.50787725000001]],
    [ "Enedis",[-1.5183885810957631, 43.5019112536603]],
    [ "Enedis",[-1.5282088142903123, 43.49878715052033]],
    [ "Enedis",[-1.4967000000000004, 43.50594125]],
    [ "Enedis",[-1.5325658916055414, 43.50230854197228]],
    [ "Enedis",[-1.53186375, 43.496584500000004]],
    [ "Enedis",[-1.5260347251908395, 43.50072926615775]],
    [ "Enedis",[-1.5344665040443324, 43.49632423398147]],
    [ "Enedis",[-1.50987965, 43.5034002]],
    [ "Enedis",[-1.5161947500000004, 43.5151975]],
    [ "Enedis",[-1.5299226760710556, 43.501325846394984]],
    [ "Enedis",[-1.5023611887615933, 43.51077745662848]],
    [ "Enedis",[-1.4984442500000001, 43.503702499999996]],
    [ "Enedis",[-1.5245990801120453, 43.5081763114846]],
    [ "Enedis",[-1.5041134999999997, 43.512950000000004]],
    [ "Enedis",[-1.5154758631452578, 43.50149658023209]],
    [ "Enedis",[-1.505593999999999, 43.502350250000006]],
    [ "Enedis",[-1.4930341228070176, 43.501532403508776]],
    [ "Enedis",[-1.5167124142868385, 43.496600789842326]],
    [ "Enedis",[-1.5377519566518048, 43.49916589762407]],
    [ "Enedis",[-1.5337858571207548, 43.506504951961]],
    [ "Enedis",[-1.539186627576248, 43.496597595344674]],
    [ "Enedis",[-1.5359396233766232, 43.49045170995672]],
    [ "Enedis",[-1.5373281675874764, 43.492120237569054]],
    [ "Enedis",[-1.5407745, 43.4899415]],
    [ "Enedis",[-1.5348125000000001, 43.4939775]],
    [ "Enedis",[-1.5332525103684034, 43.49184659152072]],
    [ "Les Minimes",[4.821889858042471, 45.91768257417607]],
    [ "Enedis",[1.5545668440084406, 48.90235779097334]],
    [ "RTE",[3.744914111890442, 46.62701124019688]],
    [ "Gueugnon",[4.067839636667205, 46.60385857456056]],
    [ "RTE",[3.7384661416226734, 46.62208002542516]],
    [ "Charolles",[4.272429270107707, 46.44434288284163]],
    [ "RTE",[4.00380994566612, 46.48990467528289]],
    [ "Paray-le-Monial",[4.1075184848712825, 46.46052847937305]],
    [ "La Clayette",[4.299586373028582, 46.29663320677215]],
    [ "Enedis",[-1.5236763268733853, 43.48771310335918]],
    [ "Enedis",[-1.512416839257504, 43.482311924960506]],
    [ "Enedis",[-1.5245543133159272, 43.48409972724108]],
    [ "Enedis",[-1.52498, 43.48599850000001]],
    [ "Enedis",[-1.5009464999999993, 43.48447199999999]],
    [ "Enedis",[-1.520740367420581, 43.48541954964882]],
    [ "Enedis",[-1.530773672282097, 43.49210409506605]],
    [ "Enedis",[-1.507987761727767, 43.4870438695824]],
    [ "Enedis",[-1.5290991500000002, 43.4915369]],
    [ "Enedis",[-1.5276123568193452, 43.48783672391702]],
    [ "Enedis",[-1.503860884127942, 43.48679886411185]],
    [ "Enedis",[-1.51789425, 43.482195250000004]],
    [ "Enedis",[-1.5201745002611928, 43.48410499926539]],
    [ "Enedis",[-1.5290688744588743, 43.484735683982684]],
    [ "Enedis",[-1.53251230295243, 43.48295686317171]],
    [ "Enedis",[-1.5272123708990735, 43.48835608339595]],
    [ "Enedis",[-1.4951230032069975, 43.47228221729836]],
    [ "Enedis",[-1.5008970551248921, 43.476331842807916]],
    [ "Enedis",[-1.505429291956934, 43.47805628351277]],
    [ "Enedis",[-1.5166837879771566, 43.47463447159603]],
    [ "Enedis",[-1.5220456499999997, 43.4761346]],
    [ "Enedis",[-1.514756471435095, 43.47984948534649]],
    [ "Enedis",[-1.4945061272838933, 43.47507271625979]],
    [ "Enedis",[-1.5306179999999998, 43.47658]],
    [ "Enedis",[-1.514382328843276, 43.47318158755611]],
    [ "Enedis",[-1.497740982608696, 43.47632131884058]],
    [ "Enedis",[-1.5098838505315826, 43.47443970564937]],
    [ "Enedis",[-1.5115402082890552, 43.476730667375136]],
    [ "Enedis",[-1.51938675, 43.4791995]],
    [ "Enedis",[-1.5140085508395522, 43.47697432820274]],
    [ "Enedis",[-1.4985753965744404, 43.47521003952569]],
    [ "Enedis",[-1.4919909450028883, 43.474776886976976]],
    [ "Enedis",[-1.5104759820242286, 43.47803248300117]],
    [ "Enedis",[-1.523665683195116, 43.47988340175075]],
    [ "Enedis",[-1.4977997950343371, 43.47359841679873]],
    [ "Enedis",[-1.4930921999999989, 43.473091950000004]],
    [ "Enedis",[-1.4987180518256773, 43.47407560306243]],
    [ "Enedis",[-1.528609795918367, 43.48029386362164]],
    [ "Épinac",[4.504764487763051, 46.97751229654349]],
    [ "DUSSART",[-1.4989071522088353, 43.46440724685408]],
    [ "Enedis",[-1.4985778731841382, 43.46724179151943]],
    [ "RTE",[1.5331231255048345, 44.16243093032409]],
    [ "RTE",[1.5306583375409233, 44.16313346507021]],
    [ "RTE",[3.423801081559808, 46.25186609975724]],
    [ "RTE",[4.247283346717759, 45.63882044807401]],
    [ "Poste électrique de Golfech 2",[0.8433221757497931, 44.10646594996525]],
    [ "Poste électrique de Golfech 1",[0.8451513755738618, 44.10579793473671]],
    [ "Marcigny",[4.045651409071093, 46.2714734559146]],
    [ "RTE",[4.449789217886706, 46.79036964022167]],
    [ "Breuil",[4.450236304037829, 46.788631978647636]],
    [ "Enedis",[6.628834636904763, 45.869709071428574]],
    [ "berthelets",[6.632670500000001, 45.870180500000004]],
    [ "Cornery",[6.634776014294998, 45.86647066536712]],
    [ "Le pravis",[6.6454496580172995, 45.87273648968729]],
    [ "Enedis",[6.624915048236141, 45.87850321454284]],
    [ "Enedis",[6.6313608557411845, 45.871674553434104]],
    [ "Enedis",[6.617596347150259, 45.87506996718481]],
    [ "Enedis",[4.650514382281553, 45.01400153341424]],
    [ "Enedis",[4.6298639, 45.00878425]],
    [ "Les Jonquets",[-1.554464, 48.97994129999999]],
    [ "Enedis",[5.894035997278233, 48.649453498958586]],
    [ "Poste électrique de la Ferrande",[1.0399257268010882, 46.63137603989646]],
    [ "157782727",[5.506236926145024, 43.30941395381011]],
    [ "Enedis",[5.9095485000000005, 48.54918499999999]],
    [ "RTE",[6.805284564919748, 49.139700453116724]],
    [ "Enedis",[5.434795828006088, 43.31893548513978]],
    [ "Enedis",[5.434215170815032, 43.32265201317716]],
    [ "Enedis",[5.436428379888268, 43.31867810242086]],
    [ "Enedis",[5.431662095202469, 43.32126730774544]],
    [ "Enedis",[5.430047904615385, 43.321296691623935]],
    [ "Enedis",[-0.02404424399164052, 48.37085903761756]],
    [ "Enedis",[-0.09522911314984685, 48.35648861569826]],
    [ "Enedis",[-0.1111562698412694, 48.36414474603174]],
    [ "Enedis",[-0.11954116751269035, 48.405524023688656]],
    [ "Enedis",[-0.16791379674796722, 48.40803572357723]],
    [ "Enedis",[-0.09291963189355808, 48.399163851621566]],
    [ "Enedis",[-0.10077669531008393, 48.39911699635229]],
    [ "Enedis",[-0.10651738138138181, 48.400632476810145]],
    [ "Enedis",[-0.10117371901211544, 48.39610377850264]],
    [ "Enedis",[-0.06832774193548405, 48.409962502508954]],
    [ "Poste électrique Primevères",[2.5137213155644917, 48.94397252856451]],
    [ "Enedis",[6.049403990920398, 47.33337350014215]],
    [ "Enedis",[2.2668585000000006, 47.858639999999994]],
    [ "Enedis",[2.274613383006537, 47.86071054967321]],
    [ "Enedis",[2.2637616806245573, 47.86630675727466]],
    [ "Enedis",[2.255031332426416, 47.86493317247919]],
    [ "Enedis",[4.778816982466171, 45.38581265141987]],
    [ "Enedis",[4.770487884678209, 45.403960288250026]],
    [ "Enedis",[4.787022352905199, 45.38534645535167]],
    [ "Enedis",[4.790731071987101, 45.382955744296666]],
    [ "Enedis",[4.788649666666665, 45.37984033333333]],
    [ "Enedis",[-0.6051347499999998, 44.6571461]],
    [ "Poste électrique de Forbach",[6.881930919281084, 49.180545244049085]],
    [ "Enedis",[-0.60037113715847, 44.65056999672131]],
    [ "Enedis",[-0.6005787111941728, 44.65304808054437]],
    [ "Mas de Laure 13097",[4.818638176241456, 43.642155611851315]],
    [ "Enedis",[4.766100468053217, 43.634467451286305]],
    [ "Ontario",[4.8220221755287085, 43.634816464594316]],
    [ "Enedis",[0.6512666871898202, 42.97411732764282]],
    [ "Enedis",[5.8370126, 45.901844700000005]],
    [ "Enedis",[5.8521482581902955, 45.86994365011552]],
    [ "Enedis",[5.8344672985102415, 45.902271279702056]],
    [ "Enedis",[5.850490738116955, 45.89626812450592]],
    [ "RTE",[2.6636647697199747, 49.36453501406377]],
    [ "Enedis",[4.810113, 45.4567085]],
    [ "Enedis",[5.000576510617328, 47.34423699862071]],
    [ "Enedis",[5.0045889175265605, 47.34511440404458]],
    [ "Enedis",[4.9908486871908275, 47.357519608134886]],
    [ "Enedis",[5.003101493229958, 47.344808490716524]],
    [ "Enedis",[4.975608193965128, 47.339071420179465]],
    [ "Enedis",[4.770966333259044, 45.44933214904537]],
    [ "Enedis",[4.770548983471075, 45.44061241689623]],
    [ "Enedis",[4.769272099999999, 45.44506855]],
    [ "Enedis",[4.7746899742569875, 45.45154731355651]],
    [ "Enedis",[4.774247835847357, 45.441603717382385]],
    [ "Enedis",[4.7745795, 45.4454505]],
    [ "Enedis",[4.7722052155189925, 45.438036052813324]],
    [ "Enedis",[4.7963892237366625, 45.451127136807244]],
    [ "Village",[5.829279900000001, 48.625855800000004]],
    [ "Enedis",[6.2836795, 46.20594645]],
    [ "Gromelle",[6.26693425, 46.1997267]],
    [ "Enedis",[6.2520365, 46.95371975]],
    [ "Enedis",[6.10836425, 49.360831950000005]],
    [ "Enedis",[5.0631585, 47.231841249999995]],
    [ "Enedis",[4.75686945, 45.422939799999995]],
    [ "Enedis",[4.7596134058685955, 45.41987408207173]],
    [ "Enedis",[4.759622537144585, 45.417477224379915]],
    [ "Enedis",[5.0603345, 47.27103049999999]],
    [ "Velars",[4.908223988575802, 47.31880049258805]],
    [ "4 saisons",[4.9165414999999975, 47.32015299999999]],
    [ "Vivaldi",[4.912727943271122, 47.319929338181645]],
    [ "Enedis",[4.913389881049562, 47.31613302798833]],
    [ "Enedis",[4.988988799999999, 47.2727584]],
    [ "Enedis",[5.008138233176346, 47.279135969335414]],
    [ "Enedis",[4.995771810971974, 47.27854603935599]],
    [ "Hameau",[4.987264102059086, 47.27559296777081]],
    [ "Enedis",[4.02927181168831, 47.216368823593065]],
    [ "Enedis",[1.5785600974930365, 50.46330580965645]],
    [ "Enedis",[1.5879965, 50.46300050000001]],
    [ "Enedis",[4.795584841994085, 45.36978719687367]],
    [ "Enedis",[4.794289330178377, 45.37205083485014]],
    [ "Enedis",[4.7983898802395215, 45.36915066367265]],
    [ "Enedis",[4.797265233879782, 45.36836906885245]],
    [ "Enedis",[4.800667437499999, 45.37044561458334]],
    [ "Enedis",[4.798037770401106, 45.376152941908714]],
    [ "Enedis",[4.803317250062799, 45.37810914757598]],
    [ "Enedis",[4.795302882726179, 45.36833270801882]],
    [ "Enedis",[4.795603514659965, 45.37551283085827]],
    [ "RTE",[0.9020999577502993, 43.113273578723586]],
    [ "Enedis",[4.800797736733288, 45.371682702963476]],
    [ "Enedis",[-1.5008642595771944, 43.5403873810805]],
    [ "Enedis",[-1.47588280239521, 43.55134605988023]],
    [ "Enedis",[-1.4528565622058727, 43.533509283820514]],
    [ "Enedis",[-1.4496549999999995, 43.524675200000004]],
    [ "Enedis",[-1.487441023941068, 43.542344852670354]],
    [ "Enedis",[-1.497222112793734, 43.53706663724977]],
    [ "Enedis",[-1.4217455, 43.5070125]],
    [ "Enedis",[-1.4643419411764707, 43.552480745098045]],
    [ "Enedis",[-1.449487869417158, 43.53483269711854]],
    [ "Enedis",[-1.4361395000000001, 43.514457]],
    [ "Enedis",[-1.4467615, 43.529870499999994]],
    [ "Enedis",[-1.4498690500000004, 43.5310194]],
    [ "Enedis",[-1.494512187362687, 43.53417402876016]],
    [ "Enedis",[-1.4547999, 43.53530500000001]],
    [ "Enedis",[-1.483434115226338, 43.5422012510288]],
    [ "Enedis",[-1.45439295, 43.53127014999999]],
    [ "Enedis",[-1.4618025, 43.53733950000001]],
    [ "Enedis",[-1.493777861416279, 43.53102397775961]],
    [ "Enedis",[-1.4847027335500578, 43.54998564788393]],
    [ "Enedis",[-1.4634926999999998, 43.5426878]],
    [ "Enedis",[-1.4798226500000002, 43.549998949999996]],
    [ "Enedis",[-1.4951502499999998, 43.528813]],
    [ "Enedis",[-1.4517811705912727, 43.524169151407165]],
    [ "Enedis",[-1.4500314499999998, 43.5399764]],
    [ "Enedis",[-1.4666347, 43.5411484]],
    [ "Enedis",[-1.4341566999999997, 43.5216181]],
    [ "Enedis",[-1.4899540073313784, 43.53780133162268]],
    [ "Enedis",[-1.4770887500000003, 43.54074925]],
    [ "Enedis",[-1.47028, 43.53772449999999]],
    [ "Enedis",[-1.469242092113983, 43.54026221404904]],
    [ "Enedis",[-1.4622988188018895, 43.542839525626256]],
    [ "Enedis",[-1.4636078495754123, 43.5275602314328]],
    [ "Enedis",[-1.4843140000000001, 43.54376666666668]],
    [ "Enedis",[-1.4590147999999992, 43.536418299999994]],
    [ "Enedis",[-1.4672474999999998, 43.537372000000005]],
    [ "Enedis",[-1.4523906400534785, 43.538729222656556]],
    [ "Enedis",[-1.4290986938471184, 43.51662753305206]],
    [ "Enedis",[-1.4893730329411765, 43.536394328627445]],
    [ "Enedis",[-1.4497012637703905, 43.53799178094355]],
    [ "Enedis",[-1.4983564999999999, 43.5411875]],
    [ "Enedis",[-1.4741180291005287, 43.55022432671958]],
    [ "Enedis",[-1.4547821804853884, 43.52512571475979]],
    [ "Enedis",[-1.4545222281133057, 43.538705904945296]],
    [ "Enedis",[-1.442934334456133, 43.527225202971096]],
    [ "Enedis",[-1.4961869499999998, 43.5358813]],
    [ "Enedis",[-1.4565645, 43.55022484999999]],
    [ "Enedis",[-1.4529735519327567, 43.543197298779255]],
    [ "Enedis",[-1.461822367371645, 43.54495964188563]],
    [ "RTE",[1.3976103292143043, 46.89149148339134]],
    [ "Enedis",[4.8179605, 45.425553]],
    [ "Enedis",[4.814703909090909, 45.414670969696964]],
    [ "Enedis",[4.8173917484276725, 45.42879416981132]],
    [ "Enedis",[1.4881532331804284, 46.96337010168196]],
    [ "Enedis",[1.8560334481099061, 49.16112481060798]],
    [ "RTE",[6.853907933162539, 44.0672351188016]],
    [ "Enedis",[1.5958678174997567, 50.47025947276625]],
    [ "Enedis",[1.5728600000000004, 50.461393]],
    [ "Enedis",[1.5763893650838252, 50.460631289032506]],
    [ "Enedis",[1.5808220250600753, 50.46082199244765]],
    [ "réséda",[6.138809302827764, 49.27319668671807]],
    [ "réséda",[6.125310500000001, 49.269802]],
    [ "réséda",[6.11916344530644, 49.27447683682441]],
    [ "réséda",[6.121345088247092, 49.26809178700361]],
    [ "réséda",[6.1416056806935515, 49.27201530605883]],
    [ "réséda",[6.128004999999999, 49.27207300000001]],
    [ "réséda",[6.149675000000001, 49.27492649999999]],
    [ "réséda",[6.144761912457913, 49.27402820202021]],
    [ "réséda",[6.123966643979056, 49.273828823734725]],
    [ "Golf 1989001",[6.0199897, 48.5507876]],
    [ "Enedis",[6.166470935330673, 49.25905823852479]],
    [ "Enedis",[4.052324733313154, 49.069590498298254]],
    [ "Poste électrique de Melle",[-0.14191420430749924, 46.21375301197566]],
    [ "Enedis",[1.588347999999999, 50.461293]],
    [ "Enedis",[1.5850155000000006, 50.4615695]],
    [ "Enedis",[1.5774018164642376, 50.45957396761134]],
    [ "Enedis",[1.58300820890937, 50.46029195391705]],
    [ "Enedis",[1.5926999105691062, 50.46115875609756]],
    [ "Enedis",[1.609574615274803, 50.45637369878657]],
    [ "Enedis",[1.5998998739125647, 50.4592216382557]],
    [ "Enedis",[6.145537821312418, 49.23665263847968]],
    [ "Enedis",[5.635511465431597, 46.272789147047405]],
    [ "Enedis",[5.6311560090123995, 46.269686321605455]],
    [ "Enedis",[5.625846742072714, 46.26520656793089]],
    [ "Enedis",[5.633999043545938, 46.26368513450307]],
    [ "Enedis",[5.623292265718258, 46.26845224591237]],
    [ "Enedis",[5.6259594353242015, 46.26894947579016]],
    [ "Enedis",[5.6228814000000025, 46.26752960000001]],
    [ "Enedis",[5.629331650598654, 46.27149079947452]],
    [ "Enedis",[5.6673429, 46.24011585]],
    [ "Enedis",[5.671045950000001, 46.23701705]],
    [ "Enedis",[5.6609215, 46.246562899999994]],
    [ "Enedis",[5.667098950000001, 46.24205175000001]],
    [ "Enedis",[5.6539283, 46.2675208]],
    [ "Enedis",[5.653316797595855, 46.270374532994815]],
    [ "Enedis",[5.658085950000001, 46.27133860000001]],
    [ "Enedis",[5.659639165158696, 46.26677582489567]],
    [ "Alplast",[5.656269042580314, 46.26794883372852]],
    [ "Mercure",[5.659582750823251, 46.27065573360829]],
    [ "Enedis",[5.660677999999998, 46.268655499999994]],
    [ "Enedis",[5.6622975, 46.2684645]],
    [ "Enedis",[5.661063550000001, 46.26439035]],
    [ "Enedis",[5.6609655, 46.26594474999999]],
    [ "Enedis",[5.663877421606949, 46.265838244733985]],
    [ "Enedis",[5.6633574, 46.26713325]],
    [ "Enedis",[5.649058800000001, 46.2658253]],
    [ "Enedis",[5.652863750000001, 46.2655215]],
    [ "Enedis",[5.6465029, 46.2667039]],
    [ "Enedis",[5.65703465, 46.26308230000001]],
    [ "Enedis",[5.658416675010975, 46.26107444126668]],
    [ "Enedis",[5.660805699999998, 46.263503400000005]],
    [ "Enedis",[5.6620740340238545, 46.25819639730069]],
    [ "Enedis",[5.6654596, 46.2573647]],
    [ "Enedis",[5.660450294623655, 46.259462696774186]],
    [ "Enedis",[5.665812062342158, 46.261788827347935]],
    [ "Enedis",[5.663229099999999, 46.259676649999996]],
    [ "Enedis",[5.638877849999999, 46.2618726]],
    [ "Enedis",[5.6429972500000005, 46.261745749999996]],
    [ "Enedis",[5.6427433, 46.26215455]],
    [ "Enedis",[5.637698698790463, 46.257067183218226]],
    [ "Enedis",[5.63633, 46.25988375]],
    [ "Enedis",[5.6397223171856945, 46.25902451211073]],
    [ "Enedis",[5.637648150716291, 46.25888630041368]],
    [ "Enedis",[5.643903648696558, 46.263401878296925]],
    [ "Enedis",[1.614603789359391, 50.4664272683074]],
    [ "Enedis",[1.6128756251713392, 50.46415523563863]],
    [ "Enedis",[1.61683662543886, 50.47226627986758]],
    [ "Enedis",[1.616107308115543, 50.46993251811095]],
    [ "Enedis",[2.7463550000000003, 50.386893500000006]],
    [ "Enedis",[2.7465562150352985, 50.39703995039114]],
    [ "Enedis",[2.747425611541775, 50.39040579414297]],
    [ "Enedis",[2.7343489011334987, 50.39102423270421]],
    [ "Enedis",[2.742101192965839, 50.38777790711624]],
    [ "Enedis",[2.7468835, 50.38783450000001]],
    [ "Enedis",[2.7515566685873427, 50.398087511283975]],
    [ "Enedis",[2.7368405, 50.395269500000005]],
    [ "Enedis",[2.739044177278403, 50.38675901872659]],
    [ "Enedis",[2.7407415, 50.39808449999999]],
    [ "Enedis",[2.7450748156028375, 50.394392468085115]],
    [ "Enedis",[2.7381398421052623, 50.38666966666667]],
    [ "Enedis",[2.744191625721352, 50.39243227562517]],
    [ "Enedis",[2.741571984765387, 50.39226260979077]],
    [ "Géraniums",[5.6495502933132835, 46.26268193164622]],
    [ "Enedis",[5.651655250000001, 46.26158375]],
    [ "Enedis",[5.652544999999999, 46.26290775]],
    [ "Enedis",[5.6535144, 46.261913]],
    [ "Enedis",[5.640962573894582, 46.2488223120096]],
    [ "Enedis",[5.642422347445668, 46.25696091814846]],
    [ "Enedis",[5.64430775, 46.2567505]],
    [ "Enedis",[5.6457880026493825, 46.261526083942584]],
    [ "Enedis",[5.649520467518498, 46.26094759869182]],
    [ "Enedis",[5.647588250000001, 46.25720625]],
    [ "Enedis",[5.650364, 46.257867000000005]],
    [ "Enedis",[5.648926272444115, 46.259795137366964]],
    [ "Enedis",[5.646343572504219, 46.25517901223755]],
    [ "Complexe Sportif",[5.643803146808174, 46.2537331954337]],
    [ "Enedis",[5.65079125, 46.256558600000005]],
    [ "Enedis",[5.654215250000001, 46.25757175]],
    [ "Enedis",[5.654453749999999, 46.256497249999995]],
    [ "Enedis",[5.654464, 46.259493250000006]],
    [ "Enedis",[5.656031901798904, 46.25805938365043]],
    [ "Enedis",[6.159386176091814, 46.855119682460156]],
    [ "Poste électrique de Frasne",[6.163036729368154, 46.87190152579084]],
    [ "Enedis",[2.760633150187488, 45.84272989145451]],
    [ "Enedis",[3.856501174790744, 49.52601402067947]],
    [ "Enedis",[-0.6590585531954535, 45.12763560797466]],
    [ "Enedis",[1.6139408763977026, 50.455459354487765]],
    [ "Enedis",[5.6592252499999995, 46.25766425]],
    [ "Enedis",[5.659537536201012, 46.25632389624471]],
    [ "Enedis",[5.648495, 46.25473524999999]],
    [ "Enedis",[5.65011655, 46.255547150000005]],
    [ "Enedis",[5.647389, 46.2526625]],
    [ "Enedis",[5.645279983711857, 46.250269950696406]],
    [ "Enedis",[5.64622675, 46.2513305]],
    [ "Enedis",[5.66205025, 46.2556557]],
    [ "Enedis",[5.640447500000001, 46.2456875]],
    [ "Enedis",[5.64320175, 46.2451585]],
    [ "Enedis",[5.646985000000002, 46.245375749999994]],
    [ "Enedis",[5.650571749999999, 46.2522915]],
    [ "Enedis",[5.655646099999999, 46.25552544999999]],
    [ "Enedis",[5.6545285, 46.25426725]],
    [ "Enedis",[5.65578, 46.25337525]],
    [ "Enedis",[5.6500562500000004, 46.2500085]],
    [ "Enedis",[5.6516415, 46.24798199999999]],
    [ "Enedis",[5.653058900000001, 46.25187429999999]],
    [ "Enedis",[5.653155589442934, 46.249188342095394]],
    [ "Enedis",[5.6577589999999995, 46.25164375000001]],
    [ "Enedis",[5.660836500000001, 46.25396725]],
    [ "Enedis",[5.6577079999999995, 46.25439825000001]],
    [ "Enedis",[5.660087, 46.2521025]],
    [ "Enedis",[5.65830205, 46.25280095]],
    [ "Enedis",[5.657691553670216, 46.249496042823516]],
    [ "Enedis",[-0.6495366346030665, 45.14165819701612]],
    [ "Enedis",[-0.645016208333333, 45.14332415277778]],
    [ "Enedis",[-0.6427378825143942, 45.14558179889317]],
    [ "Enedis",[-0.6480476135962946, 45.14150404267144]],
    [ "Enedis",[-0.5406854416259571, 44.81967285003663]],
    [ "Enedis",[-0.6189397000000003, 45.158118200000004]],
    [ "Enedis",[1.6133293211660333, 50.44121115868187]],
    [ "Enedis",[1.612125210857593, 50.43893448675583]],
    [ "Enedis",[1.6176071969910675, 50.45170652045133]],
    [ "Enedis",[3.101186195219481, 49.254990964500934]],
    [ "Enedis",[3.0980372601481654, 49.2357050112189]],
    [ "Enedis",[3.0967206330313726, 49.247842556245104]],
    [ "Enedis",[3.0726794904118453, 49.241585063098135]],
    [ "Enedis",[3.103408979236833, 49.254019524172]],
    [ "Enedis",[3.0968938874034917, 49.26062219267111]],
    [ "Enedis",[3.1014545144231436, 49.24957413444371]],
    [ "Enedis",[3.097205083979241, 49.240020375554344]],
    [ "Enedis",[3.079079558316128, 49.24261493289508]],
    [ "Enedis",[3.095623621969859, 49.248121874199406]],
    [ "Enedis",[3.0776907863974494, 49.2411016554838]],
    [ "Enedis",[3.094405684176374, 49.23773631822623]],
    [ "Enedis",[3.084062093121398, 49.23995941440241]],
    [ "Enedis",[3.1055693661798016, 49.25118832954446]],
    [ "Enedis",[3.077129415404381, 49.24319587600407]],
    [ "Enedis",[3.095732975649873, 49.23638494454179]],
    [ "Enedis",[3.085341779403343, 49.244502338209706]],
    [ "Enedis",[3.099860175684549, 49.25717522300532]],
    [ "Enedis",[3.1009682025631724, 49.23900037006294]],
    [ "Enedis",[3.098265744769034, 49.259299931994406]],
    [ "Enedis",[3.0953842369922873, 49.23783228477837]],
    [ "Enedis",[3.106818581849875, 49.24857741995972]],
    [ "Enedis",[3.0868771117802143, 49.249958335210295]],
    [ "Enedis",[3.0980257466224916, 49.24702376803505]],
    [ "La Miral",[5.291628, 45.67476883333333]],
    [ "Bourg",[5.291167680920228, 45.670763161040256]],
    [ "Enedis",[1.6149941016875815, 50.38459530881292]],
    [ "Enedis",[1.61767, 50.379594999999995]],
    [ "Enedis",[1.6101696086245068, 50.382830287034366]],
    [ "Enedis",[1.6119755000000007, 50.3804305]],
    [ "Enedis",[1.6212588053579893, 50.382648157657655]],
    [ "réséda",[6.39440881825591, 49.084838487367556]],
    [ "réséda",[6.3895615, 49.08505350000001]],
    [ "réséda",[6.39559928965195, 49.09606155360897]],
    [ "RTE",[-1.2203703241293764, 43.512326994409385]],
    [ "Enedis",[6.262820299999999, 46.25622179999999]],
    [ "réséda",[6.3818681004975115, 49.048031225870645]],
    [ "réséda",[6.367521601489757, 49.054263412476715]],
    [ "réséda",[6.365361500000001, 49.05217050000001]],
    [ "réséda",[6.382946382212422, 49.06225536420435]],
    [ "réséda",[6.366712538318194, 49.049547169112515]],
    [ "Enedis",[6.867638909385112, 47.63112915210356]],
    [ "RTE",[0.9558316221254821, 43.157554251347605]],
    [ "Enedis",[3.8175745950609445, 45.22044708835152]],
    [ "Enedis",[3.820852918102386, 45.21999468294743]],
    [ "Tartifume",[-0.5295805317221045, 44.79963671840757]],
    [ "Enedis",[3.4045807553307914, 49.04281544720001]],
    [ "Enedis",[3.3962955368691636, 49.0782062655892]],
    [ "Enedis",[3.4036966664886106, 49.045294291944494]],
    [ "Enedis",[3.3900396824730623, 49.0554962442986]],
    [ "Enedis",[6.884548139459896, 47.63813931237405]],
    [ "Enedis",[6.894002974866478, 47.63622837480364]],
    [ "Enedis",[6.894505675132276, 47.63229433862435]],
    [ "Enedis",[6.911867278188083, 47.64321295800098]],
    [ "Enedis",[6.918667572815535, 47.641709773000464]],
    [ "Enedis",[6.918814500000002, 47.64351150000001]],
    [ "Enedis",[5.358937419406031, 43.34305610927227]],
    [ "Enedis",[5.357609199999999, 43.356073599999995]],
    [ "Enedis",[5.350425, 43.34931050000001]],
    [ "Enedis",[5.345686837549475, 43.34812412269833]],
    [ "Enedis",[5.36145878612133, 43.34635912558608]],
    [ "Enedis",[5.345640499999999, 43.3480575]],
    [ "Enedis",[5.358767826224328, 43.3675656943128]],
    [ "Enedis",[5.356986, 43.34954650000001]],
    [ "Enedis",[5.370354700900901, 43.36858254414415]],
    [ "Enedis",[5.338517124461243, 43.37643830939593]],
    [ "Enedis",[5.354635890675021, 43.346650088939526]],
    [ "Enedis",[5.359885711577398, 43.368824636848885]],
    [ "Enedis",[5.351897000000001, 43.369286499999994]],
    [ "Enedis",[5.358810736111111, 43.3487470595238]],
    [ "Enedis",[5.364442, 43.331762499999996]],
    [ "Enedis",[5.348415395905755, 43.34753678022403]],
    [ "Enedis",[5.360964, 43.349981]],
    [ "Enedis",[5.3646145, 43.36798699999999]],
    [ "Enedis",[5.371295551418004, 43.36945090842582]],
    [ "Enedis",[5.347299639137347, 43.37062753871994]],
    [ "Enedis",[5.35078918316648, 43.36851080163144]],
    [ "Enedis",[5.354252500000001, 43.338283]],
    [ "Enedis",[5.356297686433977, 43.34067560081328]],
    [ "Enedis",[5.350659638384464, 43.339773371428315]],
    [ "Enedis",[5.3565356500000005, 43.37700435]],
    [ "Enedis",[5.352219749063671, 43.340299554307116]],
    [ "Enedis",[5.354426268401789, 43.35098445777416]],
    [ "Enedis",[5.354078256443066, 43.34841278164245]],
    [ "Enedis",[5.350512720750554, 43.36667296909492]],
    [ "Enedis",[5.345774112745098, 43.37399735784313]],
    [ "Enedis",[5.3546621546572934, 43.339665656121845]],
    [ "Enedis",[5.350997867149172, 43.343701823845436]],
    [ "Enedis",[5.354574499999999, 43.343497500000005]],
    [ "Enedis",[5.347722500000001, 43.366445500000005]],
    [ "Enedis",[5.354046772040196, 43.367051703188125]],
    [ "Enedis",[5.367987233330436, 43.35149021607429]],
    [ "Enedis",[5.350552894241417, 43.343370146179396]],
    [ "Enedis",[5.3569627164301235, 43.36052293471423]],
    [ "Enedis",[5.340822579470014, 43.369742283793585]],
    [ "Enedis",[5.356314743859649, 43.3596268877193]],
    [ "Enedis",[5.354824083215843, 43.36570079974302]],
    [ "Enedis",[5.357496291010882, 43.35166622087698]],
    [ "Enedis",[5.353519999999999, 43.36796]],
    [ "Enedis",[5.3608721894510225, 43.346404720487975]],
    [ "Enedis",[5.356885195847363, 43.36745630751964]],
    [ "Enedis",[5.347925999999999, 43.3675205]],
    [ "Enedis",[5.344410271190653, 43.37109959010689]],
    [ "Enedis",[5.358169599999998, 43.35550810000001]],
    [ "Enedis",[5.3500187, 43.3479957]],
    [ "Enedis",[5.361548773527719, 43.33002585735661]],
    [ "Enedis",[5.357078588832486, 43.35419523181049]],
    [ "Enedis",[5.35948517232048, 43.371298478689475]],
    [ "Enedis",[5.3575442611971615, 43.36583977229752]],
    [ "Enedis",[5.35202542253321, 43.350978031342535]],
    [ "Enedis",[-0.6054760562335487, 45.148267475233304]],
    [ "Enedis",[5.355982462029355, 43.34660972622847]],
    [ "Enedis",[5.355489822212718, 43.37420669744144]],
    [ "Enedis",[5.342047785396089, 43.37246451752857]],
    [ "Enedis",[5.358200409879486, 43.34681899629188]],
    [ "Enedis",[5.357288082625919, 43.35641649264289]],
    [ "Enedis",[5.358553899987292, 43.34645979311221]],
    [ "Enedis",[5.356185838060908, 43.36423606963274]],
    [ "Enedis",[5.35933462851303, 43.373623959291436]],
    [ "Enedis",[5.359425269942605, 43.34789325266008]],
    [ "Enedis",[5.36329688094614, 43.374709186734755]],
    [ "Enedis",[3.4504583108652405, 49.03634252149145]],
    [ "Enedis",[3.4737406057461246, 49.04213731440777]],
    [ "Enedis",[3.484663315712588, 49.048652971425014]],
    [ "Enedis",[6.957210288484344, 47.62154757832449]],
    [ "Enedis",[6.954231946585273, 47.62060077464073]],
    [ "Enedis",[6.238231000000001, 46.889557]],
    [ "Enedis",[6.23946545, 46.88129825]],
    [ "Enedis",[6.234154450000001, 46.88775845]],
    [ "Enedis",[6.9779385, 47.613534800000004]],
    [ "RTE",[1.217555261211519, 43.28496029286668]],
    [ "Enedis",[0.10436750000000002, 49.52775775]],
    [ "ESSeyssel",[5.882720224033544, 45.964791184911576]],
    [ "Enedis",[5.912921253060852, 45.616521750547946]],
    [ "Poste électrique de Caumont",[-0.8236320650679418, 49.08947507857007]],
    [ "Enedis",[6.824480210267418, 47.52260545192737]],
    [ "Enedis",[6.8253924999999995, 47.5238345]],
    [ "Enedis",[6.82101, 47.52181050000001]],
    [ "Enedis",[4.094105350282486, 43.674468683615814]],
    [ "Enedis",[4.089096194444445, 43.674255022222226]],
    [ "Enedis",[4.093390545454547, 43.675745181818186]],
    [ "Enedis",[4.103872628502803, 43.68132594222044]],
    [ "Enedis",[4.0960185000000005, 43.67541550000001]],
    [ "Enedis",[4.090693712778049, 43.6724395970125]],
    [ "Enedis",[4.086548134765017, 43.67539479676316]],
    [ "Enedis",[4.103912681845063, 43.681124268677316]],
    [ "Enedis",[4.09750716939332, 43.67543231117928]],
    [ "Enedis",[4.090997738320783, 43.67890626888511]],
    [ "Enedis",[2.4974702, 50.5551298]],
    [ "RTE",[3.8508466813380164, 46.90710088470122]],
    [ "Enedis",[1.7951200277227726, 49.864827071287145]],
    [ "Enedis",[1.799796095238095, 49.864074190476195]],
    [ "Enedis",[1.9075594999999996, 49.847896199999994]],
    [ "Enedis",[1.8939854066666677, 49.84430044500001]],
    [ "Enedis",[1.8972309433719432, 49.846736240669244]],
    [ "Enedis",[1.9079301150971597, 49.84120334977578]],
    [ "Enedis",[1.8852385661329865, 49.85151525832973]],
    [ "Enedis",[1.8619661666666665, 49.82598100000001]],
    [ "Beausroy",[6.194452129925454, 48.688565675186375]],
    [ "Enedis",[6.194928684452914, 48.68998903176116]],
    [ "Enedis",[6.194223259493669, 48.69627067932488]],
    [ "Enedis",[6.193232999999999, 48.691737999999994]],
    [ "Enedis",[6.194935340277241, 48.68760458205228]],
    [ "Enedis",[6.91536, 47.633185]],
    [ "Enedis",[6.934044500000001, 47.626870000000004]],
    [ "Enedis",[6.923606306368461, 47.629119199722005]],
    [ "Enedis",[6.924238238938052, 47.62621994493608]],
    [ "Enedis",[6.9265505, 47.6334905]],
    [ "Enedis",[6.928374464088397, 47.62372818968693]],
    [ "Enedis",[6.932038644444445, 47.63023582222222]],
    [ "Enedis",[6.921093175038052, 47.626326161846784]],
    [ "Enedis",[6.914348499999998, 47.629029499999994]],
    [ "Enedis",[6.907674283790284, 47.63287273641174]],
    [ "Enedis",[4.052999332503286, 49.0679892255766]],
    [ "Enedis",[6.897722, 47.6550555]],
    [ "Enedis",[6.898260199999998, 47.653369399999995]],
    [ "Enedis",[6.917953475917976, 47.658869637100615]],
    [ "Enedis",[6.913129, 47.6593165]],
    [ "160460481",[6.481431036630037, 43.60848769047619]],
    [ "Enedis",[6.480553811684924, 43.6123037360459]],
    [ "Enedis",[6.911588509240247, 47.6649831670089]],
    [ "Enedis",[6.914487558849953, 47.666219207547165]],
    [ "Enedis",[6.918123333333334, 47.672984000000014]],
    [ "Enedis",[6.925491999999999, 47.671731]],
    [ "Enedis",[6.920788832110839, 47.66809548818256]],
    [ "Enedis",[3.372545710084451, 45.31468663248882]],
    [ "Enedis",[0.03964602564102538, 47.685276661538474]],
    [ "Enedis",[6.898890350000001, 48.081898949999996]],
    [ "Poste électrique de Le Thou",[-0.9471561501797156, 46.1006468479153]],
    [ "Enedis",[-0.020321055256064844, 47.7163018081761]],
    [ "Enedis",[6.325821344009627, 45.60794910508772]],
    [ "Enedis",[6.324822508986416, 45.61570415809824]],
    [ "Enedis",[6.54562396851852, 47.89343281008232]],
    [ "Enedis",[5.4604115579823205, 43.41906477769111]],
    [ "Enedis",[5.426997562675363, 43.43093636825506]],
    [ "Enedis",[5.4246012689533885, 43.43145909206373]],
    [ "Enedis",[5.454961170212767, 43.42105203333333]],
    [ "Enedis",[5.434056500000001, 43.429644]],
    [ "Enedis",[5.43274928163993, 43.43108457754011]],
    [ "Enedis",[5.436996375913876, 43.43179738795428]],
    [ "Enedis",[5.43503168993916, 43.4373863183135]],
    [ "Enedis",[5.424847549999999, 43.43287874999999]],
    [ "Enedis",[5.438404039304043, 43.429361726874454]],
    [ "Enedis",[5.426403254545455, 43.43335858787879]],
    [ "Enedis",[5.431278000000001, 43.42989620000001]],
    [ "Enedis",[5.435870651633594, 43.43464255562236]],
    [ "Enedis",[5.4491492500000005, 43.417343149999994]],
    [ "Enedis",[5.44787515, 43.42458945]],
    [ "Enedis",[5.4616684, 43.41606530000001]],
    [ "Enedis",[5.431654524597254, 43.43457733181965]],
    [ "Enedis",[5.460349499999999, 43.42776349999998]],
    [ "Enedis",[5.4343870999999995, 43.43215240000001]],
    [ "Enedis",[5.4289376752931755, 43.43415769157888]],
    [ "Enedis",[5.432705465686276, 43.43449429411764]],
    [ "Enedis",[5.434117211852943, 43.43059295204721]],
    [ "Enedis",[-0.26544810512820516, 47.196105958974364]],
    [ "Enedis",[-0.29639620370370423, 47.19152092592592]],
    [ "Enedis",[-0.26117850000000076, 47.19508549999999]],
    [ "Enedis",[-0.27169916167664687, 47.196858848303386]],
    [ "Enedis",[-0.30186896578064154, 47.18982900020138]],
    [ "RTE",[-0.25403037852251725, 47.18310106966273]],
    [ "Enedis",[2.7789135657428594, 48.44767038019865]],
    [ "Enedis",[2.766745103450674, 48.449387562714435]],
    [ "Enedis",[2.77238815, 48.460529199999996]],
    [ "Enedis",[2.7578439097511667, 48.44554223690339]],
    [ "Enedis",[2.760422599999999, 48.443032]],
    [ "Enedis",[2.7686074291481773, 48.45305593925777]],
    [ "Enedis",[3.107179401362891, 48.947003668410794]],
    [ "Enedis",[2.8265505075166497, 48.577014997900164]],
    [ "RTE",[-0.45534408723518194, 47.449923016086025]],
    [ "Enedis",[3.7381865013461537, 43.903779333157054]],
    [ "Enedis",[2.1649007351390606, 48.346715465545934]],
    [ "Enedis",[2.2076179270714484, 48.55180844169839]],
    [ "Enedis",[2.212634644604893, 48.54895582036925]],
    [ "Enedis",[2.203866615424296, 48.55307999564766]],
    [ "Enedis",[2.2072279898576515, 48.55552879683365]],
    [ "Enedis",[2.2103931275946755, 48.56178207894698]],
    [ "Enedis",[2.201221140393472, 48.55202374472608]],
    [ "Enedis",[2.1950371053833337, 48.594318970018556]],
    [ "Enedis",[2.1893874212872517, 48.58817871636902]],
    [ "Enedis",[2.1904442884113973, 48.590121647488736]],
    [ "Enedis",[2.191276867550136, 48.563601273435715]],
    [ "RTE",[1.723056995948752, 44.1606305619784]],
    [ "LAJUS",[6.921477964736356, 48.43990984423831]],
    [ "Enedis",[6.9397009758157155, 48.47557358837324]],
    [ "Enedis",[2.277856545798279, 48.66974216926336]],
    [ "Enedis",[2.277843391999221, 48.66709289410224]],
    [ "Enedis",[6.8796434, 48.49913665]],
    [ "Enedis",[2.3015309729114315, 48.70984046817786]],
    [ "Enedis",[2.311699470527823, 48.71536921497247]],
    [ "Enedis",[2.3060369961685825, 48.69477593256705]],
    [ "Enedis",[2.296763089507375, 48.70650212001259]],
    [ "Enedis",[2.3013535750447405, 48.70876378625736]],
    [ "Enedis",[2.312800740716298, 48.70826333064769]],
    [ "Enedis",[2.298434063009947, 48.710214453562145]],
    [ "Enedis",[2.308132816435062, 48.69857958693583]],
    [ "Enedis",[1.6380230637955375, 48.73297536250527]],
    [ "Enedis",[7.3382464999999995, 47.812]],
    [ "Enedis",[1.8769312214119291, 44.671661695277876]],
    [ "Enedis",[1.87688275, 44.67394825]],
    [ "Enedis",[4.442082, 46.54390700000001]],
    [ "Enedis",[1.624916813060178, 48.5877461986769]],
    [ "Enedis",[1.640604716471647, 48.60629216951695]],
    [ "Enedis",[1.6584593145029876, 48.59527903331523]],
    [ "Enedis",[1.6543479600658701, 48.60357393152189]],
    [ "Enedis",[1.6529497718248314, 48.59585656815807]],
    [ "Enedis",[1.667300344527363, 48.61271953917911]],
    [ "Enedis",[1.6428643916098362, 48.600760862574916]],
    [ "Enedis",[1.6563699212705836, 48.60173612043262]],
    [ "Enedis",[1.677551674660775, 48.5959743959188]],
    [ "Enedis",[1.6384497199999994, 48.60259833333332]],
    [ "Enedis",[1.6458320000000004, 48.6032635]],
    [ "Enedis",[1.6371442085385879, 48.60378490640394]],
    [ "Enedis",[3.505892547299622, 45.85966370209838]],
    [ "Enedis",[3.4991670081300827, 45.85514600000001]],
    [ "Enedis",[3.5069391184393868, 45.854153622387365]],
    [ "Enedis",[1.5941777802106623, 48.58047959564591]],
    [ "Enedis",[1.5837974999999995, 48.593965]],
    [ "Enedis",[1.5746549999999997, 48.574771000000005]],
    [ "Enedis",[1.5878111113320075, 48.591809738237245]],
    [ "Enedis",[1.5774871800486618, 48.59310752408759]],
    [ "Enedis",[1.5915040000000003, 48.58059599999999]],
    [ "Enedis",[1.5884238613374067, 48.586573296503744]],
    [ "Enedis",[1.5756874966675551, 48.5966891667111]],
    [ "Enedis",[1.5920513248128065, 48.588524518977536]],
    [ "Enedis",[1.5935264999999996, 48.5761465]],
    [ "Enedis",[1.5753994999999998, 48.5799035]],
    [ "Enedis",[1.5845598236420195, 48.58934585379277]],
    [ "Enedis",[1.5773852194207418, 48.58663000117646]],
    [ "Enedis",[3.103523749491322, 49.20933553698384]],
    [ "Enedis",[-0.5572864151002184, 44.8003514819084]],
    [ "Enedis",[-0.56502175, 44.788925750000004]],
    [ "Enedis",[-0.5638364692046313, 44.78955654428304]],
    [ "Enedis",[3.0676698005578795, 45.92038013249651]],
    [ "Enedis",[3.080866665784249, 45.91958850341938]],
    [ "Enedis",[3.069738206627155, 45.938147117726295]],
    [ "Enedis",[3.0637116553926207, 45.92099636739198]],
    [ "Enedis",[3.077099746504124, 45.923103559101236]],
    [ "Enedis",[3.058064747912243, 45.922212867657464]],
    [ "Enedis",[3.062519209711973, 45.92461582845355]],
    [ "Enedis",[3.071286138344227, 45.92163384331881]],
    [ "Enedis",[3.05278806359782, 45.910765289117705]],
    [ "Enedis",[4.526083399999999, 45.9198338]],
    [ "Enedis",[4.521235849999999, 45.92020185]],
    [ "Enedis",[4.531727376534414, 45.923875447783]],
    [ "Enedis",[5.575309098311428, 48.31994011629095]],
    [ "Enedis",[5.569351701839897, 48.31997211877146]],
    [ "Enedis",[5.581482429107238, 48.32016780581457]],
    [ "Enedis",[5.590369315688154, 48.317823160497795]],
    [ "Enedis",[-0.5895751111111114, 44.81870822222223]],
    [ "Enedis",[-0.5901656666666667, 44.82232436257309]],
    [ "Enedis",[-0.5878075919148424, 44.820047206554236]],
    [ "Enedis",[-0.594155365724804, 44.81886664118378]],
    [ "Enedis",[-0.5865853077961494, 44.82256207182229]],
    [ "Enedis",[-0.5892533984891415, 44.82056233805477]],
    [ "Enedis",[-0.5854767982626683, 44.82006039613926]],
    [ "Enedis",[-0.5851633333333334, 44.81920400562045]],
    [ "Stand an Amann",[-3.5232984999999997, 47.872819500000006]],
    [ "Enedis",[5.810413249206275, 47.225681250334574]],
    [ "Enedis",[4.409169749450469, 48.92085798349076]],
    [ "Enedis",[4.40103852595889, 48.91881483280356]],
    [ "Enedis",[4.4032776, 48.922751600000005]],
    [ "Maladrerie",[4.414250165248227, 48.91594416950355]],
    [ "Enedis",[4.406553, 48.9229253]],
    [ "Les Prières",[4.412306188476364, 48.91853237167782]],
    [ "Enedis",[4.410231853408785, 48.9184201781191]],
    [ "Enedis",[4.405568008333335, 48.92104566666667]],
    [ "Enedis",[4.401566168681635, 48.92089950949913]],
    [ "Enedis",[4.4055634912794375, 48.9198236168006]],
    [ "Enedis",[4.40392145, 48.923809750000004]],
    [ "Enedis",[4.417172328988207, 48.91546802482931]],
    [ "Enedis",[5.890963, 47.219767749999995]],
    [ "BLANQUEFORT (FORD)",[-0.6117528390164655, 44.91807338051532]],
    [ "Enedis",[1.7876920532172231, 46.775472163038216]],
    [ "Enedis",[1.7819167180851063, 46.77728817907803]],
    [ "Enedis",[1.787189657871592, 46.77804315743183]],
    [ "Enedis",[1.7798493333333334, 46.77988667605634]],
    [ "Enedis",[0.06250699999999844, 47.8245195]],
    [ "L'étang",[0.0771694999999997, 47.82956600000001]],
    [ "Enedis",[0.08244884098939928, 47.824057027090696]],
    [ "La Girardière",[0.08255900000000053, 47.8272675]],
    [ "Enedis",[0.07487035205408209, 47.821225695267806]],
    [ "Enedis",[0.08256318813905898, 47.82709564458078]],
    [ "Médiathèque",[0.07859023426894138, 47.8281359002018]],
    [ "Enedis",[0.09138065753424675, 47.82931118264841]],
    [ "Groupe scolaire",[0.0795454932106876, 47.82795866360052]],
    [ "Enedis",[0.07666133811965811, 47.82989500581197]],
    [ "Enedis",[5.8779898500000005, 47.106833849999994]],
    [ "TK de la Correrie",[6.108492085357549, 45.60314590283768]],
    [ "Enedis",[6.106940950000001, 45.60866169999999]],
    [ "Enedis",[-0.3543034631225701, 44.77460277777292]],
    [ "Enedis",[2.7799757428823013, 48.09869541375599]],
    [ "Enedis",[2.766110223887079, 48.05912692247557]],
    [ "Enedis",[4.503711037328094, 45.176080338135776]],
    [ "Enedis",[3.6856578800000004, 49.30799231]],
    [ "Enedis",[3.6664891693989077, 49.30988250273225]],
    [ "Enedis",[3.685162231853418, 49.310145936575054]],
    [ "Enedis",[3.6682610733572267, 49.314924367502975]],
    [ "Enedis",[3.666973136659436, 49.3080818054953]],
    [ "Enedis",[3.6689692499999995, 49.30999599999999]],
    [ "Poste électrique de Fismes",[3.6908180892710383, 49.3059576015915]],
    [ "162250456",[1.3401116499999992, 46.7865528]],
    [ "Enedis",[-0.6030167052974101, 44.808324785435374]],
    [ "Enedis",[-0.6004625499999999, 44.8084397]],
    [ "Enedis",[-0.5879681139601143, 44.8159572551236]],
    [ "Enedis",[-0.5879173895437413, 44.81714988100393]],
    [ "Enedis",[2.4097135, 47.06290475]],
    [ "Enedis",[2.4074662499999997, 47.06648949999999]],
    [ "Enedis",[2.432045, 47.08162874999999]],
    [ "Enedis",[2.4069732500000005, 47.05637324999999]],
    [ "Enedis",[2.402634033333332, 47.058148233333334]],
    [ "Enedis",[2.42936925, 47.07760725000001]],
    [ "Enedis",[2.4112067499999994, 47.07677025]],
    [ "Enedis",[2.4083513775811203, 47.07088157227138]],
    [ "Enedis",[2.419257, 47.08472700000001]],
    [ "Enedis",[2.4152456195539513, 47.06409028104028]],
    [ "Enedis",[2.403379436669618, 47.071643891939765]],
    [ "Enedis",[2.4332422499999997, 47.05379875]],
    [ "Enedis",[2.3968550000000004, 47.07235375]],
    [ "Enedis",[2.40298575, 47.068842499999995]],
    [ "Enedis",[2.4390134999999997, 47.081573]],
    [ "Enedis",[2.422449171431933, 47.065040477232614]],
    [ "Enedis",[2.423371, 47.08282025000002]],
    [ "Enedis",[2.4166622500000003, 47.06040975]],
    [ "Enedis",[2.406595737437846, 47.06890522536168]],
    [ "Enedis",[2.4116084878487847, 47.05614889213921]],
    [ "Poste électrique de Penchard",[2.8477656534486466, 48.99875872119541]],
    [ "Enedis",[4.489044005208334, 48.978423609375]],
    [ "Enedis",[4.51702005, 48.990653349999995]],
    [ "Enedis",[4.513786999999999, 48.9900625]],
    [ "Enedis",[4.482771724895628, 48.982768347176446]],
    [ "Zone Industrielle",[4.51021540143824, 48.987801312605754]],
    [ "Enedis",[4.499110268037871, 48.98465838981391]],
    [ "Enedis",[4.514526198458573, 48.98510605780347]],
    [ "Enedis",[4.5120284999999996, 48.98934]],
    [ "Aristide Appert",[4.5057709453125, 48.98769156510417]],
    [ "Enedis",[4.489687408265589, 48.98422662597179]],
    [ "Enedis",[4.493704365721183, 48.978814576141616]],
    [ "Les Pyramides",[5.8971820289289765, 45.55964813018039]],
    [ "Vaugelas",[5.899062680176668, 45.57144782281111]],
    [ "EGLISE",[6.820835762947111, 47.70506577157448]],
    [ "Les Boulets",[6.803989853772048, 47.70026985122046]],
    [ "CALVAIRE",[6.8228755098039215, 47.70145331960784]],
    [ "Enedis",[1.482057103628296, 48.83532458209345]],
    [ "Enedis",[1.4843376257309935, 48.83710970760234]],
    [ "Lôtissement HLM",[0.4173434000000001, 47.92920260000001]],
    [ "Mairie",[0.41446877212254196, 47.92912647198828]],
    [ "Enedis",[3.4788079471000786, 49.08640078819033]],
    [ "Enedis",[6.804331206256922, 48.09382125481728]],
    [ "RTE",[5.827526318326761, 48.6636146141315]],
    [ "Sous-station SNCF de Choloy",[5.839583143998168, 48.663420491949054]],
    [ "Enedis",[2.826502959770115, 46.15905489885059]],
    [ "Enedis",[2.832467381690845, 46.165671903118216]],
    [ "Enedis",[2.8598180585585586, 46.178558101801805]],
    [ "Enedis",[2.8289627515723264, 46.159765399371075]],
    [ "Enedis",[2.831956243770314, 46.16265295955219]],
    [ "Enedis",[2.8322596130811664, 46.16754962568952]],
    [ "Enedis",[2.604818879099364, 46.03764274987763]],
    [ "Enedis",[2.1999693914373086, 48.57453378287462]],
    [ "Enedis",[2.236550591715976, 48.58401680276134]],
    [ "Enedis",[2.2221221985018724, 48.57776655056181]],
    [ "Enedis",[2.2251084095781075, 48.57954827654885]],
    [ "Enedis",[2.2262836409027362, 48.57655563151588]],
    [ "Nouveau Port",[-2.8247507030376195, 48.64817816846003]],
    [ "Enedis",[-0.5956171801756401, 45.19402974512966]],
    [ "Enedis",[-0.5708014186220466, 45.21789122542534]],
    [ "Enedis",[1.4468675108177484, 48.8514636710671]],
    [ "Enedis",[1.4460761666666675, 48.859939749999995]],
    [ "Enedis",[1.4541896259211098, 48.86046599003034]],
    [ "Enedis",[1.4347295, 48.847424000000004]],
    [ "Enedis",[1.4413966935929794, 48.8530331620387]],
    [ "Enedis",[1.4357795545276135, 48.85764476675078]],
    [ "Enedis",[1.4406038531073442, 48.85545562335217]],
    [ "Enedis",[1.4357323189165574, 48.852502918304936]],
    [ "Enedis",[1.4593088699315433, 48.864788946287526]],
    [ "Enedis",[1.4315183375769152, 48.84794247506895]],
    [ "Enedis",[1.4268097193333344, 48.850006146999995]],
    [ "Enedis",[3.4119798533094823, 49.072902887906345]],
    [ "Centre",[5.85071436071111, 48.70654520302222]],
    [ "Château",[5.853426947072803, 48.7073622750054]],
    [ "Belpré",[5.854777001062494, 48.70512480131362]],
    [ "Poste électrique de Saulnois",[6.456101930954618, 48.84866033288852]],
    [ "Village",[5.879676694842186, 48.76520082879138]],
    [ "Sorea",[6.371167195121952, 45.26448423577235]],
    [ "Poste source de Troyes Est",[4.095029502701027, 48.30602731279202]],
    [ "Bourg Surfonds",[0.4622511206807146, 47.9744967601895]],
    [ "Enedis",[6.520642325854703, 47.948243445779916]],
    [ "Enedis",[7.20732341382114, 48.92565922357724]],
    [ "RTE",[4.211718121438627, 47.71738738447062]],
    [ "Sous-station de Petit-Beru",[4.011488408486678, 47.853679108242574]],
    [ "RTE",[4.322533388289927, 47.62040436119609]],
    [ "SNCF Réseau",[4.3074020022442365, 47.63212838061963]],
    [ "RTE",[4.005431971960329, 48.03482788209986]],
    [ "SNCF Réseau",[4.106002090509706, 47.7820871888451]],
    [ "Enedis",[4.4188885, 43.466032]],
    [ "Enedis",[4.43013475, 43.4567635]],
    [ "Enedis",[4.422475368849926, 43.461152412603866]],
    [ "Enedis",[-0.23379800000000003, 47.4572185]],
    [ "Enedis",[6.12801542509964, 48.395123686789155]],
    [ "Enedis",[6.1332805000000015, 48.392466000000006]],
    [ "Sous-station SNCF de Chézy-sur-Marne",[3.3731788060952126, 48.9950751614527]],
    [ "RTE",[3.27820880818536, 48.973212939359655]],
    [ "Enedis",[-0.6058435940706952, 44.79995189745344]],
    [ "Anciens Combattants",[-0.6040657421875009, 44.79140194791667]],
    [ "Enedis",[-0.5955732499999996, 44.80427200000001]],
    [ "Enedis",[-0.5935929002957337, 44.80625231432192]],
    [ "Poste électrique de Insming",[6.88748884178541, 48.95869407259442]],
    [ "Poste électrique de Puttelange",[6.916943228114943, 49.057556805495224]],
    [ "Poste électrique de Sarrebourg",[7.037240335445966, 48.740827059146234]],
    [ "Poste électrique de Dieppedalle",[1.016571879382872, 49.41989682501138]],
    [ "Plattuy",[6.446521789090908, 45.91215472545454]],
    [ "La Rochette",[6.4421879765651555, 45.911149202909854]],
    [ "REThones",[6.443582652039126, 45.880689640831065]],
    [ "Résidence des Confins",[6.46705007551448, 45.91420654540756]],
    [ "Poste électrique de Hambach",[7.052133719047495, 49.04827232209183]],
    [ "Enedis",[2.4100297499999996, 47.103395000000006]],
    [ "Enedis",[2.4201614999999994, 47.10254325]],
    [ "Enedis",[2.421461, 47.09011299999999]],
    [ "Enedis",[2.4242252499999997, 47.1044495]],
    [ "Enedis",[2.4141595000000002, 47.097227000000004]],
    [ "Enedis",[2.4189228180223736, 47.11747629339527]],
    [ "Enedis",[2.41864025, 47.0915955]],
    [ "Enedis",[2.424948999999999, 47.1067345]],
    [ "Enedis",[2.4154575000000005, 47.09523225]],
    [ "Enedis",[2.4250853080049133, 47.10153263177738]],
    [ "Enedis",[2.421110333716226, 47.09969084181887]],
    [ "Enedis",[2.422972749999999, 47.0942665]],
    [ "Enedis",[2.41633725, 47.112908000000004]],
    [ "Enedis",[2.4314510000000005, 47.107319999999994]],
    [ "Enedis",[2.42675716330514, 47.10392402971155]],
    [ "Enedis",[2.410930116108, 47.09645399231929]],
    [ "Enedis",[2.431681291658705, 47.104848749087516]],
    [ "Enedis",[6.36470810982876, 45.29916656335913]],
    [ "Enedis",[4.083740864864864, 45.59359530630631]],
    [ "Enedis",[4.075834370615851, 45.59812977593666]],
    [ "Sous-station RFF de Saran",[1.8895422099497283, 47.962308387417096]],
    [ "Enedis",[1.5608065000000002, 43.51360099999999]],
    [ "Enedis",[7.173210749419538, 48.925566283341816]],
    [ "Enedis",[7.1777292328783595, 48.923041647593884]],
    [ "Enedis",[-0.6359487368565774, 44.827690163058065]],
    [ "Enedis",[-0.6285626156982665, 44.8220832772681]],
    [ "Enedis",[1.4743297404479576, 48.88271393675888]],
    [ "Enedis",[1.4817210832424565, 48.885910716103226]],
    [ "Poste Électrique de Vouziers",[4.678272695914302, 49.39471880318922]],
    [ "Poste électrique de Suippes",[4.529250009444506, 49.12444653128626]],
    [ "Enedis",[0.9504128313902138, 49.57149010104222]],
    [ "Enedis",[0.9397945562695669, 49.56508837090131]],
    [ "Enedis",[0.942325120898099, 49.567871793609676]],
    [ "Enedis",[0.9464892990654209, 49.57158248598131]],
    [ "Enedis",[0.9433445624626418, 49.564625221558096]],
    [ "Enedis",[0.9624069999999997, 49.585917]],
    [ "Enedis",[0.9304130000000002, 49.567646]],
    [ "Enedis",[0.9365601983442575, 49.56885235770955]],
    [ "Enedis",[0.9489176835871406, 49.573596214890024]],
    [ "Enedis",[0.9381275374222211, 49.57003230897777]],
    [ "Enedis",[-0.6625368083333334, 44.811050034]],
    [ "Poste électrique de la Chaussée",[4.5118114895078545, 48.83871518003743]],
    [ "Enedis",[5.954764859420289, 45.53118497376812]],
    [ "Enedis",[-0.9228769688366059, 47.010207202983665]],
    [ "Enedis",[-0.8935318583525788, 47.047925598152425]],
    [ "Enedis",[5.98609344845173, 45.58526492391361]],
    [ "Enedis",[-0.5062277983134225, 43.359147002030134]],
    [ "Enedis",[-0.5132005113268611, 43.36398578964401]],
    [ "Enedis",[-0.5048175215038972, 43.3602883294819]],
    [ "Enedis",[6.262485914762997, 45.20614799031435]],
    [ "Enedis",[6.263921648547618, 45.205604941145694]],
    [ "Enedis",[1.1597028333333332, 49.495955]],
    [ "Enedis",[1.1496360000000003, 49.499124499999986]],
    [ "Enedis",[1.1390820000000001, 49.490341]],
    [ "Enedis",[1.1457512169703867, 49.498967864654524]],
    [ "Enedis",[1.156228, 49.501194500000004]],
    [ "Enedis",[1.1311514999999999, 49.5154225]],
    [ "Enedis",[1.1391502999999998, 49.4950153]],
    [ "Enedis",[1.1525019815209665, 49.498762662402264]],
    [ "Enedis",[1.149803945539136, 49.50340462506842]],
    [ "Enedis",[1.1384029160671458, 49.49310902018386]],
    [ "Enedis",[1.123524, 49.469362000000004]],
    [ "Arondine",[6.521883250000001, 45.876343250000005]],
    [ "REThones",[6.499162100134953, 45.855438041430496]],
    [ "Le Char",[6.494225740010117, 45.86590144815377]],
    [ "REThones",[6.525195000000002, 45.879023499999995]],
    [ "REThones",[6.344345486144674, 45.84567699950403]],
    [ "REThones",[6.332495276755427, 45.84180614144212]],
    [ "REThones",[6.347338270121057, 45.85364378439315]],
    [ "Enedis",[6.739928, 48.447254750000006]],
    [ "Enedis",[6.745683750000001, 48.44571725]],
    [ "Enedis",[6.739582099999998, 48.445512199999996]],
    [ "Enedis",[6.748022277247668, 48.44660844478788]],
    [ "Enedis",[6.740922292139095, 48.44258927132836]],
    [ "Enedis",[6.752189690021233, 48.4439254522293]],
    [ "Enedis",[6.756504, 48.44158125]],
    [ "REThones",[6.375572465942028, 45.85980036431159]],
    [ "REThones",[6.415203887708006, 45.85842937494257]],
    [ "REThones",[6.36041255, 45.86122345]],
    [ "Les murailles",[6.366171501769021, 45.865214574526085]],
    [ "REThones",[6.370648198515076, 45.85944691664278]],
    [ "REThones",[6.4066775499999995, 45.848864899999995]],
    [ "Comburce",[6.413016037083359, 45.852535155562535]],
    [ "REThones",[6.3458275, 45.85962524999999]],
    [ "164915474",[6.330008155823293, 45.79424925662651]],
    [ "Poste électrique de Thiers",[3.5643974916298284, 50.40518289081065]],
    [ "RTE",[6.270910343608253, 45.240489831889946]],
    [ "La Plage",[-1.6044906428123034, 49.0981037564344]],
    [ "Terres",[-1.6022899999999998, 49.100896999999996]],
    [ "Enedis",[5.2505799999999985, 43.369698500000005]],
    [ "Enedis",[5.250750499999999, 43.3690025]],
    [ "Enedis",[5.262662420340295, 43.37364369102862]],
    [ "Enedis",[5.241845455172415, 43.36633769885057]],
    [ "Enedis",[5.2451066, 43.36702209999999]],
    [ "Enedis",[5.237607563876653, 43.35842468428781]],
    [ "Enedis",[5.248223729370009, 43.367175648920444]],
    [ "Enedis",[5.2612835, 43.37272]],
    [ "Enedis",[5.241448675287357, 43.36441600957855]],
    [ "Enedis",[5.253507355974843, 43.36881122515723]],
    [ "Enedis",[6.310379749999999, 45.230556199999995]],
    [ "Enedis",[6.252359298611109, 45.2484869537037]],
    [ "Enedis",[6.3147922211587755, 45.236400582034264]],
    [ "RTE",[-0.24290459712477092, 43.18269881963925]],
    [ "Enedis",[4.394305523183198, 45.66830061588535]],
    [ "Tour Mérieux",[6.343168924066389, 45.31147806085753]],
    [ "Les Lacets",[6.340520281828647, 45.31446045118302]],
    [ "Sertours",[6.3400432720452935, 45.31422474217977]],
    [ "Enedis",[6.340367489665923, 45.324872118371324]],
    [ "Enedis",[7.030448783279347, 48.998204663273185]],
    [ "Enedis",[7.027132580756014, 48.98809829553266]],
    [ "Enedis",[7.02126, 48.98401249999999]],
    [ "Enedis",[7.028092751412428, 48.990274587570624]],
    [ "Enedis",[7.028490743461325, 48.99516393656094]],
    [ "Enedis",[7.025308840682149, 48.995567164030064]],
    [ "Enedis",[7.027442678861788, 48.999976626016256]],
    [ "RTE",[3.670311030838873, 49.58407811209441]],
    [ "RTE",[3.6081727485837654, 49.58174352184031]],
    [ "Enedis",[6.8301655, 47.527776499999995]],
    [ "Enedis",[6.825886212237093, 47.527378955598046]],
    [ "Enedis",[6.825734411173732, 47.52045968324757]],
    [ "Enedis",[6.038547563868402, 45.5635114811579]],
    [ "Sorea",[6.409240400000001, 45.25312795000001]],
    [ "Sorea",[6.4179461, 45.25408379999999]],
    [ "Sorea",[6.41616235, 45.255560700000004]],
    [ "Saint Barthélemy",[6.393784899999998, 45.25859]],
    [ "Enedis",[1.0783661833154312, 49.510443650196926]],
    [ "Enedis",[4.078413683499376, 48.30139781103765]],
    [ "RTE",[2.6591855667005513, 49.43066840011615]],
    [ "Poste Électrique SNCF du Parc",[0.47230920356932504, 48.051331997690184]],
    [ "Enedis",[4.079951951339159, 48.27027708153557]],
    [ "Enedis",[0.3053028464152227, 46.55442376044853]],
    [ "Enedis",[0.30146619542066044, 46.554611329605976]],
    [ "Enedis",[0.3042979605055297, 46.550977382306485]],
    [ "Enedis",[0.3130242320000009, 46.546973050666665]],
    [ "Enedis",[0.2954616666666667, 46.55262676595745]],
    [ "Enedis",[3.2834383713080166, 49.64291024472574]],
    [ "Enedis",[6.020372999999999, 46.092014500000005]],
    [ "Enedis",[6.023741000000001, 46.0944365]],
    [ "Enedis",[6.017431383991065, 46.087338862571364]],
    [ "Enedis",[0.5540166025867133, 48.55211375485009]],
    [ "Enedis",[0.5106293196413548, 48.53453269769744]],
    [ "Enedis",[3.3910432451844597, 45.84130535031015]],
    [ "Enedis",[3.3929793359580054, 45.836072007874016]],
    [ "Enedis",[3.3845698520452565, 45.832268266753694]],
    [ "Enedis",[3.3794585657859617, 45.82979572111023]],
    [ "Enedis",[3.3879414999999997, 45.836215]],
    [ "Enedis",[3.3929983269230775, 45.830136339743596]],
    [ "Enedis",[3.3817024808629097, 45.83093582741824]],
    [ "Enedis",[3.3957220407450746, 45.832436674476476]],
    [ "Enedis",[3.3900319999999997, 45.834697999999996]],
    [ "Enedis",[3.372228426443203, 45.82935649286158]],
    [ "Enedis",[5.0033241, 43.3986766]],
    [ "Sorea",[6.379176678319981, 45.263536282835645]],
    [ "Sorea",[6.37542316512631, 45.26255593530499]],
    [ "Sorea",[6.377303474298456, 45.26086569581265]],
    [ "Sorea",[6.3805569663056545, 45.26319066064982]],
    [ "Sorea",[6.384618341541928, 45.254617743907936]],
    [ "RTE",[3.1182419814364173, 50.65980000421747]],
    [ "Les Chalmieux",[6.31577182934927, 45.185153323240364]],
    [ "Enedis",[6.308897473883161, 45.19484873848798]],
    [ "Belleville",[6.30218445, 45.1969414]],
    [ "Enedis",[1.8607104914509138, 43.08969861706257]],
    [ "Enedis",[0.27470599145299146, 49.14749407692309]],
    [ "Pas du Roc",[6.448192164866811, 45.22606649719223]],
    [ "RTE",[3.0185071423370946, 50.764055578536436]],
    [ "Poste électrique de la Croix",[3.1318881194899286, 50.71082344477147]],
    [ "Poste électrique du Manoir",[1.2176501905495747, 49.31731904095618]],
    [ "Poste électrique de Villerbon",[1.3290376314540369, 47.648826542906896]],
    [ "RTE",[3.2633974694411227, 48.220514952434726]],
    [ "Poste électrique de Molinons",[3.5309053702944473, 48.22442587243155]],
    [ "Poste électrique des Chaillots",[3.3145444133179285, 48.21539905489082]],
    [ "Enedis",[2.213900096104167, 48.682975945979166]],
    [ "Enedis",[2.2142059670362273, 48.68190894967729]],
    [ "Enedis",[2.231615558083975, 48.67990299599036]],
    [ "Enedis",[2.206625396228191, 48.68393434662228]],
    [ "Enedis",[2.2194554774307176, 48.67437840794858]],
    [ "Enedis",[2.2384088584257777, 48.68280586872689]],
    [ "Enedis",[2.212277236996776, 48.6827896448967]],
    [ "Enedis",[2.203871742207213, 48.684298675839]],
    [ "Enedis",[2.2346584144074755, 48.68019634020913]],
    [ "Enedis",[2.242171322397996, 48.677016526410526]],
    [ "Poupardiere",[2.240476333030567, 48.68360868096913]],
    [ "RTE",[4.10955740495603, 44.42671523046651]],
    [ "SER",[8.177327961219293, 48.95370016520315]],
    [ "166245906",[3.4079341631701636, 47.86037464069264]],
    [ "Enedis",[3.395054205353388, 47.86677577788889]],
    [ "SER",[7.911113928400897, 48.751262083743974]],
    [ "Enedis",[6.006075715928837, 45.64661997315773]],
    [ "Poste electrique de Haguenau",[7.773440836008184, 48.82029016472803]],
    [ "SNCF Réseau",[-0.519221981898965, 47.50347160363979]],
    [ "RTE",[-0.9666402737552848, 47.844500683326665]],
    [ "Église",[5.7682408, 44.74514584999999]],
    [ "Poste électrique de Sens",[3.263751954227129, 48.19686867867877]],
    [ "Enedis",[1.5388820776215177, 43.5459050615915]],
    [ "Grande Fosse",[0.4142650000000001, 47.79000875]],
    [ "Poste électrique d’Argentré-du-Plessis",[-1.1546436457126994, 48.039802452647294]],
    [ "SER",[7.600871401496701, 48.84671372287313]],
    [ "Enedis",[3.388808627507244, 47.96996384283322]],
    [ "RTE",[3.6445254897865085, 47.605305455346084]],
    [ "Poste électrique de Plantades",[4.70867524231674, 44.368362070770004]],
    [ "RTE",[-0.8963171555092794, 46.941588899919964]],
    [ "RTE",[-0.9765582063740753, 46.46649371214857]],
    [ "RTE",[-1.833291539992324, 46.958370817820565]],
    [ "Poste Électrique Nérondes",[2.8466601719983995, 46.957483171371635]],
    [ "Sous-station RFF de Saint-Pierre-la-Cour",[-1.0236351346192927, 48.1137475151473]],
    [ "RTE",[-1.3192644099185218, 46.371764764050326]],
    [ "RTE",[-0.6186571460662021, 48.28937571712096]],
    [ "Enedis",[3.1349891137302404, 50.67215823421633]],
    [ "RTE",[3.062883147977901, 50.592717607522566]],
    [ "Enedis",[1.4983751909137322, 43.64897545635529]],
    [ "Enedis",[1.4878098429207829, 43.655844323261675]],
    [ "Enedis",[1.477617373682297, 43.648765759360224]],
    [ "Enedis",[1.4966839999999997, 43.6396665]],
    [ "Enedis",[1.499806, 43.651235500000006]],
    [ "Enedis",[1.477936006662747, 43.652717166764646]],
    [ "Orthez",[1.4804296062913607, 43.65001680636388]],
    [ "Enedis",[1.4994755000000002, 43.63943749999999]],
    [ "Enedis",[1.4942646513958469, 43.649920176330234]],
    [ "Enedis",[1.475931654631656, 43.65026331473378]],
    [ "Enedis",[1.4917568076358294, 43.64939697356828]],
    [ "Enedis",[1.492212213327803, 43.65115446785567]],
    [ "Enedis",[1.4793639999999997, 43.6539435]],
    [ "Enedis",[1.4898944052140324, 43.651409795086366]],
    [ "Enedis",[1.4922914999999999, 43.65673899999999]],
    [ "Enedis",[1.4954565353535354, 43.651791383838386]],
    [ "Enedis",[1.4802426783919598, 43.65625167504188]],
    [ "Enedis",[1.4743008700411775, 43.64749869910615]],
    [ "Enedis",[1.50231925, 43.6495216]],
    [ "Enedis",[1.48516, 43.649086000000004]],
    [ "Enedis",[1.4901816499999996, 43.6587647]],
    [ "Enedis",[1.4746583258370816, 43.64599046326836]],
    [ "Enedis",[1.488490805809323, 43.652881842820335]],
    [ "Enedis",[1.4772444999999998, 43.644693000000004]],
    [ "Enedis",[1.4968660947166192, 43.65347021075888]],
    [ "Enedis",[1.4871779971397137, 43.649836493660615]],
    [ "Enedis",[1.4948008577462404, 43.654996576110825]],
    [ "Enedis",[1.4860657, 43.655347400000004]],
    [ "Enedis",[1.4792295, 43.6463355]],
    [ "Enedis",[1.4920737854604529, 43.65283754553762]],
    [ "Enedis",[1.4773012500000005, 43.64701924999999]],
    [ "RTE",[0.5334821065051689, 49.493623252302044]],
    [ "RTE",[-2.562919332389179, 47.95709529558694]],
    [ "RTE",[-2.5840050907910683, 47.83329516848964]],
    [ "RTE",[3.0034596826057913, 47.08541939749874]],
    [ "166482502",[3.256102080468344, 46.93390468690804]],
    [ "166482504",[3.2649942599476316, 46.93250808993938]],
    [ "Poste électrique d’Alizay",[1.189843962624704, 49.313216393039866]],
    [ "Poste Électrique de Ganterie",[0.8330167295635712, 49.38003601429744]],
    [ "Sous-station de Pont-de-l’Arche",[1.19310945, 49.31454785]],
    [ "Poste Électrique de Foulonnière",[1.2114935471512658, 49.200000987444426]],
    [ "Sous-station de Malaunay",[1.0329812999999997, 49.524469200000006]],
    [ "Enedis",[6.029264700000001, 47.00696270000001]],
    [ "Poste électrique de Cailly",[1.0460579557803014, 49.46746379396876]],
    [ "Enedis",[4.059140542372881, 45.99807495803067]],
    [ "Enedis",[4.0502014365308545, 45.98595253321862]],
    [ "Poste électrique de Saint-Jean-de-Folleville",[0.4839292232251614, 49.49363684715168]],
    [ "RTE",[1.6998306026975631, 44.79353557333995]],
    [ "Enedis",[3.5131831628470973, 45.98191364653813]],
    [ "Poste Électrique de Rethel",[4.344380512456436, 49.51375431229148]],
    [ "Poste électrique de Bazancourt",[4.156931250682185, 49.36493642170566]],
    [ "Enedis",[3.3360695, 45.924459]],
    [ "Enedis",[3.3296293789954343, 45.915822164383556]],
    [ "Bas Trésor",[3.3328989999999994, 45.91178624999999]],
    [ "Enedis",[3.3317889999999992, 45.914210499999996]],
    [ "RTE",[1.8749027428088747, 44.85355901101306]],
    [ "Poste électrique de Paimbœuf",[-2.0135872704156834, 47.27880705674573]],
    [ "Poste électrique de Bois-Guillaume",[1.1327942070834833, 49.46711155052756]],
    [ "Poste électrique de Cazerie",[1.25759300114963, 49.49993679692692]],
    [ "Poste électrique d'Yvetot",[0.7400220775126969, 49.61387531610164]],
    [ "Enedis",[3.285085385453815, 50.27472667170111]],
    [ "Enedis",[3.955530206779081, 49.42140920686609]],
    [ "Poste Électrique des Campeaux",[0.9514758121703195, 49.52865019105421]],
    [ "Poste électrique de Bourgay",[1.0473815173345566, 49.53500782241008]],
    [ "Enedis",[4.730120765032473, 45.98724785828257]],
    [ "Poste électrique de Corneille",[1.0212571277548497, 49.38270103797751]],
    [ "Enedis",[-0.644794666989793, 47.55004988177331]],
    [ "RTE",[-0.8823545492614583, 47.95864158800168]],
    [ "Poste électrique des Sorinières",[-1.5328098191036736, 47.15427559553163]],
    [ "Poste électrique de Cotoni",[1.108329360646831, 49.37053840060344]],
    [ "RTE",[5.508706264453231, 48.910761516948995]],
    [ "Sous-station de Lérouville",[5.557964204974234, 48.78999493944674]],
    [ "SNCF Réseau",[6.026815401511851, 48.96666471897971]],
    [ "SNCF Réseau",[6.48197795363858, 45.21112764540365]],
    [ "Synergie Maurienne",[6.480462399999998, 45.209073700000005]],
    [ "Sainte-Anne",[6.484348099999999, 45.21093695]],
    [ "Poste source d’Auberville",[0.5843591412271553, 49.53017334255946]],
    [ "RTE",[-1.0241173573498832, 48.12714645513747]],
    [ "RTE",[-1.8104089632670612, 47.1063767920974]],
    [ "Village",[7.430262295271049, 48.363519220299885]],
    [ "Poste électrique de Gaillon",[1.3505861813756397, 49.16996174043702]],
    [ "Poste Électrique de la Chapelle-Réanville",[1.4102068385218922, 49.105789451914454]],
    [ "Poste Électrique des Andelys",[1.4121332469926688, 49.244807247006726]],
    [ "Poste Électrique du Cerf",[1.3730978523319197, 49.38043839860748]],
    [ "Poste électrique du Marais",[1.4663885392210572, 49.10316985844077]],
    [ "Poste Électrique de la Chapelle Darblay 225 kV",[1.1175954911644723, 49.364762645973585]],
    [ "Niemen",[2.368039431808916, 48.78688136302004]],
    [ "Station Électrique de Gonneville",[1.0638460581075746, 49.75960395737395]],
    [ "Poste Électrique de Buquet",[0.9587648520104197, 49.838011985334404]],
    [ "Poste électrique de Dieppe",[1.1004061615258627, 49.91440446265101]],
    [ "Ets Perrin",[4.092913, 46.0120655]],
    [ "Enedis",[4.099068331466948, 46.00947964839444]],
    [ "Enedis",[4.088707500000001, 46.013968]],
    [ "Enedis",[4.0828205, 46.016351]],
    [ "ZI n°1",[4.0938655, 46.019228999999996]],
    [ "Enedis",[4.097335826237779, 46.00965089593187]],
    [ "Enedis",[4.099997999999999, 46.01342149999999]],
    [ "Enedis",[4.094389000000001, 46.00809900000001]],
    [ "Enedis",[4.101774000000001, 46.0091185]],
    [ "Vauban",[4.089081, 46.0262645]],
    [ "Enedis",[4.100127777076478, 46.01269779252169]],
    [ "Enedis",[4.086912861322081, 46.013935690576645]],
    [ "Enedis",[4.086459133915575, 46.02781252273311]],
    [ "Enedis",[4.0845365000000005, 46.01568]],
    [ "Enedis",[4.095101759188474, 46.019006735077916]],
    [ "La Glacière",[4.090892817332184, 46.02742033381794]],
    [ "W Churchill",[4.089522318996414, 46.01903109978032]],
    [ "Enedis",[4.094874679888657, 46.013780512178144]],
    [ "Enedis",[4.100450250000001, 46.01218225]],
    [ "Les balmes",[4.081649679733538, 46.030649512938766]],
    [ "Enedis",[4.091679684742158, 46.011643802232854]],
    [ "Enedis",[4.0839145660136, 46.02105511541777]],
    [ "Enedis",[4.09013837720329, 46.01658984528005]],
    [ "Enedis",[4.0844319894135905, 46.016622952111746]],
    [ "Enedis",[4.086583724351249, 46.0127748385153]],
    [ "Enedis",[4.094729824316661, 46.01437816536237]],
    [ "Enedis",[-1.3604844999999999, 46.93635475]],
    [ "Enedis",[5.878216589143072, 47.0522296253657]],
    [ "Enedis",[0.7488396666666666, 47.360047333333334]],
    [ "RTE",[3.7387602791754317, 43.78123388334133]],
    [ "Enedis",[-1.37708705030213, 46.9120329667162]],
    [ "Enedis",[-1.382413815531642, 46.91203766733961]],
    [ "Enedis",[2.2571165248271527, 50.7493873239053]],
    [ "Enedis",[2.2610223336625186, 50.754372498123644]],
    [ "Enedis",[2.2607390093131547, 50.76601197322468]],
    [ "Enedis",[2.2493520188590277, 50.754547913719954]],
    [ "Enedis",[2.258372577277476, 50.75869697862638]],
    [ "Enedis",[2.263272263877716, 50.749236845535]],
    [ "Enedis",[2.252481120135364, 50.74446850253806]],
    [ "Enedis",[2.280112866666667, 50.74909337777778]],
    [ "Enedis",[2.2464962646633926, 50.75340744476548]],
    [ "Enedis",[2.2481759124260354, 50.75770280907297]],
    [ "Enedis",[2.270163, 50.7570255]],
    [ "Enedis",[2.25929990334203, 50.75494087476705]],
    [ "Enedis",[2.2582273376511224, 50.75348187651123]],
    [ "Enedis",[2.26840141048218, 50.748175566247376]],
    [ "Enedis",[2.255812799616622, 50.75404159676867]],
    [ "Enedis",[2.2528113845737687, 50.75526478769609]],
    [ "Enedis",[1.169578618249535, 49.51465278770951]],
    [ "Enedis",[1.1787799999999997, 49.526225999999994]],
    [ "Enedis",[1.1742282452419803, 49.51871905546493]],
    [ "Enedis",[1.185013191111111, 49.520498071111106]],
    [ "Enedis",[1.176338169312169, 49.51752352733686]],
    [ "Enedis",[1.1775806421533277, 49.52474622999279]],
    [ "Enedis",[1.168491731878605, 49.51197519187358]],
    [ "Enedis",[1.1881191312893076, 49.52555690959121]],
    [ "Enedis",[-1.3761811666659902, 46.907362250031035]],
    [ "Enedis",[-1.3717025840569002, 46.91361946717367]],
    [ "Enedis",[-1.3745103547718192, 46.911033568685916]],
    [ "Enedis",[-1.3774433999999998, 46.90540744999999]],
    [ "Enedis",[-1.3747873500000003, 46.90610165]],
    [ "Enedis",[-1.3755249208740759, 46.902774543987434]],
    [ "Enedis",[-1.3775700500000003, 46.9015823]],
    [ "Enedis",[-1.3807488000000006, 46.89946805]],
    [ "Enedis",[-1.3736814094388354, 46.908855770223845]],
    [ "Enedis",[-1.378171109071309, 46.90158603071769]],
    [ "Saint-François",[6.616811958598956, 45.201756117641196]],
    [ "Enedis",[6.60051175, 45.20122115000001]],
    [ "Saint-André",[6.6221764499999995, 45.19997025]],
    [ "Le Col",[6.632620938963697, 45.205709239750355]],
    [ "Poste Électrique de Logerie",[0.5949490142838781, 49.11056812064371]],
    [ "Poste électrique de Louvet",[0.38339343279174803, 49.34950438967723]],
    [ "Poste Électrique de Serquigny",[0.7215734860045557, 49.12655988082063]],
    [ "Poste électrique de Vallée",[0.21555333193750126, 49.21622884261711]],
    [ "Poste électrique des Varets",[0.2563517180320983, 49.41395202123012]],
    [ "Sous-station du Croissant",[0.22330498364352078, 49.21917152497564]],
    [ "Poste Électrique du Neubourg",[0.894616179288465, 49.14616360213023]],
    [ "Poste Électrique du Grand Hameau",[0.7290860391334439, 49.11399510846827]],
    [ "RTE",[2.196798053852711, 43.771808946382365]],
    [ "Enedis",[3.137391173792713, 45.785792848441226]],
    [ "Poste électrique de la Chapelle Darblay",[1.1114379533308176, 49.36540477998822]],
    [ "RTE",[3.48714692083179, 50.37958889290414]],
    [ "Poste Électrique de Sées",[0.19018689149955656, 48.59677014552458]],
    [ "RTE",[2.1795290212988236, 43.92448769574081]],
    [ "EDF",[2.4155797128328724, 43.66470378301305]],
    [ "RTE",[2.416036682323911, 43.66556143113421]],
    [ "RTE",[2.681290500183392, 43.709922169588985]],
    [ "EDF",[2.3252557989395894, 43.647278807881854]],
    [ "Nivoy",[6.02553003360408, 48.98249839928844]],
    [ "Poste électrique de Revel",[1.9951434023037655, 43.466966451291476]],
    [ "Leclerc",[6.023931275087368, 48.979683838741884]],
    [ "Enedis",[6.0214496626130325, 48.97871466354849]],
    [ "Enedis",[6.015746139720559, 48.979758243512975]],
    [ "École",[0.5511655000000001, 47.972389]],
    [ "Enedis",[0.54767935, 47.975493]],
    [ "Enedis",[0.5569071000000001, 47.976700099999995]],
    [ "Enedis",[6.014167161290324, 48.98020949462366]],
    [ "Poste Électrique d'Harcanville",[0.7717010640857417, 49.70742890644238]],
    [ "Poste électrique d'Écrainville",[0.32913901073464663, 49.64264730202025]],
    [ "Poste électrique de Criquet",[0.4567936606707686, 49.55509944247652]],
    [ "Poste électrique de Fécamp",[0.39833255695444586, 49.75425390462225]],
    [ "Sous-station de Soquence",[0.17893482724716642, 49.49974345524825]],
    [ "Poste Électrique des Barrettes",[0.7179284895556752, 49.84137978452852]],
    [ "Station électrique du Tréport",[1.3956375453307568, 50.05983883257317]],
    [ "Poste électrique de Notre-Dame-de-Gravenchon",[0.5553128578049468, 49.465177483761714]],
    [ "RTE",[0.31055647472046494, 49.47472319221352]],
    [ "Enedis",[3.0891673378736035, 45.78525813614663]],
    [ "Enedis",[3.0888634237940216, 45.78791845013317]],
    [ "Enedis",[3.0897744859858594, 45.79134794106009]],
    [ "Enedis",[5.632020966774438, 47.042074451621936]],
    [ "SRD",[0.532194121212121, 46.197338787878785]],
    [ "SRD",[0.5352472522522522, 46.21776642342343]],
    [ "Enedis",[6.3524981414354285, 45.67228941786675]],
    [ "Le Crozet",[6.366435260484967, 45.68846277859074]],
    [ "Enedis",[6.330266292875209, 45.66898851001994]],
    [ "Enedis",[6.347439650000001, 45.668422899999996]],
    [ "167111423",[6.359133350000001, 45.67835425]],
    [ "Enedis",[6.748839356442229, 48.113588743890276]],
    [ "Enedis",[5.686054475308666, 47.02782489436226]],
    [ "Enedis",[2.243183163549161, 50.743438487769794]],
    [ "Enedis",[2.2380224485981306, 50.73458180062304]],
    [ "Enedis",[2.2459029726421886, 50.74338516918646]],
    [ "Enedis",[2.2376851110229974, 50.73629214261168]],
    [ "Enedis",[2.2750356944444445, 50.7384803411681]],
    [ "Enedis",[2.2393151335425383, 50.74157657147839]],
    [ "Enedis",[2.273580594179022, 50.74144575123558]],
    [ "Enedis",[2.2325697359735974, 50.73190634983498]],
    [ "Enedis",[2.2598048670360114, 50.73919733702679]],
    [ "Enedis",[2.2778297897528006, 50.73747965774976]],
    [ "Enedis",[2.2544248565458345, 50.735109825838116]],
    [ "Enedis",[2.2665350613407216, 50.7465034002155]],
    [ "Enedis",[2.232117272471909, 50.73596713764044]],
    [ "Enedis",[2.265588853174604, 50.74583362698413]],
    [ "Enedis",[2.2698241881566377, 50.7446529617956]],
    [ "Enedis",[2.2661361387441548, 50.73842687975952]],
    [ "Enedis",[2.272642328114098, 50.739361995081744]],
    [ "Enedis",[2.2465150513813583, 50.73523333436613]],
    [ "Enedis",[5.664986394345647, 47.01117237127223]],
    [ "RTE",[3.4713567000000016, 50.38961435]],
    [ "Enedis",[2.2404825311760064, 50.7559221670613]],
    [ "Enedis",[2.226531668219461, 50.765754122670806]],
    [ "Enedis",[2.2323213281682346, 50.757055165836555]],
    [ "Enedis",[2.2414547866666665, 50.75459733333334]],
    [ "Enedis",[2.2293781149399226, 50.75850173377164]],
    [ "Enedis",[2.2414772755195993, 50.746807619968436]],
    [ "Poste électrique Coulondres",[3.821596687723182, 43.70444836940972]],
    [ "Poste Électrique d'Alleux",[1.9348876032183284, 49.664827613413614]],
    [ "Poste électrique de Blocaux",[1.7834086776784426, 49.77200292324308]],
    [ "Poste Électrique de Bourdel",[1.6779200515036774, 49.90551273681499]],
    [ "Poste électrique de Caudebecquet",[0.7574879215630269, 49.52196207002403]],
    [ "Poste Électrique de Vièvre",[0.6402379929226059, 49.269170102474654]],
    [ "Station Électrique d'Aumale",[1.7595839340967843, 49.76773174422405]],
    [ "RTE",[1.8289629985826805, 49.73809073216362]],
    [ "Poste Électrique de Rogerville",[0.25781330283853404, 49.471611160004464]],
    [ "Poste Électrique du Valtier",[1.1438391999999997, 49.14134965]],
    [ "Poste Électrique de la Croix-Saint-Lefroy",[1.25386475732913, 49.108632066290866]],
    [ "Enedis",[-2.819709070245196, 47.6278201747294]],
    [ "Enedis",[6.055776105263159, 48.69477328070175]],
    [ "Poste électrique de Lisieux",[0.2220098590176668, 49.141840951900186]],
    [ "Poste électrique de Touques",[0.09579524995181439, 49.345698508196016]],
    [ "Enedis",[5.8666182, 48.762104550000004]],
    [ "Village",[5.863118941911285, 48.766114578995435]],
    [ "Enedis",[5.60324665636343, 46.76070476532828]],
    [ "Enedis",[5.5956022500000016, 46.76651075000001]],
    [ "Enedis",[5.599227750000002, 46.76311250000001]],
    [ "Enedis",[5.599968339994881, 46.76024901486301]],
    [ "Enedis",[5.5990708499999995, 46.7573364]],
    [ "Enedis",[5.59401325, 46.76375834999999]],
    [ "Enedis",[5.5738322, 46.7539886]],
    [ "167493121",[5.5971191000000005, 46.76242105000001]],
    [ "EDF",[6.584367297628672, 45.18108448313676]],
    [ "Enedis",[-1.615624531592837, 48.23146380172258]],
    [ "Sous-station de la Bronsonnière",[6.548591599999997, 45.206131199999994]],
    [ "Orelle",[6.5784973938314595, 45.204673495464256]],
    [ "P.C. Terre Froide",[6.668634011844979, 45.19498845868687]],
    [ "Électricité de France",[6.448125578143574, 45.224406078545066]],
    [ "Enedis",[2.212746, 50.745939500000006]],
    [ "Enedis",[2.2086006444662094, 50.74430667515507]],
    [ "Enedis",[2.2159901140265617, 50.74569500518302]],
    [ "Enedis",[2.2045894020706047, 50.7415537720638]],
    [ "Enedis",[2.208665534638672, 50.742595892348824]],
    [ "Enedis",[2.2001301336870807, 50.73919918291694]],
    [ "RUE MAREY",[4.752286753144425, 46.90438504399745]],
    [ "RUE DE PIERRE",[4.755064158734737, 46.9049678797231]],
    [ "Enedis",[4.757293628415301, 46.89922819672132]],
    [ "Enedis",[4.7551981751183225, 46.90904936646382]],
    [ "Le NANTIL",[4.751065997478568, 46.90657760564801]],
    [ "Enedis",[4.758383648995983, 46.90325263574297]],
    [ "Enedis",[4.760580151515151, 46.90730220454545]],
    [ "Enedis",[4.765477602466157, 46.90040011379172]],
    [ "Enedis",[4.758820097060154, 46.90642487118949]],
    [ "Enedis",[4.74781698079353, 46.91769918776852]],
    [ "Enedis",[6.279779290338436, 48.75358290808152]],
    [ "Enedis",[6.298042895246469, 48.76004197874946]],
    [ "RTE",[6.305685731821997, 46.29570182345581]],
    [ "RTE",[-0.5254766704644543, 48.42770332616977]],
    [ "Enedis",[1.0967778752882735, 49.338801206670205]],
    [ "Enedis",[1.1125839999999994, 49.358263]],
    [ "Enedis",[1.0948001454775458, 49.344799600253005]],
    [ "Enedis",[1.0846823276946431, 49.341657453480806]],
    [ "Enedis",[1.0879914999999998, 49.341550500000004]],
    [ "Enedis",[1.0927154076987917, 49.34529400252881]],
    [ "Enedis",[1.0922039999999997, 49.3554685]],
    [ "Enedis",[1.097891164420485, 49.342786980233605]],
    [ "Enedis",[1.0981890279047388, 49.340431223638845]],
    [ "Enedis",[1.1049329701086954, 49.3488583731884]],
    [ "Enedis",[1.0700071843510899, 49.33387301732351]],
    [ "Enedis",[1.0958505, 49.35994650000001]],
    [ "Enedis",[1.1119542162162162, 49.35508696096096]],
    [ "Enedis",[1.1063405, 49.345121999999996]],
    [ "Enedis",[1.0960787914614123, 49.36202807881774]],
    [ "Enedis",[1.0888062109504624, 49.343257285533696]],
    [ "Enedis",[1.0915604632730522, 49.3394498450547]],
    [ "Enedis",[1.0959076294391377, 49.343218765858445]],
    [ "Enedis",[1.1006061703612486, 49.34111897331691]],
    [ "Enedis",[1.1058136726642676, 49.34218368599227]],
    [ "Enedis",[1.0845151173485572, 49.33813821725341]],
    [ "Enedis",[1.075969831117676, 49.344460492860655]],
    [ "Enedis",[1.0916052403884258, 49.34125083412603]],
    [ "Enedis",[4.302155683839314, 46.66876252683226]],
    [ "RTE",[4.885816529273899, 45.81224209106394]],
    [ "RTE",[0.8051774993005136, 48.329284574423006]],
    [ "Enedis",[3.18419954573867, 45.85275028044236]],
    [ "Enedis",[3.1745875299389, 45.84900204792939]],
    [ "Enedis",[3.1779343359023775, 45.851892503318346]],
    [ "Enedis",[3.177581337700145, 45.853486007278015]],
    [ "Enedis",[-2.5751979825327505, 47.806281141193594]],
    [ "Enedis",[6.260790417147751, 46.1837156715103]],
    [ "Enedis",[2.295501681898067, 50.73568362975981]],
    [ "Enedis",[2.294271493699885, 50.72904068728521]],
    [ "Enedis",[2.3329769999999996, 50.729837499999995]],
    [ "Enedis",[2.3077356734561913, 50.73494151621942]],
    [ "Enedis",[2.2970258327974276, 50.72353450332262]],
    [ "Enedis",[2.2915895, 50.732953]],
    [ "Enedis",[2.3234980199508897, 50.72996933235991]],
    [ "Enedis",[2.2757515500000003, 50.73414780000001]],
    [ "Enedis",[2.3070547565842894, 50.74907345144448]],
    [ "Enedis",[2.2817510000000003, 50.733781]],
    [ "Enedis",[2.3133926880539497, 50.74532704874758]],
    [ "Enedis",[2.29819801322199, 50.73632263604732]],
    [ "Enedis",[2.292479123025769, 50.72416273732335]],
    [ "Enedis",[2.278450845865481, 50.73467194799985]],
    [ "Enedis",[-0.9901717500000011, 46.459582999999995]],
    [ "Enedis",[-0.9884775277846625, 46.4646662046157]],
    [ "Enedis",[5.665139100015592, 46.99422194701799]],
    [ "168365915",[5.455650795698925, 47.1454564516129]],
    [ "Poste électrique de la Baule",[-2.387762419765111, 47.28930267812538]],
    [ "Enedis",[3.824711239329546, 48.44421716357389]],
    [ "Enedis",[1.128372055944056, 49.4269481072261]],
    [ "Enedis",[3.5929102333339156, 49.58652517492212]],
    [ "Enedis",[1.1236589659006677, 49.42302138151719]],
    [ "Enedis",[1.1143498391336262, 49.42843765983847]],
    [ "Enedis",[2.5643119000000003, 43.56478600000001]],
    [ "Enedis",[5.822947569620252, 48.66338532911392]],
    [ "Enedis",[5.818662278271166, 48.661962658969806]],
    [ "Enedis",[2.8738936435643567, 49.16367179867987]],
    [ "Enedis",[2.8799444422803813, 49.162473717589776]],
    [ "Enedis",[2.880168297518065, 49.16004609456488]],
    [ "Enedis",[2.8756794856512142, 43.13512644686219]],
    [ "Enedis",[2.8704402241887905, 43.164338560471975]],
    [ "Enedis",[2.87284537414966, 43.16729474829932]],
    [ "Enedis",[0.2325227365356615, 48.14223493886462]],
    [ "Bourg",[0.23747923282887182, 48.138213258828095]],
    [ "Poste d'Apt",[5.376007210236959, 43.88989004559434]],
    [ "Enedis",[4.218255121084755, 43.9451125865278]],
    [ "Enedis",[4.2206516618131715, 43.943995718843446]],
    [ "Enedis",[4.224254600536192, 43.95032224486148]],
    [ "Enedis",[4.215478000000001, 43.9443685]],
    [ "Enedis",[4.21559465, 43.95095570000001]],
    [ "Enedis",[4.2144301154486605, 43.94601923251486]],
    [ "Enedis",[4.21824027086819, 43.94652904945156]],
    [ "Enedis",[4.215886457632123, 43.95166071833482]],
    [ "Enedis",[4.208740250000001, 43.94474069999999]],
    [ "Enedis",[1.9494350617283955, 49.92392480246914]],
    [ "Enedis",[2.049747701574265, 49.92238854072552]],
    [ "Enedis",[1.9895217497403936, 49.95486841017654]],
    [ "Enedis",[1.9903735406638352, 49.95838568916155]],
    [ "RTE",[1.9557938602749114, 49.95958496625246]],
    [ "Enedis",[1.7537778805513011, 49.847787223073]],
    [ "Enedis",[1.7533206882479893, 49.85169134471257]],
    [ "Enedis",[1.756272000000001, 49.845549000000005]],
    [ "Pigeon Blanc",[-2.825338038276836, 47.881059925423735]],
    [ "Enedis",[-2.8380011, 47.88656835]],
    [ "Laennec",[-2.8327571324684766, 47.887114647941054]],
    [ "Enedis",[-2.8360131831820934, 47.88312743605566]],
    [ "Enedis",[0.4787989411764701, 48.00605060784315]],
    [ "Enedis",[5.5936813499457125, 47.00860913749563]],
    [ "Enedis",[1.1502774999999998, 49.41242549999999]],
    [ "Enedis",[1.1457288800084706, 49.40188179373147]],
    [ "Enedis",[1.1415053454545465, 49.41022201818182]],
    [ "Enedis",[1.1463243197152584, 49.40515247560095]],
    [ "Enedis",[1.1321401747430253, 49.4100270352423]],
    [ "Enedis",[1.144372439965547, 49.40258320620154]],
    [ "Enedis",[1.1373909999999998, 49.412622999999996]],
    [ "Enedis",[1.1379420704761907, 49.40646214095238]],
    [ "Enedis",[1.1322525833333337, 49.41404021666667]],
    [ "Enedis",[1.1407678749999999, 49.4082896875]],
    [ "Enedis",[1.1362697995338003, 49.409040827505834]],
    [ "Enedis",[1.1348356510228643, 49.415500153429605]],
    [ "Enedis",[1.1521799033661957, 49.40601315640821]],
    [ "Enedis",[1.1538205000000006, 49.4088983]],
    [ "Enedis",[1.1424367900535093, 49.41515083506452]],
    [ "Enedis",[1.1490623363636363, 49.408656006926414]],
    [ "Enedis",[1.142297718213059, 49.4129037628866]],
    [ "Enedis",[1.1439384999999997, 49.41911899999998]],
    [ "Enedis",[1.1333994109279018, 49.413093374070485]],
    [ "Enedis",[1.1448674586304473, 49.407661118732314]],
    [ "Enedis",[1.15482665562249, 49.411167981927704]],
    [ "Enedis",[1.1443201697098369, 49.4105728314225]],
    [ "Enedis",[1.1326705867846982, 49.40779069512068]],
    [ "Enedis",[1.1357882492522435, 49.41129014223995]],
    [ "Enedis",[1.1467139495305176, 49.40676402190923]],
    [ "Enedis",[5.559086088659592, 47.0251167793009]],
    [ "Enedis",[2.4510358261563003, 50.82217614673046]],
    [ "Enedis",[5.4931167499999995, 47.05286574999999]],
    [ "Enedis",[5.49772015, 47.04568925]],
    [ "Enedis",[5.497282818157156, 47.05159090269324]],
    [ "Enedis",[5.4969291, 47.04468655]],
    [ "Enedis",[4.7426982169811325, 47.44351592845912]],
    [ "Poste électrique de Kérolay",[-3.3746013317503536, 47.73484390564598]],
    [ "Jura",[7.2738223500000005, 47.73257265]],
    [ "Rue du Château",[7.263912917293279, 47.73549924935255]],
    [ "Enedis",[5.487899889188438, 47.022400327656854]],
    [ "RTE",[4.246885618278879, 43.74575930949069]],
    [ "Enedis",[5.970362021232621, 46.112731232656905]],
    [ "Enedis",[5.976322199999999, 46.10825035]],
    [ "Enedis",[5.9692395, 46.1050779]],
    [ "Enedis",[5.972460736037403, 46.11298125643164]],
    [ "169084661",[5.962605686666667, 46.10569952666667]],
    [ "Enedis",[5.973848349315068, 46.11421921050228]],
    [ "Enedis",[5.969492868955651, 46.10355846852645]],
    [ "Enedis",[5.974968700000001, 46.110040899999994]],
    [ "Enedis",[5.969406865845855, 46.115379168211554]],
    [ "Enedis",[5.97231517844397, 46.10697215943374]],
    [ "Enedis",[5.969128608820405, 46.112473554126815]],
    [ "Enedis",[5.966369632344802, 46.113058160023726]],
    [ "Bindo",[-2.847026649999999, 47.59642174999999]],
    [ "Bourg",[-2.8439834499999983, 47.59681145]],
    [ "Enedis",[6.246371769222837, 46.20014809759914]],
    [ "Enedis",[5.839836169728784, 48.360256009623804]],
    [ "Enedis",[-3.3396683291524143, 47.72013692056253]],
    [ "Église",[-3.3381905830495637, 47.72328738800606]],
    [ "Enedis",[-3.323271, 47.7299705]],
    [ "Nezenel",[-3.3443747085448403, 47.73040604653131]],
    [ "Enedis",[-3.335777594202899, 47.72585779227053]],
    [ "Enedis",[-3.32686, 47.72548833333333]],
    [ "Enedis",[-3.3405947145214525, 47.726719645214516]],
    [ "Enedis",[-3.334701763823595, 47.72780559368704]],
    [ "Gelin",[-3.3478220506558114, 47.72453167774763]],
    [ "Enedis",[-3.34349355158608, 47.72224382486397]],
    [ "Enedis",[-3.324717833389469, 47.7272954974739]],
    [ "Enedis",[6.025309775871032, 48.935534407176284]],
    [ "Enedis",[6.029223000000001, 48.9363245]],
    [ "Enedis",[6.0278266206896545, 48.9333281724138]],
    [ "Enedis",[2.69848875, 50.309380450000006]],
    [ "RTE",[4.269453042483465, 43.70340556864024]],
    [ "Poste électrique de Connerré",[0.4961012779433348, 48.05180227152731]],
    [ "Poste Électrique de Pont-de-Gennes",[0.3925681003994515, 48.03742785410415]],
    [ "Sous-station RFF de Sceaux-Boëssé",[0.573311072463132, 48.12317392482249]],
    [ "RTE",[0.3684815849502999, 48.03391044145409]],
    [ "Poste Électrique d'Yvré-l'Évêque",[0.307242504657722, 48.01133836881113]],
    [ "Enedis",[-1.1428425, 43.4820212]],
    [ "Enedis",[-0.5788150112815095, 44.81780350008813]],
    [ "Enedis",[-0.5808051141552513, 44.816611278538815]],
    [ "Enedis",[-0.5823975000000006, 44.81622425]],
    [ "Enedis",[-0.5752965961696305, 44.81375921896945]],
    [ "Enedis",[-0.5824511478274084, 44.819369793527805]],
    [ "Enedis",[6.251932936744114, 46.173021401804824]],
    [ "Poste électrique de Vendin",[2.864939254650257, 50.468553223369355]],
    [ "Enedis",[7.281067708786139, 47.73499793081139]],
    [ "Enedis",[5.437360638850314, 47.03590237955325]],
    [ "Enedis",[-0.6090351552511417, 43.168577744292236]],
    [ "Enedis",[-0.6107553342065043, 43.17114481641563]],
    [ "Enedis",[4.52675875, 47.534334699999995]],
    [ "Enedis",[-3.2282610672248016, 47.888991442628765]],
    [ "Enedis",[3.7432364999999996, 49.70094]],
    [ "Enedis",[6.2728842716195246, 48.745758720025336]],
    [ "Enedis",[6.274054163896741, 48.747782804786134]],
    [ "Enedis",[6.235746, 48.74162150000001]],
    [ "Enedis",[6.256323360819589, 48.73994618540729]],
    [ "Enedis",[6.32274915, 48.71349575]],
    [ "St Hubert",[7.279099325357819, 47.73762289739929]],
    [ "Enedis",[5.454764266316291, 47.067225301093174]],
    [ "Enedis",[5.4498165, 47.0648845]],
    [ "Enedis",[5.432842150000001, 47.06315120000001]],
    [ "Choisey Les Nicottes",[5.456421949999999, 47.06349250000001]],
    [ "Enedis",[3.2079134445821893, 50.69992425917901]],
    [ "Sous-station de Bellevue",[2.2032753759241768, 48.503546812692946]],
    [ "SER",[7.429805711827957, 48.7436908204301]],
    [ "RTE",[0.41045350000000164, 42.785854]],
    [ "RTE",[-0.5811789472993067, 43.37285405860726]],
    [ "Poste Électrique de Fresnay",[0.021140954533262583, 48.29995560827166]],
    [ "Poste Électrique de la Foucaudière",[0.1820007996882464, 47.97595164936442]],
    [ "RTE",[0.12246700336840358, 47.96340430198093]],
    [ "Enedis",[2.478161127427185, 50.503164644822]],
    [ "Enedis",[2.4751501583333333, 50.502687474999995]],
    [ "Enedis",[2.450775316151203, 50.51821725429553]],
    [ "Enedis",[2.4767653833168914, 50.501490300997474]],
    [ "Enedis",[2.471728842019544, 50.50259607991314]],
    [ "Enedis",[2.4639392109704636, 50.5039263267698]],
    [ "Enedis",[2.464507898595945, 50.50585032553302]],
    [ "Enedis",[2.471118702739725, 50.50567241917808]],
    [ "Enedis",[2.468203695112286, 50.49820853218846]],
    [ "Enedis",[2.465391999999999, 50.514701]],
    [ "Enedis",[2.4684230085959875, 50.50830551914561]],
    [ "Enedis",[2.4744365, 50.5060315]],
    [ "Enedis",[2.4819653582089556, 50.515886121890546]],
    [ "Enedis",[2.476571, 50.5044875]],
    [ "Enedis",[2.4570285631552164, 50.514752326412214]],
    [ "Enedis",[2.4633538989898995, 50.507842210858584]],
    [ "Enedis",[2.4719026188118804, 50.501226189768964]],
    [ "Enedis",[2.4733145, 50.504386499999995]],
    [ "Enedis",[2.47688222081397, 50.506831328826934]],
    [ "Enedis",[2.471565834554479, 50.50413340802017]],
    [ "Enedis",[2.4732093695758666, 50.50955986061319]],
    [ "Enedis",[2.4614177787356315, 50.50990756609195]],
    [ "SNCF Réseau",[1.9386039579124503, 47.76931257834019]],
    [ "Village",[5.843240193103448, 48.6934752045977]],
    [ "Peupliers",[-3.199890627139744, 47.64963222689076]],
    [ "Enedis",[-3.1459362398191386, 47.66833649232479]],
    [ "Enedis",[-3.182302191805211, 47.66901312902287]],
    [ "Enedis",[-3.1344343411596265, 47.67902925435457]],
    [ "SRD",[0.5268944593175854, 46.27677617060367]],
    [ "SRD",[0.5525970000000004, 46.291938]],
    [ "SRD",[0.5308505241524152, 46.27567983228323]],
    [ "RTE",[2.579471362638487, 50.50431222746822]],
    [ "Poste électrique de Montcroisette",[2.801933270098846, 50.450487330168606]],
    [ "Enedis",[6.142667259513484, 46.34245910085907]],
    [ "Enedis",[6.146524088681448, 46.36084620731233]],
    [ "Enedis",[6.134219835016834, 46.34637706734007]],
    [ "Les Vacances Bleues",[6.148114500000001, 46.357008300000004]],
    [ "Enedis",[6.143283499999999, 46.371081000000004]],
    [ "Enedis",[6.132058939540508, 46.36927165699315]],
    [ "Enedis",[6.14653960091935, 46.36058914904583]],
    [ "Enedis",[6.1409265, 46.353950999999995]],
    [ "Enedis",[6.11254667174926, 46.36235382846251]],
    [ "Enedis",[6.144242350818764, 46.38115371384958]],
    [ "Enedis",[6.141030940229885, 46.345460154022994]],
    [ "Enedis",[6.131434500000001, 46.353736500000004]],
    [ "Enedis",[6.124536509612852, 46.348159500131686]],
    [ "Enedis",[6.146704099999999, 46.358372349999996]],
    [ "Enedis",[6.1442186731287025, 46.35909498492192]],
    [ "Enedis",[6.137804545454545, 46.36249235151515]],
    [ "Enedis",[6.1527430038258295, 46.37168534751638]],
    [ "Enedis",[6.155262501925545, 46.36725602952503]],
    [ "Enedis",[6.137212000000001, 46.3507475]],
    [ "Enedis",[6.128457563658685, 46.34586582394106]],
    [ "Enedis",[6.128561819700966, 46.37029834388742]],
    [ "Enedis",[6.150720407505754, 46.35890865321296]],
    [ "Enedis",[6.135223712548016, 46.359926761288946]],
    [ "Enedis",[6.144750662937063, 46.35729006340327]],
    [ "Enedis",[6.140302599542334, 46.35116878718535]],
    [ "Enedis",[6.123534967107329, 46.361928289593145]],
    [ "Enedis",[6.151419999999999, 46.36777]],
    [ "Enedis",[6.151228547416244, 46.37266930153322]],
    [ "Enedis",[6.141030666666666, 46.347315004566205]],
    [ "Enedis",[6.139311280607685, 46.352074585344056]],
    [ "Enedis",[6.128802575608265, 46.36569108448507]],
    [ "Enedis",[6.1462341518151815, 46.348417966996706]],
    [ "Enedis",[6.143766309248068, 46.366565433702576]],
    [ "Enedis",[6.144338162756598, 46.34872557966764]],
    [ "Enedis",[6.142416841894061, 46.35015246227928]],
    [ "Enedis",[6.125849222508592, 46.346424432130576]],
    [ "Enedis",[6.141421432962137, 46.369393545063105]],
    [ "Enedis",[6.140378695076744, 46.34343893478135]],
    [ "Enedis",[6.136919898472366, 46.343326666065906]],
    [ "Enedis",[6.136390004813478, 46.366706962695545]],
    [ "Enedis",[6.135510966325333, 46.34958666486711]],
    [ "Enedis",[6.145617996781609, 46.372527435402304]],
    [ "Enedis",[6.162213716854378, 46.37268925824936]],
    [ "Enedis",[6.144272487989885, 46.36076340834387]],
    [ "Enedis",[6.143373605423094, 46.35323548994857]],
    [ "Enedis",[6.132680363851153, 46.349037032486706]],
    [ "Enedis",[6.126802446607016, 46.358296443655256]],
    [ "Enedis",[6.132167316367265, 46.34472296506987]],
    [ "Enedis",[6.115740365550481, 46.36934287113141]],
    [ "Enedis",[6.142580332800285, 46.372141426616906]],
    [ "Enedis",[6.121417387742899, 46.345808407623316]],
    [ "Enedis",[6.135804967339758, 46.35299946703382]],
    [ "Enedis",[-1.2181111500000001, 44.142619]],
    [ "Enedis",[3.1005982325428194, 45.75118090513834]],
    [ "Enedis",[3.1012663055555545, 45.75120863888889]],
    [ "Enedis",[5.886315858021171, 48.35691043407706]],
    [ "Victor Hugo",[-0.6104820184159602, 47.52859873108021]],
    [ "Enedis",[-0.6285333750811165, 47.533439552671425]],
    [ "Enedis",[-1.04650465, 46.68113915]],
    [ "Enedis",[-1.0447715, 46.689498]],
    [ "Enedis",[-0.9990797500000002, 46.68287305]],
    [ "Enedis",[-1.0551022500000002, 46.69898750000001]],
    [ "Enedis",[-1.05520775, 46.70270274999999]],
    [ "Enedis",[-1.0543758744678398, 46.693060535576805]],
    [ "Enedis",[-1.04425065, 46.68633950000001]],
    [ "Enedis",[-1.0611842500000002, 46.67993685]],
    [ "Enedis",[-1.0490755, 46.674512]],
    [ "Enedis",[-1.05395895, 46.68773825]],
    [ "Enedis",[-1.0490238255591617, 46.679165605797124]],
    [ "Enedis",[-1.05100365, 46.697939199999986]],
    [ "Enedis",[-1.0477925015473715, 46.68865634887394]],
    [ "Enedis",[5.5146264999999985, 47.1027185]],
    [ "Cite Pechiney",[6.46911824639002, 45.21479107230948]],
    [ "Livraison",[6.470727482558645, 45.212278325225675]],
    [ "Sorea",[6.464969713095239, 45.20862174404762]],
    [ "ZA",[6.464448792339782, 45.21700412536974]],
    [ "Enedis",[6.47158845, 45.21236074999999]],
    [ "Enedis",[6.262578387802823, 46.17982222976164]],
    [ "Poste électrique de Beauvais",[2.0674130025282875, 49.42902055278498]],
    [ "Poste électrique de Gournay",[1.740720639952332, 49.47729893591187]],
    [ "Poste électrique de Persan",[2.2659029851469508, 49.14565825840344]],
    [ "Poste électrique de Thérain",[2.1157412393354007, 49.41461102179078]],
    [ "Poste Électrique Etrepagny",[1.6411013880065692, 49.314124100016876]],
    [ "Poste électrique de Beuvry",[2.681307777806288, 50.50574589517061]],
    [ "Poste électrique de La Palisse",[4.107602633283869, 44.781654523135636]],
    [ "Poste électrique de Mazingarbe",[2.7240677040204253, 50.47430186027683]],
    [ "Enedis",[3.476286836627142, 45.868117121739125]],
    [ "Enedis",[3.468538420058285, 45.87060126389308]],
    [ "Enedis",[4.097350768010842, 48.33074819357783]],
    [ "Enedis",[6.289385144877345, 48.367798483116886]],
    [ "Poste électrique du Perizet",[3.3639738096874576, 50.08489780188891]],
    [ "Enedis",[1.931424500000001, 46.221225999999994]],
    [ "RTE",[2.064259545438935, 47.418437755606526]],
    [ "Enedis",[4.36417266878313, 45.315421772127266]],
    [ "Enedis",[0.4663006000000014, 47.921945099999995]],
    [ "Bourg de Saint-Mars-de-Locquenay",[0.4851072548286608, 47.9277825105919]],
    [ "Enedis",[-0.4782955284648606, 43.34225081468395]],
    [ "Enedis",[-0.47996845580404696, 43.34355614802982]],
    [ "Enedis",[-0.4832978514265826, 43.34362165887265]],
    [ "Enedis",[6.407684078549848, 45.75270722759315]],
    [ "Enedis",[6.4258227085515385, 45.74974156564516]],
    [ "Enedis",[6.421932133051444, 45.7469091952079]],
    [ "Enedis",[6.462302239289745, 45.778961603890316]],
    [ "Enedis",[6.425038041215759, 45.73807892977873]],
    [ "Enedis",[6.413827599999999, 45.75109365000001]],
    [ "Enedis",[6.410720630708378, 45.7498797961165]],
    [ "Enedis",[6.434888413854754, 45.75133689753911]],
    [ "Enedis",[6.4135096520653825, 45.753255067304806]],
    [ "Enedis",[6.407369167643612, 45.75150614888629]],
    [ "170256972",[6.429798571474843, 45.74408987376838]],
    [ "Enedis",[4.063577062848575, 44.82451502656155]],
    [ "Girodière",[6.479666651572328, 45.181412615094345]],
    [ "Les mélèzes",[6.477922749999999, 45.19187875]],
    [ "Enedis",[6.148674852703057, 48.6773615260265]],
    [ "Enedis",[6.151375462747898, 48.68760941805488]],
    [ "Sous-station RFF",[0.25009035008690783, 46.349269275366154]],
    [ "Enedis",[-1.761018, 46.501737500000004]],
    [ "Enedis",[-1.71987235, 46.48144415000001]],
    [ "Enedis",[-1.7627986202247194, 46.49987488215866]],
    [ "Enedis",[-1.7596458137254904, 46.49232696078432]],
    [ "Enedis",[-1.739345750000001, 46.505872999999994]],
    [ "Enedis",[-1.7649509999999993, 46.50301799999999]],
    [ "Enedis",[-1.744562, 46.4968705]],
    [ "Enedis",[-1.74796, 46.490742749999995]],
    [ "Enedis",[-1.7452643499999991, 46.487147050000004]],
    [ "Enedis",[-1.7597019999999992, 46.48831750000001]],
    [ "Enedis",[-1.7514510000000003, 46.48235784999999]],
    [ "Enedis",[-1.7376145499999995, 46.48217350000001]],
    [ "Enedis",[-1.7324752881600605, 46.504621129642985]],
    [ "Enedis",[-1.7441301321329084, 46.48851175294711]],
    [ "Enedis",[-1.7364953117146358, 46.48150376988373]],
    [ "Enedis",[-1.72502845, 46.494033249999994]],
    [ "Enedis",[-1.744410464223839, 46.48605023456801]],
    [ "Enedis",[-1.749576750000001, 46.502253749999994]],
    [ "Enedis",[-1.7541635936073061, 46.482251876712326]],
    [ "Enedis",[-1.7571156, 46.497164]],
    [ "Enedis",[-1.75095875, 46.498659249999996]],
    [ "Enedis",[-1.741693279692055, 46.48202420504893]],
    [ "Enedis",[-1.73336095, 46.487857000000005]],
    [ "Enedis",[-1.7532424174109158, 46.50148165223274]],
    [ "Enedis",[-1.7305937500000004, 46.50365225]],
    [ "Enedis",[-1.7513861954400967, 46.48081215247177]],
    [ "Enedis",[-1.7427950417906382, 46.49138367532438]],
    [ "Enedis",[-1.7716803995223467, 46.50166769631526]],
    [ "Enedis",[-1.7573311733169632, 46.49025502353182]],
    [ "Enedis",[-1.754274499999999, 46.4959405]],
    [ "Enedis",[-1.7499962500000004, 46.50545925]],
    [ "Enedis",[-1.76504384028394, 46.499465655723164]],
    [ "Enedis",[-1.7440360999999995, 46.482811649999995]],
    [ "Enedis",[-1.7283021724947583, 46.4930726941719]],
    [ "Enedis",[-1.7490149, 46.47725314999998]],
    [ "Enedis",[-1.7472761500000005, 46.4848342]],
    [ "Enedis",[-1.7337375000000002, 46.502996499999995]],
    [ "Enedis",[-1.749388717171717, 46.48361769898991]],
    [ "Enedis",[-1.7418064587021158, 46.50036023139077]],
    [ "Enedis",[-1.742788726420621, 46.50452132454598]],
    [ "Enedis",[-1.7388051115566159, 46.48845607794413]],
    [ "Enedis",[-1.7268356, 46.48227249999999]],
    [ "Enedis",[-1.7529947918840578, 46.49013461101449]],
    [ "Enedis",[-1.7661402648809523, 46.50204694642858]],
    [ "Enedis",[-1.7392946999999999, 46.478882399999996]],
    [ "Enedis",[-1.7454091999999999, 46.479814600000005]],
    [ "Enedis",[-1.7492187499999994, 46.49969975]],
    [ "Enedis",[-1.7475275, 46.501261750000005]],
    [ "Enedis",[-1.74710995, 46.492459849999996]],
    [ "Enedis",[-1.7608513307936506, 46.499265510476185]],
    [ "Enedis",[-1.7618836666666666, 46.494535]],
    [ "Enedis",[-1.7411500858462372, 46.488823243873945]],
    [ "Enedis",[-1.755450589228658, 46.50177155023032]],
    [ "Enedis",[-1.7471142628838374, 46.474469880518015]],
    [ "Enedis",[-1.7484684169973528, 46.49698490117356]],
    [ "Enedis",[-1.7541639500480304, 46.484702125840535]],
    [ "Enedis",[-1.7423631880841086, 46.478639077983765]],
    [ "Enedis",[-1.7541451858599748, 46.49414483556779]],
    [ "Enedis",[-1.7497639000000005, 46.48602465]],
    [ "Enedis",[-1.735690295879054, 46.484150058839305]],
    [ "Enedis",[-1.7640330051143198, 46.49816532581227]],
    [ "Enedis",[-1.7406884999999999, 46.503909500000006]],
    [ "Enedis",[-1.7411505648505166, 46.510177775887115]],
    [ "Enedis",[-1.7471233000000006, 46.4813956]],
    [ "Enedis",[-1.74559175, 46.50076575]],
    [ "Enedis",[-1.72986825, 46.50158125]],
    [ "Enedis",[-1.7298992260369679, 46.491011355209544]],
    [ "Enedis",[-1.7501593145274887, 46.48997547425628]],
    [ "Enedis",[-1.768272375708744, 46.49981375290959]],
    [ "Enedis",[-1.7312889999999992, 46.48813025]],
    [ "Enedis",[-1.7328690000000007, 46.49114825]],
    [ "Enedis",[-1.7450918949571956, 46.481720740819256]],
    [ "Enedis",[-1.7469800572792356, 46.482536006364356]],
    [ "Enedis",[-1.7436576055776887, 46.48443216998671]],
    [ "Enedis",[-1.75542575, 46.48811375]],
    [ "Enedis",[6.8125413022159345, 49.03178486185761]],
    [ "Enedis",[-1.1145686680546716, 46.82891114729661]],
    [ "Enedis",[-1.1253625726271863, 46.82636730517088]],
    [ "RTE",[1.6178013122215185, 42.93539279656615]],
    [ "SRD",[0.4954722573839659, 46.32863314345992]],
    [ "SRD",[0.4908130549019606, 46.32969231764705]],
    [ "Enedis",[6.145897855275081, 48.650837303042074]],
    [ "Enedis",[-2.6749310000000013, 47.756641]],
    [ "Enedis",[-1.0405177500000002, 46.5903775]],
    [ "Enedis",[0.39123795000000033, 47.51982780000001]],
    [ "Enedis",[-1.2264750000000009, 46.46490675]],
    [ "Enedis",[4.713393071794871, 47.40902218974358]],
    [ "Enedis",[4.66117670278182, 47.45805204364944]],
    [ "Enedis",[4.582161819666758, 48.72863021958893]],
    [ "Enedis",[4.581561147764407, 48.72400633731364]],
    [ "Enedis",[4.585767626910244, 48.72425687876585]],
    [ "Enedis",[1.1536272508250824, 49.47055356105609]],
    [ "Enedis",[1.1532611363040626, 49.46026481345565]],
    [ "Enedis",[1.1527405328874032, 49.46762928316611]],
    [ "Enedis",[1.1554775516828029, 49.465414802674054]],
    [ "Enedis",[1.144482282666666, 49.46446177333333]],
    [ "Enedis",[1.1646712107155708, 49.468467055375775]],
    [ "Enedis",[1.144955725314183, 49.467118053062045]],
    [ "Enedis",[1.1647908315964364, 49.467484003926906]],
    [ "Enedis",[1.1528112975391498, 49.4628760671141]],
    [ "Enedis",[1.164208, 49.469994500000006]],
    [ "Enedis",[1.1470811775121736, 49.46515395042054]],
    [ "Enedis",[1.1477303504380476, 49.463008646224445]],
    [ "Enedis",[3.0777802135922334, 45.777369591381365]],
    [ "Enedis",[2.6045466180421033, 44.33753948930671]],
    [ "Enedis",[2.632742, 44.3360595]],
    [ "Enedis",[-2.981449554744525, 47.95490906326034]],
    [ "Enedis",[4.581730776255629, 48.736547905340686]],
    [ "Enedis",[4.584289866214223, 48.73235295066558]],
    [ "Enedis",[4.593527062019913, 48.73291952424701]],
    [ "Enedis",[4.596799187654158, 48.73385282525012]],
    [ "Enedis",[4.5886973211099695, 48.73075940962328]],
    [ "Enedis",[4.591560403563223, 48.72806476580226]],
    [ "Enedis",[4.592007311843957, 48.73391372415555]],
    [ "Enedis",[4.581234115756652, 48.72174559564398]],
    [ "Enedis",[2.026105999999999, 50.380856]],
    [ "Électricité de France",[0.0025849959115930394, 42.87510602929909]],
    [ "Enedis",[2.0050890898321807, 50.37425217933531]],
    [ "Enedis",[4.574563816879881, 48.72855585267432]],
    [ "Enedis",[7.30936985, 47.72268325]],
    [ "Enedis",[-0.484639, 47.175725]],
    [ "Saint Césaire",[4.336207268622673, 43.812109141768474]],
    [ "Taveno",[-2.9069241815734985, 47.55020514367312]],
    [ "Transfo",[-2.898220631677313, 47.54323327433168]],
    [ "Enedis",[-2.9037984, 47.546945400000006]],
    [ "Rue du Crouesty",[-2.8938004000000004, 47.54904180000001]],
    [ "Enedis",[1.9727225, 50.3974245]],
    [ "Enedis",[1.976763178669325, 50.396268534595904]],
    [ "Enedis",[2.975829, 50.4936425]],
    [ "Enedis",[2.966378235900608, 50.49717115413959]],
    [ "Enedis",[2.9392780241735266, 50.486513681040115]],
    [ "Enedis",[2.956574626286386, 50.49363008066255]],
    [ "Enedis",[2.9237133362995924, 50.47266350611791]],
    [ "Enedis",[2.9423685717761554, 50.48939330900244]],
    [ "Enedis",[2.943102165149337, 50.499397559175854]],
    [ "Enedis",[2.9494200568181816, 50.49486034280304]],
    [ "Enedis",[2.954667442087617, 50.486794267439315]],
    [ "Enedis",[2.9581155, 50.495526]],
    [ "Enedis",[2.9641604999999998, 50.4909045]],
    [ "Enedis",[2.940893218106995, 50.49504134156379]],
    [ "Enedis",[2.96887523643506, 50.4895825469351]],
    [ "Enedis",[2.966046220779221, 50.495248809523815]],
    [ "Enedis",[2.954336477205447, 50.49608482948489]],
    [ "Enedis",[2.9719225, 50.492806]],
    [ "Enedis",[2.94079641918295, 50.47396562522203]],
    [ "Enedis",[2.964566693595837, 50.4940289699027]],
    [ "Enedis",[2.9723027997943974, 50.4944086383963]],
    [ "Enedis",[2.9636685000000003, 50.47545950000001]],
    [ "Enedis",[2.9676430000000003, 50.503276666666665]],
    [ "Enedis",[2.9424669999999993, 50.48776999999999]],
    [ "Enedis",[2.945187682460516, 50.490810151704075]],
    [ "Enedis",[2.955525202666667, 50.500694157333335]],
    [ "Enedis",[2.9429512513661207, 50.497291409836066]],
    [ "Enedis",[2.9695721631975864, 50.49490517375565]],
    [ "Enedis",[2.9530831664299244, 50.492639009943176]],
    [ "Enedis",[2.978174477393745, 50.4916260144254]],
    [ "Enedis",[2.9462574999999993, 50.49836150000001]],
    [ "Enedis",[2.9515723278011357, 50.48952351183891]],
    [ "Enedis",[2.9234788255707764, 50.47417998356163]],
    [ "Enedis",[2.96253350450878, 50.48275966650845]],
    [ "Enedis",[2.9377956738170337, 50.48876733480547]],
    [ "Enedis",[2.9850833333333333, 50.490324]],
    [ "Enedis",[2.9481277771265404, 50.48939610414788]],
    [ "Enedis",[2.9393551904761908, 50.47593753968255]],
    [ "Enedis",[2.958831870466321, 50.472992753022446]],
    [ "Enedis",[2.9828916237466028, 50.49117150801237]],
    [ "Enedis",[2.944830906602254, 50.49686866988727]],
    [ "Enedis",[2.9560635691056905, 50.48995433333332]],
    [ "Enedis",[2.9456408370079536, 50.48951283569919]],
    [ "Enedis",[2.9819953222886944, 50.49301372779569]],
    [ "Enedis",[2.9669645006369425, 50.49167161146497]],
    [ "Enedis",[2.9477362068849704, 50.48551533467674]],
    [ "Enedis",[2.9730130747663535, 50.4913044140187]],
    [ "Enedis",[2.9526645635539897, 50.493891858125686]],
    [ "Enedis",[2.9518835830649204, 50.48259252251092]],
    [ "Enedis",[2.9572743315892738, 50.491689689775455]],
    [ "Enedis",[2.9546169286965505, 50.48554607738279]],
    [ "Enedis",[2.950947969389571, 50.49158125093433]],
    [ "Enedis",[2.9494691309823673, 50.48678959445844]],
    [ "Enedis",[2.9536477028102155, 50.4908168194106]],
    [ "Enedis",[-0.8533658999999997, 46.67416924999999]],
    [ "Enedis",[-0.8482596500000005, 46.67592545]],
    [ "Enedis",[-0.8443115111603647, 46.67767157214313]],
    [ "Enedis",[-0.8445869492030098, 46.67215446668044]],
    [ "Enedis",[-0.8654515000000009, 46.67802050000002]],
    [ "Enedis",[-0.8496422666666665, 46.67764516666666]],
    [ "Enedis",[-0.8683078163957241, 46.66092880419247]],
    [ "École de Pourchery",[6.060042507775755, 45.14989642372163]],
    [ "Enedis",[2.897948326743812, 50.45046516481839]],
    [ "Enedis",[2.8911196773807895, 50.44528766148113]],
    [ "Enedis",[2.894089661027011, 50.4472704936183]],
    [ "Enedis",[2.8963384153005465, 50.4411348579235]],
    [ "Enedis",[2.8974428205128198, 50.44864817948718]],
    [ "Enedis",[2.8907674558960075, 50.44127019220056]],
    [ "Enedis",[2.909377727969349, 50.448753323116215]],
    [ "Enedis",[2.9018671451310856, 50.43758246535582]],
    [ "Enedis",[2.894767306868305, 50.4439155047259]],
    [ "Enedis",[2.9016891936210785, 50.4494941073843]],
    [ "Enedis",[2.876837869885071, 50.444533455091616]],
    [ "Enedis",[2.915470473540645, 50.44871477086743]],
    [ "Enedis",[2.9010564776032575, 50.44142267015707]],
    [ "Enedis",[2.9056078167577417, 50.45648284568306]],
    [ "Enedis",[2.8755420199818356, 50.44173592824705]],
    [ "Enedis",[2.934020357959542, 50.46428018029903]],
    [ "Enedis",[2.904415837768176, 50.443106409824885]],
    [ "Enedis",[2.8760716616188944, 50.44302421597259]],
    [ "Enedis",[2.9020977160250077, 50.452731656465936]],
    [ "Enedis",[2.90669100120337, 50.442895399518655]],
    [ "Enedis",[2.9117384097140606, 50.44987125303564]],
    [ "Enedis",[2.88486143599598, 50.440801446445626]],
    [ "Enedis",[2.881748066308244, 50.44414411362008]],
    [ "Enedis",[2.8976153333333334, 50.45069648226951]],
    [ "Enedis",[2.9112199999999997, 50.44775180246914]],
    [ "Enedis",[2.899430173411919, 50.44436570491159]],
    [ "Enedis",[2.9087825182571363, 50.4455170727217]],
    [ "Enedis",[2.8804715477832517, 50.44189815402299]],
    [ "Enedis",[2.9137692580878096, 50.445370940207965]],
    [ "Enedis",[2.9053321946679285, 50.45332807319766]],
    [ "Enedis",[2.9033011668897815, 50.44707522668451]],
    [ "Enedis",[2.8841001881673884, 50.438546558152964]],
    [ "Enedis",[2.320809732885529, 48.66406387300585]],
    [ "Enedis",[2.335279820576585, 48.6663437274898]],
    [ "Séquigny",[2.3300509166674237, 48.65758551014979]],
    [ "Sika",[2.336908487892768, 48.6596171093921]],
    [ "Victor",[2.3258121096098887, 48.65921625492642]],
    [ "Enedis",[2.330196367215961, 48.66346897726873]],
    [ "Enedis",[2.3270434855072475, 48.665921944994416]],
    [ "Enedis",[2.3345528572359426, 48.6624004463244]],
    [ "Enedis",[2.332108292122066, 48.65500122633068]],
    [ "Enedis",[2.3314299609018643, 48.659040013713685]],
    [ "Enedis",[2.336238859244339, 48.657101653246045]],
    [ "Abraham",[2.336061942087259, 48.66141423408281]],
    [ "Enedis",[2.3294577284877174, 48.66001862731389]],
    [ "Enedis",[2.3265297748755573, 48.65640691194487]],
    [ "Bouton",[2.327354721049353, 48.662455237881105]],
    [ "Enedis",[3.6024140000000004, 49.6133725]],
    [ "Enedis",[3.6052070558766856, 49.61622517148362]],
    [ "Enedis",[4.585480626573863, 48.71448351108938]],
    [ "Enedis",[3.6089365397788615, 49.61567142869396]],
    [ "Enedis",[4.588671238100479, 48.72211955033918]],
    [ "Enedis",[4.594385330491713, 48.72248954994298]],
    [ "Enedis",[4.6029804500000004, 48.725123499999995]],
    [ "La Brugière",[4.58449125, 44.8214876]],
    [ "Enedis",[-1.0753021499999997, 46.527936]],
    [ "Enedis",[-1.0570799782477112, 46.5650677052256]],
    [ "Enedis",[-1.0585270999999998, 46.550653849999996]],
    [ "Enedis",[-1.06187295, 46.55574595000001]],
    [ "Enedis",[-1.0504252499999998, 46.554373999999996]],
    [ "Enedis",[-1.0695675, 46.526147099999996]],
    [ "Enedis",[-1.03936075, 46.542029400000004]],
    [ "Enedis",[-1.06766395, 46.54692334999999]],
    [ "Enedis",[-1.0623162845081338, 46.55937403261306]],
    [ "Enedis",[-1.0657115, 46.55857825]],
    [ "Enedis",[-1.060707361257764, 46.55284882399888]],
    [ "Enedis",[-1.0544256500000002, 46.551747500000005]],
    [ "Enedis",[-1.06831385, 46.5278011]],
    [ "Enedis",[-1.0845826190241066, 46.56444485418986]],
    [ "Enedis",[-1.0731934556378715, 46.53126858196476]],
    [ "Enedis",[-1.0644244167239254, 46.52546790024141]],
    [ "Enedis",[-1.0572478113814374, 46.55532652164092]],
    [ "Nt ZAC Patures 1",[2.218593850000001, 48.915531400000006]],
    [ "Enedis",[2.181183180818028, 48.89371496433278]],
    [ "RTE",[-1.0001948143696537, 47.35871521038603]],
    [ "RTE",[-1.0194651458744413, 46.881857740127266]],
    [ "RTE",[-1.3130475907780628, 47.2656660211389]],
    [ "Enedis",[-2.72253067660184, 47.565208454397904]],
    [ "Enedis",[-2.713494, 47.5770874]],
    [ "Enedis",[-2.7156119999999997, 47.56246]],
    [ "Enedis",[-2.7091076655367234, 47.5721219841808]],
    [ "RTE",[-1.1801428482466416, 47.37766254543114]],
    [ "Enedis",[3.714776115657392, 49.52771170812874]],
    [ "Enedis",[3.70691058392366, 49.52929512628174]],
    [ "Enedis",[5.38044075, 46.586617499999996]],
    [ "RTE",[-0.0919246949907119, 47.702305094027494]],
    [ "Enedis",[6.443229383868169, 44.21822255816513]],
    [ "Enedis",[7.29672625, 47.717816]],
    [ "Enedis",[7.302244500000001, 47.717687]],
    [ "Enedis",[7.301380450949719, 47.72320093371638]],
    [ "Enedis",[5.519833001086778, 47.00203251246171]],
    [ "Enedis",[3.7367550452978833, 49.58492683825702]],
    [ "Enedis",[2.3960216699761756, 51.04436924968694]],
    [ "RTE",[4.597711695855903, 44.816303591505]],
    [ "RTE",[2.653119535421955, 48.60264837197286]],
    [ "Enedis",[-0.958618960607151, 46.75696182486606]],
    [ "Enedis",[-0.9723349499999999, 46.73909305]],
    [ "Enedis",[-0.9552002500000001, 46.754461400000004]],
    [ "Domaine du Lorier",[0.10951214082521217, 47.991909523770886]],
    [ "Enedis",[0.1421402000000002, 47.98410975]],
    [ "Parking de la Sar",[0.10815037383271069, 47.994117675156055]],
    [ "RTE",[2.8216209931661447, 50.30028413411161]],
    [ "Sous-station Les Suisses",[2.2988156512565023, 48.803612220072395]],
    [ "Enedis",[-0.625584, 43.109972]],
    [ "Enedis",[4.462309747226968, 47.54215355759026]],
    [ "Enedis",[4.441235440860215, 47.53382047956988]],
    [ "Enedis",[4.445185000000001, 47.535776]],
    [ "Enedis",[4.465908999999999, 47.539314499999996]],
    [ "Enedis",[4.4733435, 47.545132499999994]],
    [ "Enedis",[4.459316462392168, 47.542805187355825]],
    [ "Enedis",[4.469555377447336, 47.53990068946717]],
    [ "Enedis",[4.447961891107413, 47.54241071584396]],
    [ "Enedis",[-0.3652902474786667, 48.590493726661485]],
    [ "Enedis",[-0.3618385, 48.598130999999995]],
    [ "Enedis",[-0.35896832761421993, 48.590003812959]],
    [ "Enedis",[-0.35398660186263137, 48.591706860690714]],
    [ "Enedis",[-0.359651333333335, 48.59471500000001]],
    [ "Enedis",[-0.35789621475981576, 48.58725618584768]],
    [ "Enedis",[-0.361758, 48.5967885]],
    [ "Enedis",[-0.3562286665893089, 48.592621833217294]],
    [ "Enedis",[-0.35381249706298534, 48.595216229006624]],
    [ "Enedis",[-0.3585852775014921, 48.58876066374909]],
    [ "Enedis",[-0.364004387558645, 48.58918946507154]],
    [ "Enedis",[-0.34958199999999995, 48.595147]],
    [ "Enedis",[-0.34245799184425146, 48.59797933385951]],
    [ "Enedis",[-0.3567227513675064, 48.599372060434256]],
    [ "Enedis",[-0.33486950000000015, 48.605129]],
    [ "Enedis",[-0.36354708000000036, 48.60187577333333]],
    [ "Enedis",[-0.3692395, 48.5745295]],
    [ "Enedis",[2.228834394697737, 46.301088317426554]],
    [ "Enedis",[2.455461488630061, 50.50351062174154]],
    [ "Enedis",[2.4436806296296303, 50.50079514814815]],
    [ "Enedis",[2.4605731864406777, 50.502059448066056]],
    [ "Enedis",[0.1513783401375642, 47.651652140102165]],
    [ "SER",[7.376109337544133, 48.76143209199781]],
    [ "Enedis",[1.904303442424242, 47.610627300606055]],
    [ "Enedis",[5.87013475, 48.6730709]],
    [ "Enedis",[5.868338260782548, 48.673539452116486]],
    [ "Allées d'Écrouves",[5.867508372013651, 48.68322956769055]],
    [ "Bautzen",[5.866957125403788, 48.680396991683644]],
    [ "Enedis",[5.8758766, 48.67445035]],
    [ "Enedis",[1.3566885198495613, 47.57288615865719]],
    [ "Enedis",[1.3685871891891892, 47.570302684684684]],
    [ "Enedis",[1.370758785440613, 47.56475440102172]],
    [ "Enedis",[1.3691079254125413, 47.561829277887796]],
    [ "Enedis",[1.3728816771168273, 47.565449076634515]],
    [ "Enedis",[1.3645989770114961, 47.56231033333333]],
    [ "Enedis",[1.362974349726776, 47.57556824590165]],
    [ "Enedis",[1.3639534459833798, 47.56108536380424]],
    [ "Enedis",[1.357422999999999, 47.5691295]],
    [ "Enedis",[1.368314991810737, 47.56692216954807]],
    [ "Enedis",[1.3550024881141047, 47.571464977812994]],
    [ "Enedis",[1.366694, 47.564493999999996]],
    [ "Enedis",[1.3672796675651395, 47.568470504043134]],
    [ "Enedis",[1.3642196521926984, 47.56852791337223]],
    [ "Enedis",[1.3573492545454546, 47.56359308484848]],
    [ "Enedis",[1.3684554999999992, 47.563388499999995]],
    [ "Enedis",[1.3597342441427045, 47.568930758785946]],
    [ "Enedis",[1.3619559047198506, 47.56480399411245]],
    [ "Poste électrique du Soler",[2.8222275556003398, 42.681248840436794]],
    [ "Poste électrique de Ganil",[-0.3643645226450658, 49.21546416247918]],
    [ "Poste Électrique de Verneuil-sur-Avre",[0.9254954139155421, 48.75274951602394]],
    [ "RTE",[0.7035604797948024, 48.803019881382525]],
    [ "Orbec",[0.38616339999999905, 49.0176936]],
    [ "RTE",[1.2154245323843795, 48.774092053938894]],
    [ "RTE",[1.07675032196682, 48.85957736352468]],
    [ "Línea Figueras Perpignan S.A.",[2.8453810953574035, 42.607007545406056]],
    [ "Línea Figueras Perpignan S.A.",[2.857843074891619, 42.52682700441539]],
    [ "RTE",[2.8155359060881926, 50.29331916617713]],
    [ "Enedis",[1.5549173306233064, 48.649721990063235]],
    [ "Enedis",[1.5564076797720783, 48.6365610119658]],
    [ "Enedis",[1.5523270000000002, 48.647352500000004]],
    [ "Enedis",[1.5432321659487176, 48.65129849517948]],
    [ "RTE",[3.145419578497423, 48.31753352793677]],
    [ "RTE",[2.9611975903288417, 48.37126175222614]],
    [ "Poste électrique de Massy",[2.279912421530235, 48.722678861367136]],
    [ "Enedis",[-1.1960147500000005, 47.01207825000001]],
    [ "Enedis",[-1.1988532432461523, 47.01634548192163]],
    [ "Enedis",[-1.1996527332923408, 47.014730700756786]],
    [ "Enedis",[-1.192776, 47.013451849999996]],
    [ "Enedis",[4.3438755, 47.4988425]],
    [ "Enedis",[4.353333803813881, 47.49261847871854]],
    [ "Enedis",[4.342771704000764, 47.490234547216645]],
    [ "Enedis",[4.346838833171238, 47.4940561685794]],
    [ "Enedis",[4.342513004997502, 47.49539070414792]],
    [ "Enedis",[4.350857754929578, 47.493143653051646]],
    [ "Enedis",[4.355098, 47.49483849999999]],
    [ "Enedis",[4.335175171717172, 47.49686200598579]],
    [ "Enedis",[4.3504906858974355, 47.48635904807693]],
    [ "Enedis",[4.35249432078193, 47.500574924230484]],
    [ "Enedis",[4.343415246025923, 47.48639710247003]],
    [ "Enedis",[4.350685226737339, 47.48858786248527]],
    [ "Enedis",[4.328937121652037, 47.49027192437542]],
    [ "Enedis",[4.325447, 47.495194999999995]],
    [ "Enedis",[4.353103999999998, 47.48498899999999]],
    [ "Enedis",[4.325287974186307, 47.49169917845118]],
    [ "Enedis",[4.350663875555556, 47.48390466222222]],
    [ "Enedis",[4.327408448871181, 47.4841245648517]],
    [ "Enedis",[4.353689928090298, 47.49371546386311]],
    [ "Enedis",[4.333100845196759, 47.48988467274305]],
    [ "Enedis",[3.283949066528067, 49.66646082189883]],
    [ "Enedis",[3.2852226802847424, 49.672131524399056]],
    [ "Enedis",[3.2868048495882105, 49.66311060294755]],
    [ "Enedis",[3.283109856826976, 49.6631770525152]],
    [ "RTE",[2.634731143738334, 50.004868843167905]],
    [ "RTE",[0.637425723930676, 42.91143978514515]],
    [ "Enedis",[3.2847503224844052, 49.65065680688907]],
    [ "Enedis",[3.2863695, 49.644534]],
    [ "Enedis",[3.3000369751243785, 49.67066160696517]],
    [ "Enedis",[3.3008054057758205, 49.675336696035245]],
    [ "Enedis",[3.2978061714285714, 49.66760201904763]],
    [ "Enedis",[3.5553283467055685, 48.23361701239971]],
    [ "Enedis",[3.560515723399559, 48.233354378101545]],
    [ "Enedis",[3.5576078404928406, 48.234679880119884]],
    [ "Enedis",[3.561872751243781, 48.23661854726368]],
    [ "Enedis",[5.437184177083333, 46.57137997159091]],
    [ "Poste électrique de Chaunay",[1.4491935081882819, 48.413699229772284]],
    [ "Enedis",[6.307362623068263, 45.046351587864]],
    [ "Ventelon",[6.3077414942208, 45.051776856564906]],
    [ "Enedis",[1.3058463963332902, 47.58415187342068]],
    [ "Enedis",[1.302639436261368, 47.5842938655312]],
    [ "Enedis",[1.2986394161849713, 47.581331421965324]],
    [ "Enedis",[1.3005928280134094, 47.58036810989652]],
    [ "Enedis",[1.3090394946852002, 47.58002066857454]],
    [ "Enedis",[1.3021308047771074, 47.57750433498878]],
    [ "Enedis",[1.3285260771084328, 47.56782684658635]],
    [ "Enedis",[1.3213579213973803, 47.56469484352256]],
    [ "Enedis",[1.3283833448417597, 47.56510697205096]],
    [ "Enedis",[1.321145453216374, 47.56760823976608]],
    [ "Enedis",[1.331762756150902, 47.565212573719705]],
    [ "Enedis",[1.3334446372980904, 47.5711117195301]],
    [ "Enedis",[1.3323399611307414, 47.569090070671386]],
    [ "Enedis",[1.329542816925735, 47.57131947668394]],
    [ "Enedis",[1.3258712424242427, 47.57235212121211]],
    [ "Enedis",[1.3329213149971058, 47.57422962748503]],
    [ "Enedis",[1.3377330210083598, 47.573850963293125]],
    [ "Enedis",[1.3400971743119268, 47.57412998165138]],
    [ "Enedis",[-1.5694851521104365, 48.89045478461982]],
    [ "Enedis",[6.114679165005537, 45.930207636877064]],
    [ "Violettes",[7.422457900931602, 47.722492399982855]],
    [ "École maternelle",[7.425089754960252, 47.72424960134692]],
    [ "C.E.F. Habsheim",[7.425267900000001, 47.723062]],
    [ "Enedis",[3.692847000000001, 49.26805650000001]],
    [ "Enedis",[7.35026675, 47.66198575]],
    [ "Enedis",[3.3011664893018016, 49.651988490990995]],
    [ "Enedis",[3.299277500000001, 49.6553125]],
    [ "Enedis",[3.29996330117647, 49.65658016313726]],
    [ "EDF",[0.5536387856912185, 42.732561043586905]],
    [ "Enedis",[3.321945814765102, 49.655301714988816]],
    [ "Enedis",[3.323771716014236, 49.65555153546857]],
    [ "Enedis",[-1.11012025, 47.01640925]],
    [ "Enedis",[3.348534677813906, 47.720285041618254]],
    [ "Enedis",[2.4573426522309716, 50.52138817454069]],
    [ "Enedis",[2.4700931492227984, 50.54213268877374]],
    [ "Poste électrique de La Chapelle du Bard",[6.100097161023844, 45.43027918353074]],
    [ "RTE",[1.1722391474982146, 42.963835016334315]],
    [ "RTE",[1.125964676616176, 42.97942150891992]],
    [ "RTE",[1.203933415503493, 42.87217523061435]],
    [ "Enedis",[1.314986281266217, 48.07888231171078]],
    [ "RTE",[3.4909933098987374, 50.17698250465646]],
    [ "RTE",[3.557385455377907, 49.89891140008564]],
    [ "Enedis",[7.4046994999999995, 47.56232874999999]],
    [ "Enedis",[7.400460043581845, 47.564999432914426]],
    [ "SNCF Réseau",[3.1312768375605575, 48.368779385654335]],
    [ "Enedis",[7.379644000000002, 47.58531775000001]],
    [ "Enedis",[7.385362999999999, 47.58464774999999]],
    [ "Enedis",[3.1279660151515163, 48.355472294612795]],
    [ "Enedis",[3.137717000000001, 48.33990300000001]],
    [ "RTE",[1.0289709439579162, 42.896173637515794]],
    [ "EDF",[1.426505771117925, 42.71032991256648]],
    [ "RTE",[1.489962055804572, 42.7146145890559]],
    [ "Enedis",[-0.01484109364198984, 47.80377881906599]],
    [ "RTE",[2.9539073952077874, 50.31401217390367]],
    [ "RTE",[2.945546968667354, 50.31555508369289]],
    [ "Enedis",[4.576674485892468, 48.727444414439844]],
    [ "Enedis",[4.58065251593489, 48.72917081000928]],
    [ "Enedis",[4.581124700000002, 48.72754519999999]],
    [ "RTE",[1.864488081866396, 42.71005080386747]],
    [ "Poste électrique du Teich",[1.8413873088867707, 42.71867400707455]],
    [ "Enedis",[1.3224197508778308, 48.07946850040345]],
    [ "RTE",[5.356115430345801, 44.761482135852425]],
    [ "RTE",[1.7876727952237512, 42.74144223284387]],
    [ "Enedis",[4.3777402, 47.512155099999994]],
    [ "Enedis",[4.59812618413413, 48.72705431806997]],
    [ "Enedis",[4.599497324835265, 48.72937435872844]],
    [ "RTE",[3.417190833857827, 50.13390684725688]],
    [ "Enedis",[0.35264515, 46.56681465]],
    [ "Enedis",[1.3183236929201636, 48.07550638325802]],
    [ "Enedis",[2.109979142232775, 42.60557756221007]],
    [ "Poste électrique d'Aureilhan",[0.09147761473637141, 43.23756556786841]],
    [ "Enedis",[2.482580329614969, 50.517805501379385]],
    [ "RTE",[1.8337658840128352, 42.922133321342436]],
    [ "Enedis",[0.6098264999999999, 44.139667499999995]],
    [ "Poste électrique de Valréas",[4.977416113375934, 44.3937433766241]],
    [ "CERN",[6.054569149431104, 46.25465991049672]],
    [ "RTE",[3.5628189295547954, 50.398109925179895]],
    [ "RTE",[1.5932675327574393, 43.169930195557036]],
    [ "RTE",[0.41521788634883516, 44.20588316207895]],
    [ "Enedis",[3.5329787489177487, 50.252555588744585]],
    [ "Enedis",[3.796798784832451, 49.65778865608466]],
    [ "Enedis",[3.7921126810740016, 49.65527453721895]],
    [ "Enedis",[7.562412300391615, 48.28766954042847]],
    [ "Enedis",[7.561989387197725, 48.28451928525368]],
    [ "Enedis",[7.543524500000001, 48.289256]],
    [ "Enedis",[3.5479467385229535, 50.23888450698603]],
    [ "Enedis",[7.564529970833334, 48.2895991550926]],
    [ "Enedis",[7.5433965, 48.2892515]],
    [ "Enedis",[3.54304422253277, 50.22782500748325]],
    [ "Enedis",[3.5472912282414697, 50.22921182887139]],
    [ "Enedis",[2.084613894609579, 46.30207113097786]],
    [ "Enedis",[0.3479558402808239, 48.022507797864556]],
    [ "Enedis",[3.549203631001371, 43.701394325102875]],
    [ "Enedis",[7.62170505345912, 48.281274091823896]],
    [ "SNCF Réseau",[3.9226921500000023, 47.84956435000001]],
    [ "Enedis",[3.499523, 50.265826]],
    [ "Enedis",[3.4930345, 50.268851]],
    [ "RTE",[1.551609803594306, 43.29463434407845]],
    [ "SNCF Réseau",[1.5128603612218683, 43.310121120753905]],
    [ "Enedis",[3.4761968581677722, 50.26706248304951]],
    [ "Enedis",[3.4799511072768192, 50.27085683170794]],
    [ "Enedis",[3.5149104999999996, 50.258837]],
    [ "Enedis",[3.5070975000000004, 50.263403999999994]],
    [ "RTE",[1.9645355081085476, 43.309208201421896]],
    [ "RTE",[1.9591923828473026, 43.309390218735864]],
    [ "RTE",[2.1280820719294495, 43.2424907538671]],
    [ "Enedis",[3.5245378115942017, 50.24835202557545]],
    [ "Enedis",[3.5105754440977637, 50.28588619916797]],
    [ "Enedis",[3.5681624999999997, 50.2826545]],
    [ "Enedis",[3.445647217592593, 50.325877680555564]],
    [ "Enedis",[3.4451930551452485, 50.32413432816346]],
    [ "Enedis",[3.4521241730886842, 50.33190427155962]],
    [ "Enedis",[3.4529416700144564, 50.333466522179116]],
    [ "Enedis",[3.4523333392504933, 50.32491016765286]],
    [ "Enedis",[3.448815357775416, 50.318544557917114]],
    [ "Enedis",[3.46078335722564, 50.33418631300703]],
    [ "Enedis",[3.4607073523592087, 50.335916799847794]],
    [ "Enedis",[3.453846996884735, 50.32423722741432]],
    [ "Enedis",[3.454603666666667, 50.32244799086758]],
    [ "Enedis",[3.455285, 50.3203725]],
    [ "Enedis",[3.457366432302405, 50.341900471821305]],
    [ "Enedis",[3.4558038147938404, 50.33566447931642]],
    [ "Enedis",[3.4478711611956245, 50.31789699252736]],
    [ "Enedis",[3.449247886920437, 50.332602830580164]],
    [ "Enedis",[3.4533034187640954, 50.33498291560668]],
    [ "Enedis",[2.8957143000000007, 48.06368]],
    [ "SNCF Réseau",[3.6708921501160146, 47.9866521506897]],
    [ "SNCF Réseau",[3.5141461223229284, 48.085003650690396]],
    [ "Enedis",[3.4370117092968933, 50.316996092501746]],
    [ "Enedis",[3.416352167659749, 50.31871555859187]],
    [ "Enedis",[2.887437285714286, 48.068835030303035]],
    [ "Enedis",[3.4293782025147803, 50.312369136480974]],
    [ "Enedis",[3.4396963773584908, 50.31641208053382]],
    [ "Enedis",[3.43450160157466, 50.316666608624004]],
    [ "Poste électrique d'Aix",[5.455147596234169, 43.53253360552285]],
    [ "Enedis",[3.487046273917421, 50.348234684793546]],
    [ "Enedis",[3.482658833487797, 50.35056649304912]],
    [ "Enedis",[3.485282170402299, 50.34936401551724]],
    [ "Enedis",[3.485308705128205, 50.34731180769231]],
    [ "Enedis",[3.4827666604951184, 50.34792693080785]],
    [ "Enedis",[3.4839095, 50.34747050000001]],
    [ "Enedis",[3.46802322044241, 50.341236643783375]],
    [ "Enedis",[3.4596755000000003, 50.342695]],
    [ "Enedis",[7.6804830000000015, 48.33599600000001]],
    [ "Enedis",[7.6787407499999984, 48.332652749999994]],
    [ "Enedis",[7.67316428399594, 48.33497465156091]],
    [ "Enedis",[3.480023661271405, 50.343411701853164]],
    [ "Enedis",[7.685298000000001, 48.328828]],
    [ "Poste électrique de Hazebrouck",[2.5362855862102602, 50.7130406770176]],
    [ "RTE",[2.390989188312097, 50.6923878979777]],
    [ "Sous-station de Cuperly",[4.42031660274529, 49.05380379346218]],
    [ "Poste électrique de Batavia",[2.30518175, 50.723871749999994]],
    [ "Enedis",[3.321551597258065, 45.371454625860224]],
    [ "Enedis",[4.099997350000001, 43.714187800000005]],
    [ "Enedis",[4.112482830303031, 43.723122664242425]],
    [ "Enedis",[6.162808779591204, 48.695128402656444]],
    [ "Enedis",[6.164541020042519, 48.69345350696556]],
    [ "Enedis",[4.11100460047142, 43.72969925417403]],
    [ "Enedis",[3.574419753682303, 50.30740077883526]],
    [ "Enedis",[3.5761699793814445, 50.31256064604812]],
    [ "Enedis",[3.5762318260635433, 50.30699833602585]],
    [ "Enedis",[3.5720516772554003, 50.31073784201609]],
    [ "Enedis",[3.5723015000000005, 50.313205999999994]],
    [ "Enedis",[3.5691009712762805, 50.314356122100484]],
    [ "Enedis",[3.559943510048511, 50.29385052390852]],
    [ "Enedis",[3.5711230594059424, 50.2928343729373]],
    [ "Enedis",[3.5754989999999998, 50.2914455]],
    [ "Enedis",[3.578343824223603, 50.297599636853015]],
    [ "Enedis",[3.5759501781852374, 50.295541901269395]],
    [ "Enedis",[3.583042393870169, 50.29253075804302]],
    [ "Enedis",[3.620269716997793, 50.28199683134658]],
    [ "Enedis",[3.629662917293355, 50.27638708235439]],
    [ "Enedis",[3.678683630100688, 50.31852594091151]],
    [ "RTE",[2.2307247223185263, 43.06158987750021]],
    [ "Enedis",[3.6512056746605754, 50.351331476589266]],
    [ "Enedis",[3.6556683102386445, 50.34853392840646]],
    [ "Enedis",[3.6525860869013638, 50.33524151538218]],
    [ "Enedis",[3.6524066748495794, 50.33949116149218]],
    [ "Enedis",[3.645676631342325, 50.34693078676942]],
    [ "2359",[6.011850752165441, 46.277308167641294]],
    [ "Enedis",[6.72568225, 44.932843500000004]],
    [ "Enedis",[6.722696870783638, 44.93208345803656]],
    [ "Enedis",[2.476363318128026, 48.78941159268291]],
    [ "Enedis",[2.4811435148755105, 48.78715494479864]],
    [ "Enedis",[2.4757909006229015, 48.79106373914621]],
    [ "SNCF Réseau",[3.4583600134450214, 50.05500644268175]],
    [ "Enedis",[2.492185614265561, 48.78979483448895]],
    [ "Enedis",[2.488955046439938, 48.79084836177806]],
    [ "Enedis",[2.502915022231248, 48.78690274102214]],
    [ "Enedis",[2.502490879602921, 48.7853785547254]],
    [ "Enedis",[2.501162967852709, 48.78830448969909]],
    [ "Enedis",[2.484393611008656, 48.78971002935014]],
    [ "Enedis",[2.4800439555173877, 48.79260991793957]],
    [ "Enedis",[2.485425712595117, 48.793125538402435]],
    [ "Enedis",[2.486981586133223, 48.79056974385674]],
    [ "Enedis",[2.4861917841073895, 48.78706608734293]],
    [ "Poste électrique d'Haybes",[4.708791955073781, 50.00074453352149]],
    [ "Enedis",[2.5147685557758397, 48.79482916448812]],
    [ "Enedis",[2.5148844521802856, 48.78726858072446]],
    [ "Enedis",[2.5133635498143465, 48.80829973383037]],
    [ "Enedis",[2.5173644717293784, 48.79169350384717]],
    [ "Enedis",[2.516216607078082, 48.793438002275415]],
    [ "Enedis",[2.5214679557315343, 48.79408830165267]],
    [ "Enedis",[2.5170117760002246, 48.80795327562363]],
    [ "Enedis",[2.515288385730763, 48.79370662981596]],
    [ "Enedis",[2.5206603102455944, 48.80475013922162]],
    [ "Enedis",[2.512795731219638, 48.790342569685336]],
    [ "Enedis",[2.5115372855533127, 48.78735572789938]],
    [ "Enedis",[2.5146178631087075, 48.805615218026624]],
    [ "Enedis",[4.027830249999997, 43.727663750000005]],
    [ "RTE",[2.2003895682111447, 42.935525612201054]],
    [ "Enedis",[2.5092893534572154, 48.79397014814235]],
    [ "Enedis",[2.5030107531431764, 48.7920042737528]],
    [ "Enedis",[2.5052694662997306, 48.79264688466968]],
    [ "Enedis",[2.5029322858168523, 48.79606927376105]],
    [ "Enedis",[2.507162081187771, 48.79131637006526]],
    [ "Enedis",[2.5102503328261014, 48.80108577307808]],
    [ "Enedis",[2.508408714066251, 48.80833393234858]],
    [ "Enedis",[2.480459902895414, 48.810393171416735]],
    [ "Enedis",[2.249878499999999, 49.416928]],
    [ "Enedis",[3.1050026712172927, 48.32409749374288]],
    [ "Enedis",[3.7033815973389355, 50.2705573545168]],
    [ "Enedis",[3.649558433121018, 49.6347646581741]],
    [ "Enedis",[2.475808066666666, 48.796807388805014]],
    [ "Enedis",[2.493422863031213, 48.79695987527198]],
    [ "Enedis",[2.488044395635767, 48.793046907988824]],
    [ "Enedis",[2.4883949036276025, 48.80315211681046]],
    [ "Enedis",[2.0465075, 46.27189849999999]],
    [ "Enedis",[2.50876643477743, 48.80678249545709]],
    [ "Enedis",[2.496086494957714, 48.78599759364928]],
    [ "RTE",[1.9012094227008793, 42.4590706688049]],
    [ "Calanque de la Louve",[6.456794831325301, 43.1602821686747]],
    [ "Fontiede",[6.454110666338691, 43.159305507707444]],
    [ "Canadel",[6.464590106382979, 43.15923631205674]],
    [ "San Marino",[6.4684285119047615, 43.157576625]],
    [ "Paradou",[6.45861761764706, 43.157306735294114]],
    [ "Ecuelle",[6.455319779294654, 43.15637553242321]],
    [ "Enedis",[3.5001065000000002, 45.9856981]],
    [ "Poste électrique de Saint-Amand",[3.4355729752838307, 50.43388971115975]],
    [ "Enedis",[4.094851204460234, 45.611382766528514]],
    [ "Enedis",[2.972734249999999, 48.05610025]],
    [ "Bourg Poncé",[0.6563845211826213, 47.76181632341806]],
    [ "Enedis",[3.6360698593125504, 50.226210320543565]],
    [ "Enedis",[3.6421587462374037, 50.22323315796361]],
    [ "Enedis",[3.6894450238622385, 50.2250816305043]],
    [ "Enedis",[3.676907365256715, 50.22738543894653]],
    [ "Enedis",[3.6971866757182204, 50.24661860842189]],
    [ "Enedis",[3.653277872428121, 50.21288605938816]],
    [ "Enedis",[3.4729432994896574, 50.22414554042439]],
    [ "Enedis",[3.4793265735681493, 50.2180038393919]],
    [ "Enedis",[3.4830620838323356, 50.22019069660678]],
    [ "Enedis",[3.481701736889154, 50.21668802810886]],
    [ "Enedis",[3.4857849999999995, 50.216483]],
    [ "Enedis",[3.4808116068012747, 50.215044123627344]],
    [ "Enedis",[3.4720267645895144, 50.21854354995055]],
    [ "Enedis",[3.4693098224290493, 50.33932725353537]],
    [ "Enedis",[3.4772430371929826, 50.34240246760234]],
    [ "Enedis",[3.4660983750892225, 50.33600724143469]],
    [ "Enedis",[3.5066721858247893, 50.33729598452958]],
    [ "Enedis",[3.505152193667498, 50.333697343302866]],
    [ "Enedis",[3.5057358946236556, 50.33497515627239]],
    [ "Enedis",[3.479884, 50.32832700000001]],
    [ "Enedis",[3.481563908183632, 50.32728724351297]],
    [ "Enedis",[3.480201236820763, 50.32559214355231]],
    [ "Enedis",[3.4772953963705824, 50.3267262408787]],
    [ "Enedis",[3.4778082944673065, 50.3292424508459]],
    [ "Enedis",[3.4944194763135226, 50.329383837209306]],
    [ "Enedis",[3.4911569166666663, 50.32766025]],
    [ "Enedis",[3.4868890604630254, 50.323578669139124]],
    [ "Enedis",[3.484330139963167, 50.3249563130755]],
    [ "Enedis",[3.4837279660652927, 50.32451330412371]],
    [ "Enedis",[3.473789679860729, 50.31816282316291]],
    [ "Enedis",[3.4878325, 50.3271145]],
    [ "Enedis",[3.481671641430073, 50.33491427339642]],
    [ "Enedis",[3.4831333758182508, 50.3345832466949]],
    [ "Enedis",[3.48420908438194, 50.33672825339254]],
    [ "Enedis",[3.483654352843994, 50.31665408976477]],
    [ "Enedis",[2.4886358828828827, 48.80503135462735]],
    [ "Enedis",[3.4934510000000008, 50.31143]],
    [ "Enedis",[3.4897861698442365, 50.30491919987539]],
    [ "Enedis",[3.4933479999999997, 50.309160999999996]],
    [ "Enedis",[3.4959220168453293, 50.309444823889734]],
    [ "Enedis",[3.474444422777331, 50.30446235849226]],
    [ "Enedis",[3.4836064025984985, 50.30931798439214]],
    [ "Poste Electrique Chapelle",[6.345049141025641, 43.1518559460189]],
    [ "Poste Enedis Faureou",[6.345051107035928, 43.11788459496008]],
    [ "Poste Enedis Cardenon",[6.3466094740113, 43.11900324463278]],
    [ "Enedis",[3.6745006681852694, 49.65091848899012]],
    [ "Genêts",[0.3276550663139304, 48.019434174947826]],
    [ "Guibé",[0.33042962055335934, 48.024970827404466]],
    [ "Le Bas Villiers",[0.31751198983326157, 48.02002967121495]],
    [ "Bourcaillon",[0.32636604681300174, 48.020512064828004]],
    [ "Marie Curie",[0.32858033333333336, 48.02053772839507]],
    [ "Enedis",[0.33634736021661277, 48.02053823063534]],
    [ "L'Huisne",[0.33363883769742303, 48.02905379925187]],
    [ "Les Landes",[0.32672717065390755, 48.02268636204147]],
    [ "Enedis",[1.883066640987654, 48.416549762962966]],
    [ "Enedis",[1.877307260162602, 48.41727114634147]],
    [ "Enedis",[1.878532131159421, 48.41316917608695]],
    [ "Poste Enedis Foyer 3eme âge",[6.339101949999999, 43.145573899999995]],
    [ "Le Grand Plantier",[6.343710266188372, 43.14050037481134]],
    [ "Poste Enedis Catalanes",[6.342243500000001, 43.1343485]],
    [ "Enedis",[0.6778924635663646, 43.08302881747571]],
    [ "Enedis",[0.6712106571233807, 43.080310183367416]],
    [ "Enedis",[5.1893945, 45.8102585]],
    [ "RTE",[5.776662618385481, 43.83101660844776]],
    [ "Enedis",[3.029186467027392, 48.07972569890655]],
    [ "Enedis",[3.0256326422413795, 48.07643831034483]],
    [ "Enedis",[3.010012, 48.099428749999994]],
    [ "Enedis",[3.0071914999999994, 48.105805000000004]],
    [ "Enedis",[3.0021938077709627, 48.0895745235174]],
    [ "Enedis",[3.0130265000000005, 48.117852000000006]],
    [ "Enedis",[3.008857314098499, 48.11566545740859]],
    [ "Enedis",[3.0171907500000006, 48.120560000000005]],
    [ "Enedis",[3.01179525120773, 48.13138556521738]],
    [ "Enedis",[3.0099788258027873, 48.12067883574879]],
    [ "Enedis",[2.973321432098766, 48.007325333333334]],
    [ "Enedis",[2.9687064999999997, 48.0051015]],
    [ "Enedis",[2.9675891815476185, 48.003553927721086]],
    [ "Enedis",[2.9687782162162164, 48.001669342342346]],
    [ "Enedis",[2.982139750000001, 48.09236324999999]],
    [ "Enedis",[1.1097506797239323, 46.69903415127774]],
    [ "Tignes les Pigettes",[6.9128020431963835, 45.53712498463986]],
    [ "Enedis",[3.0710808551448556, 47.96870516916416]],
    [ "Enedis",[2.912014, 47.964509]],
    [ "Enedis",[2.911510871746031, 47.968085694603175]],
    [ "Enedis",[2.8430142126670543, 47.95500095719543]],
    [ "Enedis",[2.8420108954595795, 47.95303987663345]],
    [ "Enedis",[2.840887927983539, 47.969736754115225]],
    [ "Enedis",[2.868617412429378, 47.98338963206215]],
    [ "Enedis",[2.8782480000000006, 47.98040300000001]],
    [ "Enedis",[2.8567103975103736, 47.95034364868603]],
    [ "Enedis",[2.8258493000000002, 47.960509050000006]],
    [ "Enedis",[2.0240440664845365, 46.30251678114566]],
    [ "Enedis",[2.0196455999999996, 46.303647999999995]],
    [ "Enedis",[2.0271301999999998, 46.2977574]],
    [ "Enedis",[2.0575184387726018, 46.29680517303539]],
    [ "RTE",[3.407258433333333, 50.33222223333333]],
    [ "Enedis",[-0.5918424761904769, 44.80349952380951]],
    [ "Enedis",[3.5293304999999995, 50.20441650000001]],
    [ "Enedis",[2.8706117471684967, 48.119497088168664]],
    [ "Enedis",[2.8706117528735633, 48.119450105831234]],
    [ "RTE",[2.1077371536667053, 50.70039495535266]],
    [ "Poste électrique de Lestrem",[2.681122192129112, 50.64009846655026]],
    [ "Enedis",[3.698000276497696, 49.40094423655914]],
    [ "175916427",[6.908227190099765, 45.535623595394604]],
    [ "Enedis",[3.52474129429581, 50.21188440972573]],
    [ "Enedis",[3.5286286125356123, 50.209145811188826]],
    [ "Enedis",[1.990029167224827, 47.89923650346059]],
    [ "Enedis",[3.6012197149758456, 50.16315217230274]],
    [ "Enedis",[3.5764798221211134, 50.17970616888744]],
    [ "Enedis",[3.505088172322022, 50.195122437791085]],
    [ "Enedis",[3.491321000000001, 50.1896695]],
    [ "Enedis",[3.5068276700554533, 50.19683393222429]],
    [ "Enedis",[3.4975892905816504, 50.191247559097924]],
    [ "Enedis",[3.5029709490291263, 50.184118322411]],
    [ "Enedis",[3.492391597861337, 50.17884817810501]],
    [ "Enedis",[3.497013335014949, 50.18476174906577]],
    [ "Enedis",[3.4846475, 50.183938000000005]],
    [ "Enedis",[3.4930944021693793, 50.18930370755111]],
    [ "Enedis",[3.487556594466062, 50.18717291742327]],
    [ "Enedis",[3.4715118188469414, 50.18807306438396]],
    [ "Enedis",[3.4887255, 50.1906615]],
    [ "Enedis",[3.482371793177454, 50.18753813701569]],
    [ "Enedis",[3.4834154810126576, 50.19049601265823]],
    [ "Enedis",[3.479865795187793, 50.18956094014084]],
    [ "Enedis",[3.441603176811595, 50.306910973913055]],
    [ "Enedis",[3.429304130853995, 50.30473234297521]],
    [ "Enedis",[3.444602822373242, 50.304906233126786]],
    [ "Enedis",[3.4521644478094675, 50.309946826717635]],
    [ "Enedis",[3.4471542900000003, 50.296027473333325]],
    [ "Enedis",[3.4515402701165763, 50.30451755084825]],
    [ "Enedis",[3.4129876762589935, 50.26198196882494]],
    [ "Enedis",[3.418963816269285, 50.25889599064983]],
    [ "Enedis",[3.4153967214611884, 50.25739554794521]],
    [ "Enedis",[3.412279439215686, 50.255767695098044]],
    [ "Enedis",[3.409313237716195, 50.26169663453224]],
    [ "Enedis",[5.116430199228755, 46.68271917788894]],
    [ "Enedis",[5.1111675, 46.6780945]],
    [ "Enedis",[5.115961, 46.679646700000006]],
    [ "Enedis",[6.452419149524632, 47.893898470757705]],
    [ "RTE",[-1.289547156258837, 46.17013377195332]],
    [ "Enedis",[1.0353880826136264, 49.51624440486784]],
    [ "Enedis",[1.0383168224043715, 49.5039950624512]],
    [ "Enedis",[1.0359046021203613, 49.507781922513246]],
    [ "Enedis",[1.0365254990166, 49.50637395035795]],
    [ "Enedis",[1.008946, 49.511879]],
    [ "Enedis",[3.4081710696218375, 47.32014457425494]],
    [ "Enedis",[1.0758377243797197, 49.55508787675296]],
    [ "Enedis",[1.0731496790123451, 49.554148320987665]],
    [ "Enedis",[1.0773070502670434, 49.5490735830977]],
    [ "Enedis",[1.1068342570806096, 49.545819350762514]],
    [ "Enedis",[2.810094875223881, 48.586705806487565]],
    [ "RTE",[1.4089947960193827, 44.83251991024708]],
    [ "Poste électrique de Melgven",[-3.833209423312197, 47.91050954256654]],
    [ "Enedis",[3.570220552986512, 49.54210065510597]],
    [ "Enedis",[3.554127573591253, 49.53673379422483]],
    [ "Enedis",[-2.6705294932362125, 47.538108772112395]],
    [ "Poste électrique de Messempré",[5.16422391070249, 49.67006272837736]],
    [ "Enedis",[3.5390088513436258, 49.55083820754717]],
    [ "ESSeyssel",[5.919257749541351, 46.010329551554456]],
    [ "Enedis",[5.9380975000000005, 46.01779699999999]],
    [ "Enedis",[5.927743499999998, 46.023047]],
    [ "Enedis",[5.9291174999999985, 46.01823399999999]],
    [ "Enedis",[5.905869895281932, 46.01510628346758]],
    [ "Enedis",[5.935172187288137, 46.02049184096046]],
    [ "Enedis",[-1.2276474999999993, 44.70711995]],
    [ "Enedis",[-1.25142925, 44.63214575]],
    [ "Enedis",[4.152642844604481, 46.45205584907252]],
    [ "Enedis",[3.155853239294168, 48.20393753066494]],
    [ "Enedis",[2.519036456439419, 48.85464518684649]],
    [ "Ners",[5.915909587298748, 46.10779186577221]],
    [ "Enedis",[4.696327349999999, 44.373134500000006]],
    [ "Enedis",[3.571043218561231, 49.54716425771554]],
    [ "Enedis",[3.574658, 49.543755250000004]],
    [ "Enedis",[3.5546845183541183, 49.55240099344748]],
    [ "Enedis",[2.9867048573947317, 50.53069196515246]],
    [ "Enedis",[2.9891164999999997, 50.53125149999999]],
    [ "Enedis",[-2.6405828500000004, 47.57779945000001]],
    [ "Enedis",[-2.629677157894735, 47.57933133333333]],
    [ "Enedis",[-2.631192177026355, 47.57638341505054]],
    [ "Enedis",[-2.6310263217439305, 47.578386770419435]],
    [ "Enedis",[-2.6907765213778667, 47.586138208454706]],
    [ "Enedis",[-2.701281765217391, 47.58527245217391]],
    [ "Enedis",[-2.7106533883379247, 47.57597397584941]],
    [ "Enedis",[1.6039123055983564, 46.985504715459676]],
    [ "Enedis",[1.6175319208633094, 46.981639678657075]],
    [ "Enedis",[1.6158523212508888, 46.973093820184786]],
    [ "Enedis",[1.6055867333333338, 46.97365899999999]],
    [ "Enedis",[1.610031332797715, 46.97809727905731]],
    [ "Poste Source Arras",[2.7629665229425626, 50.307004000657805]],
    [ "Enedis",[3.0846226853801175, 50.50300903430799]],
    [ "Poste électrique d'Amélécourt",[6.495326793846438, 48.83025828370193]],
    [ "Enedis",[0.183886, 49.347941]],
    [ "Enedis",[0.17773181736334345, 49.363942387352616]],
    [ "Enedis",[0.18295633066310615, 49.344069014686255]],
    [ "Enedis",[0.19182563259958085, 49.34711242557652]],
    [ "Enedis",[0.21843925562151448, 49.31138034844397]],
    [ "Enedis",[0.21868599999999952, 49.30627200000001]],
    [ "Enedis",[0.33531150000000004, 49.341995000000004]],
    [ "Enedis",[0.206613, 49.309279]],
    [ "Enedis",[0.34693665939393964, 49.34578867636365]],
    [ "Enedis",[0.18925307168458813, 49.34717921146954]],
    [ "Enedis",[0.16643665651272002, 49.35692943989519]],
    [ "Enedis",[0.34341413725490216, 49.33792130283224]],
    [ "Enedis",[3.8111520417633415, 50.32814876720804]],
    [ "Enedis",[2.834319936317757, 48.52961022188434]],
    [ "Enedis",[3.8058592063492065, 50.309377936507936]],
    [ "Enedis",[3.8433595170199113, 50.337260581245985]],
    [ "Enedis",[3.8109909999999996, 50.287054999999995]],
    [ "Enedis",[-2.7016785, 47.642772]],
    [ "Enedis",[-2.6986755045333335, 47.6443016656]],
    [ "Enedis",[-2.674497, 47.6584205]],
    [ "Enedis",[0.9947224035863419, 49.45784213289118]],
    [ "Enedis",[1.0019223551029963, 49.46045433895131]],
    [ "Enedis",[1.0085291000000003, 49.461200766666664]],
    [ "Enedis",[1.002091, 49.4587015]],
    [ "Enedis",[5.932644089403973, 46.09932879580574]],
    [ "Enedis",[5.940964900000001, 46.102423800000004]],
    [ "Enedis",[0.13664497245434848, 49.32361582234602]],
    [ "REThones",[6.393412495993954, 45.96461183492063]],
    [ "L'Île",[6.393117186133768, 45.95171896693855]],
    [ "Enedis",[5.31138225, 46.598685749999994]],
    [ "Poste électrique de Saint-Roch",[3.0692781508852978, 50.67146576769814]],
    [ "N° 6 Bourg",[-0.944865336024218, 43.18682999360916]],
    [ "Poste électrique de Predembourg",[2.2948100222413217, 51.03822340663029]],
    [ "RTE",[2.7236003434710856, 50.73813180647325]],
    [ "Sous-station des Trois Domaines",[5.252813559061314, 48.98097829019906]],
    [ "Enedis",[2.37387628615281, 51.04058166247115]],
    [ "Enedis",[2.4358900555091436, 51.05646612930474]],
    [ "Enedis",[2.4338841701238145, 51.055273434635794]],
    [ "Enedis",[2.438473380912163, 51.05842156404843]],
    [ "Enedis",[2.4373928090185677, 51.054424955260835]],
    [ "Enedis",[2.4366825734168396, 51.05253171159051]],
    [ "Enedis",[2.43435507465964, 51.049339823451895]],
    [ "Enedis",[2.439905231552838, 51.05204444645229]],
    [ "Enedis",[2.4399910000000005, 51.05013499999999]],
    [ "Enedis",[2.4431509182156135, 51.051589190210656]],
    [ "Enedis",[2.4431112260058216, 51.049218223763056]],
    [ "Enedis",[2.451394644032922, 51.05396717489712]],
    [ "Enedis",[2.45475425476992, 51.05358821286944]],
    [ "Enedis",[2.453759654269972, 51.05489667263544]],
    [ "Enedis",[2.451799683274804, 51.05276431474174]],
    [ "Enedis",[2.467610478581391, 51.05003417911936]],
    [ "RTE",[3.023822964041657, 50.36214879787747]],
    [ "Enedis",[2.451975, 51.047075500000005]],
    [ "RTE",[3.339249683930151, 50.2606013317187]],
    [ "Enedis",[2.4622406688903715, 51.03204650322437]],
    [ "Enedis",[2.4640328642669673, 51.03573482939633]],
    [ "Enedis",[2.465321296281049, 51.03365089254839]],
    [ "RTE",[3.4788883794293017, 50.340169594917256]],
    [ "Enedis",[2.3771043356509893, 51.00147250252214]],
    [ "Enedis",[2.1548607445887438, 49.72543687445887]],
    [ "Enedis",[2.3662015000000003, 50.99871925]],
    [ "Enedis",[2.360647618083352, 50.99624524071894]],
    [ "Enedis",[2.3630574999999996, 51.008757]],
    [ "Enedis",[2.3626355, 50.99781699999999]],
    [ "Enedis",[2.3600925748031503, 50.9971788792651]],
    [ "Enedis",[2.3593385, 51.0021835]],
    [ "Enedis",[2.3569998088188475, 51.00476083874563]],
    [ "Enedis",[2.362703616407981, 50.99629129637842]],
    [ "Enedis",[2.3572010769971126, 50.992158753930056]],
    [ "Enedis",[2.373499823149528, 50.99745800894187]],
    [ "Enedis",[2.3546698755555555, 51.00156072444444]],
    [ "Enedis",[2.3592795000000004, 50.992813500000004]],
    [ "Enedis",[2.354850453747628, 51.00367714618911]],
    [ "Enedis",[2.355157263324298, 50.99434242050588]],
    [ "RTE",[4.047535434924243, 50.28318965517415]],
    [ "Poste électrique de Fourmies",[4.064535066756093, 50.022088197338526]],
    [ "Enedis",[2.377112824495737, 50.99680844167187]],
    [ "Enedis",[2.386818194925029, 50.99910335640139]],
    [ "Enedis",[2.3827485000000004, 51.001082000000004]],
    [ "Enedis",[2.384686878456591, 50.99676505594856]],
    [ "Enedis",[2.3488556846846853, 51.00058325225225]],
    [ "Enedis",[2.334636000000001, 50.9966745]],
    [ "Enedis",[2.334697826870099, 50.99858683577029]],
    [ "Enedis",[2.3360875922297746, 50.99850501812609]],
    [ "Enedis",[2.343944768060227, 50.993189666346304]],
    [ "Enedis",[2.3459391960332687, 50.96769954024312]],
    [ "Enedis",[2.3510797956989244, 50.978695462365586]],
    [ "Enedis",[2.355151417525342, 50.97867750322526]],
    [ "Enedis",[2.299331071639275, 50.98662232069112]],
    [ "Enedis",[2.320286174097665, 50.969104028580766]],
    [ "Enedis",[2.323505, 50.972104]],
    [ "Enedis",[2.3270875000000006, 50.970355]],
    [ "Enedis",[2.3261251371036855, 50.968984796915166]],
    [ "Enedis",[2.2185942499999993, 50.97883674999999]],
    [ "Enedis",[2.2195166981875496, 50.97625691725768]],
    [ "Enedis",[2.2628159999999995, 50.9569815]],
    [ "Enedis",[2.2164310000000005, 50.980356]],
    [ "Enedis",[2.2611541966277553, 50.95649428923476]],
    [ "Enedis",[2.2356613502729945, 50.96891280330393]],
    [ "Enedis",[2.2092352266244073, 50.97869621130481]],
    [ "Enedis",[2.1571359911111108, 50.94462116203175]],
    [ "Enedis",[2.1772303882940762, 50.95607967665954]],
    [ "Enedis",[3.7386013297966403, 50.33653980901857]],
    [ "Enedis",[3.751174051282051, 50.322981615384606]],
    [ "Enedis",[2.194706024922118, 50.94098019626168]],
    [ "Enedis",[2.2018824152992273, 50.95167683437902]],
    [ "Enedis",[2.20231264196749, 50.943774559003586]],
    [ "Enedis",[2.195821826751358, 50.93950147620363]],
    [ "Enedis",[2.1952482119460512, 50.94793180154142]],
    [ "Enedis",[2.2001543757961786, 50.93784672016984]],
    [ "Enedis",[2.185544616033755, 50.93944628270042]],
    [ "Enedis",[2.1923265000000005, 50.946462]],
    [ "Enedis",[2.183264999999999, 50.94657249999999]],
    [ "Enedis",[2.19354, 50.9441705]],
    [ "Enedis",[2.198647883211679, 50.94082372871046]],
    [ "Enedis",[2.203437334822378, 50.94535150329717]],
    [ "Enedis",[2.191027495116772, 50.94878508067941]],
    [ "Enedis",[2.1964375167958656, 50.946834537467694]],
    [ "Enedis",[2.2022915, 50.941320999999995]],
    [ "Enedis",[2.192997832897033, 50.945820007853406]],
    [ "Enedis",[3.2895674999999995, 50.518680749999994]],
    [ "Enedis",[2.175183321727486, 50.92283268675371]],
    [ "Enedis",[2.1615371416122, 50.91901681917211]],
    [ "Enedis",[2.213072831392842, 50.95571878568348]],
    [ "Enedis",[2.2631574896377806, 50.95457939090632]],
    [ "Enedis",[0.4580908977879485, 48.10777331045003]],
    [ "Poste électrique d'Alerie",[-1.271270215956218, 49.29592063951562]],
    [ "Poste Électrique d'Isigny",[-1.1005481426607595, 49.31555188354768]],
    [ "Poste Électrique de Ferrière",[0.5917030828436941, 48.8573716525201]],
    [ "Poste Électrique de Livarot",[0.15252405613680994, 49.01095535285266]],
    [ "P 1 Idaux Idaux",[-0.9160886302054541, 43.18050132277087]],
    [ "Enedis",[2.171791666666666, 50.96946450724637]],
    [ "Enedis",[2.166813084132056, 50.96709440255592]],
    [ "Roubie",[5.78660153197405, 48.67357143008959]],
    [ "Rempart",[5.784759156462585, 48.68460833333334]],
    [ "Enedis",[3.3207554999999997, 50.51109650000001]],
    [ "Poste Électrique de Coutances",[-1.446390496807895, 49.03415137074437]],
    [ "Poste Électrique de Saint-Barthélémy",[-0.5147712368733813, 47.472751550114616]],
    [ "RTE",[-0.6413886261566409, 47.65125871471204]],
    [ "Poste électrique d’Avranches",[-1.3149922, 48.67543069999999]],
    [ "Poste Électrique de Boucey",[-1.48101255, 48.552632550000006]],
    [ "Poste électrique de Morihan",[-1.639881916796567, 47.94015056879082]],
    [ "Poste électrique de Saint-Brice",[-1.3870320411829744, 48.41745682536673]],
    [ "Poste électrique de Saint-Laurent",[-1.6513574735582193, 48.13889962746746]],
    [ "Poste Électrique de Villeudieu",[-1.2429783604302742, 48.84164603001635]],
    [ "poste_631",[2.997041499999999, 49.2846955]],
    [ "RTE",[0.5819738747490402, 47.744920044122004]],
    [ "Enedis",[1.0397355, 49.30950000000001]],
    [ "Enedis",[1.0245191839080459, 49.3077363497537]],
    [ "Enedis",[1.0315661812865493, 49.31781268421052]],
    [ "Enedis",[1.0545277499660846, 49.32134578483246]],
    [ "Enedis",[1.032134168985835, 49.31475966879779]],
    [ "Enedis",[1.0581401112184132, 49.31745068340577]],
    [ "Enedis",[2.111975, 50.9937015]],
    [ "Enedis",[2.113316179544054, 50.99598352643253]],
    [ "Enedis",[2.1084025000000004, 50.998213]],
    [ "Enedis",[2.1011135613949716, 51.00136459886456]],
    [ "Enedis",[2.114863, 50.99341350000001]],
    [ "Enedis",[2.110113128997639, 50.996862708735776]],
    [ "Enedis",[2.108881435690654, 51.00052342245659]],
    [ "Enedis",[2.0933302967925282, 51.00198535485182]],
    [ "Enedis",[1.0344809999999993, 49.29907]],
    [ "Enedis",[1.022456569550931, 49.30311008835341]],
    [ "Enedis",[1.005322, 49.30189550000001]],
    [ "Enedis",[1.0149197226683127, 49.296630456248714]],
    [ "Enedis",[1.0193843314891657, 49.30542901014292]],
    [ "Enedis",[1.0083135, 49.2968995]],
    [ "Enedis",[1.0387745, 49.30266400000001]],
    [ "Enedis",[1.0393094999999999, 49.3056375]],
    [ "Enedis",[1.0312083507910526, 49.30254032624114]],
    [ "Enedis",[1.0244113851851853, 49.300005348148154]],
    [ "Enedis",[1.0123576631393298, 49.30656201940036]],
    [ "Enedis",[1.0027719999999987, 49.3000215]],
    [ "Enedis",[1.0209061512565192, 49.29723817401612]],
    [ "Enedis",[1.0190046201373002, 49.29793527612509]],
    [ "Enedis",[1.0293467791611086, 49.30514894204832]],
    [ "Enedis",[1.0285475322376743, 49.30213501685629]],
    [ "RTE",[3.9603253667883407, 50.10126915402812]],
    [ "Enedis",[5.290123649999998, 46.615189999999984]],
    [ "SNCF Réseau",[2.6628182751283056, 50.49601233719155]],
    [ "Poste Électrique de Hautes-Rivières",[4.841079914263707, 49.87506823506861]],
    [ "RTE",[4.070394646907049, 49.920884073825526]],
    [ "Poste Électrique d'Huberville",[-1.433885545630099, 49.49988516911607]],
    [ "Poste électrique de Valcanville",[-1.3409848224072607, 49.64909874309882]],
    [ "RTE",[6.042477073002774, 45.47072424094381]],
    [ "Sous-station RFF de Beaulin",[0.4245878620317909, 46.711552588472365]],
    [ "Saint-Pierre-Eynac",[4.033229482919877, 45.0459447993393]],
    [ "Enedis",[2.1185055667718196, 51.003184453798625]],
    [ "Enedis",[2.1184582601524604, 50.99721195786557]],
    [ "Enedis",[2.1126725000000004, 51.000904]],
    [ "Enedis",[2.1159065, 51.00073450000001]],
    [ "Enedis",[2.1164156870937787, 51.006850037140204]],
    [ "Enedis",[2.1158806077172643, 51.00426172244081]],
    [ "Enedis",[2.118090459902896, 51.004077792901384]],
    [ "Enedis",[2.130804829431896, 51.00530644668035]],
    [ "Enedis",[2.1272567943262413, 50.97576947659575]],
    [ "Enedis",[2.1231981292517013, 50.97508479591837]],
    [ "Enedis",[2.123198, 50.98408599999999]],
    [ "Enedis",[2.1247624741200832, 50.984907093995865]],
    [ "Enedis",[2.1304922072535697, 50.98738053752374]],
    [ "Enedis",[2.1499267490734684, 50.98115109134509]],
    [ "Enedis",[2.1439880000000002, 50.980955]],
    [ "Enedis",[2.1455477379828065, 50.98530015171328]],
    [ "Enedis",[2.1442119420135177, 50.98282402170046]],
    [ "Enedis",[2.13361782535461, 50.989224973404255]],
    [ "Enedis",[2.1446395000000003, 50.98692699999999]],
    [ "Enedis",[2.139935357484277, 50.98618132955975]],
    [ "Enedis",[2.1374200000000014, 50.987789]],
    [ "Enedis",[2.1486425000000007, 50.99090300000001]],
    [ "Enedis",[2.1326549999999997, 50.991367]],
    [ "Enedis",[2.145789659329593, 50.99398844986334]],
    [ "Enedis",[2.1445434999999997, 50.995599500000004]],
    [ "Enedis",[2.1366081648250455, 50.99557901767955]],
    [ "Enedis",[2.1564786941445857, 50.99184863395485]],
    [ "Enedis",[3.1754597601040038, 50.72383351923158]],
    [ "Enedis",[2.201049, 51.021079499999985]],
    [ "Enedis",[2.1997650000000006, 51.0184265]],
    [ "Enedis",[2.194926331584929, 51.00883251070899]],
    [ "Enedis",[2.1933491308176096, 51.01247233962263]],
    [ "Enedis",[2.1929315, 51.0057685]],
    [ "Enedis",[2.1878127254320994, 51.0135371288889]],
    [ "Enedis",[2.19240389403136, 51.01368117804755]],
    [ "Enedis",[2.190109589074209, 51.015831911892676]],
    [ "Enedis",[2.2345244871794865, 51.000050205128204]],
    [ "Enedis",[2.2481506829204347, 51.00968759142744]],
    [ "Enedis",[2.253462090221898, 51.00102661838575]],
    [ "Enedis",[2.2300909366802357, 51.00647315128901]],
    [ "Enedis",[2.239470406651044, 50.9880298929807]],
    [ "Enedis",[2.234675659386719, 50.99063873615707]],
    [ "Enedis",[2.2354739999999995, 51.00334250000001]],
    [ "RTE",[3.6502258321207446, 50.265008600291985]],
    [ "Enedis",[2.211676294073659, 50.99490154066179]],
    [ "Enedis",[2.2040565, 50.9957395]],
    [ "Enedis",[2.2135043679519533, 50.99408480751697]],
    [ "Enedis",[2.2100008876804424, 50.997099284819726]],
    [ "Enedis",[2.216018684326712, 50.99797052980133]],
    [ "Enedis",[2.2123927477393197, 50.99725517929529]],
    [ "Enedis",[2.2208905, 50.998718000000004]],
    [ "Enedis",[2.22408155, 50.997284150000006]],
    [ "Enedis",[6.203409836125965, 48.671984662982766]],
    [ "Enedis",[4.699767337411723, 44.37177112453833]],
    [ "Enedis",[2.225372049770792, 50.994138177799606]],
    [ "Enedis",[2.225278715124907, 50.99222082375077]],
    [ "Enedis",[2.228180328717816, 50.99280343944763]],
    [ "Enedis",[3.243091666851646, 50.56679400443951]],
    [ "Enedis",[2.2181562043528062, 50.99037725819016]],
    [ "Enedis",[2.2196875600245627, 50.98865382202437]],
    [ "Enedis",[2.2155027550972846, 50.98898814924852]],
    [ "Enedis",[2.2175453737906774, 50.990420936382286]],
    [ "Enedis",[2.2165485, 50.98615649999999]],
    [ "Enedis",[-1.0672811484412466, 43.5470394673861]],
    [ "Enedis",[7.2970187000000015, 47.74025175000001]],
    [ "Enedis",[7.30363907936243, 47.74030458042919]],
    [ "Enedis",[-0.10657266757679212, 48.84554249647327]],
    [ "Enedis",[7.308630000000001, 47.743898]],
    [ "Enedis",[1.3786760021963165, 47.26860456259503]],
    [ "Enedis",[1.3697129053672317, 47.26730112711865]],
    [ "Enedis",[1.3551413550200804, 47.26275979678715]],
    [ "Enedis",[1.3777166217851737, 47.264296913262726]],
    [ "Enedis",[1.374984823019997, 47.26838537710597]],
    [ "Enedis",[2.9699693096872615, 50.561337632341726]],
    [ "Enedis",[3.000422, 50.50288450000001]],
    [ "Poste électrique de Saint-Dié",[6.9663659500301565, 48.27400790968435]],
    [ "Camping",[-0.47524468816965065, 43.891285958602026]],
    [ "Enedis",[6.1047384031741405, 46.01949228389772]],
    [ "Enedis",[6.097483499999999, 46.033375500000005]],
    [ "Gendarmerie",[6.110647421886792, 46.035048691572335]],
    [ "Enedis",[6.107893030788802, 46.04642854198473]],
    [ "Enedis",[6.108604333333332, 46.03372008333333]],
    [ "Fessigny",[6.108238362745099, 46.025323715686284]],
    [ "Maison Familiale",[6.120223526181188, 46.03720477919376]],
    [ "Enedis",[7.302389499999999, 47.74326875]],
    [ "Enedis",[5.991921842818427, 46.07220394579946]],
    [ "Enedis",[5.976301665298678, 46.07430648153215]],
    [ "Enedis",[6.053883213485155, 46.08058346356329]],
    [ "Enedis",[6.029684768819599, 46.07004381477357]],
    [ "Enedis",[6.047715500000001, 46.0673612]],
    [ "Enedis",[6.041773679304896, 46.06633868630858]],
    [ "Enedis",[5.999665044552451, 46.067496181855]],
    [ "Enedis",[5.98835805856231, 46.05219181459895]],
    [ "Poste électrique de Noyal",[-1.6600006876202014, 48.07965972154768]],
    [ "Enedis",[5.807636949999999, 46.108946849999995]],
    [ "Enedis",[5.833977871524513, 46.10821082030445]],
    [ "Enedis",[5.8136029950282495, 46.11069865966102]],
    [ "Enedis",[5.8114051656399734, 46.11525549281315]],
    [ "Enedis",[5.808848352617081, 46.1204317906336]],
    [ "Enedis",[5.8101828433515506, 46.11657467431693]],
    [ "Enedis",[5.81390221927888, 46.088147548933044]],
    [ "Enedis",[5.8172779836372195, 46.122224390597395]],
    [ "Enedis",[5.810777007591808, 46.12107571027543]],
    [ "Enedis",[5.818622668551679, 46.12006504859148]],
    [ "Enedis",[5.814834094707069, 46.11114648694949]],
    [ "Enedis",[5.813971210277126, 46.11822933100762]],
    [ "Enedis",[6.016162150484978, 46.03267032356282]],
    [ "Enedis",[6.00899680060423, 46.03207941188319]],
    [ "Enedis",[-2.3791065000000002, 47.80795400000001]],
    [ "Enedis",[7.295370999999999, 47.741290250000006]],
    [ "Enedis",[0.37264425, 47.948202949999995]],
    [ "Enedis",[2.4544023000000004, 47.09724895]],
    [ "Enedis",[2.4848079999999997, 47.098521]],
    [ "Enedis",[2.4922059999999995, 47.097827]],
    [ "Enedis",[2.5057398648373974, 47.09773622713414]],
    [ "Enedis",[2.4599205, 47.096810250000004]],
    [ "Enedis",[2.4820874999999996, 47.101800499999996]],
    [ "Enedis",[2.4915962499999997, 47.10173700000001]],
    [ "Enedis",[2.495155499999999, 47.100688999999996]],
    [ "Enedis",[2.48286625, 47.10149200000001]],
    [ "Enedis",[2.4873507113335696, 47.102835553223166]],
    [ "Usinelec",[6.0709031499999995, 46.029443949999994]],
    [ "Enedis",[7.286627, 47.742157500000005]],
    [ "Enedis",[3.0056352669427637, 50.501119196517855]],
    [ "Enedis",[7.288791999999999, 47.738337]],
    [ "Enedis",[0.9093164556354906, 48.39368237170264]],
    [ "Sous-station SNCF Réseau de Condé-sur-Huisne",[0.8536146827504115, 48.38688727267404]],
    [ "Enedis",[-0.12542162857142913, 48.81692566666666]],
    [ "Enedis",[-0.11786667385057471, 48.83303098275863]],
    [ "réséda",[6.309803300000001, 49.10384145000001]],
    [ "réséda",[6.3044585, 49.114037]],
    [ "réséda",[6.311821979276054, 49.10545304748541]],
    [ "réséda",[6.312071500000001, 49.1061425]],
    [ "Enedis",[5.955902716110625, 45.89073409418993]],
    [ "Enedis",[5.9746172500000005, 45.9042354]],
    [ "La Croix",[5.967968256370656, 45.904332788545695]],
    [ "Enedis",[5.971801263041682, 45.902187008693446]],
    [ "Enedis",[2.9867222499999992, 50.5501425]],
    [ "Enedis",[2.9835625, 50.55172575000001]],
    [ "Enedis",[2.986271462111801, 50.54476033084885]],
    [ "Enedis",[3.00164425, 50.5410555]],
    [ "Enedis",[2.9899774810865756, 50.54339467745465]],
    [ "Enedis",[2.981389451325357, 50.54682266783954]],
    [ "Enedis",[2.9770361785834183, 50.54864920174555]],
    [ "Enedis",[1.9440510761223684, 47.91007175474772]],
    [ "Enedis",[1.9384895000000009, 47.903957]],
    [ "Enedis",[1.9706309526093317, 47.92429317896009]],
    [ "Enedis",[1.9888906586253068, 47.908817918947214]],
    [ "Enedis",[1.9839058190655379, 47.90814303951082]],
    [ "Enedis",[1.9782795419177777, 47.91980983805305]],
    [ "Enedis",[1.9777099448751072, 47.91479310520488]],
    [ "Enedis",[1.9660750000000005, 47.922037400000015]],
    [ "Enedis",[1.9504850023123146, 47.915676008753444]],
    [ "Enedis",[1.9659790735775995, 47.91491527137326]],
    [ "Enedis",[1.9451828396940216, 47.914454554668666]],
    [ "Enedis",[1.9761289999999991, 47.90937750000001]],
    [ "Enedis",[1.9586860000000001, 47.905184500000004]],
    [ "Enedis",[1.981438611635221, 47.908110136202836]],
    [ "Enedis",[1.9862634499999998, 47.91189274999999]],
    [ "PAC KENNEDY",[1.95504415, 47.90731335]],
    [ "Enedis",[1.9769489999999998, 47.912079]],
    [ "Enedis",[1.947079171680159, 47.92000858707272]],
    [ "Enedis",[2.0049641, 47.9197659]],
    [ "Enedis",[1.9697110238331241, 47.92160827518898]],
    [ "Enedis",[1.95284555, 47.90985715000001]],
    [ "Enedis",[1.9413220292270532, 47.91558886041063]],
    [ "Enedis",[1.9602411568143752, 47.92301292690727]],
    [ "RABELAIS",[1.9526430000000001, 47.907947699999994]],
    [ "Enedis",[2.0035705459708906, 47.91704733084841]],
    [ "Enedis",[1.9731318600748131, 47.90830102533469]],
    [ "Enedis",[1.9594125999999996, 47.90906435]],
    [ "Enedis",[1.9582457333333332, 47.913188299999995]],
    [ "Enedis",[1.978411153412194, 47.912553548286326]],
    [ "Enedis",[1.9875494999999996, 47.90591499999999]],
    [ "Enedis",[1.965183349999999, 47.9172939]],
    [ "Enedis",[1.9724433460980564, 47.91176937626922]],
    [ "Enedis",[1.9376521603294843, 47.90184748483568]],
    [ "Enedis",[1.9549148016896043, 47.91366603460195]],
    [ "Enedis",[1.9437955388502843, 47.911917786565596]],
    [ "Enedis",[1.972706594343114, 47.91307576764473]],
    [ "Enedis",[1.9683100358565737, 47.906656845949534]],
    [ "Enedis",[1.9494371704051212, 47.91489636739481]],
    [ "Enedis",[1.9903154700854704, 47.91309136752137]],
    [ "Enedis",[1.9807818451897032, 47.91808601151761]],
    [ "Enedis",[1.9797165657359928, 47.90938942019963]],
    [ "Enedis",[1.9562061930136183, 47.90828434418789]],
    [ "Enedis",[1.9939172791154802, 47.91436144903101]],
    [ "Enedis",[1.9714394927536218, 47.91823804637682]],
    [ "Enedis",[1.9508584581657804, 47.90991790584953]],
    [ "Enedis",[1.9381576764770005, 47.90665365838238]],
    [ "Enedis",[1.9570161323941624, 47.92104850704378]],
    [ "Enedis",[0.679710162037037, 44.17615702777778]],
    [ "Enedis",[0.678216672231497, 44.17900949193099]],
    [ "Enedis",[0.6841852664878156, 44.17628916521351]],
    [ "Enedis",[0.6759174999999991, 44.17905650000001]],
    [ "Enedis",[0.6880774999999999, 44.177029499999996]],
    [ "Enedis",[0.6850054999999987, 44.177141]],
    [ "Enedis",[0.6846219134045083, 44.178914742586]],
    [ "Enedis",[2.9913245000000006, 50.507218]],
    [ "Enedis",[2.9891315423256066, 50.51036467537948]],
    [ "Enedis",[2.9766322500000006, 50.54313175]],
    [ "Enedis",[2.9789605, 50.545017]],
    [ "Enedis",[2.97509075, 50.54629550000001]],
    [ "RTE",[7.592466782488984, 48.17094682809932]],
    [ "Enedis",[2.982166019001487, 50.54131412319495]],
    [ "Enedis",[2.9841870429657207, 50.541239743136344]],
    [ "La Roche",[5.21992025, 45.596556]],
    [ "Les Roussets",[5.222440212608981, 45.594024533074695]],
    [ "Poste électrique Rhenalu",[7.563817498132989, 48.048162285699085]],
    [ "Grammont",[5.229662487068283, 45.59367099535892]],
    [ "David",[5.223999366423158, 45.5990189670094]],
    [ "La Grive",[5.229506405797103, 45.60143048405798]],
    [ "Lot Artisanal",[5.239156690091696, 45.591470073985995]],
    [ "Enedis",[4.020799394060728, 49.65613225859194]],
    [ "Strasbourg Électricité Réseaux",[7.892834336655592, 48.9426961480251]],
    [ "Enedis",[2.9833457500000002, 50.513244]],
    [ "Enedis",[2.9872850000000004, 50.51213325]],
    [ "Enedis",[4.811700265993266, 45.93567747474747]],
    [ "Veissieux",[4.826960563334323, 45.930683308414906]],
    [ "Enedis",[4.814407500000001, 45.93258450000001]],
    [ "Plein Soleil",[4.8263875, 45.93208069999999]],
    [ "Enedis",[4.8144572499999985, 45.92168165]],
    [ "Enedis",[4.828219071301247, 45.93522568003565]],
    [ "Enedis",[4.8131095376702815, 45.937726358910204]],
    [ "Maison Carrée",[4.812041, 45.92625350000001]],
    [ "Enedis",[4.827406000000001, 45.92644300000001]],
    [ "Enedis",[4.815791464696224, 45.931607142583466]],
    [ "Le Bret",[4.8213646916579895, 45.92909815052643]],
    [ "Trève d'Ars",[4.8262101500000005, 45.922341900000006]],
    [ "Enedis",[5.605178800000001, 46.98273299999999]],
    [ "Enedis",[5.606376280880257, 46.97487680320502]],
    [ "Enedis",[5.2442349545720415, 43.41495038539026]],
    [ "Enedis",[5.253902542709276, 43.41201472086155]],
    [ "Enedis",[5.26452665, 43.40507435]],
    [ "Enedis",[5.271471999999999, 43.41572549999999]],
    [ "Enedis",[5.272431849999999, 43.41599395]],
    [ "Enedis",[5.256406, 43.411294500000004]],
    [ "Enedis",[5.255948999999999, 43.418543500000006]],
    [ "Poste électrique de Vielmoulin",[4.687244563821123, 47.32614397450898]],
    [ "ESSeyssel",[5.963299825041492, 45.92974859781592]],
    [ "ESSeyssel",[5.938177104546334, 45.93590923488102]],
    [ "ESSeyssel",[5.947866704480748, 45.9465592946231]],
    [ "Enedis",[5.3705595, 46.6901195]],
    [ "Enedis",[-1.12150375, 44.60403325000001]],
    [ "Enedis",[-1.1187486074074078, 44.61351034869684]],
    [ "Enedis",[-1.1228146122512441, 44.614839348414186]],
    [ "Enedis",[7.241734750000001, 47.570196249999995]],
    [ "Enedis",[1.547434918249044, 43.465158408958686]],
    [ "Enedis",[1.5511642710462288, 43.46975695717762]],
    [ "Enedis",[1.5465589500000014, 43.47210820000001]],
    [ "Enedis",[1.5491731939321831, 43.46845459171128]],
    [ "Enedis",[1.5520327711988295, 43.46649966739766]],
    [ "Enedis",[-1.7872643876578507, 46.49681403764593]],
    [ "Enedis",[-1.8066932841177072, 46.493047155131755]],
    [ "Enedis",[-1.7666131248009092, 46.49001392354949]],
    [ "Enedis",[-1.7873567638923744, 46.49911350244426]],
    [ "Enedis",[-1.794993, 46.503252499999995]],
    [ "Enedis",[-1.764662735207967, 46.48695915055653]],
    [ "Enedis",[-1.7934207487317217, 46.50339814801551]],
    [ "Enedis",[-1.795639181051266, 46.50095996668829]],
    [ "Enedis",[-1.7703721690325174, 46.494258168428466]],
    [ "Enedis",[-1.79340625, 46.49454839999999]],
    [ "Enedis",[-1.807122416300036, 46.50356775321554]],
    [ "Enedis",[-1.8018391848617177, 46.491119151382826]],
    [ "Enedis",[-1.780483930293502, 46.505487607442355]],
    [ "Enedis",[-1.797838718418514, 46.50114095049823]],
    [ "Enedis",[-1.8087965000000001, 46.4985995]],
    [ "Enedis",[-1.774384398671096, 46.50446580177187]],
    [ "Enedis",[-1.8025589061541822, 46.50698741885677]],
    [ "Enedis",[-1.7635596079820128, 46.48850488083193]],
    [ "Enedis",[-1.7772805, 46.506733000000004]],
    [ "Enedis",[-1.8088888535905687, 46.49529483908538]],
    [ "Enedis",[-1.806671481364497, 46.51691674373552]],
    [ "Enedis",[-1.798886199739329, 46.4918794532421]],
    [ "Enedis",[-1.7645950992343937, 46.49420205123675]],
    [ "Enedis",[-1.789875683201282, 46.50036446662156]],
    [ "Enedis",[-1.7574079239263802, 46.48555118752556]],
    [ "Enedis",[-1.7655657031497851, 46.485321218672105]],
    [ "Enedis",[-1.7727528003527335, 46.49904243280424]],
    [ "Enedis",[-1.7963821949513383, 46.491869952554744]],
    [ "Enedis",[-1.8130106033844007, 46.496721712225906]],
    [ "Enedis",[-1.7997900000000002, 46.5050245]],
    [ "Enedis",[-1.7915763999999998, 46.50468240000001]],
    [ "Enedis",[-1.8072132089978654, 46.50793507050081]],
    [ "Enedis",[-1.7782138817204305, 46.49427121505376]],
    [ "Enedis",[-1.8093350249847655, 46.49758661649401]],
    [ "Enedis",[-1.8035144999999988, 46.491752]],
    [ "Enedis",[-1.8079201004784684, 46.50665436523127]],
    [ "Enedis",[-1.7806092292456812, 46.494667621154655]],
    [ "Enedis",[-1.7630571570658036, 46.492310526429335]],
    [ "Enedis",[-1.8051298018018025, 46.49445954299755]],
    [ "Enedis",[-1.7683121500000003, 46.497210349999996]],
    [ "Enedis",[-1.7892811757906033, 46.498748462802425]],
    [ "Enedis",[-1.7788985916667677, 46.49839953320601]],
    [ "Enedis",[-1.7941568177876484, 46.49776803310068]],
    [ "Enedis",[-1.7872068742453433, 46.50506442295011]],
    [ "Enedis",[-1.8010023191410611, 46.500610095847385]],
    [ "Enedis",[-1.7834393598925684, 46.50315654968666]],
    [ "Enedis",[-1.7777024241771753, 46.50145809945658]],
    [ "Enedis",[-1.7743214242592078, 46.497624789993544]],
    [ "Enedis",[-1.7944573571071585, 46.50339964774482]],
    [ "Enedis",[-1.7761443883735313, 46.49928773902289]],
    [ "Enedis",[-1.7971283674208138, 46.49386223529412]],
    [ "Enedis",[-1.8036492555887487, 46.50941796163068]],
    [ "Enedis",[-1.800871697543622, 46.49641152964031]],
    [ "Enedis",[-1.7745284459091664, 46.49356771930265]],
    [ "Enedis",[-1.8141191851299399, 46.51207308861693]],
    [ "Enedis",[-1.7979615705635157, 46.50214438795632]],
    [ "Enedis",[-1.7980595621521338, 46.49730385263715]],
    [ "Enedis",[1.0002547598165672, 49.37638070793924]],
    [ "Enedis",[0.9972102910485922, 49.380453206990616]],
    [ "Enedis",[1.0013596745296265, 49.37855479303566]],
    [ "Enedis",[3.770453308, 49.598404984]],
    [ "Enedis",[3.774087734202211, 49.59878696800947]],
    [ "Enedis",[0.9739567290056672, 49.359480542503874]],
    [ "Enedis",[0.9749742967702617, 49.36648085252895]],
    [ "Enedis",[0.9786003568554629, 49.36405371309192]],
    [ "Enedis",[0.982236, 49.364271499999994]],
    [ "Enedis",[7.29725275, 48.94786215]],
    [ "Enedis",[3.1909050000000003, 50.513037749999995]],
    [ "Le Thuel",[3.12158305, 45.582782349999995]],
    [ "Enedis",[7.291656000000001, 47.7366495]],
    [ "Enedis",[7.290290000000001, 47.735327]],
    [ "Enedis",[-0.11532773222100566, 48.789733081444005]],
    [ "Enedis",[-0.10757549999999999, 48.789258999999994]],
    [ "Enedis",[0.37434225, 47.685313]],
    [ "Enedis",[3.6001286728481454, 43.50230711084676]],
    [ "Enedis",[3.603815, 43.50514125000001]],
    [ "Enedis",[3.6100525, 43.4965695]],
    [ "Enedis",[3.590342664810395, 43.47825447122779]],
    [ "Enedis",[3.608071500000001, 43.49906575000001]],
    [ "Enedis",[3.5922196500000005, 43.499272149999996]],
    [ "Enedis",[3.608006249999999, 43.5008305]],
    [ "Enedis",[3.6055690499999997, 43.49706845]],
    [ "Enedis",[3.6076397499999993, 43.5030865]],
    [ "Enedis",[3.6062598089103597, 43.50237749006978]],
    [ "Enedis",[3.5947525000000002, 43.500233]],
    [ "Enedis",[3.604527791029308, 43.49953418807942]],
    [ "Enedis",[3.6124708500000007, 43.50020475]],
    [ "179787060",[6.636650394444445, 43.26287427222223]],
    [ "179787802",[6.636649719932361, 43.26001456436271]],
    [ "179789295",[6.630488833056754, 43.259659487000775]],
    [ "Enedis",[6.632122548674442, 43.25834583273241]],
    [ "Poste électrique de Compertrix",[4.347348245724302, 48.94144155069718]],
    [ "Enedis",[2.297581, 50.98689949999999]],
    [ "Enedis",[2.3110165207138307, 50.997369832377316]],
    [ "Enedis",[2.3112550101999187, 50.99540066503469]],
    [ "Enedis",[2.3064894999999987, 50.9981345]],
    [ "Enedis",[2.2989318106718093, 50.99743267888139]],
    [ "Enedis",[2.308070405997693, 50.994590762399085]],
    [ "Enedis",[2.3146987109412325, 50.99898818983914]],
    [ "Enedis",[2.2982361693912505, 50.99309822435]],
    [ "Enedis",[2.300132836065573, 50.9956761693989]],
    [ "Enedis",[2.314006777096114, 51.00152956646217]],
    [ "Enedis",[2.310485325176727, 50.99554747308321]],
    [ "Enedis",[2.3054031482028554, 51.003004255375025]],
    [ "Enedis",[2.31407500486855, 51.00267777052904]],
    [ "Enedis",[2.313904022635408, 51.00265615871733]],
    [ "Enedis",[2.282958080851501, 51.00598906928666]],
    [ "Enedis",[2.288548204147544, 51.004563969403364]],
    [ "Enedis",[2.2882949383097766, 51.00651338825089]],
    [ "Enedis",[2.287038418560607, 51.00850279734849]],
    [ "Enedis",[2.2910946693979746, 51.00272549265961]],
    [ "Enedis",[2.2934605553050225, 51.00262249855944]],
    [ "Enedis",[2.2993195, 51.0058445]],
    [ "Enedis",[2.2980542952433534, 51.00458354549637]],
    [ "Enedis",[2.300141243744415, 51.00419954915102]],
    [ "Enedis",[2.301997808163266, 51.00506047891157]],
    [ "Enedis",[2.302330047524095, 51.00595400332336]],
    [ "Enedis",[2.3000206596858637, 51.007932339732406]],
    [ "Enedis",[2.297485325110134, 51.006906505726874]],
    [ "Enedis",[2.298411142882493, 51.00714652713358]],
    [ "Enedis",[2.2979448706745895, 51.01173434358615]],
    [ "Enedis",[2.3039817647524115, 51.01012705378895]],
    [ "Enedis",[2.3019953066058827, 51.012477963976046]],
    [ "Enedis",[2.3019169493102334, 51.00839351700354]],
    [ "Enedis",[2.3027253662303533, 51.00798885010307]],
    [ "Enedis",[2.3127783002364066, 51.00800106619386]],
    [ "Enedis",[2.3087649999999997, 51.00848100000001]],
    [ "Enedis",[2.309672, 51.006015500000004]],
    [ "Enedis",[2.3078715000000005, 51.0072765]],
    [ "Enedis",[2.3080359225806455, 51.012102184946244]],
    [ "Enedis",[2.3058397306292253, 51.011562162766516]],
    [ "Enedis",[2.306715980563654, 51.01345666812439]],
    [ "Enedis",[2.3167389999999997, 51.01321233333333]],
    [ "Enedis",[2.312236258025411, 51.015409250012006]],
    [ "Enedis",[2.3138276374732984, 51.01236124707558]],
    [ "Enedis",[2.3107230730101733, 51.01249183512867]],
    [ "Enedis",[2.3154108100168127, 51.01777046615344]],
    [ "Enedis",[2.3180836014794286, 51.01904184096164]],
    [ "Enedis",[2.3092898093278467, 51.017030967078185]],
    [ "Enedis",[2.3136229999999998, 51.016693]],
    [ "Enedis",[2.3070714999999997, 51.017643]],
    [ "Enedis",[2.3159952246788804, 51.02248345623641]],
    [ "Enedis",[2.313302499999998, 51.02107849999999]],
    [ "Enedis",[2.3125940422322775, 51.01952281447963]],
    [ "Enedis",[2.3148485067581226, 51.020118924548214]],
    [ "Enedis",[2.3051904999999997, 51.020737]],
    [ "Enedis",[2.3085555, 51.021075999999994]],
    [ "Enedis",[2.3105919999999998, 51.02237349999999]],
    [ "Enedis",[2.30814632038835, 51.01929142718448]],
    [ "Enedis",[2.30980411317145, 51.020365654746136]],
    [ "Enedis",[2.3011662001081556, 51.01987782493331]],
    [ "Enedis",[2.303650968876439, 51.01888268160597]],
    [ "Enedis",[2.3026584971448054, 51.01416233512315]],
    [ "Enedis",[2.305105029839471, 51.01560180793201]],
    [ "Enedis",[2.2995330074074074, 51.01489314814814]],
    [ "Enedis",[2.2953330000000003, 51.011994499999986]],
    [ "Enedis",[2.2978503456022947, 51.01688715089229]],
    [ "Enedis",[-1.211030662675148, 46.461517517469744]],
    [ "Enedis",[-1.2081686082741026, 46.46027141570986]],
    [ "Enedis",[-1.2151986499999994, 46.45608334999999]],
    [ "RTE",[2.3316007516878865, 43.21907451886421]],
    [ "Enedis",[2.2949232951347005, 51.01812384238036]],
    [ "Enedis",[2.2923368392712233, 51.015291736962446]],
    [ "Sous-station des Terres Noires",[1.7235343675224941, 47.293233474325525]],
    [ "Enedis",[2.292399833333333, 51.01137016666666]],
    [ "Enedis",[2.289377325482968, 51.01268115762167]],
    [ "Enedis",[2.2874846166666676, 51.01124492916668]],
    [ "Enedis",[2.2850619999999995, 51.01265700000001]],
    [ "Enedis",[2.2879381787634405, 51.014648848118284]],
    [ "Enedis",[2.289495800616876, 51.0162420376735]],
    [ "Enedis",[2.303337960207958, 51.02546659448111]],
    [ "Enedis",[2.294493013858497, 51.02376725893508]],
    [ "Enedis",[2.2963898083565457, 51.02163924642525]],
    [ "Enedis",[2.2824657677183358, 51.02227294558757]],
    [ "Enedis",[2.272497360645562, 51.03684605690047]],
    [ "Enedis",[2.278686100977199, 51.042092752443]],
    [ "Enedis",[2.271725863746958, 51.03924387931873]],
    [ "Enedis",[3.170682750000001, 50.5116535]],
    [ "Enedis",[3.1735536957103276, 50.51079453902742]],
    [ "Poste électrique de Guingamp",[-3.188518230681509, 48.56534341500435]],
    [ "Chavagnes",[-1.261896737844572, 46.869699285919545]],
    [ "Enedis",[2.4175310901527567, 50.99554721003819]],
    [ "Enedis",[2.41777470994685, 50.99366704555809]],
    [ "Enedis",[2.4191204424898514, 50.99131567433469]],
    [ "Enedis",[2.4140833642554145, 50.99517260359456]],
    [ "Enedis",[2.448976, 51.01693949999999]],
    [ "Enedis",[2.4499335, 51.015135]],
    [ "Enedis",[2.445725813966543, 51.017293465476584]],
    [ "Enedis",[2.443426024402643, 51.01650613540079]],
    [ "Enedis",[2.440275365754813, 51.014001247213784]],
    [ "Enedis",[2.437002234896084, 51.01678415160303]],
    [ "Enedis",[2.4338062556144346, 51.02014405299017]],
    [ "Enedis",[2.4408292234533704, 51.01922404709141]],
    [ "Enedis",[2.435876526065163, 51.02146397493734]],
    [ "Enedis",[5.971056266986935, 46.09217885594021]],
    [ "179994945",[6.629650857404022, 43.259614289457645]],
    [ "Enedis",[2.4475289464524765, 51.02051966934404]],
    [ "Enedis",[2.4405762863849763, 51.022372145539904]],
    [ "Enedis",[2.4297345, 51.02506849999999]],
    [ "Enedis",[2.429756557892743, 51.04068072067462]],
    [ "Enedis",[2.446656913276411, 51.04470068776159]],
    [ "Enedis",[2.412882172215372, 51.02878051812577]],
    [ "Enedis",[2.4108728213432986, 51.0306951658881]],
    [ "Enedis",[2.412684282765737, 51.03331452837977]],
    [ "Enedis",[2.4107683089430894, 51.03371095934959]],
    [ "Enedis",[2.4129351826356595, 51.036476315658916]],
    [ "Enedis",[2.4139182781862742, 51.03433133129084]],
    [ "Enedis",[2.415707833333333, 51.036311]],
    [ "Enedis",[2.4191622138364783, 51.03268754716981]],
    [ "Enedis",[2.420076, 51.036657500000004]],
    [ "Enedis",[2.4205095, 51.033305500000004]],
    [ "Enedis",[2.4208435, 51.035165]],
    [ "Enedis",[2.42271251385719, 51.03750499119661]],
    [ "Enedis",[2.4234472759023102, 51.03362991106109]],
    [ "Enedis",[1.0100281945137155, 49.2840651928512]],
    [ "Enedis",[6.530138499999999, 43.2461355]],
    [ "Enedis",[6.53130025, 43.24762625]],
    [ "Enedis",[6.5406179408867, 43.2505992816092]],
    [ "Enedis",[6.539687, 43.251191]],
    [ "Enedis",[6.537451610328639, 43.25097175117371]],
    [ "Enedis",[6.537153500000001, 43.25243999999999]],
    [ "Enedis",[6.535747578574679, 43.252213727928286]],
    [ "Enedis",[6.530883840800305, 43.246979774716756]],
    [ "Enedis",[6.5389884782886325, 43.25271284993614]],
    [ "Enedis",[5.988571, 46.10385649999999]],
    [ "Enedis",[5.991575654571016, 46.11020793139549]],
    [ "Enedis",[-3.2612244954338654, 47.88683625428827]],
    [ "RTE",[1.1512932128370676, 47.345001110264434]],
    [ "Poste électrique de Beaupuy",[-1.236944166681011, 46.64476752868779]],
    [ "Enedis",[0.30081650000000004, 48.129199]],
    [ "Bourg",[0.36213912056737557, 48.1461815177305]],
    [ "Enedis",[0.36209844712990974, 48.14618921047331]],
    [ "Enedis",[0.3876249531442664, 48.14946691656391]],
    [ "Enedis",[2.0195276940764426, 47.58759047875743]],
    [ "Enedis",[2.0250505641587533, 47.587534283662045]],
    [ "Enedis",[2.023100781572395, 47.59494474706511]],
    [ "Enedis",[2.0289686894977166, 47.593317479452054]],
    [ "Enedis",[2.025094227513227, 47.5966598941799]],
    [ "Enedis",[2.0298916532554623, 47.59538972269089]],
    [ "Enedis",[2.0365572512077295, 47.60079595652175]],
    [ "Enedis",[2.027442081770082, 47.59980471476671]],
    [ "Enedis",[2.022806588322575, 47.60005220114252]],
    [ "Enedis",[-0.6918088999999991, 49.3351745]],
    [ "RTE",[-1.4880489381141622, 46.43307561433809]],
    [ "Poste électrique de Crédin",[-2.7546575755329994, 48.02320135520567]],
    [ "Poste électrique de Saint-Père-en-Retz",[-2.0566248053458263, 47.204499240702255]],
    [ "Poste de Quiberon",[-3.122534539171028, 47.488965044522885]],
    [ "Enedis",[2.0371206125348187, 47.60427280869699]],
    [ "Enedis",[2.030347321146953, 47.60208095985663]],
    [ "Enedis",[2.033521050135502, 47.60216108672087]],
    [ "Enedis",[-0.4755418801203651, 49.32762256635605]],
    [ "Enedis",[-0.4708550359491779, 49.327791374551566]],
    [ "Enedis",[-0.47262399638363295, 49.32932451626659]],
    [ "Enedis",[3.177019077746479, 50.69814573334055]],
    [ "Enedis",[3.185930069507717, 50.70468188934204]],
    [ "Enedis",[3.172665511861951, 50.70587145535655]],
    [ "Enedis",[3.168785733333333, 50.512758733333335]],
    [ "Enedis",[2.0224680080192177, 47.60491049946416]],
    [ "Enedis",[2.02652953132702, 47.60860217574412]],
    [ "Enedis",[2.0280512076946198, 47.60680292575895]],
    [ "Enedis",[2.034552349260523, 47.60612948236632]],
    [ "RTE",[2.3602175522453095, 42.58316162331438]],
    [ "Enedis",[3.1728399999999994, 50.5130275]],
    [ "Enedis",[-1.6164747604422047, 46.53967542103406]],
    [ "Enedis",[-1.6170503, 46.5435043]],
    [ "Enedis",[-1.6179183423923693, 46.54169944790862]],
    [ "Enedis",[2.773855, 42.608526]],
    [ "Enedis",[2.7748339892473113, 42.61018082382134]],
    [ "Poste électrique de Long-champ",[3.477986819883594, 49.34318894860476]],
    [ "RTE",[2.848948721153395, 49.23497779137334]],
    [ "Enedis",[4.560569509040809, 47.82209849601213]],
    [ "Enedis",[7.219195689885968, 48.97131077849401]],
    [ "Enedis",[7.2197664, 48.970376249999994]],
    [ "RTE",[3.0386067667392522, 43.02196583568485]],
    [ "Poste électrique Port-La-Nouvelle",[3.0333236224138975, 43.013738783670505]],
    [ "Enedis",[-2.895060700434001, 47.583363153276174]],
    [ "Poste électrique de Crozon",[-4.425502868347117, 48.24729920674017]],
    [ "Enedis",[7.220230229663998, 48.968511594881065]],
    [ "RTE",[4.0127517, 49.68340725000001]],
    [ "Enedis",[3.066722, 50.50883425]],
    [ "Enedis",[-1.5342517500000008, 46.506643749999995]],
    [ "Poste électrique de Rozeboom",[2.2785705588320586, 51.03564569140968]],
    [ "Enedis",[1.1661954035087727, 49.48792004093568]],
    [ "Enedis",[1.1700413259911895, 49.481283516257605]],
    [ "Enedis",[0.9957509133213496, 49.31223470021969]],
    [ "Enedis",[0.9973616651735725, 49.313033993654344]],
    [ "Enedis",[0.9923867880597014, 49.30125673764512]],
    [ "Maison de Retraite",[7.073281000000001, 47.82622299999999]],
    [ "Enedis",[-1.4899171221708039, 46.507174864153264]],
    [ "Enedis",[-1.1669262254657422, 46.46599514538008]],
    [ "Enedis",[-1.1736701411764945, 46.46699898063482]],
    [ "Enedis",[-1.1688074374689301, 46.47037947463882]],
    [ "Enedis",[-1.17102725, 46.468248900000006]],
    [ "Enedis",[-1.1811215, 46.47447374999999]],
    [ "Enedis",[-1.1697964500000007, 46.4665427]],
    [ "Enedis",[-1.1776026737958611, 46.47184696091116]],
    [ "Enedis",[-1.1693405583882477, 46.46842243743163]],
    [ "Enedis",[2.8024477511529238, 49.13639655690832]],
    [ "Enedis",[2.812289211906568, 49.13981555502151]],
    [ "Enedis",[2.8152906600747087, 49.137933075148325]],
    [ "Enedis",[2.8185586713341118, 49.13592897355115]],
    [ "Enedis",[2.7957442050158265, 49.13688994278061]],
    [ "Enedis",[2.8020732962557076, 49.13771235123288]],
    [ "Enedis",[2.811829642156862, 49.13546830882353]],
    [ "Enedis",[2.7973265112002674, 49.141758875961216]],
    [ "Enedis",[2.806151993525342, 49.138472785666444]],
    [ "La Cote",[6.0538281845246935, 46.10955414386629]],
    [ "Grds Champs",[6.0307415, 46.126192]],
    [ "Enedis",[6.007490485673679, 46.11367813323811]],
    [ "Les Teppes",[6.052653299999999, 46.113706300000004]],
    [ "ZA",[6.026783159800809, 46.12728201560597]],
    [ "Les peupliers",[6.0535588508333324, 46.11203302833333]],
    [ "Enedis",[0.9339763237228126, 49.34499855336368]],
    [ "Enedis",[0.850358167436864, 47.38918356594511]],
    [ "Enedis",[0.83227272817149, 47.38157544720153]],
    [ "Enedis",[0.8064494899981715, 47.393009692935514]],
    [ "Enedis",[0.8241553500768001, 47.38096808848099]],
    [ "Enedis",[0.7991155080475858, 47.39432224749241]],
    [ "Enedis",[0.8027831188630483, 47.397274945736434]],
    [ "Enedis",[0.8048104116415414, 47.39492422654941]],
    [ "Enedis",[0.8000527831928134, 47.378699950671376]],
    [ "Enedis",[0.8129811495081972, 47.391643065027324]],
    [ "Enedis",[0.8275253074380221, 47.38416022941329]],
    [ "Enedis",[0.8750751484494499, 47.39163475909112]],
    [ "Enedis",[3.060777460802387, 50.520069320165035]],
    [ "Enedis",[3.095048130110775, 45.58667793524673]],
    [ "Enedis",[-1.4868543203038838, 46.4655069409924]],
    [ "Enedis",[-1.4912088762750282, 46.466360615946776]],
    [ "Enedis",[-1.4729528302023087, 46.4659676957639]],
    [ "Enedis",[-1.4901407500000001, 46.484761]],
    [ "Enedis",[-1.4937568499999998, 46.4699178]],
    [ "Enedis",[-1.48868215, 46.4635126]],
    [ "RTE",[5.343174925495183, 45.05854796395743]],
    [ "RTE",[5.300218734686412, 45.0435417542245]],
    [ "RTE",[5.488167076617978, 45.08067638391028]],
    [ "RTE",[5.3962881025699305, 45.20793647543549]],
    [ "RTE",[5.224975232506076, 45.0766960225679]],
    [ "RTE",[5.744048434560847, 45.44326820352114]],
    [ "Cimetière",[3.1713987547242257, 45.515789130479405]],
    [ "Enedis",[-0.49628064999999943, 43.9051311]],
    [ "RTE",[6.0748913229344526, 45.27979143114937]],
    [ "Sous-station d'Epierre",[6.31317971151125, 45.53445121742207]],
    [ "RTE",[3.5544922260034317, 48.87103451288652]],
    [ "Enedis",[3.064831, 50.52326225000001]],
    [ "Enedis",[1.1851388395461904, 49.47024823230686]],
    [ "Enedis",[1.17860543196347, 49.46487660045661]],
    [ "Enedis",[4.375237683786197, 44.88733620287608]],
    [ "Enedis",[4.3406609132966985, 44.88000948451886]],
    [ "Enedis",[4.364369393502128, 44.88114387277164]],
    [ "Enedis",[1.1548245033274958, 49.398055016929355]],
    [ "Enedis",[1.1264896454352435, 49.38863573460723]],
    [ "Enedis",[1.1445894657559197, 49.3851584708561]],
    [ "Enedis",[1.1259517462511501, 49.39099347172656]],
    [ "Enedis",[1.1362737115235886, 49.40080155607115]],
    [ "Enedis",[1.1315271363429096, 49.4001482129503]],
    [ "Poste électrique de la Roche-sur-Yon",[-1.4334338485517262, 46.68948432650179]],
    [ "RTE",[-2.28595420906848, 47.72737358697699]],
    [ "RTE",[-1.7868219472145415, 47.82953216675274]],
    [ "Enedis",[5.748407034482254, 49.398591892370334]],
    [ "Enedis",[6.2666947, 48.575852699999984]],
    [ "RTE",[5.05517875814222, 44.520769725232086]],
    [ "Enedis",[3.1326867683221398, 50.514841346670245]],
    [ "Enedis",[3.2153012939980554, 47.67674962485637]],
    [ "Enedis",[4.272738691803279, 43.690077412021864]],
    [ "Enedis",[4.283277195698274, 43.69858655457943]],
    [ "Enedis",[4.281605872247657, 43.68739414432091]],
    [ "Enedis",[4.274739810207335, 43.69616746411483]],
    [ "Enedis",[4.275850826233942, 43.68974555983773]],
    [ "Enedis",[4.270404345189703, 43.69157689024391]],
    [ "Enedis",[4.272039151455026, 43.68886068761023]],
    [ "Enedis",[4.346254352648522, 43.54545120456541]],
    [ "Enedis",[4.273641165470744, 43.68818214450623]],
    [ "Enedis",[5.852942170874131, 45.59225289103317]],
    [ "ZI Erier Est 2",[5.889316666666665, 45.600689561403506]],
    [ "Denis Papin",[5.876947966704645, 45.602425748322574]],
    [ "ZI de L'Erier",[5.887715749999999, 45.59886774999999]],
    [ "Le Tremblay",[5.86801430525221, 45.62595340873634]],
    [ "Enedis",[3.7559320762917934, 47.481121772948335]],
    [ "Enedis",[3.7568594704014378, 47.48307588314359]],
    [ "Enedis",[1.151690141414141, 49.43399265656566]],
    [ "Enedis",[1.155156768833234, 49.43238943887895]],
    [ "Enedis",[1.1664177591224545, 49.428913628609806]],
    [ "Enedis",[1.152852328926282, 49.43539392067308]],
    [ "Enedis",[1.1689505000000016, 49.43090099999999]],
    [ "Enedis",[1.1650876523349438, 49.42623966022544]],
    [ "Enedis",[1.1664801186953062, 49.43285732863961]],
    [ "Enedis",[1.1586875, 49.432424]],
    [ "Enedis",[1.160776311819336, 49.43096554225964]],
    [ "Enedis",[1.147371618059349, 49.43127822383033]],
    [ "Enedis",[1.1647968320968585, 49.430394672488404]],
    [ "Enedis",[0.537511529045644, 47.145228899031814]],
    [ "Poste Électrique de Saint-Guillerme",[6.078620337214713, 45.031010617463224]],
    [ "Enedis",[1.0281305, 49.2899835]],
    [ "Enedis",[1.0146693034825869, 49.283356109452725]],
    [ "Enedis",[1.0267218167375884, 49.278850613617024]],
    [ "Enedis",[1.0217599090909086, 49.276125303030305]],
    [ "Enedis",[1.0188442407191218, 49.2789327829403]],
    [ "Enedis",[1.0323543001550386, 49.276833435968996]],
    [ "Enedis",[1.0157753145833335, 49.28231796249999]],
    [ "Enedis",[1.032718169477305, 49.28713033442402]],
    [ "Enedis",[1.0342691693857, 49.27561948580062]],
    [ "Enedis",[1.0150898158427288, 49.27884352279079]],
    [ "Enedis",[1.0314177867603982, 49.28352003134154]],
    [ "Enedis",[1.0236944574982711, 49.28013596175996]],
    [ "RTE",[3.245974349073101, 49.20353560187549]],
    [ "Enedis",[-0.18180673090192215, 44.57937144521111]],
    [ "Enedis",[-0.1899563894389444, 44.57127122532253]],
    [ "Enedis",[-0.19416049999999954, 44.57183550000001]],
    [ "Enedis",[-0.18968400000000005, 44.57482950000001]],
    [ "Sous-station RFF de La Loupe",[0.995546435656381, 48.46936664167757]],
    [ "Enedis",[0.6595765, 44.13289150000001]],
    [ "Enedis",[0.6603551328673107, 44.13539560667966]],
    [ "Poste électrique de Puybelliard",[-1.0383472734223596, 46.7045719368486]],
    [ "Enedis",[6.318965301918465, 45.71147775107913]],
    [ "Enedis",[1.3738543589743586, 47.5800943006993]],
    [ "Enedis",[1.389501094562648, 47.59560187352246]],
    [ "Enedis",[1.3961984500723594, 47.58204181524361]],
    [ "Enedis",[1.3701225, 47.572973499999996]],
    [ "Enedis",[1.4026249999999998, 47.5854745]],
    [ "Enedis",[1.3996103674911666, 47.5853643097762]],
    [ "Enedis",[1.382885, 47.58343]],
    [ "Enedis",[1.380188747052519, 47.58238546302252]],
    [ "Enedis",[1.3796475000000004, 47.58333249999999]],
    [ "Enedis",[1.372314094775213, 47.58481013203726]],
    [ "Enedis",[1.3967426676170116, 47.603036496317415]],
    [ "Enedis",[1.4007574569105694, 47.60043716097561]],
    [ "Enedis",[1.3780650000000005, 47.581407]],
    [ "Enedis",[1.3743541728038506, 47.56728051046932]],
    [ "Enedis",[1.389297145018916, 47.57576979234972]],
    [ "Enedis",[1.3979348747390397, 47.6020808559499]],
    [ "Enedis",[1.3753278160919542, 47.57405769540231]],
    [ "Enedis",[1.4090479999999999, 47.5796125]],
    [ "Enedis",[1.4047924623655914, 47.60637534408602]],
    [ "Enedis",[1.3845214999999989, 47.5685815]],
    [ "Enedis",[1.3749781240934726, 47.587450348374965]],
    [ "Enedis",[1.384281, 47.568439]],
    [ "Enedis",[1.390784, 47.58110100000001]],
    [ "Enedis",[1.3768880468995905, 47.60630662301962]],
    [ "Enedis",[4.526113765882055, 47.579597653505445]],
    [ "Enedis",[4.503197947990544, 47.557198822695035]],
    [ "Dreux",[1.38209614117548, 48.745268464263376]],
    [ "Enedis",[1.457396200156128, 47.593007847306794]],
    [ "Enedis",[1.4769743118279568, 47.598352]],
    [ "Enedis",[1.4310599212962962, 47.58657828240741]],
    [ "Enedis",[1.4740998604091458, 47.60331961131167]],
    [ "Enedis",[3.226650258328864, 44.58587606645357]],
    [ "Enedis",[1.2080613299708587, 49.38577151042368]],
    [ "Enedis",[1.1920633548387098, 49.38096806451613]],
    [ "Enedis",[1.2120706384976525, 49.38570541596244]],
    [ "Enedis",[1.1974605, 49.39023000000001]],
    [ "Enedis",[1.20885850772627, 49.38910229359824]],
    [ "Enedis",[1.2000250000000001, 49.3895865]],
    [ "Enedis",[1.2098768102936313, 49.3872836360937]],
    [ "Enedis",[1.206915, 49.38784295]],
    [ "Enedis",[1.1993155084876541, 49.38298516435185]],
    [ "Enedis",[1.1908817474747477, 49.3795153939394]],
    [ "Enedis",[1.1975724460431656, 49.385153834532375]],
    [ "Enedis",[1.1888471, 49.3781877]],
    [ "Enedis",[1.1954388901200372, 49.38294582363804]],
    [ "Enedis",[1.2062784893617016, 49.38831675886525]],
    [ "Enedis",[1.1925567, 49.3796633]],
    [ "Enedis",[1.1838076631944443, 49.38485701388888]],
    [ "Enedis",[1.3653822919389973, 50.057061549019615]],
    [ "Enedis",[1.3708345000000002, 50.0544045]],
    [ "Amiral Courbet",[1.3656863353696074, 50.06022499626366]],
    [ "Enedis",[1.380031243710692, 50.05738494025158]],
    [ "Enedis",[1.3672666511430986, 50.05636422664408]],
    [ "Enedis",[1.370945622933718, 50.05745586093725]],
    [ "Pré Clos",[5.551119899999999, 44.961381]],
    [ "RTE",[6.392229853722809, 46.06794341408023]],
    [ "Enedis",[0.9508833000475502, 46.702371460770316]],
    [ "RTE",[6.053383188018975, 49.33635400207253]],
    [ "Enedis",[0.3167681743896362, 47.953196491778776]],
    [ "Enedis",[0.35801636132441544, 47.95417358170259]],
    [ "Enedis",[0.3545478999999999, 47.951063366666666]],
    [ "RTE",[2.8230776952344514, 42.61160013154042]],
    [ "Enedis",[1.057441, 49.26338249999999]],
    [ "Enedis",[1.039289123951342, 49.28103762486017]],
    [ "Enedis",[1.0436975, 49.27706049999999]],
    [ "Enedis",[1.0427394825442369, 49.27213807030129]],
    [ "Enedis",[1.0453101751824823, 49.27156858829556]],
    [ "Enedis",[1.037505, 49.27723500000002]],
    [ "Enedis",[1.0472160930506482, 49.276438256772686]],
    [ "Enedis",[1.04454176419214, 49.27013874818051]],
    [ "Enedis",[1.050273, 49.274632499999996]],
    [ "Enedis",[1.0455814008681925, 49.28835204998684]],
    [ "Enedis",[1.048617917397478, 49.28467912118934]],
    [ "Enedis",[1.0438700829471734, 49.282933462001864]],
    [ "Mairie",[6.505071441426059, 45.380071106871576]],
    [ "Enedis",[6.534425585329799, 45.32366027038634]],
    [ "Enedis",[3.1122657499999997, 50.50604975]],
    [ "Enedis",[2.771075649465729, 42.60738322726088]],
    [ "Lagney Gare",[5.839542164844065, 48.73241550263265]],
    [ "Enedis",[4.113188649999999, 43.65450934999999]],
    [ "Enedis",[4.114029499999999, 43.6575865]],
    [ "Enedis",[4.117195333333332, 43.661668999999996]],
    [ "Enedis",[4.116928349999999, 43.65366040000001]],
    [ "Enedis",[1.5188135901700754, 47.61232433767888]],
    [ "Enedis",[6.322357476989714, 44.46894566305722]],
    [ "Enedis",[3.5953576303583974, 49.680632234949634]],
    [ "Enedis",[3.586624155942468, 49.68113526469846]],
    [ "Enedis",[3.586174163060898, 49.67862501362179]],
    [ "Enedis",[3.5920471746031746, 49.68427460317459]],
    [ "Enedis",[3.5900191394422305, 49.682122273572375]],
    [ "RTE",[-3.323931552027641, 48.24694440871495]],
    [ "Poste électrique de Carhaix",[-3.555552592470897, 48.28467340645483]],
    [ "Enedis",[1.5382295906040278, 47.5442868344519]],
    [ "Enedis",[1.550300339712919, 47.55081784649122]],
    [ "Enedis",[1.553876823596793, 47.55094719473081]],
    [ "Enedis",[1.5412515438919585, 47.549234173726205]],
    [ "Enedis",[1.5464934012121216, 47.54362675151514]],
    [ "Enedis",[1.54456564709993, 47.54900228930817]],
    [ "Enedis",[1.5488599864263461, 47.546907935914554]],
    [ "Enedis",[1.5428263134986222, 47.547632971349856]],
    [ "Enedis",[1.5396259999999997, 47.55408175]],
    [ "Enedis",[2.686674, 49.987465500000006]],
    [ "Enedis",[0.9773511264898115, 49.48771887773933]],
    [ "Enedis",[1.0141619205236094, 49.48258363861618]],
    [ "Enedis",[1.0187895, 49.485692499999985]],
    [ "Enedis",[0.9995207542310526, 49.51252118567575]],
    [ "Enedis",[1.131392051693404, 49.33370601426025]],
    [ "Enedis",[1.1370410000000002, 49.33925300000001]],
    [ "Enedis",[2.479180752688172, 49.90214554121864]],
    [ "Enedis",[1.7858691040799837, 46.148390428187376]],
    [ "Enedis",[1.8126373253827857, 46.15047505327216]],
    [ "Enedis",[1.5107160488758546, 47.540926869990216]],
    [ "Enedis",[-4.635644023914182, 48.43628954275249]],
    [ "Enedis",[1.6023619999999994, 47.563814500000014]],
    [ "Enedis",[6.317739700000001, 45.365099900000004]],
    [ "Enedis",[6.316316854614711, 45.36263533064554]],
    [ "Livraison Régie",[6.320685625022104, 45.36449011954023]],
    [ "Poste Source de Saint-Martin-la-Chambre",[6.320962719596415, 45.364412314475466]],
    [ "Enedis",[1.415881323850166, 43.66212645376956]],
    [ "Enedis",[1.4337911408173314, 43.66228277991138]],
    [ "Enedis",[1.4310612545670225, 43.65656590889679]],
    [ "Enedis",[1.4374916899330206, 43.66594355567306]],
    [ "Enedis",[1.4373290133406267, 43.665163214049706]],
    [ "Enedis",[1.4318071505376342, 43.65998128494624]],
    [ "Enedis",[1.4404943000000006, 43.658493549999996]],
    [ "Enedis",[1.4319495, 43.6565575]],
    [ "Enedis",[1.4307383614333595, 43.66550718922403]],
    [ "Enedis",[1.4304595565534666, 43.655852321732624]],
    [ "Enedis",[1.4400834999999998, 43.6604545]],
    [ "Enedis",[1.438079840722496, 43.66715370279145]],
    [ "Enedis",[1.4333037000691944, 43.65944938560734]],
    [ "Enedis",[1.4333630899866485, 43.65641876653316]],
    [ "Enedis",[3.0185017739270585, 50.487599423915384]],
    [ "Enedis",[1.4346873145601395, 43.665315338790656]],
    [ "Enedis",[1.4383011494390343, 43.66367040343983]],
    [ "Enedis",[1.4373797692020893, 43.65336996718867]],
    [ "Enedis",[1.4424724999999998, 43.6497905]],
    [ "Enedis",[1.4401018317504983, 43.65096398874429]],
    [ "Enedis",[1.4385929999999982, 43.651299]],
    [ "Enedis",[1.4445102992700731, 43.64394068288727]],
    [ "Enedis",[1.8351340500000008, 46.09959045000001]],
    [ "Enedis",[1.8399840000000003, 46.1419004]],
    [ "Enedis",[-1.5650977090444689, 48.86817415911937]],
    [ "Enedis",[3.611237657694772, 49.69822223217066]],
    [ "Enedis",[3.6242323249830743, 49.69142928864816]],
    [ "Enedis",[6.216173695702337, 46.15972561751793]],
    [ "Enedis",[6.218630500000001, 46.15106415]],
    [ "Enedis",[6.215293399999998, 46.15811920000001]],
    [ "Enedis",[6.223026846213076, 46.15182307244854]],
    [ "Rue des Trois Lacs",[6.228496284495348, 46.16315310216836]],
    [ "Enedis",[6.218610880089545, 46.14775821016195]],
    [ "Relais Saleve",[6.188140392381388, 46.14518175149652]],
    [ "Enedis",[3.039598064601418, 50.478153781525535]],
    [ "Enedis",[-1.1980781708997834, 43.46518038255562]],
    [ "Enedis",[-1.1961740794980127, 43.47431711020989]],
    [ "Enedis",[-1.203825, 43.478726]],
    [ "Enedis",[-1.193178033201697, 43.48322483340646]],
    [ "Gedia",[1.3631503999999999, 48.7649998]],
    [ "Gedia",[1.3639474, 48.752165649999995]],
    [ "Gedia",[1.36528135, 48.752706149999995]],
    [ "Gedia",[1.3661036000000002, 48.753435550000006]],
    [ "Gedia",[1.3658990000000002, 48.75297905]],
    [ "Gedia",[1.369010474894434, 48.74823925401696]],
    [ "Gedia",[1.3577837500000014, 48.748561249999995]],
    [ "Gedia",[1.3607003324177005, 48.750334771179524]],
    [ "Gedia",[1.3547638500000003, 48.75141815]],
    [ "Gedia",[1.3622749686362137, 48.751077203173146]],
    [ "Gedia",[1.3622169623267515, 48.75105425645168]],
    [ "Gedia",[1.3668976819694283, 48.74844913123892]],
    [ "Gedia",[1.366247133334394, 48.74641579969452]],
    [ "Gedia",[1.35978735, 48.7482605]],
    [ "Gedia",[1.3559858938635392, 48.7483497499247]],
    [ "Gedia",[1.3523669123982967, 48.748247217613425]],
    [ "Gedia",[1.3465025000000002, 48.746421999999995]],
    [ "Gedia",[1.3482698415162053, 48.747925133512474]],
    [ "Gedia",[1.3518307367753886, 48.74841392044782]],
    [ "Gedia",[1.3523046905124974, 48.75026838170635]],
    [ "Gedia",[1.3437405499999997, 48.75104040000001]],
    [ "Gedia",[1.3484269999999998, 48.74990225]],
    [ "Gedia",[1.3483752344641897, 48.74987149838449]],
    [ "Gedia",[1.3747459500000017, 48.7482798]],
    [ "Gedia",[1.360508009880946, 48.7419283980773]],
    [ "Gedia",[1.3581242169598395, 48.74259840035485]],
    [ "Gedia",[1.365684374537875, 48.7452117535787]],
    [ "Gedia",[1.36940135, 48.7448058]],
    [ "Gedia",[1.3648749987331716, 48.743071532902086]],
    [ "Gedia",[1.3749594200289226, 48.744694821378644]],
    [ "Gedia",[1.3728275999999997, 48.743731700000005]],
    [ "Gedia",[1.3958762499999997, 48.75002924999999]],
    [ "Gedia",[1.3955152172520335, 48.74526355083927]],
    [ "Gedia",[1.358316561902956, 48.73133575465699]],
    [ "Gedia",[1.3572786078324328, 48.73262008092859]],
    [ "Gedia",[1.3665071252983396, 48.73373812860653]],
    [ "Gedia",[1.363725010529852, 48.73638662343487]],
    [ "Gedia",[1.3850972499999998, 48.73624325]],
    [ "Gedia",[1.386310997723035, 48.73661293362713]],
    [ "Gedia",[1.3965796264766417, 48.741099261753774]],
    [ "Gedia",[1.3927343500000002, 48.750396499999994]],
    [ "Gedia",[1.3927576085807745, 48.750471677091554]],
    [ "Gedia",[1.3965586950250324, 48.75410233412052]],
    [ "Gedia",[1.3973873836952158, 48.75280520122393]],
    [ "Gedia",[1.39741095, 48.753207249999996]],
    [ "Gedia",[1.3973721143813573, 48.75276612015978]],
    [ "Gedia",[1.364161, 48.729387]],
    [ "Gedia",[1.3627321000000003, 48.72941035]],
    [ "Gedia",[1.3839974000000002, 48.73842755000001]],
    [ "Gedia",[1.3803482128763065, 48.73951191901744]],
    [ "Gedia",[1.3881535414041382, 48.74745701535693]],
    [ "Gedia",[1.36827945, 48.731055999999995]],
    [ "Gedia",[1.3667475840552963, 48.7306473322371]],
    [ "Gedia",[1.36824055, 48.734289249999996]],
    [ "Gedia",[1.3828395859756766, 48.72770411962206]],
    [ "Gedia",[1.388164318296942, 48.729590994959146]],
    [ "Gedia",[1.38266575, 48.729748199999996]],
    [ "Gedia",[1.3494411, 48.7516619]],
    [ "Gedia",[1.350906996071617, 48.7518649290327]],
    [ "Gedia",[1.3548166665721073, 48.75371975019588]],
    [ "Gedia",[1.3528539071572165, 48.752397872915616]],
    [ "Enedis",[1.3455100086428402, 48.73201294347115]],
    [ "Enedis",[1.3533385, 48.73193199999999]],
    [ "Enedis",[1.3495840000000001, 48.72272949999999]],
    [ "Enedis",[1.3365699201485617, 48.717038682451246]],
    [ "Enedis",[1.3314151863517052, 48.71697102624671]],
    [ "Enedis",[1.3372582872538088, 48.71494632627276]],
    [ "Enedis",[1.3767512119944225, 48.71459252719665]],
    [ "Enedis",[1.3796383394777267, 48.714717797235025]],
    [ "Enedis",[1.3578829795294836, 48.717065323556376]],
    [ "Enedis",[1.358332461077844, 48.70813814171657]],
    [ "Enedis",[1.3640148692810454, 48.71592890849673]],
    [ "Enedis",[1.3623839277799505, 48.71672985073654]],
    [ "Enedis",[1.3607338362573094, 48.71295194949495]],
    [ "Enedis",[1.3630182085402183, 48.71420754948693]],
    [ "Enedis",[1.3632007604832979, 48.723404122245924]],
    [ "Enedis",[1.370143815602837, 48.71529749269919]],
    [ "Enedis",[1.3650618783068782, 48.7187816984127]],
    [ "Enedis",[1.3569409134199135, 48.71958383982684]],
    [ "Enedis",[1.360086794481446, 48.718811543292105]],
    [ "Enedis",[1.362516297760211, 48.72172170421607]],
    [ "Enedis",[1.3573691927945466, 48.71525272379098]],
    [ "Enedis",[1.3703068997588326, 48.72358553560835]],
    [ "Enedis",[1.3657427674881752, 48.72305957729648]],
    [ "Enedis",[-1.3540935290125322, 46.42532570672124]],
    [ "Enedis",[1.3567681102018547, 48.710143886524826]],
    [ "Enedis",[1.3707261471912326, 48.717668747308664]],
    [ "Enedis",[1.3742396666666663, 48.71833928571428]],
    [ "Enedis",[-2.8911822593814205, 47.79019726781841]],
    [ "Enedis",[1.3270023344632769, 48.73460351384181]],
    [ "Enedis",[1.3276104142326073, 48.73208658612144]],
    [ "Enedis",[1.321656, 48.730959]],
    [ "Enedis",[1.3220432218398714, 48.72868005368203]],
    [ "Enedis",[1.3234717082579865, 48.72671749246533]],
    [ "Enedis",[1.3252008508268054, 48.725352826805924]],
    [ "Enedis",[1.330328160790897, 48.73049198601007]],
    [ "Enedis",[1.3339403901604838, 48.7302497932123]],
    [ "Enedis",[1.3392701127260147, 48.7351637522277]],
    [ "Enedis",[1.337489, 48.7340495]],
    [ "Enedis",[1.3383506660329532, 48.72901849524715]],
    [ "Enedis",[1.340820290301548, 48.73088432648737]],
    [ "Enedis",[1.333167661011472, 48.73212218629828]],
    [ "Enedis",[1.337898244841523, 48.71975105105297]],
    [ "Enedis",[1.3320986546175195, 48.72482468529528]],
    [ "Enedis",[1.3294209011689695, 48.72501659723698]],
    [ "Enedis",[1.3329075472963123, 48.72721665704709]],
    [ "Enedis",[1.332074908024304, 48.7275297345485]],
    [ "Enedis",[1.3427757432478638, 48.72612509299146]],
    [ "Enedis",[1.3242017725366864, 48.72193111740041]],
    [ "Enedis",[6.949914829059829, 47.824113504273505]],
    [ "Poste électrique de Passy",[6.724982747023938, 45.915969740099]],
    [ "Enedis",[6.376451423143032, 44.516556984346224]],
    [ "Enedis",[6.375038733159872, 44.51618016160741]],
    [ "Enedis",[-1.4991701007094016, 46.573732433302126]],
    [ "Enedis",[-1.50627735, 46.574547249999995]],
    [ "Enedis",[-1.5116301429362589, 46.57391211819191]],
    [ "Enedis",[-1.515753762993949, 46.575238919205376]],
    [ "Enedis",[3.601230225697948, 47.909630930037004]],
    [ "Enedis",[3.594732393825055, 47.907082685744186]],
    [ "Enedis",[3.5961588775510207, 47.905900687074826]],
    [ "Enedis",[3.599911442307692, 47.90388326282051]],
    [ "Enedis",[3.596363330611368, 47.90070583491149]],
    [ "Enedis",[3.61070150260787, 47.901502094831685]],
    [ "Poste électrique La Janais",[-1.7114932589954166, 48.05386141443349]],
    [ "Enedis",[-1.0825262264379416, 46.42047717635385]],
    [ "RTE",[-1.544594235623123, 48.840715274287064]],
    [ "Enedis",[0.5138394999999999, 49.347682999999996]],
    [ "Enedis",[0.500308945355192, 49.35648675956285]],
    [ "Enedis",[0.5327372948846539, 49.351489021731865]],
    [ "Enedis",[0.5217960261437908, 49.35225233333333]],
    [ "Enedis",[0.5041844947381764, 49.35995667617598]],
    [ "Enedis",[0.5351641874999992, 49.348870974637684]],
    [ "Enedis",[0.5276686513661204, 49.35015381420765]],
    [ "Enedis",[0.498642, 49.352993000000005]],
    [ "Enedis",[0.5094232398406373, 49.35657467543161]],
    [ "Enedis",[2.4860703500000003, 46.18151105]],
    [ "Enedis",[2.489733245135164, 46.17325793001909]],
    [ "Enedis",[2.4833835999999994, 46.17322145000001]],
    [ "Enedis",[2.48325735, 46.181767949999994]],
    [ "Enedis",[2.4835198500000004, 46.1763177]],
    [ "Enedis",[2.485938028755521, 46.17053889555727]],
    [ "Enedis",[2.525789016496414, 46.1365323078707]],
    [ "182911598",[2.5006147, 46.171271149999995]],
    [ "Enedis",[0.8319464190631217, 48.331865816603354]],
    [ "Enedis",[0.8208444160000004, 48.32754066133333]],
    [ "Enedis",[0.828244003963012, 48.32258197798327]],
    [ "RTE",[6.409921835052807, 45.24635719945588]],
    [ "RTE",[6.808581645146546, 44.760012537661915]],
    [ "RTE",[4.867802940615419, 43.40860332354446]],
    [ "RTE",[5.190997650166753, 43.48848067306551]],
    [ "RTE",[5.203877855497764, 43.496994258056695]],
    [ "RTE",[6.039199592604765, 45.50449284443008]],
    [ "Enedis",[0.8164266237314599, 48.33214097501953]],
    [ "Enedis",[0.7898443172690759, 48.31441533065596]],
    [ "Enedis",[3.970638722681669, 48.81813604055179]],
    [ "RTE",[4.034534611351362, 49.00792893460819]],
    [ "Enedis",[5.222460399999999, 47.25261819999999]],
    [ "Enedis",[5.2278418721264375, 47.23928476005747]],
    [ "Enedis",[5.2275088750313055, 47.24040136589031]],
    [ "Enedis",[-0.5870697607810481, 44.79315288811772]],
    [ "Russac",[-0.5908943567642962, 44.793965179637375]],
    [ "Plume la poule",[-0.5854238975426905, 44.79706135826739]],
    [ "Enedis",[0.8081265708673319, 48.325899681474766]],
    [ "Enedis",[0.8064733124999998, 48.332183332614946]],
    [ "Enedis",[0.7988238209072976, 48.31793946824459]],
    [ "Enedis",[5.5728683326321615, 47.28107824929679]],
    [ "Enedis",[-1.2121210999999998, 46.53341874999999]],
    [ "Enedis",[-1.2215833440061867, 46.53384337909745]],
    [ "Enedis",[-1.2213765380736976, 46.539525420013405]],
    [ "Enedis",[4.851792788050315, 47.31108860566037]],
    [ "Enedis",[3.514508694935218, 49.60404604711425]],
    [ "Enedis",[3.5120203101705623, 49.60789046641398]],
    [ "Enedis",[1.7184032182873155, 46.887410090736296]],
    [ "Poste électrique de Contamine",[6.554283833950924, 45.49395157068292]],
    [ "Enedis",[3.2220050000000002, 50.5229385]],
    [ "Enedis",[3.2324728963974585, 50.527010031761314]],
    [ "RTE",[4.44325310064739, 44.91184452833038]],
    [ "Enedis",[0.819065, 48.321174]],
    [ "Enedis",[0.8157038234730992, 48.321125491097064]],
    [ "Enedis",[0.8417309999999997, 48.31627999999999]],
    [ "Enedis",[0.8382396203790967, 48.312840805738816]],
    [ "Enedis",[0.8338030000000001, 48.31192899999999]],
    [ "Enedis",[0.836316051926298, 48.311294177554444]],
    [ "Enedis",[0.8313679008002975, 48.31342327135678]],
    [ "Enedis",[0.8259906852085968, 48.31301927096502]],
    [ "Enedis",[0.8352971089211612, 48.31497056829184]],
    [ "Enedis",[0.8329631401648999, 48.31820089281507]],
    [ "Enedis",[0.8282468881002428, 48.31804501162965]],
    [ "Enedis",[0.8238445, 48.319752]],
    [ "Enedis",[0.8214579176470587, 48.31818772287581]],
    [ "Enedis",[0.8246428708452043, 48.32213624501424]],
    [ "Enedis",[0.8242887037037037, 48.32396744444444]],
    [ "Enedis",[0.8111816663866331, 48.31185250228693]],
    [ "Enedis",[0.8232681666666666, 48.312289]],
    [ "Enedis",[0.8153936655752028, 48.314577739358214]],
    [ "Enedis",[0.8145873378112972, 48.315749375520454]],
    [ "RTE",[5.480477669548262, 45.69346557720527]],
    [ "RTE",[2.520162481224348, 49.36733177788948]],
    [ "RTE",[5.418790239868478, 45.80388501546372]],
    [ "RTE",[5.693313005349483, 45.76919121154002]],
    [ "Poste source",[5.777568189289019, 45.70866531204536]],
    [ "RTE",[5.499185633750317, 45.92456058644916]],
    [ "SNCF Réseau",[5.599155846934793, 45.827070543917394]],
    [ "Poste électrique de Chavanod",[6.081190186768324, 45.8943070565616]],
    [ "RTE",[5.842458027745863, 45.93290200686547]],
    [ "SNCF Réseau",[5.943008906916033, 45.872073387545456]],
    [ "RTE",[5.955442163663896, 45.86512646733271]],
    [ "Bourg",[4.644113251468224, 46.75844729950152]],
    [ "Enedis",[0.6833688231552163, 48.26833606106871]],
    [ "Enedis",[0.6792991091915833, 48.26529570808415]],
    [ "Enedis",[0.6826371502347409, 48.26370218864704]],
    [ "Enedis",[0.677317174362357, 48.263354420404575]],
    [ "Enedis",[0.684046146101114, 48.26128659340189]],
    [ "Enedis",[0.6802628136482939, 48.26318898425197]],
    [ "Enedis",[0.6827977827852217, 48.267166174989846]],
    [ "Enedis",[0.6731778902627514, 48.265204373003606]],
    [ "Enedis",[0.6822404144649044, 48.262366588737656]],
    [ "Sous-station d'Héricourt",[6.795701909869857, 47.55971499146548]],
    [ "Enedis",[1.432446, 47.5222795]],
    [ "Enedis",[1.4529300102564102, 47.508074830769225]],
    [ "RTE",[6.433150268548634, 45.75056139663648]],
    [ "Poste électrique de Venthon",[6.403399583267296, 45.693207423562036]],
    [ "Sous-Station de Saint-Pierre-d'Albigny",[6.178370441404985, 45.564217116155525]],
    [ "RTE",[6.160669044289785, 45.549963696868176]],
    [ "RTE",[6.4349427635718985, 45.75200970303656]],
    [ "Poste électrique de Faverges",[6.29509245622353, 45.75198487146153]],
    [ "Poste électrique de Thônes",[6.31982374482489, 45.88465214297037]],
    [ "Poste source de Chamonix",[6.874403336434151, 45.92057070376799]],
    [ "SNCF Réseau",[6.706331134378742, 45.91044342324471]],
    [ "SNCF Réseau",[5.887207768411403, 46.11367807122555]],
    [ "Enedis",[3.21861395079365, 50.53101096190476]],
    [ "RTE",[5.171384174293711, 43.50324958918413]],
    [ "Sous-station de Miramas",[5.01375486413139, 43.578835638087995]],
    [ "Enedis",[5.674448079074826, 45.27073101914755]],
    [ "RTE",[0.40067212280961034, 46.55160609792902]],
    [ "Enedis",[2.539570764573119, 48.851632455366186]],
    [ "Enedis",[2.536357, 48.85046099999999]],
    [ "Enedis",[2.5456739999999987, 48.84690525]],
    [ "Enedis",[2.5438432499999997, 48.850570000000005]],
    [ "RTE",[6.572887382306184, 45.37557601282436]],
    [ "Enedis",[4.7627376574271505, 45.94813625941719]],
    [ "Enedis",[3.2040837500000015, 50.5275065]],
    [ "Poste électrique de Villesèque des Corbières",[2.887956196680145, 43.00716825002281]],
    [ "Poste électrique de Lézignan-Corbières",[2.7787750624758183, 43.20134473730392]],
    [ "RTE",[2.0772020350834164, 42.7259525676763]],
    [ "RTE",[2.1373771698927477, 42.713502496050246]],
    [ "Enedis",[-4.25165580932755, 47.84886329997592]],
    [ "Enedis",[1.367046831902626, 46.31663936805467]],
    [ "Enedis",[2.95780045, 50.541571250000004]],
    [ "Enedis",[2.9568353010038955, 50.53604779701405]],
    [ "Enedis",[2.9572765000000003, 50.5390265]],
    [ "SNCF Réseau",[3.0125050154102175, 42.549417159333636]],
    [ "Enedis",[1.3371788178217827, 46.93146875016502]],
    [ "Enedis",[1.341897248888889, 46.926232542222216]],
    [ "Enedis",[1.338164935770343, 46.925293085914916]],
    [ "RTE",[5.1163035246755, 44.35213458570526]],
    [ "RTE",[4.885623446682524, 44.217444169865175]],
    [ "RTE",[4.90911645408832, 44.21522184258024]],
    [ "RTE",[5.087765030899837, 44.242620578180194]],
    [ "Sous-station SNCF de Lapalud",[4.697524986507116, 44.31894720213641]],
    [ "Sous-station SNCF de Piolenc",[4.787680217762778, 44.172683647821785]],
    [ "Enedis",[4.981135142464914, 44.06322759886373]],
    [ "Terradou",[5.045178673290735, 44.034874905847495]],
    [ "RTE",[5.047571786684561, 44.062262363364965]],
    [ "RTE",[4.859402486218907, 43.98734211245453]],
    [ "RTE",[4.861131668347992, 43.990517948049245]],
    [ "RTE",[4.861803454128025, 43.98610107632675]],
    [ "Enedis",[5.198836188869346, 43.85714980602216]],
    [ "Sous-station de Le Camp",[5.046906392983515, 43.825116080831435]],
    [ "Sous-station SNCF de Biscarrat",[4.55345915967674, 43.84385317616457]],
    [ "RTE",[4.431673541321523, 43.81983116781391]],
    [ "Enedis",[3.443295226666667, 48.282258946666666]],
    [ "RTE",[4.53610085639399, 43.79321982564489]],
    [ "Enedis",[3.4740336666666667, 48.20010666666667]],
    [ "Enedis",[1.9080792735086374, 45.79330552506168]],
    [ "Sous-Station des Ségonnaux",[4.644090290330115, 43.75236197221057]],
    [ "RTE",[1.347519007310887, 48.06752073633094]],
    [ "RTE",[4.458766703664452, 43.71852746028071]],
    [ "Poste électrique de Roure",[4.39931007846589, 43.5493092772715]],
    [ "Salin de Giraud",[4.730890841901458, 43.416762997788126]],
    [ "RTE",[4.796004777573187, 43.41096816890837]],
    [ "Poste éléectrique de Port St-Louis du Rhône",[4.814426267721037, 43.39336536655294]],
    [ "Enedis",[1.919885540459007, 45.74591921607446]],
    [ "Enedis",[1.9234360000000001, 45.764943999999986]],
    [ "Enedis",[5.882171622612397, 45.40694608999734]],
    [ "Enedis",[2.2757466873156345, 49.768643451327435]],
    [ "Enedis",[2.2624580000000005, 49.7673545]],
    [ "Poste électrique de Louveciennes",[2.1101036608570487, 48.8498559435677]],
    [ "Enedis",[2.948099, 50.544247750000004]],
    [ "Enedis",[2.954165250000001, 50.54336525]],
    [ "Poste HT du Mas de Goin",[4.8058840927312705, 43.62513652364288]],
    [ "UZES",[4.40637189792069, 44.00693871761577]],
    [ "RTE",[4.625626956693877, 43.89554656399638]],
    [ "RTE",[4.308693192243828, 44.50510895605394]],
    [ "Ruisseau",[7.059588, 47.772664000000006]],
    [ "SNCF Réseau",[4.729540796916866, 44.61298897283049]],
    [ "SNCF Réseau",[4.763861935911271, 44.62634222308153]],
    [ "SNCF Réseau",[4.785957805134945, 44.703805580929924]],
    [ "RTE",[4.562679252711687, 44.97723264029302]],
    [ "Enedis",[5.8660744787528865, 45.46097767444187]],
    [ "RTE",[4.728478497802942, 45.74572018655362]],
    [ "Poste électrique de Lizy-sur-Ourcq",[3.013147449454989, 49.028192534980896]],
    [ "Enedis",[0.9601146542927852, 49.30390751570532]],
    [ "Enedis",[0.9559815000000004, 49.296185]],
    [ "Enedis",[0.9616986418079086, 49.301056288700565]],
    [ "Enedis",[0.9478185, 49.309456574561395]],
    [ "Enedis",[0.9564483444589882, 49.306329536212914]],
    [ "Enedis",[0.9473506640584245, 49.30802350286906]],
    [ "Enedis",[0.9625522481004286, 49.3053366674375]],
    [ "Enedis",[2.999116251572327, 42.61670839622642]],
    [ "Enedis",[3.0237672996941893, 42.632274764525995]],
    [ "Enedis",[3.0278869461219653, 42.63656768324453]],
    [ "Enedis",[3.0068684999999995, 42.616971]],
    [ "Enedis",[3.027415, 42.63403650000001]],
    [ "Enedis",[3.0207560000000004, 42.635511]],
    [ "Enedis",[3.0277639888693613, 42.607046598320636]],
    [ "Enedis",[3.0402999193235063, 42.60254740132556]],
    [ "Enedis",[3.0330477998433216, 42.60898034900117]],
    [ "Enedis",[2.9989561663152964, 42.61976700538767]],
    [ "Enedis",[3.010247831627907, 42.616341991317825]],
    [ "Enedis",[3.0264585, 42.6317475]],
    [ "Enedis",[3.0285655000000005, 42.631564]],
    [ "Enedis",[3.036605255644852, 42.6019935573415]],
    [ "Enedis",[3.02445870771757, 42.63080173686371]],
    [ "Enedis",[3.001714241999534, 42.619213059565524]],
    [ "Enedis",[3.0035567000000007, 42.615664900000006]],
    [ "Enedis",[3.037631589430894, 42.62008362804878]],
    [ "Enedis",[3.0284515987062237, 42.626568730240166]],
    [ "Enedis",[3.0287476009419825, 42.62088770605867]],
    [ "Enedis",[3.0283219999999997, 42.618703499999995]],
    [ "Enedis",[3.0159665096418733, 42.617217209366395]],
    [ "Enedis",[3.012529928178316, 42.633960080719135]],
    [ "Enedis",[3.0271479999999995, 42.6252105]],
    [ "Enedis",[3.032706247222223, 42.61107456805556]],
    [ "Enedis",[3.024654817681079, 42.628577523730826]],
    [ "Enedis",[3.0257352359208523, 42.63554493668189]],
    [ "Enedis",[3.021839236286919, 42.63696856962025]],
    [ "Enedis",[3.0304861021897818, 42.63683626034064]],
    [ "Enedis",[3.0221563888249583, 42.634280271832246]],
    [ "Enedis",[3.0407708966049376, 42.60165463139329]],
    [ "Enedis",[3.0269825235572516, 42.62900126121354]],
    [ "Enedis",[4.479823602708805, 46.798828793829955]],
    [ "Enedis",[4.474301586940016, 46.802424400151864]],
    [ "Enedis",[5.840701010426542, 45.83000406018958]],
    [ "Enedis",[5.851506880438448, 45.811901490612705]],
    [ "Enedis",[2.9490216094994803, 50.53889622562409]],
    [ "RTE",[3.5818876762359286, 44.3426579242917]],
    [ "RTE",[3.2530666868303553, 44.509100637261845]],
    [ "RTE",[3.063533127336762, 44.42187985920397]],
    [ "RTE",[3.0550506910933106, 44.3265559380121]],
    [ "SNCF Réseau",[3.098981521011973, 44.14945852178419]],
    [ "RTE",[3.071051923813089, 44.09290959361251]],
    [ "RTE",[4.079037057661684, 44.148937492995906]],
    [ "RTE",[4.074699616918817, 44.134422390232054]],
    [ "RTE",[4.078087178368651, 43.782209947745244]],
    [ "RTE",[4.043561706310048, 44.201066669034326]],
    [ "RTE",[4.235384383517064, 43.98321170525907]],
    [ "RTE",[3.9852851319611027, 44.04149772796672]],
    [ "Enedis",[1.8923975258013292, 46.21540599151054]],
    [ "Enedis",[1.8941857499999997, 46.21405364999999]],
    [ "Poste électrique de Servon",[-1.469808271981599, 48.133411607150435]],
    [ "Arcoliere",[5.773160177113003, 45.69446039450649]],
    [ "Charrey",[5.768806999999999, 45.70587600000001]],
    [ "Enedis",[4.081875602644399, 43.78109776130829]],
    [ "Enedis",[4.0894231, 43.78799665]],
    [ "Enedis",[5.9709919370375335, 45.01727111471516]],
    [ "Enedis",[2.95294205, 50.5368548]],
    [ "Poste électrique de Aime",[6.6594147524921015, 45.55287231772726]],
    [ "RTE",[6.646733634320785, 45.43961173821147]],
    [ "RTE",[6.48605653175401, 45.52263936877231]],
    [ "Sous Station Quai d'Issy",[2.2677072194378796, 48.83558946445522]],
    [ "Enedis",[2.4227439999999993, 50.519743]],
    [ "RTE",[5.780363162634429, 45.07866012082397]],
    [ "RTE",[1.7499240500000002, 46.675439299999994]],
    [ "Enedis",[1.7732164999999989, 48.456036]],
    [ "Enedis",[1.7683001736090882, 48.46325147490047]],
    [ "Enedis",[1.768547637146372, 48.459141335793355]],
    [ "Enedis",[1.7647452010761084, 48.46266220098932]],
    [ "Enedis",[1.7621867562104463, 48.46243934202323]],
    [ "Enedis",[1.7680663447293448, 48.47368807122508]],
    [ "Enedis",[1.7605015000000002, 48.466598499999996]],
    [ "Enedis",[1.7795585000000007, 48.46815550000001]],
    [ "Enedis",[1.7713035040033713, 48.46486383480827]],
    [ "Enedis",[1.7730145722288913, 48.46955201320528]],
    [ "Enedis",[1.7738760069904345, 48.45597342163356]],
    [ "Enedis",[1.7675383333333334, 48.46100035999999]],
    [ "Enedis",[1.7723545060492287, 48.45944650771798]],
    [ "Enedis",[1.7640982940320238, 48.46573619577875]],
    [ "Enedis",[1.7685232250190699, 48.476329632951945]],
    [ "Enedis",[1.7750868337212309, 48.46157850659426]],
    [ "Enedis",[1.765402664101047, 48.47519236964673]],
    [ "Enedis",[1.77489, 48.47034700000001]],
    [ "Enedis",[1.7797168575129532, 48.46591632210708]],
    [ "Enedis",[1.7719110276187868, 48.46111401698919]],
    [ "Bourg",[5.160814433410687, 45.58770779957611]],
    [ "Enedis",[2.9510178829251212, 50.53420129659613]],
    [ "Enedis",[2.9486685499999994, 50.536476650000004]],
    [ "Enedis",[2.525648429813543, 46.72133872920511]],
    [ "Enedis",[2.5399693135118344, 46.719944276540694]],
    [ "Enedis",[2.5016787456156018, 46.73419105767257]],
    [ "Enedis",[2.4991014125853845, 46.732164329242316]],
    [ "Enedis",[2.497948693936484, 46.73648493783838]],
    [ "Enedis",[2.503333164969991, 46.73819757545755]],
    [ "Enedis",[2.49089645, 46.7346246]],
    [ "Enedis",[2.4959157274276116, 46.736700821823675]],
    [ "Enedis",[2.4974281378367316, 46.723957573268365]],
    [ "Enedis",[2.4987128281568287, 46.73014884395152]],
    [ "Enedis",[2.5026235645729193, 46.729647767437164]],
    [ "Enedis",[2.5000598608892606, 46.72705846046687]],
    [ "Enedis",[2.5052746416932665, 46.72102843118153]],
    [ "Enedis",[2.502048998779557, 46.72209889464362]],
    [ "Enedis",[2.5020306999999997, 46.71828364999999]],
    [ "Enedis",[2.513892026797099, 46.716629654047146]],
    [ "Enedis",[2.5212306212561097, 46.724249883902864]],
    [ "Enedis",[2.509497354534259, 46.72111516053498]],
    [ "Enedis",[2.5291369292858494, 46.723294130816555]],
    [ "Enedis",[2.5093640575965237, 46.728454493531665]],
    [ "Enedis",[2.51221477680025, 46.72709132540594]],
    [ "Enedis",[2.5166111617852427, 46.73521006971757]],
    [ "Enedis",[2.5052632710056466, 46.73045916098037]],
    [ "184416320",[2.5207581163438726, 46.73317961696848]],
    [ "Enedis",[2.5109873031063885, 46.73573445833945]],
    [ "Enedis",[2.509803287391407, 46.73471322057231]],
    [ "Enedis",[2.5123315853992283, 46.73221448110128]],
    [ "Enedis",[2.505349906415554, 46.730321119812956]],
    [ "Enedis",[2.512407378868609, 46.73459192253426]],
    [ "Enedis",[2.8903977857142857, 50.34690779761904]],
    [ "Enedis",[2.9100963292053668, 50.35126399277607]],
    [ "Enedis",[2.910887857777778, 50.35315082888889]],
    [ "Enedis",[2.9158495, 50.352576]],
    [ "Enedis",[5.437296000000001, 45.14218950000001]],
    [ "184449279",[5.840641511696472, 45.84783162553568]],
    [ "Enedis",[5.851284568872646, 45.84722614438462]],
    [ "Enedis",[1.0429731412429384, 49.679964254237284]],
    [ "Poste électrique de laThur",[7.116020110245094, 47.8082599828999]],
    [ "Poste Electrique de Landres",[5.77412122762214, 49.30753790885645]],
    [ "Chattes",[6.249859916736788, 46.204782957390655]],
    [ "Enedis",[0.3441648188168242, 49.27282501960473]],
    [ "Les Moulins Gaud",[6.237901812381822, 46.20472244849125]],
    [ "Henon",[6.2408682, 46.20434385]],
    [ "Enedis",[1.6918580632603406, 46.693353637469585]],
    [ "Enedis",[-1.5688542402369023, 48.88944704744573]],
    [ "Enedis",[5.378393816568047, 45.18327372555648]],
    [ "Enedis",[-0.5895945764517846, 43.65493820955427]],
    [ "Enedis",[-0.5921903484491052, 43.6636202112276]],
    [ "Enedis",[-0.5867890000000014, 43.661306499999995]],
    [ "Enedis",[-0.5850806306620205, 43.65885618699187]],
    [ "Enedis",[2.517404538305563, 50.44198883381822]],
    [ "Enedis",[-0.614308182795699, 43.64034066666667]],
    [ "Enedis",[-0.6192432737003057, 43.63870045504587]],
    [ "Enedis",[-0.5999875, 43.6538105]],
    [ "Enedis",[-1.023430252959429, 47.25492480945438]],
    [ "Enedis",[-0.6019554280282448, 43.66256534419699]],
    [ "Enedis",[-0.6019945395154549, 43.65710081035923]],
    [ "Enedis",[-0.5914671666666669, 43.655145]],
    [ "Enedis",[-0.596228832445234, 43.65271549654628]],
    [ "Babys",[6.139819700000002, 46.535530299999984]],
    [ "Enedis",[6.1398553, 46.539828199999995]],
    [ "Enedis",[6.12465900108666, 46.531042487539615]],
    [ "Enedis",[6.141870999999999, 46.539106249999996]],
    [ "Enedis",[6.140042234102933, 46.54159560185889]],
    [ "Enedis",[6.145204, 46.54832899999999]],
    [ "Enedis",[6.146794500000001, 46.539840500000004]],
    [ "Enedis",[6.111295245688226, 46.52297000407719]],
    [ "Enedis",[6.107123, 46.52006399999999]],
    [ "Enedis",[6.151434690996397, 46.543099705479115]],
    [ "Enedis",[6.12478875, 46.52260700000001]],
    [ "Enedis",[6.132911873523014, 46.52921600621988]],
    [ "Enedis",[0.5099961873463796, 47.3396181196719]],
    [ "Enedis",[0.5057831981981978, 47.33442415315316]],
    [ "Enedis",[0.510659825340439, 47.33837336550227]],
    [ "Enedis",[6.232280449999999, 46.202356300000005]],
    [ "Link",[6.232048000000001, 46.2012325]],
    [ "Enedis",[1.3817425999999997, 43.70067985]],
    [ "Enedis",[-0.6785559905169022, 43.26783041827497]],
    [ "Enedis",[6.974988465751368, 47.7801756683172]],
    [ "Enedis",[1.4167376239055203, 46.883934832620646]],
    [ "Enedis",[1.413100117456461, 46.883746400567034]],
    [ "Enedis",[6.343352389333335, 45.40449127866667]],
    [ "Enedis",[6.347696599999999, 45.40957905]],
    [ "Enedis",[6.346349754418273, 45.40696485930619]],
    [ "Enedis",[6.69480666015084, 45.515153614690625]],
    [ "Enedis",[6.677294021775737, 45.511066071968706]],
    [ "Enedis",[6.659301973252131, 45.55243104414418]],
    [ "Enedis",[2.9110416999999993, 50.51416849999999]],
    [ "Enedis",[1.8956212499999998, 46.25688949999999]],
    [ "Enedis",[6.75394325, 45.550109750000004]],
    [ "Enedis",[6.7658470282519145, 45.54684149146558]],
    [ "Enedis",[6.765180877192983, 45.54769164912281]],
    [ "Enedis",[6.764185350511246, 45.54872564907975]],
    [ "Enedis",[6.754781074233129, 45.547691248057255]],
    [ "SALLE POLYVALENTE",[6.7570229045031995, 45.54386349764578]],
    [ "Enedis",[4.822176137439105, 46.628998229701075]],
    [ "Enedis",[2.9080505000000008, 50.51647250000001]],
    [ "185081478",[8.08792705, 48.89546944999999]],
    [ "Enedis",[5.93899975, 45.79004375]],
    [ "Enedis",[5.9414160996455, 45.7813793613576]],
    [ "Enedis",[5.939254576551157, 45.76955501539614]],
    [ "Enedis",[5.950494003960526, 45.78525326158261]],
    [ "Enedis",[1.8257004787603852, 43.52506547476736]],
    [ "Enedis",[1.8325128321668729, 43.52547571332509]],
    [ "Enedis",[6.404524140287235, 47.03978252918212]],
    [ "Enedis",[5.499649408733245, 46.11976503196395]],
    [ "Poste électrique de Chinon",[0.2436787998744827, 47.171930031590236]],
    [ "Enedis",[0.553476632618845, 48.51105094610352]],
    [ "Enedis",[6.231538500000001, 45.291971]],
    [ "RTE",[0.312188731847255, 43.75419568108754]],
    [ "Enedis",[6.3246579999999994, 45.74873300000001]],
    [ "SAFHER",[6.214779481198116, 45.03614222914748]],
    [ "Enedis",[6.975665675264549, 45.44478856735009]],
    [ "Enedis",[6.971629199999999, 45.45497245]],
    [ "Enedis",[6.981068802946594, 45.44900185451197]],
    [ "Enedis",[6.974099739264751, 45.45177818906395]],
    [ "le fornet",[7.009240906236442, 45.449371795311585]],
    [ "Vieux Village",[6.982197478775228, 45.447320978427285]],
    [ "Enedis",[5.960292000000001, 45.513012]],
    [ "Enedis",[4.341870149891382, 43.85304973883659]],
    [ "Sous-station RFF de Gazeran",[1.7697295569331661, 48.625402630100574]],
    [ "Sous-station de Plaisir",[1.9322834888327083, 48.83302153163057]],
    [ "Poste électrique d’Épernon",[1.6853303406480247, 48.591794795005164]],
    [ "Poste électrique de Rambouillet",[1.8471698489696962, 48.64393798546078]],
    [ "Enedis",[2.9090175907137286, 50.51712102137973]],
    [ "Enedis",[2.9168880687552887, 50.51711945292287]],
    [ "Enedis",[6.606664072011575, 47.04362148094635]],
    [ "Enedis",[5.459685812449691, 46.35722222511183]],
    [ "Enedis",[5.4555580231521645, 46.35357714455219]],
    [ "Enedis",[1.7120397964713303, 46.766020225582864]],
    [ "Enedis",[1.729963253443526, 46.79725467217631]],
    [ "Enedis",[1.7123777759562844, 46.780106957498475]],
    [ "Enedis",[1.7178441753394786, 46.76812825007434]],
    [ "Enedis",[1.7123177982456144, 46.768423232456136]],
    [ "Enedis",[1.7064527313432838, 46.76240346766169]],
    [ "Enedis",[1.6932595, 46.7711015]],
    [ "Enedis",[1.7197935524691357, 46.76541081591711]],
    [ "Enedis",[1.6990657450980393, 46.76983510980391]],
    [ "Enedis",[1.7093318301703164, 46.77547927104623]],
    [ "Enedis",[1.6976852941176463, 46.768574039215686]],
    [ "Enedis",[1.7042961918344526, 46.76690683389263]],
    [ "Enedis",[1.717372348170129, 46.772245287833826]],
    [ "Enedis",[1.7126053671388615, 46.77157125800584]],
    [ "Enedis",[1.6893339310344826, 46.77028763218391]],
    [ "Enedis",[1.731621337827121, 46.79464975918583]],
    [ "Enedis",[1.6919407060041407, 46.763034226560194]],
    [ "Enedis",[1.7297067821041374, 46.79545784364024]],
    [ "Enedis",[1.7109989301977804, 46.757259328075264]],
    [ "Enedis",[1.6959245000000012, 46.765946]],
    [ "Enedis",[1.7215900000000004, 46.7899165]],
    [ "Enedis",[1.7250611412867758, 46.76138590430004]],
    [ "Enedis",[1.7361991067699922, 46.79363781013885]],
    [ "Poste électrique de Saint-Amand",[2.5098008290540372, 46.71790062763809]],
    [ "Poste Électrique de Venesmes",[2.2941508554946606, 46.8584686051955]],
    [ "Enedis",[5.475241584681096, 46.28154823558339]],
    [ "Enedis",[5.492240949937266, 46.29528188749499]],
    [ "Poste électrique de Dieuze",[6.703616017987181, 48.818159285285304]],
    [ "Poste électrique de Luçon",[-1.17413727037402, 46.469865629095885]],
    [ "Enedis",[2.4740590458785467, 48.20249812811727]],
    [ "Enedis",[2.456406917367386, 48.20749064843426]],
    [ "Enedis",[2.466631818757921, 48.20090103041825]],
    [ "Enedis",[2.477787839346563, 48.21121398155943]],
    [ "Enedis",[2.4627525000000046, 48.20064399999999]],
    [ "Enedis",[2.465173772442589, 48.21970692205984]],
    [ "Enedis",[2.4766565, 48.20462700000001]],
    [ "Enedis",[3.3798865755274266, 50.24774525400844]],
    [ "Enedis",[3.3705980154656143, 50.24945728002632]],
    [ "Enedis",[3.375236328804881, 50.25167171766095]],
    [ "Enedis",[3.374420272300469, 50.24921661971831]],
    [ "Enedis",[0.8718405, 49.2985245]],
    [ "Enedis",[0.8744656197421266, 49.29773528788839]],
    [ "Enedis",[5.518801500086106, 46.2652138374501]],
    [ "Enedis",[5.519841550000001, 46.266988250000004]],
    [ "Enedis",[0.8815225000000001, 49.30019]],
    [ "Enedis",[5.565449425398481, 46.30154784372517]],
    [ "Enedis",[4.137017705645967, 44.883680821430396]],
    [ "Enedis",[2.559778670626975, 44.64383735346614]],
    [ "Poste Électrique de Saussaye",[1.9701774280981683, 47.81025691650636]],
    [ "RTE",[2.1105183744949234, 47.853367762358246]],
    [ "RTE",[2.183166003342682, 48.4331979479752]],
    [ "RTE",[2.205208908331917, 48.289526732026985]],
    [ "Enedis",[-0.2148678499999987, 43.26666235]],
    [ "Enedis",[2.8926879, 50.50519875000001]],
    [ "Crève Cœur",[5.459170549999999, 46.5763354]],
    [ "Orbagna Bourg",[5.449473553482294, 46.58069414091516]],
    [ "Vercia Bourg",[5.45725282340725, 46.59058603945045]],
    [ "Buchens 2",[1.4751231367123103, 43.55478946586833]],
    [ "Enedis",[5.56742265600315, 46.396174133497674]],
    [ "Société PFC Arinthod",[5.565638099237009, 46.402229083706814]],
    [ "Closey",[5.570920443595167, 46.395848585298914]],
    [ "ZA",[5.562109800000003, 46.40249555]],
    [ "Mairie",[5.565159208953384, 46.39286904610496]],
    [ "Enedis",[5.5694261419976865, 46.39269407640724]],
    [ "ZA Charcour",[5.565023891223743, 46.40347353348059]],
    [ "Enedis",[5.58356288552401, 46.40437120056082]],
    [ "Enedis",[2.89513897683894, 50.510896767829145]],
    [ "Enedis",[1.695916984534138, 46.80312264302779]],
    [ "RTE",[5.7829669612868, 45.842341479921465]],
    [ "Enedis",[1.7097922265552479, 46.793130972144844]],
    [ "Enedis",[1.70542732807571, 46.7935905446898]],
    [ "Enedis",[1.7011530066703722, 46.79898004168982]],
    [ "Enedis",[1.7107818728870858, 46.794775837052065]],
    [ "Enedis",[1.7135218498062017, 46.79458267189922]],
    [ "Enedis",[1.7106102213291845, 46.80140821450177]],
    [ "Enedis",[1.706880577652184, 46.80412253549982]],
    [ "Enedis",[1.7155754999999997, 46.796814999999995]],
    [ "Enedis",[1.7065969783393502, 46.807161595667864]],
    [ "Enedis",[1.7147658020833334, 46.802114350694445]],
    [ "Enedis",[1.7255836653973091, 46.7966500266565]],
    [ "Enedis",[1.719078648854962, 46.79795182103478]],
    [ "Enedis",[1.7194495544294297, 46.799668092717724]],
    [ "Enedis",[1.7210309784677016, 46.798801373393424]],
    [ "Enedis",[1.7236784999999997, 46.799226]],
    [ "Enedis",[1.7198153260051487, 46.801497408199644]],
    [ "Enedis",[1.7104930302807047, 46.80592460931015]],
    [ "Gustave Dolfus",[7.3629940000000005, 47.7515665]],
    [ "Cimetière",[7.370581049849243, 47.74712884997315]],
    [ "Rue des Vignerons",[7.373103999999999, 47.744262]],
    [ "E.D.F.-Nucléaire",[7.371978250000001, 47.74371549999999]],
    [ "Banlieue",[7.377764749999999, 47.75516025000001]],
    [ "Violettes-Gounod",[7.3777505, 47.75383374999999]],
    [ "Enedis",[7.377714, 47.74987225]],
    [ "Enedis",[1.7275840000000011, 46.79809500000001]],
    [ "Enedis",[1.727645664437012, 46.80260350501672]],
    [ "Enedis",[1.7245343458706466, 46.80700450109452]],
    [ "Enedis",[1.720676164319249, 46.80766902347418]],
    [ "Enedis",[1.7182205144656855, 46.80744133736175]],
    [ "Enedis",[1.7203153384998087, 46.80585845780711]],
    [ "Enedis",[1.7129505491923644, 46.80901576064611]],
    [ "Enedis",[1.7145502917705737, 46.807846087281796]],
    [ "Enedis",[1.7244085909878688, 46.81395220854997]],
    [ "Enedis",[1.7222904883720924, 46.81166833333333]],
    [ "Enedis",[1.7168873333333332, 46.81124315350877]],
    [ "Enedis",[1.9906920172709675, 45.78172647957964]],
    [ "Enedis",[1.718727, 46.791542666666665]],
    [ "Enedis",[1.7148848226226907, 46.79110452193131]],
    [ "Enedis",[1.4317600547656664, 46.88715730911005]],
    [ "Enedis",[1.422096899999999, 46.89565819999999]],
    [ "Enedis",[1.426590678624813, 46.88601770014947]],
    [ "Enedis",[1.4192220234352253, 46.89763421809107]],
    [ "Enedis",[1.427570414500684, 46.889916653442775]],
    [ "Enedis",[1.4185703288296168, 46.88891130306078]],
    [ "Enedis",[1.4106680699072258, 46.89325688004705]],
    [ "Enedis",[1.4218173968253964, 46.889639793650794]],
    [ "Enedis",[1.4198092268817217, 46.89320750107527]],
    [ "Enedis",[1.421769785121719, 46.88963433312934]],
    [ "Enedis",[1.930355, 45.795029]],
    [ "Enedis",[1.7111880000000002, 46.788613500000004]],
    [ "Enedis",[3.5401243182362805, 49.56032383800622]],
    [ "Enedis",[4.098082921568628, 46.031701254901954]],
    [ "Enedis",[1.6764239003601449, 46.78391967266907]],
    [ "Enedis",[1.693752477297896, 46.79157410188261]],
    [ "Enedis",[1.6954702526506316, 46.79396597646755]],
    [ "Enedis",[1.6986978880105406, 46.79634620454545]],
    [ "Enedis",[1.6951220931899642, 46.795096250896066]],
    [ "Enedis",[1.6961118227360308, 46.79009306358382]],
    [ "Enedis",[1.6971565000000013, 46.7996325]],
    [ "Enedis",[1.6864725178849147, 46.795126588906164]],
    [ "Enedis",[1.6920331943182831, 46.798750774423375]],
    [ "Enedis",[1.6896451781899944, 46.79377494322653]],
    [ "Enedis",[1.6673355590828922, 46.79053165608465]],
    [ "Enedis",[1.689248186889819, 46.80197446861925]],
    [ "Enedis",[1.6868826644474042, 46.80325419840213]],
    [ "Enedis",[1.6811929864271455, 46.8009525912974]],
    [ "Enedis",[1.6846895000000002, 46.79851]],
    [ "Enedis",[1.6786734999999984, 46.798612]],
    [ "Enedis",[1.6711287267267279, 46.79370381288982]],
    [ "RTE",[3.1728379, 49.054466]],
    [ "Enedis",[0.6473711770966366, 48.482243185043274]],
    [ "Enedis",[3.5190375, 50.31082249999999]],
    [ "Enedis",[6.747499299999999, 48.068575749999994]],
    [ "Enedis",[3.5110035000000006, 50.320144]],
    [ "Enedis",[1.1327812214663644, 49.875282352985636]],
    [ "Enedis",[1.136359, 49.888974999999995]],
    [ "Enedis",[1.1260442763173673, 49.88271446693074]],
    [ "Enedis",[1.1341633333333334, 49.87269108]],
    [ "Enedis",[1.128940159854678, 49.87980980653951]],
    [ "Enedis",[2.587017529247911, 50.50682551940576]],
    [ "Enedis",[2.58405386129754, 50.50972417797664]],
    [ "Enedis",[2.5832321662280706, 50.507531980701756]],
    [ "Enedis",[2.586099552519733, 50.51142822910344]],
    [ "Poste électrique d'Artigues",[0.2029091199917555, 42.927379350960216]],
    [ "Enedis",[2.897557944138184, 50.5161623384785]],
    [ "Enedis",[2.89692625, 50.51497279999999]],
    [ "Enedis",[2.894849383802176, 50.516378451871695]],
    [ "Enedis",[1.0451962723577237, 49.87469170731707]],
    [ "Enedis",[1.0226859566683533, 49.855754380880114]],
    [ "Enedis",[1.0477866417704012, 49.875537258644535]],
    [ "Enedis",[1.0451615209380234, 49.86434348715801]],
    [ "Enedis",[1.0533197936081242, 49.86470560065711]],
    [ "Enedis",[1.038288, 49.871707]],
    [ "Enedis",[7.516989949910554, 48.976285864281465]],
    [ "Poste électrique de Nemours",[2.687918675029257, 48.235111157178245]],
    [ "Enedis",[1.0972200697674415, 49.886806713178295]],
    [ "Enedis",[1.1123798528121263, 49.90536579204894]],
    [ "Enedis",[1.0994639012756517, 49.883903846367176]],
    [ "Enedis",[1.0842303252032524, 49.910009666666674]],
    [ "Enedis",[1.0969422899126293, 49.905810916335724]],
    [ "Enedis",[1.1068905475826971, 49.90416986564887]],
    [ "Poste électrique de Tousson",[2.4447140662423896, 48.35735535269472]],
    [ "Enedis",[1.1393976097627492, 49.90227330151805]],
    [ "Enedis",[1.111057333333334, 49.907456]],
    [ "SNCF Réseau",[2.4440518403142173, 48.895827762416104]],
    [ "Enedis",[0.5926133461445493, 49.08858263475949]],
    [ "Enedis",[0.5990232142857136, 49.09067136054422]],
    [ "Enedis",[0.5892671990375217, 49.08902953710805]],
    [ "Enedis",[0.586087499999999, 49.088190000000004]],
    [ "Enedis",[0.5913717181889149, 49.0878615409836]],
    [ "Enedis",[0.5960944391955497, 49.08651504655541]],
    [ "Enedis",[0.6019034999999999, 49.096706000000005]],
    [ "Enedis",[0.6022413258370815, 49.09108700649675]],
    [ "Enedis",[0.5962512517914499, 49.09061585001235]],
    [ "Enedis",[0.5938881741076071, 49.08935547424291]],
    [ "Enedis",[0.5986166479820633, 49.10126032810164]],
    [ "Enedis",[0.5872663008130081, 49.08492705110338]],
    [ "Enedis",[0.6023275208333334, 49.09933029166666]],
    [ "Enedis",[0.5925022579970105, 49.07967695336323]],
    [ "Enedis",[0.590383547232408, 49.09935659944124]],
    [ "Enedis",[0.6029197798306389, 49.08908898280729]],
    [ "Enedis",[0.6073884449905793, 49.095608828459085]],
    [ "Enedis",[0.5874761259887009, 49.08745978135593]],
    [ "Enedis",[0.5957290000000001, 49.085465]],
    [ "Enedis",[0.6018830000000001, 49.08770500000001]],
    [ "Enedis",[0.5935454621553882, 49.090395182456135]],
    [ "Enedis",[0.5832845028248584, 49.08698324858757]],
    [ "Enedis",[0.5932737434787839, 49.0835403416276]],
    [ "Enedis",[0.5887850760784312, 49.084225647372556]],
    [ "Enedis",[0.5983711576376168, 49.095331817150836]],
    [ "Enedis",[0.5807339693639693, 49.072988316017316]],
    [ "Enedis",[0.5874940787671228, 49.09998532001522]],
    [ "Enedis",[0.6049578546181631, 49.09154142066564]],
    [ "Enedis",[0.649176372046684, 49.08752275491034]],
    [ "Enedis",[0.5899136654962546, 49.086806474836145]],
    [ "Enedis",[0.5981346227307399, 49.09132158993135]],
    [ "Enedis",[0.5968543030224301, 49.089219449120975]],
    [ "Enedis",[0.5925248408628412, 49.09415321164021]],
    [ "Enedis",[0.5901795000000011, 49.07742799999999]],
    [ "Enedis",[0.6092321316370397, 49.09377853491343]],
    [ "Enedis",[0.5841176234618543, 49.074178850423856]],
    [ "Enedis",[2.8962110390827265, 50.51928459863901]],
    [ "Enedis",[2.89272735, 50.51881075]],
    [ "Enedis",[4.837913956995428, 45.69647310213235]],
    [ "RTE",[3.745384698675329, 48.717897667062054]],
    [ "Enedis",[1.714689828322934, 46.81631833371148]],
    [ "Enedis",[5.378830432153373, 45.704952898524326]],
    [ "Enedis",[-0.7351891567304095, 43.463168765654295]],
    [ "Enedis",[-0.754732913079019, 43.46947865967302]],
    [ "Enedis",[1.7179613813107557, 46.823096585449996]],
    [ "Enedis",[1.718853487179487, 46.82139824358975]],
    [ "Enedis",[1.7140725891472872, 46.82136186046512]],
    [ "Enedis",[3.51102988062708, 50.32951491942546]],
    [ "Enedis",[3.5105999040139606, 50.32863730424664]],
    [ "Enedis",[3.5111749891830346, 50.333169749786514]],
    [ "Enedis",[3.516109366033755, 50.33398038350474]],
    [ "Enedis",[1.7118751538904897, 46.817764531604226]],
    [ "Enedis",[1.7143987404727448, 46.819673988261776]],
    [ "Enedis",[3.5171464999999995, 50.33255]],
    [ "Enedis",[3.5195552009640343, 50.33414096106785]],
    [ "Enedis",[3.5198193614782185, 50.332177378977]],
    [ "Enedis",[3.514440925518293, 50.3328888687142]],
    [ "Enedis",[1.7081151639652339, 46.819349261216814]],
    [ "Enedis",[1.7063206226734349, 46.8163156463621]],
    [ "Enedis",[3.5237742275264226, 50.32655854898468]],
    [ "Enedis",[3.5284717361394926, 50.330718994551155]],
    [ "Enedis",[3.5219270000000007, 50.334387500000005]],
    [ "Enedis",[3.5201410000000015, 50.334849]],
    [ "Enedis",[3.5319097645459494, 50.33015566884177]],
    [ "Enedis",[1.6988793847429522, 46.81571573466003]],
    [ "Enedis",[1.7036623807007247, 46.81766966216482]],
    [ "Enedis",[1.6995063225371132, 46.819576510121465]],
    [ "Enedis",[1.704108858585859, 46.81439242424243]],
    [ "Enedis",[5.477468919439097, 46.60092175780656]],
    [ "Enedis",[5.432107507662278, 46.6122647041686]],
    [ "Poste électrique de Locmalo",[-3.154535950214839, 48.062185214263046]],
    [ "Poste électrique de Plusquellec",[-3.4480549709402384, 48.39493664480156]],
    [ "Enedis",[3.01215426772654, 50.51739209787062]],
    [ "Enedis",[7.185725168445512, 48.941159034013836]],
    [ "Enedis",[7.187823981808573, 48.94191748708806]],
    [ "Enedis",[2.813947574585635, 47.67481681583794]],
    [ "Enedis",[2.806558450347881, 47.67598063757116]],
    [ "Enedis",[2.8045169999999993, 47.67277549999999]],
    [ "Enedis",[2.809362, 47.67161649999999]],
    [ "Poste Électrique de Verdun",[5.401248931083089, 49.149537719906874]],
    [ "Poste électrique de Dugny",[5.394162283884494, 49.1219265928807]],
    [ "Enedis",[7.2326891500000015, 48.40985014999999]],
    [ "Enedis",[3.0195441, 50.51556970000001]],
    [ "Enedis",[2.881578515006822, 47.74543479213279]],
    [ "Enedis",[2.89272515720524, 47.7401585165315]],
    [ "Enedis",[3.5634004999999997, 43.583847799999994]],
    [ "Enedis",[3.5613973647520405, 43.5791133268461]],
    [ "Enedis",[3.5618119913875597, 43.58202086491227]],
    [ "Enedis",[4.7122649999999995, 50.0093335]],
    [ "Enedis",[4.724728028985508, 50.010947243156195]],
    [ "Enedis",[4.699011015594543, 50.00200551267057]],
    [ "Enedis",[4.70378675, 50.00162274999999]],
    [ "Enedis",[4.710638829691294, 50.00211261949359]],
    [ "Enedis",[4.718420125244618, 50.0098987508154]],
    [ "Enedis",[7.1880631199430045, 48.936431803002314]],
    [ "Enedis",[3.020160033333334, 50.51919865833333]],
    [ "RTE",[4.896025942413922, 49.08248412724519]],
    [ "Enedis",[0.784355577412219, 47.39588646261039]],
    [ "Sous-station RFF de Courville",[1.2368809505209533, 48.45207020088476]],
    [ "Poste de Bourgueil",[0.16978393558987576, 47.27294648706916]],
    [ "Enedis",[6.309287503519947, 48.085695332104144]],
    [ "Enedis",[6.5413619999999995, 45.7582155]],
    [ "Enedis",[6.542079700000001, 45.75710960000001]],
    [ "Enedis",[6.542999649999999, 45.75572785000001]],
    [ "Enedis",[6.5376313, 45.756793800000004]],
    [ "Enedis",[6.544385882603107, 45.75740535127409]],
    [ "Enedis",[6.546912321599504, 45.74999670711748]],
    [ "Poste électrique de Thimert",[1.260192236155509, 48.57158998606798]],
    [ "Enedis",[3.014076874648621, 50.52088616464226]],
    [ "Enedis",[1.9461140000000015, 49.962633000000004]],
    [ "Enedis",[1.935562329907698, 49.96556347987439]],
    [ "Enedis",[1.9429768821590443, 49.96062676308199]],
    [ "Enedis",[1.9436039424572318, 49.96635817988595]],
    [ "Enedis",[1.9275250271900763, 49.96853911462316]],
    [ "Enedis",[1.940865086666666, 49.97050273393939]],
    [ "Enedis",[1.9402587414141406, 49.96421843059162]],
    [ "Enedis",[1.9482299928798952, 49.96459070058087]],
    [ "Enedis",[1.9489353893604975, 49.96313068336163]],
    [ "Enedis",[1.9403768369987073, 49.96165185985338]],
    [ "Enedis",[1.6964541305993692, 46.819899236803366]],
    [ "Enedis",[1.6836664544626587, 46.82857331602914]],
    [ "Enedis",[1.6851591242937851, 46.82145375141243]],
    [ "Enedis",[1.6832389438293278, 46.82764408416599]],
    [ "Enedis",[1.6820377715846997, 46.82675045483066]],
    [ "Enedis",[1.686565336088154, 46.82523978567493]],
    [ "Enedis",[1.6895748916666686, 46.81963833333333]],
    [ "Enedis",[2.0465398184380628, 46.215120930648865]],
    [ "Enedis",[1.9992542232231598, 46.206159608923535]],
    [ "Enedis",[1.9483897751125798, 46.18868890862953]],
    [ "Enedis",[1.9958905, 46.208517750000006]],
    [ "Enedis",[1.9939264500000005, 46.206652600000005]],
    [ "Enedis",[1.6748794537037035, 46.824119152777776]],
    [ "Enedis",[1.6808699947565533, 46.8233966659176]],
    [ "Enedis",[1.6740734999999987, 46.820793499999986]],
    [ "Enedis",[1.6839500277887012, 46.81914119884651]],
    [ "Enedis",[6.1620500749046325, 45.672835841126215]],
    [ "Enedis",[1.6766207433431295, 46.81121257850209]],
    [ "Enedis",[1.673364183646323, 46.81345732538637]],
    [ "Enedis",[1.6746092590632347, 46.810825791439484]],
    [ "Enedis",[1.7719150650887578, 47.29360388998466]],
    [ "Enedis",[1.7553851568655086, 47.29576192155719]],
    [ "Enedis",[1.7565107151033386, 47.29288903895072]],
    [ "Enedis",[1.7613481594996097, 47.3177537557988]],
    [ "Enedis",[1.712332229540918, 47.29367096663816]],
    [ "Enedis",[1.6867578707182316, 46.810593835727445]],
    [ "Enedis",[1.6811044999999998, 46.810161]],
    [ "Enedis",[1.6602543659660238, 46.80282023485939]],
    [ "Enedis",[1.6551089049764705, 46.804104290077106]],
    [ "Enedis",[1.672969436505696, 46.79994782707333]],
    [ "Enedis",[3.022756233309695, 50.5131134997778]],
    [ "Enedis",[3.0233772500000007, 50.514739000000006]],
    [ "Enedis",[5.623986959555748, 46.21770946777729]],
    [ "Poste électrique de Saint-Nicolas",[-3.1342271999999998, 48.31198435000001]],
    [ "RTE",[-2.092941069730318, 47.129864018320376]],
    [ "Enedis",[1.6903130102810147, 46.81328317614803]],
    [ "Enedis",[1.6668930134704008, 46.803451651542005]],
    [ "Enedis",[1.671332155109489, 46.80544602798054]],
    [ "Enedis",[1.6831952402047652, 46.804285630832844]],
    [ "Enedis",[1.6862234556231481, 46.80515847558883]],
    [ "Enedis",[1.6822125210492924, 46.803880705168886]],
    [ "Enedis",[1.6814196614090429, 46.807147662460565]],
    [ "Enedis",[1.6880944305138976, 46.80803268486302]],
    [ "Enedis",[1.684176385098039, 46.80808273607844]],
    [ "Enedis",[1.6913027725085912, 46.804456625429566]],
    [ "Enedis",[5.677455315575872, 46.327644198048624]],
    [ "Enedis",[5.675223499999999, 46.3277275]],
    [ "Enedis",[5.685431750000001, 46.33311750000001]],
    [ "Enedis",[5.680950257342146, 46.330385259913974]],
    [ "Enedis",[5.673546650464595, 46.325469533518806]],
    [ "Enedis",[1.7046302662819952, 46.80966848543361]],
    [ "Enedis",[5.7298360000000015, 46.30427924999999]],
    [ "Enedis",[5.744153433540747, 46.30193800109929]],
    [ "Enedis",[5.741128992506647, 46.293584664732904]],
    [ "Enedis",[5.736556, 46.297211749999995]],
    [ "Enedis",[5.725898, 46.289050499999995]],
    [ "Enedis",[5.737992899999998, 46.2994453]],
    [ "Enedis",[5.7320743499999995, 46.2990867]],
    [ "Enedis",[5.7397165, 46.300275000000006]],
    [ "Enedis",[1.6956775157726414, 46.808196736735]],
    [ "Enedis",[1.6950635, 46.80877550000001]],
    [ "Enedis",[1.6931944711473197, 46.80857124983028]],
    [ "Enedis",[5.767156320372526, 46.30474986550252]],
    [ "Enedis",[5.782773499999998, 46.32779084999999]],
    [ "Enedis",[5.815328695255863, 46.29184864349691]],
    [ "Enedis",[4.429748087934561, 48.90753173824131]],
    [ "Enedis",[4.43041845, 48.90423894999999]],
    [ "Enedis",[4.425219750000002, 48.91070425]],
    [ "Enedis",[4.42712375, 48.90921650000001]],
    [ "Enedis",[2.848583, 42.52988233333333]],
    [ "Enedis",[2.879906, 42.518195999999996]],
    [ "Enedis",[2.8683569999999996, 42.5360745]],
    [ "Enedis",[-0.2002305800224465, 49.28440468372616]],
    [ "Enedis",[-0.20842662007668147, 49.285160725572204]],
    [ "Enedis",[-0.201862, 49.283438000000004]],
    [ "Enedis",[-0.19303184877276355, 49.28263407891264]],
    [ "Enedis",[-0.19768644619338271, 49.28143082449941]],
    [ "Enedis",[-0.2024533896583567, 49.28268069252077]],
    [ "Enedis",[-0.20058449999999997, 49.2813415]],
    [ "Enedis",[-0.2041702145481261, 49.279703376520544]],
    [ "Enedis",[-0.19971364609053496, 49.27907704938272]],
    [ "Enedis",[-0.19256974806393168, 49.28197534832757]],
    [ "Enedis",[-0.1994378468974119, 49.28049357686312]],
    [ "Enedis",[-0.20403399999999997, 49.277401000000005]],
    [ "Enedis",[-0.19917143178842606, 49.28509886514796]],
    [ "Enedis",[-0.20305793939394046, 49.28476588744589]],
    [ "Enedis",[-0.20794385929648276, 49.28130314991624]],
    [ "Poste électrique de Sausset",[2.575599591423091, 48.97613854529807]],
    [ "RTE",[0.40926585060998655, 47.70086582158093]],
    [ "RTE",[-1.382798820668127, 48.038113947206845]],
    [ "Enedis",[3.044087672120201, 50.51082672871453]],
    [ "Enedis",[3.0119814368185596, 50.51019062869437]],
    [ "Enedis",[3.0159679258785936, 50.51234696119986]],
    [ "Enedis",[3.014921159965452, 50.509854330618914]],
    [ "RTE",[-1.23914912115661, 48.12814736872575]],
    [ "Enedis",[2.208000842424242, 49.19526746060606]],
    [ "Enedis",[2.219787248608535, 49.201180841475974]],
    [ "Enedis",[2.2054863431479474, 49.198644404216644]],
    [ "Jardins des Albères",[2.9278807435193657, 42.57831289569991]],
    [ "Enedis",[2.923820042735043, 42.58211656410256]],
    [ "Enedis",[2.9279145000000004, 42.5817295]],
    [ "Enedis",[2.924798838944674, 42.57637451033668]],
    [ "Enedis",[2.9194137623386487, 42.58129454517844]],
    [ "Enedis",[2.933287614709112, 42.577962694474934]],
    [ "Enedis",[2.9243318096495097, 42.5730065669453]],
    [ "Enedis",[2.9218782401511363, 42.588428122464855]],
    [ "Enedis",[2.9256909158805033, 42.58270565959119]],
    [ "Enedis",[2.9237203448940265, 42.578396256262046]],
    [ "Enedis",[2.9022663263443995, 42.584267469423416]],
    [ "Ortaffa",[2.9260531548590234, 42.57878646615142]],
    [ "Enedis",[3.7256131291538197, 49.18244183556012]],
    [ "Enedis",[1.8467667188504824, 46.18270600407037]],
    [ "Enedis",[6.043020749999999, 47.38411165000001]],
    [ "Enedis",[3.85972570701513, 49.19571345208621]],
    [ "Enedis",[4.065010406846846, 49.34901722486486]],
    [ "Enedis",[4.072047850393701, 49.35465654593175]],
    [ "Enedis",[4.0704155, 49.3473105]],
    [ "Enedis",[4.068640949138294, 49.350185274485085]],
    [ "gare",[4.07336765, 49.35097665]],
    [ "Enedis",[4.072947999999999, 49.3490355]],
    [ "188415240",[1.4260857999999998, 48.74394315000001]],
    [ "188415295",[1.437795940879825, 48.75118373380348]],
    [ "188415400",[1.4258116441223838, 48.74518049699898]],
    [ "Enedis",[1.4051487189231724, 48.76905115535114]],
    [ "SICAE-ELY",[1.4437288929227945, 48.756273734175856]],
    [ "SICAE-ELY",[1.4258693661203543, 48.74285605303424]],
    [ "SICAE-ELY",[1.448289194292368, 48.75124817154357]],
    [ "188415785",[1.4413088499999998, 48.75795180000001]],
    [ "SICAE-ELY",[1.4080134656856549, 48.76778585140556]],
    [ "188415900",[1.4274146306174922, 48.74820044282789]],
    [ "188415907",[1.4208550500000001, 48.74844085]],
    [ "Enedis",[5.84537685, 46.28218369999999]],
    [ "Enedis",[1.699295532588454, 46.81032784729981]],
    [ "Enedis",[1.6967352223482706, 46.81225051730006]],
    [ "Enedis",[2.578384704944179, 44.345167951089856]],
    [ "Enedis",[2.571166172822617, 44.339182369813045]],
    [ "Enedis",[2.5776183140332516, 44.34271064283224]],
    [ "Enedis",[2.5837876695839093, 44.34336399585444]],
    [ "Enedis",[3.742507646239555, 49.208002911792015]],
    [ "Enedis",[6.2205255, 47.600568499999994]],
    [ "Enedis",[6.216397978733241, 47.602224331484045]],
    [ "Enedis",[2.9356625, 50.52133225]],
    [ "Enedis",[2.9326950000000003, 50.519468999999994]],
    [ "Enedis",[2.937557170610211, 50.51861851017019]],
    [ "Enedis",[2.5523498115942025, 44.33793990338165]],
    [ "Enedis",[2.55053485, 44.33742315]],
    [ "Enedis",[2.5551578063743103, 44.339681844476395]],
    [ "Enedis",[2.5506655000000005, 44.3458605]],
    [ "Enedis",[2.5581292777987183, 44.34340746902877]],
    [ "Enedis",[2.552925862204724, 44.34318884251968]],
    [ "Enedis",[2.5548940065662418, 44.34157285090769]],
    [ "Enedis",[2.5590442259391577, 44.340602848435644]],
    [ "Enedis",[2.552817000000001, 44.347066]],
    [ "Enedis",[2.5548565399665955, 44.34476565998568]],
    [ "Enedis",[2.326023000000002, 48.545021500000004]],
    [ "Enedis",[-0.8909313282051279, 47.620061492307684]],
    [ "Enedis",[5.62871835390379, 45.367452394309915]],
    [ "Le Paris",[5.6122980398406375, 45.383036180610894]],
    [ "Poste électrique de Morlaix",[-3.8411674526964634, 48.58910146209655]],
    [ "Enedis",[2.92758925, 50.52029950000001]],
    [ "Enedis",[2.9315550000000017, 50.53538015000001]],
    [ "Enedis",[1.1855169686350737, 45.05891620480341]],
    [ "Enedis",[2.5444965494809275, 44.284371820004075]],
    [ "Enedis",[2.5118350798265485, 44.299867710580195]],
    [ "Enedis",[2.5177179002720482, 44.30392589993277]],
    [ "Enedis",[2.532249108817076, 44.314163717630535]],
    [ "Enedis",[2.559756315045719, 44.32210306400665]],
    [ "Enedis",[2.5572865222915984, 44.29587975988608]],
    [ "Enedis",[2.5559159588289115, 44.29668567276608]],
    [ "Enedis",[2.558539999896513, 44.28933801790335]],
    [ "Enedis",[2.5597222826510717, 44.297602860948665]],
    [ "Enedis",[2.5628206542108196, 44.29523840955568]],
    [ "Poste électrique de Cissac",[-0.8434906406839192, 45.218206316773355]],
    [ "Médoquine",[-0.5987170396772035, 44.81715932043456]],
    [ "BLANQUEFORT (S.N.C.F.)",[-0.6168985164333483, 44.90231926986]],
    [ "Enedis",[3.492287504938272, 47.28060499458891]],
    [ "Enedis",[2.9383299999999997, 50.523452]],
    [ "Enedis",[2.9322474999999995, 50.525506500000006]],
    [ "Enedis",[2.9359775, 50.52616525]],
    [ "Enedis",[2.934329250000001, 50.527961]],
    [ "Bel Air",[0.46545525594790854, 48.27408076759329]],
    [ "Forbonnais",[0.45589531279620904, 48.27379615797789]],
    [ "École",[0.4625302112676052, 48.27454884507042]],
    [ "Saint-Damien",[0.4598634703030301, 48.27426431515151]],
    [ "Enedis",[1.7718083264699156, 46.85931023015329]],
    [ "Enedis",[7.096302211384928, 48.511634729913304]],
    [ "Enedis",[2.417621249999999, 50.54851165]],
    [ "Enedis",[2.477863219294606, 50.552850762364166]],
    [ "Enedis",[2.4839246648073665, 50.55939945175]],
    [ "Enedis",[2.478343332842072, 50.560482109602425]],
    [ "Enedis",[2.487257480153853, 50.56198569825953]],
    [ "Enedis",[2.485292267843005, 50.56226965417501]],
    [ "Enedis",[2.482996256259605, 50.5634414076511]],
    [ "Enedis",[2.472245894849318, 50.556464488856555]],
    [ "Enedis",[2.473257481210873, 50.566344402766]],
    [ "Enedis",[2.4951451368814435, 50.55380877926975]],
    [ "Enedis",[2.4875064384478223, 50.560159878912586]],
    [ "Enedis",[2.478681174124515, 50.55742325869314]],
    [ "Enedis",[2.4639520806932893, 50.56653366546722]],
    [ "Enedis",[2.4728026050743663, 50.566565133333334]],
    [ "Enedis",[2.479769455811644, 50.5680902550238]],
    [ "Enedis",[2.475012634024246, 50.560761343053024]],
    [ "Enedis",[2.4801184724726077, 50.5629501859668]],
    [ "Enedis",[2.4750117140043097, 50.56605406802774]],
    [ "Enedis",[2.483635217048932, 50.56481787451506]],
    [ "Enedis",[2.4813164603919367, 50.56492057092213]],
    [ "Enedis",[2.476661391317549, 50.568007203832785]],
    [ "Enedis",[2.486394045570385, 50.56627584651344]],
    [ "Enedis",[7.199854310575636, 48.863377462362266]],
    [ "Enedis",[7.199675693746923, 48.86478955686853]],
    [ "Enedis",[2.4448628335433042, 50.56056374648702]],
    [ "Enedis",[2.409353298076972, 44.248087299999995]],
    [ "Enedis",[2.454214285563056, 50.59832372678413]],
    [ "Enedis",[2.3500435983267836, 50.525753340494]],
    [ "Enedis",[3.8880841599861182, 49.37254053531148]],
    [ "Enedis",[3.9040193355667228, 49.37157150307091]],
    [ "Enedis",[3.8785028981481484, 49.36788669907408]],
    [ "Enedis",[2.5663398974834197, 44.18374482396407]],
    [ "Enedis",[4.942008231892617, 46.115028110713325]],
    [ "Enedis",[4.9404875110843225, 46.11159941871359]],
    [ "Enedis",[4.963236172002743, 46.11289289725633]],
    [ "Enedis",[4.960608597917988, 46.118056045490135]],
    [ "Enedis",[4.95190975, 46.120326199999994]],
    [ "Enedis",[4.947575241023874, 46.11968893299518]],
    [ "Enedis",[3.9145169999999996, 49.3377635]],
    [ "Enedis",[3.9093095000000004, 49.33902300000001]],
    [ "Enedis",[3.886881708688783, 49.325931218641394]],
    [ "Enedis",[3.9112255000000005, 49.336606]],
    [ "Enedis",[3.91285975, 49.335118]],
    [ "Enedis",[3.9028285000000005, 49.335827]],
    [ "Sous-station SNCF de Saint-Avé",[-2.7338556940177794, 47.67191258248829]],
    [ "Enedis",[4.955688032355419, 47.15493733307384]],
    [ "Enedis",[3.763438, 49.211099000000004]],
    [ "Enedis",[3.760389927272727, 49.21052672727272]],
    [ "Enedis",[2.9413639999999988, 50.529265]],
    [ "Enedis",[2.9450815, 50.52713275]],
    [ "Enedis",[2.224650679339505, 46.35257979725738]],
    [ "Enedis",[2.21615685, 46.352560149999995]],
    [ "Enedis",[2.21393035, 46.3474288]],
    [ "Enedis",[2.2156041500000003, 46.346676200000005]],
    [ "Enedis",[2.2160934514965733, 46.35259301665724]],
    [ "Enedis",[2.2226377499999996, 46.349597599999996]],
    [ "Enedis",[4.588082, 48.985699000000004]],
    [ "Enedis",[6.261759108366219, 46.20328102775586]],
    [ "RTE",[-0.39455034293837066, 45.294628092892715]],
    [ "Enedis",[1.4252532314425297, 48.74105569584364]],
    [ "Enedis",[1.420370888551313, 48.73706634186556]],
    [ "Enedis",[1.3939044999999999, 48.728668]],
    [ "Enedis",[1.4108293459232621, 48.73605203717027]],
    [ "Enedis",[1.4191891578794484, 48.73228701161946]],
    [ "Poste électrique de Pomerol",[-0.23109887328390452, 44.93834369382405]],
    [ "Enedis",[5.7479304, 46.32695225]],
    [ "Enedis",[5.745154600000001, 46.32258939999999]],
    [ "Enedis",[3.8975534999999994, 48.199757000000005]],
    [ "Enedis",[5.193890953817438, 46.16959474142582]],
    [ "Enedis",[5.205823237393531, 46.182894679673666]],
    [ "Enedis",[5.20835932936721, 46.17652295839035]],
    [ "Enedis",[5.213225550000001, 46.1915051]],
    [ "Enedis",[5.208979168877585, 46.187563512157844]],
    [ "Enedis",[5.209903074862829, 46.19066278788145]],
    [ "Enedis",[5.197715693701383, 46.16746092740964]],
    [ "Enedis",[5.1953608489526015, 46.16580319971026]],
    [ "Enedis",[5.204923066568802, 46.17207877371815]],
    [ "Enedis",[5.20006325028631, 46.1762089582405]],
    [ "Enedis",[5.2320540328198515, 46.178953642297124]],
    [ "Enedis",[5.202970906955706, 46.17425965126102]],
    [ "Enedis",[5.205813385031149, 46.18710012312093]],
    [ "Enedis",[5.2089623159923075, 46.18177556523411]],
    [ "Enedis",[5.22622375, 46.1811695]],
    [ "Enedis",[5.1992180999999995, 46.1717227]],
    [ "Enedis",[5.206954300983553, 46.18957158717766]],
    [ "Enedis",[5.194924818199834, 46.172199890734284]],
    [ "Enedis",[5.206921570794841, 46.18061105316521]],
    [ "Enedis",[5.203887994959349, 46.18083629798374]],
    [ "Enedis",[5.1957278512067395, 46.17641840184003]],
    [ "Enedis",[2.9312591077535126, 50.52978560453699]],
    [ "Enedis",[2.9398089997673504, 50.531406501797456]],
    [ "Poste électrique de la Gupie",[0.14164285486560738, 44.55054197880309]],
    [ "Enedis",[5.896943950847351, 46.3453212021712]],
    [ "Réole (La)",[-0.03211466822900647, 44.5926828915882]],
    [ "Polygone",[5.863180499999998, 48.680305999999995]],
    [ "Poste électrique de Marmande",[0.16870241555704954, 44.51381975655036]],
    [ "Enedis",[7.0900193389830495, 49.067046231638415]],
    [ "Enedis",[7.095420251282051, 49.06280955384615]],
    [ "Enedis",[-1.8689105039054876, 49.52511451189221]],
    [ "Enedis",[-1.7064784999999998, 47.3815985]],
    [ "Enedis",[6.466907462086513, 45.54106675419847]],
    [ "Enedis",[6.466848538435023, 45.542390687004485]],
    [ "Enedis",[6.504206200323001, 45.55115923437687]],
    [ "Enedis",[6.469832213433987, 45.50249724995441]],
    [ "Enedis",[6.494555291689092, 45.53969435113812]],
    [ "Enedis",[6.522093023686693, 45.55853325481046]],
    [ "Enedis",[0.6285419999999999, 47.99276125]],
    [ "Enedis",[6.215407249999999, 47.61110010833333]],
    [ "Enedis",[2.9287485000000006, 50.53119300000001]],
    [ "Enedis",[5.909762993500227, 45.76262779863087]],
    [ "Enedis",[5.931588259401291, 45.77431389217784]],
    [ "Enedis",[3.99362905, 49.22601874999999]],
    [ "Enedis",[4.004548184523809, 49.22527460119047]],
    [ "Enedis",[4.001891750000001, 49.2162265]],
    [ "Enedis",[3.98291825, 49.22339649999999]],
    [ "Bergerie",[3.9970202500000003, 49.22451]],
    [ "Enedis",[3.9868502500000016, 49.22640925]],
    [ "Enedis",[3.9839029999999993, 49.2258985]],
    [ "Enedis",[3.9881417723305317, 49.2254070949051]],
    [ "Enedis",[3.9239883836568774, 48.22226653709971]],
    [ "Enedis",[3.8622514999999997, 49.226558]],
    [ "Enedis",[-1.5088815300046874, 43.53721779659836]],
    [ "Enedis",[3.4343572019030635, 48.15688314540589]],
    [ "Enedis",[2.928253382193315, 50.53399959660093]],
    [ "Enedis",[3.8227334455327986, 49.241143561590974]],
    [ "Enedis",[2.4415250430276823, 44.22798305502449]],
    [ "Poste Électrique du Vexin",[1.9075580652123372, 49.14836034859381]],
    [ "Beg Ar Roz",[-3.5378181642828017, 47.88824467558749]],
    [ "Keriquel",[-3.51007995, 47.89203145]],
    [ "Enedis",[2.4872828486536673, 44.278087719591454]],
    [ "Enedis",[3.264805919427813, 50.53908629500997]],
    [ "Enedis",[2.9240621499595125, 50.53612128334769]],
    [ "Enedis",[7.008479837850689, 47.65947199286733]],
    [ "Enedis",[7.0012065, 47.6647595]],
    [ "Enedis",[7.015382000000001, 47.6591475]],
    [ "Enedis",[7.005182981509444, 47.65573766362647]],
    [ "Enedis",[6.993639263269639, 47.653571550601555]],
    [ "Enedis",[7.008581625329813, 47.65455565787158]],
    [ "Enedis",[7.0011089, 47.663321700000004]],
    [ "Enedis",[6.9972785, 47.65736329999999]],
    [ "Enedis",[3.7804868977591033, 49.9871767745098]],
    [ "Enedis",[3.72456813130352, 49.992224880748495]],
    [ "Enedis",[3.750327250000001, 49.983903000000005]],
    [ "Enedis",[3.7431398662551443, 49.98615865329218]],
    [ "Enedis",[4.1479892, 43.9359923]],
    [ "Enedis",[3.3626399999999994, 49.399483]],
    [ "Enedis",[3.365138041412911, 49.40588517458383]],
    [ "Enedis",[3.3377430000000006, 49.395951999999994]],
    [ "Enedis",[3.33318938027845, 49.39889080301461]],
    [ "Poste électrique du Lazaret",[-0.7577613298059022, 45.22998308364786]],
    [ "ST-VIVIEN",[-1.0324676550126128, 45.42080675122487]],
    [ "Enedis",[3.7809153825136614, 50.00551089617487]],
    [ "Enedis",[3.7928329544379977, 50.02293984569096]],
    [ "Enedis",[3.786551333236237, 50.01626550228178]],
    [ "Enedis",[3.7834801030303025, 50.018550781818185]],
    [ "Enedis",[3.783216, 50.010495000000006]],
    [ "Enedis",[3.7842243261021684, 50.016252527058526]],
    [ "Enedis",[3.7815197490196075, 50.01341244705883]],
    [ "SER",[7.492206146524311, 48.87585861326536]],
    [ "Poste électrique Cellulose (Facture)",[-0.9728029651851445, 44.63359518225441]],
    [ "Enedis",[3.0671645677585095, 50.708910057096986]],
    [ "Enedis",[2.565753646813646, 44.239751198306706]],
    [ "Enedis",[4.149508437005039, 44.17986573506119]],
    [ "Enedis",[3.7102266999999993, 48.284260249999996]],
    [ "Enedis",[3.9423644791327903, 43.64358754460704]],
    [ "Enedis",[4.035739164169165, 48.27757368364968]],
    [ "Enedis",[2.692112311602947, 44.230480744474114]],
    [ "Enedis",[2.725053081148564, 44.28665378526841]],
    [ "Enedis",[2.721841918181818, 44.28594920909091]],
    [ "Enedis",[3.94056095, 43.645417099999996]],
    [ "Enedis",[-0.531762994969021, 44.7557865093043]],
    [ "Enedis",[-0.5317105073796055, 44.75578899890631]],
    [ "Enedis",[6.935138376068376, 47.660178752136744]],
    [ "Enedis",[6.92908740626461, 47.66046874567556]],
    [ "Enedis",[6.932142000000002, 47.659606499999995]],
    [ "Enedis",[-2.743680974272006, 47.749177062840914]],
    [ "Enedis",[6.944233475592687, 47.66992360533509]],
    [ "Enedis",[6.936763641509433, 47.66912413207547]],
    [ "Enedis",[6.934973686308492, 47.66996846331599]],
    [ "Enedis",[6.894842000000001, 47.586614]],
    [ "Enedis",[6.909956899999999, 47.5930647]],
    [ "Enedis",[6.898065699660736, 47.59594819980808]],
    [ "Enedis",[6.9036100000000005, 47.59385700000001]],
    [ "Enedis",[6.895995408408409, 47.5982394984985]],
    [ "Enedis",[3.933701812596007, 43.650470631336404]],
    [ "Enedis",[-0.8619475000000001, 48.53221650000001]],
    [ "Enedis",[-0.8746778572790088, 48.53917427960448]],
    [ "Enedis",[-0.876269, 48.534464]],
    [ "Enedis",[-0.8739571868411492, 48.535546996728456]],
    [ "Enedis",[-0.8763057008547009, 48.539780957264966]],
    [ "Enedis",[-0.8717869175911253, 48.53527943317486]],
    [ "Enedis",[-0.6404731935682146, 44.75707181260016]],
    [ "Enedis",[-0.6425419999999992, 44.7584075]],
    [ "Enedis",[-0.6568039999999999, 44.770107]],
    [ "Enedis",[-0.6380609233449472, 44.758799186411146]],
    [ "Enedis",[-0.661885, 44.76723125000001]],
    [ "Enedis",[3.9363203269713707, 43.65998520910765]],
    [ "Enedis",[3.6942519999999996, 50.007597000000004]],
    [ "Enedis",[3.692942857047173, 50.014583900830054]],
    [ "Nestlé France Boué",[3.689095682728547, 50.01013884438774]],
    [ "RTE",[0.09443466376470905, 44.31905925979359]],
    [ "SNCF Réseau",[0.15539243448947662, 44.50890484543121]],
    [ "Poste électrique d'Unet",[0.33127335835035304, 44.352595728893945]],
    [ "Poste électrique de Roja",[0.305821276418899, 44.411338484846794]],
    [ "Poste électrique de Tonneins",[0.3044205858625753, 44.40390535188443]],
    [ "SAUVETAT (LA)",[0.3402423191014574, 44.64873428587193]],
    [ "LICAUGAS",[-0.9606518639807422, 44.39843447352022]],
    [ "Enedis",[6.3950146399371075, 45.73092659968554]],
    [ "Enedis",[2.419331855183046, 44.380981511287516]],
    [ "Taniot",[5.27319612839879, 47.40471297925479]],
    [ "Enedis",[2.4909444999999995, 44.34640205000001]],
    [ "Croas Hentou",[-3.424452671847341, 47.89428830616322]],
    [ "Enedis",[6.9701569999999995, 47.538241]],
    [ "Enedis",[6.9693470133333335, 47.54212972]],
    [ "Enedis",[6.977957, 47.53830549999999]],
    [ "Enedis",[6.993732752254611, 47.54122691138651]],
    [ "Enedis",[6.2573408, 45.315064600000014]],
    [ "Enedis",[0.8050639999999998, 44.388085999999994]],
    [ "Enedis",[0.8227430558344345, 44.383034850946714]],
    [ "Enedis",[0.8043290519146263, 44.37881096666668]],
    [ "Enedis",[4.201747015544041, 43.90980098238342]],
    [ "Enedis",[0.6789294946258413, 47.34983864238801]],
    [ "Enedis",[3.7047785669198317, 49.94602709873417]],
    [ "Planaise",[6.090411839551709, 45.50500818254442]],
    [ "SER",[7.813845030784159, 48.941190323328435]],
    [ "Enedis",[6.9305865, 47.556377000000005]],
    [ "Enedis",[6.934043999999999, 47.5545615]],
    [ "Enedis",[6.8584805, 47.5635315]],
    [ "Enedis",[6.864776382379654, 47.5718247493188]],
    [ "Enedis",[6.865754833333335, 47.56098583333333]],
    [ "Enedis",[6.863086672433679, 47.565002517877744]],
    [ "Enedis",[6.867797663833805, 47.55636505949008]],
    [ "Enedis",[6.347443970944742, 47.67514174385026]],
    [ "Enedis",[6.832547376499508, 47.58891765408063]],
    [ "Enedis",[6.840612000000001, 47.58521999999999]],
    [ "Enedis",[6.134916499999998, 45.471456499999995]],
    [ "Enedis",[6.1364594, 45.474110749999994]],
    [ "Enedis",[3.0621969410464516, 50.687622669113004]],
    [ "Enedis",[6.520771458949251, 47.82269736920919]],
    [ "Enedis",[2.3568175273345546, 51.016017810794324]],
    [ "Beethoven",[-1.1288440000000006, 45.50284750000001]],
    [ "Villas de l'Atlantique",[-1.1337475610561056, 45.503426933093316]],
    [ "Amélie",[-1.1475625926791277, 45.484792408748696]],
    [ "Romain",[-1.1332041785579463, 45.50267684107211]],
    [ "Enedis",[-1.117121839708561, 45.50603704845174]],
    [ "Mémorial",[-1.1213771614627293, 45.519131493389594]],
    [ "Enedis",[-1.115725830917875, 45.522192493516414]],
    [ "Enedis",[-1.120032828657788, 45.517667479172566]],
    [ "Centre de Loisirs",[-1.1283767706724737, 45.51287076908863]],
    [ "Grand Quevilly",[-1.1285284999999998, 45.5085325]],
    [ "Marché",[-1.1238810890585242, 45.5134801707258]],
    [ "Enedis",[-1.1500165863874345, 45.48468796230367]],
    [ "Les Chênes Verts",[-1.0668244, 45.546863450000004]],
    [ "Enedis",[-1.0640239999999987, 45.558186500000005]],
    [ "Mairie",[-1.0633450794701984, 45.545407144591614]],
    [ "Enedis",[-1.0544468508403435, 45.47102323083581]],
    [ "Enedis",[-1.0574525, 45.472125500000004]],
    [ "Enedis",[2.55587311771273, 44.465613929252676]],
    [ "Enedis",[2.497591299897649, 44.419912162503955]],
    [ "CESML",[3.85044375, 43.6517855]],
    [ "CESML",[3.852721146784289, 43.65316171849819]],
    [ "CESML",[3.849897178916706, 43.65060724081247]],
    [ "Le Sanglier",[3.8443221932500813, 43.71688191836786]],
    [ "Nougayrols",[2.4360567511737092, 44.4460839483568]],
    [ "RTE",[-1.2291171927241795, 44.19461837911558]],
    [ "Enedis",[2.607992, 44.2900155]],
    [ "Enedis",[2.6034333370716514, 44.29031237445483]],
    [ "Enedis",[6.803954388070333, 47.91996960366514]],
    [ "Enedis",[6.813664456195682, 47.92436611055448]],
    [ "Poste électrique de Saint-Jean-d'Illac",[-0.857302670783654, 44.81418485078807]],
    [ "Poste électrique de la Croix d'Hins",[-0.8159109786755686, 44.709244417425616]],
    [ "Enedis",[1.0756929999999998, 49.900971999999996]],
    [ "Enedis",[1.060612125470514, 49.88783831827688]],
    [ "Enedis",[1.076294067603161, 49.89654321656425]],
    [ "Enedis",[1.066048499999999, 49.882945]],
    [ "Enedis",[1.0756268192918192, 49.90038498168497]],
    [ "Enedis",[1.0684442586182032, 49.906551361603924]],
    [ "Enedis",[1.073707864826611, 49.902032829440905]],
    [ "Enedis",[1.076074560439561, 49.89809628205128]],
    [ "Enedis",[1.0743292440691596, 49.896712586784616]],
    [ "L'Herbe",[-1.2424172939659868, 44.686627794348965]],
    [ "Poste électrique Lège",[-1.1692213814378098, 44.791635249846784]],
    [ "LACANAU",[-1.0539649944697462, 44.98231127132666]],
    [ "Poste électrique de Boulay",[6.484855728180097, 49.18887483026602]],
    [ "Enedis",[2.4443390056624477, 44.403230162978026]],
    [ "Enedis",[6.496137229917645, 45.802098921516084]],
    [ "Hourtin",[-1.0432489951913448, 45.186461768400946]],
    [ "VIGNAUD",[-1.0337324999999995, 45.0791205]],
    [ "SNCF Réseau",[6.713368279237223, 49.17127060417378]],
    [ "Enedis",[3.052607549999999, 50.683333950000005]],
    [ "BELIET",[-0.7814992600188554, 44.51968503784113]],
    [ "Hostens",[-0.6259581164613517, 44.48597795338329]],
    [ "Enedis",[-0.7909838605482712, 44.6685066563369]],
    [ "Enedis",[-0.7885306053252437, 44.61879611681834]],
    [ "Enedis",[-0.7654237500000001, 44.60985050000001]],
    [ "Enedis",[-0.7689297630853981, 44.60546153278237]],
    [ "Enedis",[-0.7745676704233493, 44.61183246149401]],
    [ "Enedis",[-0.7660211533516988, 44.604226339761254]],
    [ "Enedis",[-0.7813881271837874, 44.60945433787561]],
    [ "Enedis",[-0.7818462500000002, 44.61030974999999]],
    [ "Enedis",[-0.7617004321556583, 44.59989017050691]],
    [ "Enedis",[-0.7965198109406949, 44.663730510071574]],
    [ "Enedis",[-0.733014, 44.58071499999999]],
    [ "Enedis",[-0.7832823515358358, 44.60713394311718]],
    [ "Enedis",[-0.7713786615214983, 44.60664599375229]],
    [ "Enedis",[-0.7786481916329285, 44.60811797975708]],
    [ "Enedis",[-0.7848930967320267, 44.62031766405228]],
    [ "Enedis",[-0.7682142607933374, 44.60613043677405]],
    [ "Enedis",[-0.7868725000000002, 44.620731250000006]],
    [ "Enedis",[-0.769304, 44.60368449999999]],
    [ "Enedis",[-0.767096, 44.60728025]],
    [ "Enedis",[-0.7702418145087234, 44.61050077961433]],
    [ "Enedis",[-0.775145396547532, 44.60469979941649]],
    [ "Enedis",[-0.7770345, 44.607027]],
    [ "Enedis",[-0.7733469753347428, 44.61229629457364]],
    [ "Enedis",[2.6576834856602587, 50.51462069671184]],
    [ "Enedis",[2.656140899286988, 50.50041877094474]],
    [ "Enedis",[2.659900166951178, 50.5076794975532]],
    [ "Enedis",[2.658068190476191, 50.50990274285714]],
    [ "Enedis",[2.6510346611316113, 50.50736695202953]],
    [ "Enedis",[2.658183662909622, 50.499242014402]],
    [ "Enedis",[2.66752940935456, 50.504104054424275]],
    [ "Enedis",[2.6651954559029396, 50.50241944050397]],
    [ "Enedis",[6.545227319826582, 47.95575949874937]],
    [ "Enedis",[6.923773807354116, 47.48237915747403]],
    [ "Enedis",[6.920393981560284, 47.48576116737588]],
    [ "Enedis",[6.9321092, 47.49642]],
    [ "Enedis",[6.918077000000002, 47.484820000000006]],
    [ "Enedis",[6.9265159999999995, 47.4887305]],
    [ "Enedis",[6.929638500000001, 47.488321500000005]],
    [ "Enedis",[6.926252534487496, 47.492131950261054]],
    [ "Enedis",[6.923002999999999, 47.486094]],
    [ "Enedis",[6.925339736877204, 47.489734676955315]],
    [ "Enedis",[6.9300485, 47.489200999999994]],
    [ "Enedis",[6.925816166916542, 47.48397650308179]],
    [ "Enedis",[6.912189999999999, 47.495417]],
    [ "Enedis",[6.9141009168851815, 47.48514289505363]],
    [ "Enedis",[6.920219505241089, 47.489098834381544]],
    [ "Enedis",[6.926159489535941, 47.48623383409161]],
    [ "Enedis",[6.92217370940171, 47.49113318233619]],
    [ "Enedis",[6.918749191456904, 47.491558680396636]],
    [ "Enedis",[6.915688924594257, 47.48158961914273]],
    [ "Enedis",[6.92071520692884, 47.481132156367046]],
    [ "Enedis",[6.914050825290047, 47.492787147947745]],
    [ "Enedis",[7.008327082648402, 47.5309258977169]],
    [ "Enedis",[7.008099, 47.5336395]],
    [ "Enedis",[7.0005733128464, 47.53006941310372]],
    [ "Enedis",[6.997369067864272, 47.53265467065868]],
    [ "Enedis",[3.1899476756756755, 45.54918349549549]],
    [ "Courtelevant Village",[7.079147657105049, 47.51602462268341]],
    [ "Enedis",[3.5064596092544993, 45.99841636589545]],
    [ "RTE",[-0.3224133976281489, 44.04493474228612]],
    [ "Enedis",[-0.6584047797206208, 44.03197607891002]],
    [ "Poste électrique de Saint-Macaire",[-0.22939015070945604, 44.56824696027429]],
    [ "Enedis",[3.0509179362903223, 50.686006659946244]],
    [ "Enedis",[3.0466567028538796, 50.68410518568885]],
    [ "Enedis",[6.052908582774915, 47.360618306701035]],
    [ "Poste électrique de Bazas",[-0.2182955574795081, 44.413767756786854]],
    [ "Sous-station RFF de Dreux",[1.3910409126082617, 48.74217319257926]],
    [ "Enedis",[3.708661019698725, 48.359001672460415]],
    [ "Enedis",[3.756879132684585, 48.40265011739265]],
    [ "Enedis",[3.775174504899135, 48.37538583323727]],
    [ "Enedis",[3.7691439140080476, 48.36775942738171]],
    [ "Poste électrique de Bergholz",[7.08942171457602, 48.76722890019395]],
    [ "Enedis",[5.783369073374862, 45.67453579119184]],
    [ "Enedis",[3.1259749607276226, 50.78186867630406]],
    [ "Enedis",[2.637096120785104, 50.50339683786278]],
    [ "Enedis",[2.6478354393817205, 50.506752502822586]],
    [ "Enedis",[2.64565, 50.499993500000016]],
    [ "Enedis",[2.6457920462287103, 50.498041328467146]],
    [ "Enedis",[2.634090695574672, 50.51003052042161]],
    [ "Enedis",[2.618403045454546, 50.48439322727273]],
    [ "Enedis",[2.6252632921719954, 50.482693577361275]],
    [ "Enedis",[2.6265253561702577, 50.512448388656594]],
    [ "Enedis",[2.6167748236957085, 50.517961827293746]],
    [ "Enedis",[2.6079278249110316, 50.515017687069985]],
    [ "Enedis",[2.6103699019874766, 50.515768197295586]],
    [ "Enedis",[3.1314735, 50.784197000000006]],
    [ "Enedis",[2.5727985000000007, 50.42671500000001]],
    [ "Enedis",[2.57049540360723, 50.4307490184545]],
    [ "Enedis",[2.5526095203194545, 50.437143641707216]],
    [ "Enedis",[5.3928076, 47.44506969999999]],
    [ "Enedis",[5.387078807207585, 47.438769169306816]],
    [ "Enedis",[3.1243883500000003, 50.784101099999994]],
    [ "Enedis",[3.1225698077622153, 50.78398755369044]],
    [ "Enedis",[3.1222416622250417, 50.78647454709531]],
    [ "Poste électrique de Cirey sur Vezouze",[6.948416015153557, 48.57221016858156]],
    [ "Enedis",[3.128420418142243, 50.778052351204465]],
    [ "Enedis",[3.1318347500000003, 50.78097279999999]],
    [ "Enedis",[3.1341858662330147, 50.78262159812085]],
    [ "Enedis",[3.1270384, 50.7790288]],
    [ "Enedis",[3.1296104234042548, 50.779732368321504]],
    [ "Enedis",[3.1311834233174376, 50.78566551967291]],
    [ "Enedis",[2.5435285, 50.46507150000001]],
    [ "Enedis",[2.542056691163604, 50.462378454068244]],
    [ "Enedis",[2.5288170813008133, 50.45296627642277]],
    [ "Enedis",[2.543376332503113, 50.46027599045248]],
    [ "Enedis",[2.553050447863248, 50.466297323076915]],
    [ "Enedis",[2.549538670349908, 50.45663398895028]],
    [ "Enedis",[2.5413418445642413, 50.46415694519317]],
    [ "Enedis",[2.5387185066390754, 50.45242933471289]],
    [ "Enedis",[2.536351, 50.45017149999999]],
    [ "Enedis",[2.5371658933873147, 50.45108114116059]],
    [ "Enedis",[2.5492773956170716, 50.453657581314886]],
    [ "Enedis",[2.527515099613797, 50.44776837488347]],
    [ "Enedis",[2.5287455, 50.45576350000001]],
    [ "Enedis",[2.553942817852785, 50.46187345059645]],
    [ "Enedis",[2.5454425, 50.463634500000005]],
    [ "Enedis",[2.54225272596341, 50.44948814986376]],
    [ "Enedis",[2.5467775485273347, 50.462949262254114]],
    [ "Enedis",[2.5449949140495858, 50.458694274931126]],
    [ "Enedis",[5.834230610587676, 46.34195354495073]],
    [ "RTE",[0.20057914884852798, 47.98011487508231]],
    [ "Enedis",[-0.16517098878343303, 47.550019455277535]],
    [ "Enedis",[-0.17190220449342108, 47.5520013179971]],
    [ "Enedis",[-0.17533062913096675, 47.531697321501426]],
    [ "Enedis",[6.237220232681743, 45.50075189395285]],
    [ "Enedis",[3.1391426999999994, 50.784408250000006]],
    [ "Enedis",[3.1377004655224616, 50.78598640259802]],
    [ "Enedis",[3.1354053, 50.785901900000006]],
    [ "Enedis",[2.484842871501272, 50.674702245547074]],
    [ "Poste électrique de Luxey",[-0.51906711885446, 44.24058190473204]],
    [ "AURIOLLES",[0.0351437420488405, 44.74512827147738]],
    [ "STE-FOY(-LA-GRANDE)",[0.23572206513582206, 44.84063299653265]],
    [ "ST-PEY",[-0.12315897011014848, 44.857033572077775]],
    [ "BERGERAC (NITRO-CELLULOSE)",[0.5223328621236943, 44.84322766807943]],
    [ "BERGERAC (BERGERAC)",[0.48559800865195635, 44.83597237804369]],
    [ "Poste électrique de Caudeau",[0.4883907793498382, 44.872208650173484]],
    [ "Enedis",[5.736705104467354, 46.36004603150057]],
    [ "Enedis",[5.7390817429253795, 46.355805416267415]],
    [ "Enedis",[2.503818214814814, 49.22260094814814]],
    [ "Enedis",[2.5384959999999994, 50.641156499999994]],
    [ "Enedis",[5.976874215125388, 49.01559366958544]],
    [ "Enedis",[2.6537796635514024, 49.891918685358256]],
    [ "Enedis",[2.394054996349255, 50.64068242166109]],
    [ "Enedis",[2.408261999999999, 50.642116]],
    [ "Enedis",[2.3906431952861955, 50.636351537851645]],
    [ "Enedis",[2.3957639448171, 50.63930202978267]],
    [ "Enedis",[2.3988538607305943, 50.63505905479452]],
    [ "Enedis",[2.4076197121741747, 50.63452213994494]],
    [ "Enedis",[2.396248374219193, 50.66300830910468]],
    [ "Enedis",[2.4084264999999996, 50.632933999999985]],
    [ "Enedis",[2.399133054263566, 50.64025633333333]],
    [ "Enedis",[2.4037544999999994, 50.64481649999999]],
    [ "Enedis",[2.385126, 50.639149]],
    [ "Enedis",[2.3973229999999996, 50.6343315]],
    [ "Enedis",[2.4055481914022527, 50.6385544667349]],
    [ "Enedis",[2.4025944977973563, 50.63394238399413]],
    [ "Enedis",[2.393370825197583, 50.660493492329145]],
    [ "Enedis",[2.4023568158542594, 50.631938339241756]],
    [ "Enedis",[2.3874392161520186, 50.631891552313085]],
    [ "Enedis",[2.4092099999999994, 50.63185]],
    [ "Enedis",[2.393707960954447, 50.635214311641356]],
    [ "Enedis",[2.396126838854211, 50.638667125226426]],
    [ "Enedis",[2.393720755300008, 50.63853139630759]],
    [ "Enedis",[2.3910065813886923, 50.639202919975354]],
    [ "Électricité de France",[7.585874163632994, 44.04673204861007]],
    [ "Enedis",[3.136733944760585, 50.781100562222996]],
    [ "Enedis",[3.138437617562514, 50.77715960120226]],
    [ "Chemin de Fer",[6.226418399999998, 46.19492564999999]],
    [ "Groupe Scolaire",[6.227698110620259, 46.19585532964006]],
    [ "Enedis",[6.1709635, 48.669887499999994]],
    [ "Enedis",[3.4249151190407674, 50.329753009016784]],
    [ "Enedis",[3.418098406631763, 50.33097471553228]],
    [ "Enedis",[3.414626291081158, 50.32877365557785]],
    [ "Enedis",[3.4203931521131, 50.33362051349511]],
    [ "Enedis",[3.4117288359607034, 50.331095834589895]],
    [ "Enedis",[3.4155272659228264, 50.33154454718735]],
    [ "Enedis",[3.4111644860326042, 50.3330471142787]],
    [ "Enedis",[6.849707852420963, 47.942859464558126]],
    [ "Enedis",[1.297475663176265, 47.43529947643979]],
    [ "Sous-station SNCF de Cesson-Sévigné",[-1.621856059729867, 48.10985681836796]],
    [ "Enedis",[6.872924898109352, 47.94012574870399]],
    [ "Enedis",[5.377674149235359, 47.35119464967221]],
    [ "194394531",[6.023723810852713, 47.346140458914725]],
    [ "Enedis",[3.3815670882658364, 50.31750928569574]],
    [ "Enedis",[3.389302040322581, 50.334231331317206]],
    [ "Enedis",[3.4042947739256046, 50.32832477139762]],
    [ "Enedis",[3.385359478964402, 50.330040119741106]],
    [ "Enedis",[3.3904982803445556, 50.335599976246414]],
    [ "Enedis",[3.3701084878587184, 50.3159928830022]],
    [ "Enedis",[3.3799483453046033, 50.322116834796496]],
    [ "Enedis",[3.3965344710830703, 50.323421688748674]],
    [ "Enedis",[3.3915793532744165, 50.33106642397462]],
    [ "Enedis",[3.3907410216277745, 50.31610516752039]],
    [ "Enedis",[3.4114198942115777, 50.31973970059881]],
    [ "Enedis",[3.381656, 50.340686]],
    [ "Enedis",[3.3767689323116215, 50.319003390804596]],
    [ "Enedis",[3.4131469958333334, 50.31902177500001]],
    [ "Enedis",[3.4091833186972953, 50.32961773742366]],
    [ "Enedis",[3.369473235050326, 50.33281204854944]],
    [ "Enedis",[3.395342527094899, 50.326113261432724]],
    [ "Enedis",[3.3772538261039466, 50.322688867917165]],
    [ "Enedis",[3.4035558349351636, 50.331402395270786]],
    [ "Enedis",[3.3996307193479804, 50.32333778431372]],
    [ "Enedis",[3.3933595249343846, 50.325719500187475]],
    [ "Enedis",[3.411517470373889, 50.3179905329531]],
    [ "Enedis",[3.381797345603272, 50.33297517586912]],
    [ "Enedis",[3.13935875, 50.7724966]],
    [ "Enedis",[3.1248912999999994, 50.77540785]],
    [ "Enedis",[3.349721109090909, 50.339362200000004]],
    [ "Enedis",[3.3497060888620824, 50.33217319742662]],
    [ "Enedis",[3.3420653332290686, 50.345432497654045]],
    [ "Enedis",[3.334389499999999, 50.33377599999999]],
    [ "Enedis",[3.361848999999999, 50.324876999999994]],
    [ "Enedis",[3.3383545, 50.33517499999999]],
    [ "Enedis",[3.3581966602815974, 50.324750340536994]],
    [ "Enedis",[3.3328632491525423, 50.33144498945386]],
    [ "Enedis",[3.3430986341463416, 50.33204531707317]],
    [ "Enedis",[3.3535890000000004, 50.331972]],
    [ "Enedis",[3.3560451021798374, 50.32773035286104]],
    [ "Enedis",[3.3355606925424897, 50.332496842872004]],
    [ "Enedis",[3.333918578481444, 50.334857029690156]],
    [ "Enedis",[3.343553780064309, 50.31987063065381]],
    [ "Enedis",[3.4265068128716334, 50.366136086393844]],
    [ "Enedis",[3.425143671971707, 50.3692704801061]],
    [ "Enedis",[3.4282628725081117, 50.367761240611955]],
    [ "Enedis",[3.359183394364879, 50.370677786576806]],
    [ "Enedis",[3.367794989218329, 50.35001533378257]],
    [ "Poste électrique de Toulouse-Centre",[1.452170788101194, 43.609473181814714]],
    [ "Enedis",[3.4308653666666666, 50.38267714222222]],
    [ "Enedis",[3.42849842534819, 50.38056438774374]],
    [ "Enedis",[6.843513000000001, 47.4858665]],
    [ "Enedis",[6.8375871741076075, 47.4877744742429]],
    [ "Enedis",[6.848915, 47.478323]],
    [ "Enedis",[6.854209415222305, 47.481905754835466]],
    [ "Enedis",[6.842128497031381, 47.48905940797285]],
    [ "Enedis",[6.844381680295286, 47.46621117319704]],
    [ "Enedis",[6.8419805, 47.47247850000001]],
    [ "Enedis",[6.853994500000001, 47.477328]],
    [ "Enedis",[6.8556695, 47.4831685]],
    [ "Enedis",[6.854749901547117, 47.473016294655416]],
    [ "Enedis",[6.848190615148155, 47.48689594129859]],
    [ "Enedis",[6.858469499999999, 47.47289650000001]],
    [ "Enedis",[6.855985500000001, 47.47362449999999]],
    [ "Enedis",[6.8394499999999985, 47.488631999999996]],
    [ "Enedis",[6.8427880000000005, 47.470628999999995]],
    [ "Enedis",[6.849753189386174, 47.475601342102266]],
    [ "Enedis",[6.845998350687829, 47.46885469015872]],
    [ "Enedis",[6.849726036814426, 47.47424363886802]],
    [ "Enedis",[6.856657705061082, 47.47493195520651]],
    [ "Enedis",[6.844150233038348, 47.48118043362831]],
    [ "Enedis",[6.846203999999998, 47.484696]],
    [ "Enedis",[6.861442758530184, 47.48550205511812]],
    [ "Enedis",[6.84519877843719, 47.487170698318494]],
    [ "Enedis",[6.836243624399616, 47.48952629779058]],
    [ "Enedis",[6.852626266666666, 47.47146278579234]],
    [ "Enedis",[6.845375, 47.47573]],
    [ "Enedis",[6.842542455380578, 47.47852729790025]],
    [ "Enedis",[6.8461414778903364, 47.488830766361154]],
    [ "Enedis",[6.8409599666666665, 47.48630007361111]],
    [ "Enedis",[6.832588999999999, 47.491958499999996]],
    [ "Enedis",[6.85239723255814, 47.474422449612405]],
    [ "Enedis",[6.843521353174603, 47.47455296031745]],
    [ "Enedis",[6.8563348284684675, 47.48048681801801]],
    [ "Enedis",[6.8585970000000005, 47.474514]],
    [ "Enedis",[6.839276569730358, 47.485802404851654]],
    [ "Enedis",[6.8433946347200285, 47.48438858328945]],
    [ "Enedis",[6.861023468853546, 47.48415509178264]],
    [ "Enedis",[6.851065863115058, 47.4828733518313]],
    [ "Enedis",[6.863074152401588, 47.47266069321055]],
    [ "Enedis",[6.848708372859025, 47.489477103425564]],
    [ "Enedis",[6.849595285944274, 47.4793188121316]],
    [ "RTE",[-1.6107545396381444, 48.060764048542985]],
    [ "Sous-station SNCF de Lamotte-Brebière",[2.412441784469016, 49.88637204870207]],
    [ "Enedis",[0.9921957615629979, 49.52910131419456]],
    [ "Enedis",[2.5206011026541764, 49.91526006245122]],
    [ "Enedis",[2.516518249999999, 49.91417150000001]],
    [ "Enedis",[2.5151831353919243, 49.912109219319085]],
    [ "Enedis",[2.4907427215686284, 49.905989027450985]],
    [ "Enedis",[2.4955756636025384, 49.91308116721383]],
    [ "Enedis",[2.5101028858173073, 49.91363128385416]],
    [ "Enedis",[2.49338970964504, 49.91534888548225]],
    [ "Enedis",[2.5206305873978274, 49.90995617579267]],
    [ "Sous-station RFF du Theil",[0.6958224575788527, 48.267137649383585]],
    [ "Enedis",[3.3307212726337445, 50.436155877572006]],
    [ "Enedis",[3.3275374999999996, 50.436431999999996]],
    [ "Enedis",[6.137835034933334, 49.3001075768]],
    [ "réséda",[6.145530727956989, 49.31071696666666]],
    [ "Enedis",[3.5083738482835, 50.36724215503876]],
    [ "Enedis",[3.5068526720015805, 50.37449098636632]],
    [ "Enedis",[3.499301352471408, 50.371497954849815]],
    [ "Enedis",[3.523756086509376, 50.383106690260135]],
    [ "Enedis",[3.4998300017313864, 50.368767506307194]],
    [ "Enedis",[3.504465327808471, 50.36571651933701]],
    [ "Enedis",[3.4987490000000014, 50.3740255]],
    [ "Enedis",[3.5083626632494553, 50.373426506989745]],
    [ "Enedis",[3.512436868884541, 50.371466020482714]],
    [ "Enedis",[3.5000394999999993, 50.380425499999994]],
    [ "Enedis",[3.521275329517863, 50.380525642386395]],
    [ "Enedis",[3.506186161209068, 50.3769929395466]],
    [ "Enedis",[3.508558894154818, 50.370702641511734]],
    [ "Enedis",[3.50183351039069, 50.3681081670823]],
    [ "Enedis",[3.4937254650076506, 50.368347671096075]],
    [ "Enedis",[3.497918999635862, 50.369969792877434]],
    [ "RTE",[0.6796008858422763, 48.25659413884305]],
    [ "RTE",[0.6388560730702774, 48.19757342376736]],
    [ "Enedis",[3.596488293492695, 50.405900881806105]],
    [ "Enedis",[3.601789, 50.40922]],
    [ "Enedis",[2.516721218773849, 49.9085602799966]],
    [ "Enedis",[2.4983946666666648, 49.908005]],
    [ "Enedis",[2.507098883424934, 49.908768547006076]],
    [ "Enedis",[2.50366751995089, 49.907518949048495]],
    [ "Enedis",[2.5074431732656515, 49.91033352182741]],
    [ "Enedis",[2.5014036333115324, 49.9085114203183]],
    [ "Enedis",[2.506578813293502, 49.911031659696285]],
    [ "Enedis",[2.4989267391304346, 49.906318115942035]],
    [ "Enedis",[2.507896182496194, 49.90591836270928]],
    [ "Enedis",[2.5102741196493907, 49.90711731307974]],
    [ "Enedis",[2.5010065000000004, 49.9037765]],
    [ "Enedis",[2.5014114999999997, 49.910084]],
    [ "Enedis",[2.5008677898383374, 49.90485598460355]],
    [ "Enedis",[2.513315568604127, 49.90886425706931]],
    [ "Enedis",[3.4500453129343196, 50.41820063528357]],
    [ "Enedis",[3.4353885813333327, 50.449374794666674]],
    [ "Enedis",[3.4295774999999993, 50.4370515]],
    [ "Enedis",[3.4195908888888886, 50.4478696419753]],
    [ "Enedis",[3.418386102564103, 50.44125238461538]],
    [ "Enedis",[3.4385596690647477, 50.44908709352517]],
    [ "Enedis",[3.4279917882816338, 50.434328330379124]],
    [ "Enedis",[3.4311549999999995, 50.444861]],
    [ "Enedis",[3.4446309999999998, 50.445147]],
    [ "Enedis",[3.428711747023808, 50.44865280803571]],
    [ "Enedis",[3.423056035639413, 50.446141809224315]],
    [ "Enedis",[3.4380328574137278, 50.43758358551384]],
    [ "Enedis",[3.434892090128756, 50.44107727753935]],
    [ "Enedis",[3.4473631796349675, 50.43803697118156]],
    [ "Enedis",[3.4405300000000008, 50.444188]],
    [ "Enedis",[3.4408238560794047, 50.446981298593876]],
    [ "Enedis",[3.426182642359297, 50.44703528776792]],
    [ "Enedis",[3.431051864180479, 50.44797346224678]],
    [ "Enedis",[3.439824840126009, 50.43895369186848]],
    [ "Enedis",[7.031191986666665, 48.362425736]],
    [ "Enedis",[7.018784989295889, 48.33449353508343]],
    [ "Poste électrique de Risle",[0.5036048499999999, 49.3571099]],
    [ "Enedis",[3.4794183204556695, 50.467259471025244]],
    [ "Enedis",[3.451238, 50.46927349999999]],
    [ "Enedis",[5.7745715, 48.381978999999994]],
    [ "Enedis",[3.444043268380695, 50.48544861772666]],
    [ "Enedis",[4.364206445408317, 49.516059834081176]],
    [ "Enedis",[4.377258201126125, 49.50813809324325]],
    [ "Enedis",[4.370990758254057, 49.51882510483119]],
    [ "Enedis",[4.369018959349593, 49.519916333333335]],
    [ "Enedis",[4.369349000000001, 49.521801]],
    [ "Enedis",[4.365000116465864, 49.51230521686747]],
    [ "Enedis",[4.367932697043668, 49.51802754271766]],
    [ "Enedis",[4.3785518699494945, 49.51891443055557]],
    [ "Enedis",[4.368882544592321, 49.50531282071286]],
    [ "Enedis",[4.3689308198308785, 49.51125582855973]],
    [ "Enedis",[4.3655655, 49.519791]],
    [ "Enedis",[4.379238563636363, 49.510629629201105]],
    [ "Enedis",[4.364023266521424, 49.51410418300654]],
    [ "Enedis",[4.383178571987699, 49.5057731114528]],
    [ "Enedis",[4.367843883797052, 49.51653504637207]],
    [ "Enedis",[4.380459275942029, 49.50976891710146]],
    [ "Enedis",[4.373877526069127, 49.52059270650265]],
    [ "Enedis",[4.364964334724706, 49.50451850566487]],
    [ "Sous-station SNCF de Gannes",[2.4203401114528638, 49.58241962544573]],
    [ "RTE",[2.3086465639252323, 49.62717554911453]],
    [ "RTE",[2.528040045013482, 49.55478602788143]],
    [ "Enedis",[6.49021081935351, 48.267376718221115]],
    [ "Enedis",[3.569513182705718, 50.462457417015344]],
    [ "Enedis",[3.5774145021571937, 50.4770672447946]],
    [ "Enedis",[3.5591113382292945, 50.465985976336185]],
    [ "Enedis",[3.5719298506672628, 50.46920922418549]],
    [ "Enedis",[3.5736794999999995, 50.4790215]],
    [ "Enedis",[3.582345267542001, 50.488601643230275]],
    [ "Enedis",[3.5751443267233247, 50.45494219169027]],
    [ "Enedis",[3.568508985472155, 50.47813732405166]],
    [ "Enedis",[3.572834736816642, 50.48187745089501]],
    [ "Enedis",[3.5800919999999996, 50.455763000000005]],
    [ "Enedis",[3.572467226515151, 50.45448569242424]],
    [ "Enedis",[3.578479999999999, 50.48075349999999]],
    [ "Enedis",[3.5762479999999996, 50.45948599999999]],
    [ "Enedis",[3.572658423423422, 50.47756531531531]],
    [ "Enedis",[3.555458590140996, 50.46281631449789]],
    [ "Enedis",[3.5669654061987397, 50.46458226961928]],
    [ "Enedis",[3.5793283336735557, 50.483300502168916]],
    [ "Enedis",[3.5763746530973446, 50.45271025663717]],
    [ "Enedis",[3.578492496995035, 50.456051331939726]],
    [ "Enedis",[3.55186484751773, 50.46783083723403]],
    [ "Enedis",[3.402729208383716, 50.48289785207578]],
    [ "Enedis",[3.4102636714116246, 50.47916097508897]],
    [ "Enedis",[2.64088570259481, 49.993049261477054]],
    [ "Enedis",[2.6404535, 49.9993735]],
    [ "Enedis",[2.640865418451106, 49.995680439150604]],
    [ "Enedis",[2.6441458850889195, 49.9964317870497]],
    [ "Enedis",[2.64481716829086, 49.99959715952183]],
    [ "Enedis",[2.6493071668901402, 50.000513497809955]],
    [ "Enedis",[2.6450080469314066, 49.99848445487364]],
    [ "Enedis",[2.6395559706666663, 50.00012318420512]],
    [ "Enedis",[2.6626672708333334, 50.00084464583333]],
    [ "Enedis",[2.6564380872274147, 50.002005383177575]],
    [ "Enedis",[2.6554870000000017, 49.998513499999994]],
    [ "Enedis",[2.6545345, 50.0009615]],
    [ "Enedis",[2.659562, 50.004957000000005]],
    [ "Enedis",[2.657161, 49.99886150000001]],
    [ "Enedis",[2.6633809999999998, 50.0024585]],
    [ "Enedis",[2.6567345427095748, 50.00411961124355]],
    [ "Enedis",[1.6697152996453906, 46.85750083510638]],
    [ "Enedis",[1.6685231328751446, 46.855247172966784]],
    [ "Enedis",[1.6717563071895425, 46.859081333333336]],
    [ "Enedis",[1.676893381150958, 46.853346360022236]],
    [ "Enedis",[1.6813494736959929, 46.85527216498768]],
    [ "Enedis",[2.6517418538812776, 50.00723346879756]],
    [ "Enedis",[2.650882748607368, 50.007581339802336]],
    [ "Enedis",[2.6525839931406257, 50.00320550940226]],
    [ "Enedis",[2.646164319103973, 50.00431476021414]],
    [ "Enedis",[2.6545941251619403, 50.00890064926432]],
    [ "Enedis",[2.6567852409190085, 50.00551116082659]],
    [ "Enedis",[2.6515035230299047, 50.00621091137306]],
    [ "Enedis",[2.6453715533480513, 50.00201244904341]],
    [ "Enedis",[2.652365113888889, 50.01261789444444]],
    [ "Enedis",[2.6361715969912907, 49.99867077197149]],
    [ "Enedis",[2.636219, 50.000916000000004]],
    [ "Enedis",[2.638407071765364, 50.0040654028713]],
    [ "Enedis",[2.65663925196335, 49.98085065205061]],
    [ "Enedis",[2.663232120862202, 49.98365533102386]],
    [ "Enedis",[2.672431362668744, 49.984076418224305]],
    [ "Enedis",[2.6701070436681222, 49.98602083260553]],
    [ "Enedis",[2.6597161683211996, 49.98495901455989]],
    [ "Enedis",[2.6104257017543855, 49.96191905847953]],
    [ "Enedis",[3.1192385648604275, 50.77807700131363]],
    [ "Enedis",[3.1149378819277103, 50.78257309790273]],
    [ "Enedis",[3.1117642715207054, 50.780232040173104]],
    [ "Enedis",[3.1191863205622945, 50.782601504054796]],
    [ "Enedis",[3.1119227319077374, 50.79027593765817]],
    [ "Enedis",[3.108846316997302, 50.78244489920051]],
    [ "Enedis",[3.112475034557376, 50.79066305133784]],
    [ "Enedis",[3.118706483890541, 50.7804778963656]],
    [ "Enedis",[3.328679, 50.2334929]],
    [ "Enedis",[3.3235735, 50.244068399999996]],
    [ "Enedis",[2.6208105696336674, 44.496449041724055]],
    [ "Enedis",[3.327442671608266, 50.23637606352202]],
    [ "Enedis",[3.3176185000000005, 50.2331979]],
    [ "Enedis",[3.3282359363273457, 50.23514707485031]],
    [ "Poste électrique de Bruz",[-1.7736403075981002, 48.00999281996284]],
    [ "Enedis",[2.98326262680362, 49.85309371851308]],
    [ "Enedis",[-0.9557527499750196, 48.587089299616146]],
    [ "Enedis",[6.801235453333334, 48.245351694666674]],
    [ "Enedis",[5.355932370983763, 43.467514802776826]],
    [ "Boulaincourt",[6.081656574327753, 48.373634125317096]],
    [ "Enedis",[3.4942196674191117, 50.38339449795168]],
    [ "Enedis",[3.489215371841784, 50.38786356961143]],
    [ "Enedis",[3.505113904399907, 50.40456138147893]],
    [ "Enedis",[3.485432025965596, 50.38873316439469]],
    [ "Enedis",[3.5219352780569517, 50.40331241876047]],
    [ "Enedis",[3.4695388036529686, 50.393146707762554]],
    [ "Enedis",[3.4949925378370543, 50.398633277181794]],
    [ "Enedis",[3.4877112298325725, 50.400970195761616]],
    [ "Enedis",[3.5014545937757777, 50.400867776452955]],
    [ "Enedis",[3.49488019699233, 50.37518073020615]],
    [ "Enedis",[3.4886178325791852, 50.39595299525964]],
    [ "Enedis",[3.482199643481363, 50.39445744551453]],
    [ "Enedis",[3.5033529064143036, 50.3982427015172]],
    [ "Enedis",[3.485578657534246, 50.38880653881278]],
    [ "Enedis",[3.4829684580152667, 50.390865154803656]],
    [ "Enedis",[3.4826380432291684, 50.38778963880207]],
    [ "Centre",[6.682049293564714, 48.34748554880694]],
    [ "RTE",[2.532269382731421, 49.70688839700379]],
    [ "Enedis",[3.537232132197891, 50.4697430892133]],
    [ "Enedis",[3.5247172485927276, 50.480227883158385]],
    [ "Enedis",[3.537139335264581, 50.47413349362689]],
    [ "Enedis",[3.5372324109003475, 50.47212969507525]],
    [ "Enedis",[3.533264100671141, 50.45636537807607]],
    [ "Enedis",[3.5523619349873643, 50.44410546864231]],
    [ "Enedis",[3.549109105149552, 50.453189343509095]],
    [ "Enedis",[3.5457751090655507, 50.453582792840535]],
    [ "Enedis",[3.5435499999999998, 50.4540795]],
    [ "Enedis",[7.072926794212677, 48.22360141875228]],
    [ "Enedis",[7.058664443525902, 48.22862021584041]],
    [ "Enedis",[-4.2271089748664785, 48.025833374803646]],
    [ "Enedis",[3.1491375999999978, 50.78757809999999]],
    [ "Enedis",[3.1469216999999996, 50.784812949999996]],
    [ "195850224",[3.146633865480765, 50.787241195374975]],
    [ "Enedis",[3.143832020841908, 50.788356353404424]],
    [ "Enedis",[3.1417910000000004, 50.785602999999995]],
    [ "Enedis",[3.143032399999999, 50.78350740000001]],
    [ "Enedis",[-4.019589816815074, 48.01979203275792]],
    [ "Poste électrique de Phalsbourg",[7.271567178771269, 48.773977465123075]],
    [ "Poste électrique de Sandricourt",[2.129288370647355, 49.21700946557816]],
    [ "Poste électrique de Muremont",[5.584032667695718, 48.439804914400284]],
    [ "Enedis",[6.633400956494715, 48.34075033706237]],
    [ "Enedis",[6.641190380396056, 48.333920683320905]],
    [ "Enedis",[6.63982480697847, 48.33680612249443]],
    [ "Enedis",[6.653194347631813, 48.339040218945485]],
    [ "Enedis",[6.649329828375599, 48.33663452311032]],
    [ "Enedis",[6.642348599999999, 48.342962599999986]],
    [ "Enedis",[6.655504999999999, 48.339285999999994]],
    [ "Enedis",[6.641419099999999, 48.34985230000001]],
    [ "Pont Noir",[6.21704085, 46.19238045]],
    [ "Poste Enedis",[6.307374094917428, 44.160889735449736]],
    [ "Enedis",[6.118132343291051, 43.822521208703854]],
    [ "Enedis",[5.7576211991480895, 48.48221200268262]],
    [ "Enedis",[3.1186582099997717, 50.76993202414]],
    [ "Enedis",[3.1199346398268397, 50.77102805194805]],
    [ "Enedis",[3.1304139994736886, 50.766382233455985]],
    [ "Verne",[5.190972381092617, 45.544881006017924]],
    [ "RTE",[1.3412940912562668, 43.447936528343305]],
    [ "Enedis",[5.836588999295965, 45.90414690530734]],
    [ "Enedis",[6.351750282291666, 44.17170604375001]],
    [ "Enedis",[6.350531000000001, 44.174996]],
    [ "Enedis",[4.867320067169541, 46.3597293200431]],
    [ "Enedis",[6.22299085, 46.192902]],
    [ "Enedis",[3.1133181999999997, 50.7757065]],
    [ "Enedis",[3.118043349999999, 50.77393784999999]],
    [ "Enedis",[3.102700142445214, 50.7782459538639]],
    [ "Enedis",[3.1065964152722447, 50.78088184462151]],
    [ "Enedis",[3.1216264999999996, 50.77566950000001]],
    [ "Enedis",[3.1047771762656144, 50.779572652202496]],
    [ "Enedis",[3.110067058453731, 50.77764773077672]],
    [ "Enedis",[3.0969075500000005, 50.76622185]],
    [ "Enedis",[3.111629103919799, 50.7777265316354]],
    [ "Enedis",[3.1155558999999995, 50.77688449999999]],
    [ "Enedis",[3.099089495455932, 50.77682695062521]],
    [ "Enedis",[6.797402220229096, 45.62432131626886]],
    [ "Enedis",[6.800385596716059, 45.6207538348685]],
    [ "Les Coins",[6.795903599999999, 45.625575]],
    [ "Enedis",[6.789766559965854, 45.62293460392659]],
    [ "Enedis",[6.825942182415785, 45.613542650820264]],
    [ "Enedis",[6.786927266501967, 45.62616973267842]],
    [ "Enedis",[3.785647828542173, 47.37787876893735]],
    [ "Enedis",[3.7593160930171283, 45.11024407233202]],
    [ "Enedis",[6.61335641622967, 46.071158433112494]],
    [ "Enedis",[7.7157029999999995, 48.3183265]],
    [ "Enedis",[7.6955324830420375, 48.32497699888676]],
    [ "Enedis",[7.7000979699870395, 48.323781494855254]],
    [ "Enedis",[7.7032769000000005, 48.321490100000005]],
    [ "Enedis",[7.70136035, 48.31692945]],
    [ "Poste électrique d'Espiers",[1.7747476567214193, 48.236066517389574]],
    [ "RTE",[3.755479694615527, 47.996013245236924]],
    [ "Sous-station RFF de Cordy",[2.0311636534332442, 47.554007219416214]],
    [ "Enedis",[6.472809499999999, 46.258160000000004]],
    [ "Enedis",[6.4788481902398685, 46.249277228701416]],
    [ "Enedis",[6.458499505577466, 46.24570272746231]],
    [ "Enedis",[6.48705219723866, 46.24677452662722]],
    [ "Enedis",[6.460422969223513, 46.25960693580561]],
    [ "Portes",[6.294714515031602, 46.30686480766694]],
    [ "Enedis",[6.3042401604461835, 46.295550519095336]],
    [ "Enedis",[6.306724270446002, 46.29663919924471]],
    [ "Enedis",[6.308350825889968, 46.30979517605178]],
    [ "Enedis",[6.313711050000001, 46.309566350000004]],
    [ "Enedis",[6.305678690870488, 46.307597521019105]],
    [ "Enedis",[6.301167002030456, 46.30960683739425]],
    [ "Manoir",[6.29635060827572, 46.30373293108742]],
    [ "Enedis",[6.30836379342723, 46.30816507042254]],
    [ "Enedis",[6.306958490706052, 46.30530421939241]],
    [ "Enedis",[6.311510640524823, 46.30057988787547]],
    [ "Enedis",[6.304421999999999, 46.309279499999995]],
    [ "Enedis",[6.3099001999999995, 46.30432660000001]],
    [ "Enedis",[6.310454057395145, 46.307408838852105]],
    [ "Enedis",[6.306351162577691, 46.31216800588813]],
    [ "Enedis",[6.302156743932639, 46.30989605646359]],
    [ "Enedis",[6.301156119157677, 46.306825736517716]],
    [ "Enedis",[6.314406991564572, 46.3063624571199]],
    [ "Enedis",[6.308642620131514, 46.29962983965605]],
    [ "Enedis",[6.312248000000001, 46.309070500000004]],
    [ "Enedis",[6.314365908640138, 46.302343897607535]],
    [ "Enedis",[6.301679905109489, 46.2936923406326]],
    [ "Enedis",[6.315729322792223, 46.305257532677444]],
    [ "Enedis",[6.282435601734105, 46.30143690385356]],
    [ "Poste électrique du Grand-Quevilly",[1.0222062463828463, 49.41927993091554]],
    [ "Enedis",[6.330859292566427, 46.317797684526546]],
    [ "Enedis",[6.320744098279159, 46.322815983789035]],
    [ "Enedis",[6.332320468775344, 46.32177516382806]],
    [ "Enedis",[6.30134495, 46.3326287]],
    [ "Enedis",[6.325876164075992, 46.320076554404146]],
    [ "Enedis",[6.2908208000000005, 46.35373160000001]],
    [ "Enedis",[6.299525000000001, 46.352088499999994]],
    [ "Enedis",[6.295521968543886, 46.34939989548453]],
    [ "Enedis",[6.2755114999999995, 46.334123500000004]],
    [ "Enedis",[6.275025982404694, 46.332415979472145]],
    [ "Enedis",[6.288967024470307, 46.34875683184124]],
    [ "Enedis",[6.317226831225765, 46.35496733636824]],
    [ "Enedis",[6.2943261658343745, 46.355551971702035]],
    [ "Enedis",[6.316294294138607, 46.353506473543554]],
    [ "Enedis",[6.280348669393319, 46.34946701772324]],
    [ "Enedis",[6.284908326533503, 46.345140507862304]],
    [ "Enedis",[6.284929405725101, 46.34796535367874]],
    [ "Enedis",[6.292374839647366, 46.344071868715744]],
    [ "Enedis",[6.275695846689113, 46.33635841885522]],
    [ "Enedis",[6.302234492412242, 46.3614676904102]],
    [ "Enedis",[6.304056544564495, 46.36324203427385]],
    [ "Residence du Moulin",[6.310188466057188, 46.3659301823251]],
    [ "Enedis",[6.355649868970483, 46.35057838300936]],
    [ "Enedis",[6.346156, 46.357022]],
    [ "Enedis",[6.333296358346927, 46.35061734801522]],
    [ "Enedis",[6.350769172067463, 46.35127156660981]],
    [ "Enedis",[6.35453611695467, 46.340122904583026]],
    [ "Enedis",[6.351025462819518, 46.34512279337722]],
    [ "Enedis",[6.3990525, 46.335479]],
    [ "Enedis",[6.368608802973976, 46.3318591511772]],
    [ "Enedis",[6.35668, 46.3341]],
    [ "Enedis",[6.370888094859037, 46.33173781326699]],
    [ "Enedis",[6.391641623826938, 46.3397712123497]],
    [ "Enedis",[6.354339823129254, 46.33091201360545]],
    [ "Enedis",[6.359069174345549, 46.337565647294944]],
    [ "Enedis",[6.38087625, 46.333974]],
    [ "Enedis",[6.396685355850223, 46.34475816738547]],
    [ "Enedis",[6.3947229911336665, 46.340407718987805]],
    [ "Excuchefattaz",[6.3572258239700385, 46.34060994756555]],
    [ "Enedis",[6.389539881206937, 46.333394777144214]],
    [ "Enedis",[6.367346927868852, 46.34372060459017]],
    [ "Enedis",[6.400906249999999, 46.33594725]],
    [ "Enedis",[6.388576218109759, 46.33217744231677]],
    [ "Enedis",[6.394325129014538, 46.34258722145665]],
    [ "Enedis",[6.399158693779905, 46.33864947710184]],
    [ "Enedis",[6.36282325308642, 46.327678197530865]],
    [ "Enedis",[6.392238976811594, 46.33423706183576]],
    [ "Enedis",[6.224406449999999, 46.1981467]],
    [ "Enedis",[6.357409042546062, 44.501632245226126]],
    [ "Enedis",[3.6606786120689656, 50.235349866858236]],
    [ "Enedis",[3.6644295000000002, 50.23322099999999]],
    [ "Enedis",[3.6804298846761445, 50.248475296998414]],
    [ "Enedis",[2.4591945948963323, 48.2859541208134]],
    [ "Enedis",[2.4744388216783215, 48.28269408857809]],
    [ "Enedis",[2.405796903846154, 48.299779714743586]],
    [ "Enedis",[2.4025487754385964, 48.29145355789473]],
    [ "Enedis",[2.4144377950401164, 48.293401951859956]],
    [ "Enedis",[2.401509471631205, 48.297228480111]],
    [ "Enedis",[2.4139261669713594, 48.282757007922]],
    [ "Enedis",[2.412086294117647, 48.29327096078431]],
    [ "Enedis",[2.399633795109873, 48.29905964923141]],
    [ "Enedis",[2.387902820520612, 48.29781755707485]],
    [ "Enedis",[2.402182708364922, 48.29066646803134]],
    [ "Enedis",[2.4022991061093273, 48.29088245873526]],
    [ "Enedis",[2.407779081604426, 48.29609526661784]],
    [ "Enedis",[2.402498222513318, 48.29145896445725]],
    [ "Enedis",[2.4010365578350075, 48.28336401964208]],
    [ "Enedis",[2.4118249265536718, 48.29743275141242]],
    [ "Enedis",[2.4076344997573997, 48.30097547889374]],
    [ "Enedis",[2.3963363353214047, 48.299342991716365]],
    [ "Enedis",[2.4005794639977753, 48.286721591604106]],
    [ "Enedis",[2.408456447049146, 48.28194435410241]],
    [ "Enedis",[6.33171535, 46.25085075]],
    [ "Poste Électrique de la Croix Baptiste",[2.148646429890523, 49.13140118711263]],
    [ "Enedis",[1.457755320866592, 43.55201327107563]],
    [ "RTE",[-0.574474, 46.7186198]],
    [ "Les Meuniers",[0.39465912173052886, 46.59703595891601]],
    [ "Enedis",[1.4499269456082333, 43.64035778794561]],
    [ "Enedis",[-1.5551679934001015, 48.83855949056646]],
    [ "Enedis",[-1.5559536127945388, 48.844382430674735]],
    [ "Enedis",[-1.546348692881227, 48.84352495588882]],
    [ "Enedis",[-1.5576066418142045, 48.8376308663961]],
    [ "Enedis",[-1.5451992093149933, 48.84268740738694]],
    [ "Enedis",[-1.5573291677842669, 48.84325062287156]],
    [ "Enedis",[-1.5522348295697825, 48.83792155901002]],
    [ "Enedis",[-1.5470233206125366, 48.83832110319104]],
    [ "Enedis",[-1.5575378502276087, 48.839568599225586]],
    [ "Enedis",[-1.561212574240694, 48.83669470546803]],
    [ "Enedis",[1.4473791307226649, 43.6353461942868]],
    [ "RTE",[6.137150809177647, 48.645152999765386]],
    [ "Enedis",[6.315495384976526, 48.633614911468804]],
    [ "Enedis",[6.319722499999998, 48.640007]],
    [ "Enedis",[6.326821831629175, 48.63240955623722]],
    [ "Enedis",[6.295538303815581, 48.64470307302597]],
    [ "Enedis",[6.318071999999999, 48.6351655]],
    [ "Enedis",[6.3000445, 48.64233050000001]],
    [ "Enedis",[6.3277562962737335, 48.63888334084679]],
    [ "Enedis",[6.31945626659715, 48.63685863746958]],
    [ "Enedis",[6.331503316831683, 48.6328354059406]],
    [ "Enedis",[6.324086332420093, 48.6342573260274]],
    [ "Enedis",[6.326561628876259, 48.63749023461961]],
    [ "Enedis",[6.321899840544872, 48.63846944663461]],
    [ "Enedis",[6.329385142857143, 48.63425703986712]],
    [ "Enedis",[6.30293614020074, 48.642508421658746]],
    [ "Enedis",[6.311793505153214, 48.63345598817181]],
    [ "Enedis",[6.309690699883314, 48.636059797705165]],
    [ "Enedis",[4.08706468814842, 44.15970447381911]],
    [ "Enedis",[4.0893514999999985, 44.1495865]],
    [ "Enedis",[4.097253865181467, 44.16017561824077]],
    [ "Enedis",[1.4801136597885227, 46.07271132830304]],
    [ "Senlis",[2.607455788251928, 49.203438562074595]],
    [ "Sautillet",[3.110314196538938, 49.39370441871877]],
    [ "réséda",[6.2986933536231895, 49.20440365072464]],
    [ "réséda",[6.307076333333334, 49.20396547692308]],
    [ "réséda",[6.308746499999999, 49.1949165]],
    [ "réséda",[6.3024028476231395, 49.20677901499708]],
    [ "réséda",[6.2922736666666665, 49.20228466666667]],
    [ "réséda",[6.3076083731884065, 49.20525013405797]],
    [ "réséda",[6.293660304107426, 49.207387191943134]],
    [ "réséda",[6.2995754999999996, 49.227242999999994]],
    [ "réséda",[6.296182076859843, 49.207226720098646]],
    [ "Enedis",[1.5690064999999995, 46.054154999999994]],
    [ "SNCF Réseau",[5.698370226115797, 48.96270821495545]],
    [ "SNCF Réseau",[5.554988980850581, 48.95704185114866]],
    [ "SNCF Réseau",[5.393875531567033, 48.971221785776244]],
    [ "SNCF Réseau",[4.694610422519937, 49.02261959810682]],
    [ "SNCF Réseau",[4.96751825, 49.01222735]],
    [ "RTE",[3.296601283407804, 48.77555142316]],
    [ "Enedis",[2.2066677746710823, 48.71504184282271]],
    [ "Enedis",[1.6328263311331135, 47.99672349761642]],
    [ "Poste source de Reinange",[6.2427605850013475, 49.30494513626296]],
    [ "Poste électrique de Basse Ham",[6.228755727449139, 49.370198899259606]],
    [ "Enedis",[2.601751470588235, 48.05651915686274]],
    [ "La Gare",[6.40184866532064, 46.14270396068223]],
    [ "Enedis",[2.879282160792565, 42.678540901630726]],
    [ "Enedis",[6.392079070131055, 46.139414143487826]],
    [ "Enedis",[3.229829060165805, 50.17476207214972]],
    [ "Enedis",[3.2318655194485464, 50.176085133431805]],
    [ "Enedis",[3.2309449010159645, 50.173904811901295]],
    [ "Enedis",[1.5788501617722823, 43.72466650953117]],
    [ "Enedis",[1.579454, 43.710919499999996]],
    [ "Enedis",[1.5788286666666669, 43.706398]],
    [ "Enedis",[1.5749343819277108, 43.70949336626506]],
    [ "Enedis",[1.5810245, 43.70795149999999]],
    [ "Enedis",[1.582402527860696, 43.71932861393035]],
    [ "Enedis",[1.5809834768593602, 43.717990947100894]],
    [ "Pescador",[1.5916447385206547, 43.71639046570604]],
    [ "Enedis",[4.112860193341869, 44.180734572912215]],
    [ "Enedis",[4.107096999999999, 44.1700265]],
    [ "Poste électrique de Lesneven",[-4.294857112876502, 48.573583893682866]],
    [ "Enedis",[3.2277112329559743, 50.175019918742144]],
    [ "Enedis",[-1.0173253127677804, 44.683104154241654]],
    [ "Enedis",[-1.0099718670869444, 44.69063858576307]],
    [ "Enedis",[-1.0077594543776374, 44.686484375395565]],
    [ "Enedis",[-0.9966876096969698, 44.685024656727286]],
    [ "Enedis",[-1.011357899598393, 44.67837078714859]],
    [ "Enedis",[-1.0208714999999997, 44.6886855]],
    [ "Enedis",[-1.0150442922173273, 44.69107865198237]],
    [ "Enedis",[-1.005415999999999, 44.68032099999999]],
    [ "Enedis",[-1.0015418187134515, 44.67923051461989]],
    [ "Enedis",[-1.0165697555152178, 44.68675002552001]],
    [ "Enedis",[-1.0197410000000007, 44.69072150000001]],
    [ "Enedis",[-1.0162685, 44.6829535]],
    [ "Enedis",[-0.9997485, 44.68521199999999]],
    [ "Enedis",[-1.0074362485875705, 44.67880605084746]],
    [ "Enedis",[-1.0041391638676844, 44.68388849058525]],
    [ "Enedis",[-1.0127280723876237, 44.6880612084063]],
    [ "Enedis",[-1.0164598781648266, 44.685196532497876]],
    [ "Enedis",[3.2293904851831394, 50.17271311062028]],
    [ "Enedis",[6.359963546889889, 45.370963305158575]],
    [ "Bourg",[5.765397631392828, 46.35663369773806]],
    [ "Enedis",[5.759800829367452, 46.35218245777402]],
    [ "Enedis",[3.2240523660523164, 50.17599277997665]],
    [ "Enedis",[3.223083747179827, 50.17650244105287]],
    [ "Enedis",[3.2248744999999994, 50.174316000000005]],
    [ "Enedis",[3.2237457087867782, 50.17517697339782]],
    [ "Poste Source Courtine",[4.794557677016143, 43.92670636089981]],
    [ "Enedis",[-2.7430784999999998, 47.9783975]],
    [ "Enedis",[-2.748086727060818, 47.97709413329095]],
    [ "Enedis",[-2.748240685585585, 47.9801371072072]],
    [ "Enedis",[5.1482375000000005, 47.005354999999994]],
    [ "Enedis",[5.1534482799263355, 47.00604588029466]],
    [ "Enedis",[5.156413500000001, 47.0024225]],
    [ "Enedis",[5.148523149999999, 47.00124135000001]],
    [ "Enedis",[5.14562003695032, 46.999653674445845]],
    [ "Enedis",[5.14950711827451, 46.99703374007843]],
    [ "Enedis",[5.1543030000000005, 46.99754250000001]],
    [ "Enedis",[5.149465158449192, 46.99279697254437]],
    [ "Enedis",[3.399877215679137, 49.20036720207316]],
    [ "Enedis",[4.774646120337076, 45.75773864317451]],
    [ "Enedis",[6.502898500000001, 48.196030750000006]],
    [ "Enedis",[2.5794586422918644, 48.08425625989236]],
    [ "Enedis",[5.631739074460542, 44.91126700268507]],
    [ "Enedis",[6.5120461689272515, 48.49514744348542]],
    [ "Poste électrique de Novion",[2.3041709015989817, 48.913952126155124]],
    [ "RTE",[-2.054222959674664, 46.79411241006941]],
    [ "Enedis",[1.8904100973958333, 47.84051309479167]],
    [ "Enedis",[1.8590461143773382, 47.868439171922326]],
    [ "Les jésuites",[1.887178102705589, 47.86635675074165]],
    [ "Enedis",[4.806652251572327, 47.32740206918239]],
    [ "Village",[4.799033499999999, 47.3245495]],
    [ "Poste électrique de Montagny-Les Lanches",[6.056997510518108, 45.865174059452166]],
    [ "RTE",[3.7032819797852166, 46.54313809611034]],
    [ "Enedis",[4.82744119584129, 47.32963285338426]],
    [ "Clos du bas",[4.822751795180722, 47.328529196787144]],
    [ "Enedis",[3.2318662535408427, 50.17961969573452]],
    [ "Enedis",[3.398400268697106, 50.298786063951574]],
    [ "GreenAlp",[6.349560500000001, 45.52375025]],
    [ "Enedis",[3.38506556017316, 50.30595816928686]],
    [ "Enedis",[1.8867399999999999, 47.862478499999995]],
    [ "Enedis",[1.8893535, 47.86372849999999]],
    [ "Enedis",[6.2879662, 45.440185]],
    [ "Enedis",[6.285447039612188, 45.4301315677747]],
    [ "Enedis",[1.8762812309124768, 47.86521793854749]],
    [ "Enedis",[1.875027256910569, 47.867994941322024]],
    [ "Enedis",[1.8925379337097683, 47.87764910941088]],
    [ "Enedis",[1.894873332089933, 47.8745789172543]],
    [ "Enedis",[1.9088906530389758, 47.85513602861815]],
    [ "Enedis",[1.9095088612612616, 47.84393518738738]],
    [ "Enedis",[1.9133606229946518, 47.83937113190732]],
    [ "Enedis",[1.9194752436761404, 47.84322714688934]],
    [ "Enedis",[1.9225525064171944, 47.84230381816542]],
    [ "Enedis",[1.8679992643404704, 47.86786304943166]],
    [ "RTE",[-1.2833663679732965, 47.507828586326355]],
    [ "Poste de Nort-sur-Erdre",[-1.5105478286874285, 47.4546152718501]],
    [ "Enedis",[1.8802428577075099, 47.865622301712776]],
    [ "Enedis",[1.9163424307832428, 47.847354218761375]],
    [ "RTE",[2.3069944957949806, 47.05057887393004]],
    [ "Poste Électrique Les Rivages",[2.368306286840368, 47.094829493371456]],
    [ "Enedis",[6.20907255, 46.1922724]],
    [ "Enedis",[3.231831354685483, 50.17740923491976]],
    [ "Enedis",[3.2317541098758262, 50.17743146854172]],
    [ "Enedis",[6.279528255322632, 45.40077431694681]],
    [ "Enedis",[6.2783384, 45.398798199999995]],
    [ "Enedis",[4.873765716254246, 47.34111109767103]],
    [ "Enedis",[3.2388327938101207, 50.17470764659137]],
    [ "Enedis",[3.2373407508090617, 50.176982941747575]],
    [ "Enedis",[3.2340363628640425, 50.17636791837855]],
    [ "Enedis",[6.304315243933014, 45.660989895669466]],
    [ "Enedis",[3.3473088576051784, 50.31144922060409]],
    [ "Enedis",[3.3502632329749105, 50.312624342188485]],
    [ "Enedis",[3.362842490317089, 50.31706190317089]],
    [ "Enedis",[3.3579608194054003, 50.31639445676804]],
    [ "Enedis",[3.3538948460622304, 50.302167633913946]],
    [ "Enedis",[3.354133372063491, 50.30087065291005]],
    [ "Enedis",[3.35065379055007, 50.30113721062529]],
    [ "Enedis",[2.6280886423888776, 50.2038782367985]],
    [ "Enedis",[2.6296949911280105, 50.19642662484158]],
    [ "Écoles Olivet",[1.900073723809524, 47.863963937414965]],
    [ "Enedis",[6.331187756218906, 45.64334591044775]],
    [ "Enedis",[3.2340451540084385, 50.17379755168777]],
    [ "Enedis",[3.235787838666665, 50.174641189999996]],
    [ "Enedis",[0.026741433104177367, 48.198999731535345]],
    [ "Enedis",[0.020924842433697063, 48.199668828913154]],
    [ "Enedis",[3.2365075381047053, 50.170886903909874]],
    [ "Enedis",[6.485500750000001, 44.831390000000006]],
    [ "Enedis",[6.481524099999998, 44.817612499999996]],
    [ "Enedis",[3.2420583208333333, 50.17064652255434]],
    [ "Enedis",[3.2387303055804235, 50.16755296239927]],
    [ "Enedis",[5.1721542, 43.380819599999995]],
    [ "Enedis",[5.127939649999998, 43.393084550000005]],
    [ "Enedis",[5.13967657387041, 43.39361510546491]],
    [ "Enedis",[5.1701169999999985, 43.38348450000001]],
    [ "Enedis",[5.1560787372549015, 43.383488121568625]],
    [ "Enedis",[5.142443699999999, 43.39335930000001]],
    [ "Enedis",[5.130529950000001, 43.39757590000001]],
    [ "Enedis",[5.17584852812986, 43.38512649348473]],
    [ "Enedis",[5.160448150000001, 43.386703049999994]],
    [ "Enedis",[5.1455493500000005, 43.392927750000005]],
    [ "Enedis",[5.1410205000000015, 43.3948645]],
    [ "Enedis",[5.169806600000002, 43.37920524999999]],
    [ "Enedis",[5.1742681500000005, 43.37806095]],
    [ "Enedis",[5.169951545114361, 43.37990898186642]],
    [ "Enedis",[5.1378146, 43.392468550000004]],
    [ "Enedis",[5.14228505, 43.38998725000001]],
    [ "Enedis",[5.1648833000000005, 43.3835181]],
    [ "Enedis",[5.186668764893134, 43.38043254297407]],
    [ "Enedis",[5.1225378, 43.394805950000006]],
    [ "Enedis",[5.1716156430539595, 43.390126623188884]],
    [ "Enedis",[5.159090775956284, 43.384052622950826]],
    [ "Enedis",[5.172934853683668, 43.38959938897476]],
    [ "Enedis",[5.1892265, 43.3789695]],
    [ "Enedis",[5.11629016999111, 43.39721549719112]],
    [ "Enedis",[5.1658325, 43.381722999999994]],
    [ "Enedis",[5.16379315579497, 43.38506547203183]],
    [ "Enedis",[5.1680037500000005, 43.3794871]],
    [ "Enedis",[5.141453028932366, 43.39193036220672]],
    [ "Enedis",[5.114488724913023, 43.40334373920815]],
    [ "Enedis",[5.109683087095103, 43.399122274471424]],
    [ "Enedis",[5.188049356315035, 43.38693417522114]],
    [ "Enedis",[5.16984861919084, 43.38637129441744]],
    [ "Enedis",[5.178236437325904, 43.3772197455896]],
    [ "Enedis",[5.16241375, 43.3910722]],
    [ "Enedis",[5.163104999999998, 43.383546]],
    [ "Enedis",[5.170223999999999, 43.389501]],
    [ "Enedis",[5.136255545845718, 43.394194972235]],
    [ "Enedis",[5.148920252111668, 43.404861682474014]],
    [ "Enedis",[5.137757304237825, 43.39335689464173]],
    [ "Enedis",[5.162908450000001, 43.38101135000001]],
    [ "Enedis",[5.171838774205268, 43.387825604178026]],
    [ "Enedis",[5.15128557638724, 43.39158035899913]],
    [ "Enedis",[5.11173435, 43.39754370000001]],
    [ "Enedis",[5.16597465, 43.38650325000001]],
    [ "Enedis",[5.13532335, 43.39616695]],
    [ "Enedis",[5.17105895, 43.38591325000001]],
    [ "Enedis",[5.1449875, 43.391394]],
    [ "Enedis",[5.173495657055718, 43.379678219760514]],
    [ "Enedis",[5.170484, 43.39005249999999]],
    [ "Enedis",[5.139894957866326, 43.391033534402546]],
    [ "Enedis",[5.14384615, 43.390245349999994]],
    [ "Enedis",[5.176765227043602, 43.37643496713728]],
    [ "Enedis",[5.150967130900378, 43.39112217156211]],
    [ "Enedis",[5.153015267401101, 43.38629168936738]],
    [ "Enedis",[5.173773000000001, 43.381972999999995]],
    [ "Enedis",[5.166838724544479, 43.38489390996785]],
    [ "Enedis",[5.147280249999999, 43.3905583]],
    [ "Enedis",[5.164221068761802, 43.38817402304293]],
    [ "Enedis",[5.176665943277689, 43.385437707054386]],
    [ "Enedis",[5.155410472396567, 43.388908233095485]],
    [ "Enedis",[5.155918850000001, 43.38755225]],
    [ "Enedis",[5.175024985435753, 43.38389077096547]],
    [ "Enedis",[5.107496224393251, 43.40014195406561]],
    [ "Enedis",[5.161760224489796, 43.388867891156465]],
    [ "Enedis",[5.16991105074588, 43.38843841668296]],
    [ "Enedis",[5.170339528384405, 43.393736489584235]],
    [ "Enedis",[5.176831880190226, 43.39164554183536]],
    [ "Enedis",[2.2628374792899404, 49.814944611439834]],
    [ "Enedis",[2.261558830275661, 49.80956451105467]],
    [ "Enedis",[6.189324857305038, 49.28528520015719]],
    [ "Enedis",[6.19008, 49.282507]],
    [ "Enedis",[6.194342000000001, 49.273949500000015]],
    [ "Enedis",[5.236316922480621, 43.39029915245478]],
    [ "Enedis",[5.237061341693957, 43.39227123606241]],
    [ "Enedis",[5.23925425382263, 43.39458017431192]],
    [ "Enedis",[5.196988679649529, 43.37910569701746]],
    [ "Enedis",[5.226984565498551, 43.39336527381345]],
    [ "Enedis",[5.237347224761904, 43.387902329523804]],
    [ "Enedis",[5.241903084288716, 43.39172843763821]],
    [ "Enedis",[5.2324665, 43.388083]],
    [ "Enedis",[5.234814922791389, 43.391813654788415]],
    [ "Enedis",[5.211759149956766, 43.3953608833553]],
    [ "Enedis",[5.235434500000001, 43.3907455]],
    [ "Enedis",[5.231515699999999, 43.390282850000006]],
    [ "Enedis",[5.232451907555049, 43.39537065280942]],
    [ "Enedis",[5.237034250269687, 43.39643732384035]],
    [ "Enedis",[5.233481903116, 43.38857782770359]],
    [ "Enedis",[5.232261350840819, 43.38802223888505]],
    [ "Enedis",[5.202442229038114, 43.39416224744638]],
    [ "Enedis",[5.2391118, 43.38908515]],
    [ "Enedis",[5.2024748999999995, 43.380346349999996]],
    [ "Enedis",[5.238597557478944, 43.39070451608032]],
    [ "Enedis",[5.236138191489362, 43.388245737588655]],
    [ "Enedis",[5.232699774011301, 43.38551361581921]],
    [ "Enedis",[5.200611355532622, 43.380009871092774]],
    [ "Enedis",[5.239276663412871, 43.394162065075925]],
    [ "Enedis",[5.219747569007264, 43.396222271993544]],
    [ "Enedis",[5.235734126787058, 43.39294933182844]],
    [ "Enedis",[5.232130848861283, 43.392309209109726]],
    [ "Enedis",[5.197288657484777, 43.377579678403364]],
    [ "Enedis",[5.235207599623289, 43.38732398343037]],
    [ "Enedis",[5.230790085910653, 43.3874798281787]],
    [ "Enedis",[5.197732615196791, 43.37589718054995]],
    [ "Enedis",[5.2278579999999994, 43.390606999999996]],
    [ "Enedis",[5.228271, 43.39441850000001]],
    [ "Enedis",[5.236017729282935, 43.38552745329712]],
    [ "Enedis",[5.240709500000001, 43.38934555]],
    [ "Enedis",[5.227286340873635, 43.39684399167967]],
    [ "Enedis",[5.270979508043678, 43.393473018738305]],
    [ "Enedis",[2.3248200000000003, 49.815233000000006]],
    [ "Enedis",[2.3213398529741855, 49.814753841750836]],
    [ "Enedis",[2.319065499999999, 49.820410499999994]],
    [ "Enedis",[2.3166842819966873, 49.82029044499645]],
    [ "Enedis",[2.3157513343535934, 49.815984480104945]],
    [ "Enedis",[2.3187876659983284, 49.81839499097745]],
    [ "Enedis",[2.3128437192214113, 49.81606618880778]],
    [ "Enedis",[6.2257952, 46.19337465000002]],
    [ "Enedis",[3.239587884539767, 50.1797062057194]],
    [ "Enedis",[3.237975871046229, 50.17861342335766]],
    [ "Enedis",[3.2358855392831605, 50.181977996493515]],
    [ "SNCF Réseau",[6.1472113, 47.44118765]],
    [ "SNCF Réseau",[6.40060922542787, 47.514801813600265]],
    [ "Enedis",[2.5223305, 48.001009]],
    [ "Enedis",[2.5402268165625723, 48.00128648438584]],
    [ "SNCF Réseau",[5.624537615238226, 47.238789778603994]],
    [ "Enedis",[1.3913784999999996, 43.548908999999995]],
    [ "Jean Jaurès",[6.22144155, 46.197681]],
    [ "Enedis",[3.2473643334605278, 50.1752819978801]],
    [ "Enedis",[6.974659766127612, 48.290548402464616]],
    [ "Enedis",[6.973657036053594, 48.2863251585465]],
    [ "Enedis",[6.976867299882457, 48.292875321386695]],
    [ "Enedis",[6.966794593557422, 48.27901948431373]],
    [ "Enedis",[6.967037507385514, 48.27610211621985]],
    [ "Enedis",[6.968608399516908, 48.2817017652174]],
    [ "Enedis",[6.958466733081761, 48.27308720528301]],
    [ "Enedis",[6.964201417661098, 48.27976148886238]],
    [ "Enedis",[6.95641075479282, 48.27656355285793]],
    [ "Enedis",[6.948962226874003, 48.2821919046252]],
    [ "Enedis",[6.947245571989593, 48.277513333002126]],
    [ "Enedis",[6.954635138250563, 48.27500096989911]],
    [ "RTE",[-0.009413440784805662, 48.74304660035789]],
    [ "RTE",[0.33017253088618403, 47.55263429836724]],
    [ "Enedis",[3.845806321568629, 48.22852301176471]],
    [ "Enedis",[3.856900403834262, 48.22336521170893]],
    [ "Enedis",[3.2481600499999996, 50.174321850000005]],
    [ "Enedis",[3.2477113333333336, 50.169247000000006]],
    [ "Enedis",[-4.482357258650305, 48.38365467350745]],
    [ "Enedis",[3.3370861918020105, 50.30859366821345]],
    [ "Enedis",[3.3291050579710144, 50.30781244927535]],
    [ "Enedis",[3.343854, 50.312372]],
    [ "Enedis",[3.3368011511343805, 50.303807413263534]],
    [ "Enedis",[3.3397040916750926, 50.3060454115268]],
    [ "Enedis",[3.332603940639269, 50.31311517503805]],
    [ "Enedis",[3.3327851233474326, 50.30335978328186]],
    [ "Enedis",[3.3294518390393457, 50.31054216879578]],
    [ "RTE",[-1.3614028794525574, 47.714436967413924]],
    [ "Poste électrique de Bagnols-sur-Cèze",[4.648194992686098, 44.20036782768845]],
    [ "Enedis",[1.1430164999999999, 42.9930422]],
    [ "RTE",[-0.013403098748813005, 42.871041491132445]],
    [ "Poste de Lunes",[3.00371533905661, 43.16935042701187]],
    [ "Enedis",[5.785386499378095, 44.903081697055995]],
    [ "Enedis",[3.3049078475750577, 50.305876350269436]],
    [ "Enedis",[3.3079451172766636, 50.304285160173166]],
    [ "Enedis",[3.3060524068298314, 50.3077626373547]],
    [ "Enedis",[3.3054240087326936, 50.31035270223643]],
    [ "Enedis",[3.290339895716947, 50.33579440720051]],
    [ "Enedis",[-0.270596645274213, 47.45820382808246]],
    [ "Enedis",[-0.268929, 47.459697500000004]],
    [ "Enedis",[-0.30143412892202576, 47.46164830537433]],
    [ "Enedis",[-0.2730616694594066, 47.46093449690804]],
    [ "Enedis",[-0.2869520732136565, 47.459460762407616]],
    [ "Enedis",[-0.2844692923777019, 47.457707352673495]],
    [ "Enedis",[-0.2761995196078438, 47.45808304901961]],
    [ "Enedis",[3.2441060000000004, 50.163773500000005]],
    [ "Poste Électrique Saint-Germain-du-Puy",[2.4976924716168925, 47.1124681066576]],
    [ "Enedis",[3.2349787588185657, 50.167000283206754]],
    [ "Enedis",[2.9013612058165545, 42.66921709917972]],
    [ "Enedis",[6.203284682624672, 48.120781452474496]],
    [ "Enedis",[2.5335289013212905, 48.05595478976418]],
    [ "Enedis",[3.6075597144921843, 50.366062476941266]],
    [ "Enedis",[3.607478287520452, 50.366064520452184]],
    [ "Enedis",[3.6134827943805883, 50.380882818646235]],
    [ "Enedis",[3.6000568695652175, 50.3846046350461]],
    [ "Enedis",[3.591806373929429, 50.38053457571086]],
    [ "Enedis",[3.5996886343173853, 50.4000228189119]],
    [ "Enedis",[3.596463640301318, 50.38811680163214]],
    [ "Enedis",[3.597126958282208, 50.39051882658487]],
    [ "Enedis",[3.6006251234567914, 50.39759614814815]],
    [ "Enedis",[3.5824387446393757, 50.38187316321106]],
    [ "Enedis",[3.596008513909224, 50.39772816642263]],
    [ "Enedis",[3.601631102206736, 50.39245080081301]],
    [ "Enedis",[3.604946967653645, 50.38786234005143]],
    [ "Enedis",[3.6032833959333517, 50.39484330226866]],
    [ "Enedis",[3.5993420217172902, 50.38210110889558]],
    [ "Enedis",[3.604221165137615, 50.38594416972477]],
    [ "Enedis",[3.590168024952015, 50.39330745681381]],
    [ "Enedis",[3.5901732096436065, 50.38768708595389]],
    [ "Enedis",[3.6004867224958943, 50.38377615270936]],
    [ "Enedis",[3.583918915444016, 50.39079317503218]],
    [ "Enedis",[3.6043598302813447, 50.382624494422224]],
    [ "Enedis",[3.5832340000000005, 50.38083]],
    [ "Enedis",[3.589647000535047, 50.38406833906596]],
    [ "Enedis",[3.5963545586715053, 50.39274204234026]],
    [ "Enedis",[3.5924723526483477, 50.39502855575468]],
    [ "Enedis",[3.593103602010644, 50.38210151586833]],
    [ "Enedis",[4.779163999999998, 45.075407999999996]],
    [ "Enedis",[4.8208332406586445, 45.07698406438674]],
    [ "Enedis",[4.810615399176954, 45.0822359643347]],
    [ "Enedis",[4.8133718394698075, 45.074545003600065]],
    [ "Enedis",[4.8186227516339875, 45.08884708496732]],
    [ "Enedis",[4.820335067813513, 45.084832746447276]],
    [ "Enedis",[4.804143696548171, 45.07355516898505]],
    [ "Enedis",[4.817681142989786, 45.07661670566388]],
    [ "Enedis",[4.799434818548387, 45.07210951657706]],
    [ "Enedis",[4.812970670152266, 45.07577469235003]],
    [ "Enedis",[4.809757362573098, 45.0762688517671]],
    [ "Enedis",[4.784173487893083, 45.06808358537736]],
    [ "Enedis",[1.897000189677758, 47.86295053488777]],
    [ "Enedis",[1.8912986543209875, 47.865397345679014]],
    [ "Enedis",[1.8948418912655973, 47.87714365418895]],
    [ "Enedis",[3.225992333430544, 50.16943250228443]],
    [ "Enedis",[3.2229340010256404, 50.17363476512821]],
    [ "Enedis",[1.9005424999999987, 47.875615499999995]],
    [ "Enedis",[1.921798, 47.84715549999999]],
    [ "Enedis",[1.9211742089460784, 47.85477783517157]],
    [ "RTE",[2.366637859394179, 43.286901343825555]],
    [ "Enedis",[2.973184, 42.611151500000005]],
    [ "Enedis",[3.595102342534504, 50.35009733918862]],
    [ "Enedis",[3.595720554764325, 50.353315420820614]],
    [ "Enedis",[3.5575278731117823, 50.38813582578046]],
    [ "Enedis",[3.5556995000000007, 50.384842]],
    [ "Enedis",[3.550849515380623, 50.393440167222586]],
    [ "Enedis",[3.556253723521018, 50.38289517429481]],
    [ "Enedis",[3.548615479423868, 50.37146047530864]],
    [ "Enedis",[3.574504, 50.3773855]],
    [ "Enedis",[3.5544465, 50.37553449999999]],
    [ "Enedis",[3.5523628117443877, 50.377206036269435]],
    [ "Enedis",[3.559987816143497, 50.38303252658552]],
    [ "Enedis",[3.553366267531219, 50.384451219180264]],
    [ "Enedis",[3.5577236408317576, 50.389794884814116]],
    [ "Enedis",[3.5558327561385803, 50.36769205751766]],
    [ "Enedis",[3.5611300000000004, 50.36936]],
    [ "Enedis",[3.5630000840241536, 50.37096711943506]],
    [ "Enedis",[3.5696955, 50.373016500000006]],
    [ "Enedis",[3.561410621399177, 50.36597713466723]],
    [ "Enedis",[3.566261106140351, 50.36943570964912]],
    [ "Enedis",[3.563458, 50.3667805]],
    [ "Enedis",[3.5568125, 50.365493]],
    [ "Enedis",[3.5583883383084585, 50.36796206484527]],
    [ "Enedis",[3.5626384999999994, 50.3642895]],
    [ "Enedis",[3.565393939771232, 50.370117758781255]],
    [ "Enedis",[3.2125592038300677, 50.17780002949592]],
    [ "Enedis",[3.210686543063774, 50.174220377821605]],
    [ "Enedis",[3.217883695291755, 50.17361547302636]],
    [ "Enedis",[3.21937123281865, 50.17604134675547]],
    [ "Enedis",[3.2154513999999996, 50.1753825]],
    [ "Enedis",[3.2165564789024983, 50.173032055806274]],
    [ "Enedis",[3.2134673492723493, 50.17372146621623]],
    [ "Enedis",[5.000265149999998, 43.40903265]],
    [ "RTE",[-0.035469548578017034, 42.90596141559329]],
    [ "Enedis",[3.2289488121363257, 50.18313346467165]],
    [ "Enedis",[3.2223418920335427, 50.178860512578616]],
    [ "Enedis",[3.2234233333333333, 50.18281713333333]],
    [ "Enedis",[3.2228116166821894, 50.18158420252509]],
    [ "Enedis",[3.6080711693404632, 50.46794598633393]],
    [ "Enedis",[3.5987341616832778, 50.46973006423035]],
    [ "Enedis",[3.595123499999999, 50.465912]],
    [ "Enedis",[3.6017307976878623, 50.468191306358385]],
    [ "Enedis",[3.584189456475584, 50.4641516693206]],
    [ "Enedis",[3.58812580995324, 50.45493014595858]],
    [ "Enedis",[3.594420167108753, 50.45744749307398]],
    [ "Enedis",[3.588930920245399, 50.45817817382413]],
    [ "Enedis",[3.5863563943355126, 50.456673298474946]],
    [ "Enedis",[3.5906195000000003, 50.4585725]],
    [ "Enedis",[3.580662130616026, 50.472008651844995]],
    [ "Enedis",[3.5794044384250214, 50.47536210611561]],
    [ "Enedis",[3.5922793285262493, 50.44877748690702]],
    [ "Enedis",[3.8137495000000006, 49.68109549999999]],
    [ "Enedis",[3.6372339999999994, 50.443673000000004]],
    [ "Enedis",[3.641277159333333, 50.443401976]],
    [ "Enedis",[3.6332672703442883, 50.43948018035994]],
    [ "Enedis",[3.23387155641445, 50.182439823646]],
    [ "Enedis",[3.23783072339421, 50.18393736397068]],
    [ "Enedis",[3.2312725, 50.1846205]],
    [ "Enedis",[3.231519539499037, 50.18587614258189]],
    [ "Enedis",[5.20766265, 43.3324116]],
    [ "Enedis",[5.192509, 43.33608516666667]],
    [ "Enedis",[5.193217687127024, 43.352966017902816]],
    [ "Enedis",[5.2020432057647215, 43.35674555357882]],
    [ "Enedis",[5.198184833615014, 43.337675043036235]],
    [ "Enedis",[5.193912, 43.33235800000001]],
    [ "Enedis",[5.21224833066167, 43.35157519548013]],
    [ "Enedis",[5.209646116402116, 43.35574781481481]],
    [ "Enedis",[5.1956940000000005, 43.352186999999994]],
    [ "Enedis",[5.210358683232931, 43.35235996084338]],
    [ "Enedis",[5.201118500000001, 43.3520635]],
    [ "Enedis",[5.201684499999999, 43.3446865]],
    [ "Enedis",[5.2132885, 43.332006]],
    [ "Enedis",[5.19734305, 43.340623400000005]],
    [ "Enedis",[5.1964305683576955, 43.37981278819146]],
    [ "Enedis",[5.216217834344966, 43.35085998179059]],
    [ "Enedis",[5.191214247863248, 43.33078457264958]],
    [ "Enedis",[5.198076519451309, 43.33474080441436]],
    [ "Enedis",[5.204181652054541, 43.348990082267264]],
    [ "Enedis",[5.200889499999999, 43.3544045]],
    [ "Enedis",[5.216107999999999, 43.33150500000001]],
    [ "Enedis",[5.213956110492081, 43.35240325301659]],
    [ "Enedis",[6.924804265919282, 48.285136094768305]],
    [ "Enedis",[6.9253929364583335, 48.28893248463543]],
    [ "Enedis",[6.907886691108302, 48.279986743784235]],
    [ "Enedis",[6.939736470000001, 48.28270547833333]],
    [ "Enedis",[6.937897301263821, 48.28530224944707]],
    [ "Enedis",[6.9023699999999995, 48.2768815]],
    [ "Enedis",[6.939146040347609, 48.284903666190765]],
    [ "Enedis",[3.2379842411347513, 50.18376594468086]],
    [ "Enedis",[6.940846031335387, 48.285753484421825]],
    [ "Enedis",[5.8745555000000005, 45.07146605]],
    [ "Enedis",[3.5201632382948977, 50.39983388050314]],
    [ "Enedis",[3.548652343313373, 50.39099698203593]],
    [ "Enedis",[3.5408160816714145, 50.39563202184236]],
    [ "Enedis",[3.52688760175138, 50.39934236131735]],
    [ "Enedis",[3.5424425726604913, 50.40270531544767]],
    [ "Enedis",[3.53461233836478, 50.40454602641509]],
    [ "Enedis",[3.5356475000000014, 50.40383800000001]],
    [ "Enedis",[3.537392299451152, 50.40103241492864]],
    [ "Enedis",[3.5379255, 50.396141]],
    [ "Enedis",[3.5464580990600134, 50.39465986623282]],
    [ "Enedis",[6.927709709994222, 48.29048863835933]],
    [ "Enedis",[3.529390715549936, 50.391787447450476]],
    [ "Enedis",[3.534761167428475, 50.39029349703741]],
    [ "Enedis",[3.5490266650199955, 50.409884514545595]],
    [ "Enedis",[3.557441930232558, 50.41030132364342]],
    [ "Enedis",[3.5611235000000008, 50.409395]],
    [ "Enedis",[3.5458489999999996, 50.4078785]],
    [ "Enedis",[3.555697534004858, 50.40466437798257]],
    [ "Enedis",[3.5473443335473283, 50.40348448455667]],
    [ "Enedis",[3.547051992000704, 50.40688889697609]],
    [ "Enedis",[6.930218392008093, 48.30981865048052]],
    [ "Enedis",[6.919577849751264, 48.313052204414554]],
    [ "Enedis",[6.927737001749973, 48.310953160840405]],
    [ "Enedis",[6.933238153333332, 48.31099056]],
    [ "Enedis",[6.931358279180151, 48.30651993840345]],
    [ "Enedis",[6.927505848361069, 48.30303587897299]],
    [ "Enedis",[6.950847494332707, 48.300828555687566]],
    [ "Enedis",[6.949227300000001, 48.29051430000001]],
    [ "Poste électrique de Plouvara",[-2.905488050000001, 48.4901863]],
    [ "Enedis",[3.1727638434564627, 47.389662697848976]],
    [ "RTE",[2.9343178770828997, 47.41632878743839]],
    [ "Enedis",[3.2424482178477687, 50.181427152230974]],
    [ "Enedis",[3.245212616536458, 50.181081654513896]],
    [ "Enedis",[3.2451748357194856, 50.18378966812263]],
    [ "Enedis",[6.932892000000001, 48.290728]],
    [ "Enedis",[3.249278063748081, 50.178970529646705]],
    [ "Enedis",[3.2455392226120865, 50.17985941039636]],
    [ "Enedis",[5.7887985, 46.35888425]],
    [ "Enedis",[5.78626393352488, 46.361627150299576]],
    [ "Les Ronchaux",[5.7937457000000006, 46.4951792]],
    [ "Enedis",[5.792889840895528, 46.50659244590588]],
    [ "Enedis",[5.71578725, 46.439329]],
    [ "Salle des Fêtes",[5.725164899023485, 46.42975371695794]],
    [ "En Bonneille",[5.716344372322149, 46.44226945263355]],
    [ "Verpillat",[5.726345500000001, 46.42239375]],
    [ "En Pont",[5.716324878898598, 46.4313443010061]],
    [ "En Barges",[5.7261475, 46.4247849]],
    [ "École",[5.726414008035241, 46.431113472039534]],
    [ "Enedis",[5.7154518, 46.43951839999999]],
    [ "Champ Vital",[5.725763, 46.42296349999999]],
    [ "ZAD Les Cytises",[5.719277000000002, 46.43739075]],
    [ "Enedis",[5.7180299, 46.4311251]],
    [ "Les Carres",[5.72542925, 46.4351035]],
    [ "Champ Renard",[5.7233485, 46.418213249999994]],
    [ "Corne au Boeuf",[5.715242753227672, 46.44572463200916]],
    [ "Enedis",[5.731747666666666, 46.43057833333334]],
    [ "ZI Ouest",[5.715210527167822, 46.43786075694883]],
    [ "Enedis",[5.7152702, 46.4157575]],
    [ "Gymnase",[5.722107869879518, 46.43530774056225]],
    [ "Abattoir",[5.7244741999999995, 46.43271334999999]],
    [ "En Braye",[5.714402125782439, 46.434887560651056]],
    [ "Tyr",[5.720011049999999, 46.439852800000004]],
    [ "Clerc",[5.731355950728157, 46.41617342086564]],
    [ "Petit Villars",[5.734769406752402, 46.41272047781836]],
    [ "Grand Villards",[5.73627595, 46.42020659999999]],
    [ "Grand Chatel",[5.729098204276692, 46.38554871665423]],
    [ "Clerc Client",[5.731338943739769, 46.41615498782979]],
    [ "Villards d'Héria Mairie",[5.7346654740518765, 46.41547110534954]],
    [ "Enedis",[3.575064, 50.4108375]],
    [ "Enedis",[3.562461981445038, 50.41216210861213]],
    [ "Enedis",[3.5537911376979525, 50.412166630166084]],
    [ "Enedis",[6.0703049, 45.093305650000005]],
    [ "Enedis",[3.553060999999999, 50.42474350000001]],
    [ "Enedis",[3.5587516420906034, 50.41955247661178]],
    [ "Enedis",[3.5482522474720595, 50.4264804450949]],
    [ "Enedis",[3.5592477035791292, 50.425226561103926]],
    [ "Enedis",[3.555074415333841, 50.43030954303124]],
    [ "Enedis",[3.554598163735014, 50.42017318675068]],
    [ "Enedis",[3.550021821818182, 50.42663002424243]],
    [ "Enedis",[3.553692848909658, 50.42195031308412]],
    [ "Enedis",[3.5505702075978114, 50.41991045917209]],
    [ "Enedis",[5.720284955855855, 46.50033339819819]],
    [ "Village",[5.727910172550755, 46.49837165397526]],
    [ "La Croix",[5.71327215, 46.464049200000005]],
    [ "Le Muret",[5.7174700082314365, 46.46937453390696]],
    [ "Village",[5.711778000000002, 46.465776999999996]],
    [ "Enedis",[5.717916890034792, 46.47028727749647]],
    [ "Enedis",[3.2495443094867813, 50.17611417625714]],
    [ "Enedis",[3.247002666120666, 50.176683506688505]],
    [ "Enedis",[5.685270938230385, 46.46729365831941]],
    [ "Maisod Bourg",[5.689048016084014, 46.47689279034179]],
    [ "Trelachaume",[5.6871999755302225, 46.46988644685398]],
    [ "Enedis",[5.683589660130719, 46.468721557734206]],
    [ "Enedis",[1.5891985, 46.09852349999999]],
    [ "Enedis",[1.1431569377926423, 47.775393315273135]],
    [ "Château Verrin",[5.7838089, 46.39118125]],
    [ "En Melay",[5.779454361700041, 46.38090682788047]],
    [ "Enedis",[5.797431849652052, 46.37405574988983]],
    [ "Pesse",[5.79421245, 46.3838169]],
    [ "Castor",[5.7813956, 46.38794395]],
    [ "Enedis",[5.78485424549536, 46.386186992044735]],
    [ "Gare Lizon",[5.7981465001044, 46.38309355079648]],
    [ "Bellevue",[5.7812463, 46.38095595000001]],
    [ "Chenavier",[5.782801400000001, 46.38318235]],
    [ "La Fortune",[5.782467100000002, 46.390220750000005]],
    [ "Caton",[5.784235810871532, 46.385840718371526]],
    [ "Bourg Lavans",[5.7829047850994515, 46.38424291337189]],
    [ "Enedis",[5.784247788253061, 46.38585783447598]],
    [ "Auge Bourg",[5.71276824997734, 46.53312353904077]],
    [ "Enedis",[5.69744155, 46.5534047]],
    [ "Coyron Bourg",[5.708343332218525, 46.51245742469272]],
    [ "Enedis",[5.723429212980204, 46.52229436557359]],
    [ "Barésia Bourg",[5.709310016620086, 46.548724000714245]],
    [ "Thoiria bourg",[5.736290044787997, 46.535974794010386]],
    [ "Les Cavets",[5.763160999999999, 46.56583825]],
    [ "Sur la Perrière",[5.7625414999999975, 46.56970225]],
    [ "Hautecour",[5.7668118798805015, 46.56788815461346]],
    [ "Enedis",[5.6758826, 46.388524899999986]],
    [ "Enedis",[5.66419225, 46.38665575]],
    [ "Enedis",[-1.4327429036919639, 46.792676397135196]],
    [ "Poste électrique de Ploufragan",[-2.823987851314818, 48.51315605285948]],
    [ "Lézine du Tilleul",[5.7968485, 46.40913635]],
    [ "Enedis",[5.783234065182614, 46.43225892215729]],
    [ "Ravilloles Hauts",[5.803944704813092, 46.42761791616966]],
    [ "La Perrine",[5.801616, 46.429077500000005]],
    [ "Chemin de la Seigne",[5.79040133047619, 46.40192604571428]],
    [ "Cuttura Bourg",[5.808259491298932, 46.416258506161405]],
    [ "Mayet",[5.798099792630554, 46.39859747963996]],
    [ "ZI Sud Coupure",[5.798219250000001, 46.39878840000001]],
    [ "La Perrena",[5.800018349999998, 46.428184]],
    [ "Enedis",[5.8107754499999995, 46.3959979]],
    [ "Gare",[5.794041000000001, 46.398711649999996]],
    [ "Pré Colas",[5.793669501648032, 46.4037609484618]],
    [ "Jeurre Bourg",[5.705958000000002, 46.36577800000001]],
    [ "Le Troz",[5.705526030615986, 46.36836553100067]],
    [ "Enedis",[5.705282305302651, 46.370329517337005]],
    [ "Enedis",[3.2541691514880062, 50.17389167313626]],
    [ "Enedis",[3.2498694010903417, 50.1747388457944]],
    [ "Enedis",[3.2492077837169986, 50.17802216985304]],
    [ "ZI Curtillet",[5.76923515, 46.38193760000001]],
    [ "Enedis",[5.752658493333333, 46.36553734333332]],
    [ "En Trévie",[5.763212233041328, 46.37503987605371]],
    [ "Enedis",[5.7676757499999995, 46.37538175]],
    [ "Petit Châtel",[5.739945244844718, 46.381181913819404]],
    [ "Enedis",[5.7016106206489665, 46.39169283244837]],
    [ "Bourg",[5.698942370312379, 46.388222007048384]],
    [ "Enedis",[5.852822500000001, 46.3882856]],
    [ "Enedis",[5.8470721021392755, 46.38981701670533]],
    [ "RTE",[1.2832810878833556, 43.51596765537146]],
    [ "RTE",[-1.21207135357858, 48.22597591214505]],
    [ "RTE",[-2.7483975097701396, 48.16811648771984]],
    [ "Poste électrique de Sauveur",[-2.6266974992254384, 48.168217208942714]],
    [ "Enedis",[5.8874165, 46.4873076]],
    [ "Enedis",[5.955022043417665, 46.50778280195401]],
    [ "Enedis",[5.928649681350956, 46.51385840352423]],
    [ "Enedis",[0.020302580800593998, 48.18377665307636]],
    [ "Enedis",[2.8902848, 48.440795449999996]],
    [ "Enedis",[2.869382930755064, 48.463738086187846]],
    [ "Enedis",[2.8685337804107425, 48.4620251796087]],
    [ "Enedis",[5.904053810669846, 46.13314375858481]],
    [ "Enedis",[2.951471301712779, 48.55540290513833]],
    [ "Enedis",[5.924551808669657, 46.19141193273542]],
    [ "Enedis",[5.928498312968917, 46.20683461950697]],
    [ "Enedis",[5.9237852958579875, 46.1876187218935]],
    [ "Enedis",[5.952395377390988, 46.194820748526865]],
    [ "Enedis",[1.9011657661315704, 47.86632544601825]],
    [ "Enedis",[1.9032113944830957, 47.86085688880752]],
    [ "Enedis",[1.9081394393565465, 47.86532957133718]],
    [ "Enedis",[1.9037466, 47.86438884999999]],
    [ "Enedis",[1.8976435632183906, 47.87119411494253]],
    [ "Enedis",[1.9022608219710107, 47.871823988102555]],
    [ "Enedis",[1.9044936773562497, 47.871152147488296]],
    [ "Enedis",[1.9039536000000004, 47.87274440000001]],
    [ "Enedis",[1.9008064000000002, 47.87237375000001]],
    [ "Enedis",[1.903244371801082, 47.87367224497407]],
    [ "Enedis",[1.9049371499999999, 47.87295195]],
    [ "Enedis",[1.9043070408163265, 47.87512233333333]],
    [ "Enedis",[1.9037068195004963, 47.87551539263507]],
    [ "Enedis",[1.910238130629886, 47.868984054912595]],
    [ "Enedis",[1.913512067611456, 47.86977477000294]],
    [ "Enedis",[1.9097655696515392, 47.87146819543254]],
    [ "Enedis",[1.9087047196067517, 47.870679895920276]],
    [ "Enedis",[1.906483511863137, 47.877286522072396]],
    [ "Enedis",[5.903833654218534, 46.1660331715076]],
    [ "Enedis",[2.531023730687153, 47.992486944515576]],
    [ "Enedis",[2.581691941896024, 47.97501662259399]],
    [ "Enedis",[2.5490192671888603, 47.989738848563974]],
    [ "Enedis",[3.098941324079429, 50.31411602660497]],
    [ "Enedis",[3.094317824561404, 50.32189833333333]],
    [ "Enedis",[3.097378104575164, 50.313328228758174]],
    [ "Enedis",[3.0888992375690596, 50.314669052670354]],
    [ "Enedis",[1.9185623501747697, 47.857293087384804]],
    [ "Enedis",[3.250531, 50.17314700000001]],
    [ "Enedis",[3.2538115000000007, 50.1700885]],
    [ "Enedis",[1.8483025905511812, 47.857555141732284]],
    [ "Enedis",[1.8452863610149943, 47.866299850057665]],
    [ "Enedis",[1.8275006712643678, 47.8606870137931]],
    [ "Enedis",[1.8293837078255373, 47.85874903011379]],
    [ "Enedis",[0.33798621347337426, 47.93698400946532]],
    [ "Enedis",[5.831711820908311, 46.09415101285347]],
    [ "Enedis",[5.842058630946582, 46.08958814758889]],
    [ "Enedis",[5.836905330952381, 46.088223980952385]],
    [ "Enedis",[5.8577335, 46.078446500000005]],
    [ "Enedis",[5.839825562661167, 46.086323430290534]],
    [ "Enedis",[5.828902310408068, 46.09693752246675]],
    [ "Enedis",[5.983822500000001, 46.179228]],
    [ "Enedis",[5.976118933767642, 46.18510923995657]],
    [ "Enedis",[5.97133394663573, 46.178051081979895]],
    [ "Enedis",[5.974351601888276, 46.18114622292158]],
    [ "Enedis",[5.8279429850601145, 46.136438782198915]],
    [ "Enedis",[5.846401750000001, 46.12632624999999]],
    [ "Enedis",[2.5022055511870858, 50.35747255593542]],
    [ "Enedis",[3.248345450079271, 50.16212312439556]],
    [ "Enedis",[3.2520020270178995, 50.165406834177645]],
    [ "Enedis",[3.2491378810459266, 50.165098397732855]],
    [ "Enedis",[1.1658892962494734, 49.31979789127686]],
    [ "Enedis",[-0.0739389276043891, 47.62485916274761]],
    [ "Les Roulliers",[5.8282579, 46.497317550000005]],
    [ "Enedis",[5.8291531999999995, 46.49551395]],
    [ "Les Piards",[5.834338491174377, 46.50135932361145]],
    [ "Enedis",[5.8279441499999995, 46.49213279999999]],
    [ "Enedis",[2.570361349031921, 47.909169215593934]],
    [ "Enedis",[2.5677133936651586, 47.90432136500754]],
    [ "Enedis",[2.1114965000000003, 49.2541935]],
    [ "Enedis",[6.44286982840801, 45.64238071750874]],
    [ "Enedis",[6.452746285069283, 45.6138255941149]],
    [ "Enedis",[6.450518022831049, 45.62876205388128]],
    [ "Enedis",[6.440974301671062, 45.636454702726475]],
    [ "Enedis",[6.4366620674931125, 45.644809124885214]],
    [ "Enedis",[6.437694540145985, 45.64239289245743]],
    [ "Marché de Gros",[6.23541947069648, 46.1908378469113]],
    [ "Enedis",[3.235878850846613, 50.160941865454845]],
    [ "Enedis",[3.2403631603952867, 50.16287125807678]],
    [ "Enedis",[3.238799646551724, 50.16549633793103]],
    [ "Enedis",[3.2392233207885304, 50.16345994982078]],
    [ "RTE",[1.7205747432011151, 50.80682510214526]],
    [ "Enedis",[3.2325353604523897, 50.160257161255025]],
    [ "Enedis",[3.230592980053909, 50.16342032955975]],
    [ "Enedis",[1.4813353586760365, 43.6053119836137]],
    [ "Enedis",[3.2287182429792427, 50.167416167684166]],
    [ "Enedis",[3.2283483974023963, 50.16595010522633]],
    [ "Enedis",[3.228147076971158, 50.16596973622646]],
    [ "Enedis",[3.2430333, 50.1860673]],
    [ "Enedis",[3.2420758075258074, 50.19059934665334]],
    [ "Enedis",[3.2433380000000005, 50.1904625]],
    [ "Enedis",[3.237828665846658, 50.186769018040174]],
    [ "Enedis",[3.2400201470588237, 50.19055048039216]],
    [ "Enedis",[3.2388236227265406, 50.18857984339289]],
    [ "Enedis",[3.245068914680483, 50.18899472504319]],
    [ "Enedis",[2.1694264999999993, 47.4553193]],
    [ "Enedis",[2.1758701687830686, 47.45461474867725]],
    [ "Enedis",[2.1733355, 47.4600505]],
    [ "Enedis",[5.657952315030061, 46.39289389456986]],
    [ "Enedis",[5.6547669, 46.3974602]],
    [ "Enedis",[5.647157974389017, 46.388953755714766]],
    [ "Enedis",[2.504458, 47.89479849999999]],
    [ "Enedis",[2.5087895, 47.88390499999999]],
    [ "Enedis",[2.508122858156029, 47.888464452009465]],
    [ "Enedis",[2.528890449799196, 47.847239775100405]],
    [ "Enedis",[2.514468421133594, 47.891637270973845]],
    [ "Enedis",[2.520918454935622, 47.88673182689555]],
    [ "Enedis",[2.5021306708425453, 47.88522075853598]],
    [ "Enedis",[2.517565778618255, 47.88433159640276]],
    [ "Enedis",[2.5126367163120564, 47.88075166666667]],
    [ "Enedis",[2.5161094440243645, 47.88841369124133]],
    [ "Enedis",[2.5928889677419718, 48.776735214565264]],
    [ "Enedis",[2.589530999999999, 48.777813499999986]],
    [ "Enedis",[1.8195179912556851, 47.879143208884216]],
    [ "Enedis",[1.8343949999999989, 47.896044499999995]],
    [ "Enedis",[5.843406062010688, 46.35556317485755]],
    [ "Enedis",[5.865631733118951, 46.36486709883376]],
    [ "Enedis",[5.878716153249851, 46.36919445676804]],
    [ "La Frasnée Les Plaines",[5.793970991102658, 46.5749569924356]],
    [ "Cogna bourg",[5.7559035000000005, 46.57978475]],
    [ "La Frasnée bourg",[5.8110284798117835, 46.558158394989235]],
    [ "Enedis",[6.7947429999999995, 47.643241499999995]],
    [ "Enedis",[5.78974375, 46.60371324999999]],
    [ "Enedis",[5.790589700000001, 46.642069500000005]],
    [ "Enedis",[5.807652, 46.610344999999995]],
    [ "Enedis",[5.828076250115102, 46.600993499946604]],
    [ "RTE",[2.981073159422923, 43.18035653432263]],
    [ "Enedis",[1.8606115, 47.8957635]],
    [ "Enedis",[3.2501539999999998, 50.185517499999996]],
    [ "Enedis",[3.2524574975653744, 50.182645334655845]],
    [ "Enedis",[3.250869390235775, 50.18308885786887]],
    [ "Enedis",[5.205042900000001, 43.406202150000006]],
    [ "Enedis",[5.1984674, 43.40753060000001]],
    [ "Enedis",[5.203126583526242, 43.40623314377433]],
    [ "Enedis",[5.252516568264139, 43.405278757106714]],
    [ "Enedis",[5.20869125, 43.428337049999996]],
    [ "Enedis",[5.2580298999999995, 43.4025253]],
    [ "Enedis",[5.20032443191148, 43.397370520602415]],
    [ "Enedis",[5.2349418000000005, 43.411617299999996]],
    [ "Enedis",[5.21983316009899, 43.4010334900057]],
    [ "Enedis",[5.200599715430474, 43.40498100680517]],
    [ "Enedis",[5.257969384699681, 43.40056890277432]],
    [ "Enedis",[5.195165360879852, 43.43601003413063]],
    [ "Enedis",[5.243007000000001, 43.410481000000004]],
    [ "Enedis",[5.1995843000000015, 43.408243500000005]],
    [ "Enedis",[5.202892346928499, 43.40010996666666]],
    [ "Enedis",[1.8322014999999994, 47.8898295]],
    [ "Enedis",[1.8370689061032857, 47.890522201877936]],
    [ "Enedis",[5.626465876089759, 46.48595355514904]],
    [ "Sarrogna bourg",[5.611377359782998, 46.46745764904025]],
    [ "Enedis",[5.624453318678951, 46.478853295151126]],
    [ "Enedis",[5.605955768802222, 46.45527355391025]],
    [ "Enedis",[5.653085563176507, 46.456756174563985]],
    [ "Pont St Martin",[5.782693646547541, 45.44450809702184]],
    [ "Enedis",[3.249407914191419, 50.18153318811881]],
    [ "Enedis",[3.2542207325279473, 50.17889430709219]],
    [ "Enedis",[5.866419038986411, 46.51720833691706]],
    [ "Enedis",[5.897038749567619, 46.52707603627054]],
    [ "Enedis",[5.918070047462578, 46.55052384702445]],
    [ "Enedis",[5.875444728177008, 46.51765275390887]],
    [ "Enedis",[5.9184280000000005, 46.54569875]],
    [ "La Cuzan",[5.86767953610414, 46.52065842859846]],
    [ "Enedis",[6.31454225, 45.375919100000004]],
    [ "Les Épinettes",[5.607556831602048, 46.52754169837363]],
    [ "Mouret",[5.608585500000001, 46.5258818]],
    [ "Girard Labrosse",[5.60911825, 46.520954249999996]],
    [ "Route de Lons",[5.60422475, 46.533418450000006]],
    [ "Enedis",[5.622285818888004, 46.51514653392388]],
    [ "Mont Teillet",[5.614670600000001, 46.5189364]],
    [ "Barbuise",[5.604406648919346, 46.5279689335969]],
    [ "Prés Millat",[5.60500815, 46.51528399999999]],
    [ "Route de Lons",[5.604242150291702, 46.533444883468675]],
    [ "Le Closey",[5.617424250000001, 46.524746250000014]],
    [ "Grange Magnin",[5.600849749999999, 46.52710175]],
    [ "Champ de Foire",[5.6067420000000014, 46.522428500000004]],
    [ "Lotissement",[5.6110605, 46.5191205]],
    [ "Enedis",[5.6208429624910705, 46.52390169975381]],
    [ "Ets Menouillard",[5.604404469634135, 46.52790540256784]],
    [ "Enedis",[5.673687210462944, 46.48720147076327]],
    [ "Enedis",[3.6386954079264684, 47.914033139272206]],
    [ "Enedis",[3.2575953261455526, 50.1827851150045]],
    [ "Enedis",[3.255201679063361, 50.17904361900826]],
    [ "RTE",[7.160791505692068, 48.40570888930609]],
    [ "Poste électrique du Prionnet",[5.772348660472199, 43.705331425608904]],
    [ "Mairie",[5.859532411100663, 45.29178992745651]],
    [ "Enedis",[2.464457867229255, 46.723432430942346]],
    [ "Enedis",[6.288323432183909, 45.33594623333334]],
    [ "Enedis",[3.7716243139534886, 49.74412234108527]],
    [ "Enedis",[3.7674570236559135, 49.738634832258064]],
    [ "Enedis",[3.7674923777777773, 49.734195296296285]],
    [ "Enedis",[3.7750976400733607, 49.737719854195326]],
    [ "Enedis",[3.772425780167265, 49.735562774193546]],
    [ "Enedis",[3.7661351902661373, 49.7343847276871]],
    [ "Enedis",[3.7648636613818756, 49.74254841945586]],
    [ "Enedis",[3.7727997259007235, 49.740693362819506]],
    [ "Enedis",[2.4415531302991638, 47.99309982830002]],
    [ "Enedis",[2.4371175000000003, 47.987602]],
    [ "Enedis",[2.4470731852305176, 47.98720980946552]],
    [ "Enedis",[2.440472117792932, 47.99098901074603]],
    [ "SNCF Réseau",[2.2696763499999997, 48.732618699999996]],
    [ "Enedis",[3.272149444292029, 50.170519294467304]],
    [ "Enedis",[3.2704983495440736, 50.16783403850051]],
    [ "Enedis",[3.269431206397307, 50.17006883265994]],
    [ "Enedis",[3.2626967552469135, 50.16883333981481]],
    [ "Enedis",[3.2594095000000003, 50.170865]],
    [ "Enedis",[3.2661157244953625, 50.167812154064364]],
    [ "Enedis",[5.740056006169163, 45.45603697115907]],
    [ "Enedis",[5.752964064139286, 45.44217862453115]],
    [ "Enedis",[5.752407709659284, 45.43808591458993]],
    [ "Enedis",[6.36495575, 48.664482]],
    [ "Poste électrique d'Abondance",[55.66733104581174, -21.03568511510112]],
    [ "Centrale du Glando",[6.288978746832541, 45.33198835494019]],
    [ "Enedis",[2.590672150000001, 47.8418941]],
    [ "Enedis",[2.5710175, 47.8579545]],
    [ "Enedis",[7.006781694893059, 48.263994278626505]],
    [ "Enedis",[7.017674265232973, 48.26564446594982]],
    [ "Enedis",[7.011832841269841, 48.26535668783069]],
    [ "SYCTOM",[2.3880045418426676, 48.82072594104976]],
    [ "Enedis",[3.2555358027072754, 50.165650888917085]],
    [ "Enedis",[3.2594025000000006, 50.163230999999996]],
    [ "Enedis",[3.2609844494319757, 50.16115082848434]],
    [ "Enedis",[3.264296177718121, 50.16342852733781]],
    [ "Enedis",[3.2560513223795073, 50.16683802022245]],
    [ "Enedis",[3.271178016825575, 50.16604938773603]],
    [ "Enedis",[3.2603816685116844, 50.15915195571956]],
    [ "Enedis",[3.254540879025239, 50.16102419321149]],
    [ "Enedis",[3.257070595558472, 50.16142031954928]],
    [ "Enedis",[3.2517030841012335, 50.16056466374649]],
    [ "Enedis",[3.2646883134598013, 50.15692377326107]],
    [ "Enedis",[3.262506029516261, 50.15538934745376]],
    [ "Enedis",[3.256073440092432, 50.1578118146736]],
    [ "Enedis",[3.2620891554192233, 50.1579215398773]],
    [ "Enedis",[0.9554816791136644, 43.09746946354809]],
    [ "Roxy",[2.3798706, 48.5267041]],
    [ "Galilée",[6.039162105841926, 45.481552344673545]],
    [ "Marco Polo",[6.02402625, 45.47268749999999]],
    [ "Enedis",[6.035283866151787, 45.47745400403322]],
    [ "Enedis",[6.030121248849623, 45.475090822381745]],
    [ "Enedis",[3.247419283801874, 50.160190118697]],
    [ "Enedis",[3.2449835170867054, 50.15796924420268]],
    [ "Enedis",[3.257883767917757, 50.154926348074866]],
    [ "Enedis",[3.2519791361709074, 50.1586436919439]],
    [ "Sous-station de Suisse",[6.569955078690087, 48.963954314662196]],
    [ "RTE",[1.6712364346959658, 48.1423734227093]],
    [ "Enedis",[3.6666955, 47.308924000000005]],
    [ "Enedis",[3.2362067203085743, 50.15931995335486]],
    [ "Enedis",[3.234255521413597, 50.15821929709493]],
    [ "Poste électrique de Landroff",[6.6108807152410325, 48.95717435484018]],
    [ "Poste électrique de Thaims",[-0.77899189632788, 45.602801763930096]],
    [ "Enedis",[3.6420137428326917, 50.1913242470689]],
    [ "Poste électrique d'Ancerville",[6.408170912699917, 49.04593944841295]],
    [ "Enedis",[3.6482198282828278, 50.18935596969697]],
    [ "Enedis",[2.4363148489208633, 47.99717702877698]],
    [ "Enedis",[2.445809336569579, 47.99528216720605]],
    [ "Enedis",[2.437670191854234, 47.99529598285101]],
    [ "Poste électrique de Woippy",[6.17903340907026, 49.15210827455708]],
    [ "Enedis",[3.22222435253054, 50.15384246073299]],
    [ "Enedis",[3.223976932470831, 50.14881365244299]],
    [ "Enedis",[3.222307265971658, 50.15081794714207]],
    [ "Enedis",[3.219945847417839, 50.15074739436619]],
    [ "Poste électrique de Vuarche",[6.510806421758501, 46.38576286155839]],
    [ "Enedis",[3.6464366235399823, 50.19441782479786]],
    [ "Enedis",[3.6494030232421304, 50.196517303324505]],
    [ "Poste source de Gex",[6.06338567624446, 46.326109411839965]],
    [ "Poste électrique de Ludres",[6.191195835956539, 48.61131632812964]],
    [ "Enedis",[2.6872093091787437, 48.10468716231884]],
    [ "Enedis",[1.036159811594203, 49.43944368985507]],
    [ "Enedis",[1.0097653824160109, 49.402127771741725]],
    [ "Enedis",[1.0469205, 49.451570499999995]],
    [ "Enedis",[1.0480886514170946, 49.45372374641077]],
    [ "Enedis",[1.0405958317250719, 49.4644744372778]],
    [ "Enedis",[2.3889348177777783, 47.99707601511112]],
    [ "Enedis",[2.3623923167979, 47.99762938149607]],
    [ "Enedis",[2.360462656446541, 47.9947547586478]],
    [ "Enedis",[2.3906606666666668, 48.00210535220126]],
    [ "Poste électrique de Goetzenbruck",[7.369542401529359, 48.97489571550699]],
    [ "SER",[7.646784763396369, 48.903186190958834]],
    [ "Super U",[0.35464923762554834, 47.939521082898736]],
    [ "Crapez",[0.36077855, 47.93913315]],
    [ "Le Ruisseau",[0.3545775, 47.939550950000005]],
    [ "Poste électrique de Bitche",[7.385030228420693, 49.063221115853835]],
    [ "Enedis",[0.9453290952380956, 47.82158572380952]],
    [ "Enedis",[0.9446150701203564, 47.827413551282056]],
    [ "Poste électrique de Biberkirch",[7.1001418063945385, 48.67124945336926]],
    [ "Poste électrique d'Auneau",[1.7762427140191626, 48.44302355554013]],
    [ "RTE",[1.6394629248841153, 48.27321205205491]],
    [ "Poste électrique de Maintenon",[1.5950438997598126, 48.57670412198833]],
    [ "Enedis",[2.80289472471416, 48.45288298153035]],
    [ "RTE",[0.29838002536743874, 48.781600479619115]],
    [ "Enedis",[5.6701014999999995, 45.59606915]],
    [ "Enedis",[5.651246649999999, 45.6057536]],
    [ "Enedis",[5.663078799999999, 45.59094399999999]],
    [ "SER",[7.212600137430349, 48.46768701876402]],
    [ "Enedis",[3.218430847948132, 50.15160584655813]],
    [ "Enedis",[3.2157503427771945, 50.15021605981112]],
    [ "Enedis",[3.2115448245878317, 50.14473552347477]],
    [ "Enedis",[3.217008499999999, 50.149339999999995]],
    [ "Enedis",[3.2122297991021322, 50.148418567789]],
    [ "Enedis",[3.2143771945996273, 50.14593040875232]],
    [ "Enedis",[6.854403694076039, 47.45984488594164]],
    [ "Enedis",[6.864253057971015, 47.457395202898546]],
    [ "Enedis",[6.874665953795378, 47.45246972442245]],
    [ "Enedis",[6.863447040426314, 47.467018582873955]],
    [ "Enedis",[6.869141230954532, 47.45994535862692]],
    [ "Enedis",[6.872786999999999, 47.46257749999999]],
    [ "Enedis",[6.856535968603151, 47.464008011231606]],
    [ "Enedis",[6.852584240924092, 47.45444004950496]],
    [ "Enedis",[6.858349000000001, 47.45897850000001]],
    [ "Enedis",[6.853238500000001, 47.46060250000001]],
    [ "Enedis",[6.848849999999999, 47.4619635751634]],
    [ "Enedis",[6.8543596435523115, 47.46799741579771]],
    [ "Enedis",[6.862454702656384, 47.45855144215938]],
    [ "Enedis",[6.872372969924811, 47.4554166679198]],
    [ "Enedis",[6.875535242651656, 47.45723758161349]],
    [ "Enedis",[6.846869844990285, 47.45954197890646]],
    [ "Enedis",[6.861311500000001, 47.46405000000001]],
    [ "Enedis",[6.851406673321235, 47.45779334059285]],
    [ "Enedis",[6.858766701098902, 47.46159697739404]],
    [ "Enedis",[6.85508577774687, 47.457621060863154]],
    [ "Enedis",[6.864689155763239, 47.459880444963645]],
    [ "Enedis",[6.872103496366167, 47.45901661275192]],
    [ "Enedis",[6.845727000000001, 47.49919750000001]],
    [ "Enedis",[6.833551309262567, 47.49800976031301]],
    [ "Enedis",[6.831397812087622, 47.49493396489837]],
    [ "Enedis",[6.8353209999999995, 47.504082]],
    [ "Enedis",[6.826449505197967, 47.49957016699846]],
    [ "Enedis",[6.84178290267359, 47.50590325293325]],
    [ "Enedis",[6.831557890314878, 47.50077368667957]],
    [ "Enedis",[6.840121748717948, 47.50145255897436]],
    [ "Enedis",[7.010828499999999, 48.297031999999994]],
    [ "RTE",[2.004707686239351, 50.86711289077598]],
    [ "Enedis",[2.0342379999999993, 47.843848249999986]],
    [ "Enedis",[3.2098955824602258, 50.15545535467598]],
    [ "Enedis",[3.206407182877781, 50.15707783526224]],
    [ "Enedis",[3.208808653434521, 50.158534670169296]],
    [ "Enedis",[6.887471056737588, 47.43337969503546]],
    [ "Enedis",[6.8840365, 47.44373]],
    [ "Enedis",[6.888917407843138, 47.437088564705896]],
    [ "Enedis",[6.889101933184855, 47.43330430363772]],
    [ "Enedis",[6.880118038613081, 47.44748893301813]],
    [ "Enedis",[6.880503008955225, 47.437480667661696]],
    [ "Enedis",[6.884363736283185, 47.447863641887906]],
    [ "Enedis",[6.8908442907534955, 47.43294244854936]],
    [ "Enedis",[6.887065018098798, 47.43713674512246]],
    [ "Enedis",[6.8831394999999995, 47.43958650000001]],
    [ "Enedis",[6.885980162239771, 47.43441133117971]],
    [ "Enedis",[6.892217807719297, 47.434367245847945]],
    [ "Enedis",[2.2555088711860263, 47.566777127128375]],
    [ "Enedis",[6.915394999999999, 47.43387599999999]],
    [ "Enedis",[6.921374254901962, 47.430695372549025]],
    [ "Enedis",[6.91156309540131, 47.430454117918245]],
    [ "Enedis",[6.849267500000001, 47.5800085]],
    [ "Enedis",[3.213754411304567, 50.16526318344688]],
    [ "Enedis",[3.2166835, 50.16473650000001]],
    [ "Enedis",[2.621661066813912, 50.450740858400884]],
    [ "Enedis",[2.6217318389202897, 50.45224474211965]],
    [ "Enedis",[2.6220078094796926, 50.4566658376175]],
    [ "Enedis",[2.61712137396803, 50.45234793780943]],
    [ "Enedis",[2.6282476882044983, 50.460778346415715]],
    [ "Enedis",[2.622619513995329, 50.45346154108066]],
    [ "Enedis",[2.6058853259462316, 50.45365272371632]],
    [ "Enedis",[2.6281644087371574, 50.45692659212215]],
    [ "Enedis",[2.6153407967039373, 50.44795945627773]],
    [ "Enedis",[2.6206506865446393, 50.45629358670413]],
    [ "Enedis",[2.646127331116908, 50.44731443369645]],
    [ "Enedis",[2.6502922431933236, 50.44981739501788]],
    [ "Enedis",[2.63843431217438, 50.45132472867334]],
    [ "Enedis",[2.6336507528678594, 50.45517488761301]],
    [ "Enedis",[2.6524603980333863, 50.44194388075807]],
    [ "Enedis",[2.655923379660504, 50.44097774516554]],
    [ "Enedis",[2.6435462101460283, 50.440162298132265]],
    [ "Enedis",[2.651483641363607, 50.44562740874942]],
    [ "Enedis",[2.652939565953621, 50.44668235076207]],
    [ "Enedis",[2.640714440567739, 50.45032834100497]],
    [ "Enedis",[2.660981462050909, 50.44553922853526]],
    [ "Enedis",[2.650811270703001, 50.45364814767341]],
    [ "Enedis",[2.6710129298488634, 50.45955296039835]],
    [ "Enedis",[2.6722603042157407, 50.46130850178935]],
    [ "Enedis",[-0.7901170172299536, 44.6195486389662]],
    [ "Enedis",[5.925297438886004, 46.37929466640706]],
    [ "Enedis",[5.9154462500000005, 46.368624499999996]],
    [ "Enedis",[5.909785950000001, 46.36949985]],
    [ "Poste électrique de Saint-Claude",[5.839852423576167, 46.379002661664316]],
    [ "Enedis",[1.871984641715381, 50.871030206647504]],
    [ "Enedis",[1.8520276925746302, 50.875302218594186]],
    [ "Enedis",[1.8766999682244185, 50.870335731673]],
    [ "Enedis",[1.8802360000784957, 50.86135517185904]],
    [ "Enedis",[1.8687824007686062, 50.86928039338564]],
    [ "Enedis",[1.877243100487771, 50.865886974688564]],
    [ "Enedis",[1.8803287831957134, 50.866179367378614]],
    [ "Enedis",[1.8716329602025361, 50.86751222344038]],
    [ "Enedis",[1.8721352178104786, 50.882044617671596]],
    [ "Enedis",[1.8704040003633722, 50.87532919410865]],
    [ "Enedis",[6.337304708595343, 45.61673733616268]],
    [ "Enedis",[3.208739333333334, 50.16019699111112]],
    [ "RTE",[-0.41470850566466144, 48.157674906463804]],
    [ "RTE",[1.05041655, 48.5609303]],
    [ "Sous-station SNCF Réseau d'Évron",[-0.38217496024569225, 48.1444763134621]],
    [ "RTE",[-0.2804566003974536, 48.33901294068553]],
    [ "Enedis",[3.201205696296296, 50.15938966666668]],
    [ "RTE",[-1.463247087143089, 48.239376517913044]],
    [ "Sous-station RFF de Maintenon",[1.5944361409233507, 48.57567158613137]],
    [ "RTE",[0.9081524152695807, 47.985531244193]],
    [ "Enedis",[3.211815110375276, 50.16963780463577]],
    [ "Enedis",[3.2134950211307816, 50.16715533219113]],
    [ "Enedis",[3.2103303158861345, 50.16717448393022]],
    [ "Poste source de Guinebert",[2.6866407859463037, 48.277089440783904]],
    [ "RTE",[2.98676666671747, 49.571786995344404]],
    [ "RTE",[2.3723799174925895, 49.880363565428624]],
    [ "RTE",[2.3384202000547907, 50.14663834371116]],
    [ "204027842",[2.761029665899786, 49.097253976152935]],
    [ "Synelva",[1.5251137082151514, 48.4281813457628]],
    [ "Enedis",[1.519613642208242, 48.44064138791233]],
    [ "RTE",[1.9214252966026546, 48.161991616590456]],
    [ "RTE",[2.4297570775782416, 48.07652194382265]],
    [ "RTE",[3.2256311075488306, 48.46515626614951]],
    [ "RTE",[0.7279540098662595, 48.06744083495874]],
    [ "RTE",[-0.267776983198384, 47.43553086229969]],
    [ "RTE",[-0.08817167790133913, 47.36626146813084]],
    [ "Saint-Georges-sur-Loire",[-0.756176899574113, 47.38692845340974]],
    [ "RTE",[-1.05501535, 47.555098900000004]],
    [ "RTE",[1.3529597398330049, 47.619384966562095]],
    [ "Enedis",[6.7329121, 48.013583000000004]],
    [ "RTE",[-0.0998658022943736, 47.53145195161366]],
    [ "Enedis",[2.3504436799410033, 50.3885223178466]],
    [ "Enedis",[3.165234034782609, 50.1679988115942]],
    [ "Poste électrique de Saint-Hubert",[6.134303830976082, 49.30024958584431]],
    [ "RTE",[3.961961811396051, 49.05059766733998]],
    [ "Enedis",[2.3360460582403966, 50.380061330441954]],
    [ "Poste électrique de Saint-Coulitz",[-4.0622757227605355, 48.175403469126344]],
    [ "Enedis",[2.342296, 50.3791365]],
    [ "Enedis",[2.3350905309008625, 50.38329773497383]],
    [ "Enedis",[2.342359903905979, 50.38461726074432]],
    [ "Enedis",[3.643719748148148, 50.15641437037037]],
    [ "Enedis",[3.201631705882353, 50.170751549019606]],
    [ "Enedis",[3.2032704999999995, 50.17299200000001]],
    [ "Enedis",[3.1939967137980596, 50.17494863035094]],
    [ "Enedis",[3.207418791724403, 50.1723160719592]],
    [ "Enedis",[-1.35202875, 46.849994]],
    [ "Enedis",[-1.3493330000000006, 46.849753]],
    [ "Enedis",[-1.34556895, 46.849039000000005]],
    [ "Poste électrique de Plémy",[-2.6502229763594083, 48.32291831993166]],
    [ "Poste électrique de Sévignac",[-2.3323096498786846, 48.31419288714231]],
    [ "Enedis",[2.333033210293527, 50.38183826698834]],
    [ "Enedis",[2.3337233333333343, 50.384514]],
    [ "Enedis",[2.3379449332477535, 50.38747857509629]],
    [ "Enedis",[2.342749719688109, 50.38850092709552]],
    [ "Enedis",[2.3379165113788494, 50.39004404016064]],
    [ "Enedis",[2.3371329326700394, 50.38754976576978]],
    [ "Poste électrique de Rophémel",[-2.0608574994029727, 48.32044619279578]],
    [ "RTE",[-2.067303946876152, 48.4443427444325]],
    [ "RTE",[-2.1041101808886036, 47.905610317532776]],
    [ "Enedis",[2.330983485016287, 50.379977959609114]],
    [ "Enedis",[2.1935203153692613, 47.35268090419162]],
    [ "Enedis",[2.3233946576907383, 50.378773469332245]],
    [ "Enedis",[2.3293123306982877, 50.37170567918315]],
    [ "Enedis",[2.3444684732698864, 50.38619566913406]],
    [ "Enedis",[3.2032744999999996, 50.177741999999995]],
    [ "Enedis",[2.36507525, 48.67224275000001]],
    [ "Enedis",[2.3615627263157895, 48.66324305964911]],
    [ "Enedis",[2.363917184878816, 48.675077216753515]],
    [ "Enedis",[3.1981763208862333, 50.17909105227782]],
    [ "Enedis",[3.1930858540811675, 50.180705867533064]],
    [ "Enedis",[3.1989621526144125, 50.181725312887686]],
    [ "Enedis",[3.1996444361301806, 50.17822086781083]],
    [ "Enedis",[3.201143825192802, 50.18060356023993]],
    [ "Maison du Parc",[5.9734485, 46.37471875000001]],
    [ "Enedis",[5.994405992633517, 46.36754519337017]],
    [ "Lajoux Village",[5.9761573702059785, 46.374505773827224]],
    [ "Enedis",[2.0277429485380125, 47.04350040818713]],
    [ "Enedis",[2.0240053579826314, 47.04787622197728]],
    [ "Enedis",[3.2054695, 50.1820575]],
    [ "Enedis",[3.201127549549549, 50.181903792792795]],
    [ "Enedis",[3.2036515039674853, 50.1813230000645]],
    [ "Enedis",[3.1974565000000004, 50.195521]],
    [ "Enedis",[3.1933977201805286, 50.18755467633785]],
    [ "Enedis",[3.196394067131104, 50.19710365425296]],
    [ "Enedis",[3.2004769155145936, 50.194864147465445]],
    [ "Enedis",[3.1956242939481263, 50.18950469548511]],
    [ "Enedis",[3.194197497024939, 50.19747732358527]],
    [ "Enedis",[3.205026115384616, 50.19552367948718]],
    [ "Enedis",[3.1970992000000003, 50.19178798039215]],
    [ "Chatelet",[6.213489851848204, 46.189439966881295]],
    [ "Enedis",[3.1594189149395504, 50.192533720639034]],
    [ "Enedis",[3.164506096131969, 50.194756100113764]],
    [ "Enedis",[3.1687115, 50.190148]],
    [ "Enedis",[3.169811747055253, 50.19119617000607]],
    [ "Enedis",[5.8273991999999994, 46.45172345000001]],
    [ "Enedis",[5.835310750559367, 46.46776225283307]],
    [ "Palais des sports",[5.056278269773193, 47.33101910348321]],
    [ "Enedis",[5.784031000000001, 46.117013]],
    [ "Enedis",[5.782989854462111, 46.11147967024782]],
    [ "Enedis",[5.786652673979862, 46.113304270376254]],
    [ "Enedis",[5.802096, 46.141077499999994]],
    [ "Enedis",[5.793908543378147, 46.141913521784105]],
    [ "Enedis",[5.794006499999999, 46.14465525]],
    [ "Enedis",[5.790947899999999, 46.1521725]],
    [ "Enedis",[5.794207791377498, 46.136925411146166]],
    [ "Enedis",[6.221202596697045, 46.1858181325841]],
    [ "Enedis",[3.2199929999999997, 50.194511500000004]],
    [ "Enedis",[3.2141049999999995, 50.19394549999999]],
    [ "Enedis",[3.217652846915018, 50.19450861466821]],
    [ "Enedis",[2.0612843069677935, 47.42433626791073]],
    [ "SER",[7.706098391089996, 48.729732045719985]],
    [ "Enedis",[1.63166125, 46.119032399999995]],
    [ "Enedis",[1.6249307, 46.1191522]],
    [ "Enedis",[1.6233821859934856, 46.11603287046688]],
    [ "Enedis",[1.626346643183301, 46.11847291389433]],
    [ "Enedis",[1.6267329343937345, 46.1156803972884]],
    [ "Minorville-Village",[5.890816999999999, 48.816924500000006]],
    [ "Église",[5.893507, 48.817693500000004]],
    [ "Enedis",[7.584528547038987, 49.05721658307359]],
    [ "ZI La Chatelaine 2",[6.217275979352385, 46.179095488691125]],
    [ "Enedis",[3.9989733999999992, 43.712400849999995]],
    [ "Enedis",[3.2222009909909914, 50.1955287927928]],
    [ "Enedis",[3.2230777152941177, 50.19679555808278]],
    [ "Enedis",[4.206378404449438, 44.88403440429962]],
    [ "Poste électrique La Côte",[1.4866975986936632, 46.24725639745836]],
    [ "Sous-station RFF des Vignes",[1.5527750680631047, 46.444543205277384]],
    [ "Poste électrique La Souterraine",[1.4934028489789888, 46.24614088103376]],
    [ "Poste éléctrique de Magnazeix",[1.2538822321757854, 46.3172772475043]],
    [ "Sous-station RFF de La Ville-sous-Grange",[1.4369753071876008, 46.06742201639004]],
    [ "Enedis",[2.1732328000000005, 43.55283169999999]],
    [ "Enedis",[6.976678499999999, 47.487236499999995]],
    [ "Enedis",[6.97754003539823, 47.491929191740404]],
    [ "Enedis",[6.981041, 47.485844]],
    [ "Enedis",[3.2051545000000004, 50.200894500000004]],
    [ "Enedis",[3.2044639999999993, 50.20136049999999]],
    [ "Enedis",[3.200634500000001, 50.202814999999994]],
    [ "Enedis",[3.1993954999999996, 50.20138800000001]],
    [ "Enedis",[7.467781600667362, 49.144747333295115]],
    [ "Enedis",[7.461004562538038, 49.142997179358375]],
    [ "Enedis",[6.451755790476191, 48.53254858095238]],
    [ "Enedis",[6.457988177108666, 48.533585357768764]],
    [ "Enedis",[6.467504000000001, 48.532344999999985]],
    [ "Enedis",[6.442132192254495, 48.55457934301521]],
    [ "Enedis",[6.450729, 48.556388999999996]],
    [ "Enedis",[6.451767639200067, 48.55463926031036]],
    [ "Enedis",[6.450212351387055, 48.55410076177896]],
    [ "Enedis",[2.0351777712418304, 47.08699437908497]],
    [ "Enedis",[2.0273782911218494, 47.08343735734471]],
    [ "Enedis",[-4.4683515, 48.430558600000005]],
    [ "Bourg",[-3.957170451335285, 48.300183516801226]],
    [ "SICAE de la Somme et du Cambraisis",[3.2547132359976314, 50.196687496092366]],
    [ "Enedis",[3.6128014044943817, 50.15110735205994]],
    [ "Enedis",[3.6178920000000008, 50.149246000000005]],
    [ "Enedis",[3.6207546311992798, 50.151541927261796]],
    [ "Enedis",[3.6129763930605914, 50.147362644916285]],
    [ "Enedis",[3.620494328223994, 50.14086748416104]],
    [ "Poste électrique de Dalstein",[6.399705260987093, 49.3069951841943]],
    [ "Poste électrique de Bouzonville",[6.517570244722041, 49.29715989512412]],
    [ "Enedis",[7.380040469985252, 49.12477213051565]],
    [ "Enedis",[7.350518926091149, 49.12172433494637]],
    [ "Enedis",[7.351081500000001, 49.1231276]],
    [ "Poste Électrique de Rambervillers",[6.625955028511592, 48.36101146015254]],
    [ "Le Brus",[4.312615868753726, 45.00832230327967]],
    [ "Enedis",[5.964049225700072, 46.44557419829117]],
    [ "Enedis",[5.930634598571774, 46.45901848389464]],
    [ "Enedis",[5.928999681186623, 46.45801224609574]],
    [ "Enedis",[5.964612833216538, 46.458718496379355]],
    [ "Enedis",[5.929358466687399, 46.46110715034439]],
    [ "Enedis",[5.931634093298074, 46.46030658764581]],
    [ "Enedis",[5.931474666726831, 46.4580498007483]],
    [ "Enedis",[5.933755795498448, 46.46034495962279]],
    [ "Enedis",[5.930744576351052, 46.46125455108109]],
    [ "Enedis",[6.71642455, 45.38864425]],
    [ "Enedis",[3.1920833046209056, 47.0859720538358]],
    [ "Enedis",[3.1986420472767922, 47.089136651653085]],
    [ "Enedis",[3.2528842000000004, 50.184393400000005]],
    [ "Enedis",[3.131977076870748, 50.02678462721087]],
    [ "Enedis",[3.1547233466666667, 50.040148592380945]],
    [ "Enedis",[1.2202935403694288, 47.139379410417085]],
    [ "Enedis",[2.514653921767884, 47.622992968176206]],
    [ "Les Ponnants",[5.9079574185055455, 45.09781470344424]],
    [ "Enedis",[1.0742368770114943, 48.40148506436781]],
    [ "Enedis",[5.803518747945206, 48.545069099543376]],
    [ "SICAE de la Somme et du Cambraisis",[3.2649003999999997, 50.188333899999996]],
    [ "Enedis",[5.8242588016288535, 46.14696579678107]],
    [ "Enedis",[5.824432674723062, 46.14914565659618]],
    [ "Enedis",[5.8216740499999995, 46.150082]],
    [ "Poste électrique de Carquefou",[-1.4787073782996782, 47.28483351534587]],
    [ "Enedis",[3.2728297630522083, 50.18184204819276]],
    [ "Enedis",[-4.341514541637747, 47.96146431274078]],
    [ "Enedis",[3.5722343202247187, 50.14009583707865]],
    [ "RTE",[-0.2731628777835295, 42.88593371455594]],
    [ "RTE",[-0.21923649047136357, 42.946613222015486]],
    [ "RTE",[6.164882050042864, 49.29000456393572]],
    [ "Enedis",[6.2146830349432145, 46.179835324738036]],
    [ "Enedis",[-4.5295736212773665, 48.38697914117298]],
    [ "Enedis",[6.210291403438744, 46.17663988343644]],
    [ "Enedis",[6.20244157893282, 46.187566583124266]],
    [ "Enedis",[3.2838768444444444, 50.16001298222223]],
    [ "Richemont",[6.179252577534976, 49.28407457817698]],
    [ "Poste électrique de Lanton",[-1.0250035268393993, 44.74016069537232]],
    [ "Enedis",[6.207333050000001, 46.1901101]],
    [ "Enedis",[3.2799543276108727, 50.15696502145923]],
    [ "Enedis",[-1.5353564292784838, 46.44286923685641]],
    [ "Enedis",[-1.5189129999999997, 46.451790499999994]],
    [ "Enedis",[-1.5240571936416185, 46.4465837822736]],
    [ "Enedis",[6.571292644801869, 45.50477959232036]],
    [ "Pragneres",[0.011512952273697719, 42.821462960806585]],
    [ "Enedis",[6.20603398070368, 46.18478571614363]],
    [ "Poste électrique d'Amnéville",[6.144292610292919, 49.265301404327694]],
    [ "RTE",[6.176168624627585, 49.26184050382348]],
    [ "Poste électrique de Fontoy",[5.980580748258455, 49.35409982461491]],
    [ "Poste électrique de Ranguevaux",[6.056499880212446, 49.30886558774475]],
    [ "SNCF Réseau",[5.776612179910263, 49.3004243272271]],
    [ "RTE",[5.900414409421026, 49.31224550636626]],
    [ "RTE",[5.971712616514074, 49.174824049495605]],
    [ "Enedis",[5.902949710625686, 46.74376952504964]],
    [ "Enedis",[5.898374473094701, 46.75612190626335]],
    [ "Enedis",[5.904184149999999, 46.742159099999995]],
    [ "Enedis",[5.902043841661084, 46.754213199811225]],
    [ "Enedis",[5.898675407035751, 46.746476169541026]],
    [ "Enedis",[5.9020352070444, 46.74599911898838]],
    [ "Enedis",[6.193960886340388, 46.18216651728053]],
    [ "RTE",[6.147119692657717, 49.23969307567953]],
    [ "RTE",[6.151917231787212, 49.20083817956149]],
    [ "Poste électrique d'Ennery",[6.229411690279768, 49.23201530673378]],
    [ "Poste électrique de Thionville Usinor (Aciéries)",[6.146363261667958, 49.339447040723016]],
    [ "Collège",[5.555026804861166, 45.411092396242076]],
    [ "RTE",[4.848583230054834, 45.82181185084909]],
    [ "Enedis",[6.191657276493175, 46.18083819038945]],
    [ "Enedis",[3.0803568420971184, 49.744757560228656]],
    [ "Enedis",[3.078636714896768, 49.7514346807615]],
    [ "Enedis",[3.076535789033203, 49.74057537821593]],
    [ "Enedis",[3.07572952250372, 49.758902889043824]],
    [ "Enedis",[3.092225307540538, 49.74121453122274]],
    [ "Enedis",[3.082282721261221, 49.74309586819917]],
    [ "Enedis",[3.077203131697271, 49.746250670531545]],
    [ "Enedis",[3.0738780591833788, 49.75498738133847]],
    [ "Enedis",[3.075909350702558, 49.7541905590846]],
    [ "Enedis",[3.0929929516690624, 49.73930616570189]],
    [ "Enedis",[3.0902015739305155, 49.743991840198916]],
    [ "Enedis",[3.067855768611855, 49.754323564894406]],
    [ "Enedis",[3.081256537157959, 49.74216030924655]],
    [ "Enedis",[3.074228098970774, 49.75676694539274]],
    [ "Enedis",[3.086471462303312, 49.752247871290706]],
    [ "Enedis",[3.0951238735938276, 49.74343543541681]],
    [ "Enedis",[3.0707857294741836, 49.74850095613983]],
    [ "Enedis",[3.0866636878708418, 49.741670885333264]],
    [ "Enedis",[3.0775497660796294, 49.75130687912624]],
    [ "Enedis",[3.0637600804118277, 49.74798732320235]],
    [ "Enedis",[3.0907899530713316, 49.745142694208134]],
    [ "Enedis",[3.064710560228701, 49.75426526513817]],
    [ "Enedis",[3.0861664653273393, 49.742131041782436]],
    [ "Enedis",[3.0763735932577343, 49.743746729311724]],
    [ "Enedis",[3.252117, 50.14873500000001]],
    [ "Enedis",[3.256935483537551, 50.14711666541636]],
    [ "Enedis",[3.2537532074028697, 50.14878116807364]],
    [ "Enedis",[3.2537128370136696, 50.14711604416403]],
    [ "Poste électrique de Sept-Saulx",[4.261304638805224, 49.144418040322506]],
    [ "Poste de Bancairon",[7.137378476739152, 44.00356397919792]],
    [ "Enedis",[6.347645849999999, 46.27417595]],
    [ "Enedis",[6.368988754626308, 46.26331552486049]],
    [ "Enedis",[6.380867596339933, 46.25873040513078]],
    [ "Enedis",[6.36068353019765, 46.26568235805893]],
    [ "Enedis",[6.3728897339250965, 46.26472807667283]],
    [ "Enedis",[6.352473968575487, 46.273100341562134]],
    [ "Enedis",[6.347314999999999, 46.275931600000014]],
    [ "Enedis",[6.363483395085851, 46.27607643379881]],
    [ "Enedis",[6.377731216314221, 46.26763444948509]],
    [ "Enedis",[6.338974416491829, 46.26389789650325]],
    [ "Enedis",[6.350745656178934, 46.261935808984134]],
    [ "Enedis",[6.3639828846103805, 46.26038975235185]],
    [ "Enedis",[6.370579604887625, 46.27080243166496]],
    [ "Enedis",[6.36669933472299, 46.26873740363122]],
    [ "Enedis",[6.373584406941701, 46.26098082641969]],
    [ "Enedis",[6.36972741656082, 46.26981637800345]],
    [ "Enedis",[6.376708409240929, 46.266692173771254]],
    [ "Enedis",[6.366593484075443, 46.26637941219212]],
    [ "Enedis",[6.364234586858293, 46.26383722088437]],
    [ "Poste électrique d'Errouville",[5.899557307889502, 49.41206497827162]],
    [ "Poste électrique Hayange Tournebride",[6.072598749488432, 49.32425559420161]],
    [ "Enedis",[-3.148805900190616, 47.84776245102254]],
    [ "Enedis",[-3.225684784671585, 47.80894145659306]],
    [ "Enedis",[-3.109805649881719, 47.84692288746218]],
    [ "Enedis",[-3.0765403000000004, 47.833421699999995]],
    [ "Enedis",[-3.160229158506503, 47.86918594887649]],
    [ "Enedis",[1.4297365622545473, 43.661180656509096]],
    [ "Poste source",[6.309139390221011, 45.34654630526689]],
    [ "SNCF Réseau",[6.308436372613811, 45.346254224702335]],
    [ "Poste Électrique de Randens",[6.319091453550112, 45.52675310248945]],
    [ "Enedis",[6.629982866165981, 47.105567672455855]],
    [ "Enedis",[3.220673909295114, 50.127873534892295]],
    [ "Enedis",[2.836428658464567, 44.923419701115485]],
    [ "Enedis",[1.1241014058244287, 49.31786474565263]],
    [ "Enedis",[1.1189192771084324, 49.31937203435966]],
    [ "Enedis",[1.1127415779092693, 49.31875471597633]],
    [ "Enedis",[1.1142945, 49.3242475]],
    [ "Enedis",[-0.31128584229046696, 47.482811363883556]],
    [ "RTE",[5.886453244400314, 43.099710966371795]],
    [ "Séchin Village",[6.284237171605569, 47.337996341105786]],
    [ "Enedis",[-0.9336914000000001, 44.60978705]],
    [ "Enedis",[-0.8899662685701488, 44.60650301428731]],
    [ "Poste électrique de Gap",[6.072752172076356, 44.55570374321177]],
    [ "Enedis",[2.4387017696926687, 47.49015166103492]],
    [ "Enedis",[2.420915727259822, 47.487356353007776]],
    [ "Enedis",[6.132858349999999, 45.44867295]],
    [ "Enedis",[3.223956392094539, 50.119786783292575]],
    [ "Enedis",[3.2201144934523813, 50.12403375238095]],
    [ "Enedis",[-0.3577375305322125, 48.58321887619047]],
    [ "Enedis",[1.5160775499999986, 43.61111504999999]],
    [ "Enedis",[1.4990769999999998, 43.619569999999996]],
    [ "Enedis",[-0.937382367442826, 45.3017932855098]],
    [ "Enedis",[3.2166926369014086, 50.128907866009385]],
    [ "Enedis",[3.2181494305027236, 50.13154845613001]],
    [ "Enedis",[5.890071499776355, 48.295578102262446]],
    [ "Enedis",[5.893764833333332, 48.299085999999996]],
    [ "Enedis",[-1.2847192019835758, 43.670857494054815]],
    [ "Enedis",[-1.3112087619883042, 43.659089390643274]],
    [ "Enedis",[-1.304999824373777, 43.665443215570036]],
    [ "Enedis",[-1.310496837322595, 43.66133542584391]],
    [ "Enedis",[-1.2859117315789477, 43.675407003508774]],
    [ "Enedis",[-1.3053527818843396, 43.66123580498692]],
    [ "Enedis",[-1.30193622444655, 43.65603881141431]],
    [ "Enedis",[-1.3059757355114385, 43.66311211137621]],
    [ "Enedis",[-1.308030018949881, 43.666189618615746]],
    [ "Enedis",[-1.2991130953788876, 43.66018444296978]],
    [ "Enedis",[-1.3003848462491812, 43.66625099425978]],
    [ "Enedis",[-1.2936084169679387, 43.660892601785314]],
    [ "Enedis",[-1.3148986604275783, 43.66013999711778]],
    [ "Enedis",[-1.29605395943821, 43.6621545470204]],
    [ "Enedis",[3.712521331201184, 43.49959488281292]],
    [ "Poste Électrique de Sombussy",[5.193554529715393, 49.36433481671097]],
    [ "Enedis",[2.5697989500000005, 48.31901965]],
    [ "Poste Électrique de Chauvency (RFF)",[5.294175045360347, 49.52556583764021]],
    [ "Poste Électrique d'Osnes",[5.162389877868032, 49.65700554392379]],
    [ "Poste électrique de Floing",[4.935348387230647, 49.724359630073224]],
    [ "Enedis",[1.9327740737109653, 47.526364624183]],
    [ "Enedis",[1.9320693999999998, 47.5225399]],
    [ "Enedis",[-0.5304131499999999, 44.04486269999999]],
    [ "Enedis",[-0.5336416999999999, 44.04471525]],
    [ "Poste Électrique de Fléville",[4.965799573093223, 49.317500189712725]],
    [ "Enedis",[3.1660688000000006, 50.1216515]],
    [ "Enedis",[3.169873477842452, 50.12254001272798]],
    [ "Enedis",[3.1678891999999994, 50.125110299999996]],
    [ "Enedis",[6.552842299999999, 47.38605390000001]],
    [ "SNCF Réseau",[6.426160253182103, 49.00226396659808]],
    [ "Enedis",[1.2155670286539577, 47.34604087198151]],
    [ "Ardoint",[2.5764246729492974, 48.32001109346093]],
    [ "Enedis",[3.3619686172055, 43.57849495403195]],
    [ "Enedis",[3.3594197499999994, 43.57603055]],
    [ "Enedis",[3.3639985899001164, 43.57575897749843]],
    [ "Enedis",[3.1718547299038065, 50.12487737153854]],
    [ "Enedis",[6.737953163623082, 47.47881302252252]],
    [ "Enedis",[-3.6367928638685325, 48.668496862091935]],
    [ "Enedis",[-3.62763625, 48.66223120000001]],
    [ "Enedis",[-3.6328290706991355, 48.656390695470016]],
    [ "Enedis",[-3.6291150000000005, 48.65519350000001]],
    [ "RTE",[-0.02639544779160332, 43.755666080539115]],
    [ "Enedis",[0.3644902913396672, 47.93740101115241]],
    [ "Poste électrique de Villement",[1.9625277409850352, 46.932104731066474]],
    [ "Enedis",[-0.5586697727569346, 49.291495646156434]],
    [ "Enedis",[0.8487599505187552, 48.785445547486034]],
    [ "Enedis",[3.0820597001670844, 47.78143955764411]],
    [ "Enedis",[3.0784489817629184, 47.78307417831814]],
    [ "Enedis",[3.0737763289902276, 47.77975451248643]],
    [ "Enedis",[3.0758407541305597, 47.777740922125936]],
    [ "Enedis",[3.083842127069834, 47.78017530705544]],
    [ "Enedis",[3.065769964670401, 47.778691653741205]],
    [ "Enedis",[3.987317138819617, 47.5983860889443]],
    [ "Enedis",[3.297718275060701, 48.082162591050995]],
    [ "Enedis",[3.2835864016482317, 48.08618965495555]],
    [ "Enedis",[3.346851599156117, 48.097813713080164]],
    [ "Enedis",[3.2976135817471937, 48.08898731381163]],
    [ "Enedis",[3.2926037208182914, 48.074717897713604]],
    [ "Enedis",[3.2772919083579684, 48.083563195288015]],
    [ "Enedis",[3.3159440876930746, 48.06207277030393]],
    [ "Enedis",[3.284225768768767, 48.08556029429429]],
    [ "Enedis",[3.2805061889596603, 48.08842936942675]],
    [ "Enedis",[3.2985860000000002, 48.07993000000001]],
    [ "Enedis",[3.290936219077568, 48.0835224129979]],
    [ "Enedis",[3.2845060675917717, 48.08519069782453]],
    [ "Enedis",[3.2904662253072763, 48.07553992316716]],
    [ "Enedis",[3.3478347238418062, 48.09484547638418]],
    [ "Enedis",[3.2991015, 48.0789595]],
    [ "Enedis",[3.274038, 48.08607462318841]],
    [ "Enedis",[3.2957320908547523, 48.087718232914916]],
    [ "Enedis",[3.2826566, 48.089488]],
    [ "Enedis",[3.2955841369963372, 48.077435765672426]],
    [ "Enedis",[3.2957208210871647, 48.08753319899906]],
    [ "Enedis",[3.1739359614203457, 50.11449761701855]],
    [ "Enedis",[3.1724229499999996, 50.1175877]],
    [ "Enedis",[6.3845826523157205, 48.539357285061975]],
    [ "RTE",[3.3586880129326753, 47.18369130032986]],
    [ "CCAS",[-4.280784219512194, 48.10967395121951]],
    [ "Poste électrique de Calan",[-3.302988870976464, 47.88070051817539]],
    [ "Enedis",[7.0471055, 48.974115499999996]],
    [ "Enedis",[7.045031193962748, 48.9788785564119]],
    [ "Enedis",[7.048582330908208, 48.97752348696496]],
    [ "RTE",[1.8875584013884694, 48.09403546320872]],
    [ "Enedis",[5.751184658853076, 45.644612479977674]],
    [ "Enedis",[3.18987182193358, 50.127313351489185]],
    [ "Enedis",[-4.23183895, 48.19990870000001]],
    [ "Enedis",[7.15628075, 48.897738000000004]],
    [ "Enedis",[5.742107955363422, 45.62985668845013]],
    [ "Enedis",[6.885013954385965, 47.58916280350877]],
    [ "Enedis",[6.805581094302849, 47.6066511677911]],
    [ "Enedis",[6.78573622262052, 47.615070329707464]],
    [ "Enedis",[2.4603902522853596, 49.02281966324622]],
    [ "Enedis",[0.36665383670366886, 47.93784636945347]],
    [ "Le Vautour",[1.1134003647715593, 49.48056271903923]],
    [ "Enedis",[6.737346049999999, 47.562535999999994]],
    [ "Enedis",[5.720281403379569, 45.59391730021826]],
    [ "Enedis",[0.35137484999999996, 47.934635]],
    [ "La Taille",[0.35216136541324516, 47.937578775490756]],
    [ "Enedis",[5.6540942470533535, 45.60512324630881]],
    [ "Enedis",[0.364430756074995, 47.93378424230746]],
    [ "Enedis",[5.883289398510722, 45.79969969923256]],
    [ "Enedis",[2.6390854002971893, 47.68442473342074]],
    [ "Enedis",[2.6415661085649194, 47.68436783628359]],
    [ "Enedis",[2.638953434450743, 47.68660733589271]],
    [ "211491607",[2.63696755282191, 47.690208073881955]],
    [ "Enedis",[2.6409170820137096, 47.688027246697374]],
    [ "Enedis",[2.6376014000000003, 47.68742879999999]],
    [ "Enedis",[2.637715169895691, 47.69165608393546]],
    [ "Enedis",[2.644910363196649, 47.68268366791926]],
    [ "Enedis",[2.633078891712327, 47.69068844440229]],
    [ "Enedis",[2.6761444604514417, 47.66935120676094]],
    [ "Enedis",[2.6471211984474525, 47.68724211666033]],
    [ "Enedis",[2.65424925, 47.68641135]],
    [ "Enedis",[2.645514616609071, 47.68682584970176]],
    [ "Enedis",[2.6478709737116293, 47.68479844448305]],
    [ "Enedis",[2.644252806900172, 47.68795733402721]],
    [ "Enedis",[2.6434336756133248, 47.688731082097604]],
    [ "Enedis",[2.65288188633133, 47.681355333919775]],
    [ "Enedis",[2.634107498840957, 47.69623947982421]],
    [ "Enedis",[2.646361230871557, 47.681829256609156]],
    [ "Enedis",[2.6352103055284424, 47.68306610047184]],
    [ "Enedis",[2.6335197524324605, 47.684776372675465]],
    [ "Enedis",[2.6073975190859353, 47.704531196481064]],
    [ "Enedis",[2.6172254896509584, 47.70254526932162]],
    [ "Enedis",[2.6327244000000003, 47.693243200000005]],
    [ "Enedis",[2.625734819186733, 47.69433604551625]],
    [ "Enedis",[2.6285947511611756, 47.68101851051963]],
    [ "Enedis",[2.6250280617000183, 47.69259622966428]],
    [ "Enedis",[2.631502506210113, 47.694604938070704]],
    [ "Enedis",[2.610838282537834, 47.69937411419925]],
    [ "Enedis",[2.6236431500000004, 47.69946039999999]],
    [ "Enedis",[2.6263751, 47.68955245000001]],
    [ "Enedis",[2.6080356, 47.69940340000001]],
    [ "Enedis",[2.6303967972167435, 47.67994675169845]],
    [ "Enedis",[2.614713216231168, 47.70334015168315]],
    [ "Enedis",[2.6223951870361515, 47.704531651823515]],
    [ "Enedis",[2.628736033394429, 47.68903774943669]],
    [ "Enedis",[2.628515893735104, 47.686485292156874]],
    [ "Enedis",[2.624699628740168, 47.69724458711925]],
    [ "Enedis",[2.607328751818897, 47.69304888392835]],
    [ "Enedis",[2.6215223656349584, 47.69763280591141]],
    [ "Enedis",[2.6141522038138967, 47.695635116988065]],
    [ "Enedis",[2.623351911273713, 47.688668518699195]],
    [ "Enedis",[2.6249177766572207, 47.68708305161965]],
    [ "Enedis",[2.6179071537222574, 47.70510254027359]],
    [ "Enedis",[2.6203884733796183, 47.702146713102756]],
    [ "Enedis",[2.619856754904293, 47.69049584336807]],
    [ "Enedis",[2.625858217737573, 47.697069717408546]],
    [ "Enedis",[2.6191507499463205, 47.69944279206892]],
    [ "Enedis",[6.127157658407155, 43.19566386118663]],
    [ "GARBAILLE",[-1.0606599138285473, 44.625489081253775]],
    [ "Enedis",[-1.0683151855169224, 44.623445518692755]],
    [ "RTE",[2.4407017302135547, 48.75103119113094]],
    [ "Champigneulles",[6.171621790936866, 48.72231056853576]],
    [ "Colonie",[2.450813053453789, 48.799954721650955]],
    [ "Vendangeur",[6.05380005, 48.7954049]],
    [ "Enedis",[2.0452355387346355, 47.42470583267419]],
    [ "Poste électrique de Pontac",[-0.5484109572752522, 44.76053919423733]],
    [ "Poste électrique de Gérardmer",[6.891847853641827, 48.08482386218048]],
    [ "Enedis",[1.6128413727300368, 49.09978605335006]],
    [ "Les Vergers",[6.057876950000001, 48.7939461]],
    [ "RTE",[3.5127258944831814, 49.19453932336272]],
    [ "Enedis",[4.7515757, 46.78336049999999]],
    [ "RTE",[2.561570205829758, 48.633723974919974]],
    [ "RTE",[1.6466034555601365, 46.30326561126644]],
    [ "Poste électrique de Scheer",[7.519253196765894, 48.356730023395635]],
    [ "Enedis",[0.36688325, 47.931487000000004]],
    [ "Enedis",[0.37025439218002865, 47.93095103460372]],
    [ "Poste électrique de Brou",[5.23868730115043, 46.20005008729892]],
    [ "Poste électrique de Ponteau",[5.0233656327578515, 43.36096794083432]],
    [ "Enedis",[2.039882348503855, 47.423502450282626]],
    [ "Enedis",[-0.39397270668707723, 49.188175107693056]],
    [ "RTE",[4.415105417205302, 46.80686551289181]],
    [ "L Auche",[3.867877298850574, 49.24651383908046]],
    [ "Enedis",[3.8671678222862638, 49.244348991354464]],
    [ "Enedis",[3.608961419243986, 47.99527091752579]],
    [ "Enedis",[3.613031021480118, 47.99295795002303]],
    [ "Enedis",[3.6157450477515063, 47.99102079786741]],
    [ "Enedis",[3.629271494949495, 47.98939835353535]],
    [ "Enedis",[3.621235002357378, 47.98470231494578]],
    [ "Enedis",[3.606783392424242, 47.99053364772727]],
    [ "Enedis",[3.598439731420035, 47.98952805388164]],
    [ "Enedis",[3.6240703198471564, 47.99237048370421]],
    [ "Enedis",[3.6065033053743405, 48.01871184187636]],
    [ "Enedis",[3.618630120567376, 47.99295120567375]],
    [ "Enedis",[3.6213111745739854, 47.98468349319602]],
    [ "Enedis",[3.6133075056444994, 47.98916605131363]],
    [ "Enedis",[5.725116800882463, 45.56679914976378]],
    [ "RTE",[0.9895183234196964, 47.14208821839072]],
    [ "215860804",[4.834705586247351, 46.18927606684075]],
    [ "Enedis",[3.0989682544802855, 43.18570803942652]],
    [ "Enedis",[3.09432475, 43.18793335]],
    [ "Enedis",[3.0853726183206116, 43.189341353689564]],
    [ "Enedis",[3.0694683063427792, 43.193519048583]],
    [ "Enedis",[3.0904363419465977, 43.189653332472005]],
    [ "Enedis",[3.09799653866687, 43.188661021911514]],
    [ "Enedis",[3.0920805000000002, 43.19040974999999]],
    [ "Enedis",[3.0894282969966627, 43.187687098628096]],
    [ "Enedis",[3.0726736666666667, 43.20355757037037]],
    [ "Enedis",[3.0718135, 43.1975375]],
    [ "Enedis",[3.0690072147838214, 43.20155492887029]],
    [ "Enedis",[3.127411880063228, 43.20703418889547]],
    [ "Enedis",[3.06935529845799, 43.20327452457126]],
    [ "Enedis",[3.0780924334549367, 43.2039511996374]],
    [ "Enedis",[3.072226069544365, 43.200715767386086]],
    [ "Enedis",[6.700308808591385, 46.2829066637529]],
    [ "Enedis",[6.724989915384615, 46.281694932307694]],
    [ "Enedis",[6.7474085000000015, 46.289339500000004]],
    [ "Charmy l'Adroit",[6.736568999999999, 46.26450499999999]],
    [ "Enedis",[6.748720196850394, 46.292170047244106]],
    [ "Enedis",[6.728813656125555, 46.264384282501766]],
    [ "Enedis",[6.711223591368984, 46.280549876028005]],
    [ "Enedis",[6.689812895894725, 46.29043833280785]],
    [ "Enedis",[6.75678590122384, 46.29316881331017]],
    [ "Enedis",[6.718710780829293, 46.279934513731824]],
    [ "Enedis",[6.72062831607145, 46.27851972319198]],
    [ "RTE",[1.3884020043547596, 44.74431807074404]],
    [ "Poste électrique de Royan",[-1.0195046505437355, 45.632060601560305]],
    [ "Enedis",[-1.2390045448156528, 43.16876539559405]],
    [ "Enedis",[-1.2396695454307671, 43.168326429949325]],
    [ "Enedis",[2.3729644999999997, 47.095578]],
    [ "Enedis",[2.38197175, 47.096621000000006]],
    [ "Enedis",[2.37775075, 47.11389400000001]],
    [ "Enedis",[2.3872097629701083, 47.10659442982502]],
    [ "Enedis",[2.38055325, 47.099574000000004]],
    [ "Enedis",[2.3859675, 47.10407]],
    [ "Enedis",[2.3851510000000005, 47.099115]],
    [ "Enedis",[2.388381750000001, 47.096643500000006]],
    [ "Enedis",[2.3762774999999996, 47.097892499999986]],
    [ "Enedis",[2.381071, 47.102520500000004]],
    [ "Village",[5.958897490482964, 48.37810233577436]],
    [ "Enedis",[2.5623948385013025, 48.52026245676921]],
    [ "Enedis",[5.42544835, 43.3838405]],
    [ "Poste électrique d'Aubette",[5.195019854984279, 43.50212916174636]],
    [ "Enedis",[2.3226335618493033, 50.394152895359085]],
    [ "Enedis",[2.3301653214910507, 50.385734528865555]],
    [ "Enedis",[2.3230561679185016, 50.39298556306655]],
    [ "Enedis",[-0.15014465, 47.62744825000001]],
    [ "Enedis",[-0.14693027995321764, 47.62747947772422]],
    [ "Enedis",[6.039424317434816, 48.37320219666458]],
    [ "Enedis",[2.5524172, 48.526633249999996]],
    [ "Sous-station SNCF de Carpentier",[-1.6277920771104875, 49.53920272620219]],
    [ "Enedis",[5.458991744596067, 46.6726941849968]],
    [ "Enedis",[5.46116625, 46.666698999999994]],
    [ "Enedis",[5.4382995, 46.67502775000001]],
    [ "Enedis",[5.4635677000000005, 46.670104050000006]],
    [ "Enedis",[5.46720005, 46.6662038]],
    [ "Enedis",[-0.2656108620432512, 43.341379755406415]],
    [ "Enedis",[-0.26289144077134957, 43.350343147973234]],
    [ "Enedis",[-0.2660726726955418, 43.34441883547518]],
    [ "Enedis",[-0.26774699999999996, 43.34602149999999]],
    [ "Enedis",[-0.25719985634282916, 43.35109525152179]],
    [ "Enedis",[-0.2699118295539493, 43.34274748459175]],
    [ "Enedis",[-0.2595895956727511, 43.345423807978364]],
    [ "Enedis",[5.879942799999999, 48.585110950000015]],
    [ "Enedis",[2.950136505870745, 47.70220683496135]],
    [ "Enedis",[2.9457028702497277, 47.70285993919652]],
    [ "Enedis",[2.95409695133095, 47.7001008905443]],
    [ "Enedis",[2.9388355000000006, 47.69831200000001]],
    [ "Enedis",[2.946458162426254, 47.69548858904868]],
    [ "Enedis",[2.9417415, 47.694893500000006]],
    [ "Enedis",[2.963692000000001, 47.7047929]],
    [ "Enedis",[5.512907617554241, 45.63718265364892]],
    [ "Enedis",[5.504116655290462, 45.63578168278528]],
    [ "Enedis",[5.53218275, 45.63045325]],
    [ "Enedis",[6.650043003683241, 46.14437888766115]],
    [ "Enedis",[6.688026499999999, 46.15240399999999]],
    [ "Enedis",[6.688164250393905, 46.182533850249776]],
    [ "Enedis",[6.66758290163599, 46.16106142453987]],
    [ "Enedis",[6.66532445, 46.15683904999999]],
    [ "Enedis",[6.670088193107829, 46.15214023535016]],
    [ "Enedis",[6.64375956321839, 46.144542885057476]],
    [ "Carres",[6.680043730461019, 46.16173150615496]],
    [ "Enedis",[6.667868963589383, 46.15265582687339]],
    [ "Enedis",[6.680343348706123, 46.18495481732001]],
    [ "Folliets bas",[6.678761249999999, 46.16014074999999]],
    [ "Enedis",[6.681315909531803, 46.16213276067897]],
    [ "CHECY",[2.0435574528435887, 47.916021607706114]],
    [ "Enedis",[-1.2620835245834023, 46.8626217616848]],
    [ "Enedis",[-1.2425343232507897, 46.86702485486305]],
    [ "Enedis",[5.029589945622622, 45.998234140837404]],
    [ "Poste électrique Peugeot",[7.424334131065009, 47.769379485259435]],
    [ "Saint-Blaise",[6.088420890336552, 46.065266523543556]],
    [ "Enedis",[5.623674699999999, 45.59766255]],
    [ "Enedis",[6.66021817557252, 48.287038539440196]],
    [ "218225181",[6.51695985, 45.80639909999999]],
    [ "Enedis",[6.539509209132475, 45.80463301986524]],
    [ "Enedis",[7.084910451341939, 49.017756135013315]],
    [ "Enedis",[7.077828337748345, 49.01874748859455]],
    [ "Enedis",[7.081654677692392, 49.01403247366076]],
    [ "Enedis",[3.408142922752111, 50.12395975749297]],
    [ "Enedis",[3.412521667089679, 50.13279650951777]],
    [ "Enedis",[3.4140873039082544, 50.1258425734118]],
    [ "Enedis",[3.404329758440589, 50.117004157433314]],
    [ "Enedis",[3.409750482580646, 50.1296630851613]],
    [ "Enedis",[3.4076638325116924, 50.131762502591336]],
    [ "Enedis",[3.4147066691122525, 50.12842649376376]],
    [ "Enedis",[3.405789698454746, 50.110969422516554]],
    [ "Enedis",[3.4077241399639333, 50.1203063993619]],
    [ "218294017",[3.416600818150088, 50.1225901493892]],
    [ "Enedis",[3.412197883481382, 50.127848702484656]],
    [ "Enedis",[3.416464441089908, 50.12477782607045]],
    [ "Enedis",[3.4178277088989444, 50.129814054298635]],
    [ "Enedis",[3.419703223808566, 50.118002544674574]],
    [ "Enedis",[3.4200005996937226, 50.11472170148035]],
    [ "Enedis",[3.4260820000000005, 50.12765099999999]],
    [ "Enedis",[3.4127493494670063, 50.123813051797136]],
    [ "Enedis",[3.4182695714837337, 50.12391614007143]],
    [ "Enedis",[3.428798476357268, 50.111333098073565]],
    [ "Enedis",[3.4371510000000014, 50.10848449999999]],
    [ "Enedis",[3.4184783606286193, 50.11044675902949]],
    [ "Enedis",[3.438721444529997, 50.11019225165222]],
    [ "Enedis",[3.409291687676553, 50.117791972810736]],
    [ "Enedis",[3.4299293137254896, 50.1131957009804]],
    [ "Enedis",[3.408757, 50.1264525]],
    [ "Enedis",[3.4091795, 50.124992999999996]],
    [ "Enedis",[3.4241519999999994, 50.12768199999999]],
    [ "Enedis",[3.414615603137711, 50.11384115649816]],
    [ "Enedis",[3.404309853074433, 50.11358616763754]],
    [ "Enedis",[3.432486211134211, 50.109181470778466]],
    [ "Enedis",[3.4288745000000014, 50.112896]],
    [ "Enedis",[3.403829648011264, 50.12085742907428]],
    [ "Enedis",[3.401293, 50.10994600000001]],
    [ "Enedis",[3.4236131352829777, 50.11220207644294]],
    [ "Enedis",[3.410485371474946, 50.12251215581255]],
    [ "Enedis",[3.400144169312168, 50.1222345026455]],
    [ "Enedis",[3.401554236761053, 50.12432715191256]],
    [ "Enedis",[3.4130959467369046, 50.112257581796904]],
    [ "Enedis",[3.4237106666666666, 50.117878942528726]],
    [ "Enedis",[3.4178653180668244, 50.12731116252291]],
    [ "Enedis",[3.4074290123022846, 50.11175948310876]],
    [ "Enedis",[3.4249298168826843, 50.130454811232966]],
    [ "Enedis",[3.4063141601489764, 50.108685342644314]],
    [ "Enedis",[3.4066051176863485, 50.116248055214164]],
    [ "Enedis",[3.423124683959779, 50.1256169596731]],
    [ "Enedis",[3.4161729061133164, 50.11681881151459]],
    [ "Enedis",[3.413741860045542, 50.11663481975827]],
    [ "Enedis",[3.3997144276294105, 50.124420179030665]],
    [ "Enedis",[3.4137536566998894, 50.12024482502769]],
    [ "Enedis",[3.4222884044679596, 50.13133868430335]],
    [ "Enedis",[3.4167932149595672, 50.11869703818509]],
    [ "Enedis",[3.4329941401077755, 50.11095631177829]],
    [ "Enedis",[3.404917713302919, 50.127347153957565]],
    [ "Enedis",[3.4227757416934614, 50.1150814437299]],
    [ "Enedis",[3.4214308263386397, 50.112790681620844]],
    [ "Enedis",[3.400088554810995, 50.11963726718212]],
    [ "Enedis",[3.4018490348658075, 50.130320252087905]],
    [ "Enedis",[3.415109456383592, 50.12231000635471]],
    [ "Enedis",[3.41626856336261, 50.132135151819334]],
    [ "Enedis",[3.419591358004514, 50.11678271095027]],
    [ "Enedis",[3.423060742250904, 50.112643850884915]],
    [ "Enedis",[3.411624, 50.122340918239004]],
    [ "Enedis",[3.406298899534038, 50.119326564382305]],
    [ "Enedis",[3.421573160374149, 50.121752347193876]],
    [ "Enedis",[3.402010996851103, 50.133125116059375]],
    [ "Enedis",[3.409837371698638, 50.11440090895618]],
    [ "Enedis",[3.427215438813475, 50.120766574677376]],
    [ "Enedis",[3.4131171013479777, 50.11458816025961]],
    [ "Enedis",[-0.7123716844884488, 44.792748459405935]],
    [ "Enedis",[-0.5074623566787001, 43.33557202370638]],
    [ "Enedis",[6.278853759961478, 43.184659930781265]],
    [ "Enedis",[2.7744011880655415, 49.70652265786256]],
    [ "Enedis",[6.524014589127337, 45.82322839995402]],
    [ "Les Glieres",[6.503551338237023, 45.847436654639246]],
    [ "Enedis",[6.844274, 47.49317899999999]],
    [ "Enedis",[6.854926840909091, 47.49542498484849]],
    [ "Enedis",[6.845248000000001, 47.49591650000001]],
    [ "Enedis",[6.852292511368016, 47.4960716628131]],
    [ "Enedis",[6.840665622813323, 47.49413620560747]],
    [ "Enedis",[6.863678499999999, 47.509299]],
    [ "Enedis",[6.852448129801565, 47.53952080593481]],
    [ "Enedis",[6.848682756778965, 47.535957340454665]],
    [ "Enedis",[6.850545723061492, 47.53838109634941]],
    [ "Enedis",[6.8416245, 47.53290199999999]],
    [ "Enedis",[6.848016499999999, 47.543327000000005]],
    [ "Enedis",[6.844708903083699, 47.54069226578561]],
    [ "Enedis",[6.850607994174909, 47.54320427052912]],
    [ "Enedis",[6.838834999999999, 47.536822]],
    [ "Enedis",[6.8421345216572504, 47.5358268945386]],
    [ "Enedis",[6.837581328334896, 47.53457129956264]],
    [ "Enedis",[6.841994168314322, 47.530366448922685]],
    [ "Enedis",[6.8384437283135195, 47.527955944517835]],
    [ "Enedis",[6.8436875, 47.52309050000001]],
    [ "Enedis",[6.840671891812865, 47.52589672807018]],
    [ "Enedis",[6.838591101010101, 47.53095723232323]],
    [ "Enedis",[6.836095045378503, 47.521527108741104]],
    [ "Enedis",[6.835368674565558, 47.52766627856766]],
    [ "Enedis",[6.845467937853109, 47.52080260703075]],
    [ "Enedis",[6.830157488772618, 47.524695875953775]],
    [ "Enedis",[6.828025744685663, 47.52215405427409]],
    [ "Enedis",[6.849004516363636, 47.52195215474747]],
    [ "Enedis",[-0.29501871655072076, 49.294442262633574]],
    [ "Enedis",[-0.30837743915343935, 49.29337168823769]],
    [ "Enedis",[-0.3048490206103843, 49.290760086008724]],
    [ "Enedis",[-0.30954562151224685, 49.291673658359954]],
    [ "Enedis",[-0.3083726530612245, 49.29655666666666]],
    [ "Enedis",[-1.2824960500000002, 46.8355215]],
    [ "Enedis",[-1.1993105500000003, 46.81927325]],
    [ "Centrale de Queige",[6.4605390147141755, 45.716131704081675]],
    [ "Enedis",[2.216191161974079, 48.68930454089254]],
    [ "Enedis",[2.226821987394661, 48.68598702159904]],
    [ "Enedis",[2.2075901802721085, 48.69562216836734]],
    [ "Enedis",[2.2227681260712675, 48.697904604194854]],
    [ "VY Lisses",[2.215958821875955, 48.68781148304308]],
    [ "Enedis",[2.218136618822603, 48.69854586079936]],
    [ "Enedis",[2.2185931700680275, 48.69167201814059]],
    [ "Enedis",[2.211064821138211, 48.68829966666666]],
    [ "Aubigny",[2.2114974999999997, 48.693638]],
    [ "Enedis",[2.2144067388888895, 48.696514414814814]],
    [ "Enedis",[2.217175950155763, 48.68570779587598]],
    [ "Enedis",[2.2230440678466077, 48.69227013569322]],
    [ "Enedis",[2.245412285956006, 48.69771989170896]],
    [ "Enedis",[2.2142225254577377, 48.69117044118385]],
    [ "Enedis",[2.2081010957731406, 48.68829662921349]],
    [ "Enedis",[2.2473101701149427, 48.70022881685824]],
    [ "Enedis",[2.2456309801633605, 48.70252367133411]],
    [ "Enedis",[2.2056424369946397, 48.68594488725356]],
    [ "Enedis",[2.8345295000000004, 49.4298455]],
    [ "Enedis",[6.6454022218103175, 43.33283465492742]],
    [ "Enedis",[6.646198899999999, 43.33457009999999]],
    [ "Enedis",[6.650415178281649, 43.335814527509164]],
    [ "Enedis",[6.650110224287484, 43.338997940520436]],
    [ "Enedis",[6.647093870629811, 43.33790729862425]],
    [ "Enedis",[5.8921695326541235, 45.77908944703696]],
    [ "Enedis",[3.50848863393662, 50.37783827917619]],
    [ "Enedis",[2.644884461434371, 50.327589325214255]],
    [ "Enedis",[2.641240322117541, 50.33176552355316]],
    [ "Enedis",[2.638417565762613, 50.32930917789444]],
    [ "Enedis",[6.01878184613549, 46.61978503408082]],
    [ "Enedis",[-0.5196032594874777, 49.344206996614616]],
    [ "Enedis",[-0.5214124165677353, 49.33590480451128]],
    [ "Enedis",[-0.5254315, 49.33771374999999]],
    [ "Enedis",[-0.5315184999999997, 49.3289713]],
    [ "Enedis",[-0.5148499136676592, 49.34445647610386]],
    [ "Enedis",[-0.514946, 49.343033500000004]],
    [ "Enedis",[-0.5234153926552679, 49.3405320317275]],
    [ "Enedis",[-0.52713655, 49.33694020000001]],
    [ "Enedis",[-0.5297585000000001, 49.3314755]],
    [ "Enedis",[2.643115965397924, 50.334863668973476]],
    [ "Enedis",[2.651950999999999, 50.34717599999999]],
    [ "Enedis",[2.652382000000001, 50.349233]],
    [ "Enedis",[2.656259389616691, 50.34963229597283]],
    [ "Enedis",[1.9310499868042523, 47.87816509330832]],
    [ "Enedis",[1.9165146828703705, 47.8846770324074]],
    [ "Enedis",[1.924118212956883, 47.88041865878748]],
    [ "Enedis",[1.9221618126266804, 47.88710121902376]],
    [ "Enedis",[1.9181366725339204, 47.88724596332967]],
    [ "Enedis",[1.9136731500000002, 47.888545449999995]],
    [ "Enedis",[1.9269846239316237, 47.88358932478632]],
    [ "Enedis",[1.927572826357678, 47.89011025177903]],
    [ "218750835",[1.9190777133917396, 47.89436106716729]],
    [ "Enedis",[1.9258059999999992, 47.878184]],
    [ "Enedis",[1.927753120676101, 47.87371962775157]],
    [ "Enedis",[1.9182156611301262, 47.882468529273204]],
    [ "Enedis",[1.9290607344141268, 47.89249668264328]],
    [ "Enedis",[1.9434506728334666, 47.87430048448555]],
    [ "Enedis",[1.9425626691792295, 47.873980020100504]],
    [ "Enedis",[1.9296040681114552, 47.8802098255934]],
    [ "Enedis",[1.9309088602150537, 47.87415480645161]],
    [ "Enedis",[1.9116811756944432, 47.891784623611116]],
    [ "Enedis",[1.922738361142428, 47.87645758699736]],
    [ "218752065",[1.9201983488384615, 47.88572133334637]],
    [ "Enedis",[1.9358762467408883, 47.882978445047094]],
    [ "Coop Janvry",[3.871943820670774, 49.24562798973306]],
    [ "Enedis",[3.8758086725064236, 49.246074833450116]],
    [ "Enedis",[-1.3270560000000007, 46.71809799999999]],
    [ "ZAC AjoncsEst",[-1.3650912407004565, 46.695249249380026]],
    [ "La Borderie Fer",[-1.3127640000000003, 46.709046]],
    [ "Croix Rouge",[-1.317339, 46.71477899999999]],
    [ "Enedis",[-1.3101265764943224, 46.73634073476281]],
    [ "Enedis",[-1.3364832500000003, 46.702577250000004]],
    [ "La Lairière",[-1.3057630233702338, 46.7235356605166]],
    [ "Enedis",[-1.3123823238938055, 46.71685201533924]],
    [ "Le Stade",[-1.3186304999999992, 46.717979500000006]],
    [ "Enedis",[-1.3084449999999994, 46.709703999999995]],
    [ "Enedis",[-1.3296830183091004, 46.71971386914377]],
    [ "Gazon",[-1.3384280000000004, 46.70389350000001]],
    [ "Enedis",[-1.3019647283950615, 46.72528233333334]],
    [ "FER901 AC3M CBLANC",[-1.325242394636016, 46.70775468582376]],
    [ "Enedis",[-1.3322472499999998, 46.70398425]],
    [ "Enedis",[-1.3791472500000006, 46.721885500000006]],
    [ "Les Genêts",[-1.3207879609810478, 46.70757768896321]],
    [ "Enedis",[-1.3176759999999992, 46.7210295]],
    [ "Le Puits",[-1.3093342500000003, 46.7117215]],
    [ "P89 Patis de la Gasse",[-1.32926175, 46.718633999999994]],
    [ "Enedis",[-1.3143765, 46.715065249999995]],
    [ "La Moraine",[-1.3201762500000007, 46.713221000000004]],
    [ "Les Acacias Fer",[-1.3102912500000001, 46.706809750000005]],
    [ "Route de la Roche",[-1.317822, 46.71086725000001]],
    [ "Audouardière",[-1.3256082500000002, 46.7177595]],
    [ "Route des Essarts 02",[-1.3073638110100194, 46.71798746453766]],
    [ "Enedis",[-1.3507260678624886, 46.723594902971435]],
    [ "La Paillerie N12 FER",[-1.284423, 46.7203147]],
    [ "Enedis",[-1.3827143333234326, 46.73565960023762]],
    [ "Caillou Blanc",[-1.3272615000000003, 46.715928]],
    [ "Aveneau",[-1.3231690000000005, 46.71643475]],
    [ "Les Trainelles",[-1.308105, 46.7080665]],
    [ "ZA Artipôle 94",[-1.3287312989690707, 46.72172597594501]],
    [ "Les Floralies",[-1.3142333247061322, 46.718155244300505]],
    [ "FER n°4 Château d'Eau",[-1.312513, 46.710241749999994]],
    [ "Bout de Ville",[-1.307361, 46.713502250000005]],
    [ "Florine",[-1.323546544936086, 46.71322453982301]],
    [ "Enedis",[2.635948303722504, 50.35350603384096]],
    [ "Enedis",[2.6388282, 50.3500356]],
    [ "Enedis",[2.6327602392638036, 50.34818993251533]],
    [ "Enedis",[2.7970741580397283, 50.262309480208785]],
    [ "Enedis",[-0.6825149240354661, 44.785571868439966]],
    [ "Enedis",[-0.6415748474576269, 44.785724664585196]],
    [ "Enedis",[-0.6734305639733408, 44.786533606719615]],
    [ "Enedis",[2.7984236810989955, 50.27179194868505]],
    [ "Enedis",[-0.641137079206415, 44.78673706905849]],
    [ "Enedis",[-0.6501318811594204, 44.79044631014492]],
    [ "Hôtel",[-0.64304675, 44.78580350000001]],
    [ "Enedis",[-0.6530401382113822, 44.78857519512196]],
    [ "Enedis",[-0.6876067499999999, 44.78265149999999]],
    [ "Enedis",[-0.670254756862745, 44.78562101633987]],
    [ "Enedis",[-0.6839888449014567, 44.782813986289625]],
    [ "Enedis",[-0.6447755499999996, 44.78826024999999]],
    [ "Enedis",[-0.6380215243140277, 44.78743569433672]],
    [ "Enedis",[-0.6813941380317267, 44.78569101098313]],
    [ "Enedis",[-0.6455047244372989, 44.786120540434084]],
    [ "Enedis",[2.7854592708362342, 50.233863540290336]],
    [ "Enedis",[2.7927338875, 50.23297165833334]],
    [ "Enedis",[-0.65579945, 44.78913495]],
    [ "Enedis",[-1.566341968495771, 48.803795049942316]],
    [ "Enedis",[-1.5478863384596078, 48.8157641274547]],
    [ "Enedis",[-1.5393645039907673, 48.81091899784185]],
    [ "Enedis",[-1.5648690719231233, 48.81757916458895]],
    [ "Enedis",[-1.555875082403927, 48.81586806205453]],
    [ "Enedis",[-1.5695466657168389, 48.816905021626454]],
    [ "Enedis",[-1.5601389959810044, 48.80641500498596]],
    [ "Enedis",[-1.563987687631785, 48.81095764892841]],
    [ "Enedis",[-1.558463936617484, 48.81504824094143]],
    [ "Enedis",[-1.5639284608385033, 48.78745946206593]],
    [ "Enedis",[-1.5665625043976343, 48.805173503484056]],
    [ "Enedis",[-1.5464163471691426, 48.814210637739045]],
    [ "Enedis",[-1.5656220079061398, 48.79540999629322]],
    [ "Enedis",[-1.5639658052681158, 48.794210030213726]],
    [ "Enedis",[-1.5688855458465585, 48.79070931349843]],
    [ "Enedis",[-1.5492520026168923, 48.81399249527028]],
    [ "Enedis",[-1.5582962703015684, 48.816200809742845]],
    [ "Enedis",[-1.5660762822886474, 48.79995985007454]],
    [ "Enedis",[-1.567333992138005, 48.813606000056936]],
    [ "Enedis",[-1.5584663058746424, 48.80858349888983]],
    [ "Enedis",[-1.566370251346251, 48.79199595581689]],
    [ "Enedis",[-1.5690075082115649, 48.81082549970958]],
    [ "Enedis",[-1.5641471007099585, 48.815301097492835]],
    [ "Enedis",[-1.5455189864363805, 48.794490511721015]],
    [ "Enedis",[-1.5387901959394112, 48.807674426172724]],
    [ "Enedis",[-1.5420383978538927, 48.81084349591685]],
    [ "Enedis",[-1.5584476312180644, 48.81084721498982]],
    [ "Enedis",[-1.567970354691474, 48.796017483676145]],
    [ "Enedis",[-1.5600292658369108, 48.812960090131405]],
    [ "Enedis",[-0.5193588677690442, 49.26680134057802]],
    [ "Enedis",[2.8057378562091504, 50.25793029411765]],
    [ "RTE",[2.429557482995164, 47.482698808263535]],
    [ "RTE",[2.196996891644878, 47.85924024850399]],
    [ "Jussy",[6.077681518152459, 46.062047947635286]],
    [ "Enedis",[6.069460174708821, 46.06847709567387]],
    [ "Enedis",[6.074980961969592, 46.06060245949625]],
    [ "Enedis",[3.289769, 47.73626475]],
    [ "Enedis",[-1.2396487500000002, 46.76965955]],
    [ "Enedis",[-1.2364132499999994, 46.76722875000001]],
    [ "Enedis",[-1.2346670832441835, 46.776435599295574]],
    [ "Enedis",[-1.2471528992374434, 46.761315733269605]],
    [ "Enedis",[-1.2337734999999983, 46.7653075]],
    [ "Enedis",[-1.2357327500000002, 46.773086]],
    [ "Enedis",[-1.2351659500000003, 46.7650771]],
    [ "Enedis",[-1.22249925, 46.779006]],
    [ "Enedis",[-1.2237625000000008, 46.77104175]],
    [ "Enedis",[-1.2272439800687764, 46.778594495213525]],
    [ "Enedis",[-1.2365309999999994, 46.76869925]],
    [ "Poste Électrique de Vieux-Pré",[6.9331170922236165, 48.44883606442975]],
    [ "Enedis",[3.501723574068554, 50.38156682185793]],
    [ "Enedis",[3.5044258311910874, 50.380787741216785]],
    [ "Enedis",[5.580896784674329, 45.524570767816094]],
    [ "Bois joli",[-0.37092140132122114, 49.325067760803734]],
    [ "Enedis",[1.0637172759577056, 49.39759727630438]],
    [ "Enedis",[6.575034163027658, 45.836665471615724]],
    [ "Plaine des Varins",[6.5735291, 45.833886500000006]],
    [ "Cristal Arly",[6.571380500000001, 45.83586850000001]],
    [ "Enedis",[6.577168, 45.8377728]],
    [ "Mont Charvin",[6.570659972460005, 45.83692015306338]],
    [ "Enedis",[6.566994750569528, 45.835293892283374]],
    [ "Enedis",[5.348275769621922, 43.22487677804418]],
    [ "Enedis",[3.210480911138411, 48.459625131040134]],
    [ "Enedis",[0.6776709000247461, 49.59496684855233]],
    [ "Enedis",[0.6754686053639847, 49.593963854022995]],
    [ "Enedis",[0.6803776540634507, 49.59378790830073]],
    [ "Salle Polyvalente",[6.2016413073867875, 46.12383292604005]],
    [ "Le Mont",[6.213182199999999, 46.1150921]],
    [ "Enedis",[6.210116600000001, 46.13246259999999]],
    [ "Champ Bénit",[6.206968999999999, 46.12845440000001]],
    [ "Mairie",[6.205114568199302, 46.12447360616703]],
    [ "Enedis",[-0.5678138172246373, 42.90145774801787]],
    [ "Enedis",[2.7210593762287756, 50.247972674709565]],
    [ "Enedis",[2.734287518847007, 50.25362028307465]],
    [ "Enedis",[2.717078134787879, 50.24740695806061]],
    [ "Enedis",[2.7257691642228745, 50.245122134408604]],
    [ "Enedis",[2.7182250541591095, 50.24324796899023]],
    [ "Enedis",[3.5120706514247844, 50.38542984691849]],
    [ "Enedis",[3.5158933333333344, 50.38618366666668]],
    [ "Enedis",[3.5067623140096615, 50.385064014492755]],
    [ "RTE",[2.836206518372088, 47.33841359126058]],
    [ "RTE",[2.545459675047466, 47.30322802795486]],
    [ "Enedis",[3.0569941475276536, 47.647625923407965]],
    [ "Enedis",[2.6402060953231503, 47.45147111679238]],
    [ "Enedis",[5.664755566995091, 45.34172216752214]],
    [ "Vovray-en-Bornes",[6.14227578937918, 46.066282405593874]],
    [ "Chez Vardon",[6.14414935, 46.06516140000001]],
    [ "Ostrohove",[1.6338209807184687, 50.71646393074321]],
    [ "Enedis",[3.5007128998941797, 50.386510763174606]],
    [ "Enedis",[3.4998466218755713, 50.38349150802774]],
    [ "Enedis",[3.5147906712962964, 50.39194331481482]],
    [ "RTE",[3.6376778446278477, 50.39514808384956]],
    [ "Enedis",[2.61747471605362, 47.119490380333495]],
    [ "Enedis",[2.6258890877192993, 47.12368951754386]],
    [ "Enedis",[2.6905703578411817, 50.33773128404243]],
    [ "Enedis",[2.69024325, 50.347404600000004]],
    [ "Enedis",[2.669724792602377, 50.3418215363276]],
    [ "Enedis",[2.6818403991614255, 50.3426002538784]],
    [ "Enedis",[6.532290904369414, 45.46705765700099]],
    [ "Enedis",[2.849771733683082, 50.340986334461554]],
    [ "Enedis",[2.8529983089306894, 50.3392979649212]],
    [ "Enedis",[2.8527581449150925, 50.338392947338285]],
    [ "Enedis",[6.533055, 48.256482500000004]],
    [ "Enedis",[6.534747860396039, 48.25556611324846]],
    [ "Enedis",[2.870050357324609, 50.301298050163574]],
    [ "Enedis",[2.860792713498623, 50.30042465564739]],
    [ "Enedis",[6.508327649999999, 45.45909415]],
    [ "Le puits",[6.510013034704861, 45.46867650263572]],
    [ "Poste électrique Manom",[6.176593319357527, 49.36815203965711]],
    [ "Poste électrique de Roussy",[6.171903062092874, 49.44486684618281]],
    [ "Enedis",[1.0079196706827311, 49.564409963855425]],
    [ "Savouraire",[5.6078434419208225, 44.872144931025]],
    [ "Les Gorges",[6.1238341272543595, 45.00322204435982]],
    [ "Enedis",[6.680926699999999, 48.23995699999999]],
    [ "Poste électrique de Brailly-Cornehotte",[1.9678257486544088, 50.23634274716583]],
    [ "RTE",[2.042831396314012, 50.371502687100474]],
    [ "Enedis",[1.1896685118483405, 49.197593244865715]],
    [ "Enedis",[2.6990276822830745, 50.23487534235126]],
    [ "Enedis",[2.6748735631055744, 50.22906081515037]],
    [ "Enedis",[2.6812708167456556, 50.22989346498157]],
    [ "Enedis",[2.705726867861886, 50.231581952191235]],
    [ "Enedis",[2.677603315398412, 50.227435694337686]],
    [ "Poste électrique de Erstein",[7.632707411962545, 48.4266066952063]],
    [ "RTE",[0.3290451935332549, 47.585988424635865]],
    [ "Enedis",[6.4556146666666665, 45.58520055555555]],
    [ "Enedis",[6.459693260869566, 45.58725559420291]],
    [ "Enedis",[6.446513856527502, 45.59691216037111]],
    [ "Enedis",[6.822418211938438, 48.859660981195525]],
    [ "Enedis",[-0.9947427000000002, 46.75904115]],
    [ "Enedis",[-1.06354465, 46.779782999999995]],
    [ "Enedis",[-1.0596840000000007, 46.78311155000001]],
    [ "Enedis",[-1.0627485, 46.77526625]],
    [ "Enedis",[-1.062233928757205, 46.780183809862116]],
    [ "Enedis",[1.150064775837279, 46.87490165961775]],
    [ "Enedis",[6.097096571093859, 45.42179027506289]],
    [ "Enedis",[2.1710056499999997, 45.8803535]],
    [ "Enedis",[2.1851805637254906, 45.88853328921569]],
    [ "SNCF Réseau",[-1.4350840014438768, 46.68875578812254]],
    [ "Enedis",[4.790099705524465, 45.414094678494294]],
    [ "Enedis",[1.774453698211877, 45.810208094973]],
    [ "Enedis",[1.777281342465753, 45.81818610045662]],
    [ "Sous-station d'Imling",[7.02999676590646, 48.72801309914867]],
    [ "Enedis",[3.4730852588839345, 50.38410847304418]],
    [ "Enedis",[3.4741423918317826, 50.38350697904031]],
    [ "RTE",[-1.1548702147505354, 47.00242694088404]],
    [ "Enedis",[3.475320318892901, 50.378734976276434]],
    [ "Enedis",[3.4728201764596167, 50.37798850811416]],
    [ "Enedis",[3.4825455, 50.38249399999999]],
    [ "Enedis",[3.478314486848398, 50.38007433763749]],
    [ "Enedis",[3.474945, 50.376234499999995]],
    [ "Enedis",[3.4903655000000007, 50.370343500000004]],
    [ "Enedis",[3.4851937687019823, 50.36487684890147]],
    [ "Enedis",[3.491310333333334, 50.374354000000004]],
    [ "Enedis",[3.486905816611388, 50.37082371337756]],
    [ "Enedis",[1.6384251735493434, 45.70746915681915]],
    [ "Enedis",[4.201146994446843, 48.76901769805723]],
    [ "Valeille",[1.2323721439986575, 44.13047818404118]],
    [ "Moulin à Vent",[1.24054035, 44.13224914999999]],
    [ "Lunel",[1.210944940980846, 44.1448656706375]],
    [ "Enedis",[1.2468841499999994, 44.12427755000001]],
    [ "Enedis",[2.5093365, 46.9914715]],
    [ "Enedis",[2.503544500000001, 46.9959675]],
    [ "Enedis",[0.5986740011695901, 44.16454173099415]],
    [ "Enedis",[0.5980025, 44.16651399999999]],
    [ "Enedis",[1.7599625895699287, 50.46599365312111]],
    [ "Enedis",[1.7652535790258803, 50.466756429029616]],
    [ "Enedis",[1.761086762051086, 50.460519236540556]],
    [ "Enedis",[1.7584557369181846, 50.46065064989881]],
    [ "Enedis",[1.7704726666666672, 50.46685416666667]],
    [ "Enedis",[3.4785559114484506, 50.368098921568624]],
    [ "Enedis",[3.475591784424457, 50.36882827936973]],
    [ "Enedis",[1.6954774936108066, 45.64894639028842]],
    [ "Enedis",[1.6604878294573646, 45.66909048372092]],
    [ "Enedis",[1.650215155179363, 45.664542979443766]],
    [ "Enedis",[1.646155536258379, 45.66452430652041]],
    [ "Enedis",[3.4704651375527424, 50.372730292827015]],
    [ "Enedis",[3.463255734275963, 50.373208071347314]],
    [ "Enedis",[3.4687242332822867, 50.37408055181214]],
    [ "Enedis",[3.4727600000000005, 50.373704000000004]],
    [ "Enedis",[3.4731265970102543, 50.37179128989591]],
    [ "Enedis",[3.4704349518624644, 50.37610510563514]],
    [ "Enedis",[1.2855278999999997, 44.1926025]],
    [ "poste_587",[2.8281075, 49.449624500000006]],
    [ "poste_585",[2.829936916468121, 49.44806119923802]],
    [ "Enedis",[2.285264, 49.1693135]],
    [ "Enedis",[2.2878669999999994, 49.175983499999994]],
    [ "Enedis",[2.282985657142857, 49.176810292857155]],
    [ "Enedis",[3.6626895000000004, 50.41291]],
    [ "Enedis",[3.6623590411954767, 50.41624669763059]],
    [ "Enedis",[3.6580749343025096, 50.41455938918018]],
    [ "Fontaine de Kerandon",[-3.563744, 47.867197499999996]],
    [ "Enedis",[3.6602447619734093, 50.428626592503605]],
    [ "Enedis",[3.661317494791666, 50.42100739753605]],
    [ "Enedis",[3.6605407250842554, 50.418671763761836]],
    [ "Enedis",[3.6572001594848937, 50.42371031253096]],
    [ "Enedis",[3.6547302177589853, 50.421106440149]],
    [ "Enedis",[3.3263188742086434, 50.27646584503165]],
    [ "Enedis",[3.3192008305355714, 50.28035417665867]],
    [ "Enedis",[3.3223542335957994, 50.28011298687663]],
    [ "Enedis",[-0.25544412878787864, 47.56038542708334]],
    [ "Enedis",[-0.230894, 47.55423749999999]],
    [ "Enedis",[-0.23385783728278126, 47.5562659943128]],
    [ "Enedis",[4.582591111314379, 44.862453624221075]],
    [ "Tournemire",[3.01967230801604, 43.9661648336568]],
    [ "Enedis",[2.663700861386139, 50.24369363696369]],
    [ "Enedis",[2.652711414864316, 50.24360647873633]],
    [ "Enedis",[2.6570923755569704, 50.24117132357309]],
    [ "Enedis",[2.668179215579711, 50.2441113786232]],
    [ "Enedis",[2.6656331088017633, 50.24208678255394]],
    [ "Enedis",[1.9601123845271384, 50.945333806983584]],
    [ "Enedis",[1.9337724059126384, 50.9489418001993]],
    [ "Enedis",[1.949167832867784, 50.94958945996275]],
    [ "Enedis",[1.9556430360638855, 50.94852498402885]],
    [ "Enedis",[1.946208616941126, 50.94743976934669]],
    [ "Enedis",[1.9298652525252524, 50.946053343434336]],
    [ "Enedis",[1.9633732670713195, 50.946936161102684]],
    [ "Enedis",[1.9551003974716095, 50.955030687379484]],
    [ "Enedis",[1.9271550928381953, 50.950621200707346]],
    [ "Enedis",[1.9395317635135134, 50.945753475225224]],
    [ "Enedis",[1.978768712121212, 50.95306733333334]],
    [ "Enedis",[1.941557500000001, 50.946122]],
    [ "Enedis",[1.9524313885720672, 50.95002880252711]],
    [ "Enedis",[1.9582059404170806, 50.94667132886462]],
    [ "Enedis",[1.9553908372525244, 50.946000287999986]],
    [ "Enedis",[1.9250851466451673, 50.94696460557197]],
    [ "Enedis",[1.9247768616394925, 50.94976028917573]],
    [ "Enedis",[6.466465915343915, 45.55273141798941]],
    [ "Enedis",[6.08535675, 48.48723344999999]],
    [ "Enedis",[3.3035283935061064, 50.28440219779565]],
    [ "Enedis",[3.311677129533679, 50.282566246977545]],
    [ "Enedis",[3.317014, 50.28369249999999]],
    [ "Enedis",[3.313543, 50.283401500000004]],
    [ "Enedis",[1.8262986015117644, 50.957122722027044]],
    [ "Enedis",[1.8237234999999998, 50.954386500000005]],
    [ "Enedis",[1.8215599315726296, 50.955958211684674]],
    [ "Enedis",[1.770004311174165, 50.94780580718822]],
    [ "Enedis",[1.7671922782331515, 50.9475420701275]],
    [ "Enedis",[1.7644158532580803, 50.94752227107918]],
    [ "Enedis",[1.7766175, 50.9524045]],
    [ "Enedis",[1.8200780000000008, 50.958966999999994]],
    [ "Enedis",[1.7520337227722775, 50.94191687615078]],
    [ "Enedis",[1.7570469999999996, 50.947338499999994]],
    [ "Enedis",[1.8223095748930105, 50.957301782215886]],
    [ "Enedis",[1.7532546885409468, 50.94574853572799]],
    [ "Enedis",[1.7439170000000002, 50.941767000000006]],
    [ "Enedis",[1.8101889292819673, 50.95503421956954]],
    [ "Enedis",[1.5249702089387036, 47.41553352608275]],
    [ "Enedis",[6.438218609278351, 45.61226553024055]],
    [ "Enedis",[3.320153258054803, 50.286007611035835]],
    [ "Enedis",[3.3181504966623314, 50.28904783355006]],
    [ "Enedis",[3.314251434106308, 50.28750328638229]],
    [ "Enedis",[1.4942803743589743, 45.61315765692307]],
    [ "Enedis",[1.4956409914738118, 45.61452774462039]],
    [ "Enedis",[2.5858884823625923, 50.35051116871753]],
    [ "Enedis",[2.5890620715778465, 50.35177548380354]],
    [ "Enedis",[2.595518230982019, 50.35271455288151]],
    [ "Enedis",[2.5826037840486915, 50.348453593470865]],
    [ "221341505",[2.589890528047337, 50.35514782871795]],
    [ "Enedis",[2.5853239822316993, 50.357747670931055]],
    [ "SNCF Réseau",[1.8066437999999998, 50.90821]],
    [ "Sous-station Revest",[2.352921229756714, 48.91302112410347]],
    [ "Enedis",[2.5911287268518515, 50.97939484722222]],
    [ "Enedis",[2.5859810793950855, 50.98041386137366]],
    [ "Enedis",[2.5802281654320995, 50.97805417530864]],
    [ "Enedis",[2.5824064684753534, 50.97927768271557]],
    [ "Enedis",[2.5876414999999997, 50.97588150000001]],
    [ "Enedis",[2.5888042071754156, 50.974415453903845]],
    [ "Enedis",[2.584174, 50.97554040000001]],
    [ "Enedis",[2.583557679803983, 50.97652888343239]],
    [ "Enedis",[2.586478749969753, 50.973817073926185]],
    [ "Enedis",[2.868526685497013, 50.27637196668478]],
    [ "Enedis",[2.892517, 50.2710615]],
    [ "Enedis",[2.856864110086456, 50.27281757848223]],
    [ "Enedis",[2.8538041757487687, 50.27134038919835]],
    [ "Enedis",[2.88783005, 50.272603950000004]],
    [ "Moidieu Bourg",[5.016999784893026, 45.51134178245264]],
    [ "Enedis",[2.8883249478044744, 50.32795636371168]],
    [ "Enedis",[2.885117, 50.328520499999996]],
    [ "Enedis",[2.8885976303377947, 50.33080670911408]],
    [ "Enedis",[1.49953945, 45.94712685000001]],
    [ "Enedis",[2.5771578362235066, 50.97593445279384]],
    [ "Enedis",[2.5818214587570627, 50.980532168361584]],
    [ "Enedis",[2.61239, 50.3528885]],
    [ "Enedis",[2.6160952409420295, 50.3511159384058]],
    [ "Enedis",[2.604754749206349, 50.35342705396825]],
    [ "Enedis",[2.884403833237087, 50.200654498107156]],
    [ "Enedis",[2.884071999999999, 50.20478149999999]],
    [ "Enedis",[2.8820993068475453, 50.202070350129205]],
    [ "Enedis",[2.8743888620689653, 50.20015301149426]],
    [ "Enedis",[2.8805344743589743, 50.19933783076923]],
    [ "Enedis",[2.883832340909091, 50.196596539772735]],
    [ "Enedis",[2.8849609756340127, 50.19930243560418]],
    [ "Enedis",[2.88017864, 50.19639357333334]],
    [ "Enedis",[2.876209829268293, 50.19801897398374]],
    [ "Enedis",[6.150873843917851, 43.083128836018965]],
    [ "Enedis",[1.1891100000000001, 47.9057462]],
    [ "Enedis",[4.0220863, 43.695741850000005]],
    [ "Bègles (client)",[-0.5368192549377385, 44.8153652300147]],
    [ "Bassens",[-0.5387267282522334, 44.91608928645838]],
    [ "Bassens (client)",[-0.5261085472271465, 44.91616286911024]],
    [ "Poste électrique de Hastignan",[-0.742623562122802, 44.89098386112943]],
    [ "Enedis",[3.377020735094038, 50.18926140336135]],
    [ "Enedis",[3.376543002123142, 50.196389944798305]],
    [ "Enedis",[6.237159100000001, 46.188631199999996]],
    [ "Enedis",[6.1876455, 43.168346]],
    [ "Enedis",[3.387891018094615, 50.193756855533756]],
    [ "Enedis",[3.38639668852459, 50.1905717704918]],
    [ "Enedis",[3.3857397712596637, 50.18962596816735]],
    [ "Enedis",[3.3864043176150056, 50.194629533794405]],
    [ "Enedis",[3.391299486654251, 50.19401532774674]],
    [ "Poste électrique de Taute",[-1.3612980699610704, 49.20292481021428]],
    [ "Enedis",[1.4249063421439068, 47.617358024963295]],
    [ "Enedis",[1.412405408163265, 47.613630750340135]],
    [ "Enedis",[1.4218453623188403, 47.61623094202899]],
    [ "Enedis",[1.4208425, 47.614401]],
    [ "Enedis",[1.416997937853107, 47.61804020903955]],
    [ "Enedis",[1.4297204480431855, 47.605130352226716]],
    [ "Enedis",[0.9078189886363635, 48.84557647916667]],
    [ "Enedis",[6.129505595854924, 43.08572720552678]],
    [ "Enedis",[6.130042500000001, 43.092812]],
    [ "Enedis",[3.370462164057023, 50.19909050236815]],
    [ "Enedis",[3.3822048140224137, 50.19783385125498]],
    [ "Enedis",[3.377011859315589, 50.19813099239543]],
    [ "Enedis",[3.3821655005356703, 50.196397356096526]],
    [ "Enedis",[-1.5098762500000003, 46.641873000000004]],
    [ "Enedis",[-1.50140575, 46.631427]],
    [ "Enedis",[-1.505598, 46.63192225]],
    [ "Enedis",[-1.44176375, 46.64069975]],
    [ "Enedis",[-1.5128582, 46.624188000000004]],
    [ "Enedis",[-1.5078532500000013, 46.6494275]],
    [ "Enedis",[3.396301331111111, 48.4110850088889]],
    [ "Enedis",[5.899681000000001, 47.25972050000001]],
    [ "Enedis",[2.7794759999999994, 50.13018099999999]],
    [ "Enedis",[2.783895336996337, 50.130843518925516]],
    [ "Enedis",[2.779101657565415, 50.13326275767918]],
    [ "Enedis",[2.7835247740740745, 50.13213975185186]],
    [ "Enedis",[2.761504517647059, 50.13813197124183]],
    [ "Enedis",[2.7772822499999994, 50.13057275]],
    [ "Enedis",[1.0750041727598574, 47.33167052043011]],
    [ "Enedis",[-1.4466394267626101, 46.60997423515512]],
    [ "Enedis",[-1.4474658499999993, 46.60577465]],
    [ "Enedis",[-1.4495132999999998, 46.60425845]],
    [ "Poste électrique de la Glacière",[-0.611507163016827, 44.83774954187585]],
    [ "Poste électrique de Vayres",[-0.32555438159801975, 44.90203416719867]],
    [ "Poste électrique de Guitayne",[-0.6678650324356734, 44.76256651840283]],
    [ "Enedis",[2.7491392866593167, 50.35439644615949]],
    [ "Enedis",[2.75671986863711, 50.355758844827584]],
    [ "Enedis",[2.7669996538461543, 50.36146606410257]],
    [ "Enedis",[2.7510566833890744, 50.35288846307135]],
    [ "Enedis",[2.760627010734221, 50.35478254186346]],
    [ "Enedis",[-0.10288476085504378, 47.4814872251169]],
    [ "Enedis",[2.8757512315789473, 50.24946444912281]],
    [ "Enedis",[2.8505427667076666, 50.26615432923329]],
    [ "Enedis",[2.8645654834456935, 50.261984773258426]],
    [ "222775695",[5.968941046854617, 47.323884117181365]],
    [ "222775717",[5.967789099999999, 47.32181645]],
    [ "Enedis",[5.970966862184433, 47.32267513263508]],
    [ "Enedis",[6.075522034857352, 47.397919996098516]],
    [ "Enedis",[2.8884546609799804, 50.25713017496301]],
    [ "Enedis",[-1.461971537214624, 46.56472932714614]],
    [ "Enedis",[-1.452968595925817, 46.56926081460985]],
    [ "Enedis",[-1.4573627594117398, 46.56658442054209]],
    [ "Enedis",[-1.4588374499999992, 46.56322835]],
    [ "Enedis",[-1.4517381999999999, 46.5947266]],
    [ "Enedis",[3.6121920000000007, 50.40224849999999]],
    [ "Enedis",[3.640836073593074, 50.39649176033058]],
    [ "Enedis",[3.6126121657826733, 50.39763500380796]],
    [ "Poste électrique de Barbezieux",[-0.15166953207435852, 45.45987772550213]],
    [ "Jean Lioud",[5.748479953470009, 45.43041473247448]],
    [ "Enedis",[-0.33566269337971527, 45.682118030111184]],
    [ "Enedis",[3.6209379999999998, 50.388065999999995]],
    [ "Enedis",[3.629663000000001, 50.393565]],
    [ "Enedis",[3.6229941463537716, 50.38333981175876]],
    [ "Enedis",[3.6243259415900484, 50.386139888047595]],
    [ "Enedis",[3.6299550000000003, 50.3869675]],
    [ "Enedis",[-0.09821769275929616, 47.91056404957599]],
    [ "Enedis",[3.2020092000000004, 50.11133370000001]],
    [ "Enedis",[3.205482924846215, 50.10991631210662]],
    [ "Enedis",[3.2094517811382115, 50.11152633154471]],
    [ "Enedis",[3.200911411347517, 50.108306143971625]],
    [ "Charréconduit",[4.8188746737608, 46.78988467569097]],
    [ "SER",[7.2966823191324615, 48.520333284630006]],
    [ "Enedis",[-0.6538805000000003, 44.803185500000005]],
    [ "Enedis",[-0.657303781636069, 44.80213747946095]],
    [ "Enedis",[3.2060994857322043, 50.121042976889306]],
    [ "Enedis",[3.19877436071753, 50.11806325099909]],
    [ "Enedis",[3.2063629093093082, 50.117766604504496]],
    [ "Enedis",[3.2012843, 50.12024039999999]],
    [ "Enedis",[3.207481, 50.12230220000001]],
    [ "Enedis",[3.210435298324357, 50.118515703091965]],
    [ "Enedis",[3.2032552175602205, 50.11905593815074]],
    [ "Enedis",[3.2079404213567164, 50.11712261911366]],
    [ "Enedis",[3.203977375372413, 50.11798767775198]],
    [ "Enedis",[2.676731794680695, 50.26531211603098]],
    [ "Enedis",[2.6680720701754383, 50.264450175438604]],
    [ "Enedis",[2.6714868414481896, 50.27690567915105]],
    [ "Enedis",[2.667600443740352, 50.2731671148753]],
    [ "Marosse",[5.895392775193798, 48.53023737984496]],
    [ "Enedis",[3.2121335000000006, 50.1227701]],
    [ "Enedis",[3.2158529693989073, 50.114042056284156]],
    [ "Enedis",[-0.3446028669103092, 42.97420589807793]],
    [ "Enedis",[6.422495662748939, 46.96724502481227]],
    [ "Enedis",[6.425767350000001, 46.97501985]],
    [ "Enedis",[6.422810598356208, 46.97061107141195]],
    [ "RTE",[5.656933157307705, 49.19583515840393]],
    [ "Poste Electrique de Wadonville",[5.6904349208565, 49.06698822369693]],
    [ "Enedis",[6.1525174339118065, 47.01339023373162]],
    [ "Enedis",[6.291656, 46.998175499999995]],
    [ "Enedis",[6.335944112269556, 46.99300487776398]],
    [ "Enedis",[6.293552363496871, 46.99889737379366]],
    [ "Enedis",[3.3291270612403108, 49.40013138372094]],
    [ "Enedis",[3.369948288017963, 49.369838654827525]],
    [ "Enedis",[3.341049396387356, 49.37478917645092]],
    [ "Enedis",[3.3697579292421205, 49.36799691146881]],
    [ "Enedis",[3.362109705627706, 49.3706956125541]],
    [ "Enedis",[3.3575661865650583, 49.37259128196443]],
    [ "Enedis",[3.3321717198776755, 49.37058607889909]],
    [ "Enedis",[-1.4029025, 46.591004000000005]],
    [ "Enedis",[-1.4028183, 46.593232300000004]],
    [ "Enedis",[-1.4073753005347591, 46.59458173329769]],
    [ "Enedis",[-1.3961166228535655, 46.58820189914552]],
    [ "Enedis",[-1.4058050999999998, 46.592179099999996]],
    [ "Enedis",[-1.44170425, 46.59459149999999]],
    [ "Enedis",[-1.4067420999999998, 46.6122999]],
    [ "Enedis",[2.9255852039534807, 47.40925597030245]],
    [ "Enedis",[2.923067266502867, 47.40402453202292]],
    [ "Enedis",[2.936089, 47.40048149999999]],
    [ "Enedis",[2.9312445366666666, 47.40291234888889]],
    [ "Enedis",[2.928089384625008, 47.405895766247575]],
    [ "Enedis",[2.9356125040316448, 47.3995296680359]],
    [ "Enedis",[2.926781148681056, 47.402345966426864]],
    [ "Enedis",[2.9388662610722607, 47.40125060839161]],
    [ "Enedis",[2.9276911342564715, 47.404699818181804]],
    [ "Enedis",[6.505680409871507, 46.24523131941609]],
    [ "Enedis",[3.443675072916666, 50.08262426041666]],
    [ "Enedis",[3.4400140000000015, 50.08268900000001]],
    [ "Enedis",[3.4407042195900974, 50.08576769124301]],
    [ "RTE",[6.15214457926915, 43.13506022709104]],
    [ "Vialis",[7.372489182023127, 48.082235944175856]],
    [ "Enedis",[3.749314, 46.5337775]],
    [ "Enedis",[3.7276766614173242, 46.53432122572178]],
    [ "Enedis",[3.7328631487179504, 46.5332543705803]],
    [ "Enedis",[3.4400667094878252, 50.08987594962217]],
    [ "Enedis",[3.435205489380274, 50.09565233304238]],
    [ "Enedis",[6.447293324988784, 45.587740993988334]],
    [ "Enedis",[6.471401, 49.243660500000004]],
    [ "Enedis",[5.205462669705851, 46.2030065170954]],
    [ "Enedis",[5.199218163157894, 46.19348287368421]],
    [ "Enedis",[5.2001574999999995, 46.2026715]],
    [ "Enedis",[5.202219868029271, 46.19325517991422]],
    [ "Enedis",[5.2068262247191015, 46.20001444194756]],
    [ "Enedis",[3.445884666666667, 50.092033460992916]],
    [ "Enedis",[6.11012062138529, 47.01807164345311]],
    [ "Enedis",[1.7952107894327094, 48.796030987531715]],
    [ "Enedis",[6.248628008882521, 46.81238031537727]],
    [ "Enedis",[5.841590335557039, 49.40295648276629]],
    [ "Enedis",[2.7935538540630183, 50.354841932006636]],
    [ "Enedis",[2.800272621199395, 50.35451844879333]],
    [ "Enedis",[2.80049863683818, 50.35848573825504]],
    [ "Enedis",[2.787320945273631, 50.35517421393035]],
    [ "Enedis",[2.795907987132671, 50.35591736028516]],
    [ "Enedis",[3.293904393582187, 50.02732417157825]],
    [ "Enedis",[5.827138849393912, 47.27351475613883]],
    [ "224327995",[5.872011244118316, 47.312929766757684]],
    [ "Enedis",[5.870147500000001, 47.30977600000001]],
    [ "Enedis",[6.322241558485959, 47.01816055543345]],
    [ "Enedis",[5.7738314261603385, 46.057446729957796]],
    [ "Enedis",[5.802550899224806, 46.05547376744187]],
    [ "Enedis",[5.778414807407408, 46.06150907037037]],
    [ "Enedis",[5.805878631226937, 46.05399254923893]],
    [ "RTE",[3.106302379612493, 47.88662762724653]],
    [ "Enedis",[5.7835145, 46.078706000000004]],
    [ "Enedis",[5.778093772821884, 46.08152864377043]],
    [ "Enedis",[5.945302645096056, 46.30675206740815]],
    [ "Enedis",[5.936378439094518, 46.297612667954084]],
    [ "Enedis",[5.932454692154115, 46.293826890655765]],
    [ "Enedis",[5.937986739962275, 46.301069548100244]],
    [ "Enedis",[5.925769597923938, 46.282610870030396]],
    [ "Enedis",[5.949994780938901, 46.300861916041534]],
    [ "Enedis",[5.995132312970106, 46.36587311928232]],
    [ "Enedis",[5.953074828343313, 46.32072852694611]],
    [ "Enedis",[5.94668436440678, 46.31062530734464]],
    [ "Enedis",[5.981286661762219, 46.35578625334009]],
    [ "Enedis",[6.053347560493194, 46.38869193493795]],
    [ "Enedis",[6.067878168340884, 46.55887252915703]],
    [ "Enedis",[6.068307329934139, 46.558218503080525]],
    [ "Enedis",[6.066444000000001, 46.557664499999994]],
    [ "RTE",[4.3836574518084905, 48.08129404017419]],
    [ "Enedis",[6.049305600000001, 46.660903250000004]],
    [ "Enedis",[6.0781370343788454, 46.6502064164073]],
    [ "Enedis",[6.074988606012237, 46.658443852673585]],
    [ "Enedis",[6.073222500000001, 46.65942]],
    [ "Enedis",[6.0478365, 46.65999870000001]],
    [ "Enedis",[6.057128854103412, 46.65459180134845]],
    [ "Enedis",[6.0573493, 46.663717299999995]],
    [ "Enedis",[6.032705012839589, 46.63798591168991]],
    [ "Enedis",[6.028708425155276, 46.63241999402561]],
    [ "Enedis",[5.695287498532109, 45.580781100316166]],
    [ "Enedis",[5.631465250000001, 46.24514500000001]],
    [ "Enedis",[5.63480425, 46.253268250000005]],
    [ "Enedis",[5.628502999999999, 46.23505875]],
    [ "Enedis",[5.631908697011698, 46.24301741525774]],
    [ "Enedis",[5.6330849999999995, 46.2517315]],
    [ "Enedis",[5.629925750000001, 46.238783]],
    [ "Enedis",[5.6354465, 46.2552985]],
    [ "Enedis",[5.63269175, 46.2396565]],
    [ "Enedis",[5.63416675, 46.244414]],
    [ "Enedis",[5.636682500000002, 46.2381045]],
    [ "Enedis",[5.624060965665409, 46.232716900915435]],
    [ "Enedis",[5.629467250000002, 46.239611499999995]],
    [ "Enedis",[5.6373415, 46.250160750000006]],
    [ "Enedis",[5.622986496639932, 46.2374103650863]],
    [ "Ets Ponceblanc",[5.62619844054581, 46.241946176088376]],
    [ "Enedis",[5.62563835, 46.2342578]],
    [ "Enedis",[5.628657250704537, 46.248610017004665]],
    [ "Enedis",[5.633567, 46.24877725]],
    [ "Enedis",[5.627606800000001, 46.23629235000001]],
    [ "Enedis",[5.627205749999999, 46.2428175]],
    [ "Enedis",[5.637801231884058, 46.252198362318836]],
    [ "Enedis",[5.635314929789144, 46.251542299013]],
    [ "Enedis",[5.6339472499999985, 46.2510763]],
    [ "Enedis",[5.626165151853796, 46.24657613382616]],
    [ "Enedis",[5.630075499999999, 46.23867615]],
    [ "Enedis",[5.62896325, 46.24680625]],
    [ "Stade",[5.6227698, 46.2394312]],
    [ "Enedis",[5.63782146567744, 46.243152401556955]],
    [ "Enedis",[5.635685999999998, 46.24735524999999]],
    [ "Enedis",[5.63634125, 46.2503884]],
    [ "Enedis",[2.0443536172933445, 48.98204002302589]],
    [ "Enedis",[5.600583140765672, 44.832589719581776]],
    [ "Enedis",[5.625462647058822, 44.82073835294118]],
    [ "Enedis",[5.6564153447641985, 44.79215261041735]],
    [ "Poste électrique de Réchicourt-le-Château",[6.8325051342929894, 48.65846975826521]],
    [ "Enedis",[5.761438278984077, 44.89384869795367]],
    [ "Enedis",[5.7592886899403615, 44.894608640990754]],
    [ "Enedis",[5.787776534866118, 44.9032548505062]],
    [ "Enedis",[5.7894918832, 44.90030328693334]],
    [ "Enedis",[5.791547828060522, 44.90275416460338]],
    [ "Enedis",[5.785426669798657, 44.910883432662196]],
    [ "Enedis",[5.789702314471858, 44.89661626196731]],
    [ "Enedis",[5.7880341917922955, 44.905950034757126]],
    [ "Enedis",[5.7860716210103575, 44.899994699006555]],
    [ "Enedis",[5.794112336143901, 44.90746244491287]],
    [ "Enedis",[5.78102105521156, 44.92740383488133]],
    [ "Enedis",[5.781360503076366, 44.91441390365545]],
    [ "Enedis",[5.8982496499999995, 46.59788694999999]],
    [ "Enedis",[5.8880574999999995, 46.61450375]],
    [ "Enedis",[5.947836694636371, 46.62929789574422]],
    [ "Enedis",[5.913340142291698, 46.61448075743873]],
    [ "Bourg",[5.899612249999999, 46.602395249999994]],
    [ "Enedis",[5.904097750000001, 46.60679375]],
    [ "Enedis",[-1.370776050000001, 46.615460500000005]],
    [ "Enedis",[-1.2961329500000003, 46.60165775000001]],
    [ "Enedis",[-1.3538364, 46.6146663]],
    [ "Enedis",[5.93484589274622, 48.57472403161534]],
    [ "Enedis",[6.429023900000001, 46.307369300000005]],
    [ "Enedis",[6.42902945, 46.30829405]],
    [ "Enedis",[6.4452150373860855, 46.30344435594066]],
    [ "La Tuillière",[6.415511749999999, 46.300967299999996]],
    [ "Palouse",[6.4266076000000005, 46.2965223]],
    [ "Enedis",[6.4463827, 46.30519685000001]],
    [ "Enedis",[6.459457851214516, 46.30332881643572]],
    [ "Enedis",[6.46714509582187, 46.30649976888767]],
    [ "Enedis",[6.454315895734597, 46.29650491627172]],
    [ "Enedis",[6.410499482688208, 46.2907530510704]],
    [ "Enedis",[6.4172480499999995, 46.28417550000001]],
    [ "Graveliere",[6.415678753238534, 46.28699286584526]],
    [ "Enedis",[-1.3204255658134467, 46.55778608289562]],
    [ "Enedis",[-1.3425194999999999, 46.57319075]],
    [ "Enedis",[6.387476811492642, 46.270298438448954]],
    [ "Enedis",[6.387367500000001, 46.24623449999999]],
    [ "Enedis",[6.556783571364204, 46.324383854121216]],
    [ "Enedis",[6.586008539935738, 46.324813474513384]],
    [ "Enedis",[6.5684113617263575, 46.32980906630094]],
    [ "Enedis",[6.463987873864779, 46.1981845469223]],
    [ "Enedis",[6.477256467586783, 46.13494131200335]],
    [ "Enedis",[6.46068790578597, 46.1417546625704]],
    [ "Enedis",[6.462927355100801, 46.13523594972267]],
    [ "Enedis",[6.465885279569894, 46.146453096774195]],
    [ "Enedis",[6.470029852986217, 46.16578682184788]],
    [ "Enedis",[6.46829860964449, 46.14507270784935]],
    [ "Enedis",[6.462873500000001, 46.135252949999995]],
    [ "Enedis",[6.492533952054795, 46.1245013630137]],
    [ "Enedis",[6.461586187421386, 46.137429849056595]],
    [ "Enedis",[6.448320554829339, 46.163343899237475]],
    [ "Enedis",[6.459576881866065, 46.14353082844244]],
    [ "Enedis",[6.458932059895834, 46.13705221614583]],
    [ "Enedis",[6.468891490593955, 46.13397562101036]],
    [ "Enedis",[6.450383367813398, 46.13671690118122]],
    [ "Enedis",[6.454108814569538, 46.13666420971302]],
    [ "Enedis",[6.454551058064515, 46.139108809384155]],
    [ "Tournoire",[6.484919199999999, 46.1304143]],
    [ "Enedis",[6.470205127346163, 46.13858582252316]],
    [ "Enedis",[6.669586630112063, 46.07746925658528]],
    [ "Enedis",[6.682914769219705, 46.08130152455951]],
    [ "Enedis",[6.669933627773566, 46.08523506578216]],
    [ "Enedis",[5.90196949222839, 49.179817111516435]],
    [ "Enedis",[6.666626005882352, 46.09457014509804]],
    [ "Enedis",[6.572990106124578, 46.10824230175439]],
    [ "Enedis",[6.4055186790885354, 46.1326047796345]],
    [ "Chef Lieu",[6.430354800582243, 46.13356514992722]],
    [ "Enedis",[6.440941499999999, 46.1365753]],
    [ "Enedis",[6.446525914229053, 46.13536494612461]],
    [ "Enedis",[6.327561260273972, 46.247224885844744]],
    [ "Enedis",[6.32391293250255, 46.238435255677025]],
    [ "Enedis",[6.331442430586489, 46.244789210838896]],
    [ "Enedis",[6.32788076144433, 46.239804765844816]],
    [ "Enedis",[6.309057325430693, 46.240071581002056]],
    [ "Église",[6.3211349, 46.235347499999996]],
    [ "Enedis",[4.174166954022989, 43.719118620689656]],
    [ "Enedis",[4.171999181211149, 43.72154482159615]],
    [ "Enedis",[5.314900949093904, 48.692887842394285]],
    [ "Enedis",[5.326352061979167, 48.691557546800595]],
    [ "Enedis",[5.3148792657320865, 48.692873591900316]],
    [ "Enedis",[5.332032616404227, 48.69163981574494]],
    [ "Enedis",[5.3320333387270775, 48.688694167206044]],
    [ "Enedis",[5.331342042517946, 48.68618918148352]],
    [ "Enedis",[5.336313016968407, 48.68330930273341]],
    [ "Enedis",[5.327439991357545, 48.6870593930508]],
    [ "Enedis",[5.327819099999999, 48.6821904]],
    [ "Enedis",[5.32430843086301, 48.688132410330454]],
    [ "Enedis",[5.325713803142184, 48.68991161649646]],
    [ "RTE",[0.6650659414396642, 43.292134071223764]],
    [ "Enedis",[5.32144427027027, 48.69298312612612]],
    [ "Enedis",[5.3185736602996245, 48.69146538726592]],
    [ "Enedis",[5.322163371786169, 48.689337072111435]],
    [ "Enedis",[5.321607312485276, 48.69073034240282]],
    [ "RTE",[0.19849137741036543, 43.954820026573195]],
    [ "École",[5.975635953233829, 45.80288200149253]],
    [ "Saint-Félix",[5.9723965, 45.8008085]],
    [ "Seguin",[5.974371486904046, 45.801998187547774]],
    [ "ZAC Orsan",[5.971524671403491, 45.79755592878864]],
    [ "Enedis",[5.9703352377052585, 45.80825087866462]],
    [ "Effrancey",[5.982341250000001, 45.805553499999995]],
    [ "Touviere",[5.973661815488217, 45.80028274208754]],
    [ "Enedis",[5.321049700322965, 48.688309591619934]],
    [ "Enedis",[5.322302794583685, 48.68707758895146]],
    [ "Enedis",[5.325297836672701, 48.677892790424465]],
    [ "Enedis",[5.325011291120816, 48.684725372634645]],
    [ "Enedis",[5.3241301, 48.68171290000001]],
    [ "Enedis",[5.317587322619047, 48.68687959166667]],
    [ "Enedis",[6.4275519999999995, 46.18782000000001]],
    [ "Les Lavoets",[6.435410849999999, 46.197797599999994]],
    [ "Enedis",[6.512170086092715, 46.1290833222958]],
    [ "Enedis",[6.543879314155252, 46.121998052054806]],
    [ "Enedis",[6.548262782298358, 46.15826335117773]],
    [ "Enedis",[6.527318469514711, 46.13231204272885]],
    [ "Enedis",[6.5203150875, 46.13411720208333]],
    [ "Enedis",[6.547405696075753, 46.15698346107621]],
    [ "Enedis",[6.671568500000001, 46.201660999999994]],
    [ "Enedis",[6.677540299999999, 46.310788550000005]],
    [ "Enedis",[6.67038185, 46.296483050000006]],
    [ "Enedis",[1.699293820083682, 46.82621168758716]],
    [ "Enedis",[1.7008140000000012, 46.8345785]],
    [ "Enedis",[1.6805260312499994, 46.82847269791666]],
    [ "Enedis",[1.714665348332451, 46.85524846867832]],
    [ "Enedis",[1.7014971553398068, 46.82641097842503]],
    [ "Enedis",[1.7044417015417592, 46.827952552559985]],
    [ "Enedis",[2.8620323230678992, 50.237964600087984]],
    [ "Enedis",[1.710667, 46.83062449999999]],
    [ "Enedis",[1.7133924960723284, 46.83222716837113]],
    [ "Enedis",[1.7149263661971834, 46.83373361971831]],
    [ "Poste électrique de Moulaine",[5.791877339195487, 49.48303604963924]],
    [ "Enedis",[2.8399948847736627, 50.22868999588477]],
    [ "Enedis",[2.834020420967637, 50.22677458543453]],
    [ "Enedis",[6.268806782312926, 46.32225993197279]],
    [ "Enedis",[6.273021770623742, 46.32503784372904]],
    [ "Enedis",[6.277194664230611, 46.325095979293536]],
    [ "Enedis",[6.276392500000001, 46.3292825]],
    [ "Enedis",[6.265581073497267, 46.32223487540983]],
    [ "Enedis",[6.685489715302493, 46.19356908956109]],
    [ "Enedis",[6.683619815920397, 46.19604879878386]],
    [ "Chable",[6.712741818047554, 46.17122543036478]],
    [ "Enedis",[6.707482970963995, 46.171709159117306]],
    [ "Enedis",[6.715395149999999, 46.16735635]],
    [ "Enedis",[6.719100291134171, 46.18125601628823]],
    [ "Enedis",[6.722742880658436, 46.181888699588484]],
    [ "Enedis",[6.708013688357681, 46.17578340416113]],
    [ "Enedis",[6.6851225, 46.19694399999999]],
    [ "District",[6.693075950000001, 46.19022360000001]],
    [ "Enedis",[6.767784320481501, 46.19653887925298]],
    [ "Enedis",[6.7651850499999995, 46.044564099999995]],
    [ "Enedis",[6.811644772451792, 46.070268957024794]],
    [ "Maison Neuve",[6.771078626213878, 46.047555997455326]],
    [ "Enedis",[6.822597768237833, 46.07319410164382]],
    [ "Enedis",[1.7427872280701764, 46.816774649122806]],
    [ "RTE",[0.4181466977963424, 43.52058159408142]],
    [ "RTE",[0.6497618426524778, 43.86227829598137]],
    [ "RTE",[0.6030346346199889, 43.935900684578264]],
    [ "Enedis",[2.1627945000000004, 45.950099]],
    [ "Enedis",[2.1957363679054054, 45.962301895870866]],
    [ "Enedis",[2.163662986527091, 45.95814810090143]],
    [ "Enedis",[2.1672602612660943, 45.95384236820456]],
    [ "Enedis",[6.330256556670012, 48.58685167602809]],
    [ "Enedis",[6.3583029999999985, 48.603388499999994]],
    [ "Enedis",[6.32390742241622, 48.587976137332205]],
    [ "Enedis",[6.333827055888224, 48.59289262152619]],
    [ "Enedis",[6.328953458711074, 48.59459813429294]],
    [ "Enedis",[6.3285623326198985, 48.588497481093945]],
    [ "Enedis",[6.337332747889226, 48.59398334869977]],
    [ "Enedis",[6.33045533146751, 48.592581233471236]],
    [ "Enedis",[6.331588937372788, 48.58550583505558]],
    [ "Enedis",[6.333637612416297, 48.59508650402905]],
    [ "Enedis",[6.0515705, 48.620907]],
    [ "République",[6.049553327035938, 48.621671846779094]],
    [ "RTE",[0.8646194290707684, 43.791429360838556]],
    [ "RTE",[0.14073140835305134, 43.87274222434625]],
    [ "RTE",[1.0897829749807366, 43.60112528436494]],
    [ "RTE",[0.022094978121954507, 43.468098041926815]],
    [ "RTE",[0.26565109805123743, 43.42366195820932]],
    [ "Enedis",[0.1636023363896464, 43.58431292296617]],
    [ "RTE",[0.6078589193149534, 44.3944799994965]],
    [ "ESSeyssel",[6.010647249878158, 45.780845850022736]],
    [ "ESSeyssel",[6.0114311, 45.7958271]],
    [ "RTE",[0.7232245098215628, 44.4023491141987]],
    [ "RTE",[0.6469398068423039, 44.53685308444576]],
    [ "RTE",[0.9457937014059633, 44.4974030581578]],
    [ "RTE",[0.6693330190859064, 44.66058195102315]],
    [ "RTE",[1.1743045428935708, 44.50232846346557]],
    [ "Enedis",[4.670643148259729, 46.707529532886]],
    [ "Enedis",[5.596173249999999, 45.403113399999995]],
    [ "Enedis",[4.780826049999999, 46.7835441]],
    [ "Enedis",[5.3268893, 46.16985295]],
    [ "Enedis",[5.329890163344019, 46.16647337987546]],
    [ "RTE",[1.5808074500000002, 50.0864668]],
    [ "Enedis",[3.1069439209039538, 49.3948873220339]],
    [ "Enedis",[3.1032966633058354, 49.39841974763215]],
    [ "Enedis",[3.105585069256511, 49.39909452611668]],
    [ "RTE",[0.6654645477180423, 45.19213561074491]],
    [ "RTE",[0.6578897570601205, 45.51443579323897]],
    [ "RTE",[1.044604000597476, 45.329945502644264]],
    [ "MOULINOTTE",[-0.4605452381138342, 44.93215461802841]],
    [ "Enedis",[-0.08060674999999863, 48.021841499999994]],
    [ "LAMOTHE",[-1.002339946491761, 44.62154843900668]],
    [ "SECARY",[-1.1253678749396787, 44.61611116310543]],
    [ "La Teste",[-1.1423479312320408, 44.63618712202291]],
    [ "CAZAUX (ENEDIS)",[-1.1603551298082815, 44.54017433722141]],
    [ "Enedis",[2.64445753112555, 50.226742327953595]],
    [ "Enedis",[1.3848149999999995, 49.0149595]],
    [ "Enedis",[2.8550517205020913, 50.09300887698743]],
    [ "Enedis",[2.851472333333333, 50.106534999999994]],
    [ "Enedis",[2.8516535609509357, 50.10742062518968]],
    [ "Enedis",[2.84926034805726, 50.10739985112475]],
    [ "Enedis",[2.856317656228811, 50.10899723052252]],
    [ "Enedis",[2.8646555888412015, 50.09652381144492]],
    [ "Enedis",[2.865050474576271, 50.10864094915254]],
    [ "Enedis",[2.858559265294453, 50.10398146274063]],
    [ "Enedis",[2.8461775, 50.1058695]],
    [ "Enedis",[2.8644134138963486, 50.09370765502978]],
    [ "Enedis",[2.866579250082264, 50.099211622902274]],
    [ "226303089",[2.8507980574712644, 50.099098666666656]],
    [ "Enedis",[2.8612965000000004, 50.094871000000005]],
    [ "Enedis",[2.854301211864407, 50.105497240112996]],
    [ "Enedis",[2.8487391666666664, 50.10579500000001]],
    [ "Enedis",[2.8628185193100864, 50.1069586856018]],
    [ "Enedis",[2.8452890024560604, 50.09872303714791]],
    [ "Enedis",[2.8547892075565504, 50.098788158090976]],
    [ "Enedis",[2.85112418771166, 50.102469203555884]],
    [ "Enedis",[2.8662415534446364, 50.091662479178666]],
    [ "Enedis",[2.8458238849404265, 50.102683594624544]],
    [ "Enedis",[2.8547974552308353, 50.09877785548496]],
    [ "Enedis",[2.853758498148563, 50.10335635763811]],
    [ "Centre Orthopédique",[4.782953849999999, 46.79767435000001]],
    [ "Restaurant Clinique",[4.78251541484791, 46.79797475168361]],
    [ "Champ Pacaud",[4.785479725688257, 46.799292520366265]],
    [ "Enedis",[2.8135617683615814, 50.21324405084746]],
    [ "Enedis",[2.819948243439092, 50.21361605327066]],
    [ "Enedis",[0.47648808333364, 49.61837477492862]],
    [ "Enedis",[2.2040652966824643, 48.99443897440758]],
    [ "Enedis",[2.2064150885844747, 48.99231128584475]],
    [ "Enedis",[2.202510827361564, 48.99511701737242]],
    [ "Enedis",[2.206368040412101, 48.99392937867905]],
    [ "Enedis",[3.3289318731459785, 50.065307250390326]],
    [ "Enedis",[-0.92939405, 46.86691059999999]],
    [ "Enedis",[-0.9395284819880108, 46.858388104635694]],
    [ "RTE",[-1.8598948384481127, 46.84915954770057]],
    [ "Enedis",[-0.6198593916483518, 44.80514637670329]],
    [ "Enedis",[-0.6251939828244266, 44.8037681081425]],
    [ "Enedis",[-0.6173855388254404, 44.801667975875574]],
    [ "Enedis",[-0.6154417795578278, 44.80834220449416]],
    [ "Enedis",[-0.6042158938373463, 44.79557953975978]],
    [ "Enedis",[-0.6141235069234291, 44.8051469996332]],
    [ "Enedis",[-0.6237295628151651, 44.805158125202006]],
    [ "Poste électrique de Pont-Trinquat",[3.9117365282698047, 43.59572091156387]],
    [ "Enedis",[2.4897990070639113, 50.97762749412142]],
    [ "Enedis",[3.614540818542865, 50.18476215053409]],
    [ "Enedis",[3.609302480173482, 50.18735674002478]],
    [ "RTE",[2.418515, 48.79224865]],
    [ "RTE",[2.4188368755552743, 48.79139516008175]],
    [ "RTE",[2.4187564045155825, 48.79160193000174]],
    [ "RTE",[2.4184318500000006, 48.792466]],
    [ "Enedis",[3.6086265000000006, 50.1928271]],
    [ "Enedis",[6.737264908287885, 48.917042251746246]],
    [ "RTE",[2.797274061631581, 44.39452807540359]],
    [ "Enedis",[-3.169852000246156, 47.947033400062985]],
    [ "Enedis",[3.6172985, 50.19064459999999]],
    [ "Enedis",[3.6055522102655564, 50.189918891319856]],
    [ "Enedis",[3.612030108187135, 50.19003663898636]],
    [ "Enedis",[3.609657775989857, 50.19012365188219]],
    [ "Enedis",[6.809168840579711, 48.42171078623189]],
    [ "Enedis",[-1.2291587500000003, 44.20415535000001]],
    [ "Enedis",[-1.2280749007710974, 44.2053990211406]],
    [ "Enedis",[-1.2270537982978726, 44.206925257084315]],
    [ "Enedis",[-1.2245430854133386, 44.202844720600964]],
    [ "Enedis",[-1.2274352751301905, 44.20259740937565]],
    [ "Enedis",[-1.2279579997170988, 44.20369905036952]],
    [ "Enedis",[-1.2224618532506544, 44.20080109629481]],
    [ "Enedis",[-1.2221677792840115, 44.204444449348266]],
    [ "Enedis",[-1.2236748230907915, 44.20653215509993]],
    [ "Enedis",[-1.2832495952233876, 44.21008715821861]],
    [ "Enedis",[-1.281309366771468, 44.208208748331366]],
    [ "Enedis",[2.856960738995882, 48.50244194774221]],
    [ "Enedis",[2.8415931095391875, 48.51490050217004]],
    [ "Enedis",[2.8613786319205805, 48.50401628789615]],
    [ "Enedis",[4.9696656405035995, 44.83614918037295]],
    [ "Enedis",[4.994271569432561, 44.839757997990816]],
    [ "Enedis",[4.975376699999999, 44.8314133]],
    [ "Enedis",[3.187988329066966, 48.39910366770653]],
    [ "Enedis",[3.1916003000000006, 48.39869625]],
    [ "Enedis",[3.191751421512211, 48.39738503451544]],
    [ "Enedis",[4.910498101861433, 44.769731595742364]],
    [ "Enedis",[-0.6198659489806516, 44.779669885364186]],
    [ "Enedis",[-0.6390875167321566, 44.77342951896679]],
    [ "Enedis",[-0.6350548816904575, 44.77357225181082]],
    [ "Enedis",[-0.6359611378765864, 44.7590943965657]],
    [ "Enedis",[-0.6265142731305859, 44.762404943098815]],
    [ "Enedis",[-0.6267421877905665, 44.76737219837036]],
    [ "Enedis",[-0.6145747058292688, 44.75635641978048]],
    [ "Enedis",[-0.6250278260195463, 44.76746675888353]],
    [ "Enedis",[-0.6182896988216747, 44.78142163548796]],
    [ "Enedis",[-0.6298215551336623, 44.781822023956664]],
    [ "Enedis",[-0.6022752905777068, 44.78962370448135]],
    [ "Enedis",[-0.6054041470160182, 44.787378676080344]],
    [ "Enedis",[-0.612244282526935, 44.77689293379778]],
    [ "Champ de foire",[6.123940056752522, 48.31931182526107]],
    [ "Enedis",[6.122986376930063, 48.31415111989101]],
    [ "Enedis",[6.118841116273287, 48.31778359949159]],
    [ "Enedis",[6.124965729885059, 48.314257045977]],
    [ "Sanal Éco",[6.128712225695422, 48.31134933352439]],
    [ "Enedis",[2.3361458637208887, 51.03265373909796]],
    [ "Enedis",[6.101693900000001, 48.37424815]],
    [ "Enedis",[7.20162939657632, 49.08428270233]],
    [ "Enedis",[7.201687296875002, 49.090802958333335]],
    [ "Enedis",[0.4093753578431375, 48.0362833639706]],
    [ "Enedis",[0.4151691000000005, 48.03747475]],
    [ "Ruelle du riez",[3.361039806367278, 50.445747750706296]],
    [ "Enedis",[6.64016850180562, 49.08178104757418]],
    [ "Enedis",[6.624681000000001, 49.0791265]],
    [ "Enedis",[6.609030766101696, 49.07668793672317]],
    [ "réséda",[6.641298183604022, 49.04306120185614]],
    [ "réséda",[6.6267971, 49.055803100000006]],
    [ "réséda",[6.626547251318165, 49.0498124674973]],
    [ "Enedis",[6.563679138169627, 49.15433146543977]],
    [ "Enedis",[2.5373759999999996, 50.94305550000001]],
    [ "Enedis",[6.645559866385372, 49.153942226441636]],
    [ "Enedis",[0.41940825000000004, 48.051511]],
    [ "Groupe Scolaire",[0.4075316608850815, 48.04839242755174]],
    [ "Enedis",[0.4027803000000001, 48.0495518]],
    [ "Poste électrique d'Argonay",[6.149486846043824, 45.9413814301744]],
    [ "Poste électrique de Chevene",[6.112197844949287, 45.90177734504307]],
    [ "Poste électrique du Thiou",[6.112300800304986, 45.90243853539275]],
    [ "Portique 225 kV de Chavanod",[6.08149606949081, 45.893628692775614]],
    [ "RTE",[6.0995490748707395, 45.899883332577424]],
    [ "Enedis",[1.1422457093570306, 49.12763617442062]],
    [ "Enedis",[1.1121511785610676, 49.10979130896431]],
    [ "Enedis",[3.8711393409429276, 43.69099958180314]],
    [ "Rassuen",[4.967112946895078, 43.49374439511457]],
    [ "Enedis",[-0.8337988000000001, 44.80186229999999]],
    [ "Enedis",[2.4315089999999997, 50.7951825]],
    [ "Enedis",[2.428447999999999, 50.794230500000005]],
    [ "Enedis",[2.4569020000000004, 50.789799499999994]],
    [ "Enedis",[-0.5901473046080347, 44.72979175766341]],
    [ "Enedis",[-0.6016705500000001, 44.7404047]],
    [ "Enedis",[-0.5992655614177029, 44.73693565636353]],
    [ "Enedis",[-0.5963124345804689, 44.73547662247509]],
    [ "Enedis",[-0.596975736708817, 44.7386204402929]],
    [ "Enedis",[-0.6086418685922086, 44.73569731357338]],
    [ "Enedis",[-0.5972953719020031, 44.74319738197164]],
    [ "Enedis",[-0.5933063876843476, 44.74551442116088]],
    [ "Enedis",[-0.6115965946367584, 44.740924860611685]],
    [ "Enedis",[-0.59132435, 44.74280475000001]],
    [ "Enedis",[-0.6194054168313768, 44.74035221724923]],
    [ "Enedis",[-0.5894207239027374, 44.73862230411217]],
    [ "Enedis",[-0.5883120723212586, 44.74146198563488]],
    [ "Enedis",[-0.610641068187043, 44.73724083308157]],
    [ "Enedis",[-0.6187258875376885, 44.7384378877186]],
    [ "Enedis",[-0.6197903999999999, 44.743884099999995]],
    [ "Enedis",[-0.5881402964610737, 44.748949521512735]],
    [ "Enedis",[-0.6156482310630994, 44.74151207946324]],
    [ "Enedis",[-0.6159431815034724, 44.74224288685911]],
    [ "Enedis",[-0.6004414588613117, 44.731814546301536]],
    [ "Enedis",[-0.5967760643386051, 44.732751260487184]],
    [ "Enedis",[-0.6099542426456394, 44.73505474390244]],
    [ "Enedis",[-0.6127243497430191, 44.74141721183406]],
    [ "Enedis",[-0.5900084413586173, 44.74419416718315]],
    [ "Enedis",[-0.6044736744648712, 44.73394147071192]],
    [ "Enedis",[-0.591010393884057, 44.73369928104244]],
    [ "163 Ruaudin",[0.2222426999999994, 47.97664224999999]],
    [ "Enedis",[-0.5951849900581642, 44.72694752360763]],
    [ "Enedis",[-0.6209254487205056, 44.71472219062824]],
    [ "Enedis",[-0.6006464834573901, 44.72454187035042]],
    [ "Enedis",[-0.6242411893232407, 44.71798862500039]],
    [ "Enedis",[-0.604071366715108, 44.724402601395106]],
    [ "Enedis",[-0.593377, 44.719366150000006]],
    [ "Enedis",[-0.6091223867419722, 44.7213058156938]],
    [ "Enedis",[-0.5975925531703171, 44.72698136897671]],
    [ "Enedis",[-0.6101344239041822, 44.726607147556315]],
    [ "Enedis",[-0.6066253143543548, 44.71263806832022]],
    [ "Enedis",[6.301477350622564, 45.716992385338635]],
    [ "Poste électrique du Fier",[6.094394155708948, 45.91169849618174]],
    [ "Fossettes",[-0.37514674195442754, 49.28992909560468]],
    [ "Enedis",[-1.3640764667231673, 46.506985649909225]],
    [ "Enedis",[7.378889611251048, 48.972311727120065]],
    [ "Enedis",[7.379929504910267, 48.97359199955362]],
    [ "Enedis",[7.344734565064479, 48.97061834740133]],
    [ "Enedis",[7.338258500000001, 48.960452499999995]],
    [ "Enedis",[7.331719723514212, 48.958855847545216]],
    [ "Enedis",[7.378476895057034, 49.00565682991128]],
    [ "Enedis",[7.380659803414553, 48.997183448035805]],
    [ "Enedis",[7.36438279886507, 48.9999200624212]],
    [ "Enedis",[5.76537848327552, 45.78791800065648]],
    [ "Enedis",[5.7627267, 45.78646270000001]],
    [ "Enedis",[5.77481975, 45.80684910000001]],
    [ "Enedis",[2.562001, 50.351804499999986]],
    [ "Enedis",[6.749367505747128, 48.980041666666665]],
    [ "Enedis",[6.74512299122807, 48.97062887368421]],
    [ "Enedis",[6.738766226816862, 48.97914823817133]],
    [ "SNCF Réseau",[2.581012010710315, 49.0647030377051]],
    [ "Enedis",[6.677239308153477, 49.02638466366906]],
    [ "Enedis",[7.351732824644552, 48.989527974723536]],
    [ "Enedis",[7.3476550000000005, 49.0133115]],
    [ "Enedis",[7.3378819048453, 49.015081768826626]],
    [ "Enedis",[7.337217649010478, 49.009541116026384]],
    [ "Enedis",[-1.3418220444312097, 46.505714932392365]],
    [ "Enedis",[-1.3498388666871737, 46.51011360097204]],
    [ "Enedis",[-1.3413054, 46.50909125]],
    [ "Enedis",[-1.3385575000000003, 46.5052415]],
    [ "Enedis",[-1.3458826878498182, 46.50729917156053]],
    [ "Enedis",[2.9762038912747877, 49.43119855826186]],
    [ "Enedis",[-0.94729245, 46.82920575]],
    [ "Enedis",[-0.9863562676999131, 46.822616102795834]],
    [ "Enedis",[2.0460142784615383, 46.08381695381598]],
    [ "Enedis",[2.050353130005546, 46.086764105269]],
    [ "Enedis",[2.043802852330231, 46.08573828418764]],
    [ "Enedis",[2.0447990000000003, 46.08782599999999]],
    [ "Enedis",[2.0233584176756314, 46.122410624585704]],
    [ "Enedis",[2.147881266666667, 45.9871028]],
    [ "Enedis",[6.294505801032509, 47.98131358364727]],
    [ "Enedis",[3.7720627492428833, 48.47122150015771]],
    [ "Enedis",[3.8271556999999996, 48.323508]],
    [ "Station GRT",[3.7989942389456157, 48.305883668469846]],
    [ "Enedis",[3.764041906575964, 48.30585672925171]],
    [ "Enedis",[2.2855132335872317, 50.8817300359086]],
    [ "Enedis",[2.3423244999999997, 50.913740000000004]],
    [ "Enedis",[2.3577422662585032, 50.928918468843534]],
    [ "Enedis",[2.3288491271966523, 50.92673423291491]],
    [ "Enedis",[2.3712370437037027, 50.951264064444445]],
    [ "Enedis",[2.2939960000000004, 50.9544955]],
    [ "Enedis",[2.227445403586888, 50.88207689239332]],
    [ "Enedis",[2.2206293916974924, 50.87335031689272]],
    [ "Enedis",[2.2240275637676175, 50.912390702761556]],
    [ "Enedis",[2.2327040493237873, 50.898466673031024]],
    [ "Enedis",[2.2228948144909424, 50.9028968246929]],
    [ "Enedis",[2.2217543433817153, 50.900310170946526]],
    [ "Enedis",[2.2271195, 50.89972799999999]],
    [ "Enedis",[2.2023592650602413, 50.85500277911646]],
    [ "Enedis",[2.251068584412632, 50.9274482585776]],
    [ "Enedis",[2.2847012813053014, 50.92789137106251]],
    [ "Enedis",[2.2659030375360922, 50.91729006277403]],
    [ "Enedis",[2.271602205944798, 50.91750496815286]],
    [ "Enedis",[2.2282545888412013, 50.86709118855507]],
    [ "Enedis",[2.2013297306666666, 50.90373092533333]],
    [ "Enedis",[2.2035292537313436, 50.89893909452736]],
    [ "Enedis",[2.1883527467282526, 50.89599265434949]],
    [ "Enedis",[2.1972675, 50.910492]],
    [ "Enedis",[2.192008896002894, 50.899767202025686]],
    [ "Enedis",[4.688180320316215, 46.55231009473398]],
    [ "Enedis",[4.689616, 46.54843975000001]],
    [ "Enedis",[4.690464080188197, 46.52967229621619]],
    [ "Enedis",[3.6466085886889457, 47.744104293916024]],
    [ "Enedis",[3.6547520715307575, 47.7419608769671]],
    [ "Enedis",[3.6499865474529694, 47.74218945888818]],
    [ "RTE",[7.517528463883425, 43.912313171974475]],
    [ "Enedis",[6.32978284866824, 48.06367754715466]],
    [ "Enedis",[2.468928945426356, 51.0382284663566]],
    [ "Enedis",[2.4879151193425253, 51.02210116927631]],
    [ "Enedis",[2.2903505, 49.60506349999999]],
    [ "Enedis",[-1.4299076499999996, 46.49957379999999]],
    [ "Enedis",[7.484784031606386, 49.0875698973607]],
    [ "Enedis",[7.407562230242982, 49.07841084005661]],
    [ "Enedis",[7.336790075235245, 49.0738322983522]],
    [ "Enedis",[2.4848375000000003, 51.01865900000001]],
    [ "Enedis",[2.4824965000000003, 51.0224525]],
    [ "Enedis",[3.7271663907244625, 48.227771936647464]],
    [ "Enedis",[7.3520971697486965, 49.04360742911333]],
    [ "Enedis",[7.346659774209245, 49.04210718880778]],
    [ "Enedis",[7.389878099667776, 49.04121291251384]],
    [ "Enedis",[7.221155999999997, 48.9894895]],
    [ "Enedis",[7.123640524361949, 49.061106997679815]],
    [ "Les Merciers",[5.999596765268701, 45.379664212026654]],
    [ "RTE",[5.887679869831379, 45.23506852798583]],
    [ "Enedis",[7.233503999999998, 49.0930335]],
    [ "Enedis",[7.266013820927723, 49.11018784034519]],
    [ "Enedis",[7.2780651195499315, 49.11733626160338]],
    [ "Salle des Fêtes",[6.55812756868687, 44.79197626464646]],
    [ "Enedis",[6.554815126126125, 44.78670425045045]],
    [ "Enedis",[1.0916920500000005, 49.468559150000004]],
    [ "Poste électrique du Cheylas",[6.001560800860619, 45.3846815775688]],
    [ "Enedis",[-1.2036245500000011, 46.578099249999994]],
    [ "Enedis",[0.9319294507019656, 43.14276711608505]],
    [ "Enedis",[0.9346911533248629, 43.13162246293943]],
    [ "Enedis",[0.9423085219539272, 43.14453654204829]],
    [ "Briques 05052P0515",[6.616523652879414, 44.67484917488599]],
    [ "réséda",[6.4755043169372035, 49.11490742006887]],
    [ "réséda",[6.217181963988725, 48.94608923016208]],
    [ "réséda",[6.241204884987277, 48.92914107620865]],
    [ "réséda",[6.232145225158491, 48.95210657080413]],
    [ "réséda",[6.286097339637699, 48.945523526773606]],
    [ "Enedis",[2.0781937448635226, 46.3606462309175]],
    [ "réséda",[6.29093666028003, 48.965325304839105]],
    [ "réséda",[6.296326071492404, 48.97294712645219]],
    [ "réséda",[6.289131567010308, 48.96626880068729]],
    [ "réséda",[6.284523954983922, 48.96164430546624]],
    [ "réséda",[6.298511745497792, 48.97098570778117]],
    [ "réséda",[6.303479920227921, 48.96976805384615]],
    [ "réséda",[6.2914864999999995, 48.964532000000005]],
    [ "réséda",[6.29707817834681, 48.966077026954174]],
    [ "réséda",[6.131474066152667, 48.97424577532487]],
    [ "réséda",[6.1360793906899405, 48.94983360964255]],
    [ "réséda",[6.134842179630782, 48.9488736500726]],
    [ "réséda",[6.141126779026218, 48.94914259051187]],
    [ "Enedis",[2.095873499999999, 50.88417499999999]],
    [ "Enedis",[2.09445, 50.8917435]],
    [ "Enedis",[2.098696, 50.880407500000004]],
    [ "Sailly",[6.301503437371415, 48.93986512223431]],
    [ "Enedis",[2.066710288400732, 50.87950114611415]],
    [ "Enedis",[2.0636385, 50.88014900000001]],
    [ "Enedis",[2.070743268579651, 50.88017245657694]],
    [ "Enedis",[2.0723535771975636, 50.88109278690709]],
    [ "Enedis",[2.083383, 50.886008999999994]],
    [ "Enedis",[2.078433330725346, 50.88317350268948]],
    [ "Enedis",[2.082671105829778, 50.87893189660776]],
    [ "Enedis",[2.073284892053359, 50.87704325615844]],
    [ "Enedis",[2.0729933324595895, 50.87798399038882]],
    [ "Enedis",[2.078892983606058, 50.877352732486365]],
    [ "Enedis",[1.9113869999999997, 46.322078250000004]],
    [ "Enedis",[1.90313705, 46.328542999999996]],
    [ "Enedis",[1.908111, 46.3264115]],
    [ "Enedis",[1.9020614999999996, 46.33013375]],
    [ "Enedis",[1.91650175, 46.30855775]],
    [ "Enedis",[1.9145380514809942, 46.31873963233765]],
    [ "Enedis",[1.9043895, 46.3240885]],
    [ "Enedis",[1.9052171500000004, 46.3262587]],
    [ "Enedis",[1.9076660313596954, 46.32303050814125]],
    [ "Enedis",[2.20400550919794, 50.77415344665195]],
    [ "Enedis",[2.2029529999999995, 50.777611500000006]],
    [ "Enedis",[1.827061428602495, 46.44998749440052]],
    [ "Enedis",[-0.2920066059810741, 43.24156099783968]],
    [ "229874134",[-0.34333885000000036, 43.19746684999999]],
    [ "Enedis",[1.1414815999999999, 42.99561185]],
    [ "réséda",[6.349701813840831, 48.98219754348328]],
    [ "réséda",[6.340591095238094, 48.98032480952381]],
    [ "réséda",[6.336345706001008, 48.99493380080684]],
    [ "Enedis",[1.1382091198352986, 43.00195385259807]],
    [ "RTE",[6.845799982709669, 43.95245097719109]],
    [ "Enedis",[-0.47563091738525676, 43.318182721557726]],
    [ "Sous-station de Grenay",[5.07578379765931, 45.65424190417077]],
    [ "Enedis",[-0.4867907321063408, 43.35570896400817]],
    [ "Enedis",[-0.4796625000000001, 43.358707499999994]],
    [ "Enedis",[-0.489253, 43.356637]],
    [ "réséda",[6.316426, 49.0428765]],
    [ "réséda",[6.320268335125449, 49.03396098566308]],
    [ "Enedis",[2.020926664629177, 50.87018704889976]],
    [ "Enedis",[2.043168757787076, 50.878059152022324]],
    [ "Enedis",[2.7529413802425844, 50.23377343781968]],
    [ "Enedis",[2.738644, 50.227112]],
    [ "Enedis",[2.027133811827958, 50.876338163978495]],
    [ "Enedis",[2.02225359025788, 50.87447882043934]],
    [ "RTE",[-1.3384210761540298, 46.9747474562394]],
    [ "Enedis",[-0.23359966793248935, 47.962082020253156]],
    [ "Enedis",[2.017519700906903, 50.940984706644464]],
    [ "Enedis",[2.0209349505178364, 50.9413358830073]],
    [ "Enedis",[-1.2709922500000002, 46.5844615]],
    [ "Enedis",[-1.2476341406587834, 46.62591476827508]],
    [ "Enedis",[-1.2702099610920232, 46.631662219312254]],
    [ "Enedis",[-1.2637017832270332, 46.607807049636484]],
    [ "Enedis",[-1.2191504000000006, 46.627362250000004]],
    [ "Enedis",[2.128926159394997, 50.94343900756254]],
    [ "Enedis",[2.12478748574238, 50.94548627040315]],
    [ "Enedis",[2.125075942423247, 50.94334644509997]],
    [ "Enedis",[2.1050135000000005, 50.9390365]],
    [ "Enedis",[2.132198871933378, 50.87054832703129]],
    [ "Enedis",[2.05659869423559, 50.926184522175]],
    [ "Enedis",[2.0686885645684616, 50.92978339930781]],
    [ "Enedis",[2.0679023822304488, 50.854106750269935]],
    [ "Enedis",[6.009808285145108, 43.5492308383904]],
    [ "Poste électrique d'Hermillon",[6.361857852710051, 45.284712417137726]],
    [ "Enedis",[2.300770129536572, 50.77202380429927]],
    [ "Enedis",[2.3021113024331985, 50.770112244066276]],
    [ "Enedis",[2.3015038329357984, 50.76875901828662]],
    [ "Le Clouzot",[4.690009068566652, 46.713677874459364]],
    [ "Enedis",[6.844420750000001, 48.9719625]],
    [ "Enedis",[6.854784696078432, 48.93150379362745]],
    [ "Enedis",[6.873142415268994, 48.9560157091009]],
    [ "Enedis",[6.735678999999998, 49.071727499999994]],
    [ "Salle des Fêtes",[4.6925289, 46.71236544999999]],
    [ "Enedis",[6.866183196675899, 48.967568585410895]],
    [ "Enedis",[6.867708788482834, 48.964454458471764]],
    [ "Enedis",[6.864134166666666, 48.964017999999996]],
    [ "Les Amerons",[4.7136512, 46.71404735]],
    [ "Marbre",[4.704602352736596, 46.71430323402567]],
    [ "Enedis",[1.7612188351721834, 45.95823792276831]],
    [ "Enedis",[1.7575047767258378, 45.95988913767258]],
    [ "Enedis",[1.767515749999999, 45.958617749999995]],
    [ "Enedis",[6.793055702579665, 48.953579599898845]],
    [ "Enedis",[6.7784537377734875, 49.004013901544404]],
    [ "Enedis",[6.817179621057985, 48.95353198982706]],
    [ "Enedis",[6.7860168, 48.91332418333333]],
    [ "Enedis",[6.7512075000000005, 48.942375000000006]],
    [ "Enedis",[1.8740901251139068, 48.03201594148703]],
    [ "Enedis",[1.8754187511111107, 48.02802753333333]],
    [ "Enedis",[1.8727568391503748, 48.03465994204683]],
    [ "Enedis",[1.872728713917805, 48.03468566255687]],
    [ "Enedis",[6.045359185987293, 45.7926436955722]],
    [ "Enedis",[6.051700928561725, 45.78974315362381]],
    [ "réséda",[6.579038495118549, 49.06838516736402]],
    [ "réséda",[6.559652911111112, 49.06325084444444]],
    [ "réséda",[6.563973000000002, 49.064280000000004]],
    [ "réséda",[6.567166322751323, 49.06406126455027]],
    [ "Enedis",[-1.222190743647597, 46.502474866844864]],
    [ "réséda",[6.526856344943476, 49.07678368713718]],
    [ "Enedis",[6.9110024999999995, 49.032092500000005]],
    [ "Enedis",[6.9048480496715925, 49.03139179064039]],
    [ "Enedis",[6.927722071574641, 49.02846182413089]],
    [ "Enedis",[-1.2515165, 46.6595375]],
    [ "Enedis",[-1.230714232560918, 46.656261002368744]],
    [ "Enedis",[-1.2671499091405043, 46.6458365818399]],
    [ "Enedis",[1.76667825, 46.261968499999995]],
    [ "réséda",[6.436153692724395, 49.0428068354164]],
    [ "Enedis",[2.171899599933709, 50.79521631841786]],
    [ "Enedis",[2.1630915, 50.7916975]],
    [ "Enedis",[2.171284999999999, 50.795954]],
    [ "Enedis",[2.168293619874693, 50.79754817336823]],
    [ "Enedis",[2.1299286899766896, 50.76540517016317]],
    [ "Enedis",[2.127817000000001, 50.763616]],
    [ "réséda",[6.593148020443602, 49.0533711282027]],
    [ "réséda",[6.594233475189263, 49.04928584453625]],
    [ "Enedis",[2.2297897215868114, 50.772907706852145]],
    [ "Enedis",[2.200504731827748, 50.793959904889114]],
    [ "Enedis",[4.224438680022896, 43.72849270196527]],
    [ "Enedis",[4.227481026647202, 43.72768762382099]],
    [ "Bourg",[4.546082567651315, 46.77454409416986]],
    [ "Église",[4.5425446, 46.773473800000005]],
    [ "Enedis",[2.269368554781508, 50.73149221057633]],
    [ "Enedis",[2.2542452827918167, 50.714275277176085]],
    [ "Enedis",[2.265849985772357, 50.71411173577235]],
    [ "Enedis",[2.270378869721689, 50.71338754886436]],
    [ "Enedis",[2.267674817271589, 50.716240187317474]],
    [ "Enedis",[2.268584311308768, 50.71484810376959]],
    [ "Enedis",[2.268449130940286, 50.71258452827633]],
    [ "Enedis",[2.2718530406052193, 50.71492048000618]],
    [ "Enedis",[2.2840480000000007, 50.705533499999994]],
    [ "Enedis",[2.2866897185911403, 50.718709110021784]],
    [ "Enedis",[2.2899061723093865, 50.719975087714474]],
    [ "St Eloi",[6.640658982582583, 43.44204484164163]],
    [ "Enedis",[2.280613809545082, 50.72194834338471]],
    [ "Flamant Rose",[6.638284510474092, 43.46218133774348]],
    [ "Enedis",[2.2707968262281772, 50.721109823792126]],
    [ "Enedis",[2.2760489578884937, 50.71707904240808]],
    [ "réséda",[6.353050452083332, 49.11990301041666]],
    [ "réséda",[6.360619502953586, 49.11770469746835]],
    [ "réséda",[6.354828600456622, 49.11447197899544]],
    [ "réséda",[6.3599954499999996, 49.120010050000005]],
    [ "réséda",[6.3543642780656295, 49.12126146286701]],
    [ "Enedis",[0.6515754545214526, 48.17828281359735]],
    [ "Enedis",[0.6253245000000001, 48.165760000000006]],
    [ "Enedis",[0.6649898660766959, 48.18426320294985]],
    [ "Enedis",[0.6602149272467905, 48.18178389205896]],
    [ "Enedis",[0.6526856666666677, 48.181239000000005]],
    [ "Enedis",[0.6523643431372549, 48.18434574754902]],
    [ "Enedis",[0.6551173396637926, 48.18100380800383]],
    [ "Enedis",[0.657130980752406, 48.18449566054243]],
    [ "Enedis",[0.6624823260340632, 48.179718664233576]],
    [ "Enedis",[0.6363284682926829, 48.16215988292683]],
    [ "Enedis",[0.6637179327651627, 48.18757021622867]],
    [ "Enedis",[0.6492417066666663, 48.187430293333335]],
    [ "Enedis",[0.6608289393424034, 48.18512268877551]],
    [ "Enedis",[0.6552262874010081, 48.1885325814255]],
    [ "Enedis",[0.6621188992248064, 48.19215809372798]],
    [ "Enedis",[0.666481380400633, 48.18142724794638]],
    [ "Enedis",[0.6559357365668572, 48.185842207712604]],
    [ "Enedis",[0.6481797510355355, 48.185657826466105]],
    [ "Enedis",[1.5327666038567491, 43.53600263966941]],
    [ "Enedis",[0.6405012311949686, 48.18719740150942]],
    [ "Enedis",[0.6401884999999992, 48.1835455]],
    [ "Enedis",[0.6404445733333332, 48.19078857333332]],
    [ "Enedis",[0.6430197009803926, 48.1903411764706]],
    [ "Enedis",[0.6252281387631976, 48.1961623167421]],
    [ "Enedis",[0.6389649999999999, 48.1800631]],
    [ "Enedis",[0.6379578220600056, 48.19247044196834]],
    [ "Enedis",[0.6411925, 48.17797000000001]],
    [ "Enedis",[0.6373102718894893, 48.19573900594322]],
    [ "Enedis",[0.6386541074675244, 48.188762349915194]],
    [ "Enedis",[0.6454805034980088, 48.187675834571095]],
    [ "Enedis",[0.6302785695538056, 48.1951747045786]],
    [ "Enedis",[0.6390903773595777, 48.195825559120436]],
    [ "Enedis",[0.6412293566606856, 48.18230676048875]],
    [ "Enedis",[0.643495749559083, 48.18497396296296]],
    [ "Enedis",[0.6386907472636814, 48.185365914427855]],
    [ "Enedis",[1.7741325187885586, 50.88968887960366]],
    [ "Enedis",[-0.77185915, 43.18723930000001]],
    [ "Enedis",[-0.7774183999999998, 43.18454640000001]],
    [ "Enedis",[2.2412837142085356, 50.69716371745004]],
    [ "Enedis",[2.2467052801381695, 50.70139588393783]],
    [ "Enedis",[2.261518144055539, 50.66558716092951]],
    [ "Marmagne",[4.69246045, 46.7152005]],
    [ "Enedis",[2.288635882212257, 50.66989950044843]],
    [ "Enedis",[2.2844435100170504, 50.667704985933504]],
    [ "Enedis",[5.70444485, 49.4486173]],
    [ "Enedis",[2.223165309219859, 50.65539947234043]],
    [ "Enedis",[2.2208698804653024, 50.65857442338549]],
    [ "Enedis",[2.2837991442786074, 50.697196154144535]],
    [ "Enedis",[5.693057224093614, 49.44357163709386]],
    [ "Enedis",[2.325232253756698, 50.63407855873515]],
    [ "Enedis",[2.3196921650165008, 50.635467992927865]],
    [ "Enedis",[2.366951, 50.706373000000006]],
    [ "Enedis",[2.3380671961674233, 50.6911719475542]],
    [ "Enedis",[2.3509872210002616, 50.68798915946582]],
    [ "Enedis",[2.3462564999999995, 50.691309499999996]],
    [ "Enedis",[2.340562, 50.690862]],
    [ "Enedis",[2.351727016877636, 50.69180548342376]],
    [ "Enedis",[2.3480161403834265, 50.69823315460729]],
    [ "Enedis",[2.359848885714286, 50.69295965238095]],
    [ "Enedis",[2.355342840953173, 50.69156357772236]],
    [ "Enedis",[2.351592599229287, 50.69755214065511]],
    [ "Enedis",[2.3558776744736223, 50.69422452211971]],
    [ "Enedis",[2.360093674448768, 50.689435531776915]],
    [ "Enedis",[6.771893387423261, 49.03477515712627]],
    [ "Enedis",[2.2579728749197177, 50.63747405170199]],
    [ "Enedis",[2.257181094416344, 50.6359682354458]],
    [ "Enedis",[2.3702318438786003, 50.67055559773662]],
    [ "Enedis",[1.9800696745532338, 50.877760540649376]],
    [ "Enedis",[1.9821070000000003, 50.876345]],
    [ "Enedis",[1.9758436890521154, 50.876155592864635]],
    [ "Enedis",[1.9697197545893708, 50.87778683748792]],
    [ "Enedis",[1.9745103668639052, 50.85476795266272]],
    [ "Enedis",[1.9846096744902797, 50.85319516903746]],
    [ "Enedis",[1.9805734757990865, 50.85142366392694]],
    [ "Enedis",[1.976024302508644, 50.851927118805314]],
    [ "Enedis",[1.982706329501916, 50.853126475095785]],
    [ "Enedis",[1.973748015531661, 50.85590961808044]],
    [ "Enedis",[1.9824766486305943, 50.85562218303273]],
    [ "Enedis",[1.9794732766699905, 50.85702527733465]],
    [ "Enedis",[1.9764666303123402, 50.85374142959549]],
    [ "Enedis",[1.9790327172867643, 50.85459459352063]],
    [ "Enedis",[-0.995365898532495, 43.48160906624737]],
    [ "Enedis",[-1.4029837500000002, 46.409545]],
    [ "Enedis",[-1.39936525, 46.406221]],
    [ "Enedis",[-1.3969407500000002, 46.4090545]],
    [ "Enedis",[-1.3900011999999997, 46.40584689999999]],
    [ "Enedis",[-1.4138965, 46.40336175]],
    [ "Enedis",[-1.413055594564615, 46.406251181919025]],
    [ "Enedis",[-1.4053039999999999, 46.404866500000004]],
    [ "Enedis",[-1.4039687499999998, 46.4076295]],
    [ "Enedis",[-1.4091188499999994, 46.395263050000004]],
    [ "Enedis",[6.16502385, 48.69562165]],
    [ "Enedis",[-0.8682659500000001, 43.237550049999996]],
    [ "Enedis",[-0.8740443122938301, 43.22983811044156]],
    [ "Enedis",[1.9878906071428566, 50.85036110714285]],
    [ "Enedis",[2.1248145, 50.807258]],
    [ "Enedis",[-0.7346290784214944, 43.270408686146084]],
    [ "REThones",[6.32381123685081, 45.86935352618811]],
    [ "REThones",[6.294858420850431, 45.89817329567326]],
    [ "Enedis",[-0.9999975, 43.446420499999995]],
    [ "réséda",[6.252921600000001, 49.1347409]],
    [ "réséda",[6.2513380000000005, 49.1330095]],
    [ "réséda",[6.256785827794562, 49.137032994629074]],
    [ "réséda",[6.2603077, 49.1242443]],
    [ "Bourg Rouillon",[0.13473750000000104, 48.00536100000001]],
    [ "Les Fontaines",[0.13174274999999877, 48.00732525]],
    [ "Domaine de Beauvoir",[0.1328215, 48.011693750000006]],
    [ "La Mussotrie",[0.14063000000000003, 48.0103275]],
    [ "Bocage",[0.13807474999999933, 48.010204599999994]],
    [ "Le cour de Rouillon",[0.13702740657760074, 48.003908865847876]],
    [ "Les Bruyères",[0.13604575000000002, 48.007875500000004]],
    [ "Orée",[0.14326300646211432, 48.006742827484935]],
    [ "Poste électrique CPDE de Magenta",[2.3538586810485493, 48.876196959487956]],
    [ "Poste électrique Foch",[2.275126457137356, 48.87243933132237]],
    [ "Poste électrique de Bercy",[2.385391807376349, 48.837316775259254]],
    [ "Poste électrique de Longchamps",[2.2855073109810475, 48.86500300909291]],
    [ "Poste électrique de Magenta",[2.3542524684562784, 48.87618875063081]],
    [ "Poste électrique de Nation 3",[2.395130501492881, 48.853295283218486]],
    [ "Poste électrique d'Ornano",[2.3556986961182016, 48.88841725479906]],
    [ "Poste électrique de Sèvres",[2.3297665775895773, 48.85105037455987]],
    [ "Poste électrique de Tolbiac",[2.355323099255014, 48.82660638605863]],
    [ "Poste électrique de Valmy",[2.3957309952585275, 48.830168916607356]],
    [ "Poste électrique des Gobelins",[2.3543328392535336, 48.8348613204385]],
    [ "Poste électrique des Halles",[2.3477521147029674, 48.863274073715445]],
    [ "Enedis",[1.967675530730637, 50.85363760943871]],
    [ "Enedis",[1.970267624646763, 50.85701034623418]],
    [ "Enedis",[1.9674731001642034, 50.85522382500587]],
    [ "Champ de Foire",[4.741866542822845, 46.869035427255376]],
    [ "Enedis",[4.0246719892976595, 46.66212017435899]],
    [ "réséda",[6.2782966710526305, 49.132183232456136]],
    [ "réséda",[6.2733246080246925, 49.13433225617284]],
    [ "réséda",[6.273836174094127, 49.131948766902674]],
    [ "réséda",[6.274858061728395, 49.13611128395061]],
    [ "réséda",[6.280304044444444, 49.132171835555546]],
    [ "réséda",[6.281489938648783, 49.13430921385991]],
    [ "réséda",[6.273676, 49.133340499999996]],
    [ "Bonlieu",[6.127258568347989, 45.90163175345412]],
    [ "Eveché",[6.124539913984819, 45.9002358541797]],
    [ "Poste privé usine des eaux",[6.139776334340498, 45.88744626151351]],
    [ "Poste privé usine des eaux",[6.1431252811472215, 45.88761819020198]],
    [ "Usine Espagnoux TV",[6.139763140249085, 45.88740760467732]],
    [ "Usine Puya TV",[6.143189195833503, 45.88762054260516]],
    [ "Enedis",[6.7242355, 49.0857885]],
    [ "Enedis",[6.725709900752909, 49.087034154688574]],
    [ "Enedis",[2.198378395596052, 50.82277724449506]],
    [ "Enedis",[2.2000361550116545, 50.82220497358197]],
    [ "Enedis",[2.1495378728323695, 50.80910570905588]],
    [ "Charézier Village",[5.7283972259390445, 46.610966464854336]],
    [ "Enedis",[5.740746037460704, 46.595427294893]],
    [ "Enedis",[5.765341524968089, 46.603125891999724]],
    [ "Enedis",[3.376187129915242, 50.09579272738641]],
    [ "RTE",[7.307611664847411, 44.02112832427616]],
    [ "Enedis",[5.991184842125815, 46.402743303168165]],
    [ "Enedis",[5.967766487579911, 46.394887514619604]],
    [ "Enedis",[5.984952285061783, 46.40217829610873]],
    [ "Enedis",[5.983894768090716, 46.40368476762132]],
    [ "Enedis",[5.965949781602804, 46.395815891993706]],
    [ "Enedis",[5.964151620205802, 46.397695176909885]],
    [ "RTE",[6.582788996945808, 45.44968448560584]],
    [ "Poste électrique de Bacchus",[-1.7514700578965494, 49.6536063260372]],
    [ "Enedis",[-1.0786794999999996, 44.50354525]],
    [ "Enedis",[-1.0675205000000003, 44.4948165]],
    [ "Enedis",[-1.0543895769033322, 44.49646530354871]],
    [ "Enedis",[-1.0678079999999999, 44.496571]],
    [ "Enedis",[-1.0717495504398282, 44.49723432715702]],
    [ "Enedis",[-1.0802841179589133, 44.48114720808482]],
    [ "Enedis",[-1.057609257843137, 44.49286993529411]],
    [ "Enedis",[-1.0568968978427273, 44.49697996910229]],
    [ "Enedis",[-1.0750711499999996, 44.500007999999994]],
    [ "Enedis",[-1.0624179999999999, 44.4900555]],
    [ "Enedis",[-1.0697085, 44.497662000000005]],
    [ "Enedis",[-1.0665624561403508, 44.4923661754386]],
    [ "Enedis",[-1.0628441893004115, 44.493130958847736]],
    [ "Enedis",[-1.0858934500000001, 44.48231705]],
    [ "Enedis",[-1.0597519999999998, 44.49137825]],
    [ "Enedis",[3.382415041996134, 50.10048935143208]],
    [ "Enedis",[3.3729675942857136, 50.10590568761904]],
    [ "Enedis",[3.3733705902140674, 50.101893418960245]],
    [ "Enedis",[1.9046077769328253, 50.92119173384031]],
    [ "Enedis",[1.8792872823205793, 50.911798446861724]],
    [ "Enedis",[1.8614673118069152, 50.927382159165035]],
    [ "Enedis",[1.8621537021505379, 50.92577604731183]],
    [ "Enedis",[1.875910811827957, 50.92634347849463]],
    [ "Enedis",[1.8765823811742781, 50.92218424355389]],
    [ "Enedis",[1.8767243333333334, 50.927380666666664]],
    [ "Enedis",[1.8739499685112302, 50.924901875974385]],
    [ "Enedis",[1.879386365441487, 50.92592177017321]],
    [ "Enedis",[1.8868297805907173, 50.923217177215186]],
    [ "Enedis",[1.8942894520409637, 50.92501571051493]],
    [ "Enedis",[1.8883522611683858, 50.92102895189004]],
    [ "Enedis",[1.886847703192712, 50.92598100194569]],
    [ "Enedis",[1.8863290000000006, 50.93076333333334]],
    [ "Enedis",[1.890771419931272, 50.9195357347079]],
    [ "Enedis",[1.8895597528106967, 50.925114936189615]],
    [ "Enedis",[1.8851612018706954, 50.92546475115635]],
    [ "Enedis",[1.8845979204049828, 50.920807791199366]],
    [ "Enedis",[3.427526236543768, 50.16125408781038]],
    [ "RTE",[4.003396718740273, 49.25946949432358]],
    [ "Enedis",[4.003348665896774, 49.260548877058135]],
    [ "Betheny (SNCF)",[4.041822622627293, 49.27547878505403]],
    [ "RTE",[3.7636740892163854, 49.09079880459777]],
    [ "Poste électrique de Poix-Terron",[4.630051779267584, 49.647915232124504]],
    [ "Enedis",[1.6146173000000004, 46.02324350000001]],
    [ "Enedis",[3.4277170101953947, 50.16100551029212]],
    [ "Enedis",[7.179408031620165, 48.67496166058306]],
    [ "Enedis",[7.1730029993817634, 48.66905389894207]],
    [ "Enedis",[3.4282575423843005, 50.168409225893384]],
    [ "Enedis",[3.4219768286594903, 50.16636303162205]],
    [ "Poste électrique de Mirecourt",[6.146416326881106, 48.30271176642496]],
    [ "RTE",[0.46076051917724226, 47.88127806367122]],
    [ "Enedis",[1.8265862500000003, 46.35199920000001]],
    [ "Poste électrique de l’Indret",[-1.6882079022297316, 47.197085379244136]],
    [ "232184197",[3.798882050918467, 48.01810166630637]],
    [ "RTE",[6.0719056228913235, 43.43549830874161]],
    [ "RTE",[6.570430205548033, 43.26016098647375]],
    [ "RTE",[6.383446268878141, 43.42134949851288]],
    [ "Enedis",[3.5248718783899875, 48.46829577057423]],
    [ "Vrémy",[6.284840426004728, 49.16236102695034]],
    [ "Lotissement de Vrémy",[6.2799723666577645, 49.16285294995082]],
    [ "Rue des Jardins",[6.261860355579719, 49.15889023249112]],
    [ "Failly Village",[6.260730700000001, 49.1573525]],
    [ "Enedis",[3.2659467126436783, 50.28704704597702]],
    [ "Enedis",[3.265528777947543, 50.28992707257448]],
    [ "Enedis",[3.2626069999999987, 50.290271499999996]],
    [ "Enedis",[-1.1492022751004016, 43.88024813534137]],
    [ "Enedis",[-1.152377787472036, 43.881312469798665]],
    [ "Enedis",[-1.144642153030303, 43.88333169242424]],
    [ "Enedis",[2.5131769999999993, 49.189837499999996]],
    [ "Enedis",[2.2898583511321453, 43.30753633095769]],
    [ "Enedis",[0.9458831999999999, 49.551615799999986]],
    [ "Enedis",[1.892284094331282, 46.43087552831205]],
    [ "Enedis",[0.931872303772803, 49.55635918279354]],
    [ "Enedis",[2.1836987333333338, 46.162966799530516]],
    [ "Enedis",[2.2715989999999997, 46.2244225]],
    [ "Enedis",[2.2442785729290518, 46.192188195092896]],
    [ "Enedis",[2.2328876, 46.187177899999995]],
    [ "Enedis",[2.23368975, 46.1989095]],
    [ "Enedis",[2.241803, 46.18637889999999]],
    [ "Enedis",[2.2352780995088306, 46.18965346658447]],
    [ "Enedis",[2.2493402500000017, 46.168735000000005]],
    [ "Enedis",[2.2440838951532034, 46.19394906583103]],
    [ "Enedis",[2.08302975, 46.183488]],
    [ "Enedis",[2.086885454449472, 46.1795353392911]],
    [ "Enedis",[2.0861918414092746, 46.18163643035799]],
    [ "Enedis",[-1.2193008, 43.5160756]],
    [ "Enedis",[4.513020794132602, 45.36894287792083]],
    [ "Enedis",[1.83460475, 46.41887224999999]],
    [ "Enedis",[1.8634917500000003, 46.422485050000006]],
    [ "Enedis",[1.89290535, 46.3535083]],
    [ "Enedis",[3.233499000000003, 48.411488999999996]],
    [ "Enedis",[3.24650680376427, 48.407233688882044]],
    [ "Enedis",[3.240470783200908, 48.41201564396519]],
    [ "Enedis",[3.2393414296724474, 48.40950141022947]],
    [ "Enedis",[3.2397453706563706, 48.41486562805663]],
    [ "Enedis",[3.247398928455286, 48.410208816260166]],
    [ "Enedis",[3.2490092776184625, 48.40985320032774]],
    [ "Enedis",[3.2522240858539564, 48.40920806935534]],
    [ "Enedis",[3.238176042588042, 48.41691842915643]],
    [ "Enedis",[3.2566431040355424, 48.343238602246075]],
    [ "Enedis",[3.262431784393324, 48.339327124041496]],
    [ "Enedis",[1.1852593299808896, 48.044104959184665]],
    [ "Enedis",[1.2283214582620325, 48.000569956898396]],
    [ "Enedis",[2.3111791164247006, 47.81535422772893]],
    [ "Enedis",[1.7362305804081544, 46.125676252014564]],
    [ "Enedis",[1.667006802867384, 46.093455774193544]],
    [ "Enedis",[0.9469728734166383, 49.54694004608588]],
    [ "Enedis",[3.3292273000000008, 47.9141957]],
    [ "Enedis",[2.207851449930994, 50.623238117114205]],
    [ "Enedis",[3.416411500000001, 50.183329500000006]],
    [ "Enedis",[3.41279309632446, 50.1837197008872]],
    [ "Enedis",[3.422302278431373, 50.18438297254902]],
    [ "Enedis",[3.4631316248256607, 47.79831579358438]],
    [ "Cité Pérouges",[5.17693515, 45.9042143]],
    [ "Dubois-Lecordier",[0.192848, 47.750238]],
    [ "Enedis",[0.18984348953213187, 47.75379988585472]],
    [ "Enedis",[2.500207457082452, 50.2803432141649]],
    [ "Enedis",[3.408457504968644, 47.7533860966715]],
    [ "Enedis",[3.5236175000000003, 48.023636499999995]],
    [ "Enedis",[3.5174217231638423, 48.017574066769384]],
    [ "Enedis",[3.4076437999023916, 50.18576362264519]],
    [ "Zone Artisanale Giez",[6.258764553379954, 45.76039251585082]],
    [ "Enedis",[2.365548132200912, 48.55508531093654]],
    [ "Enedis",[0.9457699500000002, 49.54181470000001]],
    [ "RTE",[-2.215555063670658, 48.1509907879851]],
    [ "Enedis",[0.7676373899883587, 48.043371823826156]],
    [ "Enedis",[1.9697426881235476, 50.894073285089526]],
    [ "Enedis",[1.9677045668417985, 50.90652271511968]],
    [ "Enedis",[1.929846650317893, 50.90559827702089]],
    [ "Enedis",[1.9340926182155136, 50.911717159121004]],
    [ "Enedis",[1.9367827666533173, 50.90872622146577]],
    [ "Enedis",[1.8241900000000002, 50.9075106793249]],
    [ "Enedis",[1.849886012276901, 50.912767874548635]],
    [ "Enedis",[1.833877323493235, 50.89637598769988]],
    [ "Enedis",[2.3222108993446327, 50.50272854639549]],
    [ "Enedis",[2.4081331320460397, 50.96224960393761]],
    [ "Enedis",[2.4125734293978747, 50.962924563478936]],
    [ "Chavannes",[6.180419254325378, 46.0796103014909]],
    [ "Chez Trosset",[6.1743397999999985, 46.05934504999999]],
    [ "La Grange",[6.147187599999999, 46.06077964999999]],
    [ "Menthonnex",[6.173589678413435, 46.05233011212311]],
    [ "Villy le Bouveret",[6.161755536515456, 46.04415147713774]],
    [ "Enedis",[2.4143621581508508, 50.95794699026763]],
    [ "Enedis",[2.4155647329490866, 50.95448512199807]],
    [ "Enedis",[2.4148528249581243, 50.95541392964823]],
    [ "Enedis",[2.0941753713729474, 50.26644495005283]],
    [ "Enedis",[2.380264296555512, 50.430641275680564]],
    [ "Enedis",[2.392745932517592, 50.42617611568617]],
    [ "Enedis",[3.313877636772309, 49.519693403911]],
    [ "Enedis",[0.9356910903781118, 49.55673137697097]],
    [ "Enedis",[0.7986581042454813, 46.92069336696091]],
    [ "Enedis",[0.8153402999999998, 46.927071549999994]],
    [ "Enedis",[0.9187244999999992, 46.920228]],
    [ "Enedis",[0.8134455322672105, 46.97787313439945]],
    [ "La Reculaz",[6.187100374213455, 46.05787134408126]],
    [ "Enedis",[0.015952250000001503, 48.282718250000016]],
    [ "Enedis",[0.025045499999999995, 48.28697950000001]],
    [ "Enedis",[1.4462931836477997, 47.211340378616356]],
    [ "Enedis",[5.15022995, 46.7876471]],
    [ "Enedis",[-0.6932255000000002, 43.43909]],
    [ "Enedis",[-0.6969449999999999, 43.4409735]],
    [ "Enedis",[0.8817289702251272, 46.99080915976761]],
    [ "Enedis",[0.8673486591865357, 47.01464989367528]],
    [ "Blye bourg",[5.703317500000001, 46.6216055]],
    [ "Enedis",[3.5271645000000014, 50.46166149999999]],
    [ "Enedis",[3.5159827391733254, 50.463782846798864]],
    [ "Enedis",[3.9436451595655813, 47.89163801336676]],
    [ "Enedis",[3.5462780000000005, 47.8339935]],
    [ "Enedis",[3.554937456431535, 47.81987184094052]],
    [ "La Loubière",[5.769665803350369, 44.8713851004543]],
    [ "Enedis",[-0.7613103321865444, 43.32674550668961]],
    [ "Enedis",[0.963542888056207, 47.42269239375487]],
    [ "Enedis",[0.9534908143939388, 47.44320095454545]],
    [ "Enedis",[0.9679861395480215, 47.4182730180791]],
    [ "Enedis",[0.9544149999999997, 47.4312605]],
    [ "Enedis",[0.9587673046025107, 47.41818904365412]],
    [ "Enedis",[0.9743125, 47.4235555]],
    [ "Enedis",[0.9344863981378014, 47.42665144022345]],
    [ "Enedis",[0.9757827203302372, 47.426261049535604]],
    [ "Enedis",[2.831448654741624, 42.65497253151619]],
    [ "Enedis",[1.906238049815498, 50.828438939114385]],
    [ "Enedis",[5.305965764879202, 45.424969382381455]],
    [ "Enedis",[3.298706912910951, 48.03233323143915]],
    [ "Enedis",[3.312178562673648, 48.037376640300906]],
    [ "Enedis",[3.29621396308601, 48.024547638242886]],
    [ "Enedis",[3.3038563391850193, 48.031977820832]],
    [ "Enedis",[3.2638551251173347, 48.024105971127966]],
    [ "Enedis",[3.2842785317538663, 48.029231583744654]],
    [ "Enedis",[3.2917226838688736, 48.032001110353775]],
    [ "Enedis",[3.307327891363814, 48.03579127002736]],
    [ "Enedis",[3.2820956398000622, 48.02622672810581]],
    [ "Enedis",[3.2888205, 48.03191150000001]],
    [ "Enedis",[3.2967497869674185, 48.02860715288221]],
    [ "Enedis",[3.302378303877366, 48.026913828975054]],
    [ "Enedis",[3.284391659649123, 48.028401168421055]],
    [ "Enedis",[3.142883801247772, 48.05543009180036]],
    [ "Enedis",[3.148658138528139, 48.054911958874456]],
    [ "Enedis",[3.1551555172413805, 48.05425924137932]],
    [ "Enedis",[3.4177205208437966, 50.20621725899882]],
    [ "Sougères",[3.3301821616091694, 47.55914542152548]],
    [ "Enedis",[4.875623039668175, 46.75965934645551]],
    [ "Enedis",[4.871616496471828, 46.756923825329125]],
    [ "Enedis",[3.4127039999999997, 50.04275499999999]],
    [ "Enedis",[3.4250424999999995, 50.0498635]],
    [ "Poste électrique de Brivet",[-2.137012443557275, 47.30375464921181]],
    [ "RTE",[-2.072571104577056, 47.313575204931006]],
    [ "réséda",[6.26517435, 49.11807239999999]],
    [ "réséda",[6.295332981936778, 49.1220734736578]],
    [ "réséda",[6.285393716524929, 49.119016763881945]],
    [ "réséda",[6.283474493311342, 49.11936149899212]],
    [ "réséda",[6.277692199999999, 49.1208522]],
    [ "Enedis",[3.4166013643195106, 50.0471105198998]],
    [ "Enedis",[1.1209839871635616, 49.6021467031056]],
    [ "réséda",[6.299392692946059, 49.10072768049792]],
    [ "réséda",[6.294840443882569, 49.097875201292325]],
    [ "réséda",[6.242423685534591, 49.05550598113208]],
    [ "réséda",[6.241991594925233, 49.05686757013701]],
    [ "réséda",[6.2385012500000006, 49.05739395]],
    [ "Le Frene",[6.21523808122929, 46.0501625557114]],
    [ "Poste électrique de Chancia",[5.632548936570009, 46.347791362252416]],
    [ "Enedis",[-0.7151260475956468, 43.25757276779475]],
    [ "Enedis",[5.728353495876004, 46.69827425067261]],
    [ "Enedis",[3.3565411827411165, 50.20032502588832]],
    [ "Poste électrique d'Araches",[6.635052810892795, 46.0171881089187]],
    [ "Enedis",[6.63898490869303, 46.03975862397672]],
    [ "Enedis",[6.63758713684178, 46.03796785253255]],
    [ "Enedis",[6.6353790431698725, 46.01581008235508]],
    [ "Enedis",[6.63020999044493, 46.055455722246535]],
    [ "Enedis",[3.1334448998153284, 43.13445915535549]],
    [ "Enedis",[3.0928454186228485, 43.10566507089202]],
    [ "Enedis",[3.1000550000000002, 43.110361999999995]],
    [ "Enedis",[3.1117176415094336, 43.10286661006289]],
    [ "Enedis",[3.093365376284074, 43.10817070686728]],
    [ "Enedis",[3.1357192600881656, 43.13299761207189]],
    [ "Enedis",[3.352669272654157, 50.201190431456666]],
    [ "Enedis",[3.3485798363363366, 50.20580132012011]],
    [ "Enedis",[3.101011967711301, 43.11289717188984]],
    [ "Enedis",[3.0956124594594594, 43.104542153153155]],
    [ "Enedis",[3.1032898338762207, 43.11254725950054]],
    [ "Enedis",[3.095789316666666, 43.10500203499999]],
    [ "Enedis",[3.0960551850390656, 43.10701779113483]],
    [ "Enedis",[3.0979255519075375, 43.10497891582285]],
    [ "Enedis",[3.1005803174775752, 43.11170168252242]],
    [ "Enedis",[3.0997958227752638, 43.11451199660633]],
    [ "Enedis",[3.097290542599135, 43.11032576658815]],
    [ "Enedis",[3.1022119958814574, 43.110981316501025]],
    [ "Enedis",[5.974497, 47.23307]],
    [ "Enedis",[-0.34948199999999974, 43.476125249999996]],
    [ "réséda",[6.102242122742577, 49.02964834404652]],
    [ "réséda",[6.0863405, 49.031828499999996]],
    [ "réséda",[6.099725747474747, 49.0298474040404]],
    [ "réséda",[6.097654949599679, 49.02893469160829]],
    [ "réséda",[6.1044237128036825, 49.028620690353485]],
    [ "réséda",[6.096552933228179, 49.03061381072555]],
    [ "réséda",[6.094697500000001, 49.02747049999999]],
    [ "Enedis",[0.9711696931802013, 49.52902121712524]],
    [ "RTE",[5.591622477308497, 46.29210541921286]],
    [ "Oyonnax",[5.64870328145967, 46.262383241677]],
    [ "Enedis",[3.3126541520325206, 50.266093150406505]],
    [ "Enedis",[3.3134589638297873, 50.26305218794327]],
    [ "Enedis",[3.3163459000000004, 50.265869]],
    [ "Enedis",[3.3239590827945, 50.263281385581564]],
    [ "Enedis",[3.3429466529723015, 50.25441602365391]],
    [ "Enedis",[3.3146155315625507, 50.262450342515166]],
    [ "Enedis",[3.3403910000000003, 50.2596365]],
    [ "Enedis",[3.3183010941759608, 50.26215483705081]],
    [ "Enedis",[3.3460998154506436, 50.25870257749166]],
    [ "Enedis",[3.3271908295417876, 50.264412839625685]],
    [ "Enedis",[3.3457434999999993, 50.257090999999996]],
    [ "Enedis",[4.2136482399999995, 46.791625493333335]],
    [ "Enedis",[2.2039545375440155, 50.38842725666]],
    [ "Enedis",[3.4687031638095234, 50.369886667845805]],
    [ "Enedis",[3.464263852813852, 50.36987372294371]],
    [ "Enedis",[5.533911746420249, 46.82615573641473]],
    [ "Enedis",[5.52068711733343, 46.837985915701154]],
    [ "Enedis",[5.535648194245279, 46.82465909584221]],
    [ "ESSeyssel",[6.088508674104657, 45.85715059084931]],
    [ "Enedis",[3.4609525523336107, 50.15527626373929]],
    [ "Enedis",[3.4667172787825318, 50.15343568137039]],
    [ "Enedis",[3.461687287031809, 50.15430512874082]],
    [ "Enedis",[3.4623395784061697, 50.15266529391602]],
    [ "Enedis",[0.5874254274809154, 48.04797249872773]],
    [ "Enedis",[0.5860349919587321, 48.042444770747984]],
    [ "Enedis",[1.3358602527849908, 43.51086981820499]],
    [ "Enedis",[-0.7343763223071059, 46.389420003619726]],
    [ "Enedis",[0.06650379407806116, 47.49499451144011]],
    [ "Enedis",[0.05043949999999999, 47.518237]],
    [ "réséda",[6.356981754248365, 49.09199336993464]],
    [ "réséda",[6.335670110516933, 49.06631175579323]],
    [ "réséda",[6.359711649371928, 49.09730976934279]],
    [ "réséda",[6.340410999999999, 49.0683065]],
    [ "réséda",[6.366297614706564, 49.0944926986778]],
    [ "réséda",[6.356643511511516, 49.08588542221868]],
    [ "réséda",[6.35277255, 49.08567604999999]],
    [ "Enedis",[3.078501208179581, 50.32674404059041]],
    [ "Enedis",[3.076024671876252, 50.32918152656889]],
    [ "Enedis",[4.342648999999999, 45.4400032]],
    [ "Enedis",[0.021549257588215135, 47.294367839842415]],
    [ "Enedis",[2.2381044880768672, 48.57424158390009]],
    [ "Enedis",[6.56918975741327, 45.73085520427784]],
    [ "Enedis",[1.3814268104127774, 43.51353044181705]],
    [ "Enedis",[1.3775678368007396, 43.51302999260287]],
    [ "Enedis",[1.3777874939800483, 43.51074016477468]],
    [ "Enedis",[1.3746505996321274, 43.513471717760076]],
    [ "Enedis",[1.3722337255272268, 43.51134082887419]],
    [ "Enedis",[1.3691669576502725, 43.5100788852459]],
    [ "Enedis",[1.367371118363794, 43.507867217580504]],
    [ "Enedis",[1.3661327845742657, 43.50420058999618]],
    [ "Enedis",[3.3319800977564094, 50.26736537051282]],
    [ "Enedis",[3.3279318485484435, 50.27374138382884]],
    [ "Enedis",[3.3339958283118123, 50.27856027098676]],
    [ "Enedis",[1.2343036815514519, 49.98241620666952]],
    [ "Enedis",[1.2382500553574451, 49.980470650874516]],
    [ "Enedis",[1.2336173634302272, 49.980302243307875]],
    [ "Enedis",[1.2533858126520683, 49.98513367477697]],
    [ "Enedis",[1.2481034679006173, 49.98163015907954]],
    [ "Enedis",[1.108187, 49.8604345]],
    [ "Mairie",[5.906021837281647, 45.92412548887294]],
    [ "Saint-André",[5.8957132, 45.930357799999996]],
    [ "Enedis",[-0.3154669852320673, 47.500650289029544]],
    [ "Enedis",[3.351257166807711, 50.269031453173476]],
    [ "Enedis",[1.3377011606837612, 50.04004899230769]],
    [ "Enedis",[1.2831610775503413, 50.00986885538358]],
    [ "Enedis",[1.3249283282390891, 50.034590910511135]],
    [ "REThones",[6.378482160732446, 45.856961927886985]],
    [ "REThones",[6.331491180468593, 45.861015988981194]],
    [ "Enedis",[6.930749057471265, 48.94940867816092]],
    [ "Enedis",[6.888619, 49.028949499999996]],
    [ "Enedis",[6.887584305220884, 49.026624558232925]],
    [ "Enedis",[6.831889414156258, 49.03347527577761]],
    [ "Enedis",[6.835746774481657, 49.03602396267942]],
    [ "Enedis",[2.7154416358410156, 50.31187832622772]],
    [ "Enedis",[2.7074948192959143, 50.29279176340853]],
    [ "Enedis",[2.710133008589442, 50.3042466971997]],
    [ "Enedis",[2.7013914843984796, 50.306798312837145]],
    [ "Enedis",[2.6779727258417307, 50.311296109185044]],
    [ "Enedis",[2.6790889854685784, 50.30512199449239]],
    [ "Enedis",[2.7091770572763494, 50.29215621238546]],
    [ "Enedis",[2.685834403510954, 50.30698790676876]],
    [ "Enedis",[2.6820361894728486, 50.31003909929175]],
    [ "Enedis",[2.6819277243478266, 50.30730892589372]],
    [ "Enedis",[-0.05729149999999979, 47.514309999999995]],
    [ "Enedis",[-0.06657549999999923, 47.510738499999995]],
    [ "Enedis",[-0.0793065471220743, 47.49858539911449]],
    [ "Enedis",[3.3446362836879437, 50.273862456028375]],
    [ "Enedis",[3.350116084010841, 50.274928481571806]],
    [ "Enedis",[3.344621578231293, 50.27183148367346]],
    [ "Enedis",[3.34998877437468, 50.272070096835115]],
    [ "Enedis",[0.06629858823529391, 47.301265156862755]],
    [ "Enedis",[0.06366825087218546, 47.30821082334283]],
    [ "Enedis",[0.0642711090224807, 47.303725295382115]],
    [ "Poste électrique de Cubnezais",[-0.43552965916309766, 45.06876009724949]],
    [ "Enedis",[1.8353683834771495, 45.77122496519655]],
    [ "Enedis",[1.8823014806324108, 45.783661882301274]],
    [ "Enedis",[1.8759579409064833, 50.910088841652325]],
    [ "Enedis",[1.875562614325068, 50.88457777134987]],
    [ "Enedis",[1.1532418410419993, 49.9193543195109]],
    [ "Enedis",[1.1500384999999995, 49.9209205]],
    [ "Enedis",[1.1472149699248122, 49.92112289223058]],
    [ "Enedis",[1.21184953719364, 49.93874275201332]],
    [ "Enedis",[0.6425953361285814, 49.788954515024464]],
    [ "Enedis",[0.6381755, 49.790714]],
    [ "Enedis",[0.6442851275990371, 49.790127264390456]],
    [ "Enedis",[0.6442372470588236, 49.78115508235294]],
    [ "Enedis",[0.6363535502645498, 49.78223765255732]],
    [ "Enedis",[0.6441789999999995, 49.77670749999999]],
    [ "RTE",[4.850343140933895, 46.817500270405496]],
    [ "Enedis",[-0.05667334146341468, 47.17061278861788]],
    [ "Enedis",[0.1568266191780819, 47.54778878538813]],
    [ "Enedis",[0.19520912164297027, 47.563925706161136]],
    [ "Enedis",[0.17028191827957026, 47.58727516344086]],
    [ "Enedis",[0.178968, 47.475792000000006]],
    [ "Enedis",[0.1559553196229653, 47.473940158526126]],
    [ "Enedis",[-0.0680464, 47.211104899999995]],
    [ "Enedis",[6.023905757554013, 45.77204724999174]],
    [ "Enedis",[6.019430809000629, 45.775883782035926]],
    [ "Enedis",[3.122779973108193, 43.23397786929331]],
    [ "Enedis",[3.1107248867202446, 43.23972138614619]],
    [ "Enedis",[3.1115336266476104, 43.23496168048277]],
    [ "Enedis",[3.120103019047619, 43.24171652380952]],
    [ "Enedis",[3.108223413793104, 43.23504886206897]],
    [ "Enedis",[3.118563687830688, 43.237777216931214]],
    [ "Enedis",[3.116665741258741, 43.23990154428904]],
    [ "Enedis",[3.1131837908496727, 43.23815208496732]],
    [ "Enedis",[3.1225553922807006, 43.23670791649122]],
    [ "Enedis",[1.0882276, 46.083444]],
    [ "Enedis",[1.8217714999999999, 50.804905999999995]],
    [ "Enedis",[1.8256728005698009, 50.805729306742634]],
    [ "Enedis",[1.9354908309539998, 50.789906496714245]],
    [ "Enedis",[1.943754536923931, 50.783107015546925]],
    [ "Enedis",[1.9323555294117671, 50.784786333333344]],
    [ "Enedis",[1.90467, 50.77659650000001]],
    [ "Enedis",[2.788305619339947, 43.148323302144576]],
    [ "Enedis",[-0.5597199999999984, 47.664854500000004]],
    [ "Enedis",[3.3983254995102845, 50.22430997812602]],
    [ "Enedis",[3.4036632181818187, 50.22575329090908]],
    [ "Enedis",[2.201368841818764, 49.32308463752802]],
    [ "Pont de Vannes",[5.708124524301009, 45.11045715563332]],
    [ "Enedis",[4.828389849132291, 46.797573616897914]],
    [ "Enedis",[1.0038489182059671, 46.07034560037286]],
    [ "Enedis",[0.9632514678138335, 46.065137847011826]],
    [ "Enedis",[0.9964613464417983, 46.06225974727721]],
    [ "Enedis",[0.9939915498171634, 46.065632148868225]],
    [ "Enedis",[2.8487854999999995, 43.229178]],
    [ "Enedis",[2.837837639329295, 43.20764413283177]],
    [ "Enedis",[2.840024680742999, 43.230421026846614]],
    [ "Enedis",[4.338403938820905, 45.442555831459195]],
    [ "Enedis",[0.77418362649522, 47.93839309461816]],
    [ "Enedis",[6.023063112770884, 47.25773581916873]],
    [ "Enedis",[1.3717055123558488, 43.6522863272927]],
    [ "Enedis",[3.3761341422966655, 50.12849731929983]],
    [ "Enedis",[5.194027088402062, 46.723370676761164]],
    [ "Enedis",[5.1724132, 46.7231494]],
    [ "Enedis",[1.678377032348619, 46.47420497579553]],
    [ "Enedis",[3.3830865, 50.130231499999994]],
    [ "Poste électrique de Givet",[4.821227300955288, 50.13296555549026]],
    [ "Poste électrique de Fromelennes",[4.8568016499999995, 50.11461525]],
    [ "Camp de Verdun",[5.6950771248587975, 45.12666642326065]],
    [ "Enedis",[2.2618748331872625, 50.88370199444931]],
    [ "Enedis",[2.2937134298158557, 50.86143717818944]],
    [ "Enedis",[2.296504280657276, 50.860396692018774]],
    [ "Luce District",[0.7361004999999999, 47.91684179999999]],
    [ "Enedis",[0.7660541999999999, 47.9167973]],
    [ "Enedis",[0.7398062728935944, 47.925462656686534]],
    [ "236089104",[5.103963101503051, 46.24476778226602]],
    [ "Route de Vevy",[5.6586799999999995, 46.68521175]],
    [ "Enedis",[3.398117077380952, 50.07921861093073]],
    [ "Enedis",[-0.3508455, 47.4682235]],
    [ "Enedis",[-0.34660362652344545, 47.47373257288439]],
    [ "Enedis",[2.1967729214892238, 49.35024990666211]],
    [ "Enedis",[0.08684082374159628, 47.58215617281521]],
    [ "réséda",[6.476574492985003, 48.99784626463474]],
    [ "réséda",[6.521197100000002, 49.0022351]],
    [ "réséda",[6.543269991337101, 49.01953001977401]],
    [ "Enedis",[0.03269380457844761, 47.461110513121156]],
    [ "Les Sagnolles",[4.246974499999999, 45.249416249999996]],
    [ "Les Patureaux",[4.249973323800022, 45.25691971655027]],
    [ "Le Prat",[4.248334966386555, 45.25221532212885]],
    [ "Colly Martin",[4.253591000000001, 45.252293]],
    [ "Enedis",[4.256602592132505, 45.25057470531401]],
    [ "Beau Soleil",[4.233672749999999, 45.25224389999999]],
    [ "Janouissaire",[4.219039749999999, 45.24436325]],
    [ "La Croix",[4.223815428173345, 45.246111313442874]],
    [ "Enedis",[6.398575500000001, 49.444475999999995]],
    [ "Enedis",[6.39523467013087, 49.44181100615858]],
    [ "Enedis",[2.3156688776770458, 49.32013869425831]],
    [ "Enedis",[2.3282309590163934, 49.3121389063232]],
    [ "Enedis",[2.3205892923777007, 49.32080397042094]],
    [ "Enedis",[2.3265563218390803, 49.30766596551724]],
    [ "Enedis",[-0.3570612289065453, 47.702097107075296]],
    [ "Enedis",[-0.36038983979631806, 47.69939798002349]],
    [ "Enedis",[-0.11622631744163195, 47.232077166960956]],
    [ "Antide",[6.131141889364605, 45.90419696101004]],
    [ "Haras",[6.128489501382633, 45.90409260592257]],
    [ "Rondpoint Parmelan",[6.132133088134956, 45.9050118250653]],
    [ "Les Roches",[4.229529381203957, 45.25120229497877]],
    [ "HLM La Croze",[4.227221338205249, 45.24452416643093]],
    [ "Croix de Fruges",[4.236531989139516, 45.246725821599455]],
    [ "Enedis",[4.2329394505328475, 45.24478078885742]],
    [ "STTP",[4.231327011185683, 45.24636171588368]],
    [ "Le Réservoir",[4.230527068938504, 45.24048968605562]],
    [ "Flachères",[4.236632089954338, 45.243549143379]],
    [ "La Peyrouse",[4.24138145, 45.2429633]],
    [ "Église",[4.233905886601727, 45.24311219987748]],
    [ "Enedis",[5.69145287476045, 45.997240725343396]],
    [ "Colavière",[5.674063920831719, 45.27314202110816]],
    [ "Enedis",[6.385228880772689, 48.89114890592991]],
    [ "RTE",[5.553289646937606, 46.27659165671661]],
    [ "Enedis",[-0.18686158061734817, 47.47855065787205]],
    [ "Enedis",[-0.18974700000000105, 47.486375499999994]],
    [ "Enedis",[2.1221050000000004, 49.349953]],
    [ "Enedis",[2.1175369702537186, 49.34747316972879]],
    [ "réséda",[6.335042144267275, 49.02706631814731]],
    [ "réséda",[6.376397999999999, 48.985604499999994]],
    [ "réséda",[6.380493907692308, 48.98481365641025]],
    [ "Enedis",[2.9770671292818784, 42.92965089472546]],
    [ "Enedis",[7.003038823091247, 49.17601993854749]],
    [ "Enedis",[6.998587500000001, 49.178392]],
    [ "Enedis",[6.998856074626866, 49.1814523221393]],
    [ "Enedis",[7.013571689419796, 49.17808903337126]],
    [ "Enedis",[7.011227650000002, 49.1868726]],
    [ "Enedis",[6.9989405, 49.173224499999996]],
    [ "Enedis",[-0.6956544683544302, 47.59780407594937]],
    [ "Enedis",[4.760299433389674, 46.77813507418832]],
    [ "Enedis",[3.4328719116714166, 50.5033726586706]],
    [ "Enedis",[3.4375273331350393, 50.500241995439225]],
    [ "Enedis",[3.4339894999999996, 50.498141]],
    [ "Enedis",[3.3292353147208127, 50.44575866159052]],
    [ "Enedis",[3.3464164266337857, 50.44073877147555]],
    [ "Enedis",[3.3448754999999997, 50.444683]],
    [ "Enedis",[3.33961257199211, 50.44875147435897]],
    [ "Enedis",[0.07687380308030912, 47.341451473204465]],
    [ "Enedis",[-0.9393035000000002, 47.05684]],
    [ "Enedis",[-0.9370358319388867, 47.056190434461016]],
    [ "236935173",[5.01532083643549, 46.23018027676948]],
    [ "Enedis",[4.978612, 46.20615399999999]],
    [ "Enedis",[3.457385500000001, 50.2853305]],
    [ "Enedis",[3.291869978902953, 50.491529973101265]],
    [ "Enedis",[3.4572884999999998, 50.28742599999999]],
    [ "Enedis",[3.295804499999999, 50.4911665]],
    [ "Enedis",[3.454426843351549, 50.28586867431694]],
    [ "Billin",[5.62687775, 46.7438555]],
    [ "Enedis",[5.629396249960172, 46.738497500477926]],
    [ "Enedis",[3.7748737485380115, 50.27430605847953]],
    [ "Le Gontard",[4.934738189481445, 45.431890165372764]],
    [ "Enedis",[3.5148219999999997, 50.14398099999999]],
    [ "Enedis",[3.5105065000000004, 50.1448565]],
    [ "Enedis",[3.5116663573883153, 50.14200353951889]],
    [ "Enedis",[3.1536092841664387, 50.25798884494219]],
    [ "Enedis",[3.1570348787505136, 50.25792075585697]],
    [ "Enedis",[3.1931713906321053, 50.254187210095495]],
    [ "Enedis",[3.229999613270514, 50.259277396544974]],
    [ "Enedis",[3.2262905, 50.25547]],
    [ "Enedis",[3.2286868281573495, 50.25750151345756]],
    [ "Enedis",[2.3868822332027646, 48.690375797075745]],
    [ "Enedis",[3.216798564681725, 50.21877667624914]],
    [ "Enedis",[3.2201013435489974, 50.22064498070373]],
    [ "Enedis",[3.2124438355029588, 50.220280067652865]],
    [ "Enedis",[3.1928252347057455, 50.21636465457083]],
    [ "Enedis",[3.1937280373255432, 50.20203768776371]],
    [ "Enedis",[3.1564115267034993, 50.20697766421117]],
    [ "Enedis",[3.1623158471824255, 50.211482979942694]],
    [ "Enedis",[3.1586247354333943, 50.21108504097179]],
    [ "Enedis",[3.1769439999999993, 50.196815500000014]],
    [ "Enedis",[3.172330329068942, 50.19217574555793]],
    [ "Enedis",[3.1774985553602817, 50.1922293815661]],
    [ "Enedis",[3.178455564844587, 50.1893464619507]],
    [ "Enedis",[6.262856093457942, 48.77370431464174]],
    [ "Enedis",[6.251569000000001, 48.76300800000001]],
    [ "Enedis",[6.262569312581064, 48.77205296108949]],
    [ "Enedis",[6.259138697323601, 48.775201594403896]],
    [ "Enedis",[6.264644198067632, 48.76899204830917]],
    [ "Enedis",[6.266538245324532, 48.777617683168316]],
    [ "Enedis",[6.266609999999998, 48.770993999999995]],
    [ "Enedis",[6.255212171676687, 48.76465344488978]],
    [ "Enedis",[6.2711892100310695, 48.77648536245006]],
    [ "Enedis",[6.257560241590214, 48.77206314984709]],
    [ "Poste électrique de Lannilis",[-4.545481200088083, 48.54392509991313]],
    [ "Enedis",[3.2746285, 50.24707099999999]],
    [ "Enedis",[3.278601934678195, 50.24408683813641]],
    [ "Enedis",[3.2781054999999997, 50.246335]],
    [ "Enedis",[3.2707536426291077, 50.24608879549295]],
    [ "Poste électrique de Rumengol",[-4.143367607858246, 48.30363148259579]],
    [ "Poste électrique de Dirinon",[-4.261283944153818, 48.38952417975142]],
    [ "Poste électrique de Plougastel",[-4.350651927126687, 48.37663057539848]],
    [ "Enedis",[3.236909347467047, 50.23430231507067]],
    [ "Enedis",[3.2295396698656433, 50.228884484325015]],
    [ "Enedis",[3.2284783794135246, 50.22637432794734]],
    [ "Enedis",[3.237238025765001, 47.89484360731036]],
    [ "Enedis",[3.3039076574074073, 50.25308117592593]],
    [ "SICAE de la Somme et du Cambraisis",[3.2826631518138267, 50.22705648131417]],
    [ "SICAE de la Somme et du Cambraisis",[3.2867338709744596, 50.22624592315886]],
    [ "Enedis",[3.3178161682979885, 50.22726699129962]],
    [ "SICAE de la Somme et du Cambraisis",[3.2956987062146887, 50.221096056497174]],
    [ "Enedis",[3.308924499999999, 50.19887899999999]],
    [ "Enedis",[3.316879874458875, 50.20112059740259]],
    [ "Enedis",[3.3471720000000005, 50.1491435]],
    [ "Enedis",[3.3505753067092656, 50.16828307561236]],
    [ "Enedis",[3.128469, 50.1104645]],
    [ "Enedis",[-0.27731218603538893, 47.52715052367288]],
    [ "Enedis",[-0.2801058282891529, 47.52321432394073]],
    [ "Enedis",[-0.28315977311309937, 47.52265041683872]],
    [ "Enedis",[-0.46776789743589775, 47.10768981025641]],
    [ "Enedis",[-0.46956496822788285, 47.109373345384824]],
    [ "Enedis",[1.222922937827716, 47.32218434906368]],
    [ "Enedis",[3.1637285908045976, 50.074170616091955]],
    [ "Enedis",[3.113460374133649, 50.082425336861476]],
    [ "Enedis",[3.221008685700342, 50.076068709614454]],
    [ "Enedis",[3.2239335696626856, 50.07851985816048]],
    [ "Enedis",[3.1982699, 50.0801227]],
    [ "Enedis",[3.2390725532377225, 50.09627783659279]],
    [ "Enedis",[3.2347838900756565, 50.0915661388518]],
    [ "Enedis",[3.234835215880147, 50.09348272314167]],
    [ "Enedis",[3.1960177500000007, 50.07921199999999]],
    [ "Enedis",[3.1794129279393175, 50.07364926927939]],
    [ "Enedis",[3.2046875361305363, 50.0602956938617]],
    [ "Enedis",[3.198249329230769, 50.03982288820512]],
    [ "Enedis",[3.1984591660632393, 50.03309399493122]],
    [ "Enedis",[3.2191861773286936, 49.86133498238312]],
    [ "Enedis",[3.211986765451609, 49.864088333467194]],
    [ "Enedis",[3.2157049381773772, 49.86120015017905]],
    [ "Enedis",[3.212258757749107, 49.8615516768774]],
    [ "Enedis",[3.2158624629173604, 49.86383173427654]],
    [ "Enedis",[3.2166065129289634, 49.85813917115841]],
    [ "Enedis",[3.208078283580176, 49.85967661645578]],
    [ "Enedis",[3.213429341897828, 49.858604570606424]],
    [ "Enedis",[3.2084531999999997, 49.77465585000001]],
    [ "Enedis",[4.827108496798802, 46.83035076597194]],
    [ "Enedis",[-0.10514397860300582, 47.43464218337754]],
    [ "Enedis",[0.11793320903954826, 47.49506084745763]],
    [ "237564632",[-0.5257613347794649, 47.15349231308749]],
    [ "Enedis",[-0.5203362261904766, 47.15233461349207]],
    [ "Enedis",[6.248940849999999, 48.15388925]],
    [ "Enedis",[6.2483712989858295, 48.15109567296275]],
    [ "Enedis",[3.259207605445628, 50.06447672061559]],
    [ "Enedis",[3.3710577849131433, 50.04636189125974]],
    [ "Enedis",[3.2865481328320802, 50.07282568922306]],
    [ "Enedis",[3.268683325052632, 50.09852067185965]],
    [ "Enedis",[3.3053256857402356, 50.12932446594005]],
    [ "Enedis",[3.303051308128545, 50.10418446565847]],
    [ "Enedis",[3.3722929241311794, 50.08578041948116]],
    [ "Enedis",[3.3692795, 50.16386850000001]],
    [ "Enedis",[3.3922337523056654, 50.1594608647343]],
    [ "Enedis",[3.421139057982526, 50.19190011675933]],
    [ "Enedis",[3.4243185, 50.15165349999999]],
    [ "Enedis",[3.435794513151602, 50.13533633763749]],
    [ "Enedis",[2.246299, 49.304375500000006]],
    [ "Enedis",[-0.3906962247231154, 47.10569792568776]],
    [ "Enedis",[3.4851951381468105, 50.16105794681107]],
    [ "Enedis",[3.488527683060109, 50.160296934426235]],
    [ "Enedis",[3.5385359512991834, 50.120114647661474]],
    [ "Enedis",[3.542088200000001, 50.1165909]],
    [ "Enedis",[3.4571254065018557, 50.12370021579281]],
    [ "Enedis",[3.461531357270454, 50.12259424222937]],
    [ "Enedis",[3.465821961403508, 50.12152621052631]],
    [ "Enedis",[3.46427549953832, 50.10249892890119]],
    [ "Enedis",[3.486538925925926, 50.081480703703704]],
    [ "Enedis",[3.480626310753922, 50.083064010524296]],
    [ "Enedis",[3.4824658333825993, 50.08287149768449]],
    [ "Enedis",[3.467333212792845, 50.07286017235346]],
    [ "Enedis",[3.4670050900491867, 50.07657280817253]],
    [ "Enedis",[3.484202331970316, 50.06530149719825]],
    [ "Enedis",[3.4577902955460313, 50.06617019413063]],
    [ "Enedis",[3.471735, 50.06808900000001]],
    [ "Enedis",[2.9994731184254597, 43.24112876943049]],
    [ "Enedis",[2.9970650969316592, 43.24731108368201]],
    [ "Enedis",[2.997681721442885, 43.242538197060796]],
    [ "Enedis",[2.9932426535947716, 43.26291582643428]],
    [ "Enedis",[3.0044618415147273, 43.24326296026181]],
    [ "Enedis",[3.019671290693363, 43.265858811642566]],
    [ "Enedis",[3.0193173766218853, 43.275373761740724]],
    [ "Enedis",[2.995794441843014, 43.25212073795587]],
    [ "Enedis",[3.0027266069182392, 43.24519812421384]],
    [ "Enedis",[2.9980895419523343, 43.24763702873]],
    [ "Enedis",[6.24621374929972, 48.22056467058823]],
    [ "Enedis",[2.7776362982998464, 50.210088736218445]],
    [ "Enedis",[2.7811141638418078, 50.21032852542371]],
    [ "Enedis",[2.199122, 49.2854295]],
    [ "Poste électrique de Vesaignes",[5.4378095211479645, 48.27849942214138]],
    [ "Enedis",[5.334246524463198, 45.15923525430078]],
    [ "Enedis",[5.338673183194035, 45.159510777197646]],
    [ "Mazirot Centre",[6.148159114613181, 48.32306174546323]],
    [ "Transformateur du Château",[6.145260100000001, 48.3291245]],
    [ "Enedis",[1.947946824113475, 43.269372032624105]],
    [ "Enedis",[1.9466669999999997, 43.2814325]],
    [ "Enedis",[2.7595705718135277, 50.20037931581784]],
    [ "Enedis",[2.7702826623763057, 50.210729565428]],
    [ "Enedis",[5.700863504453158, 46.73736050806658]],
    [ "Enedis",[-0.7610361000000002, 46.49582170000001]],
    [ "Enedis",[-0.7795833000000001, 46.487314]],
    [ "Enedis",[-0.7695854280569128, 46.52111785922067]],
    [ "Enedis",[-0.755260983970645, 46.52305882146775]],
    [ "Enedis",[2.141251332920281, 49.2806084952499]],
    [ "Enedis",[0.1642308008255935, 47.436406210526314]],
    [ "Enedis",[-0.3544602870170392, 47.11710475665947]],
    [ "238348088",[-0.36368325390070894, 47.11822913475177]],
    [ "238348136",[-0.3705907961630694, 47.122480860911274]],
    [ "238348190",[-0.36707704166666694, 47.118364337500005]],
    [ "238348457",[-0.3631721610486891, 47.11960606741573]],
    [ "238348773",[-0.37110650643274845, 47.120148915204666]],
    [ "Verges bourg",[5.680851244135933, 46.654550504275136]],
    [ "SNCF Réseau",[6.2270606138604885, 48.957689620900325]],
    [ "Riflard",[2.1438755999999994, 49.386019999999995]],
    [ "Enedis",[5.734610107648058, 46.64934307627987]],
    [ "Enedis",[5.729103391874361, 46.66082211804939]],
    [ "Enedis",[5.728852500000001, 46.65760050000001]],
    [ "Enedis",[2.8756243532763532, 43.26708127635328]],
    [ "Enedis",[2.8708090251207725, 43.266866157487925]],
    [ "Enedis",[-0.5980447898518116, 47.51996298172194]],
    [ "Enedis",[3.00078485, 48.32927384999999]],
    [ "Enedis",[5.672641437496651, 46.55578932432928]],
    [ "Bourg",[5.671801810079103, 46.55387040178617]],
    [ "Enedis",[5.5618815, 46.802075499999994]],
    [ "Enedis",[-0.9420534999999999, 47.029528]],
    [ "Enedis",[5.64189675, 46.63262425]],
    [ "Enedis",[5.639179117830124, 46.63520565360788]],
    [ "Enedis",[1.974190914239481, 46.952156077669905]],
    [ "Enedis",[1.9783687427473586, 46.95206365609991]],
    [ "Enedis",[1.9825213283909942, 46.94999789126854]],
    [ "Enedis",[1.9807963987538946, 46.94412417133957]],
    [ "Enedis",[1.995317580128206, 46.95754479647436]],
    [ "Enedis",[1.993752186176143, 46.9517830245262]],
    [ "Enedis",[2.851238738356165, 42.77169185251142]],
    [ "Enedis",[2.0050183333333336, 46.95457782051282]],
    [ "Enedis",[2.000001136134007, 46.95164868740582]],
    [ "Enedis",[1.99637987562189, 46.95151924875622]],
    [ "Enedis",[1.991964680555555, 46.94940832722223]],
    [ "Enedis",[1.9993847343389906, 46.94809824827153]],
    [ "Enedis",[2.005727023065167, 46.948359268306234]],
    [ "Enedis",[2.005475917742344, 46.94621469020501]],
    [ "Enedis",[1.9933970000000003, 46.94792733333333]],
    [ "Enedis",[1.993422, 46.946834999999986]],
    [ "Enedis",[1.9919189999999989, 46.946969]],
    [ "Enedis",[1.9856004999999999, 46.946726]],
    [ "Enedis",[1.9978672208861448, 46.945593577293906]],
    [ "Enedis",[2.001095721775736, 46.945071683505546]],
    [ "Enedis",[1.9958746222719042, 46.94167133386564]],
    [ "Enedis",[2.0007994999999994, 46.941965999999994]],
    [ "Enedis",[1.9868066107980613, 46.94354674325776]],
    [ "Enedis",[1.9866398277326307, 46.94127251811992]],
    [ "Enedis",[1.9889197763215383, 46.94253848492792]],
    [ "Enedis",[1.9832945324440736, 46.93804147922745]],
    [ "Enedis",[2.7981487887840095, 50.21195138486026]],
    [ "Enedis",[1.9950478030303032, 46.93894809848485]],
    [ "Enedis",[1.996255, 46.937642000000004]],
    [ "Enedis",[2.0047637600000003, 46.93912066666666]],
    [ "Enedis",[2.003698861894737, 46.94323574315789]],
    [ "Enedis",[2.0118118096381683, 46.94304298983349]],
    [ "Enedis",[2.0094965, 46.94982149999999]],
    [ "Enedis",[2.007913455938697, 46.9481378467433]],
    [ "Enedis",[2.0079940575986894, 46.95021002773684]],
    [ "Enedis",[2.0152556677858606, 46.96004802797985]],
    [ "Enedis",[2.006325, 46.957206000000006]],
    [ "Enedis",[2.012966, 46.960934]],
    [ "Enedis",[2.0078132805402094, 46.953791472068765]],
    [ "Enedis",[2.0096846470539784, 46.958864456118675]],
    [ "Enedis",[2.0022085651626433, 46.96257318034277]],
    [ "Enedis",[2.001520242125745, 46.96496314690503]],
    [ "Enedis",[1.9995019631139384, 46.965843916131625]],
    [ "Enedis",[2.0076506226490602, 46.97548777871149]],
    [ "Enedis",[-3.6101800119260576, 48.602339833631476]],
    [ "Enedis",[1.1416457070686472, 44.50428880106196]],
    [ "Enedis",[1.1429543809447191, 44.499501717746256]],
    [ "Enedis",[1.1322152107554442, 44.51004452029131]],
    [ "Enedis",[1.435985517583333, 47.13355530017193]],
    [ "Enedis",[1.4454871530758227, 47.130274969957085]],
    [ "Enedis",[1.439684558139535, 47.12833517829457]],
    [ "Enedis",[2.334452, 50.6915865]],
    [ "Enedis",[2.3343176656872346, 50.69302898726739]],
    [ "Enedis",[2.332508632982817, 50.6937107657519]],
    [ "Enedis",[2.3633361902465166, 50.70747810289388]],
    [ "Enedis",[2.342129012967201, 50.71079132875668]],
    [ "Enedis",[2.3521011619047614, 50.709434666666674]],
    [ "Enedis",[5.775324999737662, 45.43892979352353]],
    [ "Enedis",[2.0164496417910454, 50.823236691542284]],
    [ "Enedis",[2.0045815000000005, 50.830516499999995]],
    [ "Enedis",[2.11966, 50.84910550000001]],
    [ "SE ETUDIANTE",[2.0546837218875496, 48.80811390963855]],
    [ "Enedis",[2.0805400000000005, 50.81869449999999]],
    [ "Enedis",[2.0868925, 50.81546550000001]],
    [ "Enedis",[2.153644212926073, 50.75080565250111]],
    [ "Enedis",[2.94522731854317, 42.69441751432797]],
    [ "Enedis",[2.1709515896276197, 50.72467261872457]],
    [ "Enedis",[2.1696601745602173, 50.725951507442495]],
    [ "Enedis",[2.14039565060241, 50.73415594779116]],
    [ "Sous-station SNCF de Saint-Nazaire",[4.631326879794095, 44.19042020470371]],
    [ "Enedis",[4.541728407849855, 44.15896188614242]],
    [ "Sous-station SNCF de Saint-Géniès",[4.737170863073003, 44.06315282615777]],
    [ "Enedis",[-1.1566527063614676, 46.55282343262334]],
    [ "Enedis",[-1.1602210000000015, 46.5535555]],
    [ "Enedis",[-1.1665972503799507, 46.55956737023918]],
    [ "Enedis",[2.1186123693270735, 50.697909139280114]],
    [ "Enedis",[2.1273761362530417, 50.709150338199514]],
    [ "Enedis",[2.1150519710144926, 50.708748695652176]],
    [ "Enedis",[2.1183637238095243, 50.707965447619046]],
    [ "Enedis",[2.1208769721485417, 50.71001936427941]],
    [ "Enedis",[2.1221881533556823, 50.70473681574969]],
    [ "Enedis",[2.1194471307380374, 50.70291858475264]],
    [ "Enedis",[2.18182116954987, 50.70746227424488]],
    [ "Enedis",[2.1811548603174598, 50.70426578888889]],
    [ "Enedis",[-1.604623384185647, 49.60958277897149]],
    [ "Enedis",[-1.6036881665784366, 49.61425550308805]],
    [ "Enedis",[-1.6013247296675193, 49.620259734867865]],
    [ "Enedis",[-1.5961037257383968, 49.604627637130804]],
    [ "Enedis",[-1.5867911674470458, 49.623527496544035]],
    [ "Enedis",[-1.6054537798353914, 49.618130656378604]],
    [ "Enedis",[-1.607291365841333, 49.60532016439278]],
    [ "Enedis",[-1.6047312200647252, 49.61628917799352]],
    [ "Enedis",[-1.60367350640322, 49.60311069045006]],
    [ "Enedis",[-1.60436010768572, 49.610735300149855]],
    [ "Enedis",[2.0837126854970127, 50.69996603331523]],
    [ "Enedis",[1.5711587262659015, 50.35094933052716]],
    [ "Enedis",[1.5644667792886164, 50.331289547287355]],
    [ "Enedis",[1.5767849999999988, 50.345692299999996]],
    [ "Enedis",[1.5693244816795153, 50.34496043606242]],
    [ "Enedis",[1.5680799975635216, 50.33955057583861]],
    [ "Enedis",[1.5652820500000002, 50.3436881]],
    [ "Enedis",[1.576830842292484, 50.332913323470066]],
    [ "Enedis",[1.5760318278198076, 50.34301435868868]],
    [ "Enedis",[1.5580577923682912, 50.337924526531616]],
    [ "Enedis",[1.5752269633424747, 50.34696298080162]],
    [ "Enedis",[1.55147405, 50.34199175]],
    [ "Enedis",[1.5596537, 50.33987320000001]],
    [ "Enedis",[1.5509921267511046, 50.34145397650226]],
    [ "Enedis",[1.5653145559188775, 50.34140931823219]],
    [ "Enedis",[1.5708179792222947, 50.34653099485551]],
    [ "Enedis",[1.557974256263829, 50.341280490468364]],
    [ "Enedis",[1.5743922696729409, 50.34070596783391]],
    [ "Enedis",[1.5579569896792467, 50.33983466720425]],
    [ "Enedis",[1.5534600440366961, 50.338325622516486]],
    [ "Enedis",[1.5509058244813663, 50.34064391400652]],
    [ "Enedis",[1.564918736355932, 50.339446355202284]],
    [ "RTE",[6.020385020052272, 43.140984095680096]],
    [ "Enedis",[0.0735415, 47.386356]],
    [ "Enedis",[0.0714315092392607, 47.389101190064785]],
    [ "Enedis",[0.049885676341248575, 47.390682466138955]],
    [ "239657124",[-0.5296965, 47.140826]],
    [ "239657148",[-0.5425180000000012, 47.144828000000004]],
    [ "239657193",[-0.5393755, 47.146794]],
    [ "Enedis",[-0.5494620596491226, 47.14311584561404]],
    [ "Enedis",[-0.5475522748242997, 47.12792071602639]],
    [ "Enedis",[-0.5498306796399259, 47.12488452501986]],
    [ "Enedis",[-0.5416575, 47.14602]],
    [ "Enedis",[-0.5358109999999999, 47.142177000000004]],
    [ "239657741",[-0.5324762665829679, 47.14599831889516]],
    [ "239657851",[-0.5244193358746293, 47.14502902371877]],
    [ "Enedis",[-0.5352828405797102, 47.139852420289856]],
    [ "239658080",[-0.5228166880983002, 47.14669383797685]],
    [ "Enedis",[-0.541956, 47.15998049999999]],
    [ "239658122",[-0.5275873952183912, 47.14705876413793]],
    [ "Enedis",[-0.5372049529360444, 47.13937181944096]],
    [ "239658274",[-0.5224200111265648, 47.14863657719054]],
    [ "Enedis",[-0.5189814304741611, 47.147914754217716]],
    [ "Enedis",[-0.5462299583333333, 47.12528083333333]],
    [ "Enedis",[-0.5271563484276729, 47.14284504276729]],
    [ "Enedis",[-0.5384432507093702, 47.14353431872455]],
    [ "Enedis",[-0.5424882013888889, 47.140831069444445]],
    [ "239658896",[-0.5359991466666665, 47.13733873575758]],
    [ "Enedis",[-0.5510348438885933, 47.12724747702678]],
    [ "Enedis",[-0.5475178741616676, 47.126400244112105]],
    [ "Enedis",[-0.5404308244803175, 47.148777682405196]],
    [ "Enedis",[-0.5405375, 47.142129999999995]],
    [ "239659724",[-0.5358156998082725, 47.149178537660916]],
    [ "Enedis",[-0.5325930230590978, 47.143664007890436]],
    [ "239659847",[-0.5398393054121239, 47.13696831918149]],
    [ "239659955",[-0.5392313842364534, 47.139860178981934]],
    [ "Enedis",[-0.52443, 47.14204350000001]],
    [ "239659977",[-0.5288546666666671, 47.14920166666668]],
    [ "Enedis",[-0.5499760087014725, 47.12286733634537]],
    [ "239660238",[-0.5189662254400913, 47.14746010221465]],
    [ "Enedis",[-0.5283374085470082, 47.14489158290598]],
    [ "239660587",[-0.5362023441191575, 47.14492943348742]],
    [ "Enedis",[-0.4269905944083486, 47.199557582201216]],
    [ "Enedis",[-0.4291031355759427, 47.20228674413864]],
    [ "Enedis",[2.641872637302355, 50.2605651881252]],
    [ "Enedis",[2.643108000000001, 50.25743799999999]],
    [ "Enedis",[2.6398391598646502, 50.25609160548305]],
    [ "Enedis",[2.644691645771144, 50.261146931343276]],
    [ "Enedis",[2.1775983713702565, 50.65442673207162]],
    [ "Enedis",[2.1889621873162546, 50.655278724065525]],
    [ "Enedis",[0.35441983432343227, 49.49337703762376]],
    [ "Enedis",[0.32626433333333327, 49.473042]],
    [ "Enedis",[0.3295957897513177, 49.4783817756845]],
    [ "Enedis",[0.31845450000000003, 49.4744385]],
    [ "Enedis",[0.32856381677149027, 49.47803527756813]],
    [ "Enedis",[0.31985796819085643, 49.47933119151756]],
    [ "Enedis",[-1.4624428365846707, 48.75143369681004]],
    [ "Enedis",[-1.4547275641446158, 48.75488584860068]],
    [ "Enedis",[-1.459037004585493, 48.75384900238485]],
    [ "Enedis",[-1.4547890976641162, 48.75099117492789]],
    [ "Enedis",[-1.1811120499999994, 46.50552724999999]],
    [ "Enedis",[-1.1817262693803274, 46.51082622279243]],
    [ "Enedis",[-1.1814717000000001, 46.51433295]],
    [ "Enedis",[-1.15002415, 46.49254505000001]],
    [ "Enedis",[-1.18484175, 46.50320765000001]],
    [ "Cathedrale",[6.125388234103554, 45.89960869517826]],
    [ "Enedis",[2.131955249195016, 50.64715934313315]],
    [ "Enedis",[2.0523953120890237, 50.64671296509862]],
    [ "Enedis",[3.332806912820513, 49.65802345128205]],
    [ "Enedis",[3.3321317645967583, 49.660433872974025]],
    [ "Enedis",[3.333092205585726, 49.65162313679855]],
    [ "Enedis",[3.3363561911972544, 49.66150896002422]],
    [ "Enedis",[3.3485374859967054, 49.66274466996156]],
    [ "Enedis",[3.348178, 49.652663999999994]],
    [ "Enedis",[3.345733534772182, 49.644518946043156]],
    [ "Enedis",[3.3429317776155716, 49.66380346131387]],
    [ "Enedis",[2.4134355530864195, 47.91780532592593]],
    [ "Enedis",[2.1871229683351245, 50.63770829243732]],
    [ "RTE",[6.027927865724804, 43.200250066607154]],
    [ "Enedis",[2.0467336033705195, 50.81139721140913]],
    [ "Enedis",[2.049853084490741, 50.804924802083335]],
    [ "Enedis",[2.0455324660194187, 50.803079537864086]],
    [ "Enedis",[2.2103593630461105, 50.70869082194344]],
    [ "Enedis",[2.2129156666666656, 50.70680966666666]],
    [ "Enedis",[0.41348050000000003, 47.69811274999999]],
    [ "Enedis",[0.41942834150076613, 47.69832010923941]],
    [ "Enedis",[0.4088869196064489, 47.697924673702865]],
    [ "Rotondes",[4.8233045500000005, 46.79169265]],
    [ "Enedis",[2.8637115059288534, 47.86568732293954]],
    [ "Chapier",[4.825004280324507, 46.79311290160126]],
    [ "Verbuisson",[4.8253626999999994, 46.7930612]],
    [ "Enedis",[4.809324622563853, 46.79280574555959]],
    [ "Enedis",[2.9256095866666665, 47.85217162222223]],
    [ "Enedis",[0.41584049999999995, 47.69296925000001]],
    [ "Enedis",[0.41221500000000066, 47.684166000000005]],
    [ "Enedis",[5.6188132500000005, 46.75128575]],
    [ "Mezières",[5.615434299999999, 46.75735579999999]],
    [ "Enedis",[5.614042593394924, 46.75426561653095]],
    [ "Enedis",[2.926669714210803, 47.79457055707044]],
    [ "Enedis",[2.0971694521777193, 50.598977393630996]],
    [ "Enedis",[2.0919582580287934, 50.59958394352159]],
    [ "Enedis",[2.096904414794007, 50.601430314138575]],
    [ "Enedis",[-1.0788686504670886, 46.47458467606715]],
    [ "Enedis",[-1.0583882500000001, 46.47477799999999]],
    [ "Enedis",[-1.0174805499999997, 46.46859994999999]],
    [ "Enedis",[-1.0364554999999993, 46.4718275]],
    [ "Enedis",[-1.0679338499999989, 46.475285299999996]],
    [ "Enedis",[-1.04655242026284, 46.4720988791987]],
    [ "Enedis",[-1.0233056135802465, 46.47266918271606]],
    [ "Enedis",[-1.027860428997635, 46.47021199448184]],
    [ "Enedis",[-1.0228404138491713, 46.448935618434035]],
    [ "Enedis",[-1.030235, 46.43650825]],
    [ "Enedis",[-1.02498275, 46.473443]],
    [ "Enedis",[2.1814068462301583, 50.602577966269834]],
    [ "Enedis",[2.845886, 47.82526950000001]],
    [ "Enedis",[2.8491318835046493, 47.8121463627019]],
    [ "Enedis",[2.854108781531531, 47.81783994594595]],
    [ "RTE",[0.17139459111333083, 43.02824668038405]],
    [ "Magellan",[5.214313100000001, 45.622194400000005]],
    [ "Aubin",[2.2571870735272377, 47.104775348081866]],
    [ "Enedis",[0.4239902499999997, 47.697013]],
    [ "Astrolabe",[5.212767100000001, 45.624909200000005]],
    [ "Enedis",[-1.0386781499999997, 46.55602204999999]],
    [ "Enedis",[-1.0270328598991634, 46.55588357674038]],
    [ "Enedis",[-1.0317640000000001, 46.57746925]],
    [ "Enedis",[-0.9845495, 46.624072250000005]],
    [ "Enedis",[-1.0149244, 46.57796784999999]],
    [ "Les Contamines",[5.228441003586799, 45.62308148081084]],
    [ "Badolas",[5.247930513193322, 45.61851766182014]],
    [ "Enedis",[0.4235934, 47.68606470000001]],
    [ "Enedis",[0.4206012863010289, 47.69127722627043]],
    [ "Enedis",[0.4160375000000011, 47.6859595]],
    [ "Camille Veyron",[5.243529000000001, 45.61671174999999]],
    [ "Chapelle Ste Anne",[5.237570837323037, 45.62065549343628]],
    [ "Enedis",[-1.661531925325286, 48.17752300207339]],
    [ "Poste électrique de Landivisiau",[-4.051669292646916, 48.51329559455204]],
    [ "Enedis",[1.1408, 49.86411699999999]],
    [ "Enedis",[1.6120098507412253, 50.22918833821541]],
    [ "Enedis",[1.6172087514176159, 50.22924343203528]],
    [ "Enedis",[1.6163466385074865, 50.22800127024624]],
    [ "Enedis",[1.6133183073496975, 50.22612806324188]],
    [ "Enedis",[1.6176831815244015, 50.219778474176344]],
    [ "Enedis",[1.628897635819543, 50.21810484893549]],
    [ "Enedis",[1.6215630010205098, 50.22177396843738]],
    [ "Enedis",[1.6230332069586322, 50.214951866896314]],
    [ "Enedis",[1.6180704599083067, 50.221700440221134]],
    [ "Enedis",[1.6259514296378583, 50.215021964913916]],
    [ "Enedis",[5.80208185, 49.5187518]],
    [ "Le Mollard",[5.2233228, 45.624614949999994]],
    [ "Fonbonnière 1",[5.219097499999999, 45.626628999999994]],
    [ "Le Moriaud",[5.221684500000001, 45.626907499999994]],
    [ "Les Boisselières",[5.220520229511097, 45.61789616021766]],
    [ "Les Pruneliers",[5.2174359966760315, 45.61909588876013]],
    [ "Enedis",[2.1260174655436446, 50.68993864726901]],
    [ "Lombard",[5.216475806842904, 45.61000195133554]],
    [ "Enedis",[2.982877099024319, 50.476379187709334]],
    [ "Enedis",[3.0003511731574966, 50.48206448725873]],
    [ "Enedis",[3.0009495, 50.474894500000005]],
    [ "Enedis",[3.323138000000001, 45.39565150000001]],
    [ "Enedis",[3.3280066666666666, 45.3917315390071]],
    [ "Enedis",[3.324330962724014, 45.3939773390681]],
    [ "Poste électrique de Saint-Fiacre",[-3.8214973594129913, 48.56070001468708]],
    [ "Enedis",[-1.1232631153897064, 46.48581265636137]],
    [ "Enedis",[-1.1112254310858065, 46.46546039545463]],
    [ "Enedis",[-1.1124342500000002, 46.479559249999994]],
    [ "Enedis",[-1.1105806, 46.46388289999999]],
    [ "Enedis",[-1.117699968397657, 46.47598940394821]],
    [ "Enedis",[-1.1174263, 46.48291245]],
    [ "Enedis",[-1.1169252500000002, 46.477227500000005]],
    [ "Enedis",[-1.0820337499999997, 46.465569499999994]],
    [ "Enedis",[3.013036579896119, 50.47816161533762]],
    [ "Enedis",[3.015720326192366, 50.47471451864586]],
    [ "Enedis",[3.013257828282827, 50.47614733333333]],
    [ "Enedis",[3.0101803187000167, 50.47355392070785]],
    [ "Enedis",[3.0099137659033075, 50.47684549363868]],
    [ "Enedis",[3.01593342995169, 50.47785041514726]],
    [ "Enedis",[3.0146584285714275, 50.483567038961034]],
    [ "Enedis",[3.0099804802492893, 50.47986905599853]],
    [ "Enedis",[3.017787175953079, 50.483782022482885]],
    [ "Enedis",[3.0173496867360803, 50.48500291685529]],
    [ "Enedis",[3.0127256509864746, 50.486688471439656]],
    [ "PR 3 Vallons",[5.215355582338039, 45.60498321100254]],
    [ "Résidence du Golf",[5.2180975, 45.603395500000005]],
    [ "Enedis",[5.787142743502824, 49.54062705762713]],
    [ "Enedis",[3.005574834991708, 50.48841799115532]],
    [ "Enedis",[3.0022349999999998, 50.49492000000001]],
    [ "Enedis",[3.0013303493975902, 50.48933919277109]],
    [ "Enedis",[3.0065571665861905, 50.49050550297763]],
    [ "Enedis",[3.005402266431407, 50.48481065652109]],
    [ "Enedis",[2.995791666666667, 50.49130517874396]],
    [ "Enedis",[3.002145990673575, 50.49113883454231]],
    [ "Enedis",[3.0099965, 50.489515]],
    [ "Enedis",[2.9973375114674954, 50.48663465752716]],
    [ "Enedis",[2.997920776832403, 50.491994593725074]],
    [ "Enedis",[2.9752444432432434, 50.45828766306307]],
    [ "Enedis",[5.2212985, 45.6009105]],
    [ "Enedis",[3.003544, 50.45769416666666]],
    [ "Enedis",[2.99671, 50.4560995]],
    [ "Enedis",[2.999959343514476, 50.456107396121]],
    [ "Les Coteaux du Golf",[5.215613509283528, 45.600671502124044]],
    [ "Enedis",[3.0104556202145454, 50.466895702686735]],
    [ "Enedis",[3.014348820590208, 50.47197797719651]],
    [ "Enedis",[3.0067308125412544, 50.46603196633662]],
    [ "Enedis",[3.005589808456988, 50.468346460382115]],
    [ "Enedis",[3.0093551264197536, 50.46974339135802]],
    [ "Enedis",[3.01388432231884, 50.46871825217391]],
    [ "Enedis",[3.013868664253265, 50.46577164097104]],
    [ "Enedis",[5.775008832962138, 49.52663400519674]],
    [ "Enedis",[5.7767944, 49.52896945]],
    [ "Enedis",[2.988896119047619, 50.471522190476186]],
    [ "Enedis",[3.000619827345961, 50.471606163727415]],
    [ "Enedis",[2.9908313333333343, 50.46588951428571]],
    [ "Enedis",[2.994923759656651, 50.46872880185981]],
    [ "Enedis",[2.995719072860598, 50.46080269896249]],
    [ "Enedis",[2.9849411204819276, 50.47039463453815]],
    [ "Enedis",[2.992815149755438, 50.463449563742316]],
    [ "Enedis",[2.9806063361206756, 50.47081055435317]],
    [ "Enedis",[2.9933626646306974, 50.470378196810316]],
    [ "Enedis",[3.0003725, 50.4666305]],
    [ "Enedis",[2.9837825, 50.47348399999999]],
    [ "Enedis",[2.9893308230266458, 50.46939863901458]],
    [ "Enedis",[2.99794213923014, 50.461373374283376]],
    [ "Enedis",[2.9966045, 50.470479499999996]],
    [ "Enedis",[2.9980856440189414, 50.464709233683344]],
    [ "Enedis",[2.985205161061946, 50.47170760176991]],
    [ "Enedis",[2.9897113098591546, 50.45787784138163]],
    [ "Enedis",[2.990282444038928, 50.46794728041362]],
    [ "Enedis",[2.995759241028487, 50.46399270662228]],
    [ "Enedis",[2.997484205753551, 50.47158237039753]],
    [ "Enedis",[3.000782158326818, 50.4682105835288]],
    [ "Enedis",[-1.5238092506694134, 48.82315113590801]],
    [ "Enedis",[-1.4991956316365576, 48.82062482245264]],
    [ "Enedis",[-1.529517842268064, 48.83735277484335]],
    [ "Enedis",[2.918147500000001, 50.439553499999995]],
    [ "Enedis",[2.920999290394183, 50.44542061905856]],
    [ "Enedis",[2.947428499999999, 50.4431825]],
    [ "Enedis",[2.9423544747953048, 50.44490383229752]],
    [ "Enedis",[2.943091152912787, 50.4404573399622]],
    [ "Enedis",[2.946203856626736, 50.442399676934784]],
    [ "Enedis",[2.9396502659885098, 50.44647444004898]],
    [ "Enedis",[2.939223903076568, 50.452284865602586]],
    [ "Enedis",[2.9372152235410485, 50.45170570227498]],
    [ "Enedis",[2.940655805725971, 50.449633447852754]],
    [ "Enedis",[2.937650827505828, 50.44981998212898]],
    [ "Enedis",[2.939235732223378, 50.45232244849115]],
    [ "Enedis",[2.9426627105398993, 50.45338855032518]],
    [ "Enedis",[2.9427754422423558, 50.45496106021141]],
    [ "Enedis",[2.9405745063338298, 50.45561432228018]],
    [ "Enedis",[2.952262640194489, 50.45221271420854]],
    [ "Enedis",[2.9503313322295805, 50.450612991169976]],
    [ "Enedis",[2.9503716869442362, 50.453169730158734]],
    [ "Enedis",[2.9511614786953753, 50.45750218724448]],
    [ "Enedis",[4.8099455, 46.77656245000001]],
    [ "Enedis",[2.942802328901601, 50.45967911210493]],
    [ "Enedis",[2.9479535632563243, 50.45765015882838]],
    [ "Poste électrique de Jane",[5.026022672359968, 43.78610503945163]],
    [ "Poste électrique les Aubes",[5.0814635298150765, 43.671975887265425]],
    [ "RTE",[5.487240907687066, 43.464997854594806]],
    [ "Enedis",[2.9090246293460917, 50.41103245390749]],
    [ "Enedis",[2.902880312898264, 50.41363631311798]],
    [ "Enedis",[2.9079898442542573, 50.41267568164394]],
    [ "Enedis",[2.8995715000000004, 50.412757]],
    [ "Enedis",[2.9076562912598214, 50.41248422298959]],
    [ "Enedis",[2.8940052492211836, 50.4127125482866]],
    [ "Enedis",[2.9122864840182645, 50.41515972054794]],
    [ "Enedis",[2.9161518363069137, 50.41400233206591]],
    [ "Enedis",[2.912701023581899, 50.413918558317405]],
    [ "Enedis",[2.897496251399491, 50.41826525597965]],
    [ "Enedis",[2.9094487045614037, 50.41709935368422]],
    [ "Enedis",[2.8822629771120836, 50.42878037125548]],
    [ "Enedis",[2.8890903585095664, 50.42517101711984]],
    [ "Enedis",[2.879415692839051, 50.42631924123111]],
    [ "Enedis",[2.8752577910330066, 50.428810718598626]],
    [ "Enedis",[2.8622144930615363, 50.43208765613935]],
    [ "Enedis",[2.8729098981818186, 50.426836710303036]],
    [ "Enedis",[2.8755042440944885, 50.43092895538057]],
    [ "Enedis",[2.8769775221297853, 50.43118350615641]],
    [ "SNCF Réseau",[4.496366153804801, 46.79783322801303]],
    [ "Enedis",[3.312158, 45.3844625]],
    [ "Enedis",[3.304707604023776, 45.388329067215366]],
    [ "Enedis",[2.840029488486337, 50.42721268805649]],
    [ "Enedis",[2.852060877192982, 50.42622137192982]],
    [ "Enedis",[2.841628356337704, 50.4249711757534]],
    [ "Enedis",[0.10189650000000075, 48.504411]],
    [ "Enedis",[2.875987385604113, 50.43978870265639]],
    [ "Enedis",[2.8663711117366093, 50.443074923575566]],
    [ "Enedis",[2.8631533766978565, 50.43642672723615]],
    [ "Enedis",[2.8615616492089475, 50.44245967375887]],
    [ "Enedis",[2.8736165708410444, 50.43734121488792]],
    [ "Enedis",[2.875822188660311, 50.4377608653621]],
    [ "Enedis",[2.864090048121414, 50.438810469739046]],
    [ "Enedis",[2.864452746555158, 50.44154578000797]],
    [ "Enedis",[2.8536144427667196, 50.439760679707156]],
    [ "Enedis",[2.856885592859786, 50.440250251757305]],
    [ "Enedis",[2.8557901621621626, 50.44232164864866]],
    [ "Enedis",[2.8580957981438506, 50.43903341453982]],
    [ "Enedis",[2.8596228343600276, 50.44552671594798]],
    [ "Enedis",[2.8540810724637677, 50.43733732065218]],
    [ "Enedis",[2.843099243243244, 50.44767230546827]],
    [ "Enedis",[2.8530814237652113, 50.45182757408734]],
    [ "Enedis",[2.8480452489451475, 50.44565114767932]],
    [ "Enedis",[2.8464435209580836, 50.44898297205589]],
    [ "Enedis",[-0.15835667375886558, 47.45202892765957]],
    [ "Enedis",[-0.22671068261562924, 47.43615402392344]],
    [ "Enedis",[-0.2461085, 47.44734100000001]],
    [ "Enedis",[-0.22419195838433217, 47.44289915871073]],
    [ "Enedis",[-0.21098583650022124, 47.4362034981588]],
    [ "Enedis",[-0.2240411549501334, 47.43494853790643]],
    [ "Enedis",[-0.22856800000000005, 47.440372]],
    [ "Enedis",[-0.19643617465224217, 47.43891049149922]],
    [ "Enedis",[-0.21106818279569892, 47.44338487096775]],
    [ "Enedis",[-0.21095800000000092, 47.44109850000001]],
    [ "Enedis",[-0.21132497007481305, 47.44625532003325]],
    [ "Enedis",[-0.21817223539518898, 47.43637394196258]],
    [ "Enedis",[-0.22356453925670702, 47.440970243990485]],
    [ "Enedis",[-0.19618466666666667, 47.43860175555555]],
    [ "Enedis",[-0.20709142857142826, 47.436687847619055]],
    [ "Enedis",[-0.16466867155514828, 47.4589012355637]],
    [ "Enedis",[-0.22131171305841923, 47.44257391752576]],
    [ "Enedis",[-0.2068527592319061, 47.438925341703595]],
    [ "Enedis",[-0.20837541892312478, 47.43597500653595]],
    [ "Enedis",[0.3754709637229707, 47.295491013751864]],
    [ "Poste électrique du Plan d'Orgon",[5.0179416773654415, 43.82718753464501]],
    [ "Enedis",[2.875275418909593, 50.45328173706004]],
    [ "Enedis",[2.8713082032011132, 50.46020404592902]],
    [ "Enedis",[2.8742429999999994, 50.457432000000004]],
    [ "Enedis",[2.8857499999999994, 50.466421]],
    [ "Enedis",[2.8801914999999996, 50.4636965]],
    [ "Enedis",[2.8753371983471063, 50.46341354435262]],
    [ "Enedis",[2.8711834402130183, 50.46238533264283]],
    [ "Enedis",[2.8791478472222196, 50.46491366666667]],
    [ "Enedis",[2.8767982500000002, 50.468253250000004]],
    [ "Enedis",[2.888232674641148, 50.4781436608187]],
    [ "Enedis",[2.885940518398851, 50.473073346975404]],
    [ "Enedis",[2.891587788866456, 50.47430045072589]],
    [ "Enedis",[2.746564000000001, 50.4663895]],
    [ "Enedis",[2.756380168551427, 50.466873487883674]],
    [ "Enedis",[2.7493561682184975, 50.47097048913717]],
    [ "Enedis",[2.760295135964912, 50.46431262719299]],
    [ "Enedis",[2.7433711231015185, 50.46815776738608]],
    [ "Enedis",[2.753645085271318, 50.468750294573645]],
    [ "Enedis",[2.720839913978495, 50.46107282795699]],
    [ "Enedis",[2.721102, 50.464246]],
    [ "Enedis",[2.7194865951611322, 50.466847307767644]],
    [ "Enedis",[2.723766130600764, 50.460392244524805]],
    [ "Rocher du cerf",[2.7531822000000004, 45.0831143]],
    [ "Enedis",[2.7132400000000008, 50.4640115]],
    [ "Enedis",[5.6354724586672, 46.555831069229896]],
    [ "Enedis",[2.70714584679089, 50.470738540372665]],
    [ "Enedis",[2.7180453863552088, 50.47135570559881]],
    [ "Enedis",[2.712032948320414, 50.474392326873385]],
    [ "Enedis",[2.7173600213949505, 50.474649667522456]],
    [ "Enedis",[2.7210458190394515, 50.47164802973127]],
    [ "Enedis",[2.7134211038540754, 50.471817798274]],
    [ "Enedis",[2.7085915192452834, 50.46787244452829]],
    [ "Enedis",[2.7242563121693126, 50.47790956084656]],
    [ "Enedis",[2.716822583434671, 50.47658415768141]],
    [ "Enedis",[2.7234640068314246, 50.476558519087796]],
    [ "EDF",[2.6043779992743286, 44.60546822550986]],
    [ "Enedis",[2.827921976138829, 50.48877483369487]],
    [ "Enedis",[2.8223326681350955, 50.48687196475772]],
    [ "Enedis",[2.8195982499999994, 50.48672324999999]],
    [ "Enedis",[-0.9741861999999986, 46.534928050000005]],
    [ "Enedis",[-0.9375917499999994, 46.52394725]],
    [ "Enedis",[1.8066774262320051, 47.066998823621645]],
    [ "Enedis",[1.8192960128005677, 47.07963737124254]],
    [ "Enedis",[1.8182739163548478, 47.06977324954844]],
    [ "Enedis",[0.13820649999999998, 48.5207415]],
    [ "Enedis",[2.6784001656948493, 50.44729702915451]],
    [ "Enedis",[2.6733339107755665, 50.44385411164494]],
    [ "Enedis",[2.6703813710514224, 50.44352437596419]],
    [ "Enedis",[2.6810242527401673, 50.442530855208624]],
    [ "Enedis",[2.6859079972130733, 50.44809350620724]],
    [ "Enedis",[2.6813985, 50.450108]],
    [ "Enedis",[2.6940023541333327, 50.446480237866666]],
    [ "Enedis",[2.691905013503908, 50.44919082480455]],
    [ "Enedis",[2.6938365633333325, 50.45579697111112]],
    [ "Enedis",[2.7636428012868106, 50.40676064563226]],
    [ "Enedis",[2.7557704999999997, 50.40370256060606]],
    [ "Enedis",[2.7703763501459426, 50.40311434121424]],
    [ "Enedis",[2.7689095000000004, 50.405733]],
    [ "Enedis",[2.7637575499474245, 50.40520177707677]],
    [ "Enedis",[2.7594127168862053, 50.40454865678742]],
    [ "Enedis",[2.751619699346405, 50.41174266666666]],
    [ "Enedis",[2.754396019169329, 50.41280995207667]],
    [ "Enedis",[2.696577432922027, 50.44224368320644]],
    [ "Enedis",[2.7127825260770977, 50.42353938775512]],
    [ "Enedis",[2.703961916310033, 50.42230731502616]],
    [ "Enedis",[2.710664268810618, 50.42837934813681]],
    [ "Enedis",[2.7250685949579827, 50.41499623921568]],
    [ "Enedis",[2.699890601797248, 50.42630782111766]],
    [ "Enedis",[2.7103630535254766, 50.425471669411564]],
    [ "Enedis",[2.7163225, 50.420500000000004]],
    [ "Enedis",[2.715726046472328, 50.43235018462189]],
    [ "Enedis",[-0.2616356730729489, 48.3818352787766]],
    [ "Enedis",[-0.90060925, 46.5163555]],
    [ "Enedis",[-0.901167, 46.5209345]],
    [ "Enedis",[-0.8960717999999999, 46.5225276]],
    [ "Enedis",[-0.9016507099025047, 46.51401193146447]],
    [ "Enedis",[2.38136225, 49.20434685]],
    [ "Enedis",[2.375080027416437, 49.21129091467771]],
    [ "Enedis",[2.3756757682555536, 49.20978251804669]],
    [ "Enedis",[2.681406879441624, 50.43838364445854]],
    [ "Enedis",[2.6692574008230454, 50.43357177860083]],
    [ "Enedis",[2.6662766666666666, 50.435026835164834]],
    [ "Enedis",[2.6724416150696153, 50.43254088861588]],
    [ "Enedis",[2.674892837169651, 50.42820701364024]],
    [ "Enedis",[2.6762677618371216, 50.435803161268936]],
    [ "Enedis",[2.68722615892673, 50.41429170278638]],
    [ "Enedis",[2.6822211661600814, 50.41948847720364]],
    [ "Enedis",[2.6651433657038512, 50.42238343899402]],
    [ "Enedis",[2.633606884501581, 50.408789200897864]],
    [ "Enedis",[2.6380536801152736, 50.400384926032665]],
    [ "Enedis",[2.642429633539155, 50.4096318292659]],
    [ "Enedis",[2.6388555, 50.409119000000004]],
    [ "Enedis",[2.639109, 50.40177433333334]],
    [ "Enedis",[2.632480166666667, 50.406248999999995]],
    [ "Enedis",[2.9104958472813243, 50.49571973900709]],
    [ "Enedis",[2.897640000000001, 50.492650999999995]],
    [ "Enedis",[2.8979461408308005, 50.4966635845998]],
    [ "Enedis",[2.898266681731032, 50.494854540673785]],
    [ "Enedis",[2.893615911581067, 50.50029075085599]],
    [ "Enedis",[2.8918364999999997, 50.497886]],
    [ "Enedis",[2.8829082799076597, 50.49515745436559]],
    [ "Enedis",[2.889761891674404, 50.49964729433613]],
    [ "Enedis",[2.8933811763826607, 50.501204473841554]],
    [ "Enedis",[-0.1448795, 48.37163400000001]],
    [ "Enedis",[-0.14635278354978354, 48.37068794805195]],
    [ "Enedis",[1.6594095000000006, 50.347407000000004]],
    [ "Enedis",[2.788120473851031, 50.656509963549915]],
    [ "Enedis",[2.7927276984559493, 50.64898593006358]],
    [ "Enedis",[2.7913900987245146, 50.65619474902079]],
    [ "Enedis",[-0.27649057610211175, 48.34130365478042]],
    [ "Enedis",[-0.286680007092199, 48.35340730070923]],
    [ "Enedis",[-0.2778815, 48.342023499999996]],
    [ "Enedis",[-0.29035750000000177, 48.3448905]],
    [ "Enedis",[-0.28759580925076456, 48.33721103899082]],
    [ "Enedis",[-0.27009016327519364, 48.346098026162785]],
    [ "Enedis",[-0.2785575759637186, 48.34591818140589]],
    [ "Enedis",[-0.2858801206543971, 48.345980799591004]],
    [ "Enedis",[-0.28461520743919944, 48.34687989062296]],
    [ "SNCF Réseau",[6.004141199999999, 47.33438765]],
    [ "Enedis",[2.760988660997066, 50.652041330596276]],
    [ "Enedis",[2.7673184659197014, 50.657385337068156]],
    [ "Enedis",[2.792450108242304, 50.659905741145316]],
    [ "Enedis",[2.772079009021513, 50.661234111034]],
    [ "Enedis",[2.8021611960236097, 50.66236185958373]],
    [ "Enedis",[2.7887580966542758, 50.661077696406444]],
    [ "SNCF Réseau",[3.2500973422882096, 44.86609188226204]],
    [ "Enedis",[2.7048531797020483, 50.60386604096835]],
    [ "Enedis",[2.6847082195121947, 50.617941048780494]],
    [ "Enedis",[2.6704780082732844, 50.620611162796905]],
    [ "Enedis",[2.682452970853205, 50.61585785426603]],
    [ "Enedis",[5.634929195228993, 49.516232355701845]],
    [ "Enedis",[5.649416600000003, 49.5098232]],
    [ "Enedis",[2.889393972972973, 42.60361234234234]],
    [ "Enedis",[2.899626787251754, 42.6045215579736]],
    [ "Enedis",[2.906988860906453, 42.606286266354104]],
    [ "Enedis",[2.8983892020028605, 42.60267344978541]],
    [ "Enedis",[2.902828823069404, 42.604411169110456]],
    [ "Enedis",[2.8859934684684685, 42.60382534234235]],
    [ "Enedis",[2.897768351585015, 42.60682881460135]],
    [ "Enedis",[2.903601844585497, 42.60189046797461]],
    [ "Enedis",[2.8664950510655403, 42.60883559268194]],
    [ "Enedis",[2.901172249439211, 42.606105068640645]],
    [ "Enedis",[2.8933419529612996, 42.60444636027368]],
    [ "Enedis",[2.8045464999999994, 50.502810249999996]],
    [ "Enedis",[2.8077162500000004, 50.50294825]],
    [ "Enedis",[2.800670573955474, 50.50460993290638]],
    [ "Enedis",[2.804147008340284, 50.50388604170142]],
    [ "Enedis",[2.8048492500000006, 50.51161375]],
    [ "Enedis",[2.8046186576105887, 50.50572655546848]],
    [ "Enedis",[2.801389593076737, 50.508861414911635]],
    [ "Enedis",[2.7987951022158684, 50.50881511865618]],
    [ "Enedis",[2.796676852556287, 50.507040646996664]],
    [ "Enedis",[6.231174375501388, 45.52757599752887]],
    [ "Enedis",[2.6114160983072923, 50.622002271484384]],
    [ "Enedis",[2.6108292654754304, 50.61347215315889]],
    [ "Enedis",[2.596848233618234, 50.60825184520418]],
    [ "Enedis",[2.6085424995586934, 50.61410948808472]],
    [ "Enedis",[2.5414972888201044, 50.62056013936178]],
    [ "Enedis",[2.5340803244104526, 50.618443992989164]],
    [ "Enedis",[2.550576067460317, 50.62287073611111]],
    [ "Enedis",[2.52599095026716, 50.62004106165228]],
    [ "Enedis",[2.557782038240918, 50.62307914659019]],
    [ "Enedis",[3.6472930738007374, 47.48093290049199]],
    [ "Enedis",[-1.0868278698891436, 46.37529685314366]],
    [ "Enedis",[-1.085305329504375, 46.37345409482248]],
    [ "Enedis",[1.6687476484848478, 50.37203175151516]],
    [ "Enedis",[1.6718715000000015, 50.371605]],
    [ "Enedis",[1.6674905000000015, 50.376360000000005]],
    [ "Enedis",[1.6608566161306786, 50.37299179938744]],
    [ "Enedis",[1.6650854915254236, 50.359065118644075]],
    [ "Enedis",[1.6657743158924199, 50.372974436919314]],
    [ "Enedis",[1.6548578267279344, 50.37763466800689]],
    [ "Enedis",[1.648440271137552, 50.380308051379124]],
    [ "Enedis",[1.650547706306306, 50.38323518738739]],
    [ "Enedis",[0.3108434999999983, 48.26886650000001]],
    [ "Enedis",[-0.2854451019157085, 48.32515815862068]],
    [ "Enedis",[-0.34414771574642117, 48.338443453987715]],
    [ "Enedis",[-0.35039052470098814, 48.33867437441498]],
    [ "Enedis",[-0.2355120354090356, 48.430639191697196]],
    [ "Enedis",[-0.2476681968810917, 48.439113905133205]],
    [ "Enedis",[2.528197485731098, 50.275959388423075]],
    [ "Enedis",[2.5263635, 50.2722815]],
    [ "Enedis",[2.5240624477079874, 50.276995900677065]],
    [ "Enedis",[2.531290824561403, 50.27645498245614]],
    [ "Enedis",[2.5260186579992774, 50.27823234019501]],
    [ "Enedis",[2.5223368520027156, 50.2754666544467]],
    [ "Enedis",[2.542963, 50.275055]],
    [ "Enedis",[2.522368, 50.27279600000001]],
    [ "Enedis",[2.5364749029535862, 50.27920628270042]],
    [ "Enedis",[2.5283599148936178, 50.278028496453906]],
    [ "Enedis",[2.533904999999999, 50.277686]],
    [ "Enedis",[2.5203476537753224, 50.27920082136279]],
    [ "Enedis",[2.5255932492492486, 50.273369060060055]],
    [ "Enedis",[2.531777125088841, 50.279940815209656]],
    [ "RTE",[2.9995104498895744, 42.63347691535413]],
    [ "Enedis",[-0.8925879, 46.40433865]],
    [ "Enedis",[2.400802756947225, 49.216599754535636]],
    [ "Enedis",[1.7764912773386048, 50.36701083359684]],
    [ "Enedis",[0.458598, 47.881063000000005]],
    [ "Enedis",[7.080924332404372, 48.92288263779381]],
    [ "Enedis",[7.086192292815799, 48.922747546475996]],
    [ "Enedis",[2.4857761559279363, 50.60827185280466]],
    [ "Enedis",[2.5032422625368733, 50.61521394690265]],
    [ "Enedis",[2.488854700938522, 50.610502038279016]],
    [ "Enedis",[2.4939775102358124, 50.6125886661484]],
    [ "Enedis",[-1.055819141161031, 46.3758861693506]],
    [ "Enedis",[-0.21924749999999998, 48.460775500000004]],
    [ "Enedis",[-0.33267350000000007, 48.41372249999999]],
    [ "Enedis",[-0.337015, 48.4421715]],
    [ "Enedis",[2.2800931681016814, 49.94126864944649]],
    [ "Enedis",[2.3153026693548386, 49.949806989247314]],
    [ "Enedis",[2.3118152397003744, 49.94569691885143]],
    [ "Enedis",[2.312452793248945, 49.94806206329115]],
    [ "Enedis",[2.3123224116465853, 49.95585069277109]],
    [ "Enedis",[2.3159223404641516, 49.944379640217804]],
    [ "Enedis",[2.574852, 50.562792]],
    [ "Enedis",[2.5717274403116366, 50.56271167940018]],
    [ "Enedis",[2.5673469378531077, 50.557449977401134]],
    [ "Enedis",[2.422169300174521, 50.62236497033159]],
    [ "Enedis",[2.3942509105951424, 50.621307625033346]],
    [ "Enedis",[2.400230226532701, 50.61602956293609]],
    [ "Enedis",[6.525872, 48.5792835]],
    [ "Enedis",[6.5287070499999995, 48.573671]],
    [ "Enedis",[6.53269, 48.57187950000001]],
    [ "Enedis",[6.531861, 48.571023000000004]],
    [ "Enedis",[6.523035900161057, 48.57987254522649]],
    [ "Enedis",[0.3246024492753619, 48.25478036231883]],
    [ "Enedis",[0.318818038788407, 48.25614465555313]],
    [ "Parc brulé",[0.31089925000000007, 48.247865]],
    [ "centre de secours",[0.3184634590747331, 48.249250587188605]],
    [ "Enedis",[0.31158890987868315, 48.249147886770665]],
    [ "Poste source de Poteau-Rouge",[-3.312561662118627, 47.824426285089864]],
    [ "Enedis",[-0.9420787553013806, 46.38601638248095]],
    [ "Sous-station SNCF de Château-Malo",[-1.944434994355961, 48.6225457584703]],
    [ "Poste électrique de Saint-Servan",[-1.9458787835732618, 48.62104798477208]],
    [ "Enedis",[2.4062626787614905, 50.587167450411236]],
    [ "Enedis",[2.981153, 48.0887965]],
    [ "Enedis",[-0.8919862499999999, 46.35383650000001]],
    [ "Enedis",[-0.9222434, 46.37271915]],
    [ "Enedis",[-0.940877000000001, 46.442782750000006]],
    [ "Enedis",[-0.9428674154907681, 46.45518482967307]],
    [ "Enedis",[-0.9491403075359127, 46.43965058110521]],
    [ "Jean Moulin",[0.3115842790173541, 48.250771816542716]],
    [ "Romenay",[5.050841867248466, 46.50493834404603]],
    [ "Enedis",[1.8735253022831053, 50.366958656621]],
    [ "Enedis",[1.9998423689655174, 50.384900206075535]],
    [ "Les Barreaux",[4.601972199999999, 46.66855465]],
    [ "Enedis",[1.9985893557649168, 50.34005195289368]],
    [ "Enedis",[2.41072888758449, 50.57676580576307]],
    [ "Enedis",[0.3343250000000011, 47.909735999999995]],
    [ "Enedis",[5.7517475000000005, 47.15801750000001]],
    [ "Enedis",[5.7430607, 47.15893869999999]],
    [ "Enedis",[5.732301250000001, 47.17136575]],
    [ "Enedis",[5.758211937903895, 47.161395097151356]],
    [ "Enedis",[5.75270585, 47.1597211]],
    [ "Enedis",[5.74054675, 47.155782]],
    [ "Enedis",[2.5971126132404185, 50.54973784785135]],
    [ "Enedis",[2.6153167127927928, 50.542793799279266]],
    [ "Enedis",[2.6058465487618476, 50.54121356893916]],
    [ "Enedis",[2.5942329014951633, 50.540572694810905]],
    [ "Enedis",[-4.555211639267561, 48.37262109486251]],
    [ "SSR5 LIB",[-4.4864471627855025, 48.38940063745202]],
    [ "Enedis",[-4.553322847619495, 48.37694271497762]],
    [ "Enedis",[1.0911005395607256, 47.18292167849217]],
    [ "Enedis",[5.758744632723969, 47.152989998871846]],
    [ "Enedis",[5.761411358333334, 47.144215700000004]],
    [ "Enedis",[5.759827698866496, 47.15346974953825]],
    [ "Enedis",[5.76765795, 47.147300800000004]],
    [ "Enedis",[5.662763772463768, 47.14911798985507]],
    [ "Enedis",[5.660980253018697, 47.146437749642466]],
    [ "Enedis",[-0.9001592, 46.449974100000006]],
    [ "Enedis",[-0.8696817499999994, 46.45715175]],
    [ "Enedis",[-0.8669977806470718, 46.44288429543124]],
    [ "Enedis",[-0.8921296501036156, 46.44656812893358]],
    [ "Enedis",[-0.8556656336212864, 46.43328455302351]],
    [ "Enedis",[-0.8225492499999999, 46.4149846]],
    [ "Enedis",[-0.4180342244897962, 48.624268321876116]],
    [ "Enedis",[-0.8471739, 46.4836563]],
    [ "Enedis",[1.5780720999999998, 50.6744116]],
    [ "Enedis",[1.5735876999999985, 50.66956100000001]],
    [ "Enedis",[1.5762494999999999, 50.669425000000004]],
    [ "Enedis",[1.5748745499999999, 50.67750955]],
    [ "Enedis",[1.57026875, 50.6792935]],
    [ "Enedis",[1.5759012333224731, 50.67575095017586]],
    [ "Enedis",[1.5717336, 50.68333930000001]],
    [ "Enedis",[1.5795419840606253, 50.675458195074825]],
    [ "Enedis",[1.57246035, 50.67446244999999]],
    [ "Enedis",[1.57310305, 50.67290184999999]],
    [ "Enedis",[1.5742207142508586, 50.68121274985299]],
    [ "Enedis",[1.5717417310004613, 50.676885103919226]],
    [ "Enedis",[-0.37521592726873226, 49.181850129422095]],
    [ "Bourgogne",[3.435377267011165, 46.12903856012895]],
    [ "Châteaudun",[3.438507393623361, 46.126307452274546]],
    [ "Durantats",[3.3156475499999996, 46.557314299999994]],
    [ "Poste électrique de Bellenaves",[3.0893836826816643, 46.20107312545225]],
    [ "Presles 2",[3.4398768317967274, 46.13481510977076]],
    [ "Enedis",[3.3628693530417646, 46.57066675855665]],
    [ "Enedis",[3.291210691396214, 46.27381996396]],
    [ "Jeux de Boules",[4.732601399999999, 45.944919199999994]],
    [ "Le Bois des Lys",[4.7417891850950795, 45.94837336085771]],
    [ "Helvètes",[4.737692861643019, 45.94707561881755]],
    [ "RTE",[4.748782254591621, 45.95105117623525]],
    [ "Enedis",[2.6187426516934056, 50.50263352932265]],
    [ "Enedis",[2.619946, 50.504781]],
    [ "Enedis",[2.624073895747599, 50.50338063776863]],
    [ "Enedis",[2.625653402777777, 50.50639826736111]],
    [ "Enedis",[2.62644642261397, 50.49954568192588]],
    [ "Enedis",[2.5877766056961504, 50.50174568395556]],
    [ "Sennecey (SNCF)",[4.8732822532270825, 46.65110427794107]],
    [ "Enedis",[5.584019725109952, 47.134990096743536]],
    [ "Enedis",[5.560637421431583, 47.130276992638095]],
    [ "Enedis",[5.5663827999999995, 47.1293889]],
    [ "Enedis",[5.5565625999999995, 47.12954179999999]],
    [ "Enedis",[5.567061749999999, 47.131011949999994]],
    [ "Enedis",[5.563529900905956, 47.124614350214074]],
    [ "Enedis",[2.587042992601727, 50.47861666687218]],
    [ "Enedis",[2.5857798241577648, 50.48032529964393]],
    [ "Enedis",[2.582608341023951, 50.47174216413975]],
    [ "Enedis",[2.577833990336346, 50.47423325301205]],
    [ "Enedis",[2.5817782512562806, 50.47804929983249]],
    [ "Enedis",[2.569662471840574, 50.469131668399555]],
    [ "Enedis",[2.563181767473118, 50.466014575268815]],
    [ "Enedis",[2.5657146798021153, 50.47617497134084]],
    [ "Enedis",[2.5653925171247005, 50.46660286135183]],
    [ "Enedis",[2.565087104145937, 50.46570313134328]],
    [ "Enedis",[2.5761399621963896, 50.47146644882337]],
    [ "Enedis",[2.58253, 50.457748499999994]],
    [ "Enedis",[2.585421351182197, 50.46209963351877]],
    [ "Enedis",[2.598907427609428, 50.469340616161624]],
    [ "Enedis",[2.598853656716417, 50.47323798507462]],
    [ "Enedis",[2.5957335, 50.4751]],
    [ "Enedis",[2.6004966975016255, 50.473255347822054]],
    [ "Enedis",[2.5941947808493593, 50.47100575320513]],
    [ "Enedis",[2.5942228418034925, 50.47372202736512]],
    [ "Enedis",[2.5971759999999997, 50.475783]],
    [ "Enedis",[2.600185415946205, 50.46572688376561]],
    [ "Enedis",[2.597794885148267, 50.47075782483856]],
    [ "Enedis",[2.597980579399142, 50.47005894360992]],
    [ "Enedis",[2.582506867056794, 50.46974823422613]],
    [ "Enedis",[2.5858434626532887, 50.454407228093636]],
    [ "Enedis",[2.5902439867469873, 50.44958064016065]],
    [ "Enedis",[2.617030326765189, 50.42566752052545]],
    [ "Enedis",[2.6625315215198624, 50.5713073357513]],
    [ "Enedis",[2.667423707352514, 50.570714844521945]],
    [ "Enedis",[2.6568236847791558, 50.57745617095648]],
    [ "Enedis",[2.6740211421400866, 50.58001625005704]],
    [ "Enedis",[1.009111756762875, 49.63793038551217]],
    [ "Enedis",[2.7212127033854965, 50.57391284085098]],
    [ "Montaigu bourg",[5.562654797836572, 46.66065641691674]],
    [ "Enedis",[5.563278347232449, 46.639600284465814]],
    [ "Enedis",[5.577249710524066, 46.625828903138384]],
    [ "Enedis",[5.5776571, 46.62935375]],
    [ "Enedis",[5.555713656049433, 46.61440043906511]],
    [ "Enedis",[2.6059341535003218, 50.40008432434169]],
    [ "Enedis",[2.608678604474746, 50.39902477530636]],
    [ "Enedis",[2.6816432670768267, 50.50193295504022]],
    [ "Enedis",[2.680191521781934, 50.484090212322585]],
    [ "Enedis",[2.673727, 50.490126499999995]],
    [ "Enedis",[2.6739253838963246, 50.49277282175483]],
    [ "Enedis",[2.685257077145214, 50.4985293174505]],
    [ "Enedis",[2.6820755943482215, 50.48262768307506]],
    [ "Enedis",[2.683325887788779, 50.50166472277228]],
    [ "Enedis",[4.818371934230753, 45.74444266464289]],
    [ "Enedis",[2.1355721856786913, 50.326029431264224]],
    [ "Enedis",[2.7044480858214337, 50.512978207125336]],
    [ "Enedis",[2.754475423978729, 50.55291870364999]],
    [ "Enedis",[2.7369578790707325, 50.541631986309895]],
    [ "RTE",[-0.35322610382186664, 47.837468735491136]],
    [ "Enedis",[2.6874938294237936, 50.53402716913268]],
    [ "Enedis",[2.698075885060147, 50.53930916693831]],
    [ "Enedis",[2.706780357651246, 50.52614372746145]],
    [ "Enedis",[2.6905055, 50.5132515]],
    [ "Enedis",[2.6866221260530176, 50.518177077826714]],
    [ "Enedis",[2.680686736757352, 50.51712807509111]],
    [ "Enedis",[2.6694591351150714, 50.51892830248288]],
    [ "Enedis",[2.6682143629967676, 50.521059276478425]],
    [ "Enedis",[2.6730869999999998, 50.51425199999999]],
    [ "Enedis",[2.667050489096573, 50.52216550882658]],
    [ "Gamm Vert",[2.670648794464377, 50.51732795113618]],
    [ "Enedis",[2.047923, 50.3658395]],
    [ "Enedis",[2.039102834929321, 50.36502356543547]],
    [ "Enedis",[2.6873117560720376, 50.529090526852414]],
    [ "Enedis",[2.684759126815101, 50.52724667699258]],
    [ "Enedis",[2.677108, 50.5224485]],
    [ "Enedis",[2.674448330563948, 50.52296558710977]],
    [ "Perrigny Bourg",[5.582074200000001, 46.6705734]],
    [ "Enedis",[5.583284475079794, 46.680148454855804]],
    [ "Malfontaine",[5.5756713412972765, 46.670281266494335]],
    [ "Enedis",[5.5832482500000005, 46.67965725]],
    [ "Enedis",[5.580141179755868, 46.67977712520181]],
    [ "Vauglin",[5.580924622856038, 46.68233246550003]],
    [ "Liberté",[5.58622905, 46.66861699999999]],
    [ "Les Contours",[5.580540381072555, 46.67333487171398]],
    [ "Les Combes",[5.584309549999999, 46.686679049999995]],
    [ "RVI",[5.583349794219582, 46.67712227407458]],
    [ "Enedis",[5.5803258, 46.677707149999996]],
    [ "Champion",[5.5756162499999995, 46.670253]],
    [ "Enedis",[5.5805523636666425, 46.668532523655976]],
    [ "La Lième",[5.58384997844164, 46.684257311354436]],
    [ "Enedis",[1.3068616464646472, 43.66014366666668]],
    [ "Enedis",[1.308071656167979, 43.6594740104987]],
    [ "Enedis",[2.79370369586212, 50.52030466183791]],
    [ "Enedis",[2.791803, 50.51132500000001]],
    [ "Enedis",[2.7870915000000003, 50.510782500000005]],
    [ "Enedis",[2.741666011722968, 50.5752922414006]],
    [ "Enedis",[2.7430693117238674, 50.57834346426127]],
    [ "Enedis",[2.7885991260162593, 50.513247621951216]],
    [ "Enedis",[2.775132792415171, 50.517146455089815]],
    [ "Enedis",[2.7576508056872036, 50.528862034755136]],
    [ "Enedis",[2.7717738511904764, 50.52210644642858]],
    [ "Enedis",[2.7585155600681905, 50.52539359416366]],
    [ "Enedis",[2.7416335000000003, 50.475792500000004]],
    [ "Enedis",[2.746284658194084, 50.483208830626815]],
    [ "Enedis",[2.7501261460541815, 50.49461974204947]],
    [ "Enedis",[2.743028113050707, 50.4854280648379]],
    [ "Enedis",[2.7475950955739967, 50.48878610663901]],
    [ "Enedis",[2.7475136084507046, 50.49329218215962]],
    [ "Enedis",[2.749697167470593, 50.49248249792673]],
    [ "Enedis",[2.7499745681839083, 50.48587770298851]],
    [ "Enedis",[2.746093667811018, 50.48673700408696]],
    [ "Enedis",[2.7440357350282496, 50.490640975706214]],
    [ "Enedis",[2.749319361572244, 50.48696185410627]],
    [ "Enedis",[2.744169715285369, 50.510658465631614]],
    [ "Enedis",[2.7339333841807907, 50.50998323163842]],
    [ "Enedis",[2.74408700017646, 50.512043468678314]],
    [ "Enedis",[2.7398498132132127, 50.509409985585584]],
    [ "Enedis",[2.727296135802469, 50.50771164197532]],
    [ "Enedis",[2.7277454999999997, 50.5090875]],
    [ "Enedis",[2.729370711841204, 50.507635041067765]],
    [ "Enedis",[2.72633250499002, 50.50628293213574]],
    [ "Enedis",[2.7249055451869295, 50.50398491119615]],
    [ "Enedis",[2.7272002255892254, 50.50472264870931]],
    [ "Enedis",[2.7285648332420096, 50.503102004383564]],
    [ "Enedis",[2.720912458191449, 50.505536892167996]],
    [ "Enedis",[2.7813783076923073, 50.51843922649574]],
    [ "Enedis",[2.7398803876957976, 50.52236427330073]],
    [ "Enedis",[2.7509826690154626, 50.51846298649441]],
    [ "Enedis",[2.7272968888218734, 50.49286516839567]],
    [ "Enedis",[1.4987665023221486, 47.65751213249598]],
    [ "Enedis",[1.4949918734491319, 47.657391192721256]],
    [ "Enedis",[1.4946562225886233, 47.65571093404782]],
    [ "Enedis",[2.622844263693575, 50.54569824647643]],
    [ "Enedis",[2.640549255791962, 50.5532118282112]],
    [ "Enedis",[2.6152354940530644, 50.53647964257395]],
    [ "Enedis",[2.6132267767988258, 50.537768339207055]],
    [ "Enedis",[2.6235570337899543, 50.542411835616434]],
    [ "Enedis",[2.6187186942392904, 50.53531551944855]],
    [ "Enedis",[2.617672111813135, 50.53980604154701]],
    [ "Enedis",[2.6142063001104607, 50.53940057388053]],
    [ "Enedis",[2.6214866200448768, 50.539231311393664]],
    [ "Enedis",[2.619107244352236, 50.53085928215767]],
    [ "Enedis",[2.5974803197867127, 50.53546469909209]],
    [ "Enedis",[2.597761638285549, 50.529319542426876]],
    [ "Enedis",[2.6028542366666665, 50.53073006]],
    [ "Enedis",[2.602074697318008, 50.53399804597702]],
    [ "Enedis",[2.602674370474847, 50.52874992524683]],
    [ "Enedis",[2.597760776901946, 50.53039327528962]],
    [ "Enedis",[2.5958665, 50.529365]],
    [ "RTE",[2.267488073252977, 49.93394126551382]],
    [ "Enedis",[2.612403719101123, 50.52650266292134]],
    [ "Enedis",[2.605567532729104, 50.527417695871094]],
    [ "Enedis",[2.4315437760484215, 49.51235017423261]],
    [ "Enedis",[2.4294595, 49.50339549999999]],
    [ "Enedis",[2.426236, 49.5085025]],
    [ "Enedis",[2.4258365, 49.50335750000001]],
    [ "Enedis",[2.4327148534278957, 49.50282867769897]],
    [ "Enedis",[2.438302140915594, 49.499664914163084]],
    [ "Enedis",[2.4265708140801245, 49.50582355153635]],
    [ "Enedis",[2.140767922619048, 50.67965424801586]],
    [ "Enedis",[6.1259462345491436, 45.017355747386624]],
    [ "Enedis",[1.613961731563421, 50.586765030130636]],
    [ "Enedis",[1.6177292408293462, 50.58906414597498]],
    [ "Enedis",[1.6134143162467416, 50.585192898349256]],
    [ "Enedis",[1.6229011172298704, 50.5962236725073]],
    [ "Enedis",[1.6136057256488032, 50.58937252881699]],
    [ "Enedis",[4.477547083285239, 46.756140349696004]],
    [ "Enedis",[5.61626435, 46.79975965]],
    [ "Enedis",[5.615557249999999, 46.79801549999999]],
    [ "Enedis",[5.684618592351598, 46.79966344452055]],
    [ "Enedis",[6.394706209689082, 48.08885044468547]],
    [ "Enedis",[6.402024592435368, 48.09177813663829]],
    [ "MLTVULCA",[-3.413422321929887, 47.745494012701506]],
    [ "Plateau Menez",[-3.3896702500000004, 47.70906995]],
    [ "Forges Mimosas",[-3.2555254000000007, 47.8231743]],
    [ "Rue du Roch",[-3.1344392500000002, 47.5235106]],
    [ "Enedis",[6.8249096499999995, 45.57345619999999]],
    [ "Le Maigre",[3.0819919369887074, 45.230521224590845]],
    [ "Enedis",[1.9156782391857508, 50.424511653944016]],
    [ "Enedis",[1.8942245000000002, 50.4230605]],
    [ "Enedis",[1.890726010403662, 50.42631983312525]],
    [ "Enedis",[1.9129426760828632, 50.42170330131828]],
    [ "Enedis",[1.8964019233830838, 50.42661939900498]],
    [ "Enedis",[1.9004106023932545, 50.42265021086031]],
    [ "Enedis",[1.6429219587131765, 50.67407431398652]],
    [ "Enedis",[1.6337723520266512, 50.65595874606701]],
    [ "Enedis",[1.625615, 50.65054250000001]],
    [ "Enedis",[1.6274424318181817, 50.64950409848485]],
    [ "Enedis",[1.6246995881824715, 50.65315126849857]],
    [ "Enedis",[1.6122163308395678, 50.64699397921862]],
    [ "Enedis",[1.6276521438240275, 50.651048302876475]],
    [ "Enedis",[1.6308662454725495, 50.64912098244765]],
    [ "Enedis",[1.6325972, 50.6573643]],
    [ "Enedis",[1.62215, 50.648028]],
    [ "Enedis",[1.6222239375000014, 50.64670347916666]],
    [ "Enedis",[1.6188130811965815, 50.650443211538466]],
    [ "Enedis",[1.6269441309471209, 50.648117763588154]],
    [ "Enedis",[1.635235066421268, 50.65395544523517]],
    [ "Enedis",[1.62029805, 50.653422000000006]],
    [ "Enedis",[1.6263042659409017, 50.65467319025402]],
    [ "Enedis",[1.679632659840728, 50.68452149146758]],
    [ "Enedis",[1.6587437999999999, 50.67244655]],
    [ "Enedis",[1.6560213500000003, 50.67445239999999]],
    [ "Enedis",[1.6371744499999998, 50.68268065000001]],
    [ "Enedis",[1.6514304999999998, 50.673009]],
    [ "Enedis",[1.655145, 50.67224999999999]],
    [ "Enedis",[1.6549772574688457, 50.67091070186459]],
    [ "Enedis",[1.64854095, 50.67562284999999]],
    [ "Enedis",[1.6432323000000006, 50.67861045]],
    [ "Enedis",[1.6094815000000002, 50.69704965]],
    [ "Enedis",[1.6148179999999999, 50.69574799999999]],
    [ "Enedis",[1.6086626499999996, 50.694536949999986]],
    [ "Enedis",[1.6147425, 50.69459735]],
    [ "Enedis",[1.62303056715481, 50.69103814886647]],
    [ "Enedis",[1.623754151195355, 50.68687032462433]],
    [ "Enedis",[1.6161877315199473, 50.69070380824266]],
    [ "Enedis",[1.6118057000000001, 50.69164645]],
    [ "Enedis",[1.6185492999999997, 50.68956000000001]],
    [ "Enedis",[1.6185033, 50.68846585000001]],
    [ "Enedis",[1.6203677, 50.68881714999999]],
    [ "Enedis",[1.6241000071512248, 50.6870078686811]],
    [ "Enedis",[1.6220664692067213, 50.688343989058225]],
    [ "Enedis",[1.610733, 50.691839449999996]],
    [ "Enedis",[1.6103376546579777, 50.69231139521027]],
    [ "Bourg",[5.642163224704122, 46.59816914262203]],
    [ "Enedis",[1.6317480000000009, 50.6888291]],
    [ "Enedis",[1.6345425500000001, 50.68999135]],
    [ "Enedis",[1.626211, 50.687225250000004]],
    [ "Enedis",[1.6314101439212558, 50.69169332056397]],
    [ "Enedis",[1.6278667099567097, 50.688290748917744]],
    [ "Enedis",[1.630181062380005, 50.694374693232824]],
    [ "Enedis",[1.6254766499999995, 50.691481499999995]],
    [ "Enedis",[1.6322612, 50.6902518]],
    [ "Enedis",[-0.9046681262153385, 46.41971741539341]],
    [ "Enedis",[-0.9017544301912629, 46.42936043389569]],
    [ "Enedis",[-0.896751, 46.41001325]],
    [ "Enedis",[-0.8983149999999992, 46.412598249999995]],
    [ "Enedis",[1.6216806473586394, 50.693731666703925]],
    [ "Enedis",[1.6257389453697682, 50.69405358095857]],
    [ "Enedis",[1.6280758869643668, 50.69553339255751]],
    [ "Enedis",[1.629493, 50.6960636]],
    [ "Enedis",[1.6330150180131418, 50.695443653362425]],
    [ "Enedis",[1.6722869999999999, 50.67145500000001]],
    [ "Enedis",[1.683611949999999, 50.6667985]],
    [ "Enedis",[1.6800446191508556, 50.6634281156442]],
    [ "Enedis",[1.6762952499999992, 50.66676525]],
    [ "Enedis",[1.6668254461574934, 50.6701207829682]],
    [ "Enedis",[1.6865967754385967, 50.665682442105265]],
    [ "Enedis",[1.6762944499999999, 50.6645885]],
    [ "Enedis",[1.6792159216682547, 50.665494155414414]],
    [ "Enedis",[-0.9098329500000003, 46.480554999999995]],
    [ "Enedis",[-0.9083247683868348, 46.47704810269492]],
    [ "Mi-Carrère",[-0.4695043, 43.90360055]],
    [ "Enedis",[1.6748620888888892, 50.65666531111111]],
    [ "Enedis",[1.6859106320346324, 50.65514256103896]],
    [ "Enedis",[1.660815824017467, 50.66062596521107]],
    [ "Enedis",[1.6646025, 50.660183]],
    [ "Enedis",[1.6607922503457813, 50.660643688796696]],
    [ "Enedis",[1.6988197328007575, 50.64792700337298]],
    [ "Enedis",[1.7111366000000001, 50.6482645]],
    [ "Enedis",[1.7037463254983558, 50.65042204677846]],
    [ "Enedis",[4.711486753259907, 46.47856052664894]],
    [ "Enedis",[4.040755515912897, 46.41192019262981]],
    [ "Enedis",[4.035552155096935, 46.40749167292057]],
    [ "Enedis",[1.6726324848276146, 50.63201251952639]],
    [ "Enedis",[1.7037057506631303, 50.62580213793104]],
    [ "Enedis",[1.6448248356164386, 50.627080522070024]],
    [ "Enedis",[5.6085210000000005, 46.63270275]],
    [ "Enedis",[0.4573825963199817, 47.25533030416245]],
    [ "Enedis",[1.7325394999999997, 50.616925499999994]],
    [ "Poste électrique de Péronne",[2.9176929237758404, 49.9421927968316]],
    [ "Enedis",[-0.9721731699177378, 46.602519503522]],
    [ "Enedis",[-0.9440439253338807, 46.50505803481728]],
    [ "Enedis",[-0.94363625, 46.5093785]],
    [ "Enedis",[1.236362854776791, 47.667846454316106]],
    [ "Enedis",[1.2462276518256767, 47.671058037926976]],
    [ "Enedis",[1.2422906, 47.66877575]],
    [ "Enedis",[1.234573337777778, 47.67347552444444]],
    [ "Enedis",[2.088244603906078, 50.3593387028747]],
    [ "Enedis",[2.080735144435675, 50.35809801236517]],
    [ "Enedis",[2.130615712919724, 50.34810386946854]],
    [ "réséda",[6.271443799999999, 49.054433100000004]],
    [ "réséda",[6.268725386531559, 49.045575206248685]],
    [ "réséda",[6.2602716, 49.059215399999985]],
    [ "réséda",[6.257654683403068, 49.06954057322175]],
    [ "réséda",[6.254645307189542, 49.072183124183006]],
    [ "réséda",[6.250718621217771, 49.06863744032309]],
    [ "réséda",[6.258270305393867, 49.06854697982149]],
    [ "réséda",[6.265157499999999, 49.078169]],
    [ "réséda",[6.246725399999999, 49.0674417]],
    [ "244022213",[-2.074526336220713, 47.6470530418638]],
    [ "Puteaux",[2.235081329698004, 48.877144146886145]],
    [ "244036855",[-1.8744662499999998, 47.69618775000001]],
    [ "Centrale souterraine de Montézic",[2.6433822692259956, 44.72993008396219]],
    [ "Poste électrique de Cubnezais",[-0.43404020506832447, 45.066290854975186]],
    [ "Poste source de Rueyres",[2.690166931322266, 44.779832719235905]],
    [ "Enedis",[1.6608108453121497, 50.70684381309018]],
    [ "Enedis",[1.6509493000000004, 50.70383515]],
    [ "Enedis",[1.6369025000000004, 50.696639000000005]],
    [ "Enedis",[1.6927058000000001, 50.7185932]],
    [ "Enedis",[1.6929014938635252, 50.71630279675996]],
    [ "Enedis",[1.6771171096588593, 50.71118006377033]],
    [ "Enedis",[1.6852010360360374, 50.70955233333332]],
    [ "Enedis",[2.729272026695015, 50.15086989605481]],
    [ "Bourg",[5.036565941461749, 45.95366772636612]],
    [ "Enedis",[5.040471238687579, 45.95323446782044]],
    [ "Enedis",[-0.8106901683903524, 46.53177648893968]],
    [ "Enedis",[-0.80793595, 46.5326239]],
    [ "Enedis",[2.1511405061183946, 50.39171064139849]],
    [ "Enedis",[1.766674106280193, 50.40746525120773]],
    [ "Petit Marché",[0.4257891210090972, 48.179092816790735]],
    [ "Enedis",[0.43434536681222724, 48.17768661572053]],
    [ "Enedis",[0.44143183452246837, 48.179039281011384]],
    [ "La Chesnaie",[0.43091809519176233, 48.18526725687181]],
    [ "Église",[0.4291122636145082, 48.174441446953075]],
    [ "De Gaulle",[0.4259881072261069, 48.18225225291375]],
    [ "Enedis",[0.42437137421313903, 48.18414655024809]],
    [ "Avenue du 8 Mai 1945",[0.4180804319161904, 48.170658441153115]],
    [ "Enedis",[0.42093184615384655, 48.17983296410256]],
    [ "Château",[0.42639152672444874, 48.17725724918743]],
    [ "Enedis",[1.8228763575235754, 50.39041230381303]],
    [ "Enedis",[1.8143629999999997, 50.384873000000006]],
    [ "Enedis",[1.6868997844767841, 50.732203041580036]],
    [ "Enedis",[1.704223, 50.73322099999999]],
    [ "Enedis",[1.7040131753948462, 50.731774970906066]],
    [ "Enedis",[1.6855590000000011, 50.73436900000001]],
    [ "Enedis",[1.6990541726162884, 50.72137897187452]],
    [ "Enedis",[1.7071873540121374, 50.731327469768495]],
    [ "Enedis",[1.7123123900143238, 50.731389789850624]],
    [ "Enedis",[1.7056348738766027, 50.72763216378874]],
    [ "Enedis",[1.712414350000001, 50.72917985]],
    [ "Enedis",[1.7036888499999994, 50.72556185]],
    [ "Enedis",[1.6989631866666668, 50.73410897333333]],
    [ "Enedis",[1.7102927264024506, 50.73209492590465]],
    [ "Enedis",[1.7099343073880593, 50.729320425755965]],
    [ "Enedis",[1.8810275000000003, 50.395345500000005]],
    [ "Enedis",[1.8585563171553758, 50.391755923154705]],
    [ "Enedis",[1.8902940705487117, 50.40141067711832]],
    [ "Enedis",[6.190883253800732, 48.257768080026814]],
    [ "Enedis",[1.691116066961001, 50.733960018395884]],
    [ "Enedis",[1.7063361903612515, 50.75051920259071]],
    [ "Vatagna",[5.590156755603887, 46.65962476118526]],
    [ "Enedis",[5.604478009032353, 46.65527199118642]],
    [ "Enedis",[5.596916260636224, 46.65415943280994]],
    [ "Enedis",[5.59315525047377, 46.65758776664611]],
    [ "Enedis",[5.598635200000001, 46.65653755]],
    [ "Enedis",[1.7382152617886177, 50.741458450174214]],
    [ "Enedis",[1.734502, 50.74705]],
    [ "Enedis",[0.15590369383160685, 47.60805518205014]],
    [ "Enedis",[5.8233967563739935, 47.00597796004714]],
    [ "Enedis",[1.7606014999999997, 50.45765550000001]],
    [ "Enedis",[1.752797316766375, 50.45153062080073]],
    [ "Enedis",[2.1193984432234427, 50.226786109890114]],
    [ "Enedis",[2.1074575, 50.22882249999999]],
    [ "Enedis",[2.125085507164352, 50.22706521933252]],
    [ "Enedis",[2.1131882245491567, 50.23478846538685]],
    [ "Enedis",[2.111111769620695, 50.23021557315154]],
    [ "Enedis",[2.1034137176963736, 50.23235667705485]],
    [ "Enedis",[2.116761708333333, 50.22807391666667]],
    [ "Enedis",[2.1163893009922825, 50.23027206872473]],
    [ "Enedis",[2.1290063797841015, 50.232428745175]],
    [ "Enedis",[2.126710076114439, 50.22880490825016]],
    [ "Enedis",[2.1095652579345088, 50.2320370649874]],
    [ "Enedis",[1.7431560815850813, 50.44223434032635]],
    [ "Enedis",[1.7477842551570093, 50.45029047155433]],
    [ "Enedis",[1.7376858439614906, 50.445946651129546]],
    [ "Enedis",[1.7377965, 50.443824]],
    [ "Enedis",[1.749365657407407, 50.449627629629624]],
    [ "Enedis",[1.7555997439334416, 50.45606095670596]],
    [ "Enedis",[-0.8840278168964507, 46.56929104791319]],
    [ "Enedis",[1.7017911274509807, 50.43555407843137]],
    [ "Enedis",[1.7261722172284644, 50.41605389138577]],
    [ "Enedis",[1.7228506203190597, 50.409348293031066]],
    [ "Enedis",[1.721300134328358, 50.41187175621891]],
    [ "Enedis",[1.662128158366981, 50.46947626185136]],
    [ "Enedis",[1.6437184611752362, 50.48471932327737]],
    [ "Enedis",[1.6782768301008535, 50.461606000517186]],
    [ "Enedis",[1.6603140916303498, 50.45780790904011]],
    [ "Enedis",[1.6575030000000002, 50.46918049999999]],
    [ "Place des Tresums",[6.129678369098685, 45.894225593996495]],
    [ "Enedis",[1.726773, 50.47533549999999]],
    [ "Enedis",[5.777750392008372, 47.0112357624625]],
    [ "Enedis",[1.754464403327596, 50.47170871084337]],
    [ "Enedis",[-1.6156897575697189, 49.635596175903366]],
    [ "Enedis",[1.7731307803533016, 50.47648189355683]],
    [ "Enedis",[1.7799147162201074, 50.47454519677326]],
    [ "Enedis",[1.7737979564356432, 50.47214732277228]],
    [ "Enedis",[-2.4794406233691557, 48.1446457857107]],
    [ "Enedis",[-2.4396934992224586, 48.16412800201162]],
    [ "Enedis",[2.847703286045198, 48.32330708396584]],
    [ "Enedis",[2.8142689973123844, 48.31167895286649]],
    [ "Bourg",[-3.6044420012326004, 47.85827998084176]],
    [ "Enedis",[6.120524627678238, 46.78983855556674]],
    [ "Enedis",[6.126130000000001, 46.79211595]],
    [ "Enedis",[-0.914976349999999, 46.56732645]],
    [ "Enedis",[1.7081206758304701, 50.462488967272144]],
    [ "Enedis",[1.7243507084019767, 50.4575329538715]],
    [ "Enedis",[1.7238393382242285, 50.45335600601956]],
    [ "Enedis",[5.628182977554016, 44.90752185126914]],
    [ "Enedis",[1.8324465076560659, 50.664969173144875]],
    [ "Enedis",[1.8367844963365636, 50.66575033900576]],
    [ "Enedis",[1.847304963373656, 50.6693894002016]],
    [ "Enedis",[1.8449507435897434, 50.66651623076924]],
    [ "Enedis",[1.8322807790014688, 50.67478995007342]],
    [ "Enedis",[1.834555737324549, 50.6682202774754]],
    [ "Enedis",[1.8234539051675187, 50.66860899488927]],
    [ "Enedis",[1.830814052608457, 50.66699218616145]],
    [ "Enedis",[1.830700790491237, 50.66849181095314]],
    [ "Enedis",[-0.4075473704488155, 48.381515981643986]],
    [ "Enedis",[1.8162713848238488, 50.70944012737127]],
    [ "Enedis",[2.041473730065202, 47.416868257628224]],
    [ "Enedis",[2.0303679623590503, 47.42167832834796]],
    [ "Enedis",[2.051731706425239, 47.4351059732098]],
    [ "Enedis",[2.0514552599119265, 47.43129994138608]],
    [ "Enedis",[2.04763935, 47.43855045]],
    [ "Enedis",[2.0444096, 47.416525799999995]],
    [ "Enedis",[2.0528390421982796, 47.43316327944429]],
    [ "Enedis",[2.0332895042945953, 47.420318369995215]],
    [ "Enedis",[2.008094946943778, 47.41161289730378]],
    [ "Enedis",[2.047514944675704, 47.416591127041244]],
    [ "Enedis",[2.0476514499999996, 47.417838749999994]],
    [ "Enedis",[2.0487334537151907, 47.42420262813637]],
    [ "Bourg Tremeven",[-3.5320370000000003, 47.897842999999995]],
    [ "Enedis",[-0.35076920000000006, 47.851954250000006]],
    [ "Enedis",[-0.34075593301164997, 47.84489692427398]],
    [ "Enedis",[-0.356025, 47.85299425]],
    [ "Enedis",[3.146304271695053, 45.799296107866994]],
    [ "Enedis",[-0.3282202583471855, 47.84733936561388]],
    [ "Enedis",[-0.3313343905706669, 47.843080000106674]],
    [ "Enedis",[-0.3313040000000001, 47.84586825000001]],
    [ "Enedis",[-0.3328509815184368, 47.84365400686428]],
    [ "Enedis",[-0.3324833748468279, 47.840743779278235]],
    [ "Enedis",[-0.34352794999999997, 47.83513]],
    [ "Enedis",[6.631674999999999, 46.259671]],
    [ "Enedis",[6.640928881656806, 46.28264029388561]],
    [ "Enedis",[6.651308925100061, 46.28679469582619]],
    [ "Enedis",[6.631913194275161, 46.26203966638966]],
    [ "Enedis",[6.626935528602152, 46.27143365763441]],
    [ "Enedis",[6.647687147088353, 46.285515754267074]],
    [ "Enedis",[6.643987412836767, 46.28357558795563]],
    [ "Enedis",[6.103771076007308, 46.73269709355785]],
    [ "Drouzin",[6.65673314169353, 46.27417520595195]],
    [ "Enedis",[1.7765796201800008, 50.79547229270911]],
    [ "Enedis",[1.7958363770685604, 50.81046385933806]],
    [ "Enedis",[1.7421064332714906, 50.48941788225109]],
    [ "Enedis",[1.7210820009076473, 50.49195950601317]],
    [ "Enedis",[1.7139605190054559, 50.49455810025937]],
    [ "Enedis",[2.1686346111393258, 50.4216217641781]],
    [ "Enedis",[2.41767062876395, 50.48707981196041]],
    [ "Enedis",[2.4091888792846485, 50.484184430700445]],
    [ "Enedis",[2.4141678961487374, 50.483907790969454]],
    [ "Enedis",[2.407778013245034, 50.4834878432671]],
    [ "Enedis",[2.422072, 50.4833825]],
    [ "Enedis",[2.4029780223018715, 50.48343810195141]],
    [ "Enedis",[2.408721313113807, 50.48075996244946]],
    [ "Enedis",[2.4141062831244597, 50.48391021762434]],
    [ "RTE",[4.0894966206026595, 44.29059789454864]],
    [ "Enedis",[1.7056807727658545, 50.519701442706435]],
    [ "Enedis",[1.775206602460364, 50.83624832182094]],
    [ "Enedis",[1.778311361515579, 50.84094067574761]],
    [ "Enedis",[1.7680290155781906, 50.83793237147992]],
    [ "Enedis",[1.7802262459113893, 50.84259094588165]],
    [ "Enedis",[1.7757734999999997, 50.838490500000006]],
    [ "Sous-station SNCF de Saint-Brieuc - Berno",[-2.7779535080782956, 48.50820105649022]],
    [ "Enedis",[1.702092853799452, 50.56335845152761]],
    [ "Enedis",[1.6948856266666674, 50.551893706666675]],
    [ "Enedis",[1.6958298846153848, 50.5556993397436]],
    [ "Enedis",[1.699763528735632, 50.582929731800775]],
    [ "Enedis",[1.698105, 50.560601000000005]],
    [ "Enedis",[1.6959020945273635, 50.55846572953414]],
    [ "Enedis",[1.6702419866605511, 50.58424952307418]],
    [ "Enedis",[1.7503388906250006, 50.811358721354175]],
    [ "Enedis",[1.7515285, 50.80423000000001]],
    [ "Enedis",[1.7546303895348834, 50.80548279780362]],
    [ "Enedis",[1.745500488692232, 50.80882683251394]],
    [ "Enedis",[1.6892142256169227, 50.795870969839406]],
    [ "Enedis",[1.7144524999999997, 50.82363150000001]],
    [ "Enedis",[1.7123462837859313, 50.82574952580594]],
    [ "Enedis",[1.7201232352428666, 50.807778895084574]],
    [ "Enedis",[1.718446956447348, 50.808241828433665]],
    [ "Enedis",[1.7144035703777993, 50.81157525643598]],
    [ "Enedis",[1.7073497882136277, 50.819577626150995]],
    [ "Enedis",[1.707518072282891, 50.82179584139366]],
    [ "Enedis",[1.7018376615384616, 50.81903398632478]],
    [ "Enedis",[1.7036990000000003, 50.8214885]],
    [ "Enedis",[1.699793497797357, 50.819082607929516]],
    [ "Enedis",[1.6989422408656547, 50.8121582373056]],
    [ "Enedis",[1.7035106077227173, 50.81285119313849]],
    [ "Enedis",[1.6672766539440214, 50.80260798218829]],
    [ "Enedis",[1.6646969999999999, 50.799693999999995]],
    [ "Enedis",[1.6640969503039909, 50.80142277583928]],
    [ "Enedis",[1.6653944278915793, 50.7943217018415]],
    [ "Enedis",[1.5915607291666667, 50.82406364583334]],
    [ "Enedis",[1.596518967653705, 50.82289612926626]],
    [ "Enedis",[1.5940025997261524, 50.82433681408794]],
    [ "Enedis",[1.591085919614148, 50.825927685959265]],
    [ "Enedis",[1.594753808678501, 50.82173196844182]],
    [ "Enedis",[1.59924938125243, 50.822663211824185]],
    [ "Enedis",[1.6271645364181666, 50.80298771550985]],
    [ "Enedis",[1.6224109999999998, 50.80386799999998]],
    [ "Enedis",[1.6133287707129096, 50.80915995901209]],
    [ "Enedis",[1.6047650977653636, 50.80805977932961]],
    [ "Enedis",[1.608494549613184, 50.80869460208544]],
    [ "Enedis",[2.94403368987085, 50.272137393071716]],
    [ "Enedis",[2.94298756997645, 50.26981865196373]],
    [ "Enedis",[2.9466605000000006, 50.274305749999996]],
    [ "Enedis",[6.183541745091818, 48.62124703080076]],
    [ "Enedis",[-0.32015625641025647, 47.83602452991453]],
    [ "Enedis",[-0.3281855000000001, 47.83611474999999]],
    [ "Enedis",[-0.3222786792225664, 47.83941123512212]],
    [ "Enedis",[-0.32746745, 47.828939649999995]],
    [ "Enedis",[-0.31622937050705185, 47.839392193093076]],
    [ "Enedis",[-0.32544939091961245, 47.830552264945545]],
    [ "Enedis",[-0.3218735076436747, 47.82812183439043]],
    [ "Enedis",[-0.3217107339194139, 47.84157960109889]],
    [ "Enedis",[1.9360099680851064, 50.53825796595744]],
    [ "Sous-station de Drancy",[2.439722172145777, 48.93331000096169]],
    [ "Enedis",[-0.9551119499999999, 46.65831049999999]],
    [ "Enedis",[-0.9511238575019575, 46.66310038147229]],
    [ "Enedis",[2.7797396905547873, 42.847807817887634]],
    [ "Enedis",[1.2239068166666673, 49.54073866666667]],
    [ "Enedis",[0.5887618054837042, 49.110657442662536]],
    [ "Enedis",[0.5616019847009736, 49.12744658507185]],
    [ "Enedis",[2.0984260231556275, 50.38085485191168]],
    [ "Enedis",[2.9960002500000007, 50.21288425]],
    [ "Enedis",[3.0013699000000007, 50.212281000000004]],
    [ "Enedis",[2.6734985329717387, 50.37390030754591]],
    [ "Enedis",[1.861643599378559, 47.9161829364925]],
    [ "Enedis",[1.8793826721544298, 47.924489694398275]],
    [ "Enedis",[1.8786995778550404, 47.92072374546608]],
    [ "Enedis",[1.8823980776699032, 47.92346280521395]],
    [ "Enedis",[1.8828258281825454, 47.92111602676808]],
    [ "Enedis",[1.88548145, 47.92312555]],
    [ "Enedis",[2.7097767669049544, 50.39250464675154]],
    [ "Enedis",[2.705970031297235, 50.39419237672269]],
    [ "Enedis",[1.8951784023038074, 50.58833982041107]],
    [ "Enedis",[2.116447499999999, 50.55013300000001]],
    [ "Enedis",[6.167807524039333, 48.69578532841203]],
    [ "Enedis",[2.7719555602477475, 50.38813640836149]],
    [ "Enedis",[2.771851366139616, 50.3914992298591]],
    [ "Enedis",[2.767610676575321, 50.38880703096456]],
    [ "Enedis",[2.782882899521531, 50.393400686204146]],
    [ "Enedis",[2.7682833126129, 50.39459446732547]],
    [ "Enedis",[2.7774878384431405, 50.38866517786475]],
    [ "Enedis",[2.83885136977058, 50.35670568825909]],
    [ "Enedis",[2.8680332176960146, 50.36110782435392]],
    [ "Enedis",[2.8757045472717446, 50.364879122096156]],
    [ "Enedis",[2.8915997733993355, 50.367210843693144]],
    [ "Enedis",[2.8891306601265825, 50.364980606118145]],
    [ "Enedis",[-0.9405765799524443, 46.63533174425078]],
    [ "Enedis",[-0.9174236329795635, 46.6220787476946]],
    [ "Enedis",[-0.9138788714209607, 46.62294740458806]],
    [ "Enedis",[2.6118965000000007, 50.306954000000005]],
    [ "Enedis",[2.609775014897579, 50.310043662011175]],
    [ "Enedis",[2.656205187250996, 50.31608559628153]],
    [ "Enedis",[2.660649200992269, 50.30705653628707]],
    [ "Bourg",[0.0701913661178787, 48.00918786187572]],
    [ "Enedis",[2.6374949024894616, 50.30877505200867]],
    [ "Enedis",[2.626987939085388, 50.29182592568775]],
    [ "Enedis",[2.613375971428571, 50.274400453968255]],
    [ "Enedis",[2.6154795999999996, 50.276710200000004]],
    [ "Enedis",[2.6107375932521086, 50.27406838769134]],
    [ "Enedis",[-0.8222255631505941, 46.583283328188706]],
    [ "Enedis",[-0.8788244999999999, 46.62027075]],
    [ "Enedis",[-0.8527599972658507, 46.593592366983124]],
    [ "Enedis",[-0.31842774999999995, 47.8298135]],
    [ "Enedis",[-0.30764425, 47.823418499999995]],
    [ "Enedis",[-0.31101850000000003, 47.821946249999996]],
    [ "Enedis",[-0.31192369999999825, 47.83921765]],
    [ "Enedis",[-0.3107692499999987, 47.83631825000001]],
    [ "Enedis",[-0.3076312699831594, 47.83382928449281]],
    [ "Enedis",[-0.3043393622246299, 47.82076605489346]],
    [ "Enedis",[-0.304296, 47.82758]],
    [ "Enedis",[-0.311873, 47.82176100000001]],
    [ "Enedis",[-0.30993600000000004, 47.83752375]],
    [ "Enedis",[-0.31543374999999896, 47.82601400000001]],
    [ "Enedis",[3.3771472, 47.15375193333333]],
    [ "Enedis",[1.87826305, 47.91910395000001]],
    [ "Enedis",[1.8713004717189585, 47.921627089392665]],
    [ "Enedis",[1.8649401701795494, 47.90890363465478]],
    [ "Enedis",[1.8593206000000004, 47.90781175]],
    [ "Enedis",[1.8619316746021692, 47.910336998881206]],
    [ "Enedis",[1.8624173150740426, 47.90648034571151]],
    [ "Enedis",[1.8615668837238133, 47.91263596802487]],
    [ "Enedis",[1.8588548495793373, 47.91310311102257]],
    [ "Enedis",[1.8822514296757125, 47.91109535909655]],
    [ "Enedis",[1.8819209000000012, 47.915911550000004]],
    [ "Enedis",[1.8812517085867155, 47.91043166310885]],
    [ "Enedis",[1.881916303152383, 47.91594857120729]],
    [ "Enedis",[1.8713295288627287, 47.91546613533446]],
    [ "Enedis",[1.8728718051940383, 47.917079301174034]],
    [ "Enedis",[1.8780305019668528, 47.911535467147765]],
    [ "Enedis",[1.8771491000000002, 47.9132295]],
    [ "Enedis",[2.6148165604395603, 50.21607884249085]],
    [ "Enedis",[2.0639243333333335, 50.53400083870968]],
    [ "Enedis",[2.4008237564438617, 50.13777281127128]],
    [ "Enedis",[2.4253197838541665, 50.158013953125]],
    [ "Enedis",[0.6427887376026271, 47.344846032512315]],
    [ "Enedis",[0.6481419999999999, 47.34650649999999]],
    [ "Enedis",[0.683643, 47.3572255]],
    [ "Enedis",[0.645495, 47.34542599999999]],
    [ "Enedis",[2.4593365158902563, 50.17555783192533]],
    [ "Enedis",[2.461966541843972, 50.1726286070922]],
    [ "Enedis",[2.6347663866666684, 50.37305933333334]],
    [ "Enedis",[2.6413548672009157, 50.36941165846211]],
    [ "Bâtiment Montaigne",[2.6353169999999997, 50.370720999999996]],
    [ "Enedis",[0.6630430000000005, 47.353694999999995]],
    [ "Enedis",[3.0021400696571843, 50.27599033698031]],
    [ "Enedis",[2.998745242859881, 50.27483065804101]],
    [ "Enedis",[5.479658932214054, 46.70963974894638]],
    [ "Enedis",[2.4885957386461013, 50.15640549500144]],
    [ "Enedis",[2.488232171330388, 50.15468864212076]],
    [ "Enedis",[2.483517944855145, 50.1591781992674]],
    [ "Enedis",[6.2471455, 48.71058300000001]],
    [ "Enedis",[2.4953709999999996, 50.18458699999999]],
    [ "Enedis",[2.4938535000000015, 50.187227]],
    [ "Enedis",[2.496242133372676, 50.19071840562606]],
    [ "Enedis",[1.9046571719833567, 50.571225259361995]],
    [ "Enedis",[1.9154913137254896, 50.56725007843137]],
    [ "Enedis",[1.9097104643237484, 50.56716798509052]],
    [ "Enedis",[2.120500745303626, 50.49988206815204]],
    [ "Enedis",[-0.9002374163226269, 45.532981730644394]],
    [ "Enedis",[2.041133100574713, 50.56455718678161]],
    [ "Enedis",[2.1506660226253316, 50.464143254777056]],
    [ "SNCF Réseau",[5.078950777618411, 45.74024436378039]],
    [ "SNCF Réseau",[5.07089, 45.69220335000001]],
    [ "Enedis",[2.0079940433212995, 50.52424584717208]],
    [ "Enedis",[2.5593052009132413, 50.20766943378995]],
    [ "Enedis",[2.5730541303599375, 50.18473904945228]],
    [ "Enedis",[2.6208004538176723, 50.17535983008105]],
    [ "246107616",[-3.327060196371394, 48.593661721503]],
    [ "SNCF Réseau",[-3.550596563330395, 48.58646970116472]],
    [ "SNCF Réseau",[-3.706786800450389, 48.564032583889876]],
    [ "SNCF Réseau",[-3.852915082733272, 48.55954864252749]],
    [ "Enedis",[2.925929, 50.33485999999999]],
    [ "Enedis",[2.9317145, 50.337113249999994]],
    [ "Enedis",[2.9266222548415692, 50.33393068447448]],
    [ "Enedis",[2.574926212765957, 50.120356453900705]],
    [ "Enedis",[2.632867, 50.14831550000002]],
    [ "Enedis",[2.1790841872981703, 50.397597839971304]],
    [ "Enedis",[2.7637574861170178, 50.04961384959272]],
    [ "Enedis",[2.828871400570497, 50.085035368704155]],
    [ "Enedis",[2.819085840257649, 50.081447872302746]],
    [ "Enedis",[2.81905290220347, 50.08146252137834]],
    [ "Enedis",[6.701332, 45.89056750000001]],
    [ "Enedis",[6.721884280827129, 45.871807591584904]],
    [ "Laforet",[6.713948318122557, 45.897338437635824]],
    [ "Enedis",[6.760094453087349, 45.87667508020106]],
    [ "Vignette d Haut",[6.714195350000003, 45.894615050000006]],
    [ "Ateliers Municipaux",[6.719621749999999, 45.87188025]],
    [ "Enedis",[6.710905984773855, 45.89753256430189]],
    [ "Enedis",[6.690904484687052, 45.86824173647564]],
    [ "Fayet",[6.699841277393003, 45.90468344592434]],
    [ "Huskies",[6.71391495, 45.8856773]],
    [ "Enedis",[6.7191065, 45.89724149999999]],
    [ "Enedis",[6.686858972904661, 45.86409355465992]],
    [ "TMB",[6.710103500000001, 45.899913500000004]],
    [ "Enedis",[6.7040043118262584, 45.87387221323409]],
    [ "Compagnie du Mont Blanc",[6.702533009451607, 45.905732899841276]],
    [ "Enedis",[2.102560883936316, 50.39666704835146]],
    [ "Enedis",[2.832143320537428, 50.08627204158669]],
    [ "Enedis",[2.0960718221169032, 50.3995288311743]],
    [ "Enedis",[2.098991131652661, 50.40213473389356]],
    [ "Enedis",[2.656085946043166, 50.17980958872902]],
    [ "réséda",[6.053717018721463, 49.04784892328767]],
    [ "réséda",[6.237975935246505, 49.08354686718175]],
    [ "réséda",[6.232092292827005, 49.07690294177215]],
    [ "réséda",[6.2191956755260245, 49.076607044296786]],
    [ "réséda",[6.234626500000002, 49.075299]],
    [ "réséda",[6.239114776906319, 49.07039703355121]],
    [ "réséda",[6.2344305, 49.07076999999999]],
    [ "réséda",[6.223495499999999, 49.0750923]],
    [ "réséda",[6.223569199999999, 49.076921000000006]],
    [ "réséda",[6.230947500000001, 49.070220250000006]],
    [ "Enedis",[2.8893715863312375, 50.056742306582805]],
    [ "Enedis",[2.87952435, 50.07473550000001]],
    [ "Enedis",[2.929018956521739, 50.05945433333333]],
    [ "Enedis",[2.9284569083557956, 50.05676233872417]],
    [ "Enedis",[3.2960660735424208, 43.44907263755528]],
    [ "Enedis",[-0.9149305209201799, 46.65698836532124]],
    [ "Enedis",[-0.9146235732157915, 46.658751905511934]],
    [ "Enedis",[2.9308804567688926, 50.07657986099169]],
    [ "Enedis",[3.9381441500000007, 46.05695635]],
    [ "Enedis",[3.9361530722595073, 46.051146036912755]],
    [ "Enedis",[3.9164926171171173, 46.05249134774774]],
    [ "Enedis",[3.9309251392972238, 46.05185742550809]],
    [ "Enedis",[3.9327150651204397, 46.05141957396789]],
    [ "Enedis",[1.480177000000001, 48.5693435]],
    [ "Enedis",[1.5055613795802205, 48.56334818534331]],
    [ "La Balancerie",[6.633965151868602, 45.89561165532017]],
    [ "Champet",[6.643163387842057, 45.89190972244958]],
    [ "Enedis",[6.619014278512918, 45.89942545662676]],
    [ "246577075",[5.031914257165337, 46.2000452638876]],
    [ "Enedis",[4.752049682492944, 46.786516104601475]],
    [ "Enedis",[2.705119387976621, 50.14063480248631]],
    [ "Enedis",[2.713276666666667, 50.1449855022831]],
    [ "Enedis",[2.7140525026001043, 50.14044023712949]],
    [ "Enedis",[2.678570668103448, 50.151661969827586]],
    [ "Enedis",[2.7135390862676054, 50.138146838615015]],
    [ "Enedis",[2.715033, 50.13558200000001]],
    [ "Enedis",[2.714631778711314, 50.146803646230346]],
    [ "Enedis",[2.70563997169552, 50.13831714399561]],
    [ "Enedis",[2.709475037326387, 50.14044519618055]],
    [ "Enedis",[2.9596400828957923, 50.2936608985638]],
    [ "Enedis",[2.974618345238095, 50.05709478720239]],
    [ "Enedis",[4.750584, 46.92066900000001]],
    [ "Enedis",[4.74641455935347, 46.924431272655006]],
    [ "Enedis",[2.8429491717171724, 50.23217300808082]],
    [ "Enedis",[4.736547566666665, 46.925598699999995]],
    [ "Enedis",[4.730335498132934, 46.93833467724671]],
    [ "Enedis",[4.044992416943521, 46.25271738648948]],
    [ "Enedis",[2.9476277499999988, 50.209160000000004]],
    [ "Enedis",[2.949355253558504, 50.211364619260145]],
    [ "Enedis",[2.714278718614719, 50.22007114978355]],
    [ "Enedis",[3.061621400803213, 50.067544153413664]],
    [ "Enedis",[3.06530625, 50.067574500000006]],
    [ "Enedis",[2.708820506015176, 50.19392066500093]],
    [ "Enedis",[3.081472213155446, 50.21190373399524]],
    [ "Enedis",[3.0769452628854386, 50.21339962795409]],
    [ "Enedis",[3.0846876499999993, 50.21296205000001]],
    [ "Enedis",[3.09225761292747, 50.20647322660655]],
    [ "Enedis",[3.0903998333333336, 50.212815]],
    [ "Enedis",[3.0956668142076493, 50.20860751912569]],
    [ "Enedis",[3.0947919147506187, 50.21105222237943]],
    [ "Enedis",[3.087139620182757, 50.20866234485499]],
    [ "Enedis",[3.0843747070139096, 50.209695206570004]],
    [ "Enedis",[3.1053791149862597, 50.23658303614459]],
    [ "Enedis",[2.7783401074879226, 50.180668706521736]],
    [ "Enedis",[2.774333, 50.160686]],
    [ "Enedis",[2.772583430493274, 50.16292667115097]],
    [ "Enedis",[5.963498876777252, 46.204574672985785]],
    [ "Enedis",[5.945954330550919, 46.21353250287517]],
    [ "Enedis",[5.956682499999999, 46.2176535]],
    [ "Enedis",[5.942194000000002, 46.211821500000006]],
    [ "Enedis",[5.959124674202899, 46.20916953391305]],
    [ "Enedis",[5.957663340389138, 46.21049080543084]],
    [ "Enedis",[5.954202375438595, 46.216621803508765]],
    [ "Enedis",[5.9599804999999995, 46.203760499999994]],
    [ "Enedis",[2.798937531802501, 50.15145083139284]],
    [ "Enedis",[3.0634704999999998, 50.249306000000004]],
    [ "Enedis",[3.0687234999999995, 50.253143]],
    [ "Enedis",[2.8599667363089587, 50.184569119658136]],
    [ "Enedis",[2.8579687894893557, 50.18731645332737]],
    [ "Enedis",[6.035242702294939, 46.2797057232893]],
    [ "Enedis",[6.04052042310231, 46.28273422772277]],
    [ "Enedis",[6.053686526690392, 46.28489383748518]],
    [ "Enedis",[6.042762081370715, 46.28238084959502]],
    [ "Enedis",[3.114732170050321, 50.17610928509457]],
    [ "Enedis",[3.115980318521594, 50.17830287472315]],
    [ "Enedis",[3.1108468497854074, 50.17817930329042]],
    [ "Enedis",[3.12029705, 50.1795815]],
    [ "Enedis",[1.8688377241922778, 47.908288283827694]],
    [ "Enedis",[1.8716985274579705, 47.907900568051716]],
    [ "Enedis",[1.8788101474374035, 47.90748528321439]],
    [ "Enedis",[1.8762387695521936, 47.90647191754257]],
    [ "Enedis",[1.8756279740720827, 47.908995254548714]],
    [ "Enedis",[1.8705026580923474, 47.91028629227415]],
    [ "Enedis",[1.8697484177090367, 47.91432143998995]],
    [ "Enedis",[2.9585303814397816, 50.15783186711019]],
    [ "Enedis",[0.9300512478632478, 49.38435655555555]],
    [ "Enedis",[3.0033912602869006, 50.175189085126455]],
    [ "Enedis",[2.796616606935366, 50.123271340232385]],
    [ "Enedis",[2.7982835, 50.1273605]],
    [ "Enedis",[3.0548132442002456, 50.18205296520147]],
    [ "Enedis",[3.0510674496253123, 50.17752581681931]],
    [ "Enedis",[2.8190742500046952, 50.11585939470486]],
    [ "Enedis",[2.9010520818701777, 50.08098269606277]],
    [ "Enedis",[5.668473249999999, 46.273346000000004]],
    [ "Enedis",[5.671390000000001, 46.27542374999999]],
    [ "Enedis",[5.6857590851085655, 46.287349316392515]],
    [ "Enedis",[5.6574615, 46.273281999999995]],
    [ "Enedis",[5.670563798089507, 46.271101466616514]],
    [ "Enedis",[5.67931375, 46.28297700000001]],
    [ "Enedis",[5.676790499999999, 46.28193525]],
    [ "Enedis",[5.674105500000001, 46.27496675]],
    [ "Perma",[5.6587857, 46.27198899999999]],
    [ "Enedis",[5.681165, 46.29549475]],
    [ "Enedis",[5.6772181, 46.28310545000001]],
    [ "Enedis",[5.6581863839250435, 46.27595255430602]],
    [ "Enedis",[5.674206999999999, 46.277099500000006]],
    [ "Enedis",[5.6690802019837685, 46.2779178733093]],
    [ "Enedis",[5.68286675, 46.29411725]],
    [ "Enedis",[5.654968, 46.273039]],
    [ "Enedis",[5.672698556752135, 46.27243810216242]],
    [ "Enedis",[5.681053231530602, 46.28833585382056]],
    [ "Enedis",[5.658874121955718, 46.27427245586262]],
    [ "Enedis",[5.68089995, 46.2750233]],
    [ "Enedis",[5.6838961394865315, 46.297519328541576]],
    [ "Enedis",[5.6772986, 46.275329400000004]],
    [ "Enedis",[5.665338252388078, 46.27322493394988]],
    [ "Enedis",[5.671276749999999, 46.27567975]],
    [ "Enedis",[5.676015665275741, 46.289919089530116]],
    [ "Enedis",[5.683949893917942, 46.2907525826856]],
    [ "Enedis",[5.67796165, 46.278842900000015]],
    [ "Enedis",[5.665944352589695, 46.27226121678619]],
    [ "Enedis",[5.679204050000001, 46.29188100000001]],
    [ "Enedis",[5.667468455425734, 46.26957715749016]],
    [ "Enedis",[5.664191161931698, 46.270498589166515]],
    [ "Enedis",[5.6757627, 46.28505985]],
    [ "Enedis",[5.66055575, 46.3194285]],
    [ "Enedis",[5.650858408845732, 46.317976216074875]],
    [ "Enedis",[5.6570544499999995, 46.320612800000006]],
    [ "Enedis",[5.6476093, 46.30761205]],
    [ "Enedis",[5.65990253807291, 46.32068733484796]],
    [ "Enedis",[5.65454405, 46.30548935]],
    [ "Enedis",[5.662414973221409, 46.323575303892525]],
    [ "Enedis",[1.9271445428934735, 47.9475977972226]],
    [ "Enedis",[1.921914972808346, 47.94157198325661]],
    [ "Enedis",[1.924174755270798, 47.938300346612756]],
    [ "Enedis",[1.9351789515576865, 47.939541641873866]],
    [ "Enedis",[1.9193248472467135, 47.94846880934442]],
    [ "Enedis",[1.9363651989111705, 47.936259449895104]],
    [ "Enedis",[1.9221959543717495, 47.93784419334533]],
    [ "Enedis",[1.9334389319342102, 47.94102079098943]],
    [ "Enedis",[1.9213179279360655, 47.93933279684892]],
    [ "Enedis",[1.9400133360792016, 47.938056983575514]],
    [ "Enedis",[1.9200805839954065, 47.94305441201857]],
    [ "Enedis",[1.9199028500000004, 47.95109160000001]],
    [ "Enedis",[1.92652906505647, 47.94012961809251]],
    [ "Enedis",[1.9247282999999997, 47.95057250000001]],
    [ "Enedis",[1.9309196406422309, 47.9450787013148]],
    [ "Enedis",[1.9240094616864616, 47.94058663607632]],
    [ "Enedis",[1.9081566724778813, 47.939680425626854]],
    [ "Enedis",[1.90398886009696, 47.9412496715473]],
    [ "Enedis",[1.9044365652065365, 47.93877664833382]],
    [ "Enedis",[1.9139411511274067, 47.937907660436416]],
    [ "Enedis",[1.9081539225167716, 47.94119220162171]],
    [ "Enedis",[3.1038064999999997, 50.143657000000005]],
    [ "Enedis",[1.9056093929209759, 47.934692824064506]],
    [ "Enedis",[1.8959119933661688, 47.93650734048368]],
    [ "Enedis",[1.9052546648634554, 47.932435107372584]],
    [ "Enedis",[3.109989507832639, 50.14502122645251]],
    [ "Enedis",[3.10805060629345, 50.14470279290158]],
    [ "Enedis",[1.9305657255873516, 47.93009414510816]],
    [ "Enedis",[1.9157443940001941, 47.940180325717826]],
    [ "Enedis",[1.9116024006044137, 47.927889562960104]],
    [ "Enedis",[1.9113428805794879, 47.93549928914008]],
    [ "Enedis",[1.9168358613948646, 47.94500945978331]],
    [ "Enedis",[1.9242186071343352, 47.929364079465]],
    [ "Enedis",[1.9108994804591146, 47.9330186763434]],
    [ "Enedis",[1.9138791386004397, 47.93638451045278]],
    [ "Enedis",[1.9285920939332446, 47.93411539600076]],
    [ "Enedis",[1.9234941680726227, 47.93570809348431]],
    [ "Enedis",[1.9085008315715295, 47.93682179485554]],
    [ "Enedis",[1.908247813000083, 47.923308420934035]],
    [ "Enedis",[1.916392261425192, 47.94285928733581]],
    [ "Enedis",[1.9201825821827405, 47.93072798886873]],
    [ "Enedis",[1.8975540928534653, 47.929445276881886]],
    [ "Enedis",[1.9151118124597861, 47.926566326495575]],
    [ "Enedis",[1.921390260247796, 47.9262411222795]],
    [ "Enedis",[1.9078139373274352, 47.93280508020189]],
    [ "Enedis",[1.9157041337318397, 47.928463266903684]],
    [ "Enedis",[1.921506797198951, 47.92460236662247]],
    [ "Enedis",[1.90978215, 47.92544620000001]],
    [ "Enedis",[1.9184470175945902, 47.91979703947038]],
    [ "Enedis",[1.933921198628277, 47.93107873905782]],
    [ "Enedis",[1.9153802614446613, 47.93106811276849]],
    [ "Enedis",[1.9170654398172091, 47.94756910524227]],
    [ "Enedis",[1.9001332228553098, 47.928340464669354]],
    [ "Enedis",[1.9068952742510992, 47.929850761803365]],
    [ "Enedis",[1.9236898134769425, 47.933101118394134]],
    [ "Enedis",[1.8956635659119325, 47.930981279103584]],
    [ "Enedis",[1.9139211610792277, 47.93435869596272]],
    [ "Enedis",[1.9297035907176083, 47.935323251485045]],
    [ "Enedis",[1.9212377946074002, 47.928560870027795]],
    [ "Enedis",[1.9251184092029674, 47.92792556844606]],
    [ "Enedis",[3.042096628282828, 50.20619501919191]],
    [ "Enedis",[3.0528406981132075, 50.207573477987424]],
    [ "Enedis",[3.0546834438040342, 50.20640382901057]],
    [ "Enedis",[3.0651752039561617, 50.21450635418338]],
    [ "Enedis",[3.0622515623391817, 50.21289918947369]],
    [ "Enedis",[3.0531671275080927, 50.21018184970875]],
    [ "Enedis",[2.913870509307521, 50.14909485852569]],
    [ "Enedis",[2.905894, 50.1503555]],
    [ "Enedis",[2.9032247, 50.1523689]],
    [ "Enedis",[2.904809066807314, 50.15304412869198]],
    [ "Enedis",[2.900097152249135, 50.15301080046136]],
    [ "Enedis",[2.9071317000000003, 50.1470968]],
    [ "Enedis",[2.9027796847389555, 50.14316566566265]],
    [ "Enedis",[2.8234840077625574, 50.15995666643837]],
    [ "Enedis",[3.040156804409449, 50.2889545408924]],
    [ "Enedis",[3.034507699999998, 50.28973575]],
    [ "Enedis",[3.0325375000000006, 50.291058500000005]],
    [ "Enedis",[3.0435955406638353, 50.288358689161555]],
    [ "Enedis",[2.932924560460653, 50.16992617786308]],
    [ "Enedis",[2.9109959333333335, 50.1823311]],
    [ "Enedis",[2.928044170388251, 50.19111643170388]],
    [ "Enedis",[2.9293254546291725, 50.19380648227264]],
    [ "Enedis",[2.92489216555974, 50.19297562427252]],
    [ "Enedis",[2.9252144680483587, 50.306973066493946]],
    [ "Enedis",[2.9223821452991454, 50.30700667887668]],
    [ "Enedis",[2.9568676182676703, 50.13884027858935]],
    [ "Enedis",[2.9331809827871558, 50.119417121593294]],
    [ "Enedis",[2.968500881326352, 50.11371734031414]],
    [ "Enedis",[2.966952859702631, 50.111317820307534]],
    [ "Enedis",[2.9579215043556153, 50.20229534174225]],
    [ "Enedis",[2.957331629368221, 50.199104146449436]],
    [ "Enedis",[2.9583724272517316, 50.200294965357976]],
    [ "Enedis",[2.9927537427652737, 50.28833404876742]],
    [ "Enedis",[3.077888687542779, 50.10876908247776]],
    [ "Enedis",[3.0863847981438517, 50.11021345939674]],
    [ "Enedis",[3.083433850055127, 50.110863977949286]],
    [ "Enedis",[2.9874094745998616, 50.088293196938075]],
    [ "Enedis",[2.9753764944483816, 50.086051833215215]],
    [ "Enedis",[2.9819732991566803, 50.08535729782513]],
    [ "Enedis",[2.9763061557247066, 50.0885293016175]],
    [ "Enedis",[2.977420684249084, 50.08410397582418]],
    [ "Enedis",[2.980900038325941, 50.08929427317184]],
    [ "Enedis",[3.0926977847314685, 50.08908423139517]],
    [ "Enedis",[3.0352264071449095, 50.243090503278346]],
    [ "Enedis",[3.041101476190476, 50.243604999999995]],
    [ "Enedis",[2.9812565, 50.120711]],
    [ "Enedis",[2.984419302380952, 50.119020042857144]],
    [ "Enedis",[3.0242273557234816, 50.0697151063532]],
    [ "PIAN-MEDOC (LE)",[-0.626107475605969, 44.9482827817445]],
    [ "Enedis",[3.014982503581661, 50.22442096991404]],
    [ "Enedis",[3.0102904654970764, 50.222149080701755]],
    [ "SNCF Réseau",[-4.2831776911484924, 48.43992640772256]],
    [ "Enedis",[3.0118285, 50.08173349999999]],
    [ "SNCF Réseau",[-4.443920549999996, 48.394768]],
    [ "Enedis",[2.941940704879482, 50.31267257260434]],
    [ "Enedis",[2.934499453329399, 50.31433767178126]],
    [ "Enedis",[2.9443837628930822, 50.31870281886792]],
    [ "Enedis",[2.943911599785639, 50.31120251789925]],
    [ "Enedis",[2.941516574845959, 50.315350809230395]],
    [ "Enedis",[2.9473642249426866, 50.30989824750115]],
    [ "Enedis",[2.944439540370144, 50.30974894915599]],
    [ "Enedis",[2.9469315926786743, 50.31204630989735]],
    [ "Enedis",[2.9367857822588284, 50.310819152608175]],
    [ "Enedis",[2.947975073464913, 50.30782450219299]],
    [ "Enedis",[2.933360202170963, 50.312183857078246]],
    [ "Enedis",[2.9514283526641885, 50.31095795861214]],
    [ "réséda",[6.2438062121212115, 49.11607167676768]],
    [ "réséda",[6.247593, 49.113937500000006]],
    [ "réséda",[6.247807384425681, 49.109569767785416]],
    [ "réséda",[6.244262297680459, 49.109802224999044]],
    [ "réséda",[6.247683744588745, 49.11205958874459]],
    [ "réséda",[6.2511127633758665, 49.114027424445936]],
    [ "réséda",[6.241722317959468, 49.114700757512225]],
    [ "réséda",[6.2471171309577205, 49.11622680095206]],
    [ "réséda",[6.237254346820808, 49.1014592273603]],
    [ "réséda",[6.239441500000001, 49.0922485]],
    [ "réséda",[6.24268841663936, 49.106596533305]],
    [ "réséda",[6.241493033193571, 49.09304166404612]],
    [ "réséda",[6.2432444, 49.1018039]],
    [ "réséda",[6.240499794084773, 49.099744154930775]],
    [ "réséda",[6.244561606965173, 49.09129973631841]],
    [ "réséda",[6.244596499999999, 49.0937355]],
    [ "réséda",[6.244603000000001, 49.097682999999996]],
    [ "réséda",[6.245656899999999, 49.106820750000004]],
    [ "réséda",[6.247340406630017, 49.09643326336832]],
    [ "réséda",[6.249976500000001, 49.094792000000005]],
    [ "réséda",[6.2399563977216435, 49.10315415799901]],
    [ "réséda",[6.236067423236516, 49.09237623098201]],
    [ "réséda",[6.243758639534883, 49.09187445008509]],
    [ "réséda",[6.243183321928161, 49.09336826989582]],
    [ "réséda",[6.247190950589224, 49.09312595858586]],
    [ "réséda",[6.241512001952951, 49.089648432046154]],
    [ "réséda",[6.246040312276945, 49.09045776632762]],
    [ "Enedis",[2.9518936923330044, 50.2470577413623]],
    [ "Enedis",[2.959382390344698, 50.251430359256126]],
    [ "Enedis",[2.9154375000000003, 50.289771]],
    [ "Enedis",[2.9124243175647178, 50.291686209380195]],
    [ "Enedis",[2.9540906448117816, 50.253550866885575]],
    [ "Enedis",[2.9585905894783755, 50.25330397092768]],
    [ "réséda",[6.200696849279162, 49.09701379729141]],
    [ "réséda",[6.196648216550658, 49.09908025599382]],
    [ "réséda",[6.191609333333334, 49.099840666666665]],
    [ "réséda",[6.201380886765747, 49.09952796783117]],
    [ "réséda",[6.203778428335279, 49.10227218368339]],
    [ "réséda",[6.2054505464862775, 49.10815573359494]],
    [ "réséda",[6.196621640085272, 49.111890800462504]],
    [ "réséda",[6.1946889999999994, 49.1035765]],
    [ "Pont Lothaire",[6.1875112506082734, 49.10286144525548]],
    [ "réséda",[6.187063310730744, 49.108867737327195]],
    [ "réséda",[6.189090330892542, 49.10603894976787]],
    [ "réséda",[6.193893884041548, 49.10774150544539]],
    [ "Enedis",[2.937983440503144, 50.24747280050314]],
    [ "Enedis",[2.8933605310734456, 50.305282935028245]],
    [ "Enedis",[2.897670404718694, 50.30571021355112]],
    [ "Enedis",[2.896492962616822, 50.29700817133957]],
    [ "Enedis",[2.8949948103881202, 50.30212072733525]],
    [ "Enedis",[2.8945457893575957, 50.30054123490488]],
    [ "Enedis",[2.9025386167315177, 50.295815552204935]],
    [ "Enedis",[2.907692, 50.29863700000001]],
    [ "Enedis",[2.573802176689976, 50.37567883636363]],
    [ "réséda",[6.208898008049304, 49.115565322460895]],
    [ "réséda",[6.1966105, 49.113715000000006]],
    [ "réséda",[6.192677779406237, 49.11451103156708]],
    [ "réséda",[6.1991553016682746, 49.11349848595983]],
    [ "Poste électrique de Port-Jérôme",[0.5512093847308147, 49.482260090374595]],
    [ "Enedis",[6.610323023831163, 48.69852483525441]],
    [ "réséda",[6.221394999999999, 49.1126925]],
    [ "réséda",[6.224714201302932, 49.10992407643865]],
    [ "réséda",[6.23225421926489, 49.111512982256016]],
    [ "réséda",[6.229028263452642, 49.109377113708305]],
    [ "réséda",[6.232223485319515, 49.11318173723168]],
    [ "réséda",[6.220658499999999, 49.113981]],
    [ "réséda",[6.228763471501762, 49.11025452529619]],
    [ "Enedis",[2.971545226887473, 50.26545139370387]],
    [ "Enedis",[2.5357511748037527, 50.205068022018004]],
    [ "Enedis",[2.5286824883628007, 50.217431274498054]],
    [ "Enedis",[2.5299761029738126, 50.20986878894807]],
    [ "Enedis",[3.0413043371735786, 50.11382346831797]],
    [ "Enedis",[3.036942581251927, 50.11335271384522]],
    [ "Enedis",[2.0794791956521737, 50.27961876086957]],
    [ "Enedis",[2.072076971184625, 50.25877166749258]],
    [ "réséda",[6.215111816995701, 49.11882895052461]],
    [ "réséda",[6.205633699999997, 49.1185192]],
    [ "réséda",[6.204967035408756, 49.11836555134845]],
    [ "réséda",[6.201005500000001, 49.11818649999999]],
    [ "Enedis",[2.388153135837171, 50.50872294229062]],
    [ "SNCF Réseau",[-3.843799323004107, 47.96080220107095]],
    [ "réséda",[6.220086500000001, 49.095319999999994]],
    [ "réséda",[6.22283156937746, 49.09568226215423]],
    [ "réséda",[6.221432, 49.094241000000004]],
    [ "Enedis",[6.386210117053482, 48.56748033736966]],
    [ "Enedis",[6.38380781460593, 48.55814248329718]],
    [ "Enedis",[6.3745545, 48.549136000000004]],
    [ "Enedis",[6.381884182067076, 48.56966152908967]],
    [ "Enedis",[6.388866834278512, 48.56410448204159]],
    [ "Enedis",[6.373098702302148, 48.560666548951]],
    [ "Enedis",[6.392383, 48.562336]],
    [ "Enedis",[6.392907790798611, 48.552190164062495]],
    [ "Enedis",[6.377326887086427, 48.55414918155989]],
    [ "Enedis",[6.377691693388146, 48.55700291538198]],
    [ "Enedis",[6.380158023569024, 48.552621831649844]],
    [ "Enedis",[6.384955673978494, 48.55658390494623]],
    [ "Enedis",[6.386638999999999, 48.554560499999994]],
    [ "Enedis",[6.38221616990921, 48.55162801167315]],
    [ "Enedis",[6.3883329218048654, 48.55678760926057]],
    [ "Enedis",[6.4017212772934835, 48.55392504856787]],
    [ "Enedis",[6.422848758169935, 48.555232091503264]],
    [ "Enedis",[6.418166122222222, 48.54203424166666]],
    [ "Enedis",[6.415001999999999, 48.5555725]],
    [ "Enedis",[6.407091131690445, 48.55635628690012]],
    [ "Enedis",[6.398862937984497, 48.56101267054264]],
    [ "Enedis",[6.4092934999999995, 48.5642625]],
    [ "Enedis",[6.413910143884891, 48.55234774340528]],
    [ "Enedis",[6.392539, 48.549109]],
    [ "Enedis",[6.399955878004311, 48.55596677805404]],
    [ "Enedis",[6.407234387002098, 48.553375438784066]],
    [ "Enedis",[6.4106732239185735, 48.55701367811705]],
    [ "Enedis",[6.410288477531857, 48.55852164453387]],
    [ "Enedis",[6.411812098621878, 48.565000402670115]],
    [ "Enedis",[6.410299973068745, 48.552478436569814]],
    [ "Enedis",[6.394559360752322, 48.55567718445502]],
    [ "Enedis",[2.2140546448014278, 50.22866575368138]],
    [ "Enedis",[2.2114585, 50.22935950000001]],
    [ "Enedis",[2.199296522045856, 50.30093779717813]],
    [ "Enedis",[2.20496, 50.30249249999999]],
    [ "Enedis",[2.5052544999999986, 50.24225699999999]],
    [ "Enedis",[2.4992228281724347, 50.24075336794171]],
    [ "Enedis",[2.2651970039331375, 50.24152467354965]],
    [ "Enedis",[2.284209195505618, 50.278432479900125]],
    [ "Enedis",[2.2834971164102558, 50.27814005743589]],
    [ "Enedis",[2.2774824999999996, 50.275375000000004]],
    [ "Enedis",[2.287895522807018, 50.27314543508771]],
    [ "Enedis",[2.2902548178755455, 50.26659957074911]],
    [ "Enedis",[2.2913091334610467, 50.27297994220946]],
    [ "Enedis",[2.2958811039812645, 50.28207630241999]],
    [ "Enedis",[2.2938107999999997, 50.28175959090908]],
    [ "Enedis",[2.2948943547728438, 50.281340749361924]],
    [ "Enedis",[2.295824344252672, 50.27787267512035]],
    [ "Enedis",[2.2986929627696586, 50.27460815692415]],
    [ "Enedis",[2.2940589893205505, 50.276851391103264]],
    [ "Enedis",[2.2928905000000013, 50.2756545]],
    [ "Enedis",[2.2928673230055376, 50.27745143668612]],
    [ "Enedis",[2.2840702618453865, 50.27586623025769]],
    [ "Enedis",[2.292985775053879, 50.278843362966946]],
    [ "Enedis",[2.2860139463869467, 50.27825410722612]],
    [ "Enedis",[2.2884745913978506, 50.27671425806452]],
    [ "Vignette",[5.735474753555878, 46.17756226303822]],
    [ "Enedis",[2.730854089253798, 48.56274868632708]],
    [ "Enedis",[2.7400487208835345, 48.56097294626507]],
    [ "Enedis",[-3.967669708991249, 48.22524057201003]],
    [ "Rue des Écoles",[-3.967766374134508, 48.22947624497028]],
    [ "Enedis",[-3.985791148316256, 48.19105875604829]],
    [ "Enedis",[2.2332382605420897, 50.37486452206063]],
    [ "Enedis",[2.4704869069767446, 50.25821266666666]],
    [ "Enedis",[3.446702, 47.863284]],
    [ "Enedis",[3.4513253429564292, 47.86898482999198]],
    [ "Enedis",[3.540218851443033, 47.95528083396511]],
    [ "Enedis",[3.524471820485747, 47.95128828546287]],
    [ "Enedis",[3.531277, 47.955639999999995]],
    [ "Enedis",[3.5319398397790063, 47.95009804604052]],
    [ "Enedis",[3.529102668240851, 47.953387874458876]],
    [ "Enedis",[3.5366710813492057, 47.952161591269835]],
    [ "Enedis",[3.5266005230125526, 47.9495362137876]],
    [ "Enedis",[3.5267209781042923, 47.951214717084405]],
    [ "Enedis",[3.5726853233399947, 47.95037160093271]],
    [ "Enedis",[3.575225005710207, 47.95595627266239]],
    [ "Enedis",[3.5766923237895707, 47.967456707867775]],
    [ "Enedis",[5.9300394999999995, 49.239729]],
    [ "Enedis",[5.9424865, 49.247099000000006]],
    [ "Enedis",[5.931788062006502, 49.24109348536925]],
    [ "Enedis",[2.3389560997042693, 50.264843174904954]],
    [ "SNCF Réseau",[-3.7046021439946957, 47.92545460987774]],
    [ "SNCF Réseau",[-3.553440046370301, 47.86980516504808]],
    [ "Enedis",[-0.8081552499999999, 46.50396225]],
    [ "Enedis",[-0.8060147170159577, 46.49591023432528]],
    [ "Enedis",[-0.8066810236889188, 46.47645823603377]],
    [ "Enedis",[-0.8080496481385656, 46.520514516314506]],
    [ "Enedis",[2.2736845, 50.3702865]],
    [ "Enedis",[3.3593414000000004, 47.953007]],
    [ "Enedis",[3.439086281333334, 47.993478936]],
    [ "Enedis",[3.4407462299044407, 47.99232995896571]],
    [ "Enedis",[3.6595071347012373, 48.01996391988435]],
    [ "Enedis",[3.6686965376344096, 48.02581181720431]],
    [ "Enedis",[3.6761617081199707, 48.01144752694465]],
    [ "Enedis",[3.6807462702222216, 48.02140249955556]],
    [ "Enedis",[2.5823663456354295, 50.29066400438596]],
    [ "Enedis",[3.7697196703567046, 48.089648836162375]],
    [ "Enedis",[3.761145301454473, 48.106219342299255]],
    [ "Enedis",[3.774685158556769, 48.086380404501824]],
    [ "Enedis",[2.5475639683059144, 50.29452070137506]],
    [ "Enedis",[2.547351, 50.29182049999999]],
    [ "Enedis",[2.585583522150323, 50.323151297494604]],
    [ "Enedis",[2.4508995000000002, 50.27238350000001]],
    [ "Enedis",[2.4437991327350903, 50.37713331164703]],
    [ "Enedis",[2.4442679999999983, 50.3589075]],
    [ "Enedis",[2.4285271493084966, 50.39828406915043]],
    [ "Enedis",[2.4541385978815144, 48.95056115457522]],
    [ "Enedis",[2.426918150477445, 50.28121019086818]],
    [ "Enedis",[2.429688156603775, 50.26693418207546]],
    [ "Enedis",[2.5102471564291564, 50.296488950859946]],
    [ "réséda",[6.4226235, 49.05588850000001]],
    [ "Poste électrique de Malesherbes",[2.4000239883510797, 48.28307781177641]],
    [ "Enedis",[2.4928913414634137, 50.317725674796755]],
    [ "Enedis",[3.3737866109134047, 47.98795661803085]],
    [ "Enedis",[3.347238613683482, 47.99857567634185]],
    [ "Enedis",[3.346561019023986, 48.001001366418535]],
    [ "Enedis",[3.328566800877193, 48.009600157675436]],
    [ "Enedis",[2.5291477385844745, 50.31374967786815]],
    [ "Enedis",[2.4784059999999997, 50.3246885]],
    [ "Enedis",[2.484068000000001, 50.329635499999995]],
    [ "Enedis",[-0.7918631, 46.5949374]],
    [ "Enedis",[-0.7940288646936706, 46.589943244299704]],
    [ "Enedis",[-0.8156582500000001, 46.598021749999994]],
    [ "Enedis",[2.484891182389937, 50.374450924528304]],
    [ "Enedis",[6.254695101575368, 48.82431475352667]],
    [ "Enedis",[6.247926406569737, 48.821988244480345]],
    [ "Enedis",[6.253708873789172, 48.61644923988603]],
    [ "Enedis",[6.265992036986301, 48.614457862100444]],
    [ "Enedis",[6.233506161172162, 48.62150233100235]],
    [ "Enedis",[6.25561743071392, 48.61209765511044]],
    [ "Enedis",[6.253203499999999, 48.63018300000001]],
    [ "Enedis",[6.26919732327297, 48.602825393024816]],
    [ "Enedis",[6.264174171578258, 48.600857025540286]],
    [ "Enedis",[6.267548839383938, 48.60186533223322]],
    [ "Enedis",[6.281824059259259, 48.58670467777778]],
    [ "Enedis",[6.3467791, 48.55433360000001]],
    [ "Enedis",[6.341420945355192, 48.55591718306011]],
    [ "Enedis",[6.354193000000001, 48.55766050000001]],
    [ "Enedis",[6.333037257861636, 48.56164296226415]],
    [ "Enedis",[6.328794968743023, 48.56125381670016]],
    [ "Enedis",[6.234507603100774, 48.614395215503876]],
    [ "Enedis",[2.398457961538462, 50.460139852564105]],
    [ "Enedis",[2.42407888237792, 50.36436230219391]],
    [ "Enedis",[-0.7533371626027572, 46.63334553410335]],
    [ "Enedis",[-0.7538835743135723, 46.63034765600507]],
    [ "Enedis",[-0.7695059, 46.601893249999996]],
    [ "Enedis",[3.1279836729559753, 47.82098603773586]],
    [ "Enedis",[2.2488707368637115, 50.448254077996715]],
    [ "Enedis",[-0.28850205, 43.199564149999986]],
    [ "Enedis",[-3.928964799440955, 47.956703583344854]],
    [ "Enedis",[2.502398277893278, 50.423612289674296]],
    [ "Enedis",[2.5047563507212764, 50.427744139747546]],
    [ "Enedis",[2.5074355083798885, 50.43074840782123]],
    [ "Enedis",[-0.2375976319591256, 43.18491310627871]],
    [ "Enedis",[2.3184080000000002, 50.375959]],
    [ "Enedis",[2.312970271541367, 50.374813955372474]],
    [ "Enedis",[2.314700674278975, 50.3734483379436]],
    [ "Enedis",[5.781271000000001, 46.091196000000004]],
    [ "Enedis",[5.784149766690091, 46.08654517263996]],
    [ "Enedis",[5.29380790788225, 45.92305266959266]],
    [ "Enedis",[-0.7011928000000001, 46.59328024999999]],
    [ "Enedis",[-0.7241183261925784, 46.60221966526892]],
    [ "Enedis",[6.649530666666668, 48.33488093055555]],
    [ "Enedis",[5.94048996388029, 48.293014159958716]],
    [ "Enedis",[5.253012299999999, 45.89147739999999]],
    [ "Enedis",[5.260159300000001, 45.896524850000006]],
    [ "Enedis",[5.261355200000001, 45.880556100000014]],
    [ "Enedis",[3.5360645215069066, 44.37154580359512]],
    [ "Enedis",[3.539117048406621, 44.37104349650756]],
    [ "Enedis",[1.683019039936859, 48.98721339444883]],
    [ "Enedis",[3.42534026874536, 47.12999984187081]],
    [ "Enedis",[6.581296409620067, 46.08819546348176]],
    [ "Enedis",[6.630688682005899, 46.09482897876106]],
    [ "Enedis",[6.643210000000001, 46.0930595]],
    [ "Enedis",[1.0397055000000002, 49.403163500000005]],
    [ "Enedis",[0.2258698700036007, 49.22720230992678]],
    [ "Enedis",[0.2244652274143302, 49.23254554917667]],
    [ "Enedis",[0.2233148104417665, 49.22894582706827]],
    [ "Enedis",[6.230920403040466, 48.677769865761235]],
    [ "Enedis",[6.228734181729302, 48.67715194506086]],
    [ "Enedis",[6.231333050000001, 48.67936275]],
    [ "Enedis",[6.22669025, 48.67995205]],
    [ "Enedis",[3.028594, 49.365347]],
    [ "Enedis",[1.2623849999999999, 49.39246550000001]],
    [ "Enedis",[1.2705608939974458, 49.39356979438059]],
    [ "Balmettes",[6.1231712499999995, 45.8966453]],
    [ "Cabaud",[6.117846669408632, 45.90790482115838]],
    [ "Dessous Novel",[6.137396802708617, 45.91518966687554]],
    [ "L'Abbaye",[6.211260222568021, 45.84072985768488]],
    [ "Le Coteau",[6.1316416634221484, 45.91126783949457]],
    [ "Le Président",[6.138007399999999, 45.90468555]],
    [ "Manutent",[6.129485249999999, 45.91459995]],
    [ "Pianfetti",[6.1129799642075655, 45.88586451468688]],
    [ "SIC",[6.1208004475659585, 45.89059187690543]],
    [ "Saint-Michel",[6.122108813608566, 45.891034265875746]],
    [ "Lotissement Dunoyer",[6.174399115256985, 45.89066495460185]],
    [ "Enedis",[6.141461719636668, 45.90552475167683]],
    [ "Enedis",[2.949898556600979, 48.37613553368998]],
    [ "Enedis",[2.9290435637344845, 48.37553699131139]],
    [ "Enedis",[2.9568409526722186, 48.36902517182811]],
    [ "Enedis",[2.9330824950956846, 48.37710815145401]],
    [ "Enedis",[2.767402408201114, 48.43380822493282]],
    [ "Enedis",[2.771340979281137, 48.425570709409826]],
    [ "Enedis",[2.7565113047817316, 48.43036521963047]],
    [ "Enedis",[2.7537233790692643, 48.44001256541372]],
    [ "Enedis",[2.7523333072611984, 48.43345718790613]],
    [ "Enedis",[2.7563702558606704, 48.43443991092653]],
    [ "Enedis",[-0.25113914876690524, 43.08741659029435]],
    [ "Enedis",[-1.3287965000000002, 43.68534644999999]],
    [ "Enedis",[-1.3292021999999994, 43.69284865000001]],
    [ "Enedis",[-1.3265345000000004, 43.6841745]],
    [ "Enedis",[-1.3213300295829622, 43.68775062024215]],
    [ "Enedis",[-1.3326765, 43.690015249999995]],
    [ "Enedis",[-1.3608353227760852, 43.56610135640173]],
    [ "Enedis",[-1.3512405445291011, 43.56068381773315]],
    [ "Enedis",[-0.7762519756097561, 43.48168717886179]],
    [ "Enedis",[-1.3024584500000003, 43.54633205000001]],
    [ "Enedis",[-1.3163626232439345, 43.54992628735632]],
    [ "Enedis",[-1.3195676499999986, 43.552447599999994]],
    [ "Enedis",[-1.264117121212121, 43.53146633333334]],
    [ "Enedis",[-1.2856279999999993, 43.52030340000001]],
    [ "Enedis",[-1.2692283331174128, 43.53386396787029]],
    [ "Enedis",[-1.177900307291666, 43.567373947916664]],
    [ "Enedis",[-1.1785640000000006, 43.564594500000005]],
    [ "Enedis",[5.894027845713117, 49.27019905627173]],
    [ "Enedis",[6.4971190000000005, 48.568698]],
    [ "Enedis",[6.492278000000001, 48.570176499999995]],
    [ "Enedis",[6.491984700000001, 48.56814889999999]],
    [ "Enedis",[6.4919434164989624, 48.56892734763591]],
    [ "Enedis",[6.5022474664962795, 48.56667500175158]],
    [ "Salle des fêtes",[0.42901372785848957, 47.837686527468605]],
    [ "Enedis",[-0.8357810000000001, 46.6379795]],
    [ "Enedis",[-0.8009741999999991, 46.64415150000001]],
    [ "Enedis",[-0.8045760500000003, 46.64626875]],
    [ "Enedis",[-0.8056679915429201, 46.643701219217]],
    [ "Crenans Village",[5.7358715500000015, 46.45749405]],
    [ "Coulouvre",[5.743283552249905, 46.465267203310646]],
    [ "Châtel de Joux",[5.794435886363958, 46.52682331871421]],
    [ "Les Perrières",[5.944950500000001, 46.57309845]],
    [ "Enedis",[5.98261392705015, 46.73267609668948]],
    [ "Enedis",[5.981648559620881, 46.734292841077014]],
    [ "Enedis",[5.046429406846209, 47.365184095068635]],
    [ "Pont de la Chaux",[5.946674334785766, 46.65674984604212]],
    [ "Autistes",[5.952966000000001, 46.668611999999996]],
    [ "Cornu",[5.951788509431621, 46.665942004715816]],
    [ "Enedis",[5.942988549714711, 46.65445269701881]],
    [ "Enedis",[5.970641827094476, 46.65751552228164]],
    [ "Enedis",[5.939735589011558, 46.65492883566727]],
    [ "Enedis",[5.97051655, 46.6427278]],
    [ "Enedis",[5.976999522254648, 46.64397240520362]],
    [ "Enedis",[5.88310125, 46.69973550000001]],
    [ "Enedis",[5.865802300000001, 46.7172239]],
    [ "Enedis",[5.978286600000001, 46.743326100000004]],
    [ "Enedis",[1.8581801500000001, 48.106625099999995]],
    [ "Enedis",[5.8243005499999985, 46.590656450000004]],
    [ "Enedis",[5.847619279760525, 46.599211583307145]],
    [ "Enedis",[5.8162472604207895, 46.75370400078648]],
    [ "Enedis",[5.815824497840816, 46.74750224958427]],
    [ "249887700",[5.807611749999998, 46.7569495]],
    [ "Enedis",[5.996921332100609, 46.66670850503033]],
    [ "Enedis",[4.173404981744592, 46.78678216386296]],
    [ "RTE",[0.9929392580778361, 43.04028801187158]],
    [ "Enedis",[-1.1167152554067459, 43.51614445027777]],
    [ "Enedis",[-1.1553483333333334, 43.556612]],
    [ "Enedis",[-1.1412124999999993, 43.559388000000006]],
    [ "Enedis",[-1.1475724872074031, 43.55190482870623]],
    [ "Enedis",[-1.1598216759474091, 43.55585000850735]],
    [ "Enedis",[-1.1260992000000014, 43.5485749]],
    [ "Enedis",[-1.0920850942528741, 43.54867488390804]],
    [ "Enedis",[-1.1177615285895803, 43.549958815332495]],
    [ "Enedis",[-1.092630908045978, 43.54587275862068]],
    [ "Enedis",[-1.115037174358974, 43.546215663003665]],
    [ "Enedis",[-1.08951122080486, 43.546550591799544]],
    [ "Enedis",[-1.1097750326445044, 43.54742163492064]],
    [ "Enedis",[-1.102442, 43.5405685]],
    [ "La vigne aux chats",[6.107860207840004, 46.31411078793081]],
    [ "Enedis",[6.111045796942778, 46.31548003464922]],
    [ "Enedis",[6.115796193100633, 46.31462979506022]],
    [ "Les gelées",[6.113717497809366, 46.317374044656674]],
    [ "Les Choulets",[6.117501367651868, 46.314182942638844]],
    [ "Pommerets",[6.110879497026168, 46.31135832451862]],
    [ "Tournier",[6.109985755129415, 46.316750131277004]],
    [ "Le Reculet",[6.104540249221888, 46.30686355497853]],
    [ "Enedis",[5.831450323982816, 46.71046483173473]],
    [ "Enedis",[5.83672125, 46.70902725]],
    [ "Enedis",[6.118796688114387, 48.407384533512065]],
    [ "Enedis",[5.8128350048914745, 46.73389475928162]],
    [ "Gare",[5.77745263343839, 46.72344330336181]],
    [ "Enedis",[5.775007304679641, 46.720985207070186]],
    [ "Enedis",[5.80061332384954, 46.7270305035376]],
    [ "Enedis",[5.782988468601306, 46.727165893624196]],
    [ "Enedis",[5.7854996000000005, 46.72001345000001]],
    [ "Enedis",[5.070731331322429, 47.32414495534009]],
    [ "Enedis",[1.0494199340801385, 49.4702061518024]],
    [ "Enedis",[5.8284495867272765, 46.62629059688869]],
    [ "Enedis",[5.758340385187773, 46.64786919442135]],
    [ "Enedis",[5.750079253736739, 46.62708109551851]],
    [ "Enedis",[5.751041064888035, 46.63493287020704]],
    [ "Reverbery",[5.768421909797491, 46.62524414647382]],
    [ "Enedis",[5.813163681885507, 46.63463850327418]],
    [ "Val Dessous",[5.82187925, 46.62058925]],
    [ "Enedis",[5.779290176156428, 46.63657176915017]],
    [ "Enedis",[1.3371311704577362, 47.62618253664701]],
    [ "Enedis",[1.3265115815170014, 47.62235779133974]],
    [ "Enedis",[1.330203191994332, 47.62510208749557]],
    [ "Enedis",[1.3313757017543855, 47.624405653907495]],
    [ "Enedis",[1.3201232526690387, 47.62390084816133]],
    [ "Enedis",[1.310932173979985, 47.61411101770593]],
    [ "Enedis",[1.314904800976801, 47.62845186080586]],
    [ "Enedis",[1.3355936666666668, 47.629389028985514]],
    [ "Enedis",[1.3312481002277903, 47.62676880447988]],
    [ "Enedis",[1.3032119999999998, 47.613924499999996]],
    [ "Enedis",[1.3056934999999998, 47.609119500000006]],
    [ "Enedis",[1.3225544341902877, 47.62518020714129]],
    [ "Enedis",[1.3078069000000003, 47.62386315000001]],
    [ "Enedis",[1.323431199843872, 47.62127155971897]],
    [ "Enedis",[1.323619, 47.61958949999999]],
    [ "Enedis",[1.3078439175937369, 47.612133663370415]],
    [ "Enedis",[1.3055540984899703, 47.61295058921192]],
    [ "Enedis",[6.146680809064717, 48.6793484780195]],
    [ "250294515",[5.19488339474202, 47.04094253086601]],
    [ "Enedis",[1.6436421255587432, 48.680015606851576]],
    [ "Enedis",[1.8770553, 50.28909249999999]],
    [ "Enedis",[1.6664352683473391, 50.26736541064426]],
    [ "Enedis",[1.6768933519743434, 50.26740332611745]],
    [ "Enedis",[1.6791338655303027, 50.26972467234848]],
    [ "Enedis",[1.6749812930817605, 50.27103026289308]],
    [ "Enedis",[1.6666919330232568, 50.26455112062016]],
    [ "Enedis",[1.6947807558753007, 50.27398995107914]],
    [ "Enedis",[1.6842383081263077, 50.26895353225953]],
    [ "Enedis",[1.6742646313017304, 50.274737997366444]],
    [ "Enedis",[1.6608712102640757, 50.27538661335326]],
    [ "Enedis",[1.679991, 50.272491499999994]],
    [ "Enedis",[1.6809525995599566, 50.27432279867986]],
    [ "Enedis",[1.7462003805460755, 50.34874947758818]],
    [ "Enedis",[1.7652625000000004, 50.32897850000001]],
    [ "Enedis",[-2.8336900000000003, 47.98931075]],
    [ "Enedis",[1.732233333333334, 50.318139]],
    [ "Enedis",[5.014538539498356, 46.725218403143124]],
    [ "Enedis",[-2.8174675529831985, 47.918130059156375]],
    [ "Enedis",[-2.8241475000000005, 47.91387580000001]],
    [ "Enedis",[4.68439075, 46.65374905]],
    [ "Enedis",[4.59890690851735, 46.520866385208556]],
    [ "Enedis",[4.596092131313131, 46.52217866666667]],
    [ "Enedis",[-3.4502594, 48.795007500000004]],
    [ "Enedis",[2.7108410894522956, 48.072173453592825]],
    [ "Securite Sociale",[-4.490660971733194, 48.388401368104105]],
    [ "Enedis",[2.747790483290446, 48.077180282531465]],
    [ "Sous-station de Saint-Martin-de-Commune",[4.490138949178735, 46.91475204464131]],
    [ "Enedis",[1.6348489999999996, 50.316784]],
    [ "Enedis",[1.5875453054331863, 50.31893235682819]],
    [ "Enedis",[1.5861068694396294, 50.324328957250145]],
    [ "Enedis",[1.5820435994310837, 50.319094690199115]],
    [ "Enedis",[1.57691152631579, 50.318617730994156]],
    [ "Enedis",[1.5715385, 50.31900950000001]],
    [ "Enedis",[1.5699907499999983, 50.31682849999999]],
    [ "Enedis",[1.55478051952916, 50.31954344515784]],
    [ "Enedis",[1.5470891840607215, 50.32213934703775]],
    [ "Enedis",[1.5474001099381138, 50.32042614500667]],
    [ "Enedis",[5.93294975, 46.85367725]],
    [ "Enedis",[5.930606500000001, 46.84872575000001]],
    [ "Enedis",[5.925683750000001, 46.85768525000001]],
    [ "Enedis",[5.934927860524137, 46.85249393109698]],
    [ "RTE",[-0.8257787447212176, 48.403698478916574]],
    [ "Enedis",[4.117180278423948, 43.641290633306284]],
    [ "Enedis",[4.120659916400473, 43.64448094911042]],
    [ "Enedis",[-0.743204, 46.651585]],
    [ "Enedis",[-0.7303335, 46.6463573]],
    [ "Enedis",[-0.7488420702716468, 46.64201490221844]],
    [ "Enedis",[-0.7455585329704767, 46.651035098515585]],
    [ "Enedis",[-0.7441329702105872, 46.64549975446398]],
    [ "Enedis",[-0.7444808500000001, 46.64908019999999]],
    [ "Enedis",[-0.7377824999999999, 46.650567499999994]],
    [ "Enedis",[-0.7360943348350901, 46.64857627314598]],
    [ "Enedis",[-0.7504532999999999, 46.649988900000004]],
    [ "Enedis",[-0.7277475, 46.645428249999995]],
    [ "Enedis",[-0.7475258167181448, 46.64688895746434]],
    [ "Enedis",[-0.7331261221942852, 46.647525635333274]],
    [ "Enedis",[-0.7377884, 46.6481663]],
    [ "Enedis",[-0.7308414999999999, 46.6433215]],
    [ "Enedis",[-0.7356597500000001, 46.644301500000005]],
    [ "Enedis",[-0.7406828000000003, 46.64939560000001]],
    [ "Enedis",[1.0862690162731758, 49.23640656289182]],
    [ "Enedis",[1.2036278682170547, 49.31175323255814]],
    [ "Enedis",[1.2027385830212238, 49.31477931294215]],
    [ "Enedis",[1.1979925075757576, 49.312654333677685]],
    [ "Enedis",[1.2567287266767522, 49.35747619616742]],
    [ "Enedis",[1.2500279999999986, 49.364683]],
    [ "Enedis",[1.2335596685036017, 49.37083349922283]],
    [ "Enedis",[1.6476586306796284, 48.68145045190036]],
    [ "Enedis",[1.985467791093929, 50.2692436543528]],
    [ "Enedis",[6.0385188, 46.70679925]],
    [ "Enedis",[1.2600385, 43.391434000000004]],
    [ "Enedis",[1.2555541519663997, 43.395177949217256]],
    [ "Enedis",[1.2667763254593178, 43.39539951181102]],
    [ "Enedis",[1.2608066707281698, 43.393994994487954]],
    [ "Enedis",[1.2645236568457539, 43.39514443616407]],
    [ "Enedis",[1.2595810000000003, 43.388063]],
    [ "Enedis",[1.783565681078936, 50.13235076609811]],
    [ "RTE",[1.8280761340538192, 50.09391331141203]],
    [ "Enedis",[1.7721624999999996, 50.111351]],
    [ "Enedis",[1.7757367879758095, 50.108649527570265]],
    [ "251035516",[2.244003617929778, 48.58446699836338]],
    [ "Enedis",[1.7705749033297533, 50.114832326172575]],
    [ "Enedis",[2.240040749206349, 49.21634707936507]],
    [ "Enedis",[5.838408250000001, 46.79106375]],
    [ "Enedis",[5.8367895999999995, 46.793918049999995]],
    [ "Enedis",[2.247200871099446, 49.22977222922135]],
    [ "Enedis",[-0.6666239617608284, 46.61569252044599]],
    [ "Enedis",[4.855692489615063, 46.24048275225248]],
    [ "Enedis",[0.6586055653077536, 47.433102706155076]],
    [ "Enedis",[-0.13690185000000063, 48.180623]],
    [ "Enedis",[-0.13278735, 48.178674550000004]],
    [ "Enedis",[2.123786077108434, 49.21416415341365]],
    [ "Enedis",[2.1048498351559335, 49.22366950263264]],
    [ "Enedis",[-0.6288949, 46.587073600000004]],
    [ "Enedis",[-0.6523429000000004, 46.57455574999999]],
    [ "Enedis",[1.8324667672447004, 50.06510195144509]],
    [ "RTE",[1.6850548642060916, 50.2769648226867]],
    [ "Enedis",[1.8607861542227666, 50.0787164255406]],
    [ "Enedis",[1.8684353058419245, 50.1086100515464]],
    [ "Enedis",[1.8731554999999998, 50.1120575]],
    [ "Foyer",[2.7041947499999988, 48.48804945]],
    [ "Enedis",[2.7000239668737054, 48.48441721325052]],
    [ "Enedis",[2.689967343313373, 48.48993698802394]],
    [ "Enedis",[2.7088178326914845, 48.48830271031237]],
    [ "Enedis",[2.6974429999999985, 48.48675500000001]],
    [ "Enedis",[2.69845334294047, 48.489158469377244]],
    [ "Enedis",[4.75580039255814, 46.01253559472869]],
    [ "Enedis",[4.784318884928493, 45.00659790341034]],
    [ "Enedis",[1.8512869343696035, 50.15183719343696]],
    [ "Enedis",[1.8823451698113205, 50.133176992567186]],
    [ "Enedis",[1.3879647952811782, 43.75235846656023]],
    [ "Enedis",[1.3982541194518125, 43.74964725826702]],
    [ "Enedis",[1.3905973010752686, 43.75444077869013]],
    [ "Enedis",[1.4031937955383287, 43.74756600874002]],
    [ "Enedis",[1.8757921868758909, 50.168817033285784]],
    [ "Enedis",[1.8756379999999997, 50.168811999999996]],
    [ "De Gaulle",[4.80530905, 46.795980400000005]],
    [ "Enedis",[4.8087887, 46.797231499999995]],
    [ "Enedis",[5.760639768533126, 49.389986560075926]],
    [ "Enedis",[-0.1289655, 48.191184750000005]],
    [ "Enedis",[2.010636, 50.237700000000004]],
    [ "Enedis",[-0.630486550000001, 46.55524775]],
    [ "Enedis",[6.07413535443656, 46.75200280426507]],
    [ "Valempoulières Villa",[5.862583883168951, 46.82606849865287]],
    [ "Enedis",[2.0652905176151757, 50.25355416892503]],
    [ "Enedis",[2.3634206653804366, 48.64720930791191]],
    [ "Enedis",[6.0905876, 46.751433250000005]],
    [ "Enedis",[6.0042918, 46.7800533]],
    [ "Enedis",[5.8987918665502646, 46.885486399551475]],
    [ "Enedis",[1.3109765852713182, 43.780145716279065]],
    [ "Enedis",[1.3089407326489817, 43.78258574885641]],
    [ "Enedis",[6.971668499999998, 49.04210200000001]],
    [ "Enedis",[5.781760906578982, 46.68335604809595]],
    [ "Enedis",[-0.6569427, 46.628857599999996]],
    [ "Enedis",[5.711653754459439, 46.82162225433535]],
    [ "Enedis",[5.8140634986876645, 46.665069176602934]],
    [ "Enedis",[6.028142666314481, 46.80691337265382]],
    [ "Enedis",[5.64037925, 46.573729549999996]],
    [ "Enedis",[5.62366955, 46.600846]],
    [ "Enedis",[5.629644987501294, 46.59565885537909]],
    [ "Cofratel",[5.627817613339663, 46.5988588248924]],
    [ "Dompierre",[5.608285999695437, 46.558779283316056]],
    [ "Enedis",[2.359125548821548, 49.22495769584737]],
    [ "Poste Lucy",[4.349305113559668, 46.66659907760345]],
    [ "SNET",[4.351586911506778, 46.66830673908583]],
    [ "Enedis",[2.693419709438347, 48.32024434078984]],
    [ "Enedis",[2.690943666813771, 48.31610486796892]],
    [ "Enedis",[2.684855885325812, 48.31121917110252]],
    [ "Enedis",[2.6890178519391044, 48.317504200085175]],
    [ "Enedis",[2.6877797478019634, 48.313691316986755]],
    [ "Enedis",[2.6916643910073828, 48.31755137375333]],
    [ "Enedis",[5.603515658914729, 45.31889901268498]],
    [ "Rossignol",[5.6040754, 45.3191529]],
    [ "Blanchisserie",[5.620196965467238, 45.329318241274194]],
    [ "Enedis",[1.2402295190048376, 49.30662144989634]],
    [ "Enedis",[-4.245641466875734, 48.4628540988591]],
    [ "Enedis",[-4.24087299143678, 48.46087392542828]],
    [ "Enedis",[-4.243290241986657, 48.46328221742194]],
    [ "Enedis",[-4.235090400000001, 48.46078275]],
    [ "Enedis",[2.295647930328931, 49.22286611162912]],
    [ "Storengy",[1.4984765835802603, 47.42769305458186]],
    [ "Enedis",[5.6325286000000006, 46.53254845]],
    [ "Enedis",[5.567951428507966, 46.50745959816175]],
    [ "Embarcadère",[5.6726545, 46.52063425]],
    [ "Sur le Buis",[5.65932745, 46.528234700000006]],
    [ "Surchauffant",[5.67457954892884, 46.521931666228625]],
    [ "Enedis",[5.548717399999998, 46.52625675]],
    [ "Bourg",[5.561051397328735, 46.52628434949617]],
    [ "Enedis",[0.9587117245225951, 46.04275169778874]],
    [ "Enedis",[0.9583289629712697, 46.04104937543086]],
    [ "Enedis",[1.9826811360544216, 50.205369999999995]],
    [ "Enedis",[1.1741140476874006, 49.35302835326954]],
    [ "Enedis",[1.179589883252208, 49.35017087670813]],
    [ "Enedis",[1.1764195204022987, 49.34992589195401]],
    [ "Enedis",[0.9163637000000004, 49.50588450000001]],
    [ "Enedis",[0.9210538214765104, 49.497608734675616]],
    [ "Enedis",[0.9218597137681158, 49.4993330289855]],
    [ "Enedis",[0.9254880828779601, 49.50132826502732]],
    [ "Enedis",[0.92735868992248, 49.504759602067175]],
    [ "Enedis",[0.9198542205954118, 49.50355241288433]],
    [ "Enedis",[0.9369590726612163, 49.500470826521344]],
    [ "Enedis",[-3.6559178147146034, 48.147093143874606]],
    [ "Enedis",[-3.5238503144139686, 48.67945967507038]],
    [ "Enedis",[-3.5128744888554975, 48.67635789551716]],
    [ "Enedis",[-3.4792102667156772, 48.6095952997799]],
    [ "Enedis",[-3.4809524320958274, 48.610964798213786]],
    [ "Enedis",[0.5373269102040819, 48.73907121904763]],
    [ "Enedis",[0.5416462220876239, 48.73949202008883]],
    [ "Enedis",[0.5537493279456543, 48.74172583391895]],
    [ "Enedis",[0.5345068138801261, 48.73854165825446]],
    [ "Enedis",[0.5398726089417084, 48.740090793246544]],
    [ "Enedis",[0.5519738987294467, 48.73776324252615]],
    [ "Enedis",[0.5454688999999986, 48.7386]],
    [ "Enedis",[2.1709069999999997, 49.2162285]],
    [ "Enedis",[2.168161499021527, 49.22139698825831]],
    [ "Enedis",[5.5355573937568945, 46.467895004301795]],
    [ "Enedis",[5.607508, 46.43434782113821]],
    [ "Enedis",[5.551198527435319, 46.44170739447743]],
    [ "Enedis",[5.596343777620114, 46.42711436431166]],
    [ "Enedis",[-3.1413096271765397, 48.767437988003]],
    [ "Enedis",[2.3685879304155826, 48.66037505419427]],
    [ "Enedis",[2.365746466053049, 48.660733244055585]],
    [ "Enedis",[2.3713699139874134, 48.658229407539395]],
    [ "Enedis",[1.10370755, 47.1858738]],
    [ "Enedis",[5.445219068802758, 47.10536343468022]],
    [ "Enedis",[5.422366394994313, 47.091678601005874]],
    [ "Enedis",[5.43486345, 47.10196034999999]],
    [ "Enedis",[5.437847074277217, 47.10600994405793]],
    [ "Enedis",[5.624901532919683, 46.36551394512237]],
    [ "Enedis",[5.532141187670303, 46.3535152009842]],
    [ "Enedis",[5.522522900000001, 46.34009475]],
    [ "Enedis",[5.5554798, 46.3337939]],
    [ "Enedis",[5.623961, 46.337459499999994]],
    [ "Enedis",[5.609775198212962, 46.33859546611131]],
    [ "Enedis",[5.420019742771347, 47.04614242900669]],
    [ "Enedis",[5.403499018099548, 47.03190942986426]],
    [ "Enedis",[5.4181453986092825, 47.043986283156606]],
    [ "Enedis",[5.40979875, 47.03583445]],
    [ "Enedis",[5.420646912892087, 47.04753064584263]],
    [ "Enedis",[5.416590497151077, 47.04657851672818]],
    [ "Enedis",[-3.570306022115424, 48.49869681508328]],
    [ "Enedis",[5.5317775000000005, 46.422385]],
    [ "Enedis",[1.9430285, 50.13244000000001]],
    [ "Enedis",[1.9472948423485903, 50.129491175219606]],
    [ "Poste électrique de Custine",[6.138619416979014, 48.76544892924653]],
    [ "Poste électrique de Frouard",[6.1356093923143655, 48.76987718452803]],
    [ "Enedis",[-0.660615969826748, 46.477760755772835]],
    [ "Centre Commercial",[5.5242441979522185, 46.668015410693975]],
    [ "Maty",[5.5270490500000005, 46.6739627]],
    [ "Chantrans 2",[5.526160799999999, 46.67045374999999]],
    [ "Enedis",[5.53569375, 46.67944775]],
    [ "Enedis",[5.503087366207846, 46.68989700355587]],
    [ "Enedis",[5.505661752711596, 46.68802563362743]],
    [ "Salines",[5.535851250000001, 46.674769250000004]],
    [ "Mairie",[5.530058999999999, 46.6768645]],
    [ "Enedis",[5.534997425216123, 46.68204919332129]],
    [ "Enedis",[5.538422000000001, 46.677076500000005]],
    [ "Enedis",[5.541308549999999, 46.67994840000001]],
    [ "Cimetière",[5.5329075, 46.6790195]],
    [ "Enedis",[5.503279242601579, 46.686813490135435]],
    [ "Enedis",[5.51703775, 46.689406999999996]],
    [ "Enedis",[1.9488717381507368, 50.13620003108004]],
    [ "Enedis",[1.268055, 49.475087]],
    [ "Enedis",[1.2720164252311037, 49.47425273463839]],
    [ "Enedis",[1.2702338619824334, 49.47078521371811]],
    [ "Enedis",[-0.6644537, 46.46974500000001]],
    [ "Enedis",[1.2864385184170468, 49.46109465966514]],
    [ "Enedis",[5.500063, 46.5992065]],
    [ "Enedis",[1.908353827064677, 50.09205450925372]],
    [ "Enedis",[1.9130585, 50.087643]],
    [ "Enedis",[1.9073784999999996, 50.087809]],
    [ "Enedis",[1.9104225000000001, 50.090416000000005]],
    [ "Enedis",[5.520849955782075, 46.66309877976249]],
    [ "Enedis",[5.523292534057892, 46.6639398019629]],
    [ "Enedis",[5.5111425, 46.6646025]],
    [ "Enedis",[5.521462999999997, 46.665293]],
    [ "Enedis",[5.501138325607443, 46.661752990539725]],
    [ "Enedis",[5.511168250000001, 46.65998600000001]],
    [ "Enedis",[5.517706213390481, 46.66270939388575]],
    [ "Enedis",[5.50104925, 46.67786975]],
    [ "Chanet",[5.494749192592594, 46.67563253283951]],
    [ "Enedis",[5.5299581999999985, 46.65127300000001]],
    [ "Enedis",[5.488135299584113, 46.639667062499655]],
    [ "Enedis",[5.465294437269129, 46.64170114352618]],
    [ "Enedis",[5.447548388503045, 46.63251992634832]],
    [ "Enedis",[1.544041553178511, 43.8112306341722]],
    [ "Enedis",[1.5441683, 43.808178]],
    [ "Enedis",[5.547741, 46.685145999999996]],
    [ "Enedis",[5.564086092132436, 46.694514068787484]],
    [ "Route de Voiteur",[5.562550236066081, 46.69009241624209]],
    [ "Enedis",[5.694934172402485, 46.996736653132224]],
    [ "Enedis",[5.7058190009105285, 46.99324074778927]],
    [ "Enedis",[2.3085823747779743, 49.27629282001183]],
    [ "Profil",[5.6260462, 46.975247550000006]],
    [ "Enedis",[5.628685829372024, 46.994203230692456]],
    [ "Village",[5.6197169, 46.97681835000001]],
    [ "Enedis",[5.746655548647537, 46.97056832458129]],
    [ "Enedis",[1.7626188769051072, 43.571734958493245]],
    [ "Enedis",[-0.6826406999999992, 46.6521523]],
    [ "Enedis",[5.64582525, 46.89453055]],
    [ "Enedis",[5.622083643223742, 46.895432319714054]],
    [ "Enedis",[5.682087644604907, 46.91430119577009]],
    [ "Enedis",[5.685480179717392, 46.93923939530686]],
    [ "Enedis",[5.740208800683943, 46.96181434998217]],
    [ "Enedis",[5.702370849999999, 46.9319417]],
    [ "Montmalin",[5.717898711185508, 46.952845636966664]],
    [ "Enedis",[1.7856800499999996, 43.5727731]],
    [ "Enedis",[1.8751043452583758, 43.512304531516186]],
    [ "Enedis",[1.8777442270663034, 43.51297661489555]],
    [ "Enedis",[-0.3120376028368794, 48.447858212765965]],
    [ "Enedis",[-0.3027787528963907, 48.46192751605429]],
    [ "Enedis",[-0.2928018920521941, 48.48139828706998]],
    [ "Enedis",[-3.404340131762858, 48.40674139764429]],
    [ "Enedis",[-3.40204575, 48.4104625]],
    [ "Enedis",[-0.34107685560053946, 48.49509318218623]],
    [ "Enedis",[-0.3457305000000001, 48.495152999999995]],
    [ "Enedis",[-3.4368626117305134, 48.47121675788023]],
    [ "Enedis",[-3.4561490327923465, 48.47983941659529]],
    [ "Enedis",[-0.19114965676724477, 48.53184766935931]],
    [ "Enedis",[5.555933160086562, 46.757931475446334]],
    [ "Enedis",[5.548416869487791, 46.9951026955963]],
    [ "Enedis",[1.726104420152563, 43.53761060750399]],
    [ "Enedis",[-0.8281062, 46.6032967]],
    [ "Enedis",[-0.8416389212767713, 46.60279841457868]],
    [ "Enedis",[5.587600300000001, 46.853985800000004]],
    [ "Enedis",[5.636351931866497, 46.823919923427646]],
    [ "Enedis",[5.51555325, 46.73215]],
    [ "Enedis",[2.161009762458794, 49.26126306263332]],
    [ "Enedis",[2.1683969554531495, 49.26446384485407]],
    [ "Enedis",[2.17026735, 49.26165640000001]],
    [ "Enedis",[2.1691468020574085, 49.26709505176705]],
    [ "Enedis",[2.1646932758620694, 49.25898368582375]],
    [ "Enedis",[2.1635374999999994, 49.266352]],
    [ "Enedis",[2.1683062000000004, 49.2615266]],
    [ "Enedis",[2.1691121000000004, 49.260253600000006]],
    [ "Enedis",[5.5776259624030775, 46.780785599238506]],
    [ "Enedis",[-3.8592924366666677, 48.67004733333334]],
    [ "Enedis",[1.6424036077010726, 48.696911633517175]],
    [ "Enedis",[-3.699770918238994, 48.56773019496855]],
    [ "SNCF Réseau",[5.947722104298443, 48.96903835179913]],
    [ "Enedis",[6.763055755461592, 48.085392611698374]],
    [ "Enedis",[5.721360354347277, 46.826816033711346]],
    [ "Enedis",[5.446442000000001, 47.080335250000005]],
    [ "Longs Champs",[5.46242725, 47.083871500000015]],
    [ "Soleil",[5.462625500000001, 47.0821963]],
    [ "Enedis",[5.458779823119443, 47.081203974765685]],
    [ "Enedis",[5.602085167639336, 47.159837734223345]],
    [ "Enedis",[5.455859900000003, 47.13415605]],
    [ "Enedis",[5.619326964476023, 47.204988832445224]],
    [ "Enedis",[5.615299629932553, 47.17762508572189]],
    [ "Enedis",[5.673616425217073, 47.18395554181616]],
    [ "Enedis",[5.611837499999999, 47.177807]],
    [ "Enedis",[5.678785546322091, 47.19682195059299]],
    [ "Enedis",[5.5307735000000005, 47.153314900000005]],
    [ "Enedis",[5.612988007510789, 47.187822509849305]],
    [ "Enedis",[5.68157041502003, 47.205935972047236]],
    [ "Enedis",[5.684956274163322, 47.2038705439807]],
    [ "Enedis",[1.4091143645833326, 47.01003033333333]],
    [ "Enedis",[1.4055545431148788, 47.011004701793276]],
    [ "Enedis",[5.489622624109175, 47.142087028149675]],
    [ "Enedis",[5.48823085, 47.141154799999995]],
    [ "Enedis",[-0.08439521411192272, 47.55960716642336]],
    [ "Enedis",[5.550621489066733, 47.2085739971943]],
    [ "Enedis",[5.639181020897865, 47.246149919545054]],
    [ "Enedis",[5.6642363499999995, 47.24117700000001]],
    [ "Enedis",[5.6176479995172, 47.25140287087105]],
    [ "Enedis",[5.61596551240261, 47.24672147459857]],
    [ "Enedis",[5.559104440976814, 47.25665676284934]],
    [ "Enedis",[5.476277622543418, 47.17563444558635]],
    [ "Peintre Village",[5.479873507424854, 47.1939189967238]],
    [ "Enedis",[5.406918834501672, 46.740117568588914]],
    [ "RTE",[0.07639572174838509, 43.38038306554859]],
    [ "Enedis",[-0.7624286975407439, 48.46447944967934]],
    [ "Enedis",[2.0905035999999995, 49.28810915]],
    [ "Enedis",[-4.611823450150318, 48.438971306322664]],
    [ "Enedis",[2.280610650423012, 49.27730974382403]],
    [ "Enedis",[2.42018605, 48.68240944999999]],
    [ "Sous-station Pompadour",[2.437012104416157, 48.7756737918318]],
    [ "Enedis",[5.331231863900581, 45.15835211324915]],
    [ "Enedis",[5.336074796315976, 45.165634764080764]],
    [ "Enedis",[5.3362492999999995, 45.17941400000001]],
    [ "Enedis",[5.3273153787317495, 45.156756278782595]],
    [ "RTE",[4.750651415065339, 46.90244517841672]],
    [ "Enedis",[-0.8119844124677658, 44.71441187551185]],
    [ "Enedis",[-0.8147864218210188, 44.709603487172636]],
    [ "Les Villas du Bourg",[-0.8537642191018349, 44.68781762232765]],
    [ "Enedis",[5.533970783253342, 47.2235057506458]],
    [ "Enedis",[2.2312933333333334, 49.26796000000001]],
    [ "Enedis",[6.125555748858448, 44.577713543379]],
    [ "Enedis",[2.577917154639175, 49.15399004810996]],
    [ "Enedis",[2.094777807282237, 49.32189517964192]],
    [ "SNCF Réseau",[4.751214643173475, 44.75021730897515]],
    [ "Enedis",[2.4170921849056977, 48.65743564574199]],
    [ "Enedis",[1.4300558000000012, 43.7833712]],
    [ "Enedis",[6.30890613005128, 46.22076376495947]],
    [ "Enedis",[1.9705005665837516, 47.972630250535]],
    [ "Enedis",[3.48809436494997, 43.6042409171856]],
    [ "CLAOU",[3.4901555875129495, 43.60643331431306]],
    [ "Enedis",[3.491798, 43.603682799999994]],
    [ "Enedis",[3.4862243951424223, 43.61126149710932]],
    [ "Enedis",[3.490421588789812, 43.611287349761994]],
    [ "Enedis",[3.4951858999999996, 43.611129950000006]],
    [ "Enedis",[1.8306075118871425, 50.01296907325628]],
    [ "Enedis",[3.17753281323877, 47.984703650118206]],
    [ "Enedis",[1.1949031994778065, 47.155736463620535]],
    [ "Enedis",[3.23266077142857, 48.056347309625124]],
    [ "Enedis",[3.2320013830845773, 48.05901590547265]],
    [ "Enedis",[4.843335740112997, 45.41353003389832]],
    [ "Enedis",[4.0185357, 43.76981462222222]],
    [ "Enedis",[3.091426692694064, 47.734349276255706]],
    [ "Enedis",[2.3974562999999995, 49.3016751]],
    [ "Enedis",[2.394090880967917, 49.29936672408918]],
    [ "253757247",[5.874407040294241, 45.07237719548516]],
    [ "Enedis",[2.1401788131072585, 48.75888974153808]],
    [ "Enedis",[2.1375057240123794, 48.76432867722556]],
    [ "Enedis",[2.1411127499999996, 48.76301]],
    [ "Enedis",[2.1454594308943085, 48.764201666666665]],
    [ "Enedis",[2.1401987946593, 48.764869534254146]],
    [ "Enedis",[6.021251500000001, 46.23590600000001]],
    [ "Enedis",[6.024809213137096, 46.23637819814663]],
    [ "Enedis",[6.025622333333332, 46.235451166666664]],
    [ "Enedis",[6.023767001373625, 46.23679256227106]],
    [ "Enedis",[6.027417854934061, 46.2360311420126]],
    [ "Enedis",[6.024339177486338, 46.2350895304918]],
    [ "Poste électrique de Saint-Genis-Pouilly",[6.020805799908706, 46.23489650250612]],
    [ "Enedis",[-0.7316892262394283, 46.664305977245405]],
    [ "Enedis",[-0.7225787000000016, 46.6486435]],
    [ "Enedis",[-0.7337552999999999, 46.67298275]],
    [ "Enedis",[-0.7572822499999995, 46.662989]],
    [ "Enedis",[-0.7273514889266568, 46.64833227579244]],
    [ "Enedis",[-0.73047405, 46.659774000000006]],
    [ "Enedis",[3.4224132977777786, 48.164572755555554]],
    [ "Enedis",[6.402741500000001, 46.14056300000001]],
    [ "Enedis",[6.617116351915577, 49.225688819224594]],
    [ "Enedis",[6.62494, 49.225755500000005]],
    [ "Enedis",[6.623342, 49.22555950000001]],
    [ "Enedis",[3.6228363411181883, 48.181426176928525]],
    [ "Poste E.R.D.F.",[2.68599768871448, 49.84610208874222]],
    [ "Enedis",[6.029648919263713, 46.263102743211235]],
    [ "Enedis",[-1.694927, 46.75412849999999]],
    [ "Enedis",[-1.6869402499999993, 46.7553384]],
    [ "Enedis",[0.3450285252537892, 44.604786549085006]],
    [ "réséda",[6.397124816091953, 48.95555996551724]],
    [ "Enedis",[5.411537599773791, 46.7038801000019]],
    [ "Enedis",[2.2243565, 49.38374400000001]],
    [ "Enedis",[2.2244689999999983, 49.3831125]],
    [ "Enedis",[2.222144, 49.386374]],
    [ "Enedis",[2.2457978776398857, 49.38219130938384]],
    [ "Enedis",[-3.5552993390903556, 48.46226992213552]],
    [ "Enedis",[-3.5285672106294634, 48.4724703240405]],
    [ "Enedis",[5.377082745322097, 46.80336050087153]],
    [ "Enedis",[5.395000251777781, 46.80949824988149]],
    [ "Enedis",[-0.790536139114041, 46.66780902381496]],
    [ "Enedis",[5.50758056234784, 46.784924394870714]],
    [ "Enedis",[-0.7643345323933807, 46.572158698728316]],
    [ "Enedis",[-0.7708662320574455, 46.55859985238875]],
    [ "Enedis",[2.260058, 49.37870200000001]],
    [ "Enedis",[2.550173490196076, 49.92033623137254]],
    [ "Enedis",[2.5483392824379507, 49.922135357524354]],
    [ "Enedis",[3.962836639526793, 49.223987340640214]],
    [ "Enedis",[3.9617428596491235, 49.23021326315789]],
    [ "Enedis",[3.959535842592593, 49.21947896090536]],
    [ "Enedis",[3.962887175772402, 49.21751481842891]],
    [ "Enedis",[3.960104, 49.22157575000001]],
    [ "Enedis",[3.956763499999999, 49.2178895]],
    [ "Enedis",[3.9644408433296983, 49.21606693271538]],
    [ "Enedis",[3.959361517028694, 49.21635208903192]],
    [ "Enedis",[2.5465149999999994, 49.9152575]],
    [ "Enedis",[2.542250514253135, 49.91807435436462]],
    [ "Enedis",[1.8773119463340857, 47.025077763612835]],
    [ "Poste électrique de Saut-Mortier",[5.64185794084745, 46.37142976384848]],
    [ "Enedis",[5.018567500000001, 47.283846999999994]],
    [ "Ophelia",[5.014846, 47.2982925]],
    [ "Enedis",[4.014991096288866, 49.20004321029756]],
    [ "Juillet",[5.0217421311475405, 47.298008306010935]],
    [ "Enedis",[5.4090715000000005, 46.81081025]],
    [ "Enedis",[5.446837778708012, 46.81950567701279]],
    [ "Enedis",[5.452546042795691, 46.80318127857238]],
    [ "Enedis",[5.4474413, 46.802815]],
    [ "Enedis",[3.5181963500000015, 43.59231355]],
    [ "Enedis",[-0.7150700608043935, 46.68930387602262]],
    [ "Enedis",[-0.67573585, 46.705596549999996]],
    [ "Enedis",[-0.69313415, 46.70103824999999]],
    [ "Enedis",[-0.6889535499999999, 46.67760045]],
    [ "Enedis",[-0.695603418221633, 46.71639090290145]],
    [ "Enedis",[-0.7029909500000006, 46.69433375]],
    [ "Enedis",[-0.6958550463290295, 46.69834423287086]],
    [ "Enedis",[-0.6969290500000003, 46.694240099999995]],
    [ "Enedis",[-0.7063434999999999, 46.69330545]],
    [ "Enedis",[4.1170895, 49.28998174999999]],
    [ "Enedis",[4.11573925, 49.28571875]],
    [ "Chantereine",[4.125710000000001, 49.29429625]],
    [ "Enedis",[4.1105402500000014, 49.2830125]],
    [ "Enedis",[4.111673500000001, 49.286271]],
    [ "Lamartine",[4.1142035, 49.287865249999996]],
    [ "Église",[4.1196880447035955, 49.29456216569484]],
    [ "Monteve",[4.112672, 49.288701249999995]],
    [ "Enedis",[4.114545, 49.29238599999999]],
    [ "Enedis",[4.11925, 49.29033475]],
    [ "Enedis",[4.11632575, 49.289388]],
    [ "Enedis",[4.11505425, 49.2932735]],
    [ "Enedis",[3.963936726027397, 49.304681155251146]],
    [ "Enedis",[3.9541851240069086, 49.29875357409326]],
    [ "Enedis",[2.1633625092592577, 49.83882058307612]],
    [ "Enedis",[2.1681270000000006, 49.839501000000006]],
    [ "Enedis",[2.4946952972508587, 49.898579967926686]],
    [ "Enedis",[2.5045569933943095, 49.898027689024396]],
    [ "Enedis",[2.5087477687952897, 49.89515386163949]],
    [ "Enedis",[2.4192934999999998, 49.87676849999999]],
    [ "Enedis",[2.4215349376681887, 49.88064432796363]],
    [ "Enedis",[2.4292758334978615, 49.880521253701865]],
    [ "Enedis",[2.4392865901598224, 49.864118069792895]],
    [ "254824247",[5.111063658586748, 47.23508251432347]],
    [ "Enedis",[2.0373009308099306, 43.21789661253561]],
    [ "Enedis",[3.1142689129759122, 49.39754724009324]],
    [ "Enedis",[3.149812741369924, 49.38652700631602]],
    [ "Vacquières",[3.945092857742783, 43.84537581679789]],
    [ "Enedis",[-3.363863420763407, 48.47873170381704]],
    [ "Enedis",[-3.32956025, 48.47867]],
    [ "Enedis",[3.0319947748091605, 42.90653992366412]],
    [ "Enedis",[3.0503495, 42.88411250000001]],
    [ "Enedis",[3.044473688598179, 42.859815323842504]],
    [ "Enedis",[3.0526429871086553, 42.90226156942909]],
    [ "Enedis",[3.044261544764456, 42.90533128421523]],
    [ "Enedis",[3.0267474999999995, 42.910166499999995]],
    [ "Enedis",[3.039694646671729, 42.859984850164516]],
    [ "Enedis",[3.0367467529411765, 42.848710435294116]],
    [ "Enedis",[3.0262473506343714, 42.935391317185704]],
    [ "Enedis",[3.044183550387597, 42.858139749354]],
    [ "Enedis",[3.038580021786492, 42.85245183369644]],
    [ "Enedis",[3.0487095982035273, 42.87907379088619]],
    [ "Enedis",[3.0391933906899427, 42.85389179467997]],
    [ "Enedis",[3.0344100000000007, 42.85496199999999]],
    [ "Enedis",[3.0386893543244327, 42.84992157180816]],
    [ "Enedis",[3.047241514285714, 42.90676063650794]],
    [ "Enedis",[3.0365832324561404, 42.931701854010015]],
    [ "Enedis",[3.0255381747126435, 42.91163846298852]],
    [ "Enedis",[3.03695125477399, 42.84582958158086]],
    [ "Enedis",[3.046919481953291, 42.8767571613588]],
    [ "Enedis",[3.048338813333334, 42.905144783333334]],
    [ "Enedis",[3.024537000000001, 42.9350465]],
    [ "Enedis",[3.042168330094467, 42.8502424825911]],
    [ "Enedis",[3.0444314258396084, 42.87953464300227]],
    [ "Enedis",[3.0273150000000006, 42.909194500000005]],
    [ "Enedis",[3.0263670222222228, 42.93675213333335]],
    [ "Enedis",[3.0536648369004507, 42.9052705128965]],
    [ "Enedis",[3.043174000000002, 42.8546745]],
    [ "Enedis",[3.041835, 42.862035]],
    [ "Enedis",[3.0476772976960387, 42.88172968889787]],
    [ "CYTISES",[3.044918619378052, 42.863213304548964]],
    [ "Enedis",[3.0569702517623365, 42.909327630412896]],
    [ "Enedis",[3.0420255556243, 42.85384401135602]],
    [ "Enedis",[3.041629165644173, 42.858379491615544]],
    [ "Enedis",[3.048071840531876, 42.878018881983074]],
    [ "Enedis",[2.457433632972323, 49.901137291215406]],
    [ "Enedis",[2.453635, 49.902595000000005]],
    [ "Enedis",[2.4401701904761897, 49.8999985047619]],
    [ "Enedis",[2.449259621722847, 49.901512009737836]],
    [ "Enedis",[2.748174094295713, 50.320309834293845]],
    [ "Enedis",[3.55119072247444, 43.6979659040959]],
    [ "Enedis",[2.4481614439672006, 49.896837281400494]],
    [ "Enedis",[2.45135362325874, 49.896434796530954]],
    [ "Enedis",[2.5264628570986223, 49.90360273308563]],
    [ "SICAE-Somme",[2.7212565000000004, 49.943541999999994]],
    [ "Enedis",[2.596661712011799, 48.28444457623523]],
    [ "Enedis",[2.596793257919393, 48.28649720862875]],
    [ "Alouette",[2.6090338354854827, 48.314124255681875]],
    [ "Enedis",[2.4296043483709275, 49.31355847869675]],
    [ "Enedis",[-4.41351575, 48.4417483]],
    [ "Enedis",[2.966184461187215, 49.84663970319634]],
    [ "Enedis",[6.149572782024675, 43.225187202721095]],
    [ "Enedis",[6.156036228855251, 43.23314619608473]],
    [ "Enedis",[6.144754367012204, 43.22598180130152]],
    [ "Enedis",[6.141205952890475, 43.22558905543172]],
    [ "Enedis",[6.145244400521287, 43.22290878154262]],
    [ "Enedis",[6.141630964804052, 43.22089729709433]],
    [ "Enedis",[6.1495888701573325, 43.22704064400215]],
    [ "Enedis",[6.1404129, 43.22336835]],
    [ "Enedis",[6.14950345, 43.22023139999999]],
    [ "Enedis",[6.145855674665006, 43.221113473148435]],
    [ "Enedis",[6.157711713737931, 43.23438718115937]],
    [ "Enedis",[6.1200821010990385, 43.251953832146874]],
    [ "Enedis",[6.1729934810131075, 43.24786994183493]],
    [ "Enedis",[6.145255562889519, 43.22621400598049]],
    [ "Enedis",[6.142120662548006, 43.224247067899334]],
    [ "Enedis",[6.147627999999999, 43.224809050000005]],
    [ "Enedis",[6.159895320157404, 43.23330255522806]],
    [ "Enedis",[-1.6484626499999995, 47.34254744999999]],
    [ "Enedis",[-1.5926757499999997, 47.27589535]],
    [ "Enedis",[-1.5894728, 47.275377399999996]],
    [ "Enedis",[-1.5935636364319579, 47.27811569366172]],
    [ "RTE",[-3.4911887352670288, 48.094144908034906]],
    [ "Enedis",[-1.63144625, 47.33627575]],
    [ "RTE",[1.6030775884969022, 50.401943351037986]],
    [ "255429637",[-1.324437093303236, 48.52381631828443]],
    [ "Bellevue",[6.125350794615182, 45.91960057647626]],
    [ "Mauris",[6.1230926499999985, 45.9215093]],
    [ "Enedis",[-0.808425775799926, 46.72120733523261]],
    [ "Enedis",[-0.8056117500000001, 46.718575050000005]],
    [ "Enedis",[5.574203468017335, 46.884510602281715]],
    [ "Enedis",[5.55826263534794, 46.90141982624499]],
    [ "Enedis",[5.515248587642064, 46.92153858476326]],
    [ "Enedis",[5.54161138846407, 46.918911424425474]],
    [ "Enedis",[5.518297225541652, 46.95228654332828]],
    [ "Enedis",[5.504805899999997, 46.9612452]],
    [ "Jardin de Genève",[6.03530119266055, 46.253864709480126]],
    [ "Enedis",[6.0217515, 46.248864499999996]],
    [ "Le Belmont",[6.035545, 46.243469]],
    [ "Enedis",[0.4948605508038923, 48.0573581331253]],
    [ "Enedis",[-0.9022743790128566, 46.689688956432704]],
    [ "Enedis",[-1.5648361495631902, 48.76822050345876]],
    [ "Enedis",[-1.5702181037782579, 48.76463422740706]],
    [ "Enedis",[-1.5602380156005173, 48.77632599784398]],
    [ "Enedis",[-1.5674335041779206, 48.77972549604758]],
    [ "Grotte aux Fées",[-1.5685037628919547, 48.775329995858414]],
    [ "Enedis",[-1.5668977002651685, 48.77621135935794]],
    [ "Enedis",[-1.5633964999999999, 48.776283500000005]],
    [ "Enedis",[-1.5665105086400362, 48.77405799826419]],
    [ "Enedis",[-1.5641872980291238, 48.773767394915474]],
    [ "Enedis",[-1.5620343005855588, 48.77985845080383]],
    [ "Enedis",[-1.567117515180312, 48.7858285030368]],
    [ "Enedis",[-1.5642701061317408, 48.78094733148077]],
    [ "Enedis",[-1.560016991744102, 48.77076400361171]],
    [ "Enedis",[-1.5610216630816947, 48.77251566265048]],
    [ "Enedis",[-1.5668460049741337, 48.77042499374444]],
    [ "Cuques université",[5.454446532475071, 43.51763169402517]],
    [ "Enedis",[5.466316753769814, 46.96256824945651]],
    [ "Enedis",[2.0718741501706486, 49.26323336052332]],
    [ "Enedis",[2.0787992500000003, 49.260971000000005]],
    [ "Poste Électrique de Mariveaux",[2.072355354902013, 49.24727297340157]],
    [ "Enedis",[5.463709795322853, 46.98488073367979]],
    [ "Enedis",[5.4796916081280775, 46.91963758096425]],
    [ "Enedis",[5.438841999556264, 46.90148905019502]],
    [ "Enedis",[5.486285731695964, 46.92147739817551]],
    [ "Enedis",[5.75843467012422, 45.17553219775031]],
    [ "Enedis",[-1.5629999494126279, 48.75522693630563]],
    [ "Enedis",[-1.570423689798566, 48.75920849015418]],
    [ "Enedis",[-1.5578417662865547, 48.75703737183525]],
    [ "Enedis",[-1.5581697459441177, 48.75371304650104]],
    [ "Enedis",[-1.5548741864259261, 48.7494604024611]],
    [ "Enedis",[-1.564163208751513, 48.75392655897909]],
    [ "SNCF Réseau",[4.307382428632284, 49.089827214140726]],
    [ "Enedis",[5.386002132709595, 46.90173475566931]],
    [ "Enedis",[5.402962353573713, 46.908586701098734]],
    [ "SNCF Réseau",[4.2166308127534915, 49.13695226011326]],
    [ "QT S/ Jacques",[-1.4003432534939657, 48.793072635887135]],
    [ "Enedis",[-0.9199837059226206, 46.71605608169612]],
    [ "Enedis",[5.405572178991481, 46.94611769661546]],
    [ "Enedis",[5.39975405, 46.97064925]],
    [ "Enedis",[5.455974710331604, 46.97907887111783]],
    [ "Enedis",[5.329564896987828, 46.93807778263117]],
    [ "Enedis",[5.337014362319386, 46.93196727534344]],
    [ "Enedis",[5.3320510291342735, 46.94173354500597]],
    [ "Enedis",[2.550503, 50.1305805]],
    [ "Enedis",[-0.7778290932023965, 46.72889976752376]],
    [ "Enedis",[5.329154963480119, 46.914983735927585]],
    [ "Enedis",[6.016793085880887, 46.236763822735284]],
    [ "SNCF Réseau",[5.3404116, 47.21672689999999]],
    [ "Enedis",[2.910719200000001, 47.165905650000006]],
    [ "Enedis",[3.172779512944944, 46.735992299892686]],
    [ "Enedis",[3.0560262255566246, 46.78752505522107]],
    [ "SNCF Réseau",[5.906741477130722, 47.29640231915773]],
    [ "Enedis",[5.2783623500871455, 46.95921215069845]],
    [ "Enedis",[5.29677, 46.967148]],
    [ "Enedis",[5.31532665, 46.98015575]],
    [ "Enedis",[5.367556357517372, 46.99288856982651]],
    [ "Enedis",[5.362608254988112, 46.99376301720354]],
    [ "Enedis",[5.420550960784314, 47.01968166666666]],
    [ "Enedis",[5.348996003851112, 47.05870625392898]],
    [ "Enedis",[1.9936304313996196, 49.18735150291642]],
    [ "Enedis",[-0.762326516610212, 46.7788453518881]],
    [ "Enedis",[-0.7363196925602725, 46.79586022344767]],
    [ "Enedis",[-0.7348142825359695, 46.79291840313584]],
    [ "Enedis",[0.5318452499999999, 48.017416749999995]],
    [ "Enedis",[1.4464636743092305, 46.68455384009407]],
    [ "Enedis",[5.726737160621256, 45.94458755105016]],
    [ "Enedis",[5.718776849999999, 45.910948499999996]],
    [ "Enedis",[1.982844702729271, 46.578017661241105]],
    [ "Enedis",[1.9739314999999988, 46.57916600000001]],
    [ "Enedis",[1.9814597299286867, 46.56604258339084]],
    [ "Enedis",[1.9748535158604799, 46.58033032727871]],
    [ "Enedis",[1.9834748370304642, 46.569336827565806]],
    [ "Enedis",[1.9849545000000002, 46.577056999999996]],
    [ "Enedis",[1.9815886874081332, 46.571903816756496]],
    [ "Enedis",[2.0013145, 46.581081499999996]],
    [ "Enedis",[1.9841379999999997, 46.566385450000006]],
    [ "Enedis",[1.9900405, 46.57459399999999]],
    [ "Enedis",[2.44768522614841, 47.276966087161355]],
    [ "Enedis",[2.551555933333333, 46.664929111111114]],
    [ "RTE",[6.174197154257359, 43.29690853739351]],
    [ "Enedis",[6.160429300972527, 48.69094681476022]],
    [ "Enedis",[2.434362131838277, 51.05101369218211]],
    [ "Enedis",[5.048799604577841, 47.360425379557064]],
    [ "Enedis",[5.049210899999999, 47.36094504999999]],
    [ "Enedis",[5.064935894025364, 47.33570741664133]],
    [ "Enedis",[2.2257378243500314, 47.16543318304799]],
    [ "Enedis",[2.2158539114583338, 47.16925320052084]],
    [ "Enedis",[2.2285555600356037, 47.16452967850468]],
    [ "Enedis",[2.2229441628012734, 47.1503567594361]],
    [ "Enedis",[2.348863897926635, 46.623113274521536]],
    [ "Enedis",[2.436788017445483, 47.553592832398756]],
    [ "Enedis",[-0.41122078723404465, 48.511124219858154]],
    [ "RTE",[-2.4133441357428165, 47.92594417874541]],
    [ "Enedis",[-0.8498295167158226, 46.74394199952249]],
    [ "Enedis",[-0.846723, 46.74791844999999]],
    [ "Enedis",[-0.842823, 46.74241000000001]],
    [ "Enedis",[-0.8817290041686778, 46.74587468295359]],
    [ "Enedis",[-0.8716951086789445, 46.74567503262156]],
    [ "Enedis",[-0.8462034499999999, 46.742268499999994]],
    [ "Enedis",[-0.8159426000000004, 46.7449997]],
    [ "Enedis",[-0.8145028961959357, 46.74673388087596]],
    [ "Enedis",[0.07679809106825398, 46.618673161478284]],
    [ "Enedis",[3.0031483637681156, 47.1234516673913]],
    [ "Enedis",[-0.02816165548098434, 48.319029033557044]],
    [ "Enedis",[-0.06528269717223739, 48.307959686718085]],
    [ "Enedis",[-0.03398641025641001, 48.3198717948718]],
    [ "Enedis",[-0.034970676217765055, 48.31770701337153]],
    [ "Enedis",[-0.024354950000000004, 48.3177381]],
    [ "Enedis",[-0.06506449999999901, 48.3080105]],
    [ "Enedis",[-0.0623440207715131, 48.3093181147379]],
    [ "Enedis",[-0.007234270137270366, 48.32546006241907]],
    [ "Enedis",[-0.010367346289753027, 48.3226387008245]],
    [ "Enedis",[-0.9873025000000007, 46.7058308]],
    [ "Enedis",[0.037119, 48.32338850000001]],
    [ "Enedis",[-0.007469484420004913, 48.24841337732391]],
    [ "Enedis",[0.011752067034991102, 48.27730717053407]],
    [ "Enedis",[0.026107857748295115, 48.271274329648065]],
    [ "Enedis",[2.5683035, 46.88746]],
    [ "Enedis",[2.5683875705237416, 46.88939129607309]],
    [ "Enedis",[6.212463000000001, 48.7065445]],
    [ "Mairie",[5.834107231481481, 45.05514756481481]],
    [ "Jeymond",[5.835227663003663, 45.05385348864469]],
    [ "Enedis",[5.835650543593632, 45.05284413570887]],
    [ "Enedis",[-0.36729930969183006, 49.18314531670313]],
    [ "Enedis",[2.8851695272407722, 47.38690928353838]],
    [ "Enedis",[2.8864825, 47.38918199999999]],
    [ "Enedis",[6.308583260918759, 43.23623112310477]],
    [ "Enedis",[6.283374156626506, 43.232292701969165]],
    [ "Enedis",[6.312085082515539, 43.239834297841014]],
    [ "Enedis",[6.305156808384706, 43.238256870409316]],
    [ "RTE",[1.2170902212883759, 49.38162174314277]],
    [ "SNCF Réseau",[5.114732599999999, 48.99049625]],
    [ "Enedis",[5.053045916642912, 47.36030965003012]],
    [ "Enedis",[4.074976949999999, 46.60963414999999]],
    [ "Poste source de Geugnon",[4.074335402702664, 46.608176552480764]],
    [ "Poste Emponse",[6.549760118816491, 43.34397078180777]],
    [ "Enedis",[6.444730748308135, 45.590640909503236]],
    [ "Enedis",[6.438337807970865, 45.59700885335862]],
    [ "Enedis",[6.4463414625627, 45.592425838486086]],
    [ "Enedis",[4.666780132489451, 46.4274696628692]],
    [ "Enedis",[4.666634, 46.427691]],
    [ "Enedis",[1.752913, 47.8235605]],
    [ "Enedis",[1.7482139999999997, 47.818877500000006]],
    [ "Enedis",[1.7634741262405462, 47.82283084392302]],
    [ "Enedis",[1.7650445815244822, 47.83290681255259]],
    [ "Enedis",[1.7512090215053766, 47.816438354838716]],
    [ "Enedis",[1.7475031639042355, 47.81272802946592]],
    [ "Enedis",[1.7546564228187922, 47.821324821029094]],
    [ "Enedis",[1.7432126837606836, 47.81224203418803]],
    [ "Enedis",[1.7611554999999992, 47.8216745]],
    [ "Enedis",[1.75899098015873, 47.82222883531746]],
    [ "Enedis",[1.744325, 47.81085900000001]],
    [ "Enedis",[5.234595999999999, 45.62181749999999]],
    [ "Le Creuzat",[5.223677024858976, 45.615248488959466]],
    [ "Le Laro",[5.2311190000000005, 45.61715924999999]],
    [ "Allée Lans",[5.217819500000002, 45.6150735]],
    [ "ZA les Branches",[5.234681999999999, 45.615649000000005]],
    [ "Enedis",[5.232006250000001, 45.613267750000006]],
    [ "Enedis",[5.206174000000001, 45.623046000000016]],
    [ "Bourg",[5.232808042253521, 45.62298046478874]],
    [ "Vergers du Parc",[5.22873175, 45.61821065]],
    [ "Campanile",[5.2200695, 45.613977]],
    [ "Enedis",[5.22955985, 45.61268230000001]],
    [ "Résidence du stade",[5.22211454901191, 45.622572790734196]],
    [ "Enedis",[5.2311545000000015, 45.6155955]],
    [ "Les Branches 2",[5.234628000000001, 45.617537]],
    [ "Enedis",[2.1365883603371905, 47.80117597885412]],
    [ "Enedis",[2.1293917946257195, 47.80531604691832]],
    [ "Enedis",[2.1222603038348082, 47.81157054572272]],
    [ "Enedis",[1.609848080959712, 47.765413560046184]],
    [ "Enedis",[-0.9956756521451777, 46.78864515272634]],
    [ "Enedis",[-0.9906248999999999, 46.79021529999999]],
    [ "Enedis",[1.5020313360360362, 47.69970600720721]],
    [ "Enedis",[1.500737394944708, 47.69595676619274]],
    [ "Enedis",[1.5122768847909929, 47.71523382446398]],
    [ "Enedis",[1.514397272108844, 47.704237238095246]],
    [ "Enedis",[1.5134337535014006, 47.699152165266106]],
    [ "Enedis",[1.5315067826086959, 47.70495530756843]],
    [ "Enedis",[1.5028246450060168, 47.694824377858005]],
    [ "Enedis",[1.5178179999999997, 47.70370599999999]],
    [ "Enedis",[1.507448632850242, 47.702573925120774]],
    [ "Enedis",[1.512392991489362, 47.698021937588656]],
    [ "Enedis",[1.513387986228003, 47.687324464422346]],
    [ "Enedis",[1.50427289290012, 47.70733737713598]],
    [ "Enedis",[1.5054727796610168, 47.7158163314501]],
    [ "Enedis",[1.4949174057750765, 47.71109477254306]],
    [ "Enedis",[1.5047327450980394, 47.69240574509805]],
    [ "Enedis",[1.5049220542635662, 47.71542765697674]],
    [ "Enedis",[1.5090902194092828, 47.690415843881844]],
    [ "Enedis",[1.5057498672397323, 47.70545237440305]],
    [ "Enedis",[1.4974332459970894, 47.71017906841339]],
    [ "Enedis",[1.5126255000000004, 47.70719250000001]],
    [ "Enedis",[1.5054002549923196, 47.69492297695853]],
    [ "Enedis",[1.5369768278606963, 47.71063245074627]],
    [ "Enedis",[1.510991145415252, 47.695419675311314]],
    [ "Enedis",[1.5123603645833332, 47.713250536458325]],
    [ "Enedis",[1.5040336555217828, 47.70958148024315]],
    [ "Enedis",[1.5095528107588854, 47.70732680211336]],
    [ "Enedis",[1.5164311203126704, 47.69582354519515]],
    [ "Enedis",[1.504604, 47.697585]],
    [ "Enedis",[-0.9419392978912158, 46.78193133337878]],
    [ "Enedis",[-0.9183316411792862, 46.77406091915901]],
    [ "Enedis",[-0.91835275, 46.80475855]],
    [ "Enedis",[-0.9520389898707309, 46.80064352005273]],
    [ "SNCF Réseau",[4.773731412265648, 46.28916892769561]],
    [ "Enedis",[-4.260017303572955, 48.35963553112622]],
    [ "Enedis",[-4.256835, 48.366077700000005]],
    [ "Enedis",[2.7544215000000003, 50.097373999999995]],
    [ "Enedis",[2.7300328889710865, 50.09190566056346]],
    [ "Écoles",[-1.062006956923077, 45.35819569333333]],
    [ "Enedis",[1.3735717812500006, 43.659436153645835]],
    [ "Enedis",[-0.8812677999999999, 46.83002255]],
    [ "Enedis",[-0.9211475333742515, 46.83321730034167]],
    [ "Enedis",[0.13436941902313596, 48.068205256212515]],
    [ "Enedis",[0.13491321200904746, 48.060481342586876]],
    [ "Enedis",[0.137597, 48.0622555]],
    [ "Enedis",[0.13760362523540465, 48.05965537099811]],
    [ "Poste électrique de Quimper",[-4.071540646003033, 47.99320350432195]],
    [ "Sous-station SNCF de Kerangall",[-4.060667812177268, 47.99120144932668]],
    [ "Enedis",[2.804760833333333, 49.402224999999994]],
    [ "Enedis",[5.675161213898532, 43.17997044673853]],
    [ "Enedis",[1.6907967000000002, 43.769954000000006]],
    [ "Bateliers",[5.8999875331901945, 48.684774342100546]],
    [ "Enedis",[2.37372975, 47.10314655]],
    [ "Enedis",[4.671723226190476, 45.09924544047619]],
    [ "Enedis",[5.685498901600165, 45.91213635093516]],
    [ "Enedis",[5.3747284889071265, 45.56196282871913]],
    [ "Enedis",[2.3922654999999997, 47.10807224999999]],
    [ "Enedis",[2.3908957500000003, 47.109874250000004]],
    [ "Enedis",[-0.8613343871889027, 46.831764184822525]],
    [ "Enedis",[-0.8342842999999999, 46.814327899999995]],
    [ "Champaurie",[4.5057613992013685, 45.129167652432194]],
    [ "Bois La Grange",[4.497229292871913, 45.14965464443192]],
    [ "Sous-station SNCF de Mardeuil",[3.927730802934982, 49.058250616154716]],
    [ "Poste électrique de Rupreux",[3.416207356059907, 49.366538435063404]],
    [ "RTE",[3.2949919326605075, 48.54771965471985]],
    [ "Chapon",[5.821692246987241, 45.17096068874593]],
    [ "RTE",[3.347542455406614, 49.3951460435054]],
    [ "Poste Électrique de Saint-Dizier",[4.936044291370482, 48.644916988786825]],
    [ "Poste électrique de Chamouilley",[5.042943087445783, 48.60199939499757]],
    [ "RTE",[4.519215378175126, 48.414211532614786]],
    [ "Poste électrique de Marnaval",[4.990787210994244, 48.62770011390373]],
    [ "Poste électrique de Bayard sur Marne",[5.07005381142691, 48.54517547724038]],
    [ "Poste électrique de Chancenay",[4.99852956689654, 48.676864774284645]],
    [ "Poste électrique de Brousseval",[4.969784098944416, 48.49468724067266]],
    [ "RTE",[3.4071749215868237, 49.369212951695026]],
    [ "Enedis",[2.3426055000000003, 47.05410899999999]],
    [ "Enedis",[2.3461670000000003, 47.05419350000001]],
    [ "RTE",[2.9729211092202483, 49.24431121110421]],
    [ "RTE",[3.3800917484614303, 50.326993007806664]],
    [ "RTE",[3.237502721792944, 50.46596956685872]],
    [ "Enedis",[1.802171755688093, 49.190596446874316]],
    [ "RTE",[5.2061034440983445, 46.21534297573082]],
    [ "SNCF Réseau",[5.211979944917704, 46.208349258648454]],
    [ "RTE",[5.305390582043883, 46.08986924243163]],
    [ "ENe",[5.375812360644665, 46.04440576552664]],
    [ "Poste électrique de Bellignat",[5.619502927542136, 46.23661644154674]],
    [ "Poste électrique de la Cluse",[5.568233307332091, 46.17431715314815]],
    [ "Poste électrique de Lannion",[-3.4540363288282316, 48.718336429671645]],
    [ "Poste électrique de Minhy",[-3.2493757459774417, 48.766174419989596]],
    [ "Poste électrique de Guézennec",[-3.143116201570391, 48.707966202591]],
    [ "Poste électrique de Guerlesquin",[-3.592414539780084, 48.535070377454105]],
    [ "Poste électrique de Lanmeur",[-3.6952536583231392, 48.65616229947845]],
    [ "Enedis",[5.0132282168559685, 47.331478421561876]],
    [ "Enedis",[-0.411726393442623, 48.49406359562842]],
    [ "Enedis",[-1.1800743, 46.816804700000006]],
    [ "Enedis",[-1.19121585, 46.83311295000001]],
    [ "Enedis",[-1.1936183150479585, 46.83235114036674]],
    [ "Enedis",[5.006593, 44.6901578]],
    [ "RTE",[1.9716348485993902, 46.58380154400999]],
    [ "Enedis",[0.5664803953735308, 48.36986685566932]],
    [ "Enedis",[0.5632179362214196, 48.36498727717609]],
    [ "Enedis",[0.5715864946004321, 48.37549066594673]],
    [ "Enedis",[0.5648383485763587, 48.366254708848615]],
    [ "Enedis",[0.5677954999999999, 48.36782499999999]],
    [ "SNCF Réseau",[4.917136975346971, 47.08334800677084]],
    [ "Enedis",[4.5549630572634126, 46.609865678722116]],
    [ "SNCF Réseau",[3.1584062805827675, 49.05124980114814]],
    [ "Enedis",[6.575525365706593, 46.396078682680155]],
    [ "RTE",[6.585627966234028, 46.107627743125384]],
    [ "Poste électrique d'Abondance",[6.762592461245212, 46.29340725134604]],
    [ "Sallanches",[6.632725994388499, 45.95632891295075]],
    [ "Enedis",[4.643775825174825, 46.85009802797204]],
    [ "Enedis",[4.638862275209159, 46.849977351827384]],
    [ "Enedis",[4.632293499999999, 46.8445825]],
    [ "Enedis",[3.27686954270538, 48.19171523019194]],
    [ "Enedis",[4.042258154989384, 46.29125353503184]],
    [ "Enedis",[4.131991757705074, 46.7366519374111]],
    [ "RTE",[0.23640448387049778, 43.18410617313026]],
    [ "Enedis",[-0.9529470521345657, 46.32973156734781]],
    [ "Enedis",[-0.9465186882756426, 46.32773072122185]],
    [ "Enedis",[-0.94336825, 46.32866825]],
    [ "Enedis",[-0.953786, 46.320536000000004]],
    [ "Enedis",[2.37385925, 47.10867025]],
    [ "Enedis",[2.3774531000000008, 47.109719]],
    [ "Enedis",[2.3712427500000004, 47.10482325000001]],
    [ "Poste électrique de Vallorcine",[6.9497811850737214, 46.051025059679866]],
    [ "République",[2.869244469734867, 45.110153138235795]],
    [ "Enedis",[-1.1036823499999993, 46.54026575000001]],
    [ "Poste électrique de Plouay",[-3.2835201076426683, 47.93271398129092]],
    [ "La Conception",[5.397281635626784, 43.2927668778787]],
    [ "Enedis",[-1.2139780314235284, 46.54315649609487]],
    [ "Enedis",[-1.1223761798503336, 46.380762445670065]],
    [ "Enedis",[-1.1189695000000006, 46.383598000000006]],
    [ "Enedis",[3.3906216881720423, 49.38950147096775]],
    [ "Enedis",[3.416670553147145, 49.38924691482562]],
    [ "Enedis",[-4.669614759184533, 48.40551012762962]],
    [ "Rue des Bois",[7.362468360173232, 47.73656033423372]],
    [ "Enedis",[-1.3041207500000007, 46.396679250000005]],
    [ "Électricité de France",[5.833419200305336, 43.85014343749801]],
    [ "Enedis",[4.51871232172471, 47.61185647263682]],
    [ "Enedis",[3.388084831725072, 47.971293562722195]],
    [ "Enedis",[6.237868352638353, 46.73306896353497]],
    [ "EDF",[6.650415563523672, 44.67196149277954]],
    [ "RTE",[-0.10041589644177935, 42.99773573900966]],
    [ "Enedis",[6.9359097012488, 48.29360347838617]],
    [ "RTE",[2.424230975494569, 48.65479196258152]],
    [ "Enedis",[-1.4514904999999994, 46.69034175]],
    [ "Enedis",[3.276463725468589, 48.19410840315239]],
    [ "Enedis",[2.6895236060292262, 50.05403909895294]],
    [ "Enedis",[5.955635670079636, 48.195611162305646]],
    [ "Enedis",[5.9554100000000005, 48.193821]],
    [ "RTE",[1.3560457937375734, 46.43851127064842]],
    [ "Poste électrique de Sarrans",[2.7404222988943925, 44.82886260033247]],
    [ "RTE",[5.7149473405902915, 46.625990203799276]],
    [ "262214116",[-2.309579027865292, 47.67513405107899]],
    [ "SNCF Réseau",[-3.4423066499999995, 47.801541750000005]],
    [ "Poste électrique de Lorient",[-3.3555164607909833, 47.756234918538894]],
    [ "262377478",[-3.3364791024045375, 47.769307136371324]],
    [ "Enedis",[4.449766, 47.5518225]],
    [ "Enedis",[4.4534799968479115, 47.55264913203309]],
    [ "262417577",[-3.0494570763027475, 47.70974194475632]],
    [ "RTE",[2.465438842823818, 51.05138603836257]],
    [ "Poste électrique de Barlin",[2.611277141912491, 50.443194031296684]],
    [ "RTE",[2.373239452579406, 50.74932365237237]],
    [ "Sous-station de Neuvy sur Loire",[2.8928822001510026, 47.51081669988384]],
    [ "Poste électrique d’Arques",[2.2959568591244293, 50.730119325701004]],
    [ "Poste électrique de Sully-sur-Loire",[2.3576512324394425, 47.75781672920777]],
    [ "Poste électrique de Pontivy",[-2.9533191981204943, 48.05664466269047]],
    [ "Albert 1",[5.892392054561896, 48.68187075908843]],
    [ "Enedis",[1.8331852453986277, 49.2438063026733]],
    [ "262641170",[-2.8942650000000003, 47.67638105]],
    [ "Sous-station de Cize",[5.461336311590849, 46.20543837163801]],
    [ "Pompage Torse",[5.46309429574551, 43.527033031581446]],
    [ "Poste électrique de Bornel",[2.205450729725746, 49.19469483230313]],
    [ "Enedis",[3.492584700022691, 49.15259446267302]],
    [ "Enedis",[-0.619907200000001, 49.34014765]],
    [ "Enedis",[-0.6211602827004219, 49.33746205063291]],
    [ "Besson B",[5.450232550252018, 43.53852677018964]],
    [ "Plante folie",[5.438774200000001, 43.540829099999996]],
    [ "Poste électrique d'Aix Mouret",[5.435644672467275, 43.51996866213115]],
    [ "Radiotherapie",[5.444125369639482, 43.53429083088233]],
    [ "Enedis",[5.387324527154297, 43.49996779601142]],
    [ "Enedis",[-0.5872141884057971, 49.33750563478261]],
    [ "Enedis",[-0.585141219694484, 49.337186250644336]],
    [ "Enedis",[-0.575614197383501, 49.341737540058375]],
    [ "Enedis",[-0.58289985, 49.34024229999999]],
    [ "Enedis",[-0.580012735694823, 49.33752363633061]],
    [ "Enedis",[-0.5832149999999983, 49.3354885]],
    [ "Enedis",[-0.5896491000000001, 49.33992245000001]],
    [ "Enedis",[-0.6021502, 49.330454499999995]],
    [ "Enedis",[-0.6262839039215686, 49.339564860784314]],
    [ "Enedis",[0.2557382746627683, 49.40892579132131]],
    [ "Enedis",[0.2608382690790213, 49.40781314631948]],
    [ "Enedis",[0.2538981987670066, 49.409955864370744]],
    [ "Enedis",[-0.4776258702481933, 49.19995330662896]],
    [ "Enedis",[2.1069885, 47.979926000000006]],
    [ "Enedis",[2.086040319354839, 47.95653148953028]],
    [ "Enedis",[2.0964774015748033, 47.95123280052494]],
    [ "Enedis",[2.1019125000000005, 47.974764]],
    [ "Enedis",[2.11065755, 47.98667974999999]],
    [ "Enedis",[2.1055016710526315, 47.970100017543864]],
    [ "Enedis",[2.1097391682368776, 47.97290046388515]],
    [ "Enedis",[2.0939067523809523, 47.98291232380953]],
    [ "Enedis",[0.29597856666666605, 49.393684066666665]],
    [ "P12 Espace Selene",[-4.26266165, 48.449711300000004]],
    [ "Enedis",[2.2022849496491945, 48.88489239594167]],
    [ "Enedis",[-4.364586560764673, 47.989087099361505]],
    [ "Enedis",[6.504228681220818, 45.49721795281526]],
    [ "École",[6.498935991883934, 45.49085399645591]],
    [ "Enedis",[0.4910410992248061, 46.98266698914728]],
    [ "Enedis",[0.48956883822843805, 46.98044987762238]],
    [ "Enedis",[0.9590571007064244, 43.14578504304255]],
    [ "Enedis",[0.9907385, 43.172205999999996]],
    [ "Enedis",[-0.42865038182523496, 49.18859774182911]],
    [ "Enedis",[-0.41298129059829114, 49.18891924786325]],
    [ "Enedis",[-0.42607899999999915, 49.1902015]],
    [ "Enedis",[-0.4408528956663178, 49.184216727314976]],
    [ "Enedis",[-0.41737550000000007, 49.1885755]],
    [ "Enedis",[-0.43326770450450447, 49.18709097657658]],
    [ "Enedis",[-0.42612815716410546, 49.18792484597587]],
    [ "Enedis",[-0.45354809444444494, 49.1815659568376]],
    [ "EX LUSA",[2.4691065427350427, 48.799259700189936]],
    [ "Vallée",[0.22955897215276003, 48.010585553049246]],
    [ "Enedis",[6.574801775105485, 48.02586579688413]],
    [ "Enedis",[1.0706903222530013, 47.81534868605724]],
    [ "Enedis",[1.0821480963655241, 47.812409709865]],
    [ "Enedis",[1.0692348214285718, 47.80764153571429]],
    [ "Enedis",[1.0798521664106497, 47.81171625652841]],
    [ "Enedis",[1.0814159999999997, 47.8250205]],
    [ "Enedis",[1.0740235938903855, 47.81634281850854]],
    [ "Enedis",[1.072855749206349, 47.81345047301587]],
    [ "Enedis",[1.078074717159763, 47.81568838067061]],
    [ "Enedis",[1.0779213208722744, 47.817537028037385]],
    [ "Enedis",[1.0755514999999993, 47.811761000000004]],
    [ "Enedis",[1.08536123605707, 47.814280757457844]],
    [ "Enedis",[1.0793579704641352, 47.820684164556965]],
    [ "Enedis",[1.0808805, 47.815186999999995]],
    [ "Enedis",[1.0777474752500322, 47.82286230484871]],
    [ "Enedis",[1.0784004999999992, 47.80406949999999]],
    [ "Enedis",[1.0799246164251213, 47.80817579012346]],
    [ "Enedis",[1.0756862581172624, 47.81902157252977]],
    [ "Enedis",[1.072194205247426, 47.81094507793645]],
    [ "Enedis",[2.2225155, 49.83001]],
    [ "Enedis",[2.223139814022339, 49.832344651594674]],
    [ "Enedis",[2.217642050671716, 49.83122989333694]],
    [ "Enedis",[2.6304780499999993, 50.080962]],
    [ "Enedis",[2.4065836000000003, 45.77052115]],
    [ "Enedis",[1.8885091929561266, 49.27493067483116]],
    [ "Enedis",[1.8848866992337163, 49.265756653256716]],
    [ "Enedis",[1.870136677151595, 49.26575297116645]],
    [ "Enedis",[-0.4160089191374665, 48.58118583737647]],
    [ "RTE",[-1.4756532701400118, 47.885830670197976]],
    [ "Poste électrique de Poisy",[6.0659839471915165, 45.9358129709597]],
    [ "Sous-station de Chandieu",[4.969606222666436, 45.676801250309644]],
    [ "Enedis",[1.2464009999999994, 47.532008]],
    [ "Enedis",[1.250231654993515, 47.5293805226978]],
    [ "Enedis",[-0.41474257671253134, 49.19715315283326]],
    [ "Enedis",[-0.40965911482317513, 49.19069984800602]],
    [ "RTE",[0.255788055541967, 47.825275166697594]],
    [ "Enedis",[-2.68450413111027, 47.990511831763285]],
    [ "Enedis",[4.83395211781726, 45.75820746051018]],
    [ "Enedis",[-0.3875084388472881, 49.231000155434536]],
    [ "Enedis",[-0.3860759725633528, 49.233062395272896]],
    [ "Enedis",[6.362935381728395, 43.49499471960493]],
    [ "Enedis",[6.363666000000001, 43.496207]],
    [ "Enedis",[1.1156206499999999, 43.00863030000001]],
    [ "Enedis",[4.864423203255526, 45.06926593139223]],
    [ "Enedis",[1.181307675336028, 43.46353734336797]],
    [ "Enedis",[2.2071335000000003, 49.813244]],
    [ "Enedis",[2.2032461706036743, 49.81590945931758]],
    [ "Enedis",[2.199664389451477, 49.80217649335443]],
    [ "Enedis",[2.1755752975391496, 49.89315915883669]],
    [ "Enedis",[2.172406713978494, 49.89163892903226]],
    [ "Enedis",[2.2238226556886227, 49.81583381636726]],
    [ "Enedis",[2.220781719169719, 49.81537115332288]],
    [ "Enedis",[4.836982796747967, 45.059991731707306]],
    [ "Enedis",[4.7985500000000005, 45.06224100000001]],
    [ "Enedis",[-0.18843010000000002, 45.04003695000001]],
    [ "Enedis",[0.3324996499999987, 46.5651235]],
    [ "Enedis",[0.08484064999999939, 49.52123015]],
    [ "Enedis",[-0.20932001135684392, 48.64491227455668]],
    [ "Enedis",[-0.2051816995397756, 48.64541396383959]],
    [ "Enedis",[-0.21254593265228883, 48.63964418766554]],
    [ "Enedis",[-0.20740537138364729, 48.64229480597484]],
    [ "Enedis",[2.173260536261725, 49.86882985152139]],
    [ "Poste électrique de Trinité Victor",[7.31897431122528, 43.74717455553793]],
    [ "Enedis",[2.118801, 49.85161600000001]],
    [ "Enedis",[5.943186150171574, 45.822679900239194]],
    [ "Enedis",[5.884607, 48.189761499999996]],
    [ "Enedis",[5.888495828773639, 48.190039382062885]],
    [ "Enedis",[5.883006291013194, 48.1873445571322]],
    [ "Enedis",[5.8828105, 48.18988699999999]],
    [ "Enedis",[6.922725332060194, 49.193142297881415]],
    [ "Enedis",[6.9295911000000014, 49.1939943]],
    [ "Enedis",[6.916289306415755, 49.19008373225242]],
    [ "Enedis",[6.913266432692112, 49.19149570983284]],
    [ "HLM Pl d'armes",[-0.3524199537061086, 49.180878252774654]],
    [ "Poste électrique de Takamaka",[55.62121765, -21.09367965]],
    [ "Enedis",[6.915811069923914, 49.19838341907919]],
    [ "Enedis",[6.9258715, 49.196841299999996]],
    [ "Enedis",[6.922869829296098, 49.19864336546015]],
    [ "Enedis",[6.92552795, 49.20410685]],
    [ "Enedis",[3.2379889696197153, 48.305042837051204]],
    [ "Enedis",[3.2420324999999997, 48.281465999999995]],
    [ "Enedis",[6.9322997609401735, 49.200531283765784]],
    [ "Enedis",[-1.2529288373797887, 46.469202974243615]],
    [ "Enedis",[-1.2589522499999999, 46.46184625]],
    [ "Enedis",[6.9407293954180975, 49.2016060172394]],
    [ "Enedis",[2.703864227651339, 48.20190137263122]],
    [ "Enedis",[6.936204369545998, 49.199183354187255]],
    [ "Enedis",[6.9415132, 49.20618085]],
    [ "Enedis",[0.9127089999999999, 43.121264000000004]],
    [ "St Roch",[-1.3893426594523368, 48.80051141417943]],
    [ "Enedis",[0.8826617029163263, 43.05203882284001]],
    [ "Enedis",[3.207668914914043, 48.32332620415271]],
    [ "Enedis",[3.2055421050975443, 48.32094079546884]],
    [ "Enedis",[6.9468062383419, 49.20322772855592]],
    [ "Enedis",[6.9435419, 49.20448880000001]],
    [ "Enedis",[3.230095459259257, 48.42608933333334]],
    [ "Enedis",[-0.5199158024983558, 49.27037888632478]],
    [ "Enedis",[-0.5150987944557592, 49.26990396700611]],
    [ "Enedis",[-0.305964, 49.111302949999995]],
    [ "Enedis",[-0.3073783960496249, 49.11227856764611]],
    [ "Enedis",[-0.26639940000000134, 49.1012294]],
    [ "Enedis",[-0.3081701980600851, 49.09312216960497]],
    [ "Enedis",[-0.22424610000000003, 49.079844099999995]],
    [ "Sous-station de Cornier",[6.2925767285590215, 46.085239033907655]],
    [ "RTE",[6.507446454045152, 46.066364947787264]],
    [ "Enedis",[3.3355596397582854, 48.40090972573496]],
    [ "Enedis",[4.082414669002697, 49.91084783629831]],
    [ "Enedis",[6.558327484046694, 43.47846983683529]],
    [ "Enedis",[6.562864591943957, 43.47413280677174]],
    [ "Enedis",[6.569547252032521, 43.4755255691057]],
    [ "Enedis",[4.087715345903166, 49.915314462988825]],
    [ "Enedis",[4.091723150521609, 49.91069701142573]],
    [ "Enedis",[2.172674425359712, 49.77891499460431]],
    [ "Enedis",[2.173505064976028, 49.77557111809236]],
    [ "Enedis",[6.533552899999999, 43.4968136]],
    [ "Enedis",[6.539247151387424, 43.49020227701674]],
    [ "Enedis",[6.536082249999998, 43.490967149999996]],
    [ "SNCF Réseau",[0.23979674934501408, 43.18246867723355]],
    [ "Enedis",[4.073839411973153, 49.91774421579128]],
    [ "Enedis",[4.0805634929358785, 49.915493646017694]],
    [ "Enedis",[4.083407713240675, 49.914663536454526]],
    [ "Poste électrique de l'Epinette",[6.584429098287834, 46.060315789933895]],
    [ "RTE",[5.821235953302105, 45.91193007078494]],
    [ "Enedis",[2.0915284115413955, 48.88361734816054]],
    [ "Enedis",[2.1193177753527825, 48.90249214104625]],
    [ "Enedis",[2.0990379276286237, 48.8859552992]],
    [ "Enedis",[2.087431028338883, 48.87978692193467]],
    [ "Enedis",[2.1160310028834903, 48.89574003917385]],
    [ "Enedis",[2.1130540600988956, 48.88937405634631]],
    [ "Enedis",[2.0950831087528594, 48.885317100277526]],
    [ "Enedis",[2.114759012635694, 48.904055476467484]],
    [ "PC PRAIRIES 3",[2.105246734782852, 48.90082078046765]],
    [ "Enedis",[1.3800239480451788, 48.600032853547646]],
    [ "Enedis",[1.330752194879806, 48.56357365004503]],
    [ "Enedis",[1.385553739200809, 48.59765420841342]],
    [ "RTE",[5.177495163656316, 46.1482282618626]],
    [ "Enedis",[1.682567816663877, 48.795185500105504]],
    [ "Enedis",[1.6690905499999997, 48.77324365]],
    [ "Enedis",[1.6878402555448406, 48.782725716784114]],
    [ "Enedis",[1.6967100500000016, 48.790386899999994]],
    [ "Enedis",[1.6733441364661557, 48.7762991591735]],
    [ "Enedis",[1.6803509216368047, 48.79088881418498]],
    [ "Enedis",[1.7024629007898449, 48.79584232149546]],
    [ "Enedis",[1.67337495, 48.79207805]],
    [ "Enedis",[1.6657926980330895, 48.77664466150645]],
    [ "Enedis",[6.274257800000001, 46.26822955]],
    [ "Enedis",[4.081634912878788, 49.920269579545455]],
    [ "Enedis",[-0.24190339999999988, 49.10456789999999]],
    [ "Enedis",[-0.21625035, 49.11004825]],
    [ "Enedis",[4.750838752525253, 45.12549013434344]],
    [ "Enedis",[2.0840520544431977, 48.64314616890211]],
    [ "Enedis",[4.089409513127854, 49.91999309988584]],
    [ "Poste électrique de Saint-Ouen",[2.327626368378979, 48.910407682316304]],
    [ "Enedis",[0.14926340746569094, 47.639471064569555]],
    [ "Enedis",[0.1509875, 47.64142975]],
    [ "Enedis",[6.912059200000001, 49.010733599999995]],
    [ "Enedis",[4.081883899599663, 49.924929430151714]],
    [ "Enedis",[4.084575416278793, 49.92350469137129]],
    [ "Enedis",[-1.797105578632855, 46.54255831695338]],
    [ "Enedis",[2.3726355, 47.102543499999996]],
    [ "Enedis",[2.3608884999999997, 47.1007925]],
    [ "Enedis",[2.36318025, 47.101498]],
    [ "Enedis",[6.689466499999998, 43.448187499999996]],
    [ "Enedis",[0.16562794999999855, 47.636552349999995]],
    [ "Enedis",[4.071576535179338, 49.928870329770874]],
    [ "Enedis",[4.077360051702091, 49.92410110412996]],
    [ "Enedis",[4.073683101868967, 49.92856670281372]],
    [ "Enedis",[4.074203, 49.9259365]],
    [ "Enedis",[4.076762082706767, 49.925013360902255]],
    [ "Enedis",[5.937387999999999, 48.202796000000006]],
    [ "Enedis",[-0.35631676161493175, 47.58234763866909]],
    [ "Enedis",[-0.3537325568423856, 47.58155318250798]],
    [ "Enedis",[0.15344149999999998, 47.647451000000004]],
    [ "Enedis",[4.805233283246977, 45.11260147668394]],
    [ "Enedis",[4.784757907172995, 45.11055103507383]],
    [ "Enedis",[6.252986476914152, 46.26403940587781]],
    [ "Enedis",[4.092687598052235, 49.9303066206286]],
    [ "Enedis",[4.096374566137565, 49.92812532098766]],
    [ "Enedis",[4.097663157894737, 49.924108157894736]],
    [ "Enedis",[4.091462439400192, 49.925372276241575]],
    [ "Enedis",[4.099531191007602, 49.92750634400776]],
    [ "Enedis",[4.095691291970803, 49.92292798418491]],
    [ "Enedis",[1.274034800000001, 43.912078]],
    [ "Enedis",[1.271204488037267, 43.91124032686511]],
    [ "Enedis",[1.2600569999999998, 43.92488409999999]],
    [ "Enedis",[1.263439177875619, 43.92308616502216]],
    [ "Enedis",[1.8200497378870646, 49.29231596320584]],
    [ "Enedis",[2.3397175000000003, 47.096363999999994]],
    [ "Enedis",[2.36329325, 47.105081]],
    [ "Enedis",[6.9381250100965515, 49.01358307687237]],
    [ "Enedis",[6.259240000000002, 46.27227155]],
    [ "Enedis",[4.088302067619972, 49.92376393375343]],
    [ "Enedis",[4.0732436578619176, 49.933219804634426]],
    [ "Enedis",[4.07107677374196, 49.93257632160424]],
    [ "Enedis",[4.0989187250437835, 49.957731040863976]],
    [ "Enedis",[-0.3233795, 49.09834899999999]],
    [ "Enedis",[-0.32116899999999865, 49.09790649999999]],
    [ "Enedis",[-0.31972762308711783, 49.09417670334426]],
    [ "Enedis",[-0.322872799999999, 49.093727349999995]],
    [ "Enedis",[2.152054047658631, 42.8911099016348]],
    [ "Enedis",[2.1695726459330142, 42.87054478446115]],
    [ "Enedis",[2.1576188784366166, 42.86843839931639]],
    [ "Enedis",[-0.05457225, 48.039035500000004]],
    [ "Enedis",[4.173836391949155, 47.198529972410554]],
    [ "Enedis",[3.0378430000000005, 50.62113900000001]],
    [ "Enedis",[0.15471580205794666, 47.6428984627674]],
    [ "Enedis",[0.15826990000000002, 47.649079]],
    [ "Enedis",[0.15457925000000003, 47.64872325000001]],
    [ "Enedis",[0.18159822277567267, 48.031770588848886]],
    [ "Enedis",[6.24880899836553, 46.27396401102407]],
    [ "Enedis",[4.270544572007087, 47.65132676689445]],
    [ "Enedis",[4.275026499999999, 47.6487545]],
    [ "Enedis",[4.261913900000001, 47.649849399999994]],
    [ "Enedis",[6.2575601, 46.2857149]],
    [ "Enedis",[0.7008445499999995, 47.937191199999994]],
    [ "Enedis",[0.6754096500000013, 47.9811094]],
    [ "Poste électrique de Malgovert",[6.791300767779765, 45.619656276292815]],
    [ "Enedis",[2.702887593171045, 48.297787647389335]],
    [ "Enedis",[2.7156535252578, 48.31007729095253]],
    [ "Enedis",[2.698403037297339, 48.29577003245639]],
    [ "Enedis",[5.140828959366458, 44.776458734268374]],
    [ "Enedis",[-0.9932574627703342, 44.65472737480505]],
    [ "Enedis",[-0.9885542500000001, 44.6541435]],
    [ "Enedis",[-0.9876667257582893, 44.64249811460952]],
    [ "Enedis",[-0.9853057637942921, 44.6543143574363]],
    [ "Enedis",[-0.9833847949514256, 44.64254054620728]],
    [ "Enedis",[-0.9852719730619143, 44.64555946007822]],
    [ "Enedis",[-0.9839819178354305, 44.65056541859748]],
    [ "Enedis",[-0.9808171271381849, 44.641618588563865]],
    [ "Enedis",[-0.9820493118491286, 44.645570936536274]],
    [ "Enedis",[-0.9787797391041949, 44.63917302193064]],
    [ "Enedis",[-0.9677567391835751, 44.638680317636286]],
    [ "Enedis",[-0.9553370930445554, 44.63936863551384]],
    [ "Enedis",[-0.9631905377783729, 44.64318235198688]],
    [ "Enedis",[-0.9743215201595921, 44.64804033814828]],
    [ "Enedis",[-0.9606382767559937, 44.635404755484956]],
    [ "Enedis",[-0.9770858167997649, 44.633129926915245]],
    [ "Enedis",[-0.9614658808438076, 44.64118503953532]],
    [ "Enedis",[-0.9581954750325018, 44.64649946687969]],
    [ "Enedis",[-0.9676432689482785, 44.64952979359526]],
    [ "Enedis",[-0.9693918499999999, 44.65175945]],
    [ "Enedis",[-0.95960329779507, 44.6472690688716]],
    [ "Enedis",[-0.9596938636605415, 44.63408205885137]],
    [ "Enedis",[-0.9718167359192685, 44.63995030380006]],
    [ "Enedis",[-0.9722213664920213, 44.6416824498116]],
    [ "Enedis",[-0.9694756180195228, 44.64023624572247]],
    [ "Enedis",[-0.9522347499999996, 44.64673430000001]],
    [ "Enedis",[-0.97506523388976, 44.64251189511206]],
    [ "Enedis",[-0.9681761819968246, 44.64759329658728]],
    [ "Enedis",[-0.9628111667567834, 44.64685780182692]],
    [ "Enedis",[-0.9600813889856468, 44.64771513237073]],
    [ "Enedis",[-0.9547955555452018, 44.64363155808443]],
    [ "Enedis",[-0.9558020625026569, 44.63704798651833]],
    [ "Enedis",[-0.9681632797400586, 44.65010261360652]],
    [ "Enedis",[-0.9713584118018516, 44.641784131774365]],
    [ "Enedis",[-0.9759109975926442, 44.64135358922783]],
    [ "Enedis",[-0.9789035875578457, 44.63441787355804]],
    [ "Enedis",[-0.9673110119121661, 44.64422301500011]],
    [ "Enedis",[-0.9656132274815179, 44.64380755693571]],
    [ "Enedis",[-0.9715719139951078, 44.64303488270356]],
    [ "Enedis",[-0.9698646244991286, 44.64955667039301]],
    [ "Enedis",[-0.9747800499999992, 44.650135]],
    [ "Enedis",[-0.96554141926, 44.647541581378746]],
    [ "Enedis",[-0.9652610801958964, 44.64071693419291]],
    [ "Enedis",[4.8035605, 45.183907000000005]],
    [ "Enedis",[4.808379225120772, 45.180508248792265]],
    [ "Enedis",[4.80533806679578, 45.184382749410375]],
    [ "Enedis",[4.80743, 45.1780611]],
    [ "Enedis",[4.810272288288288, 45.183075702702695]],
    [ "Enedis",[4.803209600509987, 45.18763677022241]],
    [ "Enedis",[0.9528336122807016, 43.15975886140351]],
    [ "Enedis",[4.796735406392695, 45.19117124983691]],
    [ "Enedis",[4.800707317997045, 45.18942158130082]],
    [ "Enedis",[4.799780947878789, 45.18330510303031]],
    [ "Enedis",[3.602393999597336, 43.42185894709719]],
    [ "Enedis",[4.5631409288582025, 44.971095786254956]],
    [ "Enedis",[4.559555499328695, 44.97524141047236]],
    [ "Enedis",[4.563609763592446, 44.9786539361221]],
    [ "Enedis",[4.566144602446483, 44.980096308868504]],
    [ "Enedis",[4.573007380503144, 44.989129452544304]],
    [ "Enedis",[4.5806007792618635, 44.993067150322204]],
    [ "Enedis",[4.575906674757712, 44.98573331767736]],
    [ "Enedis",[4.579545898523404, 44.98497770248193]],
    [ "Enedis",[4.578124914928426, 44.98474071901842]],
    [ "Enedis",[4.5836265, 44.98652500000001]],
    [ "Enedis",[4.585097674731182, 44.98535522849462]],
    [ "Enedis",[4.587376000000001, 44.989326]],
    [ "RTE",[6.41009725408725, 45.24596293540985]],
    [ "Enedis",[-0.53306695, 44.834553400000004]],
    [ "Enedis",[1.5773776666666672, 46.44333366666667]],
    [ "Enedis",[1.5741496500000003, 46.443892600000005]],
    [ "Enedis",[1.5787614000000005, 46.44133958095238]],
    [ "Enedis",[4.632771934624469, 44.35031542382488]],
    [ "Enedis",[4.646421150000001, 44.36755904999999]],
    [ "Enedis",[4.6409145363572835, 44.38234473738625]],
    [ "Enedis",[4.6459475011240485, 44.39822851719521]],
    [ "Enedis",[4.6355500685983015, 44.37038424691075]],
    [ "Enedis",[4.6349689290072105, 44.38315090130338]],
    [ "Enedis",[4.645083116635476, 44.36792846419152]],
    [ "Enedis",[4.63924505697768, 44.380915584752536]],
    [ "Enedis",[1.9668195457793214, 47.87323429735096]],
    [ "Enedis",[1.9666261298170997, 47.886799618682126]],
    [ "Enedis",[1.9546802823709564, 47.87503808753472]],
    [ "Enedis",[1.9586373792836973, 47.89160082550156]],
    [ "Enedis",[1.9617046120456412, 47.87527555186056]],
    [ "Enedis",[1.9629757000000005, 47.873853849999996]],
    [ "Enedis",[1.9911626862415932, 47.88801535257236]],
    [ "Enedis",[1.9716966, 47.8641569]],
    [ "Enedis",[1.947597182755611, 47.88261083587148]],
    [ "Enedis",[1.9565815585043205, 47.87718899207672]],
    [ "Enedis",[1.9864705403180782, 47.886530096722744]],
    [ "Enedis",[1.954550050274867, 47.883138395256005]],
    [ "Enedis",[1.939095540612719, 47.87808689274476]],
    [ "Enedis",[1.9636812477184913, 47.878325942122615]],
    [ "Enedis",[1.9433575484891548, 47.87788039565871]],
    [ "Enedis",[1.9498778245716508, 47.89107182758924]],
    [ "AC3M Morpoix",[1.9587243865446884, 47.87941365126262]],
    [ "Enedis",[1.945801293968255, 47.89112961296296]],
    [ "Enedis",[1.949978470621654, 47.8808465445985]],
    [ "Enedis",[1.9500528874067444, 47.879562993483866]],
    [ "Enedis",[1.9578456773882287, 47.883195974526565]],
    [ "Enedis",[1.9413619248430116, 47.8803291786756]],
    [ "Enedis",[1.9536612499999997, 47.72251035]],
    [ "Enedis",[1.9495075327065914, 47.719480300984586]],
    [ "Enedis",[1.9222749751070334, 47.722188441773696]],
    [ "Enedis",[1.9288660512775329, 47.72752508270191]],
    [ "Enedis",[1.9427680423490483, 47.71206110901572]],
    [ "Enedis",[1.9386132853443705, 47.728430150052716]],
    [ "Enedis",[1.9473714256388177, 47.72015545560988]],
    [ "Enedis",[1.9375244604040547, 47.71907138015896]],
    [ "Enedis",[1.9473406383931824, 47.72121857743409]],
    [ "Enedis",[1.9426693648828641, 47.70702803143891]],
    [ "Enedis",[1.9549511656819698, 47.71948455384032]],
    [ "Enedis",[1.9347547064652495, 47.71581367940702]],
    [ "Enedis",[1.9488755944393008, 47.71423832665115]],
    [ "Enedis",[1.9388085549282355, 47.71707172777037]],
    [ "Enedis",[1.95398452013381, 47.745061144330975]],
    [ "Enedis",[2.0232139025470657, 49.31125433942414]],
    [ "Enedis",[2.0081788655337554, 49.327914951555805]],
    [ "Enedis",[-0.2867802448979589, 49.081116458049884]],
    [ "Enedis",[-0.2801454373719249, 49.08169752536213]],
    [ "Enedis",[-0.2776442512820512, 49.07949066153846]],
    [ "Enedis",[2.3006112760416664, 49.62611290625]],
    [ "GreenAlp",[5.739109644153671, 45.196553011108634]],
    [ "Enedis",[2.28398375, 43.738870750000004]],
    [ "Bourg",[0.19353865, 48.075922049999996]],
    [ "La Pièce",[0.1937271824957575, 48.07370470362104]],
    [ "Enedis",[0.2005925000000008, 48.081322750000005]],
    [ "Sous-station SNCF de Cheppes",[4.479661544619068, 48.829070335838765]],
    [ "Sous-station SNCF de Révigny",[4.999912717614873, 48.82423129929014]],
    [ "Enedis",[1.501932122112211, 46.57502937623762]],
    [ "Enedis",[1.5201922784918593, 46.58655952870608]],
    [ "Enedis",[1.5276047681801406, 46.58884640199335]],
    [ "Enedis",[1.5291129728035584, 46.59326836912345]],
    [ "Enedis",[1.519108960090362, 46.58729076104417]],
    [ "Enedis",[1.5179901293840254, 46.590996361037426]],
    [ "Enedis",[1.5249660696479055, 46.58581316319712]],
    [ "Enedis",[1.524193, 46.59418349999999]],
    [ "Enedis",[1.522114021778584, 46.589186863883846]],
    [ "Enedis",[1.522446166897827, 46.58616399583911]],
    [ "Enedis",[1.5312046067415728, 46.59017727340824]],
    [ "Enedis",[1.5129824966476533, 46.59415916571601]],
    [ "Enedis",[1.5263118405498282, 46.592731465979384]],
    [ "Enedis",[1.5243005434173684, 46.591782067226895]],
    [ "Enedis",[1.5217241591609503, 46.58471181926633]],
    [ "Enedis",[1.5194830480995631, 46.59026786915573]],
    [ "Enedis",[1.5145492512820513, 46.58249492820514]],
    [ "Enedis",[1.5228793517060364, 46.57696910498688]],
    [ "Enedis",[1.5147348426592795, 46.57985542179132]],
    [ "Enedis",[1.5109855000000005, 46.5761225]],
    [ "Enedis",[1.5143672868483324, 46.58544644798108]],
    [ "Enedis",[1.5126052517006805, 46.57261890476191]],
    [ "Enedis",[1.5102249159779624, 46.57226606139315]],
    [ "Enedis",[1.5077248479532157, 46.60284518128654]],
    [ "Enedis",[1.5134474398790965, 46.600349315386794]],
    [ "Enedis",[1.5084158092769444, 46.604651551250576]],
    [ "Enedis",[1.5112270058106638, 46.59594205667768]],
    [ "Enedis",[1.5157789235550032, 46.59496001450176]],
    [ "Enedis",[1.5173957174992698, 46.60235784039342]],
    [ "Enedis",[0.2250377544125018, 48.072964829406324]],
    [ "Enedis",[0.22431749999999998, 48.07472425]],
    [ "Enedis",[0.22278656727976778, 48.07139892223298]],
    [ "Enedis",[-0.28798660000000004, 43.143220050000004]],
    [ "Enedis",[-0.25772800000000007, 43.14033525]],
    [ "Enedis",[-0.256292234182682, 43.14246747261838]],
    [ "Enedis",[2.288804894695864, 49.63648361148418]],
    [ "Enedis",[2.2800461736065216, 49.63997549041639]],
    [ "Enedis",[2.2903553333333333, 49.63736489542484]],
    [ "Béton Vernon",[1.461030188590422, 49.1030419393745]],
    [ "Socar",[1.464670705383213, 49.10027391575198]],
    [ "Branthomme",[1.4622869, 49.10199540000001]],
    [ "Enedis",[0.05802950000000141, 47.618317999999995]],
    [ "Poste électrique de la Maie",[2.480615109412095, 50.51936288347053]],
    [ "Enedis",[-4.264159775878443, 48.35535594776828]],
    [ "Enedis",[2.90263375, 43.211035849999995]],
    [ "Communal",[2.901778414634146, 43.21282228455284]],
    [ "Enedis",[3.5322139999999997, 43.336454499999995]],
    [ "Colmir",[6.138885599999998, 45.8904678]],
    [ "Côte Perrière",[6.127540028062014, 45.89776363751938]],
    [ "Pont neuf",[5.821193577593856, 46.105731901311884]],
    [ "Enedis",[5.824459882026377, 46.105545992118046]],
    [ "Enedis",[0.6673986000000001, 48.02232705]],
    [ "Centre médical",[6.123361050000001, 45.91104430000001]],
    [ "Pont Ballon",[1.4576429665988977, 49.105487799436546]],
    [ "Sous-station SNCF de Vidauban",[6.433306402153987, 43.43316136908163]],
    [ "Sous-station SNCF de la Pinède",[6.962247164732569, 43.54376044691283]],
    [ "Sous-station SNCF de Pont-Saint-Jean",[7.327668806780502, 43.70226128487895]],
    [ "Sous-station RFF de Vouzon",[1.983895, 47.648955699999995]],
    [ "Sous-station RFF de La Ravelle",[1.5118942447235009, 46.52203704547937]],
    [ "Enedis",[-1.306711566666666, 47.73369766666666]],
    [ "Sous-station RFF d'Amonville",[1.9704523999999997, 48.25249714999999]],
    [ "Poste d'Auvilliers",[1.8783813704913899, 48.07023036029263]],
    [ "Sous-station RFF de Noyant",[0.5767907000000001, 47.11047670000001]],
    [ "Sous-station SNCF de Saint-Benoît",[0.3351476325060224, 46.54592505248524]],
    [ "Sous-station SNCF de Bassens (Pichon)",[-0.5220626519335624, 44.90207851033499]],
    [ "Enedis",[2.7678402443617505, 48.5420466256202]],
    [ "Enedis",[2.751284614661431, 48.52756780054743]],
    [ "Enedis",[2.74999725440451, 48.52888963460183]],
    [ "Enedis",[2.7551730677118833, 48.52694188207226]],
    [ "Sous-station SNCF Réseau de Chartres",[1.46682896825927, 48.44346138475037]],
    [ "Sous-station SNCF du Mans",[0.19804393969951362, 47.99320781084863]],
    [ "Sous-station du Gault-Saint-Denis",[1.523287241683855, 48.242474296962]],
    [ "Enedis",[1.9498687408901438, 47.817172077316116]],
    [ "Enedis",[1.9487868596067608, 47.817300521329194]],
    [ "Enedis",[1.9486074999999998, 47.839563]],
    [ "Enedis",[1.9507884999999998, 47.8368469]],
    [ "Enedis",[1.9291666740696267, 47.80437745655144]],
    [ "Enedis",[1.9735779031221308, 47.83351610284665]],
    [ "Enedis",[1.9660778541666668, 47.82969797222223]],
    [ "Enedis",[1.9497848923662857, 47.813891748468286]],
    [ "Enedis",[1.972201679338843, 47.826796155922864]],
    [ "Enedis",[1.9713455515653768, 47.83475471731124]],
    [ "Enedis",[1.9631686672514619, 47.83420799532164]],
    [ "Enedis",[1.9739823330163757, 47.825227507448496]],
    [ "Enedis",[1.9678862466335936, 47.83284706071344]],
    [ "Enedis",[1.9537064999999998, 47.83957249999999]],
    [ "Enedis",[1.9487611304347825, 47.820629159420285]],
    [ "Enedis",[1.920686200000001, 47.813944299999996]],
    [ "RTE",[1.9381592837677535, 47.76934899587528]],
    [ "Enedis",[2.2950680613777346, 49.63261531864185]],
    [ "AYDES (LES)",[1.8902264482751514, 47.93664834359844]],
    [ "Enedis",[3.2937014999999996, 43.252810499999995]],
    [ "Enedis",[1.5320037026108086, 43.520336830479664]],
    [ "Enedis",[1.5373879000000001, 43.51845470000001]],
    [ "Enedis",[1.5590788095709562, 43.520185730033006]],
    [ "Enedis",[1.5609185490445858, 43.5197325908705]],
    [ "Enedis",[7.665027092970792, 48.3093327266461]],
    [ "Enedis",[2.5483780779969316, 48.52226067508219]],
    [ "Enedis",[-0.4820365218080873, 48.98931371679029]],
    [ "Enedis",[-0.4756121268518518, 48.98094376296295]],
    [ "CCAS",[3.353070750000001, 43.27972605]],
    [ "Tramontane",[3.3582432511187297, 43.278374004013486]],
    [ "Riviérette",[3.353439, 43.277531]],
    [ "Tour de l'Orb 2",[3.3579163059951713, 43.28045442678047]],
    [ "Exhaure",[3.3517291999999994, 43.27759759999999]],
    [ "Enedis",[3.0543687696423887, 47.942187391439084]],
    [ "Enedis",[2.9302568313632786, 43.19660607486209]],
    [ "Enedis",[2.927672330218069, 43.19509817712506]],
    [ "Enedis",[2.9240568100696795, 43.19244852933244]],
    [ "Enedis",[2.9345171888598793, 43.18433268494342]],
    [ "Enedis",[2.9369980261780104, 43.18057215881326]],
    [ "Enedis",[2.943221158212448, 48.253417892334056]],
    [ "Enedis",[1.8162467865921896, 48.958682699973956]],
    [ "Enedis",[3.0083527941125285, 48.22638302091027]],
    [ "Enedis",[3.023411132812763, 48.25720888325776]],
    [ "Enedis",[2.991917063354721, 48.23651631688777]],
    [ "Enedis",[2.972161331031106, 48.27907996699431]],
    [ "Enedis",[2.9610556220291655, 48.28514991788003]],
    [ "Enedis",[2.9619309054832237, 48.28063147758762]],
    [ "Enedis",[2.970153645176708, 48.285907086841036]],
    [ "Enedis",[2.968859867151353, 48.278753221302686]],
    [ "Enedis",[2.9749777475108323, 48.287489620298096]],
    [ "Enedis",[2.960750109198555, 48.281817457926444]],
    [ "Enedis",[2.967440851655733, 48.27718467496916]],
    [ "Enedis",[2.975706359674443, 48.34841709631861]],
    [ "Enedis",[2.972142666666667, 48.35912161362764]],
    [ "Enedis",[2.9670784158238335, 48.511878567179906]],
    [ "Poste électrique de la Martiniere",[4.737448115240363, 44.282825575357855]],
    [ "Sous-station SNCF de La Croisière",[4.697554834849967, 44.27101015553029]],
    [ "RTE",[4.698791518510983, 44.27158166383631]],
    [ "RTE",[4.709076549999999, 44.36720194999999]],
    [ "Sous-station SNCF de La Combette",[4.634294564532474, 44.31662315716083]],
    [ "EDF",[4.73193835, 44.328768149999995]],
    [ "Avulliens",[6.052703183832164, 45.86030457976161]],
    [ "Chez Chamoux D-P",[6.066894, 45.885764099999996]],
    [ "Le Corbier",[6.056039350000001, 45.8802131]],
    [ "Montagny",[6.045696084920713, 45.86152169757471]],
    [ "ZAC Altais P13",[6.081134844376573, 45.89203946998909]],
    [ "ZAE Cesardes 2",[6.07621657081083, 45.88878169725534]],
    [ "Enedis",[6.108350470645947, 45.89083423261474]],
    [ "Enedis",[1.6106543531420043, 47.71682623199813]],
    [ "Enedis",[1.61230975, 47.711733499999994]],
    [ "Enedis",[1.5985526, 47.710336]],
    [ "Enedis",[1.605534912280702, 47.70913707017543]],
    [ "Enedis",[1.6105989107454806, 47.71845185352428]],
    [ "Enedis",[1.622937157601116, 47.71860462343096]],
    [ "Enedis",[1.6043424163138322, 47.70833195517086]],
    [ "Enedis",[1.5850201510225244, 47.70192575830741]],
    [ "Enedis",[1.5860961963109357, 47.68758535046113]],
    [ "Enedis",[1.5825385, 47.699031500000004]],
    [ "Enedis",[1.5786562916213276, 47.69726281682988]],
    [ "Enedis",[1.559759483146068, 47.68678183021223]],
    [ "Enedis",[1.5707024406779662, 47.68168325988701]],
    [ "Enedis",[1.5629218703027667, 47.68223266702882]],
    [ "Enedis",[1.6266414129051596, 47.716868937046186]],
    [ "Enedis",[1.6266092828732568, 47.7176518429801]],
    [ "Enedis",[1.613628686900958, 47.71833336208733]],
    [ "Enedis",[1.616852311890838, 47.71457827436647]],
    [ "Enedis",[1.6150528382389941, 47.71292144930817]],
    [ "Enedis",[1.6197103346163717, 47.71532850654349]],
    [ "Enedis",[1.6198239257124556, 47.716067661543384]],
    [ "Enedis",[1.5598919527934163, 47.68527715511767]],
    [ "Enedis",[1.5570027579617833, 47.6830570955414]],
    [ "Enedis",[1.5634545504829944, 47.6873115069472]],
    [ "Enedis",[1.591923568188807, 47.707738489929284]],
    [ "La Rivoire",[5.75927875, 44.95427524999999]],
    [ "La Festinière",[5.754748229227657, 44.95324700920131]],
    [ "Enedis",[2.5670679999999995, 48.518560449999995]],
    [ "Enedis",[3.068943983802881, 48.54980182351381]],
    [ "Enedis",[3.064823840713824, 48.54945034527413]],
    [ "Enedis",[1.9585270348098174, 48.53148653396555]],
    [ "Enedis",[1.9556952552464437, 48.5307622121819]],
    [ "Enedis",[2.921668779237936, 48.31263822767876]],
    [ "Enedis",[2.9920628606575166, 48.28204320193009]],
    [ "Enedis",[1.4691560674306643, 49.09373992548751]],
    [ "Enedis",[-0.3371802664182964, 49.094750416257895]],
    [ "MAS DE ROUX",[5.0026102, 45.8359285]],
    [ "Enedis",[1.4571637603915597, 49.10101980474728]],
    [ "Enedis",[1.463687832533689, 49.10338635106683]],
    [ "Les Jardins du Levant",[1.4734458, 49.09986189999999]],
    [ "Les Près",[1.4525351841701801, 49.10287325161689]],
    [ "Enedis",[1.4544233336535, 49.10293759916757]],
    [ "Enedis",[1.4556456, 49.1047877]],
    [ "Enedis",[2.552556449734342, 48.520025575831866]],
    [ "Enedis",[1.7767199999999999, 49.351335]],
    [ "Enedis",[1.7728312884764117, 49.34912044392884]],
    [ "Sagebien",[0.19304811689131965, 47.99356710271188]],
    [ "Enedis",[4.321972760456274, 47.61868598986058]],
    [ "Enedis",[4.312051666666666, 47.61238349333333]],
    [ "Enedis",[4.3071628, 47.60977021818182]],
    [ "Enedis",[3.511990000000001, 50.341156]],
    [ "Enedis",[3.5131619074290166, 50.34174230882925]],
    [ "Enedis",[-1.654988365245749, 48.14877836361518]],
    [ "Concorde",[6.110365981471424, 45.89892099106398]],
    [ "Les Ebeaux N1",[6.102038718236373, 46.03230112288983]],
    [ "Sublet",[6.159932899991986, 46.05043314975272]],
    [ "Guirinis",[-1.758213, 48.030534]],
    [ "Enedis",[1.4228009333333331, 46.63886616666667]],
    [ "Enedis",[1.4232060993157405, 46.63634623151508]],
    [ "Enedis",[3.0721710962133346, 45.74732775829334]],
    [ "Enedis",[1.9145535190535763, 46.13993011905998]],
    [ "Sacré Cœur 182",[6.162475247050018, 48.686080998025034]],
    [ "Enedis",[-0.6231407534714006, 49.312517671735584]],
    [ "Enedis",[-0.6218437000000003, 49.31155760000001]],
    [ "Enedis",[-0.6308225266507562, 49.311819881463805]],
    [ "Enedis",[-0.6203403667645403, 49.31386487821575]],
    [ "Enedis",[-1.7094301636655949, 48.03116598027868]],
    [ "EDF",[1.7605244711521517, 45.53421976153277]],
    [ "Enedis",[1.4160290003436422, 46.638757939862536]],
    [ "Enedis",[1.4169578833863397, 46.63722492200516]],
    [ "Enedis",[-0.7200769506243084, 43.08836155833728]],
    [ "Enedis",[2.252764308399735, 46.998551969191624]],
    [ "Enedis",[2.25170863114964, 46.99965677476867]],
    [ "Enedis",[2.2585105878681064, 46.991460533634964]],
    [ "Enedis",[2.237615213880279, 46.99304007508831]],
    [ "Enedis",[2.260209420413718, 46.99190815580526]],
    [ "Enedis",[2.2449358993431776, 46.99412721239177]],
    [ "Enedis",[2.2562494304708105, 47.005941877416596]],
    [ "Enedis",[2.2519938350193223, 46.995265295023295]],
    [ "Enedis",[2.2473199477954307, 46.99770483863936]],
    [ "Enedis",[2.25949617293766, 46.99922987853398]],
    [ "Enedis",[2.2501885970404625, 47.00295700208228]],
    [ "Enedis",[2.2467032469886066, 46.99998102240911]],
    [ "Enedis",[2.255951270582589, 46.998164033126685]],
    [ "Enedis",[2.2328499942605284, 46.99313094028975]],
    [ "Enedis",[2.2564780494463803, 47.00759027846192]],
    [ "Enedis",[2.255998090463213, 46.99818820539009]],
    [ "Enedis",[2.2525560976587404, 47.00325669750021]],
    [ "Enedis",[2.248539337418228, 46.9921710138607]],
    [ "Enedis",[2.2445345392385714, 46.98395794208299]],
    [ "Enedis",[2.74097484043321, 46.289649459881716]],
    [ "Enedis",[2.742073268451531, 46.29413861735646]],
    [ "Enedis",[2.73495082150604, 46.273922544497566]],
    [ "Enedis",[2.74727295, 46.30105429999999]],
    [ "Enedis",[2.739487577199912, 46.29846393684441]],
    [ "Enedis",[2.7355438478390033, 46.286705016367904]],
    [ "Enedis",[2.7433202476434495, 46.28935519496015]],
    [ "Enedis",[2.74218342803099, 46.30066429707607]],
    [ "Enedis",[2.7349113173849795, 46.29789691044027]],
    [ "Enedis",[2.7421214029328893, 46.289592946664186]],
    [ "Enedis",[2.7290804875935417, 46.29864794081713]],
    [ "Enedis",[2.7438795853361473, 46.292468017301054]],
    [ "Enedis",[2.7552987645852913, 46.287912575071545]],
    [ "Enedis",[2.74426478503393, 46.29336998503495]],
    [ "Enedis",[2.736342783061298, 46.294007708352765]],
    [ "Enedis",[2.742130307105132, 46.29895387639924]],
    [ "Enedis",[2.484509847814671, 43.48016418397089]],
    [ "Enedis",[2.736041072527089, 46.291882409901035]],
    [ "Enedis",[2.490838394160584, 43.47636520924574]],
    [ "CAMPS",[-1.0429691631977442, 44.623362342243354]],
    [ "Grosse Borne",[1.4697731167132553, 49.09847504637542]],
    [ "Enedis",[5.4174965626241285, 47.141338877314496]],
    [ "RTE",[2.2061995838628268, 44.699522926246075]],
    [ "Val d'Huisne",[0.23258750000000147, 47.99309269999999]],
    [ "Gare",[0.5145223891252956, 48.115303135224586]],
    [ "Enedis",[0.51190428699187, 48.11057236070461]],
    [ "Les Noyers",[0.5197724866352206, 48.112231886320735]],
    [ "Les Ouches",[0.5095627976710331, 48.117986156623]],
    [ "Enedis",[2.358727, 49.95281850000001]],
    [ "Enedis",[2.363005592451115, 49.95276226978929]],
    [ "Enedis",[2.358074224530168, 49.95093991097922]],
    [ "Enedis",[2.3633152768361576, 49.95592693323061]],
    [ "Enedis",[3.8986216830870277, 49.56690341954022]],
    [ "Enedis",[3.889794187731685, 49.57155428301564]],
    [ "Enedis",[3.893173724303555, 49.57093524447647]],
    [ "Enedis",[3.8858940802577613, 49.57111818570591]],
    [ "Enedis",[3.892368146892655, 49.5696558361582]],
    [ "Enedis",[3.899667574812666, 49.56808686318589]],
    [ "Enedis",[3.8999618235596705, 49.57038845164609]],
    [ "Enedis",[3.882781611965116, 49.572177142845106]],
    [ "Enedis",[3.8957134429223745, 49.57007312557078]],
    [ "Enedis",[3.8820195472962866, 49.571545750541866]],
    [ "Enedis",[3.894650285213033, 49.5661058471178]],
    [ "Enedis",[2.364772351123982, 49.94170682374844]],
    [ "Enedis",[2.361709718327183, 49.93758584378844]],
    [ "Enedis",[2.36114082374914, 49.942094530030474]],
    [ "Enedis",[2.3025222789987794, 49.96804993711844]],
    [ "Enedis",[2.2950670651915295, 49.96948766143231]],
    [ "Enedis",[2.3508056683804623, 49.96959599057411]],
    [ "Enedis",[2.356921666666667, 49.97136741379311]],
    [ "Enedis",[2.354134155764784, 49.97005598942848]],
    [ "Enedis",[2.240653018351296, 49.87792312331293]],
    [ "Enedis",[2.2465706716604243, 49.88284606991261]],
    [ "Sous-station SNCF de Villargoix",[4.274410085176984, 47.28301496014123]],
    [ "Sous-station RFF de Malay-le-Petit",[3.369023983207991, 48.17391184510596]],
    [ "Enedis",[1.8131430000000004, 49.858685]],
    [ "Enedis",[2.429862926456543, 49.908372284622736]],
    [ "But",[1.4899860552495476, 49.09744158371783]],
    [ "C Electroniq",[1.4194388215769869, 49.061670058684506]],
    [ "Chaussée",[1.4897830567063675, 49.0989693332561]],
    [ "ZA Heunière",[1.41947015, 49.0617324]],
    [ "Enedis",[1.4822341838397919, 49.10311574831336]],
    [ "Enedis",[1.4639421999999993, 49.08148924999999]],
    [ "Enedis",[3.162969046853602, 47.019888916465604]],
    [ "Enedis",[3.131119118770043, 47.01249267342388]],
    [ "Enedis",[3.163526380094046, 47.016107189146794]],
    [ "Enedis",[3.1426186644836513, 47.01604049438273]],
    [ "Enedis",[3.1412589402606304, 47.01821098844818]],
    [ "Enedis",[3.1527622044009944, 47.01798106707919]],
    [ "Enedis",[3.1593525420397373, 47.02378242571843]],
    [ "Enedis",[3.1484325030301026, 47.02521070085509]],
    [ "Enedis",[3.1384618381912066, 47.003029904855616]],
    [ "Enedis",[3.1254272995954566, 47.04667878332037]],
    [ "Enedis",[3.1583878699242196, 47.01242812661064]],
    [ "Enedis",[3.12582166083313, 47.04977470440137]],
    [ "Enedis",[3.146167959662256, 47.032511454044496]],
    [ "Enedis",[3.16135347305693, 47.01410724732773]],
    [ "Enedis",[3.128385821607769, 47.00767379216391]],
    [ "Enedis",[3.13839729227938, 47.01497707777682]],
    [ "Enedis",[3.1413699334674914, 47.01156326230901]],
    [ "Enedis",[3.142061580139746, 47.02422488486385]],
    [ "Enedis",[3.1612560506811644, 47.018386469391324]],
    [ "Enedis",[3.152116929254998, 47.014997648907624]],
    [ "Enedis",[3.1430139725334683, 47.010061700739094]],
    [ "Enedis",[3.1497293143298464, 47.0237910561933]],
    [ "Enedis",[3.1279180811163974, 47.00890406712249]],
    [ "Enedis",[3.1590439801270995, 47.01886358618311]],
    [ "Enedis",[3.1488215657312812, 47.01117643682827]],
    [ "Enedis",[3.1327646674835226, 47.00531113689554]],
    [ "Enedis",[3.139048826507444, 47.002834260895845]],
    [ "Enedis",[3.1575152003551166, 47.00892888654215]],
    [ "Enedis",[3.1359255595594853, 46.999762675901245]],
    [ "Enedis",[3.1470370964200147, 47.009080074184595]],
    [ "Enedis",[0.60724346486028, 48.76629892314749]],
    [ "282561865",[0.5989018841853887, 48.758217773411225]],
    [ "Enedis",[0.6088934436497113, 48.75788516232688]],
    [ "Enedis",[2.5355283260426718, 48.56825273314767]],
    [ "Enedis",[3.849433368058167, 49.5299945202457]],
    [ "Enedis",[3.8288620036930663, 49.568278775406924]],
    [ "Pain Perdu",[0.17434938421017465, 48.01817078304567]],
    [ "Enedis",[1.2260328279274335, 49.19426366703316]],
    [ "Enedis",[2.3449574999999996, 49.807905500000004]],
    [ "Enedis",[2.1425708057395147, 49.88411753863135]],
    [ "Enedis",[2.2130595, 49.897642499999996]],
    [ "Enedis",[2.224737948398269, 49.90172596554112]],
    [ "Enedis",[2.216879141304347, 49.89824774528986]],
    [ "Enedis",[2.2255085, 49.9124095]],
    [ "Enedis",[2.2297097426326125, 49.91097422265881]],
    [ "Enedis",[2.2281671604010023, 49.91544925647451]],
    [ "Enedis",[2.237252449001503, 49.915084314293885]],
    [ "Enedis",[2.2329688626919606, 49.912071722673886]],
    [ "Enedis",[2.200986620412727, 49.929711315340036]],
    [ "Enedis",[2.1970175266411345, 49.92855128132769]],
    [ "Enedis",[2.1868263059755226, 49.92390454201379]],
    [ "Enedis",[2.175025033956878, 49.8992332953624]],
    [ "Enedis",[1.4169896015904189, 50.04691088035563]],
    [ "Enedis",[1.4234069446742255, 50.040556963680466]],
    [ "Enedis",[1.4217796873763444, 50.04223354277366]],
    [ "Enedis",[1.4238827019939009, 50.055602567282435]],
    [ "Enedis",[1.415141107682622, 50.046973827969715]],
    [ "Enedis",[1.4190498486119687, 50.042385714400616]],
    [ "Enedis",[1.852409315626649, 47.90957265186479]],
    [ "Enedis",[1.8547359896706839, 47.932507631407965]],
    [ "Enedis",[1.8591072221549894, 47.90371920438069]],
    [ "Enedis",[1.8547629559539816, 47.90421156624533]],
    [ "Enedis",[1.8356291272210443, 47.93454619672422]],
    [ "Enedis",[1.8557992448657072, 47.90218124136029]],
    [ "Enedis",[1.8568800541879842, 47.912327204560064]],
    [ "Enedis",[2.7131594091086937, 47.98422402015332]],
    [ "Enedis",[2.70938803150702, 47.97302840335445]],
    [ "Enedis",[2.712777753654603, 47.990869539696426]],
    [ "Enedis",[2.713896939212342, 47.9890655292679]],
    [ "Enedis",[2.695453223755119, 47.99499976214717]],
    [ "Enedis",[2.7116368836949327, 47.9970504215814]],
    [ "Enedis",[2.7111458466337197, 47.9807231895181]],
    [ "Enedis",[2.7108971419440886, 47.9763631432089]],
    [ "Enedis",[2.723309424091134, 47.97543964878483]],
    [ "Enedis",[2.6930935767133564, 47.98955079483074]],
    [ "Enedis",[2.7010915021555633, 47.98734273267172]],
    [ "Enedis",[2.7232283072833305, 47.98620315485362]],
    [ "Enedis",[2.708237838106275, 47.965741724436356]],
    [ "Enedis",[2.6788573570489387, 47.993316531739254]],
    [ "Enedis",[2.70582805, 47.99595200000001]],
    [ "Enedis",[2.7137882491687786, 47.976821842421124]],
    [ "Enedis",[2.716183210633092, 48.001083077740425]],
    [ "Enedis",[2.2313745806268863, 47.14101832648279]],
    [ "Enedis",[2.203436743084454, 47.13258338186443]],
    [ "Enedis",[2.2315986216888235, 47.14597481125061]],
    [ "Enedis",[2.2147532246137773, 47.15426506708793]],
    [ "Enedis",[2.2128370426572737, 47.13392263667191]],
    [ "Enedis",[2.2109976248419874, 47.13063000348013]],
    [ "Enedis",[2.207272529442003, 47.14053262044019]],
    [ "Enedis",[2.2112511254433396, 47.15403135352469]],
    [ "Enedis",[2.216329363174663, 47.145343200443214]],
    [ "Enedis",[2.213272190873736, 47.14648094102813]],
    [ "Enedis",[2.2134846328412663, 47.15641465708677]],
    [ "Enedis",[2.2142102092101417, 47.144235379487135]],
    [ "Enedis",[2.208086615090431, 47.135246097973436]],
    [ "Enedis",[2.9218107892227416, 45.50702376930508]],
    [ "Enedis",[4.374479593937974, 48.11253278853215]],
    [ "Enedis",[4.3831575770546705, 48.12153180483366]],
    [ "Enedis",[4.379950877115526, 48.11942876453274]],
    [ "Enedis",[6.248277168535367, 46.30369389388714]],
    [ "Enedis",[6.017194420942009, 46.013078517345924]],
    [ "Chavannes",[6.03697705, 46.10898025]],
    [ "Enedis",[6.475742499999998, 46.35467405]],
    [ "Enedis",[6.693661242408197, 46.190384477995465]],
    [ "Poste électrique d'Avoriaz",[6.767052716108362, 46.198200879262295]],
    [ "Enedis",[1.3939203038447456, 46.44209704489198]],
    [ "Enedis",[1.3964484870247436, 46.44548667330516]],
    [ "Enedis",[1.3892638869742455, 46.440335140003704]],
    [ "Enedis",[1.3910833652324839, 46.44032035515666]],
    [ "Enedis",[1.4560675346818168, 44.463291733478485]],
    [ "Enedis",[3.046240796715328, 48.48949802104623]],
    [ "Enedis",[2.5524155218036535, 43.47345754828767]],
    [ "Enedis",[2.7188592943135586, 48.00836616318543]],
    [ "Seminaire",[6.12715565, 45.894641899999996]],
    [ "Enedis",[6.417489349999999, 46.1708914]],
    [ "Enedis",[6.432637155352183, 46.220609165253904]],
    [ "Meyrieux",[6.168023982958567, 45.893991544228584]],
    [ "33 Av des Romains",[6.119757136951812, 45.90982755271457]],
    [ "Enedis",[6.450275097988571, 46.17529848911304]],
    [ "Enedis",[2.087446000000001, 49.3415555]],
    [ "Regie municipale d'électricité de Larruns",[-0.42834249999999996, 42.9850424]],
    [ "Hourat",[-0.4281564696797953, 42.98440810318053]],
    [ "Enedis",[2.5210743112123186, 51.063232442854044]],
    [ "Enedis",[6.440430149999999, 46.07717039999999]],
    [ "Enedis",[6.445986885453412, 46.07648341747147]],
    [ "RTE",[-1.749746839899248, 48.55372558714827]],
    [ "Enedis",[6.001598411151948, 46.079250116709545]],
    [ "Enedis",[2.51408416992612, 51.070314281616696]],
    [ "Auto-sud",[4.846352225005318, 44.11137362761443]],
    [ "Enedis",[3.4357457878313666, 47.91712674995962]],
    [ "Enedis",[-0.6903529, 43.16476995]],
    [ "Enedis",[2.6621617100747486, 48.09366403065211]],
    [ "Enedis",[0.3327621952195754, 46.566245948614494]],
    [ "Enedis",[0.33141280532555517, 46.5664149430439]],
    [ "Enedis",[-0.2695165300859598, 43.236151172874884]],
    [ "Enedis",[-0.28030863540630163, 43.234819585903814]],
    [ "Enedis",[-0.2771231740041929, 43.23798830607967]],
    [ "Enedis",[-0.18586123135131288, 43.26764617041716]],
    [ "Enedis",[3.312928093333334, 48.02601932]],
    [ "Enedis",[3.3115957743885684, 48.020291651552625]],
    [ "Enedis",[2.5156121420971216, 51.07927348007083]],
    [ "Enedis",[2.9906967057816383, 48.18828854017054]],
    [ "Enedis",[-0.41209222362869197, 43.53696194514768]],
    [ "Enedis",[-0.41481182920280885, 43.53608815695994]],
    [ "Enedis",[-0.41071124999999997, 43.536143499999994]],
    [ "Enedis",[3.0011708871965417, 48.200926677853474]],
    [ "Enedis",[1.1954114335378327, 47.75301217995909]],
    [ "Enedis",[1.192384497947102, 47.757060769311565]],
    [ "Enedis",[3.0708670042023773, 48.338343224827206]],
    [ "Enedis",[3.0716507492625365, 48.344149970501476]],
    [ "Enedis",[3.0697462647333498, 48.341468526360366]],
    [ "Enedis",[3.0736760000000003, 48.33924449999999]],
    [ "Enedis",[3.0771087548209364, 48.34146284848484]],
    [ "Enedis",[3.0606381787726518, 48.33648482000914]],
    [ "Enedis",[3.063925831756461, 48.34351220315375]],
    [ "Enedis",[3.0648922510680725, 48.34075328921485]],
    [ "Enedis",[3.0494348359022263, 48.3465921372723]],
    [ "Enedis",[-0.5504120000000001, 49.3173595]],
    [ "Enedis",[-0.5486710000000006, 49.312596250000006]],
    [ "Enedis",[-0.5346347499999988, 49.2843027]],
    [ "Enedis",[-0.5475564, 49.280990800000005]],
    [ "Enedis",[-0.5446513447154471, 49.2802943211382]],
    [ "Enedis",[-0.5338279246562226, 49.28728781884483]],
    [ "Enedis",[-0.5426601000000001, 49.282503199999994]],
    [ "Centrale électrique",[6.494500258752947, 46.12387443372435]],
    [ "Enedis",[5.9345678999999985, 45.88923005000001]],
    [ "Enedis",[5.932134674218845, 46.0953308440894]],
    [ "Enedis",[3.4180946193078325, 47.88292485610201]],
    [ "Ferrini",[5.429184465819974, 43.49170319698135]],
    [ "Enedis",[5.591447299999999, 43.285320799999994]],
    [ "Enedis",[4.848838249999999, 45.756886299999984]],
    [ "Enedis",[4.845614764275843, 45.75877507251624]],
    [ "Enedis",[4.8476596, 45.759023549999995]],
    [ "Enedis",[4.845331437833418, 45.75858790675466]],
    [ "Sous-station RFF de Venarey",[4.439086098755031, 47.56110291048507]],
    [ "Poste électrique de Dinard",[-2.0586724128610476, 48.61580149526805]],
    [ "Enedis",[1.3706086626935194, 43.66224774393753]],
    [ "Gaillard",[2.1354282500000004, 48.677992249999996]],
    [ "Z A C",[-0.49229181907724207, 49.103504036288236]],
    [ "La Croix Boucher",[-0.49466499999999997, 49.106014]],
    [ "Enedis",[0.683692801947616, 44.180884319032906]],
    [ "Enedis",[0.669375705171352, 44.18151321745849]],
    [ "Enedis",[0.6641090832552504, 44.17991865327754]],
    [ "Enedis",[0.6685563931234969, 44.179625209316086]],
    [ "Enedis",[0.6798886678084705, 44.18217534835074]],
    [ "Enedis",[0.678584442786508, 44.17312581228877]],
    [ "Enedis",[0.6754695465542153, 44.17464971414711]],
    [ "Enedis",[0.6742637486317282, 44.183786306714076]],
    [ "Enedis",[2.7255275772889487, 48.40371688536691]],
    [ "Enedis",[2.7289305384276554, 48.40304782595647]],
    [ "Enedis",[2.7177194344587163, 48.404559044991835]],
    [ "Enedis",[2.7207953681818857, 48.403063850977475]],
    [ "Enedis",[2.722043647989467, 48.407155560418225]],
    [ "Enedis",[2.717511076177659, 48.413287666644166]],
    [ "Enedis",[2.7238311582828594, 48.41656895768229]],
    [ "Enedis",[2.722523209327291, 48.40192158079084]],
    [ "Enedis",[2.7239515822841427, 48.403385486540735]],
    [ "Enedis",[2.727769042250451, 48.403348172076775]],
    [ "Enedis",[2.7210032038238428, 48.41249909119133]],
    [ "DP Chateleine",[2.7247759245994434, 48.41639261383918]],
    [ "Enedis",[2.719956944607095, 48.40489869740359]],
    [ "Enedis",[1.979386554288044, 48.6743378992054]],
    [ "Enedis",[1.9772219375187716, 48.671365584652506]],
    [ "Enedis",[1.9798276648175732, 48.672566847786506]],
    [ "Enedis",[-0.173172950000001, 49.19937234999999]],
    [ "Enedis",[3.8165375700609214, 49.579170726718885]],
    [ "Enedis",[3.8172916851851855, 49.58278966606928]],
    [ "Enedis",[2.1290969108152025, 50.07512236922172]],
    [ "Enedis",[2.1231644458245937, 50.074556983437304]],
    [ "Enedis",[2.120832718072256, 50.073096934289524]],
    [ "Enedis",[2.1335495353181253, 50.07360787659464]],
    [ "Enedis",[2.127365399848607, 50.07367831093441]],
    [ "Enedis",[5.92447045, 45.85139215]],
    [ "Circulaire",[1.50033184979313, 49.10435508327539]],
    [ "Ecolelrba",[1.5043507500000002, 49.10565625]],
    [ "Pénitants",[1.498301, 49.1066038]],
    [ "Enedis",[6.030266500000001, 46.24626625]],
    [ "Coq",[1.4913118042555575, 49.08325856881218]],
    [ "Cauvins",[1.4876467831157458, 49.0836541003415]],
    [ "Fagets",[1.4965909500000003, 49.0748242]],
    [ "Hau Lard",[1.4927759587544494, 49.07824851857508]],
    [ "Les Boutardes 1",[1.4920687, 49.076836400000005]],
    [ "Blériot",[1.4599165759439934, 49.09881626713091]],
    [ "Falaise",[1.4599209764791756, 49.09878226061932]],
    [ "Enedis",[2.4339238087382644, 48.56239193102239]],
    [ "Enedis",[2.48766983230224, 48.61562934899738]],
    [ "Fouqueyère",[0.15385211623258865, 48.01992059768491]],
    [ "Enedis",[6.1245345, 48.563579499999996]],
    [ "Enedis",[6.125577, 48.5664225]],
    [ "Enedis",[6.126300322356384, 48.56963452103915]],
    [ "Enedis",[3.973175963888889, 46.59376517083333]],
    [ "Enedis",[1.3516540969620507, 48.0850762071042]],
    [ "Enedis",[1.3526611196060132, 48.07714431708438]],
    [ "Enedis",[1.3433252358632937, 48.07205500930786]],
    [ "Enedis",[1.3474780100819506, 48.08580931347481]],
    [ "Enedis",[1.3457613106508273, 48.080583575490955]],
    [ "Enedis",[1.3431908271018056, 48.07084066937603]],
    [ "Enedis",[1.3424007185700209, 48.08601139268787]],
    [ "Enedis",[1.3528378907090406, 48.084276980561306]],
    [ "Enedis",[1.3348317652857506, 48.07031294002163]],
    [ "Enedis",[1.3217349371637501, 48.07354846514583]],
    [ "Enedis",[1.3278686573141913, 48.07549340691265]],
    [ "Enedis",[1.3218754277894027, 48.07068444213423]],
    [ "Enedis",[1.3491982054523801, 48.0842163706846]],
    [ "Enedis",[1.3431969001811566, 48.06855207553665]],
    [ "Enedis",[1.3358593307053943, 48.0780663153527]],
    [ "Enedis",[1.3339980059954781, 48.09293235435064]],
    [ "Enedis",[1.331197656240208, 48.067902462995704]],
    [ "Enedis",[1.347231905973143, 48.083346543279596]],
    [ "Enedis",[1.3529954426587552, 48.07313182785048]],
    [ "Enedis",[1.347319192223662, 48.05484456288143]],
    [ "Enedis",[1.3403923791487617, 48.0740523426915]],
    [ "Enedis",[1.3251349097261615, 48.06783480845504]],
    [ "Enedis",[1.33975743831234, 48.067358352012725]],
    [ "Enedis",[1.3479364463133376, 48.08676203131184]],
    [ "Enedis",[1.5017657882859596, 49.083175682341576]],
    [ "Enedis",[1.5048290086896776, 49.08085457721015]],
    [ "Enedis",[-0.8702906199870728, 44.52102845250724]],
    [ "Enedis",[-0.87758495, 44.514165250000005]],
    [ "Enedis",[-0.875432917456975, 44.52854049700692]],
    [ "Enedis",[-0.88172445, 44.53318125]],
    [ "Enedis",[-0.8945504766860947, 44.52552979723842]],
    [ "Enedis",[-0.866174164599232, 44.54412839716479]],
    [ "Enedis",[-0.8748762542241133, 44.554178324845395]],
    [ "Enedis",[-0.8669405283441793, 44.56835255307303]],
    [ "Enedis",[-0.8705976057805309, 44.55831057750516]],
    [ "Enedis",[-0.8412247999999999, 44.564890600000005]],
    [ "Enedis",[-0.8687303704237975, 44.5638847549946]],
    [ "Enedis",[-0.9106740999999987, 44.560386699999995]],
    [ "Enedis",[-0.8677092289686261, 44.5492799550448]],
    [ "Enedis",[-0.8461731921577418, 44.55227438435957]],
    [ "Enedis",[-0.8821629340004764, 44.54666939349536]],
    [ "Enedis",[-0.8735726440486056, 44.54694908461751]],
    [ "Enedis",[-0.7842972443337699, 44.57780817329777]],
    [ "Enedis",[-0.91675525, 44.538601750000005]],
    [ "Enedis",[-0.8532793216796499, 44.54471383675519]],
    [ "Enedis",[-0.8722687761892267, 44.550491246777064]],
    [ "Enedis",[-0.853214152621766, 44.54569393321091]],
    [ "Enedis",[-0.8504606, 44.547935700000004]],
    [ "Enedis",[-0.8791140000000003, 44.58424035]],
    [ "Enedis",[-0.8526121608578431, 44.570176721233054]],
    [ "Enedis",[-0.8607404628135554, 44.551565064144626]],
    [ "Enedis",[-0.9146196000000001, 44.558027100000004]],
    [ "Enedis",[-0.8559064476171878, 44.560209614498696]],
    [ "Enedis",[-0.8772883499999999, 44.5457106]],
    [ "Enedis",[-0.9145209999999993, 44.55199525000001]],
    [ "Enedis",[-0.844192612335601, 44.55169022446711]],
    [ "Enedis",[-0.883808099108769, 44.57832666721691]],
    [ "Enedis",[-0.8555867685699402, 44.54683510481841]],
    [ "Enedis",[-0.8652381435808565, 44.55175845994757]],
    [ "Enedis",[-0.8628232910609093, 44.541871624839565]],
    [ "Enedis",[-0.8624932695008958, 44.5439643950028]],
    [ "Enedis",[-0.8442952775466119, 44.55226796527346]],
    [ "Enedis",[-0.8719124789911634, 44.55702669675327]],
    [ "Enedis",[3.808595125414472, 49.60808719738638]],
    [ "Enedis",[3.807518378169791, 49.611449723998525]],
    [ "Enedis",[3.8015145000000006, 49.60832]],
    [ "Enedis",[3.8120451827956985, 49.613646032258075]],
    [ "Enedis",[3.803794722846443, 49.607947546816476]],
    [ "Enedis",[3.7990659920364056, 49.609757548350395]],
    [ "Enedis",[3.8059581005052565, 49.60936235927899]],
    [ "Enedis",[3.8122118541824617, 49.60964367930251]],
    [ "Enedis",[3.8053056116646737, 49.60612327593996]],
    [ "Enedis",[2.997487863619453, 48.19775833954588]],
    [ "EDF",[0.7847410206470241, 43.09700549569284]],
    [ "RTE",[0.8117638341064679, 43.09617796323728]],
    [ "Enedis",[6.1387415186454115, 45.998381398987036]],
    [ "Le Clus",[6.138594049999999, 45.9996357]],
    [ "Les Tattes",[6.147491380217335, 46.00109512233811]],
    [ "Enedis",[2.9583517504528984, 46.31764885099638]],
    [ "Enedis",[2.965463630926331, 46.32458964867493]],
    [ "Enedis",[2.9555053669341698, 46.31792465934141]],
    [ "Enedis",[2.9662705, 46.32639799999999]],
    [ "Enedis",[2.9599409996207813, 46.31490320439894]],
    [ "Enedis",[2.956960720922347, 46.320538964733814]],
    [ "Enedis",[1.4264143801352052, 46.629624613104525]],
    [ "Enedis",[1.43002, 46.62998500000001]],
    [ "Enedis",[2.9201815, 42.836978]],
    [ "Enedis",[2.9179535, 42.835332]],
    [ "Enedis",[2.921333827917282, 42.83557201575578]],
    [ "Enedis",[2.9201357417417433, 42.833173537537526]],
    [ "Enedis",[2.9197592351208024, 42.82951393321548]],
    [ "Enedis",[2.916453642848924, 42.83362281728225]],
    [ "Enedis",[2.9122601683501674, 42.83355829292929]],
    [ "Enedis",[2.922709859672901, 42.83165568455769]],
    [ "Enedis",[2.9195964638927094, 42.82557533447959]],
    [ "Enedis",[2.915531309856531, 42.83448751891187]],
    [ "Enedis",[0.10538757514831931, 47.84835827183038]],
    [ "Enedis",[5.083733978566593, 47.32670418648206]],
    [ "Enedis",[5.074738999999999, 47.326260999999995]],
    [ "Enedis",[6.272878768315955, 46.10047023272382]],
    [ "Poste électrique du Mottay",[6.510772333708227, 46.38871281601459]],
    [ "Enedis",[6.121384083275612, 45.900061219046506]],
    [ "Enedis",[6.124575999999999, 45.90213374999999]],
    [ "Enedis",[3.556086188824662, 47.90376903853564]],
    [ "Embruns",[3.3644842499999994, 43.2782905]],
    [ "SNCF Réseau",[1.3400491043908218, 44.65440865397051]],
    [ "Enedis",[-4.226151068745189, 48.43702670315627]],
    [ "Enedis",[0.3994153638877462, 43.50310152260814]],
    [ "Enedis",[1.3823109518304435, 43.55206765510598]],
    [ "Enedis",[1.3886085000000004, 43.54970949999999]],
    [ "Enedis",[1.3870596508271735, 43.54855958078142]],
    [ "Enedis",[2.729712697962432, 48.42109576821474]],
    [ "Enedis",[2.707806424632626, 48.41056373792254]],
    [ "Enedis",[2.697671333406252, 48.40547914337656]],
    [ "Enedis",[2.7013872722201224, 48.41017972047601]],
    [ "Enedis",[2.696031934794031, 48.401772777550285]],
    [ "Enedis",[2.68210468476386, 48.3935171780548]],
    [ "Enedis",[2.724033413335692, 48.400297309766636]],
    [ "Enedis",[2.7047185473240973, 48.41142882319004]],
    [ "Enedis",[2.6868628637336984, 48.400460909501035]],
    [ "Enedis",[2.7029790313037423, 48.41003562645972]],
    [ "Enedis",[2.6936099515061165, 48.405447287695566]],
    [ "Faisanderie",[2.6809180451210906, 48.404925656637154]],
    [ "Saint-Merry",[2.691009367004291, 48.402154267064994]],
    [ "Enedis",[2.6871864002993586, 48.40432315302532]],
    [ "Enedis",[2.7270427316287003, 48.3966462418052]],
    [ "Enedis",[2.7054893134980325, 48.41490745988128]],
    [ "Enedis",[2.716710657842905, 48.41528046123813]],
    [ "Enedis",[2.7060837964406863, 48.40865777036872]],
    [ "Enedis",[2.698988846348382, 48.411168088547704]],
    [ "Enedis",[2.7136504770668197, 48.39885306003084]],
    [ "Enedis",[2.7060313598103076, 48.41250456346698]],
    [ "Enedis",[2.690380672484318, 48.40091489005422]],
    [ "Enedis",[1.3859629628804753, 43.55367115701559]],
    [ "Enedis",[4.143192000000001, 43.52172725]],
    [ "Enedis",[2.8178269054517138, 50.27791176725078]],
    [ "Cret du Maure",[6.126061763460437, 45.895495456538754]],
    [ "Le Mottet",[6.231157175859848, 45.79059310836164]],
    [ "Verthier",[6.231116721494311, 45.78709604055746]],
    [ "Place des Romains",[6.121652349999999, 45.9081828]],
    [ "La croix",[6.231136049999999, 45.784223100000006]],
    [ "Enedis",[4.891248249999999, 45.69316375]],
    [ "Enedis",[3.9598141500000006, 50.2926835]],
    [ "Enedis",[3.5704810000000013, 47.9847685]],
    [ "Enedis",[1.318321964113182, 43.6457406583851]],
    [ "Enedis",[1.3180010644831115, 43.64536315830774]],
    [ "Enedis",[1.3159557000000002, 43.64233109999999]],
    [ "Enedis",[1.3162357492354742, 43.64854694801224]],
    [ "Enedis",[1.3092653214568488, 43.65125853365004]],
    [ "Enedis",[1.5591639427402866, 43.61374031697341]],
    [ "Enedis",[1.5736619351323298, 43.61464424701608]],
    [ "Enedis",[1.569439054020413, 43.61388565969629]],
    [ "Enedis",[1.572600180157742, 43.61334645745123]],
    [ "Enedis",[1.5758839999999998, 43.612883000000004]],
    [ "Enedis",[1.5801441060606058, 43.61535271969697]],
    [ "Enedis",[1.5609255000000002, 43.616059]],
    [ "Enedis",[1.5786667000000008, 43.6137925]],
    [ "Enedis",[1.5708784999999998, 43.61124100000001]],
    [ "Enedis",[1.5623571191028611, 43.6146347099768]],
    [ "Enedis",[1.5746346782140106, 43.61137244033872]],
    [ "Enedis",[1.5701124600938972, 43.60868676643192]],
    [ "Enedis",[1.5659018095238098, 43.61263675238095]],
    [ "Enedis",[1.569033024830172, 43.61219314663857]],
    [ "Enedis",[5.968020098609581, 46.09372341762978]],
    [ "Veigy",[6.02085917624521, 46.1401352881226]],
    [ "Enedis",[5.992653449999999, 46.1049312]],
    [ "Enedis",[-2.2269506999999997, 47.7921783]],
    [ "Enedis",[2.9876856396396394, 47.595784693693695]],
    [ "Enedis",[2.9826335000000004, 47.59816649999999]],
    [ "Enedis",[4.1492593, 43.5181489]],
    [ "Enedis",[1.5718596182170537, 43.59068432364341]],
    [ "Enedis",[1.5777619771497855, 43.5898993403283]],
    [ "Poulidor",[0.2890809333660866, 42.814897299846365]],
    [ "Enedis",[0.290144, 42.81424309999999]],
    [ "Poste électrique Harcourt",[2.267887250737903, 48.833911005782966]],
    [ "Enedis",[6.024921670250895, 48.70295456989246]],
    [ "Enedis",[6.028534803505223, 48.701265832827765]],
    [ "Enedis",[2.72080442570988, 47.998897136593605]],
    [ "Enedis",[2.7439552032634538, 47.97271715500463]],
    [ "Cret Vignon",[6.172023180063826, 45.89311501298396]],
    [ "Le Peril",[6.182486301106739, 45.88326906628262]],
    [ "Les Champs",[6.177424500000001, 45.88752515000001]],
    [ "Regina",[6.14231485, 45.88109915]],
    [ "Résidence du Parc",[6.145117969638948, 45.90561855324925]],
    [ "Stade Albigny",[6.1559982785619916, 45.906571496088766]],
    [ "Enedis",[2.367147365525673, 49.752103444580285]],
    [ "Enedis",[2.3605756708937204, 49.752529128019326]],
    [ "Enedis",[2.360810090909091, 49.75629426406927]],
    [ "Enedis",[2.3609110197313186, 49.75433699769102]],
    [ "Enedis",[2.367532081419624, 49.76977472999303]],
    [ "Enedis",[2.3720179527213805, 49.75575689686398]],
    [ "Enedis",[2.371241169335274, 49.75747367442988]],
    [ "Enedis",[2.425382656753408, 51.0314740495663]],
    [ "Enedis",[2.3512620000000006, 49.75856099999999]],
    [ "Enedis",[2.3491016476190474, 49.7561233047619]],
    [ "Enedis",[2.3415126793720273, 49.756245330139414]],
    [ "Enedis",[1.83457055, 48.83849975]],
    [ "Enedis",[3.3151817197356515, 48.85455237445229]],
    [ "Enedis",[1.6096851560283687, 43.38718745390071]],
    [ "Enedis",[1.6187115000000003, 43.3621465]],
    [ "Enedis",[1.6106181, 43.3850722]],
    [ "Charmentray",[2.7762794127241324, 48.94446696396374]],
    [ "Enedis",[2.372899, 49.7807495]],
    [ "Enedis",[2.3683297570864728, 49.77841367635449]],
    [ "Enedis",[2.7712857668351867, 48.24984089971847]],
    [ "Enedis",[2.7421964045142615, 48.22566650183504]],
    [ "Enedis",[2.741268865445359, 48.22351715977335]],
    [ "Enedis",[2.745287327761939, 48.223629471134444]],
    [ "Villiers",[2.6471233738862425, 48.3167632788521]],
    [ "Brillier",[2.646916174411045, 48.323457997402414]],
    [ "Enedis",[2.7181700157352027, 48.008997298143456]],
    [ "Enedis",[2.40815143789887, 49.824627837997056]],
    [ "Enedis",[2.331244113661202, 49.7280182021858]],
    [ "Enedis",[2.342945542234332, 49.69835804904632]],
    [ "Enedis",[2.2581247018513864, 49.79077646217643]],
    [ "Enedis",[2.2618319050131923, 49.782727305189105]],
    [ "Enedis",[2.1161232968065122, 49.742226658317676]],
    [ "Enedis",[2.120186450444588, 49.73953184016482]],
    [ "Enedis",[1.838243, 44.91893515000001]],
    [ "Enedis",[2.0450932036645524, 49.728897155038766]],
    [ "Enedis",[1.984864824238129, 49.777894476021736]],
    [ "Enedis",[1.9925760000000001, 49.77585900000001]],
    [ "Enedis",[1.9797062425552356, 49.775355670509114]],
    [ "Enedis",[1.9742770000000012, 49.778511]],
    [ "Enedis",[1.9783571247468605, 49.77459823126772]],
    [ "Enedis",[1.9865641442542779, 49.77406783211084]],
    [ "Enedis",[1.9783742937698956, 49.768819414279214]],
    [ "Enedis",[1.9815621550068583, 49.77086347325103]],
    [ "Enedis",[1.9801126725025748, 49.773209315482326]],
    [ "Enedis",[1.9883181139430288, 49.715939285357315]],
    [ "Enedis",[2.4226327500000004, 47.10982825]],
    [ "Enedis",[2.0776723230844314, 49.81987907515861]],
    [ "Enedis",[2.080713000000001, 49.82408450000002]],
    [ "Enedis",[1.8825409122807018, 49.804439190789466]],
    [ "Enedis",[2.2272005111952065, 49.92820133459477]],
    [ "Enedis",[2.2257881839080467, 49.929458931034475]],
    [ "Enedis",[2.2307119999999996, 49.9434355]],
    [ "Enedis",[2.228454637599094, 49.932908540958856]],
    [ "Enedis",[2.210423835149483, 49.940648561329986]],
    [ "Enedis",[2.2171607175295187, 49.937184158946415]],
    [ "Enedis",[2.21005490347388, 49.9387582601432]],
    [ "Enedis",[2.2084770828402362, 49.937191420118346]],
    [ "Enedis",[2.201291170073822, 49.97154094548552]],
    [ "Enedis",[2.1997997441860475, 50.009148077519384]],
    [ "Enedis",[2.19335, 50.0088255]],
    [ "Enedis",[2.190338, 50.00801500000001]],
    [ "Enedis",[2.1962726644729242, 50.011470549359196]],
    [ "Enedis",[2.2036121625661784, 50.01601514471089]],
    [ "Enedis",[2.238739670948012, 50.03652270948012]],
    [ "Enedis",[2.217455342522974, 50.05920833444723]],
    [ "Enedis",[2.2249195807770965, 50.057197932515336]],
    [ "Enedis",[2.2152470771197716, 50.05322152153012]],
    [ "Enedis",[2.1972962927379336, 50.050510451511045]],
    [ "Enedis",[2.1512548000000002, 50.04601919999999]],
    [ "Enedis",[2.1576084064516134, 50.044293802150534]],
    [ "Enedis",[2.1836452674868987, 50.05216711825016]],
    [ "Enedis",[2.1352829999999994, 50.04789499999999]],
    [ "Enedis",[2.1387929278131637, 50.049660188959656]],
    [ "Enedis",[2.1331864246229166, 50.048043863302524]],
    [ "Enedis",[2.1300765000000004, 50.046796500000006]],
    [ "Enedis",[2.1267294532110093, 50.034508014678906]],
    [ "Enedis",[2.123301110459433, 50.03843271358748]],
    [ "Enedis",[2.12519251753009, 50.037753350601776]],
    [ "Enedis",[2.112179688681343, 50.02697082810763]],
    [ "Enedis",[2.7219884913974903, 48.02965493130219]],
    [ "Enedis",[2.0847324999999985, 50.008863999999996]],
    [ "Enedis",[2.082364, 50.010317]],
    [ "Enedis",[2.0849270713501653, 50.018033257226485]],
    [ "Enedis",[2.0844385, 50.016413500000006]],
    [ "Enedis",[2.078325052136133, 50.015314560222066]],
    [ "Enedis",[2.077375325009249, 50.0174117563818]],
    [ "Enedis",[2.0804291508880857, 50.012262825314714]],
    [ "Enedis",[2.2729274999999998, 50.0361305]],
    [ "Enedis",[-1.9332578999999988, 48.176588499999994]],
    [ "Enedis",[2.4438119999999994, 51.02368049999998]],
    [ "Enedis",[2.966212827269386, 42.7806424031606]],
    [ "Enedis",[2.9619522539682532, 42.79034841269841]],
    [ "Enedis",[2.961931186657203, 42.78175945233026]],
    [ "Enedis",[2.963120885378398, 42.7824182414891]],
    [ "Enedis",[2.9616950000000006, 42.786432500000004]],
    [ "Enedis",[2.963992300254453, 42.787557099236636]],
    [ "Enedis",[2.96772669538235, 42.78477645250868]],
    [ "Enedis",[2.7133270635677804, 48.03180265529539]],
    [ "Enedis",[5.889191754952479, 46.029051448436974]],
    [ "Enedis",[5.8860730000000006, 46.0431835]],
    [ "Enedis",[5.883576, 46.0502475]],
    [ "Enedis",[5.889263163658242, 46.0288635354994]],
    [ "Enedis",[1.629156389648121, 50.18727867277343]],
    [ "Enedis",[1.6198632821830328, 50.18860134936286]],
    [ "Enedis",[1.6461354671606816, 50.176001828349314]],
    [ "Enedis",[1.6190309820832758, 50.184415144896604]],
    [ "Enedis",[1.6240926205724062, 50.18821197726116]],
    [ "Enedis",[1.6263371082106983, 50.18571278140861]],
    [ "Enedis",[1.64414485, 50.17904289999999]],
    [ "Enedis",[1.6365717831438202, 50.177278748921395]],
    [ "Enedis",[1.6437654861004822, 50.18462817243206]],
    [ "Enedis",[1.6329890427509703, 50.18793396968931]],
    [ "Enedis",[1.624395394133186, 50.18379083848027]],
    [ "Enedis",[1.6432308013903374, 50.18212212669344]],
    [ "Enedis",[1.6376398845290974, 50.18672001167745]],
    [ "Enedis",[2.900269853443526, 42.541319712947654]],
    [ "Enedis",[6.350898499999999, 46.295787000000004]],
    [ "Enedis",[6.3252625, 46.28660014999999]],
    [ "Enedis",[6.351890399999999, 46.28427899999999]],
    [ "Enedis",[0.8946861624999997, 43.03536700416667]],
    [ "Enedis",[2.45318105, 48.56314195]],
    [ "Enedis",[0.8784186526491173, 43.07740631463957]],
    [ "Poste électrique de Boule",[2.203019053586012, 48.88733507759864]],
    [ "Enedis",[2.3284301999999997, 48.85003449999999]],
    [ "Enedis",[4.8509468783446765, 45.7524341839653]],
    [ "Enedis",[-0.9246677033217311, 46.88802463277707]],
    [ "Poirier",[1.513932032299004, 48.63318032820876]],
    [ "Enedis",[5.149968046017699, 47.194599617699126]],
    [ "Enedis",[5.1475956491440655, 47.20205962540872]],
    [ "Enedis",[5.147482225479931, 47.194148145026176]],
    [ "Enedis",[5.156213131659056, 47.20147549421614]],
    [ "Enedis",[5.150888236855539, 47.200386351710065]],
    [ "Enedis",[5.142994525239618, 47.19457196315229]],
    [ "Poste électrique du Bourget",[2.438267074424127, 48.93444386027574]],
    [ "Enedis",[5.141432796169013, 46.997642582666664]],
    [ "Enedis",[5.140632650075066, 46.99833740078608]],
    [ "Enedis",[3.0199122653419694, 48.345269644398314]],
    [ "Enedis",[2.940993381776472, 48.33424153585441]],
    [ "Enedis",[2.926733049398659, 48.33452680060768]],
    [ "Enedis",[6.146664258085808, 48.72499738811882]],
    [ "Enedis",[-2.1449130748865466, 47.69587400480655]],
    [ "Enedis",[3.137304669475853, 49.177561947352146]],
    [ "Enedis",[3.102971783319871, 49.112806290396655]],
    [ "Enedis",[2.7854099285018883, 49.1083948542763]],
    [ "Enedis",[2.7885782150760154, 49.106256543837254]],
    [ "Enedis",[0.23036465079365118, 46.057580365079374]],
    [ "Enedis",[2.021762, 47.7043365]],
    [ "Enedis",[2.0250494000000003, 47.698456799999995]],
    [ "Enedis",[2.192776055643045, 50.95091861686352]],
    [ "Enedis",[-1.9982634999999997, 46.822438749999996]],
    [ "Électricité de France",[-61.56266591612969, 16.051738627724813]],
    [ "Enedis",[4.85063577182268, 45.76106216273612]],
    [ "Enedis",[4.851215949999999, 45.761925100000006]],
    [ "réséda",[6.160262191441442, 48.988042348348344]],
    [ "réséda",[6.158646017055267, 48.98716960427461]],
    [ "réséda",[6.15695576371308, 48.98815054852321]],
    [ "Enedis",[0.732273670205707, 49.37729306458748]],
    [ "293491238",[2.010465183900293, 42.441034327265925]],
    [ "Enedis",[4.217351483823475, 45.52155674901142]],
    [ "Poste électrique de Saint-Malo",[-1.9959984062730354, 48.64937812303032]],
    [ "Enedis",[0.7329631908713694, 48.273513385892116]],
    [ "Enedis",[0.7373808477987421, 48.27148732578617]],
    [ "Enedis",[0.7634788333333333, 48.267263]],
    [ "Enedis",[4.129208149999999, 49.91430859999999]],
    [ "Enedis",[4.128595157899174, 49.921319033721126]],
    [ "Enedis",[4.034705936856099, 49.21357928348288]],
    [ "Enedis",[4.0312625, 49.2141515]],
    [ "Enedis",[3.1373465000000005, 45.80307335]],
    [ "Enedis",[3.0892921101544326, 48.35952658544884]],
    [ "Enedis",[2.849750978574515, 48.34930393267607]],
    [ "Enedis",[3.0189613064324385, 48.42732025776643]],
    [ "Enedis",[3.016091132792991, 48.425948871942914]],
    [ "Enedis",[3.09411760545495, 48.360560889124464]],
    [ "Enedis",[3.080936308610791, 48.36152736239721]],
    [ "Enedis",[2.905387785206135, 48.314850060669585]],
    [ "Enedis",[1.701041999999999, 44.9587475]],
    [ "Enedis",[3.11285155, 45.812605700000006]],
    [ "Enedis",[3.13002044048416, 45.81283422706045]],
    [ "RTE",[2.037766778348534, 47.60650259252249]],
    [ "Enedis",[4.8465253, 45.761253350000004]],
    [ "Enedis",[4.838182006043543, 45.742511674941994]],
    [ "Enedis",[4.841293565179396, 45.7570535683944]],
    [ "Enedis",[4.845743858759241, 45.75800335766771]],
    [ "Enedis",[4.845582854515818, 45.75951557496792]],
    [ "Enedis",[4.848490099999999, 45.76334945]],
    [ "Enedis",[4.844945899999999, 45.76294574999999]],
    [ "Enedis",[4.847651793458629, 45.760161766721346]],
    [ "Enedis",[4.8466855168040786, 45.760394133630896]],
    [ "Enedis",[4.849035909111239, 45.76034460063006]],
    [ "Poste électrique de Lannemezan",[0.3925980200747502, 43.11305765558148]],
    [ "Enedis",[0.8163687024661892, 49.355184556085916]],
    [ "Enedis",[0.8231599999999999, 49.3440385]],
    [ "Enedis",[0.8160456270518711, 49.35846678901292]],
    [ "Enedis",[0.8089896583850931, 49.355619035886825]],
    [ "Enedis",[0.8190323652551572, 49.3590458204126]],
    [ "Enedis",[0.816493, 49.3480215]],
    [ "Enedis",[0.8278271794871788, 49.35558728205129]],
    [ "Enedis",[0.8239838496334425, 49.35140772593978]],
    [ "Enedis",[0.8132024860828239, 49.359355568907]],
    [ "Enedis",[2.135004278092158, 49.964956983831854]],
    [ "Enedis",[2.075883999999999, 49.98731799999999]],
    [ "Enedis",[2.266157735202492, 50.00456383364486]],
    [ "Enedis",[2.2698672724767386, 50.00341855587435]],
    [ "Enedis",[2.253653316061148, 49.99920265832836]],
    [ "Enedis",[2.2559791314553985, 49.99540414504572]],
    [ "Enedis",[2.2543616969435663, 49.99723834454345]],
    [ "Enedis",[2.255556890946502, 49.9990531872428]],
    [ "Enedis",[2.2529860265486756, 49.99994648028962]],
    [ "Enedis",[2.0660591941356525, 49.98054495746737]],
    [ "Enedis",[2.9470431545623836, 49.48226602979515]],
    [ "Enedis",[2.1453105, 49.953466999999996]],
    [ "Enedis",[2.143252713011239, 49.948032411308084]],
    [ "Enedis",[2.250251558974359, 49.96315953333333]],
    [ "Enedis",[2.247487, 49.962391]],
    [ "Enedis",[2.1103228461538457, 49.975171131410264]],
    [ "Enedis",[4.133068350785341, 49.920071650959855]],
    [ "Enedis",[4.138446658051099, 49.91971283036246]],
    [ "Enedis",[4.737790484365352, 46.5929200679617]],
    [ "Enedis",[3.947597668684035, 43.96272897469193]],
    [ "Enedis",[2.6489673015800066, 45.28387085244293]],
    [ "Enedis",[1.902592, 49.961415]],
    [ "Enedis",[1.7658965913434508, 49.955897206857784]],
    [ "Enedis",[1.7609713242290754, 49.9577951629956]],
    [ "Enedis",[1.7623814999999998, 49.95914150000001]],
    [ "Enedis",[1.763778839378238, 49.95435902763385]],
    [ "Enedis",[3.4241952540614604, 49.95303682305736]],
    [ "Enedis",[3.4215380826417703, 49.94760673686031]],
    [ "Enedis",[3.4291080649905923, 49.950453393364114]],
    [ "Enedis",[3.4230923382292935, 49.9510610375357]],
    [ "Enedis",[2.0034662920029347, 50.00852053974077]],
    [ "Enedis",[2.002153134834614, 50.00973842261687]],
    [ "Enedis",[2.0049401850053936, 50.01159288381877]],
    [ "Enedis",[1.9945351338235295, 50.01242817009804]],
    [ "Enedis",[2.0193717430786267, 50.01004377297896]],
    [ "Enedis",[2.013080500000002, 50.00998200000001]],
    [ "Enedis",[2.0429866955903266, 50.018052208155524]],
    [ "Enedis",[2.0433821319340324, 50.021006176661665]],
    [ "Enedis",[2.0488901654599863, 50.020132511342794]],
    [ "Enedis",[2.0336482157668776, 50.02333145546726]],
    [ "Enedis",[2.0282254256724874, 50.02459204152902]],
    [ "Enedis",[2.3932714189843134, 50.07983031583884]],
    [ "Enedis",[2.393393644616592, 50.081788819959804]],
    [ "Enedis",[2.3927713404020263, 50.08638458689371]],
    [ "Enedis",[1.9750311999999999, 50.021870400000005]],
    [ "Enedis",[1.9406357559490863, 50.02787782881387]],
    [ "Enedis",[1.5639808248374243, 50.21501549087477]],
    [ "Enedis",[1.8963866593137253, 50.0508378357843]],
    [ "Enedis",[1.519097165229885, 50.192576948275864]],
    [ "Enedis",[1.5086608547854778, 50.191923600660054]],
    [ "Enedis",[1.50824796635752, 50.192000331514826]],
    [ "Enedis",[1.525937885554222, 50.20162073709484]],
    [ "Enedis",[1.5203706666666665, 50.19437500000001]],
    [ "Enedis",[1.5273203029279279, 50.19526192567567]],
    [ "Enedis",[1.517062701575904, 50.1974157604229]],
    [ "Enedis",[1.5007962915931798, 50.1801584973545]],
    [ "Enedis",[1.5030161450488153, 50.18424073012553]],
    [ "Enedis",[1.4984357883846287, 50.18521896174578]],
    [ "Enedis",[1.4975158878865982, 50.18186721499141]],
    [ "Enedis",[1.5049601677715463, 50.182455927078024]],
    [ "Enedis",[1.502587202269503, 50.17879129418441]],
    [ "Enedis",[1.490650321770069, 50.179979544807644]],
    [ "Enedis",[1.4893308208422558, 50.17749751605996]],
    [ "Enedis",[1.4940179445802766, 50.1790177196414]],
    [ "Enedis",[0.3958059531914896, 47.668712452077]],
    [ "Enedis",[1.498737726685134, 50.17567545152354]],
    [ "Enedis",[1.4969855000000003, 50.17196250000001]],
    [ "Enedis",[1.4911805026351128, 50.1755852591199]],
    [ "Enedis",[1.582821020128824, 50.15902683776167]],
    [ "Enedis",[1.5725256804407706, 50.11497603305784]],
    [ "Enedis",[1.5743077999999997, 50.11775360000001]],
    [ "Enedis",[1.565210828273536, 50.12093199478688]],
    [ "Enedis",[1.614223897056134, 50.121643299140594]],
    [ "Enedis",[1.615250993242009, 50.12758466776257]],
    [ "Enedis",[1.6165492974427995, 50.140407580080755]],
    [ "Enedis",[1.6157579962602844, 50.13120254923959]],
    [ "RTE",[6.357493150988941, 43.14059180046372]],
    [ "Enedis",[6.518308192017732, 43.18283880989409]],
    [ "Enedis",[3.2070983444909347, 45.72697105578801]],
    [ "Enedis",[0.4993587500000001, 47.712553250000006]],
    [ "Enedis",[1.6837437371794872, 50.15363339743589]],
    [ "Enedis",[1.6647304646868455, 50.13142456539121]],
    [ "Enedis",[1.671473083333333, 50.13540929166668]],
    [ "Enedis",[1.659988064320626, 50.129595752101665]],
    [ "Enedis",[1.6425053333333335, 50.09765061818182]],
    [ "Enedis",[1.6407915325670501, 50.1006533825032]],
    [ "Enedis",[1.638822000000001, 50.09708100000001]],
    [ "Enedis",[1.642110453786708, 50.09558767336425]],
    [ "Enedis",[1.7295693793160285, 44.52641154693037]],
    [ "Enedis",[0.5163637, 47.7073743]],
    [ "Enedis",[0.5071110000000013, 47.710390999999994]],
    [ "Poste électrique de Rennes",[-1.7055161475216032, 48.10601087439308]],
    [ "Le Mollard",[4.8418244613633155, 45.91188542532644]],
    [ "Canchy",[-0.35251338879644983, 49.17451345562951]],
    [ "Enedis",[6.253132514475529, 48.49644088395592]],
    [ "Enedis",[1.709002717111771, 50.13648046290802]],
    [ "Enedis",[1.5498046559648526, 50.11752644970148]],
    [ "Enedis",[1.8892686576836704, 49.99538151684312]],
    [ "Enedis",[1.872389, 49.99283350000001]],
    [ "Enedis",[1.7008932339483396, 50.299968953628536]],
    [ "RTE",[6.997849944604927, 47.776584966049754]],
    [ "Enedis",[2.8952466909123427, 48.340230821413]],
    [ "Enedis",[2.8505146269902553, 48.326916170287575]],
    [ "Enedis",[2.8959120617132528, 48.342909582499516]],
    [ "Enedis",[2.847101622481624, 48.32846769514005]],
    [ "Enedis",[2.8495314982164826, 48.32316844124562]],
    [ "Enedis",[2.9022533855281276, 48.34382199790531]],
    [ "Enedis",[2.003886024009978, 50.181419653258494]],
    [ "Enedis",[2.08694259002849, 50.143377462393175]],
    [ "Enedis",[2.0866473273056054, 50.16117001567209]],
    [ "Enedis",[1.9996130227835565, 50.07866265656264]],
    [ "Enedis",[1.9912207968949776, 50.06922055378995]],
    [ "Enedis",[1.9948706618750993, 50.07963583277431]],
    [ "Enedis",[2.3111642443919695, 50.004038973632426]],
    [ "Enedis",[2.3142624245880863, 50.00376715589354]],
    [ "Enedis",[2.3232157816091954, 49.997662539184944]],
    [ "Enedis",[2.316234179302046, 49.99267688447654]],
    [ "Enedis",[2.318417890295358, 49.99600977637131]],
    [ "Enedis",[2.364180353208404, 50.04388172799546]],
    [ "Enedis",[2.3294788575380356, 50.03451719225449]],
    [ "Enedis",[2.3249085359477126, 50.02899877647059]],
    [ "Enedis",[2.3197819999999987, 50.0340565]],
    [ "Enedis",[2.3201760000000005, 50.030575]],
    [ "Enedis",[2.3229129999999993, 50.03241049999998]],
    [ "Enedis",[2.1867939369034053, 48.89333173865733]],
    [ "Enedis",[1.9410523288069823, 50.072147005496284]],
    [ "Enedis",[0.1531089489849041, 48.09844427988895]],
    [ "Enedis",[0.14732587536514125, 48.10280192048036]],
    [ "Zac du Chatelet",[0.1539118539630834, 48.093576804017374]],
    [ "Enedis",[0.1483207404418702, 48.098140918888575]],
    [ "Enedis",[4.846388340230799, 45.74780153331842]],
    [ "Enedis",[6.890087676439792, 47.66080633368237]],
    [ "Enedis",[6.8735637500000015, 47.66525024999999]],
    [ "Enedis",[2.434797724637681, 49.93778296135265]],
    [ "Enedis",[2.4304882119460496, 49.937412198458574]],
    [ "Enedis",[2.444028334779914, 49.94491917358959]],
    [ "Enedis",[2.481136330827069, 49.95049291729323]],
    [ "Enedis",[2.4829279761904766, 49.95391267857143]],
    [ "Enedis",[2.4772379999999985, 49.9521775]],
    [ "Enedis",[-2.9580544284455805, 48.021399836517794]],
    [ "Enedis",[-2.952291, 48.019689750000005]],
    [ "Enedis",[-2.9538869311874105, 48.018060007153075]],
    [ "Enedis",[-2.9575609000000003, 48.040996400000004]],
    [ "Enedis",[1.9913322690142157, 49.78387273970836]],
    [ "Poste électrique de Houdreville",[6.081284319785505, 48.52000936395598]],
    [ "Enedis",[-2.3473139349348933, 48.2378819186323]],
    [ "Enedis",[6.937491499999999, 47.662981]],
    [ "Enedis",[2.4308727511737094, 49.81180254929578]],
    [ "Enedis",[1.8672613913392149, 49.77524072146485]],
    [ "Enedis",[1.8602020000000008, 49.773337]],
    [ "Enedis",[2.596717323573573, 49.871489340090086]],
    [ "Enedis",[2.592736, 49.871109499999996]],
    [ "Enedis",[2.029721377954848, 50.03414530916335]],
    [ "Enedis",[3.2820212785443736, 48.97288583353689]],
    [ "Enedis",[3.288946483690721, 48.97621435011042]],
    [ "Enedis",[3.279964363678619, 48.9756807124834]],
    [ "Enedis",[3.284560671698344, 48.97809591721249]],
    [ "Enedis",[3.2731281836238475, 48.975817434076305]],
    [ "Enedis",[3.2747443684216173, 48.975810402064134]],
    [ "Enedis",[3.283631046906428, 48.97549576521719]],
    [ "Enedis",[3.275553683171678, 48.977545112913596]],
    [ "Enedis",[3.2787858896242037, 48.979022811180535]],
    [ "Enedis",[3.275947145899735, 48.97396310059424]],
    [ "Enedis",[2.0765115994295025, 50.07100527506113]],
    [ "Enedis",[1.7100414417677507, 47.8386825215855]],
    [ "Enedis",[1.689108021033359, 47.82484942362935]],
    [ "Enedis",[1.7120674966259346, 47.8413605890005]],
    [ "Enedis",[1.7106682987894466, 47.83765585183659]],
    [ "Enedis",[1.6954670904461724, 47.83372336418964]],
    [ "Enedis",[1.7101583325394873, 47.84063666327531]],
    [ "Enedis",[1.716830437194825, 47.839756286497426]],
    [ "Enedis",[1.6770426390637203, 47.83128684205225]],
    [ "Enedis",[1.7100103976258312, 47.83870373987875]],
    [ "Enedis",[1.7028969070772109, 47.838345896970246]],
    [ "Enedis",[3.0426646088791154, 49.23415230881955]],
    [ "Enedis",[4.480270821618988, 43.81110944303738]],
    [ "Enedis",[4.894074642830105, 45.76676005060024]],
    [ "Enedis",[4.889802704487506, 45.76781797322964]],
    [ "Enedis",[4.895354241716825, 45.76652526476416]],
    [ "Enedis",[4.883446339357583, 45.76912030271821]],
    [ "Enedis",[4.898282300000003, 45.766156849999994]],
    [ "Enedis",[4.88421365, 45.76854215000001]],
    [ "Enedis",[4.85449155, 45.75867305]],
    [ "Enedis",[4.853777250000001, 45.75202825]],
    [ "Enedis",[4.896738833148049, 45.76646660641073]],
    [ "Enedis",[6.598647896169101, 46.07805309778121]],
    [ "Enedis",[2.030877335130278, 49.88494398382748]],
    [ "Enedis",[2.0198178794213644, 49.884288783099585]],
    [ "Enedis",[2.668875, 49.8446075]],
    [ "Enedis",[2.6613925664694493, 49.85166858000035]],
    [ "Enedis",[2.6704092749050523, 49.849878969617286]],
    [ "Enedis",[-3.108964000000001, 47.98201299999999]],
    [ "Enedis",[-2.466906888995965, 47.73624971235789]],
    [ "Enedis",[7.010377500000001, 47.5250485]],
    [ "Enedis",[4.011917325365002, 45.141660806075855]],
    [ "Enedis",[2.1185719241130703, 50.1180552618099]],
    [ "Enedis",[2.713377200000001, 49.9959474]],
    [ "Croix des rameaux",[4.088170299999998, 46.15143955]],
    [ "Enedis",[4.314605175363046, 43.788040064106845]],
    [ "Enedis",[1.7850234999999992, 44.920221000000005]],
    [ "Enedis",[1.8160870943396221, 49.993832748427664]],
    [ "Enedis",[6.8462060000000005, 47.64655099999999]],
    [ "Enedis",[-2.9018364788268958, 47.996739344062945]],
    [ "Enedis",[2.370711, 50.176721499999985]],
    [ "Enedis",[2.3821294630467573, 50.17940666968325]],
    [ "Enedis",[2.5666496551724136, 49.95949366666667]],
    [ "Poste électrique de l'Étoile",[-52.88760553394864, 5.155605303923433]],
    [ "Enedis",[2.568584923230128, 49.95341029585371]],
    [ "Enedis",[2.537576518574487, 49.951089326407256]],
    [ "Enedis",[2.5374088329621385, 49.9532220155902]],
    [ "Enedis",[1.1734061220209457, 44.51724252678481]],
    [ "Enedis",[3.5259239990585702, 49.47887550363796]],
    [ "Enedis",[3.5192198257625624, 49.48186150954576]],
    [ "Enedis",[3.5192250000000005, 49.479299499999996]],
    [ "Enedis",[0.9454191231992054, 48.97123091505217]],
    [ "Enedis",[1.6747962052002243, 48.61205007069655]],
    [ "Enedis",[1.69552566642277, 48.593104283332146]],
    [ "Enedis",[1.6818312054273115, 48.61258293848194]],
    [ "Enedis",[1.7027589403567562, 48.61007196296528]],
    [ "Enedis",[1.6908929727545012, 48.605437570420285]],
    [ "Enedis",[1.6708344753795554, 48.61191988058597]],
    [ "Enedis",[1.678246816098424, 48.61208662271904]],
    [ "Enedis",[1.667100702324863, 48.60634860335985]],
    [ "Enedis",[1.6928309330166833, 48.593134756913535]],
    [ "Enedis",[1.6841714423676921, 48.59665686258186]],
    [ "Enedis",[1.6649814738273288, 48.604021941536374]],
    [ "Enedis",[1.6819897870503842, 48.60272202143933]],
    [ "Enedis",[1.6867344062278005, 48.608164038427226]],
    [ "Enedis",[1.6735981218452303, 48.60630320956116]],
    [ "Enedis",[1.6994882310609272, 48.60026824847799]],
    [ "Enedis",[1.6843843500000002, 48.6113213]],
    [ "Enedis",[1.6861009118994519, 48.59300497810846]],
    [ "Enedis",[1.681288327320517, 48.6043577231314]],
    [ "Enedis",[1.6932285168164154, 48.59419459241273]],
    [ "Enedis",[1.7061817246948847, 48.59830736022595]],
    [ "Enedis",[1.6808411807500385, 48.60924279080042]],
    [ "Enedis",[1.7027338747437408, 48.59993153314018]],
    [ "Enedis",[1.6783187266131163, 48.614075862331]],
    [ "Enedis",[1.67463562221951, 48.61038003297066]],
    [ "Enedis",[2.4099091606192546, 50.02643401015965]],
    [ "Enedis",[-0.7494597916114796, 44.14894971810154]],
    [ "Enedis",[-0.7460372999999999, 44.149254899999995]],
    [ "Enedis",[-0.7457286750000001, 44.14780081666667]],
    [ "Enedis",[-0.7396807253630888, 44.144482263301455]],
    [ "Enedis",[-0.7378316495064544, 44.15091503591496]],
    [ "Enedis",[1.7587429950248754, 49.884018690120826]],
    [ "Enedis",[2.2781284271186446, 49.707352403389834]],
    [ "Enedis",[2.1943157906545694, 50.169515549995126]],
    [ "Enedis",[3.0268475000000006, 45.09134549999999]],
    [ "RTE",[0.4044442779544388, 47.30402169476429]],
    [ "Enedis",[-4.0614092795796175, 48.516458447641796]],
    [ "Enedis",[2.393974560337553, 49.73287668523206]],
    [ "Enedis",[2.6040495203503355, 50.0801963166409]],
    [ "Enedis",[3.002057169909209, 49.79875501167316]],
    [ "Enedis",[3.0076584999999985, 49.798061999999994]],
    [ "Enedis",[1.6571859999999987, 44.5653236]],
    [ "Enedis",[1.194086315682996, 48.76145649537276]],
    [ "Enedis",[1.1982355487380705, 48.764399664045364]],
    [ "Enedis",[1.1951965856199411, 48.75186351639637]],
    [ "Enedis",[1.2012137028207495, 48.75759797551329]],
    [ "Enedis",[1.2022663375058715, 48.762514798675035]],
    [ "Enedis",[1.1986618098720798, 48.75940991678259]],
    [ "Enedis",[1.1862068932101708, 48.76236890100179]],
    [ "Enedis",[1.195129495306197, 48.76270010387331]],
    [ "Enedis",[1.1996725240668094, 48.7688862406681]],
    [ "Enedis",[1.1892411836679346, 48.76587113247819]],
    [ "Enedis",[3.184886888888889, 47.55608675132276]],
    [ "La Croisette",[6.17204625, 46.119008799999996]],
    [ "Enedis",[3.2206337634854774, 47.61245048778239]],
    [ "Enedis",[2.4363330209394456, 49.97960683418223]],
    [ "Enedis",[0.514528, 47.802448549999994]],
    [ "Enedis",[1.642936492399789, 46.15989856534616]],
    [ "Enedis",[1.6478210000000006, 46.160804600000006]],
    [ "Enedis",[1.4876853021779441, 46.23469883338239]],
    [ "Enedis",[1.5046070818032535, 46.23045192521272]],
    [ "Chataigners",[6.139144225593603, 45.88402769206816]],
    [ "Corniche",[6.1342564193116615, 45.89088286243035]],
    [ "Église",[6.141271915381358, 45.86417567507716]],
    [ "Enedis",[6.1484423, 48.675019750000004]],
    [ "Enedis",[1.113892435718441, 45.80287415532286]],
    [ "SOURCE",[1.9431204271400413, 47.845880217879724]],
    [ "Enedis",[-0.6920395984848485, 43.14507266666667]],
    [ "Enedis",[-0.7273342447782539, 43.12275913319026]],
    [ "Enedis",[-0.7238422277739646, 43.126660938046214]],
    [ "Enedis",[-0.6591314385102882, 43.099951714258154]],
    [ "RTE",[4.869283795832267, 45.81521374901619]],
    [ "Enedis",[0.6749631881974089, 48.18901545225791]],
    [ "Enedis",[0.6800647320000015, 48.19272908786207]],
    [ "Enedis",[6.995419221183802, 47.51059709345795]],
    [ "SNCF Réseau",[2.5758667002715008, 48.70768803520192]],
    [ "Foyer Cantal En",[2.5393537153138976, 45.24835939345913]],
    [ "EDF",[6.133760814728381, 43.73773722950453]],
    [ "La Grouas",[0.20989468200836806, 48.11146594281728]],
    [ "Enedis",[0.21397480102040817, 48.10862342687074]],
    [ "Enedis",[-1.6708362957095713, 48.15351663696369]],
    [ "Électricité de France",[0.6330078400447815, 49.85918431072897]],
    [ "Électricité de France",[0.6372096317705512, 49.85886710690667]],
    [ "Électricité de France",[0.6351050717863119, 49.85902312808661]],
    [ "Électricité de France",[0.63930287713246, 49.858708188209455]],
    [ "Enedis",[1.8860173495934953, 44.94791766666666]],
    [ "Enedis",[-1.1534897996650657, 44.41405152181868]],
    [ "Enedis",[-3.3656938000000007, 47.902831250000006]],
    [ "Enedis",[-3.34594125, 47.932242349999996]],
    [ "Enedis",[-3.378725486548834, 47.935826131176945]],
    [ "Enedis",[-2.2018732500000002, 47.914048]],
    [ "Enedis",[1.6646147500000001, 46.70054139999999]],
    [ "Poste électrique de Plaisance",[2.5188670201408017, 48.865382359807064]],
    [ "BARP (CEA)",[-0.7854963946519947, 44.63662895081737]],
    [ "réséda",[6.1651675324857935, 49.132599010668905]],
    [ "réséda",[6.159838949063548, 49.12814689916701]],
    [ "réséda",[6.146667768655219, 49.128750484908615]],
    [ "réséda",[6.180955434520143, 49.1371742384611]],
    [ "réséda",[6.150246235189314, 49.14079101309548]],
    [ "réséda",[6.142640389543678, 49.130082722210325]],
    [ "réséda",[6.155404536696396, 49.130392966155874]],
    [ "réséda",[6.1724953361243005, 49.1346055530504]],
    [ "réséda",[6.139572763956002, 49.13531997819682]],
    [ "réséda",[6.166251299999999, 49.139823449999994]],
    [ "réséda",[6.1462232828625165, 49.1387953157729]],
    [ "réséda",[6.1482589822411615, 49.13215251555731]],
    [ "Rovelli",[6.1544905, 49.1394134]],
    [ "réséda",[6.164360249999999, 49.130467599999996]],
    [ "réséda",[6.170368699999998, 49.14537239999999]],
    [ "réséda",[6.166706819702617, 49.13830800287683]],
    [ "réséda",[6.167778831759339, 49.136387239668565]],
    [ "réséda",[6.16588722174108, 49.14107946529055]],
    [ "réséda",[6.172783770403986, 49.1393851770405]],
    [ "réséda",[6.1727258957232145, 49.14143226457579]],
    [ "réséda",[6.149680086038272, 49.13223924642593]],
    [ "réséda",[6.148481441582467, 49.136872512413454]],
    [ "réséda",[6.176433977213141, 49.1405035226951]],
    [ "réséda",[6.174086214427861, 49.147455869007075]],
    [ "réséda",[6.15841662803158, 49.12879533355037]],
    [ "réséda",[6.187330316026356, 49.14068470464964]],
    [ "réséda",[6.175162039715147, 49.142753781161744]],
    [ "réséda",[6.1521089877197435, 49.13131998119123]],
    [ "réséda",[6.147868456345253, 49.141760119885554]],
    [ "réséda",[6.149712527680261, 49.130593309068324]],
    [ "Nicolas Jung",[6.158115116300873, 49.1347174172364]],
    [ "Enedis",[6.1123413, 48.65791714999999]],
    [ "Basilique",[6.125869549999999, 45.894453500000004]],
    [ "Visitation",[6.126567374187411, 45.89152039519728]],
    [ "Enedis",[5.885739857632881, 46.06213142112071]],
    [ "Enedis",[-0.3424493, 43.301799849999995]],
    [ "Enedis",[3.0442361221071477, 45.8720482232451]],
    [ "Enedis",[3.041625943270301, 45.86814071672227]],
    [ "Enedis",[2.9952769102495878, 45.872356576423186]],
    [ "Enedis",[3.037749311111111, 45.86891033422223]],
    [ "Enedis",[3.041566981382978, 45.875577706560286]],
    [ "Enedis",[5.105251674388817, 45.05872254146931]],
    [ "Enedis",[5.105689306653542, 45.05798929282178]],
    [ "Enedis",[2.9969883, 45.7646908]],
    [ "Enedis",[1.1505057918968693, 47.9702974014733]],
    [ "Enedis",[1.557341676190476, 46.64428732380953]],
    [ "Enedis",[-1.4208035747069694, 46.35181229049479]],
    [ "Enedis",[1.1415993500000001, 42.983431499999995]],
    [ "Enedis",[2.6004641499999996, 46.027081900000006]],
    [ "Enedis",[1.6493006379906552, 48.43894217486937]],
    [ "Enedis",[4.844364484256084, 45.793570802654]],
    [ "Enedis",[1.5844240000000016, 48.54050049999999]],
    [ "Enedis",[1.585723, 48.53594150000001]],
    [ "Enedis",[1.5849060468319562, 48.54843018732783]],
    [ "Enedis",[1.5844690791397846, 48.53698018709677]],
    [ "Enedis",[1.5880213767605638, 48.54284608685445]],
    [ "Enedis",[1.3883162011494259, 48.184614933908044]],
    [ "Enedis",[1.376136844505244, 48.17902852074784]],
    [ "Enedis",[1.3973393775933618, 48.17654799170124]],
    [ "Enedis",[1.3988073202463434, 48.198158045419554]],
    [ "Enedis",[1.391284931301403, 48.19052623056189]],
    [ "Enedis",[1.397913781917536, 48.18208011624441]],
    [ "Enedis",[1.4001096004829623, 48.19907908890082]],
    [ "Enedis",[1.380245629340278, 48.17839335590278]],
    [ "Deyrier",[6.1360821, 46.0268383]],
    [ "Enedis",[1.879843287427912, 44.90932893979239]],
    [ "Enedis",[1.882728261513908, 44.909098757774736]],
    [ "Enedis",[-2.750481271673432, 48.06771908350898]],
    [ "La Monnerie",[0.2747382857850421, 47.76558994848934]],
    [ "Enedis",[0.2760912775573192, 47.75676472354497]],
    [ "Enedis",[0.27612091721655757, 47.76802081803639]],
    [ "Enedis",[3.96000935, 45.0518305]],
    [ "Enedis",[1.1867199039470055, 49.380058385086016]],
    [ "Le Collet",[4.3970359000000006, 45.22978195]],
    [ "Serepi",[4.849424218630627, 45.913258518708396]],
    [ "Enedis",[1.8146013000000007, 46.59828080000001]],
    [ "Enedis",[1.8051165443166253, 46.596566787044644]],
    [ "Enedis",[3.696014762272241, 48.08466315841372]],
    [ "RTE",[7.224967102948085, 47.62746138857748]],
    [ "RTE",[7.308616591744142, 47.55262030212728]],
    [ "Clairière",[6.254767217408079, 48.697382001761945]],
    [ "Enedis",[1.8196446509961943, 46.73993265704053]],
    [ "Enedis",[1.805103207481985, 46.76272741150923]],
    [ "Enedis",[1.8355157044165502, 46.74266856336587]],
    [ "Enedis",[1.8346195558614364, 46.7447462115164]],
    [ "Rte de St Cyr",[0.9579446072242166, 45.77291902097455]],
    [ "Enedis",[4.600508738471296, 43.69616990386932]],
    [ "Enedis",[1.8512565243685821, 44.924095934674796]],
    [ "Enedis",[1.8542135170502978, 44.92998083930094]],
    [ "Enedis",[1.8526518829516536, 44.92608130025445]],
    [ "Enedis",[6.197672049999999, 48.564093250000006]],
    [ "Enedis",[6.199505, 48.56360575]],
    [ "Enedis",[6.201376749999999, 48.56298674999999]],
    [ "Cap sud",[6.091792190347459, 45.9001167283459]],
    [ "Le Jourdil",[6.097916550000001, 45.90613414999999]],
    [ "Les Hauts de Gevrier",[6.095876961731059, 45.906653559946754]],
    [ "Vergers de Cran",[6.090738449999998, 45.895542049999996]],
    [ "Enedis",[6.181265478540389, 48.56685349110756]],
    [ "Enedis",[6.186581333333335, 48.565841763636364]],
    [ "Enedis",[6.186687099999999, 48.56409215]],
    [ "Enedis",[6.190305500000001, 48.56654425]],
    [ "Enedis",[6.188586249999999, 48.569270749999994]],
    [ "Pouilly",[6.335424250000001, 46.1413775]],
    [ "Enedis",[-1.6099957499999995, 46.68413725]],
    [ "59 Av des Romains",[6.116713765137193, 45.912469211511606]],
    [ "Belvedere",[6.122157396847005, 45.915290553532714]],
    [ "Enedis",[6.12506253375884, 45.91730036376782]],
    [ "Cité des sports",[6.120973371884168, 45.9145688989572]],
    [ "Clos Pinget",[6.122291031313392, 45.9136120762312]],
    [ "HLM Solidarité",[6.1174918, 45.91387484999999]],
    [ "Leclerc",[6.1208999, 45.91381065]],
    [ "Les Parons",[6.1229803, 45.913552249999995]],
    [ "Résidence de Brogny",[6.1241141999999975, 45.923653599999994]],
    [ "Enedis",[-1.6110453388566277, 46.679138971441475]],
    [ "Enedis",[3.271763950660794, 49.69509519236418]],
    [ "Leclerc",[2.806486256564084, 49.4062507348257]],
    [ "Enedis",[6.184600576159014, 48.643710059762704]],
    [ "Poste électrique de Cluny",[4.663021480451984, 46.42781192491821]],
    [ "Enedis",[0.6226369999999999, 47.503859000000006]],
    [ "Enedis",[3.3226370000000003, 48.046217999999996]],
    [ "Enedis",[3.319608074872169, 48.04933438858047]],
    [ "Enedis",[3.3217603466440107, 48.04518364032852]],
    [ "Enedis",[3.07796715, 50.634789250000004]],
    [ "Enedis",[2.3203522042981746, 50.16215112426941]],
    [ "Enedis",[2.343433333333334, 50.15074033333333]],
    [ "Enedis",[2.350528612244898, 50.14928266666666]],
    [ "Enedis",[2.357245175713711, 50.1482198234821]],
    [ "Enedis",[2.346488920723227, 50.14869479406584]],
    [ "Enedis",[2.356975752891053, 50.1621171476973]],
    [ "Enedis",[2.3424744999999993, 50.16346599999999]],
    [ "Enedis",[2.350158307539682, 50.15379295238096]],
    [ "Enedis",[2.332361433825434, 50.15967481792281]],
    [ "Enedis",[2.3482174351945853, 50.16089369712352]],
    [ "Enedis",[2.3356012051282056, 50.157661450142456]],
    [ "Enedis",[2.337710015486725, 50.15893979351032]],
    [ "Enedis",[2.3524231889996305, 50.16240454669619]],
    [ "Enedis",[2.353221, 50.160444]],
    [ "Enedis",[2.3362788241998436, 50.15649165144418]],
    [ "Enedis",[2.3365964511440107, 50.15978423436518]],
    [ "Enedis",[2.3420477398373984, 50.15516214634146]],
    [ "Enedis",[2.339845635802469, 50.15396747839506]],
    [ "Enedis",[2.346135592483178, 50.15610529935993]],
    [ "Enedis",[2.464553, 50.211595]],
    [ "37 Av du Parmelan",[6.1343215145403205, 45.907138149283384]],
    [ "Étang",[6.168354150000001, 46.011873400000006]],
    [ "Jonquilles",[6.134860412831884, 45.909785739218016]],
    [ "Le Star",[6.133893463490591, 45.90574732231062]],
    [ "Marmottes R Favre",[6.136806626876733, 45.90429891237248]],
    [ "Prefecture",[6.1314015763296315, 45.90322207164213]],
    [ "RTE Chevene",[6.112448639788985, 45.90176382173296]],
    [ "Roselet",[6.1355456307216505, 45.905298634299164]],
    [ "Enedis",[6.140117789496188, 45.90611837068864]],
    [ "ZAC Port du Chateau",[-4.487362731312849, 48.37994137951833]],
    [ "Enedis",[4.7440539057422, 46.33178938002483]],
    [ "Chez Chevalli",[6.278969749999999, 46.070298750000006]],
    [ "Enedis",[1.625848, 43.087568]],
    [ "Enedis",[1.6259127595870206, 43.096724106194685]],
    [ "Enedis",[1.6228793073159884, 43.08661688681344]],
    [ "Enedis",[1.62414579455217, 43.09437710941829]],
    [ "Enedis",[1.6229065232174578, 43.094644681130006]],
    [ "Enedis",[1.627150419117647, 43.09567838725491]],
    [ "Enedis",[1.6267566899224803, 43.09255798837209]],
    [ "Enedis",[2.3011308035190616, 50.99072683284458]],
    [ "7 rue de la crete",[6.105800637956191, 45.90852963579507]],
    [ "Bouillet",[6.10938789891831, 45.9068202956261]],
    [ "Edelweiss",[6.11121290528098, 45.906614520715415]],
    [ "Les greves",[6.103183249729481, 45.9088482334634]],
    [ "Les tets",[6.115263649999999, 45.90773989999999]],
    [ "Limitrophe",[6.11410743763688, 45.90667850454032]],
    [ "Mosinge",[6.1075892571210115, 45.90933245784794]],
    [ "Square",[6.10941245, 45.90463185]],
    [ "Enedis",[4.885382652283351, 43.99715396583963]],
    [ "Enedis",[3.630549647291064, 48.208855517537565]],
    [ "Enedis",[3.53454, 48.231641]],
    [ "Enedis",[3.5367017288135596, 48.23334160451977]],
    [ "Enedis",[3.5464425482341184, 48.23485664145762]],
    [ "Enedis",[2.967669731770833, 46.140566164062506]],
    [ "Enedis",[2.0251895, 48.527091999999996]],
    [ "Enedis",[1.777775751412429, 50.18768842937853]],
    [ "Enedis",[1.7639114767932498, 50.17209422362869]],
    [ "Enedis",[3.419440475012569, 49.95117716913022]],
    [ "Enedis",[2.845513656457624, 50.34066347901475]],
    [ "Enedis",[1.4407849488310542, 47.76796496412292]],
    [ "Enedis",[2.467821582742618, 51.058248748255174]],
    [ "Enedis",[-1.8392655608766233, 47.630657433306276]],
    [ "Enedis",[2.3796590150416934, 48.87375378380502]],
    [ "Pre de la source",[6.150886921587276, 45.832700297024026]],
    [ "Résidence du Centre",[6.164443458371153, 45.83226451569422]],
    [ "ZAC Periaz bas",[6.098124250000001, 45.88180465000001]],
    [ "Adenauer",[2.803214023567252, 49.40344863814473]],
    [ "10 Avenue de Novel",[6.126946150000001, 45.9100407]],
    [ "Germain Sommeiller",[6.130886496361558, 45.90678446005749]],
    [ "Enedis",[3.7683867011572363, 45.13350459925306]],
    [ "Enedis",[3.89913115, 45.247423500000004]],
    [ "Montpeyroux",[3.8860513499999993, 45.26140405]],
    [ "Enedis",[6.183329022668394, 48.701837159542315]],
    [ "Sous-station des Quatrouillots",[6.003391365014368, 47.269116381797545]],
    [ "Lossulien",[-4.399747492797321, 48.397391183249574]],
    [ "École",[0.5584067500000001, 47.74324550000001]],
    [ "Enedis",[0.5628782344670304, 47.74267711743849]],
    [ "Enedis",[0.9989704321745058, 47.470133606254265]],
    [ "Boileau 2",[6.141898014228965, 45.90709602828609]],
    [ "Briand",[6.126604749087438, 45.912274279672395]],
    [ "Colisee",[6.1334821, 45.91377855000001]],
    [ "Cret Duparc 1",[6.13490748220244, 45.91202411941814]],
    [ "La Vigie",[6.13337445, 45.91078315]],
    [ "Les Tilleuls",[6.14237407276626, 45.90838386541768]],
    [ "Lycée Berthollet",[6.127872031923709, 45.906953322596074]],
    [ "Rue Felix Petit",[6.12699776855106, 45.908458862367894]],
    [ "Salomon",[6.137684960242276, 45.91209788432151]],
    [ "Taine",[6.128278772178366, 45.90698680236808]],
    [ "ZUP 11 Garage",[6.131354083807158, 45.91610100064227]],
    [ "Enedis",[6.1328248, 45.91150865]],
    [ "Enedis",[6.139941849810959, 45.90944386377369]],
    [ "Enedis",[-2.745916292483989, 47.64339435739805]],
    [ "Enedis",[3.827997758306538, 46.906922961414786]],
    [ "Enedis",[3.8236211448471784, 46.90730115890232]],
    [ "Enedis",[3.8417270878661087, 46.90508669177127]],
    [ "Enedis",[3.8316600378555346, 46.90880046347452]],
    [ "Le Pré au Crêt",[5.741763999210984, 45.14383889321971]],
    [ "Enedis",[2.487989400000001, 49.00105135]],
    [ "RTE",[6.569020584033232, 45.730725590463194]],
    [ "Beaufort",[6.57484714784838, 45.71806031703838]],
    [ "Les Curtillets",[6.578006709736461, 45.72578947338308]],
    [ "Enedis",[6.553998598166908, 45.7270140810902]],
    [ "Enedis",[3.1225776842897766, 50.40167500598156]],
    [ "Enedis",[3.0355669764309763, 42.542724319865314]],
    [ "Poste électrique de Le Gouray",[-2.5050177095450366, 48.30436055267362]],
    [ "Clos du Plantis 2",[0.33959312343865994, 46.65708412345992]],
    [ "Enedis",[0.9099077499999986, 47.59542619999999]],
    [ "Enedis",[0.26702865, 47.1836889]],
    [ "Enedis",[3.247471786492375, 47.96702378867102]],
    [ "Enedis",[1.1340433397341207, 49.48349653947152]],
    [ "Enedis",[1.1294694664044571, 49.47246417174697]],
    [ "UTC",[2.8002744845570495, 49.40045053312196]],
    [ "RTE",[1.6042653523157937, 45.72149874018686]],
    [ "Enedis",[5.705044394778371, 49.414315935597024]],
    [ "Enedis",[3.0937975680751175, 46.982979056338024]],
    [ "Enedis",[3.098265473518143, 46.992313163849445]],
    [ "Enedis",[3.0889561056676276, 46.98073370365034]],
    [ "Enedis",[3.0840004009843964, 47.00377770237721]],
    [ "Enedis",[3.086037002650986, 46.98789948969476]],
    [ "Enedis",[3.0817066172570384, 47.00946273060854]],
    [ "Enedis",[3.1058409999999994, 46.99880049999999]],
    [ "Enedis",[3.082698896713615, 46.967723403755876]],
    [ "Enedis",[3.103688007450132, 47.00354833429464]],
    [ "Enedis",[3.0818611660015964, 47.004573506783714]],
    [ "Enedis",[3.110759535084428, 46.98534681338336]],
    [ "Enedis",[3.083528, 46.99458599999999]],
    [ "Enedis",[3.079223637026903, 46.99322476151391]],
    [ "Enedis",[3.084834864068662, 46.982132136627236]],
    [ "Enedis",[3.080389786666666, 46.99235309333333]],
    [ "Enedis",[3.0831880192307692, 47.00136366987179]],
    [ "Enedis",[3.107151, 46.98058950000001]],
    [ "Enedis",[3.085410066922831, 46.975562217739345]],
    [ "Enedis",[3.090856670932358, 46.996841306520416]],
    [ "Enedis",[3.394165505288852, 46.181192835909954]],
    [ "Poste électrique de Courbevoie",[2.26119799270073, 48.905407134836814]],
    [ "Enedis",[0.6837992500000001, 47.43763975]],
    [ "Enedis",[2.509122701149426, 46.329255701149414]],
    [ "Enedis",[2.5124467124183005, 46.32554709150327]],
    [ "Enedis",[2.506519872582478, 46.326011496018204]],
    [ "Enedis",[2.5157575000000003, 46.32914050000001]],
    [ "Enedis",[0.6016695, 49.85243800000001]],
    [ "Enedis",[2.566184603547208, 46.27839046583203]],
    [ "Enedis",[0.6834582000000001, 47.41800555]],
    [ "Enedis",[0.6866350298082154, 47.404457089552054]],
    [ "Enedis",[1.750933015656134, 47.864226885852545]],
    [ "Enedis",[1.7437273165591396, 47.86581084301075]],
    [ "Enedis",[1.733402095913978, 47.84974211096774]],
    [ "Enedis",[1.7636377023675323, 47.86311716384817]],
    [ "Enedis",[1.7568181031307548, 47.859638685082864]],
    [ "Enedis",[1.759240836788182, 47.860942005003565]],
    [ "Enedis",[1.7618798039094223, 47.86537077651593]],
    [ "Enedis",[1.7579227571108977, 47.86440511872802]],
    [ "Enedis",[1.7656301469534053, 47.86653962724015]],
    [ "Enedis",[1.7446061465324385, 47.85509194630872]],
    [ "Enedis",[1.750993833842127, 47.85932610379415]],
    [ "Poste électrique Pyramides",[2.330723210122961, 48.86612663375035]],
    [ "Enedis",[1.4834338304835448, 43.53347549696635]],
    [ "Enedis",[0.7962965000000001, 47.28669635]],
    [ "Enedis",[0.7954772098966029, 47.28779373535205]],
    [ "Enedis",[6.44788245, 46.23484685]],
    [ "Enedis",[6.43744235, 46.22596265000001]],
    [ "Enedis",[6.471014172930932, 46.25066375448167]],
    [ "Enedis",[6.4347146020206045, 46.235843767803175]],
    [ "Burdignin",[6.420762829431111, 46.22116655703926]],
    [ "Mermoz 1",[2.2931034500000007, 48.811077]],
    [ "Enedis",[2.8717901908907053, 50.20049246241099]],
    [ "Enedis",[2.988500887835663, 48.201988121997985]],
    [ "Enedis",[2.987417056251812, 48.20441302583269]],
    [ "Enedis",[2.986587678601235, 48.20336621321983]],
    [ "Enedis",[0.8350082969129495, 45.83755840012863]],
    [ "Enedis",[0.8353957381963878, 45.829414761612604]],
    [ "Enedis",[0.8384703396066394, 45.83204464626701]],
    [ "Enedis",[0.845612306666955, 45.83956530353974]],
    [ "Enedis",[0.8195639608518276, 45.82734247187776]],
    [ "Enedis",[0.8592067613076554, 45.83689234215048]],
    [ "Enedis",[0.8257877541036003, 45.834754696336695]],
    [ "Enedis",[0.8264197519728641, 45.84190415739139]],
    [ "Enedis",[0.8423914325332121, 45.834508376035906]],
    [ "Enedis",[0.8349184380954741, 45.83725658059821]],
    [ "Enedis",[0.8316268047791341, 45.833964861864395]],
    [ "Enedis",[0.82192045, 45.82766855]],
    [ "Enedis",[0.8075156545972386, 45.80180745572431]],
    [ "Enedis",[0.8309651718359513, 45.829830643652365]],
    [ "Enedis",[2.9350227168754937, 46.83505390182865]],
    [ "Enedis",[2.908869570769797, 46.83319675077716]],
    [ "Enedis",[1.5443757919792398, 47.27845697218052]],
    [ "Enedis",[1.553641281831308, 47.27695914592307]],
    [ "Enedis",[1.5651646689911285, 47.2805805448181]],
    [ "Enedis",[1.553728723442118, 47.27378791815901]],
    [ "Enedis",[1.5778097508734632, 47.28259345308914]],
    [ "Enedis",[1.5446466807663288, 47.27646088377129]],
    [ "Enedis",[1.550366255635189, 47.278601105475786]],
    [ "Enedis",[1.5433411799341155, 47.28360864357307]],
    [ "Enedis",[1.5544723499999984, 47.28509155]],
    [ "Enedis",[1.538606058294113, 47.27745427090117]],
    [ "Enedis",[1.5365509835424305, 47.28312005129009]],
    [ "Enedis",[1.5393822618359734, 47.28546850326951]],
    [ "Enedis",[1.5562576746781125, 47.27327505937053]],
    [ "Enedis",[1.5443045395462225, 47.28087884757126]],
    [ "Enedis",[1.5572923065412183, 47.27583477697133]],
    [ "Enedis",[1.5418069205113178, 47.276779145289]],
    [ "Enedis",[1.5444381546031416, 47.278451019088465]],
    [ "Enedis",[1.5462497677791702, 47.28426580102793]],
    [ "Enedis",[1.5601879691075462, 47.27909678883776]],
    [ "Poste électrique de Kerbach",[6.976579364753249, 49.153813705097704]],
    [ "Enedis",[1.9516295300468494, 48.74659678931993]],
    [ "Enedis",[1.971975544544178, 48.74593748435407]],
    [ "Enedis",[1.9418941411120725, 48.74593585335015]],
    [ "Enedis",[1.9592284481222024, 48.74649672186565]],
    [ "Enedis",[2.6854228130675226, 48.23252289693715]],
    [ "Seigle",[2.6394650896663654, 48.34616047439812]],
    [ "Ecosse",[2.640177940798311, 48.34874438838035]],
    [ "Recloses",[2.6435687199501254, 48.34762055738432]],
    [ "Jockey",[2.6421942526600275, 48.35138153521721]],
    [ "Pontferrat",[2.639604929425696, 48.343578569917646]],
    [ "Enedis",[2.602388501063965, 48.496830901252494]],
    [ "Poste électrique de Saint-Agathon",[-3.080452523668548, 48.55565889769129]],
    [ "Enedis",[6.24106266936163, 48.70846950277919]],
    [ "Poste électrique d'Allonne",[2.129711724003774, 49.385592326892954]],
    [ "Enedis",[-0.6725309197066, 44.72849854851743]],
    [ "Enedis",[-0.6720997585767424, 44.73307619488568]],
    [ "Enedis",[-0.6645531515516431, 44.735595889340864]],
    [ "Enedis",[-0.6671947683250017, 44.73394618019519]],
    [ "Enedis",[-0.6704884986118761, 44.735206968091894]],
    [ "Enedis",[-0.6630969309770375, 44.73872367769023]],
    [ "Enedis",[-0.66799015, 44.73780295]],
    [ "Enedis",[-0.6766012739627589, 44.734374021885145]],
    [ "Enedis",[-0.6802546989289459, 44.73515122645815]],
    [ "Enedis",[-0.6746000404359581, 44.736166256820994]],
    [ "Enedis",[-0.6918630752697414, 44.7645896644037]],
    [ "Enedis",[-0.682750702800899, 44.739780111681725]],
    [ "Enedis",[-0.7019589533884808, 44.77151003356988]],
    [ "Enedis",[-0.6980473077918965, 44.776232393935594]],
    [ "Enedis",[-0.6974908205989724, 44.76560318583464]],
    [ "Enedis",[-0.6867668388013174, 44.74036088588928]],
    [ "Enedis",[-0.7056347588518689, 44.77408716575912]],
    [ "Enedis",[-0.7005895093397742, 44.776656626317]],
    [ "Enedis",[-0.695377598718015, 44.77074402626646]],
    [ "Enedis",[-0.6927929472056424, 44.768520323057615]],
    [ "Enedis",[-0.7061502870846069, 44.772246608344425]],
    [ "Enedis",[-0.6948833500000001, 44.7775525]],
    [ "Enedis",[-0.7021934312708917, 44.76837442387509]],
    [ "Enedis",[-0.7036960271119264, 44.77005596228607]],
    [ "Enedis",[1.6324378846924181, 47.933463911587985]],
    [ "Enedis",[0.1541223193512308, 47.787226039149886]],
    [ "Enedis",[0.16178229738562092, 47.78271105228759]],
    [ "bourg",[0.16248895238095248, 47.78452431428571]],
    [ "Enedis",[0.1652712904109592, 47.78985183105023]],
    [ "Enedis",[3.3121652097378274, 48.24486525612216]],
    [ "Enedis",[3.3244339510532837, 48.24460414869888]],
    [ "Enedis",[3.3165183191721113, 48.24799099128539]],
    [ "Enedis",[-1.0033163188405794, 49.344044469565205]],
    [ "Enedis",[3.26764697710718, 48.255600585848065]],
    [ "Enedis",[3.262454384527873, 48.25709228816838]],
    [ "Enedis",[3.2538907492006386, 48.26742642605915]],
    [ "Enedis",[3.2561463054611375, 48.26431031067301]],
    [ "Enedis",[2.689580300000001, 43.5491409]],
    [ "Enedis",[3.2404019896882503, 48.25189226378897]],
    [ "Enedis",[4.83345302323737, 45.1587608353444]],
    [ "HLM Donjean",[6.164147699814697, 45.83495642149529]],
    [ "Le Villard",[6.163708241862426, 45.82915022909991]],
    [ "Les Verts Prés",[6.1713935168914915, 45.8290629789385]],
    [ "Enedis",[0.45340299999999994, 47.022942]],
    [ "Enedis",[0.4561109760837071, 47.03839117787743]],
    [ "Enedis",[6.243441154939677, 48.706076671086166]],
    [ "Enedis",[4.0022234503249, 45.74678704981336]],
    [ "Talence",[-0.5977169911771196, 44.81593501990315]],
    [ "Enedis",[2.4355511165295023, 49.33676022635377]],
    [ "Enedis",[2.8954404214521707, 48.2370587988658]],
    [ "Enedis",[2.8549108836575128, 48.2493614001705]],
    [ "Enedis",[2.825149255305309, 48.2111205376999]],
    [ "Enedis",[2.901898385415681, 48.23866909700575]],
    [ "Enedis",[2.850659230975489, 48.24763943679441]],
    [ "Enedis",[2.795247051450932, 48.22188519872586]],
    [ "Enedis",[2.902951680728341, 48.23830251273086]],
    [ "Enedis",[2.9183191508564916, 48.24567887415936]],
    [ "Enedis",[2.9006430238608334, 48.24200968070398]],
    [ "Enedis",[2.8893915200028157, 48.249344032919986]],
    [ "Enedis",[0.49366396749074337, 47.44731281508593]],
    [ "Poste électrique de Tendrecourt",[4.841020067754072, 49.73476150255391]],
    [ "Enedis",[4.987523236030006, 48.82243603253642]],
    [ "Enedis",[-2.600898966600479, 48.43030549894762]],
    [ "Enedis",[0.5550351570650329, 47.38204403223474]],
    [ "Enedis",[2.8365349469027996, 48.15040876212204]],
    [ "Enedis",[1.1996393045563547, 48.935111417266185]],
    [ "Bourg",[0.6309056353022, 47.90201691645637]],
    [ "réséda",[6.084614038759692, 48.97623034108527]],
    [ "réséda",[6.086032179669031, 48.99017603073287]],
    [ "réséda",[6.085000306306307, 48.987605261261265]],
    [ "réséda",[6.086730720510096, 48.97952696315976]],
    [ "Enedis",[5.281145498909594, 48.72410699880234]],
    [ "Enedis",[5.285227038461538, 48.7171892948718]],
    [ "Enedis",[5.289931124846649, 48.726302716677495]],
    [ "Enedis",[5.287199827309237, 48.726783987148586]],
    [ "Enedis",[5.275233747924083, 48.71851890510083]],
    [ "Enedis",[5.2871288917668, 48.724648891410766]],
    [ "Enedis",[5.280580675069463, 48.721543171179455]],
    [ "Enedis",[5.284514000000001, 48.71919638759689]],
    [ "Enedis",[5.281914422519508, 48.71814022185062]],
    [ "Enedis",[5.288566409700722, 48.72470404024768]],
    [ "Enedis",[1.2904889, 48.90484669999999]],
    [ "Enedis",[0.65471775, 48.176590250000004]],
    [ "Enedis",[0.6551497499999996, 48.17104275]],
    [ "Enedis",[0.6534644999999999, 48.17220950000001]],
    [ "Enedis",[0.6742915000000002, 48.1676655]],
    [ "Enedis",[4.73352025, 45.415986600000004]],
    [ "Enedis",[4.7362441555812484, 45.416805378726075]],
    [ "Enedis",[6.012916536156404, 45.316240624191764]],
    [ "ARCELORMITTAL",[4.965914323945704, 48.80689500895861]],
    [ "vougeot",[4.9638513, 47.1771104]],
    [ "Villezier Village",[2.1709265886398135, 48.67374842099097]],
    [ "Les Glycines",[-4.00477582887618, 48.0998251547697]],
    [ "Les Bruyères",[-3.9987546635008115, 48.09894483112521]],
    [ "Rosbriand",[-3.995011431532819, 48.097559203819266]],
    [ "IME",[-3.9978570236606257, 48.096746040381234]],
    [ "Enedis",[-4.003780210275611, 48.08752487880168]],
    [ "Station Épuration",[-3.9890008870922835, 48.09753169480213]],
    [ "ZA Lumunoch",[-4.02861725, 48.104775149999995]],
    [ "Pargamou",[-4.0103407574694145, 48.10429010465539]],
    [ "Enedis",[-4.0323874325235485, 48.10472700261624]],
    [ "Église",[-3.9997298592011936, 48.10264314846335]],
    [ "Rue Pierre Briand",[-3.996437700000001, 48.101712500000005]],
    [ "RTE",[-1.8855065437641223, 48.47605282736571]],
    [ "Dame Jeanette",[6.2808908179377285, 49.11214604882151]],
    [ "RTE",[-0.896229009902167, 47.69104142421643]],
    [ "Enedis",[0.025057406599029143, 47.890480459588524]],
    [ "Enedis",[0.02891071953177245, 47.88804260861788]],
    [ "Enedis",[0.031059986643684853, 47.89452566763162]],
    [ "Enedis",[0.022834830687563843, 47.88712995404944]],
    [ "Enedis",[0.03271475, 47.897079950000006]],
    [ "Enedis",[0.010121907326007932, 47.88455068498168]],
    [ "Enedis",[0.01847750206318056, 47.888599231718565]],
    [ "Enedis",[0.0437873896768273, 47.89279939709727]],
    [ "Enedis",[0.035473484901479606, 47.89122447687649]],
    [ "Enedis",[1.5226295578757956, 48.64704946233934]],
    [ "Enedis",[1.511190659479249, 48.6350399385331]],
    [ "Enedis",[1.5228013105655482, 48.65243947807654]],
    [ "Enedis",[1.5210512855774077, 48.64029472501261]],
    [ "Enedis",[1.5175685631499911, 48.63775348111702]],
    [ "Enedis",[0.527118, 49.58528450000001]],
    [ "Enedis",[1.7868453827537452, 48.9090164856468]],
    [ "Enedis",[1.856209320221445, 46.8872307004662]],
    [ "Enedis",[1.8580827243456108, 46.883398942784346]],
    [ "Enedis",[1.8592821868544602, 46.886127692018775]],
    [ "RTE",[1.061726557419005, 43.20361379769942]],
    [ "Etablis- Piscicole",[6.944472418780428, 45.24332966773058]],
    [ "Fond d'Ambin",[6.8507261593616775, 45.20526050526887]],
    [ "Fruitiere",[6.9089496731085065, 45.250863542498436]],
    [ "Le Floz",[6.908907953885999, 45.25318027107379]],
    [ "Le col",[6.90527775, 45.25788480000001]],
    [ "Les alpages",[6.8986724499999985, 45.26674009999999]],
    [ "Enedis",[6.917084100000002, 45.252398150000005]],
    [ "Enedis",[3.155303079889807, 48.31312662258953]],
    [ "Enedis",[3.1210840000000006, 48.3143545]],
    [ "Enedis",[3.080521682818494, 48.335842978800734]],
    [ "Enedis",[3.0837447699530527, 48.31551908450705]],
    [ "Enedis",[3.0913080196078444, 48.325745333333344]],
    [ "Enedis",[3.0780253212778788, 48.32563752863171]],
    [ "Enedis",[3.0869940799999998, 48.318447680000006]],
    [ "Enedis",[3.0801654127389373, 48.32155128050538]],
    [ "Enedis",[-1.0295198702836432, 46.85428779550796]],
    [ "Enedis",[0.45171984165172613, 49.55310642903609]],
    [ "La Porte Verte",[6.24086578629139, 48.71125252312209]],
    [ "Enedis",[3.377830499999999, 48.172029]],
    [ "Enedis",[0.9620274456436197, 49.01718453608769]],
    [ "Enedis",[0.37596487948717955, 49.575386690109895]],
    [ "Enedis",[0.16318884364791536, 47.21399508493409]],
    [ "Enedis",[6.176905820921986, 48.7105729893617]],
    [ "Enedis",[1.75438461195542, 47.86077682472137]],
    [ "Enedis",[1.7979349647713225, 47.880503873094355]],
    [ "Enedis",[1.803463824858757, 47.877303491525424]],
    [ "Enedis",[1.7896353125649016, 47.8737903172378]],
    [ "Enedis",[1.8000333960199006, 47.875083710447775]],
    [ "Enedis",[1.7882382259414218, 47.868442048814515]],
    [ "Enedis",[1.7999146785638414, 47.88222452305077]],
    [ "Enedis",[1.803805760948396, 47.88016401743375]],
    [ "Enedis",[1.7915247250703006, 47.874763943759454]],
    [ "Enedis",[1.6287682, 47.8920337]],
    [ "Enedis",[1.5513296732827397, 48.03671544210937]],
    [ "Enedis",[3.1862558466476463, 48.26846491631004]],
    [ "Enedis",[3.2017942790643277, 48.2908626051462]],
    [ "Enedis",[3.2112223325679294, 48.285240993494064]],
    [ "Enedis",[3.1975900947368423, 48.29242915438595]],
    [ "Enedis",[3.1975, 48.282093]],
    [ "Enedis",[3.203320232798712, 48.283108390526884]],
    [ "Enedis",[3.201093093333333, 48.28649733333333]],
    [ "Enedis",[3.1977124277372266, 48.29282215133819]],
    [ "Enedis",[3.2043289212398367, 48.28640189939025]],
    [ "Enedis",[3.1976110704293297, 48.29293353642065]],
    [ "Enedis",[3.200224987068965, 48.28166622078543]],
    [ "Enedis",[3.206591809381549, 48.28428630486455]],
    [ "Enedis",[0.2636320772357716, 49.524156766666664]],
    [ "Enedis",[5.91176, 48.83029150000001]],
    [ "Enedis",[3.1764988810156485, 48.30211983141423]],
    [ "Enedis",[3.1744744021143854, 48.29963447920417]],
    [ "Enedis",[5.313540028985507, 48.69446133333334]],
    [ "Enedis",[5.29214783396417, 48.70518950643453]],
    [ "Enedis",[5.299669500000001, 48.706292999999995]],
    [ "Enedis",[5.296405764609054, 48.70240367572017]],
    [ "Enedis",[5.2904263340350886, 48.708855006315794]],
    [ "Enedis",[5.303048157657659, 48.70115668018018]],
    [ "Enedis",[5.310011999999999, 48.69638849999999]],
    [ "Enedis",[5.299402977724263, 48.702555534617694]],
    [ "EDF",[6.374796220881341, 46.046601101544]],
    [ "Enedis",[2.9628674999999998, 47.413512000000004]],
    [ "Enedis",[2.9545002068965522, 47.39900556321839]],
    [ "Enedis",[2.960811579055442, 47.41148227583847]],
    [ "Enedis",[2.948173981651376, 47.40662400917431]],
    [ "Enedis",[4.994305204020903, 46.834392677563606]],
    [ "Fin Margan",[6.397103577870607, 46.13005829702925]],
    [ "Enedis",[6.39981635, 46.166498]],
    [ "Enedis",[6.397597950000001, 46.140904400000004]],
    [ "SNCF Réseau",[2.822067344300954, 48.384863713601916]],
    [ "Enedis",[-2.658113630343464, 48.1338221761799]],
    [ "Enedis",[6.36181251757272, 46.10074470803013]],
    [ "Enedis",[5.005147590703723, 46.7516045537437]],
    [ "Enedis",[2.403647, 51.016067500000005]],
    [ "Enedis",[5.3706013884441255, 43.426478525698194]],
    [ "Pouzauges",[-0.8362036515583671, 46.77612001579557]],
    [ "Enedis",[6.184530604142243, 48.6976189179367]],
    [ "312637171",[-2.56141592509673, 48.16234549365202]],
    [ "Enedis",[4.709605599999998, 45.56652259999999]],
    [ "Enedis",[4.6693198018018025, 45.41692571171172]],
    [ "312672965",[-2.611788698202346, 48.16389128244251]],
    [ "Baixas Static Inverter Station",[2.8016101304828096, 42.730870323070555]],
    [ "réséda",[6.060396242545173, 48.992671420019946]],
    [ "réséda",[6.058365629409827, 48.99605767276625]],
    [ "Enedis",[3.6263889999999996, 48.342619500000005]],
    [ "Enedis",[4.063012054991543, 45.43218388028599]],
    [ "RTE",[0.9884318697432997, 43.18637703069228]],
    [ "Enedis",[1.3057113033375924, 43.60483806720374]],
    [ "Enedis",[1.3073255, 43.6015558]],
    [ "Enedis",[1.3291967321331148, 43.62468568194216]],
    [ "Enedis",[4.2667732720685105, 47.58473332938076]],
    [ "Enedis",[4.212451149999999, 47.58360545000001]],
    [ "Lambarene",[1.9793770754303024, 49.089730703274334]],
    [ "Enedis",[4.393132073815676, 45.64163088931955]],
    [ "Enedis",[1.6604145000000008, 50.064329]],
    [ "Enedis",[1.683588064171124, 50.03528112477719]],
    [ "Enedis",[5.7750522, 49.52513700000001]],
    [ "Enedis",[4.38308985, 45.64567125000001]],
    [ "Enedis",[-0.6159500217576115, 44.61536527520251]],
    [ "Enedis",[5.002646550202624, 49.11130489955408]],
    [ "Enedis",[6.774329279975802, 46.20981440193588]],
    [ "Girbet 1",[1.5744646325448974, 43.23988837507665]],
    [ "RTE",[0.014483746149435942, 47.87362155464165]],
    [ "Cité de Parc ar Roz",[-4.006511925177425, 48.10538409202696]],
    [ "Park Ollivier",[-4.004244017585447, 48.10354907461067]],
    [ "5 Chemins",[-3.4784735805426585, 47.80069371352416]],
    [ "Kergant",[-3.5782954789916177, 47.776347052680535]],
    [ "Kerglanchard",[-3.5621263499999998, 47.87652855000001]],
    [ "Kergohel 2",[-3.4759969, 47.73276745]],
    [ "Kergoualec",[-3.727700382113822, 47.81481849718573]],
    [ "Moulin Boulai",[-3.5797658821254528, 47.87875534747308]],
    [ "Pont Saint-Roch",[-3.62996245, 47.81067135]],
    [ "Rue Lulli",[-3.563211531175301, 47.874447951696716]],
    [ "Villeneuve",[-3.502251402021998, 47.754606464694035]],
    [ "Zabrenn Mellac",[-3.5636576483850244, 47.88988508281643]],
    [ "Enedis",[4.125681999999999, 47.491865000000004]],
    [ "Enedis",[0.24995586646824502, 48.4534784330498]],
    [ "Enedis",[0.1750159, 48.435138099999996]],
    [ "Enedis",[0.25212374299234835, 48.447224922381835]],
    [ "Enedis",[3.8918955000000004, 47.555561000000004]],
    [ "Enedis",[3.8990925798344622, 47.553302654853276]],
    [ "Enedis",[2.578365758241759, 47.20098184981686]],
    [ "Enedis",[2.568745170395869, 47.19964117154333]],
    [ "Enedis",[2.567121161664432, 47.194360063659644]],
    [ "Enedis",[2.5777964905660378, 47.19917911713837]],
    [ "Enedis",[2.5789661671974526, 47.20406251963907]],
    [ "Enedis",[2.573536244604316, 47.19771442605916]],
    [ "Enedis",[2.5814030382428936, 47.20343167080103]],
    [ "Enedis",[2.56245332514081, 47.195328554872845]],
    [ "Enedis",[4.207825498214128, 47.483981009628174]],
    [ "Enedis",[6.197945615682077, 48.6884997980902]],
    [ "Enedis",[0.4024202036819425, 49.141942810027416]],
    [ "ESSeyssel",[6.00544235, 45.9908832]],
    [ "ESSeyssel",[5.993258829311887, 46.009637509383374]],
    [ "ESSeyssel",[5.959498315615247, 45.9938592850206]],
    [ "Enedis",[3.3277817537167924, 47.904706100880176]],
    [ "Enedis",[3.3656173027322143, 47.92163079944181]],
    [ "Enedis",[3.3551015735196272, 47.91264017697938]],
    [ "Messioual Moulin",[-4.511245134504909, 48.419267947147134]],
    [ "Messioual Beuzit",[-4.510831564916882, 48.42079286687863]],
    [ "ESSeyssel",[5.937613119431207, 45.90026122548888]],
    [ "ESSeyssel",[5.935677144088326, 45.964609341178786]],
    [ "ESSeyssel",[5.9792702498229335, 45.96475524962148]],
    [ "ESSeyssel",[5.936691499449195, 45.900342296828526]],
    [ "ESSeyssel",[5.917633049401823, 45.963557177853474]],
    [ "Enedis",[1.8296835000000002, 50.188860500000004]],
    [ "Enedis",[1.8187937757889017, 50.14317656206919]],
    [ "Enedis",[1.8127240171821302, 50.154870353951885]],
    [ "Enedis",[1.818284409160581, 50.15100920439996]],
    [ "Enedis",[1.7745534214792293, 50.21750562715299]],
    [ "Enedis",[1.7832904999999997, 50.21299599999999]],
    [ "Enedis",[1.7608067835068384, 50.213009552765875]],
    [ "Enedis",[6.213858449999999, 48.68393105]],
    [ "Enedis",[3.0713378723667906, 48.454772366790586]],
    [ "Enedis",[3.0603548919687285, 48.453444232054025]],
    [ "Enedis",[3.062901274443506, 48.45378885347986]],
    [ "Enedis",[3.3402324341491605, 47.818736694841014]],
    [ "RTE",[0.3641890570472652, 42.899871078785594]],
    [ "Enedis",[1.718950860297536, 50.19048606322641]],
    [ "Enedis",[2.7197456784214946, 47.796632701091525]],
    [ "Enedis",[2.9877271063101136, 48.207636332837204]],
    [ "Enedis",[3.0367483798882673, 49.7720166368715]],
    [ "Enedis",[1.7937938270594653, 48.05168334860884]],
    [ "Enedis",[1.7974811861301931, 48.0521815515211]],
    [ "Enedis",[0.17522796633841387, 49.19216338573661]],
    [ "Enedis",[0.16352174885796678, 49.17824562998553]],
    [ "SNCF Réseau",[6.423226797406174, 45.662108493237064]],
    [ "Pachaudiere",[6.425038192762837, 45.660065534623875]],
    [ "ZAC Vernay P1",[6.235911504857622, 45.773812299826716]],
    [ "Les Montets",[6.269725164487909, 45.75577476878648]],
    [ "Enedis",[4.352178445923667, 45.64823046350833]],
    [ "réséda",[6.098954000000001, 49.077791999999995]],
    [ "réséda",[6.08323405, 49.062935599999996]],
    [ "réséda",[6.100995935064935, 49.07942916017316]],
    [ "réséda",[6.082526633010653, 49.06668159628918]],
    [ "réséda",[6.0866125, 49.06934259999999]],
    [ "réséda",[6.082217413152541, 49.07008654663654]],
    [ "réséda",[6.09619425, 49.072845449999996]],
    [ "réséda",[6.083127370988058, 49.06411348298264]],
    [ "réséda",[6.099356349999998, 49.07411435]],
    [ "réséda",[6.095691238909067, 49.07649231616747]],
    [ "réséda",[6.0770469, 49.0625606]],
    [ "réséda",[6.079859417768427, 49.06483383359551]],
    [ "réséda",[6.078375508596226, 49.05932732248372]],
    [ "Bourg",[0.15344153305380875, 47.82047032753319]],
    [ "Grillons",[0.14560877604790426, 47.819334022355285]],
    [ "Centre de secours",[0.14194116666666667, 47.81873300000001]],
    [ "Enedis",[2.0008885000000003, 48.3041125]],
    [ "Enedis",[1.998843300469483, 48.3177453599374]],
    [ "Enedis",[2.0112557592765827, 48.31408338104146]],
    [ "Enedis",[2.0096051569173636, 48.308569184772516]],
    [ "Enedis",[2.006689316069057, 48.315197086321376]],
    [ "Enedis",[2.0010115783664455, 48.31564382339956]],
    [ "Enedis",[2.0013345, 48.305767]],
    [ "Enedis",[2.0092900137221275, 48.29413851057748]],
    [ "Enedis",[2.0078245, 48.320373]],
    [ "Enedis",[2.003779533389767, 48.31706083944696]],
    [ "Enedis",[1.9990982889233155, 48.315504092434814]],
    [ "Enedis",[1.994988054022989, 48.31560534367816]],
    [ "Enedis",[1.9925875584568338, 48.31783021618443]],
    [ "Enedis",[4.7017157930607185, 45.01072709128459]],
    [ "Enedis",[4.699717248587571, 45.00896111299435]],
    [ "Enedis",[0.7037983289193307, 49.58583822404871]],
    [ "Enedis",[0.7087149999999999, 49.586887100000006]],
    [ "réséda",[6.099417549801114, 49.07985648114559]],
    [ "Saint-Romain Bourg",[4.331962760911259, 45.2661258248593]],
    [ "Enedis",[1.9811783, 49.091128850000004]],
    [ "Enedis",[0.6955103485341347, 47.37540532361412]],
    [ "Enedis",[0.70053, 47.34074055]],
    [ "Enedis",[0.7009887366118399, 47.392630652259534]],
    [ "315458887",[4.846804005155655, 46.57350896086773]],
    [ "Enedis",[-2.6364330975609764, 48.488343203252036]],
    [ "Enedis",[-2.635993000000001, 48.4848555]],
    [ "Villa Ruby",[6.3212003648298545, 46.23710166462881]],
    [ "Enedis",[3.5576443892587664, 47.92014674656014]],
    [ "Enedis",[3.2248281124730602, 47.950738871913835]],
    [ "Enedis",[3.263233249527426, 47.84677895030988]],
    [ "Enedis",[3.3104056162361624, 47.82259430012299]],
    [ "Enedis",[3.299215518623481, 47.81454275047233]],
    [ "Enedis",[3.3033107096540744, 47.820258743957154]],
    [ "Enedis",[3.325213915508704, 47.8166943635202]],
    [ "Enedis",[3.603189763276836, 47.739417918644065]],
    [ "Enedis",[3.5918745000000007, 47.7450065]],
    [ "Enedis",[3.5972134999999996, 47.74056]],
    [ "Enedis",[3.4066701625069338, 48.08607300776484]],
    [ "Enedis",[3.2346993727768973, 48.01873822891354]],
    [ "Enedis",[2.4723841000000006, 50.982799]],
    [ "Enedis",[6.457056273905167, 46.236887207932]],
    [ "Enedis",[6.453084710118689, 46.2332464049487]],
    [ "Enedis",[6.455270863013698, 46.23074710776257]],
    [ "Enedis",[6.45835288888889, 46.238029177777776]],
    [ "RTE",[0.4100413419599166, 42.78589588551537]],
    [ "Enedis",[2.359022000093573, 49.00621129962102]],
    [ "Enedis",[6.268833999999999, 48.6555135]],
    [ "Enedis",[3.5849977518518523, 48.160939259259266]],
    [ "Enedis",[3.529331740888005, 48.24307595228628]],
    [ "Enedis",[3.4970755, 48.270331000000006]],
    [ "Enedis",[3.3588447795275593, 48.21718018635171]],
    [ "Enedis",[3.3641689192124917, 48.23261731500338]],
    [ "Enedis",[3.3522297257383973, 48.21527320675105]],
    [ "Enedis",[3.0777300197869097, 48.1005832891933]],
    [ "Enedis",[3.055022554268149, 48.14249183266335]],
    [ "Enedis",[0.6871256101806318, 47.413670466123214]],
    [ "Enedis",[2.2075148140928014, 46.84006865072596]],
    [ "Enedis",[3.4001308421694083, 48.28763282754418]],
    [ "Enedis",[3.3366391749018494, 48.29368164139819]],
    [ "Enedis",[3.385416203001108, 48.29476853747636]],
    [ "Enedis",[3.4024538043859653, 48.29414611052631]],
    [ "Enedis",[3.2779095635576536, 48.36561075639512]],
    [ "Enedis",[3.1656683265798358, 48.339499565846594]],
    [ "Enedis",[3.168446173913043, 48.3364919468599]],
    [ "Enedis",[3.163134696796095, 48.3365566815192]],
    [ "Enedis",[3.0464848285595685, 48.241105571606475]],
    [ "Enedis",[3.0505196431106816, 48.24010698612456]],
    [ "Enedis",[3.1174022816479403, 48.23120596629214]],
    [ "Enedis",[3.2186145, 48.22410850000001]],
    [ "Enedis",[3.2261654999999982, 48.220178499999996]],
    [ "Enedis",[5.22024277703827, 46.785779930671104]],
    [ "Enedis",[3.2681506460661853, 48.22815778917378]],
    [ "Enedis",[3.266345110553289, 48.226964252065684]],
    [ "Enedis",[3.2603341802023516, 48.23570304922068]],
    [ "Enedis",[0.8166245000000009, 49.122744499999996]],
    [ "Enedis",[3.1025994573839664, 48.17845895274262]],
    [ "Enedis",[3.0903599929078003, 48.17995783687944]],
    [ "Enedis",[3.087567, 48.1684677]],
    [ "Enedis",[3.1044163654658665, 48.18268327951967]],
    [ "Enedis",[-2.4736314999999993, 48.4731325]],
    [ "Enedis",[3.160903953586498, 48.125666165963416]],
    [ "Enedis",[0.7848619499999989, 49.712554600000004]],
    [ "Enedis",[3.89184291961326, 47.951466859760586]],
    [ "Enedis",[2.667551954300312, 47.990103780360386]],
    [ "Enedis",[2.6686477588915136, 47.9877996229659]],
    [ "Enedis",[1.4208231357993548, 43.54757808634343]],
    [ "Enedis",[3.62509070621469, 47.70434205649718]],
    [ "Enedis",[3.63474383712696, 47.70304568791098]],
    [ "Enedis",[3.6307398356713425, 47.7050524742819]],
    [ "Enedis",[3.6360973377219223, 47.70065246159983]],
    [ "Enedis",[0.822643117948718, 49.06427887948718]],
    [ "Enedis",[2.6695140154849804, 48.03605087608541]],
    [ "Enedis",[0.8151743333333332, 49.070243]],
    [ "Enedis",[0.8234256466932979, 49.069202243231246]],
    [ "Enedis",[0.641859561759017, 49.74785286009213]],
    [ "Enedis",[6.411810449275363, 46.22745489855074]],
    [ "Enedis",[0.7140551635316701, 49.571334224696095]],
    [ "Enedis",[6.668075860753459, 46.18856056032428]],
    [ "Enedis",[6.656636484752894, 46.18497779284963]],
    [ "Enedis",[6.676309482269506, 46.19377090780143]],
    [ "Poste électrique de Taden",[-2.0574929648070204, 48.490513765180296]],
    [ "RTE",[0.4095580035044469, 42.74405078884031]],
    [ "Enedis",[6.179933398848092, 48.466952302375816]],
    [ "Enedis",[6.695073145547074, 46.19561532213741]],
    [ "Enedis",[6.74386042331696, 46.21152647617258]],
    [ "Enedis",[6.694930500000001, 46.20237629999999]],
    [ "Enedis",[6.70151784398977, 46.19145301364023]],
    [ "Enedis",[6.689049455138663, 46.199669447525835]],
    [ "Enedis",[6.7008035, 46.190360999999996]],
    [ "Enedis",[1.3476261831664822, 43.59893219836856]],
    [ "Enedis",[1.3488554999999998, 43.5975665]],
    [ "Recueilleres",[6.516681513219726, 46.27819532287633]],
    [ "Enedis",[6.526751354352031, 46.28856673516012]],
    [ "Enedis",[6.5290388684684695, 46.290616850450455]],
    [ "Fruitiere",[6.522841335540839, 46.28307102759382]],
    [ "Enedis",[6.520970686711152, 46.28462424489915]],
    [ "Poste électrique de Fougères",[-1.1810138453959502, 48.36853865243705]],
    [ "RTE",[-1.8195370616777071, 48.12012325602935]],
    [ "Enedis",[0.7207498999999999, 47.3906904]],
    [ "Enedis",[0.9243863913320587, 49.17584384767367]],
    [ "RTE",[-2.7922549145008007, 47.87527717836861]],
    [ "Poste électrique d’Annay",[3.9632317122094514, 47.733049464800295]],
    [ "SNCF Réseau",[4.582252925870688, 47.515135944722594]],
    [ "Enedis",[6.306772826033546, 46.290929024120004]],
    [ "Enedis",[6.31357355, 46.28114215]],
    [ "Enedis",[6.313736183883813, 46.27237352377606]],
    [ "Enedis",[6.317831379706697, 46.284643606024574]],
    [ "Enedis",[6.3157875, 46.282854]],
    [ "Enedis",[6.499231158325422, 46.321941463369946]],
    [ "Enedis",[6.491780757378473, 46.32062895225694]],
    [ "Enedis",[6.491407, 46.313045]],
    [ "Enedis",[6.486218380457379, 46.31583868260568]],
    [ "Les Crêts",[6.486557941223352, 46.313982140266994]],
    [ "Enedis",[6.5011233344861665, 46.32212310230567]],
    [ "Enedis",[5.1625697957537495, 46.95445530871341]],
    [ "Enedis",[0.7043087931491077, 47.346996573517565]],
    [ "Enedis",[0.7031610814432604, 47.34669675939996]],
    [ "Enedis",[0.7043578260459143, 47.34663094000166]],
    [ "Enedis",[0.9515549999999999, 49.221878521739136]],
    [ "Croix",[6.4452396, 46.28881339999999]],
    [ "Cervens",[6.440680704540826, 46.285709648544085]],
    [ "Pessinges",[6.44989, 46.2896]],
    [ "Brettenay",[6.4536496468797555, 46.28983245053273]],
    [ "Taillou",[6.4415205, 46.2800665]],
    [ "Enedis",[6.441580075268818, 46.28295833333333]],
    [ "Reyret",[6.433891631939524, 46.28289217741555]],
    [ "Chataigniers",[6.437122323232323, 46.28305377777777]],
    [ "Groupe Scolaire",[6.4380916559571615, 46.284168053547525]],
    [ "Brénalin",[6.440269477041038, 46.28741051699575]],
    [ "Enedis",[3.375762453038674, 48.09469007366482]],
    [ "Enedis",[3.380337191955547, 48.0988585286923]],
    [ "Enedis",[2.499703435878875, 46.02407184274541]],
    [ "Enedis",[6.177815692166463, 48.65430529330886]],
    [ "Enedis",[3.8207286468129573, 47.90074437513063]],
    [ "Enedis",[3.846009924738081, 47.92298263822963]],
    [ "Enedis",[0.21252569629629633, 47.83090698518519]],
    [ "Enedis",[4.11484505, 47.97593215]],
    [ "GRPH",[6.618031216503788, 46.30011302156288]],
    [ "Enedis",[0.9564364025157235, 49.292439402515726]],
    [ "Enedis",[0.9598856701434159, 49.29337708604954]],
    [ "ZAC Periaz 1",[6.0967679560065315, 45.88088581484055]],
    [ "ESSeyssel",[5.824598, 46.021119]],
    [ "Balmont",[6.0598946498947415, 45.83143331663214]],
    [ "Grand Espagny Est 2",[6.08353128366666, 45.93372449965772]],
    [ "317264967",[6.061877565648601, 45.86403674704089]],
    [ "Bao",[6.0915279, 45.8782084]],
    [ "ESSeyssel",[6.070436476640592, 45.86347519113429]],
    [ "Sebastien",[6.102989834683788, 45.88563099754463]],
    [ "Enedis",[5.613751500000001, 46.230944]],
    [ "Enedis",[5.608772208775653, 46.22547159872611]],
    [ "Enedis",[5.625551525603277, 46.23026093543166]],
    [ "Enedis",[5.62235174099931, 46.22989121382346]],
    [ "Enedis",[5.620467858990673, 46.23049881882107]],
    [ "Enedis",[5.6239919339778774, 46.230701408341154]],
    [ "Enedis",[5.6220849, 46.224459800000005]],
    [ "Enedis",[5.62621875, 46.22607550000001]],
    [ "Enedis",[5.615157100000001, 46.2284653]],
    [ "Enedis",[5.6196955, 46.22796050000001]],
    [ "Enedis",[5.6238777933923405, 46.22704790639372]],
    [ "Enedis",[-0.06118785, 48.16805785]],
    [ "Enedis",[5.625538000000001, 46.257232]],
    [ "RTE",[-2.2168714980981234, 47.6511511989635]],
    [ "Enedis",[3.957176582340574, 47.725049663162814]],
    [ "Enedis",[3.094020330820748, 48.425273799071554]],
    [ "Enedis",[3.0914036999999994, 48.41784915]],
    [ "Poste électrique de Saint-Michel-sur-Meurthe",[6.8997221609979436, 48.32127590477276]],
    [ "Enedis",[1.7794033013493253, 50.06859891204398]],
    [ "Enedis",[1.7499837369673377, 50.07232457300454]],
    [ "Recharinges",[4.158830075463718, 45.06439315602733]],
    [ "Enedis",[1.3386723744297944, 43.60851629969394]],
    [ "Enedis",[1.35175354529361, 43.599310515795835]],
    [ "Enedis",[6.294045827804106, 48.55175651658768]],
    [ "Enedis",[6.294105999999999, 48.555727499999996]],
    [ "Enedis",[-0.12366299999999998, 47.821492500000005]],
    [ "Enedis",[0.6065249341889701, 47.441328401561876]],
    [ "Poste électrique de Plancoët",[-2.2324727765118935, 48.52651759631637]],
    [ "Enedis",[2.902359165338235, 48.264966890604576]],
    [ "Enedis",[5.1741435000000005, 48.788152000000004]],
    [ "Enedis",[6.305434385100865, 46.28393811862987]],
    [ "Gérard Dupré",[0.487987629094515, 47.82310524022238]],
    [ "Enedis",[0.4655211000000001, 47.8319301]],
    [ "Enedis",[-3.4585744940476193, 48.39984241369048]],
    [ "Enedis",[-3.4928946272213612, 48.4112937544277]],
    [ "Enedis",[0.12676324254215368, 47.8724429213143]],
    [ "Enedis",[0.14185754346289742, 47.8802023163722]],
    [ "Enedis",[0.12642073034162063, 47.87626271012736]],
    [ "Enedis",[0.1314631086956519, 47.87789132427537]],
    [ "Enedis",[0.1382793035714291, 47.88014365942728]],
    [ "Enedis",[0.13692411414141514, 47.87732077272728]],
    [ "Enedis",[0.13471282601505005, 47.879197206483084]],
    [ "Enedis",[0.1270392666666665, 47.87770386666667]],
    [ "Enedis",[2.5044597, 48.64364145]],
    [ "Enedis",[2.6349390500000003, 48.53369940000001]],
    [ "Poste électrique d’Élancourt",[1.95083246468303, 48.77442352955323]],
    [ "Enedis",[-3.4679457414248023, 48.431010452653176]],
    [ "Enedis",[-3.49875465, 48.426515849999994]],
    [ "Enedis",[-3.473581257820927, 48.448096355987055]],
    [ "Enedis",[-3.448961964102564, 48.44704316581197]],
    [ "Enedis",[-3.5234971759699856, 48.439302745214796]],
    [ "Enedis",[3.0738705, 47.6446595]],
    [ "Enedis",[3.074965307116105, 47.64433021348314]],
    [ "Enedis",[3.0751448390804597, 47.64036851724138]],
    [ "Enedis",[3.0662109999999996, 47.634277499999996]],
    [ "Enedis",[3.069414368021143, 47.64312389791874]],
    [ "Enedis",[3.064944371475954, 47.641132820895514]],
    [ "Enedis",[3.0623335215869316, 47.64480766783353]],
    [ "Enedis",[3.068197040627886, 47.64076067590028]],
    [ "Enedis",[3.07834901140114, 47.638634959495946]],
    [ "Enedis",[3.0647897291666664, 47.63948651388888]],
    [ "Enedis",[-2.7618373612430065, 47.51894221710653]],
    [ "EDF",[2.2125921113779383, 43.95397051834835]],
    [ "Enedis",[6.1539765394059405, 48.757626197623765]],
    [ "Enedis",[6.634212000000001, 49.237945999999994]],
    [ "Enedis",[5.856504733893669, 46.047596604848124]],
    [ "Enedis",[0.15045516035248152, 47.47425517508536]],
    [ "Enedis",[6.800296362367769, 49.10599331667792]],
    [ "Enedis",[6.840033255747127, 49.13227094827587]],
    [ "Enedis",[6.840836492424242, 49.138569607620326]],
    [ "Enedis",[3.1654976730408704, 48.44112316460443]],
    [ "Enedis",[-0.07625773741496582, 47.812337174149654]],
    [ "Enedis",[-0.08267710843941588, 47.813003323903814]],
    [ "Enedis",[-0.084349800807537, 47.81578402916106]],
    [ "Enedis",[5.163535002236297, 48.77109118698783]],
    [ "Poste électrique Henri-Paul",[4.503745403580134, 46.74703277706736]],
    [ "Enedis",[5.160022658483432, 48.772482884215684]],
    [ "ESSeyssel",[6.032626027210885, 45.97094865956336]],
    [ "Enedis",[-3.5681870000000004, 48.3860125]],
    [ "Enedis",[-3.5212512174343726, 48.32938415304606]],
    [ "Enedis",[4.1893685000000005, 46.869986768518515]],
    [ "Enedis",[4.1841056648726225, 46.866529840330095]],
    [ "Enedis",[5.160179925551056, 48.77071305838956]],
    [ "Enedis",[1.6904583427119975, 49.90535102657289]],
    [ "Enedis",[-3.4801805, 48.2290575]],
    [ "Enedis",[2.9990744121644575, 47.68254895446823]],
    [ "Enedis",[3.003013028947368, 47.68322939473685]],
    [ "Enedis",[-3.5011795000000006, 48.265211]],
    [ "Enedis",[-3.5064799467617354, 48.26938045953654]],
    [ "Enedis",[-3.51654825, 48.310514500000004]],
    [ "Enedis",[3.0339835679713176, 47.66013181555622]],
    [ "Enedis",[-3.4646502500000005, 48.31712100000001]],
    [ "Enedis",[-3.5013577500000004, 48.30577850000001]],
    [ "Enedis",[-3.4887365000000004, 48.32502550000001]],
    [ "Enedis",[-3.4677986746202305, 48.292355907858884]],
    [ "Enedis",[1.922390800284829, 43.92187509563804]],
    [ "Enedis",[-3.437948000000001, 48.324768]],
    [ "Enedis",[-2.828405835742222, 47.65543877819633]],
    [ "Enedis",[1.8068452463768123, 50.06506404347827]],
    [ "Enedis",[1.6485436903304775, 50.0606503618931]],
    [ "Enedis",[1.6511515, 50.05763050000001]],
    [ "Enedis",[1.642520760158217, 50.058028826321475]],
    [ "Enedis",[1.6492269941262856, 50.062912497797356]],
    [ "Enedis",[1.651128, 50.06415950000001]],
    [ "Enedis",[1.6479409999999999, 50.065841999999996]],
    [ "Enedis",[1.6416740452538632, 50.063242269315666]],
    [ "Enedis",[1.635614, 50.0669195]],
    [ "Enedis",[1.5899810000000005, 50.070999]],
    [ "Enedis",[1.5873226666666664, 50.057258000000004]],
    [ "Enedis",[1.5918229999999993, 50.0618845]],
    [ "Enedis",[1.5858106576940334, 50.05886532929565]],
    [ "Enedis",[1.598624203370134, 50.06161175188844]],
    [ "Enedis",[1.603804798679868, 50.05595414474304]],
    [ "Enedis",[1.6122520740740742, 50.06017206878307]],
    [ "Enedis",[1.6049885000000002, 50.063145000000006]],
    [ "Enedis",[4.144883868904248, 49.21125414797219]],
    [ "Aéro-Club",[4.149607602591592, 49.20859412482648]],
    [ "Enedis",[3.0251995, 45.73695999999999]],
    [ "Enedis",[0.6254388469800174, 47.43996688172836]],
    [ "Couronne",[5.157363632528543, 48.77270563845188]],
    [ "Enedis",[4.578216508128353, 45.30320882749266]],
    [ "Enedis",[2.5069619999999997, 46.778789499999995]],
    [ "Électricité de France",[9.04669566884255, 41.70377161694005]],
    [ "Électricité de France",[6.7208249278352685, 45.21341283954937]],
    [ "Enedis",[5.16446775625657, 48.77293905573081]],
    [ "Enedis",[5.164569900000001, 48.77381829999999]],
    [ "Enedis",[5.166486473857852, 48.77129741389199]],
    [ "Enedis",[0.9550729999999998, 48.803607500000005]],
    [ "Enedis",[0.59907055, 47.4059882]],
    [ "Enedis",[3.6754231616028186, 50.040927329810664]],
    [ "Enedis",[-0.2274785999999983, 47.43834525]],
    [ "Enedis",[0.5970118500000006, 47.407911899999995]],
    [ "Enedis",[0.5843926001345034, 47.40213322171613]],
    [ "Enedis",[0.5969150500000001, 47.3871957]],
    [ "Enedis",[0.59491195, 47.38979135]],
    [ "Enedis",[0.5830711427432977, 47.411032024593226]],
    [ "Enedis",[5.159244196671186, 48.77825783294055]],
    [ "Enedis",[5.153210400000001, 48.777734249999995]],
    [ "Enedis",[2.3821835000000005, 49.67126100000001]],
    [ "Enedis",[5.15467095, 48.78159579999999]],
    [ "Enedis",[5.1556392446269355, 48.778419617466575]],
    [ "Enedis",[5.170677850000001, 48.769154099999994]],
    [ "Enedis",[2.1392618, 48.43411785]],
    [ "Enedis",[2.377223951426341, 48.647704728896244]],
    [ "Enedis",[5.168852650000001, 48.76765300000001]],
    [ "Enedis",[2.3892751095159213, 50.0153153051721]],
    [ "Enedis",[5.166049993035579, 48.76784386621245]],
    [ "Enedis",[5.173085152360845, 48.76518254277977]],
    [ "Enedis",[5.176300734277127, 48.766291532839865]],
    [ "BG Voeux",[2.2987221595093787, 48.799170086204704]],
    [ "Enedis",[5.148717700000001, 48.78224970000001]],
    [ "Enedis",[5.14686025, 48.78248355]],
    [ "Enedis",[5.150779279754064, 48.779079115149]],
    [ "Enedis",[4.3773508410329, 45.65185211756641]],
    [ "Enedis",[4.3726414999999985, 45.65244145]],
    [ "Enedis",[5.157496674381422, 48.770135194571104]],
    [ "Enedis",[1.7170793259623265, 49.89181471089271]],
    [ "Enedis",[1.718326, 49.89208500000001]],
    [ "Enedis",[1.7214899185750638, 49.88759642239186]],
    [ "CNR",[5.834881958366351, 45.97078878269125]],
    [ "Enedis",[5.1611743500000005, 48.768834999999996]],
    [ "Enedis",[1.8539756737588655, 49.5255290212766]],
    [ "Enedis",[1.8789700000000007, 49.512642500000005]],
    [ "Enedis",[-0.38788, 47.8276878]],
    [ "Enedis",[6.0202690729166655, 46.23370375347222]],
    [ "Enedis",[6.020222637177194, 46.233641659422155]],
    [ "Enedis",[4.132683094878254, 49.194969783655196]],
    [ "Enedis",[4.134120038468937, 49.1975633869975]],
    [ "Enedis",[4.126354, 49.1973845]],
    [ "Enedis",[4.135801910602344, 49.195022440446984]],
    [ "Enedis",[4.1261565, 49.19797399999999]],
    [ "Enedis",[5.1484293999999995, 48.765775749999996]],
    [ "Enedis",[1.3173292521773556, 43.56573934718923]],
    [ "Enedis",[1.2956810000000003, 43.557591]],
    [ "Enedis",[1.249036, 43.5572005]],
    [ "SICAE-Oise",[2.508609918600954, 49.54365577164812]],
    [ "SICAE-Oise",[2.5175945000000004, 49.551962499999995]],
    [ "Enedis",[-0.40223220000000004, 47.88988875]],
    [ "Enedis",[1.2446219180387854, 48.76366380131723]],
    [ "Enedis",[1.240460098200102, 48.76232711632429]],
    [ "Enedis",[1.2340521482504603, 48.76305080432781]],
    [ "Enedis",[1.245285126066974, 48.76793393937404]],
    [ "Enedis",[1.2502737724003887, 48.76095603420797]],
    [ "Enedis",[1.2505861574803148, 48.753003299212594]],
    [ "Enedis",[1.2308518255343732, 48.75760452859619]],
    [ "Enedis",[1.238570522267209, 48.76346602159244]],
    [ "Enedis",[1.2254639756786954, 48.75685356399131]],
    [ "Enedis",[1.2450404999999998, 48.754387]],
    [ "Enedis",[1.2328907798742124, 48.760883471698115]],
    [ "Enedis",[1.23197038522674, 48.765173766479656]],
    [ "Enedis",[-0.3516241, 47.90106024999999]],
    [ "Enedis",[-0.3741815000000015, 47.895855100000006]],
    [ "Enedis",[-0.36686168531084135, 47.91205615281107]],
    [ "Enedis",[-0.29272354440905257, 47.91442852313017]],
    [ "Enedis",[2.4605167491219273, 50.03517282622513]],
    [ "Enedis",[1.5129613343105641, 50.08513251148245]],
    [ "Enedis",[1.5167166998313657, 50.085768277684096]],
    [ "Enedis",[5.146785550000001, 48.76834089999999]],
    [ "Enedis",[5.1592596455353155, 48.76690148302678]],
    [ "Enedis",[1.448545, 49.731155666666666]],
    [ "Enedis",[1.4357080000000015, 49.72943500000001]],
    [ "Enedis",[1.4392004000000012, 49.7308575]],
    [ "Enedis",[1.442913172476117, 49.72768333901367]],
    [ "Enedis",[1.4477356376165718, 49.73561682017]],
    [ "Enedis",[1.4374495234375002, 49.73957225402463]],
    [ "Enedis",[1.4356687128358208, 49.729987385870636]],
    [ "Enedis",[1.437873, 49.724207500000006]],
    [ "Enedis",[1.4332630000000008, 49.73031149999999]],
    [ "Enedis",[1.6336807494524748, 50.0772886999562]],
    [ "Enedis",[1.6214933150877195, 50.07580806807018]],
    [ "Enedis",[0.3857153942652331, 48.47192413620071]],
    [ "Enedis",[0.37022193548387106, 48.48186886021505]],
    [ "Enedis",[2.8023533, 48.496140299999986]],
    [ "Enedis",[2.802515884701397, 48.49708914519295]],
    [ "Enedis",[6.273959600000001, 46.32777265000001]],
    [ "Enedis",[1.3767329489528795, 47.273579299956374]],
    [ "Enedis",[1.4012854176245193, 47.27594804244032]],
    [ "Enedis",[2.6839085931045834, 50.30547669217477]],
    [ "Enedis",[0.3415198496581684, 48.56093055175292]],
    [ "Enedis",[0.35633914878671774, 48.514363019412514]],
    [ "Enedis",[2.4740970229885058, 50.09418333333334]],
    [ "Enedis",[2.5196349999999996, 50.008354]],
    [ "Enedis",[1.5398343218390804, 50.06563288505746]],
    [ "Enedis",[1.5432244230526977, 50.06453638428162]],
    [ "Enedis",[1.5375528848241882, 50.065279488014696]],
    [ "Jardin",[5.163739508375468, 48.769416329046685]],
    [ "Enedis",[2.444947690726818, 49.986505283709285]],
    [ "POLE 45",[1.841455035507122, 47.94294771997414]],
    [ "Résistance",[5.156608670153417, 48.76557714476987]],
    [ "Enedis",[5.155831212729157, 48.763995718169035]],
    [ "Mandallaz",[6.100572987005815, 45.987500157492406]],
    [ "ESSeyssel",[6.050678100000001, 46.00753755000001]],
    [ "Enedis",[5.152118000000001, 48.76393320000001]],
    [ "Enedis",[2.51385617882789, 46.41694248320593]],
    [ "Enedis",[2.508127500000001, 46.418755000000004]],
    [ "Enedis",[2.5120227344632777, 46.415181954802264]],
    [ "Enedis",[2.495674453145057, 46.428582697261454]],
    [ "Enedis",[2.4245896579108117, 49.66823728629607]],
    [ "Le Petit Juré",[5.1513142, 48.75992645]],
    [ "La Chênaie",[5.153796100000001, 48.75939015000001]],
    [ "Enedis",[-1.9143102888675911, 48.180897214742046]],
    [ "Enedis",[5.148962316908688, 48.76345415175979]],
    [ "Enedis",[5.1523497, 48.75715485]],
    [ "Enedis",[5.152570324130824, 48.75584469477052]],
    [ "Enedis",[5.14920775, 48.756181399999996]],
    [ "Enedis",[6.278074750000001, 46.33892929999999]],
    [ "ESSeyssel",[5.893821749999999, 45.96661124999999]],
    [ "ESSeyssel",[5.904714749999999, 45.966762399999986]],
    [ "Enedis",[5.157697744476126, 48.760140932309035]],
    [ "Enedis",[2.93279197400612, 47.92049635926946]],
    [ "Enedis",[5.161974534717775, 48.76100840301423]],
    [ "Enedis",[5.1562425184081855, 48.79103029665356]],
    [ "Enedis",[5.1517593, 48.793018700000005]],
    [ "Enedis",[5.1710768296017005, 48.77234891467516]],
    [ "Enedis",[-1.6036925, 48.081107]],
    [ "Enedis",[4.1229542911540715, 45.5729883298184]],
    [ "Enedis",[4.081594249140893, 45.71023573986254]],
    [ "Enedis",[5.174508749999999, 48.76950365]],
    [ "Enedis",[2.364421730133333, 47.77393544959999]],
    [ "Stade",[-0.20623647210657825, 47.84600963197336]],
    [ "Le Calvaire",[-0.19846692270531396, 47.84001915217391]],
    [ "Enedis",[-0.23866899999999996, 47.845937499999984]],
    [ "Enedis",[-0.15808418468720864, 47.827142598506065]],
    [ "Enedis",[0.6216752133333342, 49.39237045714285]],
    [ "Enedis",[2.3817210999999996, 47.796941249999996]],
    [ "Enedis",[5.175407240096733, 48.77233407080586]],
    [ "Enedis",[3.4154781672248378, 47.97604411746438]],
    [ "Enedis",[5.168824563270969, 48.775582487235305]],
    [ "Enedis",[3.8903214999999993, 49.445702499999996]],
    [ "Enedis",[5.178242670204726, 48.77468068202217]],
    [ "Enedis",[-0.12190216091954054, 47.787376977011505]],
    [ "Enedis",[1.9671180000000001, 46.577948]],
    [ "Poste électrique d'Erquy",[-2.452139785375322, 48.60023557084489]],
    [ "Poste électrique de Bourseul",[-2.2842055208201613, 48.44489951591654]],
    [ "Poste électrique de Croix Gibat",[-2.701406097221981, 48.4808635049163]],
    [ "Chez Contat",[6.186794450000002, 45.9426427]],
    [ "Enedis",[-0.2418161270643754, 47.816927662285146]],
    [ "Enedis",[-0.272344488146244, 47.81455283119109]],
    [ "Poste électrique de Lamballe",[-2.5298633026192467, 48.480586731908346]],
    [ "Enedis",[-0.29880878620689655, 47.799402027586204]],
    [ "RTE",[-2.4176303465080826, 48.185105278429084]],
    [ "Enedis",[-0.967819662151994, 48.16075994130925]],
    [ "Enedis",[-0.9683920625000001, 48.159515902777784]],
    [ "Poste électrique de Kerboquet",[-2.85563901254537, 48.06793499249676]],
    [ "Enedis",[5.179033870900768, 48.771872557990164]],
    [ "Enedis",[-0.25381969551281996, 47.79228294871795]],
    [ "Enedis",[-0.23067284034879876, 47.78261191677616]],
    [ "Enedis",[-0.32394023130358685, 47.764707287684864]],
    [ "Enedis",[1.7239994043624163, 49.91515285067114]],
    [ "Enedis",[5.1816878338786525, 48.7691407003832]],
    [ "Poste Electrique de Bar le Duc",[5.179536169825141, 48.77020134179521]],
    [ "Enedis",[-0.2380253243847877, 47.731483536912755]],
    [ "Enedis",[-0.2357604955932196, 47.73386816858757]],
    [ "Enedis",[5.162609711908539, 48.776128181102905]],
    [ "Enedis",[0.23625336797613067, 48.167706337808724]],
    [ "Champ de foire",[0.2356337415824919, 48.173511063131315]],
    [ "Enedis",[5.184274799999999, 48.7723345]],
    [ "Enedis",[1.021628923076923, 48.47184887912088]],
    [ "Enedis",[1.0096121903789426, 48.47159148127979]],
    [ "Enedis",[1.0204105421618563, 48.466822372382566]],
    [ "Enedis",[1.0140997997775314, 48.47318051946608]],
    [ "Enedis",[1.0237527003633962, 48.47749983085563]],
    [ "Enedis",[1.0239019472902757, 48.46930800638456]],
    [ "Enedis",[1.020327866799205, 48.475396756129896]],
    [ "Enedis",[1.0071854637681161, 48.469450307971016]],
    [ "Enedis",[1.021625583837569, 48.465162055936545]],
    [ "Enedis",[1.0246087299420996, 48.47239432692308]],
    [ "Enedis",[6.740593765688888, 45.556400241066655]],
    [ "Enedis",[5.900767553831689, 46.0458277174424]],
    [ "Enedis",[5.898557800000001, 46.03457]],
    [ "Enedis",[-0.1554545, 47.745973]],
    [ "Enedis",[-0.154814, 47.76612699999999]],
    [ "Enedis",[-0.15983929485257228, 47.765694475262364]],
    [ "Enedis",[-0.1554165, 47.7679455]],
    [ "Enedis",[-0.13998455162893453, 47.763881826247015]],
    [ "Enedis",[-0.20961100000000002, 47.781848]],
    [ "Enedis",[-0.21587682691007387, 47.77845048478702]],
    [ "Enedis",[-0.21466669698484042, 47.772703290354826]],
    [ "Enedis",[5.892562155315315, 46.062522952072065]],
    [ "Enedis",[5.8940481081761, 46.06389916226416]],
    [ "Enedis",[5.896720016717325, 46.06419615775076]],
    [ "Maison Blanche",[0.2992062160784073, 42.792029066107666]],
    [ "Mont Gosse",[6.234214222841965, 46.18602054661354]],
    [ "Enedis",[1.5681974413997624, 50.069257028588375]],
    [ "Enedis",[1.5128438257411425, 50.05950999204628]],
    [ "Enedis",[1.5261409681528662, 50.102939163481956]],
    [ "Enedis",[0.06217537927388053, 47.89606280237339]],
    [ "Enedis",[0.06495259360730575, 47.89419907305936]],
    [ "Enedis",[0.06879133230393941, 47.89363448251388]],
    [ "Enedis",[0.06962169454745196, 47.89742241436616]],
    [ "Enedis",[6.568242245274545, 46.21436723214942]],
    [ "Enedis",[0.09413264962930441, 47.90288468338916]],
    [ "Enedis",[2.312907687947555, 46.84039878396368]],
    [ "Enedis",[4.171349568797479, 45.67111885422216]],
    [ "Enedis",[0.811392036408624, 49.38954074054437]],
    [ "Enedis",[3.8112225, 49.4895215]],
    [ "Enedis",[3.4421686326733565, 49.45659808543531]],
    [ "Bourdigale",[0.1249025, 47.901213999999996]],
    [ "Enedis",[0.11063666003316767, 47.89259382421228]],
    [ "Enedis",[0.08030998924731238, 47.92888328039702]],
    [ "Enedis",[0.07261377298407806, 47.92895552953261]],
    [ "Enedis",[0.06573468832173211, 47.92935064191803]],
    [ "Enedis",[0.11013613114754063, 47.94198318032787]],
    [ "Enedis",[0.11043600000000002, 47.9385765]],
    [ "Enedis",[0.112169, 47.9434735]],
    [ "Enedis",[4.994388818820655, 47.25494866004684]],
    [ "Enedis",[5.056035999999999, 47.22463425]],
    [ "Enedis",[5.083039500000001, 47.215118]],
    [ "324077131",[5.0971035708154515, 47.22214330185981]],
    [ "Enedis",[0.7735107973568275, 49.39648815859032]],
    [ "Enedis",[0.7695049225641024, 49.39740624974358]],
    [ "Enedis",[4.160077905781584, 44.16674321770164]],
    [ "Enedis",[3.916456270628632, 49.48313040739567]],
    [ "Enedis",[3.9209002150055374, 49.484040443078634]],
    [ "Enedis",[-0.9180749999999993, 45.59940664999999]],
    [ "Enedis",[0.9144098964549856, 49.3717477049222]],
    [ "Enedis",[4.03754398960343, 45.77357538842443]],
    [ "Enedis",[0.240995500000001, 48.170322000000006]],
    [ "Enedis",[0.24528391050119333, 48.17285054256165]],
    [ "Enedis",[0.12541422567814944, 48.23051092536197]],
    [ "Enedis",[0.12821272337113745, 48.2293735383589]],
    [ "Enedis",[0.13112670774091623, 48.22855164362822]],
    [ "Enedis",[0.68615925, 47.439761]],
    [ "Enedis",[0.808027908730159, 49.30056880357142]],
    [ "RTE",[0.23014524596598773, 42.7883316844359]],
    [ "Enedis",[0.40120861793478235, 47.760638958876825]],
    [ "Enedis",[3.457452310701584, 49.510005841254454]],
    [ "Enedis",[3.445765839771102, 49.50087867811159]],
    [ "Enedis",[3.4422827247706436, 49.5084157584098]],
    [ "Enedis",[3.445132995869191, 49.50326047779689]],
    [ "Enedis",[3.4478785026312417, 49.50613822282121]],
    [ "Enedis",[3.4500035000000002, 49.504384]],
    [ "324829116",[3.446918500000001, 49.504498]],
    [ "Enedis",[2.3570495, 46.8116546]],
    [ "Enedis",[2.3268634221014493, 46.82482816177537]],
    [ "Enedis",[2.6204944407430277, 47.990209606488065]],
    [ "Enedis",[1.2519325924275064, 48.296803791327605]],
    [ "Enedis",[1.2507879741413794, 48.307207792970914]],
    [ "Enedis",[1.2446643982023393, 48.29840316379727]],
    [ "Enedis",[1.2475596323198392, 48.29702751306835]],
    [ "Enedis",[1.2358780112180061, 48.29682971279665]],
    [ "Enedis",[0.22285565694076032, 48.239317515473026]],
    [ "Enedis",[1.6900749551692595, 47.13243914059164]],
    [ "Enedis",[2.4283320357337357, 46.18918407125542]],
    [ "Enedis",[1.0556470377733602, 48.759711830351236]],
    [ "Enedis",[1.0567358366972472, 48.75635783588176]],
    [ "Enedis",[1.0567013637912674, 48.75635568178913]],
    [ "Enedis",[5.16687775, 48.783193100000005]],
    [ "Enedis",[2.066281105794647, 48.56597162299202]],
    [ "Enedis",[1.5413342785550745, 50.09090584414484]],
    [ "Enedis",[1.5395826695035457, 50.096552485106386]],
    [ "Enedis",[1.5587901790228893, 50.09479442261702]],
    [ "Enedis",[1.563299907356948, 50.09441582107176]],
    [ "Enedis",[1.5547891627564328, 50.08941050731045]],
    [ "325179967",[1.5555313440170941, 50.09226010897436]],
    [ "Enedis",[1.5486984573322997, 50.07653211047965]],
    [ "Enedis",[1.5455332937629545, 50.07502314339551]],
    [ "Enedis",[1.540498785504145, 50.07821555558526]],
    [ "Enedis",[1.5452543493013973, 50.07958961676647]],
    [ "Enedis",[1.5375923262023217, 50.07527063366501]],
    [ "Enedis",[1.5378202183112142, 50.07882876668591]],
    [ "Enedis",[1.542103168055556, 50.08042559444445]],
    [ "Enedis",[1.5398719802602063, 50.07545008882907]],
    [ "Enedis",[1.538477179695982, 50.08078505483171]],
    [ "Enedis",[1.544429573031352, 50.08289156034391]],
    [ "Enedis",[1.5472136576209852, 50.07966551394542]],
    [ "Enedis",[1.551313414977245, 50.090208626948005]],
    [ "Enedis",[1.552466966399749, 50.08725199623176]],
    [ "Enedis",[1.540973804281346, 50.09014059510704]],
    [ "Enedis",[1.5465598482124407, 50.09051707604877]],
    [ "Enedis",[1.5475000927384075, 50.08516565704286]],
    [ "Enedis",[1.5447013080459768, 50.0910330275862]],
    [ "Enedis",[1.5459409537299336, 50.08750085174693]],
    [ "Enedis",[1.5440676751376539, 50.085235977128335]],
    [ "Enedis",[1.550081, 50.086488499999994]],
    [ "Enedis",[0.25719745, 48.24868315000001]],
    [ "RTE",[0.3171173076387153, 42.814776888119795]],
    [ "Enedis",[0.2545975912534443, 48.2025037827135]],
    [ "325256942",[-1.4640318807264314, 47.62659841954638]],
    [ "Enedis",[0.2963415, 48.20419400000001]],
    [ "Enedis",[1.8965397908357833, 48.80680054191781]],
    [ "Enedis",[1.9019464630490204, 48.8054318992643]],
    [ "Enedis",[1.9102582834543247, 48.8001526763011]],
    [ "Enedis",[1.9032795173773, 48.8019024803187]],
    [ "Enedis",[-1.0057349500000001, 45.625769250000005]],
    [ "Enedis",[-0.9911312500000002, 45.63472345]],
    [ "Enedis",[1.6739280748002898, 47.04083915831518]],
    [ "Enedis",[1.673329296165424, 47.035346876325086]],
    [ "Enedis",[-1.0471829, 45.64012975]],
    [ "Enedis",[0.2808353651659843, 48.28917559390632]],
    [ "Enedis",[0.274737, 48.29054200000001]],
    [ "Enedis",[0.30676433197278913, 48.3237500122449]],
    [ "Enedis",[2.623865874990777, 47.99396763415209]],
    [ "Enedis",[5.163095267917006, 48.783403897152006]],
    [ "Enedis",[-1.02583285, 45.6375198]],
    [ "Enedis",[5.1624051569196165, 48.78632701752669]],
    [ "Enedis",[5.165997528462331, 48.78718242192265]],
    [ "Enedis",[1.2776900000000002, 43.675962]],
    [ "Enedis",[5.318622249999999, 46.8424335]],
    [ "Enedis",[-1.0550765834806273, 45.639903200180804]],
    [ "Enedis",[5.161703200000002, 48.78178765]],
    [ "Enedis",[4.177613229693246, 47.37514014547075]],
    [ "RTE",[2.8023692353235417, 50.41179141438299]],
    [ "Enedis",[4.945082293849523, 46.811893879400756]],
    [ "Enedis",[4.952697195574996, 46.803075604297206]],
    [ "Enedis",[4.937847199999999, 46.807482500000006]],
    [ "Enedis",[4.936877500000001, 46.81316300000001]],
    [ "Enedis",[4.947168548472598, 46.80893171634862]],
    [ "Enedis",[5.094349749999999, 46.74139975]],
    [ "Enedis",[4.7010243442565045, 46.61751553258185]],
    [ "Enedis",[4.169804726356902, 47.38674718067173]],
    [ "réséda",[6.0840322, 49.032257300000005]],
    [ "réséda",[6.057073700592355, 49.033526467959085]],
    [ "réséda",[6.061084331418541, 49.034242502792395]],
    [ "réséda",[6.06258370682993, 49.03146836736836]],
    [ "réséda",[6.065490768866251, 49.036794017454945]],
    [ "réséda",[6.072299466666666, 49.043599251480636]],
    [ "réséda",[6.059460345426174, 49.032898727520966]],
    [ "réséda",[6.06881135, 49.040625500000004]],
    [ "réséda",[6.082481371967896, 49.02901401054081]],
    [ "réséda",[6.069290000000001, 49.0374145]],
    [ "réséda",[6.061938340902474, 49.036841988937404]],
    [ "réséda",[6.063413850000002, 49.033185450000005]],
    [ "RTE",[2.4463224934374335, 49.252219447924865]],
    [ "SNCF Réseau",[2.4482404921759593, 49.242982972372644]],
    [ "Enedis",[2.8577949499999993, 42.9617809]],
    [ "Enedis",[2.032141318620357, 48.572862342981196]],
    [ "Enedis",[2.0391375893155255, 48.56525567946577]],
    [ "Enedis",[2.1494540396698616, 48.529261858093726]],
    [ "Enedis",[-0.27227137835072585, 48.02669572007366]],
    [ "Enedis",[-0.2713581666666662, 48.028997833333335]],
    [ "Enedis",[-0.26583850000000087, 48.0111135]],
    [ "Enedis",[3.1775015, 43.3778495]],
    [ "Enedis",[5.156454788265219, 48.784333126251404]],
    [ "Enedis",[-0.19866796695922467, 48.1590214319755]],
    [ "Bourg Crannes",[-0.0500923467461502, 47.980317781917535]],
    [ "Enedis",[-0.06871788258410601, 47.96448821639555]],
    [ "Enedis",[-0.0650771502415465, 47.96472257487923]],
    [ "Auvers-sous-Montfaucon",[-0.07116732536796538, 48.00631742233767]],
    [ "Enedis",[5.15212775, 48.7857652]],
    [ "Enedis",[2.0826639028350202, 48.60214379905083]],
    [ "Enedis",[2.111973215686275, 48.59224274509804]],
    [ "Enedis",[-0.31594088470948045, 48.29279224021407]],
    [ "Enedis",[1.5222448499999996, 45.78393404999999]],
    [ "Enedis",[2.2219496948590374, 47.260943302929796]],
    [ "Enedis",[1.3658791651267472, 43.52354050841032]],
    [ "Enedis",[0.8163379454926625, 48.95087191194968]],
    [ "Enedis",[4.6715880864936565, 45.46657682470146]],
    [ "Enedis",[2.148385236022647, 43.978107638075016]],
    [ "Enedis",[5.181074260115493, 48.764284783640946]],
    [ "Enedis",[5.183997248294527, 48.7634594669229]],
    [ "Les Grandes Terres 2",[5.187984881378442, 48.76132972862794]],
    [ "Enedis",[1.8254144999999995, 49.6003625]],
    [ "Enedis",[5.1919922, 48.75821474999999]],
    [ "Enedis",[5.1895316166407035, 48.759359723363914]],
    [ "Enedis",[5.186886259437391, 48.76978942702693]],
    [ "Enedis",[0.9283122470769904, 49.29800909750718]],
    [ "Enedis",[0.9424989990004995, 49.294696452273854]],
    [ "Enedis",[0.9173987094801224, 49.29876305504587]],
    [ "Enedis",[0.9084420104166667, 49.29797484375]],
    [ "Enedis",[0.9186001125769573, 49.29718566402815]],
    [ "Enedis",[0.9221188864716179, 49.29462958589647]],
    [ "Enedis",[-0.280878, 47.86629949999999]],
    [ "Enedis",[-0.28578149999999936, 47.864409]],
    [ "Enedis",[-0.3201279999999991, 47.84713799999999]],
    [ "réséda",[6.454567480690595, 49.07867167499621]],
    [ "Enedis",[5.184626551878814, 48.75986083145819]],
    [ "Veau Foron",[5.171037, 48.76389424999999]],
    [ "Enedis",[5.176016750000002, 48.75866325000001]],
    [ "Enedis",[5.179813249999999, 48.75613575]],
    [ "Enedis",[5.180454, 48.758120999999996]],
    [ "Poste électrique Alouettes",[2.495180574844273, 48.85665134431561]],
    [ "Poste électrique Avenir",[2.4172034665190103, 48.916197883080144]],
    [ "Enedis",[-0.038165931645570125, 47.87162768270043]],
    [ "Enedis",[2.9878448445807773, 50.42174053987731]],
    [ "Enedis",[-0.030679181542699555, 47.90132048938141]],
    [ "Le Jardin",[-0.0339157201645251, 47.8997587023542]],
    [ "Enedis",[1.4646001773136774, 50.08145896723997]],
    [ "Enedis",[1.4856028939283104, 50.08801095610827]],
    [ "Enedis",[1.5670906, 43.5455917]],
    [ "Enedis",[1.5713419, 43.54380530000001]],
    [ "Enedis",[5.195281656641605, 48.752237939849635]],
    [ "Enedis",[1.4700425347786812, 50.03829367208672]],
    [ "Enedis",[1.4729154999999998, 50.039572]],
    [ "Enedis",[1.4737294545454536, 50.03807515151515]],
    [ "Enedis",[1.474773909722222, 50.038496802083344]],
    [ "Enedis",[1.8322037264957263, 49.42530695726496]],
    [ "Enedis",[-0.15114351745852767, 44.55792083108683]],
    [ "Enedis",[-0.14941519438111686, 44.55673162270581]],
    [ "Enedis",[-3.7318046119188635, 48.32567051438461]],
    [ "Écoles",[-0.013825156929649992, 47.92746247074515]],
    [ "Enedis",[-0.013754390855592054, 47.927470596716326]],
    [ "Enedis",[3.9942470383104127, 49.19244674459724]],
    [ "Enedis",[2.892515361065483, 48.276875125401894]],
    [ "Enedis",[2.8567417004957476, 48.272770647250184]],
    [ "Enedis",[2.868805296658346, 48.26749007175906]],
    [ "Enedis",[0.7626510064102562, 49.217249854588395]],
    [ "Poste 63 kV de Villevaudé",[2.665057462114511, 48.90432216337793]],
    [ "Enedis",[-1.1024579888623625, 45.650004715984316]],
    [ "Enedis",[-1.0776611696272538, 45.653014307364444]],
    [ "Enedis",[1.4839030840952019, 43.64336446930685]],
    [ "Enedis",[1.4729264999999996, 43.6335085]],
    [ "Enedis",[1.4638615499999998, 43.6397765]],
    [ "Enedis",[5.157917203005778, 48.776827485236005]],
    [ "Enedis",[1.8397513677984676, 47.89606470101605]],
    [ "Enedis",[1.8501773305624267, 47.89276199650228]],
    [ "Enedis",[1.8534774748594929, 47.89727567665524]],
    [ "Enedis",[1.8514374570219017, 47.898478363198635]],
    [ "Enedis",[1.855374341801636, 47.89520297328945]],
    [ "Enedis",[1.8595051737566153, 47.89628380476656]],
    [ "Enedis",[1.853277292979721, 47.89618195760805]],
    [ "Enedis",[1.8549767552478522, 47.89948379356056]],
    [ "Bourg",[0.08002566666666698, 48.110463666666675]],
    [ "La Godinière",[0.1031728787878795, 48.102612296969696]],
    [ "Enedis",[1.7683467070324816, 49.453743984547465]],
    [ "Chez les Hudry",[6.41350032914739, 46.154183376955274]],
    [ "Enedis",[6.411905550000001, 46.14559175]],
    [ "Enedis",[6.40438473412004, 46.14798256267159]],
    [ "Enedis",[6.389296000000001, 46.140994500000005]],
    [ "Enedis",[6.3856809000000005, 46.14653255]],
    [ "Enedis",[6.404504318504496, 46.149166688752175]],
    [ "Enedis",[6.406856528836755, 46.14823583968719]],
    [ "Enedis",[6.40554866985341, 46.1453971637986]],
    [ "Enedis",[2.9850763805604545, 43.19026385361553]],
    [ "Enedis",[2.4759817658466745, 48.38359317219837]],
    [ "Enedis",[2.4740086764091855, 48.380501083507305]],
    [ "Enedis",[2.4738144047341866, 48.38277981761738]],
    [ "Enedis",[1.5150459539325847, 43.609597508614236]],
    [ "Enedis",[1.4976539999999994, 43.601352000000006]],
    [ "Enedis",[-0.2673356015037585, 47.91629667669173]],
    [ "Enedis",[0.9798972530120481, 49.253837746987955]],
    [ "Enedis",[1.877485874070094, 47.87604650536747]],
    [ "Enedis",[1.8806420000000008, 47.88791955]],
    [ "Enedis",[1.8664655904258682, 47.87811267900612]],
    [ "Enedis",[1.8813375994821433, 47.877575112774124]],
    [ "Enedis",[1.8696045111920183, 47.88840267906156]],
    [ "Enedis",[1.8660795878605245, 47.89157568247319]],
    [ "Enedis",[1.8805166161507905, 47.89138726694855]],
    [ "Enedis",[1.8712506837811718, 47.87985347112342]],
    [ "Enedis",[1.8728272783244426, 47.88319518421343]],
    [ "Enedis",[1.8852221820234867, 47.89035507434509]],
    [ "Enedis",[1.8672641922390674, 47.88909487217324]],
    [ "Enedis",[1.8698104183857265, 47.8767782574123]],
    [ "Enedis",[1.8670081606877322, 47.87669820824704]],
    [ "Enedis",[1.8772674005337289, 47.878699352163]],
    [ "Enedis",[1.869972621796444, 47.878458046873405]],
    [ "Enedis",[1.8755796496376902, 47.89135003837871]],
    [ "Enedis",[1.8806111327226003, 47.887908348701735]],
    [ "Enedis",[3.261295827004219, 47.6206093248945]],
    [ "Enedis",[1.471413802501028, 44.411691165933085]],
    [ "Enedis",[1.470298767610271, 44.46864112667017]],
    [ "Enedis",[3.1463690499999983, 43.43573145]],
    [ "Enedis",[6.019397550000001, 46.1148616]],
    [ "Enedis",[-0.09852981153449925, 47.765164618091305]],
    [ "Enedis",[-0.11943067724867722, 47.733931285714284]],
    [ "Enedis",[5.2287346999999995, 48.79941744999999]],
    [ "Enedis",[5.218623, 48.79469925]],
    [ "Enedis",[2.863814, 50.480981]],
    [ "Enedis",[2.858143656374063, 50.47399219438317]],
    [ "Enedis",[5.214466, 48.7928]],
    [ "Enedis",[2.059071522340246, 49.398175340920595]],
    [ "Enedis",[0.0776155784940733, 48.64082187991973]],
    [ "Enedis",[0.5123143599574014, 48.84089226517571]],
    [ "Enedis",[0.7046458293204922, 48.287848505082934]],
    [ "Enedis",[0.7490465061728395, 48.21896714979424]],
    [ "Enedis",[0.7070946087704219, 48.286976770707945]],
    [ "Enedis",[0.7523768542303776, 48.22770169317023]],
    [ "Enedis",[0.735562000000001, 48.2434635]],
    [ "Enedis",[0.7225084999999998, 48.281325499999994]],
    [ "Enedis",[0.28747792715231796, 49.144186770419424]],
    [ "Enedis",[-0.0556689999999991, 48.457060000000006]],
    [ "Enedis",[1.3652414236267874, 43.03593411662905]],
    [ "Enedis",[1.8589937406022838, 49.46269000394601]],
    [ "Enedis",[-0.17134249999999998, 47.91669525]],
    [ "Enedis",[-0.16155439433551186, 47.921260612200435]],
    [ "Enedis",[2.922633914666667, 43.054097816]],
    [ "Restany",[-1.6617121621890547, 48.10232378706468]],
    [ "Enedis",[0.6547880180180182, 48.59052278378379]],
    [ "Enedis",[0.6136274871605614, 48.57857089894379]],
    [ "Enedis",[2.94279232288699, 50.486969823361825]],
    [ "Enedis",[1.9673049108932463, 49.465538253159046]],
    [ "Enedis",[4.27377875, 43.7610935]],
    [ "Enedis",[4.267230168658698, 43.75371556175298]],
    [ "Enedis",[4.265942746900385, 43.75215171269774]],
    [ "Enedis",[4.098885663978494, 49.260101424731175]],
    [ "Enedis",[4.1047773467656405, 49.2611137506186]],
    [ "Enedis",[4.099852970213918, 49.26659161738424]],
    [ "Enedis",[4.107636219202898, 49.26360696286231]],
    [ "Enedis",[-0.16424129440118654, 47.98784702410085]],
    [ "Bourg",[-0.16799083448275864, 47.984077960919535]],
    [ "Enedis",[2.741875316239316, 50.3864699734093]],
    [ "Enedis",[2.4339957588075882, 46.9482718355917]],
    [ "Enedis",[2.4457619999999998, 46.99209750000001]],
    [ "Enedis",[2.402714069089278, 47.04589277523553]],
    [ "Enedis",[2.4581140000000006, 46.9944355]],
    [ "Enedis",[2.3971999999999998, 47.04107899999999]],
    [ "Enedis",[2.447601238086936, 47.00034299946274]],
    [ "Libération",[-0.15461925820185476, 47.99287346806912]],
    [ "Enedis",[1.412808811702065, 46.73912451564108]],
    [ "Enedis",[-0.14224252547307148, 47.99461382969432]],
    [ "Enedis",[-0.13799303292349716, 47.99359766816939]],
    [ "Enedis",[-0.15944744827586219, 47.99607118390804]],
    [ "Enedis",[1.565304362403101, 46.835617432170544]],
    [ "Enedis",[1.5704698175263445, 46.82588596006655]],
    [ "Enedis",[1.5632497004933037, 46.82397970754052]],
    [ "Enedis",[0.5128889801393294, 47.743666110704616]],
    [ "Enedis",[2.618981880189043, 47.99406546153269]],
    [ "Enedis",[0.4538360000000001, 47.77058699999999]],
    [ "Enedis",[2.790851655480984, 42.51172597986577]],
    [ "Enedis",[2.7930210000000004, 42.513493]],
    [ "Enedis",[2.7921395765978363, 42.512340299705016]],
    [ "Enedis",[2.791957497940918, 42.509980999401755]],
    [ "Enedis",[2.7887225831134566, 42.51279280826738]],
    [ "Enedis",[2.787378773510244, 42.51461745181564]],
    [ "Enedis",[0.013787541095125044, 49.119909901304794]],
    [ "Bouviere Repartition",[6.174609751457849, 45.92329391700543]],
    [ "Chez Falquet",[6.2296456, 45.9826369]],
    [ "Cret Martian",[6.184992716546537, 45.931276399773765]],
    [ "La Bouviere",[6.1745803499999985, 45.92330769999999]],
    [ "Le Vuaz",[6.24864765, 45.9902519]],
    [ "Enedis",[0.07436207951963639, 49.15463915287245]],
    [ "Enedis",[-0.37738835000000004, 43.96964095]],
    [ "Enedis",[3.4528751670165616, 49.43535450361558]],
    [ "Enedis",[3.4260740297951577, 49.447669830540036]],
    [ "Noyers",[5.009305171891892, 47.27140999423424]],
    [ "Route de Richecourt",[5.722428, 48.8880625]],
    [ "Enedis",[-1.5291408106060604, 47.17809492148759]],
    [ "Enedis",[1.3387565450106154, 43.64405454458598]],
    [ "Enedis",[1.3292412295238096, 43.652848572380954]],
    [ "Enedis",[1.3325342000000002, 43.6542611]],
    [ "Enedis",[1.3163805135446685, 43.646695838232475]],
    [ "Enedis",[1.338733776119403, 43.64399140945273]],
    [ "Enedis",[1.9376911826266858, 49.513251026777354]],
    [ "Enedis",[3.9154994907566225, 47.75029363350486]],
    [ "Enedis",[3.8368038160919546, 47.50901265517241]],
    [ "Enedis",[3.8591136443747827, 47.48763585214212]],
    [ "Viret",[6.410511399999999, 46.2823182]],
    [ "Guyot",[6.406547818226078, 46.279228848083825]],
    [ "Enedis",[6.321187977111097, 46.27653338953472]],
    [ "Enedis",[2.239825881802722, 50.292671419217676]],
    [ "Enedis",[2.6860953913043475, 43.04008027149758]],
    [ "Enedis",[2.66546549722578, 43.05019332138284]],
    [ "Enedis",[2.2368258192279136, 50.29133915775799]],
    [ "Enedis",[-1.0363558046084216, 45.6403568653317]],
    [ "Enedis",[2.3520090000000007, 47.00993150000001]],
    [ "Enedis",[2.3648709722222225, 47.014621461988305]],
    [ "Enedis",[2.3858868029375753, 47.05512923949408]],
    [ "Enedis",[2.358532480367586, 47.01114982372598]],
    [ "Enedis",[2.36833642966752, 47.01496226513215]],
    [ "Enedis",[2.3530291780885784, 47.01586833379953]],
    [ "Enedis",[2.353803258064516, 47.008635150537636]],
    [ "Enedis",[2.3599836772745815, 47.00871601223991]],
    [ "Enedis",[2.3826316995305166, 47.05127279342723]],
    [ "Enedis",[2.3841246568972254, 47.05627667330989]],
    [ "Enedis",[2.3611480099502486, 47.014075149253735]],
    [ "Enedis",[2.382217561931419, 47.05337745976207]],
    [ "Enedis",[2.3761300371095886, 47.03448261910981]],
    [ "Enedis",[-0.046416104252400295, 49.19025371604938]],
    [ "Enedis",[-0.05036305653111472, 49.20057931731362]],
    [ "Enedis",[6.208613017629776, 48.67135866144302]],
    [ "Enedis",[2.484089046413502, 47.02937183016877]],
    [ "Enedis",[2.4961414933333335, 47.02365558666668]],
    [ "Enedis",[2.851695156434273, 43.01614520568853]],
    [ "Enedis",[2.8173873174332766, 42.994244856520915]],
    [ "Enedis",[3.1246905000000003, 50.520835500000004]],
    [ "Enedis",[0.22222600139130286, 49.188566212521735]],
    [ "Enedis",[0.22272006232294603, 49.182353780925396]],
    [ "Enedis",[0.22255985972850656, 49.18571720060332]],
    [ "Enedis",[0.21026513824884757, 49.196118231950855]],
    [ "Enedis",[0.2081885, 49.197649000000006]],
    [ "Enedis",[0.20912849999999997, 49.19545949999999]],
    [ "Enedis",[0.21472934831826698, 49.21501407525042]],
    [ "Enedis",[4.6381169999999985, 49.943459]],
    [ "Enedis",[2.303420500000001, 50.822041000000006]],
    [ "Enedis",[4.631852486426999, 49.941873316458796]],
    [ "Enedis",[2.459365000000001, 50.8785155]],
    [ "Enedis",[4.629012049099836, 49.938395301691216]],
    [ "Enedis",[4.6271121441525, 49.93332852462649]],
    [ "Enedis",[4.630759231341493, 49.933093522438206]],
    [ "Enedis",[4.64161223255814, 49.935740457364325]],
    [ "Enedis",[4.640880452380952, 49.93692833333334]],
    [ "Enedis",[4.642444739738088, 49.93316636304611]],
    [ "Enedis",[4.641307723270443, 49.939127026954175]],
    [ "Enedis",[4.639035169193743, 49.939188526113114]],
    [ "Enedis",[4.635419985221675, 49.930192667077165]],
    [ "Enedis",[4.64199174594344, 49.92992437505795]],
    [ "Enedis",[4.637830740045079, 49.93090513398447]],
    [ "Enedis",[4.650785226086957, 49.92633709420291]],
    [ "Enedis",[4.650803574962962, 49.92821871066666]],
    [ "EDF",[6.444069163254945, 45.64351950759389]],
    [ "Enedis",[-0.46526775, 43.83957710000001]],
    [ "Enedis",[4.8612213585554604, 50.12623662453425]],
    [ "Enedis",[4.803674480738478, 50.10702467538886]],
    [ "Enedis",[4.792684759139785, 50.12218780860215]],
    [ "Enedis",[4.808410344431233, 50.100781468093544]],
    [ "Enedis",[1.9010711328512115, 50.94939275237712]],
    [ "Enedis",[1.8979709292347107, 50.94191323535062]],
    [ "Enedis",[1.8027685002865328, 48.440914343648515]],
    [ "Enedis",[1.8118033500000001, 48.44147990000001]],
    [ "Enedis",[1.8052271593116849, 48.4402875062448]],
    [ "Enedis",[1.79768834591195, 48.44352064150943]],
    [ "Enedis",[1.8116887844611527, 48.44314941541353]],
    [ "École",[0.07817166889733672, 48.02603137137137]],
    [ "Le Chêne Vert",[0.07671310000000113, 48.02355380000001]],
    [ "La Colomberie",[0.07413733470857867, 48.02283219083765]],
    [ "Enedis",[0.04654649999999954, 48.00157225]],
    [ "Cèdre",[2.3646325014758323, 48.74196061202944]],
    [ "Enedis",[0.12269631804465904, 48.064158566887954]],
    [ "Sylvia",[0.11829755123610419, 48.07009900668657]],
    [ "Enedis",[0.11620851565622925, 48.06472983366644]],
    [ "Enedis",[6.286419900110789, 48.49607585380141]],
    [ "Enedis",[2.2947974, 48.66041625]],
    [ "Enedis",[1.39268727538673, 50.06979666758337]],
    [ "Enedis",[1.3980263597894176, 50.065124879914706]],
    [ "Enedis",[1.4039724367283954, 50.06210739094651]],
    [ "Enedis",[1.4068557231603942, 50.06039601102067]],
    [ "Enedis",[0.14754550000000038, 49.2820135]],
    [ "Enedis",[0.27409409264957235, 49.245920123076914]],
    [ "Enedis",[6.185155800000002, 48.654992150000005]],
    [ "Enedis",[0.21316290237936458, 49.290229266844555]],
    [ "Bois Raymond",[0.19174049999999998, 47.897499]],
    [ "Bignon",[0.205427171882762, 47.897357567809244]],
    [ "Enedis",[0.20354102373581023, 47.899548320949435]],
    [ "Enedis",[0.19793094847794962, 47.89567505590689]],
    [ "Enedis",[1.4442862891105568, 50.099013063840395]],
    [ "Enedis",[1.4523324431709645, 50.099580317096475]],
    [ "Enedis",[1.4498456668859652, 50.09974900482457]],
    [ "Étoile",[0.10175194024651661, 48.01949704876742]],
    [ "Enedis",[0.1209135, 48.03141]],
    [ "Enedis",[0.11213170654144011, 48.0192124755314]],
    [ "Enedis",[0.11485306863055432, 48.02854820597183]],
    [ "RTE",[1.2947186332823886, 43.386014113007406]],
    [ "Enedis",[-0.023336, 48.126940499999996]],
    [ "Enedis",[-0.02054865268065235, 48.12318468764569]],
    [ "Gare",[-0.01460398662551435, 48.12562713271604]],
    [ "Enedis",[-0.01496945000000031, 48.11540684999999]],
    [ "Courbet",[1.2864871921032646, 43.62271024601367]],
    [ "Enedis",[1.2745912179487184, 43.611594301282054]],
    [ "Enedis",[1.23383935, 43.62049235]],
    [ "Enedis",[3.9756835287282137, 47.616487999354426]],
    [ "Enedis",[4.052334995739679, 47.56813530498017]],
    [ "Enedis",[4.055293109506619, 47.570744299638996]],
    [ "réséda",[6.059741356125357, 49.056660860398864]],
    [ "réséda",[6.054088999999999, 49.053349499999996]],
    [ "réséda",[6.058702157894737, 49.05862722807017]],
    [ "réséda",[6.061644029268293, 49.063564334959345]],
    [ "réséda",[6.05709914910465, 49.051345865023826]],
    [ "Enedis",[6.458956602481389, 48.16930065277089]],
    [ "Enedis",[0.027042100000000003, 48.15531094999999]],
    [ "Enedis",[1.4437233690082645, 50.07273287961432]],
    [ "Enedis",[1.4612069999999997, 50.063870499999986]],
    [ "Enedis",[0.09374372965235175, 48.322698587730066]],
    [ "Enedis",[0.09626677162552749, 48.32356237002446]],
    [ "Enedis",[1.4893327916524377, 50.03125156700817]],
    [ "Enedis",[0.12457200000000002, 49.29630350000001]],
    [ "Enedis",[0.12692575570272283, 49.29731308977189]],
    [ "Enedis",[0.1110926326860839, 49.297742980987046]],
    [ "Enedis",[1.5127389999999998, 50.01934150000001]],
    [ "Enedis",[1.5182195037866315, 50.013579833278456]],
    [ "Enedis",[2.407024281998864, 51.041820701646785]],
    [ "Enedis",[0.17085064818481868, 48.28716718745874]],
    [ "Enedis",[0.17759739547341685, 48.36594723463238]],
    [ "Enedis",[-1.5363119999999997, 46.824221249999994]],
    [ "Enedis",[1.3178428999999987, 43.68728685]],
    [ "Enedis",[1.3222762202243257, 43.685480376305634]],
    [ "Enedis",[0.01921464913647139, 49.26209640469579]],
    [ "Enedis",[2.3156085110776257, 48.80534594605186]],
    [ "Enedis",[6.346186132342246, 46.36501575478424]],
    [ "Enedis",[6.380897936476787, 46.31470431888984]],
    [ "Enedis",[0.08008187440982088, 49.28199775259679]],
    [ "Enedis",[1.5642715773582234, 45.87729808841748]],
    [ "Enedis",[-0.2599194029850749, 49.115362845771145]],
    [ "Enedis",[2.6199441744680856, 47.67921248510639]],
    [ "Enedis",[2.5986451702193385, 47.6779371720729]],
    [ "Enedis",[2.600384527675277, 47.67116116728167]],
    [ "Enedis",[2.588311213483146, 47.67914154681648]],
    [ "Enedis",[0.8014394802043423, 48.423629825031924]],
    [ "Enedis",[1.436334967694517, 43.67171765787334]],
    [ "Enedis",[1.4259380488625457, 43.665804367198334]],
    [ "Enedis",[1.4153152373889175, 43.67465655862629]],
    [ "Poste Source Saint-Jean",[3.2890743223389864, 49.862478123157935]],
    [ "Enedis",[0.0950341684981685, 48.30047958241759]],
    [ "Enedis",[1.8758409999999999, 48.202028500000004]],
    [ "Enedis",[1.8743603532338304, 48.2012812039801]],
    [ "Enedis",[1.8805190472813238, 48.20420767375886]],
    [ "Enedis",[1.8777089999999999, 48.2050915]],
    [ "Enedis",[1.8894789712858928, 48.19785732459426]],
    [ "Enedis",[1.880572346642151, 48.20358089422993]],
    [ "Enedis",[1.8887604999999998, 48.19628]],
    [ "Enedis",[1.8828171871945254, 48.20000267790812]],
    [ "Enedis",[1.8892126602357988, 48.203172938906754]],
    [ "Enedis",[1.8731933307378565, 48.20314355839822]],
    [ "Enedis",[1.8856925000000002, 48.199129]],
    [ "Enedis",[1.884634419455331, 48.200163456738004]],
    [ "Enedis",[2.442614425345958, 50.85637819397911]],
    [ "Enedis",[3.2274632337252513, 44.46692748751943]],
    [ "Enedis",[0.16201760206718316, 45.95914283893195]],
    [ "Enedis",[1.1767768820862619, 49.00407707004272]],
    [ "Enedis",[1.1779209, 49.00506099999999]],
    [ "Enedis",[1.9595649999999998, 49.557672000000004]],
    [ "Enedis",[1.9787187999999998, 49.5566049]],
    [ "Enedis",[3.246120878376023, 49.92032119411047]],
    [ "Enedis",[4.009394500000001, 49.395275000000005]],
    [ "Enedis",[3.1770954026402647, 49.60341077557755]],
    [ "Enedis",[1.3072516, 43.613160900000004]],
    [ "Enedis",[1.3403411990257474, 43.62476272025053]],
    [ "Enedis",[1.3055569284116328, 43.61540662639822]],
    [ "Enedis",[1.3589865, 43.596686000000005]],
    [ "ARISTIDE MAILLOL",[1.3532906846240191, 43.59615798316499]],
    [ "Enedis",[1.3457236637105352, 43.59846634716802]],
    [ "Mas de la Plage",[3.5558948177135004, 43.320509151297244]],
    [ "Auroux",[2.496660933400712, 48.85575246810508]],
    [ "Chambout",[6.124993694945478, 45.93519912071664]],
    [ "Fontaine Froide",[6.160981454439171, 45.9751551843419]],
    [ "La Pelle",[6.164362280752895, 45.98369543061677]],
    [ "Pringy Gare",[6.1219962500000005, 45.938643899999995]],
    [ "Scierie",[6.1810709593627715, 45.95475208246358]],
    [ "La Salle",[6.125369522897311, 45.935345677546444]],
    [ "Enedis",[6.61076975, 46.34944290000001]],
    [ "Enedis",[6.599916192411549, 46.35070891333275]],
    [ "Enedis",[7.04055135277178, 43.667725700503965]],
    [ "Enedis",[-0.5466979000000001, 43.86851285]],
    [ "Enedis",[7.038432711231119, 43.67325372370666]],
    [ "Enedis",[0.8106726728110599, 47.873865932411675]],
    [ "RTE",[3.0696335320436527, 50.216035754200774]],
    [ "Enedis",[7.0497844999999995, 43.6657385]],
    [ "Sous-station des Terres-Noires",[3.135491273653556, 50.35947740191777]],
    [ "Enedis",[2.430202501009159, 51.016259253997816]],
    [ "Enedis",[0.8574385, 47.76934850000001]],
    [ "Enedis",[0.8773834534686965, 47.762476116751266]],
    [ "Enedis",[0.8660056858054227, 47.753131071770326]],
    [ "Enedis",[0.868695614602035, 47.75747269180131]],
    [ "Enedis",[0.8515461567319712, 47.760042531332495]],
    [ "Enedis",[0.8562020257452577, 47.757494834010856]],
    [ "Enedis",[0.8592234539363484, 47.76278017420435]],
    [ "Enedis",[0.8747108293705019, 47.75654449357748]],
    [ "Enedis",[0.87249601453958, 47.76289417339795]],
    [ "Enedis",[0.869254, 47.75874449999999]],
    [ "Enedis",[0.8750344946619215, 47.75982233214709]],
    [ "Enedis",[0.8585893052913923, 47.766084034869536]],
    [ "Enedis",[0.8710095979505723, 47.747707672694396]],
    [ "Enedis",[0.8619468148995675, 47.75181665141113]],
    [ "Enedis",[0.8725385884034303, 47.750228138559955]],
    [ "Enedis",[0.8660153063800751, 47.750297829750934]],
    [ "Enedis",[0.8843114999999988, 47.760111]],
    [ "Enedis",[0.8593272897119343, 47.758900622496576]],
    [ "Enedis",[0.8707813333333337, 47.753328]],
    [ "Enedis",[1.370218188405797, 43.01761346376811]],
    [ "Enedis",[7.04774807197341, 43.66730223248047]],
    [ "Enedis",[7.049415499999999, 43.670111999999996]],
    [ "Poste électrique Charles Laffitte",[0.12775627133942927, 49.492484050104636]],
    [ "Enedis",[-1.6857415000000002, 48.133567]],
    [ "Poste électrique de Sainte-Croix-en-Plaine",[7.370308000147148, 48.01795419959497]],
    [ "SNCF Réseau",[7.50329115, 48.73879794999999]],
    [ "Camouyer",[7.0541175, 43.669675000000005]],
    [ "Enedis",[7.052548582905374, 43.67092599917214]],
    [ "Enedis",[6.957915999999999, 48.2789705]],
    [ "Enedis",[6.96002966923571, 48.2810238354528]],
    [ "Enedis",[-1.7806382153110052, 49.52724402551835]],
    [ "Enedis",[6.37531442821803, 47.81435923306901]],
    [ "Enedis",[6.379631832473659, 47.81516572445891]],
    [ "Poste électrique d'Ensisheim",[7.376453504923982, 47.866129569367054]],
    [ "Enedis",[2.3173699948559343, 48.80618415710689]],
    [ "Les allées d'Aussandra",[3.1950008763373985, 45.71486538946025]],
    [ "Enedis",[3.1523860608465606, 49.51691118121693]],
    [ "Enedis",[3.1562295, 49.515096500000006]],
    [ "Enedis",[3.1500299303797465, 49.51503374789029]],
    [ "Enedis",[6.873963150000002, 47.6048715]],
    [ "Enedis",[0.18839867049808431, 48.64762646743295]],
    [ "Enedis",[0.1991072296103891, 48.65489702597402]],
    [ "Enedis",[6.387048079331549, 47.81708615549278]],
    [ "Enedis",[6.383961665266048, 47.816985081142825]],
    [ "Enedis",[2.698965976744186, 46.35742066666668]],
    [ "RTE",[3.8471812254306457, 48.48443486163176]],
    [ "RTE",[2.78477210926178, 44.51917230039407]],
    [ "Enedis",[1.6364802878914522, 49.010955827986166]],
    [ "Enedis",[6.3789366794252285, 47.82146274626987]],
    [ "Poste électrique de Gabion",[0.22480669164194908, 49.48167703819758]],
    [ "Enedis",[6.388155154039665, 47.822841517618585]],
    [ "Poste électrique Marie-Louise",[7.272835270364815, 47.830838074877725]],
    [ "Poste électrique Théodore",[7.327957829530077, 47.83045334732844]],
    [ "Poste électrique de Saint-Amarin",[7.02431019833259, 47.87359566324237]],
    [ "Poste électrique de Thann",[7.11589447181242, 47.80471111003763]],
    [ "Enedis",[6.389968292523428, 47.819906629003015]],
    [ "Enedis",[1.251775026798457, 49.87254105337773]],
    [ "Enedis",[1.2169069990539767, 49.87976951075857]],
    [ "Enedis",[1.2214527083161897, 49.87834017447166]],
    [ "Enedis",[1.215048672887445, 49.881275003432165]],
    [ "Enedis",[1.2207528995812351, 49.88046019988339]],
    [ "Enedis",[1.2059769045307565, 49.88199322364399]],
    [ "Enedis",[1.2371802053321255, 49.87382140322795]],
    [ "Enedis",[2.624907916911046, 46.497665619745845]],
    [ "Enedis",[0.2461929385113264, 48.37666322977346]],
    [ "Enedis",[0.247329797752809, 48.373672947565545]],
    [ "Poste électrique Gay Lussac",[7.330460188012439, 47.74218533484488]],
    [ "Poste électrique Lavoisier",[7.322421973282067, 47.755582916770095]],
    [ "Enedis",[1.9538161296055485, 49.57262682314695]],
    [ "Enedis",[2.326959866057293, 47.06770054727353]],
    [ "Enedis",[2.3078862140849696, 47.05604838158714]],
    [ "Enedis",[2.319608750333057, 47.058247003832186]],
    [ "Enedis",[2.3242003108426075, 47.06694982773767]],
    [ "Enedis",[2.323131396746506, 47.06172060600576]],
    [ "Enedis",[3.506513489526207, 49.1986414948193]],
    [ "Enedis",[3.5120061808551446, 49.19843867985538]],
    [ "Enedis",[3.5149085949144223, 49.20519307779774]],
    [ "Enedis",[3.501191302832565, 49.178629411785714]],
    [ "Enedis",[3.5088691285257063, 49.203319168550465]],
    [ "Enedis",[3.5174949400274986, 49.19988948746984]],
    [ "Enedis",[3.5168521466565483, 49.19792928637024]],
    [ "Enedis",[3.513397254197938, 49.200136102457385]],
    [ "Enedis",[3.5205245765460877, 49.199688323105974]],
    [ "Enedis",[3.5165616899033227, 49.20210506710266]],
    [ "Enedis",[3.5069029523463477, 49.20217379815574]],
    [ "Enedis",[6.385389815095516, 47.813826223762696]],
    [ "Enedis",[-0.097330735227785, 48.18687836941814]],
    [ "Enedis",[-0.12201300000000036, 48.180302]],
    [ "Enedis",[-0.09113225, 48.18710025]],
    [ "Enedis",[6.597472203854009, 46.31432218800176]],
    [ "Enedis",[3.9107704571755564, 49.834162891216025]],
    [ "Enedis",[3.8965152270369123, 49.83055260679764]],
    [ "Enedis",[3.903542848102363, 49.8328587986337]],
    [ "Enedis",[3.9076780699333438, 49.833105795793166]],
    [ "Enedis",[3.9159156220236957, 49.83295916123502]],
    [ "Enedis",[3.9171535960919104, 49.83910145835542]],
    [ "Enedis",[3.9185375363164, 49.84151214692422]],
    [ "Enedis",[3.90784203737134, 49.83535433657308]],
    [ "Enedis",[3.91466152417119, 49.83678792059951]],
    [ "Enedis",[3.9082093349958016, 49.83212724942781]],
    [ "Enedis",[4.245331587628866, 45.40826765292097]],
    [ "Enedis",[2.734286636190476, 46.30221508571427]],
    [ "Enedis",[2.7367778490346586, 46.30146829774366]],
    [ "Enedis",[2.737905789776818, 46.30081563498919]],
    [ "Enedis",[2.7351460000000003, 46.300122]],
    [ "Enedis",[2.0121714362090812, 47.930693224169936]],
    [ "Enedis",[2.0083722522522525, 47.93176308108109]],
    [ "Enedis",[2.0065255, 47.924472]],
    [ "Enedis",[2.0084289663879877, 47.92369846680847]],
    [ "Enedis",[2.9938314822524683, 46.22679128315986]],
    [ "Enedis",[-0.2618275, 48.485864500000005]],
    [ "Enedis",[-0.26042888864942604, 48.4844155711207]],
    [ "Enedis",[1.2285147552602438, 43.599462966777416]],
    [ "Enedis",[1.2144527231216289, 48.914094531755914]],
    [ "Enedis",[3.5085040800000002, 47.97102915333333]],
    [ "Enedis",[3.5110235, 47.97375699999999]],
    [ "Enedis",[1.188924, 48.903044]],
    [ "Enedis",[-1.3021497999999996, 46.67701275]],
    [ "Enedis",[-1.2918619500000001, 46.32449659999999]],
    [ "Enedis",[-1.2912237, 46.32264504999999]],
    [ "Enedis",[-1.2956631000000003, 46.32535]],
    [ "Enedis",[-1.2972550000000005, 46.333251749999995]],
    [ "Enedis",[-1.3150319499999996, 46.33659175]],
    [ "Enedis",[1.894184751789268, 46.31668542860727]],
    [ "Enedis",[-0.3819318869003531, 43.86587221933026]],
    [ "Poste électrique de Paimpol",[-3.04239106946331, 48.76396935513317]],
    [ "Poste électrique de Perros",[-3.4571556378475465, 48.78251279677365]],
    [ "Enedis",[6.36663552617205, 47.81478795197254]],
    [ "Enedis",[3.316089666666666, 46.31605533333334]],
    [ "Enedis",[3.2848573333333344, 46.31229216666667]],
    [ "Enedis",[3.299469671500086, 46.30805698273778]],
    [ "Enedis",[3.298053267043314, 46.29658205386064]],
    [ "Enedis",[3.2930030612244905, 46.31400226530613]],
    [ "Enedis",[3.2793219131714495, 46.31225639955851]],
    [ "Enedis",[3.2831029138576775, 46.3109214494382]],
    [ "Enedis",[3.2847796887835705, 46.30824408530807]],
    [ "Enedis",[3.291846824822695, 46.31317995780142]],
    [ "Enedis",[3.2820074126984125, 46.30240641269842]],
    [ "Enedis",[3.2813682898876397, 46.31123455672077]],
    [ "Enedis",[3.3011986689271433, 46.302809836376284]],
    [ "Enedis",[3.2862456497825527, 46.30545329751854]],
    [ "Enedis",[3.297983592592592, 46.308699399999995]],
    [ "Enedis",[3.281946298554283, 46.302399107883254]],
    [ "Enedis",[4.179185282670824, 49.767644074045215]],
    [ "Enedis",[4.189974836165874, 49.77747897507365]],
    [ "Enedis",[4.166395915055363, 49.7717486248554]],
    [ "Poste électrique d'Uzel",[-2.848626111115509, 48.27573363930904]],
    [ "RTE",[-3.6362629409245937, 48.13195995483101]],
    [ "RTE",[-2.921383250712753, 48.36820064738764]],
    [ "RTE",[-3.7233506379646495, 48.046419121353516]],
    [ "Poste Électrique de Cascadec",[-3.681312000681679, 48.001420847850305]],
    [ "RTE",[-3.847035647440113, 48.181755069476196]],
    [ "Enedis",[3.9005799999999993, 49.6483615]],
    [ "Enedis",[3.8996555, 49.64569349999999]],
    [ "Enedis",[3.9028619136597937, 49.64708258977664]],
    [ "Enedis",[3.895775831922612, 49.6547154917372]],
    [ "Enedis",[3.204135900571713, 46.305482281630624]],
    [ "Enedis",[3.833820166666667, 49.96635200000001]],
    [ "Enedis",[3.8402343097731237, 49.970864062827225]],
    [ "Enedis",[4.656153865633075, 46.47045583979329]],
    [ "Poste électrique de Pluzunet",[-3.333465545356435, 48.63855196374268]],
    [ "Poste Électrique de Cléder",[-4.133449083556326, 48.64470502484472]],
    [ "RTE",[-2.8771054253862816, 48.1721990585907]],
    [ "RTE",[-3.0372498034933564, 47.87775451234354]],
    [ "Poste électrique de Lisloch",[-3.5843631126215865, 47.851876664679104]],
    [ "RTE",[-3.165550720607959, 47.777435214795716]],
    [ "RTE",[-2.999327599070125, 47.68794723460108]],
    [ "Enedis",[4.719928180742104, 45.473197476691325]],
    [ "Enedis",[5.080463700686103, 44.87362051103837]],
    [ "Enedis",[0.7844331, 48.11342195]],
    [ "Enedis",[0.7725630999999992, 48.10965289999999]],
    [ "Enedis",[3.52236417210539, 49.32266851752477]],
    [ "Enedis",[3.5251808498168504, 49.51351896336996]],
    [ "Enedis",[1.8877331008403362, 47.19125434565826]],
    [ "Enedis",[0.9245721446393759, 47.90516746725147]],
    [ "Enedis",[0.9297092916361865, 47.90074861789807]],
    [ "Enedis",[1.1251555, 47.893383500000006]],
    [ "Enedis",[1.232049863068466, 47.89916354722639]],
    [ "Enedis",[1.2335664999999998, 47.9063105]],
    [ "Vigné",[4.205337699999999, 43.78444530000001]],
    [ "Ceps",[4.2042732, 43.7851304]],
    [ "Enedis",[-0.44826729629258233, 43.91827288464764]],
    [ "RTE",[2.343874197475268, 48.94545311160294]],
    [ "Enedis",[2.7037186606614916, 48.002461391176624]],
    [ "Enedis",[6.576582718808194, 49.19762587523278]],
    [ "Poste électrique de Lapoutroie",[7.182496945004191, 48.1427474781561]],
    [ "Poste électrique de Munster",[7.1306083501260575, 48.03691423287815]],
    [ "Enedis",[3.4539475303103995, 43.64666710264073]],
    [ "Enedis",[3.4561495322169056, 43.645208693567255]],
    [ "Enedis",[3.46150885, 43.64600475000001]],
    [ "Enedis",[1.5429908865503155, 48.449240859616296]],
    [ "Enedis",[1.5441952643871117, 48.45027485631131]],
    [ "Au Chou",[6.145934051950642, 45.934669583854934]],
    [ "Bregny",[6.387255625462056, 46.150008553023035]],
    [ "Château",[6.0644792, 45.892797200000004]],
    [ "Maclamot",[6.069582696283247, 45.895729317098684]],
    [ "Primeveres",[6.41324895, 46.14898899999999]],
    [ "Province",[6.1750494499999995, 45.950005049999994]],
    [ "Pré des Combes",[6.4229161027307855, 46.14426436674174]],
    [ "Enedis",[6.069807226770582, 45.898579291086406]],
    [ "Enedis",[6.477680377948393, 46.174227154663384]],
    [ "Enedis",[0.7450837988316904, 46.871515483023]],
    [ "Enedis",[0.10582941161616184, 48.761641636363635]],
    [ "Cité basse",[2.5034958000000005, 45.41393195]],
    [ "Enedis",[6.01882075, 48.979145]],
    [ "Mairie",[3.1623685751679504, 46.185304757006]],
    [ "Enedis",[2.263889, 49.65688199999999]],
    [ "Enedis",[2.2698817504105095, 49.656178844189014]],
    [ "Poënat",[3.4328900099835225, 46.215367069109234]],
    [ "Enedis",[3.4294472944983814, 46.2175051262136]],
    [ "Enedis",[3.434288899408284, 46.23904334911243]],
    [ "Enedis",[3.4284008840579707, 46.23779322463768]],
    [ "Enedis",[3.6141994994910944, 49.262879021882945]],
    [ "Enedis",[3.3597745, 46.163813000000005]],
    [ "Enedis",[3.3497999999999997, 46.1656795]],
    [ "Enedis",[0.6129780000000005, 47.747175000000006]],
    [ "Enedis",[0.6242577500000006, 47.7653875]],
    [ "Enedis",[0.6176379999999999, 47.74739950000001]],
    [ "Enedis",[2.1688607223739296, 49.69032492463135]],
    [ "RTE",[7.198872545155445, 48.25026475403597]],
    [ "RTE",[7.36878425418692, 48.18911254868652]],
    [ "Laxou",[6.121746504205753, 48.69433941835153]],
    [ "Enedis",[2.1479528102564105, 49.5897808923077]],
    [ "RTE",[1.6612203347427765, 47.746112025369975]],
    [ "Enedis",[1.672617333333333, 47.75825227272727]],
    [ "Enedis",[1.686433, 47.76348900000001]],
    [ "Enedis",[1.6912751536643027, 47.766893028368784]],
    [ "Enedis",[1.6921094888888888, 47.768209333333324]],
    [ "Enedis",[1.6983560000000002, 47.77012300000001]],
    [ "Enedis",[1.6801914999999998, 47.762263]],
    [ "Enedis",[1.687551, 47.771949]],
    [ "Enedis",[1.691218, 47.77113]],
    [ "Enedis",[1.7201011666666668, 47.799859]],
    [ "Enedis",[1.7184638978978977, 47.796539345345344]],
    [ "Enedis",[1.1348070842105262, 47.60738516491229]],
    [ "Enedis",[1.142523500000001, 47.606148999999995]],
    [ "Poste électrique des Talus",[-1.7111345309647779, 48.13920586145646]],
    [ "Enedis",[1.5254795180055396, 47.79980423730379]],
    [ "Enedis",[1.874534447488584, 47.78117431050228]],
    [ "340272094",[1.8724238, 47.77798454999999]],
    [ "Enedis",[0.9027110000000002, 49.283837000000005]],
    [ "Enedis",[0.9042431818840589, 49.26882701594202]],
    [ "Enedis",[6.51111225, 46.14699825]],
    [ "Enedis",[1.8221349999999998, 47.833401499999994]],
    [ "Enedis",[1.2924068838243472, 47.58663729812859]],
    [ "Enedis",[3.7763448819792718, 49.913274599799394]],
    [ "Enedis",[1.30089675, 47.5902725]],
    [ "Enedis",[3.842439365524404, 49.63242010488058]],
    [ "Enedis",[1.3119856417578508, 47.60729059185126]],
    [ "Enedis",[1.3077646583770037, 47.60626153808162]],
    [ "Enedis",[1.3054358642849806, 47.607250824863975]],
    [ "Enedis",[1.3063699687074828, 47.60647033877551]],
    [ "Enedis",[1.987674716934732, 49.65981159163257]],
    [ "Enedis",[1.9867382918712482, 49.65680764920895]],
    [ "Enedis",[3.2493798027210885, 49.20611524489796]],
    [ "Enedis",[1.3450253365243985, 47.579856111687285]],
    [ "Enedis",[3.330983818333334, 46.08852331166667]],
    [ "Enedis",[1.296838749999999, 47.587519750000006]],
    [ "Enedis",[3.4710555, 46.204254]],
    [ "Enedis",[1.3181927131936209, 47.60839840160801]],
    [ "Enedis",[2.7362276183127574, 48.27177102870371]],
    [ "Enedis",[2.725857367057233, 48.27071661176206]],
    [ "Enedis",[2.7264355688026356, 48.27029044369414]],
    [ "Enedis",[2.7412428203386034, 48.27431076145937]],
    [ "Enedis",[3.9637964575818296, 46.88463151258071]],
    [ "Pont de Villaz",[6.165662656630888, 45.957823224088614]],
    [ "Enedis",[2.825717483022676, 48.29713975852132]],
    [ "Enedis",[2.824700397753296, 48.30106176976587]],
    [ "Enedis",[-0.599585702199662, 43.1621344213198]],
    [ "Enedis",[3.396188867803311, 46.23513316530762]],
    [ "Enedis",[3.96866415, 50.272777649999995]],
    [ "Guichot",[2.523210103658536, 48.35918144731707]],
    [ "Enedis",[2.5188935325451944, 48.35678104170228]],
    [ "Fontenelle",[2.5254699972431705, 48.362401187738975]],
    [ "Enedis",[2.458451846281506, 48.34615385533658]],
    [ "Enedis",[2.462575238369968, 48.3483354028875]],
    [ "Enedis",[1.9876590038201944, 49.64641894127072]],
    [ "Enedis",[-0.6466654331310002, 49.152680300507896]],
    [ "Enedis",[2.995573272412839, 48.42379510561704]],
    [ "Enedis",[0.78682416288909, 49.27186533000907]],
    [ "Enedis",[0.7826105, 49.272099]],
    [ "Enedis",[0.781563408824124, 49.27051899959523]],
    [ "RTE",[-2.2911516544744197, 47.46934630423428]],
    [ "Enedis",[-2.5218551694919125, 47.545429586112995]],
    [ "RTE",[-2.3275848182820567, 47.53184883195668]],
    [ "Enedis",[2.5704357616696316, 48.356053019746106]],
    [ "Enedis",[2.5672097183189746, 48.35998310330759]],
    [ "Enedis",[2.5723556463575807, 48.35308600195255]],
    [ "Enedis",[2.56488989971142, 48.35608679937177]],
    [ "Enedis",[2.5610754128620337, 48.359751423421585]],
    [ "Enedis",[2.567291420803402, 48.35185018124284]],
    [ "RTE",[-2.083098726495015, 47.99683338217768]],
    [ "RTE",[-1.2623400276624503, 47.89102131104924]],
    [ "RTE",[-0.968686251596407, 48.46220843926172]],
    [ "Enedis",[0.9113745, 49.82251149999999]],
    [ "Enedis",[0.9128624055316097, 49.81587743390804]],
    [ "Enedis",[0.9093827888762758, 49.82376896405599]],
    [ "Enedis",[0.9167944066336807, 49.82677940150265]],
    [ "Enedis",[0.9126408709677418, 49.828233314217435]],
    [ "Enedis",[0.9307264012903226, 49.83095647956989]],
    [ "Enedis",[0.9272022534301871, 49.83041803631961]],
    [ "Enedis",[3.9045673585002567, 46.10558307190549]],
    [ "Enedis",[-0.8561875011502402, 44.55702718277003]],
    [ "Enedis",[3.7470950341332583, 46.254058595232436]],
    [ "Enedis",[3.4921679308050564, 46.021633740186296]],
    [ "Enedis",[2.652484, 50.0233725]],
    [ "SICAE Oise",[2.5575082378294574, 49.55126503069768]],
    [ "SICAE Oise",[2.5561321921369102, 49.55335465726179]],
    [ "Enedis",[2.535713666666667, 50.049546553459116]],
    [ "Schabis",[7.2796458, 47.79012335]],
    [ "Poste électrique d'Alger",[6.119798628420768, 49.08932027078937]],
    [ "Enedis",[-0.6413004797486678, 49.1927695031821]],
    [ "RTE",[6.120983412126297, 49.089874177676506]],
    [ "réséda",[6.157299403140615, 49.15166516452535]],
    [ "Enedis",[2.0707634999999995, 48.60403899999999]],
    [ "Enedis",[3.9568274188984973, 50.281659179723285]],
    [ "Enedis",[1.8186193918441744, 48.844185165322116]],
    [ "Enedis",[4.834875767373933, 45.73161909458838]],
    [ "Enedis",[3.365495550048591, 46.35912919339165]],
    [ "Enedis",[3.3876321121064934, 46.348738763014936]],
    [ "RTE",[1.570641358519873, 47.74325678392347]],
    [ "Enedis",[1.8951797499999998, 48.45947339999999]],
    [ "Enedis",[1.8891624411322936, 48.474429785268626]],
    [ "Enedis",[1.8978501275960848, 48.45470814235012]],
    [ "Enedis",[1.9027784732983941, 48.48383118690173]],
    [ "Enedis",[1.8968677805375973, 48.458560026273105]],
    [ "Enedis",[1.5424461607017543, 47.71688448561403]],
    [ "Enedis",[-0.9837257999999991, 44.23268755]],
    [ "343667642",[2.393842750677507, 43.22229846341464]],
    [ "343985057",[2.170684191139485, 49.517369422131935]],
    [ "Enedis",[2.1670033279132785, 49.51362534688346]],
    [ "Enedis",[2.260220813504823, 49.55966758199357]],
    [ "Enedis",[3.6242637016072687, 46.255743550663865]],
    [ "Enedis",[3.61391309469697, 46.24793579924242]],
    [ "Enedis",[3.6230169028362824, 46.25036570710474]],
    [ "Enedis",[3.6362227943467222, 46.247874777806956]],
    [ "Enedis",[3.634050873674753, 46.24124531626235]],
    [ "Enedis",[3.6651215385906033, 46.28702768064877]],
    [ "Enedis",[3.6382605000000003, 46.249553500000005]],
    [ "Enedis",[3.629949269733876, 46.23715055164636]],
    [ "Enedis",[3.622497155464105, 46.247354767261086]],
    [ "Enedis",[3.619663194715787, 46.248803827053685]],
    [ "Enedis",[3.6139623515981727, 46.2490188173516]],
    [ "Enedis",[3.6247830648967563, 46.24481656047199]],
    [ "Enedis",[3.6805130923665947, 46.28658273821488]],
    [ "Enedis",[3.6631158882843673, 46.25033374537541]],
    [ "Enedis",[3.6389207139411477, 46.253692197780985]],
    [ "Enedis",[3.6336615679233994, 46.24599926451227]],
    [ "Enedis",[3.6241875347820667, 46.24613017784438]],
    [ "Enedis",[3.6324398728730816, 46.24405037594142]],
    [ "Poste électrique de Morosaglia",[9.214259003869804, 42.4558842691622]],
    [ "Poste électrique de Tolla",[8.949366607967928, 41.96164630177543]],
    [ "Poste électrique de Bastia-Ville",[9.444240120901595, 42.7013529151296]],
    [ "Les Boches",[5.872348450000002, 45.62787455000001]],
    [ "Enedis",[5.532124250000001, 43.5501572]],
    [ "Enedis",[2.494259163265306, 47.61771951020409]],
    [ "Enedis",[2.497781139215686, 47.6179203117647]],
    [ "Enedis",[2.485406421143474, 47.62538280582523]],
    [ "Enedis",[-1.1242834665248986, 48.75177747703349]],
    [ "Enedis",[1.873451649999999, 47.983928950000006]],
    [ "Enedis",[1.956136742319263, 48.04738032094088]],
    [ "Enedis",[1.9798667268493644, 48.03697244545944]],
    [ "Enedis",[1.9823444841269848, 48.04232864955357]],
    [ "Enedis",[1.9822540000000002, 48.03832916666668]],
    [ "Enedis",[1.4759497, 42.8945954]],
    [ "Enedis",[3.49349416898793, 46.31863767780873]],
    [ "Poste électrique de Petit-Saut",[-53.04585045688093, 5.064188387194621]],
    [ "Enedis",[2.8766589655712047, 42.869018151799686]],
    [ "Enedis",[2.8742584999999985, 42.868743]],
    [ "FCStation 83",[2.236527816771445, 48.993821546395594]],
    [ "Enedis",[-0.6985257000000009, 49.15526049999999]],
    [ "Enedis",[1.360465837100825, 43.55433904772156]],
    [ "Poste de Cajarc",[1.8322822548613613, 44.47530250416209]],
    [ "Enedis",[1.649965032351243, 46.77470445522818]],
    [ "Enedis",[1.6716907941326526, 46.820244446768704]],
    [ "Enedis",[1.6293206381825749, 46.79651460983742]],
    [ "Enedis",[1.63098253745395, 46.80244550061399]],
    [ "Enedis",[1.9465592284263282, 47.93409970422454]],
    [ "Enedis",[1.951275437565545, 47.940881730343314]],
    [ "Enedis",[1.967479307934631, 47.9350684972403]],
    [ "Enedis",[1.9409475614961582, 47.93473959899291]],
    [ "Enedis",[1.958523343287425, 47.93037634516617]],
    [ "Enedis",[1.9455415487179482, 47.93632467692307]],
    [ "Enedis",[1.9505043075652344, 47.93865400614325]],
    [ "Enedis",[1.9460492610709987, 47.92830456315282]],
    [ "Enedis",[1.9475898875838926, 47.932447021885864]],
    [ "Enedis",[1.9471765213512058, 47.93846983673567]],
    [ "Enedis",[1.9484743840331367, 47.93681804121586]],
    [ "Enedis",[1.9439577505131027, 47.93234180115448]],
    [ "Enedis",[2.319798357159876, 48.34336625899452]],
    [ "Enedis",[2.1706724294726216, 48.500303818571794]],
    [ "Enedis",[2.1687749999999997, 48.50329000000001]],
    [ "Poste électrique du Thillot",[6.75267070271373, 47.88661445710396]],
    [ "Enedis",[2.353558204971059, 49.10730897786857]],
    [ "Enedis",[2.054674729492081, 47.95267092876388]],
    [ "Enedis",[2.0528789943445096, 47.972110998215996]],
    [ "Enedis",[2.0554847968640866, 47.95815479973248]],
    [ "Enedis",[2.0545064184002872, 47.94995496377333]],
    [ "Enedis",[2.0480975, 47.943811834189496]],
    [ "Enedis",[2.40594087819209, 49.01177660307344]],
    [ "Enedis",[2.406454499526311, 49.01135078041089]],
    [ "Enedis",[1.3349408439716313, 43.55273745390071]],
    [ "Enedis",[3.095060982954546, 46.3362651657197]],
    [ "Enedis",[3.85923095, 43.9659999]],
    [ "SRD",[0.19737378703703704, 46.57132995185185]],
    [ "SRD",[0.2191574102079397, 46.56996476244486]],
    [ "SRD",[0.21572077160493813, 46.57441514444445]],
    [ "SRD",[0.2108036261645188, 46.56949451629955]],
    [ "SRD",[0.20395527160493854, 46.566455481481476]],
    [ "Hermitage",[5.2346067872414705, 45.598181210606]],
    [ "Enedis",[0.7527077525727071, 47.83468434944071]],
    [ "Enedis",[0.752779, 47.83988583333333]],
    [ "Enedis",[0.7471416118038234, 47.83395329010806]],
    [ "Enedis",[0.7415098176033925, 47.827791856663126]],
    [ "Enedis",[0.7517448254698887, 47.83796885538935]],
    [ "Enedis",[0.748155766390889, 47.832160133618004]],
    [ "Vivaldi",[5.258778820420579, 45.585696587111855]],
    [ "Enedis",[4.629864325576037, 46.53659577081414]],
    [ "Enedis",[-1.5085730720978874, 43.47586254432339]],
    [ "Essert la Pierre",[6.673584909506586, 46.2156278767154]],
    [ "Enedis",[6.616941700000003, 46.28280885]],
    [ "Enedis",[3.7082547007042246, 46.68081305516433]],
    [ "Enedis",[0.2384528653198656, 47.82216234175083]],
    [ "Enedis",[2.60843546969697, 48.203293647474744]],
    [ "Enedis",[2.5652224285183225, 48.152102792130854]],
    [ "Enedis",[2.5342286768491733, 48.19316209922831]],
    [ "Enedis",[2.528291677720472, 48.157694487800356]],
    [ "Enedis",[2.5476859808313916, 48.18819650709291]],
    [ "Enedis",[2.6101416740648973, 48.21377685981499]],
    [ "Enedis",[2.966010254256934, 48.39766341853424]],
    [ "Enedis",[2.9724701302288374, 48.39654045698894]],
    [ "Enedis",[2.9682932748173076, 48.40151058929366]],
    [ "Enedis",[2.973076770910504, 48.39260732080473]],
    [ "Enedis",[2.9551054031551627, 48.390220614552796]],
    [ "Enedis",[2.9929518711800607, 48.416932955914916]],
    [ "Enedis",[2.9831267075005043, 48.3994945111894]],
    [ "Enedis",[2.989482101313641, 48.40008236254277]],
    [ "Enedis",[2.9861444467936162, 48.40040915445765]],
    [ "Enedis",[2.9974092777801955, 48.39913649607545]],
    [ "Enedis",[2.954870620271678, 48.39514737379731]],
    [ "Enedis",[2.959460740419187, 48.399213098301544]],
    [ "Enedis",[2.960071744500216, 48.39753230784368]],
    [ "Enedis",[2.955328798030075, 48.39721336626588]],
    [ "Enedis",[2.96673159312139, 48.403390951316645]],
    [ "Enedis",[2.9870449043173872, 48.398927748930625]],
    [ "Enedis",[2.9911011723600267, 48.40099665620641]],
    [ "Enedis",[2.964273314791337, 48.393103713467994]],
    [ "Enedis",[2.952163223679619, 48.37838912397573]],
    [ "Enedis",[2.9760030904012083, 48.38298439071738]],
    [ "Enedis",[2.9489409966141613, 48.39080425633937]],
    [ "Enedis",[2.9487641005026597, 48.37899753776484]],
    [ "Enedis",[2.9550859521182264, 48.384272622814734]],
    [ "Enedis",[2.9636205693030773, 48.38781162864233]],
    [ "Enedis",[2.9748523578908763, 48.384282282777555]],
    [ "Enedis",[2.9579567601077024, 48.38385105960859]],
    [ "Enedis",[2.9531864272919526, 48.3870897869584]],
    [ "Enedis",[2.953398468904208, 48.39569018095198]],
    [ "Enedis",[2.952763352508458, 48.39331816809487]],
    [ "Enedis",[2.966730694190236, 48.388398883852894]],
    [ "Enedis",[2.9540588265601686, 48.38089522259799]],
    [ "Enedis",[2.9682074730675136, 48.386131498063925]],
    [ "Enedis",[2.950580965280003, 48.39965456634778]],
    [ "Enedis",[2.957856224584497, 48.38623476291273]],
    [ "Enedis",[2.979130185646105, 48.38599204801829]],
    [ "Enedis",[2.9665351445459507, 48.38566955234726]],
    [ "Enedis",[2.954723315912055, 48.397917255574086]],
    [ "Enedis",[2.9499212866697606, 48.38514043279367]],
    [ "Enedis",[2.972545160420532, 48.38516261249228]],
    [ "Enedis",[2.93791408656158, 48.389334289350735]],
    [ "Enedis",[2.947550642194472, 48.383880692685764]],
    [ "Enedis",[3.5629579130185993, 46.33021649828327]],
    [ "Enedis",[5.237976800540062, 46.95645639998909]],
    [ "Enedis",[5.2446873499999995, 46.95704575]],
    [ "Enedis",[4.224796746352413, 46.99911777328843]],
    [ "Enedis",[4.186126511750256, 47.011788018990075]],
    [ "Enedis",[0.689298, 47.771060500000004]],
    [ "Enedis",[0.6989228729641688, 47.790543779587395]],
    [ "Enedis",[0.677918940732133, 47.79440410730196]],
    [ "Enedis",[0.22718964392815924, 47.879105645905426]],
    [ "Enedis",[0.22822740000000047, 47.875943533333334]],
    [ "Enedis",[0.2322110076406381, 47.87715596523928]],
    [ "Enedis",[0.23393246105466967, 47.879351496371555]],
    [ "Enedis",[1.921279151266631, 47.88921245616912]],
    [ "Enedis",[1.9288940993676598, 47.89057016169828]],
    [ "Poste électrique de Lusignan",[0.10082642363977438, 46.42728523884772]],
    [ "Poste électrique de la Courneuve",[2.3903680852686042, 48.9298486657572]],
    [ "Enedis",[0.22080685561497299, 47.876277678125795]],
    [ "Enedis",[0.214444307337689, 47.878018581636574]],
    [ "Enedis",[0.20442897481860842, 47.874680395219805]],
    [ "Enedis",[0.21850116467258482, 47.87569733088897]],
    [ "Enedis",[0.19372050000000002, 47.87625800000001]],
    [ "ESSeyssel",[6.16610705739399, 45.999802893591685]],
    [ "Enedis",[2.519650000000001, 47.761801000000006]],
    [ "Enedis",[2.5081885202354672, 47.759506340691686]],
    [ "Enedis",[2.5073684865207735, 47.760695272438944]],
    [ "Enedis",[2.5287215600739366, 47.76967133887862]],
    [ "Enedis",[2.525933277056277, 47.76306705627706]],
    [ "Enedis",[2.556094145316285, 48.25879854946259]],
    [ "Enedis",[2.6786866836601697, 47.64127023224919]],
    [ "Enedis",[4.4986215000000005, 43.828011499999995]],
    [ "Enedis",[0.6773579920343136, 48.14836832996323]],
    [ "Enedis",[0.64178975, 48.162544]],
    [ "RTE",[-1.2530520017710947, 47.178211910842236]],
    [ "Enedis",[-1.746397112247869, 49.35608295616552]],
    [ "Enedis",[-1.7567713000000005, 49.359885899999995]],
    [ "Enedis",[6.675658900000003, 46.36058855000001]],
    [ "ChefLieu",[6.632107699999999, 46.37859535]],
    [ "RTE",[1.6273410818806269, 42.87818246480626]],
    [ "Enedis",[2.6045895000000003, 47.598395]],
    [ "Enedis",[2.7494108490374867, 47.59129901823708]],
    [ "Enedis",[2.761063581348541, 47.58224290528905]],
    [ "Enedis",[2.756515668041237, 47.59021801786941]],
    [ "Enedis",[2.745132400395941, 47.59191277926256]],
    [ "Enedis",[2.754159833218628, 47.58835700504703]],
    [ "Enedis",[2.491225, 47.7266705]],
    [ "Enedis",[2.5517427139639643, 47.721097054054056]],
    [ "Enedis",[2.5452838561403506, 47.721640849122814]],
    [ "Enedis",[2.7458764999999996, 47.742081999999996]],
    [ "Enedis",[2.801462358126721, 47.59125500413222]],
    [ "Enedis",[2.83738758677686, 47.56162432782369]],
    [ "Enedis",[2.833581186397985, 47.56583285082563]],
    [ "Enedis",[2.8399828099463855, 47.56184946847846]],
    [ "Enedis",[2.844064118424156, 47.5574703693088]],
    [ "Enedis",[2.820392306782107, 47.53758370389611]],
    [ "Enedis",[2.808343269779507, 47.546848674448775]],
    [ "Enedis",[2.8352884999999994, 47.545247499999995]],
    [ "Enedis",[2.816694208396561, 47.546277447142145]],
    [ "Enedis",[2.818049837315131, 47.54158804778157]],
    [ "Enedis",[2.814434125498475, 47.539674308468214]],
    [ "Enedis",[2.816697446969697, 47.53891833333333]],
    [ "Enedis",[3.91263063395825, 46.675110160483975]],
    [ "Enedis",[6.591192283915693, 46.36711914819668]],
    [ "Enedis",[6.029044235824058, 46.25080419766824]],
    [ "Enedis",[6.028465663194442, 46.25210806944444]],
    [ "Enedis",[2.21754352173913, 47.695803333333345]],
    [ "Enedis",[2.2099008041112453, 47.715429044336965]],
    [ "Enedis",[2.2270917550043516, 47.67727314403828]],
    [ "Enedis",[2.25462680702284, 47.667931935808426]],
    [ "Enedis",[2.29823, 47.731026]],
    [ "Enedis",[2.3006010264317185, 47.72661033186491]],
    [ "Enedis",[2.6645905102467586, 47.542006863237134]],
    [ "Enedis",[2.6568296851424176, 47.53719921324096]],
    [ "Enedis",[2.7713004999999984, 49.412867]],
    [ "Enedis",[2.7763095000000004, 49.4147885]],
    [ "Enedis",[2.773213074074074, 49.4164932121212]],
    [ "Enedis",[0.398916333333334, 48.30837]],
    [ "RTE",[2.4471991525576278, 49.8920262916697]],
    [ "Enedis",[2.988858968378414, 47.54085471331504]],
    [ "Beausejour",[6.157801677144795, 45.969050741268234]],
    [ "Enedis",[6.526158582602109, 46.345578210241]],
    [ "Enedis",[6.608196200000001, 46.35643755]],
    [ "Enedis",[3.068852806670545, 47.528729672484]],
    [ "Enedis",[3.065890748448338, 47.528453912376776]],
    [ "Enedis",[3.078582600000001, 47.5297585]],
    [ "Enedis",[3.07148129134361, 47.53063723318382]],
    [ "Enedis",[3.0825763000000004, 47.5248762]],
    [ "Sous-station Ouest Ceinture",[2.3044422544266387, 48.82954651674579]],
    [ "Enedis",[-0.7118650000000001, 49.05284399999999]],
    [ "Centrale électrique de Audon",[-0.8236237951202747, 43.80765881077232]],
    [ "Enedis",[5.081747387485244, 44.37504632428268]],
    [ "Enedis",[1.3242921198675306, 47.58475914109833]],
    [ "Enedis",[2.4409692989519747, 47.74230924667473]],
    [ "Enedis",[6.296715850000001, 48.63218619999999]],
    [ "Enedis",[3.431325437994004, 46.84011369501226]],
    [ "Enedis",[3.4462813111578576, 46.83928167902533]],
    [ "Enedis",[2.3713682174343726, 49.025366339276864]],
    [ "Enedis",[2.2409640000000004, 48.356722999999995]],
    [ "Enedis",[3.3535765350877194, 46.70925557894737]],
    [ "Enedis",[4.38557877240434, 44.001945743580386]],
    [ "RFF Tunnel Frejus",[6.668293999999999, 45.19472484999999]],
    [ "Enedis",[4.2033388, 43.92201545]],
    [ "Poste électrique de Bellac",[1.0436181934881885, 46.105255060483096]],
    [ "EDF",[0.68504465, 46.24278555]],
    [ "355799590",[0.6734222628553926, 46.17008211944809]],
    [ "Poste électrique de Sorigny",[0.7277172110420292, 47.26241388652253]],
    [ "RTE",[-0.06989603199438776, 47.24402105920158]],
    [ "Enedis",[3.502040094972066, 50.35153482160149]],
    [ "Enedis",[-1.8405901585106386, 48.15379917907801]],
    [ "Enedis",[-1.8393552211837993, 48.15517502907581]],
    [ "Enedis",[3.932273998281619, 47.05642967864647]],
    [ "Enedis",[3.9332682099094316, 47.05761383217901]],
    [ "Enedis",[3.9382336362378965, 47.06178932918395]],
    [ "Enedis",[3.9306142628661918, 47.0704875209818]],
    [ "Enedis",[3.929689467078522, 47.06764560805816]],
    [ "Enedis",[2.2280036629017084, 48.382344224149534]],
    [ "Enedis",[2.853157833333334, 47.50126000000001]],
    [ "Enedis",[2.855210615581098, 47.49624586717752]],
    [ "Enedis",[2.8570137038917096, 47.49865941793571]],
    [ "Enedis",[2.8489827450980387, 47.50966249019607]],
    [ "Enedis",[2.8498532575699507, 47.4963261706912]],
    [ "Enedis",[2.47539484396637, 49.64128334767556]],
    [ "Enedis",[4.340048159064765, 44.00307206109391]],
    [ "Enedis",[2.616849730810696, 49.654546542894565]],
    [ "Enedis",[2.6500699000000005, 50.0722764]],
    [ "Enedis",[2.730491, 50.0413545]],
    [ "Enedis",[3.638108497683492, 47.70322367008046]],
    [ "Enedis",[3.8212865036266157, 47.42706383349101]],
    [ "Cimetière",[0.27069941845664297, 47.890078686555285]],
    [ "Enedis",[0.2761054999999991, 47.88808725]],
    [ "Poste électrique de Bissorte",[6.584736401528984, 45.20252920473964]],
    [ "Enedis",[2.5224906999999996, 49.640474900000015]],
    [ "Enedis",[1.5124160867321867, 43.50321765429975]],
    [ "Enedis",[5.555862117769561, 44.65942463452964]],
    [ "Enedis",[2.7347914899455135, 47.84790935664969]],
    [ "DEODAT DE SEVERAC II",[1.354792655485275, 43.59761497096712]],
    [ "Enedis",[2.4632870838445813, 49.654057118970286]],
    [ "SICAE de la Somme et du Cambraisis",[2.64198381604426, 49.62866502213001]],
    [ "Enedis",[-1.69258, 47.843528]],
    [ "Enedis",[-1.6531271490837975, 47.86288174114336]],
    [ "Enedis",[2.2577183000000005, 48.8235764]],
    [ "Enedis",[3.031229727447996, 49.74029708092338]],
    [ "Enedis",[3.0415006953358033, 49.74001015105918]],
    [ "Enedis",[3.0522749245794496, 49.732749125061616]],
    [ "Enedis",[3.1017935000000003, 49.717134200000004]],
    [ "Enedis",[1.0250850707482995, 42.927045499319725]],
    [ "Enedis",[1.1836965727887534, 47.342343025716325]],
    [ "Enedis",[1.192170643770725, 47.34527225596085]],
    [ "Enedis",[1.176351779462623, 47.34273336445863]],
    [ "Enedis",[1.1780140000000001, 47.341050499999994]],
    [ "Enedis",[1.187105268867435, 47.35007575376311]],
    [ "Enedis",[1.1669292668111928, 47.34394814923582]],
    [ "Enedis",[1.1712745, 47.34451249999999]],
    [ "Enedis",[1.1796825774647899, 47.3524289342723]],
    [ "Enedis",[1.1643403751037347, 47.3531157142462]],
    [ "Enedis",[1.178260683681361, 47.34479792343388]],
    [ "Le Perrier",[6.062670165548065, 45.151436902314344]],
    [ "Enedis",[1.6228104999999997, 48.27623749999999]],
    [ "Enedis",[1.6259078952737875, 48.26747837226733]],
    [ "Enedis",[1.6335473252402624, 48.28148850834598]],
    [ "Enedis",[1.6209383171521037, 48.269132649406686]],
    [ "Enedis",[1.6213911397849459, 48.2717211812596]],
    [ "Enedis",[1.6201697807560134, 48.27350000389462]],
    [ "Enedis",[1.627733047639988, 48.27581313954852]],
    [ "Enedis",[1.6176985632088523, 48.27830685477178]],
    [ "Enedis",[1.6264263220329875, 48.276057255532464]],
    [ "Enedis",[1.6310057688140838, 48.27185140742889]],
    [ "Enedis",[1.6228675682424243, 48.276254119757574]],
    [ "Enedis",[1.6144504967969953, 48.27292425248509]],
    [ "Enedis",[1.6307432142857141, 48.26726309070295]],
    [ "Enedis",[1.6343852506162688, 48.267812621473574]],
    [ "Enedis",[1.6232852499999992, 48.2695116]],
    [ "Enedis",[2.2208616266958194, 48.684232104058374]],
    [ "Enedis",[-0.3018127551215776, 49.118888538196444]],
    [ "RTE",[6.250206116948093, 43.561244456933174]],
    [ "Enedis",[4.27563825, 44.240355199999996]],
    [ "Enedis",[0.7118040962009803, 47.88604807965686]],
    [ "Enedis",[2.4531414740740742, 48.64367066666667]],
    [ "Enedis",[2.4658237277203354, 48.65099344047267]],
    [ "Enedis",[2.4418621667059655, 48.65073450208284]],
    [ "Enedis",[2.449645332700942, 48.645199498134446]],
    [ "Enedis",[2.4631185031766196, 48.6471449707751]],
    [ "Enedis",[2.4452683871510255, 48.65533130003364]],
    [ "Enedis",[2.452918484071296, 48.64107416767481]],
    [ "Enedis",[2.4548995, 48.640133000000006]],
    [ "Enedis",[2.450861662283364, 48.644188490390455]],
    [ "Enedis",[2.459786652862745, 48.64990553364706]],
    [ "Enedis",[2.4579936390658172, 48.650472653927814]],
    [ "Enedis",[2.462565464907078, 48.6458841632068]],
    [ "Enedis",[2.465609499999999, 48.64978550000001]],
    [ "Enedis",[2.451001184707225, 48.65028200816434]],
    [ "Enedis",[2.458996798887463, 48.648509905006414]],
    [ "Enedis",[2.4586052700408674, 48.644355143770305]],
    [ "Enedis",[1.3804384999999992, 43.71041174999999]],
    [ "Enedis",[2.477602038759691, 49.82308834108526]],
    [ "SICAE de la Somme et du Cambraisis",[2.6636708000000002, 49.58767879999999]],
    [ "SICAE de la Somme et du Cambraisis",[2.6480607126174163, 49.598548899213]],
    [ "SICAE de la Somme et du Cambraisis",[2.649698150326798, 49.59087493464053]],
    [ "SICAE de la Somme et du Cambraisis",[2.647971882183908, 49.59370026149425]],
    [ "SICAE de la Somme et du Cambraisis",[2.6498065834647737, 49.59512583543638]],
    [ "SICAE de la Somme et du Cambraisis",[2.657489308014468, 49.59249148201029]],
    [ "Enedis",[0.21736126676222273, 49.42126607596751]],
    [ "Enedis",[0.22470997784200386, 49.42040416714836]],
    [ "SICAE de la Somme et du Cambraisis",[2.6554143403209114, 49.593442051242235]],
    [ "SICAE de la Somme et du Cambraisis",[2.5711010004033894, 49.606732151943]],
    [ "Enedis",[4.865810256610174, 45.74069113456283]],
    [ "Enedis",[6.8061865, 47.63810550000001]],
    [ "Enedis",[0.5810060000000001, 47.69158800000001]],
    [ "Nt Sainte Rita",[2.1961190902123624, 48.88752030167608]],
    [ "Enedis",[1.3771403250216452, 50.063483491948055]],
    [ "Enedis",[2.9950854999999983, 50.414064999999994]],
    [ "Enedis",[0.24682824999999906, 49.3756975]],
    [ "Enedis",[0.2510070964187326, 49.377539195592284]],
    [ "Enedis",[2.1746637760102394, 46.74772815615285]],
    [ "Enedis",[2.1740779532163756, 46.753286356725155]],
    [ "Enedis",[-0.10507006233062276, 47.86616968925023]],
    [ "Enedis",[-0.09809028491001529, 47.868994983115584]],
    [ "Enedis",[-0.09662088945899698, 47.87146275569357]],
    [ "poste_88",[2.825193330218068, 49.65519393457943]],
    [ "Enedis",[6.753043749999999, 46.40098235]],
    [ "Enedis",[2.0034923968918115, 49.52994670332735]],
    [ "Enedis",[2.4559056989801764, 49.21922670677208]],
    [ "Enedis",[2.4590814999999986, 49.231592]],
    [ "Enedis",[2.8079088525689766, 47.911984324399]],
    [ "Enedis",[2.4814434631511193, 49.629309962060866]],
    [ "Enedis",[-1.6967370555620773, 48.13654961601685]],
    [ "Enedis",[-1.6966909026514139, 48.13651305650073]],
    [ "SICAE de la Somme et du Cambraisis",[2.6117121032448374, 49.62118095575221]],
    [ "SICAE de la Somme et du Cambraisis",[2.609222518742139, 49.619474735345904]],
    [ "Enedis",[3.7580419323799794, 47.61054120784247]],
    [ "Enedis",[3.759734761056106, 47.60399854950495]],
    [ "Enedis",[0.25193100000000074, 48.321679499999995]],
    [ "Enedis",[6.92172025, 47.64223715]],
    [ "Enedis",[1.2150819002659579, 48.59791610837767]],
    [ "Enedis",[6.943165552899972, 47.670483594489596]],
    [ "Enedis",[1.3884933662271934, 48.71755873218804]],
    [ "Enedis",[1.4000435, 48.719614]],
    [ "Enedis",[1.3611695000000001, 48.671554]],
    [ "Enedis",[1.386386999999999, 48.67431949999999]],
    [ "Enedis",[1.3700205000000003, 48.652842]],
    [ "Enedis",[0.13279600000000147, 48.384322000000004]],
    [ "Enedis",[2.1202392450247007, 48.625432452552346]],
    [ "Enedis",[2.12236793542583, 48.622301281394115]],
    [ "Enedis",[0.04893972903672903, 48.291441536382536]],
    [ "Enedis",[2.539962312607944, 46.703722278065634]],
    [ "réséda",[6.135649499999999, 49.1276505]],
    [ "Enedis",[0.394236000000001, 43.501620499999994]],
    [ "Enedis",[3.6869246209704225, 47.51024290029911]],
    [ "BROSSES",[3.6918747616959053, 47.53488867543859]],
    [ "Enedis",[3.681079392301392, 47.54640326781327]],
    [ "Enedis",[3.8332557936758906, 47.794638737812924]],
    [ "Enedis",[3.8326045, 47.792023500000006]],
    [ "Enedis",[3.976714696582467, 47.7838245211986]],
    [ "Enedis",[3.8925728038617886, 47.673998141260164]],
    [ "Enedis",[3.977175141428238, 44.2994913137939]],
    [ "Enedis",[4.229368144654088, 47.931386213836475]],
    [ "Enedis",[0.7846706675541749, 48.42605192537534]],
    [ "Maison d'Arrêt",[5.905749849839421, 45.57463764625241]],
    [ "Enedis",[4.9931678, 46.8073008]],
    [ "Enedis",[3.341019192682871, 43.47115371837145]],
    [ "Enedis",[4.1188462, 47.803049200000004]],
    [ "Enedis",[3.5433671280068726, 49.48041040979381]],
    [ "Enedis",[4.0964427500000005, 47.63095435000001]],
    [ "Enedis",[4.099125433021808, 47.62928377258567]],
    [ "Enedis",[0.8506540000000007, 48.380276]],
    [ "Enedis",[0.851970841294299, 48.38280141191578]],
    [ "Enedis",[0.848892977011495, 48.379851655172416]],
    [ "Enedis",[0.8557115, 48.37945630000001]],
    [ "Enedis",[3.9836948355555553, 47.501749928888884]],
    [ "Enedis",[3.974040762853172, 47.49146447506562]],
    [ "Enedis",[4.021516, 47.563666]],
    [ "Enedis",[2.9097477072007627, 46.401363593228425]],
    [ "Enedis",[3.2502510186492852, 46.44190898569618]],
    [ "Enedis",[5.257482646131098, 48.72842976622309]],
    [ "Enedis",[4.861981200000001, 46.76868245]],
    [ "Enedis",[4.0074308, 46.039984950000004]],
    [ "Enedis",[2.366111510608696, 49.67598317066667]],
    [ "Enedis",[-4.312501, 48.37619050000001]],
    [ "Enedis",[0.33436139548022603, 48.50047990960451]],
    [ "Enedis",[0.34229593240509304, 48.51050973711771]],
    [ "Enedis",[3.4352319999999996, 49.395838499999996]],
    [ "Enedis",[0.289787656240126, 48.70206582969985]],
    [ "Enedis",[3.981232500000001, 46.11644800000001]],
    [ "Enedis",[3.969019428336079, 46.1002727457441]],
    [ "Enedis",[3.963676194315929, 46.102206326503634]],
    [ "Enedis",[3.9620189500000005, 46.1058426]],
    [ "Enedis",[3.968391, 46.10475749999999]],
    [ "Enedis",[3.8921133288973393, 46.142110659062105]],
    [ "Enedis",[3.433441996563574, 49.383981738831615]],
    [ "Enedis",[3.4366172604288496, 49.38673047212475]],
    [ "Enedis",[3.4336885789473683, 49.38766073684211]],
    [ "Enedis",[2.1226358199233717, 48.6711019642401]],
    [ "Trois Quartier",[2.1215044965162275, 48.66999688333901]],
    [ "RTE",[5.918395155734495, 49.46887768040991]],
    [ "Enedis",[0.03994643395272376, 48.05732580571357]],
    [ "Enedis",[0.07934815138592778, 48.08953017412935]],
    [ "cimetière",[0.06301449999999999, 48.078005499999996]],
    [ "Enedis",[0.061150000000000516, 48.07540699999999]],
    [ "Enedis",[0.07064583333333334, 48.08520399999999]],
    [ "Enedis",[1.0299312061855663, 42.89758873539519]],
    [ "Enedis",[1.9845597226049414, 46.589941660788085]],
    [ "réséda",[6.1804387382126285, 49.10650801933867]],
    [ "Enedis",[0.32652130000000007, 48.286122700000014]],
    [ "Enedis",[4.75284781958194, 46.78802694307429]],
    [ "Enedis",[4.7759165914976425, 46.79606856947772]],
    [ "Enedis",[2.483868105544287, 50.119953245638946]],
    [ "Enedis",[2.3703378376132926, 50.13968023645518]],
    [ "Enedis",[2.3408723989628917, 50.115935234483885]],
    [ "Enedis",[2.3366492024990837, 50.11465881587652]],
    [ "Enedis",[2.3262385, 50.109166]],
    [ "Enedis",[2.3277703255281508, 50.1061823422833]],
    [ "Enedis",[2.3298239345991565, 50.10781881786215]],
    [ "Enedis",[2.328021274051086, 50.10301534630381]],
    [ "garlet",[3.823491955564172, 49.46158976346984]],
    [ "Enedis",[2.3374249999999996, 50.211076999999996]],
    [ "Enedis",[2.3433137061143987, 50.21485475345168]],
    [ "Enedis",[2.2587864151436037, 50.11043618624891]],
    [ "Enedis",[2.416850131313131, 50.19838115263749]],
    [ "Enedis",[2.472667688880177, 50.00314183300666]],
    [ "Enedis",[2.322276591822591, 50.13667235412335]],
    [ "Enedis",[2.3227175268965525, 50.155796785287365]],
    [ "Enedis",[2.2697453817110613, 50.12769427441706]],
    [ "Enedis",[2.1685157978142073, 50.13259206557377]],
    [ "Enedis",[2.1633255, 50.134232000000004]],
    [ "Malataverne Up",[4.06996183366949, 45.17442524903991]],
    [ "Enedis",[6.192259695573951, 48.6938592519526]],
    [ "Enedis",[0.44241100000000005, 48.127906833333334]],
    [ "Enedis",[3.1865230967741938, 46.951569430107526]],
    [ "Enedis",[3.166282559221077, 46.97249815028637]],
    [ "Enedis",[3.1572989370478983, 46.962239619550346]],
    [ "Enedis",[3.160281157427938, 46.96302767627495]],
    [ "Enedis",[3.167936067385445, 46.97571147169812]],
    [ "Enedis",[3.1821775772695773, 46.950759875259884]],
    [ "Enedis",[3.1627526402446478, 46.96943781737003]],
    [ "Enedis",[3.159891845886445, 46.96960925994593]],
    [ "Enedis",[3.1623614999999994, 46.9496275]],
    [ "Enedis",[0.19528510913286806, 49.515900797392845]],
    [ "Enedis",[2.3022124940476187, 48.387316291666664]],
    [ "Enedis",[2.2997546932782744, 48.405388623078686]],
    [ "Enedis",[2.303682295597484, 48.39457154297694]],
    [ "Enedis",[4.78847531416523, 50.016846487061535]],
    [ "Enedis",[4.720548873333334, 50.08260476666667]],
    [ "Enedis",[4.722370858960765, 50.08058125945564]],
    [ "Enedis",[4.720997132158589, 50.08750723641703]],
    [ "Enedis",[4.716907626016261, 50.08844681300813]],
    [ "Enedis",[4.723878366947257, 50.085141963005235]],
    [ "Enedis",[4.722169100000001, 50.073188699999996]],
    [ "Enedis",[4.733537017543857, 50.07449643040934]],
    [ "Enedis",[4.731951547263682, 50.076417065920396]],
    [ "Enedis",[4.736078649122807, 50.072839269005854]],
    [ "Enedis",[4.72955901431493, 50.07259378732107]],
    [ "Enedis",[4.848041355599001, 50.131481586008206]],
    [ "Poste électrique de Ligny",[5.331893105470564, 48.680529965517145]],
    [ "Poste Electrique de Tronville",[5.282489967225142, 48.71012760716622]],
    [ "Enedis",[4.737338620761606, 50.10413392070944]],
    [ "Enedis",[1.1974846000000001, 43.70513570000001]],
    [ "Enedis",[-0.3912879106310403, 43.36292029833239]],
    [ "Enedis",[-0.39338350000000033, 43.3619897]],
    [ "Enedis",[4.295275163387001, 49.870187163784536]],
    [ "Frocourt Activités",[6.193651949999999, 48.64164105]],
    [ "Enedis",[3.854028558664702, 49.66865090819834]],
    [ "Enedis",[2.0478686666666666, 48.53158190196078]],
    [ "Enedis",[2.0429673775102777, 48.53115827252801]],
    [ "Enedis",[2.0542485882352937, 48.516515193277314]],
    [ "Enedis",[2.0381235613079016, 48.53030274023615]],
    [ "Enedis",[2.0612910465241034, 48.516579872307815]],
    [ "Enedis",[1.8681910586245778, 48.368316255543036]],
    [ "Enedis",[1.069990148220903, 46.12295005009365]],
    [ "Enedis",[3.3810157227906976, 46.32998052434108]],
    [ "Enedis",[3.3940893652729383, 46.31850825551684]],
    [ "Enedis",[3.398594485921889, 46.316158113533156]],
    [ "Enedis",[3.3833988247753526, 46.31826805819426]],
    [ "Enedis",[3.4250669415734327, 46.295302345586926]],
    [ "Enedis",[3.4090881959304475, 46.319510595042544]],
    [ "Enedis",[3.390953356744704, 46.314287186176145]],
    [ "Enedis",[3.4459100925619834, 46.316363765013776]],
    [ "Enedis",[3.5119203777777783, 46.29428371111111]],
    [ "Enedis",[3.42580725382263, 46.26186682874617]],
    [ "Enedis",[2.507210862176074, 49.20010644577565]],
    [ "Enedis",[6.901111999999999, 47.669504]],
    [ "CA Amaury",[2.087567053692982, 48.740821327380715]],
    [ "Enedis",[4.119265, 49.46749350000001]],
    [ "Enedis",[4.115854050790448, 49.46694320383451]],
    [ "Enedis",[4.110462, 49.46257850000001]],
    [ "Enedis",[2.0464247, 48.777030350000004]],
    [ "Enedis",[2.1592993371086866, 49.41772499711291]],
    [ "Enedis",[0.5271224017178721, 49.82513770036339]],
    [ "Enedis",[0.5525215407527755, 49.82449632385595]],
    [ "Les Rottes",[0.181532, 48.1183335]],
    [ "Enedis",[1.823527999999999, 48.26814200000001]],
    [ "Enedis",[1.8548091884057967, 48.274644826086956]],
    [ "Enedis",[1.8230315172413791, 48.26228666666667]],
    [ "Enedis",[1.2359681542232277, 43.60953595701358]],
    [ "Enedis",[6.478626949999999, 46.33710835]],
    [ "Enedis",[2.7402694999999997, 47.8545145]],
    [ "Enedis",[2.725712999999999, 47.82763]],
    [ "Enedis",[2.736240395322435, 47.84364128315168]],
    [ "Enedis",[2.7235171908045976, 47.82897667126436]],
    [ "Enedis",[2.7410050194205673, 47.85051529003501]],
    [ "Enedis",[2.7419676905343096, 47.84552445972336]],
    [ "Enedis",[2.7263916000448636, 47.82636051076716]],
    [ "Enedis",[2.730459, 47.83264900000001]],
    [ "Enedis",[2.721273, 47.820292]],
    [ "Enedis",[2.74630028029034, 47.84542155164712]],
    [ "Enedis",[2.7175350000000003, 47.823452]],
    [ "Enedis",[2.7446404289741926, 47.853211205378436]],
    [ "RTE",[3.7458690097009604, 48.50654752143987]],
    [ "Enedis",[2.87272625, 50.4961125]],
    [ "Montrevel PCCN",[5.120340721771123, 46.33191952572598]],
    [ "Enedis",[1.3009390000000012, 47.521808500000006]],
    [ "Enedis",[1.313396505168849, 47.53050283206984]],
    [ "Enedis",[1.303075333076924, 47.523939015202934]],
    [ "Enedis",[1.3127199999999997, 47.537449499999994]],
    [ "Enedis",[1.3143715, 47.518134]],
    [ "Enedis",[1.3056580000000007, 47.522876499999995]],
    [ "Enedis",[2.369662286089239, 49.647877938582674]],
    [ "Enedis",[2.432251142149579, 49.63943743536404]],
    [ "Enedis",[2.449981643377001, 49.61438668267831]],
    [ "Enedis",[-0.5077483999999997, 43.63982955]],
    [ "Enedis",[2.3182375000000004, 49.663885]],
    [ "Poste électrique de Ressons-sur-Matz",[2.7450701237515647, 49.53477382186105]],
    [ "RTE",[4.004675362111102, 45.262092361437766]],
    [ "Route de Carhaix",[-3.958822099999999, 48.22412340000001]],
    [ "Enedis",[3.387922838198198, 46.15810999387387]],
    [ "Le presbytère",[-0.18845865893719763, 48.01712385362318]],
    [ "Enedis",[1.4961532587446391, 47.70853551065247]],
    [ "Enedis",[1.3375814541136555, 49.911763796352844]],
    [ "Enedis",[3.6314186547490066, 46.231648821957386]],
    [ "Enedis",[3.6397483672727278, 46.24597706303032]],
    [ "Enedis",[1.0834164204702628, 48.68769969847856]],
    [ "Enedis",[1.0729001484184912, 48.69057740354536]],
    [ "Enedis",[1.073429109118087, 48.68852672645739]],
    [ "Enedis",[1.0746465, 48.693521999999994]],
    [ "Enedis",[1.0781421117021277, 48.69445888297873]],
    [ "Enedis",[1.0752007796803644, 48.69074804794521]],
    [ "Enedis",[1.0832309825345585, 48.69436947438127]],
    [ "Enedis",[1.0752432156267255, 48.69251715958034]],
    [ "Enedis",[1.0794404551817245, 48.68951117014674]],
    [ "Enedis",[6.031374656107004, 46.25136795987328]],
    [ "Flies",[6.0314735000000015, 46.27613399999999]],
    [ "Enedis",[2.319851090412828, 49.58227600784715]],
    [ "Enedis",[2.3590015, 49.633525500000005]],
    [ "Enedis",[3.8744655648351647, 46.176107229670336]],
    [ "Enedis",[3.864123972156818, 46.17875409727629]],
    [ "Enedis",[3.8660552580645167, 46.17630733333333]],
    [ "Enedis",[3.87048668849477, 46.17577098226467]],
    [ "Poste électrique de Linxe",[-1.232457684361079, 43.907467575651225]],
    [ "Enedis",[3.835871029530201, 46.220159343847875]],
    [ "Enedis",[2.6636612006535954, 46.4208057546841]],
    [ "Enedis",[2.381277, 49.4668585]],
    [ "Enedis",[2.3817020716968815, 49.46691597925854]],
    [ "Enedis",[2.355463149235474, 49.59028144587157]],
    [ "Enedis",[2.3803589238294895, 49.62976076499949]],
    [ "Enedis",[2.356618145530145, 49.422096692654186]],
    [ "Enedis",[2.327647, 49.45913049999999]],
    [ "Enedis",[2.621140241213466, 46.534613294857564]],
    [ "Enedis",[2.598760793539325, 46.534123157771546]],
    [ "Enedis",[2.633224, 46.53196095]],
    [ "Enedis",[2.6088635695931486, 46.537194162027134]],
    [ "Enedis",[2.6069878322368423, 46.532307483552636]],
    [ "Enedis",[2.6196400500000006, 46.53741655]],
    [ "Enedis",[2.6113809983686784, 46.53673997553018]],
    [ "Enedis",[2.6040862261072264, 46.534696806102986]],
    [ "Enedis",[2.6070815888487764, 46.534701596871244]],
    [ "Enedis",[2.591271, 46.51843650000001]],
    [ "Enedis",[2.704497160730594, 46.32989132694065]],
    [ "Poste Électrique de Liart",[4.345332510909455, 49.7736884115065]],
    [ "Enedis",[-3.455755, 48.80862450000001]],
    [ "Enedis",[2.5923328244880897, 46.43845722941914]],
    [ "Enedis",[0.1597176443163967, 48.1387128358652]],
    [ "Enedis",[0.16893583474225096, 48.14639895494778]],
    [ "Centre",[0.16598636379729134, 48.14259382203286]],
    [ "Enedis",[1.232494839080459, 46.941036850574704]],
    [ "Enedis",[1.234589181037701, 46.94428595558043]],
    [ "Enedis",[1.1735162332147098, 46.99123566809016]],
    [ "Enedis",[1.1784306265328872, 46.989865392419176]],
    [ "Enedis",[1.1740612602401128, 46.97996893644068]],
    [ "Enedis",[1.1731595110823188, 46.98421107062279]],
    [ "Enedis",[1.1707076617074024, 46.979742006021965]],
    [ "Enedis",[1.1672986659985154, 46.99102715406459]],
    [ "SICAE Oise",[2.472913274666667, 49.45246183093334]],
    [ "Enedis",[4.7144751126272295, 49.77331162031193]],
    [ "Enedis",[3.0731373433032356, 47.26334937170805]],
    [ "Enedis",[3.072234916786227, 47.334690560497364]],
    [ "Enedis",[3.075041476874003, 47.29948801754386]],
    [ "Enedis",[3.155059108486439, 46.237867592300965]],
    [ "poste_1787",[2.5535059652040735, 49.60327762266666]],
    [ "SICAE-Oise",[2.5523402500000003, 49.58892289999999]],
    [ "SICAE Oise",[2.5827373906532416, 49.5868451742835]],
    [ "SICAE Oise",[2.5857254999999997, 49.583312]],
    [ "SICAE Oise",[2.5669934000000003, 49.5622905]],
    [ "Enedis",[2.8407761258204163, 43.182683848205556]],
    [ "Enedis",[2.807960676331604, 47.90347509477084]],
    [ "Enedis",[2.8096033868682797, 47.90171217159947]],
    [ "SICAE-Oise",[2.5352544924546327, 49.59420266446991]],
    [ "SICAE Oise",[2.6064849999999997, 49.600566500000006]],
    [ "SICAE Oise",[2.604075133437991, 49.59417165096808]],
    [ "SICAE Oise",[2.6007534999999993, 49.5976235]],
    [ "poste_1514",[2.6248070000000006, 49.570395999999995]],
    [ "SICAE-Oise",[2.62726185, 49.56661]],
    [ "Enedis",[6.858197800000001, 47.65740435]],
    [ "Enedis",[-1.6075329221965131, 46.73866304329346]],
    [ "Enedis",[7.371134847501029, 47.5947685337152]],
    [ "Enedis",[2.426052462686567, 46.372271282587064]],
    [ "Enedis",[1.8933916000000008, 43.59757555]],
    [ "SICAE-Oise",[2.5088572309711283, 49.58830478915135]],
    [ "Venthon",[6.4034018697269905, 45.693494898150604]],
    [ "Enedis",[2.4035086562402257, 47.07897082316755]],
    [ "Enedis",[-1.6060577500000013, 46.74200524999999]],
    [ "Enedis",[-1.598415310463327, 46.74095452371709]],
    [ "Enedis",[-0.2660606694626099, 48.2757264007295]],
    [ "La Croix Verte",[6.29782685, 46.0626403]],
    [ "réséda",[6.306342416451112, 49.13589115640628]],
    [ "réséda",[6.307985349999999, 49.13473665]],
    [ "réséda",[6.30212575, 49.1352128]],
    [ "réséda",[6.302566523809524, 49.132648333333336]],
    [ "réséda",[6.3109741, 49.1459831]],
    [ "Cairn",[6.3902704, 45.66534380000001]],
    [ "Enedis",[6.155156949999999, 45.918866300000005]],
    [ "Burgaz",[6.130372449999999, 45.9834918]],
    [ "Enedis",[1.3328282451288775, 47.67795862022717]],
    [ "Les Ormeaux",[0.12315850000000002, 48.1531015]],
    [ "Enedis",[4.020728, 43.614752249999995]],
    [ "Enedis",[4.022546958612975, 43.612704214765095]],
    [ "Enedis",[4.020724812596006, 43.61291191244239]],
    [ "Les Bornands",[6.3939910499999995, 45.9696542]],
    [ "Scierie Goy",[6.385818444439005, 45.9621912576273]],
    [ "REThones",[6.389098150000001, 45.955316200000006]],
    [ "Enedis",[6.318596850000001, 46.05312205]],
    [ "Enedis",[0.31589739999999994, 48.196756150000006]],
    [ "Enedis",[0.198962396712133, 48.15273196814734]],
    [ "Enedis",[0.18087949999999997, 48.151863]],
    [ "Bourg de Teillé",[0.1884871494028224, 48.18188283626493]],
    [ "Enedis",[-3.8274752999999992, 48.5644727]],
    [ "Enedis",[-3.8425579346405234, 48.587484287581695]],
    [ "Enedis",[0.12103575, 48.20867375]],
    [ "Enedis",[0.1341387327617005, 48.221236082172204]],
    [ "Enedis",[0.17203649999999893, 48.20298950000001]],
    [ "Enedis",[0.16409899999999986, 48.202195499999995]],
    [ "Enedis",[0.1700043257229822, 48.20125050837137]],
    [ "Enedis",[0.131462, 48.218513]],
    [ "Enedis",[0.3417117402641962, 48.07045159907359]],
    [ "Enedis",[2.7134370081152355, 45.16694874641269]],
    [ "Enedis",[2.588202494797273, 50.80538516110513]],
    [ "RTE",[2.747134387635827, 45.08307996522941]],
    [ "RTE",[6.1067199947633695, 48.6123572276044]],
    [ "Enedis",[5.247535666666666, 48.7349178]],
    [ "Enedis",[2.823438880376344, 47.84726905645161]],
    [ "Enedis",[2.8170151201392923, 47.850370572644614]],
    [ "Enedis",[3.5754984016332596, 47.41186361989606]],
    [ "Enedis",[3.6694554086226012, 47.25325780171184]],
    [ "Enedis",[3.6706825, 47.2918475]],
    [ "Enedis",[6.487993960045204, 48.67712418573264]],
    [ "Enedis",[-0.07983265087123777, 48.10863852109075]],
    [ "Enedis",[3.574268300448431, 47.22912344643746]],
    [ "Enedis",[2.49641597869267, 47.103498712892744]],
    [ "Enedis",[0.9949525337432287, 42.87483160708534]],
    [ "RTE",[6.580144080187749, 45.20416358727011]],
    [ "Les Combes",[6.4169388, 45.9219116]],
    [ "Zone Artisanale St Jean",[6.41949396332268, 45.914672041746584]],
    [ "REThones",[6.418022825953217, 45.91789574259099]],
    [ "Enedis",[6.047397549723757, 46.31162057005525]],
    [ "Poste électrique de Glonville",[6.705460380423164, 48.46323577891129]],
    [ "ZAC du Roulet",[5.59840275041103, 45.34965817833465]],
    [ "Enedis",[0.4847763441454728, 48.075338195601425]],
    [ "Enedis",[-1.8759324829267074, 48.102619214848076]],
    [ "Sous-station SNCF",[6.562430966782784, 45.504321073758675]],
    [ "Poste Électrique de Gironcourt",[5.918815539702952, 48.307543478964405]],
    [ "RTE",[1.3985373513601886, 48.20159641817545]],
    [ "Enedis",[1.2470220395010394, 46.28676766874566]],
    [ "Enedis",[1.511577466315984, 48.061042163436696]],
    [ "Enedis",[1.5271345655913977, 48.073171731182796]],
    [ "RTE",[-0.39194700123790877, 47.57674276965751]],
    [ "Enedis",[0.25917551924037985, 47.69076456471765]],
    [ "Enedis",[0.260943, 47.678973]],
    [ "Enedis",[0.2674314249830281, 47.68935906969902]],
    [ "RTE",[1.9947933717417563, 43.76815863817751]],
    [ "Poste source de Lorris",[2.526333425514237, 47.88729472187756]],
    [ "Enedis",[0.32103650000000006, 47.669191999999995]],
    [ "Enedis",[0.304129, 47.654204]],
    [ "Enedis",[0.31728952445328085, 47.66847378184229]],
    [ "Enedis",[0.3017703304347817, 47.6715793884058]],
    [ "Enedis",[6.573724643077583, 46.02759351552982]],
    [ "Enedis",[1.9176218059277306, 48.75189345026391]],
    [ "Enedis",[1.9212274999999999, 48.751462499999995]],
    [ "Enedis",[1.9086872916155406, 48.73737003190185]],
    [ "Enedis",[1.9190036650710074, 48.743116485240144]],
    [ "Enedis",[1.9364860000000002, 48.758488500000006]],
    [ "Enedis",[1.926511264770241, 48.745083880926344]],
    [ "Enedis",[1.9308000000000003, 48.75434900000001]],
    [ "Enedis",[1.9092826796368354, 48.73567849027237]],
    [ "Enedis",[1.9324311637913936, 48.756790505651395]],
    [ "Enedis",[1.9166081675931645, 48.74297699526457]],
    [ "Enedis",[1.921194383561644, 48.74923874885845]],
    [ "Enedis",[1.9345053333333335, 48.75291949645391]],
    [ "Enedis",[1.9253357476555844, 48.75515615515771]],
    [ "Enedis",[1.9254959999999999, 48.75309850000001]],
    [ "Enedis",[1.914157328093511, 48.740412755743655]],
    [ "Enedis",[1.9150459609250394, 48.74128511483253]],
    [ "Enedis",[2.467981695276806, 48.56548102909457]],
    [ "Enedis",[0.6609282878172583, 42.977218601353634]],
    [ "Enedis",[2.866573749174917, 47.42409503300329]],
    [ "Enedis",[4.845048499999999, 46.8066593]],
    [ "Enedis",[4.84866630499627, 45.05503002087995]],
    [ "Enedis",[2.513745137163332, 48.6147105830976]],
    [ "Enedis",[2.51285686756859, 48.62851734909523]],
    [ "Enedis",[0.4255781484047019, 47.6326785358942]],
    [ "Enedis",[3.984678263157894, 47.01482903859648]],
    [ "Enedis",[2.1187777224335287, 48.74611129081479]],
    [ "Enedis",[5.9027576, 49.0483836]],
    [ "Poste électrique de Garennes",[1.882888677742947, 50.962730385998626]],
    [ "RTE",[3.040964066974335, 49.87861663395183]],
    [ "RTE",[1.8917566572564184, 50.96895392390098]],
    [ "Castor",[3.089712739805016, 49.94710124895843]],
    [ "Pontcarré",[2.7043313333441183, 48.800087872263475]],
    [ "Balme4A",[6.609669593754313, 46.0396145143288]],
    [ "Fabienne",[6.6432429709509595, 45.89243550556959]],
    [ "Fresney Haut",[6.677653350000003, 45.89073740000001]],
    [ "Intage 2",[6.657130401373095, 45.88574248369305]],
    [ "La Savoyarde",[6.6450147, 45.885132649999996]],
    [ "Les Amerands",[6.691718151708036, 45.896636419683794]],
    [ "Mariaz",[6.617885378480255, 46.02828955717813]],
    [ "Prairie",[6.654679145019898, 45.89395957704813]],
    [ "Servoz",[6.650051168668021, 45.88402198365216]],
    [ "Tournoir",[6.617839358567707, 46.02829185017819]],
    [ "ZI Nord",[6.6327459499999994, 45.95724479999999]],
    [ "Enedis",[6.613318499999999, 46.023775]],
    [ "GESallanches",[6.6325348511173505, 45.959612282958346]],
    [ "374134990",[6.656016249999999, 45.9295388]],
    [ "Enedis",[6.627031931100726, 46.009674710784]],
    [ "Enedis",[6.6323268509364794, 45.98769371453704]],
    [ "Poste électrique de Fromainville",[2.1695761355601664, 48.97573447801925]],
    [ "ETTO",[2.1368304, 48.70267199999999]],
    [ "Geispolsheim",[7.686811247865841, 48.524661795571845]],
    [ "Curtenay",[6.635190649999999, 46.3943871]],
    [ "Cutafour",[6.108407850000001, 46.0945869]],
    [ "Enedis",[6.264741600000001, 46.31519374999999]],
    [ "Enedis",[6.325542005600509, 46.241415369793955]],
    [ "Enedis",[6.2684208, 46.323072999999994]],
    [ "Enedis",[6.3191028295598235, 46.23211966626136]],
    [ "Enedis",[1.418424, 49.06268695]],
    [ "Enedis",[-1.171234590720307, 45.69441782851661]],
    [ "réséda",[6.149958330085377, 49.2760801699094]],
    [ "Enedis",[1.2142253019356848, 44.88850968997204]],
    [ "Enedis",[-0.7861174555444006, 44.50024978819035]],
    [ "Enedis",[-0.8176299805871814, 44.52642351648218]],
    [ "Enedis",[-0.8196066500000001, 44.527877700000005]],
    [ "Enedis",[-0.7800568999999999, 44.494862]],
    [ "Enedis",[-0.7886219570208355, 44.48853812918836]],
    [ "Enedis",[-0.7842075890179103, 44.49978981957265]],
    [ "Enedis",[-0.7878436067976996, 44.50158301260789]],
    [ "Enedis",[-0.7825117728792871, 44.491451559343346]],
    [ "Enedis",[-0.7810993823066733, 44.50459105288546]],
    [ "Enedis",[-0.7876263745066355, 44.5018123906076]],
    [ "Enedis",[-0.78665495, 44.49383699999999]],
    [ "Enedis",[-0.768010380185273, 44.5083661819909]],
    [ "Enedis",[-0.7932769295248745, 44.515294705250234]],
    [ "Enedis",[-0.7811114947030938, 44.4974262586699]],
    [ "Enedis",[-0.7831566738650716, 44.488016108741505]],
    [ "Enedis",[-0.8164216429837927, 44.52430773174251]],
    [ "Enedis",[-0.789766, 44.493720599999996]],
    [ "Enedis",[-0.7944695500000004, 44.487154049999994]],
    [ "Enedis",[-0.7920881, 44.49372814999999]],
    [ "Enedis",[-0.7864710500000002, 44.508400200000004]],
    [ "Enedis",[-0.7799087000000002, 44.513322200000005]],
    [ "Enedis",[-0.8154949, 44.529582950000005]],
    [ "Enedis",[-0.7880697807728527, 44.5150040021466]],
    [ "Enedis",[-0.7839772000000008, 44.509692099999995]],
    [ "Enedis",[-0.7923648337108511, 44.48516897270997]],
    [ "Enedis",[4.896332900000001, 44.03412565000001]],
    [ "Enedis",[1.9248716368381784, 47.84059214839671]],
    [ "Poste source de Borny",[6.247030098871267, 49.114366636410516]],
    [ "Enedis",[6.605074200000001, 45.90818715]],
    [ "Enedis",[6.343808072595428, 46.04688084320664]],
    [ "Enedis",[6.347613321860415, 46.04655489612906]],
    [ "Enedis",[6.615729350000002, 45.913720350000006]],
    [ "Enedis",[6.352445738731439, 46.0453148743826]],
    [ "Enedis",[5.358897645497729, 43.35005400848581]],
    [ "Enedis",[6.159769965988471, 48.671359633299424]],
    [ "Enedis",[-0.2885581796073135, 49.15425639177075]],
    [ "RTE",[1.3775272122507638, 46.46198851374129]],
    [ "Moulins",[6.320274768401287, 46.366119383301836]],
    [ "Enedis",[6.125479059369488, 45.9268822166372]],
    [ "Enedis",[2.368101079990842, 48.674750366180106]],
    [ "Enedis",[6.775393699999998, 47.616241450000004]],
    [ "Poste électrique de Couffrau",[2.7960816860520916, 43.730259640738936]],
    [ "Enedis",[1.5158097892863505, 43.54183266826838]],
    [ "Enedis",[1.5157534999999995, 43.5350005]],
    [ "Enedis",[1.500135, 43.550622000000004]],
    [ "Enedis",[0.47078746161616125, 47.65104633333334]],
    [ "Enedis",[0.47449409666666703, 47.664337]],
    [ "Enedis",[0.48154893631014206, 47.66679817646245]],
    [ "Enedis",[0.4711815744680854, 47.664024838770686]],
    [ "Enedis",[0.4709801526881724, 47.678334541935484]],
    [ "Enedis",[0.44728083687943204, 47.66165293971631]],
    [ "Enedis",[5.915569597796664, 45.591173368250494]],
    [ "Enedis",[5.919116302040131, 45.26825883404702]],
    [ "poste_4785",[2.938430807799443, 49.4148240742804]],
    [ "Enedis",[4.999832, 43.58905849999999]],
    [ "Enedis",[2.7608767010721715, 50.43312169915186]],
    [ "Enedis",[0.5781499660145756, 48.14867152888377]],
    [ "Enedis",[0.5667148244241633, 48.13376713733159]],
    [ "Enedis",[0.5453265, 48.135702499999994]],
    [ "Enedis",[0.5703581507161458, 48.13151344303386]],
    [ "Enedis",[2.712653064768286, 49.81514446621999]],
    [ "Enedis",[2.691261987641951, 49.811020999666]],
    [ "Enedis",[2.6978975108225094, 49.810118865800874]],
    [ "Enedis",[2.6929796652267814, 49.81284432325413]],
    [ "Enedis",[2.712697717320261, 49.812424496732035]],
    [ "Enedis",[2.696968728711378, 49.81315484049234]],
    [ "Enedis",[2.7067488565203863, 49.813200598091086]],
    [ "Enedis",[2.7027806285182603, 49.810775349150965]],
    [ "Enedis",[2.702275, 49.81244649999999]],
    [ "Enedis",[2.706111134232498, 49.819085680796405]],
    [ "Enedis",[2.7008969990776612, 49.81474776314333]],
    [ "Enedis",[2.707829899590809, 49.81663432640856]],
    [ "Enedis",[2.7078731834957783, 49.82671913996102]],
    [ "Enedis",[2.7118893529411765, 49.82551770588235]],
    [ "Enedis",[2.712162726170463, 49.822950972804]],
    [ "Enedis",[1.4251826421435065, 49.262698957311535]],
    [ "Enedis",[3.3940383078259044, 50.01384966808955]],
    [ "Enedis",[1.9863528031264157, 48.70528615352666]],
    [ "Enedis",[1.9827033329637838, 48.70248499113083]],
    [ "Enedis",[1.9795721650031688, 48.69942151259505]],
    [ "Enedis",[1.763298516666667, 48.716313601515154]],
    [ "Enedis",[1.7537718074657183, 48.722081996935835]],
    [ "Enedis",[1.7697331275862078, 48.715875590421454]],
    [ "Enedis",[2.810861055056589, 43.10445634259311]],
    [ "Enedis",[2.8179759039548022, 43.07064726553672]],
    [ "Enedis",[2.7706073175853025, 43.102515708661414]],
    [ "Enedis",[2.7705084456587734, 43.10254011991499]],
    [ "Enedis",[0.6004564330708663, 48.11085680052493]],
    [ "Enedis",[0.580377, 48.100926]],
    [ "Enedis",[3.166840202557396, 46.58242745277535]],
    [ "Enedis",[3.506164217228464, 50.01878419787764]],
    [ "Poste électrique de la Possession",[55.32860119102124, -20.930816971686873]],
    [ "Enedis",[5.190964845611848, 43.431349044329004]],
    [ "Enedis",[0.5915249493670882, 48.066477535864976]],
    [ "Enedis",[0.5914453114819226, 48.06651343623361]],
    [ "Enedis",[1.9197887546058878, 43.529544098100665]],
    [ "Enedis",[4.499798517490197, 49.05058810088018]],
    [ "Enedis",[1.3620613838383837, 43.07941021212122]],
    [ "Enedis",[1.3749276777198365, 43.07895185583191]],
    [ "Enedis",[1.361112995904256, 43.082852126800624]],
    [ "Enedis",[1.3552908600647633, 43.066636633871724]],
    [ "Gabre",[1.4177877323720665, 43.07452100105739]],
    [ "Enedis",[3.97152675, 50.00398135000001]],
    [ "SRD",[0.3611585350353068, 46.50326781459352]],
    [ "SRD",[0.355293, 46.507957499999996]],
    [ "Enedis",[3.9512918111753375, 49.961363971098265]],
    [ "Enedis",[3.948775030060606, 49.94863963272728]],
    [ "Enedis",[2.8302824924391836, 49.8964413425378]],
    [ "Enedis",[2.8554316132930513, 49.88236670191339]],
    [ "Enedis",[-3.3590480000000005, 48.8110105]],
    [ "Enedis",[1.3738603999999999, 43.94004144999999]],
    [ "Enedis",[1.371825704699063, 43.942151534433265]],
    [ "Enedis",[2.767909351568198, 50.436956738876724]],
    [ "Poste électrique de Commercy",[5.582849230993507, 48.77079360142921]],
    [ "Enedis",[2.971070212864027, 43.286272158809965]],
    [ "RTE",[-4.0122943978398755, 48.66167676861096]],
    [ "Poste électrique de Kerlouan",[-4.385053570300586, 48.64014008821312]],
    [ "Enedis",[0.5879967500000001, 48.17305595]],
    [ "Enedis",[0.5844022814026795, 48.1734078174941]],
    [ "Enedis",[0.584823307217695, 48.17074490783857]],
    [ "Poste électrique de La Capelle",[3.932115350000001, 49.974300150000005]],
    [ "Enedis",[3.914041533333333, 49.96893227660819]],
    [ "Enedis",[3.9161569999999997, 49.968624999999996]],
    [ "Enedis",[3.9180732764166035, 49.9765918180517]],
    [ "Enedis",[3.921430024277737, 49.978378613012865]],
    [ "Enedis",[3.9197135031446546, 49.97993338639936]],
    [ "Enedis",[3.914338121827411, 49.97582969373943]],
    [ "Enedis",[1.9306111033519555, 43.6775580744879]],
    [ "Enedis",[1.9191898331918857, 43.67121975061508]],
    [ "Enedis",[2.8288461500000004, 43.302488749999995]],
    [ "Enedis",[4.056650657990574, 49.95903489492287]],
    [ "Enedis",[1.5065653330692024, 49.77365699471736]],
    [ "RTE",[1.7399786564262019, 50.60999967510866]],
    [ "Enedis",[-1.1207822056737602, 43.70849347299509]],
    [ "Enedis",[-1.110314, 43.706441999999996]],
    [ "Enedis",[-1.1184815000000001, 43.711786]],
    [ "Enedis",[-1.1114069999999998, 43.71016899999999]],
    [ "Enedis",[-1.112380820164609, 43.70317283362382]],
    [ "Enedis",[-1.1160705036653729, 43.70996783527382]],
    [ "Enedis",[-1.106795, 43.70480800000001]],
    [ "Enedis",[1.4190732461059177, 49.71669563551402]],
    [ "Enedis",[1.4181824654953001, 48.951524213000724]],
    [ "Single Pied",[-1.39188881400276, 46.18053668603979]],
    [ "Zone Artisanale",[-1.3789583055736003, 46.18007337966743]],
    [ "Enedis",[-1.3784844500000002, 46.17555895]],
    [ "Enedis",[0.7051070589595373, 48.17109028131021]],
    [ "Enedis",[0.7078278172860363, 48.169156017736476]],
    [ "Enedis",[0.7062859, 48.167906900000006]],
    [ "Enedis",[4.019597869793926, 49.885662143944316]],
    [ "Enedis",[4.023654, 49.89888316666667]],
    [ "Enedis",[4.019570770767614, 49.89537493375395]],
    [ "SNCF Réseau",[0.6093273058476173, 47.37360767972224]],
    [ "RTE",[2.3557320104867494, 50.387187609544334]],
    [ "Enedis",[4.026370678377982, 49.930347067925624]],
    [ "Enedis",[0.7051894730496457, 48.119243673049645]],
    [ "Enedis",[6.0820864166002675, 46.100424237583006]],
    [ "Enedis",[6.183520595105991, 46.157882149138764]],
    [ "Structure SARL",[6.0801648046097965, 46.11383445460187]],
    [ "Poste électrique de Drulingen",[7.203487347018111, 48.858905271797]],
    [ "Poste électrique de Sarre Union",[7.0598764298670975, 48.9338655430925]],
    [ "Enedis",[1.2659618221604083, 49.81529144135372]],
    [ "Enedis",[2.487556759553968, 50.35251444819747]],
    [ "Enedis",[0.65239196969697, 48.08922344444444]],
    [ "Enedis",[2.7783109257786616, 50.296272243207426]],
    [ "Enedis",[6.057163635041113, 48.31028018722328]],
    [ "Enedis",[0.7930465000000007, 48.168515600000006]],
    [ "Enedis",[0.872985265060241, 48.15638820481928]],
    [ "Enedis",[6.1021224, 46.01733980000001]],
    [ "Cret de la Cote",[6.1045035, 46.03908699999999]],
    [ "Enedis",[1.2304144999999997, 49.8396395]],
    [ "Enedis",[0.7139479620349278, 48.130527309035685]],
    [ "Enedis",[0.7368515, 48.15096950000001]],
    [ "1681 Ave Landiers",[5.896770300000001, 45.59708960000001]],
    [ "Houille Blanche",[5.890170599999999, 45.58853315]],
    [ "Mager",[5.887673881674397, 45.57732009176995]],
    [ "Parc des Expositions",[5.897289280900646, 45.581275540812086]],
    [ "Enedis",[1.1877139238657348, 49.866889762113544]],
    [ "Jouvet",[2.5027778798638005, 48.6949481834261]],
    [ "Enedis",[2.809933244414046, 49.88423246739627]],
    [ "Enedis",[2.8062376715328465, 49.88648998864558]],
    [ "Enedis",[2.823671516044532, 49.87497479698755]],
    [ "Enedis",[2.8239164999999993, 49.87314925]],
    [ "Enedis",[2.8293782473118285, 49.87435655555555]],
    [ "Enedis",[2.8186482509803925, 49.875011964705884]],
    [ "Enedis",[2.83252955751938, 49.87393048713177]],
    [ "RTE",[0.88275439448827, 47.757609033797536]],
    [ "Enedis",[2.8491235, 49.864357999999996]],
    [ "Enedis",[2.8441505, 49.8664045]],
    [ "Enedis",[6.0760725, 45.75215155]],
    [ "Enedis",[6.034649998632332, 45.76747233311668]],
    [ "Enedis",[1.3995790459746484, 49.15689335738267]],
    [ "Enedis",[2.7121403994910933, 49.86025488295166]],
    [ "Enedis",[1.3669508471491227, 49.18141645241227]],
    [ "Enedis",[2.8015697951807224, 49.80775206425703]],
    [ "Enedis",[2.799788761265581, 49.80665156503675]],
    [ "Enedis",[2.8012830180296224, 49.810234028546894]],
    [ "Enedis",[2.80253566878531, 49.81106300882769]],
    [ "Enedis",[2.80271473150736, 49.81312488224334]],
    [ "Enedis",[2.764782680553372, 47.97391376967982]],
    [ "Enedis",[2.7847543008840145, 47.9780457157577]],
    [ "Enedis",[2.8032286936416186, 49.81578873217726]],
    [ "Enedis",[2.802722889875667, 49.816879767613976]],
    [ "Enedis",[2.7991830198886243, 49.81799683074781]],
    [ "Enedis",[2.806885521870287, 49.81502948164907]],
    [ "Enedis",[2.8063058195247264, 49.81642793770071]],
    [ "Enedis",[2.8004325393186877, 49.821089694046485]],
    [ "Enedis",[2.8098329973045812, 49.81712974573225]],
    [ "Enedis",[1.4472583255118463, 48.056227760984584]],
    [ "Enedis",[1.3855942976858693, 48.04318055790251]],
    [ "Enedis",[1.413278500823045, 48.05961670226337]],
    [ "Enedis",[2.8309564757182417, 49.828104255436294]],
    [ "Enedis",[0.9974297310686486, 43.03957884713376]],
    [ "Enedis",[1.763553900000001, 48.34301765]],
    [ "Rhododendrons",[6.286562204337546, 46.0901337692363]],
    [ "Enedis",[1.732446481481482, 48.112189370370366]],
    [ "RTE",[1.950756986088573, 48.19554600772896]],
    [ "Enedis",[1.1295242261380316, 49.829006284875184]],
    [ "Enedis",[3.5369734999999998, 50.346436499999996]],
    [ "Enedis",[2.7405405, 50.313486]],
    [ "Poste électrique de Sadirac",[-0.37788180618595185, 44.785941551835116]],
    [ "Enedis",[1.0832975000000002, 49.837475999999995]],
    [ "Poste électrique de Metzange",[6.09965364526939, 49.36137952375618]],
    [ "Enedis",[0.5940974898828544, 44.167962797076186]],
    [ "Enedis",[0.5890164343287914, 44.168058961728036]],
    [ "Enedis",[0.582280730041293, 44.16364420488966]],
    [ "Enedis",[0.5727025000000004, 44.165580500000004]],
    [ "Enedis",[0.5725881392156864, 44.170357125490206]],
    [ "Enedis",[0.5750658605442179, 44.17066228717202]],
    [ "Enedis",[0.5900686372517785, 44.16817323404482]],
    [ "Enedis",[0.5877141903196359, 44.16963848146118]],
    [ "Enedis",[0.5861853104056443, 44.163204719576726]],
    [ "Enedis",[0.5754034434303099, 44.16639421353869]],
    [ "RTE",[1.7693150703659029, 48.01990483755529]],
    [ "Enedis",[2.0422721260800327, 47.98998126924369]],
    [ "Enedis",[2.0336750031565027, 47.98900800522228]],
    [ "Enedis",[2.036700057485526, 47.98693375062035]],
    [ "Enedis",[2.0449819964094273, 47.985920504058534]],
    [ "Enedis",[2.0415470954573363, 47.989082752302025]],
    [ "Enedis",[2.0281379999999998, 47.98577500000001]],
    [ "Enedis",[2.026946086506818, 47.9824332505877]],
    [ "Enedis",[2.0884287530222, 48.000720299039955]],
    [ "Pommier de pepin",[2.0922675, 48.008097]],
    [ "Enedis",[2.0907626376817983, 47.999233656339065]],
    [ "Enedis",[2.079877347676174, 47.99424274277673]],
    [ "Enedis",[2.089109076327599, 48.00457483103964]],
    [ "Enedis",[2.0835481744165296, 47.99917483863948]],
    [ "Enedis",[2.0813748795632923, 47.995218558250514]],
    [ "Enedis",[2.0969376559139783, 48.00294045806452]],
    [ "Ane vert",[2.086930519536903, 48.00099905209841]],
    [ "Enedis",[2.19968264192808, 50.01116673425147]],
    [ "Enedis",[3.9509401623931617, 49.41788399430199]],
    [ "Enedis",[2.956698236326109, 49.10556051062951]],
    [ "Enedis",[2.9555100333333337, 49.101709763725495]],
    [ "Enedis",[2.958989754578754, 49.10197731355312]],
    [ "Enedis",[1.15755284089284, 43.12126295177311]],
    [ "Enedis",[1.34878, 49.159422000000006]],
    [ "Enedis",[1.337390749999999, 49.156893249999996]],
    [ "Enedis",[1.3471414999999998, 49.15437175]],
    [ "Enedis",[1.3410667500000002, 49.15822175]],
    [ "Enedis",[1.3353057500000003, 49.16023400000001]],
    [ "Enedis",[1.3234575999999998, 49.157491699999994]],
    [ "Enedis",[1.3405525, 49.1637385]],
    [ "Enedis",[1.3212769000000013, 49.15786115000001]],
    [ "Enedis",[1.34874475, 49.1557605]],
    [ "Enedis",[1.3477441457808026, 49.16585893210145]],
    [ "Stade",[1.34346275, 49.16470100000001]],
    [ "Enedis",[1.3373667499999986, 49.161861249999994]],
    [ "Enedis",[2.307319, 49.8378075]],
    [ "Enedis",[2.3166060000000006, 49.8373285]],
    [ "Enedis",[2.7394571179549114, 48.03975686664655]],
    [ "Enedis",[2.7431319999999997, 48.04214499999999]],
    [ "Enedis",[4.501555500000001, 47.310923499999994]],
    [ "Poste ERDF ZAC de la Roche",[4.745744515129913, 45.61850138134439]],
    [ "Enedis",[-1.1311745350353068, 43.61380318540649]],
    [ "Enedis",[-0.8953050999999999, 43.656826699999996]],
    [ "Enedis",[-0.9074219464688816, 43.647973317161835]],
    [ "Enedis",[-0.9043271876120091, 43.66035042050725]],
    [ "Enedis",[6.274792756141606, 46.793002440508864]],
    [ "Enedis",[1.8795810000000002, 47.861732]],
    [ "Enedis",[-1.4508428569348333, 46.60751683108513]],
    [ "Enedis",[1.1082914910297814, 49.79197790043057]],
    [ "Enedis",[1.3206247500000001, 43.1138695]],
    [ "Enedis",[2.7700385, 49.87435000000001]],
    [ "Enedis",[2.775717439441118, 49.874257755049896]],
    [ "Enedis",[4.259843825498576, 49.55618361443495]],
    [ "Enedis",[-1.8029353902114722, 46.497054232384684]],
    [ "Enedis",[2.310706, 49.884724500000004]],
    [ "Enedis",[4.35954675, 47.26057925]],
    [ "Ritchie Bros",[1.3193755038915622, 49.13645831674038]],
    [ "Gendarmerie",[1.3170185193715407, 49.138752870410045]],
    [ "Enedis",[1.3289211040773026, 49.14694916536226]],
    [ "Enedis",[4.152120808906873, 47.34151530568516]],
    [ "Enedis",[1.1209158780487798, 49.177129747967484]],
    [ "Enedis",[-1.3527375983606558, 43.84048079121129]],
    [ "Enedis",[-1.3595665000000003, 43.842811]],
    [ "Enedis",[-1.353143492953757, 43.84282324906168]],
    [ "Enedis",[-1.3578378670360103, 43.84367569621422]],
    [ "Enedis",[4.845022678676159, 45.06722231246247]],
    [ "Enedis",[4.30138299971875, 47.28209058762501]],
    [ "Enedis",[2.8865483312994353, 49.848586510508476]],
    [ "Enedis",[2.8690123800813008, 49.81182822425474]],
    [ "Super U Parunis",[-1.6926486849183489, 48.09281531988472]],
    [ "Enedis",[2.7909637514411667, 49.86373634893184]],
    [ "Enedis",[2.790833018552318, 49.8670887513982]],
    [ "Enedis",[2.800363445698667, 49.86142534729057]],
    [ "Enedis",[5.509231898312607, 47.11030947688694]],
    [ "Chaparon",[6.204595500000001, 45.80070705]],
    [ "Chevilly",[6.203904349999999, 45.78640064999999]],
    [ "La Combe",[6.25300173237529, 45.76696125133535]],
    [ "Villard",[6.249910534250963, 45.7655540982071]],
    [ "Campings",[6.180558742721106, 45.83038258488058]],
    [ "Le Jourdy",[6.210128579963212, 45.76725058713929]],
    [ "Enedis",[-0.9619966999999993, 44.328333050000005]],
    [ "Enedis",[4.005556151064437, 43.622340829435956]],
    [ "Enedis",[2.792135, 48.379548]],
    [ "Enedis",[2.787373999999999, 48.38045399999999]],
    [ "Enedis",[2.799684077192982, 48.373861224880386]],
    [ "Enedis",[2.7807320209424082, 48.385132834205926]],
    [ "Enedis",[2.792411516862745, 48.37310016470589]],
    [ "Enedis",[2.8091480457331444, 48.37929989580387]],
    [ "Enedis",[2.7794537835867015, 48.38375459300456]],
    [ "Enedis",[2.78639327363184, 48.388805344941964]],
    [ "Enedis",[2.793121348324714, 48.37604144651825]],
    [ "Enedis",[2.784380265331355, 48.3779280380811]],
    [ "Enedis",[2.8055948486601117, 48.37588366840177]],
    [ "Enedis",[2.789047333333334, 48.382373666666666]],
    [ "Enedis",[2.8139025891472866, 48.37716134108527]],
    [ "Enedis",[1.4584017012588706, 43.58264869140456]],
    [ "Enedis",[2.703265581602374, 48.237005268051426]],
    [ "Enedis",[2.7022567390355205, 48.24067131401732]],
    [ "Enedis",[3.1888265000000007, 45.71131700000001]],
    [ "Enedis",[3.1925775412956168, 45.71244880932425]],
    [ "Enedis",[-1.9614171714374535, 48.01147212066167]],
    [ "Lot RTE",[6.293676, 46.0833606]],
    [ "Ornex Bas",[6.250026225163632, 46.10706665171897]],
    [ "Enedis",[1.505011, 43.568464450000015]],
    [ "Enedis",[2.694628294232649, 48.28020166797004]],
    [ "Enedis",[2.70003942358741, 48.26095573720137]],
    [ "Enedis",[2.692744089708808, 48.274105292819684]],
    [ "Enedis",[2.702399991069574, 48.261363019106966]],
    [ "Enedis",[2.7101802283464567, 48.27160412335958]],
    [ "Enedis",[2.7000954999999998, 48.265792]],
    [ "Enedis",[2.700320305555555, 48.27621488888889]],
    [ "Enedis",[2.7044221721597514, 48.27252033708156]],
    [ "Enedis",[2.7179651190476206, 48.26400033333334]],
    [ "Enedis",[2.6978989483568054, 48.27301480229525]],
    [ "Enedis",[2.7007069103061667, 48.2712292815869]],
    [ "Enedis",[2.712585609289617, 48.26662467486338]],
    [ "Enedis",[2.689669153579769, 48.27091767230411]],
    [ "Enedis",[2.6902238230879503, 48.26713743966608]],
    [ "Enedis",[2.7169608608317053, 48.26519403095605]],
    [ "Enedis",[2.6995448526043235, 48.27913664963649]],
    [ "Enedis",[2.7108366716679173, 48.26474636934233]],
    [ "Enedis",[2.7140494999999984, 48.2701275]],
    [ "Enedis",[2.7191123087719293, 48.26137383891547]],
    [ "Enedis",[2.696452229018211, 48.26430662965163]],
    [ "Enedis",[2.7018723768636854, 48.257534459797654]],
    [ "Enedis",[2.6949910000000004, 48.270079]],
    [ "Enedis",[2.704625846057572, 48.267974836045056]],
    [ "Enedis",[2.710934847953216, 48.26654521052632]],
    [ "Enedis",[2.7057580000000003, 48.25594650000001]],
    [ "Enedis",[2.695207756773787, 48.27878970478893]],
    [ "Enedis",[2.6917098125894126, 48.27028646781116]],
    [ "Enedis",[4.311043894990674, 45.56224545611061]],
    [ "Enedis",[3.270398161821691, 46.08261437508009]],
    [ "Enedis",[3.204103006590452, 46.097280039224295]],
    [ "Enedis",[3.2005539184244722, 46.09813085591555]],
    [ "Enedis",[3.2043145790658696, 46.08766672093784]],
    [ "Enedis",[3.2024469347774493, 46.08245516193542]],
    [ "Enedis",[3.2016270748632714, 46.106150890126735]],
    [ "Enedis",[3.1986804240821396, 46.09477674310715]],
    [ "Enedis",[3.1333082553754457, 46.10007392087997]],
    [ "Enedis",[3.1973547165192744, 46.09593106640001]],
    [ "Enedis",[3.1419507363548314, 46.10222378307724]],
    [ "Enedis",[3.1935216721728388, 46.09968411733806]],
    [ "Enedis",[3.1968516483784355, 46.1033999932113]],
    [ "Enedis",[3.201835020046744, 46.088370420200384]],
    [ "Enedis",[3.1966777340402324, 46.09837543372558]],
    [ "Enedis",[3.206960334976894, 46.095875149499356]],
    [ "Enedis",[-4.471563115279145, 48.410634496783075]],
    [ "Enedis",[-3.5614191440005194, 48.77204318442514]],
    [ "Enedis",[-4.32703425, 48.613200049999996]],
    [ "Enedis",[2.3685555960485947, 47.10877011691979]],
    [ "Enedis",[2.359649426054504, 47.11413038568643]],
    [ "Enedis",[-1.8082486320913767, 46.51840965824521]],
    [ "Enedis",[2.5509931, 50.779840899999996]],
    [ "Enedis",[3.181146163361662, 49.09420492067989]],
    [ "Enedis",[6.023996279339191, 45.81575834129387]],
    [ "REThones",[6.3688600926963765, 45.79926798397526]],
    [ "REThones",[6.373400196502311, 45.80970525200075]],
    [ "Enedis",[3.5332806842798345, 43.59243995251029]],
    [ "REThones",[6.334048799999999, 45.826760400000005]],
    [ "Thuet",[6.4402471, 46.06385545000001]],
    [ "Enedis",[6.076432635206499, 46.09464472352387]],
    [ "Enedis",[6.46020355, 46.06781225]],
    [ "Enedis",[6.122805500000001, 46.139776499999996]],
    [ "Enedis",[3.8976410499999985, 43.61720505]],
    [ "RTE",[1.4382809912675902, 43.59040894005042]],
    [ "Enedis",[4.812712463632828, 48.79138562258917]],
    [ "Enedis",[-0.7814072161064239, 44.30600974661268]],
    [ "Enedis",[-0.7295913410256409, 44.33717565384615]],
    [ "SNCF Réseau",[5.100727516443198, 45.55442836951327]],
    [ "Enedis",[-0.7869684137254901, 44.00028184313725]],
    [ "Enedis",[-0.7374462555555561, 43.97512223888889]],
    [ "Enedis",[-0.7387399749170533, 43.97808422119]],
    [ "Enedis",[-0.7413874126984116, 43.969722751322756]],
    [ "Enedis",[0.9514586729997886, 49.81423748363944]],
    [ "Enedis",[3.139797195758564, 46.14709202610114]],
    [ "Enedis",[3.1892384124101403, 46.123890912599315]],
    [ "Enedis",[6.225244, 48.747876999999995]],
    [ "Enedis",[6.22537586512524, 48.75106384007708]],
    [ "Enedis",[6.233802761531767, 48.750553518450815]],
    [ "Enedis",[1.8399372356202357, 48.98965404539154]],
    [ "Enedis",[1.837344716666665, 48.990756025925926]],
    [ "Enedis",[1.85214771654305, 48.993122436095874]],
    [ "Enedis",[1.8479996417013402, 48.989714437355524]],
    [ "Enedis",[1.8364665134899916, 48.98710893733682]],
    [ "Apremont",[1.8599156762028612, 48.99401566363242]],
    [ "Enedis",[1.8588310766096163, 48.991955431132844]],
    [ "Enedis",[1.8530058841379315, 48.99054622789272]],
    [ "Enedis",[1.8452777733860342, 48.994072944664026]],
    [ "Enedis",[-2.9567748000000007, 48.0499293]],
    [ "Col des Aravis",[6.464974282771106, 45.87190250304196]],
    [ "Les Avanays",[6.515507503979748, 45.87370353345841]],
    [ "Sous le Freux",[6.481909126171033, 45.86935089585929]],
    [ "REThones",[6.52734835, 45.880178300000004]],
    [ "Enedis",[-2.950942712854758, 48.05746522927102]],
    [ "Camping",[4.07069923278189, 44.82386217463903]],
    [ "Saint Memmie",[4.498345293871903, 48.98055177163724]],
    [ "Cimetière",[6.094514819877107, 49.31564509270014]],
    [ "Enedis",[1.3506907889678414, 43.54008987232602]],
    [ "Enedis",[0.6537445, 47.515344999999996]],
    [ "Poste électrique de Bogny",[4.749548554619157, 49.86069968280932]],
    [ "Lot Boulbene",[1.2715665821482305, 43.85710319066308]],
    [ "Enedis",[5.255911151308384, 49.637267179134106]],
    [ "Poste électrique de La Mal Campée",[4.6741245855289915, 49.788576336178835]],
    [ "Enedis",[4.991175722798816, 49.64858274520552]],
    [ "Enedis",[6.8417620096196865, 47.627901866666654]],
    [ "Love",[4.739991520629061, 45.605543009431955]],
    [ "Enedis",[-1.6285839690834925, 49.6452029762798]],
    [ "Enedis",[4.4118735, 48.881066499999996]],
    [ "Enedis",[6.266777768710504, 46.263747443848835]],
    [ "SNCF Réseau",[6.046413700000001, 47.37278875000001]],
    [ "Enedis",[4.40754004176072, 48.88352185882733]],
    [ "Enedis",[4.41129885, 48.88417375]],
    [ "Enedis",[1.3950559999999985, 43.5986635]],
    [ "Enedis",[2.1967335315146985, 48.97648959373443]],
    [ "Chasseurs",[6.124399474312236, 45.91362281979235]],
    [ "Le Canari",[6.1229356564477655, 45.91777322517632]],
    [ "réséda",[6.403491950000002, 49.110005449999996]],
    [ "Enedis",[6.234605643117995, 46.18427138282332]],
    [ "Enedis",[4.3989185499999985, 48.87946565000001]],
    [ "Enedis",[4.402454757564563, 48.87945097901846]],
    [ "390999398",[6.14316543370986, 47.637087917176174]],
    [ "Enedis",[4.436606652893113, 48.90184947890953]],
    [ "Enedis",[4.444268234007617, 48.89789992748115]],
    [ "Enedis",[3.273020396365701, 46.1403385076661]],
    [ "Enedis",[3.266594311594201, 46.14728618538648]],
    [ "Enedis",[1.9485069888143165, 50.946374252796424]],
    [ "Enedis",[4.440229150000001, 48.90046315]],
    [ "Enedis",[6.234422182674199, 46.18329346704332]],
    [ "Enedis",[1.5216279767916314, 43.49066195481325]],
    [ "Enedis",[2.8595699477271026, 47.338525057140714]],
    [ "Enedis",[2.846252371859297, 47.33770926298157]],
    [ "Enedis",[1.573172953900709, 43.49098130141844]],
    [ "Enedis",[1.5745067636476646, 43.48835795887054]],
    [ "La Grangette",[4.47522429960698, 45.04793923689275]],
    [ "Saint-Jeure",[4.4617248003845615, 45.04639936704813]],
    [ "Enedis",[1.252330677977721, 47.38661446512426]],
    [ "Enedis",[1.248424719298245, 47.391145803508756]],
    [ "Enedis",[1.3040225331942987, 47.391272237400074]],
    [ "Enedis",[2.7678536791831356, 49.82478817061924]],
    [ "Enedis",[2.763134338517367, 49.823559449455686]],
    [ "Enedis",[6.142767176658898, 48.3053183456785]],
    [ "Enedis",[4.874979, 45.720660949999996]],
    [ "Enedis",[4.11738307969286, 49.21814997003299]],
    [ "charpak",[4.001754698710399, 49.224126266154]],
    [ "BONIN",[1.41128955, 43.6268343]],
    [ "Poste ERDF Clos du Levant",[4.695348232770969, 45.67318619268926]],
    [ "RTE",[4.586611726685396, 43.8295584071919]],
    [ "Enedis",[1.3337554015873396, 43.650648782190956]],
    [ "Enedis",[1.3335834000000002, 43.65140385]],
    [ "Église",[-0.10586005000000001, 49.2670821]],
    [ "2D Eglise",[-0.10589195911728347, 49.26709328174959]],
    [ "Enedis",[4.003620233759594, 49.21589066667728]],
    [ "Enedis",[6.238480330478907, 46.184631595940374]],
    [ "Poste électrique d'Epizon",[5.330004052411523, 48.39578393865393]],
    [ "Enedis",[3.9803643500000017, 49.24550175000001]],
    [ "Enedis",[6.2405713748388765, 46.18261020916043]],
    [ "Enedis",[-0.3250108529914527, 43.72481377606837]],
    [ "Enedis",[-0.41974747967209897, 48.54808364673017]],
    [ "Enedis",[6.238373807751938, 46.17983640930233]],
    [ "Enedis",[1.5191082500000006, 43.49486540000001]],
    [ "Enedis",[3.1692735648158075, 49.924857750675024]],
    [ "Enedis",[3.279492673794976, 49.9169002521385]],
    [ "Enedis",[6.243655149577106, 46.179392339705714]],
    [ "Enedis",[6.242869, 46.17748]],
    [ "Enedis",[4.133499567614125, 48.05905398822854]],
    [ "Enedis",[4.141778810130421, 48.059121787685775]],
    [ "Enedis",[4.13532807360673, 48.05773131125133]],
    [ "Enedis",[1.4263808762470267, 43.56323584044324]],
    [ "Sous-station SNCF Iscles",[5.019434700453139, 43.82778915720372]],
    [ "Enedis",[1.4460789462365597, 43.649485354838724]],
    [ "Enedis",[3.235436861772808, 49.95539079906012]],
    [ "Enedis",[1.539672836132515, 43.550974737322726]],
    [ "Enedis",[-1.3812368333333336, 49.482896]],
    [ "Enedis",[2.3112120838748687, 48.63712890064605]],
    [ "Zone Industrielle",[-2.2152777665866896, 48.51196124914597]],
    [ "Enedis",[3.2587536, 49.95742705]],
    [ "Enedis",[2.1802610000000007, 48.980731000000006]],
    [ "Enedis",[3.3874741999999998, 49.94043823333333]],
    [ "Enedis",[6.250837499999999, 46.18945550000001]],
    [ "Les Tournelles",[6.24106182010582, 46.19507548465609]],
    [ "Enedis",[3.3693191977911643, 49.92162614357431]],
    [ "Enedis",[-1.7896069999999986, 46.51936995]],
    [ "Poste RTE d'Angresse",[-1.3737521883272852, 43.65825997477805]],
    [ "Enedis",[2.264222793012905, 49.15637828237651]],
    [ "Enedis",[2.272725206157966, 49.15601825301205]],
    [ "Enedis",[2.270813, 49.15623764000001]],
    [ "Enedis",[3.2162203432465923, 49.9041849504337]],
    [ "Enedis",[3.2314214045307437, 49.907486618122974]],
    [ "Enedis",[3.232579110179641, 49.91022629980039]],
    [ "Thil 2",[4.021729649999999, 49.26283564999999]],
    [ "Enedis",[2.2665848039215684, 49.153530666666676]],
    [ "Enedis",[2.268151849507735, 49.15228852742616]],
    [ "Enedis",[2.2720188263518657, 49.15351146763138]],
    [ "Enedis",[2.3749305, 50.7288315]],
    [ "Enedis",[3.4687635000000006, 49.955291499999994]],
    [ "Enedis",[2.276842319747695, 49.15575368089924]],
    [ "Enedis",[2.86747070802005, 49.83904693922306]],
    [ "Enedis",[2.8633195794647204, 49.83372767785888]],
    [ "Enedis",[2.8708645472057275, 49.83464945895465]],
    [ "Poste électrique de Caffarelli",[-4.503539783081315, 48.3805733969969]],
    [ "Poste électrique de Caffarelli",[-4.520613768791809, 48.36982630775717]],
    [ "Enedis",[2.850928415604481, 50.271834259945926]],
    [ "Enedis",[2.2762548165548098, 49.15464642464917]],
    [ "Boubas",[3.9857004634997883, 45.25625888094133]],
    [ "Enedis",[3.9893228945874792, 45.24554619249566]],
    [ "Enedis",[1.8551698329695234, 48.69424090505508]],
    [ "Enedis",[1.8510033119703384, 48.68834406604542]],
    [ "Enedis",[1.8567159545023615, 48.70195845354054]],
    [ "Enedis",[1.8467833500000002, 48.6916018]],
    [ "Enedis",[1.8418035272280882, 48.69710574049209]],
    [ "Enedis",[1.859932340071919, 48.697267749155465]],
    [ "Enedis",[1.860210947435545, 48.70319153302861]],
    [ "Enedis",[1.8317216176793427, 48.69454469983958]],
    [ "Enedis",[1.8576929698999067, 48.703291450097595]],
    [ "Enedis",[1.85158108547739, 48.69452133855737]],
    [ "Enedis",[1.8521439026387487, 48.693161901392855]],
    [ "Enedis",[1.8596625500000001, 48.70015505]],
    [ "Enedis",[1.8542762789634473, 48.70229908161481]],
    [ "Enedis",[1.8471365968383502, 48.688579382935984]],
    [ "Enedis",[1.8516094338497036, 48.69719376960168]],
    [ "Enedis",[1.86452031027377, 48.68804585023643]],
    [ "Enedis",[1.85326681910042, 48.69214318510932]],
    [ "Enedis",[1.8646442, 48.69288409999999]],
    [ "Enedis",[1.8593945589317544, 48.702568599680234]],
    [ "Enedis",[1.848492159466832, 48.686723301501395]],
    [ "Enedis",[1.8402830700212551, 48.6820134029945]],
    [ "Enedis",[1.8580121600775192, 48.70389567906977]],
    [ "Enedis",[1.848090800827474, 48.692232100300956]],
    [ "Enedis",[-1.008108441355436, 45.675279784052385]],
    [ "Enedis",[2.2894038033888404, 49.154447035056975]],
    [ "Enedis",[2.2894638210281646, 49.15362715750614]],
    [ "Enedis",[2.2895094952815835, 49.15069532100457]],
    [ "Enedis",[2.9853246752588674, 47.03944444987883]],
    [ "Enedis",[1.9071731749459504, 43.565927806574734]],
    [ "Enedis",[1.884347816268267, 43.569318390395544]],
    [ "Enedis",[1.8895271824593707, 43.56660025132171]],
    [ "Enedis",[1.8998768000000001, 43.5596314]],
    [ "Enedis",[6.233360941557048, 43.57280588042318]],
    [ "Enedis",[6.301674196533175, 43.55290137378786]],
    [ "Enedis",[2.272639217114915, 49.147813116788925]],
    [ "Enedis",[2.2697348887924385, 49.147903595389664]],
    [ "Enedis",[2.256330453267163, 49.14522085277089]],
    [ "Enedis",[2.273325, 49.143778]],
    [ "Enedis",[6.319190649999999, 46.27336554999999]],
    [ "Enedis",[2.282325813662466, 49.149539464301014]],
    [ "Enedis",[2.287225650906225, 49.14806822301025]],
    [ "Enedis",[2.28322914699793, 49.147541717506336]],
    [ "Enedis",[2.2852134999999993, 49.148154500000004]],
    [ "Monument Branscourt",[3.818466610733183, 49.270632022675734]],
    [ "Enedis",[5.529058306109221, 45.12533128415741]],
    [ "Enedis",[1.52687676598024, 43.49944592480494]],
    [ "Enedis",[5.2992545, 48.713508499999996]],
    [ "Enedis",[2.284128346269015, 49.14638107162535]],
    [ "Enedis",[2.2319031899831874, 49.85870546615343]],
    [ "Électricité de France",[5.775437686380406, 43.789055036189446]],
    [ "SNCF Réseau",[5.072825221194916, 43.52827822277277]],
    [ "Poste ERDF Collège",[4.696932347352908, 45.67497578766837]],
    [ "Enedis",[2.1993387574257417, 47.79316803674367]],
    [ "réséda",[6.157871623421043, 49.1500612095665]],
    [ "Bananier",[5.033488021218585, 45.7698759218824]],
    [ "Manguier",[5.036640819145176, 45.769467674555514]],
    [ "Enedis",[2.951815644955609, 48.36636295131558]],
    [ "Enedis",[1.5099536146984782, 43.48799736963056]],
    [ "Enedis",[2.6724065, 48.18470150000001]],
    [ "Les Eygats",[4.4704751, 45.00959935]],
    [ "Enedis",[1.2989652500000002, 43.58921825]],
    [ "RTE",[-0.689593395469899, 47.848389837086344]],
    [ "Enedis",[2.7328089911019853, 47.08144433127994]],
    [ "Enedis",[2.7278667945736434, 47.08063769379846]],
    [ "Enedis",[2.7264324136752136, 47.08359524316239]],
    [ "Enedis",[0.3844511392111365, 49.15593516318638]],
    [ "Enedis",[0.38126248393711554, 49.1642443326498]],
    [ "Enedis",[-1.5064862499999996, 46.6371115]],
    [ "EDF",[2.22423813938218, 45.24205943038051]],
    [ "EDF",[2.223332234518728, 45.243773176558314]],
    [ "Enedis",[2.3324991714166408, 49.13863046810732]],
    [ "Enedis",[2.3291268994032395, 49.1390809258312]],
    [ "Enedis",[6.072119101771556, 46.14359882925709]],
    [ "Enedis",[2.4154283882220757, 47.081598532552555]],
    [ "Enedis",[2.819448090325539, 49.073172287941304]],
    [ "Enedis",[2.815105688193085, 49.07142484083496]],
    [ "Électricité de France",[55.4899811, -21.3390072]],
    [ "Électricité de France",[55.490705179299454, -21.339953792535173]],
    [ "Électricité de France",[55.4910717, -21.339111]],
    [ "Électricité de France",[55.491004849999996, -21.33964855]],
    [ "Poste électrique de Bort",[2.4982775760428426, 45.41306224851241]],
    [ "Poste électrique de Marèges",[2.3648866950661986, 45.38962896401303]],
    [ "Poste électrique de Saint Pierre",[2.3661893279498893, 45.3907349529035]],
    [ "Enedis",[2.2025213684210536, 49.09861663157895]],
    [ "Enedis",[2.20676222080471, 49.11124376382074]],
    [ "Enedis",[2.2050021193087406, 49.13148219574609]],
    [ "Enedis",[2.2044346416855922, 49.13628582311379]],
    [ "Enedis",[2.2053047161860824, 49.105388349557856]],
    [ "Enedis",[2.2104804109759906, 49.12636832865607]],
    [ "Enedis",[1.7183942519747235, 48.58019461848342]],
    [ "Enedis",[1.7281061638109125, 48.59072079231984]],
    [ "Enedis",[3.514293028659993, 43.61882894062707]],
    [ "Poste électrique d'Oraison",[5.9241559712737075, 43.921047710561396]],
    [ "Enedis",[2.2997878073432343, 49.111563054455445]],
    [ "Nappe",[2.287757781602813, 49.110390527669814]],
    [ "Enedis",[2.4579665, 50.70631900000001]],
    [ "Enedis",[3.739902861157954, 49.91460627993254]],
    [ "Enedis",[2.838732872314303, 46.47542687152503]],
    [ "Enedis",[2.8353937063805756, 46.47336580801806]],
    [ "Enedis",[2.8423228328786503, 46.48188449762554]],
    [ "Enedis",[2.834367500000002, 46.476061]],
    [ "Enedis",[2.8371868367300723, 46.47117246490035]],
    [ "Enedis",[2.832185523743279, 46.47376144664792]],
    [ "Enedis",[2.8163053333333337, 46.47447199166667]],
    [ "Brommat 1",[2.6828296691601348, 44.76807759699101]],
    [ "Enedis",[2.6871384493529926, 44.768793433273196]],
    [ "Enedis",[2.6980039, 44.7819668]],
    [ "Enedis",[1.4419962859890905, 43.65221621135123]],
    [ "Enedis",[3.004081284214755, 46.70843844663897]],
    [ "Enedis",[2.93257227694859, 46.73475941072416]],
    [ "Enedis",[3.1638651685155836, 46.13248957210776]],
    [ "Enedis",[2.4066860275715802, 48.54979015871332]],
    [ "réséda",[6.121156799999999, 49.15529620000001]],
    [ "Enedis",[1.0402033746835444, 48.55395417383967]],
    [ "Enedis",[1.035384078424015, 48.55464421776111]],
    [ "Enedis",[1.0337140936639126, 48.559821630853996]],
    [ "Enedis",[1.0391399999999997, 48.5589145]],
    [ "Enedis",[1.0426399999999998, 48.556374]],
    [ "Enedis",[1.041867413867822, 48.56226670892019]],
    [ "Enedis",[1.0356517715053764, 48.56219014784945]],
    [ "Enedis",[1.0345433856893544, 48.5717942408377]],
    [ "Enedis",[1.0235994957264958, 48.56418566666667]],
    [ "Enedis",[1.0284686583747928, 48.56264054063019]],
    [ "Enedis",[1.0277603103076098, 48.56486106350063]],
    [ "Enedis",[2.0207765129683004, 48.688392888568686]],
    [ "Enedis",[1.990889004754877, 48.87745638039023]],
    [ "Enedis",[1.9739116308457714, 48.87389404577115]],
    [ "Enedis",[7.191616442879367, 43.80215915628438]],
    [ "Poste électrique du Vazzio",[8.781214147400792, 41.93282110162818]],
    [ "RTE",[0.1553774268556984, 43.07438912825396]],
    [ "Enedis",[1.914998516569201, 48.820647849252765]],
    [ "Enedis",[1.8970808368200842, 48.83514179358438]],
    [ "Enedis",[1.9100095000000001, 48.835036]],
    [ "Enedis",[1.9081766576621648, 48.83548874553945]],
    [ "Enedis",[1.9125120000000002, 48.834982]],
    [ "Enedis",[3.099066095671981, 49.34047081624905]],
    [ "Enedis",[1.802857332992153, 48.84570750426475]],
    [ "Enedis",[1.9196371182094742, 48.88273384897871]],
    [ "Enedis",[1.9186203331314353, 48.884395585907534]],
    [ "Enedis",[1.9276682944354402, 48.88520684819017]],
    [ "Électricité de France",[1.6123691129862596, 46.45511571923113]],
    [ "Enedis",[3.5063115, 43.56813425]],
    [ "Enedis",[3.50665465, 43.5667092]],
    [ "Enedis",[1.9771680075964748, 43.643325629990876]],
    [ "Enedis",[1.7972823484137928, 48.869457737195404]],
    [ "Enedis",[2.8456897012282214, 46.33023246072552]],
    [ "Enedis",[2.9990444999999983, 50.6788845]],
    [ "Chabanne",[3.9888117970543027, 45.30511569982949]],
    [ "Enedis",[0.42049518864415, 45.62721337931713]],
    [ "Enedis",[1.850145559927857, 48.47933329709789]],
    [ "Enedis",[1.890189176682692, 48.501618006143154]],
    [ "Enedis",[1.679587302020202, 48.6345214939394]],
    [ "Enedis",[1.7944654819229504, 48.82048318184612]],
    [ "Enedis",[5.824797103537768, 48.36851456865592]],
    [ "Enedis",[6.209323447048138, 48.313003169663936]],
    [ "SNCF Réseau",[4.709743156249439, 44.41348842887801]],
    [ "Enedis",[2.4281160120188745, 49.51436856044515]],
    [ "Enedis",[2.77349973971592, 42.69783439346984]],
    [ "Enedis",[2.8801335000000003, 42.6602415]],
    [ "Enedis",[2.876801, 42.68058833333334]],
    [ "Enedis",[2.8811975, 42.676030000000004]],
    [ "Enedis",[2.8812721157799617, 42.680599637862876]],
    [ "Enedis",[2.82304295164076, 46.32031947862694]],
    [ "Enedis",[-1.64802725, 46.603193]],
    [ "Enedis",[0.5829176085491685, 45.70500077805854]],
    [ "Enedis",[1.6304755426211457, 49.08158756510647]],
    [ "Enedis",[2.789073440366972, 49.08425419571865]],
    [ "Enedis",[2.7959861282818212, 49.08064590860751]],
    [ "Enedis",[2.7877946983568074, 49.072862245305174]],
    [ "Enedis",[2.785615332606325, 49.07805250999636]],
    [ "Enedis",[2.805220737486687, 49.06931394036209]],
    [ "Enedis",[2.7930015725843282, 49.07044717074444]],
    [ "Enedis",[2.8051105, 49.0719095]],
    [ "Enedis",[2.7904697717171723, 49.076934850505054]],
    [ "Enedis",[2.7955536747659617, 49.07755947396655]],
    [ "Enedis",[2.788516777669271, 49.07509942447916]],
    [ "Enedis",[2.7885373095768378, 49.077408962880476]],
    [ "Enedis",[2.7885842470406574, 49.08132656459084]],
    [ "Enedis",[2.8468144999999994, 49.809143500000005]],
    [ "Enedis",[2.8485124052287585, 49.81364314379085]],
    [ "Enedis",[1.6437068657544787, 50.72945649235165]],
    [ "Enedis",[3.527382, 43.58824275]],
    [ "Enedis",[3.528127, 43.5951615]],
    [ "Enedis",[3.5224941282051283, 43.59263206410257]],
    [ "Enedis",[4.019401748427673, 47.956593056603765]],
    [ "Enedis",[4.046292770915215, 47.9627780505334]],
    [ "CNR",[4.788087229000276, 44.6759875515463]],
    [ "réséda",[6.298743450000001, 49.097302400000004]],
    [ "Enedis",[2.302819242424242, 49.14467818181818]],
    [ "Enedis",[2.2864165382541297, 49.14224683923154]],
    [ "Enedis",[2.290243648389905, 49.13933065361184]],
    [ "Enedis",[2.2841561547810545, 49.139842476854334]],
    [ "Enedis",[2.2864555795519834, 49.14221508424278]],
    [ "Enedis",[2.2975658467883706, 49.14481870040567]],
    [ "Enedis",[2.2884891677607593, 49.14689192122539]],
    [ "Enedis",[1.6775485000000008, 49.08779650000001]],
    [ "Enedis",[2.668376185083235, 47.861513143662336]],
    [ "Hautiers",[1.9857800499999991, 49.1460228]],
    [ "Enedis",[1.9732377339097023, 49.14269855043228]],
    [ "Enedis",[1.9854164999999995, 49.14860850000001]],
    [ "Enedis",[1.9844708189509304, 49.14253895769882]],
    [ "Enedis",[1.9820500504266878, 49.145848912076545]],
    [ "Enedis",[1.9879706666666668, 49.1430399537037]],
    [ "Enedis",[1.9886878333333333, 49.15178199999999]],
    [ "Enedis",[1.97490278367062, 49.14531775040707]],
    [ "Enedis",[1.9721244319248825, 49.14421789045383]],
    [ "Enedis",[1.9781795669501412, 49.14626826046357]],
    [ "Enedis",[1.9897890741630628, 49.141977915265095]],
    [ "Enedis",[1.9858038611384377, 49.14082966487477]],
    [ "Enedis",[4.130315979174725, 47.956800054158606]],
    [ "Enedis",[3.626972568627451, 47.23301509803922]],
    [ "Portique de Monteynard",[5.685237256721729, 44.96415069311527]],
    [ "Enedis",[1.590683149999999, 42.8104067]],
    [ "Enedis",[1.5908961500000012, 42.814475900000005]],
    [ "Enedis",[1.282385, 43.584464000000004]],
    [ "Poste ERDF Grand Champ",[5.1715778, 45.7529792]],
    [ "Enedis",[0.7776456246291165, 44.35058039389705]],
    [ "Enedis",[2.6015151167960777, 50.756227147836746]],
    [ "Enedis",[0.749437331291683, 48.5288328308153]],
    [ "Enedis",[0.760947290947148, 48.528821029827306]],
    [ "Enedis",[0.755285337317397, 48.52876950686144]],
    [ "Enedis",[-3.237653674742616, 47.73900966200229]],
    [ "Enedis",[3.9804339521449665, 44.481134427136645]],
    [ "EDF",[3.9643604870268736, 44.48589465366006]],
    [ "EDF",[4.03746969754027, 44.44741634627302]],
    [ "Enedis",[1.7213820000000004, 43.826989000000005]],
    [ "Enedis",[1.72887525, 43.82739225]],
    [ "Enedis",[1.693429572327044, 43.81600781132076]],
    [ "réséda",[6.188873624353469, 49.10810191590877]],
    [ "Enedis",[1.7145345, 43.82000275000001]],
    [ "Enedis",[1.6957423737373727, 49.14689674747474]],
    [ "Enedis",[1.8434954689265532, 47.01539706949153]],
    [ "Enedis",[2.340225901408451, 49.047344713615026]],
    [ "Enedis",[3.8921897054902574, 49.90795982090801]],
    [ "Enedis",[3.894754257631734, 49.90933102225377]],
    [ "Enedis",[4.0723519999999995, 47.235297]],
    [ "Enedis",[4.071619999999999, 47.24679999999999]],
    [ "Enedis",[2.0317812167689158, 48.61698827811862]],
    [ "Enedis",[2.0099349780367546, 48.598770915284625]],
    [ "Enedis",[2.0328977874604544, 48.61930942881796]],
    [ "Enedis",[2.026718882646692, 48.6145218639201]],
    [ "Enedis",[2.892436689532194, 49.89266294929741]],
    [ "Enedis",[2.5141482002556996, 44.14270535127217]],
    [ "Enedis",[1.0323369999999998, 47.46083575]],
    [ "Enedis",[-1.5347810348162945, 48.072363121961146]],
    [ "Bromines Haut",[6.071128200000001, 45.94738075000001]],
    [ "Imperial",[6.13111982542786, 45.963423340753415]],
    [ "Lavorel Bas",[6.095990349999999, 45.978573700000005]],
    [ "Panty",[6.121321218312846, 45.97819874311981]],
    [ "RTE",[-1.7782373513999385, 43.35771418809706]],
    [ "Enedis",[2.059237282590552, 48.70651076514771]],
    [ "Enedis",[2.0407352349265855, 48.71202832396126]],
    [ "Enedis",[2.057147302476701, 48.712318935107874]],
    [ "Enedis",[2.032277027180068, 48.70891516628917]],
    [ "Enedis",[2.03633025, 48.70342300000001]],
    [ "Les Berges",[2.0465295, 48.70662599999999]],
    [ "Enedis",[2.0376830391254313, 48.707028758189495]],
    [ "Enedis",[4.0833663661892725, 49.70167580426846]],
    [ "Enedis",[4.9185867647267285, 45.723229055946454]],
    [ "Enedis",[1.70863865, 43.8136967]],
    [ "Enedis",[1.7143250999999997, 43.815427949999986]],
    [ "Enedis",[1.68804616415035, 43.81333411713455]],
    [ "Enedis",[4.024582439393941, 49.684525803030304]],
    [ "Enedis",[4.7719204, 45.648770799999994]],
    [ "Enedis",[4.766650023828874, 45.65536986018849]],
    [ "Enedis",[4.122552395766378, 49.28978153430676]],
    [ "Escaillon",[5.891941087949244, 43.13194729250716]],
    [ "Enedis",[3.2252597219219217, 43.271413966366374]],
    [ "Enedis",[3.2399235363825363, 43.22346433818434]],
    [ "Enedis",[2.996570427192636, 48.54776789197311]],
    [ "Enedis",[3.025337603292858, 48.55584738831359]],
    [ "Enedis",[3.001923625764388, 48.553988860281535]],
    [ "Enedis",[3.010099378548345, 48.55804618296695]],
    [ "Enedis",[3.014717429541275, 48.555423477775356]],
    [ "Enedis",[3.006058526309863, 48.55170924701805]],
    [ "Enedis",[3.0181985472615978, 48.550586118024064]],
    [ "Enedis",[3.0156078154273858, 48.55068733609724]],
    [ "Enedis",[3.0086390491563857, 48.55293282080839]],
    [ "Enedis",[3.0235763126732174, 48.558970667643386]],
    [ "Enedis",[3.005261418083736, 48.55248766405057]],
    [ "Enedis",[1.6411116218201538, 49.10549398733123]],
    [ "Enedis",[1.6547807948112263, 49.118055210527245]],
    [ "Enedis",[2.48173725, 47.95301729999999]],
    [ "Feigères",[6.0790246, 46.11160319999999]],
    [ "Les Crottes",[6.026845853445331, 46.13782320790532]],
    [ "Lotissement Dunand",[6.040334336384169, 46.12135898788934]],
    [ "Urbain Parc",[6.097233319307341, 45.88040324160469]],
    [ "ZA Du Viaduc",[6.099619100000002, 46.143135349999994]],
    [ "Enedis",[6.096700966151361, 46.108842746224056]],
    [ "Enedis",[4.81062725, 45.7200828]],
    [ "Kembs",[7.517050196078363, 47.65424206383629]],
    [ "Poste électrique de Gerstheim",[7.726146496422058, 48.40457754240277]],
    [ "Enedis",[-0.8457014365591399, 43.82447663010754]],
    [ "Porte Serpenoise",[6.172059629703375, 49.11248684671124]],
    [ "Enedis",[1.7961156562039873, 49.15809365702495]],
    [ "Enedis",[1.8071546692720792, 49.16289005090575]],
    [ "Enedis",[1.7941293285433153, 49.15361977758324]],
    [ "Enedis",[1.7883852446820707, 49.14663341419028]],
    [ "Enedis",[1.785904791724383, 49.147033792996595]],
    [ "Enedis",[1.7796948104709809, 49.154176249672346]],
    [ "Enedis",[1.7810599523520194, 49.15498950151164]],
    [ "Enedis",[1.779586746401669, 49.15571342492854]],
    [ "Enedis",[1.7946664992050667, 49.156165724926815]],
    [ "Enedis",[1.7948824852068486, 49.15929808322507]],
    [ "Enedis",[1.7987671347426273, 49.156019442461194]],
    [ "Enedis",[1.8216873555887956, 48.85917564759799]],
    [ "Enedis",[-0.6950212248062015, 43.63304382248062]],
    [ "Bourg",[5.623814386721247, 46.75524893907444]],
    [ "Enedis",[3.855131754372228, 46.16984696554424]],
    [ "réséda",[6.240806602168665, 49.117847640949996]],
    [ "réséda",[6.23626415, 49.116597950000006]],
    [ "réséda",[6.231225881825286, 49.11683029877092]],
    [ "réséda",[6.2430754499999965, 49.11789545000001]],
    [ "Gérédis",[-0.1906470844670548, 47.050515645857985]],
    [ "Enedis",[2.736819161068045, 50.26831998621877]],
    [ "Bourg",[-5.098027619440805, 48.458840545226224]],
    [ "Enedis",[0.008239999999999999, 45.01271049999999]],
    [ "Enedis",[0.00664511805555587, 45.01760779583333]],
    [ "Enedis",[0.015677764771460424, 45.01672844481605]],
    [ "Enedis",[0.00483930431667441, 45.01907047662547]],
    [ "Enedis",[-0.0038523188405794944, 45.014217362318846]],
    [ "Enedis",[1.8558738128790377, 49.09594019891939]],
    [ "Enedis",[1.9469078622754503, 49.12365836177645]],
    [ "Enedis",[2.13509944310518, 49.10139215827235]],
    [ "Enedis",[2.1359918606247192, 49.09986345353087]],
    [ "Enedis",[4.197681398441848, 47.984899724540895]],
    [ "Enedis",[2.462293, 49.233055]],
    [ "sas les arènes",[4.0212293535039825, 49.26419825637965]],
    [ "Enedis",[2.8958867, 42.68698865]],
    [ "Enedis",[2.7570962163153077, 48.41430967776759]],
    [ "Enedis",[2.771096322505801, 48.42325084764114]],
    [ "Enedis",[2.760891731613022, 48.42502305378895]],
    [ "Enedis",[2.7591754999999996, 48.4239435]],
    [ "Enedis",[2.751126499999999, 48.419450999999995]],
    [ "Enedis",[2.7556369555555564, 48.42892786666666]],
    [ "Enedis",[2.768621681114551, 48.42452969246646]],
    [ "Enedis",[2.2388722307692315, 50.28978494871796]],
    [ "Enedis",[3.4330121213287517, 43.60467009683849]],
    [ "SICAE-Somme",[3.0866707070079222, 50.024784407678254]],
    [ "SICAE-Somme",[3.078903576624029, 50.02260516829697]],
    [ "Enedis",[2.4719540000000007, 47.77194000000001]],
    [ "Enedis",[2.4747097640781113, 47.76846185263397]],
    [ "Enedis",[2.488948971040907, 47.78352119785339]],
    [ "Enedis",[2.503405, 47.7623652]],
    [ "Enedis",[2.4638484999999997, 47.777434]],
    [ "Enedis",[2.472321173054587, 47.770250180023226]],
    [ "Enedis",[2.486508047068892, 47.77712718271288]],
    [ "Enedis",[2.4655716325379604, 47.77638821214751]],
    [ "Motte 27278 P 027",[1.4577789163970862, 48.8946737543728]],
    [ "Enedis",[6.159149866050684, 48.7359216545286]],
    [ "Enedis",[1.9680929461742713, 48.28006355949795]],
    [ "Enedis",[1.9188431369509045, 48.28397387532299]],
    [ "Enedis",[6.24857826247685, 46.203100629396175]],
    [ "Enedis",[0.51403396775871, 49.344130073842955]],
    [ "Enedis",[4.120016754807693, 45.68145806474357]],
    [ "Enedis",[4.126550345640306, 45.680533962205594]],
    [ "Poste source des Bablons",[3.9175613178179924, 48.521105029624565]],
    [ "Poste électrique de la Voie Moyenne",[3.9194412227829827, 48.522056113318655]],
    [ "Enedis",[3.1693509, 43.309843400000005]],
    [ "Enedis",[1.920058798033959, 48.313962151027695]],
    [ "Enedis",[1.896834544342506, 48.29634944138633]],
    [ "Le Cristal",[6.122426450000001, 45.936888]],
    [ "Enedis",[-1.2249471999999997, 43.641198599999996]],
    [ "Enedis",[-1.2221724999999999, 43.64397815]],
    [ "Enedis",[-1.2381971881602911, 43.641032949908926]],
    [ "Enedis",[-1.2449884499999995, 43.65380715]],
    [ "RTE",[-2.0419072871463237, 46.925211923480276]],
    [ "Enedis",[-1.1510498000000002, 43.68029905]],
    [ "Enedis",[-1.1498079499999998, 43.67905995]],
    [ "Poste électrique de Palluau",[-1.6040497881646967, 46.8068262927307]],
    [ "Enedis",[3.9822552815734973, 46.0806839689441]],
    [ "Enedis",[3.9827049999999993, 46.08431499999999]],
    [ "Enedis",[3.990385683229814, 46.08043395859213]],
    [ "Enedis",[3.9886248400194506, 46.0825524811573]],
    [ "Enedis",[3.992442168316832, 46.0715210110011]],
    [ "Enedis",[3.977139592396109, 46.083539841732986]],
    [ "Enedis",[3.981398, 46.0828505]],
    [ "Enedis",[4.0219748008658005, 46.08213056709957]],
    [ "Enedis",[-0.6472292, 43.66321880000001]],
    [ "Enedis",[3.05808523260372, 50.611998010283415]],
    [ "Enedis",[3.0541592386406595, 50.61181898289437]],
    [ "Enedis",[3.051788932406071, 50.6054355051912]],
    [ "Enedis",[3.044300840774615, 50.606917693035584]],
    [ "Enedis",[3.0396353499999993, 50.6133546]],
    [ "Enedis",[2.9828605603271985, 43.33506718200409]],
    [ "Enedis",[2.9609678654048377, 43.34605405257623]],
    [ "Enedis",[2.9724503526970962, 43.318996654910094]],
    [ "Enedis",[2.969752686402265, 43.34716246968838]],
    [ "Enedis",[1.7667974999999998, 48.282515000000004]],
    [ "Enedis",[1.7786300000000002, 48.297067000000006]],
    [ "Enedis",[3.9132947500000004, 49.1574337]],
    [ "Enedis",[3.227529, 49.517022000000004]],
    [ "Enedis",[1.7104829999999998, 48.275653]],
    [ "Enedis",[1.688251215864759, 48.277243628955354]],
    [ "Enedis",[1.7082853063009422, 48.27663987270368]],
    [ "Enedis",[1.700821496621622, 48.2176910945946]],
    [ "Enedis",[1.7035946200000003, 48.21623839333334]],
    [ "Enedis",[1.7096402961116646, 48.23509908840147]],
    [ "Viatel Grisolles",[1.2905775000000002, 43.83898849999999]],
    [ "réséda",[6.2321217437968, 49.11831988336852]],
    [ "réséda",[6.23029765, 49.11769875]],
    [ "réséda",[6.230069933029791, 49.11539609981677]],
    [ "réséda",[6.227787482859889, 49.11718814969208]],
    [ "Enedis",[2.957055560063644, 43.314767637231505]],
    [ "Enedis",[2.934728522597301, 43.3549524447504]],
    [ "Enedis",[1.6019046767432488, 48.24023151242778]],
    [ "Enedis",[1.642757486748787, 48.223937832026884]],
    [ "Enedis",[1.2413611753086422, 43.59597205530864]],
    [ "Enedis",[1.2361879999999998, 43.59057049999999]],
    [ "Enedis",[1.2449434087705555, 43.60261620882277]],
    [ "Enedis",[1.2594232012578617, 43.60222652075472]],
    [ "Enedis",[1.2216025, 43.596425]],
    [ "Enedis",[1.238852, 43.5941635]],
    [ "Enedis",[1.2571705, 43.602661]],
    [ "Enedis",[1.2359594321977285, 43.605386713872186]],
    [ "Enedis",[1.2257345130224107, 43.589533672723604]],
    [ "Enedis",[1.2304385920765801, 43.59394618728083]],
    [ "Enedis",[1.2444770815047024, 43.59648324503658]],
    [ "Enedis",[1.223553, 43.59957450000001]],
    [ "Enedis",[1.225769886363636, 43.59551268560606]],
    [ "Enedis",[1.2301979824561406, 43.60377950877192]],
    [ "Enedis",[1.2265517088122608, 43.592696615581104]],
    [ "Enedis",[1.2619168978878168, 43.60057811484124]],
    [ "Enedis",[1.2239433619702178, 43.60419391981672]],
    [ "Enedis",[1.2230978606770835, 43.595064156250004]],
    [ "Enedis",[1.2358719518304433, 43.59698834489403]],
    [ "Enedis",[1.236179276169265, 43.59321794803267]],
    [ "Enedis",[1.225880689589302, 43.59127297325692]],
    [ "Enedis",[1.2282169108964032, 43.6066346879585]],
    [ "Enedis",[1.2395660338983052, 43.59818630790961]],
    [ "Enedis",[1.2353574999999994, 43.5945385]],
    [ "Enedis",[1.2283188357487924, 43.59828250313115]],
    [ "Enedis",[1.2417898082191787, 43.600615785388136]],
    [ "Enedis",[1.2170535, 43.595355]],
    [ "Enedis",[1.2549196834532372, 43.60008714148682]],
    [ "Enedis",[1.240723, 43.594001500000005]],
    [ "Enedis",[1.2388540000000001, 43.59090949999999]],
    [ "Enedis",[1.259544500000001, 43.599725500000005]],
    [ "Enedis",[1.2341555, 43.597260999999996]],
    [ "Enedis",[1.2290690000000002, 43.593836]],
    [ "Enedis",[1.2308605000000004, 43.60650450000001]],
    [ "Enedis",[1.2576390000000002, 43.601935499999996]],
    [ "Enedis",[1.2234807379134862, 43.598189966072944]],
    [ "Enedis",[1.2255363636363639, 43.603084939393945]],
    [ "Enedis",[1.2259309999999999, 43.5970325]],
    [ "Enedis",[1.225895892431809, 43.599612748623386]],
    [ "Enedis",[1.2395088094225999, 43.59999813567128]],
    [ "Enedis",[1.2581800074626865, 43.60108224626866]],
    [ "Enedis",[2.7682435259678946, 50.27491417752598]],
    [ "Enedis",[1.468768247191011, 48.17358744569289]],
    [ "Enedis",[1.4681077418706001, 48.186322717063355]],
    [ "Enedis",[1.4629793395125297, 48.197067163748706]],
    [ "Enedis",[1.4625601599999998, 48.185085869787244]],
    [ "Enedis",[1.4598177821596245, 48.14639974178404]],
    [ "Enedis",[1.4602224307588763, 48.14704750092545]],
    [ "Enedis",[4.3600918, 45.436815100000004]],
    [ "Enedis",[1.6065329876160988, 48.137388021671825]],
    [ "Enedis",[1.6254609519422698, 48.123902792568714]],
    [ "réséda",[6.161697746075996, 49.100155576910204]],
    [ "Enedis",[2.588432562998351, 44.36140023026272]],
    [ "SRD",[0.26043550000000004, 46.43446350000001]],
    [ "SRD",[0.26078986904761864, 46.43577317355889]],
    [ "Enedis",[-1.685678134315229, 49.64735040153209]],
    [ "Enedis",[4.434945089888408, 44.803469169451446]],
    [ "Enedis",[1.9467439843524004, 48.57276015055885]],
    [ "Enedis",[1.9378536088280052, 48.57159024657533]],
    [ "Enedis",[1.943724788261108, 48.57824159279576]],
    [ "Enedis",[1.9410757418699194, 48.573341132113825]],
    [ "Enedis",[1.9467062573402416, 48.57394837823835]],
    [ "Enedis",[1.9322943173943175, 48.56414522972973]],
    [ "Enedis",[1.949950817860747, 48.57058002287252]],
    [ "Enedis",[1.9343478555629288, 48.5671384714665]],
    [ "Enedis",[1.9289458452874928, 48.565858378976486]],
    [ "Enedis",[6.808756443430898, 43.429706895847865]],
    [ "Poste électrique de Cannes La Bocca",[6.959043576564692, 43.55285480091534]],
    [ "Poste électrique de Fréjus",[6.732626550217389, 43.43742045805095]],
    [ "Villemaur Eglise",[3.726408675558725, 48.25695685553616]],
    [ "Enedis",[1.6577219265380998, 48.29432105365658]],
    [ "Enedis",[1.6811194961328768, 48.35704133295297]],
    [ "Enedis",[1.695540076502732, 48.328857590163935]],
    [ "Enedis",[1.6932585000000002, 48.327787]],
    [ "Enedis",[1.559934775279879, 48.34390945907136]],
    [ "Enedis",[-1.5742585999999998, 49.64989044999999]],
    [ "Enedis",[5.0021755, 45.64655120000001]],
    [ "réséda",[6.54417051909968, 49.022832953363675]],
    [ "Enedis",[2.9749365011725226, 50.329226502560516]],
    [ "Enedis",[1.6859437500000003, 42.78719064999999]],
    [ "réséda",[6.3441230499999985, 49.05035515]],
    [ "réséda",[6.316889438502558, 49.04569647580053]],
    [ "Drudec",[-5.105275959876352, 48.45786987476046]],
    [ "réséda",[6.484356299999999, 49.09013274999999]],
    [ "réséda",[6.4857546500000005, 49.09293859999999]],
    [ "Enedis",[-0.8815595342656496, 43.60451803438673]],
    [ "réséda",[6.523752242324643, 49.06976566916741]],
    [ "Enedis",[2.830195500540541, 48.25551768072072]],
    [ "Enedis",[2.0031049, 48.63945059999999]],
    [ "Enedis",[1.998997499999999, 48.63965799999999]],
    [ "Enedis",[1.9983016148648656, 48.62776181644144]],
    [ "Enedis",[1.9878622491627596, 48.63923653337798]],
    [ "Enedis",[1.1580640000000002, 49.033603500000005]],
    [ "Enedis",[2.8228451000000008, 48.355153050000006]],
    [ "réséda",[6.1915055, 49.03981225]],
    [ "réséda",[6.195890499999999, 49.043307250000005]],
    [ "Enedis",[1.6545104999999998, 48.326753000000004]],
    [ "Enedis",[1.6409502015968065, 48.327310963406525]],
    [ "Enedis",[1.6553327791446988, 48.32978663678969]],
    [ "Enedis",[0.553653, 44.174178500000004]],
    [ "Enedis",[0.5597085, 44.1770645]],
    [ "Enedis",[0.55547, 44.1769195]],
    [ "Enedis",[-0.7794017000000004, 43.620519900000005]],
    [ "Enedis",[-0.7773487933895916, 43.61314120182841]],
    [ "Enedis",[2.8607300395843644, 50.09815999901039]],
    [ "réséda",[6.301858716129031, 49.15813470537634]],
    [ "Enedis",[1.8378007277844801, 48.3435614057097]],
    [ "Enedis",[3.1427911499999994, 45.79964435]],
    [ "Chef Lieu",[6.157999549999999, 45.977387449999995]],
    [ "Collet",[6.3149976166903015, 45.9558225982415]],
    [ "Molliats",[6.161796515237172, 45.96259739080922]],
    [ "Mont Veyrier",[6.157106677026511, 45.97983575406091]],
    [ "Pont de Pierre",[6.299512976344704, 45.96922530608122]],
    [ "poste_853",[2.9013398447653436, 49.49960497111913]],
    [ "Enedis",[2.3459286471030856, 43.66688620617006]],
    [ "Enedis",[1.6991452999999999, 48.379781033333344]],
    [ "Les Echelettes",[6.271549692525431, 46.15280068443091]],
    [ "Enedis",[1.7332376500000009, 43.8281678]],
    [ "Enedis",[1.7280848996848437, 43.825603716525016]],
    [ "SEMREV",[-2.525117596094385, 47.287846472518346]],
    [ "Piste Cyclable",[6.137298232961796, 45.937645920130315]],
    [ "ESSeyssel",[6.112931250000001, 45.98904255]],
    [ "Maloux",[6.113161644384553, 45.99462773368987]],
    [ "Enedis",[1.7248047794340924, 43.83078119220151]],
    [ "Enedis",[1.709484853436215, 48.43829468159643]],
    [ "Enedis",[1.7151189291642777, 48.43368789961277]],
    [ "Enedis",[2.865568779801275, 47.48610987864986]],
    [ "Enedis",[2.8623449737740763, 47.48134070917264]],
    [ "Enedis",[1.6844183706600404, 49.21032219145753]],
    [ "Enedis",[1.6806898523809517, 49.207979800887806]],
    [ "Enedis",[1.683211702210877, 49.208057230216625]],
    [ "Enedis",[6.137126734016551, 45.92382240556276]],
    [ "Enedis",[1.6718850354546289, 48.47729082989959]],
    [ "Enedis",[1.700584274962003, 48.46657414615334]],
    [ "Enedis",[3.3858200486903693, 49.65561000049357]],
    [ "Enedis",[3.3851871933423516, 49.66252843207535]],
    [ "Sous-station des Mollettes",[6.031935450185304, 45.465576517135595]],
    [ "réséda",[6.203584899999999, 49.12056605]],
    [ "Poste électrique de Saint-Herbot",[-3.8010273819326166, 48.3313612526589]],
    [ "Enedis",[5.6116159, 43.19465374999999]],
    [ "EDF",[6.050935685696365, 43.69851561685604]],
    [ "Enedis",[-0.3249976369986876, 43.595625459812695]],
    [ "Enedis",[1.7187710916110093, 48.5006299361376]],
    [ "Enedis",[1.7257997006325458, 48.49857903312249]],
    [ "Enedis",[1.7270440391220288, 48.495081712892706]],
    [ "Enedis",[1.74104367218524, 48.486665030088794]],
    [ "Enedis",[6.215344035054973, 48.69984062294031]],
    [ "Enedis",[1.6582498833324115, 48.49229331355603]],
    [ "Les Peupliers",[5.652343506726018, 45.27388254518478]],
    [ "Galaxy Imprimeurs",[0.1486242, 48.01514614999999]],
    [ "Cartoucherie",[0.19486565, 47.9825698]],
    [ "Enedis",[1.1881615945316757, 49.26443957961796]],
    [ "Enedis",[1.188284572110154, 49.26024119531961]],
    [ "RTE",[5.772341803836214, 44.87209292262027]],
    [ "Enedis",[2.0697283387083467, 49.19142755752605]],
    [ "Enedis",[2.076824219681328, 49.1908593986161]],
    [ "Enedis",[6.026067275539568, 46.26309661055155]],
    [ "Enedis",[2.4418310855827894, 49.897409669179204]],
    [ "Enedis",[3.1073242682571602, 49.40409612129189]],
    [ "Enedis",[3.113677572064091, 49.40265988878248]],
    [ "Pont de Ruy",[5.29882878472861, 45.5882672536032]],
    [ "Poste électrique de Saint-Vulbas",[5.259278592574003, 45.79564707516543]],
    [ "Enedis",[5.4848451738423165, 43.35480115779816]],
    [ "Enedis",[2.951741189819326, 49.81425457437122]],
    [ "Enedis",[4.3183965034861, 48.24029809162714]],
    [ "Enedis",[5.3070719753595155, 43.36277354296198]],
    [ "Sorea",[6.415184355856159, 45.25312773094647]],
    [ "Sorea",[6.412348699999999, 45.252141249999994]],
    [ "Enedis",[3.084649106545307, 46.94239140953859]],
    [ "Enedis",[6.075197865919637, 45.89253074847953]],
    [ "Enedis",[6.077123107807253, 45.892723883692135]],
    [ "Enedis",[6.079193956765258, 45.89199972206811]],
    [ "SNCF Réseau",[4.052628579595553, 50.02246740940815]],
    [ "réséda",[6.185090040116016, 49.08250627022312]],
    [ "réséda",[6.184744998987388, 49.07925324100156]],
    [ "Enedis",[2.972617802491623, 49.799188898899935]],
    [ "Enedis",[1.03962235, 47.790823]],
    [ "Enedis",[3.2622428, 43.323633050000005]],
    [ "Enedis",[2.9664740436982586, 49.73908355728551]],
    [ "Enedis",[2.990724875197809, 49.74235649415734]],
    [ "Enedis",[6.299542129032256, 46.30873522150537]],
    [ "Poste de Castelsarrasin",[1.1196784646707147, 44.04011930063278]],
    [ "Enedis",[1.395618800111863, 47.823887601817994]],
    [ "Enedis",[1.2461030035805636, 43.599372605370846]],
    [ "Enedis",[6.523917502004279, 46.13287024631251]],
    [ "Enedis",[3.9200189303253463, 48.099514060109094]],
    [ "Enedis",[3.8062124928353467, 48.272370775758446]],
    [ "Enedis",[3.8134794712606985, 48.26646259736906]],
    [ "Enedis",[3.808126197491749, 48.2654690587164]],
    [ "Enedis",[3.8017211696943316, 48.26954837654725]],
    [ "Enedis",[3.806308545735048, 48.268288139350794]],
    [ "Enedis",[1.534143, 47.75093999999999]],
    [ "RTE",[1.9291256898511993, 47.94666931177469]],
    [ "Enedis",[-0.7043220902290471, 43.56800105076205]],
    [ "Enedis",[-0.33647911150417575, 44.99003134760355]],
    [ "Enedis",[3.5872081089567174, 48.359527926213126]],
    [ "Enedis",[2.1036263139229914, 47.83374538105543]],
    [ "RTE",[4.082755752215827, 43.570472578742354]],
    [ "Enedis",[6.093076972949541, 48.58871355567244]],
    [ "Enedis",[6.808193239742833, 47.63640406334179]],
    [ "Enedis",[2.0228874726976347, 47.76261542024394]],
    [ "Enedis",[2.0296662999999993, 47.768377949999994]],
    [ "Enedis",[2.0246769846919355, 47.7668109519501]],
    [ "Enedis",[2.0147850499999995, 47.763823499999994]],
    [ "Enedis",[2.0270970022276846, 47.765552794825844]],
    [ "Enedis",[2.016300985594142, 47.76541768538637]],
    [ "Enedis",[5.230353744462225, 46.183602572129]],
    [ "Enedis",[5.2248116, 46.183958849999996]],
    [ "Enedis",[5.20286825, 46.187114900000005]],
    [ "Enedis",[0.021248700000000002, 45.01033949999999]],
    [ "Enedis",[3.92335518614597, 48.04715080442204]],
    [ "Enedis",[3.913205198896845, 48.04125847379153]],
    [ "Enedis",[3.588084154937308, 48.41167071714711]],
    [ "Enedis",[3.6012134594325125, 48.40368759403618]],
    [ "Enedis",[3.457535691430548, 48.453910797647524]],
    [ "Enedis",[0.009860283335615493, 45.02903256440067]],
    [ "Enedis",[2.118181709127239, 48.8416205638949]],
    [ "Enedis",[2.1318698583826565, 48.84835317043371]],
    [ "Enedis",[5.962784016915398, 47.21113629971694]],
    [ "RTE",[0.33996604113215106, 44.26789659205362]],
    [ "Enedis",[2.914622785415238, 49.77823637557102]],
    [ "Enedis",[2.903624543212269, 49.828653581522694]],
    [ "Enedis",[3.6538836956431537, 48.27133164169634]],
    [ "Enedis",[3.8474711854639896, 48.04838723858355]],
    [ "Enedis",[3.6694703871931815, 48.2366082378655]],
    [ "Le Praz",[6.1722269, 45.9684045]],
    [ "Les Rassettes",[6.179270399999999, 45.975856449999995]],
    [ "Enedis",[6.190804449999998, 48.65588929999999]],
    [ "Enedis",[6.815980993989694, 47.704928754154004]],
    [ "Chantelot 2",[4.7680549999999995, 45.60839795]],
    [ "Enedis",[6.0553685658928496, 46.34018996099924]],
    [ "Enedis",[3.1138407000000003, 49.81184]],
    [ "Enedis",[3.1141321341910606, 49.81087650621866]],
    [ "Poste électrique d’Oudon",[-1.0085771818800784, 47.98611182420665]],
    [ "réséda",[6.1466142999999995, 49.06947385]],
    [ "réséda",[6.14973695, 49.0640714]],
    [ "Sous-station de Sarraltroff",[7.08308631473435, 48.765161451196214]],
    [ "432779249",[2.3249351077698925, 48.63294980954593]],
    [ "RTE",[1.3861132925267983, 44.562117065176494]],
    [ "RTE",[1.4397343837976038, 44.497793375469875]],
    [ "Enedis",[1.7376239499999997, 47.3846035]],
    [ "Enedis",[1.6911211556565366, 49.120227834715514]],
    [ "Enedis",[1.71178747879766, 49.10768379437832]],
    [ "Enedis",[5.607105874954087, 43.192366441175196]],
    [ "Enedis",[0.6754011678810975, 48.377959161597815]],
    [ "Enedis",[0.6819313723764207, 48.37870650826253]],
    [ "Enedis",[1.7050254341366062, 49.19709356736618]],
    [ "Enedis",[0.685207729498055, 48.39887724033228]],
    [ "réséda",[6.139288850000001, 49.069973850000004]],
    [ "Sous-station de Mondion",[0.45286647199923336, 46.92732953905353]],
    [ "Enedis",[1.196166150000001, 42.865657299999995]],
    [ "Enedis",[1.2203041519446418, 42.872559666153826]],
    [ "Enedis",[3.311637655007668, 46.40605046898379]],
    [ "FHYT",[6.256352105990731, 45.30494102317279]],
    [ "Central du Bacheux",[6.280587875149012, 45.32780130278379]],
    [ "Enedis",[1.96469084261761, 48.9173430257986]],
    [ "Enedis",[3.227284927497263, 49.72336951985915]],
    [ "Enedis",[1.1860249754605727, 42.78785577545131]],
    [ "Enedis",[2.186652303720589, 48.897382051909446]],
    [ "Enedis",[3.3290162210895087, 47.1732501523263]],
    [ "GEGrenoble",[6.139017849999999, 45.04784324999999]],
    [ "Chambon",[6.136105743208934, 45.04452472917653]],
    [ "GRPH Barrage",[6.137710274681687, 45.04711629421643]],
    [ "Enedis",[1.0610328881316036, 43.72988066647601]],
    [ "Bois d'Amont",[6.8999501500000004, 45.28829520000001]],
    [ "Poste électrique de Lanslebourg",[6.886293610145905, 45.28617018589866]],
    [ "Flambeau 1",[6.9007382049753945, 45.286778634080555]],
    [ "Enedis",[7.050833999999999, 45.371660399999996]],
    [ "Enedis",[6.90647733344581, 45.276570968294955]],
    [ "Flambeau 2",[6.900777084110059, 45.28680575229348]],
    [ "Enedis",[7.090135551420974, 45.38098989233271]],
    [ "Enedis",[3.3203256999999997, 43.5013532]],
    [ "Enedis",[1.263346756815281, 42.79971411193616]],
    [ "Enedis",[5.606228844403885, 43.18700673538747]],
    [ "Enedis",[1.516090234637805, 43.736454597479]],
    [ "Enedis",[3.276360950000001, 43.48335405]],
    [ "Enedis",[3.278579913857988, 43.4830026723733]],
    [ "EDF",[6.5060934929917735, 45.480826323650106]],
    [ "Enedis",[-1.87537875, 46.848256350000014]],
    [ "Enedis",[1.3644926423940262, 47.74651120181054]],
    [ "Enedis",[1.3465734, 47.77376495]],
    [ "Enedis",[2.02678633191591, 49.07720080078035]],
    [ "Enedis",[2.0307939, 49.07745595000001]],
    [ "Enedis",[2.0280788810141748, 49.069707345962954]],
    [ "435919383",[2.022397805432939, 49.07665030971483]],
    [ "Enedis",[4.011580711456616, 46.13460827744936]],
    [ "Enedis",[4.003346143575851, 46.12377918660093]],
    [ "Enedis",[4.012260667124412, 46.137927483647445]],
    [ "Enedis",[3.84462756325905, 46.23903545676537]],
    [ "Enedis",[3.9483964999999985, 46.0116691]],
    [ "Enedis",[3.9358981123690038, 46.00870183163114]],
    [ "Enedis",[3.9395399359107177, 46.01210702966941]],
    [ "Enedis",[3.943596458198827, 46.01045141694046]],
    [ "Enedis",[3.9379689064591483, 46.010395184248296]],
    [ "Enedis",[6.918843439934102, 47.472035915754844]],
    [ "Enedis",[2.404709966504648, 51.0468417057767]],
    [ "Enedis",[-3.2295216248417367, 47.3715859964262]],
    [ "Enedis",[-1.888932841365932, 48.093318131790774]],
    [ "SRD",[0.11591295, 46.427515]],
    [ "Enedis",[2.672307409613478, 49.07872691613218]],
    [ "Enedis",[2.674943065991118, 49.084593254325725]],
    [ "Enedis",[2.6697058106148868, 49.082641033333346]],
    [ "Enedis",[2.6711952070183735, 49.07604548901414]],
    [ "Enedis",[2.6671737202257284, 49.080017890923685]],
    [ "Enedis",[2.6740874776469465, 49.08118836545563]],
    [ "Enedis",[2.6711625783958715, 49.073201475656056]],
    [ "Enedis",[2.6649368728846574, 49.07882558681178]],
    [ "Enedis",[2.668810210827284, 49.07797522218003]],
    [ "Enedis",[2.6770498705247783, 49.07772353754259]],
    [ "Enedis",[2.6741906018202304, 49.07841171717742]],
    [ "Enedis",[2.812017623446449, 48.38705435977492]],
    [ "Enedis",[2.8194755308376314, 48.386631450706695]],
    [ "Enedis",[2.823789888370325, 48.387281538784805]],
    [ "Enedis",[2.8176348012391186, 48.39059696351582]],
    [ "Enedis",[2.8227476523826973, 48.38812366800118]],
    [ "Enedis",[2.818213942376782, 48.383310648405704]],
    [ "Enedis",[2.8112852975237175, 48.38222622063907]],
    [ "Enedis",[3.113124233806299, 50.27820470056105]],
    [ "Enedis",[4.875838777576362, 44.09546258968901]],
    [ "Enedis",[4.8795196477712794, 44.09335148833675]],
    [ "SRD",[0.36384240000000007, 46.4792356]],
    [ "Enedis",[1.6937969490938454, 48.65021876796694]],
    [ "Enedis",[0.3581075338338381, 46.65101676052676]],
    [ "Enedis",[1.7008685958015872, 48.61564887919348]],
    [ "Enedis",[1.7297184461592812, 48.61791952171414]],
    [ "Enedis",[1.734246370943592, 48.62131209797435]],
    [ "Enedis",[1.7697677917091248, 48.56777050382677]],
    [ "Enedis",[1.7820832902238295, 48.576387373943646]],
    [ "Poste électrique de Flamanville-3",[-1.8828953499999999, 49.538645300000006]],
    [ "Poste électrique de Flamanville",[-1.88020975, 49.54125109999999]],
    [ "Enedis",[-1.8808491290692184, 49.53214116624379]],
    [ "Poste électrique de Flamanville-2",[-1.8833053358381364, 49.53787487058808]],
    [ "Poste électrique de Flamanville-1",[-1.8838715499999996, 49.53657945]],
    [ "Enedis",[1.8846713978694705, 48.69511902281265]],
    [ "Enedis",[1.8086531586212828, 48.47966725590943]],
    [ "Enedis",[1.8370337748251284, 48.479245323430554]],
    [ "Enedis",[1.8339351987150658, 48.47805317755773]],
    [ "Enedis",[1.9179493338188887, 48.91655653936895]],
    [ "RTE",[2.1779585275096656, 49.06718693053064]],
    [ "Enedis",[2.0977062560488107, 49.07829814935493]],
    [ "Enedis",[2.102287444567402, 49.07227855969213]],
    [ "Enedis",[2.093082186748979, 49.09382606861976]],
    [ "Enedis",[2.09533555, 49.09530905]],
    [ "Enedis",[2.0899395096776225, 49.09452286520461]],
    [ "437212961",[2.015132440558873, 49.119350087965344]],
    [ "Enedis",[2.022129178687644, 49.10950192286436]],
    [ "Cultura",[-1.1219317682814782, 46.17547340125287]],
    [ "Glacis du Fort",[-1.4873454000000015, 46.2009508]],
    [ "Le Prizet",[-1.369975041703368, 46.18796801745266]],
    [ "Martray",[-1.4830028850940737, 46.20225281707386]],
    [ "Rivedoux Bourg",[-1.276380758873811, 46.1597837818013]],
    [ "Rivedoux",[-1.2897788000000003, 46.17056385]],
    [ "Tirefous",[-1.4194839933636285, 46.19351922937532]],
    [ "Enedis",[-1.5440196329301457, 46.24181184936276]],
    [ "Enedis",[-1.1200898000000001, 46.17491845000001]],
    [ "La Beurliere",[-1.3124901, 46.154165299999995]],
    [ "Le clos Vauban",[-1.3642204935740105, 46.20546760892077]],
    [ "Enedis",[-1.28313225, 46.16725005]],
    [ "Enedis",[2.1172590448745314, 49.17039953712934]],
    [ "Enedis",[2.3227454241445282, 49.15904684745414]],
    [ "Enedis",[2.332723478275955, 49.159037928161055]],
    [ "Enedis",[2.319936160635342, 49.15627082057745]],
    [ "Enedis",[2.330910701588458, 49.15014759152451]],
    [ "Enedis",[2.337547338470603, 49.156759682099576]],
    [ "Enedis",[2.322905707126966, 49.15713611986546]],
    [ "Enedis",[2.3229628147352352, 49.15292102645274]],
    [ "Enedis",[2.318106, 49.1582686]],
    [ "Enedis",[2.3283763332872227, 49.15612753166484]],
    [ "Enedis",[2.3323701099736467, 49.15693108671997]],
    [ "Enedis",[2.2928364330677438, 49.15287852451307]],
    [ "EDF",[-0.6911096637809413, 45.254122422330845]],
    [ "EDF",[-0.6915400225052253, 45.25694150946156]],
    [ "EDF",[0.6527924713769562, 46.45664820248229]],
    [ "RTE",[0.6544901499999998, 46.455261099999994]],
    [ "EDF",[-0.6912265882895218, 45.25491018339787]],
    [ "EDF",[0.6537121500000002, 46.45532]],
    [ "EDF",[-0.6916608614657952, 45.25776879415452]],
    [ "RTE",[0.6549984272921967, 46.45757086674403]],
    [ "Enedis",[0.9430450499999996, 49.55421555]],
    [ "Enedis",[6.260090454043742, 48.138487567065404]],
    [ "Enedis",[2.7985036657670035, 49.43763950155085]],
    [ "SRD",[0.3765657744498121, 46.55788554045843]],
    [ "DP CRUE",[2.257968664815099, 48.71362446356708]],
    [ "RTE",[5.59929623986456, 43.18567542389795]],
    [ "RTE",[2.13346305, 51.0117438]],
    [ "Électricité de France",[2.135649111254192, 51.01327766629726]],
    [ "RTE",[2.1391315499999997, 51.01474304999999]],
    [ "RTE",[2.1381419093239726, 51.01423156053451]],
    [ "RTE",[2.136883024396907, 51.01358057819845]],
    [ "RTE",[2.1344061904004565, 51.012239654635096]],
    [ "Enedis",[0.9652148065238059, 49.53684097813666]],
    [ "Poste source de Verfeil",[1.6706417691262865, 43.631331018003486]],
    [ "Enedis",[3.2081809999999997, 43.468612900000004]],
    [ "Enedis",[3.2045036936156754, 43.46982497454222]],
    [ "réséda",[6.244402866311585, 49.08730889964491]],
    [ "réséda",[6.244547, 49.0858861]],
    [ "réséda",[6.242245214393939, 49.086740980844155]],
    [ "RTE",[6.3231254305619675, 45.25260984056004]],
    [ "Centrale des Clapeys",[6.349058056155061, 45.26820120767436]],
    [ "Enedis",[1.4180286336076702, 47.71931806830298]],
    [ "Enedis",[1.4074683425731216, 47.728727520601545]],
    [ "Enedis",[1.3885249056440836, 47.73066166400244]],
    [ "Enedis",[1.418625449999999, 47.7159486]],
    [ "Enedis",[0.9536009900271541, 49.54810285403941]],
    [ "Poste Abrial",[4.297502690308888, 45.11429398626065]],
    [ "Chalayon",[4.305335488985134, 45.11405028061627]],
    [ "École",[4.29395871846381, 45.113887009511416]],
    [ "Enedis",[0.9433621827270171, 49.542232385393135]],
    [ "Enedis",[1.3580999824955395, 47.699260862760845]],
    [ "Enedis",[1.3812767479361385, 47.69415346779596]],
    [ "Enedis",[1.3849670716676952, 47.69666829060341]],
    [ "Bourg",[4.345538967425664, 45.15788896905215]],
    [ "Chaudier",[4.351120717921617, 45.13020282093473]],
    [ "Enedis",[4.9897371999999995, 45.82499995]],
    [ "439131488",[-0.60044165, 43.387781149999995]],
    [ "Enedis",[1.4670652047398314, 47.20474519446845]],
    [ "Enedis",[3.140306291765766, 45.70103844328992]],
    [ "Enedis",[2.1313228613742603, 48.84578462459524]],
    [ "RTE",[-0.8853539268719622, 44.014148133548936]],
    [ "Enedis",[-0.6168609808591131, 44.61527283810018]],
    [ "La Garna",[4.24118135, 45.24898039999999]],
    [ "Chambeau",[4.241312049598328, 45.24685223349227]],
    [ "Enedis",[0.7465984799859038, 48.34864888665815]],
    [ "Enedis",[0.7425078138104841, 48.345278021812604]],
    [ "Enedis",[0.748799483862899, 48.343897990941656]],
    [ "Enedis",[0.74449210245614, 48.34669942714681]],
    [ "Enedis",[0.7477888849261419, 48.34762129432288]],
    [ "Enedis",[0.743167951769909, 48.347615793326845]],
    [ "Enedis",[0.7405391848601834, 48.346592342315795]],
    [ "Coquette",[5.549798630350404, 45.415887194890146]],
    [ "Poste électrique de Bris",[-0.4937685516040084, 45.77754138567335]],
    [ "Enedis",[4.990002680126528, 45.8279800903057]],
    [ "Osiris",[4.991376529909985, 45.82828614275962]],
    [ "Enedis",[4.033158251079653, 48.278253382579024]],
    [ "Enedis",[0.9554318964854596, 49.54410104464208]],
    [ "Enedis",[0.9536253728461348, 49.54373260515797]],
    [ "Enedis",[0.9521241856852314, 49.54201727266379]],
    [ "Enedis",[0.9557001160015474, 49.531707546366796]],
    [ "Enedis",[0.9585161854348149, 49.53067529356442]],
    [ "Enedis",[0.955567049379232, 49.53611825626412]],
    [ "439998525",[1.9664999801444958, 49.06622095229688]],
    [ "Enedis",[1.518738158426314, 43.499800715427284]],
    [ "Enedis",[0.9554152835216188, 49.541255639404966]],
    [ "Enedis",[6.778229678061815, 47.734808600778116]],
    [ "Poste Pontiffroy",[6.1755420584452425, 49.129354647934534]],
    [ "Enedis",[6.7768785000000005, 47.74178630000001]],
    [ "440245885",[1.9649366784295343, 49.06903347349886]],
    [ "FONTAINE",[6.767006833145905, 47.74406604848105]],
    [ "Enedis",[2.6442074446229786, 43.967506427169745]],
    [ "Enedis",[2.642428976746819, 43.96722594622714]],
    [ "RTE",[0.018535147261451376, 42.78542422159411]],
    [ "Poste de Montpezat",[4.229477830686068, 44.70269091843186]],
    [ "Poste de Montpezat",[4.231033582862179, 44.702010031479496]],
    [ "Poste électrique de Gien",[2.619034179083805, 47.69736530108454]],
    [ "Enedis",[4.0187285, 45.73836884999999]],
    [ "RTE",[-0.42203227273372373, 43.009712866114384]],
    [ "Enedis",[1.3399483016349274, 47.644761107754846]],
    [ "Enedis",[1.376457246145866, 47.67403813549778]],
    [ "Enedis",[1.3700270737503104, 47.66122558883294]],
    [ "Enedis",[1.2689179169409976, 47.501115321255384]],
    [ "Enedis",[1.2657448984162385, 47.49757058492921]],
    [ "Enedis",[1.263543208285635, 47.49846934292484]],
    [ "Enedis",[1.2657541672788843, 47.496128651901685]],
    [ "Enedis",[1.3324203501599055, 47.51158566664988]],
    [ "Enedis",[1.2886044190156436, 47.50154459854114]],
    [ "Enedis",[1.297459425368744, 47.49916585274391]],
    [ "Enedis",[1.3040211267999404, 47.50225224070865]],
    [ "Enedis",[1.2931576964812457, 47.49537217135221]],
    [ "Enedis",[1.2990395, 47.495834349999996]],
    [ "Enedis",[1.3004403499999986, 47.49836535]],
    [ "Enedis",[1.2974559275357496, 47.49501431247644]],
    [ "Enedis",[1.3326300230918369, 47.49713237583018]],
    [ "SRD",[0.3728612000000004, 46.555973900000005]],
    [ "Enedis",[1.4492571125979352, 47.66914230166785]],
    [ "Enedis",[1.447359833416704, 47.6761733113235]],
    [ "Enedis",[1.4708401661806814, 47.66737697181286]],
    [ "Enedis",[1.4661043722884248, 47.66725988550787]],
    [ "Enedis",[3.9825084341723507, 45.3225986983761]],
    [ "Vorey Sud",[3.9059533426032345, 45.184244922703215]],
    [ "Enedis",[6.2261082, 48.6759724]],
    [ "Enedis",[5.031723574013692, 47.31711076149455]],
    [ "Enedis",[3.0192011999999995, 43.19503645]],
    [ "EDF",[1.6651640185196228, 42.70551963294656]],
    [ "Enedis",[3.9934361900892332, 49.357052511738395]],
    [ "Enedis",[2.7284107238258954, 50.29239601406481]],
    [ "Enedis",[2.922907458296296, 47.39701934611271]],
    [ "Enedis",[2.924241701284078, 47.396472718638655]],
    [ "RTE",[1.9566409445126354, 47.73363499399681]],
    [ "Enedis",[2.0616866028984524, 49.04991495276685]],
    [ "Previeres",[6.428085858297331, 46.140716518490905]],
    [ "Enedis",[3.325538487012352, 45.37192385240969]],
    [ "Enedis",[3.3202403802434817, 45.372841462857394]],
    [ "Enedis",[3.316903589755646, 45.36951989322037]],
    [ "Enedis",[3.3161140264584343, 45.37492256337065]],
    [ "Enedis",[3.3270806500000005, 45.36689205]],
    [ "Enedis",[3.315053248422723, 45.36749851425274]],
    [ "Enedis",[3.3283016162099366, 45.374486694838836]],
    [ "Enedis",[3.3221662824150866, 45.368370693727705]],
    [ "Chef-Lieu Villaz",[6.191149950000001, 45.950834500000006]],
    [ "Enedis",[6.1639826166996, 45.94799899865272]],
    [ "Enedis",[6.193710584510079, 45.94850507987282]],
    [ "Chez Bornand",[6.194227650000001, 45.93866310000001]],
    [ "Enedis",[0.5456065466374445, 45.087658147336406]],
    [ "Enedis",[1.1983771218582961, 47.5907760101706]],
    [ "Enedis",[1.193825191351189, 47.58284063280596]],
    [ "Enedis",[1.2251166160572688, 47.575668921399625]],
    [ "Enedis",[1.3331609522477714, 47.50823055075551]],
    [ "Enedis",[1.3332827244207897, 47.504755789438526]],
    [ "Enedis",[1.335136358406321, 47.502864786118046]],
    [ "Enedis",[1.9303334786122581, 49.11011378565313]],
    [ "Enedis",[1.9325923999999997, 49.11173144999999]],
    [ "Cailhol",[2.8227768999999996, 43.36480089999999]],
    [ "Sous Station SNCF de Clérac",[-0.25066238295743365, 45.1779415596932]],
    [ "Enedis",[1.050756497583715, 47.59651609688555]],
    [ "Enedis",[1.2330682559075756, 47.613417011696605]],
    [ "Enedis",[1.2389323465481925, 47.61358848708963]],
    [ "Enedis",[1.23492555230434, 47.61442587340962]],
    [ "Enedis",[1.23955927315715, 47.61452600293811]],
    [ "Poste source de Morbras",[2.6515807170507677, 48.778696890626044]],
    [ "Enedis",[1.849929007420424, 49.108767275778945]],
    [ "Enedis",[1.2744297463263283, 47.610657305536805]],
    [ "Enedis",[1.2753033353869676, 47.60747722985559]],
    [ "Enedis",[1.2761470000000004, 47.609775600000006]],
    [ "Enedis",[1.27073355, 47.611727249999994]],
    [ "Enedis",[1.2668644518290866, 47.60799594685815]],
    [ "Enedis",[1.2735699499999997, 47.607941649999994]],
    [ "Enedis",[1.2690944505773354, 47.60996610700865]],
    [ "Enedis",[1.2735303991984428, 47.601751945528484]],
    [ "Enedis",[1.2716620842331894, 47.60412466098234]],
    [ "Enedis",[1.2686284816813838, 47.60322007223122]],
    [ "Enedis",[1.2641023171100614, 47.60415194388463]],
    [ "Enedis",[1.2655121648684609, 47.60039141660994]],
    [ "Enedis",[1.2846842933329707, 47.590984050979124]],
    [ "Enedis",[1.2924402679164575, 47.59790390151642]],
    [ "Enedis",[1.2924735452360678, 47.58953945573188]],
    [ "Enedis",[1.29319036754216, 47.592513598966626]],
    [ "Enedis",[1.29624948032259, 47.589212043452164]],
    [ "Enedis",[1.013622231078097, 47.693361609488726]],
    [ "Enedis",[1.014397655078243, 47.687488685591134]],
    [ "Enedis",[1.0217046056187522, 47.687443026956224]],
    [ "Enedis",[1.0164010184960313, 47.686057361434365]],
    [ "Enedis",[1.0153505391775015, 47.69043828884073]],
    [ "Enedis",[1.0175162740874273, 47.68914064312459]],
    [ "Enedis",[1.0119435500000011, 47.69437175]],
    [ "SRD",[0.3069499, 46.52462760000001]],
    [ "Enedis",[3.9853858354921465, 49.92102809972391]],
    [ "Enedis",[1.1702585643962182, 47.50075540591759]],
    [ "Enedis",[1.1691643510482572, 47.49926528561885]],
    [ "Enedis",[1.194245118785088, 47.50393660084774]],
    [ "Enedis",[3.9806161159511793, 49.51571251346429]],
    [ "Enedis",[3.9903908, 49.504668849999995]],
    [ "Enedis",[3.980658016395741, 49.513816905658786]],
    [ "Enedis",[0.59212485, 46.93216215]],
    [ "Enedis",[2.3790259091490724, 47.84255000671784]],
    [ "Enedis",[4.022466906480007, 49.3816037683623]],
    [ "Enedis",[4.029489347709819, 49.379942868713826]],
    [ "Enedis",[1.497581422425735, 47.24692450494054]],
    [ "Enedis",[1.1338900165684582, 47.46372436310059]],
    [ "Enedis",[1.1358907876937885, 47.46475242571929]],
    [ "Enedis",[1.105817828618311, 47.50997822522689]],
    [ "Poste électrique de Vaires sur Marnes",[2.655337167520699, 48.87441790995158]],
    [ "Enedis",[3.972435, 49.439908800000005]],
    [ "Enedis",[3.970179525635192, 49.43887236115375]],
    [ "Enedis",[3.9706684839964295, 49.441254055128844]],
    [ "Enedis",[3.97239903069556, 49.43349931874496]],
    [ "Enedis",[3.9673673809903764, 49.43675659633888]],
    [ "Enedis",[3.9655864136534524, 49.434257275571085]],
    [ "Enedis",[3.9504051000000002, 49.43134464999999]],
    [ "Enedis",[3.949754342071224, 49.43246972156826]],
    [ "Enedis",[3.9521500498196955, 49.43426484808382]],
    [ "Enedis",[3.9591888880518837, 49.43479470987746]],
    [ "Enedis",[3.950479352146214, 49.431866105017825]],
    [ "Enedis",[3.9504968572420114, 49.43140484487207]],
    [ "Enedis",[3.9497286, 49.43102625000001]],
    [ "Enedis",[3.94990866365697, 49.43258190464677]],
    [ "Enedis",[3.9647590208750465, 49.436640476387105]],
    [ "Enedis",[3.981705418862276, 49.41408991911177]],
    [ "Enedis",[3.850517210978908, 49.49612125100264]],
    [ "Enedis",[3.9109473808243163, 49.621155897519394]],
    [ "Enedis",[3.0713320931433974, 43.620936722246064]],
    [ "Enedis",[1.2899188858354906, 42.84788537483463]],
    [ "réséda",[6.1474211973348325, 49.0733934666205]],
    [ "Enedis",[3.8410549571531014, 49.73473638336322]],
    [ "Électricité de France",[1.7586197121724072, 48.96831959518653]],
    [ "Électricité de France",[1.7568163636005958, 48.96843258391943]],
    [ "Électricité de France",[1.7595036272514293, 48.968282899012586]],
    [ "Électricité de France",[1.7576986113837525, 48.968383310886395]],
    [ "réséda",[6.15389245, 49.11320495]],
    [ "Molières",[4.510809485083055, 45.05679310264938]],
    [ "Poste électrique de Blénod",[6.0847510373865115, 48.868116580740484]],
    [ "EDF",[2.067736070233415, 45.07114422426032]],
    [ "SRD",[0.274399202741606, 46.604182967891646]],
    [ "Enedis",[7.3451828559909, 47.6619116794893]],
    [ "réséda",[6.097665440946639, 49.0753693654767]],
    [ "Enedis",[6.420272850000001, 43.15931435000001]],
    [ "réséda",[6.2172502, 49.137685149999996]],
    [ "Sous-station d'Asnières-sur-Nouère",[0.0654291907894974, 45.71413174038802]],
    [ "Enedis",[-0.6094561555635535, 44.801227763883595]],
    [ "Albert",[-0.6105559499999998, 44.8063167]],
    [ "Poste électrique ferroviaire",[-0.46997624179052716, 46.30176298600904]],
    [ "Électricité de France",[2.4182971919737657, 48.78610484217407]],
    [ "EDF",[2.4186602185507398, 48.786703098472984]],
    [ "RTE",[2.461065039452433, 48.75677840374151]],
    [ "Poste électrique Guesde",[2.4176486481594637, 48.78808912829439]],
    [ "Les Champais",[6.17851225, 45.87739405]],
    [ "Mairie",[6.180381049999999, 45.881627999999985]],
    [ "Enedis",[1.7675650847893407, 49.14499818263727]],
    [ "Centre Vallon",[6.134202470932977, 45.92252174522847]],
    [ "Cuisine Centrale",[6.13109253364642, 45.92038633074078]],
    [ "Edelweiss",[6.131070808503801, 45.9204174301151]],
    [ "Les Cedres",[6.1336341181527905, 45.92058408698889]],
    [ "Sajama",[6.131911508849356, 45.92214325429]],
    [ "ZUP 16",[6.130424392327994, 45.92062321752436]],
    [ "Enedis",[3.736987140205443, 49.7379953252592]],
    [ "réséda",[6.157027300000001, 49.156147700000005]],
    [ "Sous station SNCF",[-0.30778290886493304, 47.874075254450716]],
    [ "Poste électrique des Renardieres",[2.8495262016886134, 48.372652397041094]],
    [ "SRD",[0.16326619945240942, 46.444441366631814]],
    [ "Poste de Rom",[0.1455700131220509, 46.32415226791954]],
    [ "Enedis",[2.7109095811005717, 50.29116681058811]],
    [ "Enedis",[2.7305189472504305, 50.28259972674012]],
    [ "Enedis",[2.7272518, 50.2922813]],
    [ "Enedis",[2.722295824278797, 50.283063386444994]],
    [ "Enedis",[2.726194042273269, 50.27787938350326]],
    [ "Enedis",[2.657447565907938, 46.820251666022756]],
    [ "Enedis",[1.7729665274027784, 49.072383700884004]],
    [ "Enedis",[1.7698869659067564, 49.06911691598858]],
    [ "Arboras",[4.70469032097289, 45.68460926680484]],
    [ "Électricité de France",[4.755436706754579, 44.63455066476705]],
    [ "EDF",[2.5197337160761637, 47.733065249791125]],
    [ "Électricité de France",[4.756079857946787, 44.63520385094065]],
    [ "EDF",[2.5166935301289266, 47.73471295595537]],
    [ "EDF",[2.518801664101939, 47.73355698043058]],
    [ "EDF",[2.5175899623315656, 47.73423197696661]],
    [ "Électricité de France",[4.7530765729218905, 44.63211454020754]],
    [ "Électricité de France",[4.7537113766578285, 44.632769387150645]],
    [ "Enedis",[-0.42287983179907124, 48.55757829900226]],
    [ "Enedis",[1.7171546790540508, 49.14033833626527]],
    [ "réséda",[6.1731042909266005, 48.998905231245324]],
    [ "Enedis",[2.324589208109373, 47.84247868236849]],
    [ "Enedis",[2.32684435066228, 47.84118826439817]],
    [ "Électricité de France",[4.791066278361576, 50.089037394632]],
    [ "Électricité de France",[4.788800149999999, 50.08872505]],
    [ "Enedis",[2.2903438059515944, 46.640725778695405]],
    [ "Enedis",[2.316629509643957, 46.642420152222186]],
    [ "Enedis",[3.0166444309937153, 50.340540243269366]],
    [ "Poste source de Frasne",[6.161146930861677, 46.87224963067975]],
    [ "Enedis",[2.7324774940565533, 50.287544966658565]],
    [ "Enedis",[1.3706287061766023, 43.60226427355764]],
    [ "RTE",[5.081411855346609, 49.60483151190338]],
    [ "Enedis",[0.709779215867032, 44.389827096194836]],
    [ "Enedis",[3.0052113796018793, 50.33516277734651]],
    [ "Électricité de France",[-3.870135330152136, 48.357134472672364]],
    [ "Électricité de France",[-3.8701835293549647, 48.35678743605139]],
    [ "Enedis",[0.7168076211381057, 44.40687649737993]],
    [ "Enedis",[-0.15218465, 43.902515550000004]],
    [ "SRD",[0.1714644999999993, 46.6782438]],
    [ "Enedis",[0.3286151569446132, 49.69733737880607]],
    [ "RTE",[5.7094958573801255, 45.11959091012876]],
    [ "RTE",[5.708475385706377, 45.11994734842447]],
    [ "Enedis",[4.1587784999999995, 47.18368365]],
    [ "450076034",[5.7010763118706445, 45.12304526504713]],
    [ "Enedis",[1.6652759684065124, 47.276913359803814]],
    [ "Enedis",[1.6674430841996513, 47.2820044540621]],
    [ "Enedis",[1.6686395727073842, 47.277000161817035]],
    [ "Enedis",[1.6717705693018354, 47.27785548493335]],
    [ "Enedis",[1.6640031177254464, 47.280684392489825]],
    [ "Enedis",[4.704825992530977, 45.14842893965861]],
    [ "Bajolaz",[6.411162822431871, 46.16094970978212]],
    [ "SER",[7.748163802586994, 48.952253434533105]],
    [ "réséda",[6.251695999999999, 49.107629700000004]],
    [ "Enedis",[1.4209453316423215, 48.86821786404523]],
    [ "Enedis",[3.1528660499999996, 45.747343449999995]],
    [ "Enedis",[3.152185905898691, 45.74778231006418]],
    [ "Enedis",[1.380200053834298, 47.83273820658959]],
    [ "SRD",[0.26681983156079486, 46.56788540163373]],
    [ "4 Vents Coupure",[6.015701800735274, 48.79536642213936]],
    [ "Coop Agricole",[6.004130467747271, 48.79685339150954]],
    [ "Enedis",[6.037960372374056, 46.31017089088402]],
    [ "SRD",[0.4470176039467647, 46.620833815572894]],
    [ "Les Claisets",[6.068649691340153, 46.297341987943796]],
    [ "CNR;RTE",[4.643647655930321, 43.82411469292648]],
    [ "Enedis",[6.039553492370036, 46.28111889954523]],
    [ "Flotte",[4.8982151499999995, 43.804795299999995]],
    [ "Enedis",[4.662643088401224, 45.14397266779279]],
    [ "Enedis",[4.6829348522189935, 45.137992993190345]],
    [ "Grand Pré",[4.683505580211883, 45.69887661648314]],
    [ "Paqueminot",[4.701409347046268, 45.68968272816234]],
    [ "Simon Rousseau",[4.6753168, 45.6967899]],
    [ "Enedis",[2.7552419897426152, 47.2582962950958]],
    [ "Enedis",[0.9730184685354302, 48.37169071630393]],
    [ "Enedis",[0.986241212679137, 48.34546726270813]],
    [ "Poste RTE de Tourettes",[6.718020932973651, 43.60843003937546]],
    [ "Enedis",[0.9972807366622759, 48.30977407123789]],
    [ "Enedis",[0.9929399304936676, 48.31234628938096]],
    [ "Enedis",[0.9905201917988178, 48.31114281643988]],
    [ "Enedis",[0.991876696234615, 48.308483489469204]],
    [ "Enedis",[0.9969395913670988, 48.30683018702391]],
    [ "Enedis",[0.9885918690249478, 48.3064120984252]],
    [ "Enedis",[0.9912293895450172, 48.30656633159019]],
    [ "Enedis",[3.037490050000001, 42.622088000000005]],
    [ "Enedis",[1.0219337842067482, 48.307660041361565]],
    [ "Enedis",[1.0584705829313554, 48.293948152827475]],
    [ "RTE",[2.290769097319886, 50.27174092819842]],
    [ "Enedis",[1.1093489330020705, 48.32441290192401]],
    [ "Enedis",[6.246798108850671, 46.188380021240334]],
    [ "Enedis",[1.6442643000000001, 50.61880235]],
    [ "Enedis",[2.873667159755079, 47.20791660028228]],
    [ "Enedis",[2.855065164016222, 47.22983890482625]],
    [ "Enedis",[2.9013964484402925, 49.76547526448315]],
    [ "Enedis",[2.9038823322060554, 49.762229046038165]],
    [ "Enedis",[2.9125762833936624, 49.76361547437492]],
    [ "Enedis",[1.9676203912917283, 48.82516170122678]],
    [ "Enedis",[2.917834454029492, 49.76190880592291]],
    [ "Enedis",[2.9159930114635433, 49.759399566961676]],
    [ "Enedis",[-0.5587710500000005, 43.79264845]],
    [ "Poste électrique de la Saline",[55.280537841578564, -21.08907596417683]],
    [ "Poste électrique de Saint-Leu",[55.302305473834465, -21.200455202303576]],
    [ "Poste électrique de Saint-André",[55.62228180659346, -20.96314678380732]],
    [ "454676139",[2.5481055897408518, 47.13508937964545]],
    [ "Enedis",[2.90019863365854, 49.75715118674865]],
    [ "Poste électrique de Rueil",[2.168446575773744, 48.89075102821146]],
    [ "Enedis",[2.910265961956271, 49.75776691756806]],
    [ "Poste électrique de Pont-Évêque",[4.927894385142136, 45.5214330143077]],
    [ "Enedis",[0.8628576889024248, 48.31211434429696]],
    [ "Enedis",[0.8632164046132579, 48.31354927975964]],
    [ "Enedis",[2.914414381802801, 49.754685579834614]],
    [ "Sous-station SNCF de La Castelle",[3.87222838774667, 43.567983525895386]],
    [ "Enedis",[0.7045385456273089, 44.402533862048486]],
    [ "Enedis",[2.449366143946188, 47.168352847533626]],
    [ "Poste électrique de Feissons",[6.470737857035636, 45.563756361078276]],
    [ "Poste électrique de Viclaire",[6.8606190833416685, 45.600648297233974]],
    [ "455838180",[6.923771533116585, 45.50481300891504]],
    [ "GEG",[5.7126292776153935, 45.187336411896666]],
    [ "Enedis",[6.270909347295148, 48.71955721804505]],
    [ "SRD",[0.7222610777457478, 46.48463590940794]],
    [ "Électricité de France",[55.47642654999999, -20.91750205]],
    [ "Électricité de France",[55.47656000016816, -20.91783134085368]],
    [ "Enedis",[0.6945204999999984, 46.239363450000006]],
    [ "Enedis",[0.6935018, 46.23913115]],
    [ "Enedis",[0.69207525, 46.238479799999986]],
    [ "Enedis",[2.431871514574184, 47.188558378462076]],
    [ "Enedis",[2.653275143623708, 42.48593024712568]],
    [ "Enedis",[1.5790181016804008, 43.79600096757957]],
    [ "Enedis",[2.8038263824404766, 42.694678736050165]],
    [ "Enedis",[2.7977180563492734, 42.69327862216423]],
    [ "Les Mouilles",[6.51218295, 46.2492272]],
    [ "Enedis",[0.9195906650560458, 48.34691527942034]],
    [ "Poste Électrique de St Rémy des Landes",[-1.6612378175358262, 49.29716653232419]],
    [ "RTE",[1.863797978190504, 47.91021725789111]],
    [ "Enedis",[4.0228718910438745, 49.33608205848966]],
    [ "Enedis",[4.034583302482313, 49.35106644761162]],
    [ "Mairie",[4.022867648829493, 49.33986822618126]],
    [ "Enedis",[4.0256506086748205, 49.34295054603292]],
    [ "Enedis",[6.250528035030813, 48.70172122111964]],
    [ "Enedis",[6.250110275936303, 48.70318114029311]],
    [ "Enedis",[6.251120217023939, 48.70448627377833]],
    [ "Enedis",[6.250445350000001, 48.6999831]],
    [ "SRD",[0.4957461, 46.554365999999995]],
    [ "Les petits Pierres",[6.183274601568595, 46.04646195332445]],
    [ "EDF",[6.594393845880423, 45.752188965052135]],
    [ "EDF",[5.791699358672767, 45.04635685779428]],
    [ "Enedis",[-0.6358261, 43.927346]],
    [ "RTE",[-1.0913838271812093, 46.130206074342986]],
    [ "Enedis",[2.347064061334012, 47.15296748090876]],
    [ "Enedis",[2.342469954981399, 47.152782857809726]],
    [ "Enedis",[2.3274353453503904, 47.183333726220056]],
    [ "Enedis",[2.3501395320787584, 47.155311843379266]],
    [ "Enedis",[2.8018669898446995, 42.69508483074866]],
    [ "Enedis",[2.850010740668274, 47.32982023492131]],
    [ "Enedis",[2.8276062783604066, 47.32638611153432]],
    [ "Enedis",[2.7989331066702285, 47.33660291375512]],
    [ "Enedis",[2.802442271301167, 47.33776424050619]],
    [ "Enedis",[2.834045205738733, 47.328067013595664]],
    [ "Enedis",[2.82849473548228, 47.329040949925144]],
    [ "Enedis",[2.8359468340924296, 47.33126543086796]],
    [ "Enedis",[2.320666013942855, 47.13316883130014]],
    [ "Enedis",[0.8412190851789755, 48.620204404130384]],
    [ "Enedis",[0.9013344250199379, 48.613938260359475]],
    [ "Enedis",[0.8993534971015942, 48.60997226756187]],
    [ "Enedis",[0.9012249595926966, 48.61189934338912]],
    [ "Enedis",[0.9032465588331409, 48.61153896864756]],
    [ "Enedis",[0.9055308150400451, 48.60972611167755]],
    [ "SNCF Réseau",[1.5155236005316048, 45.091135362428936]],
    [ "Enedis",[0.9920241324988086, 48.6482893134899]],
    [ "Enedis",[2.2807051524661355, 47.10251291523106]],
    [ "Enedis",[2.286756128155341, 47.10077060539667]],
    [ "Enedis",[2.286071153160253, 47.098770820321434]],
    [ "Enedis",[2.2828174556732397, 47.09657575726932]],
    [ "Enedis",[2.2878813449411837, 47.10221867798649]],
    [ "Enedis",[2.283422144021691, 47.10090983112481]],
    [ "Enedis",[2.5532073, 48.07502015]],
    [ "Enedis",[3.530288621530612, 49.1726071187375]],
    [ "Enedis",[3.2885981842482113, 49.103616416117745]],
    [ "Enedis",[2.7698291999999993, 47.9821661]],
    [ "Enedis",[1.0804809669027013, 48.62424313166798]],
    [ "Enedis",[2.428987120564117, 47.144711442085274]],
    [ "Enedis",[2.4337664046365832, 47.13775197814085]],
    [ "Enedis",[2.4303380082326274, 47.136700897797084]],
    [ "Enedis",[2.4251526651217916, 47.14779898560934]],
    [ "Enedis",[2.428720020207016, 47.13954908388827]],
    [ "Enedis",[1.8813586855705868, 48.26966053266799]],
    [ "Enedis",[1.1767629052352266, 48.70200589404363]],
    [ "Enedis",[1.1765980085674852, 48.70518775611862]],
    [ "Enedis",[0.8876116362560413, 48.19617243953382]],
    [ "Enedis",[0.8912686506307368, 48.20544132188308]],
    [ "Enedis",[0.8891848288711407, 48.19584455156047]],
    [ "Enedis",[2.4864904387922353, 47.14595571752683]],
    [ "Enedis",[1.3914599998968324, 46.35398439705841]],
    [ "Enedis",[1.0253381375667905, 48.69927174254006]],
    [ "Enedis",[5.93157974944481, 45.879950272947895]],
    [ "Enedis",[1.50434977725098, 48.308741841443414]],
    [ "RTE",[4.68958808898085, 48.24884248618277]],
    [ "Enedis",[1.0431925339735255, 48.73512794586281]],
    [ "Enedis",[0.3616060154459411, 46.55992006857693]],
    [ "Enedis",[-0.8421429346118786, 45.57887734530774]],
    [ "Enedis",[0.3654216175729199, 46.560490564933474]],
    [ "Enedis",[0.36205276730684255, 46.56056482936392]],
    [ "SRD",[0.31273553038045265, 46.6204013747523]],
    [ "Enedis",[3.570338626335271, 49.06162845058977]],
    [ "Enedis",[3.162980609050391, 49.15859593708576]],
    [ "Enedis",[2.3750038930888655, 51.0363079658612]],
    [ "Enedis",[2.38481375, 51.02910130000001]],
    [ "Enedis",[1.5468113000000001, 43.524762599999995]],
    [ "Enedis",[1.8749514837467671, 48.67058931251078]],
    [ "Enedis",[1.8810100752525953, 48.667868098830866]],
    [ "Enedis",[1.8785770140393772, 48.67318949640054]],
    [ "Enedis",[1.7716400262531757, 48.630604567625724]],
    [ "Enedis",[1.7836226453585564, 48.6285790823063]],
    [ "Enedis",[0.053900228523976634, 44.6956410290532]],
    [ "Enedis",[1.0149628460179865, 48.50370427795543]],
    [ "Enedis",[1.4303734999999997, 43.749600199999996]],
    [ "Enedis",[2.3672718500000003, 51.0233186]],
    [ "Enedis",[2.384136416232357, 51.01975147942454]],
    [ "Enedis",[2.985551174931574, 49.564066279769854]],
    [ "Sous-station du Pont des Chèvres",[6.565184850000001, 45.2084093]],
    [ "Perrine",[6.3134267840505185, 46.065162700911394]],
    [ "Enedis",[6.710258298280106, 45.88895030000331]],
    [ "Enedis",[3.9471927197582404, 49.323854403991845]],
    [ "Enedis",[1.2554339045368466, 48.65452692970273]],
    [ "Sous-station de Ventabren",[5.332538311055789, 43.540641866625094]],
    [ "Enedis",[2.4815268999486824, 48.59849757234897]],
    [ "Enedis",[1.6104007987828808, 48.03246485806807]],
    [ "Enedis",[0.8381489150339326, 49.508717574603]],
    [ "Enedis",[0.8384146833726351, 49.50741825004156]],
    [ "Enedis",[0.8423250445635546, 49.51050743105934]],
    [ "SNCF Réseau",[6.720726882807004, 45.9263916500652]],
    [ "déchets verres, papiers et vêtements",[2.1755941107581678, 47.60961970387861]],
    [ "Enedis",[0.8822424677263239, 49.43690525223973]],
    [ "Enedis",[1.0053201321652365, 48.48005103137962]],
    [ "Enedis",[0.25081813729621816, 48.542140301817824]],
    [ "Aciéries de Champagnole",[5.914963059528408, 46.74529633427113]],
    [ "Enedis",[1.105494983590193, 48.57018797022683]],
    [ "SRD",[0.18965728712881494, 46.192962343872104]],
    [ "Enedis",[1.6635618930313094, 48.09133003411833]],
    [ "Enedis",[1.648492005255103, 48.10398165432791]],
    [ "Enedis",[1.6650097151284011, 48.09179495262646]],
    [ "RTE",[1.8779960873095887, 44.668707451689656]],
    [ "Enedis",[1.5607553405205183, 48.10191460661953]],
    [ "Enedis",[1.1562602214249478, 48.540078291115314]],
    [ "Enedis",[1.1924005124394004, 48.50505947682063]],
    [ "EDF",[5.592093874979307, 46.29307882566023]],
    [ "ESSeyssel",[5.92156225, 45.94975675]],
    [ "Enedis",[1.3266216574044032, 48.677502738976905]],
    [ "Enedis",[1.3319675225728977, 48.67983945505883]],
    [ "Enedis",[1.3305773589213676, 48.67671801252405]],
    [ "Enedis",[1.3696413, 48.1061942]],
    [ "Enedis",[1.3321046052940027, 48.096696605453545]],
    [ "Enedis",[1.3661379274014123, 48.1057765929684]],
    [ "Enedis",[1.4115109761698823, 48.103513341824545]],
    [ "Enedis",[1.0145527091628725, 48.59333387087801]],
    [ "Enedis",[1.6853733979934131, 48.124327864566744]],
    [ "Enedis",[1.658726425840917, 48.14021654684913]],
    [ "Enedis",[1.667323913937808, 48.16452615669862]],
    [ "Enedis",[1.811288730559779, 48.22561163593456]],
    [ "Enedis",[1.8470563846283168, 48.23901830983023]],
    [ "Enedis",[1.8598753542078528, 48.23966783134036]],
    [ "Enedis",[1.2502578150236472, 48.570516206080704]],
    [ "Enedis",[1.2477797641574842, 48.578591577943826]],
    [ "Enedis",[1.2303948369626285, 48.56558959082821]],
    [ "Enedis",[1.2413828174945338, 48.57720959064842]],
    [ "Enedis",[1.2483821756283753, 48.57298886731824]],
    [ "Enedis",[1.9315121645048932, 48.23003567256829]],
    [ "Enedis",[1.9421294382506817, 48.23678392675663]],
    [ "Enedis",[-1.5392343587419983, 48.809012416054955]],
    [ "Enedis",[1.7258810858743443, 47.554428412585274]],
    [ "réséda",[6.151706332052304, 49.09392933700209]],
    [ "Enedis",[1.4566737231231326, 43.65946542430536]],
    [ "Rue de Verdun",[4.625407686540545, 48.96318653036173]],
    [ "Enedis",[1.8957241000000002, 48.17184255]],
    [ "Enedis",[1.8967679136612021, 48.1713305474317]],
    [ "Poste électrique du Pecq",[2.1056416159781466, 48.900299436956864]],
    [ "Enedis",[1.8529802551468457, 48.206430709506]],
    [ "Enedis",[2.9791153509006256, 42.61281893358894]],
    [ "Enedis",[2.9799081824186517, 42.61378051716984]],
    [ "Enedis",[1.9048783153308506, 48.334096381305116]],
    [ "Enedis",[1.0752326117187498, 48.044196262760416]],
    [ "Enedis",[1.0784298450958083, 48.038534781706176]],
    [ "Enedis",[1.0766817599262115, 48.046197344617795]],
    [ "Enedis",[1.0923977909714406, 48.03781560383896]],
    [ "Enedis",[1.0715312412868956, 48.04087854380797]],
    [ "Enedis",[1.066438807936882, 48.040002826580164]],
    [ "Enedis",[1.0773500618119405, 48.04643096466838]],
    [ "Enedis",[1.0704361424741642, 48.03998471251431]],
    [ "Enedis",[4.506649296479054, 47.09540744617995]],
    [ "Enedis",[3.9458405553141405, 49.2833857494392]],
    [ "Enedis",[3.9435863735668697, 49.29574543109672]],
    [ "Enedis",[3.2661238106199133, 47.13985419972358]],
    [ "Thou",[4.075252706670442, 46.60847767860711]],
    [ "SRD",[0.28393553057867854, 46.51252945468291]],
    [ "Enedis",[2.9922597776566815, 49.57905754703349]],
    [ "Enedis",[1.147060022672471, 48.62950014021585]],
    [ "Enedis",[2.4094976000000004, 48.550261500000005]],
    [ "RTE",[-0.5492010782398052, 47.943122420705585]],
    [ "Enedis",[1.304122303042623, 48.603440944419255]],
    [ "Les allées du château",[6.068683930684419, 46.25666989461578]],
    [ "Gecina",[6.071708699999999, 46.2509937]],
    [ "Pre bois",[6.09588580181883, 46.26622547204147]],
    [ "Les acculats",[6.06746245, 46.25587589999999]],
    [ "Jardins du Prieuré",[6.084216200000001, 46.2511042]],
    [ "Mategnin",[6.075651506139154, 46.25022536552977]],
    [ "Enedis",[2.4122186047214984, 47.80967972050421]],
    [ "Enedis",[2.4017827590403082, 47.81679803070311]],
    [ "Électricité de France",[-0.26005762322798226, 42.90964002146087]],
    [ "Électricité de France",[-0.18490221494054307, 42.967637271660585]],
    [ "RTE",[-0.1445176817739606, 42.98163271916809]],
    [ "RTE",[0.6457366, 47.105638600000006]],
    [ "REThones",[6.4302440999999995, 45.906596099999994]],
    [ "Chalets des Confins",[6.480984745202489, 45.920630719934124]],
    [ "Pompage Confins",[6.465302374635153, 45.91333625501874]],
    [ "Enedis",[1.5619739299884534, 43.717914852974275]],
    [ "Enedis",[1.495785949999999, 48.23444805000001]],
    [ "Enedis",[1.4890872313516907, 48.243079201233485]],
    [ "Enedis",[1.4841176239354557, 48.24435466255169]],
    [ "Enedis",[1.4743866083243338, 48.26815414464381]],
    [ "Enedis",[3.6016183000000006, 43.497166500000006]],
    [ "Eglise",[6.62599647132822, 45.936427746466336]],
    [ "Enedis",[1.459974521889326, 48.28268210927015]],
    [ "Enedis",[0.6139796747361953, 46.9716141092629]],
    [ "Poste électrique de Digue des Francais",[7.206854694711282, 43.676575670603015]],
    [ "Poste électrique de Cagnes sur Mer",[7.130525462546749, 43.66120302271377]],
    [ "RTE",[7.191894908794047, 43.68533612732856]],
    [ "Poste électrique de Tour Lascaris",[7.250481150376861, 43.70181308455895]],
    [ "RTE",[7.245735071670862, 43.70401918757914]],
    [ "Villelongue",[-0.05768575000000059, 42.95437185]],
    [ "Poste électrique des Galoreaux",[-0.8551681720483623, 47.31048464286968]],
    [ "Enedis",[2.27504845, 48.54826565]],
    [ "Poste électrique de Darcey",[4.555305924425018, 47.564349452490426]],
    [ "Enedis",[1.7377035829817815, 48.37654762829705]],
    [ "Enedis",[5.067714400000001, 47.33306005]],
    [ "Enedis",[1.4125271999999984, 48.2537575]],
    [ "Poste électrique de Palente",[6.054120159457455, 47.26741441667345]],
    [ "Enedis",[3.7295468893374966, 46.1553761902086]],
    [ "Enedis",[6.2910056, 46.30769934999999]],
    [ "Enedis",[-0.26073210640841243, 44.543371319681164]],
    [ "Enedis",[6.964793176915004, 48.28546374697559]],
    [ "Maconnex",[6.086374796023623, 46.28790425930184]],
    [ "Les Roussets",[6.105477700509909, 46.264525574062056]],
    [ "Vergers de la Tour",[6.100194555229233, 46.269553174876]],
    [ "Gendarmerie",[6.102178264834708, 46.2629098707911]],
    [ "Résidence étudiants",[6.1120858715253945, 46.264924911597326]],
    [ "Villard Tacon",[6.085566377295994, 46.28355287434841]],
    [ "Enedis",[6.078745867033403, 46.282512735860834]],
    [ "Clos des Pralets",[6.0824307751707005, 46.28825628162632]],
    [ "Les Eycherolles",[6.103247165922023, 46.266045441455184]],
    [ "SCI du Bruant",[6.08892029445626, 46.28559429757906]],
    [ "EHPAD",[6.112276549313513, 46.26359082001652]],
    [ "Parc de la Roche",[6.083957922140463, 46.281870508296905]],
    [ "Prenepla",[6.098935773200615, 46.26784094617041]],
    [ "Grands Horizons",[6.113233385242507, 46.26372851315027]],
    [ "Les Pralets",[6.079970379587392, 46.2834251861874]],
    [ "Clos St Brice",[6.099887312371004, 46.27212865412007]],
    [ "Le Lion",[6.0820740436201906, 46.286513074389795]],
    [ "Vergers d'Ornex",[6.099363278338945, 46.27030166666667]],
    [ "L'apparté",[6.097562639881008, 46.269258318044386]],
    [ "Jardin de Romain",[6.080186238618738, 46.28584577076356]],
    [ "La Culaz",[6.094212881543309, 46.27134929706709]],
    [ "Enedis",[6.086566518104913, 46.28454315644796]],
    [ "Les tulipiers",[6.088120620528134, 46.28866086691613]],
    [ "Mairie",[6.092834909893098, 46.27489290744214]],
    [ "Le Parc de Villard",[6.083547260118742, 46.28051212488117]],
    [ "La Maladière",[6.090595113794936, 46.283360782279516]],
    [ "Enedis",[1.1278083975388824, 48.09713800009961]],
    [ "Enedis",[1.1342546495288859, 48.76068533876661]],
    [ "Enedis",[5.55628465, 45.086203899999994]],
    [ "Enedis",[1.127587858767361, 48.10589634856749]],
    [ "Enedis",[-0.25184344999999997, 49.13452864999999]],
    [ "Enedis",[-0.2464034167830437, 49.13545298025651]],
    [ "Enedis",[-0.24497876674160463, 49.140426450274]],
    [ "Enedis",[-0.24617454999999971, 49.133892800000005]],
    [ "Enedis",[-0.25446953359245106, 49.12359321098659]],
    [ "Enedis",[0.6420640184496871, 48.426112846609165]],
    [ "Poste électrique de Joux-la-Ville",[3.8824289833263324, 47.6436208012049]],
    [ "Fouillet",[0.23982244999999996, 47.97427035]],
    [ "Enedis",[6.236086300000001, 46.205614249999996]],
    [ "Enedis",[6.516157372242365, 46.2572503532124]],
    [ "Enedis",[0.9355433999999999, 48.59321415]],
    [ "RTE",[1.8334473789744348, 50.6622593254179]],
    [ "Enedis",[2.758524172338612, 48.1088108759712]],
    [ "Enedis",[2.7532634500000004, 48.113950349999996]],
    [ "Enedis",[2.7281756000000015, 48.09931975]],
    [ "Enedis",[2.7582146999999995, 48.0994093]],
    [ "Enedis",[2.740886914449983, 48.132427954257146]],
    [ "Enedis",[2.753312045525092, 48.08481755005726]],
    [ "Poste électrique de La Rivière",[4.405142649439228, 45.41319282853938]],
    [ "Ces Paixhans",[6.183889385840308, 49.122667998782944]],
    [ "Enedis",[2.8697461599745195, 47.47351083886176]],
    [ "Enedis",[2.8701549670319926, 47.46957185236536]],
    [ "Enedis",[2.8727377655259434, 47.468903699158915]],
    [ "Poste d'Aiguebelle",[6.31522331482069, 45.526230107853095]],
    [ "Station EDF de Randens",[6.320260750153706, 45.52705009303556]],
    [ "Cousin",[6.044841316748112, 46.262055013767224]],
    [ "ZA Les Pins",[4.2631947, 45.25467870000001]],
    [ "Grand Chemin",[4.27134355, 45.2459782]],
    [ "Enedis",[4.264256199999999, 45.254687649999994]],
    [ "Poste électrique de Joux-la-Ville",[3.8817149549682486, 47.64330619422126]],
    [ "Kernevez",[-5.1043406000000004, 48.462157499999996]],
    [ "Poste électrique de Matras",[1.3659532257692346, 44.03822385727374]],
    [ "Enedis",[3.6231705869979276, 49.725402999131276]],
    [ "Enedis",[2.767497333396175, 47.995370108325105]],
    [ "Enedis",[2.3089300536042785, 48.55750228131141]],
    [ "Enedis",[3.60129967745177, 49.817212974657]],
    [ "Enedis",[3.6072584499999993, 49.81606235]],
    [ "Enedis",[3.622174575332554, 49.804384629316694]],
    [ "Enedis",[3.643232862674926, 49.82212858634121]],
    [ "Enedis",[3.6621656765444115, 49.82187051398535]],
    [ "Enedis",[3.710079130437121, 49.824262276812405]],
    [ "Enedis",[3.7067392092609395, 49.823918651874315]],
    [ "Enedis",[3.7694022090876427, 49.8470671693956]],
    [ "Enedis",[2.8328139963178898, 42.58938883282033]],
    [ "RTE",[3.920395125932222, 50.2712894967326]],
    [ "Enedis",[3.749725750000001, 49.92373845000001]],
    [ "Enedis",[2.644877836810883, 47.456861201394354]],
    [ "Enedis",[2.8412823499999997, 42.591147]],
    [ "Enedis",[2.7484290318893767, 47.496853186717445]],
    [ "Enedis",[4.85557665, 45.72527825]],
    [ "Enedis",[3.791075751821254, 43.55908967846275]],
    [ "Poste électrique d’Orsonville",[2.7983597339691766, 48.85270776880154]],
    [ "Enedis",[6.109887199999999, 48.658819300000005]],
    [ "Enedis",[-0.6638733819657106, 49.22586745123508]],
    [ "Enedis",[5.993451189130043, 46.25516837069676]],
    [ "SNCF Réseau",[6.336522424778991, 45.650500086546096]],
    [ "Geredis",[-0.13298341296992494, 46.22626256567982]],
    [ "Enedis",[3.1537378217614593, 46.961917298322426]],
    [ "Enedis",[3.1551977097791264, 46.96618278614628]],
    [ "Enedis",[3.155614102280043, 46.96112308993]],
    [ "Enedis",[3.156834882224517, 46.97070442910994]],
    [ "Enedis",[3.1348942559710413, 46.95122504473144]],
    [ "Enedis",[3.153630981248863, 46.94694894591298]],
    [ "Enedis",[3.14873057802543, 46.94556022476655]],
    [ "Enedis",[3.1375930380152943, 46.9482260339966]],
    [ "Enedis",[3.1549843968269484, 46.94896766071801]],
    [ "RTE",[5.5551987909953535, 47.13434951940389]],
    [ "Électricité de France",[55.431898000000004, -21.30227195]],
    [ "Poste source d'Aubusson",[2.1906842388746823, 45.95333509657296]],
    [ "Enedis",[3.7159680287241303, 49.894424045194285]],
    [ "Enedis",[4.697443849999999, 49.03555535]],
    [ "Poste électrique de la Croix-Verte",[3.922186779878469, 47.49886801748255]],
    [ "Enedis",[1.2830347222717857, 47.98048471757208]],
    [ "Enedis",[1.3294220280588627, 48.11396569242775]],
    [ "Enedis",[1.3342997068558804, 48.11626662724348]],
    [ "Enedis",[1.3337834881200685, 48.10529035031792]],
    [ "RTE",[1.61738603785382, 48.81151602171299]],
    [ "Enedis",[1.3501017390653798, 48.68972778929474]],
    [ "Enedis",[1.3645643500000006, 48.69368805]],
    [ "Enedis",[1.337211889034853, 48.70809729296581]],
    [ "Enedis",[1.3378230269162077, 48.70531774198853]],
    [ "Enedis",[1.3338707319051049, 48.70485966642634]],
    [ "Enedis",[1.3948167580343152, 48.76255417691834]],
    [ "Enedis",[1.398226833525466, 48.765292733143404]],
    [ "SRD",[0.30171103795464593, 46.27228271732567]],
    [ "Enedis",[1.2433485176757, 47.73328073321161]],
    [ "Enedis",[0.48810443582300034, 44.329791501132995]],
    [ "Enedis",[-0.925875021966427, 44.20473715285372]],
    [ "Enedis",[-0.9161759258495951, 44.19486751040511]],
    [ "Enedis",[-0.9235808297220246, 44.21068192644205]],
    [ "Enedis",[-0.9168267380418751, 44.18900372653146]],
    [ "Enedis",[-0.9181871922201679, 44.19634049031808]],
    [ "Enedis",[-0.925260954009919, 44.202930734424875]],
    [ "Enedis",[-0.9169353242123803, 44.200301912213796]],
    [ "Enedis",[-0.6523051999999998, 44.52860975]],
    [ "Enedis",[-0.9099699676368591, 44.238920768935095]],
    [ "Enedis",[-0.9140549420435371, 44.21199561173559]],
    [ "Enedis",[-0.9146487003663277, 44.21769690886907]],
    [ "Enedis",[-0.9119723888293665, 44.21497395936009]],
    [ "Enedis",[-0.9095470499999999, 44.21255955]],
    [ "Enedis",[-0.9130748232623821, 44.21390408139841]],
    [ "Enedis",[-0.6398053405008473, 44.49197502310341]],
    [ "Champ Moutons",[6.242680350000001, 46.198916]],
    [ "Enedis",[1.188936922761736, 47.734197137420026]],
    [ "Enedis",[-0.9122828590574661, 44.20843347346251]],
    [ "Enedis",[-0.9146817999999995, 44.20927725000001]],
    [ "Enedis",[-0.9173278499999994, 44.20419925]],
    [ "Enedis",[-0.9169132500000008, 44.2090214]],
    [ "Enedis",[1.2709371651221617, 48.06475353689592]],
    [ "Enedis",[1.2867397487293264, 48.06708969928735]],
    [ "Enedis",[1.2981404600447473, 48.069447801134416]],
    [ "Enedis",[1.292058649999999, 48.06723415]],
    [ "Enedis",[1.3026991674725017, 48.06567325383441]],
    [ "Enedis",[1.2993124333113308, 48.06641077432344]],
    [ "Enedis",[1.0593332416665566, 47.71799696056937]],
    [ "Enedis",[1.3025623215644553, 48.038433057548986]],
    [ "Enedis",[1.3119396801362553, 48.060005535845036]],
    [ "Enedis",[1.3103380532256208, 48.06264997435358]],
    [ "Enedis",[1.3071809795127354, 48.05718512524917]],
    [ "Enedis",[-0.9866909864744827, 45.60189089061735]],
    [ "Enedis",[1.6390630238127526, 49.218335897448036]],
    [ "Enedis",[0.6829280999999994, 47.364157049999996]],
    [ "Enedis",[0.6828449734495913, 47.364151808149266]],
    [ "Enedis",[0.6531274025135656, 49.78755641818915]],
    [ "Enedis",[0.36606064070974226, 49.72836525281777]],
    [ "Enedis",[0.36076054157269083, 49.719843419955765]],
    [ "Enedis",[0.36993445008782155, 49.74177087759694]],
    [ "Enedis",[1.2850612149376799, 48.20843576614254]],
    [ "Enedis",[6.245625377879995, 46.19998230144501]],
    [ "Enedis",[2.4265282018580816, 47.36341084129704]],
    [ "Poste électrique d'Issoire",[3.2544019229158057, 45.55583280159051]],
    [ "Enedis",[2.834918770023063, 42.53284921644949]],
    [ "Enedis",[2.2101665500000003, 49.835849450000005]],
    [ "Enedis",[3.0866580417585836, 45.04065036881779]],
    [ "Enedis",[3.0857283000000004, 45.041557]],
    [ "Enedis",[0.8743019596855386, 47.713592889474235]],
    [ "Enedis",[3.7342745125210506, 49.723342398018985]],
    [ "Enedis",[3.7353789075421684, 49.71711387095733]],
    [ "Enedis",[1.19931530342163, 48.77007264528298]],
    [ "Enedis",[1.2154744500000003, 48.762845549999994]],
    [ "Enedis",[1.197941457119592, 48.77043292147923]],
    [ "Enedis",[1.2159315500000003, 48.6737438]],
    [ "Enedis",[1.2384301476882602, 47.959371325221966]],
    [ "Enedis",[3.790125054300657, 49.857219703789006]],
    [ "Enedis",[3.7870767103180967, 49.86064163808251]],
    [ "Enedis",[3.6993792695177463, 49.803504121826336]],
    [ "Enedis",[3.7087091006635178, 49.79471592676231]],
    [ "Enedis",[1.2174031815733097, 48.162006249053]],
    [ "Tournesols",[6.245291450000001, 46.203607000000005]],
    [ "Enedis",[2.178802632232626, 48.49481660104247]],
    [ "Enedis",[3.4741333995934958, 49.610736313821135]],
    [ "Enedis",[3.4689973524367668, 49.551853459482544]],
    [ "Enedis",[1.3418938621913032, 47.94135041005047]],
    [ "Enedis",[3.6377111040037957, 46.66386591083211]],
    [ "Enedis",[3.5785907450699437, 46.524303188176255]],
    [ "Enedis",[3.6060226623931624, 46.654581598290605]],
    [ "Enedis",[3.6139725889127767, 46.65501452787674]],
    [ "Enedis",[3.6046953147137337, 46.65422132320198]],
    [ "Enedis",[1.77700305, 48.1715508]],
    [ "Enedis",[-0.7584611000000001, 44.36056225]],
    [ "Enedis",[-0.7537117, 44.409191250000006]],
    [ "Enedis",[-0.7511218000000002, 44.4142273]],
    [ "Enedis",[-0.75212265, 44.37367310000001]],
    [ "Enedis",[6.25792272708058, 48.717594209418515]],
    [ "Enedis",[0.5873926636618012, 49.782680286073095]],
    [ "Enedis",[0.5889409654315126, 49.78430588726842]],
    [ "Enedis",[1.101423216208416, 47.849022283544215]],
    [ "Enedis",[1.1132239126439665, 47.84081521601966]],
    [ "Enedis",[3.5086739336798796, 43.6482649060173]],
    [ "Enedis",[3.067875886971961, 46.42223290757716]],
    [ "Enedis",[3.1747932500000005, 46.403923000000006]],
    [ "SRD",[0.41193098988079474, 46.384396667185186]],
    [ "Enedis",[5.19670586603843, 46.169326944489846]],
    [ "Enedis",[5.20272795, 46.183004200000006]],
    [ "Enedis",[1.2885495445542157, 43.59706411545999]],
    [ "Enedis",[1.2891022999999997, 43.5979909]],
    [ "Enedis",[1.286404544340643, 43.59540026540411]],
    [ "Enedis",[1.2847773911354134, 43.5941223615453]],
    [ "Enedis",[5.197636925790785, 46.189156920183336]],
    [ "Enedis",[1.7045143999999997, 49.2896547]],
    [ "Poste électrique des Brizeaux",[-0.43420175187974447, 46.34803916969109]],
    [ "Enedis",[3.1450616, 50.73120540000001]],
    [ "Enedis",[3.5035835082281035, 49.51708358927503]],
    [ "Enedis",[0.9017620315449737, 47.64356619839155]],
    [ "Enedis",[0.8983369357895659, 47.650414668943455]],
    [ "Enedis",[2.9864951675486613, 42.637265500973236]],
    [ "Enedis",[2.9879948, 42.63545825]],
    [ "Enedis",[3.411462667428318, 49.54197863669389]],
    [ "Enedis",[2.798017274078374, 47.35856805033581]],
    [ "Enedis",[1.4244212591826368, 47.95530471526752]],
    [ "Enedis",[4.796068044583289, 45.18713972593131]],
    [ "Enedis",[1.2881420463486006, 43.719971809035016]],
    [ "Enedis",[1.0705380457722031, 49.39702576040297]],
    [ "Enedis",[1.317255921346399, 47.6095834761765]],
    [ "Enedis",[1.32747447541346, 47.6051576535149]],
    [ "Enedis",[1.3284485999999984, 47.61331794999999]],
    [ "Enedis",[1.3330905817946097, 47.60328834815749]],
    [ "Enedis",[1.3202223806729434, 47.6095406410148]],
    [ "Enedis",[1.3144169226581504, 47.610883384907254]],
    [ "Enedis",[1.3149651082245688, 47.61062149192453]],
    [ "Enedis",[1.335509248532247, 47.61041553272807]],
    [ "Enedis",[1.3222063250739728, 47.61255578407854]],
    [ "Enedis",[1.676555706701345, 48.59388152550727]],
    [ "Poste électrique de Soullans",[-1.8871678216497334, 46.78451998713784]],
    [ "Enedis",[3.0946945773079615, 47.170789224715314]],
    [ "Enedis",[-1.16754016554758, 45.679172197708525]],
    [ "Enedis",[3.0701619713993824, 47.214448526623016]],
    [ "Enedis",[5.89332704325516, 46.75712812337]],
    [ "Enedis",[3.329196693219687, 49.10766564464174]],
    [ "Naves",[6.189037061475853, 45.93451054696615]],
    [ "Epine",[6.187579449999999, 45.930095699999995]],
    [ "Enedis",[1.3005966138800142, 47.82585381355921]],
    [ "Enedis",[1.2967344049920562, 47.82123366141722]],
    [ "Enedis",[3.1181436265909497, 50.62962145290548]],
    [ "Poste électrique Avelon",[2.0677447755206915, 49.43503169046866]],
    [ "Enedis",[2.563324084437651, 47.5011479092885]],
    [ "Poste Électrique de Bigny",[2.370596333686571, 46.80394169868283]],
    [ "Poste Électrique Dun",[2.5923616386735238, 46.89138352477807]],
    [ "Poste Électrique Le Paradis",[2.385586951803961, 47.09904476976211]],
    [ "Poste électrique de Forges-les-Eaux",[1.557051486647111, 49.613233240894246]],
    [ "481392904",[1.9877542239470067, 49.08972239473664]],
    [ "Enedis",[3.5224790903422325, 49.06175622071298]],
    [ "Enedis",[2.2839065057481593, 48.66545199854142]],
    [ "Enedis",[3.2676869787257847, 48.991390000738974]],
    [ "Enedis",[3.268017249113006, 48.99820057873973]],
    [ "Enedis",[3.2662451499489764, 49.001608454185636]],
    [ "La Cosnière",[-1.5040995001554984, 47.453022454740044]],
    [ "Grand Siècle 02",[6.103070165396129, 46.253808246712055]],
    [ "Villa Victoria",[6.09981445, 46.2506492]],
    [ "Enedis",[0.9957440101791287, 48.45271227470558]],
    [ "Enedis",[1.2901846241656787, 47.824252833879065]],
    [ "Enedis",[4.928624091122797, 44.94952693685662]],
    [ "Enedis",[4.87795378625337, 44.90953755166216]],
    [ "Enedis",[1.9567611813690986, 46.764943521307934]],
    [ "Enedis",[1.294192649999999, 47.8231996]],
    [ "Enedis",[1.2858765939383854, 47.821096626007076]],
    [ "Enedis",[2.918540219794342, 47.39378051934258]],
    [ "Enedis",[2.9176816802528442, 47.383677070215306]],
    [ "Enedis",[2.9332272659872545, 47.38961490894276]],
    [ "Enedis",[2.9227547652900854, 47.385619820978036]],
    [ "Enedis",[2.921332973944488, 47.39998897694435]],
    [ "Enedis",[2.9198532995909026, 47.39932688383863]],
    [ "Enedis",[2.921187878049523, 47.38146744071399]],
    [ "Enedis",[2.9327233028204165, 47.388556031218215]],
    [ "Enedis",[2.9307565092815446, 47.39644767238463]],
    [ "Enedis",[2.9258786334707816, 47.39262853084058]],
    [ "Enedis",[2.9163536694887555, 47.38522535826783]],
    [ "Enedis",[2.9191212543786773, 47.39667662224038]],
    [ "Enedis",[2.9140540999999995, 47.3831882]],
    [ "Enedis",[2.9301082281386006, 47.39776963462134]],
    [ "Enedis",[2.9269868293129218, 47.39573965473539]],
    [ "Enedis",[2.9423978313006547, 47.400599992194344]],
    [ "RTE",[0.3241126281002816, 44.147366308050415]],
    [ "Trith Saint-Léger",[3.493645388423977, 50.338578038282115]],
    [ "Poste électrique Roubaix Nord",[3.1727874048342994, 50.703070050797564]],
    [ "Enedis",[1.2894655682937317, 47.81751625811391]],
    [ "Enedis",[1.2865879693774558, 47.816262774802404]],
    [ "Enedis",[1.2863747770302632, 47.81667566022208]],
    [ "Enedis",[4.999590722412603, 43.58867373742556]],
    [ "Enedis",[2.3651386, 48.6104653]],
    [ "CESML",[3.826959300000001, 43.650488499999994]],
    [ "CESML",[3.8360042425491283, 43.65095149122124]],
    [ "CESML",[3.83552640307436, 43.64856443158787]],
    [ "CESML",[3.8306645659765244, 43.6471482515504]],
    [ "CESML",[3.79253153261737, 43.649787529645074]],
    [ "CESML",[3.8004664338379706, 43.649487566477745]],
    [ "Enedis",[2.92390365, 47.157009450000004]],
    [ "Enedis",[-0.88138945, 44.48624095000001]],
    [ "Enedis",[-0.8868771211296989, 44.485778952935355]],
    [ "Enedis",[-0.8856680524418462, 44.48312202988978]],
    [ "Enedis",[2.9530326080069056, 47.217901906885274]],
    [ "Enedis",[-4.5761497, 48.491317249999994]],
    [ "Enedis",[0.947462340018535, 48.38713027199922]],
    [ "Enedis",[0.9611414681966145, 48.40645084595352]],
    [ "Enedis",[0.96092215, 48.4052879]],
    [ "RTE",[-1.0083179154812785, 47.17322102561481]],
    [ "RTE",[-0.4801101450575859, 47.38376735064982]],
    [ "Enedis",[-4.53873425, 48.45646365]],
    [ "Enedis",[1.4729125218928862, 48.871126893596234]],
    [ "Enedis",[1.4709863499999998, 48.87140375]],
    [ "Enedis",[1.4714763070679444, 48.86588412786138]],
    [ "Enedis",[3.5739653017904383, 49.20258474181683]],
    [ "Domaine du Lac",[6.14818708623532, 46.35667872784532]],
    [ "Enedis",[1.3172096236127395, 47.86575679339585]],
    [ "Enedis",[1.318652729857434, 47.852938234152006]],
    [ "Enedis",[-0.9890858435726759, 45.6161087700366]],
    [ "483415195",[2.5248709231793827, 47.10208180312429]],
    [ "Enedis",[2.4543537355141494, 42.77227921850379]],
    [ "Enedis",[1.7043895570857552, 49.2939294935239]],
    [ "SER",[7.346361633261114, 48.73834395208235]],
    [ "Enedis",[1.5714803693746033, 46.55142870198865]],
    [ "Enedis",[1.568185195162432, 46.55405875911259]],
    [ "Enedis",[2.757227629575393, 47.35007954154464]],
    [ "Enedis",[2.7500601000000002, 47.35116645000001]],
    [ "SRD",[0.5805853, 46.40850274999999]],
    [ "Enedis",[1.402141845009755, 48.015693131130064]],
    [ "Enedis",[1.38205245, 48.02222465]],
    [ "Enedis",[5.0758697671317, 45.048911733771995]],
    [ "Enedis",[0.7588868488333127, 47.82937356626512]],
    [ "Les Touts",[4.65891936648441, 45.223714601283085]],
    [ "Sables",[4.648520421449573, 45.22298365234353]],
    [ "Chaillot",[4.66718890018179, 45.23030034991845]],
    [ "Enedis",[5.078629184663271, 44.96658458200339]],
    [ "Enedis",[5.383614504325946, 45.02738771618974]],
    [ "Enedis",[5.175410424646107, 44.854351117449525]],
    [ "Poste électrique de Saint-Joseph",[-1.520632265496581, 47.2497570222508]],
    [ "Enedis",[0.7243896020711476, 47.76905012287212]],
    [ "réséda",[6.202790980468091, 49.138414358642315]],
    [ "réséda",[6.203377068134329, 49.13947029211658]],
    [ "Enedis",[-1.6335266280771061, 43.397251131778326]],
    [ "Enedis",[-1.6339171846739302, 43.39777698143879]],
    [ "Enedis",[1.3986602496821505, 47.98153888668286]],
    [ "Contamines",[3.0833835289233478, 46.19744302577477]],
    [ "Abattoirs",[3.0793790999999997, 46.20359515]],
    [ "Enedis",[2.8216887999999996, 50.31656365]],
    [ "Enedis",[2.822079740281751, 50.318021458007074]],
    [ "Silo",[3.0871942265911696, 46.201462846002244]],
    [ "Église",[3.0788452199355323, 46.19962350763531]],
    [ "Bel Air",[3.084340588222698, 46.200876490649534]],
    [ "Bellenaves",[3.077585929591599, 46.20045438670291]],
    [ "Les Chaises",[3.0878785500000014, 46.1983767]],
    [ "Enedis",[1.24869455, 43.57113895]],
    [ "485199936",[2.085404651911621, 49.01426209619959]],
    [ "Val",[1.9996081, 49.0754241]],
    [ "Enedis",[0.7812135087350321, 47.73734081482518]],
    [ "Enedis",[1.5912113115410291, 46.54052534514499]],
    [ "Enedis",[1.5454288300704475, 46.57760214714332]],
    [ "Enedis",[1.5482286283962023, 46.57310404860436]],
    [ "Enedis",[1.5357210352436528, 46.57610431327627]],
    [ "Enedis",[4.778474465639794, 44.35657644473361]],
    [ "Enedis",[4.341584405449501, 47.45066164625032]],
    [ "Enedis",[4.1641222456439895, 47.4777784608747]],
    [ "Enedis",[4.174302596873879, 47.44940250486245]],
    [ "Kerhéré",[-5.0866636, 48.4684084]],
    [ "Sévenans",[6.870660735116836, 47.58388389725875]],
    [ "Feunten Velen",[-5.096050382520727, 48.44616619420194]],
    [ "Kerandraon",[-5.10099065, 48.442707600000006]],
    [ "Enedis",[4.276463453376091, 47.20715766001526]],
    [ "Enedis",[5.703674049999999, 45.156992349999996]],
    [ "Enedis",[3.8043462783478605, 49.22381090969624]],
    [ "Enedis",[3.721418816564476, 47.055266604820396]],
    [ "Enedis",[4.3343922103783745, 47.19371792639452]],
    [ "Enedis",[3.5123243688700123, 49.338094202898894]],
    [ "Enedis",[4.453454009195542, 47.216173755909566]],
    [ "Enedis",[2.594029827934643, 46.734631731528346]],
    [ "Enedis",[3.4712370595052655, 43.3000561185319]],
    [ "RTE",[1.230182262736857, 45.12609630329001]],
    [ "488003395",[5.14574195, 44.359007749999996]],
    [ "Enedis",[-0.957876863045214, 48.968255732643264]],
    [ "RTE",[1.469815862037882, 48.42791160407868]],
    [ "Enedis",[2.7488071569690637, 46.99904778474245]],
    [ "Enedis",[2.7440584794753398, 47.00213451003253]],
    [ "Enedis",[2.8226890379776384, 46.98103911639486]],
    [ "Enedis",[3.2247587567570473, 47.055073923950935]],
    [ "Enedis",[4.939652027021486, 44.82826735321356]],
    [ "Enedis",[4.940477974865988, 44.82503064653524]],
    [ "Enedis",[4.919306889544196, 44.83157101282763]],
    [ "Enedis",[2.898232009691659, 46.918070709926816]],
    [ "SNCF Réseau",[2.0329144706150655, 47.01129866797873]],
    [ "Enedis",[2.8203325736856675, 46.99448749414883]],
    [ "Enedis",[2.812210366813094, 46.996621748330035]],
    [ "Enedis",[2.854494464905964, 47.03026348828085]],
    [ "Enedis",[2.822850473846634, 47.00052969475301]],
    [ "Enedis",[2.8166401630772393, 46.99847638503348]],
    [ "Enedis",[2.817357388399516, 46.99670458037179]],
    [ "Poste électrique de Saint-Gilles",[-1.8899390123988828, 46.69560601180993]],
    [ "RTE",[-1.7256005658597036, 46.493745010779406]],
    [ "RTE",[-1.5056371233191121, 47.000430868678826]],
    [ "Enedis",[3.575689568713146, 49.14819396593826]],
    [ "Poste électrique de Peyrilhac",[1.1546174672396652, 45.9732002926463]],
    [ "RTE",[1.7597967126733223, 49.35783274000189]],
    [ "RTE",[-0.8823145894099672, 47.03834507423971]],
    [ "Enedis",[-1.349795444318334, 43.671742921718874]],
    [ "Enedis",[-1.3442439762285703, 43.674417358511676]],
    [ "Enedis",[-1.3484822151268305, 43.67059381317286]],
    [ "Enedis",[-1.3552520247098199, 43.67599673263977]],
    [ "Enedis",[5.617964765027081, 45.306736280155484]],
    [ "RTE",[1.600482413450687, 42.836363379064686]],
    [ "RTE",[1.5997333204298423, 42.836335009651265]],
    [ "Poste électrique d’Hargeville",[1.7518924556575082, 48.90407577181255]],
    [ "RTE",[1.6117513864758064, 42.96062531089766]],
    [ "Poste électrique d'Ax les Thermes",[1.8338465245768063, 42.72422593460862]],
    [ "Salbris",[2.04891385, 47.420416]],
    [ "Les Fontaines",[1.3813043819919772, 48.74504569422237]],
    [ "Maritime",[0.9894209500000001, 49.351124899999995]],
    [ "Enedis",[7.220685255864264, 43.68713415184616]],
    [ "Enedis",[6.131276700811338, 48.395387812992475]],
    [ "Enedis",[5.948694918056981, 47.295421648386764]],
    [ "Enedis",[5.954353449999999, 47.30104845000001]],
    [ "Poste électrique de Doulon",[-1.4967209252498186, 47.228432630236185]],
    [ "SNCF Réseau",[1.607200246099293, 42.977650232817005]],
    [ "RTE",[1.6139773703315008, 42.99610491898727]],
    [ "Enedis",[3.262610934997795, 49.1123386903182]],
    [ "Enedis",[-1.1402860030596236, 44.322227031690204]],
    [ "Enedis",[-1.1338829580257548, 44.32288152431592]],
    [ "Enedis",[-1.1466109106375315, 44.326091978326]],
    [ "Enedis",[-1.18380605, 44.21820875]],
    [ "Enedis",[-1.2026486711035267, 44.216396366666665]],
    [ "Enedis",[-1.1940421069681015, 44.212591505589266]],
    [ "Enedis",[-1.1977014000000001, 44.214006700000006]],
    [ "Enedis",[-1.1913802220232934, 44.21506210503898]],
    [ "Enedis",[-1.2047066821091403, 44.21747213343127]],
    [ "Enedis",[-1.3030621650440468, 43.79869256906485]],
    [ "Enedis",[-1.3011812689983322, 43.7969723865169]],
    [ "Enedis",[-1.2943912941751612, 43.80378323465086]],
    [ "Enedis",[-1.3017830908463233, 43.80044891547616]],
    [ "RTE",[1.5310229881117026, 44.356267787714685]],
    [ "SNCF Réseau",[1.494275767393455, 44.36249770301024]],
    [ "RTE",[1.4307921131996604, 44.449326543115795]],
    [ "Enedis",[5.635243344261587, 45.29048383868266]],
    [ "Enedis",[3.2693441593333246, 49.186971791094415]],
    [ "Enedis",[3.2497459958627273, 49.186298457567645]],
    [ "Enedis",[3.2641738595664793, 49.16500742959191]],
    [ "Enedis",[3.2636666416761515, 49.16931643773645]],
    [ "Enedis",[3.2644334499999994, 49.166519449999996]],
    [ "Enedis",[3.2650918895451455, 49.171108515818055]],
    [ "Enedis",[2.1477871518767544, 47.00118802332333]],
    [ "Enedis",[1.3153137079584005, 44.30146654102973]],
    [ "Poste Électrique de Clairefontaine",[6.874948088566511, 48.37205537662961]],
    [ "Enedis",[1.0857805959074893, 42.958208471784914]],
    [ "Enedis",[1.087155213443699, 42.96037975978145]],
    [ "RTE",[1.3527954902150297, 44.65220593068542]],
    [ "Poste de Figeac",[1.9996564495816882, 44.60647289143945]],
    [ "RTE",[5.396944705369204, 47.058861462565744]],
    [ "RTE",[4.856570172024052, 46.80656472592253]],
    [ "RTE",[4.45228495, 46.7823308]],
    [ "RTE",[6.79938249499022, 47.530118408942606]],
    [ "RTE",[4.812216978782192, 46.287099378271144]],
    [ "RTE",[7.398278619979231, 47.7519319812485]],
    [ "Poste électrique d'Arches",[6.5401121299466976, 48.11375088302694]],
    [ "RTE",[7.5487455883445564, 47.585275414553564]],
    [ "RTE",[7.5767399769596055, 47.58660949888132]],
    [ "490190419",[1.1264214447253649, 42.97874337593892]],
    [ "Poste électrique du Bourguignon",[6.775659659715707, 47.42148099122222]],
    [ "RTE",[5.1988741110669, 43.37680717663524]],
    [ "réséda",[6.150720641904467, 49.123678304099066]],
    [ "RTE",[5.742624045055811, 47.97359995639929]],
    [ "Poste Électrique de Bologne",[5.1633779586915844, 48.19768214624818]],
    [ "RTE",[5.766255256864151, 43.1456594432386]],
    [ "RTE",[4.90890359907246, 43.46124754190385]],
    [ "RTE",[4.914432709656868, 43.46210230529286]],
    [ "RTE",[4.928619227049449, 43.450011038909274]],
    [ "RTE",[4.92629752603603, 43.44932203015915]],
    [ "RTE",[4.956092734550909, 43.4809816856251]],
    [ "RTE",[4.997251835239081, 43.5696046738887]],
    [ "RTE",[4.9198293258057175, 43.59469703429139]],
    [ "RTE",[5.178971915479101, 46.79997143287731]],
    [ "Poste électrique de Saint-Apollinaire",[5.079388539867344, 47.32499740339752]],
    [ "SNCF Réseau",[4.995722190096201, 47.22199439162933]],
    [ "RTE",[5.014228978691596, 43.57889409381559]],
    [ "RTE",[5.339848608048833, 43.3549856162206]],
    [ "RTE",[5.242590369753879, 43.44446157009119]],
    [ "SNCF Réseau",[5.325115121842188, 43.36384978545056]],
    [ "RTE",[4.197932809154737, 43.56593683152461]],
    [ "Poste électrique de Raon l'étape",[6.827833423502897, 48.41130750051454]],
    [ "RTE",[-0.15162818603111425, 48.174680672665275]],
    [ "RTE",[5.009808022098677, 43.391361029471994]],
    [ "RTE",[5.015154645336115, 43.38366443482366]],
    [ "RTE",[5.013555530070212, 43.38552283319089]],
    [ "RTE",[5.0473603490544825, 43.4097658879322]],
    [ "RTE",[4.850445383483092, 43.457454331083554]],
    [ "SNCF Réseau",[4.850576172156984, 43.429110538258]],
    [ "RTE",[4.609187221439551, 43.67397369345728]],
    [ "RTE",[4.602407290350108, 43.675522905806446]],
    [ "Poste électrique de Coignères",[1.9199630735917448, 48.73722408891565]],
    [ "Poste électrique de Treffort",[5.362532327106775, 46.271023421259514]],
    [ "RTE",[4.395624775399059, 45.460553410837846]],
    [ "Etrez 63 kV",[5.218455942195834, 46.34015743037256]],
    [ "RTE",[5.995504427372114, 47.25653889953403]],
    [ "RTE",[4.843452448383835, 45.685431855099665]],
    [ "RTE",[4.9013365694778726, 45.71095490439222]],
    [ "RTE",[4.713545122649492, 45.77816956890034]],
    [ "Sous-station de la Saulaie",[4.817486165183282, 45.71127938876228]],
    [ "RTE",[4.823350084559282, 45.710074178739305]],
    [ "RTE",[4.895664448122473, 45.80548466232055]],
    [ "Enedis",[3.5899709993970963, 46.80880982825802]],
    [ "Enedis",[2.709948496851018, 47.32915610981378]],
    [ "RTE",[1.5045195975182917, 44.271457444125]],
    [ "Enedis",[3.0872995628941102, 46.115427718354844]],
    [ "Enedis",[3.085485234320491, 46.114139101734715]],
    [ "RTE",[7.537437306484666, 48.00118527183885]],
    [ "RTE",[7.555898725488301, 48.067734872155434]],
    [ "Enedis",[2.9377009, 47.1286192]],
    [ "RTE",[2.08552402164912, 42.75136971519109]],
    [ "Enedis",[2.8755753429764166, 47.143768365557605]],
    [ "Enedis",[1.736663249999999, 49.48392225]],
    [ "Enedis",[1.7412347666328616, 49.4857807991422]],
    [ "Enedis",[1.7441590972762433, 49.48746811678651]],
    [ "Enedis",[3.3065056999999998, 47.05801605]],
    [ "Poste Électrique de Nouzonville",[4.742243181422795, 49.82000742723374]],
    [ "Enedis",[3.4820866504882253, 46.7500094133242]],
    [ "Poste Électrique de Marlière",[4.913953934016791, 49.699015283191194]],
    [ "Poste Électrique de Sommer-Sedan",[4.9140541528549155, 49.70274979910022]],
    [ "Poste Électrique de Bazeilles",[5.005852483246267, 49.67424369086565]],
    [ "Enedis",[0.9307032801591358, 47.998680293367435]],
    [ "Poste électrique de Villeneuve-de-Blaye",[-0.6334648333156865, 45.080485189822994]],
    [ "MARQUIS (LE) (ENGRAIS D AMBES)",[-0.54975325, 45.0052972]],
    [ "RTE",[-0.461701163412942, 45.0006853108011]],
    [ "Poste électrique de Soulac",[-1.1139916473649685, 45.50468474152341]],
    [ "Soulac - SNCF",[-1.1150583125113103, 45.50470905594924]],
    [ "Poste électrique de Margaux",[-0.6838175049085374, 45.0432940503943]],
    [ "Labarde",[-0.6422104931954803, 45.02340997765073]],
    [ "Enedis",[-1.4270876999999995, 46.723486199999996]],
    [ "Enedis",[-1.4307116, 46.70861945]],
    [ "Enedis",[-1.4699006670124648, 46.720249398769596]],
    [ "Enedis",[-1.4259821500000003, 46.71723785]],
    [ "Poste électrique de Pauillac (S.N.C.F.)",[-0.7556926001199477, 45.203978648422904]],
    [ "Poste électrique de Pauillac",[-0.7554411547938665, 45.205720711437316]],
    [ "MEDOC (LESPARRE)",[-0.9457653177225582, 45.29761285703538]],
    [ "LESPARRE (S.N.C.F.)",[-0.9474817051307364, 45.30439596228928]],
    [ "Enedis",[3.640411525289429, 49.2133184599465]],
    [ "réséda",[6.378698983305833, 48.95903065101751]],
    [ "Poste électrique de Bessanges (Client)",[-0.05832034780711355, 45.08445071419016]],
    [ "Poste électrique de Coutras",[-0.14843002054933935, 45.02283301791476]],
    [ "EDF",[6.518164372847092, 45.45695504491415]],
    [ "Enedis",[6.495610849999999, 45.40437804999999]],
    [ "Enedis",[6.507155007733687, 49.24132428499582]],
    [ "Enedis",[1.7469221000000006, 49.48839710000001]],
    [ "Enedis",[-1.39972405, 46.73692785]],
    [ "Enedis",[3.31526509448816, 46.771013405929516]],
    [ "Enedis",[1.7439909884166345, 49.48515118105449]],
    [ "RTE",[6.051113693725544, 49.16997822705152]],
    [ "Beauregard (Thionville)",[6.157271251153448, 49.34642414151818]],
    [ "Poste électrique Ebange-Sollac",[6.138024473228399, 49.332491487199675]],
    [ "RTE",[6.147196510381167, 49.33202382158412]],
    [ "RTE",[6.125335059806193, 49.258545482645545]],
    [ "Poste électrique de Gandrange",[6.135490340251321, 49.26672761212787]],
    [ "RTE",[6.149702937916112, 49.239681936949424]],
    [ "RTE",[5.922661654244407, 48.693948842047945]],
    [ "SNCF Réseau",[6.040318848035132, 48.947562846058474]],
    [ "RTE",[6.064178655132167, 48.8683936473504]],
    [ "Poste électrique de l'Avrima",[6.065348425162913, 48.87009250098466]],
    [ "RTE",[6.075701801859287, 48.827979288458515]],
    [ "Poste électrique de Ban-la-Dame",[6.135701515539446, 48.769382954797386]],
    [ "Tapis-Vert",[6.19403090038928, 48.69140328539818]],
    [ "RTE",[6.240587974429673, 48.71025192674383]],
    [ "491655532",[6.2897288805129365, 48.70356878969177]],
    [ "Laneuvelotte 2",[6.289083497871857, 48.703041682918716]],
    [ "Dombasle 5",[6.335849616342926, 48.628233519499105]],
    [ "Dombasle",[6.328775103138151, 48.6299416820743]],
    [ "Mézot",[6.307331879913387, 48.63789687714878]],
    [ "Poste de Saint-Charles",[6.177345616807414, 48.6686759756123]],
    [ "Poste électrique de Xeuilley",[6.111326132083506, 48.56188228542941]],
    [ "Poste électrique de Saintois",[6.133201582448778, 48.48357129993406]],
    [ "RTE",[6.707413480521563, 49.19130544053426]],
    [ "RTE",[6.715982746950336, 49.15137202791907]],
    [ "RTE",[7.093878758060308, 49.10412069206952]],
    [ "Poste électrique d'Adelsberg",[7.03239048101377, 49.0086874056037]],
    [ "Poste électrique de Sarralbe",[7.032453297501954, 49.00912879813614]],
    [ "RTE",[6.967091232466475, 48.68862112132363]],
    [ "RTE",[6.47273618146749, 47.68728609595145]],
    [ "Poste électrique de Prévessin-Moëns",[6.0797080648345, 46.270040780048284]],
    [ "RTE",[-1.2205672437774953, 45.88280869159776]],
    [ "Poste électrique de Charbonnière",[-0.7822688597106496, 45.5061952968401]],
    [ "RTE",[-0.36609839302175784, 45.227621098516956]],
    [ "ETAULIERS",[-0.5667789630534564, 45.21925357430927]],
    [ "Enedis",[6.123961949999998, 46.03711885]],
    [ "Enedis",[6.3479646077351655, 45.24497510471706]],
    [ "Enedis",[3.4132873652682507, 49.52813926751751]],
    [ "Enedis",[3.465673371486535, 49.5341932979815]],
    [ "Enedis",[3.462114573828278, 49.533196153783194]],
    [ "RTE",[1.8192269731545225, 47.09851033786464]],
    [ "RTE",[1.5993129338117638, 46.97314742112853]],
    [ "Station Électrique des Épines Fortes",[0.7453797089562946, 47.39065199428199]],
    [ "Enedis",[6.317447483105373, 46.11086569372442]],
    [ "Enedis",[6.313345665176663, 46.13336990321249]],
    [ "Enedis",[1.9397439289619434, 47.38675081269791]],
    [ "Enedis",[1.958425955617996, 47.38242675233156]],
    [ "RTE",[4.9334719774751665, 44.454952554450294]],
    [ "Enedis",[1.9119719773542814, 47.39344206157766]],
    [ "Enedis",[1.9154210032751644, 47.385128511647856]],
    [ "Enedis",[1.9241910412899106, 47.391570044135264]],
    [ "Enedis",[1.9085096731307314, 47.38473875748546]],
    [ "Enedis",[1.9237259560333115, 47.38517396145706]],
    [ "Enedis",[1.917831674092079, 47.3986510694654]],
    [ "Enedis",[1.921456688704317, 47.38697956383668]],
    [ "Enedis",[1.917942743227409, 47.38683010929162]],
    [ "Enedis",[1.922367204925918, 47.38355591360947]],
    [ "Enedis",[0.4340601621300787, 48.71387825375104]],
    [ "Enedis",[0.4277386831712551, 48.711866969501685]],
    [ "Enedis",[3.58552565, 46.842838300000004]],
    [ "Chemin de Maraye",[4.0307702999999995, 48.28337129999999]],
    [ "Enedis",[0.4920549434682643, 48.73110442156972]],
    [ "Poste électrique des Chartreux",[2.2817566142501517, 48.66772788753639]],
    [ "Poste électrique de Choisy-le-Roi",[2.408192747276175, 48.77244809013532]],
    [ "491948659",[2.9284419727722395, 48.94937014476221]],
    [ "Poste électrique de Bondy",[2.4892908369802536, 48.90858065518776]],
    [ "Enedis",[0.06494990227497892, 45.01185490538518]],
    [ "Enedis",[1.0207969525072302, 48.00939912145818]],
    [ "RTE",[0.782133445319203, 47.524168513884035]],
    [ "RTE",[0.9094691601130008, 47.60906691263671]],
    [ "Enedis",[6.2407759235168925, 46.16059644546707]],
    [ "Enedis",[6.32753575, 46.0700961]],
    [ "Enedis",[6.334333812976949, 46.06998267483999]],
    [ "Enedis",[6.336540247230396, 46.061841082371565]],
    [ "Enedis",[2.9924246531285563, 47.27133951331058]],
    [ "Enedis",[2.986387417095905, 47.25776571307027]],
    [ "Enedis",[2.983511283886674, 47.2666204631874]],
    [ "Enedis",[2.9695233826239464, 47.28828799000458]],
    [ "Enedis",[2.9549509675033474, 47.288110321764805]],
    [ "Enedis",[2.9492925787785307, 47.28619843001639]],
    [ "Enedis",[-1.7291581999999999, 46.463891649999994]],
    [ "Enedis",[-1.760380778250864, 46.47852992758581]],
    [ "Enedis",[2.2272900716128006, 48.97619801252224]],
    [ "Enedis",[6.817121187242379, 49.08796633397009]],
    [ "RTE",[1.9432650724668254, 47.91647597204783]],
    [ "Enedis",[3.2695665499999995, 49.7736159]],
    [ "RTE",[4.566740940839381, 48.76947234319032]],
    [ "RTE",[4.301376223128543, 49.23924505612471]],
    [ "Enedis",[4.436742683654616, 49.45386421766967]],
    [ "RTE",[4.483325631936625, 49.41915278863588]],
    [ "RTE",[4.9100311443362825, 49.70119075464406]],
    [ "RTE",[5.072314072621995, 49.608894726333574]],
    [ "RTE",[4.805487863978206, 48.91288499362882]],
    [ "RTE",[5.070748114650615, 48.70457208493641]],
    [ "Enedis",[5.202831122013083, 48.962297446177324]],
    [ "RTE",[5.284857078576746, 48.715644999972085]],
    [ "RTE",[5.603077881145181, 49.44190191397472]],
    [ "RTE",[5.768053631216163, 49.50377080902949]],
    [ "RTE",[5.147042314807821, 48.44548811150972]],
    [ "réséda",[6.248117142574217, 49.19558636527191]],
    [ "Enedis",[1.3458009010453622, 49.74774426838041]],
    [ "Enedis",[3.4843223572119992, 46.989054922235745]],
    [ "Enedis",[2.925128805811312, 47.33292972812823]],
    [ "Enedis",[2.928005951607242, 47.33768079744529]],
    [ "Enedis",[2.924402484338214, 47.339038275157336]],
    [ "Enedis",[0.38642293816112105, 48.741125376015454]],
    [ "Enedis",[3.481058097335296, 47.009115231145586]],
    [ "Enedis",[0.46312991150071026, 48.79186591985401]],
    [ "RTE",[5.100748415751626, 45.84613517202064]],
    [ "Enedis",[-0.1735623997278493, 48.15730244236844]],
    [ "Enedis",[3.44645019423744, 48.472262403176714]],
    [ "Enedis",[3.43914308299496, 48.47394359829202]],
    [ "Enedis",[4.189382380703894, 48.18123485207146]],
    [ "Enedis",[3.1131718107088173, 47.26656351522505]],
    [ "Enedis",[1.4332345007765321, 48.596460468827594]],
    [ "Enedis",[1.4251577876224246, 48.607620080677435]],
    [ "Enedis",[3.1114824495777436, 47.22358930364854]],
    [ "Enedis",[1.4238873572804631, 48.63485492890041]],
    [ "Enedis",[1.4301812569978078, 48.63669796617854]],
    [ "Enedis",[6.597587036930194, 49.27907946824748]],
    [ "réséda",[6.10164375, 49.10810385]],
    [ "RTE",[3.1379530169112906, 48.95092679865641]],
    [ "RTE",[3.2524850472056954, 48.79053916074999]],
    [ "RTE",[3.5230766971679044, 49.0464249148386]],
    [ "RTE",[3.616963888816999, 49.064068185423736]],
    [ "Poste source de la Maladière",[4.019146054628137, 48.29153044736091]],
    [ "Poste source de la Troyes Industries",[4.040666340231622, 48.31624165964181]],
    [ "RTE",[3.514627800508111, 48.48576140299849]],
    [ "Enedis",[0.002039224553278535, 48.75324216895974]],
    [ "Enedis",[4.708953022029246, 44.3851005517296]],
    [ "Enedis",[3.3334868528488433, 46.96623782679418]],
    [ "Enedis",[3.342978138716594, 46.95701259651449]],
    [ "Enedis",[3.2602576407213193, 46.941129401505755]],
    [ "Enedis",[3.271805180406363, 46.966085495186945]],
    [ "Enedis",[3.2698868418980003, 46.96899102873623]],
    [ "Enedis",[3.2378637624419917, 46.983761361863436]],
    [ "Enedis",[3.23449336762278, 46.98120119038635]],
    [ "Enedis",[3.257886162791292, 46.939376320358825]],
    [ "Enedis",[3.243472627511443, 46.97986917532867]],
    [ "Enedis",[3.2415298697092645, 46.977275012149576]],
    [ "Enedis",[4.947087355974174, 44.8691853717874]],
    [ "Enedis",[4.944887843414768, 44.87141232811418]],
    [ "SRD",[0.36652060000000003, 46.484444700000005]],
    [ "Enedis",[6.422039181869936, 46.30422258486922]],
    [ "Enedis",[6.423060118312565, 46.30527609831971]],
    [ "Brecorens",[6.421807840571696, 46.29662832387654]],
    [ "Enedis",[6.674040371939859, 46.402523423085285]],
    [ "Enedis",[6.765047616605636, 46.39823700007817]],
    [ "SRD",[0.36459901923515264, 46.49379519645012]],
    [ "Enedis",[3.0655882999999995, 45.79357565]],
    [ "Enedis",[2.4546091000000003, 48.6449015]],
    [ "Enedis",[1.0871762432155643, 49.40469738014993]],
    [ "Enedis",[1.1493530443468791, 42.9856291028589]],
    [ "SRD",[0.22939410000000002, 46.60732599999999]],
    [ "Poste électrique d’Allement (portique)",[5.420989236616346, 46.11334824154452]],
    [ "Enedis",[3.2688163308428866, 46.92862664165441]],
    [ "Enedis",[3.2663932137752316, 46.93085791078194]],
    [ "Enedis",[3.2676690202638867, 46.92130003051451]],
    [ "Enedis",[3.2631011795938853, 46.922069019797625]],
    [ "Enedis",[3.2642550068596625, 46.925952647029995]],
    [ "Enedis",[3.2729231830490955, 46.92356460540629]],
    [ "Enedis",[3.2638665650415843, 46.92754017354656]],
    [ "Enedis",[3.2602570421206054, 46.926876363123675]],
    [ "Enedis",[3.259353057953616, 46.92539360813061]],
    [ "Enedis",[3.264304665992231, 46.92440512238412]],
    [ "Enedis",[3.2638492279831213, 46.920874251063054]],
    [ "Enedis",[3.2622093223416786, 46.92931438024806]],
    [ "Enedis",[3.1836315208324137, 46.73241853248261]],
    [ "SRD",[0.36935886160641757, 46.48902648002709]],
    [ "Enedis",[6.229684550000001, 48.225463749999996]],
    [ "RTE",[-0.8374884, 47.065036400000004]],
    [ "RTE",[-0.6457867294590424, 46.830271576905]],
    [ "EDF",[3.0029926601160084, 44.891117720306525]],
    [ "RTE",[3.023863506465442, 45.222662086255866]],
    [ "Enedis",[3.321874849069044, 47.019406466697546]],
    [ "Enedis",[5.383874100000001, 45.6885978]],
    [ "Enedis",[5.40311682217037, 45.7062519271824]],
    [ "Enedis",[5.40577151077414, 45.72732070088703]],
    [ "SNCF",[2.4867447857529816, 48.73641719282536]],
    [ "Enedis",[4.114999252766616, 48.53299824123914]],
    [ "RTE",[3.9220011911781585, 50.28674581737141]],
    [ "SNCF Réseau",[3.9301321277742827, 50.26533663884229]],
    [ "RTE",[3.9540922812128123, 50.26440074799787]],
    [ "RTE",[4.030618239508325, 50.28220584412487]],
    [ "RTE",[4.641773494591772, 49.93022718892584]],
    [ "SNCF Réseau",[3.884852304987972, 47.93240827399868]],
    [ "RTE",[4.074772078339743, 47.7853296702185]],
    [ "Poste électrique des Craies",[3.9641357999999993, 47.733279849999995]],
    [ "RTE",[4.432403243572612, 47.54047873724129]],
    [ "Enedis",[2.8594494491000146, 50.58169764996317]],
    [ "Enedis",[1.0694470495706778, 46.12302339793229]],
    [ "Enedis",[6.0212115255790675, 48.9825551594386]],
    [ "Enedis",[3.53275230595276, 49.421817832911515]],
    [ "Enedis",[3.5135035417229328, 49.40667637442076]],
    [ "Enedis",[3.5230991479340834, 49.404765848489156]],
    [ "Albigny Plage",[6.15083115, 45.9053849]],
    [ "Les Portes du Lac",[6.2114253, 45.774319999999996]],
    [ "Boussiere",[6.2222588660627745, 45.7689569759666]],
    [ "Lathuile",[6.2026251, 45.781120900000005]],
    [ "Les Esserts",[6.204562, 45.80259834999999]],
    [ "réséda",[6.0977594, 49.11053149999999]],
    [ "Enedis",[1.814582244062888, 48.424673505088954]],
    [ "RTE",[3.722608731522034, 48.23393605367813]],
    [ "Enedis",[1.7903784578084592, 47.84725411548543]],
    [ "Enedis",[1.5736702814683459, 48.62066671342087]],
    [ "Enedis",[1.5571783303477758, 48.62697182332173]],
    [ "Enedis",[1.577594603770753, 48.618453259204756]],
    [ "Enedis",[1.5574881110562568, 48.63366108827001]],
    [ "Enedis",[1.567298202014256, 48.62527617647513]],
    [ "Enedis",[1.5579669493150679, 48.623151922678844]],
    [ "Poste électrique de Saint-Laurent-du-Maroni",[-53.96878390154625, 5.488350645608066]],
    [ "Poste électrique de l'Étoile",[-52.8878805042986, 5.156059134510718]],
    [ "Poste électrique Seine",[2.3384620171903863, 48.92522880442815]],
    [ "Enedis",[1.4173038740557609, 43.63723386087166]],
    [ "Enedis",[1.4134232, 43.6346418]],
    [ "Enedis",[2.1195615367790293, 46.933201808518284]],
    [ "Enedis",[4.333934346063609, 48.89529780924192]],
    [ "RTE",[4.204420198860102, 48.17494664628581]],
    [ "RTE",[3.697237319724072, 47.25512009510638]],
    [ "RTE",[3.938719903029771, 47.05769866036082]],
    [ "Enedis",[3.9172232801837064, 49.28904461024678]],
    [ "Enedis",[3.9224690482647255, 49.27910035929938]],
    [ "RTE",[4.3182219871028185, 46.95573255401615]],
    [ "Poste électrique des Quatre Croix",[3.0664352368833243, 48.03747492958853]],
    [ "RTE",[4.3071196860597505, 48.24881564765637]],
    [ "RTE",[4.451285276021281, 48.235162216297255]],
    [ "RTE",[3.3058441240249685, 44.66074987972773]],
    [ "réséda",[6.12336613142917, 49.14002497969322]],
    [ "Enedis",[1.617097029227078, 48.567245156297794]],
    [ "Enedis",[1.6140507865841076, 48.56637401857967]],
    [ "Enedis",[1.6356755971988164, 48.54936981819632]],
    [ "Enedis",[1.6870434543170572, 48.52537704369378]],
    [ "Enedis",[1.6884558929722415, 48.5263960573723]],
    [ "Enedis",[1.6910237048788177, 48.52269549351368]],
    [ "Enedis",[1.6975169000000014, 48.529120799999994]],
    [ "Enedis",[1.6859718390570415, 48.52442091415498]],
    [ "Enedis",[1.6770125819290593, 48.53246578129126]],
    [ "Enedis",[1.6768947156009375, 48.53234671652459]],
    [ "Enedis",[1.6834894175995028, 48.527272245530085]],
    [ "Enedis",[1.6821810366536958, 48.53234889980285]],
    [ "Enedis",[1.673785382321462, 48.5288718395891]],
    [ "Enedis",[1.68252630419574, 48.53039511265665]],
    [ "Enedis",[1.6818088093619767, 48.5294996843218]],
    [ "Enedis",[1.714384308972947, 48.51912043389644]],
    [ "Enedis",[1.711624905283313, 48.50965948589412]],
    [ "Enedis",[1.7183100416808668, 48.50456557714423]],
    [ "Enedis",[1.7058625037605444, 48.5099799202341]],
    [ "Enedis",[1.6610594439519804, 48.56928127576634]],
    [ "Enedis",[1.3650614038689317, 43.7922612841698]],
    [ "Enedis",[1.3616178499999996, 43.791462249999995]],
    [ "Enedis",[2.156654807202934, 46.881017775928264]],
    [ "RTE",[0.6027239909100262, 44.20604545474421]],
    [ "Enedis",[1.7228022440277349, 48.54463888054463]],
    [ "Enedis",[1.7274931397010926, 48.545015368263684]],
    [ "SRD",[0.30467537804761907, 46.60508476128571]],
    [ "Enedis",[1.456546566853504, 48.67318605032015]],
    [ "Enedis",[4.341045037264121, 49.49964735312602]],
    [ "Sibourgrans",[5.0903564250643, 43.63581106337448]],
    [ "Enedis",[1.6100503180278325, 48.61674822737226]],
    [ "Enedis",[1.5938810161868986, 48.62122699422536]],
    [ "Enedis",[1.5943765156031384, 48.64035813628341]],
    [ "Enedis",[4.50127069882842, 48.987038178923385]],
    [ "Enedis",[1.5475299959929756, 49.185169422169544]],
    [ "Enedis",[1.44672635, 43.6269033]],
    [ "Enedis",[1.6408610903933354, 49.16653361443636]],
    [ "Enedis",[1.6058882340372418, 49.15605990370277]],
    [ "Enedis",[1.5688056292758201, 49.20881454803235]],
    [ "Enedis",[-0.6723379347441112, 44.73838209828838]],
    [ "Enedis",[2.721922438068913, 42.88638358655913]],
    [ "Villarclement",[6.38731265, 45.26280995]],
    [ "Sorea",[6.402270482547366, 45.25626830246768]],
    [ "Sorea",[6.411477296783198, 45.248914332389596]],
    [ "Sorea",[6.412965273500303, 45.25618636723157]],
    [ "Le Claret",[6.422167283424286, 45.2548231446338]],
    [ "Prise d'Eau",[6.415935844111737, 45.28372302998089]],
    [ "Regie de Saint Léger",[6.276768399125578, 45.43342728302351]],
    [ "Enedis",[0.9082333904204506, 47.98481924653142]],
    [ "RTE",[-1.9066851131030136, 47.329948149428965]],
    [ "Enedis",[2.2066220448374896, 46.702824576617225]],
    [ "RTE",[1.5770369158887398, 47.14986719783212]],
    [ "SNCF Réseau",[1.5632868879271853, 46.69488751657469]],
    [ "RTE",[1.9142435655951697, 46.93710834701351]],
    [ "SNCF Réseau",[1.8199669915339607, 46.861204457895525]],
    [ "RTE",[-0.7170513561625264, 47.23919806885484]],
    [ "RTE",[0.07557569310843633, 47.00307555797826]],
    [ "Enedis",[1.3747437720730131, 48.075400241015345]],
    [ "Enedis",[1.3672415251630745, 48.07457331317549]],
    [ "Enedis",[1.3692618709222972, 48.073559444484125]],
    [ "Enedis",[2.7348432618054983, 50.74820116384366]],
    [ "Enedis",[3.200606124086539, 50.68715475338827]],
    [ "Enedis",[2.6620343842349192, 48.0487206289151]],
    [ "Enedis",[2.734270693708987, 50.28866955554977]],
    [ "Enedis",[2.73808362406217, 50.288678309383535]],
    [ "Poste électrique SNCF",[5.90160319597941, 45.696913277740805]],
    [ "Poste électrique d'Aix-les-Bains",[5.9129494069829285, 45.69409079821761]],
    [ "Enedis",[0.9013828738956879, 47.9812529506514]],
    [ "Enedis",[0.9027998016248818, 47.97957221654288]],
    [ "Enedis",[2.1747027402826937, 46.672990222452114]],
    [ "Sem Tan Haluchère",[-1.5235116885091031, 47.24931661450891]],
    [ "Poste électrique d'Olonne",[-1.7608214347012814, 46.53284570682048]],
    [ "Poste électrique de Saint-Hilaire-de-Riez",[-1.9321903595669068, 46.73904978553809]],
    [ "Enedis",[2.3896962210671133, 47.84825484047964]],
    [ "Enedis",[2.3641897487156616, 47.85090036000977]],
    [ "Enedis",[-1.9002606270267852, 48.216267176123424]],
    [ "Sorea",[6.4419993566504505, 45.19503267243453]],
    [ "Poste source de Valloire",[6.4386071352564604, 45.18712361944109]],
    [ "Sorea",[6.378866175607193, 45.26070193096448]],
    [ "Bourg",[0.5328314503042713, 44.656509806187955]],
    [ "Enedis",[0.45217880783075326, 49.78566315758858]],
    [ "Enedis",[5.331378949999999, 43.36746435]],
    [ "Enedis",[5.3262001378581605, 43.368631532716066]],
    [ "Enedis",[5.084118950000001, 47.347153850000005]],
    [ "Enedis",[6.320340215854928, 45.654151251569374]],
    [ "Le Pegny",[6.214684315970693, 45.88661983299133]],
    [ "Enedis",[6.167924902396499, 45.921874583619676]],
    [ "Hauts d'Annicea",[6.1705965333827315, 45.92154834991309]],
    [ "Enedis",[3.108989, 45.8353238]],
    [ "Enedis",[3.146532329829712, 45.83816292474305]],
    [ "Enedis",[3.0998308000000003, 45.797811499999995]],
    [ "Enedis",[3.0754279500000004, 45.775008650000004]],
    [ "Enedis",[3.102343524660018, 45.83423229675631]],
    [ "Enedis",[4.2234432, 43.943320199999995]],
    [ "Enedis",[5.3555230954487305, 43.37238719337411]],
    [ "Enedis",[-0.8345260812610461, 43.82770561443983]],
    [ "Enedis",[4.824170003135703, 45.726310466348274]],
    [ "Enedis",[4.124608963664384, 43.66434061709292]],
    [ "Enedis",[3.5449339433778415, 49.07367957229641]],
    [ "Enedis",[3.535464712100965, 49.09010920504825]],
    [ "Enedis",[3.5358699925191, 49.08819021294423]],
    [ "Poste électrique du Glandon",[6.289550928347063, 45.33185704407336]],
    [ "Enedis",[5.0381347000000005, 43.3298702]],
    [ "Poste électrique de Saint-Vincent-de-Tyrosse",[-1.302717704783284, 43.657623552985285]],
    [ "Poste électrique de Saint-Paul-les-Dax",[-1.056873583136649, 43.72050608850275]],
    [ "Poste électrique de Soustons",[-1.295052363079382, 43.771353360977955]],
    [ "Enedis",[6.840897500000001, 47.987650599999995]],
    [ "Enedis",[2.9750296439769435, 47.19954940231359]],
    [ "Enedis",[2.9983800093720308, 47.17005979298158]],
    [ "Enedis",[1.44306622893797, 49.16221092766045]],
    [ "Enedis",[1.5206767780754955, 49.14193239358799]],
    [ "Enedis",[1.3596665390218816, 49.25696543082163]],
    [ "Enedis",[2.351937394848538, 46.57929858494092]],
    [ "Enedis",[3.122697885392721, 46.78939862904159]],
    [ "Enedis",[6.138036689465248, 48.20595898416431]],
    [ "Enedis",[3.0622409765641216, 47.45404246849352]],
    [ "Enedis",[3.067407466275165, 47.45711751573509]],
    [ "Enedis",[3.06481341065494, 47.44767229531609]],
    [ "Enedis",[1.8426243918586733, 48.41058749085625]],
    [ "Riscle",[-0.07145582511298386, 43.657842895320954]],
    [ "Enedis",[0.8690306616120573, 48.84557672225778]],
    [ "Enedis",[2.9326110072836205, 47.448580307721365]],
    [ "Enedis",[2.938403666940267, 47.44425311826626]],
    [ "Enedis",[2.937728727319875, 47.44675866088429]],
    [ "Enedis",[2.9450543000000007, 47.437878399999995]],
    [ "Enedis",[1.0582702857142856, 47.26157621075838]],
    [ "Enedis",[1.0257834035527564, 47.26341862889946]],
    [ "Enedis",[1.068000381945381, 47.274156183490405]],
    [ "Enedis",[1.0666148529384778, 47.23186356619322]],
    [ "Enedis",[5.941775425588764, 45.7762889971345]],
    [ "Enedis",[-0.009741099999999754, 48.74555005000001]],
    [ "Poste électrique de Montoir",[-2.120719187085037, 47.327284662583615]],
    [ "SNCF Réseau",[-2.1718557000000005, 47.316759800000014]],
    [ "Enedis",[2.9497278731606302, 42.65989149657429]],
    [ "Enedis",[2.9463258269151127, 42.659873453416154]],
    [ "Enedis",[1.5390963834161533, 43.81612178320413]],
    [ "Enedis",[2.991677078635015, 42.66442485974566]],
    [ "Enedis",[5.48837215, 43.32622915]],
    [ "Enedis",[2.95689645, 42.6254104]],
    [ "Enedis",[1.0803937840921527, 49.4027657562897]],
    [ "Enedis",[-0.6058499104529961, 44.79350392174316]],
    [ "Enedis",[0.897057707380918, 47.97288861646539]],
    [ "Musardière",[0.23692805625030425, 48.016976120540306]],
    [ "Maison Neuve",[0.2293009189720253, 48.019974214332215]],
    [ "Enedis",[1.8262911499999999, 48.16531055000001]],
    [ "Enedis",[1.8096207, 48.16002999999999]],
    [ "Enedis",[1.807489053940619, 48.158327167847716]],
    [ "Tete de Reseau",[6.347973150000001, 45.280059699999995]],
    [ "Station de conversion Savoie piemont 320 kV",[6.030931515370804, 45.47042140255108]],
    [ "Sous-station SNCF Iscles 25kV",[5.020966075158446, 43.82888267043475]],
    [ "Vernays 2",[6.235956432406612, 45.77383789871851]],
    [ "CT Liberté",[2.2905169641090977, 48.802727452691876]],
    [ "Enedis",[5.097715902980153, 44.71557316967218]],
    [ "Enedis",[5.09099222454165, 44.711727554056026]],
    [ "Enedis",[-0.9471932830510946, 45.7273010444592]],
    [ "Enedis",[6.532504354161255, 46.258261217307336]],
    [ "Enedis",[6.541240981034523, 46.26635332043483]],
    [ "EC de Médecine",[-4.486292465257574, 48.39651838468018]],
    [ "Boulet",[0.18227291562574266, 47.98689815488839]],
    [ "SSR 11 Zamenhof",[0.21802880285079776, 48.017818131962386]],
    [ "Enedis",[5.93330617223129, 45.79514263674013]],
    [ "Enedis",[5.950286915503311, 45.805684219767016]],
    [ "Enedis",[5.8905940999999995, 45.782663050000004]],
    [ "Enedis",[5.912581682912686, 45.766249349234116]],
    [ "Enedis",[5.907022012672921, 45.76989901242835]],
    [ "Enedis",[-2.0817482751288026, 47.11332554038393]],
    [ "Enedis",[-2.093604198654893, 47.11482954674886]],
    [ "Enedis",[-2.086872847195531, 47.114863995543345]],
    [ "Enedis",[-2.0855915443661974, 47.1155287990275]],
    [ "Enedis",[-2.238602862244313, 47.13583522463519]],
    [ "Enedis",[-2.2123992000000006, 47.13298270000001]],
    [ "Enedis",[-2.1895401777223493, 47.14077514234866]],
    [ "Enedis",[-2.1985448819164954, 47.139495418028744]],
    [ "Enedis",[-2.183623704773473, 47.15061262850711]],
    [ "Enedis",[-2.170106298798993, 47.157661759078174]],
    [ "Enedis",[-2.1735049500000008, 47.15162569999999]],
    [ "Enedis",[-2.1956344500695364, 47.140105383294774]],
    [ "Enedis",[-2.177966753649582, 47.15277754421219]],
    [ "Enedis",[-2.1861369374555055, 47.13957494295556]],
    [ "Enedis",[-2.1766038999999995, 47.151381099999995]],
    [ "Enedis",[-2.1930297, 47.135872049999996]],
    [ "Enedis",[-2.164037653786741, 47.15702083191659]],
    [ "RTE",[-0.2994127785887514, 47.82108365137587]],
    [ "Enedis",[-0.7614946469926787, 43.95621908753856]],
    [ "Parc du Château",[6.222528704055328, 46.198644240702215]],
    [ "Enedis",[1.363728630864936, 49.585648685516254]],
    [ "Tennis",[6.224910199999998, 46.1955168]],
    [ "RTE",[0.9799780105237014, 45.141027660334835]],
    [ "Enedis",[6.814298011242743, 45.28753827930454]],
    [ "Enedis",[6.814852087951888, 45.26876052177038]],
    [ "Barrage Bramans",[6.7602435799502105, 45.21952747128848]],
    [ "Enedis",[6.833564840721851, 45.35905699821555]],
    [ "Enedis",[1.025621299950708, 48.06126374539512]],
    [ "Enedis",[6.922767449999999, 47.494564600000004]],
    [ "Enedis",[0.9056881999999999, 49.5715328]],
    [ "Enedis",[3.09447376015449, 47.04335296086504]],
    [ "Enedis",[3.0760334697849014, 47.03496092110502]],
    [ "Enedis",[3.083294502911917, 47.02948276031335]],
    [ "Enedis",[3.098436595910686, 47.07706177361559]],
    [ "Enedis",[3.105872320569733, 47.07899809993893]],
    [ "Enedis",[3.088479038755721, 47.085619859725846]],
    [ "Enedis",[1.0698770303707, 48.86186530463097]],
    [ "Enedis",[1.0740513047908695, 48.871279478155465]],
    [ "Enedis",[1.0750183328284604, 48.86700349477231]],
    [ "SNCF Réseau",[4.000524889748592, 43.62956689493076]],
    [ "Enedis",[4.899824399999999, 45.864175200000005]],
    [ "Enedis",[0.35094391576752915, 46.57565176664166]],
    [ "Enedis",[0.3205364999999999, 46.579244349999996]],
    [ "Enedis",[0.3178795, 46.581500950000006]],
    [ "Enedis",[0.3219150000000002, 46.607668200000006]],
    [ "Enedis",[0.33154113640693, 46.61033616539452]],
    [ "Enedis",[1.5624902232916567, 43.711082982972876]],
    [ "Enedis",[0.7836733008139466, 49.60681076855547]],
    [ "Enedis",[-4.503081873279162, 48.0263971206792]],
    [ "ZA Viclaire",[6.8623089478561905, 45.602315532729705]],
    [ "livraison régies",[6.8617952041723, 45.60144514882538]],
    [ "Enedis",[0.9245521148053082, 49.61274040694132]],
    [ "Enedis",[1.5186731258526687, 49.81196924681004]],
    [ "Enedis",[0.8278884148333178, 48.24735829911469]],
    [ "Enedis",[-4.601079749999999, 48.37673504999999]],
    [ "Enedis",[1.4273701710655933, 49.81633216501473]],
    [ "Goélettes",[5.209884750200222, 45.621715116755]],
    [ "Enedis",[1.1446901378405674, 48.311977732425845]],
    [ "Enedis",[3.898590708331777, 43.86074833729698]],
    [ "Enedis",[3.0741249556687986, 46.92904873350066]],
    [ "Enedis",[0.794041629248097, 49.550593665735875]],
    [ "EDF",[6.798013239082887, 45.60763477928048]],
    [ "La Reculaz",[6.949231400000001, 45.475632450000006]],
    [ "Hôtel Toviere",[6.965858601136232, 45.46031738280115]],
    [ "Enedis",[2.305072944371452, 47.566548235086685]],
    [ "Enedis",[1.4885365499999994, 47.38721265]],
    [ "Le Bourg Chirens",[5.555996449999999, 45.41287399999998]],
    [ "Enedis",[1.5775495500000016, 43.6825307]],
    [ "Enedis",[1.51463736573169, 43.69433790467488]],
    [ "Enedis",[1.516606019031103, 43.699371120830975]],
    [ "Enedis",[6.7905422, 45.590769449999996]],
    [ "Enedis",[6.769183259472644, 45.61565156449399]],
    [ "Quartier des Alpins",[6.769025200000001, 45.61058315]],
    [ "Serep",[6.770260265769436, 45.6105096716532]],
    [ "Bourg-Saint-Maurice",[6.772012236699495, 45.610696261103904]],
    [ "Enedis",[1.399037197377049, 47.31818960837158]],
    [ "Enedis",[3.1716209593062987, 45.74668878385534]],
    [ "Enedis",[3.1726936163224986, 45.745855153973565]],
    [ "Enedis",[2.3111687595699117, 47.57034648880009]],
    [ "Enedis",[3.184695378247114, 47.102500706469826]],
    [ "Enedis",[0.15058010000000002, 49.6198386]],
    [ "Enedis",[2.7836448955409705, 50.58577321266055]],
    [ "Enedis",[4.525278168603841, 48.90968583893003]],
    [ "Enedis",[4.5268383753975066, 48.91242308709238]],
    [ "Enedis",[6.128096907565288, 45.911402419104355]],
    [ "Les Ajoncs",[-1.3420996025902407, 46.156638020362]],
    [ "Strasbourg Électricité Réseaux",[7.707363556162129, 48.39166552776848]],
    [ "Enedis",[3.0752533298916793, 50.5854191864232]],
    [ "RTE",[5.806467949446194, 43.2541508600405]],
    [ "RTE",[5.594637890169149, 43.21282849774449]],
    [ "RTE",[5.70983286238563, 43.1969079290931]],
    [ "RTE",[5.845557221660634, 43.113337505941566]],
    [ "RTE",[4.88856448407657, 45.78812332125852]],
    [ "réséda",[6.203868054377923, 49.01196179382902]],
    [ "réséda",[6.214964999999999, 49.013313999999994]],
    [ "Enedis",[1.565528510424955, 43.7036471660396]],
    [ "Enedis",[1.5799735101501255, 47.34292899730954]],
    [ "Enedis",[1.5917082499999995, 47.31202585]],
    [ "Enedis",[4.900753750000001, 44.11704835]],
    [ "RTE",[3.6948757264690837, 43.41313443350463]],
    [ "RTE",[3.693515928480017, 43.4130268270622]],
    [ "RTE",[3.7595730473934195, 43.533918611268014]],
    [ "Poste électrique du Peyrou",[3.865059522413895, 43.60873918022386]],
    [ "Enedis",[6.485890013538244, 44.55397850468357]],
    [ "Enedis",[1.6002377674099488, 47.40660889925672]],
    [ "Enedis",[1.6082108849463337, 47.40562214852769]],
    [ "Enedis",[1.6188240335678905, 47.47491054334443]],
    [ "Enedis",[0.4726374000000006, 44.31849495]],
    [ "Enedis",[1.9195175591157538, 47.918702610522644]],
    [ "Les Courants",[-1.3282548999999997, 46.17976229999999]],
    [ "Poincaré",[-1.196289130826916, 46.16745274786758]],
    [ "Les Gros Moulins",[-1.3143280497072625, 46.18280413322258]],
    [ "Enedis",[-0.6687940997068222, 44.76341796658555]],
    [ "Enedis",[6.337039853035825, 46.85566701811262]],
    [ "Enedis",[1.406674824456393, 47.49206286663359]],
    [ "Enedis",[1.4027190957372038, 47.493715569136704]],
    [ "Enedis",[1.406724849999999, 47.48904240000001]],
    [ "Base de Loisir",[-1.355951190968382, 46.191064546264]],
    [ "Enedis",[1.3125300669148865, 43.782977306515775]],
    [ "Enedis",[1.37950795, 47.51444530000001]],
    [ "Enedis",[1.2791771798484635, 43.57759920268805]],
    [ "SNCF Réseau",[-4.083092714038355, 48.49586754433249]],
    [ "Enedis",[-1.159330362445633, 45.7820823072567]],
    [ "SRD",[0.29975580000000046, 46.54690895]],
    [ "Le Hall",[6.102935163928921, 45.908005853039285]],
    [ "Amchou",[6.101372049999998, 45.90785245]],
    [ "Centrale Coexhye",[6.101224516207943, 45.90783197869538]],
    [ "Moulin4A",[6.119912179657276, 45.88181960794186]],
    [ "Entremont",[6.116345039169692, 45.88146644873191]],
    [ "Avenue du Semnoz",[6.0990546, 45.878543050000005]],
    [ "Enedis",[3.629717740596333, 46.32575194850159]],
    [ "Enedis",[-0.16693837277317355, 43.900915553332936]],
    [ "Enedis",[1.0929185500000005, 47.409777299999995]],
    [ "Enedis",[1.134500642408914, 47.3364406858278]],
    [ "Enedis",[1.1591408, 47.34214145]],
    [ "Enedis",[1.1547777687786283, 47.33492897992217]],
    [ "Enedis",[1.1627902943528552, 47.34186382489816]],
    [ "Enedis",[1.1260724500000001, 47.334881249999995]],
    [ "Enedis",[3.6920797249221184, 46.35343060560747]],
    [ "Enedis",[0.3898337726626168, 46.5900315596175]],
    [ "Enedis",[0.38609475390800496, 46.57979047881045]],
    [ "Enedis",[0.3840117499999997, 46.5798905]],
    [ "Enedis",[5.806167399999999, 45.823577949999994]],
    [ "Enedis",[5.845541959887693, 45.83352112364369]],
    [ "Enedis",[5.8457208285966775, 45.85723419359914]],
    [ "Enedis",[5.8491909500000006, 45.81387934999999]],
    [ "Enedis",[5.8345484037000155, 45.902612169915464]],
    [ "Enedis",[6.367049109780289, 45.66562070450053]],
    [ "Enedis",[4.764139528105901, 50.10078605294615]],
    [ "Enedis",[-2.3680828732204207, 46.72577575523855]],
    [ "Enedis",[5.775906796658065, 45.587176382476905]],
    [ "Enedis",[0.1590013193591453, 49.64941236841481]],
    [ "Enedis",[0.16448622033784804, 49.6434936969585]],
    [ "Enedis",[0.160553602704744, 49.63148881137807]],
    [ "Enedis",[1.503187651590106, 46.51527628174323]],
    [ "Les Cadorats",[5.644016971005657, 44.966727330270835]],
    [ "Ternier Rosset",[6.085581866172619, 46.14130454449685]],
    [ "EDF",[6.026943343887551, 45.8957455609774]],
    [ "Les Vermerets",[6.039195927132193, 45.90375003815926]],
    [ "Petelat",[6.024599384235296, 45.900506376506264]],
    [ "Enedis",[1.5941617502899181, 47.058176467065955]],
    [ "Enedis",[4.2893263046240495, 49.43786028410716]],
    [ "Enedis",[4.283886189304603, 49.44054102919987]],
    [ "Enedis",[0.2833804, 49.58599365]],
    [ "réséda",[6.221364900000001, 49.09202535000001]],
    [ "réséda",[6.234164224604557, 49.10451075944191]],
    [ "Marguerites",[6.279717981962848, 46.1841468443587]],
    [ "Enedis",[1.8184740835390671, 46.82714764373586]],
    [ "Enedis",[1.7801948930268197, 46.82023566130268]],
    [ "Enedis",[1.8138244999315185, 46.82556357395351]],
    [ "Les Emerys",[6.10855935, 45.9786044]],
    [ "La Cuma",[6.111337104685093, 45.977930498821955]],
    [ "ESSeyssel",[6.042629858478103, 45.9473924481024]],
    [ "SNC Sepric",[6.0808397323648435, 45.93503355114605]],
    [ "Enedis",[2.2211093500000003, 48.852773449999994]],
    [ "Enedis",[6.956371466000139, 48.28630185749112]],
    [ "PX Fusilles 6",[2.22524922333437, 48.88411043788754]],
    [ "Enedis",[1.7308808430622011, 46.582705586124405]],
    [ "Enedis",[5.715027668265697, 46.155771145933194]],
    [ "Enedis",[0.6027810181933231, 47.01615831522544]],
    [ "Enedis",[1.2159186706282914, 47.091457193109555]],
    [ "RTE",[7.397556333630477, 44.06867018437189]],
    [ "RTE",[7.28120593006451, 44.04364925122091]],
    [ "Enedis",[5.74585861916159, 45.552845375567536]],
    [ "RTE",[5.734533315657281, 45.54276564033406]],
    [ "Enedis",[6.804615579113685, 46.06799215743294]],
    [ "Enedis",[6.0359527453307775, 46.46437411913797]],
    [ "Enedis",[6.0473388, 46.4507994]],
    [ "Enedis",[-1.40104623878016, 43.36033734288167]],
    [ "Enedis",[0.2838159834385408, 48.77244366672169]],
    [ "Enedis",[0.685704428924314, 47.36352765739926]],
    [ "Enedis",[6.122131549999999, 48.701309099999996]],
    [ "Enedis",[0.9876534, 47.28971235]],
    [ "Enedis",[3.00325465768106, 47.383828139133975]],
    [ "Enedis",[1.088282076070382, 47.33023444205548]],
    [ "Enedis",[1.1003644558472558, 47.349640308258664]],
    [ "Pompage la Balme",[5.889210672996598, 45.126006534828775]],
    [ "Enedis",[1.1064486332025385, 47.45623146773037]],
    [ "La Plage",[-2.2562256, 48.636338900000005]],
    [ "Enedis",[4.8435954070123435, 45.793705152277774]],
    [ "Mairie",[6.0018265839316385, 48.73831925102874]],
    [ "Enedis",[6.471463560494027, 45.75849074505875]],
    [ "Enedis",[5.7389768000000005, 45.472427200000006]],
    [ "Enedis",[5.75196855, 45.4503025]],
    [ "Enedis",[2.9274516, 47.4801459]],
    [ "RTE",[1.101687367608221, 44.26651420509508]],
    [ "Enedis",[0.5424428858254292, 49.52352018302926]],
    [ "Enedis",[3.163516349862599, 47.01332526661611]],
    [ "Enedis",[3.1656995, 47.013030300000004]],
    [ "Enedis",[1.1111149524261155, 47.32370739656379]],
    [ "Enedis",[1.147018257340114, 47.32827290303422]],
    [ "Enedis",[1.124671087606443, 47.322617782225535]],
    [ "525444202",[1.12454465, 47.31595039999999]],
    [ "Enedis",[1.1238042698079707, 47.324451419787614]],
    [ "Enedis",[1.3155457950299445, 47.30405336434794]],
    [ "Enedis",[2.9722216345758503, 47.28987416326669]],
    [ "Enedis",[2.960803965660832, 47.306990567630876]],
    [ "Enedis",[2.9574785014876053, 47.299202031482956]],
    [ "Enedis",[2.9994970537734917, 47.24242053317746]],
    [ "Enedis",[2.9955503622417723, 47.24002623869411]],
    [ "Enedis",[2.9948706011861845, 47.24281807419174]],
    [ "Enedis",[3.2531630660867306, 47.23991923156052]],
    [ "Enedis",[3.1831428520038862, 47.24558749314689]],
    [ "Enedis",[6.100719250000001, 45.908197349999995]],
    [ "Ilot A",[6.102989035593204, 45.9080207977903]],
    [ "Les Ruines",[5.8033394000000005, 45.05311695]],
    [ "Enedis",[3.0336373914438517, 47.14188579812835]],
    [ "Enedis",[3.034715047514014, 47.138540420095055]],
    [ "Enedis",[4.841372302812024, 45.791062747015715]],
    [ "Enedis",[3.5187226944355094, 47.16062477942428]],
    [ "Preydières",[5.817730269502428, 45.108167729841135]],
    [ "Enedis",[6.269586914400806, 48.717738633131916]],
    [ "Enedis",[0.7130872569478939, 47.28717610240169]],
    [ "Enedis",[3.1481263061503415, 45.75831320434183]],
    [ "Enedis",[1.522845383912152, 43.48632255636701]],
    [ "Poste Transformation - Camping du Niel",[6.3215315, 43.1374314]],
    [ "Enedis",[3.8380664029365, 49.71306548893488]],
    [ "Enedis",[1.5300022, 43.49196465000001]],
    [ "Enedis",[1.5277521499999998, 43.49215785]],
    [ "Enedis",[1.5244385500000002, 43.4931192]],
    [ "Enedis",[3.4511572470083225, 47.19268459457159]],
    [ "Enedis",[2.012282197655425, 46.63801151189421]],
    [ "Enedis",[6.307028249999999, 45.684329899999994]],
    [ "Enedis",[0.7874039156537538, 47.39837927485359]],
    [ "Basse Boulogne",[1.75269935, 49.77531555]],
    [ "Beausejour 035 05",[1.7482766051622398, 49.771475480224076]],
    [ "Enedis",[0.6673987222892442, 47.393289732559666]],
    [ "Enedis",[3.830170699999999, 43.57407805]],
    [ "Enedis",[0.9902246116521376, 49.787367644216275]],
    [ "Enedis",[0.9970320427230905, 49.78639581695799]],
    [ "Enedis",[6.337230076593055, 47.34720155964895]],
    [ "Enedis",[1.7505799820239178, 49.77248155449811]],
    [ "Soeur Badiou",[1.7502578288164374, 49.76744439629989]],
    [ "Enedis",[2.773030570273359, 47.57260134229004]],
    [ "Rue de Normandie",[1.7543618656432327, 49.76762531888615]],
    [ "Bailly",[1.7489122780585358, 49.76441086457332]],
    [ "Enedis",[1.151267686309335, 44.126929141543116]],
    [ "RTE",[6.119677435666194, 45.451680740278036]],
    [ "EDF",[6.1218723429457125, 45.43939114346208]],
    [ "Poste source de Chateau-Gombert",[5.431688226984511, 43.337700320067675]],
    [ "Enedis",[0.7044386006123049, 49.73983598856431]],
    [ "Enedis",[1.60958755560949, 47.76712988428325]],
    [ "La Maladiere",[6.314696183195384, 45.538878249750404]],
    [ "Enedis",[0.5826728292118233, 48.74651210267999]],
    [ "531119482",[-2.728982932150357, 47.514093034110324]],
    [ "réséda",[6.1164892, 49.07239835]],
    [ "GEGrenoble",[6.3377602999999985, 45.523070399999995]],
    [ "Saint-Lazare 035 n°6",[1.7559099000000002, 49.76726815000001]],
    [ "Église 035 03",[1.7533556209051109, 49.769239622599]],
    [ "Enedis",[6.450707776541009, 45.47008035359873]],
    [ "Paniere",[6.3950501, 46.073431150000005]],
    [ "Beziere",[6.391628150000001, 46.07122425000001]],
    [ "ZI N8",[6.096868000122378, 45.9109883931666]],
    [ "Enedis",[0.7366861571736424, 49.61975667833997]],
    [ "Enedis",[4.778320909235443, 45.72530692993283]],
    [ "Enedis",[3.8841195306790928, 43.626141836165175]],
    [ "Enedis",[3.8957234153844778, 43.62409951027539]],
    [ "Enedis",[3.887416193809385, 43.62377005433517]],
    [ "Enedis",[7.088693994563757, 48.93142237959433]],
    [ "532791128",[6.176464933404405, 49.13403855653146]],
    [ "Enedis",[1.811936159289713, 48.39438294044172]],
    [ "Enedis",[1.0916439868743317, 44.10509655137728]],
    [ "ESSeyssel",[6.098918850000002, 45.98158755]],
    [ "Enedis",[5.8836245, 45.07590125]],
    [ "Enedis",[6.560306425914876, 45.45564447541641]],
    [ "Enedis",[6.6984261670049206, 45.42395293210795]],
    [ "Enedis",[6.073246384140895, 45.885390031430234]],
    [ "Enedis",[3.174793765986945, 45.74198329885419]],
    [ "Enedis",[2.9222332500000006, 42.62968825]],
    [ "RTE",[3.5776402170281516, 50.45882483908085]],
    [ "Enedis",[1.9932494668424154, 47.152401277477445]],
    [ "Enedis",[3.5269383570139845, 47.257024872799086]],
    [ "Enedis",[2.0605553573344437, 47.12773258325981]],
    [ "Enedis",[2.0644058546017234, 47.12776472042537]],
    [ "Enedis",[4.326873558004746, 49.52077885438915]],
    [ "Enedis",[4.288827300450304, 49.415519715304164]],
    [ "Gratteloup",[6.250848734619413, 46.043569766319415]],
    [ "Enedis",[4.37174582629229, 49.4000043553548]],
    [ "Enedis",[4.376110753340013, 49.39309457268605]],
    [ "Enedis",[4.382269169310086, 49.392204990191466]],
    [ "Enedis",[4.348662497453407, 49.450806752503546]],
    [ "Enedis",[4.361296370200749, 49.49470396488818]],
    [ "Enedis",[4.368114336263364, 49.491517988468125]],
    [ "Enedis",[4.36879562923155, 49.493689137764015]],
    [ "Chez Christin",[6.176842699999999, 46.00932875]],
    [ "La Rose",[6.16208185, 46.0075382]],
    [ "Enedis",[2.1988121128021056, 47.080268925604216]],
    [ "Enedis",[2.1786695604784336, 47.09498758876634]],
    [ "Enedis",[6.538381228059237, 45.830337099164545]],
    [ "Enedis",[6.540040149999999, 45.82599525]],
    [ "Enedis",[6.683859095525675, 45.55670209370298]],
    [ "Enedis",[6.678079650000002, 45.50784165]],
    [ "Blaissière n° 3",[5.8302969838093075, 48.55745789297894]],
    [ "Enedis",[2.174340933026048, 46.96740655456819]],
    [ "Les Figuières",[6.344757381740466, 43.13532389724398]],
    [ "La Madeleine",[6.263484415718698, 48.63648636430402]],
    [ "Enedis",[0.6570703111958524, 48.779993884587086]],
    [ "Enedis",[0.6392855500000001, 48.7724962]],
    [ "Enedis",[1.4428992888649146, 45.5546378039308]],
    [ "RTE",[-1.7454146331155327, 47.47257775845622]],
    [ "RTE",[-1.5789412286966154, 47.32149165172755]],
    [ "Enedis",[2.2738773321178285, 46.93027316542093]],
    [ "Enedis",[2.2226838299845504, 46.92161938649878]],
    [ "Enedis",[2.271555082173917, 46.93287270985025]],
    [ "Enedis",[2.252093481692584, 46.959992873466156]],
    [ "Groupe Scolaire",[2.252119143359754, 46.954419655729794]],
    [ "Vovray Abattoirs",[6.11699425, 45.8927612]],
    [ "Square Priairie",[6.120948148109038, 45.89503271570375]],
    [ "Isernon",[6.117790192119037, 45.895137823991625]],
    [ "Beaulieu",[6.156918466080568, 45.91239788507019]],
    [ "IRMP Beaulieu",[6.156898703094858, 45.912385666133076]],
    [ "Eden Park",[6.162656945847866, 45.91334620407202]],
    [ "L'Arcana",[6.162091285240757, 45.912253762779955]],
    [ "Malfroid",[6.162534400000001, 45.9075005]],
    [ "Vendome",[6.1063580976709515, 45.8899239339482]],
    [ "Enedis",[6.557098320682724, 45.806624445866866]],
    [ "Enedis",[6.576584251825196, 45.836164989097696]],
    [ "SNCF Réseau",[6.5828521087812994, 45.52545942223307]],
    [ "EDF",[6.562883447028661, 45.498697078347384]],
    [ "Enedis",[6.55581338163097, 45.49561704702474]],
    [ "Enedis",[6.537869007498597, 45.48742284836094]],
    [ "Enedis",[1.426507267006875, 43.71633615716328]],
    [ "Enedis",[5.50152645, 47.098311550000005]],
    [ "Enedis",[1.6610985490748096, 48.40491002106645]],
    [ "Enedis",[1.6587386774805253, 48.40507573895462]],
    [ "Strasbourg Électricité Réseaux",[7.794785965145241, 48.82897971637893]],
    [ "Enedis",[6.061176325631786, 46.34667734129053]],
    [ "Enedis",[-1.6699335863955034, 47.65144998237755]],
    [ "Poste électrique Joli Mai",[-1.5940634241998848, 47.24202141561672]],
    [ "Poste électrique des Tanneurs",[-1.5569182477320143, 47.21991832988752]],
    [ "Sous-station de Blottereau",[-1.4898519543292115, 47.2361281411001]],
    [ "Poste électrique de Montluc",[-1.72889447773008, 47.27553359946548]],
    [ "Électricité de France",[-1.8741031549589755, 47.275269337963095]],
    [ "Poste électrique Kuhlmann",[-2.0115726044397233, 47.28035466756476]],
    [ "RTE",[-2.1980400245481606, 47.29110601634219]],
    [ "RTE",[-2.272376005679102, 47.267852951167306]],
    [ "RTE",[-2.455224916012079, 47.37075701717226]],
    [ "Trebillet",[5.771126754143539, 46.1645120685883]],
    [ "Pompage Coz",[5.799364982267672, 46.14966885698247]],
    [ "Longefan",[5.741360246388367, 46.18055966635099]],
    [ "Enedis",[5.779007040792748, 46.20553702516972]],
    [ "Enedis",[5.860714530338609, 46.20477435754029]],
    [ "Enedis",[5.857721883318099, 46.19628445012848]],
    [ "Enedis",[5.811752500000001, 46.190393099999994]],
    [ "Enedis",[5.801254999292291, 46.16222501642696]],
    [ "Enedis",[5.800563857056117, 46.165425724845896]],
    [ "Enedis",[6.000913282661978, 45.81971529311595]],
    [ "Enedis",[5.947332612330804, 45.87873595927372]],
    [ "Enedis",[5.959096477892792, 45.868566546852676]],
    [ "Enedis",[2.6238450292058473, 48.78365197468668]],
    [ "538177879",[1.1434265088173592, 42.977895111653105]],
    [ "Enedis",[5.798281148345287, 46.1428890242446]],
    [ "St-Germain",[5.738824550000001, 46.17783765]],
    [ "Enedis",[5.729161224016109, 46.17273529643875]],
    [ "Enedis",[5.7176560499999995, 46.167649000000004]],
    [ "Enedis",[5.6570909500000015, 46.161447200000005]],
    [ "Enedis",[5.968174398847893, 45.52645111737376]],
    [ "Bassetin",[0.09536993320812699, 47.97284489162802]],
    [ "Enedis",[4.400450238058585, 45.44139157528329]],
    [ "Enedis",[-1.7376806545823824, 48.2016875189527]],
    [ "Enedis",[3.513299333103901, 49.04924004836666]],
    [ "Enedis",[2.426418570588673, 48.8958988545853]],
    [ "Les Monts",[5.932155938388626, 45.57514345181675]],
    [ "Enedis",[7.023625524236971, 45.42874166079512]],
    [ "Enedis",[-0.8263837407163689, 45.51678869452017]],
    [ "Enedis",[0.3296902654413118, 49.721021704726276]],
    [ "Luzes",[6.214543657736433, 45.874655534746836]],
    [ "Enedis",[0.40848239677949194, 49.77220513100924]],
    [ "Enedis",[0.4120842816042168, 49.77151963559994]],
    [ "Enedis",[0.4202154247105706, 49.768346887148304]],
    [ "Enedis",[0.4097851297489869, 49.73304440302484]],
    [ "Enedis",[-1.2186661499999996, 45.8840715]],
    [ "Enedis",[0.6020586500000008, 48.85093735]],
    [ "Enedis",[1.7571895350069733, 47.62218159330544]],
    [ "RTE",[7.521145703957123, 47.790020793401105]],
    [ "Enedis",[0.8730300738739544, 45.666978804180225]],
    [ "RTE",[6.478961721326224, 45.52690594873161]],
    [ "RTE",[6.437679332578214, 45.64202658256596]],
    [ "540272347",[6.453239740712607, 45.61952069096792]],
    [ "Enedis",[6.510832195610976, 45.55200590175107]],
    [ "Enedis",[6.453855583789228, 45.55062381169878]],
    [ "Enedis",[6.393308349345945, 46.24547671662979]],
    [ "Enedis",[6.355560682798611, 46.22303308955795]],
    [ "Enedis",[1.5277934630918508, 46.83760720691155]],
    [ "Enedis",[1.5313041027626504, 46.841305238441656]],
    [ "Enedis",[1.5385348657113889, 46.8517253553632]],
    [ "REThones",[6.4364742, 45.960876400000004]],
    [ "Enedis",[0.433034044997307, 47.25245318281844]],
    [ "Enedis",[1.2332183012605273, 42.91972768891162]],
    [ "Poste électrique de Langevin",[55.64720488514321, -21.35815774644868]],
    [ "Enedis",[5.82530035, 45.42090335]],
    [ "Enedis",[6.331547, 45.6448356]],
    [ "Enedis",[0.545092183147035, 47.30708855231756]],
    [ "Cry",[6.273277530534159, 46.14217720206464]],
    [ "Lot Communal",[6.095183665331984, 46.12069947490258]],
    [ "Chamossat",[5.9789044, 45.80338564999999]],
    [ "Erema",[6.314970928961079, 45.5150275885751]],
    [ "Enedis",[2.3368950720572483, 47.96719741002726]],
    [ "Enedis",[0.9110418534760781, 48.260834738682654]],
    [ "Enedis",[2.5911905427741346, 47.35372167690158]],
    [ "Enedis",[5.65970455, 45.58050050000001]],
    [ "Enedis",[5.70833235314046, 45.51277785209762]],
    [ "Enedis",[0.35500585000000057, 47.29503165]],
    [ "Enedis",[5.786063823949701, 46.71558109429241]],
    [ "Enedis",[2.686797446587286, 47.30378693237436]],
    [ "Enedis",[4.494627830380937, 49.51743854188091]],
    [ "Enedis",[1.63880905, 50.728896450000015]],
    [ "La Balme",[6.315139093098485, 45.525867773470985]],
    [ "Montartier",[6.3150694, 45.5258636]],
    [ "Sous-station Asnieres",[2.284222140580158, 48.90497585186224]],
    [ "Enedis",[5.511978249619697, 47.077585816105554]],
    [ "Enedis",[2.7538473667588748, 47.40391715040952]],
    [ "Enedis",[5.9569505, 45.79774269999999]],
    [ "Champ Herse",[5.9667110795648695, 45.80215574737517]],
    [ "Cimetiere",[5.971736924932205, 45.802978792584184]],
    [ "Irondel4",[5.967039975157968, 45.79656539652518]],
    [ "Alexandra",[5.970076222251774, 45.79984655895896]],
    [ "Port",[6.325219849999999, 46.370743850000004]],
    [ "Enedis",[2.799874785699824, 50.1844962246896]],
    [ "Enedis",[2.1558473413464796, 47.01379832612939]],
    [ "Enedis",[0.25975112465080846, 49.700757803353014]],
    [ "Enedis",[2.8884835999999994, 47.52370895]],
    [ "Enedis",[2.890288747734401, 47.533858255669]],
    [ "Enedis",[2.8844045499999997, 47.52252274999999]],
    [ "Enedis",[2.8811614219462203, 47.51996294309235]],
    [ "Enedis",[2.8825855, 47.5254941]],
    [ "Enedis",[2.8852177164065274, 47.520897401296814]],
    [ "Enedis",[2.8782647, 47.52863795000001]],
    [ "Enedis",[2.891116812132387, 47.52166767368214]],
    [ "Enedis",[2.864445191737068, 47.532894021603816]],
    [ "Enedis",[2.1564268190755227, 46.52353153975625]],
    [ "Enedis",[0.5129980402798384, 49.74314820312755]],
    [ "Enedis",[0.5158775571470336, 49.74132281919294]],
    [ "Enedis",[0.5133775248093975, 49.744228348672905]],
    [ "Enedis",[1.6163176499999998, 50.76242655000001]],
    [ "Enedis",[0.7065271639953514, 47.28399943410972]],
    [ "Enedis",[0.7059696924586668, 47.27842811848534]],
    [ "Delaitre",[6.297033583142368, 46.21575860039042]],
    [ "Vers les Bois",[6.298439899999999, 46.216230249999995]],
    [ "Église",[4.9009630069314545, 45.42071702602972]],
    [ "réséda",[6.238729586755882, 49.085561403926526]],
    [ "Poste électrique du Prat",[-2.7109749210713927, 47.657289860473476]],
    [ "RTE",[-3.3227680460530697, 47.78056691461019]],
    [ "Enedis",[0.2812819213220548, 49.698759936924425]],
    [ "Enedis",[0.2757245693505936, 49.69932349341705]],
    [ "Enedis",[0.2869796875299756, 49.69958174996003]],
    [ "Poste électrique de Grandes-Rivières",[-2.136605431675527, 47.306683195861766]],
    [ "Enedis",[3.669960569532701, 46.603554604273]],
    [ "RTE",[-3.486348680105413, 47.76748478046093]],
    [ "Enedis",[5.349674034439618, 43.3819828125117]],
    [ "Enedis",[3.78928620807558, 46.35239711267096]],
    [ "Poste électrique de Facture",[-0.9720435657806977, 44.63696682943507]],
    [ "Poste électrique de Haubourdin",[2.9829412295310234, 50.60546884285664]],
    [ "Sequedin",[3.0045369917538474, 50.625527786875516]],
    [ "Sous-station de Lille-Délivrance",[2.991493150526212, 50.62752879816053]],
    [ "Enedis",[4.794240312977025, 45.34350357436309]],
    [ "Enedis",[3.101649332965432, 50.58285944419023]],
    [ "Enedis",[3.0992232027351196, 50.581746532071826]],
    [ "Enedis",[3.0981460500000004, 50.5816011]],
    [ "Enedis",[3.0996327165050515, 50.58474473742635]],
    [ "RTE",[4.715123756302299, 44.332935650613635]],
    [ "Poste électrique de Pierrelatte",[4.716746380873126, 44.34340383071263]],
    [ "Enedis",[3.4514317740443454, 49.498268017310224]],
    [ "Enedis",[3.44968875826086, 49.49455628567792]],
    [ "Enedis",[1.65946919518847, 47.318025383277906]],
    [ "Poste électrique du Galion",[-60.946662762137265, 14.7164553209665]],
    [ "Enedis",[0.3079913576178797, 49.73901450148543]],
    [ "Enedis",[0.312679271897738, 49.73658395082163]],
    [ "Enedis",[0.3143757959479549, 49.73721006569694]],
    [ "Enedis",[0.3190830647401922, 49.733909047769274]],
    [ "Enedis",[0.3094831192505972, 49.73764108598949]],
    [ "Enedis",[0.6947755724164159, 47.41802194706316]],
    [ "Poste de Viaud",[6.570954256267052, 49.05312727362462]],
    [ "Enedis",[0.5417326282019208, 49.76968223599682]],
    [ "Enedis",[1.7631261799990907, 50.481692229923105]],
    [ "RTE",[-2.462341093429547, 47.6828473835931]],
    [ "réséda",[6.144246389988623, 49.152170537997726]],
    [ "Enedis",[-1.0559018558577127, 45.67397277158197]],
    [ "Enedis",[1.7301071675342992, 49.30907324162349]],
    [ "RTE",[-3.0194299058246856, 48.19311466484501]],
    [ "Poste électrique de Mauduit",[-3.5456530952006213, 47.88708179331541]],
    [ "Poste électrique de Quimperlé",[-3.5499607064977194, 47.883684670584124]],
    [ "Poste électrique de Malestroit",[-2.3696764560831256, 47.80875012181479]],
    [ "RTE",[1.9509916566514098, 44.95399264741418]],
    [ "Enedis",[1.3798566551214986, 49.2052648335157]],
    [ "RTE",[-1.0810190748800208, 46.13390632175695]],
    [ "Enedis",[-0.5550453391194837, 47.44299515955508]],
    [ "Enedis",[-0.48198921072189493, 47.50668707687465]],
    [ "RTE",[-0.6143847516532068, 47.52660702732462]],
    [ "RTE",[-0.14115315269172837, 46.80974460261211]],
    [ "Enedis",[3.4784672362785143, 49.39878231000026]],
    [ "réséda",[6.165122768517738, 49.094473684352295]],
    [ "Poste électrique des Crechets",[2.7135825254262884, 50.66333654386323]],
    [ "Enedis",[-1.2068022202193427, 46.161000913531225]],
    [ "SNCF Réseau",[1.4414345496357774, 44.09028372746134]],
    [ "SNCF Réseau",[0.8829661522296033, 44.11395275026121]],
    [ "RTE",[0.8828653047251658, 44.11656176850965]],
    [ "Enedis",[1.4780086407313626, 49.27600268981117]],
    [ "Messioual Spernot",[-4.506019132965663, 48.42112139954304]],
    [ "RTE",[1.1084692004264245, 44.047695263069784]],
    [ "Enedis",[1.3013969573752902, 49.265137247476005]],
    [ "Sous-Station de Lavilledieu",[1.1956258208597745, 44.03615042032079]],
    [ "Enedis",[0.2288906953820683, 49.60672913246]],
    [ "Enedis",[0.23079019906706072, 49.607786866488595]],
    [ "Enedis",[0.22782425000000006, 49.6037296]],
    [ "Enedis",[0.23122912368213633, 49.60562928409243]],
    [ "Enedis",[4.950927330580608, 45.4431544951023]],
    [ "Tissage",[4.936408319239877, 45.431132403302435]],
    [ "Armandieres",[4.897005217767356, 45.42077940187923]],
    [ "Mairie",[5.017843933550089, 45.51226451688926]],
    [ "Vernea",[5.034406425441853, 45.52189153193059]],
    [ "Gaitieres",[5.012866792453885, 45.52169629755714]],
    [ "Les Granges",[5.002626250759154, 45.509780098843024]],
    [ "Enedis",[2.374325053728418, 51.045755587482674]],
    [ "Enedis",[2.36431024817841, 51.048590465087116]],
    [ "Chavanette",[6.6089638372890365, 46.318518297817]],
    [ "Poste électrique d’Odet",[-4.0212178975435, 48.025264162077065]],
    [ "RTE",[-1.1648297609268394, 47.75001157433868]],
    [ "Poste électrique de Semblancay",[0.5552273627686314, 47.52240980123203]],
    [ "Poste électrique de Chaumette",[0.6467398159675252, 47.35687617510818]],
    [ "Poste électrique de Vaugouin",[-1.1929768308949413, 46.15602234678241]],
    [ "Poste électrique des Minimes",[-1.1507883940546813, 46.148317843651675]],
    [ "Enedis",[-1.7086048499999997, 48.1270688]],
    [ "Enedis",[-1.70721795, 48.131729449999995]],
    [ "Arenc",[5.3664286529281116, 43.32196166281123]],
    [ "RTE",[-4.02632759019178, 48.098987300912]],
    [ "Enedis",[3.703686392166546, 46.45386626903492]],
    [ "Poste électrique de Saint-Renan",[-4.640200465760382, 48.434667262542774]],
    [ "Sous-station SNCF de Pencran",[-4.227913396631028, 48.46181620975256]],
    [ "Enedis",[2.6003967090190137, 48.812473120007226]],
    [ "Enedis",[5.716783243326544, 46.445996996719785]],
    [ "Enedis",[6.647085550000001, 45.2027909]],
    [ "Enedis",[-0.33539256713129445, 47.84061462131109]],
    [ "Sous-station de Marguand",[1.8554765700105977, 48.691058495283634]],
    [ "Monin ZI",[4.705298029409813, 45.61935774754253]],
    [ "Enedis",[4.703154042853719, 45.621082872035764]],
    [ "Le Clair",[4.68767571473428, 45.63841017464983]],
    [ "Bottand",[4.6825680162973455, 45.636438349595856]],
    [ "Poste source de Miolles",[2.5674359447204997, 43.874267560194156]],
    [ "Poste électrique de Brens",[1.9597922281984486, 43.905601386280516]],
    [ "Enedis",[1.5746673675755427, 43.59104112141771]],
    [ "Enedis",[0.8464898789185116, 47.42968813956486]],
    [ "RTE",[0.6501132904745754, 44.21995792469866]],
    [ "Enedis",[0.7820576000000004, 47.49350525]],
    [ "Enedis",[1.4417511862278596, 43.66136611716604]],
    [ "Enedis",[3.8500651044096665, 46.92211812364056]],
    [ "Enedis",[3.853221078407806, 48.502193656399754]],
    [ "SICAE-Somme",[2.874091349999999, 49.736339650000005]],
    [ "Enedis",[3.9182608652327127, 47.9482811620783]],
    [ "Enedis",[-1.748919466774403, 48.11658120011808]],
    [ "Enedis",[-1.7485055772159939, 48.11814059693472]],
    [ "Enedis",[3.009593730358085, 42.76369625278355]],
    [ "Enedis",[3.0079108, 42.762435599999996]],
    [ "Enedis",[3.009526350000001, 42.7623124]],
    [ "Enedis",[3.0121175500000006, 42.761931350000005]],
    [ "Enedis",[3.009780146062948, 42.76055733140622]],
    [ "Enedis",[3.013366622259666, 42.75858110376893]],
    [ "Synergie Maurienne",[6.437519866530892, 45.236270400215695]],
    [ "Pont Ramina",[6.439101838961802, 45.236372510995196]],
    [ "Enedis",[0.542624858436734, 47.544218038143406]],
    [ "Enedis",[2.59335495, 48.80363415]],
    [ "Enedis",[3.5126407999756357, 49.04392629370489]],
    [ "Enedis",[3.509811749152204, 49.04620364012452]],
    [ "ZAC Tuilerie 2",[6.180659782285078, 45.83130982721127]],
    [ "Enedis",[0.22097595, 49.63857855]],
    [ "Enedis",[-1.3872030999999991, 43.54340485000001]],
    [ "Enedis",[-1.3839065, 43.54598849999999]],
    [ "Enedis",[1.5698565826414594, 43.55291218431714]],
    [ "Enedis",[6.383116836069869, 48.55075095185316]],
    [ "Enedis",[1.9655446, 46.16388825000001]],
    [ "Enedis",[3.0970547320475816, 49.484886046948894]],
    [ "Enedis",[-4.54259363835652, 48.38610948460503]],
    [ "Enedis",[0.20280542638978824, 49.68210754679372]],
    [ "Enedis",[-1.6926107072227203, 48.092080231680136]],
    [ "Enedis",[-0.4142813250447936, 48.55684010978963]],
    [ "Enedis",[2.5231135687736534, 50.73118441570989]],
    [ "Enedis",[1.4808985442837543, 43.68055297113199]],
    [ "551819299",[0.6931882206248742, 47.333453992521356]],
    [ "Enedis",[1.9296872122749646, 47.8364621709401]],
    [ "Enedis",[3.214081595763309, 49.08457447582714]],
    [ "Enedis",[1.355253765621263, 43.55817357473719]],
    [ "réséda",[6.122343302603991, 49.0640444656514]],
    [ "Enedis",[2.9105229953041944, 42.66258677095056]],
    [ "Enedis",[2.9096905999999993, 42.660402149999996]],
    [ "Enedis",[2.9096299999999986, 42.66040234999999]],
    [ "Poste électrique Salanques",[3.0031711738401445, 42.788117678129744]],
    [ "Poste électrique de Laval",[-0.7926441001179408, 48.052097379323065]],
    [ "Enedis",[-1.0089957575960407, 47.984063474858]],
    [ "Poste électrique de Montahut",[2.9404317710469994, 43.570324027810216]],
    [ "Enedis",[-2.2353873, 47.01100885]],
    [ "SRD",[0.3695212500000001, 46.4776195]],
    [ "SRD",[0.3715889333505174, 46.47907970014797]],
    [ "SRD",[0.367369302140007, 46.47697054639101]],
    [ "Enedis",[-1.891078169176502, 48.22204607291808]],
    [ "Enedis",[-1.9118178761404105, 48.183824953313945]],
    [ "Enedis",[-2.0378579707335054, 48.19451082478344]],
    [ "Enedis",[-2.0363441063915713, 48.19852760592603]],
    [ "Enedis",[1.3642125187845582, 43.550939995901594]],
    [ "réséda",[6.138992928844048, 49.0683043377444]],
    [ "Enedis",[2.332600863586401, 51.007194810978845]],
    [ "Poste électrique de Couffrau",[2.796545271659119, 43.72972193469101]],
    [ "Poste source de Saint-Vincent",[3.2185760326816686, 43.36752129640154]],
    [ "Enedis",[1.9711465448910153, 47.915513770762225]],
    [ "RTE",[4.739865077099293, 43.82977974454128]],
    [ "Enedis",[1.4690344796863704, 43.53496978343736]],
    [ "réséda",[6.1221871000000005, 49.055618100000004]],
    [ "CNR;RTE",[4.872232222506248, 44.961105024552126]],
    [ "CNR;RTE",[4.811404583843487, 44.82356787176733]],
    [ "Enedis",[2.3431053999999993, 47.1043095]],
    [ "Enedis",[2.348268199558859, 51.017021116822036]],
    [ "Enedis",[3.9015203500000006, 49.566823750000005]],
    [ "Enedis",[2.3621970253184554, 51.019847887081895]],
    [ "Enedis",[1.7614532719776321, 48.51382047543611]],
    [ "Enedis",[0.7111535067618285, 47.419626894390966]],
    [ "Enedis",[1.7614300563886223, 48.5171997588001]],
    [ "Mallein",[6.091541602819294, 45.94545596588288]],
    [ "Prairie",[6.393892400660451, 46.0753632291395]],
    [ "Les Bruts",[6.488406599999999, 46.127809899999995]],
    [ "Bigmat",[6.710283966585306, 45.91414288017264]],
    [ "Enedis",[6.715068181408491, 45.91980120797718]],
    [ "Lot Pegaz",[6.154621278039602, 45.825041289818465]],
    [ "Cret Belluard",[6.146267343521478, 45.83372516563217]],
    [ "Les Chataigniers",[6.150223950000001, 45.8305981]],
    [ "Hôtel Semnoz",[6.151873404205753, 45.82551701742461]],
    [ "Gefco",[6.313259699999999, 46.08869015]],
    [ "Clé",[6.294083083238932, 46.08984254912902]],
    [ "La Riolle",[6.769972893343196, 46.04541892022561]],
    [ "Chevrier",[5.9162879, 46.10878955]],
    [ "Gruaz",[6.43738445043493, 46.21323786676651]],
    [ "Stade",[6.343755901145815, 46.16062801712824]],
    [ "Grand Noix",[6.36019295, 46.16501455000001]],
    [ "Resfilin",[6.35363385, 46.1636297]],
    [ "Bellegarde",[6.350258335208059, 46.165561745469084]],
    [ "Enedis",[0.22195690000000004, 49.66558435000001]],
    [ "Enedis",[0.22140691202197132, 49.66344377526859]],
    [ "Enedis",[2.3690314066693756, 51.025900453030815]],
    [ "Enedis",[2.36852534672516, 47.1257269222502]],
    [ "Poste électrique de Pernes",[2.426510355559713, 50.481234482773125]],
    [ "Enedis",[2.8976803923649577, 42.56630149900026]],
    [ "Enedis",[-0.669534037964756, 47.540738892090495]],
    [ "Enedis",[6.276054850993689, 46.167130295025316]],
    [ "Enedis",[6.27546743636492, 46.16189484493762]],
    [ "Enedis",[6.734196216823924, 48.01418610017378]],
    [ "556423524",[1.9847345345009324, 46.585200376277996]],
    [ "Pierrailles 2",[6.2711482499999995, 46.155895349999994]],
    [ "Salle Villageoise",[6.268267218370164, 46.15923691858572]],
    [ "Truaz",[6.262603827134209, 46.15957321205757]],
    [ "Arthaz",[6.2703782, 46.15884455]],
    [ "Enedis",[6.2481182213889355, 46.190195954279226]],
    [ "Enedis",[6.315279400000001, 46.1615193]],
    [ "Enedis",[2.9952756206064857, 42.610295030169404]],
    [ "Enedis",[3.0476385570675277, 50.65077338445351]],
    [ "Enedis",[2.3260423266192456, 51.045126493654635]],
    [ "Enedis",[2.8642381500000003, 42.64398785000001]],
    [ "Enedis",[2.865941193444689, 42.64190597048227]],
    [ "Enedis",[4.090986693747235, 49.908219500734084]],
    [ "Enedis",[1.2500624011144943, 48.29561832220785]],
    [ "Enedis",[1.96727795, 47.82807615]],
    [ "Enedis",[0.8502465757055119, 47.37732174139256]],
    [ "Poste électrique de Blaux",[6.042020773968056, 44.40233101711423]],
    [ "Enedis",[3.56638095, 50.43240875]],
    [ "RTE",[2.5089693056808287, 49.29160750207323]],
    [ "Enedis",[0.8443314535253221, 47.38301485577624]],
    [ "Enedis",[2.937694147560869, 42.66058417017073]],
    [ "Enedis",[2.937130931974158, 42.65805815135489]],
    [ "Enedis",[2.9393288550888386, 42.657943856872556]],
    [ "Enedis",[2.940594574854058, 42.66019464224016]],
    [ "Enedis",[-1.8182623564828226, 49.50958462828564]],
    [ "Enedis",[0.9409036709844443, 45.875771476282914]],
    [ "Enedis",[0.9253708551486395, 45.86110925354748]],
    [ "Enedis",[0.9431911662805127, 45.8734363508855]],
    [ "Enedis",[0.9387568, 45.8531267]],
    [ "Enedis",[5.1943482, 44.68934095000001]],
    [ "Enedis",[2.94954530888904, 42.685441910656195]],
    [ "Enedis",[-1.5951143084789554, 49.61887243733089]],
    [ "Enedis",[2.945818818823998, 42.684166698031675]],
    [ "Enedis",[6.029358519980944, 46.261188360156865]],
    [ "RTE",[2.6394108391136, 48.5299013487979]],
    [ "RTE",[4.864876880765094, 47.57973050912093]],
    [ "Poste électrique de Montereau",[2.852037935156873, 48.38076179597438]],
    [ "Enedis",[0.6886795754571146, 49.612361938946634]],
    [ "Enedis",[0.6261992484444957, 49.60581794198847]],
    [ "Enedis",[0.4934230757516973, 49.22965132462003]],
    [ "Enedis",[0.6315984758187897, 49.622577939099145]],
    [ "Enedis",[0.6047110587349306, 49.61201281013207]],
    [ "Enedis",[0.5927771738981603, 49.65157027971759]],
    [ "Enedis",[0.5981569356577342, 49.6527565597527]],
    [ "Enedis",[0.5951652196905098, 49.65360047451445]],
    [ "Enedis",[0.7767100753177343, 49.6291938678477]],
    [ "Enedis",[0.7660203523522814, 49.62730978662385]],
    [ "Enedis",[0.6040696794199641, 49.72960962294465]],
    [ "Enedis",[0.5441244480632463, 49.353061828806666]],
    [ "Enedis",[2.945163129413812, 42.691964140626325]],
    [ "Enedis",[2.9432712459760686, 42.69152263062354]],
    [ "Enedis",[0.7887114, 49.721276599999996]],
    [ "Enedis",[0.7830173883454391, 49.72125299785276]],
    [ "Enedis",[0.7796288107565544, 49.719797720109774]],
    [ "Enedis",[0.7867474015988527, 49.72269818994135]],
    [ "Enedis",[0.7839757851830298, 49.722729617542264]],
    [ "Enedis",[0.7884509027450979, 49.717127646666654]],
    [ "Enedis",[0.781429758527831, 49.717873428708636]],
    [ "Enedis",[6.219944180834309, 46.18910990975588]],
    [ "Enedis",[6.22020853133973, 46.18762056658634]],
    [ "Enedis",[-4.110378980743117, 48.52922140930976]],
    [ "Enedis",[-0.30573187003780317, 49.11937421677306]],
    [ "Enedis",[-0.3191736000000005, 49.09670255]],
    [ "Enedis",[1.646265826215659, 48.54254514839571]],
    [ "Enedis",[1.6708376231038502, 48.52480235227538]],
    [ "Enedis",[1.5390037178673663, 48.64153787289247]],
    [ "Enedis",[1.536526143711758, 48.64590048425249]],
    [ "Enedis",[1.5631318068952538, 48.59296933516475]],
    [ "Enedis",[1.5690533573353302, 48.58140402333779]],
    [ "Enedis",[1.56484846622644, 48.589984300630405]],
    [ "Enedis",[1.57384825209775, 48.583111384720915]],
    [ "Enedis",[1.5632936006702811, 48.57866262221463]],
    [ "Enedis",[1.5619738571880957, 48.582533629692364]],
    [ "Enedis",[1.5674983956533222, 48.58394325759875]],
    [ "Enedis",[1.5707718696477193, 48.58309409575483]],
    [ "Enedis",[1.565870364540221, 48.58116117874774]],
    [ "Enedis",[1.5636914619358833, 48.58117126912972]],
    [ "Enedis",[1.5612444690431908, 48.5895109900806]],
    [ "Enedis",[-0.3351074318377636, 49.149833938812606]],
    [ "Enedis",[-0.3344723497688854, 49.148699622784]],
    [ "Enedis",[-0.2879784310897305, 49.14509329757635]],
    [ "Enedis",[3.4137333362252607, 47.43683129005832]],
    [ "CPCU",[2.327876620248213, 48.91145294138677]],
    [ "Poste source de Caen dit de la Dronnière",[-0.33929088010411834, 49.139541930478735]],
    [ "Enedis",[3.2869740999999997, 45.41780074999999]],
    [ "Hérisson 2",[-0.5569578000000002, 47.49116395]],
    [ "Enedis",[5.68952445, 46.849784299999996]],
    [ "RTE",[4.177747008932091, 45.22304668251843]],
    [ "RTE",[4.187199500000001, 45.16144335]],
    [ "RTE",[4.146071524367591, 45.24596039774095]],
    [ "Enedis",[5.277790172896632, 44.568831015195414]],
    [ "Petite Rue",[5.967775768355085, 48.756705602607994]],
    [ "Enedis",[1.4774158338889518, 42.76210412947575]],
    [ "Enedis",[3.210080968017291, 47.283022278952764]],
    [ "Le Parc",[3.1614924000000006, 45.704920800000004]],
    [ "Enedis",[-0.9510591, 44.64073824999999]],
    [ "Enedis",[-0.9571570999999991, 44.6360446]],
    [ "Bourdonnières",[0.12902505, 48.006555999999996]],
    [ "Enedis",[5.504965479303381, 47.10572889737905]],
    [ "Enedis",[1.525250496421268, 47.66948128071575]],
    [ "Enedis",[5.602465141875403, 46.75729913416827]],
    [ "Enedis",[1.5276022828752176, 47.66633046666947]],
    [ "Enedis",[-1.0468553697976413, 43.732089902260334]],
    [ "Enedis",[1.4407759185221225, 47.645799991903466]],
    [ "Enedis",[1.4455416156015268, 47.64148259783278]],
    [ "Enedis",[1.4490215756494729, 47.63504903633693]],
    [ "Enedis",[1.4481011335442002, 47.638981029137064]],
    [ "Enedis",[1.4451467306119745, 47.63905622063366]],
    [ "Enedis",[1.43876235, 47.633736999999996]],
    [ "Enedis",[1.4345557142382668, 47.63446983418453]],
    [ "Enedis",[1.4394083499999992, 47.636285750000006]],
    [ "RTE",[4.694888178500532, 45.69586485405581]],
    [ "Commerces",[5.661412615767927, 46.68758094399262]],
    [ "Enedis",[1.1162800655629004, 45.80147799790594]],
    [ "Enedis",[3.1692496710346933, 50.68955277976409]],
    [ "Enedis",[4.105327435701287, 47.424090469291755]],
    [ "Enedis",[4.106336199414407, 47.425688260556434]],
    [ "Enedis",[2.389336130435417, 47.051819723530784]],
    [ "Enedis",[1.2986771983413343, 47.57039606736868]],
    [ "Enedis",[4.468789973695054, 44.880343794576284]],
    [ "Enedis",[4.48515356450177, 44.88548397195734]],
    [ "Enedis",[5.36065765, 43.422126799999994]],
    [ "Enedis",[3.7307590267842854, 44.020476566041786]],
    [ "SRD",[0.22764799999999996, 46.30019670000001]],
    [ "Enedis",[6.837025350000002, 47.6462433]],
    [ "Enedis",[3.8610723499999997, 43.67067605]],
    [ "Enedis",[3.0751444, 48.039575049999996]],
    [ "Enedis",[-1.0393947869726474, 43.728815834085815]],
    [ "Enedis",[6.88222485, 47.6648837]],
    [ "Enedis",[3.5941265513515046, 47.0806317075866]],
    [ "Enedis",[1.5423205160532338, 43.54874329263881]],
    [ "Enedis",[1.5431122205857775, 43.5483449604621]],
    [ "Enedis",[1.3625163206807795, 43.5398113202858]],
    [ "Enedis",[1.3625787999999985, 43.5398002]],
    [ "RTE",[-1.0388582133157473, 48.10843206797204]],
    [ "Creusation",[5.068244613435867, 47.30953242706035]],
    [ "Enedis",[4.785992579138999, 45.37633577705039]],
    [ "Enedis",[4.7835496, 45.37998335]],
    [ "Poste électrique de Pontmain",[-1.0719810091571869, 48.440649295867814]],
    [ "Enedis",[6.123971171208965, 45.90815162479748]],
    [ "RTE",[-0.7063363234969059, 48.31976125435164]],
    [ "RTE",[-2.757184136049245, 48.52297600679325]],
    [ "RTE",[-2.7744883816281933, 48.50237705407636]],
    [ "Poste électrique de Nénez",[-3.3831604775103545, 48.55836091048349]],
    [ "réséda",[6.1502870000000005, 49.1371768]],
    [ "Enedis",[-1.48825535, 46.76370215]],
    [ "Enedis",[1.4812087312074067, 43.68493255852633]],
    [ "RTE",[-1.4451392470354016, 47.188446613407756]],
    [ "RTE",[-1.587215304891888, 47.16440144240526]],
    [ "Enedis",[0.28823401759603473, 46.13983939120198]],
    [ "BELVEDERE",[5.759311045347263, 45.14551473617602]],
    [ "Enedis",[2.891278922530882, 50.69737565285383]],
    [ "Enedis",[5.969401343194813, 46.10906262413118]],
    [ "Poste électrique de Changé",[-0.749521945874195, 48.096098649209864]],
    [ "RTE",[-0.7282184216649211, 48.041184081877965]],
    [ "RTE",[-0.13313221976875939, 47.99165374361244]],
    [ "Poste électrique de Pré-en-Pail",[-0.2212849256889219, 48.46189413453338]],
    [ "Enedis",[3.1486701964683688, 47.48801432978734]],
    [ "Enedis",[3.1309637623666458, 47.50031389842212]],
    [ "Enedis",[3.1680293499999994, 47.48630285]],
    [ "Poste électrique de Boisthorel",[0.5629246156003287, 48.74701672791202]],
    [ "Veuves-Monteaux",[1.1245858770492858, 47.47611111071604]],
    [ "RTE",[1.3708943369668767, 47.575401093956984]],
    [ "RTE",[0.7462270767588265, 47.82550892653482]],
    [ "RTE",[0.7475088540053006, 47.82515153040634]],
    [ "réséda",[6.2299271664610325, 49.11279708307742]],
    [ "RTE",[0.171920975798143, 47.93656960158904]],
    [ "RTE",[0.6423994037607468, 48.137332719736754]],
    [ "Enedis",[2.2825475500000003, 49.892896650000004]],
    [ "Enedis",[3.1335245831271936, 50.63507104898857]],
    [ "Vie de Joux",[6.437664450000001, 47.13336545]],
    [ "Bareillot",[6.438873149999998, 47.137674999999994]],
    [ "RTE",[0.6333519790423799, 47.19890335137726]],
    [ "Cadran",[5.1552016499999995, 45.61216825]],
    [ "SRD",[0.3748898186644877, 46.74508233707676]],
    [ "Enedis",[1.3802392695343324, 47.254802807804694]],
    [ "RTE",[0.9653056688321877, 47.30970716153855]],
    [ "Vernou",[0.8539095740835955, 47.40531628140518]],
    [ "Enedis",[3.0826285122468335, 50.62317982033898]],
    [ "Enedis",[1.9372646622624075, 47.89101288143999]],
    [ "Enedis",[-1.9515139000000006, 46.86802195]],
    [ "Poste électrique de Limeux",[1.8284427540890735, 50.01096244528863]],
    [ "SRD",[0.08778512324755904, 46.84075757321539]],
    [ "Enedis",[-1.8420588, 46.85240304999999]],
    [ "Enedis",[0.46848545253507556, 47.66511584261686]],
    [ "Storengy",[1.483301027988594, 47.390845537558775]],
    [ "RTE",[1.4466686232114938, 47.43038389438248]],
    [ "RTE",[0.7120101520375248, 42.88666048381304]],
    [ "RTE",[0.7366816, 42.8687401]],
    [ "RTE",[0.7504001436888676, 42.865783797302164]],
    [ "RTE",[0.6024068348535281, 42.85358728380718]],
    [ "Enedis",[-1.8569287820105602, 46.84312139752297]],
    [ "MD Vertugadins 4",[2.2366990998572693, 48.80259001856548]],
    [ "MD Vertugadins 14",[2.239585022390017, 48.80207206895982]],
    [ "Enedis",[0.7386706610913405, 49.26570392502966]],
    [ "RTE",[0.6224466894726992, 46.56546404043925]],
    [ "RTE",[0.9420305652175618, 46.8583406020775]],
    [ "RTE",[0.6874733902779079, 46.97660706040006]],
    [ "RTE",[0.532035562072153, 46.8059549063201]],
    [ "Enedis",[-1.893301649999999, 46.84345465]],
    [ "Enedis",[6.824329541041255, 45.56378784095349]],
    [ "Enedis",[6.831013176082316, 45.57497173372382]],
    [ "Riviere",[1.9524232, 45.15187325]],
    [ "La Chapeloune",[1.7743257, 45.195711499999994]],
    [ "Clerc",[5.167530999268626, 45.61957222280017]],
    [ "RTE",[2.008134633850533, 45.15104354510709]],
    [ "EDF",[2.008929151577912, 45.15097768506782]],
    [ "Enedis",[-1.8978084968518696, 46.83879826579052]],
    [ "Pincio",[6.2269684000000005, 46.197515149999994]],
    [ "Enedis",[-5.10557245, 48.45906745]],
    [ "Poste électrique de Cestas",[-0.7977558245576218, 44.72014102422764]],
    [ "Chassée Morte",[-2.2400460674386675, 46.9849816529716]],
    [ "Enedis",[3.138828899999999, 45.71793649999999]],
    [ "Fossard 014",[6.2492788358292914, 46.199663435974735]],
    [ "Enedis",[1.5620755034443914, 49.331510053914705]],
    [ "Grand Coeur I",[6.17858147276422, 48.6863826764855]],
    [ "Enedis",[4.356176719586594, 43.8097120761563]],
    [ "Enedis",[2.031794114273272, 44.98266824121559]],
    [ "EDF",[2.218827414950427, 44.94546339051259]],
    [ "Poste électrique de l'Enchanet",[2.194438666383463, 45.081497685700356]],
    [ "Barrage d'Enchanet",[2.193545523848788, 45.08079117519983]],
    [ "RTE",[1.4455392950600459, 43.34753196348609]],
    [ "RTE",[-0.2209961552859448, 43.78048765114989]],
    [ "Enedis",[4.685771108872776, 45.41909538449362]],
    [ "Enedis",[3.0556549034503573, 50.655994006396]],
    [ "Enedis",[0.70714435, 47.244205799999996]],
    [ "Enedis",[1.1229042130351337, 45.792704533906495]],
    [ "Enedis",[3.165333363932394, 50.707920339219406]],
    [ "Enedis",[1.1899760363526934, 48.11609136054771]],
    [ "Enedis",[1.184559071346311, 48.11695170249689]],
    [ "Enedis",[1.1933752438258713, 48.206568622199015]],
    [ "Enedis",[3.0520174419988226, 50.642439323945936]],
    [ "Enedis",[0.5763307709673757, 46.84390559550626]],
    [ "Enedis",[1.30664345, 47.605864200000006]],
    [ "Enedis",[3.662953355723023, 48.41238850695173]],
    [ "Enedis",[1.3844857511150612, 42.85977061182345]],
    [ "SNCF Réseau",[2.0973481186908987, 47.212900313398556]],
    [ "Sous-station de Sorgues",[4.866437720521383, 43.985202572056686]],
    [ "RTE",[2.7000359551950015, 47.68858928386734]],
    [ "Enedis",[2.7620289728020366, 47.63515362906411]],
    [ "RTE",[2.364714776601016, 47.73735349115066]],
    [ "Sous-station de Gripoy",[2.760280397745138, 47.95518839085047]],
    [ "Enedis",[2.7897508070599732, 47.96860498036987]],
    [ "RTE",[2.682357199999998, 47.99943884999999]],
    [ "Poste électrique des Columeaux",[2.7823687147448988, 48.10975765917254]],
    [ "Sous-station de Dordives",[2.7631453196775895, 48.12173936171956]],
    [ "Sous-station des Larris",[2.682116455753996, 48.27814592784384]],
    [ "RTE",[3.4641095706271137, 49.480660264417985]],
    [ "RTE",[3.396316151209017, 49.032900908720045]],
    [ "RTE",[3.2098209760077476, 50.65646170315116]],
    [ "Poste électrique des Asturies",[3.0711789456895504, 50.4110949270714]],
    [ "RTE",[3.096444002929672, 50.37272719926468]],
    [ "RTE",[3.000073880093619, 49.40664089225881]],
    [ "RTE",[3.087845170206773, 49.243852829632175]],
    [ "Poste électrique de Saône",[6.136097967126248, 47.23334206044279]],
    [ "Poste électrique de Boué",[3.6916370395700704, 50.01895247872366]],
    [ "RTE",[3.2428545275806124, 50.18023997226291]],
    [ "RTE",[3.2708768307438976, 50.34667411713839]],
    [ "RTE",[3.31043463063155, 50.29765384570958]],
    [ "SNCF Réseau",[3.501747482152944, 50.311889565404975]],
    [ "RTE",[2.914649456222096, 49.91091611527628]],
    [ "RTE",[2.9012987982051786, 49.76816424996985]],
    [ "RTE",[2.897727211786561, 49.7675288165235]],
    [ "RTE",[3.095710273598423, 49.737672256589875]],
    [ "SNCF Réseau",[3.29535833638714, 49.65273944846627]],
    [ "RTE",[3.33616903409811, 49.65393987415373]],
    [ "RTE",[3.284257368421498, 49.83156331460989]],
    [ "RTE",[3.458538199999999, 49.7790913]],
    [ "Sous-station SNCF de Saint-Marcel",[1.4982471560090502, 46.607253740549965]],
    [ "MERIE",[1.9283848999999997, 47.792539749999996]],
    [ "ST-CYR-EN-VAL",[1.92877783019192, 47.793758754840646]],
    [ "Poste électrique de Thimert-Gâtelles (prod. Crucey)",[1.2612578361546611, 48.57237277935455]],
    [ "Poste électrique de Val-de-Reuil",[1.1923934389682478, 49.262205779126866]],
    [ "Poste électrique de la Coulinière",[1.1889327290035607, 49.2607196550705]],
    [ "RTE",[1.0034967304181088, 49.30808132612597]],
    [ "Poste électrique de Pont-Audemer",[0.5256825775838591, 49.34703440440113]],
    [ "Poste électrique des Compas",[0.5352780125790139, 49.49246863082629]],
    [ "RTE",[0.2877464741884252, 49.47295167444388]],
    [ "RTE",[2.707778538965362, 49.032762275426556]],
    [ "RTE",[1.2245725836665413, 49.976124257230815]],
    [ "RTE",[1.3959736907285396, 50.06115829826372]],
    [ "Poste électrique de Quatre Ecluses",[2.383067176045766, 51.02896752381295]],
    [ "Poste électrique d'Usinor",[2.2856334685570943, 51.027188473711156]],
    [ "RTE",[2.2726668950614677, 51.01858594994946]],
    [ "RTE",[2.347314888110437, 51.00007542210656]],
    [ "Poste électrique des Guerlettes",[1.879454750636576, 50.9670335383333]],
    [ "RTE",[1.6001188736824483, 50.7056241814961]],
    [ "Outreau",[1.6030644387019743, 50.692300875659285]],
    [ "Poste électrique du Pont de Briques",[1.6244285621466086, 50.683384056618564]],
    [ "Poste électrique d'Avesnes-le-Comte",[2.5121338817406067, 50.272302626245356]],
    [ "RTE",[2.2200987042213347, 50.705832396651374]],
    [ "RTE",[2.355147502497213, 50.385806929581214]],
    [ "RTE",[3.1148495591804246, 50.77660993464917]],
    [ "Poste électrique d’Essars",[2.666598478421278, 50.53585017100862]],
    [ "Poste électrique de la Ferme du Roi",[2.6561371589274367, 50.54223544513522]],
    [ "Poste électrique de Vauban",[3.03805521031317, 50.63109421768978]],
    [ "Margaux",[6.115579681100482, 46.03632794793409]],
    [ "Enedis",[6.145844599999998, 45.852696349999995]],
    [ "Les Grand Pres",[6.143310175048525, 45.85200830385832]],
    [ "Résidences séniors",[-0.5749030999999999, 47.4961515]],
    [ "Parcou Bras",[-3.762995569275799, 47.807661798106444]],
    [ "RTE",[0.2699593248752734, 44.05378890503648]],
    [ "Enedis",[1.097928750949921, 47.98794677850137]],
    [ "Enedis",[1.0834245919136782, 47.98686828323657]],
    [ "Enedis",[6.949409246301248, 44.23666449816623]],
    [ "Poste électrique de Condaminé - Chatelard",[6.749400091811319, 44.46212281053254]],
    [ "EDF",[3.5582526665266507, 43.85624332415549]],
    [ "RTE",[6.0735568874585075, 44.790459699290714]],
    [ "EDF",[5.978979267534413, 44.1374158490918]],
    [ "Enedis",[5.356388141592661, 43.71153227068869]],
    [ "RTE",[4.247084293953827, 43.726770020267985]],
    [ "Laveyrune",[3.899888357032297, 44.62046472926331]],
    [ "RTE",[3.8294092856303474, 44.75012302357158]],
    [ "RTE",[3.645435638922019, 44.96815712487526]],
    [ "RTE",[4.2718519722121995, 44.68991252363987]],
    [ "RTE",[5.427143743675591, 45.061055397970186]],
    [ "RTE",[5.262484699999999, 44.981764350000006]],
    [ "EDF",[5.317559128438102, 45.12144815760184]],
    [ "RTE",[4.824320287978186, 43.95207914553815]],
    [ "RTE",[4.618872074482228, 43.804269537678486]],
    [ "RTE",[4.8199019797552065, 43.79530135907695]],
    [ "RTE",[4.5286193911453605, 43.896374327300286]],
    [ "Sous-station SNCF de Courbessac",[4.375017554466384, 43.84186750554261]],
    [ "Poste électrique de Nîmes-Talabot",[4.371815831341431, 43.83599902039375]],
    [ "Poste électrique de Codolet",[4.704968128280962, 44.13705594221865]],
    [ "Poste électrique de Marcoule",[4.705621885760626, 44.14690228358002]],
    [ "RTE",[4.753724488760743, 44.64675106040043]],
    [ "RTE",[4.764677422324849, 44.65985709060655]],
    [ "Enedis",[4.930667068531258, 44.826525960728524]],
    [ "RTE",[4.879969491260608, 44.89673014740703]],
    [ "RTE",[4.904700348129221, 44.93972539667639]],
    [ "Enedis",[5.016462802819667, 45.04433030284884]],
    [ "RTE",[6.596189881258584, 46.324956851287205]],
    [ "RTE",[4.9905929462759335, 45.20575921846654]],
    [ "CNR",[4.797649955160793, 45.306596313131955]],
    [ "RTE",[4.842950350000001, 45.70247019999999]],
    [ "RTE",[4.870746205091629, 45.73777451313949]],
    [ "RTE",[4.629530367100744, 45.881837137138746]],
    [ "RTE",[4.913072649999999, 45.52536369999999]],
    [ "RTE",[5.125744401430414, 45.64295194624299]],
    [ "Enedis",[4.989944625195804, 45.71563945611256]],
    [ "Sous-Station du Chambon-Feugerolles",[4.316016280798953, 45.393510598796986]],
    [ "590597004",[4.230083996675099, 45.73810808529052]],
    [ "RTE",[3.649883252139835, 45.68578870731744]],
    [ "RTE",[3.202950323304566, 45.67882486063614]],
    [ "RTE",[2.607129324749787, 46.35334331460912]],
    [ "RTE",[2.578634849999999, 46.391025549999995]],
    [ "RTE",[2.8351920130229806, 46.16485420502584]],
    [ "RTE",[3.0292200166103003, 47.18825385579904]],
    [ "Enedis",[6.0355117687342075, 46.115681928845774]],
    [ "RTE",[6.444258785267984, 43.539908259072895]],
    [ "RTE",[6.19894445, 43.44408465]],
    [ "Idex",[6.160743767071291, 43.39064955743148]],
    [ "Sous-station de la Marquisanne",[5.8918415100581685, 43.12868823850118]],
    [ "RTE",[5.939933423653452, 43.11469932129462]],
    [ "RTE",[6.089284111231626, 43.107129001096034]],
    [ "Sous-station de Suives",[6.175835801661855, 43.29541218498991]],
    [ "Rabatau",[5.3973251133457225, 43.277298888471265]],
    [ "RTE",[5.463063951453307, 43.45166807934967]],
    [ "RTE",[5.173168086300865, 43.50520185461685]],
    [ "RTE",[5.120105033424592, 43.393842085388656]],
    [ "590866690",[4.847508975347849, 43.42641851204359]],
    [ "590866691",[4.847758708028307, 43.426621500833036]],
    [ "RTE",[5.612695144525036, 45.307719398130104]],
    [ "EDF",[6.029939800000001, 45.43277955]],
    [ "RTE",[5.944465665886808, 45.5658985340087]],
    [ "RTE",[5.9425316172419205, 45.56446919360651]],
    [ "RTE",[6.102002421730184, 45.917759552896825]],
    [ "RTE",[5.9207889992556675, 45.90206434912188]],
    [ "RTE",[5.811442258849303, 45.8787300370121]],
    [ "RTE",[5.69892117158414, 45.725203702653225]],
    [ "Enedis",[5.772081614320255, 45.8473888153611]],
    [ "Enedis",[1.4863398900653597, 48.072548240392166]],
    [ "Enedis",[1.4562356660391789, 48.02313630294794]],
    [ "Qox",[2.1953854000000015, 48.71278269999999]],
    [ "Barrault",[2.3464976461701914, 48.82597019519724]],
    [ "Austerlitz",[2.3647095361361745, 48.84344695098143]],
    [ "Chevaleret",[2.3752911889452117, 48.829956650026176]],
    [ "Enedis",[4.0955369, 49.14986624999999]],
    [ "Enedis",[4.07825455, 49.16187064999999]],
    [ "Enedis",[3.2688087156902363, 48.197685322935065]],
    [ "Station de Pompage",[5.891742400000001, 46.62656545]],
    [ "Enedis",[4.081574939679574, 49.159159229961524]],
    [ "Enedis",[6.933610550000001, 47.55635005]],
    [ "Enedis",[6.934917099999999, 47.549993]],
    [ "Enedis",[6.927886050000001, 47.5490613]],
    [ "Enedis",[6.92386225, 47.478408349999995]],
    [ "Enedis",[6.912689650000001, 47.49745185000001]],
    [ "Enedis",[6.909985990111327, 47.49427897833854]],
    [ "Enedis",[6.9162464059350865, 47.49426292459291]],
    [ "Enedis",[6.914010300000001, 47.492858500000004]],
    [ "Enedis",[6.929600483335796, 47.484202949273595]],
    [ "Enedis",[4.707899603664635, 46.77996973472809]],
    [ "Enedis",[4.739193816451507, 46.766987004526975]],
    [ "Enedis",[4.803972135219622, 46.79180947963019]],
    [ "Enedis",[4.80952095, 46.778807400000005]],
    [ "Enedis",[4.81075845, 46.7705779]],
    [ "Enedis",[4.74595627142909, 46.789695052859685]],
    [ "Enedis",[6.876547412295546, 47.450111580936266]],
    [ "Enedis",[6.88273795, 47.44133205]],
    [ "Enedis",[6.881737905594534, 47.44106640998459]],
    [ "Enedis",[6.91640722236929, 47.436323322378826]],
    [ "Enedis",[6.913978125755773, 47.43334170276309]],
    [ "Enedis",[6.918758460633407, 47.43145088274835]],
    [ "Enedis",[6.8674502, 47.52205075]],
    [ "Enedis",[6.865172633196137, 47.52140975018814]],
    [ "Enedis",[6.861214368770761, 47.520258652352574]],
    [ "Enedis",[6.882290182027019, 47.518913448331766]],
    [ "Enedis",[6.88920375, 47.5874997]],
    [ "Enedis",[6.8774211, 47.58501715]],
    [ "Enedis",[6.876318218691156, 47.58069090192114]],
    [ "Enedis",[6.914678849999999, 47.644413150000005]],
    [ "Enedis",[6.859484881892079, 47.6449171635882]],
    [ "Enedis",[6.842642163437658, 47.645910667427884]],
    [ "Enedis",[6.849815150000001, 47.643693899999995]],
    [ "Enedis",[6.8517289328946065, 47.46788885191943]],
    [ "Enedis",[6.852955438049959, 47.46597078363143]],
    [ "Enedis",[6.846389740606734, 47.482416133277674]],
    [ "Enedis",[6.851413933792811, 47.49786205334269]],
    [ "Enedis",[6.840724842483462, 47.496246458578625]],
    [ "Enedis",[6.833735849999999, 47.50255735]],
    [ "Enedis",[6.836450237848589, 47.49642548096282]],
    [ "Enedis",[6.855914243518361, 47.50415826473764]],
    [ "Enedis",[6.854033749999998, 47.5068645]],
    [ "Enedis",[6.843977861830967, 47.619834825369445]],
    [ "Enedis",[6.809781639554319, 47.63319852943363]],
    [ "Enedis",[6.804129249999999, 47.63763995000001]],
    [ "Enedis",[6.820329787890795, 47.63439752516499]],
    [ "Enedis",[6.83223245, 47.61923730000001]],
    [ "Enedis",[4.081373847200115, 49.155675715903065]],
    [ "Enedis",[1.570298790029606, 49.82135123430949]],
    [ "Enedis",[6.9874092999999995, 47.64658814999999]],
    [ "SRD",[0.2612842000000023, 46.12117355]],
    [ "a thomas",[4.078057261447037, 49.15463587255719]],
    [ "Enedis",[4.079709800000001, 49.153815449999996]],
    [ "Gustave",[-0.6431124000000014, 44.7857925]],
    [ "Enedis",[6.939137922937865, 47.500947403356015]],
    [ "Enedis",[6.955192115150829, 47.50594487918929]],
    [ "Enedis",[6.950290212167829, 47.50210287424548]],
    [ "Enedis",[6.972396300000001, 47.49283334999999]],
    [ "Enedis",[6.982572244451807, 47.4953193342154]],
    [ "Enedis",[6.984791131045976, 47.5020280468765]],
    [ "Enedis",[6.9915647, 47.508646399999996]],
    [ "Enedis",[6.988753411994232, 47.51038206135844]],
    [ "Enedis",[7.0086088, 47.5063708]],
    [ "Enedis",[7.00353372368777, 47.502898015035136]],
    [ "Enedis",[6.851931925585603, 47.55769904118448]],
    [ "Enedis",[6.862960167353076, 47.558100902968256]],
    [ "La Croix Blanche",[-1.3693250834513762, 46.19100766432144]],
    [ "Petit Bois",[-1.3243744922457192, 46.1547535470292]],
    [ "Enedis",[6.967845819289745, 47.537501096577614]],
    [ "Enedis",[6.977368280624318, 47.54092039588977]],
    [ "Enedis",[6.973926349999999, 47.539356749999996]],
    [ "Enedis",[6.984237919337602, 47.536530146948934]],
    [ "Enedis",[7.0129562000000005, 47.5315537]],
    [ "Enedis",[6.998710117043233, 47.52746695173221]],
    [ "Enedis",[7.002289008183048, 47.52549841789948]],
    [ "Enedis",[7.013825015005593, 47.52480337858892]],
    [ "Enedis",[0.5343938500000001, 49.79495214999999]],
    [ "Enedis",[0.52924495, 49.802261699999995]],
    [ "Enedis",[5.784588511838262, 43.832380105853765]],
    [ "Enedis",[6.922774750000001, 47.65309035]],
    [ "Enedis",[6.893417838567889, 47.65184647982017]],
    [ "Enedis",[6.9039584000000005, 47.65639645]],
    [ "Enedis",[6.886595099999999, 47.66075015]],
    [ "Enedis",[6.886572159955155, 47.66543998061399]],
    [ "Enedis",[6.860153250000001, 47.658050550000006]],
    [ "Enedis",[6.8396491, 47.6627761]],
    [ "Enedis",[6.853576136460153, 47.65323082913891]],
    [ "Enedis",[6.839859714730842, 47.65551104581672]],
    [ "Enedis",[5.474524799999999, 46.76937935]],
    [ "Enedis",[3.117148307186815, 50.245345734776194]],
    [ "Enedis",[3.125239727487614, 50.250124245029376]],
    [ "Enedis",[3.1204589838177124, 50.24875675541405]],
    [ "594340597",[3.121882960106143, 50.25127626085296]],
    [ "Enedis",[3.127104627447373, 50.25108294751928]],
    [ "Enedis",[3.127607558742281, 50.251165160324845]],
    [ "Enedis",[3.118572243146819, 50.25102259821462]],
    [ "Le FOURNEAU",[4.747710299093951, 46.90045153362993]],
    [ "ROUTE DE GIVRY",[4.7505675499999995, 46.8974679]],
    [ "L'HOTEL",[4.7615188, 46.897215149999994]],
    [ "Enedis",[4.770368099999999, 46.89770655]],
    [ "Enedis",[4.761234849999999, 46.90067804999999]],
    [ "Enedis",[4.761534900000001, 46.90331545]],
    [ "SNCF Réseau",[3.308200179696462, 49.04977559178097]],
    [ "Enedis",[6.0619885257918895, 46.47883945352693]],
    [ "Enedis",[6.3867288, 48.5613076]],
    [ "Enedis",[6.404032416899334, 48.56292918189572]],
    [ "Enedis",[-0.6033963817373018, 44.65308343349554]],
    [ "Enedis",[4.744148818885289, 46.92430648375827]],
    [ "Enedis",[4.7431514, 46.922679900000006]],
    [ "Enedis",[4.739820079843615, 46.922032314391465]],
    [ "Enedis",[4.739435196120775, 46.92396623191735]],
    [ "Enedis",[5.151539249999999, 45.606679150000005]],
    [ "Poste électrique de Beaumont",[5.752882186118101, 43.734896410936784]],
    [ "Enedis",[4.826429416120767, 46.79434325567405]],
    [ "Enedis",[4.789227180563126, 46.7986317535617]],
    [ "Enedis",[4.792710203296027, 46.797593535052314]],
    [ "Enedis",[4.761247889043027, 46.79578507016298]],
    [ "Enedis",[4.77751016995493, 46.79100097549161]],
    [ "Enedis",[4.773088014411231, 46.86129518009224]],
    [ "Enedis",[4.795135115362149, 46.86231288034787]],
    [ "Enedis",[4.767740806859593, 46.90558322808146]],
    [ "Enedis",[-1.6696442015490915, 48.13707845022224]],
    [ "Enedis",[-1.6902395461857167, 48.13693604763673]],
    [ "Enedis",[1.1049791951375902, 43.012333118243106]],
    [ "Enedis",[1.1170231326693931, 43.00669023286877]],
    [ "Enedis",[1.1101663221724989, 43.00332379479617]],
    [ "Enedis",[1.1072120157115355, 43.00571205494177]],
    [ "Enedis",[1.105370438539772, 43.007818531175424]],
    [ "Enedis",[1.1239003, 43.00606935]],
    [ "Enedis",[1.1199641, 43.01083899999999]],
    [ "Enedis",[1.112676216441174, 43.01322165033785]],
    [ "Enedis",[1.1270113636695147, 43.00543298551686]],
    [ "Enedis",[1.1271882968167284, 43.003229068570036]],
    [ "Enedis",[1.1302138726680633, 43.001984226432604]],
    [ "Enedis",[1.1039663338410322, 43.01764260470091]],
    [ "Enedis",[1.0961058624826026, 43.02012977168977]],
    [ "Enedis",[1.0984824999999996, 43.01990935]],
    [ "Enedis",[1.0962204, 43.02147659999999]],
    [ "Enedis",[1.0942992021617104, 43.02132871712647]],
    [ "Enedis",[1.1003365972589059, 43.021381915973066]],
    [ "Enedis",[1.098010150000001, 43.0244586]],
    [ "Enedis",[1.09643515, 43.02416775000001]],
    [ "Enedis",[1.1065114283705395, 43.02833345353411]],
    [ "Enedis",[1.1197537734133203, 43.015948422803504]],
    [ "Enedis",[1.1189523666959678, 43.01229854966199]],
    [ "Enedis",[1.1149542499999987, 43.0010947]],
    [ "Enedis",[1.1390792464106314, 43.00054653370193]],
    [ "Enedis",[3.7364871218414915, 49.313019118986986]],
    [ "Enedis",[1.6574264711368218, 49.859825403056504]],
    [ "Enedis",[1.6614956979185826, 49.857185251812844]],
    [ "Enedis",[1.6577122178734607, 49.85777680175967]],
    [ "Barry",[5.172902250000001, 45.61799355000001]],
    [ "Enedis",[1.931214729969919, 45.593416445281875]],
    [ "Enedis",[1.5664103907187759, 49.86701900628444]],
    [ "Poste électrique de Luxé",[0.11650460120644934, 45.89744284431248]],
    [ "Enedis",[1.518039271808564, 49.68845535485073]],
    [ "Enedis",[6.185908399999999, 48.620560850000004]],
    [ "Enedis",[6.1977688, 48.61631375]],
    [ "Enedis",[6.1810193311542925, 48.61246010177507]],
    [ "Enedis",[6.169681329672299, 48.627464093855615]],
    [ "Enedis",[6.158820725666668, 48.61803060333334]],
    [ "Enedis",[6.18066895, 48.6293746]],
    [ "Enedis",[6.179075899999999, 48.61927384999999]],
    [ "Enedis",[6.1703791667104175, 48.620249769661164]],
    [ "Enedis",[6.173051351182252, 48.61660350687088]],
    [ "Enedis",[6.188810699999999, 48.60824505000001]],
    [ "Enedis",[6.212544825976469, 48.61684242540704]],
    [ "RTE",[5.9722726933385095, 45.32963267555777]],
    [ "RTE",[5.9227712877445615, 45.270193070209466]],
    [ "Enedis",[5.882432266482953, 45.265054911764786]],
    [ "Enedis",[6.2034688000000004, 48.654463600000014]],
    [ "Enedis",[6.191578850000001, 48.64555325]],
    [ "Enedis",[6.188068166829731, 48.64472604411611]],
    [ "Enedis",[6.193209899999999, 48.655334]],
    [ "Enedis",[6.193214500000002, 48.65537585]],
    [ "Enedis",[6.199437143878975, 48.656386969276085]],
    [ "Enedis",[6.195133149999999, 48.651457799999996]],
    [ "Enedis",[6.207307208468558, 48.62848098075526]],
    [ "Enedis",[6.197210588465427, 48.62434657222435]],
    [ "Enedis",[6.197947716891998, 48.65952660502845]],
    [ "Enedis",[6.191391171856138, 48.659881125079366]],
    [ "Enedis",[6.170441773491348, 48.65748926314642]],
    [ "Enedis",[6.165804199528814, 48.65765090920623]],
    [ "Enedis",[6.162658899999999, 48.648005399999995]],
    [ "Enedis",[6.117700322744398, 48.6589567185667]],
    [ "Enedis",[6.114545705341209, 48.657748882435946]],
    [ "Enedis",[5.700353247082253, 46.92992271676805]],
    [ "Enedis",[6.163358688825563, 48.676588457565636]],
    [ "Enedis",[6.168155, 48.677746299999995]],
    [ "Enedis",[6.161378267725446, 48.6737157519776]],
    [ "Enedis",[6.171742271734085, 48.67196582246238]],
    [ "Enedis",[6.169230000000001, 48.67110200000001]],
    [ "Enedis",[6.1515148, 48.66387310000001]],
    [ "Enedis",[6.138414050000001, 48.64440785]],
    [ "Enedis",[6.179452193752551, 48.67013772219995]],
    [ "Opalinska Mirecourt",[6.1917699499999985, 48.667591449999996]],
    [ "Enedis",[6.188283569878362, 48.669024435184525]],
    [ "Enedis",[6.184065900000001, 48.668704600000005]],
    [ "Enedis",[6.194092446028305, 48.666636060068896]],
    [ "Enedis",[6.171369444569647, 48.66612780894668]],
    [ "Enedis",[2.292630191799369, 47.012281575217656]],
    [ "Enedis",[2.295489380777177, 47.00932262822151]],
    [ "Enedis",[2.3155468304090125, 47.03523597787773]],
    [ "Enedis",[2.330499269026456, 47.04427245673447]],
    [ "Enedis",[2.3262334909210796, 47.047023429048636]],
    [ "Enedis",[2.2939900419926977, 47.009396217318724]],
    [ "Enedis",[2.2923534405254307, 47.014964179368604]],
    [ "Enedis",[6.142798590003267, 48.67066179701623]],
    [ "Enedis",[6.1490109522792515, 48.66968112023194]],
    [ "Enedis",[6.142719508044753, 48.67247445286806]],
    [ "Cantelot 1",[4.76982355, 45.60799755]],
    [ "Sous-station SNCF du Pertre",[-1.0368336697628926, 48.01413389478046]],
    [ "Enedis",[1.7816255323926709, 48.11709081631225]],
    [ "Enedis",[1.8907588327340386, 48.127773207615796]],
    [ "Enedis",[6.156902732549077, 48.69419130214896]],
    [ "Enedis",[6.144791455072625, 48.69201884514155]],
    [ "Enedis",[6.134057320259238, 48.692111326102996]],
    [ "Enedis",[6.125728526378143, 48.690662792089135]],
    [ "Enedis",[6.173722355381193, 48.68496168964895]],
    [ "Enedis",[6.155899869154538, 48.68083240756259]],
    [ "Beauregard",[-1.3193356500000013, 46.183292300000005]],
    [ "La Merente",[-1.2872858500000002, 46.167512500000015]],
    [ "Les Cormoans",[-1.2749443000000003, 46.157567799999995]],
    [ "La Garenne",[-1.2652388999999995, 46.15883359999999]],
    [ "CBU Les Breuils",[-1.2741362924354134, 46.154104773968896]],
    [ "Vivier",[-1.2758329459723352, 46.15134286886357]],
    [ "Malachats",[-1.276066480776538, 46.15491069739603]],
    [ "Enedis",[-1.2797134190757817, 46.15593815255469]],
    [ "Enedis",[-1.2845472767244974, 46.1584640912629]],
    [ "Château d'Eau",[-1.2902620776907527, 46.16368463671596]],
    [ "Grandes Vallees",[-1.2869864000000002, 46.1715219]],
    [ "Enedis",[1.517536328472696, 48.91451597222827]],
    [ "Enedis",[1.5329096336603947, 48.89624373903665]],
    [ "Enedis",[1.4077260500000004, 48.853563199999996]],
    [ "Enedis",[6.197850603544816, 48.6819035309641]],
    [ "Enedis",[6.1995206164069065, 48.67979724472843]],
    [ "Enedis",[1.38265225, 48.8399618]],
    [ "Enedis",[1.385689685336387, 48.84287699069213]],
    [ "Enedis",[1.381997606856648, 48.817712159578]],
    [ "Enedis",[1.4213078000000001, 48.834352599999995]],
    [ "Enedis",[1.386269303337705, 48.82392778277972]],
    [ "Enedis",[6.194272405511648, 48.68478793787877]],
    [ "Enedis",[3.4691520006537386, 43.624567883500426]],
    [ "Zephyr",[3.4705066499999995, 43.604092349999995]],
    [ "Marin",[3.4726799, 43.604121649999996]],
    [ "Muraille",[3.472461055103881, 43.606180986268335]],
    [ "Soleil",[3.4619877, 43.60901715000001]],
    [ "Auster",[3.465438332606539, 43.60618017852766]],
    [ "Muscat",[3.466095265845533, 43.609594451555175]],
    [ "Mur de Balp",[3.46643605, 43.60940015]],
    [ "La Salamane-01",[3.466991796559747, 43.60916461954317]],
    [ "Serane",[3.48982395, 43.6098509]],
    [ "Enedis",[3.495173732681572, 43.60625125086042]],
    [ "Enedis",[3.4858874, 43.60386835]],
    [ "Enedis",[3.48259085, 43.59967035]],
    [ "Enedis",[3.486533630087773, 43.598753294590736]],
    [ "Enedis",[4.067912400000001, 43.62424144999999]],
    [ "Enedis",[4.070584783649465, 43.623869361520384]],
    [ "Enedis",[4.067357892160241, 43.61447147277981]],
    [ "Enedis",[3.5133509, 43.59385514999999]],
    [ "Enedis",[3.559302850000001, 43.5861671]],
    [ "Enedis",[3.5582323, 43.580822149999996]],
    [ "Enedis",[1.570639293311774, 48.292446665383316]],
    [ "Enedis",[1.5656711155074268, 48.28843510255605]],
    [ "Enedis",[1.565864293535877, 48.25903998100917]],
    [ "ZAC VETROTEX",[5.910722020175233, 45.57803089449648]],
    [ "Enedis",[2.7668226656041277, 50.31113212293979]],
    [ "Enedis",[2.7680111574863555, 50.29841896179412]],
    [ "Poste électrique d'Izon",[-0.35694483565268637, 44.909515123301404]],
    [ "Enedis",[4.821151774150481, 45.77417525672509]],
    [ "Enedis",[3.008012827057091, 50.61725090270726]],
    [ "Enedis",[3.740371057251291, 49.226130178973]],
    [ "Enedis",[2.334921298707167, 50.36246261486373]],
    [ "Enedis",[2.3314276020434965, 50.363963340519206]],
    [ "Enedis",[1.3388233678834311, 43.622371593105]],
    [ "Enedis",[1.3754245373068716, 43.611074732693346]],
    [ "RTE",[1.3979814946941251, 43.613591087467]],
    [ "Enedis",[1.4157490287364125, 43.61318266794728]],
    [ "Enedis",[1.4116553206473441, 43.623116463223084]],
    [ "Enedis",[1.4213275683728386, 43.62209495519607]],
    [ "Enedis",[1.4312441274670307, 43.60689134830474]],
    [ "Enedis",[-1.775557381428246, 43.348756755502315]],
    [ "Enedis",[-1.779306168649266, 43.353234730054666]],
    [ "Enedis",[-1.76958775, 43.36103369999999]],
    [ "Enedis",[1.4192998763549256, 43.60937683519313]],
    [ "Enedis",[5.454639503211483, 43.454382496208666]],
    [ "Enedis",[2.9502046230062136, 50.41065510121296]],
    [ "Enedis",[-3.4801770222925796, 47.81375687893507]],
    [ "Enedis",[6.1439762, 48.70637155]],
    [ "Enedis",[6.14328968184575, 48.70543549852491]],
    [ "Enedis",[6.13776555, 48.70197294999999]],
    [ "Enedis",[6.134209968438186, 48.708037107463]],
    [ "Enedis",[6.189761457273614, 48.69540728202353]],
    [ "Enedis",[6.193434731034611, 48.686240312521875]],
    [ "Enedis",[3.507717425176954, 43.56468439742659]],
    [ "Enedis",[3.49731445, 43.5953827]],
    [ "Enedis",[3.4886159, 43.57791365]],
    [ "Enedis",[3.4866894457408053, 43.56885662639643]],
    [ "Enedis",[3.487234600000001, 43.573878900000004]],
    [ "Enedis",[1.2830162211812115, 47.63206199594239]],
    [ "Enedis",[1.2849863241942148, 47.63032541729508]],
    [ "Enedis",[1.2782311263754447, 47.632429931897654]],
    [ "Enedis",[1.288668283886925, 47.63876071741677]],
    [ "Enedis",[1.286614910884561, 47.63746540852099]],
    [ "Enedis",[1.2739467500000001, 47.643829]],
    [ "Enedis",[1.2853331162122446, 47.65121979929787]],
    [ "Enedis",[7.0126838975794765, 47.65874160337947]],
    [ "Enedis",[1.4489988569541379, 43.6446601342946]],
    [ "Enedis",[1.4422376369544339, 43.64166746225235]],
    [ "Enedis",[1.4337039252553996, 43.64299849672168]],
    [ "Enedis",[1.4324778228674468, 43.65141585890658]],
    [ "Enedis",[5.989886036711185, 47.28391856684101]],
    [ "Ste-Eulalie",[-0.4919683919078858, 44.904136162955616]],
    [ "Enedis",[1.4239407721533008, 43.64831514489058]],
    [ "Enedis",[1.4349563500000009, 43.636045800000005]],
    [ "Enedis",[1.4291446714059644, 43.636858804578644]],
    [ "Enedis",[1.422304913325466, 43.63607832948262]],
    [ "Enedis",[1.4232504313741234, 43.631307941270634]],
    [ "Enedis",[1.4238644500000004, 43.62976904999999]],
    [ "Enedis",[1.436528, 43.5836866]],
    [ "Enedis",[1.4210240999999986, 43.54839264999999]],
    [ "Enedis",[1.4306135000000002, 43.54911255000001]],
    [ "Enedis",[1.4316119881926146, 43.55647387614705]],
    [ "Enedis",[-1.7772651660434282, 48.221240833446515]],
    [ "Enedis",[-1.7969774748843013, 48.21907910332982]],
    [ "Enedis",[-1.7776385, 48.215998150000004]],
    [ "Enedis",[-1.8004044166651239, 48.22121519985981]],
    [ "Enedis",[-1.78610731676587, 48.222046999880824]],
    [ "Enedis",[-1.7865382333312085, 48.21997030009732]],
    [ "Enedis",[-1.7797585289927884, 48.22165389754839]],
    [ "Enedis",[-1.794160249999998, 48.221383550000006]],
    [ "Enedis",[-1.8020229000000005, 48.2245719]],
    [ "Mérignac",[-0.6708805826525325, 44.84957568432756]],
    [ "Enedis",[1.4606950135261625, 43.57325104038968]],
    [ "Enedis",[1.4766584427141716, 43.57505823413357]],
    [ "Enedis",[1.47388735, 43.56438149999999]],
    [ "Enedis",[1.462168354511574, 43.56418118768695]],
    [ "Enedis",[1.45526975, 43.57583139999999]],
    [ "Volvestre",[1.4623152643325454, 43.57504909211429]],
    [ "Enedis",[1.4536832, 43.55534414999999]],
    [ "Enedis",[1.4627947000000003, 43.56038169999999]],
    [ "RTE",[1.368169998112107, 43.653575902976584]],
    [ "RTE",[1.380792686979541, 43.56664405416117]],
    [ "RTE",[1.4768861245346585, 43.58075549741362]],
    [ "RTE",[1.3100153514313906, 43.59620988416002]],
    [ "SNCF Réseau",[1.33404425, 43.48709025]],
    [ "RTE",[1.3814574505434107, 43.5311945690475]],
    [ "Bessamorel Gare",[4.069346811262653, 45.12688394643225]],
    [ "Mortessagne",[4.0533749026946575, 45.14494505492031]],
    [ "RTE",[2.3182878911877833, 43.20356376057609]],
    [ "RTE",[2.1272456953548238, 43.242174415666724]],
    [ "RTE",[1.6788188131445694, 43.447199856590316]],
    [ "Poste électrique d'Escales Conilhac",[2.7185946164898476, 43.212119337913066]],
    [ "RTE",[2.5494882602410502, 43.186867159850195]],
    [ "SNCF Réseau",[2.859165207741525, 42.55086031460599]],
    [ "SNCF Réseau",[2.5897068757335506, 42.654125642061096]],
    [ "Enedis",[2.896958068765229, 42.73482839490529]],
    [ "RTE",[2.7743199364818283, 42.732178804875964]],
    [ "Enedis",[2.6954125371431434, 42.79632246392774]],
    [ "SNCF Réseau",[2.8945135517670617, 42.79269490875164]],
    [ "SNCF Réseau",[2.9986402806717667, 42.89645775819575]],
    [ "SNCF Réseau",[2.9985945971577936, 42.8958359879775]],
    [ "SNCF Réseau",[1.083586693515235, 43.21283380638145]],
    [ "RTE",[1.152173249919675, 43.23778777913096]],
    [ "SNCF Réseau",[1.251603576234123, 43.356101601710265]],
    [ "RTE",[1.1992786626954568, 43.387351764197994]],
    [ "RTE",[0.8412959794288588, 43.379148340515]],
    [ "RTE",[0.9707737210153462, 43.53277639150239]],
    [ "RTE",[2.261172836834263, 43.54664781388133]],
    [ "RTE",[2.363833268204508, 43.512032159000164]],
    [ "RTE",[2.4207753769923666, 43.508044204805444]],
    [ "RTE",[2.456909438768057, 43.51896492458918]],
    [ "RTE",[2.7888541856022933, 43.49713485444886]],
    [ "RTE",[3.2513745414992603, 43.44217823792541]],
    [ "RTE",[3.1201994202847114, 43.29947120993806]],
    [ "RTE",[2.1459029169081365, 44.05102906174162]],
    [ "RTE",[1.9901570309038183, 44.06967546354667]],
    [ "RTE",[2.2169687486476524, 43.95290878877384]],
    [ "RTE",[1.9626152614997805, 43.906520356805935]],
    [ "RTE",[1.8859310391837873, 43.90312575410257]],
    [ "RTE",[1.5010060179261284, 43.869708766548875]],
    [ "RTE",[1.6459129272622857, 43.76365565650455]],
    [ "RTE",[0.9943806157717201, 43.892327957844266]],
    [ "Sous-station SNCF de Dieupentale",[1.2812587987131194, 43.85904989127799]],
    [ "Enedis",[0.3732779306829286, 43.94611227150775]],
    [ "Enedis",[2.2413502919211266, 49.92556338375926]],
    [ "Enedis",[4.5022993499999995, 45.45485555]],
    [ "Enedis",[6.923962500000001, 47.623373099999995]],
    [ "Enedis",[6.911067918891103, 47.61173898402808]],
    [ "Enedis",[6.912817249999998, 47.60714919999999]],
    [ "Enedis",[6.91382224934266, 47.59159756671201]],
    [ "Enedis",[6.776116758292233, 47.61146367254487]],
    [ "Enedis",[6.779323513635477, 47.61539232013948]],
    [ "Enedis",[6.812764614437013, 47.59126699338417]],
    [ "Enedis",[6.81215478304426, 47.59363459964396]],
    [ "Enedis",[6.82339170466347, 47.606227132766726]],
    [ "Enedis",[6.834526049999998, 47.5664462]],
    [ "Enedis",[6.851423296513092, 47.564820331505395]],
    [ "Enedis",[6.844715786372525, 47.55663583293948]],
    [ "Enedis",[6.845575411957059, 47.55338113447008]],
    [ "Enedis",[6.8680952500000005, 47.54817305000001]],
    [ "Zone agro",[4.082550721175994, 46.05287189113957]],
    [ "Enedis",[6.817621299162586, 47.52372558358242]],
    [ "Enedis",[6.808126871139213, 47.51825320289651]],
    [ "Enedis",[6.82352678999689, 47.516835922261194]],
    [ "Enedis",[4.867080655918388, 46.764067715624776]],
    [ "Enedis",[4.8702076000000005, 46.76520124999999]],
    [ "Enedis",[4.874697274850876, 46.76855199223549]],
    [ "Enedis",[4.86808365, 46.76830209999999]],
    [ "Terre de l'Âne",[4.861089232302401, 46.77194917532631]],
    [ "Zone Commerciale Sud",[4.863604166803537, 46.77130446659229]],
    [ "Enedis",[4.86198495, 46.7755373]],
    [ "Enedis",[4.8434510500000005, 46.77582255]],
    [ "Enedis",[4.843142770771951, 46.76823531683363]],
    [ "Enedis",[4.82534395, 46.76687139999999]],
    [ "Enedis",[4.836931983675723, 46.766371696907456]],
    [ "Enedis",[5.470386399999999, 43.44177845]],
    [ "Enedis",[5.461197021430697, 43.434855393785696]],
    [ "Enedis",[3.596516665419855, 43.50075746761014]],
    [ "Enedis",[3.6022188500000007, 43.500134149999994]],
    [ "Enedis",[3.6058983563790847, 43.50129177094555]],
    [ "Enedis",[3.5125238229502846, 43.62034544650366]],
    [ "Enedis",[3.513834299999999, 43.61351]],
    [ "Enedis",[3.497026049999999, 43.6480361]],
    [ "Enedis",[3.494040356010735, 43.648941365852565]],
    [ "Enedis",[3.50054126929645, 43.64756640940653]],
    [ "Enedis",[3.5140842500000002, 43.64601335]],
    [ "Enedis",[3.5008416499999995, 43.64530195]],
    [ "Enedis",[3.4985699500000007, 43.64330675]],
    [ "Enedis",[3.5062743194211645, 43.643278870857074]],
    [ "Enedis",[3.5034213273823327, 43.64279930560601]],
    [ "Enedis",[3.5015147389842367, 43.64224685222421]],
    [ "Enedis",[3.5051186999999997, 43.64122825]],
    [ "Enedis",[3.5191478000000003, 43.64886140000001]],
    [ "Enedis",[3.521625902663859, 43.650733723810966]],
    [ "Jandos",[3.5242839499999996, 43.650725050000005]],
    [ "Poste Z.A.E.",[3.523206550000001, 43.652667050000005]],
    [ "Enedis",[3.51877835, 43.65371604999999]],
    [ "Enedis",[3.5030010682421966, 43.657589156090765]],
    [ "Enedis",[3.5077831315669448, 43.65354569849604]],
    [ "Enedis",[3.50488155, 43.6531828]],
    [ "Enedis",[3.5013015748872567, 43.653023366808405]],
    [ "Enedis",[3.4973238097470705, 43.654779165238516]],
    [ "Enedis",[3.4967965424262815, 43.653450862795815]],
    [ "Enedis",[3.5077118, 43.651087849999996]],
    [ "Enedis",[3.511730483692186, 43.64966305053599]],
    [ "Enedis",[3.513230600000001, 43.64955015]],
    [ "Enedis",[3.496699474454981, 43.65042947709822]],
    [ "Enedis",[3.5035083, 43.649316549999995]],
    [ "Enedis",[3.4786975961799294, 43.67259388506537]],
    [ "Enedis",[3.460844595752467, 43.665409830036744]],
    [ "Enedis",[3.4640644108977283, 43.66298711674843]],
    [ "Enedis",[3.4604400500000003, 43.66188055]],
    [ "Enedis",[3.505223118483066, 43.648036580718234]],
    [ "RTE",[-1.4950952776286022, 43.53433350949228]],
    [ "RTE",[-1.5021319027199034, 43.530235071183306]],
    [ "Enedis",[-1.4962792963177498, 43.5044344735472]],
    [ "RTE",[-1.5476391530325921, 43.488184877946644]],
    [ "RTE",[-0.8865386647238318, 43.04773598542964]],
    [ "RTE",[-0.6345096478390868, 43.41039296250751]],
    [ "RTE",[-0.636432533270523, 43.39382824797533]],
    [ "RTE",[-1.0198036664181154, 43.344919499091006]],
    [ "SNCF Réseau",[-1.2210750076536532, 43.51804690182467]],
    [ "RTE",[-0.9077115043626274, 43.914508728528595]],
    [ "Enedis",[-0.884474284789603, 44.01116359670402]],
    [ "RTE",[-0.34511244999999946, 44.0412093]],
    [ "RTE",[-0.8258421089528722, 43.83241322190198]],
    [ "RTE",[-0.3935001999999987, 43.77727395000001]],
    [ "Constantin",[-0.8077014803939615, 44.72551925706268]],
    [ "Gua",[5.222433542443737, 45.61340807892218]],
    [ "Lapoule",[5.224049424466178, 45.6169154166785]],
    [ "RTE",[0.06232286262775074, 43.23997068810503]],
    [ "SNCF Réseau",[0.0960288750656408, 43.2233624675229]],
    [ "Enedis",[-0.36064649991770836, 43.29232950767845]],
    [ "Sous-station Pau",[-0.3598371379351411, 43.28905607114476]],
    [ "RTE",[-0.32016387380423084, 43.31883382485002]],
    [ "ARCACHON (ENEDIS)",[-1.1634465051293739, 44.65684301228103]],
    [ "RTE",[-1.2354071633428922, 44.429678242626345]],
    [ "RTE",[-1.2497165004473911, 44.20683473846375]],
    [ "Enedis",[-0.5655965721523738, 44.759721445214296]],
    [ "Enedis",[4.836568004924623, 46.79651198134003]],
    [ "Enedis",[4.819941666330711, 46.79923205041161]],
    [ "Enedis",[4.81381315, 46.79928414999999]],
    [ "Enedis",[4.79628728376705, 46.799292180298174]],
    [ "Enedis",[4.783511900000001, 46.79488235]],
    [ "Enedis",[4.858892858125286, 46.73013194693742]],
    [ "Enedis",[4.856331382932423, 46.732867486739934]],
    [ "Enedis",[4.85450213702899, 46.73530146802959]],
    [ "Enedis",[4.8545812132091655, 46.73681806767145]],
    [ "Enedis",[4.84581304516129, 46.73383269892473]],
    [ "Enedis",[4.854345162701862, 46.733270187315675]],
    [ "Enedis",[4.852427340146703, 46.739461443670876]],
    [ "Enedis",[4.856690779767315, 46.74826087385028]],
    [ "Enedis",[4.853339998717519, 46.74872276775093]],
    [ "Enedis",[4.848538213177093, 46.747592380786365]],
    [ "Enedis",[4.849640663945184, 46.74164446726888]],
    [ "Enedis",[4.846829349999999, 46.74235650000001]],
    [ "Enedis",[4.850063042233806, 46.74381327168243]],
    [ "Enedis",[4.846959163779053, 46.74718323367598]],
    [ "Enedis",[4.845793717305153, 46.750042265573704]],
    [ "Enedis",[4.815518271467637, 46.765621257646096]],
    [ "RTE",[0.4105417885640917, 42.78548675198713]],
    [ "Électricité de France",[0.3799461162898593, 42.955250898049464]],
    [ "RTE",[0.3095718294654325, 43.13622212678374]],
    [ "SNCF Réseau",[0.57157944215226, 43.07859997487069]],
    [ "RTE",[0.65114815, 42.9177813]],
    [ "SNCF Réseau",[0.8521199694163368, 43.10518095411213]],
    [ "RTE",[0.6633027342841671, 44.17324472239111]],
    [ "SNCF Réseau",[0.6988895982976964, 44.16470438479489]],
    [ "RTE",[0.4973783374849526, 44.39211542348945]],
    [ "RTE",[0.19499021760015056, 44.486175638414906]],
    [ "MIRAIL (LE)",[-0.029904162226931152, 44.57969577641141]],
    [ "RTE",[0.12951831737663344, 44.3288872959056]],
    [ "Poste électrique de Cazalis",[-0.35038890177438553, 44.3320580417213]],
    [ "SNCF Réseau",[1.4325764568253556, 44.454311216830405]],
    [ "EDF",[2.6904491025733615, 44.122159341726096]],
    [ "Poste électrique de la Jourdanie",[2.7123829670560853, 44.017179298511465]],
    [ "RTE",[2.2552197757934724, 44.126267365503814]],
    [ "Enedis",[3.4552928999999994, 43.6472941]],
    [ "Enedis",[3.4576366500000004, 43.64795895]],
    [ "Enedis",[3.4539838499999997, 43.61609885]],
    [ "Enedis",[3.452018775650501, 43.61943369626597]],
    [ "Enedis",[3.4487415628497065, 43.6188882807634]],
    [ "Enedis",[3.4375950235537385, 43.60579490960495]],
    [ "Enedis",[3.435672455405892, 43.609982179601005]],
    [ "Enedis",[3.408609435885706, 43.62191777054385]],
    [ "Enedis",[3.3632932000000006, 43.5723671]],
    [ "Enedis",[3.3973872004634798, 43.57032458312021]],
    [ "602237598",[3.16268165, 43.81031435]],
    [ "RTE",[2.7086004240781274, 44.657703480770735]],
    [ "RTE",[2.873674530648638, 44.50510558414374]],
    [ "Enedis",[6.160210917787643, 48.701829458624665]],
    [ "Enedis",[6.1541286, 48.70802259999999]],
    [ "Enedis",[6.155033316397546, 48.70370220278091]],
    [ "Enedis",[6.152161650000002, 48.70428400000001]],
    [ "Enedis",[6.1493811681555215, 48.70909337231008]],
    [ "Enedis",[6.145080981366316, 48.71292192425539]],
    [ "Enedis",[6.147692046284629, 48.70166801831541]],
    [ "Enedis",[6.150824137498932, 48.70022733346733]],
    [ "Enedis",[6.14983496874713, 48.69993862233359]],
    [ "Enedis",[6.148841324147826, 48.69964669747333]],
    [ "Enedis",[1.4485657377791297, 49.975302029319366]],
    [ "Enedis",[-3.5087040734852746, 47.78283160833931]],
    [ "Enedis",[6.18120955037553, 48.70321438387252]],
    [ "Enedis",[6.1686007499999995, 48.70245895]],
    [ "Enedis",[6.16350132299265, 48.70376067170218]],
    [ "Enedis",[0.8602723864066202, 49.692165267789605]],
    [ "Enedis",[6.020413650000001, 48.7040005]],
    [ "Poste électrique de Saint-Orens",[1.5104585132696262, 43.55267645258201]],
    [ "Enedis",[1.5966077924958157, 42.85116290175049]],
    [ "Enedis",[6.235718816674779, 48.66651079879937]],
    [ "Enedis",[6.271230018627387, 48.65595344809545]],
    [ "Enedis",[6.265020981999159, 48.65526434842515]],
    [ "Les Sapins",[6.221266828353838, 48.65635398652607]],
    [ "Enedis",[6.2300611542317235, 48.65893346875741]],
    [ "Enedis",[6.228034350000001, 48.65563265]],
    [ "Enedis",[6.227604683637704, 48.651704979893]],
    [ "Enedis",[6.224645632415914, 48.650673155135]],
    [ "Enedis",[6.248360966901103, 48.644834464402514]],
    [ "Enedis",[6.243916242018013, 48.67761152661951]],
    [ "Enedis",[3.3704318165500142, 43.635359550282864]],
    [ "Enedis",[3.356350331393575, 43.61851072295524]],
    [ "Enedis",[3.352015633894003, 43.61778880141682]],
    [ "Enedis",[4.834740406852055, 45.775293979156146]],
    [ "Enedis",[6.1967287104903885, 48.69069663425895]],
    [ "Enedis",[6.17304835, 48.710734450000004]],
    [ "Enedis",[6.171276883311683, 48.71747394997217]],
    [ "Enedis",[6.1700567, 48.71286]],
    [ "Enedis",[6.1713674240941305, 48.70806640946198]],
    [ "Enedis",[6.172359763101962, 48.707917488856516]],
    [ "Laennec",[-1.6651464841547008, 48.108534562270066]],
    [ "Enedis",[6.212961883765775, 48.68448282514826]],
    [ "Enedis",[-3.4443073499999994, 47.746944799999994]],
    [ "Aéroport",[-3.436518587485389, 47.75257992880882]],
    [ "Enedis",[6.222145249999999, 48.69004150000001]],
    [ "Enedis",[6.240312244458689, 48.68523341956402]],
    [ "Enedis",[6.2304097, 48.6846068]],
    [ "Enedis",[6.211437753678922, 48.6892551821999]],
    [ "Enedis",[6.257145280081844, 48.709122549137916]],
    [ "Enedis",[6.256357255529078, 48.69861123265073]],
    [ "Enedis",[-1.6652634653003733, 48.10484070648393]],
    [ "Enedis",[6.232220335477381, 48.70713737343913]],
    [ "Enedis",[6.235127450000001, 48.70666965]],
    [ "Enedis",[6.260851411346279, 48.71499933042404]],
    [ "Enedis",[6.2627953986707015, 48.712248933794164]],
    [ "Enedis",[6.277993870310594, 48.7207302349774]],
    [ "Enedis",[6.2720129500000015, 48.71568874999999]],
    [ "Enedis",[-1.2095113236084447, 48.13059364376199]],
    [ "Enedis",[-1.226807971156514, 48.131372473017116]],
    [ "Enedis",[-1.2158774439369409, 48.12338109494227]],
    [ "Enedis",[6.1720989039461465, 48.721169519521816]],
    [ "Enedis",[6.18081162255125, 48.72398434931507]],
    [ "Enedis",[6.177823733125195, 48.71918594636799]],
    [ "Enedis",[6.18416225, 48.711016949999994]],
    [ "Enedis",[6.1989352, 48.709594800000005]],
    [ "Enedis",[6.20320518964618, 48.709627520756044]],
    [ "Enedis",[6.206645981185443, 48.71606189803505]],
    [ "Enedis",[6.2202559422055606, 48.71776218306779]],
    [ "Enedis",[6.207589913137197, 48.70141547280113]],
    [ "Enedis",[6.214663707057942, 48.69787680373491]],
    [ "Enedis",[6.223123356842011, 48.704919278748044]],
    [ "Enedis",[6.22375205, 48.70385319999999]],
    [ "Enedis",[6.2268, 48.7010447]],
    [ "Enedis",[6.2254495499999996, 48.69659104999999]],
    [ "Enedis",[6.183867520669298, 48.6232863526287]],
    [ "Enedis",[6.1506812121834376, 48.67273179123888]],
    [ "Électricité de France",[0.01065877348792473, 42.821229445081954]],
    [ "SNCF Réseau",[0.3927584961489847, 43.11393497616109]],
    [ "Enedis",[0.5884702170673596, 43.62143460417726]],
    [ "Poste de Jalis",[0.5872881055744283, 43.622162247821514]],
    [ "E Jordan",[-1.659595067567394, 48.111089325273895]],
    [ "Intermarché",[4.919022916145951, 45.51900805102751]],
    [ "JM Huchet",[-1.6338839194882262, 48.10872890759793]],
    [ "Enedis",[3.4251762369804553, 43.63315703268648]],
    [ "Enedis",[3.418853099999999, 43.635904700000005]],
    [ "Enedis",[3.4170545746357046, 43.62331530998936]],
    [ "Le Mas de Julien",[3.4137454792202795, 43.62590585938503]],
    [ "Lauze",[3.40857342017951, 43.6241542527424]],
    [ "Roncelay",[-1.622621345645878, 48.114517395817366]],
    [ "La Bascule",[1.2406642682683031, 44.12922269447279]],
    [ "Slav",[5.156103599999999, 45.6133967]],
    [ "Parc des Musiciens",[5.162852144018625, 45.61874146919239]],
    [ "605360946",[5.17961805, 45.61878585]],
    [ "Sables",[5.181230082860999, 45.61814710080972]],
    [ "Enedis",[0.784842186582152, 45.94909935609347]],
    [ "Enedis",[4.00400585, 45.7457275]],
    [ "Enedis",[5.981745900000001, 47.26758405000001]],
    [ "Les Chênes",[5.17864135, 45.6012327]],
    [ "Loissat",[5.177057429126386, 45.59297447012758]],
    [ "Bionne",[5.1788374, 45.5947725]],
    [ "Kardelen",[5.18047665, 45.59770004999999]],
    [ "Les Ayes",[5.181738149999999, 45.6006139]],
    [ "Les Hauts de Monbaly",[5.1873211, 45.6087306]],
    [ "Enedis",[2.6695796495028006, 48.00430651676]],
    [ "Enedis",[0.5059650066539294, 49.7075366602703]],
    [ "Enedis",[0.5054622152922648, 49.70433006356708]],
    [ "FT Dolivet",[2.2892683948612995, 48.79130283111926]],
    [ "Enedis",[2.6979595936374157, 48.04574501708602]],
    [ "Enedis",[1.96922026530963, 49.145356372222594]],
    [ "Enedis",[1.96847955, 49.1483229]],
    [ "Enedis",[5.265773400118731, 48.532575526610806]],
    [ "Enedis",[1.889145200000001, 45.7344396]],
    [ "Enedis",[1.1275101932418035, 45.78695773462226]],
    [ "Enedis",[1.4579681712150747, 43.647988890448346]],
    [ "Enedis",[1.4725378668474314, 43.628806482634204]],
    [ "Enedis",[1.4725201580375673, 43.632400384321436]],
    [ "Enedis",[1.4746922544524799, 43.639363433269835]],
    [ "Enedis",[1.4723615142609214, 43.640077284688715]],
    [ "Enedis",[1.4681953000000003, 43.64407800000001]],
    [ "Enedis",[1.4667417670626883, 43.64041847803815]],
    [ "Enedis",[1.4570464, 43.6437725]],
    [ "Vallon",[4.530548587003209, 45.682968560291314]],
    [ "Duerne",[4.530113683554591, 45.68517489332823]],
    [ "Enedis",[-1.2124853, 48.1218579]],
    [ "Enedis",[-1.1946130929032257, 48.12129500774193]],
    [ "Enedis",[-1.1969530608102157, 48.12732645332453]],
    [ "Enedis",[-1.1943303785195132, 48.12815044077128]],
    [ "Enedis",[1.1509890484654202, 45.79873103347219]],
    [ "Enedis",[4.779155950000001, 43.92148424999999]],
    [ "Enedis",[4.787237268917016, 43.93060497341587]],
    [ "Enedis",[1.4902755260253602, 43.557005410451]],
    [ "Enedis",[1.4902591500000004, 43.557045699999996]],
    [ "Enedis",[1.492739717978167, 43.557248804181214]],
    [ "Enedis",[1.2260326722754762, 49.70943432954191]],
    [ "Enedis",[-0.3957682284349599, 43.32082071944242]],
    [ "Enedis",[2.1760909500000003, 46.11176230000002]],
    [ "Enedis",[3.8622876000000006, 49.25329829999999]],
    [ "Correard",[5.603775208252294, 44.83127288070887]],
    [ "Enedis",[1.1140495723978494, 45.79956984715571]],
    [ "Enedis",[1.1172937013301099, 45.79878546608102]],
    [ "Pierre Martin",[-1.6633749074939528, 48.10327902539814]],
    [ "Coutanceau",[-1.6666419201275566, 48.097101315175394]],
    [ "Père Maunoir",[-1.6631658414969526, 48.100377807341395]],
    [ "Enedis",[1.1181304000000003, 45.80031565]],
    [ "Enedis",[1.4839644249890782, 49.572467445915244]],
    [ "Poste ERDF Ruelle",[4.749753275087489, 45.62073740888014]],
    [ "Enedis",[2.3015651598683267, 49.00857733279296]],
    [ "Enedis",[1.4900907242678763, 43.58200265023159]],
    [ "Enedis",[1.4857913033058834, 43.58274806642389]],
    [ "Enedis",[1.4787035, 43.590178599999994]],
    [ "Enedis",[1.4796607000000004, 43.5908751]],
    [ "Enedis",[1.483244339255076, 43.591235494195764]],
    [ "Enedis",[1.495549016745469, 43.58002235628844]],
    [ "Enedis",[1.5014836658308985, 43.57517625195182]],
    [ "Enedis",[1.4996751000000004, 43.55871115]],
    [ "Enedis",[1.4957676500000003, 43.559203800000006]],
    [ "Enedis",[1.47955275, 43.564278949999995]],
    [ "Enedis",[1.4874787048827338, 43.568792867401854]],
    [ "Enedis",[1.4858673187336804, 43.57348301007486]],
    [ "Enedis",[1.4890394844624886, 43.57489822836105]],
    [ "Enedis",[1.4830946957003008, 43.57767948269282]],
    [ "Enedis",[1.4786939767300717, 43.5789755169803]],
    [ "Enedis",[1.4837211119755132, 43.55105745983812]],
    [ "Enedis",[1.482203322787712, 43.554091610820734]],
    [ "Enedis",[1.4826162500000002, 43.5544203]],
    [ "Enedis",[1.484810733742805, 43.554995197313865]],
    [ "Enedis",[1.44583696592404, 43.5671550017823]],
    [ "Enedis",[1.4485206500000005, 43.562819600000005]],
    [ "Enedis",[1.453012663287332, 43.55502234739725]],
    [ "Enedis",[1.4129827500000003, 43.554082050000005]],
    [ "Enedis",[1.42075235, 43.55054889999999]],
    [ "Enedis",[1.4254736244236792, 43.562771283236074]],
    [ "Enedis",[1.4241733716835805, 43.579582649368234]],
    [ "Enedis",[1.4242963000000002, 43.5744688]],
    [ "Enedis",[1.420906883023544, 43.575191461627426]],
    [ "Enedis",[1.4217866961767784, 43.57022828084734]],
    [ "Enedis",[1.4191419500000002, 43.5711223]],
    [ "Enedis",[4.821785006901147, 45.7722166388276]],
    [ "Enedis",[0.7417738829872379, 49.70127188579519]],
    [ "Enedis",[5.506532900000001, 47.0972097]],
    [ "Enedis",[4.8299915991489515, 45.77716574702859]],
    [ "Enedis",[4.826354880175447, 45.77934605979414]],
    [ "Enedis",[4.820595632803487, 45.78025017492331]],
    [ "Enedis",[4.819015655024207, 45.77789107587955]],
    [ "Enedis",[4.8173455, 45.779492000000005]],
    [ "Enedis",[4.81897262529447, 45.77263741206204]],
    [ "Enedis",[4.822553257174295, 45.77183428381928]],
    [ "Enedis",[1.4014813000000006, 43.540479649999995]],
    [ "Enedis",[1.406806849743777, 43.54702871656904]],
    [ "Enedis",[1.405178082864258, 43.548712555386274]],
    [ "Enedis",[1.4152183674438998, 43.54717034339352]],
    [ "Enedis",[1.3977363170477617, 43.55521699951073]],
    [ "Enedis",[1.3878635841032312, 43.5522495044242]],
    [ "Enedis",[1.38708625, 43.54497825]],
    [ "Enedis",[1.4073201414382608, 43.554970278972355]],
    [ "Enedis",[1.464382878130125, 43.582013145897214]],
    [ "Enedis",[1.4723553998910837, 43.57955018235971]],
    [ "Enedis",[6.025214456803892, 48.93293117673933]],
    [ "Enedis",[6.037864374774422, 48.930772705034656]],
    [ "Enedis",[6.0335961276693135, 48.93421484729145]],
    [ "Poste Enedis Content",[6.338375386474673, 43.13381857268167]],
    [ "Enedis",[1.3818497834231585, 43.574638900088424]],
    [ "Enedis",[1.3805454327734288, 43.57608764262792]],
    [ "Enedis",[1.38223045, 43.57230345000001]],
    [ "Enedis",[1.3832677330593806, 43.57134229443876]],
    [ "Enedis",[1.3969508251525649, 43.56735494025693]],
    [ "Enedis",[1.385149, 43.567544850000004]],
    [ "Enedis",[1.3871859304994312, 43.56451650253242]],
    [ "Enedis",[1.378958674496006, 43.551044017773755]],
    [ "Enedis",[1.384869366143827, 43.55693544513996]],
    [ "Enedis",[1.3823032500000003, 43.55916075000001]],
    [ "Enedis",[1.3823274999999997, 43.55758845]],
    [ "Enedis",[1.3805814751312522, 43.55681374250079]],
    [ "Enedis",[1.374412497655397, 43.55285406970342]],
    [ "Enedis",[1.3778306384649406, 43.56561528335374]],
    [ "Enedis",[1.3666213838634569, 43.55481294897385]],
    [ "Enedis",[1.3626629908846617, 43.5554101332045]],
    [ "Enedis",[1.3672317994514598, 43.55931885895704]],
    [ "Enedis",[1.363185103218058, 43.55940008451924]],
    [ "Enedis",[1.3688244662925335, 43.56595745050669]],
    [ "Enedis",[1.3698839499999989, 43.57242925]],
    [ "Enedis",[2.9167950241313867, 42.57898050876898]],
    [ "Enedis",[2.91675109432783, 42.57900162047668]],
    [ "Enedis",[2.9194846157856023, 42.57696448325141]],
    [ "Enedis",[0.7588249464503993, 46.738876527216576]],
    [ "Enedis",[2.02151531810784, 49.11054901452249]],
    [ "Enedis",[2.0149588081063743, 49.11122990540912]],
    [ "Enedis",[0.5956427, 46.932889599999996]],
    [ "Enedis",[0.4083629029845396, 47.12004919228427]],
    [ "Enedis",[0.41307808401597174, 47.11883489680798]],
    [ "Enedis",[0.4183028904855583, 47.11863420505828]],
    [ "Enedis",[0.4206026713700433, 47.11860674363785]],
    [ "Enedis",[0.4257539000000004, 47.1200869]],
    [ "Enedis",[1.5858526000000004, 43.968249699999994]],
    [ "Enedis",[1.5887563999999996, 43.971812899999996]],
    [ "Enedis",[1.5952563000000002, 43.97332]],
    [ "Enedis",[1.60673335, 43.97257019999999]],
    [ "Enedis",[1.5333539460089658, 43.96394108293678]],
    [ "Enedis",[1.417051121508612, 43.5915082091486]],
    [ "Enedis",[1.4151181179607513, 43.58235008671809]],
    [ "Enedis",[1.4109893605997752, 43.57670951001453]],
    [ "Enedis",[1.4122466221744003, 43.571171803526646]],
    [ "Enedis",[1.4137034499999999, 43.5699327]],
    [ "Enedis",[1.3792759097566734, 43.581492370863074]],
    [ "Enedis",[1.4041692999999997, 43.57723615000001]],
    [ "Enedis",[1.4003072161646752, 43.57528155700986]],
    [ "Enedis",[1.385097985552786, 43.58016829226667]],
    [ "Enedis",[1.3936681984278496, 43.577468415297226]],
    [ "Enedis",[1.3941414707306399, 43.58314385810783]],
    [ "Enedis",[1.3951624854292564, 43.582986310935254]],
    [ "Enedis",[1.397261042400049, 43.582781781760275]],
    [ "Enedis",[1.3975245754632961, 43.583690285220186]],
    [ "Pampa",[2.0575410279805166, 49.050721159982814]],
    [ "Enedis",[6.108350949999999, 48.62352305]],
    [ "Enedis",[6.118040109919457, 48.6258362831306]],
    [ "Enedis",[6.165084649999997, 48.7441246]],
    [ "Enedis",[6.152197565709392, 48.73077854757322]],
    [ "Enedis",[6.153074941275207, 48.728415346399856]],
    [ "Enedis",[6.145891699999999, 48.7246502]],
    [ "Enedis",[6.147415074879309, 48.611053846287206]],
    [ "Enedis",[6.2554098217071346, 48.60872945465903]],
    [ "Enedis",[6.268873349999999, 48.61682874999999]],
    [ "Enedis",[6.264326999999999, 48.61652175]],
    [ "Enedis",[6.236416498712511, 48.61143036631435]],
    [ "Enedis",[1.881552595130058, 47.960427634568205]],
    [ "Enedis",[1.8827838499999996, 47.95963]],
    [ "Enedis",[1.8845493163819431, 47.9598773601568]],
    [ "Enedis",[-0.6130876712726985, 43.16951124190062]],
    [ "Enedis",[-1.4755538311043963, 43.52823771341671]],
    [ "Enedis",[1.4148361653659087, 43.60459549745504]],
    [ "Enedis",[1.412055953374245, 43.601156867048935]],
    [ "VEDEL",[1.3955544999999996, 43.6058084]],
    [ "Enedis",[1.3847615081123823, 43.602808822104]],
    [ "SAINT MARTIN",[1.377528480961618, 43.60832479987194]],
    [ "P2414 CLOS",[1.3688285, 43.6064048]],
    [ "Enedis",[1.3670929531026037, 43.60269416854559]],
    [ "Enedis",[1.3830871219450234, 43.594781696739446]],
    [ "Enedis",[1.417222057922773, 43.63492172211224]],
    [ "Enedis",[1.4146822355170052, 43.62629461846124]],
    [ "Enedis",[1.4102129190611357, 43.63846360725183]],
    [ "Enedis",[1.3904719992792736, 43.636714995961256]],
    [ "Enedis",[1.3900615, 43.64187925]],
    [ "Enedis",[1.3753617992971818, 43.63878621705137]],
    [ "Enedis",[1.369758669382152, 43.64813200214222]],
    [ "Enedis",[1.3600267500000016, 43.64572865]],
    [ "Enedis",[1.3693494, 43.637489900000006]],
    [ "Enedis",[1.3779464000000001, 43.625952749999996]],
    [ "Enedis",[1.8421116335405476, 45.958076749387]],
    [ "Enedis",[6.1089528, 48.62557434999999]],
    [ "Enedis",[6.10209055, 48.6071659]],
    [ "Enedis",[6.092767792940405, 48.60559886446341]],
    [ "Enedis",[6.07829755, 48.623545199999995]],
    [ "Enedis",[6.081313800000001, 48.620631350000004]],
    [ "Enedis",[6.092115477729433, 48.61930800474978]],
    [ "Enedis",[6.087008967104243, 48.61773654939182]],
    [ "Enedis",[6.092535718946889, 48.61722677535377]],
    [ "Enedis",[6.097865200000001, 48.61052254999999]],
    [ "Enedis",[6.1108222613448575, 48.617627372789286]],
    [ "Enedis",[6.112984284925147, 48.614076332100446]],
    [ "Enedis",[6.118377777207859, 48.612114853146906]],
    [ "Enedis",[6.1649154800901735, 48.606873464519175]],
    [ "Enedis",[6.138564682391983, 48.60801040110177]],
    [ "Enedis",[6.164772232909948, 48.73140215468978]],
    [ "Enedis",[6.155063349999999, 48.7167124]],
    [ "Enedis",[6.104427449999999, 48.69510715]],
    [ "Enedis",[6.101463536945334, 48.60551268464459]],
    [ "Enedis",[6.169954483988618, 48.69190043891452]],
    [ "Enedis",[-1.2119027320033802, 48.10898587093405]],
    [ "Enedis",[-1.2022175269109, 48.10877935260063]],
    [ "Enedis",[-1.1928746306502758, 48.1077163357676]],
    [ "Enedis",[-1.1757135225930675, 48.12315307586649]],
    [ "Enedis",[-1.1718443939372731, 48.12355942556384]],
    [ "Enedis",[-1.1666833193917088, 48.1221385296091]],
    [ "Enedis",[1.9250260982975864, 47.95368241072403]],
    [ "Enedis",[6.317266672716777, 48.63790911713574]],
    [ "Enedis",[6.307303431689955, 48.64073910149876]],
    [ "Enedis",[6.2893657773129785, 48.632614558760146]],
    [ "Enedis",[1.921608663172689, 47.95237338688194]],
    [ "Enedis",[1.9235172671834537, 47.927888725987444]],
    [ "Enedis",[1.9157480343739834, 47.928462404491675]],
    [ "Enedis",[6.361221049999998, 48.603034199999996]],
    [ "Enedis",[6.3542012801894705, 48.60448439635215]],
    [ "Enedis",[6.354944375091617, 48.60923809554131]],
    [ "Enedis",[6.364271123071286, 48.613001646196736]],
    [ "Enedis",[1.919528059793825, 47.9408295809388]],
    [ "Enedis",[1.9247800896652472, 47.92692645376997]],
    [ "Enedis",[1.91909761998988, 47.922517294956414]],
    [ "Enedis",[1.9231639364203517, 47.9223383379726]],
    [ "Enedis",[1.911537864675742, 47.92928620992186]],
    [ "Enedis",[1.910750091946147, 47.930477820150955]],
    [ "Enedis",[1.9034537279015356, 47.93603158260604]],
    [ "Enedis",[1.9062492292183737, 47.93775082044637]],
    [ "Enedis",[1.9109085060666495, 47.93974796028517]],
    [ "Enedis",[1.8994279097915654, 47.95034000220491]],
    [ "Enedis",[1.914230217262672, 47.9236278586133]],
    [ "Enedis",[1.9132170321878887, 47.928234174070475]],
    [ "Enedis",[1.9146555471883904, 47.92933697792251]],
    [ "Enedis",[1.9107536504468874, 47.93635533073548]],
    [ "Enedis",[1.9120702259534825, 47.93696820428464]],
    [ "Enedis",[6.334394491512204, 48.58921517881089]],
    [ "Enedis",[6.335985728785629, 48.59789091513502]],
    [ "Enedis",[6.0527986174611295, 48.6973904721083]],
    [ "Enedis",[6.05581045, 48.70646535]],
    [ "Enedis",[6.057862699999999, 48.70581325]],
    [ "Enedis",[1.930660831320483, 47.92789854341044]],
    [ "Enedis",[1.9337644529082856, 47.92738661737676]],
    [ "Enedis",[1.9292935378816993, 47.9321466027649]],
    [ "Enedis",[1.9192317692727268, 47.93528857165381]],
    [ "Enedis",[6.221040552546378, 48.749041481944936]],
    [ "Enedis",[6.232997414702266, 48.7486004742198]],
    [ "Enedis",[6.240466516916742, 48.741656420220835]],
    [ "Enedis",[6.2389451, 48.7331703]],
    [ "Enedis",[6.237282899999999, 48.731683399999994]],
    [ "Enedis",[1.943606311547688, 47.95950593865071]],
    [ "Enedis",[1.934964031505146, 47.92480937964081]],
    [ "Enedis",[1.9265357575381397, 47.9229690033144]],
    [ "Enedis",[1.9496619485853532, 47.926892351630975]],
    [ "Enedis",[1.9509173940886027, 47.93167489560822]],
    [ "Enedis",[6.1918102, 48.74799395]],
    [ "Enedis",[1.9129927238410593, 47.95375435298012]],
    [ "Enedis",[1.9297157231309936, 47.94384639492552]],
    [ "Enedis",[1.926188197889932, 47.94291631378785]],
    [ "Enedis",[1.930607303271702, 47.942353849507455]],
    [ "Enedis",[1.9380791759053957, 47.94256230539751]],
    [ "Enedis",[1.9444028080196614, 47.94316224693917]],
    [ "Enedis",[1.9476508664559096, 47.943923565732426]],
    [ "Enedis",[1.9515868466836366, 47.94084250052326]],
    [ "Enedis",[1.935003883348106, 47.934836765308376]],
    [ "Enedis",[1.9349578004648391, 47.93369620036708]],
    [ "Enedis",[1.92086915, 47.944897850000004]],
    [ "Enedis",[1.9063061472026823, 47.945164750505484]],
    [ "Enedis",[1.7097587000000016, 48.272531150000006]],
    [ "Enedis",[-1.3759242579611397, 48.09636251050737]],
    [ "Enedis",[-1.3660412508501463, 48.09443805125582]],
    [ "Enedis",[-1.370465598824794, 48.09199322842191]],
    [ "Enedis",[-1.3599243225837554, 48.118294255603764]],
    [ "Enedis",[-1.359721420827647, 48.12023449272806]],
    [ "Enedis",[-1.3888367074246428, 48.11404720469839]],
    [ "Enedis",[-1.40960825, 48.10836815]],
    [ "Enedis",[-1.404184376735737, 48.10654600905199]],
    [ "Enedis",[-1.4134774673134771, 48.101774459025656]],
    [ "Enedis",[-1.4088746422750518, 48.10151001393245]],
    [ "Enedis",[-1.4055205968077207, 48.10239233214551]],
    [ "Enedis",[-1.403293780838677, 48.10167484632051]],
    [ "Enedis",[-1.40317635, 48.10275755]],
    [ "Enedis",[1.9447278631834253, 47.93906304695654]],
    [ "Enedis",[1.951947554024929, 47.93443361542222]],
    [ "Enedis",[1.968740703325766, 47.93004559978885]],
    [ "Enedis",[1.972641978332448, 47.92779329545053]],
    [ "Enedis",[6.162015046952278, 48.75242555972246]],
    [ "Enedis",[6.146564567306058, 48.74859200072033]],
    [ "Enedis",[6.1491542500000005, 48.74539155]],
    [ "Enedis",[6.1507632999999995, 48.74164265000001]],
    [ "Enedis",[6.158036114287732, 48.74486271780319]],
    [ "Enedis",[1.9810402758261496, 47.92961080851293]],
    [ "Enedis",[1.9580433026966528, 47.92508079726265]],
    [ "Enedis",[1.9534925703433745, 47.924695782942074]],
    [ "Enedis",[1.9479186526174532, 47.92455659346867]],
    [ "Enedis",[1.9517092869526074, 47.921886536188346]],
    [ "Enedis",[1.9479204139644775, 47.92200825541795]],
    [ "Enedis",[1.9458178767613032, 47.92259057497372]],
    [ "Enedis",[1.9419964734324695, 47.922513255537034]],
    [ "Enedis",[1.9383735810556304, 47.92409779331996]],
    [ "Enedis",[6.134136650000001, 48.7727619]],
    [ "Enedis",[6.134333042417169, 48.77753847731936]],
    [ "Enedis",[6.139980956042522, 48.77571247128095]],
    [ "Enedis",[6.127388436614212, 48.76785455272403]],
    [ "Enedis",[1.5397266791495128, 49.614605337216254]],
    [ "Enedis",[1.5488380575453775, 49.61374538328113]],
    [ "Enedis",[1.535381589999161, 49.6176651731052]],
    [ "Enedis",[1.5341157499999998, 49.616624699999996]],
    [ "Enedis",[1.5453834187122737, 49.614849545808184]],
    [ "Enedis",[1.533163795261732, 49.60763502747188]],
    [ "Enedis",[1.5381027181270144, 49.61656421197908]],
    [ "Enedis",[2.294174178419614, 48.819295108562095]],
    [ "Enedis",[1.5972525554812982, 49.662856848072195]],
    [ "Enedis",[1.6295514500000006, 49.645016049999995]],
    [ "Enedis",[1.6154755903939064, 49.65266397457685]],
    [ "Enedis",[1.6076849403660187, 49.65762610042448]],
    [ "Enedis",[1.41504975, 43.6466346]],
    [ "Enedis",[1.4435439675441926, 43.65183224733018]],
    [ "Enedis",[1.4430618644778712, 43.65789506970398]],
    [ "Enedis",[1.4434277276245742, 43.664448387608445]],
    [ "Enedis",[1.4429049500000002, 43.648111150000005]],
    [ "Enedis",[1.4386751092129424, 43.648516875521324]],
    [ "Enedis",[1.4384589973674025, 43.65885842694541]],
    [ "Enedis",[1.43698415, 43.65922054999999]],
    [ "Enedis",[1.4264314678350032, 43.664285501645544]],
    [ "Enedis",[1.425866753269669, 43.66775509004113]],
    [ "Enedis",[1.400734866546848, 43.65012286266459]],
    [ "Enedis",[1.4167854500000001, 43.6415764]],
    [ "Enedis",[1.9827661897807682, 47.924094785694635]],
    [ "Enedis",[-0.39369514999999866, 43.31999465]],
    [ "Enedis",[1.3362307500000012, 47.58715375]],
    [ "Enedis",[-1.6702826499999999, 46.54027764999999]],
    [ "Enedis",[-1.7874349180988292, 46.50460616995359]],
    [ "Enedis",[-1.789009791353909, 46.50731354758239]],
    [ "Atterrage BI DP Port Jean",[-3.191143553816762, 47.36295018294033]],
    [ "Enedis",[-1.786615753795843, 46.49503203484813]],
    [ "Enedis",[6.050709035486816, 48.76865667617169]],
    [ "Gendarmerie",[6.060467491254137, 48.7613149321439]],
    [ "Enedis",[6.0606944330460895, 48.74958780040381]],
    [ "Enedis",[6.153745574394344, 48.60211158387469]],
    [ "Enedis",[6.09919523018147, 48.603871340019104]],
    [ "Enedis",[1.4939552818601651, 43.61550714848064]],
    [ "Enedis",[1.5017338633293789, 43.587402878179745]],
    [ "Enedis",[1.456533983223892, 43.610150426625836]],
    [ "Enedis",[1.4703071277682627, 43.60500641801001]],
    [ "Caneton 117 18",[-0.12054346546821817, 49.29275134679826]],
    [ "Enedis",[-1.769480952714173, 46.49191378712756]],
    [ "Enedis",[-1.765459985640019, 46.49100557804921]],
    [ "Enedis",[-1.808790423897512, 46.49204527176644]],
    [ "Enedis",[-1.7975958662759621, 46.50144786813077]],
    [ "Enedis",[-1.7930220056971031, 46.49952354692851]],
    [ "Enedis",[-1.80314815498048, 46.499741871443796]],
    [ "Enedis",[-1.8027406987634678, 46.502360214240056]],
    [ "Enedis",[-1.783224681594256, 46.50922807503275]],
    [ "Enedis",[-1.781199216970777, 46.51245383976191]],
    [ "Enedis",[-1.7909088201338779, 46.5125329707928]],
    [ "Palestine",[-1.662450612154344, 48.115110838973706]],
    [ "Bellevue",[-1.653496877568128, 48.116890605965445]],
    [ "Michelet",[-1.648896850846639, 48.1175733901402]],
    [ "La Lambouet",[-1.6430496738093687, 48.117194831574885]],
    [ "Enedis",[-1.7921246955954324, 46.51423382097153]],
    [ "Enedis",[1.4942275999999999, 43.622994000000006]],
    [ "Enedis",[1.4858840953902839, 43.626108077829045]],
    [ "Enedis",[1.4870197677943129, 43.62679922451274]],
    [ "Enedis",[1.4839376414734187, 43.62588463342799]],
    [ "Enedis",[1.5135437753439023, 45.903546468235064]],
    [ "Enedis",[1.4978179798509454, 45.906291505974906]],
    [ "Enedis",[1.5066101708218815, 45.88717048703804]],
    [ "Enedis",[-3.1528578592549703, 47.342578829500525]],
    [ "Enedis",[-3.1600012537210325, 47.34899132514336]],
    [ "Enedis",[-3.242823038551425, 47.37958205093179]],
    [ "Poste 13 - Port Maria",[-3.0813712245404488, 47.29374461400842]],
    [ "Enedis",[6.95588735, 45.219087349999995]],
    [ "Enedis",[6.671309050000001, 45.2019231]],
    [ "Enedis",[6.66512217196948, 45.20403573103547]],
    [ "Enedis",[6.667038712185073, 45.19621436688349]],
    [ "Poste source de Val d'Isère",[6.969138438646041, 45.45394895362788]],
    [ "Enedis",[7.0566243, 45.375599750000006]],
    [ "Enedis",[-1.7607033999999988, 46.53927005]],
    [ "Chemin de la Toviere",[6.97054565, 45.4534406]],
    [ "Les Coves",[6.969828123986902, 45.45324617959478]],
    [ "G TPH de Solaise",[6.992430166000645, 45.43484485171301]],
    [ "STVI Col de Iseran",[7.033358166312148, 45.42103451875492]],
    [ "Le Saint-Charles",[6.884396435959941, 45.284998810476885]],
    [ "Enedis",[6.816456167299302, 45.27484400773761]],
    [ "Enedis",[6.807700800000001, 45.26284075000002]],
    [ "Enedis",[6.7886871, 45.2340362]],
    [ "Enedis",[6.539949579379266, 45.75738924188216]],
    [ "Poste électrique de Argia",[-1.4295588304375015, 43.42257169184225]],
    [ "Enedis",[1.4647010499999995, 43.63582315]],
    [ "Enedis",[1.4688691879268532, 43.63531834262031]],
    [ "Enedis",[1.49067915, 43.64367155]],
    [ "Enedis",[1.48947735, 43.64222115]],
    [ "Enedis",[1.48258175, 43.64287165]],
    [ "Enedis",[1.4740338165525912, 43.64213189938878]],
    [ "Enedis",[1.484514325483154, 43.641506638724564]],
    [ "Enedis",[1.4749231586337321, 43.63804488433805]],
    [ "Enedis",[1.495086500000001, 43.63759844999999]],
    [ "Enedis",[1.4974770999999998, 43.6375082]],
    [ "Enedis",[1.4955146499999998, 43.63588515]],
    [ "Enedis",[1.4839111999999994, 43.63943105]],
    [ "Enedis",[1.4824078391845517, 43.636852266553824]],
    [ "Enedis",[1.4887770317927147, 43.63524535202285]],
    [ "Enedis",[1.4863721797846268, 43.633157140346526]],
    [ "Enedis",[1.4858893057226432, 43.633300779967946]],
    [ "Enedis",[6.4551681747452845, 48.556407055007924]],
    [ "Enedis",[1.432649828595243, 43.631978641688804]],
    [ "Enedis",[1.4351600849093835, 43.63718391502766]],
    [ "Enedis",[1.4364304796322505, 43.63924360260276]],
    [ "Enedis",[1.4331650174714545, 43.64420765155098]],
    [ "Enedis",[1.4287895411515104, 43.639959906933505]],
    [ "Enedis",[1.47311195, 43.644849]],
    [ "Enedis",[1.4577770949391495, 43.62186771488078]],
    [ "Enedis",[1.4749404983786516, 43.626756619440926]],
    [ "Enedis",[1.47321383064183, 43.62786078004925]],
    [ "Enedis",[1.468495949999999, 43.629271900000006]],
    [ "Enedis",[1.4652862500000001, 43.628556950000004]],
    [ "Enedis",[1.4575062000000003, 43.63360655]],
    [ "Enedis",[1.4396312779407732, 43.63649074231897]],
    [ "Enedis",[6.549662749999999, 46.073586299999995]],
    [ "Enedis",[6.554912257682277, 46.07371302149137]],
    [ "Enedis",[1.6419813500000007, 49.80337885]],
    [ "Enedis",[-1.7856455224537215, 46.50279597260734]],
    [ "Enedis",[2.2706802, 48.80304695]],
    [ "Enedis",[2.2634266806812295, 48.811550211690346]],
    [ "Enedis",[2.27219627761742, 48.81076084624636]],
    [ "S Marguerite 21",[2.283341596640873, 48.8300761519007]],
    [ "Enedis",[2.2803730844027195, 48.83013819817593]],
    [ "Enedis",[2.2672419307093175, 48.833826535945114]],
    [ "Enedis",[1.5161919357194409, 49.54020652883406]],
    [ "Enedis",[1.514606342300609, 49.5429813570657]],
    [ "Enedis",[-0.7382883180236605, 47.01216923558476]],
    [ "Enedis",[-0.7374437681181123, 47.00646882805099]],
    [ "Enedis",[1.5016671351758695, 43.616459852454305]],
    [ "Enedis",[1.4963123312464253, 43.615077177432156]],
    [ "Enedis",[1.496208149999999, 43.62356559999999]],
    [ "Enedis",[1.509928772252904, 43.59734813210431]],
    [ "Enedis",[1.4939538000000008, 43.603717749999994]],
    [ "Enedis",[1.5165493796492027, 43.60464222189187]],
    [ "Enedis",[1.4934711976589146, 43.60666543449235]],
    [ "Parmentier 2",[1.4915262000000002, 43.608649250000006]],
    [ "Parmentier 1",[1.4933721971492075, 43.60905643168067]],
    [ "Enedis",[1.51479555, 43.61164995]],
    [ "Enedis",[1.5146760727923796, 43.61243351714384]],
    [ "Enedis",[1.50826325, 43.60894525]],
    [ "Enedis",[1.4984744699185533, 43.61085587267021]],
    [ "Enedis",[1.5078879455524998, 43.613703015631415]],
    [ "Enedis",[1.5142529158219502, 43.618653353169634]],
    [ "Enedis",[1.5116277837180747, 43.6193610542818]],
    [ "Enedis",[1.5255538469891825, 43.58580192590829]],
    [ "Enedis",[1.5275434499999998, 43.582904199999994]],
    [ "Enedis",[1.5183690432763053, 43.581148273540734]],
    [ "Enedis",[1.5199158499999985, 43.582071400000004]],
    [ "Enedis",[1.5207194213058208, 43.5800863529949]],
    [ "Enedis",[1.514033016184026, 43.57941212818387]],
    [ "Enedis",[1.5096155999999998, 43.57666125]],
    [ "Enedis",[1.4875531885645403, 43.582446623818676]],
    [ "Enedis",[1.4919723012719452, 43.583330283901525]],
    [ "Enedis",[1.492864667416063, 43.58275907106197]],
    [ "Enedis",[1.4086422196075115, 43.61910035468895]],
    [ "Enedis",[1.4100218591612252, 43.61372861409066]],
    [ "Enedis",[1.4116975149166013, 43.61353355596556]],
    [ "Enedis",[1.4075814499999997, 43.616726150000005]],
    [ "Enedis",[1.3979331478256851, 43.60778807653383]],
    [ "Enedis",[1.3961152886783437, 43.61737096597499]],
    [ "Enedis",[1.4265059520434145, 43.62818660004055]],
    [ "Enedis",[1.41881825, 43.638090299999995]],
    [ "Enedis",[1.4126463000000002, 43.63072745]],
    [ "Enedis",[1.4244575662200705, 43.62409140612474]],
    [ "Enedis",[1.4336814181719373, 43.62958121511184]],
    [ "Enedis",[1.4137224492727705, 43.65075275382205]],
    [ "Enedis",[1.4114325000000014, 43.64953905]],
    [ "Enedis",[1.41136955, 43.64953225000001]],
    [ "Enedis",[1.4193419031082493, 43.64505568383604]],
    [ "Enedis",[1.4324168950480431, 43.6403006929591]],
    [ "Enedis",[-1.8306377325166951, 46.59697601922916]],
    [ "Enedis",[-1.745941394557032, 46.50583277933303]],
    [ "Enedis",[-1.8036220277086235, 46.49771725602876]],
    [ "Enedis",[6.090191249999998, 48.5965607]],
    [ "Enedis",[6.095400628511097, 48.585424743564445]],
    [ "CM PORT TRIVAUX 107",[2.2587141868464564, 48.79062221316207]],
    [ "Enedis",[-1.26668735, 47.172503199999994]],
    [ "Enedis",[1.31541215, 43.6285656]],
    [ "Enedis",[1.3232830708677321, 43.624312243280386]],
    [ "Enedis",[1.3302500976444984, 43.621176684364755]],
    [ "Enedis",[1.3260008950590576, 43.62422496500872]],
    [ "Enedis",[1.3263930999999998, 43.62613294999999]],
    [ "Enedis",[1.3344322998360638, 43.62523696673481]],
    [ "Enedis",[1.3363219500000003, 43.6246374]],
    [ "Enedis",[1.3379627765330995, 43.624959103158815]],
    [ "Enedis",[1.3439822812469124, 43.63198247600039]],
    [ "Enedis",[1.3465391499999997, 43.62571605]],
    [ "Enedis",[1.3452917500000006, 43.60982275]],
    [ "Enedis",[1.3545818445404862, 43.61306527860691]],
    [ "Enedis",[1.3544250000000004, 43.606265199999996]],
    [ "Enedis",[1.3563398, 43.6082829]],
    [ "Enedis",[1.3593824961327572, 43.603502583141214]],
    [ "Enedis",[1.3577288499999987, 43.60429059999999]],
    [ "Enedis",[1.35788125, 43.60323385]],
    [ "Enedis",[1.3597337999999999, 43.605385299999995]],
    [ "Enedis",[1.3606560000000003, 43.6078362]],
    [ "Enedis",[1.3624474172782441, 43.60782644455975]],
    [ "Enedis",[1.364322124326687, 43.60572159612967]],
    [ "P2332 ALISIER BLANC",[1.36421845, 43.607092300000005]],
    [ "Enedis",[6.054955249999999, 48.58175189999999]],
    [ "Enedis",[6.0512181499999995, 48.58277195]],
    [ "Enedis",[6.03063415, 48.58349085]],
    [ "Enedis",[6.811911851158124, 45.591970799440915]],
    [ "Enedis",[6.0358826500000005, 48.5784817]],
    [ "Enedis",[-1.7555532803589335, 46.480825824610044]],
    [ "612032025",[-1.7686847600503945, 46.503999026904914]],
    [ "Enedis",[1.3244088, 43.579106599999996]],
    [ "Enedis",[1.3330928599502285, 43.58794116414095]],
    [ "Enedis",[1.3473396652952458, 43.59062325408211]],
    [ "Enedis",[1.3469282536093308, 43.593388482409544]],
    [ "Enedis",[1.3498765636177914, 43.59435036756289]],
    [ "Enedis",[1.3501580000000004, 43.5954253]],
    [ "Enedis",[1.3547711, 43.599619049999994]],
    [ "Enedis",[1.35471545, 43.5996438]],
    [ "Paul Valery2",[1.3450475005832, 43.59639313353781]],
    [ "Enedis",[1.3177093999999996, 43.600640049999996]],
    [ "Enedis",[1.312916196696507, 43.57811793140025]],
    [ "Enedis",[1.314495582681343, 43.577321392896174]],
    [ "Enedis",[1.3164011, 43.57568775000001]],
    [ "Salle Polyvalente",[1.3195434329272338, 43.574493843580505]],
    [ "Enedis",[1.3451467091924187, 43.58183683615834]],
    [ "DEODAT DE SEVERAC 1",[1.3562192334401932, 43.59934462243359]],
    [ "Enedis",[1.358634550000001, 43.599207500000006]],
    [ "Enedis",[1.3582815999999995, 43.60037125]],
    [ "Enedis",[1.3624940894079092, 43.599826989827314]],
    [ "Enedis",[1.359937834091588, 43.59757200513085]],
    [ "Enedis",[1.3626967499999998, 43.58027834999999]],
    [ "Enedis",[1.3606041235347286, 43.591876508522745]],
    [ "LOURDON 2",[1.3172048308702073, 43.6032834830903]],
    [ "Enedis",[1.3547890549784647, 43.64559744756088]],
    [ "Enedis",[1.341768583937388, 43.63607676984254]],
    [ "Enedis",[1.397941591979112, 43.6297444817531]],
    [ "Enedis",[1.3966446076183716, 43.63319097290303]],
    [ "Enedis",[1.3858934499999997, 43.64037149999999]],
    [ "Enedis",[6.121268784013875, 48.605104551463334]],
    [ "Enedis",[6.123514234155717, 48.604602098553734]],
    [ "Enedis",[1.543166866781101, 43.627069310675374]],
    [ "Enedis",[1.5326555998649267, 43.626269416719346]],
    [ "Enedis",[1.5271764500000002, 43.6238424]],
    [ "Enedis",[1.5759054191317678, 43.61612383348665]],
    [ "Enedis",[1.5390719499999996, 43.59685444999999]],
    [ "Enedis",[1.54920115, 43.60101034999999]],
    [ "Enedis",[1.54721545, 43.5989625]],
    [ "Enedis",[1.5460688666666667, 43.599444399999996]],
    [ "Enedis",[-1.35682245, 46.17750505]],
    [ "Volette",[-1.3721422398594258, 46.179759716351505]],
    [ "Enedis",[-1.3538287176350237, 46.16932010671668]],
    [ "Oliviers",[-1.3544744, 46.16035035]],
    [ "Le Hameau de Perthus",[-1.3405977, 46.151760599999996]],
    [ "Montamer",[-1.3332934257982474, 46.15263610344056]],
    [ "La Noue",[-1.3304683000000002, 46.155628]],
    [ "Enedis",[-1.3191581499999998, 46.156117099999996]],
    [ "Les Binais",[-1.3209064695928872, 46.15100168208865]],
    [ "Les Senses",[-1.3208840500000003, 46.146100100000005]],
    [ "Enedis",[-1.312074159930575, 46.14965097517213]],
    [ "Enedis",[-1.312055987081832, 46.15078788138617]],
    [ "Enedis",[-1.3049210000000002, 46.150164000000004]],
    [ "Abbaye",[-1.304851099999999, 46.148959899999994]],
    [ "Croix de la Mine",[-1.3073822750467798, 46.15245839655126]],
    [ "Enedis",[1.5727607824616558, 43.59290115820271]],
    [ "Enedis",[1.5673509499999998, 43.5889412]],
    [ "Enedis",[1.5645684030237705, 43.59238756715295]],
    [ "Enedis",[1.560089081225395, 43.593236575458675]],
    [ "Enedis",[1.560763, 43.59573035]],
    [ "Enedis",[1.10564661614205, 49.9192909027591]],
    [ "Enedis",[2.355810112098765, 47.01153832271605]],
    [ "Poste électrique de Vanves",[2.278974766712432, 48.813023583853216]],
    [ "Enedis",[2.298166209761009, 48.803979166406044]],
    [ "Sous-station SNCF Masséna",[2.3844886175036155, 48.823734916122106]],
    [ "Enedis",[-4.28691265, 48.39075475]],
    [ "Enedis",[5.977846528798697, 48.69364218155397]],
    [ "Enedis",[1.548317881887796, 43.64661150593234]],
    [ "Enedis",[1.5553227500000004, 43.64645605]],
    [ "Enedis",[5.970138215865525, 48.70161527913741]],
    [ "Enedis",[5.980904533564749, 48.702092061522364]],
    [ "Enedis",[5.969218400000001, 48.69103225]],
    [ "Enedis",[1.499635888483199, 43.56883960715898]],
    [ "Le Préau",[-1.3447435999999997, 46.19974840000001]],
    [ "Le Pas des Boeufs",[-1.3853720999999994, 46.181637599999995]],
    [ "Enedis",[-1.3853720999999999, 46.18171024999999]],
    [ "Les Varennes",[-1.3855148127518582, 46.179258811500084]],
    [ "Le Courlis",[-1.3962818793309426, 46.18102979645609]],
    [ "Le Bois",[-1.3902095500000013, 46.1878085]],
    [ "ZI",[5.68919285, 46.5766505]],
    [ "Enedis",[-1.8784824879022386, 46.694361075372996]],
    [ "Enedis",[-1.8774252499999997, 46.6978043]],
    [ "Enedis",[1.5214368506300286, 43.568358355063]],
    [ "Enedis",[1.5304183160401723, 43.56276831785548]],
    [ "Enedis",[1.5363623178128747, 43.558959898755994]],
    [ "Enedis",[1.5240371000000013, 43.56011]],
    [ "Enedis",[1.5238966666951475, 43.55832934983527]],
    [ "Enedis",[1.5160790330830491, 43.56414985032537]],
    [ "Carmes",[1.5061610499999998, 43.567530649999995]],
    [ "Enedis",[1.568799472221733, 43.557412746622774]],
    [ "Enedis",[1.56283615, 43.553735350000004]],
    [ "Enedis",[1.41128465, 43.5811846]],
    [ "Enedis",[1.4072141414975952, 43.57328047952866]],
    [ "Enedis",[1.4165223337653619, 43.56498104923373]],
    [ "Enedis",[1.42252012716246, 43.587270051539655]],
    [ "Enedis",[1.4077762, 43.582807800000005]],
    [ "Enedis",[1.406492181726877, 43.582364942118915]],
    [ "Enedis",[1.409823087997299, 43.60428016458231]],
    [ "Enedis",[1.3406287990633823, 43.56785171630391]],
    [ "Enedis",[1.486067516365911, 43.65390990066977]],
    [ "Enedis",[1.4846012999999998, 43.65629955]],
    [ "Enedis",[1.483591910338891, 43.65065867860384]],
    [ "Enedis",[1.4709315841087647, 43.647184647217564]],
    [ "Enedis",[1.4682417, 43.65058625]],
    [ "Enedis",[1.465675722364578, 43.64679963938892]],
    [ "Enedis",[1.5072497009558983, 43.653667387839604]],
    [ "Enedis",[1.507202642578932, 43.64910752013376]],
    [ "Enedis",[1.5045636655670342, 43.65025380458884]],
    [ "Enedis",[1.5017776832589746, 43.64762999990958]],
    [ "Enedis",[1.5040620348357125, 43.66271696807338]],
    [ "Enedis",[1.5034796326488093, 43.65858740666667]],
    [ "Enedis",[1.5045313845947843, 43.652802804226404]],
    [ "Enedis",[1.4936716505940963, 43.6607991164826]],
    [ "Enedis",[1.4884336000000002, 43.665989450000005]],
    [ "Enedis",[1.48997855, 43.66434485]],
    [ "Enedis",[1.4881900658569867, 43.66432889865825]],
    [ "Enedis",[1.4851789, 43.663486799999994]],
    [ "Enedis",[1.4878313500000002, 43.658616]],
    [ "Enedis",[1.4851389110409956, 43.65909196159147]],
    [ "Enedis",[1.4768487000000001, 43.66272204999999]],
    [ "Enedis",[1.479800600947222, 43.66189398860966]],
    [ "Enedis",[1.4633455999999998, 43.66374234999999]],
    [ "Enedis",[1.4617355500000007, 43.66213665]],
    [ "Enedis",[1.4556541500000004, 43.6605337]],
    [ "Enedis",[1.4573405324022248, 43.658991518446214]],
    [ "Enedis",[1.45028445, 43.65556445000001]],
    [ "Enedis",[1.447636350000001, 43.6554847]],
    [ "Enedis",[1.4435606000000014, 43.673756250000004]],
    [ "Enedis",[1.4460334000000004, 43.67193269999999]],
    [ "Enedis",[1.4498881, 43.671468850000004]],
    [ "Enedis",[1.4324459619434229, 43.67023088320371]],
    [ "Enedis",[1.2948184123317485, 43.5908819597382]],
    [ "Enedis",[-1.3150232891604852, 43.66178929312853]],
    [ "Enedis",[1.5302029499999985, 43.64403980000001]],
    [ "Enedis",[1.5388573314955212, 43.640790409411814]],
    [ "Enedis",[1.5392545985414432, 43.63791668262974]],
    [ "Enedis",[1.5357803165667703, 43.64145175407758]],
    [ "Enedis",[1.52457875, 43.64404570000001]],
    [ "Enedis",[1.5164019499999999, 43.6462876]],
    [ "Enedis",[1.517128514020209, 43.652023444245366]],
    [ "Enedis",[1.514668938373842, 43.664669925105585]],
    [ "Enedis",[1.5144862723840995, 43.65898749264505]],
    [ "Enedis",[1.5137795340979003, 43.66046284642141]],
    [ "Enedis",[1.5127226999999996, 43.66304820000001]],
    [ "Enedis",[1.5080139822706644, 43.664431330651034]],
    [ "Enedis",[1.5117750384028998, 43.668487167887406]],
    [ "Enedis",[1.5167742038532037, 43.668998514738924]],
    [ "Enedis",[1.5331080499999994, 43.66665125]],
    [ "Enedis",[1.5297378156918786, 43.66003304847441]],
    [ "Enedis",[1.5312744999999994, 43.65737275]],
    [ "Enedis",[1.330748467618356, 43.646993902680585]],
    [ "Enedis",[1.3484383500000001, 43.66004245]],
    [ "Enedis",[1.3484358, 43.6601006]],
    [ "Enedis",[1.3476853661553383, 43.66113876959941]],
    [ "Enedis",[1.3483932750806826, 43.65626559586855]],
    [ "Enedis",[1.342304622675937, 43.654991546290404]],
    [ "Enedis",[1.3436489, 43.65279725]],
    [ "Enedis",[1.3635431999999994, 43.65252460000001]],
    [ "Béteil",[1.3600924526057385, 43.65568696594751]],
    [ "Enedis",[1.3603192, 43.6590615]],
    [ "Enedis",[1.3832364277733453, 43.67030940332535]],
    [ "Enedis",[1.3682550177298372, 43.66275890112502]],
    [ "Enedis",[1.3739168821617924, 43.65813847145591]],
    [ "Enedis",[1.3702403368542881, 43.65854346748699]],
    [ "Enedis",[1.3865771000000002, 43.65533685000001]],
    [ "Enedis",[1.3826936839332287, 43.65552117013562]],
    [ "Enedis",[1.3787425681705285, 43.65595230156267]],
    [ "Enedis",[1.3716818489120093, 43.65539881733693]],
    [ "Enedis",[1.371543612921341, 43.6540684543835]],
    [ "Enedis",[1.3727136866564127, 43.65271736710337]],
    [ "Enedis",[1.376906482051282, 43.653459548717954]],
    [ "Enedis",[1.3816508000000003, 43.65056059999999]],
    [ "La Diette",[-1.5219132108540394, 46.20667853182057]],
    [ "Place République",[-1.368369726850351, 46.201328839816306]],
    [ "Simaillauds",[-1.4519776500000015, 46.22306005]],
    [ "Enedis",[-1.4370723000000012, 46.22047460000001]],
    [ "Dechetterie",[-1.4327623001509309, 46.22163833338225]],
    [ "Enedis",[-1.429048607515121, 46.226381478058634]],
    [ "La Passe",[-1.460259473009209, 46.20361645342625]],
    [ "Peux Ragot",[-1.4312480702609438, 46.19406938343996]],
    [ "Enedis",[-1.7834638685013033, 46.5002548493074]],
    [ "Enedis",[-1.7861877296361754, 46.50312639732189]],
    [ "Le Gayet",[5.557016096065829, 45.410666493025936]],
    [ "Enedis",[-1.732492772899729, 46.48178502411925]],
    [ "Enedis",[-1.7327210000000006, 46.477225049999994]],
    [ "Enedis",[1.337826215879896, 43.548511768073745]],
    [ "Enedis",[1.2765110000000002, 43.67120570000001]],
    [ "Enedis",[1.2808574671708877, 43.670326483500396]],
    [ "Enedis",[1.2865432444109968, 43.67114327593716]],
    [ "Enedis",[1.2834030425217284, 43.66963937052846]],
    [ "Enedis",[1.3158303775335776, 43.6439324536436]],
    [ "Enedis",[1.3082462336257865, 43.55265625449646]],
    [ "Enedis",[1.3113611443690987, 43.55411598395222]],
    [ "Enedis",[1.3141505, 43.55979875]],
    [ "Enedis",[1.31287375, 43.56183805000001]],
    [ "Enedis",[1.3230660152166283, 43.55580960414297]],
    [ "Enedis",[1.3582112692921235, 43.52695395218788]],
    [ "Enedis",[1.3991218153231506, 49.46022705316573]],
    [ "La Grange",[-1.5192582838878514, 46.20485555082751]],
    [ "Les Roussieres",[-1.5442821585285655, 46.2255762860431]],
    [ "Les 3 Pins",[-1.5256752, 46.21573535000001]],
    [ "Enedis",[-1.5066827999999999, 46.251556300000004]],
    [ "Église",[-1.499092792998345, 46.24993093045362]],
    [ "Gris Buisson",[-1.444509836305733, 46.20043401273885]],
    [ "Enedis",[-1.4287494829309764, 46.19444648405563]],
    [ "Enedis",[-1.4254547845448355, 46.19836291694501]],
    [ "Enedis",[6.563126372321815, 45.743392925941016]],
    [ "Clermont",[5.542368897698911, 45.419038640684825]],
    [ "Enedis",[1.5687047933259954, 43.541416381846766]],
    [ "Enedis",[1.5681826885674324, 43.54268908359046]],
    [ "Enedis",[1.5602218182169447, 43.54678775151896]],
    [ "Enedis",[1.5593353114644302, 43.544956774887865]],
    [ "LANOUX",[1.5450329844498472, 43.54784842421895]],
    [ "Enedis",[-1.7558010219739482, 46.48711849570885]],
    [ "Innopole Labège",[1.50058545, 43.549714050000006]],
    [ "Enedis",[1.4968588500000002, 43.55116569999999]],
    [ "Enedis",[1.50736385, 43.54261460000001]],
    [ "Enedis",[1.50953585, 43.5421503]],
    [ "Enedis",[1.5129289500000014, 43.539368200000006]],
    [ "Enedis",[1.5164341161986945, 43.53827590493082]],
    [ "Enedis",[1.5221633053778887, 43.53708547919322]],
    [ "Enedis",[1.5243883043574034, 43.53984916568585]],
    [ "Enedis",[1.5206131500000002, 43.54406170000001]],
    [ "Enedis",[1.5171736500000004, 43.543621200000004]],
    [ "Enedis",[1.5139994694743022, 43.54659697194359]],
    [ "Enedis",[1.5152856, 43.547331549999996]],
    [ "Enedis",[1.5067882499999998, 43.55310300000001]],
    [ "Enedis",[1.5064854654643747, 43.55560459748226]],
    [ "Enedis",[1.3688325500000003, 43.58005585]],
    [ "Enedis",[1.3630921499999997, 43.57896785]],
    [ "Enedis",[1.3389868335724535, 43.61862540345016]],
    [ "Enedis",[1.37051035, 43.53956195000001]],
    [ "Enedis",[1.34476625, 43.54326964999999]],
    [ "Enedis",[1.3493095378478435, 43.546962268138984]],
    [ "Enedis",[1.36692178934056, 43.54950616734418]],
    [ "Enedis",[1.319434453610078, 43.53400063280351]],
    [ "Enedis",[1.3317567000000001, 43.54718335]],
    [ "Enedis",[1.3410043677877441, 43.550439644343655]],
    [ "Enedis",[1.337233303166191, 43.551668865404785]],
    [ "Enedis",[1.3354291497138877, 43.53720962025657]],
    [ "Enedis",[1.3398598500000007, 43.537609100000005]],
    [ "Enedis",[1.3567503330019501, 43.53618805889763]],
    [ "Enedis",[1.3679737205279796, 43.53883914736612]],
    [ "Enedis",[1.3651943187877782, 43.539367420531036]],
    [ "Enedis",[1.36774525, 43.53253169999999]],
    [ "Enedis",[1.3300616, 43.52660285]],
    [ "Enedis",[1.3441588114943166, 43.530227038809514]],
    [ "Enedis",[1.3510184206016291, 43.53044655889347]],
    [ "Enedis",[1.3578835159433305, 43.53074929825402]],
    [ "Enedis",[1.35459575, 43.53185020000001]],
    [ "Enedis",[1.356219592143779, 43.532258284412485]],
    [ "Enedis",[1.3471779499999998, 43.5340998]],
    [ "Enedis",[1.3997033039963187, 43.53325661644323]],
    [ "Enedis",[1.3999575499999997, 43.5358131]],
    [ "Enedis",[1.3970947622094094, 43.52421553320169]],
    [ "Enedis",[1.3818216940019104, 43.52372302746853]],
    [ "Enedis",[1.380847522942073, 43.52637683730158]],
    [ "Enedis",[1.3752040651127293, 43.5281083674431]],
    [ "Enedis",[1.32115185, 43.51996320000001]],
    [ "Enedis",[1.3196687000000002, 43.52118480000001]],
    [ "Enedis",[1.3399539500000002, 43.52081375000001]],
    [ "Enedis",[1.342564555164436, 43.520518419588434]],
    [ "Enedis",[1.3361315450692743, 43.52115533333333]],
    [ "Enedis",[1.3249212, 43.52318095]],
    [ "Enedis",[1.3200917999999997, 43.52591369999999]],
    [ "Enedis",[1.4027023433341699, 43.54118296065467]],
    [ "Enedis",[1.4148317, 43.53457965]],
    [ "Enedis",[1.4047335500000002, 43.52847745]],
    [ "Enedis",[1.3533589706039288, 43.51672924676847]],
    [ "Enedis",[1.365071066560762, 43.51159239981467]],
    [ "Enedis",[1.3828515499999998, 43.515745999999986]],
    [ "Enedis",[1.3279286500000003, 43.511550799999995]],
    [ "Enedis",[1.324591783140057, 43.51259010030088]],
    [ "Enedis",[1.3259771500000002, 43.51378730000001]],
    [ "Enedis",[1.3405606499999998, 43.51045615000001]],
    [ "Enedis",[1.3376248, 43.51768515]],
    [ "Enedis",[1.3187377812418497, 43.51766995923908]],
    [ "Enedis",[1.394354874808666, 43.567956513095865]],
    [ "Enedis",[1.4073345764694218, 43.56253059431064]],
    [ "Enedis",[1.4099672148911635, 43.5621836012732]],
    [ "Enedis",[2.7697674517501505, 47.977496127359366]],
    [ "Enedis",[2.7601030503100525, 47.97890393607484]],
    [ "Enedis",[-1.38515655, 43.545765]],
    [ "Enedis",[-1.3827823354971522, 43.540737218508056]],
    [ "Enedis",[-1.3857650774931336, 43.54318924381752]],
    [ "Enedis",[-1.3905820000000007, 43.5435982]],
    [ "SEQUE",[-1.4339054424787525, 43.50864737269803]],
    [ "LOUSTANAOU",[-1.4319101500000002, 43.508064250000004]],
    [ "Enedis",[-1.4438440980906977, 43.505332016422365]],
    [ "Enedis",[-1.4462077308441108, 43.50184879679419]],
    [ "Enedis",[-1.4607109, 43.50528160000001]],
    [ "Enedis",[-1.4603178499999998, 43.5070953]],
    [ "Enedis",[-1.4613376, 43.50271390000001]],
    [ "Enedis",[1.4801683000000003, 43.54175735]],
    [ "Enedis",[1.4847324999999998, 43.53520954999999]],
    [ "Enedis",[1.4836288000000002, 43.53578490000001]],
    [ "Enedis",[1.4899330499999999, 43.540698600000006]],
    [ "Enedis",[1.4876493025699071, 43.540512987560085]],
    [ "Enedis",[1.4841208865316113, 43.54051898850982]],
    [ "Enedis",[1.4816369334999804, 43.54315899433399]],
    [ "Enedis",[1.4760890500000001, 43.51580675]],
    [ "Enedis",[1.5230533000000002, 43.51217784999999]],
    [ "Enedis",[1.521885156202174, 43.51300403793478]],
    [ "Enedis",[1.5019036000000008, 43.51421235]],
    [ "Enedis",[1.5096072987219937, 43.51532893408644]],
    [ "Enedis",[1.5097211681076115, 43.51628257333601]],
    [ "Enedis",[1.5058986500000002, 43.51829000000001]],
    [ "Enedis",[1.4987032500000002, 43.51830290000001]],
    [ "Enedis",[1.4924119342846043, 43.530114904068206]],
    [ "Enedis",[1.4934404500000003, 43.5295582]],
    [ "Enedis",[1.4936539999999998, 43.52501240000001]],
    [ "Enedis",[1.4833168243261077, 43.52631625679751]],
    [ "Enedis",[1.482852108087878, 43.52919061542019]],
    [ "Enedis",[5.1261288, 47.517170549999996]],
    [ "Enedis",[5.110076669337457, 47.514488333352155]],
    [ "Enedis",[5.118230466611713, 47.52691179934056]],
    [ "Enedis",[5.11499169897798, 47.5293970664189]],
    [ "Enedis",[5.1480523, 47.52871661666667]],
    [ "Enedis",[5.136106101902333, 47.53432048320233]],
    [ "Enedis",[5.091829983367298, 47.514465459877655]],
    [ "Enedis",[1.4345603500000002, 43.54037769999999]],
    [ "Enedis",[1.4579334499999999, 43.52577335]],
    [ "Enedis",[1.45668205, 43.52970495]],
    [ "Enedis",[1.4497337, 43.542248900000004]],
    [ "Enedis",[-1.49123935, 43.49413505]],
    [ "Enedis",[-1.491191407258737, 43.49504517597149]],
    [ "Enedis",[-1.4599526499999997, 43.490698450000004]],
    [ "Enedis",[-1.4573137784295707, 43.49034949634336]],
    [ "Enedis",[-1.4520333500000002, 43.491941249999996]],
    [ "Enedis",[-1.4552188000000001, 43.493235299999995]],
    [ "Enedis",[-1.4521903499999993, 43.492523]],
    [ "Enedis",[-1.4880255000000002, 43.4870328]],
    [ "Enedis",[-1.4855204, 43.479481750000005]],
    [ "Enedis",[-1.47912785, 43.4762993]],
    [ "Enedis",[-1.4502428460280896, 43.47149563310735]],
    [ "Enedis",[-1.4455824607000611, 43.468598482389964]],
    [ "Enedis",[-1.4489564047992036, 43.46522961610762]],
    [ "Enedis",[-1.4458130000000002, 43.46386905]],
    [ "Enedis",[-1.3935595158467153, 43.52696994587245]],
    [ "Enedis",[-1.4819505189272675, 43.534571641280536]],
    [ "Enedis",[-1.4784703499999996, 43.5370941]],
    [ "Enedis",[1.317320017891611, 43.56143825144766]],
    [ "Enedis",[1.3129997170545376, 43.55269301733589]],
    [ "Enedis",[-1.4907767500000002, 43.52700899999999]],
    [ "Enedis",[-1.5000107307789847, 43.53540834759731]],
    [ "Enedis",[-1.4875241, 43.5463654]],
    [ "Enedis",[1.3083783500000001, 43.6045692]],
    [ "P0649 GARABOT",[1.3002001499999996, 43.60100655]],
    [ "Enedis",[1.2956606770913253, 43.59877465354845]],
    [ "Enedis",[1.2918600500000001, 43.59643075]],
    [ "Enedis",[1.2954539255564357, 43.595978803578745]],
    [ "Enedis",[1.2946697000000003, 43.59162865]],
    [ "Enedis",[1.3021732677930216, 43.5921661227156]],
    [ "Enedis",[1.3032837499999999, 43.59185155]],
    [ "Enedis",[1.3022688500000008, 43.589060749999994]],
    [ "Industrie 1",[1.3108626002065467, 43.588385549863645]],
    [ "Enedis",[1.3193730222465954, 43.58329016677125]],
    [ "Enedis",[1.2854839000000005, 43.58352659999999]],
    [ "Enedis",[1.293062317361861, 43.5889728062722]],
    [ "Enedis",[1.3112901971639734, 43.57050166967188]],
    [ "Enedis",[1.3038503494074445, 43.570673083531716]],
    [ "Enedis",[1.2928249000000005, 43.5716944]],
    [ "Enedis",[1.2910708291637052, 43.576280777062976]],
    [ "Enedis",[1.2912310687292572, 43.580773760586005]],
    [ "Enedis",[1.2753089168652232, 43.571578950440504]],
    [ "Enedis",[1.284523864994079, 43.57343206789695]],
    [ "Enedis",[1.2849688499999998, 43.57722145]],
    [ "Enedis",[1.2769713820136088, 43.57930368010594]],
    [ "Enedis",[1.2729190500000012, 43.577540000000006]],
    [ "Enedis",[1.288782634297829, 43.57662994841]],
    [ "Enedis",[1.2854803499999998, 43.5794149]],
    [ "Enedis",[1.2949314969071413, 43.55598936573133]],
    [ "Enedis",[1.2988993, 43.55496285]],
    [ "Enedis",[1.3028767500000002, 43.5580009]],
    [ "Enedis",[1.3018204174349837, 43.55951815640264]],
    [ "Enedis",[1.3030465427672748, 43.56231734126366]],
    [ "Enedis",[1.304513198135219, 43.56142265161185]],
    [ "Enedis",[1.2602401945403394, 43.55975211619255]],
    [ "Enedis",[1.265263677000294, 43.56529190354295]],
    [ "Enedis",[1.2746369662424786, 43.56031265346099]],
    [ "Enedis",[1.27467895, 43.5578203]],
    [ "Enedis",[1.2782772049310585, 43.55765596560292]],
    [ "Enedis",[1.2847905500000008, 43.55866205]],
    [ "Enedis",[1.2940388538121863, 43.567357120868394]],
    [ "Enedis",[1.298501187055763, 43.568899216888454]],
    [ "Enedis",[-1.7581534964933623, 46.5161086513319]],
    [ "P01 Bourg",[-0.8091394007246516, 47.55308144688394]],
    [ "Enedis",[-0.37727225, 43.3776599]],
    [ "Enedis",[1.8682091848384732, 44.910864383558845]],
    [ "private",[1.9671489246508895, 50.236082060929334]],
    [ "Enedis",[-0.9651257277036306, 46.922468289095264]],
    [ "Enedis",[-1.4195089806309311, 43.53002060825162]],
    [ "Enedis",[-1.4607088793758485, 43.53759176586958]],
    [ "Enedis",[-1.4597144817794028, 43.53656339521274]],
    [ "Enedis",[-1.5144839, 43.52997785000001]],
    [ "Enedis",[1.3804108686190664, 43.668951452911166]],
    [ "Enedis",[1.3669516803198343, 43.661980510015766]],
    [ "Enedis",[1.3652818675044944, 43.66160210766934]],
    [ "Enedis",[1.3628953499999998, 43.662533950000004]],
    [ "Enedis",[1.405284970409731, 43.68116428710956]],
    [ "Enedis",[1.3823676000000011, 43.68483270000001]],
    [ "Enedis",[1.3833606278735058, 43.68462563498377]],
    [ "Enedis",[1.3880630167264179, 43.68277510011221]],
    [ "Enedis",[1.3970140535529594, 43.68509158117771]],
    [ "Enedis",[1.3988665500000004, 43.68386605]],
    [ "Enedis",[1.3919874842971647, 43.67753985510357]],
    [ "Enedis",[1.3984865000000006, 43.673888299999994]],
    [ "Enedis",[1.414004735120824, 43.679838872565384]],
    [ "Enedis",[1.4107186, 43.67744415000001]],
    [ "Enedis",[1.4078288, 43.682109399999995]],
    [ "Enedis",[1.4063989494317088, 43.685512083655574]],
    [ "Enedis",[1.4064179906421874, 43.68312141637231]],
    [ "Enedis",[1.4085418999999997, 43.676294049999996]],
    [ "Enedis",[1.4092491649814292, 43.674178167157926]],
    [ "Enedis",[1.4200374999999998, 43.66220764999999]],
    [ "Enedis",[1.3751888499999998, 43.70681755]],
    [ "Bocage",[1.3874371772433178, 43.700190991450995]],
    [ "Enedis",[1.3800831987003264, 43.70316779418302]],
    [ "Enedis",[1.3777358826763695, 43.703621195936925]],
    [ "Enedis",[1.3717273787812587, 43.69918826305679]],
    [ "Enedis",[1.3777579812942466, 43.70006837046856]],
    [ "Enedis",[1.37939725, 43.6991729]],
    [ "Enedis",[-1.4348080500000002, 43.591781499999996]],
    [ "Enedis",[1.3513555182089896, 43.6759514017605]],
    [ "Enedis",[1.3598273162618797, 43.68520360682858]],
    [ "Enedis",[1.359031796260894, 43.68470438181988]],
    [ "Enedis",[1.3592988701844402, 43.67850336679565]],
    [ "Enedis",[1.3609800097390887, 43.67953206663125]],
    [ "Enedis",[1.3618612304400792, 43.68037298371948]],
    [ "Enedis",[1.3615091707360307, 43.6867579035607]],
    [ "Enedis",[1.3616818999999998, 43.6854596]],
    [ "Garonnette",[1.3655240520514904, 43.68320356556666]],
    [ "Enedis",[1.3675141000000002, 43.6787359]],
    [ "Enedis",[1.3642763, 43.6771616]],
    [ "Enedis",[1.3656444145356819, 43.671834019584196]],
    [ "Sous-station de Juigné-sur-Sarthe",[-0.27771481558564615, 47.90946172467416]],
    [ "Enedis",[1.3558095944958695, 43.6963115713845]],
    [ "Enedis",[1.3549074499999998, 43.6934458]],
    [ "Enedis",[1.3508144829049102, 43.68827372088135]],
    [ "Enedis",[-1.45123339884941, 43.55797948370979]],
    [ "Enedis",[-1.4483563905082737, 43.56240892123769]],
    [ "Enedis",[-1.4430075329879801, 43.56316579464703]],
    [ "Enedis",[-1.4461712070028336, 43.55788472943403]],
    [ "Les Pelettes",[5.560669126548735, 45.40964688571306]],
    [ "Enedis",[1.3203097832669874, 43.695489866583905]],
    [ "Enedis",[1.3222493811018916, 43.69439269077336]],
    [ "Enedis",[1.3234803340364814, 43.68853723218095]],
    [ "Enedis",[1.3274730500000003, 43.68741755]],
    [ "Enedis",[1.3285112831418808, 43.687612851929245]],
    [ "Enedis",[1.333550649999999, 43.686559149999994]],
    [ "Enedis",[1.3356283672931, 43.68799315087083]],
    [ "Enedis",[1.340260961128288, 43.688763581883954]],
    [ "Enedis",[1.34835975, 43.69591265]],
    [ "Enedis",[1.323245486142853, 43.67547758092374]],
    [ "Enedis",[1.320242650000001, 43.67751554999999]],
    [ "Enedis",[1.31436145, 43.6866755]],
    [ "Enedis",[1.3157764500000002, 43.6857204]],
    [ "Enedis",[1.317483664248411, 43.68333102840726]],
    [ "Enedis",[1.319275015389418, 43.68556007028771]],
    [ "Enedis",[1.3208699142921125, 43.68405472436732]],
    [ "Enedis",[1.3126375559586245, 43.64392277105395]],
    [ "Enedis",[1.3163696910519316, 43.65032443313575]],
    [ "Enedis",[1.3194603626072123, 43.652442411440276]],
    [ "Enedis",[1.31853345, 43.6484315]],
    [ "Enedis",[1.4004542157926034, 43.71269530591909]],
    [ "Enedis",[1.3982246156208873, 43.71178224831066]],
    [ "Enedis",[1.3926848022319813, 43.7111683289597]],
    [ "Enedis",[1.3970727846398134, 43.710311294502326]],
    [ "Enedis",[1.4001911155773092, 43.70955820327539]],
    [ "Enedis",[1.4001527999999994, 43.709585649999994]],
    [ "Enedis",[1.3912148816485213, 43.71017764850181]],
    [ "Enedis",[1.39061325, 43.708346]],
    [ "Enedis",[1.3983670816008569, 43.70851580210413]],
    [ "Enedis",[1.3995113999999997, 43.70629185000001]],
    [ "Enedis",[1.3976165999999994, 43.70402095]],
    [ "Enedis",[1.4039794963934427, 43.70357546907103]],
    [ "Enedis",[1.4012557000000003, 43.70262580000001]],
    [ "Enedis",[1.3950676499999999, 43.703672999999995]],
    [ "Enedis",[1.4066281999999988, 43.69444099999999]],
    [ "Enedis",[1.40898075, 43.69377615]],
    [ "Enedis",[1.4025301476272087, 43.691812517629764]],
    [ "Enedis",[1.4065733500000002, 43.69022615]],
    [ "Enedis",[1.4093415000000002, 43.6873565]],
    [ "Enedis",[4.502853600000001, 45.4833485]],
    [ "GLAIN CHEMIN DE LA BAIGNADE",[-1.4728128788592447, 43.48535877304648]],
    [ "Enedis",[-1.4738385503511406, 43.482919766829724]],
    [ "Enedis",[-1.494518000796585, 43.4654686831958]],
    [ "Enedis",[-1.4917242000000002, 43.46672210000001]],
    [ "Enedis",[-1.49108111593641, 43.46851585274061]],
    [ "Enedis",[-1.4959657338150438, 43.489155483284684]],
    [ "Enedis",[-1.4937777, 43.476557650000004]],
    [ "TOKI LANA",[-1.4899909202473731, 43.48267080776177]],
    [ "Enedis",[-1.4816050000000003, 43.479107600000006]],
    [ "André Roux",[-1.636685346059249, 48.12476295095556]],
    [ "Enedis",[1.2835647, 43.67290595]],
    [ "Enedis",[1.2834989500000002, 43.67291434999999]],
    [ "Enedis",[1.288702554337481, 43.67517898439791]],
    [ "Enedis",[1.2803129855697235, 43.67658361863079]],
    [ "Enedis",[1.2787031951029717, 43.67510196545918]],
    [ "Enedis",[1.2773936, 43.6775241]],
    [ "Enedis",[1.283667318615212, 43.662985597623155]],
    [ "Enedis",[1.2845079418368162, 43.663829278957465]],
    [ "Enedis",[1.3042317135923964, 43.608775267258565]],
    [ "Enedis",[1.31501935, 43.612165950000005]],
    [ "Enedis",[1.3107042660473482, 43.614081772050746]],
    [ "Enedis",[1.3097026999999999, 43.61661204999999]],
    [ "Herbaudière",[1.3032874292923617, 43.619169860435214]],
    [ "Enedis",[-1.48425464503121, 43.50445116481331]],
    [ "Enedis",[-1.4822997139327387, 43.50432270255223]],
    [ "Enedis",[-1.4636273667149335, 43.509159266632054]],
    [ "Enedis",[-1.4680319174194894, 43.507883059184444]],
    [ "Enedis",[-1.4757983119364235, 43.507472717344406]],
    [ "Enedis",[-1.4583954, 43.4818308]],
    [ "Enedis",[-1.4638347219945178, 43.48104615295689]],
    [ "Enedis",[1.2470609678638942, 43.60246357126655]],
    [ "Enedis",[1.2518532709418522, 43.60028619652469]],
    [ "Enedis",[1.25808115, 43.6064382]],
    [ "Enedis",[1.2747951, 43.61723215]],
    [ "Enedis",[1.2867652000000007, 43.621441399999995]],
    [ "Enedis",[-1.4446974, 43.494241099999996]],
    [ "Enedis",[-1.4444856000000001, 43.49217585]],
    [ "Enedis",[1.4741211000000007, 43.538580399999994]],
    [ "Enedis",[1.480528282146062, 43.53884109861921]],
    [ "Enedis",[1.4393545, 43.5221691]],
    [ "Enedis",[1.440658, 43.531205549999996]],
    [ "Enedis",[1.4452480835091157, 43.527538903312106]],
    [ "Enedis",[-1.4541634853100078, 43.478975911725826]],
    [ "Enedis",[-1.4515369593577359, 43.479208034843204]],
    [ "Enedis",[-1.4503999604424183, 43.47981547011517]],
    [ "Enedis",[-1.45142195, 43.4799533]],
    [ "Enedis",[-1.4525226999999998, 43.4803139]],
    [ "Enedis",[-1.450613528489165, 43.484021353256104]],
    [ "Enedis",[-1.4384794500000002, 43.499273900000006]],
    [ "Enedis",[-1.43838084354121, 43.49756277448411]],
    [ "Enedis",[-1.43954545, 43.494895650000004]],
    [ "Enedis",[-1.4424585124713638, 43.49591332855706]],
    [ "Enedis",[1.4551472493485718, 43.58284469990268]],
    [ "Enedis",[1.4636554334304723, 43.575134593152434]],
    [ "Enedis",[1.45159625, 43.57586960000001]],
    [ "Enedis",[2.0397075499999993, 47.84158315]],
    [ "Enedis",[1.4587932039981666, 43.59559575720931]],
    [ "Enedis",[1.2372082624054104, 43.61432056647977]],
    [ "Enedis",[1.2439777298408474, 43.61206576033194]],
    [ "Enedis",[1.2488613795467913, 43.61391153215706]],
    [ "Enedis",[1.2463827057341863, 43.61049456655379]],
    [ "Enedis",[1.235161100000001, 43.61040259999999]],
    [ "Enedis",[1.234673016842118, 43.608480383355385]],
    [ "Enedis",[1.2364915999999997, 43.6043708]],
    [ "Enedis",[1.2311840142211343, 43.59618581845382]],
    [ "Enedis",[1.2328595500000001, 43.598110899999995]],
    [ "Enedis",[1.2414876030403865, 43.59129363404974]],
    [ "Enedis",[1.2441596000000001, 43.598098799999995]],
    [ "Enedis",[1.462837987467828, 43.59874971467696]],
    [ "Enedis",[1.4731683916500988, 43.582756984135195]],
    [ "Enedis",[1.474771962427999, 43.580039886876925]],
    [ "Enedis",[1.4445083932101561, 43.608333723120964]],
    [ "Enedis",[1.5134769499999998, 43.6689957]],
    [ "Enedis",[1.5128238999999999, 43.67241210000001]],
    [ "Enedis",[1.5073520502130249, 43.67391176676001]],
    [ "Enedis",[1.493422883995298, 43.66888409866235]],
    [ "Enedis",[1.5078172500000009, 43.6677439]],
    [ "Enedis",[1.5333344877068649, 43.52881481614937]],
    [ "Enedis",[1.5381602499999998, 43.52579385000001]],
    [ "Enedis",[1.5394681332860907, 43.52595599993833]],
    [ "Enedis",[1.5337746499999998, 43.53516404999999]],
    [ "HLM",[1.5354028500000005, 43.53661195]],
    [ "ZAC du Tricou 3",[1.5348947037424756, 43.53773018428369]],
    [ "Enedis",[1.5488156000000008, 43.5395251]],
    [ "Enedis",[1.5340710123695345, 43.548599707707595]],
    [ "Enedis",[-1.3974837741898154, 46.01499184640006]],
    [ "Enedis",[-1.3968663824593857, 46.01870535560671]],
    [ "Enedis",[1.2447508174202528, 43.572313172585524]],
    [ "Enedis",[1.250473668528348, 43.574994951717464]],
    [ "Enedis",[1.2626743999999999, 43.58123260000001]],
    [ "Enedis",[1.263088668159284, 43.57891449844084]],
    [ "Enedis",[1.2662418000000002, 43.58051935000001]],
    [ "Enedis",[1.249175968670508, 43.55268925170714]],
    [ "Enedis",[-0.3900633011691892, 43.33280999580279]],
    [ "Enedis",[-0.39219702359755276, 43.33186485440938]],
    [ "Enedis",[-0.3900590661615592, 43.32942799886191]],
    [ "Enedis",[0.10830624999999919, 47.51606355]],
    [ "Enedis",[-1.4757652808735278, 43.52346788491513]],
    [ "Enedis",[-1.4774527325340001, 43.5184645488441]],
    [ "Enedis",[-1.4615751000000003, 43.529842699999996]],
    [ "Enedis",[-1.4611758329321316, 43.52961497380193]],
    [ "Enedis",[-1.4740904755265112, 43.52950135975158]],
    [ "Enedis",[-1.4663454740844724, 43.52447805346046]],
    [ "Enedis",[1.4400689513994718, 43.602268390990055]],
    [ "Lycée J Filles",[1.4396397758093633, 43.60975139022673]],
    [ "Enedis",[1.41399988206595, 43.595365925073246]],
    [ "Enedis",[1.4103804055926323, 43.594216679469085]],
    [ "Enedis",[1.119849700283034, 45.799597029135214]],
    [ "Enedis",[1.1295708692957336, 45.78276639688719]],
    [ "Enedis",[1.4148354, 43.698592549999994]],
    [ "Enedis",[1.4409316000000003, 43.697152900000006]],
    [ "Enedis",[1.4165861499999994, 43.696274849999995]],
    [ "Enedis",[1.4410233000000008, 43.694052750000004]],
    [ "Enedis",[1.4429296845134703, 43.694353953468344]],
    [ "Enedis",[1.4457274999999998, 43.695779849999994]],
    [ "Enedis",[1.464960702672987, 43.67720238423201]],
    [ "CAZALBARBIER 72",[1.4584964571980286, 43.68051197850007]],
    [ "Enedis",[1.4171699840766752, 43.69315650367192]],
    [ "Enedis",[1.4457796520854422, 43.67410361228833]],
    [ "Enedis",[1.4502785, 43.681179549999996]],
    [ "Enedis",[1.43938785, 43.68363195]],
    [ "Enedis",[1.428999830945973, 43.67798004339625]],
    [ "Enedis",[1.4341602999999987, 43.6752752]],
    [ "Enedis",[1.42919145, 43.6671675]],
    [ "Enedis",[1.4214571342180495, 43.68108345133698]],
    [ "Enedis",[1.37806215, 43.7135949]],
    [ "Enedis",[1.3875925292234474, 43.724927941163735]],
    [ "Enedis",[1.3959655654715204, 43.72100982149898]],
    [ "Enedis",[1.3957890813925133, 43.71921063914262]],
    [ "Enedis",[1.39314005, 43.71721585]],
    [ "Enedis",[1.394025616748485, 43.7154394541182]],
    [ "Enedis",[1.3976144499999998, 43.7156438]],
    [ "Enedis",[1.3910149950023711, 43.712739268184265]],
    [ "Enedis",[1.4891959080770636, 43.68721273768148]],
    [ "Enedis",[1.4798092021129137, 43.68144553231543]],
    [ "Enedis",[1.5079500334516822, 43.520436443505616]],
    [ "Enedis",[1.513267549999999, 43.519683349999994]],
    [ "Enedis",[1.4622002000000012, 43.515066950000005]],
    [ "Enedis",[1.5460823, 43.5205985]],
    [ "Commune d'Escalquens P 38 avenue de Toulouse",[1.5480920902368707, 43.515999366327875]],
    [ "Enedis",[1.5433286842794558, 43.517879973548936]],
    [ "Enedis",[1.5433482836473942, 43.51783022390421]],
    [ "Enedis",[1.5422795666048297, 43.52023395017105]],
    [ "Enedis",[1.5354227400367753, 43.518072634020456]],
    [ "Enedis",[1.533124349999999, 43.520757399999994]],
    [ "Enedis",[-4.572151200000001, 48.48964450000001]],
    [ "SER",[7.641938127689092, 48.723180030197646]],
    [ "Grisonnerie",[-1.6689738189251002, 48.145673722298454]],
    [ "Enedis",[-1.5450970499999985, 48.18890115000001]],
    [ "Enedis",[1.4025252050571055, 43.75181201815025]],
    [ "Enedis",[2.936710257846897, 50.55249169082729]],
    [ "Enedis",[1.2838444, 44.19059035]],
    [ "Enedis",[1.406627677965622, 43.71315859090137]],
    [ "Enedis",[1.4171169330851894, 43.704905000758615]],
    [ "Enedis",[-1.6192690253193174, 43.341876241716854]],
    [ "Enedis",[-1.623208908299702, 43.34464402148856]],
    [ "Enedis",[-1.6283627065930006, 43.35717568387697]],
    [ "Enedis",[-1.6254222724144956, 43.35105609149556]],
    [ "Poste de transformation HTA/BT",[7.6151050871505115, 48.97858687831875]],
    [ "Enedis",[-1.5503523378083426, 43.354148379282684]],
    [ "Enedis",[-1.5513378838639993, 43.3561986296588]],
    [ "Enedis",[-1.5442733499999999, 43.35419654999999]],
    [ "Enedis",[-1.5417426500000002, 43.35563265]],
    [ "Enedis",[-1.5476205, 43.3558336]],
    [ "Enedis",[-1.5477975499999999, 43.35839025000001]],
    [ "Enedis",[-1.5492657000000003, 43.356294999999996]],
    [ "Enedis",[1.464359234017156, 43.70987224801846]],
    [ "Enedis",[1.4651817660008732, 43.70686234686919]],
    [ "Enedis",[1.4879393238956466, 43.69668149590439]],
    [ "Enedis",[1.481650799999999, 43.696424650000004]],
    [ "Enedis",[1.4717967167582326, 43.701930152175784]],
    [ "Enedis",[1.4707759315670288, 43.6988105085145]],
    [ "Enedis",[1.4693237335999412, 43.69705724476117]],
    [ "Enedis",[1.46541805, 43.6979689]],
    [ "Enedis",[-1.5679612714651134, 43.35856385786208]],
    [ "Enedis",[-1.5813122000000002, 43.361247999999996]],
    [ "Enedis",[-1.5766828151724899, 43.35865693037185]],
    [ "Enedis",[-1.5798181465022922, 43.36070351791866]],
    [ "Enedis",[-1.5780549000000008, 43.36087695000001]],
    [ "Poste électrique de Valabres",[7.093143056841467, 44.125875167145786]],
    [ "Enedis",[0.24226911048434474, 49.6609019262676]],
    [ "Enedis",[1.5148547239712604, 43.68710791029827]],
    [ "Enedis",[1.4935073821733564, 43.6852597528489]],
    [ "Enedis",[1.5792183, 43.67727055]],
    [ "Enedis",[1.576745516064944, 43.680766079426384]],
    [ "Enedis",[1.5787250500000005, 43.6816956]],
    [ "Enedis",[1.5813714419498113, 43.679769733438036]],
    [ "Enedis",[1.5868817499999996, 43.67650569999999]],
    [ "Enedis",[1.5830296499999996, 43.67917515]],
    [ "Enedis",[-1.4614001184686771, 48.37802779642106]],
    [ "Enedis",[-1.4659393011862925, 48.37370334658757]],
    [ "Enedis",[1.3882715358174573, 50.03079096919873]],
    [ "Enedis",[1.3921156540221264, 43.74511099769807]],
    [ "Moulin du Pré",[5.79262615, 47.174249]],
    [ "Enedis",[6.127440060176396, 48.56066676944479]],
    [ "Enedis",[6.184857849999999, 48.5598757]],
    [ "Enedis",[6.185093049999999, 48.57488030000001]],
    [ "Enedis",[6.182485879704164, 48.574359574956524]],
    [ "Enedis",[6.1792679469971805, 48.57710786340185]],
    [ "Enedis",[6.1978629, 48.793642899999995]],
    [ "Enedis",[6.193181393119465, 48.793075028868856]],
    [ "Enedis",[6.195812135867424, 48.79009397869431]],
    [ "Enedis",[6.1894623657995735, 48.78919344517186]],
    [ "Enedis",[6.154394442367017, 48.79444567488681]],
    [ "Enedis",[6.14992115, 48.794620099999996]],
    [ "Enedis",[1.4214757122606536, 43.72531026809509]],
    [ "Enedis",[1.4237848338934953, 43.717717752299265]],
    [ "Enedis",[1.4311223000000002, 43.71386329999999]],
    [ "Enedis",[1.4321726000000001, 43.72500825]],
    [ "Enedis",[1.4290224999999994, 43.72408340000001]],
    [ "Enedis",[1.427554165863197, 43.717002253097995]],
    [ "Enedis",[1.4321525291924577, 43.71580656280019]],
    [ "Enedis",[1.4324921924331315, 43.723362833868094]],
    [ "Enedis",[1.4320406999999997, 43.721198449999996]],
    [ "Enedis",[-0.9945668050343601, 43.85122291591716]],
    [ "Enedis",[-0.9971152179793967, 43.852546204545575]],
    [ "Enedis",[-0.9945508000000002, 43.849195449999996]],
    [ "Enedis",[-0.9919644789530708, 43.850793522410235]],
    [ "Enedis",[-0.9933692000000001, 43.8541867]],
    [ "Enedis",[1.5182522985539617, 49.958698258424796]],
    [ "Chantier Fosse Courbée",[-1.6713691000000002, 48.1212511]],
    [ "Enedis",[-1.6749453500000002, 48.11744385]],
    [ "Enedis",[-0.4742292772299945, 43.783361356940375]],
    [ "Enedis",[-0.46447235000000064, 43.78477710000001]],
    [ "Jardins de Phalsbourg",[6.183461849999999, 48.68286894999999]],
    [ "Enedis",[-0.43125729999999995, 43.7771574]],
    [ "La Charbonnière",[-1.6574298600806279, 48.15060731985846]],
    [ "Enedis",[5.02988075, 47.66732199999999]],
    [ "Enedis",[1.674636618015685, 49.915157215548874]],
    [ "Enedis",[-3.320048282924946, 47.732151214705546]],
    [ "Enedis",[-3.314402243360202, 47.72974830276827]],
    [ "Enedis",[-3.3215806000000008, 47.72622165]],
    [ "Enedis",[4.43165705, 43.45292849999999]],
    [ "Enedis",[4.431642482979663, 43.456358504975775]],
    [ "Enedis",[4.42823955, 43.45716675]],
    [ "Enedis",[1.6360133466699516, 49.695384761125844]],
    [ "Enedis",[1.134761606150594, 45.79701816848031]],
    [ "Enedis",[1.4728542, 43.536787649999994]],
    [ "Enedis",[1.4081538464788337, 43.532519003017136]],
    [ "Enedis",[1.4623588500000007, 43.51138675]],
    [ "Enedis",[1.4515536682747805, 43.50843032708964]],
    [ "Enedis",[1.4436286000000003, 43.51015580000001]],
    [ "Enedis",[1.4951704999999995, 43.53124125000001]],
    [ "Enedis",[1.4896384, 43.531211649999996]],
    [ "Enedis",[1.4902885500000003, 43.52946855]],
    [ "La Verne",[6.401462649999999, 43.234745200000006]],
    [ "Enedis",[0.8487155397071525, 49.81931839579741]],
    [ "Enedis",[0.8424735411874941, 49.81007402083676]],
    [ "Enedis",[0.8440044759933604, 49.818877762724355]],
    [ "Enedis",[-1.496125057690245, 43.500934377962615]],
    [ "Enedis",[-1.4945514500000001, 43.499717]],
    [ "Enedis",[-1.4999854999999997, 43.51225324999999]],
    [ "Enedis",[1.5869658499999995, 43.526012900000005]],
    [ "Enedis",[1.5639496159987516, 43.51059477685856]],
    [ "Enedis",[1.562788916196334, 43.51366010139894]],
    [ "Enedis",[1.5517869, 43.516142800000004]],
    [ "Enedis",[1.5495065860818569, 43.523007381362845]],
    [ "Enedis",[1.5532357500000011, 43.523261899999994]],
    [ "Enedis",[1.5532890936246346, 43.575890281708546]],
    [ "Enedis",[1.5049297, 43.576864]],
    [ "Enedis",[1.5181619567058893, 43.59877017113626]],
    [ "Enedis",[1.52636125, 43.61174905]],
    [ "Enedis",[1.5224046195864112, 43.648482006177034]],
    [ "Enedis",[1.535790973036236, 43.64473352652938]],
    [ "Enedis",[4.98459565, 45.73090415]],
    [ "ZAC Saint-Hubert",[5.233617950000001, 45.61359355000001]],
    [ "Auxibat",[5.228803431790492, 45.61407958177575]],
    [ "Enedis",[1.759474664462351, 48.782376324070285]],
    [ "Enedis",[1.3043438319835605, 47.64955148342561]],
    [ "Enedis",[1.3063004305201258, 47.648787514363946]],
    [ "Mairie Illzach",[7.346289292762317, 47.78166372957482]],
    [ "Parc des Oeillets",[7.37310610531086, 47.753711582768744]],
    [ "Enedis",[-1.5229523, 43.510601699999995]],
    [ "Enedis",[-1.5248782327714698, 43.51823480475711]],
    [ "Enedis",[-1.538365381406282, 43.49808181442818]],
    [ "Enedis",[-1.5290852743760086, 43.49419519686954]],
    [ "Carrière",[5.2400434, 45.61739135]],
    [ "Médiathèque",[5.218744053686478, 45.62075200993892]],
    [ "Salle Polyvalente",[5.230680588095898, 45.62184245850306]],
    [ "Enedis",[2.488331060701041, 48.03187138332471]],
    [ "Enedis",[1.5203591666666667, 43.67365254385151]],
    [ "Enedis",[1.514915049169165, 43.67140891647925]],
    [ "Enedis",[1.5171696, 43.67137785]],
    [ "Enedis",[1.5209726999999997, 43.655635950000004]],
    [ "Enedis",[1.5132685801428871, 43.66791160235705]],
    [ "Enedis",[3.1146655313557123, 45.75258227836847]],
    [ "Enedis",[2.8089420499999997, 49.432829999999996]],
    [ "Enedis",[2.8235456443205464, 49.42436533092581]],
    [ "Enedis",[1.1399429855758187, 45.79759281999491]],
    [ "Enedis",[1.5350566205190905, 43.68309329147733]],
    [ "Enedis",[1.538691366375991, 43.68118075055454]],
    [ "Enedis",[1.5442397499999998, 43.6738501]],
    [ "Enedis",[1.5394238, 43.67832469999999]],
    [ "Driève",[5.14415035, 45.61528915000001]],
    [ "Enedis",[1.04472385, 43.0332974]],
    [ "Enedis",[1.04653805, 43.03435205]],
    [ "Enedis",[1.0568941000000005, 43.03493599999999]],
    [ "Enedis",[1.137525, 42.99260565]],
    [ "Enedis",[1.1393031499999997, 42.99659289999998]],
    [ "Enedis",[1.142475992783476, 42.99190498454015]],
    [ "Enedis",[1.1405809033874046, 42.986674821904124]],
    [ "Enedis",[2.8211043096621675, 49.42167803194334]],
    [ "Enedis",[-3.3753414, 48.810792600000006]],
    [ "Enedis",[1.2936178327403445, 43.77513574483914]],
    [ "Jemblerie",[-1.1268972499999999, 48.76770415]],
    [ "Arcueil",[2.3343684499999973, 48.82105385]],
    [ "Enedis",[6.365496285671688, 46.88780037630478]],
    [ "Poste électrique du Castellet",[5.7238573990152295, 43.250695017974515]],
    [ "Enedis",[5.200402126030223, 43.37715144275842]],
    [ "Enedis",[-1.5341581893731586, 43.48987909165905]],
    [ "Enedis",[-1.5307519500000002, 43.492267049999995]],
    [ "Enedis",[-1.5334118676295287, 43.48555545518341]],
    [ "Enedis",[-1.52604937054435, 43.48076230959963]],
    [ "Enedis",[-1.5183828361148617, 43.47668891660025]],
    [ "Enedis",[-1.3954575780562868, 43.78592144313775]],
    [ "Enedis",[-1.4011581499999999, 43.78408139999999]],
    [ "Saint-Georges",[1.3939232726183042, 44.0104228962335]],
    [ "Domaine de Maury - Negosaoumos",[1.3920814999999989, 44.010929649999994]],
    [ "Enedis",[1.545243266025772, 43.67545465234746]],
    [ "Enedis",[1.5507944499999988, 43.66615455]],
    [ "Enedis",[1.5621864598866477, 43.70592097465109]],
    [ "Enedis",[1.5596824499999993, 43.7554997]],
    [ "Enedis",[1.5568531499999994, 43.75850899999999]],
    [ "Enedis",[1.5538648341002874, 43.757467197621146]],
    [ "Bleury",[3.3930197, 47.84279700000001]],
    [ "Enedis",[3.400359568907332, 47.86978869837244]],
    [ "Enedis",[3.3517937434231366, 47.87570292055977]],
    [ "Enedis",[3.362036750000001, 47.876830950000006]],
    [ "Enedis",[3.3638706580670443, 47.87324655046878]],
    [ "Enedis",[3.3585037812862018, 47.874527855734776]],
    [ "Raffine",[1.3935879500000001, 44.00855069999999]],
    [ "Enedis",[2.4873769500000003, 43.47809649999999]],
    [ "Enedis",[5.151772604221636, 47.195632329375556]],
    [ "Croix des Moulins",[5.2758286905707195, 47.32798637783292]],
    [ "Enedis",[5.153553900000001, 47.19203060000001]],
    [ "Enedis",[5.146772650000001, 47.197993249999996]],
    [ "Enedis",[5.150750449999999, 47.1926019]],
    [ "Enedis",[1.5454899567234954, 43.719105247115635]],
    [ "Enedis",[1.5755577087693569, 43.71329313130201]],
    [ "Enedis",[1.5841889, 43.71973439999999]],
    [ "Enedis",[1.5840219978588426, 43.71920773272536]],
    [ "Enedis",[1.5700364070023698, 43.71934692341757]],
    [ "Enedis",[1.5815193999999997, 43.725841700000004]],
    [ "Enedis",[5.220260142379131, 47.25629507123467]],
    [ "Enedis",[5.222126455551672, 47.252602080455134]],
    [ "Maritelle",[5.741297485732567, 45.13867797730751]],
    [ "Enedis",[1.2971530000000004, 43.7713095]],
    [ "Enedis",[1.286563802099043, 43.77399368265938]],
    [ "Enedis",[-0.9754755722672818, 43.198972295005824]],
    [ "Enedis",[-0.9609480424502297, 43.194032821786635]],
    [ "Enedis",[1.0122576499999998, 47.398969699999995]],
    [ "Enedis",[1.5341745792362855, 43.79170120492592]],
    [ "Enedis",[1.5462433999999994, 43.77092905]],
    [ "Enedis",[1.5642133667543199, 43.80886519981434]],
    [ "Enedis",[1.56299245, 43.807196499999996]],
    [ "Enedis",[1.5728065999999998, 43.806168500000005]],
    [ "Enedis",[-0.5160250220342815, 43.65780810902382]],
    [ "Enedis",[-0.5198724, 43.66006065]],
    [ "Enedis",[-0.5117665595203177, 43.6558655628051]],
    [ "Enedis",[-0.5237548844220216, 43.65189285583933]],
    [ "Enedis",[-0.49837929999999997, 43.65170905]],
    [ "Enedis",[-0.49858340000000007, 43.648737499999996]],
    [ "Enedis",[-0.5041808999999999, 43.639002999999995]],
    [ "Enedis",[-0.498518725261771, 43.64044819384232]],
    [ "Enedis",[3.1668885170999186, 50.71106485790685]],
    [ "Néolia",[5.5594391000000005, 46.678028899999994]],
    [ "Enedis",[-1.0207849170536418, 43.446863398057545]],
    [ "Enedis",[-1.0242209635028148, 43.4559174626892]],
    [ "Enedis",[-1.0238812143657254, 43.45189612058009]],
    [ "Enedis",[4.998313050000001, 45.7310847]],
    [ "RTE",[2.5647132237595884, 43.46989600329504]],
    [ "Enedis",[0.6768476706694679, 43.07802825399486]],
    [ "Enedis",[0.6733863500000018, 43.08242885]],
    [ "Enedis",[1.7082843471938804, 49.675803933163856]],
    [ "Pompiers",[4.400305250000001, 45.2851667]],
    [ "Enedis",[-0.13672790000000004, 43.395153]],
    [ "Enedis",[-1.2671659430717488, 43.58164367788788]],
    [ "Enedis",[2.48935865, 43.480601]],
    [ "Enedis",[3.1505435853268096, 50.65271923209335]],
    [ "Enedis",[1.7641036499999996, 43.576116049999996]],
    [ "Enedis",[1.8641933999999993, 43.574621099999995]],
    [ "Enedis",[1.8600785499999999, 43.558475200000004]],
    [ "Enedis",[1.8282306823982664, 43.5661469519098]],
    [ "Enedis",[1.2938199499999992, 43.72725305000001]],
    [ "Enedis",[1.2937764309034476, 43.72912482996873]],
    [ "Enedis",[1.3017107499999996, 43.728844349999996]],
    [ "Enedis",[1.2923709999999997, 43.75885354999999]],
    [ "Enedis",[1.8574304000000001, 43.512505700000006]],
    [ "Les Essarts",[-0.5069146999999999, 46.0849256]],
    [ "Enedis",[1.846469048287753, 43.678657566257144]],
    [ "Enedis",[0.1553880667748178, 45.6516916380202]],
    [ "Enedis",[1.7201736499999998, 43.7100717]],
    [ "Enedis",[-0.37868875, 43.362849649999994]],
    [ "Enedis",[-0.3771154730704471, 43.353096787610795]],
    [ "Enedis",[1.7539991499999998, 43.65009034999999]],
    [ "Enedis",[-0.3594603975211536, 43.36986631745323]],
    [ "Enedis",[-0.38128019026327364, 43.37765208782108]],
    [ "Enedis",[3.2398593606450037, 50.16781372261892]],
    [ "Enedis",[1.6961423545084957, 43.76176943322353]],
    [ "Enedis",[-1.2660326, 43.57156355]],
    [ "Enedis",[-1.2640383999999998, 43.57292970000001]],
    [ "Enedis",[-1.26077875, 43.57814905000001]],
    [ "Enedis",[-1.2707648880622135, 43.57398121725185]],
    [ "Enedis",[-1.2739183948171788, 43.57717606855663]],
    [ "Enedis",[-1.2670622107699199, 43.586428574803264]],
    [ "Enedis",[-1.2732250812811505, 43.584548844510834]],
    [ "Enedis",[-0.9175891779871599, 43.65170544688244]],
    [ "Enedis",[-0.9150027999999998, 43.65075229999999]],
    [ "Enedis",[1.863103392165419, 47.2745672462598]],
    [ "Enedis",[-0.4003699572832211, 49.193200524275646]],
    [ "Enedis",[3.8736796499999997, 43.950663649999996]],
    [ "Enedis",[4.252764424957147, 45.29517552190607]],
    [ "Enedis",[-0.908931866350016, 43.670200297638324]],
    [ "Enedis",[-0.9317034999999999, 43.68312885]],
    [ "Enedis",[-0.9190363000000008, 43.68408829999999]],
    [ "Enedis",[-0.9024466574545836, 43.672996469466206]],
    [ "Enedis",[-0.9138709000000009, 43.6655369]],
    [ "Enedis",[-0.9131130858882931, 43.66784242460626]],
    [ "Enedis",[-0.9374574, 43.686304050000004]],
    [ "Enedis",[-1.2534612500000002, 43.62471855]],
    [ "Enedis",[-1.2602029999999986, 43.62503675000001]],
    [ "Enedis",[-1.26368135, 43.6234047]],
    [ "Enedis",[1.612110590544004, 47.78533098865486]],
    [ "Saint-Bonnet",[5.164826950135062, 45.60278436663065]],
    [ "Enedis",[-1.220624923949054, 43.64119879290058]],
    [ "Enedis",[-1.2411149054205974, 43.64011718187932]],
    [ "Lescot",[5.278105421823815, 45.587190224568396]],
    [ "Enedis",[-1.2010810500000002, 43.629030699999994]],
    [ "Enedis",[1.7327341500000002, 43.81594715]],
    [ "Enedis",[1.744084470324633, 43.79387935916898]],
    [ "Enedis",[-0.5915428842755853, 44.81755441735133]],
    [ "Enedis",[-0.5857267499999992, 44.81737975]],
    [ "Enedis",[2.1185349768063153, 42.52705773020731]],
    [ "Enedis",[2.121456719226448, 42.52511559907395]],
    [ "Seguin",[5.237034051149843, 45.59589259047884]],
    [ "Serres",[5.23843765, 45.59520855]],
    [ "Enedis",[-1.5340912000000002, 43.45536800000001]],
    [ "Enedis",[-1.5331131499999995, 43.458110399999995]],
    [ "Enedis",[-1.126223, 43.61430425]],
    [ "Enedis",[-1.1255886849606855, 43.61347673319548]],
    [ "Enedis",[-1.1240175000000001, 43.61063655]],
    [ "Enedis",[1.1443093140924452, 42.98284957853338]],
    [ "Enedis",[1.1438703958332044, 42.984452216743314]],
    [ "Enedis",[1.147351499999999, 42.9842065]],
    [ "Enedis",[1.1512296420896089, 42.98202662864031]],
    [ "Poste source d'Ormes",[3.9755077053694383, 49.23706246810337]],
    [ "Enedis",[1.929694729347556, 47.94048012566495]],
    [ "Enedis",[-1.1365412119805978, 43.68806767670236]],
    [ "Enedis",[-1.1486932715305564, 43.680597953445265]],
    [ "Enedis",[-1.1443040544147518, 43.67879858483753]],
    [ "Enedis",[-1.1399688000000001, 43.679016250000004]],
    [ "Enedis",[-1.134857246435154, 43.68024921730991]],
    [ "Enedis",[-1.1333913999999998, 43.682463549999994]],
    [ "Enedis",[-1.19067115, 43.666502699999995]],
    [ "Enedis",[-1.1860286092790442, 43.67441603165832]],
    [ "Enedis",[-1.1934240633485118, 43.669504030685864]],
    [ "Enedis",[2.197542289476604, 48.683053883612594]],
    [ "Enedis",[2.5101830500000015, 43.47355995]],
    [ "Enedis",[0.46173235086500447, 47.8666034584389]],
    [ "Enedis",[0.7009720333019351, 47.38911869589986]],
    [ "Enedis",[-0.8266443450460321, 43.20672970199376]],
    [ "Enedis",[4.143636289523253, 49.161616592142494]],
    [ "Enedis",[4.148629342253403, 49.160784567410005]],
    [ "Enedis",[4.142520022039668, 49.15984258054448]],
    [ "Enedis",[4.147842403573377, 49.15741899768456]],
    [ "Enedis",[4.1397853265109825, 49.15850176260657]],
    [ "Enedis",[-1.0587513332721588, 43.59824874753007]],
    [ "Enedis",[-1.0673557500000006, 43.60272440000001]],
    [ "Enedis",[-1.0672339999999998, 43.59522605]],
    [ "Enedis",[-1.10819245, 43.59130880000001]],
    [ "Enedis",[-1.12933305, 43.59318330000001]],
    [ "Enedis",[-1.1137159499999998, 43.591170149999996]],
    [ "PA Paris 2",[2.248032591666667, 48.71699942916666]],
    [ "Enedis",[1.7179309057585246, 43.82144106961685]],
    [ "Enedis",[1.724113089042351, 43.82277380526631]],
    [ "Enedis",[1.72755505, 43.82292685]],
    [ "Enedis",[1.7190232499999998, 43.81639605]],
    [ "Enedis",[1.6788106388520359, 43.7861346737525]],
    [ "Enedis",[1.6797940695254006, 43.77359617230126]],
    [ "Enedis",[1.6672732499999998, 43.77246364999999]],
    [ "Enedis",[1.6749750499999991, 43.77526635]],
    [ "Enedis",[1.67117755, 43.77498295]],
    [ "Enedis",[4.7895187, 45.633781250000006]],
    [ "Enedis",[4.7865131000000005, 45.6367841]],
    [ "Enedis",[-0.69256265, 43.259482750000004]],
    [ "Enedis",[-0.6884489944204241, 43.25980888340539]],
    [ "Enedis",[4.332387399999999, 44.42866135]],
    [ "Enedis",[3.133422778693881, 50.66946394256724]],
    [ "Place des Droits de l'Homme",[5.2757348, 45.58688119999999]],
    [ "Enedis",[-1.005320177111707, 43.68573220619242]],
    [ "Enedis",[-0.9916610999999992, 43.67075735]],
    [ "Enedis",[-0.9926437525725434, 43.683672183097755]],
    [ "EDF",[0.16728317455439784, 47.224013805929395]],
    [ "EDF",[0.16759349053806508, 47.224783701440096]],
    [ "Enedis",[2.4574728499573313, 47.99015863335013]],
    [ "Enedis",[-0.9686777332352327, 43.7098965497451]],
    [ "Enedis",[-0.9864025000000002, 43.711417]],
    [ "Enedis",[-0.9826276609544439, 43.710176627201406]],
    [ "Enedis",[-0.9769091624006373, 43.71204268116402]],
    [ "Enedis",[-0.9973493191204785, 43.712063643088804]],
    [ "Enedis",[-1.014750039214106, 43.70948397673983]],
    [ "Enedis",[-1.0132655000000002, 43.710306700000004]],
    [ "ORMADI",[-1.583851451349874, 43.311522844010526]],
    [ "Enedis",[2.426025450000001, 45.571198499999994]],
    [ "Enedis",[-1.51679255, 43.48342505]],
    [ "Enedis",[1.827208266106679, 43.80148135428287]],
    [ "Enedis",[1.8323702544289662, 43.798598830846224]],
    [ "Poste électrique du Petit-Bois",[2.2049053887735477, 48.59748868311407]],
    [ "Enedis",[0.7323502271947383, 47.91527431603659]],
    [ "Enedis",[0.7464444982903807, 47.911650064357495]],
    [ "625941186",[0.7496873952424712, 47.92099964742371]],
    [ "Enedis",[0.7461923761533669, 47.917831800118165]],
    [ "Enedis",[0.7457571842725993, 47.92427297718958]],
    [ "Enedis",[-1.4005294500000012, 43.49856884999999]],
    [ "Enedis",[-1.3999484575313978, 43.510971331904614]],
    [ "Enedis",[-1.4178970041597652, 43.51373102967475]],
    [ "Enedis",[-1.4169266865677514, 43.49831767669487]],
    [ "Enedis",[-1.3844591499999999, 43.498027750000006]],
    [ "Enedis",[-1.3924548072807736, 43.49788167124974]],
    [ "Enedis",[-1.3483709499999996, 43.5015316]],
    [ "Enedis",[-1.3660520179468805, 43.49691344620467]],
    [ "S1TR",[2.255663722389328, 48.71312669589601]],
    [ "Enedis",[-1.3873458, 43.4860398]],
    [ "Enedis",[-1.3840837979637883, 43.48904276732644]],
    [ "Enedis",[-1.3823699500000004, 43.49043975]],
    [ "RTE",[2.2217324041765965, 42.788632530677184]],
    [ "Enedis",[0.819310868175444, 49.79567968171624]],
    [ "Enedis",[-1.3585485206811017, 43.48729419641918]],
    [ "Enedis",[-1.33381785, 43.484946050000005]],
    [ "Enedis",[4.953672249999999, 44.85736944999999]],
    [ "Enedis",[1.4991904336688384, 43.599164400581714]],
    [ "Enedis",[-1.397880496552845, 43.47310757565489]],
    [ "Enedis",[-1.3933792078283067, 43.471363065506914]],
    [ "Enedis",[-1.3895573896501723, 43.47254107717704]],
    [ "Enedis",[-1.3926270757382633, 43.479158407311445]],
    [ "Enedis",[-1.39764025, 43.478807]],
    [ "Enedis",[-1.3981833500000014, 43.47721109999999]],
    [ "Enedis",[-1.4154252500000002, 43.46844080000001]],
    [ "Poste électrique des Mélincols",[5.861277462858014, 46.94830300030541]],
    [ "Enedis",[-1.4409736310067645, 43.46194565335152]],
    [ "Enedis",[-1.442853814150862, 43.46922282042378]],
    [ "Enedis",[-1.4364521500000003, 43.466327650000004]],
    [ "Enedis",[-1.4389311352341758, 43.46438700567236]],
    [ "Enedis",[-1.44097935, 43.46667085]],
    [ "Enedis",[-1.4420062499999995, 43.46578375]],
    [ "Enedis",[3.011971352671878, 50.63392862456685]],
    [ "Enedis",[-1.327561041929258, 43.46053217879507]],
    [ "Enedis",[-1.3532868, 43.45884275000001]],
    [ "Enedis",[-1.358526192809093, 43.45554372242712]],
    [ "Enedis",[-1.2995580951447112, 43.494144730698444]],
    [ "Enedis",[-1.2982253, 43.49243275]],
    [ "Enedis",[-1.2948998297331897, 43.4899299098861]],
    [ "Enedis",[-1.284037488635302, 43.49295967595246]],
    [ "Enedis",[-1.2877699574552246, 43.49155908360681]],
    [ "Enedis",[-1.2962345827433377, 43.485633595044035]],
    [ "Siton Guidon",[3.025798627189661, 50.66418350667478]],
    [ "Simon Vollant",[3.027384165521164, 50.66525893436008]],
    [ "Enedis",[-1.7717997896700142, 43.34324681995749]],
    [ "Enedis",[-1.0595527677273242, 43.33982909851724]],
    [ "Enedis",[-1.0394866444986266, 43.3291237199778]],
    [ "Enedis",[-1.041564150000002, 43.33302975000001]],
    [ "Poste électrique de Justice",[1.6594966718893422, 48.323913021665106]],
    [ "Poste électrique de Chartres-sud",[1.4498557913664445, 48.41480821186258]],
    [ "Les Aires",[6.195197025080026, 46.01686072259673]],
    [ "Perrelas",[5.142205266419516, 45.73171386694643]],
    [ "Enedis",[1.5875682065415402, 49.50248083220168]],
    [ "Enedis",[-1.10832435, 43.51274295]],
    [ "Enedis",[-0.8649013121175488, 44.550729376023945]],
    [ "Enedis",[-0.8761321982733393, 44.54629940729295]],
    [ "Enedis",[-1.2804400157908, 43.67057839795841]],
    [ "Enedis",[-1.2910927657653537, 43.67026357571291]],
    [ "Enedis",[2.0159636000687717, 47.95387947908756]],
    [ "SRD",[0.17222608599806205, 46.647639631443795]],
    [ "Enedis",[-0.6238959444740296, 43.375766032153024]],
    [ "Enedis",[1.5154498885877399, 49.465172572685155]],
    [ "Enedis",[1.9875919257284034, 46.56628014685613]],
    [ "Enedis",[-0.6245581000000003, 43.409692799999995]],
    [ "Enedis",[-0.6240301000000006, 43.40979845]],
    [ "Enedis",[-0.6169932499999997, 43.412059899999996]],
    [ "Enedis",[-0.5972081825686476, 43.406031065384944]],
    [ "Enedis",[-0.60557075, 43.40903080000001]],
    [ "Enedis",[-0.6329534197452834, 43.41701110824989]],
    [ "Enedis",[-1.1610841, 44.39608940000001]],
    [ "Enedis",[-1.1641523000000003, 44.39514755]],
    [ "Enedis",[6.573493426493007, 46.06465565888922]],
    [ "Enedis",[4.558057779427488, 49.250866470808795]],
    [ "Enedis",[2.516493789128032, 48.81101758273827]],
    [ "F Ameco",[2.3689307887722113, 48.76847257669363]],
    [ "Or Mairie",[2.1849254, 48.6983319]],
    [ "Enedis",[0.8859890820467434, 47.391936131644904]],
    [ "Enedis",[-0.34712931594915525, 43.474246351554044]],
    [ "Enedis",[5.487776033322512, 47.997452699706244]],
    [ "Enedis",[5.487606090363992, 47.995356684051906]],
    [ "Enedis",[-0.6852902755764865, 43.45577370753091]],
    [ "Enedis",[-0.6838758774270682, 43.45887059282639]],
    [ "Enedis",[-0.7039254500000001, 43.466785249999994]],
    [ "Enedis",[-0.7025906000000005, 43.4654674]],
    [ "Pont Resal",[-1.534395925261499, 47.21330639304153]],
    [ "Enedis",[0.18489891650314896, 45.65921248944719]],
    [ "Enedis",[-0.55672235, 43.389939150000004]],
    [ "Enedis",[-0.566314762585768, 43.40038611909104]],
    [ "Enedis",[-0.5688827289371742, 43.4027105342639]],
    [ "Enedis",[-0.566899967531841, 43.401984125737336]],
    [ "Enedis",[-0.5655579182672957, 43.40619446591725]],
    [ "Enedis",[-0.56922017821049, 43.40582025382152]],
    [ "Enedis",[-0.5736057766948507, 43.40420789539803]],
    [ "Enedis",[-0.57193615, 43.4021625]],
    [ "Enedis",[1.6314230906206897, 45.54923536375337]],
    [ "Enedis",[0.8604846, 47.36525275]],
    [ "Enedis",[1.6536241597112282, 45.54143707654484]],
    [ "Poste source de Montceau",[1.8400708259986382, 45.57524154972639]],
    [ "Enedis",[1.8405444824830988, 45.575961415606194]],
    [ "GRPH",[1.8140195908993906, 45.553657333114764]],
    [ "Enedis",[-1.2762597217126896, 43.559220395074696]],
    [ "Enedis",[0.8635685562653945, 47.38979952455612]],
    [ "Enedis",[-1.7466915824256746, 48.117466299649536]],
    [ "Enedis",[-1.771497344218289, 48.12084224126844]],
    [ "Enedis",[0.3469467902881627, 49.69590711288083]],
    [ "Enedis",[-1.3158418276373092, 43.54391754528496]],
    [ "Enedis",[-1.3081007999999998, 43.55187505]],
    [ "Enedis",[-1.3618621, 43.57090115]],
    [ "Enedis",[-1.3601837588886379, 43.56874006586562]],
    [ "Enedis",[-1.36200705, 43.56490970000001]],
    [ "Enedis",[-1.370134437399054, 43.54100392384032]],
    [ "Enedis",[-1.20035895, 43.508691349999985]],
    [ "Enedis",[-1.2138343500000002, 43.50557425]],
    [ "Enedis",[-1.2109082500000001, 43.51561434999999]],
    [ "Enedis",[4.82129042595029, 45.09424557985416]],
    [ "Enedis",[-1.7488413337754494, 48.1199176391583]],
    [ "Enedis",[-1.725701674850165, 48.116834355375936]],
    [ "Enedis",[-0.9719393, 43.75203260000001]],
    [ "Enedis",[0.8452310120402704, 47.3896724864108]],
    [ "Enedis",[-1.7473105738465526, 48.12105843384899]],
    [ "Enedis",[-1.6614397561457697, 48.14534962139181]],
    [ "Enedis",[0.8420342509093309, 47.389608755086364]],
    [ "Enedis",[-1.0619163, 43.1984581]],
    [ "Enedis",[-1.06427225, 43.19397035]],
    [ "Enedis",[-1.0673065800171646, 43.20164619272337]],
    [ "Enedis",[-1.0865683161004047, 43.20458892765215]],
    [ "Enedis",[-1.09486925, 43.233753899999996]],
    [ "Enedis",[-1.7300206104787712, 48.120148036193925]],
    [ "Enedis",[-1.7338682853408027, 48.11789999747899]],
    [ "Enedis",[-0.879709600000001, 43.2411314]],
    [ "Aouye",[-1.5108317690395738, 43.48351917467898]],
    [ "Enedis",[3.3319923763970403, 45.91584620292807]],
    [ "Enedis",[6.266580952556641, 46.17705034947476]],
    [ "Enedis",[-3.3459938736931916, 47.77128698325605]],
    [ "Enedis",[4.100550823808449, 47.13674006396031]],
    [ "Enedis",[0.8404834000000005, 47.384880200000005]],
    [ "Enedis",[-3.335406936750257, 47.770680566647115]],
    [ "Enedis",[0.8419663511859263, 47.386792242342416]],
    [ "Poste source de Moru",[2.675719097027232, 49.31062338570253]],
    [ "Enedis",[0.8395819499999999, 47.38643820000001]],
    [ "Enedis",[-0.7099565499999998, 43.2554969]],
    [ "Enedis",[-0.7171131300189229, 43.26221880522656]],
    [ "Enedis",[-0.7107625733668099, 43.26543454155125]],
    [ "Enedis",[-0.7406513500000002, 43.27525334999999]],
    [ "Enedis",[0.8388739170541178, 47.38789936139987]],
    [ "Poste source",[1.952659363495015, 48.774106791680666]],
    [ "Enedis",[-1.656384890880214, 48.13306580495962]],
    [ "Enedis",[-1.6731848865047614, 48.12824236751439]],
    [ "Enedis",[-1.6721933386107415, 48.127322200989965]],
    [ "Termant",[5.15535595, 45.72562299999999]],
    [ "Lot Muriers",[5.1475493, 45.728197699999996]],
    [ "Mollard du clos",[5.1466261499999995, 45.73153065]],
    [ "Enedis",[0.835915234993272, 47.38904986525823]],
    [ "AC3M Stade",[5.744386150000001, 46.57568085]],
    [ "631888959",[3.9040972234535016, 47.48969494429745]],
    [ "Enedis",[-1.6866597393366247, 48.11265665202214]],
    [ "Enedis",[0.8343756239673001, 47.37776626049168]],
    [ "Enedis",[-0.9019101739439064, 43.767308155584026]],
    [ "Enedis",[-0.9008919434693785, 43.76396628402202]],
    [ "Enedis",[0.8313084757396455, 47.37795419026314]],
    [ "Enedis",[0.8312833954319817, 47.38021948233143]],
    [ "Enedis",[0.8295943869537677, 47.38097632690699]],
    [ "Enedis",[-1.6943493704224974, 48.135401252563355]],
    [ "Enedis",[-1.6895247743156687, 48.13269877206368]],
    [ "Enedis",[-1.6886253215832463, 48.1269780627468]],
    [ "632451511",[4.810588547455201, 46.31653361863008]],
    [ "632458776",[4.81001295, 46.3171202]],
    [ "632462876",[4.815324126893211, 46.314275709745665]],
    [ "632462877",[4.8132365375876205, 46.31770003185727]],
    [ "Enedis",[-1.0470566808491633, 43.804733291509784]],
    [ "Enedis",[-0.19739192204039555, 46.991974360951524]],
    [ "Enedis",[-0.2752408507488233, 47.39733804763366]],
    [ "Enedis",[-1.3311229638717723, 43.696137775912895]],
    [ "Enedis",[-1.200924629450392, 43.52155979435209]],
    [ "Enedis",[-1.6755590490602081, 48.159142634351205]],
    [ "Enedis",[-1.3326549709840276, 43.69469371277447]],
    [ "Enedis",[-1.3329706555354652, 43.68949663296836]],
    [ "Enedis",[3.1279034999999995, 50.6067643]],
    [ "Enedis",[-3.2998620747141074, 47.8011464319308]],
    [ "Enedis",[0.8898716037630197, 49.66173993261765]],
    [ "Enedis",[0.8987924491919416, 49.66631713989645]],
    [ "Enedis",[0.8934947209652511, 49.67141612755447]],
    [ "Enedis",[3.1103819000000006, 45.84985615]],
    [ "Enedis",[3.106156447056408, 45.85041571327889]],
    [ "Enedis",[3.1096874408781696, 45.84637231793444]],
    [ "Enedis",[3.109994024848207, 45.836388392271516]],
    [ "Enedis",[3.0767236911280644, 45.82840631604403]],
    [ "Enedis",[3.0813142499999997, 45.8301942]],
    [ "Enedis",[3.077423128103845, 45.83359160688091]],
    [ "Enedis",[-0.9829290364180223, 43.629642116980904]],
    [ "Gendarmerie Auvergne",[3.105906080066635, 45.802204475940016]],
    [ "Enedis",[3.110013919920491, 45.80155050634344]],
    [ "Enedis",[1.9334500094939915, 47.819817323332444]],
    [ "Enedis",[-1.1573995000000001, 43.453882449999995]],
    [ "Enedis",[-1.1444818375568477, 43.47093442570921]],
    [ "Enedis",[-1.1449644319590662, 43.46717965180877]],
    [ "Groupe scolaire n°6",[5.143407000000002, 45.62325465]],
    [ "Les 3 Communes",[5.139165800000001, 45.6241604]],
    [ "Enedis",[-3.3462131009753655, 47.75762119665539]],
    [ "Enedis",[-3.3298096220750057, 47.725682683709586]],
    [ "Enedis",[2.3471692999999982, 47.07983265]],
    [ "Enedis",[-0.6079954170522665, 44.79762410268417]],
    [ "Haut perlic",[-0.38830605000000085, 43.34019644999999]],
    [ "Enedis",[-0.3826746961639301, 43.33564165675141]],
    [ "Enedis",[-0.3863453999999988, 43.33900965]],
    [ "Enedis",[-0.38830475507692197, 43.33888102174359]],
    [ "Enedis",[-0.38982115000000006, 43.33996929999999]],
    [ "Enedis",[-0.39482708272910444, 43.335951421413256]],
    [ "Enedis",[-0.6868349209147525, 44.75882749442545]],
    [ "Enedis",[-0.6972294923324412, 44.77454328334559]],
    [ "Enedis",[-0.6631997999999999, 44.78986175]],
    [ "Enedis",[-0.6615503826049404, 44.790387878671716]],
    [ "Enedis",[5.264885399999999, 44.75020070000001]],
    [ "Enedis",[0.8284008307861638, 47.38519354592281]],
    [ "Enedis",[2.00115145, 49.0614386]],
    [ "Enedis",[3.0940897123096045, 45.793104043770896]],
    [ "Enedis",[3.087835134279807, 45.79453077245578]],
    [ "Enedis",[3.0815678958363764, 45.79425778530152]],
    [ "Enedis",[3.0685912988141326, 45.79540937967893]],
    [ "Enedis",[3.0720747942847795, 45.78541727372867]],
    [ "Enedis",[3.0719808395706876, 45.78412040294714]],
    [ "Enedis",[3.0771981500000014, 45.784404499999994]],
    [ "Enedis",[3.080526381023733, 45.78830874754095]],
    [ "Enedis",[3.084384773550039, 45.78949896900344]],
    [ "Enedis",[3.088128786566637, 45.78894312470139]],
    [ "Enedis",[3.088667314139724, 45.791530416759564]],
    [ "Enedis",[3.091098098013544, 45.79157692865118]],
    [ "Enedis",[3.0953721471487277, 45.7880946726324]],
    [ "Enedis",[3.1015582622167757, 45.78777742580405]],
    [ "Enedis",[3.1044072444693023, 45.788723730552135]],
    [ "Enedis",[3.1050779045018917, 45.788052483952384]],
    [ "Enedis",[3.1046006742782324, 45.787115043142016]],
    [ "Enedis",[3.1041591558544694, 45.78700461884151]],
    [ "Enedis",[3.104150119939803, 45.78560263814704]],
    [ "Enedis",[3.103483104596888, 45.78502194933798]],
    [ "Enedis",[3.1038821478640153, 45.78471276239926]],
    [ "Enedis",[3.1030493381606132, 45.784189615885815]],
    [ "Enedis",[3.1019770040663763, 45.78330248295148]],
    [ "Enedis",[3.088123479376768, 45.781216581669035]],
    [ "Enedis",[3.0855848517593367, 45.780255599278384]],
    [ "Enedis",[3.08305196904299, 45.779624388464036]],
    [ "Enedis",[3.07953857631063, 45.7800039872844]],
    [ "Enedis",[3.0753071998287838, 45.780634105897455]],
    [ "Enedis",[1.9283893124769682, 47.923951539323504]],
    [ "Enedis",[3.1261275406179907, 45.77932518052906]],
    [ "Enedis",[3.1295542499999995, 45.7860993]],
    [ "Enedis",[3.1327769395357326, 45.78834989437032]],
    [ "Enedis",[-1.0427818491269252, 43.63206198305362]],
    [ "Enedis",[-1.0489806656642722, 43.64675896693201]],
    [ "Enedis",[3.1377345865499757, 45.78957183342976]],
    [ "Enedis",[3.143275343339165, 45.79695304503041]],
    [ "Enedis",[3.1570233294083, 45.799273109582316]],
    [ "Enedis",[3.1557311411618323, 45.800999341536524]],
    [ "Enedis",[3.1644767579884103, 45.79079356496808]],
    [ "Enedis",[3.167315449879612, 45.7915814294525]],
    [ "Enedis",[3.1749488, 45.77654104999999]],
    [ "Enedis",[3.1797654999999994, 45.776840899999996]],
    [ "Enedis",[3.1985346748094727, 45.77997684673919]],
    [ "Enedis",[3.2000027500000003, 45.77969885]],
    [ "Enedis",[3.2002702499999995, 45.777652249999996]],
    [ "Enedis",[3.2109431500000003, 45.778564149999994]],
    [ "Enedis",[3.21027092838207, 45.77588320914804]],
    [ "Enedis",[3.203249725972455, 45.76377123068732]],
    [ "Enedis",[3.1896263999999994, 45.767094549999996]],
    [ "Enedis",[-3.344384769556679, 47.761693189875295]],
    [ "Enedis",[4.288595241254125, 49.54401793333333]],
    [ "Enedis",[4.267334172584702, 49.56018685231355]],
    [ "Valencia",[5.244053531172792, 45.617225991739105]],
    [ "Lissieux",[5.246684516614972, 45.619350667527804]],
    [ "Belvédère",[5.2399196, 45.6197888]],
    [ "Aulne",[-0.5670083796172312, 47.49109727881302]],
    [ "Enedis",[0.8291072190100458, 47.386929330980486]],
    [ "Enedis",[-1.0758229499999998, 43.680421700000004]],
    [ "Enedis",[-1.0895069, 43.67563455]],
    [ "Enedis",[-1.07791735, 43.668650099999994]],
    [ "Enedis",[-1.0817764704736124, 43.66715508436607]],
    [ "Enedis",[-1.0780169211656276, 43.67478795340432]],
    [ "Enedis",[-1.0968137496781025, 43.67617453588901]],
    [ "Station de refoulement",[5.207065049278117, 45.6272583169269]],
    [ "Lassarat",[5.215754919774012, 45.62766482937853]],
    [ "Bord du Parc",[5.224005022675261, 45.619233574428]],
    [ "Enedis",[5.222512599999999, 45.6179963]],
    [ "Enedis",[-1.0435666836913833, 43.67191317642396]],
    [ "Enedis",[-1.0367852401428976, 43.66959706728034]],
    [ "SRD",[0.4639468836755873, 46.48919216669648]],
    [ "Enedis",[-0.50430485, 46.09859255]],
    [ "Enedis",[3.0748450729546137, 50.60004667469113]],
    [ "Enedis",[1.4754158543908442, 43.54232218319369]],
    [ "CSBJ",[5.250662915668273, 45.590869881927965]],
    [ "635449396",[1.8800693721990458, 47.94045176265032]],
    [ "Enedis",[3.141412782375701, 45.78596192712903]],
    [ "Enedis",[3.1345779606920194, 45.77809593598222]],
    [ "Enedis",[3.1309077064675868, 45.77445139554647]],
    [ "Enedis",[3.1349418013541683, 45.752928776006314]],
    [ "Enedis",[3.1386418337621698, 45.751529901520975]],
    [ "Enedis",[3.1484072611025438, 45.758673001690006]],
    [ "Enedis",[3.1463964524514156, 45.75944680625314]],
    [ "Enedis",[3.144618056567711, 45.75787027765142]],
    [ "Enedis",[3.155370971887948, 45.75622842887972]],
    [ "Enedis",[3.168521764686553, 45.74244720560525]],
    [ "Enedis",[3.1738117108516457, 45.73689360404291]],
    [ "Enedis",[3.1874752228919756, 45.72337026471385]],
    [ "Enedis",[3.2126385820140855, 45.7462275982664]],
    [ "Enedis",[3.966911806716961, 43.637189457605245]],
    [ "Enedis",[3.9563958507124948, 43.64088898367156]],
    [ "Enedis",[3.9643829138151303, 43.64076815279807]],
    [ "Enedis",[0.8394701370013767, 47.39116048632439]],
    [ "Enedis",[0.4879289555091995, 49.030367128046336]],
    [ "Enedis",[-2.0069382000000004, 47.938809299999996]],
    [ "J Jouvenet",[-1.6881719573916836, 48.09611778608753]],
    [ "Enedis",[4.70628998395905, 47.31435560567511]],
    [ "Poste source d'Étival",[6.870997521307825, 48.37990088626917]],
    [ "Enedis",[-1.7717080933088762, 43.34874386371635]],
    [ "Enedis",[-1.7696844999999999, 43.34769079999999]],
    [ "Enedis",[-1.7743086312456735, 43.3465899980412]],
    [ "Enedis",[-1.7673370183221013, 43.34849795385581]],
    [ "Enedis",[-1.7697514566635908, 43.34977182672637]],
    [ "Enedis",[-1.7740031000000005, 43.35350920000001]],
    [ "Citadelle",[2.3220318423498454, 48.809594627535624]],
    [ "Enedis",[-1.3944367499999994, 46.903636500000005]],
    [ "Enedis",[1.6489802000000002, 46.7834681]],
    [ "Enedis",[-1.7651496044679604, 43.35877951945914]],
    [ "Enedis",[-1.7670521499999987, 43.360046450000006]],
    [ "Enedis",[-4.536889286393197, 48.38346253379931]],
    [ "SRD",[0.06958895, 46.65730024999999]],
    [ "Tf.",[-0.8365044667133151, 43.779468050049914]],
    [ "Enedis",[-0.8521821812781055, 43.78389744262537]],
    [ "Enedis",[-0.82698085, 43.778453549999995]],
    [ "Enedis",[-0.8388833774458101, 43.785509603925014]],
    [ "Enedis",[-0.8586358564080638, 43.78391962632857]],
    [ "Enedis",[-0.8644934, 43.779359400000004]],
    [ "Enedis",[-0.8604976835672193, 43.78812717395425]],
    [ "Enedis",[2.2391543028717487, 48.58593242378567]],
    [ "Guinguette",[5.179163506227381, 45.62146927709076]],
    [ "Cula",[5.174149463980427, 45.62434341701417]],
    [ "Axell",[5.173943835306459, 45.62467003817373]],
    [ "Plasma Technic",[5.169516546569166, 45.626960562982006]],
    [ "Enedis",[0.830348641896984, 47.390225705626236]],
    [ "Enedis",[-0.874870116375317, 43.23096859923613]],
    [ "Enedis",[-0.8766370650776336, 43.22945272803288]],
    [ "Enedis",[-0.8496091417928108, 43.8257203678897]],
    [ "Enedis",[-0.8426793499999999, 43.82226875]],
    [ "Enedis",[-0.8485434000000002, 43.820575899999994]],
    [ "Enedis",[-0.831021299999999, 43.8290002]],
    [ "Enedis",[-0.835967098483616, 43.83061593357434]],
    [ "Enedis",[-0.8412954500000001, 43.828549699999996]],
    [ "Enedis",[-0.7720612667680755, 43.31922386838505]],
    [ "Avocats",[5.077166322307713, 45.661271491030966]],
    [ "Enedis",[3.019398583531844, 50.63129299359505]],
    [ "Enedis",[-0.7297494969950545, 43.122024132961975]],
    [ "Enedis",[-0.7357451500000001, 43.1156442]],
    [ "Enedis",[1.1356279697555016, 49.489560647783016]],
    [ "Enedis",[-1.4567406560486003, 43.55298065324882]],
    [ "Enedis",[2.385175556336529, 47.79908736666667]],
    [ "Enedis",[1.9342651265720916, 47.88860148374814]],
    [ "Enedis",[1.9406772896938589, 47.89059937474009]],
    [ "638499870",[0.6704955204683104, 47.43234154729517]],
    [ "Enedis",[2.5459388917094015, 50.45383783837607]],
    [ "Enedis",[-0.8911953685970466, 43.2246417630862]],
    [ "Enedis",[-0.8854364490533041, 43.22547738320893]],
    [ "Enedis",[7.4118828, 49.034597350000006]],
    [ "Enedis",[-0.6703645, 44.73725735]],
    [ "Enedis",[-0.8985576914361001, 43.21425923201581]],
    [ "Enedis",[-4.363186167362243, 48.38063619246458]],
    [ "Enedis",[2.2993601499999996, 48.58087945]],
    [ "Enedis",[-1.4929044999999999, 43.53289684999999]],
    [ "638871550",[-0.22225068924851182, 46.651811004777024]],
    [ "638871553",[-0.2221996500000012, 46.651799800000006]],
    [ "Enedis",[3.2452634499999995, 48.18851514999999]],
    [ "Eau de Paris",[2.4227864413880495, 48.74811526865695]],
    [ "EDF",[6.560734882800757, 44.789996591475045]],
    [ "Enedis",[6.05670285, 46.48691315]],
    [ "Enedis",[1.7894487735460511, 49.45029608268085]],
    [ "Enedis",[-1.4305653916710275, 43.584100911176904]],
    [ "Enedis",[-1.427976168404624, 43.58934545172146]],
    [ "Enedis",[-1.4282835000000003, 43.587060550000004]],
    [ "Enedis",[-1.431275126625482, 43.586219491509645]],
    [ "Enedis",[-1.4333158439526001, 43.596200331189955]],
    [ "Enedis",[3.069629684346967, 45.78439733805796]],
    [ "Enedis",[3.1021762245313225, 45.81197504343261]],
    [ "Enedis",[3.1001497475238367, 45.81263791241261]],
    [ "Enedis",[3.104352886739469, 45.81653185439417]],
    [ "Enedis",[3.1098109820531232, 45.81822829683157]],
    [ "Enedis",[3.1105404743910148, 45.82086722229935]],
    [ "Enedis",[3.1173287267861243, 45.80482596695613]],
    [ "Enedis",[3.1193717084170616, 45.80013442062015]],
    [ "Enedis",[3.1388744580535772, 45.797549200642194]],
    [ "Enedis",[3.1474501341723116, 45.819687785488114]],
    [ "Enedis",[3.149571339372659, 45.83713637247191]],
    [ "Enedis",[3.1615045548301457, 45.839321090136806]],
    [ "Enedis",[1.7627025744060487, 48.51474785489402]],
    [ "Enedis",[1.748173490370985, 48.52002388953582]],
    [ "Enedis",[-0.90351355, 43.190766100000005]],
    [ "Enedis",[-1.4285615181628726, 43.588502556219176]],
    [ "Enedis",[-1.42711315, 43.59012855000001]],
    [ "Enedis",[0.9299281884338217, 45.93244684438176]],
    [ "Enedis",[0.9211772364854425, 45.940084171290316]],
    [ "Enedis",[0.938332022615345, 45.923790064342484]],
    [ "Green Park",[5.2601713, 45.59008880000001]],
    [ "Enedis",[3.437419397163485, 50.49119756651827]],
    [ "Poste électrique de Dillon",[-61.04412105293847, 14.617143321957926]],
    [ "Poste électrique du Lamentin",[-60.976820436761585, 14.624216332061627]],
    [ "Enedis",[0.9532054518059522, 45.89580121120978]],
    [ "Enedis",[0.983100407622258, 45.889265940451]],
    [ "Enedis",[0.9848820426124522, 45.8832905542482]],
    [ "Enedis",[0.9709094222313381, 45.87670211427835]],
    [ "Poste source",[6.133461160869031, 49.29974370098877]],
    [ "Enedis",[-0.8715570000000001, 43.229981]],
    [ "Enedis",[-0.8739553531450531, 43.22851146668756]],
    [ "Enedis",[-1.1906929113179912, 46.83779897892263]],
    [ "Enedis",[0.9543220674084637, 45.87998809937589]],
    [ "Enedis",[0.950998058141744, 45.881607830569514]],
    [ "Enedis",[0.9582164670572412, 45.87796750011305]],
    [ "Enedis",[2.3653053994077973, 48.64087691675166]],
    [ "Enedis",[0.8274408460128767, 47.38926181534233]],
    [ "SRD",[0.6300828999999992, 46.529872350000005]],
    [ "Enedis",[6.281627913963321, 46.203870711339334]],
    [ "Enedis",[6.282951732166677, 46.2053536440938]],
    [ "Enedis",[6.281930764533871, 46.20400150589188]],
    [ "réséda",[6.273981299999999, 49.11492855]],
    [ "Enedis",[0.8205942938459457, 47.381229671332925]],
    [ "Enedis",[2.828223509970989, 48.3670868779614]],
    [ "SNCF Réseau",[5.707843366893364, 45.20457490034736]],
    [ "Enedis",[0.940060894347721, 46.01996795665868]],
    [ "Enedis",[0.9412501529041182, 46.00664945258804]],
    [ "Enedis",[0.8205213905917418, 47.38592546849724]],
    [ "Enedis",[0.3201911499999992, 46.522339599999995]],
    [ "Enedis",[0.7072966373124951, 49.70066451481446]],
    [ "Enedis",[-1.0505203500000009, 43.6926793]],
    [ "Enedis",[-1.004772583167955, 43.69251079968021]],
    [ "Enedis",[-1.4461812697792218, 46.65892129223192]],
    [ "SRD",[0.26816415, 46.65008559999999]],
    [ "Enedis",[-1.494462065599944, 48.21970965209197]],
    [ "Enedis",[-1.25399226858533, 43.39243175671532]],
    [ "Enedis",[-1.2478121523836303, 43.38443533403311]],
    [ "Enedis",[1.0593324834076714, 45.88188374991095]],
    [ "Enedis",[1.7210485692663335, 49.83773621648011]],
    [ "Enedis",[1.7599469300672266, 45.53454079273009]],
    [ "Enedis",[0.8219566652284286, 47.38990492005456]],
    [ "réséda",[6.136544474066087, 48.94574455323531]],
    [ "SRD",[0.22271731107444556, 46.709420877401065]],
    [ "UP Depardon",[5.14657299592721, 45.63512213404102]],
    [ "Enedis",[2.003930398601863, 48.4157220451676]],
    [ "Enedis",[2.0099600971436615, 48.413668932847834]],
    [ "Enedis",[1.979474016622508, 48.40802601640217]],
    [ "Enedis",[2.003978106801058, 48.41570620791742]],
    [ "Enedis",[2.002132051520084, 48.41344590427959]],
    [ "Enedis",[2.1576976514099497, 48.59845361387627]],
    [ "Enedis",[2.1271039729658487, 48.59693940876201]],
    [ "Enedis",[-0.2700706320551548, 43.34619684243744]],
    [ "Morlaàs 5 collège",[-0.27628378434720835, 43.338250121006766]],
    [ "Enedis",[2.2555058254376608, 45.56920348067773]],
    [ "Enedis",[1.084968858982281, 46.2448240681934]],
    [ "Enedis",[3.0996871951301483, 50.28621389388121]],
    [ "Enedis",[1.7754017670608155, 48.85798755509218]],
    [ "Enedis",[1.7727098912702348, 48.858643484958336]],
    [ "Enedis",[1.7781468473938578, 48.849129348300984]],
    [ "Enedis",[0.8170669214433416, 47.383028030521096]],
    [ "Enedis",[0.81490475602442, 47.384721218060974]],
    [ "Enedis",[-0.8731664, 48.5342874]],
    [ "Enedis",[6.1258133500000005, 45.019803]],
    [ "Enedis",[4.610360647478301, 45.08191996615723]],
    [ "Enedis",[-0.9309731671803764, 43.571431366465106]],
    [ "Électricité de France",[-61.51230152837054, 16.236468786697515]],
    [ "Enedis",[-0.252896550388151, 49.23472841839299]],
    [ "Enedis",[-0.2536615000000011, 49.238243]],
    [ "Enedis",[1.4261153000000002, 45.69940855]],
    [ "Enedis",[2.58804815, 44.80980515000001]],
    [ "Enedis",[4.5991114, 45.0827709]],
    [ "Enedis",[4.660652799999999, 45.049167350000005]],
    [ "Enedis",[-0.9145655738878288, 44.20067320311682]],
    [ "Enedis",[-0.9180718000000002, 44.20998395]],
    [ "Enedis",[1.0096914506564296, 47.771166891776595]],
    [ "réséda",[6.29139809196446, 48.963218484795625]],
    [ "Enedis",[0.8108955416963, 47.37421345068713]],
    [ "Enedis",[0.8129815273078984, 47.37628532966202]],
    [ "Enedis",[4.631669699999999, 45.080754000000006]],
    [ "No Limit",[6.269247978280649, 46.20202024708307]],
    [ "Enedis",[2.6583344309824417, 46.61146600408584]],
    [ "SRD",[0.3113049000000006, 46.6160632]],
    [ "Enedis",[0.8113000124285011, 47.37283725749107]],
    [ "Mont rond",[6.257289793274687, 46.19787687857876]],
    [ "Enedis",[0.819034934597267, 47.37125922698619]],
    [ "Parc Technologique 2",[5.16398805, 45.6274141]],
    [ "Enedis",[0.8336477868418168, 47.370766357050165]],
    [ "Enedis",[0.8185802007489789, 47.37992667611187]],
    [ "Enedis",[5.158396157012048, 48.44466792308279]],
    [ "Enedis",[-0.3766473735931624, 49.17125396444193]],
    [ "Enedis",[-0.37437458904123694, 49.170506811798724]],
    [ "Enedis",[3.4068082691453188, 48.65254539815045]],
    [ "Enedis",[-1.5438878009655463, 43.44538861613454]],
    [ "Enedis",[4.437358515029857, 45.54787048639794]],
    [ "Enedis",[1.9285389318007926, 47.84589760177451]],
    [ "Enedis",[2.180900919813307, 47.97710566655149]],
    [ "réséda",[6.27768860907937, 49.01803776552768]],
    [ "Enedis",[-1.5512080126084227, 43.42287502464753]],
    [ "Enedis",[4.169043045466059, 49.36617612878534]],
    [ "Enedis",[4.163838209538282, 49.36195474315528]],
    [ "Enedis",[4.166557118988754, 49.35582662815693]],
    [ "Enedis",[4.172732681881524, 49.36274131861116]],
    [ "Enedis",[4.169990233331696, 49.36426534077043]],
    [ "Enedis",[0.792323422809635, 49.117342239014896]],
    [ "Enedis",[0.5125706245402823, 48.92271676180699]],
    [ "Enedis",[0.5136355849908024, 48.945302791084266]],
    [ "Mallet",[1.3841831628268484, 44.00128266875375]],
    [ "Enedis",[-1.4297866813895759, 43.651689370856865]],
    [ "Enedis",[-1.4273684, 43.651180049999994]],
    [ "réséda",[6.265367942774431, 49.05879592283976]],
    [ "RTE",[2.436975332100283, 48.79934635577466]],
    [ "Enedis",[3.0486645902566445, 45.76646937925656]],
    [ "Enedis",[3.054167022612274, 45.76561117736292]],
    [ "Enedis",[3.0588845015363035, 45.77070071806266]],
    [ "Enedis",[3.06002301047619, 45.7698004]],
    [ "Enedis",[3.0581630809248526, 45.77148571777656]],
    [ "Enedis",[3.0627537183178517, 45.76994135092684]],
    [ "Enedis",[3.066867477200234, 45.766538034491965]],
    [ "Enedis",[3.0718783802929517, 45.76841807778674]],
    [ "Enedis",[3.080652535939833, 45.766192880039405]],
    [ "Enedis",[3.0874082378087326, 45.7703140559875]],
    [ "Enedis",[3.078399533333333, 45.76903373333334]],
    [ "Enedis",[3.071841398246868, 45.77113352554523]],
    [ "Enedis",[3.0660988455939266, 45.77049542819619]],
    [ "Enedis",[3.0440256215612083, 45.75933339742133]],
    [ "Enedis",[3.0546029840107445, 45.759327091399555]],
    [ "Enedis",[3.05884800809186, 45.755296809378834]],
    [ "Enedis",[3.057983206415952, 45.754346299608294]],
    [ "Enedis",[3.0630237774517677, 45.75531868428456]],
    [ "Enedis",[3.062514014750125, 45.75048144695231]],
    [ "Enedis",[3.0602098339355632, 45.748031646738525]],
    [ "Enedis",[3.065600528668382, 45.728174075810955]],
    [ "Enedis",[3.063560794796731, 45.72468746968233]],
    [ "Enedis",[3.0843373803996057, 45.72600120139112]],
    [ "Enedis",[3.1172651079601987, 45.738108809479634]],
    [ "Enedis",[3.101943862267225, 45.73943347252678]],
    [ "Enedis",[3.103853628444942, 45.73739432540785]],
    [ "Enedis",[3.0873502500000005, 45.736264950000006]],
    [ "Enedis",[3.069228496094356, 45.73463385410565]],
    [ "Enedis",[3.0694843506974054, 45.74888439497362]],
    [ "Enedis",[3.083562323718964, 45.75651793367046]],
    [ "Enedis",[3.0807123934276888, 45.75801795200912]],
    [ "Enedis",[3.0978250000000016, 45.7524913]],
    [ "Enedis",[3.0892916891634608, 45.74220373435995]],
    [ "Enedis",[3.1219705876337547, 45.75893362787321]],
    [ "Enedis",[3.095437585419525, 45.748395805140376]],
    [ "Enedis",[3.098331267322804, 45.749271229750235]],
    [ "Enedis",[3.1414130499999993, 45.73886]],
    [ "Enedis",[3.147251488092256, 45.73582587084703]],
    [ "Enedis",[3.1383596182344418, 45.72885390573941]],
    [ "Enedis",[3.1484687499999997, 45.72567145]],
    [ "Enedis",[3.1956705500000004, 45.76471885000001]],
    [ "Enedis",[3.128116919968025, 45.76301965234919]],
    [ "Enedis",[3.1211496802384, 45.786001362484]],
    [ "Enedis",[3.1237650139344284, 45.78972346280503]],
    [ "Enedis",[3.1263772403162906, 45.787409989251174]],
    [ "Enedis",[3.1169787999999996, 45.79408726666667]],
    [ "Enedis",[3.11560171690621, 45.79899016376451]],
    [ "Enedis",[3.136189215100447, 45.799435133956614]],
    [ "Enedis",[3.1482353291806384, 45.798075566546295]],
    [ "Enedis",[3.09418340162819, 45.771678955246095]],
    [ "Enedis",[-1.5278622237449442, 43.497254304463056]],
    [ "Enedis",[3.09405123579021, 45.76611987023893]],
    [ "Enedis",[1.1094203171577826, 49.93123598123093]],
    [ "Enedis",[7.355357266003755, 47.75409049472432]],
    [ "Enedis",[3.0868624602122905, 45.76406740679637]],
    [ "Enedis",[3.0864094668047675, 45.757877799563886]],
    [ "Enedis",[3.0810230501603, 45.75150982702746]],
    [ "Enedis",[3.085957333333333, 45.712566422384036]],
    [ "Enedis",[3.0730211352692054, 45.71123921567613]],
    [ "Enedis",[3.042815375067282, 45.70697556796869]],
    [ "Enedis",[3.0346999772052916, 45.707230441409045]],
    [ "Enedis",[3.0178335256160835, 45.713534993252665]],
    [ "Enedis",[3.029316519418585, 45.74070115442489]],
    [ "Enedis",[3.0298591305794424, 45.74818087483414]],
    [ "Enedis",[3.0285849310180146, 45.750896713488196]],
    [ "Enedis",[3.05188283700934, 45.7803571460786]],
    [ "Enedis",[3.0506682000000005, 45.77743979210155]],
    [ "Enedis",[3.061494980403511, 45.78411887276136]],
    [ "Enedis",[3.1085509037924157, 45.789319787881375]],
    [ "Enedis",[3.1025923419173203, 45.78610306989023]],
    [ "Enedis",[3.081670086074147, 45.785224253653716]],
    [ "Enedis",[3.101298278114198, 45.77326840871999]],
    [ "Enedis",[3.124894898071206, 45.7861479252556]],
    [ "Enedis",[4.4974875197778434, 45.464801622007634]],
    [ "Aulière",[3.302653493081996, 45.75043056994878]],
    [ "Enedis",[3.2820002000000006, 45.75033315000001]],
    [ "Enedis",[3.1532821499999986, 45.745027300000004]],
    [ "Enedis",[3.148426983432597, 45.81475981956356]],
    [ "Enedis",[3.1421514523847005, 45.81844057146558]],
    [ "Enedis",[3.1414232044274613, 45.822801478231646]],
    [ "Enedis",[3.133820681376945, 45.83161092493196]],
    [ "Enedis",[3.127736462296297, 45.83536473243127]],
    [ "Enedis",[3.1267037999999996, 45.8326174]],
    [ "Enedis",[3.117547778446438, 45.83947945151314]],
    [ "Enedis",[3.1170777499999995, 45.84115744999999]],
    [ "Poste électrique de Duvigneau",[2.1569834400108627, 51.01596527096156]],
    [ "Enedis",[-1.6255629499999997, 43.405061599999996]],
    [ "Enedis",[4.222084159957121, 49.53269178025842]],
    [ "Enedis",[4.623914039072541, 49.88754520398381]],
    [ "Enedis",[3.2330265399171303, 45.804903867558494]],
    [ "Enedis",[3.126631972969928, 45.67158107109435]],
    [ "Enedis",[3.1120476365863983, 45.66743736516189]],
    [ "Enedis",[3.0847930477608987, 45.65439169408684]],
    [ "Enedis",[3.0880955346212744, 45.661400050707016]],
    [ "Enedis",[3.1389846163650437, 45.67842031497213]],
    [ "Enedis",[3.10758, 45.70190925]],
    [ "Enedis",[-0.9630937643014821, 44.637908569181214]],
    [ "Enedis",[-0.9594148000000001, 44.63427204999999]],
    [ "Enedis",[-0.9526718499999998, 44.63835835]],
    [ "Enedis",[-0.9512073236891979, 44.63931390429386]],
    [ "Enedis",[-0.9534195433473329, 44.64253616621381]],
    [ "Enedis",[-0.9540055000000001, 44.64142215000001]],
    [ "Enedis",[-0.9526373163909175, 44.64038845154238]],
    [ "Enedis",[-0.950224401381751, 44.64271566640687]],
    [ "Bleuets",[6.280141806439394, 46.18579138494318]],
    [ "Enedis",[3.122570347843373, 45.7135509452005]],
    [ "Enedis",[3.1067905613419295, 45.75731827571521]],
    [ "Enedis",[3.19011758495362, 45.809772469063965]],
    [ "Enedis",[3.1444623176487485, 45.841037674231835]],
    [ "Enedis",[3.11420333278334, 45.81459164208662]],
    [ "Enedis",[3.116783806202365, 45.812850238729744]],
    [ "Enedis",[3.1169123331602586, 45.80736358060767]],
    [ "Enedis",[3.109121598493051, 45.80877999733296]],
    [ "Enedis",[3.0976245985985984, 45.81231238145686]],
    [ "Enedis",[3.102325677411048, 45.80572428529877]],
    [ "Enedis",[3.0898426999999997, 45.845742200000004]],
    [ "Enedis",[3.1350319565673574, 45.685986830928016]],
    [ "Enedis",[3.1330695666666664, 45.68845751215403]],
    [ "Enedis",[3.124332855675733, 45.687051046641265]],
    [ "Enedis",[3.13102755288522, 45.68451767220912]],
    [ "Enedis",[3.1267604897488894, 45.68295955361847]],
    [ "Enedis",[3.1252042380009373, 45.68092991816089]],
    [ "Enedis",[3.1245608034761876, 45.672562592813684]],
    [ "Enedis",[-0.5833606109809154, 44.77402172425387]],
    [ "Enedis",[-0.9873083999999999, 44.656550700000004]],
    [ "Enedis",[3.517173890718139, 45.83607947486364]],
    [ "Enedis",[3.49760656943931, 45.836323866296986]],
    [ "Enedis",[-0.9807532500000004, 44.65161299999999]],
    [ "Closon",[6.08343565, 45.91799400000001]],
    [ "Enedis",[6.084256882856052, 45.920269006491026]],
    [ "Enedis",[3.457302060580524, 45.86809367082029]],
    [ "Enedis",[-0.9606446343241644, 44.64366909689756]],
    [ "Enedis",[1.6272620347035445, 49.30219550241589]],
    [ "650568908",[4.899545939325636, 49.002513678319666]],
    [ "École Centrale de Lille",[3.1477111251145065, 50.610057452641776]],
    [ "Enedis",[-1.0027305315803619, 44.68268143345107]],
    [ "Enedis",[-1.002542121638652, 44.68376080648907]],
    [ "Enedis",[-1.0014904285446005, 44.682451266666675]],
    [ "Enedis",[4.320166551961649, 49.30130174960223]],
    [ "Enedis",[4.310140157033089, 49.297529030344215]],
    [ "Enedis",[4.323737646091576, 49.29704622786914]],
    [ "ZI Béria",[5.742396750045028, 46.58419641667307]],
    [ "Enedis",[3.2661859608870962, 45.80805301612903]],
    [ "Enedis",[3.2655758110047794, 45.82772548056133]],
    [ "Enedis",[3.347350910295838, 45.72002301801228]],
    [ "Enedis",[4.178726793947568, 49.31488635405686]],
    [ "Enedis",[4.165200474410877, 49.31710546158816]],
    [ "Enedis",[4.171354053909073, 49.31417998325976]],
    [ "Enedis",[4.157027666695219, 49.252570055857376]],
    [ "Enedis",[-0.9659121202580417, 44.66268079389184]],
    [ "Enedis",[-0.9917417328551247, 44.66280799788664]],
    [ "Enedis",[-1.0137610889972901, 44.68036142788954]],
    [ "Enedis",[-1.0103564, 44.6844894]],
    [ "Enedis",[-1.01314845, 44.6846144]],
    [ "Enedis",[-1.00645805, 44.687944949999995]],
    [ "Enedis",[0.575474051442059, 46.81433948166559]],
    [ "Enedis",[-0.8796896833101456, 43.30637934936363]],
    [ "Enedis",[4.659289098196076, 45.762643383644765]],
    [ "Enedis",[4.211111948517471, 49.34939116620755]],
    [ "Enedis",[4.2142672509353964, 49.347721081762785]],
    [ "Enedis",[4.232093394470964, 49.34898234475701]],
    [ "Enedis",[4.224941583693692, 49.349730007115475]],
    [ "Enedis",[4.2093445572871095, 49.34740894142144]],
    [ "Enedis",[4.213877149124032, 49.356876053109474]],
    [ "Enedis",[4.2152541114946525, 49.350802395420665]],
    [ "Enedis",[4.211931055923815, 49.355587706804506]],
    [ "Enedis",[-1.2167332823813197, 43.77795283379161]],
    [ "Enedis",[-1.217867683498064, 43.77577195392752]],
    [ "Enedis",[-1.2176916000000007, 43.780878650000005]],
    [ "Enedis",[-1.2238989747514029, 43.77935084895044]],
    [ "Enedis",[-1.2234740848937964, 43.78370076712503]],
    [ "Enedis",[-1.216298398401793, 43.78411653706004]],
    [ "Maison Rouge",[4.064995461950047, 49.38512073320763]],
    [ "Enedis",[4.069049329305461, 49.38540381249462]],
    [ "Enedis",[-1.2242221500000001, 43.7899999]],
    [ "Enedis",[-1.2088342223961088, 43.777999253637496]],
    [ "Enedis",[3.89517895, 49.29971605]],
    [ "Enedis",[3.9183747730202034, 49.27302533180956]],
    [ "Enedis",[3.8844106497947357, 49.27595134386195]],
    [ "Enedis",[3.883006283544087, 49.27486845524854]],
    [ "Enedis",[3.909382340383139, 49.274826661031234]],
    [ "Enedis",[3.8916686, 49.27525064999999]],
    [ "ZI Muizon",[3.9107579492267304, 49.273007229079695]],
    [ "Enedis",[3.899247479259259, 49.27457233037038]],
    [ "Enedis",[3.8953259983262685, 49.27330495622505]],
    [ "Enedis",[3.887195921879935, 49.273856146346475]],
    [ "Enedis",[3.9018220019856518, 49.27270754676902]],
    [ "Enedis",[3.8918799780631437, 49.27452957655843]],
    [ "Enedis",[-1.2166731208475225, 43.786989495838064]],
    [ "Enedis",[-1.2202177112319983, 43.780092276584284]],
    [ "Enedis",[-1.2045029449688418, 43.77607521709414]],
    [ "Enedis",[-1.2173539935008264, 43.76513527555754]],
    [ "Enedis",[-1.1560972321262435, 43.88153383523009]],
    [ "Enedis",[3.8428633124293774, 49.260226762598876]],
    [ "Courcelle",[3.845242249425526, 49.26232881450283]],
    [ "Enedis",[3.8371400156335915, 49.26553022924772]],
    [ "Enedis",[5.301640890645461, 47.90033144418121]],
    [ "Enedis",[6.127401666310353, 45.01741070054042]],
    [ "Enedis",[-1.2949166867266637, 43.79239231975132]],
    [ "Enedis",[-1.2958154977288827, 43.80337973378116]],
    [ "Enedis",[-1.2964416000000007, 43.7992661]],
    [ "Enedis",[-1.297289717651285, 43.799716246969425]],
    [ "Enedis",[3.7655426065575166, 49.252369846644676]],
    [ "Enedis",[3.7539297202485478, 49.25281602100128]],
    [ "Tampytiere",[6.723016831124821, 44.9008908023784]],
    [ "Enedis",[3.7923583485854033, 49.23427966962176]],
    [ "Enedis",[3.786087684092466, 49.22528939080861]],
    [ "Enedis",[3.7969441997042908, 49.2330736447363]],
    [ "Enedis",[3.7884054830960294, 49.23657580173479]],
    [ "Rocher de l'Aigle",[6.730314405822789, 44.915147052879824]],
    [ "Querelay",[6.7231211740004335, 44.910551961395775]],
    [ "Rocher de l'Aigle 2",[6.7497669, 44.906817200000006]],
    [ "Chalmette",[6.728971612994829, 44.91502413344832]],
    [ "Napoleon",[6.725058736863378, 44.931884317432605]],
    [ "Enedis",[6.724560582304853, 44.93330843691708]],
    [ "SNCF Réseau",[1.847717831188128, 50.85425318679036]],
    [ "Enedis",[3.746329048440018, 49.255647929158734]],
    [ "Enedis",[3.7403752589629766, 49.25476152137136]],
    [ "Enedis",[3.6929248154122005, 49.234161873065304]],
    [ "Enedis",[3.698262062297528, 49.23379019168797]],
    [ "Enedis",[-1.3249570084014695, 43.756818136449816]],
    [ "Enedis",[-1.3347625500000009, 43.75622570000001]],
    [ "Enedis",[-1.331038749999999, 43.752653900000006]],
    [ "Enedis",[-1.3214766649160485, 43.75874267811301]],
    [ "Enedis",[2.839315994580746, 42.48617838713305]],
    [ "Bourg",[4.042156586284328, 45.36965394746528]],
    [ "Enedis",[5.14147715019038, 48.19779957546018]],
    [ "Enedis",[3.814226055034573, 49.21908414689572]],
    [ "Enedis",[3.8208481932709906, 49.21615542620851]],
    [ "Mairie Sarcy",[3.8237820470495625, 49.20621958712477]],
    [ "Aux Petits Près",[5.736666999999999, 46.53336515]],
    [ "Enedis",[3.921519263477403, 49.21944586137023]],
    [ "Poste source de Île-Napoléon",[7.381079931278708, 47.769516907712415]],
    [ "Enedis",[-0.6213479633212179, 43.2175399683617]],
    [ "P1 ESTOS BG",[-0.6126112, 43.21029849999999]],
    [ "Enedis",[-0.61121495, 43.22548359999999]],
    [ "Enedis",[-0.61205965, 43.2227976]],
    [ "Enedis",[-0.5929537441011405, 43.19887061473458]],
    [ "Enedis",[-1.3137259499999998, 43.38634355]],
    [ "Enedis",[-1.3114564191224183, 43.38231940395746]],
    [ "Enedis",[-1.4469297254861093, 43.565506940511824]],
    [ "Enedis",[-1.4525851187045444, 43.572076525748415]],
    [ "Enedis",[3.520754137548154, 48.493370375591596]],
    [ "Enedis",[3.516863023639116, 48.48957528529588]],
    [ "Enedis",[3.511283323500439, 48.49357863938434]],
    [ "Enedis",[3.514893147956732, 48.48881107341314]],
    [ "Enedis",[3.511922177234879, 48.495635366152555]],
    [ "Enedis",[3.514788400985867, 48.49112443380989]],
    [ "Enedis",[3.5147708001137254, 48.49314745011236]],
    [ "Enedis",[3.5120190016304114, 48.48906757479359]],
    [ "Enedis",[3.510744260891114, 48.496834045731084]],
    [ "Enedis",[3.510055509166341, 48.499303059015276]],
    [ "Enedis",[3.504444990443637, 48.49731630549856]],
    [ "Enedis",[3.5073919870643078, 48.49563054734058]],
    [ "Enedis",[3.495947870757679, 48.50087035288539]],
    [ "Enedis",[3.494253324971187, 48.497344063157044]],
    [ "Enedis",[3.492854020096522, 48.49887282802713]],
    [ "Enedis",[3.4868057019081156, 48.49738447011678]],
    [ "Enedis",[3.4848148218186883, 48.49728695745612]],
    [ "Enedis",[3.4822254926418164, 48.4991600821001]],
    [ "Enedis",[3.5034803516641007, 48.49407733150905]],
    [ "Enedis",[3.480341810947892, 48.4947680099829]],
    [ "Enedis",[3.5098141861942134, 48.49468222698388]],
    [ "Enedis",[3.5091414935566747, 48.49549218614142]],
    [ "Enedis",[3.4993416740684276, 48.49479055048762]],
    [ "Enedis",[3.494432716666666, 48.49538290000002]],
    [ "Enedis",[9.268700800000001, 41.47382915]],
    [ "Enedis",[3.5095792494584566, 48.4888253178654]],
    [ "Enedis",[3.506962807064018, 48.49377746313466]],
    [ "Enedis",[3.5076597730980916, 48.487604103368184]],
    [ "Enedis",[3.4874889097753488, 48.48697344525401]],
    [ "Enedis",[3.4941323864020766, 48.489768198182546]],
    [ "Enedis",[3.4935691499999995, 48.4909958]],
    [ "Enedis",[3.4908850538563296, 48.48793800416945]],
    [ "Enedis",[3.4907330417066897, 48.48535227974579]],
    [ "Enedis",[3.4967047875132855, 48.489033875072494]],
    [ "Enedis",[-2.19734155, 47.13803720000001]],
    [ "Enedis",[-2.1679972371736267, 47.155117275615304]],
    [ "Enedis",[3.5009074440961947, 48.490096386031084]],
    [ "Enedis",[3.5035100579681218, 48.49148320085356]],
    [ "Enedis",[-2.16171545, 47.15232499999999]],
    [ "Enedis",[-1.4345990351308342, 43.55529452401892]],
    [ "Enedis",[-1.4394710499999999, 43.57235515]],
    [ "Enedis",[3.961161272488852, 49.18876914388273]],
    [ "Vaugelas",[5.9131686499999985, 45.69429689999998]],
    [ "Enedis",[-1.441978700000001, 43.53074605]],
    [ "Enedis",[3.241738792423176, 45.811081628430685]],
    [ "Église",[7.007151732011909, 43.675324706434616]],
    [ "Jardins d'Ebalunga 2",[9.473556881552083, 42.77634963003287]],
    [ "Enedis",[3.927085320224719, 49.214320953558044]],
    [ "Enedis",[5.447840623671049, 47.105587146901414]],
    [ "Enedis",[3.9052909215611074, 49.246409663044425]],
    [ "Enedis",[3.9170734780703484, 49.24612364795116]],
    [ "Enedis",[3.910449746118555, 49.25061694469395]],
    [ "Enedis",[0.557715592703541, 46.80561933991509]],
    [ "Enedis",[3.9124206632136507, 49.24788975092072]],
    [ "Enedis",[3.916090812016488, 49.24414645483061]],
    [ "Enedis",[3.911529279239873, 49.25343868009208]],
    [ "Enedis",[3.9111327221710512, 49.255351375451426]],
    [ "Enedis",[3.9144170342668914, 49.2525422294974]],
    [ "ZA Moutier 2",[3.914368475811605, 49.24454375724425]],
    [ "Enedis",[3.916757318850536, 49.251816488138985]],
    [ "Enedis",[3.9119050958011856, 49.24381436594477]],
    [ "Enedis",[-0.7910726459986271, 44.515573930753696]],
    [ "Enedis",[2.4056109513837947, 50.59472635171882]],
    [ "Enedis",[3.2014158290890506, 50.57369957284016]],
    [ "Enedis",[3.93658601719339, 49.201042231538985]],
    [ "Enedis",[0.8084924726125724, 49.68712978386544]],
    [ "Enedis",[4.217854316852662, 49.24808178353031]],
    [ "Enedis",[2.4426672232347686, 48.84866623215748]],
    [ "Enedis",[5.564237220162124, 43.295633076161806]],
    [ "Enedis",[3.7091239374441396, 49.3247408629139]],
    [ "Enedis",[4.5255455622572365, 45.4820044571638]],
    [ "Enedis",[4.519871165382635, 45.47866180181723]],
    [ "Enedis",[4.143469909214997, 49.27008451907982]],
    [ "Mesnois bourg",[5.6878095988384745, 46.59922531626783]],
    [ "Enedis",[-1.2724747, 43.5284415]],
    [ "Enedis",[4.199934835129826, 49.355525776589474]],
    [ "Enedis",[4.204104992491032, 49.34785714957303]],
    [ "Enedis",[4.2020198779054825, 49.349428314203564]],
    [ "Enedis",[4.201427607267588, 49.36112320952773]],
    [ "Enedis",[2.345554549999998, 48.8423642]],
    [ "Poste électrique de Malassis",[2.4335796266119014, 48.870778667734314]],
    [ "Poste électrique d’Alma",[2.304410724177493, 48.86430830257881]],
    [ "Enedis",[-0.8153658015907584, 44.52249026746552]],
    [ "Enedis",[-1.1720668, 44.38200624999999]],
    [ "Enedis",[0.8543065889299727, 46.13628743662793]],
    [ "Enedis",[-1.935737536905599, 47.132661022145875]],
    [ "Enedis",[-1.9286603500000008, 47.1367159]],
    [ "Enedis",[-1.9581157, 47.136974949999995]],
    [ "Enedis",[2.031340876785715, 47.5349159000744]],
    [ "Enedis",[2.0361397499999994, 47.546328599999995]],
    [ "Enedis",[2.0275291991090123, 47.543842960177585]],
    [ "Enedis",[2.030896692520881, 47.53319870138572]],
    [ "Enedis",[2.0468475347888746, 47.53221526468545]],
    [ "Enedis",[2.043985658546252, 47.531250463048565]],
    [ "Enedis",[2.0421941171696045, 47.534907477675745]],
    [ "Enedis",[2.033324959563176, 47.54360143716065]],
    [ "RTE",[2.030926516185955, 47.55398203492764]],
    [ "Enedis",[2.0314741317372027, 47.52884181356995]],
    [ "Enedis",[2.0312680955117677, 47.52854125205254]],
    [ "Enedis",[2.038823853875403, 47.55888307851945]],
    [ "Enedis",[2.038145403215625, 47.535163204028784]],
    [ "Enedis",[2.036735430260633, 47.52578711824198]],
    [ "Enedis",[-0.78073105, 44.66360815]],
    [ "Enedis",[2.7102558613064947, 46.50825315390687]],
    [ "Enedis",[2.15310201065512, 46.20118177370237]],
    [ "Enedis",[4.136930498936034, 47.37113807990637]],
    [ "Brescia",[5.2149683499999995, 45.62095635]],
    [ "Enedis",[-1.6948691990033173, 43.38483448403742]],
    [ "Enedis",[-1.6828113598625052, 43.38652772216077]],
    [ "Enedis",[-1.68787175, 43.3850326]],
    [ "Enedis",[-1.6867225320182135, 43.38119536533289]],
    [ "Enedis",[-1.6777075499999994, 43.37770979999999]],
    [ "Enedis",[-1.6932415499999995, 43.38586565000001]],
    [ "Enedis",[-1.6931785923405502, 43.38251212420718]],
    [ "Enedis",[-1.67178505, 43.37818625]],
    [ "Enedis",[-1.6699717532721752, 43.38390467659294]],
    [ "Enedis",[-1.6754495807115488, 43.37477921022756]],
    [ "Enedis",[-1.6754692500000001, 43.37482545]],
    [ "Enedis",[-1.014397459367396, 43.743779322627745]],
    [ "Enedis",[-1.0151193499999989, 43.7416426]],
    [ "Enedis",[-1.0019401500000003, 43.74732145000001]],
    [ "Enedis",[-1.0002014500000003, 43.744137099999996]],
    [ "Enedis",[-1.0032028499999994, 43.7422119]],
    [ "Enedis",[-1.0162680499999999, 43.94706185]],
    [ "poste_2912",[2.9204289282170275, 49.61559849883227]],
    [ "poste_2913",[2.9154071522126244, 49.6139164160816]],
    [ "SICAE Oise",[2.9125466727620055, 49.6122667524926]],
    [ "Enedis",[4.3777414836893085, 43.82412805171864]],
    [ "Aranc coupure",[5.5123325, 46.003336149999996]],
    [ "Enedis",[2.349890083589945, 48.62721107550828]],
    [ "Enedis",[0.5405835753298097, 46.800225964929204]],
    [ "Enedis",[5.8994038, 45.557687050000006]],
    [ "SICAE Oise",[2.7990130645595133, 49.56958801650041]],
    [ "Rolin",[5.06106656743165, 47.283906947322556]],
    [ "Les jardins",[5.07301855, 47.28587690000001]],
    [ "Enedis",[5.0652591708717845, 47.289770615188466]],
    [ "Enedis",[-1.6304327655436577, 43.35754017271996]],
    [ "Enedis",[4.079986958177264, 48.28726021364379]],
    [ "Chasseurs à Pied",[4.071007799999999, 48.2924058]],
    [ "Enedis",[1.3689788914728684, 49.40804003007753]],
    [ "Poste source de Hauts-Clos",[4.068782495807213, 48.27492547800835]],
    [ "Enedis",[-0.7616467806615774, 43.1137191250212]],
    [ "Enedis",[-3.3133480581169077, 47.714384031552136]],
    [ "Enedis",[-1.5169370500000001, 43.47476125]],
    [ "Enedis",[-1.5470794942749682, 43.46219942821659]],
    [ "Robinson",[2.245785044812013, 48.77311192778916]],
    [ "Poste électrique de Moulineaux",[2.2686258078775965, 48.82627029996464]],
    [ "RTE",[2.173256578479508, 48.67795778723541]],
    [ "Enedis",[1.3603248618914396, 49.36205371822816]],
    [ "Enedis",[1.3536574621113189, 49.35870173449107]],
    [ "Enedis",[-0.8616938357328702, 44.54806786915242]],
    [ "SER",[7.603917763014367, 48.65265756583745]],
    [ "Enedis",[1.57136225, 45.39679645]],
    [ "Granissou",[1.227562135360175, 44.12655399501506]],
    [ "Rivals",[1.230100458277839, 44.125001637093995]],
    [ "Enedis",[2.6246118948472605, 47.93585566580482]],
    [ "Enedis",[-0.7385690931345176, 44.589494168971875]],
    [ "Enedis",[-0.7912763325080995, 44.620759389106915]],
    [ "Enedis",[-3.0915215374089744, 47.86179810227095]],
    [ "Enedis",[-3.0806745816940593, 47.863017955201784]],
    [ "Poste électrique de Moliere",[-0.3070084320312071, 47.87498914087229]],
    [ "Vieux Moulin",[5.149119349999999, 45.635234499999996]],
    [ "Chemin du Gua",[5.151441200000001, 45.63255409999999]],
    [ "659396160",[-0.20396070000000052, 46.87784765]],
    [ "RTE",[4.88385691054227, 44.89020444785384]],
    [ "SNCF Réseau",[-0.2800812762982512, 47.871269748934836]],
    [ "Enedis",[2.7012003071156063, 45.19180294517213]],
    [ "Enedis",[2.7384638735520226, 47.93987781803859]],
    [ "Poste source Richardet",[2.582161448392217, 48.83329469197419]],
    [ "RTE",[2.545061761512427, 48.79761929165263]],
    [ "659918879",[-0.13177129547618388, 46.12255606894509]],
    [ "659918902",[-0.22043820000000008, 46.1415019]],
    [ "659930182",[-0.32685521288515423, 46.87923051615313]],
    [ "Enedis",[1.2474458718584813, 48.867396281479515]],
    [ "Enedis",[1.238755937307224, 48.881049750938054]],
    [ "Enedis",[1.2440109691294452, 48.86127708329042]],
    [ "Enedis",[0.18964078426353734, 49.32029408993223]],
    [ "Enedis",[4.883968175923708, 45.065121217088134]],
    [ "Enedis",[4.878721850000002, 45.06570585]],
    [ "Enedis",[4.84530498164586, 45.0683288014478]],
    [ "Enedis",[4.838675, 45.0696154]],
    [ "Enedis",[0.2789842339625777, 49.312288105130186]],
    [ "660328223",[-3.175457830046857, 47.750365382691335]],
    [ "Enedis",[6.288071631234195, 46.18959618051118]],
    [ "660496982",[-0.27629083004365695, 47.08088418350981]],
    [ "Enedis",[0.9625865332706335, 48.7627359884408]],
    [ "Enedis",[-1.4354180456293297, 43.48769143399864]],
    [ "Enedis",[-1.4395148069494734, 43.48467662837892]],
    [ "Enedis",[-1.4338948075017355, 43.48462682655177]],
    [ "Enedis",[-1.4325958000000003, 43.483012849999994]],
    [ "Enedis",[-1.429477659941945, 43.4897742399613]],
    [ "Enedis",[-1.427690231805776, 43.49109939040255]],
    [ "Enedis",[-1.429465980245555, 43.48935435255635]],
    [ "Marais",[5.252591316317999, 45.58754989958244]],
    [ "Denis Papin",[5.2542770999999995, 45.588341099999994]],
    [ "Enedis",[0.70492045, 48.93813165]],
    [ "Enedis",[0.5406348373219239, 49.072431390975915]],
    [ "Enedis",[0.2502935, 49.34617249999999]],
    [ "Enedis",[2.7358822000000003, 50.290494800000005]],
    [ "Enedis",[2.4306596438745003, 49.12714141583189]],
    [ "Enedis",[0.6673674246396554, 49.229606046835414]],
    [ "Enedis",[0.27495507458918833, 49.36935779782897]],
    [ "Enedis",[1.4781919249039202, 49.51758413166795]],
    [ "Enedis",[1.469180729004911, 49.51861475139239]],
    [ "Enedis",[0.5746902370051288, 49.74663553971808]],
    [ "Enedis",[1.2228654998956026, 49.60609350950793]],
    [ "Enedis",[0.9147273217879983, 49.67962881489371]],
    [ "Enedis",[2.751874872358277, 50.28760280729041]],
    [ "Enedis",[2.7411111, 50.284923400000004]],
    [ "Enedis",[2.7545590038004577, 50.256416533123364]],
    [ "Enedis",[2.7597708840893906, 50.25625724734575]],
    [ "Enedis",[2.0148084026226583, 47.94569491989776]],
    [ "Enedis",[5.571446350000001, 46.674147250000004]],
    [ "Enedis",[2.8215586260912104, 46.61725590410969]],
    [ "ZAC La Guiche",[5.567886229467816, 46.66977883567774]],
    [ "Enedis",[0.8302348939084631, 49.88174723935683]],
    [ "Enedis",[0.8344998499999999, 49.88131139999999]],
    [ "Enedis",[0.8268332694890509, 49.88455254248174]],
    [ "Enedis",[4.854679024612272, 45.0599863906932]],
    [ "Enedis",[4.83885185, 45.06289715]],
    [ "Érizole",[5.21008050165101, 45.616846932439714]],
    [ "Enedis",[2.7663638651058315, 50.29023632235435]],
    [ "Expansion 39",[5.572049297422694, 46.681975034130126]],
    [ "Enedis",[1.02667997160548, 49.70565239829819]],
    [ "Enedis",[2.78725286790223, 50.28728834290488]],
    [ "Enedis",[2.7824550700974915, 50.27623350184687]],
    [ "Enedis",[4.8914075354929905, 45.07737807795162]],
    [ "Enedis",[4.844135381995678, 45.04392219023486]],
    [ "Enedis",[4.849418478704233, 45.0521709469198]],
    [ "Enedis",[4.84430945, 45.053791800000006]],
    [ "Enedis",[4.83426835, 45.05108085]],
    [ "Enedis",[4.839964200000002, 45.05947465]],
    [ "Enedis",[2.774734893081975, 50.27669520794231]],
    [ "Enedis",[5.5551544, 47.12408205]],
    [ "Enedis",[4.831664821363843, 45.03304937289419]],
    [ "Enedis",[4.8314775999999995, 45.03838794999999]],
    [ "Enedis",[4.830552909443641, 45.0405527233186]],
    [ "Enedis",[2.8000894173546045, 50.27043305233899]],
    [ "Enedis",[3.1193109000000008, 50.61776055]],
    [ "Enedis",[3.5551379532639507, 48.47685494055135]],
    [ "Enedis",[-1.566111968598, 43.35594993572588]],
    [ "Enedis",[2.78406178598096, 50.262970427805826]],
    [ "Enedis",[2.7634625051561583, 50.277730967763084]],
    [ "Enedis",[3.0859700401569983, 50.58578476582811]],
    [ "Enedis",[4.796226071107499, 45.06568286598672]],
    [ "Enedis",[-1.5777044881628024, 43.311481327539596]],
    [ "Enedis",[-1.5787851123958805, 43.3138253094581]],
    [ "Enedis",[-1.6683104841034992, 43.33790750562921]],
    [ "Enedis",[2.8027088485451164, 50.30723984144696]],
    [ "Enedis",[6.345659421106853, 46.91594210017189]],
    [ "Enedis",[2.7846914687744517, 50.26196271319261]],
    [ "Enedis",[2.812186617519515, 50.284008653584735]],
    [ "Evesa",[2.3251642, 48.84142925]],
    [ "Enedis",[5.265178503389708, 45.58570807693305]],
    [ "Poste électrique de Petit-Bernard",[5.034509186132395, 47.30804981663708]],
    [ "Enedis",[4.42225494691074, 45.481611851522295]],
    [ "Enedis",[2.794213492846478, 50.30297202693912]],
    [ "Enedis",[2.8182668, 50.30693105000001]],
    [ "Enedis",[2.8279485289676787, 50.314296052026286]],
    [ "Enedis",[0.8785465831164951, 49.514397924423264]],
    [ "Enedis",[0.8805186215916764, 49.515431741633414]],
    [ "Enedis",[0.8847018017965003, 49.515494143924066]],
    [ "Enedis",[0.6724048834483822, 49.15906053722851]],
    [ "Arteparc 1",[3.1298952, 50.594939950000004]],
    [ "Enedis",[4.260359772300443, 49.326006679069096]],
    [ "Enedis",[2.800505934179873, 50.26377741007973]],
    [ "Enedis",[-1.4595907669788415, 43.48424279962901]],
    [ "Enedis",[5.875715150000001, 43.448917050000006]],
    [ "SNCF Réseau",[2.9046775099409534, 50.32597148093173]],
    [ "Enedis",[2.738982499999999, 50.30251335]],
    [ "Enedis",[2.7841218791652707, 50.29029435626047]],
    [ "Enedis",[2.557785546377298, 50.34982301563253]],
    [ "Enedis",[2.7934201844266173, 50.236356148009655]],
    [ "Jacques Cartier",[5.209936934517982, 45.62436225157588]],
    [ "Enedis",[1.2787416120390807, 49.31425521903711]],
    [ "Électricité de France",[2.8049171378170588, 44.069893865676775]],
    [ "SRD",[0.5256954712510381, 46.792048268475675]],
    [ "Enedis",[3.1169143668267294, 50.59370270614641]],
    [ "Enedis",[3.13787965, 50.586416449999994]],
    [ "Enedis",[3.0512840594929918, 50.64331356246357]],
    [ "Enedis",[2.5463193666729698, 50.456780349890835]],
    [ "Enedis",[2.3545029520808765, 49.54861329365578]],
    [ "Enedis",[1.3193383111104358, 49.13827313402058]],
    [ "SICAE-Oise",[2.4756789687186367, 49.457936782200136]],
    [ "Roustang",[5.5662651499999996, 46.67355904999999]],
    [ "Enedis",[1.3687282567963126, 43.746490080968485]],
    [ "Enedis",[1.3669743704331565, 43.74161426237784]],
    [ "Enedis",[-1.4741375, 43.47887690000001]],
    [ "Haut du lac P1",[5.134876615947788, 45.62694965424793]],
    [ "Enedis",[1.3631190929066586, 43.7545436201811]],
    [ "Enedis",[3.182756502313532, 50.697017686928845]],
    [ "Enedis",[2.7518904500000003, 50.30105960000001]],
    [ "Enedis",[2.755967432950595, 50.298299616495925]],
    [ "Enedis",[2.7338412059873027, 50.32026067105937]],
    [ "Enedis",[0.6062124742761333, 48.893838359374215]],
    [ "Le Trianon",[5.265092050000001, 45.58655875000001]],
    [ "Enedis",[3.26570604183036, 50.16226773303467]],
    [ "Enedis",[0.33355499207539585, 49.20932240418986]],
    [ "Enedis",[0.31677087623388683, 49.20995808430036]],
    [ "Enedis",[0.32062417935405235, 49.2095665341621]],
    [ "Megevette",[6.4987818743970305, 46.201682968567304]],
    [ "Enedis",[2.623041791979449, 50.27879802667321]],
    [ "Enedis",[4.9103085, 45.50773315]],
    [ "Enedis",[0.9574322070135639, 45.974795501926785]],
    [ "Enedis",[0.943686191465224, 45.986676551479576]],
    [ "Enedis",[0.9446501103289675, 45.97867245168336]],
    [ "Enedis",[5.578466, 46.68167749999999]],
    [ "Escalon",[5.211222481387475, 45.606854706733984]],
    [ "Enedis",[0.5557164309944204, 49.80278779003797]],
    [ "Enedis",[0.2818483784551809, 49.297269094545854]],
    [ "Champagne",[5.7481014, 46.6229896]],
    [ "Poste électrique de Gerstheim",[7.7258903887140224, 48.403720155818554]],
    [ "Poste électrique de Rhinau",[7.675318820426384, 48.28345041520448]],
    [ "Enedis",[3.48739325, 48.48569414999999]],
    [ "Enedis",[3.501554131687367, 48.485271198750446]],
    [ "Enedis",[3.4723286002232836, 48.49885751979199]],
    [ "Enedis",[3.4908372499999984, 48.49731325000001]],
    [ "Enedis",[-1.4909391794750604, 43.54455231887418]],
    [ "Enedis",[1.7498662999999997, 47.3691922]],
    [ "Enedis",[0.36959924340663586, 45.65149131559543]],
    [ "Enedis",[0.38009524225726715, 45.64560823178838]],
    [ "Enedis",[4.8875224500000005, 45.81563020000001]],
    [ "Enedis",[0.4971264201416572, 44.85134222860779]],
    [ "Enedis",[3.882470472041986, 43.610174791276116]],
    [ "Enedis",[4.597245037142644, 48.72236987549526]],
    [ "Enedis",[4.602261600000001, 48.723458099999995]],
    [ "Enedis",[0.8108743709652783, 47.38455859421692]],
    [ "Enedis",[4.577971050000001, 48.72654245000002]],
    [ "Enedis",[4.577512050000001, 48.72504575000001]],
    [ "Enedis",[4.603152160284047, 48.73134551651802]],
    [ "Enedis",[2.98766499310248, 50.756812835515305]],
    [ "Enedis",[-1.6707382126781438, 48.14884792057077]],
    [ "Enedis",[-0.37068257607095256, 43.27352745968063]],
    [ "Croix Blanche",[5.233862969373037, 45.600561053477755]],
    [ "Champoulant",[5.224672103937267, 45.60348347152422]],
    [ "667036255",[5.222183190127361, 45.60450313327064]],
    [ "Enedis",[0.7851001598130841, 49.6750522559017]],
    [ "Poste électrique de Lestarquit",[2.840344371464651, 50.52514582314965]],
    [ "Cheneviers",[5.571967432473357, 46.693999138080095]],
    [ "La Lième",[5.584171067696338, 46.700564909346255]],
    [ "Théodore Vernier",[5.576865869730642, 46.67251832772847]],
    [ "Enedis",[5.567990549999999, 46.6703882]],
    [ "Enedis",[4.5284812053441375, 45.476655654227564]],
    [ "Enedis",[5.562810920106667, 46.686680490399986]],
    [ "Camping",[5.568620132513077, 46.684329192734864]],
    [ "Enedis",[1.2350536647335062, 46.193025143237044]],
    [ "Enedis",[2.7178603321559875, 48.006408801112336]],
    [ "Tillette",[5.743805050000001, 46.61962805]],
    [ "Bourg Soucia",[5.748337899999998, 46.54214665]],
    [ "Enedis",[5.289438728897794, 45.58498410250819]],
    [ "Enedis",[0.8458708015838268, 49.67539192618105]],
    [ "Enedis",[0.8432985367844091, 49.67764207508845]],
    [ "Enedis",[1.4869136000253482, 49.55082625109193]],
    [ "La Crochère",[5.805053784318451, 46.51424739335462]],
    [ "ZA",[5.789174305216286, 46.50234416638041]],
    [ "Enedis",[-0.8422045829607424, 44.65861838037422]],
    [ "Enedis",[1.0382860988390201, 47.793246443363955]],
    [ "Enedis",[1.7593302553848214, 47.36122017056554]],
    [ "Enedis",[2.7650079134935286, 48.1560070692431]],
    [ "Enedis",[3.886689583739284, 43.62367784946843]],
    [ "Enedis",[3.878939910395857, 43.61870948832409]],
    [ "Enedis",[1.561028788871469, 49.84933007874459]],
    [ "Enedis",[0.74675565, 44.40594455000001]],
    [ "Enedis",[1.3673630538242434, 50.034793035499526]],
    [ "La Source",[5.23222402030013, 45.598270316646875]],
    [ "Acacias",[5.226517533481303, 45.59921820684359]],
    [ "ZA 2",[5.235625914447491, 45.59316121700568]],
    [ "Fleming",[5.241275235917158, 45.59670328302685]],
    [ "Saint-Alban Roche",[5.224922756378346, 45.59607787256034]],
    [ "Curtes",[5.2238401, 45.59155884999999]],
    [ "Enedis",[-1.42965355512537, 43.50554463458196]],
    [ "Enedis",[1.740911832255943, 48.08242011518975]],
    [ "Enedis",[1.6221012000000001, 50.73607375]],
    [ "Modane Gare",[6.657343263977208, 45.193530325383946]],
    [ "Enedis",[5.949280666904466, 46.66484270725287]],
    [ "Enedis",[1.861770340851384, 48.08669840974164]],
    [ "Enedis",[1.8596243259377325, 48.090236690755496]],
    [ "Enedis",[-1.6579541610059065, 43.37267120946606]],
    [ "Enedis",[-1.6614124000000001, 43.373451349999996]],
    [ "Enedis",[-1.6212129344567818, 43.38826974815174]],
    [ "Enedis",[-1.6341891282781937, 43.392965989701686]],
    [ "Espace Coudères",[-0.3634908276964129, 43.304368803170775]],
    [ "Les Geargillats",[5.7119369669109785, 46.42739180082455]],
    [ "Enedis",[0.9358590132866128, 45.84894234845524]],
    [ "Enedis",[6.305894850000002, 46.15536590000001]],
    [ "Enedis",[5.9492212, 45.859349550000005]],
    [ "Les Trefles",[6.1465140499999995, 45.96547314999999]],
    [ "SNCF Réseau",[5.234922987816964, 43.628816316815524]],
    [ "Enedis",[-1.4525875777844846, 43.50386879439195]],
    [ "Enedis",[5.2577308, 45.58550135]],
    [ "réséda",[6.396137800000001, 49.0135973]],
    [ "Enedis",[4.237370938077946, 49.28902238082789]],
    [ "Enedis",[4.210445616147042, 49.524131049279084]],
    [ "Enedis",[4.206668856759229, 49.52223737815418]],
    [ "Enedis",[-0.7843943629109902, 44.616379428939695]],
    [ "SER",[7.693422200000001, 48.523083899999996]],
    [ "SER",[7.69346845, 48.5230285]],
    [ "SER",[7.412662468628802, 48.54149424663378]],
    [ "SER",[7.510687442840122, 48.47748514469814]],
    [ "Papeterie",[5.749147222244423, 46.58039571099711]],
    [ "Route de Hautecourt",[5.754662754562349, 46.572035465715636]],
    [ "Enedis",[-3.1282752356579735, 47.74700772556235]],
    [ "Saclay",[2.200298734468284, 48.72036892504027]],
    [ "Enedis",[1.7585063151490863, 49.27811064585803]],
    [ "Enedis",[4.6926578068048785, 45.45764207758439]],
    [ "Enedis",[-1.4815365321872807, 43.49577244225112]],
    [ "671557234",[4.7975797999999985, 44.12144355]],
    [ "Le Clos",[5.190514526235328, 45.59117285326163]],
    [ "Fontanel",[5.191446899999999, 45.589754099999986]],
    [ "Enedis",[2.8434998563129823, 50.105630514635685]],
    [ "Enedis",[2.8458411248566695, 50.10707440523484]],
    [ "Enedis",[5.18955285050777, 45.58394111681388]],
    [ "Enedis",[-1.49198640400011, 43.465766576670475]],
    [ "Enedis",[0.2819850862155103, 48.06635481730249]],
    [ "Enedis",[0.30064970000000085, 48.0791166]],
    [ "Enedis",[1.1022158963753033, 49.71705847818755]],
    [ "Enedis",[1.100568266492126, 49.71792011712839]],
    [ "Enedis",[1.0979099454698196, 49.71939546699068]],
    [ "Enedis",[1.1047541196088853, 49.7155088844953]],
    [ "Enedis",[1.1033069157409596, 49.71797703283699]],
    [ "Enedis",[1.1054830951163686, 49.71939035438826]],
    [ "Enedis",[1.1048044949021554, 49.71429836539529]],
    [ "Enedis",[-1.4878343500000002, 43.5394238]],
    [ "Enedis",[2.5047867, 48.63403699999999]],
    [ "Bourg",[5.7626645, 46.37754700000001]],
    [ "Enedis",[6.35595725, 46.9052832]],
    [ "Enedis",[1.4008334876307535, 49.83166734297907]],
    [ "Enedis",[1.392285776421657, 49.83121719798166]],
    [ "Lotissement",[5.72299195, 46.49846045]],
    [ "Boucq Bas n°2",[5.762539518475513, 48.75052940622718]],
    [ "RTE",[4.7700245500000005, 45.42883489999999]],
    [ "Enedis",[2.0601495976831594, 47.42878631754986]],
    [ "Enedis",[3.483480544227019, 46.8277419924075]],
    [ "Électricité de France",[4.729966400000001, 44.32988740000001]],
    [ "Électricité de France",[4.729856531359195, 44.33068312280948]],
    [ "EDF",[4.72959375, 44.33252625]],
    [ "EDF",[4.729701622917449, 44.33170705305266]],
    [ "EDF",[3.518565640233025, 48.515928539969664]],
    [ "EDF",[3.5163850889279233, 48.51618307757675]],
    [ "Enedis",[2.98158365, 42.7309628]],
    [ "Enedis",[-1.2204186162400992, 43.693228617585355]],
    [ "Enedis",[6.336802850925619, 46.90071551067293]],
    [ "Enedis",[-1.2325457999999998, 43.682918199999996]],
    [ "Enedis",[-1.6306134217179906, 48.121465295461924]],
    [ "Enedis",[-1.2344582999999993, 43.687989400000006]],
    [ "Enedis",[-1.229595024218733, 43.68681001023913]],
    [ "Enedis",[-1.241739002051311, 43.68417843325816]],
    [ "Enedis",[-1.231075570713172, 43.67958116625899]],
    [ "Enedis",[2.810814163665029, 50.305588028629614]],
    [ "Enedis",[6.364858849999999, 46.891167100000004]],
    [ "Enedis",[6.336808849999999, 46.90934359999999]],
    [ "Enedis",[6.340233058527287, 46.907825623871155]],
    [ "Enedis",[6.332952305569629, 46.907684769652946]],
    [ "Enedis",[6.332236027845405, 46.90651753998401]],
    [ "Enedis",[6.33755892045908, 46.90179147084403]],
    [ "Enedis",[6.334392400000001, 46.89875700000001]],
    [ "Enedis",[6.34269225, 46.90064095]],
    [ "Enedis",[6.345203307811884, 46.905441471011855]],
    [ "Enedis",[6.345955050000001, 46.9077049]],
    [ "Enedis",[6.350933200000001, 46.909803600000004]],
    [ "Enedis",[6.3573921, 46.90868634999999]],
    [ "Enedis",[6.363047936546719, 46.90639731710564]],
    [ "Enedis",[6.367294625782382, 46.905149538154454]],
    [ "Enedis",[6.371409673081205, 46.904045260167074]],
    [ "Enedis",[6.360717367109719, 46.90016525065646]],
    [ "EDF",[6.5094874, 45.48550705000001]],
    [ "EDF",[6.510842204583462, 45.49626010385128]],
    [ "Enedis",[0.7208872569727139, 45.88098180316422]],
    [ "Enedis",[2.829260623100768, 42.66977703017036]],
    [ "Enedis",[2.831466954744888, 42.67533178454061]],
    [ "Enedis",[2.818331343413716, 42.664189626095165]],
    [ "Enedis",[2.819616467532947, 42.663978770500904]],
    [ "Enedis",[3.1544793954318893, 50.601851884994694]],
    [ "Enedis",[2.8414228126980787, 42.67462186745565]],
    [ "Sous-station de Mitry-Mory",[2.630076988989775, 48.96668464169515]],
    [ "673572808",[2.4980862651356612, 48.96700385342445]],
    [ "Enedis",[5.3502886213466585, 48.041488522108786]],
    [ "Enedis",[5.633126892966459, 46.440568334349585]],
    [ "Enedis",[5.598230957287078, 46.44351666939591]],
    [ "Chambéria",[5.5611816, 46.464958599999996]],
    [ "Enedis",[1.7328625848221908, 47.33126418233409]],
    [ "Enedis",[1.7349016543512137, 47.33784518550825]],
    [ "Enedis",[0.05466043147651485, 47.923966435835574]],
    [ "Enedis",[5.561180299999999, 46.46495795000001]],
    [ "Enedis",[-0.32411662188041723, 47.8272877411071]],
    [ "Enedis",[3.82483770613803, 43.61128092477468]],
    [ "Enedis",[3.9042589820815077, 43.605358072978696]],
    [ "Enedis",[-0.7392975455142666, 43.593620022182165]],
    [ "Enedis",[-0.7397829786823583, 43.64140904342448]],
    [ "Enedis",[6.352720680598089, 46.93113759224686]],
    [ "Enedis",[6.3483688, 46.9275821]],
    [ "Enedis",[6.341643283851231, 46.926153400958256]],
    [ "Enedis",[6.3415247, 46.92376724999999]],
    [ "Enedis",[6.34134448471355, 46.921452991107216]],
    [ "Enedis",[6.349694697681966, 46.92002614012668]],
    [ "Enedis",[6.335753550000001, 46.9146171]],
    [ "Enedis",[6.34054355, 46.911976499999994]],
    [ "Enedis",[6.35236145, 46.905136299999995]],
    [ "Enedis",[5.6800147999999995, 46.59162525]],
    [ "Lahu Mode Industrie",[5.680820787891947, 46.5903952830591]],
    [ "Aux Craies",[5.687145100000001, 46.58883455]],
    [ "Enedis",[5.691489582343464, 46.58688110843221]],
    [ "Village",[5.70371637858661, 46.586224596917255]],
    [ "Route Château d'Eau",[5.707136045696641, 46.58606061531927]],
    [ "Enedis",[-0.8304803499999992, 43.63160065]],
    [ "Transfo.",[-0.8218164150085047, 43.62372029965537]],
    [ "Enedis",[-0.8187443608925138, 43.63254118295769]],
    [ "Enedis",[-0.8240839913431707, 43.63491008416452]],
    [ "Piételle",[5.711778827208807, 46.56992093991764]],
    [ "Enedis",[-1.0895794582484495, 44.47137308726915]],
    [ "Enedis",[5.597832863269941, 46.543994974888825]],
    [ "Enedis",[5.603845588184927, 46.55865354328452]],
    [ "Enedis",[5.602965000000001, 46.5592008]],
    [ "Église",[5.610916612061906, 46.55976232774553]],
    [ "Dahlia",[7.000777446878943, 43.67371769962889]],
    [ "Enedis",[-1.8839883654743081, 46.841634997677374]],
    [ "Champagne",[5.702217967966509, 46.61753924776008]],
    [ "Charézier Lieffenans",[5.721672611028161, 46.62468312170561]],
    [ "Enedis",[5.166780065450347, 45.629956060895424]],
    [ "Petit Planot",[5.158102384332031, 45.63204249746095]],
    [ "UFRA la Verpillière",[5.1589654000000005, 45.63127715]],
    [ "Menton",[7.493910440146313, 43.78407659414944]],
    [ "Poste électrique de Cronenbourg",[7.694997702654773, 48.60040227526422]],
    [ "Enedis",[-2.7250650499999995, 47.54512765]],
    [ "Publy Gare",[5.643196832681289, 46.649457975235514]],
    [ "Bourg",[5.64815448535394, 46.664251374940015]],
    [ "La Muette",[5.634722399999999, 46.6713186]],
    [ "Combe Vaudrée",[5.642798534860289, 46.666016375350566]],
    [ "Enedis",[5.64484257510404, 46.63186849576989]],
    [ "Sur le Montcel",[5.645278749999999, 46.59751375]],
    [ "SNCF Réseau",[-0.5292451, 48.0033439]],
    [ "Tillette",[5.66425609358993, 46.68998812865489]],
    [ "Villaret",[5.745793000000001, 46.559147949999996]],
    [ "Enedis",[0.863535342758266, 47.39110812314861]],
    [ "Enedis",[4.807898571501061, 45.3560743530215]],
    [ "Enedis",[-1.2543021693697223, 46.478730304805424]],
    [ "Enedis",[-1.40898432968178, 43.79176406219321]],
    [ "Enedis",[-1.4082296683804438, 43.79133925828326]],
    [ "Enedis",[-1.4095254877451178, 43.789576338668454]],
    [ "Marsoutin",[5.638655716542985, 46.60222215100321]],
    [ "Enedis",[-1.3985239958885083, 43.78607282695519]],
    [ "Enedis",[-1.4012608723250402, 43.78560639905327]],
    [ "Chemin de la Batteuse",[5.566704209812185, 46.38886722423911]],
    [ "Enedis",[5.564012216237572, 46.38001070851073]],
    [ "Pompiers",[5.569155071932609, 46.39027999689263]],
    [ "Faubourg",[5.5645418499999995, 46.390272599999996]],
    [ "Enedis",[5.562119124784161, 46.3926693904548]],
    [ "Hospice",[5.567853181052842, 46.394259120020195]],
    [ "Enedis",[5.57341675, 46.39539220000001]],
    [ "Enedis",[5.5719645999999985, 46.39238235]],
    [ "SNCF Réseau",[-0.622107906396126, 48.062952683447854]],
    [ "Enedis",[5.556671075436212, 46.40087625665836]],
    [ "Henry",[5.555052449999999, 46.421970900000005]],
    [ "Enedis",[5.5926680045480275, 46.417475586775595]],
    [ "Enedis",[5.587404239116441, 46.4137040989431]],
    [ "Enedis",[5.588527997207714, 46.412115298928285]],
    [ "Enedis",[6.345791499999999, 46.9020116]],
    [ "Enedis",[5.626035398205726, 46.46480509996722]],
    [ "Enedis",[5.65997935, 46.5198047]],
    [ "Enedis",[5.630286728121523, 46.53779428173179]],
    [ "Enedis",[5.6213821500000005, 46.51296455000001]],
    [ "L'Isle",[5.630373049999998, 46.50486969999999]],
    [ "Écrille Bourg",[5.632069599137721, 46.507672899447165]],
    [ "Enedis",[-1.3555479356284894, 43.86334687070846]],
    [ "Enedis",[5.16782631570894, 43.38169579509644]],
    [ "Enedis",[6.332287419286603, 46.93815506458548]],
    [ "Enedis",[6.317907999999999, 46.938060400000005]],
    [ "Enedis",[6.32959105, 46.93650220000001]],
    [ "Enedis",[6.3242365, 46.935968450000004]],
    [ "Regains 130004",[4.667911380943091, 43.65117715219352]],
    [ "Cizes",[5.792683675225688, 46.394353655159414]],
    [ "En Fourger",[5.788040094680134, 46.38804229636692]],
    [ "Champ Courbet",[5.793051434814795, 46.509102124537534]],
    [ "Enedis",[5.7914726499999984, 46.38949765]],
    [ "Enedis",[5.8423732, 46.392125400000005]],
    [ "Lamartine",[5.864754199999999, 46.3907931]],
    [ "Enedis",[5.86813389098526, 46.38480407159257]],
    [ "Enedis",[5.85961765873335, 46.3834927988226]],
    [ "Enedis",[5.85807756433619, 46.385825619121725]],
    [ "Enedis",[5.8613944, 46.3830978]],
    [ "Enedis",[5.865318950000001, 46.39681315]],
    [ "Enedis",[2.2559945247365762, 48.81966471832438]],
    [ "Enedis",[5.585634884432484, 46.66293784655979]],
    [ "Les Moulins",[5.58135305, 46.6646942]],
    [ "Enedis",[5.574153150000001, 46.6309444]],
    [ "Enedis",[5.57601465, 46.632427299999996]],
    [ "Gare",[5.5865387, 46.715904750000014]],
    [ "Enedis",[5.59276975, 46.73152260000001]],
    [ "La Richère",[5.582097749999998, 46.71889995]],
    [ "Enedis",[5.577731488450536, 46.73096753019082]],
    [ "Enedis",[5.596574499999999, 46.7195461]],
    [ "Enedis",[5.59774575, 46.7341074]],
    [ "Enedis",[5.576265449999999, 46.71741545]],
    [ "Enedis",[5.614094766827996, 46.75134870334183]],
    [ "Enedis",[-1.3894798, 43.79688929999999]],
    [ "Enedis",[-1.390369651144932, 43.795440633295435]],
    [ "Enedis",[-2.719111607160252, 47.546441049508005]],
    [ "Enedis",[-2.6858670256855737, 47.5493755225856]],
    [ "Enedis",[2.7831822, 50.2725448]],
    [ "Enedis",[-1.3817060999999995, 43.79718075]],
    [ "Les Girauds",[5.160324457138227, 45.591800423414355]],
    [ "Saunier",[5.165079357526376, 45.56920096635523]],
    [ "La Croix",[5.163636699999999, 45.58626990000001]],
    [ "SER",[7.739734940912172, 48.58789307345725]],
    [ "676664966",[7.740111302361872, 48.82599311989576]],
    [ "SER",[7.832671046780403, 48.77060786009309]],
    [ "Strasbourg Électricité Réseaux S.A.",[7.9062087000000005, 48.7661958]],
    [ "Enedis",[-1.5474924499999994, 43.411498499999986]],
    [ "Enedis",[5.93161337548205, 46.5486106185987]],
    [ "Salave de Vents",[5.939022977588657, 46.570615873013466]],
    [ "Enedis",[6.020476960941137, 46.463742370499865]],
    [ "La Forêt",[5.85129325, 46.527131350000005]],
    [ "Enedis",[5.939003186342711, 46.557832206212524]],
    [ "Enedis",[6.080599749999999, 46.44500415]],
    [ "Enedis",[6.047336649999998, 46.45613509999999]],
    [ "Enedis",[6.02178815, 46.46415715]],
    [ "Enedis",[-1.3370201124839936, 43.74894282587163]],
    [ "Enedis",[-1.352477283471162, 43.75056765177979]],
    [ "Enedis",[-1.34757265, 43.74897675]],
    [ "Enedis",[2.3092032833333325, 45.493499299999996]],
    [ "Enedis",[5.671216390233236, 46.32921628457239]],
    [ "Enedis",[5.670518157746386, 46.32066891671344]],
    [ "Enedis",[5.646547354362312, 46.31524761615392]],
    [ "Enedis",[5.616793625900307, 46.27472795412568]],
    [ "Enedis",[-1.3212951000000002, 43.74687355]],
    [ "Enedis",[-1.3076242, 43.7449075]],
    [ "Enedis",[-1.3131802755241366, 43.745058853946944]],
    [ "Enedis",[-1.3098665167166705, 43.734719598499886]],
    [ "Enedis",[-1.3114497622890662, 43.73754423143924]],
    [ "Enedis",[-1.3110164499999992, 43.73895745]],
    [ "Enedis",[-1.3143509500000001, 43.74196045]],
    [ "Enedis",[-1.3109005000000002, 43.7427006]],
    [ "Enedis",[-1.325393597935177, 43.75287171806615]],
    [ "Enedis",[-1.3279364012411654, 43.76054221637075]],
    [ "Enedis",[-1.32376825, 43.764708549999995]],
    [ "Poste électrique de Bourbaki",[1.063644872329399, 49.43661611834873]],
    [ "Enedis",[2.9079977011822544, 46.67215379025445]],
    [ "Enedis",[2.604369922436647, 47.671038518653255]],
    [ "SNCF Réseau",[-0.6995635624036713, 48.09353199671399]],
    [ "Enedis",[7.011777858250258, 43.67606001615931]],
    [ "Gare",[2.1611418999999996, 48.76505124999999]],
    [ "Enedis",[-1.3906805287591142, 43.48688179836473]],
    [ "Enedis",[0.7146749863413381, 49.147422849341595]],
    [ "Enedis",[0.6713013173315823, 48.94375267786151]],
    [ "Enedis",[2.123749966440822, 50.07750224957593]],
    [ "Enedis",[-1.3972132024283273, 43.47604971805838]],
    [ "Enedis",[-1.3876024957201374, 43.490468521140684]],
    [ "Enedis",[2.1851672109826654, 48.766300712585966]],
    [ "Enedis",[2.180983828240444, 48.76649169017348]],
    [ "Enedis",[0.6028465754694051, 49.10506566907831]],
    [ "Enedis",[0.6080570548208262, 49.09976621209362]],
    [ "Enedis",[0.6126490943520378, 49.1040993148297]],
    [ "Enedis",[0.6179105187136503, 49.09949539083952]],
    [ "Enedis",[0.6055864362772788, 49.09780383402937]],
    [ "Enedis",[0.6133496949525944, 49.10167523234577]],
    [ "Enedis",[2.2078320165618415, 48.75457064368951]],
    [ "Enedis",[2.226332402020901, 48.763497216921174]],
    [ "Enedis",[2.2180776022071376, 48.755051352297166]],
    [ "Enedis",[2.1304928317363725, 50.07016387864186]],
    [ "Enedis",[2.11413005, 50.072762850000004]],
    [ "Péroseys",[5.56892315, 46.665069100000004]],
    [ "Enedis",[5.539201634135784, 46.64927836557909]],
    [ "Mancy",[5.5462449000000005, 46.66291725000001]],
    [ "Cimetière",[5.568220900544369, 46.65851128326993]],
    [ "Bassin Michel",[5.55135605, 46.66722935]],
    [ "Midi",[5.54384475, 46.66854289999999]],
    [ "Route Macornay",[5.545192613760815, 46.66708597823954]],
    [ "Enedis",[5.537853543330233, 46.65163581686477]],
    [ "Enedis",[1.8599227610174651, 47.948171517711046]],
    [ "Enedis",[-2.0128810687974212, 48.02932078094687]],
    [ "Enedis",[3.0479224490103256, 50.62430169972796]],
    [ "Enedis",[2.548615454844392, 47.69637816713642]],
    [ "Enedis",[2.770252976346309, 42.60999237784004]],
    [ "Enedis",[2.7701991, 42.610001100000005]],
    [ "Enedis",[1.83192935, 48.532482400000006]],
    [ "Poste électrique de Bâton",[5.980808767515334, 45.120774139829]],
    [ "Clos du Village",[5.950539190520003, 45.991698888629124]],
    [ "Enedis",[5.824917529466477, 46.62542942285747]],
    [ "Bourg",[5.85451325, 46.59987449999999]],
    [ "Renas",[7.020530177920687, 43.67106195991425]],
    [ "Enedis",[5.764677731920904, 46.66268779748902]],
    [ "Enedis",[5.775959356521738, 46.66126103333334]],
    [ "Enedis",[5.7742803, 46.653330800000006]],
    [ "Le Martinet",[5.774846074913376, 46.64970655610978]],
    [ "Enedis",[5.879321200011004, 46.707418549755914]],
    [ "Enedis",[5.788862549999999, 46.717985299999995]],
    [ "Enedis",[5.8123553999999995, 46.750315549999996]],
    [ "Enedis",[5.8095446333577465, 46.75624024997411]],
    [ "Enedis",[5.812957661516992, 46.7547609450305]],
    [ "Enedis",[5.7793172, 46.681521350000004]],
    [ "Enedis",[5.779504710530259, 46.67885886979842]],
    [ "Enedis",[5.7930685, 46.68263645]],
    [ "Enedis",[5.778076606070783, 46.64946841758723]],
    [ "Enedis",[0.7185824667663475, 49.2889848012323]],
    [ "Enedis",[-1.1532405437797915, 43.55234431565484]],
    [ "Enedis",[0.33744156172741924, 49.27364927305171]],
    [ "Enedis",[0.3699978874090087, 49.279319179938646]],
    [ "Enedis",[-1.18398155, 43.56610904999999]],
    [ "Enedis",[5.746593416213632, 46.75208523303739]],
    [ "Enedis",[5.725622384270657, 46.75183597314345]],
    [ "Enedis",[5.690980668380396, 46.73419200529698]],
    [ "Enedis",[5.684242371176465, 46.75237003205475]],
    [ "Enedis",[5.663408794720022, 46.731058583430595]],
    [ "Enedis",[5.672358455046964, 46.742975930932815]],
    [ "Enedis",[5.7267527, 46.69609834999999]],
    [ "Enedis",[-1.6154839832032808, 48.140945551202]],
    [ "Enedis",[3.11515175, 50.588820350000006]],
    [ "En Novalet",[5.5227372500000005, 46.67672075]],
    [ "Enedis",[5.4969174500000015, 46.671749899999995]],
    [ "Pierre Morte",[5.521038133397949, 46.68050159835878]],
    [ "Enedis",[5.5595964, 46.69351399999999]],
    [ "Enedis",[5.57489298067569, 46.667383129283756]],
    [ "Enedis",[5.574777817679156, 46.66659807046395]],
    [ "Platane",[5.5834036252199155, 46.667220305635674]],
    [ "Babylone",[5.585737333167958, 46.67206464651679]],
    [ "Camuset",[5.5756381667157, 46.6838826745321]],
    [ "Curie",[5.578623366949707, 46.67492062542592]],
    [ "Les Grangettes",[5.58338595, 46.68104225]],
    [ "Enedis",[0.8809776569833653, 49.26415506474267]],
    [ "Enedis",[0.8302060845527331, 49.35467145510087]],
    [ "Enedis",[0.8401218726472808, 49.35848391565152]],
    [ "Le Rocher",[5.513778100000001, 46.677474000000004]],
    [ "Vieille Saline",[5.521891750000001, 46.68537235]],
    [ "Enedis",[5.53795664253511, 46.682835784842055]],
    [ "Enedis",[5.53920715, 46.67955525000001]],
    [ "Enedis",[5.546390224825042, 46.681636716080725]],
    [ "Enedis",[5.55051245, 46.679851000000006]],
    [ "Magnolias",[5.558500994259615, 46.67960598018267]],
    [ "Marcel Paul",[5.55940845, 46.67571049999999]],
    [ "Enedis",[5.553500719870417, 46.67720478330704]],
    [ "Saubief",[5.52597201430912, 46.67769626906892]],
    [ "Les Cluziaux",[5.526420330056447, 46.674426077470734]],
    [ "Juraparc",[5.538270480895711, 46.67531642995476]],
    [ "En Bresse",[5.538828423352705, 46.67342799431156]],
    [ "Enedis",[5.553764161020319, 46.669315369207524]],
    [ "Enedis",[5.556843683248244, 46.67148072532973]],
    [ "ESAT Perrigny",[5.583241733706281, 46.68185379450523]],
    [ "Enedis",[1.2739813225593208, 49.329385766624085]],
    [ "Enedis",[1.2508953623509773, 49.332235985673854]],
    [ "Enedis",[1.2588752067504994, 49.327964488733244]],
    [ "Enedis",[4.516996398319686, 45.46908755235688]],
    [ "Enedis",[2.622607978797272, 42.44700205069745]],
    [ "Enedis",[-1.0625776999999992, 43.7146053]],
    [ "Enedis",[-1.0593446000000013, 43.7191616]],
    [ "Enedis",[-1.0556540999999997, 43.7209231]],
    [ "Enedis",[1.8450521145186685, 42.71189419057549]],
    [ "Enedis",[1.2781996500000006, 49.330992599999995]],
    [ "Enedis",[1.2751867646001946, 49.33423133746408]],
    [ "Perrotin",[5.138285300000001, 45.63253745000001]],
    [ "Centre Équestre",[5.714435573820974, 46.45855121016664]],
    [ "Le Saint-Cyr",[5.142296766019205, 45.631081050922056]],
    [ "Enedis",[5.59241815, 43.21360380000001]],
    [ "Bourg",[5.658397625466723, 46.68822445537907]],
    [ "Au Tartre",[5.648185349999999, 46.687057599999996]],
    [ "Esplanade",[4.1248834500000005, 45.14262994999999]],
    [ "zonindus",[4.691442029765112, 43.79435104915442]],
    [ "Enedis",[5.044723517958216, 45.67971464645358]],
    [ "Fallavier",[5.120914331973325, 45.62118658050622]],
    [ "Enedis",[-1.4704993673640794, 43.483584421033505]],
    [ "Enedis",[-0.9360815673104117, 43.70194893396505]],
    [ "Enedis",[-0.9362666500000002, 43.69872235]],
    [ "Enedis",[-0.9419917000000001, 43.70532895000001]],
    [ "Enedis",[-0.9154870172206683, 43.7050327376529]],
    [ "Enedis",[-0.9155745449993223, 43.70657982238299]],
    [ "Enedis",[-0.9173671499999996, 43.70594485]],
    [ "Enedis",[-0.9261107499999999, 43.70134989999999]],
    [ "Enedis",[-0.9288771500000002, 43.70238945]],
    [ "Poste électrique de Saint-Illiers-la-Ville",[1.5561154496978475, 48.98508553001537]],
    [ "Enedis",[-1.1403424135936369, 48.265606355025305]],
    [ "Enedis",[-0.9490826423499986, 43.7076998155297]],
    [ "Enedis",[-0.9393957566655072, 43.71088627449327]],
    [ "Enedis",[-0.9380714678670748, 43.71805159550815]],
    [ "Enedis",[2.9504451695972636, 50.36048177007049]],
    [ "Enedis",[2.9453896999999998, 50.36247025]],
    [ "Enedis",[2.9542372926095326, 50.36220986824729]],
    [ "Enedis",[2.9504993902445418, 50.364079510847766]],
    [ "Enedis",[2.9485629999999996, 50.36389899999999]],
    [ "Nogarette",[4.194711840510107, 43.78316114034434]],
    [ "Enedis",[6.022639784924124, 47.263313003663114]],
    [ "Enedis",[5.815087813146165, 46.422615882236194]],
    [ "Les Écuriais",[5.79816735, 46.40623535]],
    [ "Pont de Cuttura",[5.798971899999999, 46.414468549999995]],
    [ "Zoz",[5.793687293619389, 46.39563280179712]],
    [ "Enedis",[5.7890742093915595, 46.39922019627261]],
    [ "Cityses",[5.7937565499999995, 46.40833955]],
    [ "Cimetière",[5.780447250000002, 46.38611145]],
    [ "Planet",[5.775869896473934, 46.39099946678041]],
    [ "Léséna",[5.81194826519615, 46.41043989594858]],
    [ "Église",[5.7932208, 46.400788899999995]],
    [ "Enedis",[5.788625722578276, 46.397057545163236]],
    [ "Les Tilleuls",[5.786065050000001, 46.39984085000001]],
    [ "Enedis",[5.765890505860377, 46.357978982109316]],
    [ "Mayet",[5.79093488345817, 46.39896620618634]],
    [ "Les Chênes",[5.7881285, 46.3993915]],
    [ "ZI Nord",[5.793921250000001, 46.406276150000004]],
    [ "Bas Village",[5.811754932929267, 46.4136171985569]],
    [ "Au Parc",[5.4537505500000005, 46.744017449999994]],
    [ "Enedis",[5.4992066936941715, 46.74000437826126]],
    [ "Enedis",[5.4626738, 46.74026079999999]],
    [ "Enedis",[5.4682321743714075, 46.73404429493293]],
    [ "Enedis",[5.460354009773664, 46.73219047047368]],
    [ "Enedis",[0.284286826994316, 49.726669075744816]],
    [ "Enedis",[0.2799108095666917, 49.72207672459586]],
    [ "Enedis",[0.2907286514340445, 49.73469973782992]],
    [ "Valouse",[5.610103100000001, 46.52134840000001]],
    [ "Fromagerie",[5.5925683, 46.523796850000004]],
    [ "Gare",[5.607943832903578, 46.52015960423309]],
    [ "La Confise",[5.60717445, 46.51675235]],
    [ "Zone industrielle Orgelet",[5.606256300000002, 46.52653915000001]],
    [ "Poste électrique de Berthollet",[2.3277496010268743, 48.80651396340577]],
    [ "Poste électrique de Crétaine",[2.3584988819590493, 48.799265725508725]],
    [ "Enedis",[0.8033398242979837, 49.81338793676161]],
    [ "Dumas",[5.542301896724471, 46.67651178689788]],
    [ "Garenne",[5.127290478915486, 45.60025277581046]],
    [ "Église",[5.130043649999999, 45.596897399999996]],
    [ "EDF",[5.778788720550909, 45.0570883491942]],
    [ "Enedis",[0.26191706472112214, 49.64041917029709]],
    [ "Enedis",[0.2649999339341682, 49.642032849680504]],
    [ "Enedis",[0.2654713618731738, 49.644251173260685]],
    [ "Enedis",[1.808404508618518, 47.74579293805103]],
    [ "Enedis",[1.8092542508220684, 47.74425281195191]],
    [ "Enedis",[1.8182182085566092, 47.7420917652964]],
    [ "Enedis",[1.8043800000000012, 47.74887885]],
    [ "Enedis",[6.22838355, 45.26519745000001]],
    [ "Enedis",[-0.4645236999999999, 43.83786920000001]],
    [ "Enedis",[-0.46318915, 43.84140595]],
    [ "Enedis",[-0.4620074925727445, 43.84783932577022]],
    [ "Enedis",[0.5759343565971776, 46.81629042309734]],
    [ "La Petite Pierre",[6.546990699999999, 49.05643159999999]],
    [ "Enedis",[1.9854751, 48.78221505]],
    [ "Enedis",[1.9855272124084493, 48.782245037385714]],
    [ "Enedis",[1.87398826488934, 47.776664898074564]],
    [ "Enedis",[0.5586832691034118, 44.17337149419187]],
    [ "Virgule",[2.4228722952635136, 48.877822889531124]],
    [ "Dames belles 1",[6.13013458200215, 49.140379920089764]],
    [ "Enedis",[3.085610160260397, 50.59444861527125]],
    [ "Enedis",[1.6221907, 47.778296799999985]],
    [ "Enedis",[6.364718149999999, 47.81249214999999]],
    [ "Enedis",[6.2131736453211355, 45.25358158031551]],
    [ "Haut de Chirens",[5.560016811576922, 45.42782767521153]],
    [ "La Guillletiere",[5.5594716115769245, 45.432423575211544]],
    [ "Enedis",[2.493608780497233, 50.357952680057785]],
    [ "Enedis",[-2.005695119644563, 46.96895432069033]],
    [ "réséda",[6.322379861625402, 49.08001511586984]],
    [ "684303788",[6.226777232342585, 45.283003130571274]],
    [ "Enedis",[-3.0995414000000006, 47.748729100000006]],
    [ "Enedis",[-0.43507931514221526, 43.874542433092174]],
    [ "Enedis",[0.5716516333333334, 44.167283420524015]],
    [ "Enedis",[0.5783150738124038, 44.166679545843806]],
    [ "Enedis",[0.5834602140736863, 44.165169067532034]],
    [ "La Rapée",[2.3931828358214156, 48.8312261722364]],
    [ "Enedis",[0.6716627981932896, 44.179286432798015]],
    [ "Enedis",[0.6807129000000002, 44.1798787]],
    [ "Larsenal",[5.567478169760457, 45.43162749573744]],
    [ "Fagot",[5.567005099999999, 45.402857350000005]],
    [ "Église",[5.0263614843963005, 45.69214077843339]],
    [ "ZAC",[-1.5993660065551345, 49.10112707817029]],
    [ "Enedis",[3.9032700618957157, 43.60955987641529]],
    [ "Enedis",[1.7090184, 50.18289665]],
    [ "Enedis",[-3.575977846039464, 47.86786133058736]],
    [ "Enedis",[-0.6061098500000001, 43.916038949999994]],
    [ "Enedis",[1.9484329000000005, 50.13156504999999]],
    [ "Enedis",[-0.25851617153192263, 47.578628417662145]],
    [ "Enedis",[-0.25974600000000087, 47.579935049999996]],
    [ "Enedis",[-2.087974615370013, 48.00669579317395]],
    [ "Enedis",[5.12595265, 45.71795730000001]],
    [ "Enedis",[-3.08221495, 47.749685]],
    [ "Enedis",[-0.7505121499999999, 43.4996726]],
    [ "Enedis",[-0.7463672679556829, 43.499663228238504]],
    [ "Chalet du Carrelet",[6.7264055836227845, 45.24548228230981]],
    [ "SNCF Réseau",[-1.3086116111449013, 48.06107612726159]],
    [ "Enedis",[1.8863666538082162, 50.96217611997246]],
    [ "Enedis",[1.8903303999999996, 50.96142689999999]],
    [ "Enedis",[-1.7612331000000003, 48.144288949999996]],
    [ "Enedis",[-1.759154695437299, 48.141800160630865]],
    [ "Enedis",[4.8978779, 46.749740450000004]],
    [ "Enedis",[4.69302635, 46.70377190000001]],
    [ "Enedis",[1.3074196500000002, 47.5917881]],
    [ "Enedis",[0.65874785, 44.1367151]],
    [ "Enedis",[3.0261011875282606, 50.63548550226703]],
    [ "Enedis",[1.9867972294691854, 48.58152939529862]],
    [ "Enedis",[1.9896204095268055, 48.58156637502955]],
    [ "Enedis",[1.9893251500000002, 48.58393554999999]],
    [ "Enedis",[1.9855134190593897, 48.58712875487554]],
    [ "Enedis",[5.308457936582431, 46.59872497496992]],
    [ "Enedis",[5.310721630247104, 46.60317810265415]],
    [ "Enedis",[5.254416016873067, 46.594744451063754]],
    [ "Route Nationale",[5.500823250000001, 46.64107055000001]],
    [ "Enedis",[1.3216552364128538, 47.57852640238532]],
    [ "Enedis",[1.3231900033866568, 47.579917602536334]],
    [ "Enedis",[1.3223309050341978, 47.58003726561864]],
    [ "Enedis",[-0.19516812102729594, 43.26689509014118]],
    [ "Enedis",[-0.19929672920863697, 43.270719032642184]],
    [ "Enedis",[-0.1839243189190926, 43.26459493576425]],
    [ "Enedis",[-0.1963485989849388, 43.27120178376176]],
    [ "Enedis",[5.440020216530339, 46.65087989528697]],
    [ "Enedis",[5.4186383000000005, 46.63469385]],
    [ "Enedis",[5.420664700000001, 46.63179425]],
    [ "Enedis",[5.370189837505861, 46.64333729976152]],
    [ "Enedis",[5.2474729999999985, 46.62487295]],
    [ "Enedis",[5.266425567772172, 46.63516516825107]],
    [ "Enedis",[5.262501598635288, 46.64578254732946]],
    [ "Enedis",[5.262351674359349, 46.64284315581271]],
    [ "Roseaux",[5.241727999999999, 45.59183405000001]],
    [ "Enedis",[5.515653398667549, 46.69702895002577]],
    [ "Enedis",[5.454840434620603, 46.67360989774433]],
    [ "Enedis",[5.4558972, 46.67205704999999]],
    [ "Enedis",[5.458148149999999, 46.667476400000005]],
    [ "Enedis",[-0.29230515, 43.289102650000004]],
    [ "Enedis",[-0.29113764999999997, 43.28628275]],
    [ "Enedis",[-0.2812982686921876, 43.28847358020075]],
    [ "Enedis",[-0.26952465000000003, 43.28216235]],
    [ "Enedis",[-0.24591520000000003, 43.272870749999996]],
    [ "Enedis",[-0.2490496415003177, 43.275940305614796]],
    [ "Enedis",[6.3036783000000005, 46.92841525]],
    [ "Enedis",[6.30625083420537, 46.929080294171115]],
    [ "Enedis",[6.305513394495413, 46.92561368318043]],
    [ "Enedis",[6.307949349999999, 46.92352165]],
    [ "Enedis",[6.30726775, 46.92376095]],
    [ "Enedis",[6.304724028660058, 46.920397523317554]],
    [ "Enedis",[6.304650904429215, 46.91786936939834]],
    [ "Enedis",[6.3098513500000015, 46.91484655000001]],
    [ "Enedis",[6.322484400000001, 46.91309965000001]],
    [ "Enedis",[6.32163625, 46.91193035]],
    [ "Enedis",[4.231988250000001, 43.78801815]],
    [ "Enedis",[5.55507165, 46.72786385]],
    [ "687938051",[-3.511384783029319, 47.78142164964783]],
    [ "Enedis",[-3.494585432012466, 47.79498336565324]],
    [ "Enedis",[5.006298821322868, 45.65724025960177]],
    [ "Château",[5.599095058920624, 46.766286871108775]],
    [ "Enedis",[5.589656083433007, 46.76157369909098]],
    [ "Enedis",[5.560333066909041, 46.75614184929408]],
    [ "Enedis",[0.29103795, 49.553401599999994]],
    [ "Enedis",[6.3425611656290934, 46.92245130480741]],
    [ "Super Bissorte",[6.5768780346993845, 45.201720593365025]],
    [ "Enedis",[5.7848513425245685, 46.359659183999085]],
    [ "Enedis",[5.781071649682854, 46.355959583411966]],
    [ "Enedis",[5.760918516766342, 46.357691099879524]],
    [ "Enedis",[5.756840200000001, 46.3523035]],
    [ "Enedis",[5.7338155, 46.3590329]],
    [ "Enedis",[5.71361275, 46.365248449999996]],
    [ "Enedis",[5.812562200000001, 46.39934855000001]],
    [ "En Planchamp",[5.773181827260416, 46.38542561844727]],
    [ "Stade",[5.777823381341005, 46.38384401814856]],
    [ "Crêt du Bief",[5.774210034625862, 46.38108336954409]],
    [ "Enedis",[5.240413146626814, 43.36383756352015]],
    [ "Enedis",[2.9220504000000003, 47.411844450000004]],
    [ "Enedis",[-0.29214658764645923, 43.24334174771759]],
    [ "Enedis",[-0.2908490499999995, 43.24459950000001]],
    [ "Enedis",[-0.28958306573270126, 43.24381567568649]],
    [ "Enedis",[-0.2889645458587669, 43.2441852301994]],
    [ "Enedis",[-0.29778129985762214, 43.24497198332489]],
    [ "Enedis",[-0.2983186305940729, 43.24096749460984]],
    [ "Enedis",[0.5403475800387596, 46.83892618943053]],
    [ "Enedis",[5.61841575, 46.7984226]],
    [ "Enedis",[5.6190008920131245, 46.796120953207684]],
    [ "Enedis",[5.614583101586721, 46.7857374250842]],
    [ "Enedis",[5.686042616203399, 46.80097309779757]],
    [ "Enedis",[5.795318588976539, 46.71869085276093]],
    [ "Enedis",[5.797294463525359, 46.71485478686078]],
    [ "Juraparc 2",[5.5382175183724165, 46.675325170176]],
    [ "En Laye",[5.5043635, 46.641554000000006]],
    [ "Enedis",[5.438737900000001, 46.57620105]],
    [ "Enedis",[5.441337683333332, 46.5795943]],
    [ "Centre",[5.441758050000001, 46.572740550000006]],
    [ "Enedis",[5.55570965, 46.824959050000004]],
    [ "Enedis",[5.559390050000001, 46.8327863]],
    [ "Enedis",[5.4851492847601175, 46.84463494013324]],
    [ "Enedis",[5.471947375650025, 46.75039694339249]],
    [ "Enedis",[5.394110050000002, 46.747335850000006]],
    [ "Enedis",[5.475162097885222, 46.765741216547276]],
    [ "Enedis",[5.422199249999999, 46.7033955]],
    [ "Enedis",[5.461709049999999, 46.73590195]],
    [ "Enedis",[5.44421555, 46.7511511]],
    [ "Enedis",[5.46695255, 46.70996515]],
    [ "Enedis",[5.476906200000001, 46.705715549999994]],
    [ "Enedis",[5.4035278, 46.74754505]],
    [ "Enedis",[1.3103120586234398, 47.57249006333252]],
    [ "Enedis",[-0.2797921, 43.23759845]],
    [ "Le Puy Rouge",[5.902539600000001, 46.500992800000006]],
    [ "Enedis",[5.897258358154254, 46.50156399793994]],
    [ "Enedis",[5.890359749999999, 46.47245195]],
    [ "Enedis",[5.897606600000001, 46.4751674]],
    [ "689484848",[6.385078047855549, 47.722626298728485]],
    [ "Enedis",[6.114889999999999, 46.5988399]],
    [ "Enedis",[-4.576787598660865, 48.376520760340746]],
    [ "Enedis",[-0.2561651999999999, 43.20717644999999]],
    [ "Enedis",[-1.4109668158153565, 46.77138957101377]],
    [ "Château des Près Village",[5.897257543724799, 46.50157897030022]],
    [ "Enedis",[6.0110015500000005, 46.55673665]],
    [ "Enedis",[6.015467200000001, 46.559817800000005]],
    [ "Enedis",[5.946444051689986, 46.59705681661136]],
    [ "Village Chaumusse",[5.940924403581341, 46.58675138306545]],
    [ "Abbaye",[5.921287674889791, 46.53519725605313]],
    [ "Enedis",[5.9198002999999995, 46.5383478]],
    [ "Enedis",[6.00368654090611, 46.626212893697044]],
    [ "Village",[5.9933626, 46.618853400000006]],
    [ "Clos Bechet",[5.991399250000001, 46.61749270000001]],
    [ "Stade",[5.988040936187517, 46.61719402473889]],
    [ "Enedis",[5.948711449999999, 46.60080524999999]],
    [ "Enedis",[5.960110849586033, 46.619747787502156]],
    [ "Favieres",[5.981770299999999, 46.585169449999995]],
    [ "Les Martins",[5.9819956915638235, 46.58758839841411]],
    [ "Enedis",[6.023158612100057, 46.56348041518325]],
    [ "Enedis",[6.018502001725391, 46.55384112535593]],
    [ "Enedis",[6.069847897802491, 46.55771851659847]],
    [ "Enedis",[6.104614, 46.58988485]],
    [ "Enedis",[6.101746499999998, 46.58620369999999]],
    [ "Enedis",[6.089869150000001, 46.58209609999999]],
    [ "Enedis",[6.053255793176269, 46.55209952526893]],
    [ "Enedis",[6.0639934, 46.55599734999999]],
    [ "Enedis",[6.078592650000002, 46.56745675]],
    [ "Enedis",[6.039270684233305, 46.544681801849116]],
    [ "Enedis",[2.9975310812274714, 50.211163618771465]],
    [ "Enedis",[5.820461618652226, 46.81271054067388]],
    [ "Enedis",[5.972246700000002, 46.704562550000006]],
    [ "Enedis",[6.028622200536983, 46.63949317048412]],
    [ "Enedis",[6.030455849999999, 46.63596375]],
    [ "réséda",[6.161466875873408, 49.141258611012304]],
    [ "Enedis",[5.448120115167828, 46.82159696912435]],
    [ "Enedis",[5.484966700284721, 46.84849372496089]],
    [ "Enedis",[5.485383317961007, 46.86447369793416]],
    [ "Enedis",[5.659949116009629, 46.84306949602023]],
    [ "Enedis",[5.584283149999999, 46.9019556]],
    [ "Enedis",[5.5781605, 46.9195306]],
    [ "Enedis",[5.583257800000001, 46.91905974999999]],
    [ "Enedis",[5.59179845, 46.908035899999994]],
    [ "Enedis",[-1.5276659450460566, 43.50355547744577]],
    [ "Enedis",[-1.50994788347263, 43.50216465347324]],
    [ "Enedis",[6.091288500000001, 45.8981005]],
    [ "Enedis",[6.682305366662945, 47.155600250385135]],
    [ "Enedis",[6.651229949999999, 47.138811000000004]],
    [ "réséda",[6.258619301743488, 49.07370273256563]],
    [ "Enedis",[5.757569841760714, 46.45088348140148]],
    [ "Station Épuration",[5.71120932245874, 46.43161565942798]],
    [ "Grand Mottet",[5.7316986345474055, 46.43055096623002]],
    [ "Enedis",[5.7791478321584195, 46.38498645360866]],
    [ "Bouclage ZA",[5.765136699999999, 46.3776755]],
    [ "Centrale béton",[5.71427305, 46.43264715]],
    [ "Delezay",[5.7263096, 46.42239950000001]],
    [ "Enedis",[-1.7070961237807927, 48.11525652556034]],
    [ "Enedis",[5.689596331631838, 46.4744358774973]],
    [ "Enedis",[2.3208673357915734, 48.812353324375074]],
    [ "Enedis",[5.8838065919105755, 46.9502122497061]],
    [ "Enedis",[5.90309635, 46.9890732]],
    [ "Poste électrique de Petit Bourg",[-60.96243685952545, 14.552128686212933]],
    [ "Ancien poste électrique",[-61.10223208122465, 14.619248435780856]],
    [ "Enedis",[0.1223794538338768, 46.07902028107057]],
    [ "Enedis",[-1.0603213754991794, 43.683944596563094]],
    [ "Enedis",[-1.0577771664463003, 43.684021921236805]],
    [ "Électricité de France",[1.4780374240662906, 42.76120928914818]],
    [ "Enedis",[6.509235670544681, 45.496818820321735]],
    [ "EDF",[6.510210275977192, 45.49129810361092]],
    [ "Enedis",[4.500201083373148, 45.46775720006875]],
    [ "Enedis",[5.088504892279254, 44.70348043178259]],
    [ "Enedis",[5.131761700000001, 44.59136530000001]],
    [ "Enedis",[5.117675250000001, 44.60482745]],
    [ "Enedis",[5.0189577212947984, 44.66201688432805]],
    [ "Enedis",[2.5606375244005304, 50.42954605885009]],
    [ "Chez Fauraz",[6.185095400000001, 46.095378249999996]],
    [ "Scierie Brand",[6.149525820601018, 46.07494335292411]],
    [ "Chez Quetant",[6.140071500000002, 46.04865185]],
    [ "Praz Monaz",[6.349575323228577, 45.52368301661016]],
    [ "Chaufferie Sud",[5.073896312083938, 47.30612729364768]],
    [ "Enedis",[5.963675816456915, 48.69354256830146]],
    [ "Enedis",[3.66486525, 49.47226864999999]],
    [ "Enedis",[5.594380289377645, 47.26326162443912]],
    [ "Étang de la Mouche",[5.214287126714087, 46.334539059683706]],
    [ "Poste électrique de Beaufond",[55.72070365760885, -21.051525696514876]],
    [ "Enedis",[0.31682660000000146, 46.580620149999994]],
    [ "Enedis",[0.32176490707945854, 46.58202853044405]],
    [ "Enedis",[0.33948261103732785, 46.587445623925944]],
    [ "Orphelinat",[5.261539599999999, 46.4414788]],
    [ "Enedis",[5.233908425531829, 46.444702595935745]],
    [ "Enedis",[5.2337552, 46.4480188]],
    [ "Dauphin",[5.262649499999999, 45.594070349999996]],
    [ "Million",[5.269329099999999, 45.59073975]],
    [ "Enedis",[3.3590622441277436, 46.43939851821396]],
    [ "Flagy",[6.1629350500074125, 46.01883373159668]],
    [ "Enedis",[3.121384849072388, 50.311393116371285]],
    [ "695931197",[5.074737350172578, 46.196250445103644]],
    [ "Enedis",[4.8367018499183665, 46.34561975745837]],
    [ "Enedis",[1.3099897572678303, 49.73486914614072]],
    [ "Enedis",[1.2399217, 44.125995249999995]],
    [ "AC Pierre Morte",[5.521061215760271, 46.68051914791051]],
    [ "AC Pré Prince",[5.528348551634381, 46.68480466628909]],
    [ "Enedis",[5.5161194183746325, 46.73333872160838]],
    [ "Enedis",[5.312022618761638, 43.365151845641726]],
    [ "Enedis",[-1.339041642009407, 43.74648303885042]],
    [ "Bois Bernard",[5.21997205, 46.3436091]],
    [ "GDF",[5.214975161851069, 46.34006760093638]],
    [ "Enedis",[5.213213775598538, 46.33781110934538]],
    [ "EZ 04",[5.215222850000002, 46.33436390000001]],
    [ "Enedis",[0.4165802253347138, 49.19579340615061]],
    [ "Quebec",[1.4898534807103267, 49.077034404592894]],
    [ "Enedis",[6.961324901569251, 48.27732857477269]],
    [ "Enedis",[0.4526565973958322, 49.143983037825514]],
    [ "Enedis",[0.4618096148562299, 49.14027704737528]],
    [ "Enedis",[3.44509135014013, 48.41173194334832]],
    [ "Enedis",[3.4422917731588694, 48.41501513971521]],
    [ "Enedis",[3.4475139834312736, 48.417978546890616]],
    [ "Enedis",[3.429431578812978, 48.413052032643606]],
    [ "Enedis",[5.768751617972768, 46.30258274771667]],
    [ "Enedis",[5.73877983236399, 46.29251842698074]],
    [ "Enedis",[3.0685797302265834, 50.64514837813596]],
    [ "Enedis",[2.5544207500000007, 50.438330500000006]],
    [ "Enedis",[1.800476038718189, 47.878262717249925]],
    [ "Enedis",[1.7915006832286338, 47.87842204540817]],
    [ "Enedis",[1.7425363134745557, 47.86228747777725]],
    [ "Enedis",[2.8258385999999995, 47.53510525000001]],
    [ "Enedis",[2.8346427000000003, 47.52815679999999]],
    [ "Enedis",[1.801298967177862, 47.87775150065529]],
    [ "Enedis",[1.745164516613855, 47.867590000510084]],
    [ "Enedis",[1.75281425, 47.862225200000005]],
    [ "Enedis",[1.7140632166454475, 47.838477801079804]],
    [ "Enedis",[1.7956856000000012, 47.874462400000006]],
    [ "Enedis",[1.7605907, 47.86258945]],
    [ "Enedis",[1.7985836499999999, 47.87698565000001]],
    [ "Enedis",[1.79710115, 47.87862405]],
    [ "Enedis",[1.7672073500000003, 47.86803275000001]],
    [ "Enedis",[1.7301895013613262, 47.863607333188206]],
    [ "Enedis",[1.7487994356176322, 47.86601966272273]],
    [ "Le Faron",[5.1154585714876, 45.638880879533495]],
    [ "Croix Rouge",[5.13051775, 45.6412251]],
    [ "Savoie",[5.128938665322658, 45.638396074385106]],
    [ "Manon",[5.129717975083296, 45.63984106168267]],
    [ "Joseph Berirand",[5.133353768258292, 45.63765334589935]],
    [ "Enedis",[1.9931149923922253, 43.90100367628826]],
    [ "Enedis",[3.891699872117573, 43.62198436299687]],
    [ "Ideo",[6.112363703529963, 45.9068075685157]],
    [ "La Gymnase",[6.4091715587954905, 45.25694168354597]],
    [ "699259559",[6.409077551914519, 45.25696428387532]],
    [ "Avenue Marzin",[-3.4689756803645553, 48.75662520871684]],
    [ "Enedis",[6.362947883004017, 47.81066444194272]],
    [ "Enedis",[-3.4699958863175078, 47.79349938378753]],
    [ "Enedis",[-3.4782959258021813, 47.79743183451007]],
    [ "Melezeven2",[-3.4821564000000014, 47.792816599999995]],
    [ "Enedis",[-3.4884865104580287, 47.79795412495208]],
    [ "Résidence de Kerprat",[-3.4836218, 47.7863798]],
    [ "Enedis",[-3.4942458000000003, 47.7894091]],
    [ "Enedis",[-3.4886691999999995, 47.7936121]],
    [ "Enedis",[-3.4966670499999997, 47.79851745]],
    [ "CESML",[3.846623172077381, 43.650083392039015]],
    [ "CESML",[3.8243833000000005, 43.64498310000001]],
    [ "Enedis",[-3.481948470185283, 47.79029396174796]],
    [ "Enedis",[-3.4857837, 47.789260049999996]],
    [ "Grandes Sétives Sud",[5.14657455, 45.63746755]],
    [ "Enedis",[5.144879458697123, 45.639602632727005]],
    [ "Enedis",[5.135791130888488, 45.639979548030816]],
    [ "Enedis",[-0.948267, 45.5582357]],
    [ "Enedis",[5.2438784, 46.324964249999994]],
    [ "Enedis",[3.062336552738159, 50.690262823256155]],
    [ "700105104",[-1.4090322, 46.9664433]],
    [ "Enedis",[-1.5558912094199815, 47.22614115072234]],
    [ "Enedis",[-1.55278675, 47.20420215]],
    [ "Enedis",[2.3158197935385814, 48.81730898099607]],
    [ "700183054",[1.4670235390698267, 43.5573510904792]],
    [ "Enedis",[-4.596961931121393, 48.375612257669275]],
    [ "Enedis",[3.0543823410846254, 50.60522118310496]],
    [ "Enedis",[-4.603630238640483, 48.379962729229135]],
    [ "Poste électrique du Pouget",[2.7681198130930347, 44.05903502427818]],
    [ "Rue du Repos",[5.1426602500000005, 45.6354453]],
    [ "RTE",[6.645718478398086, 43.322517911245384]],
    [ "EDF",[2.7683884755783597, 44.05979091743905]],
    [ "Enedis",[4.154941746442125, 43.686620366393605]],
    [ "Enedis",[1.4208363519021476, 47.420233890257165]],
    [ "Enedis",[1.4206340827681445, 47.417938129046426]],
    [ "Enedis",[-2.973616465284384, 48.0676832463047]],
    [ "Tréfimetaux",[5.16701365, 45.74932965000001]],
    [ "Cimetière",[5.1713419499999995, 45.753796900000005]],
    [ "Enedis",[-2.9820141712961195, 48.06771002571861]],
    [ "Enedis",[2.3628876339433518, 47.75393180459861]],
    [ "Enedis",[0.13605888921739118, 47.53045397284058]],
    [ "Enedis",[1.748704432900854, 50.48480645159414]],
    [ "Enedis",[3.0828110893223193, 50.68066677642388]],
    [ "Enedis",[3.0821554997342537, 50.679937720074165]],
    [ "Baume les Grottes",[5.63906286089211, 46.68920662865713]],
    [ "Enedis",[5.628939949775127, 46.68582748330725]],
    [ "Jura Bétail",[5.628592397402597, 46.68537491742424]],
    [ "Route de Crançot",[5.640097949999998, 46.68595555]],
    [ "Sermu",[5.637874069725261, 46.69579094238538]],
    [ "Croix Blanche",[5.664318320449508, 46.68622764725622]],
    [ "Route de Mirebel",[5.6662825860021195, 46.68834842697067]],
    [ "Aigle",[-0.5620021211310853, 47.49287207525381]],
    [ "Waldeck Rousseau",[5.2766344499999995, 45.594089749999995]],
    [ "Enedis",[1.1449818585214775, 42.98905899532235]],
    [ "EDF",[2.646280782195095, 45.364697929482865]],
    [ "Enedis",[-2.9487042998005695, 48.05517763326922]],
    [ "Charnasserie",[-0.5551732328342045, 47.49108096789941]],
    [ "Enedis",[6.594707351198963, 48.13957515009721]],
    [ "Enedis",[6.59910739965548, 48.13271578320363]],
    [ "Enedis",[6.595786082591259, 48.131988977447044]],
    [ "Enedis",[4.274148388665453, 43.694902761393465]],
    [ "Enedis",[4.268576860560967, 43.70709150060243]],
    [ "EDF",[2.349523019011804, 45.34750692432035]],
    [ "Enedis",[3.141058277365028, 50.61919025071493]],
    [ "Enedis",[-1.3074863756221915, 43.65816415886025]],
    [ "Enedis",[-1.3136381000000001, 43.662685]],
    [ "Enedis",[-1.1837480190453327, 44.21395076452901]],
    [ "Enedis",[-1.2228714944693826, 44.19952587102202]],
    [ "Ets Lambert",[5.712250298811928, 46.36602171646272]],
    [ "Enedis",[4.263243300000001, 43.75061675]],
    [ "703443911",[1.4697350500000004, 43.558182]],
    [ "Enedis",[-0.9121219774977658, 44.044130055697615]],
    [ "Enedis",[-0.9169810308373137, 44.041176996023005]],
    [ "Enedis",[-0.9140601810084248, 44.04015189596243]],
    [ "Enedis",[-0.9150685091215246, 44.0381830706555]],
    [ "Enedis",[-0.9103525336079575, 44.03707101680945]],
    [ "Enedis",[-0.9212175410132925, 44.041263637743604]],
    [ "Enedis",[-0.9210423889512798, 44.037311575385395]],
    [ "Enedis",[-0.9117473519146503, 44.03415310775327]],
    [ "Enedis",[5.777959807305776, 47.28866302269106]],
    [ "Enedis",[-0.5313762999999998, 49.293830250000006]],
    [ "Bas Satolas",[5.13105062010371, 45.69157335309448]],
    [ "Route du Chaffard",[5.129093323154222, 45.689165957708745]],
    [ "Rue des Voirons",[6.250529620854803, 46.20294471917401]],
    [ "Enedis",[6.260177835992398, 46.20384402413928]],
    [ "Enedis",[6.2521491000000005, 46.20210945]],
    [ "704177402",[-0.637645821819523, 44.89086539173099]],
    [ "Poste électrique du Bouscat",[-0.6126568114591395, 44.86924704366541]],
    [ "Enedis",[5.919781900000002, 47.229371]],
    [ "La Motte",[5.013393999999999, 45.70322815]],
    [ "Enedis",[6.35697285, 47.80918825]],
    [ "Enedis",[6.360148813536585, 47.8080322174187]],
    [ "Paysant",[0.3782026645185063, 49.24628748053206]],
    [ "J B Barre",[-1.6752295993684418, 48.09603884843709]],
    [ "Glais Bizoin",[-1.672133830018038, 48.09598370691475]],
    [ "Enedis",[1.3398251, 43.486945500000004]],
    [ "Enedis",[1.340708368617787, 43.48833964477836]],
    [ "Enedis",[1.3382152953698556, 43.48560692856603]],
    [ "Enedis",[-0.9206107576760988, 44.027653787849594]],
    [ "Enedis",[-0.9169527545320341, 44.02707218709773]],
    [ "Enedis",[-0.919369942465348, 44.03095698778096]],
    [ "Enedis",[-0.9209867928057743, 44.03185443147997]],
    [ "Enedis",[-0.9216424000000001, 44.0340499]],
    [ "Enedis",[-0.917727422313, 44.037894104879854]],
    [ "Enedis",[-0.9161688720324727, 44.03614784434035]],
    [ "Transf.",[-0.9078670109921139, 44.026808326603714]],
    [ "Enedis",[-0.9035812840086741, 44.027152705732895]],
    [ "Enedis",[-0.9064859969811664, 44.02445360463152]],
    [ "Enedis",[-0.9021088854576756, 44.02265648238018]],
    [ "Enedis",[-0.8974470798532267, 44.02685692227236]],
    [ "Enedis",[-0.894920968535893, 44.02349125404417]],
    [ "Enedis",[-0.9051589334256247, 44.04572552994189]],
    [ "Enedis",[-0.9145372, 44.033484050000006]],
    [ "Enedis",[6.458827609529713, 45.719917659514046]],
    [ "Enedis",[-0.9224738337018309, 44.033080616808206]],
    [ "Enedis",[0.08814315502951146, 49.5217165707313]],
    [ "Enedis",[4.180591288116754, 43.66353701703981]],
    [ "Renault",[1.041078489373237, 49.31639274812303]],
    [ "Enedis",[4.997774899999999, 47.2331972]],
    [ "Enedis",[5.0356451612381505, 47.27513053004409]],
    [ "Enedis",[5.043275481458258, 47.27185539819797]],
    [ "Enedis",[5.05579505, 47.274763899999996]],
    [ "Enedis",[5.069746245348649, 47.278256384313025]],
    [ "Enedis",[5.0594309499999985, 47.27629185]],
    [ "Enedis",[4.389222278853198, 45.4261767898563]],
    [ "Enedis",[0.09433400693155691, 49.52250057769689]],
    [ "Enedis",[-0.9136008999999999, 44.0465958]],
    [ "Enedis",[-0.90864745, 44.04531035000001]],
    [ "Enedis",[-0.882852300000001, 44.0135775]],
    [ "Enedis",[-0.8883168920067035, 44.01517901941907]],
    [ "Enedis",[-0.8804295000000003, 44.015016200000005]],
    [ "Enedis",[-0.87943775, 44.0108808]],
    [ "Enedis",[-0.3859445, 49.17837599999999]],
    [ "Enedis",[-0.35047168015518, 43.32172400832099]],
    [ "Enedis",[3.210763781425696, 49.6294935921551]],
    [ "Enedis",[4.35948837045014, 43.80918840823462]],
    [ "Le Val",[-1.5919568999999985, 49.034341749999996]],
    [ "réséda",[6.3810924999999985, 49.01492124999999]],
    [ "Enedis",[2.2133828500000003, 49.45542875]],
    [ "Joux Verte",[6.764470700000001, 46.199168050000004]],
    [ "Enedis",[5.7043535, 47.9567013]],
    [ "Enedis",[4.9286349, 45.715587299999996]],
    [ "Enedis",[1.166152395760848, 42.96703162829208]],
    [ "Enedis",[-1.5792711753822615, 49.63961400308158]],
    [ "Enedis",[6.325008490512495, 46.8728443811944]],
    [ "Enedis",[4.916041250000001, 45.72766605]],
    [ "Enedis",[3.092345733062182, 50.63777169365709]],
    [ "Enedis",[-0.6504363424793165, 43.93045958300191]],
    [ "Enedis",[-0.6401551, 43.92681125]],
    [ "Enedis",[7.1190910189068175, 49.126995498358355]],
    [ "Enedis",[6.939368881817806, 49.17103738572244]],
    [ "Enedis",[-1.425676011894636, 43.63344042004975]],
    [ "Enedis",[3.0980027961144017, 50.631756371384355]],
    [ "Enedis",[-1.4221432333647956, 43.633363154955305]],
    [ "Enedis",[-1.4270474, 43.630244250000004]],
    [ "Enedis",[-1.4277533911455036, 43.63026101879145]],
    [ "Enedis",[4.877583391528434, 45.751201366921144]],
    [ "Enedis",[4.876723603507951, 45.749862408267404]],
    [ "Enedis",[0.09021732190088666, 49.520773153836096]],
    [ "Enedis",[6.358177540332069, 47.82163797577286]],
    [ "Enedis",[6.358746070478143, 47.81955875414508]],
    [ "Poste électrique du Bras de la Plaine",[55.462543291519154, -21.283059462830327]],
    [ "Enedis",[3.0250848655933207, 50.6553679470483]],
    [ "Enedis",[6.3913169, 47.8160557]],
    [ "Enedis",[6.367893544210319, 47.81740637020803]],
    [ "Casset",[6.481830204526366, 44.98709526783545]],
    [ "Enedis",[6.3739472999999975, 47.81638470000001]],
    [ "Enedis",[6.376855262416132, 47.81920862987053]],
    [ "Enedis",[6.3778170228894195, 47.81660657467781]],
    [ "Enedis",[-4.213400490871974, 48.50894826940799]],
    [ "Enedis",[1.45538981483805, 45.908414195674744]],
    [ "Enedis",[3.2230848, 49.61394855]],
    [ "Aviation",[-0.31434215, 43.303523649999995]],
    [ "Enedis",[-0.3199184016464005, 43.30467918255442]],
    [ "Fanyau 2",[3.1064587910602177, 50.62959054264397]],
    [ "Enedis",[-4.344621739243132, 48.09627108138932]],
    [ "Enedis",[3.102832989766147, 50.62958735856199]],
    [ "Cargoport",[5.0817581, 45.70063210000001]],
    [ "Enedis",[-1.0743095499999997, 45.27109165]],
    [ "Enedis",[6.884048964842796, 49.18287547876166]],
    [ "Rte de Villevieux",[5.45802125, 46.74429924999999]],
    [ "Condamine",[5.6361761, 46.299521049999996]],
    [ "Enedis",[5.018100970311373, 45.8385307350508]],
    [ "Banco",[1.7753020336678138, 49.6871387983945]],
    [ "Enedis",[6.372581390278151, 47.810579263546245]],
    [ "Enedis",[6.369906222670023, 47.813813434659295]],
    [ "Enedis",[6.371512986340769, 47.82471865860879]],
    [ "Enedis",[6.368583635603026, 47.82470011667408]],
    [ "Enedis",[6.383152536988531, 47.80936009145764]],
    [ "Enedis",[6.380690455980279, 47.81295619820882]],
    [ "Enedis",[-0.7515358999999987, 43.4683488]],
    [ "Enedis",[6.3960271590387645, 47.81776892548533]],
    [ "Enedis",[1.5291072499999998, 46.5908847]],
    [ "Enedis",[4.9209406, 45.7224604]],
    [ "Enedis",[5.7348074605208845, 46.417665353151946]],
    [ "Millet",[5.734861582450861, 46.41915992665935]],
    [ "Millet 1",[5.763170207180284, 46.37781066789573]],
    [ "Enedis",[-2.146958475019335, 48.61161599048724]],
    [ "Enedis",[-2.1560211000000016, 48.603154100000005]],
    [ "Enedis",[-2.1567792999999997, 48.5987503]],
    [ "Enedis",[-2.14452225, 48.613624099999996]],
    [ "Enedis",[-2.1473475916811187, 48.60709801825109]],
    [ "Enedis",[-2.1478563, 48.60015334999999]],
    [ "Enedis",[-2.135614951071449, 48.60111573335065]],
    [ "Enedis",[-2.14376594024549, 48.601839681041724]],
    [ "Enedis",[-2.149846557915688, 48.6159063679884]],
    [ "Enedis",[-2.1616299000000003, 48.6056929]],
    [ "Enedis",[-2.1463894499999996, 48.59010054999999]],
    [ "Enedis",[-2.14702449092867, 48.60450977531747]],
    [ "Enedis",[-2.146325159395857, 48.61792041762819]],
    [ "Enedis",[-2.145485042391482, 48.60901076680782]],
    [ "Enedis",[-2.1472892999999997, 48.595384849999995]],
    [ "Enedis",[-2.1345313, 48.59682195]],
    [ "Électricité de France",[2.2607033671065033, 48.93710496740892]],
    [ "Enedis",[5.571994705940899, 46.51014521753923]],
    [ "Enedis",[2.8100388993557206, 48.349930409955995]],
    [ "Enedis",[2.8272815499999995, 48.36008544999999]],
    [ "Enedis",[1.8917363251072588, 46.17806582507889]],
    [ "Enedis",[-3.58983805, 48.51378509999999]],
    [ "Enedis",[5.634478748450267, 46.24114176648624]],
    [ "Enedis",[5.6305719526880535, 46.23810438411833]],
    [ "Enedis",[5.61245096662484, 46.22534946962353]],
    [ "La Chavonne",[5.625740623624449, 46.24146294850347]],
    [ "Le Marais",[5.618765233207128, 46.23513890690969]],
    [ "Enedis",[5.638337287013696, 46.24658631539044]],
    [ "Enedis",[5.6292585, 46.242582250000005]],
    [ "réséda",[6.296948859353547, 49.09871597535069]],
    [ "Enedis",[5.664665, 46.244501850000006]],
    [ "Enedis",[3.099490360223161, 50.626962165487484]],
    [ "Enedis",[3.8576200062703547, 43.668036021057205]],
    [ "Enedis",[3.857026433296741, 43.673729849686225]],
    [ "Enedis",[3.8550283718545537, 43.67667426055556]],
    [ "Enedis",[3.8532306500000004, 43.6750528]],
    [ "Enedis",[3.07462935, 43.207437950000006]],
    [ "Enedis",[3.0754147079464853, 43.20126469990011]],
    [ "Enedis",[3.0961890362970763, 50.62985649662263]],
    [ "Enedis",[4.79788695, 45.1864599]],
    [ "Enedis",[-2.74617845, 47.643213349999996]],
    [ "Enedis",[-2.7482101339421963, 47.64324145118827]],
    [ "Enedis",[-1.1110351042362518, 43.542150863632344]],
    [ "Enedis",[-1.1121906162504767, 43.54674598357469]],
    [ "Enedis",[-1.1154084330505385, 43.558136648762776]],
    [ "Enedis",[-1.1159254405767403, 43.55130141873151]],
    [ "Pont du Lizon",[5.804438830477341, 46.38135466099752]],
    [ "Charton",[5.794718021060728, 46.38364034464374]],
    [ "Les Jardins d'Erbalunga",[9.475091467531865, 42.775707168313495]],
    [ "Enedis",[5.011936101784436, 43.57529664251009]],
    [ "Enedis",[2.357166617759813, 49.86978856004394]],
    [ "Plein Ouest",[-3.1252123999999992, 47.4912112]],
    [ "Enedis",[-1.3945280647224398, 48.7989306376904]],
    [ "Enedis",[5.659273999999999, 46.388549299999994]],
    [ "Enedis",[5.6763509999999995, 46.3847888]],
    [ "Enedis",[5.6772437, 46.390280999999995]],
    [ "Enedis",[5.788193999256666, 46.43101173329876]],
    [ "Moulin",[5.807961899999999, 46.4324021]],
    [ "Enedis",[-1.3971914977383075, 48.79588118131362]],
    [ "L'Église",[-1.3959855164166288, 48.794589954321346]],
    [ "Enedis",[-2.8341748941388007, 48.044282031047054]],
    [ "Roseaux",[5.766373100000001, 45.160008899999994]],
    [ "Enedis",[5.82257185, 46.379703600000006]],
    [ "Enedis",[5.819240543359829, 46.38090942173641]],
    [ "Enedis",[5.791696340369409, 46.461990530472754]],
    [ "Bourg",[5.79531825, 46.46036475]],
    [ "Enedis",[5.792983894151926, 46.460892081195475]],
    [ "Enedis",[5.7732477, 46.46510585]],
    [ "Enedis",[5.7673267202940615, 46.45780374457841]],
    [ "Rue de la Libération",[-1.3980751025980493, 48.7973297012672]],
    [ "Enedis",[5.9583668058408845, 46.40450275417925]],
    [ "Enedis",[5.908579399999999, 46.359921549999996]],
    [ "Enedis",[5.9079071, 46.362470200000004]],
    [ "Enedis",[5.912352167422785, 46.37293074877611]],
    [ "Enedis",[5.912944200000001, 46.37149245]],
    [ "Enedis",[5.9115782, 46.3693125]],
    [ "Enedis",[5.909526550000001, 46.3704815]],
    [ "SRD",[0.30727377709824416, 46.57685338107425]],
    [ "Enedis",[-4.325902229675344, 48.08733448093828]],
    [ "Bocquel",[-0.5567989691651005, 47.48985832920322]],
    [ "Enedis",[6.268444980907876, 46.91382992158433]],
    [ "Enedis",[6.275873130648894, 46.913332346377196]],
    [ "Enedis",[6.327449859495833, 46.92012326505281]],
    [ "Enedis",[6.324235268455284, 46.88343275336585]],
    [ "Enedis",[6.3218597, 46.8828324]],
    [ "La Planche",[-1.395189387691823, 48.792508616794905]],
    [ "Poste électrique d'Esquit",[-0.6104198030083542, 42.963139435705514]],
    [ "Pont d'Esquit",[-0.6110067950557141, 42.96330146724916]],
    [ "Urdos",[-0.5484867, 42.84955065]],
    [ "RTE",[-0.5617213224798498, 42.882385994475094]],
    [ "Électricité de France",[-0.6110639744917244, 42.94332318186863]],
    [ "Poste source d'Eygun-Lescun",[-0.6110062285272321, 42.94413785976424]],
    [ "Enedis",[5.897172099999999, 46.63254935]],
    [ "Enedis",[5.89989315, 46.6413657]],
    [ "Enedis",[5.894240544887256, 46.59726953223212]],
    [ "Enedis",[5.919555339726224, 46.56933083063332]],
    [ "Enedis",[5.916659481488908, 46.565808653452386]],
    [ "Enedis",[5.646972051167069, 46.252060316807636]],
    [ "Enedis",[5.647390032931637, 46.252435301789376]],
    [ "Enedis",[5.6454062500000015, 46.248549350000005]],
    [ "Enedis",[-1.7000507576017332, 48.1222320167835]],
    [ "Enedis",[-1.7003543545039854, 48.12436957415219]],
    [ "Enedis",[-0.5622001030243866, 42.81730082927269]],
    [ "Électricité de France",[-0.5625455196021275, 42.81715643882119]],
    [ "Enedis",[3.080589420352057, 47.018428983944496]],
    [ "Enedis",[4.1101104500000005, 49.292573399999995]],
    [ "Le puisard",[4.131834994149272, 49.301729815275536]],
    [ "Moulin Florent",[4.12900275, 49.299696850000004]],
    [ "AC3D Lacroix",[5.803636699999999, 46.429365100000005]],
    [ "Enedis",[5.652542800000001, 46.25429390000001]],
    [ "Enedis",[5.651607839740463, 46.25353831570427]],
    [ "Enedis",[5.64654755, 46.2567732]],
    [ "Enedis",[5.645647109535808, 46.25624976733195]],
    [ "Enedis",[5.641350100629214, 46.25588696690116]],
    [ "Enedis",[5.641999478287337, 46.252997628970505]],
    [ "Alamans",[5.602273099999999, 46.53125629999999]],
    [ "Enedis",[6.36519301784558, 47.810069907807865]],
    [ "SNCF Réseau",[-0.8319642762506209, 48.11082481864884]],
    [ "Echeillon",[5.6096923, 46.51442955]],
    [ "Enedis",[5.646583086575149, 46.26270272278853]],
    [ "Enedis",[5.646317727963846, 46.259563661285966]],
    [ "Enedis",[5.646929666937539, 46.257965194365845]],
    [ "Enedis",[5.644887154077393, 46.25834951766626]],
    [ "Enedis",[5.644226050000001, 46.25902515000001]],
    [ "Enedis",[5.64349585, 46.26468935]],
    [ "Enedis",[5.629861249999999, 46.27257104999999]],
    [ "Enedis",[5.632347065764303, 46.27275269662517]],
    [ "Enedis",[5.631266499999999, 46.26182265000001]],
    [ "Enedis",[5.641709418468357, 46.26535189790142]],
    [ "Enedis",[5.6525269233076365, 46.25571004658976]],
    [ "Poste électrique des Menus",[2.235383330499644, 48.84766337323913]],
    [ "Poste source de Courcelles",[2.310308344084846, 48.873924318326]],
    [ "Poste source de Puteaux",[2.2368873918123025, 48.87627881346119]],
    [ "Poste électrique d'Argenteuil",[2.2403285665219417, 48.935533202154694]],
    [ "Enedis",[1.3901984360390642, 45.90431435794211]],
    [ "Enedis",[5.662053357414424, 46.26859697241948]],
    [ "Enedis",[5.658858693343594, 46.26315646915481]],
    [ "Enedis",[5.6637393181125315, 46.2618212956512]],
    [ "Enedis",[5.6592641, 46.261867599999995]],
    [ "Enedis",[5.65505055, 46.2618652]],
    [ "Enedis",[5.654616192144388, 46.26341287317335]],
    [ "Enedis",[5.6566551, 46.2664524]],
    [ "Enedis",[5.650602583277471, 46.2605641567371]],
    [ "La cure",[-1.4408784669271473, 46.22364433060409]],
    [ "Enedis",[5.625851600000001, 46.29024465]],
    [ "Enedis",[5.661114904091045, 46.286015629760925]],
    [ "Enedis",[5.660175700000003, 46.28332695]],
    [ "Enedis",[5.661178550000001, 46.28124880000001]],
    [ "Enedis",[5.670871481841885, 46.269045256423176]],
    [ "Enedis",[3.499284050000001, 43.2986205]],
    [ "Enedis",[-1.0624110499999988, 43.547472]],
    [ "Enedis",[5.645071749999999, 46.3047041]],
    [ "Enedis",[5.645229967940942, 46.307514347275486]],
    [ "Enedis",[5.630959331282256, 46.30181723062933]],
    [ "Enedis",[5.659579449999999, 46.302621550000005]],
    [ "Enedis",[5.65516865, 46.294118100000006]],
    [ "Enedis",[5.676865699999999, 46.29220840000001]],
    [ "Enedis",[5.67875287446365, 46.286372110199544]],
    [ "Enedis",[5.676612902279115, 46.285468924158224]],
    [ "Enedis",[5.6817636, 46.2793456]],
    [ "Enedis",[5.6759730500000005, 46.273318550000006]],
    [ "Enedis",[3.1287908790838226, 50.65015946394666]],
    [ "Enedis",[6.30572775, 46.8785089]],
    [ "Enedis",[5.656444099999999, 46.31448065]],
    [ "Enedis",[5.655098751170932, 46.31590333321797]],
    [ "Enedis",[5.658815378062472, 46.31735390802759]],
    [ "Enedis",[5.65499520422463, 46.31965271587154]],
    [ "Enedis",[5.656322079128459, 46.32308854481485]],
    [ "Enedis",[5.678673968295795, 46.32449513259536]],
    [ "Enedis",[5.6888094083583685, 46.333189179389755]],
    [ "Billon",[5.52477835, 46.6759164]],
    [ "Bel Usine",[5.546677101969059, 46.67104316666667]],
    [ "Enedis",[5.5115867157805125, 46.65767175369377]],
    [ "Enedis",[5.520668399999999, 46.66045495]],
    [ "Enedis",[5.447388407891325, 46.79755791565677]],
    [ "Enedis",[5.435661383149573, 46.76530065123892]],
    [ "Rivoire Jacquemin2",[5.529768300000001, 46.673287099999996]],
    [ "réséda",[6.637415265883696, 49.04342065996616]],
    [ "Enedis",[4.178845273758646, 43.7203693144214]],
    [ "Enedis",[-4.621059812340918, 48.385784726275865]],
    [ "Enedis",[4.924629500000001, 45.72264314999999]],
    [ "RTE",[4.871261925128909, 47.57968436432061]],
    [ "Enedis",[6.378194794297529, 47.82431117288569]],
    [ "réséda",[6.159745508674904, 49.1059730651647]],
    [ "Enedis",[5.34799295, 46.89933555]],
    [ "Enedis",[5.38619725, 46.77895095]],
    [ "Enedis",[5.101138199999999, 46.419812799999995]],
    [ "Enedis",[5.103755550000002, 46.422680150000005]],
    [ "Enedis",[5.133049323450818, 46.38002454430244]],
    [ "Enedis",[5.834793669424794, 46.38083044767316]],
    [ "Enedis",[5.8279155, 46.3834013]],
    [ "Enedis",[5.825129, 46.38392555]],
    [ "Enedis",[2.6786343411586353, 48.48088555602993]],
    [ "Enedis",[2.6742659539452864, 48.48222853036719]],
    [ "Enedis",[-1.714368817039055, 48.11156041626313]],
    [ "Enedis",[2.687248534453452, 48.4767866894156]],
    [ "Enedis",[-1.5506270500000001, 46.489846449999995]],
    [ "Enedis",[2.687710399658889, 48.473519978550954]],
    [ "Enedis",[2.692582946166362, 48.48073204983731]],
    [ "Enedis",[2.686467038106473, 48.47900058475956]],
    [ "Poste de Baussenq",[4.852028813574354, 43.61031783304407]],
    [ "Enedis",[-0.9408183201124192, 43.39957127693187]],
    [ "Enedis",[2.7058483290571966, 48.47971416766668]],
    [ "Enedis",[2.4154531459696678, 47.08116311931794]],
    [ "Enedis",[2.4154335456184333, 47.08120438026243]],
    [ "Enedis",[-1.8337436204345816, 48.07928169140083]],
    [ "Enedis",[-0.29644422098695483, 43.29820961350386]],
    [ "Enedis",[5.09565365, 46.73956019999999]],
    [ "Enedis",[5.089104049365173, 46.75119537248351]],
    [ "Enedis",[5.0813095982650545, 46.74300693275106]],
    [ "Enedis",[2.6973389817217726, 48.478068278254]],
    [ "Delon",[4.866550231956032, 46.774947370722835]],
    [ "Belmondo",[4.865801050000001, 46.77514295]],
    [ "Gabin",[4.865836650000002, 46.77514885]],
    [ "Les Portes du Sud",[4.8656248, 46.772191299999996]],
    [ "René Cassin",[4.868372400000003, 46.77241315]],
    [ "Enedis",[3.152898847290188, 50.72719699841018]],
    [ "Poste source",[0.011956265865992381, 42.820280864681756]],
    [ "Enedis",[-0.21776020000000093, 42.9470114]],
    [ "Enedis",[4.8520331500000005, 46.776108900000004]],
    [ "Enedis",[5.0243456955894, 46.7527889886268]],
    [ "Enedis",[5.019754500497614, 46.746632733336725]],
    [ "Enedis",[5.021977303873549, 46.75312116903539]],
    [ "Enedis",[4.989921598930789, 46.749786099956594]],
    [ "Enedis",[4.886561366801351, 46.792762850206124]],
    [ "Enedis",[4.877195014779049, 46.79662173165283]],
    [ "Enedis",[4.867594291051173, 46.786856834279575]],
    [ "Enedis",[4.870150400000001, 46.78615]],
    [ "Enedis",[4.8746675829224495, 46.78868904637198]],
    [ "Enedis",[4.85694325, 46.783990550000006]],
    [ "Enedis",[4.856631676023076, 46.78313796663109]],
    [ "Enedis",[4.8513045, 46.778922300000005]],
    [ "Enedis",[4.844784100417997, 46.779892216309484]],
    [ "Enedis",[4.847052943810156, 46.77626951566275]],
    [ "Enedis",[4.84357485707886, 46.7712777721933]],
    [ "Enedis",[4.868713952211633, 46.78231501607181]],
    [ "Enedis",[4.8718782, 46.77816964999999]],
    [ "Enedis",[4.867026000000003, 46.77642]],
    [ "Enedis",[4.8626930807383175, 46.76322135414259]],
    [ "Enedis",[4.860824654930744, 46.76673080768585]],
    [ "Enedis",[4.858990564654938, 46.76704572926128]],
    [ "Enedis",[4.857415050000001, 46.766809650000006]],
    [ "Enedis",[4.854976450000001, 46.7654508]],
    [ "Enedis",[4.859435047514138, 46.77071982754416]],
    [ "Enedis",[4.853914099999999, 46.776604400000004]],
    [ "Enedis",[5.697566414837861, 47.27930484404781]],
    [ "Champ de Mars",[4.667948699999998, 45.2417192]],
    [ "Enedis",[5.115603350000001, 46.6776986]],
    [ "Enedis",[5.049665224598145, 46.71177344528193]],
    [ "Enedis",[5.0498469, 46.705795200000004]],
    [ "Enedis",[6.51840895, 46.131216599999995]],
    [ "Enedis",[5.15891806478931, 46.55028288335065]],
    [ "Enedis",[5.0143812, 46.69837605]],
    [ "Enedis",[4.969821583474646, 46.703788230198306]],
    [ "Enedis",[4.940359582398852, 46.73784083184171]],
    [ "Enedis",[4.932929129860401, 46.741243907717624]],
    [ "Enedis",[4.929325936937933, 46.73927203354805]],
    [ "Enedis",[5.166480452095423, 46.64530206092844]],
    [ "Enedis",[5.058733242640681, 46.66575422541453]],
    [ "Enedis",[5.10181136127338, 46.69229816623662]],
    [ "Enedis",[0.11079825000000001, 49.37612489999999]],
    [ "Électricité de France",[-0.09975382749934601, 42.997909909292936]],
    [ "Enedis",[4.8606119337987295, 46.81420873871478]],
    [ "Enedis",[4.8688044, 46.819558400000005]],
    [ "EMBARCADERE",[4.854736190786369, 46.81155488375212]],
    [ "BOIS ZI NORD",[4.857205350000001, 46.814739450000005]],
    [ "Enedis",[4.8588078, 46.8253074]],
    [ "Enedis",[4.859601649999999, 46.816432]],
    [ "Enedis",[4.864338205797354, 46.81382032519907]],
    [ "CROIX RENAUD",[4.8511270574256375, 46.821083022557524]],
    [ "Enedis",[4.856858272136487, 46.80685611713837]],
    [ "CHARPAK-N814",[4.853274481608878, 46.8205551024532]],
    [ "Enedis",[4.856034233309552, 46.81737610444682]],
    [ "Enedis",[4.8672528, 46.8273523]],
    [ "Enedis",[4.855733649999999, 46.82136565]],
    [ "Enedis",[4.8646711954616215, 46.824053019779534]],
    [ "Enedis",[4.869151500000001, 46.82255405]],
    [ "BECQUEREL",[4.853035949999998, 46.81448360000001]],
    [ "Enedis",[4.862114034624504, 46.81998497888732]],
    [ "SIVIGNON",[4.850794449999999, 46.817477749999995]],
    [ "LIPPMANN-N512",[4.85317280282573, 46.81553518416796]],
    [ "Enedis",[4.8698862499999995, 46.83051075000001]],
    [ "Enedis",[4.85734595, 46.823834749999996]],
    [ "ROUTE DE CRISSEY",[4.862904199999999, 46.8079175]],
    [ "ZA ESPACE THENARD",[4.86017083596185, 46.80667503250594]],
    [ "Enedis",[4.950847120616897, 46.86262567507837]],
    [ "Enedis",[4.971717149999999, 46.9040038]],
    [ "Enedis",[4.981236947765735, 46.90844501681921]],
    [ "SHEM",[-0.06944905973455724, 42.95315288256059]],
    [ "Poste électrique de Soulom",[-0.0689919749322236, 42.9528901022354]],
    [ "Poste source",[-0.06883937725595948, 42.95325691638661]],
    [ "Enedis",[3.9168084499999987, 43.617932350000004]],
    [ "Enedis",[3.915696095031785, 43.61607651987108]],
    [ "Enedis",[4.990986805751023, 45.82959225716078]],
    [ "Enedis",[4.9903290999999985, 45.827262399999995]],
    [ "Enedis",[5.843199394811723, 46.34290808332376]],
    [ "Enedis",[5.72159065, 46.30201565]],
    [ "Enedis",[5.740452640152085, 46.29911345823515]],
    [ "Enedis",[5.7587325544696215, 46.32438796760168]],
    [ "Enedis",[-1.7007452293871228, 48.11757952450536]],
    [ "Poste Electrique de Mont-Pinson",[4.438376977333646, 49.453463524601794]],
    [ "Enedis",[2.7118613094927047, 48.47948309268069]],
    [ "Enedis",[2.7084828247584594, 48.473707341572]],
    [ "Enedis",[4.9431897182940805, 46.79926340372654]],
    [ "Enedis",[4.969840744098183, 46.81829553229676]],
    [ "Enedis",[4.940399899999999, 46.79953454999999]],
    [ "Enedis",[4.926629384759726, 46.80711738751393]],
    [ "Enedis",[4.9469235000000005, 46.813518800000004]],
    [ "Enedis",[4.989457378453738, 46.82878426023615]],
    [ "Enedis",[5.011174800000001, 46.85111740000001]],
    [ "Enedis",[4.992464466666666, 46.83785905036711]],
    [ "Enedis",[4.940885495175101, 46.814577219297256]],
    [ "Enedis",[4.943622700000001, 46.809721]],
    [ "Enedis",[4.966530446504269, 46.821092168032344]],
    [ "Enedis",[4.914996143023339, 46.83334491732376]],
    [ "Enedis",[4.96878155, 46.8138923]],
    [ "Enedis",[4.986612799613148, 46.83273746650528]],
    [ "Enedis",[5.0179477000000015, 46.856464900000006]],
    [ "Enedis",[4.971682125268263, 46.8229522132925]],
    [ "Enedis",[5.00672015, 46.84655685]],
    [ "Enedis",[5.962396072903354, 46.395046035043144]],
    [ "Enedis",[-1.4939215216026878, 46.849706721384955]],
    [ "Enedis",[5.064242538926889, 46.607153224458855]],
    [ "Enedis",[5.133844727717327, 45.69521247270455]],
    [ "Enedis",[4.920057299999999, 45.76150995]],
    [ "Enedis",[4.892583432337812, 44.03322203078684]],
    [ "Enedis",[3.3478272169586742, 48.29579545523367]],
    [ "Enedis",[3.368263037299873, 48.29255298067111]],
    [ "Enedis",[3.2042398922608517, 48.32161307858329]],
    [ "Enedis",[3.3703786742878328, 48.291056946029535]],
    [ "Enedis",[3.1986546, 48.325126149999996]],
    [ "Enedis",[3.369385076473582, 48.2961072052078]],
    [ "Enedis",[3.3383847, 48.290680449999996]],
    [ "Enedis",[3.3068201499999996, 48.28732354999999]],
    [ "Enedis",[-1.1635597000000002, 45.8527293]],
    [ "Enedis",[3.0291974831472093, 50.63214286112204]],
    [ "Enedis",[6.7679502841123185, 47.57832334750288]],
    [ "Enedis",[3.0334912903201547, 50.633830567644196]],
    [ "Enedis",[3.0262073805744407, 50.62248754751168]],
    [ "Enedis",[3.920367098100104, 50.279175954903536]],
    [ "Enedis",[3.9202923477039713, 50.27917285563046]],
    [ "Enedis",[3.9202260044356176, 50.27917001537692]],
    [ "SNCF Réseau",[-0.9232193499999999, 48.05137235]],
    [ "Senicroze",[4.228612600546602, 44.970229730889166]],
    [ "Enedis",[3.073358639126671, 49.05129221963575]],
    [ "Enedis",[3.3106854500000003, 48.4100661]],
    [ "SNCF Réseau",[3.56423589958434, 49.12555314299221]],
    [ "Freycenet",[4.215482897229201, 45.077845542698]],
    [ "Fontaines",[5.0817789499999995, 45.6622761]],
    [ "Bourg",[5.07961245, 45.66521355]],
    [ "Enedis",[5.077908900000001, 45.66774529999999]],
    [ "Cimetière",[5.075958467177117, 45.66627272262372]],
    [ "Colas Rail",[5.092810749999998, 45.6555278]],
    [ "Enedis",[5.110528, 45.63945465]],
    [ "Enedis",[5.109474499999999, 45.63948925]],
    [ "Gaic",[5.102468416595363, 45.640609700369225]],
    [ "Enedis",[6.3625605187028915, 47.81431269830595]],
    [ "Enedis",[6.358324647328589, 47.81451738205718]],
    [ "Enedis",[6.364230416226251, 47.81547907228615]],
    [ "Enedis",[6.364791806149421, 47.822539282252265]],
    [ "RTE",[-0.4470516500000001, 42.93280500000001]],
    [ "SHEM",[-0.4467818813952244, 42.93262983234339]],
    [ "Orvault",[-1.6531583348657675, 47.283276760418836]],
    [ "Enedis",[-0.439673408927232, 43.34011038398975]],
    [ "Enedis",[4.9191229767677, 43.97363940338093]],
    [ "Enedis",[5.201395695062192, 48.80516901555723]],
    [ "Enedis",[5.210854033503059, 48.79064270030964]],
    [ "Poste électrique de la Morandie",[0.34640519648829066, 45.63225026116789]],
    [ "Enedis",[1.2831139225360169, 48.817188584202874]],
    [ "Enedis",[5.199034335436314, 48.754183483649406]],
    [ "Enedis",[5.175829933375725, 48.76320040020501]],
    [ "Enedis",[5.174618379378758, 48.76560013098954]],
    [ "Enedis",[5.14362195, 48.76093570000001]],
    [ "Enedis",[5.144247050000001, 48.77371289999999]],
    [ "Enedis",[5.147488999999999, 48.771413949999996]],
    [ "Enedis",[5.156982153697787, 48.77558762237232]],
    [ "Enedis",[5.164462200000001, 48.77760155]],
    [ "Enedis",[5.172739364170422, 48.773046931814356]],
    [ "Enedis",[5.177284650000001, 48.777435999999994]],
    [ "Enedis",[5.187094943227992, 48.77261887512556]],
    [ "Sassis",[-0.0170683, 42.8787971]],
    [ "Enedis",[2.840266197021906, 48.36359194763791]],
    [ "Enedis",[4.003648349999999, 49.2143468]],
    [ "Enedis",[2.9191475210375257, 42.62738241240681]],
    [ "Enedis",[2.9205335999999997, 42.62738135]],
    [ "Enedis",[-0.6118167458247336, 43.6369527739333]],
    [ "Enedis",[-0.6119283307842097, 43.63938149699492]],
    [ "Enedis",[-0.61887815, 43.64085155000001]],
    [ "Électricité de France",[0.21742772190526016, 42.94131976035174]],
    [ "Enedis",[1.0988934562457133, 44.108026081378796]],
    [ "Enedis",[-0.60004835, 43.664167400000004]],
    [ "Blaise",[6.1965661640077, 46.10667816677488]],
    [ "Les Fruitieres des Bornes",[6.218634849999999, 46.093317249999984]],
    [ "Le Chaumet",[6.208840245611744, 46.05347733118168]],
    [ "RTE",[2.6626550500000006, 48.8423323]],
    [ "Poste source de Coupvray",[2.7861856075912614, 48.88075523376365]],
    [ "Poste source de Génitoy",[2.7337747073616265, 48.83262474654134]],
    [ "Enedis",[-0.5965136815916527, 43.682292171423995]],
    [ "Enedis",[4.28966795, 44.667533399999996]],
    [ "Curtil",[5.4834159499999995, 45.553536349999995]],
    [ "Enedis",[2.709305449566793, 50.38865911821302]],
    [ "Enedis",[3.1206061887127077, 50.59442996309789]],
    [ "Enedis",[3.1238527499999997, 50.594899999999996]],
    [ "Enedis",[2.414586125101969, 46.929442380511716]],
    [ "La Tuilerie",[2.352284222352054, 46.55055496818278]],
    [ "Enedis",[2.334048481640416, 46.55660113049835]],
    [ "Enedis",[2.8920243484967183, 50.68825129658646]],
    [ "Enedis",[-0.7754706880170061, 43.48439897042277]],
    [ "Enedis",[-0.7798456625944152, 43.48448808655913]],
    [ "Enedis",[-0.7901013999999998, 43.485437049999994]],
    [ "Enedis",[1.1595311500000005, 49.36150165]],
    [ "Enedis",[1.3668162819550953, 43.653655948410744]],
    [ "Poste source du Vieux-Port",[5.369339012106306, 43.28971050480325]],
    [ "Poste source de Mazargues",[5.404126620036539, 43.2541429556796]],
    [ "Belle-de-mai",[5.388441653799007, 43.310475237775904]],
    [ "Enedis",[6.278907480723983, 46.20421383960227]],
    [ "Enedis",[-1.5048812000000014, 43.44953785]],
    [ "Enedis",[-1.50112745, 43.44797065000001]],
    [ "Enedis",[-1.5129238000000003, 43.445021499999996]],
    [ "SNCF Réseau",[5.344705922143914, 43.35978369807172]],
    [ "734714465",[1.959480512963178, 47.93352551956832]],
    [ "Enedis",[-1.86353585, 46.6180816]],
    [ "Enedis",[4.91329825, 44.911910700000014]],
    [ "Enedis",[6.242657650000001, 46.889312450000006]],
    [ "Enedis",[6.240034000000001, 46.8859948]],
    [ "Enedis",[6.2688679, 46.87722925]],
    [ "Enedis",[6.332942215185921, 46.84958977375342]],
    [ "réséda",[6.18856942538065, 49.12165234569018]],
    [ "Enedis",[6.389298915971835, 46.9193409537792]],
    [ "Enedis",[6.386283750000001, 46.87281625000001]],
    [ "Enedis",[6.3838985500000005, 46.87219185000001]],
    [ "Enedis",[6.340494137828145, 46.89788141972136]],
    [ "Enedis",[6.258433009756445, 46.18469041012579]],
    [ "Sorbier",[2.67675085, 48.829790200000005]],
    [ "Cotinus",[2.6778539999999995, 48.828797300000005]],
    [ "Enedis",[2.988867599999999, 49.57536925]],
    [ "Enedis",[2.147603834408575, 48.635995671247485]],
    [ "Enedis",[6.374903718048327, 46.86699019415574]],
    [ "Enedis",[1.6044138777468615, 50.73559550608766]],
    [ "Pi Buchillons",[6.265347772540024, 46.19719006451262]],
    [ "Enedis",[6.261066399999999, 46.2070531]],
    [ "Enedis",[1.6056788797721375, 50.81357301313164]],
    [ "Peguet Z.I.",[6.267761999999999, 46.1992897]],
    [ "réséda",[6.113097566924735, 49.08430487805462]],
    [ "Venelle Montaigu",[-0.3627292239641651, 49.17175377367394]],
    [ "Enedis",[-1.383115267934389, 43.36275349122969]],
    [ "Enedis",[-1.3830963800166571, 43.36270980404546]],
    [ "Enedis",[-1.3800502060419455, 43.36242633163903]],
    [ "Enedis",[-1.4056991, 43.355358349999996]],
    [ "Enedis",[-1.4056834452122193, 43.357374646255664]],
    [ "Enedis",[-1.4041322499999989, 43.35687259999999]],
    [ "Enedis",[-1.404907659365295, 43.359181268865356]],
    [ "Enedis",[-1.4042879911321777, 43.36150181886478]],
    [ "Enedis",[-1.3956135328123032, 43.35751875217566]],
    [ "Enedis",[-1.408198748445997, 43.36591270861585]],
    [ "Enedis",[-1.3988808619558977, 43.35523597554466]],
    [ "Enedis",[-1.3964256969638493, 43.365024817014024]],
    [ "Enedis",[3.237695674038986, 49.59513546806784]],
    [ "Enedis",[-1.4132338500000003, 43.356198150000004]],
    [ "Enedis",[-1.4096154045685607, 43.3619211438754]],
    [ "Enedis",[6.215559059756778, 46.1846902689684]],
    [ "Enedis",[2.2128484871689205, 48.89805632462205]],
    [ "Enedis",[-1.4005102000000003, 43.355823150000006]],
    [ "Enedis",[-1.3970761313189912, 43.35540011973015]],
    [ "Enedis",[-1.3907272294353903, 43.356058694343226]],
    [ "Enedis",[-1.393152077529095, 43.361319393881566]],
    [ "Enedis",[-1.4066699674689715, 43.36434676987949]],
    [ "Enedis",[0.47835275000000016, 47.6051166]],
    [ "Enedis",[0.47789925645223713, 47.60777957987299]],
    [ "Enedis",[0.4768323911795863, 47.609219631295346]],
    [ "Enedis",[0.4720086, 47.607023049999995]],
    [ "Enedis",[0.47423870000000024, 47.602374749999996]],
    [ "Enedis",[-1.4747283999999985, 43.341201999999996]],
    [ "Enedis",[0.4829723499999988, 47.5926088]],
    [ "Enedis",[-0.8147933516462151, 44.521538517513605]],
    [ "Enedis",[2.2724929606294393, 49.8811513370732]],
    [ "Enedis",[-1.4053196000000001, 43.3365274]],
    [ "Enedis",[6.688354081474895, 45.863907579257386]],
    [ "HP",[5.1662344963362985, 45.6254965180313]],
    [ "Enedis",[-4.430152363265306, 48.42628221360545]],
    [ "Enedis",[-4.432256885165694, 48.42660433859948]],
    [ "Poste de Vitry-Nord",[2.4064356640407993, 48.80522453991589]],
    [ "Enedis",[0.57468375, 46.83433745]],
    [ "Poste électrique des eaux bonnes",[-0.3920015545903327, 42.97428064601136]],
    [ "Poste électrique d'Espalungue",[-0.416253573936933, 42.98129522322173]],
    [ "Enedis",[-0.3907580000000001, 42.972927799999994]],
    [ "Enedis",[6.266520683859016, 46.17221228280427]],
    [ "Geredis",[0.06171586572205127, 46.11017026710332]],
    [ "Enedis",[2.329297450364849, 50.72648795382667]],
    [ "Enedis",[7.101398736874049, 43.58424062457487]],
    [ "Enedis",[-1.3504801165022045, 43.670554283186114]],
    [ "Enedis",[-1.3543845500000002, 43.674717050000005]],
    [ "Enedis",[3.064959277999271, 50.21718499606106]],
    [ "Enedis",[2.909982611959119, 50.14816369991815]],
    [ "Enedis",[1.9288313256928455, 48.83295775041438]],
    [ "Enedis",[2.7628488776685307, 47.10197839696217]],
    [ "Enedis",[2.83410335, 42.68286315]],
    [ "Enedis",[-1.203168678210772, 43.362021191662585]],
    [ "Enedis",[-1.2028235999999999, 43.36565914999999]],
    [ "RTE",[1.9094453457016582, 47.84856756875775]],
    [ "Enedis",[2.945798457529289, 42.700192504780595]],
    [ "Enedis",[2.9444167692824115, 42.69894472239296]],
    [ "Enedis",[2.94437785, 42.69992919999999]],
    [ "Enedis",[-0.47836437484615507, 43.22276271790351]],
    [ "Enedis",[2.8055178869400943, 42.67745241578082]],
    [ "Enedis",[5.006694976480358, 47.270864180098684]],
    [ "Enedis",[5.0101076, 47.26850495000001]],
    [ "Enedis",[5.009191667268366, 47.26901249327513]],
    [ "Enedis",[5.012206067893713, 47.26953793060543]],
    [ "Enedis",[-1.2044110043358638, 43.510945330343766]],
    [ "Enedis",[2.830843927360441, 42.65732211541466]],
    [ "Enedis",[-1.11400485, 43.5491569]],
    [ "Enedis",[0.5413742791342588, 46.83428500207739]],
    [ "Enedis",[2.9045558000000002, 42.744422500000006]],
    [ "Enedis",[3.6643118723351007, 47.057110953145134]],
    [ "Enedis",[-1.2738520618503135, 43.58106572540487]],
    [ "Enedis",[2.1512260735034805, 50.81128450723594]],
    [ "Enedis",[1.0581067803125075, 46.63083261465271]],
    [ "Poste électrique de Carrières",[2.44135895953624, 49.243065127214585]],
    [ "Enedis",[0.8277694999999999, 49.4530939]],
    [ "Enedis",[-1.159642939086079, 46.55548257802736]],
    [ "Enedis",[0.32486246190725077, 46.647233940998824]],
    [ "Enedis",[-1.586960551302085, 43.43958185959315]],
    [ "Enedis",[3.10735295, 47.370402449999986]],
    [ "Enedis",[-4.623386339306357, 48.37980340064225]],
    [ "Enedis",[-1.2096281671686848, 43.63017300479929]],
    [ "Docteur Favre",[6.237750266666666, 46.19799636666667]],
    [ "Enedis",[2.891324370283481, 42.60267406661166]],
    [ "Enedis",[-0.6002378059791361, 43.17272437352079]],
    [ "Enedis",[2.0158493, 47.959873200000004]],
    [ "Enedis",[0.7022256222015365, 47.61769387294236]],
    [ "réséda",[6.095719509279777, 49.078155824399815]],
    [ "Montcul",[5.127497750000001, 45.7131934]],
    [ "réséda",[6.305964131458931, 49.17461894363485]],
    [ "Poste source de Saint-Cézaire",[4.335646222713532, 43.81133702087468]],
    [ "Enedis",[3.013540034470407, 50.516598336922065]],
    [ "Enedis",[6.462757755700188, 46.99401621531339]],
    [ "Enedis",[3.0185924424771966, 50.51274337449994]],
    [ "Poste électrique de Rupéreux",[3.3310378369763325, 48.642115905525316]],
    [ "Poste électrique de Vingeanne",[5.368649895427944, 47.51109441158684]],
    [ "Rivoisières",[5.126954989324115, 45.71384908122485]],
    [ "Enedis",[-1.317044038628905, 43.38321703899231]],
    [ "Station de Conversion Tourbe",[-0.26201129188271716, 49.11086277136321]],
    [ "Enedis",[2.9938811144401765, 50.68301577683617]],
    [ "Enedis",[6.440527379019168, 46.22299438805]],
    [ "Enedis",[6.4414532499999995, 46.22069715000001]],
    [ "Électricité de France",[0.3301767807858547, 42.864447867254064]],
    [ "Enedis",[0.3303539948484622, 42.86432006553294]],
    [ "réséda",[6.239340390959333, 49.107561658131395]],
    [ "réséda",[6.239271418490185, 49.10755998854298]],
    [ "Enedis",[5.4137799, 47.0613395]],
    [ "Enedis",[5.411846082944998, 47.07529155558864]],
    [ "Enedis",[5.411060559019919, 47.08017592478158]],
    [ "Enedis",[5.415314943611247, 47.0720173805953]],
    [ "Enedis",[5.496661845729034, 47.07067913076464]],
    [ "Enedis",[5.466015583335717, 47.070786400146865]],
    [ "Enedis",[5.4517109, 47.06506425]],
    [ "Rouhaude",[5.458292149999999, 47.065478199999994]],
    [ "Prairières Choisey",[5.457831949999999, 47.0674762]],
    [ "HLM Choisey",[5.457386054834461, 47.06714001690782]],
    [ "ZA Choisey",[5.4542184667691185, 47.067561298637386]],
    [ "Enedis",[5.893623299931857, 45.584123583361745]],
    [ "RTE",[0.16589283320810702, 47.22695269720836]],
    [ "Enedis",[-1.00674675, 46.548415649999995]],
    [ "playa polo",[-1.5776662380177107, 43.45973413808789]],
    [ "RTE",[0.16539737453268427, 47.22415855902929]],
    [ "Enedis",[0.62961345, 47.33921805000001]],
    [ "Enedis",[6.224627904921625, 46.19920170052371]],
    [ "Jardin des Hespérides",[6.2225864323938405, 46.19986599664773]],
    [ "Cree de France",[6.227225418185346, 46.20108995211133]],
    [ "Intemporelle",[6.234711082341843, 46.20288020524236]],
    [ "Coqueloup",[6.251697799999999, 46.20019235]],
    [ "Enedis",[6.248863349999999, 46.198542]],
    [ "Jura Noyer",[6.242739164913306, 46.20095152808045]],
    [ "Cite SNCF",[6.244974593377341, 46.19854918152034]],
    [ "Clos du Chatelet",[6.2146331688752365, 46.19123623620628]],
    [ "Enedis",[6.2134876435074275, 46.191741285763115]],
    [ "Poste Rivoli",[6.225483763463674, 46.19755233119199]],
    [ "Enedis",[6.227024930227516, 46.19928266400192]],
    [ "Via Geneva",[6.221138846747837, 46.19173801848817]],
    [ "Enedis",[6.218917204872378, 46.19176939537346]],
    [ "Enedis",[6.2187443702715575, 46.18592115809899]],
    [ "Enedis",[6.2103285, 46.188791849999994]],
    [ "Enedis",[6.192404903285254, 46.1797254170244]],
    [ "Enedis",[6.1959678, 46.180633449999995]],
    [ "Enedis",[6.199394949999999, 46.18017244999999]],
    [ "Enedis",[6.213468332128757, 46.180865355358826]],
    [ "Brouaz Grands Prés",[6.2290796616702, 46.18526602918458]],
    [ "Versoie",[6.238565386332675, 46.187227982034536]],
    [ "Val Riant",[6.235636482454723, 46.19030261194542]],
    [ "Escours",[6.219504649999999, 46.18408415]],
    [ "Bois des communs",[6.272607116620604, 46.20294755735475]],
    [ "Village D'ents",[6.264502899999999, 46.202069699999996]],
    [ "Bois des Mouilles",[6.2648309882381366, 46.203739559428875]],
    [ "Enedis",[6.2651478518305685, 46.205393425644864]],
    [ "Enedis",[6.2609019, 46.205442350000006]],
    [ "Enedis",[6.261713016374093, 46.20224340567936]],
    [ "Enedis",[6.25650225, 46.20351184999999]],
    [ "Corzent",[6.253606987933395, 46.20670630568933]],
    [ "Enedis",[6.252425503888331, 46.202158556579235]],
    [ "Enedis",[6.262013678299189, 46.20050134337802]],
    [ "Pottière",[6.2575337499999995, 46.20050354999999]],
    [ "Enedis",[6.280622527796286, 46.21494807105703]],
    [ "Enedis",[6.2842229848229945, 46.20868671336992]],
    [ "Enedis",[6.2855301, 46.2093498]],
    [ "Enedis",[6.287512979221287, 46.2074998306398]],
    [ "Enedis",[6.278667079240313, 46.20338225657654]],
    [ "Enedis",[6.278083633205484, 46.205888493266634]],
    [ "Enedis",[6.2771579499999985, 46.20602209999999]],
    [ "Enedis",[6.274044729860854, 46.205074046951246]],
    [ "Californie Nord",[6.267295036200696, 46.19974876877575]],
    [ "Karting",[5.8680452, 48.53146725]],
    [ "755472606",[1.4186043002263078, 48.754695709519105]],
    [ "Enedis",[3.0134720853113324, 50.416125058898686]],
    [ "Enedis",[3.0039305967190124, 50.41247313423574]],
    [ "Enedis",[3.004690293161697, 50.41419685533307]],
    [ "Enedis",[3.0056204263790502, 50.417771487000074]],
    [ "Enedis",[3.00740976523194, 50.41464823325541]],
    [ "Enedis",[5.84358947392907, 48.53197030369073]],
    [ "Enedis",[6.256699588223011, 46.17443222569423]],
    [ "Enedis",[6.250151700000001, 46.1736233]],
    [ "Enedis",[6.2518323661222315, 46.174681103606886]],
    [ "Enedis",[6.2506796, 46.17782255]],
    [ "Enedis",[6.247153080524011, 46.179278960447235]],
    [ "Enedis",[6.246885948870848, 46.17770817894965]],
    [ "Enedis",[6.247938649999999, 46.176481200000005]],
    [ "Enedis",[6.245261504289153, 46.181069270171]],
    [ "Enedis",[6.243459743552882, 46.18235582516864]],
    [ "Panoramic",[6.238589243739577, 46.195842083224846]],
    [ "Perreuses",[6.233568188890546, 46.200724825795405]],
    [ "Les Clairières",[6.231848053314644, 46.200852750181404]],
    [ "Allain",[6.228485185949226, 46.19443556924263]],
    [ "Enedis",[6.2154362669746295, 46.18640098507911]],
    [ "Enedis",[6.199668449999999, 46.18300024999999]],
    [ "Enedis",[6.2008100353442135, 46.183520229183905]],
    [ "Enedis",[6.204765939077155, 46.18406642503918]],
    [ "Enedis",[6.259887390582567, 46.19502101800263]],
    [ "Enedis",[6.255502219712132, 46.19770858341662]],
    [ "Enedis",[6.253411943084346, 46.19892578787536]],
    [ "Enedis",[6.2442966914672615, 46.18850230963802]],
    [ "Enedis",[6.239692515947999, 46.196919608074296]],
    [ "Enedis",[3.1485267339687124, 50.726235305631775]],
    [ "SNCF Réseau",[3.1347453986732643, 50.35957178216733]],
    [ "Enedis",[2.680936923750277, 50.50663513727876]],
    [ "Enedis",[-0.9286118189106215, 46.0604324066475]],
    [ "Villevieille",[5.151373662905008, 45.72583682370272]],
    [ "Enedis",[-1.8300772948374813, 48.15774479373925]],
    [ "Enedis",[-1.840576620179507, 48.147663677715165]],
    [ "Enedis",[-1.840522416500799, 48.1501568830936]],
    [ "Enedis",[-1.8388555903407573, 48.15193454527723]],
    [ "Poste électrique de Valenciennes",[3.5389433825420227, 50.37593184538432]],
    [ "Enedis",[6.252664553284177, 46.191122315421396]],
    [ "Enedis",[6.2603859257082215, 46.19080364595042]],
    [ "Enedis",[6.26695465, 46.191268]],
    [ "Enedis",[6.256577750000002, 46.19354655000001]],
    [ "Lorfidis",[6.2804787, 46.2055338]],
    [ "CES",[6.25736755, 46.202273350000006]],
    [ "Cret",[6.268245025678898, 46.21273954158011]],
    [ "Enedis",[1.8803484500000003, 47.94954]],
    [ "Enedis",[2.3340946568463683, 50.36404632376427]],
    [ "Enedis",[-1.2242501054104435, 43.16815058388037]],
    [ "Enedis",[-1.2271091332769333, 43.17134979989903]],
    [ "Enedis",[-1.2343307, 43.170276799999996]],
    [ "Aulnoye",[3.8162172886681858, 50.19920449798822]],
    [ "RTE",[6.049841322840645, 48.894424603271894]],
    [ "Enedis",[4.935754933547023, 45.70188735989289]],
    [ "Enedis",[6.25128154101592, 46.18192427235279]],
    [ "Enedis",[5.311626049999999, 48.68415274999999]],
    [ "Enedis",[5.320166049999999, 48.685311850000005]],
    [ "Enedis",[5.333673849022266, 48.683985716907415]],
    [ "Arboriale",[5.29617075, 45.5891337]],
    [ "Enedis",[5.291783323930771, 45.58881301639208]],
    [ "Vendome",[5.2926793555131475, 45.58938191967496]],
    [ "RTE",[1.7835429996457777, 49.77134521767821]],
    [ "Poste source de Coupelle Neuve",[2.0969517517306193, 50.499621698277245]],
    [ "Enedis",[2.066543757240837, 48.895557067266665]],
    [ "Poste source de Ginestous",[1.4128085917154416, 43.658770517435855]],
    [ "Poste source de Leguevin",[1.2731622304406451, 43.598134794698666]],
    [ "Saint-Romain",[4.220650685707015, 45.247058405534226]],
    [ "Enedis",[3.035887176853418, 50.67660794707962]],
    [ "Enedis",[2.1040661500000004, 47.8534869]],
    [ "Enedis",[-0.4976181076553146, 47.46219452649387]],
    [ "Enedis",[-0.5082592683373969, 47.466862651627274]],
    [ "Enedis",[-0.5085712275570052, 47.467243395350756]],
    [ "Brigitte",[-0.5011210448132223, 47.466499621693856]],
    [ "MCE finances",[-0.5112207501761737, 47.456629699628245]],
    [ "Enedis",[-1.6408103, 48.0820703]],
    [ "Poste source de la Chaussée sur Marne",[4.512208268585902, 48.83966066915697]],
    [ "Enedis",[2.8363267499999996, 50.223972700000004]],
    [ "Enedis",[2.809299966060606, 42.58054489418182]],
    [ "Enedis",[3.514437647122068, 48.402791837650405]],
    [ "Enedis",[6.251844472600808, 46.185369507205074]],
    [ "Enedis",[6.249849964530487, 46.1848353983145]],
    [ "Enedis",[6.247362460403167, 46.18500369961987]],
    [ "Enedis",[6.251851829905444, 46.17573132694468]],
    [ "Enedis",[6.264179013246981, 46.17142676899497]],
    [ "Enedis",[3.7662750591765657, 43.676101222196586]],
    [ "Enedis",[6.38295155, 46.20338650000001]],
    [ "Les Arolles",[6.391042724685713, 46.20585030586046]],
    [ "Enedis",[6.393199041932099, 46.20400777898668]],
    [ "Enedis",[6.40152565, 46.2082666]],
    [ "réséda",[6.153719609533467, 49.1579674726542]],
    [ "Enedis",[6.440351632099168, 46.217450696143075]],
    [ "Enedis",[0.5467212180906401, 46.8147239417952]],
    [ "Enedis",[-1.45167395, 46.67259855]],
    [ "Enedis",[0.5318663671171481, 46.83871081677703]],
    [ "Enedis",[2.8080490812610632, 45.57821057939285]],
    [ "Enedis",[6.428768167205435, 46.2192597724425]],
    [ "Enedis",[6.460103349999999, 46.251010449999995]],
    [ "Enedis",[6.463358950000001, 46.26290744999999]],
    [ "Enedis",[6.463191508551726, 46.26199408377012]],
    [ "Enedis",[6.465852432425216, 46.2622285235917]],
    [ "Enedis",[6.4673047208221135, 46.265634271283346]],
    [ "Enedis",[6.465769849999999, 46.267941750000006]],
    [ "Enedis",[6.395407865781402, 46.2432021790604]],
    [ "Enedis",[6.390673033816374, 46.248112598100036]],
    [ "Enedis",[1.081211153521044, 47.92036935056318]],
    [ "Enedis",[4.998206296650295, 45.73413256565924]],
    [ "Enedis",[4.8326175000000005, 45.06517395]],
    [ "Enedis",[0.5324173999999998, 46.84141495]],
    [ "Enedis",[6.701015750000001, 46.35923955]],
    [ "760395070",[6.120556891812046, 45.929160310694336]],
    [ "Parking Mettis Gab",[6.232538748169655, 49.093900173931324]],
    [ "Buttay",[6.682108056967751, 46.36498628232454]],
    [ "HLM",[6.67728606083976, 46.36150462459297]],
    [ "Enedis",[6.690770528817561, 46.35736413818246]],
    [ "Enedis",[6.695004057382278, 46.35885407506072]],
    [ "Poste électrique de Saint-Géraud-de-Corps",[0.23679583481899172, 44.93935148364584]],
    [ "SNCF Réseau",[2.8707589256132597, 50.12677036586823]],
    [ "AR Gendarme",[2.318091763512855, 48.808525807206344]],
    [ "Enedis",[2.2969151433271713, 50.73136567559652]],
    [ "Enedis",[2.2969945810054724, 50.73142539184182]],
    [ "Mairie",[-1.8526054843063164, 48.6747173189543]],
    [ "Chez Masson",[6.660169063332927, 46.355918567678685]],
    [ "Enedis",[-1.112486175962591, 45.71516904304481]],
    [ "Poste électrique de Bois-de-Serves",[6.0540277372915465, 46.25186470074086]],
    [ "Chanteclerc",[-0.5046733500000015, 47.467530700000005]],
    [ "Les Tonnelles",[5.1584504665153945, 45.611973050776776]],
    [ "Parc Ouest",[2.3331364, 48.82100230000001]],
    [ "Enedis",[0.5062452059661053, 46.8241974586134]],
    [ "Enedis",[6.55578655, 46.3366434]],
    [ "Enedis",[6.566904125374392, 46.325393497947104]],
    [ "Place des Fêtes",[1.4872433336236568, 43.61276554257256]],
    [ "Cyclone",[1.5022147499999998, 43.636403200000004]],
    [ "Enedis",[-1.334378355977528, 43.748772779537624]],
    [ "Enedis",[2.28566125, 50.73600935]],
    [ "Enedis",[0.7089960153682158, 47.4223634760364]],
    [ "Enedis",[0.7089942664349421, 47.42240261548724]],
    [ "Enedis",[0.7104986391842395, 47.42192889015282]],
    [ "Enedis",[4.940519101458684, 44.85882806733991]],
    [ "Enedis",[3.9808794620199213, 43.65280602063305]],
    [ "Enedis",[4.940229100000001, 44.86135444999999]],
    [ "Les Rivaux",[1.14492309065002, 45.80239870004985]],
    [ "Enedis",[4.920788262068966, 44.86981993249739]],
    [ "Enedis",[4.934313900000001, 44.85940685]],
    [ "Enedis",[4.922968000000001, 44.86506145]],
    [ "Enedis",[4.9190811565232995, 44.8855131245866]],
    [ "Enedis",[4.918498943779779, 44.891811373365364]],
    [ "Enedis",[4.9187324772005985, 44.89079558823402]],
    [ "Enedis",[4.8841054, 44.9015378]],
    [ "Enedis",[4.886912550000001, 44.90148639999999]],
    [ "Enedis",[4.88769005, 44.900307749999996]],
    [ "BG Jean Jaurès",[2.3025416499999998, 48.80214949999999]],
    [ "Enedis",[4.1146129269701595, 43.67526358829083]],
    [ "Enedis",[4.948478500000001, 44.8590938]],
    [ "Enedis",[4.944940499999999, 44.86237185000001]],
    [ "Enedis",[3.195340480989884, 46.991504702332136]],
    [ "Enedis",[3.2298656579389924, 46.98656645536133]],
    [ "Enedis",[3.233459669749929, 46.968392966005965]],
    [ "Enedis",[3.2256945865289413, 46.97926321369117]],
    [ "Enedis",[3.2286503296865003, 46.965216208829176]],
    [ "Enedis",[3.210412309790028, 46.98239576678606]],
    [ "Enedis",[3.1987394988658853, 46.999710554605244]],
    [ "Enedis",[3.2195898559587577, 46.99636609460203]],
    [ "Enedis",[3.200466043174714, 46.98592241715596]],
    [ "Enedis",[3.22640107093013, 46.97165144538659]],
    [ "Enedis",[3.2043864500000003, 46.9843613]],
    [ "Enedis",[3.2112541743591168, 46.97245854443026]],
    [ "Enedis",[5.1569755151716645, 45.61686593124379]],
    [ "Enedis",[4.94140573485539, 44.88861171813081]],
    [ "Enedis",[4.932718268367143, 44.90085466745665]],
    [ "Enedis",[4.909852670846551, 44.906772303241546]],
    [ "Enedis",[4.917976126294447, 44.88543539616323]],
    [ "Pont Plagnettes",[6.6837404000000005, 46.20146270000001]],
    [ "Enedis",[6.6279776789643154, 46.33303254607566]],
    [ "Jotty",[6.6155349, 46.30287169999999]],
    [ "Gys",[6.619140149999999, 46.278962050000004]],
    [ "Sous-station du Sauvageon",[2.398048339536872, 48.283528751037665]],
    [ "Enedis",[-0.34314193353637606, 49.3137240453413]],
    [ "Enedis",[4.962827467544398, 44.8404785875336]],
    [ "Enedis",[4.962715300000001, 44.843537500000004]],
    [ "Enedis",[4.955419449999999, 44.85138265]],
    [ "Enedis",[4.949647623486568, 44.85440938646841]],
    [ "Enedis",[2.1028319232652666, 49.27509656732983]],
    [ "Mecasonic",[6.2806728000000005, 46.20567855]],
    [ "Enedis",[1.6957357862001048, 49.191292328985426]],
    [ "Enedis",[2.1360314973089793, 47.91157227389567]],
    [ "RTE",[1.9281368610253475, 47.79261839203535]],
    [ "Poste électrique de Château-Bernard",[-0.3213997244220644, 45.6800190192923]],
    [ "Guetat",[5.260444267113829, 45.5971352833948]],
    [ "Rusure",[5.260504881073037, 45.59713140764698]],
    [ "Enedis",[2.57737895, 49.086917250000006]],
    [ "Enedis",[2.1321844332295776, 47.926365199886746]],
    [ "Enedis",[3.983090093193724, 43.65171346839667]],
    [ "RTE",[3.6733510383701136, 49.5838212468075]],
    [ "Enedis",[0.6786652948032085, 47.36933126242084]],
    [ "Enedis",[0.6786906759340109, 47.3693146654283]],
    [ "Pablo Picasso",[3.11587922171335, 45.76527085276754]],
    [ "Enedis",[-0.5074706790687624, 47.469781414671125]],
    [ "Enedis",[-0.5367409562148385, 47.47190163038097]],
    [ "Enedis",[-0.5066389377603178, 47.47015016794285]],
    [ "Enedis",[2.7423038842246474, 50.39706830421063]],
    [ "Enedis",[0.6897843490200384, 47.40485778294255]],
    [ "765385199",[-1.3391628961860285, 48.53005826657377]],
    [ "Enedis",[0.3847364179510486, 44.214224798547235]],
    [ "Enedis",[0.4126135821876058, 44.203999251800425]],
    [ "Enedis",[0.4157800000000001, 44.20414980000001]],
    [ "Enedis",[1.2552840499999998, 44.025441199999996]],
    [ "Enedis",[4.871187802938193, 45.822949233993356]],
    [ "Enedis",[2.920481715263368, 42.82517054786502]],
    [ "Enedis",[1.0780565262414423, 48.76116545780233]],
    [ "Les Maines",[5.2554318, 45.59825845000001]],
    [ "Les Verts",[5.250929748334484, 45.597854466293825]],
    [ "Enedis",[5.25200596006195, 45.60031842645883]],
    [ "La Plaine",[5.259031899999998, 45.601896800000006]],
    [ "Enedis",[3.110901751612212, 45.764150245779035]],
    [ "RTE",[2.4085644491845923, 49.01018189446982]],
    [ "SNCF Réseau",[2.44237436010406, 49.24519538573298]],
    [ "Enedis",[2.993338311840358, 42.60980871760953]],
    [ "Enedis",[2.9970792867613665, 42.61149464687895]],
    [ "Enedis",[4.000555494334806, 43.910189373680716]],
    [ "Enedis",[0.33122002002442, 46.555166301343114]],
    [ "Enedis",[-1.4273517261964552, 46.81092110319517]],
    [ "Enedis",[2.9768925576340783, 42.55621543757544]],
    [ "Enedis",[4.9588137, 44.869125]],
    [ "Enedis",[2.4202015868146303, 48.88941732212577]],
    [ "Derrick",[2.1949765258848397, 48.67348914115808]],
    [ "Enedis",[4.1317848, 43.68777505]],
    [ "Enedis",[-1.4399979451890257, 46.67985837995742]],
    [ "Enedis",[2.9299981500000003, 50.312208299999995]],
    [ "Enedis",[2.952650018818816, 50.31796558923514]],
    [ "Enedis",[6.472902568015746, 48.56412372885656]],
    [ "Enedis",[4.94295925, 44.873251207388314]],
    [ "Enedis",[4.940880267292459, 44.870742566574975]],
    [ "Enedis",[4.9398479, 44.86850454999999]],
    [ "Enedis",[-0.73811225, 48.05808274999999]],
    [ "Enedis",[-0.7374613541323933, 48.06092826677383]],
    [ "Station de Pompage",[1.2988834, 45.87225444999999]],
    [ "Ker Jean",[-3.5809988, 47.85151115]],
    [ "Kerlorgan",[-4.154560213851404, 47.86859008309301]],
    [ "Kerfraval",[-3.05949035, 47.58605699999999]],
    [ "Kerdruc",[-3.748244950000001, 47.82271035]],
    [ "Petit Bodo",[-2.6173883227711534, 47.5091728611395]],
    [ "Le Lichern",[-3.5334875319955255, 47.87175788043821]],
    [ "Poulperson",[-3.076187006139834, 47.586219233988594]],
    [ "Kerneg",[-3.5573440846942783, 47.885959919446684]],
    [ "Cuisine Centrale",[-3.561294427887524, 47.874644752800386]],
    [ "Pré Prieur",[4.8198640500000005, 45.90343484999999]],
    [ "Kernouarn",[-3.520886049999999, 47.86286545]],
    [ "Église",[1.8315538476778708, 45.7187447594239]],
    [ "Enedis",[1.6413316063836871, 47.84982727354141]],
    [ "Enedis",[4.869567915517991, 45.82539344657151]],
    [ "Enedis",[4.14438005, 43.69097605000001]],
    [ "Enedis",[4.142592375621858, 43.68919589067553]],
    [ "Le Belvédère",[4.8382025, 45.89504545]],
    [ "Enedis",[-2.1289271268086294, 47.11621194414769]],
    [ "Enedis",[-2.1354906513821525, 47.11686126603776]],
    [ "Enedis",[-2.130221742054088, 47.11306701940589]],
    [ "Enedis",[6.555366462880824, 48.03219094686342]],
    [ "Enedis",[3.9506666755705173, 49.2570848619737]],
    [ "Enedis",[4.89526015, 44.8752389]],
    [ "Enedis",[4.936295966778015, 44.85234525255703]],
    [ "Électricité de France",[-61.55424279779735, 16.233298713073957]],
    [ "SNCF Réseau",[4.128097046802873, 43.702431449300065]],
    [ "Enedis",[0.14067975, 48.063219499999995]],
    [ "Enedis",[6.2458238795713505, 46.19477239073607]],
    [ "Le Clos du Chêne",[0.14050665000000004, 48.060512700000004]],
    [ "Enedis",[4.938504325902938, 44.86561513755863]],
    [ "Enedis",[4.866740175331964, 45.82445100928058]],
    [ "Enedis",[0.2542024500000012, 48.057252850000005]],
    [ "Enedis",[6.679280273380449, 46.35656604475854]],
    [ "Enedis",[2.7296619208411106, 50.306853968764315]],
    [ "Vendanche",[6.437334386647532, 46.30382271376379]],
    [ "Enedis",[4.578704046211807, 49.57873812492941]],
    [ "Enedis",[6.438127419273065, 46.30909574550325]],
    [ "Saint-Germain",[1.7001629999999996, 44.9528527]],
    [ "Les Forains",[-2.758902709289682, 47.651062243857695]],
    [ "ZA Montauban",[-3.05858875, 47.58863134999999]],
    [ "Kernours",[-2.9558299, 47.63600515000001]],
    [ "Port Blanc",[-2.8627148000000004, 47.601423249999996]],
    [ "Pointe de Brouel",[-2.8276681999999993, 47.59077105]],
    [ "La Grande Vigne",[-2.8016363999999996, 47.589147000000004]],
    [ "Rudevent",[-2.790607799999999, 47.594499850000005]],
    [ "Demeure de Royan",[-2.76505255, 47.660645800000005]],
    [ "Les Bosses",[-2.766464276872827, 47.66161134742286]],
    [ "Cantizac",[-2.74053245, 47.62584450000001]],
    [ "Semaphore",[-3.211301517190635, 47.65084832887883]],
    [ "ZA du Baigno",[-3.2511319499999995, 47.79809175]],
    [ "Enedis",[-4.286592381714017, 47.80213216161215]],
    [ "Pinède d'Helios",[4.200743035017898, 43.76065347205727]],
    [ "Halles",[4.146974629637689, 43.75540357479429]],
    [ "Foukart",[4.164142405579364, 43.749390980226636]],
    [ "Gallay Chatel",[6.56705895, 46.06848285]],
    [ "Radar Satolas",[5.098359373516816, 45.71946455352931]],
    [ "Enedis",[2.7840603875283247, 50.29266829292292]],
    [ "Enedis",[2.7890838000000002, 50.28611660000001]],
    [ "Enedis",[6.764011450000002, 45.53858005]],
    [ "Enedis",[6.578978600000001, 47.05699445]],
    [ "Enedis",[6.5877738500000005, 47.057780750000006]],
    [ "Enedis",[6.61249325, 47.0605705]],
    [ "Enedis",[6.61024178335848, 47.052974533306646]],
    [ "Enedis",[6.6099257453963896, 47.05401158269145]],
    [ "Enedis",[6.609347047883045, 47.05694133332807]],
    [ "Enedis",[6.296214, 46.83964050000001]],
    [ "Enedis",[6.28234161778193, 46.83735409684295]],
    [ "Enedis",[-1.0693330499999998, 43.597956800000006]],
    [ "Enedis",[2.9089740253560117, 50.29093604489785]],
    [ "Enedis",[2.92074895, 50.29154595]],
    [ "Enedis",[2.395391473126014, 47.095065496559684]],
    [ "Enedis",[0.8225843961909474, 49.456867673179985]],
    [ "Enedis",[0.8299511181593497, 49.45651472805768]],
    [ "Enedis",[0.8358423171966757, 49.45601848255395]],
    [ "Enedis",[4.134412776458803, 43.6631211444349]],
    [ "Enedis",[0.8331142320344154, 49.45910979515539]],
    [ "Varnaz",[6.2725902, 46.213546199999996]],
    [ "Enedis",[2.9862189044941827, 50.31869233264055]],
    [ "Poste source de Hardt",[7.473603027152251, 47.64844934679725]],
    [ "Enedis",[4.126740519930589, 43.67192180673573]],
    [ "Enedis",[3.251389400000001, 43.47009985]],
    [ "Enedis",[1.0731621215382274, 47.7756091644281]],
    [ "Enedis",[1.0695985, 47.7763723]],
    [ "Enedis",[3.259868821401539, 50.31368275715355]],
    [ "Enedis",[4.97504825, 44.989192599999996]],
    [ "Tilleuls",[4.058527216959435, 49.286945337101926]],
    [ "Enedis",[2.928809269305698, 42.6777410908273]],
    [ "Enedis",[2.926185051188074, 42.678838697276404]],
    [ "Enedis",[-0.7429008707097933, 43.984236655979565]],
    [ "Enedis",[-0.7258200503090911, 43.969925016766055]],
    [ "Enedis",[-0.7331645999999999, 43.97079239999999]],
    [ "Enedis",[-0.7352451933939702, 43.98061122248057]],
    [ "FC P Saulniers 4",[2.23095060254842, 48.995241433333334]],
    [ "Lilattes",[5.2719133488563275, 45.59117931723722]],
    [ "Enedis",[-1.6100149816818872, 49.416074366053905]],
    [ "Roquebin",[2.3936251430130167, 48.70661330754044]],
    [ "Aurillac",[2.396391046843811, 48.7079341463319]],
    [ "Enedis",[4.977186800000001, 44.98633385000001]],
    [ "Enedis",[4.97585209257783, 44.987553417775324]],
    [ "775447498",[4.888994326954453, 43.44125145662021]],
    [ "Station de Conversion Peuplingues",[1.7805520291159151, 50.920229480160984]],
    [ "Enedis",[-1.37458175, 43.42737709999999]],
    [ "Enedis",[0.8327826809255254, 49.459837604084974]],
    [ "Enedis",[2.8033759241779204, 42.73418563379221]],
    [ "Enedis",[2.987754230095158, 50.45901221534048]],
    [ "Enedis",[0.8683701, 49.47916494999999]],
    [ "Enedis",[0.8683981, 49.479180250000006]],
    [ "Enedis",[-0.5272753858158861, 47.46841758681746]],
    [ "Gagnereaux",[5.0416643405624795, 47.328525384756055]],
    [ "Fremiet",[5.047069160745096, 47.33040077521316]],
    [ "Enedis",[-1.5719365840516515, 49.06839325302502]],
    [ "Enedis",[-1.5721203254840186, 49.10148355436896]],
    [ "Enedis",[-1.5732781999999998, 49.17179779999999]],
    [ "Enedis",[0.6684893085519651, 49.289556029127226]],
    [ "Enedis",[3.073860100073012, 50.62869337631192]],
    [ "SRD",[0.11690075000000083, 47.04305345]],
    [ "Enedis",[1.7432015535276923, 50.48739251618331]],
    [ "Enedis",[1.7489834617346156, 50.48743895073605]],
    [ "Enedis",[1.7490268008221994, 50.487456311202465]],
    [ "Enedis",[1.7496376634660906, 50.48690931156942]],
    [ "Enedis",[-1.269337566772983, 49.593522501541585]],
    [ "Enedis",[-1.2865877500000005, 49.594244399999994]],
    [ "Enedis",[-1.2870759297421397, 49.591298791299685]],
    [ "Enedis",[5.978672825597074, 46.23137766666962]],
    [ "Enedis",[7.286567478739806, 47.73793164735706]],
    [ "Enedis",[7.284675699999999, 47.73676785]],
    [ "Enedis",[7.282774550000001, 47.735726549999995]],
    [ "Enedis",[7.260410481540783, 47.734557457059545]],
    [ "Enedis",[-1.8978791253720249, 49.71302050401785]],
    [ "Enedis",[-1.8972483000000002, 49.710132099999996]],
    [ "Enedis",[-1.6265518682065576, 49.36741410925261]],
    [ "Enedis",[-1.6392531000000017, 49.322979100000005]],
    [ "Enedis",[7.302780036012494, 47.725537432003456]],
    [ "Enedis",[7.301126913314286, 47.72550128414802]],
    [ "Enedis",[7.299656067198855, 47.72416523109895]],
    [ "Enedis",[7.299749000000001, 47.71155900000001]],
    [ "Enedis",[7.294551830617519, 47.71903326537191]],
    [ "Enedis",[7.296177850000001, 47.7201824]],
    [ "Sauveterre de Guyenne",[-0.08687192500402101, 44.713932298819046]],
    [ "Enedis",[-4.61892405, 48.37682544999999]],
    [ "Enedis",[3.987412767934306, 49.26220712477281]],
    [ "Enedis",[7.26794185, 47.74570435]],
    [ "Échangeur Lutterbach",[7.274212764038414, 47.74616831884221]],
    [ "Enedis",[6.337306081511993, 47.345547948476785]],
    [ "Enedis",[6.3378149622509445, 46.89911111077007]],
    [ "Enedis",[-1.7789130162815117, 49.59228975045674]],
    [ "Enedis",[-1.809327367002582, 49.512804698985676]],
    [ "Enedis",[-1.7395572000000001, 49.5076604]],
    [ "Enedis",[3.8771084255354573, 43.9545252933102]],
    [ "Enedis",[-4.268565321191845, 48.449770305394594]],
    [ "Enedis",[0.36613374368728974, 46.70297441749537]],
    [ "Enedis",[-1.8654782549071625, 49.518750904062976]],
    [ "Enedis",[-1.8376769788443617, 49.51117019630319]],
    [ "Enedis",[-1.8152081843898815, 49.510768848163224]],
    [ "Enedis",[-1.8088231815821696, 49.508969093923305]],
    [ "Enedis",[-1.703760571150972, 49.4276072955157]],
    [ "Enedis",[-1.8521352149871693, 49.70390506789795]],
    [ "Enedis",[0.31748543333333334, 46.58574432553763]],
    [ "Enedis",[-1.2756583820792737, 49.40450875572398]],
    [ "Enedis",[0.18910201396002488, 49.559076093742036]],
    [ "Enedis",[-0.8599078496068625, 44.54654034986081]],
    [ "Enedis",[-1.5717893256133868, 49.16734285844368]],
    [ "réséda",[6.175754849999998, 49.15026395]],
    [ "Enedis",[4.953053378883585, 44.8348371955262]],
    [ "Luze",[-0.5760002867317218, 44.862739603379765]],
    [ "Enedis",[1.9655850645937714, 47.96223398199346]],
    [ "Enedis",[2.5680619644964793, 48.651869872759974]],
    [ "Enedis",[2.3474813, 48.602071200000005]],
    [ "Enedis",[2.3146811499999997, 48.6114253]],
    [ "Poste électrique Temple",[2.374083826651305, 48.872396018144386]],
    [ "Enedis",[1.9728769704719102, 47.96905715256401]],
    [ "Enedis",[3.0881233747567176, 50.642596854094066]],
    [ "Enedis",[-1.3347529500000002, 47.9533237]],
    [ "Enedis",[-1.3756348119704338, 47.997181975103665]],
    [ "Enedis",[3.9986398339584963, 49.225899532242074]],
    [ "Enedis",[3.052073423321925, 50.64185600854376]],
    [ "Enedis",[3.052122425991189, 50.64133329265785]],
    [ "Enedis",[3.0521692, 50.64082655]],
    [ "Enedis",[3.0522329565519253, 50.640146851595595]],
    [ "Enedis",[-4.416896274833255, 48.51275955320602]],
    [ "Enedis",[-1.4888241109917262, 43.48030984456082]],
    [ "Enedis",[-1.4664099765643834, 43.47891982322586]],
    [ "Enedis",[-1.3700290499999996, 48.0022785]],
    [ "Enedis",[-1.3024192378810941, 48.026374369112666]],
    [ "Enedis",[-1.2771554785748422, 48.02032554527586]],
    [ "Enedis",[1.0699458553646601, 46.16389363060925]],
    [ "Enedis",[1.0829670465638266, 46.14665444703722]],
    [ "Enedis",[1.058864995444661, 46.1430925006958]],
    [ "Enedis",[1.8936768132229753, 49.125403284983854]],
    [ "Enedis",[1.909264228029684, 49.13789314729601]],
    [ "Enedis",[3.020711850000001, 50.642465850000015]],
    [ "Enedis",[2.018744317868217, 49.143297097105936]],
    [ "Enedis",[2.021390227899271, 49.142221523790596]],
    [ "Enedis",[2.0113697868790523, 49.15284504372478]],
    [ "Enedis",[2.052276305223881, 49.17318900348259]],
    [ "Enedis",[2.0409952601204875, 49.17275898932689]],
    [ "Enedis",[2.1176519914950584, 49.20179027725561]],
    [ "Enedis",[0.7662816623865568, 46.73852834224691]],
    [ "Enedis",[0.3433057387501466, 46.990069867932924]],
    [ "Enedis",[0.32373460930843423, 46.98550487085546]],
    [ "Enedis",[0.34697647481453453, 46.99047390509786]],
    [ "Enedis",[0.33801838373732807, 46.95646533150375]],
    [ "Enedis",[0.3402307867656159, 46.946585767800464]],
    [ "Enedis",[0.3657472097268988, 46.9679747345492]],
    [ "Enedis",[0.36960201425572153, 46.97039139567573]],
    [ "Enedis",[0.38234718937839207, 46.96386066604599]],
    [ "Enedis",[0.39864415000000003, 46.96887259999999]],
    [ "Enedis",[0.37797708283967546, 46.97535629510795]],
    [ "Enedis",[1.8668930499999983, 46.80765914999999]],
    [ "Enedis",[0.43517549372340986, 47.016937624897764]],
    [ "Enedis",[0.4246975476456645, 46.99937391437699]],
    [ "Enedis",[0.4368221915341199, 47.00397766912947]],
    [ "Enedis",[0.4404582082096628, 47.00889123429903]],
    [ "Enedis",[0.45413952767639904, 47.02676380620438]],
    [ "Enedis",[1.8672387215170607, 46.8055423948362]],
    [ "Enedis",[0.455974067529301, 47.031716797159625]],
    [ "Enedis",[0.46552617998165163, 47.060458065696835]],
    [ "Enedis",[3.020056330162734, 50.649053446255614]],
    [ "Enedis",[0.4907102586248412, 46.984428662803296]],
    [ "Enedis",[0.4882152391591622, 46.98299587286236]],
    [ "Enedis",[0.49585880000000004, 46.9757496]],
    [ "Alliages",[4.775263228280367, 45.72533293700112]],
    [ "La Fosse",[4.7744525, 45.724498600000004]],
    [ "Enedis",[1.8564902999999997, 47.924687649999996]],
    [ "Enedis",[0.4785579, 46.99190780000001]],
    [ "Enedis",[0.5489770350528067, 46.997760313677624]],
    [ "Enedis",[0.5161767999999999, 46.99357299999999]],
    [ "Enedis",[0.5761475999999999, 46.901852500000004]],
    [ "Bourbre",[5.2565524, 45.60536665]],
    [ "Enedis",[5.2659251000000005, 45.60225944999999]],
    [ "Enedis",[-1.1472848999999992, 44.32829545]],
    [ "Centre",[5.849114466930267, 48.484091349646704]],
    [ "Enedis",[2.4107616999999997, 48.753409649999995]],
    [ "Enedis",[0.61106975, 46.972822199999996]],
    [ "Enedis",[0.622761467097397, 46.9690379992128]],
    [ "Enedis",[2.7630699286791045, 50.30432622136704]],
    [ "Enedis",[0.6316131975718848, 46.9388204171459]],
    [ "Enedis",[0.71261125, 47.05740775]],
    [ "Enedis",[0.7093283499999999, 47.046881649999996]],
    [ "réséda",[6.189218460559164, 49.11008042046838]],
    [ "Enedis",[4.775894112064001, 45.44890368368001]],
    [ "Enedis",[0.6411585369414808, 46.977635267467335]],
    [ "Enedis",[0.65802725, 46.976891849999994]],
    [ "Enedis",[0.6618395759602397, 46.97663112860642]],
    [ "Enedis",[0.271934049125475, 46.81661920230672]],
    [ "Enedis",[-0.33298230000000006, 49.14063625000001]],
    [ "Enedis",[-0.334265057143788, 49.14190260313145]],
    [ "Enedis",[-0.9295455339584676, 46.058567690154135]],
    [ "Enedis",[-0.9336571499999999, 46.059234599999996]],
    [ "Enedis",[4.771496182191811, 45.71065680196364]],
    [ "Enedis",[2.0023565, 49.166001050000006]],
    [ "Enedis",[1.9758670702907208, 49.1684721393867]],
    [ "Enedis",[0.8835368900959744, 44.2750009838012]],
    [ "Enedis",[0.8559346189170417, 44.287812646780026]],
    [ "Enedis",[0.83831195, 44.29735895000001]],
    [ "Enedis",[0.8106873917010422, 44.33449796429031]],
    [ "Enedis",[2.9022225558845487, 42.72663587943856]],
    [ "Enedis",[0.76452655, 44.39432395]],
    [ "Enedis",[0.76435145, 44.39174584999999]],
    [ "Enedis",[0.7706949852011643, 44.39223918264418]],
    [ "Enedis",[0.8462970186656037, 44.35034375191521]],
    [ "Enedis",[-1.1564268980545984, 44.39794148343393]],
    [ "Enedis",[5.255240119245826, 45.89418710331056]],
    [ "Enedis",[3.9850291500000012, 49.24493169999999]],
    [ "Enedis",[-1.1565488289886745, 44.38410057040691]],
    [ "Enedis",[-4.2287944604337815, 48.44991349247662]],
    [ "Enedis",[2.820181004583773, 48.353747059266766]],
    [ "SICAE-ELY",[1.428116593273453, 48.74483021651696]],
    [ "Enedis",[6.105147316323425, 45.9478573495327]],
    [ "Enedis",[6.252901167020871, 46.26557036048721]],
    [ "Enedis",[1.9072859489920637, 50.77891506700477]],
    [ "Enedis",[-0.32969678564476845, 43.1491082540146]],
    [ "Enedis",[-0.3015303959559335, 43.12615993412356]],
    [ "Enedis",[5.297375550000001, 45.920213700000005]],
    [ "Enedis",[5.292218667433223, 45.92710215673036]],
    [ "Enedis",[5.2889958, 45.92449120000001]],
    [ "Enedis",[5.264349518797062, 45.88097234820625]],
    [ "Poste électrique de Saint Pierre",[-61.1700946657937, 14.742642369090891]],
    [ "Enedis",[-0.7267576154858119, 43.11978545618212]],
    [ "Enedis",[-0.7297436459549657, 43.10278553441151]],
    [ "Enedis",[6.3480743955070045, 45.04221014243026]],
    [ "Poste électique d'Hydrobase",[-61.058126449933454, 14.596333597756074]],
    [ "Poste électrique de Bellefontaine",[-61.15668071143066, 14.667889773143349]],
    [ "Poste électrique de la Trinité",[-60.968629381559204, 14.73149836565734]],
    [ "Poste électrique de Marigot",[-61.04091993103593, 14.824744190695238]],
    [ "Poste électrique de Le François",[-60.91911064370105, 14.62680384817316]],
    [ "Poste électrique de Le Marin",[-60.854523187746004, 14.471585036166745]],
    [ "Poste électrique de Desbrosses",[-61.06595274455329, 14.635993497087393]],
    [ "Enedis",[6.624131313102932, 47.10263023402054]],
    [ "Électricité de France",[-61.5971367617246, 16.250871201278255]],
    [ "EDF SEI",[-61.37720623815128, 16.231945153798815]],
    [ "Enedis",[2.5420697853873637, 44.856830525227046]],
    [ "EDF SEI",[-61.32588327098073, 16.305885270322054]],
    [ "Enedis",[6.298365521112852, 46.30803684712047]],
    [ "Enedis",[2.5661152846457114, 44.88914627415188]],
    [ "Enedis",[1.703092681694296, 44.95809983297218]],
    [ "Enedis",[5.7471964, 43.616306449999996]],
    [ "City In",[3.8492412999999996, 43.5760949]],
    [ "Enedis",[2.2342806521057703, 48.97849331613026]],
    [ "Enedis",[2.2899531134846987, 48.672802677808086]],
    [ "Enedis",[0.8859709032718533, 46.20994620730138]],
    [ "Enedis",[2.547480954073582, 50.28324901560127]],
    [ "Enedis",[-1.4617349999999998, 43.47631694999999]],
    [ "Enedis",[-1.459588650132926, 43.47775111669097]],
    [ "Enedis",[-1.4581347694759206, 43.476861862010935]],
    [ "Enedis",[-4.536506186261186, 48.38343649948444]],
    [ "Enedis",[-4.547175603855684, 48.38001816810339]],
    [ "Enedis",[-0.656891054205108, 43.289902766785964]],
    [ "Enedis",[-0.8920105, 46.95002395]],
    [ "Enedis",[-0.6521461500000001, 43.31657825]],
    [ "Enedis",[-1.9119878000000001, 46.837498849999996]],
    [ "Enedis",[-0.5889839586489367, 45.200605944578534]],
    [ "Enedis",[-0.6096386306061649, 45.187347407012716]],
    [ "Enedis",[1.5130381673275795, 47.10344124694832]],
    [ "Provisoire 2",[6.1227420933936445, 45.917861969176755]],
    [ "Enedis",[-0.5989785935326299, 45.16956741656896]],
    [ "Enedis",[-0.6043845178435838, 45.15484590584662]],
    [ "Enedis",[-0.6287348199974656, 45.15741968557119]],
    [ "Enedis",[-0.6486969659674545, 45.140419912258885]],
    [ "Enedis",[0.29939294999999916, 46.702578599999995]],
    [ "Enedis",[0.29019287392397075, 46.69814447084512]],
    [ "Enedis",[0.2974960000000001, 46.69690035]],
    [ "Enedis",[0.32155005000000003, 46.72827060000001]],
    [ "Enedis",[0.31741471746009076, 46.72750355687872]],
    [ "Enedis",[0.36790084435810994, 46.81726587883354]],
    [ "Enedis",[0.36495451058543904, 46.81961908694048]],
    [ "Gérédis",[-0.11700900606547353, 47.03845872061916]],
    [ "Gérédis",[-0.08914850024250145, 47.01790350301392]],
    [ "Gérédis",[-0.1456364700974423, 47.02955164480132]],
    [ "Gérédis",[-0.1338356999999999, 47.01056754999999]],
    [ "Gérédis",[-0.11556750573217894, 47.012766538436395]],
    [ "Gérédis",[-0.11832110000000136, 46.995394]],
    [ "Enedis",[-1.8924289311041465, 46.82827891028872]],
    [ "Gérédis",[-0.18521575, 47.012064800000005]],
    [ "Gérédis",[-0.16937313453838626, 47.024810475855695]],
    [ "Gérédis",[-0.18884574250325495, 47.02840135964596]],
    [ "Gérédis",[-0.18923217602881, 47.04815045261112]],
    [ "Gérédis",[-0.1851352199655248, 47.04952630657384]],
    [ "Gérédis",[-0.18386524483084365, 47.057195504237896]],
    [ "Gérédis",[-0.18144030000000086, 47.055479999999996]],
    [ "Cersay",[-0.350817120728191, 47.041445170181866]],
    [ "Gérédis",[-0.3459698253253558, 47.00829559654993]],
    [ "Gérédis",[-0.3177566209406569, 46.99810329248528]],
    [ "Enedis",[4.952922904026218, 44.95998994934416]],
    [ "Enedis",[-2.184805353085568, 48.60192996527395]],
    [ "Enedis",[-2.1899506963452975, 48.585049466976685]],
    [ "Enedis",[4.94337755, 44.856062]],
    [ "Enedis",[3.824427731183532, 45.160737285771766]],
    [ "Enedis",[2.876041198313515, 42.679123732447344]],
    [ "Enedis",[1.1278981787240776, 49.02179834659577]],
    [ "Enedis",[1.1279468350960344, 49.021799721341644]],
    [ "Enedis",[1.08025785, 49.40585815]],
    [ "Enedis",[2.8767870999999996, 50.64898501666668]],
    [ "Enedis",[1.134409469361453, 49.02102580751704]],
    [ "Enedis",[1.1341631499999998, 49.0230561]],
    [ "Enedis",[-4.558885105789357, 48.37439816755734]],
    [ "Enedis",[1.7129640154943686, 46.77380628265137]],
    [ "Enedis",[1.7145834342524204, 46.77254292510334]],
    [ "Enedis",[1.7108525000000012, 46.78575535000001]],
    [ "Enedis",[4.64003619764454, 49.65019551420414]],
    [ "Enedis",[0.4596964620954291, 43.75799992042276]],
    [ "Enedis",[1.6502636814836251, 46.77452251683103]],
    [ "Enedis",[1.6759001701605225, 46.80876160273352]],
    [ "Enedis",[3.02004746401959, 50.626569715322724]],
    [ "787400602",[6.282628757519538, 45.04357243081633]],
    [ "Enedis",[4.691684421841581, 49.69422590719283]],
    [ "Enedis",[4.693145970401575, 49.69452680573733]],
    [ "Enedis",[3.343255229079963, 46.564017793940835]],
    [ "Enedis",[3.3192863999999997, 46.5591865]],
    [ "Enedis",[3.333280846733922, 46.547373867660745]],
    [ "Enedis",[4.455033572437649, 49.4810407802406]],
    [ "Enedis",[-1.3946748500000004, 46.66625265]],
    [ "Enedis",[4.962060178115968, 44.92703095382297]],
    [ "Enedis",[4.942884258590981, 44.89877541853427]],
    [ "Enedis",[-0.6354319824950961, 45.02532139786501]],
    [ "Enedis",[4.416862194024454, 49.69868386054094]],
    [ "Enedis",[-1.4192582500000004, 46.65388915]],
    [ "Enedis",[1.7373255283461686, 48.93241116682256]],
    [ "Enedis",[5.032645113464867, 47.326238768811805]],
    [ "Poste électrique du Lavoir",[5.032233325690412, 47.32629186741945]],
    [ "Enedis",[1.6422276899341999, 46.81133397010112]],
    [ "Enedis",[1.7231744500000004, 46.87272109999999]],
    [ "Enedis",[5.030613689595155, 47.32409521255288]],
    [ "Enedis",[5.030124818758258, 47.323652386253215]],
    [ "Enedis",[5.029468236109638, 47.32291165535645]],
    [ "Enedis",[5.037132370393846, 47.32631745304217]],
    [ "Enedis",[-0.88598545, 46.95904865000001]],
    [ "Enedis",[-0.8857843999999998, 46.9608188]],
    [ "Enedis",[3.0472419847393506, 50.67221074181534]],
    [ "Poste électrique de Travo",[9.370141626432071, 41.926811103560816]],
    [ "Enedis",[2.3007057035659866, 48.5898681795736]],
    [ "Enedis",[5.034785168673254, 47.30585350698146]],
    [ "Enedis",[5.03534307639422, 47.30255654585249]],
    [ "Enedis",[5.0407656081963585, 47.299796321653304]],
    [ "Enedis",[5.040222602943693, 47.30081137223608]],
    [ "Enedis",[5.037093446540878, 47.30760044103774]],
    [ "Enedis",[5.038934233455875, 47.30395987348234]],
    [ "Enedis",[5.040559057397477, 47.30361624380937]],
    [ "Bregand",[5.044390266071447, 47.334013836978656]],
    [ "Enedis",[-4.5613851, 48.37641365]],
    [ "Enedis",[1.7277251297648426, 47.38041562112652]],
    [ "Enedis",[5.073449262879522, 47.33067495032545]],
    [ "Enedis",[5.06968834537006, 47.33343401610507]],
    [ "Enedis",[5.072124559540228, 47.331751857471254]],
    [ "Enedis",[5.070899278431373, 47.331739568627455]],
    [ "Enedis",[1.8805730500000015, 47.88623345000001]],
    [ "789995706",[2.9277406, 50.32935385]],
    [ "Enedis",[2.929818574593114, 50.33353895868956]],
    [ "Enedis",[1.5716119710877863, 47.28092790884225]],
    [ "Enedis",[2.5814068689936636, 46.31970661712328]],
    [ "Enedis",[2.6265143698464515, 46.36595880180399]],
    [ "Enedis",[6.066870123787601, 46.31464985969674]],
    [ "Enedis",[-1.4196905500000008, 46.6808261]],
    [ "Enedis",[-1.4177833815198935, 46.67756394478897]],
    [ "Zone Artisanale",[5.961914999999999, 46.57134955]],
    [ "Enedis",[-0.8544373999999991, 46.91763805]],
    [ "Enedis",[-0.5845106454591624, 46.113072863878955]],
    [ "Gouges",[5.044968912323232, 47.33694962875167]],
    [ "Enedis",[2.27921735902672, 48.764683337611864]],
    [ "Enedis",[4.93537333418654, 44.854756732166706]],
    [ "Enedis",[1.9069185623695157, 47.26333472400918]],
    [ "Enedis",[1.912810132681233, 47.27105888458534]],
    [ "Enedis",[1.8823447134568465, 47.267727810621196]],
    [ "Enedis",[1.0158830688895903, 49.44195984163478]],
    [ "Enedis",[5.034876384475069, 46.97824964599913]],
    [ "Enedis",[1.963908350000002, 47.91162715]],
    [ "Enedis",[-4.3434988, 48.560543949999996]],
    [ "Enedis",[-1.946103295652361, 49.71579533542472]],
    [ "Enedis",[5.026056301070061, 47.35738368660581]],
    [ "Enedis",[5.026813383746871, 47.3568502236222]],
    [ "Enedis",[5.02667719397522, 47.357012876114624]],
    [ "Enedis",[5.029371171743961, 47.35558638432497]],
    [ "Enedis",[5.036404553118961, 47.3540909498128]],
    [ "Enedis",[5.040378166229585, 47.35759667871749]],
    [ "Enedis",[5.053825099059336, 47.35302086580834]],
    [ "Enedis",[5.0584162728243385, 47.3512655322235]],
    [ "Enedis",[5.0527235842763165, 47.35045733347867]],
    [ "Enedis",[5.051177474871012, 47.34999824247986]],
    [ "Enedis",[5.049707027951317, 47.349501818696005]],
    [ "Enedis",[5.0495582644216785, 47.350343011242686]],
    [ "Enedis",[5.048557774192289, 47.349105144755974]],
    [ "Enedis",[5.048223696269262, 47.3475080244931]],
    [ "Enedis",[5.047090650859267, 47.348602001455646]],
    [ "Enedis",[5.045989622409116, 47.34897494671731]],
    [ "Enedis",[5.046231876944294, 47.34981966524688]],
    [ "Enedis",[5.045837444901466, 47.347827682650475]],
    [ "Enedis",[5.044270133237679, 47.3474979908298]],
    [ "Enedis",[5.043915989914178, 47.35262817648353]],
    [ "Enedis",[5.043448414481009, 47.35265055939176]],
    [ "Enedis",[5.041196732225498, 47.35084177223423]],
    [ "Enedis",[5.0403071824275365, 47.34927090018115]],
    [ "Enedis",[5.038493317978428, 47.35036369274177]],
    [ "Enedis",[5.036643141987181, 47.35017629358975]],
    [ "Enedis",[5.038736584179052, 47.347928788698354]],
    [ "Enedis",[5.0400639509774035, 47.345525864810035]],
    [ "Enedis",[5.044156581314413, 47.342547544726024]],
    [ "Enedis",[5.043012922833267, 47.34116895131401]],
    [ "Bourdelle",[5.041892952624643, 47.340942861269475]],
    [ "Enedis",[5.043033164745986, 47.338554638025016]],
    [ "Enedis",[5.037870000000002, 47.337486]],
    [ "Enedis",[5.03597763651191, 47.336221544795926]],
    [ "Enedis",[5.032505873252014, 47.33589580113009]],
    [ "Enedis",[5.0304735439647, 47.33464903113558]],
    [ "Enedis",[5.03150355, 47.33366389999999]],
    [ "Enedis",[5.029734250000001, 47.33107055]],
    [ "Enedis",[5.038980200000001, 47.33006500000002]],
    [ "Lecoulteux",[5.037380674798426, 47.32948488386492]],
    [ "Enedis",[5.036007914575903, 47.329339324973674]],
    [ "Lacordaire",[5.034576320222936, 47.328890107319616]],
    [ "Gastinel 1",[5.03235805, 47.3337346]],
    [ "Enedis",[5.02762062427369, 47.333277225104005]],
    [ "Enedis",[5.028131418551403, 47.328713463225675]],
    [ "Enedis",[5.026384133515708, 47.32861810173255]],
    [ "Enedis",[5.025877279099273, 47.32802835505799]],
    [ "Enedis",[5.028281731437945, 47.32702845322041]],
    [ "Enedis",[5.028306132585799, 47.324450755235404]],
    [ "Enedis",[5.024723333733073, 47.32539973553329]],
    [ "Enedis",[5.021647899878899, 47.324162908862554]],
    [ "Enedis",[5.0203151675626785, 47.32715218382973]],
    [ "Enedis",[2.8043715831174802, 50.25908054972415]],
    [ "Enedis",[2.7981032657208638, 50.26196729787194]],
    [ "Enedis",[5.030732316334294, 47.32509637866038]],
    [ "Enedis",[4.990016226908908, 47.302199251058]],
    [ "Enedis",[6.5340028, 43.2492503]],
    [ "Enedis",[2.8694921499999997, 50.67761315]],
    [ "réséda",[6.1041028668601225, 49.07829207036166]],
    [ "Enedis",[-0.5304208786711505, 44.722012676973215]],
    [ "Enedis",[2.5487771552594904, 50.44466311745306]],
    [ "Enedis",[5.00044001068315, 47.318450781762714]],
    [ "Enedis",[5.006469594709828, 47.3186574826004]],
    [ "Enedis",[5.006447805826868, 47.318055631178034]],
    [ "Enedis",[5.008669167292741, 47.31872492340876]],
    [ "Enedis",[5.002534765057895, 47.31664452032995]],
    [ "Enedis",[4.998170702036442, 47.31516346623794]],
    [ "Enedis",[4.993913504925996, 47.32132460186868]],
    [ "Enedis",[4.996802501315588, 47.32210996471296]],
    [ "Enedis",[4.998332419873906, 47.322130523428726]],
    [ "Enedis",[4.999613622440711, 47.321874904962606]],
    [ "Enedis",[4.999885596585591, 47.32097593679059]],
    [ "Enedis",[5.001307812096555, 47.32082637198264]],
    [ "Enedis",[5.002016710319685, 47.320123702635996]],
    [ "Enedis",[5.00376138194579, 47.32012819523323]],
    [ "Enedis",[5.004508552592959, 47.319468841446586]],
    [ "Enedis",[5.00529510055386, 47.31961296586431]],
    [ "Enedis",[1.956535083840272, 47.92198383219669]],
    [ "Enedis",[5.010903070288624, 45.737624672112766]],
    [ "Enedis",[2.635399021093187, 50.39255157800839]],
    [ "Enedis",[4.9521295499999995, 44.847335699999995]],
    [ "Enedis",[5.029986427336073, 47.34980885680566]],
    [ "Enedis",[5.031429305306934, 47.35226282672549]],
    [ "Enedis",[2.478986382727757, 50.61453369106231]],
    [ "Enedis",[2.7820648752915753, 50.268913032155965]],
    [ "Enedis",[1.9159187903908077, 47.92382974875569]],
    [ "Enedis",[1.921370809501076, 47.9194800461202]],
    [ "Enedis",[1.9247048473770267, 47.920345352696074]],
    [ "Enedis",[1.9249731098475633, 47.921844558067214]],
    [ "Enedis",[1.9264622503145772, 47.91832319949951]],
    [ "Enedis",[1.9223670106690427, 47.91848439122382]],
    [ "Enedis",[1.9135916000000002, 47.91594685]],
    [ "Enedis",[1.918077131541697, 47.91720041787078]],
    [ "Enedis",[1.9203435005676002, 47.91734662655506]],
    [ "Enedis",[1.9329791987866114, 47.91876705517836]],
    [ "Enedis",[1.910874436654968, 47.89832071521769]],
    [ "Enedis",[1.9044204045440196, 47.90067181697696]],
    [ "Enedis",[1.8973421361191432, 47.90457878042316]],
    [ "Enedis",[1.901849331565026, 47.905944172057566]],
    [ "Enedis",[1.9124952644786575, 47.90280401765739]],
    [ "Enedis",[1.89575687411771, 47.902246997740484]],
    [ "Enedis",[4.207080082576357, 47.34412125048051]],
    [ "Villec",[-3.6473605000000004, 48.693604549999996]],
    [ "795254597",[1.9237275324509202, 47.91812933248302]],
    [ "Enedis",[1.9257384142318728, 47.91778932510088]],
    [ "Enedis",[6.950631983333634, 48.28739597373359]],
    [ "Enedis",[6.950625507515992, 48.28736547647157]],
    [ "Enedis",[6.948592383922786, 48.28822373929939]],
    [ "Enedis",[6.9600244157470605, 48.286276610482204]],
    [ "Enedis",[6.9427306314307495, 48.288679630872295]],
    [ "Enedis",[6.942549445491835, 48.29043525865781]],
    [ "Enedis",[6.945238494862537, 48.289827414257324]],
    [ "Enedis",[6.939878161677126, 48.29193092616025]],
    [ "Enedis",[6.937134395930943, 48.29233706012426]],
    [ "Enedis",[6.93549248543641, 48.29270197567875]],
    [ "Enedis",[6.932013055552338, 48.296284758559594]],
    [ "Enedis",[6.932980191980765, 48.2977610952019]],
    [ "Enedis",[6.935949776443022, 48.295777197465895]],
    [ "Enedis",[6.938645476757836, 48.29447067449094]],
    [ "Enedis",[6.948634507714169, 48.29397862454793]],
    [ "Enedis",[6.9513398516890295, 48.292676063275806]],
    [ "Enedis",[6.950427398407515, 48.288594757360094]],
    [ "Enedis",[6.955217149999999, 48.29220940000001]],
    [ "Enedis",[6.968191742812467, 48.2861077533499]],
    [ "Enedis",[6.957183998541822, 48.28327646999712]],
    [ "Enedis",[6.958515593064537, 48.28187042133865]],
    [ "Enedis",[1.9551904677192495, 47.90594115394719]],
    [ "Enedis",[1.9822812500000007, 47.9071692]],
    [ "Enedis",[5.009216110077025, 45.74117307873742]],
    [ "RTE",[2.01407905, 47.9169818]],
    [ "Enedis",[1.9638304755598786, 47.90950058730865]],
    [ "Enedis",[1.9794934771566728, 47.91087879121107]],
    [ "Enedis",[1.976441424417359, 47.91075750383927]],
    [ "Enedis",[1.97661024205808, 47.912830676999405]],
    [ "Enedis",[1.9745000575012803, 47.91284517583205]],
    [ "Enedis",[1.9914297999999995, 47.92315335]],
    [ "Enedis",[1.9986361748146317, 47.9239519948042]],
    [ "Enedis",[2.000748803176086, 47.9182928818932]],
    [ "Enedis",[1.9978454247349413, 47.91651319283467]],
    [ "Enedis",[1.99149995, 47.9085398]],
    [ "Enedis",[1.98031375, 47.90488834999999]],
    [ "Enedis",[1.9337071499999998, 47.905649849999996]],
    [ "Enedis",[1.931956778199205, 47.90168149134538]],
    [ "Enedis",[1.927096379340594, 47.901712693739064]],
    [ "Enedis",[1.9233579499999998, 47.91096005]],
    [ "Enedis",[1.930191192469491, 47.911643022455934]],
    [ "Enedis",[6.929938229476184, 48.2920665938235]],
    [ "Enedis",[6.928730431569094, 48.29459410689184]],
    [ "Enedis",[6.9183624748752806, 48.29312576080484]],
    [ "Enedis",[6.919886856854978, 48.29915375494104]],
    [ "Enedis",[6.918617361317124, 48.30166432855489]],
    [ "Enedis",[6.900449970907057, 48.27518483141131]],
    [ "Enedis",[6.904553146229959, 48.274297828549756]],
    [ "Enedis",[6.8965727217709825, 48.272334257635684]],
    [ "Enedis",[6.949917072825242, 48.281243369452554]],
    [ "Enedis",[6.949893793753756, 48.27995793784363]],
    [ "Enedis",[6.954342842734531, 48.280335250189616]],
    [ "Enedis",[6.953621331447212, 48.282504460091324]],
    [ "Enedis",[6.961634301154854, 48.27886926706052]],
    [ "Enedis",[6.964506762014596, 48.27544940054924]],
    [ "Enedis",[6.964825359733211, 48.27304963635153]],
    [ "Enedis",[6.926135835094356, 48.30143357255465]],
    [ "Enedis",[6.918441270652378, 48.31067088156929]],
    [ "Enedis",[4.617759241887957, 45.08215087456832]],
    [ "Enedis",[4.868264488695387, 45.010743770625666]],
    [ "Enedis",[4.8887946499999995, 45.07397795000001]],
    [ "Enedis",[4.890438650000001, 45.07091725]],
    [ "Enedis",[3.6438298160955354, 49.0683013884949]],
    [ "Enedis",[3.641338929481753, 49.06823049645169]],
    [ "Enedis",[4.707451966250326, 45.146027034137404]],
    [ "La Vialette",[4.512643673616511, 45.111302377423286]],
    [ "Enedis",[4.502351854716546, 45.09975719812402]],
    [ "Les Asclards",[4.528535650872539, 45.09313398937507]],
    [ "Enedis",[1.8965142723733677, 47.93224620352609]],
    [ "Enedis",[2.2698190323179985, 46.13719666991776]],
    [ "Enedis",[2.029228034644164, 50.28897658886165]],
    [ "Enedis",[1.9047425452790192, 47.9189350799147]],
    [ "Enedis",[1.8884577999999999, 47.92013015]],
    [ "Enedis",[1.8862714500000002, 47.908422650000006]],
    [ "Enedis",[1.8723520999999994, 47.90114605]],
    [ "Enedis",[1.8756518983053356, 47.91393087449365]],
    [ "Enedis",[1.8856772646344842, 47.88665996744301]],
    [ "Enedis",[1.881820544985137, 47.88687402483843]],
    [ "Enedis",[1.8776509499999998, 47.884607]],
    [ "Enedis",[1.8575187000000002, 47.91541935]],
    [ "Enedis",[1.8668912711955084, 47.91343459466243]],
    [ "Enedis",[1.8976853500000002, 47.883411349999996]],
    [ "Enedis",[1.8967720396792478, 47.88110147267876]],
    [ "Enedis",[1.8960055661254671, 47.87977266705353]],
    [ "Enedis",[1.8787582653492978, 47.92447481423149]],
    [ "Enedis",[1.8820261714573776, 47.93270250865595]],
    [ "Enedis",[1.9108949500000012, 47.874910050000004]],
    [ "Enedis",[1.9202397744704507, 47.881135891323424]],
    [ "Enedis",[1.9161007500000002, 47.876960800000006]],
    [ "Enedis",[1.9291083000000002, 47.88855800000001]],
    [ "Enedis",[1.8877529941187023, 47.927698918673975]],
    [ "Enedis",[1.8788257499999999, 47.915496299999994]],
    [ "Enedis",[1.8661755319547504, 47.91718020166108]],
    [ "Enedis",[1.856573516193949, 47.919606196600455]],
    [ "Enedis",[1.6973328872833295, 49.514181480101946]],
    [ "Enedis",[1.87510345, 47.86704159999999]],
    [ "Enedis",[1.8811698511893868, 47.86466857942118]],
    [ "Enedis",[1.8723546182911093, 47.86446360200563]],
    [ "RTE",[1.9350268499999999, 47.86698635]],
    [ "Enedis",[1.9071581670139928, 47.856106895757655]],
    [ "Enedis",[1.90941665, 47.85279795]],
    [ "Enedis",[1.9127768220564831, 47.85151439140811]],
    [ "Enedis",[1.9125008262957268, 47.84918695847026]],
    [ "Enedis",[1.9109414, 47.854035350000004]],
    [ "Enedis",[1.9424619331522173, 47.845817502339415]],
    [ "Enedis",[1.9344669637721759, 47.8402324172739]],
    [ "Enedis",[1.9153785339306548, 47.83953847229997]],
    [ "Enedis",[1.9050291, 47.8616755]],
    [ "Enedis",[1.9070725499999999, 47.854584349999996]],
    [ "Enedis",[1.9152550500000014, 47.84913425]],
    [ "Enedis",[1.914581300000001, 47.84376265]],
    [ "Enedis",[1.9239724500000004, 47.84844805000001]],
    [ "Enedis",[1.9215483225349672, 47.84594098175659]],
    [ "Enedis",[1.9127977230052409, 47.849540790926724]],
    [ "Enedis",[1.9248036000000002, 47.8501294]],
    [ "Enedis",[1.9168110000000007, 47.840777849999995]],
    [ "Enedis",[1.9072082739450562, 47.84352337144612]],
    [ "Enedis",[1.892663271054577, 47.85169507080456]],
    [ "CARMES(LES)",[1.9350597972679993, 47.86693798629625]],
    [ "Enedis",[1.9591209667240723, 47.81799362922036]],
    [ "Enedis",[1.953144100000001, 47.810794300000005]],
    [ "Enedis",[1.9706911204619482, 47.82711515783362]],
    [ "Enedis",[1.9169699500000006, 47.85461105]],
    [ "Enedis",[1.8941850750817442, 47.84584504377821]],
    [ "Enedis",[1.9139301500000006, 47.85143835000001]],
    [ "Enedis",[1.9165529724835604, 47.83921420736121]],
    [ "Enedis",[1.92071, 47.84106835]],
    [ "Enedis",[1.9566718807897205, 47.89982818758947]],
    [ "Enedis",[1.9462679949247776, 47.91689613047853]],
    [ "Enedis",[2.034173, 47.905259449999996]],
    [ "Enedis",[2.0155111000000003, 47.901871750000005]],
    [ "Enedis",[2.0218219131738784, 47.89917826578532]],
    [ "Enedis",[2.03317765, 47.894129649999996]],
    [ "Enedis",[2.539699783897148, 50.4708055493007]],
    [ "Enedis",[4.965910505169795, 48.8069052141217]],
    [ "Enedis",[5.171687349387966, 47.49458212525189]],
    [ "Enedis",[1.0220053500000001, 49.49165840000001]],
    [ "Poste électrique du CSG de Kourou",[-52.78200423112549, 5.223018694181103]],
    [ "Enedis",[4.816897530566593, 45.09518517750707]],
    [ "Enedis",[4.803563349999998, 45.115942499999996]],
    [ "Enedis",[4.814313449999999, 45.131198099999985]],
    [ "Enedis",[4.816758315623425, 45.13714068918456]],
    [ "Enedis",[4.817934550221865, 45.14024071673787]],
    [ "Enedis",[4.832527781806808, 45.16198460165165]],
    [ "Enedis",[4.837460759030447, 45.1599608824154]],
    [ "Enedis",[4.9002501999999994, 45.1127065]],
    [ "Enedis",[2.8451169177378333, 47.59923363165121]],
    [ "Enedis",[6.503477328700321, 48.58960647348836]],
    [ "Enedis",[1.0198380077129954, 49.33532793455117]],
    [ "Enedis",[4.692913502853233, 45.064885717925875]],
    [ "Enedis",[4.672410096397894, 45.02142613287614]],
    [ "Enedis",[3.9959102852344146, 49.22677793642561]],
    [ "Gérédis",[-0.15231474999999978, 46.535757450000006]],
    [ "Enedis",[5.046395377998281, 47.33991111868463]],
    [ "Enedis",[4.816188830701232, 45.07517374789938]],
    [ "Enedis",[4.802092418027455, 45.06792358267234]],
    [ "Enedis",[3.0555577366931326, 50.68977763154999]],
    [ "Enedis",[3.15975875, 50.603634549999995]],
    [ "Enedis",[3.1589668500000005, 50.6037741]],
    [ "Enedis",[3.1611473867415305, 50.602618068254536]],
    [ "Enedis",[-1.4311008999999997, 46.71291430000001]],
    [ "Enedis",[-1.43237955, 46.725115249999995]],
    [ "Enedis",[0.5132977084301255, 45.09215452531111]],
    [ "Enedis",[0.5399142132477615, 45.08925803410503]],
    [ "Enedis",[5.045669163858138, 47.34411870601157]],
    [ "Enedis",[6.97047174046124, 49.19509179774258]],
    [ "Enedis",[-0.5617180829612303, 44.786010980709605]],
    [ "Enedis",[-0.5617882781391749, 44.78599447383561]],
    [ "Enedis",[4.75159025, 45.16407405]],
    [ "Enedis",[4.786261349999999, 45.18756855]],
    [ "Enedis",[4.8079437999999985, 45.18557185]],
    [ "Enedis",[4.800694103120808, 45.18686702795743]],
    [ "Enedis",[0.5864649132823886, 45.083607194579834]],
    [ "SA Mermoz",[2.2586020999999996, 48.97292809999999]],
    [ "Enedis",[-0.56209981955492, 44.78448839131214]],
    [ "Enedis",[-2.3936771807717365, 47.39283024531669]],
    [ "Enedis",[-2.4126815567588977, 47.40400618234431]],
    [ "Enedis",[-1.3581383080686122, 46.665408173531674]],
    [ "Enedis",[4.890135049157752, 44.091028566764074]],
    [ "Enedis",[5.65700345, 46.25674999999999]],
    [ "RTE",[6.537825236955715, 43.84530328459931]],
    [ "Enedis",[5.058436011244981, 47.34767646900937]],
    [ "Enedis",[5.056154486397575, 47.34751052062582]],
    [ "Enedis",[5.052572414653088, 47.348817158027536]],
    [ "Enedis",[5.051269780016438, 47.348756723184614]],
    [ "Enedis",[5.047915149750877, 47.35013796593564]],
    [ "Enedis",[6.9652611037814935, 48.26643988971427]],
    [ "Les Fontanettes",[5.959975450032382, 46.57421203228685]],
    [ "Enedis",[5.958298516755055, 46.57225399886157]],
    [ "pompier",[5.956695750000001, 46.575700049999995]],
    [ "P.R.L. Chalets",[5.966739633084726, 46.579858024383476]],
    [ "Franzini",[5.956690044153174, 46.57906828370599]],
    [ "Salle des Fêtes",[5.9551515, 46.57761395]],
    [ "3 eme Saphis",[5.951888383917042, 46.57817880110226]],
    [ "Griffon",[5.9517079971713365, 46.578108434062955]],
    [ "Passage a Niveau",[5.950624449999999, 46.5756617]],
    [ "Chemin du Bois",[5.96670835, 46.579819400000005]],
    [ "Enedis",[-4.002348928040147, 48.10448075835883]],
    [ "Enedis",[4.78291135, 45.09548015000001]],
    [ "Enedis",[4.78433215, 45.05847124999999]],
    [ "Enedis",[4.843277374009933, 44.99608373514929]],
    [ "Enedis",[4.797455779975599, 44.98109652226881]],
    [ "Enedis",[4.798175200000001, 44.97753864999999]],
    [ "Enedis",[4.699391939976058, 45.112449870719466]],
    [ "Enedis",[4.697012000000001, 45.11113065000001]],
    [ "Enedis",[6.1373883343807965, 46.53730760277868]],
    [ "Enedis",[6.137867883504969, 46.53264970262678]],
    [ "Enedis",[6.121238955270941, 46.52933791307426]],
    [ "Enedis",[6.091374783236128, 46.50957265026385]],
    [ "Enedis",[6.0785928, 46.50072535]],
    [ "Enedis",[6.096712725827999, 46.48966115959854]],
    [ "Enedis",[4.800864450000001, 45.12097335000001]],
    [ "Enedis",[4.771218855286681, 45.13123061883951]],
    [ "Enedis",[4.769239625203535, 45.12988290473128]],
    [ "Enedis",[4.772259360825575, 45.126435967059514]],
    [ "Enedis",[4.7356971430700625, 45.110110533575714]],
    [ "Enedis",[4.7187506283826, 45.09997854628694]],
    [ "Enedis",[1.5048595500000008, 43.49815569999999]],
    [ "Enedis",[4.775913550000001, 45.006523200000004]],
    [ "Enedis",[4.7380436999999995, 45.04958534999999]],
    [ "Enedis",[4.648277963576481, 45.095810082498225]],
    [ "Enedis",[4.666637712217645, 45.088776377937215]],
    [ "Enedis",[4.663987983442517, 45.090193650127866]],
    [ "Enedis",[4.669522049999999, 45.09594285]],
    [ "Enedis",[4.721794535626678, 45.12496497267581]],
    [ "Enedis",[6.062677130357611, 46.48057293097792]],
    [ "Enedis",[6.0636267841108245, 46.48017081048706]],
    [ "Enedis",[6.064265015293323, 46.478040704311994]],
    [ "Enedis",[6.0708285666076, 46.486129516674765]],
    [ "Enedis",[-1.877716729029119, 46.95872760437907]],
    [ "Enedis",[6.055884384655985, 46.48555711861562]],
    [ "Enedis",[6.041352799999999, 46.48933495]],
    [ "Enedis",[6.05622436667997, 46.48396915023084]],
    [ "Enedis",[-0.6150468499999999, 44.73516565]],
    [ "Enedis",[6.033037083539779, 46.496730343886064]],
    [ "Enedis",[6.043870093972326, 46.49363877401469]],
    [ "Enedis",[4.56645865, 44.90248075]],
    [ "Enedis",[4.557415247134952, 44.97394811667198]],
    [ "Enedis",[4.622397415657116, 45.00888197829767]],
    [ "Enedis",[4.618353338521537, 44.99162452479122]],
    [ "Enedis",[4.593057300000001, 44.9905506]],
    [ "Enedis",[4.5706811, 44.981638450000005]],
    [ "Enedis",[4.571494001000344, 44.98341806722063]],
    [ "Enedis",[4.5811746, 44.983205049999995]],
    [ "Enedis",[4.58205375, 44.994300949999996]],
    [ "Enedis",[4.5788104999999995, 44.989947199999996]],
    [ "Enedis",[4.5759147180011945, 44.989277107235395]],
    [ "Enedis",[4.5707998207436615, 44.99148022287433]],
    [ "Enedis",[6.02667295, 46.4979968]],
    [ "Enedis",[6.026723756013321, 46.4949812726485]],
    [ "Enedis",[5.082727930754235, 47.329514091804036]],
    [ "Enedis",[6.07193685, 46.5604056]],
    [ "Enedis",[6.04338355, 46.5489513]],
    [ "Enedis",[6.293904400000001, 46.30562755]],
    [ "Enedis",[6.48564053315282, 44.55601169717196]],
    [ "Enedis",[1.9430297000000003, 50.4038894]],
    [ "Enedis",[1.9432989671188088, 50.40468199859514]],
    [ "Enedis",[1.9550731230825378, 50.403705877654296]],
    [ "Enedis",[1.9263466000000002, 50.41013395000001]],
    [ "Enedis",[1.9314866315446282, 50.40609630642515]],
    [ "Enedis",[1.9363105539635426, 50.40657728695638]],
    [ "Enedis",[1.9333504543264266, 50.408796584444346]],
    [ "Enedis",[1.9277796078585536, 50.40696611506887]],
    [ "Enedis",[6.001579993549999, 46.53699711352872]],
    [ "Enedis",[5.997962316402222, 46.53595879883022]],
    [ "Enedis",[5.99138421499272, 46.53079729737991]],
    [ "Enedis",[5.077048262155242, 47.33363621045595]],
    [ "Enedis",[5.089070648547487, 47.338271476305316]],
    [ "Enedis",[5.093161192363637, 47.33454101333333]],
    [ "Enedis",[5.090630827873229, 47.335429444325825]],
    [ "Enedis",[5.11176891558957, 47.34112362177823]],
    [ "Enedis",[5.105217135002555, 47.3398679169477]],
    [ "Enedis",[0.09097924999999998, 49.518718549999996]],
    [ "Enedis",[5.0035911692751025, 45.72091174674827]],
    [ "Enedis",[1.5362584158504469, 43.580689217482885]],
    [ "Enedis",[6.027188454844732, 46.51296398168978]],
    [ "Enedis",[6.0204888, 46.524291649999995]],
    [ "Enedis",[6.022515180070934, 46.524605146170806]],
    [ "Enedis",[2.106402841719387, 49.42584442884152]],
    [ "Enedis",[3.9847532175813796, 49.26762970858599]],
    [ "Enedis",[4.9873011161514516, 45.72985889106959]],
    [ "Enedis",[4.985934800000001, 45.73040655]],
    [ "Enedis",[4.991627837191426, 47.33542590691867]],
    [ "Enedis",[-1.5342132788645915, 48.73841715754228]],
    [ "Enedis",[1.5110407000000001, 43.54105770000001]],
    [ "Enedis",[4.891984729848139, 45.74749039768609]],
    [ "Enedis",[1.815907179729393, 46.82890867879833]],
    [ "Enedis",[1.948162569761823, 49.39886741067237]],
    [ "Enedis",[1.8949145, 47.8873432]],
    [ "Enedis",[1.898136717214881, 47.89010913816886]],
    [ "Enedis",[1.9127988072289277, 47.91369829850927]],
    [ "réséda",[6.145676550000002, 49.18951305]],
    [ "Valmy",[2.4428320974354905, 48.806259574136575]],
    [ "HLM Croc",[4.23624426463135, 45.244716963367594]],
    [ "réséda",[6.21246025, 49.12764045]],
    [ "réséda",[6.215101949999999, 49.12878704999999]],
    [ "Enedis",[5.034186309556996, 47.34692058819826]],
    [ "Enedis",[5.0354700929771194, 47.34678168469714]],
    [ "Enedis",[5.021958894316784, 47.33828589850908]],
    [ "Enedis",[5.064257198916745, 47.34765299714414]],
    [ "Enedis",[5.0625655, 47.34202835]],
    [ "Enedis",[5.0640107468661535, 47.33826960527225]],
    [ "Enedis",[5.061074955675597, 47.34147847883895]],
    [ "Enedis",[5.073717171552304, 47.34361933647257]],
    [ "Enedis",[5.074150506272907, 47.350342276860886]],
    [ "Enedis",[5.0788063624217115, 47.35060123757828]],
    [ "Enedis",[-1.3524628672562022, 46.85457176850189]],
    [ "Enedis",[-1.3524051195246438, 46.85459494553504]],
    [ "Enedis",[3.18819785, 49.09552935]],
    [ "Enedis",[5.061417111860501, 47.33583030711869]],
    [ "Enedis",[5.062988484370622, 47.335155144625595]],
    [ "Enedis",[5.065431331485758, 47.33320303702849]],
    [ "Enedis",[5.066484092849782, 47.332331694656574]],
    [ "Enedis",[5.067693417924024, 47.33103528416266]],
    [ "Enedis",[5.0681468024275524, 47.33048092875617]],
    [ "Enedis",[5.068458664445583, 47.329409252178365]],
    [ "Enedis",[5.069991610323672, 47.32942858191242]],
    [ "Enedis",[5.065693671213629, 47.329781275151525]],
    [ "Enedis",[5.059391080878967, 47.33061620812733]],
    [ "Enedis",[5.04554686200887, 47.339292417961765]],
    [ "Enedis",[5.045107151006163, 47.33890937619459]],
    [ "Enedis",[5.050780768796653, 47.33652348811602]],
    [ "Enedis",[5.04725361292402, 47.33782902982348]],
    [ "Michel",[5.046933982126865, 47.33767208150785]],
    [ "Enedis",[5.047485642127645, 47.33510347436475]],
    [ "Enedis",[5.046114933737334, 47.334771482998946]],
    [ "Michaud",[5.04629808191988, 47.33301690440063]],
    [ "Enedis",[5.046306530936117, 47.33310237999904]],
    [ "Delmas",[5.048137171824859, 47.33234129983087]],
    [ "Enedis",[5.048141136430884, 47.33237072274744]],
    [ "Enedis",[5.0439894576959325, 47.331058117790036]],
    [ "Enedis",[5.044010446542261, 47.33072375861691]],
    [ "Enedis",[2.8512599832751744, 50.57182849184027]],
    [ "Enedis",[2.850160352227452, 50.570636679979714]],
    [ "Enedis",[2.852711475962227, 50.57066864988603]],
    [ "Enedis",[-1.6016667434618728, 46.74015516733352]],
    [ "Enedis",[1.905941739345003, 47.886841169885805]],
    [ "Enedis",[2.3885589280392105, 48.51956999683758]],
    [ "Enedis",[2.3811865312609135, 48.52737235161605]],
    [ "806895791",[1.4163912999999995, 48.75849464999999]],
    [ "Enedis",[0.6585484704504878, 45.974048497412355]],
    [ "Enedis",[0.9622945242395562, 49.55217241218024]],
    [ "Enedis",[0.9556231842813722, 49.5486752716288]],
    [ "Enedis",[0.9571238465948767, 49.56079660296662]],
    [ "Enedis",[0.9562552981432094, 49.55438340135298]],
    [ "Enedis",[0.9582564423529043, 49.55399366113447]],
    [ "Enedis",[-1.322496041137441, 46.7928660693523]],
    [ "Enedis",[0.9425102164441929, 49.539494101226055]],
    [ "Enedis",[2.794361682185062, 50.56942898300176]],
    [ "Enedis",[-0.03800198142623416, 49.23086074210268]],
    [ "Enedis",[0.940644669358687, 49.57244696899903]],
    [ "Enedis",[1.878103150000001, 47.9038281]],
    [ "Enedis",[1.8848603751715647, 47.88742964567262]],
    [ "Enedis",[5.057895057401287, 47.328348072343935]],
    [ "Enedis",[5.060760114509488, 47.32869748493901]],
    [ "Enedis",[5.062899013592222, 47.32786390655283]],
    [ "Enedis",[5.064552765348311, 47.328183607839925]],
    [ "Enedis",[5.065543993080864, 47.328645430483114]],
    [ "Enedis",[5.068933668056551, 47.32870927227674]],
    [ "Enedis",[5.066683193328201, 47.32772458742622]],
    [ "Enedis",[5.0576015, 47.32705335]],
    [ "Enedis",[5.058282565014646, 47.32576405085882]],
    [ "Enedis",[5.059791061934106, 47.326164310357896]],
    [ "Enedis",[5.0613673902402745, 47.32656195495449]],
    [ "Enedis",[5.056324958360696, 47.32227764656754]],
    [ "Enedis",[5.052090365814945, 47.32910618751813]],
    [ "Enedis",[5.0556843987838604, 47.32929944355289]],
    [ "Enedis",[5.049539551926099, 47.329233411721034]],
    [ "Enedis",[5.049108726420518, 47.32822132291356]],
    [ "Enedis",[5.0467718438236275, 47.329246499016406]],
    [ "Enedis",[5.0454950947947355, 47.32776597758209]],
    [ "Enedis",[5.044466561244417, 47.3273694204049]],
    [ "Enedis",[5.048458922841914, 47.32742871168917]],
    [ "Enedis",[5.048327119122367, 47.32692788047742]],
    [ "Enedis",[5.046927030352538, 47.3269229892065]],
    [ "Enedis",[5.0472936078360195, 47.32619480894127]],
    [ "Enedis",[5.049962537699741, 47.325867969222394]],
    [ "Enedis",[5.050941652561785, 47.32629068771398]],
    [ "Enedis",[5.050031873407891, 47.324517226883685]],
    [ "Enedis",[5.0517624597541735, 47.32557303672057]],
    [ "Enedis",[5.052177143503002, 47.32666845888402]],
    [ "Enedis",[5.051720459681729, 47.32755008910158]],
    [ "Enedis",[5.050649122242227, 47.32704455452432]],
    [ "Enedis",[6.67368403888053, 47.058873525719335]],
    [ "RTE",[5.915903727128532, 43.125104595619796]],
    [ "Enedis",[2.5112575685668816, 49.87151453223975]],
    [ "RTE",[6.680930793684703, 43.45247945196877]],
    [ "Enedis",[2.5191113168381043, 48.61247829398409]],
    [ "Enedis",[2.7957650925695106, 49.13387797583274]],
    [ "Eco Quartier",[6.141811500000001, 45.94244810000001]],
    [ "Centre readaptation",[6.148638266510248, 45.947427298864525]],
    [ "Enedis",[1.9508608205399722, 47.91222885636734]],
    [ "Enedis",[-4.454816177944008, 48.462813747089065]],
    [ "Enedis",[1.3182214, 43.5580903]],
    [ "Enedis",[1.3174505000000003, 43.55916785]],
    [ "Enedis",[0.12053027020730622, 48.22867218871825]],
    [ "Enedis",[3.1501168499999994, 49.5129186]],
    [ "Enedis",[5.139350580681613, 48.19533474230068]],
    [ "Enedis",[6.22349358769074, 45.24659027253256]],
    [ "Enedis",[3.1771899, 50.6872628]],
    [ "Enedis",[1.9754957500000008, 47.91732135000001]],
    [ "Enedis",[1.988045650666105, 47.91376311686237]],
    [ "Bignon",[2.033573930349221, 48.680192446260534]],
    [ "Enedis",[3.1588480999999993, 43.154867800000005]],
    [ "Enedis",[4.987125849999998, 44.85560269999999]],
    [ "CM Trivaux 183",[2.2457392091962376, 48.78675802914308]],
    [ "Île Charlemagne",[1.9383707128549197, 47.89440007557137]],
    [ "Bouilen",[-3.669826032392856, 48.68564680103632]],
    [ "Enedis",[-1.5785868788663684, 48.843240621770704]],
    [ "Saint Hilaire",[6.186515122552402, 46.01866370876998]],
    [ "Enedis",[4.344797631604174, 49.76789494704601]],
    [ "Enedis",[4.340748616875593, 49.76659259973795]],
    [ "Enedis",[1.4483294500000043, 43.6027465]],
    [ "Enedis",[1.8630120177508553, 49.16368667742715]],
    [ "Enedis",[1.8604486499999993, 49.162631350000005]],
    [ "Enedis",[1.854046786057613, 49.15770387577704]],
    [ "Enedis",[0.8204193999999999, 49.4634852]],
    [ "Enedis",[3.2248395000000003, 49.51804645]],
    [ "Enedis",[2.3453069183660995, 48.74952855949731]],
    [ "Enedis",[7.002946148340134, 47.76325546684063]],
    [ "Enedis",[4.944584339481448, 44.867133829621274]],
    [ "Enedis",[-0.507408416626952, 47.47591380213576]],
    [ "Enedis",[-0.5120720820080993, 47.47453307653563]],
    [ "Bretonnières",[-0.5145944499999999, 47.47783055]],
    [ "Enedis",[1.6707809357275185, 46.091352249394326]],
    [ "ESSeyssel",[6.193440523163996, 46.01561272285667]],
    [ "Enedis",[-1.1937752435206639, 46.39560401840627]],
    [ "Enedis",[-3.6516122500000003, 48.688292749999995]],
    [ "Poste électrique de La Hague (P90)",[-1.873895176627957, 49.68067017102313]],
    [ "Enedis",[4.8729687, 45.73764895000001]],
    [ "Enedis",[0.3805561, 49.24561965]],
    [ "Enedis",[0.7793797513960101, 45.894830101376996]],
    [ "Enedis",[0.782983172315777, 45.89460153686193]],
    [ "Enedis",[0.8108396973597994, 45.89120959150316]],
    [ "Enedis",[0.855785350169175, 45.88856670074513]],
    [ "Enedis",[0.8507379999639127, 45.888334412252846]],
    [ "Enedis",[0.637020422112505, 46.00837578595861]],
    [ "Enedis",[-1.4981338000000002, 48.8369638]],
    [ "Garenne Bechevet",[2.125325648548755, 48.847148164826635]],
    [ "Enedis",[-3.649288195223316, 48.67164224683743]],
    [ "Enedis",[-3.644834731021763, 48.672213539920094]],
    [ "Enedis",[-3.6643029396082683, 48.678635558391875]],
    [ "Enedis",[-3.6693301105845952, 48.67977688463547]],
    [ "Enedis",[-3.652339788968319, 48.67913611625352]],
    [ "Enedis",[-3.6594601762030283, 48.682717167424954]],
    [ "Enedis",[-3.6456799762441214, 48.66493392238382]],
    [ "Enedis",[-1.5905152945649987, 48.83883213359763]],
    [ "Village",[5.881607450000001, 48.557812000000006]],
    [ "Enedis",[0.9301527532283913, 46.7719767593864]],
    [ "Lc Aqueduc 7",[2.1310375899437477, 48.84464498814191]],
    [ "Lc Étangs 47",[2.1285794296456686, 48.843288152553534]],
    [ "Enedis",[0.3961657000000016, 49.233861399999995]],
    [ "PV Villarclement",[6.387279503501687, 45.262838783228695]],
    [ "Enedis",[6.493516929269039, 46.386044577818495]],
    [ "Enedis",[4.9071897792954875, 44.83080815271349]],
    [ "Enedis",[4.838384903204602, 44.12973194839771]],
    [ "REThones",[6.304038056419824, 45.88453782323614]],
    [ "Enedis",[2.442262033871794, 48.69066335065625]],
    [ "Enedis",[0.7452191499999998, 48.7827014]],
    [ "Enedis",[-1.734258948419069, 47.98016206231346]],
    [ "Enedis",[-0.28983289999999934, 47.67414949999999]],
    [ "Enedis",[0.18848123006691486, 47.65399794568039]],
    [ "Enedis",[0.38007621829761956, 47.68729575294015]],
    [ "Enedis",[1.4434602663190563, 43.61382844949092]],
    [ "Enedis",[2.1490649314749546, 48.42800009586005]],
    [ "Enedis",[2.1605283291197845, 48.45259891646093]],
    [ "Enedis",[2.1696396386833867, 48.45121516570273]],
    [ "Enedis",[6.391033841302399, 46.2144631173386]],
    [ "Enedis",[-0.6189089660982758, 44.731086452570985]],
    [ "RTE",[0.7012013106059496, 45.19334240531476]],
    [ "Poste électrique de Braek",[2.2943854222105076, 51.03719466041763]],
    [ "Poste électrique de Ressons-sur-Matz",[2.745082780788246, 49.53509909862118]],
    [ "Sous-station RFF de Tivernon",[1.9224581526242341, 48.16163025227708]],
    [ "RTE",[2.437182105892386, 48.79933187049678]],
    [ "RTE",[2.539086566058075, 49.006579365393115]],
    [ "RTE",[2.372315035617037, 45.41601708226677]],
    [ "RTE",[2.41099026880151, 48.77992700397787]],
    [ "RTE",[5.722286540106153, 45.07505034358795]],
    [ "RTE",[7.263898320141153, 43.92352765299297]],
    [ "RTE",[6.680832360490145, 43.45208907048819]],
    [ "RTE",[2.2160748, 48.62307870000001]],
    [ "SNCF Réseau",[0.130016764911152, 45.637369351169]],
    [ "RTE",[5.888594445197044, 45.079423390707944]],
    [ "RTE",[-0.6926786529154622, 45.25865004498892]],
    [ "SNCF Réseau",[6.2882396191527, 45.443224977195165]],
    [ "RTE",[2.4086770999228815, 48.788167437647715]],
    [ "RTE",[2.1452995209168773, 48.72893163076319]],
    [ "RTE",[1.4212406743540433, 45.63537794492459]],
    [ "Poste électrique de La Magdeleine",[0.07538680589889656, 46.04860394901718]],
    [ "Poste électrique d'Issac",[-0.7782485191107579, 44.884395953531445]],
    [ "RTE",[1.8101965816487484, 48.78241029091425]],
    [ "RTE",[0.38037057526334833, 42.956075361438785]],
    [ "RTE",[5.704419429809336, 45.127362964084554]],
    [ "RTE",[5.01983106639428, 43.379085528081106]],
    [ "Synelva",[1.2290107327265434, 48.45019597126809]],
    [ "RTE",[0.9376476915456413, 42.84158595517464]],
    [ "Poste électrique de la Mame",[-0.010766091527957131, 45.18211285387817]],
    [ "RTE",[5.176696808844033, 43.49567737725732]],
    [ "RTE",[2.2666473375825107, 42.55307837904569]],
    [ "Enedis",[5.308772460825584, 45.145810168215405]],
    [ "SNCF Réseau",[2.1159216889535717, 48.409612422441974]],
    [ "RTE",[5.824930851514561, 43.771503040215705]],
    [ "RTE",[6.986276374529016, 43.640829250711754]],
    [ "Benauge",[-0.5470851707747579, 44.83771355276058]],
    [ "Poste électrique des Eaux Claires",[0.13114271475749395, 45.63698382290308]],
    [ "Deschamps",[-0.5467955143852539, 44.83788552796142]],
    [ "RTE",[0.30841366254696045, 46.342819139220055]],
    [ "RTE",[-0.06528850394357673, 42.96487059446977]],
    [ "Poste RFF de L'Age",[1.4222181499291824, 46.16337362928248]],
    [ "RTE",[-4.503996961007909, 48.38067138038134]],
    [ "Le Hérie-la-Viéville",[3.662200566766348, 49.80888463688889]],
    [ "RTE",[0.22303547031228785, 46.085382513156866]],
    [ "RTE",[-0.6598495970540549, 43.426339000335005]],
    [ "RTE",[2.4984571475267785, 43.94625928596588]],
    [ "RTE",[6.760498298802905, 43.67256807527195]],
    [ "Enedis",[5.69574602216609, 48.33788623913149]],
    [ "RTE",[-2.0841476571396824, 47.31260781391479]],
    [ "RTE",[2.440190359321841, 44.91695184665971]],
    [ "RTE",[2.2334582441562523, 48.89712566499056]],
    [ "RTE",[5.734652785793062, 45.092953846307424]],
    [ "Isola village",[7.053365300000001, 44.18708276666667]],
    [ "Poste électrique de Mees",[5.993636351136668, 43.99626177014388]],
    [ "RTE",[5.738360031393521, 45.08856047644689]],
    [ "RTE",[7.195653687782048, 43.85373048477545]],
    [ "VALDEROURE",[6.647704016474215, 43.788176204128995]],
    [ "Poste Source de Mérantais",[2.0628419839585854, 48.75066526239857]],
    [ "Poste électrique de Poissy",[2.051219934449344, 48.940836550609596]],
    [ "Poste électrique de Montgros",[3.7196957654324816, 44.79124222958812]],
    [ "RTE",[7.418666423242978, 43.74201915552869]],
    [ "RTE",[7.373760365438283, 43.791815005962555]],
    [ "RTE",[7.105064991918308, 43.711537527668405]],
    [ "Poste électrique de Salles-Moussac",[0.14367052495309918, 45.957443593924516]],
    [ "Poste électrique de Verdery",[-0.7980821883112978, 44.72118126306041]],
    [ "Arudy",[-0.4438181706639064, 43.09983318547779]],
    [ "RTE",[6.890255145355597, 43.64043468746284]],
    [ "SNCF Réseau",[2.443202037535692, 48.72371133513209]],
    [ "Enedis",[6.661616005319568, 47.367049250136596]],
    [ "Poste électrique de Ruelle",[0.22036835225519977, 45.680549489690854]],
    [ "RTE",[7.253828767599071, 43.729987389368524]],
    [ "RTE",[-0.39223437261553423, 42.864494774131686]],
    [ "RTE",[-1.6937198392490875, 47.20777602197732]],
    [ "RTE",[2.2023492220985013, 48.499927441127554]],
    [ "Enedis",[2.300430496084682, 48.5249210687292]],
    [ "Enedis",[1.7330600465324388, 47.854442546308725]],
    [ "Enedis",[4.97861315, 43.54514335000001]],
    [ "Enedis",[2.834969931671081, 48.36909504750125]],
    [ "Enedis",[2.8330306807808787, 48.36861919190504]],
    [ "Enedis",[0.9932945442651966, 48.88932571959728]],
    [ "Enedis",[1.3279421399811304, 47.602846223977735]],
    [ "Cret de la Grange",[6.107618349999999, 46.04328919999999]],
    [ "Enedis",[5.4998513088144545, 46.123322814196825]],
    [ "Enedis",[5.494284481948448, 46.119174115105665]],
    [ "Enedis",[5.4913014, 46.119108350000005]],
    [ "Enedis",[5.491330100000001, 46.11440185]],
    [ "Enedis",[5.494023985178158, 46.11576161869147]],
    [ "Enedis",[0.25136827769350134, 49.478753504139796]],
    [ "Enedis",[4.13920085, 43.73370100000001]],
    [ "Enedis",[0.2351148405398035, 49.54934746209609]],
    [ "Enedis",[0.23712459619852602, 49.54972640817027]],
    [ "Menoire",[1.7155623847347694, 44.95451429638638]],
    [ "Betaille Escabrerie",[1.7178339944498475, 44.95239809806781]],
    [ "Moulin Vert",[2.32086515, 48.830921749999995]],
    [ "Enedis",[2.319911354341779, 48.83162526352656]],
    [ "Enedis",[-3.584345090958476, 48.51207277863071]],
    [ "Gérédis",[-0.3931632895702076, 46.0948176819953]],
    [ "821135676",[-0.30145098151112587, 46.098349965292044]],
    [ "Gérédis",[-0.30395168753382573, 46.09921137341306]],
    [ "poste_1920",[2.464059866666667, 49.40071178884327]],
    [ "Enedis",[-1.776471494601205, 46.49613197860085]],
    [ "Enedis",[-1.771479193371181, 46.49636643057307]],
    [ "Enedis",[-1.8010645584208482, 46.49489901649164]],
    [ "Enedis",[-1.8053868017953323, 46.498422168462]],
    [ "Enedis",[0.87494095, 49.8890899]],
    [ "Enedis",[0.4133173181895858, 47.33592945137915]],
    [ "Enedis",[0.701472522735447, 47.418424747089595]],
    [ "Enedis",[0.7066432499999998, 47.42005185000001]],
    [ "Enedis",[0.7164184324683543, 47.41863867630831]],
    [ "Enedis",[0.7164939999999997, 47.4196626]],
    [ "Enedis",[0.7056685755714286, 47.418996096761894]],
    [ "Enedis",[2.2828684, 48.798377200000004]],
    [ "Enedis",[2.28537199797959, 48.79617354382709]],
    [ "Gabelle 016 012",[1.400942037302018, 49.24111905970049]],
    [ "Enedis",[3.1183428527625305, 50.617768171070466]],
    [ "Enedis",[3.1182763999999996, 50.61776545]],
    [ "Enedis",[6.680644699999999, 45.9040155]],
    [ "Enedis",[1.6117269755674242, 49.30752724722556]],
    [ "Enedis",[1.611571199999999, 49.3024853]],
    [ "Enedis",[1.6195832061608146, 49.305669928788376]],
    [ "IUT A",[3.1372504500000002, 50.6133779]],
    [ "Enedis",[-1.17756005, 47.1809553]],
    [ "Enedis",[-1.23843045, 47.212556750000005]],
    [ "Robinière",[-1.2416658846445874, 47.19299167599961]],
    [ "Enedis",[3.8707948920696986, 47.16247699698086]],
    [ "RTE",[3.493353078456672, 50.338171998109026]],
    [ "RTE",[-1.1828808287401846, 48.123512482878766]],
    [ "Enedis",[2.368281951277156, 48.91160794405658]],
    [ "RTE",[1.361416800736726, 43.61052899495538]],
    [ "RTE",[4.305953383705559, 45.533397504828834]],
    [ "Le Freney",[6.624847582255755, 45.1949246623138]],
    [ "Poste électrique Saint Vulbas Ouest",[5.258710282197238, 45.79463285200773]],
    [ "Poste électrique de Saint-Vulbas Est",[5.2602506979531505, 45.79738192257902]],
    [ "RTE",[5.926225810112576, 43.11694067712398]],
    [ "RTE",[1.4583692236552974, 48.457011065729574]],
    [ "Enedis",[-1.626490484571049, 47.23597437294864]],
    [ "RTE",[6.294746617893588, 45.46200524069163]],
    [ "RTE",[6.294765534511807, 45.46191093879293]],
    [ "RTE",[6.991749224334238, 43.721173733113936]],
    [ "Enedis",[3.2202436782974804, 50.64965928464852]],
    [ "Enedis",[3.21272367301056, 50.59595002405843]],
    [ "Enedis",[3.2128977961976206, 50.613345458157426]],
    [ "RTE",[-0.7968061586497011, 48.68908089057283]],
    [ "RTE",[0.48448108566137427, 49.49386539998189]],
    [ "EDF",[6.710850516696943, 45.88707315518642]],
    [ "RTE",[4.914172087345218, 43.461914217479624]],
    [ "RTE",[2.7812287910217663, 50.139205776508845]],
    [ "Enedis",[2.5396121136941088, 49.00752312366499]],
    [ "Poste Débonnaire",[6.184239386303985, 49.10120404510271]],
    [ "RTE",[5.659571720940471, 45.23638099614319]],
    [ "RTE",[2.1988225005566284, 48.850970347995556]],
    [ "RTE",[-0.2421850555439229, 43.18247708080047]],
    [ "RTE",[7.363672515300076, 48.09406618311677]],
    [ "RTE",[-0.06465550610356838, 42.963079183770915]],
    [ "Enedis",[2.201502612387442, 49.35863103729784]],
    [ "RTE",[1.5025123011782224, 43.635822657470996]],
    [ "Garies",[-0.6631223285506469, 44.81651888019101]],
    [ "Enedis",[6.5343296559023445, 45.76269576250354]],
    [ "RTE",[3.262755969766188, 45.555113192822596]],
    [ "Poste électrique Technocentre",[2.0819874335884863, 48.75701692886247]],
    [ "RTE",[-0.223308, 43.78016699999999]],
    [ "Le Transloy",[2.9035563994303177, 50.04587597417851]],
    [ "RTE",[-0.22438826035796877, 43.780125827681516]],
    [ "RTE",[2.2170175099120155, 42.52122163786401]],
    [ "RTE",[1.9561158778268535, 47.02475100919361]],
    [ "RTE",[2.40736892617389, 48.79522494834414]],
    [ "Poste source du Cubry",[3.900106645934712, 49.00589465732861]],
    [ "Sous-station SNCF d'Achiet",[2.7817684887498677, 50.13902370510395]],
    [ "RTE",[7.3348725904029255, 43.78276463305009]],
    [ "RTE",[3.071402863609003, 45.78310294519666]],
    [ "824179767",[5.739151305880243, 45.14574410806739]],
    [ "RTE",[5.8850039478539955, 45.26744052374658]],
    [ "RTE",[4.902226351782999, 45.88158776923891]],
    [ "Enedis",[2.0147013499999997, 48.5253031]],
    [ "Enedis",[0.6091320370591305, 47.35652134366715]],
    [ "Enedis",[0.6597161786471425, 47.402350318862375]],
    [ "Enedis",[4.155339999999999, 43.7534846]],
    [ "réséda",[6.2173942541027944, 49.22221282269211]],
    [ "réséda",[6.216038797280483, 49.220113473348725]],
    [ "Enedis",[5.058534000000001, 47.320401950000004]],
    [ "Enedis",[6.10631951066626, 49.31319503613691]],
    [ "Enedis",[6.09848791574897, 49.31924073699459]],
    [ "Enedis",[6.098225272800185, 49.3126403054373]],
    [ "Enedis",[-4.56673169548718, 48.584217606256416]],
    [ "Enedis",[-4.5650934, 48.5854489]],
    [ "Enedis",[-4.601913052055581, 48.574759565087234]],
    [ "Enedis",[-4.589725530729753, 48.58076149789711]],
    [ "P35 Prat Allan",[-4.6002641388413315, 48.58945317500216]],
    [ "Enedis",[-4.566022728861008, 48.59666712704059]],
    [ "Enedis",[-4.595732448283697, 48.5987643438482]],
    [ "Enedis",[-4.6007132, 48.5831731]],
    [ "Enedis",[-4.598228114427015, 48.591666230160904]],
    [ "Enedis",[-4.556001012028942, 48.58798026946329]],
    [ "Enedis",[-4.5987350099025734, 48.580770857033365]],
    [ "Enedis",[-4.593807616141871, 48.58560500554785]],
    [ "Enedis",[-4.57785453830122, 48.584986729479624]],
    [ "Enedis",[-4.59783105, 48.57615975]],
    [ "Enedis",[3.1565683499999992, 50.62456975]],
    [ "Sarvatan",[6.361991859631767, 45.43073223322572]],
    [ "FAGALDE",[-1.4449469263267252, 43.47208389775489]],
    [ "Enedis",[-1.4448142692105663, 43.471151356617355]],
    [ "Enedis",[2.4103776212195043, 48.68808732561507]],
    [ "Enedis",[-3.5859050009691535, 48.519710683582794]],
    [ "Enedis",[-3.582433690641823, 48.52331712033126]],
    [ "Enedis",[3.05443149203955, 50.65789022023804]],
    [ "Enedis",[-3.5937564500000003, 48.5054008]],
    [ "Enedis",[6.110882503288791, 49.313641081279194]],
    [ "Enedis",[3.688613088167386, 49.075053976294285]],
    [ "Enedis",[3.7078858485986075, 49.07793696712031]],
    [ "Enedis",[3.710124138248367, 49.081191875235355]],
    [ "Enedis",[3.705060156834195, 49.08119137627994]],
    [ "Enedis",[3.7084487122096723, 49.082619609083245]],
    [ "SICAE-Est",[5.9943165546041985, 47.94145835251647]],
    [ "Enedis",[4.125470149999999, 43.666400599999996]],
    [ "Enedis",[4.136798423903545, 43.65624175389093]],
    [ "Enedis",[4.14043825, 43.65665215]],
    [ "réséda",[6.241761543038863, 49.10817815142721]],
    [ "Enedis",[3.1954402, 47.0891029]],
    [ "Enedis",[6.820924108899608, 46.27800195516216]],
    [ "Enedis",[4.131378450000001, 43.663351500000005]],
    [ "Enedis",[4.118384067067758, 43.67194540633723]],
    [ "Enedis",[4.114457137843434, 43.677642126281796]],
    [ "Enedis",[4.116938204628027, 43.67812637963817]],
    [ "Enedis",[4.1204639351876065, 43.677086269651596]],
    [ "Enedis",[4.13641315, 43.682371399999994]],
    [ "Enedis",[4.1300892000000005, 43.6943834]],
    [ "Enedis",[3.983075128910267, 43.6521201074437]],
    [ "Enedis",[4.136045900000001, 43.67405285]],
    [ "Enedis",[4.138958226354862, 43.72092902787942]],
    [ "Enedis",[0.6218489396401661, 47.411998882056146]],
    [ "Enedis",[2.06315978852293, 48.80852122566783]],
    [ "Enedis",[6.7496646, 48.4433799]],
    [ "Enedis",[6.7488515, 48.4421916]],
    [ "Enedis",[-1.8898026499999996, 47.35389930000001]],
    [ "Enedis",[2.009807155542211, 47.94899750718564]],
    [ "Enedis",[1.4590364144693937, 49.72499620276652]],
    [ "Enedis",[5.20114941928519, 47.490963646421704]],
    [ "Enedis",[4.564578842112034, 47.14970258188906]],
    [ "Enedis",[4.556622439192789, 47.125054320994735]],
    [ "Enedis",[4.528867631735558, 47.1215591427025]],
    [ "Enedis",[6.29721762514604, 45.48880188719099]],
    [ "Enedis",[4.588179059384199, 47.12040070100871]],
    [ "Enedis",[4.588458800000001, 47.100460899999995]],
    [ "Enedis",[-0.38350089421124994, 49.231513616208275]],
    [ "Enedis",[1.7553272999999996, 47.8242495]],
    [ "Enedis",[1.7568696500000005, 47.81811524999999]],
    [ "Enedis",[4.141950002800578, 43.67788038338776]],
    [ "Enedis",[4.146643200000001, 43.68940955000001]],
    [ "Enedis",[0.10457484905416337, 48.501677216913606]],
    [ "Enedis",[4.1391025, 43.67322525]],
    [ "Enedis",[4.331716556960968, 47.20650126032633]],
    [ "Enedis",[4.3519976518185155, 47.20267324987649]],
    [ "Enedis",[1.7599278734052115, 47.81901603261455]],
    [ "Enedis",[4.227801926794842, 47.2787078057601]],
    [ "Enedis",[-1.7509324762927165, 46.49375666048665]],
    [ "Enedis",[2.6587902701404347, 48.573931190837314]],
    [ "Enedis",[4.23984515, 47.2914915]],
    [ "Enedis",[4.251441880705916, 47.28577403946355]],
    [ "Enedis",[4.30284385, 47.31715365000001]],
    [ "Enedis",[5.454456279691626, 44.61503710374818]],
    [ "Enedis",[1.7953438, 47.85016995]],
    [ "Enedis",[3.681602661731759, 49.30671340028257]],
    [ "Enedis",[4.521777518240338, 49.926459155840384]],
    [ "Enedis",[4.5183657, 49.92488075000001]],
    [ "Enedis",[4.5257700914772165, 49.92932892901294]],
    [ "Enedis",[4.524758800000002, 49.92796814999999]],
    [ "Enedis",[4.521117921034911, 49.9300169163376]],
    [ "Enedis",[4.625780778032227, 49.93047021534613]],
    [ "Enedis",[4.744515154137805, 45.649689096976466]],
    [ "wikidata=Q3587594",[2.8724499758191224, 43.31495101047799]],
    [ "réséda",[6.159271499999998, 49.03709185]],
    [ "Enedis",[5.1676983103989, 45.66113440447497]],
    [ "Route des Etraits",[5.133408416949011, 45.69256760068268]],
    [ "Enedis",[1.884104854178424, 46.25754648226612]],
    [ "La Pallaz",[6.158780104077847, 45.984276068821]],
    [ "Les Couvettes",[6.1548772, 45.98190309999999]],
    [ "Enedis",[2.0095823420341703, 47.956584094717755]],
    [ "Enedis",[2.0130429362030196, 47.952288157435234]],
    [ "Enedis",[0.4685560827385117, 47.98107430160024]],
    [ "Enedis",[1.8964876499999979, 47.95763324999999]],
    [ "Enedis",[2.704542743887394, 50.649729125009934]],
    [ "Enedis",[2.7217538634591922, 50.64422281447214]],
    [ "Enedis",[2.712841332413235, 50.64410650280735]],
    [ "Enedis",[2.7173739453005923, 50.650500938389925]],
    [ "Enedis",[2.718639237190083, 50.6431999133291]],
    [ "Enedis",[2.7247494861454276, 50.64309455516771]],
    [ "Enedis",[2.7177523833099455, 50.64428323152248]],
    [ "Enedis",[3.922113659170531, 43.583512379160226]],
    [ "Enedis",[4.073329778239059, 43.65560694681759]],
    [ "Enedis",[4.0807325500000005, 43.65518934999999]],
    [ "Enedis",[1.7690333897294932, 49.05755772836165]],
    [ "Enedis",[3.127881845400889, 50.63595839847979]],
    [ "Enedis",[1.897762315544597, 47.9139996299832]],
    [ "Enedis",[5.0701837410438415, 47.32785702956543]],
    [ "Enedis",[5.071871997590086, 47.324078801789064]],
    [ "Enedis",[5.075031920830827, 47.32438396211613]],
    [ "Enedis",[5.060003173841779, 47.321529486286515]],
    [ "Enedis",[5.064585813588792, 47.320750876387486]],
    [ "Enedis",[5.065549074652673, 47.318318240239876]],
    [ "Enedis",[5.056508784029485, 47.32142020606061]],
    [ "Enedis",[5.0610539563699835, 47.3166446464382]],
    [ "Enedis",[5.058611683033361, 47.31476806434539]],
    [ "Enedis",[5.056912395745821, 47.314529071847176]],
    [ "Enedis",[5.054911008052786, 47.31353747125282]],
    [ "Enedis",[5.054349447940543, 47.311808385846554]],
    [ "Enedis",[5.053111753992878, 47.311360730870796]],
    [ "Enedis",[5.057913949876259, 47.309209056139885]],
    [ "Enedis",[5.058478520190874, 47.308882679928416]],
    [ "Enedis",[5.057865146888179, 47.30826615927987]],
    [ "Enedis",[5.056146629172965, 47.308619662742075]],
    [ "Enedis",[5.062133209449523, 47.30485393732435]],
    [ "Enedis",[5.060544636234384, 47.30197146685948]],
    [ "Enedis",[5.0606821018329, 47.30168946776032]],
    [ "Enedis",[5.0586504, 47.301805]],
    [ "Enedis",[3.1394684574529435, 50.638101982665326]],
    [ "Enedis",[2.2782747423636924, 48.75943818913732]],
    [ "Village",[4.494244199999999, 45.025419299999996]],
    [ "Enedis",[1.1587260965649677, 46.13416809169642]],
    [ "Enedis",[1.160245409419786, 46.14534910638204]],
    [ "Enedis",[1.1732929023790086, 46.147885650170444]],
    [ "Enedis",[1.2159844546414225, 46.14788179972888]],
    [ "Enedis",[2.9401309298622365, 42.73485408653528]],
    [ "Enedis",[4.061114338307599, 43.66625306576898]],
    [ "Enedis",[0.47608140465773446, 47.10542266676662]],
    [ "Enedis",[4.0628935152524965, 43.66878573738733]],
    [ "Enedis",[-1.2179169313781009, 45.88575942438794]],
    [ "Enedis",[0.5386768675230698, 47.04345848444253]],
    [ "Enedis",[6.540040308999421, 45.75736956085962]],
    [ "réséda",[6.200541063184453, 49.115024656454835]],
    [ "ZAC Etoile 2",[6.237528721953621, 46.1996037758374]],
    [ "Poste électrique de Biguglia",[9.421300662946514, 42.6023968314661]],
    [ "Enedis",[4.561443800877313, 45.1888926940391]],
    [ "Enedis",[3.7702862195170543, 43.58553129948083]],
    [ "Enedis",[0.6859349863665961, 47.340528442537995]],
    [ "Enedis",[4.4108445323681655, 47.55935585265046]],
    [ "GEBonneville",[6.44389775, 46.06042485]],
    [ "réséda",[6.158274451354234, 49.04388166270453]],
    [ "Enedis",[1.1578786921101456, 49.214557926084595]],
    [ "Enedis",[-0.1009419531490757, 45.74153685094301]],
    [ "Centre Culturel et Sportif",[6.714735061009145, 46.07990342031809]],
    [ "Enedis",[7.2814668000000005, 43.69582955]],
    [ "RTE",[2.6594752368188015, 48.6382291830856]],
    [ "RTE",[2.6592617157109464, 48.63831166662335]],
    [ "Enedis",[4.93014746699567, 44.88590650206061]],
    [ "RTE",[7.164045093776648, 43.91985287598309]],
    [ "Enedis",[6.64516975, 45.89243090000001]],
    [ "Enedis",[1.240265666896985, 47.20856981995394]],
    [ "Enedis",[4.973306296352621, 44.85212990337134]],
    [ "Enedis",[3.1911051346575725, 43.18760685423529]],
    [ "Enedis",[3.1949151651708942, 43.18813802882189]],
    [ "Enedis",[5.5388399497645615, 46.74977085511377]],
    [ "840358198",[5.997211599999999, 46.8485752]],
    [ "Joindet",[6.2016159175998276, 46.12606229781189]],
    [ "Chef-lieu",[6.206064926900025, 46.12217919044194]],
    [ "Enedis",[3.0531369718043555, 50.619200496314726]],
    [ "Sensorex",[6.2147579, 46.17999535]],
    [ "Foyer des Italiens",[6.660990699999998, 45.19462669999999]],
    [ "Enedis",[3.552804019300425, 47.87672905875466]],
    [ "SRD",[0.45055465, 46.92527055]],
    [ "ZA Neussargues",[2.9789339, 45.13593434999999]],
    [ "Rue du talus 15",[2.2059247499999985, 48.8745955]],
    [ "NR fusillés 122 cp",[2.217658758268628, 48.87894842461718]],
    [ "Enedis",[0.21597775, 47.06637800000001]],
    [ "Enedis",[0.3147087631576509, 47.020568917860246]],
    [ "Enedis",[0.31523783215586537, 47.018471375462354]],
    [ "RTE",[1.0862237755326596, 43.029425889426825]],
    [ "Pre Cochat",[6.158516534874328, 46.004807722014284]],
    [ "Enedis",[3.0757238544453207, 50.66822737058463]],
    [ "Enedis",[-3.576875767853128, 48.52173450159632]],
    [ "Enedis",[-3.5948219999999997, 48.515012399999996]],
    [ "Enedis",[-3.6423739500000005, 48.526863049999996]],
    [ "Enedis",[-3.700457999999999, 48.565483199999996]],
    [ "Enedis",[4.93744505, 44.96576315]],
    [ "ZA Vieran",[6.129888149999999, 45.98045095]],
    [ "Camping de la Cote",[-1.2306538, 45.69688705]],
    [ "Enedis",[2.0474566786975457, 48.934813600823]],
    [ "Enedis",[2.2345764840709794, 48.68647045150233]],
    [ "Bouclage depart Saint-Blaise",[6.0990626500000005, 46.04037645]],
    [ "Charbonnet",[6.1057576000000005, 46.037359]],
    [ "Enedis",[3.148986668283543, 50.628174221285]],
    [ "Tigre",[3.1586293033098096, 50.624241449496886]],
    [ "Enedis",[3.147565036486991, 50.61792864408071]],
    [ "Enedis",[2.205825355908558, 43.915394488997464]],
    [ "Enedis",[2.0569744572732973, 48.934688550916476]],
    [ "Enedis",[1.752755727079769, 47.20426305205266]],
    [ "Enedis",[1.7571515772406539, 47.204956241053196]],
    [ "ACMD Fief Traversi",[-1.1391951595239618, 45.733067571638905]],
    [ "Sapin Vert",[-1.1382233, 45.736853350000004]],
    [ "Enedis",[-3.4445507, 48.810458749999995]],
    [ "Enedis",[6.456722970046438, 46.07496297542357]],
    [ "Enedis",[1.9604049214859987, 47.91593978584036]],
    [ "Enedis",[1.9741880500000002, 47.9191754]],
    [ "Enedis",[1.9193788499999997, 47.943203100000005]],
    [ "Enedis",[1.8697021499999997, 47.917513899999996]],
    [ "Enedis",[1.8649216357223952, 47.91317678236677]],
    [ "Enedis",[1.8565995, 47.91011385000001]],
    [ "Chavouents",[6.689781499999999, 45.9171764]],
    [ "Enedis",[1.9348687948314707, 47.88675623070122]],
    [ "Enedis",[1.9166575835559974, 47.879857245863136]],
    [ "Enedis",[1.8868290000000005, 47.884117350000004]],
    [ "Enedis",[1.86391235, 47.88333624999999]],
    [ "Enedis",[1.865406528944685, 47.923153253096835]],
    [ "Enedis",[6.197196563816215, 48.60677968316801]],
    [ "Enedis",[1.8537246181633371, 47.921978796391]],
    [ "Enedis",[1.8509895, 47.9045615]],
    [ "Les Pelerets",[6.173067749999998, 46.0039123]],
    [ "Enedis",[6.443905647193876, 45.59367034923312]],
    [ "Enedis",[3.1777874105053594, 50.67398158922564]],
    [ "Enedis",[0.18017376729865875, 47.13946680158875]],
    [ "Le Clos",[5.27433475, 45.58105805]],
    [ "Enedis",[4.93608961926342, 44.80100340209468]],
    [ "Enedis",[4.974710087008111, 44.840836224248804]],
    [ "Enedis",[3.182205601860025, 50.68552176167202]],
    [ "Enedis",[3.1839110608891814, 50.69697136916487]],
    [ "Enedis",[-0.4825823, 47.457598649999994]],
    [ "Enedis",[-0.48249855000000025, 47.457593149999994]],
    [ "Cobra",[2.376575701031452, 48.81643208302255]],
    [ "Enedis",[1.1362916844230082, 45.795260187439766]],
    [ "Enedis",[6.243389774035026, 48.74773113309792]],
    [ "Enedis",[6.2425692373634325, 48.74185172438709]],
    [ "Enedis",[3.146855679806928, 50.65664739910083]],
    [ "Enedis",[6.05707111346602, 48.839652706465785]],
    [ "Poste électrique de Pralong",[6.510477092506574, 44.580407094511436]],
    [ "Enedis",[4.7677876, 45.4555747]],
    [ "Enedis",[7.2597608739239075, 43.709058908415564]],
    [ "Enedis",[4.976971900000001, 44.88578685]],
    [ "Enedis",[4.9797569500000005, 44.8346563]],
    [ "Enedis",[4.284666120470859, 45.391070773765435]],
    [ "Enedis",[7.219241264583548, 43.68770068489461]],
    [ "Enedis",[7.217831006983353, 43.68825841805724]],
    [ "Enedis",[-4.273133040481038, 48.45992265674854]],
    [ "Enedis",[-4.251157879725676, 48.45377979766313]],
    [ "519034",[7.813103700000001, 48.67288950275481]],
    [ "Enedis",[1.9029215387224705, 47.9207366736097]],
    [ "Enedis",[-0.4801261999999984, 47.45380580000001]],
    [ "Enedis",[4.8419231943478005, 45.73947346454878]],
    [ "Enedis",[6.089145537343979, 48.61462890557925]],
    [ "Enedis",[4.971617650000001, 44.82596035000001]],
    [ "Enedis",[4.977147750000001, 44.82749865]],
    [ "REThones",[6.397342799999999, 45.94933555]],
    [ "Enedis",[-1.1219059444840966, 46.83027866826942]],
    [ "Enedis",[5.943802744369442, 47.21631447026732]],
    [ "Enedis",[-2.6362362895601756, 47.575733338373325]],
    [ "Enedis",[2.7351405589181574, 48.03281873297222]],
    [ "CESML",[3.832722348903544, 43.65162328373584]],
    [ "Enedis",[1.022142434019432, 47.08948029427256]],
    [ "Enedis",[3.1703711950731237, 47.00048874630113]],
    [ "Enedis",[4.902322746796306, 44.91148571467412]],
    [ "Enedis",[2.3803551338640125, 49.386997152887155]],
    [ "Pompage",[5.775653967900271, 48.674206375941544]],
    [ "Enedis",[7.2700517, 43.70649389999999]],
    [ "Enedis",[3.0262638500000003, 42.5713261]],
    [ "Enedis",[0.9580879731828983, 45.00335355558576]],
    [ "519045",[7.816519195853101, 48.6642129437533]],
    [ "Enedis",[1.3029868500000001, 43.749630149999994]],
    [ "Enedis",[4.85974775, 44.9215372]],
    [ "Enedis",[4.859717750000001, 44.923559700000006]],
    [ "Enedis",[3.1382299789166717, 50.639633297862176]],
    [ "Enedis",[3.143198882386851, 50.640470499112155]],
    [ "Sétives",[5.255977236304738, 45.5919934318279]],
    [ "Maladière",[5.258013981234019, 45.59172945199103]],
    [ "Enedis",[5.682142089782497, 44.699705799275776]],
    [ "857473739",[0.08634303433170848, 48.40632929693092]],
    [ "Mairie",[-0.3768363183240641, 49.29584723378931]],
    [ "Enedis",[4.9824161500000015, 44.9473486]],
    [ "Enedis",[4.9690849, 44.96300805]],
    [ "Enedis",[3.1357167351189537, 50.64348887835172]],
    [ "Enedis",[3.553665830666999, 43.69874979584932]],
    [ "Enedis",[3.5105998519616266, 48.46086639799542]],
    [ "Evesa",[2.3157361269397345, 48.832520794078704]],
    [ "Evesa",[2.298952750000001, 48.83363020000001]],
    [ "Enedis",[2.9223748499999993, 42.6859928]],
    [ "GT Chaperon Vert",[2.3338351741159653, 48.81454570915632]],
    [ "Enedis",[1.8914425251512703, 47.95452467900774]],
    [ "Bruyères",[5.0316089, 45.62893145]],
    [ "SRD",[0.051498449999999994, 47.1231403]],
    [ "SNCF Réseau",[-1.1748537999999997, 48.0453835]],
    [ "Enedis",[5.5933005312119874, 43.20780932651249]],
    [ "Enedis",[5.5962244000000005, 43.21188825]],
    [ "Enedis",[5.597316231922104, 43.21122619424652]],
    [ "Enedis",[6.6726785, 46.162929049999995]],
    [ "Enedis",[6.674797641900141, 46.16455438216461]],
    [ "La Mernaz",[6.71018554552643, 46.17568136943045]],
    [ "Enedis",[-0.5042597744520518, 43.33494744662092]],
    [ "Enedis",[6.103045766197113, 45.93747334540092]],
    [ "Vieranne",[6.107641933336766, 45.93861575058191]],
    [ "Enedis",[0.8354506382107614, 45.91409608619418]],
    [ "Enedis",[0.60848945, 47.31664195]],
    [ "Enedis",[5.600761999999999, 43.21285925]],
    [ "Enedis",[5.6027776, 43.2111681]],
    [ "SNCF Réseau",[-1.5880561499999983, 48.109448949999994]],
    [ "SNCF Réseau",[-1.46956395, 48.06370615]],
    [ "Enedis",[3.101494466054576, 50.64899663806035]],
    [ "Enedis",[6.44212706755482, 46.30237180216848]],
    [ "Enedis",[6.3121262, 46.22222559999999]],
    [ "Bousson",[5.091312924653707, 45.58938730374593]],
    [ "Enedis",[6.4947639, 46.380470750000015]],
    [ "Enedis",[-1.869982349176816, 49.531839660701394]],
    [ "Enedis",[-4.714242386077605, 48.52494056957066]],
    [ "Enedis",[-1.5411825421253427, 48.854208846599064]],
    [ "Enedis",[-1.5603322484465048, 48.85483239736182]],
    [ "Enedis",[-1.5437102337029447, 48.863967657094975]],
    [ "Enedis",[-1.526340548617489, 48.8627444529408]],
    [ "Enedis",[-1.4938331459246337, 48.8632307838249]],
    [ "Enedis",[3.904440276099788, 43.862593496692284]],
    [ "Enedis",[3.8945831000000006, 43.864985950000005]],
    [ "Enedis",[3.905495197046397, 43.85435977062247]],
    [ "Enedis",[3.8843279344592365, 43.869312150560084]],
    [ "Enedis",[-2.748179541790255, 48.49826441526217]],
    [ "Enedis",[0.7654902316825486, 46.734626589223865]],
    [ "Enedis",[3.124543338820295, 50.64796484093226]],
    [ "Enedis",[1.9141988075033127, 47.876014719960494]],
    [ "Enedis",[-4.267293021266373, 47.79970933496494]],
    [ "Enedis",[-4.259343663573959, 47.80658271845179]],
    [ "Poste électrique d’Aire",[2.396337987967142, 50.64440850151147]],
    [ "Enedis",[5.146845682105636, 45.62956692125464]],
    [ "Cigogne",[5.889243349999999, 48.584086600000006]],
    [ "Enedis",[3.1218275402163305, 50.66604002157671]],
    [ "Enedis",[3.121634577431176, 50.65813540993777]],
    [ "Enedis",[3.130493703532849, 50.6628335859724]],
    [ "Enedis",[4.856205114733462, 43.86842054527938]],
    [ "réséda",[6.104964965837069, 49.07898184913321]],
    [ "Enedis",[-4.086144946427005, 48.25477391665935]],
    [ "Enedis",[0.9210626548485414, 45.937883674885455]],
    [ "Enedis",[0.8925654391302607, 45.91612294444244]],
    [ "Enedis",[5.690253073416117, 46.84399078993115]],
    [ "Enedis",[0.9207361357967561, 45.98690951814998]],
    [ "Enedis",[0.7832386999999998, 46.044935300000006]],
    [ "Enedis",[3.137116717400239, 50.679696365990324]],
    [ "Enedis",[1.1646508787813181, 49.47409323365898]],
    [ "Seguet",[-0.7523513702620945, 45.07357389168558]],
    [ "Bourdieu",[-0.7567739, 45.07229555]],
    [ "Enedis",[0.7704362971379424, 46.07906624791648]],
    [ "Enedis",[0.7646535140198043, 46.07937600259677]],
    [ "Enedis",[0.7397492028042613, 45.98647100320511]],
    [ "Enedis",[0.8162208157606731, 46.03417610629292]],
    [ "Enedis",[0.6932319583680044, 46.0556224437304]],
    [ "Enedis",[0.565472109598925, 46.68551314017629]],
    [ "Enedis",[5.657684950000001, 47.14887615]],
    [ "Enedis",[6.710030674844998, 48.08379285534592]],
    [ "Mahungu",[2.2061196183047977, 48.64158953233596]],
    [ "Enedis",[0.6568880966568179, 45.96846639669438]],
    [ "Enedis",[3.135230449999999, 50.668602549999996]],
    [ "Enedis",[0.6722283404009258, 45.947694472086745]],
    [ "Enedis",[0.7485341945653806, 45.900826269954365]],
    [ "Enedis",[0.7809374589439784, 46.05910300703334]],
    [ "Enedis",[3.133942070758285, 50.64349937044726]],
    [ "Enedis",[0.2705443000008486, 48.01882360032786]],
    [ "DP Mojito",[2.4163090639716507, 48.87745318335636]],
    [ "Enedis",[1.5379435897253417, 49.6310450814782]],
    [ "Enedis",[6.153306381285433, 45.92330508489387]],
    [ "Enedis",[6.158815927928343, 45.92241750377375]],
    [ "Enedis",[6.17434825, 45.9196208]],
    [ "Enedis",[6.1792054499999995, 45.919760499999995]],
    [ "Enedis",[6.1644020158195785, 45.930486203255754]],
    [ "Enedis",[6.1688908, 45.93274219999999]],
    [ "Enedis",[6.414417884899773, 48.56573802102084]],
    [ "Enedis",[6.756418350000001, 46.4023693]],
    [ "Enedis",[0.87599314295327, 49.78732252473604]],
    [ "Enedis",[4.862303619443308, 43.87836070363402]],
    [ "Enedis",[4.864167282292474, 43.87202272797534]],
    [ "Enedis",[4.84308425, 43.88566695]],
    [ "Vert Vallon",[6.446777737364535, 46.35264406617466]],
    [ "Enedis",[6.436010262116033, 46.35096638265483]],
    [ "Enedis",[6.435962043897103, 46.350943610833504]],
    [ "Enedis",[0.959781686723355, 46.00899330284249]],
    [ "Enedis",[1.0079387236797914, 46.03178976950519]],
    [ "Enedis",[0.7165672799012768, 46.030188595148154]],
    [ "Enedis",[5.974564133994024, 45.03074532873149]],
    [ "Enedis",[1.8451002625072799, 49.610206868043186]],
    [ "Enedis",[1.0554875409042161, 46.05603194626614]],
    [ "Enedis",[1.0506400612931668, 46.04949877677708]],
    [ "Enedis",[1.0588574315720505, 46.081802807486376]],
    [ "Enedis",[2.938446418366519, 42.6915138913462]],
    [ "Enedis",[4.020430466856805, 43.66065550475347]],
    [ "Enedis",[6.455042241493046, 46.36648311738948]],
    [ "Garenne",[0.18347922507286, 47.93754316093807]],
    [ "Enedis",[2.932682047295767, 42.68660050475527]],
    [ "cordelier",[4.670444382305238, 43.80651068375999]],
    [ "Loranthy",[6.4243174418028515, 46.35564439657172]],
    [ "Enedis",[0.56502785, 45.88350640000001]],
    [ "Enedis",[0.5393304, 45.8648598]],
    [ "Fresney",[6.4287805584606375, 46.358234226163304]],
    [ "Enedis",[6.410831533026884, 46.34619789862097]],
    [ "Enedis",[1.231744871494658, 46.13292179641076]],
    [ "Enedis",[1.163635378171318, 46.102883843553414]],
    [ "Enedis",[1.1142912794605195, 46.08329044441506]],
    [ "Enedis",[3.6385441941048287, 47.729549799988185]],
    [ "Enedis",[2.948359524041118, 42.67533175064646]],
    [ "Enedis",[2.95141515, 42.676205949999996]],
    [ "Poissonnière",[2.93195457506762, 42.690103128503544]],
    [ "Enedis",[5.476569416384299, 43.60301308039653]],
    [ "Enedis",[5.473850399999999, 43.58376139999999]],
    [ "Enedis",[3.6455174554911465, 47.80176292825492]],
    [ "Enedis",[-2.75806815, 48.5046447]],
    [ "EDF",[6.682176849040669, 45.44255191499229]],
    [ "Enedis",[1.1906899500000006, 46.03221409999999]],
    [ "Enedis",[1.1473436577786085, 45.79946416246327]],
    [ "Enedis",[3.12444541871279, 50.67206339667761]],
    [ "Enedis",[3.1255340590623737, 50.67165592648853]],
    [ "Enedis",[6.3727341, 46.33262729999999]],
    [ "Enedis",[6.3763913500000005, 46.331247000000005]],
    [ "Enedis",[6.357877943223966, 46.33580243128863]],
    [ "Enedis",[6.358120700000001, 46.33107545000001]],
    [ "Enedis",[-0.2561822, 44.53389175]],
    [ "Enedis",[6.350424689155145, 46.3538099805032]],
    [ "Enedis",[3.1354862467608373, 50.67840448226151]],
    [ "Enedis",[1.6496929519802594, 50.70147250898361]],
    [ "Enedis",[1.6836912564788995, 50.67248426179587]],
    [ "Poste électrique TAC Sud",[55.450578717886614, -21.3178445800497]],
    [ "Enedis",[-2.750818851993264, 48.49848037832587]],
    [ "Enedis",[6.297540287505419, 46.35808184734427]],
    [ "Enedis",[0.09926950000000001, 49.52816280000001]],
    [ "Enedis",[2.286670856553695, 48.75940259167007]],
    [ "Enedis",[3.507349428864813, 43.64355187932261]],
    [ "Enedis",[6.270738032164421, 46.32850588133706]],
    [ "Enedis",[6.26994265, 46.3280997]],
    [ "Enedis",[2.95244185, 50.73793375]],
    [ "Enedis",[6.299077819859349, 46.30436844009974]],
    [ "Enedis",[6.2940168000000005, 46.303390199999996]],
    [ "Enedis",[6.244432400000001, 46.28174175000001]],
    [ "Enedis",[-0.344777106060873, 47.46963624574815]],
    [ "Enedis",[3.139916083721316, 50.68759449949341]],
    [ "Enedis",[-0.2771156683052295, 49.07851894724424]],
    [ "Enedis",[-1.8898951499999992, 46.9374709]],
    [ "Enedis",[6.328155962485401, 46.34662872763668]],
    [ "Enedis",[4.375268481800859, 44.890415733628856]],
    [ "Enedis",[2.9349550205696255, 42.678361077679924]],
    [ "Enedis",[4.366960873137681, 44.883182287756135]],
    [ "Enedis",[4.367874634247793, 44.881782705041886]],
    [ "879016651",[4.768195219495874, 45.78455941015743]],
    [ "Enedis",[4.3630711147570995, 44.880885923136006]],
    [ "Enedis",[2.9991508, 50.76079279999999]],
    [ "Enedis",[6.303185308003109, 46.30726035930182]],
    [ "Enedis",[-2.7388019978440554, 48.50870390479945]],
    [ "Enedis",[-1.0120093442410212, 45.66686337016472]],
    [ "Engie",[3.5907071110566147, 43.92779134514434]],
    [ "Enedis",[6.941854718194891, 49.034377251760894]],
    [ "Enedis",[1.8321260244562085, 48.6503817912273]],
    [ "Enedis",[2.3624332, 50.9928237]],
    [ "Enedis",[2.562000362507039, 46.42932965487981]],
    [ "Enedis",[6.259657288760408, 46.27773332103197]],
    [ "Enedis",[6.2588963999999985, 46.27454335]],
    [ "Enedis",[6.25274802961587, 46.273405790755255]],
    [ "Enedis",[6.253348131075052, 46.267089153227744]],
    [ "Enedis",[4.125722047116154, 43.676709967092734]],
    [ "Enedis",[6.25979085, 46.260910749999994]],
    [ "Enedis",[6.267287149954132, 46.26105673331676]],
    [ "Enedis",[6.26826735, 46.25734509999999]],
    [ "Enedis",[-1.9712012, 48.1393418]],
    [ "Enedis",[-1.9347008500000002, 48.17546699999999]],
    [ "Enedis",[6.294026, 46.2211632]],
    [ "Enedis",[6.286446099999999, 46.21854449999999]],
    [ "Enedis",[2.8775122027984374, 50.45967305487764]],
    [ "Enedis",[-0.8448251000000007, 46.778812800000004]],
    [ "Enedis",[6.2698951, 46.27215085]],
    [ "Enedis",[6.2686376225432925, 46.26851475246462]],
    [ "Enedis",[6.2615422, 46.2675942]],
    [ "Enedis",[6.257447966446999, 46.269509450301044]],
    [ "Enedis",[6.292883971081085, 46.2724566838306]],
    [ "Enedis",[6.257045800528844, 46.26583593352359]],
    [ "Sous-station Perelle",[5.575968341193375, 45.32505826036991]],
    [ "Enedis",[3.8175714999999997, 43.57925575]],
    [ "Enedis",[3.338010566900175, 50.331369430531225]],
    [ "Enedis",[2.919252100000001, 42.6927663]],
    [ "Enedis",[2.9384686024634985, 42.68956113893924]],
    [ "Collège",[5.171594799999999, 45.7543858]],
    [ "Enedis",[5.176067799999999, 45.759299600000006]],
    [ "Enedis",[6.266491416672164, 46.18834319936225]],
    [ "Enedis",[0.98894725, 47.35508215]],
    [ "Enedis",[6.8355270500000005, 47.94230335]],
    [ "Enedis",[2.9490988169614925, 50.41137300829605]],
    [ "RTE",[3.1006486668376856, 45.782109895033045]],
    [ "Enedis",[-0.6027418, 44.8071246]],
    [ "Enedis",[-0.6026740417742255, 44.80711434580273]],
    [ "réséda",[6.1440714, 49.14705615000001]],
    [ "Enedis",[-2.0658118349053893, 47.824352702756485]],
    [ "Enedis",[-2.1319895567101605, 47.81963947537933]],
    [ "Enedis",[1.038969397754359, 46.289699404575906]],
    [ "Enedis",[3.1268524860365727, 50.68547188407178]],
    [ "Enedis",[3.1248921, 50.686474700000005]],
    [ "Enedis",[3.1308708713964246, 50.68465420190789]],
    [ "Enedis",[-2.3638319661357925, 46.727840192873515]],
    [ "Enedis",[-3.7836639065271402, 48.680916429182304]],
    [ "Enedis",[1.2937028307701364, 47.93736388105842]],
    [ "Enedis",[6.367024858700045, 48.19400700901218]],
    [ "Enedis",[4.968952094221271, 45.82779802063833]],
    [ "Enedis",[1.1005126401554934, 44.739749745896994]],
    [ "Pré Falquet",[6.1059501, 45.942461650000006]],
    [ "Enedis",[-0.4885954012674067, 45.32809174867596]],
    [ "réséda",[6.096965549999999, 49.07927709999999]],
    [ "Enedis",[2.174046075451902, 43.5551495048442]],
    [ "Enedis",[2.1777005545394847, 43.55556933114978]],
    [ "Enedis",[2.1788520499999997, 43.55282065]],
    [ "Enedis",[2.18050365, 43.5603716]],
    [ "Enedis",[2.16925265, 43.5583187]],
    [ "réséda",[6.138648222120757, 49.06551241111716]],
    [ "Enedis",[0.8427445972985196, 45.876042954363]],
    [ "Enedis",[0.8415880075059781, 45.87279694057704]],
    [ "SRD",[0.50271605, 46.4901308]],
    [ "Enedis",[6.462096303907286, 43.535335449776646]],
    [ "Enedis",[2.970930295557642, 49.35769501594036]],
    [ "Enedis",[1.143166997365439, 49.40609976144362]],
    [ "Enedis",[1.1484948955823298, 49.40466177108434]],
    [ "Enedis",[2.210392000000001, 50.620016150000005]],
    [ "Enedis",[2.213684175648074, 50.62052329388223]],
    [ "SNCF Réseau",[1.0879227500000002, 48.078758099999995]],
    [ "Enedis",[1.7923595589632741, 48.8041186218259]],
    [ "Enedis",[1.790911911641788, 48.799503798707185]],
    [ "Enedis",[1.7957660910369406, 48.79372725759868]],
    [ "Enedis",[3.492268944953425, 50.36006774818161]],
    [ "Enedis",[6.3049467660610565, 45.36108095585861]],
    [ "Enedis",[3.154428661573535, 50.60746003438997]],
    [ "Genon",[6.107831139636041, 45.95019895224146]],
    [ "SNCF Réseau",[-0.41910755000000166, 47.94412075]],
    [ "SNCF Réseau",[-0.060420436530846736, 47.956680996283744]],
    [ "SNCF Réseau",[0.30177970000000004, 48.09585415]],
    [ "SNCF Réseau",[0.12401463199661929, 48.07447729943489]],
    [ "SNCF Réseau",[0.03617327015054633, 48.021020237818696]],
    [ "SNCF Réseau",[0.7324124077869937, 47.32049553844791]],
    [ "Enedis",[4.71427054787215, 45.217223771693874]],
    [ "Enedis",[2.4570174500000004, 49.02155305]],
    [ "Enedis",[2.457317000000001, 49.02132780000001]],
    [ "Enedis",[6.331482599999999, 46.248974350000005]],
    [ "Enedis",[2.2147175959860275, 50.61603061702123]],
    [ "SNCF Réseau",[0.6765424644412985, 47.27355102976232]],
    [ "Enedis",[6.38779002025267, 45.67181535638064]],
    [ "Enedis",[6.7009366, 43.44306699999999]],
    [ "Enedis",[6.415220218155506, 45.74812834822172]],
    [ "Enedis",[6.4099010000000005, 45.75268945]],
    [ "Enedis",[6.413631655217159, 45.72499891719136]],
    [ "Enedis",[6.489920495978577, 45.51497705027239]],
    [ "Enedis",[3.9292161917912742, 43.638545453392034]],
    [ "Poste électrique de Faux-Fresnay",[3.975483454800217, 48.64976245930465]],
    [ "Enedis",[6.399318272713397, 48.08853446484678]],
    [ "Le Curbelet",[1.29153835, 43.82174995]],
    [ "Poste électrique de Civray",[0.25979236307383324, 46.1502536916045]],
    [ "Poste électrique de Saint-Savol",[0.21700849098809183, 46.144774034531984]],
    [ "Enedis",[3.1921188351983827, 46.99065231706671]],
    [ "Enedis",[3.194813984553529, 46.98958400622846]],
    [ "Poste électrique de Mansle",[0.16055289301327932, 45.876374521115835]],
    [ "Enedis",[4.133213317397166, 43.711197289161426]],
    [ "Le Castillon",[6.538991688125187, 43.87778311423662]],
    [ "Enedis",[1.0722134757151247, 46.21119493202571]],
    [ "Enedis",[-3.1458702752128507, 47.522533847346764]],
    [ "Enedis",[5.185562299999999, 45.7468283]],
    [ "Enedis",[5.897989376026433, 46.06677835938248]],
    [ "Enedis",[5.863168043482845, 46.0654633355955]],
    [ "891486899",[3.609310599999999, 43.99057405]],
    [ "Enedis",[1.3288692256666659, 43.46050288990244]],
    [ "Enedis",[1.3331754827805793, 43.465233650776256]],
    [ "Enedis",[1.3213721502788676, 43.452163898020935]],
    [ "Enedis",[1.3266760500000003, 43.4585551]],
    [ "Enedis",[1.3227120500000003, 43.4440639]],
    [ "Enedis",[1.3117995500000001, 43.4139797]],
    [ "Enedis",[1.3181431000000003, 43.42324445]],
    [ "Enedis",[1.3128347392343709, 43.43962643263765]],
    [ "Enedis",[1.3112292676699961, 43.44154074684852]],
    [ "Enedis",[3.60367464398458, 43.98608562730151]],
    [ "Enedis",[1.3398398, 43.466824200000005]],
    [ "Enedis",[1.3220195499999998, 43.4595391]],
    [ "Enedis",[1.3194277698471082, 43.46480930269244]],
    [ "Enedis",[1.3246686110938515, 43.4589984166467]],
    [ "Enedis",[1.326261092213664, 43.47392942061154]],
    [ "Enedis",[1.3177414814192359, 43.466269808490246]],
    [ "Enedis",[1.3167729, 43.4611226]],
    [ "Enedis",[1.31508625, 43.455668]],
    [ "Enedis",[2.5759026682297406, 48.7761195486092]],
    [ "Enedis",[2.584860942684208, 48.77149977658184]],
    [ "Enedis",[2.6001783500000015, 48.775835]],
    [ "Enedis",[2.5797536343603613, 48.77890609676775]],
    [ "Enedis",[2.578781021798665, 48.78059477544723]],
    [ "Enedis",[2.5682238729198903, 48.78204062510403]],
    [ "Enedis",[2.570111478888534, 48.781474696647464]],
    [ "Enedis",[2.537191290186272, 48.76727547899943]],
    [ "Enedis",[2.5487342500000003, 48.77028355]],
    [ "Enedis",[2.5447464999192366, 48.77751681669601]],
    [ "Enedis",[2.537796199999999, 48.77740195]],
    [ "Enedis",[6.3378627, 45.399931050000006]],
    [ "Enedis",[6.282427521517268, 45.39111290465657]],
    [ "Enedis",[1.3325546049989756, 43.47399271958616]],
    [ "Enedis",[1.332879653796164, 43.47134271814868]],
    [ "Enedis",[1.3385965164771112, 43.45434640258841]],
    [ "Enedis",[1.3105134, 43.46414190000001]],
    [ "Enedis",[1.3135023466704163, 43.46560018421553]],
    [ "Enedis",[4.570082180951246, 46.442452107840495]],
    [ "CESML",[3.8325728827363092, 43.647064450786395]],
    [ "CESML",[3.8305950160597844, 43.64833075101763]],
    [ "SRD",[0.28329509903498074, 46.59662482621941]],
    [ "Enedis",[2.769587800000001, 46.06284575]],
    [ "Enedis",[1.3321808499999985, 43.477166600000004]],
    [ "Enedis",[1.4532997, 42.899203699999994]],
    [ "Enedis",[1.6171944962601945, 42.82732521705418]],
    [ "Enedis",[1.6245496828748762, 42.821471751739615]],
    [ "Enedis",[-1.3705520080621127, 48.78011622347319]],
    [ "Enedis",[5.7229992, 46.82704790000001]],
    [ "Enedis",[5.712470200000001, 46.832803500000004]],
    [ "Enedis",[6.2186206409, 45.849325864690115]],
    [ "Enedis",[0.9691723499999995, 49.2739019]],
    [ "SAIPEM",[4.921792229875992, 45.82262263065318]],
    [ "Enedis",[4.342205697811942, 46.97824613492238]],
    [ "Enedis",[6.100280795083447, 45.9507050340861]],
    [ "Enedis",[6.108441983784472, 45.94029290112931]],
    [ "Le Capitole",[6.0924295, 45.9150116]],
    [ "Enedis",[4.338139599291657, 47.03360575028238]],
    [ "Enedis",[1.9222622703603762, 47.884005121893765]],
    [ "Novaxud",[0.18673852908461697, 47.99406224210842]],
    [ "Alembert",[0.20844832760865925, 47.98534491642516]],
    [ "Miroir",[0.20040704072208218, 47.98899486666666]],
    [ "Enedis",[1.1462707776605114, 49.20737212497801]],
    [ "Enedis",[1.1568100589659784, 49.20600988861937]],
    [ "Enedis",[3.60243445, 43.989715749999995]],
    [ "Enedis",[1.3101316178800622, 43.42940159860667]],
    [ "Enedis",[1.3283846367289247, 43.46674192412703]],
    [ "Enedis",[1.3227681831508091, 43.46181069965223]],
    [ "Enedis",[1.3306489, 43.46906365]],
    [ "Enedis",[1.2940887190060484, 43.46660035384985]],
    [ "Enedis",[1.3072367690644122, 43.45862094473539]],
    [ "Enedis",[1.308643308033254, 43.460090917057904]],
    [ "Enedis",[1.8144807430151595, 48.77221341134186]],
    [ "Enedis",[1.8206963506726943, 48.781534886987366]],
    [ "Enedis",[1.8149107500000001, 48.7822679]],
    [ "Enedis",[1.7945575703563292, 48.77125303441403]],
    [ "Estienne d'Orves",[0.1992880000000012, 47.98085455]],
    [ "Enedis",[1.2716345853922186, 43.46574752571189]],
    [ "Enedis",[3.627665868538927, 43.99110322829435]],
    [ "Enedis",[1.2515838938216162, 43.467402632457116]],
    [ "LISEA",[0.6561689, 47.19651729999999]],
    [ "Enedis",[4.374941062964741, 49.872708181483496]],
    [ "Enedis",[6.1095087999999995, 45.3952952]],
    [ "Acacia 63",[0.18451973344357392, 47.99633274804324]],
    [ "Enedis",[1.0873296191022637, 49.51532480184108]],
    [ "Bazin 2",[-0.49518989999999996, 47.46757945]],
    [ "Centre commercial",[-0.4951789896809996, 47.4675436318851]],
    [ "Margaux",[0.1989648160559502, 47.989112254220736]],
    [ "Bachet",[2.647456722269305, 48.320683387706]],
    [ "Bordeaux",[2.6541594733607985, 48.3196144185122]],
    [ "Garenne",[2.6511089841101363, 48.31807957633165]],
    [ "Enedis",[2.816819979278054, 50.29729980308576]],
    [ "Enedis",[0.959676192369792, 49.55881474529073]],
    [ "Enedis",[0.9600999002717183, 49.560325266528025]],
    [ "Enedis",[0.7533036, 49.624863350000005]],
    [ "Brossolette",[0.20572820000000003, 47.977707900000006]],
    [ "Pied Sec 2",[0.20261005731125803, 47.98205142454949]],
    [ "Enedis",[1.845516545429418, 50.96610813337903]],
    [ "Enedis",[0.5484112176298805, 46.803143161839685]],
    [ "Gaugain",[0.21126487092243015, 47.97309272462677]],
    [ "Enedis",[5.727225994658698, 46.81427604533143]],
    [ "Enedis",[5.733196952357427, 46.8157557664821]],
    [ "Enedis",[5.67235025, 46.7429785]],
    [ "Enedis",[5.719654408789725, 46.86705666532301]],
    [ "Enedis",[5.722968497729197, 46.86716271769003]],
    [ "Enedis",[5.637170516699559, 46.911111150897504]],
    [ "Enedis",[5.63067385, 46.912321500000004]],
    [ "Enedis",[5.64592475, 46.89793614999999]],
    [ "Enedis",[5.68188275, 46.8610879]],
    [ "Enedis",[5.685628866634907, 46.861842696188944]],
    [ "Enedis",[5.6997814, 46.846837900000004]],
    [ "Enedis",[3.403779883149548, 47.00449366023766]],
    [ "Enedis",[5.482106, 47.0175702]],
    [ "Enedis",[5.44570814995338, 47.037828233310194]],
    [ "Enedis",[5.441575449999998, 47.038206249999995]],
    [ "Enedis",[5.44658659277697, 46.99318738331104]],
    [ "Enedis",[5.4465552, 46.9927112]],
    [ "Enedis",[5.4962527802394066, 47.00749359314955]],
    [ "Enedis",[5.55665155405656, 46.99290371678257]],
    [ "Enedis",[5.50252347007622, 46.95006334806609]],
    [ "Enedis",[5.535764852311834, 47.03674148314589]],
    [ "Enedis",[5.72879151001327, 47.024180580075736]],
    [ "Enedis",[5.571436851168306, 46.965752987033426]],
    [ "Enedis",[3.6064524999999996, 43.99031179999999]],
    [ "Enedis",[5.5539493664453365, 47.027980101383314]],
    [ "Enedis",[5.557120447567642, 47.03188146672984]],
    [ "Enedis",[5.5816147739514355, 47.01735654437087]],
    [ "Enedis",[1.8472936785981153, 43.843471833227525]],
    [ "Les Platanes",[0.23174254999999885, 47.982549199999994]],
    [ "Enedis",[2.7828783525927423, 42.67963126508807]],
    [ "SRD",[0.23166700000000018, 46.3203007]],
    [ "Enedis",[5.942101194546714, 46.93273152998527]],
    [ "Enedis",[5.795628864660841, 46.90283716545656]],
    [ "Enedis",[5.793510497181608, 46.90117434643048]],
    [ "Enedis",[5.758162446851764, 46.88328783406482]],
    [ "Enedis",[5.640752039009016, 47.019163447222994]],
    [ "Enedis",[5.634801628596457, 47.03976849264272]],
    [ "40 Courbet",[0.22187025000000005, 47.972126900000006]],
    [ "6 Courbet",[0.21983838847502576, 47.97421172857514]],
    [ "Enedis",[5.437240767373967, 47.110121895015205]],
    [ "Enedis",[5.432124623474693, 47.10253363698642]],
    [ "Enedis",[5.43522455, 47.10644045]],
    [ "Enedis",[5.4404265, 47.10241645000001]],
    [ "Enedis",[5.4389236886524825, 47.10110994219858]],
    [ "Enedis",[5.518149150000002, 47.004839]],
    [ "Enedis",[5.494228453810648, 47.014335380790186]],
    [ "Enedis",[5.46329405, 47.0559807]],
    [ "Enedis",[5.493748850097512, 47.05544563334117]],
    [ "Enedis",[5.494076806882531, 47.05157867550763]],
    [ "Enedis",[5.4982912, 47.05024555000001]],
    [ "Enedis",[5.72679155, 47.14357819999999]],
    [ "Enedis",[2.0053847999999985, 47.925788649999994]],
    [ "Enedis",[2.986046379030878, 42.72554399748852]],
    [ "DP Cogne",[0.21150244999999998, 48.0002459]],
    [ "Enedis",[3.24329297788306, 50.188781537281805]],
    [ "Enedis",[3.234190447293184, 50.164194726163466]],
    [ "Enedis",[0.5391923886251365, 46.83816319280291]],
    [ "Enedis",[2.6536340495335677, 50.43718998332499]],
    [ "Concept TY",[0.19473442024167295, 47.98055197537828]],
    [ "René Cassin",[0.1949244, 47.981148999999995]],
    [ "Enedis",[3.2207210440524103, 50.17267002249485]],
    [ "Enedis",[6.007318203777894, 48.15813242754622]],
    [ "Enedis",[5.999458126244233, 48.18907786372259]],
    [ "Enedis",[5.798622200000001, 47.1863238]],
    [ "Enedis",[5.792973199080925, 47.183131483627776]],
    [ "Enedis",[5.7797388515119446, 47.171930383245915]],
    [ "Enedis",[5.743632079495495, 47.155059534306304]],
    [ "Enedis",[5.738705848541309, 47.15371288065801]],
    [ "Enedis",[-1.5906154000000001, 47.279470200000006]],
    [ "Enedis",[-1.5906422358744137, 47.27943957804102]],
    [ "Enedis",[-1.5882701936222063, 47.27795572997205]],
    [ "Enedis",[5.762255856188956, 47.1481956734035]],
    [ "Enedis",[5.442311053601354, 47.10585806579887]],
    [ "Enedis",[5.49657695, 47.12791239999999]],
    [ "Enedis",[5.490577745711265, 47.14515276686211]],
    [ "Enedis",[5.49267196784578, 47.14364621839791]],
    [ "Enedis",[5.511404367309336, 47.15709759829077]],
    [ "Perronnière",[0.166096, 47.9852763]],
    [ "Être des Prés",[0.17204804345897998, 47.992236733333336]],
    [ "Enedis",[2.5752931066328033, 44.361920892951396]],
    [ "Enedis",[5.65040909238623, 47.17402058090252]],
    [ "Enedis",[5.749738505165058, 47.191244082678345]],
    [ "Carrière Noire",[3.3689416000000003, 50.4441549]],
    [ "Enedis",[5.6059132, 47.15088490000001]],
    [ "Enedis",[5.480503260366238, 47.11105812561287]],
    [ "Enedis",[5.381278721293166, 47.19901568882059]],
    [ "Enedis",[5.388101725131034, 47.19913420518922]],
    [ "Enedis",[5.3787477500000005, 47.187910200000005]],
    [ "Foucaudière 2",[0.19762840000000068, 47.977077949999995]],
    [ "Clarté Foucaudière",[0.185989150000001, 47.97149185000001]],
    [ "Becquerel 2",[0.1775140301254346, 47.96358359748063]],
    [ "Lefaucheux 11 13",[0.17576799420723338, 47.967910120700914]],
    [ "Prosper",[0.17715530000000096, 47.970536550000006]],
    [ "Frères Renault",[0.1769460000000005, 47.98249104999999]],
    [ "Espace Boissin",[0.18415841613783285, 47.98385434917973]],
    [ "Refour",[0.18565955883293223, 47.98272400418989]],
    [ "Enedis",[5.8326357, 46.813421600000005]],
    [ "Enedis",[-4.132984799999999, 48.2683925]],
    [ "Enedis",[3.515315350381566, 50.35251302067632]],
    [ "Enedis",[2.405544334812456, 48.75361556946207]],
    [ "Coquelicots",[0.1913499844903736, 47.986393202152655]],
    [ "Enedis",[5.7007558000000005, 46.93440345]],
    [ "Enedis",[5.935416850000001, 46.94020700000001]],
    [ "LISEA",[0.6133007523236935, 47.06435360144019]],
    [ "LISEA",[0.6799429497504522, 47.11885599941139]],
    [ "Enedis",[5.191506913048997, 46.62326803158161]],
    [ "Enedis",[6.102440613912693, 46.02361057246604]],
    [ "Enedis",[6.1059303, 46.0269947]],
    [ "Enedis",[6.10224805, 46.02616455]],
    [ "Enedis",[3.9458530729684913, 50.26811306835677]],
    [ "Enedis",[3.935851394366978, 50.27810851444713]],
    [ "réséda",[6.183125688859695, 49.0526228361079]],
    [ "Enedis",[1.1351314000000001, 43.21965529999999]],
    [ "Enedis",[3.0491667018515134, 50.68476566645369]],
    [ "Enedis",[3.7101523693567913, 43.552644139532724]],
    [ "Enedis",[3.050928641102063, 50.68434228780051]],
    [ "Enedis",[6.113238082589929, 47.016814701907855]],
    [ "Enedis",[6.15170859865977, 47.04269371626771]],
    [ "Enedis",[6.154802345150196, 47.04508396590996]],
    [ "Enedis",[6.149228150000001, 47.0446975]],
    [ "Enedis",[5.402985900000001, 46.89754415]],
    [ "Enedis",[0.5950584359363688, 48.55482852752472]],
    [ "Enedis",[3.5078190664726425, 47.799012054363665]],
    [ "Enedis",[5.344052464197705, 46.99638189334472]],
    [ "Enedis",[5.342870532351285, 46.99683014389167]],
    [ "Enedis",[5.3008698999999995, 46.96663805]],
    [ "Enedis",[5.309234849999999, 47.00356920000001]],
    [ "Enedis",[5.377182008476506, 46.977050896000705]],
    [ "Enedis",[5.361188425393274, 46.9807902880519]],
    [ "Enedis",[5.3852988, 47.0105531]],
    [ "Vauguyon",[0.21847724999999998, 47.97679625]],
    [ "Les Bigarreaux",[0.2220850673793734, 47.97890989885828]],
    [ "Enedis",[2.463869365481036, 48.80940883174312]],
    [ "Enedis",[2.476950460140531, 48.795163228453085]],
    [ "Enedis",[1.3097789879237942, 48.53211113022219]],
    [ "Enedis",[1.1891081448333578, 48.534866687410585]],
    [ "Enedis",[-0.7274577609635055, 47.3294615594513]],
    [ "Enedis",[-1.6923166897173898, 48.19351032670676]],
    [ "Enedis",[0.21669565000000002, 47.9726441]],
    [ "Hémery",[0.21914565, 47.96921305]],
    [ "Enedis",[3.2517378309192755, 50.47403906827477]],
    [ "Enedis",[-1.5423833033741923, 48.83985673512802]],
    [ "La Mare avec Fond",[2.1344053746796607, 48.66634441766829]],
    [ "Enedis",[2.1997675345759613, 48.6785047038265]],
    [ "Enedis",[2.198801296492354, 48.67755276676105]],
    [ "Enedis",[2.1953719990724805, 48.67249003372638]],
    [ "Enedis",[5.495198589787654, 46.62525157059537]],
    [ "Enedis",[5.464253499999999, 46.60316924999999]],
    [ "Enedis",[5.461643483487415, 46.597065551530775]],
    [ "Enedis",[5.521762066738547, 46.657252205139585]],
    [ "Dalloz Courbouzon",[5.536138592635816, 46.656969246107565]],
    [ "Enedis",[5.595197658232282, 46.655665494023005]],
    [ "Enedis",[5.551201376387595, 46.69192332566435]],
    [ "Poids Public",[5.4734671, 46.60617145000001]],
    [ "Enedis",[5.474539442812103, 46.60950306553244]],
    [ "Enedis",[5.539404435206329, 46.64833925358992]],
    [ "Enedis",[1.6514131170682425, 50.513362065897034]],
    [ "Enedis",[3.1380246687035864, 50.41227235805899]],
    [ "Enedis",[2.4942213712196124, 50.35354206135146]],
    [ "réséda",[6.091775150000001, 48.98968095]],
    [ "réséda",[6.055387359576789, 48.99464918209445]],
    [ "Enedis",[-2.083164072223402, 47.11177841574588]],
    [ "Enedis",[2.6314329499999993, 50.1535013]],
    [ "Enedis",[3.056529503524321, 50.372950429135415]],
    [ "Enedis",[6.973045482906749, 47.59222004481364]],
    [ "Enedis",[6.1324578, 46.6799471]],
    [ "Enedis",[6.126618283304973, 46.682818100415965]],
    [ "Enedis",[6.082654749999998, 46.662859299999994]],
    [ "Enedis",[1.9315040167606006, 47.88677024969508]],
    [ "Enedis",[1.32925855, 43.048726949999995]],
    [ "P 46 Jean Perrin",[0.17120544999999998, 48.0263375]],
    [ "Nice N23",[0.17792288292819597, 48.01927278388991]],
    [ "Enedis",[6.051982309201684, 46.661889518705664]],
    [ "Enedis",[3.105396316318336, 50.369618936943034]],
    [ "Enedis",[5.518334389460313, 46.777544820292704]],
    [ "Enedis",[5.516744749999999, 46.7627977]],
    [ "Enedis",[5.528429917874119, 46.761122427077396]],
    [ "Enedis",[5.554645649999999, 46.73589205]],
    [ "Enedis",[5.554725280102725, 46.73172837009342]],
    [ "Enedis",[5.5232802, 46.76192464999999]],
    [ "Enedis",[5.486114853745766, 46.74216448635648]],
    [ "Enedis",[5.498615699999999, 46.74378120000001]],
    [ "Enedis",[1.8800563000000001, 47.892768450000005]],
    [ "Enedis",[-4.513541450000001, 48.41817895]],
    [ "Enedis",[3.146195237444323, 50.35151183440241]],
    [ "Enedis",[3.127112633333333, 50.351248466666654]],
    [ "Enedis",[3.4055043815217667, 50.3206968177786]],
    [ "Enedis",[3.4033970761904766, 50.32050327835499]],
    [ "Enedis",[3.4084858115664844, 50.319549772885765]],
    [ "Enedis",[1.4976626790784555, 46.244280224180166]],
    [ "Enedis",[5.56345029070173, 47.16492293360842]],
    [ "Enedis",[5.56544082946368, 47.16670015923329]],
    [ "Enedis",[5.551496472232765, 47.14901613357144]],
    [ "Enedis",[5.5556449500000005, 47.148052549999996]],
    [ "Enedis",[5.5775383, 47.14708815]],
    [ "Enedis",[5.587133550000001, 47.12451565]],
    [ "Enedis",[5.554859800000001, 47.1288122]],
    [ "Enedis",[5.545554449303337, 47.11626844998169]],
    [ "Enedis",[5.556751834162792, 47.12667164543797]],
    [ "dp/151",[5.560480999999999, 47.12518175]],
    [ "Enedis",[5.5620470000000015, 47.13318810000001]],
    [ "Enedis",[5.561794668865293, 47.12819149610605]],
    [ "Enedis",[5.530981350000001, 46.52649895]],
    [ "Enedis",[5.5472201209877685, 46.586456876463245]],
    [ "Enedis",[3.026006979026415, 50.60684949308958]],
    [ "Enedis",[4.73759785, 48.374512700000004]],
    [ "Enedis",[5.44760248605184, 46.58593213226039]],
    [ "Enedis",[5.456429399999999, 46.5687109]],
    [ "Enedis",[1.87367232514573, 47.922532510516945]],
    [ "Enedis",[2.7209901488449866, 49.307073317163145]],
    [ "Enedis",[2.2390438125220955, 50.75234677170514]],
    [ "Enedis",[1.3214757585075354, 43.457871566006474]],
    [ "Enedis",[1.3396930198069859, 43.46611114730079]],
    [ "Enedis",[1.33872805, 43.465700049999995]],
    [ "Enedis",[1.337228645578969, 43.465322671408074]],
    [ "Enedis",[5.14177795, 46.915039799999995]],
    [ "Enedis",[5.1698237193426175, 46.959925634277795]],
    [ "Enedis",[2.2956017300170632, 49.96744595230661]],
    [ "Niemen",[0.21498779999999998, 47.98931055]],
    [ "Enedis",[5.3703135684076075, 46.69254059247298]],
    [ "Enedis",[5.365339250949358, 46.69097138362852]],
    [ "Enedis",[5.334607087239574, 46.669550465478636]],
    [ "Ury",[2.601142920155993, 48.34281960016421]],
    [ "Enedis",[2.752958735717155, 50.26612242384278]],
    [ "Enedis",[2.592675275540026, 50.22167757754709]],
    [ "Enedis",[5.432445850480496, 46.27575332386161]],
    [ "Enedis",[5.560404612251356, 46.358207216300755]],
    [ "Enedis",[5.54064895, 46.30832225]],
    [ "Enedis",[1.7746866323853177, 48.472112349025814]],
    [ "Enedis",[1.6305322027634004, 42.815288617201055]],
    [ "Enedis",[1.6468127165545385, 42.79985109985659]],
    [ "Enedis",[1.6325622191889158, 42.799427254854656]],
    [ "Enedis",[1.5685320215740675, 42.85405335343308]],
    [ "Enedis",[2.5779853666946884, 50.353635800243794]],
    [ "Enedis",[-1.7459783091755, 46.50846885648104]],
    [ "Enedis",[5.522490625130817, 46.34013761438269]],
    [ "Enedis",[5.49408205, 46.2916805]],
    [ "Enedis",[5.485044870154253, 46.27605610514562]],
    [ "Foyer d’accueil",[6.13163625, 48.38902155]],
    [ "Enedis",[4.170072022534414, 43.74595757420301]],
    [ "Enedis",[-1.769285050000001, 46.508466]],
    [ "TV usine des eaux",[0.23526338704014726, 47.99675216256695]],
    [ "Enedis",[5.338534037792129, 46.194583451314976]],
    [ "Enedis",[5.4271242, 46.248108450000004]],
    [ "CCI Formation",[0.2354060872003083, 47.98387045117234]],
    [ "Le Verger",[0.24793788956677973, 47.98966705933164]],
    [ "Épau",[0.24261938850708284, 47.98841713235643]],
    [ "Enedis",[1.5565536500000001, 42.783744049999996]],
    [ "Enedis",[1.5796944499999999, 42.79258]],
    [ "Enedis",[1.6106023000000007, 42.831114250000006]],
    [ "ZAC ST Jean Sud",[1.297846599561548, 43.834236240624755]],
    [ "Enedis",[4.979804349999999, 44.96873895]],
    [ "Enedis",[4.954992250000001, 44.9182963]],
    [ "Taillebrague",[1.2863018072602885, 43.82248178339126]],
    [ "Enedis",[5.486696688615025, 46.42193517009933]],
    [ "Enedis",[5.4783287174047075, 46.43046149854089]],
    [ "Enedis",[5.467374393604366, 46.419965986145286]],
    [ "Enedis",[5.437808892783881, 46.41589313996564]],
    [ "Enedis",[5.430974299999999, 46.37950265]],
    [ "Sous-station SNCF",[0.19778524963373642, 47.993337183864476]],
    [ "Enedis",[5.362878650000002, 46.4563559]],
    [ "Enedis",[5.35684155, 46.455146]],
    [ "Enedis",[5.450303809856672, 46.46988164788269]],
    [ "Enedis",[5.37186515, 46.53428319999999]],
    [ "Enedis",[5.334896099999999, 46.4312989]],
    [ "Enedis",[5.371756508460371, 46.40554214857416]],
    [ "LISEA",[0.5480933510188855, 46.997633700707304]],
    [ "Enedis",[3.0383367446950755, 50.68425611534272]],
    [ "Enedis",[5.405902425204716, 46.52129153463092]],
    [ "Enedis",[5.48976595, 46.49093279999999]],
    [ "Enedis",[5.45164154470924, 46.49278743866845]],
    [ "Enedis",[5.36434095, 46.4306156]],
    [ "Enedis",[5.37744535, 46.44057069999999]],
    [ "Zone Artisanale",[1.2954053451374843, 43.83439535397467]],
    [ "Residence St Georges",[1.2928951655728729, 43.83468283887977]],
    [ "Lot des Nauzes",[1.2912451757057548, 43.83666942888165]],
    [ "Enedis",[5.422002432896433, 46.560801791553274]],
    [ "Enedis",[5.41601275, 46.5617646]],
    [ "Enedis",[5.418059399981781, 46.58175744967045]],
    [ "Enedis",[5.5593913, 46.615346100000004]],
    [ "Enedis",[5.565092367022513, 46.597295698592795]],
    [ "Zone Industrielle",[1.2882577146167065, 43.835957996350096]],
    [ "Lot Pinedes",[1.2858627965781633, 43.83452619918535]],
    [ "Rue Sapiac",[1.2861324204017766, 43.83254652079884]],
    [ "Enedis",[5.451824199999999, 46.530286399999994]],
    [ "Enedis",[5.438510400000001, 46.53348029999999]],
    [ "Enedis",[5.4333049, 46.905647050000006]],
    [ "Enedis",[5.417720246497282, 46.91125085048301]],
    [ "Enedis",[5.520518650000001, 46.921437850000004]],
    [ "Enedis",[5.462441708087513, 46.91560583139749]],
    [ "Enedis",[5.325359000000001, 46.9400266]],
    [ "Enedis",[5.320795008328647, 46.93680506459731]],
    [ "Enedis",[5.341049599999999, 46.93850525]],
    [ "Enedis",[5.3429263, 46.93961999999999]],
    [ "Enedis",[5.34299715, 46.9361492]],
    [ "Des Ardeilles",[1.2878433778498708, 43.83032734582076]],
    [ "Enedis",[1.57353815, 50.158951449999996]],
    [ "Enedis",[5.320328591179324, 47.02869112065361]],
    [ "Enedis",[5.3924767, 47.105762799999994]],
    [ "Enedis",[5.392078844224416, 47.10072400139824]],
    [ "Enedis",[5.554282000000001, 46.720694550000005]],
    [ "Enedis",[5.898928659490616, 46.91078236994914]],
    [ "Enedis",[5.7736441428252085, 46.25833527104464]],
    [ "Enedis",[5.778477168708377, 46.25009721656981]],
    [ "Enedis",[5.786257866174343, 46.25422498311399]],
    [ "Enedis",[5.807501616668457, 46.2699488499359]],
    [ "Enedis",[2.3643323281162347, 48.54866523921613]],
    [ "Enedis",[2.1872647274442407, 48.590436247067714]],
    [ "Glonnières",[0.2125322831558258, 47.97138360808909]],
    [ "SSR7 Les Glonnières",[0.21537378233355275, 47.97254424116978]],
    [ "Paul Cézanne",[0.21500385000000002, 47.97326090000001]],
    [ "Enedis",[5.5944630643579165, 47.25478157272429]],
    [ "Enedis",[5.61343015, 47.23756845]],
    [ "Enedis",[5.701737186281458, 47.260284126056554]],
    [ "Enedis",[5.685009400000001, 47.25268504999999]],
    [ "Enedis",[5.664035332485769, 47.24749814838294]],
    [ "Enedis",[5.5671155500000005, 47.263269349999995]],
    [ "Enedis",[5.574444678537589, 47.27575051135449]],
    [ "Enedis",[5.573591899999999, 47.27777739999999]],
    [ "Enedis",[5.502537320493921, 47.20339065857969]],
    [ "Enedis",[5.5329326000000005, 47.19278595]],
    [ "Enedis",[5.531853508663691, 47.19150124162819]],
    [ "Enedis",[5.8925795, 47.1034865]],
    [ "Enedis",[5.792133809387158, 47.167022465952435]],
    [ "Enedis",[5.856240501562182, 47.173213184006876]],
    [ "Enedis",[5.7571701392120085, 47.14596971607254]],
    [ "Enedis",[5.892573667068891, 47.1064927005794]],
    [ "Enedis",[5.649339549999999, 47.19373979999999]],
    [ "Enedis",[5.418169149999999, 47.0440107]],
    [ "Enedis",[5.42124065, 47.047976399999996]],
    [ "Enedis",[6.117342174340759, 46.80072564349816]],
    [ "Enedis",[6.011125899999999, 46.79571425]],
    [ "Enedis",[5.974790699999999, 46.5749084]],
    [ "Enedis",[5.669654749999999, 46.898397550000006]],
    [ "Enedis",[1.9184826678088063, 47.88425083987286]],
    [ "Enedis",[6.090374096451718, 45.91695562020783]],
    [ "Enedis",[5.6933733, 46.989724349999996]],
    [ "Enedis",[5.693348754667488, 46.98974688243956]],
    [ "Enedis",[5.780417599999999, 47.01612550000001]],
    [ "Enedis",[5.737897349999999, 46.9993254]],
    [ "Enedis",[5.70622565, 46.997561899999994]],
    [ "Enedis",[5.897413526411684, 47.05975460727844]],
    [ "Enedis",[5.8615466000000005, 47.0584279]],
    [ "Enedis",[5.935413350000003, 46.855157500000004]],
    [ "Enedis",[5.934309209375462, 46.85349837162854]],
    [ "Enedis",[5.939771059975405, 46.778335074634235]],
    [ "Enedis",[5.943412189525438, 46.775877729048496]],
    [ "Enedis",[5.87830065, 47.0410782]],
    [ "Enedis",[5.883204549999999, 47.039423]],
    [ "Enedis",[5.79078555, 47.004832949999994]],
    [ "Enedis",[5.515809, 47.2174513]],
    [ "Enedis",[2.8432235, 50.32066285000001]],
    [ "Enedis",[2.8271618865803596, 50.31688086734387]],
    [ "Enedis",[2.824376614560683, 50.311338329956655]],
    [ "Enedis",[6.1178076, 46.734152349999995]],
    [ "Enedis",[6.083500999999999, 46.72355654999999]],
    [ "Enedis",[5.9678006666476975, 46.74970135036752]],
    [ "Enedis",[5.9922176, 46.77908204999999]],
    [ "Enedis",[6.11830024748538, 46.78909636666667]],
    [ "Enedis",[6.117654934042924, 46.789954801455025]],
    [ "Enedis",[6.123301049999999, 46.79049694999999]],
    [ "Enedis",[6.098670718613764, 46.79932145263693]],
    [ "Enedis",[6.1063933000000015, 46.79521835]],
    [ "Enedis",[6.108522511981828, 46.79424938052242]],
    [ "Enedis",[6.069610370347707, 46.82504740711668]],
    [ "Enedis",[6.065871799999999, 46.813681300000006]],
    [ "Enedis",[6.037652805106708, 46.824644569481386]],
    [ "Enedis",[6.045002891484064, 46.81093275404632]],
    [ "Enedis",[6.0402316, 46.80142169999999]],
    [ "Enedis",[5.898837250000001, 46.8854772]],
    [ "Enedis",[5.975316652422106, 46.899068178544574]],
    [ "Enedis",[5.4117090999999995, 47.04791869999999]],
    [ "Enedis",[2.839875840545061, 50.32258403232979]],
    [ "Enedis",[1.813864307580004, 47.94062271542557]],
    [ "Enedis",[6.1391960027057495, 46.53296506670424]],
    [ "Enedis",[6.063665346608713, 46.65475171569864]],
    [ "Enedis",[5.817169375430704, 46.29364965551693]],
    [ "Enedis",[5.814833950000001, 46.289555050000004]],
    [ "Enedis",[5.9128226999999995, 46.33225215000001]],
    [ "Enedis",[5.991038935693678, 46.40652775105379]],
    [ "Enedis",[5.99758555, 46.41499555]],
    [ "Enedis",[3.054624395962816, 48.32133866037184]],
    [ "906702710",[3.2802859116290235, 48.09375835497218]],
    [ "Enedis",[5.922892066740478, 46.43758615148236]],
    [ "Enedis",[5.964620699999998, 46.45872105]],
    [ "Enedis",[5.925076792451681, 46.45379177413382]],
    [ "Enedis",[5.9180642500000005, 46.4502928]],
    [ "Enedis",[5.968537950000001, 46.39297335]],
    [ "Enedis",[5.968409853412976, 46.39087338294394]],
    [ "Enedis",[5.961864300000001, 46.39323410000001]],
    [ "REPUBLIQUE",[4.95687926675666, 45.826185899782956]],
    [ "Perrunquines 1",[1.274503482970463, 43.84894879040241]],
    [ "Perrunquines 2",[1.2743810889208422, 43.849424642516595]],
    [ "Perrunquines 3",[1.2742099549304884, 43.85006520986098]],
    [ "Enedis",[4.150023045317491, 43.69250747525234]],
    [ "Enedis",[2.011819347253998, 48.60214708629348]],
    [ "Enedis",[0.3768907005448589, 46.6714891339963]],
    [ "Enedis",[3.1417681499999985, 50.679520749999995]],
    [ "Enedis",[3.1541052249593, 50.674084086748785]],
    [ "Enedis",[3.1518697787736825, 50.674020468093424]],
    [ "Enedis",[4.9244848, 44.829704899999996]],
    [ "CM Stendhal",[2.2365245299164704, 48.78774277511319]],
    [ "Enedis",[4.914721800000001, 44.834388600000004]],
    [ "Enedis",[4.911258891605024, 44.836280382561156]],
    [ "Enedis",[4.898436370111817, 44.83696055531971]],
    [ "Enedis",[6.041996715711301, 48.19190810173893]],
    [ "Enedis",[6.0084052321601735, 48.18687455185077]],
    [ "Enedis",[5.917552384608518, 48.19093997688163]],
    [ "SRD",[0.27994691862048177, 46.60101403347368]],
    [ "Moëns bourg",[6.093703650793296, 46.263500266674114]],
    [ "SRD",[0.3128539999342194, 46.586573762728534]],
    [ "Enedis",[2.5497644839176696, 50.73622522711094]],
    [ "Powerex",[0.21782922487306094, 47.96502795391251]],
    [ "Brice",[0.2192918000000006, 47.96363100000001]],
    [ "Panorama",[0.21981230000000002, 47.96396305]],
    [ "Petit Houx",[0.21411065000000076, 47.961510000000004]],
    [ "EDME Bouchardon",[0.2109386, 47.9616506]],
    [ "Enedis",[6.14939665610779, 48.201896772244936]],
    [ "RTE",[2.3272258847637195, 50.07403365549254]],
    [ "Mariey",[2.5887545785498967, 48.87202089785918]],
    [ "Stade",[5.954100068308322, 46.57504873680851]],
    [ "Enedis",[4.958223590742503, 44.83596937972875]],
    [ "Enedis",[4.9283425, 44.851669449999996]],
    [ "Enedis",[5.9282942996845325, 48.78639228322872]],
    [ "Étoc Demazy",[0.19078054757865007, 47.993802520938985]],
    [ "Magasin Stoc",[0.17836729568920476, 47.997737365443534]],
    [ "Enedis",[0.1739194558291206, 47.99886957091967]],
    [ "Clos Montfort",[0.17062395000000002, 47.9960744]],
    [ "Le Loulay",[0.15237779999999998, 48.00582765]],
    [ "Rigaudières",[0.16018819999999942, 48.00354870000001]],
    [ "Jardin des tambours",[0.17393224218531111, 48.0062554230605]],
    [ "Les quatre saisons",[0.1802909, 48.0044106]],
    [ "Enedis",[0.19025964925987, 47.9962952999607]],
    [ "Enedis",[2.695076987762237, 46.705825700775094]],
    [ "Enedis",[-1.74234895, 48.11859985]],
    [ "SNCF Réseau",[0.395974714141945, 46.849480336142996]],
    [ "Coste Basse",[1.310703435242503, 43.82910426945328]],
    [ "Enedis",[-1.7459933360724742, 48.115538966920276]],
    [ "Couty",[6.326143534105602, 46.25766523706741]],
    [ "EDF",[2.8517208531742417, 48.380552429662075]],
    [ "EDF",[2.851479385125872, 48.380732770771054]],
    [ "Électricité de France",[-1.8776009184954645, 47.27700772206687]],
    [ "Électricité de France",[-1.876706953906758, 47.27665410992763]],
    [ "Électricité de France",[-1.87541439260494, 47.275801868370536]],
    [ "Électricité de France",[-1.8746574364059856, 47.27550401407925]],
    [ "Enedis",[1.7454326304012566, 47.34052423451669]],
    [ "Le Pinette",[0.23701190000000052, 47.968146749999995]],
    [ "Bazinière",[0.23276959333854436, 47.96715608702409]],
    [ "Le Meslier",[0.22698086820311153, 47.95356835124325]],
    [ "Étrier Sarthois",[0.22132230000000003, 47.951429850000004]],
    [ "Korten",[0.22838545, 47.953625949999996]],
    [ "Hunaudières",[0.2299952685301888, 47.947897580086284]],
    [ "Hippodrome",[0.22276484999999924, 47.946386450000006]],
    [ "Enedis",[1.3341032987843753, 43.58609048476976]],
    [ "Enedis",[0.20394223706753478, 48.59473918239627]],
    [ "Enedis",[2.9594765981654274, 42.53849455293022]],
    [ "Enedis",[2.963811312994663, 42.538073824828075]],
    [ "Enedis",[4.26129395, 49.536178]],
    [ "Résidence Douce Amie",[0.23585184088785935, 48.0004813739672]],
    [ "300 rue de l'Éventail",[0.23160553290122682, 48.005219472824116]],
    [ "Les Hauts de Saint-Ex",[0.21734730000000002, 48.00095244999999]],
    [ "Enedis",[2.3831941177921974, 48.56409656393789]],
    [ "Germois",[5.795529449097087, 48.54705568333583]],
    [ "Jardins",[5.788233209485781, 48.54728627578037]],
    [ "Enedis",[5.778461600000002, 48.54576465000001]],
    [ "Grand Pré",[-0.49220350000000007, 47.4672358]],
    [ "Flaubert",[3.123173549999999, 45.76969485]],
    [ "Enedis",[5.15363327461172, 45.61271513357744]],
    [ "Moulins des Noyers",[0.26179289999999894, 47.99817285]],
    [ "Enedis",[1.611803829750528, 45.62887418641778]],
    [ "Enedis",[4.864394376461063, 45.74585927514917]],
    [ "Enedis",[4.865921174147607, 45.74021029731018]],
    [ "Courteboule",[0.2716727187586952, 47.984134798355676]],
    [ "Le Pavillon",[0.2635972577447236, 47.983834124186366]],
    [ "Californie",[0.26685012185991974, 47.98472900530375]],
    [ "Les Landrières",[0.26555051451636846, 47.98886865242039]],
    [ "Enedis",[6.130096101108414, 45.91514783304902]],
    [ "Enedis",[4.879216663632092, 45.73681049680966]],
    [ "912169070",[4.883541196907843, 45.738595267125284]],
    [ "Enedis",[-1.1517861255212403, 45.84078570844386]],
    [ "Enedis",[-1.09793981390258, 45.81575632579143]],
    [ "Ruet du Goulot",[-1.5928474074328483, 49.04866701654949]],
    [ "Général Guérin",[-1.5894704499999999, 49.04744809999999]],
    [ "Maison de retraite",[-1.5754234000000016, 49.045909249999994]],
    [ "Orions",[-1.5755201822289577, 49.0413683990472]],
    [ "Eugène Bosquet",[-1.5777127999999998, 49.03879050000001]],
    [ "Enedis",[-1.5886984678900629, 49.04464794429632]],
    [ "Enedis",[1.33342535, 43.48254490000001]],
    [ "Le Centre",[-1.5985057999999999, 49.04898915]],
    [ "La Mi-Mielle",[-1.586241109903164, 49.02562021770632]],
    [ "La Mare Vallée",[-1.5841753499999998, 49.048412600000006]],
    [ "Enedis",[1.4483590778544702, 45.92362027976541]],
    [ "Tennis",[6.0422613657915125, 48.634059332650175]],
    [ "Enedis",[1.6323724500878807, 47.24300220193711]],
    [ "Enedis",[1.6501798149455313, 47.258566930598576]],
    [ "Enedis",[1.6508598414044748, 47.25245882320108]],
    [ "Enedis",[1.6434487113049518, 47.2483772762679]],
    [ "Enedis",[1.651477368643195, 47.25195089558058]],
    [ "Enedis",[1.6570716883346266, 47.25538520792797]],
    [ "Enedis",[1.6398266401160648, 47.24570194155924]],
    [ "Enedis",[1.6476348469035411, 47.24881151456932]],
    [ "Enedis",[1.6542392266311747, 47.25410716978037]],
    [ "Enedis",[1.643803149032662, 47.253702876203484]],
    [ "Enedis",[1.6666022789429966, 47.255076826634905]],
    [ "Enedis",[4.871701931590288, 45.747231909619]],
    [ "Enedis",[5.0825052500000005, 45.0472119]],
    [ "Enedis",[5.0795594, 45.0503639]],
    [ "Enedis",[4.976828960479887, 44.85709561669254]],
    [ "Albert Maignan",[0.2046062717631973, 48.00525459686037]],
    [ "Enedis",[0.05186750000000001, 47.1776242]],
    [ "Enedis",[0.04885987960216658, 47.18393547474255]],
    [ "Enedis",[3.1525057915184225, 50.60707370544037]],
    [ "Enedis",[2.7959678499999994, 49.43653465]],
    [ "Oiseleurs",[5.865809211359999, 48.68248997045334]],
    [ "Thouvenot",[5.86443745, 48.6812486]],
    [ "Enedis",[5.8718151193087875, 48.680053851807]],
    [ "Enedis",[5.8878798, 48.67946249999999]],
    [ "SNCF Réseau",[0.39258905632827706, 46.740768163737854]],
    [ "Enedis",[0.3062362049942067, 46.73041818423887]],
    [ "SNCF Réseau",[0.350645405045373, 46.645509612496454]],
    [ "RTE",[1.2173682829096648, 43.288517988152066]],
    [ "Jean Bouin",[0.19444521492103997, 48.0174510169115]],
    [ "HLM Alençon",[0.18341246401834438, 48.02292982634129]],
    [ "Rhin et Danube",[0.1840183, 48.02069535]],
    [ "Enedis",[6.399523344091473, 48.691413817812425]],
    [ "réséda",[6.273566136579893, 49.09286942805762]],
    [ "Enedis",[2.810642566757917, 49.43392775041291]],
    [ "Enedis",[2.7969354841002807, 49.43952085354819]],
    [ "Enedis",[2.796901015004062, 49.43954845295132]],
    [ "Enedis",[2.8020273654132155, 49.43682600327245]],
    [ "Enedis",[2.793143, 49.43457994999999]],
    [ "Enedis",[6.095394750000001, 45.93507635]],
    [ "Enedis",[6.08583805, 45.934350649999985]],
    [ "Ornex Bourg",[6.098347143013954, 46.272360850582004]],
    [ "Market",[2.5904339338179874, 48.870150541464724]],
    [ "Cerise",[2.5902422906857616, 48.87011977525323]],
    [ "Noix de Coco",[2.5902424835311675, 48.870081366856034]],
    [ "Pugliese",[3.0104757, 42.72710805]],
    [ "STIC",[7.0737191, 43.61392825]],
    [ "Village",[5.97234993201129, 48.5731379456827]],
    [ "Enedis",[5.9393824, 48.582002349999996]],
    [ "Enedis",[5.94134845, 48.580999850000005]],
    [ "Demecy sur le Vault",[3.809781999999999, 47.4903725]],
    [ "Enedis",[3.73944255, 47.4597156]],
    [ "Monument",[3.7648161671471883, 47.4570750684325]],
    [ "Enedis",[-1.6752171694327553, 48.094399821547476]],
    [ "SNCF Réseau",[0.27843577593605107, 46.5685388014794]],
    [ "Enedis",[1.434320975975267, 43.01025956168633]],
    [ "Enedis",[1.3331931500000014, 43.001626300000005]],
    [ "Enedis",[1.280373265326817, 42.99553250531238]],
    [ "Enedis",[1.2843818500000002, 42.99645915]],
    [ "Enedis",[1.2849713190313077, 42.993820724749]],
    [ "Enedis",[1.43087905, 43.0124096]],
    [ "Enedis",[1.5111275499999988, 42.963919]],
    [ "Enedis",[1.5100459499999999, 43.052374650000004]],
    [ "Enedis",[5.870764116682202, 48.67005150568575]],
    [ "Enedis",[5.87111725, 48.66744215]],
    [ "SNCF Réseau",[0.21783146236767198, 46.48434904789467]],
    [ "Enedis",[4.764566450000001, 48.4649795]],
    [ "SRD",[0.6442916277934165, 46.56199979379174]],
    [ "Daudet",[2.591968284181883, 48.869082132516034]],
    [ "Drouault",[0.17618345000000113, 47.999916]],
    [ "Résidence Nova",[0.17418417815378248, 48.01266600982787]],
    [ "Presle",[0.1742385, 48.01402514999999]],
    [ "Leboindre",[0.19053694999999948, 47.99853260000001]],
    [ "réséda",[6.122943150000001, 49.17384645]],
    [ "Enedis",[2.448355549999999, 48.51312075]],
    [ "Enedis",[-1.5889031023880595, 46.412043847494104]],
    [ "PELASQUE",[7.280365778186568, 43.94745967211003]],
    [ "Greverend",[0.18559579999999998, 47.965200349999996]],
    [ "Succursale Renault",[0.17809769693752148, 47.97949573187222]],
    [ "Enedis",[0.1768468667766472, 47.97731619186145]],
    [ "Setram",[0.1754318068278122, 47.976743230032284]],
    [ "Pierre Martin n°1",[0.17099050000000002, 47.97214304999999]],
    [ "CEC",[0.17169615, 47.97100669999999]],
    [ "Thyssen",[0.17291047534112167, 47.96966988426354]],
    [ "La Chevallerie",[0.17446825000000127, 47.96829989999999]],
    [ "La Renardière",[0.17443576285053927, 47.969762670895484]],
    [ "Enedis",[-1.851638600322928, 46.61226658335128]],
    [ "Cité Capeyroux",[1.238581774854101, 43.95767150386832]],
    [ "Les Fregnards",[6.125004551972286, 45.97390462912429]],
    [ "Le Breuil",[5.854093319084342, 48.576776266115814]],
    [ "Enedis",[5.8514737000000006, 48.57429915]],
    [ "Enedis",[5.841680238261116, 48.544564475304455]],
    [ "Enedis",[2.972764760237077, 42.67628232448586]],
    [ "Enedis",[4.957391730721645, 44.873266378628365]],
    [ "Enedis",[4.940991449999999, 44.7995226]],
    [ "Enedis",[4.938242166859331, 44.8003673042386]],
    [ "Enedis",[4.93522991321897, 44.80358337904473]],
    [ "Enedis",[4.92040125, 44.81755505]],
    [ "Enedis",[2.8007970488586973, 42.850805707360145]],
    [ "Enedis",[6.0864796000000005, 48.53634325]],
    [ "Enedis",[6.091873850000001, 48.5341961]],
    [ "Enedis",[6.112333231002723, 46.25139057019747]],
    [ "Enedis",[2.3586117352792697, 49.74997344487568]],
    [ "Les Courtiaux",[3.1659474940807995, 45.707040416961085]],
    [ "Enedis",[0.9261962964624197, 45.9056876935723]],
    [ "Enedis",[0.9224072919609465, 45.9046614832133]],
    [ "Enedis",[1.5158944806041916, 43.487680338932684]],
    [ "Enedis",[1.5163892491245214, 43.48974666690523]],
    [ "Enedis",[1.5105465630141555, 43.490891966230436]],
    [ "Enedis",[1.5162950097748817, 43.49389433257801]],
    [ "Enedis",[1.517483, 43.496586349999994]],
    [ "Enedis",[-1.8867891, 46.6414128]],
    [ "Zone Artisanale",[-0.4947964500000002, 49.1039623]],
    [ "Haie Bruyère",[-0.49705579675228384, 49.107174186267365]],
    [ "920858538",[4.362050188144099, 48.965487818786734]],
    [ "920858568",[4.357830700000001, 48.965193150000005]],
    [ "Enedis",[1.4963173311243183, 43.52438459733431]],
    [ "Enedis",[1.507144, 43.49938230000001]],
    [ "Enedis",[1.5068002000000003, 43.50070945000001]],
    [ "Enedis",[1.5077823346525567, 43.50292562901173]],
    [ "Enedis",[1.5025810232289132, 43.50174919028672]],
    [ "Enedis",[1.5159487272841619, 43.501031137904526]],
    [ "Enedis",[1.515647774148036, 43.5028367195005]],
    [ "Enedis",[1.5151164664135257, 43.5054005405252]],
    [ "Enedis",[1.5015842, 43.50389945]],
    [ "Enedis",[1.5045063394719769, 43.50481642003294]],
    [ "Enedis",[1.5179827743055492, 43.50291198445072]],
    [ "Enedis",[1.5268226500000002, 43.497939599999995]],
    [ "Collège Ferney-Voltaire",[6.115274296732322, 46.26515176116876]],
    [ "Enedis",[1.5480857999999997, 43.47288154999999]],
    [ "Victimes",[0.21458808276732005, 48.012543146415254]],
    [ "Enedis",[2.399504045310647, 50.69409457402604]],
    [ "Enedis",[2.7863774711708644, 49.38482309317936]],
    [ "Enedis",[2.7890394701786816, 49.38405193234749]],
    [ "Enedis",[-0.5423243677238972, 44.71493615282855]],
    [ "Enedis",[-0.5302492820684079, 44.72292397555077]],
    [ "Enedis",[6.067738348351737, 46.30050596126505]],
    [ "Nouveau Quartier",[6.069967723953387, 46.29460897135021]],
    [ "Enedis",[6.078005652198505, 46.296618701952646]],
    [ "Journans",[6.064931100000001, 46.298980799999995]],
    [ "Les jardins de Ségny",[6.07353040649286, 46.29264736666882]],
    [ "Rossanets",[6.076591783404284, 46.29137040058634]],
    [ "Enedis",[2.787393928409652, 49.38843628253111]],
    [ "Salle polyvalente 54528P0111",[5.888688149999998, 48.6656284]],
    [ "Grosnière",[5.874344645646825, 48.74165997157988]],
    [ "Village",[5.880690120353951, 48.741550455239825]],
    [ "Calvaire",[5.832936749999999, 48.733852799999994]],
    [ "Enedis",[5.8580791, 48.7055256]],
    [ "Route de Bruley",[5.845685850000001, 48.6942159]],
    [ "Pivoine",[5.846560950000001, 48.69372465000001]],
    [ "Enedis",[5.848233806903392, 48.69079988416823]],
    [ "Le Mulson",[5.850181844067454, 48.69115113196638]],
    [ "Enedis",[4.9615921, 44.8392741]],
    [ "Grand Frais",[6.094682089584476, 46.244579807450855]],
    [ "Enedis",[6.1174150484033385, 46.25535478503651]],
    [ "ajouts divers",[3.086261955701618, 50.74011771381328]],
    [ "Enedis",[6.027541700000001, 46.247817999999995]],
    [ "Enedis",[2.9730346194461226, 50.41235865659129]],
    [ "Enedis",[1.5202157856144571, 43.49498619397783]],
    [ "Vert Tilleul",[6.0781779, 46.24862525]],
    [ "Les Allées fleuries",[6.071882155071042, 46.252164884617244]],
    [ "Aire accueil",[6.056086284167365, 46.24896294413253]],
    [ "Domaine",[6.072748879008809, 46.24986874819251]],
    [ "Enedis",[4.774494750000001, 43.93003014999999]],
    [ "Enedis",[2.4679842699259527, 48.49844871917743]],
    [ "Coubertin",[-0.49154404999999995, 47.46971819999999]],
    [ "Enedis",[-2.3620601042173086, 47.55127098818275]],
    [ "Enedis",[2.8594530500000004, 50.27118240000001]],
    [ "ETS René Mandrillon",[5.924514517015344, 46.58308540362623]],
    [ "Enedis",[-0.58597875, 47.474066050000005]],
    [ "Fleming",[-0.6054709000000013, 47.47864834999999]],
    [ "Lakanal",[-0.6020059657459554, 47.47782872420667]],
    [ "Enedis",[-0.60701295, 47.477104849999996]],
    [ "Packard Bell",[-0.6025244466066322, 47.47294092104271]],
    [ "Enedis",[6.123239616957702, 45.909818221036716]],
    [ "Perouses",[6.122349299999999, 45.9110796]],
    [ "Enedis",[6.125124503915684, 45.911432264153085]],
    [ "Enedis",[6.129664461169788, 45.9087329856448]],
    [ "ZA Canals",[1.2952361673009487, 43.85168762598733]],
    [ "AS prat",[1.2955430846548173, 43.854153703336515]],
    [ "Enedis",[1.0693138103459126, 46.6399511786846]],
    [ "RIPS",[5.1306695, 45.600675100000004]],
    [ "Pavé",[0.18452595245362427, 48.00765094251682]],
    [ "Suifferie Impasse",[0.17735657728886284, 48.0063671416195]],
    [ "Libération",[0.18566438974582547, 48.003500058825026]],
    [ "Enedis",[3.1619417500000004, 45.6700616]],
    [ "Enedis",[5.833368317611561, 48.59987140329963]],
    [ "Enedis",[6.1189628025364415, 45.906699493765345]],
    [ "Enedis",[3.139011276600377, 50.61631279924177]],
    [ "Enedis",[2.47628735, 50.67268180000001]],
    [ "Route d'Heyrieux",[5.090119169192843, 45.595350759355405]],
    [ "Enedis",[2.7872522035700222, 49.39282238507459]],
    [ "Enedis",[5.954054748541797, 46.21401864028383]],
    [ "Enedis",[2.8405185149102277, 50.10405255956215]],
    [ "Enedis",[2.3511918069163746, 48.820039329021824]],
    [ "Enedis",[2.9124928721088432, 42.83106635953741]],
    [ "Coop Claircigny",[0.21399754132364399, 47.997766099967784]],
    [ "DP Lurçat",[0.2154958275490084, 47.9964560097847]],
    [ "Enedis",[5.978051600000001, 46.23471010000001]],
    [ "Enedis",[2.8475715231673098, 50.1093596434868]],
    [ "Enedis",[3.0698489463675527, 50.59362258433561]],
    [ "Enedis",[2.971001899999999, 50.49768125000001]],
    [ "Enedis",[2.964034762062244, 50.497670211001875]],
    [ "Enedis",[2.6493053499999997, 50.4401084]],
    [ "Enedis",[1.1512543258141266, 43.5007644357787]],
    [ "Enedis",[1.5248523828932652, 43.484458100685636]],
    [ "Enedis",[1.532864542666429, 43.490004282047614]],
    [ "Enedis",[1.5324467469510183, 43.490292584228804]],
    [ "SNCF Réseau",[0.19702539798080707, 46.399365946763545]],
    [ "Enedis",[1.7550542683897152, 50.4825755986793]],
    [ "Enedis",[0.6743407499999999, 44.45780014999999]],
    [ "Enedis",[0.73589355, 44.40999084999999]],
    [ "Enedis",[0.6699964557803659, 44.404779842018456]],
    [ "Enedis",[0.7130414670639247, 44.37762721912049]],
    [ "Enedis",[0.6874090859823674, 44.403886874369434]],
    [ "Enedis",[0.6829065134973765, 44.391059471178025]],
    [ "Enedis",[-1.4036949749807546, 46.5878465947652]],
    [ "Montaigne",[0.21474995979892483, 48.015891695524694]],
    [ "Enedis",[1.5345735999999999, 43.49107]],
    [ "Lavaur 2",[1.9166328812137072, 44.94156082408933]],
    [ "Zamenhof",[-1.53593895, 47.20422565]],
    [ "Busway BLI",[-1.5360130390823108, 47.20419878224839]],
    [ "SNCF Réseau",[0.1405385967922291, 46.21955927871312]],
    [ "Enedis",[4.266093276817964, 45.254289675627014]],
    [ "LISEA",[-0.44514845244085094, 44.958323548217784]],
    [ "LISEA",[-0.41793309488935865, 45.01814276203843]],
    [ "LISEA",[-0.36791600749174186, 45.094445993280246]],
    [ "LISEA",[-0.17177103571444646, 45.20686835726946]],
    [ "SNCF Réseau",[-0.09241517038938037, 45.281906509540136]],
    [ "SNCF Réseau",[-0.031002297250956608, 45.33477095724913]],
    [ "LISEA",[0.005454245787514458, 45.42123742391788]],
    [ "LISEA",[0.04590773644510579, 45.47582339551152]],
    [ "LISEA",[0.0611245153439383, 45.54769902291177]],
    [ "LISEA",[0.07137556903834674, 45.65418438363166]],
    [ "Enedis",[3.1316874362272347, 50.60675806426144]],
    [ "LISEA",[0.10124962028518329, 45.832157986317256]],
    [ "LISEA",[0.1283920284788481, 45.909743177234745]],
    [ "Enedis",[2.846171103269867, 49.43283798211679]],
    [ "LISEA",[0.12402129966888975, 46.00619589798445]],
    [ "Épine Saint-Blaise",[0.24217129999999856, 48.006789749999996]],
    [ "LISEA",[0.1285840758657926, 46.11793795249589]],
    [ "Oiselières",[0.2371687088216763, 48.013363532524686]],
    [ "Savorgnan de Brazza",[0.22302660271468738, 48.01221796489557]],
    [ "Les Grands Bois",[0.22038844301166263, 48.00946312172429]],
    [ "Pompes",[0.21319487095827908, 48.011429481414204]],
    [ "Enedis",[1.5298433301218433, 43.62597545752824]],
    [ "Clos de Marolles",[0.22908797409402532, 48.00883200329019]],
    [ "SNCF Réseau",[3.048465682242291, 50.540326949646506]],
    [ "SNCF Réseau",[2.9805859823606955, 50.480841363439595]],
    [ "SNCF Réseau",[2.9827676500000004, 50.40660704999999]],
    [ "SNCF Réseau",[2.8529323, 50.238120699999996]],
    [ "Enedis",[0.20337645397448462, 48.00161678014394]],
    [ "SNCF Réseau",[2.8722345000000002, 50.06312095]],
    [ "SNCF Réseau",[2.850553173025706, 49.923691183185234]],
    [ "SNCF Réseau",[2.7601081, 49.65017875]],
    [ "SNCF Réseau",[2.741385578098929, 49.24865924967181]],
    [ "SNCF Réseau",[2.7247867405801176, 49.12168347098497]],
    [ "SNCF Réseau",[2.583267468063902, 48.9819845867051]],
    [ "SNCF Réseau",[2.7285760056190935, 48.93550552914494]],
    [ "Sous Station de Saint-Cyr",[2.068489778554026, 48.79783920322066]],
    [ "SNCF Réseau",[2.9918884801557843, 49.03425638289174]],
    [ "SNCF Réseau",[3.804948536957768, 49.219081453197425]],
    [ "SNCF Réseau",[3.9687329374960534, 49.22397324609679]],
    [ "SNCF Réseau",[4.112388323114118, 49.18552864789279]],
    [ "SNCF Réseau",[6.409219787080853, 48.95941740020463]],
    [ "SNCF Réseau",[6.648866249999999, 48.904570099999994]],
    [ "SNCF Réseau",[6.79736771902894, 48.86255658284012]],
    [ "SNCF Réseau",[6.916684800000001, 48.80669619999999]],
    [ "SNCF Réseau",[7.261792150000001, 48.77727285000001]],
    [ "SNCF Réseau",[7.377662258140564, 48.78017507687064]],
    [ "SNCF Réseau",[7.5998648000000015, 48.7081769]],
    [ "SNCF Réseau",[2.7360762999999997, 48.97123214999999]],
    [ "SNCF Réseau",[2.7202492674589074, 50.705813799618184]],
    [ "SNCF Réseau",[2.5479416500000003, 50.75793575]],
    [ "SNCF Réseau",[2.3537929373513222, 50.80775313784396]],
    [ "SNCF Réseau",[1.9769351464711211, 50.8189148345154]],
    [ "SNCF Réseau",[3.141340774671964, 50.58566638538443]],
    [ "Enedis",[1.5020482475612569, 43.48544113545529]],
    [ "Enedis",[1.50533435, 43.48345235]],
    [ "Clinique Delagenière",[0.20024864824754993, 48.01287718266339]],
    [ "Girandière",[0.20526895000000003, 48.01487595000001]],
    [ "SNCF Réseau",[2.784979520532874, 48.88277525834568]],
    [ "SNCF Réseau",[2.8064630500000005, 48.7839099]],
    [ "SNCF Réseau",[2.720072084537366, 48.59796774553663]],
    [ "SNCF Réseau",[3.265666482620585, 48.28902374706304]],
    [ "SNCF Réseau",[4.13063975, 47.5493229]],
    [ "SNCF Réseau",[4.218031995433649, 47.41557784467548]],
    [ "SNCF Réseau",[4.343250817601706, 47.144804819168904]],
    [ "SNCF Réseau",[4.547935017078011, 46.69765192858089]],
    [ "SNCF Réseau",[4.666715450000001, 46.47187149999999]],
    [ "SNCF Réseau",[4.69206785, 46.35980065]],
    [ "SNCF Réseau",[4.8427603999999995, 46.1532661]],
    [ "SNCF Réseau",[5.08013035, 45.7840864]],
    [ "SNCF Réseau",[5.06771775, 45.517131549999995]],
    [ "SNCF Réseau",[5.0452786, 45.46745425000001]],
    [ "SNCF Réseau",[5.022861508193841, 45.42946731916642]],
    [ "SNCF Réseau",[4.985861450000001, 45.3172204]],
    [ "SNCF Réseau",[4.959320646315565, 45.26756711160171]],
    [ "SNCF Réseau",[4.92884085, 45.21329305000001]],
    [ "SNCF Réseau",[4.918682736023118, 45.17029125825192]],
    [ "SNCF Réseau",[4.933769102358436, 45.12173598176066]],
    [ "SNCF Réseau",[4.951095797711317, 45.07678181871378]],
    [ "SNCF Réseau",[4.96526489371944, 45.03649293703285]],
    [ "Enedis",[3.1670146646703863, 50.60817061988566]],
    [ "Enedis",[3.1667866999999985, 50.607006899999995]],
    [ "SNCF Réseau",[5.0030487, 44.925725850000006]],
    [ "SNCF Réseau",[5.00566765, 44.83923060000001]],
    [ "SNCF Réseau",[5.003930320089325, 44.772463431207406]],
    [ "SNCF Réseau",[4.889448693969139, 44.62324656349586]],
    [ "SNCF Réseau",[4.787542048361866, 44.514404497590256]],
    [ "SNCF Réseau",[4.738029346492038, 44.395059177607884]],
    [ "SNCF Réseau",[4.7642608860892945, 44.13690663678967]],
    [ "SNCF Réseau",[4.835822699999999, 43.91560885]],
    [ "SNCF Réseau",[5.1421111649339215, 43.73702910884612]],
    [ "SNCF Réseau",[4.668134532422708, 43.92294469200153]],
    [ "SNCF Réseau",[5.482187649999999, 47.22753885]],
    [ "SNCF Réseau",[5.796740847560582, 47.280600099151336]],
    [ "SNCF Réseau",[5.975399213499771, 47.31467681268258]],
    [ "SNCF Réseau",[6.26579057837288, 47.48946061361999]],
    [ "SNCF Réseau",[6.547273374265659, 47.54788094781269]],
    [ "SNCF Réseau",[6.714209199999999, 47.55713204999999]],
    [ "SNCF Réseau",[6.876180891262204, 47.58020036725349]],
    [ "SNCF Réseau",[6.943393245846043, 47.60205587923592]],
    [ "SNCF Réseau",[-0.17429763073223037, 47.91141467750356]],
    [ "Petite Andouillerie",[0.20863934260001304, 48.02749202544137]],
    [ "Molière",[0.20329772294661763, 48.0216887532159]],
    [ "Nice",[0.19695018083333263, 48.02261350005557]],
    [ "SRD",[0.6384875, 46.55502065]],
    [ "Provence",[0.19182102863157288, 48.028786878826935]],
    [ "Mach’Tel",[0.19678950424779978, 48.026575328211585]],
    [ "SNCF Réseau",[3.3229303927729648, 45.99163662766498]],
    [ "Gaudine",[0.20230860000000006, 48.0330895]],
    [ "Rue des Vignes",[0.20924195000000004, 48.026681450000005]],
    [ "Enedis",[3.161017671020055, 50.61437645966529]],
    [ "Enedis",[3.153137140071958, 50.603035119473]],
    [ "Enedis",[3.152953884996677, 50.60476360198759]],
    [ "928823147",[2.2460713924309803, 48.72600358287012]],
    [ "Le Crottet",[5.04071175, 45.797795949999994]],
    [ "Enedis",[4.8278886297267345, 45.76811320043191]],
    [ "Enedis",[1.407562752433049, 43.78413943397382]],
    [ "Souche",[4.202759585877138, 43.7826801186493]],
    [ "Chaffre",[4.203273594735965, 43.78310177865744]],
    [ "Collège Bardin 454",[4.2002713499999995, 43.782676099999996]],
    [ "Bourg",[0.19114009999999998, 48.037464299999996]],
    [ "Aillande",[0.20927079999999862, 48.041608749999995]],
    [ "Enedis",[2.9324577001664056, 42.681586668614806]],
    [ "Enedis",[2.78382035, 50.42346204999999]],
    [ "Enedis",[3.1627754486490187, 50.59236599105599]],
    [ "Enedis",[3.1655798736710357, 50.594976777482955]],
    [ "Enedis",[3.1636676937402197, 50.58896468231611]],
    [ "Enedis",[3.1621788051768465, 50.59061541950376]],
    [ "Enedis",[1.5357267605232467, 43.62956996519843]],
    [ "Enedis",[-3.4808880374326567, 48.606489631592936]],
    [ "Enedis",[1.4392283947026498, 43.698630919990855]],
    [ "Enedis",[2.8354435825764503, 50.651119147790304]],
    [ "Enedis",[1.622780739422779, 43.35686616818271]],
    [ "Enedis",[1.6191469166927908, 43.35839924996737]],
    [ "Gounod 1",[0.1658077000000015, 47.96954595]],
    [ "Chabrier",[0.16298344999999878, 47.97146875000001]],
    [ "Enedis",[4.308217966324945, 48.876231295606445]],
    [ "Enedis",[4.313966153917985, 48.880908383659275]],
    [ "Cantaranne",[2.608081299999999, 44.36133720000001]],
    [ "Le Clos Pasteur",[0.15647875, 47.96716490000001]],
    [ "Tunisie",[0.16340090000000002, 47.950570850000005]],
    [ "Enedis",[3.167413776341517, 50.59254160359204]],
    [ "Enedis",[3.1675255245809883, 50.591106153958066]],
    [ "Enedis",[3.171665231554019, 50.59066736152073]],
    [ "Enedis",[3.167294818606515, 50.58911240766205]],
    [ "Enedis",[-3.5738277499999995, 48.5187614]],
    [ "Enedis",[1.6097917499999999, 43.38564494999999]],
    [ "Enedis",[2.8816588999999997, 49.42615580000001]],
    [ "Enedis",[2.83955195, 49.422466299999996]],
    [ "Enedis",[2.844667222050468, 49.42214780777924]],
    [ "Enedis",[1.6275441121890197, 43.359824770913704]],
    [ "Enedis",[1.6238926250141483, 43.360303740464055]],
    [ "Enedis",[1.624702631715116, 43.36084490207372]],
    [ "Enedis",[2.337653235937602, 48.66337913178339]],
    [ "Enedis",[3.1063834116521374, 50.52574777243444]],
    [ "Pâquerettes",[0.1577274999999984, 47.9722646]],
    [ "Enedis",[3.111615484962545, 50.52586405345052]],
    [ "Enedis",[3.1099140450239013, 50.523908729611705]],
    [ "Enedis",[3.1157533183879247, 50.52560545337546]],
    [ "Enedis",[3.1133848718644517, 50.52433651566606]],
    [ "Enedis",[2.427435865255282, 48.687818303482636]],
    [ "Enedis",[2.355996657085235, 48.64639441675671]],
    [ "Enedis",[3.118693321280812, 50.520993431899676]],
    [ "Enedis",[0.46207505, 47.252955]],
    [ "Enedis",[3.11831805, 50.51582235]],
    [ "Enedis",[3.1200406532842693, 50.518503413742124]],
    [ "Enedis",[3.1172902161716163, 50.52272494755601]],
    [ "Enedis",[3.127577619738254, 50.518905384495014]],
    [ "Le Carrefour",[0.14034230637936526, 48.00657622137638]],
    [ "Grandes Bourdonnières",[0.1314698000000001, 48.00549454999999]],
    [ "La Forge",[0.12985491687785228, 48.00078541662273]],
    [ "Enedis",[2.8385457589673417, 49.4271481495373]],
    [ "Enedis",[2.8410209380029294, 49.43125686436437]],
    [ "Enedis",[2.8419034000000005, 49.4316988]],
    [ "Enedis",[2.637834267202454, 50.406387117747556]],
    [ "931751122",[3.1212744389118887, 43.86260903997015]],
    [ "Enedis",[2.946845832202728, 50.24984224783081]],
    [ "Enedis",[4.885625130050854, 44.88779774312342]],
    [ "Enedis",[2.833323833865983, 50.32353236479539]],
    [ "Enedis",[2.835997255903128, 50.32476211584258]],
    [ "Enedis",[5.898488250000001, 45.65872595]],
    [ "RTE",[3.0891036225758404, 49.94683040279553]],
    [ "Enedis",[3.1070315848245142, 49.91643700269774]],
    [ "Maine Denrée 2",[0.18211842852979324, 47.96070419103339]],
    [ "Chromex",[0.17977330000000125, 47.960327199999995]],
    [ "Les Halles",[0.18605614999999998, 47.9571224]],
    [ "Les Pêchers",[0.1881871620751631, 47.94623291999658]],
    [ "Enedis",[6.383590970896081, 46.88700142315576]],
    [ "Enedis",[1.4934595000000002, 43.47653075]],
    [ "Enedis",[1.5019674500000002, 43.4758602]],
    [ "Enedis",[1.2046116106407723, 49.268825251675835]],
    [ "Enedis",[3.1795420402861554, 50.528864693424644]],
    [ "Huardière",[0.20253250000000003, 47.89662895000001]],
    [ "Enedis",[3.1696076056615436, 50.529543469655685]],
    [ "Enedis",[3.1723011192172503, 50.5304578202412]],
    [ "Enedis",[3.1690292166666665, 50.52540690000001]],
    [ "Enedis",[3.1722601329493028, 50.53271297999182]],
    [ "Enedis",[3.174595450000001, 50.52816715]],
    [ "Enedis",[4.9323932173556, 44.853952707280385]],
    [ "Enedis",[2.4372967999999995, 50.6629569]],
    [ "Enedis",[2.8116344499999992, 50.50731819999999]],
    [ "Enedis",[2.8385413537113577, 50.508131374144014]],
    [ "Enedis",[2.8607732299368234, 50.51766930578116]],
    [ "Enedis",[2.8465671357722204, 50.48580792717463]],
    [ "Enedis",[2.768725812166396, 50.43459397796815]],
    [ "Enedis",[2.87508216819459, 50.40174624510818]],
    [ "Enedis",[3.041098017786712, 50.432235250984284]],
    [ "Enedis",[0.572918433389555, 49.52330019921992]],
    [ "Enedis",[1.2058015990036144, 49.269487632912]],
    [ "Enedis",[2.3749106211157214, 48.52442157194559]],
    [ "Enedis",[1.2094619815108194, 49.262108091220576]],
    [ "Enedis",[3.1209221562524134, 50.62094637837343]],
    [ "Les Bousquets",[1.260871333816036, 44.20792765124175]],
    [ "Enedis",[2.8199526999999995, 49.4369767]],
    [ "Vallon Robin des Bois",[0.14565269488856042, 48.02068760120616]],
    [ "Enedis",[6.224438325686857, 46.17712078212955]],
    [ "Enedis",[3.31390235, 50.35925805]],
    [ "Enedis",[2.2183812848208113, 49.38880658270505]],
    [ "Enedis",[2.6071169500000004, 50.522045750000004]],
    [ "Enedis",[2.68214968858462, 50.49722997992795]],
    [ "Enedis",[2.73693145, 50.50849199999999]],
    [ "Lot du Grand Clot",[-2.8209233115609096, 48.57269933700706]],
    [ "Enedis",[0.6780191487005621, 47.36951108352914]],
    [ "Enedis",[4.928904624509386, 44.95425338855857]],
    [ "Pins",[0.24545368020594824, 47.9123010749621]],
    [ "Hermitage",[0.23807337784379762, 47.90727149132704]],
    [ "Bonen",[0.24725057561704245, 47.913508507900644]],
    [ "Enedis",[3.4126856528345724, 49.04110762581062]],
    [ "Debussy",[2.8277062500000003, 49.432011749999994]],
    [ "Enedis",[-3.2390680213796235, 47.79535580922851]],
    [ "Enedis",[-3.2390554938949876, 47.79539857134824]],
    [ "Enedis",[2.7431463010280117, 50.47195166161244]],
    [ "Enedis",[2.8569293679628807, 50.48666950663915]],
    [ "Enedis",[2.8478874651579145, 50.4864092530749]],
    [ "Enedis",[2.841466527375526, 50.44681316582055]],
    [ "Enedis",[2.4331598000000003, 48.5714504]],
    [ "Enedis",[2.8746958500000006, 50.40681889999999]],
    [ "Enedis",[3.1364403221607273, 50.557745812040004]],
    [ "Enedis",[3.1474720853097615, 50.55953592014814]],
    [ "Enedis",[2.8464015500000004, 50.35506249999999]],
    [ "Enedis",[2.7536401000000006, 50.483498850000004]],
    [ "Enedis",[2.8905811010571614, 50.327079672274934]],
    [ "Enedis",[1.4486169000000002, 47.434158200000006]],
    [ "Enedis",[1.446021649999999, 47.4349705]],
    [ "Enedis",[3.085849596641662, 50.61082532922376]],
    [ "Enedis",[6.00228111916198, 47.2278652789544]],
    [ "Enedis",[2.6360284000000003, 50.51429405]],
    [ "Les Fontenelles",[0.23452322516382446, 48.029249252974765]],
    [ "Vieil Hêtre",[0.1616506, 48.01011615]],
    [ "Enedis",[3.2759900578622023, 49.77465846258782]],
    [ "Enedis",[0.1579183281862573, 48.01295835367834]],
    [ "CBM",[0.15682323231448686, 48.010293256918295]],
    [ "Care Pak",[0.15627166926717076, 48.0119397218359]],
    [ "Carmel",[0.15226107859276142, 48.012432867766094]],
    [ "Chanteloup",[0.14947075, 48.01340525]],
    [ "Dolmetsch",[0.14737045, 48.015504099999994]],
    [ "Enedis",[1.4234849904413491, 47.427079764049545]],
    [ "Enedis",[3.3147922211531244, 49.88212552671844]],
    [ "Enedis",[0.16425953594480563, 48.021737166386124]],
    [ "Montand",[0.16050597536268274, 48.022616111108604]],
    [ "Leprince Ringuet",[0.14279277832082948, 48.01786075314576]],
    [ "Foresterie",[0.1442523999999995, 48.01738385]],
    [ "Harel de la Noë",[0.14891594322966356, 48.017487406960626]],
    [ "Patinoire",[0.151493616265419, 48.01654088203318]],
    [ "Enedis",[-0.363222413378382, 43.305042765715676]],
    [ "Enedis",[6.9534584830898165, 46.0213950504799]],
    [ "CTTM",[0.1421508000000014, 48.015797250000006]],
    [ "Georges Auric",[0.15204607726919123, 48.015324761955405]],
    [ "Tusculan",[0.156174222234084, 48.01508407168409]],
    [ "Droit",[0.15977882271464344, 48.01625071630478]],
    [ "Technopolis",[0.16218459907372643, 48.019564706033286]],
    [ "Restaurant Universitaire",[0.1655396, 48.01559825]],
    [ "La Navière",[0.16437754945033115, 48.0168632991566]],
    [ "Enedis",[6.013326978542495, 47.264651506897316]],
    [ "Enedis",[-1.836643335034903, 46.673440232533125]],
    [ "SNCF Réseau",[5.230441848137298, 45.597852384150514]],
    [ "SNCF Réseau",[5.299683577845787, 45.5795989289683]],
    [ "SNCF Réseau",[5.343716872659285, 45.563100927059814]],
    [ "SNCF Réseau",[5.416808951467785, 45.55673175018838]],
    [ "SNCF Réseau",[5.488247213509969, 45.50499319521505]],
    [ "SNCF Réseau",[5.4448709, 45.470867250000005]],
    [ "SNCF Réseau",[5.425876649999999, 45.43236829999999]],
    [ "SNCF Réseau",[5.424750599712955, 45.39402192650305]],
    [ "SNCF Réseau",[5.4491242864497815, 45.34698848145475]],
    [ "SNCF Réseau",[5.500648298587205, 45.3679027553515]],
    [ "SNCF Réseau",[5.56086435, 45.3547658]],
    [ "SNCF Réseau",[5.594337275899506, 45.35211619582357]],
    [ "SNCF Réseau",[5.622326507074349, 45.2991286160218]],
    [ "SNCF Réseau",[5.658325301386244, 45.252491816111906]],
    [ "Enedis",[2.8133718978649047, 50.10618952361111]],
    [ "réséda",[6.13957529080254, 49.072795442714686]],
    [ "Enedis",[2.898555489100312, 49.22085422877566]],
    [ "Enedis",[-1.7430307500000006, 48.54194085]],
    [ "Enedis",[1.3368102500000005, 43.44105345]],
    [ "Enedis",[2.316674324942978, 44.19842095820439]],
    [ "Enedis",[2.396001008158644, 44.24801419574572]],
    [ "Cabiscol",[1.376667466112485, 44.00536660286151]],
    [ "Enedis",[1.350846031138546, 43.42819615160799]],
    [ "Enedis",[1.3520183499999996, 43.4271147]],
    [ "Enedis",[1.347724172265441, 43.42753310806224]],
    [ "Enedis",[1.3455806000000001, 43.42741354999999]],
    [ "Enedis",[1.34195903221813, 43.436841596359194]],
    [ "Enedis",[1.3519435330442215, 43.42966815486672]],
    [ "Enedis",[2.456477291347906, 50.74982791354728]],
    [ "Enedis",[1.6676863845285455, 50.36920127650209]],
    [ "Enedis",[2.8224867610055178, 42.61201796826077]],
    [ "Enedis",[1.6277531717413285, 50.39589375486827]],
    [ "Enedis",[1.3548343499999997, 43.423112100000004]],
    [ "Enedis",[1.353624953892543, 43.42317083236647]],
    [ "Enedis",[1.3591670000000002, 43.42534525000002]],
    [ "Enedis",[1.3570388999999998, 43.42471445]],
    [ "Enedis",[1.2873192999999998, 43.4376404]],
    [ "Enedis",[1.2923225500000002, 43.44841035]],
    [ "Enedis",[1.2903525661196742, 43.4457121204376]],
    [ "Enedis",[1.2917274157813787, 43.445297398146685]],
    [ "Enedis",[1.2933099160965802, 43.443697047664486]],
    [ "Enedis",[2.114703178279146, 50.775549180114425]],
    [ "Enedis",[2.9372460804476734, 42.68170409020007]],
    [ "Enedis",[3.0777640441184113, 50.600136467644]],
    [ "Enedis",[2.238745648618475, 48.684984383779764]],
    [ "Enedis",[2.326277248553983, 50.72004555133132]],
    [ "Enedis",[2.6909072128980442, 50.62516676087625]],
    [ "Enedis",[2.942452075235466, 42.689399146539515]],
    [ "SNCF Réseau",[4.2421108150558675, 43.71796476217583]],
    [ "SNCF Réseau",[4.390360240704482, 43.77863409555557]],
    [ "940356613",[2.74045417644249, 44.531875353097476]],
    [ "Enedis",[3.0977421830003866, 50.59335105033704]],
    [ "Enedis",[2.3677731177370256, 47.119663980287925]],
    [ "Enedis",[2.706685166518975, 48.002596251037055]],
    [ "Enedis",[3.0979260596027287, 50.603993177314734]],
    [ "Lac d'Issarlès",[4.0612981999999995, 44.820886949999995]],
    [ "Enedis",[3.23969150590479, 50.562540509965636]],
    [ "Enedis",[-3.566773732914808, 48.583811883951924]],
    [ "Enedis",[4.136863699999999, 43.5413874]],
    [ "Maison de Retraite",[4.06294305, 44.81614354999999]],
    [ "Enedis",[2.704810872386366, 47.99784685481397]],
    [ "Petit Chemin de la Sarre",[3.118004870630144, 45.77123365886949]],
    [ "Enedis",[1.1377184820965842, 49.02280244845701]],
    [ "Enedis",[5.4844848126999715, 46.67288137012243]],
    [ "Enedis",[5.478377, 46.709275]],
    [ "Enedis",[5.479962780066577, 46.70941449407488]],
    [ "Enedis",[2.4131661, 49.119867049999996]],
    [ "Enedis",[2.6934099783854903, 48.00785673509817]],
    [ "Enedis",[2.694639738477391, 48.01054141474326]],
    [ "Enedis",[2.701013394085366, 48.010372023678855]],
    [ "Sous-station de la section urbaine du T13 Express",[2.0772569546180963, 48.912826630754296]],
    [ "Enedis",[2.0810267665917386, 50.81665648332416]],
    [ "Enedis",[2.6733516204970877, 45.2275025900716]],
    [ "Enedis",[3.1241528768726683, 50.746976803021276]],
    [ "Enedis",[3.1178333155772116, 50.78105870373814]],
    [ "Enedis",[3.041446331321329, 50.76934852126976]],
    [ "Enedis",[2.837459115150094, 50.67452358281348]],
    [ "Enedis",[3.2123140167191293, 50.70583758305233]],
    [ "Enedis",[3.207509865737577, 50.70318621045449]],
    [ "Enedis",[0.8952795062504687, 42.92881366280692]],
    [ "Enedis",[3.2128741667451424, 50.305865279497816]],
    [ "Enedis",[-4.584583965182545, 48.269983368425635]],
    [ "Enedis",[6.689922883284959, 48.38811070046162]],
    [ "Enedis",[6.693649888533121, 48.390229723446055]],
    [ "EDF",[1.9515782603847605, 44.95324720197719]],
    [ "Enedis",[2.9440783386292875, 42.67709199256178]],
    [ "Enedis",[7.0202486173550085, 49.06796282684937]],
    [ "Fatines",[0.34863913357164283, 48.04579096003858]],
    [ "Taillis",[0.26601678431348147, 47.94026109657407]],
    [ "Enedis",[6.116557749999999, 45.915475099999995]],
    [ "Enedis",[6.11531095, 45.91558824999999]],
    [ "Chemin de Cos",[1.4312128534518536, 44.074212371101765]],
    [ "Enedis",[3.3824500000000004, 50.325937100000004]],
    [ "Enedis",[3.1580850122394533, 50.515452299073246]],
    [ "Enedis",[2.934179059074727, 50.57095113849559]],
    [ "Enedis",[3.4211098351069307, 50.11657507324944]],
    [ "Enedis",[3.400950219934116, 50.114531138017476]],
    [ "Enedis",[1.9385087698533305, 47.819258000191404]],
    [ "Enedis",[0.39696750720142465, 48.134183231130535]],
    [ "Enedis",[5.9225461500000005, 48.82809375]],
    [ "Enedis",[5.8653809, 48.67854665000001]],
    [ "Enedis",[5.866771620705347, 48.67887408737201]],
    [ "CM Réaumur",[2.2394806063096984, 48.777809680192995]],
    [ "Sommand",[6.55106815951424, 46.16048565773563]],
    [ "Frénot",[5.910298666666667, 48.69739350000001]],
    [ "Enedis",[5.934043625560371, 48.700588302862336]],
    [ "Enedis",[5.9170142, 48.70009385]],
    [ "Enedis",[2.9654374499999996, 50.41991650000001]],
    [ "Enedis",[5.8663853820674685, 48.6697050198428]],
    [ "Enedis",[3.541061735537614, 49.548519379755525]],
    [ "Enedis",[3.236183394110522, 50.702954116756025]],
    [ "Enedis",[3.238564318624616, 50.70552470373329]],
    [ "Enedis",[2.38254845, 48.52662885]],
    [ "Enedis",[2.7083210218033806, 49.82642851873536]],
    [ "Enedis",[3.1615861999999995, 50.72623705]],
    [ "Enedis",[3.1616628831053557, 50.72621985023786]],
    [ "Enedis",[3.53996975, 49.527493099999994]],
    [ "Enedis",[3.1782478561936918, 50.74695608384384]],
    [ "Sst2 zenith",[5.057280993747858, 47.35652387078527]],
    [ "Enedis",[1.8697830128833466, 50.932018719208074]],
    [ "Enedis",[4.785695057983731, 45.57212823612702]],
    [ "Enedis",[4.908774169642242, 45.71921639768033]],
    [ "Enedis",[3.105076533627452, 50.65853814701062]],
    [ "zagres",[4.711740600000001, 43.78968684999999]],
    [ "Enedis",[3.5287019499999994, 49.51194295]],
    [ "Bois Saint-Pierre",[5.103705550000002, 45.75855314999999]],
    [ "Enedis",[2.6947735, 49.814543900000004]],
    [ "Avenue du Golf",[-1.6004338204966035, 49.05065158592177]],
    [ "Enedis",[2.958669645018326, 50.59434485495459]],
    [ "Poste électrique de Bajatière",[5.745961685956102, 45.171881910268525]],
    [ "Poste électrique Abbaye",[5.745721700960089, 45.174204937141546]],
    [ "Enedis",[2.3517740326096104, 49.88714679545502]],
    [ "Rivet",[-0.7299724758610752, 45.07039380473651]],
    [ "Enedis",[3.151485544776686, 50.72253652953213]],
    [ "Enedis",[2.3419440000000016, 49.899493449999994]],
    [ "Enedis",[5.063744400000002, 45.750338549999995]],
    [ "La Belle Étoile",[0.15378216480115936, 47.97659733115402]],
    [ "Fougère",[0.14967784999999997, 47.977807649999995]],
    [ "Enedis",[3.554431899999999, 49.52916115]],
    [ "PN Place Molière",[-0.5547928000000001, 47.47404625000001]],
    [ "Quatuor",[5.049611600625962, 47.36481153137158]],
    [ "Les Murots",[4.794561091197183, 47.285348597065735]],
    [ "Enedis",[5.305393248269233, 43.459137660640614]],
    [ "Enedis",[-3.8345937, 48.643923650000005]],
    [ "Enedis",[4.082531767976377, 43.67573120114201]],
    [ "Grand Salan 500",[3.3523406710648205, 43.279893112450765]],
    [ "Enedis",[-1.2788932499999999, 46.15749555]],
    [ "Relevement Atalante",[-1.2956380874866311, 46.14632092174687]],
    [ "Enedis",[1.9926049499999998, 49.78394534999999]],
    [ "Poste de Saint Cassien",[6.841493444030711, 43.60161170828904]],
    [ "Poste source du Risso",[7.285155970013192, 43.707919891805524]],
    [ "Cristalou",[2.306430445548088, 48.94109146165937]],
    [ "Enedis",[3.1694531164752213, 50.58740485083403]],
    [ "Côteaux de la Gironde 2",[0.2157522, 48.024130850000006]],
    [ "Enedis",[3.157209429310002, 50.52975869699927]],
    [ "Enedis",[2.6438563295647333, 50.448314251660534]],
    [ "Enedis",[2.7206413152165703, 50.45616755143236]],
    [ "Enedis",[2.7394616156916856, 50.47926390804917]],
    [ "CA Pres Wilson",[2.3293956000000002, 48.79259185]],
    [ "Enedis",[2.9966332139306147, 50.43435923378563]],
    [ "Bocage",[-0.5833551, 47.50067555]],
    [ "La Girandière",[-0.5803434999999999, 47.49498394999999]],
    [ "6 juin 1940",[-0.5804906330456278, 47.495087791217536]],
    [ "Enedis",[-0.5769786070091779, 47.49628833365984]],
    [ "Charnaux",[-0.577448316514987, 47.49363100031241]],
    [ "Deroche",[-0.5763893000000002, 47.49418645]],
    [ "Lion",[-0.5757032705368289, 47.495089992509364]],
    [ "Plateau de la Mayenne",[-0.5753300739812288, 47.493765947065675]],
    [ "Yvette 5",[-0.5686821232798231, 47.491691604605464]],
    [ "Faucon",[-0.5593552693217475, 47.49280340275163]],
    [ "Boulestrau",[-0.5547330000000016, 47.49219185]],
    [ "Ravel",[0.16096934999999857, 47.9705735]],
    [ "Hardangère",[0.15254274999999962, 47.9619128]],
    [ "Solo",[2.445465917159444, 48.72612470196587]],
    [ "Corniche Louve",[6.45006345692751, 43.15605908758303]],
    [ "Enedis",[3.5703934030318556, 49.54425592338183]],
    [ "Enedis",[3.571152408186634, 49.54422712371346]],
    [ "Capucins",[-0.553022694470227, 47.49158734455819]],
    [ "Sauvage",[-0.557255708621098, 47.49110611191796]],
    [ "Amarante",[-0.5584445110565968, 47.488541696366184]],
    [ "Enedis",[-0.5747449, 47.489255150000005]],
    [ "Martyrs 5",[-0.5780684000600128, 47.49241636826826]],
    [ "Confidence",[-0.5726816999999993, 47.48048469999999]],
    [ "Meignanne 9",[-0.5722054250062373, 47.4799138978835]],
    [ "Rue du Conseil de l’Europe",[2.76165695, 50.425895600000004]],
    [ "RTE",[4.17483182943808, 49.34266847884829]],
    [ "Côteau Saint-Georges",[0.16428285253793984, 47.98635677753936]],
    [ "Elis Maine",[0.17665743205469284, 48.02729661858376]],
    [ "TV Ogec",[0.17783364999999995, 48.022898100000006]],
    [ "Enedis",[6.362163849999999, 46.26874924999999]],
    [ "Enedis",[2.848693243400407, 50.4496735678666]],
    [ "Enedis",[3.26496905, 50.10229975000001]],
    [ "Enedis",[3.2974643028239803, 50.101266366731956]],
    [ "Poste électrique de Tringue",[1.628090736928853, 50.50478066614044]],
    [ "Enedis",[-1.45060985, 46.568656149999995]],
    [ "Enedis",[2.478485316846928, 50.56284618949976]],
    [ "La Velle",[4.85686166248952, 47.30922037146145]],
    [ "Le Pont",[0.4663347, 47.930518049999996]],
    [ "Cimetiere",[-1.3142563484148424, 46.14766940109265]],
    [ "Groupe Scolaire",[-1.3225484620491643, 46.156355435049356]],
    [ "Enedis",[5.701478504314191, 45.99606104412919]],
    [ "Le Hameau Saint-Pavace",[0.19280771978259428, 48.04042321684363]],
    [ "Puyou",[3.081125859864588, 45.79579806840781]],
    [ "CPV Aussières",[2.740197200428534, 46.30336106702226]],
    [ "Enedis",[1.140361623730893, 43.55500267236343]],
    [ "Enedis",[2.6808702683907613, 49.08102603774887]],
    [ "Enedis",[2.6764264850239563, 49.07946307504457]],
    [ "Enedis",[2.9455681324463234, 42.669086949125905]],
    [ "Enedis",[6.732018401127868, 46.10324245731771]],
    [ "Enedis",[2.2483760439480287, 49.13880597410016]],
    [ "Enedis",[2.6628477280336598, 49.08150124391742]],
    [ "Enedis",[2.92415365, 42.688605100000004]],
    [ "Mare Boeufs",[0.23654615, 48.026857050000004]],
    [ "Calvaire",[0.24154741560102327, 48.02731170488942]],
    [ "Petites Bardonnières",[0.23346458501508902, 48.023917701814675]],
    [ "Bradonnières",[0.23955981866977774, 48.02434560598051]],
    [ "Enedis",[-1.1189782, 44.610007499999995]],
    [ "Enedis",[2.9213600039846566, 42.691309029199736]],
    [ "PAUL BERT",[-1.4712498, 43.4926694]],
    [ "BOUFFLERS",[-1.4712033500000008, 43.492647000000005]],
    [ "FETE FORAINE",[-1.4722884617183158, 43.49306308152892]],
    [ "FORAINS",[-1.4696044000000001, 43.491860849999995]],
    [ "Enedis",[-1.4518855000000002, 43.4937167]],
    [ "Malain IPT N 129",[4.799604252463264, 47.29802941649321]],
    [ "Enedis",[-1.398822468956967, 46.188532228982005]],
    [ "Enedis",[2.9489941381362943, 47.74690273753324]],
    [ "Enedis",[3.1754761564273397, 48.023568657017954]],
    [ "Enedis",[-1.4610399999999997, 43.490094899999995]],
    [ "Fangio",[0.17158700000000002, 48.0387986]],
    [ "Aigné",[0.1193975168423534, 48.06662821643263]],
    [ "Enedis",[2.6722385339826276, 49.07656171869467]],
    [ "Enedis",[3.0875752999999997, 50.66250050000001]],
    [ "Enedis",[3.2919641992965762, 48.21784586630872]],
    [ "Enedis",[3.301331446441379, 48.218127755653086]],
    [ "Enedis",[3.2997168153530656, 48.216198203443035]],
    [ "Enedis",[3.2994594449751125, 48.21437209973742]],
    [ "Maison Haute",[4.0820297, 46.062878]],
    [ "Rue d'Agey",[4.792766136285196, 47.29010173945174]],
    [ "Enedis",[2.365814791642371, 47.74903603542274]],
    [ "Prairie 130004",[4.715966350475228, 43.64743831648435]],
    [ "Bergers ",[4.71355222814627, 43.64605506375896]],
    [ "Enedis",[3.057662453235702, 50.41696389618349]],
    [ "Enedis",[3.2934023999999997, 48.206392199999996]],
    [ "Enedis",[3.2946778, 48.20516275]],
    [ "Enedis",[3.2979879285399387, 48.205616247051246]],
    [ "Enedis",[3.29922905, 48.20607929999999]],
    [ "Enedis",[2.193586990196275, 48.81323894366484]],
    [ "Enedis",[-0.8288716720627209, 45.578893659608276]],
    [ "Enedis",[2.18288465, 48.81122345000001]],
    [ "Enedis",[2.6784793149757333, 49.0698616554728]],
    [ "Enedis",[3.2942823999999997, 48.201083800000006]],
    [ "Enedis",[3.2802080716168676, 48.21632810352062]],
    [ "Enedis",[3.2844216520845726, 48.22350387203407]],
    [ "Enedis",[3.2799988829813342, 48.2231340714396]],
    [ "Enedis",[3.280776876227827, 48.220621827716805]],
    [ "Enedis",[3.2785901281213197, 48.21862948957597]],
    [ "Enedis",[1.9814368999148033, 49.99307758211395]],
    [ "Pavillon Ruaudin",[0.2298912000000011, 47.95535080000001]],
    [ "Zac des Hunaudières",[0.23269196522342742, 47.95228286675385]],
    [ "La Boulaie",[0.23860869999999998, 47.94899315000001]],
    [ "Enedis",[3.3152486678779973, 48.20260735711942]],
    [ "Enedis",[3.3163352, 48.20098089999999]],
    [ "Enedis",[3.285875420183516, 48.201263217992796]],
    [ "Enedis",[2.255464291743322, 46.08773435539827]],
    [ "Enedis",[3.2851862251406776, 48.20014739342736]],
    [ "Enedis",[3.277820534754946, 48.197590431080734]],
    [ "Enedis",[3.305199076803788, 48.17218920709844]],
    [ "Enedis",[3.307309810351154, 48.17615251882476]],
    [ "Enedis",[3.300869800000001, 48.17755785]],
    [ "Enedis",[3.3062116043991354, 48.17942391827475]],
    [ "Enedis",[3.3118492695366215, 48.177874991509725]],
    [ "Châtaigniers",[0.19001899531411887, 47.93827000334062]],
    [ "Enedis",[0.19504102878323662, 47.950270580147105]],
    [ "Électricité de France",[55.521314399999994, -20.9005176]],
    [ "Enedis",[3.3085679195237665, 48.21238445520223]],
    [ "Enedis",[-4.275229943883876, 48.48369607910624]],
    [ "Enedis",[-4.268769441657198, 48.484040971254935]],
    [ "Pointe de Kerpenhir",[-2.9342898499999994, 47.5592287]],
    [ "Général de Gaulle",[-2.9443715674802027, 47.569403400856935]],
    [ "Digue",[-4.165083017154245, 47.851880197659625]],
    [ "Enedis",[-4.157585269557644, 47.853690519971636]],
    [ "Best",[5.176354582850335, 45.617175750557045]],
    [ "Donchères",[5.175141549999998, 45.61761655]],
    [ "Enedis",[-4.2425155409030015, 48.46692407985073]],
    [ "Enedis",[0.11073581838526744, 48.42090641705186]],
    [ "Enedis",[0.11328971838164259, 48.42295168331289]],
    [ "Enedis",[3.2751550279790966, 48.212398471874025]],
    [ "Enedis",[3.2751255500000003, 48.210432]],
    [ "Enedis",[3.2786907000000003, 48.21374674999999]],
    [ "Enedis",[3.27865295, 48.21379300000001]],
    [ "Enedis",[3.273231650000001, 48.21380054999999]],
    [ "Enedis",[3.2869108639872784, 48.20884832098478]],
    [ "Enedis",[3.2797708500000002, 48.2090164]],
    [ "Enedis",[3.316184249999999, 48.19507485]],
    [ "Enedis",[3.31703835, 48.19838024999999]],
    [ "Enedis",[3.3202606500000003, 48.19935865]],
    [ "Enedis",[3.32007735, 48.199062299999994]],
    [ "Enedis",[3.3200077, 48.2024117]],
    [ "Agey",[4.765451279886418, 47.28395775244623]],
    [ "Enedis",[3.2900899000000012, 48.17015315]],
    [ "Enedis",[3.2892320999999995, 48.172399600000006]],
    [ "Enedis",[3.2925189500000003, 48.17496565]],
    [ "Enedis",[3.2887128292409824, 48.174932592096155]],
    [ "Enedis",[3.285342350000001, 48.173754900000006]],
    [ "Enedis",[0.1957153639841274, 48.007497593803585]],
    [ "Enedis",[5.9804111, 49.22310815000001]],
    [ "Enedis",[5.83502049482773, 49.326049471100035]],
    [ "Enedis",[4.92145285, 47.3148522]],
    [ "Enedis",[-1.0529547999008315, 45.64182077831101]],
    [ "Gesleries",[0.13139158838887308, 47.907672838574]],
    [ "Bourg",[0.12507714999999897, 47.89897065]],
    [ "Langa Solar",[0.13386512360466976, 47.91327155543564]],
    [ "Enedis",[1.970234451358122, 42.415441867152296]],
    [ "956574067",[1.41804115, 48.75574770000001]],
    [ "956596300",[3.884082989667216, 47.64033270468386]],
    [ "Enedis",[3.2765527774280954, 48.196115329399696]],
    [ "Enedis",[3.2745471560315065, 48.19815586449559]],
    [ "Enedis",[2.4293462963733807, 49.5113650683554]],
    [ "Enedis",[4.105670374662854, 45.99306304758948]],
    [ "Enedis",[3.3479460723789702, 48.21343119683904]],
    [ "Enedis",[3.34604725, 48.20910610000001]],
    [ "Enedis",[3.2618456500000006, 48.19937395000001]],
    [ "Enedis",[3.50878896494316, 50.3916741478996]],
    [ "957423841",[-1.6217342306683795, 48.135471635728784]],
    [ "Golf",[4.8157008455737715, 47.30688678491804]],
    [ "Enedis",[-0.3454439071758391, 43.3191908377262]],
    [ "Enedis",[2.7090116780882028, 50.64694617139421]],
    [ "Enedis",[3.2720722820158934, 48.21457432176217]],
    [ "Enedis",[3.2710988437308797, 48.21485222116568]],
    [ "Enedis",[3.2605249499999998, 48.189413749999986]],
    [ "Enedis",[3.317719688574596, 48.19112564103306]],
    [ "Enedis",[3.31589685, 48.192212399999995]],
    [ "Enedis",[3.311580826035923, 48.19226367184844]],
    [ "Enedis",[3.30769115, 48.19394960000001]],
    [ "Enedis",[-4.2772022000000005, 48.4532923]],
    [ "Bois Salève",[6.189454433901193, 46.16585846723452]],
    [ "Enedis",[3.2639500704265805, 48.230628263724135]],
    [ "Enedis",[1.9072011825989899, 42.45208653540355]],
    [ "Enedis",[1.907578480865834, 42.45928277154643]],
    [ "Enedis",[1.919989115850737, 42.45868626384257]],
    [ "Enedis",[3.3066628999999996, 48.18801765]],
    [ "Enedis",[3.3204287658559406, 48.18620474899695]],
    [ "Enedis",[3.321178113226606, 48.18981957200177]],
    [ "Enedis",[3.320570852126195, 48.19631769244889]],
    [ "Enedis",[3.2924575963015426, 48.19413366847135]],
    [ "Enedis",[3.2946287377469514, 48.19843312427133]],
    [ "Enedis",[3.301672507814071, 48.200331876403574]],
    [ "Enedis",[3.2893389, 48.17648225000001]],
    [ "Enedis",[3.2886655787871284, 48.17701419160074]],
    [ "Enedis",[3.288586800000001, 48.17717205]],
    [ "Enedis",[3.288859844826258, 48.178772069398214]],
    [ "Enedis",[3.2872571, 48.180947800000006]],
    [ "Enedis",[3.295632, 48.17896040000001]],
    [ "Enedis",[3.3262065370597766, 48.183289031778685]],
    [ "Enedis",[3.3342699618770477, 48.18136143227533]],
    [ "Enedis",[3.34104095, 48.17390185]],
    [ "Rue Halborg",[-1.59158695, 49.036989649999995]],
    [ "Le Bourg",[-1.5354628999999984, 48.96653379999999]],
    [ "Rue de la Mer",[-1.60142965, 49.09767135]],
    [ "Pêcherie de Normandie",[-1.6043080805848897, 49.10152099768771]],
    [ "Épuration",[-1.5925331659582462, 49.05477149436484]],
    [ "Enedis",[2.948526952145803, 42.81715236696423]],
    [ "Enedis",[2.8877906323893163, 42.821880213168676]],
    [ "Enedis",[2.8963197049257725, 42.844779652750155]],
    [ "Enedis",[2.924444003410852, 42.832171661749726]],
    [ "Enedis",[2.916256079659288, 42.830761038680336]],
    [ "Enedis",[2.919191698529411, 42.838671703431366]],
    [ "Enedis",[2.9229712798928715, 42.837611107949805]],
    [ "Enedis",[2.8470246139448214, 42.89674632345097]],
    [ "Enedis",[2.8457849935433366, 42.89868779505013]],
    [ "Enedis",[5.492206649999999, 46.7686552]],
    [ "Base de Loisirs",[5.483322250000001, 46.76161200000001]],
    [ "Enedis",[2.9531028, 42.52620525]],
    [ "Enedis",[3.3445536499999995, 48.17709245]],
    [ "Enedis",[3.3452933000000007, 48.17859370000001]],
    [ "Enedis",[3.3442177500000008, 48.1695451]],
    [ "Enedis",[3.3446357398996405, 48.17142422883536]],
    [ "Enedis",[3.32142391464252, 48.17272724653955]],
    [ "Enedis",[3.3197093420118886, 48.17635717975921]],
    [ "Enedis",[3.298314720620507, 48.191178384563]],
    [ "Enedis",[3.288357951603593, 48.18753138309134]],
    [ "Enedis",[0.14453285000000077, 49.4014222]],
    [ "Enedis",[2.918397343453232, 42.82331917889143]],
    [ "Enedis",[3.284185348872176, 48.18931422238943]],
    [ "Enedis",[-1.4149250721576057, 46.59640009684397]],
    [ "Enedis",[-1.3993690841715156, 46.59018352720008]],
    [ "Enedis",[1.6006267500000002, 43.44820615000001]],
    [ "Enedis",[3.085463987208413, 50.60774819045018]],
    [ "CESML",[3.8265505661277213, 43.64635382273407]],
    [ "CESML",[3.825383949550696, 43.64584746684733]],
    [ "CESML",[3.8279185499999993, 43.64470684999999]],
    [ "CESML",[3.8386798558680355, 43.64743936601089]],
    [ "Enedis",[-1.3767527423129629, 48.32449878560946]],
    [ "Margencel",[6.4318028027229355, 46.33780536705018]],
    [ "Enedis",[6.676928676278994, 47.070085120859304]],
    [ "Église",[6.464545818179775, 46.33653412410298]],
    [ "Enedis",[2.0345318, 42.38767225]],
    [ "Enedis",[1.997338460230583, 42.43976472020798]],
    [ "Enedis",[1.9933679499999999, 42.4401121]],
    [ "Secours Valloire",[6.448769505530577, 45.22329871586481]],
    [ "Enedis",[4.758580282173625, 45.416588551809134]],
    [ "Enedis",[3.0675383928999, 50.604501384890504]],
    [ "Enedis",[3.772407906034119, 43.5859127285126]],
    [ "Enedis",[1.83076585, 42.549258050000006]],
    [ "Moceas",[4.854497533391329, 47.30780749867749]],
    [ "Enedis",[1.9755406256581112, 42.41635939463517]],
    [ "Enedis",[1.9772918247311384, 42.41299574385763]],
    [ "Enedis",[1.9827445473579184, 42.416232891630266]],
    [ "Enedis",[1.9855229500000002, 42.41597345]],
    [ "Enedis",[1.9875313999999997, 42.41404895000001]],
    [ "Enedis",[3.0939673031883173, 50.60792319442637]],
    [ "Enedis",[3.0924149837209955, 50.606346941891296]],
    [ "Enedis",[1.5901800124642493, 50.8642664401144]],
    [ "Enedis",[3.097878156368883, 50.5941914201181]],
    [ "Enedis",[3.0894188499999995, 50.59096145]],
    [ "Enedis",[3.0893922, 50.59093064999999]],
    [ "Enedis",[3.0887721097059133, 50.58360303746619]],
    [ "Enedis",[3.0882655250956916, 50.58377412750587]],
    [ "Enedis",[3.0862175331931723, 50.58486819979309]],
    [ "Enedis",[2.935268825690997, 42.68513680593817]],
    [ "Enedis",[6.312953130634361, 48.27738368866485]],
    [ "Enedis",[6.488214299999998, 48.19940844999999]],
    [ "Enedis",[1.9729245317545838, 42.50022321421292]],
    [ "Enedis",[1.97255015, 42.5008248]],
    [ "Enedis",[1.9839664, 42.49987265000001]],
    [ "Enedis",[4.9500475999999995, 44.916764]],
    [ "Enedis",[4.96044622153877, 44.913506887110586]],
    [ "Enedis",[-4.2533705510060535, 48.45095693375235]],
    [ "Enedis",[3.0712119600128105, 50.592520083005255]],
    [ "Enedis",[3.0865328999999995, 50.58173150000001]],
    [ "Enedis",[3.0865583500000007, 50.58168615000001]],
    [ "Enedis",[3.0858045020121665, 50.58013857463301]],
    [ "Enedis",[1.1766360562474507, 43.487428701576526]],
    [ "Enedis",[3.060433801709851, 50.6130029016938]],
    [ "Enedis",[-0.38785378834544293, 43.309448760352524]],
    [ "Enedis",[2.1962105673142105, 49.923294347638056]],
    [ "Enedis",[6.4851334000000005, 46.34652705]],
    [ "Enedis",[3.08201215, 50.61419375]],
    [ "Enedis",[2.110496315977858, 49.218638353413766]],
    [ "Enedis",[3.0800281500000004, 50.5838915]],
    [ "Enedis",[-1.399431413155742, 46.58817802193356]],
    [ "Enedis",[3.0753418917404116, 50.613832480530974]],
    [ "Enedis",[4.115939129978536, 43.653346747319446]],
    [ "Enedis",[1.9224606251385812, 47.90140151413421]],
    [ "Enedis",[3.2714174999999996, 48.20472624999999]],
    [ "Enedis",[3.2686134499999993, 48.2086901]],
    [ "Enedis",[3.2697166999999983, 48.20402889999998]],
    [ "Enedis",[3.244538468659247, 48.27811790171237]],
    [ "Enedis",[3.24359855, 48.28356545]],
    [ "Enedis",[3.24459615, 48.28138404999999]],
    [ "Enedis",[3.241405381784103, 48.27902669204345]],
    [ "Enedis",[3.247526333303812, 48.27921244997146]],
    [ "Enedis",[6.485905499999999, 46.34316644999999]],
    [ "Enedis",[6.48660905, 46.34537634999999]],
    [ "RTE",[3.4583892600251134, 49.97992012250812]],
    [ "Enedis",[3.4490559, 49.97869214999999]],
    [ "Sousy",[6.111056143072676, 45.896524925508444]],
    [ "Enedis",[6.482455973700189, 46.33541005353155]],
    [ "SRD",[0.11202205203697821, 46.43206218241333]],
    [ "964352053",[6.276995024430252, 45.43337660873906]],
    [ "Les Etalons",[6.268530574451057, 45.40324685812171]],
    [ "964352068",[6.267258024907575, 45.382539979639944]],
    [ "Enedis",[1.7828178817435698, 49.28398222169932]],
    [ "Enedis",[-1.8306564722866856, 48.15252770504431]],
    [ "Enedis",[-1.8309556614940297, 48.153814034120934]],
    [ "Chez Berthelot",[-0.32468135, 45.4624031]],
    [ "Enedis",[0.2007942420219078, 48.15652986600912]],
    [ "Enedis",[0.2317606985959946, 48.15951622748754]],
    [ "Enedis",[2.428066224550934, 49.449019873262436]],
    [ "Enedis",[3.24890235, 48.27160315]],
    [ "Enedis",[3.2614403999999997, 48.26332454999999]],
    [ "Enedis",[3.2648330999999997, 48.25956789999999]],
    [ "Enedis",[3.2738474000000006, 48.2543292]],
    [ "Enedis",[3.2687191999999996, 48.25303535000001]],
    [ "Enedis",[3.2749467, 48.24761719999999]],
    [ "Enedis",[3.2082176999999996, 48.288387050000004]],
    [ "Enedis",[3.2085198539276867, 48.29001211662824]],
    [ "Enedis",[3.2102096500000004, 48.29325795]],
    [ "Enedis",[3.2408963178327723, 48.25021335663903]],
    [ "Enedis",[3.2397635676657845, 48.25169395671466]],
    [ "Enedis",[3.2454695499999997, 48.24399144999999]],
    [ "Enedis",[3.2332592519358556, 48.25831458240064]],
    [ "Enedis",[3.232494, 48.25935815]],
    [ "Enedis",[3.2256466499999994, 48.261175249999994]],
    [ "Enedis",[3.2142029164302692, 48.279092651634656]],
    [ "Enedis",[3.206667029336399, 48.28050303099854]],
    [ "Enedis",[3.2032763925195256, 48.27889507583149]],
    [ "Enedis",[3.2001882999999998, 48.278755849999996]],
    [ "Enedis",[3.19428375, 48.283380799999996]],
    [ "Enedis",[3.1903710811157087, 48.28424029726516]],
    [ "Enedis",[3.196983809815629, 48.295600970250305]],
    [ "Enedis",[3.199386750000001, 48.29401595]],
    [ "Enedis",[3.2007999500000004, 48.292576600000004]],
    [ "Enedis",[3.2029261776965767, 48.296013345171126]],
    [ "La Turbine à saveurs",[5.22834480098816, 45.60249043310305]],
    [ "Biofrais",[6.080210253830845, 45.93740673333333]],
    [ "Enedis",[3.1878295999999997, 48.301777449999996]],
    [ "Enedis",[3.1836093766438394, 48.299097332344104]],
    [ "Enedis",[3.181177915686141, 48.30048280703018]],
    [ "Enedis",[3.178637606465729, 48.30007528378888]],
    [ "Enedis",[3.181548525675599, 48.30912866073065]],
    [ "Enedis",[-0.760477, 48.075247850000004]],
    [ "Enedis",[4.3599319, 43.80782735]],
    [ "Enedis",[3.148991629841779, 48.31504586992826]],
    [ "Enedis",[3.133146585588892, 48.31723846772551]],
    [ "Enedis",[3.126842713477831, 48.31714546640677]],
    [ "Enedis",[3.121654282617047, 48.316739150974236]],
    [ "Enedis",[3.1261003964710157, 48.31993516574031]],
    [ "Enedis",[3.1212610499999993, 48.32164004999999]],
    [ "Enedis",[3.1211185, 48.32603135000001]],
    [ "Enedis",[3.107863587393297, 48.329749067185844]],
    [ "Place de la Mairie",[-0.056580330194022706, 48.04074476962896]],
    [ "Enedis",[-1.8330723961556572, 48.151539282091164]],
    [ "Enedis",[1.3409562500000003, 49.7315618]],
    [ "Enedis",[-0.5172298599701547, 47.14529777725134]],
    [ "Enedis",[3.0876505013551845, 50.620003432895004]],
    [ "Enedis",[3.0680831873274865, 50.601126656434836]],
    [ "Enedis",[1.9573473999999997, 47.278516]],
    [ "Enedis",[3.0979432792549613, 50.609093497238696]],
    [ "Enedis",[3.1030203029483934, 50.603908580785735]],
    [ "Enedis",[3.0589665808315836, 50.61453927496854]],
    [ "Enedis",[2.081424994829312, 47.997440243069704]],
    [ "Enedis",[3.065611383889528, 50.6019360427377]],
    [ "Enedis",[3.0609739450975524, 50.599742883923255]],
    [ "Enedis",[3.0572816586712075, 50.59953265700346]],
    [ "Enedis",[3.042030265974769, 50.60238951756695]],
    [ "Enedis",[3.0448661170585933, 50.60197421861153]],
    [ "Enedis",[3.0447510500000017, 50.60217195000001]],
    [ "Enedis",[3.0479248286082643, 50.60536735898506]],
    [ "Enedis",[3.0455542212098026, 50.604242122968216]],
    [ "Enedis",[-1.8383780753272565, 48.15672215319912]],
    [ "Enedis",[-1.3306400825740343, 48.7010710850788]],
    [ "Enedis",[-1.3734021977328303, 48.694230606043305]],
    [ "Enedis",[3.0373962978157, 50.63136803366325]],
    [ "Enedis",[3.0374217685202813, 50.63134916890989]],
    [ "Enedis",[3.0373707021843, 50.63138706633675]],
    [ "Enedis",[3.0372405553277724, 50.63304483059993]],
    [ "Enedis",[3.0372743876219284, 50.63302930499132]],
    [ "Enedis",[1.364507301836035, 49.69475536612051]],
    [ "Enedis",[3.039229169389463, 50.6131064587914]],
    [ "Enedis",[0.5749693499999999, 48.75051654999999]],
    [ "Enedis",[-3.1199548, 47.499031849999994]],
    [ "Enedis",[0.08174855259968955, 43.24031987706726]],
    [ "Hameau de Millery",[4.782638243180256, 45.63550185492495]],
    [ "Enedis",[3.3381125292120832, 43.470346153142735]],
    [ "Enedis",[1.73958305, 49.575065949999995]],
    [ "Enedis",[-3.1059033301081804, 47.48217005844797]],
    [ "Enedis",[3.050176800000001, 50.644962250000006]],
    [ "Enedis",[-3.1463153572636164, 47.52766458384486]],
    [ "Enedis",[3.964239049999999, 50.27279435]],
    [ "Jonc",[-1.4285583999999998, 46.348437399999995]],
    [ "Les Thévenins",[5.998213531295235, 46.60762734553186]],
    [ "Enedis",[3.0516994, 50.642969449999995]],
    [ "Enedis",[3.0503031999999997, 50.64407965000001]],
    [ "Enedis",[3.0407780653924372, 50.63804972882725]],
    [ "La Boquette",[4.696184939546864, 47.302987412764864]],
    [ "Charrieres",[4.698044828818834, 47.303565953951995]],
    [ "Enedis",[3.096385934732203, 50.65931704162334]],
    [ "Enedis",[3.0567731500000015, 50.61773815]],
    [ "Enedis",[3.0582574497804966, 48.34088248541218]],
    [ "Enedis",[3.063495017120108, 48.33916595346936]],
    [ "Enedis",[3.0531434822328514, 48.345035506865685]],
    [ "Enedis",[3.0558529930077576, 48.348894880291546]],
    [ "Enedis",[3.0829795499999997, 48.3559292]],
    [ "Enedis",[3.0740263301858395, 48.34945820543852]],
    [ "Enedis",[3.0725550000000004, 48.346199500000004]],
    [ "Enedis",[4.69429223493229, 47.2936767676806]],
    [ "ZA Comb",[4.693278041416348, 47.29387046356835]],
    [ "Enedis",[3.0611488499999995, 48.342602199999995]],
    [ "Enedis",[3.0620180999999995, 48.334099599999995]],
    [ "Enedis",[3.0636914499999994, 48.33399685]],
    [ "Charmessy",[4.926540543722453, 47.31854969249464]],
    [ "Delecey",[5.990115044926286, 47.32090034526739]],
    [ "970892289",[4.733114133485079, 47.252812449266564]],
    [ "Enedis",[1.9512855999999998, 42.430989649999994]],
    [ "Lavenant",[4.3762574798517635, 45.091191648487005]],
    [ "Micoulaud",[4.485836679731014, 45.01781491968609]],
    [ "Saint-Charles",[0.28586849188706087, 47.99226997767026]],
    [ "Enedis",[-2.08135114953551, 46.918132833495484]],
    [ "Outtier",[-2.3414180999999994, 47.26873865]],
    [ "Enedis",[4.8658815220821925, 45.410223496887035]],
    [ "Enedis",[5.352046649999999, 43.361222950000005]],
    [ "Enedis",[3.0670062, 48.33499475]],
    [ "Enedis",[3.06922355, 48.33375935]],
    [ "Enedis",[3.0676285, 48.33261465]],
    [ "Enedis",[3.0974919441769857, 48.33129013175038]],
    [ "Enedis",[3.106640051457941, 48.32228126694608]],
    [ "Enedis",[3.102437046568736, 48.322614666085514]],
    [ "Enedis",[3.087062875839018, 48.32774045400284]],
    [ "Enedis",[3.081252449999999, 48.32698505000001]],
    [ "Enedis",[3.0757880000000015, 48.3240562]],
    [ "Enedis",[3.083323882493955, 48.325139021917785]],
    [ "Enedis",[3.084425403223709, 48.32351316726083]],
    [ "réséda",[6.099232837521421, 49.02853971966785]],
    [ "Enedis",[3.088224949999999, 48.321553050000006]],
    [ "Enedis",[3.07923445, 48.32275450000001]],
    [ "Enedis",[3.0804266, 48.31912415]],
    [ "Enedis",[-1.5457562000000002, 46.41056415]],
    [ "Enedis",[3.086371450112214, 48.313886772427054]],
    [ "Enedis",[3.0872040251540023, 48.31043865787799]],
    [ "Enedis",[3.0515204999999983, 48.302544649999994]],
    [ "Enedis",[3.0502362834279233, 48.30533643000378]],
    [ "Enedis",[3.2860692, 48.1942047]],
    [ "Enedis",[3.2818951595477315, 48.18930517742286]],
    [ "Enedis",[3.274449684104515, 48.190378178068244]],
    [ "Enedis",[2.949105482686843, 47.699821051130456]],
    [ "Enedis",[2.9424805000000003, 47.70571795000001]],
    [ "Enedis",[2.9555969794170984, 47.70230974653348]],
    [ "Enedis",[2.9580362421935384, 47.69616047710975]],
    [ "Enedis",[2.956602762943418, 47.69631441962463]],
    [ "Enedis",[2.9434093083024466, 47.69696107407956]],
    [ "Enedis",[2.950913744718329, 47.693918773448374]],
    [ "Enedis",[2.9467253500000004, 47.693562699999994]],
    [ "RTE",[3.386879332696706, 45.29867433115548]],
    [ "réséda",[6.185711019110621, 49.0542184493691]],
    [ "Électricité de France",[55.50181860000001, -20.907884350000003]],
    [ "LE PETIT ROUX",[0.10091267499935763, 48.0238116790478]],
    [ "Aigreville",[0.07386224087040327, 48.00679126288349]],
    [ "Enedis",[3.060958105897515, 47.64568131653227]],
    [ "Villargondran",[6.381635162428969, 45.265933276303585]],
    [ "Enedis",[3.078395254169161, 47.6386257743995]],
    [ "Enedis",[-2.124084067440062, 47.89841550302729]],
    [ "Enedis",[3.0626405500000007, 47.636607]],
    [ "Enedis",[3.06484695, 47.639534149999996]],
    [ "Enedis",[6.224647194726423, 44.46649654805606]],
    [ "Enedis",[3.173258293194331, 47.699826118394064]],
    [ "Enedis",[3.1664201999999997, 47.7016451]],
    [ "Enedis",[3.411924722720175, 48.23499142421961]],
    [ "Enedis",[3.4387946500000006, 48.227589449999996]],
    [ "Enedis",[3.4415000499999997, 48.228490699999995]],
    [ "Enedis",[3.443507161229534, 48.229912234167436]],
    [ "Enedis",[3.44795545, 48.23315889999999]],
    [ "Enedis",[3.3842766203218697, 48.21901809776366]],
    [ "Enedis",[3.37951335, 48.2142856]],
    [ "Enedis",[3.3791575, 48.2176121]],
    [ "Enedis",[3.377555094143166, 48.25233832834418]],
    [ "Enedis",[3.3927104065758344, 48.250307775180644]],
    [ "Enedis",[3.393114224857524, 48.25302344011157]],
    [ "Enedis",[3.3254646246766177, 48.20143160577114]],
    [ "Enedis",[3.3253579285045913, 48.20143302516322]],
    [ "Enedis",[3.3221403499999997, 48.19889015]],
    [ "Enedis",[3.323128175481812, 48.196860058152055]],
    [ "Enedis",[3.3244998500000005, 48.19237445000001]],
    [ "Enedis",[3.2651605000000004, 48.2056924]],
    [ "Enedis",[3.2643841339127087, 48.201919382684366]],
    [ "Enedis",[3.2483452, 48.21053125000001]],
    [ "Enedis",[3.255191269541842, 48.208317191785206]],
    [ "Enedis",[3.2563002, 48.216128999999995]],
    [ "Enedis",[3.2594482266105542, 48.2269639532206]],
    [ "Enedis",[3.188590092382985, 50.60761262977851]],
    [ "Enedis",[3.255405564924792, 48.22467323000932]],
    [ "Enedis",[3.24165758382384, 48.2231948509614]],
    [ "Enedis",[3.251302036555675, 48.228054322305766]],
    [ "Enedis",[3.2532993679680264, 48.230529805639236]],
    [ "Enedis",[3.22667505, 48.222676050000004]],
    [ "Enedis",[3.2162163500000003, 48.2214882]],
    [ "Enedis",[3.213324049999998, 48.223993199999995]],
    [ "Enedis",[3.2325070323213705, 48.22135817556722]],
    [ "Enedis",[3.3541381500000003, 48.167513150000005]],
    [ "Enedis",[2.9757606, 50.61524885000001]],
    [ "Enedis",[6.121092282463536, 45.91924624573775]],
    [ "Carrieres",[6.1223811999999995, 45.92521415]],
    [ "Tele Freddy",[6.66906120579441, 45.85513548940933]],
    [ "Enedis",[3.5060232499999997, 48.3427202]],
    [ "Enedis",[3.50913065, 48.34619675]],
    [ "Enedis",[3.4553675, 48.3661971]],
    [ "Enedis",[3.454692335196181, 48.363309169792394]],
    [ "Enedis",[3.953256215210049, 47.89420177657721]],
    [ "Enedis",[3.3267840288415287, 48.2478521923487]],
    [ "Enedis",[3.337467600000001, 48.2500261]],
    [ "Enedis",[3.3314560059546943, 48.24959277187059]],
    [ "Enedis",[3.3277929575018272, 48.24979698022997]],
    [ "Enedis",[3.3276285094423765, 48.25014872816415]],
    [ "Enedis",[3.3234376879736938, 48.2457327862593]],
    [ "Enedis",[3.32036245, 48.24523565]],
    [ "Enedis",[3.3187882703427736, 48.24479285275707]],
    [ "Enedis",[3.3176095500000002, 48.24340354999999]],
    [ "Enedis",[3.3164213677276404, 48.24727424867105]],
    [ "Enedis",[3.3175625500000003, 48.2473681]],
    [ "Enedis",[3.309610414689599, 48.24607102951691]],
    [ "Enedis",[3.302463393709542, 48.24574082558534]],
    [ "Enedis",[3.2975084699588084, 48.245061802007974]],
    [ "Enedis",[3.289176123603467, 48.243801029379135]],
    [ "Enedis",[3.30718785, 48.243046250000006]],
    [ "Enedis",[7.274123774789759, 47.73104574331485]],
    [ "Enedis",[1.5965734999999999, 43.43245095]],
    [ "Enedis",[3.40014295, 48.29551065]],
    [ "Enedis",[3.3985939888435848, 48.2925709236504]],
    [ "Enedis",[3.3997743, 48.28583904999999]],
    [ "Enedis",[3.4035318366942064, 48.291565691885296]],
    [ "Enedis",[3.44749288443616, 48.27827445598012]],
    [ "Enedis",[3.384152866389382, 48.383011474407425]],
    [ "Enedis",[3.3588557000000003, 48.366838200000004]],
    [ "Enedis",[1.7581708999999999, 44.152266049999994]],
    [ "Prugnerède",[1.7682075388533578, 44.14915511664195]],
    [ "Enedis",[1.7683699, 44.148974499999994]],
    [ "Enedis",[1.4351297499999998, 49.73620565]],
    [ "Enedis",[3.317135349999999, 49.524422049999984]],
    [ "Enedis",[3.0669690987489684, 50.64457552326406]],
    [ "Enedis",[3.275261, 48.36581520000001]],
    [ "Enedis",[3.2944557, 48.35663135]],
    [ "Enedis",[3.3029784000000015, 48.35543779999999]],
    [ "Enedis",[3.3067085999999994, 48.350935050000004]],
    [ "Enedis",[3.265785488352673, 48.14421542023668]],
    [ "Enedis",[3.2667075500000005, 48.14709880000001]],
    [ "Enedis",[3.26120135, 48.14957664999999]],
    [ "Enedis",[3.2640047, 48.15414754999998]],
    [ "Enedis",[3.2659047500000002, 48.15496470000001]],
    [ "Enedis",[3.2552308127857836, 48.15405986944814]],
    [ "GRANDE RUE",[3.259535815256647, 48.15676866966995]],
    [ "Enedis",[3.2462544921471728, 48.17746407583747]],
    [ "Enedis",[3.2356085999999995, 48.18452504999999]],
    [ "Enedis",[3.248345770447807, 48.187253508408055]],
    [ "Enedis",[3.2587094, 48.19416400000001]],
    [ "Enedis",[3.102981256075193, 50.634027005293866]],
    [ "Enedis",[3.3016029888803686, 48.162128182356064]],
    [ "Enedis",[3.2999989824911915, 48.15887439498457]],
    [ "Enedis",[3.307775366987303, 48.15485640301635]],
    [ "Enedis",[3.3097823000000006, 48.15169690000001]],
    [ "Enedis",[3.31263527814765, 48.15349293417598]],
    [ "GDF",[7.2758351, 47.745709950000006]],
    [ "Enedis",[1.9241981911251127, 47.91477193505372]],
    [ "Enedis",[1.9248368527704418, 47.91459029242397]],
    [ "Enedis",[7.261194850000001, 47.7424495]],
    [ "Poste électrique d'Oletta",[9.32906678017176, 42.659904038959354]],
    [ "Enedis",[-3.8551530500000006, 48.66580015]],
    [ "Enedis",[3.3114214528932964, 48.1450318180111]],
    [ "Enedis",[3.3197729000000002, 48.14351785]],
    [ "Enedis",[-1.83852035245819, 46.69655551501772]],
    [ "Enedis",[3.21813454130383, 50.56556292426637]],
    [ "Enedis",[-3.845241593905137, 48.66297993228476]],
    [ "Enedis",[-3.84280935, 48.67553565000001]],
    [ "Enedis",[-3.8395929254031773, 48.6731616541549]],
    [ "Enedis",[-3.843016612807258, 48.669175518031125]],
    [ "Enedis",[3.3586613499999998, 48.177521850000005]],
    [ "Enedis",[3.383318837910411, 48.17258372579359]],
    [ "Enedis",[3.3888575, 48.17269085]],
    [ "Enedis",[3.4250894460900594, 48.17891908000323]],
    [ "Enedis",[3.354927295236834, 48.211136368981165]],
    [ "Enedis",[3.2581034306296317, 48.33749054749511]],
    [ "Enedis",[3.2625798500000007, 48.3452358]],
    [ "Enedis",[3.2631704, 48.34226775]],
    [ "Enedis",[3.275568110095114, 48.33933491715146]],
    [ "Enedis",[6.604470834460372, 46.31799774676187]],
    [ "Enedis",[3.2306402668882495, 48.32845636372319]],
    [ "Enedis",[3.2304068980078315, 48.32867061713036]],
    [ "Enedis",[3.2041367000000007, 48.319503600000004]],
    [ "Enedis",[3.1639186111879436, 48.35970227047197]],
    [ "Enedis",[3.116578767861293, 48.3555394698351]],
    [ "Enedis",[3.1233986999999996, 48.35430385]],
    [ "Enedis",[3.119736112807262, 48.34913423327889]],
    [ "Enedis",[3.13017, 48.35147745]],
    [ "Enedis",[3.1349143, 48.355302449999996]],
    [ "Enedis",[3.1370573000000004, 48.35100045000001]],
    [ "Enedis",[3.10561615, 48.34910610000001]],
    [ "Enedis",[-3.8656924999999998, 48.61998305000001]],
    [ "Enedis",[-2.3744203774859756, 46.7046301373898]],
    [ "Enedis",[-1.9822223440498985, 47.81158375127455]],
    [ "Enedis",[-1.6884024151028891, 48.11091347085535]],
    [ "Enedis",[2.884146701227709, 50.25353624510782]],
    [ "Enedis",[1.9544167000000001, 47.96560260000001]],
    [ "Enedis",[-0.6854145, 46.53028195]],
    [ "Enedis",[-3.901294949999999, 48.66664754999999]],
    [ "Enedis",[3.0986553567680475, 48.345966268332]],
    [ "Enedis",[3.1436626, 48.34816085]],
    [ "Enedis",[3.1396834815366352, 48.34576275772095]],
    [ "Provisoire Président",[-0.54725695, 47.4805466]],
    [ "Enedis",[4.126400528849191, 43.55102989414835]],
    [ "Enedis",[-3.8026887758221113, 48.709899859580176]],
    [ "Enedis",[3.1596390999999997, 48.34171759999999]],
    [ "Enedis",[3.179159968265294, 48.3509847964776]],
    [ "Enedis",[3.171622965749545, 48.337228851535485]],
    [ "Enedis",[3.1973714, 48.344031099999995]],
    [ "Enedis",[3.2687872999999996, 48.28695955]],
    [ "Enedis",[3.284508110270163, 48.28340393353276]],
    [ "Enedis",[3.222025197263158, 48.30471491691229]],
    [ "Enedis",[3.2484913499999997, 48.30489975]],
    [ "Enedis",[3.239599702952174, 48.309204379610016]],
    [ "Enedis",[1.4506662, 48.920453699999996]],
    [ "Enedis",[-0.8040607, 45.9700596]],
    [ "Enedis",[1.463703540528195, 48.89213923026787]],
    [ "Enedis",[-3.8225452859862323, 48.63874342326592]],
    [ "Enedis",[3.066763552372192, 50.65239743198945]],
    [ "Enedis",[3.070862156347978, 50.65326126596296]],
    [ "Enedis",[2.9577774000000003, 42.69596625]],
    [ "Enedis",[2.9247353499999997, 42.68720439999999]],
    [ "Enedis",[6.117862408859879, 45.89401501194327]],
    [ "Enedis",[6.142110821242662, 45.91305158026035]],
    [ "Brague",[6.9843147, 43.668407800000004]],
    [ "Enedis",[-3.616940151249188, 48.6731051163616]],
    [ "Enedis",[-3.623117350000001, 48.672094599999994]],
    [ "Enedis",[-3.8078894499999993, 48.635920850000005]],
    [ "Enedis",[3.303160999999999, 48.029674400000005]],
    [ "Enedis",[1.6473561741196434, 50.1731567564582]],
    [ "Vallourd",[6.153604065777624, 46.002664093193275]],
    [ "Enedis",[3.0274707984783764, 50.62414674771714]],
    [ "Enedis",[6.932494407385217, 46.03388358490247]],
    [ "Résidence Tourisme",[6.933667804029874, 46.03379706679211]],
    [ "La Vallée 1",[0.25341593089340964, 48.0112152474507]],
    [ "Fanière",[0.2492220851395949, 48.00481805779146]],
    [ "Enedis",[2.9977731329308934, 50.63991929161583]],
    [ "Strasbourg Électricité Réseaux S.A.",[8.0213928, 48.86327505]],
    [ "Strasbourg Électricité Réseaux S.A.",[8.0121035, 48.81104969999999]],
    [ "Enedis",[-1.5017308999999999, 43.479631350000005]],
    [ "Enedis",[3.0112750759256794, 50.64568448206546]],
    [ "Verdannes",[6.422179961883324, 46.33801401716817]],
    [ "Grande Vigne",[6.416759000000001, 46.33931695]],
    [ "Enedis",[2.8132944007834895, 50.2722636836616]],
    [ "Enedis",[6.428051655396622, 46.33350428060225]],
    [ "Enedis",[3.0126128843588003, 50.642593995154385]],
    [ "Champ Menou",[6.4563495500000005, 46.33538165]],
    [ "Enedis",[6.45792128762069, 46.3322686693828]],
    [ "Valere",[6.448805650000001, 46.33081405]],
    [ "Enedis",[6.44361839213837, 46.32655337558191]],
    [ "Enedis",[6.4367303315376345, 46.324812006700384]],
    [ "Enedis",[6.47829515, 46.3514415]],
    [ "Enedis",[6.471127312960433, 46.35212853566725]],
    [ "Arpinges",[6.45675575, 46.3431402]],
    [ "Enedis",[6.485295600000001, 46.3373973]],
    [ "Enedis",[3.6093424499999993, 48.22646955]],
    [ "Enedis",[3.6190190617676348, 48.226376673772215]],
    [ "Enedis",[3.6025795000000005, 48.22827285000001]],
    [ "Enedis",[3.5966067015454004, 48.23148762400783]],
    [ "Enedis",[3.5916465653732077, 48.23214379479588]],
    [ "Étang",[-1.0919902500000005, 44.9781824]],
    [ "Terrehault",[0.40202875955609074, 48.207206616217945]],
    [ "Garandeau",[-0.38215119999999947, 45.085828850000006]],
    [ "Enedis",[3.5708026999999993, 48.231950000000005]],
    [ "Enedis",[3.55610175, 48.24792745]],
    [ "Enedis",[3.554298409456685, 48.24218897359482]],
    [ "Enedis",[3.939132650000002, 45.386536400000004]],
    [ "Enedis",[4.156697808725611, 45.42473300189118]],
    [ "arm31 RD139",[7.720609799999999, 48.77802355]],
    [ "Enedis",[2.8306548504634, 50.60558348318056]],
    [ "Enedis",[6.669526491615507, 46.326541960842654]],
    [ "Enedis",[-4.0670147, 48.457465500000005]],
    [ "Enedis",[2.9008132, 47.74279444999999]],
    [ "Enedis",[2.8882163000000016, 47.74918124999999]],
    [ "Enedis",[2.8812794894497404, 47.749430974921644]],
    [ "Clos Guillaume",[-0.27069875, 49.284577549999995]],
    [ "Enedis",[2.0216652054681203, 47.94592409517756]],
    [ "Enedis",[6.598935172224715, 45.20135185353367]],
    [ "Secours centrale Orelle",[6.5811888330314225, 45.2041362016605]],
    [ "Bissortete",[6.581540483715627, 45.2039273492226]],
    [ "TSA",[6.583509967455085, 45.2028890217747]],
    [ "Enedis",[3.239506258254816, 48.058349134988326]],
    [ "Enedis",[3.23748405, 48.0565462]],
    [ "Enedis",[3.215150200000001, 48.0498803]],
    [ "Enedis",[3.18547755, 48.03085875]],
    [ "Enedis",[3.1752416499999985, 48.02812949999999]],
    [ "Enedis",[3.1732174876031864, 48.0294792782638]],
    [ "Enedis",[3.171855259085842, 48.031414574247485]],
    [ "Enedis",[2.817167879302486, 50.245382906301835]],
    [ "Enedis",[6.529898299999998, 46.255669350000005]],
    [ "Enedis",[6.551771632541931, 46.28497275124752]],
    [ "Enedis",[2.712732401831026, 50.3224264661904]],
    [ "Enedis",[5.300161544260394, 45.58125173174945]],
    [ "Enedis",[5.307993958625131, 45.57321513036241]],
    [ "Garnieri",[5.303075754673608, 45.57493511579618]],
    [ "Déchetterie",[5.3055866, 45.56941249999999]],
    [ "Enedis",[3.0217509208139908, 50.618825152308894]],
    [ "Enedis",[3.020769364509708, 50.61882577958561]],
    [ "Enedis",[3.161304055406331, 48.01426313457074]],
    [ "Enedis",[3.232435225511477, 48.01662763989152]],
    [ "Enedis",[-4.371290702050296, 48.381561031106415]],
    [ "Poste électrique de la Deuve Garbe",[2.9516586320312634, 50.12639453046476]],
    [ "Poste électrique de Morchies",[2.9523584333590605, 50.12597785589316]],
    [ "Poste électrique de la Deuve Brulée",[2.9515433361734167, 50.125742510544065]],
    [ "Enedis",[2.3599124933965987, 49.098888392487645]],
    [ "RTE",[1.6171795181575996, 43.240613647790305]],
    [ "Grimaud",[6.553090063300959, 43.26663810469692]],
    [ "986793476",[0.6647125673995649, 47.32171522675687]],
    [ "Beziers Est",[3.2597979801031505, 43.35194900700592]],
    [ "986794199",[5.04727969130307, 43.638471578527714]],
    [ "SNCF Réseau",[-0.033999198467201557, 43.1012337615474]],
    [ "986795870",[6.562512111247538, 45.49687144902843]],
    [ "986795920",[2.140511190322733, 42.711592052554785]],
    [ "Provencialis",[5.818655539507789, 43.55488926689521]],
    [ "Poste électrique d'Issou",[1.789577060498942, 48.970692309540325]],
    [ "RTE",[5.548298722605429, 43.41340768141199]],
    [ "RTE",[7.326647887999147, 43.799683768660806]],
    [ "Poste électrique de Roumagnolle",[-0.5278255913705304, 45.927519302524914]],
    [ "986797416",[2.855476609732114, 49.79555084105188]],
    [ "RTE",[3.4748573814402715, 50.16760523133173]],
    [ "986798278",[5.710696615254006, 45.121066808525036]],
    [ "Super U",[0.41768970000000016, 48.05008605]],
    [ "Carrefour",[0.4065536297197267, 48.03479172156506]],
    [ "986798991",[2.227690397068525, 48.65065117101545]],
    [ "RTE",[0.6243522148893859, 47.40718762278102]],
    [ "986799293",[-0.45931507311202324, 49.13817846257857]],
    [ "RTE",[3.2133172428869123, 50.14686143996366]],
    [ "RTE",[-2.0194490839369, 47.356451435036064]],
    [ "RTE",[7.150528465089914, 44.18986184700768]],
    [ "Station de Privas",[4.602863214228765, 44.72412643158022]],
    [ "RTE",[1.7143557604868884, 45.81972527868707]],
    [ "RTE",[2.0350036939372416, 48.35196697646673]],
    [ "Poste électrique de Fontenay-Mauvoisin",[1.6365534517219011, 48.9534611666883]],
    [ "986801938",[-0.2869686988022514, 43.23632056777815]],
    [ "Poste électrique de Villegats",[0.1987864234173904, 45.99061297802929]],
    [ "Poste électrique de Metz Technopôle",[6.224918439813266, 49.10661493837907]],
    [ "Enedis",[-0.8044115499999999, 46.468122599999994]],
    [ "Enedis",[6.513354781602867, 46.24612492208413]],
    [ "Enedis",[2.36078385, 48.671889699999994]],
    [ "Enedis",[6.530363564820459, 46.29778242228813]],
    [ "Enedis",[4.890457549999999, 44.839761649999986]],
    [ "Enedis",[-0.7796949346453191, 46.46298869573049]],
    [ "Enedis",[3.0236874244889087, 50.62026948600841]],
    [ "Enedis",[-1.31282115, 49.41412095]],
    [ "Irlandais",[2.3361174047616067, 48.80306311235209]],
    [ "Enedis",[4.103476437506517, 43.733720066003656]],
    [ "Enedis",[4.890963552026863, 44.841801467615596]],
    [ "Enedis",[4.914847934863235, 44.84898242765415]],
    [ "Enedis",[4.927903599999999, 44.8588601]],
    [ "Enedis",[2.97253455, 50.6521715]],
    [ "Enedis",[2.995936863920611, 50.609972383199484]],
    [ "Enedis",[3.0170143115283627, 50.61560709612436]],
    [ "Enedis",[-1.8843562499999995, 46.854211349999986]],
    [ "Lices de Provence",[7.003864499999999, 43.67397965]],
    [ "Enedis",[6.502639473963927, 46.37839640362119]],
    [ "Enedis",[3.2961393276906077, 48.084155488337245]],
    [ "Enedis",[3.297089350000001, 48.077860949999994]],
    [ "Enedis",[3.295914999066305, 48.0775798332731]],
    [ "Enedis",[3.0519187867965307, 50.587264723089135]],
    [ "Enedis",[5.199225249999999, 44.89854019999999]],
    [ "Enedis",[0.1704311286819839, 47.21110990314829]],
    [ "Enedis",[0.1688109, 47.2119208]],
    [ "Pisciculture",[6.038579180795231, 48.6201596813465]],
    [ "Enedis",[1.9561773616141938, 48.04738158374174]],
    [ "Enedis",[3.0358396748850573, 50.61257178954023]],
    [ "Enedis",[3.0957693500000003, 50.6234246]],
    [ "Enedis",[3.0944664554201364, 50.616501880029524]],
    [ "Enedis",[6.474442757074696, 45.529234378404745]],
    [ "Enedis",[6.69089745, 46.00762034999999]],
    [ "Enedis",[2.3774181925616147, 45.86902716910677]],
    [ "Enedis",[2.364928650898797, 45.86914781643595]],
    [ "Enedis",[0.6965886842123286, 47.360124194152114]],
    [ "Enedis",[3.1043358000000008, 50.61471565]],
    [ "Enedis",[6.597382250000001, 46.35750805]],
    [ "Enedis",[0.3623309171690033, 46.65926081840801]],
    [ "Enedis",[6.304373867040731, 46.29971811655098]],
    [ "Salle des Fetes",[6.30286933617833, 46.303845625069044]],
    [ "Enedis",[6.310172550000001, 46.3068943]],
    [ "Enedis",[6.318431125785102, 46.30552445330234]],
    [ "Enedis",[6.307227318945596, 46.30153040513848]],
    [ "Enedis",[6.306780000000002, 46.2975833]],
    [ "Enedis",[2.0514739281314025, 47.95109446994211]],
    [ "Enedis",[0.45372834327526274, 48.011472078575416]],
    [ "Enedis",[3.1807366255534153, 50.621109038974325]],
    [ "Enedis",[6.316779417509004, 46.30377128051843]],
    [ "Enedis",[6.2790356, 46.2073844]],
    [ "Enedis",[6.236365100000001, 46.20157619999999]],
    [ "Quinte",[6.234750318795579, 46.20090585193992]],
    [ "Enedis",[2.9546174530846745, 42.62878277535362]],
    [ "Enedis",[6.27282570076526, 46.20113198736995]],
    [ "Gay Frères",[6.272032649999999, 46.201127400000004]],
    [ "Enedis",[6.2708815, 46.200708600000006]],
    [ "Enedis",[3.1192900999999997, 50.6321443]],
    [ "Enedis",[4.763907467998418, 45.45310239430305]],
    [ "Enedis",[4.742602299999999, 44.9272153]],
    [ "Enedis",[6.30443755, 46.2163765]],
    [ "Laubert",[1.3832838333639559, 44.040064049269645]],
    [ "Enedis",[1.818361587369101, 48.62695273894632]],
    [ "Enedis",[0.6991095985309557, 47.36009176703565]],
    [ "Enedis",[3.13199835, 50.617196799999995]],
    [ "Enedis",[3.1332280934642256, 50.616220316052306]],
    [ "Enedis",[3.1283734021907, 50.60951583350768]],
    [ "Enedis",[-0.9578457899371932, 45.55809753187818]],
    [ "Médipôle",[5.245420999898062, 45.59892428297655]],
    [ "Enedis",[6.3624205499999995, 46.2659138]],
    [ "Enedis",[0.7291882820351865, 47.34145234399771]],
    [ "Enedis",[0.6323791435268297, 47.339404073279255]],
    [ "Enedis",[0.6050976395611906, 47.40581457711593]],
    [ "Enedis",[3.16491205, 50.6503967]],
    [ "Enedis",[3.160204840841183, 50.6514184212539]],
    [ "Enedis",[3.149673733706757, 50.64051977703646]],
    [ "Enedis",[3.2685569390738705, 48.11469702944272]],
    [ "BG Pasteur Verdun GPE",[2.3192997500000003, 48.80214765000001]],
    [ "BG Néo Chateaubriand",[2.319458350000003, 48.802416949999994]],
    [ "Enedis",[4.976166168914043, 44.83314499701926]],
    [ "Enedis",[6.313317519734897, 46.27590578174593]],
    [ "Enedis",[3.1522123672624147, 50.649365383345085]],
    [ "Enedis",[0.6812226923233514, 47.368056475980374]],
    [ "MK C Fabien 79 GPE",[2.281147665427554, 48.81172114879608]],
    [ "BayWa R.E.",[0.9098825789532309, 46.161135236245045]],
    [ "Bignac du Haut",[0.9100634294303648, 46.1611935544749]],
    [ "Enedis",[3.13300225, 50.64502490000001]],
    [ "Enedis",[6.255874358234268, 46.274583376468186]],
    [ "Enedis",[6.255049002258747, 46.26752011609995]],
    [ "Enedis",[5.368324900000001, 43.4255734]],
    [ "Enedis",[1.9366685499999994, 47.88730915]],
    [ "Enedis",[4.096420700077152, 43.67249886662994]],
    [ "Enedis",[6.315957589593921, 46.28987688414102]],
    [ "Enedis",[6.3111097319528975, 46.29405435116881]],
    [ "Enedis",[6.310799000992474, 46.280608882877054]],
    [ "Enedis",[4.096913982324828, 43.678219824868215]],
    [ "Enedis",[-4.241935173189891, 48.447389245637424]],
    [ "Enedis",[3.94250488262415, 43.58214614378928]],
    [ "Enedis",[2.8989554205181856, 50.65184030289499]],
    [ "Enedis",[0.65769880624212, 47.337675586904076]],
    [ "Enedis",[3.272738333050601, 48.08551525566642]],
    [ "Enedis",[3.277657652963501, 48.09708258209923]],
    [ "Enedis",[3.2740153000000003, 48.09896655000001]],
    [ "Enedis",[3.274052281645409, 48.09897854856422]],
    [ "Enedis",[1.3793517323001319, 44.036849421795914]],
    [ "De Guibert",[1.381343154712011, 44.03835643442]],
    [ "Enedis",[4.812985328913165, 44.861985596911936]],
    [ "Futuropole 4",[1.38632405, 44.03751039999999]],
    [ "Enedis",[1.3882984499999995, 44.043490799999994]],
    [ "Enedis",[1.3768077, 44.0368265]],
    [ "Villard sur Ain",[5.756367949999998, 46.66821459999999]],
    [ "Enedis",[0.6906868584074102, 44.03487254031427]],
    [ "Enedis",[0.7242205825170054, 47.30802935682757]],
    [ "Enedis",[2.9012708, 42.68178625]],
    [ "Enedis",[5.022652651742262, 47.36309345633059]],
    [ "Enedis",[5.593998700000001, 47.12321075]],
    [ "Enedis",[4.935218079716299, 44.916595990483444]],
    [ "Enedis",[4.9363732830475815, 44.913761416629114]],
    [ "Enedis",[4.9364989, 44.91372384999998]],
    [ "Oratoire",[6.654283813795631, 46.36503461921879]],
    [ "Enedis",[3.2515511499999996, 48.1134624]],
    [ "Enedis",[3.2424730499999996, 48.11299205]],
    [ "Enedis",[0.6951380248307866, 47.37593415319644]],
    [ "Enedis",[3.212093284564889, 48.07878093039331]],
    [ "Enedis",[2.9288962999999995, 42.65693395000001]],
    [ "Enedis",[2.947555448556658, 42.65266281654781]],
    [ "Enedis",[2.95704005, 42.6527378]],
    [ "Enedis",[0.5995770683171694, 47.32634601271499]],
    [ "Enedis",[0.6970365448510316, 47.34938455627288]],
    [ "Marclaz Dessus",[6.444565327714761, 46.352968313106466]],
    [ "Carré Vert",[6.444806550000001, 46.3511879]],
    [ "Enedis",[0.7279400799487353, 47.338994410901584]],
    [ "Enedis",[0.7169371, 47.33929151125104]],
    [ "Enedis",[0.3702890798781714, 46.65699906575662]],
    [ "Enedis",[2.888350319733842, 42.69761630891874]],
    [ "Enedis",[0.708141568227606, 47.344191830099916]],
    [ "Enedis",[4.018303621139926, 43.61349952338974]],
    [ "Enedis",[4.017745799276648, 43.618799966977534]],
    [ "Enedis",[1.5836961207105333, 48.54767964480074]],
    [ "Roseraie",[2.335219120814315, 48.77338322554853]],
    [ "Enedis",[0.7027412261495843, 47.34428249292593]],
    [ "Enedis",[2.410245115274299, 50.966152396788154]],
    [ "CIMETIERE",[1.340888529787126, 43.27455989508597]],
    [ "La Pilonière",[0.19848254999999998, 48.114252400000005]],
    [ "Hameau de la cour",[0.2182292, 48.1094574]],
    [ "La Cave",[0.23565804449753291, 48.16965667051703]],
    [ "Mairie",[0.23400985000000066, 48.175432]],
    [ "Maison de retraite",[0.24190295439915344, 48.175012470975254]],
    [ "Enedis",[6.625369425322946, 46.37846254703063]],
    [ "Enedis",[6.619694199999999, 46.35197664999999]],
    [ "Enedis",[6.630785800000001, 46.37683435]],
    [ "Enedis",[6.660277466472158, 46.378562146917034]],
    [ "Enedis",[6.69298975, 46.38680355000001]],
    [ "Enedis",[6.692413599999998, 46.38914325]],
    [ "Enedis",[6.703017018295624, 46.39604903566592]],
    [ "Hycrocoop",[6.16275115, 45.404593250000005]],
    [ "Enedis",[3.1850150168438005, 48.12217140412238]],
    [ "Enedis",[3.1863279500000012, 48.12025700000001]],
    [ "Enedis",[3.181733713556974, 48.12076697814184]],
    [ "Enedis",[3.1802953489480057, 48.11882468313266]],
    [ "Enedis",[1.0499533918118977, 49.410427247846116]],
    [ "ZAC Centre Sud",[5.170437016642016, 45.747988701112746]],
    [ "ZAC Centre Nord",[5.1688454, 45.7495894]],
    [ "Enedis",[6.655077878753005, 46.389229625829444]],
    [ "Enedis",[6.336404585629265, 45.04307183193558]],
    [ "réséda",[6.3125790784420435, 49.103666187730155]],
    [ "Enedis",[3.3915347999999996, 48.16232349999999]],
    [ "Enedis",[3.3916312000000004, 48.161991900000004]],
    [ "Enedis",[-0.44425695547193533, 43.914920634553916]],
    [ "Enedis",[4.037549526742778, 47.50496667406252]],
    [ "Enedis",[4.037475269788301, 47.50496517587544]],
    [ "Gare",[0.49235198465942476, 48.058962147249304]],
    [ "Vieille usine",[0.4971264180152002, 48.06033765130411]],
    [ "Enedis",[3.310236415189136, 48.03525358321267]],
    [ "Enedis",[3.30834855, 48.037693649999994]],
    [ "Enedis",[3.3065821000000004, 48.0295537]],
    [ "Enedis",[3.299581690251093, 48.024246480593675]],
    [ "Enedis",[3.315329425370966, 48.04895925584156]],
    [ "Enedis",[3.3162674999999995, 48.05088605]],
    [ "Enedis",[3.3192642304105453, 48.04776321451554]],
    [ "Vikings",[-1.5593751359905605, 48.97523786852499]],
    [ "Enedis",[3.146251658551652, 50.62702308621792]],
    [ "Enedis",[6.5431371306405, 46.35396324506313]],
    [ "Enedis",[6.54788975, 46.354322]],
    [ "Enedis",[6.570311535119969, 46.35702182997276]],
    [ "Enedis",[6.546627242973561, 46.3605025226506]],
    [ "Enedis",[6.5460579303980655, 46.35635517202278]],
    [ "Enedis",[4.859078925704948, 47.36645276221144]],
    [ "Enedis",[2.8739894167794273, 47.74002899778577]],
    [ "Enedis",[2.8820690898869783, 47.74328488055546]],
    [ "Enedis",[2.8833340239332186, 47.748742898273306]],
    [ "Enedis",[2.8857921209844677, 47.74510737254637]],
    [ "Enedis",[0.6638013074724483, 47.34784226820145]],
    [ "Enedis",[-0.002649229426429849, 45.01396151305754]],
    [ "SRD",[0.2980645500000005, 46.56512769999999]],
    [ "Enedis",[6.553599204248317, 46.375895217566686]],
    [ "Enedis",[6.566177260775989, 46.366919881879824]],
    [ "Enedis",[6.553729029765788, 46.367221794458914]],
    [ "Enedis",[2.6882830332281498, 48.465325701747226]],
    [ "Enedis",[3.05457235, 48.27371885]],
    [ "Enedis",[3.1143878097403097, 50.63439700216729]],
    [ "Enedis",[0.6867368, 47.370030150000005]],
    [ "Enedis",[3.0462022266193416, 48.26798760518626]],
    [ "Enedis",[2.9881596500000005, 48.1683988]],
    [ "Enedis",[2.9881382, 48.16857865]],
    [ "Enedis",[3.0074941302167866, 48.170264520519694]],
    [ "SRD",[0.2442089877251852, 46.41775863364352]],
    [ "Enedis",[0.6763033283272951, 47.37047990900638]],
    [ "Enedis",[0.6718586415712057, 47.361703824643556]],
    [ "EDF",[6.6314985723569615, 45.13888483686264]],
    [ "Enedis",[6.526913215181468, 46.37803753198054]],
    [ "Enedis",[4.860827742616368, 45.725889080881096]],
    [ "Enedis",[2.8242493933420394, 50.3188171841549]],
    [ "Enedis",[6.6494452787313145, 46.403587353348215]],
    [ "Enedis",[3.14047865, 48.33961185]],
    [ "Enedis",[3.4328111655304707, 48.169171182234386]],
    [ "Enedis",[3.4267152874996194, 48.16743696707539]],
    [ "Enedis",[3.4261557064863073, 48.16557776610408]],
    [ "Enedis",[6.330540499999999, 47.4944325]],
    [ "Enedis",[0.6744280107646385, 47.35110569682506]],
    [ "Enedis",[6.6500097, 46.401619399999994]],
    [ "Les Isles",[3.42954661928119, 46.115257295485044]],
    [ "La Fontaine du Roy",[3.4226586290020222, 46.14260180415084]],
    [ "Enedis",[4.8605120721738615, 45.72865429638633]],
    [ "Enedis",[3.4418420499999995, 48.15355095]],
    [ "Enedis",[3.434433970979381, 48.15813338449256]],
    [ "Les Brandes",[2.7320739018046076, 46.30507062439429]],
    [ "Enedis",[6.664945471588125, 46.402197025754056]],
    [ "Enedis",[-1.0840573621987348, 46.378155573882815]],
    [ "Enedis",[3.39298985, 48.15903525]],
    [ "Enedis",[3.3892845, 48.164090599999994]],
    [ "Enedis",[3.40188786304294, 48.16308203061084]],
    [ "Enedis",[4.861677904232722, 45.723083332643625]],
    [ "Enedis",[4.863880952969976, 45.72326530163088]],
    [ "Enedis",[4.858758100000001, 45.72333595]],
    [ "Enedis",[4.784413794592674, 44.872750576585084]],
    [ "Enedis",[4.880416799747289, 44.84337542740246]],
    [ "Enedis",[4.8644013, 45.72151300000001]],
    [ "Enedis",[2.9870486499999993, 42.63371239999999]],
    [ "Enedis",[3.7048046147504285, 43.93492037005406]],
    [ "Enedis",[4.83182055, 44.857956999999985]],
    [ "Enedis",[6.636766199999999, 46.40503185]],
    [ "Enedis",[2.8144598, 42.62641589999999]],
    [ "Jouambe",[-1.0306673327619722, 43.71046201881273]],
    [ "Enedis",[6.6961852676133695, 45.211085817026124]],
    [ "Enedis",[3.0708914670175975, 48.26400599883315]],
    [ "Enedis",[2.9473767499999997, 42.665502149999995]],
    [ "Enedis",[2.9463184000000013, 42.66742299999999]],
    [ "Enedis",[2.91949415, 42.68084679999998]],
    [ "Poste électrique de Ayres",[2.8253118209976433, 44.038031375124724]],
    [ "Enedis",[0.3640421, 46.559656499999996]],
    [ "Enedis",[1.601287751702787, 47.760256399690405]],
    [ "Enedis",[3.018461972215279, 48.17222570784803]],
    [ "Enedis",[3.0245574999999993, 48.171126400000006]],
    [ "Enedis",[4.887943674876427, 45.69637789055316]],
    [ "Enedis",[4.895124750000001, 45.69340195]],
    [ "Enedis",[2.719366495476634, 50.394172426704046]],
    [ "Enedis",[-0.28994734999999977, 49.13212385]],
    [ "Enedis",[0.6860278999999999, 47.338139549999994]],
    [ "Enedis",[2.29455905, 49.870397249999996]],
    [ "Enedis",[0.41700323798447986, 47.33542592439184]],
    [ "Enedis",[-1.323414029783867, 46.71084007642704]],
    [ "Launarie",[0.17376856920406192, 48.14319029295169]],
    [ "Quarterons",[0.16808804771618382, 48.14452088977757]],
    [ "Enedis",[2.4787335398118735, 50.506778081259824]],
    [ "Enedis",[3.0460582660882607, 48.174413803941725]],
    [ "Enedis",[2.9803177829012286, 48.192014301455515]],
    [ "Enedis",[2.990744374928095, 48.20126934653315]],
    [ "Enedis",[2.9840893748181134, 48.20715391710537]],
    [ "Enedis",[0.46771345, 47.86823940000001]],
    [ "Enedis",[3.708309814952277, 43.931162297413714]],
    [ "Enedis",[2.86253295, 46.58516765]],
    [ "Enedis",[1.5560207148221443, 50.037562054006]],
    [ "Enedis",[0.6648977572601968, 47.35247503581154]],
    [ "Enedis",[-0.7812731826551825, 46.54416604834354]],
    [ "Église",[0.17971005, 48.1454804]],
    [ "ZAC Etoile 1",[6.237490167798176, 46.1995821393488]],
    [ "Enedis",[0.1213211, 49.5600904]],
    [ "Enedis",[2.996099219276743, 48.20229145210353]],
    [ "Enedis",[2.9987169071579007, 48.203133219854166]],
    [ "Enedis",[2.997624891117231, 48.201351450771796]],
    [ "Enedis",[1.8653729499999987, 47.9114622]],
    [ "Enedis",[6.11527981856258, 49.2216066075495]],
    [ "Enedis",[-0.3277991310734673, 47.294757979224975]],
    [ "Enedis",[2.9935106499999997, 48.200126749999995]],
    [ "Enedis",[3.0022521004606726, 48.198417166672314]],
    [ "Enedis",[1.8543837393579132, 49.64956608441553]],
    [ "Enedis",[0.7281768500000001, 47.3452689]],
    [ "Enedis",[0.6975687992005154, 47.33384533207219]],
    [ "Enedis",[3.0096466844403404, 48.201097752794446]],
    [ "Enedis",[3.0114616933440006, 48.204482778272]],
    [ "Enedis",[3.004575287553145, 48.20232988273442]],
    [ "Enedis",[3.005933893301581, 48.195216767188846]],
    [ "Enedis",[3.016733769644348, 48.187459242583365]],
    [ "Enedis",[1.61992895, 50.68846795]],
    [ "Enedis",[3.1154274188390456, 48.23073977842526]],
    [ "Enedis",[3.0286236161082996, 50.65362017075892]],
    [ "Enedis",[0.08132640713122048, 48.324676091560455]],
    [ "Enedis",[0.675211869780666, 47.3664457931492]],
    [ "Enedis",[-2.7554571499999985, 47.5346184]],
    [ "Enedis",[6.745699831849823, 47.20107967833696]],
    [ "Enedis",[0.6456218157596566, 47.344396181633265]],
    [ "Enedis",[1.6507922857385988, 48.4389917709458]],
    [ "Enedis",[-0.5334486499999993, 48.85722089999999]],
    [ "Enedis",[-0.49764134142180516, 49.069125442737906]],
    [ "Enedis",[2.245951769625203, 49.913727833966284]],
    [ "Enedis",[2.3585496168378546, 49.86921944980691]],
    [ "Enedis",[2.418065571677255, 49.87915880854187]],
    [ "Enedis",[0.6590720500000001, 47.3504353]],
    [ "Près aux Mules",[6.3251652499999995, 46.3303299]],
    [ "Gare",[0.42118583996502235, 48.17305263481884]],
    [ "Enedis",[0.651141699999999, 47.3355916]],
    [ "Enedis",[3.1216871000000004, 48.229403149999996]],
    [ "Enedis",[0.9951292645930293, 48.469167900271444]],
    [ "Trai les Granges",[6.319406533950065, 46.35161564871515]],
    [ "Enedis",[1.90897485, 47.90623544999999]],
    [ "Enedis",[1.9125193999999999, 47.9058357]],
    [ "Enedis",[2.2427319499999996, 49.87843215]],
    [ "Enedis",[6.698983264102508, 46.285330219603345]],
    [ "Enedis",[1.6133152218035112, 50.72681053425465]],
    [ "Enedis",[1.605269449999999, 50.68745585]],
    [ "Enedis",[1.6061716000000001, 50.68926025]],
    [ "Enedis",[1.6283376693797462, 50.67436224127315]],
    [ "Enedis",[1.63523925, 50.672899199999996]],
    [ "Enedis",[3.134950875300919, 48.13889045343828]],
    [ "Enedis",[3.1326031884230257, 48.13631528382291]],
    [ "Enedis",[3.1343621999999995, 48.136125549999996]],
    [ "Enedis",[3.1176569, 48.14266175]],
    [ "Enedis",[3.1287752834097016, 48.14621225042092]],
    [ "Enedis",[3.1374435499047744, 48.16338623333117]],
    [ "Enedis",[3.1398793, 48.16679755]],
    [ "Enedis",[3.1392044000000006, 48.16735875]],
    [ "Enedis",[3.139079729673904, 48.16907949263858]],
    [ "Enedis",[2.921875356717334, 42.688693715707046]],
    [ "Enedis",[2.9296931165917375, 42.694830295183124]],
    [ "Enedis",[2.92603295, 42.6885264]],
    [ "Enedis",[2.9425855431258756, 42.690411630694356]],
    [ "Enedis",[2.931639137856157, 42.69403857384079]],
    [ "Enedis",[-1.3966001576666596, 46.667320319171985]],
    [ "Enedis",[4.2718029, 45.36924665]],
    [ "Enedis",[1.6552141999999999, 50.6776307]],
    [ "Enedis",[1.6503619588431244, 50.71772431637894]],
    [ "Enedis",[2.3128883937752716, 46.372465134265674]],
    [ "Enedis",[-0.02270186408250288, 47.82391511169096]],
    [ "Sainte-Croix",[-0.016268764886193957, 47.8287152172644]],
    [ "Enedis",[1.8735598153983872, 50.96309426369437]],
    [ "Enedis",[0.697000932739935, 47.342371322629496]],
    [ "Enedis",[3.7210517, 43.926550000000006]],
    [ "Enedis",[3.7190371500000006, 43.92798819999999]],
    [ "Enedis",[4.83214955, 44.11851305]],
    [ "Enedis",[4.829190415916688, 44.12174858776609]],
    [ "Gavet",[5.8707580857858845, 45.074472749733076]],
    [ "1007133919",[5.871477144597623, 45.06620828405828]],
    [ "Enedis",[0.2184791971673683, 45.12945794016941]],
    [ "Enedis",[2.25928525, 47.100728749999995]],
    [ "Oratoire",[6.32519615, 46.07178874999999]],
    [ "Intermarché",[6.325237899999999, 46.0718254]],
    [ "Enedis",[3.088284049999999, 48.181037149999995]],
    [ "Enedis",[3.0900480180843592, 48.180366496395706]],
    [ "Enedis",[3.0915859905214793, 48.1793921692855]],
    [ "TRAOUC",[6.988919709647778, 43.69036023378507]],
    [ "BELLEGARDE",[6.9939650337007055, 43.69457774576502]],
    [ "Enedis",[6.7611557499999995, 46.29386605]],
    [ "Enedis",[6.735687715390228, 46.28338882604469]],
    [ "Enedis",[2.3360080893074926, 49.86291160206608]],
    [ "Enedis",[4.016895654055998, 48.29015967686207]],
    [ "Enedis",[4.0097395192765015, 48.28979740728873]],
    [ "Enedis",[4.01386005, 48.289023750000005]],
    [ "Enedis",[4.0378564673555575, 48.28057172994504]],
    [ "Enedis",[6.784354150000001, 46.29472780000001]],
    [ "Enedis",[6.775247071895401, 46.29526157859139]],
    [ "1008050174",[5.86937561771425, 46.92953109477471]],
    [ "1008134666",[5.99395545, 43.99514090000001]],
    [ "Enedis",[0.6982011252995006, 47.3372755669528]],
    [ "Enedis",[2.3100248914733834, 49.91227268067384]],
    [ "Enedis",[1.6640610500000002, 50.66970189999999]],
    [ "Enedis",[1.643282336670917, 50.69029568371416]],
    [ "Enedis",[2.309682849999999, 49.895902799999995]],
    [ "Enedis",[2.3362565499999994, 49.866006649999996]],
    [ "Enedis",[2.3071434500000003, 49.8731469]],
    [ "1008490636",[5.964321480405109, 46.90000913164246]],
    [ "Enedis",[6.809138958629531, 46.249563032406684]],
    [ "Enedis",[6.817467400000001, 46.25346805]],
    [ "Enedis",[6.839147000000001, 46.2693922]],
    [ "Enedis",[6.837767119304819, 46.268411560583644]],
    [ "Enedis",[6.792034818262037, 46.23736439508346]],
    [ "Sous-station SNCF de Mondragon",[4.713220381171135, 44.23045654766714]],
    [ "Enedis",[6.8325141004727525, 46.275688420981254]],
    [ "Enedis",[2.97700925, 42.63364465000001]],
    [ "Enedis",[2.976887900000001, 42.6327996]],
    [ "Enedis",[2.977502149999999, 42.630214800000005]],
    [ "Enedis",[2.9769348166296044, 42.630350950081095]],
    [ "Enedis",[2.975483750109704, 42.63215313330511]],
    [ "Enedis",[2.973948199999999, 42.6319647]],
    [ "Enedis",[2.9732736500000003, 42.6327486]],
    [ "Enedis",[2.971892934095512, 42.63196972479144]],
    [ "Enedis",[2.97201384065266, 42.63098022938273]],
    [ "Enedis",[2.9552597554173197, 42.627289034437496]],
    [ "Enedis",[2.9489977851022395, 42.627546072368105]],
    [ "Enedis",[2.950700065767502, 42.62598334707772]],
    [ "Enedis",[2.9517717254096563, 42.625186046940854]],
    [ "Enedis",[1.6132654999999991, 50.692568300000005]],
    [ "Enedis",[1.6093041, 50.69955925]],
    [ "Enedis",[1.6066689629381816, 50.696389927888674]],
    [ "Enedis",[1.6186636484285948, 50.68952371642718]],
    [ "Enedis",[1.6079335000000001, 50.69714975000001]],
    [ "Enedis",[1.5997142499999997, 50.69893235]],
    [ "Enedis",[1.61358315, 50.6999001]],
    [ "Enedis",[3.0932152499999996, 48.17552415]],
    [ "Enedis",[3.095414493526538, 48.175289967567316]],
    [ "Enedis",[3.1020851793688524, 48.17276863598906]],
    [ "Enedis",[3.097838876979465, 48.179840817018246]],
    [ "Enedis",[1.6305543, 50.68255305]],
    [ "Enedis",[1.539039, 48.4493684]],
    [ "Enedis",[3.1572011607386816, 48.20567617964411]],
    [ "Enedis",[3.1540251689563727, 48.206386955096]],
    [ "Enedis",[3.159067429373595, 48.20851924561721]],
    [ "Enedis",[3.157492400000001, 48.21100265]],
    [ "Enedis",[3.166332545791873, 48.220068516417804]],
    [ "Enedis",[3.1294957432127086, 48.19750040456047]],
    [ "SNCF Réseau",[1.5381467805433342, 46.34038932740504]],
    [ "Enedis",[0.7087102721437422, 47.34121564389407]],
    [ "Enedis",[0.6688647, 47.41498490000001]],
    [ "Enedis",[2.2664861684468467, 49.8700010992672]],
    [ "Enedis",[2.713333048924784, 44.739862597159565]],
    [ "Enedis",[1.571267388381573, 50.6706665851778]],
    [ "Enedis",[6.840855250000001, 46.25786155]],
    [ "TotalEnergies",[-4.072621786395727, 48.522262127560744]],
    [ "Enedis",[3.1389486811506893, 48.212703303666835]],
    [ "Enedis",[1.5892181842113486, 50.67207909886982]],
    [ "Poste de Lamanon",[5.092279250000001, 43.70894715000001]],
    [ "SNCF Réseau",[5.037890668300999, 43.61936458297954]],
    [ "Enedis",[6.839155714171029, 46.27528090942279]],
    [ "Soldanelles",[6.8406093000000014, 46.26521555]],
    [ "Enedis",[1.61839105, 50.719768249999994]],
    [ "Enedis",[1.6207280999999998, 50.723132400000004]],
    [ "Enedis",[1.630615938114923, 50.705706567473584]],
    [ "Enedis",[6.271892507057129, 46.11718532614416]],
    [ "Enedis",[6.263879049999999, 46.12089904999999]],
    [ "Comballes",[5.904896884649876, 46.48082180227498]],
    [ "Enedis",[2.91546235, 42.632787500000006]],
    [ "Enedis",[3.191908315244429, 48.172230074473]],
    [ "Enedis",[3.181115550000001, 48.1743833]],
    [ "Enedis",[3.1729596000000013, 48.1751745]],
    [ "Bourg",[-0.13879550000000002, 47.9676976]],
    [ "Les Braudières",[-0.07986321500003438, 47.986235748701695]],
    [ "Enedis",[6.673255154524388, 46.209742985969186]],
    [ "Enedis",[6.643664165852221, 46.2463690020899]],
    [ "Enedis",[6.6423094, 46.24485650000001]],
    [ "Enedis",[6.375532211752422, 47.80829450463035]],
    [ "Enedis",[6.380981854863643, 47.82024904715746]],
    [ "Enedis",[6.380490505085335, 47.8187112183053]],
    [ "Enedis",[-0.7330523593549699, 46.31905933039786]],
    [ "Électricité de France",[4.617256451649819, 49.927498987820904]],
    [ "Enedis",[6.721041356754251, 46.209829316219434]],
    [ "Enedis",[6.695345958412368, 46.195309480003935]],
    [ "Enedis",[3.195047201237975, 48.16088533294604]],
    [ "Enedis",[3.2676003, 48.15870820000001]],
    [ "Enedis",[1.6013224615931967, 50.70505658204115]],
    [ "Enedis",[1.6052337304379862, 50.71276514786782]],
    [ "Enedis",[1.6058063499999993, 50.71140329999999]],
    [ "Enedis",[6.758878184117168, 46.21445624468445]],
    [ "Enedis",[2.9572926946271423, 49.4847638190057]],
    [ "Enedis",[6.673352031410258, 46.19722237083334]],
    [ "Enedis",[7.3379877797317885, 43.90291081742733]],
    [ "Enedis",[6.555009955520287, 46.311389184733066]],
    [ "Enedis",[6.503720703104772, 46.286715896266585]],
    [ "Enedis",[4.867069749463169, 45.7254780457755]],
    [ "Enedis",[5.041385088482105, 43.694414284423424]],
    [ "Enedis",[5.0393799, 43.69464605]],
    [ "Enedis",[5.03686665, 43.69440575000001]],
    [ "Le cimetière",[0.07860486785050387, 47.82527752639908]],
    [ "L'arche",[0.07360015, 47.824814350000004]],
    [ "CLARINES",[6.748637785496864, 43.74574986869164]],
    [ "Cantine",[0.10614955587245503, 47.810916375362375]],
    [ "Enedis",[3.0544845000000005, 48.239352600000004]],
    [ "Enedis",[3.04955615, 48.23827385]],
    [ "Enedis",[4.941299225857933, 44.89415978479142]],
    [ "Enedis",[6.471738599999999, 46.24441310000002]],
    [ "Enedis",[6.47167745, 46.24443349999999]],
    [ "Enedis",[6.484696099999999, 46.24170009999999]],
    [ "RTE",[2.910495721433149, 49.73224480672459]],
    [ "Enedis",[5.034894467862026, 43.687035797064624]],
    [ "Enedis",[5.030733672123801, 43.68658985320607]],
    [ "Enedis",[5.023435116581489, 43.69094364880345]],
    [ "Enedis",[3.187662963803602, 50.633482127529476]],
    [ "Enedis",[3.2816977500000006, 48.08822094999999]],
    [ "Enedis",[3.2699106147264585, 48.09738616690016]],
    [ "Enedis",[3.2650769650348197, 48.09519381843396]],
    [ "Enedis",[3.259936496176707, 48.094447281285134]],
    [ "Enedis",[1.9476432760415954, 47.932444999724275]],
    [ "Enedis",[3.1929762347273427, 50.65109530444755]],
    [ "Enedis",[3.190819656462505, 50.653408679402524]],
    [ "Enedis",[3.294612967115097, 48.03424855189565]],
    [ "Enedis",[3.250225608677208, 48.02153142454042]],
    [ "Enedis",[0.07738740000000001, 48.4193039]],
    [ "Enedis",[0.07601747131034349, 48.419140297057474]],
    [ "Enedis",[-1.58601015, 49.0393711]],
    [ "Le Passous",[-1.5960632172325666, 49.03708234373859]],
    [ "Enedis",[6.418981300000001, 46.19400915]],
    [ "Enedis",[6.43812555, 46.18314579999999]],
    [ "Enedis",[4.501800871091554, 47.30948315976078]],
    [ "Enedis",[3.317920649999999, 48.0279059]],
    [ "Enedis",[3.31549605, 48.024178649999996]],
    [ "Enedis",[6.3930153, 46.14820635]],
    [ "Enedis",[5.0285773, 43.708402]],
    [ "Enedis",[5.158058850000001, 43.3904471]],
    [ "Enedis",[6.412994634036238, 46.15032758161146]],
    [ "Enedis",[7.0711378866607335, 43.644569617887875]],
    [ "Enedis",[5.428799199999999, 43.434307600000004]],
    [ "Enedis",[6.500391750000001, 46.353054]],
    [ "Enedis",[6.3935115499999995, 46.20165505000001]],
    [ "Enedis",[4.444019020848236, 49.84650540774767]],
    [ "Enedis",[-0.6270741, 44.952325900000005]],
    [ "Enedis",[2.060741367294975, 48.89137444734617]],
    [ "Enedis",[3.267250101370485, 48.20425703318862]],
    [ "Enedis",[3.2720171735875527, 48.203727655176934]],
    [ "Enedis",[6.443379500000001, 46.1338438]],
    [ "Enedis",[6.458761964262508, 46.138708782542786]],
    [ "Enedis",[6.4693254343813065, 46.14869234832044]],
    [ "Enedis",[3.3309109083310946, 48.0067814243035]],
    [ "Enedis",[3.3327317205855103, 48.00476019521956]],
    [ "Enedis",[3.34209005, 48.002697149999996]],
    [ "Enedis",[6.491475187092877, 46.123972032707904]],
    [ "Océanopolis",[-4.435733414754789, 48.39117648676912]],
    [ "Enedis",[2.060603633319854, 48.88179936533223]],
    [ "Enedis",[2.0708891815929467, 48.888719113024976]],
    [ "Enedis",[5.914477399609669, 46.31330491654911]],
    [ "Enedis",[2.3670397015903726, 51.03929196730406]],
    [ "Enedis",[1.6858089266201326, 42.7714566019049]],
    [ "Enedis",[1.5779753240111691, 50.69727275434896]],
    [ "Enedis",[1.5830935214097035, 50.69987570440963]],
    [ "Enedis",[1.5796327, 50.704274749999996]],
    [ "Enedis",[3.1988055342558632, 47.974997901957146]],
    [ "Couline",[3.1988263658850014, 47.97497369836741]],
    [ "Enedis",[3.196798238509088, 47.974807230064435]],
    [ "Enedis",[3.1968899214760405, 47.974108052415374]],
    [ "Enedis",[3.1969853072566226, 47.973975874049856]],
    [ "Enedis",[5.923164191385918, 46.34471143427939]],
    [ "Enedis",[5.716400592309856, 45.76743419671129]],
    [ "Enedis",[3.825404499999999, 43.6169515]],
    [ "Enedis",[3.3594188999999997, 47.974462800000005]],
    [ "Enedis",[3.3703714999999996, 47.971054650000006]],
    [ "Enedis",[6.340597000000001, 46.367878749999996]],
    [ "Enedis",[6.839573900966853, 47.96084062443728]],
    [ "Enedis",[3.51848518592591, 43.28455733618995]],
    [ "Enedis",[3.368431949999999, 47.9765382]],
    [ "Enedis",[3.864246421940258, 49.2586412630363]],
    [ "Enedis",[3.863067532362664, 49.25710040235403]],
    [ "Enedis",[3.8663217066978954, 49.25299816982028]],
    [ "Enedis",[3.375010750000001, 47.979567599999996]],
    [ "Enedis",[3.3761096000000013, 47.97885545]],
    [ "Enedis",[3.382678800000001, 47.9793374]],
    [ "Enedis",[-1.5870846620767727, 47.92782043145831]],
    [ "Enedis",[4.91329755, 43.75604715000001]],
    [ "Enedis",[-1.5853524999188973, 48.00412736242905]],
    [ "Enedis",[-1.5633165004879102, 48.01187049821764]],
    [ "Enedis",[0.09881456263605329, 47.31012897832889]],
    [ "Enedis",[5.884798124889131, 46.38784846134845]],
    [ "Enedis",[5.874940033233271, 46.382255667415535]],
    [ "Enedis",[3.386611049999999, 47.975161549999996]],
    [ "Enedis",[2.7380355987116367, 47.96152996338903]],
    [ "Enedis",[2.737767458009553, 47.964566221122695]],
    [ "Enedis",[2.7344506912003887, 47.964736377248]],
    [ "Enedis",[1.1628803958428313, 49.365362304376504]],
    [ "Enedis",[3.3909417307827483, 47.97457039793782]],
    [ "Enedis",[3.40032875, 47.97471590000001]],
    [ "Enedis",[6.499261338947272, 44.39175866354769]],
    [ "Enedis",[3.4008596769112853, 47.97320940281727]],
    [ "Enedis",[3.4065113000000005, 47.97235855000001]],
    [ "Enedis",[3.4064778499999986, 47.97237055]],
    [ "Enedis",[3.3850011763373513, 47.98364400407404]],
    [ "Enedis",[3.3901482833176506, 47.985615499854575]],
    [ "Enedis",[3.39198525, 47.98752765]],
    [ "Enedis",[1.5895786999999995, 50.70528425]],
    [ "Enedis",[1.5907484, 50.70237540000001]],
    [ "Enedis",[1.4904533097801644, 48.44550750057415]],
    [ "Enedis",[3.1440768419533165, 50.721524479152166]],
    [ "Enedis",[5.3809679326342685, 43.35420275440052]],
    [ "Enedis",[0.4487754634734088, 47.295060154609835]],
    [ "Enedis",[6.000554200000002, 47.25719065000001]],
    [ "Enedis",[5.999178304975022, 47.256471514851356]],
    [ "Enedis",[3.4073001294756162, 47.98324142920673]],
    [ "Enedis",[3.4065054000000003, 47.98310330000001]],
    [ "Enedis",[3.409549944279787, 47.98270352835091]],
    [ "Enedis",[3.4120801871299102, 47.97982781915408]],
    [ "Enedis",[3.4236240500000004, 47.973793199999996]],
    [ "Enedis",[3.4306220329048323, 47.97440090055139]],
    [ "Enedis",[4.9941568462605535, 43.59248202080107]],
    [ "Enedis",[3.4180631786900086, 47.977994704401986]],
    [ "Enedis",[3.4258538798785443, 47.97611408877731]],
    [ "Enedis",[3.4438441500000003, 47.98782979999999]],
    [ "Enedis",[3.4391855999999987, 47.99557699999999]],
    [ "Enedis",[3.43648067327362, 47.990705745018985]],
    [ "Enedis",[3.10758795, 48.0497583]],
    [ "Enedis",[1.5963983278813032, 50.71402791118446]],
    [ "Enedis",[3.135921810786068, 48.056112667241926]],
    [ "Enedis",[3.1237742500000008, 48.06048835]],
    [ "Enedis",[3.1029095409045744, 48.063836337235216]],
    [ "Enedis",[3.100060624289177, 48.06171908301242]],
    [ "Enedis",[3.0990938999999997, 48.06146914999999]],
    [ "Enedis",[3.0955278000000015, 48.06142545]],
    [ "Enedis",[3.093677509360594, 48.063012329518294]],
    [ "Enedis",[3.163922955669088, 48.051467630514125]],
    [ "Enedis",[3.16098365, 48.047782749999996]],
    [ "Enedis",[3.15719285, 48.03871130000001]],
    [ "Enedis",[3.157826683831435, 48.03651014854451]],
    [ "Enedis",[3.1860453, 48.05342045]],
    [ "Enedis",[3.197354899999999, 48.05414804999999]],
    [ "Enedis",[3.0934762494945947, 48.070568566654956]],
    [ "Enedis",[3.0927561499999996, 48.074852299999996]],
    [ "Enedis",[3.0909735924710677, 48.07736026804019]],
    [ "Enedis",[-2.9422865651174606, 47.39329302812699]],
    [ "Enedis",[-2.9825920214461323, 47.39461004516527]],
    [ "Enedis",[-2.9587415000000004, 47.3923952]],
    [ "Enedis",[-2.955294024554793, 47.39002608955656]],
    [ "Enedis",[-2.96315898631364, 47.38696487425978]],
    [ "Enedis",[3.3452525417212287, 48.31749871408419]],
    [ "Enedis",[1.1849724530932575, 44.53716808773322]],
    [ "Enedis",[-1.8188799915614118, 47.92841438022487]],
    [ "Enedis",[3.38368945, 48.3538253]],
    [ "Enedis",[6.04052045, 46.442552649999996]],
    [ "Enedis",[3.5181394000000004, 48.1784291]],
    [ "Enedis",[3.5133765761473836, 48.17998255372714]],
    [ "Enedis",[3.51738495, 48.18226394999999]],
    [ "Enedis",[3.521796877507936, 48.18370641534097]],
    [ "Enedis",[3.5313178, 48.184597950000004]],
    [ "Enedis",[3.5313107066761007, 48.184576421017084]],
    [ "Enedis",[3.4685588000000003, 48.175964400000005]],
    [ "Enedis",[3.4751907463970806, 48.17189993186158]],
    [ "Enedis",[3.478823365551109, 48.16798461297441]],
    [ "Enedis",[3.4831839706089105, 48.16782553172604]],
    [ "Enedis",[3.481256608439898, 48.198437332651324]],
    [ "Enedis",[3.452990696792997, 48.208627933546765]],
    [ "Enedis",[3.8867317447416334, 49.57350816708733]],
    [ "Enedis",[-3.1264507335465996, 48.04035595539565]],
    [ "Granges d'Agneaux",[6.44419452638718, 46.9014767444584]],
    [ "Enedis",[5.35650925, 43.355822249999996]],
    [ "Enedis",[5.3584647339119424, 43.359053065350615]],
    [ "Enedis",[5.351811201062675, 43.352870972389084]],
    [ "Enedis",[5.360557356651061, 43.34641697594722]],
    [ "Enedis",[3.405811121638184, 48.35080735293418]],
    [ "Enedis",[3.5626781032906036, 48.29901693259084]],
    [ "Enedis",[3.5721203771827463, 48.27343054527488]],
    [ "Enedis",[3.585916850000001, 48.264103000000006]],
    [ "Enedis",[1.915674450000001, 45.84268825000001]],
    [ "Enedis",[-2.076398064709669, 47.951370662033554]],
    [ "Enedis",[-1.9302391500000002, 48.032699199999996]],
    [ "Enedis",[-1.9409957966735336, 48.02835675037002]],
    [ "Enedis",[-1.8682677251846054, 48.03485046723183]],
    [ "Enedis",[6.309219315230124, 43.23546519639378]],
    [ "Enedis",[-1.6016555170170481, 47.85217972586464]],
    [ "Enedis",[2.1722436500000004, 47.17516825]],
    [ "Enedis",[-1.6432258575802732, 48.25544753640605]],
    [ "Enedis",[-3.2843164109623366, 47.87769228880889]],
    [ "Enedis",[3.209257862917835, 48.02090826974006]],
    [ "Enedis",[3.211333241343668, 48.00961616666667]],
    [ "Enedis",[5.35293675, 43.371500149999996]],
    [ "Enedis",[5.888067899999999, 46.3070698]],
    [ "Giulia",[1.5096831621085487, 43.543813064613346]],
    [ "réséda",[6.220746925136369, 49.10474786053304]],
    [ "Enedis",[-4.226267052198534, 47.86603713846702]],
    [ "Enedis",[-4.224667485822467, 47.86515002809855]],
    [ "Enedis",[5.436741832953215, 43.512268520328576]],
    [ "Enedis",[5.428170935297173, 43.51317824407638]],
    [ "Enedis",[3.3676180745734405, 48.096460543801484]],
    [ "Enedis",[6.27123045, 46.19350384999999]],
    [ "Enedis",[6.3090533256124335, 43.238451864964304]],
    [ "Enedis",[3.9093571999999996, 49.56736945]],
    [ "Volkswagen",[6.2166051850706125, 48.68992301749608]],
    [ "Enedis",[3.44382355, 48.18412565]],
    [ "Enedis",[3.4099848000000006, 48.16416195000001]],
    [ "Enedis",[0.2819786522347349, 47.131836416809826]],
    [ "Enedis",[0.20051125000000003, 47.3042372]],
    [ "MASSO",[-4.231314162481387, 47.8629328168241]],
    [ "Enedis",[-1.3295934062142365, 46.715407505089736]],
    [ "Enedis",[3.3743682391040535, 48.16504401951291]],
    [ "Enedis",[3.5871068164340305, 48.16450684542482]],
    [ "Enedis",[3.5465052744310284, 48.23681304679982]],
    [ "Enedis",[3.5541420499999994, 48.238384700000005]],
    [ "Enedis",[3.555397383393178, 48.23510352712449]],
    [ "Enedis",[3.5548599000000007, 48.2363525]],
    [ "Enedis",[3.558818573865113, 48.23458124382099]],
    [ "Enedis",[3.5462074745460352, 48.23388349698878]],
    [ "Enedis",[3.8732673999999996, 49.57081505]],
    [ "Enedis",[2.533211410252009, 47.99234628155373]],
    [ "Enedis",[5.4561008773261355, 43.44990364078534]],
    [ "Enedis",[5.4569257, 43.4452269]],
    [ "Enedis",[5.4566494500000005, 43.4453782]],
    [ "Enedis",[5.466128368101785, 43.443513843362574]],
    [ "Enedis",[2.0203712751789396, 47.59723463652644]],
    [ "Enedis",[3.3036745700539183, 48.13059341890361]],
    [ "Enedis",[3.29879915, 48.13201730000001]],
    [ "Enedis",[3.289095081959714, 48.13513528127071]],
    [ "Enedis",[3.3116589500000004, 48.13220885]],
    [ "Enedis",[3.3136621411214264, 48.130304318138165]],
    [ "Enedis",[2.71857931781305, 48.03479912821869]],
    [ "Enedis",[5.4840996, 43.463531100000004]],
    [ "Enedis",[5.483260183831851, 43.46286275239428]],
    [ "Enedis",[5.3639413021388735, 43.35632009439963]],
    [ "Enedis",[5.380369326176734, 43.35322760315391]],
    [ "Enedis",[2.207062224516435, 48.57312138246724]],
    [ "Enedis",[3.304596822415863, 48.127319642327585]],
    [ "Enedis",[3.3055738226463363, 48.123900062011884]],
    [ "Enedis",[3.3063212500000003, 48.12270335]],
    [ "Enedis",[3.3373929500000004, 48.128018]],
    [ "Enedis",[3.3114344000000004, 48.15090465]],
    [ "Gaston Genin",[1.4844112167526011, 43.57325234888705]],
    [ "Enedis",[3.2987247500000003, 48.1102257]],
    [ "Enedis",[3.3001074217734203, 48.1121126713395]],
    [ "Enedis",[5.348111238306337, 43.34306520081198]],
    [ "Enedis",[5.458150230126098, 43.447053202850874]],
    [ "Enedis",[5.45459555, 43.4595455]],
    [ "Enedis",[5.433278879848309, 43.473975991971685]],
    [ "Enedis",[3.2830761, 48.08652305]],
    [ "Enedis",[3.29327595180873, 48.19234323623136]],
    [ "Enedis",[3.34053035, 47.988571500000006]],
    [ "Enedis",[3.483614249999999, 47.9963315]],
    [ "Enedis",[1.9532198666265186, 47.914827710881966]],
    [ "Enedis",[1.9450276227881, 47.91020005297647]],
    [ "Enedis",[3.437114877917109, 48.3299390448023]],
    [ "Enedis",[3.441086163951269, 48.33008267370367]],
    [ "Enedis",[4.125927594801061, 43.69082326755084]],
    [ "Enedis",[-0.1924705221117862, 48.16058112928783]],
    [ "Enedis",[1.9465497922154775, 47.907533462104375]],
    [ "Enedis",[1.0482680491368261, 49.396521666340746]],
    [ "Tourterelles",[5.042152892836106, 45.795475025786345]],
    [ "Liberté",[5.0443172836184305, 45.79880210104641]],
    [ "Les Vernes",[5.039925583821226, 45.80015335618522]],
    [ "Route des Grottes",[5.6465986500000005, 46.70538975]],
    [ "Enedis",[3.508706500000001, 48.01816915]],
    [ "Enedis",[1.7830948761798218, 47.98553693525244]],
    [ "Enedis",[2.3308814854155644, 51.0346610994899]],
    [ "Enedis",[2.295122123590419, 51.00904939710346]],
    [ "Bourg",[-4.1549720500000005, 47.88711694999999]],
    [ "Enedis",[5.3632669, 43.37366205]],
    [ "Enedis",[3.521548782966226, 48.01922205042595]],
    [ "Enedis",[3.526090919558303, 48.026129443385805]],
    [ "Enedis",[3.5192438663142727, 48.042528701121256]],
    [ "Enedis",[5.367094566999456, 43.41878889369119]],
    [ "Enedis",[5.367642530413239, 43.41660513386426]],
    [ "Enedis",[5.36624645, 43.41201825000001]],
    [ "Enedis",[-1.2229184793154777, 46.98116211759386]],
    [ "Awa",[2.4212442265266265, 48.882557908843765]],
    [ "Enedis",[3.0343605772490196, 43.18162970471043]],
    [ "Enedis",[3.5620984500000006, 48.068697150000006]],
    [ "Enedis",[3.5679753672318495, 48.062215248994995]],
    [ "Enedis",[3.55374355, 48.0760903]],
    [ "Enedis",[5.4057716000000005, 43.436664949999994]],
    [ "Enedis",[3.5448738999999994, 48.08323805]],
    [ "Enedis",[5.393672999999999, 43.42718949999999]],
    [ "Enedis",[2.66280055, 48.614360399999995]],
    [ "Enedis",[2.68473555, 47.85264479999999]],
    [ "Enedis",[5.396912875726478, 43.433939262297194]],
    [ "Enedis",[5.399036450000001, 43.4263657]],
    [ "Enedis",[-1.3884212909176648, 48.80681005411634]],
    [ "Enedis",[-1.3860801618201881, 48.7995281660585]],
    [ "Enedis",[-1.4021532463510467, 48.80811382062401]],
    [ "Enedis",[-1.4040845703432876, 48.81657915108959]],
    [ "Enedis",[-1.4077836016222847, 48.82431659022361]],
    [ "Enedis",[-1.407299150446838, 48.82767346311601]],
    [ "Enedis",[-1.3951656941509145, 48.85685910585059]],
    [ "Enedis",[-1.4164965000623468, 48.83671320143399]],
    [ "Enedis",[-1.4193513609152615, 48.835975371766594]],
    [ "Enedis",[-1.3508094079005832, 48.83694422685643]],
    [ "Enedis",[-1.3283969033071814, 48.83839655538522]],
    [ "Enedis",[-1.2548482140516493, 48.84247280053637]],
    [ "réséda",[6.126935526204294, 49.194929261507966]],
    [ "réséda",[6.124228134016927, 49.19000484466145]],
    [ "réséda",[6.121628099999999, 49.189449649999986]],
    [ "Enedis",[4.100052667311828, 43.716182655340496]],
    [ "Enedis",[5.34487455, 43.41886895000001]],
    [ "Enedis",[5.330322169356105, 43.42852077121213]],
    [ "Enedis",[5.330346039339301, 43.42858271065019]],
    [ "Enedis",[5.330353183802149, 43.42570759941517]],
    [ "Enedis",[6.294619365860371, 43.28249575205183]],
    [ "Enedis",[5.367952415040729, 43.329547172143954]],
    [ "Enedis",[5.367108821508666, 43.32977626244463]],
    [ "Enedis",[5.3589591988926895, 43.33105786348982]],
    [ "Enedis",[6.129127901651585, 43.15268892973288]],
    [ "Enedis",[6.274969483458094, 43.181983997248594]],
    [ "Enedis",[5.349808231810536, 43.350237465399225]],
    [ "Enedis",[5.315295049024409, 43.281610567977815]],
    [ "Enedis",[5.313127878598855, 43.28096315249551]],
    [ "Enedis",[-1.4299668681571047, 48.78393606836256]],
    [ "Enedis",[-1.4927431748647326, 48.79003364456794]],
    [ "Enedis",[-1.5085984499999998, 48.805940299999996]],
    [ "Enedis",[-1.5399133543515398, 48.81116148815258]],
    [ "Enedis",[-1.5439100056486315, 48.81160488567438]],
    [ "Enedis",[-1.5258118997404277, 48.82242414388709]],
    [ "Enedis",[-1.5286066049557911, 48.8228427489937]],
    [ "Enedis",[-1.5273977002070405, 48.82397150151398]],
    [ "Enedis",[-1.5218129356567396, 48.82583861795548]],
    [ "Enedis",[-1.5413851372549707, 48.82333487538568]],
    [ "Enedis",[-1.5493350334157237, 48.82469251741698]],
    [ "Enedis",[-1.5513640474531432, 48.82599025930341]],
    [ "Enedis",[-1.5562235392269819, 48.80046725453714]],
    [ "Enedis",[-1.564634649315769, 48.798038509399326]],
    [ "Enedis",[-1.560560097125983, 48.792603320414734]],
    [ "Enedis",[-1.5624077125171336, 48.78585437593233]],
    [ "Enedis",[-1.5655636723608597, 48.78431819414973]],
    [ "Enedis",[-1.410896447650155, 48.684730994284166]],
    [ "Enedis",[6.117744699999999, 43.172518100000005]],
    [ "Enedis",[6.2128151, 43.22891465]],
    [ "Enedis",[6.18789905, 43.274934800000004]],
    [ "Armorique",[-1.4074969299652058, 48.26065212964518]],
    [ "Enedis",[-1.408935591283931, 48.261180435068766]],
    [ "Enedis",[5.413431065937594, 43.38822304620408]],
    [ "Enedis",[5.336583825337899, 43.36243368977435]],
    [ "Enedis",[5.336566370422625, 43.36239229228394]],
    [ "Enedis",[5.338700533357234, 43.36327804982282]],
    [ "Enedis",[5.336922475478835, 43.35951785399781]],
    [ "Enedis",[-1.5050656486197602, 48.72018690028709]],
    [ "Enedis",[-1.5027747538979237, 48.71789817557758]],
    [ "Enedis",[-1.4986190024524506, 48.71544661447427]],
    [ "Enedis",[-1.491704041879322, 48.713004264129054]],
    [ "Enedis",[-1.488746229947964, 48.70401280087924]],
    [ "Enedis",[-1.477692397491571, 48.70708459997533]],
    [ "Enedis",[-1.4876487629413584, 48.70716173111881]],
    [ "Enedis",[-1.4727542298821052, 48.707782345137694]],
    [ "Enedis",[-1.469509869456682, 48.70336350022028]],
    [ "Enedis",[-1.4658207344620855, 48.70194151412174]],
    [ "Enedis",[-1.2591403945107795, 45.9093921948481]],
    [ "Enedis",[-1.2325334685205818, 44.20144985401145]],
    [ "Enedis",[-1.4413104385281863, 48.699037074864655]],
    [ "Enedis",[-1.4395571184093146, 48.700292305937346]],
    [ "Enedis",[-1.4380870795884835, 48.70226448011173]],
    [ "Enedis",[-1.4149445711752635, 48.72492904051356]],
    [ "Enedis",[-1.4080864430798299, 48.72676998870257]],
    [ "Enedis",[-1.4432880239061752, 48.66846955448373]],
    [ "EDF SEI",[55.561343669363346, -21.34708649478875]],
    [ "Enedis",[6.115074207005191, 43.246697236729325]],
    [ "Enedis",[6.129548335863007, 43.249870390282354]],
    [ "Enedis",[6.096577865310672, 43.25058898449358]],
    [ "Enedis",[6.09581975713542, 43.25195566632501]],
    [ "Enedis",[2.797058266340202, 49.06882425044177]],
    [ "Tele de La Croix",[6.676934406494669, 45.85028731893211]],
    [ "Enedis",[2.6593284019983265, 47.65557004285081]],
    [ "Enedis",[2.65583105, 47.65763375]],
    [ "Enedis",[-1.4239564112464131, 48.66800430359516]],
    [ "Enedis",[-1.4101750750007258, 48.67254169510701]],
    [ "Enedis",[-1.4013680566326736, 48.67484334723174]],
    [ "Enedis",[-1.3972076845944834, 48.680021373793906]],
    [ "Enedis",[-1.3913283225418827, 48.68128544169681]],
    [ "Enedis",[-1.385127506851335, 48.676342087448525]],
    [ "Enedis",[-1.386153436640229, 48.67229313122956]],
    [ "Enedis",[-1.3753821295337931, 48.68395462175265]],
    [ "Enedis",[-1.3770404641967244, 48.68305505072734]],
    [ "Enedis",[-1.3736897355762934, 48.6864068394697]],
    [ "Enedis",[-1.3681580593634892, 48.678614904541185]],
    [ "Enedis",[-1.4095734881073139, 48.68798604764091]],
    [ "Enedis",[-1.3926964076364228, 48.69770329809641]],
    [ "Enedis",[-1.4719034543368876, 48.71485478261455]],
    [ "Enedis",[-1.4905354725545392, 48.72878830225219]],
    [ "Enedis",[-1.5058683398389356, 48.729694278350806]],
    [ "Enedis",[-1.4790422070836222, 48.748051120838085]],
    [ "Enedis",[-1.5183172254800072, 48.76353582009563]],
    [ "Enedis",[-1.5256014326655072, 48.762849413224345]],
    [ "Enedis",[-1.5241915920614069, 48.759542790381445]],
    [ "Enedis",[-1.521029330674863, 48.786489773059294]],
    [ "Enedis",[-1.5154087035701758, 48.79435254501132]],
    [ "Enedis",[5.341551447001768, 43.35066660325992]],
    [ "Enedis",[5.341169506574284, 43.35094046662369]],
    [ "Enedis",[5.3379328670864545, 43.35489213068036]],
    [ "Champblanc",[4.106939833891759, 45.10296312477958]],
    [ "Enedis",[3.7164334352385446, 49.529179423740715]],
    [ "Enedis",[-1.37046125827235, 48.68884654113177]],
    [ "Enedis",[3.746324716048295, 46.625836328132586]],
    [ "Enedis",[2.9493904284622796, 50.435327619716674]],
    [ "Enedis",[2.962375105060685, 50.43121031028154]],
    [ "Enedis",[2.957888846367316, 50.4279706360065]],
    [ "Enedis",[4.83061915, 44.12024365]],
    [ "Enedis",[-1.3787247984895987, 48.695425801690895]],
    [ "Enedis",[-1.3610722144555172, 48.69859388375378]],
    [ "Enedis",[-1.35978404561681, 48.70036470351967]],
    [ "Enedis",[-1.3246114442557904, 48.68808473962235]],
    [ "Enedis",[-1.5461684102239583, 48.734213221787705]],
    [ "Enedis",[-1.5431663300758591, 48.73393092439519]],
    [ "Enedis",[-1.5316260433237334, 48.73492861206867]],
    [ "Enedis",[-1.5387434999742906, 48.73350659930588]],
    [ "Enedis",[1.5300073332348918, 47.79580865093307]],
    [ "Enedis",[4.827896692868816, 45.72282161586193]],
    [ "Poste source Ampère",[2.339978216897276, 48.921888620189364]],
    [ "Enedis",[-1.4623931930703609, 48.7487212422932]],
    [ "Enedis",[-1.462344189537285, 48.75260179401402]],
    [ "Enedis",[-1.4584121365746736, 48.75203742343105]],
    [ "Enedis",[-1.453788068952119, 48.75575647412042]],
    [ "Enedis",[-1.4517401119314088, 48.75521832583527]],
    [ "Enedis",[-1.457373197459165, 48.75573499716341]],
    [ "Enedis",[-1.4506413924961745, 48.75010025643485]],
    [ "Enedis",[-1.4535038477569993, 48.75144544931651]],
    [ "Enedis",[-1.4685836537833248, 48.75154215114822]],
    [ "Enedis",[-1.4806457257556433, 48.74814368144695]],
    [ "Enedis",[-1.4477556040498905, 48.74643404705576]],
    [ "Enedis",[-1.4310036371643218, 48.75416066709192]],
    [ "Enedis",[-1.3946185660172679, 48.7340229559825]],
    [ "Enedis",[-1.434568836544622, 48.73672480512585]],
    [ "Enedis",[-1.4485960842492844, 48.73212744916952]],
    [ "Enedis",[2.808649673490517, 43.10026538940507]],
    [ "Enedis",[-1.5276561221814413, 48.739161863877534]],
    [ "Enedis",[3.2370431, 43.34066059999999]],
    [ "Enedis",[-3.081504581848914, 47.85207539501982]],
    [ "Enedis",[-3.181812075369505, 47.864306694614676]],
    [ "Enedis",[-1.5685410144831626, 48.76157601442882]],
    [ "Enedis",[0.9853175, 47.451329550000004]],
    [ "Enedis",[2.4207607913111415, 44.225166178385095]],
    [ "Enedis",[1.5687100636417182, 47.72969377000915]],
    [ "Enedis",[1.3969747000000001, 47.597957599999994]],
    [ "Enedis",[3.1159422500000002, 50.784982649999996]],
    [ "Enedis",[-1.3869436953329184, 48.80176552926778]],
    [ "Kergall",[-4.529517450000001, 48.58653925000001]],
    [ "Enedis",[3.9419189500000003, 43.589984650000005]],
    [ "Enedis",[-1.5582566722061697, 48.748239233653656]],
    [ "Enedis",[-1.56147944563675, 48.74817100200668]],
    [ "Enedis",[-1.5585678494149982, 48.751109700911286]],
    [ "Enedis",[-1.5690102441559535, 48.75521686514089]],
    [ "Enedis",[-1.5595548133543682, 48.74642670621846]],
    [ "Le Domaine",[5.144546649999999, 45.614786]],
    [ "Enedis",[-1.3126407007757965, 48.712591150355976]],
    [ "Enedis",[-1.3105132114998428, 48.70811328692605]],
    [ "Enedis",[-1.310731021720076, 48.71387056420747]],
    [ "Enedis",[-1.310854865835105, 48.71367501020027]],
    [ "Enedis",[-1.2772340494153414, 48.71184322629451]],
    [ "Enedis",[2.369119913483441, 47.82830501734965]],
    [ "Enedis",[2.370759390752619, 47.82981998370678]],
    [ "Enedis",[-1.344526326588246, 48.719270294769636]],
    [ "Enedis",[-1.2945937631106392, 48.733382807438325]],
    [ "Enedis",[-1.2423237795245328, 48.71609173879108]],
    [ "Enedis",[-1.2367745204167855, 48.71644048605767]],
    [ "Enedis",[-1.2408738406102202, 48.71599902480969]],
    [ "Enedis",[-1.2825853, 48.6789779]],
    [ "Enedis",[-1.3185447004809348, 48.75636634594412]],
    [ "Enedis",[-1.3505480891870827, 48.79201675082224]],
    [ "Enedis",[-1.362602182126409, 48.79595440194728]],
    [ "Enedis",[-1.4005464507716423, 48.80240480391954]],
    [ "Enedis",[-1.3325372311616406, 48.79929235612227]],
    [ "Enedis",[-1.2472636655441423, 48.75280175999377]],
    [ "Enedis",[-1.2351492968595896, 48.762558101699966]],
    [ "Enedis",[-1.1708483, 48.73771545]],
    [ "Enedis",[-1.1678062232603663, 48.73189001784677]],
    [ "Enedis",[2.6127931173730916, 42.61473341558164]],
    [ "Enedis",[2.4773237000000012, 44.4293354]],
    [ "Enedis",[-1.264077700399043, 48.75550408842777]],
    [ "Enedis",[-1.2208868370404338, 48.77122264409658]],
    [ "Enedis",[-1.2155640173902882, 48.77761229765689]],
    [ "Enedis",[-1.233999349914517, 48.79092550138784]],
    [ "Enedis",[-1.247196108035345, 48.79196054094355]],
    [ "Enedis",[-1.2627898639491981, 48.78778991667481]],
    [ "Enedis",[-1.2662452799450603, 48.78973344047649]],
    [ "Enedis",[-1.2709171987226606, 48.78926635569822]],
    [ "Enedis",[-1.270166306788519, 48.78672175234522]],
    [ "Enedis",[-1.2886563578382215, 48.77831185222278]],
    [ "Enedis",[-1.2947606512725034, 48.80628325223693]],
    [ "Enedis",[-1.2961679831489719, 48.80401670123541]],
    [ "Enedis",[-1.2744686953662132, 48.84469916136894]],
    [ "Enedis",[-1.2751024034582363, 48.849142550883094]],
    [ "Enedis",[-1.272692771452092, 48.85756000211352]],
    [ "Enedis",[-1.3008055923602297, 48.86043957201502]],
    [ "Enedis",[-1.2825667769914657, 48.859840145843464]],
    [ "Enedis",[-1.5607406151613252, 48.78245983484876]],
    [ "Enedis",[-1.555141870842124, 48.78559258031106]],
    [ "Enedis",[-1.5508770615460095, 48.79008444624359]],
    [ "Enedis",[-1.5516830406079622, 48.801914487198275]],
    [ "Ballofy",[4.73464395, 45.94259675]],
    [ "Enedis",[5.400453485748668, 43.334044696046064]],
    [ "Enedis",[5.400402414319549, 43.33407815366087]],
    [ "Enedis",[-1.5506317435528367, 48.7603409440724]],
    [ "Enedis",[-1.5552100761108272, 48.76092118950643]],
    [ "Enedis",[-1.5526477380939068, 48.772673844568665]],
    [ "Enedis",[-1.5427306428451082, 48.77158864888498]],
    [ "Enedis",[-1.5452486397055727, 48.791836758270435]],
    [ "Enedis",[-1.554201684933949, 48.78737451242336]],
    [ "Enedis",[-1.5474733136529049, 48.80358238923475]],
    [ "Enedis",[-1.5418168874341918, 48.80807775428013]],
    [ "Enedis",[-1.5055187445979674, 48.79139343518451]],
    [ "Enedis",[-3.981206372795489, 48.06241294078283]],
    [ "Enedis",[2.3663315499999995, 47.82939579999999]],
    [ "Enedis",[2.645114801534815, 50.792365990407404]],
    [ "Enedis",[6.215151350000001, 46.181933599999994]],
    [ "Enedis",[-1.739779375644926, 48.55082045273329]],
    [ "Enedis",[-1.7375435167215891, 48.554166844672494]],
    [ "Enedis",[-1.570364704210814, 48.767226649000634]],
    [ "Enedis",[-1.5652655200424297, 48.777649509192216]],
    [ "Enedis",[-1.5563953103906842, 48.814134171235246]],
    [ "Enedis",[-1.5575583429757491, 48.81771825099326]],
    [ "Enedis",[-1.5608497600083158, 48.81924445890158]],
    [ "Enedis",[-1.5471286948949592, 48.818613006465576]],
    [ "Enedis",[-0.9548677035589831, 48.583016636173554]],
    [ "Enedis",[5.3233442199242225, 43.410253406887406]],
    [ "Enedis",[5.321751757426017, 43.407960924804456]],
    [ "Enedis",[5.321557199999999, 43.406393599999994]],
    [ "Enedis",[5.287123064659452, 43.423466270192996]],
    [ "Enedis",[5.2869667, 43.4230252]],
    [ "Enedis",[-1.5586495006953625, 48.82475509978958]],
    [ "Enedis",[-1.582115052038315, 48.831245045305145]],
    [ "Enedis",[-1.570373234469308, 48.83167752527837]],
    [ "Enedis",[-1.5995884452160494, 48.83795154355536]],
    [ "Lefaucheux 182",[0.18706019403396243, 47.934267320629196]],
    [ "Enedis",[5.593253700000001, 43.393805799999996]],
    [ "Enedis",[-1.41070603564766, 48.80287014509374]],
    [ "Enedis",[5.279135986903021, 43.42935108147195]],
    [ "Enedis",[3.7399432499999987, 43.60419845]],
    [ "Le Foyer",[3.0404472683721373, 45.87122957687826]],
    [ "Enedis",[5.2296005999999995, 43.44012749999999]],
    [ "Enedis",[5.228545815053541, 43.43935024586271]],
    [ "Enedis",[5.228493500000001, 43.43931055]],
    [ "Enedis",[5.293976216215049, 43.46691650629824]],
    [ "Enedis",[5.315855749999999, 43.4622252]],
    [ "Enedis",[-1.3525957459380642, 48.82868282651018]],
    [ "Enedis",[-1.3645708370293699, 48.83129557891377]],
    [ "Enedis",[-1.3823497464365713, 48.862252447088174]],
    [ "Enedis",[-1.3740378010109693, 48.86335166447853]],
    [ "Enedis",[-1.3903495059880908, 48.864029048251865]],
    [ "Enedis",[-1.576338381694333, 48.86011057607463]],
    [ "Enedis",[-1.5651258605480751, 48.87936500210098]],
    [ "Enedis",[-1.5637560288360153, 48.880483139613766]],
    [ "Enedis",[-1.5653173622687295, 48.878361100385284]],
    [ "Enedis",[-1.563807078522666, 48.87714989404394]],
    [ "Enedis",[-1.5588413048836969, 48.87243759368486]],
    [ "Enedis",[1.9203769591983386, 47.92473211372362]],
    [ "Enedis",[1.9187370115322793, 47.92746366300404]],
    [ "Enedis",[1.919649093372335, 47.929503028502445]],
    [ "Enedis",[-3.505604029398519, 48.73816575370457]],
    [ "Enedis",[-3.4986296811963356, 48.744054656565005]],
    [ "Enedis",[1.9272130452751992, 47.94240826804631]],
    [ "Enedis",[1.9366140497173148, 47.95488293665093]],
    [ "Enedis",[4.769004269727327, 45.44334115746553]],
    [ "Enedis",[4.78483423804105, 45.44391078219822]],
    [ "Enedis",[1.9175654764901013, 47.92696236616682]],
    [ "1040792128",[1.9184268614369258, 47.92611471891679]],
    [ "Enedis",[1.9166071, 47.926131]],
    [ "Enedis",[1.9215506432029452, 47.92574599898587]],
    [ "Enedis",[1.9246566338967488, 47.92893140842002]],
    [ "Enedis",[1.947869999492889, 47.98337564974974]],
    [ "Enedis",[1.9606322484759782, 47.974057501558356]],
    [ "Enedis",[1.9546332056747977, 48.009666604395186]],
    [ "Enedis",[5.448460246285757, 43.45088447089838]],
    [ "Enedis",[5.447057, 43.450444399999995]],
    [ "Enedis",[1.9544189813155428, 47.925665004715725]],
    [ "Enedis",[1.9330838837285496, 47.79224350057608]],
    [ "Chanon",[5.69638977974614, 46.377713753546196]],
    [ "Enedis",[1.9173781980750315, 47.82071029807503]],
    [ "Enedis",[1.919473152453874, 47.827005478630774]],
    [ "Enedis",[1.9209028627454194, 47.826920517973186]],
    [ "Enedis",[1.9225160517516109, 47.83922400255419]],
    [ "Enedis",[1.9387096217947568, 47.834185125773885]],
    [ "Enedis",[1.938989146714903, 47.833615948771026]],
    [ "Enedis",[1.940238812767458, 47.83337281459139]],
    [ "Enedis",[1.9385392696159625, 47.833473153236696]],
    [ "Enedis",[-1.6004572522929381, 48.836893180538176]],
    [ "Enedis",[-1.5975819458860305, 48.83642585568333]],
    [ "Enedis",[-1.5950024444010975, 48.835922400489395]],
    [ "Enedis",[-1.5948299483655868, 48.8362606356219]],
    [ "Poste électrique Z.I. de Caldaniccia",[8.805245033840496, 41.95002137534896]],
    [ "Poste électrique de Loretto - Ajaccio-Ville",[8.716983291332546, 41.932333797084894]],
    [ "Poste électrique d'Aléria",[9.512763567694064, 42.1022654565797]],
    [ "Poste électrique de Lucciana",[9.448580308310621, 42.52736063898669]],
    [ "Poste électrique d'Aspretto - Ajaccio-Ville",[8.756196679825543, 41.930765246772296]],
    [ "Poste électrique du Rizzanese",[9.047004510720784, 41.70359596929054]],
    [ "Enedis",[1.9328572722517772, 47.942917736015275]],
    [ "Enedis",[1.9320612645327353, 47.94581161350358]],
    [ "Enedis",[1.9536461874147395, 47.94220094826364]],
    [ "Enedis",[1.9503948219482732, 47.942270494208955]],
    [ "Enedis",[1.9090609354337185, 47.93926677162563]],
    [ "Poste électrique de Sovenzia",[9.018164283191696, 42.32417464974731]],
    [ "Poste électrique de Lugo-di-Nazza",[9.315944250000001, 42.0914382]],
    [ "Poste électrique de Corscia",[9.045287327263267, 42.34077765373422]],
    [ "Enedis",[-1.5658247652285582, 48.83378976717716]],
    [ "Enedis",[-1.5642746579474307, 48.83226937660497]],
    [ "Enedis",[1.0959715293742054, 49.36289565257614]],
    [ "RTE",[2.721981215436428, 49.65090174701677]],
    [ "Enedis",[4.09953553352417, 44.11479825223479]],
    [ "Enedis",[-1.5730942349232464, 48.83713475265661]],
    [ "EHPAD Collonges",[4.6123158759546925, 45.849967096461704]],
    [ "RTE",[2.6115675400827145, 49.73666730367261]],
    [ "Enedis",[-3.5780746316080605, 48.514358446341774]],
    [ "Enedis",[-1.437091281413078, 48.76665144043156]],
    [ "Enedis",[-1.4262643618029225, 48.784446848708214]],
    [ "Enedis",[6.310422366666666, 43.238020533333334]],
    [ "Enedis",[-4.550980047337369, 48.377367064247416]],
    [ "Enedis",[4.148838387561944, 43.93987513131907]],
    [ "Merienne",[5.626319500000002, 46.97562165]],
    [ "Enedis",[5.609496000000001, 46.9791672]],
    [ "Enedis",[-1.4415602338349458, 48.76845407072051]],
    [ "Enedis",[-1.3904711999955905, 48.79583969981187]],
    [ "Village Fontaine",[-1.4609873064700278, 48.8408152024104]],
    [ "Enedis",[-1.4634871498780944, 48.82564719977936]],
    [ "Enedis",[-1.4687644209328388, 48.79091979380183]],
    [ "Enedis",[-1.4783442518739194, 48.68579250293261]],
    [ "Enedis",[5.323680656311067, 43.46075136574451]],
    [ "Enedis",[5.32379965, 43.46066725]],
    [ "Enedis",[-1.5632253289333844, 48.85320168625188]],
    [ "Enedis",[-1.5586008311177024, 48.85187722160013]],
    [ "Enedis",[-1.559235823547391, 48.84623850411665]],
    [ "Enedis",[-3.3894040224656785, 47.75754947915257]],
    [ "Enedis",[-3.3841080708444538, 47.769858829290776]],
    [ "Enedis",[-3.367290614702709, 47.75906957209721]],
    [ "Enedis",[-3.377299266692923, 47.732906266744344]],
    [ "Enedis",[-3.379912225399235, 47.75091278992915]],
    [ "Enedis",[-3.38594131336047, 47.73374256933562]],
    [ "Enedis",[-3.3901262401382004, 47.7425561531365]],
    [ "Uillemai",[-3.4406651633016097, 47.73394595363698]],
    [ "Jules Verne",[-3.439600801027396, 47.73644248482235]],
    [ "Kerouriec",[-3.4447124779828617, 47.72273366144178]],
    [ "Residence du Stole",[-3.4256730016215493, 47.70578650141264]],
    [ "RTE",[2.3464201339963453, 47.767286308612405]],
    [ "Paul Chevallier",[0.4713306944580861, 47.86664546792266]],
    [ "Enedis",[2.250970376188754, 48.82879595723262]],
    [ "RTE",[2.251709975695366, 48.82759888778659]],
    [ "SNCF Réseau",[2.165195688808518, 48.80004121600046]],
    [ "Église",[4.612327949999999, 45.8536957]],
    [ "Le Bourg",[4.611894799999999, 45.85205634999999]],
    [ "Électricité de France",[55.45475375182611, -20.87544241687254]],
    [ "RTE",[-4.471396509843993, 48.38589668938299]],
    [ "Enedis",[4.852845702562464, 46.793634381844335]],
    [ "MASSADE",[-1.00996255, 45.096037450000004]],
    [ "Enedis",[4.84913775, 46.79591975]],
    [ "Enedis",[4.851155895084857, 46.79464397001895]],
    [ "Enedis",[4.8585921, 46.78612725]],
    [ "Enedis",[5.333790649999999, 43.364153849999994]],
    [ "Enedis",[5.329473950000001, 43.365482650000004]],
    [ "Enedis",[5.32806767607146, 43.36557754393678]],
    [ "Enedis",[4.8305597, 46.791084]],
    [ "CA Anjou Maine",[0.1520733828358209, 48.0146514071863]],
    [ "Enedis",[5.273235850000002, 43.35929495]],
    [ "Enedis",[1.079961778249235, 45.880865314029045]],
    [ "Enedis",[4.991363604263419, 43.595051118914895]],
    [ "Enedis",[5.260153349999999, 43.3420692]],
    [ "Pierre de Ronsard",[1.32291615, 47.5911329]],
    [ "Enedis",[6.359068602524624, 45.29811741800947]],
    [ "Enedis",[-1.586795335862745, 48.841214571756026]],
    [ "Enedis",[-1.560556091194026, 48.8407740492324]],
    [ "Enedis",[-1.5665672748923303, 48.839804845044206]],
    [ "Enedis",[-1.5662587994706736, 48.83675131730723]],
    [ "RTE",[-3.050576172537091, 47.77300517945921]],
    [ "Enedis",[4.992532504148346, 43.601174166443634]],
    [ "Enedis",[4.9911109, 43.602200749999994]],
    [ "Enedis",[5.004267808608685, 43.60840807355362]],
    [ "Enedis",[4.992760914173222, 43.6078962232006]],
    [ "Enedis",[5.006865399999999, 43.616490899999995]],
    [ "Enedis",[5.0107764, 43.59748699999999]],
    [ "Enedis",[5.007644845064034, 43.597502975749805]],
    [ "Enedis",[5.007050020648026, 43.59498532100854]],
    [ "Enedis",[-1.5856092795155485, 48.83305205217022]],
    [ "Enedis",[-1.5400932199531203, 48.838073465115]],
    [ "Enedis",[4.769381700000001, 45.42754740000001]],
    [ "Enedis",[4.766913572112684, 45.42214879657221]],
    [ "Lido",[2.337896082706055, 48.81259529213395]],
    [ "Enedis",[3.935345434977946, 43.57615409271673]],
    [ "Enedis",[5.011947120200027, 43.58422537520941]],
    [ "Enedis",[4.85921288304318, 46.79738609891277]],
    [ "Enedis",[4.858365834256619, 46.795708375915474]],
    [ "Enedis",[5.179067275557155, 43.50463716094835]],
    [ "Enedis",[5.194273790196915, 43.49074401742482]],
    [ "Enedis",[5.19434135, 43.4907163]],
    [ "Enedis",[5.2804601, 43.418228199999994]],
    [ "Enedis",[1.9639836453225576, 47.8949207990913]],
    [ "Enedis",[3.257507426693934, 43.346633646563596]],
    [ "Enedis",[5.353356699999999, 43.21272110000001]],
    [ "Enedis",[5.349951817633683, 43.22840044678069]],
    [ "Enedis",[5.352549369815947, 43.23201814710872]],
    [ "Enedis",[5.17905247080292, 43.50066361824817]],
    [ "Enedis",[5.212282622492541, 43.495377502429385]],
    [ "Enedis",[1.4179310405413972, 47.243830562415056]],
    [ "Enedis",[5.360413617960159, 43.34723234249773]],
    [ "Salle de loisirs",[0.4175032665222615, 48.03883893308629]],
    [ "Lombron",[0.41894099999999995, 48.07881505]],
    [ "Enedis",[0.4568566424401789, 49.4754076002016]],
    [ "Enedis",[5.35349885, 43.23038305]],
    [ "Enedis",[3.0634698311590105, 50.662708814800375]],
    [ "Enedis",[3.0692858000000003, 50.66300375]],
    [ "Enedis",[-0.4517096636750552, 49.18435166449388]],
    [ "1049309582",[1.2821148129191093, 47.42345246286068]],
    [ "Enedis",[-1.5433329055112743, 48.828785367801416]],
    [ "Enedis",[5.321580904828618, 43.361575374186295]],
    [ "Enedis",[5.321716094386767, 43.35977162792299]],
    [ "Enedis",[5.329540153813876, 43.3586901160966]],
    [ "Juvigny",[6.289447953382786, 46.21002779118174]],
    [ "Enedis",[5.336213267907013, 43.35796194866214]],
    [ "Enedis",[6.268951450000001, 48.616633349999994]],
    [ "Enedis",[6.275670170208188, 47.154044953362344]],
    [ "Enedis",[6.275644311449777, 47.154082777702605]],
    [ "Enedis",[5.00525025, 43.59908634999999]],
    [ "Enedis",[3.8462508607436536, 43.5722666167489]],
    [ "Enedis",[4.178643650000001, 43.65932565]],
    [ "Enedis",[4.8431991, 46.793991999999996]],
    [ "Enedis",[4.835828665703041, 46.7797446074708]],
    [ "Enedis",[4.850680515827728, 46.79933470150048]],
    [ "Enedis",[5.33757825, 43.356679500000006]],
    [ "Enedis",[5.3150180833333325, 43.3599597]],
    [ "Enedis",[5.311627832758686, 43.37212065545915]],
    [ "Enedis",[5.3027978813669145, 43.36999606417708]],
    [ "Enedis",[5.298324300000002, 43.360170249999996]],
    [ "Clos Bellevue",[6.2509327, 46.18834409999999]],
    [ "RTE",[3.0239906440620263, 50.362244319682034]],
    [ "Enedis",[-1.5760549446842589, 48.857431975847504]],
    [ "Enedis",[-1.5696924344860752, 48.857834869024614]],
    [ "Enedis",[-1.5583041556912172, 48.858631744106056]],
    [ "Enedis",[-1.5616880032477454, 48.85576641698263]],
    [ "Enedis",[-1.5280113262684807, 48.87263870517472]],
    [ "Enedis",[-1.5616122162974029, 48.86532240658937]],
    [ "Enedis",[-1.5558047519103786, 48.85495244911101]],
    [ "Enedis",[-1.5539471368745719, 48.85425783655523]],
    [ "Électricité de France",[55.31546009999999, -21.0397196]],
    [ "Enedis",[2.718158250069047, 50.766372969708186]],
    [ "Caennaise",[-0.3433543930865524, 49.17466602414205]],
    [ "BHV",[6.216980979352386, 46.17898398869113]],
    [ "Enedis",[-3.642674773368553, 47.80932711264815]],
    [ "Enedis",[-3.6333255152943846, 47.80729776681453]],
    [ "Enedis",[-4.0580918453991535, 47.87991471827602]],
    [ "Enedis",[-4.057974373664905, 47.876033890803974]],
    [ "Enedis",[-4.0411403, 47.8571703]],
    [ "Enedis",[-4.043059250000001, 47.865971900000005]],
    [ "Enedis",[-4.2329089, 47.80010340000001]],
    [ "Enedis",[-4.040513449999999, 47.86037845]],
    [ "Enedis",[-3.9905649193513386, 47.90647954806892]],
    [ "Enedis",[-3.99545115, 47.905208849999994]],
    [ "Enedis",[-4.029046579786845, 47.875419077746464]],
    [ "Enedis",[-4.040318577013683, 47.85221094527521]],
    [ "Enedis",[-3.6402721760089163, 47.81255715534484]],
    [ "Enedis",[-4.0219196, 47.8799258]],
    [ "Enedis",[-4.34120535, 47.80421219999999]],
    [ "Enedis",[-4.285879199999999, 47.7932933]],
    [ "Enedis",[-4.297484782640121, 47.80180589739692]],
    [ "Enedis",[-4.027364083786817, 47.90090959564215]],
    [ "Enedis",[-4.283770300000002, 47.795249950000006]],
    [ "Berot",[-3.6342470420282442, 47.92409163204878]],
    [ "Lotissement du Stade",[-4.162833549999999, 47.8501018]],
    [ "Enedis",[-4.09636055, 47.99059019999999]],
    [ "Enedis",[5.174483400000001, 43.503546]],
    [ "Enedis",[5.1806948514963045, 43.503024634416946]],
    [ "Enedis",[5.175896599999999, 43.49331265000001]],
    [ "Enedis",[5.160950149999999, 43.47068010000001]],
    [ "Enedis",[5.165059329837769, 43.473282391684236]],
    [ "Enedis",[1.953184849716722, 47.89219989360002]],
    [ "Enedis",[1.9648053033320387, 47.89038394637641]],
    [ "Enedis",[1.9678988819483618, 47.889821981421306]],
    [ "Tribunal",[-0.348520072695952, 49.18397052497847]],
    [ "Enedis",[1.8932502557093431, 47.89090226952711]],
    [ "Enedis",[-1.495886527418091, 48.840963249932614]],
    [ "Enedis",[-1.55964003624897, 48.867645796794555]],
    [ "Enedis",[-1.5269249129534253, 48.88081402341929]],
    [ "Enedis",[2.387649502042853, 51.034189067610654]],
    [ "Enedis",[2.3852115506128593, 51.0336719694873]],
    [ "Enedis",[1.90407235, 46.342296000000005]],
    [ "Enedis",[1.9030934680884233, 46.34166492461671]],
    [ "Enedis",[-1.482857749163052, 48.84479840044574]],
    [ "RTE",[2.8045968516695683, 43.53685501786342]],
    [ "Enedis",[-1.5786482688091723, 48.83868060048739]],
    [ "Enedis",[2.378074107025308, 51.047048677397115]],
    [ "Enedis",[1.6553289466900913, 47.81857173360562]],
    [ "Paysanne 130004",[4.729559250067956, 43.64286504078829]],
    [ "Enedis",[5.319773752617805, 43.35688766742513]],
    [ "Enedis",[5.323846564054536, 43.35776156958544]],
    [ "Enedis",[5.327305491654294, 43.35576223052475]],
    [ "Enedis",[5.3273048, 43.35574485]],
    [ "Enedis",[5.324889768060464, 43.3551836973302]],
    [ "Enedis",[-1.5903517418030337, 48.840768179244876]],
    [ "Tolosana 13004",[4.654033049999998, 43.6591932]],
    [ "Enedis",[4.643570499999999, 43.6562945]],
    [ "Nadal 13004",[4.64224065, 43.66177859999999]],
    [ "Estajan",[4.64027762561482, 43.64763899655722]],
    [ "Reine 13004",[4.636800989620873, 43.66349013358916]],
    [ "Manadier 13004",[4.644607365851982, 43.66928765775405]],
    [ "Arcades 13004",[4.6444334, 43.66533685]],
    [ "Vallee 13004",[4.643428217040517, 43.664865350392915]],
    [ "Enedis",[4.645021865851983, 43.668741457754045]],
    [ "Enedis",[3.3195883857398196, 47.95256353160232]],
    [ "Enedis",[3.3297615168456383, 47.96097733273878]],
    [ "Enedis",[2.907425131334391, 42.57899453594264]],
    [ "Enedis",[2.906375375015934, 42.57976858910134]],
    [ "Enedis",[2.9034210702729615, 42.58205049741328]],
    [ "Enedis",[2.90813097541734, 42.57801904651835]],
    [ "Enedis",[2.904746975015935, 42.580412089101344]],
    [ "Enedis",[2.9034779837727043, 42.58204067950967]],
    [ "Gouloumès",[0.33138323262002306, 48.02224773756666]],
    [ "Enedis",[-0.55997325, 44.78482605]],
    [ "Enedis",[-0.5639149, 44.784474499999995]],
    [ "Enedis",[1.32905495, 43.46115459999999]],
    [ "SNCF",[5.450550250000001, 46.591121150000006]],
    [ "Calvaire 13004",[4.745203739122068, 43.63728044463191]],
    [ "Bellombre 13004",[4.728493791352662, 43.64891260088898]],
    [ "Enedis",[2.385645542104247, 51.039059626922054]],
    [ "Jouvernex",[6.421442539898132, 46.330325807923025]],
    [ "Enedis",[2.4140062998678475, 51.045756816394025]],
    [ "Poste Baleine",[-1.1262174500000002, 45.51448870000001]],
    [ "Douane",[-1.0654150000000002, 45.56802685]],
    [ "Enedis",[-1.1346749999999999, 45.500541750000004]],
    [ "Enedis",[4.76302722785494, 43.63494485587302]],
    [ "Velay 13004",[4.6236161000000005, 43.70476150000001]],
    [ "Heritier 13004",[4.6220904, 43.7081966]],
    [ "CCI 13004",[4.6263377, 43.700000749999994]],
    [ "Charmeryenne",[5.51724995, 45.02391725]],
    [ "Enedis",[5.3514483202209115, 43.452543790661394]],
    [ "Enedis",[5.3521788500000005, 43.4462427]],
    [ "Enedis",[2.4341831877675837, 51.058021388379196]],
    [ "Enedis",[2.436261, 51.048206449999995]],
    [ "Enedis",[2.3436400000000006, 51.04676715]],
    [ "Enedis",[2.3392356835837878, 51.043384449457534]],
    [ "Enedis",[2.324910737228992, 51.04477893423197]],
    [ "Enedis",[2.30006261186916, 51.03308240746754]],
    [ "Breuvant",[5.369904797938392, 45.88198823271285]],
    [ "Enedis",[-1.5128394806098007, 46.64506860276147]],
    [ "Enedis",[2.4234119161033436, 51.030729652519476]],
    [ "Enedis",[6.14327926078565, 43.22812896886477]],
    [ "Enedis",[6.1421455093246715, 43.22243602760095]],
    [ "Enedis",[6.1374959, 43.224121200000006]],
    [ "Enedis",[6.14460476679345, 43.22049074985617]],
    [ "Enedis",[6.1450197739731065, 43.21946998947834]],
    [ "Enedis",[6.15063545, 43.21904885]],
    [ "Repos Marin",[-1.12001, 45.50516825]],
    [ "Enedis",[2.371916714856364, 51.01942735240721]],
    [ "Enedis",[5.349497165808118, 43.37285242787296]],
    [ "Enedis",[5.3432505500000005, 43.37545925]],
    [ "Enedis",[5.341932370386741, 43.378818533621825]],
    [ "Enedis",[5.339765824197989, 43.366082409627666]],
    [ "Enedis",[1.84071945, 47.98075964999999]],
    [ "Enedis",[2.2322245, 43.592730599999996]],
    [ "1054417830",[3.92554476803919, 50.040525960722796]],
    [ "Station Epuration Stiring",[6.9442512999999995, 49.21142244999999]],
    [ "Frere",[5.6847270499999984, 45.05804125]],
    [ "Enedis",[6.3531928545761405, 46.35365573001109]],
    [ "Croix de Saint Hérem",[2.6924941500000004, 48.36280355]],
    [ "Enedis",[6.39569905, 46.272025150000005]],
    [ "Enedis",[2.9975455999999996, 42.9141944]],
    [ "Daniel 13097",[4.8157535000000005, 43.632266200000004]],
    [ "Enedis",[2.3285922976577695, 51.00385426367732]],
    [ "Fontenelle 13004",[4.72724485095122, 43.64112769991808]],
    [ "Enedis",[4.1091706000000014, 43.72140255000001]],
    [ "Enedis",[2.3201069823342033, 51.02635184315521]],
    [ "Resto ENT",[5.036077421162247, 45.77066619062826]],
    [ "Campus RTE",[5.036402624924159, 45.771726329424546]],
    [ "Fraisier",[5.035423641546786, 45.76809363478069]],
    [ "Enedis",[2.2854470351682, 51.00336503885417]],
    [ "1056285006",[-2.5400277297573677, 48.46466082398018]],
    [ "Poste électrique d’Avallon",[3.9195873318091348, 47.49664661473177]],
    [ "Poste source",[1.3869103683968693, 43.52938478021396]],
    [ "Enedis",[2.3188987372762537, 51.00384008034841]],
    [ "Enedis",[2.2964701308832787, 50.99680484787095]],
    [ "Enedis",[2.3017503000000006, 50.9929057]],
    [ "Enedis",[2.325234371060004, 51.01830095798196]],
    [ "Enedis",[5.495218925117344, 43.45604736685622]],
    [ "Enedis",[2.3383699172661103, 51.02610339930674]],
    [ "Enedis",[2.3222632406794386, 51.04027741671243]],
    [ "Enedis",[2.3115125243955097, 51.04156288758474]],
    [ "Enedis",[2.3467428499999996, 51.02461625]],
    [ "Enedis",[2.346947920882873, 51.03809665955821]],
    [ "Enedis",[2.3464739499999987, 51.03853804999999]],
    [ "Enedis",[6.83507255, 47.95970049999999]],
    [ "Enedis",[6.8276340499999995, 47.9571986]],
    [ "Enedis",[5.455363133275415, 43.41364416658079]],
    [ "Enedis",[1.6796086748009103, 50.668923295070165]],
    [ "Enedis",[1.6873803500000002, 50.66350715000001]],
    [ "Enedis",[5.439118456632933, 43.427182820765665]],
    [ "Enedis",[5.43525252978574, 43.427565910824704]],
    [ "Enedis",[2.3267941867299036, 51.023898746022425]],
    [ "SICAE Oise",[2.7472669638217933, 49.542709306974224]],
    [ "Enedis",[2.488438166974904, 51.04552324477396]],
    [ "Enedis",[5.428042339245924, 43.43183253277103]],
    [ "Enedis",[2.3444449499999997, 51.025334550000004]],
    [ "Enedis",[5.468106924384582, 43.4399420966693]],
    [ "Enedis",[1.5813108999999999, 50.66293329999999]],
    [ "Enedis",[1.59225405, 50.664397900000004]],
    [ "Enedis",[1.5992653000000003, 50.66553385000001]],
    [ "Enedis",[2.3200540659860156, 51.02141692788813]],
    [ "Enedis",[3.1622620744915992, 43.80988983870058]],
    [ "Enedis",[3.6733243499999997, 49.58359014999999]],
    [ "Poste électrique Aillas",[-0.05164485162199765, 44.514454620906044]],
    [ "Parouseaux",[1.8323759, 48.51520185]],
    [ "Enedis",[-1.905543544732777, 46.85378838000048]],
    [ "Enedis",[5.014603694775061, 43.5579715795915]],
    [ "Enedis",[3.17119095, 50.68393295]],
    [ "Enedis",[5.446345288399392, 43.41452382541546]],
    [ "Enedis",[5.437341057560912, 43.413454427029706]],
    [ "poste_143",[2.7579873647503375, 49.49576871292848]],
    [ "Enedis",[2.4078740089657114, 51.02422433115189]],
    [ "Enedis",[5.365605578610867, 43.39730284664888]],
    [ "Tele La Croix",[6.682397250000001, 45.85778245]],
    [ "Enedis",[6.695050976395331, 45.87233989749183]],
    [ "Enedis",[6.697682041990868, 45.8714270354925]],
    [ "Enedis",[6.672187300693676, 45.89047067998777]],
    [ "Enedis",[2.806658150000001, 49.40434904999999]],
    [ "Enedis",[5.130952640380106, 43.92142273690161]],
    [ "Enedis",[5.35106235, 43.33902160000001]],
    [ "Alberta",[1.8323768218217664, 48.64309895283587]],
    [ "Enedis",[6.0259348448831735, 46.23807667026425]],
    [ "Enedis",[6.1475857, 43.22787340000001]],
    [ "Enedis",[3.6854414552415746, 46.72377351872759]],
    [ "Savignac",[1.4498037035798765, 43.53368302217606]],
    [ "Peyraunet",[1.4449436878588096, 43.53750584032716]],
    [ "Enedis",[3.610386769856601, 46.65734885496983]],
    [ "Enedis",[1.9825784, 43.3074215]],
    [ "Girivel",[5.6288392634244815, 44.99637158214167]],
    [ "Bochet",[6.636345054954328, 46.35688996574615]],
    [ "Enedis",[1.5087658547012257, 47.69720360413188]],
    [ "Enedis",[2.6033429499999996, 50.730775]],
    [ "Enedis",[5.348188149999999, 43.66372655]],
    [ "Enedis",[2.175255334301603, 48.8946711988187]],
    [ "Jules Verne",[0.33117996613386685, 48.01998327156735]],
    [ "Bricoman",[0.24896510310978942, 48.004591214594484]],
    [ "Enedis",[2.5906612090238537, 50.97579065303787]],
    [ "Enedis",[-0.39801297952373976, 49.202393888671665]],
    [ "Enedis",[3.122368243816206, 50.78150080124692]],
    [ "SRD",[0.3098801019271318, 46.34226524855657]],
    [ "RTE",[4.883959050068308, 44.88948399613471]],
    [ "Enedis",[3.6632778976301235, 49.80867651281402]],
    [ "Les Favrats",[6.501600874356216, 46.30222529659389]],
    [ "Landes",[6.480302649999999, 46.31074040000001]],
    [ "Cimetiere",[-2.4841631550794725, 47.28140637770269]],
    [ "Enedis",[5.010684250000001, 43.60368029999999]],
    [ "Enedis",[4.998884416316108, 43.57588829603962]],
    [ "Enedis",[2.487554889677837, 47.34767096237208]],
    [ "Enedis",[2.439369284520888, 48.93515210040802]],
    [ "Enedis",[5.009106249999999, 43.57849055]],
    [ "Enedis",[3.1163859145434505, 50.78526979036953]],
    [ "Enedis",[3.1386587761592155, 50.78212371083883]],
    [ "Enedis",[2.9668064330557304, 42.53320819222711]],
    [ "Enedis",[6.499772000000001, 45.37968605]],
    [ "Enedis",[5.001797209843401, 43.58074171409397]],
    [ "Enedis",[3.5360930130073913, 50.374833339116364]],
    [ "Enedis",[3.1493025500000007, 50.78637175]],
    [ "Enedis",[3.149456504798498, 50.78597882954005]],
    [ "Enedis",[4.0784593, 48.291567738543975]],
    [ "Bas Trevois 5",[4.080325791549355, 48.293700083961006]],
    [ "Mairie",[6.009480299999999, 45.56576065000001]],
    [ "Enedis",[5.0002959, 43.51555285]],
    [ "Enedis",[5.010353096144678, 43.526784015181185]],
    [ "Enedis",[5.011143380453998, 43.54996177795819]],
    [ "Enedis",[3.131249859108717, 50.78565960712409]],
    [ "Enedis",[5.014226150000001, 43.57626915]],
    [ "Enedis",[3.1494750331701806, 50.73669373632279]],
    [ "Enedis",[3.1520792507801416, 50.73676137390071]],
    [ "Enedis",[6.038726199999999, 48.9340491]],
    [ "Enedis",[5.929557300000001, 48.74066555]],
    [ "Enedis",[5.036877275937075, 43.55039864714425]],
    [ "Enedis",[5.030689618179796, 43.54884182761242]],
    [ "Enedis",[5.242313851967787, 43.364571202094226]],
    [ "Enedis",[5.048400951357956, 43.53904073361922]],
    [ "Enedis",[5.00727605, 43.58782239999999]],
    [ "Enedis",[5.0097898, 43.58767615]],
    [ "Enedis",[5.0065685, 43.59186415000001]],
    [ "Enedis",[5.016537945114654, 43.58847337992281]],
    [ "Enedis",[5.014982267875476, 43.585317803283644]],
    [ "Enedis",[5.0243937999999995, 43.5890885]],
    [ "Enedis",[4.996234238951699, 43.597129436607254]],
    [ "Enedis",[4.973187931336597, 43.587063001962704]],
    [ "Enedis",[4.973280349999999, 43.5880288]],
    [ "Enedis",[5.449144225241723, 43.4525997593184]],
    [ "Enedis",[5.449957054515461, 43.44768208257829]],
    [ "Enedis",[3.1422321459148517, 50.788079671375286]],
    [ "Enedis",[3.1465217, 50.78290495]],
    [ "Stade",[5.7777015205664775, 44.954052585882756]],
    [ "Enedis",[3.1457163, 50.721419149999996]],
    [ "Enedis",[-1.3956173, 46.18086135000001]],
    [ "Le Peu des Hommes",[-1.4272595464529907, 46.192337581112696]],
    [ "Enedis",[4.766868471170095, 45.44581178845161]],
    [ "Poste électrique Berclau",[2.8586772281072994, 50.52088383771471]],
    [ "Enedis",[4.776874950000001, 45.460766150000005]],
    [ "Enedis",[-0.6501767630276294, 49.152115281125184]],
    [ "Enedis",[5.035568700000001, 43.5477179]],
    [ "Enedis",[2.1481897624832555, 50.749019521959504]],
    [ "Enedis",[2.1504843499999997, 50.7484043]],
    [ "Enedis",[2.6655751601127915, 42.653988131086045]],
    [ "Enedis",[5.560511599999999, 43.396436933333334]],
    [ "Enedis",[5.5613484500000006, 43.399784149999995]],
    [ "Enedis",[4.737380467924976, 45.741556480632305]],
    [ "Enedis",[6.158068000000001, 43.2318976]],
    [ "Enedis",[6.163890025093118, 43.23300704602588]],
    [ "Enedis",[6.153641615529774, 43.23591161081204]],
    [ "Enedis",[4.76827644507015, 45.45242995560567]],
    [ "Enedis",[2.1094979627344195, 50.21285278019151]],
    [ "Enedis",[1.8594253499999998, 47.94888015]],
    [ "Enedis",[1.4167963086646371, 46.31300797582008]],
    [ "Enedis",[1.4201682479801385, 46.31413928405801]],
    [ "Beaulieu",[-0.4230447158871066, 45.43869465205826]],
    [ "1069581295",[1.9336238499999996, 47.95579730000001]],
    [ "Enedis",[1.524756241387739, 45.03088062927409]],
    [ "Enedis",[-0.49119611905042876, 47.50030271664319]],
    [ "Enedis",[0.7771494912907119, 47.47555436516937]],
    [ "Enedis",[0.63192414587856, 47.16634863112137]],
    [ "Espace Vert",[6.503056824130743, 46.39405680899579]],
    [ "Enedis",[1.6344118255518025, 50.72249469701628]],
    [ "Las Borios",[1.8119803791316544, 43.84553124877114]],
    [ "Teillet",[1.8126449205427584, 43.84745009516146]],
    [ "Enedis",[1.7556891454480552, 50.16735103437718]],
    [ "Parc Emeraude",[6.503107210017863, 46.39337522918396]],
    [ "Enedis",[2.223129834214524, 48.68336379463733]],
    [ "M Prestault",[-1.7016143928149519, 48.100702495978595]],
    [ "Enedis",[1.5792112000000003, 48.4673774]],
    [ "Enedis",[1.5800349999999999, 48.46884560000001]],
    [ "Enedis",[0.6943773769798415, 47.3898178152705]],
    [ "Enedis",[1.2456074286268919, 43.33771104063109]],
    [ "Enedis",[1.2446263999999998, 43.334497049999996]],
    [ "Enedis",[0.8894389920619441, 45.905661404133866]],
    [ "Enedis",[2.8350625999999983, 42.61869699999999]],
    [ "Enedis",[2.834529224695229, 42.619484354374414]],
    [ "Enedis",[2.8316376253621955, 42.61883038978347]],
    [ "Enedis",[2.8344683590983903, 42.61829817061391]],
    [ "Enedis",[2.8324304341206097, 42.61765762496493]],
    [ "Enedis",[2.83511409859736, 42.616885733333326]],
    [ "Enedis",[2.835493099999998, 42.61575145]],
    [ "Enedis",[2.8378622562463107, 42.61555417868062]],
    [ "Enedis",[2.8354202500000003, 42.615354499999995]],
    [ "Enedis",[2.8372673754381355, 42.61674124586285]],
    [ "Enedis",[2.84101072499101, 42.61923080355987]],
    [ "Enedis",[2.8405928554552036, 42.618438028528416]],
    [ "Enedis",[2.8407596194794293, 42.61744792502099]],
    [ "Enedis",[2.842948374948902, 42.617450953567456]],
    [ "Enedis",[2.8427756, 42.6184437]],
    [ "Enedis",[2.8442931249489227, 42.61923535355976]],
    [ "Enedis",[2.8371632093465804, 42.62178902960275]],
    [ "Enedis",[2.836664115979236, 42.622581025035096]],
    [ "Enedis",[2.843291396578954, 42.62050168282919]],
    [ "Enedis",[2.841173008014858, 42.620753534556975]],
    [ "Enedis",[2.840135772161516, 42.62012418260173]],
    [ "Enedis",[2.8417573859759035, 42.616855146458114]],
    [ "Enedis",[0.16581210398795768, 47.94183951906871]],
    [ "La Balance",[6.142948999680383, 45.90913163858711]],
    [ "Enedis",[6.143671953097247, 45.909963065501614]],
    [ "Grange Coulou",[6.140538033286966, 45.91449880005251]],
    [ "Le Valentin",[6.151885050000001, 45.91077175]],
    [ "Enedis",[-0.38450116840336157, 49.25133771871149]],
    [ "Intermarché",[1.3537725618424696, 49.17093722685973]],
    [ "Enedis",[-0.30418543420217514, 49.29591067538254]],
    [ "Enedis",[-4.51553845, 48.3833993]],
    [ "Électricité de France",[55.28766087508923, -21.177067242140236]],
    [ "Argento 1",[1.47676205, 43.5335515]],
    [ "Enedis",[-3.2001192534999845, 47.91600686381742]],
    [ "Argillieres",[1.332638293027771, 49.16796452379533]],
    [ "Enedis",[0.9210054289631334, 45.896652563899046]],
    [ "Enedis",[0.919499107648083, 45.89821564948974]],
    [ "Enedis",[0.17103065000000162, 48.033826399999995]],
    [ "Bonevfaux",[5.974045300715193, 48.729741519016166]],
    [ "Piqueras",[6.129198650000001, 45.921953300000006]],
    [ "Enedis",[4.336232166396618, 47.69913260946322]],
    [ "Enedis",[-0.10163392474413062, 47.479618641328216]],
    [ "Square Botrel",[-3.74760094082425, 47.85116637374716]],
    [ "Poste électrique de Grand-Port",[2.1977706920958555, 50.96146256964353]],
    [ "RTE",[1.9637119584207348, 50.36828394398134]],
    [ "Enedis",[3.0617422754836636, 45.785800662763656]],
    [ "Enedis",[3.058377792313658, 45.79081212264233]],
    [ "Enedis",[3.0611821000000004, 45.7924432]],
    [ "RTE",[2.5198443312714267, 44.035685496831235]],
    [ "Enedis",[1.9659604499999999, 47.969449850000004]],
    [ "Enedis",[3.7028437000000003, 43.54611505]],
    [ "Enedis",[3.052547035718861, 43.01619916249862]],
    [ "Enedis",[4.981847004339606, 43.58800206824334]],
    [ "Enedis",[5.1085944350980474, 43.6366937067309]],
    [ "Enedis",[5.123484721461998, 43.62787620341973]],
    [ "Enedis",[5.088937949999999, 43.6971288]],
    [ "Enedis",[4.738277292571672, 45.37176652373086]],
    [ "1078273890",[2.5617074450708426, 48.64798095095746]],
    [ "1078273891",[2.562971080003733, 48.64928434767167]],
    [ "Olye",[2.564678568647157, 48.649492292738195]],
    [ "JATTEAU",[2.5789263399908853, 48.6320179922222]],
    [ "Camping Émeraude",[3.3622029436620684, 43.28777082816671]],
    [ "Enedis",[0.18763822521539553, 47.996532564967616]],
    [ "Enedis",[0.6352761436557381, 48.17999958236464]],
    [ "Enedis",[6.098280800000002, 46.333814200000006]],
    [ "Enedis",[1.3661415500000007, 43.94757275]],
    [ "Enedis",[1.368551184692431, 43.9439155173724]],
    [ "Enedis",[0.20301738273268613, 48.02933842328579]],
    [ "Enedis",[4.41986525, 45.34155725]],
    [ "Enedis",[4.7608310000000005, 45.4507489]],
    [ "Enedis",[0.7832139480017651, 45.99371254101352]],
    [ "Enedis",[0.7947621557308873, 45.98694134996719]],
    [ "Course",[1.52386534370259, 48.94537802166465]],
    [ "Enedis",[5.292171587372171, 43.40158338301312]],
    [ "1081212618",[2.8581511689150894, 49.79456992664643]],
    [ "Poste électrique des Taupinières",[3.9745186508659653, 48.6487328584135]],
    [ "Poste électrique de Fontaine-Galuche",[3.976081102033373, 48.64852754647181]],
    [ "Poste électrique de la Cotelettes",[3.9767443297187457, 48.65074487086163]],
    [ "Poste électrique de Petites-Noues",[3.9769921708138796, 48.65156958772129]],
    [ "Poste électrique de Coubertin",[5.091707069491959, 47.302436474651294]],
    [ "1081289299",[-1.5178446521123572, 49.09370726404639]],
    [ "1081289312",[1.2508438803875594, 46.70340935603291]],
    [ "Poste électrique de Saint-Médard",[-0.7217783044099904, 44.87674906018043]],
    [ "Poste électrique d'Arnac-sur-Dourdou",[2.9733781352431934, 43.7243676912226]],
    [ "La Salle",[4.864482328076395, 46.41397449695473]],
    [ "1081289590",[-0.48871479901287945, 43.1217616234154]],
    [ "Total Direct Energie",[3.3015487169950455, 46.272481079001956]],
    [ "RTE",[5.8249630713575575, 43.47235673858284]],
    [ "Energie développement services du Briançonnais",[6.63034383571664, 44.890059958267]],
    [ "1081289700",[6.7074675, 48.189901500000005]],
    [ "Enedis",[6.650315195042998, 45.18970276844542]],
    [ "Centrale",[6.302830134451075, 45.19592810484974]],
    [ "Enedis",[4.955131719500533, 47.652109012117656]],
    [ "Enedis",[6.212062014037301, 46.183606477212166]],
    [ "Camping",[6.271990116727551, 45.256827167680456]],
    [ "Tennis",[6.334243150000001, 45.2643387]],
    [ "Combe des Moulins",[6.326117601411291, 45.257455116221436]],
    [ "Enedis",[0.9013422304206187, 45.888603462687094]],
    [ "Enedis",[4.866161640130421, 45.46598426877053]],
    [ "Enedis",[-2.897125542805223, 47.71102784770832]],
    [ "Livraison St Jean",[6.360708285754637, 45.27643666693491]],
    [ "Henri Deville",[6.360612595356999, 45.27655780954599]],
    [ "Enedis",[-5.097181900000001, 48.46533854999999]],
    [ "EV Cars SAS",[5.225008399999999, 45.61392925]],
    [ "Sur la campagne",[6.2607205696832, 46.148089641072694]],
    [ "Chappuis",[6.2667869, 46.1252154]],
    [ "La Biolle",[6.232268947387088, 46.14447107496949]],
    [ "EDF",[6.243281299999999, 46.16379915]],
    [ "Enedis",[0.9089972328457216, 45.88780302045841]],
    [ "Enedis",[0.9222175944155718, 45.89311720953683]],
    [ "Enedis",[4.87479655, 45.45460965]],
    [ "Enedis",[6.399286933730951, 45.03340077034544]],
    [ "Enedis",[6.362975121307564, 45.039082203101884]],
    [ "Enedis",[0.2967582785919532, 48.192188582892875]],
    [ "Enedis",[-0.1303209141332484, 48.183740417454224]],
    [ "Enedis",[0.24025594999999997, 47.94295084999999]],
    [ "Enedis",[0.24043953358223422, 47.9441206329763]],
    [ "Noes Rondes 2",[-2.26130485, 47.2555632]],
    [ "Enedis",[6.37029975, 44.204364049999995]],
    [ "Enedis",[6.2944649, 45.746692499999995]],
    [ "Enedis",[0.6727980084450143, 46.002874393151615]],
    [ "Enedis",[-4.2679502000000005, 48.4466844]],
    [ "Enedis",[-2.3104032000000005, 47.25009065]],
    [ "Enedis",[6.42912691602721, 44.18748261423673]],
    [ "Enedis",[-1.350253373548658, 48.909132112509035]],
    [ "Z-A La Chartreuse",[1.3495375499999998, 49.1749417]],
    [ "Enedis",[5.738897153204531, 45.15863707364214]],
    [ "Enedis",[3.2943248, 48.215212050000005]],
    [ "Enedis",[3.0325155, 47.66279095]],
    [ "Enedis",[3.0202003333263483, 47.67024610010578]],
    [ "Enedis",[2.2234996612050746, 48.88591474843379]],
    [ "Enedis",[-1.45090429213198, 49.04090067978517]],
    [ "Enedis",[-1.45261735, 49.051499]],
    [ "Lay Marillet",[-1.1993369999999992, 46.54379125]],
    [ "Enedis",[4.999762119771927, 44.836284541664824]],
    [ "Enedis",[6.290126685067298, 44.15974218425304]],
    [ "Enedis",[6.294401168429394, 44.15621900864381]],
    [ "arzieras 510",[6.3125711588640625, 44.1615945554387]],
    [ "Enedis",[6.313183346307019, 44.16307708651179]],
    [ "Enedis",[6.349952556905128, 44.17110883130897]],
    [ "Enedis",[6.3454426541530085, 44.177306335116334]],
    [ "Enedis",[6.384255859888541, 44.208202145661275]],
    [ "Chanolles",[6.419064123427676, 44.15289383333334]],
    [ "Enedis",[6.413356029110954, 44.173749960731875]],
    [ "Enedis",[6.467484547439353, 44.236469771638205]],
    [ "Enedis",[4.971695570319875, 44.0181211021623]],
    [ "Enedis",[4.971716838248696, 44.01808845782349]],
    [ "Enedis",[0.24955401216383194, 49.401193839414766]],
    [ "Enedis",[3.2773608000000007, 49.820275599999995]],
    [ "ZAE Les Chassats",[0.7366839973404016, 45.88096324984824]],
    [ "Enedis",[2.182943067045643, 48.809689600805314]],
    [ "Cartonneries",[5.73047473482013, 45.08659309719022]],
    [ "Fox",[2.4421204432156047, 48.80058328697793]],
    [ "Enedis",[-2.3301783, 47.2579473]],
    [ "Enedis",[2.195783718847526, 48.81822308310699]],
    [ "Enedis",[5.4218147, 43.43081535]],
    [ "Centrale du Carrelet",[6.7248474686624755, 45.24868585593261]],
    [ "Enedis",[3.458521186806214, 49.98024821997919]],
    [ "1086686349",[6.345063895114151, 45.620093813444036]],
    [ "Enedis",[4.887073134409854, 45.700229143386025]],
    [ "Enedis",[3.4036872147421158, 50.329346612742306]],
    [ "Enedis",[-1.4147642000000002, 43.36641095]],
    [ "Enedis",[2.1970700073606726, 48.7949720192429]],
    [ "Enedis",[1.3770625499999987, 49.24584945]],
    [ "Enedis",[1.3771456140098721, 49.245823173473624]],
    [ "3 I Navigation",[5.977720049999999, 48.651957849999995]],
    [ "Warin",[1.2996794451550266, 49.20133438338656]],
    [ "Enedis",[-4.080947749999999, 48.51669785]],
    [ "EDF",[6.715062279870061, 45.22327090720512]],
    [ "Enedis",[3.9264316500000005, 43.660563549999985]],
    [ "EDF",[6.717150546419806, 45.215980566876574]],
    [ "transfo",[2.351424315565418, 50.68985525448062]],
    [ "Enedis",[5.017587550000001, 45.8392045]],
    [ "Enedis",[-0.1930531500000006, 45.21831415]],
    [ "SNCF Réseau",[3.3606305289424867, 46.442962462912554]],
    [ "La Barme",[6.656887050000001, 45.895710699999995]],
    [ "Plan Perret",[6.651133357203223, 45.89578822767986]],
    [ "Enedis",[0.924677684562529, 45.90015721033426]],
    [ "Enedis",[0.9380143649765301, 45.90321984388858]],
    [ "Enedis",[0.8922024233064965, 45.88981671977378]],
    [ "Enedis",[6.752473600000001, 45.88519825]],
    [ "Compostage",[5.137640685139289, 45.656981455390714]],
    [ "Enedis",[0.834879430083574, 47.37586630700505]],
    [ "Enedis",[0.8208693782371242, 47.37852803081253]],
    [ "Enedis",[0.8111577315720463, 47.38370864877459]],
    [ "Enedis",[-0.19051325000000002, 49.1096562]],
    [ "ESSeyssel",[5.836207432429523, 45.97025046970896]],
    [ "Enedis",[0.9199388816198475, 45.908216294050646]],
    [ "Enedis",[0.9300022139319237, 45.903580738374686]],
    [ "Enedis",[4.921460605419181, 45.52491478248612]],
    [ "Enedis",[-1.4073939, 43.3306657]],
    [ "Enedis",[0.8959578156483319, 45.982497500170545]],
    [ "EnvironnTech",[5.272165049999999, 45.6120333]],
    [ "Enedis",[1.3920246286890021, 43.934647734307894]],
    [ "Perret",[6.6418793822752065, 45.90043060523609]],
    [ "Enedis",[2.5136895343887034, 51.05445814996649]],
    [ "Enedis",[2.5123126, 51.06402685]],
    [ "Enedis",[2.534226866242079, 51.07216317578503]],
    [ "Enedis",[5.150418, 47.00458720000001]],
    [ "Enedis",[5.15073755, 47.0046613]],
    [ "Enedis",[4.9085082, 45.49681565]],
    [ "Enedis",[5.149031257621642, 46.99104805148395]],
    [ "Enedis",[3.85393175, 43.96496165]],
    [ "Herbline",[-0.2576260871670334, 49.27370688748854]],
    [ "EDF",[5.464781768027153, 46.21092450361708]],
    [ "SNCF Réseau",[4.837330284407125, 46.32459695246345]],
    [ "Enedis",[0.7576047500000006, 49.6038744]],
    [ "Enedis",[-4.570341381508168, 48.48713038543178]],
    [ "Enedis",[1.6027379016332426, 50.73616477473754]],
    [ "Enedis",[1.6123176000000001, 50.70724234999999]],
    [ "Enedis",[0.8879837359458899, 45.892448504584884]],
    [ "Enedis",[1.6455439005722734, 50.73174536098774]],
    [ "Enedis",[1.6454886999999998, 50.73026439999999]],
    [ "Enedis",[1.6448228836564047, 50.732911200342066]],
    [ "Enedis",[-1.8689484383221417, 46.865499965644084]],
    [ "Enedis",[0.8991084983333785, 45.87659365000308]],
    [ "1089932201",[4.911090333147405, 45.75819512697441]],
    [ "Enedis",[0.867466811983511, 45.88963685717352]],
    [ "Enedis",[2.6811729319033377, 44.82793644567101]],
    [ "Enedis",[2.190777273885971, 48.81011677377218]],
    [ "Enedis",[4.84367925, 45.6549908]],
    [ "Enedis",[4.845430524449152, 45.648424745585665]],
    [ "Enedis",[3.3684247999999997, 47.9537356]],
    [ "Enedis",[3.3740158, 47.95502485000001]],
    [ "Enedis",[3.3725744942536924, 47.95762283206995]],
    [ "Enedis",[3.3632686333333335, 47.95574833846154]],
    [ "Enedis",[3.3561141499999994, 47.95633865]],
    [ "Enedis",[3.3582756189055263, 47.958648719686295]],
    [ "Enedis",[3.36200375, 47.959671799999995]],
    [ "Enedis",[1.31066575, 45.8160912]],
    [ "EDF SEI",[9.305408834110853, 41.55084304614392]],
    [ "Enedis",[1.8924687000000002, 49.2732276]],
    [ "Enedis",[1.887479705124044, 49.289099532892806]],
    [ "Enedis",[3.344535307877879, 47.9388755768812]],
    [ "Enedis",[3.347453, 47.933109599999995]],
    [ "Enedis",[3.34951885, 47.929966750000006]],
    [ "Enedis",[0.836244446463269, 45.91167879176184]],
    [ "Enedis",[0.36424260419537746, 46.67252141723479]],
    [ "Enedis",[3.228424368775927, 48.11812412905]],
    [ "Enedis",[3.219222759020152, 48.11913591658824]],
    [ "Enedis",[6.2840930961340735, 45.74525941813625]],
    [ "Enedis",[6.293517396356694, 45.74388213158144]],
    [ "Enedis",[-2.7458684, 47.97428165]],
    [ "Enedis",[-2.7489862065095405, 47.98266132873126]],
    [ "Enedis",[-2.7475147812475935, 47.973314992102]],
    [ "Enedis",[1.3852081500000004, 49.175605649999994]],
    [ "Enedis",[-2.712332236804446, 47.96317281697687]],
    [ "Le Pont",[1.351651433008356, 49.175309600442326]],
    [ "Enedis",[4.4967034564905255, 47.31385200924337]],
    [ "Enedis",[4.4950500333333325, 47.314850448172756]],
    [ "Enedis",[4.50838132974625, 47.30711414708005]],
    [ "Enedis",[4.5042887479177365, 47.317540951745485]],
    [ "Poste Quai sud",[9.285606883617445, 41.58917782248075]],
    [ "Enedis",[3.306966478882683, 47.649661852637045]],
    [ "Enedis",[3.40977985, 47.756260499999996]],
    [ "Enedis",[3.4082003999999997, 47.75936265]],
    [ "Poste électrique Garrigou",[2.82172974819299, 43.62682042246934]],
    [ "Enedis",[0.7401494743934774, 45.88666640132638]],
    [ "Route de Saint-Georges",[0.37693203656518176, 46.663356681039964]],
    [ "Enedis",[4.362634750000001, 46.681653850000004]],
    [ "Enedis",[6.07394135, 43.221572699999996]],
    [ "Enedis",[6.0696879, 43.225775950000006]],
    [ "Enedis",[6.0712682, 43.229456500000005]],
    [ "Enedis",[6.06871855, 43.2309208]],
    [ "Enedis",[6.071296950000002, 43.23134325]],
    [ "Enedis",[-1.2511833, 46.79087831666667]],
    [ "Rémy Becker",[1.3577658166666675, 49.172904499999994]],
    [ "Enedis",[-0.1517236078511091, 47.74705293351177]],
    [ "Enedis",[-0.16281830686505117, 47.76809658011254]],
    [ "Enedis",[6.218249805206296, 47.606778921811404]],
    [ "1091935551",[4.8896813, 44.0244795]],
    [ "Enedis",[3.62528245, 47.99041520000001]],
    [ "Enedis",[4.5994071000000005, 45.523286500000005]],
    [ "Enedis",[6.709951666508673, 45.92016138146755]],
    [ "Poste électrique de Courbevoie",[2.2606905483876525, 48.90564606341387]],
    [ "Enedis",[-2.9758084459224494, 48.07195081774574]],
    [ "Lolo Houssais",[1.3866462332407041, 49.171728948895066]],
    [ "Enedis",[-0.500178702441357, 43.89894190129241]],
    [ "Enedis",[2.7733684308103097, 50.296948998120605]],
    [ "Enedis",[-2.959032605116391, 48.07431071847613]],
    [ "Enedis",[-2.965641741551724, 48.059610665908274]],
    [ "Enedis",[-2.95979535, 48.0615126]],
    [ "Enedis",[-2.962787405644991, 48.06003306864816]],
    [ "Enedis",[3.624885749999999, 47.985542499999994]],
    [ "Enedis",[3.6283035000000003, 47.980799149999996]],
    [ "Enedis",[3.6167129805637437, 47.99327047508905]],
    [ "Golf Panorama 1",[7.075183016859894, 43.61889111955443]],
    [ "Château",[1.3979836507591559, 49.17263793295932]],
    [ "Enedis",[6.070040850000001, 43.239687950000004]],
    [ "Enedis",[2.4405176816476866, 51.01640149777309]],
    [ "Enedis",[3.61283355, 47.98579470000001]],
    [ "Enedis",[3.607048245979084, 47.985975472708525]],
    [ "Enedis",[-2.8758963403737927, 48.06614468492483]],
    [ "Enedis",[3.608218421640932, 47.99309954566887]],
    [ "Enedis",[3.6121549531150268, 47.99612021783064]],
    [ "Enedis",[3.6105503499999982, 47.98998619999999]],
    [ "Enedis",[3.5876209574183098, 47.98814791999144]],
    [ "Le Cochard",[5.227580550833895, 45.649193317901435]],
    [ "Enedis",[3.5794759870349133, 47.98746682553478]],
    [ "Enedis",[3.5780389627862084, 47.98419437535164]],
    [ "Enedis",[3.571495244051924, 47.985968419348545]],
    [ "Enedis",[-4.265069996621031, 48.44298587389301]],
    [ "Enedis",[-2.753712178909746, 48.06957526708247]],
    [ "Enedis",[-2.8943650999999986, 48.0642862]],
    [ "Enedis",[4.6842778, 46.66954749999999]],
    [ "Enedis",[3.5657445385139885, 47.955078423542226]],
    [ "REThones",[6.367882864672285, 45.86032877464122]],
    [ "REThones",[6.368253968156297, 45.85034310586987]],
    [ "Joux",[6.390114933776163, 45.853910366750284]],
    [ "REThones",[6.409820800000001, 45.84416389999999]],
    [ "REThones",[6.41575595, 45.8378746]],
    [ "Enedis",[-1.9842839500000002, 46.76166475000001]],
    [ "Enedis",[-2.0005347159132043, 46.765404232987045]],
    [ "Enedis",[2.5097089694958297, 49.90819323587552]],
    [ "Enedis",[-1.065660091572189, 46.78646762542055]],
    [ "Enedis",[-1.0654121469356346, 46.77661771716516]],
    [ "Enedis",[2.7592581078215077, 49.70545132421762]],
    [ "Enedis",[3.4832305841646884, 47.951524724069685]],
    [ "Enedis",[2.865626746472492, 50.53277838199505]],
    [ "Enedis",[5.667693199999999, 45.138269699999995]],
    [ "Enedis",[5.6714589274506135, 45.122398403292166]],
    [ "Enedis",[5.600345712324045, 46.76711291263658]],
    [ "Engie",[4.32351455, 43.93896535]],
    [ "Enedis",[5.936782417921149, 45.251788931182794]],
    [ "Enedis",[5.97431434330173, 45.23732562690496]],
    [ "Pont de Charniaz",[6.10257465, 45.7220316]],
    [ "Enedis",[4.834322091484319, 46.57549632113385]],
    [ "Enedis",[3.5114384845321225, 47.959330097925495]],
    [ "Enedis",[3.5166604316069052, 47.95813474568393]],
    [ "Enedis",[5.954256974565407, 47.2975635046857]],
    [ "Enedis",[5.9529491499999985, 47.29575239999999]],
    [ "1096232497",[5.964815649999999, 47.283744]],
    [ "1096236171",[5.975183050000001, 47.28198170000001]],
    [ "Col de Tamié",[6.3087157287862885, 45.67278199265477]],
    [ "Enedis",[4.0561564, 49.43360129999999]],
    [ "Enedis",[5.974123199999999, 47.31386125000001]],
    [ "Enedis",[5.97735205, 47.3167525]],
    [ "Enedis",[5.972685633249564, 47.31967292988568]],
    [ "Enedis",[6.923919150000001, 47.60602505000001]],
    [ "Enedis",[6.9483769, 47.60556665000001]],
    [ "Martinet",[5.1003426, 45.6469729]],
    [ "Enedis",[4.700200745165677, 45.58673376995752]],
    [ "Enedis",[6.134684050000001, 47.432285050000004]],
    [ "Enedis",[6.763054916299991, 47.569596078153666]],
    [ "Enedis",[4.564184134887647, 45.42196689988086]],
    [ "Enedis",[4.765654399999999, 45.43852785]],
    [ "Enedis",[3.5197327166900103, 47.96954670025904]],
    [ "Enedis",[4.7686972, 45.4707619]],
    [ "Enedis",[4.7727595, 45.468693449999996]],
    [ "Enedis",[4.716242476743241, 45.55227416044408]],
    [ "Enedis",[4.715436858924204, 45.554633615484924]],
    [ "Enedis",[4.717254879845497, 45.56027710997999]],
    [ "Enedis",[4.712119532382311, 45.56542489904897]],
    [ "Enedis",[4.72120855, 45.57591225000001]],
    [ "Enedis",[4.722760759198404, 45.57678313462077]],
    [ "Enedis",[4.726583850000001, 45.58010670000001]],
    [ "Enedis",[4.7367479, 45.579425449999995]],
    [ "Enedis",[4.7403584500000004, 45.58136414999999]],
    [ "Verchères",[6.308128217889662, 45.73816944341664]],
    [ "Enedis",[3.527178712135271, 47.9759094258011]],
    [ "Enedis",[4.731448250000001, 45.60386505000001]],
    [ "Engie",[4.349831241067309, 43.8737617294932]],
    [ "Enedis",[3.0559234500000003, 42.5056077]],
    [ "Enedis",[3.5210394499999995, 47.964019400000005]],
    [ "Enedis",[3.5264884499999996, 47.963737249999994]],
    [ "Enedis",[3.5284667175968125, 47.96495340113509]],
    [ "Enedis",[3.5260397894824482, 47.96084305184053]],
    [ "1097912618",[1.2873062192544134, 44.08026143980745]],
    [ "Enedis",[6.035105500000002, 47.34890465000001]],
    [ "Enedis",[5.983302982400334, 47.3174218990986]],
    [ "Enedis",[5.978150990120217, 47.318148524072704]],
    [ "Enedis",[5.9881454000000005, 47.31045985]],
    [ "Enedis",[5.909390370472593, 47.2976007656872]],
    [ "Enedis",[5.867336899999999, 47.308851999999995]],
    [ "1098087184",[5.862565319399461, 47.31697200204678]],
    [ "1098090186",[5.8694304, 47.31903964999999]],
    [ "Enedis",[5.86353015, 47.3127583]],
    [ "1098094479",[5.872293175303173, 47.31353664544889]],
    [ "Enedis",[3.5384865999999997, 47.9733234]],
    [ "Enedis",[3.5413650999999997, 47.97456595]],
    [ "Enedis",[3.54565365, 47.973972550000006]],
    [ "Enedis",[4.6451545, 44.3649057]],
    [ "Enedis",[5.9152645999999995, 47.2554073]],
    [ "Enedis",[5.939635750000001, 47.25673565]],
    [ "Enedis",[5.9535695185427215, 47.24751990502457]],
    [ "1098292629",[1.6774581000000002, 46.78605974999999]],
    [ "Enedis",[4.746995350000001, 44.03282515]],
    [ "Enedis",[1.3479218499999999, 44.121950600000005]],
    [ "AC3M Rond-Point",[0.3573803229910434, 49.73898521114254]],
    [ "Enedis",[3.0503268927965856, 50.59537199809823]],
    [ "Enedis",[3.466330983508152, 47.96833168307632]],
    [ "Enedis",[3.469944970669395, 47.96502324134788]],
    [ "Enedis",[2.9811834828968102, 50.39445920220933]],
    [ "PT Bas Rayol",[6.4789964474875905, 43.157832968795866]],
    [ "Enedis",[2.369925829195857, 48.901101174830764]],
    [ "Enedis",[3.58575015, 47.786604149999995]],
    [ "Enedis",[2.164838300000001, 49.26067394999999]],
    [ "1100636552",[1.6391029500000014, 47.25297975]],
    [ "1100814862",[1.4196412613641178, 46.63391667910864]],
    [ "Le Galy",[4.042954950000001, 45.2691449]],
    [ "1100833874",[1.5120457850996891, 46.591135275427796]],
    [ "Enedis",[5.4875219, 43.326834500000004]],
    [ "Enedis",[2.91026835, 50.2557138]],
    [ "Enedis",[6.093122749999999, 48.568622950000005]],
    [ "Enedis",[6.217129423274704, 47.578563720652944]],
    [ "Enedis",[3.5205842499999993, 43.64976449999999]],
    [ "Enedis",[2.6438545563007256, 50.44492026770072]],
    [ "Enedis",[5.794544907954659, 44.94353093925133]],
    [ "Enedis",[4.832579381723073, 45.895660064635244]],
    [ "Enedis",[1.3920308985727967, 43.980288566164916]],
    [ "Enedis",[5.843331394725072, 44.859639466164154]],
    [ "Enedis",[-1.3267671330968158, 45.981062749739216]],
    [ "Enedis",[2.0119676924532723, 47.927519067363924]],
    [ "Enedis",[1.9725254646450379, 47.91065167548898]],
    [ "Enedis",[3.4128874223126897, 47.88410969769052]],
    [ "Enedis",[1.9474039965945944, 47.907745716526016]],
    [ "Enedis",[3.411327390180921, 47.8875686665786]],
    [ "Enedis",[3.410702550000001, 47.88193625]],
    [ "Enedis",[2.921666458375874, 47.41382767161825]],
    [ "Enedis",[2.936849200000002, 47.416378400000006]],
    [ "Enedis",[2.9416187999999996, 47.42152629999999]],
    [ "Enedis",[1.9093304999999978, 47.90085545000001]],
    [ "Enedis",[1.9078007235357088, 47.89997000713049]],
    [ "Enedis",[2.99201658835433, 47.54079340603365]],
    [ "Enedis",[2.968319890384434, 47.40935411511287]],
    [ "Enedis",[2.9797976419130032, 47.41032302196589]],
    [ "1103117728",[1.876751789199304, 48.26674172327115]],
    [ "1103130008",[1.9370487046789198, 48.29603148143738]],
    [ "1103146544",[1.9106202000000003, 48.35355415]],
    [ "Enedis",[5.598658383604022, 43.21119733127649]],
    [ "Enedis",[5.599912783263696, 43.21081595008299]],
    [ "Enedis",[5.594812533127647, 43.210237155715156]],
    [ "Enedis",[5.596076252997749, 43.20990796987152]],
    [ "Enedis",[5.593846516072026, 43.21052644916159]],
    [ "Bourg",[6.7086132191779155, 46.180377802995686]],
    [ "Enedis",[5.765126858779087, 45.78460171931096]],
    [ "Enedis",[5.7690674500000005, 45.787167950000004]],
    [ "Enedis",[2.9236776, 47.40831675]],
    [ "Enedis",[2.927653558784006, 47.41138697125865]],
    [ "Enedis",[2.93035465024856, 47.41142661889587]],
    [ "Enedis",[2.927734533850247, 47.41297019928972]],
    [ "Enedis",[-4.265465533072482, 48.447375502633356]],
    [ "Enedis",[5.631774054892322, 43.201744716112366]],
    [ "Enedis",[2.949920224815262, 50.41630057807354]],
    [ "Enedis",[2.9193282486018997, 47.40101741544458]],
    [ "Enedis",[-4.320801549999998, 48.47283040000001]],
    [ "Cemoby",[5.035705931557684, 45.76811418104874]],
    [ "Enedis",[2.4552829826298654, 48.21031685193056]],
    [ "Enedis",[2.915793682643716, 47.395679362661376]],
    [ "Enedis",[2.9222740000000003, 47.39561890000001]],
    [ "Enedis",[2.922560108743337, 47.39431697521783]],
    [ "réséda",[6.19261885393242, 49.001374565706854]],
    [ "Enedis",[2.9226530402131496, 47.388223524889774]],
    [ "Enedis",[2.9241429000000005, 47.38868304999999]],
    [ "Enedis",[2.922698688850142, 47.387042979905196]],
    [ "Enedis",[2.927690999999999, 47.3936173]],
    [ "Enedis",[2.92557706255369, 47.39451762510958]],
    [ "Enedis",[2.934488140720689, 47.39720881542305]],
    [ "Enedis",[2.9291756148721992, 47.399365319458646]],
    [ "Enedis",[-4.235017618431787, 48.4356268182708]],
    [ "Enedis",[3.595653937948027, 47.944248556839504]],
    [ "Enedis",[3.6236963859175564, 47.9289610755796]],
    [ "Enedis",[2.928740048093307, 47.405304521424725]],
    [ "Enedis",[2.9386750999999998, 47.40591520000001]],
    [ "Enedis",[4.733941520565075, 46.938018376833675]],
    [ "Enedis",[2.3602254477523514, 49.097017832400205]],
    [ "Enedis",[2.937206594714685, 47.409263614919986]],
    [ "Enedis",[2.936059534323306, 47.41185569385535]],
    [ "CNR",[5.812552236601825, 46.05185518879169]],
    [ "Enedis",[2.8331619710348486, 50.141760427644414]],
    [ "Enedis",[2.9318965999999986, 47.41818845000001]],
    [ "Enedis",[4.738339578690514, 45.713016037423436]],
    [ "Enedis",[2.0852449081663704, 48.652600977605026]],
    [ "Enedis",[2.1086206819468876, 48.64737110794268]],
    [ "Enedis",[2.939142296450915, 47.413549534184604]],
    [ "Enedis",[6.374339972713859, 49.45914055288668]],
    [ "Enedis",[2.9480985349702826, 47.40453215347075]],
    [ "Enedis",[2.95016545, 47.40307515]],
    [ "Enedis",[2.921213468638607, 47.37378295192059]],
    [ "Enedis",[2.9134303931957284, 47.379521514972595]],
    [ "Enedis",[2.9127120162358273, 47.378373026757366]],
    [ "Enedis",[3.075266806095098, 47.52635806800462]],
    [ "Enedis",[0.20285391697398514, 49.70682703214643]],
    [ "Enedis",[2.3436905999999995, 48.600904150000005]],
    [ "Ramey",[5.03900799704721, 47.323528491043604]],
    [ "Avee 2000",[5.038861729802535, 47.323364482544]],
    [ "Enedis",[3.0697806073912126, 47.52993783265503]],
    [ "Enedis",[3.06929105, 47.53264085]],
    [ "Enedis",[2.3451479646344504, 48.59884199605634]],
    [ "Enedis",[3.1291836666897317, 47.374397946615204]],
    [ "Enedis",[3.1345074499999996, 47.37382105000001]],
    [ "Enedis",[3.125966783649953, 47.3694020525635]],
    [ "Enedis",[3.122484528721959, 47.36776713340166]],
    [ "Enedis",[3.1219238999999996, 47.36890544999999]],
    [ "Enedis",[4.946571215178655, 44.96180859890285]],
    [ "Enedis",[2.6063444231361776, 46.34783723979746]],
    [ "Enedis",[2.325740402334386, 48.604257066609655]],
    [ "Enedis",[2.3542982, 48.603348499999996]],
    [ "Enedis",[2.3178061499999996, 48.60138089999999]],
    [ "Enedis",[2.3220684, 48.5950539]],
    [ "Enedis",[2.912852, 43.218602000000004]],
    [ "Enedis",[2.0525714443031053, 47.97304254233186]],
    [ "Enedis",[2.0514575486559954, 47.97539299972514]],
    [ "Enedis",[2.058295603516408, 47.939593299891804]],
    [ "Enedis",[2.0158828592274345, 47.95558545270477]],
    [ "Enedis",[2.014824296176556, 47.96480130230697]],
    [ "Enedis",[2.0069206454310997, 47.934788914345376]],
    [ "Enedis",[2.010015856839341, 47.93726143387172]],
    [ "Enedis",[2.0285429336279863, 47.89690206996851]],
    [ "Enedis",[2.028603874986561, 47.89138965464708]],
    [ "Enedis",[2.0576284052885567, 47.90670642598319]],
    [ "Enedis",[3.0223315294333393, 48.24189961146995]],
    [ "Enedis",[2.3336873711065826, 48.58460495742228]],
    [ "Enedis",[2.719012109522092, 42.85589799175786]],
    [ "Enedis",[2.344373359480174, 48.6018878537751]],
    [ "Enedis",[2.3474407368312664, 48.59928505639002]],
    [ "Enedis",[2.351858610060888, 48.60426336629745]],
    [ "Enedis",[2.346586399589388, 48.58459921656668]],
    [ "Enedis",[2.346908392178596, 48.58478721501655]],
    [ "Enedis",[0.7941354060945739, 47.28349838077461]],
    [ "Enedis",[2.2775558, 48.6750559]],
    [ "Husser Widolf",[7.266573108304891, 47.73318798418907]],
    [ "Gérédis",[-0.5676860998493977, 46.22772229062499]],
    [ "Gérédis",[-0.5779355546896029, 46.22975597653593]],
    [ "Gérédis",[-0.5791938516101873, 46.225032883697]],
    [ "1109066309",[-0.5841288998333728, 46.232761308727085]],
    [ "Gérédis",[-0.5857453084050429, 46.23998446654133]],
    [ "Gérédis",[-0.5833320636388238, 46.24187004736241]],
    [ "Gérédis",[-0.5780531438787254, 46.24110614644427]],
    [ "Gérédis",[-0.5874462405519125, 46.228431458370416]],
    [ "Gérédis",[-0.5974591371465919, 46.22183838672491]],
    [ "Enedis",[6.316608182124155, 46.8519042468602]],
    [ "Enedis",[0.8514573252344971, 49.806790395493785]],
    [ "Enedis",[1.2961116350377069, 42.78729139538169]],
    [ "Enedis",[-0.6356796643622216, 45.76862623215936]],
    [ "Poste HLM",[2.11797275, 42.50128984999999]],
    [ "Enedis",[2.76556050009285, 49.70022027920149]],
    [ "Enedis",[2.765605729052464, 49.70021994003932]],
    [ "Enedis",[2.7656035502325342, 49.70014341827937]],
    [ "Enedis",[2.7655601536598304, 49.70014346246614]],
    [ "Enedis",[2.765597, 49.700035699999994]],
    [ "Enedis",[2.7656943, 49.69998540000001]],
    [ "Enedis",[2.7656927838281535, 49.69996149475061]],
    [ "Enedis",[2.7660035999999995, 49.699375100000005]],
    [ "Enedis",[5.379610899969832, 47.47282754904144]],
    [ "Enedis",[-1.3264438993333088, 45.98282361649938]],
    [ "Enedis",[1.9048122500000013, 47.9232998]],
    [ "Enedis",[4.9132944, 47.07690725]],
    [ "Enedis",[5.345289950000001, 43.66125160000001]],
    [ "Enedis",[5.350355967918139, 43.66679847222163]],
    [ "Enedis",[2.1125281, 47.9753611]],
    [ "Enedis",[5.32144245, 43.6531387]],
    [ "Enedis",[2.0274078500000017, 48.0403169]],
    [ "Enedis",[2.0499503240343513, 48.06742993466618]],
    [ "Enedis",[3.5612700873499734, 47.869825539555144]],
    [ "Enedis",[3.56124865, 47.86979939999999]],
    [ "Enedis",[0.19282380000000002, 47.94099115]],
    [ "Verchères",[5.115935249999999, 45.69151485]],
    [ "Enedis",[0.9477293303345018, 47.486715129680775]],
    [ "SIVOM Séchex",[6.40486081407273, 46.35144075219127]],
    [ "Enedis",[3.2062842609039617, 50.71546784824251]],
    [ "Enedis",[3.6544097977762795, 49.47397723018817]],
    [ "Enedis",[-0.32935815659823997, 49.1370509]],
    [ "Enedis",[-0.33137949532188277, 49.136392487923004]],
    [ "Enedis",[4.973003589967717, 47.77882715891147]],
    [ "Enedis",[5.3577620778922945, 43.662273547146555]],
    [ "Enedis",[5.362354657198815, 43.66100031488201]],
    [ "Enedis",[2.2745574, 47.861046200000004]],
    [ "Enedis",[-2.360473211864092, 46.728955730707945]],
    [ "Enedis",[3.8454153291622957, 49.49750805271384]],
    [ "BG Marin",[2.321318894383198, 48.80526409022271]],
    [ "Enedis",[5.7407628487736, 48.21612475616956]],
    [ "Enedis",[1.870330516666667, 50.399289700000004]],
    [ "Enedis",[4.982235598557228, 43.99715435317305]],
    [ "Enedis",[2.198347649999999, 46.56220939999999]],
    [ "Enedis",[6.716051255639909, 46.085227670730575]],
    [ "Enedis",[4.190606150000001, 43.891614000000004]],
    [ "Enedis",[4.194994200061509, 43.91965906891901]],
    [ "Enedis",[4.19598522008158, 43.919376123961925]],
    [ "Enedis",[4.195013599999999, 43.919723499999996]],
    [ "Enedis",[4.19829345, 43.91922675000001]],
    [ "Enedis",[4.199735345565041, 43.91784848207894]],
    [ "Enedis",[4.196895306642594, 43.92781847339963]],
    [ "Enedis",[4.19881353342129, 43.93050559987658]],
    [ "Enedis",[4.202826133443161, 43.932255283323606]],
    [ "Enedis",[4.20646565, 43.932192150000006]],
    [ "Enedis",[4.223133693966235, 43.94340692530279]],
    [ "Enedis",[4.2291238308296455, 43.94511074768514]],
    [ "Enedis",[4.21373625, 43.94503985]],
    [ "Enedis",[4.2251609342685015, 43.94229731513908]],
    [ "Enedis",[4.2281150315145695, 43.9440945520584]],
    [ "Enedis",[4.230733485429972, 43.94667257589655]],
    [ "RTE",[5.352826692319773, 43.33607119254607]],
    [ "Enedis",[1.8294761, 47.398493800000004]],
    [ "Enedis",[-0.31965992045459113, 49.13058867522639]],
    [ "Enedis",[-0.3085160261051803, 49.1098954595554]],
    [ "Enedis",[4.205714619263261, 43.946937913986986]],
    [ "Enedis",[4.212739605462879, 43.95147883066962]],
    [ "Enedis",[4.215384224717146, 43.9425400086162]],
    [ "Enedis",[4.212583367251825, 43.94308738341186]],
    [ "Enedis",[1.9367837996654362, 47.526413279684434]],
    [ "1117879111",[1.1026253, 47.23060575]],
    [ "Centrale Gemilly",[6.3172675798760825, 45.511975437469566]],
    [ "Enedis",[0.32960890000000004, 47.55452185000001]],
    [ "Enedis",[6.15079505, 49.29240015]],
    [ "Enedis",[1.9470539246813987, 47.919172718945795]],
    [ "Enedis",[2.6010111999999994, 46.3671819]],
    [ "Auto Center",[6.4334357348685645, 46.348134982965014]],
    [ "Philippe Mahut",[2.6842759837888512, 48.4043135325326]],
    [ "Enedis",[2.5146553999999997, 50.68187175]],
    [ "Enedis",[2.7167365085420303, 50.423080421102696]],
    [ "Enedis",[1.30245433143566, 43.602675409138]],
    [ "Enedis",[2.14441625, 47.67823225]],
    [ "Enedis",[5.907697298543444, 48.54024048021673]],
    [ "Enedis",[1.354237283710619, 43.59330869949499]],
    [ "Enedis",[1.9724193731832156, 47.96671400382004]],
    [ "Enedis",[1.8907116698540483, 47.94660410956868]],
    [ "Enedis",[1.892130881242579, 47.94669209634742]],
    [ "Enedis",[1.8901103997920947, 47.94162760955567]],
    [ "Enedis",[1.8617524627452942, 47.95705023211708]],
    [ "Enedis",[1.8881606485074305, 47.916041775011976]],
    [ "Enedis",[1.7966894000000002, 48.0183605]],
    [ "Enedis",[3.12033801218257, 50.31218263254752]],
    [ "Aneo",[6.2327594500000005, 46.188292800000006]],
    [ "Enedis",[1.8892663758953225, 47.962142582379045]],
    [ "Enedis",[1.8910983533115364, 47.96123400026263]],
    [ "Enedis",[2.5666715500000006, 46.349423550000004]],
    [ "Enedis",[3.1580641962529445, 50.73029145137661]],
    [ "1120863518",[1.2647583510872973, 44.66810985066039]],
    [ "Enedis",[1.2803486773727006, 44.67723430646983]],
    [ "PDL",[1.9205246350608052, 43.921777648908844]],
    [ "Enedis",[1.9235047867659285, 43.92225382197079]],
    [ "PDL",[1.5464277108042905, 45.006702192994126]],
    [ "Enedis",[-1.2094207806288224, 47.082895602764076]],
    [ "RTE",[5.708512861229774, 47.760186191838315]],
    [ "PDL",[-0.8282750422090799, 46.924415450582444]],
    [ "1121060565",[1.731245502442866, 47.28989213309426]],
    [ "Enedis",[1.765143895422217, 47.29339484964845]],
    [ "Enedis",[1.922140967103978, 47.39889757687441]],
    [ "Enedis",[1.9144357518652, 47.39249595622934]],
    [ "PDL",[1.8367277000000006, 47.815686250000006]],
    [ "Enedis",[1.8368611089981675, 47.81579327541017]],
    [ "Enedis",[1.8215431499999999, 47.84963435]],
    [ "Enedis",[1.8119964000000002, 47.861612]],
    [ "Enedis",[1.8049089453560379, 47.83023375319224]],
    [ "Le Clos du Levant",[6.28016136523848, 46.067657352686346]],
    [ "Enedis",[1.9294782302559932, 47.866526158315104]],
    [ "1121196705",[3.3699476563199977, 48.53370330091989]],
    [ "Enedis",[-0.7747336499999987, 44.89596295]],
    [ "FURST_PLAST",[4.600912653563183, 43.695457251669865]],
    [ "1121663511",[0.19609591917676375, 45.67061915028894]],
    [ "Enedis",[0.19554996737431168, 45.67068036469376]],
    [ "Enedis",[2.9755222613179755, 50.400279484672396]],
    [ "Enedis",[1.924672846401606, 47.91510212905319]],
    [ "Enedis",[3.126657229540265, 50.642405205219816]],
    [ "Enedis",[0.49381215000000006, 45.67379125000001]],
    [ "Enedis",[2.967770393177317, 43.14660043025824]],
    [ "Enedis",[0.2669224624916199, 48.02021055372038]],
    [ "Enedis",[3.9628037999999997, 49.60774945]],
    [ "Enedis",[3.96692, 49.610665099999984]],
    [ "Enedis",[3.9640820499999996, 49.61039164999999]],
    [ "Enedis",[3.1335940417768033, 50.59532346158681]],
    [ "Enedis",[0.09817360391978787, 48.02226812344133]],
    [ "Cruets",[6.5176721, 46.34469325]],
    [ "Enedis",[5.3245681438070935, 43.65314481729075]],
    [ "Enedis",[5.33368505, 43.6533932]],
    [ "Enedis",[2.8075662967409527, 47.66809452999896]],
    [ "Enedis",[3.1188701499999993, 50.594110300000004]],
    [ "Enedis",[3.130816618804984, 50.59765146395055]],
    [ "Enedis",[2.83723245817955, 42.578344928959865]],
    [ "Enedis",[2.835276676576913, 42.576924303041054]],
    [ "Enedis",[2.835644942284284, 42.577227974142104]],
    [ "Enedis",[2.8360424441569925, 42.577434371399086]],
    [ "Enedis",[3.7863557147258438, 49.63380642516781]],
    [ "Enedis",[3.7810527244961247, 49.63166377891473]],
    [ "Enedis",[2.4807028, 47.76906385000001]],
    [ "Px sculpteurs dp1",[2.250083734213388, 48.88754593102937]],
    [ "Enedis",[2.9920578000000004, 42.621597799999996]],
    [ "Enedis",[2.9913042659443807, 42.620871598774954]],
    [ "Enedis",[3.8101952000000003, 49.61048029999999]],
    [ "Enedis",[-0.91794525, 45.59749720000001]],
    [ "Enedis",[-0.91912295, 45.5985159]],
    [ "Enedis",[-0.910808326763116, 45.60073013328834]],
    [ "Enedis",[-0.9056135320092389, 45.599476292509415]],
    [ "Enedis",[-0.8322448742723889, 45.58639139612538]],
    [ "réséda",[6.2302435, 49.16109435000001]],
    [ "Enedis",[1.9757579999999997, 46.651108449999995]],
    [ "Prat Hir",[-4.011278650000001, 48.1012742]],
    [ "Enedis",[4.20528295, 43.69068225]],
    [ "Enedis",[-0.9696654296162093, 45.593352138186575]],
    [ "Enedis",[2.1844544259429153, 46.197942095582746]],
    [ "Enedis",[3.2288937299561806, 50.617115030955816]],
    [ "Enedis",[3.8090931927197924, 49.60646652477417]],
    [ "Enedis",[0.8641921950956182, 45.94798190544486]],
    [ "Enedis",[4.802184450000001, 45.75642814999999]],
    [ "Enedis",[2.534109248780815, 46.33005415246476]],
    [ "Enedis",[0.1375261665957656, 47.750661679217586]],
    [ "Enedis",[2.7373036714801335, 50.27931590440086]],
    [ "Poste électrique de Marly",[6.145646950060233, 49.083229213456654]],
    [ "Enedis",[3.8115263198689693, 49.602626427791655]],
    [ "Enedis",[2.069366666545343, 48.64742388331278]],
    [ "Enedis",[2.06480825, 48.64492905]],
    [ "Enedis",[5.046572250191521, 47.366264062001285]],
    [ "Enedis",[5.05385867037037, 47.360328433333336]],
    [ "Enedis",[5.05381901701638, 47.36034464840937]],
    [ "Evovalmy",[5.059307423812126, 47.359943583711996]],
    [ "Enedis",[5.064733971506665, 47.36192965962083]],
    [ "Charmette",[5.038424994402354, 47.364939283112626]],
    [ "S p i",[5.042051183626828, 47.35827754428842]],
    [ "Enedis",[3.843184916781093, 49.65493560195128]],
    [ "Enedis",[3.8441771000000005, 49.653144149999996]],
    [ "Square Balouët",[-4.48461205, 48.39378745000001]],
    [ "Route de Landudal",[-3.9954285, 48.09513160000001]],
    [ "Le Clos de Lannec’huen",[-4.007223850000001, 48.09976495]],
    [ "Enedis",[-3.909313680468774, 47.90000899222793]],
    [ "Enedis",[-4.234727434148997, 48.27998687787093]],
    [ "Enedis",[-4.532979719955997, 48.440793252414345]],
    [ "Enedis",[-4.0026983, 48.01841494999999]],
    [ "Enedis",[1.216938422285525, 43.59945163386994]],
    [ "Enedis",[1.213847608867388, 43.60023163507354]],
    [ "Enedis",[1.204154174690654, 43.60178003268326]],
    [ "Cotteland",[5.650326349999999, 45.27767914999998]],
    [ "Enedis",[-4.726101017807028, 48.531388234810784]],
    [ "Enedis",[-3.7758705717326766, 48.70079604180886]],
    [ "Enedis",[-4.7276285, 48.53200844999999]],
    [ "Enedis",[-4.1675328, 48.299545550000005]],
    [ "Enedis",[-4.4240484, 48.63239015]],
    [ "Lavallot",[-3.8742057499999993, 48.61019675000001]],
    [ "Enedis",[-3.7860265499999994, 48.700359449999986]],
    [ "Enedis",[-4.4900096, 48.42304865]],
    [ "Kersivy",[-4.28227955, 47.8214857]],
    [ "Enedis",[1.8867217000000005, 47.93899865]],
    [ "Enedis",[1.85724055, 47.951671250000004]],
    [ "Enedis",[1.8535180499999986, 47.951896250000004]],
    [ "ZA Vierand",[6.129383605210419, 45.981645229859716]],
    [ "Enedis",[5.471644023024731, 43.580682345708745]],
    [ "Enedis",[2.09113745, 48.64164075]],
    [ "Enedis",[2.068548077475918, 48.646197729082715]],
    [ "Enedis",[5.477077749999999, 43.5884209]],
    [ "Enedis",[3.1043650454391525, 50.587646134495444]],
    [ "Enedis",[4.103846399999999, 49.9921429]],
    [ "Enedis",[4.102685864666871, 49.99110010113375]],
    [ "Enedis",[4.107653019613912, 49.98792373330782]],
    [ "réséda",[6.234114588662094, 49.17132260552674]],
    [ "Enedis",[5.472702616042275, 43.58401572778394]],
    [ "Enedis",[5.60140746559, 43.21206619827362]],
    [ "Enedis",[5.603524633688271, 43.213598648210095]],
    [ "Enedis",[0.2435475, 47.98955755]],
    [ "Enedis",[0.940785122576868, 45.467625258634236]],
    [ "Enedis",[2.8611983627555557, 42.54494866764444]],
    [ "Enedis",[2.83251515, 42.5649365]],
    [ "SNCF Réseau",[-3.1868864894928937, 47.78151523095032]],
    [ "SNCF Réseau",[-2.5191165388909553, 47.69153321555886]],
    [ "Enedis",[-4.427082981219487, 48.254353246599464]],
    [ "Enedis",[-3.624202150000001, 48.26020265]],
    [ "Enedis",[-4.456590720064385, 48.290084684606626]],
    [ "Enedis",[-4.3732255, 48.2405642]],
    [ "Enedis",[-4.52940215, 48.2796441]],
    [ "Enedis",[-4.51645338135476, 48.23030865259574]],
    [ "Enedis",[-3.6217297000000004, 48.32211315]],
    [ "Enedis",[-4.5003892289221055, 48.279182529769145]],
    [ "Enedis",[-4.46468505, 48.287411999999996]],
    [ "1128423547",[0.07698598446417614, 43.38472589914422]],
    [ "Enedis",[4.62705405, 44.373004849999994]],
    [ "Enedis",[5.46632098750949, 43.58210781716344]],
    [ "Enedis",[5.465615268217505, 43.58204785126467]],
    [ "Enedis",[5.630043650000002, 43.47435705]],
    [ "HydroWatt",[6.741964560895903, 44.4600570185695]],
    [ "Enedis",[0.7058604554844996, 45.87387544605302]],
    [ "Enedis",[0.7352407199556567, 45.880794490352606]],
    [ "Enedis",[0.6648015747895978, 45.88613742349187]],
    [ "Enedis",[5.619534297244187, 43.20415893122454]],
    [ "Enedis",[5.617961479250576, 43.20480890921297]],
    [ "Enedis",[5.61799668243974, 43.20482124204078]],
    [ "Enedis",[5.61659988395371, 43.204370595510724]],
    [ "Enedis",[5.619773183500057, 43.20249759399793]],
    [ "Enedis",[5.619803616499944, 43.202463406002074]],
    [ "Enedis",[5.619831083500057, 43.20243039399793]],
    [ "Enedis",[5.617855333935045, 43.202691526870716]],
    [ "Enedis",[5.617020085938509, 43.207198267229124]],
    [ "Enedis",[5.6199153, 43.206743100000004]],
    [ "Enedis",[0.5722517251052819, 45.896902246194024]],
    [ "Enedis",[0.5669887890179826, 45.90288414569561]],
    [ "Enedis",[0.5618775224555377, 45.906652201712824]],
    [ "Enedis",[5.6172088808877065, 43.19672969809804]],
    [ "Enedis",[5.6172613, 43.19739235000001]],
    [ "Enedis",[5.615933217237278, 43.19812534412822]],
    [ "Enedis",[1.0369292024653327, 44.610138145762036]],
    [ "Tulipe",[2.033717567113995, 49.06478769954266]],
    [ "Enedis",[3.2276992143427634, 47.27879006130737]],
    [ "Enedis",[3.150298768076257, 47.08927559833234]],
    [ "Enedis",[3.108855209838714, 46.94507793324257]],
    [ "Enedis",[3.298322337190577, 46.742341927612706]],
    [ "Enedis",[2.841184624902511, 42.589194066510686]],
    [ "FC Closeaux 3",[2.2275014647622178, 48.98651016704768]],
    [ "Interxion",[2.4003799116611164, 48.92710761972697]],
    [ "Enedis",[2.365370377231553, 48.98828107179839]],
    [ "Enedis",[5.16705445, 43.482680599999995]],
    [ "Enedis",[1.0131534609602892, 46.099140034905226]],
    [ "Enedis",[0.9620431566782199, 46.041212100550155]],
    [ "Enedis",[0.9313556775901178, 46.03459914945924]],
    [ "Enedis",[0.9380101487772882, 46.03726826853375]],
    [ "Bocuse",[5.028324320843278, 47.31774640421638]],
    [ "CESML",[3.8279097171288234, 43.64763221273515]],
    [ "Enedis",[1.0636305228060294, 46.12565994592784]],
    [ "Enedis",[5.61505425, 43.20585915]],
    [ "Enedis",[5.618487949999999, 43.19953415]],
    [ "Enedis",[5.612398583270295, 43.198173609630594]],
    [ "Enedis",[5.612430650044277, 43.19813368331454]],
    [ "Enedis",[5.6113330999999995, 43.199072900000004]],
    [ "Enedis",[5.6082196851011945, 43.19903875318215]],
    [ "Enedis",[8.820480450000002, 42.565940350000005]],
    [ "Enedis",[1.96028114849089, 47.8889376681388]],
    [ "Enedis",[-0.06916780726096687, 42.961784716453934]],
    [ "Enedis",[1.8897152032618283, 47.96059205018369]],
    [ "Enedis",[1.9241581741297256, 47.90540389480102]],
    [ "Enedis",[1.0371393179100503, 46.121702904605726]],
    [ "Enedis",[1.0611394819951216, 46.1170399168705]],
    [ "Enedis",[1.0572435559682725, 46.124414798839275]],
    [ "Enedis",[1.0493307535558907, 46.12602949641587]],
    [ "Enedis",[1.0404046398837248, 46.13998825033427]],
    [ "Enedis",[1.0795994130850761, 46.12620128675647]],
    [ "Enedis",[1.0750446634142568, 46.114373389950316]],
    [ "Enedis",[0.9776912588381778, 46.14648745462953]],
    [ "Enedis",[1.0785522499999998, 46.0943716]],
    [ "Enedis",[1.0812364682408842, 46.08933105498439]],
    [ "Estagnols",[3.041994822584258, 43.00969660326772]],
    [ "Enedis",[5.60718865, 43.20154425]],
    [ "Enedis",[5.59807495, 43.199501950000005]],
    [ "Enedis",[5.606078367052795, 43.19941330665465]],
    [ "Enedis",[5.605511134317382, 43.20066432376123]],
    [ "Enedis",[5.605939025455691, 43.19774554841231]],
    [ "Enedis",[5.606076098767767, 43.19710406605407]],
    [ "Enedis",[5.60179775, 43.19378270000001]],
    [ "Enedis",[5.609242159445934, 43.19350246076854]],
    [ "Enedis",[5.611921233506494, 43.19537155336797]],
    [ "Enedis",[5.611689909651281, 43.195718265930225]],
    [ "Enedis",[2.8560889, 42.56015155]],
    [ "Enedis",[2.868622592986194, 42.55075929358147]],
    [ "Enedis",[4.090056918832637, 49.987237142696145]],
    [ "Enedis",[6.09044473119941, 46.734587902133924]],
    [ "Enedis",[5.624840256245333, 43.205384366178436]],
    [ "Enedis",[5.627786644464609, 43.207683377737446]],
    [ "Enedis",[5.6295812000000005, 43.20657825]],
    [ "Enedis",[1.892060250000001, 47.9385772]],
    [ "Enedis",[1.8861651500000003, 47.90219765000001]],
    [ "Enedis",[0.8952936665457778, 45.99010638082037]],
    [ "Enedis",[0.8948865963826499, 45.98007348843165]],
    [ "Enedis",[0.9638590547803435, 45.97487219773357]],
    [ "Enedis",[0.9423323491123201, 45.948726603230575]],
    [ "Enedis",[0.9006337540379522, 46.027743147804536]],
    [ "Enedis",[0.9301810986441085, 46.02343360206384]],
    [ "Enedis",[0.9400369507141597, 46.015735312484566]],
    [ "Enedis",[0.9329956, 45.998856100000005]],
    [ "Enedis",[1.021868678760935, 46.082580479159276]],
    [ "Enedis",[0.9829676381699002, 46.08964463889931]],
    [ "Enedis",[3.9225065325807233, 43.688088498550265]],
    [ "Enedis",[3.925620502082278, 43.6893758817294]],
    [ "Enedis",[0.7820988796054144, 46.00902934271914]],
    [ "Enedis",[0.7992585194061315, 46.020751314470374]],
    [ "Enedis",[1.0724246549038783, 46.05944389997466]],
    [ "Enedis",[1.1479450492050918, 46.07103149995125]],
    [ "Enedis",[6.488716050000001, 46.36172865]],
    [ "Enedis",[3.9167551554055784, 43.683126445837495]],
    [ "Enedis",[0.9490856601293697, 46.0702459730801]],
    [ "Enedis",[1.7864013590342998, 47.99855730015539]],
    [ "Enedis",[1.7754510906619527, 48.00275583446359]],
    [ "Enedis",[-0.9649978220161249, 46.97798181501024]],
    [ "Enedis",[-1.0667177, 46.9455393]],
    [ "Enedis",[0.6898360499999999, 46.062410650000004]],
    [ "Enedis",[0.68446837595986, 46.06044503324391]],
    [ "Enedis",[0.6607966463861153, 46.06415185302403]],
    [ "Enedis",[0.6590344984336229, 46.06571947585469]],
    [ "Enedis",[0.6703783746077177, 46.025712458527074]],
    [ "Enedis",[0.6328819615342125, 46.0555341719918]],
    [ "Enedis",[0.7120578206435922, 46.05833204562203]],
    [ "Enedis",[0.7156836552580039, 46.060673683986145]],
    [ "Enedis",[0.7262497707744593, 46.06290147417849]],
    [ "Enedis",[0.7398367691030823, 46.06651342783149]],
    [ "Enedis",[0.7805314610219498, 46.07083964941304]],
    [ "Enedis",[4.89576323373172, 43.910259572679465]],
    [ "Enedis",[3.435909938330565, 45.86301027653684]],
    [ "Enedis",[3.4285016234736725, 45.863029394242524]],
    [ "Enedis",[5.358977628121361, 43.42086208322196]],
    [ "Enedis",[5.358384178413252, 43.421415470084035]],
    [ "Enedis",[5.361280630278523, 43.42139634232786]],
    [ "Enedis",[5.360295558177441, 43.415889780247916]],
    [ "Enedis",[5.364286885332138, 43.41663222752145]],
    [ "Enedis",[5.361282300000001, 43.41405875000001]],
    [ "Enedis",[5.364156243638384, 43.41330747131018]],
    [ "Enedis",[5.3634741182555175, 43.41769670180034]],
    [ "Enedis",[5.36289532311684, 43.41050544324982]],
    [ "Enedis",[5.318162694058285, 43.41554873130875]],
    [ "1144354872",[5.260857740636739, 46.11061362158602]],
    [ "Enedis",[2.9006404, 42.571137099999994]],
    [ "Enedis",[2.9059007500000003, 42.568166000000005]],
    [ "Enedis",[2.901998230608059, 42.5696615275982]],
    [ "Enedis",[2.9004510000000003, 42.56563805]],
    [ "Régie d'Electricité de Villarodin",[6.693691495382037, 45.210908625887484]],
    [ "Enedis",[0.7214425985703776, 45.9614255506132]],
    [ "Enedis",[1.3561854190228722, 47.71056500321467]],
    [ "Enedis",[1.3506586856080458, 47.71751657620937]],
    [ "réséda",[6.060059600000001, 49.04078495]],
    [ "Enedis",[0.623915066615535, 45.94874824134382]],
    [ "Enedis",[1.494030607808041, 48.23776121771725]],
    [ "Boucaud",[0.06731646804411993, 44.688404717477084]],
    [ "Enedis",[0.7620127996575067, 45.95532779647083]],
    [ "Hiesta",[2.9472305499999996, 42.67176715]],
    [ "Poséïdon",[2.9461712218894376, 42.67021708989264]],
    [ "Enedis",[0.7876821371484187, 45.89417636979239]],
    [ "Enedis",[0.7866911330021854, 45.89137440628871]],
    [ "Enedis",[5.3864073, 43.42941725]],
    [ "Enedis",[4.360388828188558, 43.818248296833985]],
    [ "Enedis",[4.376663565686971, 43.8257590519738]],
    [ "Enedis",[0.8034085883395138, 45.89333456856686]],
    [ "Enedis",[0.8070638416214526, 45.88309124835586]],
    [ "Enedis",[3.1557463999999995, 50.623724450000005]],
    [ "Enedis",[6.410900100000001, 46.274591099999995]],
    [ "Enedis",[0.7835665415910987, 46.000517897313365]],
    [ "Enedis",[0.7197071611233012, 46.03008711637913]],
    [ "Enedis",[0.8520958928800209, 46.05018378020245]],
    [ "Enedis",[0.9315166603411369, 45.958103955187475]],
    [ "Enedis",[0.9087314490634189, 45.943985898964584]],
    [ "Enedis",[0.9052565514852607, 45.93944359411289]],
    [ "Enedis",[3.154309449999999, 50.6385855]],
    [ "Enedis",[1.0335556782301831, 46.12518040185906]],
    [ "Enedis",[2.1638004, 48.897329699999986]],
    [ "Enedis",[5.164570240576784, 43.47246696486939]],
    [ "Enedis",[5.170140857212993, 43.474374614670346]],
    [ "Centre",[5.7166821500000005, 48.23544665]],
    [ "Enedis",[2.460862224947578, 48.75166119713308]],
    [ "SIAAP",[2.453946687614604, 48.759089846132554]],
    [ "Enedis",[0.7849772917585202, 46.056709350932316]],
    [ "Enedis",[0.7871839080281171, 46.05165166649676]],
    [ "Enedis",[2.27051735687484, 48.64357645235158]],
    [ "Enedis",[2.0318460109431005, 47.90508071807627]],
    [ "réséda",[6.270283197668186, 49.116951941003805]],
    [ "Enedis",[1.9349386999999996, 47.88053845]],
    [ "Enedis",[1.8294105185023906, 47.8587739347242]],
    [ "Chanaron",[5.621043383780266, 45.33471383259561]],
    [ "Magnanerie",[5.622414833228639, 45.3354811316146]],
    [ "Enedis",[-1.0650969081626034, 46.124646983260966]],
    [ "réséda",[6.264334547233962, 49.07662134075326]],
    [ "Enedis",[1.9527642337168074, 47.976252575873154]],
    [ "Enedis",[1.9485331398693342, 47.9733152548919]],
    [ "Drive",[6.26966375989948, 46.20003833237067]],
    [ "Enedis",[6.2665054499999995, 46.19888395]],
    [ "Poste Trempelec",[6.26666695, 46.20003005]],
    [ "Enedis",[1.90486255, 47.85668395]],
    [ "réséda",[6.09761043037873, 49.024266684151726]],
    [ "Enedis",[0.7843894860811503, 46.05422660457114]],
    [ "Enedis",[0.8934005184745741, 46.03718915068819]],
    [ "Enedis",[2.8914789252239763, 42.56577588910305]],
    [ "Enedis",[2.88773865, 42.56843875]],
    [ "Enedis",[2.888836499283545, 42.56788118078339]],
    [ "Enedis",[2.889129128246409, 42.566684177789135]],
    [ "Enedis",[2.8872472848783497, 42.5659727466563]],
    [ "Enedis",[0.7806039135385255, 46.06071491299278]],
    [ "Enedis",[0.7663260070146214, 46.07423812383567]],
    [ "Enedis",[0.7712563359819764, 46.07242000222017]],
    [ "Enedis",[0.7703247234486268, 46.097586186163994]],
    [ "Enedis",[0.7515471953337665, 46.07853104930682]],
    [ "Enedis",[0.7781419599693518, 46.0609497489244]],
    [ "Enedis",[5.435068172562108, 43.77252473728943]],
    [ "Enedis",[3.2718108431335104, 48.20276537289322]],
    [ "Enedis",[6.332846500000001, 46.2507851]],
    [ "Enedis",[5.938836255246994, 47.22738683111608]],
    [ "Château",[5.9807995, 45.3400603]],
    [ "Enedis",[1.3805060999999998, 43.627749699999995]],
    [ "Enedis",[1.3804809183571567, 43.6278000578558]],
    [ "Enedis",[6.88302515, 48.06295979999999]],
    [ "Enedis",[-4.422792076598832, 48.43973529654398]],
    [ "Enedis",[0.40046035, 47.924703]],
    [ "Enedis",[0.41111984614014824, 47.92754641592891]],
    [ "Enedis",[0.8500478509139997, 46.04689624896464]],
    [ "Enedis",[-0.7291172500000005, 46.32425514999999]],
    [ "Enedis",[0.6502048990730629, 46.02714865266611]],
    [ "Enedis",[-0.3280300207106408, 45.65334710240687]],
    [ "Enedis",[1.0917168000000002, 44.10640775]],
    [ "Enedis",[1.0905735718158702, 44.10220469879877]],
    [ "Enedis",[1.0938759, 44.10122685]],
    [ "Enedis",[1.1188139000000001, 44.113691550000006]],
    [ "Enedis",[1.1119830165183608, 44.113347399726585]],
    [ "Enedis",[1.106928099999999, 44.11174885]],
    [ "Enedis",[1.095663533177508, 44.106658605324036]],
    [ "Enedis",[1.098327525915622, 44.107198803243364]],
    [ "Enedis",[1.100757065943775, 44.10819135579652]],
    [ "Enedis",[1.0975223, 44.06965745]],
    [ "Enedis",[1.101022679895443, 44.06498069777727]],
    [ "Enedis",[1.1210187323493879, 44.117943943570424]],
    [ "Enedis",[1.1149938654512856, 44.116215347187484]],
    [ "Enedis",[1.1085204000000002, 44.11523245]],
    [ "Enedis",[1.1050596773898707, 44.11239470901569]],
    [ "Enedis",[1.0964196500000005, 44.10426905]],
    [ "Enedis",[1.1002019766628384, 44.104150753695166]],
    [ "Enedis",[-0.5032228526707256, 43.89308806812655]],
    [ "Enedis",[-0.44203489368183563, 43.91998101604612]],
    [ "Enedis",[-0.5690305000000004, 43.9122817]],
    [ "Enedis",[-1.8836969730414113, 48.21806933126398]],
    [ "Enedis",[-1.8825582749277874, 48.21706064026575]],
    [ "Enedis",[2.9642207428757583, 43.16142872399984]],
    [ "Enedis",[4.159059016737789, 45.492254593513714]],
    [ "Enedis",[4.459406599999999, 45.9932198]],
    [ "Les Fleurs",[-0.7215006327223873, 47.21194399377946]],
    [ "Enedis",[-0.7190337995469869, 47.210108866863074]],
    [ "Enedis",[-0.7193008818548661, 47.21007499757774]],
    [ "Enedis",[2.14668585, 47.1921314]],
    [ "Enedis",[2.780565624368813, 44.39471564406338]],
    [ "Enedis",[2.964747377686146, 43.133916059141846]],
    [ "Enedis",[2.966173314892423, 43.13562983278379]],
    [ "Enedis",[3.1011617499999997, 50.5824173]],
    [ "Enedis",[2.977177182523092, 43.14937054353396]],
    [ "Enedis",[2.961248777927032, 43.13474980309564]],
    [ "Enedis",[2.9659283079275207, 43.140531529369575]],
    [ "Enedis",[1.0558765701399424, 46.054453357130335]],
    [ "Enedis",[-3.4372310792308864, 48.361772793948425]],
    [ "519060",[7.82272515, 48.66544514999999]],
    [ "519013",[7.830886500000001, 48.6635714]],
    [ "Enedis",[0.9204292939977298, 46.10590567753242]],
    [ "Enedis",[0.9048023645028361, 46.104991279964715]],
    [ "Enedis",[0.9225688825605024, 46.07627162175626]],
    [ "Enedis",[0.9269372499999999, 46.07555305]],
    [ "Enedis",[0.26671845, 48.01735435]],
    [ "Enedis",[-3.4295211293257974, 48.40587471337424]],
    [ "1152812812",[-2.5463041499999997, 48.027993800000004]],
    [ "Poste du Bois Paillet",[1.640376482021746, 48.273285631656236]],
    [ "Poste des Myosotis",[4.298938657941148, 49.48616783060186]],
    [ "Enedis",[5.17938452029587, 43.4813053531974]],
    [ "Enedis",[1.0335316271911197, 46.04409900179308]],
    [ "Enedis",[1.0455042225400848, 46.04063681879815]],
    [ "réséda",[6.085694350000002, 49.08435825]],
    [ "Enedis",[1.9142200761285868, 47.905127558805795]],
    [ "Enedis",[0.9656721847683415, 46.04120319145857]],
    [ "Enedis",[6.345417432360155, 46.27725859657868]],
    [ "Enedis",[6.661663121930368, 48.001962981154705]],
    [ "Poste électrique Ringo de Ventavon",[5.915910959169418, 44.34452323561447]],
    [ "Enedis",[0.949297083817194, 45.95924640386464]],
    [ "Oranger",[-0.46438415, 43.911101249999994]],
    [ "Enedis",[-0.5095073499999991, 43.902651]],
    [ "Enedis",[1.1125434500000004, 44.111066099999995]],
    [ "Enedis",[1.10506305, 44.110831000000005]],
    [ "Enedis",[1.107120057705416, 44.10958337092865]],
    [ "Enedis",[1.1029815381341603, 44.10826626716893]],
    [ "Enedis",[1.1058758499999999, 44.10613829999999]],
    [ "Enedis",[1.1080977000000016, 44.1070431]],
    [ "Enedis",[1.1079166244952554, 44.1059863903934]],
    [ "Enedis",[1.1066524871737287, 44.10424306756183]],
    [ "Enedis",[1.1133945150423126, 44.106362369772754]],
    [ "Enedis",[1.1097446947614624, 44.11476331780833]],
    [ "Enedis",[1.1093160342141406, 44.11629799460506]],
    [ "Enedis",[1.102187487061421, 44.09975067279547]],
    [ "Enedis",[1.0779626452262667, 44.100751521458236]],
    [ "Enedis",[1.0860587499999999, 44.1001118]],
    [ "Enedis",[1.0854227692881586, 44.10169659457166]],
    [ "Enedis",[1.0906940999999997, 44.104217049999995]],
    [ "Enedis",[1.0836024063355758, 44.105856174194095]],
    [ "Enedis",[1.0859151670533074, 44.10609586648705]],
    [ "Maison Maternelle",[4.3887769077633045, 45.4126048895904]],
    [ "Enedis",[0.8906094057606183, 45.92022424355572]],
    [ "Enedis",[-0.3021942315099945, 49.116952007941656]],
    [ "Poste source de Guislain",[-1.2238928863246523, 48.965955813098915]],
    [ "Enedis",[4.126554853660608, 43.931162429713375]],
    [ "Enedis",[4.12931945, 43.933212700000006]],
    [ "Enedis",[1.3125399000000006, 49.1428803]],
    [ "Enedis",[0.6641143015140871, 45.912759103970885]],
    [ "Enedis",[1.2948051000000003, 47.49474775]],
    [ "Shiro",[6.2381694, 46.18609890000001]],
    [ "Enedis",[0.9118503969615053, 45.89647540371169]],
    [ "Enedis",[0.7190582202889778, 45.18463276085886]],
    [ "Enedis",[0.7190936202889778, 45.18466976085887]],
    [ "Enedis",[0.6655821882137068, 45.19247922032812]],
    [ "Enedis",[0.6656638075444476, 45.192484347159294]],
    [ "Enedis",[0.9676748000000001, 46.0528804]],
    [ "Enedis",[5.199426433992064, 43.49025462066195]],
    [ "Enedis",[5.192806900000001, 43.49114554999999]],
    [ "Enedis",[5.192868961363367, 43.491168432939645]],
    [ "Enedis",[0.9536139770975521, 46.10933110442203]],
    [ "Enedis",[5.2044198, 43.49662]],
    [ "Enedis",[5.204475420798863, 43.49662940359322]],
    [ "Enedis",[5.204558133289241, 43.497287616622806]],
    [ "Enedis",[5.204556983377193, 43.49725723328924]],
    [ "Enedis",[5.204566634058033, 43.497878819432984]],
    [ "Enedis",[5.204038500000001, 43.4973529]],
    [ "Enedis",[5.204049928735631, 43.49724517397701]],
    [ "Enedis",[5.204179426212705, 43.4973467969061]],
    [ "Enedis",[5.20418515, 43.497297849999995]],
    [ "Enedis",[5.204188971986248, 43.49725107623008]],
    [ "Enedis",[4.642336776965959, 44.37957398809752]],
    [ "Enedis",[0.9224250192332697, 45.92351292543074]],
    [ "Enedis",[0.92289729930071, 45.933212140440276]],
    [ "Enedis",[0.9206742429808717, 45.94243289964425]],
    [ "Enedis",[0.9371778506636282, 45.92051245070094]],
    [ "Enedis",[0.9331232361048042, 45.91877727311631]],
    [ "Enedis",[5.195388082563123, 43.515744301262366]],
    [ "Enedis",[5.196174516137146, 43.51497176143045]],
    [ "Enedis",[5.19785395, 43.51654985]],
    [ "Enedis",[5.198691103994176, 43.51759631879612]],
    [ "Enedis",[5.197121450000001, 43.5172262]],
    [ "Enedis",[5.195183110876656, 43.518201767256876]],
    [ "Enedis",[0.8537441336683415, 45.90861112495812]],
    [ "Enedis",[0.8296942733050241, 45.917584541855796]],
    [ "Enedis",[5.19343654664637, 43.522759081720736]],
    [ "Enedis",[5.19451473632487, 43.5201855153522]],
    [ "Enedis",[5.2003351, 43.51859445000001]],
    [ "Enedis",[7.223444742792146, 43.690481917508095]],
    [ "HEGUICHOURY",[-1.0345657876141001, 43.03671292823843]],
    [ "Enedis",[0.9321833123873563, 45.9153637010887]],
    [ "Enedis",[4.642587141159889, 44.38702792518138]],
    [ "Enedis",[5.166197933403944, 43.47624194984975]],
    [ "Enedis",[5.1660582999999995, 43.4756496]],
    [ "Enedis",[0.7848818573434247, 45.86850923399301]],
    [ "Enedis",[0.7825241411001649, 45.87319024589491]],
    [ "Enedis",[0.7831237764622716, 45.879513551429895]],
    [ "réséda",[6.177296962596053, 49.14525208735784]],
    [ "Enedis",[4.641668386646, 44.397006859644634]],
    [ "Le Brachet",[5.099459362132464, 45.57074568398517]],
    [ "Enedis",[4.63673895, 44.40785595]],
    [ "Enedis",[5.166900761451137, 43.483400690927496]],
    [ "Enedis",[0.26910295000000006, 48.016597999999995]],
    [ "Enedis",[4.64030755, 44.40710325]],
    [ "Enedis",[4.64810505, 44.40738364999999]],
    [ "Enedis",[5.083162897895429, 47.34583623196043]],
    [ "Perino",[5.626617800000001, 45.29470843333335]],
    [ "Le Diamant",[5.64958629383415, 45.275106236277836]],
    [ "Enedis",[0.9212332999999999, 45.899248]],
    [ "Enedis",[3.9918388275411973, 49.27977071347325]],
    [ "La malle",[3.992145967970878, 49.27694589010963]],
    [ "RTE",[2.3628965742605126, 43.28572246597645]],
    [ "Bourg Vieux 1",[5.645184227536134, 45.28821044447426]],
    [ "Enedis",[5.6270489843139755, 45.29107909476096]],
    [ "Enedis",[5.630128929881091, 45.27300069699306]],
    [ "1159655692",[5.656209115136031, 45.26069126377112]],
    [ "Enedis",[0.8374310161207333, 45.89517674126023]],
    [ "Enedis",[0.7389047535991602, 45.95589586781357]],
    [ "Enedis",[5.62222832153679, 45.28522685320817]],
    [ "Enedis",[0.7896825675947848, 45.9255679232211]],
    [ "Enedis",[5.23608925, 43.45139345]],
    [ "Enedis",[5.624186550000001, 45.27484575]],
    [ "Enedis",[5.623231641595509, 45.27710171523165]],
    [ "Enedis",[5.62367980720952, 45.27885683400616]],
    [ "Enedis",[0.7554388044164142, 45.903763829261216]],
    [ "Enedis",[5.236194749999998, 43.44693745]],
    [ "Enedis",[5.618249671325946, 45.30587475893029]],
    [ "Enedis",[5.617404900603819, 45.30821341642639]],
    [ "Enedis",[5.616852137596988, 45.30863102048146]],
    [ "Enedis",[5.615233952282305, 45.30759551117529]],
    [ "Enedis",[-0.5499210942657925, 47.481274217040095]],
    [ "Enedis",[4.934441308944181, 44.849630570276425]],
    [ "Enedis",[4.934305831684809, 44.851547253370086]],
    [ "Enedis",[1.4808082866706398, 48.065750744703514]],
    [ "Enedis",[6.422597784483175, 46.30321769701178]],
    [ "Enedis",[1.8712645796261447, 47.92299115895428]],
    [ "Enedis",[-0.07828227586171395, 47.97554449710454]],
    [ "Enedis",[3.8358027000000003, 50.19921049999999]],
    [ "Enedis",[1.9247000499999998, 47.8467161]],
    [ "Enedis",[6.208779090899637, 48.56863722423297]],
    [ "Enedis",[6.219622838546308, 48.56519705243161]],
    [ "Enedis",[2.4665390336405437, 50.56833939072224]],
    [ "Enedis",[2.466126124696048, 50.56859641065184]],
    [ "Enedis",[2.4678525675042575, 50.56906726665374]],
    [ "Enedis",[2.470533837392871, 50.56904955489368]],
    [ "Enedis",[2.473474822524371, 50.56945673260507]],
    [ "Enedis",[2.4727980171734285, 50.57172144912537]],
    [ "Poste électrique de Levallois",[2.2810827706803867, 48.89356216373739]],
    [ "Enedis",[2.45981885, 50.5663749]],
    [ "Enedis",[2.4656432623193294, 50.56537677901788]],
    [ "Enedis",[2.4983328, 50.55808149999999]],
    [ "Le Forum",[6.123451, 45.9122451]],
    [ "Enedis",[1.76021795, 50.81359679999999]],
    [ "Enedis",[2.4745714743046534, 50.550365654945914]],
    [ "Enedis",[5.2335352197139615, 43.4401826565563]],
    [ "Enedis",[6.3592447000000005, 44.18355544999999]],
    [ "Enedis",[6.314970218447186, 44.16213271632059]],
    [ "Enedis",[2.4981168, 50.56633649999999]],
    [ "Enedis",[2.4996451864246527, 50.56710326909807]],
    [ "Enedis",[2.496723700000001, 50.5664348]],
    [ "Enedis",[1.6400272856510694, 44.999792088487304]],
    [ "1162530913",[2.244098312610068, 48.932007294471404]],
    [ "Enedis",[5.598433549999999, 45.31853709999999]],
    [ "Enedis",[4.784919150000001, 43.93463520000001]],
    [ "Enedis",[4.232233389705147, 46.94289978400799]],
    [ "Enedis",[1.9771577500000013, 47.90736555]],
    [ "Ebrard",[-0.2968495, 43.297075850000006]],
    [ "Enedis",[6.213705772700186, 44.17610086041724]],
    [ "Trentaine",[2.4259334499999996, 48.8794562]],
    [ "Enedis",[4.761894968723222, 43.93060900188517]],
    [ "Enedis",[4.793485870623139, 43.91291279146467]],
    [ "SNCF Réseau",[1.7854128763316677, 47.87839241349668]],
    [ "Enedis",[4.669895299999999, 43.81339545]],
    [ "cadres",[4.6541248500000005, 43.81463345000001]],
    [ "Enedis",[4.655609050000001, 43.8102326]],
    [ "Gouvernet",[4.65812848577874, 43.81253046763945]],
    [ "Enedis",[5.2435800587307195, 43.443908581707554]],
    [ "Enedis",[5.243508679203512, 43.44392090252478]],
    [ "Enedis",[5.245386849535796, 43.442721967285664]],
    [ "Enedis",[4.659815411613234, 43.81046402790003]],
    [ "Enedis",[4.816367250913003, 45.718224687626495]],
    [ "Enedis",[4.820780599942506, 45.71313213525656]],
    [ "Enedis",[4.689797751022587, 43.82036966688902]],
    [ "Mouret",[4.700042234702883, 43.81232479705418]],
    [ "Enedis",[4.707165217103491, 43.8221440576357]],
    [ "Enedis",[4.890835906635284, 44.91618312786371]],
    [ "ferry",[4.663160599999999, 43.8010273]],
    [ "Enedis",[4.674062892855424, 43.8017362248606]],
    [ "Enedis",[4.6659597999999995, 43.79923839999999]],
    [ "Enedis",[4.669579340275152, 43.79548816969875]],
    [ "Enedis",[0.711011271590528, 45.96646421983649]],
    [ "Enedis",[0.6657935960361212, 46.00582869770362]],
    [ "Enedis",[0.6665088273077066, 45.990592577177644]],
    [ "Enedis",[0.6840160425486396, 46.0515539752152]],
    [ "Enedis",[1.8996955207830954, 47.925130101161784]],
    [ "Enedis",[1.9001892499999986, 47.924849050000006]],
    [ "Enedis",[1.9004618374139435, 47.924067283383465]],
    [ "Enedis",[3.7108540274019877, 43.93649587437605]],
    [ "Enedis",[3.7025005661474486, 43.93641789916204]],
    [ "Enedis",[3.709804851778496, 43.94152678958616]],
    [ "Enedis",[3.705384557938476, 43.93051264404937]],
    [ "Enedis",[3.7064236936621433, 43.93364668832024]],
    [ "Enedis",[4.661370271323463, 43.80334091193725]],
    [ "Enedis",[4.691932224782899, 43.79291606993988]],
    [ "Enedis",[4.6914336, 43.79177484999999]],
    [ "vitembal",[4.689305200000001, 43.79131949999999]],
    [ "Enedis",[4.684818683371484, 43.791304385156224]],
    [ "Enedis",[4.681320276249572, 43.789275946729646]],
    [ "Enedis",[4.681567476307569, 43.79186344546878]],
    [ "Enedis",[4.70060395, 43.795049600000006]],
    [ "Enedis",[4.70066817013148, 43.795030417165364]],
    [ "Enedis",[4.711106793588851, 43.79193051479299]],
    [ "Enedis",[4.71456215, 43.789301300000005]],
    [ "Enedis",[4.656700782519758, 43.78836947573946]],
    [ "Enedis",[4.6562174999999995, 43.7882893]],
    [ "Enedis",[4.654145649999999, 43.78829455]],
    [ "Enedis",[4.655140495475781, 43.79695007109615]],
    [ "Enedis",[4.654799499999999, 43.79687935]],
    [ "Enedis",[4.685327965012323, 43.79417722055123]],
    [ "Enedis",[1.9272444370147912, 47.92137368276813]],
    [ "Enedis",[1.9272822761923198, 47.921509440267265]],
    [ "Enedis",[2.570151696256685, 48.86092803582887]],
    [ "Enedis",[4.801815773954984, 45.719414296355836]],
    [ "Enedis",[6.298351932698541, 44.15584114800716]],
    [ "Les Iles",[6.114000315487995, 45.91582265279464]],
    [ "Enedis",[6.2737396, 44.1167067]],
    [ "Enedis",[0.8038149622944248, 47.386054434630296]],
    [ "Enedis",[1.8774050718896205, 47.92411224216557]],
    [ "Enedis",[-1.1104234055420301, 45.508567680160304]],
    [ "Enedis",[1.8759611081377292, 47.91921402023159]],
    [ "Enedis",[1.8804443999999998, 47.9090739]],
    [ "Enedis",[1.9753095110905383, 47.87337596674617]],
    [ "réséda",[6.180009033070482, 49.10428088354332]],
    [ "Enedis",[2.016739349999999, 47.8938815]],
    [ "Enedis",[2.01322495, 47.902183199999996]],
    [ "Enedis",[2.0174835328342535, 47.902025546243046]],
    [ "RTE",[-0.4106978734647917, 46.49149027112088]],
    [ "Enedis",[2.057254042518584, 47.88592837732113]],
    [ "Poste électrique de Nivelle",[-1.5423070025388628, 43.38369649073669]],
    [ "Enedis",[4.909762120740821, 44.913019135654565]],
    [ "Enedis",[4.914177713034001, 44.91257107224244]],
    [ "Enedis",[1.6279345836661006, 46.73792890545737]],
    [ "Enedis",[6.06684115, 47.263682249999995]],
    [ "Enedis",[0.9254917389570181, 45.88455357976441]],
    [ "Enedis",[4.853007600000001, 45.635408049999995]],
    [ "Enedis",[0.7822678730826594, 45.855116605980285]],
    [ "Enedis",[5.238164707634647, 43.43369975238731]],
    [ "réséda",[6.274070186032408, 49.11659003197218]],
    [ "Enedis",[1.8642632078240085, 47.89662816806058]],
    [ "Enedis",[0.9230076846868014, 45.87496467472058]],
    [ "Enedis",[0.9212401017096259, 45.87764206731632]],
    [ "Enedis",[0.7231228988365099, 46.031278545573294]],
    [ "Enedis",[7.405282050000001, 47.5618858]],
    [ "Enedis",[7.125337581872045, 48.03718289664215]],
    [ "Enedis",[7.15252115, 48.04234015]],
    [ "Enedis",[-0.25152995, 45.17700775000001]],
    [ "Baechle",[7.128346202406591, 48.033340033863766]],
    [ "Enedis",[6.35031624279837, 46.90364564420416]],
    [ "Maison Médicale",[1.5099623500000001, 43.5820639]],
    [ "Estimat 54232P0031",[6.003239424638115, 48.688945096909535]],
    [ "Enedis",[5.248423566040495, 43.428088349248135]],
    [ "Enedis",[5.2490029549435375, 43.43085547936191]],
    [ "Enedis",[5.249161070648989, 43.43027596019005]],
    [ "Enedis",[5.24864345, 43.4310233]],
    [ "Enedis",[5.24497331427768, 43.429508946852614]],
    [ "Les Chalets",[1.52857165, 43.58524205000001]],
    [ "Laboratoires Horiba",[2.1981867770083636, 48.712474128247955]],
    [ "Enedis",[6.15331995, 43.03228005]],
    [ "Enedis",[6.150410730238669, 43.033229321513026]],
    [ "Enedis",[2.601469203354792, 44.39260081203314]],
    [ "Enedis",[5.335655349999999, 43.6630713]],
    [ "Enedis",[0.887213438197771, 45.88779747111133]],
    [ "Enedis",[0.34144627595110444, 48.068813746859675]],
    [ "Enedis",[0.6820148790104333, 45.87113242142807]],
    [ "Enedis",[0.6845602999813997, 45.87366949665814]],
    [ "Enedis",[5.889637074615265, 45.71580629430115]],
    [ "Enedis",[3.59029215, 43.478223650000004]],
    [ "Enedis",[1.9793812500000003, 47.803501900000015]],
    [ "Enedis",[1.9866853848243509, 47.80080814541313]],
    [ "Enedis",[1.9928351000000002, 47.79610929999999]],
    [ "Enedis",[1.9816694999999998, 47.80347325]],
    [ "Enedis",[6.05317695, 48.69963095000001]],
    [ "Soleil",[2.9055933499999997, 48.863581800000006]],
    [ "Comissariat",[2.905603178488846, 48.861190253117734]],
    [ "Enedis",[0.09077020593799852, 47.96719352932204]],
    [ "Enedis",[0.0965044, 47.98270230000001]],
    [ "Enedis",[5.2338128999999975, 43.44509675]],
    [ "Enedis",[5.233023281512104, 43.44709995733505]],
    [ "Enedis",[5.2353979382506255, 43.44742927435317]],
    [ "Enedis",[0.8204415578771419, 45.86574173775482]],
    [ "Enedis",[0.814869651241888, 45.87063398813866]],
    [ "Enedis",[0.8179249142129612, 45.846682818894436]],
    [ "Enedis",[4.08659912435125, 46.0135965385153]],
    [ "Enedis",[0.8302199688928642, 45.86800486801091]],
    [ "Enedis",[0.8257718703343401, 45.87222965844796]],
    [ "Enedis",[0.7912366472116122, 45.88786712716377]],
    [ "Enedis",[1.836758265713946, 47.993010500029726]],
    [ "Enedis",[1.8308350033346195, 47.99009860063863]],
    [ "Mas Roca",[2.947434749999999, 42.70030125]],
    [ "Enedis",[0.8083751052060623, 45.8790778014695]],
    [ "Enedis",[0.8097490624523601, 45.87791209850686]],
    [ "Enedis",[3.2364479594159117, 48.31184986837866]],
    [ "Enedis",[3.2766649000000005, 48.194712849999995]],
    [ "Enedis",[3.2735907182093853, 48.19055641932612]],
    [ "Enedis",[3.5043998999999997, 47.95942835]],
    [ "Enedis",[-0.9393364499999999, 43.203784299999995]],
    [ "Enedis",[-0.56045325, 42.81880375]],
    [ "Électricité de France",[55.62433395868238, -21.375317876280064]],
    [ "Enedis",[3.2695101999999987, 48.20899635]],
    [ "Enedis",[3.271096793440419, 48.20598786978468]],
    [ "Enedis",[1.6888810776123384, 47.83539645926852]],
    [ "Enedis",[5.233084668471669, 43.44422303416205]],
    [ "Enedis",[5.23127555, 43.44816330000001]],
    [ "Enedis",[5.232111174826112, 43.448761902942344]],
    [ "Enedis",[3.2352389500000003, 48.158051549999996]],
    [ "Enedis",[3.4896028479726846, 47.94030653283804]],
    [ "Enedis",[5.0296560849616645, 47.350567504024895]],
    [ "Enedis",[3.566117, 47.80737045]],
    [ "Enedis",[3.57048185, 47.80907065]],
    [ "Enedis",[5.24240869915619, 43.43259174924458]],
    [ "Enedis",[3.5381410783143106, 47.800230761559845]],
    [ "Enedis",[5.247211, 43.43232285]],
    [ "Enedis",[5.254915134172939, 43.43173692176904]],
    [ "Enedis",[5.254848271765558, 43.43172675372923]],
    [ "Enedis",[5.25557235, 43.42969785]],
    [ "Enedis",[5.253011246633687, 43.433798132360316]],
    [ "Enedis",[5.250682548092613, 43.43431518267377]],
    [ "Enedis",[5.251500199999998, 43.43529310000001]],
    [ "Enedis",[5.251454869553002, 43.435279001679326]],
    [ "Enedis",[5.24924125, 43.432457449999994]],
    [ "Enedis",[5.245252450000001, 43.4333138]],
    [ "Enedis",[5.2440165499999996, 43.43447645]],
    [ "Enedis",[5.24351345, 43.43385295000001]],
    [ "Enedis",[5.244639135289024, 43.433373833607874]],
    [ "Enedis",[5.2506900141077875, 43.42630076778754]],
    [ "Enedis",[5.253483, 43.42744965000001]],
    [ "Enedis",[3.5025954178691427, 47.79360316812556]],
    [ "Enedis",[2.8541195400547164, 42.751990861604725]],
    [ "Enedis",[2.8482771000000002, 42.75177445]],
    [ "Enedis",[2.85003187849785, 42.75451663981576]],
    [ "Enedis",[3.644868846849027, 47.74698110961587]],
    [ "Enedis",[3.14942165, 50.60567279999999]],
    [ "1177514445",[4.862437082802832, 47.77793997760317]],
    [ "Enedis",[5.250218044410005, 43.43351561800712]],
    [ "Enedis",[5.2486558, 43.4373303]],
    [ "Enedis",[5.247043135618187, 43.437993333430185]],
    [ "Enedis",[5.2451420338140275, 43.43976555556498]],
    [ "Enedis",[5.243137284309126, 43.440574109665]],
    [ "Enedis",[3.593000795243705, 47.74133707844697]],
    [ "Enedis",[3.5950851349375466, 47.74311518261834]],
    [ "Enedis",[3.5977165184844138, 47.73777883017435]],
    [ "Enedis",[0.875472800112222, 45.89479688939762]],
    [ "Enedis",[0.8675852024614439, 45.89599691384639]],
    [ "Enedis",[0.8297470248946536, 45.85146281952383]],
    [ "Enedis",[0.8298151659944228, 45.85032622388745]],
    [ "Enedis",[5.040919915333333, 47.35810720674004]],
    [ "Enedis",[3.66233775, 47.71299809999999]],
    [ "Enedis",[3.6653566000000004, 47.71481645000001]],
    [ "Enedis",[5.235393783745045, 43.43110781857274]],
    [ "Enedis",[5.23769565, 43.431817800000005]],
    [ "Enedis",[5.2373021500000005, 43.43147030000001]],
    [ "Enedis",[5.236221699999999, 43.429534749999995]],
    [ "Enedis",[5.23627105, 43.4278217]],
    [ "Enedis",[5.2412329007745555, 43.425008000002684]],
    [ "Enedis",[3.6951818883232077, 47.76174571969141]],
    [ "Enedis",[3.582593216699158, 47.79281459991381]],
    [ "Enedis",[0.9328809933289902, 45.87880802880515]],
    [ "Enedis",[5.2393985, 43.42949265000001]],
    [ "Enedis",[5.242552739488556, 43.42734793517028]],
    [ "Enedis",[5.245735735123139, 43.426686753894494]],
    [ "Enedis",[5.2449772, 43.425981449999995]],
    [ "Enedis",[5.242785750000002, 43.424784900000006]],
    [ "Enedis",[5.2421939, 43.424813400000005]],
    [ "Enedis",[5.240247500000001, 43.425334250000006]],
    [ "Enedis",[5.23988179258201, 43.42573816181798]],
    [ "Enedis",[5.238379085718816, 43.426600545861085]],
    [ "Enedis",[5.239190429227911, 43.43008521934923]],
    [ "Enedis",[0.9421230976003249, 45.8806358341286]],
    [ "Enedis",[3.565568232260536, 47.78619890131119]],
    [ "Enedis",[0.845857487829153, 45.89761787931255]],
    [ "Enedis",[5.2449241, 43.418999449999994]],
    [ "Enedis",[5.262715284624061, 43.41968974617031]],
    [ "Enedis",[5.264445291949667, 43.418350265520814]],
    [ "Enedis",[5.269278733455449, 43.41846335081355]],
    [ "Enedis",[5.271208500000001, 43.41808815000001]],
    [ "Enedis",[5.265310725786829, 43.41913655594328]],
    [ "Enedis",[5.265880697710365, 43.41934263754915]],
    [ "Enedis",[5.266056699999999, 43.42177009999999]],
    [ "Enedis",[3.6273712753910012, 47.70771350694821]],
    [ "Enedis",[3.641284895159059, 47.69588743236514]],
    [ "Forage",[-0.6104271364395949, 44.7968952305196]],
    [ "Enedis",[5.259990372746823, 43.415054759712106]],
    [ "Enedis",[5.26136135, 43.4154992]],
    [ "Enedis",[5.2577365500000015, 43.4133889]],
    [ "Enedis",[5.258478976486396, 43.41385625305061]],
    [ "Enedis",[5.260997520220671, 43.413677402774695]],
    [ "Enedis",[5.257577166223724, 43.4148407273736]],
    [ "Enedis",[5.25990795, 43.41625475]],
    [ "Enedis",[3.105527042820526, 47.370417504316656]],
    [ "Enedis",[3.110078915834323, 47.372919766722816]],
    [ "Enedis",[5.256474050000001, 43.41304525]],
    [ "Enedis",[5.254928517567483, 43.40939681620836]],
    [ "Enedis",[5.259030333284133, 43.40530369810086]],
    [ "1180293118",[3.7706542112033383, 46.648157504649156]],
    [ "Enedis",[3.4141745219210398, 50.10933488504647]],
    [ "Enedis",[5.24148075, 43.411027149999995]],
    [ "Enedis",[5.240850087682539, 43.41123072409525]],
    [ "Enedis",[5.274607107598853, 43.41277922514618]],
    [ "Enedis",[5.274606992401147, 43.41274017485382]],
    [ "Enedis",[2.931666610238602, 47.45380442536066]],
    [ "Enedis",[2.9311196, 47.44805499999999]],
    [ "Enedis",[3.111953292240808, 45.968783074541676]],
    [ "Enedis",[-4.488580637778619, 48.41238310732905]],
    [ "Enedis",[2.9352363379748105, 47.44280257967585]],
    [ "Enedis",[1.7702090236928423, 49.6892622054758]],
    [ "Enedis",[3.1648613982778864, 47.483698015179385]],
    [ "Enedis",[5.380821534147307, 43.29426170281441]],
    [ "Enedis",[3.05461665, 47.48127505]],
    [ "Enedis",[3.0575220805051755, 47.479862256100404]],
    [ "La Dinière",[6.450355329786259, 46.29177851669868]],
    [ "Enedis",[0.9589904171382233, 46.029884853655794]],
    [ "Enedis",[0.9594068379268116, 46.03336309374267]],
    [ "Enedis",[3.81972605, 47.6664413]],
    [ "Enedis",[3.8232573, 47.6660677]],
    [ "Enedis",[6.413074889103738, 46.27832900779217]],
    [ "Enedis",[-1.754823334382548, 48.125233099961704]],
    [ "Enedis",[3.583542316373437, 47.798714500407975]],
    [ "Enedis",[3.58494055, 47.80128525]],
    [ "Quick",[2.119185350000002, 43.91882220000001]],
    [ "réséda",[6.120996529136428, 49.269948641751625]],
    [ "réséda",[6.126509550000001, 49.271222800000004]],
    [ "réséda",[6.13679307726923, 49.27446972287439]],
    [ "Enedis",[3.5794023567658835, 47.822721425725035]],
    [ "Enedis",[5.903606282317404, 45.574297700968614]],
    [ "BP Station Pompage",[6.132935150000001, 45.89684965000001]],
    [ "CT E Deforges 39",[2.2973825999999997, 48.8045106]],
    [ "Lavoir Malroy",[6.214942317039527, 49.17610818188644]],
    [ "Enedis",[3.5748857721838423, 47.82361650865094]],
    [ "Pont de la Caille",[6.109730650000002, 46.01103095]],
    [ "Enedis",[4.244613984040545, 44.23579634640418]],
    [ "Enedis",[4.248158918339453, 44.234962848336856]],
    [ "Villas Green",[7.08072256276611, 43.61811637478743]],
    [ "Enedis",[4.2443857, 44.23703904999999]],
    [ "Enedis",[4.235588150000001, 44.237212500000005]],
    [ "Enedis",[4.274075831587708, 44.23880294506172]],
    [ "réséda",[6.19798863870885, 49.15856263552186]],
    [ "réséda",[6.192377395415725, 49.1542159681822]],
    [ "réséda",[6.19381795, 49.13990814999999]],
    [ "réséda",[6.19257465, 49.138663]],
    [ "Enedis",[4.621165775012495, 45.523540559170414]],
    [ "Funel",[7.080257367361955, 43.61501037243164]],
    [ "Chappes",[7.0801622730954685, 43.61501349742848]],
    [ "TV Gentech",[7.07943168464589, 43.612766396701055]],
    [ "Golf Panorama 2",[7.07497463007374, 43.618210752541515]],
    [ "Station Saint Philippe",[7.0724769, 43.6171582]],
    [ "Enedis",[1.6786204970051224, 50.62938156855093]],
    [ "Enedis",[1.5456149167627402, 50.08191662065164]],
    [ "Enedis",[1.5422821262630202, 50.086899356830955]],
    [ "Enedis",[1.5505600492183824, 50.07577131665881]],
    [ "Enedis",[-0.56492495, 44.7816894]],
    [ "Enedis",[3.5541818663021534, 47.84700249928665]],
    [ "Enedis",[4.9754741326301115, 44.73652967178426]],
    [ "Enedis",[4.977101250000001, 44.7374535]],
    [ "Enedis",[4.979061413156174, 44.736613617214836]],
    [ "Enedis",[4.981476232772968, 44.73703065664655]],
    [ "Enedis",[5.234406800361913, 43.4371740489862]],
    [ "Enedis",[3.5560756000000007, 47.85074335]],
    [ "Enedis",[3.5566119, 47.85067119999999]],
    [ "Enedis",[3.5592772196906823, 47.849436983266806]],
    [ "Enedis",[3.56117345, 47.848326899999996]],
    [ "Enedis",[3.5596783194138673, 47.853505202994306]],
    [ "Enedis",[1.538321033234648, 50.07723475014714]],
    [ "Enedis",[1.5376539262023219, 50.075262633665005]],
    [ "Enedis",[1.54860215, 50.08465645]],
    [ "Enedis",[5.234377297083551, 43.43384803582243]],
    [ "Enedis",[4.382198300000001, 45.418698250000006]],
    [ "Enedis",[3.527764841294796, 47.86124552523846]],
    [ "Enedis",[3.5278898, 47.857245400000004]],
    [ "Enedis",[3.531881774740596, 47.86059008208054]],
    [ "Enedis",[3.5422551500000004, 47.85322310000001]],
    [ "Enedis",[-4.413651179016729, 48.39674153242215]],
    [ "Enedis",[0.6850355136288183, 46.05507814889862]],
    [ "Fil de l'o",[6.1643503000000015, 45.9685948]],
    [ "Enedis",[5.740772450000001, 48.568629650000005]],
    [ "Enedis",[3.516460936902992, 47.88224507480988]],
    [ "Enedis",[1.5530848446481929, 50.079446066564344]],
    [ "Enedis",[3.5281227748184296, 47.87458751229674]],
    [ "Enedis",[7.118025482756952, 43.589405941284426]],
    [ "Les Moulards",[3.129022100000001, 46.14642315]],
    [ "Enedis",[3.0419768500000006, 47.79379485]],
    [ "Enedis",[3.0535162500000004, 47.79182285]],
    [ "Enedis",[3.091312940811661, 47.73117288102314]],
    [ "Enedis",[6.37277675, 46.853229649999996]],
    [ "Enedis",[3.5948262500000006, 50.2677843]],
    [ "Enedis",[5.224785415204349, 43.43582215890684]],
    [ "Enedis",[5.224424315298121, 43.4354874983092]],
    [ "Enedis",[3.7590337412335755, 47.481502324280974]],
    [ "Enedis",[3.759017850000001, 47.48150394999999]],
    [ "Enedis",[3.75170135, 47.48194319999999]],
    [ "Enedis",[3.9093865698446337, 47.511271943395194]],
    [ "Enedis",[3.908714781070587, 47.509040414416184]],
    [ "Enedis",[3.9084883480578108, 47.50768427773847]],
    [ "Enedis",[-4.39192217198885, 48.40213254345411]],
    [ "Enedis",[2.3144187353198102, 48.814802625085164]],
    [ "Enedis",[3.919070890312013, 47.49768687301102]],
    [ "Enedis",[3.9024159940841865, 47.48752958108646]],
    [ "Enedis",[6.649875959484229, 45.202112382325744]],
    [ "Enedis",[2.979428728533194, 50.33259654688268]],
    [ "Enedis",[2.9799066000000005, 50.3330196]],
    [ "Enedis",[2.9830225499999994, 50.33316244999999]],
    [ "Enedis",[6.25782285, 46.194279349999995]],
    [ "Enedis",[4.3894192691308875, 45.453720056792314]],
    [ "Enedis",[1.6351887818714443, 50.65950650297484]],
    [ "Enedis",[5.281348080881293, 43.40682215455378]],
    [ "Vignasse",[5.241216610511637, 43.91918608390086]],
    [ "Enedis",[1.5984385, 50.638741550000006]],
    [ "Enedis",[1.5770592977994815, 50.639239233170564]],
    [ "Enedis",[5.312526630290373, 43.4043107838126]],
    [ "Enedis",[5.310346609845065, 43.406226029596525]],
    [ "Enedis",[1.5949983337342686, 50.63459970105802]],
    [ "Enedis",[3.179368874050472, 43.27464655408299]],
    [ "Enedis",[1.6789406500000001, 50.679258899999994]],
    [ "Enedis",[-0.903691119313033, 48.136527016894654]],
    [ "Enedis",[-0.901257078794107, 48.140086070378224]],
    [ "Enedis",[-0.97033315, 48.1691754]],
    [ "Maresque",[-1.8326411430279221, 49.663392367811554]],
    [ "Enedis",[5.273933799999999, 43.39869084999999]],
    [ "Enedis",[5.273950356656135, 43.39866508067958]],
    [ "Enedis",[1.6443125693205625, 50.722307147142594]],
    [ "Enedis",[1.647536199999998, 50.734447499999995]],
    [ "Enedis",[1.6532246900870373, 50.734762322657836]],
    [ "Enedis",[1.6553017511992774, 50.74217478336772]],
    [ "Enedis",[1.5775304380818473, 50.633791871301995]],
    [ "Enedis",[1.5778683174205779, 50.63111097495328]],
    [ "Enedis",[1.5780528844857993, 50.63042073250939]],
    [ "Enedis",[1.6032266499999999, 50.632449099999995]],
    [ "Enedis",[1.600231414537104, 50.625924788726756]],
    [ "Enedis",[1.6157763499999998, 50.629175249999996]],
    [ "Enedis",[5.212707511398689, 43.40387526731693]],
    [ "Enedis",[3.975313872997634, 50.288402936077425]],
    [ "Enedis",[3.2294433901716952, 50.18585763405891]],
    [ "Enedis",[5.201535299999999, 43.40037439999999]],
    [ "Enedis",[5.20611825, 43.4030202]],
    [ "Enedis",[5.206551321653362, 43.402252837314215]],
    [ "Enedis",[5.207940950000001, 43.40198860000001]],
    [ "Enedis",[5.206194414425004, 43.39992448778369]],
    [ "Enedis",[5.210671900406694, 43.400947166804144]],
    [ "Enedis",[5.206804700000001, 43.40450685000001]],
    [ "Enedis",[5.2070564, 43.396009500000005]],
    [ "Enedis",[5.206460816822894, 43.39598939979101]],
    [ "Enedis",[5.200174510282073, 43.402264815437825]],
    [ "Enedis",[5.200108982501965, 43.40255149346574]],
    [ "Enedis",[5.2003495369718555, 43.4038978006044]],
    [ "Enedis",[3.9470083611346545, 50.28231432588721]],
    [ "Tarif Jaune Opri",[-1.8367373999999996, 49.6609479]],
    [ "Les Tohagues",[-1.8338633924661958, 49.66018738741827]],
    [ "Enedis",[3.969442527583523, 50.28125433270205]],
    [ "Enedis",[4.844057283534366, 45.78838845563012]],
    [ "Enedis",[6.559680747980662, 48.21691728005742]],
    [ "Enedis",[3.968308541926692, 50.27583462773954]],
    [ "Enedis",[3.971700575003715, 50.27825704113378]],
    [ "Enedis",[5.1028933, 43.339973099999995]],
    [ "Enedis",[5.100563506074217, 43.34062072699825]],
    [ "Enedis",[3.005009949999999, 45.76405395]],
    [ "1190696769",[3.12696681639865, 45.83849008016041]],
    [ "Enedis",[3.0667394999999997, 45.82860124999999]],
    [ "Enedis",[3.0816780500000003, 45.84104609999999]],
    [ "Enedis",[3.9676747688880383, 50.279176365443085]],
    [ "Enedis",[3.9772913822476412, 50.27832041967062]],
    [ "Enedis",[4.830614827673662, 45.79004850310209]],
    [ "Enedis",[5.18684059123565, 43.365176598087324]],
    [ "Enedis",[5.103976, 43.337101149999995]],
    [ "Enedis",[0.6619931090403693, 46.022586378850185]],
    [ "Enedis",[5.202829699999999, 43.33296659999999]],
    [ "Enedis",[3.235958379287072, 43.33671245298905]],
    [ "Enedis",[3.976202071338272, 50.272918383394185]],
    [ "Enedis",[5.1048848016928225, 43.338690416482045]],
    [ "Enedis",[5.203020149749965, 43.40254153320471]],
    [ "Enedis",[5.1936231229974, 43.40757159085996]],
    [ "Enedis",[5.201851734241227, 43.407397793362975]],
    [ "Enedis",[5.201361650000002, 43.406624300000004]],
    [ "Enedis",[3.1625807342702665, 45.747001844362245]],
    [ "Enedis",[3.17048665, 45.73758505]],
    [ "Enedis",[3.1769202, 45.724053600000005]],
    [ "Enedis",[0.1655563673595684, 48.05850271019534]],
    [ "Enedis",[0.6638820033521035, 46.012467578201836]],
    [ "Enedis",[0.6637836548341586, 46.01548386771621]],
    [ "Enedis",[0.6587706939144858, 46.01313211554906]],
    [ "Enedis",[0.6614838516266391, 46.0138690056116]],
    [ "Enedis",[1.5903222687204153, 50.72236235420835]],
    [ "Enedis",[3.988308065802874, 50.28174310767492]],
    [ "Enedis",[5.207252649999997, 43.34970405]],
    [ "Sallenave",[-0.3601475499999999, 43.31462235]],
    [ "Enedis",[5.204160511030785, 43.35365692985565]],
    [ "Enedis",[5.1890825292038905, 43.355551970834405]],
    [ "Enedis",[5.184650800324818, 43.35426066656461]],
    [ "P5 Fabien",[-0.7690201000000005, 43.315286650000004]],
    [ "Enedis",[3.1784394500000017, 45.7759488]],
    [ "réséda",[6.224547954871938, 49.10578228251175]],
    [ "Enedis",[5.18597605, 43.36455385]],
    [ "Enedis",[5.186292025695822, 43.36423699155854]],
    [ "Enedis",[0.6611506094796789, 46.0061785328437]],
    [ "Enedis",[3.9737726152206587, 50.271042745570035]],
    [ "Oliviers",[4.689663330451588, 43.97813394057527]],
    [ "Stade",[5.460694799999999, 43.52323165]],
    [ "Enedis",[4.687812228167213, 43.97128003889944]],
    [ "Enedis",[4.68711266745349, 43.971956646278976]],
    [ "Enedis",[5.198078249999999, 43.356328600000005]],
    [ "Enedis",[5.195122317424794, 43.35730130077875]],
    [ "Enedis",[4.2138425999999995, 43.943710200000005]],
    [ "Enedis",[4.70202995, 44.37934125]],
    [ "Enedis",[3.905285261243724, 50.28079407310408]],
    [ "Enedis",[3.9233758999999995, 50.27715204999999]],
    [ "Enedis",[3.9281665, 50.27616789999999]],
    [ "Enedis",[3.921259416724519, 50.280203310539434]],
    [ "Enedis",[2.9602854160279315, 47.28197989452108]],
    [ "Enedis",[2.9571345916953033, 47.28172322046105]],
    [ "Enedis",[3.943852398573856, 50.27410721534352]],
    [ "Enedis",[3.4803546000000005, 47.8761381]],
    [ "Enedis",[3.4797321277159456, 47.8793245046199]],
    [ "Enedis",[0.19306868479886813, 47.940903680813776]],
    [ "RTE",[3.497908356672988, 44.52863707566796]],
    [ "Enedis",[3.9340432361930806, 50.27614976820008]],
    [ "Enedis",[4.00681908110387, 50.25293795615802]],
    [ "Enedis",[-4.645761661691417, 48.47388278286706]],
    [ "Enedis",[-4.436298849568305, 48.39552311607788]],
    [ "Enedis",[-4.501153140493172, 48.412859969907544]],
    [ "Enedis",[3.4823405219846517, 47.8843011470026]],
    [ "Enedis",[3.2292013959948176, 48.30959183386283]],
    [ "Enedis",[6.47371305, 46.3591764]],
    [ "Enedis",[3.2324157327115235, 48.30391881756687]],
    [ "Enedis",[3.234352633968054, 48.309925329043736]],
    [ "Musdehalsu",[-1.3983535254230635, 43.352996053737265]],
    [ "Enedis",[4.370318500964039, 45.43147183369653]],
    [ "Enedis",[0.956272578076373, 46.043478102069514]],
    [ "Enedis",[1.0120770657570795, 46.134469778273605]],
    [ "Enedis",[-1.3947044176691432, 48.79835469370478]],
    [ "Enedis",[6.454707, 46.3585539]],
    [ "Enedis",[5.625430098338412, 45.30466696740376]],
    [ "La Percée",[5.791637769968846, 45.45270608612115]],
    [ "Oronoz",[-1.3927623155420934, 43.35533451951814]],
    [ "Enedis",[-1.3842682669322712, 43.35688240039841]],
    [ "Enedis",[-1.3860065193629714, 43.3602626750723]],
    [ "Enedis",[-1.4040803000000002, 43.355178699999996]],
    [ "Enedis",[-1.381517506832027, 43.36021102724798]],
    [ "Enedis",[-1.37702885, 43.36108204999999]],
    [ "Enedis",[-1.3637062248745373, 43.34469278991451]],
    [ "3I Nicamil",[6.635978976166709, 45.860208841441]],
    [ "Enedis",[5.1425091, 43.333449249999994]],
    [ "Enedis",[5.1423835042036945, 43.3352628703683]],
    [ "Enedis",[5.1394299199240026, 43.332498726435276]],
    [ "hotel la cry",[6.629100299999998, 45.8679712]],
    [ "Enedis",[5.1383808, 43.4001219]],
    [ "Enedis",[5.141450211519853, 43.39438343798918]],
    [ "Enedis",[4.381655237438196, 45.43927165176286]],
    [ "Enedis",[6.4827696, 46.35321115]],
    [ "Enedis",[6.4832306499999985, 46.3505067]],
    [ "Enedis",[6.4875871883012355, 46.34768231706353]],
    [ "Enedis",[5.1527293, 43.407251200000005]],
    [ "Enedis",[5.1357911, 43.3954864]],
    [ "Enedis",[5.1352850016456415, 43.39593926625981]],
    [ "Enedis",[3.0854095190299797, 50.58293721725524]],
    [ "Enedis",[0.39754585869047643, 42.87024232507144]],
    [ "Enedis",[5.114437100000001, 43.399113549999996]],
    [ "Enedis",[6.497486379314922, 46.355167542938055]],
    [ "Enedis",[6.497457627519483, 46.35870414750088]],
    [ "Lot Ernest Corbin",[-1.3874297249657574, 48.8003959853144]],
    [ "Enedis",[6.500687449999999, 46.36580725]],
    [ "Enedis",[-0.33469653233403596, 49.13972090082896]],
    [ "RTE",[5.385132817967499, 49.43645341596234]],
    [ "Enedis",[7.078370926447655, 48.35453101704625]],
    [ "Park Indoor",[-0.6045301218621433, 47.47286289937582]],
    [ "Enedis",[0.22752210000000053, 47.9392863]],
    [ "Enedis",[6.638229509609178, 45.894784647149514]],
    [ "Enedis",[6.519709011172416, 46.34760678404599]],
    [ "Enedis",[6.5155845, 46.3528112]],
    [ "Enedis",[6.52171395, 46.34007164999999]],
    [ "Enedis",[-0.7501557064362555, 47.007813312030706]],
    [ "Val Sapieux",[6.777101552058941, 45.57569231755975]],
    [ "1199361243",[7.256978428300119, 47.79841395490619]],
    [ "Enedis",[6.460251789924846, 46.359455126298094]],
    [ "La Patache",[-1.4833508949864103, 46.22922435158298]],
    [ "CBU la Boire",[-1.5188377915763585, 46.202942965959565]],
    [ "Mouille Barbe",[-1.5162564500000004, 46.21318635]],
    [ "Dechezeau",[-1.5168358954241623, 46.210125434176256]],
    [ "Enedis",[5.260421369391014, 43.555876713718035]],
    [ "Ecole",[6.60922821250452, 45.92109248537847]],
    [ "Enedis",[6.646104994682688, 46.241056434213796]],
    [ "Enedis",[5.190913899999999, 43.37655779999999]],
    [ "Enedis",[5.1909239407040015, 43.376525030422805]],
    [ "Station Epuration",[6.202280800000001, 49.200520450000006]],
    [ "Enedis",[6.22550855327175, 48.681214081408335]],
    [ "Enedis",[2.1996792694853187, 48.68030809703178]],
    [ "Chalet",[6.423336364437405, 46.27849938465425]],
    [ "Enedis",[-1.3083794999999996, 46.18364975000001]],
    [ "Les hertaux",[-1.2955068464449813, 46.186553682430954]],
    [ "Enedis",[-1.380113532180733, 48.80501153189104]],
    [ "Poitheviniere",[-1.3698439639178932, 46.20478566858777]],
    [ "Corsaire",[-1.3534542659981812, 46.1986680703035]],
    [ "Camping interlude",[-1.37917295, 46.17450745000001]],
    [ "Camping les Varennes",[-1.3832559, 46.17838804999999]],
    [ "Enedis",[6.829984642719902, 45.56809796536453]],
    [ "Enedis",[6.831876050000001, 45.5804672]],
    [ "Enedis",[6.834108350000001, 45.5836133]],
    [ "Enedis",[5.101900700000001, 43.3990566]],
    [ "Enedis",[-1.399763286512046, 43.3545254793615]],
    [ "Enedis",[-1.5390544881317723, 49.04796832079863]],
    [ "Enedis",[6.477379144055514, 46.36907872058639]],
    [ "Enedis",[6.475074774261396, 46.36628029321747]],
    [ "Enedis",[6.467101601840548, 46.36399686381155]],
    [ "Enedis",[6.467048263526762, 46.36397118454274]],
    [ "Enedis",[6.462948701800512, 46.36005146586837]],
    [ "S Paul",[1.30899915, 49.140668250000004]],
    [ "Enedis",[1.3067731851297009, 49.1427584291381]],
    [ "Enedis",[6.3028626294876435, 44.49901633983347]],
    [ "Enedis",[0.652505265652745, 48.17965165145023]],
    [ "Enedis",[0.5059653754373056, 48.13979756131434]],
    [ "Enedis",[-3.91941249339003, 48.65938767723027]],
    [ "Enedis",[-0.5553177000000001, 44.82809825]],
    [ "Enedis",[6.254885849999998, 48.706784600000006]],
    [ "ZAC d'Autroche",[1.8643443000000002, 48.082637649999995]],
    [ "réséda",[6.2407189999999995, 49.12227964999999]],
    [ "réséda",[6.2370803055915545, 49.13101218316175]],
    [ "réséda",[6.2553036, 49.13330164999999]],
    [ "réséda",[6.260148150000001, 49.136061350000006]],
    [ "réséda",[6.296345450000001, 49.10072715]],
    [ "réséda",[6.4528715000000005, 48.994544000000005]],
    [ "réséda",[6.480680106589782, 48.99850788219327]],
    [ "réséda",[6.220938450000001, 48.9871754]],
    [ "réséda",[6.21887637056832, 48.98784703923244]],
    [ "Enedis",[4.821032808398592, 45.75920762258632]],
    [ "Enedis",[-1.4022896000000002, 43.342341]],
    [ "Enedis",[-1.398592466319325, 43.35962589887278]],
    [ "Enedis",[6.448988688924522, 46.16638621668245]],
    [ "Enedis",[5.6775175, 49.50738799999999]],
    [ "Enedis",[5.1051871, 43.398503149999996]],
    [ "Enedis",[4.409383164200424, 45.442459180119705]],
    [ "Intermarché",[-1.4144497499999997, 43.3494279]],
    [ "Enedis",[-1.4206900506613647, 43.34962098359759]],
    [ "Enedis",[-1.4167903827419772, 43.344505942491566]],
    [ "1202738828",[6.078883218028406, 46.72067042004275]],
    [ "Enedis",[5.103138400000001, 43.39203520000001]],
    [ "Enedis",[6.491560018150035, 46.378963991649194]],
    [ "Enedis",[6.506470932549921, 46.390767579125296]],
    [ "Enedis",[6.5083369499999995, 46.3913612]],
    [ "Enedis",[6.5034284499999995, 46.387268399999996]],
    [ "Enedis",[3.9486585979195783, 43.57238491599649]],
    [ "Enedis",[3.946501907600181, 43.57377461767667]],
    [ "Enedis",[3.9479539668466983, 43.57420655041364]],
    [ "Enedis",[0.2957443, 49.7353022]],
    [ "Enedis",[4.336889382076867, 43.82841385157971]],
    [ "réséda",[6.167965836034888, 49.131067933006975]],
    [ "réséda",[6.0981276207385395, 49.08026913247254]],
    [ "réséda",[6.0990740553359215, 49.07798147263022]],
    [ "Flétrange Village",[6.5616918, 49.06069825]],
    [ "réséda",[6.496380119899718, 49.01795743434655]],
    [ "réséda",[6.492513787893514, 49.013268408824565]],
    [ "réséda",[6.496963299999999, 49.02059924999999]],
    [ "réséda",[6.499171012867925, 49.02241908766334]],
    [ "Im les Cordettes",[6.761679600000001, 45.55284850000001]],
    [ "Enedis",[5.1027152000000005, 43.400314550000004]],
    [ "réséda",[6.247535599999999, 49.1518605]],
    [ "réséda",[6.243972800000002, 49.15483055000001]],
    [ "réséda",[6.240091733156689, 49.157073749559814]],
    [ "Enedis",[-4.567407, 48.569472850000004]],
    [ "Enedis",[-4.555997900000001, 48.5677509]],
    [ "Enedis",[-4.50642665, 48.5812588]],
    [ "Enedis",[-4.528579992697948, 48.580879720634094]],
    [ "Enedis",[-4.525798881708945, 48.58808479439252]],
    [ "Enedis",[-4.535836058986645, 48.5868603740027]],
    [ "Enedis",[-4.545051809200968, 48.57594182375901]],
    [ "Enedis",[-4.541821299999999, 48.5779731]],
    [ "Enedis",[-4.543930792443815, 48.591334117156705]],
    [ "Enedis",[-4.566960171727316, 48.588825204895635]],
    [ "Enedis",[0.6379193745090682, 49.78845480935585]],
    [ "Enedis",[0.37557398507817324, 49.75936940498198]],
    [ "réséda",[6.320359403539176, 49.045559196183916]],
    [ "Enedis",[0.6744890705318595, 47.38127994678387]],
    [ "Enedis",[6.49444425, 46.368190549999994]],
    [ "Enedis",[6.49484225, 46.37323785]],
    [ "Enedis",[6.475031083685751, 46.36177149604208]],
    [ "Enedis",[6.483588850000001, 46.3589092]],
    [ "Enedis",[6.481114666391342, 46.363922909104694]],
    [ "Enedis",[6.487278733154286, 46.37034913278177]],
    [ "Diwan",[-3.553550759551731, 48.27164666204368]],
    [ "Hospice de Kerampuil",[-3.552977083711487, 48.271366931771425]],
    [ "Carhaix ZAE Kerampuil",[-3.560166400000001, 48.2736915]],
    [ "Enedis",[6.499772050000001, 46.37262020000001]],
    [ "Enedis",[5.1130145514279395, 43.40052465011928]],
    [ "Enedis",[5.493460999999998, 43.59579469999999]],
    [ "Enedis",[3.820062337364064, 49.46243680160959]],
    [ "Enedis",[6.305178702366472, 47.02507344767223]],
    [ "Enedis",[6.4595781848042275, 45.719306716981336]],
    [ "Enedis",[6.1485898847455065, 49.22042125405528]],
    [ "Enedis",[5.478211549999999, 43.60317685]],
    [ "réséda",[6.051371990631251, 49.1180960681186]],
    [ "Enedis",[3.2007876743870907, 50.61136680588525]],
    [ "réséda",[6.2377116823147345, 49.01218434671992]],
    [ "réséda",[6.423594, 49.07137660000001]],
    [ "réséda",[6.445730000771976, 49.08172506658141]],
    [ "Enedis",[6.309639200000001, 46.9227345]],
    [ "Enedis",[6.435219764635273, 46.303139005078485]],
    [ "P3 Saint-Faust",[-0.4523651421556925, 43.269905518523416]],
    [ "Enedis",[3.274771730763883, 48.21692199488873]],
    [ "Enedis",[3.2550882999999984, 48.2199684]],
    [ "Enedis",[5.8340500247263005, 45.04875190933492]],
    [ "Enedis",[5.031190804404399, 43.404615219374776]],
    [ "Enedis",[0.641880988074023, 45.994747553118565]],
    [ "Enedis",[3.230915558593218, 50.616283174403726]],
    [ "Enedis",[3.2261652179501796, 50.616291951907414]],
    [ "Enedis",[5.021458999428415, 43.38260216686452]],
    [ "Fort d'Hauteville",[4.980160806456728, 47.366217226545245]],
    [ "Enedis",[5.009932918569965, 43.3707990774288]],
    [ "Enedis",[0.837559958892863, 46.07821500631643]],
    [ "Enedis",[0.8538199456758443, 46.08445328428232]],
    [ "Enedis",[0.8740083167899723, 46.089417651143506]],
    [ "Enedis",[0.8887292508135431, 46.09744342123603]],
    [ "Enedis",[0.8965993490092198, 46.101888599312524]],
    [ "Enedis",[0.9052914147013726, 46.10795853956826]],
    [ "Enedis",[0.8837194910442064, 46.09516982415893]],
    [ "Enedis",[0.9090707684027605, 46.10775683342143]],
    [ "Enedis",[3.234919498151061, 50.614116416412756]],
    [ "Enedis",[5.34199129353112, 46.28370261621946]],
    [ "Enedis",[0.9191376404553362, 46.10656241202127]],
    [ "Enedis",[0.9074438088079082, 46.107228550060995]],
    [ "Enedis",[0.914531252864889, 46.10789625334309]],
    [ "Enedis",[0.9218842468635344, 46.111594549141714]],
    [ "Enedis",[0.921911351774833, 46.11147488588486]],
    [ "Enedis",[0.9229333926942908, 46.118077752926006]],
    [ "Enedis",[0.6718201544745459, 46.05907150565267]],
    [ "Enedis",[0.6771393389679178, 46.05686829902715]],
    [ "Enedis",[-1.5064159673113313, 48.21442846454699]],
    [ "Enedis",[6.445370900000001, 46.31168995000001]],
    [ "Sous la Broue",[5.82309415, 45.0518831]],
    [ "Ecoles",[5.8256296, 45.0430157]],
    [ "Enedis",[4.995743468467867, 43.39137599775143]],
    [ "Enedis",[0.7825700616296586, 46.06054538606785]],
    [ "réséda",[6.105551119835595, 49.03085952624574]],
    [ "Brelingot",[3.0783255235500175, 46.197911608234875]],
    [ "Enedis",[3.0799763342222737, 46.19856769386344]],
    [ "Enedis",[3.087254125705259, 46.20121418943805]],
    [ "Enedis",[5.163812366818294, 45.31229829935739]],
    [ "Enedis",[6.374362799999999, 46.26376615]],
    [ "Enedis",[5.199243850000001, 45.042409]],
    [ "Enedis",[5.1980971, 45.03712455]],
    [ "Enedis",[5.01031273167419, 43.37719875753251]],
    [ "Enedis",[4.98713833338524, 43.39335050079343]],
    [ "Enedis",[4.997406990508572, 43.39304851445713]],
    [ "Enedis",[5.091006926191435, 44.899540504618074]],
    [ "1211018343",[5.09626335, 44.8990168]],
    [ "Enedis",[4.977648200000002, 44.8045363]],
    [ "réséda",[6.188236110933061, 49.053674075578506]],
    [ "réséda",[6.18453567009634, 49.061859433389564]],
    [ "réséda",[6.187310000000001, 49.047586300000006]],
    [ "réséda",[6.093948050000001, 49.12356400000001]],
    [ "réséda",[6.0934897999999995, 49.120910099999996]],
    [ "réséda",[6.094196000000001, 49.119135299999996]],
    [ "réséda",[6.096656579603712, 49.118837545656845]],
    [ "réséda",[6.0977196000000005, 49.11416995]],
    [ "Enedis",[4.941065250000001, 44.68151340000001]],
    [ "Enedis",[4.984607610039079, 43.39651342100687]],
    [ "Enedis",[5.260035183333333, 44.789702000000005]],
    [ "Enedis",[3.0888444680224216, 46.11976744236014]],
    [ "Auto TR les Carriaux",[3.0942275, 46.12951185]],
    [ "Enedis",[6.354058486526799, 46.27079306828995]],
    [ "Enedis",[6.358223095007323, 46.257403820234266]],
    [ "Enedis",[5.015490727129324, 43.396299295428626]],
    [ "Enedis",[5.001914749919552, 43.3983547666808]],
    [ "Enedis",[5.001809027705628, 43.39848173112503]],
    [ "Enedis",[5.017009889703544, 43.394993895182196]],
    [ "Enedis",[5.025950399999998, 43.39630124999999]],
    [ "Enedis",[6.3682731499999985, 46.26889945]],
    [ "Enedis",[5.015006722928191, 43.39133042233087]],
    [ "Enedis",[5.024690880232675, 43.38440030227681]],
    [ "Enedis",[5.003576433492623, 43.39719770068971]],
    [ "Enedis",[5.007026504610687, 43.39867496048795]],
    [ "Enedis",[5.0254244, 43.39724475]],
    [ "Enedis",[5.024203205087568, 43.395868829244264]],
    [ "Enedis",[5.00449905, 43.395030899999995]],
    [ "réséda",[6.224642757563254, 49.015629660736046]],
    [ "Laurinda",[2.3678219499999997, 48.7797038]],
    [ "Enedis",[5.291204649999999, 44.7301105]],
    [ "Enedis",[5.340737478857142, 44.62439359390476]],
    [ "Enedis",[0.959658436427281, 46.06325174901394]],
    [ "Enedis",[0.974422776859961, 46.017313427979246]],
    [ "Enedis",[0.9845078624102064, 46.00966949846689]],
    [ "Pont",[6.7135345499999985, 45.89150935]],
    [ "Le Regency",[6.709276724170451, 45.88956314665574]],
    [ "Enedis",[1.0524414572674239, 46.095255922996735]],
    [ "Enedis",[1.0330694045837634, 46.0676162814897]],
    [ "Enedis",[1.021738200725281, 46.051570549738955]],
    [ "Enedis",[6.34979555, 46.278695049999996]],
    [ "Enedis",[6.349978600000001, 46.2740771]],
    [ "Enedis",[6.353747365336598, 46.27623805363869]],
    [ "Enedis",[6.35127115, 46.27646035]],
    [ "Enedis",[6.31585911787813, 46.26860675733039]],
    [ "Enedis",[3.7501236657436237, 45.38230124094266]],
    [ "Enedis",[0.9385670946379019, 45.99687506852903]],
    [ "Poste de livraison",[0.9880762735754811, 46.10384506839889]],
    [ "Enedis",[6.324020899999999, 46.23296085]],
    [ "Enedis",[6.3277116, 46.23714591666666]],
    [ "Enedis",[2.9438586, 43.893698650000005]],
    [ "Armancette",[6.7285618000000005, 45.8188369]],
    [ "Plan du Moulin",[6.727081858413321, 45.83335258956888]],
    [ "Enedis",[4.906483571512075, 45.76088560860307]],
    [ "Enedis",[4.906469149999999, 45.7609369]],
    [ "Enedis",[1.059053533602387, 46.111165651152]],
    [ "Enedis",[1.110365098191152, 46.13145664986321]],
    [ "Sarelec",[4.897486657322599, 47.36422563986387]],
    [ "Enedis",[-0.41564272809736724, 49.186451714949335]],
    [ "Enedis",[1.3771329098958756, 47.63742237835957]],
    [ "Enedis",[-0.4294840000000001, 47.201124899999996]],
    [ "Enedis",[-0.42417536721428256, 47.20151272381696]],
    [ "Enedis",[5.028201026410816, 43.387512243650214]],
    [ "réséda",[6.2368142, 49.1702458]],
    [ "Genevrois 21013P0008",[4.8262512, 47.32241609999999]],
    [ "Enedis",[-0.3006398445983683, 47.852270413315374]],
    [ "Enedis",[6.19567490520656, 46.183095141545856]],
    [ "Enedis",[6.1926104, 46.177493600000005]],
    [ "Enedis",[6.209266568895517, 46.17692760763098]],
    [ "Enedis",[0.13484866880790303, 47.74600379793633]],
    [ "Enedis",[-0.5421262380928655, 47.14320263215946]],
    [ "Enedis",[4.874084, 43.88562294999999]],
    [ "Enedis",[6.217896036686163, 46.19590913878782]],
    [ "Enedis",[0.9612780367934486, 46.09372095303374]],
    [ "Enedis",[4.388393600000001, 45.407855250000004]],
    [ "Enedis",[4.859121979953581, 43.86796777563258]],
    [ "Enedis",[4.855807950000001, 43.87365865]],
    [ "Enedis",[4.855082850000001, 43.88480315000001]],
    [ "Enedis",[1.0464834962563803, 46.114213608461725]],
    [ "Enedis",[4.87263702697352, 43.88111704658771]],
    [ "Enedis",[-0.3277568345497026, 49.138520703056926]],
    [ "Enedis",[4.862739434573756, 43.871842494831576]],
    [ "Enedis",[4.868391871833408, 43.87955517397784]],
    [ "Enedis",[4.866041250354165, 43.8800212343229]],
    [ "Enedis",[4.8582075594466545, 43.88593725144969]],
    [ "Enedis",[4.863779516269172, 43.8871105805187]],
    [ "Enedis",[4.8507665252471615, 43.88147833878482]],
    [ "Enedis",[4.844791799999999, 43.881336600000004]],
    [ "Enedis",[4.8467084, 43.88400650000001]],
    [ "Enedis",[4.844118114617339, 43.870625781813935]],
    [ "Enedis",[4.854898798254832, 43.887288782622484]],
    [ "Enedis",[4.85286645, 43.8871076]],
    [ "Enedis",[4.850916652831438, 43.88752646280299]],
    [ "Enedis",[3.30660305, 43.4895029]],
    [ "réséda",[6.267168234485584, 49.126655897475416]],
    [ "réséda",[6.269097493490359, 49.13422857252534]],
    [ "Poste électrique de Tizée",[-1.5852609676913783, 48.13983412727874]],
    [ "réséda",[6.10172120868432, 48.998722518138734]],
    [ "réséda",[6.566777349999999, 49.0628235]],
    [ "réséda",[6.577448257773407, 49.064827131550636]],
    [ "Enedis",[6.5196542, 44.54000295]],
    [ "Enedis",[6.094878414279983, 44.80339721583099]],
    [ "SNCF Réseau",[3.0043067612009464, 48.567445362813466]],
    [ "SNCF Réseau",[2.84667605, 48.633866700000006]],
    [ "SNCF Réseau",[3.252714871894864, 48.5009518358163]],
    [ "SNCF Réseau",[3.146745150000002, 48.536455149999995]],
    [ "RTE",[0.45655478208151934, 45.45815719544159]],
    [ "Enedis",[5.91919082408369, 44.435558567324335]],
    [ "réséda",[6.092614149999999, 49.09021035]],
    [ "Enedis",[5.703840449999999, 44.243414449999996]],
    [ "Enedis",[4.850830238839981, 43.88325667443767]],
    [ "Enedis",[4.853854686232979, 43.88317150350029]],
    [ "Enedis",[4.853920560746052, 43.883480852309866]],
    [ "Enedis",[4.831148600000001, 43.882204050000006]],
    [ "Enedis",[5.4771772948013435, 44.13610061546294]],
    [ "Enedis",[-0.5238393999999998, 47.150415100000004]],
    [ "Route de Toul",[5.93617195, 48.77140885]],
    [ "Enedis",[3.7736673499999998, 43.57065269999999]],
    [ "Enedis",[6.414695965640176, 48.19879809649444]],
    [ "Enedis",[2.2776284999999996, 48.67429059999999]],
    [ "Enedis",[0.15713529238849364, 47.647116621187315]],
    [ "Enedis",[2.1562135, 48.9238185]],
    [ "Enedis",[2.1424694530341224, 48.925593066762346]],
    [ "SX Leclerc",[2.2930298970516625, 48.77906598572973]],
    [ "Enedis",[4.895796929016297, 43.91175889211844]],
    [ "Enedis",[4.896157135798117, 43.9104279711666]],
    [ "Enedis",[0.9178450003293446, 45.89297836620171]],
    [ "Enedis",[5.576328196854815, 44.97568396519236]],
    [ "Enedis",[0.10955263520949893, 47.81922261789105]],
    [ "Enedis",[2.15923155, 48.915366999999996]],
    [ "Gustave Eiffel",[2.1675978538089606, 48.91077516585924]],
    [ "Enedis",[2.1585679508775244, 48.90839356654336]],
    [ "Poste électrique de Darblay",[1.0070940386178548, 49.36919445072709]],
    [ "Enedis",[0.6817786987529612, 47.3766460180024]],
    [ "Enedis",[0.6940996349163054, 47.374989596283726]],
    [ "Enedis",[1.3841784416321776, 47.63183686422943]],
    [ "Enedis",[4.0325656, 49.33878105000001]],
    [ "Enedis",[3.981255017863782, 49.347563854572115]],
    [ "Enedis",[3.98385695, 49.34181875]],
    [ "Enedis",[3.985607000000001, 49.344232250000005]],
    [ "Enedis",[4.0406175229492725, 49.38595399659448]],
    [ "Enedis",[3.985871715371848, 49.41650185234141]],
    [ "Enedis",[3.9605466500000004, 49.4274547]],
    [ "Enedis",[-0.47304661610751303, 47.10941132457488]],
    [ "Enedis",[3.9618294499999998, 49.43372689999999]],
    [ "Enedis",[3.9614156499999993, 49.435744099999994]],
    [ "Enedis",[3.9671643000000008, 49.438863000000005]],
    [ "Enedis",[3.964489530590792, 49.44277119568195]],
    [ "Enedis",[3.9670833, 49.4438232]],
    [ "Enedis",[3.9676462239971984, 49.44181934675341]],
    [ "Enedis",[3.9732858999999987, 49.43693174999999]],
    [ "Enedis",[3.9675545, 49.433085199999994]],
    [ "camping",[3.970636617682933, 49.432206695289686]],
    [ "Enedis",[3.9758526999999995, 49.43113519999999]],
    [ "Enedis",[3.9765077499999992, 49.43225360000001]],
    [ "Enedis",[3.948968258826646, 49.4345814649949]],
    [ "Enedis",[-0.31015828980707366, 49.11918657806806]],
    [ "Enedis",[6.305955699999998, 46.306818549999996]],
    [ "Enedis",[2.041899828422031, 47.89393730320404]],
    [ "Lapiniere",[1.7996060231195323, 48.64394611504848]],
    [ "Enedis",[2.019518099999999, 47.89965795]],
    [ "Enedis",[1.8246134470125317, 47.86390461785727]],
    [ "Enedis",[4.253126876250962, 46.217040854703]],
    [ "Enedis",[5.016219131234219, 43.40415841457275]],
    [ "Enedis",[5.011290768219163, 43.40587955187905]],
    [ "Enedis",[5.01206438788817, 43.40632575886011]],
    [ "Enedis",[5.01277756545978, 43.405325098312105]],
    [ "Enedis",[5.017549, 43.406494349999996]],
    [ "Enedis",[5.008154024879387, 43.40517709405712]],
    [ "Enedis",[5.008185349999999, 43.4051766]],
    [ "Enedis",[5.021257899999999, 43.406501049999996]],
    [ "Enedis",[5.021819405857294, 43.40951501501598]],
    [ "Enedis",[5.021860913308792, 43.409536583130425]],
    [ "Enedis",[5.0220882162318246, 43.40928914931723]],
    [ "Enedis",[3.8848494327043137, 49.44645944640797]],
    [ "asperges",[4.009361292525946, 49.42357092343524]],
    [ "Enedis",[4.010802924548726, 49.422561543948895]],
    [ "Enedis",[0.8729923120191658, 45.86929926645803]],
    [ "Enedis",[0.8693014290399258, 45.875446336821824]],
    [ "Enedis",[0.8178056349055373, 45.84106388724978]],
    [ "Enedis",[4.028436600000001, 49.413726]],
    [ "Enedis",[4.104648401424259, 49.4244583748665]],
    [ "Enedis",[4.101327221321591, 49.422280641573394]],
    [ "Enedis",[4.124840519395727, 49.42572670264858]],
    [ "Enedis",[4.052210350000001, 49.433775499999996]],
    [ "Enedis",[4.0697104, 49.433641900000005]],
    [ "Enedis",[4.089290527877292, 49.43757484398374]],
    [ "Piquage à Jazeneuil",[0.034849178076778974, 46.44392810425521]],
    [ "Enedis",[0.806432763423567, 45.83646525631948]],
    [ "Enedis",[0.8974118192439159, 45.86595019783976]],
    [ "Enedis",[0.9478460254898038, 45.86939775517219]],
    [ "Enedis",[0.9432494063839232, 45.89458522161914]],
    [ "Enedis",[0.9186449378232202, 45.85823254374748]],
    [ "Enedis",[0.9249992521000523, 45.869946389877]],
    [ "Enedis",[5.0398441243178915, 43.403671945964014]],
    [ "Piscine",[4.090841362815305, 46.02738899284924]],
    [ "Enedis",[0.8544592781587782, 45.847405387974334]],
    [ "Enedis",[0.8525707, 45.8477764]],
    [ "Enedis",[0.8528614302360064, 45.84777465270295]],
    [ "Enedis",[3.8264253706378373, 49.45864989413603]],
    [ "Enedis",[3.8271248208339776, 49.46021251969486]],
    [ "Enedis",[3.8273817161056796, 49.464655253761364]],
    [ "Lys d'Or",[6.137491240639934, 45.90495207341507]],
    [ "Enedis",[3.9138717983451676, 49.48406143389197]],
    [ "Enedis",[3.9069450999999993, 49.489658]],
    [ "59 LGTS OPHLM",[6.165417303822298, 49.087332916597866]],
    [ "Enedis",[4.026181630398007, 49.50919936834424]],
    [ "Enedis",[0.9028122134357861, 45.893546854401364]],
    [ "Enedis",[0.902194677999786, 45.89454717859445]],
    [ "Enedis",[4.165501376712506, 49.425488758848786]],
    [ "Enedis",[4.21615235, 49.41617475000001]],
    [ "Enedis",[0.8657820296443693, 45.875963904388605]],
    [ "Enedis",[0.8693889830834226, 45.86218220147567]],
    [ "Enedis",[0.8448724827701172, 45.873529433958076]],
    [ "Enedis",[0.8445851465966722, 45.868041047573236]],
    [ "Enedis",[0.8299513942550337, 45.87291275425503]],
    [ "Enedis",[0.7360737255493894, 45.876136194637716]],
    [ "Enedis",[-2.2367788, 47.282442599999996]],
    [ "Enedis",[-2.2373842000000006, 47.28389235]],
    [ "Enedis",[-1.0586501673618118, 43.192868450824534]],
    [ "réséda",[6.1724794153691525, 48.99513830279575]],
    [ "réséda",[6.176734149999999, 48.9954649]],
    [ "réséda",[6.311423400000002, 49.07953815]],
    [ "Enedis",[2.1387875000000003, 47.90767145]],
    [ "1226448409",[5.1082664, 45.644794700000006]],
    [ "Enedis",[4.066229625211904, 49.35341240528838]],
    [ "Enedis",[4.07227825, 49.345438800000004]],
    [ "Enedis",[4.087091450000002, 49.34422875]],
    [ "1226449100",[5.107406092021056, 45.64326971566567]],
    [ "Enedis",[4.185575250000001, 49.33857770000001]],
    [ "Enedis",[4.19049825, 49.33743495]],
    [ "Enedis",[4.188445799999998, 49.3357742]],
    [ "Enedis",[4.1843507195890695, 49.33673229690985]],
    [ "Enedis",[4.193468617591874, 49.33616016850018]],
    [ "Enedis",[4.196985284961754, 49.33476853300156]],
    [ "Enedis",[4.198630050000001, 49.33761485000001]],
    [ "Enedis",[4.168558299999999, 49.3156907]],
    [ "Enedis",[4.171212019541332, 49.31584082572858]],
    [ "Enedis",[4.17460232903289, 49.31225391239868]],
    [ "réséda",[6.2446971945721215, 49.23652397845106]],
    [ "réséda",[6.152604549999999, 49.04207404999999]],
    [ "Bastille",[6.350766418955207, 45.279788914414745]],
    [ "Enedis",[4.166990982882138, 49.31382380598429]],
    [ "Enedis",[4.148530953328904, 49.31545037202606]],
    [ "Enedis",[4.1421992, 49.33495755]],
    [ "Enedis",[4.1673556000000005, 49.358125599999994]],
    [ "Enedis",[4.164606320218226, 49.36049734172892]],
    [ "Enedis",[4.158340217873955, 49.36358382805411]],
    [ "Enedis",[4.1618040060205415, 49.36445327834536]],
    [ "Enedis",[2.0691527319607763, 47.840233015956365]],
    [ "Enedis",[2.06155755, 48.0789949]],
    [ "Enedis",[1.92863395, 47.72474319999999]],
    [ "Enedis",[6.648489749999999, 46.285299300000005]],
    [ "Lavay",[6.67206593199109, 46.16889701156886]],
    [ "Enedis",[6.687292749999999, 46.15611875000001]],
    [ "réséda",[6.306882450000001, 49.06478814999999]],
    [ "réséda",[6.303612228337301, 49.06532554544479]],
    [ "réséda",[6.3068765, 49.06948475000001]],
    [ "réséda",[6.263754219996795, 49.084019652936036]],
    [ "réséda",[6.2317333999999995, 49.2398276]],
    [ "réséda",[6.227901017356496, 49.24524879931226]],
    [ "réséda",[6.219419889015259, 49.00991716713324]],
    [ "réséda",[6.213957704702595, 49.016987082591356]],
    [ "réséda",[6.218103432438905, 49.016833652104886]],
    [ "réséda",[6.2224243999999995, 49.01411375]],
    [ "Enedis",[4.063359499999999, 49.38733814999999]],
    [ "Enedis",[4.103940153932315, 49.386397916205915]],
    [ "Enedis",[4.096240204422787, 49.38729718502794]],
    [ "Enedis",[4.0678847305941455, 49.38684320515339]],
    [ "Enedis",[4.091050274361846, 49.386353055279244]],
    [ "Enedis",[4.108750402213594, 49.380569574386406]],
    [ "Enedis",[4.13802154640658, 49.37060453190771]],
    [ "Enedis",[4.060315949999999, 49.37713105]],
    [ "Enedis",[4.0719696, 49.38519005000001]],
    [ "Enedis",[4.01800475, 49.3827616]],
    [ "Enedis",[4.0242087, 49.38027255000001]],
    [ "réséda",[6.114916875587156, 49.17340519678162]],
    [ "réséda",[6.1111254939138036, 49.17143842143509]],
    [ "réséda",[6.1053295499999995, 49.172587549999996]],
    [ "réséda",[6.106787100000001, 49.1734996]],
    [ "réséda",[6.109278999999999, 49.17519195]],
    [ "réséda",[6.359801605906798, 49.08468991944198]],
    [ "réséda",[6.2608178891388, 49.05822153226155]],
    [ "réséda",[6.275450843202412, 49.05396942708561]],
    [ "réséda",[6.258808208175468, 49.045971916024776]],
    [ "réséda",[6.267410372013368, 49.042472195321636]],
    [ "réséda",[6.271461300000001, 49.04687495000001]],
    [ "Enedis",[-2.20260485, 47.28429685000001]],
    [ "Enedis",[-0.60473365, 43.07694235]],
    [ "Enedis",[4.205156934360929, 49.34879899791131]],
    [ "Enedis",[4.206423353903521, 49.347145206748564]],
    [ "Enedis",[4.208966949999999, 49.34598165]],
    [ "Enedis",[4.212541330858851, 49.35682855484827]],
    [ "Enedis",[4.211371850000001, 49.35711985]],
    [ "Enedis",[4.2147264995785525, 49.355982766528115]],
    [ "Enedis",[4.218168232150514, 49.35510995119754]],
    [ "Enedis",[4.225371099999999, 49.356947]],
    [ "Rte de Bazancourt",[4.1965696, 49.35500395]],
    [ "Enedis",[4.178509396563887, 49.35948671558008]],
    [ "Enedis",[4.17458186152454, 49.36027887948472]],
    [ "Les Fontaines",[4.197374299999998, 49.36043215]],
    [ "Epuration",[4.194484750000001, 49.361437300000006]],
    [ "Enedis",[4.218485708458196, 49.34732429206054]],
    [ "Enedis",[4.221798114541975, 49.34985717457931]],
    [ "Enedis",[4.22430685, 49.35120075000001]],
    [ "Enedis",[4.2286318000000005, 49.348023749999996]],
    [ "Enedis",[4.221352146263766, 49.34415231572698]],
    [ "Enedis",[4.226611996635833, 49.34152580206824]],
    [ "Enedis",[0.7300296500000006, 45.8261189]],
    [ "Enedis",[0.8410220026821087, 45.83782200393173]],
    [ "Enedis",[0.8296162992197362, 45.83455504275817]],
    [ "Enedis",[0.8226063499999999, 45.83186459999999]],
    [ "Enedis",[-0.33589664999999996, 49.1439539]],
    [ "Enedis",[0.9314516767639447, 45.846618542534394]],
    [ "Enedis",[0.926032079893288, 45.83702308553777]],
    [ "Enedis",[0.9001181701160281, 45.84740162746555]],
    [ "Enedis",[2.287164519729582, 50.32295212626836]],
    [ "Enedis",[6.736269450000001, 45.85955205]],
    [ "Enedis",[0.9172958447874351, 45.85190042209989]],
    [ "Enedis",[6.6665594, 46.15832609999999]],
    [ "Enedis",[0.8610045585339448, 45.85120779962615]],
    [ "Enedis",[2.178788915772266, 46.11715974741415]],
    [ "Enedis",[2.0133027499999985, 47.851504]],
    [ "Enedis",[2.0090980999999997, 47.90192085000001]],
    [ "Enedis",[2.0527754499999995, 47.925559199999995]],
    [ "Enedis",[-3.174297500012137, 47.975186900257484]],
    [ "Enedis",[0.8573639254317631, 45.84473595540911]],
    [ "Enedis",[0.8595285589588727, 45.837634898303136]],
    [ "Enedis",[0.9522204455349409, 45.90509909738426]],
    [ "Enedis",[0.954880192448353, 45.90047922458442]],
    [ "Enedis",[0.9607904535082502, 45.90537189671455]],
    [ "Enedis",[0.9718846125877765, 45.9025991362202]],
    [ "Enedis",[5.392661449999999, 43.312885449999996]],
    [ "Enedis",[5.392078799999999, 43.3119941]],
    [ "Enedis",[0.9512063896457167, 45.87494737306955]],
    [ "Enedis",[0.9631549257356806, 45.86764535473372]],
    [ "Enedis",[5.033138838779826, 47.29510588296861]],
    [ "Irve Total Relais de la Cote",[5.008034250000001, 47.28029395]],
    [ "Marranello",[5.007957851780774, 47.28033739686743]],
    [ "Enedis",[1.2070780952175406, 49.259501496570856]],
    [ "Enedis",[1.192777428124355, 49.25700919747569]],
    [ "Enedis",[0.9527122938030732, 45.83529507009723]],
    [ "Enedis",[-3.238727499999999, 47.362872849999995]],
    [ "Enedis",[-3.23928865, 47.370800849999995]],
    [ "Enedis",[-3.0959554818435264, 47.30734088752395]],
    [ "Enedis",[-3.1625957558767532, 47.34171082256258]],
    [ "Enedis",[-3.22524645, 47.33714654999999]],
    [ "Enedis",[-3.1514674499999984, 47.310287599999995]],
    [ "Enedis",[-3.082733481011352, 47.29215148051256]],
    [ "Enedis",[-3.2325742, 47.3660264]],
    [ "Enedis",[-3.165830380790196, 47.3427654420029]],
    [ "Enedis",[-3.1636935, 47.31043680000001]],
    [ "Enedis",[-3.1473546499999996, 47.30656059999999]],
    [ "Enedis",[-3.2009187000000003, 47.352883500000004]],
    [ "Enedis",[-3.131064404499323, 47.31430026694294]],
    [ "Enedis",[-3.1573182500000003, 47.35967084999999]],
    [ "Enedis",[-3.1772522500000004, 47.3214319]],
    [ "Enedis",[-3.23446905, 47.33314265]],
    [ "Enedis",[-3.12843425, 47.2992703]],
    [ "Enedis",[-3.114072716745456, 47.293622497974006]],
    [ "Enedis",[-3.187220000000001, 47.33168645000001]],
    [ "Enedis",[-3.1557413038897466, 47.338441732663256]],
    [ "Enedis",[-3.2196977999999996, 47.3051531]],
    [ "Enedis",[-3.1835877399519856, 47.34258732622454]],
    [ "Enedis",[-3.1856399, 47.306977350000004]],
    [ "Enedis",[-3.1880119385223815, 47.31203371142403]],
    [ "Enedis",[-3.1981499756389895, 47.35017256015289]],
    [ "Enedis",[-3.1769055211819315, 47.33689240723723]],
    [ "Enedis",[-3.1962830043549535, 47.33219948369058]],
    [ "Enedis",[-3.166749217941487, 47.327576752991234]],
    [ "Enedis",[-3.1101351999999998, 47.31734675]],
    [ "Enedis",[-3.08011645, 47.312639399999995]],
    [ "Enedis",[-3.21026655, 47.315882249999994]],
    [ "Enedis",[-3.1779685, 47.334122]],
    [ "Enedis",[-3.1739262000000013, 47.3411371]],
    [ "Enedis",[-3.1965480318089172, 47.36141134823013]],
    [ "Enedis",[-3.2270645500000006, 47.346132000000004]],
    [ "Enedis",[-3.1894419500000004, 47.30135665]],
    [ "Enedis",[-3.1177962618707733, 47.317877373282364]],
    [ "Enedis",[-3.1809948593214052, 47.30778808115173]],
    [ "Enedis",[-3.1797543285877645, 47.357619853973695]],
    [ "Enedis",[-3.2088223000000005, 47.348522700000004]],
    [ "Enedis",[-3.215307100000001, 47.3529098]],
    [ "Enedis",[-3.1592864731625836, 47.32090840519673]],
    [ "Enedis",[-3.2473350166724195, 47.35095834923495]],
    [ "Enedis",[-3.0891484499999993, 47.309416]],
    [ "Enedis",[-3.0923670822157434, 47.29857719397475]],
    [ "Enedis",[-3.0873528499999985, 47.29556915]],
    [ "Enedis",[-3.2273971, 47.370371299999995]],
    [ "Enedis",[-3.213479950000001, 47.33150055]],
    [ "Enedis",[-3.2083587760244288, 47.30062144412752]],
    [ "Enedis",[-3.169423, 47.3227329]],
    [ "Enedis",[-3.2442605918056873, 47.34850603355845]],
    [ "Enedis",[-3.078306271248721, 47.28759319512795]],
    [ "Enedis",[-3.0981699174244337, 47.2906717479529]],
    [ "Enedis",[-3.1769986603744718, 47.340201423157104]],
    [ "La Saline 2",[-3.1606735500000007, 47.3465162]],
    [ "Enedis",[-3.1567969403687406, 47.31645592350003]],
    [ "Enedis",[-3.1550253937190362, 47.351092317261454]],
    [ "Enedis",[-3.2345519499999993, 47.37510315000001]],
    [ "Enedis",[-3.188713129966402, 47.34956077552362]],
    [ "Enedis",[-3.0940365181998604, 47.29254604675075]],
    [ "Enedis",[-3.224041973537748, 47.325877153247006]],
    [ "Enedis",[-3.2184244437745044, 47.31907272049939]],
    [ "Enedis",[-3.2226158500000004, 47.37510780000001]],
    [ "Enedis",[-3.1831338, 47.324671550000005]],
    [ "Enedis",[-3.2327483500000014, 47.35071979999999]],
    [ "Enedis",[-3.2055599, 47.3574106]],
    [ "Enedis",[-3.1785567512675614, 47.313599516952536]],
    [ "Enedis",[-3.1490266574338706, 47.331799630635814]],
    [ "Enedis",[-3.085586294413971, 47.29189202582971]],
    [ "Enedis",[-3.16035915, 47.33844925]],
    [ "Enedis",[-3.21109755, 47.337951499999996]],
    [ "Enedis",[-3.1266945999999987, 47.29933404999999]],
    [ "Enedis",[-3.090740139741108, 47.29411208298396]],
    [ "Enedis",[-3.15478857617573, 47.30718729455098]],
    [ "Enedis",[-3.1275136000000003, 47.3094495]],
    [ "Enedis",[-3.0922123499999996, 47.313804250000004]],
    [ "Enedis",[-3.2059336617854433, 47.36450302335393]],
    [ "Enedis",[-3.2146244207655195, 47.31367179562453]],
    [ "Enedis",[-3.2039207059806056, 47.30572272436521]],
    [ "Enedis",[-3.1748576552410768, 47.3068639690336]],
    [ "Enedis",[-3.140222325499458, 47.313696142124456]],
    [ "Enedis",[-3.11964123752816, 47.30923086856622]],
    [ "Enedis",[-3.1701630119395943, 47.351503217756324]],
    [ "Enedis",[-3.23249302437092, 47.33702919423211]],
    [ "Enedis",[-3.0896681334745133, 47.28845770365499]],
    [ "Enedis",[-3.2318251499999997, 47.3717766]],
    [ "Enedis",[-3.217026350000001, 47.356956749999995]],
    [ "Enedis",[-3.15777529, 47.33379772416667]],
    [ "Enedis",[-3.1307493500000003, 47.3137139]],
    [ "Enedis",[-3.1557249000000005, 47.32916135000001]],
    [ "Enedis",[-3.2221020442105934, 47.361685877963076]],
    [ "Enedis",[-3.21209435, 47.3602238]],
    [ "Enedis",[-3.081301740374186, 47.29926327084421]],
    [ "Enedis",[4.173725374318584, 49.34091701707302]],
    [ "Champagne Céréales",[4.170824205627924, 49.341830379547076]],
    [ "Enedis",[4.16991175, 49.34279574999999]],
    [ "Enedis",[4.169878525185952, 49.34265220376507]],
    [ "Enedis",[-2.8678545, 47.342655549999996]],
    [ "Enedis",[-3.1289984291548243, 47.51350050251987]],
    [ "Enedis",[-3.1343164243837873, 47.51611975374218]],
    [ "Enedis",[-3.1229155172308296, 47.483606253860636]],
    [ "Enedis",[-3.132746833453737, 47.47850741862554]],
    [ "Enedis",[-3.1382181000000005, 47.538347300000005]],
    [ "Rue port halig",[-3.1055847, 47.48448690000001]],
    [ "Enedis",[-3.1232973, 47.489850800000006]],
    [ "Enedis",[-3.1185871999999994, 47.481899799999994]],
    [ "Enedis",[-3.1257625700876503, 47.48885100436254]],
    [ "Enedis",[-3.12973895, 47.51039065000001]],
    [ "Enedis",[-3.1472641, 47.52616935]],
    [ "Rue du Guibelo",[-3.141024931305759, 47.50002881594254]],
    [ "Enedis",[-3.1238827999999996, 47.48682475]],
    [ "Enedis",[-3.1058335000000006, 47.4875513]],
    [ "Enedis",[-3.1439323999999997, 47.526045999999994]],
    [ "Stade Port Haliguen",[-3.109521230955183, 47.484123766683275]],
    [ "Enedis",[-3.1275179834439304, 47.489443751648416]],
    [ "Enedis",[-3.1361730780743278, 47.53219031112599]],
    [ "Enedis",[-3.1252276499999994, 47.4975731]],
    [ "Enedis",[-3.137226377587646, 47.513005146863904]],
    [ "Enedis",[-3.112707651588702, 47.47772202595891]],
    [ "Phare",[-3.1240601913689736, 47.47934842315244]],
    [ "Enedis",[-3.143940349999999, 47.52989569999999]],
    [ "Enedis",[-3.137907429450021, 47.51633999718996]],
    [ "Le Cimetière",[-3.1213425499999996, 47.48771889999999]],
    [ "Enedis",[-3.1246630499999997, 47.49256595000001]],
    [ "Enedis",[-3.1249716368625333, 47.48586367686886]],
    [ "Enedis",[-3.1306471737405843, 47.47635144510797]],
    [ "Enedis",[-3.1316525764722973, 47.483673244635135]],
    [ "Enedis",[-3.13718085, 47.50532365]],
    [ "Enedis",[-3.1031120449647234, 47.47773583088718]],
    [ "Enedis",[-3.12158995, 47.4910224]],
    [ "Enedis",[-3.101688387464388, 47.47388813333333]],
    [ "Enedis",[-3.1398532878010426, 47.4919640683555]],
    [ "Enedis",[-3.1431155, 47.51751045]],
    [ "Enedis",[-3.11322635, 47.47944755]],
    [ "Enedis",[-2.748864337666677, 47.66752104937533]],
    [ "Enedis",[0.6626880629531335, 46.06497505429257]],
    [ "Enedis",[0.9028641999999999, 45.89914005000001]],
    [ "Enedis",[0.9030555185993142, 45.900684353304044]],
    [ "Enedis",[-4.156085089558758, 47.867339517530375]],
    [ "Enedis",[5.07262328975176, 47.30503732648512]],
    [ "Contamines",[5.534473465560803, 45.41228137164804]],
    [ "Enedis",[2.033429172986394, 47.914772753930556]],
    [ "Enedis",[2.1282090040097934, 48.79908718304953]],
    [ "Enedis",[6.273681434119483, 47.15219646702265]],
    [ "LEYMIEUX",[4.561526105028337, 45.52909038000852]],
    [ "Enedis",[1.176970215877229, 43.590031152076676]],
    [ "Enedis",[1.1768070767750258, 43.590141031674335]],
    [ "Enedis",[1.1747061864416968, 43.58896468405389]],
    [ "Enedis",[1.1737871746914281, 43.58929965907938]],
    [ "Enedis",[1.8712461825934679, 47.963400773366914]],
    [ "Enedis",[0.7252062540847021, 45.99636535592941]],
    [ "Enedis",[5.90764775, 49.30143995000001]],
    [ "Le Chevalard",[3.0869196999999997, 45.81188985]],
    [ "Enedis",[4.978542585592243, 45.005680788830674]],
    [ "Enedis",[1.969572860255573, 47.96782913012441]],
    [ "Enedis",[0.6878034055805682, 46.0215958945503]],
    [ "Enedis",[0.7617905227258058, 46.0980945333169]],
    [ "Enedis",[0.7703393989952869, 46.09760670083842]],
    [ "Enedis",[0.7647548985903633, 46.09450335345033]],
    [ "Enedis",[0.7428101324685259, 46.07323584664023]],
    [ "Enedis",[0.7194923346820551, 46.072135434519765]],
    [ "Enedis",[0.7209473750182817, 46.07926855968789]],
    [ "Nord",[3.9476502966859455, 49.296492879816704]],
    [ "Enedis",[4.412974470983996, 45.43623222842693]],
    [ "Enedis",[5.9294024682167095, 48.73673699704344]],
    [ "Poste électrique de Westhouck",[2.2476432907289157, 51.031984857274885]],
    [ "Enedis",[2.305876896572693, 49.100257617339246]],
    [ "Enedis",[2.408104956137685, 48.29285973454211]],
    [ "Enedis",[4.15598905, 48.279956350000006]],
    [ "Enedis",[4.139732100096368, 48.20516053329031]],
    [ "Enedis",[4.581579125963758, 48.763606565694126]],
    [ "Henri 4",[5.704480362791905, 45.153839885976275]],
    [ "Enedis",[2.0345152075371975, 48.351503884580744]],
    [ "RTE",[1.7364198951997747, 48.26546758721085]],
    [ "Enedis",[2.1486679159465485, 48.93281840196395]],
    [ "Enedis",[1.4366104668404773, 46.06795652965218]],
    [ "Enedis",[4.1590263499999995, 48.36029039999999]],
    [ "Enedis",[4.156477034610034, 48.359770946053025]],
    [ "Enedis",[4.100585525857555, 48.33166405836164]],
    [ "Enedis",[0.987482603092385, 47.34644438336555]],
    [ "Enedis",[-4.154620730845772, 47.87319399684909]],
    [ "Enedis",[0.7820060199730178, 45.957257205230235]],
    [ "SNCF Réseau",[4.963085504850755, 47.15089779037606]],
    [ "Enedis",[2.7809215622529235, 42.848331725625236]],
    [ "RTE",[1.0841001527364864, 43.21299513409611]],
    [ "P TSA GEH Auzat",[1.4777178291268356, 42.76104419111693]],
    [ "1236363679",[-0.21051170215555765, 46.158284407296385]],
    [ "Enedis",[4.891264301624914, 45.74422200119294]],
    [ "1236930242",[1.5118679878938481, 44.050851766141186]],
    [ "1236930249",[2.2276850517354934, 42.9739227949469]],
    [ "RTE",[2.1653827412813937, 42.774400090120444]],
    [ "RTE",[2.2131717988044186, 42.77174768394441]],
    [ "Enedis",[0.6501879763146371, 46.05832338919582]],
    [ "Enedis",[0.8923835466085008, 45.828724400090444]],
    [ "Enedis",[0.8782923246820662, 45.8204562082878]],
    [ "Enedis",[0.9044668700452959, 45.82940208332221]],
    [ "réséda",[6.685564380170005, 49.044285496239574]],
    [ "réséda",[6.664638139806878, 49.05753092863251]],
    [ "réséda",[6.648262531613059, 49.06257735434369]],
    [ "réséda",[6.138960000000001, 49.1933718]],
    [ "réséda",[6.136970549999999, 49.19682244999999]],
    [ "réséda",[6.296201549999998, 49.159608750000004]],
    [ "Enedis",[0.9037363001099253, 45.820407864455305]],
    [ "Enedis",[0.890447398762326, 45.815604800006774]],
    [ "Enedis",[0.9211858215198847, 45.8188104815034]],
    [ "Enedis",[0.9216687294344246, 45.82390609125182]],
    [ "Enedis",[0.9227252932865642, 45.824101489530825]],
    [ "Pont d'Arc",[6.379858100000001, 45.26706755]],
    [ "Sorea",[6.3798002536237925, 45.267075224045385]],
    [ "SNCF Maintenance",[6.3558657, 45.27775325]],
    [ "Enedis",[0.9655313012832816, 45.8643304142963]],
    [ "Enedis",[0.9769834899382301, 45.85511405733056]],
    [ "Enedis",[0.9880978039701737, 45.85107734848347]],
    [ "Enedis",[0.9814576542184743, 45.851870953051616]],
    [ "Enedis",[0.9926234826224636, 45.837979000145666]],
    [ "Enedis",[0.6327081601769733, 45.94554813101275]],
    [ "Saint-Amand",[3.3338589685434346, 49.820154039000194]],
    [ "Enedis",[-0.2867076999999995, 49.099908]],
    [ "Enedis",[0.9909090088666191, 45.94696422047652]],
    [ "PAB Atelier fromagerie",[2.9967307, 44.930178500000004]],
    [ "Longairo",[2.996670231228761, 44.93017977595222]],
    [ "Enedis",[3.8364995103186845, 49.358454278806]],
    [ "réséda",[6.2123786999999995, 49.103595049999996]],
    [ "Enedis",[0.8902343494932053, 45.89805195424585]],
    [ "Enedis",[1.876918849591108, 47.95903205059009]],
    [ "Enedis",[1.8783808080111832, 47.95795469076856]],
    [ "Enedis",[1.8860471323355448, 47.96300942145638]],
    [ "Enedis",[1.8876755780387644, 47.962966922014104]],
    [ "Enedis",[3.070926980831681, 43.19494740783464]],
    [ "Enedis",[4.61646815, 49.0229751]],
    [ "Enedis",[4.609831181274103, 49.01587394789316]],
    [ "Enedis",[4.613104414588982, 49.01671353037325]],
    [ "Enedis",[4.61742571973741, 49.01751190337089]],
    [ "Enedis",[6.186456690747443, 46.05641316701285]],
    [ "Enedis",[4.6718611999999995, 49.02329279999999]],
    [ "Enedis",[4.684692640578979, 49.01977672466266]],
    [ "Enedis",[4.698311377609959, 49.022211204612866]],
    [ "Enedis",[4.750661, 49.036067100000004]],
    [ "Enedis",[0.6443094823861125, 45.904102663307306]],
    [ "wikidata=Q3587594",[2.87349437165218, 43.3200419434975]],
    [ "wikidata=Q3587594",[2.8747795732551853, 43.32163889495641]],
    [ "wikidata=Q3587594",[2.8617979983749873, 43.31654553955226]],
    [ "wikidata=Q3587594",[2.870714914809001, 43.316613168696776]],
    [ "Enedis",[4.54565395, 48.990668850000006]],
    [ "Enedis",[4.5456895500000005, 48.990704150000006]],
    [ "Enedis",[4.545711424826709, 48.99232494165607]],
    [ "Enedis",[3.492703500585831, 47.33934399988468]],
    [ "wikidata=Q3587594",[2.8708823890243895, 43.2985760407859]],
    [ "Enedis",[4.484208010709193, 48.97634377584597]],
    [ "Enedis",[1.8880903, 47.936684199999995]],
    [ "Enedis",[-2.070243223835799, 48.1584792085423]],
    [ "Enedis",[0.8186820542411715, 45.836707545596646]],
    [ "Enedis",[0.8332443713934834, 45.84204862686756]],
    [ "Enedis",[-0.27498462626479053, 49.07990794401624]],
    [ "Enedis",[3.231772053356072, 50.19038703633607]],
    [ "Enedis",[3.23057095, 50.1896678]],
    [ "Enedis",[3.285189943884321, 50.16114120237135]],
    [ "Enedis",[3.24385085, 50.17822345]],
    [ "Enedis",[3.2498212, 50.187011350000006]],
    [ "Enedis",[3.236357417902928, 50.18307645214311]],
    [ "Enedis",[3.22920825, 50.18131165]],
    [ "Enedis",[3.2088925652942333, 50.11683634247488]],
    [ "Enedis",[3.2257071006181683, 50.12744203354266]],
    [ "Enedis",[3.2611742999999986, 50.14463389999999]],
    [ "Enedis",[3.2226313226830876, 50.15584781224924]],
    [ "Enedis",[3.2461717000000005, 50.1632773]],
    [ "Enedis",[3.1814369168423355, 50.18703029747787]],
    [ "Enedis",[3.189728846560742, 50.18671483191752]],
    [ "Enedis",[3.198026964003865, 50.19589383001573]],
    [ "Enedis",[3.20526915, 50.1999149]],
    [ "Enedis",[3.2104931999999997, 50.203165799999994]],
    [ "Enedis",[3.2186328981484684, 50.19690326609646]],
    [ "Enedis",[3.2157806022127056, 50.19782886266952]],
    [ "Enedis",[3.2140932650562717, 50.18044948424985]],
    [ "Enedis",[3.1946561000000004, 50.1827965]],
    [ "Enedis",[3.2061574207353023, 50.17895027795196]],
    [ "Enedis",[3.24025855, 50.1771499]],
    [ "Enedis",[3.157852033545079, 50.210252652529185]],
    [ "Enedis",[3.1644124000000007, 50.213375500000005]],
    [ "Enedis",[3.165267083074373, 50.213815699792306]],
    [ "Enedis",[3.186562467119958, 50.21181650187642]],
    [ "Enedis",[3.19077295, 50.209256550000006]],
    [ "Enedis",[3.2374661499999995, 50.1740707]],
    [ "Enedis",[3.2353366, 50.17511045]],
    [ "Enedis",[3.2430096966221225, 50.14705662534231]],
    [ "Enedis",[3.2429708204879435, 50.147045003509675]],
    [ "Enedis",[3.1924879173546716, 50.117273572165054]],
    [ "Enedis",[3.1846227192877135, 50.118698648183745]],
    [ "Enedis",[3.181366998954988, 50.11987628918097]],
    [ "Enedis",[3.1791817499999997, 50.12287895]],
    [ "Enedis",[3.2165548, 50.11034395000001]],
    [ "Enedis",[3.2227524700555, 50.127469470952505]],
    [ "Enedis",[3.2136985272275798, 50.12980600369954]],
    [ "Enedis",[3.208335144256712, 50.11602681322333]],
    [ "Enedis",[3.2029425500000013, 50.115062599999995]],
    [ "Enedis",[0.8159476687547408, 45.826406931336976]],
    [ "Enedis",[3.1971485500000005, 50.19169255]],
    [ "Enedis",[3.196104580141324, 50.19288548616382]],
    [ "Enedis",[3.245474750000001, 50.1709303]],
    [ "Enedis",[3.23447895, 50.17601695]],
    [ "Enedis",[3.2252270092478534, 50.17625331514835]],
    [ "Enedis",[3.24108855, 50.18359745000001]],
    [ "Enedis",[3.2511204500000006, 50.164013049999994]],
    [ "Enedis",[3.25674652065748, 50.168533815773756]],
    [ "Enedis",[1.8942352000000002, 47.942615200000006]],
    [ "Enedis",[3.332489939220998, 43.537860792018016]],
    [ "Enedis",[4.16019955, 43.96033345000001]],
    [ "Enedis",[4.160487967429146, 43.95880105166164]],
    [ "Enedis",[-2.0353353055512575, 48.19491846452962]],
    [ "Enedis",[0.7666646104545961, 46.06315928381285]],
    [ "Enedis",[-2.0321163164433895, 48.200931450276464]],
    [ "Enedis",[-2.048298782789232, 48.19933045555586]],
    [ "RTE",[3.3616103371207013, 49.91932609511986]],
    [ "Enedis",[-2.055741536550416, 48.20393786682895]],
    [ "Enedis",[1.942578912899518, 47.905172768515875]],
    [ "Enedis",[4.495890848342061, 49.04695306970939]],
    [ "Enedis",[4.50195335, 49.04832935000001]],
    [ "Np Pins",[4.5051303385020915, 49.045656879556034]],
    [ "Enedis",[-1.9396375460037043, 48.177232777535885]],
    [ "Enedis",[0.6268571514560372, 45.92521549534694]],
    [ "Enedis",[0.7248388163488837, 46.086590170191315]],
    [ "Enedis",[0.7221961798546284, 46.097755204945784]],
    [ "Enedis",[0.7200578253891923, 46.09380989335182]],
    [ "Enedis",[0.7137003334004296, 46.0962229951898]],
    [ "Enedis",[0.9664614650970887, 46.117097968500616]],
    [ "Enedis",[-0.39421829999999997, 49.199690149999995]],
    [ "Enedis",[4.53961746614166, 49.06255041766497]],
    [ "Enedis",[4.545165289388006, 49.06258628000336]],
    [ "Enedis",[4.5438379835132405, 49.0604890728359]],
    [ "Enedis",[-1.9428034499999998, 48.18255710000001]],
    [ "Enedis",[-1.9316130686587323, 48.18629552087449]],
    [ "Enedis",[2.2173418999999996, 48.7676817]],
    [ "Enedis",[2.21824815, 48.76606085]],
    [ "Marché",[-0.2614187536911854, 49.28473007952106]],
    [ "La Coqueraine",[-0.26804297754660256, 49.28097821884557]],
    [ "Le Misargue",[-0.27307715296996465, 49.281905876024744]],
    [ "Soler",[2.19852805, 48.716612299999994]],
    [ "Enedis",[4.5186047500000015, 48.9824103]],
    [ "Epinette",[4.52000287460283, 48.98428865580927]],
    [ "Enedis",[4.527616271896887, 48.984522765673375]],
    [ "Enedis",[1.3484718999999998, 43.091886949999996]],
    [ "Enedis",[4.606747021116234, 48.97411226914284]],
    [ "Enedis",[4.529651086351191, 48.91093623351293]],
    [ "Enedis",[4.444739081890934, 48.89469494699054]],
    [ "Enedis",[4.400997183552548, 48.900537975452266]],
    [ "Enedis",[4.399406470569447, 48.90378013957874]],
    [ "Sogny",[4.395102400000001, 48.90128420000001]],
    [ "providence",[4.346430999999999, 48.92077999999999]],
    [ "Enedis",[4.3535699, 48.92055045]],
    [ "Enedis",[4.3400247499999995, 48.89873125]],
    [ "Enedis",[4.333148722711005, 48.89195148357314]],
    [ "Enedis",[4.29659945, 48.876351150000005]],
    [ "Enedis",[4.3069370000000005, 48.8801061]],
    [ "Enedis",[4.31114455, 48.877846950000006]],
    [ "Enedis",[3.1876684939406714, 47.11324920131853]],
    [ "1244176551",[1.8360593, 42.656881000000006]],
    [ "Thermot",[4.414697799999999, 48.922015699999996]],
    [ "Enedis",[4.4008631000000005, 48.87965775000001]],
    [ "Enedis",[4.408164716383499, 48.882417699534344]],
    [ "Enedis",[4.41622245, 48.88126675]],
    [ "1244599187",[4.8829215999999995, 44.0883476]],
    [ "Enedis",[4.389683944508236, 48.8715753179705]],
    [ "Enedis",[4.4242463, 48.870103900000004]],
    [ "Enedis",[-1.9913404499999998, 48.2134813]],
    [ "Enedis",[0.9099469479462333, 46.12101848359427]],
    [ "wikidata=Q3587594",[2.8748160444488757, 43.30083185118854]],
    [ "wikidata=Q3587594",[2.8716196422204128, 43.312572960636466]],
    [ "wikidata=Q3587594",[2.8665353896917303, 43.32156407713356]],
    [ "Enedis",[-1.9465637279662515, 48.24148670963884]],
    [ "Enedis",[-1.9477803808325862, 48.24236130898328]],
    [ "Enedis",[-1.95124675, 48.2454248]],
    [ "1245081591",[6.697184973039571, 45.20206368663517]],
    [ "1245081592",[6.696055407557355, 45.20131421592442]],
    [ "Enedis",[4.388331400723905, 45.423464778755104]],
    [ "Enedis",[-1.9022489672870642, 48.24356370076297]],
    [ "Enedis",[1.0868949639602175, 46.13141666320303]],
    [ "Enedis",[1.0818491136742077, 46.130029548971834]],
    [ "Enedis",[-1.88925885, 48.221784899999996]],
    [ "Enedis",[-1.8908824958620687, 48.21256506885058]],
    [ "poste_1791",[2.5486199, 49.59801765]],
    [ "Enedis",[1.0568239521603486, 46.094937750803304]],
    [ "Enedis",[1.071888802820218, 46.09100459493456]],
    [ "Enedis",[1.0624052713674286, 46.090451996458135]],
    [ "Enedis",[1.0742709478703045, 46.10592662487321]],
    [ "Enedis",[2.0311384999999986, 47.90239530000001]],
    [ "Enedis",[2.032737882102908, 47.90220574645787]],
    [ "Enedis",[2.0297513522823243, 47.90254641764754]],
    [ "Enedis",[0.6213179322297699, 43.65056545829795]],
    [ "Enedis",[2.0387201160541064, 47.90416684940876]],
    [ "Enedis",[1.142257263930695, 46.078585847499006]],
    [ "Enedis",[-4.0719920067646616, 48.50328357253927]],
    [ "Enedis",[1.113512911964477, 46.132003449968174]],
    [ "Enedis",[1.1089756410194673, 46.138765831446335]],
    [ "Enedis",[1.1231563530570692, 46.14426091780396]],
    [ "Enedis",[1.1370718229081376, 46.146006896417596]],
    [ "Enedis",[-2.2753976999999996, 48.08719874999999]],
    [ "Enedis",[-2.274964280946739, 48.08563105756314]],
    [ "Enedis",[-2.2852912, 48.08395525]],
    [ "Enedis",[-2.2811207425841915, 48.083976677269696]],
    [ "Enedis",[3.642166393249463, 49.826232843921105]],
    [ "Enedis",[3.6411160500000004, 49.83019815]],
    [ "Enedis",[3.644370722864485, 49.819479825373655]],
    [ "Enedis",[3.5914343499999997, 49.81697425]],
    [ "Enedis",[3.5914203, 49.816991550000004]],
    [ "Enedis",[3.5914024, 49.81701155]],
    [ "Enedis",[3.5913841, 49.817034449999994]],
    [ "Enedis",[3.5913676821628626, 49.817054246275774]],
    [ "Enedis",[3.5913505000000003, 49.81707525]],
    [ "Enedis",[1.9462539069521507, 47.90445682855851]],
    [ "Enedis",[1.1109507810576662, 46.151037715965955]],
    [ "Enedis",[1.8958178500000002, 47.85752715]],
    [ "wikidata=Q3587594",[2.86418134980941, 43.31446833298513]],
    [ "Enedis",[-2.481245910490244, 48.2151144212387]],
    [ "Enedis",[4.6366885500000015, 44.363956050000006]],
    [ "AS Barbusse 114",[2.274286410816013, 48.912442535450346]],
    [ "Enedis",[4.157993580886634, 47.50414906874273]],
    [ "SRD",[0.2611057500000024, 46.1211431]],
    [ "Enedis",[7.099693640074114, 48.671638733650965]],
    [ "Enedis",[4.271002825928235, 47.45485175764154]],
    [ "Enedis",[6.091489958618446, 48.61702736865418]],
    [ "Enedis",[4.405535700000001, 45.430520900000005]],
    [ "Enedis",[4.8318002, 44.11949470000001]],
    [ "Enedis",[0.9162369110673163, 46.13349826881115]],
    [ "Enedis",[0.9598944866120068, 46.13640523254367]],
    [ "Enedis",[5.195151243201259, 43.37432572033806]],
    [ "1249935198",[-2.0578057856689433, 48.10352979634511]],
    [ "Enedis",[4.266629609871224, 47.47443177846675]],
    [ "Enedis",[4.304206917399023, 47.440933446189625]],
    [ "Enedis",[5.194293423827008, 43.37922934389149]],
    [ "Enedis",[-4.0512052, 48.514099249999994]],
    [ "Enedis",[4.2926527, 45.39559164999999]],
    [ "Enedis",[4.976970006590804, 44.393219069448975]],
    [ "Enedis",[0.956575709068804, 46.16771668891898]],
    [ "Enedis",[1.369534919563781, 48.757796729665124]],
    [ "Enedis",[0.6149443, 47.98869465]],
    [ "réséda",[6.3183074999999995, 49.1344274]],
    [ "réséda",[6.3137853, 49.13411610000001]],
    [ "réséda",[6.2951774, 49.12989170000001]],
    [ "réséda",[6.3011301500000005, 49.1378897]],
    [ "réséda",[6.252697494361182, 49.07165146765345]],
    [ "réséda",[6.2547233, 49.07064174999999]],
    [ "Enedis",[-0.9193884429070687, 45.61354866517341]],
    [ "1252345142",[4.843376574671237, 43.446156875232596]],
    [ "Enedis",[2.918669686425221, 49.41459467694757]],
    [ "réséda",[6.148611700000001, 49.18581300000001]],
    [ "réséda",[6.118438073572634, 49.19139330956604]],
    [ "réséda",[6.198347088463582, 49.03798207985114]],
    [ "réséda",[6.19401968141835, 49.03970834639053]],
    [ "réséda",[6.197570200000001, 49.04248105]],
    [ "réséda",[6.193766061332087, 49.04270176881862]],
    [ "réséda",[6.191381382378091, 49.04479564362649]],
    [ "réséda",[6.155878731616192, 49.03610464620487]],
    [ "réséda",[6.158307592329425, 49.03472992024046]],
    [ "réséda",[6.162580745395415, 49.03929216753912]],
    [ "Enedis",[4.447284510510732, 47.155758917736755]],
    [ "Parc d'activité Tanesse",[-0.5094633328336504, 44.80005740059378]],
    [ "Enedis",[2.0218599661032632, 47.95330048464039]],
    [ "Enedis",[2.0252473371203044, 47.956633078282806]],
    [ "Enedis",[2.0049655007186717, 47.9485638999193]],
    [ "Enedis",[2.0043062037045987, 47.95138565364874]],
    [ "Enedis",[2.0127797489478763, 47.94717690608296]],
    [ "Enedis",[1.9965563084346765, 47.96149670911135]],
    [ "Vil Ménillot",[5.814199745107047, 48.66256308280483]],
    [ "réséda",[6.6223904000000005, 49.05454145]],
    [ "réséda",[6.6231747013311, 49.048689862840824]],
    [ "réséda",[6.632138766698603, 49.04735870005206]],
    [ "réséda",[6.639838799999999, 49.04518139999999]],
    [ "réséda",[6.63981715655152, 49.044117782201205]],
    [ "réséda",[6.636204450000001, 49.04281509999999]],
    [ "réséda",[6.1231211000000005, 49.12915545]],
    [ "réséda",[6.124175696314534, 49.1352826838655]],
    [ "réséda",[6.128433289508398, 49.13504083009399]],
    [ "réséda",[6.04168995, 49.02175314999999]],
    [ "réséda",[6.0502041639399105, 49.022712569193466]],
    [ "réséda",[6.0511669, 49.030792299999995]],
    [ "Enedis",[-4.089107143612157, 48.50356312828943]],
    [ "wikidata=Q3587594",[2.865267336515083, 43.31759500232625]],
    [ "réséda",[6.139507127081077, 48.94657619154624]],
    [ "réséda",[6.133203408786512, 48.94370007702689]],
    [ "réséda",[6.1628168999999975, 48.94625545]],
    [ "réséda",[6.056444899999999, 49.06265415]],
    [ "réséda",[6.0639829999999995, 49.06309669999999]],
    [ "réséda",[6.059047713131883, 49.06065587325359]],
    [ "réséda",[6.058046419662749, 49.04719514614042]],
    [ "réséda",[6.221574056984951, 49.002284268145594]],
    [ "réséda",[6.200250322424954, 49.002718623906226]],
    [ "réséda",[6.205732316709587, 49.00652444890719]],
    [ "réséda",[6.195243608066199, 49.00501598046667]],
    [ "réséda",[6.076776447676305, 49.130244617638866]],
    [ "réséda",[6.051061221242093, 49.1454309805667]],
    [ "réséda",[6.05391472132859, 49.138293832448774]],
    [ "Enedis",[5.600809808572967, 45.32155656846527]],
    [ "Enedis",[6.6717465643997365, 45.693941570652676]],
    [ "réséda",[6.204707353052376, 49.24859607074228]],
    [ "réséda",[6.201584664703089, 49.24507698181232]],
    [ "réséda",[6.2091534219701074, 49.242135873750776]],
    [ "réséda",[6.208815041515012, 49.239602173438534]],
    [ "réséda",[6.208325600000002, 49.2384262]],
    [ "réséda",[6.297694150000002, 48.96759424999999]],
    [ "réséda",[6.295276925661786, 48.96525785705193]],
    [ "réséda",[6.295506135981391, 48.963403917000974]],
    [ "réséda",[6.1893836, 48.96266435]],
    [ "réséda",[6.186692571405566, 48.96245536952117]],
    [ "réséda",[6.189461349999998, 48.9605558]],
    [ "réséda",[6.18404446488803, 48.95762751504449]],
    [ "réséda",[6.1945375, 49.147634899999986]],
    [ "réséda",[6.186307482380603, 49.15206704340279]],
    [ "réséda",[6.188021021536117, 49.16232744720965]],
    [ "réséda",[6.188496349999999, 49.1673371]],
    [ "réséda",[6.185756319960897, 49.16871959222798]],
    [ "réséda",[6.191898065312766, 49.16850117283506]],
    [ "réséda",[6.189193324906408, 49.17087305917047]],
    [ "Enedis",[4.784172616874892, 45.4299605338011]],
    [ "Enedis",[4.781850435642148, 45.43225863006604]],
    [ "Enedis",[1.8971996314314163, 47.90627323330219]],
    [ "Enedis",[3.7413768500000004, 49.2592997]],
    [ "Enedis",[0.4771205650535922, 45.9410572796031]],
    [ "Enedis",[-4.473426554500156, 48.38695860314905]],
    [ "Enedis",[-4.480555579745011, 48.38479983516858]],
    [ "Enedis",[1.9016832427159465, 47.880167927632776]],
    [ "Enedis",[5.810231209453839, 49.32551577385052]],
    [ "Le Chalet",[3.831242643390808, 49.206309070838586]],
    [ "Enedis",[3.82528095, 49.207444]],
    [ "réséda",[6.1437756, 49.1797607]],
    [ "réséda",[6.142984700000001, 49.18334765000001]],
    [ "réséda",[6.14006555, 49.1799517]],
    [ "réséda",[6.1387701513320785, 49.18283713288669]],
    [ "réséda",[6.203731789648534, 49.191124577444995]],
    [ "Bergerie",[6.21332036268968, 49.20547171864568]],
    [ "réséda",[6.215261099999998, 49.206538699999996]],
    [ "réséda",[6.188903165314813, 49.19065715299794]],
    [ "réséda",[6.238390967879834, 49.08572950806082]],
    [ "réséda",[6.229860249999999, 49.073111600000004]],
    [ "réséda",[6.221690248491046, 49.07439743381385]],
    [ "réséda",[6.2193527500000005, 49.0732548]],
    [ "réséda",[6.3050805, 49.205370099999996]],
    [ "réséda",[6.1111544191820535, 49.155687523156594]],
    [ "réséda",[6.115337450000001, 49.15539275]],
    [ "réséda",[6.117408778794002, 49.15387235864338]],
    [ "réséda",[6.115650650000002, 49.14223835]],
    [ "Clos de Vigneulles",[6.115789753586732, 49.146830317945174]],
    [ "réséda",[6.121531908213482, 49.147056230502464]],
    [ "réséda",[6.135148109342591, 49.14022628107149]],
    [ "Enedis",[-4.045912800000001, 48.518075550000006]],
    [ "Enedis",[0.6209747529386365, 48.763531536730554]],
    [ "Enedis",[0.9253959159677683, 46.85729774945471]],
    [ "réséda",[6.144562028421765, 49.121416276577335]],
    [ "réséda",[6.15237595, 49.1224528]],
    [ "Enedis",[0.6586203997873101, 45.880250602835865]],
    [ "Enedis",[-2.3027456421001022, 48.210256760297106]],
    [ "réséda",[6.267434400000001, 49.119032]],
    [ "réséda",[6.28200370499784, 49.123073730872854]],
    [ "réséda",[6.284822149098844, 49.122274326805424]],
    [ "réséda",[6.163044812354169, 49.11357916628457]],
    [ "réséda",[6.129249052662606, 49.11352833214066]],
    [ "Enedis",[0.15590234999999997, 47.9453437]],
    [ "Enedis",[3.79770920622642, 49.235598347893706]],
    [ "Enedis",[4.3352229, 45.39033995]],
    [ "réséda",[6.213631106276029, 49.152070617942115]],
    [ "réséda",[6.1998582, 49.141680550000004]],
    [ "réséda",[6.206618400000001, 49.137128149999995]],
    [ "réséda",[6.199348099999998, 49.134264449999996]],
    [ "réséda",[6.197203187211374, 49.12611167052403]],
    [ "réséda",[6.2050135734126295, 49.126245376733344]],
    [ "réséda",[6.202567879350113, 49.129234776837514]],
    [ "réséda",[6.2065485, 49.1314564]],
    [ "réséda",[6.207906482341853, 49.13252293336181]],
    [ "réséda",[6.129071662784441, 49.12126132830623]],
    [ "réséda",[6.115127500000001, 49.11291979999999]],
    [ "réséda",[6.10978805, 49.11432705000001]],
    [ "réséda",[6.1134414, 49.10522415]],
    [ "réséda",[6.1326067337899195, 49.10788210289647]],
    [ "réséda",[6.136834819228487, 49.29638742612266]],
    [ "Enedis",[1.1925940741255079, 45.83447289126351]],
    [ "Enedis",[1.1762951573841975, 45.84225564731279]],
    [ "Enedis",[1.177260958002357, 45.8439144040575]],
    [ "Enedis",[1.1188915500000007, 45.8678059]],
    [ "Enedis",[1.1160034825713339, 45.87904500934229]],
    [ "réséda",[6.343350649999999, 49.012549549999996]],
    [ "réséda",[6.383310750000001, 49.01843035000001]],
    [ "réséda",[6.3993719, 49.00985215]],
    [ "réséda",[6.38611198356517, 48.99936214967389]],
    [ "réséda",[6.095637435533316, 49.07394784552958]],
    [ "réséda",[6.081201199999999, 49.0685263]],
    [ "réséda",[6.075189, 49.058320699999996]],
    [ "réséda",[6.074191144234884, 49.05681443131814]],
    [ "réséda",[6.214840652236913, 49.23439761739025]],
    [ "réséda",[6.226065949999999, 49.227832899999996]],
    [ "réséda",[6.222834753360395, 49.224184383386664]],
    [ "réséda",[6.207566872121494, 49.22563420251122]],
    [ "réséda",[6.209124875481996, 49.22098820787642]],
    [ "réséda",[6.208577366276518, 49.21883784929567]],
    [ "Enedis",[4.420963671128395, 45.44487092444634]],
    [ "Enedis",[3.8456207599318897, 49.26008817481356]],
    [ "réséda",[6.580969776531408, 49.065699554955415]],
    [ "réséda",[6.572297349999999, 49.062506]],
    [ "réséda",[6.585289754533295, 49.062885220224956]],
    [ "réséda",[6.58220685, 49.0605058]],
    [ "réséda",[6.585315691054853, 49.05132423578908]],
    [ "réséda",[6.5778047194774425, 49.051132821247336]],
    [ "réséda",[6.573676954391667, 49.04967348297858]],
    [ "réséda",[6.395398490562281, 49.13550592583558]],
    [ "réséda",[6.390651600000001, 49.113228400000004]],
    [ "réséda",[6.395708264386179, 49.1064007159233]],
    [ "réséda",[6.403715816021488, 49.10546133164129]],
    [ "réséda",[6.4105880346548085, 49.10608768271291]],
    [ "réséda",[6.406863874778158, 49.11022695229325]],
    [ "réséda",[6.414290608046895, 49.10594427841652]],
    [ "réséda",[6.07759975, 49.082089249999996]],
    [ "réséda",[6.077046431043294, 49.079941714035854]],
    [ "réséda",[6.086542374415743, 49.07585081488315]],
    [ "réséda",[6.079061591004101, 49.074565465429735]],
    [ "réséda",[6.07387215, 49.07608935]],
    [ "réséda",[6.07583365, 49.07445385]],
    [ "réséda",[6.065373999999999, 49.07743935]],
    [ "Enedis",[3.910571978667091, 49.241328125784754]],
    [ "Enedis",[3.91332064979001, 49.23623091667414]],
    [ "Enedis",[3.9117303999999997, 49.23326380000001]],
    [ "Enedis",[3.89238555, 49.24252835]],
    [ "Enedis",[3.9190122, 49.2429166]],
    [ "Enedis",[3.923940449999998, 49.24442415]],
    [ "ZAC Nord",[3.9206017, 49.24427935]],
    [ "Gymnase",[3.9162636500000003, 49.249449399999996]],
    [ "réséda",[6.12268372898859, 49.07333686093119]],
    [ "réséda",[6.1134661, 49.072069500000005]],
    [ "réséda",[6.133823749999999, 49.07481025]],
    [ "réséda",[6.13176105, 49.0668623]],
    [ "réséda",[6.1227066500000005, 49.058069249999996]],
    [ "réséda",[6.1020828, 49.10846574999999]],
    [ "réséda",[6.103645441134889, 49.10702531975705]],
    [ "réséda",[6.109350887017021, 49.10282212182569]],
    [ "réséda",[6.0980607000000004, 49.10179775000001]],
    [ "réséda",[6.106054783996637, 49.10091330548332]],
    [ "réséda",[6.107516550000001, 49.0963116]],
    [ "réséda",[6.1054144500000005, 49.098424]],
    [ "réséda",[6.10200435, 49.08047640000001]],
    [ "réséda",[6.113099594228106, 49.07821491642993]],
    [ "réséda",[6.1251807, 49.08004495]],
    [ "réséda",[6.120420729888065, 49.08832308828609]],
    [ "réséda",[6.1245491, 49.08809255]],
    [ "réséda",[6.124531213012083, 49.09224623341894]],
    [ "réséda",[6.142339849999999, 49.08854039999999]],
    [ "Enedis",[5.352484449999999, 43.4500529]],
    [ "Enedis",[5.350859799999999, 43.452085350000004]],
    [ "Enedis",[3.860962434005803, 49.25918295271937]],
    [ "Enedis",[3.866146231853368, 49.275044122185236]],
    [ "Enedis",[3.8817338500000003, 49.2729722]],
    [ "ACM Stade",[3.8817546373684992, 49.27299471958854]],
    [ "Enedis",[0.5853021617627563, 45.871297030404854]],
    [ "Enedis",[0.8118853531402259, 46.102230100083304]],
    [ "Enedis",[0.7953490977418107, 46.097632699856085]],
    [ "Enedis",[0.8106050278200454, 46.101405357065744]],
    [ "Enedis",[3.8869226741075678, 49.2759162034889]],
    [ "Enedis",[3.907879000629207, 49.27277698313088]],
    [ "Enedis",[3.9074886567303726, 49.275250384479996]],
    [ "Enedis",[3.9125270218399795, 49.27268969616889]],
    [ "Enedis",[3.9105888500000003, 49.269241550000004]],
    [ "Enedis",[3.4145554319266758, 49.052720345758054]],
    [ "Enedis",[3.4176798, 49.0536474]],
    [ "Enedis",[3.9166461718904375, 49.290471396827044]],
    [ "Enedis",[3.8931016189943075, 49.294583848040766]],
    [ "Dussarat",[-0.39902624999999925, 43.30344399999999]],
    [ "Enedis",[0.8015991943447713, 46.09448645654452]],
    [ "Enedis",[0.768988280350731, 46.12977854269944]],
    [ "Enedis",[0.7803497148541145, 46.11886771755332]],
    [ "Enedis",[0.789342770035594, 46.105283398579886]],
    [ "Enedis",[0.7891522020183691, 46.087064799558696]],
    [ "Enedis",[0.8628053463201191, 46.12840997995783]],
    [ "Enedis",[0.8548526217954676, 46.123992536478795]],
    [ "Enedis",[0.8350933695422176, 46.127279976074554]],
    [ "Enedis",[0.8679846843001371, 46.13277610638886]],
    [ "Enedis",[-4.23595585, 48.457359350000004]],
    [ "Enedis",[6.199298845333565, 48.66300527935045]],
    [ "Enedis",[6.4688197, 46.35225220000001]],
    [ "Enedis",[3.990939642069801, 49.27505687285698]],
    [ "Enedis",[-4.2739319, 48.46334165]],
    [ "Enedis",[3.9933549, 49.27319195]],
    [ "Enedis",[3.993511028708398, 49.27056439033264]],
    [ "Enedis",[3.9961643499999986, 49.2672401]],
    [ "Enedis",[3.9973793500000006, 49.2635573]],
    [ "Enedis",[6.035464029076886, 45.474066707865184]],
    [ "Enedis",[6.032120065414496, 45.473432547657026]],
    [ "Enedis",[3.9985347499999997, 49.25887455]],
    [ "Enedis",[3.98779495, 49.27724375000001]],
    [ "Enedis",[3.9938855990110693, 49.28101728288512]],
    [ "Enedis",[3.9930798500000004, 49.287893950000004]],
    [ "Enedis",[3.9825210237618944, 49.28210810707455]],
    [ "Enedis",[4.000656673960006, 49.27691535718216]],
    [ "Enedis",[3.9999734277741945, 49.27818044838937]],
    [ "Enedis",[3.9983393155640554, 49.28210105273567]],
    [ "Enedis",[3.998415791582729, 49.28205896286591]],
    [ "Enedis",[1.0894835996062289, 46.14772039379052]],
    [ "Enedis",[7.326402283249843, 48.24416005021657]],
    [ "Enedis",[1.8899145500000003, 49.27011799999999]],
    [ "réséda",[6.594090600000001, 49.068230400000004]],
    [ "réséda",[6.5892722500000005, 49.064298400000006]],
    [ "réséda",[6.594817349999999, 49.061706799999996]],
    [ "réséda",[6.591679639730449, 49.05784579629694]],
    [ "réséda",[6.595685006954052, 49.052102678029094]],
    [ "réséda",[6.592742579565594, 49.04562152148481]],
    [ "réséda",[6.604232777615701, 49.05612360834465]],
    [ "réséda",[6.615800477932059, 49.052718836270664]],
    [ "réséda",[6.604639478507417, 49.0478953420978]],
    [ "réséda",[6.600001199999999, 49.03829765]],
    [ "Enedis",[1.090391420466898, 45.870717100208395]],
    [ "Enedis",[2.9720444319956156, 50.41930825654525]],
    [ "Riberpray",[6.17255655158567, 49.12633104626806]],
    [ "réséda",[6.143516360496331, 49.05798661278262]],
    [ "réséda",[6.1593104, 49.059796]],
    [ "réséda",[6.156043775786934, 49.05712096541784]],
    [ "réséda",[6.159014308348182, 49.06648647763019]],
    [ "réséda",[6.167993106914818, 49.069372365400895]],
    [ "réséda",[6.14585378310515, 49.05992474977308]],
    [ "réséda",[6.13761320163725, 49.062478770921174]],
    [ "réséda",[6.140160849113758, 49.06589811699566]],
    [ "réséda",[6.143257581792717, 49.06695759859945]],
    [ "réséda",[6.145034783103209, 49.08002070525228]],
    [ "Enedis",[4.05042565, 49.28999205]],
    [ "Enedis",[4.048360349999999, 49.289256550000005]],
    [ "Enedis",[4.0511505, 49.28874289999999]],
    [ "Enedis",[3.2660087500000006, 48.9945628]],
    [ "Enedis",[0.9830675274482789, 45.953158306202376]],
    [ "Enedis",[0.9942539758721161, 45.944261907204385]],
    [ "Enedis",[0.9860799058423135, 45.95203374965023]],
    [ "Ferdinand Hamelin",[4.046506799999999, 49.27271435]],
    [ "réséda",[6.172184900000001, 49.0829091]],
    [ "réséda",[6.1585347, 49.08412465]],
    [ "réséda",[6.172260799999999, 49.087814050000006]],
    [ "réséda",[6.1741864500000005, 49.089287]],
    [ "réséda",[6.157903200000001, 49.08715710000001]],
    [ "réséda",[6.1410239, 49.0943929]],
    [ "réséda",[6.136633999999999, 49.098589749999995]],
    [ "réséda",[6.152024957694899, 49.18104352656011]],
    [ "réséda",[6.1530708, 49.175881800000006]],
    [ "réséda",[6.1618580418959095, 49.17604352496315]],
    [ "réséda",[6.153056882976859, 49.172291933626894]],
    [ "réséda",[6.160468499999999, 49.163154299999995]],
    [ "réséda",[6.167568927608482, 49.15792989690214]],
    [ "réséda",[6.15834525, 49.15568449999999]],
    [ "réséda",[6.147598, 49.15883255]],
    [ "réséda",[6.144474049999999, 49.15786045]],
    [ "réséda",[6.138716768034405, 49.156461266257566]],
    [ "réséda",[6.15088845, 49.154980249999994]],
    [ "réséda",[6.15374815, 49.14724485000001]],
    [ "réséda",[6.15964715, 49.1531326]],
    [ "réséda",[6.165533623556, 49.14850290274835]],
    [ "réséda",[6.166420836219851, 49.14595948572123]],
    [ "Plage",[5.18027373676912, 45.618526782327706]],
    [ "Clinique",[5.181574265414249, 45.61820007927617]],
    [ "Galet",[5.1815514500000015, 45.61797035]],
    [ "Mafelec",[5.013496420478732, 45.704121721660464]],
    [ "Enedis",[5.34111133249687, 43.464205780232774]],
    [ "réséda",[6.1907415341804795, 49.13047514695797]],
    [ "Les Floralies",[5.973048398373778, 45.903705168106875]],
    [ "réséda",[6.18004965, 49.136050950000005]],
    [ "Enedis",[4.090369156998419, 49.99816317438384]],
    [ "Enedis",[4.095611525586158, 49.99903579437951]],
    [ "Enedis",[4.097318701461516, 50.00191978369331]],
    [ "réséda",[6.243961357308148, 49.11196992546302]],
    [ "réséda",[6.24347850807633, 49.10989762536281]],
    [ "réséda",[6.243795759576888, 49.104975526044726]],
    [ "réséda",[6.237413759160306, 49.0901151614479]],
    [ "réséda",[6.235806197255601, 49.08936218202222]],
    [ "réséda",[6.236657749999999, 49.08909979999999]],
    [ "réséda",[6.237904400831198, 49.08853686715466]],
    [ "réséda",[6.2382452, 49.088749549999996]],
    [ "réséda",[6.237142399999998, 49.08737145]],
    [ "réséda",[6.234883575584525, 49.08787952091727]],
    [ "réséda",[6.2343763718203125, 49.08841333011643]],
    [ "réséda",[6.2343723, 49.08702189999999]],
    [ "réséda",[6.2338296, 49.08715365]],
    [ "réséda",[6.231005700000002, 49.106202950000004]],
    [ "réséda",[6.2256476, 49.10239274999999]],
    [ "réséda",[6.2260598499999995, 49.118634]],
    [ "réséda",[6.2177054, 49.1198934]],
    [ "réséda",[6.210607966032461, 49.11093904853675]],
    [ "réséda",[6.206083190767639, 49.10572017715431]],
    [ "réséda",[6.2083771214623695, 49.12977797564312]],
    [ "réséda",[6.210201679774096, 49.128685302308696]],
    [ "réséda",[6.224553398143377, 49.124138467180714]],
    [ "réséda",[6.2128013200672, 49.122510476377606]],
    [ "réséda",[6.201225171799323, 49.120778168832054]],
    [ "réséda",[6.190298171430775, 49.126295729011154]],
    [ "réséda",[6.1870179, 49.0956681]],
    [ "réséda",[6.197844849999999, 49.10536919999999]],
    [ "réséda",[6.190673899999999, 49.106511749999996]],
    [ "réséda",[6.197055407486885, 49.112376893607205]],
    [ "Hinzelin",[6.18205785, 49.09741035]],
    [ "réséda",[6.1687728145568155, 49.10741749164912]],
    [ "réséda",[6.173159277432943, 49.14475904792956]],
    [ "réséda",[6.176099656132224, 49.14276662742136]],
    [ "réséda",[6.179137949999999, 49.14050575]],
    [ "réséda",[6.170369214074057, 49.135264478652324]],
    [ "réséda",[6.14844411791491, 49.138214770074946]],
    [ "réséda",[6.15647075, 49.13180835000001]],
    [ "Route de Lorry",[6.153629885946201, 49.132911594074365]],
    [ "réséda",[6.210231070210098, 49.087632953679716]],
    [ "Enedis",[3.239732880132909, 49.07093535293647]],
    [ "Yves Guillou",[-0.38600689999999865, 49.1717082]],
    [ "1263920343",[5.914809187453078, 48.6930848476503]],
    [ "Enedis",[1.301321806219752, 46.05789238282662]],
    [ "Enedis",[1.3099776190672574, 46.06865264776171]],
    [ "Enedis",[1.2380962689092228, 45.90631659970373]],
    [ "Enedis",[1.2293061131952645, 45.901091369238124]],
    [ "Enedis",[-1.4506875000000001, 48.824366600000005]],
    [ "Enedis",[-1.4579032738823863, 48.83269990598027]],
    [ "Enedis",[-1.4655465192982453, 48.81757665]],
    [ "Enedis",[-1.462300586494737, 48.82168897023638]],
    [ "Enedis",[-1.4650182707711432, 48.822915006475085]],
    [ "Enedis",[-1.4624249730708416, 48.82685844402125]],
    [ "Enedis",[-1.4646534578272354, 48.82931287865606]],
    [ "Enedis",[-1.4605668100348934, 48.84793490108883]],
    [ "Enedis",[-1.4905943420179584, 48.842109872181105]],
    [ "Enedis",[-1.4981634423880927, 48.84442447986741]],
    [ "Enedis",[-1.468348555148609, 48.82891997279983]],
    [ "Louis Barran",[5.606531153863417, 45.31693446557897]],
    [ "Enedis",[3.971146900000001, 49.221622950000004]],
    [ "Enedis",[3.9654512, 49.21818795]],
    [ "Enedis",[4.032583618397454, 49.235838678960704]],
    [ "Araymond",[5.61254917551542, 45.31185566692235]],
    [ "Enedis",[3.962140642288921, 49.26236578431747]],
    [ "Enedis",[3.962590915251163, 49.2622014186954]],
    [ "Enedis",[3.963692350000001, 49.26301709999999]],
    [ "Enedis",[3.964030633487839, 49.26378634669531]],
    [ "Enedis",[3.965808264427752, 49.264368065961015]],
    [ "Bouillon",[5.86633982490105, 48.76630645407562]],
    [ "Enedis",[3.955850766294227, 49.25393688901304]],
    [ "Enedis",[3.9549075057720136, 49.25162503060547]],
    [ "Enedis",[3.2433282, 50.1747992]],
    [ "Enedis",[3.4427583412348794, 50.06107188287884]],
    [ "Enedis",[3.44279655, 50.0610525]],
    [ "Enedis",[3.442844232896752, 50.06103070080148]],
    [ "Enedis",[3.4428923499999997, 50.06101459999999]],
    [ "Enedis",[3.5020110362967753, 49.846680270896414]],
    [ "Rue de la Résistance",[-2.7635172437695954, 48.46693917943327]],
    [ "Enedis",[3.4544990552658215, 49.808259633152474]],
    [ "Enedis",[6.331227098671586, 49.315225167217584]],
    [ "Marots",[4.045060508634706, 48.3075643845054]],
    [ "Sarrail 130",[4.02995035, 48.30333280000001]],
    [ "Enedis",[4.049374389658516, 48.27699131344039]],
    [ "Enedis",[6.730514926961248, 48.324560651982594]],
    [ "Enedis",[6.727392244092486, 48.32477019144499]],
    [ "Enedis",[6.655297149999999, 48.33908385]],
    [ "Enedis",[4.086650699999999, 48.2795271]],
    [ "réséda",[6.1188387868120335, 49.07498367845295]],
    [ "réséda",[6.177145200000001, 48.996791449999996]],
    [ "réséda",[6.227361749999998, 49.1283963]],
    [ "réséda",[6.487001449999999, 49.0944878]],
    [ "réséda",[6.31181385, 49.00523300000001]],
    [ "réséda",[6.379819752819205, 49.020203018826464]],
    [ "réséda",[6.214402170628238, 48.97102817750594]],
    [ "réséda",[6.279419182224599, 49.01616753405053]],
    [ "réséda",[6.339242000000001, 48.9356132]],
    [ "réséda",[6.2364224637645895, 49.21088417580106]],
    [ "réséda",[6.541943588686181, 49.02495488394822]],
    [ "réséda",[6.428873708718091, 49.02047778523621]],
    [ "réséda",[6.422845214258371, 49.02141517535893]],
    [ "réséda",[6.4370293499999995, 48.99058345]],
    [ "réséda",[6.477780999999999, 49.10477849999999]],
    [ "réséda",[6.3432560838323, 48.93301880361069]],
    [ "Enedis",[-1.4448731512725739, 48.84522222187745]],
    [ "Enedis",[1.7999929326891442, 49.43165177201881]],
    [ "Enedis",[0.04367253623501762, 47.99864601689909]],
    [ "Enedis",[4.01784185, 49.21947355000001]],
    [ "Enedis",[2.960465128900466, 50.6073464203564]],
    [ "Enedis",[5.9661367734765856, 47.22573680372851]],
    [ "Poste électrique des Mandarins",[1.7840336154154715, 50.90230796140674]],
    [ "Station de conversion des Mandarins-HVDC",[1.7820027814719632, 50.90345900070302]],
    [ "Poste électrique des Mandarins",[1.784675944182794, 50.904029043237934]],
    [ "Poste électrique des Mandarins",[1.7869375991979621, 50.90424555865423]],
    [ "Enedis",[4.09912195, 49.18804269999999]],
    [ "Enedis",[4.107537150000001, 49.1859375]],
    [ "Enedis",[4.049026729412513, 50.16827107090665]],
    [ "1271553646",[2.229980578772799, 48.71467254869354]],
    [ "Enedis",[4.137482631519252, 49.1960789013564]],
    [ "Enedis",[4.143759074361307, 49.2037166669626]],
    [ "Enedis",[4.119055935714618, 49.219336996737965]],
    [ "Enedis",[4.09462105, 49.226033550000004]],
    [ "Enedis",[4.09636105, 49.15618215]],
    [ "eglise",[4.079435879959041, 49.152340408146934]],
    [ "Enedis",[3.601604032916192, 49.55754475040093]],
    [ "Enedis",[4.68936235, 45.58517075000001]],
    [ "Enedis",[-0.30435325, 49.1330951]],
    [ "Enedis",[5.239291135376539, 48.971753460120674]],
    [ "Enedis",[-1.4646791502335672, 48.80875803780551]],
    [ "Enedis",[2.9914216499999995, 49.586749499999996]],
    [ "Enedis",[0.70731095, 47.34664235]],
    [ "Enedis",[3.6013971158533264, 45.889671024862686]],
    [ "SNCF Réseau",[-1.6571080863745986, 43.386867700171315]],
    [ "grosse pierre",[3.670150583803406, 47.78851159596783]],
    [ "Enedis",[3.233428656458834, 50.180915983223116]],
    [ "Enedis",[1.7829626068809885, 47.68404208236151]],
    [ "Enedis",[5.012602800667138, 47.2730754994301]],
    [ "Enedis",[5.011272854561167, 47.27264500591851]],
    [ "Enedis",[5.011948101743397, 47.27217277499322]],
    [ "Enedis",[5.010672358046501, 47.27079885673413]],
    [ "Malassoire",[6.173116449999998, 46.03057039999999]],
    [ "Enedis",[6.7702302795320675, 45.616193002494306]],
    [ "Poste électrique de Fruges",[2.095092826436344, 50.49953090167145]],
    [ "Enedis",[4.623175515441354, 44.38368489444731]],
    [ "Enedis",[1.857345225503066, 47.93110631155897]],
    [ "Sturel",[6.178310472462603, 49.10277040740356]],
    [ "Enedis",[3.0208196499999995, 49.58097425000001]],
    [ "Enedis",[3.02254915, 49.578068900000005]],
    [ "Enedis",[4.980444757296672, 43.4653878275794]],
    [ "Enedis",[4.98044021735193, 43.465403028409135]],
    [ "Enedis",[-1.7461711060042568, 48.1145380827298]],
    [ "Enedis",[5.045891500000001, 43.78655309999999]],
    [ "Enedis",[3.0918006, 49.753592250000004]],
    [ "Enedis",[5.047200916403231, 43.78285256858939]],
    [ "Enedis",[2.2613461500000014, 49.12333225]],
    [ "Enedis",[-4.659897569551817, 48.02946377083224]],
    [ "Enedis",[4.8730105, 45.5358667]],
    [ "Chornaix",[6.550600982276891, 45.66364324466291]],
    [ "Enedis",[5.038130981533809, 43.78677665328148]],
    [ "Enedis",[0.30736919954532266, 44.930839570774886]],
    [ "Enedis",[-0.3070106147717372, 49.137060871751196]],
    [ "Enedis",[-0.30711409999999945, 49.140202349999996]],
    [ "Enedis",[6.118963867198908, 45.91104384204915]],
    [ "Enedis",[-0.27047873195064986, 49.08142470174952]],
    [ "Enedis",[-0.2817551332993129, 49.08515371530824]],
    [ "Enedis",[-0.26007254568087645, 49.09077786535111]],
    [ "Enedis",[-0.2582317, 49.0873928]],
    [ "Enedis",[-0.2981879, 49.07926135]],
    [ "Enedis",[-0.29580678210987693, 49.07627375262777]],
    [ "Enedis",[-0.30285907804083795, 49.07219056501826]],
    [ "Enedis",[-0.30039242941207406, 49.072468695141055]],
    [ "Enedis",[-0.26732724999999935, 49.1065521]],
    [ "Enedis",[-0.2696366569481355, 49.10307301780957]],
    [ "Enedis",[-0.26936858889466425, 49.102330881868575]],
    [ "Enedis",[-0.26373710000000006, 49.10341265]],
    [ "Enedis",[-0.26975118827337197, 49.09857271496508]],
    [ "Enedis",[-0.27519490000000085, 49.10326750000001]],
    [ "Enedis",[-0.2837976718720763, 49.10141651496981]],
    [ "Enedis",[-0.2849344264110475, 49.09950614438959]],
    [ "Enedis",[-0.2896494499999999, 49.100058199999985]],
    [ "Enedis",[-0.28907289999999985, 49.097333150000004]],
    [ "SRD",[0.12830888959909398, 46.42747935514556]],
    [ "wikidata=Q3587594",[2.8717401660469, 43.30922453372327]],
    [ "Enedis",[3.1241553609278534, 50.054939382539466]],
    [ "Enedis",[3.13306214571454, 50.05800410846447]],
    [ "Enedis",[1.3727652724132202, 49.37985821643474]],
    [ "Enedis",[-0.30708819246986874, 49.14139257322231]],
    [ "Enedis",[-0.3042831567922636, 49.11237023342923]],
    [ "Enedis",[-0.3097430662555206, 49.111636298793094]],
    [ "SICAE-Somme",[3.0814918574690626, 50.026995474601286]],
    [ "SICAE-Somme",[3.077532270108143, 50.02800989769117]],
    [ "Enedis",[5.2629345999999995, 49.58830785]],
    [ "Enedis",[-4.378303382503025, 48.36670825656414]],
    [ "La Guièrche",[-0.7959017935901465, 46.866208395987684]],
    [ "Enedis",[-0.30845501719555196, 49.12395915061026]],
    [ "Enedis",[-0.30866243292262563, 49.1240211993326]],
    [ "Enedis",[-0.30828719999999915, 49.12535865]],
    [ "Enedis",[-0.30486743412213235, 49.13120520296589]],
    [ "Enedis",[-0.30710725, 49.13535610000001]],
    [ "Enedis",[4.739854874610976, 47.42881016114905]],
    [ "Enedis",[4.723243519633208, 47.4341355893851]],
    [ "Gérédis",[-0.7990350433043373, 46.866568391364474]],
    [ "Gérédis",[-0.7902333895987141, 46.865313645102304]],
    [ "Gérédis",[-0.7927936442996665, 46.871089598910764]],
    [ "Gérédis",[-0.7918646002803416, 46.87489567697195]],
    [ "Enedis",[6.442942917359136, 48.1864711258581]],
    [ "Gérédis",[-0.7983519969812412, 46.87303714793478]],
    [ "Enedis",[3.2148548499999996, 50.012497249999996]],
    [ "Enedis",[4.760048362832343, 45.22651794170248]],
    [ "Schmidhauser",[6.238969924076273, 45.89424494627379]],
    [ "ZA Vernays",[6.231269965746288, 45.894425053998525]],
    [ "Les Curtils Hauts",[6.229636095581588, 45.92288431188768]],
    [ "Curtil bas",[6.22488275, 45.918960049999995]],
    [ "Enedis",[3.1330509500000003, 50.5175515]],
    [ "Enedis",[3.1147403000000002, 50.5219152]],
    [ "Enedis",[2.9530762168975726, 50.49192384977174]],
    [ "Enedis",[4.652944767669616, 44.41457644671255]],
    [ "Enedis",[-0.31286512483298495, 49.128590824495234]],
    [ "Enedis",[-0.3154555577675327, 49.12883381607991]],
    [ "Enedis",[-0.31845334999999997, 49.131635249999995]],
    [ "Enedis",[-0.3254952321394173, 49.13484765396645]],
    [ "Enedis",[-0.3235740001097327, 49.13435831665398]],
    [ "Enedis",[6.108479850874079, 48.93013352776472]],
    [ "Enedis",[2.95953365, 50.499207100000014]],
    [ "Enedis",[2.9650953559169126, 50.487772715442816]],
    [ "Enedis",[2.9498951042595003, 50.50587835699041]],
    [ "Enedis",[2.9910103035049995, 50.518836421431395]],
    [ "Enedis",[2.957286294065447, 50.484913645535215]],
    [ "Guette",[2.900556936887014, 48.381801321779236]],
    [ "Gérédis",[-0.794856833333334, 46.86387936526946]],
    [ "Enedis",[-0.3007030499999994, 49.11584604999999]],
    [ "Enedis",[-0.27397594999999997, 49.11467679999999]],
    [ "Enedis",[4.635881400000001, 44.38025890000001]],
    [ "Enedis",[3.9660754507629647, 47.86467903327022]],
    [ "Enedis",[3.972728390551911, 47.86185817427528]],
    [ "Enedis",[3.9761209000000006, 47.867153]],
    [ "Enedis",[3.9858650000000004, 47.8478706]],
    [ "Enedis",[3.9854398499999997, 47.85544939999999]],
    [ "Enedis",[4.260263999999999, 47.64703104999998]],
    [ "Enedis",[-0.23177085133879047, 49.10836796621091]],
    [ "Enedis",[-0.25125542380685484, 49.116095846286015]],
    [ "Enedis",[-0.2503948761899165, 49.11618850372938]],
    [ "Enedis",[-0.24133525, 49.1168902]],
    [ "Enedis",[4.920708665449187, 44.27980436881123]],
    [ "Caps",[6.387270516223095, 45.67522489624269]],
    [ "Enedis",[-0.29123505, 49.1425783]],
    [ "Enedis",[-0.2912468999999999, 49.14256275]],
    [ "Enedis",[-0.2862439, 49.15589275000001]],
    [ "La Plantée 2",[6.306240932204176, 46.23379505675925]],
    [ "Gérédis",[-0.7875680571132517, 46.87222059411504]],
    [ "Enedis",[-0.2890901706557721, 49.135809054128266]],
    [ "Enedis",[-0.28797395000000003, 49.13411765]],
    [ "Enedis",[3.508982434041202, 49.1997426938754]],
    [ "Enedis",[3.516553189903323, 49.20213466710265]],
    [ "Enedis",[3.6194624101167885, 49.2629648317701]],
    [ "Richelandière 21",[4.400878114065522, 45.43888251487919]],
    [ "Enedis",[0.20246706722668162, 48.028569222381925]],
    [ "Enedis",[6.607689210661173, 48.077043226133846]],
    [ "Enedis",[0.21186988081409364, 48.00701647212473]],
    [ "Enedis",[-0.3335387999999988, 49.11061045]],
    [ "Enedis",[-0.33585831128182336, 49.13063708136176]],
    [ "Enedis",[-0.31494114821303937, 49.08851109904844]],
    [ "Enedis",[-0.32969265, 49.0926273]],
    [ "Enedis",[-4.329760127439857, 48.57754529062168]],
    [ "Enedis",[2.7365224939272754, 43.32147542647804]],
    [ "Enedis",[4.379917089687425, 45.436666181842625]],
    [ "1292596182",[-1.6552064696430133, 49.635164530381616]],
    [ "Enedis",[6.660280899999999, 46.15199935]],
    [ "Enedis",[6.66057850177981, 46.151858533530365]],
    [ "Enedis",[4.775833549999999, 45.758924449999995]],
    [ "Guynemer",[-0.35510091665605625, 49.32043409854103]],
    [ "Enedis",[-0.3916467625033051, 49.183913861300205]],
    [ "Enedis",[3.5549823440255, 49.14661062891655]],
    [ "Enedis",[4.958907772162125, 44.85749451072482]],
    [ "Redon",[6.406820084151025, 46.34997634552792]],
    [ "Enedis",[4.977535523397826, 44.83051699349675]],
    [ "Armoire Haute Tension",[4.0501564529001, 45.47377025651988]],
    [ "Enedis",[6.43142995, 46.350345600000004]],
    [ "Enedis",[-1.7443687914904793, 48.11418484530194]],
    [ "Enedis",[-1.7436445499999997, 48.112531049999994]],
    [ "Enedis",[4.148744101282591, 45.479587699176136]],
    [ "Le Suchet",[4.067107818228346, 45.52818419370574]],
    [ "Le Pont",[4.051108805408163, 45.5113542607653]],
    [ "Enedis",[4.096059349999999, 45.56058755]],
    [ "Enedis",[4.656178993740572, 45.2743687979638]],
    [ "Enedis",[2.7742439469963953, 49.70991762753624]],
    [ "Enedis",[-0.5218772, 44.912150999999994]],
    [ "Enedis",[4.835734091639125, 47.32939338193759]],
    [ "Enedis",[5.567289731956735, 43.36351124621436]],
    [ "Enedis",[6.0722937625990685, 43.30188034847893]],
    [ "Enedis",[-1.740440359753101, 48.113006160369856]],
    [ "Enedis",[3.0530755499999986, 50.54890545]],
    [ "Enedis",[3.0531951562692123, 50.54890151468811]],
    [ "Enedis",[1.3967682407859112, 43.5021446830861]],
    [ "Enedis",[4.510544799515145, 45.27672841371927]],
    [ "Enedis",[3.0691846220769166, 47.2641921543434]],
    [ "Enedis",[-1.4789843000000003, 49.120854800000004]],
    [ "Enedis",[4.370120625337822, 45.44102314637649]],
    [ "Enedis",[3.0581252999999995, 47.2730556]],
    [ "Enedis",[0.21725089999999994, 47.93684765000001]],
    [ "Enedis",[0.22163159999999998, 47.9376911]],
    [ "Enedis",[0.2254410000000013, 47.93866955]],
    [ "Enedis",[2.219718659839191, 48.91822156134723]],
    [ "Robes d'Airain",[-0.3861660562961384, 49.18113042833393]],
    [ "Enedis",[-0.2919797, 49.065070950000006]],
    [ "Enedis",[-0.2951836999999999, 49.06483954999999]],
    [ "Enedis",[-0.2944543427629672, 49.056063167203725]],
    [ "Enedis",[-0.29004995, 49.05430294999999]],
    [ "Enedis",[-0.2909318, 49.05698695]],
    [ "Dunière",[4.657542650000001, 44.821796799999994]],
    [ "Enedis",[4.982208153629635, 44.8482714158547]],
    [ "Enedis",[1.7356326556716635, 47.83428274983397]],
    [ "Gedia",[1.363288385287836, 48.74814343401311]],
    [ "Enedis",[4.98050455, 44.85142355]],
    [ "réséda",[6.124808531036328, 49.10940204866486]],
    [ "Enedis",[1.4996562499999995, 44.28201995]],
    [ "Enedis",[5.065909404026157, 47.521429254039674]],
    [ "Poste électrique de Cherbourg",[-1.624976369583092, 49.633576515266725]],
    [ "Enedis",[1.8839359375917106, 48.003445425923644]],
    [ "Enedis",[5.2380936940992715, 47.005693005137104]],
    [ "Enedis",[5.257484940769227, 47.014046528957834]],
    [ "Enedis",[6.09199905, 45.91581325]],
    [ "Le Tolede",[6.088838802248987, 45.916675883455305]],
    [ "Enedis",[5.39375811522109, 49.12200114735629]],
    [ "Enedis",[5.7426926, 47.97378055]],
    [ "1304066694",[5.400133286781581, 49.12811047682228]],
    [ "Enedis",[6.351674076605473, 43.48814154619986]],
    [ "Enedis",[6.348671462105178, 43.48671723253268]],
    [ "Enedis",[4.58350092273475, 45.29638797438318]],
    [ "CH Arvan",[6.282607698026877, 45.195935256897876]],
    [ "Enedis",[1.8994841626702543, 49.45139862165222]],
    [ "Enedis",[5.614116449999999, 43.38515375]],
    [ "Enedis",[3.086453409967336, 50.74360308279585]],
    [ "Enedis",[3.0853909012572367, 50.74604236703417]],
    [ "Enedis",[3.081538437290069, 50.737499913740656]],
    [ "Enedis",[3.0838783114885238, 50.733720787736345]],
    [ "Enedis",[3.0800838455965147, 50.73536953341049]],
    [ "Enedis",[3.0457026545402317, 50.77475446559367]],
    [ "Enedis",[5.6088626999999995, 43.40416065]],
    [ "Enedis",[0.5917115404231255, 45.88703215083357]],
    [ "Enedis",[0.5976117955374419, 45.8859593193554]],
    [ "Poste électrique de Tourlaville",[-1.582431456604547, 49.630209261385545]],
    [ "Enedis",[3.1005094, 46.72193825]],
    [ "Enedis",[-1.6523675576920895, 49.652703816828456]],
    [ "Enedis",[1.44891215, 42.716265349999986]],
    [ "Poste électrique Z.I. Paraicabo Kourou",[-52.67058187777124, 5.16318563322377]],
    [ "Enedis",[4.409539119502796, 45.41644814820203]],
    [ "Poste électrique de Saint-Pierre",[55.48207243512488, -21.33254783813939]],
    [ "Poste électrique de La Vallée",[55.45086064169564, -21.313809104611536]],
    [ "Poste électrique de Montvert",[55.51763345629688, -21.341624180872007]],
    [ "Enedis",[1.4182396602419571, 48.75206932787096]],
    [ "Poste électrique de Saint-Paul",[55.29972264870467, -20.987512696535898]],
    [ "Laurina",[6.24034895, 46.199021]],
    [ "Poste électrique du Gol",[55.39619687884541, -21.279234373769494]],
    [ "réséda",[6.099827249999999, 49.075912200000005]],
    [ "Poste électrique du Tampon",[55.51477667182847, -21.232713300928694]],
    [ "Poste électrique du Port",[55.2870799701752, -20.94634034405034]],
    [ "Poste électrique de Marquet",[55.328224376952576, -20.93137323119456]],
    [ "Électricité de France",[55.32830196654968, -20.931128941264117]],
    [ "Poste électrique de la Digue",[55.4429609553256, -20.88755284756766]],
    [ "Poste électrique de Moufia",[55.47660480866255, -20.917787595028795]],
    [ "Poste électrique des Dattiers",[55.46045212872906, -20.89948805355237]],
    [ "Enedis",[-4.323905168339082, 48.57001480794398]],
    [ "Poste électrique de Sainte-Marie",[55.53293736629174, -20.906230727393158]],
    [ "Poste électrique de Rivière de l'Est",[55.7876017813902, -21.126502081032342]],
    [ "Poste électrique de Sainte-Rose",[55.7877849674047, -21.127037774421865]],
    [ "Enedis",[3.049487924041059, 50.373078609292286]],
    [ "Poste électrique de l'avenue du Carrefour de Mana",[-53.54734869854731, 5.504947998570255]],
    [ "Électricité de France",[55.385420999999994, -21.0696355]],
    [ "Électricité de France",[55.35554624815347, -21.053365783255224]],
    [ "Électricité de France",[55.32383725970303, -21.04235687788836]],
    [ "Électricité de France",[55.30424560000001, -21.014656900000002]],
    [ "Enedis",[2.257333871667026, 49.628181358927705]],
    [ "Enedis",[-4.519099144990777, 48.39462928332255]],
    [ "Enedis",[-4.5137383697576965, 48.41077029584981]],
    [ "Enedis",[-4.495091805765785, 48.417316947387654]],
    [ "Enedis",[-4.491581690240441, 48.41260885228291]],
    [ "Enedis",[-4.46958256755792, 48.41850031576882]],
    [ "Enedis",[-4.46971773650686, 48.41699354139224]],
    [ "Enedis",[-4.461511208592717, 48.41837691453783]],
    [ "Enedis",[-4.428535561573388, 48.44084842822513]],
    [ "Enedis",[-4.428416231159166, 48.440139620735735]],
    [ "Enedis",[-4.492800356053322, 48.54952500334079]],
    [ "Enedis",[-4.524798459767694, 48.39019659435691]],
    [ "Enedis",[-4.527465412593511, 48.386078096384594]],
    [ "Enedis",[2.816357, 46.99469594999999]],
    [ "Enedis",[2.8211030852790575, 46.99428793231993]],
    [ "Enedis",[2.8214088223973093, 46.99705543718947]],
    [ "Enedis",[5.634082730398686, 43.18897295786585]],
    [ "Enedis",[3.197136945471264, 43.27632359624379]],
    [ "Enedis",[3.2096115046527114, 43.27300218139082]],
    [ "Poste électrique d'Ocana",[8.9349219, 41.94557555]],
    [ "Enedis",[1.9474036848804641, 48.75570331793369]],
    [ "Enedis",[-1.7882297739079909, 46.494943264907604]],
    [ "SICAE-ELY",[1.4313638408750344, 48.745715623784605]],
    [ "Enedis",[5.61742484250096, 43.390943420329876]],
    [ "Enedis",[4.864037464708199, 45.53216562595491]],
    [ "cadierens",[5.6962057359279346, 43.168185879686476]],
    [ "Enedis",[5.60246625, 43.191570600000006]],
    [ "Enedis",[5.601509350000001, 43.190669449999994]],
    [ "Enedis",[5.7278930214974935, 43.26833960282359]],
    [ "Starck",[6.181122173451897, 49.10443192506311]],
    [ "Enedis",[5.596605320193643, 43.1829510950781]],
    [ "Enedis",[5.597351949999998, 43.18876709999999]],
    [ "Poste de transformation HTA/BT",[7.637816558595561, 48.95790544458867]],
    [ "Enedis",[4.869489235698884, 45.52884049908562]],
    [ "Enedis",[3.026272465059048, 43.1847850816788]],
    [ "Gare",[3.3234329954972277, 49.82274979717067]],
    [ "Enedis",[6.083609673761672, 45.92388494619029]],
    [ "Chez Diossaz",[6.153125752168236, 46.007569414835295]],
    [ "Chaumontet",[6.153098449999999, 46.0076025]],
    [ "Enedis",[5.560238978832233, 43.236866593556144]],
    [ "Enedis",[5.559069951888023, 43.237598067006346]],
    [ "Enedis",[6.38999455, 46.00326245000001]],
    [ "REThones",[6.41956979313021, 45.9148409270215]],
    [ "Pont de la douane",[6.404986058520644, 45.93089482538319]],
    [ "Enedis",[3.838742235826414, 49.35728116636317]],
    [ "Enedis",[-4.3302666963180565, 48.56639910097141]],
    [ "Enedis",[3.042530549999999, 50.6870206]],
    [ "Enedis",[3.0449636, 50.68849184999999]],
    [ "Enedis",[3.050682366535092, 50.68375005561824]],
    [ "Enedis",[3.1022510471222273, 50.686934701288955]],
    [ "Enedis",[6.7971669000000015, 45.5643506]],
    [ "Complexe sportif",[6.7151747, 45.88406535]],
    [ "Promery",[6.1158258, 45.95211895]],
    [ "Enedis",[3.230204567650864, 49.620749401158236]],
    [ "Enedis",[3.2401274499999984, 49.62411914999999]],
    [ "Enedis",[3.239643320091384, 49.62147625824009]],
    [ "Enedis",[3.2258889654320995, 49.62173405555557]],
    [ "Enedis",[3.2297537879098974, 49.628431546626146]],
    [ "Enedis",[6.387561699999999, 46.19306909999999]],
    [ "Enedis",[-0.32982647077769006, 49.14747300645275]],
    [ "1309832181",[1.4119968315855977, 48.76353614347026]],
    [ "Enedis",[5.556058734860899, 43.237079901817154]],
    [ "Enedis",[5.556172330548151, 43.237133291326025]],
    [ "Enedis",[5.557133085878962, 43.23746256666667]],
    [ "Enedis",[5.55426035, 43.23751345]],
    [ "Enedis",[5.555973933590812, 43.23905505497401]],
    [ "Enedis",[5.5618661454242595, 43.23842346910328]],
    [ "Enedis",[5.55332945, 43.233739199999995]],
    [ "Enedis",[5.551875166923603, 43.23508885076522]],
    [ "Enedis",[4.866034204720693, 45.52442887941253]],
    [ "Enedis",[4.402326900000001, 45.45746330000001]],
    [ "Enedis",[3.0514128268836624, 50.6878653038257]],
    [ "Enedis",[3.0548825, 50.67970909999999]],
    [ "Enedis",[3.054405560319371, 50.67962223195379]],
    [ "Enedis",[3.0549082500000004, 50.680314450000004]],
    [ "Enedis",[3.0531397581262154, 50.67798301518934]],
    [ "Enedis",[3.0548159508737576, 50.67736847310804]],
    [ "Enedis",[3.0563251804797646, 50.67596298347695]],
    [ "Enedis",[3.070681563410038, 50.674274970135315]],
    [ "Enedis",[3.0707484993115317, 50.67425647687686]],
    [ "Enedis",[3.070862839151531, 50.67423325327622]],
    [ "Enedis",[4.86391275, 45.52445685]],
    [ "Resto Arpette",[6.797992711611056, 45.577996126395426]],
    [ "Enedis",[6.795773068387847, 45.57460269608516]],
    [ "Enedis",[6.764720792996559, 45.55819702288961]],
    [ "Enedis",[6.764725556721544, 45.558164376819875]],
    [ "Enedis",[6.768780681275068, 45.56342624617118]],
    [ "Roche Palud",[6.540297600000001, 46.14813675]],
    [ "Aviernoz Chef Lieu",[6.221675621638739, 45.977709383384436]],
    [ "Lotissement Lannénec",[-3.4797417923920793, 47.74399487759442]],
    [ "Enedis",[4.807060967881696, 44.4107137698634]],
    [ "Enedis",[4.797829680884928, 44.398543882642066]],
    [ "Enedis",[5.554210449999999, 43.231477850000005]],
    [ "Enedis",[5.550349823228599, 43.229129605215356]],
    [ "Enedis",[4.9177174, 44.41044965]],
    [ "Enedis",[1.9453158249560845, 48.75457655458844]],
    [ "Enedis",[3.2225032999999996, 49.6069493]],
    [ "Enedis",[3.2095318247682707, 49.616945201728754]],
    [ "L'Abreuvoire",[2.9887394278716415, 47.39379525422602]],
    [ "Enedis",[6.652721458833862, 46.098501028415875]],
    [ "Enedis",[6.646518429656583, 46.099771762407464]],
    [ "Enedis",[6.6591469, 46.09792885]],
    [ "Poste électrique de Saint-Julien",[6.211592800130318, 49.151625196979836]],
    [ "Enedis",[6.666915449999999, 46.0937735]],
    [ "Strasbourg Électricité Réseaux",[7.83006415, 48.6948184]],
    [ "Enedis",[4.337315450742119, 47.529013900294466]],
    [ "Enedis",[3.52737835, 49.341684799999996]],
    [ "Enedis",[6.603902990285113, 46.07495761830342]],
    [ "Enedis",[-1.2809307786711808, 49.588175771572985]],
    [ "Enedis",[-1.2787351, 49.58772714999999]],
    [ "Enedis",[4.400907534922763, 45.44583449494971]],
    [ "Enedis",[1.2560544999999999, 47.60898904999999]],
    [ "Enedis",[1.2719718999999998, 47.60935435]],
    [ "Enedis",[6.6752705, 46.1605813]],
    [ "Enedis",[1.3099372079518412, 47.64548698207336]],
    [ "Enedis",[1.3054545894263307, 47.64541061631633]],
    [ "Enedis",[1.3024559, 47.649676899999996]],
    [ "Enedis",[1.295717500153338, 47.641170233265505]],
    [ "Enedis",[1.290557649228429, 47.63876163343462]],
    [ "Enedis",[1.2883554999999998, 47.63513809999999]],
    [ "Enedis",[1.2926772, 47.6300078]],
    [ "Enedis",[1.2929655999999998, 47.62924315]],
    [ "Enedis",[1.2928045999999997, 47.62887215]],
    [ "Enedis",[1.3169429, 47.6245107]],
    [ "Enedis",[1.2753790999999999, 47.61231189999999]],
    [ "Enedis",[1.2920428999999998, 47.63323015]],
    [ "Enedis",[1.2873644330886689, 47.58892335047761]],
    [ "Enedis",[1.28814295, 47.593929550000006]],
    [ "Enedis",[1.28929225, 47.59558990000001]],
    [ "Enedis",[1.303101631986451, 47.61543598466134]],
    [ "Enedis",[1.2875054174501668, 47.60369010224761]],
    [ "Enedis",[1.3085769000000003, 47.61033375]],
    [ "Enedis",[1.311689324524858, 47.604593178766656]],
    [ "Enedis",[1.3080604081169993, 47.60412708113671]],
    [ "Enedis",[6.773023115095382, 45.534103429186246]],
    [ "Enedis",[1.315661, 47.605249750000006]],
    [ "Enedis",[1.31542505, 47.603522399999996]],
    [ "Enedis",[1.3307416312549756, 47.600791693671866]],
    [ "Enedis",[1.3059814000000003, 47.59315645000001]],
    [ "SICAE-ELY",[1.4386407309659328, 48.783247952190834]],
    [ "RTE",[5.022404261853823, 45.376490719147824]],
    [ "Enedis",[3.15932405, 44.00540465]],
    [ "Lidl",[2.5882228988669325, 44.367986370062326]],
    [ "Enedis",[6.32937955, 46.9124449]],
    [ "1312349935",[1.38615825, 48.77393065]],
    [ "SICAE-ELY",[1.4390395450645777, 48.75288271683686]],
    [ "SICAE-ELY",[1.4453599999999998, 48.7596564]],
    [ "SICAE-ELY",[1.4376717183450594, 48.752347404931214]],
    [ "1312484405",[1.4465755999999999, 48.742567949999994]],
    [ "Enedis",[-1.0738436603421821, 45.64156448265625]],
    [ "Enedis",[-1.0871104472761646, 45.6511723888129]],
    [ "Enedis",[-1.0880617206318988, 45.651731029704266]],
    [ "Enedis",[6.6683219167539, 46.15939126768854]],
    [ "Enedis",[6.668525398987986, 46.1601359163131]],
    [ "Enedis",[6.234102887353616, 48.221310423676094]],
    [ "Les Varennes",[3.9434182499999992, 46.0149201]],
    [ "Enedis",[5.279066865143396, 44.174502922341524]],
    [ "Enedis",[5.7285712, 43.26850315000001]],
    [ "Enedis",[-1.0111849294305848, 45.66945034046828]],
    [ "Enedis",[-4.622073195701092, 48.56576051569903]],
    [ "Enedis",[3.217623754317845, 50.14824473416061]],
    [ "Enedis",[5.103143399999999, 44.10302145]],
    [ "Enedis",[-0.5468010000000001, 47.48226225]],
    [ "SNCF Réseau",[3.173261070072206, 46.72705911575796]],
    [ "Gedia",[1.3605735662853677, 48.73221920042289]],
    [ "Gedia",[1.361001461044151, 48.73168641715714]],
    [ "Enedis",[1.4019313962463202, 48.7696296820126]],
    [ "Enedis",[1.4186463611964346, 48.74250497402545]],
    [ "Enedis",[1.4139022562166743, 48.7429193257848]],
    [ "Enedis",[1.4151608200521844, 48.73472632930826]],
    [ "Enedis",[-3.4729981614251813, 48.68609147991842]],
    [ "Tesla",[6.48238345, 45.518750399999995]],
    [ "Les Isles",[6.5145930344091045, 45.36397562763664]],
    [ "SNCF Réseau",[3.3123444276165706, 46.59321778921405]],
    [ "Enedis",[-4.2935395621095696, 48.5507770320665]],
    [ "Enedis",[2.243117848439313, 48.90579871753728]],
    [ "Enedis",[6.770318449999999, 46.2961757]],
    [ "Enedis",[-1.0390407096288568, 45.62084903337556]],
    [ "Enedis",[-1.0663408832889596, 49.103324849714504]],
    [ "Enedis",[-1.0644807473688909, 45.64913731804784]],
    [ "Enedis",[3.218078850000001, 50.17823515]],
    [ "Chassinal",[4.756408229080284, 46.0201125788927]],
    [ "Enedis",[4.0903286683592235, 48.269374457052315]],
    [ "Enedis",[-4.413467911216895, 48.43878575957157]],
    [ "Enedis",[1.3257669430769226, 47.60522629043357]],
    [ "Enedis",[1.3405958, 47.6076211]],
    [ "Enedis",[1.3383681445749915, 47.60819741135356]],
    [ "Enedis",[-1.038385473609389, 45.63834885907101]],
    [ "Enedis",[-1.045193567666843, 45.642159448810396]],
    [ "Enedis",[-1.0551069172494463, 45.63857440073109]],
    [ "Enedis",[1.9416128972011435, 47.87285280455045]],
    [ "Enedis",[1.9440172379578557, 47.87641277500313]],
    [ "Enedis",[1.9426414050966547, 47.875909312875756]],
    [ "Enedis",[-3.512574027813567, 48.69820145786999]],
    [ "BL Heinrich 17",[2.244720774085181, 48.82725015367931]],
    [ "Enedis",[-0.9547793533014243, 45.729519915136045]],
    [ "Enedis",[2.8783545748732253, 50.08711065870012]],
    [ "Enedis",[-1.1024570314149194, 45.648582492718745]],
    [ "Enedis",[-1.061622174225363, 45.634577523255366]],
    [ "Enedis",[-1.05285441624581, 45.63102836233147]],
    [ "Enedis",[-1.0278043592629438, 45.65089527974337]],
    [ "Enedis",[-1.027156829670654, 45.650918596537764]],
    [ "Enedis",[-1.0155296, 45.66036925]],
    [ "Enedis",[-1.0181823, 45.673052549999994]],
    [ "Enedis",[0.45984648912237325, 48.02090593949319]],
    [ "Europe",[1.3311177567430788, 43.983763176512085]],
    [ "Enedis",[1.33861665, 48.77552320000001]],
    [ "Enedis",[1.3267873500000003, 48.7657525]],
    [ "Gedia",[1.3726377999999986, 48.74847309999999]],
    [ "Enedis",[-3.49853615, 48.6853927]],
    [ "Enedis",[6.651911031146031, 45.17336496746882]],
    [ "Enedis",[-0.9537336499999999, 45.56872839999999]],
    [ "Enedis",[-0.9839858418923116, 45.58430878907046]],
    [ "Enedis",[-0.9893762, 45.632143199999994]],
    [ "Enedis",[-0.9942162500000011, 45.634696399999996]],
    [ "Enedis",[-0.9981627999999999, 45.6319172]],
    [ "Enedis",[-1.0011385320689865, 45.63045444496368]],
    [ "Enedis",[-0.9972510499999996, 45.62121249999999]],
    [ "Gedia",[1.3535943240028294, 48.750373196580945]],
    [ "Poterne",[2.7351200999999987, 47.9961468]],
    [ "Enedis",[2.96357615, 50.440010599999994]],
    [ "Mac Donalds",[1.5025335845250996, 43.55047658835542]],
    [ "Enedis",[5.1861824499999996, 43.42764244999999]],
    [ "Enedis",[6.68330035, 46.18016409999999]],
    [ "Enedis",[5.2005667, 43.43580190000001]],
    [ "Enedis",[6.17066934505221, 48.69713550824903]],
    [ "Enedis",[5.206130704188272, 43.43181086653371]],
    [ "Enedis",[2.429939228096972, 50.9518341762413]],
    [ "Enedis",[5.20011605, 43.41480969999999]],
    [ "Enedis",[-1.516729981840564, 48.563043651992885]],
    [ "Enedis",[-1.5156376645224539, 48.56342594149361]],
    [ "Enedis",[2.6709079112728027, 50.42165721921067]],
    [ "Enedis",[-0.92929775, 45.6061195]],
    [ "Enedis",[-0.9556301756121475, 45.60531839263719]],
    [ "Enedis",[-0.9740684993432575, 45.6069782334136]],
    [ "Enedis",[-0.4870435252503601, 44.77277215448001]],
    [ "Enedis",[-0.48838106324215724, 44.77035646400316]],
    [ "Enedis",[-0.48678671085133185, 44.766721223411864]],
    [ "Enedis",[1.834316484443335, 48.648934453156265]],
    [ "Poste de transformation HTA/BT",[7.663596458558058, 48.953177084613436]],
    [ "SNCF Réseau",[3.4046252595718856, 46.305798741322626]],
    [ "Poste de transformation HTA/BT",[7.665150845652946, 48.964592728546954]],
    [ "Finsterkopf",[7.672263359086123, 48.98401219838465]],
    [ "Poste de transformation client HTA/BT",[7.626927785755388, 48.961494312387245]],
    [ "Poste de transformation HTA/BT",[7.62171382123235, 48.96338156430028]],
    [ "Enedis",[4.06521845, 50.024979550000005]],
    [ "Enedis",[4.061478999999999, 50.02987695]],
    [ "Enedis",[4.047343089875906, 50.02428525526182]],
    [ "Enedis",[5.207036117556445, 43.40855145147712]],
    [ "Enedis",[-1.015069602697396, 45.65150321795088]],
    [ "Enedis",[1.9837798868424532, 46.794286233227595]],
    [ "SNCF Réseau",[3.4392718112286564, 46.08971152414137]],
    [ "Poste de transformation HTA/BT",[7.636712649805414, 48.951096914625595]],
    [ "Poste de transformation HTA/BT",[7.625482665556471, 48.95997974548178]],
    [ "Poste de transformation HTA/BT",[7.633382919451847, 48.95293688090015]],
    [ "Poste de transformation HTA/BT",[7.596359541441537, 48.96273939489393]],
    [ "Oxygene",[6.246924171204292, 45.99836782644295]],
    [ "Taquin",[6.1857139, 46.00376570000001]],
    [ "RTE",[5.02566193318036, 46.069515965258404]],
    [ "Poste de transformation HTA/BT",[7.64411309950677, 48.949759561028245]],
    [ "Enedis",[5.0779574499999995, 43.64224625]],
    [ "Enedis",[-4.567571965052037, 48.46694417202044]],
    [ "Gedia",[1.3793726999999987, 48.73224900000001]],
    [ "Enedis",[1.4060553524147839, 48.73599560173035]],
    [ "Gedia",[1.3559841000000001, 48.747428899999996]],
    [ "Gedia",[1.3423160001926342, 48.74866323339378]],
    [ "Gedia",[1.3477961291199692, 48.75370267419603]],
    [ "Gedia",[1.3512523, 48.75389345]],
    [ "Gedia",[1.3515101847988753, 48.753774422019646]],
    [ "Gedia",[1.398418868089165, 48.748697093086655]],
    [ "Gedia",[1.3995739464041836, 48.75058348167832]],
    [ "Gedia",[1.3861187880094503, 48.75016493866903]],
    [ "Gedia",[1.4028525999999988, 48.750102299999995]],
    [ "Gedia",[1.3998536000000004, 48.74775184999999]],
    [ "Enedis",[1.4149120500000012, 48.731581]],
    [ "Poste de transformation HTA/BT",[7.64387639011534, 48.95046745075892]],
    [ "Enedis",[5.205179231081446, 43.3945857980876]],
    [ "Enedis",[5.198615649999999, 43.39390105]],
    [ "Enedis",[2.872890218182215, 50.68564830170141]],
    [ "Enedis",[-0.7590222999999999, 48.771946699999994]],
    [ "Enedis",[-0.7624637005457382, 48.77298713351633]],
    [ "Enedis",[5.241784132356652, 43.39171511168823]],
    [ "Enedis",[-0.30366765, 49.190925899999996]],
    [ "Enedis",[5.2411157, 43.3953603]],
    [ "Enedis",[2.8695321653951487, 50.694112482697186]],
    [ "Enedis",[2.8702480281783016, 50.68670806259453]],
    [ "Enedis",[2.864574633025096, 50.6902479810488]],
    [ "Enedis",[2.868907969221308, 50.68249850181941]],
    [ "Gérédis",[-0.3861577, 46.337679800000004]],
    [ "Enedis",[5.239099631467017, 43.38721010725455]],
    [ "Enedis",[5.23852295, 43.3949748]],
    [ "Enedis",[5.237713822007284, 43.39551555346315]],
    [ "Gérédis",[-0.386092600000001, 46.3376799]],
    [ "Enedis",[2.5172347449551773, 50.96989326655158]],
    [ "SNCF Réseau",[3.9912359499999996, 47.802070099999995]],
    [ "SNCF Réseau",[4.031590154395764, 47.76166266263818]],
    [ "SNCF Réseau",[4.3911143, 47.02011239999999]],
    [ "SNCF Réseau",[4.165170373171139, 47.47686700079312]],
    [ "Enedis",[2.8945974576546, 50.68489140394867]],
    [ "SNCF Réseau",[2.738752, 48.703878450000005]],
    [ "SNCF Réseau",[3.0267670999999976, 48.373909649999995]],
    [ "SNCF Réseau",[3.5926387635800596, 48.04185441175089]],
    [ "Enedis",[-1.6314041547657765, 49.6352110655195]],
    [ "Enedis",[-1.625523349999999, 49.63427134999999]],
    [ "Enedis",[-1.6769916942230176, 49.65856346768758]],
    [ "Enedis",[-1.6025455935074373, 49.5950107770219]],
    [ "Enedis",[-0.9689429818988211, 45.640092943408504]],
    [ "Pont vert bas",[6.03718051523395, 45.89639598088502]],
    [ "Enedis",[2.8890815275154527, 50.685097006979014]],
    [ "Enedis",[4.448938068083646, 45.47982627621484]],
    [ "Enedis",[2.4120314472097415, 48.75318361728405]],
    [ "Enedis",[7.312009416094048, 49.061143533448174]],
    [ "Enedis",[1.340378967295245, 43.448412624816456]],
    [ "Enedis",[4.968029950000001, 47.132389149999995]],
    [ "SNCF Réseau",[5.002228276817914, 45.37792409405051]],
    [ "SNCF Réseau",[5.09597415, 45.6030036]],
    [ "SNCF Réseau",[5.031982377376154, 45.82542191826197]],
    [ "SNCF Réseau",[4.849428195854403, 46.056786575129294]],
    [ "Poste électrique de Grangent",[4.247133383530939, 45.46815682972019]],
    [ "Enedis",[4.409591442234574, 45.452845365513255]],
    [ "SRD",[0.2585512585227813, 46.15021354798244]],
    [ "Enedis",[2.0438240667066054, 48.779039750089225]],
    [ "Enedis",[2.046730181981249, 48.776773806240385]],
    [ "Enedis",[2.0481468681511377, 48.77656876240229]],
    [ "Enedis",[2.0487618999999997, 48.775356300000006]],
    [ "Enedis",[2.0492646816221365, 48.777387666403406]],
    [ "Enedis",[2.0589250000000003, 48.77666829999999]],
    [ "RTE",[1.0435864999999998, 46.1047193]],
    [ "Enedis",[-4.366945677382019, 48.64443948983535]],
    [ "EDF",[1.2102550147966664, 49.97650055783879]],
    [ "EDF",[1.2117293829302962, 49.977481433177545]],
    [ "Enedis",[6.399525732722489, 46.11066143272815]],
    [ "Poste électrique Ringo de Jalancourt",[5.367768150000001, 47.51116125000001]],
    [ "1328928939",[-0.13686599999999982, 46.217941499999995]],
    [ "1328928940",[1.9482451857501164, 44.967593322100385]],
    [ "1328928941",[-0.6156528000000002, 44.61635735]],
    [ "RTE",[3.5615305284504166, 45.86162839554818]],
    [ "RTE",[-0.3665141208460013, 47.880719274749794]],
    [ "RTE",[7.555616867358708, 44.0049111214533]],
    [ "1328928946",[2.096513714296613, 50.50040453136782]],
    [ "1328928949",[3.5985734249660495, 49.969776196727885]],
    [ "1328928952",[1.8952508735730478, 49.83382856234864]],
    [ "1328928953",[-1.1270255723059412, 46.21290937630911]],
    [ "Enedis",[4.76736031655934, 45.978682096936254]],
    [ "Enedis",[3.051487714990844, 50.65902619727417]],
    [ "Enedis",[-0.8980309500000008, 48.5059411]],
    [ "ZA Centre",[-3.488819814457183, 47.7906650018335]],
    [ "Enedis",[4.4111706687381265, 45.44355840916045]],
    [ "Enedis",[4.4269949310510155, 45.44027102132144]],
    [ "Enedis",[5.1529204, 43.3944763]],
    [ "Enedis",[5.150594887365345, 43.39337676839371]],
    [ "Enedis",[5.15148486604187, 43.39266819823121]],
    [ "Enedis",[5.181477150000001, 43.3934429]],
    [ "Enedis",[5.131485724469425, 43.394126404559806]],
    [ "1330176904",[0.26424672851214853, 47.665085775260096]],
    [ "Trèves",[6.790148416841213, 45.626548476611006]],
    [ "Le Freynet",[5.938952498924986, 45.21848773890201]],
    [ "Enedis",[5.19130706074124, 43.39353531823067]],
    [ "Enedis",[5.19141376074124, 43.39354881823068]],
    [ "Enedis",[5.1985791500000005, 43.40015620000001]],
    [ "Enedis",[5.19631015, 43.3984524]],
    [ "Enedis",[5.1961968, 43.3982728]],
    [ "Enedis",[5.195415163733399, 43.395362075333665]],
    [ "Enedis",[5.195254266444838, 43.39533001090657]],
    [ "Enedis",[2.3840441188624557, 48.849775228317185]],
    [ "Enedis",[1.5207, 49.07891355]],
    [ "Enedis",[1.5198694560884085, 49.080600667821116]],
    [ "SNCF Réseau",[3.7172582807159396, 47.97811555644181]],
    [ "Festi Ripaille",[6.485812235444902, 46.38618320323334]],
    [ "réséda",[6.245874665417309, 49.08529674756119]],
    [ "Festi Ripaille",[6.498971669950851, 46.38110067993813]],
    [ "Enedis",[3.7994358000000017, 48.16990525]],
    [ "SNCF Réseau",[3.3741571806964985, 48.485924748841015]],
    [ "Enedis",[1.5910571214284712, 49.050957493226164]],
    [ "limetz",[1.5481424796888639, 49.05998979451476]],
    [ "Enedis",[2.0799075499999984, 48.84025269999999]],
    [ "Enedis",[5.224040342660044, 43.39926403390704]],
    [ "Enedis",[5.217057482841357, 43.402465102238]],
    [ "Enedis",[5.208357543302525, 43.393675424672224]],
    [ "Enedis",[5.20673763955346, 43.393552725221]],
    [ "P34 Rose",[-0.9415140622711404, 43.218287008741186]],
    [ "Enedis",[2.1168919406504685, 48.8415684286513]],
    [ "Maintenance base parc éolien",[-0.2467151575175024, 49.2819878277183]],
    [ "P4FS Phare",[-0.24666145, 49.2819227]],
    [ "Enedis",[2.2552280255153305, 48.92203099226562]],
    [ "2 Feivres",[6.237550949999999, 49.114839849999996]],
    [ "C Bernard",[6.208995824275706, 49.11364270810682]],
    [ "11ème Rgt d'Aviation",[6.144373566190219, 49.08566384595021]],
    [ "La Rotonde",[6.118512, 49.087586949999995]],
    [ "10 Grand Rue",[6.0804715, 49.0656629]],
    [ "Stade",[6.18471020176536, 48.957449971768554]],
    [ "Salle Polyvalente",[6.0335925, 49.18721955]],
    [ "Moniaz Nord",[6.31042315, 46.2439885]],
    [ "Enedis",[4.885130750234271, 46.256299742155164]],
    [ "Gérédis",[-0.3754284499999999, 46.34680905]],
    [ "Enedis",[2.1939531, 48.712921900000005]],
    [ "Enedis",[5.75303855, 45.609095200000006]],
    [ "Enedis",[-1.0987216915728215, 48.08846254487612]],
    [ "Enedis",[1.3066915265695704, 47.58836307060935]],
    [ "Enedis",[1.3106382999999997, 47.588077549999994]],
    [ "Enedis",[1.3129108, 47.585823999999995]],
    [ "Enedis",[2.1813963500000018, 48.89261705]],
    [ "réséda",[6.173660334075943, 49.106122251196425]],
    [ "Enedis",[5.20493656871379, 43.413425030544204]],
    [ "Enedis",[2.1842075644842973, 48.895906995530346]],
    [ "Enedis",[2.1884550341123834, 48.89624258463078]],
    [ "Enedis",[2.188590716679913, 48.89472099441004]],
    [ "Enedis",[1.3179857499999998, 47.5928277]],
    [ "Enedis",[1.3148085782833558, 47.59166757671977]],
    [ "1337706723",[3.8460235000000007, 43.61143745]],
    [ "Enedis",[3.846076796563127, 43.611482130333606]],
    [ "poste_6409",[2.8263962499999997, 49.447738050000005]],
    [ "Enedis",[3.1131745999999993, 50.3732142]],
    [ "Enedis",[5.139821108034326, 43.390955773394495]],
    [ "Enedis",[5.1466268500000005, 43.389690099999996]],
    [ "Enedis",[5.145666411234168, 43.386805866116745]],
    [ "Enedis",[5.155871532607648, 43.3905377807162]],
    [ "Enedis",[5.150185590577684, 43.38741044535398]],
    [ "Enedis",[2.1790458265290296, 48.89938684193079]],
    [ "Enedis",[2.17187995, 48.8944455]],
    [ "Enedis",[2.1738412310204507, 48.891484335023215]],
    [ "Enedis",[3.0842067611208575, 50.36562281900552]],
    [ "Enedis",[3.0842815957746477, 50.36559655211268]],
    [ "Enedis",[5.180372324779897, 43.378042160269565]],
    [ "Enedis",[5.180009, 43.3800161]],
    [ "Enedis",[5.179775519705236, 43.38125310256693]],
    [ "Enedis",[5.177401649999999, 43.382613250000006]],
    [ "Enedis",[5.177447117017674, 43.38030252341272]],
    [ "Enedis",[5.177269924645477, 43.38664439623177]],
    [ "Enedis",[5.1746594, 43.3865454]],
    [ "Enedis",[5.175620813190647, 43.38782984591946]],
    [ "Enedis",[5.165596275714532, 43.389521853746054]],
    [ "Enedis",[5.173945694696833, 43.38800637017012]],
    [ "Enedis",[5.17225337510296, 43.38859336649273]],
    [ "Enedis",[5.177932154647148, 43.39003868022375]],
    [ "Enedis",[3.0400714662977655, 50.369135402149816]],
    [ "Enedis",[-0.8382195355159433, 46.14803648660959]],
    [ "Enedis",[3.0918316992993917, 50.364302267522966]],
    [ "SICAE de la Somme et du Cambraisis",[2.657399329057611, 49.58970160304804]],
    [ "Enedis",[5.196293811192971, 43.376088894152744]],
    [ "Enedis",[5.202214500000001, 43.38186054999999]],
    [ "Enedis",[5.195967321575623, 43.38167745831724]],
    [ "PL Libération 26",[2.2535051595289723, 48.7783932624144]],
    [ "PL Étang Écoute 1",[2.2664967, 48.77767595]],
    [ "SICAE de la Somme et du Cambraisis",[2.643751428691645, 49.59768085714528]],
    [ "Enedis",[5.173851055746035, 43.38564362081975]],
    [ "Enedis",[5.197499993824427, 43.38271707053527]],
    [ "Enedis",[5.1975130499999995, 43.38267485]],
    [ "Enedis",[5.1985152999999995, 43.382866549999996]],
    [ "Enedis",[5.200311600000002, 43.38299595000001]],
    [ "SICAE de la Somme et du Cambraisis",[2.6523227000000005, 49.58884669999999]],
    [ "Enedis",[0.17066810237392427, 48.0510052321691]],
    [ "Grand Chemin",[5.130698006443848, 45.59991392977811]],
    [ "SICAE Oise",[2.6148294, 49.59929225]],
    [ "Enedis",[2.9688943, 49.34867945000001]],
    [ "Enedis",[2.9635900336548873, 49.346395997829525]],
    [ "Enedis",[2.9973559499999993, 49.3491032]],
    [ "Duchesne",[2.9945056, 49.349161650000006]],
    [ "Enedis",[2.986516950000001, 49.35141795]],
    [ "Aveugles CR",[2.417131850000001, 48.84223134999999]],
    [ "SICAE de la Somme et du Cambraisis",[2.5718746000000006, 49.61470455]],
    [ "SICAE de la Somme et du Cambraisis",[2.6018998436592775, 49.613647974479285]],
    [ "Enedis",[-0.8395411563856547, 45.56989538094049]],
    [ "Enedis",[-0.843214600000001, 45.579157050000006]],
    [ "Enedis",[2.9754046000000005, 50.647110850000004]],
    [ "SICAE de la Somme et du Cambraisis",[2.5984090836652256, 49.62321305142181]],
    [ "SICAE de la Somme et du Cambraisis",[2.6091453, 49.62273065000001]],
    [ "Enedis",[6.99941765, 48.39537240000001]],
    [ "Technicolor",[-1.6194650154514996, 48.134158232349]],
    [ "Enedis",[3.084246985508051, 50.76964006932847]],
    [ "Enedis",[-0.7650925840452306, 48.06938116702683]],
    [ "Enedis",[3.10332175, 50.76806010000001]],
    [ "Enedis",[3.0995569, 50.7651442]],
    [ "Enedis",[3.084294127068597, 50.770288788449534]],
    [ "Le Pontet",[6.800615346254092, 45.26006958447741]],
    [ "Enedis",[3.0830124773840386, 50.77219564006606]],
    [ "Enedis",[3.0870461000000002, 50.7656044]],
    [ "Enedis",[3.087069750000001, 50.765616599999994]],
    [ "Enedis",[2.6376121221547804, 46.54538788145373]],
    [ "Enedis",[3.0768401499999998, 50.772753200000004]],
    [ "Enedis",[3.062163599999999, 50.7752318]],
    [ "Enedis",[3.0496735378180286, 50.77395870371552]],
    [ "Enedis",[3.0499798328664816, 50.774097354796886]],
    [ "Enedis",[3.0500510147872624, 50.77621531778559]],
    [ "Enedis",[3.0493994000000013, 50.756274350000005]],
    [ "Prieuré",[0.8802480126124375, 49.8075177276533]],
    [ "Enedis",[1.1541744331911747, 49.447507700506215]],
    [ "La Forge",[1.0421935999999998, 48.99069785]],
    [ "Bourg",[-2.2561742625642784, 48.488421423471344]],
    [ "Enedis",[3.0501403999999996, 50.76883890000001]],
    [ "Enedis",[-0.10554515231440527, 48.39597051668931]],
    [ "Enedis",[-0.9552873853613384, 46.994444368286445]],
    [ "Enedis",[3.164820168309086, 50.339966893365926]],
    [ "Enedis",[4.372797875128745, 45.41196646202083]],
    [ "Enedis",[4.372920507472823, 45.41231742655094]],
    [ "Enedis",[4.636922599999998, 44.37659730000001]],
    [ "Enedis",[4.641709733090386, 44.39695784228639]],
    [ "Enedis",[4.642276127785162, 44.397056202872726]],
    [ "Le Poulet",[4.75667785, 46.01782695]],
    [ "Enedis",[4.76456685, 45.984768700000004]],
    [ "Enedis",[4.991421219687136, 45.8282099075054]],
    [ "Enedis",[4.9893054327379325, 45.829290405306836]],
    [ "Enedis",[4.781072373524591, 45.75090703749057]],
    [ "Enedis",[2.9945499743753508, 50.75219415794959]],
    [ "Enedis",[2.9985150536345855, 50.75275661003725]],
    [ "Enedis",[2.9998130695282685, 50.752835477129516]],
    [ "Enedis",[3.007354378870697, 50.759884574974386]],
    [ "Enedis",[3.0123745499999997, 50.73771460000001]],
    [ "Enedis",[3.0120325160175905, 50.74333310229543]],
    [ "Enedis",[-4.207144058138135, 48.510842584286856]],
    [ "Enedis",[2.998336623764036, 50.759661557309656]],
    [ "Enedis",[2.9976473907918653, 50.75959673466541]],
    [ "Enedis",[2.9969034447710787, 50.759879246335416]],
    [ "Enedis",[2.3588511434607367, 50.57335006703122]],
    [ "1342372222",[-1.4751752999999994, 49.50450994999999]],
    [ "Enedis",[5.570996715704975, 48.31854135396362]],
    [ "Enedis",[6.9853018166838945, 48.269071900065036]],
    [ "Enedis",[3.1384132, 50.73806665000001]],
    [ "Enedis",[3.151142266791413, 50.7510535702662]],
    [ "Enedis",[1.7217477056004489, 47.79436093469618]],
    [ "Enedis",[-1.1336768310312908, 49.108872979913556]],
    [ "Cadiot",[2.4365291852312843, 48.801513525888794]],
    [ "Enedis",[3.1274507302750463, 50.7084298616864]],
    [ "Enedis",[3.126403834420646, 50.70562380127727]],
    [ "Enedis",[5.746754489927489, 49.517457374755594]],
    [ "Enedis",[3.2100096178107616, 50.698642784353744]],
    [ "Enedis",[3.210235022326573, 50.698792215515255]],
    [ "Enedis",[6.474338086338838, 48.19108862357122]],
    [ "Enedis",[3.2201318521594637, 50.70371596637925]],
    [ "Enedis",[3.21819181112468, 50.70363011468235]],
    [ "Enedis",[3.2156442654143746, 50.705535247951296]],
    [ "Enedis",[3.2162026, 50.70358685]],
    [ "Enedis",[3.163712321377321, 50.72184776592294]],
    [ "Enedis",[3.163784818249023, 50.72154004507282]],
    [ "Enedis",[3.1604628426087857, 50.718961614794566]],
    [ "Enedis",[6.06530025, 48.319875149999994]],
    [ "Enedis",[6.068343131370457, 48.31860542407199]],
    [ "Enedis",[6.072800449999998, 48.321291900000006]],
    [ "Enedis",[6.043040150000001, 48.35741984999999]],
    [ "Enedis",[1.4891694336688384, 43.61432560058172]],
    [ "Enedis",[6.5793780249594285, 48.0289121562374]],
    [ "Enedis",[6.557737186264289, 48.02381038054832]],
    [ "Enedis",[6.563609119429197, 48.01978749766317]],
    [ "Enedis",[6.127693151070553, 48.311503333581406]],
    [ "Enedis",[6.134469015789988, 48.312521344225054]],
    [ "Enedis",[6.1313045499999985, 48.306110049999994]],
    [ "Enedis",[6.147472765267055, 48.30294374872576]],
    [ "Enedis",[6.1478497, 48.30308345]],
    [ "Enedis",[6.137102450000001, 48.27313225]],
    [ "Enedis",[5.752409200000001, 49.51716150000001]],
    [ "Enedis",[5.770595565630148, 49.51275673120316]],
    [ "1344520065",[5.0715080349265405, 46.2034619708174]],
    [ "1344533343",[5.046536386386359, 46.189119078192846]],
    [ "Enedis",[5.910893700000001, 48.69459034999999]],
    [ "Enedis",[5.9060103999999995, 48.6944568]],
    [ "Enedis",[5.9077103, 48.69604405]],
    [ "Enedis",[5.90764805, 48.6964353]],
    [ "Enedis",[5.910957047363121, 48.69714436761753]],
    [ "Enedis",[3.1178579500000003, 50.737875450000004]],
    [ "Enedis",[6.1618276, 48.61166240000001]],
    [ "Enedis",[6.210137225646086, 48.61596255305771]],
    [ "Enedis",[6.207289412311853, 48.61722382456782]],
    [ "Enedis",[6.182033100000001, 48.610640599999996]],
    [ "Enedis",[6.197069149999999, 48.61728385000001]],
    [ "Enedis",[6.209994195411274, 48.65296537508976]],
    [ "Enedis",[6.272250557715126, 48.717248579538136]],
    [ "Enedis",[3.115051672141585, 50.73462017769123]],
    [ "RTE",[6.544099116084113, 43.8478163448092]],
    [ "Enedis",[-1.424023843511397, 49.08286992504667]],
    [ "Enedis",[3.0880197, 50.74845124999999]],
    [ "Enedis",[3.0862322708639263, 50.74831506304339]],
    [ "Enedis",[3.0934051049170956, 50.72994583168273]],
    [ "Enedis",[3.086319154085729, 50.75477666194414]],
    [ "Enedis",[3.0798231999999994, 50.74232835]],
    [ "Enedis",[3.113688580883742, 50.73923069294656]],
    [ "Enedis",[3.1178274078994197, 50.74430337134956]],
    [ "Enedis",[3.119519620007406, 50.74561064261219]],
    [ "Enedis",[3.1188412289080247, 50.74776560317714]],
    [ "Enedis",[3.122698467689338, 50.76325547556352]],
    [ "Enedis",[3.123682889473685, 50.76405806666667]],
    [ "Enedis",[3.121786276069721, 50.764764353242924]],
    [ "Enedis",[0.7125163873946602, 49.82748972855438]],
    [ "Enedis",[3.1203520625560075, 50.754303636658975]],
    [ "Enedis",[3.1252666817037573, 50.75243252289496]],
    [ "SNCF Réseau",[1.793502388143557, 43.36266700906926]],
    [ "Enedis",[3.1269853000000003, 50.74701645000001]],
    [ "Enedis",[3.1218064471194684, 50.7535341287207]],
    [ "Enedis",[3.113450984170158, 50.757534205191156]],
    [ "Enedis",[3.118797309871989, 50.757001990171396]],
    [ "Enedis",[3.142085400000001, 50.742410750000005]],
    [ "Enedis",[3.142139449999999, 50.74238985]],
    [ "Enedis",[3.142088483176948, 50.743174850219155]],
    [ "Enedis",[3.1450378932134644, 50.74179082618522]],
    [ "Enedis",[3.1453012500000015, 50.74137655]],
    [ "Enedis",[3.1325414339502364, 50.73540825140013]],
    [ "Enedis",[3.1295537834876366, 50.739696149299114]],
    [ "Enedis",[5.116972931571904, 43.394594601027954]],
    [ "Enedis",[3.1434245969419092, 50.731503016085696]],
    [ "Électricité de Mayotte",[45.11628388220638, -12.868344215186788]],
    [ "Enedis",[3.0886263832069405, 50.7332252491117]],
    [ "Enedis",[3.08153115, 50.73345595000001]],
    [ "Enedis",[3.078506800058439, 50.736201566174564]],
    [ "Enedis",[3.1451584832668393, 50.7191875321215]],
    [ "Enedis",[3.1533734956073136, 50.71107471874241]],
    [ "Enedis",[3.1547065964959, 50.71509423631243]],
    [ "Enedis",[3.1554719678198966, 50.7160279973369]],
    [ "Enedis",[3.1600743920485495, 50.714021210267795]],
    [ "Enedis",[3.161193177093188, 50.71313125918918]],
    [ "Enedis",[3.1579930497955915, 50.71202631931839]],
    [ "Enedis",[3.158197524740929, 50.71356840944499]],
    [ "Enedis",[3.1807593349387826, 50.68929254406219]],
    [ "Enedis",[3.168758996569562, 50.691463381760585]],
    [ "Enedis",[3.1834376124110944, 50.68570868809354]],
    [ "Enedis",[3.177171270475691, 50.694915250869045]],
    [ "Enedis",[3.1800503318377618, 50.6966935652717]],
    [ "Enedis",[3.1741540500000003, 50.696750150000014]],
    [ "Enedis",[3.1749778500000008, 50.70565634999999]],
    [ "Enedis",[3.225752532871965, 50.18040605462583]],
    [ "Enedis",[5.07124685, 46.050043249999995]],
    [ "Enedis",[3.1664908192644456, 50.714791907796254]],
    [ "Enedis",[3.1661941085303806, 50.71400418352304]],
    [ "Enedis",[3.1655761423369184, 50.71379476085435]],
    [ "Enedis",[3.1667532456224237, 50.71364168904826]],
    [ "Enedis",[3.167458716857688, 50.71367674718242]],
    [ "Enedis",[3.1673115393875104, 50.71208486795859]],
    [ "Enedis",[3.172090620581128, 50.708978653326994]],
    [ "Enedis",[3.1737514687395327, 50.70970476185932]],
    [ "Enedis",[3.1699617000000004, 50.719319399999996]],
    [ "Enedis",[1.8278788716040306, 43.522847785025576]],
    [ "Enedis",[3.178086584721794, 50.74398259589898]],
    [ "EDF",[-1.8799057623979432, 49.53728414938235]],
    [ "Enedis",[6.347033833551152, 45.6662204963367]],
    [ "Enedis",[5.785342924630742, 49.54689204681725]],
    [ "Enedis",[4.0578102843302855, 49.96477940227695]],
    [ "Enedis",[3.9438374807777543, 50.00529611602134]],
    [ "Enedis",[3.138837679947863, 50.676649573039334]],
    [ "Enedis",[3.1205929104398575, 50.6772647589776]],
    [ "Enedis",[3.1211942302979323, 50.67764431264324]],
    [ "Enedis",[3.125599681778625, 50.68053330187303]],
    [ "Enedis",[3.121860821403537, 50.654326146867255]],
    [ "Enedis",[3.1209862499999996, 50.65856334999999]],
    [ "Enedis",[3.1205510999999997, 50.659101699999994]],
    [ "Enedis",[3.1276992513816437, 50.656425562754166]],
    [ "Enedis",[3.12821655, 50.6570188]],
    [ "Enedis",[3.9618729948040445, 50.02497727625444]],
    [ "Enedis",[3.9622243539581934, 50.026675365392954]],
    [ "Enedis",[3.9454480792734117, 49.96956980269336]],
    [ "Enedis",[3.1197809226019477, 50.650657556886344]],
    [ "Enedis",[3.119773177395778, 50.650696093093394]],
    [ "Enedis",[3.119446069057937, 50.651638970432856]],
    [ "Enedis",[3.1194183000000004, 50.65157845]],
    [ "Enedis",[3.119679799999999, 50.6527598]],
    [ "Enedis",[3.1175165075118008, 50.65254295031705]],
    [ "Enedis",[3.1417723, 50.64273215]],
    [ "Enedis",[3.1379615565853363, 50.60132146504891]],
    [ "Enedis",[3.137995808466789, 50.60134023943392]],
    [ "Enedis",[2.781146366113018, 42.58355685351014]],
    [ "Enedis",[3.1252599999999995, 50.616733800000006]],
    [ "Enedis",[3.1266157954529135, 50.61551788416866]],
    [ "Enedis",[3.1279179928635013, 50.61535186567745]],
    [ "Enedis",[3.1254403477755823, 50.608945034043565]],
    [ "Enedis",[5.079837737693922, 43.397742718505604]],
    [ "Enedis",[3.1180517615471475, 50.64846743340771]],
    [ "Enedis",[3.1164790590253677, 50.64803568354995]],
    [ "Enedis",[1.5003374999999997, 43.59694139999999]],
    [ "Enedis",[3.111120340474613, 50.6338680696236]],
    [ "Enedis",[3.1176917008473337, 50.62786986688736]],
    [ "Enedis",[3.117655499152666, 50.62783203311264]],
    [ "Enedis",[4.56916348403396, 45.301761911208885]],
    [ "1348585200",[4.716268409485834, 50.090929099642054]],
    [ "Enedis",[3.088213844458965, 50.65761711596851]],
    [ "Enedis",[3.07131505, 50.6599024]],
    [ "Enedis",[3.0587015689162897, 50.664170178783955]],
    [ "Enedis",[3.06327425, 50.66422595]],
    [ "Enedis",[3.0567286396746796, 50.657559262343085]],
    [ "Enedis",[6.2837675718229855, 48.10236926649899]],
    [ "Enedis",[4.197519631228263, 43.79015393738211]],
    [ "Le Clos Fleuri",[6.3282574088127355, 45.667592632921796]],
    [ "Enedis",[-1.5850682500000002, 49.619735500000004]],
    [ "Enedis",[-1.5859117758200671, 49.621366330947616]],
    [ "Enedis",[2.632661541125408, 47.68380473277975]],
    [ "Solliat",[6.276510115675088, 45.44814121861813]],
    [ "Mollard",[6.2721919, 45.43685455]],
    [ "Enedis",[3.1753305322905203, 50.73589480531164]],
    [ "Enedis",[3.1640965069337943, 50.71600138454415]],
    [ "Enedis",[3.1696342076053687, 50.717053784494276]],
    [ "Enedis",[3.1471057384575616, 50.72298082125666]],
    [ "Enedis",[1.5973506804686486, 49.085470483919146]],
    [ "Enedis",[3.165357010914262, 50.727742653980265]],
    [ "Enedis",[3.1688244500000002, 50.698930000000004]],
    [ "Enedis",[3.16887465, 50.698961450000006]],
    [ "Enedis",[3.5149190999999997, 48.486928750000004]],
    [ "Enedis",[1.2540604000000002, 49.10647245000001]],
    [ "Enedis",[1.2564748500000003, 49.10416649999999]],
    [ "Enedis",[6.023102992613576, 48.32980187745777]],
    [ "Enedis",[1.4639366087738561, 49.3970970342721]],
    [ "Enedis",[3.147072022777044, 50.712835646226644]],
    [ "Enedis",[3.148780860965744, 50.71069719171541]],
    [ "Enedis",[3.1438805588958294, 50.70713422873261]],
    [ "Enedis",[3.1458550850184412, 50.706054674831485]],
    [ "Enedis",[3.151947724485964, 50.70774880256008]],
    [ "Enedis",[3.157493162434678, 50.708390322485975]],
    [ "Enedis",[6.334190150000001, 45.6741572]],
    [ "Enedis",[3.1605372943695262, 50.701855679947435]],
    [ "Enedis",[3.4772408758866393, 50.22052236567881]],
    [ "Enedis",[6.331868481628717, 45.66979289608836]],
    [ "Enedis",[3.1445535781930816, 50.58025159254541]],
    [ "Enedis",[3.1473640499999997, 50.57925304999999]],
    [ "Enedis",[3.1473036162555634, 50.57921184787298]],
    [ "Enedis",[3.1403552526128844, 50.57501571162397]],
    [ "Enedis",[3.4303707499999994, 50.19333420000001]],
    [ "Enedis",[3.4132097236759176, 50.1659135932398]],
    [ "RTE",[1.4929474434109682, 43.61589640139939]],
    [ "RTE",[1.4942020423209021, 43.616514616143704]],
    [ "RTE",[4.71634909343095, 44.332429411693774]],
    [ "RTE",[4.716286659515233, 44.33087020040884]],
    [ "RTE",[1.5782908937758517, 47.718623369125424]],
    [ "RTE",[1.5793490307807536, 47.718908495039116]]
];
