import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ukPowerSourceList, ukPowerSourceType } from './common.js';

// Register all components (including pie chart support)
Chart.register(...registerables);
Chart.register(ChartDataLabels); 

const chartTypeSelect = document.getElementById('chartType');
let selectedDatasetIndex;

const typeColorMap = {
  'Biomass': 'rgba(17, 141, 255, 1)',
  'ESS': 'rgba(18, 35, 158, 1)', 
  'ESS+PV': 'rgba(230, 108, 55, 1)',
  'Gas': 'rgba(107, 0, 123, 1)',
  'Pump': 'rgba(224, 68, 167, 1)',
  'Reactive': 'rgba(116, 78, 194, 1)',
  'Thermal': 'rgba(217,179, 0, 1)',
  'Tidal': 'rgba(214, 69, 80, 1)',
  'Wind Offshore': 'rgba(25, 114, 120, 1)',
  'Wind Onshore': 'rgba(26, 171, 64, 1)',
  
  
  //Below not found in report
  'Nuclear': 'rgba(4, 59, 92, 0.5)',
  'Hydro': 'rgba(4, 59, 92, 0.5)',
  'Coal': 'rgba(4, 59, 92, 0.5)',
  'OCGT': 'rgba(4, 59, 92, 0.5)',
  'Waste': 'rgba(4, 59, 92, 0.5)',
  'Demand': 'rgba(4, 59, 92, 0.5)',
  'CHP': 'rgba(4, 59, 92, 0.5)', 
  'CCGT': 'rgba(4, 59, 92, 0.5)', 
  
  // Add more types and colors as needed
};


chartTypeSelect.onchange = function () {
	if(chartTypeSelect.value === 'pieChartWithCategory'){
		
		document.getElementById('pieChartWithCategory').style.display = 'block';
		document.getElementById('barChartWithCategory').style.display = 'none';
		
	}else if(chartTypeSelect.value === 'barChartWithCategory'){
		
		document.getElementById('pieChartWithCategory').style.display = 'none';
		document.getElementById('barChartWithCategory').style.display = 'block';
		
	}else if(chartTypeSelect.value === 'None'){
		
		document.getElementById('pieChartWithCategory').style.display = 'none';
		document.getElementById('barChartWithCategory').style.display = 'none';
	}
};

// Function to fetch GeoJSON data from a URL
async function fetchGeoJson(url) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const geojson = await response.json();
    return geojson;
  } catch (error) {
    console.error('Error fetching GeoJSON:', error);
    return null;
  }
}


// Function to aggregate GeoJSON data
function aggregateGeoJsonData(geojson) {
  const data = {};
  let capacity;
  geojson.features.forEach(feature => {
    let type = ukPowerSourceType(feature.properties['Type']); // e.g., "solar", "wind"
    if (type !== "Misc") {
      capacity = feature.properties['Capacity (MW)']; // e.g., 50, 30
    }

	const year = getYearFromExcelDate(feature.properties['Effective Date']); // e.g., "2023", "2024"
    if(year > 2022 && year < 2029){
      if (type !== "Misc") {
        if (data[type]) {
          data[type] += capacity/1000;
        } else {
          data[type] = capacity/1000;
        }  
      }
	}
	//type = type.trim().substring(0, 4);
    // Aggregate capacity by type
    
  });

  //console.log('Aggregated Data:', data);
  return data;
}

// Function to create a pie chart with aggregated data
let pieChartWithCategory;
function createPieChart(data) {
  const ctx = document.getElementById('pieChartWithCategory').getContext('2d');
  data = ukPowerSourceList.map(category => data[category] || 0);
  
  const total = Object.values(data).reduce((sum, value) => sum + value, 0);
  
  pieChartWithCategory = new Chart(ctx, {
    type: 'pie',
    data: {
      labels: ukPowerSourceList,
      datasets: [{
        data: data,        
		backgroundColor: ukPowerSourceList.map(type => typeColorMap[type] || 'rgba(4, 59, 92, 0.5)'),
      }]
    },
	options: {
		responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
				position: 'bottom', // Move legend to the left side
				labels: {
					usePointStyle: true, // Use point style (circle)
					pointStyle: 'circle', // Specify circle style
				}
            },
			tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = parseFloat(tooltipItem.raw).toFixed(2); // Get the raw value
                        const percentage = ((value / total) * 100).toFixed(2); // Calculate percentage
                        const label = tooltipItem.label; // Get the label
                        return `${label}: ${value} GW (${percentage}%)`; // Customize the tooltip
                    }
                }
			},
			datalabels: {
                color: 'black',
                align: 'end',    // Positions the labels outside the slices
                anchor: 'end',   // Anchors the labels at the edge of the slice
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex];
					const percentage = ((value / total) * 100).toFixed(2); 
                    return `${label}: ${value.toFixed(2)} (${percentage}%)`;
                },
				display: function(context) {
					const value = context.dataset.data[context.dataIndex];
					return value > 3;
				},
            },
			title: {
				display: true,  // Set to true to display the title
				text: ['Cumulative Total Capacity (GW) by Category', 'Period 1 January to 31 December 2028'],
				font: {
				  size: 14,  // Font size for the title
				  weight: 'bold',  // Font weight for the title
				  family: 'Arial'  // Font family for the title
				},
				color: '#333',  // Color of the title text
				padding: {
				  top: 10,  // Padding above the title
				  bottom: 20  // Padding below the title
				}
			}
        },
		
		layout: {
            padding: 20 // Add padding if you want extra space between the chart and legend
        }
    }
	
  });
}

function getYearFromExcelDate(excelDate) {
	
  if (!excelDate || isNaN(excelDate)) {
    // Return null or a default value when the date is invalid or not found
    return null;
  }
  // Define Excel's base date as January 1, 1900
  const excelEpoch = new Date(1900, 0, 1);

  // Subtract 1 because Excel starts at 1, but JavaScript starts at 0
  const jsDate = new Date(excelEpoch.getTime() + (excelDate - 1) * 24 * 60 * 60 * 1000);

  // Return the year
  return jsDate.getFullYear();
}


function aggregateGeoJsonDataByYear(geojson) {
  const data = {};

  geojson.features.forEach(feature => {
	
    const year = getYearFromExcelDate(feature.properties['Effective Date']); // e.g., "2023", "2024"
    let capacity;
    if(year > 2022 && year < 2029){
		let type = ukPowerSourceType(feature.properties['Type']); // Normalize type
		if (type !== "Misc") {
      if (type === 'Energy') {
        type = 'ESS';
      }
      capacity = feature.properties['Capacity (MW)']; // e.g., 50, 30
    }
    
		// Validate data
		if (!year || typeof capacity !== 'number' || capacity <= 0) return;

		// Initialize year and type if not present
		if (!data[year]) data[year] = {};
    if (type !== "Misc") {
      if (!data[year][type]) data[year][type] = 0;

      // Aggregate capacity by year and type
      data[year][type] += capacity/1000;	  
    }
	}	
  });

  return data;
}

let barChartWithCategory;
function createBarChart(aggregatedData) {
  
  
  // Extract years, types, and capacities
  const years = Object.keys(aggregatedData); // ["2023", "2024"]
  const types = [...new Set(Object.values(aggregatedData).flatMap(yearData => Object.keys(yearData)))].sort((a, b) => {
					return ukPowerSourceList.indexOf(a) - ukPowerSourceList.indexOf(b);
				});

  const datasets = types.map(type => ({
    label: type.charAt(0).toUpperCase() + type.slice(1), // Capitalize type names
    data: years.map(year => aggregatedData[year][type] || 0), // Fill in 0 for missing types in a year
    backgroundColor: typeColorMap[type] || 'rgba(4, 59, 92, 0.5)', // Assign random color
	borderWidth: 2,
    borderColor: '#FFFFFF',
  }));

  const ctx = document.getElementById('barChartWithCategory').getContext('2d');
  barChartWithCategory = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: years, // ["2023", "2024"]
      datasets: datasets,
    },
	backgroundColor: 'white',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
		  labels: {
            usePointStyle: true, // Use point style (circle)
            pointStyle: 'circle', // Specify circle style
          },
        },
		datalabels: {
            color: 'white',
			anchor: 'center',
			align: 'center',
			display: function(context) {
			  return context.dataset.data[context.dataIndex] > 3;
			},
			font: {
			  weight: 'bold'
			},
			formatter: function(value, context) {
				const category = context.chart.data.labels[context.dataIndex];
				const datasetLabel = context.dataset.label;
				let valueFix = value.toFixed(2);
				
				const extraSpace = (datasetLabel.length - valueFix.length+1) / 2;
				//return `${datasetLabel}\n${value.toFixed(2)}`; 
				return [
					`${datasetLabel}`, // First line (label)
					`${' '.repeat(extraSpace+1)}${value.toFixed(2)}`         // Second line (value)
				];
			},
			
			
        },
		tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const year = tooltipItem.label;
              const dataset = tooltipItem.dataset;
              const value =  parseFloat(tooltipItem.raw).toFixed(2);
              const total = years.reduce((sum, year) => {
                // Sum all values for the year across all types
                return sum + types.reduce((typeSum, type) => {
                  return typeSum + (aggregatedData[year][type] || 0);
                }, 0);
              }, 0);

              const percentage = total === 0 ? 0 : ((value / total) * 100).toFixed(2); // Calculate percentage
              return `${dataset.label}: ${value} GW (${percentage}%)`; 
            },
          },
        },
		title: {
			display: true,  // Set to true to display the title
			text: 'UK Projects Cumulative Total Capacity (GW) by Year',  // Title text
			font: {
			  size: 14,  // Font size for the title
			  weight: 'bold',  // Font weight for the title
			  family: 'Arial'  // Font family for the title
			},
			color: '#333',  // Color of the title text
			padding: {
			  top: 10,  // Padding above the title
			  bottom: 5  // Padding below the title
			}
		}
      },
	  animation: {
          onComplete: function () {
            const chartInstance = this;
			const ctx = chartInstance.ctx;

			chartInstance.data.labels.forEach((label, index) => {
				// Calculate the total value for this category
				const total = chartInstance.data.datasets.reduce(
					(sum, dataset) => sum + dataset.data[index],
					0
				);

				// Get metadata for the last dataset (top stack)
				const metaTopStack = chartInstance.getDatasetMeta(chartInstance.data.datasets.length - 1);

				if (metaTopStack) {
					const topBar = metaTopStack.data[index]; // Get the bar for the last dataset at this category

					if (topBar) {
						const barX = topBar.x; // Horizontal position (center of the bar)
						const barY = topBar.y; // Vertical position (top of the stack)

						ctx.font = 'bold 14px Arial';
						ctx.textAlign = 'center';
						ctx.textBaseline = 'bottom'; // Align text to appear above the bar

						// Draw the aggregated label at the top of the stack
						ctx.fillStyle = '#000'; // Black text color
						ctx.fillText(total.toFixed(2), barX, barY - 5); // Slightly above the top bar
					}
				}
			});
          }
        },
      scales: {
        x: {
          stacked: true, // Stack on x-axis
          title: {
            display: true,
            text: 'Year',
          },
        },
        y: {
          stacked: true, // Stack on y-axis
          title: {
            display: true,
            text: 'Capacity (GW)',
          },
        },
      },
    },
  });
}

// Utility function to generate random colors
function getRandomColor() {
  return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.7)`;
}


// Main function to fetch, process, and display the chart
async function renderChartFromGeoJson(url) {
  const geojson = await fetchGeoJson(url);

  if (geojson) {
    
    createPieChart(aggregateGeoJsonData(geojson));

	createBarChart(aggregateGeoJsonDataByYear(geojson));
  }
}

document.getElementById('downloadChart').addEventListener('click', function() {
    const chart = chartTypeSelect.value === 'pieChartWithCategory' ? pieChartWithCategory : barChartWithCategory;

    const canvas = chart.canvas;
    const ctx = canvas.getContext('2d');

    // Temporarily add a white background
    ctx.save(); // Save the current canvas state
    ctx.globalCompositeOperation = 'destination-over'; // Draw background beneath chart content
    ctx.fillStyle = 'white'; // Set background color
    ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas

    // Export the canvas as an image
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/png'); // Get the PNG image URL
    link.download = 'myChart.png'; // Set the file name
    link.click(); // Trigger download

    ctx.restore(); // Restore the canvas to its original state
  });

document.getElementById('pieChartWithCategory').addEventListener('click', function (event) {
    event.preventDefault(); // Prevent the default context menu

    // Get the clicked slice
    const points = pieChartWithCategory.getElementsAtEventForMode(
      event,
      'nearest', // Determines the mode to find elements
      { intersect: true },
      false
    );

    if (points.length) {
      selectedDatasetIndex = points[0].index;

      // Position the color picker near the cursor
      colorPicker.style.left = `${event.clientX}px`;
      colorPicker.style.top = `${event.clientY}px`;
      
      // Open the color picker by simulating a click
      colorPicker.click();

    }
  });



document.getElementById('barChartWithCategory').addEventListener('click', function (event) {
    event.preventDefault(); // Prevent the default context menu

    // Get the clicked slice
    const points = barChartWithCategory.getElementsAtEventForMode(
      event,
      'nearest', // Determines the mode to find elements
      { intersect: true },
      false
    );

    if (points.length) {
      selectedDatasetIndex = points[0].datasetIndex;//stack bar chart have datasetIndex then index.

      // Position the color picker near the cursor
      colorPicker.style.left = `${event.clientX}px`;
      colorPicker.style.top = `${event.clientY}px`;
      
      // Open the color picker by simulating a click
      colorPicker.click();

    }
  });
  
colorPicker.onchange = function () {
    const newColor = colorPicker.value;

	if(chartTypeSelect.value === 'pieChartWithCategory'){
		pieChartWithCategory.data.datasets[0].backgroundColor[selectedDatasetIndex] = newColor;

        pieChartWithCategory.update();
		
	}else if(chartTypeSelect.value === 'barChartWithCategory'){
		barChartWithCategory.data.datasets.forEach((dataset, index) => {
			if (index === selectedDatasetIndex) {
			  dataset.backgroundColor = newColor;
			}
		});
        
        // Refresh the chart
        barChartWithCategory.update();
	}

};

  
// Call the main function with the GeoJSON file URL
const geojsonUrl = '/UK_Power_Plants.geojson';
renderChartFromGeoJson(geojsonUrl);
