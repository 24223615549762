import { stylePowerLinesDynamic, styleSubstations, styleEntity} from './imageryLayersStyles.js';


export const countryMVTImageryConfigs = [
  {
    country: "Austria",
    layerPath: "austria_lines_0_100kv",
    layerName: "austria_lines_0_100kV",
	  layerKey: "_0to100kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Austria",
    layerPath: "austria_lines_100_200kv",
    layerName: "austria_lines_100_200kV",
	  layerKey: "_100to200kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Austria",
    layerPath: "austria_lines_200_300kv",
    layerName: "austria_lines_200_300kV",
	  layerKey: "_200to300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Austria",
    layerPath: "austria_lines_300kv_above",
    layerName: "austria_lines_300kV_above",
	  layerKey: "_g300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Texas",
    layerPath: "texas_lines_0_100kv",
    layerName: "texas_lines_0_100kv",
	  layerKey: "_0to100kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Texas",
    layerPath: "texas_lines_100_200kv",
    layerName: "texas_lines_100_200kv",
	  layerKey: "_100to200kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Texas",
    layerPath: "texas_lines_200_300kv",
    layerName: "texas_lines_200_300kv",
	  layerKey: "_200to300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Texas",
    layerPath: "texas_lines_300kv_above",
    layerName: "texas_lines_300kv_above",
	  layerKey: "_g300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "France",
    layerPath: "france_lines_0_100kv",
    layerName: "france_lines_0_100kv",
	  layerKey: "_0to100kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "France",
    layerPath: "france_lines_100_200kv",
    layerName: "france_lines_100_200kv",
	  layerKey: "_100to200kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "France",
    layerPath: "france_lines_200_300kv",
    layerName: "france_lines_200_300kv",
	  layerKey: "_200to300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "France",
    layerPath: "france_lines_300kv_above",
    layerName: "france_lines_300kv_above",
	  layerKey: "_g300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Germany",
    layerPath: "germany_lines_0_100kv",
    layerName: "germany_lines_0_100kv",
	  layerKey: "_0to100kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Germany",
    layerPath: "germany_lines_100_200kv",
    layerName: "germany_lines_100_200kv",
	  layerKey: "_100to200kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Germany",
    layerPath: "germany_lines_200_300kv",
    layerName: "germany_lines_200_300kv",
	  layerKey: "_200to300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "Germany",
    layerPath: "germany_lines_300kv_above",
    layerName: "germany_lines_300kv_above",
	  layerKey: "_g300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "UK",
    layerPath: "uk_lines_0_100kv",
    layerName: "uk_lines_0_100kv",
	  layerKey: "_0to100kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "UK",
    layerPath: "uk_lines_100_200kv",
    layerName: "uk_lines_100_200kv",
	  layerKey: "_100to200kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "UK",
    layerPath: "uk_lines_200_300kv",
    layerName: "uk_lines_200_300kv",
	  layerKey: "_200to300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  {
    country: "UK",
    layerPath: "uk_lines_300kv_above",
    layerName: "uk_lines_300kv_above",
	  layerKey: "_g300kv",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: true
    }
  },
  // {
  //   country: "Scotland",
  //   layerPath: "scotland_lines_0_100kv",
  //   layerName: "scotland_lines_0_100kv",
	//   layerKey: "_0to100kv",
  //   maximumLevel: 18,
  //   layerStyleFnc: stylePowerLinesDynamic,
	//   layerStyleParams: {
  //     opacity: 1,
  //     defaultStyle: true
  //   }
  // },
  // {
  //   country: "Scotland",
  //   layerPath: "scotland_lines_100_200kv",
  //   layerName: "scotland_lines_100_200kv",
	//   layerKey: "_100to200kv",
  //   maximumLevel: 18,
  //   layerStyleFnc: stylePowerLinesDynamic,
	//   layerStyleParams: {
  //     opacity: 1,
  //     defaultStyle: true
  //   }
  // },
  // {
  //   country: "Scotland",
  //   layerPath: "scotland_lines_200_300kv",
  //   layerName: "scotland_lines_200_300kv",
	//   layerKey: "_200to300kv",
  //   maximumLevel: 18,
  //   layerStyleFnc: stylePowerLinesDynamic,
	//   layerStyleParams: {
  //     opacity: 1,
  //     defaultStyle: true
  //   }
  // },
  // {
  //   country: "Scotland",
  //   layerPath: "scotland_lines_300kv_above",
  //   layerName: "scotland_lines_300kv_above",
	//   layerKey: "_g300kv",
  //   maximumLevel: 18,
  //   layerStyleFnc: stylePowerLinesDynamic,
	//   layerStyleParams: {
  //     opacity: 1,
  //     defaultStyle: true
  //   }
  // },
  {
    country: "Texas",
    layerPath: "texas_towers",
    layerName: "texas_power_tower_poles",
	  layerKey: "towers",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "blue",
      strokeStyleStyle: "blue",
	  lineWidth: 4
    }
  },
  {
    country: "Texas",
    layerPath: "texas_substations",
    layerName: "texas_substations",
	  layerKey: "substations",
    maximumLevel: 18,
    layerStyleFnc: styleSubstations
  },
  
  {
    country: "England",
    layerPath: "england_minor_lines",
    layerName: "england_minor_lines",
	  layerKey: "minor_lines",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "red",
      strokeStyleStyle: "black",
	  lineWidth: 1
    }
  },
  {
    country: "England",
    layerPath: "england_towers",
    layerName: "england_towers",
	  layerKey: "towers",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 4
    }
  },
  {
    country: "England",
    layerPath: "england_poles",
    layerName: "england_poles",
	  layerKey: "poles",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "blue",
      strokeStyleStyle: "blue",
	  lineWidth: 4
    }
  },
  {
    country: "France",
    layerPath: "france_substations",
    layerName: "france_substations",
	  layerKey: "substations",
    maximumLevel: 18,
    layerStyleFnc: styleSubstations
  }, 
  {
    country: "France",
    layerPath: "france_minor_lines",
    layerName: "france_minor_lines",
	  layerKey: "minor_lines",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "red",
      strokeStyleStyle: "black",
	  lineWidth: 0.5
    }
  },
  {
    country: "France",
    layerPath: "france_towers",
    layerName: "france_towers",
	layerKey: "towers",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "blue",
      strokeStyleStyle: "blue",
	  lineWidth: 1
    }
  },
  {
    country: "France",
    layerPath: "france_sttc",
    layerName: "france_sttc",
	  layerKey: "sttc",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 1
    }
  },
  {
    country: "Austria",
    layerPath: "austria_minor_lines",
    layerName: "austria_minor_lines",
	  layerKey: "minor_lines",
    maximumLevel: 18,
    layerStyleFnc: stylePowerLinesDynamic,
	  layerStyleParams: {
      opacity: 1,
      defaultStyle: false
    }
  },
  {
    country: "Austria",
    layerPath: "austria_poles",
    layerName: "austria_poles",
	  layerKey: "poles",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "blue",
      strokeStyleStyle: "blue",
	  lineWidth: 1
    }
  },
  {
    country: "Austria",
    layerPath: "austria_towers",
    layerName: "austria_towers",
	  layerKey: "towers",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "blue",
      strokeStyleStyle: "blue",
	  lineWidth: 1
    }
  },
  {
    country: "Austria",
    layerPath: "austria_sttc",
    layerName: "austria_sttc",
  	layerKey: "sttc",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 1
    }
  },
  {
    country: "UK",
    layerPath: "uk_substations",
    layerName: "uk_substations",
	  layerKey: "substations",
    maximumLevel: 18,
    layerStyleFnc: styleSubstations
  },
  {
    country: "Austria",
    layerPath: "austria_substations",
    layerName: "austria_substations",
	  layerKey: "substations",
    maximumLevel: 18,
    layerStyleFnc: styleSubstations
  },
  {
    country: "Germany",
    layerPath: "germany_minor_lines",
    layerName: "germany_minor_lines",
	  layerKey: "minor_lines",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "black",
      strokeStyleStyle: "black",
	  lineWidth: 0.5
    }
  },
  {
    country: "Germany",
    layerPath: "germany_poles",
    layerName: "germany_poles",
	layerKey: "poles",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 4
    }
  },
  {
    country: "Germany",
    layerPath: "germany_towers",
    layerName: "germany_towers",
	layerKey: "towers",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 4
    }
  },
  {
    country: "Germany",
    layerPath: "germany_substations",
    layerName: "germany_substations",
	  layerKey: "substations",
    maximumLevel: 18,
    layerStyleFnc: styleSubstations
  }, 
  {
    country: "Germany",
    layerPath: "germany_cables",
    layerName: "germany_cables",
	  layerKey: "cables",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
	  layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 1
    }
  }, 
  {
    country: "Germany",
    layerPath: "germany_switches",
    layerName: "germany_switches",
	  layerKey: "switches",
    maximumLevel: 18,
    layerStyleFnc: styleEntity,
  	layerStyleParams: {
      fillStyle: "green",
      strokeStyleStyle: "green",
	  lineWidth: 1
    }
  },
  

];


export const voltagePowerLinesByCountry = {
  Germany: ['110kV', '132kV', '150kV', '155kV', '220kV', '225kV', '250kV', '300kV', '320kV', '380kV', '400kV', '450kV', '600kV'],
  Austria: ['110kV', '132kV', '150kV', '220kV', '380kV', '400kV'],
  UK: ['10kV', '11kV', '20kV', '33kV','38kV','66kV', '110kV', '132kV', '220kV', '250kV', '275kV', '320kV', '400kV', '600kV'],
  France: ['63kV', '90kV', '150kV', '225kV', '400kV'],
  Texas: ['69kV', '115kV', '138kV', '230kV', '345kV', '500kV'],
};
