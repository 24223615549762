import * as Cesium from 'cesium';
import { getViewer } from './main.js';
import { ukPowerSourceList, ukPowerSourceType } from './common.js';

const viewer = getViewer();
const typeSelect = document.getElementById('type');

let activeLinePositions = [];
let activeLineLabel;
let activePolyline;
let activeDrawingHandler;
let activeCircle = null;  // To store the current circle entity
let activeCircleCenterPosition = null; // To store the center position
let activeCircleLabel;
const allPolylines = [];  // Store all completed polylines here
const allCircles = [];
let isDrawing = false;
let lineID = 0;
let geomObjectsByID = new Array();
let pointID = 0;
let circleID = 0;
let clickNum = 0;

function createLabelForLine(){
	// Create a new distance label to follow the current line's end point
    activeLineLabel = viewer.entities.add({
        label: {
            show: true,
			text: "Distance: 0 km",
			font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
			fillColor: Cesium.Color.WHITE,  // White text color for contrast
			outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
			outlineWidth: 3,  // Thicker outline for better contrast
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
			showBackground: true,  // Display background behind the text
			backgroundColor: new Cesium.Color(0, 0, 0, 0.7),  // Slightly transparent black background
			backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
			zIndex: 2,  // Ensure the label appears on top of other entities
			// Shadow effect for better readability
			shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
			shadowOffsetX: 2,  // Slight horizontal offset for the shadow
			shadowOffsetY: 2,  // Slight vertical offset for the shadow
			//pixelOffset: new Cesium.Cartesian2(15, -15),
        },
    });
}

function createLabelForLineNew(){
	// Create a new distance label to follow the current line's end point
    return {
            show: true,
			text: "Distance: 0 km",
			font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
			fillColor: Cesium.Color.WHITE,  // White text color for contrast
			outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
			outlineWidth: 3,  // Thicker outline for better contrast
			style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
			showBackground: true,  // Display background behind the text
			backgroundColor: new Cesium.Color(0, 0, 0, 0.7),  // Slightly transparent black background
			backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
			verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
			horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
			zIndex: 2,  // Ensure the label appears on top of other entities
			// Shadow effect for better readability
			shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
			shadowOffsetX: 2,  // Slight horizontal offset for the shadow
			shadowOffsetY: 2,  // Slight vertical offset for the shadow
			//pixelOffset: new Cesium.Cartesian2(15, -15),
        };
    
}

function createLabelForPoint(id, latitude, longitude){
	// Create a new distance label to follow the current line's end point
    return {
		show: true,
		text: id + ": " + latitude.toFixed (2) + ", " + longitude.toFixed(2),
		font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
		fillColor: Cesium.Color.WHITE,  // White text color for contrast
		outlineColor: Cesium.Color.BLACK,  // Black outline for clarity
		outlineWidth: 3,  // Thicker outline for better contrast
		//style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
		showBackground: true,  // Display background behind the text
		backgroundColor: new Cesium.Color(0, 255, 0, 0.7),  // Slightly transparent black background
		backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
		//pixelOffset: new Cesium.Cartesian2(15, -15),  // Slightly larger offset to avoid overlap with the pointer
		verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
		horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
		zIndex: 2,  // Ensure the label appears on top of other entities
		// Shadow effect for better readability
		shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
		shadowOffsetX: 2,  // Slight horizontal offset for the shadow
		shadowOffsetY: 2,  // Slight vertical offset for the shadow};
	};
}


function createLabelForCircle(){
	// Create a new distance label to follow the current line's end point
    activeCircleLabel = viewer.entities.add({
        label: {
            show: true,
            text: "Radius: 0 km",
            font: "16px bold Helvetica, Arial, sans-serif",  // Larger, more readable font with a clean family
            fillColor: Cesium.Color.WHITE,  // White text color for contrast
            //style: Cesium.LabelStyle.FILL_AND_OUTLINE,  // Solid fill with outline for better visibility
            showBackground: true,  // Display background behind the text
            backgroundColor: new Cesium.Color(0, 255, 0, 0.7),  // Slightly transparent black background
            backgroundPadding: new Cesium.Cartesian2(10, 6),  // More padding around the text for a spacious look
            //pixelOffset: new Cesium.Cartesian2(15, -15),  // Slightly larger offset to avoid overlap with the pointer
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,  // Position the label below the point
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,  // Position the label to the right of the point
            zIndex: 2,  // Ensure the label appears on top of other entities
            // Shadow effect for better readability
            shadowColor: Cesium.Color.BLACK.withAlpha(0.5),  // Light shadow effect to improve contrast
            shadowOffsetX: 2,  // Slight horizontal offset for the shadow
            shadowOffsetY: 2,  // Slight vertical offset for the shadow
        },
    });
}


function startNewLineDrawing(){
	activeLinePositions = [];
	createLabelForLine();
	lineID++;
    // Create a new polyline entity with a dashed line to show the drawing in progress
	let id = 'Line-'+lineID;
    activePolyline = viewer.entities.add({
		id: id,
        polyline: {
            positions: new Cesium.CallbackProperty(() => activeLinePositions, false),
            width: 3,
            material: Cesium.Color.BLUE
        },
		//label: createLabelForLineNew() RnD on this, may solve the undo issue with label
    });
	
    // Set up event handlers for drawing
    activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

    // Add points to the line on left click
    activeDrawingHandler.setInputAction((event) => {
		
//			geomObjectsByID.push({ type: 'line', line: activePolyline, label: activeLineLabel });
			activeCircleCenterPosition = null;
			const cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
			if (cartesian) {
				activeLinePositions.push(cartesian);
			}
			
			if (activeLineLabel && activeLinePositions.length > 0) {
				//console.log("Calculate the distance");
				const distance = calculateDistance(activeLinePositions);
				//console.log("Calculate the distance "+distance);
				activeLineLabel.label.text = `${activePolyline.id} : ${(distance / 1000).toFixed(2)} km`;
			}
		
		if(isDrawing){
			isDrawing = false;
			finalizeCurrentLine();
		}else{
			geomObjectsByID.push({ type: 'line', line: activePolyline, label: activeLineLabel });
			isDrawing = true;
		}
        
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);


    // Update the distance label on mouse move
    activeDrawingHandler.setInputAction((movement) => {
        const cartesian = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);
        if (cartesian && activeLinePositions.length > 0) {
			let mouseLinePositions = [];
			mouseLinePositions.push(activeLinePositions[0]);
			mouseLinePositions.push(cartesian);
            const distance = calculateDistance(mouseLinePositions);
            activeLineLabel.position = cartesian;
            activeLineLabel.label.text = `${activePolyline.id} : ${(distance / 1000).toFixed(2)} km`;
        }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
}

// Function to finalize the current line and stop drawing
function finalizeCurrentLine() {
    // Position the distance label at the end of the line
    if (activeLinePositions.length > 1) {
        const lastPoint = activeLinePositions[activeLinePositions.length - 1];
		activeLineLabel.position = Cesium.Cartesian3.midpoint(activeLinePositions[0], lastPoint, new Cesium.Cartesian3());
		//activeLineLabel.position = activeLinePositions[0]; // at start of line
       // activeLineLabel.position = lastPoint; // at the end of line
    }
	
	// Make the line solid by setting a permanent material color
    if (activePolyline) {
		activePolyline.polyline.positions = activeLinePositions;
		allPolylines.push(activePolyline);
    }
	

    // Remove the drawing handler to stop adding points to this line
    if (activeDrawingHandler) {
        activeDrawingHandler.destroy();
    }

    startNewDrawing();
}

function startPointDrawing() {
    // Convert the screen position to a Cartesian3 position
	activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
	
	activeDrawingHandler.setInputAction((event) => {
		const cartesian = viewer.scene.pickPosition(event.position);
		
		if (Cesium.defined(cartesian)) {	
			// Convert the Cartesian3 position to geographic coordinates
			const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
			const latitude = Cesium.Math.toDegrees(cartographic.latitude);
			const longitude = Cesium.Math.toDegrees(cartographic.longitude);
			
			// Log latitude and longitude
			//console.log("Latitude:", latitude, "Longitude:", longitude);

			// Create the point entity at the clicked location
			let id = 'Point-' + pointID;
			let activePoint = viewer.entities.add({
				id: id,
				position: cartesian,
				point: {
					pixelSize: 10,
					color: Cesium.Color.RED,
					outlineColor: Cesium.Color.WHITE,
					outlineWidth: 2 
				},
				label: createLabelForPoint(id, latitude, longitude),
			});
			pointID = pointID + 1;
			geomObjectsByID.push({ type: 'point', point: activePoint});
		}
		
	}, Cesium.ScreenSpaceEventType.LEFT_DOWN);
}

function startCircleDrwaing(){
	createLabelForCircle();
	circleID++;
	let circleId = 'Circle-' + circleID;
	activeCircle = viewer.entities.add({
              id: circleId,
             // position: new Cesium.CallbackProperty(() => activeLinePositions, false),
              ellipse: {
                  semiMajorAxis: 1.0,  // Initial radius
                  semiMinorAxis: 1.0,
                  material: Cesium.Color.BLUE.withAlpha(0.5),
              },
          });
	
	activeDrawingHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);

    // Add points to the line on left click
    activeDrawingHandler.setInputAction((event) => {
		
		if(!isDrawing){
			
			geomObjectsByID.push({ type: 'circle', circle: activeCircle, label: activeCircleLabel });
			const cartesian = viewer.camera.pickEllipsoid(event.position, viewer.scene.globe.ellipsoid);
			if (cartesian) {
				activeCircle.position = cartesian;
				activeCircleLabel.position = cartesian;
				activeCircleCenterPosition = cartesian;
				
			}
			isDrawing = true;
			
		}else{
			isDrawing = false;
			finalizeCurrentCircle();
			activeCircleCenterPosition = null;
			setCameraControls(true);
		}
        
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
	
	activeDrawingHandler.setInputAction((event) => {
		  
			if (isDrawing && Cesium.defined(activeCircleCenterPosition)) {
			  const currentMousePosition = viewer.scene.pickPosition(event.endPosition);

			  if (Cesium.defined(currentMousePosition)) {
				try {
					
					let mouseLinePositions = [];
					mouseLinePositions.push(activeCircleCenterPosition);
					mouseLinePositions.push(currentMousePosition);
					const radius = calculateDistance(mouseLinePositions);
					if (!isNaN(radius)) {
						activeCircle.ellipse.semiMajorAxis = radius;
						activeCircle.ellipse.semiMinorAxis = radius;
						updateActiveCircleLabel (radius, currentMousePosition);
		 
					}
				} catch (error) {
					console.error('Error calculating or setting radius:', error);
				}
				  // Update the circle's radius dynamically
				  //activeCircle.ellipse.semiMajorAxis = radius;
				  //activeCircle.ellipse.semiMinorAxis = radius;
			  }
			}
		  
		}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
	
}

function updateActiveCircleLabel(radius, mousePosition) {
  let txt = "Radius: " + (radius / 1000).toFixed(2) + "km";
  if (activeCircleLabel) {
      activeCircleLabel.label.text = txt; // Convert meters to km
      const cartesian = viewer.camera.pickEllipsoid(mousePosition, viewer.scene.globe.ellipsoid);
      if (cartesian) {
          activeCircleLabel.position = cartesian; // Update label position to follow the mouse
      }
  }
}

function finalizeCurrentCircle() {
    
	
	// Make the line solid by setting a permanent material color
    if (activeCircle) {
		allCircles.push(activeCircle);
    }
	

    // Remove the drawing handler to stop adding points to this line
    if (activeDrawingHandler) {
        activeDrawingHandler.destroy();
    }

    startNewDrawing();
}


function startNewDrawing() {
    if(typeSelect.value === 'LineString'){
		
		resetAllActiveDrawing();
		startNewLineDrawing();
		
	}else if(typeSelect.value === 'Point'){
		
		resetAllActiveDrawing();
		startPointDrawing();
		
	}else if(typeSelect.value === 'Circle'){
		
		resetAllActiveDrawing();
		startCircleDrwaing();
		
	}else if(typeSelect.value === 'None'){
		
		resetAllActiveDrawing();
	}
}

function resetAllActiveDrawing() {
	if(activeDrawingHandler && !activeDrawingHandler.isDestroyed()){
			activeDrawingHandler.destroy();	
	}
}



typeSelect.onchange = function () {
	startNewDrawing();
};


document.getElementById('undo').addEventListener('click', function () {
  if (geomObjectsByID.length) {
    let entityObj = geomObjectsByID[geomObjectsByID.length - 1]
    
	if (entityObj.type === 'circle') {
        viewer.entities.remove(entityObj.circle);
        viewer.entities.remove(entityObj.label);
 
    }else if (entityObj.type === 'line') {
        viewer.entities.remove(entityObj.line);
        viewer.entities.remove(entityObj.label);
 
    }else if (entityObj.type === 'point') {
		viewer.entities.remove(entityObj.point);
    } else {
      console.log ("unknown type...");
    }
    geomObjectsByID.pop ();  
  } else {
    //Nothing to remove!
  }
});


// Function to calculate the total distance between positions
function calculateDistance(positions) {
    let distance = 0;
    for (let i = 1; i < positions.length; i++) {
        const point1 = Cesium.Cartographic.fromCartesian(positions[i - 1]);
        const point2 = Cesium.Cartographic.fromCartesian(positions[i]);
        distance += Cesium.Cartesian3.distance(
            Cesium.Cartesian3.fromRadians(point1.longitude, point1.latitude),
            Cesium.Cartesian3.fromRadians(point2.longitude, point2.latitude)
        );
    }
    return distance;
}
